import React, { useState, useEffect, Fragment } from "react";
import { OverlayBlackScreen } from "../OverlayBlackScreen";
import "./_style.scss";
import LocalStorage from "../../utils/LocalStorage";
import { Button, IconButton } from "../Button";
import PageContainer from "../PageContainer";
import { colors } from "../../Theme/general";
import bubbleTip from "../../assets/bubbleTip.png";
import { __LOC } from "../../utils/locale";

export const CoachMark = ({
  bubbleConfig,
  CustomComp,
  coachmarkItemName,
  enableGotIt,
  onShowCoachMarkChange,
}) => {
  useEffect(() => {
    let coachMarksChecklist = LocalStorage.getJSONItem("coachMarksChecklist");
    if (!coachMarksChecklist)
      LocalStorage.setJSONItem("coachMarksChecklist", {});
    let checklistItem =
      coachMarksChecklist && coachMarksChecklist[coachmarkItemName];

    if (!checklistItem) {
      setTimeout(() => setShow(true, 2000));
    }
  }, []);

  const handleCheckOffCoachItem = () => {
    setShow(false);
    let coachMarksChecklist = LocalStorage.getJSONItem("coachMarksChecklist");
    LocalStorage.setJSONItem("coachMarksChecklist", {
      ...coachMarksChecklist,
      [coachmarkItemName]: "1",
    });
  };

  const [show, setShow] = useState(false);
  const [activeBubbleIdx, setActiveBubbleIdx] = useState(0);

  useEffect(() => {
    onShowCoachMarkChange &&
      onShowCoachMarkChange({ isActive: show, activeBubbleIdx });
  }, [show, activeBubbleIdx]);

  if (!show) return null;

  return (
    <Fragment>
      <OverlayBlackScreen show={true} />
      {/* <PageContainer
        style={{ position: "fixed", left: 0, top: 0, zIndex: 100 }}
      > */}
      <Fragment>
        {/* <div style={{ position: "absolute", right: "2rem", top: "1rem" }}>
          <IconButton nav icon="X" onClick={handleCheckOffCoachItem} />
        </div> */}
        {CustomComp && <CustomComp onDone={handleCheckOffCoachItem}/>}
        {bubbleConfig && bubbleConfig.map((conf, i) => {
          console.log({ i, activeBubbleIdx });
          if (i === activeBubbleIdx) {
            return (
              <CoachMarkBubble
                key={i}
                left={conf.left}
                top={conf.top}
                bottom={conf.bottom}
                right={conf.right}
                bubbleTipPos={conf.bubbleTipPos}
                activeBubbleIdx={activeBubbleIdx}
                setActiveBubbleIdx={setActiveBubbleIdx}
                totalBubbles={bubbleConfig.length}
                title={conf.title}
                desc={conf.desc}
                handleCheckOffCoachItem={handleCheckOffCoachItem}
              />
            );
          }
        })}

        <div
          style={{
            position: "absolute",
            bottom: "2rem",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        ></div>
      </Fragment>
    </Fragment>
  );
};

export const CoachMarkBubble = (props) => {
  const wrapperStyle = {
    padding: "1rem",
    textAlign: "center",
    borderRadius: "1rem",
    backgroundColor: colors.WHITE,
    position: "absolute",
    top: props.top,
    left: props.left,
    right: props.right,
    bottom: props.bottom,
    width: "14rem",
    zIndex: 100
  };

  const setBubbleTipPos = (pos) => {
    switch (pos) {
      case "topLeft":
      default:
        return {
          top: "1px",
          left: "2rem",
          transform: "translateY(-100%)",
        };
      case "topRight":
        return {
          top: "1px",
          right: "2rem",
          transform: "scaleX(-1) translateY(-100%)",
        };
    }
  };

  const bubbleTipStyle = {
    height: "1.5rem",
    position: "absolute",
    ...setBubbleTipPos(props.bubbleTipPos),
  };

  const isLastBubble = props.activeBubbleIdx + 1 === props.totalBubbles;

  return (
    <div style={wrapperStyle}>
      {props.title && <p className="p bold">{__LOC(props.title)}</p>}
      {props.desc && <p className='h5'>{__LOC(props.desc)}</p>}
      {
        <div style={{paddingTop: '1rem', display: 'flex', justifyContent: 'center'}}>
          <Button
            height={30}
            onClick={() => {
              isLastBubble
                ? props.handleCheckOffCoachItem()
                : props.setActiveBubbleIdx((p) =>
                    p !== props.totalBubbles - 1 ? p + 1 : p
                  );
            }}
          >
            {`${isLastBubble ? __LOC("Done") : __LOC("Next")} `}
          </Button>
        </div>
      }
      <div style={bubbleTipStyle}>
        <img style={{ height: "100%", width: "auto" }} src={bubbleTip} />
      </div>
    </div>
  );
};
