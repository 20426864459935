import React, { createContext, useEffect, useState } from "react";

export const SingleChoiceContext = createContext();
export const SingleChoiceProvider = ({ children }) => {
  const [options, setOptions] = useState([]);
  const [singleChoice, setSingleChoice] = useState({});

  useEffect(() => {
    if (options && options.length) {
      let obj = options.reduce((acc, cur, i) => {
        acc[cur.display.toLowerCase().split(" ").join("")] = false;
        return acc;
      }, {});
      setSingleChoice(obj);
    }
  }, [options]);

  return (
    <SingleChoiceContext.Provider
      value={{ singleChoice, setSingleChoice, options, setOptions }}>
      {children}
    </SingleChoiceContext.Provider>
  );
};
