import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";

export const StyledButton = styled.button`
  ${({ theme }) => theme.base.button}
  ${({ orientation }) => orientation === 'horizontal' && setHorOrient}
  ${({ orientation }) => orientation === 'vertical' && setVerOrient}
  ${({ height }) => height && setCustomHeight}
  ${({ selectable }) => selectable && setBlueTheme}
  ${({ secondary }) => secondary && setWhiteTheme}
  ${({ active, selectable }) => !selectable && (active === true || active === 'neutral') && isActiveDefault}
  ${({ active }) => active === 'success' && isActiveSuccess}
  ${({ active }) => active === 'danger' && isActiveDanger}
  ${({ disabled }) => disabled && isButtonDisabled}
  ${({ styled }) => styled && styled }
  
`;

export const StyledButtonLink = styled(Link)`
  ${({ theme }) => theme.base.button}
  ${({ secondary }) => secondary && setWhiteTheme}
  ${({ styled }) => styled && styled }
`;

const setBlueTheme = css`
  background-color : ${({theme}) => theme.colors.WHITE};
  &:hover{
    background-color : ${({theme}) => theme.colors.SKY_BLUE}
  }
  &:focus{
    background-color : ${({theme, active}) => !active && theme.colors.WHITE};
  }
  ${({ active }) => (active === true || active === 'neutral') && isActiveBlueTheme}
`;

const setWhiteTheme = css`
  background-color : ${({theme}) => theme.colors.WHITE};
  &:hover{
    background-color : ${({theme}) => theme.colors.BG_YELLOW}
  }
  &:focus{
    background-color : ${({theme}) => theme.colors.WHITE};
  }
`;

const isActiveBlueTheme = css`
  background-color : ${({theme}) => theme.colors.SKY_BLUE2};

  &:focus{
    background-color : ${({theme}) => theme.colors.SKY_BLUE2};  
  }
`

const setCustomWidth = css`
  width : ${({style}) => style.width};
`;

const setCustomHeight = css`
  min-height: unset;
  height : ${({height}) => height+'px'};
`;

const isButtonDisabled = css`
  border-color : ${({theme}) => theme.colors.LIGHT_GREY};
  color : ${({theme}) => theme.colors.MID_GREY};
  background-color: ${({theme}) => theme.colors.LIGHT_GREY};
  cursor: not-allowed;
  &:hover{
    border-color : ${({theme}) => theme.colors.LIGHT_GREY};
    color : ${({theme}) => theme.colors.MID_GREY};
    background-color: ${({theme}) => theme.colors.LIGHT_GREY};
  }
`;



export const StyledIconButton = styled.button`
  ${({ theme }) => theme.base.iconButton};
  ${({nav, theme, active}) => nav && theme.base.navLinkStyle(active)}
  ${({theme, hamburger, active}) => hamburger && theme.base.hamburgerStyle(active)}
  ${({small}) => small && smallIconButton}
  ${({ disabled }) => disabled && isIconButtonDisabled};
  ${({forceActive, theme}) => forceActive ? `background-color: ${theme.colors.YELLOW2};` : ''}
`;

export const StyledIconButtonLink = styled(Link)`
  ${({ theme }) => theme.base.iconButton};
  ${({nav, theme, active}) => nav && theme.base.navLinkStyle(active)}
  ${({theme, hamburger, active}) => hamburger && theme.base.hamburgerStyle(active)}
  ${({small}) => small && smallIconButton}
  ${({ disabled }) => disabled && isIconButtonDisabled};
`;

const smallIconButton = css`
  height : 3rem; width: 3rem;
`


const setHorOrient = css`
  flex: 0 0 50%;
  
  &:first-of-type{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-of-type{
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const setVerOrient = css`
  width: 100%;
  border-radius: 0;
  border-top: none;

  &:first-of-type{
    border-top: 1px solid ${({theme}) => theme.colors.black};
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  &:last-of-type{
    
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const isActiveDefault = css`
  background-color : ${({theme}) => theme.colors.YELLOW2};
`;

const isActiveSuccess = css`
  &:hover{
    background-color : ${({theme}) => theme.colors.SUCCESS};  
  }
  background-color : ${({theme}) => theme.colors.SUCCESS};
`;

const isActiveDanger = css`
  &:hover{
    background-color : ${({theme}) => theme.colors.DANGER};  
  }
  background-color : ${({theme}) => theme.colors.DANGER};
`;


const isIconButtonDisabled = css`
  background-color : ${({theme}) => theme.colors.LIGHT_GREY};
  border: none;
  cursor: not-allowed;
  &:hover{
    background-color : ${({theme}) => theme.colors.LIGHT_GREY};  
  }
  &:focus{
    background-color : ${({theme}) => theme.colors.LIGHT_GREY};  
  }
`;