import { musicPlayersConfig, ambiencePlayersConfig, soundEffectsPlayerConfig } from "./config";

export const extractAndPopulateRest = (soundConfig, musicPlayersConfigCurrent) => {



  if (soundConfig.some((m) => m.playerName === "rest")) {
    let restConfig = soundConfig.find((m) => m.playerName === "rest");
    let configsToAppend = musicPlayersConfigCurrent
      .map((d) => {
        if (soundConfig.every((dd) => dd.playerName !== d.id)) {
          return { ...restConfig, playerName: d.id };
        } else {
          return undefined;
        }
      })
      .filter(Boolean);
    return [
      ...soundConfig.filter((d) => d.playerName !== "rest"),
      ...configsToAppend,
    ];
  } else{
      return soundConfig;
  }
};

export const handleApplyMute = (playerName, thisPlayer, value, musicPlayersConfigCurrent) => {
  //if true
  const playerConfig = playerName === 'ambience' 
    ? [ambiencePlayersConfig] 
    : playerName === 'soundEffects' 
      ? [soundEffectsPlayerConfig] 
      : musicPlayersConfigCurrent
  //get fadeout time -> x //default vol = 1
  let { fadeOut, fadeIn,  volume: defaultVolume } = playerConfig.find(
    (d) => d.id === playerName
  ).controls;
  //lowest vol we keep a const of -30
  let lowestVolume = -30;
  //const fps = 10
  let fps = 10;
  let increment;
  //setIntervaltime = 1000/ fps
  let setIntervalTime = 1000 / fps;
  let fadeInterval;

  if (value === true) {
    //if player is already mute then do nothing
    if (thisPlayer.mute === true) return;

    //else

    // --> incr = (lowestvol - defaultvol ) / ((x* 1000 * fps) / 1000)
    increment =
      (lowestVolume - defaultVolume) / ((fadeOut * 1000 * fps) / 1000);

    //setInterval(() => {
    // incr the volume
    //},setIntervalTime)
    fadeInterval = setInterval(() => {
      thisPlayer.volume.value = thisPlayer.volume.value + increment;
    }, setIntervalTime);

    //settimeout --> stop setinterval, apply mute, time = fadeouttime
    setTimeout(() => {
      clearInterval(fadeInterval);
      thisPlayer.mute = true;
    }, fadeOut * 1000);



  } else if (value === false) {



    //if player is already unmute then do nothing
    if (thisPlayer.mute === false) return;
    //else

    // set vol to -31
    // set mute to false
    thisPlayer.volume.value = lowestVolume;
    thisPlayer.mute = false;

    // --> incr = ( defaultvol - lowestvol ) / ((x* 1000 * fps) / 1000)
    increment =
      (defaultVolume - lowestVolume) / ((fadeIn * 1000 * fps) / 1000);

    //setInterval(() => {
    // incr the volume
    //},setIntervalTime)
    fadeInterval = setInterval(() => {
      thisPlayer.volume.value = thisPlayer.volume.value + increment;
    }, setIntervalTime);

    //settimeout --> stop setinterval, set vol to default val,  time = fadeouttime
    setTimeout(() => {
      clearInterval(fadeInterval);
      thisPlayer.volume.value = defaultVolume;
    }, fadeIn * 1000);
  }
};


export const handleFadeVolumeChange = (playerName, thisPlayer, musicPlayersConfigCurrent, fromVol, toVol  ) => {
  //if true
  const playerConfig = playerName === 'ambience' 
    ? [ambiencePlayersConfig] 
    : playerName === 'soundEffects' 
      ? [soundEffectsPlayerConfig] 
      : musicPlayersConfigCurrent
  //get fadeout time -> x //default vol = 1
  let { fadeOut, fadeIn } = playerConfig.find(
    (d) => d.id === playerName
  ).controls;

  console.log({
    
    playerName
  })
  
  //const fps = 10
  let fps = 10;
  let increment;
  //setIntervaltime = 1000/ fps
  let setIntervalTime = 1000 / fps;
  let fadeInterval;

  
    //if vol of player is same as the toVol, then do nothing. no fade needed
    // if (Math.round(thisPlayer.get().volume) === toVol) return;

    //else

    // --> incr = (lowestvol - defaultvol ) / ((x* 1000 * fps) / 1000)
    increment =
      (toVol - fromVol) / ((fadeOut * 1000 * fps) / 1000);

    //setInterval(() => {
    // incr the volume
    //},setIntervalTime)
    fadeInterval = setInterval(() => {
      console.log({
        playerName,
        vol: thisPlayer.volume.value
      })
      thisPlayer.volume.value = thisPlayer.volume.value + increment;
    }, setIntervalTime);

    //settimeout --> stop setinterval, apply mute, time = fadeouttime
    setTimeout(() => {
      clearInterval(fadeInterval);
      // thisPlayer.mute = true;
    }, fadeOut * 1000);



  
};