import { line, area, curveMonotoneX, pie, arc } from 'd3-shape'
import { scaleLinear, scaleTime } from 'd3-scale'
import { axisBottom, axisLeft, axisRight } from 'd3-axis'
import { timeParse } from 'd3-time-format'
import { select, selectAll, create } from 'd3-selection'
import { transition } from 'd3-transition'
import { extent, max, min, bisector } from 'd3-array'
import { drag } from 'd3-drag'
import { format } from 'd3-format'
import { forceSimulation, forceLink, forceManyBody, forceCenter, forceCollide  } from 'd3-force'


export default {
	line: line,
	area: area,
	scaleLinear: scaleLinear,
	scaleTime: scaleTime,
	select: select,
	selectAll: selectAll,
	format: format,
	pie: pie,
	arc: arc,
	curveMonotoneX: curveMonotoneX,
	timeParse: timeParse,
	extent: extent,
	min: min,
	max: max,
	bisector: bisector,
	axisBottom: axisBottom,
	axisLeft: axisLeft,
	axisRight: axisRight,
	
	transition: transition,

    forceSimulation,
    forceLink, forceManyBody, forceCenter, forceCollide,
    create,
	drag,
	

}
