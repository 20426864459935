import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 21a7 7 0 100-14 7 7 0 000 14z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit={10}
      />
      <path
        d="M18.412 15.758h-1.667c-.062.204-.12.405-.174.604-.054.199-.1.375-.14.528-.074.023-.15.042-.23.06-.08.016-.176.025-.29.025-.25 0-.438-.044-.566-.132a.432.432 0 01-.191-.379c0-.08.011-.156.034-.23l.076-.255c.04-.153.097-.343.17-.57a83.378 83.378 0 01.8-2.34l.248-.693c.073-.21.136-.377.187-.502.085-.051.217-.1.396-.145.178-.045.356-.068.531-.068.256 0 .481.036.676.107.196.07.322.188.379.353.107.3.224.65.348 1.045a135.629 135.629 0 01.75 2.466c.119.408.224.773.314 1.096a.649.649 0 01-.276.179 1.259 1.259 0 01-.439.068c-.244 0-.415-.043-.514-.128-.1-.085-.172-.218-.218-.4l-.204-.69zm-.825-2.908c-.08.255-.178.546-.294.87-.116.327-.226.659-.328 1h1.216a33.224 33.224 0 00-.294-1.025c-.099-.326-.183-.608-.25-.845h-.05z"
        fill="#222"
      />
      <path
        d="M8 17A7 7 0 108 3a7 7 0 000 14z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit={10}
      />
      <path
        d="M10.172 8.194v4.362a1.865 1.865 0 01-.516.076c-.259 0-.438-.042-.537-.126-.098-.085-.148-.223-.148-.414v-1.556a.788.788 0 01-.16.021c-.058.004-.12.004-.187.004-.164 0-.328-.018-.495-.05a1.94 1.94 0 01-.427-.135l-.017.026c.095.08.173.188.233.33.059.141.088.28.088.422 0 .406-.162.724-.487.951-.324.228-.785.342-1.382.342-.187 0-.373-.015-.563-.046a2.48 2.48 0 01-.516-.14 1.04 1.04 0 01-.376-.245.52.52 0 01-.143-.373.57.57 0 01.13-.389.656.656 0 01.284-.203c.124.112.278.212.46.3.184.088.386.131.605.131.241 0 .43-.053.562-.16a.534.534 0 00.199-.44.584.584 0 00-.16-.427c-.108-.11-.26-.165-.456-.165h-.794a.588.588 0 01-.097-.195.838.838 0 01-.038-.263.871.871 0 01.135-.481h.676c.204 0 .361-.05.473-.149a.527.527 0 00.17-.417.568.568 0 00-.166-.415c-.11-.112-.289-.169-.537-.169a1.091 1.091 0 00-.77.312.825.825 0 01-.312-.227.513.513 0 01-.12-.338c0-.21.12-.38.36-.511.24-.133.576-.199 1.01-.199.258 0 .495.033.709.097.214.066.398.157.554.28.155.12.276.267.364.44.088.172.131.362.131.57 0 .198-.041.375-.126.534a1.254 1.254 0 01-.312.389c.084.022.186.041.303.055a2.935 2.935 0 001.193-.105V8.196h-.423c-.13 0-.219-.038-.27-.114-.05-.076-.077-.19-.077-.341 0-.063.007-.135.021-.22a.872.872 0 01.055-.203h2.435c.232 0 .347.146.347.44 0 .062-.008.138-.026.228-.017.09-.036.16-.058.21h-.801v-.002z"
        fill="#222"
      />
    </svg>
  )
}

export default SvgComponent