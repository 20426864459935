// import styled, {css} from 'styled-components';
import styled, { css } from "styled-components/macro";


export const StyledMenuListWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`

export const StyledListItemWrapper = styled.div`
    /* display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: fit-content;
width: -moz-fit-content;
width: -webkit-fit-content; */
`
