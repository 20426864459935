import { useEffect, useRef, useState, useMemo, Fragment, useContext } from "react";
import { useHistory } from "react-router";
import d3 from "../../importGroups/d3Modules";
import mapLock from "../../assets/mapLock.svg";
import { theme } from "../../Theme";
import { Button, ButtonLink } from "../Button";
import { OverlayBlackScreen } from "../OverlayBlackScreen";
import {__getNetworkGraphLocale, __LOC} from '../../utils/locale';
import {EnvContext} from '../../contexts/EnvContext'
import { PopUp } from "../PopUp";
import "./_style.scss";
const {
  colors: { BROWN, BROWN2, CYAN, GREEN, WHITE, BLACK },
} = theme;

//be sure to pass the kpGaugeIdSufix. Its what makes the gauge appear in the appropriate position.
const NetworkGraph = (props) => {
  const config = {
    width: 1500,
    height: 600,
    margin: { top: 50, right: 50, bottom: 50, left: 50 },
    // tooltipStyles: {
    //   offsetX: 20,
    //   offsetY: 0,
    // },
    // genTooltipHtml: (d) => {
    //   return (
    //     <div>
    //       {['string', 'number'].indexOf(typeof d) !== -1
    //         ? d
    //         : 'unreadable default tooltip'}
    //     </div>
    //   )
    // },
  };
  // const { tooltipStyles } = config
  // const genTooltipHtml = props.genTooltipHtml || config.genTooltipHtml
  const width = props.width || config.width;
  const height = props.height || config.height;
  const marginProp = props.margin;
  const margin = marginProp
    ? {
        top: marginProp.top || config.margin.top,
        bottom: marginProp.bottom || config.margin.bottom,
        left: marginProp.left || config.margin.left,
        right: marginProp.right || config.margin.right,
      }
    : config.margin;
  // const strokeColor = props.strokeColor || greyColor100
  const {
    data,
    id,
    className,
    nodePosConfig: nodePosConfigProp,
    saveChanges: saveChangesProp,
    isEditMode,
    unlockedNodesAreClickable
    // valueKey,
    // colorScale,
  } = props;
  console.log({ NETWORK_GRAPH_DATA: JSON.parse(JSON.stringify(data)) });
  const {activeLang} = useContext(EnvContext)
  const wrapperId = `NetworkGraph${id ? "_" + id : ""}`;
  const wrapperClass = `NetworkGraph ${className || ""}`;

  const nodePosConfig = useRef(nodePosConfigProp || {});

  const unlockedButNotVisited_nextPageNodes = useRef([]);

  

  const [showConfirmPopup, setShowConfirmPopup] = useState(undefined);

  const history = useHistory();

  // const [firstPaintComplete, setFirstPaintComplete] = useState(false)

  // dont rerender if data channges
  //unless we have a compelling reasonn to
  // right now, 'in view mode' can see any situation in which progress data will change
  // while the viewer is on the map
  useEffect(
    () => drawChart(data),
    [
      /*data*/
    ]
  );

  // for rerendering the graph

  // useEffect(() => {
  //   if (firstPaintComplete) updateChartData(valueKey)
  // }, [valueKey])

  // const setColorScale = () => {
  //   let thisValueAry = geojson.features
  //     .map((f) => f.properties[valueKey])
  //     .filter(Boolean)
  //   const minVal = Math.min.apply(Math, thisValueAry)
  //   const maxVal = Math.max.apply(Math, thisValueAry)

  //   const colors = d3.scaleLinear().domain([minVal, maxVal]).range(colorScale)

  //   return colors
  // }

  const drawChart = (data) => {
    //first clear the div
    d3.select("#" + wrapperId + ">svg").remove();

    //then draw

    let svg = d3
      .select("#" + wrapperId)
      .append("svg")
      // Responsive SVG needs these 2 attributes and no width and height attr.
      // .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("height", window.innerHeight)
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom
        }`
      )
      .append("g")
      .attr("id", "vizCanvas")
      .classed("vizCanvas", true)
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // svg
    //   .append("circle")
    //   .attr("cx", 100)
    //   .attr("cy", 100)
    //   .attr("r", 100)
    //   .attr("stroke", "black")
    //   .attr("fill", "#69a3b2");
    //
    // Initialize the links
    const link = svg
      .selectAll("path")
      .data(data.links)
      .join("path")
      .attr("class", "links")

      .style("fill", "none")
      .style("stroke-width", "6");

    // Initialize the nodes

    const node = svg
      .selectAll(".node")
      .data(data.nodes)
      .join("g")
      .attr(
        "class",
        (node) =>
          `node ${node.isImportantChoice ? "choiceNode" : "nextPageNode"}`
      )
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut)
      .on("mousedown", handleMouseDown)
      .call(
        d3
          .drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended)
      );

    d3.selectAll(".choiceNode")
      .style('cursor', d => d.visited === true && unlockedNodesAreClickable ? 'pointer' : 'default')
      .append("circle")
      .attr("class", "nodeOuterCircle")
      .attr("r", 22)
      .attr("fill", (d) => {
        if (d.visited) {
          //update unlocked but not visited next page nodes data
          unlockedButNotVisited_nextPageNodes.current = [
            ...unlockedButNotVisited_nextPageNodes.current.filter((dd) =>
              d.linkedTo.every((ddd) => ddd.id !== dd.id)
            ),
            ...d.linkedTo,
          ];
          return unlockedNodesAreClickable ? GREEN : BROWN;
        } else {
          return BROWN;
        }
      });

    d3.selectAll(".choiceNode")
      .append("circle")
      .attr("class", "nodeInnerCircle")
      .attr("r", 13)
      .attr("fill", (d) =>
        d.visited ? (unlockedNodesAreClickable ? CYAN : WHITE) : BROWN
      );

    let nextPageNodeDims = {
      width: 90,
      height: activeLang.value === 'en' ? 34 : 40,
      br: 8,
    };

    let nextPageNodeAnchor = {
      x: (nextPageNodeDims.width + nextPageNodeDims.br) / 2,
      y: (nextPageNodeDims.height + nextPageNodeDims.br) / 2,
    };

    d3.selectAll(".nextPageNode")
      .append("path")
      .attr("d", () => {
        let { width, height, br } = nextPageNodeDims;
        // let width = 60
        // let height = 30
        // let br = 8; //border radius
        let anchor = `-${nextPageNodeAnchor.x}, -${nextPageNodeAnchor.y}`; //center

        let cornerTopRight = `a${br},${br} 0 0 1 ${br},${br}`;
        let cornerBottomRight = `a${br},${br} 0 0 1 -${br},${br}`;
        let cornerBottomLeft = `a${br},${br} 0 0 1 -${br},-${br}`;
        let cornerTopLeft = `a${br},${br} 0 0 1 ${br},-${br}`;
        return `M ${anchor} h${width} ${cornerTopRight} v${height} ${cornerBottomRight} h-${width} ${cornerBottomLeft} v-${height} ${cornerTopLeft} z`;
      })
      // .attr("height", 10)
      .attr("fill", (d) => (d.visited ? WHITE : BROWN));

    d3.selectAll(".nextPageNode")
      .append("svg:image")
      .attr("xlink:href", mapLock)
      .attr("display", (d) =>
        d.visited ||
        unlockedButNotVisited_nextPageNodes.current.some((dd) => dd.id === d.id)
          ? "none"
          : "block"
      )
      // .attr("x", -nextPageNodeAnchor.x + 15 /* half of the icon width */ )
      // .attr("y", -nextPageNodeAnchor.y + nextPageNodeDims.br )
      // .attr("transform", 'translate(50%, 0)' )
      .attr("x", -20)
      .attr("y", -12);

    d3.selectAll(".nextPageNode")
      .append("foreignObject")
      .attr("x", -nextPageNodeAnchor.x)
      .attr("y", -nextPageNodeAnchor.y + nextPageNodeDims.br)
      .attr("width", nextPageNodeDims.width)
      .attr("height", nextPageNodeDims.height)
      .append("xhtml:div")
      .style("height", "100%")
      .append("div")
      .style("display", "flex")
      .style("align-items", "center")
      .style("height", "100%")
      .append("p")
      .style("width", "100%")
      .style("text-align", "center")
      .style("font-size", "10px")
      .style("line-height", "1.4em")
      .style("word-break", "break-all")
      .style("color", (d) =>
        d.visited === true
          ? BLACK
          : unlockedButNotVisited_nextPageNodes.current.some(
              (dd) => dd.id === d.id
            ) && BROWN2
      )
      .style("display", (d) =>
        d.visited ||
        isEditMode ||
        unlockedButNotVisited_nextPageNodes.current.some((dd) => dd.id === d.id)
          ? "block"
          : "none"
      ) /** if is being rendered in the map creator in tci, show the text irrespective of isVisited */
      .html((d) =>
        d.visited ||
        isEditMode ||
        unlockedButNotVisited_nextPageNodes.current.some((dd) => dd.id === d.id)
          ? __getNetworkGraphLocale(d.id.split('/')[2], d.id.split('/')[3], d.id.split('/')[4], activeLang.value) || d.display
          : ""
      );
    // // .attr("display", d => d.visited ? "none" : "block")

    // Let's list the force we wanna apply on the network
    const simulation = d3
      .forceSimulation(data.nodes) // Force algorithm is applied to data.nodes
      .force(
        "link",
        d3
          .forceLink() // This force provides links between nodes
          .id((d) => d.id) // This provide  the id of a node
          .links(data.links) // and this the list of links
          .distance(80)
      )
      .force("charge", d3.forceManyBody().strength(0)) // This adds repulsion between nodes. Play with the -400 for the repulsion strength
      // .force('collision', d3.forceCollide().radius(30))
      .force("center", d3.forceCenter(width / 2, height / 2)); // This force attracts nodes to the center of the svg area
    // .on("end", ticked);

    d3.selectAll(".links").style("stroke", (d) => {
      
      return d.source.visited === true && d.target.visited === true
        ? WHITE
        : BROWN;
    });
    // const textElements = svg
    //   .append("g")
    //   .selectAll("text")
    //   .data(data.nodes)
    //   .enter()
    //   .append("text")
    //   .text((node) => node.display)
    //   .attr("class", "label")
    //   .attr("dx", -20)
    //   .attr("dy", 5)
    //   .attr("id", (d) => d.id + "_label");

    // // This function is run at each iteration of the force algorithm, updating the nodes position.
    simulation.on("tick", () => {
      //set the 'dx' while setting the node cx
      // so you can simply use d.source.x && d.x in link and textElements resp.
      node.attr("transform", (d) => {
        d.x = nodePosConfig.current[d.id]?.x || d.x;
        d.y = nodePosConfig.current[d.id]?.y || d.y;
        return `translate(${d.x}, ${d.y})`;
      });

      link.attr("d", function (d) {
        // if(d.source.display !== 'B13(ii)_1_1(bg)'){
        // return
        // }
        let x1 = d.source.x;
        let x2 = d.target.x;
        let xDist = Math.abs(x2 - x1);
        let xSmaller = Math.min(x1, x2);
        let xMid = xSmaller + xDist / 2;
        let y1 = d.source.y;
        let y2 = d.target.y;
        let yDist = Math.abs(y2 - y1);
        let ySmaller = Math.min(y1, y2);
        let yMid = ySmaller + yDist / 2;

        // return `M ${x1} ${y1} L ${x2} ${y2}`
        let bRad = 10; //border radius

        let toReturn =
          xDist > yDist //then break line on x
            ? `M ${x1} ${y1} 
             L ${xMid + (xSmaller === x1 ? -1 * bRad : bRad)} ${y1} 
             A ${bRad} ${bRad} 0 0 ${
                (ySmaller === y1 && xSmaller === x2) ||
                (ySmaller === y2 && xSmaller === x1)
                  ? 0
                  : 1
              } ${xMid} ${y1 + (ySmaller === y1 ? bRad : -1 * bRad)}
             L ${xMid} ${y2 + (ySmaller === y1 ? -1 * bRad : bRad)} 
             A ${bRad} ${bRad} 0 0 ${
                (ySmaller === y1 && xSmaller === x2) ||
                (ySmaller === y2 && xSmaller === x1)
                  ? 1
                  : 0
              } ${xMid + (xSmaller === x2 ? -1 * bRad : bRad)} ${y2}
             L ${x2} ${y2}`
            : `M ${x1} ${y1} 
             L ${x1} ${yMid + (ySmaller === y1 ? -1 * bRad : bRad)} 
              A ${bRad} ${bRad} 0 0 ${
                (xSmaller === x1 && ySmaller === y2) ||
                (xSmaller === x2 && ySmaller === y1)
                  ? 1
                  : 0
              } ${x1 + (xSmaller === x1 ? bRad : -1 * bRad)} ${yMid} 
             L ${x2 + (xSmaller === x1 ? -1 * bRad : bRad)} ${yMid} 
              A ${bRad} ${bRad} 0 0 ${
                (xSmaller === x1 && ySmaller === y2) ||
                (xSmaller === x2 && ySmaller === y1)
                  ? 0
                  : 1
              } ${x2} ${yMid + (ySmaller === y2 ? -1 * bRad : bRad)}
             L ${x2} ${y2}`;

        return toReturn;
      });
      // .attr("x1", (d) => d.source.x)
      // .attr("y1", (d) => d.source.y)
      // .attr("x2", (d) => d.target.x)
      // .attr("y2", (d) => d.target.y);

      // textElements
      //   .attr("x", (d) => d.x)
      //   .attr("y", (d) => d.y);
    });

    function dragstarted(event, d) {
      // dont allow drag in 'view' mode
      if (!isEditMode) return;
      if (!event.active) simulation.alphaTarget(0.3).restart();
    }

    function dragged(event, d) {
      // dont allow drag in 'view' mode
      if (!isEditMode) return;
      nodePosConfig.current[d.id] = {
        x: event.x,
        y: event.y,
      };
    }

    function dragended(event, d) {
      // dont allow drag in 'view' mode
      if (!isEditMode) return;
      if (!event.active) simulation.alphaTarget(0);
      saveChangesProp && saveChangesProp(nodePosConfig.current);
    }

    function handleMouseOver(e, d) {
      if (!unlockedNodesAreClickable || !d.isImportantChoice || !d.visited) return;
      d3.select(this)
        .select(".nodeOuterCircle")
        .transition()
        .duration(300)
        .attr("r", 25);

      d3.select(this)
        .select(".nodeInnerCircle")
        .transition()
        .duration(300)
        .attr("r", 16);
    }

    function handleMouseOut(e, d) {
      if (!unlockedNodesAreClickable || !d.isImportantChoice || !d.visited) return;
      d3.select(this)
        .select(".nodeOuterCircle")
        .transition()
        .duration(300)
        .attr("r", 22);

      d3.select(this)
        .select(".nodeInnerCircle")
        .transition()
        .duration(300)
        .attr("r", 13);
    }

    function handleMouseDown(e, d) {
      
      if (!unlockedNodesAreClickable || !d.isImportantChoice || !d.visited) return;
      setShowConfirmPopup(d.id);
      // d3.select(this).select("circle").transition()
      // .duration(750)
      // .attr("r", 12);
    }
  };

  return (
    <Fragment>
      <OverlayBlackScreen show={!!showConfirmPopup === true} />
      {showConfirmPopup && (
        <PopUp closePopUp={() => setShowConfirmPopup(undefined)}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h4 style={{ paddingBottom: "2rem" }} className="h4">
              {__LOC("Are you sure you want to go back to this decision point?")}
            </h4>

            <ButtonLink to={showConfirmPopup}>{`${__LOC('Yes')}!`}</ButtonLink>
          </div>
        </PopUp>
      )}
      <div
        id={wrapperId}
        className={wrapperClass}
        style={{ width: "100vw", height: "100vh", overflowY: "hidden" }}
        onScroll={(e) =>
          props.handleVizCanvasScroll && props.handleVizCanvasScroll()
        }
      />
    </Fragment>
  );
};

export default NetworkGraph;
