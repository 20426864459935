import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.098 4.508c.429-1.096 1.165-1.954 2.213-2.577C9.359 1.31 10.677 1 12.26 1c1.075 0 1.993.125 2.743.381.75.251 1.402.58 1.951.98.55.4 1.025.965 1.435 1.688.405.729.61 1.597.61 2.606v.444c0 .945-.182 1.862-.55 2.75-.368.883-1.16 1.949-2.38 3.189-.689.675-1.183 1.279-1.48 1.819-.29.536-.439 1.148-.439 1.843 0 .294-.107.526-.321.695-.214.169-.512.25-.899.25h-2.226c-.815 0-1.22-.4-1.22-1.2 0-.758.074-1.477.228-2.148a5.136 5.136 0 01.824-1.848c.396-.56.94-1.153 1.63-1.785 1.342-1.158 2.012-2.403 2.012-3.73v-.221c0-.483-.163-.869-.489-1.154-.326-.284-.806-.424-1.434-.424-.69 0-1.211.212-1.556.632-.265.314-.498.535-.703.666-.205.125-.466.159-.792.096l-2.226-.473c-.406-.106-.69-.294-.853-.569-.158-.275-.167-.603-.028-.98zM11.836 24c-.754 0-1.383-.212-1.89-.632-.508-.42-.764-1.013-.764-1.77 0-.382.083-.768.26-1.168.173-.4.48-.72.913-.965a3.017 3.017 0 011.481-.362c.527 0 1.011.12 1.449.362.438.24.736.564.899.965.163.4.242.79.242 1.167 0 .758-.242 1.346-.731 1.77-.49.42-1.109.633-1.859.633z"
        fill="#fff"
        stroke="#222"
        strokeMiterlimit={10}
      />
    </svg>
  )
}

export default SvgComponent