
  export default {
    "scene0": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "X1",
            "hi": "X1",
            "ka": "X1",
            "te": "X1",
            "or": "X1",
            "as": "X1",
            "gu": "X1"
          }
        },
        {
          "valuePath": "blocks.0.props.desc",
          "value": {
            "en": "placeholder desc",
            "hi": "प्लेसहोल्डर डेस्क",
            "ka": "ಪ್ಲೇಸ್‌ಹೋಲ್ಡರ್ ಡೆಸ್ಕ್",
            "te": "placeholder desc",
            "or": "placeholder desc",
            "as": "placeholder desc",
            "gu": "placeholder desc"
          }
        },
        {
          "valuePath": "blocks.0.props.descTexts.0.text",
          "value": {
            "en": "Naveen is an 18-year-old who lives with his mother and elder brother, Roshan, in a basti in Bangalore. His mother works at a garment factory. His brother is an auto driver.",
            "hi": "नवीन 18 साल का लड़का है जो अपनी माँ और बड़े भाई रोशन के साथ बैंगलोर की एक बस्ती में रहता है। उसकी माँ एक गारमेंट फैक्टरी में काम करती है और उसका भाई रिक्शा चलाता है।",
            "ka": "ನವೀನ್ ಬೆಂಗಳೂರಿನ ಬಸ್ತಿಯಲ್ಲಿ ತನ್ನ ತಾಯಿ ಮತ್ತು ಅಣ್ಣ ರೋಶನ್ ಜೊತೆ ವಾಸಿಸುವ 18 ವರ್ಷದ ಯುವಕ. ಅವರ ತಾಯಿ ಗಾರ್ಮೆಂಟ್ಸ್ ಕಾರ್ಖಾನೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾರೆ. ಇವರ ಸಹೋದರ ಆಟೋ ಚಾಲಕ.",
            "te": "నవీన్ తన తల్లి మరియు అన్నయ్య రోషన్‌తో కలిసి బెంగళూరులోని ఒక బస్తీలో ఉంటున్న 18 ఏళ్ల కుర్రాడు. అతని తల్లి గార్మెంట్ ఫ్యాక్టరీలో పని చేస్తుంది. అతని అన్న ఆటో డ్రైవర్.",
            "or": "ନବୀନ ଜଣେ 18 ବର୍ଷ ବୟସ୍କ ଯିଏ ତାଙ୍କ ମା ଏବଂ ବଡ ଭାଇ, ରୋଶନଙ୍କ ସହ ବାଙ୍ଗାଲୋରର ଏକ ବସ୍ତିରେ ରୁହନ୍ତି | ତାଙ୍କ ମା ଏକ ବସ୍ତ୍ର କାରଖାନାରେ କାମ କରନ୍ତି | ତାଙ୍କ ଭାଇ ଜଣେ ଅଟୋ ଡ୍ରାଇଭର |",
            "as": "নবীন এজন 18 বছৰীয়া যুৱক যি তেওঁৰ মাক আৰু ডাঙৰ ভায়েক ৰোশনৰ সৈতে বাংগালোৰৰ এটা বস্তিত বাস কৰে ৷ তেওঁৰ মাতৃয়ে এটা পোছাকৰ কাৰখানাত কাম কৰে ৷ তেওঁৰ ভায়েক এজন অটো ড্ৰাইভাৰ।",
            "gu": "નવીન તેની માતા અને મોટા ભાઈ રોશન સાથે બેંગ્લોરની વસાહતમાં રહે છે અને તે18 વર્ષનો છોકરો છે. તેની માતા કપડાના કારખાનામાં કામ કરે છે. તેનો ભાઈ રિક્ષા ડ્રાઈવર છે."
          }
        },
        {
          "valuePath": "blocks.0.props.descTexts.1.text",
          "value": {
            "en": "He does odd jobs like distributing magazines to make some pocket money. \nHe is popular in the neighbourhood for helping people with small repairs.",
            "hi": "वह पॉकेट मनी कमाने के लिए मैगज़ीन बांटने जैसे छोटे-मोटे काम करता है। \n थोड़ा-बहुत मरम्मत का काम करके लोगों की मदद करने के लिए वह आसपड़ोस में लोकप्रिय था।",
            "ka": "ಅವನು ಸ್ವಲ್ಪ ಪೊಕೆಟ್ ಮನಿ ಮಾಡಲು ಮ್ಯಾಗಜೀನ್‌ಗಳನ್ನು ವಿತರಿಸುವಂತಹ ಕೆಲಸಗಳನ್ನು ಮಾಡುತ್ತಾನೆ.\n ಸಣ್ಣ ರಿಪೇರಿಗಳೊಂದಿಗೆ ಜನರಿಗೆ ಸಹಾಯ ಮಾಡಲು ಅವನು ನೆರೆಹೊರೆಯಲ್ಲಿ ಜನಪ್ರಿಯರಾಗಿದ್ದಾನೆ.",
            "te": "పోకెట్ మనీ సంపాదించడానికి పత్రికలు పంచడం వంటి చిన్న చిన్న పనులు చేస్తుంటాడు. అతను చిన్న రిపేర్లలో ప్రజలకు సహాయం చేయడం వల్ల చుట్టు పక్కల పాపులర్ అయ్యాడు.",
            "or": "ସେ କିଛି ପକେଟରେ ଟଙ୍କା ରୋଜଗାର କରିବା ପାଇଁ ମାଗାଜିନ ବଣ୍ଟନ କରିବା ଭଳି ସାମୟିକ କାର୍ଯ୍ୟ କରନ୍ତି |\n ଛୋଟ ମରାମତି କାର୍ଯ୍ୟରେ ସାହାଯ୍ୟ କରିବା ପାଇଁ ସେ ପଡ଼ୋଶୀରେ ଲୋକପ୍ରିୟ |",
            "as": "তেওঁ কিছু জেপ খৰচ উলিয়াবলৈ আলোচনী বিতৰণ কৰাৰ দৰে অদ্ভুত কাম কৰে। সৰু-সুৰা মেৰামতিৰ কামত লোকসকলক সহায় কৰাৰ বাবে তেওঁ চুবুৰীয়াৰ মাজত জনপ্ৰিয়।",
            "gu": "તે કેટલાક પોતાના ખર્ચા માટે સામયિકોનું વિતરણ કરવા જેવી અલગ અલગ નોકરીઓ કરે છે.\nતે કઈક નાનું બગલેડું સરખું કરવામાં અને લોકોને મદદ કરવા માટે તે પડોશમાં લોકપ્રિય છે."
          }
        },
        {
          "valuePath": "blocks.0.props.descTexts.2.text",
          "value": {
            "en": "He find it hard to study and has failed the 12th standard once. He doesn't go to school and will appear for the exams as a private candidate. He has been taking extra classes to pass the exams.",
            "hi": "लेकिन उसे पढ़ाई करना बहुत मुश्किल लगता था और वह 12वीं कक्षा में एक बार फेल भी हो चूका है। वह स्कूल में नहीं जाता और प्राइवेट उम्मीदवार के तौर पर परीक्षा में बैठेगा। परीक्षा में पास होने के लिए वह एक्स्ट्रा क्लासेस ले रहा है।",
            "ka": "ಓದಲು ಕಷ್ಟಪಟ್ಟು 12ನೇ ತರಗತಿಯಲ್ಲಿ ಒಮ್ಮೆ ಫೇಲ್ ಆಗಿದ್ದಾನೆ. ಅವನು ಶಾಲೆಗೆ ಹೋಗುವುದಿಲ್ಲ ಮತ್ತು ಖಾಸಗಿ ಅಭ್ಯರ್ಥಿಯಾಗಿ ಪರೀಕ್ಷೆಗೆ ಹಾಜರಾಗುತ್ತಾನೆ. ಪರೀಕ್ಷೆಯಲ್ಲಿ ಉತ್ತೀರ್ಣರಾಗಲು ಹೆಚ್ಚುವರಿ ತರಗತಿಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಿದ್ದಾನೆ.",
            "te": "చదువు చాలా కష్టంగా ఉంది అనుకున్నాడు మరియు ఒకసారి 12వ తరగతి ఫెయిల్ అయ్యాడు.అతను పాఠశాలకు వెళ్లడు మరియు ఒక ప్రైవేట్ అభ్యర్థి గా పరీక్షలు రాస్తాడు. పరీక్షల్లో పాస్ అవ్వడానికి అదనపు తరగతులు తీసుకుంటున్నాడు.",
            "or": "ସେ ଅଧ୍ୟୟନ କରିବା କଷ୍ଟକର ପାଆନ୍ତି ଏବଂ ଥରେ ଦ୍ୱାଦଶ ଶ୍ରେଣୀ ଫେଲ୍‌ ହୋଇଛି | ସେ ବିଦ୍ୟାଳୟକୁ ଯାଆନ୍ତି ନାହିଁ ଏବଂ ଏକ ବେସରକାରୀ ପ୍ରାର୍ଥୀ ଭାବରେ ପରୀକ୍ଷା ପାଇଁ ହାଜର ହେବେ | ସେ ପରୀକ୍ଷାରେ ଉତ୍ତୀର୍ଣ୍ଣ ହେବା ପାଇଁ ଅତିରିକ୍ତ କ୍ଲାସ୍ ନେଉଛନ୍ତି |",
            "as": "তেওঁ পঢ়া শুনা কৰিবলৈ টান পায় আৰু এবাৰ দ্বাদশ শ্ৰেণীত বিফল হৈছিল। তেওঁ বিদ্যালয়লৈ নাযায় আৰু ব্যক্তিগত প্ৰাৰ্থী হিচাপে পৰীক্ষাত অৱতীৰ্ণ হ'ব। তেওঁ পৰীক্ষাত উত্তীৰ্ণ হ'বলৈ অতিৰিক্ত শ্ৰেণী লৈ আছে।",
            "gu": "તેને ભણવામાં ખૂબ જ અઘરું પડે છે અને એકવાર તે 12મા ધોરણમાં નાપાસ થયો. તે શાળાએ જતો નથી અને તેને પરીક્ષા માટે ખાનગી ઉમેદવાર તરીકે આપવી પડશે. તે પરીક્ષા પાસ કરવા માટે વધારાના ટ્યુસન કરે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.descTexts.3.text",
          "value": {
            "en": "Naveen is eager to start earning as he aspires for a better standard of living for himself and his family.",
            "hi": "नवीन जल्दी से पैसा कमाना शुरू चाहता है क्योंकि वह अपने और अपने परिवार के लिए बेहतर जिंदगी चाहता है।",
            "ka": "ನವೀನ್ ತನ್ನ ಮತ್ತು ತನ್ನ ಕುಟುಂಬಕ್ಕೆ ಉತ್ತಮ ಜೀವನಮಟ್ಟಕ್ಕಾಗಿ ಹಾತೊರೆಯುತ್ತಿರುವುದರಿಂದ ಸಂಪಾದಿಸಲು ಪ್ರಾರಂಭಿಸಲು ಉತ್ಸುಕನಾಗಿದ್ದಾನೆ.",
            "te": "నవీన్ తను మరియు తన కుటుంబం మంచిగా బ్రతకడానికి తొందరగా సంపాదించాలి అనుకుంటున్నాడు.",
            "or": "ନବୀନ ନିଜ ପାଇଁ ଏବଂ ତାଙ୍କ ପରିବାର ପାଇଁ ଏକ ଉନ୍ନତ ମାନର ଜୀବନଯାପନ କରିବାକୁ ଇଚ୍ଛା କରୁଥିବାରୁ ରୋଜଗାର ଆରମ୍ଭ କରିବାକୁ ଆଗ୍ରହୀ |",
            "as": "নবীনে উপাৰ্জন আৰম্ভ কৰিবলৈ আগ্ৰহী কিয়নো তেওঁ নিজৰ আৰু তেওঁৰ পৰিয়ালৰ বাবে এক উন্নত মানদণ্ডৰ জীৱনধাৰণৰ আকাংক্ষা কৰে।",
            "gu": "નવીન પૈસા કમાવવાનું શરૂ કરવામાં ઉત્સુક છે કારણ કે તે પોતાના અને તેના પરિવાર માટે જીવનધોરણ વધુ સરળ બનાવા માંગે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "X1_3_1//",
            "hi": "X1_3_1//",
            "ka": "X1_3_1//",
            "te": "X1_3_1//",
            "or": "X1_3_1//",
            "as": "X1_3_1//",
            "gu": "X1_3_1//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Naveen is fixing his friend’s mixer grinder at home when his mother walks in. She is coming back from the market.",
            "hi": "नवीन घर पर अपने दोस्त का मिक्सर ग्राइंडर रिपेअर कर रहा होता है तब उसकी माँ वहाँ आती है। वह अभी-अभी मार्केट से वापस लौटी है।",
            "ka": "ನವೀನ್ ತನ್ನ ಸ್ನೇಹಿತನ ಮಿಕ್ಸರ್ ಗ್ರೈಂಡರ್ ಅನ್ನು ಮನೆಯಲ್ಲಿ ಸರಿಪಡಿಸುತ್ತಿರುವಾಗ ಅವನ ತಾಯಿ ಒಳಗೆ ಹೋಗುತ್ತಾಳೆ. ಅವಳು ಮಾರುಕಟ್ಟೆಯಿಂದ ಹಿಂತಿರುಗುತ್ತಾಳೆ.",
            "te": "అతని తల్లి ఇంటికి వచ్చేవరకి నవీన్ తన మిత్రుని మిక్సర్ గ్రైండర్‌ని ఇంట్లో సరి చేస్తున్నాడు.ఆమె మార్కెట్ నుండి తిరిగి వస్తోంది.",
            "or": "ନବୀନ ତାଙ୍କ ବନ୍ଧୁଙ୍କ ମିକ୍ସର୍ ଗ୍ରାଇଣ୍ଡରକୁ ଠିକ୍ କରୁଛନ୍ତି ଯେତେବେଳେ ତାଙ୍କ ମାଆ ଭିତରକୁ ଯାଆନ୍ତି | ସେ ବଜାରରୁ ଫେରୁଛନ୍ତି |",
            "as": "নবীনে ঘৰত তেওঁৰ বন্ধুৰ মিক্সাৰ গ্ৰাইণ্ডাৰ ঠিক কৰি আছে যেতিয়া তেওঁৰ মাক সোমাই আহে। তেখেত বজাৰৰ পৰা উভতি আহিছে।",
            "gu": "નવીન તેના મિત્રનું મિક્સર ગ્રાઇન્ડર ઘરે ઠીક કરી રહ્યો છે જ્યારે તેની માતા  બજારમાંથી પાછી આવી અંદર જઇ રહી છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Mother",
            "hi": "माँ",
            "ka": "ತಾಯಿ",
            "te": "తల్లి",
            "or": "ମାତା",
            "as": "মাতৃ",
            "gu": "માતા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Oh God, this is getting more difficult to manage by the day. Everything has become so expensive…",
            "hi": "हे भगवान, घर चलाना दिन-ब-दिन कठिन होता जा रहा है। सब कुछ कितना महंगा हो गया है...",
            "ka": "ಓ ದೇವರೇ, ದಿನದಿಂದ ದಿನಕ್ಕೆ ಇದನ್ನು ನಿರ್ವಹಿಸುವುದು ಹೆಚ್ಚು ಕಷ್ಟಕರವಾಗುತ್ತಿದೆ. ಎಲ್ಲವೂ ತುಂಬಾ ದುಬಾರಿಯಾಗಿದೆ ...",
            "te": "అయ్యో దేవుడా,దీన్ని నిర్వహించడం రోజురోజుకు మరింత కష్టమవుతోంది. ప్రతిదీ చాలా ఖరీదైనదిగా మారింది ....",
            "or": "ହେ ପ୍ରଭୁ, ଦିନକୁ ଦିନ ଏହାକୁ ପରିଚାଳନା କରିବା ଅଧିକ କଷ୍ଟକର ହେଉଛି | ସବୁକିଛି ଏତେ ମହଙ୍ଗା ହୋଇଯାଇଛି…",
            "as": "হে ভগৱান, দিনক দিনে চলাটো অধিক কঠিন হৈ পৰিছে। সকলোবোৰ ইমান ব্যয়বহুল হৈ পৰিছে…",
            "gu": "અરે ભગવાન, આ બધુ સંભાળવું દિવસે ને દિવસે કઠિન બની રહ્યું છે. બધું મોંઘુ થઈ જાય છે..."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Don’t worry, Amma, you won’t have to work so hard once I get a job.",
            "hi": "चिंता मत करो, अम्मा, एक बार मुझे जॉब मिल जाए तो तुम्हें इतनी मेहनत नहीं करनी पड़ेगी।",
            "ka": "ಚಿಂತಿಸಬೇಡ, ಅಮ್ಮಾ, ನನಗೆ ಕೆಲಸ ಸಿಕ್ಕಿದ ಮೇಲೆ ನೀನು ಕಷ್ಟಪಡಬೇಕಾಗಿಲ್ಲ.",
            "te": "భయపడకు అమ్మా,నాకు ఒక్కసారి ఉద్యోగం రాగానే నువ్వు ఎక్కువ కష్టపడాల్సిన అవసరం లేదు.",
            "or": "ବ୍ୟସ୍ତ ହୁଅନାହିଁ, ଆମ୍ମା, ଥରେ ମୁଁ ଚାକିରି ପାଇବା ପରେ ତୁମକୁ ଏତେ ପରିଶ୍ରମ କରିବାକୁ ପଡ଼ିବ ନାହିଁ |",
            "as": "চিন্তা নকৰিবা, মা, মই চাকৰি পোৱাৰ পিছত তুমি ইমান কঠোৰ পৰিশ্ৰম কৰিব নালাগিব।",
            "gu": "તું ચિંતા ના કાર, અમ્મા,જ્યારે મને  નોકરી મળી જશે પછી તારે આટલી મહેનત નહીં કરવી પડે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "X1_3_2////",
            "hi": "X1_3_2////",
            "ka": "X1_3_2////",
            "te": "X1_3_2////",
            "or": "X1_3_2////",
            "as": "X1_3_2////",
            "gu": "X1_3_2////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "And when will that be? You’ve been saying that ever since you failed your 12th. Your brother and I can't keep the home running on such small salaries.",
            "hi": "और यह कब होगा? जब से तुम 12वीं में फेल हुए हो तब से यहीं कह रहे हो। इतनी कम सैलरी में तुम्हारा भाई और मैं घर नहीं चला सकते।",
            "ka": "ಮತ್ತು ಅದು ಯಾವಾಗ ಇರುತ್ತದೆ? ನಿನ್ನ 12 ನೇ ತರಗತಿಯಲ್ಲಿ ನೀನು ವಿಫಲವಾದಾಗಿನಿಂದ ನೀನು ಅದನ್ನು ಹೇಳುತ್ತಿದ್ದೀಯ. ನಿನ್ನ ಸಹೋದರ ಮತ್ತು ನಾನು ಇಷ್ಟು ಕಡಿಮೆ ಸಂಬಳದಲ್ಲಿ ಮನೆಯನ್ನು ನಡೆಸಲು ಸಾಧ್ಯವಿಲ್ಲ.",
            "te": "మరియు అది ఎప్పుడు అవుతుంది? నువ్వు నీ 12వ తరగతి ఫెయిల్ అయినప్పటి నుండి చెప్తున్నావు.మీ అన్న, నేనూ ఇంత చిన్న జీతాలతో ఇంటిని నడిపించలేం.",
            "or": "ଏବଂ ତାହା କେବେ ହେବ? ତୁମେ ଏହା କହି ଆସୁଛ ଯେବେଠାରୁ ତୁମେ ତୁମର ଦ୍ୱାଦଶ ଫେଲ୍‌ ହେଇଛ | ତୁମ ଭାଇ ଏବଂ ମୁଁ ଏତେ ଛୋଟ ଦରମା ରେ ଘର ଚଳାଇ ପାରିବୁ ନାହିଁ |",
            "as": "আৰু সেইটো কেতিয়া হ'ব? তুমি দ্বাদশ শ্ৰেণীত বিফল হোৱাৰ পৰাই সেই কথাটো কৈ আহিছা ৷ তোমাৰ ভাইটি আৰু মই ইমান কম দৰমহাত ঘৰটো চলাই ৰাখিব নোৱাৰোঁ।",
            "gu": "અને આ ક્યારે થશે? તું 12 માં ધોરણમાં નિષ્ફળ ગયો ત્યારથી તું આ કહી રહ્યો છે. હું અને તારો ભાઈ આટલા ઓછા પગારમાં ઘર ચલાવી ન શકીએ."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "I told you, I'm trying. Just give me some time to find something I like doing.",
            "hi": "मैंने कहा ना कि मैं कोशिश कर रहा हूँ। मुझे थोड़ा समय दो ताकि मैं ऐसा काम ढूंढ सकू जो मुझे पसंद हो।",
            "ka": "ನಾನು ನಿಮಗೆ ಹೇಳಿದೆ, ನಾನು ಪ್ರಯತ್ನಿಸುತ್ತಿದ್ದೇನೆ. ನಾನು ಮಾಡಲು ಇಷ್ಟಪಡುವದನ್ನು ಕಂಡುಹಿಡಿಯಲು ನನಗೆ ಸ್ವಲ್ಪ ಸಮಯ ನೀಡಿ.",
            "te": "నేను మీకు చెప్పాను,నేను ప్రయత్నిస్తున్నాను.నేను చేయాలనుకుంటున్న పని దొరకడానికి నాకు కొంత సమయం ఇవ్వండి.",
            "or": "ମୁଁ ତୁମକୁ କହିଛି, ମୁଁ ଚେଷ୍ଟା କରୁଛି | ମୁଁ କରିବାକୁ ପସନ୍ଦ କରୁଥିବା କିଛି ଖୋଜିବା ପାଇଁ ମୋତେ କେବଳ କିଛି ସମୟ ଦିଅ |",
            "as": "মই তোমাক কৈছো দেখোন, মই চেষ্টা কৰি আছোঁ ৷ মই ভাল পোৱা কিবা এটা বিচাৰিবলৈ মোক মাত্ৰ অলপ সময় দিয়া।",
            "gu": "મે કહ્યુંને, હું મહેનત કરું છું, મને થોડો સમય આપો કઈક સારું કરવા માટે જે મને ગમે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Naveen, your brother, and I are not waiting to do the job we like. We have to take whatever  we get. If you want to put food on your plate, you need to be ready to take up whatever comes your way.",
            "hi": "नवीन, तुम्हारे भाई ने और मैंने अपनी पसंद का जॉब मिलने तक इंतजार नहीं किया। हमें जो जॉब मिला वह हमने कर लिया। अगर तुम्हें अपनी थाली में खाना चाहिए तो जो भी काम मिले वह काम करने के लिए तैयार रहना चाहिए।",
            "ka": "ನವೀನ್, ನಿನ್ನ ಸಹೋದರ ಮತ್ತು ನಾನು ನಮಗೆ ಇಷ್ಟವಾದ ಕೆಲಸವನ್ನು ಮಾಡಲು ಕಾಯುತ್ತಿಲ್ಲ. ನಮಗೆ ಏನು ಸಿಕ್ಕಿತೋ ಅದನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕು. ನಿನ್ನ ತಟ್ಟೆಯಲ್ಲಿ ಆಹಾರವನ್ನು ಹಾಕಲು ನೀನು ಬಯಸಿದರೆ, ನಿನ್ನ ದಾರಿಯಲ್ಲಿ ಬರುವ ಎಲ್ಲವನ್ನೂ ತೆಗೆದುಕೊಳ್ಳಲು ನೀನು ಸಿದ್ಧರಾಗಿರಬೇಕು.",
            "te": "నవీన్,మీ అన్నయ్య, నేనూ మాకు నచ్చిన ఉద్యోగం చేయడానికి ఎదురుచూడటం లేదు. ఏది దొరికితే అది తీసుకోవాలి. నీకు నీ ప్లేట్‌లో ఆహారం రావాలనుకుంటే, ఏది వచ్చినా తీసుకోవడానికి నువ్వు సిద్ధంగా ఉండాలి.",
            "or": "ନବୀନ, ତୁମ ଭାଇ, ଏବଂ ମୁଁ ପସନ୍ଦ କରୁଥିବା କାମ କରିବାକୁ ଅପେକ୍ଷା କରୁନାହୁଁ | ଆମକୁ ଯାହା ମିଳିବ ତାହା ଗ୍ରହଣ କରିବାକୁ ପଡ଼ିବ | ଯଦି ତୁମେ ତୁମର ଥାଳିରେ ଖାଦ୍ୟ ରଖିବାକୁ ଚାହୁଁଛ, ତୁମ ରାସ୍ତାରେ ଯାହା ଆସେ ତାହା ଗ୍ରହଣ କରିବାକୁ ତୁମେ ପ୍ରସ୍ତୁତ ହେବା ଆବଶ୍ୟକ |",
            "as": "নবীন, তোমাৰ ভাইটি আৰু মই আমাৰ পছন্দৰ কামটো কৰিবলৈ অপেক্ষা কৰা নাই। আমি যি পাওঁ সেয়াই ল'ব লাগিব। যদি তুমি তোমাৰ প্লেটত খাদ্য বিচাৰা, তুমি তোমাৰ পথত যি আহে সেয়া গ্ৰহণ কৰিবলৈ সাজু থাকিব লাগিব।",
            "gu": "નવીન, તારો ભાઈ અને હું અમને ગમતું કામ કરવા માટે રાહ જોઈ રહ્યા નથી. જે મળે તે લેવું જ રહ્યું. જો તમે તમારી પ્લેટમાં ખોરાક મૂકવા માંગતા હો, તો તમારે તમારી રીતે જે આવે તે લેવા માટે તમારે તૈયાર રહેવાની જરૂર છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "But why am I bothering telling you this. You only want to talk about big things.",
            "hi": "लेकिन मैं यह सब तुम्हें क्यों बता रही हूँ। तुम्हें तो बस बड़ी बड़ी बातें करनी हैं।",
            "ka": "ಆದರೆ ಇದನ್ನು ಹೇಳಲು ನಾನು ಯಾಕೆ ತಲೆಕೆಡಿಸಿಕೊಳ್ಳುತ್ತಿದ್ದೇನೆ. ನೀನು ದೊಡ್ಡ ವಿಷಯಗಳ ಬಗ್ಗೆ ಮಾತ್ರ ಮಾತನಾಡಲು ಬಯಸುತ್ತೀಯ.",
            "te": "అయితే ఈ విషయం నేను నీకు చెప్పి ఎందుకు ఇబ్బంది పెడుతున్నాను.\n నువ్వు పెద్ద విషయాల గురించి మాత్రమే మాట్లాడాలనుకుంటున్నావు.",
            "or": "କିନ୍ତୁ ମୁଁ ତୁମକୁ ଏହା କହିବାକୁ କାହିକିଁ ବ୍ଯସ୍ତ ? ତୁମେ କେବଳ ବଡ ଜିନିଷ ବିଷୟରେ କହିବାକୁ ଚାହୁଁଛ |",
            "as": "কিন্তু মই তোমাক এইবোৰ কথা কিয় কৈ আছোঁ। তুমি কেৱল ডাঙৰ বস্তুৰ বিষয়ে কথা পাতিব বিচাৰা।",
            "gu": "પણ તને આ કહેવાની શું જરૂર છે. તારે માત્ર મોટુ વિચારવું જ છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "Naveen gets up without saying anything and leaves.",
            "hi": "नवीन बिना कुछ कहे उठा और बाहर निकल गया।",
            "ka": "ನವೀನ್ ಏನೂ ಮಾತನಾಡದೆ ಎದ್ದು ಹೊರಟು ಹೋದ.",
            "te": "నవీన్ ఏమీ మాట్లాడకుండా లేచి వెళ్ళిపోయాడు.",
            "or": "ନବୀନ କିଛି ନ କହି ଉଠନ୍ତି ଏବଂ ଚାଲିଯାଆନ୍ତି |",
            "as": "নবীন একো নোকোৱাকৈ উঠি গুচি যায়।",
            "gu": "નવીન એક પણ શબ્દ બોલ્યા વગર ઉભો થયો અને ચાલ્યો ગયો."
          }
        }
      ]
    },
    "scene1": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "X2_1_1",
            "hi": "X2_1_1",
            "ka": "X2_1_1",
            "te": "X2_1_1",
            "or": "X2_1_1",
            "as": "X2_1_1",
            "gu": "X2_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen spends a lot of time with his friends and is part of a Hip hop dance group. They participate in dance festivals and travel within the city for performances.",
            "hi": "नवीन अपने दोस्तों के साथ काफी समय बिताता है और हिप-हॉप डान्स ग्रुप का हिस्सा है। वे डान्स फेस्टिवल में भाग लेते हैं और परफोर्म करने के लिए शहर में जगह-जगह पर जाते हैं।",
            "ka": "ನವೀನ್ ತನ್ನ ಸ್ನೇಹಿತರೊಂದಿಗೆ ಬಹಳಷ್ಟು ಸಮಯವನ್ನು ಕಳೆಯುತ್ತಾನೆ ಮತ್ತು ಹಿಪ್ ಹಾಪ್ ನೃತ್ಯ ಗುಂಪಿನ ಭಾಗವಾಗಿದ್ದಾನೆ. ಅವರು ನೃತ್ಯ ಉತ್ಸವಗಳಲ್ಲಿ ಭಾಗವಹಿಸುತ್ತಾರೆ ಮತ್ತು ಪ್ರದರ್ಶನಗಳಿಗಾಗಿ ನಗರದೊಳಗೆ ಪ್ರಯಾಣಿಸುತ್ತಾರೆ.",
            "te": "నవీన్ తన మిత్రులతో ఎక్కువసేపు ఉంటాడు మరియు హిప్-హాప్ డ్యాన్స్ బృందంలో సభ్యుడు. వారు డ్యాన్స్ ఫెస్టివల్లో పాల్గొంటారు మరియు ప్రదర్శన కోసం నగరంలో ప్రయాణిస్తారు.",
            "or": "ନବୀନ ତାଙ୍କ ସାଙ୍ଗମାନଙ୍କ ସହିତ ବହୁତ ସମୟ ଅତିବାହିତ କରନ୍ତି ଏବଂ ଏକ ହିପ୍ ହପ୍ ଡ୍ୟାନ୍ସ ଗ୍ରୁପ୍‌ ର ଏକ ଅଂଶ | ସେମାନେ ନୃତ୍ୟ ମହୋତ୍ସବଗୁଡିକରେ ଅଂଶଗ୍ରହଣ କରନ୍ତି ଏବଂ ପ୍ରଦର୍ଶନ ପାଇଁ ସହର ମଧ୍ୟରେ ଯାତ୍ରା କରନ୍ତି |",
            "as": "নবীনে তেওঁৰ বন্ধুবৰ্গৰ সৈতে যথেষ্ট সময় অতিবাহিত কৰে আৰু তেওঁ এটা হিপ-হপ নৃত্য গোটৰ অংশ। তেওঁলোকে নৃত্য উৎসৱত অংশগ্ৰহণ কৰে আৰু প্ৰদৰ্শনৰ বাবে চহৰৰ ভিতৰত ভ্ৰমণ কৰে।",
            "gu": "નવીન તેના મિત્રો સાથે ઘણો સમય વિતાવે છે અને હિપ હોપ બેન્ડનો ભાગ છે. તેઓ નૃત્ય ઉત્સવોમાં ભાગ લે છે અને પ્રદર્શન માટે શહેરની મુલાકાત લે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "X2_1_2//",
            "hi": "X2_1_2//",
            "ka": "X2_1_2//",
            "te": "X2_1_2//",
            "or": "X2_1_2//",
            "as": "X2_1_2//",
            "gu": "X2_1_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Zaid",
            "hi": "झाएद",
            "ka": "ಝೈದ್",
            "te": "జైద్",
            "or": "ଜାଏଦ୍‌",
            "as": "জাইদ",
            "gu": "ઝાઇદ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Omi",
            "hi": "ओमी",
            "ka": "ಓಮಿ",
            "te": "ఓమి",
            "or": "ଓମି",
            "as": "অমি",
            "gu": "ઓમી"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Did you hear how much the prize money is for the street dance festival?",
            "hi": "स्ट्रीट डान्स फेस्टिवल के इनाम की राशी कितनी है तुम्हें पता है?",
            "ka": "ಸ್ಟ್ರೀಟ್ ಡ್ಯಾನ್ಸ್ ಫೆಸ್ಟಿವಲ್ ಗೆ ಬಹುಮಾನದ ಹಣ ಎಷ್ಟು ಅಂತ ಕೇಳಿದ್ದೀರಾ?",
            "te": "స్ట్రీట్ డ్యాన్స్ ఫెస్టివల్ నగదు బహుమతి ఎంతో విన్నారా?",
            "or": "ତୁମେ ଶୁଣିଛ ଷ୍ଟ୍ରିଟ୍ ଡ୍ୟାନ୍ସ ଫେଷ୍ଟିଭାଲ୍ ପାଇଁ ପୁରସ୍କାର ରାଶି କେତେ ?",
            "as": "তুমি শুনিছিলানে যে ৰাস্তাৰ নৃত্য উৎসৱৰ বাবে পুৰষ্কাৰৰ ধন কিমান?",
            "gu": "શું તમે સાંભળ્યું છે કે શેરી ડાન્સ પ્રોગ્રામ માટે કેટલા ઈનામની રકમ હોય છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Rs. 50,000 for the winners, Rs. 10,000 for the runners-up!",
            "hi": "जीतनेवाले के लिए रु. 50,000, और दुसरे नंबर पर आनेवाले के लिए रु. 10,000!",
            "ka": "ವಿಜೇತರಿಗೆ 50,000 ರೂ. ರನ್ನರ್ಸ್ ಅಪ್ ಗೆ 10,000!",
            "te": "రూ. విజేతలకు 50,000, రూ. రన్నర్స్-అప్‌ కి 10,000!",
            "or": "Rs. 50,000 ଟଙ୍କା ବିଜେତାମାନଙ୍କ ପାଇଁ, Rs. 10,000 ରନର୍ସ ଅପ୍ ପାଇଁ !",
            "as": "বিজয়ীসকলৰ বাবে 50,000 টকা, ৰানাৰ্ছ-আপৰ বাবে 10,000 টকা!",
            "gu": "વિજેતાઓને રૂ. 50,000 અને ભાગ લેવો અને બીજા નંબર આવે તો તેના માટે રૂ. 10,000!"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "And the dance-off is sponsored by Blue Bull. Imagine the publicity our group will get if we win this!",
            "hi": "और इस डान्स इवेंट के प्रायोजक है ब्लू बुल। जरा सोचो कि अगर हम जीत जाते हैं तो हमारे ग्रुप को कितनी पब्लिसिटी मिलेगी!",
            "ka": "ಮತ್ತು ನೃತ್ಯ-ಆಫ್ ಅನ್ನು ಬ್ಲೂ ಬುಲ್ ಪ್ರಾಯೋಜಿಸಿದೆ. ನಾವು ಇದನ್ನು ಗೆದ್ದರೆ ನಮ್ಮ ಗುಂಪಿಗೆ ಸಿಗುವ ಪ್ರಚಾರವನ್ನು ಊಹಿಸಿ!",
            "te": "మరియు డ్యాన్స్-ఆఫ్ బ్లూ బుల్ ద్వారా స్పాన్సర్ చేయబడింది. మనం ఇందులో గెలిస్తే మన బృందానికి ఎంత ప్రచారం వస్తుందో ఊహించండి!",
            "or": "ଏବଂ ଡ୍ୟାନ୍ସ- ଅଫ୍ ବ୍ଲ୍ୟୁ ବୁଲ୍‌ ଦ୍ୱାରା ପ୍ରାୟୋଜିତ | କଳ୍ପନା କର ଯଦି ଆମ ଗ୍ରୁପ୍‍ ଏହା ଜିତେ କେତେ ପ୍ରଚାର ପାଇବ !",
            "as": "আৰু ডান্স-অফটো ব্লু বুলৰ দ্বাৰা পৃষ্ঠপোষকতা কৰা হৈছে। কল্পনা কৰা যে যদি আমি এইটো জিকিব পাৰো তেন্তে আমাৰ গোটে কিমান প্ৰচাৰ পাব!",
            "gu": "આ નૃત્ય બ્લુ બુલ દ્વારા પ્રાયોજિત છે. જો આપણે આ જીતીશું તો આપણી ટીમને કેટલી જાહેરાતો મળશે તેની કલ્પના કરો!"
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "X2_1_3////",
            "hi": "X2_1_3////",
            "ka": "X2_1_3////",
            "te": "X2_1_3////",
            "or": "X2_1_3////",
            "as": "X2_1_3////",
            "gu": "X2_1_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Then what are we waiting for? Let’s start practising for this!",
            "hi": "तो हम इंतजार किस बात का कर रहे हैं? चलो इसके लिए प्रैक्टिस करना शुरू करते हैं!",
            "ka": "ಹಾಗಾದರೆ ನಾವು ಯಾವುದಕ್ಕಾಗಿ ಕಾಯುತ್ತಿದ್ದೇವೆ? ಇದಕ್ಕಾಗಿ ಅಭ್ಯಾಸವನ್ನು ಪ್ರಾರಂಭಿಸೋಣ!",
            "te": "ఇంకా ఎందుకు వేచి ఉన్నాం? దీని కోసం సాధన మొదలు పెడదాం!",
            "or": "ତେବେ ଆମେ କ’ଣ ଅପେକ୍ଷା କରିଛୁ ? ଚାଲ ଏଥିପାଇଁ ଅଭ୍ୟାସ ଆରମ୍ଭ କରିବା !",
            "as": "তেন্তে আমি কিহৰ বাবে অপেক্ষা কৰি আছোঁ? ইয়াৰ বাবে অনুশীলন আৰম্ভ কৰোঁ আহা!",
            "gu": "તો આપણે રાહ જોઈ રહ્યા છીએ? ચાલો, શરુ કરીએ!"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "But bro, don’t you have your exams coming up? Planning to fail again?",
            "hi": "लेकिन भाई, तुम्हारी परीक्षा भी तो नजदीक आ रही है? क्या फिर से फेल होने का इरादा है?",
            "ka": "ಆದರೆ ಸಹೋದರ, ನಿನ್ನ ಪರೀಕ್ಷೆಗಳು ಬರುತ್ತಿಲ್ಲವೇ? ಮತ್ತೆ ವಿಫಲಗೊಳ್ಳಲು ಯೋಜಿಸುತ್ತಿರುವಿಯಾ?",
            "te": "కానీ తమ్ముడు, నీకు పరీక్షలు ఉన్నాయి కదా? మళ్లీ ఫెయిల్ అవ్వాలని అనుకుంటున్నావా?",
            "or": "କିନ୍ତୁ ଭାଇ, ତୁମର ପରୀକ୍ଷା ଆସୁନାହିଁ କି? ପୁଣିଥରେ ବିଫଳ ହେବାକୁ ଯୋଜନା କରୁଛନ୍ତି?",
            "as": "কিন্তু ভাই, তোমাৰ পৰীক্ষা আহি থকা নাই জানো? পুনৰ বিফল হোৱাৰ পৰিকল্পনা কৰিছা নেকি?",
            "gu": "પણ ભાઈ, તારી પરીક્ષા નજદીક આવી રહી? શું તારે ફરીથી નપાસ થવું છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Shut up, Zaid! Passing exams is not everything. Look at me! I am a 10th fail, but I earn nearly 20,000 a month as a delivery boy.",
            "hi": "चुप कर, जाएद! परीक्षा में पास होना ही सब कुछ नहीं होता। मुझे ही देख लो! मैं 10वीं फेल हूँ लेकिन डिलीवरी बॉय के तौर पर तकरीबन 20,000 महिना कमा लेता हूँ।",
            "ka": "ಬಾಯಿ ಮುಚ್ಚು ,ಝೈದ್ ! ಪರೀಕ್ಷೆಗಳಲ್ಲಿ ಉತ್ತೀರ್ಣರಾಗುವುದು ಎಲ್ಲವೂ ಅಲ್ಲ. ನನ್ನನು ನೋಡು! ನಾನು 10 ನೇ ಫೇಲ್ ಆಗಿದ್ದೇನೆ, ಆದರೆ ನಾನು ಡೆಲಿವರಿ ಬಾಯ್ ಆಗಿ ತಿಂಗಳಿಗೆ ಸುಮಾರು 20,000 ಗಳಿಸುತ್ತೇನೆ.",
            "te": "నోరుమూసుకో, జైద్! పరీక్షల్లో పాస్ అవ్వడం ఒకటే కాదు. ఇటు చూడు! నేను 10వ తరగతి ఫెయిల్ అయ్యాను, కానీ డెలివరీ బాయ్‌లాగా నేను నెలకు దాదాపు 20,000 సంపాదిస్తున్నాను.",
            "or": "ଚୁପ୍‌ ହୁଅ, ଜାଏଦ୍‍ ! ପରୀକ୍ଷାରେ ଉତ୍ତୀର୍ଣ୍ଣ ହେବା ସବୁକିଛି ନୁହେଁ | ମୋତେ ଦେଖ! ମୁଁ ଦଶମ ଫେଲ୍‌, କିନ୍ତୁ ମୁଁ ଏକ ଡେଲିଭରି ବଏଅ ଭାବରେ ମାସକୁ ପ୍ରାୟ 20,000 ରୋଜଗାର କରେ |",
            "as": "চুপ থাকা, জাইদ! পৰীক্ষাত উত্তীৰ্ণ হোৱাটোৱেই সকলো নহয়। মোৰ ফালে চোৱা! মই দশম ফেইল, কিন্তু ডেলিভাৰী লৰা হিচাপে মই মাহে প্ৰায় 20,000 উপাৰ্জন কৰোঁ।",
            "gu": "ચૂપ રે, ઝાઇદ! પરીક્ષા પાસ કરવી એ જ બધું નથી. મારી સામે જો! હું 10મા ધોરણમાં નપાસ છું, પરંતુ માલસામાન પોહચાડવાની નોકરીમાં હું લગભગ 20,000 મહિનાની કમાણી કરું છું."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Just because you managed to get a job doesn’t mean everyone will. Do you even like what you do? \nYou barely even come for practice these days because of your work timing.",
            "hi": "तुम्हें जॉब मिल गया इसका मतलब यह नहीं है कि सबको मिल जाएं। क्या तुम्हें यह काम पसंद भी है? \n तुम्हारे काम के समय के कारण आजकल तुम प्रैक्टिस करने के लिए भी बहुत कम आते हो।",
            "ka": "ನೀವು ಕೆಲಸವನ್ನು ಪಡೆಯುವಲ್ಲಿ ಯಶಸ್ವಿಯಾಗಿದ್ದೀರಿ ಎಂದ ಮಾತ್ರಕ್ಕೆ ಎಲ್ಲರೂ ಮಾಡುತ್ತಾರೆ ಎಂದು ಅರ್ಥವಲ್ಲ. ನೀವು ಮಾಡುವುದನ್ನು ಸಹ ನೀವು ಇಷ್ಟಪಡುತ್ತೀರಾ?\n ನಿಮ್ಮ ಕೆಲಸದ ಸಮಯದ ಕಾರಣದಿಂದ ನೀವು ಈ ದಿನಗಳಲ್ಲಿ ಅಭ್ಯಾಸಕ್ಕೆ ಬರುವುದಿಲ್ಲ.",
            "te": "నువ్వు ఉద్యోగం తెచ్చుకున్నావని అందరూ తెచ్చుకోవాలని కాదు. నువ్వు చేసే పని నీకు నచ్చిందా?\n నీ పని సమయం కారణంగా నువ్వు ఈ మధ్య సాధనకు కూడా రావడం లేదు.",
            "or": "କେବଳ ଯେହେତୁ ତୁମେ ଚାକିରି ପାଇବାରେ ସଫଳ ହୋଇଛ ଏହାର ଅର୍ଥ ନୁହେଁ ଯେ ସମସ୍ତେ ତାହା କରିପାବେ | ତୁମେ ଯାହା କରୁଛ ତାହା ମଧ୍ୟ ତୁମେ ପସନ୍ଦ କର କି?\n ତୁମର କାର୍ଯ୍ୟ ସମୟ ହେତୁ ଏବେ ତୁମେ କ୍ୱଚିତ୍ ଅଭ୍ୟାସ ପାଇଁ ଆସୁଛ |",
            "as": "কেৱল তুমি চাকৰি এটা পাবলৈ সক্ষম হোৱাৰ অৰ্থ এইটো নহয় যে সকলোৱে পাব। আনকি তুমি যি কৰি আছা সেইটো কৰি ভাল পোৱানে? তোমাৰ কামৰ সময়ৰ বাবে তুমি আজিকালি কোনোমতে অনুশীলনৰ বাবে আহি আছা।",
            "gu": "“એનો અર્થ એ નથી કે તને જે નોકરી મળી છે તે બધાને મળી શકે છે, પરંતુ તુ જે કરે છે તે તને ગમે છે? તારા કામકાજના કલાકોને કારણે અત્યારે આ દિવસોમાં ભાગ્યે જ પ્રેક્ટિસ માટે સમય આપે છે. \""
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "X2_1_4//",
            "hi": "X2_1_4//",
            "ka": "X2_1_4//",
            "te": "X2_1_4//",
            "or": "X2_1_4//",
            "as": "X2_1_4//",
            "gu": "X2_1_4//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Omi",
            "hi": "ओमी",
            "ka": "ಓಮಿ",
            "te": "ఓమి",
            "or": "ଓମି",
            "as": "অমি",
            "gu": "ઓમી"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Ya, I don’t want to take up something that would affect our practice.",
            "hi": "हाँ, मुझे ऐसा कुछ नहीं करना है जो हमारी प्रैक्टिस पर असर करें।",
            "ka": "ಹೌದು, ನಮ್ಮ ಅಭ್ಯಾಸದ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುವ ಯಾವುದನ್ನಾದರೂ ತೆಗೆದುಕೊಳ್ಳಲು ನಾನು ಬಯಸುವುದಿಲ್ಲ.",
            "te": "అవును, మన సాధనపై ప్రభావం చూపే ఏదైనా నేను చేయలేను.",
            "or": "ହଁ, ମୁଁ ଏପରି କିଛି ନେବାକୁ ଚାହେଁ ନାହିଁ ଯାହା ଆମର ଅଭ୍ୟାସ ଉପରେ ପ୍ରଭାବ ପକାଇବ |",
            "as": "হয়, মই এনে কিবা এটা ল'ব নিবিচাৰো যি আমাৰ অনুশীলনক প্ৰভাৱিত কৰিব।",
            "gu": "હા, પણ મારે એ નથી કરવું જે મારી પ્રેક્ટિસને અસર કરે"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Do you think your studies will not affect the practice? Be practical, Naveen. Unless dance can pay for your expenses, you will have to compromise somewhere.",
            "hi": "और तुम्हें लगता है तुम्हारी पढ़ाई का प्रैक्टिस पर असर नहीं होगा? प्रैक्टिकल बनो, नवीन। जब तक डान्स करके तुम्हारे खर्चे नहीं निकलेंगे तब तुम्हें कहीं ना कहीं तो समझौता करना ही पड़ेगा।",
            "ka": "ನಿನ್ನ ಅಧ್ಯಯನಗಳು ಅಭ್ಯಾಸದ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುವುದಿಲ್ಲ ಎಂದು ನೀನು ಭಾವಿಸುತ್ತೀಯಾ? ಪ್ರಾಯೋಗಿಕವಾಗಿರಿ ನವೀನ್. ನೃತ್ಯವು ನಿನ್ನ ವೆಚ್ಚವನ್ನು ಪಾವತಿಸದಿದ್ದರೆ, ನೀನು ಎಲ್ಲೋ ರಾಜಿ ಮಾಡಿಕೊಳ್ಳಬೇಕಾಗುತ್ತದೆ.",
            "te": "నీ చదువు సాధనపై ప్రభావం చూపుతుందని నువ్వు అనుకోవట్లేదా? ఆచరణాత్మకంగా ఉండు నవీన్. డ్యాన్స్ వల్ల నీకు ఖర్చులు తప్ప, ఎక్కడో ఒకచోట రాజీ పడాల్సిందే.",
            "or": "ତୁମେ ଭାବୁଛ କି ତୁମର ଅଧ୍ୟୟନ ଅଭ୍ୟାସ ଉପରେ ପ୍ରଭାବ ପକାଇବ ନାହିଁ ? ପ୍ରାକ୍ଟିକାଲ୍ ହୁଅ, ନବୀନ | ଯେପର୍ଯ୍ୟନ୍ତ ନୃତ୍ୟ ତୁମର ଖର୍ଚ୍ଚ ପାଇଁ ପୈଠ କରିପାରେ ନାହିଁ, ତୁମକୁ କିଛି ଆପୋଷ ସମାଧାନ କରିବାକୁ ପଡିବ |",
            "as": "তুমি ভাবানে যে তোমাৰ অধ্যয়নে অনুশীলনটো প্ৰভাৱিত নকৰে? নবীন, ব্যৱহাৰিক হোৱা ৷ যেতিয়ালৈকে নৃত্যই তোমাৰ ব্যয় পৰিশোধ কৰিব নোৱাৰে, তুমি কৰবাত আপোচ কৰিব লাগিব।",
            "gu": "તને ખબર છે તારું ભણતર તારી પ્રેક્ટિસને અસર નહીં કરે? નવીન તું વાસ્તવિક્તા બન. ખાલી નૃત્ય જ તારા ખર્ચા પૂરા કરી શકે છે તો તારે ક્યાં જોવું પડે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "X2_1_5_D",
            "hi": "X2_1_5_D",
            "ka": "X2_1_5_D",
            "te": "X2_1_5_D",
            "or": "X2_1_5_D",
            "as": "X2_1_5_D",
            "gu": "X2_1_5_D"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Naveen choose to say to determine his future?",
            "hi": "नवीन को अपने भविष्य निर्धारित करने के लिए क्या कहना चाहिए?",
            "ka": "ನವೀನ್ ತನ್ನ ಭವಿಷ್ಯವನ್ನು ನಿರ್ಧರಿಸಲು ಏನು ಹೇಳಬೇಕು?",
            "te": "నవీన్ తన భవిష్యత్తు నిర్ణయించడానికి ఏమి చెప్పాలి?",
            "or": "ତାଙ୍କ ଭବିଷ୍ୟତ ନିର୍ଣ୍ଣୟ କରିବା ପାଇଁ ନବୀନ କ’ଣ କହିବାକୁ ଚୟନ କରିବେ ?",
            "as": "নবীনে তেওঁৰ ভৱিষ্যত নিৰ্ধাৰণ কৰিবলৈ কি ক'ব লাগে?",
            "gu": "નવીનને શું કરવું જોઈએ તેનું ભવિષ્ય નક્કી કરવા માટે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Studying is not the only way to earn. We need to follow our passion and find a balance with work.",
            "hi": "केवल पढ़ाई ही पैसे कमाने का एकमात्र तारिका नहीं है। हमें अपने जूनून को भी पूरा करना चाहिए और काम के साथ संतुलन बनाना चाहिए।",
            "ka": "ಗಳಿಸುವುದೊಂದೇ ಮಾರ್ಗವಲ್ಲ. ನಾವು ನಮ್ಮ ಉತ್ಸಾಹವನ್ನು ಅನುಸರಿಸಬೇಕು ಮತ್ತು ಕೆಲಸದೊಂದಿಗೆ ಸಮತೋಲನವನ್ನು ಕಂಡುಕೊಳ್ಳಬೇಕು.",
            "te": "సంపాదనకు చదువు ఒక్కటే మార్గం కాదు. మనం మన అభిరుచిని అనుసరించాలి మరియు పనితో సమతుల్యతను కనుగొనాలి.",
            "or": "ରୋଜଗାର କରିବାର ଏକମାତ୍ର ଉପାୟ ନୁହେଁ ଅଧ୍ୟୟନ କରିବା | ଆମକୁ ଆମର ଉତ୍ସାହକୁ ଅନୁସରଣ କରିବା ଏବଂ କାର୍ଯ୍ୟ ସହିତ ସନ୍ତୁଳନ ଖୋଜିବା ଆବଶ୍ୟକ ଅଟେ |",
            "as": "অধ্যয়ন কৰাটোৱেই উপাৰ্জনৰ একমাত্ৰ উপায় নহয়। আমি আমাৰ আবেগ অনুসৰণ কৰিব লাগিব আৰু কামৰ সৈতে ভাৰসাম্য বিচাৰিব লাগিব।",
            "gu": "ભણતર જ ખાલી એવું નથી કે તેનાથી કમાઈ શકાય. આપણે આપણું ગમતું કરવું જોઈએ કામ શાથે સમતોલન રાખવા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "I agree with Zaid. I can’t fail this time!  I will need to give the exam to secure my future.",
            "hi": "मैं जाएद के साथ सहमत हूँ। मैं इस बार फेल नहीं हो सकता! मुझे अपने भविष्य को सुरक्षित करने के लिए परीक्षा तो देनी ही होगी।",
            "ka": "ನಾನು ಝೈದ್ ಜೊತೆ ಒಪ್ಪುತ್ತೇನೆ. ನಾನು ಈ ಸಮಯದಲ್ಲಿ ವಿಫಲಗೊಳ್ಳಲು ಸಾಧ್ಯವಿಲ್ಲ! ನನ್ನ ಭವಿಷ್ಯವನ್ನು ಸುರಕ್ಷಿತವಾಗಿರಿಸಲು ನಾನು ಪರೀಕ್ಷೆಯನ್ನು ನೀಡಬೇಕಾಗಿದೆ.",
            "te": "అవును జైద్‌ చెప్పింది నిజమే. నేను ఈసారి ఫెయిల్ అవ్వను! నా భవిష్యత్తును కాపాడుకోవడానికి నేను పరీక్ష రాయాలి.",
            "or": "ମୁଁ ଜାଏଦ୍‌ ସହିତ ସହମତ | ମୁଁ ଏଥର ଫେଲ୍‍ ହୋଇପାରିବି ନାହିଁ ! ମୋର ଭବିଷ୍ୟତକୁ ସୁରକ୍ଷିତ ରଖିବା ପାଇଁ ମୋତେ ପରୀକ୍ଷା ଦେବା ଆବଶ୍ୟକ |",
            "as": "মই জাইদৰ সৈতে একমত। মই এইবাৰ বিফল হ'ব নোৱাৰো! মোৰ ভৱিষ্যত সুৰক্ষিত কৰিবলৈ মই পৰীক্ষা দিবই লাগিব।",
            "gu": "હું ઝાઇદ સાથે સંમત છું. આ વખતે હું નપાસ ના થઈ શકું ! મારે મારૂ ભવિષ્ય સારું બનાવવા માટે પરીક્ષા આપવી પડશે."
          }
        }
      ]
    },
    "scene2": {
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "Reflection_Focus on dance_copy",
            "hi": "डान्स पर फोकस",
            "ka": "ಡ್ಯಾನ್ಸ್_ಕಾಪಿಯಲ್ಲಿ ಪ್ರತಿಫಲನ_ಫೋಕಸ್",
            "te": "ప్రతిబింబాలు_డ్యాన్స్ పై దృష్టి పెట్టండి _కాపీ",
            "or": "Reflection_Focus on dance_copy",
            "as": "প্ৰতিফলন_নৃত্যৰ ওপৰত গুৰুত্ব_নকল",
            "gu": "થઈ રહેલા પ્રતિબિંબિત નૃત્ય પર ધ્યાન કેન્દ્રિત કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just learnt about Reflection with others!",
            "hi": "आपने अभी-अभी दूसरों के साथ रिफ्लेक्शन करना सीखा हैं!",
            "ka": "ನೀವು ಇತರರೊಂದಿಗೆ ಪ್ರತಿಫಲನದ ಬಗ್ಗೆ ಕಲಿತಿದ್ದೀರಿ!",
            "te": "మీరు ఇతరులతో ప్రతిబింబించడం గురించి ఇప్పుడే తెలుసుకున్నారు!",
            "or": "ଆପଣ ବର୍ତମାନ ଅନ୍ୟମାନଙ୍କ ସହିତ ରିଫ୍ଲେକ୍ସନ୍‌ ବିଷୟରେ ଶିଖିଲେ !",
            "as": "তুমি এইমাত্ৰ আনৰ সৈতে প্ৰতিফলনৰ বিষয়ে শিকিলা!",
            "gu": "બીજા લોકો સાથે મનન કરી પોતાના પ્રતિભાવો વિષે સમજો અને શીખો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Reflection with others",
            "hi": "दूसरों के साथ रिफ्लेक्शन",
            "ka": "ಇತರರೊಂದಿಗೆ ಪ್ರತಿಫಲನ",
            "te": "ఇతరులతో ప్రతిబింబించండి",
            "or": "ଅନ୍ୟମାନଙ୍କ ସହିତ ରିଫ୍ଲେକ୍ସନ୍‌",
            "as": "আনৰ সৈতে প্ৰতিফলন",
            "gu": "બીજા લોકો સાથે મનન કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Self Development",
            "hi": "स्वयं विकास",
            "ka": "ಸ್ವಯಂ ಅಭಿವೃದ್ಧಿ",
            "te": "స్వీయ అభివృద్ధి",
            "or": "ଆତ୍ମ ବିକାଶ",
            "as": "আত্ম বিকাশ",
            "gu": "જાત વિકાસ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Naveen talks to his friends to get clarity about his options and analyse what to do next. Reflection is thinking about your actions and what you could do better and is primarily an independent activity. You can sometimes reflect with peers and friends and reason out the options with them to get clarity. While talking with friends/mentors, you need to know that you cannot share everything with them, but you need to be truthful about the information you share with them.",
            "hi": "नवीन अपने दोस्तों के साथ बात करके अपने विकल्पों के बारे में जानकारी प्राप्त करता है और आगे क्या करना चाहिए इसके बारे में सोचता है। रिफ्लेक्शन का मतलब है अपने कार्यों के बारे में और अपने लिए क्या बेहतर कर सकते हैं इसके बारे में सोचना और यह एक स्वतंत्र एक्टिविटी है। आप कभी-कभी अपने दोस्तों और साथियों के साथ रिफ्लेक्ट कर सकते हैं और स्पष्ट कल्पना प्राप्त करने के लिए उनके साथ चर्चा कर सकते हैं। दोस्तों/उपदेशकों के साथ बार करते हुए, आपको इस बात का ध्यान रखना पड़ेगा कि आप उन्हें सब कुछ नहीं बता सकते, लेकिन आपन उन्हें जो बताएं वह जानकारी सच्ची होनी चाहिए।",
            "ka": "ನವೀನ್ ತನ್ನ ಆಯ್ಕೆಗಳ ಬಗ್ಗೆ ಸ್ಪಷ್ಟತೆ ಪಡೆಯಲು ಮತ್ತು ಮುಂದೆ ಏನು ಮಾಡಬೇಕೆಂದು ವಿಶ್ಲೇಷಿಸಲು ತನ್ನ ಸ್ನೇಹಿತರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾನೆ. ಪ್ರತಿಬಿಂಬವು ನಿಮ್ಮ ಕ್ರಿಯೆಗಳ ಬಗ್ಗೆ ಯೋಚಿಸುವುದು ಮತ್ತು ನೀವು ಉತ್ತಮವಾಗಿ ಏನು ಮಾಡಬಹುದು ಮತ್ತು ಪ್ರಾಥಮಿಕವಾಗಿ ಸ್ವತಂತ್ರ ಚಟುವಟಿಕೆಯಾಗಿದೆ. ನೀವು ಕೆಲವೊಮ್ಮೆ ಗೆಳೆಯರು ಮತ್ತು ಸ್ನೇಹಿತರೊಂದಿಗೆ ಪ್ರತಿಬಿಂಬಿಸಬಹುದು ಮತ್ತು ಸ್ಪಷ್ಟತೆಯನ್ನು ಪಡೆಯಲು ಅವರೊಂದಿಗೆ ಆಯ್ಕೆಗಳನ್ನು ತರ್ಕಿಸಬಹುದು. ಸ್ನೇಹಿತರು/ಮಾರ್ಗದರ್ಶಿಗಳೊಂದಿಗೆ ಮಾತನಾಡುವಾಗ, ನೀವು ಅವರೊಂದಿಗೆ ಎಲ್ಲವನ್ನೂ ಹಂಚಿಕೊಳ್ಳಲು ಸಾಧ್ಯವಿಲ್ಲ ಎಂದು ನೀವು ತಿಳಿದುಕೊಳ್ಳಬೇಕು, ಆದರೆ ನೀವು ಅವರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳುವ ಮಾಹಿತಿಯ ಬಗ್ಗೆ ನೀವು ಸತ್ಯವಾಗಿರಬೇಕು.",
            "te": "నవీన్ తన ఎంపికల గురించి స్పష్టత పొందడానికి మరియు తర్వాత ఏమి చేయాలో తెలుసుకోవడానికి అతని స్నేహితులతో మాట్లాడతాడు. ప్రతిబింబించడం అనేది మీ చర్యల గురించి మరియు మీరు బాగా చేయగలిగిన దాని గురించి ఆలోచించడం మరియు ఇది ఒక స్వతంత్ర కార్యాచరణ. మీరు కొన్నిసార్లు స్పష్టత కోసం సహచరులు మరియు స్నేహితులతో ప్రతిబింబించడం చేసి కొన్ని ఎంపికలు తీసివేయవచ్చు. స్నేహితులు/సలహాదారులతో మాట్లాడుతున్నప్పుడు, మీరు వారితో ప్రతిదీ పంచుకోలేరని మీరు తెలుసుకోవాలి, కానీ మీరు వారితో పంచుకునే సమాచారం మాత్రం నిజమై ఉండాలి.",
            "or": "ତାଙ୍କ ବିକଳ୍ପଗୁଡ଼ିକ ବିଷୟରେ ସ୍ପଷ୍ଟତା ପାଇବାକୁ ଏବଂ ପରବର୍ତ୍ତୀ ସମୟରେ କଣ କରିବାକୁ ବିଶ୍ଳେଷଣ କରିବାକୁ ନବୀନ ତାଙ୍କ ସାଙ୍ଗମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରନ୍ତି | ତୁମର କାର୍ଯ୍ୟଗୁଦିକ ବିଷୟରେ ଚିନ୍ତା ଏବଂ ତୁମେ କଣ ଭଲ କରି ପାରିବ ଏବଂ ମୁଖ୍ୟତ ଏକ ସ୍ୱାଧୀନ କାର୍ଯ୍ୟକଳାପ ହେଉଛି ରିଫ୍ଲେକ୍ସନ୍‌ | ଆପଣ ବେଳେବେଳେ ସାମର୍ଥ୍ଯ ବ୍ଯକ୍ତି ଏବଂ ସାଙ୍ଗମାନଙ୍କ ସହିତ ରିଫ୍ଲେକ୍ଟ କରିପାରନ୍ତି ଏବଂ ସ୍ପଷ୍ଟତା ପାଇବା ପାଇଁ ସେମାନଙ୍କ ସହିତ ବିକଳ୍ପଗୁଡିକ ବାହାର କରନ୍ତୁ | ସାଙ୍ଗ/ମେଣ୍ଟରମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରିବାବେଳେ, ଆପଣ ଜାଣିବା ଆବଶ୍ୟକ ଯେ ଆପଣ ସେମାନଙ୍କ ସହିତ ସବୁକିଛି ଅଂଶୀଦାର କରିପାରିବେ ନାହିଁ, କିନ୍ତୁ ଆପଣ ସେମାନଙ୍କ ସହିତ ଅଂଶୀଦାର କରୁଥିବା ସୂଚନା ବିଷୟରେ ସତ୍ୟବାଦୀ ହେବା ଆବଶ୍ୟକ |",
            "as": "নবীনে তেওঁৰ বিকল্পবোৰৰ বিষয়ে স্পষ্টতা পাবলৈ আৰু পৰৱৰ্তী সময়ত কি কৰিব লাগে বিশ্লেষণ কৰিবলৈ তেওঁৰ বন্ধুবৰ্গৰ সৈতে কথা পা। প্ৰতিফলন হৈছে তোমাৰ কামৰ বিষয়ে চিন্তা কৰা আৰু তুমি কি ভাল কৰিব পাৰা আৰু ই হৈছে মুখ্যতঃ এক স্বতন্ত্ৰ কাৰ্যকলাপ। তুমি কেতিয়াবা সহকৰ্মী আৰু বন্ধুবৰ্গৰ সৈতে প্ৰতিফলন কৰিব পাৰা আৰু স্পষ্টতা পাবলৈ তেওঁলোকৰ সৈতে বিকল্পবোৰ যুক্তি হিচাপে দিব পাৰা। বন্ধু/পৰামৰ্শদাতাৰ সৈতে কথা পাতি থাকোঁতে, তুমি জানিব লাগিব যে তুমি সকলোবোৰ তেওঁলোকৰ সৈতে ভাগ-বতৰা কৰিব নোৱাৰা, কিন্তু তুমি তেওঁলোকৰ সৈতে ভাগ-বতৰা কৰা তথ্যৰ বিষয়ে সত্যবাদী হ'ব লাগিব।",
            "gu": "નવીન તેના વિકલ્પો ચોખવટ રીતે સમજવા અને આગળ શું કરવું તે જોવા માટે તેના મિત્રો સાથે વાત કરે છે. ચિંતન એ તમે શું કરી રહ્યા છો અને તમે શું સારું કરવા જઈ રહ્યા છો તેના વિશે વિચારવાનું છે અને તે એક સ્વતંત્ર કાર્યક્રમ પણ છે. કેટલીકવાર તમે સાથીદારો અને મિત્રો સાથે ચિંતન કરી શકો છો, અને પછી તેમની સાથે ચોખવટ માટેના વિકલ્પોની ચર્ચા કરી શકો છો. મિત્રો અને માર્ગદર્શકકારો સાથે વાત કરતી વખતે, તમારે ધ્યાન રાખવું જોઈએ કે તમે તેમને બધું બતાવી નથી રહ્યા, પરંતુ તમે જે માહિતી કહો છો તેના વિશે તમારે પ્રમાણિક હોવું જોઈએ."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQs",
            "hi": "MCQs",
            "ka": "ಎಂಸಿಕ್ಯೂಸ್",
            "te": "MCQలు",
            "or": "MCQs",
            "as": "MCQs",
            "gu": "MCQs"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Select a statement about reflection that is TRUE:",
            "hi": "रिफ्लेक्शन के बारे में कौन सा विधान सही है वह चुनें:",
            "ka": "ಪ್ರತಿಬಿಂಬದ ಬಗ್ಗೆ ಒಂದು ಹೇಳಿಕೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ ಅದು ನಿಜವಾಗಿದೆ:",
            "te": "ప్రతిబింబించడం గురించి సరైన ప్రకటనను ఎంచుకోండి:",
            "or": "ରିଫ୍ଲେକ୍ସନ୍‌ ବିଷୟରେ ଏକ ବିବୃତ୍ତି ବାଛନ୍ତୁ ଯାହାକି ସତ୍ୟ:",
            "as": "প্ৰতিফলনৰ বিষয়ে এটা বিবৃতি বাছনি কৰা যিটো সঁচা:",
            "gu": "એક વાક્ય નક્કી કારો જે ચિંતન સાથે સાચું છે:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Reflection is a great way to learn from your experiences.",
            "hi": "रिफ्लेक्शन अपने अनुभवों से सीखने का सबसे अच्छा तारिका है।",
            "ka": "ನಿಮ್ಮ ಅನುಭವಗಳಿಂದ ಕಲಿಯಲು ಪ್ರತಿಬಿಂಬವು ಉತ್ತಮ ಮಾರ್ಗವಾಗಿದೆ.",
            "te": "ప్రతిబింబించడం అనేది మీ అనుభవాల నుండి నేర్చుకోవడానికి ఒక మంచి మార్గం.",
            "or": "ତୁମର ଅନୁଭୂତିରୁ ଶିଖିବା ପାଇଁ ରିଫ୍ଲେକ୍ସନ୍‌ ହେଉଛି ଏକ ଉତ୍ତମ ଉପାୟ |",
            "as": "প্ৰতিফলন হৈছে তোমাৰ অভিজ্ঞতাৰ পৰা শিকাৰ এক উৎকৃষ্ট উপায়।",
            "gu": "સ્વ ચિંતન એ  તમારા અનુભવોથી શીખવાનો એક રસ્તો છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Reflection is an activity done by oneself as well as with others.",
            "hi": "रिफ्लेक्शन ऐसी एक्टिविटी है जो खुद के साथ-साथ दूसरों के साथ भी की जाती हैं।",
            "ka": "ಪ್ರತಿಬಿಂಬವು ಸ್ವತಃ ಮತ್ತು ಇತರರೊಂದಿಗೆ ಮಾಡುವ ಚಟುವಟಿಕೆಯಾಗಿದೆ.",
            "te": "ప్రతిబింబించడం అనేది తనతో తాను మరియు ఇతరులతో చేసే కార్యాచరణ.",
            "or": "ରିଫ୍ଲେକ୍ସନ୍‌ ହେଉଛି ନିଜ‍ ତଥା ଅନ୍ୟମାନଙ୍କ ସହିତ କରାଯାଇଥିବା ଏକ କାର୍ଯ୍ୟକଳାପ |",
            "as": "প্ৰতিফলন হৈছে নিজৰ লগতে আনৰ সৈতে কৰা এক কাৰ্যকলাপ।",
            "gu": "ચિંતનએક એવી પ્ર્વુતી છે જે પોતે અને બીજા દ્વારા થાય છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Reflection can help us get clarity to make better decisions",
            "hi": "रिफ्लेक्शन बेहतर निर्णय लेने के लिए स्पष्टता प्राप्त करने में मदद कर सकता हैं।",
            "ka": "ಪ್ರತಿಬಿಂಬವು ಉತ್ತಮ ನಿರ್ಧಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಸ್ಪಷ್ಟತೆಯನ್ನು ಪಡೆಯಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ",
            "te": "ప్రతిబింబించడం మంచి నిర్ణయాలు తీసుకోవడానికి స్పష్టత కోసం మనకు సహాయపడుతుంది",
            "or": "ଆମକୁ ଉତ୍ତମ ନିଷ୍ପତ୍ତି ନେବାରେ ସ୍ପଷ୍ଟତା ଆଣିବାକୁ ରିଫ୍ଲେକ୍ସନ୍‌ ସାହାଯ୍ୟ କରିଥାଏ",
            "as": "প্ৰতিফলনে আমাক উন্নত সিদ্ধান্ত ল'বলৈ স্পষ্টতা প্ৰাপ্ত কৰাত সহায় কৰিব পাৰে",
            "gu": "સ્વ ચિંતન આપણને વધુ સારા નિર્ણયો લેવામાં ચોખવટ રીતે મદદ કરી શકે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.display",
          "value": {
            "en": "Reflection is only done by writing in a quiet environment",
            "hi": "रिफ्लेक्शन शांत वातावरण में लिखकर किया जाता है",
            "ka": "ಪ್ರತಿಬಿಂಬವನ್ನು ಶಾಂತ ವಾತಾವರಣದಲ್ಲಿ ಬರೆಯುವ ಮೂಲಕ ಮಾತ್ರ ಮಾಡಲಾಗುತ್ತದೆ",
            "te": "ప్రశాంతమైన ప్రదేశంలో రాయడం ద్వారా మాత్రమే ప్రతిబింబించడం జరుగుతుంది",
            "or": "ରିଫ୍ଲେକ୍ସନ୍‌ କେବଳ ଏକ ଶାନ୍ତ ପରିବେଶରେ ଲେଖି କରାଯାଇଥାଏ",
            "as": "প্ৰতিফলন কেৱল এক শান্ত পৰিৱেশত লিখিহে কৰা হয়",
            "gu": "શાંત વાતાવરણમાં લખીને જ અનુવાદ કરવામાં આવે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Great Job",
            "hi": "बहुत बढ़िया",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ",
            "te": "గొప్ప పని",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ",
            "as": "ভাল কৰিছা!",
            "gu": "સરસ કામ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great Job",
            "hi": "बहुत बढ़िया",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ",
            "te": "గొప్ప పని",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ",
            "as": "ভাল কৰিছা!",
            "gu": "સરસ કામ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "बहुत बढ़िया!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "గొప్ప పని",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ !",
            "as": "ভাল কৰিছা!",
            "gu": "સરસ કામ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.popUpText",
          "value": {
            "en": "Think again. Reflection could also be done by talking to others, \nand while taking a walk and thinking by yourself/making a voice note.",
            "hi": "फिर से सोचो। रिफ्लेक्शन दूसरों के साथ बात करके भी किया जा सकता है\n और साथ ही चलते हुए और सोचते हुए/वॉईस नोट बनाते हुए भी किया जा सकता है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು. ಇತರರೊಂದಿಗೆ ಮಾತನಾಡುವ ಮೂಲಕ ಪ್ರತಿಬಿಂಬವನ್ನು ಸಹ ಮಾಡಬಹುದು,\n ಮತ್ತು ವಾಕ್ ಮಾಡುವಾಗ ಮತ್ತು ನೀವೇ ಯೋಚಿಸುವಾಗ/ವಾಯ್ಸ್ ನೋಟ್ ಮಾಡುವಾಗ.",
            "te": "మళ్ళీ ఆలోచించండి. ఇతరులతో మాట్లాడటం ద్వారా కూడా ప్రతిబింబించడం జరుగుతుంది,\n మరియు ఒంటరిగా నడుస్తూ మీకు మీరే ఆలోచిస్తూ/వాయిస్ నోట్ తయారు చేస్తున్నప్పుడు.",
            "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ। ରିଫ୍ଲେକ୍ସନ୍‍ ଅନ୍ୟମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରି ମଧ୍ୟ କରାଯାଇପାରେ,\n ଏବଂ ବୁଲିବା ଏବଂ ନିଜେ ଚିନ୍ତା କରିବା ସମୟରେ/ଏକ ଭଏସ୍ ନୋଟ୍ ତିଆରି କରି |",
            "as": "আকৌ চিন্তা কৰা। আনৰ সৈতে কথা পাতিও আৰু খোজ কাঢ়ি থাকোঁতে আৰু নিজে চিন্তা কৰোঁতে/এটা ভইচ নোট তৈয়াৰ কৰোঁতেও প্ৰতিফলন কৰিব পাৰি ৷",
            "gu": "વિચારો ફરીથી. ચિંતન બીજા સાથે વાત કરીને પણ કરી શકાય છે,\nઅને જ્યારે તમે આસપાસ ફરો છો અને ફક્ત તમારા વિશે જ વિચારો છો / અવાજ રેકોર્ડ કરો."
          }
        }
      ],
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_1_1",
            "hi": "A1_1_1",
            "ka": "A1_1_1",
            "te": "A1_1_1",
            "or": "A1_1_1",
            "as": "A1_1_1",
            "gu": "A1_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen fails his 12th standard exams again. He is convinced studies is not an option for him.",
            "hi": "नवीन फिर से 12वीं कक्षा के परीक्षा में फेल होता है। अब उसे यकीन हो गया है कि पढ़ाई का विकल्प उसके लिए नहीं है।",
            "ka": "ನವೀನ್ ಮತ್ತೆ 12ನೇ ತರಗತಿ ಪರೀಕ್ಷೆಯಲ್ಲಿ ಫೇಲ್ ಆಗಿದ್ದಾನೆ. ಅಧ್ಯಯನವು ಅವನಿಗೆ ಒಂದು ಆಯ್ಕೆಯಾಗಿಲ್ಲ ಎಂದು ಅವನು ಮನವರಿಕೆ ಮಾಡುತ್ತಾನೆ.",
            "te": "గొప్ప పని",
            "or": "ନବୀନ ପୁଣିଥରେ ତାଙ୍କର ଦ୍ୱାଦଶ ଶ୍ରେଣୀ ପରୀକ୍ଷାରେ ଫେଲ୍‌ ହୋଇଛନ୍ତି | ସେ ନିଶ୍ଚିତ ଯେ ଅଧ୍ୟୟନ ତାଙ୍କ ପାଇଁ ଏକ ବିକଳ୍ପ ନୁହେଁ |",
            "as": "নবীনে পুনৰ দ্বাদশ শ্ৰেণীৰ পৰীক্ষাত বিফল হ’ল। তেওঁ নিশ্চিত যে অধ্যয়ন তেওঁৰ বাবে বিকল্প নহয়।",
            "gu": "નવીન 12મા ધોરણની પરીક્ષામાં ફરીથી નાપાસ થયો. તેને ચોક્કસ ખબર છે કે અભ્યાસ તેના માટે વિકલ્પ નથી."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_1_2",
            "hi": "A1_1_2",
            "ka": "A1_1_2",
            "te": "A1_1_2",
            "or": "A1_1_2",
            "as": "A1_1_2",
            "gu": "A1_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Meanwhile, Naveen’s dance group wins the first runner-up at the dance festival. They are beginning to wonder if their group has the potential to become famous.",
            "hi": "इस दौरान, नवीन का ग्रुप डान्स फेस्टिवल में दुसरे नंबर पर आ जाता है। वह सोचने लगे कि क्या उनके ग्रुप में प्रसिद्ध होने की क्षमता है।",
            "ka": "ಅಷ್ಟರಲ್ಲಿ, ನವೀನ್ ನೃತ್ಯ ಗುಂಪು ನೃತ್ಯ ಉತ್ಸವದಲ್ಲಿ ಮೊದಲ ರನ್ನರ್ ಅಪ್ ಅನ್ನು ಗೆಲ್ಲುತ್ತದೆ. ತಮ್ಮ ಗುಂಪಿಗೆ ಪ್ರಸಿದ್ಧರಾಗುವ ಸಾಮರ್ಥ್ಯವಿದೆಯೇ ಎಂದು ಅವರು ಆಶ್ಚರ್ಯಪಡಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ.",
            "te": "ఇంతలో, నవీన్ యొక్క డ్యాన్స్ బృందం డ్యాన్స్ ఫెస్టివల్‌లో మొదటి రన్నరప్‌గా వచ్చింది. తమ బృందానికి ప్రసిద్ధి చెందే అవకాశం ఉందా అని ఆలోచించడం మొదలు పెట్టాడు.",
            "or": "ଏହି ସମୟରେ, ନବୀନଙ୍କ ନୃତ୍ୟ ଗ୍ରୁପ୍‌ ନୃତ୍ୟ ମହୋତ୍ସବରେ ପ୍ରଥମ ରନର୍-ଅପ୍ ଜିତିଥିଲେ | ସେମାନେ ବିସ୍ମୟ ହେବାକୁ ଲାଗନ୍ତି କି ସେମାନଙ୍କ ଗ୍ରୁପ୍‌ ପ୍ରସିଦ୍ଧ ହେବାର ସାମର୍ଥ୍ୟ ଅଛି |",
            "as": "ইফালে, নবীনৰ নৃত্য দলটোৱে নৃত্য মহোৎসৱত প্ৰথম ৰানাৰ-আপ জয় কৰে। তেওঁলোকে আচৰিত হ'বলৈ আৰম্ভ কৰিছে যে তেওঁলোকৰ গোটটোৰ বিখ্যাত হোৱাৰ সম্ভাৱনা আছে নেকি।",
            "gu": "તે દરમિયાન નૃત્ય ઉત્સવમાં નવીનનું જૂથ પ્રથમ સ્થાન મેળવ્યું હતું. તેઓ વિચારવા લાગ્યા છે કે શું તેમના જૂથમાં પ્રખ્યાત બનવાની ક્ષમતા છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_1_3",
            "hi": "A1_1_3",
            "ka": "A1_1_3",
            "te": "A1_1_3",
            "or": "A1_1_3",
            "as": "A1_1_3",
            "gu": "A1_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He is keen on helping his mother with the finances at home, so he explores other opportunities.",
            "hi": "वह घर चलाने में अपनी माँ को दिल से मदद करना चाहता है, इसलिए वह अन्य अवसरों की तलाश करता है।",
            "ka": "ಅವನು ತನ್ನ ತಾಯಿಗೆ ಮನೆಯಲ್ಲಿ ಹಣಕಾಸಿನ ಸಹಾಯ ಮಾಡಲು ಉತ್ಸುಕನಾಗಿದ್ದಾನೆ, ಆದ್ದರಿಂದ ಅವನು ಇತರ ಅವಕಾಶಗಳನ್ನು ಅನ್ವೇಷಿಸುತ್ತಾನೆ.",
            "te": "అతను తన తల్లికి ఇంట్లో ఆర్థిక సహాయం చేయాలి అనుకుంటున్నాడు, కాబట్టి అతను ఇంకా అవకాశాల కోసం వెతుకుతున్నాడు.",
            "or": "ସେ ନିଜ ମା’ଙ୍କୁ ଘରେ ଆର୍ଥିକ ସାହାଯ୍ୟ କରିବାକୁ ଆଗ୍ରହୀ, ତେଣୁ ସେ ଅନ୍ୟାନ୍ୟ ସୁଯୋଗ ଅନୁସନ୍ଧାନ କରନ୍ତି |",
            "as": "তেওঁ তেওঁৰ মাকক ঘৰুৱা বিত্তীয় সহায় কৰিবলৈ আগ্ৰহী, সেয়েহে তেওঁ আন সুযোগবোৰ অন্বেষণ কৰে।",
            "gu": "તે તેની માતાને ઘરે આર્થિક મદદ કરવા ઉત્સુક છે, તેથી તે અન્ય વિકલ્પો શોધે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_1",
            "hi": "A1_2_1",
            "ka": "A1_2_1",
            "te": "A1_2_1",
            "or": "A1_2_1",
            "as": "A1_2_1",
            "gu": "A1_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "At Zaid’s house, after the dance practise session, he is playing with a book.",
            "hi": "डान्स प्रैक्टिस के बाद, जाएद के घर पर वह एक किताब देख रहा है।",
            "ka": "ಝೈದ್ ಅವರ ಮನೆಯಲ್ಲಿ, ನೃತ್ಯ ಅಭ್ಯಾಸದ ನಂತರ, ಅವನು ಪುಸ್ತಕದೊಂದಿಗೆ ಆಡುತ್ತಿದ್ದಾನೆ.",
            "te": "జైద్ ఇంట్లో, డ్యాన్స్ సాధన సెషన్ తర్వాత, అతను ఒక పుస్తకంతో ఆడుకుంటున్నాడు.",
            "or": "ଜାଏଦ୍‌ଙ୍କ ଘରେ, ନୃତ୍ୟ ଅଭ୍ୟାସ ଅଧିବେଶନ ପରେ, ସେ ଏକ ବହି ସହିତ ଖେଳୁଛନ୍ତି |",
            "as": "জাইদৰ ঘৰত, নৃত্য অনুশীলনৰ পিছত, তেওঁ এখন কিতাপ লৈ খেলি আছে।",
            "gu": "જાઈદના ઘરે જ્યારે ડાન્સ પ્રેક્ટિસનો સમય પતિ જાય છે, તે પછી તે એક પુસ્તક સાથે રમી રહ્યો છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_2//",
            "hi": "A1_2_2//",
            "ka": "A1_2_2//",
            "te": "A1_2_2//",
            "or": "A1_2_2//",
            "as": "A1_2_2//",
            "gu": "A1_2_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Zaid",
            "hi": "जाएद",
            "ka": "ಝೈದ್",
            "te": "జైద్",
            "or": "ଜାଏଦ୍‌",
            "as": "জাইদ",
            "gu": "જાઈદ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Zaid",
            "hi": "जाएद",
            "ka": "ಝೈದ್",
            "te": "జైద్",
            "or": "ଜାଏଦ୍‌",
            "as": "জাইদ",
            "gu": "જાઈદ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Naveen da, leave that alone. It’s my brother’s.",
            "hi": "नवीन दा, उसे मत छूना। वह मेरे भाई की है।",
            "ka": "ನವೀನ್ ಡಾ , ಅದು ಬಿಟ್ಟುಬಿಡು. ಇದು ನನ್ನ ಸಹೋದರನದು.",
            "te": "నవీన్ దా, అది వదిలెయ్. అది నా సోదరుడిది.",
            "or": "ନବୀନ ଦା, ଏହାକୁ ଏକାକୀ ଛାଡିଦିଅ | ଏହା ମୋର ଭାଇଙ୍କର |",
            "as": "নবীন দা, সেইখন থৈ দিয়ক। এয়া মোৰ ভাইটিৰ।",
            "gu": "ઓ નવીન તેને છોડી ડે. તે મારા ભાઈની છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "What is it?",
            "hi": "यह क्या है?",
            "ka": "ಏನದು?",
            "te": "ఇది ఏమిటి?",
            "or": "ଏହା କଣ ?",
            "as": "কি এইখন ?",
            "gu": "આ શું છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "It is a prospectus. He is applying for the ITI (Industrial Training Institute) course after his 10th standard.",
            "hi": "यह प्रोस्पेक्टस है। वह 10वीं कक्षा के बाद ITI (इंडस्ट्रीयल ट्रेनिंग इंस्टीट्यूट) के कोर्स के लिए अप्लाई कर रहा है।",
            "ka": "ಇದು ಪ್ರಾಸ್ಪೆಕ್ಟಸ್ ಆಗಿದೆ. ಅವರು 10 ನೇ ತರಗತಿಯ ನಂತರ ಐಟಿಐ (ಇಂಡಸ್ಟ್ರಿಯಲ್ ಟ್ರೈನಿಂಗ್ ಇನ್ಸ್ಟಿಟ್ಯೂಟ್) ಕೋರ್ಸ್‌ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಿದ್ದಾನೆ.",
            "te": "ఇది ఒక ప్రాస్పెక్టస్. అతను తన 10వ తరగతి తర్వాత ITI (ఇండస్ట్రియల్ ట్రైనింగ్ ఇన్స్టిట్యూట్) కోర్సు కోసం దరఖాస్తు చేస్తున్నాడు.",
            "or": "ଏହା ଏକ ପ୍ରୋସପେକ୍ଟସ୍ | ସେ ତାଙ୍କର ଦଶମ ମାନକ ପରେ ITI (ଇଣ୍ଡଷ୍ଟ୍ରିଆଲ୍ ଟ୍ରେନିଂ ଇନଷ୍ଟିଚ୍ୟୁଟ୍) ପାଠ୍ୟକ୍ରମ ପାଇଁ ଆବେଦନ କରୁଛନ୍ତି |",
            "as": "এইখন এখন প্ৰস্পেক্টাচ। তেওঁ তেওঁৰ দশম শ্ৰেণীৰ পিছত ITI (ঔদ্যোগিক প্ৰশিক্ষণ প্ৰতিষ্ঠান) পাঠ্যক্ৰমৰ বাবে আবেদন কৰিছে।",
            "gu": "આ પ્રોસ્પેક્ટસ છે. તે ITI (ઔદ્યોગિક તાલીમ સંસ્થા) કોર્સ માટે અરજી કરી રહ્યો છે તેના 10મા ધોરણ પછી."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.text",
          "value": {
            "en": "Oh! Those mechanic and fitter colleges, right?",
            "hi": "ओह! वह मैकेनिक और फिटर का कॉलेज, बराबर?",
            "ka": "ಓಹ್! ಆ ಮೆಕ್ಯಾನಿಕ್ ಮತ್ತು ಫಿಟ್ಟರ್ ಕಾಲೇಜುಗಳು, ಸರಿ?",
            "te": "అవునా! ఆ మెకానిక్ మరియు ఫిట్టర్ కళాశాలలు, కదా?",
            "or": "ଓହୋ ! ସେହି ମେକାନିକ୍ ଏବଂ ଫିଟର୍ କଲେଜଗୁଡିକ, ଠିକ୍?",
            "as": "অহ! সেই মেকানিক আৰু ফিটাৰ কলেজবোৰ, হয় নে?",
            "gu": "ઓહ! તે મિકેનિક અને ફિટર કોલેજોનું, બરાબર ને?"
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_3////",
            "hi": "A1_2_3////",
            "ka": "A1_2_3////",
            "te": "A1_2_3////",
            "or": "A1_2_3////",
            "as": "A1_2_3////",
            "gu": "A1_2_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "It’s not just that. There are many skills you can get trained for. My brother is applying because he wants a government job.",
            "hi": "बस इतना ही नहीं। तुम और भी कई कौशल का प्रशिक्षण ले सकते हो। मेरा भाई सरकारी जॉब करना चाहता है इसलिए अप्लाई कर रहा है।",
            "ka": "ಇದು ಕೇವಲ ಅಲ್ಲ. ನೀನು ತರಬೇತಿ ಪಡೆಯಬಹುದಾದ ಹಲವು ಕೌಶಲ್ಯಗಳಿವೆ. ನನ್ನ ಸಹೋದರ ಸರ್ಕಾರಿ ನೌಕರಿ ಬೇಕು ಎಂಬ ಕಾರಣಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಿದ್ದಾನೆ.",
            "te": "ఇది మాత్రమే కాదు. మీరు నేర్చుకోడానికి చాలా నైపుణ్యాలు ఉన్నాయి. ప్రభుత్వ ఉద్యోగం కావాలని మా తమ్ముడు దరఖాస్తు చేస్తున్నాడు.",
            "or": "ଏହା କେବଳ ଏତିକି ନୁହେଁ | ସେଠାରେ ଅନେକ କୌଶଳ ଅଛି ଯାହା ପାଇଁ ତୁମେ ତାଲିମ ପାଇପାରିବେ | ମୋ ଭାଇ ଆବେଦନ କରୁଛନ୍ତି କାରଣ ସେ ସରକାରୀ ଚାକିରି ଚାହୁଁଛନ୍ତି।",
            "as": "কেৱল সেয়াই নহয়। তুমি প্ৰশিক্ষণ লাভ কৰিব পৰা আৰু বহুতো দক্ষতা আছে। মোৰ ভাতৃয়ে আবেদন কৰিছে কাৰণ তেওঁ চৰকাৰী চাকৰি বিচাৰে।",
            "gu": "તે તેટલું જ નથી. ત્યાં ગણી આવડત સિખાવ માટે તાલીમ મળે છે. મારા ભાઈને સરકારી નોકરી મેળવવી છે. "
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "You mean if we go to an ITI, we can get a government job?",
            "hi": "तुम्हारा मतलब है कि अगर हम ITI करेंगे तो हमें सरकारी जॉब मिल सकती है?",
            "ka": "ಐಟಿಐಗೆ ಹೋದರೆ ಸರ್ಕಾರಿ ಕೆಲಸ ಸಿಗುತ್ತದೆಯೇ?",
            "te": "ITI కి వెళితే ప్రభుత్వ ఉద్యోగం దొరుకుతుందని నువ్వు అనుకుంటున్నావా?",
            "or": "ତୁମ କହିବା ମାନେ ଯଦି ଆମେ ଏକ ITI କୁ ଯାଉ, ଆମେ ଏକ ସରକାରୀ ଚାକିରି ପାଇପାରିବା କି ?",
            "as": "তুমি বুজাব বিচাৰিছা যদি আমি ITIলৈ যাওঁ, আমি চৰকাৰী চাকৰি এটা পাব পাৰো?",
            "gu": "તો તારો મતલબ કે જો આપણે ITI કરીયે તો સરકારી નોકરી મળે?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "That's only the first step. It’s just that chances of getting a government job after ITI are better than applying for a role without any training.",
            "hi": "यह बस पहला कदम है। लेकिन बिना प्रशिक्षण के बजाय ITI करने के बाद सरकारी जॉब मिलने की संभावना कई ज्यादा है।",
            "ka": "ಅದು ಮೊದಲ ಹೆಜ್ಜೆ ಮಾತ್ರ. ಯಾವುದೇ ತರಬೇತಿಯಿಲ್ಲದ ಪಾತ್ರಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದಕ್ಕಿಂತ ಐಟಿಐ ನಂತರ ಸರ್ಕಾರಿ ಉದ್ಯೋಗವನ್ನು ಪಡೆಯುವ ಅವಕಾಶಗಳು ಉತ್ತಮವಾಗಿದೆ.",
            "te": "అది మొదటి దశ మాత్రమే. ఎలాంటి ట్రైనింగ్ లేని పాత్ర కోసం దరఖాస్తు చేసుకోవడం కంటే ITI తర్వాత ప్రభుత్వ ఉద్యోగం వచ్చే అవకాశాలు ఎక్కువ ఉంటాయి.",
            "or": "ତାହା କେବଳ ପ୍ରଥମ ପଦକ୍ଷେପ ଅଟେ | କୌଣସି ତାଲିମ ବିନା ଏକ ଭୂମିକା ପାଇଁ ଆବେଦନ କରିବା ଅପେକ୍ଷା ITI ପରେ ଏକ ସରକାରୀ ଚାକିରି ପାଇବାର ସମ୍ଭାବନା ଅଧିକ ଥାଏ |",
            "as": "সেইটো কেৱল প্ৰথম পদক্ষেপ। এয়া মাত্ৰ এনেধৰণৰ যে কোনো প্ৰশিক্ষণ অবিহনে চাকৰি এটাৰ বাবে আবেদন কৰাতকৈ ITIৰ পিছত চৰকাৰী চাকৰি পোৱাৰ সম্ভাৱনা ভাল।",
            "gu": "તે બસ પહેલું પગલું છે. ITI પછી સરકારી નોકરી મેળવવાના રસ્તા સરળ છે જો સીધા તેમાં અરજી કર્યા કરતાં."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Hmm, Amma was saying that I should take up something that gives me stability in life.",
            "hi": "हम्म, अम्मा कह रही थी कि मुझे कुछ ऐसा करना चाहिए जिससे मेरे जीवन में स्थिरता बनी रहें।",
            "ka": "ಹಾಂ, ಜೀವನದಲ್ಲಿ ನನಗೆ ಸ್ಥಿರತೆ ನೀಡುವ ಯಾವುದನ್ನಾದರೂ ನಾನು ತೆಗೆದುಕೊಳ್ಳಬೇಕು ಎಂದು ಅಮ್ಮ ಹೇಳುತ್ತಿದ್ದಳು.",
            "te": "అవునా.. అమ్మ నాకు జీవితంలో స్థిరత్వం ఇచ్చేది తీసుకోవాలి అని చెప్పింది.",
            "or": "ହମ୍, ଆମ୍ମା କହୁଥିଲା ଯେ ମୁଁ ଏପରି କିଛି ନେବା ଉଚିତ ଯାହା ମୋତେ ଜୀବନରେ ସ୍ଥିରତା ଦେବ |",
            "as": "হুম, মায়ে কৈ আছিল যে মই এনে কিবা এটা লোৱা উচিত যি মোক জীৱনত স্থিৰতা প্ৰদান কৰে।",
            "gu": "બરાબર, મારી માં મને કહેતી હતી કે મારે કઈક કરવું જોઈએ જેથી મારી જિંદગીમાં સ્થિરતા આવે"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "You know what it means to get a government job, right? Life is set! It’s stable forever.",
            "hi": "सरकारी जॉब मिलने का मतलब क्या है पता है ना? जिंदगी एकदम सेट! हमेशा के लिए सब अच्छा हो जाएगा।",
            "ka": "ಸರ್ಕಾರಿ ನೌಕರಿ ಎಂದರೆ ಏನು ಗೊತ್ತಾ? ಜೀವನ ಹೊಂದಿಸಲಾಗಿದೆ! ಇದು ಶಾಶ್ವತವಾಗಿ ಸ್ಥಿರವಾಗಿರುತ್ತದೆ.",
            "te": "ప్రభుత్వ ఉద్యోగం పొందటం అంటే ఏమిటో మీకు తెలుసా? లైఫ్ సెట్ అవుతుంది! ఇది ఎప్పటికీ స్థిరంగా ఉంటుంది.",
            "or": "ଏକ ସରକାରୀ ଚାକିରି ପାଇବା ଏହାର ଅର୍ଥ କଣ ତୁମେ ଜାଣିଛ, ଠିକ୍ ? ଜୀବନ ସ୍ଥିର ! ଏହା ସବୁଦିନ ପାଇଁ ସ୍ଥିର ଅଟେ |",
            "as": "তুমি জানানে চৰকাৰী চাকৰি এটা পোৱাৰ অৰ্থ কি? জীৱন নিৰ্ধাৰিত! ই চিৰদিনৰ বাবে স্থিৰ।",
            "gu": "તને ખબર છે સરકારી નોકરી મેળવવાનો મતલબ શું છે જીવનમાં? જીવન કાઇમી સ્થિર થઈ જાય."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_4_D",
            "hi": "A1_2_4_D",
            "ka": "A1_2_4_D",
            "te": "A1_2_4_D",
            "or": "A1_2_4_D",
            "as": "A1_2_4_D",
            "gu": "A1_2_4_D"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Help Naveen decide his next step in his career by selecting what to say next?",
            "hi": "आगे नवीन को क्या जवाब देना चाहिए इसका चुनाव करके उसका अगला कदम तय करने में नवीन की मदद करें।",
            "ka": "ಮುಂದೆ ಏನು ಹೇಳಬೇಕೆಂದು ಆಯ್ಕೆ ಮಾಡುವ ಮೂಲಕ ನವೀನ್ ತನ್ನ ವೃತ್ತಿಜೀವನದ ಮುಂದಿನ ಹಂತವನ್ನು ನಿರ್ಧರಿಸಲು ಸಹಾಯ ಮಾಡುವುದೇ?",
            "te": "తరువాత ఏం చేయాలో ఎంపిక చేయడం ద్వారా నవీన్ తన వృత్తిలో తన తర్వాత దశను నిర్ణయించడంలో సహాయం చేస్తారా?",
            "or": "ପରବର୍ତ୍ତୀ କ’ଣ କହିବେ ତାହା ଚୟନ କରି ନବୀନଙ୍କୁ ତାଙ୍କ କ୍ୟାରିୟରରେ ତାଙ୍କର ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପ ସ୍ଥିର କରିବାରେ ସାହାଯ୍ୟ କରନ୍ତୁ ?",
            "as": "নবীনক পৰৱৰ্তী সময়ত কি ক'ব লাগে বাছনি কৰি তেওঁৰ কেৰিয়াৰৰ পৰৱৰ্তী পদক্ষেপটো নিৰ্ণয় কৰাত সহায় কৰা?",
            "gu": "નવીનને મદદ કરો તેની કારકિર્દી બનાવવામાં આગળના જીવનમાં. આગળ શું કહેવું છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "That means I would have to study more. I could instead earn through other jobs.",
            "hi": "इसका मतलब यह है कि मुझे बहुत पढ़ाई करनी पड़ेगी। इसके बजाय मैं दुसरे जॉब से पैसा कमा सकता हूँ।",
            "ka": "ಅಂದರೆ ನಾನು ಹೆಚ್ಚು ಅಧ್ಯಯನ ಮಾಡಬೇಕು. ನಾನು ಬದಲಿಗೆ ಇತರ ಉದ್ಯೋಗಗಳ ಮೂಲಕ ಗಳಿಸಬಹುದು.",
            "te": "అంటే నేను ఇంకా చదువుకోవాలి. నేను వేరే ఉద్యోగాల ద్వారా సంపాదించాలి.",
            "or": "ଏହାର ଅର୍ଥ ମୋତେ ଅଧିକ ଅଧ୍ୟୟନ କରିବାକୁ ପଡିବ | ମୁଁ ଏହା ପରିବର୍ତ୍ତେ ଅନ୍ୟ ଚାକିରି ମାଧ୍ୟମରେ ରୋଜଗାର କରିପାରିବି |",
            "as": "ইয়াৰ অৰ্থ হৈছে মই অধিক অধ্যয়ন কৰিব লাগিব। মই তাৰ সলনি অন্য চাকৰিৰ জৰিয়তে উপাৰ্জন কৰিব পাৰিম।",
            "gu": "તેનો મતલબ કે મારે વધારે ભણવું પડશે. તેના માટે હું બીજી નોકરીઓ દ્વારા કમાણી કરી શકું છું"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "It's actually a good idea. But that would mean letting go of dance.",
            "hi": "यह सच में अच्छी कल्पना है। लेकिन इसके लिए डान्स करना छोड़ना होगा।",
            "ka": "ಇದು ವಾಸ್ತವವಾಗಿ ಒಳ್ಳೆಯ ಉಪಾಯ. ಆದರೆ ಅದು ನೃತ್ಯವನ್ನು ಬಿಡುವುದು ಎಂದರ್ಥ.",
            "te": "నిజానికి ఇది మంచి ఆలోచన. అయితే అది డ్యాన్స్ ని వదులుకోవడం అని అర్థం.",
            "or": "ଏହା ବାସ୍ତବରେ ଏକ ଭଲ ଧାରଣା | କିନ୍ତୁ ଏହାର ଅର୍ଥ ନୃତ୍ୟ ଛାଡିବାକୁ ହେବ |",
            "as": "এইটো প্ৰকৃততে এটা ভাল ধাৰণা। কিন্তু ইয়াৰ অৰ্থ হ'ব নৃত্য এৰি দিয়া।",
            "gu": "ખરેખર આ સારો વિચાર છે. પણ તેના માટે મારે નૃત્ય છોડવું પડશે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.tag",
          "value": {
            "en": "Exploration",
            "hi": "एक्सप्लोर करना",
            "ka": "ಪರಿಶೋಧನೆ",
            "te": "అవగాహన పొందడం",
            "or": "ଏକ୍ସପ୍ଲୋରେସନ୍‌",
            "as": "অন্বেষণ",
            "gu": "સંશોધન"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.tag",
          "value": {
            "en": "Stability",
            "hi": "स्थिरता",
            "ka": "ಸ್ಥಿರತೆ",
            "te": "స్థిరత్వం",
            "or": "ସ୍ଥିରତା",
            "as": "স্থিৰতা",
            "gu": "સ્થિરતા"
          }
        }
      ]
    },
    "scene3": {
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "Reflection_Focus on studies",
            "hi": "पढ़ाई पर ध्यान केंद्रित करना",
            "ka": "ಪ್ರತಿಬಿಂಬ_ಅಧ್ಯಯನಗಳ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಿ",
            "te": "ప్రతిబింబించడం -చదువుపై శ్రద్ధ చూపండి",
            "or": "Reflection_Focus on studies",
            "as": "প্ৰতিফলন_অধ্যয়নৰ ওপৰত গুৰুত্ব",
            "gu": "ચિંતન_અભ્યાસ પર ધ્યાન આપો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just learnt about Reflection with others!",
            "hi": "आपने अभी-अभी दूसरों के साथ रिफ्लेक्शन करना सीखा हैं!",
            "ka": "ನೀವು ಇತರರೊಂದಿಗೆ ಪ್ರತಿಫಲನದ ಬಗ್ಗೆ ಕಲಿತಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే ఇతరుల నుండి ప్రతిబింబించడం గురించి తెలుసుకున్నారు!",
            "or": "ଆପଣ ବର୍ତମାନ ଅନ୍ୟମାନଙ୍କ ସହିତ ରିଫ୍ଲେକ୍ସନ୍‌ ବିଷୟରେ ଶିଖିଲେ !",
            "as": "তুমি এইমাত্ৰ আনৰ সৈতে প্ৰতিফলনৰ বিষয়ে শিকিলা!",
            "gu": "તમે બીજા લોકો સાથે હમણાં જ ચિંતન વિશે શીખ્યા!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Reflection with others",
            "hi": "दूसरों के साथ रिफ्लेक्शन",
            "ka": "ಇತರರೊಂದಿಗೆ ಪ್ರತಿಫಲನ",
            "te": "ఇతరులతో ప్రతిబింబించండి",
            "or": "ଅନ୍ୟମାନଙ୍କ ସହିତ ରିଫ୍ଲେକ୍ସନ୍‌",
            "as": "আনৰ সৈতে প্ৰতিফলন",
            "gu": "બીજા જોડે ચિંતન"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Self Development",
            "hi": "स्वयं विकास",
            "ka": "ಸ್ವಯಂ ಅಭಿವೃದ್ಧಿ",
            "te": "స్వీయ అభివృద్ధి",
            "or": "ଆତ୍ମ ବିକାଶ",
            "as": "আত্ম বিকাশ",
            "gu": "જાત વિકાસ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Naveen talks to his friends to get clarity about his options and analyse what to do next. Reflection is thinking about your actions and what you could do better and is primarily an independent activity. You can sometimes reflect with peers and friends and reason out the options with them to get clarity. While talking with friends/mentors, you need to know that you cannot share everything with them, but you need to be truthful about the information you share with them.",
            "hi": "नवीन अपने दोस्तों के साथ बात करके अपने विकल्पों के बारे में जानकारी प्राप्त करता है और आगे क्या करना चाहिए इसके बारे में सोचता है। रिफ्लेक्शन का मतलब है अपने कार्यों के बारे में और अपने लिए क्या बेहतर कर सकते हैं इसके बारे में सोचना और यह एक स्वतंत्र एक्टिविटी है। आप कभी-कभी अपने दोस्तों और साथियों के साथ रिफ्लेक्ट कर सकते हैं और स्पष्ट कल्पना प्राप्त करने के लिए उनके साथ चर्चा कर सकते हैं। दोस्तों/प्रशिक्षकों के साथ बार करते हुए, आपको इस बात का ध्यान रखना पड़ेगा कि आप उन्हें सब कुछ नहीं बता सकते, लेकिन आपन उन्हें जो बताएं वह जानकारी सच्ची होनी चाहिए।",
            "ka": "ನವೀನ್ ತನ್ನ ಆಯ್ಕೆಗಳ ಬಗ್ಗೆ ಸ್ಪಷ್ಟತೆ ಪಡೆಯಲು ಮತ್ತು ಮುಂದೆ ಏನು ಮಾಡಬೇಕೆಂದು ವಿಶ್ಲೇಷಿಸಲು ತನ್ನ ಸ್ನೇಹಿತರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾನೆ. ಪ್ರತಿಬಿಂಬವು ನಿಮ್ಮ ಕ್ರಿಯೆಗಳ ಬಗ್ಗೆ ಯೋಚಿಸುವುದು ಮತ್ತು ನೀವು ಉತ್ತಮವಾಗಿ ಏನು ಮಾಡಬಹುದು ಮತ್ತು ಪ್ರಾಥಮಿಕವಾಗಿ ಸ್ವತಂತ್ರ ಚಟುವಟಿಕೆಯಾಗಿದೆ. ನೀವು ಕೆಲವೊಮ್ಮೆ ಗೆಳೆಯರು ಮತ್ತು ಸ್ನೇಹಿತರೊಂದಿಗೆ ಪ್ರತಿಬಿಂಬಿಸಬಹುದು ಮತ್ತು ಸ್ಪಷ್ಟತೆಯನ್ನು ಪಡೆಯಲು ಅವರೊಂದಿಗೆ ಆಯ್ಕೆಗಳನ್ನು ತರ್ಕಿಸಬಹುದು. ಸ್ನೇಹಿತರು/ಮಾರ್ಗದರ್ಶಿಗಳೊಂದಿಗೆ ಮಾತನಾಡುವಾಗ, ನೀವು ಅವರೊಂದಿಗೆ ಎಲ್ಲವನ್ನೂ ಹಂಚಿಕೊಳ್ಳಲು ಸಾಧ್ಯವಿಲ್ಲ ಎಂದು ನೀವು ತಿಳಿದುಕೊಳ್ಳಬೇಕು, ಆದರೆ ನೀವು ಅವರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳುವ ಮಾಹಿತಿಯ ಬಗ್ಗೆ ನೀವು ಸತ್ಯವಾಗಿರಬೇಕು.",
            "te": "నవీన్ తన ఎంపికల గురించి స్పష్టత పొందడానికి మరియు తర్వాత ఏమి చేయాలో తెలుసుకోవడానికి అతని స్నేహితులతో మాట్లాడతాడు. ప్రతిబింబించడం అనేది మీ చర్యల గురించి మరియు మీరు బాగా చేయగలిగిన దాని గురించి ఆలోచించడం మరియు ఇది ఒక స్వతంత్ర కార్యాచరణ. మీరు కొన్నిసార్లు స్పష్టత కోసం సహచరులు మరియు స్నేహితులతో ప్రతిబింబించడం చేసి కొన్ని ఎంపికలు తీసివేయవచ్చు. స్నేహితులు/సలహాదారులతో మాట్లాడుతున్నప్పుడు, మీరు వారితో ప్రతిదీ పంచుకోలేరని మీరు తెలుసుకోవాలి, కానీ మీరు వారితో పంచుకునే సమాచారం మాత్రం నిజమై ఉండాలి.",
            "or": "ତାଙ୍କ ବିକଳ୍ପଗୁଡ଼ିକ ବିଷୟରେ ସ୍ପଷ୍ଟତା ପାଇବାକୁ ଏବଂ ପରବର୍ତ୍ତୀ ସମୟରେ କଣ କରିବାକୁ ବିଶ୍ଳେଷଣ କରିବାକୁ ନବୀନ ତାଙ୍କ ସାଙ୍ଗମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରନ୍ତି | ତୁମର କାର୍ଯ୍ୟଗୁଦିକ ବିଷୟରେ ଚିନ୍ତା ଏବଂ ତୁମେ କଣ ଭଲ କରି ପାରିବ ଏବଂ ମୁଖ୍ୟତ ଏକ ସ୍ୱାଧୀନ କାର୍ଯ୍ୟକଳାପ ହେଉଛି ରିଫ୍ଲେକ୍ସନ୍‌ | ଆପଣ ବେଳେବେଳେ ସାମର୍ଥ୍ଯ ବ୍ଯକ୍ତି ଏବଂ ସାଙ୍ଗମାନଙ୍କ ସହିତ ରିଫ୍ଲେକ୍ଟ କରିପାରନ୍ତି ଏବଂ ସ୍ପଷ୍ଟତା ପାଇବା ପାଇଁ ସେମାନଙ୍କ ସହିତ ବିକଳ୍ପଗୁଡିକ ବାହାର କରନ୍ତୁ | ସାଙ୍ଗ/ମେଣ୍ଟରମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରିବାବେଳେ, ଆପଣ ଜାଣିବା ଆବଶ୍ୟକ ଯେ ଆପଣ ସେମାନଙ୍କ ସହିତ ସବୁକିଛି ଅଂଶୀଦାର କରିପାରିବେ ନାହିଁ, କିନ୍ତୁ ଆପଣ ସେମାନଙ୍କ ସହିତ ଅଂଶୀଦାର କରୁଥିବା ସୂଚନା ବିଷୟରେ ସତ୍ୟବାଦୀ ହେବା ଆବଶ୍ୟକ |",
            "as": "নবীনে তেওঁৰ বিকল্পবোৰৰ বিষয়ে স্পষ্টতা পাবলৈ আৰু পৰৱৰ্তী সময়ত কি কৰিব লাগে বিশ্লেষণ কৰিবলৈ তেওঁৰ বন্ধুবৰ্গৰ সৈতে কথা পাতে। প্ৰতিফলন হৈছে তোমাৰ কামৰ বিষয়ে চিন্তা কৰা আৰু তুমি কি ভাল কৰিব পাৰা আৰু ই হৈছে মুখ্যতঃ এক স্বতন্ত্ৰ কাৰ্যকলাপ। তুমি কেতিয়াবা সহকৰ্মী আৰু বন্ধুবৰ্গৰ সৈতে প্ৰতিফলন কৰিব পাৰা আৰু স্পষ্টতা পাবলৈ তেওঁলোকৰ সৈতে বিকল্পবোৰ হিচাপে যুক্তি দিব পাৰা। বন্ধু/পৰামৰ্শদাতাৰ সৈতে কথা পাতি থাকোঁতে, তুমি জানিব লাগিব যে তুমি সকলোবোৰ তেওঁলোকৰ সৈতে ভাগ-বতৰা কৰিব নোৱাৰা, কিন্তু তুমি তেওঁলোকৰ সৈতে ভাগ-বতৰা কৰা তথ্যৰ বিষয়ে সত্যবাদী হ'ব লাগিব।",
            "gu": "નવીન તેના વિકલ્પો ચોખવટ રીતે સમજવા અને આગળ શું કરવું તે જોવા માટે તેના મિત્રો સાથે વાત કરે છે. ચિંતન એ તમે શું કરી રહ્યા છો અને તમે શું સારું કરવા જઈ રહ્યા છો તેના વિશે વિચારવાનું છે અને તે એક સ્વતંત્ર કાર્યક્રમ પણ છે. કેટલીકવાર તમે સાથીદારો અને મિત્રો સાથે ચિંતન કરી શકો છો, અને પછી તેમની સાથે ચોખવટ માટેના વિકલ્પોની ચર્ચા કરી શકો છો. મિત્રો અને માર્ગદર્શકકારો સાથે વાત કરતી વખતે, તમારે ધ્યાન રાખવું જોઈએ કે તમે તેમને બધું બતાવી નથી રહ્યા, પરંતુ તમે જે માહિતી કહો છો તેના વિશે તમારે પ્રમાણિક હોવું જોઈએ."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQs\n_copy_copy",
            "hi": "MCQs\n _copy_copy",
            "ka": "ಎಂಸಿಕ್ಯೂಸ್_ಕಾಪಿ_ಕಾಪಿ",
            "te": "MCQలు\n _కాపీ_కాపీ",
            "or": "MCQs\n _copy_copy",
            "as": "MCQs",
            "gu": "MCQs\n_નકલ_નકલ"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Select a statement about reflection that is TRUE:",
            "hi": "रिफ्लेक्शन के बारे में कौन सा विधान सही है वह चुनें:",
            "ka": "ಪ್ರತಿಬಿಂಬದ ಬಗ್ಗೆ ಒಂದು ಹೇಳಿಕೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ ಅದು ನಿಜವಾಗಿದೆ:",
            "te": "ప్రతిబింబించడం గురించి సరైన ప్రకటనను ఎంచుకోండి:",
            "or": "ରିଫ୍ଲେକ୍ସନ୍‌ ବିଷୟରେ ଏକ ବିବୃତ୍ତି ବାଛନ୍ତୁ ଯାହାକି ସତ୍ୟ:",
            "as": "প্ৰতিফলনৰ বিষয়ে এটা বিবৃতি বাছনি কৰা যিটো সঁচা:",
            "gu": "એક વાક્ય નક્કી કારો જે ચિંતન સાથે સાચું છે:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Reflection is a great way to learn from your experiences.",
            "hi": "रिफ्लेक्शन अपने अनुभवों से सीखने का सबसे अच्छा तारिका है।",
            "ka": "ನಿಮ್ಮ ಅನುಭವಗಳಿಂದ ಕಲಿಯಲು ಪ್ರತಿಬಿಂಬವು ಉತ್ತಮ ಮಾರ್ಗವಾಗಿದೆ.",
            "te": "ప్రతిబింబించడం అనేది మీ అనుభవాల నుండి నేర్చుకోవడానికి ఒక గొప్ప మార్గం.",
            "or": "ତୁମର ଅନୁଭୂତିରୁ ଶିଖିବା ପାଇଁ ରିଫ୍ଲେକ୍ସନ୍‌ ହେଉଛି ଏକ ଉତ୍ତମ ଉପାୟ |",
            "as": "প্ৰতিফলন হৈছে তোমাৰ অভিজ্ঞতাৰ পৰা শিকাৰ এক উৎকৃষ্ট উপায়।",
            "gu": "ચિંતન એ તમારા અનુભવોથી શીખવાનો એક રસ્તો છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Reflection is an activity done by oneself as well as with others.",
            "hi": "रिफ्लेक्शन ऐसी एक्टिविटी है जो खुद के साथ-साथ दूसरों के साथ भी की जाती हैं।",
            "ka": "ಪ್ರತಿಬಿಂಬವು ಸ್ವತಃ ಮತ್ತು ಇತರರೊಂದಿಗೆ ಮಾಡುವ ಚಟುವಟಿಕೆಯಾಗಿದೆ.",
            "te": "ప్రతిబింబించడం అనేది తనతో తాను మరియు ఇతరులతో చేసే కార్యాచరణ.",
            "or": "ରିଫ୍ଲେକ୍ସନ୍‌ ହେଉଛି ନିଜ‍ ତଥା ଅନ୍ୟମାନଙ୍କ ସହିତ କରାଯାଇଥିବା ଏକ କାର୍ଯ୍ୟକଳାପ |",
            "as": "প্ৰতিফলন হৈছে নিজৰ লগতে আনৰ সৈতে কৰা এক কাৰ্যকলাপ।",
            "gu": "ચિંતનએક એવી પ્ર્વુતી છે જે પોતે અને બીજા દ્વારા થાય છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Reflection can help us get clarity to make better decisions",
            "hi": "रिफ्लेक्शन बेहतर निर्णय लेने के लिए स्पष्टता प्राप्त करने में मदद कर सकता हैं।",
            "ka": "ಪ್ರತಿಬಿಂಬವು ಉತ್ತಮ ನಿರ್ಧಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಸ್ಪಷ್ಟತೆಯನ್ನು ಪಡೆಯಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ",
            "te": "ప్రతిబింబించడం మంచి నిర్ణయాలు తీసుకోవడానికి స్పష్టత కోసం మనకు సహాయపడుతుంది",
            "or": "ଆମକୁ ଉତ୍ତମ ନିଷ୍ପତ୍ତି ନେବାରେ ସ୍ପଷ୍ଟତା ଆଣିବାକୁ ରିଫ୍ଲେକ୍ସନ୍‌ ସାହାଯ୍ୟ କରିଥାଏ",
            "as": "প্ৰতিফলনে আমাক উন্নত সিদ্ধান্ত ল'বলৈ স্পষ্টতা প্ৰাপ্ত কৰাত সহায় কৰিব পাৰে",
            "gu": "ચિંતન આપણને વધુ સારા નિર્ણયો લેવામાં ચોખવટ રીતે મદદ કરી શકે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.display",
          "value": {
            "en": "Reflection is only done by writing in a quiet environment",
            "hi": "रिफ्लेक्शन शांत वातावरण में लिखकर किया जाता है",
            "ka": "ಪ್ರತಿಬಿಂಬವನ್ನು ಶಾಂತ ವಾತಾವರಣದಲ್ಲಿ ಬರೆಯುವ ಮೂಲಕ ಮಾತ್ರ ಮಾಡಲಾಗುತ್ತದೆ",
            "te": "ప్రశాంతమైన ప్రదేశంలో రాయడం ద్వారా మాత్రమే ప్రతిబింబించడం జరుగుతుంది",
            "or": "ରିଫ୍ଲେକ୍ସନ୍‌ କେବଳ ଏକ ଶାନ୍ତ ପରିବେଶରେ ଲେଖି କରାଯାଇଥାଏ",
            "as": "প্ৰতিফলন কেৱল এক শান্ত পৰিৱেশত লিখিহে কৰা হয়",
            "gu": "શાંત વાતાવરણમાં લખીને જ અનુવાદ કરવામાં આવે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Great Job",
            "hi": "बहुत बढ़िया",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ",
            "te": "గొప్ప పని",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ",
            "as": "ভাল কৰিছা !",
            "gu": "સરસ કામ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great Job",
            "hi": "बहुत बढ़िया",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ",
            "te": "గొప్ప పని",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ",
            "as": "ভাল কৰিছা !",
            "gu": "સરસ કામ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "बहुत बढ़िया!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ",
            "te": "గొప్ప పని!",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ !",
            "as": "ভাল কৰিছা !",
            "gu": "સરસ કામ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.popUpText",
          "value": {
            "en": "Think again. Reflection could also be done by talking to others, \nand while taking a walk and thinking by yourself/making a voice note.",
            "hi": "फिर से सोचो। रिफ्लेक्शन दूसरों के साथ बात करके भी किया जा सकता है\n और साथ ही चलते हुए और सोचते हुए/वॉईस नोट बनाते हुए भी किया जा सकता है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು. ಇತರರೊಂದಿಗೆ ಮಾತನಾಡುವ ಮೂಲಕ ಪ್ರತಿಬಿಂಬವನ್ನು ಸಹ ಮಾಡಬಹುದು,\n ಮತ್ತು ವಾಕ್ ಮಾಡುವಾಗ ಮತ್ತು ನೀವೇ ಯೋಚಿಸುವಾಗ/ಧ್ವನಿ ಟಿಪ್ಪಣಿ ಮಾಡುವಾಗ.",
            "te": "మళ్ళీ ఆలోచించండి. ఇతరులతో మాట్లాడటం వల్ల కూడా ప్రతిబింబించడం జరుగుతుంది,\n మరియు ఒంటరిగా నడుస్తూ స్వతంత్రంగా ఆలోచిస్తూ/వాయిస్ నోట్ తయారు చేస్తున్నప్పుడు.",
            "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ। ରିଫ୍ଲେକ୍ସନ୍‍ ଅନ୍ୟମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରି ମଧ୍ୟ କରାଯାଇପାରେ,\n ଏବଂ ବୁଲିବା ଏବଂ ନିଜେ ଚିନ୍ତା କରିବା ସମୟରେ/ଏକ ଭଏସ୍ ନୋଟ୍ ତିଆରି କରି |",
            "as": "আকৌ চিন্তা কৰা। আনৰ সৈতে কথা পাতিও আৰু খোজ কাঢ়ি থাকোঁতে আৰু নিজে চিন্তা কৰোঁতে/এটা ভইচ নোট তৈয়াৰ কৰোঁতেও প্ৰতিফলন কৰিব পাৰি ৷",
            "gu": "વિચારો ફરીથી. ચિંતન બીજા સાથે વાત કરીને પણ કરી શકાય છે,\nઅને જ્યારે તમે આસપાસ ફરો છો અને ફક્ત તમારા વિશે જ વિચારો છો / અવાજ રેકોર્ડ કરો."
          }
        }
      ],
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_1_1",
            "hi": "B1_1_1",
            "ka": "B1_1_1",
            "te": "B1_1_1",
            "or": "B1_1_1",
            "as": "B1_1_1",
            "gu": "B1_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen studies hard and clears his 12th standard. He is the first person in his family who has finished school, and the family is thrilled with Naveen.",
            "hi": "नवीन बहुत पढ़ाई करता है और 12वीं कक्षा में पास होता है। उसके परिवार का वह पहला सदस्य है जिसने स्कूल पूरा किया है और इसलिए परिवार के लोग बहुत खुश हैं।",
            "ka": "ನವೀನ್ ಕಷ್ಟಪಟ್ಟು ಓದಿ 12ನೇ ತರಗತಿಯಲ್ಲಿ ತೇರ್ಗಡೆಯಾಗಿದ್ದಾನೆ. ಅವರ ಕುಟುಂಬದಲ್ಲಿ ಶಾಲೆ ಮುಗಿಸಿದ ಮೊದಲ ವ್ಯಕ್ತಿ ಈತ, ನವೀನ್‌ನಿಂದ ಕುಟುಂಬ ರೋಮಾಂಚನಗೊಂಡಿದೆ.",
            "te": "నవీన్ కష్టపడి 12వ తరగతి చదివి 12 వ తరగతి పూర్తి చేస్తాడు. తన కుటుంబంలో చదువు పూర్తి చేసిన మొదటి వ్యక్తి అతనే, నవీన్‌ గురించి తన ఫ్యామిలీ చాలా సంతోషంగా ఉన్నారు.",
            "or": "ନବୀନ କଠିନ ଅଧ୍ୟୟନ କରନ୍ତି ଏବଂ ତାଙ୍କ ଦ୍ୱାଦଶ ଶ୍ରେଣୀ ଉତ୍ତୀର୍ଣ୍ଣ କରନ୍ତି | ସେ ତାଙ୍କ ପରିବାରର ପ୍ରଥମ ବ୍ୟକ୍ତି ଯିଏକି ସ୍କୁଲ ଶେଷ କରିଛନ୍ତି, ଏବଂ ନବୀନଙ୍କ ସହିତ ପରିବାର ଆନନ୍ଦିତ |",
            "as": "নবীনে কঠোৰ অধ্যয়ন কৰে আৰু তেওঁৰ দ্বাদশ শ্ৰেণী উত্তীৰ্ণ হয়। তেওঁ তেওঁৰ পৰিয়ালৰ প্ৰথম ব্যক্তি যিয়ে স্কুল সমাপ্ত কৰিছে, আৰু পৰিয়ালটো নবীনৰ সৈতে ৰোমাঞ্চিত হৈ পৰিছে।",
            "gu": "નવીન ખૂબ મહેનત કરે છે અને 12માં ધોરણમાં પાસ થઈ જાય છે. તેના પરિવારમાં તે પહેલો વ્યક્તિ છે જેને 12મુ ધોરણ પાસ કરેલું, અને તેનું કુટુંબ ચોકી જાય છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_1_2",
            "hi": "B1_1_2",
            "ka": "B1_1_2",
            "te": "B1_1_2",
            "or": "B1_1_2",
            "as": "B1_1_2",
            "gu": "B1_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen wants to study further so that he can get better-paying jobs. He wants to find a course that will give him good job opportunities.",
            "hi": "नवीन आगे पढ़ना चाहता है ताकि उसे अच्छी सैलरी वाली जॉब मिल सके। वह ऐसा कोर्स ढूँढना चाहता है जो उसे जॉब के अच्छे अवसर प्रदान करें।",
            "ka": "ನವೀನ್ ಮುಂದೆ ಓದಲು ಬಯಸುತ್ತಾನೆ, ಇದರಿಂದ ಅವನಿಗೆ ಉತ್ತಮ ಸಂಬಳದ ಕೆಲಸ ಸಿಗುತ್ತದೆ. ಅವನು ಉತ್ತಮ ಉದ್ಯೋಗಾವಕಾಶಗಳನ್ನು ನೀಡುವ ಕೋರ್ಸ್ ಅನ್ನು ಹುಡುಕಲು ಬಯಸುತ್ತಾನೆ.",
            "te": "నవీన్ ఇంకా బాగా చదువుకోవాలనుకుంటున్నాడు, తద్వారా మంచి జీతం వచ్చే ఉద్యోగాలు రావాలని కోరుకుంటున్నాడు. తనకు మంచి ఉద్యోగాలు వచ్చే కోర్సును వెతకాలనుకుంటున్నాడు.",
            "or": "ନବୀନ ଅଧିକ ଅଧ୍ୟୟନ କରିବାକୁ ଚାହାଁନ୍ତି ଯାହା ଦ୍ୱାରା ସେ ଅଧିକ ବେତନ ଦେଉଥିବା ଚାକିରି ପାଇପାରିବେ। ସେ ଏକ ପାଠ୍ୟକ୍ରମ ଖୋଜିବାକୁ ଚାହାଁନ୍ତି ଯାହା ତାଙ୍କୁ ଭଲ ଚାକିରିର ସୁଯୋଗ ଦେବ |",
            "as": "নবীনে আৰু পঢ়িব বিচাৰে যাতে তেওঁ ভাল দৰমহাৰ চাকৰি পাব পাৰে। তেওঁ এনে এটা পাঠ্যক্ৰম পঢ়িব বিচাৰে যিয়ে তেওঁক ভাল চাকৰিৰ সুযোগ দিব।",
            "gu": "નવીનને આગળ ભણવું છે જેથી કરીને તેને સારા પગરવાળી નોકરી મળે. તેને એવો કોર્સ લેવો છે જે કર્યા પછી સારી એવી નોકરીની તકો હોય."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_2_1",
            "hi": "B1_2_1",
            "ka": "B1_2_1",
            "te": "B1_2_1",
            "or": "B1_2_1",
            "as": "B1_2_1",
            "gu": "B1_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "One of his teachers, Mrs Bansal, helped him with extra classes and guidance to clear his exams. She has been a mentor to him for his 11th and 12th standards.",
            "hi": "उसकी एक शिक्षिका, श्रीमती बंसल ने उसे एक्स्ट्रा क्लासेस में और परीक्षा पास करने में मदद की थी। वह 11वीं और 12वीं कक्षा में उसकी उपदेशक थी।",
            "ka": "ಅವರ ಶಿಕ್ಷಕರಲ್ಲಿ ಒಬ್ಬರಾದ ಶ್ರೀಮತಿ ಬನ್ಸಾಲ್ ಅವರು ಹೆಚ್ಚುವರಿ ತರಗತಿಗಳು ಮತ್ತು ಅವರ ಪರೀಕ್ಷೆಗಳನ್ನು ತೆರವುಗೊಳಿಸಲು ಮಾರ್ಗದರ್ಶನದೊಂದಿಗೆ ಅವರಿಗೆ ಸಹಾಯ ಮಾಡಿದರು. ಅವನ 11 ಮತ್ತು 12 ನೇ ತರಗತಿಗಳಿಗೆ ಅವಳು ಅವನಿಗೆ ಮಾರ್ಗದರ್ಶಕಳಾಗಿದ್ದಳು.",
            "te": "అతని టీచర్లో ఒకరు శ్రీమతి బన్సల్, అతని పరీక్షలను క్లియర్ చేయడానికి అదనపు తరగతులు మరియు గైడెన్స్ తో అతనికి సహాయం చేసారు. అతని 11వ మరియు 12వ తరగతులకు ఆమె అతనికి సలహాదారుగా ఉంది.",
            "or": "ତାଙ୍କର ଜଣେ ଶିକ୍ଷକ ଶ୍ରୀମତୀ ବନସଲ୍‌ ତାଙ୍କୁ ଅତିରିକ୍ତ କ୍ଲାସ୍ ଏବଂ ତାଙ୍କ ପରୀକ୍ଷା ଉତ୍ତୀର୍ଣ୍ଣ କରିବାକୁ ମାର୍ଗଦର୍ଶନ କରିବାରେ ସାହାଯ୍ୟ କରିଥିଲେ | ସେ ତାଙ୍କର 11th ଏବଂ 12th ଶ୍ରେଣୀ ପାଇଁ ତାଙ୍କ ପାଇଁ ଜଣେ ମେଣ୍ଟର୍‌ ରହିଆସିଛନ୍ତି |",
            "as": "তেওঁৰ এগৰাকী শিক্ষয়িত্ৰী শ্ৰীমতী বনচালে তেওঁক পৰীক্ষাত উত্তীৰ্ণ হ'বলৈ অতিৰিক্ত শ্ৰেণী আৰু নিৰ্দেশনা প্ৰদান কৰিছিল। তেখেত তেওঁৰ একাদশ আৰু দ্বাদশ শ্ৰেণীৰ বাবে তেওঁৰ গুৰু আছিল।",
            "gu": "એના એક શિક્ષકા, શ્રીમતી બંસલ તેને વધારે ભણાવીને અને સારું માર્ગ દર્શન આપે છે તેની આગળની પરીક્ષા પાસ કરવામાં. તે તેના 11માં અને 12માં ધોરણમાં શિક્ષકા હતી."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_2_2//",
            "hi": "B1_2_2//",
            "ka": "B1_2_2//",
            "te": "B1_2_2//",
            "or": "B1_2_2//",
            "as": "B1_2_2//",
            "gu": "B1_2_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "He decides to approach Mrs Bansal to seek her advice.",
            "hi": "उसने श्रीमती बंसल से मिलकर सलाह लेने का निर्णय किया।",
            "ka": "ಅವನು ಶ್ರೀಮತಿ ಬನ್ಸಾಲ್ ಅವರ ಸಲಹೆಯನ್ನು ಪಡೆಯಲು ಅವರನ್ನು ಸಂಪರ್ಕಿಸಲು ನಿರ್ಧರಿಸಿದನು.",
            "te": "అతను శ్రీమతి బన్సాల్‌ను సలహా కోసం కలవాలనుకుంటున్నాడు.",
            "or": "ସେ ଶ୍ରୀମତୀ ବନସଲ୍‌ଙ୍କ ନିକଟକୁ ତାଙ୍କ ପରାମର୍ଶ ନେବା ପାଇଁ ସ୍ଥିର କରିଛନ୍ତି।",
            "as": "তেওঁ শ্ৰীমতী বনচালৰ পৰামৰ্শ ল'বলৈ তেওঁৰ ওচৰলৈ যোৱাৰ সিদ্ধান্ত লয়।",
            "gu": "એને નક્કી કર્યું કે શ્રીમતી બંસલ જોડે વાત કરીને તે મદદ લેશે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Mrs Bansal",
            "hi": "श्रीमती बंसल",
            "ka": "ಶ್ರೀಮತಿ ಬನ್ಸಾಲ್",
            "te": "శ్రీమతి బన్సాల్",
            "or": "ଶ୍ରୀମତୀ ବନସଲ୍‍",
            "as": "শ্ৰীমতী বনচাল",
            "gu": "શ્રીમતી બંસલ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Ma’am, there are so many courses to choose from, which one should I take?",
            "hi": "मैम, चुनाव करने के लिए कई कोर्सेस हैं, मुझे कौन सा कोर्स लेना चाहिए?",
            "ka": "ಮೇಡಂ, ಆಯ್ಕೆ ಮಾಡಲು ಹಲವು ಕೋರ್ಸ್‌ಗಳಿವೆ, ನಾನು ಯಾವುದನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕು?",
            "te": "మేడమ్, చాలా కోర్సెస్ ఉన్నాయి నేను ఏది తీసుకోవాలి ?",
            "or": "ମ୍ୟାଡାମ୍, ଚୟନ କରିବାକୁ ବହୁତ ପାଠ୍ୟକ୍ରମ ଅଛି, ମୁଁ କେଉଁଟି ନେବା ଉଚିତ୍ ?",
            "as": "মেডাম, বাছনি কৰিবলৈ বহুতো পাঠ্যক্ৰম আছে, মই কোনটো ল'ব লাগিব?",
            "gu": "મેડમ ત્યાં ગણા બધા વિષયો છે મારે નક્કી કરવા માટે પણ આમાથી કયો હું લઉં?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Naveen, you should take some time to reflect on what do you like doing and what are you good at. This will affect your decision.",
            "hi": "नवीन, तुम्हें क्या करना पसंद हैं और तुम किस चीज में अच्छे हो इस बात अच्छे से विचार करो। यह आपके निर्णय पर असर करेगा।",
            "ka": "ನವೀನ್, ನೀವು ಏನು ಮಾಡಲು ಇಷ್ಟಪಡುತ್ತೀರಿ ಮತ್ತು ನೀವು ಯಾವುದರಲ್ಲಿ ಉತ್ತಮರು ಎಂಬುದನ್ನು ಪ್ರತಿಬಿಂಬಿಸಲು ಸ್ವಲ್ಪ ಸಮಯ ತೆಗೆದುಕೊಳ್ಳಿ. ಇದು ನಿಮ್ಮ ನಿರ್ಧಾರದ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುತ್ತದೆ.",
            "te": "నవీన్, మీరు ఏమి చేయాలనుకుంటున్నారు మరియు మీరు దేనిలో మంచివారు అనే దాని గురించి ఆలోచించడానికి కొంత సమయం కేటాయించండి. ఇది మీ నిర్ణయాన్ని ప్రభావితం చేస్తుంది.",
            "or": "ନବୀନ, ତୁମେ କ’ଣ କରିବାକୁ ପସନ୍ଦ କରୁଛ ଏବଂ ତୁମେ କେଉଁଥିରେ ଭଲ ତାହା ରିଫ୍ଲେକ୍ଟ କରିବା ପାଇଁ ତୁମକୁ କିଛି ସମୟ ନେବା ଉଚିତ୍ | ଏହା ତୁମ ନିଷ୍ପତ୍ତିକୁ ପ୍ରଭାବ ପକାଇବ |",
            "as": "নবীন, তুমি কি কৰিবলৈ ভাল পোৱা আৰু তুমি কিহত পাৰ্গত সেই বিষয়ে চিন্তা কৰিবলৈ কিছু সময় লোৱা উচিত। ই তোমাৰ সিদ্ধান্তত প্ৰভাৱ পেলাব।",
            "gu": "નવીન તારે થોડો સમય ચિંતન કરીને નક્કી કરવાનું કે તારા માટે શું સારું છે ને કેમાં તું સારું કરી શકે છે. તે તારા નિર્ણય પર અસર કરશે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_2_3////",
            "hi": "B1_2_3////",
            "ka": "B1_2_3////",
            "te": "B1_2_3////",
            "or": "B1_2_3////",
            "as": "B1_2_3////",
            "gu": "B1_2_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Ma’am, all that is okay. I just want to take a course that will get me a first-class job.",
            "hi": "मैम, यह सब तो ठीक है, लेकिन मुझे ऐसा कोर्स करना है जो मुझे फर्स्ट-क्लास जॉब मिलने में मदद करें।",
            "ka": "ಮೇಡಂ, ಎಲ್ಲಾ ಸರಿ. ನನಗೆ ಪ್ರಥಮ ದರ್ಜೆಯ ಕೆಲಸವನ್ನು ಪಡೆಯುವ ಕೋರ್ಸ್ ಅನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ನಾನು ಬಯಸುತ್ತೇನೆ.",
            "te": "మేడమ్, అంతా ఓకే. నాకు ఫస్ట్ క్లాస్ ఉద్యోగం వచ్చే కోర్సు చదవాలనుకుంటున్నాను.",
            "or": "ମ୍ୟାଡାମ୍, ତାହା ସବୁ ଠିକ୍ | ମୁଁ କେବଳ ଏକ ପାଠ୍ୟକ୍ରମ ନେବାକୁ ଚାହୁଁଛି ଯାହା ମୋତେ ପ୍ରଥମ ଶ୍ରେଣୀ ଚାକିରି ଦେବ |",
            "as": "মেডাম, সকলো ঠিক আছে। মই কেৱল এনে এটা পাঠ্যক্ৰম ল'ব বিচাৰো যিয়ে মোক প্ৰথম শ্ৰেণীৰ চাকৰি দিব।",
            "gu": "મેડમ તે બધુ બરાબર છે. મારે બસ એવો કોર્સ લેવો છે કે મને ઉચ્ચ કક્ષાની નોકરી મળે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Haha, Naveen, it's not simple. \nOkay, I will give you two tasks. You can do any one of them and come back to me. Then we can talk about which course you should take.",
            "hi": "हाहा, नवीन, यह इतना आसान नहीं है। \n ओके, मैं तुम्हें दो काम देती हूँ। तुम इसमें से कोई भी एक काम करो और मेरे पास वापस आओ। फिर तुम्हें कौन सा कोर्स करना चाहिए इसके बारे में हम बात करेंगे।",
            "ka": "ಹ್ಹಹ್ಹ, ನವೀನ್, ಇದು ಸರಳವಲ್ಲ.\n ಸರಿ, ನಾನು ನಿಮಗೆ ಎರಡು ಕಾರ್ಯಗಳನ್ನು ನೀಡುತ್ತೇನೆ. ನೀವು ಅವುಗಳಲ್ಲಿ ಯಾವುದನ್ನಾದರೂ ಮಾಡಬಹುದು ಮತ್ತು ನನ್ನ ಬಳಿಗೆ ಹಿಂತಿರುಗಬಹುದು. ನಂತರ ನೀವು ಯಾವ ಕೋರ್ಸ್ ತೆಗೆದುಕೊಳ್ಳಬೇಕು ಎಂಬುದರ ಕುರಿತು ನಾವು ಮಾತನಾಡಬಹುದು.",
            "te": "“హహ, నవీన్, ఇది సింపుల్ కాదు.\n సరే, నేను నీకు రెండు పనులు ఇస్తాను. మీరు వాటిలో ఏదైనా ఒకటి చేసి నా వద్దకు తిరిగి రావచ్చు. మీరు ఏ కోర్సు చదవాలనే దాని గురించి మనం మాట్లాడు",
            "or": "ଠିକ ଅଛି, ମୁଁ ତୁମକୁ ଦୁଇଟି କାର୍ଯ୍ୟ ଦେବି | ତୁମେ ସେଗୁଡିକ ମଧ୍ୟରୁ ଯେକୌଣସି ଗୋଟିଏ କରି ମୋ ପାଖକୁ ଫେରିପାରିବେ | ତା’ପରେ କେଉଁ ପାଠ୍ୟକ୍ରମ ଆମେ ଗ୍ରହଣ କରିବା ବିଷୟରେ ଆଲୋଚନା କରିପାରିବା |",
            "as": "হাহা, নবীন, এয়া সহজ নহয়। ঠিক আছে, মই তোমাক দুটা কাম দিম। তুমি তাৰ ভিতৰত যিকোনো এটা কৰিবা আৰু মোৰ ওচৰলৈ ঘূৰি আহিবা। তেতিয়া আমি তুমি কোনটো পাঠ্যক্ৰম লোৱা উচিত সেই বিষয়ে কথা পাতিম।",
            "gu": "હા, નવીન, તે બધુ સરળ નથી. ચાલ હું તને બે કામ આપું છું. તું આમાથી કોઈ એક કરીને મારી જોડે આવજે. પછી આપણે નક્કી કરીયે કે કયો કોર્સ લેવો જોઈએ."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_2_4_d",
            "hi": "B1_2_4_d",
            "ka": "B1_2_4_d",
            "te": "B1_2_4_d",
            "or": "B1_2_4_d",
            "as": "B1_2_4_d",
            "gu": "B1_2_4_d"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Choose one of the task for Naveen.",
            "hi": "नवीन के लिए कोई भी एक काम चुनो।",
            "ka": "ನವೀನ್‌ಗಾಗಿ ಕಾರ್ಯಗಳಲ್ಲಿ ಒಂದನ್ನು ಆರಿಸಿ.",
            "te": "నవీన్ కోసం ఒక పనిని ఎంచుకోండి.",
            "or": "ନବୀନ ପାଇଁ କାର୍ଯ୍ୟ ମଧ୍ୟରୁ ଗୋଟିଏ ଚୟନ କରନ୍ତୁ |",
            "as": "নবীনৰ বাবে এটা কাম বাছনি কৰা।",
            "gu": "એક કાર્ય પસંદ કરો નવીન માટે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Talk to 3 people you know who went to college and are now working and have established jobs.",
            "hi": "तुम ऐसे 3 लोगों से बात करो जो कॉलेज जा चुके हैं और अब अच्छा जॉब कर रहे हैं।",
            "ka": "ಕಾಲೇಜಿಗೆ ಹೋಗಿ ಈಗ ಕೆಲಸ ಮಾಡುತ್ತಿರುವ ಮತ್ತು ಉದ್ಯೋಗವನ್ನು ಸ್ಥಾಪಿಸಿರುವ ನಿಮಗೆ ತಿಳಿದಿರುವ 3 ಜನರೊಂದಿಗೆ ಮಾತನಾಡಿ.",
            "te": "కళాశాలకి వెళ్ళి చదువుకొని ఇప్పుడు మంచి ఉద్యోగాలు చేస్తున్న ముగ్గురితో మాట్లాడు.",
            "or": "ଆପଣ ଜାଣିଥିବା 3 ଜଣଙ୍କ ସହ କଥା ହୁଅନ୍ତୁ ଯେଉଁମାନେ କଲେଜକୁ ଯାଇଛନ୍ତି ଏବଂ ବର୍ତ୍ତମାନ କାର୍ଯ୍ୟ କରୁଛନ୍ତି ଏବଂ ପ୍ରତିଷ୍ଠିତ ଚାକିରି ଅଛି |",
            "as": "তুমি জনা 3 জন লোকৰ সৈতে কথা পাতা যিসকলে মহাবিদ্যালয়লৈ গৈছিল আৰু এতিয়া কাম কৰি আছে আৰু সংস্থাপিত চাকৰি আছে।",
            "gu": "3 લોકોને વાત કરો કે જેને કોલેજ પૂરી કર્યા પછી નોકરી મેળવી છે અને નોકરી ઊભી કરી છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Answer a set of questions that will clarify what you seek out of a job.",
            "hi": "कुछ प्रश्नों का उत्तर दे जो आपको यह स्पष्ट करने में मदद करें कि आपको कैसा जॉब चाहिए।",
            "ka": "ನೀವು ಉದ್ಯೋಗದಿಂದ ಏನನ್ನು ಬಯಸುತ್ತೀರಿ ಎಂಬುದನ್ನು ಸ್ಪಷ್ಟಪಡಿಸುವ ಪ್ರಶ್ನೆಗಳ ಗುಂಪಿಗೆ ಉತ್ತರಿಸಿ.",
            "te": "నువ్వు ఉద్యోగం నుండి ఏమి కోరుకుంటున్నావో స్పష్టత ఇచ్చే ప్రశ్నలకు జవాబు చెప్పు.",
            "or": "ପ୍ରଶ୍ନଗୁଡିକର ଏକ ସେଟ୍ ର ଉତ୍ତର ଦିଅନ୍ତୁ ଯାହାକି ତୁମେ ଏକ ଚାକିରୀରୁ ଯାହା ଖୋଜୁଛ ତାହା ସ୍ପଷ୍ଟ କରିବ |",
            "as": "প্ৰশ্নৰ এটা সংহতিৰ পৰা উত্তৰ দিয়া যিয়ে স্পষ্ট কৰিব যে তুমি চাকৰি এটাৰ পৰা কি বিচাৰা।",
            "gu": "એક પ્રશ્નનો જવાબ આપો જે સમજાવશે કે તમે નોકરીમાં શું શોધી રહ્યા છો."
          }
        }
      ]
    },
    "scene4": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_1_1",
            "hi": "B2_i_1_1",
            "ka": "B2_i_1_1",
            "te": "B2_i_1_1",
            "or": "B2_i_1_1",
            "as": "B2_i_1_1",
            "gu": "B2_i_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen decides to talk to 3 people who are now in established positions.",
            "hi": "नवीन 3 लोगों से बात करने का निर्णय लेता है जो अच्छा जॉब कर रहे हैं।",
            "ka": "ನವೀನ್ ಈಗ ಸ್ಥಾಪಿತ ಸ್ಥಾನದಲ್ಲಿರುವ 3 ಜನರೊಂದಿಗೆ ಮಾತನಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
            "te": "ఇప్పుడు ఉద్యోగం చేస్తున్న వారిలో నవీన్ 3 వ్యక్తులను కలవాలి అనుకున్నాడు.",
            "or": "ବର୍ତ୍ତମାନ ପ୍ରତିଷ୍ଠିତ ପଦବୀରେ ଥିବା 3 ଜଣଙ୍କ ସହ କଥା ହେବା ପାଇଁ ନବୀନ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି।",
            "as": "নবীনে ৩ জন লোকৰ সৈতে কথা পাতিবলৈ সিদ্ধান্ত লৈছে যিসকল এতিয়া প্ৰতিষ্ঠিত পদত আছে।",
            "gu": "નવીન 3 લોકો જોડે વાત કરવાનું નક્કી કરે છે જેમને નોકરી ઊભી કરી છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_1_2",
            "hi": "B2_i_1_2",
            "ka": "B2_i_1_2",
            "te": "B2_i_1_2",
            "or": "B2_i_1_2",
            "as": "B2_i_1_2",
            "gu": "B2_i_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He first talks to Omi’s neighbour, Kartik, who teaches in a private school and has worked as a Kannada teacher for 5 years.",
            "hi": "वह सबसे पहले ओमी के पड़ोसी, कार्तिक से बात करता है, जो निजी स्कूल में पढ़ाता है और 5 सालों से कन्नड़ शिक्षक के तौर पर काम कर रहा है।",
            "ka": "ಅವನು ಮೊದಲು ಓಮಿ ಅವರ ನೆರೆಹೊರೆಯವರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾನೆ, ಅವನು ಖಾಸಗಿ ಶಾಲೆಯಲ್ಲಿ ಕಲಿಸುವ ಮತ್ತು 5 ವರ್ಷಗಳ ಕಾಲ ಕನ್ನಡ ಶಿಕ್ಷಕರಾಗಿ ಕೆಲಸ ಮಾಡಿದ ಕಾರ್ತಿಕ್.",
            "te": "అతను మొదట ఓమి యొక్క పొరిగింటి కార్తీక్‌తో మాట్లాడతాడు, అతను ఒక ప్రైవేట్ స్కూల్లో చెప్తాడు మరియు 5 సంవత్సరాలు కన్నడ ఉపాధ్యాయుడుగా పనిచేశాడు.",
            "or": "ସେ ପ୍ରଥମେ ଓମିର ପଡ଼ୋଶୀ କାର୍ତ୍ତିକଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରନ୍ତି, ଯିଏ ଏକ ଘରୋଇ ବିଦ୍ୟାଳୟରେ ଶିକ୍ଷାଦାନ କରନ୍ତି ଏବଂ 5 ବର୍ଷ ଧରି କନ୍ନଡ ଶିକ୍ଷକ ଭାବରେ କାର୍ଯ୍ୟ କରିଥିଲେ |",
            "as": "তেওঁ প্ৰথমে অমিৰ চুবুৰীয়া কাৰ্তিকৰ সৈতে কথা পাতিলে, যি এখন ব্যক্তিগত বিদ্যালয়ত শিক্ষকতা কৰে আৰু 5 বছৰ ধৰি কানাড়া শিক্ষক হিচাপে কাম কৰিছে।",
            "gu": "તે પહેલા ઓમીના પાડોશી કાર્તિક સાથે વાત કરવા માગે છે, જેને એક ખાનગી શાળામાં ભણાવે છે અને તે 5 વર્ષ સુધી કન્નડ શિક્ષક તરીકે ભણાવે હતી."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_1_3",
            "hi": "B2_i_1_3",
            "ka": "B2_i_1_3",
            "te": "B2_i_1_3",
            "or": "B2_i_1_3",
            "as": "B2_i_1_3",
            "gu": "B2_i_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Kartik shares that he studied Bachelor of Arts in Kannada and people from his college are now working as Kannada news and story writers in the entertainment industry.",
            "hi": "कार्तिक बताता है कि उसने कन्नड़ में आर्ट्स की बैचलर डिग्री पूरी की है और उसके कॉलेज के कुछ दोस्त मनोरंजन इंडस्ट्री में कन्नड़ ख़बरें और कथा लेखक के तौर पर काम कर रहे हैं।",
            "ka": "ಕಾರ್ತಿಕ್ ಅವರು ಕನ್ನಡದಲ್ಲಿ ಬ್ಯಾಚುಲರ್ ಆಫ್ ಆರ್ಟ್ಸ್ ಓದಿದ್ದಾರೆ ಮತ್ತು ಅವರ ಕಾಲೇಜಿನ ಜನರು ಈಗ ಮನರಂಜನಾ ಉದ್ಯಮದಲ್ಲಿ ಕನ್ನಡ ಸುದ್ದಿ ಮತ್ತು ಕಥೆ ಬರಹಗಾರರಾಗಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾರೆ ಎಂದು ಹಂಚಿಕೊಂಡಿದ್ದಾರೆ.",
            "te": "తాను కన్నడలో బ్యాచిలర్ ఆఫ్ ఆర్ట్స్ చదివానని మరియు అతని కళాశాలలోని వ్యక్తులు ఇప్పుడు వినోద పరిశ్రమలో కన్నడ వార్తలు మరియు కథా రచయితలుగా పనిచేస్తున్నారని కార్తీక్ పంచుకున్నారు.",
            "or": "କାର୍ତ୍ତିକ ଅଂଶୀଦାର କରନ୍ତି ଯେ ସେ କନ୍ନଡରେ ବ୍ୟାଚେଲର୍‌ ଅଫ୍‍ ଆର୍ତ୍ସ ଅଧ୍ୟୟନ କରିଥିଲେ ଏବଂ ତାଙ୍କ କଲେଜର ଲୋକମାନେ ବର୍ତ୍ତମାନ କନ୍ନଡ ସମ୍ବାଦ ଏବଂ ମନୋରଞ୍ଜନ ଶିଳ୍ପରେ କାହାଣୀ ଲେଖକ ଭାବରେ କାର୍ଯ୍ୟ କରୁଛନ୍ତି |",
            "as": "কাৰ্তিকে শ্বেয়াৰ কৰিলে যে তেওঁ কানাড়াত কলা শাখাত স্নাতক অধ্যয়ন কৰিছিল আৰু তেওঁৰ মহাবিদ্যালয়ৰ লোকসকলে এতিয়া মনোৰঞ্জন উদ্যোগত কানাড়া বাতৰি আৰু কাহিনী লিখক হিচাপে কাম কৰি আছে।",
            "gu": "કાર્તિક બતાવે છે કે તેણે કન્નડમાં આર્ટ્સમાં સ્નાતક પદવી મેળવી છે અને તેની કોલેજના લોકો હવે મનોરંજન ઉદ્યોગમાં કન્નડ સમાચાર અને વાર્તા લખવામાં કામ કરી રહ્યા છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_2_1",
            "hi": "B2_i_2_1",
            "ka": "B2_i_2_1",
            "te": "B2_i_2_1",
            "or": "B2_i_2_1",
            "as": "B2_i_2_1",
            "gu": "B2_i_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The second person he meets is Roshan’s childhood friend, Dinesh, who has just completed his commerce degree.",
            "hi": "वह दुसरे इन्सान से मिलता है, जो है रोशन का बचपन का दोस्त, दिनेश, जिसने कॉमर्स में डिग्री पूरी की है।",
            "ka": "ಅವನು ಭೇಟಿಯಾಗುವ ಎರಡನೆಯ ವ್ಯಕ್ತಿ ರೋಷನ್‌ನ ಬಾಲ್ಯದ ಗೆಳೆಯ ದಿನೇಶ್, ಅವನು ತನ್ನ ವಾಣಿಜ್ಯ ಪದವಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ.",
            "te": "అతను కలుసుకున్న రెండవ వ్యక్తి రోషన్ చిన్ననాటి స్నేహితుడు దినేష్, అతను ఇప్పుడే తన కామర్స్ డిగ్రీ పూర్తి చేశాడు.",
            "or": "ସେ ସାକ୍ଷାତ କରିଥିବା ଦ୍ୱିତୀୟ ବ୍ୟକ୍ତି ହେଉଛନ୍ତି ରୋଶନଙ୍କ ପିଲାଦିନର ବନ୍ଧୁ ଦୀନେଶ, ଯିଏକି ତାଙ୍କର ବାଣିଜ୍ୟ ଡିଗ୍ରୀ ସମାପ୍ତ କରିଛନ୍ତି |",
            "as": "তেওঁ লগ পোৱা দ্বিতীয় জন ব্যক্তি হৈছে ৰোশনৰ শৈশৱৰ বন্ধু দিনেশ, যি অলপতে তেওঁৰ বাণিজ্য ডিগ্ৰী সম্পূৰ্ণ কৰিছে।",
            "gu": "બીજા વ્યક્તિ તરીકે તે રોશનનો બાળપણનો મિત્ર દિનેશને મળે છે, જેને હમણાં કોમર્સની પદવી મેળવી છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_2_2",
            "hi": "B2_i_2_2",
            "ka": "B2_i_2_2",
            "te": "B2_i_2_2",
            "or": "B2_i_2_2",
            "as": "B2_i_2_2",
            "gu": "B2_i_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Dinesh shares that after completing his graduation in commerce, he is preparing for the bank exams as he wants to get into a Public sector bank.",
            "hi": "दिनेश बताता है कि कॉमर्स से ग्रॅज्युएट होने के बाद, अब वह बैंक की परीक्षा की तैयारी कर रहा है क्योंकि वह पब्लिक सेक्टर बैंक में काम करना चाहता है।",
            "ka": "ವಾಣಿಜ್ಯಶಾಸ್ತ್ರದಲ್ಲಿ ಪದವಿ ಮುಗಿಸಿದ ನಂತರ ಸಾರ್ವಜನಿಕ ವಲಯದ ಬ್ಯಾಂಕ್‌ಗೆ ಸೇರಲು ಬಯಸಿ ಬ್ಯಾಂಕ್ ಪರೀಕ್ಷೆಗೆ ತಯಾರಿ ನಡೆಸುತ್ತಿದ್ದೇನೆ ಎಂದು ದಿನೇಶ್ ಹಂಚಿಕೊಳ್ಳುತ್ತಾನೆ.",
            "te": "కామర్స్ లో గ్రాడ్యుయేషన్ పూర్తి చేసిన తర్వాత, తాను పబ్లిక్ సెక్టార్ బ్యాంక్‌లో చేరాలనుకుంటున్నానని బ్యాంక్ పరీక్షలకి ప్రిపేర్ అవుతున్నట్లు దినేష్ చెప్పాడు.",
            "or": "ଦୀନେଶ ଅଂଶୀଦାର କରିଛନ୍ତି ଯେ ବାଣିଜ୍ୟରେ ସ୍ନାତକ ହାସଲ କରିବା ପରେ ସେ ଏକ ପବ୍ଲିକ ସେକ୍ଟର ବ୍ୟାଙ୍କରେ ପ୍ରବେଶ କରିବାକୁ ଚାହୁଁଥିବାରୁ ବ୍ୟାଙ୍କ ପରୀକ୍ଷା ପାଇଁ ପ୍ରସ୍ତୁତ ହେଉଛନ୍ତି।",
            "as": "দিনেশে ক’লে যে বাণিজ্যত স্নাতক ডিগ্ৰী সম্পূৰ্ণ কৰাৰ পিছত, তেওঁ ৰাজহুৱা খণ্ডৰ বেংকত প্ৰৱেশ কৰিব বিচাৰে আৰু সেইবাবে বেংক পৰীক্ষাৰ বাবে প্ৰস্তুতি চলাই আছে।",
            "gu": "દિનેશ કહે છે કે તેને કોમર્સમાં સ્નાતક પૂર્ણ કર્યા પછી, તે બેંકની પરીક્ષાઓની તૈયારી કરી રહ્યો છે કારણ કે તે જાહેર ક્ષેત્રની બેંકમાં નોકરી મેળવવા માંગે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_3_1",
            "hi": "B2_i_3_1",
            "ka": "B2_i_3_1",
            "te": "B2_i_3_1",
            "or": "B2_i_3_1",
            "as": "B2_i_3_1",
            "gu": "B2_i_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The third person Naveen meets is Rashi, who has been working at a famous online shopping company, as a part of their marketing team.",
            "hi": "नवीन जिस तीसरे इन्सान को मिलता है वह है राशी, जो एक लोकप्रिय ऑनलाइन शॉपिंग कंपनी के मार्केटिंग टीम में काम करती है।",
            "ka": "ನವೀನ್ ಭೇಟಿಯಾದ ಮೂರನೇ ವ್ಯಕ್ತಿ ರಾಶಿ, ಅವರು ತಮ್ಮ ಮಾರ್ಕೆಟಿಂಗ್ ತಂಡದ ಭಾಗವಾಗಿ ಪ್ರಸಿದ್ಧ ಆನ್‌ಲೈನ್ ಶಾಪಿಂಗ್ ಕಂಪನಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾರೆ.",
            "te": "నవీన్ మూడవ వ్యక్తిని కలుస్తాడు, ఆమె ఒక ప్రముఖ ఆన్‌లైన్ షాపింగ్ కంపెనీ యొక్క మార్కెటింగ్ బృందంలో భాగంగా పనిచేస్తున్న రాశి.",
            "or": "ନବୀନଙ୍କୁ ଭେଟିଥିବା ତୃତୀୟ ବ୍ୟକ୍ତି ହେଉଛନ୍ତି ରାଶି, ଯିଏକି ଏକ ପ୍ରସିଦ୍ଧ ଅନଲାଇନ୍ ସପିଂ କମ୍ପାନୀରେ, ସେମାନଙ୍କ ମାର୍କେଟିଂ ଦଳର ଏକ ଅଂଶ ଭାବରେ କାର୍ଯ୍ୟ କରୁଛନ୍ତି |",
            "as": "নবীনে লগ পোৱা তৃতীয় ব্যক্তিজন হৈছে ৰাশি, যি তেওঁলোকৰ বিপণন দলৰ অংশ হিচাপে এটা বিখ্যাত অনলাইন শ্বপিং কোম্পানীত কাম কৰি আছে।",
            "gu": "ત્રીજા વ્યક્તિ તરીકે તે મળે છે એ છે રાશિ, તે પ્રખ્યાત ઓનલાઇન શોપિંગ કંપનીમાં કામ કરે છે, તે એક માર્કેટિંગ ટીમના ભાગ તરીકે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_3_2//",
            "hi": "B2_i_3_2//",
            "ka": "B2_i_3_2//",
            "te": "B2_i_3_2//",
            "or": "B2_i_3_2//",
            "as": "B2_i_3_2//",
            "gu": "B2_i_3_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Rashi",
            "hi": "राशी",
            "ka": "ರಾಶಿ",
            "te": "రాశి",
            "or": "ରାଶି",
            "as": "ৰাশি",
            "gu": "રાશિ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Thank you, ma’am, for agreeing to talk to me. I have a few questions I want to ask you if you don't mind.",
            "hi": "आप मुझसे बात करने के लिए राजी हो गई, इसलिए थैंक यू, मैम। अगर आपको कोई ऐतराज ना हो तो मैं आप से कुछ प्रश्न पूछना चाहता हूँ।",
            "ka": "ಧನ್ಯವಾದಗಳು, ಮೇಡಮ್, ನನ್ನೊಂದಿಗೆ ಮಾತನಾಡಲು ಒಪ್ಪಿಕೊಂಡಿದ್ದಕ್ಕಾಗಿ. ನಿಮಗೆ ಅಭ್ಯಂತರವಿಲ್ಲದಿದ್ದರೆ ನಾನು ನಿಮ್ಮನ್ನು ಕೇಳಲು ಕೆಲವು ಪ್ರಶ್ನೆಗಳಿವೆ.",
            "te": "నాతో మాట్లాడడానికి ఒప్పుకున్నందుకు ధన్యవాదాలు మేడమ్. మీకు అభ్యంతరం లేకపోతే నేను మిమ్మల్ని కొన్ని ప్రశ్నలు అడగాలనుకుంటున్నాను.",
            "or": "ମୋ ସହିତ କଥା ହେବାକୁ ରାଜି ହୋଇଥିବାରୁ ଧନ୍ୟବାଦ, ମ୍ଯାଡାମ୍‌ | ଯଦି ଆପଣ କିଛି ଭାବୁନାହାଁନ୍ତି ମୋର କିଛି ପ୍ରଶ୍ନ ଅଛି ମୁଁ ଆପଣକୁ ପଚାରିବାକୁ ଚାହେଁ |",
            "as": "মোৰ সৈতে কথা পাতিবলৈ সন্মত হোৱাৰ বাবে ধন্যবাদ, মেডাম। মোৰ আপোনাক সুধিবলগীয়া কেইটামান প্ৰশ্ন আছে যদিহে আপুনি বেয়া নাপায়।",
            "gu": "ધન્યવાદ મેડમ, મારી જોડે વાત કરવામાં તમે રાજી થયા તે બદલ. મારા મગજમાં થોડા પ્રશ્નો છે, જો તમને કોઈ વાંધો ના હોય તો પૂછું."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Yes, I would be happy to answer your questions.",
            "hi": "हाँ, क्यों नहीं, मुझे तुम्हारे प्रश्नों के उत्तर देने में खुशी होगी।",
            "ka": "ಹೌದು, ನಿಮ್ಮ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಲು ನಾನು ಸಂತೋಷಪಡುತ್ತೇನೆ.",
            "te": "సరే, నేను సంతోషంగా జవాబు చెప్తాను.",
            "or": "ହଁ, ମୁଁ ତୁମର ପ୍ରଶ୍ନର ଉତ୍ତର ଦେଇ ଖୁସି ହେବି |",
            "as": "হয়, মই তোমাৰ প্ৰশ্নবোৰৰ উত্তৰ দিবলৈ পালে সুখী হ'ম।",
            "gu": "હા, હું તારા પ્રશ્નોનાં જવાબ આપવામાં રાજી થઈશ."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_3_3////",
            "hi": "B2_i_3_3////",
            "ka": "B2_i_3_3////",
            "te": "B2_i_3_3////",
            "or": "B2_i_3_3////",
            "as": "B2_i_3_3////",
            "gu": "B2_i_3_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "What did you study to be able to work in such a position?",
            "hi": "इस पोजिशन पर काम करने के लिए आपने क्या पढ़ाई की है?",
            "ka": "ಅಂತಹ ಸ್ಥಾನದಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ನೀವು ಏನು ಅಧ್ಯಯನ ಮಾಡಿದ್ದೀರಿ?",
            "te": "అలాంటి స్థానంలో పనిచేయడానికి మీరు ఏమి చదువుకున్నారు?",
            "or": "ଏପରି ଏକ ପୋଜିସନ୍‌ରେ କାମ କରିବାକୁ ଆପଣ କ’ଣ ଅଧ୍ୟୟନ କରିଥିଲେ?",
            "as": "এনে পদত কাম কৰিবলৈ সক্ষম হ'বলৈ আপুনি কি অধ্যয়ন কৰিছিল?",
            "gu": "તમે શું ભણ્યા છો જેના લીધે આ જગ્યા પર કામ કરો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "I did a course called BBA. I had done an internship in a similar organisation so I knew a little about the work. But most of the work I learnt on the job.",
            "hi": "मैंने BBA नाम का कोर्स किया है। मैंने ऐसी ही एक संस्था में इन्टर्नशिप की थी इसलिए मुझे काम के बारे में थोड़ी जानकारी थी। पर बहुत सा काम तो मैंने जॉब लगने पर ही सीखा है।",
            "ka": "ನಾನು ಬಿಬಿಎ ಎಂಬ ಕೋರ್ಸ್ ಮಾಡಿದ್ದೇನೆ. ನಾನು ಇದೇ ಸಂಸ್ಥೆಯಲ್ಲಿ ಇಂಟರ್ನ್‌ಶಿಪ್ ಮಾಡಿದ್ದರಿಂದ ನನಗೆ ಕೆಲಸದ ಬಗ್ಗೆ ಸ್ವಲ್ಪ ತಿಳಿದಿತ್ತು. ಆದರೆ ಹೆಚ್ಚಿನ ಕೆಲಸಗಳನ್ನು ನಾನು ಕೆಲಸದ ಮೇಲೆ ಕಲಿತಿದ್ದೇನೆ.",
            "te": "BBA అనే కోర్సు చేశాను. నేను ఇలాంటి ఒక ఆర్గనైజేషన్లో ఇంటర్న్‌షిప్ చేసాను కాబట్టి నాకు పని గురించి కొంచెం తెలుసు. కానీ చాలా పని నేను ఉద్యోగంలో నేర్చుకున్నాను.",
            "or": "ମୁଁ BBA ନାମକ ଏକ ପାଠ୍ୟକ୍ରମ କଲି | ମୁଁ ସମାନ ସଂଗଠନରେ ଇଣ୍ଟର୍ନସିପ୍ କରିଥିଲି ତେଣୁ ମୁଁ କାର୍ଯ୍ୟ ବିଷୟରେ ଟିକିଏ ଜାଣିଥିଲି | କିନ୍ତୁ ଅଧିକାଂଶ କାର୍ଯ୍ୟ ମୁଁ ଚାକିରି କଲାବେଳେ ଶିଖିଲି |",
            "as": "মই BBA নামৰ এটা পাঠ্যক্ৰম কৰিছিলো। মই একেধৰণৰ সংগঠনত এটা ইন্টাৰ্ণশ্বিপ কৰিছিলো গতিকে মই কামটোৰ বিষয়ে অলপ-অচৰপ জানিছিলো। কিন্তু মই কামটো কৰি থাকোতে বেছিভাগ কাম শিকিছিলো।",
            "gu": "મે BBA નામનો કોર્સ કર્યો છે. મે સમાન કંપનીમાં ઇન્ટર્નશીપ કરી હતી જેના લીધે મને થોડો આ કામ વિષે અનુભવ છે. પણ મે વધારે કામ આ નોકરીમાંથી શીખ્યુ છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "How did you decide which college to go to?",
            "hi": "कौन से कॉलेज में जाना है यह आपने कैसे तय किया?",
            "ka": "ಯಾವ ಕಾಲೇಜಿಗೆ ಹೋಗಬೇಕೆಂದು ನೀವು ಹೇಗೆ ನಿರ್ಧರಿಸಿದ್ದೀರಿ?",
            "te": "ఏ కళాశాలకి వెళ్లాలో మీరు ఎలా నిర్ణయించుకున్నారు?",
            "or": "ଆପଣ କେଉଁ କଲେଜକୁ ଯିବେ ତାହା ଆପଣ କିପରି ସ୍ଥିର କଲେ ?",
            "as": "আপুনি কোনখন মহাবিদ্যালয়লৈ যাব লাগে কেনেকৈ সিদ্ধান্ত লৈছিল?",
            "gu": "તમે કેવી રીતે નક્કી કર્યું કે કઈ કોલેજમાં જવું?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "I had applied to 3-4 colleges based on the ratings of the college. I wanted to study where internships were compulsory.",
            "hi": "मैंने कॉलेज के रेटिंग्स के अनुसार 3-4 कॉलेज में अप्लाई किया था। मैं ऐसे कॉलेज में पढ़ना चाहती थी जहाँ इन्टर्नशिप करना अनिवार्य था।",
            "ka": "ಕಾಲೇಜಿನ ರೇಟಿಂಗ್ ಆಧರಿಸಿ 3-4 ಕಾಲೇಜುಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ್ದೆ. ಇಂಟರ್ನ್‌ಶಿಪ್ ಕಡ್ಡಾಯವಾಗಿರುವಲ್ಲಿ ನಾನು ಅಧ್ಯಯನ ಮಾಡಲು ಬಯಸುತ್ತೇನೆ.",
            "te": "కళాశాల రేటింగ్‌ల ఆధారంగా నేను 3-4 కళాశాలలకు దరఖాస్తు చేశాను. ఇంటర్న్‌షిప్‌లు తప్పనిసరిగా ఉండే చోట నేను చదవాలనుకున్నాను.",
            "or": "ମୁଁ କଲେଜର ମୂଲ୍ୟାୟନକୁ ଆଧାର କରି 3-4 ଟି କଲେଜରେ ଆବେଦନ କରିଥିଲି | ମୁଁ ଅଧ୍ୟୟନ କରିବାକୁ ଚାହୁଁଥିଲି ଯେଉଁଠାରେ ଇଣ୍ଟର୍ନସିପ୍ ବାଧ୍ୟତାମୂଳକ ଥିଲା |",
            "as": "মই মহাবিদ্যালয়ৰ ৰেটিঙৰ ওপৰত আধাৰ কৰি 3-4 খন মহাবিদ্যালয়ত আবেদন কৰিছিলো। মই পঢ়িব বিচাৰিছিলো য'ত ইণ্টাৰ্নশ্বিপ বাধ্যতামূলক আছিল।",
            "gu": "મેં 3-4 કોલેજોમાં અરજી કરી હતી, જે કોલેજના રેટિંગના આધારે હતું. હું ત્યાં ભણવા માગતી હતી જ્યાં ઇન્ટર્નશિપ ફરજિયાત હોય ."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_3_4",
            "hi": "B2_i_3_4",
            "ka": "B2_i_3_4",
            "te": "B2_i_3_4",
            "or": "B2_i_3_4",
            "as": "B2_i_3_4",
            "gu": "B2_i_3_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen makes several notes from the inputs Rashi gives him. He is interested in finding out more about a BBA degree.",
            "hi": "राशी जो बताती है उसके अनुसार नवीन कई नोट्स लिख लेता है। वह BBA डिग्री के बारे में अधिक जानकारी प्राप्त करना चाहता है।",
            "ka": "ರಾಶಿ ತನಗೆ ನೀಡಿದ ಇನ್‌ಪುಟ್‌ಗಳಿಂದ ನವೀನ್ ಹಲವಾರು ಟಿಪ್ಪಣಿಗಳನ್ನು ಮಾಡುತ್ತಾನೆ. ಅವನು ಬಿಬಿಎ ಪದವಿಯ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಆಸಕ್ತಿ ಹೊಂದಿದ್ದಾನೆ.",
            "te": "రాశి అతనికి ఇచ్చిన ఇన్‌పుట్‌ల నుండి నవీన్ చాలా నోట్స్ చేస్తాడు. అతను BBA డిగ్రీ గురించి మరింత తెలుసుకోవడానికి ఆసక్తిని కలిగి ఉన్నాడు.",
            "or": "ରାଶି ତାଙ୍କୁ ପ୍ରଦାନ କରିଥିବା ଇନପୁଟ୍‌ରୁ ନବୀନ ଅନେକ ନୋଟ୍ ପ୍ରସ୍ତୁତ କରନ୍ତି | ସେ ଏକ BBA ଡିଗ୍ରୀ ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ଆଗ୍ରହୀ ଅଟନ୍ତି |",
            "as": "ৰাশিয়ে দিয়া ইনপুটবোৰৰ পৰা নবীনে কেইবাটাও টোকা প্ৰস্তুত কৰিলে। তেওঁ BBA ডিগ্ৰীৰ বিষয়ে অধিক জানিবলৈ আগ্ৰহী হ’ল।",
            "gu": "નવીન ઘણી નોંધ બનાવે છે જે રાશિ તેને આપેલા તેમાથી. તેને BBA ડિગ્રી વિશે વધુ જાણવામાં રસ છે."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_3_5",
            "hi": "B2_i_3_5",
            "ka": "B2_i_3_5",
            "te": "B2_i_3_5",
            "or": "B2_i_3_5",
            "as": "B2_i_3_5",
            "gu": "B2_i_3_5"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He finds out that BBA stands for Bachelors in Business Administration. It provides training in management and prepares students for management roles in different fields.",
            "hi": "उसे पता चलता है कि BBA का मतलब है बैचलर इन बिजनेस एडमिनिस्ट्रेशन। इसमें मैनेजमेंट का प्रशिक्षण दिया जाता है और विद्यार्थियों को अलग-अलग क्षेत्र में मैनेजर के रोल के लिए तैयार किया जाता है।",
            "ka": "ಬಿಬಿಎ ಎಂದರೆ ಬ್ಯಾಚುಲರ್ಸ್ ಇನ್ ಬಿಸಿನೆಸ್ ಅಡ್ಮಿನಿಸ್ಟ್ರೇಷನ್ ಎಂದು ಅವನು ಕಂಡುಕೊಂಡನು. ಇದು ನಿರ್ವಹಣೆಯಲ್ಲಿ ತರಬೇತಿಯನ್ನು ನೀಡುತ್ತದೆ ಮತ್ತು ವಿವಿಧ ಕ್ಷೇತ್ರಗಳಲ್ಲಿ ನಿರ್ವಹಣಾ ಪಾತ್ರಗಳಿಗೆ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಸಿದ್ಧಪಡಿಸುತ್ತದೆ.",
            "te": "BBA అంటే బ్యాచిలర్స్ ఇన్ బిజినెస్ అడ్మినిస్ట్రేషన్ అని అతను తెలుసుకుంటాడు. ఇది నిర్వహణలో శిక్షణను అందిస్తుంది మరియు వివిధ రంగాలలో నిర్వహణ పాత్రలకు విద్యార్థులను సిద్ధం చేస్తుంది.",
            "or": "ସେ ଜାଣିବାକୁ ପାଇଲେ ଯେ BBA ବ୍ୟାଚେଲର୍ସ ଇନ୍‌ ବିଜନେସ୍‌ ଆଡ୍‌ମିନିଷ୍ଟ୍ରେସନ୍‌ କୁ ବୁଝାଏ | ଏହା ମ୍ୟାନେଜମେଣ୍ଟ୍‌ରେ ତାଲିମ ପ୍ରଦାନ କରେ ଏବଂ ବିଭିନ୍ନ କ୍ଷେତ୍ରରେ ମ୍ୟାନେଜମେଣ୍ଟ୍‌ ଭୂମିକା ପାଇଁ ଛାତ୍ରମାନଙ୍କୁ ପ୍ରସ୍ତୁତ କରେ |",
            "as": "তেওঁ গম পালে যে BBA হৈছে বেচেলৰ ইন বিজনেছ এডমিনিষ্ট্ৰেচন। ই ব্যৱস্থাপনাত প্ৰশিক্ষণ প্ৰদান কৰে আৰু শিক্ষাৰ্থীসকলক বিভিন্ন ক্ষেত্ৰত ব্যৱস্থাপনা ভূমিকাৰ বাবে প্ৰস্তুত কৰে।",
            "gu": "તેને ખબર પડી કે BBA એટલે બિઝનેસ એડમિનિસ્ટ્રેશનમાં સ્નાતક. તેમાં સંચાલનની તાલીમ પૂરી પાડે છે અને વિદ્યાર્થીઓને ગણા બધા ક્ષેત્રોમાં સંચાલન કેવી રીતે કરવું તેના માટે તૈયાર કરે છે."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_3_6",
            "hi": "B2_i_3_6",
            "ka": "B2_i_3_6",
            "te": "B2_i_3_6",
            "or": "B2_i_3_6",
            "as": "B2_i_3_6",
            "gu": "B2_i_3_6"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "BBA is a lot of learning through practical experience and projects and fewer theory classes. Naveen is very excited to learn this as he doesn’t enjoy studying theory subjects.",
            "hi": "BBA में ज्यादातर पढ़ाई प्रैक्टिकल और प्रोजेक्ट के माध्यम से होती है और थ्योअरी क्लासेस कम होते हैं। नवीन यह सुनकर बहुत खुश हुआ क्योंकि उसे थ्योअरी विषय पढ़ना अच्छा नहीं लगता था।",
            "ka": "BBA ಪ್ರಾಯೋಗಿಕ ಅನುಭವ ಮತ್ತು ಯೋಜನೆಗಳು ಮತ್ತು ಕಡಿಮೆ ಸಿದ್ಧಾಂತ ತರಗತಿಗಳ ಮೂಲಕ ಬಹಳಷ್ಟು ಕಲಿಕೆಯಾಗಿದೆ. ನವೀನ್ ಸಿದ್ಧಾಂತ ವಿಷಯಗಳನ್ನು ಕಲಿಯಲು ಇಷ್ಟಪಡದ ಕಾರಣ ಇದನ್ನು ಕಲಿಯಲು ತುಂಬಾ ಉತ್ಸುಕರಾಗಿದ್ದಾನೆ.",
            "te": "BBA లో చాలా ప్రాక్టికల్ అనుభవం ఉంటుంది మరియు ప్రాజెక్ట్‌లు మరియు తక్కువ థియరీ తరగతుల ద్వారా చాలా నేర్చుకోవచ్చు. థియరీ సబ్జెక్టులు చదవడం ఇష్టం లేని నవీన్ దీన్ని నేర్చుకోవడానికి చాలా ఉత్సాహంగా ఉన్నాడు.",
            "or": "BBA ହେଉଛି ବ୍ୟବହାରିକ ଅଭିଜ୍ଞତା ଏବଂ ପ୍ରୋଜେକ୍ଟ ଏବଂ କମ୍ ଥିଓରୀ କ୍ଲାସ୍ ମାଧ୍ୟମରେ ବହୁତ ଶିଖିବା | ନବୀନ ଏହା ଜାଣିବା ପାଇଁ ବହୁତ ଉତ୍ସାହିତ କାରଣ ସେ ଥିଓରୀ ବିଷୟଗୁଡିକ ଅଧ୍ୟୟନ କରିବାକୁ ପସନ୍ଦ କରନ୍ତି ନାହିଁ |",
            "as": "BBA হৈছে তত্ত্ব শ্ৰেণীতকৈ ব্যৱহাৰিক অভিজ্ঞতা আৰু প্ৰকল্পৰ জৰিয়তে লোৱা শিক্ষা। নবীনে এইটো শিকিবলৈ অতি উৎসাহিত হ’ল কিয়নো তেওঁ তত্ত্বৰ বিষয়বোৰ অধ্যয়ন কৰি ভাল নাপায়।",
            "gu": "BBAમાં પ્રેક્ટિકલ અનુભવ, પ્રોજેક્ટ્સ અને ઓછા થિયરી વર્ગો દ્વારા અભ્યાસ મળવી શકાય છે. નવીન તેનો અભ્યાસ કરવા માટે ખૂબ જ ઉત્સાહિત છે કારણ કે તેને થિયરી વિષયોનો અભ્યાસ કરવાનું પસંદ નથી."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "informationalInterview",
            "hi": "जानकारीपूर्णमुलाकात",
            "ka": "ಮಾಹಿತಿ ಸಂದರ್ಶನ",
            "te": "సమాచార ఇంటర్వ్యూ",
            "or": "ସୂଚନାପୂର୍ଣ୍ଣ ସାକ୍ଷାତକାର",
            "as": "তথ্যমূলক সাক্ষাৎকাৰ",
            "gu": "મુલાકાત જે માહિતી વાળી હતી"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Informational Interview experience!",
            "hi": "आपने अभी-अभी जानकारीपूर्ण मुलाकर का अनुभव प्राप्त किया है!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಮಾಹಿತಿ ಸಂದರ್ಶನದ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే సమాచార ఇంటర్వ్యూ అనుభవం పొందారు!",
            "or": "ଆପଣ ବର୍ତମାନ ସୂଚନାମୂଳକ ସାକ୍ଷାତକାର ଅଭିଜ୍ଞତା ହାସଲ କରିଛନ୍ତି !",
            "as": "তুমি এইমাত্ৰ তথ্যমূলক সাক্ষাৎকাৰৰ অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "તમે હમણાં જ માહિતી વાળું ઇન્ટરવ્યુનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Informational Interview",
            "hi": "जानकारीपूर्ण मुलाकात",
            "ka": "ಮಾಹಿತಿ ಸಂದರ್ಶನ",
            "te": "సమాచార ఇంటర్వ్యూ",
            "or": "ସୂଚନାପୂର୍ଣ୍ଣ ସାକ୍ଷାତକାର",
            "as": "তথ্যমূলক সাক্ষাৎকাৰ",
            "gu": "મુલાકાત જે માહિતી વાળી હતી"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर विकास कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "వృత్తి అభివృద్ది నైపుణ్యాలు",
            "or": "କ୍ଯାରିୟର୍‌ ବିକାଶ କୌଶଳ",
            "as": "জীৱিকা বিকাশৰ দক্ষতা",
            "gu": "કારકિર્દી વિકાસ કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Naveen talks to people who are working to know about the job and their career journey. To build an understanding of what each position requires, you can treat these conversations like an interview. It is also a form of research where you asks specific questions. In a discussion like this, you can share the questions you will ask them beforehand. It will help you focus on the information you need while keeping the person you are interviewing comfortable.",
            "hi": "नवीन ने कुछ लोगों से बात करके उनके जॉब और करियर के प्रवास के बारे में जानकारी हासिल की। हर पोजिशन के लिए किस चीज की जरूरत होती हैं यह जानने के लिए आप बातचीत को मुलाकात के जैसे इस्तमाल कर सकते हैं। यह भी एक तरह का संशोधन है जहाँ पर आप विशेष प्रश्न पूछते हैं। इस तरह की चर्चा में, आप उन्हें पहले ही बता सकते हैं कि आप किस तरह के सवाल पूछने वाले हैं। इससे आप को जरुरी जानकारी प्राप्त करने में और सामनेवाले व्यक्ति को मुलाकात के दौरान कम्फर्टेबल रखने में मदद मिल सकती हैं।",
            "ka": "ಉದ್ಯೋಗ ಮತ್ತು ಅವರ ವೃತ್ತಿಜೀವನದ ಬಗ್ಗೆ ತಿಳಿಯಲು ಕೆಲಸ ಮಾಡುವ ಜನರೊಂದಿಗೆ ನವೀನ್ ಮಾತನಾಡುತ್ತಾನೆ. ಪ್ರತಿ ಸ್ಥಾನಕ್ಕೆ ಏನು ಬೇಕು ಎಂಬುದನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು, ನೀವು ಈ ಸಂಭಾಷಣೆಗಳನ್ನು ಸಂದರ್ಶನದಂತೆ ಪರಿಗಣಿಸಬಹುದು. ನೀವು ನಿರ್ದಿಷ್ಟ ಪ್ರಶ್ನೆಗಳನ್ನು ಕೇಳುವ ಸಂಶೋಧನೆಯ ಒಂದು ರೂಪವಾಗಿದೆ. ಈ ರೀತಿಯ ಚರ್ಚೆಯಲ್ಲಿ, ನೀವು ಮೊದಲು ಕೇಳುವ ಪ್ರಶ್ನೆಗಳನ್ನು ನೀವು ಹಂಚಿಕೊಳ್ಳಬಹುದು. ನೀವು ಸಂದರ್ಶಿಸುವ ವ್ಯಕ್ತಿಯನ್ನು ಆರಾಮದಾಯಕವಾಗಿಟ್ಟುಕೊಂಡು ನಿಮಗೆ ಅಗತ್ಯವಿರುವ ಮಾಹಿತಿಯ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಲು ಇದು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
            "te": "ఉద్యోగం మరియు వారి కెరీర్ జర్నీ గురించి తెలుసుకోవడానికి నవీన్ పనిచేస్తున్న వ్యక్తులతో మాట్లాడాడు. ప్రతి స్థానంకి ఏమి అవసరమో అర్థం చేసుకోవడానికి, మీరు ఈ సంభాషణను ఇంటర్వ్యూ లాగా అనుకోండి. ఇది మీరు నిర్దిష్ట ప్రశ్నలు అడిగే పరిశోధన యొక్క విధానం కూడా. ఇలాంటి సంభాషణలో, మీరు ముందుగా వారిని అడిగే ప్రశ్నలను తెలపవచ్చు. మీరు ఇంటర్వ్యూ చేస్తున్న వ్యక్తిని సౌకర్యంగా ఉంచుతూ మీకు అవసరమైన సమాచారంపై దృష్టి పెట్టడంలో ఇది మీకు సహాయపడుతుంది.",
            "or": "ଚାକିରି ଏବଂ ସେମାନଙ୍କ କ୍ୟାରିୟର ଯାତ୍ରା ବିଷୟରେ ଜାଣିବାକୁ କାର୍ଯ୍ୟ କରୁଥିବା ଲୋକଙ୍କ ସହ ନବୀନ କଥାବାର୍ତ୍ତା କରନ୍ତି | ପ୍ରତ୍ୟେକ ପଦବୀ କଣ ଆବଶ୍ୟକ କରେ ତାହାର ଏକ ବୁଝାମଣା ଗଠନ କରିବାକୁ, ଆପଣ ଏହି ବାର୍ତ୍ତାଳାପଗୁଡିକୁ ଏକ ସାକ୍ଷାତକାର ପରି ବ୍ୟବହାର କରିପାରିବେ | ଏହା ମଧ୍ୟ ଏକ ରିସର୍ଚ୍ଚର ଏକ ପ୍ରକାର ଯେଉଁଠାରେ ଆପଣ ନିର୍ଦ୍ଦିଷ୍ଟ ପ୍ରଶ୍ନଗୁଡିକ ପଚାରନ୍ତି | ଏହି ପରି ଏକ ଆଲୋଚନାରେ, ଆପଣ ପଚାରିବାକୁଥିବା ପ୍ରଶ୍ନଗୁଡିକ ପୂର୍ବରୁ ଅଂଶୀଦାର କରିପାରିବେ | ଆପଣ ସାକ୍ଷାତକାର କରୁଥିବା ବ୍ୟକ୍ତିଙ୍କୁ ଆରାମଦାୟକ ରଖିବାବେଳେ ଏହା ଆପଣଙ୍କୁ ଆବଶ୍ୟକ ସୂଚନା ଉପରେ ଧ୍ୟାନ ଦେବାରେ ସାହାଯ୍ୟ କରିବ |",
            "as": "নবীনে চাকৰি আৰু তেওঁলোকৰ জীৱিকাৰ যাত্ৰাৰ বিষয়ে জানিবলৈ কাম কৰি থকা লোকসকলৰ সৈতে কথা পাতিছে। প্ৰতিটো স্থিতিৰ বাবে কি প্ৰয়োজন সেই বিষয়ে বুজাৰ বাবে, তুমি এই কথোপকথনবোৰক সাক্ষাৎকাৰৰ দৰে ব্যৱহাৰ কৰিব পাৰা। এইটো গৱেষণাৰ এক প্ৰকাৰো য'ত তুমি নিৰ্দিষ্ট প্ৰশ্ন সুধিবা। এনেধৰণৰ আলোচনাত, তুমি সুধিবলগীয়া প্ৰশ্নবোৰ আগতীয়াকৈ ভাগ-বতৰা কৰিব পাৰা। ই সাক্ষাৎকাৰ লোৱা ব্যক্তিজনক আৰামদায়ক কৰি ৰাখি তোমাৰ প্ৰয়োজনীয় তথ্যৰ ওপৰত গুৰুত্ব দিয়াত সহায় কৰিব।",
            "gu": "નોકરીઓ અને તેમના વ્યવસાયો વિશે વધુ જાણવા માટે કામ કરતા લોકો સાથે નવીન વાર્તાલાપ કરે છે. દરેક પદ માટે શું જરૂરી છે તે સમજવા માટે, તમે આ વાતચીતને ઇન્ટરવ્યુ તરીકે ગણી શકો છો. તે સંશોધનનું એક સ્વરૂપ પણ છે જ્યાં તમે ચોક્કસ પ્રશ્નો પૂછો છો. આવી ચર્ચામાં, તમે તેમને અગાઉથી પ્રશ્નો પૂછી શકો છો. ઇન્ટરવ્યુ લેનારને સામાન્ય રાખવાથી તમને જરૂરી માહિતી પર ધ્યાન કેન્દ્રિત કરવામાં મદદ મળશે."
          }
        }
      ],
      "page16": [
        {
          "valuePath": "display",
          "value": {
            "en": "mcq",
            "hi": "mcq",
            "ka": "ಎಂಸಿಕ್ಯೂ",
            "te": "mcq",
            "or": "mcq",
            "as": "mcq",
            "gu": "mcq"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "An informational interview is helpful when:",
            "hi": "जानकारीपूर्ण मुलाकात तब उपयुक्त होती हैं जब:",
            "ka": "ಮಾಹಿತಿಯ ಸಂದರ್ಶನವು ಯಾವಾಗ ಸಹಾಯಕವಾಗಿರುತ್ತದೆ:",
            "te": "ఒక సమాచార ఇంటర్వ్యూ ఈ సమయంలో ఉపయోగపడుతుంది:",
            "or": "ଏକ ସୂଚନାପୂର୍ଣ୍ଣ ସାକ୍ଷାତକାର ସାହାଯ୍ୟକାରୀ ଅଟେ ଯେତେବେଳେ:",
            "as": "তথ্যমূলক সাক্ষাৎকাৰ এটা সহায়ক হয় যেতিয়া:",
            "gu": "માહિતી પૂર્વક ઇન્ટરવ્યુ મદદરૂપ થાય છે જ્યારે:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "You are looking to change to a new job",
            "hi": "आप पुराना जॉब छोड़कर नया जॉब ढूंढने रहें हैं",
            "ka": "ನೀವು ಹೊಸ ಉದ್ಯೋಗಕ್ಕೆ ಬದಲಾಯಿಸಲು ಬಯಸುತ್ತೀರಿ",
            "te": "మీరు కొత్త ఉద్యోగానికి మారాలని చూసేటప్పుడు",
            "or": "ତୁମେ ଏକ ନୂତନ ଚାକିରିକୁ ପରିବର୍ତ୍ତନ କରିବାକୁ ଚାହୁଁଛ",
            "as": "তুমি এটা নতুন চাকৰি ল’ব বিচাৰিছা",
            "gu": "તમે જ્યારે નવી નોકરી બદલવા જઈ રહ્યા છો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "You need to apply to a college for your studies",
            "hi": "आपको अपनी पढाई पूरी करने के लिए कॉलेज में अप्लाई करना हैं",
            "ka": "ನಿಮ್ಮ ಅಧ್ಯಯನಕ್ಕಾಗಿ ನೀವು ಕಾಲೇಜಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಬೇಕು",
            "te": "మీరు మీ చదువుల కోసం కళాశాలకు దరఖాస్తు చేసుకోవాల్సినప్పుడు",
            "or": "ତୁମର ଅଧ୍ୟୟନ ପାଇଁ ତୁମେ ଏକ କଲେଜରେ ଆବେଦନ କରିବା ଆବଶ୍ୟକ",
            "as": "তুমি অধ্যয়নৰ বাবে এখন মহাবিদ্যালয়ত আবেদন কৰিব লাগে",
            "gu": "તમારે ભણવા માટે કોલેજમાં અરજી કરવી પડશે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "You want to know more about a career direction",
            "hi": "आपको करियर की दिशा के बारे में अधिक जानकारी प्राप्त करनी हैं",
            "ka": "ನೀವು ವೃತ್ತಿ ನಿರ್ದೇಶನದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಬಯಸುತ್ತೀರಿ",
            "te": "మీరు కెరీర్ డైరెక్షన్ గురించి మరింత తెలుసుకోవాలనుకున్నప్పుడు",
            "or": "ତୁମେ ଏକ କ୍ୟାରିୟର୍‌ ଦିଗ ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ଚାହୁଁଛ",
            "as": "তুমি জীৱিকাৰ দিশৰ বিষয়ে অধিক জানিব বিচাৰা",
            "gu": "તમે વધુ જાણવા માંગો છો કારકિર્દીની દિશા વિશે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.display",
          "value": {
            "en": "You want to know how you would feel in a particular role",
            "hi": "किसी विशेष रोल में आपको कैसा लगेगा यह जानना है",
            "ka": "ನಿರ್ದಿಷ್ಟ ಪಾತ್ರದಲ್ಲಿ ನೀವು ಹೇಗೆ ಭಾವಿಸುತ್ತೀರಿ ಎಂದು ತಿಳಿಯಲು ನೀವು ಬಯಸುತ್ತೀರಿ",
            "te": "ఒక నిర్దిష్ట పాత్రలో మీరు ఎలా ఫీల్ అవుతున్నారో తెలుసుకోవడానికి",
            "or": "ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ଭୂମିକାରେ ଆପଣ କିପରି ଅନୁଭବ କରିବେ ଆପଣ ଜାଣିବାକୁ ଚାହାଁନ୍ତି |",
            "as": "এটা নিৰ্দিষ্ট ভূমিকাত তুমি কেনে অনুভৱ কৰিবা সেইটো জানিব বিচাৰা",
            "gu": "તમને જાણવું છે કે તમે કેવું અનુભવશો આ નિશ્ચિત ભૂમિકા માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "बहुत बढ़िया!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "గొప్ప పని!",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ",
            "as": "ভাল কৰিছা!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "बहुत बढ़िया!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "గొప్ప పని!",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ",
            "as": "ভাল কৰিছা!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "बहुत बढ़िया!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "గొప్ప పని!",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ !",
            "as": "ভাল কৰিছা!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.popUpText",
          "value": {
            "en": "Try again! For an in-depth experience, an internship would be better.",
            "hi": "फिर से कोशिश करें! गहराई से अनुभव प्राप्त करने के लिए इन्टर्नशीय सही रहेगी।",
            "ka": "ಮತ್ತೆ ಪ್ರಯತ್ನಿಸು! ಆಳವಾದ ಅನುಭವಕ್ಕಾಗಿ, ಇಂಟರ್ನ್‌ಶಿಪ್ ಉತ್ತಮವಾಗಿರುತ್ತದೆ.",
            "te": "మళ్లీ ప్రయత్నించండి! లోతైన అనుభవం కోసం, ఇంటర్న్‌షిప్ మంచిది.",
            "or": "ପୁଣିଥରେ ଚେଷ୍ଟା କରନ୍ତୁ ! ଏକ ଗଭୀର ଅନୁଭୂତି ପାଇଁ, ଏକ ଇଣ୍ଟର୍ନସିପ୍ ଭଲ ହେବ |",
            "as": "পুনৰ চেষ্টা কৰা! এক গভীৰ অভিজ্ঞতাৰ বাবে, ইণ্টাৰ্নশ্বিপ এটা খুবেই ভাল হ'ব।",
            "gu": "ફરી પ્રયાસ કરો! ઊંડા અનુભવ માટે, ઇંટેંશિપ સારી રહશે."
          }
        }
      ],
      "page12": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_4_1",
            "hi": "B2_i_4_1",
            "ka": "B2_i_4_1",
            "te": "B2_i_4_1",
            "or": "B2_i_4_1",
            "as": "B2_i_4_1",
            "gu": "B2_i_4_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen decides to apply for this course. He opens a list of colleges on his phone based on state-wise ratings. Within Karnataka, he finds the following colleges.",
            "hi": "नवीन इस कोर्स के लिए अप्लाई करने का निर्णय लेता है। वह अपने मोबाईल में राज्य के अनुसार कॉलेज की लिस्ट देखता है, उसे कर्नाटक में नीचे दिए हुए कॉलेज मिलते हैं।",
            "ka": "ನವೀನ್ ಈ ಕೋರ್ಸ್‌ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ರಾಜ್ಯವಾರು ರೇಟಿಂಗ್‌ಗಳ ಆಧಾರದ ಮೇಲೆ ಅವನು ತನ್ನ ಫೋನ್‌ನಲ್ಲಿ ಕಾಲೇಜುಗಳ ಪಟ್ಟಿಯನ್ನು ತೆರೆಯುತ್ತಾನೆ. ಕರ್ನಾಟಕದೊಳಗೆ, ಅವನು ಈ ಕೆಳಗಿನ ಕಾಲೇಜುಗಳನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾರೆ.",
            "te": "నవీన్ ఈ కోర్సుకు దరఖాస్తు చేయాలని నిర్ణయించుకున్నాడు. రాష్ట్రాల వారి రేటింగ్‌ల ఆధారంగా అతను తన ఫోన్‌లో కళాశాలల జాబితాను ఓపెన్ చేస్తాడు. కర్ణాటకలో, అతను క్రింది కళాశాలలను కనుక్కున్నాడు.",
            "or": "ଏହି ପାଠ୍ୟକ୍ରମ ପାଇଁ ଆବେଦନ କରିବାକୁ ନବୀନ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି। ସେ ନିଜ ଫୋନରେ ରେଟିଙ୍ଗ୍‌ ଅନୁଯାୟୀ ରାଜ୍ୟ ଆଧାରରେ କଲେଜଗୁଡ଼ିକର ଏକ ତାଲିକା ଖୋଲନ୍ତି। କର୍ଣ୍ଣାଟକ ମଧ୍ୟରେ ସେ ନିମ୍ନଲିଖିତ କଲେଜଗୁଡ଼ିକୁ ପାଇଲେ |",
            "as": "নবীনে এই পাঠ্যক্ৰমৰ বাবে আবেদন কৰাৰ সিদ্ধান্ত লৈছে। তেওঁ ৰাজ্য অনুসৰি ৰেটিঙৰ ওপৰত আধাৰ কৰি তেওঁৰ ফোনত মহাবিদ্যালয়ৰ এখন তালিকা খোলে। কৰ্ণাটকৰ ভিতৰত, তেওঁ নিম্নলিখিত মহাবিদ্যালয়বোৰ বিচাৰি পায়।",
            "gu": "નવીન નક્કી કરે છે આ કોર્સમાં અરજી કરવાનું. તે તેના ફોન પર કોલેજોની યાદી ખોલે છે રાજ્ય મુજબ રેટિંગના આધારે. નીચેની કોલેજો મળે છે જે કર્ણાટકની અંદર."
          }
        }
      ],
      "page15": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_4_2_apply",
            "hi": "B2_i_4_2_अप्लाई करना",
            "ka": "B2_i_4_2_apply",
            "te": "B2_i_4_2_దరఖాస్తు",
            "or": "B2_i_4_2_apply",
            "as": "B2_i_4_2_apply",
            "gu": "B2_i_4_2_અરજી કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Help Naveen pick where all he can apply. He needs to apply to any 3.",
            "hi": "नवीन को कहाँ अप्लाई करना अच्छा रहेगा यह चुनने में मदद करें। उसे कोई भी 3 कॉलेज चुनने होंगे।",
            "ka": "ನವೀನ್ ಎಲ್ಲಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಬಹುದು ಎಂಬುದನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಸಹಾಯ ಮಾಡಿ. ಅವನು ಯಾವುದೇ 3 ಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಬೇಕಾಗಿದೆ.",
            "te": "నవీన్ ఎక్కడ దరఖాస్తు చేసుకోవచ్చో ఎంచుకునేందుకు సహాయం చేయండి. అతను ఏదైనా 3కి దరఖాస్తు చేసుకోవాలి.",
            "or": "ନବୀନଙ୍କୁ ସେ ଯେଉଁଠାରେ ଆବେଦନ କରିପାରିବେ ତାହା ଚୟନ କରିବାରେ ସାହାଯ୍ୟ କରନ୍ତୁ | ସେ ଯେକୌଣସି 3 ରେ ଆବେଦନ କରିବା ଆବଶ୍ୟକ କରନ୍ତି |",
            "as": "নবীনে ক'ত আবেদন কৰিব পাৰিব সেইটো বিচৰাৰ ক্ষেত্ৰত সহায় কৰা। তেওঁ যিকোনো 3 খনৰ বাবে আবেদন কৰিব লাগিব।",
            "gu": "નવીનને મદદ કરો ક્યાં તે અરજી કરી શકે છે. તેને 3 માં અરજી કરવાની છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Rating 4.6: RANB Aided College of Commerce and Management",
            "hi": "रेटिंग 4.6: आर.ए.एन.बी. एडेड कॉलेज ऑफ़ कॉमर्स एंड मैनेजमेंट",
            "ka": "ರೇಟಿಂಗ್ 4.6: ರಏಎಂಬಿ ಅನುದಾನಿತ ವಾಣಿಜ್ಯ ಮತ್ತು ನಿರ್ವಹಣೆ ಕಾಲೇಜು",
            "te": "రేటింగ్ 4.6: RANB ఎయిడెడ్ కాలేజ్ ఆఫ్ కామర్స్ అండ్ మేనేజ్‌మెంట్",
            "or": "ରେଟିଙ୍ଗ୍‌ 4.6: RANB Aided College of Commerce and Management",
            "as": "ৰেটিং 4.6: RANB এইডেদ কলেজ অফ কমাৰ্চ এণ্ড মেনেজমেণ্ট",
            "gu": "રેટિંગ 4.6: RANB એઇડ કોલેજ ઓફ કોમર્સ એન્ડ મેનેજમેન્ટ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Rating 4.2: Joseph College of Management",
            "hi": "रेटिंग 4.2: जोसेफ कॉलेज ऑफ़ मैनेजमेंट",
            "ka": "ರೇಟಿಂಗ್ 4.2: ಜೋಸೆಫ್ ಕಾಲೇಜ್ ಆಫ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್",
            "te": "రేటింగ్ 4.2: జోసెఫ్ కాలేజ్ ఆఫ్ మేనేజ్‌మెంట్",
            "or": "ରେଟିଙ୍ଗ୍‌ 4.2: Joseph College of Management",
            "as": "ৰেটিং 4.2: জোচেফ কলেজ অৱ মেনেজমেণ্ট",
            "gu": "રેટિંગ 4.2: જોસેફ કોલેજ ઓફ મેનેજમેન્ટ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Rating 4.0: Government college of commerce and Management",
            "hi": "रेटिंग 4.0: गवरमेंट कॉलेज ऑफ़ कॉमर्स एंड मैनेजमेंट",
            "ka": "ರೇಟಿಂಗ್ 4.0: ಸರ್ಕಾರಿ ವಾಣಿಜ್ಯ ಮತ್ತು ನಿರ್ವಹಣೆ ಕಾಲೇಜು",
            "te": "రేటింగ్ 4.0: గవర్న్మెంట్ కాలేజ్ ఆఫ్ కామర్స్ అండ్ మేనేజ్‌మెంట్",
            "or": "ରେଟିଙ୍ଗ୍‌ 4.0: Government college of commerce and Management",
            "as": "ৰেটিং 4.0: গভৰ্ণমেণ্ট কলেজ অফ কমাৰ্চ এণ্ড মেনেজমেণ্ট",
            "gu": "રેટિંગ 4.0: સરકારી કોલેજ ઓફ કોમર્સ એન્ડ મેનેજમેન્ટ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.display",
          "value": {
            "en": "Rating 3.2: SPJ College for Management",
            "hi": "रेटिंग 3.2: एस.पी.जे. कॉलेज ऑफ़ मैनेजमेंट",
            "ka": "ರೇಟಿಂಗ್ 3.2: ಎಸ್ ಪಿಜೆ ಕಾಲೇಜ್ ಫಾರ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್",
            "te": "రేటింగ్ 3.2: SPJ కాలేజ్ ఫర్ మేనేజ్‌మెంట్",
            "or": "ରେଟିଙ୍ଗ୍‌ 3.2: SPJ College for Management",
            "as": "ৰেটিং 3.2: SPJ কলেজ ফৰ মেনেজমেণ্ট",
            "gu": "રેટિંગ 3.2: SPJ કોલેજ ફોર મેનેજમેન્ટ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.display",
          "value": {
            "en": "Rating 3.0: MCC College for Arts, Commerce and Management",
            "hi": "रेटिंग 3.0: एम.सी.सी. कॉलेज ऑफ़ आर्ट्स, कॉमर्स एंड मैनेजमेंट",
            "ka": "ರೇಟಿಂಗ್ 3.0: ಎಂಸಿಸಿ ಕಾಲೇಜ್ ಫಾರ್ ಆರ್ಟ್ಸ್, ಕಾಮರ್ಸ್ ಮತ್ತು ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್",
            "te": "రేటింగ్ 3.0: MCC కాలేజ్ ఫర్ ఆర్ట్స్, కామర్స్ అండ్ మేనేజ్‌మెంట్",
            "or": "ରେଟିଙ୍ଗ୍‌ 3.0: MCC College for Arts, Commerce and Management",
            "as": "ৰেটিং 3.0: MCC কলেজ ফৰ আৰ্টচ, কমাৰ্চ এণ্ড মেনেজমেণ্ট",
            "gu": "રેટિંગ 3.0: આર્ટસ, કોમર્સ અને મેનેજમેન્ટ માટે MCC કોલેજ"
          }
        }
      ],
      "page14": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_4_3",
            "hi": "B2_i_4_3",
            "ka": "B2_i_4_3",
            "te": "B2_i_4_3",
            "or": "B2_i_4_3",
            "as": "B2_i_4_3",
            "gu": "B2_i_4_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Wohoooo! Naveen gets admission to his top priority college for a course in BBA.",
            "hi": "वा! नवीन को BBA के कोर्स के लिए उसके सबसे पसंदीदा कॉलेज में एडमिशन मिल गया है।",
            "ka": "ವೂಹೂ! ನವೀನ್ ಬಿಬಿಎ ಕೋರ್ಸ್‌ಗೆ ತನ್ನ ಪ್ರಮುಖ ಆದ್ಯತೆಯ ಕಾಲೇಜಿಗೆ ಪ್ರವೇಶ ಪಡೆಯುತ್ತಾನೆ.",
            "te": "హుర్రే! నవీన్ కు BBA లో ఒక కోర్సు కోసం తన జాబితాలోని అత్యంత ప్రధానమైన కళాశాలలో అడ్మిషన్ వచ్చింది.",
            "or": "ୱୁହୁ ! BBA ରେ ପାଠ୍ୟକ୍ରମ ପାଇଁ ନବୀନ ତାଙ୍କର ସର୍ବୋଚ୍ଚ ପ୍ରାଥମିକ କଲେଜରେ ଆଡମିଶନ ପାଆନ୍ତି |",
            "as": "ৱাও! নবীনে BBAৰ পাঠ্যক্ৰমৰ বাবে তেওঁৰ শীৰ্ষ অগ্ৰাধিকাৰপ্ৰাপ্ত মহাবিদ্যালয়খনত নামভৰ্তি কৰে।",
            "gu": "જોરદાર! નવીનને ટોચની પ્રાથમિકતાવાળી કોલેજમાં પ્રવેશ મળે છે જેમાં બીબીએના કોર્સ થાય છે."
          }
        }
      ]
    },
    "scene5": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_i_1_1",
            "hi": "B2_i_i_1_1",
            "ka": "B2_i_i_1_1",
            "te": "B2_i_i_1_1",
            "or": "B2_i_i_1_1",
            "as": "B2_i_i_1_1",
            "gu": "B2_i_i_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen decides to answer the questions his teacher has assigned him.",
            "hi": "नवीन शिक्षिका ने उसे पूछे हुए प्रश्नों के उत्तर देने का निर्णय लेता है।",
            "ka": "ನವೀನ್ ತನ್ನ ಶಿಕ್ಷಕರು ತನಗೆ ನಿಯೋಜಿಸಿದ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
            "te": "నవీన్ తన టీచర్ తనకు అప్పగించిన ప్రశ్నలకు సమాధానం ఇవ్వాలని నిర్ణయించుకున్నాడు.",
            "or": "ତାଙ୍କ ଶିକ୍ଷକ ଦ୍ୱାରା ତାଙ୍କୁ ନ୍ଯସ୍ତ କରାଯାଇଥିବା ପ୍ରଶ୍ନର ଉତ୍ତର ଦେବାକୁ ନବୀନ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି।",
            "as": "নবীনে শিক্ষকে তেওঁক আৱণ্টন কৰা প্ৰশ্নবোৰৰ উত্তৰ দিয়াৰ সিদ্ধান্ত লয়।",
            "gu": "નવીન તેના શિક્ષકે તેને આપેલા પ્રશ્નોના જવાબ આપવાનું નક્કી કરે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_i_1_2a",
            "hi": "B2_i_i_1_2a",
            "ka": "B2_i_i_1_2a",
            "te": "B2_i_i_1_2a",
            "or": "B2_i_i_1_2a",
            "as": "B2_i_i_1_2a",
            "gu": "B2_i_i_1_2a"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Click on the questions to reveal Naveen’s answers",
            "hi": "नवीन के उत्तरों को दिखाने के लिए प्रश्नों पर क्लिक करें.",
            "ka": "ನವೀನ್ ಅವರ ಉತ್ತರಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಪ್ರಶ್ನೆಗಳ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ",
            "te": "నవీన్ జవాబులు బయట పెట్టడానికి ప్రశ్నలపై క్లిక్ చేయండి(నొక్కండి)",
            "or": "ନବୀନଙ୍କ ଉତ୍ତର ପ୍ରକାଶ କରିବାକୁ ପ୍ରଶ୍ନଗୁଡିକ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ |",
            "as": "নবীনৰ উত্তৰবোৰ প্ৰকাশ কৰিবলৈ প্ৰশ্নবোৰত ক্লিক কৰা",
            "gu": "નવીનના જવાબો જાણવા માટે પ્રશ્નો પર ક્લિક કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Why do I want to study?",
            "hi": "मैं पढ़ाई क्यों करना चाहता हूँ?",
            "ka": "ನಾನು ಏಕೆ ಅಧ್ಯಯನ ಮಾಡಲು ಬಯಸುತ್ತೇನೆ?",
            "te": "నేను ఎందుకు చదువుకోవాలనుకుంటున్నాను?",
            "or": "ମୁଁ କାହିଁକି ଅଧ୍ୟୟନ କରିବାକୁ ଚାହୁଁଛି ?",
            "as": "মই কিয় পঢ়িব বিচাৰোঁ?",
            "gu": "મારે શું ભણવું છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "What do I enjoy doing?",
            "hi": "मुझे क्या करना पसंद है?",
            "ka": "ನಾನು ಏನು ಮಾಡುವುದನ್ನು ಆನಂದಿಸುತ್ತೇನೆ?",
            "te": "నాకు ఏమి చేయడం నచ్చుతుంది?",
            "or": "ମୁଁ କ’ଣ କରିବାକୁ ଉପଭୋଗ କରେ?",
            "as": "মই কি কৰি ভাল পাওঁ?",
            "gu": "મને શેમાં આનંદ આવે છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.beforeReveal",
          "value": {
            "en": "What kind of job do I want?",
            "hi": "मैं किस तरह की नौकरी करना चाहता हूँ?",
            "ka": "ನನಗೆ ಯಾವ ರೀತಿಯ ಕೆಲಸ ಬೇಕು?",
            "te": "నాకు ఎలాంటి ఉద్యోగం కావాలి?",
            "or": "ମୁଁ କେଉଁ ପ୍ରକାରର ଚାକିରି ଚାହୁଁଛି ?",
            "as": "মই কেনে ধৰণৰ চাকৰি বিচাৰোঁ?",
            "gu": "મારે કેવા પ્રકારની નોકરી જોઈએ છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "To get a good job and become financially independent.",
            "hi": "अच्छा जॉब प्राप्त करके आर्थिक रूप से स्वतंत्र होने के लिए।",
            "ka": "ಉತ್ತಮ ಉದ್ಯೋಗ ಪಡೆಯಲು ಮತ್ತು ಆರ್ಥಿಕವಾಗಿ ಸ್ವತಂತ್ರರಾಗಲು.",
            "te": "మంచి ఉద్యోగం తెచ్చుకోని ఆర్థికంగా స్వతంత్రంగా అవ్వాలి.",
            "or": "ଏକ ଭଲ ଚାକିରି ପାଇବା ଏବଂ ଆର୍ଥିକ ଦୃଷ୍ଟିରୁ ସ୍ୱାଧୀନ ହେବା |",
            "as": "এটা ভাল চাকৰি পাবলৈ আৰু আৰ্থিকভাৱে স্বতন্ত্ৰ হ’বলৈ।",
            "gu": "સારી નોકરી મેળવવી અને આર્થિક રીતે સ્વતંત્ર બનવા માટે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "I love dancing and understanding how things work.",
            "hi": "मुझे डान्स करना और चीजें कैसे काम करती हैं यह समझना अच्छा लगता है।",
            "ka": "ನಾನು ನೃತ್ಯವನ್ನು ಇಷ್ಟಪಡುತ್ತೇನೆ ಮತ್ತು ವಿಷಯಗಳು ಹೇಗೆ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತವೆ ಎಂಬುದನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು.",
            "te": "నాకు డ్యాన్స్ చేయడం మరియు విషయాలు ఎలా పని చేస్తాయో అర్థం చేసుకోవడం చాలా ఇష్టం.",
            "or": "ମୁଁ ନାଚିବାକୁ ଏବଂ ଜିନିଷଗୁଡିକ କିପରି କାମ କରେ ବୁଝିବାକୁ ଭଲ ପାଏ |",
            "as": "মই নৃত্য আৰু বস্তুবোৰে কেনেদৰে কাম কৰে বুজিবলৈ ভাল পাওঁ।",
            "gu": "મને નૃત્ય કરવું અને બધુ કેવી રીતે કામ કરે છે તે જાણવું ગમે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.afterReveal",
          "value": {
            "en": "Social, rough and tough, talking to people, meeting people",
            "hi": "सामाजिक, थोड़ी-बहुत मेहनत करना, लोगों से बात करना, लोगों को मिलना",
            "ka": "ಸಾಮಾಜಿಕ, ಒರಟು ಮತ್ತು ಕಠಿಣ, ಜನರೊಂದಿಗೆ ಮಾತನಾಡುವುದು, ಜನರನ್ನು ಭೇಟಿ ಮಾಡುವುದು",
            "te": "సామాజికంగా, కఠినంగా మరియు కఠినంగా, వ్యక్తులతో మాట్లాడటం, ప్రజలను కలవడం",
            "or": "ସାମାଜିକ, ରଫ୍‌ ଏବଂ ଟପ୍‌, ଲୋକଙ୍କ ସହ କଥାବାର୍ତ୍ତା, ଲୋକଙ୍କୁ ଭେଟିବା",
            "as": "সামাজিক, ৰুক্ষ আৰু কঠিন, মানুহৰ সৈতে কথা পতা, মানুহক লগ কৰা",
            "gu": "સામાજિક, અસ્ત વ્યસ્ત, લોકો સાથે વાત કરવી, લોકોને મળવું"
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_i_1_2b",
            "hi": "B2_i_i_1_2b",
            "ka": "B2_i_i_1_2b",
            "te": "B2_i_i_1_2b",
            "or": "B2_i_i_1_2b",
            "as": "B2_i_i_1_2b",
            "gu": "B2_i_i_1_2b"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Click on the questions to reveal Naveen’s answers",
            "hi": "नवीन के उत्तरों को दिखाने के लिए प्रश्नों पर क्लिक करें।",
            "ka": "ನವೀನ್ ಅವರ ಉತ್ತರಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಪ್ರಶ್ನೆಗಳ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ",
            "te": "నవీన్ జవాబులను బయట పెట్టడానికి ప్రశ్నలపై క్లిక్ చేయండి (నొక్కండి)",
            "or": "ନବୀନଙ୍କ ଉତ୍ତର ପ୍ରକାଶ କରିବାକୁ ପ୍ରଶ୍ନଗୁଡିକ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ |",
            "as": "নবীনৰ উত্তৰবোৰ প্ৰকাশ কৰিবলৈ প্ৰশ্নবোৰত ক্লিক কৰা",
            "gu": "નવીનના જવાબો જાણવા માટે પ્રશ્નો પર ક્લિક કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Where do I want to study?",
            "hi": "मैं कहाँ पढ़ाई करना चाहता हूँ?",
            "ka": "ನಾನು ಎಲ್ಲಿ ಅಧ್ಯಯನ ಮಾಡಲು ಬಯಸುತ್ತೇನೆ?",
            "te": "నేను ఎక్కడ చదువుకోవాలనుకుంటున్నాను?",
            "or": "ମୁଁ କେଉଁଠାରେ ଅଧ୍ୟୟନ କରିବାକୁ ଚାହୁଁଛି ?",
            "as": "মই ক'ত পঢ়িব বিচাৰো?",
            "gu": "મારે શું ભણવું છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "What course do you have in mind?",
            "hi": "तुम किस तरह के कोर्स के बारे में सोच रहे हो?",
            "ka": "ನಿಮ್ಮ ಮನಸ್ಸಿನಲ್ಲಿ ಯಾವ ಕೋರ್ಸ್ ಇದೆ?",
            "te": "మీ మనసులో ఏ కోర్సు ఉంది?",
            "or": "ଆପଣଙ୍କ ମନରେ କେଉଁ ପାଠ୍ୟକ୍ରମ ଅଛି ?",
            "as": "তোমাৰ মনত কি পাঠ্যক্ৰম আছে?",
            "gu": "તમારા મગજમાં કયો કોર્સ છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.beforeReveal",
          "value": {
            "en": "Where do you want to apply?",
            "hi": "तुम कहाँ अप्लाई करना चाहते हो?",
            "ka": "ನೀವು ಎಲ್ಲಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಬಯಸುತ್ತೀರಿ?",
            "te": "మీరు ఎక్కడ దరఖాస్తు చేయాలనుకుంటున్నారు?",
            "or": "ଆପଣ କେଉଁଠାରେ ଆବେଦନ କରିବାକୁ ଚାହୁଁଛନ୍ତି ?",
            "as": "তুমি ক'ত আবেদন কৰিব বিচাৰা?",
            "gu": "કઈ જગ્યાએ તમારે અરજી કરવી છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "Govt college or college where the fees is less.",
            "hi": "सरकारी कॉलेज या ऐसा कॉलेज जहाँ फी कम हो।",
            "ka": "ಶುಲ್ಕ ಕಡಿಮೆ ಇರುವ ಸರ್ಕಾರಿ ಕಾಲೇಜು ಅಥವಾ ಕಾಲೇಜು.",
            "te": "ఫీజులు తక్కువగా ఉన్న కళాశాలలా లేదా ప్రభుత్వ కళాశాలలా.",
            "or": "ସରକାରୀ କଲେଜ କିମ୍ବା କଲେଜ ଯେଉଁଠାରେ ଫିସ୍‌ କମ୍ ଥାଏ |",
            "as": "চৰকাৰী মহাবিদ্যালয় বা মাচুল কম লোৱা মহাবিদ্যালয়ত।",
            "gu": "સરકારી કોલેજમાં કે જે કોલેજમાં ફી ઓછી હોય."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "After searching online and talking to people about available courses, I feel that a course like Bachelors in Business Administration (BBA) makes the most sense for me to do.",
            "hi": "उपलब्ध कोर्सेस के बारे में ऑनलाइन सर्च करने के बाद और लोगों से बात करने के बाद, मुझे लगता है कि बैचलर्स ऑफ़ बिजनेस एडमिनिस्ट्रेशं (BBA) ज्यादा सही कोर्स लगता है।",
            "ka": "ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಹುಡುಕಿದ ನಂತರ ಮತ್ತು ಲಭ್ಯವಿರುವ ಕೋರ್ಸ್‌ಗಳ ಕುರಿತು ಜನರೊಂದಿಗೆ ಮಾತನಾಡಿದ ನಂತರ, ಬ್ಯಾಚುಲರ್ಸ್ ಇನ್ ಬಿಸಿನೆಸ್ ಅಡ್ಮಿನಿಸ್ಟ್ರೇಷನ್ (BBA) ನಂತಹ ಕೋರ್ಸ್ ನನಗೆ ಮಾಡಲು ಹೆಚ್ಚು ಅರ್ಥಪೂರ್ಣವಾಗಿದೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
            "te": "ఆన్‌లైన్‌లో శోధించిన తర్వాత మరియు అందుబాటులో ఉన్న కోర్సుల గురించి వ్యక్తులతో మాట్లాడిన తర్వాత, బ్యాచిలర్స్ ఇన్ బిజినెస్ అడ్మినిస్ట్రేషన్‌ (BBA) వంటి కోర్సు నాకు బాగా సరిపోతుందని నేను అనుకుంటున్నాను.",
            "or": "ଅନ୍‌ଲାଇନ୍ ସର୍ଚ୍ଚ କରିବା ଏବଂ ଉପଲବ୍ଧ ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ଲୋକଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବା ପରେ, ମୁଁ ଅନୁଭବ କରେ ଯେ ବ୍ୟାଚେଲର୍ସ ଇନ୍ ବିଜନେସ୍ ଆଡମିନିଷ୍ଟ୍ରେସନ୍ (BBA) ପରି ଏକ ପାଠ୍ୟକ୍ରମ ମୋ ପାଇଁ ଅଧିକ ଅର୍ଥ ପ୍ରଦାନ କରେ |",
            "as": "অনলাইনত অনুসন্ধান কৰাৰ পিছত আৰু উপলব্ধ পাঠ্যক্ৰমৰ বিষয়ে মানুহৰ সৈতে কথা পতাৰ পিছত, মই অনুভৱ কৰো যে বেচেলৰ ইন বিজনেছ এডমিনিষ্ট্ৰেচন (BBA)ৰ দৰে পাঠ্যক্ৰম কৰাটো মোৰ বাবে আটাইতকৈ বেছি অৰ্থপূৰ্ণ।",
            "gu": "ઇન્ટરનેટ પર શોધ કર્યા પછી અને ઉપલબ્ધ અભ્યાસક્રમો વિશે લોકો સાથે વાત કર્યા પછી, મને લાગે છે કે બેચલર ઑફ બિઝનેસ એડમિનિસ્ટ્રેશન (BBA) કોર્સ મારા માટે શ્રેષ્ઠ છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.afterReveal",
          "value": {
            "en": "I have heard that specific Christian colleges have the course and others. I should apply to a few Christian colleges as well.",
            "hi": "मैंने सुना है कि विशेष क्रिशचन कॉलेज में यह और अन्य कोर्स हैं। मुझे कुछ क्रिशचन  कॉलेजों में भी अप्लाई करना चाहिए।",
            "ka": "ನಿರ್ದಿಷ್ಟ ಕ್ರಿಶ್ಚಿಯನ್ ಕಾಲೇಜುಗಳಲ್ಲಿ ಕೋರ್ಸ್ ಮತ್ತು ಇತರವುಗಳಿವೆ ಎಂದು ನಾನು ಕೇಳಿದ್ದೇನೆ. ನಾನು ಕೆಲವು ಕ್ರಿಶ್ಚಿಯನ್ ಕಾಲೇಜುಗಳಿಗೂ ಅರ್ಜಿ ಸಲ್ಲಿಸಬೇಕು.",
            "te": "నిర్దిష్ట క్రిస్టియన్ కళాశాలల్లో ఇతరులకు కోర్సులున్నాయని నేను విన్నాను . నేను కొన్ని క్రిస్టియన్ కళాశాలలకు కూడా దరఖాస్తు చేయాలి.",
            "or": "ମୁଁ ଶୁଣିଛି ଯେ ନିର୍ଦ୍ଦିଷ୍ଟ ଖ୍ରୀଷ୍ଟିଆନ କଲେଜଗୁଡ଼ିକରେ ପାଠ୍ୟକ୍ରମ ଅଛି ଏବଂ ଅନ୍ୟମାନେ | ମୁଁ ମଧ୍ୟ କିଛି ଖ୍ରୀଷ୍ଟିଆନ କଲେଜରେ ଆବେଦନ କରିବା ଉଚିତ୍ |",
            "as": "মই শুনিছোঁ যে নিৰ্দিষ্ট খ্ৰীষ্টান মহাবিদ্যালয়সমূহত পাঠ্যক্ৰম বোৰ আছে। মই কেইখনমান খ্ৰীষ্টান মহাবিদ্যালয়তো আবেদন কৰা উচিত হ’ব।",
            "gu": "મેં સાંભળ્યું છે કે કોઈક ખ્રિસ્તી કોલેજોમાં કોર્સ છે અને બીજા. મારે થોડીક ખ્રિસ્તી કોલેજોમાં પણ અરજી કરવી જોઈએ."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_i_2_1//",
            "hi": "B2_i_i_2_1//",
            "ka": "B2_i_i_2_1//",
            "te": "B2_i_i_2_1//",
            "or": "B2_i_i_2_1//",
            "as": "B2_i_i_2_1//",
            "gu": "B2_i_i_2_1//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "After answering the questions, he approaches Mrs Bansal and shows her his responses.",
            "hi": "प्रश्नों के उत्तर देने के बाद, वह श्रीमती बंसल के पास जाता है और अपने उत्तर उन्हें दिखाता है।",
            "ka": "ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಿದ ನಂತರ, ಅವನು ಶ್ರೀಮತಿ ಬನ್ಸಾಲ್ ಅವರನ್ನು ಸಂಪರ್ಕಿಸುತ್ತಾನೆ ಮತ್ತು ಅವನ ಪ್ರತಿಕ್ರಿಯೆಗಳನ್ನು ತೋರಿಸುತ್ತಾನೆ.",
            "te": "ప్రశ్నలకు జవాబులు రాసిన తరువాత, అతను శ్రీమతి బన్సాల్‌ను కలిసి ఆమెకు తన జవాబులు చూపించాడు.",
            "or": "ପ୍ରଶ୍ନଗୁଡିକର ଉତ୍ତର ଦେବା ପରେ, ସେ ଶ୍ରୀମତୀ ବନସଲ୍‌ଙ୍କ ନିକଟକୁ ଯାଆନ୍ତି ଏବଂ ତାଙ୍କୁ ତାଙ୍କ ପ୍ରତିକ୍ରିୟା ଦେଖାନ୍ତି |",
            "as": "প্ৰশ্নবোৰৰ উত্তৰ দিয়াৰ পিছত, তেওঁ শ্ৰীমতী বনচালৰ ওচৰলৈ যায় আৰু তেওঁক তেওঁৰ সঁহাৰিবোৰ দেখুৱায়।",
            "gu": "પ્રશ્નોના જવાબ આપ્યા પછી, તે શ્રીમતી બંસલનો સંપર્ક કરે છે અને તેમની પ્રતિક્રિયાઓ દર્શાવે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Mrs Bansal",
            "hi": "श्रीमती बंसल",
            "ka": "ಶ್ರೀಮತಿ ಬನ್ಸಾಲ್",
            "te": "శ్రీమతి బన్సాల్",
            "or": "ଶ୍ରୀମତୀ ବନସଲ୍‌",
            "as": "শ্ৰীমতী বনচাল",
            "gu": "શ્રીમતી બંસલ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Oh, so you have decided to do a BBA? Why exactly did you choose that?",
            "hi": "ओह, तो तुमने BBA करने का निर्णय ले लिया है? तुमने यहीं कोर्स क्यों चुना है?",
            "ka": "ಓಹ್, ಹಾಗಾದರೆ ನೀವು BBA ಮಾಡಲು ನಿರ್ಧರಿಸಿದ್ದೀರಾ? ನೀವು ಅದನ್ನು ನಿಖರವಾಗಿ ಏಕೆ ಆರಿಸಿದ್ದೀರಿ?",
            "te": "అవునా, నువ్వు BBA చేయాలని నిర్ణయించుకున్నావా? నువ్వు సరిగ్గా ఇది ఎందుకు ఎంచుకున్నావు?",
            "or": "ଓହ, ତେଣୁ ଆପଣ ଏକ BBA କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି କି ? ତୁମେ କାହିଁକି ଠିକ୍ ତାହା ଚୟନ କଲ?",
            "as": "অ, তাৰমানে তুমি বি.বি.এ.কৰাৰ সিদ্ধান্ত লৈছা? তুমি সেইটো কিয় বাছনি কৰিলা বাৰু?",
            "gu": "બરાબર, તે BBA કરવાનું નક્કી કર્યું? કેમ તે આ નક્કી કર્યું?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "I thought about it, Ma’am. I want to do a course where I can learn different aspects of management and business.",
            "hi": "मैंने इसके बारे में विचार किया है। मैं ऐसा कोर्स करना चाहता हूँ जिसमें मैं मैनेजमेंट और बिजनेस के विविध पहलुओं के बारे में सीख सकूं।",
            "ka": "ನಾನು ಅದರ ಬಗ್ಗೆ ಯೋಚಿಸಿದೆ, ಮೇಡಂ. ನಾನು ನಿರ್ವಹಣೆ ಮತ್ತು ವ್ಯವಹಾರದ ವಿವಿಧ ಅಂಶಗಳನ್ನು ಕಲಿಯಬಹುದಾದ ಕೋರ್ಸ್ ಮಾಡಲು ಬಯಸುತ್ತೇನೆ.",
            "te": "నేను దాని గురించి ఆలోచించాను, మేడమ్. నేను మేనేజ్‌మెంట్ మరియు బిజినెస్‌కి సంబంధించిన వివిధ అంశాలను నేర్చుకునే కోర్సు చేయాలనుకుంటున్నాను.",
            "or": "ମୁଁ ଏହା ବିଷୟରେ ଚିନ୍ତା କଲି, ମ୍ୟାଡାମ୍‌ | ମୁଁ ଏକ ପାଠ୍ୟକ୍ରମ କରିବାକୁ ଚାହୁଁଛି ଯେଉଁଠାରେ ମୁଁ ମ୍ୟାନେଜ୍‌ମେଣ୍ଟ୍‍ ଏବଂ ବିଜନେସ୍‌ର ବିଭିନ୍ନ ଦୃଷ୍ଟିକୋଣ ଶିଖିପାରିବି |",
            "as": "মই এই বিষয়ে ভাবিলো, মেডাম। মই এনে এটা পাঠ্যক্ৰম কৰিব বিচাৰো য'ত মই ব্যৱস্থাপনা আৰু ব্যৱসায়ৰ বিভিন্ন দিশ শিকিব পাৰো।",
            "gu": "મે આના વિષે ગણું વિચાર્યું, મેડમ. મારે આ કોર્સ એટલા માટે કરવો છે કે મને અલગ અલગ મેનેજમેંટ અને ધંધા વિષે જાણવા મળે. "
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_i_2_2////",
            "hi": "B2_i_i_2_2////",
            "ka": "B2_i_i_2_2////",
            "te": "B2_i_i_2_2////",
            "or": "B2_i_i_2_2////",
            "as": "B2_i_i_2_2////",
            "gu": "B2_i_i_2_2////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Are you sure Naveen? It’s still quite a sudden decision.",
            "hi": "क्या तुम्हें पूरा यकीन है नवीन? यह अभी भी जल्दबाजी में लिया निर्णय है।",
            "ka": "ನವೀನ್ ನಿಮಗೆ ಖಚಿತವಾಗಿದೆಯೇ? ಇದು ಇನ್ನೂ ಹಠಾತ್ ನಿರ್ಧಾರವಾಗಿದೆ.",
            "te": "నవీన్ నువ్వు ఖచ్చితంగా అనుకుంటున్నావా ? ఇది ఇప్పటికీ చాలా అకస్మాత్తుగా తీసుకున్న నిర్ణయం.",
            "or": "ତୁମେ ନିଶ୍ଚିତ କି ନବୀନ? ଏହା ଏପର୍ଯ୍ୟନ୍ତ ଏକ ହଠାତ୍ ନିଷ୍ପତ୍ତି ଅଟେ |",
            "as": "তুমি নিশ্চিত নে নবীন? এইটো এতিয়াও এটা আকস্মিক সিদ্ধান্ত।",
            "gu": "નવીન તને ખાતરી છે કે? તે એકદમ અચાનક નિર્ણય છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Yes, ma’am, I went through the other courses available on the websites of various colleges. This course has a variety of subjects taught through projects and practical learning.",
            "hi": "हाँ, मैम, मैंने अलग-अलग कॉलेजों के वेबसाइट पर उपलब्ध कोर्स के बारे में जानकारी प्राप्त की है। इस कोर्स में प्रोजेक्ट और प्रैक्टिकल के जरिए अनेक विषय पढ़ाए जाते हैं।",
            "ka": "ಹೌದು, ಮೇಡಂ, ನಾನು ವಿವಿಧ ಕಾಲೇಜುಗಳ ವೆಬ್‌ಸೈಟ್‌ಗಳಲ್ಲಿ ಲಭ್ಯವಿರುವ ಇತರ ಕೋರ್ಸ್‌ಗಳನ್ನು ಓದಿದ್ದೇನೆ. ಈ ಕೋರ್ಸ್ ಯೋಜನೆಗಳು ಮತ್ತು ಪ್ರಾಯೋಗಿಕ ಕಲಿಕೆಯ ಮೂಲಕ ಕಲಿಸುವ ವಿವಿಧ ವಿಷಯಗಳನ್ನು ಹೊಂದಿದೆ.",
            "te": "అవును, మేడమ్, నేను వివిధ కళాశాలల వెబ్‌సైట్‌లలో అందుబాటులో ఉన్న ఇతర కోర్సులను చదివాను. ఈ కోర్సులో ప్రాజెక్ట్‌లు మరియు ప్రాక్టికల్ లర్నింగ్ ద్వారా బోధించే వివిధ రకాల సబ్జెక్టులు ఉన్నాయి.",
            "or": "ହଁ, ମା, ମୁଁ ବିଭିନ୍ନ କଲେଜର ୱେବସାଇଟରେ ଅନ୍ୟାନ୍ୟ ପାଠ୍ୟକ୍ରମ ଦେଇ ଗଲି | ଏହି ପାଠ୍ୟକ୍ରମରେ ଅନେକ ବିଷୟ ଅଛି | ଏହି ବିଷୟଗୁଡିକ ପ୍ରୋଜେକ୍ଟ ଏବଂ ବ୍ୟବହାରିକ ଶିକ୍ଷଣ ମାଧ୍ୟମରେ ଶିକ୍ଷା ଦିଆଯାଏ |",
            "as": "হয়, মেডাম, মই বিভিন্ন মহাবিদ্যালয়ৰ ৱেবছাইটত উপলব্ধ অন্যান্য পাঠ্যক্ৰমবোৰ পঢ়িছিলো। এই পাঠ্যক্ৰমত প্ৰকল্প আৰু ব্যৱহাৰিক শিক্ষাৰ জৰিয়তে বিভিন্ন বিষয় শিকোৱা হয়।",
            "gu": "હા, મેડમ, મેં અલગ અલગ કોલેજોની વેબસાઈટ પર ઉપલબ્ધ બીજા કોર્સ જોયા હતા. આ કોર્સમાં પ્રોજેક્ટ્સ અને પ્રેક્ટિકલ દ્વારા શીખવવામાં આવતા વિવિધ વિષયો છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "I will collaborate with so many people. It sounds like something I will enjoy.",
            "hi": "मैं बहुत सारे लोगों के साथ काम कर सकूंगा। मुझे लगता है कि ऐसा करना मुझे अच्छा लगेगा।",
            "ka": "ನಾನು ತುಂಬಾ ಜನರೊಂದಿಗೆ ಸಹಕರಿಸುತ್ತೇನೆ. ನಾನು ಆನಂದಿಸುವ ಏನೋ ಎಂದು ಧ್ವನಿಸುತ್ತದೆ.",
            "te": "నేను చాలా మందితో కలిసి పని చేస్తాను. ఇది నేను ఆనందిస్తానని అనిపిస్తుంది.",
            "or": "ମୁଁ ବହୁତ ଲୋକଙ୍କ ସହିତ ସଂଯୋଗ କରିବି | ମୁଁ ଭାବୁଛି ମୁଁ ଉପଭୋଗ କରିବି |",
            "as": "মই বহুতো লোকৰ সৈতে সহযোগিতা কৰিম। এনেকুৱা লাগিছে যেন ইয়াক মই উপভোগ কৰিম।",
            "gu": "હું ઘણા લોકો સાથે કામ કરીશ. કંઈક મને આનંદ આવે એવું લાગે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Well, I’m happy to hear that you have some clarity on which course would be the best for you, Naveen. Best of luck!",
            "hi": "ठीक है, मुझे खुशी है कि तुम्हारे लिए कौन सा कोर्स अच्छा रहेगा इसके बारे में अब तुम्हे यकीन है, नवीन। बेस्ट ऑफ़ लक!",
            "ka": "ನವೀನ್, ನಿಮಗೆ ಯಾವ ಕೋರ್ಸ್ ಉತ್ತಮವಾಗಿದೆ ಎಂಬುದರ ಕುರಿತು ನಿಮಗೆ ಸ್ವಲ್ಪ ಸ್ಪಷ್ಟತೆ ಇದೆ ಎಂದು ಕೇಳಲು ನನಗೆ ಸಂತೋಷವಾಗಿದೆ. ಒಳ್ಳೆಯದಾಗಲಿ!",
            "te": "నవీన్, మీకు ఏ కోర్సు ఉత్తమమనే దానిపై మీకు కొంత స్పష్టత ఉందని వినడానికి నేను సంతోషిస్తున్నాను. శుభం జరుగుగాక!",
            "or": "ନବୀନ, ମୁଁ ଶୁଣି ଖୁସି ଯେ ଆପଣ ସ୍ପଷ୍ଟ ଭାବରେ ବୁ understand ିଛନ୍ତି ଯେ କେଉଁ ପାଠ୍ୟକ୍ରମ ଆପଣଙ୍କ ପାଇଁ ସର୍ବୋତ୍ତମ ହେବ | ଶୁଭ କାମନା!",
            "as": "ঠিক আছে নবীন, মই শুনি সুখী হৈছো যে কোনটো পাঠ্যক্ৰম তোমাৰ বাবে সৰ্বশ্ৰেষ্ঠ হ'ব সেই বিষয়ে তোমাৰ কিছু স্পষ্টতা আহিছে। শুভকামনা!",
            "gu": "બરાબર, મને ખુશી મળી કે નવીન, તારે કયો કોર્સ યોગ્ય છે તે અંગે તને થોડી ખબર છે. શુભેચ્છા મારા તરફથી!"
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_i_3_1",
            "hi": "B2_i_i_3_1",
            "ka": "B2_i_i_3_1",
            "te": "B2_i_i_3_1",
            "or": "B2_i_i_3_1",
            "as": "B2_i_i_3_1",
            "gu": "B2_i_i_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen decides to apply for this course. He opens a list of colleges on his phone based on state-wise ratings. Within Karnataka, he finds the following colleges.",
            "hi": "नवीन इस कोर्स के लिए अप्लाई करने का निर्णय लेता है। वह अपने मोबाईल में राज्य के अनुसार कॉलेज की लिस्ट देखता है, उसे कर्नाटक में नीचे दिए हुए कॉलेज मिलते हैं।",
            "ka": "ನವೀನ್ ಈ ಕೋರ್ಸ್‌ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ರಾಜ್ಯವಾರು ರೇಟಿಂಗ್‌ಗಳ ಆಧಾರದ ಮೇಲೆ ಅವನು ತನ್ನ ಫೋನ್‌ನಲ್ಲಿ ಕಾಲೇಜುಗಳ ಪಟ್ಟಿಯನ್ನು ತೆರೆಯುತ್ತಾನೆ. ಕರ್ನಾಟಕದೊಳಗೆ, ಅವನು ಈ ಕೆಳಗಿನ ಕಾಲೇಜುಗಳನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾನೆ.",
            "te": "నవీన్ ఈ కోర్సుకు దరఖాస్తు చేయాలని నిర్ణయించుకున్నాడు. రాష్ట్రాల వారీ రేటింగ్‌ల ఆధారంగా అతను తన ఫోన్‌లో కాలేజీల జాబితాను తెరుస్తాడు. కర్ణాటకలో, అతను క్రింది కళాశాలలను కనుగొన్నాడు.",
            "or": "ଏହି ପାଠ୍ୟକ୍ରମ ପାଇଁ ନବୀନ ଆବେଦନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି। ରାଜ୍ୟ ଜ୍ଞାନୀ ମୂଲ୍ୟାୟନ ଉପରେ ଆଧାର କରି ସେ ନିଜ ଫୋନରେ କଲେଜଗୁଡ଼ିକର ଏକ ତାଲିକା ଖୋଲନ୍ତି। ସେ କର୍ଣ୍ଣାଟକ ମଧ୍ୟରେ ନିମ୍ନଲିଖିତ କଲେଜଗୁଡ଼ିକୁ ଦେଖିପାରିବେ |",
            "as": "নবীনে এই পাঠ্যক্ৰমৰ বাবে আবেদন কৰাৰ সিদ্ধান্ত লৈছে। তেওঁ ৰাজ্যঅনুসৰি ৰেটিঙৰ ওপৰত আধাৰ কৰি তেওঁৰ ফোনত মহাবিদ্যালয়ৰ এখন তালিকা খোলে। কৰ্ণাটকৰ ভিতৰত, তেওঁ নিম্নলিখিত মহাবিদ্যালয়বোৰ বিচাৰি পায়।",
            "gu": "નવીન નક્કી કરે છે આ કોર્સમાં અરજી કરવાનું. તે તેના ફોન પર કોલેજોની યાદી ખોલે છે રાજ્ય મુજબ રેટિંગના આધારે. નીચેની કોલેજો મળે છે જે કર્ણાટકની અંદર."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_i_3_2_apply",
            "hi": "B2_i_i_3_2_अप्लाई करना",
            "ka": "B2_i_i_3_2_ಅನ್ವಯಿಸಿ",
            "te": "B2_i_i_3_2_దరఖాస్తు",
            "or": "B2_i_i_3_2_apply",
            "as": "B2_i_i_3_2_apply",
            "gu": "B2_i_4_2_અરજી કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Help Naveen pick where all he can apply. He needs to apply to any 3.",
            "hi": "नवीन को कहाँ अप्लाई करना अच्छा रहेगा यह चुनने में मदद करें। उसे कोई भी 3 कॉलेज चुनने होंगे।",
            "ka": "ನವೀನ್ ಅವರು ಎಲ್ಲಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಬಹುದು ಎಂಬುದನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಸಹಾಯ ಮಾಡಿ. ಅವರು ಯಾವುದೇ 3 ಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಬೇಕಾಗಿದೆ.",
            "te": "నవీన్ ఎక్కడ దరఖాస్తు చేసుకోవచ్చో ఎంచుకునేందుకు సహాయం చేయండి. అతను ఏదైనా 3కి దరఖాస్తు చేసుకోవాలి.",
            "or": "ନବୀନଙ୍କୁ ସେ ଯେଉଁଠାରେ ଆବେଦନ କରିପାରିବେ ତାହା ଚୟନ କରିବାରେ ସାହାଯ୍ୟ କରନ୍ତୁ | ସେ ଯେକୌଣସି 3 ରେ ଆବେଦନ କରିବା ଆବଶ୍ୟକ କରନ୍ତି |",
            "as": "নবীনে ক'ত আবেদন কৰিব পাৰিব সেইটো বিচৰাৰ ক্ষেত্ৰত সহায় কৰা। তেওঁ যিকোনো 3 খনৰ বাবে আবেদন কৰিব লাগিব।",
            "gu": "નવીનને મદદ કરો ક્યાં તે અરજી કરી શકે છે. તેને 3 માં અરજી કરવાની છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Rating 4.6: RANB Aided College of Commerce and Management",
            "hi": "रेटिंग 4.6: आर.ए.एन.बी. एडेड कॉलेज ऑफ़ कॉमर्स एंड मैनेजमेंट",
            "ka": "ರೇಟಿಂಗ್ 4.6: ರಏಎಂಬಿ ಅನುದಾನಿತ ವಾಣಿಜ್ಯ ಮತ್ತು ನಿರ್ವಹಣೆ ಕಾಲೇಜು",
            "te": "రేటింగ్ 4.6: RANB ఎయిడెడ్ కాలేజ్ ఆఫ్ కామర్స్ అండ్ మేనేజ్‌మెంట్",
            "or": "ରେଟିଙ୍ଗ୍‌ 4.6: RANB Aided College of Commerce and Management",
            "as": "ৰেটিং 4.6: RANB এইডেদ কলেজ অফ কমাৰ্চ এণ্ড মেনেজমেণ্ট",
            "gu": "રેટિંગ 4.6: RANB એઇડ કોલેજ ઓફ કોમર્સ એન્ડ મેનેજમેન્ટ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Rating 4.2: Joseph College of Management",
            "hi": "रेटिंग 4.2: जोसेफ कॉलेज ऑफ़ मैनेजमेंट",
            "ka": "ರೇಟಿಂಗ್ 4.2: ಜೋಸೆಫ್ ಕಾಲೇಜ್ ಆಫ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್",
            "te": "రేటింగ్ 4.2: జోసెఫ్ కాలేజ్ ఆఫ్ మేనేజ్‌మెంట్",
            "or": "ରେଟିଙ୍ଗ୍‌ 4.2: Joseph College of Management",
            "as": "ৰেটিং 4.2: জোচেফ কলেজ অৱ মেনেজমেণ্ট",
            "gu": "રેટિંગ 4.2: જોસેફ કોલેજ ઓફ મેનેજમેન્ટ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Rating 4.0: Government college of commerce and Management",
            "hi": "रेटिंग 4.0: गवरमेंट कॉलेज ऑफ़ कॉमर्स एंड मैनेजमेंट",
            "ka": "ರೇಟಿಂಗ್ 4.0: ಸರ್ಕಾರಿ ವಾಣಿಜ್ಯ ಮತ್ತು ನಿರ್ವಹಣೆ ಕಾಲೇಜು",
            "te": "రేటింగ్ 4.0: గవర్న్మెంట్ కాలేజ్ ఆఫ్ కామర్స్ అండ్ మేనేజ్‌మెంట్",
            "or": "ରେଟିଙ୍ଗ୍‌ 4.0: Government college of commerce and Management",
            "as": "ৰেটিং 4.0: গভৰ্ণমেণ্ট কলেজ অফ কমাৰ্চ এণ্ড মেনেজমেণ্ট",
            "gu": "રેટિંગ 4.0: સરકારી કોલેજ ઓફ કોમર્સ એન્ડ મેનેજમેન્ટ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.display",
          "value": {
            "en": "Rating 3.2: SPJ College for Management",
            "hi": "रेटिंग 3.2: एस.पी.जे. कॉलेज ऑफ़ मैनेजमेंट",
            "ka": "ರೇಟಿಂಗ್ 3.2: ಎಸ್ ಪಿಜೆ ಕಾಲೇಜ್ ಫಾರ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್",
            "te": "రేటింగ్ 3.2: SPJ కాలేజ్ ఫర్ మేనేజ్‌మెంట్",
            "or": "ରେଟିଙ୍ଗ୍‌ 3.2: SPJ College for Management",
            "as": "ৰেটিং 3.2: SPJ কলেজ ফৰ মেনেজমেণ্ট",
            "gu": "રેટિંગ 3.2: SPJ કોલેજ ફોર મેનેજમેન્ટ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.display",
          "value": {
            "en": "Rating 3.0: MCC College for Arts, Commerce and Management",
            "hi": "रेटिंग 3.0: एम.सी.सी. कॉलेज ऑफ़ आर्ट्स, कॉमर्स एंड मैनेजमेंट",
            "ka": "ರೇಟಿಂಗ್ 3.0: ಎಂಸಿಸಿ ಕಾಲೇಜ್ ಫಾರ್ ಆರ್ಟ್ಸ್, ಕಾಮರ್ಸ್ ಮತ್ತು ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್",
            "te": "రేటింగ్ 3.0: MCC కాలేజ్ ఫర్ ఆర్ట్స్, కామర్స్ అండ్ మేనేజ్‌మెంట్",
            "or": "ରେଟିଙ୍ଗ୍‌ 3.0: MCC College for Arts, Commerce and Management",
            "as": "ৰেটিং 3.0: MCC কলেজ ফৰ আৰ্টচ, কমাৰ্চ এণ্ড মেনেজমেণ্ট",
            "gu": "રેટિંગ 3.0: આર્ટસ, કોમર્સ અને મેનેજમેન્ટ માટે MCC કોલેજ"
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_i_i_3_3",
            "hi": "B2_i_i_3_3",
            "ka": "B2_i_i_3_3",
            "te": "B2_i_i_3_3",
            "or": "B2_i_i_3_3",
            "as": "B2_i_i_3_3",
            "gu": "B2_i_i_3_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Wohoooo! Naveen gets admission to his top priority college for a course in BBA.",
            "hi": "वा! नवीन को BBA के कोर्स के लिए उसके सबसे पसंदीदा कॉलेज में एडमिशन मिल गया है।",
            "ka": "ವೂಹೂ! ನವೀನ್ ಬಿಬಿಎ ಕೋರ್ಸ್‌ಗೆ ತನ್ನ ಪ್ರಮುಖ ಆದ್ಯತೆಯ ಕಾಲೇಜಿಗೆ ಪ್ರವೇಶ ಪಡೆಯುತ್ತಾನೆ.",
            "te": "అయ్యో! నవీన్ BBAలో కోర్సు కోసం తన అత్యంత ప్రాధాన్యత గల కళాశాలలో ప్రవేశం పొందాడు.",
            "or": "ୱୁହୁ ! BBA ରେ ପାଠ୍ୟକ୍ରମ ପାଇଁ ନବୀନ ତାଙ୍କର ସର୍ବୋଚ୍ଚ ପ୍ରାଥମିକ କଲେଜରେ ଆଡମିଶନ ପାଆନ୍ତି |",
            "as": "ৱাও! নবীনে BBAৰ পাঠ্যক্ৰমৰ বাবে তেওঁৰ শীৰ্ষ অগ্ৰাধিকাৰপ্ৰাপ্ত মহাবিদ্যালয়খনত নামভৰ্তি কৰে।",
            "gu": "જોરદાર! નવીનને ટોચની પ્રાથમિકતાવાળી કોલેજમાં પ્રવેશ મળે છે જેમાં BBAના કોર્સ થાય છે."
          }
        }
      ]
    },
    "scene6": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_1_1",
            "hi": "B3_1_1",
            "ka": "B3_1_1",
            "te": "B3_1_1",
            "or": "B3_1_1",
            "as": "B3_1_1",
            "gu": "B3_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen is studying Business Administration. He is quite excited to be learning about marketing management and business economics.",
            "hi": "नवीन बिजनेस एडमिनिस्ट्रेशन पढ़ रहा है। वह मार्केटिंग मैनेजमेंट और बिजनेस इकोनॉमिक्स के बारे में पढ़ने के लिए काफी उत्साहित है।",
            "ka": "ನವೀನ್ ಬಿಸಿನೆಸ್ ಅಡ್ಮಿನಿಸ್ಟ್ರೇಷನ್ ಓದುತ್ತಿದ್ದಾನೆ. ಅವನು ಮಾರ್ಕೆಟಿಂಗ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್ ಮತ್ತು ವ್ಯಾಪಾರ ಅರ್ಥಶಾಸ್ತ್ರದ ಬಗ್ಗೆ ಕಲಿಯಲು ಉತ್ಸುಕರಾಗಿದ್ದಾನೆ.",
            "te": "నవీన్ బిజినెస్ అడ్మినిస్ట్రేషన్ చదువుతున్నాడు. అతను మార్కెటింగ్ మేనేజ్‌మెంట్ మరియు బిజినెస్ ఎకనామిక్స్ గురించి తెలుసుకోవడానికి చాలా ఉత్సాహంగా ఉన్నాడు.",
            "or": "ନବୀନ ବିଜନେସ୍‌ ଆଡମିନିଷ୍ଟ୍ରେସନ୍‌ ଅଧ୍ୟୟନ କରୁଛନ୍ତି | ମାର୍କେଟିଂ ମ୍ୟାନେଜମେଣ୍ଟ ଏବଂ ବିଜନେସ୍‌ ଇକୋନୋମିକ୍ସ ବିଷୟରେ ଜାଣିବାକୁ ସେ ବହୁତ ଉତ୍ସାହିତ ଅଛନ୍ତି |",
            "as": "নবীনে ব্যৱসায় প্ৰশাসন অধ্যয়ন কৰি আছে। তেওঁ বিপণন ব্যৱস্থাপনা আৰু ব্যৱসায়িক অৰ্থনীতিৰ বিষয়ে শিকিবলৈ যথেষ্ট উৎসাহিত।",
            "gu": "નવીન બિઝનેસ એડમિનિસ્ટ્રેશનનો કોર્સ કરે છે. તે માર્કેટિંગ મેનેજમેન્ટ અને બિઝનેસ ઈકોનોમિક્સ વિશે ભણવા માટે ખૂબ જ ઉત્સાહિત છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_2_1",
            "hi": "B3_2_1",
            "ka": "B3_2_1",
            "te": "B3_2_1",
            "or": "B3_2_1",
            "as": "B3_2_1",
            "gu": "B3_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen really likes college and participates in extracurricular activities, especially dance and sports. He is part of the college dance club.",
            "hi": "नवीन को कॉलेज बहुत पसंद आता है और पढ़ाई के अलावा अन्य उपक्रमों में भी सहभागी होता है, विशेष रूप से, डान्स और खेल में। वह कॉलेज के डान्स ग्रुप का हिस्सा है।",
            "ka": "ನವೀನ್ ನಿಜವಾಗಿಯೂ ಕಾಲೇಜು ಇಷ್ಟಪಡುತ್ತಾನೆ ಮತ್ತು ಪಠ್ಯೇತರ ಚಟುವಟಿಕೆಗಳಲ್ಲಿ ಭಾಗವಹಿಸುತ್ತಾನೆ, ವಿಶೇಷವಾಗಿ ನೃತ್ಯ ಮತ್ತು ಕ್ರೀಡೆಗಳಲ್ಲಿ. ಅವನು ಕಾಲೇಜು ಡ್ಯಾನ್ಸ್ ಕ್ಲಬ್‌ನ ಭಾಗವಾಗಿದ್ದಾನೆ.",
            "te": "నవీన్‌కు కళాశాల అంటే చాలా ఇష్టం మరియు ఇతరేతర కార్యకలాపాల లో ముఖ్యంగా నృత్యం మరియు క్రీడలు. అతను కాలేజ్ డ్యాన్స్ క్లబ్‌లో సభ్యుడు.",
            "or": "ନବୀନ ପ୍ରକୃତରେ କଲେଜ ପସନ୍ଦ କରନ୍ତି ଏବଂ ପାଠ୍ଯକ୍ରମ ବାହାରେ ଥିବା କାର୍ଯ୍ୟକଳାପରେ ଅଂଶଗ୍ରହଣ କରନ୍ତି, ବିଶେଷକରି ନୃତ୍ୟ ଏବଂ କ୍ରୀଡା | ସେ କଲେଜ ଡ୍ୟାନ୍ସ କ୍ଲବର ଅଂଶ ଅଟନ୍ତି |",
            "as": "নবীনে সঁচাকৈয়ে মহাবিদ্যালয় ভাল পাইছে আৰু পাঠ্যক্ৰমৰ বাহিৰৰ কাৰ্যকলাপত বিশেষকৈ নৃত্য আৰু ক্ৰীড়াত অংশগ্ৰহণ কৰিছে। তেওঁ মহাবিদ্যালয়ৰ নৃত্য সংঘৰ অংশ হৈছে।",
            "gu": "નવીનને તે કોલેજ ખરેખર ગમે છે અને ભણવાની પ્રવૃત્તિઓમાં ભાગ લે છે, ખાસ કરીને નૃત્ય અને રમતગમત. તે કોલેજમાં ડાન્સમાં ભાગ ક્લબનો ભાગ છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_2_2",
            "hi": "B3_2_2",
            "ka": "B3_2_2",
            "te": "B3_2_2",
            "or": "B3_2_2",
            "as": "B3_2_2",
            "gu": "B3_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He helps in organising an inter-departmental dance competition and gets positive feedback on his organising skills. He gets so busy with it that he misses a few weeks of classes.",
            "hi": "वह इंटर-डिपार्टमेंटल डान्स स्पर्धा आयोजित करने में मदद करता है और उसके आयोजन करने के कौशल की सब लोग तारीफ करते हैं। वह इस काम इतना व्यस्त होता है कि कुछ हफ्ते अपने क्लास में जा नहीं पाता।",
            "ka": "ಅವನು ಅಂತರ-ಇಲಾಖೆಯ ನೃತ್ಯ ಸ್ಪರ್ಧೆಯನ್ನು ಆಯೋಜಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತಾನೆ ಮತ್ತು ಅವನ ಸಂಘಟನಾ ಕೌಶಲ್ಯಗಳ ಬಗ್ಗೆ ಸಕಾರಾತ್ಮಕ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಪಡೆಯುತ್ತಾನೆ. ಅವನು ಅದರಲ್ಲಿ ಎಷ್ಟು ನಿರತನಾಗುತ್ತಾರೆಂದರೆ ಅವನು ಕೆಲವು ವಾರಗಳ ತರಗತಿಗಳನ್ನು ಕಳೆದುಕೊಳ್ಳುತ್ತಾನೆ.",
            "te": "అతను అంతర్-విభాగ నృత్య పోటీని నిర్వహించడంలో సహాయం చేస్తాడు మరియు అతని ఆర్గనైజింగ్ నైపుణ్యాలపై సానుకూల అభిప్రాయాన్ని పొందుతాడు. అతను దానితో చాలా బిజీగా ఉంటాడు, అతను కొన్ని వారాల తరగతులను మిస్ అవుతాడు.",
            "or": "ସେ ଏକ ଆନ୍ତଃବିଭାଗୀୟ ନୃତ୍ୟ ପ୍ରତିଯୋଗିତା ଆୟୋଜନ କରିବାରେ ସାହାଯ୍ୟ କରନ୍ତି ଏବଂ ତାଙ୍କର ଆୟୋଜନ କୌଶଳ ଉପରେ ସକରାତ୍ମକ ମତାମତ ପାଆନ୍ତି | ସେ ଏହା ସହିତ ଏତେ ବ୍ୟସ୍ତ ହୁଅନ୍ତି ଯେ ସେ କିଛି ସପ୍ତାହ କ୍ଲାସରୁ ବଞ୍ଚିତ ହୁଅନ୍ତି |",
            "as": "তেওঁ এক আন্তঃ-বিভাগীয় নৃত্য প্ৰতিযোগিতা আয়োজন কৰাত সহায় কৰে আৰু তেওঁৰ সংগঠিত দক্ষতাৰ ওপৰত ইতিবাচক প্ৰতিক্ৰিয়া লাভ কৰে। তেওঁ ইয়াত ইমান ব্যস্ত হৈ পৰে যে তেওঁ কেইসপ্তাহমানৰ শ্ৰেণী ক্ষতি কৰে।",
            "gu": "તેણી આંતર-એજન્સી નૃત્ય સ્પર્ધાઓનું આયોજન કરવામાં મદદ કરે છે અને તેની સંસ્થાકીય કુશળતા પર હકારાત્મક પ્રતિસાદ મેળવે છે. તે આ કરવામાં એટલો વ્યસ્ત છે કે તે અઠવાડિયા સુધી તેના પાઠ ચૂકી જાય છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_2_3",
            "hi": "B3_2_3",
            "ka": "B3_2_3",
            "te": "B3_2_3",
            "or": "B3_2_3",
            "as": "B3_2_3",
            "gu": "B3_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen gets a warning that if he misses more classes, he will not be eligible for writing his semester exams.",
            "hi": "नवीन को चेतावनी दी जाती है कि अगर वह और ज्यादा क्लास में अनुपस्थित होता है तो उसे सेमिस्टर की परीक्षा देने का अवसर नहीं दिया जाएगा।",
            "ka": "ನವೀನ್ ಹೆಚ್ಚಿನ ತರಗತಿಗಳನ್ನು ತಪ್ಪಿಸಿದನೆ, ಅವನು ತನ್ನ ಸೆಮಿಸ್ಟರ್ ಪರೀಕ್ಷೆಗಳನ್ನು ಬರೆಯಲು ಅರ್ಹನಾಗುವುದಿಲ್ಲ ಎಂಬ ಎಚ್ಚರಿಕೆಯನ್ನು ಪಡೆಯುತ್ತಾನೆ.",
            "te": "నవీన్‌ ఇంకా తరగతులు మిస్ అయితే సెమిస్టర్ పరీక్షలు రాసే అర్హత ఉండదని వార్నింగ్ వచ్చింది.",
            "or": "ନବୀନ ଏକ ଚେତାବନୀ ପାଆନ୍ତି ଯେ ଯଦି ସେ ଶ୍ରେଣୀରୁ ଅଧିକ ବଞ୍ଚିତ ହୁଅନ୍ତି, ତେବେ ସେ ସେମିଷ୍ଟର୍‌ ପରୀକ୍ଷା ଲେଖିବା ପାଇଁ ଯୋଗ୍ୟ ହେବେ ନାହିଁ |",
            "as": "নবীনে সতৰ্কবাণী পালে যে যদি তেওঁ আৰু অধিক শ্ৰেণী ক্ষতি কৰে, তেন্তে তেওঁক ছেমিষ্টাৰ পৰীক্ষাত বহিবলৈ দিয়া নহ'ব।",
            "gu": "નવીનને ચેતવણી મળે છે કે જો તે પૂરા વર્ગ નહીં ભરે, તો તે તેની સેમેસ્ટરની  પરીક્ષાઓ આપવા નહીં મળે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_3_1//",
            "hi": "B3_3_1//",
            "ka": "B3_3_1//",
            "te": "B3_3_1//",
            "or": "B3_3_1//",
            "as": "B3_3_1//",
            "gu": "B3_3_1//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Naveen decides to go meet Omi and Zaid to clear his mind. They have both been very supportive of him getting back into dance.",
            "hi": "इस बात पर निर्णय लेने से पहले, नवीन ओमी और जाएद से मिलने का तय करता है। वे दोनों भी उसे फिर से डान्स करने के लिए सपोर्ट किया करते थे।",
            "ka": "ನವೀನ್ ತನ್ನ ಮನಸ್ಸನ್ನು ತೆರವುಗೊಳಿಸಲು ಓಮಿ ಮತ್ತು ಝೈದ್ನನ್ನು ಭೇಟಿಯಾಗಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಅವನು ಮತ್ತೆ ನೃತ್ಯಕ್ಕೆ ಬರಲು ಅವರಿಬ್ಬರೂ ತುಂಬಾ ಬೆಂಬಲ ನೀಡಿದ್ದಾರೆ.",
            "te": "నవీన్ తన బాధను చెప్పుకోడానికి ఓమి మరియు జైద్‌లను కలవాలని నిర్ణయించుకుంటాడు. అతను తిరిగి డ్యాన్స్‌లోకి రావడానికి వారిద్దరూ చాలా మద్ధతు ఇచ్చారు.",
            "or": "ତାଙ୍କ ମନକୁ ସଫା କରିବା ପାଇଁ ନବୀନ ଓମି ଏବଂ ଜାଏଦ୍‌ଙ୍କୁ ଭେଟିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି | ତାଙ୍କୁ ନୃତ୍ୟକୁ ଫେରିବା ପାଇଁ ଉଭୟେ ତାଙ୍କୁ ବହୁତ ସମର୍ଥନ କରିଛନ୍ତି |",
            "as": "নবীনে তেওঁৰ মনটো স্পষ্ট হ'বলৈ অমি আৰু জাইদক লগ ধৰাৰ সিদ্ধান্ত লয়।",
            "gu": "ઓમી અને ઝાઈદને મળીને નવીન તેનું મન હળવું કરે છે. તે બંને મદદગાર હતા નવીનને નૃત્યમાં પાછા લાવવા."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Omi",
            "hi": "ओमी",
            "ka": "ಓಮಿ",
            "te": "ఓమి",
            "or": "ଓମି",
            "as": "অমি",
            "gu": "ઓમી"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Zaid",
            "hi": "जाएद",
            "ka": "ಝೈದ್",
            "te": "జైద్",
            "or": "ଜାଏଦ୍‌",
            "as": "জাইদ",
            "gu": "ઝાઈદ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "It’s so good to see you perform again, Naveen. We thought you had totally quit.",
            "hi": "तुम्हें फिर से नाचते हुए देखकर हमें बहुत खुशी हुई हैं, नवीन। हमें तो लगा था तुमने सब कुछ छोड़ दिया है।",
            "ka": "ನವೀನ್, ನೀವು ಮತ್ತೊಮ್ಮೆ ಪ್ರದರ್ಶನ ನೀಡುವುದನ್ನು ನೋಡಲು ತುಂಬಾ ಸಂತೋಷವಾಗಿದೆ. ನೀವು ಸಂಪೂರ್ಣವಾಗಿ ತ್ಯಜಿಸಿದ್ದೀರಿ ಎಂದು ನಾವು ಭಾವಿಸಿದ್ದೇವೆ.",
            "te": "నవీన్, నువ్వు మళ్లీ పాల్గొనడం చాలా బాగుంది. నువ్వు మొత్తానికే వెళ్లిపోయావని మేము అనుకున్నాము.",
            "or": "ଆପଣ ପୁନର୍ବାର ଅଭିନୟ କରିବା ଅତ୍ୟନ୍ତ ଭଲ, ନବୀନ | ଆମେ ଭାବୁଥିଲୁ ତୁମେ ସଂପୂର୍ଣ୍ଣ ଛାଡିଛ |",
            "as": "নবীন, তুমি পুনৰ প্ৰদৰ্শন কৰা দেখি ভাল লাগিছে ৷ আমি ভাবিছিলো তুমি সকলোবোৰ এৰি দিছিলা ৷",
            "gu": "નવીન, તારું આટલું સરસ દેખાવ જોઈને મજા આવી. અમને લાગ્યું હતું કે સંપૂર્ણ રીતે છોડી દીધું હશે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Reminds me of our school days again. We have all come so far from then!",
            "hi": "मुझे तो अपने स्कूल के दिन याद आ गए। तब से लेकर, अब तक हमने साथ मिलकर कितना कुछ किया है!",
            "ka": "ಮತ್ತೆ ನಮ್ಮ ಶಾಲಾ ದಿನಗಳನ್ನು ನೆನಪಿಸಿತು. ಅಂದಿನಿಂದ ನಾವೆಲ್ಲರೂ ಇಲ್ಲಿಯವರೆಗೆ ಬಂದಿದ್ದೇವೆ!",
            "te": "మళ్ళీ మా పాఠశాల రోజులు గుర్తొచ్చాయి. మనమంతా అప్పటి నుండి చాలా దూరం వచ్చాము!",
            "or": "ମୋତେ ପୁଣିଥରେ ଆମ ସ୍କୁଲ ଦିନ ବିଷୟରେ ମନେ ପକାଇଥାଏ | ସେବେଠାରୁ ଆମେ ସମସ୍ତେ ଏତେ ଦୂର ପର୍ଯ୍ୟନ୍ତ ଆସିଛୁ !",
            "as": "মোক আকৌ আমাৰ স্কুলৰ দিনবোৰৰ কথা মনত পেলাই দিছে। আমি সকলোৱে তেতিয়াৰ পৰা ইমান দূৰলৈকে আহিছোঁ!",
            "gu": "મને આપણી શાળાના દિવસો યાદ અપાવે છે ફરીથી. આપણે બધા ત્યારથી અત્યાર સુધી આવી રહ્યા છીએ!"
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_3_2////",
            "hi": "B3_3_2////",
            "ka": "B3_3_2////",
            "te": "B3_3_2////",
            "or": "B3_3_2////",
            "as": "B3_3_2////",
            "gu": "B3_3_2////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Guys, I really want to continue dancing, but it seems impossible to give my 100% to it along with getting through college. Especially now with the semester exams.",
            "hi": "दोस्तों, मुझे सच में डान्स करना जारी रखना है, लेकिन मुझे नहीं लगता कि कॉलेज पूरा करते हुए मैं अपना पूरा ध्यान इसमें नहीं दे सकता। खासकर अब, जब सेमिस्टर परीक्षा इतनी नजदीक आ गई है।",
            "ka": "ಹುಡುಗರೇ, ನಾನು ನಿಜವಾಗಿಯೂ ನೃತ್ಯವನ್ನು ಮುಂದುವರಿಸಲು ಬಯಸುತ್ತೇನೆ, ಆದರೆ ಕಾಲೇಜಿನಲ್ಲಿ ವ್ಯಾಸಂಗ ಮಾಡುವುದರೊಂದಿಗೆ ನನ್ನ 100% ಅನ್ನು ಅದಕ್ಕೆ ನೀಡುವುದು ಅಸಾಧ್ಯವೆಂದು ತೋರುತ್ತದೆ. ಅದರಲ್ಲೂ ಈಗ ಸೆಮಿಸ್ಟರ್ ಪರೀಕ್ಷೆಗಳು.",
            "te": "అబ్బాయిలు, నేను నిజంగా డ్యాన్స్‌ని కొనసాగించాలనుకుంటున్నాను, కానీ కళాశాలలో చేరడంతో పాటు నా 100% దానికి ఇవ్వడం అసాధ్యం. ముఖ్యంగా ఇప్పుడు సెమిస్టర్ పరీక్షలతో.",
            "or": "ସାଥୀଗଣ, ମୁଁ ପ୍ରକୃତରେ ନାଚିବା ଜାରି ରଖିବାକୁ ଚାହେଁ, କିନ୍ତୁ କଲେଜ ପଢିବା ସହିତ ଏହାକୁ ମୋର 100% ଦେବା ଅସମ୍ଭବ ମନେହୁଏ | ବିଶେଷକରି ବର୍ତ୍ତମାନ ସେମିଷ୍ଟର୍‌ ପରୀକ୍ଷା ସହିତ |",
            "as": "বন্ধুসকল, মই সঁচাকৈয়ে নৃত্য অব্যাহত ৰাখিব বিচাৰো, কিন্তু কলেজৰ লগতে এশ শতাংশ ইয়াক দিয়াটো মোৰ অসম্ভৱ যেন লাগিছে। বিশেষকৈ এতিয়াৰ ছেমিষ্টাৰ পৰীক্ষাৰ সৈতে।",
            "gu": "મિત્રો, ખરેખર મારે ડાંસમાં પાછા ફરવું છે પણ મારી કોલેજ હોવાથી મને 100% કઠિન લાગે છે. ખાસ કરીને સેમિસ્ટરની પરીક્ષા લીધે "
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "You always get stuck in this situation, da. I’m telling you, just focus on your passion. No one would care if you have a degree or not when they see you perform.",
            "hi": "तुम हमेशा इस मुसीबत में उलझे हुए रहते हो, दा। मैं तो कहता हूँ कि तुम्हें सिर्फ अपने जूनून पर ध्यान देना चाहिए। जब लोग तुम्हें डान्स करते हुए देखेंगे तब कोई इसबात कि परवाह नहीं करेगा कि तुम्हारे पास डिग्री है या नहीं।",
            "ka": "ನೀವು ಯಾವಾಗಲೂ ಈ ಪರಿಸ್ಥಿತಿಯಲ್ಲಿ ಸಿಲುಕಿಕೊಳ್ಳುತ್ತೀರಿ. ನಾನು ನಿಮಗೆ ಹೇಳುತ್ತಿದ್ದೇನೆ, ನಿಮ್ಮ ಉತ್ಸಾಹದ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಿ. ನಿಮ್ಮ ಸಾಧನೆಯನ್ನು ನೋಡಿದಾಗ ನೀವು ಪದವಿ ಹೊಂದಿದ್ದೀರಾ ಅಥವಾ ಇಲ್ಲವೇ ಎಂದು ಯಾರೂ ಚಿಂತಿಸುವುದಿಲ್ಲ.",
            "te": "మీరు ఎల్లప్పుడూ ఈ పరిస్థితిలో చిక్కుకుంటారు, డా. నేను మీకు చెబుతున్నాను, కేవలం మీ అభిరుచిపై దృష్టి పెట్టండి. మీ ప్రదర్శనను చూసినప్పుడు మీకు డిగ్రీ ఉందా లేదా అని ఎవరూ పట్టించుకోరు.",
            "or": "ତୁମେ ସବୁବେଳେ ଏହି ପରିସ୍ଥିତିରେ ଅଟକି ଯାଅ, ଦା । ମୁଁ ତୁମକୁ କହୁଛି, କେବଳ ତୁମର ଉତ୍ସାହ ଉପରେ ଧ୍ୟାନ ଦିଅ | ତୁମର ପ୍ରଦର୍ଶନ ଦେଖିବାବେଳେ ତୁମର ଡିଗ୍ରୀ ଅଛି କି ନାହିଁ କେହି ଧ୍ୟାନ ଦେବେ ନାହିଁ |",
            "as": "তুমি সদায় এনেধৰণৰ পৰিস্থিতিত আবদ্ধ হৈ যোৱা, দাদা। মই কৈছো, কেৱল তোমাৰ আবেগৰ ওপৰত গুৰুত্ব দিয়া। যেতিয়া তেওঁলোকে তোমাৰ প্ৰদৰ্শন দেখিব তেতিয়া তোমাৰ ডিগ্ৰী আছে নে নাই কোনেও গুৰুত্ব নিদিব ।",
            "gu": "તું કાયમ આ સ્થિતિમાં ભરાઈ જાય છે, ભાઈ. હું તને જાણવું છું, કે ફક્ત તારા કામ પર ધ્યાન કેન્દ્રિત કર. જ્યારે તેને કોઈ પ્રદર્શન કરતા જોશે ત્યારે તારી પાસે ડિગ્રી છે કે નહીં તે કોઈ નહીં જોવે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Omi, this degree means a lot to my family. I'm the first person in my family to ever get into such a professional course. They have a lot of hopes from me.",
            "hi": "तुम हमेशा इस मुसीबत में उलझे हुए रहते हो, दा। मैं तो कहता हूँ कि तुम्हें सिर्फ अपने जूनून पर ध्यान देना चाहिए। जब लोग तुम्हें डान्स करते हुए देखेंगे तब कोई इसबात कि परवाह नहीं करेगा कि तुम्हारे पास डिग्री है या नहीं।",
            "ka": "ಓಮಿ, ಈ ಪದವಿ ನನ್ನ ಕುಟುಂಬಕ್ಕೆ ಬಹಳಷ್ಟು ಅರ್ಥವಾಗಿದೆ. ನನ್ನ ಕುಟುಂಬದಲ್ಲಿ ಇಂತಹ ವೃತ್ತಿಪರ ಕೋರ್ಸ್‌ಗೆ ಪ್ರವೇಶಿಸಿದ ಮೊದಲ ವ್ಯಕ್ತಿ ನಾನು. ಅವರು ನನ್ನ ಮೇಲೆ ಸಾಕಷ್ಟು ಭರವಸೆ ಇಟ್ಟುಕೊಂಡಿದ್ದಾರೆ.",
            "te": "ఓమీ, ఈ డిగ్రీ అంటే నా ఫ్యామిలీకి చాలా ఇష్టం. మా ఫ్యామిలీలో ఇంత ప్రొఫెషనల్ కోర్సులో చేరిన మొదటి వ్యక్తిని నేనే. వాళ్లు నాపై ఎన్నో ఆశలు పెట్టుకున్నారు.",
            "or": "ଓମି, ଏହି ଡିଗ୍ରୀ ମୋ ପରିବାର ପାଇଁ ବହୁତ କିଛି ଅର୍ଥ କରେ | ମୁଁ ମୋ ପରିବାରର ପ୍ରଥମ ବ୍ୟକ୍ତି ଯିଏ କି ଏପରି ବୃତ୍ତିଗତ ପାଠ୍ୟକ୍ରମରେ ପ୍ରବେଶ କରିଛି | ମୋ ଠାରୁ ସେମାନଙ୍କର ବହୁତ ଆଶା ଅଛି |",
            "as": "অমি, এই ডিগ্ৰীটো মোৰ পৰিয়ালৰ বাবে যথেষ্ট দৰকাৰী। মই মোৰ পৰিয়ালৰ প্ৰথম ব্যক্তি যিয়ে এনে ধৰণৰ পেছাদাৰী পাঠ্যক্ৰম পঢ়িব লৈছো। তেওঁলোকৰ মোৰ পৰা বহুত আশা আছে।",
            "gu": "ઓમી, આ ડિગ્રી મારા પરિવાર માટે ખૂબ મહત્વ છે. હું મારા કુટુંબનો પહેલો માણસ છું જેણે આવા વ્યાવસાયિક અભ્યાસક્રમમાં પ્રવેશ મેળવ્યો છે. તે લોકોને મારી પાસેથી ઘણી આશાઓ રાખીને બેઠા છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "I understand Naveen, it’s a tough call. You know we will always be here to welcome you back into the group. Just do what makes you feel good.",
            "hi": "मैं समझ सकता हूँ, नवीन, यह निर्णय बहुत मुश्किल है। तुम्हें पता हैं ना तुम जब चाहो हमारे ग्रुप में वापस आ सकते हो। बस तुम्हें जो अच्छा लगता है वहीँ करो।",
            "ka": "ನವೀನ್ ನನಗೆ ಅರ್ಥವಾಗಿದೆ, ಇದು ಕಠಿಣ ಕರೆ. ನಿಮ್ಮನ್ನು ಮರಳಿ ಗುಂಪಿಗೆ ಸ್ವಾಗತಿಸಲು ನಾವು ಯಾವಾಗಲೂ ಇಲ್ಲಿರುತ್ತೇವೆ ಎಂದು ನಿಮಗೆ ತಿಳಿದಿದೆ. ನಿಮಗೆ ಒಳ್ಳೆಯದನ್ನು ಮಾಡುವುದನ್ನು ಮಾತ್ರ ಮಾಡಿ.",
            "te": "నేను నవీన్‌ను అర్థం చేసుకున్నాను, ఇది కఠినమైన పిలుపు. మిమ్మల్ని తిరిగి గ్రూప్‌లోకి స్వాగతించడానికి మేము ఎల్లప్పుడూ ఇక్కడే ఉంటామని మీకు తెలుసు. మీకు మంచి అనుభూతిని కలిగించే వాటిని చేయండి.",
            "or": "ମୁଁ ବୁଝିପାରୁଛି ନବୀନ, ଏହା ଏକ କଠିନ କାମ | ତୁମେ ଜାଣିଛ ଯେ ତୁମକୁ ସବୁବେଳେ ଗ୍ରୁପରେ ସ୍ୱାଗତ କରିବା ପାଇଁ ଆମେ ସର୍ବଦା ଏଠାରେ ରହିବୁ | ଯାହା ତୁମକୁ ଭଲ ଲାଗେ ତାହା କର |",
            "as": "মই বুজিছো নবীন, এইটো সহজ নহয় ৷ তুমি জানি থবা যে তোমাক গোটটোলৈ স্বাগতম জনাবলৈ আমি সদায় আছোঁ। কেৱল সেইটোৱে কৰা যিটো তোমাৰ ভাল লাগে।",
            "gu": "હું સમજું છું નવીન છું, તે કઠિન લાગે છે. તું જાણે છે કે અમે હંમેશા તારું આવકાર આ જૂથમાં અહીં હાજર હોય છે. ફક્ત તે કર જે તને યોગ્ય લાગે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_3_3",
            "hi": "B3_3_3",
            "ka": "B3_3_3",
            "te": "B3_3_3",
            "or": "B3_3_3",
            "as": "B3_3_3",
            "gu": "B3_3_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen feels that if he goes back to his group and works hard, he can make a career in dance. However, leaving college midway might not be a wise decision for his career.",
            "hi": "नवीन को लगता है कि अगर वह फिर से उसके ग्रुप में शामिल हो जाएगा और बहुत मेहनत करेगा तो वह डान्स में करियर बना सकता है। लेकिन, कॉलेज को बीच में ही छोड़ देना करियर के हिसाब से सही निर्णय नहीं होगा।",
            "ka": "ಮತ್ತೆ ತನ್ನ ಗುಂಪಿಗೆ ಹೋಗಿ ಕಷ್ಟಪಟ್ಟು ದುಡಿದರೆ ನೃತ್ಯವನ್ನೇ ವೃತ್ತಿಯನ್ನಾಗಿಸಬಹುದು ಎಂಬುದು ನವೀನ್ ಅನಿಸಿಕೆ. ಆದಾಗ್ಯೂ, ಕಾಲೇಜು ಮಧ್ಯದಲ್ಲಿ ಬಿಡುವುದು ಅವನ ವೃತ್ತಿಜೀವನದ ಬುದ್ಧಿವಂತ ನಿರ್ಧಾರವಲ್ಲ.",
            "te": "మళ్లీ తన బృందంలోకి వెళ్లి కష్టపడితే డ్యాన్స్‌లో కెరీర్‌ను సంపాదించుకోవచ్చని నవీన్‌ భావిస్తున్నాడు. అయితే, కళాశాలని మధ్యలోనే వదిలేయడం అతని కెరీర్‌కు తెలివైన నిర్ణయం కాకపోవచ్చు.",
            "or": "ନବୀନ ଅନୁଭବ କରନ୍ତି ଯେ ଯଦି ସେ ନିଜ ଦଳକୁ ଫେରି କଠିନ ପରିଶ୍ରମ କରନ୍ତି, ତେବେ ସେ ନୃତ୍ୟରେ ଏକ କ୍ୟାରିୟର୍‌ କରିପାରିବେ | । କିନ୍ତୁ, ମଧ୍ୟଭାଗରେ କଲେଜ ଛାଡିବା ତାଙ୍କ କ୍ୟାରିୟର୍‌ ପାଇଁ ଏକ ବୁଦ୍ଧିମାନ ନିଷ୍ପତ୍ତି ହୋଇନପାରେ |",
            "as": "নবীনে অনুভৱ কৰিলে যে যদি তেওঁ তেওঁৰ গোটলৈ ঘূৰি যায় আৰু কঠোৰ পৰিশ্ৰম কৰে, তেন্তে তেওঁ নৃত্যৰ জৰিয়তে জীৱিকা গঢ়িব পাৰিব। অৱশ্যে, মাজতে কলেজ এৰি দিয়াটো তেওঁৰ জীৱিকাৰ বাবে ভাল সিদ্ধান্ত নহ'ব পাৰে।",
            "gu": "નવીનને એવું લાગે છે કે જો તે સખત મહેનત કરશે તો તે ડાન્સમાં કારકિર્દી બનાવી શકશે અને તે જૂથમાં પાછો જાઈ શકે છે અને. પણ, કોલેજને વચ્ચેથી છોડી દેવી એ તેની કારકિર્દી માટે સારો નિર્ણય ન કહેવાય."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_3_4_D",
            "hi": "B3_3_4_D",
            "ka": "B3_3_4_D",
            "te": "B3_3_4_D",
            "or": "B3_3_4_D",
            "as": "B3_3_4_D",
            "gu": "B3_3_4_D"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Naveen do?",
            "hi": "नवीन को क्या करना चाहिए?",
            "ka": "ನವೀನ್ ಏನು ಮಾಡಬೇಕು?",
            "te": "నవీన్ ఏం చేయాలి?",
            "or": "ନବୀନ କଣ କରିବା ଉଚିତ୍ ?",
            "as": "নবীনে কি কৰা উচিত?",
            "gu": "શું કરવું જોઈએ નવીને?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Leave college and focus on dance.",
            "hi": "कॉलेज छोड़कर डान्स पर ध्यान देना चाहिए।",
            "ka": "ಕಾಲೇಜು ಬಿಟ್ಟು ನೃತ್ಯದತ್ತ ಗಮನ ಹರಿಸಿ.",
            "te": "కళాశాల వదిలి డ్యాన్స్‌పై ఫోకస్ చేయాలా.",
            "or": "କଲେଜ ଛାଡି ନୃତ୍ୟ ଉପରେ ଧ୍ୟାନ ଦିଅନ୍ତୁ |",
            "as": "কলেজ এৰি নৃত্যত মনোনিবেশ কৰক।",
            "gu": "કોલેજ છોડીને ડાન્સ ઉપર ધ્યાન આપવું."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Make up for his attendance and focus on his studies.",
            "hi": "अपनी उपस्थिति को पूरा करके, पढ़ाई पर ध्यान देना चाहिए।",
            "ka": "ಅವನ ಹಾಜರಾತಿಯನ್ನು ಹೊಂದಿಸಿ ಮತ್ತು ಅವನ ಅಧ್ಯಯನದ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಿ.",
            "te": "అతని హాజరును సరి చేస్కోని అతని చదువుపై ఫోకస్ చేయాలా.",
            "or": "ତାଙ୍କ ଉପସ୍ଥାନ ପାଇଁ ବ୍ଯବସ୍ଥା କରନ୍ତି ଏବଂ ତାଙ୍କ ଅଧ୍ୟୟନ ଉପରେ ଧ୍ୟାନ ଦିଅନ୍ତୁ |",
            "as": "তেওঁৰ উপস্থিতিৰ ক্ষতিবোৰ পূৰাওক আৰু পঢ়াশুনাত মনোনিবেশ কৰক।",
            "gu": "તેની હાજરીની ભરપાઈ કરી અને તેના અભ્યાસ પર ધ્યાન કેન્દ્રિત કર્યું."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.tag",
          "value": {
            "en": "risk",
            "hi": "जोखिम",
            "ka": "ಅಪಾಯ",
            "te": "రిస్క్",
            "or": "ବିପଦ",
            "as": "শংকা",
            "gu": "જોખમ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.tag",
          "value": {
            "en": "stability",
            "hi": "स्थिरता",
            "ka": "ಸ್ಥಿರತೆ",
            "te": "స్థిరత్వం",
            "or": "ସ୍ଥିରତା",
            "as": "স্থিৰতা",
            "gu": "સ્થિરતા"
          }
        }
      ]
    },
    "scene7": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_1_1_Focus on Dance ",
            "hi": "पढ़ाई पर ध्यान केंद्रित करना",
            "ka": "B4_1_1_ನೃತ್ಯದ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಿ",
            "te": "B4_1_1_డ్యాన్స్‌పై దృష్టి పెట్టాలి",
            "or": "B4_1_1_Focus on Dance",
            "as": "B4_1_1_নৃত্যৰ ওপৰত গুৰুত্ব",
            "gu": "B4_1_1_નૃત્ય પર ફોકસ કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen wants to complete his college. He feels the three years of college will help him grow as a person and get a job.",
            "hi": "नवीन अपना कॉलेज पूरा करना चाहता है। उसे लगता है कि कॉलेज के तीन साल उसे बेहतर व्यक्ति बनने में और नौकरी प्राप्त करने में मदद कर सकते हैं।",
            "ka": "ನವೀನ್ ತನ್ನ ಕಾಲೇಜನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ಬಯಸುತ್ತಾನೆ. ಮೂರು ವರ್ಷಗಳ ಕಾಲೇಜು ತನಗೆ ವ್ಯಕ್ತಿಯಾಗಿ ಬೆಳೆಯಲು ಮತ್ತು ಉದ್ಯೋಗ ಪಡೆಯಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ ಎಂದು ಅವರು ಭಾವಿಸುತ್ತಾನೆ.",
            "te": "నవీన్ తన కళాశాలని కంప్లీట్ చేయాలనుకుంటున్నాడు. మూడు సంవత్సరాల కళాశాల తనకు ఒక వ్యక్తిగా ఎదగడానికి మరియు ఉద్యోగం పొందడానికి సహాయపడుతుందని అతను అనుకుంటున్నాడు.",
            "or": "ନବୀନ ତାଙ୍କ କଲେଜ ଶେଷ କରିବାକୁ ଚାହାଁନ୍ତି । ସେ ଅନୁଭବ କରନ୍ତି ଯେ କଲେଜର ତିନି ବର୍ଷ ତାଙ୍କୁ ଜଣେ ବ୍ୟକ୍ତି ଭାବରେ ବଢିବାରେ ସାହାଯ୍ୟ କରିବ ଏବଂ ଚାକିରି କରାଇବ |",
            "as": "নবীনে কলেজৰ পঢ়া সম্পূৰ্ণ কৰিব বিচাৰে। তেওঁ অনুভৱ কৰে যে মহাবিদ্যালয়ত কটোৱা তিনিটা বছৰে তেওঁক এজন ব্যক্তি হিচাপে বিকাশ কৰাত আৰু চাকৰি পোৱাত সহায় কৰিব।",
            "gu": "નવીન તેની કોલેજ પૂરી કરવા માંગે છે. તેને લાગે છે કે કોલેજના ત્રણ વર્ષ  પૂરા કર્યા પછી તે એક વિકશીલ વ્યક્તિ તરીકે ઊભો થસે અને નોકરી મળવામાં સરળ હશે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_1_2",
            "hi": "B4_1_2",
            "ka": "B4_1_2",
            "te": "B4_1_2",
            "or": "B4_1_2",
            "as": "B4_1_2",
            "gu": "B4_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He decides to cut down on his dance performances. Omi and Zaid are both a little disappointed, but Naveen has decided to prioritise his family and studies.",
            "hi": "वह अपने डान्स परफोर्मेंस कम करता हैं। ओमी और जाएद थोड़े निराश होते हैं लेकिन नवीन ने अपने परिवार और पढ़ाई को प्राथमिकता देने का निर्णय लिया है।",
            "ka": "ಅವನು ತನ್ನ ನೃತ್ಯ ಪ್ರದರ್ಶನಗಳನ್ನು ಕಡಿಮೆ ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಓಮಿ ಮತ್ತು ಝೈದ್ ಇಬ್ಬರೂ ಸ್ವಲ್ಪ ನಿರಾಶೆಗೊಂಡಿದ್ದಾರೆ, ಆದರೆ ನವೀನ್ ತನ್ನ ಕುಟುಂಬ ಮತ್ತು ಅಧ್ಯಯನಕ್ಕೆ ಆದ್ಯತೆ ನೀಡಲು ನಿರ್ಧರಿಸಿದ್ದಾನೆ.",
            "te": "అతను తన నృత్య ప్రదర్శనలను తగ్గించుకోవాలని నిర్ణయించుకున్నాడు. ఓమి మరియు జైద్ ఇద్దరూ కొంచెం నిరాశ చెందారు, కానీ నవీన్ తన కుటుంబానికి మరియు చదువుకు ప్రాధాన్యత ఇవ్వాలని నిర్ణయించుకున్నాడు.",
            "or": "ସେ ତାଙ୍କ ନୃତ୍ୟ ପରିବେଷଣରେ କାଟ୍‌ କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ଓମି ଏବଂ ଜାଏଦ୍‌ ଦୁହେଁ ଟିକେ ନିରାଶ ହୁଅନ୍ତି, କିନ୍ତୁ ନବୀନ ତାଙ୍କ ପରିବାର ଏବଂ ଅଧ୍ୟୟନକୁ ପ୍ରାଥମିକତା ଦେବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
            "as": "তেওঁ তেওঁৰ নৃত্য প্ৰদৰ্শন হ্ৰাস কৰাৰ সিদ্ধান্ত লয়। অমি আৰু জাইদ দুয়ো অলপ হতাশ হ’ল, কিন্তু নবীনে তেওঁৰ পৰিয়াল আৰু পঢ়া-শুনাক প্ৰাধান্যতা দিয়াৰ সিদ্ধান্ত লৈছে।",
            "gu": "તેને તેના નૃત્ય પ્રદર્શનમાં ઓછું જવાનું નક્કી કર્યું. ઓમી અને ઝૈદ બંને આનાથી થોડા નિરાશ છે, પરંતુ નવીને તેના કુટુંબને અને અભ્યાસને પહેલી તક આપવાનું નક્કી કર્યું છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_1_3",
            "hi": "B4_1_3",
            "ka": "B4_1_3",
            "te": "B4_1_3",
            "or": "B4_1_3",
            "as": "B4_1_3",
            "gu": "B4_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen works hard and finishes his pending assignments. He does well in the semester exam as well.",
            "hi": "नवीन बहुत मेहनत करता है और अपने अधूरे असाइनमेंट पुरे करता हैं। उसे सेमिस्टर की परीक्षा में अच्छे गुण प्राप्त होते हैं।",
            "ka": "ನವೀನ್ ಕಷ್ಟಪಟ್ಟು ಕೆಲಸ ಮಾಡುತ್ತಾನೆ ಮತ್ತು ತನ್ನ ಬಾಕಿ ಉಳಿದಿರುವ ಕಾರ್ಯಯೋಜನೆಗಳನ್ನು ಮುಗಿಸುತ್ತಾನೆ. ಸೆಮಿಸ್ಟರ್ ಪರೀಕ್ಷೆಯಲ್ಲೂ ಉತ್ತಮ ಸಾಧನೆ ಮಾಡುತ್ತಾನೆ.",
            "te": "నవీన్ కష్టపడి తన పెండింగ్‌లో ఉన్న పనులను పూర్తి చేస్తాడు. సెమిస్టర్ పరీక్షలోనూ బాగా రాణిస్తాడు.",
            "or": "ନବୀନ କଠିନ ପରିଶ୍ରମ କରନ୍ତି ଏବଂ ତାଙ୍କର ବକିଥିବା କାର୍ଯ୍ୟଗୁଡିକ ସମାପ୍ତ କରନ୍ତି | ସେ ସେମିଷ୍ଟର୍‌ ପରୀକ୍ଷାରେ ମଧ୍ୟ ଭଲ ପ୍ରଦର୍ଶନ କରନ୍ତି |",
            "as": "নবীনে কঠোৰ পৰিশ্ৰম কৰে আৰু তেওঁৰ অমীমাংসিত কামবোৰ শেষ কৰে। তেওঁ ছেমিষ্টাৰ পৰীক্ষাতো ভাল প্ৰদৰ্শন কৰে।",
            "gu": "નવીન બહુજ મહેનત કરે છે અને તેન બચેલું કામ પૂરું કરે છે. તે સેમેસ્ટરની પરીક્ષામાં પણ સારો દેખાવ કરે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_2_1",
            "hi": "B4_2_1",
            "ka": "B4_2_1",
            "te": "B4_2_1",
            "or": "B4_2_1",
            "as": "B4_2_1",
            "gu": "B4_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "When placement for jobs begins, everyone in his batch is asked to do research and study trends of the job market.",
            "hi": "जब जॉब के लिए प्लेसमेंट शुरू होते हैं, तब उनके क्लास के सभी को जॉब मार्केट के ट्रेंड्स पर संशोधन और अध्ययन करने के लिए कहा जाता है।",
            "ka": "ಉದ್ಯೋಗಗಳಿಗೆ ನಿಯೋಜನೆ ಪ್ರಾರಂಭವಾದಾಗ, ಅವರ ಬ್ಯಾಚ್‌ನಲ್ಲಿರುವ ಪ್ರತಿಯೊಬ್ಬರೂ ಉದ್ಯೋಗ ಮಾರುಕಟ್ಟೆಯ ಪ್ರವೃತ್ತಿಯನ್ನು ಸಂಶೋಧನೆ ಮತ್ತು ಅಧ್ಯಯನ ಮಾಡಲು ಕೇಳಲಾಗುತ್ತದೆ.",
            "te": "ఉద్యోగాల కోసం ప్లేస్‌మెంట్ మొదలు అయినప్పుడు, అతని బ్యాచ్‌లోని ప్రతి ఒక్కరినీ ఉద్యోగం మార్కెట్ ట్రెండ్‌లను పరిశోధన చేసి స్టడీ చేయమని అడుగుతారు.",
            "or": "ଯେତେବେଳେ ଚାକିରି ପାଇଁ ପ୍ଲେସ୍‌ମେଣ୍ଟ ଆରମ୍ଭ ହୁଏ, ତାଙ୍କ ବ୍ୟାଚରେ ଥିବା ସମସ୍ତଙ୍କୁ ଚାକିରି ବଜାରର ଅନୁସନ୍ଧାନ ଏବଂ ଟ୍ରେଣ୍ଡ ଅଧ୍ୟୟନ କରିବାକୁ କୁହାଯାଏ |",
            "as": "যেতিয়া চাকৰিৰ বাবে স্থান নিৰূপন আৰম্ভ হয়, তেওঁৰ গোটৰ সকলোকে চাকৰিৰ বজাৰৰ আৰু অধ্যয়নৰ প্ৰৱণতাৰ ওপৰত গৱেষণা কৰিবলৈ কোৱা হয়।",
            "gu": "જ્યારે નોકરીઓ આપવાનું શરૂ થાય છે, ત્યારે તેના વર્ગના દરેકને સંશોધન કરવા અને નોકરીની હોડમાં અભ્યાસ કરવાનું કહેવામાં આવે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_2_2",
            "hi": "B4_2_2",
            "ka": "B4_2_2",
            "te": "B4_2_2",
            "or": "B4_2_2",
            "as": "B4_2_2",
            "gu": "B4_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "To understand the latest trends in the job market, he does some research on the internet.",
            "hi": "जॉब मार्केट में नए ट्रेंड्स के बारे में जानने के लिए, वह इंटरनेट पर कुछ संशोधन करता है।",
            "ka": "ಉದ್ಯೋಗ ಮಾರುಕಟ್ಟೆಯಲ್ಲಿನ ಇತ್ತೀಚಿನ ಪ್ರವೃತ್ತಿಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು, ಅವನು ಇಂಟರ್ನೆಟ್ನಲ್ಲಿ ಕೆಲವು ಸಂಶೋಧನೆಗಳನ್ನು ಮಾಡುತ್ತಾನೆ.",
            "te": "ఉద్యోగం మార్కెట్‌లోని కొత్త ట్రెండ్‌లను అర్థం చేసుకోవడానికి, అతను ఇంటర్నెట్‌లో కొంత పరిశోధన చేస్తాడు.",
            "or": "ଚାକିରି ବଜାରର ଅତ୍ୟାଧୁନିକ ଟ୍ରେଣ୍ଡକୁ ବୁଝିବା ପାଇଁ ସେ ଇଣ୍ଟରନେଟରେ କିଛି ଗବେଷଣା କରନ୍ତି |",
            "as": "চাকৰিৰ বজাৰৰ শেহতীয়া প্ৰৱণতাবোৰ বুজিবলৈ, তেওঁ ইণ্টাৰনেটত কিছু গৱেষণা কৰে।",
            "gu": "નોકરીની હોડમાં નવા વલણોને સમજવા માટે, તે ઇન્ટરનેટ પર સોધ કરે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_2_3(search)",
            "hi": "B4_2_3(सर्च करना)",
            "ka": "B4_2_3(ಹುಡುಕಿ)",
            "te": "B4_2_3(శోధన)",
            "or": "B4_2_3(search)",
            "as": "B4_2_3(অনুসন্ধান)",
            "gu": "B4_2_3(શોધ)"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Click on search to know what he finds out.",
            "hi": "उसे क्या जानकरी मिली यह जानने के लिए सर्च पर क्लिक करें।",
            "ka": "ಅವನು ಏನು ಕಂಡುಕೊಂಡಿದ್ದಾನೆಂದು ತಿಳಿಯಲು ಹುಡುಕಾಟದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "te": "అతను ఏమి కనుగొన్నాడో తెలుసుకోవడానికి శోధనపై క్లిక్ చేయండి (నొక్కండి).",
            "or": "ସେ କଣ ପାଆନ୍ତି ଜାଣିବା ପାଇଁ ସର୍ଚ୍ଚରେ କ୍ଲିକ୍ କରନ୍ତୁ |",
            "as": "তেওঁ কি বিচাৰি পায় জানিবলৈ সন্ধানত ক্লিক কৰক।",
            "gu": "તેને શું મળ્યું છે તે જાણવા માટે શોધ પર ક્લિક કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.strings.0.string",
          "value": {
            "en": "Jobs trends of the future in Bangalore/India",
            "hi": "बैंगलोर/भारत में भविष्य के नौकरी के ट्रेंड्स",
            "ka": "ಬೆಂಗಳೂರು/ಭಾರತದಲ್ಲಿ ಭವಿಷ್ಯದ ಉದ್ಯೋಗದ ಪ್ರವೃತ್ತಿಗಳು",
            "te": "బెంగళూరు/భారతదేశంలో భవిష్యత్తు ఉద్యోగ ట్రెండ్‌లు",
            "or": "ବାଙ୍ଗାଲୋର/ଭାରତରେ ଭବିଷ୍ୟତର ଚାକିରିର ଟ୍ରେଣ୍ଡ",
            "as": "বাংগালোৰ/ভাৰতত ভৱিষ্যতৰ চাকৰিৰ প্ৰৱণতা",
            "gu": "ભવિષ્યના નોકરીના વલણો બેંગ્લોર/ભારતમાં "
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_2_4(reveal)",
            "hi": "B4_2_4(दिखाएं)",
            "ka": "B4_2_4(ಬಹಿರಂಗಪಡಿಸು)",
            "te": "B4_2_4(వెల్లడించు )",
            "or": "B4_2_4(reveal)",
            "as": "B4_2_4(প্ৰকাশ)",
            "gu": "B4_2_4(જાહેર કરો)"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Naveen sees two categories show up: stable jobs and emerging trends.\nClick on each to reveal the jobs available.",
            "hi": "नवीन दो कैटेगरी देखता हैं: स्थिर नौकरी और आनेवाले ट्रेंड्स। \n उपलब्ध नौकरी दिखाने के लिए हर एक पर क्लिक करें।",
            "ka": "ನವೀನ್ ಎರಡು ವಿಭಾಗಗಳು ಕಾಣಿಸಿಕೊಳ್ಳುವುದನ್ನು ನೋಡುತ್ತಾನೆ: ಸ್ಥಿರ ಉದ್ಯೋಗಗಳು ಮತ್ತು ಉದಯೋನ್ಮುಖ ಪ್ರವೃತ್ತಿಗಳು.\n ಲಭ್ಯವಿರುವ ಉದ್ಯೋಗಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಪ್ರತಿಯೊಂದರ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "te": "నవీన్ రెండు వర్గాలు చూస్తున్నారు: స్థిరమైన ఉద్యోగాలు మరియు ఎమర్జింగ్ ట్రెండ్‌లు.\n అందుబాటులో ఉన్న ఉద్యోగాలను బహిర్గతం చేయడానికి ప్రతిదానిపై క్లిక్ చేయండి (నొక్కండి).",
            "or": "ନବୀନ ଦୁଇଟି ବର୍ଗ ଦେଖାଉଥିବା ଦେଖନ୍ତି: ସ୍ଥିର ଚାକିରି ଏବଂ ଉଦୀୟମାନ ଟ୍ରେଣ୍ଡ |",
            "as": "নবীনে দুটা শ্ৰেণী দেখা পাইছে: স্থিৰ চাকৰি আৰু উদীয়মান প্ৰৱণতা। উপলব্ধ চাকৰিবোৰ জানিবলৈ প্ৰতিটোত ক্লিক কৰক।",
            "gu": "નવીનને બે પ્રકાર દેખવા મળે છે: સ્થિર નોકરીઓ અને ઉભરતાના વલણો.\nહાજર નોકરીઓ જણાવવા માટે દરેક પર ક્લિક કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Stable job",
            "hi": "स्थिर नौकरी",
            "ka": "ಸ್ಥಿರ ಕೆಲಸ",
            "te": "స్థిరమైన ఉద్యోగం",
            "or": "ଉପଲବ୍ଧ ଚାକିରିଗୁଡିକ ପ୍ରକାଶ କରିବାକୁ ପ୍ରତ୍ୟେକ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ |",
            "as": "স্থিৰ চাকৰি",
            "gu": "સ્થિર નોકરી"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "Stable job",
            "hi": "स्थिर नौकरी",
            "ka": "ಸ್ಥಿರ ಕೆಲಸ",
            "te": "స్థిరమైన ఉద్యోగం",
            "or": "ସ୍ଥିର ଚାକିରି",
            "as": "স্থিৰ চাকৰি",
            "gu": "સ્થિર નોકરી"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.beforeReveal",
          "value": {
            "en": "Stable job",
            "hi": "स्थिर नौकरी",
            "ka": "ಸ್ಥಿರ ಕೆಲಸ",
            "te": "స్థిరమైన ఉద్యోగం",
            "or": "ସ୍ଥିର ଚାକିରି",
            "as": "স্থিৰ চাকৰি",
            "gu": "સ્થિર નોકરી"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.beforeReveal",
          "value": {
            "en": "Emerging trends",
            "hi": "आनेवाले ट्रेंड्स",
            "ka": "ಉದಯೋನ್ಮುಖ ಪ್ರವೃತ್ತಿಗಳು",
            "te": "ఎమర్జింగ్ ట్రెండ్‌లు",
            "or": "ଉଦୀୟମାନ ଟ୍ରେଣ୍ଡ",
            "as": "উদীয়মান প্ৰৱণতা",
            "gu": "ઉભરતા વલણો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.beforeReveal",
          "value": {
            "en": "Emerging trends",
            "hi": "आनेवाले ट्रेंड्स",
            "ka": "ಉದಯೋನ್ಮುಖ ಪ್ರವೃತ್ತಿಗಳು",
            "te": "ఎమర్జింగ్ ట్రెండ్‌లు",
            "or": "ଉଦୀୟମାନ ଟ୍ରେଣ୍ଡ",
            "as": "উদীয়মান প্ৰৱণতা",
            "gu": "ઉભરતા વલણો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.5.beforeReveal",
          "value": {
            "en": "Emerging trends",
            "hi": "आनेवाले ट्रेंड्स",
            "ka": "ಉದಯೋನ್ಮುಖ ಪ್ರವೃತ್ತಿಗಳು",
            "te": "ఎమర్జింగ్ ట్రెండ్‌లు",
            "or": "ଉଦୀୟମାନ ଟ୍ରେଣ୍ଡ",
            "as": "উদীয়মান প্ৰৱণতা",
            "gu": "ઉભરતા વલણો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "Teaching",
            "hi": "शिक्षक",
            "ka": "ಬೋಧನೆ",
            "te": "బోధన",
            "or": "ଶିକ୍ଷାଦାନ",
            "as": "শিক্ষকতা",
            "gu": "શિક્ષણ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "Medical care",
            "hi": "मेडिकल सेवा",
            "ka": "ವೈದ್ಯಕೀಯ ಆರೈಕೆ",
            "te": "మెడికల్ కేర్",
            "or": "ଡାକ୍ତରୀ ୟତ୍ନ",
            "as": "চিকিত্‍সা যত্ন",
            "gu": "તબીબી સંભાળ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.afterReveal",
          "value": {
            "en": "Lawyer",
            "hi": "वकील",
            "ka": "ವಕೀಲ",
            "te": "లాయర్",
            "or": "ଓକିଲ",
            "as": "উকীল",
            "gu": "વકીલ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.afterReveal",
          "value": {
            "en": "Event management",
            "hi": "इवेंट मैनेजमेंट",
            "ka": "ಕಾರ್ಯಕ್ರಮ ನಿರ್ವಹಣೆ",
            "te": "ఈవెంట్ మేనేజ్మెంట్",
            "or": "ଇଭେଣ୍ଟ ମ୍ୟାନେଜମେଣ୍ଟ୍‍",
            "as": "অনুষ্ঠান ব্যৱস্থাপনা",
            "gu": "ઇવેન્ટ મેનેજમેન્ટ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.afterReveal",
          "value": {
            "en": "Sales",
            "hi": "सेल्स",
            "ka": "ಮಾರಾಟ",
            "te": "సేల్స్",
            "or": "ସେଲ୍ସ",
            "as": "বিক্ৰী",
            "gu": "વેચાણ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.5.afterReveal",
          "value": {
            "en": "Marketing",
            "hi": "मार्केटिंग",
            "ka": "ಮಾರ್ಕೆಟಿಂಗ್",
            "te": "మార్కెటింగ్",
            "or": "ମାର୍କେଟିଂ",
            "as": "বিপণন",
            "gu": "માર્કેટિંગ"
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_2_5",
            "hi": "B4_2_5",
            "ka": "B4_2_5",
            "te": "B4_2_5",
            "or": "B4_2_5",
            "as": "B4_2_5",
            "gu": "B4_2_5"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen focuses on the jobs from the emerging trends, as they interest him the most.",
            "hi": "नवीन आनेवाले ट्रेंड्स की नौकरियों पर ध्यान केंद्रित करता है, क्योंकि ये उसे ज्यादा दिलचस्प लगती हैं।",
            "ka": "ನವೀನ್ ಉದಯೋನ್ಮುಖ ಪ್ರವೃತ್ತಿಗಳಿಂದ ಉದ್ಯೋಗಗಳ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸುತ್ತಾನೆ, ಏಕೆಂದರೆ ಅವುಗಳು ಅವನಿಗೆ ಹೆಚ್ಚು ಆಸಕ್ತಿಯನ್ನುಂಟುಮಾಡುತ್ತವೆ.",
            "te": "నవీన్ ఎమర్జింగ్ ట్రెండ్‌ల ఉద్యోగాలపై ఫోకస్ పెడతాడు, ఎందుకంటే అవి అతనికి ఎక్కువ ఆసక్తిని కలిగిస్తాయి.",
            "or": "ନବୀନ ଉଦୀୟମାନ ହେଉଥିବା ଟ୍ରେଣ୍ଡରୁ ଚାକିରି ଉପରେ ଧ୍ୟାନ ଦିଅନ୍ତି, ଯେହେତୁ ସେଗୁଡିକ ତାଙ୍କୁ ଅଧିକ ଆଗ୍ରହୀ କରନ୍ତି |",
            "as": "নবীনে উদীয়মান প্ৰৱণতাৰ পৰা পোৱা চাকৰিবোৰৰ ওপৰত গুৰুত্ব দিয়ে, কিয়নো সেইবোৰে তেওঁক আটাইতকৈ বেছি আগ্ৰহী কৰে।",
            "gu": "નવી આવતી નોકરીઓ પર ધ્યાન કેન્દ્રિત કરે છે, કારણ કે તેઓ તેને સૌથી વધુ રસ લે છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "Market Trends",
            "hi": "मार्केट ट्रेंड्स",
            "ka": "ಮಾರುಕಟ್ಟೆ ಪ್ರವೃತ್ತಿಗಳು",
            "te": "మార్కెట్ ట్రెండ్స్",
            "or": "ମାର୍କେଟ୍‌ ଟ୍ରେଣ୍ଡ",
            "as": "বজাৰৰ প্ৰৱণতা",
            "gu": "માર્કેટના વલણો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Market Trends experience!",
            "hi": "आपने अभी-अभी मार्केट के ट्रेंड्स के बारे में अनुभव प्राप्त किया है!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಮಾರುಕಟ್ಟೆ ಪ್ರವೃತ್ತಿಗಳ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే మార్కెట్ ట్రెండ్స్ అనుభవాన్ని పొందారు!",
            "or": "ତୁମେ ବର୍ତମାନ ମାର୍କେଟ୍‌ ଟ୍ରେଣ୍ଡ ଅଭିଜ୍ଞତା ହାସଲ କରିଛ !",
            "as": "তুমি এইমাত্ৰ বজাৰৰ প্ৰৱণতাৰ অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "તમે હમણાં જ માર્કેટ ટ્રેન્ડ્સનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Market Trends",
            "hi": "मार्केट ट्रेंड्स",
            "ka": "ಮಾರುಕಟ್ಟೆ ಪ್ರವೃತ್ತಿಗಳು",
            "te": "మార్కెట్ ట్రెండ్స్",
            "or": "ମାର୍କେଟ୍‌ ଟ୍ରେଣ୍ଡ",
            "as": "বজাৰৰ প্ৰৱণতা",
            "gu": "માર્કેટના વલણો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Understanding Work Environment",
            "hi": "काम के माहौल को समझना",
            "ka": "ಕೆಲಸದ ಪರಿಸರವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು",
            "te": "పని వాతావరణాన్ని అర్థం చేసుకోవడం",
            "or": "କାର୍ଯ୍ୟ ପରିବେଶ ବୁଝିବା",
            "as": "কামৰ পৰিৱেশ বুজা",
            "gu": "કાર્ય પર્યાવરણને સમજવું"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Before applying for a job, Naveen studies the emerging trends and about stable jobs. It is essential to know the various job trends, how we work and live changes every few years with the advancement of technology. With this, the available jobs and career paths also change. When choosing a career path, it is essential to study trends and changes in the market while deciding.",
            "hi": "जॉब के लिए अप्लाई करने से पहले, नवीन आनेवाले ट्रेंड्स और स्थिर नौकरी के बारे में जानकारी प्राप्त करता है। अलग-अलग जॉब ट्रेंड्स के बारे में जानना, हम कैसे काम करते हैं और टेक्नोलॉजी के प्रगति के साथ कुछ सालों में जिंदगी कैसे बदलती रहती है यह जानना महत्वपूर्ण है। इसके साथ, उपलब्ध नौकरियां और करियर के मार्ग भी बदलते रहते हैं। करियर का मार्ग चुनते समय, निर्णय लेने से पहले ट्रेंड्स और मार्केट के बदलाव का अध्ययन करना महत्वपूर्ण है।",
            "ka": "ಉದ್ಯೋಗಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸುವ ಮೊದಲು, ನವೀನ್ ಉದಯೋನ್ಮುಖ ಪ್ರವೃತ್ತಿಗಳು ಮತ್ತು ಸ್ಥಿರ ಉದ್ಯೋಗಗಳ ಬಗ್ಗೆ ಅಧ್ಯಯನ ಮಾಡುತ್ತಾನೆ. ತಂತ್ರಜ್ಞಾನದ ಪ್ರಗತಿಯೊಂದಿಗೆ ಪ್ರತಿ ಕೆಲವು ವರ್ಷಗಳಿಗೊಮ್ಮೆ ನಾವು ಹೇಗೆ ಕೆಲಸ ಮಾಡುತ್ತೇವೆ ಮತ್ತು ಬದುಕುತ್ತೇವೆ, ವಿವಿಧ ಉದ್ಯೋಗ ಪ್ರವೃತ್ತಿಗಳನ್ನು ತಿಳಿದುಕೊಳ್ಳುವುದು ಅತ್ಯಗತ್ಯ. ಇದರೊಂದಿಗೆ, ಲಭ್ಯವಿರುವ ಉದ್ಯೋಗಗಳು ಮತ್ತು ವೃತ್ತಿ ಮಾರ್ಗಗಳು ಸಹ ಬದಲಾಗುತ್ತವೆ. ವೃತ್ತಿ ಮಾರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡುವಾಗ, ನಿರ್ಧರಿಸುವಾಗ ಮಾರುಕಟ್ಟೆಯಲ್ಲಿನ ಪ್ರವೃತ್ತಿಗಳು ಮತ್ತು ಬದಲಾವಣೆಗಳನ್ನು ಅಧ್ಯಯನ ಮಾಡುವುದು ಅತ್ಯಗತ್ಯ.",
            "te": "ఉద్యోగం కోసం దరఖాస్తు చేయడానికి ముందు, నవీన్ ఎమర్జింగ్ ట్రెండ్‌లను మరియు స్థిరమైన ఉద్యోగాల గురించి చదువుతాడు. సాంకేతికత పెరగడంతో అనేక ఉద్యోగాల ట్రెండ్లను, ప్రతి కొన్ని సంవత్సరాలకు మనం ఎలా పని చేస్తాము మరియు జీవిస్తున్నాము అని తెలుసుకోవడం చాలా ముఖ్యం. దీంతో అందుబాటులో ఉన్న ఉద్యోగాలు, వృత్తి మార్గాలు కూడా మారుతున్నాయి. వృత్తి మార్గాన్ని ఎంచుకునేటప్పుడు, మార్కెట్‌లోని ట్రెండ్‌లు మరియు మార్పులను స్టడీ చేయడం చాలా అవసరం.",
            "or": "ଚାକିରି ପାଇଁ ଆବେଦନ କରିବା ପୂର୍ବରୁ, ନବୀନ ଉଦୀୟମାନ ଟ୍ରେଣ୍ଡ ଏବଂ ସ୍ଥିର ଚାକିରି ବିଷୟରେ ଅଧ୍ୟୟନ କରନ୍ତି | । ଟେକ୍ନୋଲୋଜିର ଅଗ୍ରଗତି ସହିତ ବିଭିନ୍ନ ଚାକିରି ଟ୍ରେଣ୍ଡ, ଆମେ କିପରି କାର୍ଯ୍ୟ କରୁ ଏବଂ ଜୀବନ୍ତ ପରିବର୍ତ୍ତନ ଜାଣିବା ଜରୁରୀ ଅଟେ | ଏହା ସହିତ ଉପଲବ୍ଧ ଚାକିରି ଏବଂ କ୍ୟାରିୟର୍‌ ପଥ ମଧ୍ୟ ବଦଳିଯାଏ | କ୍ୟାରିୟର୍‌ ପଥ ଚୟନବେଳେ, ନିଷ୍ପତ୍ତି ନେବାବେଳେ ବଜାରରେ ଟ୍ରେଣ୍ଡ ଏବଂ ପରିବର୍ତ୍ତନ ଅଧ୍ୟୟନ କରିବା ଏକାନ୍ତ ଆବଶ୍ୟକ |",
            "as": "চাকৰিৰ বাবে আবেদন কৰাৰ আগতে, নবীনে উদীয়মান প্ৰৱণতা আৰু স্থিৰ চাকৰিৰ বিষয়ে অধ্যয়ন কৰে। প্ৰযুক্তিৰ উন্নতিৰ সৈতে প্ৰতি কেইবছৰমানৰ মূৰে মূৰে আমি কেনেদৰে কাম কৰোঁ আৰু জীয়াই থাকোঁ সেই বিষয়ে বিভিন্ন চাকৰিৰ প্ৰৱণতা জনাটো আৱশ্যকীয়। ইয়াৰ লগতে, উপলব্ধ চাকৰি আৰু জীৱিকাৰ পথো সলনি হয়। জীৱিকাৰ পথ বাছনি কৰোঁতে, সিদ্ধান্ত লোৱাৰ সময়ত বজাৰৰ প্ৰৱণতা আৰু পৰিৱৰ্তন অধ্যয়ন কৰাটো আৱশ্যকীয়।",
            "gu": "નોકરી માટે અરજી કરતા પહેલા, નવીન ઉભરતા વલણો અને સ્થિર નોકરીઓનો અભ્યાસ કરે છે. કામના વિવિધ વલણો, અમે કેવી રીતે કામ કરીએ છીએ અને ટેક્નૉલૉજીની પ્રગતિ સાથે દર થોડાં વર્ષે આપણું જીવન કેવી રીતે બદલીએ છીએ તે જાણવું જરૂરી છે. તે જ સમયે, નોકરી અને કારકિર્દીના માર્ગો બદલાઈ રહ્યા છે. કારકિર્દીનો માર્ગ પસંદ કરતી વખતે, નિર્ણય લેતી વખતે માર્કેટના વલણો અને ફેરફારોનો અભ્યાસ કરવો આવશ્યક છે."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "mcqs",
            "hi": "mcqs",
            "ka": "ಎಂಸಿಕ್ಯೂಸ್",
            "te": "mcqలు",
            "or": "mcqs",
            "as": "mcqs",
            "gu": "mcqs"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Select which of the following statements about trends is true:",
            "hi": "ट्रेंड्स के बारे में नीचे दिए हुए विधानों में से सही विधान चुनें:",
            "ka": "ಟ್ರೆಂಡ್‌ಗಳ ಕುರಿತು ಈ ಕೆಳಗಿನ ಯಾವ ಹೇಳಿಕೆಗಳು ನಿಜವೆಂದು ಆಯ್ಕೆಮಾಡಿ:",
            "te": "ట్రెండ్‌ల గురించి కింది ప్రకటనలలో ఏది నిజమో ఎంచుకోండి:",
            "or": "ଟ୍ରେଣ୍ଡଗୁଡିକ ବିଷୟରେ ନିମ୍ନଲିଖିତ ବକ୍ତବ୍ୟ ମଧ୍ୟରୁ କେଉଁଟି ସତ୍ଯ ତାହା ଚୟନ କରନ୍ତୁ |",
            "as": "প্ৰৱণতাৰ বিষয়ে নিম্নলিখিত কোনটো মন্তব্য সঁচা বাছি উলিওৱা:",
            "gu": "વલણો વિશે નીચેનામાંથી કયું વાક્ય સાચું છે તે પસંદ કરો:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Trends happen once in 20 years caused by changes in how we work and live.",
            "hi": "हम किस तरह से काम करते और रहते हैं इसकी वजह से 20 सालों में एकबार ट्रेंड्स निर्माण होता है।",
            "ka": "ನಾವು ಕೆಲಸ ಮಾಡುವ ಮತ್ತು ಬದುಕುವ ವಿಧಾನದಲ್ಲಿನ ಬದಲಾವಣೆಗಳಿಂದ 20 ವರ್ಷಗಳಿಗೊಮ್ಮೆ ಪ್ರವೃತ್ತಿಗಳು ಸಂಭವಿಸುತ್ತವೆ.",
            "te": "మనం పని చేసే మరియు జీవించే విధానంలో మార్పుల వల్ల ప్రతి 20 సంవత్సరాలకు ఒకసారి ట్రెండ్‌లు జరుగుతాయి.",
            "or": "ଆମେ କିପରି କାମ କରୁ ଏବଂ ଜୀବନଯାପନରେ ପରିବର୍ତ୍ତନ ହେତୁ 20 ବର୍ଷରେ ଥରେ ଟ୍ରେଣ୍ଡ ହୁଏ |",
            "as": "আমি কেনেদৰে কাম কৰোঁ আৰু জীয়াই থাকো তাৰ পৰিৱৰ্তনৰ ফলত 20 বছৰত এবাৰকৈ প্ৰৱণতা ঘটে।",
            "gu": "જે આપણેકામ કેવી રીતે કરીએ છીએ અને જીવન પ્રસાર કરીયે છીએ તેમાં ફેરફાર થાય છે અને તે વલણો 20 વર્ષમાં એકવાર થાય છે ."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "To know the trend about an industry or area of work you need to do research & networking.",
            "hi": "इंडस्ट्री या काम के जगह के ट्रेंड्स के बारे में जानने के लिए आपको अध्ययन और नेटवर्किंग करना होगा।",
            "ka": "ಉದ್ಯಮ ಅಥವಾ ಕೆಲಸದ ಪ್ರದೇಶದ ಪ್ರವೃತ್ತಿಯನ್ನು ತಿಳಿಯಲು ನೀವು ಸಂಶೋಧನೆ ಮತ್ತು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮಾಡಬೇಕಾಗಿದೆ.",
            "te": "ఇండస్ట్రీ లేదా పని ఏరియా గురించిన ట్రెండ్‌ని తెలుసుకోవడానికి మీరు పరిశోధన & నెట్‌వర్కింగ్ చేయాలి.",
            "or": "ଏକ ଶିଳ୍ପ କିମ୍ବା କାର୍ଯ୍ୟର କ୍ଷେତ୍ର ବିଷୟରେ ଧାରା ଜାଣିବା ପାଇଁ ଆପଣଙ୍କୁ ଗବେଷଣା ଏବଂ ନେଟୱାର୍କିଂ କରିବାକୁ ପଡିବ |",
            "as": "উদ্যোগ বা কামৰ প্ৰৱণতাৰ বিষয়ে জানিবলৈ তুমি গৱেষণা আৰু নেটৱৰ্কিং কৰিব লাগিব।",
            "gu": "શોધખોળ અને નેટવર્કિંગ કરવાની જરૂર છે જે ઉદ્યોગ અથવા કાર્ય ક્ષેત્ર વિશેના વલણને જાણવા માટે તમારે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "It is always a good decision to follow the trend when choosing a course to study",
            "hi": "पढ़ाई के कोर्स के बारे चुनाव करने से पहले ट्रेंड्स का पालन करना हमेशा अच्छा निर्णय होता है।",
            "ka": "ಅಧ್ಯಯನ ಮಾಡಲು ಕೋರ್ಸ್ ಅನ್ನು ಆಯ್ಕೆಮಾಡುವಾಗ ಯಾವಾಗಲೂ ಪ್ರವೃತ್ತಿಯನ್ನು ಅನುಸರಿಸುವುದು ಉತ್ತಮ ನಿರ್ಧಾರವಾಗಿದೆ",
            "te": "చదువుకోవడానికి కోర్సును ఎంచుకున్నప్పుడు ట్రెండ్‌ను ఫాలో అవ్వడం ఎప్పటికి మంచి నిర్ణయం",
            "or": "ଅଧ୍ୟୟନ ପାଇଁ ପାଠ୍ୟକ୍ରମ ଚୟନ ବେଳେ ଟ୍ରେଣ୍ଡକୁ ଅନୁସରଣ କରିବା ସର୍ବଦା ଏକ ଭଲ ନିଷ୍ପତ୍ତି",
            "as": "অধ্যয়নৰ বাবে পাঠ্যক্ৰম এটা বাছনি কৰোঁতে প্ৰৱণতাটো অনুসৰণ কৰাটো সদায়ে এটা ভাল সিদ্ধান্ত",
            "gu": "ભણવા માટે અભ્યાસક્રમ પસંદ કરતી વખતે વલણને જોવું એ સારો નિર્ણય છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Think again! Trends are caused by changes in how we work & live and happen every few years.",
            "hi": "फिर से सोचो! हम किस तरह से काम करते और रहते हैं इसकी वजह से कुछ सालों में ट्रेंड्स आते रहते हैं।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ನಾವು ಹೇಗೆ ಕೆಲಸ ಮಾಡುತ್ತೇವೆ ಮತ್ತು ಬದುಕುತ್ತೇವೆ ಮತ್ತು ಪ್ರತಿ ಕೆಲವು ವರ್ಷಗಳಿಗೊಮ್ಮೆ ನಡೆಯುವ ಬದಲಾವಣೆಗಳಿಂದ ಟ್ರೆಂಡ್‌ಗಳು ಉಂಟಾಗುತ್ತವೆ.",
            "te": "మళ్లీ ఆలోచించండి! మనం పని చేసే విధానం & జీవించడం మరియు ప్రతి కొన్ని సంవత్సరాలకు జరిగే మార్పుల వల్ల ట్రెండ్‌లు ఏర్పడతాయి.",
            "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ଆମେ କିପରି କାର୍ଯ୍ୟ କରୁ ଏବଂ ବଞ୍ଚିବାରେ ପରିବର୍ତ୍ତନ ହେତୁ ଟ୍ରେଣ୍ଡଗୁଡିକ ସୃଷ୍ଟି ହୁଏ ଏବଂ କିଛି ବର୍ଷ ମଧ୍ୟରେ ଘଟେ |",
            "as": "আকৌ চিন্তা কৰা! আমাৰ কৰ্মপ্ৰণালী আৰু জীৱন ধাৰণৰ পৰিৱৰ্তনৰ বাবেই কেইবছৰমানৰ মূৰে মূৰে প্ৰৱণতাবোৰ হয় ৷",
            "gu": "વિચારો ફરીથી! આપણે કેવી રીતે કામ કરીએ છીએ અને જીવીએ છીએ અને દર થોડા વર્ષોમાં થાય છે તે વલણો ફેરફારોને કારણે થાય છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "बहुत बढ़िया!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "బాగా చెప్పారు!",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ!",
            "as": "মহান কাম !",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! It is also important to review your decision based on your abilities & interests.",
            "hi": "फिर से सोचो! आपकी क्षमता और दिलचस्पी के अनुसार अपने निर्णय के बारे फिर से विचार करना महत्वपूर्ण है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ನಿಮ್ಮ ಸಾಮರ್ಥ್ಯಗಳು ಮತ್ತು ಆಸಕ್ತಿಗಳ ಆಧಾರದ ಮೇಲೆ ನಿಮ್ಮ ನಿರ್ಧಾರವನ್ನು ಪರಿಶೀಲಿಸುವುದು ಸಹ ಮುಖ್ಯವಾಗಿದೆ.",
            "te": "మరలా ఆలోచించు! మీ సామర్థ్యాలు మరియు ఆసక్తుల ఆధారంగా మీ నిర్ణయాన్ని సమీక్షించడం కూడా ముఖ్యం.",
            "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ତୁମର ଦକ୍ଷତା ଏବଂ ଆଗ୍ରହ ଉପରେ ଆଧାର କରି ତୁମର ନିଷ୍ପତ୍ତି ସମୀକ୍ଷା କରିବା ମଧ୍ୟ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ଅଟେ |",
            "as": "আকৌ চিন্তা কৰা! তোমাৰ সামৰ্থ্য আৰু ইচ্ছাৰ ওপৰত তোমাৰ সিদ্ধান্ত পুনৰীক্ষণ কৰাটোও গুৰুত্বপূৰ্ণ।",
            "gu": "વિચારો ફરીથી! તમારી આવડત અને રસના આધારે તમારા નિર્ણયની સમીક્ષા કરવી પણ મહત્વપૂર્ણ છે."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_2_7",
            "hi": "B4_2_7",
            "ka": "B4_2_7",
            "te": "B4_2_7",
            "or": "B4_2_7",
            "as": "B4_2_7",
            "gu": "B4_2_7"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Naveen concludes his research with two possible careers.\nClick to reveal why he chose these two options.",
            "hi": "अध्ययन के आधार पर नवीन को दो संभाव्य करियर मिल जाते हैं। \n उसने यह दो विकल्प क्यों चुनें यह देखने के लिए क्लिक करें।",
            "ka": "ನವೀನ್ ತನ್ನ ಸಂಶೋಧನೆಯನ್ನು ಎರಡು ಸಂಭಾವ್ಯ ವೃತ್ತಿಗಳೊಂದಿಗೆ ಮುಕ್ತಾಯಗೊಳಿಸುತ್ತಾನೆ.\n ಅವನು ಈ ಎರಡು ಆಯ್ಕೆಗಳನ್ನು ಏಕೆ ಆರಿಸಿಕೊಂಡರು ಎಂಬುದನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "te": "\"నవీన్ తన పరిశోధనను రెండు సాధ్యమైన కెరీర్‌లతో ముగించాడు.\n అతను ఈ రెండు ఎంపికలను ఎందుకు ఎంచుకున్నాడో వెల్లడించడానికి క్లిక్ చేయండి.\" (నొక్కండి).",
            "or": "ଦୁଇଟି ସମ୍ଭାବ୍ୟ କ୍ୟାରିଅର୍ ସହିତ ନବୀନ ତାଙ୍କର ଗବେଷଣା ଶେଷ କରନ୍ତି |\n ସେ କାହିଁକି ଏହି ଦୁଇଟି ବିକଳ୍ପ ବାଛିଲେ ତାହା ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
            "as": "নবীনে দুটা সম্ভাৱ্য জীৱিকাৰ সৈতে তেওঁৰ গৱেষণা সমাপ্ত কৰিছে। তেওঁ কিয় এই দুটা বিকল্প বাছনি কৰিলে তাক জানিবলৈ ক্লিক কৰক।",
            "gu": "નવીન તેના સંશોધનને બે સંભવિત કાર્યો સાથે સમાપ્ત કરે છે.\nતેણે આ બે વિકલ્પો શા માટે પસંદ કર્યા તે જાણવા માટે ક્લિક કરો. \""
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Event management",
            "hi": "इवेंट मैनेजमेंट",
            "ka": "ಕಾರ್ಯಕ್ರಮ ನಿರ್ವಹಣೆ",
            "te": "ఈవెంట్ మేనేజ్మెంట్",
            "or": "ଇଭେଣ୍ଟ ମ୍ୟାନେଜମେଣ୍ଟ୍‍",
            "as": "অনুষ্ঠান ব্যৱস্থাপনা",
            "gu": "ઇવેન્ટ મેનેજમેન્ટ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "Sales",
            "hi": "सेल्स",
            "ka": "ಮಾರಾಟ",
            "te": "సేల్స్",
            "or": "ସେଲ୍ସ",
            "as": "বিক্ৰী",
            "gu": "વેચાણ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "because he has the experience from his dance and college days of organising and planning events.",
            "hi": "क्योंकि उसके पास अपने डान्स और कॉलेज के डेज को आयोजित करने और इवेंट प्लान करने का अनुभव है।",
            "ka": "ಏಕೆಂದರೆ ಅವನು ತಮ್ಮ ನೃತ್ಯ ಮತ್ತು ಕಾಲೇಜು ದಿನಗಳಲ್ಲಿ ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ಆಯೋಜಿಸುವ ಮತ್ತು ಯೋಜಿಸಿದ ಅನುಭವವನ್ನು ಹೊಂದಿದ್ದಾನೆ.",
            "te": "ఎందుకంటే అతనికి తన డ్యాన్స్ మరియు కళాశాల రోజుల నుండి ఈవెంట్‌లను నిర్వహించడం మరియు ప్రణాళిక చేయడంలో అనుభవం ఉంది.",
            "or": "କାରଣ ତାଙ୍କର ନାଚ ଏବଂ କଲେଜ ଦିନଗୁଡିକରୁ ଇଭେଣ୍ଟ ଆୟୋଜନ ଏବଂ ଯୋଜନା କରିବାର ଅଭିଜ୍ଞତା ଅଛି |",
            "as": "কাৰণ তেওঁৰ নৃত্য আৰু মহাবিদ্যালয়ৰ দিনবোৰৰ অনুষ্ঠান আয়োজন আৰু পৰিকল্পনাৰ অভিজ্ঞতা আছে।",
            "gu": "કારણ કે તેની પાસે તેના નૃત્ય અને કોલેજના દિવસોનો અનુભવ છે કે તે કાર્યક્રમોનું સંચાલન કરો અને આયોજન બનાવે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "because he is good with talking to people and understanding their needs.",
            "hi": "क्योंकि वह लोगों से अच्छी तरह से बात कर सकता है और उनकी जरूरतों को समझ सकता है।",
            "ka": "ಏಕೆಂದರೆ ಅವನು ಜನರೊಂದಿಗೆ ಮಾತನಾಡಲು ಮತ್ತು ಅವರ ಅಗತ್ಯಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಉತ್ತಮನು.",
            "te": "ఎందుకంటే అతను ప్రజలతో మాట్లాడటం మరియు వారి అవసరాలను అర్థం చేసుకోవడంలో నైపుణ్యం ఉన్నవాడు.",
            "or": "କାରଣ ସେ ଲୋକଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବା ଏବଂ ସେମାନଙ୍କର ଆବଶ୍ୟକତା ବୁଝିବାରେ ଭଲ |",
            "as": "কাৰণ তেওঁ মানুহৰ সৈতে কথা পতা আৰু তেওঁলোকৰ প্ৰয়োজনীয়তাবোৰ বুজাত ভাল।",
            "gu": "કારણ કે તે લોકો વિશે સારી રીતે બોલે છે અને તેમની જરૂરિયાતો સમજે છે."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_2_8 (D)",
            "hi": "B4_2_8 (D)",
            "ka": "B4_2_8 (D)",
            "te": "B4_2_8 (D)",
            "or": "B4_2_8 (D)",
            "as": "B4_2_8 (D)",
            "gu": "B4_2_8 (D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Which one should he choose?",
            "hi": "उसे कौन सा विकल्प चुनना चाहिए?",
            "ka": "ಅವನು ಯಾವುದನ್ನು ಆರಿಸಬೇಕು?",
            "te": "అతను ఏది ఎంచుకోవాలి?",
            "or": "ସେ କେଉଁଟି ବାଛିବା ଉଚିତ୍ ?",
            "as": "তেওঁ কোনটো বাছনি কৰা উচিত?",
            "gu": "તેણે શું પસંદ કરવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "A career in Event management",
            "hi": "इवेंट मैनेजमेंट में करियर",
            "ka": "ಈವೆಂಟ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್‌ನಲ್ಲಿ ವೃತ್ತಿಜೀವನ",
            "te": "ఈవెంట్ మేనేజ్‌మెంట్‌లో కెరీర్",
            "or": "ଇଭେଣ୍ଟ ମ୍ୟାନେଜମେଣ୍ଟ୍‌ରେ ଏକ କ୍ଯାରିୟର୍‌",
            "as": "অনুষ্ঠান ব্যৱস্থাপনাৰ ওপৰত এটা জীৱিকা",
            "gu": "ઇવેન્ટ મેનેજમેન્ટમાં કારકિર્દી"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "A career in Sales",
            "hi": "सेल्स में करियर",
            "ka": "ಮಾರಾಟದಲ್ಲಿ ವೃತ್ತಿಜೀವನ",
            "te": "సేల్స్ లో కెరీర్",
            "or": "ସେଲ୍ସରେ ଏକ କ୍ଯାରିୟର୍‌",
            "as": "বিক্ৰীৰ ওপৰত এটা জীৱিকা",
            "gu": "વેચાણમાં કારકિર્દી"
          }
        }
      ]
    },
    "scene8": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_1_1_Career in Event Management",
            "hi": "इवेंट मैनेजमेंट में करियर",
            "ka": "B5_1_1_ಈವೆಂಟ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್‌ನಲ್ಲಿ ವೃತ್ತಿ",
            "te": "B5_1_1_ఈవెంట్ మేనేజ్‌మెంట్‌లో కెరీర్",
            "or": "B5_1_1_Career in Event Management",
            "as": "B5_1_1_অনুষ্ঠান ব্যৱস্থাপনাৰ ওপৰত জীৱিকা",
            "gu": "B5_1_1_ઇવેન્ટ મેનેજમેન્ટમાં કારકિર્દી"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Through the placements on campus, Naveen joins an event management company as an event coordinator.",
            "hi": "कैम्पस के प्लेसमेंट के जरिए, नवीन एक इवेंट मैनेजमेंट कंपनी में इवेंट कोऑर्डिनेंटर के तौर पर काम करना शुरू कर देता है।",
            "ka": "ಕ್ಯಾಂಪಸ್‌ನಲ್ಲಿ ಪ್ಲೇಸ್‌ಮೆಂಟ್‌ಗಳ ಮೂಲಕ, ನವೀನ್ ಈವೆಂಟ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಕಂಪನಿಗೆ ಈವೆಂಟ್ ಸಂಯೋಜಕರಾಗಿ ಸೇರುತ್ತಾನೆ.",
            "te": "క్యాంపస్‌లో ప్లేస్‌మెంట్స్ ద్వారా, నవీన్ ఈవెంట్ మేనేజ్‌మెంట్ కంపెనీలో ఈవెంట్ కోఆర్డినేటర్‌గా చేరాడు.",
            "or": "କ୍ୟାମ୍ପସରେ ପ୍ଲେସମେଣ୍ଟ୍‍ ମାଧ୍ୟମରେ ନବୀନ ଏକ ଇଭେଣ୍ଟ ସଂଯୋଜକ ଭାବରେ ଏକ ଇଭେଣ୍ଟ ମ୍ୟାନେଜ୍‌ମେଣ୍ଟ୍‌ କମ୍ପାନୀରେ ଯୋଗ ଦିଅନ୍ତି |",
            "as": "চৌহদত প্লেচমেণ্টৰ জৰিয়তে, নবীনে অনুষ্ঠান সমন্বয়ক হিচাপে এটা অনুষ্ঠান ব্যৱস্থাপনা কোম্পানীত যোগদান কৰে।",
            "gu": "કેમ્પસમાં પ્લેસમેન્ટ દ્વારા, નવીન ઇવેન્ટ મેનેજમેન્ટ કંપનીમાં ઇવેન્ટ સંકલનકાર તરીકે જોડાય છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_1_2",
            "hi": "B5_1_2",
            "ka": "B5_1_2",
            "te": "B5_1_2",
            "or": "B5_1_2",
            "as": "B5_1_2",
            "gu": "B5_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The salary is not very good, but since the industry is trending, he feels there will be scope for him  to grow in his career.",
            "hi": "उसे अच्छी सैलरी नहीं मिलती, लेकिन चूँकि यह इंडस्ट्री अभी उभर रही है, तो उसे लगता है कि उसे इस करियर में आगे बढ़ने के कई अवसर प्राप्त होंगे।",
            "ka": "ಸಂಬಳ ತುಂಬಾ ಚೆನ್ನಾಗಿಲ್ಲ, ಆದರೆ ಉದ್ಯಮವು ಟ್ರೆಂಡಿಂಗ್ ಆಗಿರುವುದರಿಂದ, ಅವನ ವೃತ್ತಿಜೀವನದಲ್ಲಿ ಬೆಳೆಯಲು ಅವಕಾಶವಿದೆ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ.",
            "te": "జీతం అంత ఎక్కువ ఉండదు, కానీ ఇండస్ట్రీ ట్రెండింగ్‌లో ఉన్నందున, తన కెరీర్‌లో ఎదగడానికి అవకాశం ఉంటుందని అతను అనుకుంటున్నాడు.",
            "or": "ଦରମା ବହୁତ ଭଲ ନୁହେଁ, କିନ୍ତୁ ଇଣ୍ଡଷ୍ଟ୍ରି ଟ୍ରେଣ୍ଡ ହୋଇଥିବାରୁ ସେ ତାଙ୍କ କ୍ୟାରିୟରରେ ବଢିବାର ସୁଯୋଗ ପାଇବେ ବୋଲି ସେ ଅନୁଭବ କରୁଛନ୍ତି |",
            "as": "দৰমহা খুব এটা ভাল নহয়, কিন্তু যিহেতু উদ্যোগটো ট্ৰেণ্ডিং হৈ আছে, সি ভাবিলে যে তাৰ জীৱিকা বিকাশৰ সুযোগ থাকিব।",
            "gu": "વેતન એટલું સારું નથી, પરંતુ તે વિચારે છે કે તે એક ઉદ્યોગ વલણ છે, કારકિર્દી વૃદ્ધિ માટે એક સ્થાન છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_1_3",
            "hi": "B5_1_3",
            "ka": "B5_1_3",
            "te": "B5_1_3",
            "or": "B5_1_3",
            "as": "B5_1_3",
            "gu": "B5_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "He is made to sign an employment contract before joining. In the excitement of getting the job, he signs it without reading it correctly.",
            "hi": "उसे काम करना शुरू करने से पहले एम्प्लोयी कॉन्ट्रेक्ट पर साइन करने के लिए कहा जाता है।",
            "ka": "ಸೇರುವ ಮೊದಲು ಉದ್ಯೋಗ ಒಪ್ಪಂದಕ್ಕೆ ಸಹಿ ಹಾಕುವಂತೆ ಮಾಡಲಾಗುತ್ತದೆ. ಕೆಲಸ ಸಿಕ್ಕಿದ ಸಂಭ್ರಮದಲ್ಲಿ ಸರಿಯಾಗಿ ಓದದೇ ಸಹಿ ಹಾಕುತ್ತಾನೆ.",
            "te": "అతను చేరడానికి ముందు ఉద్యోగం కాంట్రాక్ట్ పై సంతకం చేసాడు. ఉద్యోగం వచ్చిందన్న ఉత్సాహంలో సరిగ్గా చదవకుండా సంతకం పెట్టేస్తాడు.",
            "or": "ଯୋଗଦେବା ପୂର୍ବରୁ ତାଙ୍କୁ ଏକ ନିଯୁକ୍ତି ଚୁକ୍ତି ସ୍ୱାକ୍ଷର କରାଯାଏ | ଚାକିରି ପାଇବା ଉତ୍ସାହରେ, ସେ ଏହାକୁ ସଠିକ୍ ପଢି ନପାରି ଦସ୍ତଖତ କରନ୍ତି |",
            "as": "যোগদান কৰাৰ আগতে সি এখন নিযুক্তি চুক্তিপত্ৰত স্বাক্ষৰ কৰিব লগীয়া হ’ল। চাকৰিটো পোৱাৰ ফুৰ্তিতে,সি ভালকৈ একো নপঢ়াকৈয়ে স্বাক্ষৰ কৰি দিলে।",
            "gu": "અંદર જોડતા પહેલા તેને રોજગાર કરાર પર હસ્તાક્ષર કરવા માટે બનાવવામાં આવે છે. નોકરી મેળવવાની આવેશમાં, તે તેને યોગ્ય રીતે વાંચ્યા વિના સહી કરે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_2_1",
            "hi": "B5_2_1",
            "ka": "B5_2_1",
            "te": "B5_2_1",
            "or": "B5_2_1",
            "as": "B5_2_1",
            "gu": "B5_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The company handles a variety of events like corporate events, weddings, fashion shows and music concerts.",
            "hi": "कंपनी में कॉर्पोरेट इवेंट, शादियाँ, फैशन शो और म्यूजिक कॉन्सर्ट जैसे कई तरह के इवेंट आयोजित किए जाते हैं।",
            "ka": "ಕಂಪನಿಯು ಕಾರ್ಪೊರೇಟ್ ಈವೆಂಟ್‌ಗಳು, ಮದುವೆಗಳು, ಫ್ಯಾಶನ್ ಶೋಗಳು ಮತ್ತು ಸಂಗೀತ ಕಚೇರಿಗಳಂತಹ ವಿವಿಧ ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ನಿರ್ವಹಿಸುತ್ತದೆ.",
            "te": "కంపెనీ కార్పొరేట్ ఈవెంట్‌లు, వివాహాలు, ఫ్యాషన్ షోలు మరియు సంగీత కచేరీలు వంటి వేర్వేరు ఈవెంట్‌లను నిర్వహిస్తుంది.",
            "or": "କର୍ପୋରେଟ୍ ଇଭେଣ୍ଟ, ବିବାହ, ଫ୍ୟାଶନ୍ ସୋ ଏବଂ ମ୍ୟୁଜିକ୍ କନ୍ସର୍ଟ ପରି କମ୍ପାନୀ ବିଭିନ୍ନ ଇଭେଣ୍ଟ ପରିଚାଳନା କରେ |",
            "as": "কোম্পানীটোৱে বিভিন্ন অনুষ্ঠান যেনে কৰ্পোৰেট অনুষ্ঠান, বিবাহ, ফেশ্বন শ্ব' আৰু সংগীতানুষ্ঠান আদি পৰিচালনা কৰে।",
            "gu": "કંપની કોર્પોરેટ ઈવેન્ટ્સ, લગ્નો, ફેશન કાર્યકરો અને મ્યુઝિક કોન્સર્ટ જેવી વિવિધ ઈવેન્ટ્સનું આયોજન કરે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_2_2",
            "hi": "B5_2_2",
            "ka": "B5_2_2",
            "te": "B5_2_2",
            "or": "B5_2_2",
            "as": "B5_2_2",
            "gu": "B5_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "But since Naveen is young and new at his job, he is asked to do tasks like get posters printed or get tea for his seniors.",
            "hi": "लेकिन चूँकि नवीन उम्र में छोटा है और जॉब पर नया है, उसे पोस्टर प्रिंट करना या उसके सीनियर्स के लिए चाय बनाना जैसे काम दिए जाते हैं।",
            "ka": "ಆದರೆ ನವೀನ್ ಚಿಕ್ಕವನಾಗಿದ್ದು ತನ್ನ ಕೆಲಸಕ್ಕೆ ಹೊಸಬನಾಗಿರುವುದರಿಂದ ಪೋಸ್ಟರ್‌ಗಳನ್ನು ಪ್ರಿಂಟ್ ಮಾಡಿಸುವುದು ಅಥವಾ ಹಿರಿಯರಿಗೆ ಟೀ ತರಿಸುವುದು ಮುಂತಾದ ಕೆಲಸಗಳನ್ನು ಮಾಡುವಂತೆ ಕೇಳಿಕೊಳ್ಳುತ್ತಾರೆ.",
            "te": "కానీ నవీన్ చిన్నవాడు మరియు అతని ఉద్యోగంలో కొత్తవాడు కాబట్టి, పోస్టర్లు ప్రింట్ చేయడం లేదా తన సీనియర్లకు టీ ఇప్పించడం వంటి పనులు చేయమని అడిగారు.",
            "or": "କିନ୍ତୁ ନବୀନ ତାଙ୍କ ଚାକିରିରେ ଯୁବ ଏବଂ ନୂତନ ହୋଇଥିବାରୁ ତାଙ୍କୁ ପୋଷ୍ଟର ଛାପିବା କିମ୍ବା ତାଙ୍କ ସିନିୟରମାନଙ୍କ ପାଇଁ ଚା ଆଣିବା ଭଳି କାର୍ଯ୍ୟ କରିବାକୁ କୁହାଯାଏ |",
            "as": "কিন্তু যিহেতু নবীন সৰু আৰু চাকৰিটোত নতুন, তাক পোষ্টাৰ ছপা কৰা বা জ্যেষ্ঠসকলৰ বাবে চাহ অনাৰ দৰে কামহে কৰিবলৈ কোৱা হয়।",
            "gu": "પરંતુ નવીન તેની નોકરીમાં યુવાન અને નવો હોવાથી, તેને પોસ્ટર છપાવવા અથવા તેના વરિષ્ઠ માટે ચા લેવા જેવા કાર્યો કરવા માટે કહેવામાં આવે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_2_3",
            "hi": "B5_2_3",
            "ka": "B5_2_3",
            "te": "B5_2_3",
            "or": "B5_2_3",
            "as": "B5_2_3",
            "gu": "B5_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen is patient and hopes he will get work where he can do more.",
            "hi": "नवीन के पास काफी धीरज है और वह आशा करता है कि उसे ऐसा काम जरुर मिलेगा जहाँ वह और कुछ कर सकें।",
            "ka": "ನವೀನ್ ತಾಳ್ಮೆಯಿಂದಿದ್ದು, ಎಲ್ಲಿ ಹೆಚ್ಚು ಕೆಲಸ ಮಾಡಬಹುದೋ ಅಲ್ಲಿ ಕೆಲಸ ಸಿಗುತ್ತದೆ ಎಂದು ಆಶಿಸಿದ್ದಾನೆ.",
            "te": "నవీన్ ఓపికగా ఉన్నాడు మరియు అతను ఇంకా చేస్తే అతనికి పని వస్తుందని అనుకుంటాడు.",
            "or": "ନବୀନ ଧୈର୍ଯ୍ୟବାନ ଏବଂ ସେ ଆଶା କରନ୍ତି ଯେ ସେ କାମ ପାଇବେ ଯେଉଁଠାରେ ସେ ଅଧିକ କାମ କରିପାରିବେ |",
            "as": "নবীনে আশা কৰে যে সি ভাল কাম পাব আৰু সেয়েহে ধৈৰ্য্য ধৰি থাকে ৷",
            "gu": "નવીન ધીરજ રાખે છે અને આશા રાખે છે કે જ્યાં તે વધુ કરી શકે ત્યાં તેને કામ મળશે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_2_4",
            "hi": "B5_2_4",
            "ka": "B5_2_4",
            "te": "B5_2_4",
            "or": "B5_2_4",
            "as": "B5_2_4",
            "gu": "B5_2_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "After 6 months of working, his condition hasn’t changed much. He isn’t learning much on the job either.",
            "hi": "6 महीने काम करने के बाद भी, उसकी स्थिति में कुछ बदलाव नहीं हुआ है। उसे काम पर कुछ सीखने भी नहीं मिल रहा।",
            "ka": "6 ತಿಂಗಳು ಕೆಲಸ ಮಾಡಿದರೂ ಅವನ ಸ್ಥಿತಿ ಹೆಚ್ಚು ಬದಲಾಗಿಲ್ಲ. ಅವನೂ ಕೆಲಸದಲ್ಲಿ ಹೆಚ್ಚು ಕಲಿಯುತ್ತಿಲ್ಲ.",
            "te": "6 నెలలు పనిచేసినా అతని పరిస్థితిలో పెద్దగా మార్పు రాలేదు. అతను ఉద్యోగంలో కూడా పెద్దగా నేర్చుకోలేదు.",
            "or": "6 ମାସ କାମ କରିବା ପରେ, ତାଙ୍କ ଅବସ୍ଥା ଅଧିକ ପରିବର୍ତ୍ତନ ହୋଇନାହିଁ | ସେ ଚାକିରିରେ ମଧ୍ୟ ଅଧିକ କିଛି ଶିଖୁନାହାଁନ୍ତି |",
            "as": "6 মাহ কাম কৰাৰ পিছতো তাৰ অৱস্থাৰ বিশেষ পৰিৱৰ্তন নহ’ল ৷ সি কামটোৰপৰা বৰ বেছি একো শিকিবও পোৱা নাই।",
            "gu": "6 મહિના કામ કર્યા પછી પણ તેની સ્થિતિમાં ખાસ બદલાવ આવ્યો નથી. તે નોકરી પર પણ વધુ શીખતો નથી."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_3_1",
            "hi": "B5_3_1",
            "ka": "B5_3_1",
            "te": "B5_3_1",
            "or": "B5_3_1",
            "as": "B5_3_1",
            "gu": "B5_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He decides to leave the company and look for other opportunities. But when he brings up the matter with his Human Resource Department, he is told that he cannot resign.",
            "hi": "वह यह कंपनी छोड़कर किसी और आगाह कोशिश करने के बारे में सोचता है। लेकिन जब वह यह बात ह्यूमन रिसोर्स विभाग में बताता है तब उसे कहा जाता है कि वह इस्तीफा नहीं दे सकता।",
            "ka": "ಅವನು ಕಂಪನಿಯನ್ನು ತೊರೆದು ಇತರ ಅವಕಾಶಗಳನ್ನು ಹುಡುಕಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಆದರೆ ಅವನು ತಮ್ಮ ಮಾನವ ಸಂಪನ್ಮೂಲ ಇಲಾಖೆಯೊಂದಿಗೆ ವಿಷಯವನ್ನು ತಂದಾಗ, ಅವನು ರಾಜೀನಾಮೆ ನೀಡಲು ಸಾಧ್ಯವಿಲ್ಲ ಎಂದು ಹೇಳಿದರು.",
            "te": "అతను కంపెనీని వదిలి ఇతర అవకాశాల కోసం వెతకాలని నిర్ణయించుకున్నాడు. అయితే ఈ విషయాన్ని ఆయన తన మానవ వనరుల శాఖకు తెలియజేసినప్పుడు, అతను రాజీనామా చేయలేవని చెప్పారు.",
            "or": "ସେ କମ୍ପାନୀ ଛାଡି ଅନ୍ୟ ସୁଯୋଗ ଖୋଜିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | । କିନ୍ତୁ ଯେତେବେଳେ ସେ ତାଙ୍କ ମାନବ ସମ୍ବଳ ବିଭାଗ ସହିତ ଏହି ମାମଲା ଆଣନ୍ତି, ସେତେବେଳେ ତାଙ୍କୁ କୁହାଯାଇଛି ଯେ ସେ ଇସ୍ତଫା ଦେଇ ପାରିବେ ନାହିଁ।",
            "as": "সি কোম্পানীটো এৰি দি আন সুযোগ বিচাৰিবলৈ সিদ্ধান্ত লয়। কিন্তু যেতিয়া সি বিষয়টো তাৰ মানৱ সম্পদ বিভাগৰ সৈতে আলোচনা কৰিলে, তেতিয়া তাক কোৱা হ’ল যে সি পদত্যাগ কৰিব নোৱাৰে।",
            "gu": "એ કંપની છોડવા માગે છે અને બીજી તકો જોવે છે. પરંતુ જ્યારે તે આ મામલો તેમના માનવ સંસાધન વિભાગ સમક્ષ લાવે છે, ત્યારે તેમને કહેવામાં આવે છે કે તેઓ રાજીનામું આપી શકતા નથી."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_3_2//",
            "hi": "B5_3_2//",
            "ka": "B5_3_2//",
            "te": "B5_3_2//",
            "or": "B5_3_2//",
            "as": "B5_3_2//",
            "gu": "B5_3_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "HR",
            "hi": "एचआर",
            "ka": "ಎಚ್ ಆರ್",
            "te": "HR",
            "or": "HR",
            "as": "HR",
            "gu": "HR"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.label",
          "value": {
            "en": "HR",
            "hi": "एचआर",
            "ka": "ಎಚ್ ಆರ್",
            "te": "HR",
            "or": "HR",
            "as": "HR",
            "gu": "HR"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "What do you mean I can’t resign?",
            "hi": "क्या मतलब मैं इस्तीफा नहीं दे सकता?",
            "ka": "ನಾನು ರಾಜೀನಾಮೆ ನೀಡಲು ಸಾಧ್ಯವಿಲ್ಲ ಎಂದು ನೀವು ಏನು ಹೇಳುತ್ತೀರಿ?",
            "te": "నేను రాజీనామా ఎందుకు చేయలేను?",
            "or": "ଆପଣ କ’ଣ କହିବାକୁ ଚାହୁଁଛନ୍ତି ମୁଁ ଇସ୍ତଫା ଦେଇ ପାରିବି ନାହିଁ ?",
            "as": "মই পদত্যাগ কৰিব নোৱাৰো বুলি আপুনি কি বুজাব বিচাৰিছে?",
            "gu": "તમે એવું કહેવા માગો છે કે હું નોકરી ના છોડી શકું?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "You signed the employee contract while joining, and it says that you’re contracted to work for at least 1 year before you can leave.",
            "hi": "तुमने काम शुरू करने से पहले एम्प्लोयी कॉन्ट्रेक्ट पर साइन किया है और इसके अनुसार तुम्हें यह नौकरी छोड़ने से पहले कम से कम 1 साल तक काम करना पड़ेगा।",
            "ka": "ಸೇರುವಾಗ ನೀವು ಉದ್ಯೋಗಿ ಒಪ್ಪಂದಕ್ಕೆ ಸಹಿ ಹಾಕಿದ್ದೀರಿ ಮತ್ತು ನೀವು ಹೊರಡುವ ಮೊದಲು ಕನಿಷ್ಠ 1 ವರ್ಷ ಕೆಲಸ ಮಾಡಲು ನೀವು ಒಪ್ಪಂದ ಮಾಡಿಕೊಂಡಿದ್ದೀರಿ ಎಂದು ಅದು ಹೇಳುತ್ತದೆ.",
            "te": "మీరు రాజీనామా చేయడానికి ముందు కనీసం సంవత్సరం పాటు పని చేయడానికి మీరు సంతకం చేసారని అది చెబుతోంది.",
            "or": "ଯୋଗଦେବା ସମୟରେ ଆପଣ କର୍ମଚାରୀଙ୍କ ଚୁକ୍ତିନାମା ସ୍ୱାକ୍ଷର କରିଛନ୍ତି, ଏବଂ ଏହା କୁହେ ଯେ ଆପଣ ଛାଡିବା ପୂର୍ବରୁ ଅତି କମରେ 1 ବର୍ଷ କାମ କରିବାକୁ ଚୁକ୍ତି କରିଛନ୍ତି |",
            "as": "তুমি যোগদান কৰাৰ সময়ত যিখন কৰ্মচাৰীৰ চুক্তিপত্ৰত স্বাক্ষৰ কৰিছিলা, তাত কোৱা হৈছে যে তুমি যোৱাৰ আগতে কমেও 1 বছৰৰ বাবে কাম কৰিবলৈ চুক্তিবদ্ধ হৈছা।",
            "gu": "જ્યારે તમે જોડાયા હતા એ વખતે તમે કર્મચારી કરાર પર હસ્તાક્ષર કર્યા હતા અને તેમાં એમ હોય છે કે તમે નોકરી 1 વર્ષ પહેલા ના છોડી શકો કારણ કે તમે કરાર કયો છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "But you had said that it is just standard procedure and there is nothing different in the contract. So I hadn’t read the entire agreement properly.",
            "hi": "लेकिन आपने कहा था कि यह बस स्टैण्डर्ड प्रोसीजर है और इस कॉन्ट्रेक्ट में कुछ अलग बात नहीं है। इस लिए मैंने यह अग्रीमेंट ठीक से नहीं पढ़ा था।",
            "ka": "ಆದರೆ ಇದು ಕೇವಲ ಪ್ರಮಾಣಿತ ಕಾರ್ಯವಿಧಾನವಾಗಿದೆ ಮತ್ತು ಒಪ್ಪಂದದಲ್ಲಿ ಯಾವುದೇ ವ್ಯತ್ಯಾಸವಿಲ್ಲ ಎಂದು ನೀವು ಹೇಳಿದ್ದೀರಿ. ಹಾಗಾಗಿ ನಾನು ಸಂಪೂರ್ಣ ಒಪ್ಪಂದವನ್ನು ಸರಿಯಾಗಿ ಓದಿರಲಿಲ್ಲ.",
            "te": "కానీ ఇది కేవలం సాధారణ ప్రక్రియ అని, కాంట్రాక్ట్ లో తేడా ఏమీ లేదని మీరు చెప్పారు. కాబట్టి నేను పూర్తి కాంట్రాక్ట్ సరిగ్గా చదవలేదు.",
            "or": "କିନ୍ତୁ ଆପଣ କହିଥିଲ ଯେ ଏହା କେବଳ ମାନକ ପ୍ରଣାଳୀ ଏବଂ ଚୁକ୍ତିରେ କିଛି ଅଲଗା ନୁହେଁ | ତେଣୁ ମୁଁ ସମ୍ପୂର୍ଣ୍ଣ ଚୁକ୍ତିନାମାକୁ ସଠିକ୍ ଭାବରେ ପଢିନାହିଁ |",
            "as": "কিন্তু আপুনি কৈছিল যে এইটো কেৱল এটা মান প্ৰক্ৰিয়া আৰু চুক্তিত বেলেগ একো নাই। গতিকে মই গোটেই চুক্তিখন সঠিকভাৱে নপঢ়িলো।",
            "gu": "પરંતુ તમે કહ્યું કે તે માત્ર એક સામાન્ય પ્રક્રિયા છે અને કરારમાં બીજું કંઈ નથી. તેથી મેં આખો કરાર વાંચ્યો નો હતો."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.text",
          "value": {
            "en": "This is standard for our company Naveen. We spend so many resources to train new employees. Hence we expect them to stay with us for at least a year.",
            "hi": "यह हमारी कंपनी के लिए स्टैण्डर्ड ही है, नवीन। हम नए कमर्चारियों को प्रशिक्षित करने में कितने संसाधन खर्च करते हैं। इसलिए हम उम्मीद करते हैं कि वे हमारे साथ कम से कम एक साल तक तो रहें।",
            "ka": "ಇದು ನಮ್ಮ ಕಂಪನಿ ನವೀನ್‌ಗೆ ಮಾನದಂಡವಾಗಿದೆ. ಹೊಸ ಉದ್ಯೋಗಿಗಳಿಗೆ ತರಬೇತಿ ನೀಡಲು ನಾವು ಸಾಕಷ್ಟು ಸಂಪನ್ಮೂಲಗಳನ್ನು ವ್ಯಯಿಸುತ್ತೇವೆ. ಆದ್ದರಿಂದ ಅವರು ಕನಿಷ್ಠ ಒಂದು ವರ್ಷ ನಮ್ಮೊಂದಿಗೆ ಇರಬೇಕೆಂದು ನಾವು ನಿರೀಕ್ಷಿಸುತ್ತೇವೆ.",
            "te": "మన కంపెనీ‌కి ఇది సాధారణం నవీన్. కొత్త ఉద్యోగులకు ట్రైనింగ్ ఇవ్వడానికి మనం చాలా వనరులు ఉపయోగిస్తాము. అందువల్ల వారు కనీసం ఒక సంవత్సరం పాటు మాతో ఉండాలని మేము కోరుకుంటాము.",
            "or": "ଏହା ଆମ କମ୍ପାନୀ ପାଇଁ ମାନକ ଅଟେ ନବୀନ | ନୂତନ କର୍ମଚାରୀମାନଙ୍କୁ ତାଲିମ ଦେବା ପାଇଁ ଆମେ ଅନେକ ଉତ୍ସ ଖର୍ଚ୍ଚ କରିଥାଉ | ତେଣୁ ଆମେ ଆଶା କରୁ ସେମାନେ ଅତି କମରେ ଏକ ବର୍ଷ ଆମ ସହିତ ରହିବେ |",
            "as": "নবীন এইটো আমাৰ কোম্পানীৰ এটা মানদণ্ড। আমি নতুন কৰ্মচাৰীসকলক প্ৰশিক্ষণ দিবলৈ বহুতো সম্পদ ব্যয় কৰোঁ। সেয়েহে তেওঁলোকে কমেও এবছৰ আমাৰ সৈতে থকাতো আমি বিচাৰোঁ।",
            "gu": "નવીન આ કંપની પ્રમાણભૂત છે. અમે ગણા કારીગરોને સિખવાડવા માટે ઘણાં સાધન સામગ્રી સમર્પિત કરીએ છીએ. તેથી તેઓ ઓછામાં ઓછા એક વર્ષ સુધી અમારી સાથે રહે એવી અપેક્ષા રાખીએ છીએ."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_4_1",
            "hi": "B5_4_1",
            "ka": "B5_4_1",
            "te": "B5_4_1",
            "or": "B5_4_1",
            "as": "B5_4_1",
            "gu": "B5_4_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen feels disappointed with himself for not being careful about signing the contract. He reaches out to Mrs Bansal because he doesn’t know who else to talk to.",
            "hi": "नवीन खुद से बहुत निराश हो जाता है कि उसने कॉन्ट्रेक्ट पर साइन करने से पहले उसे ठीक से क्यों नहीं पढ़ा।वह श्रीमती बंसल के जाकर मिलता है क्योंकि उसे पता नहीं और किस से इसके बारे में बात की जा सकती है।",
            "ka": "ಒಪ್ಪಂದಕ್ಕೆ ಸಹಿ ಹಾಕುವ ಬಗ್ಗೆ ಎಚ್ಚರ ವಹಿಸದಿದ್ದಕ್ಕಾಗಿ ನವೀನ್ ತನ್ನ ಬಗ್ಗೆ ನಿರಾಶೆಗೊಂಡಿದ್ದಾನೆ. ಅವನು ಶ್ರೀಮತಿ ಬನ್ಸಾಲ್ ಅವರನ್ನು ತಲುಪುತ್ತಾನೆ ಏಕೆಂದರೆ ಬೇರೆ ಯಾರೊಂದಿಗೆ ಮಾತನಾಡಬೇಕೆಂದು ಅವನಿಗೆ ತಿಳಿದಿಲ್ಲ.",
            "te": "ఒప్పందంపై సంతకం చేయడంలో జాగ్రత్త వహించనందుకు నవీన్ తనపై తాను నిరాశకు గురయ్యాడు. ఇంకెవరితో మాట్లాడాలో తెలియక శ్రీమతి బన్సాల్‌ను సంప్రదించాడు.",
            "or": "ଚୁକ୍ତିନାମା ସ୍ୱାକ୍ଷର ବିଷୟରେ ସାବଧାନ ନହେବା ପାଇଁ ନବୀନ ନିଜ ଉପରେ ନିରାଶ ଅନୁଭବ କରନ୍ତି | ସେ ଶ୍ରୀମତୀ ବନସଲ୍‌ଙ୍କ ନିକଟରେ ପହଂଚିଛନ୍ତି କାରଣ ସେ ଆଉ କାହା ସହିତ କଥା ହେବେ ତାହା ଜାଣି ନାହାଁନ୍ତି |",
            "as": "চুক্তিত স্বাক্ষৰ কৰাৰ ক্ষেত্ৰত সাৱধান নোহোৱাৰ বাবে নবীন হতাশ হৈ পৰে। সি শ্ৰীমতী বনচালৰ ওচৰলৈ যায় কাৰণ বেলেগ কাৰ সৈতে কথা পাতিব লাগে সি নাজানে।",
            "gu": "નવીન પોતાના પર નિરાશા અનુભવે છે કારણ કે તેને કરાર પર હસ્તાક્ષર કરવામાં સાવચેતી ન રાખી હતી. તે બીજા કોની સાથે વાત કરવી તે જાણતો નથી એટલે શ્રીમતી બંસલનો સંપર્ક કરે છે."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_4_2",
            "hi": "B5_4_2",
            "ka": "B5_4_2",
            "te": "B5_4_2",
            "or": "B5_4_2",
            "as": "B5_4_2",
            "gu": "B5_4_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Over a call, he tells her his situation. Mrs Bansal is highly sympathetic but also motivates him to see the opportunities available to him.",
            "hi": "वह फोन पर उन्हें इस सब के बारे में बताता है। श्रीमती बंसल उससे सहानुभूति जताती हैं पर साथ ही उसे प्रेरित भी करती हैं कि वह देखें कि उसके लिए कौन से अवसर उपलब्ध हैं।",
            "ka": "ಒಂದು ಕರೆಯಲ್ಲಿ, ಅವನು ತನ್ನ ಪರಿಸ್ಥಿತಿಯನ್ನು ಅವರಿಗೆ ಹೇಳುತ್ತಾನೆ. ಶ್ರೀಮತಿ ಬನ್ಸಾಲ್ ಅವರು ಹೆಚ್ಚು ಸಹಾನುಭೂತಿ ಹೊಂದಿದ್ದಾರೆ ಆದರೆ ಅನಿಗೆ ಲಭ್ಯವಿರುವ ಅವಕಾಶಗಳನ್ನು ನೋಡಲು ಅವನನ್ನು ಪ್ರೇರೇಪಿಸುತ್ತಾರೆ.",
            "te": "ఒక కాల్ ద్వారా, అతను తన పరిస్థితిని ఆమెకు చెప్పాడు. శ్రీమతి బన్సాల్ చాలా సానుభూతిపరురాలు, కానీ అతనికి అందుబాటులో ఉన్న అవకాశాలను చూసేందుకు అతనికి స్ఫూర్తిని ఇస్తుంది.",
            "or": "ଏକ କଲ୍ ଉପରେ, ସେ ତାଙ୍କୁ ତାଙ୍କ ଅବସ୍ଥା ବିଷୟରେ କୁହନ୍ତି | ଶ୍ରୀମତୀ ବନସଲ୍‌ ଅତ୍ୟନ୍ତ ସହାନୁଭୂତିଶୀଳ କିନ୍ତୁ ତାଙ୍କ ପାଇଁ ଉପଲବ୍ଧ ସୁଯୋଗଗୁଡିକ ଦେଖିବା ପାଇଁ ତାଙ୍କୁ ପ୍ରେରଣା ଦିଅନ୍ତି |",
            "as": "এটা ফোনকলৰ জৰিয়তে, সি তেখেতক তাৰ পৰিস্থিতিটো কয়। শ্ৰীমতী বনচালে সহানুভূতি জনালে কিন্তু লগতে তেওঁক বেলেগ সুযোগবোৰ চাবলৈও অনুপ্ৰাণিত কৰে।",
            "gu": "તે વાત કરે છે એક કોલ પર, તે તેણીને તેની પરિસ્થિતિ જણાવે છે. શ્રીમતી બંસલ ખૂબ જ સહાનુભૂતિશીલ બને છે પરંતુ તેમને તેમની માટે હાજર તકો જોવા માટે પણ પ્રેરિત કરે છે."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_4_3//",
            "hi": "B5_4_3//",
            "ka": "B5_4_3//",
            "te": "B5_4_3//",
            "or": "B5_4_3//",
            "as": "B5_4_3//",
            "gu": "B5_4_3//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Mrs Bansal",
            "hi": "श्रीमती बंसल",
            "ka": "ಶ್ರೀಮತಿ ಬನ್ಸಾಲ್",
            "te": "శ్రీమతి బన్సాల్",
            "or": "ଶ୍ରୀମତୀ ବନସଲ୍‌",
            "as": "শ্ৰীমতী বনচাল",
            "gu": "શ્રીમતી બંસલ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Ma’am, I’m feeling embarrassed. How could I put myself in a situation like this? I will have to continue working for the next 6 months in a job that I don't like.",
            "hi": "मैम, मुझे तो बहुत शर्म आ रही है। मैं ऐसे हालात में कैसे फंस सकता हूँ? अब मुझे ऐसी नौकरी और 6 महीने करनी पड़ेगी जो मुझे पसंद भी नहीं है।",
            "ka": "ಮೇಡಂ, ನನಗೆ ಮುಜುಗರವಾಗುತ್ತಿದೆ. ಅಂತಹ ಪರಿಸ್ಥಿತಿಯಲ್ಲಿ ನಾನು ನನ್ನನ್ನು ಹೇಗೆ ಹಾಕಿಕೊಳ್ಳಬಹುದು? ನನಗೆ ಇಷ್ಟವಿಲ್ಲದ ಕೆಲಸದಲ್ಲಿ ಮುಂದಿನ 6 ತಿಂಗಳು ಕೆಲಸ ಮಾಡುತ್ತಲೇ ಇರಬೇಕಾಗುತ್ತದೆ.",
            "te": "మేడమ్, నేను ఇబ్బందిగా ఫీల్ అవుతున్నాను. నేను ఇలాంటి పరిస్థితిలో ఎలా ఉన్నాను? నాకు నచ్చని ఉద్యోగంలో ఇంకో 6 నెలలు నేను ఎలా పని చేస్తాను.",
            "or": "ମ୍ୟାଡାମ୍‍, ମୁଁ ଲଜ୍ଜିତ ଅନୁଭବ କରୁଛି | ମୁଁ ନିଜକୁ ଏହି ପରି ପରିସ୍ଥିତିରେ କିପରି ରଖି୍ଲି? ମୋତେ ଆଗାମୀ 6 ମାସ ପାଇଁ ଏକ ଚାକିରିରେ କାର୍ଯ୍ୟ ଜାରି ରଖିବାକୁ ପଡିବ ଯାହା ମୋତେ ପସନ୍ଦ ନାହିଁ |",
            "as": "মেডাম, মই লাজ পাইছোঁ। মই কেনেকৈ নিজকে এনে পৰিস্থিতিত পেলাব পাৰোঁ? মই ভাল নোপোৱা চাকৰি এটাত পৰৱৰ্তী ছয়মাহৰ বাবে কাম কৰি থাকিব লাগিব।",
            "gu": "મેડમ મને શરમ આવે છે. હું મારી જાત ને કેવી રીતે આવી પરિસ્થિતિમાં મૂકું? મારે આવનારા 6 મહિના સુધી હજુ કામ કરવું પડશે આ કામમાં જેમાં મને રસ નથી."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Okay, Naveen, let’s take a deep breath. Can we do anything to change this situation?",
            "hi": "ठीक है, नवीन, पहले गहरी साँस लो। अब सोचो क्या तुम इस स्थिति को बदलने के लिए कुछ कर सकते हो या नहीं?",
            "ka": "ಸರಿ, ನವೀನ್, ನಾವು ಆಳವಾದ ಉಸಿರನ್ನು ತೆಗೆದುಕೊಳ್ಳೋಣ. ಈ ಪರಿಸ್ಥಿತಿಯನ್ನು ಬದಲಾಯಿಸಲು ನಾವು ಏನಾದರೂ ಮಾಡಬಹುದೇ?",
            "te": "సరే, నవీన్, కాస్త ఊపిరి పీల్చుకుందాం. ఈ పరిస్థితిని మార్చడానికి మనం ఏమైనా చేయగలమా?",
            "or": "ଠିକ ଅଛି, ନବୀନ, ଚାଲ ଏକ ଦୀର୍ଘ ନିଶ୍ୱାସ ନେବା | ଏହି ପରିସ୍ଥିତିକୁ ପରିବର୍ତ୍ତନ କରିବା ପାଇଁ ଆମେ କିଛି କରିପାରିବା କି ?",
            "as": "ঠিক আছে, নবীন, এটা দীঘলীয়া উশাহ লওঁ আহা। আমি এই পৰিস্থিতিটো সলনি কৰিবলৈ কিবা কৰিব পাৰোঁনে?",
            "gu": "ચાલ નવીન ઊંડા સ્વસ લે. શું આપણે કઈ એવું કરી શકીએ જેના લીધે સ્થિતિ બદલાઈ જાય?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "No, I don't think so.",
            "hi": "नहीं, मुझे नहीं लगता कुछ किया जा सकता है।",
            "ka": "ಇಲ್ಲ, ನಾನು ಹಾಗೆ ಯೋಚಿಸುವುದಿಲ್ಲ.",
            "te": "లేదు, ఏం చేయలేము అనుకుంటా.",
            "or": "ନା, ମୁଁ ସେପରି ଭାବୁନାହିଁ |",
            "as": "মোৰ মতে নোৱাৰো ৷",
            "gu": "ના, મને નથી લાગતું."
          }
        }
      ],
      "page12": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_4_4////",
            "hi": "B5_4_4////",
            "ka": "B5_4_4////",
            "te": "B5_4_4////",
            "or": "B5_4_4////",
            "as": "B5_4_4////",
            "gu": "B5_4_4////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "So then what is left for us to do. Just keep a positive frame of mind, and make the most of the opportunities life throws at us.",
            "hi": "तो फिर हमारे पास कौन सा मार्ग बचा है। बस हमेशा सकरात्मक सोच रखों और जिंदगी हमें जो भी अवसर देती उसका सही फायदा उठाओ।",
            "ka": "ಹಾಗಾದರೆ ನಮಗೆ ಏನು ಮಾಡಲು ಉಳಿದಿದೆ. ಕೇವಲ ಸಕಾರಾತ್ಮಕ ಮನಸ್ಸಿನ ಚೌಕಟ್ಟನ್ನು ಇಟ್ಟುಕೊಳ್ಳಿ ಮತ್ತು ಜೀವನವು ನಮಗೆ ಎಸೆಯುವ ಅವಕಾಶಗಳನ್ನು ಹೆಚ್ಚು ಮಾಡಿ.",
            "te": "కాబట్టి మనకు ఏమి మిగిలి ఉంది. పాజిటివ్ (సానుకూలం) గా ఉండు మరియు జీవితం లో మనకు వచ్చే అవకాశాలను ఎక్కువగా ఉపయోగించుకోవాలి.",
            "or": "ତେବେ ଆମ ପାଇଁ କ’ଣ କରିବାକୁ ବାକି ଅଛି | ମନର ଏକ ସକାରାତ୍ମକ ଫ୍ରେମ୍ ରଖ, ଏବଂ ଜୀବନ ଆମ ଉପରେ ପକାଉଥିବା ସୁଯୋଗଗୁଡିକର ଉପଯୋଗ କର |",
            "as": "তেতিয়াহ’লে আমাৰ বাবে কৰিবলৈ কি বাকী থাকিল। কেৱল ইতিবাচক হোৱা, আৰু জীৱনে আমাক দিয়া সুযোগবোৰৰ সদ্ব্যৱহাৰ কৰা।",
            "gu": "તો હવે શું બચ્યું છે કઈ કરવા માટે. બસ હકારત્મક વિચાર રાખ અને જીવન આપણને જે તકો આપે છે તેનો મહત્તમ ઉપયોગ કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "What opportunities ma’am, it only feels I'm making mistake after mistake.",
            "hi": "कौन से अवसर मैम, मुझे तो लगता है कि मैं गलतीयों पर गलतियाँ करता जा रहा हूँ।",
            "ka": "ಎಂತಹ ಅವಕಾಶಗಳು ಮೇಡಮ್, ನಾನು ತಪ್ಪಾದ ನಂತರ ತಪ್ಪು ಮಾಡುತ್ತಿದ್ದೇನೆ ಎಂದು ಭಾವಿಸುತ್ತದೆ.",
            "te": "ఎలాంటి అవకాశాలు మేడమ్, నేను తప్పు మీద తప్పు చేస్తున్నానని అనిపిస్తుంది.",
            "or": "କେଉଁ ସୁଯୋଗ ମ୍ୟାଡାମ୍‌, କେବଳ ଏମିତି ଲାଗୁଛି ମୁଁ ଭୁଲ୍ ପରେ ଭୁଲ୍ କରୁଛି |",
            "as": "কি সুযোগ মেডাম, মোৰ অনুভৱ হৈছে যে মই কেৱল এটাৰ পিছত এটা ভুল কৰি আছোঁ।",
            "gu": "મેડમ કઈ તકો, મને લાગે છે હું ભૂલ ઉપર ભૂલ કરું છું."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Try looking at it differently, Naveen. If you are going to be stuck here, how will you make the most of it? You are surrounded by very experienced people. Use this time to learn as much as you can.",
            "hi": "इस दूसरी नजर से देखने की कोशिश करो। अगर तुम्हें यह काम करना ही पड़ रहा है तो तुम इसका लाभ कैसे उठा सकते हो? तुम्हारे आसपास बहुत अनुभवी व्यक्ति हैं। इस समय का फायदा उठाकर उनसे जितना हो सकें सीखने कि कोशिश करो।",
            "ka": "ಅದನ್ನು ವಿಭಿನ್ನವಾಗಿ ನೋಡಲು ಪ್ರಯತ್ನಿಸಿ ನವೀನ್. ನೀವು ಇಲ್ಲಿ ಸಿಲುಕಿಕೊಂಡರೆ, ನೀವು ಅದನ್ನು ಹೇಗೆ ಹೆಚ್ಚು ಮಾಡುತ್ತೀರಿ? ನೀವು ತುಂಬಾ ಅನುಭವಿ ಜನರಿಂದ ಸುತ್ತುವರಿದಿದ್ದೀರಿ. ನೀವು ಎಷ್ಟು ಸಾಧ್ಯವೋ ಅಷ್ಟು ಕಲಿಯಲು ಈ ಸಮಯವನ್ನು ಬಳಸಿ.",
            "te": "నవీన్, దానిని వేరే విధంగా చూడడానికి ప్రయత్నించు. నువ్వు ఇక్కడ ఆగిపోతే, నువ్వు దాన్ని ఎలా ఎక్కువగా ఉపయోగించుకుంటావు? నువ్వు చాలా అనుభవం కల వ్యక్తులతో కలిసి ఉంటున్నావు. నువ్వు వీలయినంత ఎక్కువగా నేర్చుకోవడానికి ఈ సమయాన్ని ఉపయోగించాలి.",
            "or": "ନବୀନ, ଏହାକୁ ଭିନ୍ନ ଦୃଷ୍ଟିରେ ଦେଖିବା ପାଇଁ ଚେଷ୍ଟା କର | । ଯଦି ତୁମେ ଏଠାରେ ଅଟକି ଯିବ, ତୁମେ ଏଥିରୁ କିପରି ଅଧିକ ଉପଯୋଗ ପାଇବ ? ତୁମେ ବହୁତ ଅଭିଜ୍ଞ ବ୍ୟକ୍ତିଙ୍କ ମଧ୍ଯରେ ରହିଛ | ତୁମେ ଯେତେ ସମ୍ଭବ ଶିଖିବାକୁ ଏହି ସମୟକୁ ବ୍ୟବହାର କର |",
            "as": "নবীন, এইটো বেলেগ ধৰণে চাবলৈ চেষ্টা কৰা। যদি তুমি ইয়াতে আবদ্ধ হৈ থাকা, তেন্তে ইয়াৰ সৰ্বাধিক ব্যৱহাৰ কেনেদৰে কৰিবা? তোমাৰ চাৰিওফালে বহু অভিজ্ঞ মানুহ আছে ৷ তুমি যিমান পাৰা শিকিবলৈ এই সময়খিনি ব্যৱহাৰ কৰা।",
            "gu": "નવીન બીજી રીતે તું એને વિચાર. જો તું અહી ભરાઈ ગયો હોય તો, તો સૌથી સારું તું શું કરી શકે છે. તું ખૂબ જ અનુભવી લોકોથી ઘેરાયેલા છે. આ સમય તું એવી રીતે ઉપયોગ કર કઈક તું શીખી શકે."
          }
        }
      ],
      "page13": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_5_1",
            "hi": "B5_5_1",
            "ka": "B5_5_1",
            "te": "B5_5_1",
            "or": "B5_5_1",
            "as": "B5_5_1",
            "gu": "B5_5_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen spends some time thinking about what Mrs Bansal said. It makes sense to him. He has to change the way he looks at this and convert it into an opportunity.",
            "hi": "नवीन श्रीमती बंसल के उपदेश पर विचार करता है। उसे यह बातें सही लगती हैं। उसे बस इस हालात को देखने का नजरिया बदलना होगा और इसे अवसर के तौर पर इस्तेमाल करना होगा।",
            "ka": "ನವೀನ್ ಶ್ರೀಮತಿ ಬನ್ಸಾಲ್ ಹೇಳಿದ್ದನ್ನು ಕುರಿತು ಸ್ವಲ್ಪ ಸಮಯ ಕಳೆಯುತ್ತಾನೆ. ಇದು ಅವನಿಗೆ ಅರ್ಥವಾಗಿದೆ. ಅವನು ಇದನ್ನು ನೋಡುವ ದೃಷ್ಟಿಕೋನವನ್ನು ಬದಲಿಸಬೇಕು ಮತ್ತು ಅದನ್ನು ಅವಕಾಶವಾಗಿ ಪರಿವರ್ತಿಸಬೇಕು.",
            "te": "నవీన్, శ్రీమతి బన్సల్ చెప్పిన దాని గురించి ఆలోచిస్తూ కాసేపు ఉన్నాడు. అది అతనికి అర్ధం అవుతుంది. దీన్ని చూసే విధానాన్ని మార్చుకుని, అవకాశంగా మార్చుకోవాలి.",
            "or": "ଶ୍ରୀମତୀ ବନସଲ୍‌ ଯାହା କହନ୍ତି ନବୀନ ସେ ବିଷୟରେ ଚିନ୍ତା କରି କିଛି ସମୟ ଅତିବାହିତ କରନ୍ତି | । ଏହା ତାଙ୍କ ପାଇଁ ଅର୍ଥପୂର୍ଣ୍ଣ ଅଟେ | ତାଙ୍କୁ ଏହାକୁ ଦେଖିବାର ଶୈଳୀରେ ପରିବର୍ତ୍ତନ କରିବାକୁ ପଡିବ ଏବଂ ଏହାକୁ ଏକ ସୁଯୋଗରେ ପରିଣତ କରିବାକୁ ପଡିବ |",
            "as": "শ্ৰীমতী বনচালে যি কৈছিল সেই বিষয়ে নবীনে কিছু সময় চিন্তা কৰিলে ৷ সি ইয়াৰ অৰ্থ বুজি পালে ৷ সি ইয়াক চোৱাৰ ধৰণ সলনি কৰিব লাগিব আৰু ইয়াক এটা সুযোগলৈ ৰূপান্তৰ কৰিব লাগিব।",
            "gu": "નવીન થોડું વિચાર કે જે શ્રીમતી બંસલે કહ્યું હતું તે. તે કઈક અર્થ ભર્યું છે. તેને બદલવું પડશે જે તરફ તે વિચારે છે અને તક તરફ બદલાવું પડશે. "
          }
        }
      ],
      "page14": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_5_2",
            "hi": "B5_5_2",
            "ka": "B5_5_2",
            "te": "B5_5_2",
            "or": "B5_5_2",
            "as": "B5_5_2",
            "gu": "B5_5_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He decides to learn as much as he can by observing his seniors and networking to build good connections.",
            "hi": "वह अपने सीनियर्स को देखकर जितना हो सकें उतना सीखने का और अच्छे संबंध बनाने के लिए नेटवर्किंग करने का निर्णय लेता है।",
            "ka": "ಅವನು ತನ್ನ ಹಿರಿಯರನ್ನು ಗಮನಿಸುವುದರ ಮೂಲಕ ಮತ್ತು ಉತ್ತಮ ಸಂಪರ್ಕಗಳನ್ನು ನಿರ್ಮಿಸಲು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮಾಡುವ ಮೂಲಕ ಎಷ್ಟು ಸಾಧ್ಯವೋ ಅಷ್ಟು ಕಲಿಯಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
            "te": "అతను తన సీనియర్‌లను గమనించి, మంచి కనెక్షన్‌లను ఏర్పరచుకోవడానికి నెట్‌వర్కింగ్ చేయడం ద్వారా తనకు వీలైనంత ఎక్కువ నేర్చుకోవాలని నిర్ణయించుకున్నాడు.",
            "or": "ସେ ନିଜ ସିନିୟରମାନଙ୍କୁ ନଜର ରଖିବା ଏବଂ ଭଲ ସଂଯୋଗ ଗଢିବା ପାଇଁ ନେଟୱାର୍କିଂ କରି ଯଥାସମ୍ଭବ ଶିଖିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
            "as": "সি তেওঁৰ জ্যেষ্ঠসকলক লক্ষ্য কৰি আৰু ভাল সংযোগ গঢ়িবলৈ নেটৱৰ্কিং কৰি যিমান পাৰে সিমান শিকিবলৈ সিদ্ধান্ত লয়।",
            "gu": "તેને નક્કી કર્યું કે તે જેટલું બને તેટલું સારા સબંધ માટે શીકે તેના ઉપરી અધિકારી અને નેટવર્કિંગ."
          }
        }
      ]
    },
    "scene9": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_1_1",
            "hi": "B6_1_1",
            "ka": "B6_1_1",
            "te": "B6_1_1",
            "or": "B6_1_1",
            "as": "B6_1_1",
            "gu": "B6_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Four months later, Naveen’s team is organising a trade fair where various companies come to showcase their products.",
            "hi": "चार महीने बाद, नवीन की टीम एक ट्रेड फेअर आयोजित कर रही है जहाँ अलग-अलग कंपनियाँ अपने प्रोडक्ट पेश करेगी।",
            "ka": "ನಾಲ್ಕು ತಿಂಗಳ ನಂತರ, ನವೀನ್ ತಂಡವು ವ್ಯಾಪಾರ ಮೇಳವನ್ನು ಆಯೋಜಿಸುತ್ತಿದೆ, ಅಲ್ಲಿ ವಿವಿಧ ಕಂಪನಿಗಳು ತಮ್ಮ ಉತ್ಪನ್ನಗಳನ್ನು ಪ್ರದರ್ಶಿಸಲು ಬರುತ್ತವೆ.",
            "te": "నాలుగు నెలల తర్వాత, నవీన్ బృందం వాణిజ్య ప్రదర్శనను నిర్వహిస్తోంది, అక్కడ వివిధ కంపెనీలు తమ ఉత్పత్తులను ప్రదర్శించడానికి వస్తాయి.",
            "or": "ଚାରିମାସ ପରେ, ନବୀନଙ୍କ ଦଳ ଏକ ବାଣିଜ୍ୟ ମେଳା ଆୟୋଜନ କରୁଛି ଯେଉଁଠାରେ ବିଭିନ୍ନ କମ୍ପାନୀ ସେମାନଙ୍କ ଉତ୍ପାଦ ପ୍ରଦର୍ଶନ କରିବାକୁ ଆସିବେ |",
            "as": "চাৰি মাহৰ পিছত, নবীনৰ দলটোৱে এখন বাণিজ্য মেলাৰ আয়োজন কৰিছে য'ত বিভিন্ন কোম্পানীয়ে তেওঁলোকৰ সামগ্ৰী প্ৰদৰ্শন কৰিব।",
            "gu": "ચાર મહિના પછી, નવીનની ટીમ એક મેળાનું વેપારનું આયોજન કર્યું છે જ્યાં અલગ અલગ કંપનીઓ તેમના ઉત્પાદનોનું દર્શવામાં આવે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_1_2",
            "hi": "B6_1_2",
            "ka": "B6_1_2",
            "te": "B6_1_2",
            "or": "B6_1_2",
            "as": "B6_1_2",
            "gu": "B6_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen is trying to talk to some senior employees from different companies to learn new things from them.",
            "hi": "नवीन अलग-अलग कंपनियों के सीनियर कर्मचारियों से बात करनके उनसे नयी चीजें सीखने की कोशिश कर रहा है।",
            "ka": "ನವೀನ್ ವಿವಿಧ ಕಂಪನಿಗಳ ಕೆಲವು ಹಿರಿಯ ಉದ್ಯೋಗಿಗಳೊಂದಿಗೆ ಮಾತನಾಡಿ ಅವರಿಂದ ಹೊಸ ವಿಷಯಗಳನ್ನು ಕಲಿಯಲು ಪ್ರಯತ್ನಿಸುತ್ತಿದ್ದಾನೆ.",
            "te": "వివిధ కంపెనీలకు చెందిన కొందరు సీనియర్ ఉద్యోగులతో మాట్లాడి వారి నుంచి కొత్త విషయాలు తెలుసుకునేందుకు నవీన్ ప్రయత్నిస్తున్నాడు.",
            "or": "ନବୀନ ବିଭିନ୍ନ କମ୍ପାନୀର କିଛି ବରିଷ୍ଠ କର୍ମଚାରୀଙ୍କ ସହ ସେମାନଙ୍କଠାରୁ ନୂତନ ଜିନିଷ ଶିଖିବାକୁ ଚେଷ୍ଟା କରନ୍ତି |",
            "as": "নবীনে বিভিন্ন কোম্পানীৰ কিছুমান জ্যেষ্ঠ কৰ্মচাৰীৰ সৈতে তেওঁলোকৰ পৰা নতুন কথা শিকিবলৈ কথা পাতিবলৈ চেষ্টা কৰি আছে।",
            "gu": "નવીન તેના વિવિધ કંપનીના ઉપરી કર્મચારી સાથે વાત કરે છે જેથી તેને કઈક નવૂ જાણવા મળે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_1_3",
            "hi": "B6_1_3",
            "ka": "B6_1_3",
            "te": "B6_1_3",
            "or": "B6_1_3",
            "as": "B6_1_3",
            "gu": "B6_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "At the event, he meets Ms Sinha, a senior executive of another event management firm.",
            "hi": "इवेंट के दौरान, वह मिस सिन्हा से मिलता है जो एक दुसरे इवेंट मैनजमेंट फर्म में सीनियर एक्जीक्यूटिव है।",
            "ka": "ಈವೆಂಟ್‌ನಲ್ಲಿ, ಅವನು ಮತ್ತೊಂದು ಇವೆಂಟ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಸಂಸ್ಥೆಯ ಹಿರಿಯ ಕಾರ್ಯನಿರ್ವಾಹಕರಾದ Ms ಸಿನ್ಹಾ ಅವರನ್ನು ಭೇಟಿಯಾಗುತ್ತಾನೆ.",
            "te": "కార్యక్రమంలో, అతను మరొక ఈవెంట్ మేనేజ్‌మెంట్ సంస్థ యొక్క సీనియర్ ఎగ్జిక్యూటివ్ Ms సిన్హాను కలిశాడు.",
            "or": "ଇଭେଣ୍ଟରେ ସେ ଅନ୍ୟ ଏକ ଇଭେଣ୍ଟ ମ୍ୟାନେଜମେଣ୍ଟ୍‍ ଫାର୍ମର ବରିଷ୍ଠ ଏକଜିକ୍ୟୁଟିଭ୍, ମିସ୍‍ ସିହ୍ନାଙ୍କୁ ଭେଟିଛନ୍ତି |",
            "as": "এই অনুষ্ঠানটোত, সি আন এটা অনুষ্ঠান ব্যৱস্থাপনা প্ৰতিষ্ঠানৰ জ্যেষ্ঠ কাৰ্যবাহী শ্ৰীমতী সিনহাক লগ পায়।",
            "gu": "તે પ્રસંગમાં તે એક વરિષ્ઠ એક્ઝિક્યુટિવ શ્રીમતી સિંહાને મળે છે તે બીજા ઈવેન્ટ મેનેજમેન્ટ કંપનીના છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_1_4//",
            "hi": "B6_1_4//",
            "ka": "B6_1_4//",
            "te": "B6_1_4//",
            "or": "B6_1_4//",
            "as": "B6_1_4//",
            "gu": "B6_1_4//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "She is very interested in Naveen’s thoughts on their approach to organising and planning.",
            "hi": "उसे इवेंट आयोजन करने और प्लान करने के बारे में नवीन के विचार जानने में दिलचस्पी है।",
            "ka": "ಸಂಘಟಿಸುವ ಮತ್ತು ಯೋಜಿಸುವ ವಿಧಾನದ ಬಗ್ಗೆ ನವೀನ್ ಆಲೋಚನೆಗಳಲ್ಲಿ ಅವಳು ತುಂಬಾ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದಾಳೆ.",
            "te": "నిర్వహణ మరియు ప్రణాళిక పట్ల తన విధానాలపై నవీన్ ఆలోచనలపై ఆమెకు చాలా ఆసక్తి కలిగింది.",
            "or": "ନବୀନଙ୍କ ଆୟୋଜନ ଏବଂ ଯୋଜନା ଉପରେ ତାଙ୍କ ଦୃଷ୍ଟିକୋଣରେ ସେ ଅତ୍ୟଧିକ ଆଗ୍ରହୀ |",
            "as": "তেওঁ সংগঠিত আৰু পৰিকল্পনাৰ প্ৰতি তেওঁলোকৰ দৃষ্টিভংগীৰ ওপৰত নবীনৰ চিন্তাধাৰাৰ প্ৰতি যথেষ্ট আগ্ৰহী।",
            "gu": "તે આયોજન અને આજનાઓ ઉપર તેમના અભિગમ અંગે નવીનના વિચારોમાં ખૂબ સારું લાગે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Ms Sinha",
            "hi": "मिस सिन्हा",
            "ka": "ಶ್ರೀಮತಿ ಸಿನ್ಹಾ",
            "te": "శ్రీమతి సిన్హా",
            "or": "ମିସ୍‍ ସିହ୍ନା",
            "as": "শ্ৰীমতী সিনহা",
            "gu": "શ્રીમતી સિંહાને"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "I think event planning should really focus on more minor details to make events successful.",
            "hi": "मुझे लगता है कि इवेंट को सफल बनाने के लिए इवेंट प्लान करते समय छोटी डिटेल्स पर ज्यादा ध्यान देने की जरूरत होती है।",
            "ka": "ಈವೆಂಟ್‌ಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮಾಡಲು ಈವೆಂಟ್ ಯೋಜನೆ ನಿಜವಾಗಿಯೂ ಹೆಚ್ಚು ಚಿಕ್ಕ ವಿವರಗಳ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಬೇಕು ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
            "te": "ఈవెంట్‌లను విజయవంతం చేయడానికి ఈవెంట్ ప్రణాళిక నిజంగా మరిన్ని చిన్న వివరాలపై ఫోకస్ పెట్టాలని నేను అనుకుంటున్నాను.",
            "or": "ମୁଁ ଭାବୁଛି ଇଭେଣ୍ଟଗୁଡିକ ସଫଳ କରିବା ପାଇଁ ଇଭେଣ୍ଟ ଯୋଜନା ପ୍ରକୃତରେ ଅଧିକ ଛୋଟ ବିବରଣୀଗୁଡିକ ଉପରେ ଧ୍ୟାନ ଦେବା ଉଚିତ୍ |",
            "as": "মই ভাবোঁ অনুষ্ঠানৰ পৰিকল্পনাই অনুষ্ঠানবোৰ সফল কৰিবলৈ হ’লে সৰু সৰু বিৱৰণৰ ওপৰত গুৰুত্ব দিয়া উচিত।",
            "gu": "મને લાગે છે કે ઈવેન્ટનૂ આયોજન નાની વસ્તુ પર ધ્યાન રાખવું જોઈએ પ્રગતિ માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Why do you say that?",
            "hi": "तुम ऐसा क्यों कह रहे हो?",
            "ka": "ಅದನು ಯಾಕೆ ನೀನು ಹೇಳಿದೆ?",
            "te": "నువ్వు ఎందుకు అలా అంటావు?",
            "or": "ତୁମେ ଏହା କାହିଁକି କହୁଛ ?",
            "as": "তুমি তেনেকৈ কিয় ক’লা?",
            "gu": "તને શું લાગે છે?"
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_1_5////",
            "hi": "B6_1_5////",
            "ka": "B6_1_5////",
            "te": "B6_1_5////",
            "or": "B6_1_5////",
            "as": "B6_1_5////",
            "gu": "B6_1_5////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "All the events I’ve observed in the past have good concepts, but they don’t focus on the minor details like ease of registration, clear signages at the locations etc.",
            "hi": "मैंने इससे पहले जितने भी इवेंट्स देखे हैं, उनकी संकल्पना तो अच्छी थी लेकिन छोटे-छोटे डिटेल्स पर ज्यादा ध्यान नहीं दिया गया था, जैसे कि, रजिस्ट्रेशन में आसानी, स्थान स्पष्ट रुप से दर्शानेवाले बोर्ड आदि।",
            "ka": "ನಾನು ಹಿಂದೆ ಗಮನಿಸಿದ ಎಲ್ಲಾ ಈವೆಂಟ್‌ಗಳು ಉತ್ತಮ ಪರಿಕಲ್ಪನೆಗಳನ್ನು ಹೊಂದಿವೆ, ಆದರೆ ಅವು ನೋಂದಣಿಯ ಸುಲಭತೆ, ಸ್ಥಳಗಳಲ್ಲಿ ಸ್ಪಷ್ಟ ಚಿಹ್ನೆಗಳು ಮುಂತಾದ ಸಣ್ಣ ವಿವರಗಳ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸುವುದಿಲ್ಲ.",
            "te": "నేను గతంలో గమనించిన అన్ని ఈవెంట్‌లు మంచి భావనలను కలిగి ఉన్నాయి, కానీ అవి రిజిస్ట్రేషన్ సౌకర్యం, స్థానంలో క్లియర్ గుర్తులు మొదలైన చిన్న వివరాలపై దృష్టి పెట్టవు.",
            "or": "ଅତୀତରେ ମୁଁ ଦେଖିଥିବା ସମସ୍ତ ଇଭେଣ୍ଟଗୁଡ଼ିକରେ ଭଲ ଧାରଣା ଥାଏ, କିନ୍ତୁ ସେମାନେ ଛୋଟ ଛୋଟ ବିବରଣୀଗୁଡିକ ଯେପରି ପଞ୍ଜୀକରଣର ସହଜତା, ଅବସ୍ଥାନରେ ସାଇନେଜ୍‌ ସଫା କରିବା ଇତ୍ୟାଦି ଉପରେ ଧ୍ୟାନ ଦିଅନ୍ତି ନାହିଁ |",
            "as": "মই আগতে লক্ষ্য কৰা সকলোবোৰ অনুষ্ঠানৰ ভাল ধাৰণা আছে, কিন্তু সেইবোৰে সহজ পঞ্জীয়নৰ ব্যৱস্থা, স্থানবোৰত স্পষ্ট ছাইনেজ আদিৰ দৰে সৰু বিৱৰণৰ ওপৰত গুৰুত্ব নিদিয়ে।",
            "gu": "અત્યાર સુધી મેં જે ઇવેંટ જોયા છે તે સારી ધારણા વાળા છે, પરંતુ તે નાની વિગતો પર ધ્યાન કેન્દ્રિત કરતી નથી કરતાં જાણે કે નોંધણીની સરળતા, સ્થાનો પર સ્પષ્ટ સંકેતો વગેરે જેવી."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "You have some pretty exciting thoughts, Naveen.",
            "hi": "तुम्हारे विचार काफी अच्छे हैं, नवीन।",
            "ka": "ನವೀನ್, ನಿಮಗೆ ಕೆಲವು ಆಸಕ್ತಿದಾಯಕ ಆಲೋಚನೆಗಳಿವೆ.",
            "te": "నీకు చాలా ఉత్సాహమైన ఆలోచనలు ఉన్నాయి, నవీన్.",
            "or": "ତୁମ ପଖରେ କିଛି ସୁନ୍ଦର ରୋମାଞ୍ଚକର ଚିନ୍ତାଧାରା ଅଛି, ନବୀନ |",
            "as": "নবীন, তোমাৰ কিছুমান চিন্তা আকৰ্ষণীয়।",
            "gu": "નવીન તારી જોડે આકર્ષક વિચારો છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Thanks, Ms Sinha. I’d be happy to continue this conversation with you after the event.\nCan I have your card?",
            "hi": "थैंक यू, मिस सिन्हा। इवेंट खत्म होने के बाद इस विषय पर बात करना जारी रखने में मुझे खुशी होगी। \n क्या मुझे आपका कार्ड मिल सकता है?",
            "ka": "ಧನ್ಯವಾದಗಳು, ಶ್ರೀಮತಿ ಸಿನ್ಹಾ. ಈವೆಂಟ್‌ನ ನಂತರ ನಿಮ್ಮೊಂದಿಗೆ ಈ ಸಂಭಾಷಣೆಯನ್ನು ಮುಂದುವರಿಸಲು ನಾನು ಸಂತೋಷಪಡುತ್ತೇನೆ.\n ನಾನು ನಿಮ್ಮ ಕಾರ್ಡ್ ಹೊಂದಬಹುದೇ?",
            "te": "థాంక్స్, శ్రీమతి సిన్హా. ఈవెంట్ తర్వాత మీతో ఈ సంభాషణ కొనసాగించడానికి నేను సంతోషంగా ఉన్నాను.\n  మీ కార్డును ఇస్తారా?",
            "or": "ଧନ୍ୟବାଦ, ମିସ୍‌ ସିହ୍ନା | ଇଭେଣ୍ଟ ପରେ ଆପଣଙ୍କ ସହ ଏହି ବାର୍ତ୍ତାଳାପ ଜାରି ରଖିବାରେ ମୁଁ ଖୁସି ହୁଅନ୍ତି |\n ମୁଁ ଆପଣଙ୍କର କାର୍ଡ ପାଇ ପାରିବି କି ?",
            "as": "ধন্যবাদ, শ্ৰীমতী সিনহা। অনুষ্ঠানটোৰ পিছত আপোনাৰ সৈতে এই কথা-বতৰাখিনি অব্যাহত ৰাখিবলৈ পালে ভাল পাম। আপোনাৰ কাৰ্ড এখন দিব নেকি?",
            "gu": "ધન્યવાદ શ્રીમતી સિંહા. મને ખુશી થસે કે હું તામારી જોડે વધુ ચર્ચા કરીશ આ ઇવેંટ પછી. શું મને તમારું કાર્ડ મળી શકે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Sure! Here you go... I’m looking forward to hearing from you.",
            "hi": "हाँ, क्यों नहीं! ये लो... मैं तुम्हारे फोन का इंतजार करूंगी।",
            "ka": "ಖಂಡಿತ! ಇಗೋ... ನಿಮ್ಮಿಂದ ಕೇಳಲು ನಾನು ಎದುರು ನೋಡುತ್ತಿದ್ದೇನೆ.",
            "te": "తప్పకుండా! ఇదిగో... మీ నుండి వినడానికి నేను ఎదురు చూస్తూ ఉంటాను.",
            "or": "ନିଶ୍ଚିତ ! ଏବେ ହି ନିଅ... ମୁଁ ତୁମଠାରୁ ଶୁଣିବାକୁ ଅପେକ୍ଷା କରିଛି |",
            "as": "নিশ্চয়! এইখন লোৱা…তোমাৰ লগত আকৌ কথা পাতিম ৷",
            "gu": "કેમ નહીં! આ રહ્યું.. હું એવું સાંભળું માગું છું કે તું આગળ વધે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_2_1",
            "hi": "B6_2_1",
            "ka": "B6_2_1",
            "te": "B6_2_1",
            "or": "B6_2_1",
            "as": "B6_2_1",
            "gu": "B6_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen’s contract is coming to an end, and he is desperately looking to change his job. Now that he has a year of experience on his resume, he is confident that he can get a job at a better organisation.",
            "hi": "नवीन का कॉन्ट्रेक्ट खत्म होनेवाला है और वह अपनी नौकरी बदलने के लिए बेताब है। अब उसके रिज्यूम में एक साल का अनुभव है, इसलिए उसे विश्वास है कि उसे बेहतर संस्था में नौकरी मिल सकती हैं।",
            "ka": "ನವೀನ್‌ನ ಒಪ್ಪಂದವು ಕೊನೆಗೊಳ್ಳುತ್ತಿದೆ ಮತ್ತು ಅವನು ತನ್ನ ಕೆಲಸವನ್ನು ಬದಲಾಯಿಸಲು ಹತಾಶನಾಗಿ ನೋಡುತ್ತಿದ್ದಾನೆ. ಇದೀಗ ರೆಸ್ಯೂಮ್ ನಲ್ಲಿ ಒಂದು ವರ್ಷದ ಅನುಭವ ಇರುವುದರಿಂದ ಉತ್ತಮ ಸಂಸ್ಥೆಯಲ್ಲಿ ಕೆಲಸ ಸಿಗಬಹುದು ಎಂಬ ವಿಶ್ವಾಸ ಅವರದ್ದು.",
            "te": "నవీన్ కాంట్రాక్ట్ అయిపోతుంది మరియు అతను తన ఉద్యోగాన్ని మార్చుకోవాలని చూస్తున్నాడు. ఇప్పుడు తన రెజ్యూమ్‌లో ఒక సంవత్సరం అనుభవం ఉన్నందున, అతను మంచి కంపెనీలో ఉద్యోగం తెచ్చుకోగలడని నమ్మకంగా ఉన్నాడు.",
            "or": "ନବୀନଙ୍କ ଚୁକ୍ତି ଶେଷ ହେବାକୁ ଯାଉଛି, ଏବଂ ସେ ହତାଶ ଭାବରେ ଚାକିରୀ ବଦଳାଇବାକୁ ଚେଷ୍ଟା କରୁଛନ୍ତି | ବର୍ତ୍ତମାନ ତାଙ୍କ ରିଜ୍ୟୁମ୍ ଉପରେ ତାଙ୍କର ଏକ ବର୍ଷର ଅଭିଜ୍ଞତା ଅଛି, ସେ ନିଶ୍ଚିତ ଅଛନ୍ତି ଯେ ସେ ଏକ ଉନ୍ନତ ସଂସ୍ଥାରେ ଚାକିରି ପାଇପାରିବେ |",
            "as": "নবীনৰ চুক্তি সমাপ্ত হ'বলৈ হৈছে, আৰু সি নিজৰ চাকৰি সলনি কৰিব বিচাৰিছে। এতিয়া যেতিয়া তাৰ ৰিজিউমিত এবছৰৰ অভিজ্ঞতা আছে, সি নিশ্চিত যে সি এটা উন্নত সংগঠনত চাকৰি পাব পাৰে।",
            "gu": "નવીનનો કરાર પૂરો થવા આવે છે. અને તે તેની નોકરી બદલવા માટે સખત પ્રયાસ કરી રહ્યો છે. હવે તેની પાસે બાયોડેટાનો એક વર્ષનો અનુભવ છે, તેને વિશ્વાસ છે કે તે વધુ સારી સંસ્થામાં નોકરી મેળવી શકશે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_2_2",
            "hi": "B6_2_2",
            "ka": "B6_2_2",
            "te": "B6_2_2",
            "or": "B6_2_2",
            "as": "B6_2_2",
            "gu": "B6_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He thinks of approaching Ms Sinha for help.",
            "hi": "वह मिस सिन्हा से मदद लेने की सोचता है।",
            "ka": "ಅವನು ಸಹಾಯಕ್ಕಾಗಿ Ms ಸಿನ್ಹಾ ಅವರನ್ನು ಸಂಪರ್ಕಿಸಲು ಯೋಚಿಸುತ್ತಾನೆ.",
            "te": "సహాయం కోసం శ్రీమతి సిన్హాను కలవాలని అతను అనుకుంటాడు.",
            "or": "ସେ ସାହାଯ୍ୟ ପାଇଁ ମିସ୍‌ ସିହ୍ନାଙ୍କ ନିକଟକୁ ଯିବାକୁ ଚିନ୍ତା କରନ୍ତି |",
            "as": "সি সহায়ৰ বাবে শ্ৰীমতী সিনহাৰ ওচৰলৈ যোৱাৰ কথা ভাবিলে।",
            "gu": "તે શ્રીમતી સિંહાના પાસે મદદ માટે  સંપર્ક કરવાનું વિચારે છે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_2_3",
            "hi": "B6_2_3",
            "ka": "B6_2_3",
            "te": "B6_2_3",
            "or": "B6_2_3",
            "as": "B6_2_3",
            "gu": "B6_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Help Naveen send an email.",
            "hi": "नवीन को ईमेल भेजने में मदद करें।",
            "ka": "ನವೀನ್ ಇಮೇಲ್ ಕಳುಹಿಸಲು ಸಹಾಯ ಮಾಡಿ.",
            "te": "నవీన్‌కి ఇమెయిల్ పంపడంలో సహాయం చేయండి.",
            "or": "ନବୀନଙ୍କୁ ଏକ ଇମେଲ୍‌ ପଠାଇବାରେ ସାହାଯ୍ୟ କରନ୍ତୁ |",
            "as": "নবীনক এটা ইমেইল পথাবলৈ সহায় কৰা।",
            "gu": "નવીનને મદદ કરવા માટે ઈમેલ મોકલો"
          }
        },
        {
          "valuePath": "blocks.0.props.sendMailTo",
          "value": {
            "en": "kc.sinha@zmail.com",
            "hi": "kc.sinha@zmail.com",
            "ka": "kc.sinha@zmail.com",
            "te": "kc.sinha@zmail.com",
            "or": "kc.sinha@zmail.com",
            "as": "kc.sinha@zmail.com",
            "gu": "kc.sinha@zmail.com"
          }
        },
        {
          "valuePath": "blocks.0.props.sendMailSubject",
          "value": {
            "en": "Assistance in looking for new opportunities.",
            "hi": "नए अवसर ढूंढने के लिए सहायता।",
            "ka": "ಹೊಸ ಅವಕಾಶಗಳನ್ನು ಹುಡುಕುವಲ್ಲಿ ಸಹಾಯ.",
            "te": "కొత్త అవకాశాలు కోసం వెతకడంలో సహాయం.",
            "or": "ନୂତନ ସୁଯୋଗ ଖୋଜିବା ପାଇଁ ସାହାଯ୍ୟ |",
            "as": "নতুন সুযোগ বিচৰাত সহায়।",
            "gu": "નવી તકો મેળવામાં માટે મદદ કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.sendMailMsg",
          "value": {
            "en": "Hello Ms Sinha,\n\nI am Naveen Vincent. We had a conversation about event planning at a recent trade show.\nI am about to finish my contract at my current job, and I’m looking for new opportunities in the event management space. Since you are so well connected in the industry, I was wondering if you could put me in touch with organisations looking to hire.\n\nThank you. \n\nSincerely,\nNaveen.",
            "hi": "हैलो मिस सिन्हा, \n \n मैं नवीन विन्सेंट हूँ। हाल ही में हुए ट्रेड शो के दौरान हमने इवेंट प्लानिंग पर चर्चा की थी। \n मेरे चालू नौकरी के लिए किया हुआ मेरा कॉन्ट्रेक्ट अभी खत्म ही होनेवाला है और मैं इवेंट मैनेजमेंट के क्षेत्र में नए वस्र ढूंढ रहा हूँ। इस इंडस्ट्री में आपके बहुत से लोगों को जानती हैं इसलिए मैं सोच रहा था कि अगर आप मुझे किसी संस्था के बारे में बता दे जहाँ नौकरी उपलब्ध है तो मैं आभारी रहूँगा। \n \n धन्यवाद। \n \n आपका विश्वासु,\n नवीन।",
            "ka": "ನಮಸ್ಕಾರ ಶ್ರೀಮತಿ ಸಿನ್ಹಾ,\n \n ನಾನು ನವೀನ್ ವಿನ್ಸೆಂಟ್. ಇತ್ತೀಚಿನ ವ್ಯಾಪಾರ ಪ್ರದರ್ಶನದಲ್ಲಿ ನಾವು ಈವೆಂಟ್ ಯೋಜನೆ ಕುರಿತು ಸಂವಾದ ನಡೆಸಿದ್ದೇವೆ.\n ನನ್ನ ಪ್ರಸ್ತುತ ಉದ್ಯೋಗದಲ್ಲಿ ನಾನು ನನ್ನ ಒಪ್ಪಂದವನ್ನು ಮುಗಿಸಲಿದ್ದೇನೆ ಮತ್ತು ನಾನು ಈವೆಂಟ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಜಾಗದಲ್ಲಿ ಹೊಸ ಅವಕಾಶಗಳನ್ನು ಹುಡುಕುತ್ತಿದ್ದೇನೆ. ನೀವು ಉದ್ಯಮದಲ್ಲಿ ತುಂಬಾ ಚೆನ್ನಾಗಿ ಸಂಪರ್ಕ ಹೊಂದಿರುವುದರಿಂದ, ಬಾಡಿಗೆಗೆ ಪಡೆಯಲು ಬಯಸುವ ಸಂಸ್ಥೆಗಳೊಂದಿಗೆ ನೀವು ನನ್ನನ್ನು ಸಂಪರ್ಕಿಸಬಹುದೇ ಎಂದು ನಾನು ಆಶ್ಚರ್ಯ ಪಡುತ್ತಿದ್ದೆ.\n \n ಧನ್ಯವಾದ.\n \n ಪ್ರಾಮಣಿಕತೆ,\n ನವೀನ್.",
            "te": "హలో శ్రీమతి సిన్హా,\n \n నేను నవీన్ విన్సెంట్. మనము ఇటీవలి ట్రేడ్ షోలో ఈవెంట్ ప్లానింగ్ గురించి మాట్లాడుకున్నాము.\n నేను నా ప్రస్తుత ఉద్యోగం లో నా కాంట్రాక్ట్ ని పూర్తి చేసుకోబోతున్నాను మరియు ఈవెంట్ మేనేజ్‌మెంట్ లో కొత్త అవకాశాల కోసం చూస్తున్నాను. మీరు ఇండస్ట్రీలో బాగా కనెక్ట్ అయినందున, మీరు ఉద్యోగులను తీసుకునే అప్పుడు నాకు చెప్తారని అనుకుంటున్నాను.\n \n ధన్యవాదాలు.\n \n ఇట్లు,\n నవీన్.",
            "or": "ନମସ୍କାର ମିସ୍‌ ସିହ୍ନା,\n \n ମୁଁ ନବୀନ ଭିନ୍ସେଣ୍ଟ | ସମ୍ପ୍ରତି ବାଣିଜ୍ୟ ଶୋ’ରେ ଇଭେଣ୍ଟ ଯୋଜନା ବିଷୟରେ ଆମର ଏକ ବାର୍ତ୍ତାଳାପ ହୋଇଥିଲା |\n ମୁଁ ମୋର ସାମ୍ପ୍ରତିକ ଚାକିରିରେ ମୋର ଚୁକ୍ତିନାମା ସମାପ୍ତ କରିବାକୁ ଯାଉଛି, ଏବଂ ମୁଁ ଇଭେଣ୍ଟ ମ୍ୟାନେଜ୍‍ମେଣ୍ଟ୍‍ କ୍ଷେତ୍ରରେ ନୂତନ ସୁଯୋଗ ଖୋଜୁଛି | ଯେହେତୁ ଆପଣ ଇଣ୍ଡଷ୍ଟ୍ରିରେ ଏତେ ଭଲ ଭାବରେ ସଂଯୁକ୍ତ, ମୁଁ ଭାବୁଥିଲି ଆପଣ ମୋତେ ଚାକିରୀ କରିବାକୁ ଚାହୁଁଥିବା ସଂସ୍ଥାଗୁଡ଼ିକ ସହିତ ଯୋଗାଯୋଗ କରିପାରିବ କି?\n \n ଧନ୍ୟବାଦ।\n \n ଆନ୍ତରିକତାର ସହିତ,\n ନବୀନ",
            "as": "নমস্কাৰ শ্ৰীমতী সিনহা, মই নবীন ভিনচেণ্ট। শেহতীয়া বাণিজ্য অনুষ্ঠান এটাত আমি অনুষ্ঠান পৰিকল্পনাৰ বিষয়ে কথা পাতিছিলো। মই মোৰ বৰ্তমানৰ চাকৰিটোৰ চুক্তি সম্পূৰ্ণ কৰিবলৈ ওলাইছোঁ, আৰু মই অনুষ্ঠান ব্যৱস্থাপনা ক্ষেত্ৰত নতুন সুযোগ বিচাৰি আছোঁ। যিহেতু আপুনিও এই উদ্যোগটোৰ লগত জৰিত, মই ভাবিছিলো যে আপুনি মোক নিয়োগ কৰিব বিচৰা সংগঠনবোৰৰ সৈতে যোগাযোগ কৰাই দিব পাৰিবনে? ধন্যবাদ। আন্তৰিকতাৰে, নবীন।",
            "gu": "નમસ્તે શ્રીમતી સિંહા,\n\nહું નવીન વિન્સેન્ટ છું. પણે  હમણાં ટ્રેડ શોના ઇવેન્ટ પ્લાનિંગ વિશે વાતચીત થઈ હતી.\nમારી હાલની નોકરીનો કરાર પૂરો કરવા જઈ રહ્યો છું, અને હું ઇવેન્ટ મેનેજમેન્ટમાં નવી તકો શોધી રહ્યો છું. તમે ઉદ્યોગમાં ખૂબ જ સારી રીતે જોડાયેલા હોવાથી, હું વિચારી રહ્યો છું કે, તમે મને નોકરી માટે મેળ આવે તેવી સંસ્થાઓ સાથે સંપર્કમાં કરાવશો.\n\nઆભાર.\n\nઆપનો,\nનવીન."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_2_4",
            "hi": "B6_2_4",
            "ka": "B6_2_4",
            "te": "B6_2_4",
            "or": "B6_2_4",
            "as": "B6_2_4",
            "gu": "B6_2_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Ms Sinha is pleased to hear from Naveen. She discusses the possibilities, understands what he is interested in and gives him suggestions regarding his Resume.",
            "hi": "मिस सिन्हा को नवीन का मेल पढ़कर खुशी होती है। वह उसके साथ कई संभावनों के बारे में चर्चा करती है और उसे किस बात में दिलचस्पी है यह समझकर उसे उसके रिज्यूम के बारे में कुछ सुझाव भी देती है।",
            "ka": "ಶ್ರೀಮತಿ ಸಿನ್ಹಾ ನವೀನ್ ಅವರಿಂದ ಕೇಳಿ ಸಂತಸಗೊಂಡಿದ್ದಾರೆ. ಅವರು ಸಾಧ್ಯತೆಗಳನ್ನು ಚರ್ಚಿಸುತ್ತಾರೆ, ಅವನು ಆಸಕ್ತಿ ಹೊಂದಿರುವುದನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ ಮತ್ತು ಅವನ ಪುನರಾರಂಭದ ಬಗ್ಗೆ ಸಲಹೆಗಳನ್ನು ನೀಡುತ್ತಾರೆ.",
            "te": "శ్రీమతి సిన్హా నవీన్ నుండి మెయిల్ రావడాన్ని చూసి సంతోషిస్తున్నారు. ఆమె అవకాశాల గురించి మాట్లాడుతుంది, అతనికి ఆసక్తి ఉన్నవాటిని అర్థం చేసుకుంటుంది మరియు అతని రెజ్యూమ్‌కి సంబంధించి అతనికి సలహాలు ఇస్తుంది.",
            "or": "ନବୀନଙ୍କ କଥା ଶୁଣି ମିସ୍‌ ସିହ୍ନା ଖୁସି ହୁଅନ୍ତି | ସେ ସମ୍ଭାବନା ବିଷୟରେ ଆଲୋଚନା କରନ୍ତି, ସେ କ’ଣ ଆଗ୍ରହୀ ତାହା ବୁଝନ୍ତି ଏବଂ ତାଙ୍କ ରିଜ୍ୟୁମ୍ ସମ୍ବନ୍ଧରେ ତାଙ୍କୁ ପରାମର୍ଶ ଦିଅନ୍ତି |",
            "as": "নবীনৰ মেইল পাই শ্ৰীমতী সিনহা আনন্দিত হ’ল। তেখেতে সম্ভাৱনীৱতাবোৰ আলোচনা কৰে, তেওঁ কিহৰ প্ৰতি আগ্ৰহী সেয়া বুজি পায় আৰু তেওঁৰ ৰিজিউমি সম্পৰ্কে পৰামৰ্শ দিয়ে।",
            "gu": "શ્રીમતી સિંહા નવીન વિષે સાંભળીને ખુશ છે. તે ચર્ચા કરે છે જે શક્યતા હોય તે, તેને શેમાં રસ છે તે સમજો અને તેને તેના બાયોડેટા અંગે સૂચનો આપે છે."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "Networking_level2",
            "hi": "नेटवर्किंग_लेवल2",
            "ka": "ನೆಟ್‌ವರ್ಕಿಂಗ್_ಲೆವೆಲ್2",
            "te": "నెట్‌వర్కింగ్_లెవెల్2",
            "or": "Networking_level2",
            "as": "নেটৱৰ্কিং_স্তৰ2",
            "gu": "નેટવર્કિંગ_સ્તર2"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Networking experience!",
            "hi": "आपने अभी-अभी नेटवर्किंग का अनुभव प्राप्त किया है!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ನೆಟ್‌ವರ್ಕಿಂಗ್ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే నెట్‌వర్కింగ్ అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତମାନ ନେଟୱାର୍କିଂ ଅଭିଜ୍ଞତା ହାସଲ କରିଛନ୍ତି !",
            "as": "তুমি এইমাত্ৰ নেটৱৰ্কিং অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "તમે હમણાં જ નેટવર્કિંગનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Networking 3",
            "hi": "नेटवर्किंग 3",
            "ka": "ನೆಟ್‌ವರ್ಕಿಂಗ್ 3",
            "te": "నెట్‌వర్కింగ్ 3",
            "or": "Networking 3",
            "as": "নেটৱৰ্কিং 3",
            "gu": "નેટવર્કીંગ 3"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर विकास कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "వృత్తి అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ଯାରିୟର୍‌ ଡେଭଲପ୍‌ମେଣ୍ଟ୍‍ କୌଶଳ",
            "as": "জীৱিকা বিকাশৰ দক্ষতা",
            "gu": "કારકિર્દી વિકાસ કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Naveen reaches out to Ms Sinha when he starts looking for a new job. This is a form of Networking. Meeting people and making connections are essential parts of professional life. Networking requires you to be comfortable talking to people, staying in touch with them, and being interested in what other people are doing. Networking will also help you know the latest trend in the industry.",
            "hi": "जब नवीन नए जॉब की तलाश कर रहा होता है तब वह मिस सिन्हा से संपर्क करता है। यह एक तरह का नेटवर्किंग है। लोगों से मिलना और उनके साथ संबंध बनाना प्रोफेशनल जीवन का हिस्सा है। नेटवर्किंग के लिए जरुरी है कि आप लोगों से सहजता से बात करें, उनके संपर्क में रहे और दुसरे लोग क्या कर रहे हैं इसके बारे में रूचि रखें। नेटवर्किंग के जरिए आप को इंडस्ट्री के नए ट्रेंड्स के बारे में भी जानकारी मिलेगी।",
            "ka": "ನವೀನ್ ಹೊಸ ಉದ್ಯೋಗವನ್ನು ಹುಡುಕಲು ಪ್ರಾರಂಭಿಸಿದಾಗ Ms ಸಿನ್ಹಾ ಅವರನ್ನು ತಲುಪುತ್ತಾರೆ. ಇದು ನೆಟ್‌ವರ್ಕಿಂಗ್‌ನ ಒಂದು ರೂಪವಾಗಿದೆ. ಜನರನ್ನು ಭೇಟಿ ಮಾಡುವುದು ಮತ್ತು ಸಂಪರ್ಕಗಳನ್ನು ಮಾಡುವುದು ವೃತ್ತಿಪರ ಜೀವನದ ಅತ್ಯಗತ್ಯ ಭಾಗವಾಗಿದೆ. ನೆಟ್‌ವರ್ಕಿಂಗ್‌ಗೆ ನೀವು ಜನರೊಂದಿಗೆ ಆರಾಮದಾಯಕವಾಗಿ ಮಾತನಾಡಲು, ಅವರೊಂದಿಗೆ ಸಂಪರ್ಕದಲ್ಲಿರಲು ಮತ್ತು ಇತರ ಜನರು ಏನು ಮಾಡುತ್ತಿದ್ದಾರೆ ಎಂಬುದರ ಕುರಿತು ಆಸಕ್ತಿ ಹೊಂದಿರಬೇಕು. ಉದ್ಯಮದಲ್ಲಿನ ಇತ್ತೀಚಿನ ಪ್ರವೃತ್ತಿಯನ್ನು ತಿಳಿಯಲು ನೆಟ್‌ವರ್ಕಿಂಗ್ ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
            "te": "నవీన్ కొత్త ఉద్యోగం కోసం వెతకడం మొదలు పెట్టినప్పుడు శ్రీమతి సిన్హాను కలుస్తాడు. ఇది ఒక నెట్‌వర్కింగ్ విధానం. వ్యక్తులను కలవడం మరియు సంబంధాలను ఏర్పరచుకోవడం వృత్తిపరమైన జీవితంలో ముఖ్యమైన భాగాలు. నెట్‌వర్కింగ్‌కు మీరు వ్యక్తులతో సౌకర్యవంతంగా మాట్లాడటం, వారితో సన్నిహితంగా ఉండటం మరియు ఇతర వ్యక్తులు ఏమి చేస్తున్నారో ఆసక్తి కలిగి ఉండటం అవసరం. ఇండస్ట్రీలో ప్రస్తుత ట్రెండ్‌ను తెలుసుకోవడానికి నెట్‌వర్కింగ్ మీకు సహాయపడుతుంది.",
            "or": "ଯେତେବେଳେ ସେ ଏକ ନୂତନ ଚାକିରି ଖୋଜିବା ଆରମ୍ଭ କରନ୍ତି ସେତେବେଳେ ନବୀନ ଶ୍ରୀମତୀ ସିହ୍ନାଙ୍କ ନିକଟରେ ପହଞ୍ଚିଥିଲେ | ଏହା ନେଟୱର୍କିଂର ଏକ ରୂପ | ଲୋକଙ୍କୁ ଭେଟିବା ଏବଂ ସଂଯୋଗ କରିବା ବୃତ୍ତିଗତ ଜୀବନର ଅତ୍ୟାବଶ୍ୟକ ଅଂଶ ଅଟେ | ନେଟୱର୍କିଂ ଆପଣଙ୍କୁ ଲୋକମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରିବା, ସେମାନଙ୍କ ସହିତ ଯୋଗାଯୋଗରେ ରହିବା ଏବଂ ଅନ୍ୟ ଲୋକମାନେ କ’ଣ କରୁଛନ୍ତି ସେଥିପାଇଁ ଆଗ୍ରହୀ ହେବା ଆବଶ୍ୟକ କରେ | ନେଟୱାର୍କିଂ ଆପଣଙ୍କୁ ଇଣ୍ଡଷ୍ଟ୍ରିର ଅତ୍ୟାଧୁନିକ ଟ୍ରେଣ୍ଡ ଜାଣିବାରେ ମଧ୍ୟ ସାହାଯ୍ୟ କରେ |",
            "as": "নবীনে শ্ৰীমতী সিনহাৰ ওচৰলৈ গৈছিল যেতিয়া সি নতুন চাকৰি বিচাৰিবলৈ আৰম্ভ কৰিছিল। এইটো নেটৱৰ্কিঙৰ এক প্ৰকাৰ। মানুহক লগ পোৱা আৰু সংযোগ স্থাপন কৰাটো পেছাদাৰী জীৱনৰ অপৰিহাৰ্য অংশ। নেটৱৰ্কিঙৰ বাবে আপুনি মানুহৰ সৈতে কথা পাতিবলৈ, তেওঁলোকৰ সৈতে যোগাযোগ ৰাখিবলৈ আৰু আন লোকসকলে কি কৰি আছে তাৰ প্ৰতি আগ্ৰহী হোৱাটো আৱশ্যক। নেটৱৰ্কিঙে আপোনাক উদ্যোগৰ শেহতীয়া প্ৰৱণতাৰ বিষয়ে জনাতো সহায় কৰে।",
            "gu": "નવીન નોકરી શોધવા માટે શ્રીમતી સિન્હાનો સંપર્ક કરે છે. આ એક સ્વરૂપ નેટવર્કીંગનું છે. લોકોને મળવું અને ભેગા થવું એ વ્યાવસાયિક જીવનના જરૂરી ભાગો છે. લોકો સાથે વાત કરવો એ નેટવર્કીંગ માટે જરૂરી છે, તેમના સંપર્કમાં રહેવા અને બીજા લોકો શું કરી રહ્યા છે તેમાં રસ ધરાવો. નવીનતમ વલણ જાણવામાં પણ નેટવર્કિંગ તમને ઉદ્યોગમાં  મદદ કરશે."
          }
        }
      ],
      "page16": [
        {
          "valuePath": "display",
          "value": {
            "en": "mcq",
            "hi": "mcq",
            "ka": "ಎಂಸಿಕ್ಯೂ",
            "te": "mcq",
            "or": "mcq",
            "as": "mcq",
            "gu": "mcq"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Select a activity that is called ‘Networking’:",
            "hi": "‘नेटवर्किंग’ नाम की एक्टिविटी चुनें:",
            "ka": "ನೆಟ್ವರ್ಕಿಂಗ್' ಎಂದು ಕರೆಯಲ್ಪಡುವ ಚಟುವಟಿಕೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ:",
            "te": "నెట్‌వర్కింగ్' అని పిలువబడే కార్యాచరణను ఎంచుకోండి:",
            "or": "ଏକ କାର୍ଯ୍ୟକଳାପ ଚୟନ କରନ୍ତୁ ଯାହାକୁ ‘ନେଟୱର୍କିଙ୍ଗ’ କୁହାଯାଏ:",
            "as": "নেটৱৰ্কিং' বুলি কোৱা কাৰ্য্য এটা বাছনি কৰা:",
            "gu": "જેને 'નેટવર્કિંગ' કહેવાય તેવી પ્રવૃત્તિ પસંદ કરો:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Attending industry events and conferences to meet people.",
            "hi": "लोगों से मिलने के लिए इंडस्ट्री के इवेंट और कॉन्फरेंस में हिस्सा लेना।",
            "ka": "ಜನರನ್ನು ಭೇಟಿ ಮಾಡಲು ಉದ್ಯಮ ಕಾರ್ಯಕ್ರಮಗಳು ಮತ್ತು ಸಮ್ಮೇಳನಗಳಿಗೆ ಹಾಜರಾಗುವುದು.",
            "te": "ప్రజలను కలవడానికి పరిశ్రమ ఈవెంట్‌లు మరియు సమావేశాలకు హాజరు అవ్వడం.",
            "or": "ଲୋକମାନଙ୍କୁ ଭେଟିବା ପାଇଁ ଇଣ୍ଡଷ୍ଟ୍ରି ଇଭେଣ୍ଟ ଏବଂ ସମ୍ମିଳନୀରେ ଯୋଗଦେବା |",
            "as": "মানুহক লগ কৰিবলৈ উদ্যোগৰ অনুষ্ঠান আৰু সন্মিলনত অংশগ্ৰহণ কৰা।",
            "gu": "ઉદ્યોગના કાર્યક્રમો અને પરિષદોમાં હાજરી આપવી જેથી લોકોને મળી શકાય."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Reaching out to your alumni network to look for new jobs.",
            "hi": "नए जॉब ढूंढने के लिए अपने भूतपूर्व छात्रों से मिलना",
            "ka": "ಹೊಸ ಉದ್ಯೋಗಗಳನ್ನು ಹುಡುಕಲು ನಿಮ್ಮ ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳ ನೆಟ್‌ವರ್ಕ್ ಅನ್ನು ತಲುಪುವುದು.",
            "te": "కొత్త ఉద్యోగాల కోసం మీ పూర్వ విద్యార్థుల నెట్‌వర్క్‌ను కలుసుకోవడం.",
            "or": "ନୂତନ ଚାକିରି ଖୋଜିବା ପାଇଁ ତୁମର ଆଲୁମ୍ନି ନେଟୱର୍କରେ ପହଞ୍ଚିବା |",
            "as": "নতুন চাকৰি বিচাৰি তোমাৰ প্ৰাক্তন ছাত্ৰ-ছাত্ৰীৰ নেটৱৰ্কৰ ওচৰলৈ যোৱা।",
            "gu": "તમારા જૂના વિદ્યાર્થીઓના નેટવર્કથી નવી નોકરીઓ શોધવા માટે સરળ રહેશે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Meeting a friend to discuss your career goals and getting their advice.",
            "hi": "अपने करियर के लक्ष्य के बारे में चर्चा करने के लिए और सलाह लेने के लिए अपने दोस्तों से मिलना।",
            "ka": "ನಿಮ್ಮ ವೃತ್ತಿಜೀವನದ ಗುರಿಗಳನ್ನು ಚರ್ಚಿಸಲು ಮತ್ತು ಅವರ ಸಲಹೆಯನ್ನು ಪಡೆಯಲು ಸ್ನೇಹಿತರನ್ನು ಭೇಟಿ ಮಾಡಿ.",
            "te": "మీ కెరీర్ లక్ష్యాలను చర్చించడానికి మరియు వారి సలహాలను పొందడానికి స్నేహితుడిని కలవడం.",
            "or": "ତୁମ କ୍ୟାରିୟର୍‌ ଲକ୍ଷ୍ୟ ବିଷୟରେ ଆଲୋଚନା କରିବା ଏବଂ ସେମାନଙ୍କ ପରାମର୍ଶ ପାଇବା ପାଇଁ ଜଣେ ବନ୍ଧୁଙ୍କୁ ଭେଟିବା |",
            "as": "তোমাৰ জীৱিকাৰ লক্ষ্যবোৰ আলোচনা কৰিবলৈ আৰু পৰামৰ্শ লাভ কৰিবলৈ বন্ধু এজনক লগ কৰা।",
            "gu": "મિત્રને મળો જેથી તમારા કારકિર્દીના લક્ષ્યોની ચર્ચા થાય અને તેમની સલાહ સૂચન મેળવો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.display",
          "value": {
            "en": "Looking for job opportunities on company websites",
            "hi": "कंपनी के वेबसाइट पर नौकरी के अवसर ढूंढना",
            "ka": "ಕಂಪನಿಯ ವೆಬ್‌ಸೈಟ್‌ಗಳಲ್ಲಿ ಉದ್ಯೋಗಾವಕಾಶಗಳನ್ನು ಹುಡುಕಲಾಗುತ್ತಿದೆ",
            "te": "కంపెనీ వెబ్‌సైట్లలో ఉద్యోగం అవకాశాల కోసం వెతకడం",
            "or": "କମ୍ପାନୀ ୱେବସାଇଟଗୁଡିକରେ ଚାକିରି ସୁଯୋଗ ଖୋଜିବା |",
            "as": "কোম্পানীৰ ৱেবছাইটত চাকৰিৰ সুযোগ বিচৰা",
            "gu": "નોકરીની તકો માટે કંપનીની વેબસાઇટ્સ પર શોધો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Great job! This is called Networking",
            "hi": "बहुत बढ़िया! इसे नेटवर्किंग कहते हैं",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ! ಇದನ್ನು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ",
            "te": "బాగా చెప్పారు! దీనినే నెట్‌వర్కింగ్ అంటారు",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ ! ଏହାକୁ ନେଟୱର୍କିଂ କୁହାଯାଏ |",
            "as": "ভাল কৰিছা! ইয়াক নেটৱৰ্কিং বুলি কোৱা হয়",
            "gu": "સરસ કામ! આને નેટવર્કીંગ કહે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job! This is called Networking",
            "hi": "बहुत बढ़िया! इसे नेटवर्किंग कहते हैं",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ! ಇದನ್ನು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ",
            "te": "బాగా చెప్పారు! దీనినే నెట్‌వర్కింగ్ అంటారు",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ ! ଏହାକୁ ନେଟୱର୍କିଂ କୁହାଯାଏ |",
            "as": "ভাল কৰিছা! ইয়াক নেটৱৰ্কিং বুলি কোৱা হয়",
            "gu": "સરસ કામ! આને નેટવર્કીંગ કહે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Great job! This is called Networking",
            "hi": "बहुत बढ़िया! इसे नेटवर्किंग कहते हैं",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ! ಇದನ್ನು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ",
            "te": "బాగా చెప్పారు! దీనినే నెట్‌వర్కింగ్ అంటారు",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ ! ଏହାକୁ ନେଟୱର୍କିଂ କୁହାଯାଏ |",
            "as": "ভাল কৰিছা! ইয়াক নেটৱৰ্কিং বুলি কোৱা হয়",
            "gu": "સરસ કામ! આને નેટવર્કીંગ કહે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.popUpText",
          "value": {
            "en": "Think again! Looking for job opportunities on websites would be called research",
            "hi": "फिर से सोचो! वेबसाइट पर नौकरी के अवसर ढूंढने को रिसर्च कहा जाता है",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ವೆಬ್‌ಸೈಟ್‌ಗಳಲ್ಲಿ ಉದ್ಯೋಗಾವಕಾಶಗಳನ್ನು ಹುಡುಕುವುದನ್ನು ಸಂಶೋಧನೆ ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ",
            "te": "మళ్లీ ఆలోచించండి! వెబ్‌సైట్లలో ఉద్యోగం అవకాశాల కోసం వెతకడాన్ని పరిశోధన అంటారు",
            "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ୱେବସାଇଟଗୁଡିକରେ ଚାକିରି ସୁଯୋଗ ଖୋଜିବାକୁ ଗବେଷଣା କୁହାଯିବ",
            "as": "আকৌ চিন্তা কৰা! ৱেবছাইটত চাকৰিৰ সুযোগ বিচৰাটোক গৱেষণা বুলি কোৱা হ'ব",
            "gu": "વિચારો ફરીથી! નોકરીની તકો વેબસાઇટ્સ પર શોધવી એ સંશોધન કહેવાશે"
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_3_1",
            "hi": "B6_3_1",
            "ka": "B6_3_1",
            "te": "B6_3_1",
            "or": "B6_3_1",
            "as": "B6_3_1",
            "gu": "B6_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She puts Naveen in touch with a Television production house, where he manages to get a job as a production assistant.",
            "hi": "वह टेलीविजन प्रोडक्शन हाउस से नवीन का संपर्क करवाती है, जहाँ उसे प्रोडक्शन असिस्टेंट की अच्छी नौकरी मिल जाती है।",
            "ka": "ಅವರು ನವೀನ್ ಅನ್ನು ಟೆಲಿವಿಷನ್ ಪ್ರೊಡಕ್ಷನ್ ಹೌಸ್‌ನೊಂದಿಗೆ ಸಂಪರ್ಕದಲ್ಲಿರಿಸುತ್ತಾರೆ, ಅಲ್ಲಿ ಅವನು ಪ್ರೊಡಕ್ಷನ್ ಅಸಿಸ್ಟೆಂಟ್ ಆಗಿ ಕೆಲಸ ಪಡೆಯಲು ನಿರ್ವಹಿಸುತ್ತಾನೆ.",
            "te": "ఆమె నవీన్‌ను ఒక టెలివిజన్ ప్రొడక్షన్ హౌస్‌తో టచ్‌లో ఉంచుతుంది, అక్కడ అతను ప్రొడక్షన్ అసిస్టెంట్‌గా ఉద్యోగం తెచ్చుకుంటాడు.",
            "or": "ସେ ନବୀନଙ୍କୁ ଏକ ଟେଲିଭିଜନ ପ୍ରଡକ୍ସନ୍ ହାଉସ୍ ସହିତ ଯୋଗାଯୋଗ କରାନ୍ତି, ଯେଉଁଠାରେ ସେ କୌଶଳ କରି ଏକ ପ୍ରଡକ୍ସନ୍‌ ଆସିସ୍‍ଟାଣ୍ଟ୍‍ ଭାବରେ ଚାକିରି ପାଆନ୍ତି |",
            "as": "তেখেতে নবীনক এটা টেলিভিছন প্ৰডাকচন হাউচৰ সৈতে যোগাযোগ কৰাই দিয়ে, য'ত সি প্ৰডাকচন এচিষ্টেণ্ট হিচাপে চাকৰি এটা পাবলৈ সক্ষম হয়।",
            "gu": "તે ટીવી પ્રોડક્શન કંપની માટે નવીનનો સંપર્ક કરશે, જ્યાં તે સહાયક તરીકે નિર્માતાની નિમણૂક કરે છે."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_3_2",
            "hi": "B6_3_2",
            "ka": "B6_3_2",
            "te": "B6_3_2",
            "or": "B6_3_2",
            "as": "B6_3_2",
            "gu": "B6_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "In his first 2 months, he works on managing the setup of for a dance reality show. Naveen is happy with this project.  His experience as a dancer helps him at work.",
            "hi": "नौकरी शुरू होने के बाद पहले 2 महीनों में, उसने डान्स रियलिटी शो के लिए सेटअप मैनेज करने का काम किया। नवीन इस प्रोजेक्ट को लेकर बहुत खुश है। उसका डान्स के अनुभव अब उसके काम आ रहा है।",
            "ka": "ಅವನ ಮೊದಲ 2 ತಿಂಗಳುಗಳಲ್ಲಿ, ಅವನು ನೃತ್ಯ ರಿಯಾಲಿಟಿ ಶೋನ ಸೆಟಪ್ ಅನ್ನು ನಿರ್ವಹಿಸುವಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಾನೆ. ಈ ಯೋಜನೆಯಿಂದ ನವೀನ್ ಖುಷಿಯಾಗಿದ್ದಾನೆ. ನೃತ್ಯಗಾರನಾಗಿ ಅವನ ಅನುಭವವು ಕೆಲಸದಲ್ಲಿ ಅವನಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
            "te": "అతని మొదటి 2 నెలల్లో, అతను డ్యాన్స్ రియాలిటీ షో యొక్క సెటప్‌ నిర్వహణలో పని చేస్తాడు. ఈ ప్రాజెక్ట్ పట్ల నవీన్ సంతోషంగా ఉన్నాడు.డ్యాన్సర్ గా అతని అనుభవం పని లో అతనికి సహాయపడుతుంది.",
            "or": "ତାଙ୍କର ପ୍ରଥମ 2 ମାସରେ, ସେ ଏକ ଡ୍ୟାନ୍ସ ରିଆଲିଟି ସୋ ପାଇଁ ସେଟଅପ୍ ପରିଚାଳନା କରିବାରେ କାର୍ଯ୍ୟ କରନ୍ତି | ଏହି ପ୍ରୋଜେକ୍ଟ ରେ ନବୀନ ଖୁସି ଅଛନ୍ତି। ଜଣେ ନୃତ୍ୟଶିଳ୍ପୀ ଭାବରେ ତାଙ୍କର ଅଭିଜ୍ଞତା ତାଙ୍କୁ କାର୍ଯ୍ୟରେ ସାହାଯ୍ୟ କରେ |",
            "as": "তাৰ প্ৰথম দুমাহত, সি এটা নৃত্য ৰিয়েলিটি শ্ব'ৰ ছেটআপ পৰিচালনা কৰাৰ ওপৰত কাম কৰে। নবীন এই প্ৰকল্পটো লৈ সুখী। এগৰাকী নৃত্যশিল্পী হিচাপে তাৰ অভিজ্ঞতাই তাক কামত সহায় কৰিছে।",
            "gu": "પહેલા 2 મહિના તે ડાન્સ રિયાલિટી શોના આયોજન પર કામ કરે છે. નવીન આ પ્રોજેક્ટથી ખુશ છે. ડાન્સરનો અનુભવ કામમાં મદદ કરે છે."
          }
        }
      ],
      "page12": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_3_3",
            "hi": "B6_3_3",
            "ka": "B6_3_3",
            "te": "B6_3_3",
            "or": "B6_3_3",
            "as": "B6_3_3",
            "gu": "B6_3_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Over the next few years Naveen manages many shows. His family and friends are proud of what he has achieved.",
            "hi": "अगले कुछ सालों में नवीन कई शो मैनेज करता है। उसके परिवार के सदस्य और दोस्त उसकी कामयाबी पर गर्व महसूस करते हैं।",
            "ka": "ಮುಂದಿನ ಕೆಲವು ವರ್ಷಗಳಲ್ಲಿ ನವೀನ್ ಅನೇಕ ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ನಿರ್ವಹಿಸುತ್ತಾನೆ. ಅವನು ಸಾಧಿಸಿದ್ದಕ್ಕೆ ಅವನ ಕುಟುಂಬ ಮತ್ತು ಸ್ನೇಹಿತರು ಹೆಮ್ಮೆಪಡುತ್ತಾರೆ.",
            "te": "తరువాత సంవత్సరాలలో నవీన్ చాలా షోలు నిర్వహించాడు. అతను సాధించిన వాటికి అతని కుటుంబం మరియు స్నేహితులు గర్వపడుతున్నారు.",
            "or": "ପରବର୍ତ୍ତୀ କିଛି ବର୍ଷ ମଧ୍ୟରେ ନବୀନ ଅନେକ ଶୋ ପରିଚାଳନା କରନ୍ତି | ସେ ଯାହା ହାସଲ କରିଛନ୍ତି ସେଥିପାଇଁ ତାଙ୍କ ପରିବାର ଏବଂ ବନ୍ଧୁମାନେ ଗର୍ବିତ |",
            "as": "পৰৱৰ্তী কেইবছৰমানত নবীনে বহুতো অনুষ্ঠান পৰিচালনা কৰে। তাৰ পৰিয়াল আৰু বন্ধুবৰ্গ তাৰ বাবে গৌৰৱান্বিত।",
            "gu": "આગામી થોડા વર્ષોમાં, નવીન ઘણા શો કરે છે. તેણે જે પ્રગતિ કર્યું છે તેના પર તેના પરિવાર અને મિત્રોને ગર્વ છે."
          }
        }
      ],
      "page13": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_3_4",
            "hi": "B6_3_4",
            "ka": "B6_3_4",
            "te": "B6_3_4",
            "or": "B6_3_4",
            "as": "B6_3_4",
            "gu": "B6_3_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen is happy that within 2-3 years of working, he is earning nearly ₹50,000 a month. With his savings, he sets up a small shop for his mother near his house.",
            "hi": "नवीन बहुत खुश है कि काम करने के 2-3 सालों में ही वह तकरीबन महिना ₹50,000 कमा रहा है। अपने बचत किए हुए पैसों से वह अपनी माँ को घर के नजदीक एक छोटी सी दुकान खोलकर देता है।",
            "ka": "ಕೆಲಸ ಮಾಡಿದ 2-3 ವರ್ಷಗಳಲ್ಲಿ ತಿಂಗಳಿಗೆ ಸುಮಾರು ₹50 ಸಾವಿರ ಆದಾಯ ಬರುತ್ತಿದೆ ಎಂದು ನವೀನ್ ಸಂತಸ ವ್ಯಕ್ತಪಡಿಸಿದ್ದಾನೆ. ತನ್ನ ಉಳಿತಾಯದ ಹಣದಲ್ಲಿ ತನ್ನ ಮನೆಯ ಸಮೀಪವೇ ತನ್ನ ತಾಯಿಗಾಗಿ ಒಂದು ಚಿಕ್ಕ ಅಂಗಡಿಯನ್ನು ಇಡುತ್ತಾನೆ.",
            "te": "పనిచేసిన 2-3 సంవత్సరాలలో నెలకు దాదాపు ₹50,000 సంపాదిస్తున్నానని నవీన్ సంతోషిస్తున్నాడు. తన సేవింగ్స్ తో ఇంటి దగ్గరే తన తల్లికి చిన్న దుకాణం పెడతాడు.",
            "or": "ନବୀନ ଖୁସି ଯେ କାମ କରିବାର 2-3 ବର୍ଷ ମଧ୍ୟରେ ସେ ମାସକୁ ପ୍ରାୟ, ₹50,000 ରୋଜଗାର କରୁଛନ୍ତି | ତାଙ୍କ ସଞ୍ଚୟ ସହିତ, ସେ ନିଜ ଘର ପାଖରେ ତାଙ୍କ ମା ପାଇଁ ଏକ ଛୋଟ ଦୋକାନ ସ୍ଥାପନ କରନ୍ତି |",
            "as": "নবীন সুখী যে কাম কৰাৰ দুই-তিনিবছৰৰ ভিতৰত, সি মাহে প্ৰায় 50,000 টকা উপাৰ্জন কৰিছে। তাৰ সঞ্চয়খিনিৰে, সি তাৰ ঘৰৰ ওচৰত মাকৰ বাবে এখন সৰু দোকান খুলি দিছে।",
            "gu": "નવીન 2-3 વર્ષના કામ કરે છે અને દર મહિને લગભગ 50,000 રૂપિયા કમાઈને ખુશ છે. પોતાની બચતથી તે તેની માતા માટે ઘરની નજીક એક નાની દુકાન રાખે છે."
          }
        }
      ],
      "page14": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Event manager",
            "hi": "इवेंट मैनेजर",
            "ka": "**THREAD_END**_ಸಮಾರಂಭ ವ್ಯವಸ್ಥಾಪಕ",
            "te": "**THREAD_END**_ఈవెంట్ మేనేజర్",
            "or": "**THREAD_END**_Event manager",
            "as": "**থ্ৰেড_সমাপ্ত**_ইভেন্ট মেনেজাৰ",
            "gu": "**THREAD_END**_ઇવેન્ટ મેનેજર"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Naveen that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने नवीन के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँचा है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकता है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n \n ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾದ ನವೀನ್‌ಗಾಗಿ ನೀವು ಆಯ್ಕೆಗಳನ್ನು ಮಾಡಿದ್ದೀರಿ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు నవీన్ కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ !\n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି !\n \n ଆପଣ ନବୀନଙ୍କ ପାଇଁ ବାଛିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତି ଘଟାଇଛି | ଆପଣ କରୁଥିବା ପସନ୍ଦ ଉପରେ ଆଧାର କରି ଭିନ୍ନ ଭିନ୍ନ ସମାପ୍ତି ଅଛି | ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକୁ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ?",
            "as": "অভিনন্দন! তুমি এটা যাত্ৰা সমাপ্ত কৰিছা ৷ তুমি নবীনৰ বাবে কৰা বাছনিবোৰৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত ভিত্তি কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ চাব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક મુસાફરી પૂરી કરી છે!\n\nતમે નવીન માટે પસંદગીઓ કરી છે જે આ સંભવિત અંત તરફ દોરી ગઈ છે. તમે જે પસંદગી કરી છે તેનાથી અલગ અલગ અંત છે. શું તમે બીજા દ્રશ્યો ખોલવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ରକୁ ଦେଖନ୍ତୁ |",
            "as": "মানচিত্ৰখনৰ পিনে চোৱা",
            "gu": "ચાલો નકશા પર એક નજર નાખીએ"
          }
        }
      ]
    },
    "scene10": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_1_1_Career in sales",
            "hi": "सेल्स में करियर",
            "ka": "B7_1_1_ಮಾರಾಟದಲ್ಲಿ ವೃತ್ತಿ",
            "te": "B7_1_1_సేల్స్ లో కెరీర్",
            "or": "B7_1_1_Career in sales",
            "as": "B7_1_1_বিক্ৰীৰ ওপৰত জীৱিকা",
            "gu": "B7_1_1_સેલ્સમાં કારકિર્દી"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen applies for the sales executive role at Alliance Digital, a chain of showrooms for electric and electronic appliances.",
            "hi": "नवीन सेल्स एक्जीक्यूटिव की नौकरी के लिए अलायन्स डिजिटल में अप्लाई करता है, जो इलेक्ट्रिक और इलेक्ट्रॉनिक उपकरणों के शोरूम्स कि चैन है।",
            "ka": "ನವೀನ್ ಎಲೆಕ್ಟ್ರಿಕ್ ಮತ್ತು ಎಲೆಕ್ಟ್ರಾನಿಕ್ ಉಪಕರಣಗಳ ಶೋರೂಮ್‌ಗಳ ಸರಣಿಯಾದ ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್‌ನಲ್ಲಿ ಮಾರಾಟ ಕಾರ್ಯನಿರ್ವಾಹಕ ಪಾತ್ರಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಾನೆ.",
            "te": "ఎలక్ట్రిక్ మరియు ఎలక్ట్రానిక్ అప్లియన్సస్ షోరూమ్‌ల గొలుసు అయిన అలయన్స్ డిజిటల్‌లో సేల్స్ ఎగ్జిక్యూటివ్ పాత్ర కోసం నవీన్ దరఖాస్తు చేసుకున్నాడు.",
            "or": "ଇଲେକ୍ଟ୍ରିକ୍ ଏବଂ ଇଲେକ୍ଟ୍ରୋନିକ୍ ଉପକରଣ ପାଇଁ ଶୋରୁମ୍ଗୁଡ଼ିକର ଏକ ଶୃଙ୍ଖଳା ଆଲାଇନ୍ସ ଡିଜିଟାଲ୍ ରେ ସେଲ୍ସ ଏକ୍ସିକ୍ୟୁଟିଭ୍‌ ଭୂମିକା ପାଇଁ ନବୀନ ଆବେଦନ କରନ୍ତି |",
            "as": "নবীনে বৈদ্যুতিক আৰু বৈদ্যুতিক সঁজুলিৰ বাবে শ্ব'ৰুমৰ শৃংখলা এলায়েন্স ডিজিটেলত বিক্ৰী কাৰ্যবাহী ভূমিকাৰ বাবে আবেদন কৰে।",
            "gu": "નવીન એલાયન્સ ડિજિટલ, હાર્ડવેર અને ઇલેક્ટ્રોનિક્સ શોરૂમના નેટવર્કમાં વેચાણ માટે અરજી કરી રહ્યો છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_1_2",
            "hi": "B7_1_2",
            "ka": "B7_1_2",
            "te": "B7_1_2",
            "or": "B7_1_2",
            "as": "B7_1_2",
            "gu": "B7_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He feels his application is strong as he fulfils all criteria mentioned in the job description.",
            "hi": "उसे लगता है कि उसका एप्लीकेशन बहुत अच्छा है क्योंकि वह नौकरी के विवरण में दिए हुए सभी क्रायटेरिया को पूरा करता है।",
            "ka": "ಉದ್ಯೋಗ ವಿವರಣೆಯಲ್ಲಿ ಉಲ್ಲೇಖಿಸಲಾದ ಎಲ್ಲಾ ಮಾನದಂಡಗಳನ್ನು ಅವನು ಪೂರೈಸುವುದರಿಂದ ಅವನ ಅಪ್ಲಿಕೇಶನ್ ಪ್ರಬಲವಾಗಿದೆ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ.",
            "te": "ఉద్యోగ వివరణలో పేర్కొన్న అన్ని ప్రమాణాలను అతను పూర్తి చేసినందున తన దరఖాస్తు బలంగా ఉందని అతను భావిస్తున్నాడు.",
            "or": "ଚାକିରିର ବର୍ଣ୍ଣନାରେ ଉଲ୍ଲେଖ କରାଯାଇଥିବା ସମସ୍ତ ମାନଦଣ୍ଡ ପୂରଣ କରୁଥିବାବେଳେ ସେ ତାଙ୍କର ଆବେଦନକୁ ଶକ୍ତିଶାଳୀ ବୋଲି ଅନୁଭବ କରନ୍ତି |",
            "as": "সি অনুভৱ কৰে যে তাৰ আবেদনপত্ৰখন শক্তিশালী কিয়নো সি চাকৰিৰ বিৱৰণত উল্লেখ কৰা সকলোবোৰ চৰ্ত পূৰণ কৰে।",
            "gu": "તેને લાગે છે કે તેની અરજી મજબૂત છે કારણ કે તે નોકરીના વર્ણનમાં આપેલા તમામ માપદંડોને પૂર્ણ કરે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_1_3",
            "hi": "B7_1_3",
            "ka": "B7_1_3",
            "te": "B7_1_3",
            "or": "B7_1_3",
            "as": "B7_1_3",
            "gu": "B7_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The interview goes well, and he gets a job as a sales executive at Alliance Digital.",
            "hi": "इन्टरव्यू अच्छा होता है और उसे अलायन्स डिजिटल में सेल्स एक्जीक्यूटिव की नौकरी मिल जाती हैं।",
            "ka": "ಸಂದರ್ಶನವು ಉತ್ತಮವಾಗಿ ನಡೆಯುತ್ತದೆ, ಮತ್ತು ಅವನು ಅಲೈಯನ್ಸ್ ಡಿಜಿಟಲ್‌ನಲ್ಲಿ ಮಾರಾಟ ಕಾರ್ಯನಿರ್ವಾಹಕರಾಗಿ ಕೆಲಸ ಪಡೆಯುತ್ತಾನೆ.",
            "te": "ఇంటర్వ్యూ బాగా జరిగింది మరియు అతను అలయన్స్ డిజిటల్‌లో సేల్స్ ఎగ్జిక్యూటివ్‌గా ఉద్యోగం తెచ్చుకున్నాడు.",
            "or": "ସାକ୍ଷାତକାର ଭଲରେ ହୁଏ, ଏବଂ ସେ ଆଲାଇନ୍ସ ଡିଜିଟାଲରେ ସେଲ୍ସ ଏକ୍ସିକ୍ୟୁଟିଭ୍‌ ଭାବରେ ଚାକିରି ପାଆନ୍ତି |",
            "as": "তাৰ সাক্ষাৎকাৰটো ভাল হয়, আৰু সি এলায়েন্স ডিজিটেলত বিক্ৰী কাৰ্যবাহী হিচাপে চাকৰি লাভ কৰে।",
            "gu": "ઇન્ટરવ્યુ સારી રીતે જાય છે અને તે એલાયન્સ ડિજિટલમાં સેલ્સ એક્ઝિક્યુટિવ તરીકે કામ મળે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_2_1",
            "hi": "B7_2_1",
            "ka": "B7_2_1",
            "te": "B7_2_1",
            "or": "B7_2_1",
            "as": "B7_2_1",
            "gu": "B7_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He works 6 days a week and has to focus on the sales of various electronic goods sold by Alliance, such as smartphones, TVs, refrigerators etc.",
            "hi": "वह हफ्ते के 6 दिन काम करता है और अलायन्स में बिकनेवाले कई तरह के इलेक्ट्रॉनिक उपकरण, जैसे कि स्मार्टफोन्स, टीवी फ्रिज जैसे सामानों की बिक्री का उसे ध्यान रखना होता है।",
            "ka": "ಅವನು ವಾರದಲ್ಲಿ 6 ದಿನ ಕೆಲಸ ಮಾಡುತ್ತಾನೆ ಮತ್ತು ಅಲಯನ್ಸ್ ಮಾರಾಟ ಮಾಡುವ ವಿವಿಧ ಎಲೆಕ್ಟ್ರಾನಿಕ್ ಸರಕುಗಳ ಮಾರಾಟದ ಮೇಲೆ ಗಮನ ಹರಿಸಬೇಕು, ಉದಾಹರಣೆಗೆ ಸ್ಮಾರ್ಟ್‌ಫೋನ್‌ಗಳು, ಟಿವಿಗಳು, ರೆಫ್ರಿಜರೇಟರ್‌ಗಳು ಇತ್ಯಾದಿ.",
            "te": "అతను వారానికి 6 రోజులు పని చేస్తాడు మరియు స్మార్ట్‌ఫోన్‌లు, టీవీలు, రిఫ్రిజిరేటర్‌లు మొదలైన అలయన్స్ విక్రయించే వివిధ ఎలక్ట్రానిక్ వస్తువుల అమ్మకాలపై దృష్టి పెట్టాలి.",
            "or": "ସେ ସପ୍ତାହରେ 6 ଦିନ କାମ କରନ୍ତି ଏବଂ ଆଲିଆନ୍ସ ଦ୍ୱାରା ବିକ୍ରି ହେଉଥିବା ବିଭିନ୍ନ ଇଲେକ୍ଟ୍ରୋନିକ୍ ସାମଗ୍ରୀ ଯଥା ସ୍ମାର୍ଟଫୋନ୍, ଟିଭି, ରେଫ୍ରିଜରେଟର ଇତ୍ୟାଦି ଉପରେ ଧ୍ୟାନ ଦେବାକୁ ପଡ଼େ |",
            "as": "সি সপ্তাহত ছয়দিন কাম কৰে আৰু এলায়েন্সৰ দ্বাৰা বিক্ৰী কৰা বিভিন্ন ইলেক্ট্ৰনিক সামগ্ৰীৰ বিক্ৰীৰ ওপৰত গুৰুত্ব দিব লাগে, যেনে স্মাৰ্টফোন, টিভি, ৰেফ্ৰিজেৰেটৰ ইত্যাদি।",
            "gu": "તે અઠવાડિયામાં 6 દિવસ કામ કરે છે અને એલાયન્સ દ્વારા વેચાતી વિવિધ ઈલેક્ટ્રોનિક ચીજવસ્તુઓ જેમ કે સ્માર્ટફોન, ટીવી, રેફ્રિજરેટર્સ વગેરેના વેચાણ પર ધ્યાન કેન્દ્રિત કરે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_2_2",
            "hi": "B7_2_2",
            "ka": "B7_2_2",
            "te": "B7_2_2",
            "or": "B7_2_2",
            "as": "B7_2_2",
            "gu": "B7_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "His job includes reaching his sales targets, preparing weekly and monthly reports, and giving sales presentations to potential clients.",
            "hi": "उसके काम में शामिल हैं सेल्स के टारगेट पुरे करना, साप्ताहिक और मासिक रिपोर्ट बनाना और संभाव्य ग्राहकों को सेल्स प्रेजेंटेशन देना।",
            "ka": "ಅವನ ಕೆಲಸವು ತನ್ನ ಮಾರಾಟದ ಗುರಿಗಳನ್ನು ತಲುಪುವುದು, ಸಾಪ್ತಾಹಿಕ ಮತ್ತು ಮಾಸಿಕ ವರದಿಗಳನ್ನು ಸಿದ್ಧಪಡಿಸುವುದು ಮತ್ತು ಸಂಭಾವ್ಯ ಗ್ರಾಹಕರಿಗೆ ಮಾರಾಟ ಪ್ರಸ್ತುತಿಗಳನ್ನು ನೀಡುವುದನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.",
            "te": "అతని ఉద్యోగంలో అతని అమ్మకాల లక్ష్యాలను చేరుకోవడం, వారంవారీ మరియు నెలవారీ నివేదికలను సిద్ధం చేయడం మరియు పని చేయ శక్తి కలిగిన ఖాతాదారులకు విక్రయాల ప్రదర్శనలు ఇవ్వడం వంటివి ఉన్నాయి.",
            "or": "ତାଙ୍କର କାର୍ଯ୍ୟ ଅନ୍ତର୍ଭୁକ୍ତ କରେ ତାଙ୍କର ବିକ୍ରୟ ଲକ୍ଷ୍ୟରେ ପହଞ୍ଚିବା, ସାପ୍ତାହିକ ଏବଂ ମାସିକ ରିପୋର୍ଟ ପ୍ରସ୍ତୁତ କରିବା ଏବଂ ସମ୍ଭାବ୍ୟ ଗ୍ରାହକଙ୍କୁ ବିକ୍ରୟ ଉପସ୍ଥାପନା ଦେବା |",
            "as": "তাৰ কামবোৰ হৈছে বিক্ৰী লক্ষ্যত উপনীত হোৱা, সাপ্তাহিক আৰু মাহিলী প্ৰতিবেদন প্ৰস্তুত কৰা, আৰু সম্ভাৱ্য গ্ৰাহকসকলক বিক্ৰীৰ প্ৰেজেণ্টেচন দিয়া।",
            "gu": "તેના કાર્યમાં વેચાણના લક્ષ્યો સુધી પોહચવા, અઠવાડીયા અને મહિના અહેવાલો તૈયાર કરવા અને સંભવિત ગ્રાહકોને વેચાણ રજૂ કરવાનો સમાવેશ થાય છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_3_1",
            "hi": "B7_3_1",
            "ka": "B7_3_1",
            "te": "B7_3_1",
            "or": "B7_3_1",
            "as": "B7_3_1",
            "gu": "B7_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Seeing his communication skills, he gets moved to laptops and tablets sales sections.  Soon enough, his manager asks him to train new joinees.",
            "hi": "उसके बातचीत के कौशल को देखकर, उसे लैपटॉप और टेबलेट सेल्स सेक्शन में भेजा जाता है। जल्द ही, उसका मैनजेर उसे नौकरी पर लगे नए कर्मचारियों को ट्रेन करने के लिए कहता है।",
            "ka": "ಅವನ ಸಂವಹನ ಕೌಶಲ್ಯವನ್ನು ನೋಡಿ, ಅವನು ಲ್ಯಾಪ್‌ಟಾಪ್‌ಗಳು ಮತ್ತು ಟ್ಯಾಬ್ಲೆಟ್‌ಗಳ ಮಾರಾಟ ವಿಭಾಗಗಳಿಗೆ ತೆರಳುತ್ತಾನೆ. ಶೀಘ್ರದಲ್ಲೇ, ಅವನ ಮ್ಯಾನೇಜರ್ ಹೊಸ ಸೇರುವವರಿಗೆ ತರಬೇತಿ ನೀಡಲು ಕೇಳುತ್ತಾರೆ.",
            "te": "అతని కమ్యూనికేషన్ నైపుణ్యాలను చూసి, అతను ల్యాప్‌టాప్‌లు మరియు టాబ్లెట్‌ల విక్రయాల విభాగాలకు మారాడు. త్వరలో, అతని మేనేజర్ కొత్త చేరిన వారికి శిక్షణ ఇవ్వమని అడుగుతాడు.",
            "or": "ତାଙ୍କର ଯୋଗାଯୋଗ କୌଶଳ ଦେଖି ସେ ଲାପଟପ୍ ଏବଂ ଟାବଲେଟ୍ ବିକ୍ରୟ ବିଭାଗକୁ ଚାଲିଯାଆନ୍ତି | ଖୁବ୍ ଶୀଘ୍ର, ତାଙ୍କ ମ୍ୟାନେଜର୍‌ ତାଙ୍କୁ ନୂତନ ଯୋଗଦାନକାରୀଙ୍କୁ ତାଲିମ ଦେବାକୁ କୁହନ୍ତି |",
            "as": "তাৰ যোগাযোগৰ দক্ষতা দেখি, তাক লেপটপ আৰু টেবলেট বিক্ৰীৰ শাখালৈ বদলি কৰা হয়। অতি সোনকালেই, তাৰ মেনেজাৰে তাক নতুন যোগদানকাৰীসকলক প্ৰশিক্ষণ দিবলৈ কয়।",
            "gu": "તેની વાતચીત કરવાની આવડત સારી છે તે જોઈને તે લેપટોપ અને ટેબલેટના સેલ્સ વિભાગમાં મોકલે છે. થોડા સમયમાં તેનો મેનેજર તેને નવા કર્મચારીઓને તૈયાર કરવા કહે છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_3_2",
            "hi": "B7_3_2",
            "ka": "B7_3_2",
            "te": "B7_3_2",
            "or": "B7_3_2",
            "as": "B7_3_2",
            "gu": "B7_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "After 2 years, he was promoted to store front manager. \nHe constantly updates his professional social media profile to write about his roles and responsibilities at Alliance Digital.",
            "hi": "2 साल के बाद, उसे स्टोअर के फ्रंट मैनेजर के रूप में प्रमोशन मिल जाती है। \n वह हमेशा अपने प्रोफेशनल सोशल मिडिया प्रोफाइल में अलायन्स डिजिटिल में अपनी भूमिका और जिम्मेदारियों के बारे में लिखता रहता है।",
            "ka": "2 ವರ್ಷಗಳ ನಂತರ, ಅವನು ಸ್ಟೋರ್ ಫ್ರಂಟ್ ಮ್ಯಾನೇಜರ್ ಆಗಿ ಬಡ್ತಿ ಪಡೆದನು.\n ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್‌ನಲ್ಲಿ ಅವನ ಪಾತ್ರಗಳು ಮತ್ತು ಜವಾಬ್ದಾರಿಗಳ ಬಗ್ಗೆ ಬರೆಯಲು ಅವನು ತಮ್ಮ ವೃತ್ತಿಪರ ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ನಿರಂತರವಾಗಿ ನವೀಕರಿಸುತ್ತಾನೆ.",
            "te": "2 సంవత్సరాల తర్వాత, అతను స్టోర్ ఫ్రంట్ మేనేజర్‌గా పదోన్నతి పొందాడు.\n అలయన్స్ డిజిటల్‌లో తన పాత్రలు మరియు బాధ్యతల గురించి వ్రాయడానికి అతను తన వృత్తిపరమైన సోషల్ మీడియా ప్రొఫైల్‌ను నిరంతరం అప్‌డేట్ చేస్తాడు.",
            "or": "2 ବର୍ଷ ପରେ, ତାଙ୍କୁ ଷ୍ଟୋର୍‌ ଫ୍ରଣ୍ଟ ମ୍ୟାନେଜର କରିବାକୁ ପଦୋନ୍ନତି ଦିଆଗଲା |\n ଆଲାଇନ୍ସ ଡିଜିଟାଲରେ ତାଙ୍କର ଭୂମିକା ଏବଂ ଦାୟିତ୍ବଗୁଡିକ ବିଷୟରେ ଲେଖିବା ପାଇଁ ସେ କ୍ରମାଗତ ଭାବରେ ନିଜର ପ୍ରୋଫେସ୍‍ନାଲ୍‌ ସୋସିଆଲ୍ ମିଡିଆ ପ୍ରୋଫାଇଲ୍ ଅପଡେଟ୍ କରନ୍ତି |",
            "as": "দুইবছৰৰ পিছত তাক ষ্ট'ৰ ফ্ৰণ্ট মেনেজাৰ হিচাপে পদোন্নতি দিয়া হয় ৷ সি এলায়েন্স ডিজিটেলত তাৰ ভূমিকা আৰু দায়িত্বৰ বিষয়ে জনাবলৈ তাৰ পেছাদাৰী সামাজিক মাধ্যমৰ প্ৰ'ফাইল সদায় আপডেট কৰে।",
            "gu": "તેને સ્ટોર ફ્રન્ટ મેનેજર તરીકે બઢતી આપવામાં આવી 2 વર્ષ પછી. તે સતત તેની વ્યાવસાયિક સોશિયલ મીડિયા પ્રોફાઇલ બદલતો રહે  કરે છે એલાયન્સ ડિજિટલમાં અને તેની ભૂમિકાઓ અને જવાબદારીઓ વિશે લખે છે.\""
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_3_3",
            "hi": "B7_3_3",
            "ka": "B7_3_3",
            "te": "B7_3_3",
            "or": "B7_3_3",
            "as": "B7_3_3",
            "gu": "B7_3_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "A few months later, through his social media profile, he gets an offer as a Junior Sales Manager at Zell, a leading laptop manufacturing company.",
            "hi": "कुछ महीनों बाद, उसके सोशल मिडिया के प्रोफाइल के जरिए उसे झेल में ज्युनियर सेल्स मैनेजर के लिए ऑफर मिलता है, जो लैपटॉप बनानेवाली एक बड़ी कंपनी है।",
            "ka": "ಕೆಲವು ತಿಂಗಳ ನಂತರ, ಅವನ ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಪ್ರೊಫೈಲ್ ಮೂಲಕ, ಅವನು ಪ್ರಮುಖ ಲ್ಯಾಪ್‌ಟಾಪ್ ಉತ್ಪಾದನಾ ಕಂಪನಿಯಾದ ಝೆಲ್ ನಲ್ಲಿ ಜೂನಿಯರ್ ಸೇಲ್ಸ್ ಮ್ಯಾನೇಜರ್ ಆಗಿ ಪ್ರಸ್ತಾಪವನ್ನು ಪಡೆಯುತ್ತಾನೆ.",
            "te": "కొన్ని నెలల తర్వాత, అతని సోషల్ మీడియా ప్రొఫైల్ ద్వారా, అతను ఫేమస్ ల్యాప్‌టాప్ తయారీ కంపనీ అయిన Zell వద్ద జూనియర్ సేల్స్ మేనేజర్‌గా ఆఫర్‌ను పొందాడు.",
            "or": "କିଛି ମାସ ପରେ, ତାଙ୍କର ସୋସିଆଲ୍ ମିଡିଆ ପ୍ରୋଫାଇଲ୍ ମାଧ୍ୟମରେ, ସେ ଏକ ଅଗ୍ରଣୀ ଲାପଟପ୍ ଉତ୍ପାଦନକାରୀ କମ୍ପାନୀ ଜେଲ୍‌ରେ ଜୁନିୟର ସେଲ୍ସ ମ୍ୟାନେଜ‍ର୍‌ ଭାବରେ ଏକ ଅଫର୍ ପାଆନ୍ତି |",
            "as": "কেইমাহমানৰ পিছত, তাৰ ছ'চিয়েল মিডিয়া প্ৰ'ফাইলৰ জৰিয়তে, সি এক আগশাৰীৰ লেপটপ নিৰ্মাণ কোম্পানী জেলত জুনিয়ৰ চেলছ মেনেজাৰ হিচাপে এটা প্ৰস্তাৱ লাভ কৰে।",
            "gu": "થોડા મહિનાઓ પછી, તેની સોશિયલ મીડિયા પ્રોફાઇલ દ્વારા, તેને લેપટોપ નિર્માતા અગ્રણી જીલ ખાતે જુનિયર સેલ્સ મેનેજર બનવાની ઓફર મળી."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_4_1",
            "hi": "B7_4_1",
            "ka": "B7_4_1",
            "te": "B7_4_1",
            "or": "B7_4_1",
            "as": "B7_4_1",
            "gu": "B7_4_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen is surprised and also excited about the offer to work in a multinational company. He attends the interviews and gets a job offer.",
            "hi": "नवीन को आश्चर्य होता है और साथ ही मल्टीनैशनल कंपनी के ऑफर को लेकर वह उत्साहित भी है। वह इंटरव्यू के लिए जाता है और उसे जॉब ऑफर किया जाता है।",
            "ka": "ಬಹುರಾಷ್ಟ್ರೀಯ ಕಂಪನಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಪ್ರಸ್ತಾಪದ ಬಗ್ಗೆ ನವೀನ್ ಆಶ್ಚರ್ಯ ಮತ್ತು ಉತ್ಸುಕನಾಗಿದ್ದಾನೆ. ಅವನು ಸಂದರ್ಶನಗಳಿಗೆ ಹಾಜರಾಗುತ್ತಾನೆ ಮತ್ತು ಕೆಲಸದ ಪ್ರಸ್ತಾಪವನ್ನು ಪಡೆಯುತ್ತಾನೆ.",
            "te": "ఒక బహుళజాతి కంపెనీలో పని చేసే ఆఫర్ గురించి నవీన్ ఆశ్చర్యంతో పాటు సంతోషిస్తున్నాడు. అతను ఇంటర్వ్యూలకు హాజరయ్యాడు మరియు ఉద్యోగ ఆఫర్ పొందుతాడు.",
            "or": "ଏକ ବହୁରାଷ୍ଟ୍ରୀୟ କମ୍ପାନୀରେ କାମ କରିବାର ଅଫର୍‌ ବିଷୟରେ ନବୀନ ଆଶ୍ଚର୍ଯ୍ୟ ଏବଂ ଉତ୍ସାହିତ ମଧ୍ଯ ଅଛନ୍ତି | ସେ ସାକ୍ଷାତକାରରେ ଯୋଗ ଦିଅନ୍ତି ଏବଂ ଏକ ଚାକିରି ଅଫର୍‌ ପାଆନ୍ତି |",
            "as": "নবীন আচৰিত হ’ল আৰু বহুজাতিক কোম্পানী এটাৰ কামৰ প্ৰস্তাৱক লৈ উৎসাহিত হ’ল। তেওঁ সাক্ষাৎকাৰবোৰ দিলে আৰু চাকৰিৰ প্ৰস্তাৱ পায়।",
            "gu": "નવીનને બહુરાષ્ટ્રીય કંપનીમાં નોકરીની તક મળતા આશ્ચર્ય અને ઉત્સાહ અનુભવે છે. ઇન્ટરવ્યુમાં હાજરી આપે છે અને નોકરીની તક પણ મળે છે."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_4_2",
            "hi": "B7_4_2",
            "ka": "B7_4_2",
            "te": "B7_4_2",
            "or": "B7_4_2",
            "as": "B7_4_2",
            "gu": "B7_4_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The salary is 5% more than he earns at Alliance Digital. The best part for him is that he has to work only 5 days a week.",
            "hi": "उसे अलायन्स डिजिटल की सैलरी से 5% ज्यादा सैलरी मिलेगी। और सबसे अच्छी बात तो यह है की उसे हफ्ते में बस 5 दिन काम करना होगा।",
            "ka": "ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್‌ನಲ್ಲಿ ಅವನು ಗಳಿಸುವ ಸಂಬಳಕ್ಕಿಂತ 5% ಹೆಚ್ಚು. ಅವನಿಗೆ ಉತ್ತಮ ಭಾಗವೆಂದರೆ ಅವನು ವಾರದಲ್ಲಿ 5 ದಿನಗಳು ಮಾತ್ರ ಕೆಲಸ ಮಾಡಬೇಕು.",
            "te": "అలయన్స్ డిజిటల్‌లో అతను సంపాదించే జీతం కంటే 5% ఎక్కువ. అతనికి మంచి విషయం ఏమిటంటే అతను వారానికి 5 రోజులు మాత్రమే పని చేయాల్సి ఉంటుంది.",
            "or": "ଆଲାଇନ୍ସ ଡିଜିଟାଲରେ ତାଙ୍କ ରୋଜଗାରଠାରୁ ଦରମା 5% ଅଧିକ | ତାଙ୍କ ପାଇଁ ସର୍ବୋତ୍ତମ ଅଂଶ ହେଉଛି ଯେ ତାଙ୍କୁ ସପ୍ତାହରେ ମାତ୍ର 5 ଦିନ କାମ କରିବାକୁ ପଡିବ |",
            "as": "এলায়েন্স ডিজিটেলত তেওঁ উপাৰ্জন কৰাতকৈ দৰমহা 5% অধিক। তাৰ বাবে ভাল কথাটো হ'ল যে সি সপ্তাহত মাত্ৰ ৫ দিন কাম কৰিব লাগিব।",
            "gu": "એલાયન્સ ડિજિટલમાં કંપની કરતાં પગાર 5% વધુ છે. તેના માટે અઠવાડિયામાં માત્ર 5 દિવસ જ કામ કરવાનું તે સૌથી સારી વાત."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_5_1",
            "hi": "B7_5_1",
            "ka": "B7_5_1",
            "te": "B7_5_1",
            "or": "B7_5_1",
            "as": "B7_5_1",
            "gu": "B7_5_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "But when he returns home that day, he looks stressed. His mother approaches him to find out what happened.",
            "hi": "लेकिन उस दिन जब वह घर लौटता है वह कुछ परेशान सा दीखता है। उसकी माँ उसकी परेशानी का कारण जानना चाहती है।",
            "ka": "ಆದರೆ ಅವನು ಆ ದಿನ ಮನೆಗೆ ಹಿಂದಿರುಗಿದಾಗ, ಅವನು ಒತ್ತಡದಲ್ಲಿ ಕಾಣುತ್ತಾನೆ. ಏನಾಯಿತು ಎಂದು ತಿಳಿಯಲು ಅವನ ತಾಯಿ ಅವನನ್ನು ಸಂಪರ್ಕಿಸುತ್ತಾಳೆ.",
            "te": "కానీ ఆ రోజు ఇంటికి తిరిగి వచ్చినప్పుడు, అతను ఒత్తిడిగా ఉన్నాడు. ఏమి జరిగిందో తెలుసుకోవడానికి అతని తల్లి అతని వద్దకి వెళ్తుంది.",
            "or": "କିନ୍ତୁ ସେଦିନ ସେ ଯେତେବେଳେ ଘରକୁ ଫେରନ୍ତି, ସେ ଚାପଗ୍ରସ୍ତ ଦେଖାଯା୍ନ୍ତି | କଣ ଘଟିଛି ଜାଣିବା ପାଇଁ ତାଙ୍କ ମା ତାଙ୍କ ପାଖକୁ ଆସନ୍ତି |",
            "as": "কিন্তু সেইদিনা যেতিয়া সি ঘৰলৈ উভতি আহে, তাক বিৰাট চাপত থকা যেন লাগে। মাকে কি হ’ল জানিবলৈ তাৰ ওচৰলৈ আহে ৷",
            "gu": "જ્યારે તે તેના ઘરે તે દિવસે પોહચ્યો ત્યારે તે ઉદાસ અને થાકીગયેલો દેખાતો હતો. એની માતાએ એ જાણવું હતું કે શું થયું એને."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_5_2//",
            "hi": "B7_5_2//",
            "ka": "B7_5_2//",
            "te": "B7_5_2//",
            "or": "B7_5_2//",
            "as": "B7_5_2//",
            "gu": "B7_5_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Mother",
            "hi": "माँ",
            "ka": "ತಾಯಿ",
            "te": "తల్లి",
            "or": "ମାତା",
            "as": "মাক",
            "gu": "માતા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Mother",
            "hi": "माँ",
            "ka": "ತಾಯಿ",
            "te": "తల్లి",
            "or": "ମାତା",
            "as": "মাক",
            "gu": "માતા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "What happened, Naveen? Why do you look so troubled?",
            "hi": "क्या हुआ नवीन? तुम इतने परेशान क्यों दिख रहे हो?",
            "ka": "ಏನಾಯ್ತು ನವೀನ್? ನೀನು ಯಾಕೆ ತುಂಬಾ ತೊಂದರೆಗೀಡಾಗಿದ್ದೀಯ?",
            "te": "ఏమైంది నవీన్? నువ్వు ఎందుకు చాలా ఒత్తిడిగా ఉన్నావు?",
            "or": "କ’ଣ ହେଲା, ନବୀନ ? ତୁମେ କାହିଁକି ଏତେ ଅଶାନ୍ତ ଦେଖାଯାଉଛ ?",
            "as": "কি হ’ল নবীন? তোমাক চিন্তাত থকা যেন লাগিছে?",
            "gu": "શું થયું નવીન? કેમ તું કઈક તકલીફમાં લાગે છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Amma, remember I told you about that offer from Zell. I cleared the interview, they offered me the job.",
            "hi": "अम्मा, आप को याद हैं, मैंने आपको झेल के ऑफर के बारे में बताया था। मैं इंटरव्यू में पास हो गया हूँ और उन्होंने मुझे जॉब ऑफर किया है।",
            "ka": "ಅಮ್ಮಾ, ಝೆಲ್‌ನ ಆ ಪ್ರಸ್ತಾಪದ ಬಗ್ಗೆ ನಾನು ನಿಮಗೆ ಹೇಳಿದ್ದೇನೆ ಎಂದು ನೆನಪಿಸಿಕೊಳ್ಳಿ. ನಾನು ಸಂದರ್ಶನವನ್ನು ತೆರವುಗೊಳಿಸಿದೆ, ಅವರು ನನಗೆ ಕೆಲಸ ನೀಡಿದರು.",
            "te": "అమ్మా, జెల్ నుండి ఆ ఆఫర్ గురించి నేను మీకు చెప్పాను గుర్తుందా. నేను ఇంటర్వ్యూను క్లియర్ చేసాను, వారు నాకు ఉద్యోగం ఇచ్చారు.",
            "or": "ଆମ୍ମା, ମନେଥିବ ମୁଁ ଜେଲ ଠାରୁ ସେହି ଅଫର ବିଷୟରେ ତୁମକୁ କହିଥିଲି | ମୁଁ ସାକ୍ଷାତକାର ପାସ୍‌ କଲି, ସେମାନେ ମୋତେ ଚାକିରି ଦେଲେ |",
            "as": "মা, জেলৰ পৰা পোৱা প্ৰস্তাৱটোৰ বিষয়ে তোমাক কৈছিলো যে, তোমাৰ মনত আছেনে। মই সাক্ষাৎকাৰ দিলো আৰু তেওঁলোকে মোক চাকৰিটো আগবঢ়াইছে ৷",
            "gu": "અમ્મા, તને ખબર છે મને એક તક મળી છે જીલમાથી. મે ઇન્ટરવ્યૂ પૂરું કર્યું, તેઓએ મને નોકરી પણ આપી છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "That’s excellent news Naveen, shouldn’t you be happy? What is the problem?",
            "hi": "यह तो खुशी की बात है नवीन, तुम्हें तो खुश होना चाहिए? इसमें समस्या क्या है?",
            "ka": "ಇದು ಅತ್ಯುತ್ತಮ ಸುದ್ದಿ ನವೀನ್, ನೀವು ಸಂತೋಷವಾಗಿರಬೇಕಲ್ಲವೇ? ಸಮಸ್ಯೆ ಏನು?",
            "te": "ఇది చాలా మంచి వార్త నవీన్, నువ్వు సంతోషంగా ఉండాలి కదా? సమస్య ఏమిటి?",
            "or": "ଏହା ଏକ ଉତ୍ତମ ଖବର ନବୀନ, ତୁମେ ଖୁସି ହେବା ଉଚିତ୍ ନୁହେଁ କି ? ସମସ୍ୟା କ 'ଣ ?",
            "as": "সেইটো দেখুন বিৰাটেই ভাল খবৰ নবীন, তুমি সুখী হ’ব লাগিছিল? তেন্তে সমস্যাটো কি?",
            "gu": "તે ખૂબ સરસ સમાચાર છે નવીન, તારે ખુશ ના થવું જોઈએ? તકલીફ શું છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.text",
          "value": {
            "en": "My superiors at work happened to find out, so they are offering me a salary equivalent to Zell and a bonus to continue working with them this year. And it’s quite a significant bonus.",
            "hi": "मेरे सीनियर्स को इस बारे में पता चला और अब वे मुझे झेल जितनी ही सैलरी और बोनस ऑफर कर रहे हैं ताकि मैं उनके साथ इस साल काम करना जारी रखें। और यह बहुत बड़ा बोनस है।",
            "ka": "ಕೆಲಸದಲ್ಲಿರುವ ನನ್ನ ಮೇಲಧಿಕಾರಿಗಳು ಇದನ್ನು ಕಂಡುಹಿಡಿದರು, ಆದ್ದರಿಂದ ಅವರು ನನಗೆ ಝೆಲ್‌ಗೆ ಸಮಾನವಾದ ಸಂಬಳ ಮತ್ತು ಈ ವರ್ಷ ಅವರೊಂದಿಗೆ ಕೆಲಸ ಮಾಡಲು ಬೋನಸ್ ಅನ್ನು ನೀಡುತ್ತಿದ್ದಾರೆ. ಮತ್ತು ಇದು ಸಾಕಷ್ಟು ಗಮನಾರ್ಹ ಬೋನಸ್ ಆಗಿದೆ.",
            "te": "పనిలో ఉన్న నా ఉన్నతాధికారులు ఈ విషయాన్ని తెలుసుకున్నారు, కాబట్టి వారు నాకు జెల్‌కి సమానమైన జీతం మరియు ఈ సంవత్సరం వారితో కలిసి పని చేయడం కొనసాగించడానికి బోనస్‌ను అందిస్తున్నారు. మరియు ఇది చాలా ముఖ్యమైన బోనస్.",
            "or": "କାର୍ଯ୍ୟକ୍ଷେତ୍ରରେ ମୋର ଉଚ୍ଚପଦସ୍ଥ ଅଧିକାରୀମାନେ ଜାଣିବାକୁ ପାଇଲେ, ତେଣୁ ସେମାନେ ମୋତେ ଜେଲ୍ ସହିତ ସମାନ ଦରମା ଏବଂ ଏହି ବର୍ଷ ସେମାନଙ୍କ ସହିତ କାର୍ଯ୍ୟ ଜାରି ରଖିବା ପାଇଁ ଏକ ବୋନସ୍ ପ୍ରଦାନ କରୁଛନ୍ତି | ଏବଂ ଏହା ଏକ ମହତ୍ବପୂର୍ଣ୍ଣ ବୋନସ୍ ଅଟେ |",
            "as": "মোৰ উৰ্ধতন বিষয়াসকলে কথাটো গম পালে, সেয়েহে তেওঁলোকে মোক জেলৰ সমান দৰমহা আৰু এই বছৰ তেওঁলোকৰ সৈতে কাম কৰি থকাৰ বাবে এটা বোনাচ আগবঢ়াইছে। খুবেই ভাল বোনাচ ৷",
            "gu": "મારા ઉપરી અધિકારીઓને પાસેથી કામ પર જાણ થઈ, કે મને આ વર્ષે તેમની સાથે કામ કરવાનું ચાલુ રાખવા માટે જીલ જેટલો જ પગાર અને બોનસ આપશે. અને તે ખૂબ મોટું બોનસ છે."
          }
        }
      ],
      "page12": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_5_3////",
            "hi": "B7_5_3////",
            "ka": "B7_5_3////",
            "te": "B7_5_3////",
            "or": "B7_5_2//",
            "as": "B7_5_3////",
            "gu": "B7_5_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Naveen, of course, this was going to happen. They value your work. You should feel proud.",
            "hi": "नवीन, ये तो होना ही था। उन्हें तुम्हारे काम की कदर है। तुम्हें खुद पर गर्व होना चाहिए।",
            "ka": "ನವೀನ್, ಸಹಜವಾಗಿ, ಇದು ಸಂಭವಿಸಲಿದೆ. ಅವರು ನಿಮ್ಮ ಕೆಲಸವನ್ನು ಗೌರವಿಸುತ್ತಾರೆ. ನೀನು ಹೆಮ್ಮೆ ಪಡಬೇಕು.",
            "te": "నవీన్,ఖచ్చితంగా ఇదే జరుగుతుంది. వారు మీ పనికి విలువ ఇస్తారు. మీరు గర్వంగా భావించాలి.",
            "or": "ନବୀନ, ଅବଶ୍ୟ, ଏହା ଘଟିବାରଥିଲା | ସେମାନେ ତୁମର କାର୍ଯ୍ୟକୁ ଗୁରୁତ୍ୱ ଦିଅନ୍ତି | ତୁମେ ଗର୍ବ ଅନୁଭବ କରିବା ଉଚିତ୍ |",
            "as": "নবীন এইটো হ’বলগীয়া আছিল ৷ তেওঁলোকে তোমাৰ কামক মূল্য দিয়ে। তুমি গৌৰৱ অনুভৱ কৰিব লাগে।",
            "gu": "નવીન, આ ચોક્કસ થવાનું જ હતું. તે તારા કામનું કદર કરે છે. તને તારા પર ગર્વ થવો જોઇયે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "It’s not that. I would have happily left Alliance to join Zell. It is a bigger company. But this bonus amount will allow me to buy the space for that shop we were hoping to set up for you. It will make your life so much easier.",
            "hi": "ऐसी बात नहीं है। मैं खुशी-खुशी अलायन्स छोड़कर झेल जा सकता हूँ। वह बड़ी कंपनी है। लेकिन इस बोनस की राशी से हम तुम्हारी दूकान के लिए जगह खरीद सकते हैं जैसा कि हम सोच रहे थे। इससे तुम्हारी जिंदगी बहुत आसान हो जाएगी।",
            "ka": "ಅದು ಅಲ್ಲ. ನಾನು ಝೆಲ್‌ಗೆ ಸೇರಲು ಅಲೈಯನ್ಸ್ ಅನ್ನು ಸಂತೋಷದಿಂದ ತೊರೆದಿದ್ದೇನೆ. ಅದೊಂದು ದೊಡ್ಡ ಕಂಪನಿ. ಆದರೆ ಈ ಬೋನಸ್ ಮೊತ್ತವು ನಾನು ನಿಮಗಾಗಿ ಹೊಂದಿಸಲು ಆಶಿಸುತ್ತಿರುವ ಅಂಗಡಿಯ ಸ್ಥಳವನ್ನು ಖರೀದಿಸಲು ನನಗೆ ಅನುಮತಿಸುತ್ತದೆ. ಇದು ನಿಮ್ಮ ಜೀವನವನ್ನು ತುಂಬಾ ಸುಲಭಗೊಳಿಸುತ್ತದೆ.",
            "te": "అది కాదు. జెల్‌లో చేరడానికి నేను సంతోషంగా అలియన్స్ ని విడిచిపెట్టేవాన్ని. ఇది పెద్ద కంపెనీ. కానీ ఈ బోనస్ మొత్తంతో మనము నీ కోసం సెటప్ చేయాలనుకుంటున్న ఆ షాప్ కోసం స్థలాన్ని కొనవచ్చు. ఇది మీ జీవితాన్ని చాలా సులభతరం చేస్తుంది.",
            "or": "ଏହା ନୁହେଁ | ମୁଁ ଖୁସିରେ ଜେଲରେ ଯୋଗଦେବା ପାଇଁ ଆଲାଇନ୍ସ ଛାଡିଥା’ନ୍ତି | ଏହା ଏକ ବଡ କମ୍ପାନୀ ଅଟେ | କିନ୍ତୁ ଏହି ବୋନସ୍ ରାଶି ମୋତେ ସେହି ଦୋକାନ ପାଇଁ ସ୍ଥାନ କିଣିବାକୁ ଅନୁମତି ଦେବ ଯାହାକୁ ଆମେ ତୁମ ପାଇଁ ସେଟ୍ ଅପ୍ କରିବାକୁ ଆଶା କରୁଥିଲୁ | ଏହା ତୁମ ଜୀବନକୁ ଏତେ ସହଜ କରିବ |",
            "as": "সেইটো কথা নহয় ৷ মই জেলত যোগদান কৰিবলৈ আনন্দেৰে এলায়েন্স এৰিলোহেঁতেন। এইটো এটা ডাঙৰ কোম্পানী। কিন্তু এই বোনাচৰ টকাৰে মই সেই দোকানখনৰ বাবে ঠাই কিনিব পাৰিম যিখন মই তোমাৰ বাবে বনাব বিচাৰিছিলো। ই তোমাৰ জীৱনটো বহুত সহজ কৰি তুলিব।",
            "gu": "આવું નથી. હું રાજીખુશીથી ઝીલ સાથે જોડાવા માટે એલાયન્સ છોડીશ કેમ કે એ મોટી કંપની છે. પરંતુ આ બોનસની રકમ મને દુકાનની જગ્યા ખરીદવાની મદદ થાત જે મે તારા માટે શરૂ કરવાની આશા રાખી છે. તે તમારા જીવનને સરળ બનાવશે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Don’t bother about me Naveen, we can always do that after a year or two of saving if you think it is a better place to work.",
            "hi": "तुम मेरी फिक्र मत करो नवीन, अगर यह जॉब तुम्हें अच्छी लगती है तो हम एक-दो साल पैसे बचाकर बाद में जगह खरीद सकते हैं।",
            "ka": "ನನ್ನ ಬಗ್ಗೆ ತಲೆಕೆಡಿಸಿಕೊಳ್ಳಬೇಡ ನವೀನ್, ನೀನು ಕೆಲಸ ಮಾಡಲು ಇದು ಉತ್ತಮ ಸ್ಥಳವೆಂದು ನೀನು ಭಾವಿಸಿದರೆ ನಾವು ಅದನ್ನು ಯಾವಾಗಲೂ ಒಂದು ಅಥವಾ ಎರಡು ವರ್ಷಗಳ ನಂತರ ಉಳಿಸಬಹುದು.",
            "te": "నా గురించి బాధపడకు నవీన్, నువ్వు పని చేయడానికి ఇది మంచి ప్రదేశం అని నువ్వు అనుకుంటే, మనము ఒకటి లేదా రెండు సంవత్సరాలు పొదుపు చేసిన తర్వాత ఆ పని చేయవచ్చు.",
            "or": "ମୋ ବିଷୟରେ ବ୍ୟସ୍ତ ହୁଅ ନାହିଁ ନବୀନ, ଯଦି ତୁମେ ଭାବୁଛ ଏହା କାମ କରିବା ପାଇଁ ଏକ ଉତ୍ତମ ସ୍ଥାନ, ଏକ ବର୍ଷ କିମ୍ବା ଦୁଇ ବର୍ଷ ସଞ୍ଚୟ ପରେ ଆମେ ତାହା କରିପାରିବା |",
            "as": "মোৰ বিষয়ে চিন্তা নকৰিবা নবীন, যদি তুমি ভাবা যে কাম কৰাৰ বাবে এইখন ভাল ঠাই, তেন্তে আমি এবছৰ বা দুবছৰৰ জমা টকাৰে সেইটো কৰিব পাৰোঁ।",
            "gu": "નવીન તું મારા વિશે ચિંતા ના કર, જો તને લાગે કે તે આ કંપનીમાં સારી આવક મળી શકે છે તો આપણે એકાદ બે વર્ષ પછી જે બચેલા પૈસા છે એમથી ખરીદશું."
          }
        }
      ],
      "page13": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_5_4_D",
            "hi": "B7_5_4_D",
            "ka": "B7_5_4_D",
            "te": "B7_5_4_D",
            "or": "B7_5_4_D",
            "as": "B7_5_4_D",
            "gu": "B7_5_4_D"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Naveen do?",
            "hi": "नवीन को क्या करना चाहिए?",
            "ka": "ನವೀನ್ ಏನು ಮಾಡಬೇಕು?",
            "te": "నవీన్ ఏం చేయాలి?",
            "or": "ନବୀନ କଣ କରିବା ଉଚିତ୍ ?",
            "as": "নবীনে কি কৰা উচিত?",
            "gu": "હવે નવીન ને શું કરવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Get the new job at Zell",
            "hi": "झेल में नई नौकरी स्वीकार करनी चाहिए।",
            "ka": "ಝೆಲ್‌ ನಲ್ಲಿ ಹೊಸ ಕೆಲಸವನ್ನು ಪಡೆಯಿರಿ",
            "te": "జెల్ లో కొత్త ఉద్యోగం పొందాలి",
            "or": "ଜେଲ୍‌ରେ ନୂତନ ଚାକିରି କରନ୍ତୁ",
            "as": "জেলৰ নতুন চাকৰিটো ল’ব",
            "gu": "નવી નોકરી જીલમાં કરવી જોઈએ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Stay at Alliance Digital",
            "hi": "अलायन्स डिजिटल में रहना चाहिए",
            "ka": "ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್‌ನಲ್ಲಿ ಉಳಿಯಿರಿ",
            "te": "అలయన్స్ డిజిటల్‌లో ఉండాలి",
            "or": "ଆଲିଆନ୍ସ ଡିଜିଟାଲରେ ରୁହନ୍ତୁ",
            "as": "এলায়েন্স ডিজিটেলতে থাকিব",
            "gu": "યા તો એલાયન્સમાં જ કામ ચાલુ રાખવું જોઇયે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.tag",
          "value": {
            "en": "CAREER GROWTH",
            "hi": "करियर में वृद्धि",
            "ka": "ವೃತ್ತಿ ಬೆಳವಣಿಗೆ",
            "te": "కెరీర్ వృద్ధి",
            "or": "କ୍ୟାରିଅର୍ ଅଭିବୃଦ୍ଧି",
            "as": "জীৱিকাৰ বিকাশ",
            "gu": "કારકિર્દી વૃદ્ધિ"
          }
        }
      ]
    },
    "scene11": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_1_1_Continue at Alliance digital",
            "hi": "अलायन्स डिजिटल में रहना",
            "ka": "B8_1_1_ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್‌ನಲ್ಲಿ ಮುಂದುವರಿಯಿರಿ",
            "te": "B8_1_1_అలయన్స్ డిజిటల్‌లో కొనసాగించడం",
            "or": "B8_1_1_Continue at Alliance digital",
            "as": "B8_1_1_এলায়েন্স ডিজিটেলতে থাকিব",
            "gu": "B8_1_1_એલાયન્સ ડિજિટલમાં ચાલુ રાખો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen continues to work at Alliance Digital and gets the bonus.",
            "hi": "नवीन अलायन्स डिजिटल में काम करना जारी रखता है और बोनस लेता है।",
            "ka": "ನವೀನ್ ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಮುಂದುವರೆಸುತ್ತಾನೆ ಮತ್ತು ಬೋನಸ್ ಪಡೆಯುತ್ತಾನೆ.",
            "te": "నవీన్ అలయన్స్ డిజిటల్‌లోనే ఉన్నాడు మరియు బోనస్ పొందాడు.",
            "or": "ନବୀନ ଆଲିଆନ୍ସ ଡିଜିଟାଲରେ କାମ ଜାରି ରଖନ୍ତି ଏବଂ ବୋନସ ପାଆନ୍ତି |",
            "as": "নবীনে এলায়েন্স ডিজিটেলতে কাম কৰি আছে আৰু বোনাচ লাভ কৰিছে।",
            "gu": "નવીન એલાયન્સમાં ડિજિટલમાં કામ ચાલુ રાખે છે અને બોનસ મેળવે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_1_2",
            "hi": "B8_1_2",
            "ka": "B8_1_2",
            "te": "B8_1_2",
            "or": "B8_1_2",
            "as": "B8_1_2",
            "gu": "B8_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He sets up a small grocery store for his mother in their basti. Their financial status is a little better, and he is proud that he is helping the family.",
            "hi": "वह बस्ती में ही अपनी माँ के लिए छोटा सा ग्रोसरी स्टोअर खोलकर देता है। अब उनकी आर्थिक स्थिति थोड़ी अच्छी है और उसे अभिमान है कि वह अपने परिवार की मदद कर रहा है।",
            "ka": "ಅವನು ತನ್ನ ಬಸ್ತಿಯಲ್ಲಿ ತನ್ನ ತಾಯಿಗಾಗಿ ಸಣ್ಣ ಕಿರಾಣಿ ಅಂಗಡಿಯನ್ನು ಸ್ಥಾಪಿಸುತ್ತಾನೆ. ಅವರ ಆರ್ಥಿಕ ಸ್ಥಿತಿ ಸ್ವಲ್ಪ ಉತ್ತಮವಾಗಿದ್ದು, ಕುಟುಂಬಕ್ಕೆ ಸಹಾಯ ಮಾಡುತ್ತಿದ್ದೇನೆ ಎಂದು ಹೆಮ್ಮೆಪಡುತ್ತಾನೆ.",
            "te": "వాళ్ళ బస్తీలో తన తల్లికి చిన్న కిరాణా దుకాణం పెట్టాడు. తమ ఆర్థిక పరిస్థితి కాస్త మెరుగ్గా ఉందని, కుటుంబానికి తాను సాయం చేస్తున్నానని గర్విస్తున్నాడు.",
            "or": "ସେ ସେମାନଙ୍କ ବସ୍ତିରେ ତାଙ୍କ ମା ପାଇଁ ଏକ ଛୋଟ ଗ୍ରୋସରୀ ଦୋକାନ ସ୍ଥାପନ କରନ୍ତି | ସେମାନଙ୍କର ଆର୍ଥିକ ସ୍ଥିତି ଟିକିଏ ଉନ୍ନତ ହୁଏ, ଏବଂ ସେ ପରିବାରକୁ ସାହାଯ୍ୟ କରୁଥିବାରୁ ସେ ଗର୍ବିତ |",
            "as": "সি তেওঁলোকৰ বস্তিত মাকৰ বাবে এখন সৰু গেলামালৰ দোকান খুলিলে। তেওঁলোকৰ বিত্তীয় স্থিতি অলপ ভাললৈ আহিল আৰু সি পৰিয়ালটোক সহায় কৰিব পাৰি গৌৰৱান্বিত হ’ল ৷",
            "gu": "એને નાની કરિયાણાની દુકાન નાખી એની માતા માટે એની વસાહતમાં. તેમની આર્થિક સ્થિતિ થોડી સારી થાય છે, અને એને ગર્વ થાય છે કે તે એના કુટુંબને મદદ કરે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_1_3",
            "hi": "B8_1_3",
            "ka": "B8_1_3",
            "te": "B8_1_3",
            "or": "B8_1_3",
            "as": "B8_1_3",
            "gu": "B8_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "His mother is happy as she has a shop of her own to run.",
            "hi": "उसकी माँ खुश है क्योंकि अब वह खुद अपना दुकान चलाती है।",
            "ka": "ತನ್ನ ಸ್ವಂತ ಅಂಗಡಿಯನ್ನು ನಡೆಸುತ್ತಿರುವುದರಿಂದ ಅವನ ತಾಯಿ ಸಂತೋಷಪಡುತ್ತಾಳೆ.",
            "te": "తనకంటూ ఓ దుకాణం నడుపుకోవడంతో అతని తల్లి సంతోషిస్తోంది.",
            "or": "ତାଙ୍କ ମା ଖୁସି ଅଛନ୍ତି ଯେହେତୁ ଚଲାଇବାକୁ ତାଙ୍କର ନିଜର ଏକ ଦୋକାନ ଅଛି |",
            "as": "তাৰ মাকো সুখী কিয়নো তেওঁৰ নিজৰ চলাবলৈ এখন দোকান আছে।",
            "gu": "એની માતા પણ ખુશ થાય છે કે એની પોતાની દુકાન છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_2_1",
            "hi": "B8_2_1",
            "ka": "B8_2_1",
            "te": "B8_2_1",
            "or": "B8_2_1",
            "as": "B8_2_1",
            "gu": "B8_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "As a store front manager, his work ensures - attending to customers, minimising wait time and training the new staff.",
            "hi": "स्टोअर फ्रंट मैनेजर के तौर पर, उसके काम में शामिल है – ग्राहकों से बातचीत करना, कम समय में प्रोडक्ट बेचना और नए स्टाफ को ट्रेनिंग देना।",
            "ka": "ಸ್ಟೋರ್ ಫ್ರಂಟ್ ಮ್ಯಾನೇಜರ್ ಆಗಿ, ಅವನ ಕೆಲಸ ಖಾತ್ರಿಗೊಳಿಸುತ್ತದೆ - ಗ್ರಾಹಕರಿಗೆ ಹಾಜರಾಗುವುದು, ಕಾಯುವ ಸಮಯವನ್ನು ಕಡಿಮೆ ಮಾಡುವುದು ಮತ್ತು ಹೊಸ ಸಿಬ್ಬಂದಿಗೆ ತರಬೇತಿ ನೀಡುವುದು.",
            "te": "స్టోర్ ఫ్రంట్ మేనేజర్‌గా, అతని పని నిర్ధారిస్తుంది - కస్టమర్‌లకు హాజరు కావడం, వేచి ఉండే సమయాన్ని తగ్గించడం మరియు కొత్త సిబ్బందికి శిక్షణ ఇవ్వడం.",
            "or": "ଏକ ଷ୍ଟୋର ଫ୍ରଣ୍ଟ ମ୍ୟାନେଜର୍ ଭାବେ, ତାଙ୍କର କାର୍ଯ୍ୟ ସୁନିଶ୍ଚିତ କରେ - ଗ୍ରାହକମାନଙ୍କ ନିକଟରେ ଉପସ୍ଥିତ ରହିବା, ଅପେକ୍ଷା ସମୟକୁ କମ୍ କରିବା ଏବଂ ନୂତନ କର୍ମଚାରୀମାନଙ୍କୁ ତାଲିମ ଦେବା |",
            "as": "ষ্ট'ৰ ফ্ৰণ্ট মেনেজাৰ হিচাপে, তাৰ কাম হৈছে - গ্ৰাহকসকলৰ ওচৰলৈ যোৱা, অপেক্ষাৰ সময় হ্ৰাস কৰা আৰু নতুন কৰ্মচাৰীসকলক প্ৰশিক্ষণ দিয়া।",
            "gu": "સ્ટોર ફ્રન્ટ મેનેજર તરીકે તે તેનું કામ ખાત્રિ પૂર્વક કરે છે - ગ્રાહક ને આવકાર આપવો, રાહ જોવાનો ઓછો ટાઈમ કરવો અને નવા કારીગરોને સિખવાડવું."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_2_2",
            "hi": "B8_2_2",
            "ka": "B8_2_2",
            "te": "B8_2_2",
            "or": "B8_2_2",
            "as": "B8_2_2",
            "gu": "B8_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He is pretty popular in the store, and after getting good reviews, he gets shifted to the main showroom in the city.",
            "hi": "वह स्टोअर में काफी लोकप्रिय है और अच्छे रिव्यू मिलने के कारण उसे शहर के मुख्य शोरूम में शिफ्ट किया जाता है।",
            "ka": "ಅವನು ಅಂಗಡಿಯಲ್ಲಿ ಸಾಕಷ್ಟು ಜನಪ್ರಿಯರಾಗಿದ್ದಾನೆ ಮತ್ತು ಉತ್ತಮ ವಿಮರ್ಶೆಗಳನ್ನು ಪಡೆದ ನಂತರ, ಅವನು ನಗರದ ಮುಖ್ಯ ಶೋರೂಮ್‌ಗೆ ಸ್ಥಳಾಂತರಗೊಳ್ಳುತ್ತಾನೆ.",
            "te": "అతను స్టోర్‌లో బాగా పాపులర్ అయ్యాడు మరియు మంచి సమీక్షలను పొందిన తర్వాత, అతను నగరంలోని మెయిన్ షోరూమ్‌కి మార్చబడ్డాడు.",
            "or": "ସେ ଷ୍ଟୋର୍‌ରେ ଖୁବ୍‌ ଲୋକପ୍ରିୟ, ଏବଂ ଭଲ ସମୀକ୍ଷା ପାଇବା ପରେ, ସେ ସହରର ମୁଖ୍ୟ ଶୋ’ରୁମ୍ କୁ ସ୍ଥାନାନ୍ତରିତ ହୁ‍ଅନ୍ତି |",
            "as": "সি দোকানখনত যথেষ্ট জনপ্ৰিয়, আৰু ভাল পৰ্যালোচনা পোৱাৰ পিছত, তাক চহৰৰ মুখ্য শ্ব'ৰুমলৈ স্থানান্তৰিত কৰা হয়।",
            "gu": "તે ખૂબ માહિત થઈ જાય છે તેના સ્ટોરમાં, તેને સારો આવકાર મળ્યા પછી તે શહેરના મુખ્ય શોરૂમમાં બદલી થાય છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_2_3",
            "hi": "B8_2_3",
            "ka": "B8_2_3",
            "te": "B8_2_3",
            "or": "B8_2_3",
            "as": "B8_2_3",
            "gu": "B8_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "After a few years, he feels settled in the job he wants to go back to practising dance. He is slowly able to practice with his friends.",
            "hi": "कुछ सालों बाद, अब उसे लगता है कि वह जॉब में सेटल हो चूका है और वह फिर से डान्स प्रैक्टिस करना चाहता है। वह धीरे-धीरे दोस्तों के साथ प्रैक्टिस कर सकता है।",
            "ka": "ಕೆಲವು ವರ್ಷಗಳ ನಂತರ, ಅವನು ನೃತ್ಯವನ್ನು ಅಭ್ಯಾಸ ಮಾಡಲು ಬಯಸಿದ ಉದ್ಯೋಗದಲ್ಲಿ ನೆಲೆಸಿದನು. ಅವನು ನಿಧಾನವಾಗಿ ತನ್ನ ಸ್ನೇಹಿತರೊಂದಿಗೆ ಅಭ್ಯಾಸ ಮಾಡಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ.",
            "te": "కొన్ని సంవత్సరాల తర్వాత అతను ఉద్యోగంలో స్థిరపడ్డాడు కానీ అతను డ్యాన్స్ సాధన చేయడానికి తిరిగి వెళ్లాలనుకున్నాడు. అతను తన స్నేహితులతో నెమ్మదిగా సాధన చేయగలడు.",
            "or": "କିଛି ବର୍ଷ ପରେ, ସେ ଚାକିରିରେ ସ୍ଥିର ଅନୁଭବ କରନ୍ତି | ସେ ନୃତ୍ୟ ଅଭ୍ୟାସକୁ ଫେରିଯିବାକୁ ଚାହାଁନ୍ତି | ସେ ଧୀରେ ଧୀରେ ନିଜ ସାଙ୍ଗମାନଙ୍କ ସହିତ ଅଭ୍ୟାସ କରିବାକୁ ସକ୍ଷମ ଅଟନ୍ତି |",
            "as": "কেইবছৰমানৰ পিছত, সি চাকৰিটোত থিতাপি লয় আৰু নৃত্যৰ অনুশীলনলৈ ঘূৰি যাব বিচাৰে ৷",
            "gu": "થોડા વર્ષ પછી, તે તેની નોકરીમાં સેટ થઈ જાય છે અને ડાંસમાં પ્રેક્ટિસ કરવા પાછા જવું છે. તે તેના મિત્રો સાથે ધીમે ધીમે પ્રેક્ટિસ ચાલુ કરે છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Manager at Alliance Digital",
            "hi": "अलायन्स डिजिटल में मैनेजर",
            "ka": "**THREAD_END**_ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್‌ನಲ್ಲಿ ಮ್ಯಾನೇಜರ್",
            "te": "**THREAD_END**_ అలయన్స్ డిజిటల్‌లో మేనేజర్",
            "or": "**THREAD_END**_Manager at Alliance Digital",
            "as": "**থ্ৰেড_সমাপ্ত**_এলায়েন্স ডিজিটেলত মেনেজাৰ",
            "gu": "**THREAD_END**_એલાયન્સ ડિજિટલ ખાતે મેનેજર"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Naveen that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने नवीन के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँचा है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकता है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n \n ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾದ ನವೀನ್‌ಗಾಗಿ ನೀವು ಆಯ್ಕೆಗಳನ್ನು ಮಾಡಿದ್ದೀರಿ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు నవీన్ కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ !\n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି !\n \n ଆପଣ ନବୀନଙ୍କ ପାଇଁ ବାଛିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତି ଘଟାଇଛି | ଆପଣ କରୁଥିବା ପସନ୍ଦ ଉପରେ ଆଧାର କରି ଭିନ୍ନ ଭିନ୍ନ ସମାପ୍ତି ଅଛି | ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକୁ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ?",
            "as": "অভিনন্দন! তুমি এটা যাত্ৰা সমাপ্ত কৰিছা ৷ তুমি নবীনৰ বাবে কৰা বাছনিবোৰৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত ভিত্তি কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ চাব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક મુસાફરી પૂરી કરી છે!\n\nતમે નવીન માટે પસંદગીઓ કરી છે જે આ સંભવિત અંત તરફ દોરી ગઈ છે. તમે જે પસંદગી કરી છે તેનાથી અલગ અલગ અંત છે. શું તમે બીજા દ્રશ્યો ખોલવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମ୍ଯାପ୍‍ ଉପରେ ଏକ ନଜର ପକାନ୍ତୁ |",
            "as": "মানচিত্ৰখনৰ পিনে চোৱা",
            "gu": "ચાલો નકશા પર એક નજર નાખીએ"
          }
        }
      ]
    },
    "scene12": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B9_1_1_New job at Zell",
            "hi": "झेल में नई नौकरी स्वीकार करना",
            "ka": "B9_1_1_ ಝೆಲ್‌ ನಲ್ಲಿ ಹೊಸ ಕೆಲಸ",
            "te": "B9_1_1_Zellలో కొత్త ఉద్యోగం",
            "or": "B9_1_1_New job at Zell",
            "as": "B9_1_1_জেলত নতুন চাকৰি",
            "gu": "B9_1_1_જિલમાં નવી નોકરી"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen joins Zell as a Junior Sales Manager.",
            "hi": "नवीन ज्युनियर सेल्स मैनजेर के तौर पर झेल में काम करना शुरू करता है।",
            "ka": "ನವೀನ್ ಜೂನಿಯರ್ ಸೇಲ್ಸ್ ಮ್ಯಾನೇಜರ್ ಆಗಿ ಝೆಲ್‌ಗೆ ಸೇರುತ್ತಾನೆ.",
            "te": "నవీన్ జెల్‌లో జూనియర్ సేల్స్ మేనేజర్‌గా చేరాడు.",
            "or": "ନବୀନ ଜେ‍ଲ୍‌ରେ ଜୁନିଅର ସେଲ୍ସ ମ୍ୟାନେଜର୍‌ ଭାବରେ ଯୋଗ ଦିଅନ୍ତି |",
            "as": "নবীনে জেলত এজন জুনিয়ৰ চেলছ মেনেজাৰ হিচাপে যোগদান কৰে।",
            "gu": "નવીન જીલમાં જુનિયર સેલ્સ મેનેજર તરીકે કામ ચાલુ કરે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B9_1_2",
            "hi": "B9_1_2",
            "ka": "B9_1_2",
            "te": "B9_1_2",
            "or": "B9_1_2",
            "as": "B9_1_2",
            "gu": "B9_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen is happy with how much he earns but starts feeling disengaged with what he is doing after a few months.",
            "hi": "नवीन अपनी कमाई को लेकर खुश है लेकिन कुछ महीनों बाद वह अपने काम ठीक से ध्यान नहीं लगा पा रहा है।",
            "ka": "ನವೀನ್ ತಾನು ಎಷ್ಟು ಸಂಪಾದಿಸುತ್ತಾನೆಂದು ಸಂತೋಷಪಡುತ್ತಾನೆ ಆದರೆ ಕೆಲವು ತಿಂಗಳುಗಳ ನಂತರ ಅವನು ಏನು ಮಾಡುತ್ತಿದ್ದಾನೆ ಎಂಬುದರ ಬಗ್ಗೆ ನಿರ್ಲಿಪ್ತನಾಗಿರುತ್ತಾನೆ.",
            "te": "నవీన్ సంపాదన చూసి సంతోషపడతాడు కానీ కొన్ని నెలల తర్వాత చేస్తున్న పనులతో విరక్తి చెందడం ప్రారంభించాడు.",
            "or": "ସେ ଯେତେ ରୋଜଗାର କରନ୍ତି ସେଥିରେ ନବୀନ ଖୁସି ଅଛନ୍ତି, କିନ୍ତୁ କିଛି ମାସ ପରେ ସେ ଯାହା କରୁଛନ୍ତି ସେଥିରେ ଅସନ୍ତୁଷ୍ଟ ଅନୁଭବ କରିବା ଆରମ୍ଭ କରନ୍ତି |",
            "as": "নবীনে যিমান উপাৰ্জন কৰে সেইটো লৈ সি সুখী কিন্তু কেইমাহমানৰ পিছত সি যি কৰি আছে সেইটোত সি নিষ্ক্ৰিয় অনুভৱ কৰিবলৈ ধৰে।",
            "gu": "નવીન જે કમાય છે તેનાથી તે ખુશ છે પરંતુ થોડા મહિના પછી તે જે કરી રહ્યો છે તેનાથી તેને ઓછી મજા આવે છે એવો અનુભવવા તેને લાગે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B9_1_3",
            "hi": "B9_1_3",
            "ka": "B9_1_3",
            "te": "B9_1_3",
            "or": "B9_1_3",
            "as": "B9_1_3",
            "gu": "B9_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He finds it difficult to understand how big organisations work but slowly learns to adjust. His manager is tough, and the sales targets are challenging.",
            "hi": "बड़ी कंपनियां किस तरह से काम करती हैं यह समझने में उसे मुश्किल हो रही है लेकिन वह धीरे-धीरे सब सीख रहा है। उसका मैनजेर सख्त है और सेल्स के टारगेट बहुत कठिन हैं।",
            "ka": "ದೊಡ್ಡ ಸಂಸ್ಥೆಗಳು ಹೇಗೆ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತವೆ ಎಂಬುದನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಅವನಿಗೆ ಕಷ್ಟವಾಗುತ್ತದೆ ಆದರೆ ನಿಧಾನವಾಗಿ ಹೊಂದಿಕೊಳ್ಳಲು ಕಲಿಯುತ್ತಾನೆ. ಅವನ ಮ್ಯಾನೇಜರ್ ಕಠಿಣ, ಮತ್ತು ಮಾರಾಟ ಗುರಿಗಳು ಸವಾಲಿನವುಗಳಾಗಿವೆ.",
            "te": "పెద్ద సంస్థలు ఎలా పని చేస్తాయో అర్థం చేసుకోవడం అతనికి కష్టంగా ఉంది, కానీ నెమ్మదిగా సర్దుబాటు చేయడం నేర్చుకుంటాడు. అతని మేనేజర్ కఠినమైనది మరియు అమ్మకాల లక్ష్యాలు సవాలుగా ఉన్నాయి.",
            "or": "ତାଙ୍କୁ ବଡ ସଂଗଠନଗୁଡିକ କିପରି କାମ କରେ ବୁଝିବା କଷ୍ଟକର ହୁଏ କିନ୍ତୁ ଧୀରେ ଧୀରେ ଆଡଜଷ୍ଟ କରିବାକୁ ଶିଖନ୍ତି | ତାଙ୍କ ମ୍ଯାନେଜର୍‌ ଶକ୍ତ, ଏବଂ ସେଲ୍ସ ଟାର୍ଗେଟ୍‍ଗୁଡିକ ଚ୍ୟାଲେଞ୍ଜ ଅଟେ |",
            "as": "ডাঙৰ সংগঠনবোৰে কেনেদৰে কাম কৰে সি বুজিবলৈ টান পায় কিন্তু লাহে লাহে মিলাবলৈ শিকে। তাৰ মেনেজাৰজন কঠিন, আৰু বিক্ৰী লক্ষ্যবোৰো প্ৰত্যাহ্বানজনক।",
            "gu": "તેને મોટી સંસ્થાઓ કેવી રીતે કામ કરે છે તે સમજવું તેને અગરું લાગે છે પરંતુ ધીમે ધીમે તે તેમાં તૈયાર થઈ જાય છે. તેનો મેનેજર કડક છે, અને વેચાણના લક્ષ્યો પડકારરૂપ સમાન છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B9_1_4",
            "hi": "B9_1_4",
            "ka": "B9_1_4",
            "te": "B9_1_4",
            "or": "B9_1_4",
            "as": "B9_1_4",
            "gu": "B9_1_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "But Naveen feels there is a lot of scope to grow in this organisation. He meets new people from different teams to know what they do.",
            "hi": "लेकिन नवीन को लगता है कि इस संस्था में उसे तरक्की करने के अवसर मिलेंगे। वह अलग-अलग टीम के नए लोगों से मिलकर वह क्या करते हैं यह जानकारी प्राप्त करता है।",
            "ka": "ಆದರೆ ಈ ಸಂಸ್ಥೆಯಲ್ಲಿ ಬೆಳೆಯಲು ಸಾಕಷ್ಟು ಅವಕಾಶವಿದೆ ಎಂದು ನವೀನ್ ಅಭಿಪ್ರಾಯಪಟ್ಟಿದ್ದಾನೆ. ಅವರು ಏನು ಮಾಡುತ್ತಿದ್ದಾರೆಂದು ತಿಳಿಯಲು ಅವನು ವಿವಿಧ ತಂಡಗಳ ಹೊಸ ಜನರನ್ನು ಭೇಟಿಯಾಗುತ್ತಾನೆ.",
            "te": "అయితే ఈ సంస్థలో ఎదగడానికి చాలా అవకాశం ఉందని నవీన్ భావిస్తున్నాడు. మిగతావారు ఏమి చేస్తారో తెలుసుకోవడానికి అతను వివిధ బృందాల నుండి కొత్త వ్యక్తులను కలుస్తాడు.",
            "or": "କିନ୍ତୁ ନବୀନ ଅନୁଭବ କରନ୍ତି ଯେ ଏହି ସଂସ୍ଥାରେ ବଢ଼ିବାକୁ ବହୁତ ସୁଯୋଗ ଅଛି | ସେମାନେ କ’ଣ କରନ୍ତି ଜାଣିବା ପାଇଁ ସେ ବିଭିନ୍ନ ଦଳର ନୂତନ ଲୋକଙ୍କୁ ଭେଟନ୍ତି |",
            "as": "কিন্তু নবীনে অনুভৱ কৰে যে এই সংগঠনটোত আগলৈ যোৱাৰ যথেষ্ট সুযোগ আছে। সি বিভিন্ন দলৰ নতুন নতুন লোকক তেওঁলোকে কি কৰে জানিবলৈ লগ ধৰে।",
            "gu": "પણ નવીનને લાગે છે કે અહિયાં તેની પ્રગતિ સારી થસે આ કંપનીમાં. તેને નવા માણસો મળે છે જુદી ટિમના જેમાં તેને ખબર પડે છે તેઓ છું કરે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B9_1_5",
            "hi": "B9_1_5",
            "ka": "B9_1_5",
            "te": "B9_1_5",
            "or": "B9_1_5",
            "as": "B9_1_5",
            "gu": "B9_1_5"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He learns about software sales which is an entirely new and upcoming field. He hopes to get more experience to become a software sales professional one day.",
            "hi": "उसे सॉफ्टवेयर सेल्स के बारे में पता चलता है जो पूरी तरह से नया और उभरता फिल्ड है। वह चाहता है कि एक दिन से सॉफ्टवेयर सेल्स प्रोफेशनल बनने के लिए अधिक अनुभव प्राप्त हो।",
            "ka": "ಅವನು ಸಂಪೂರ್ಣವಾಗಿ ಹೊಸ ಮತ್ತು ಮುಂಬರುವ ಕ್ಷೇತ್ರವಾಗಿರುವ ಸಾಫ್ಟ್‌ವೇರ್ ಮಾರಾಟದ ಬಗ್ಗೆ ಕಲಿಯುತ್ತಾನೆ. ಮುಂದೊಂದು ದಿನ ಸಾಫ್ಟ್‌ವೇರ್ ಮಾರಾಟ ವೃತ್ತಿಪರರಾಗಲು ಹೆಚ್ಚಿನ ಅನುಭವವನ್ನು ಅವರು ಪಡೆಯುತ್ತಾನೆ ಎಂದು ಅವರು ಭಾವಿಸುತ್ತಾನೆ.",
            "te": "అతను సాఫ్ట్‌వేర్ సేల్స్ గురించి తెలుసుకుంటాడు, ఇది పూర్తిగా కొత్త మరియు రాబోయే ఫీల్డ్. ఏదో ఒక రోజు సాఫ్ట్‌వేర్ సేల్స్ ప్రొఫెషనల్‌గా మారడానికి మరింత అనుభవం పొందాలని అతను అనుకుంటాడు.",
            "or": "ସେ ସଫ୍ଟୱେୟର୍ ବିକ୍ରୟ ବିଷୟରେ ଶିଖନ୍ତି ଯାହା ଏକ ସମ୍ପୂର୍ଣ୍ଣ ନୂତନ ଏବଂ ଆଗାମୀ କ୍ଷେତ୍ର ଅଟେ | ସେ ଦିନେ ଏକ ସଫ୍ଟୱେୟର୍ ସେଲ୍ସ ପ୍ରୋଫେସ୍‌ନାଲ୍‌ ହେବା ପାଇଁ ଅଧିକ ଅଭିଜ୍ଞତା ପାଇବାକୁ ଆଶା କରନ୍ତି |",
            "as": "সি ছফ্টৱেৰ বিক্ৰীৰ বিষয়ে শিকিবলৈ পায় যিটো এটা সম্পূৰ্ণ নতুন ক্ষেত্ৰ। সি এদিন পেছাদাৰী ছফ্টৱেৰ বিক্ৰেতা হ'বলৈ আৰু অভিজ্ঞতা লাভ কৰিব বুলি আশা কৰে।",
            "gu": "તે સોફ્ટવેરથી વેચાણ વિષે જાણે છે તે તેના માટે એકદમ નવું અને આવનારા ક્ષેત્ર છે. અને તેને અસા છે કે તે એક દિવસ સોફ્ટવેર સેલ્સ ધંધાદારી બનશે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B9_2_1",
            "hi": "B9_2_1",
            "ka": "B9_2_1",
            "te": "B9_2_1",
            "or": "B9_2_1",
            "as": "B9_2_1",
            "gu": "B9_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The job gives him and his family financial security, but Naveen has started going back to meet his crew in the evening to practice. That is where he feels happy and fulfilled.",
            "hi": "यह जॉब उसे और उसके परिवार को आर्थिक सुरक्षा देता है लेकिन नवीन फिर से शाम को अपने डान्स ग्रुप से मिलकर प्रैक्टिस करने लगता है। उसे इसी में आनंद और सुकून मिलता है ।",
            "ka": "ಕೆಲಸವು ಅವನಿಗೆ ಮತ್ತು ಅವನ ಕುಟುಂಬಕ್ಕೆ ಆರ್ಥಿಕ ಭದ್ರತೆಯನ್ನು ನೀಡುತ್ತದೆ, ಆದರೆ ನವೀನ್ ಅಭ್ಯಾಸ ಮಾಡಲು ಸಂಜೆ ತನ್ನ ಸಿಬ್ಬಂದಿಯನ್ನು ಭೇಟಿ ಮಾಡಲು ಹಿಂತಿರುಗಲು ಪ್ರಾರಂಭಿಸಿದನು. ಅಲ್ಲಿಯೇ ಅವನು ಸಂತೋಷ ಮತ್ತು ತೃಪ್ತಿಯನ್ನು ಅನುಭವಿಸುತ್ತಾನೆ.",
            "te": "ఉద్యోగం అతనికి మరియు అతని కుటుంబానికి ఫైనాన్షియల్ సెక్యూరిటీ ఇస్తుంది, కానీ నవీన్ సాయంత్రం సాధన చేయడానికి తన బృందం దగ్గరికి వెళ్తాడు. అక్కడే అతను సంతోషంగా మరియు సంతృప్తిగా ఉంటాడు.",
            "or": "ଚାକିରି ତାଙ୍କୁ ଏବଂ ତାଙ୍କ ପରିବାରକୁ ଆର୍ଥିକ ସୁରକ୍ଷା ଦେଇଥାଏ, କିନ୍ତୁ ନବୀନ ଅଭ୍ୟାସ କରିବା ପାଇଁ ସନ୍ଧ୍ୟାରେ ତାଙ୍କ କୃୟୁଙ୍କୁ ଭେଟିବାକୁ ଫେରିବା ଆରମ୍ଭ କରି ଦିଅନ୍ତି | ସେହିଠାରେ ସେ ନିଜକୁ ଖୁସି ଏବଂ ପୂର୍ଣ୍ଣ ଅନୁଭବ କରନ୍ତି |",
            "as": "চাকৰিটোৱে তাক আৰু তাৰ পৰিয়ালক আৰ্থিক সুৰক্ষা দিছে, কিন্তু নবীনে সন্ধিয়া তেওঁৰ কৰ্মচাৰীসকলক অনুশীলনৰ বাবে পুনৰ লগ কৰিবলৈ যাবলৈ আৰম্ভ কৰিছে। তাতেই সি সুখী আৰু পৰিপূৰ্ণ অনুভৱ কৰে।",
            "gu": "તે નોકરી તેને અને તેના કુટુંબને આર્થિક સુરક્ષા આપે છે, પણ નવીન તેના મિત્રોને સાંજે પાછા મળવા જવાનું ચાલુ કરે છે પ્રેક્ટિસ માટે. તેના લીધે ખુશ અને પરિપૂર્ણ થાય છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Sales manager at Zell",
            "hi": "झेल में सेल्स मैनजेर",
            "ka": "**THREAD_END**_ಝೆಲ್‌ ನಲ್ಲಿ ಮಾರಾಟ ವ್ಯವಸ್ಥಾಪಕ",
            "te": "**THREAD_END**_జెల్ లో సేల్స్ మేనేజర్",
            "or": "**THREAD_END**_Sales manager at Zell",
            "as": "**থ্ৰেড_সমাপ্ত**_জেলত বিক্ৰী মেনেজাৰ",
            "gu": "**THREAD_END**_જીલ ખાતે સેલ્સ મેનેજર"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Naveen that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने नवीन के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँचा है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकता है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n \n ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾದ ನವೀನ್‌ಗಾಗಿ ನೀವು ಆಯ್ಕೆಗಳನ್ನು ಮಾಡಿದ್ದೀರಿ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు నవీన్ కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ !\n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି !\n \n ଆପଣ ନବୀନଙ୍କ ପାଇଁ ବାଛିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତି ଘଟାଇଛି | ଆପଣ କରୁଥିବା ପସନ୍ଦ ଉପରେ ଆଧାର କରି ଭିନ୍ନ ଭିନ୍ନ ସମାପ୍ତି ଅଛି | ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକୁ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ?",
            "as": "অভিনন্দন! তুমি এটা যাত্ৰা সমাপ্ত কৰিছা ৷ তুমি নবীনৰ বাবে কৰা বাছনিবোৰৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত ভিত্তি কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ চাব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક મુસાફરી પૂરી કરી છે!\n\nતમે નવીન માટે પસંદગીઓ કરી છે જે આ સંભવિત અંત તરફ દોરી ગઈ છે. તમે જે પસંદગી કરી છે તેનાથી અલગ અલગ અંત છે. શું તમે બીજા દ્રશ્યો ખોલવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ରକୁ ଦେଖନ୍ତୁ |",
            "as": "মানচিত্ৰখনৰ পিনে চোৱা",
            "gu": "ચાલો નકશા પર એક નજર નાખીએ"
          }
        }
      ]
    },
    "scene13": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_1_1_study in an ITI",
            "hi": "ITI में पढ़ना",
            "ka": "C1_1_1_ಐಟಿಐನಲ್ಲಿ ಅಧ್ಯಯನ",
            "te": "C1_1_1_ITIలో చదువు",
            "or": "C1_1_1_study in an ITI",
            "as": "C1_1_1_এখন ITIত অধ্যয়ন",
            "gu": "C1_1_1_ITI માં અભ્યાસ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Zaid",
            "hi": "जाएद",
            "ka": "ಝೈದ್",
            "te": "జైద్",
            "or": "ଜାଏଦ୍‌",
            "as": "জাইদ",
            "gu": "ઝાઈદ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.label",
          "value": {
            "en": "Zaid",
            "hi": "जाएद",
            "ka": "ಝೈದ್",
            "te": "జైద్",
            "or": "ଜାଏଦ୍‌",
            "as": "জাইদ",
            "gu": "ઝાઈદ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "It’s actually a good idea. But that would mean letting go of dance.",
            "hi": "यह सच में अच्छी कल्पना है। लेकिन फिर तुम डान्स नहीं कर पाओगे।",
            "ka": "ಇದು ವಾಸ್ತವವಾಗಿ ಒಳ್ಳೆಯ ಉಪಾಯ. ಆದರೆ ಅದು ನೃತ್ಯವನ್ನು ಬಿಡುವುದು ಎಂದರ್ಥ.",
            "te": "ఇది నిజానికి మంచి ఆలోచన. అయితే అది డ్యాన్స్‌ని వదులుకోవడం అని అర్థం.",
            "or": "ଏହା ପ୍ରକୃତରେ ଏକ ଭଲ ଆଇଡିଆ | କିନ୍ତୁ ଏହାର ଅର୍ଥ ହେଉଛି ନୃତ୍ୟ ଛାଡିବା |",
            "as": "এইটো প্ৰকৃততে এটা ভাল ধাৰণা। কিন্তু ইয়াৰ অৰ্থ হ'ব নৃত্য এৰি দিয়া।",
            "gu": "આ સરસ વિચાર છે. પણ તેના લીધે ડાંસ છોડી દઇશ."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Are you sure Naveen? You’ve been so keen on pursuing your passion.",
            "hi": "क्या तुम्हें पूरा यकीन है, नवीन? तुम तो अपने डान्स में करियर करना चाहते थे।",
            "ka": "ನವೀನ್ ನಿಮಗೆ ಖಚಿತವಾಗಿದೆಯೇ? ನಿಮ್ಮ ಉತ್ಸಾಹವನ್ನು ಮುಂದುವರಿಸಲು ನೀವು ತುಂಬಾ ಉತ್ಸುಕರಾಗಿದ್ದೀರಿ.",
            "te": "నవీన్ నువ్వు ఖచ్చితంగా అనుకుంటున్నావా ? మీరు మీ అభిరుచిని కొనసాగించడానికి చాలా ఆసక్తిగా ఉన్నారు.",
            "or": "ତୁମେ ନିଶ୍ଚିତ କି ନବୀନ ? ତୁମେ ତୁମର ଆଗ୍ରହକୁ ଅନୁସରଣ କରିବାକୁ ଏତେ ଆଗ୍ରହୀ |",
            "as": "তুমি নিশ্চিত নে নবীন? তুমি তোমাৰ আবেগ অনুসৰণ কৰিবলৈ আগ্ৰহী আছিলা।",
            "gu": "તને ખત્રિ છે નવીન? તું તારા શોખને આગળ વધારવા ખૂબ આતુર છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "I need a stable job Zaid. My family can’t keep supporting me much longer.",
            "hi": "मुझे एक स्थिर नौकरी की जरूरत है जाएद। मेरा परिवार ज्यादा समय तक मेरी जिम्मेदारी नहीं उठा सकता।",
            "ka": "ನನಗೆ ಸ್ಥಿರವಾದ ಕೆಲಸ ಬೇಕು ಝೈದ್ . ನನ್ನ ಕುಟುಂಬದವರು ನನಗೆ ಹೆಚ್ಚು ದಿನ ಬೆಂಬಲ ನೀಡಲು ಸಾಧ್ಯವಿಲ್ಲ.",
            "te": "నాకు స్థిరమైన ఉద్యోగం కావాలి జైద్. నా కుటుంబం నాకు ఎక్కువ కాలం మద్ధతు ఇవ్వలేదు.",
            "or": "ମୋତେ ଏକ ସ୍ଥାୟୀ ଚାକିରି ଦରକାର ଜାଏଦ୍‌ | ମୋ ପରିବାର ମୋତେ ଅଧିକ ସମୟ ସାହାଯ୍ୟ କରି ପାରିବେ ନାହିଁ |",
            "as": "মোক এটা সুস্থিৰ চাকৰি লাগে। মোৰ পৰিয়ালে মোক বেছি দিন চলাব নোৱাৰিব।",
            "gu": "મારે સ્થિર નોકરી જોઈએ છે ઝાઈદ. મારૂ કુટુંબ મને વધારે સમય માટે મદદ ના કરી શકે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.text",
          "value": {
            "en": "I agree, in that case, this is a very good option to become financially independent soon.",
            "hi": "मैं समझता हूँ, ऐसा है तो, आर्थिक रूप से जल्दी स्वतंत्र होने के लिए यह बहुत अच्छा ऑप्शन है।",
            "ka": "ನಾನು ಒಪ್ಪುತ್ತೇನೆ, ಆ ಸಂದರ್ಭದಲ್ಲಿ, ಶೀಘ್ರದಲ್ಲೇ ಆರ್ಥಿಕವಾಗಿ ಸ್ವತಂತ್ರರಾಗಲು ಇದು ಉತ್ತಮ ಆಯ್ಕೆಯಾಗಿದೆ.",
            "te": "నేను అంగీకరిస్తున్నాను, అలాంటప్పుడు, త్వరలో ఆర్థికంగా స్వతంత్రంగా మారడానికి ఇది చాలా మంచి ఎంపిక.",
            "or": "ମୁଁ ସହମତ, ସେହି କ୍ଷେତ୍ରରେ, ଶୀଘ୍ର ଆର୍ଥିକ ସ୍ୱାଧୀନ ହେବା ପାଇଁ ଏହା ଏକ ବହୁତ ଭଲ ବିକଳ୍ପ |",
            "as": "মই মানিছো, সেই ক্ষেত্ৰত, সোনকালে আৰ্থিকভাৱে স্বতন্ত্ৰ হ’বলৈ এইটো এটা ভাল বিকল্প।",
            "gu": "હું સમજુ છે તારા આ કિસ્સાને, આ સારો વિકલ્પ છે કે તું જાતે જ આર્થિક રીતે સ્વતંત્ર બને."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_1_2",
            "hi": "C1_1_2",
            "ka": "C1_1_2",
            "te": "C1_1_2",
            "or": "C1_1_2",
            "as": "C1_1_2",
            "gu": "C1_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He borrows the ITI prospectus from Zaid. A prospectus is a book sharing information about the college and the courses it offers.",
            "hi": "वह जाएद से ITI का प्रोस्पेक्टस देखने के लिए माँगता है। प्रोस्पेक्टस एक ऐसी पुस्तक होती हैं जिसमें कॉलेज और इसके द्वारे दिए जानेवाले कोर्सेस के बारे में जानकारी होती है।",
            "ka": "ಅವನು ಝೈದ್ ಅವರಿಂದ ಐಟಿಐ ಪ್ರಾಸ್ಪೆಕ್ಟಸ್ ಅನ್ನು ಎರವಲು ಪಡೆದನು. ಪ್ರಾಸ್ಪೆಕ್ಟಸ್ ಎನ್ನುವುದು ಕಾಲೇಜು ಮತ್ತು ಅದು ನೀಡುವ ಕೋರ್ಸ್‌ಗಳ ಬಗ್ಗೆ ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳುವ ಪುಸ್ತಕವಾಗಿದೆ.",
            "te": "అతను జైద్ నుండి ITI ప్రాస్పెక్టస్‌ను తీసుకున్నాడు. ప్రాస్పెక్టస్ అనేది కళాశాల మరియు అది అందించే కోర్సుల గురించి సమాచారాన్ని పంచుకునే పుస్తకం.",
            "or": "ସେ ଜାଏଦ୍‌ଙ୍କ ଠାରୁ ITI ପ୍ରୋସପେକ୍ଟସ ଧାର୍‌ କରନ୍ତି | ପ୍ରୋସପେକ୍ଟସ୍ ହେଉଛି କଲେଜ ଏବଂ ଏହା ପ୍ରଦାନ କରୁଥିବା ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ସୂଚନା ଅଂଶୀଦାର କରୁଥିବା ଏକ ବହି |",
            "as": "সি জাইদৰ পৰা ITI প্ৰস্পেক্টাছখন খুজি আনিলে। প্ৰস্পেক্টাচ হৈছে মহাবিদ্যালয় আৰু ই আগবঢ়োৱা পাঠ্যক্ৰমৰ বিষয়ে তথ্য জনাৰ এখন কিতাপ।",
            "gu": "તેને ITIની પુસ્તિકા ઝાઈદ જોડેથી માગ્યું. આ પુસ્તિકા એક પુસ્તક છે જેમાં કોલેજ અને કોર્સ વિષે બધી માહિત આપેલી હોય. "
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_2_1_multipopup",
            "hi": "C1_2_1_मल्टीपॉपअप",
            "ka": "C1_2_1_ಬಹುಪಾಪ್ಅಪ್",
            "te": "C1_2_1_మల్టిపాపప్",
            "or": "C1_2_1_multipopup",
            "as": "C1_2_1_মাল্টিপপআপ",
            "gu": "C1_2_1_વિવિધ પોપ અપ"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "When he reaches home, he opens the prospectus to learn about the different courses.",
            "hi": "घर जाकर वह प्रोस्पेक्टस में दिए हुए अलग-अलग कोर्सेस के बारे में पढ़ता है।",
            "ka": "ಅವನು ಮನೆಗೆ ತಲುಪಿದಾಗ, ಅವನು ವಿವಿಧ ಕೋರ್ಸ್‌ಗಳ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಲು ಪ್ರಾಸ್ಪೆಕ್ಟಸ್ ಅನ್ನು ತೆರೆಯುತ್ತಾನೆ.",
            "te": "అతను ఇంటికి చేరుకున్నప్పుడు, అతను వివిధ కోర్సుల గురించి తెలుసుకోవడానికి ప్రాస్పెక్టస్‌ను తెరుస్తాడు.",
            "or": "ଯେତେବେଳେ ସେ ଘରେ ପହଞ୍ଚନ୍ତି, ସେ ବିଭିନ୍ନ ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ଜାଣିବା ପାଇଁ ପ୍ରୋସପେକ୍ଟସ୍ ଖୋଲନ୍ତି |",
            "as": "সি ঘৰলৈ আহাৰ পিছত, বিভিন্ন পাঠ্যক্ৰমৰ বিষয়ে জানিবলৈ প্ৰস্পেক্টাচখন পঢ়িলে।",
            "gu": "તે ઘરે પહોંચા પછી, ત્યારે તે પુસ્તિકા ખોલે છે વિવિધ અભ્યાસક્રમો વિશે જાણવા માટે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Page 1",
            "hi": "पेज 1",
            "ka": "ಪುಟ 1",
            "te": "పేజ్ 1",
            "or": "Page 1",
            "as": "পৃষ্ঠা 1",
            "gu": "પાનું 1"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Page 2",
            "hi": "पेज 2",
            "ka": "ಪುಟ 2",
            "te": "పేజ్ 2",
            "or": "Page 2",
            "as": "পৃষ্ঠা 2",
            "gu": "પાનું 2"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Non-engineering subjects\n\nCourses such as  Food and Hospitality, Front office and Administration, Travel and Tourism, Computer Operations \n\nJob opportunities: Chef, bartender, front office, data entry operator, tour booking and planning, housekeeping etc. \n\nEligibility: 10th pass\n\nDuration: 1 year\n\nFees: ₹4000/year\n\nMarket: Hotels, resorts, restaurants, offices, travel agencies",
            "hi": "नॉन-इंजिनियरिंग विषय \n \n फूड और हॉस्पिटैलिटी, फ्रंट ऑफिस और एडमिनिस्ट्रेशन, ट्रेवल और टूरिजम, कंप्यूटर ऑपरेशन्स जैसे कोर्स \n \n नौकरी के अवसर: शेफ, बारटेंडर, फ्रंट ऑफिस, डेटा एंट्री ऑपरेटर, टूर बुकिंग और प्लानिंग, हाउसकीपिंग आदि. \n \n पात्रता: 10वीं पास \n \n कालावधि: 1 वर्ष \n \n फी: ₹4000/वर्ष\n \n मार्केट: होटल, रिसोर्ट, रेस्तरां, ऑफिस, ट्रेवल एजन्सी",
            "ka": "ಇಂಜಿನಿಯರಿಂಗ್ ಅಲ್ಲದ ವಿಷಯಗಳು\n \n ಆಹಾರ ಮತ್ತು ಆತಿಥ್ಯ, ಮುಂಭಾಗದ ಕಚೇರಿ ಮತ್ತು ಆಡಳಿತ, ಪ್ರಯಾಣ ಮತ್ತು ಪ್ರವಾಸೋದ್ಯಮ, ಕಂಪ್ಯೂಟರ್ ಕಾರ್ಯಾಚರಣೆಗಳಂತಹ ಕೋರ್ಸ್‌ಗಳು\n \n ಉದ್ಯೋಗಾವಕಾಶಗಳು: ಬಾಣಸಿಗ, ಬಾರ್ಟೆಂಡರ್, ಫ್ರಂಟ್ ಆಫೀಸ್, ಡೇಟಾ ಎಂಟ್ರಿ ಆಪರೇಟರ್, ಪ್ರವಾಸ ಬುಕಿಂಗ್ ಮತ್ತು ಯೋಜನೆ, ಮನೆಗೆಲಸ ಇತ್ಯಾದಿ.\n \n ಅರ್ಹತೆ: 10 ನೇ ತೇರ್ಗಡೆ\n \n ಅವಧಿ: 1 ವರ್ಷ\n \n ಶುಲ್ಕ: ₹4000/ವರ್ಷ\n \n ಮಾರುಕಟ್ಟೆ: ಹೋಟೆಲ್‌ಗಳು, ರೆಸಾರ್ಟ್‌ಗಳು, ರೆಸ್ಟೋರೆಂಟ್‌ಗಳು, ಕಚೇರಿಗಳು, ಟ್ರಾವೆಲ್ ಏಜೆನ್ಸಿಗಳು",
            "te": "నాన్-ఇంజనీరింగ్ సబ్జెక్ట్‌లు\n \n ఫుడ్ అండ్ హాస్పిటాలిటీ, ఫ్రంట్ ఆఫీస్ అండ్ అడ్మినిస్ట్రేషన్, ట్రావెల్ అండ్ టూరిజం, కంప్యూటర్ ఆపరేషన్స్ వంటి కోర్సులు\n \n ఉద్యోగ అవకాశాలు: చెఫ్, బార్ టెండర్, ఫ్రంట్ ఆఫీస్, డేటా ఎంట్రీ ఆపరేటర్, టూర్ బుకింగ్ మరియు ప్లానింగ్, హౌస్ కీపింగ్ మొదలైనవి.\n \n అర్హత: 10వ తరగతి ఉత్తీర్ణత\n \n వ్యవధి: 1 సంవత్సరం\n \n ఫీజు: ₹4000/సంవత్సరం\n \n మార్కెట్: హోటళ్లు, రిసార్ట్‌లు, రెస్టారెంట్లు, కార్యాలయాలు, ట్రావెల్ ఏజెన్సీలు",
            "or": "ଅଣ-ଇଞ୍ଜିନିୟରିଂ ବିଷୟ\n \n ଖାଦ୍ୟ ଏବଂ ଆତିଥ୍ୟ, ଫ୍ରଣ୍ଟ ଅଫିସ୍ ଏବଂ ଆଡମିନିଷ୍ଟ୍ରେସନ୍, ଟ୍ରାଭେଲ୍‌ ଏବଂ ଟୁରିଜ୍ମ୍‍, କମ୍ପ୍ୟୁଟର ଅପରେସନ୍ ଭଳି ପାଠ୍ୟକ୍ରମ\n \n ଚାକିରିର ସୁଯୋଗ: ଶେଫ୍, ବାର୍‌ଟେଣ୍ଡ୍ର୍‍, ଫ୍ରଣ୍ଟ ଅଫିସ୍, ଡାଟା ଏଣ୍ଟ୍ରି ଅପ୍ରେଟର୍‍, ଟୁର ବୁକିଂ ଏବଂ ପ୍ଲାନିଂ, ହାଉସ୍‌କିପିଂ ଇତ୍ୟାଦି |\n \n ଯୋଗ୍ୟତା: 10th ପାସ୍\n \n ସମୟ: 1 ବର୍ଷ\n \n ଦେୟ: ₹ 4000 / ବର୍ଷ \n \n ବଜାର: ହୋଟେଲ, ରିସର୍ଟ, ରେଷ୍ଟୁରାଣ୍ଟ, ଅଫିସ୍, ଟ୍ରାଭେଲ୍ ଏଜେନ୍ସି",
            "as": "অ-অভিযান্ত্ৰিক বিষয় ৷ খাদ্য আৰু আতিথ্য, সন্মুখৰ কাৰ্যালয় আৰু প্ৰশাসন, ভ্ৰমণ আৰু পৰ্যটন, কম্পিউটাৰ কাৰ্যকলাপৰ দৰে পাঠ্যক্ৰম ৷ চাকৰিৰ সুযোগ: ৰান্ধনী, বাৰটেণ্ডাৰ, সন্মুখৰ কাৰ্যালয়, ডাটা এণ্ট্ৰি অপাৰেটৰ, ট্যুৰ বুকিং আৰু পৰিকল্পনা, হাউচকিপিং ইত্যাদি। যোগ্যতা: দশম উত্তীৰ্ণ ৷ ম্যাদ: ১ বছৰ ৷ মাচুল:৪০০০ টকা/বছৰ ৷ বজাৰ: হোটেল, ৰিজৰ্ট, ৰেষ্টুৰেণ্ট, কাৰ্যালয়, ভ্ৰমণ সংস্থা",
            "gu": "નોન-એન્જિનિયરિંગ વિષયો\n\nઅભ્યાસક્રમો જેવા કે, ફૂડ એન્ડ હોસ્પિટાલિટી, ફ્રન્ટ ઓફિસ અને એડમિનિસ્ટ્રેશન, ટ્રાવેલ એન્ડ ટુરીઝમ, કોમ્પ્યુટર ઓપરેશન્સ \n\nનોકરીની તકો: રસોઇયા, બારટેન્ડર, ફ્રન્ટ ઓફિસ, ડેટા એન્ટ્રી ઓપરેટર, ટૂર બુકિંગ અને યોજનાઓ, હાઉસકીપિંગ વગેરે.\n\nપાત્રતા: 10મું પાસ\n\nસમયગાળો: 1 વર્ષ\n\nફી: ₹4000/વર્ષ\n\nબજાર: હોટેલ્સ, રિસોર્ટ્સ, રેસ્ટોરન્ટ્સ, ઓફિસો, ટ્રાવેલ એજન્સીઓ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Engineering subjects\n\nCourses such as  Fitter, Electrician, Mechanic, Welder, Plumber, Refrigeration & AC repair \n\nJob opportunities: Fitter, repair technician, mechanic, welder, plumber\n\nEligibility: 10th pass\n\nDuration: 2 years\n\nFees: ₹3000/year\n\nMarket: Public sector enterprise, factory, stand-alone shops, small scale industries, own business etc.",
            "hi": "इंजीनियरिंग विषय \n \n फिटर, इलेक्ट्रिशियन, मैकेनिक, वेल्डर, प्लंबर, फ्रिज और ईएसआई रिपेअर \n \n नौकरी के अवसर: फिटर, रिपेअर टेक्निशियन, मैकेनिक, वेल्डर, प्लंबर \n \n पात्रता: 10वीं पास\n \n कालावधि: 2 वर्ष\n \n फी: ₹3000/वर्ष\n \n मार्केट: पब्लिक सेक्टर इंटरप्राइजेज, फैक्टरी, दुकान, छोटी इंडस्ट्री, खुद का बिजनेस आदि.",
            "ka": "ಎಂಜಿನಿಯರಿಂಗ್ ವಿಷಯಗಳು\n \n ಫಿಟ್ಟರ್, ಎಲೆಕ್ಟ್ರಿಷಿಯನ್, ಮೆಕ್ಯಾನಿಕ್, ವೆಲ್ಡರ್, ಪ್ಲಂಬರ್, ರೆಫ್ರಿಜರೇಶನ್ ಮತ್ತು ಎಸಿ ರಿಪೇರಿ ಮುಂತಾದ ಕೋರ್ಸ್‌ಗಳು\n \n ಉದ್ಯೋಗಾವಕಾಶಗಳು: ಫಿಟ್ಟರ್, ರಿಪೇರಿ ತಂತ್ರಜ್ಞ, ಮೆಕ್ಯಾನಿಕ್, ವೆಲ್ಡರ್, ಪ್ಲಂಬರ್\n \n ಅರ್ಹತೆ: 10 ನೇ ತೇರ್ಗಡೆ\n \n ಅವಧಿ: 2 ವರ್ಷಗಳು\n \n ಶುಲ್ಕ: ₹ 3000/ವರ್ಷ\n \n ಮಾರುಕಟ್ಟೆ: ಸಾರ್ವಜನಿಕ ವಲಯದ ಉದ್ಯಮ, ಕಾರ್ಖಾನೆ, ಅದ್ವಿತೀಯ ಅಂಗಡಿಗಳು, ಸಣ್ಣ ಪ್ರಮಾಣದ ಕೈಗಾರಿಕೆಗಳು, ಸ್ವಂತ ವ್ಯಾಪಾರ ಇತ್ಯಾದಿ.",
            "te": "ఇంజనీరింగ్ సబ్జెక్టులు\n \n ఫిట్టర్, ఎలక్ట్రీషియన్, మెకానిక్, వెల్డర్, ప్లంబర్, రిఫ్రిజిరేషన్ & AC రిపేర్ వంటి కోర్సులు\n \n ఉద్యోగ అవకాశాలు: ఫిట్టర్, రిపేర్ టెక్నీషియన్, మెకానిక్, వెల్డర్, ప్లంబర్\n \n అర్హత: 10వ తరగతి పాస్\n \n వ్యవధి:: 2 సంవత్సరాలు\n \n ఫీజు: ₹3000/సంవత్సరం\n \n మార్కెట్: ప్రభుత్వ రంగ సంస్థ, ఫ్యాక్టరీ, స్టాండ్-ఒంటరి దుకాణాలు, చిన్న తరహా పరిశ్రమలు, సొంత వ్యాపారం మొదలైనవి.\"",
            "or": "ଇଞ୍ଜିନିୟରିଂ ବିଷୟ\n \n ଫିଟର୍, ଇଲେକ୍ଟ୍ରିସିଆନ୍‌, ମେକାନିକ୍, ୱେଲଡର୍, ପ୍ଲମ୍ବର, ରେଫ୍ରିଜରେଜେସନ୍ ଏବଂ ଏସି ରିପେୟାର୍‌ ଭଳି ପାଠ୍ୟକ୍ରମ\n \n ଚାକିରି ସୁଯୋଗ: ଫିଟର୍, ମରାମତି ଟେକ୍ନିସିଆନ୍, ମେକାନିକ୍, ୱେଲଡର୍, ପ୍ଲମ୍ବର\n \n ଯୋଗ୍ୟତା: 10th ପାସ୍ \n \n ସମୟ: 2 ବର୍ଷ\n \n ଦେୟ: ₹ 3000/ବର୍ଷ \n \n ବଜାର: ସାର୍ବଜନୀନ କ୍ଷେତ୍ର ଉଦ୍ୟୋଗ, କାରଖାନା, ଏକାକୀ ଦୋକାନ, ଛୋଟ ଶିଳ୍ପ, ନିଜସ୍ୱ ବ୍ୟବସାୟ ଇତ୍ୟାଦି",
            "as": "অভিযান্ত্ৰিক বিষয়সমূহ ৷ ফিটাৰ, ইলেক্ট্ৰিচিয়ান, মেকানিক, ৱেল্ডাৰ, প্লাম্বাৰ, ৰেফ্ৰিজাৰেচন আৰু AC মেৰামতিৰ দৰে পাঠ্যক্ৰম ৷ চাকৰিৰ সুযোগ: ফিটাৰ, মেৰামতি টেকনিচিয়ান, মেকানিক, ৱেল্ডাৰ, প্লাম্বাৰ ৷ যোগ্যতা: 10ম উত্তীৰ্ণ ৷ ম্যাদ: 2 বছৰ ৷ মাচুল: 3000 টকা/বছৰ ৷ বজাৰ: ৰাজহুৱা খণ্ডৰ উদ্যোগ, কাৰখানা, অকলশৰীয়া দোকান, ক্ষুদ্ৰ উদ্যোগ, নিজা ব্যৱসায় ইত্যাদি।",
            "gu": "એન્જિનિયરિંગ વિષયો\n\nફીટર, ઇલેક્ટ્રિશિયન, મિકેનિક, વેલ્ડર, પ્લમ્બર, રેફ્રિજરેશન અને એસી રિપેર જેવા અભ્યાસક્રમો\n\nનોકરીની તકો: ફિટર, રિપેર ટેકનિશિયન, મિકેનિક, વેલ્ડર, પ્લમ્બર\n\nપાત્રતા: 10મું પાસ\n\nસમયગાળો: 2 વર્ષ\n\nફી: ₹3000/વર્ષ\n\nબજાર: જાહેર ક્ષેત્રની એન્ટરપ્રાઇઝ, ફેક્ટરી, એકલી દુકાનો, નાના પાયાના ઉદ્યોગો, પોતાનો વ્યવસાય વગેરે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_2_4",
            "hi": "C1_2_4",
            "ka": "C1_2_4",
            "te": "C1_2_4",
            "or": "C1_2_4",
            "as": "C1_2_4",
            "gu": "C1_2_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen is sure he wants to do an engineering subjects like Mechanic, Fitter, Plumber as it will have more practical learning than theory classes.",
            "hi": "नवीन को यकीन है कि वह मैकेनिक, फिटर, प्लंबर जैसे इंजिनियरिंग विषय पढ़ना चाहता है क्योंकि इसमें थ्योअरी क्लास की तुलना में प्रैक्टिकल तरीके से पढ़ाई होती है।",
            "ka": "ನವೀನ್ ಮೆಕ್ಯಾನಿಕ್, ಫಿಟ್ಟರ್, ಪ್ಲಂಬರ್‌ನಂತಹ ಎಂಜಿನಿಯರಿಂಗ್ ವಿಷಯಗಳನ್ನು ಮಾಡಲು ಬಯಸುತ್ತಾರೆ, ಏಕೆಂದನೆ ಇದು ಸಿದ್ಧಾಂತದ ತರಗತಿಗಳಿಗಿಂತ ಹೆಚ್ಚು ಪ್ರಾಯೋಗಿಕ ಕಲಿಕೆಯನ್ನು ಹೊಂದಿರುತ್ತದೆ.",
            "te": "మెకానిక్, ఫిట్టర్, ప్లంబర్ వంటి ఇంజినీరింగ్ సబ్జెక్టులు థియరీ క్లాసుల కంటే ఎక్కువ ప్రాక్టికల్ లర్నింగ్‌ను కలిగి ఉంటాయి కాబట్టి నవీన్ అవి చదవాలని ఖచ్చితంగా అనుకుంటున్నాడు.",
            "or": "ନବୀନ ନିଶ୍ଚିତ ଯେ ସେ ମେକାନିକ୍, ଫିଟର୍, ପ୍ଲମ୍ବର ଭଳି ଏକ ଇଞ୍ଜିନିୟରିଂ ବିଷୟ କରିବାକୁ ଚାହୁଁଛନ୍ତି କାରଣ ଏଥିରେ ଥିଓରୀ କ୍ଲାସ୍ ଅପେକ୍ଷା ଅଧିକ ପ୍ରାକ୍ଟିକାଲ୍‌ ଶିକ୍ଷା ଥିବ |",
            "as": "নবীন নিশ্চিত যে সি মেকানিক, ফিটাৰ, প্লাম্বাৰৰ দৰে অভিযান্ত্ৰিক বিষয় এটা কৰিব কিয়নো ইয়াত থিয়ৰী শ্ৰেণীতকৈ ব্যৱহাৰিক শিক্ষা বেছিকৈ থাকিব।",
            "gu": "નવીનને ખાતરી છે કે તે એન્જિનિયરિંગના વિષયો કરવા માંગે છે જેમ કે મિકેનિક, ફિટર, પ્લમ્બર જેવા કારણ કે તેમાં થિયરી ક્લાસ કરતાં વધુ પ્રાયોગિક સિખવાનું  હશે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_2_5",
            "hi": "C1_2_5",
            "ka": "C1_2_5",
            "te": "C1_2_5",
            "or": "C1_2_5",
            "as": "C1_2_5",
            "gu": "C1_2_5"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "He is confused about which course he wants to take in the engineering field. By cutting out the subjects he does not like, he arrives at three courses.",
            "hi": "लेकिन अब उसे समझ्न नहीं आ रहा कि इंजिनियरिंग फिल्ड में से कौन सा कोर्स करना चाहिए। उसे जो विषय पसंद नहीं है उन्हें काटने के बाद उसके पास तीन कोर्स बच जाते हैं।",
            "ka": "ಇಂಜಿನಿಯರಿಂಗ್ ಕ್ಷೇತ್ರದಲ್ಲಿ ಯಾವ ಕೋರ್ಸ್ ತೆಗೆದುಕೊಳ್ಳಬೇಕು ಎಂಬ ಗೊಂದಲದಲ್ಲಿದ್ದಾನೆ. ಅವನು ಇಷ್ಟಪಡದ ವಿಷಯಗಳನ್ನು ಕತ್ತರಿಸುವ ಮೂಲಕ, ಅವನು ಮೂರು ಕೋರ್ಸ್‌ಗಳಿಗೆ ಆಗಮಿಸುತ್ತಾನೆ.",
            "te": "ఇంజినీరింగ్‌లో ఏ కోర్సు చదవాలనుకుంటున్నాడో తెలియక అయోమయంలో ఉన్నాడు. తనకు నచ్చని సబ్జెక్టులను తీసివేయడం ద్వారా, అతనికి మూడు కోర్సులు మిగులుతాయి.",
            "or": "ସେ ଇଞ୍ଜିନିୟରିଂ କ୍ଷେତ୍ରରେ କେଉଁ ପାଠ୍ୟକ୍ରମ ନେବାକୁ ଚାହୁଁଛନ୍ତି ସେ ବିଷୟରେ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛନ୍ତି | ସେ ପସନ୍ଦ କରୁନଥିବା ବିଷୟଗୁଡ଼ିକୁ କାଟି, ସେ ତିନୋଟି ପାଠ୍ୟକ୍ରମରେ ପହଞ୍ଚନ୍ତି |",
            "as": "সি অভিযান্ত্ৰিক ক্ষেত্ৰত কোনটো পাঠ্যক্ৰম ল'ব তাক লৈ বিমোৰত পৰিল। সি ভাল নোপোৱা বিষয়বোৰ বাদ দি, তিনিটা পাঠ্যক্ৰম বাছি উলিয়ালে।",
            "gu": "તે મુંઝવણમાં છે કે તેને કયો કોર્સ એન્જીનીયરીંગ ક્ષેત્રમાં કરવા માંગે છે. તે ત્રણ કોર્સને વિષે વિચારે જે તેને ગમે છે બીજા વિષયોને કાઢીને."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_2_5b",
            "hi": "C1_2_5b",
            "ka": "C1_2_5b",
            "te": "C1_2_5b",
            "or": "C1_2_5b",
            "as": "C1_2_5b",
            "gu": "C1_2_5b"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "He decides to read more about them to finalise. Click to know what he finds in each field.",
            "hi": "उनमें से एक कोर्स निश्चित करने के लिए वह उनके बारे में पढ़ने का निर्णय लेटा है। उसने हर एक फिल्ड के बारे में क्या जानकारी हासिल की, यह जानने के लिए क्लिक करें।",
            "ka": "ಅಂತಿಮಗೊಳಿಸಲು ಅದರ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ಓದಲು ಅವನು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಪ್ರತಿ ಕ್ಷೇತ್ರದಲ್ಲಿ ಅವನು ಏನನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾನೆ ಎಂಬುದನ್ನು ತಿಳಿಯಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "te": "అతను వాటిని ఖరారు చేయడానికి మరింత చదవాలని నిర్ణయించుకున్నాడు. ప్రతి రంగంలో అతను ఏమి కనుగొన్నాడో తెలుసుకోవడానికి క్లిక్ చేయండి (నొక్కండి).",
            "or": "ଚୂଡ଼ାନ୍ତ କରିବାକୁ ସେ ସେମାନଙ୍କ ବିଷୟରେ ଅଧିକ ପଢିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି | ସେ ପ୍ରତ୍ୟେକ କ୍ଷେତ୍ରରେ କଣ ପାଇଲେ ଜାଣିବା ପାଇଁ କ୍ଲିକ୍ କରନ୍ତୁ |",
            "as": "সি চূড়ান্ত কৰিবলৈ সেইবোৰৰ বিষয়ে আৰু পঢ়িবলৈ সিদ্ধান্ত লয়। সি প্ৰতিটো ক্ষেত্ৰত কি পালে জানিবলৈ ক্লিক কৰা।",
            "gu": "તે છેલો નિર્ણાય લેવા માટે તે વિશે વધુ વાંચવાનું નક્કી કરે છે. તે વધુ જાણવા માટે ક્લિક કરો જે દરેક ક્ષેત્રમાં શું શોધે છે ."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Fitter, 2 years",
            "hi": "फिटर, 2 वर्ष",
            "ka": "ಫಿಟ್ಟರ್, 2 ವರ್ಷಗಳು",
            "te": "ఫిట్టర్, 2 సంవత్సరాలు",
            "or": "ଫିଟର୍, 2 ବର୍ଷ",
            "as": "ফিটাৰ, ২বছৰ",
            "gu": "ફિટર, 2 વર્ષ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "Electrician, 2 years",
            "hi": "इलेक्ट्रिशियन, 2 वर्ष",
            "ka": "ಎಲೆಕ್ಟ್ರಿಷಿಯನ್, 2 ವರ್ಷಗಳು",
            "te": "ఎలక్ట్రీషియన్, 2 సంవత్సరాలు",
            "or": "ଇଲେକ୍ଟ୍ରିସିଆନ୍, 2 ବର୍ଷ",
            "as": "ইলেক্ট্ৰিচিয়ান, ২ বছৰ",
            "gu": "ઇલેક્ટ્રિશિયન, 2 વર્ષ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.beforeReveal",
          "value": {
            "en": "Mechanic, 2 years",
            "hi": "मैकेनिक, 2 वर्ष",
            "ka": "ಮೆಕ್ಯಾನಿಕ್, 2 ವರ್ಷ",
            "te": "మెకానిక్, 2 సంవత్సరాలు",
            "or": "ମେକାନିକ, 2 ବର୍ଷ",
            "as": "মেকানিক, ২ বছৰ",
            "gu": "મિકેનિક, 2 વર્ષ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "This is a National Council Vocational Training approved course.In this course, you will understand different kinds of fittings. The major learning areas are pipe fitting, machine fitting and structure fitting.",
            "hi": "यह नैशनल काउंसिल वोकेशनल ट्रेनिंग द्वार मान्य कोर्स है। इस कोर्स में, आप अलग-अलग तरह के फिटिंग के बारे में सीखेंगे। मुख्य रूप से पाइप फिटिंग, मशीन फिटिंग और स्ट्रक्चर फिटिंग के बारे में पढ़ाया जाएगा।",
            "ka": "ಇದು ರಾಷ್ಟ್ರೀಯ ಕೌನ್ಸಿಲ್ ವೊಕೇಶನಲ್ ಟ್ರೈನಿಂಗ್ ಅನುಮೋದಿತ ಕೋರ್ಸ್ ಆಗಿದೆ. ಈ ಕೋರ್ಸ್‌ನಲ್ಲಿ, ನೀವು ವಿವಿಧ ರೀತಿಯ ಫಿಟ್ಟಿಂಗ್‌ಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವಿರಿ. ಪ್ರಮುಖ ಕಲಿಕೆಯ ಕ್ಷೇತ್ರಗಳೆಂದರೆ ಪೈಪ್ ಫಿಟ್ಟಿಂಗ್, ಮೆಷಿನ್ ಫಿಟ್ಟಿಂಗ್ ಮತ್ತು ಸ್ಟ್ರಕ್ಚರ್ ಫಿಟ್ಟಿಂಗ್.",
            "te": "ఇది నేషనల్ కౌన్సిల్ వొకేషనల్ ట్రైనింగ్ ఆమోదించబడిన కోర్సు. ఈ కోర్సులో, మీరు వివిధ రకాల ఫిట్టింగ్‌లను అర్థం చేసుకుంటారు. ప్రధాన అభ్యాస ప్రాంతాలు పైప్ ఫిట్టింగ్, మెషిన్ ఫిట్టింగ్ మరియు స్ట్రక్చర్ ఫిట్టింగ్.",
            "or": "ଏହା ଏକ ନ୍ୟାସନାଲ୍‌ କାଉନସିଲ୍ ଭୋକେସନାଲ୍ ଟ୍ରେନିଂ ଅନୁମୋଦିତ ପାଠ୍ୟକ୍ରମ ଅଟେ | ଏହି ପାଠ୍ୟକ୍ରମରେ, ଆପଣ ବିଭିନ୍ନ ପ୍ରକାରର ଫିଟିଙ୍ଗ୍ ବୁଝିବେ | ମୁଖ୍ୟ ଶିଖିବା କ୍ଷେତ୍ରଗୁଡ଼ିକ ହେଉଛି ପାଇପ୍ ଫିଟିଂ, ମେସିନ୍ ଫିଟିଂ ଏବଂ ଷ୍ଟ୍ରକ୍ଚର୍‌ ଫିଟିଂ |",
            "as": "এইটো এটা ৰাষ্ট্ৰীয় পৰিষদ বৃত্তিমূলক প্ৰশিক্ষণ অনুমোদিত পাঠ্যক্ৰম। এই পাঠ্যক্ৰমত, তুমি ফিটিঙৰ বিভিন্ন প্ৰকাৰ বুজি পাবা। মুখ্য শিক্ষণ ক্ষেত্ৰবোৰ হৈছে পাইপ ফিটিং, মেচিন ফিটিং আৰু গাঁথনি ফিটিং।",
            "gu": "આ નેશનલ કાઉન્સિલ વોકેશનલ ટ્રેનિંગ દ્વારા માન્ય અભ્યાસક્રમ છે. આ કોર્સમાં, તમે અલગ અલગ પ્રકારની ફિટિંગને સમજી શકાય. મુખ્ય શિક્ષણ ભાગોમાં પાઇપ ફિટિંગ, મશીન ફિટિંગ અને માળખાના ફિટિંગ છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "This is a National Council Vocational Training approved course. This is a specialized training course where you will learn about the electrical wiring of buildings, transmission lines, stationary machines, and related equipment.",
            "hi": "यह नैशनल काउंसिल वोकेशनल ट्रेनिंग द्वार मान्य कोर्स है। यह एक विशेष ट्रेनिंग कोर्स है जहाँ आप बिल्डिंग के इलेक्ट्रिकल वायरिंग, ट्रांसमिशन लाइन, स्टेशनरी मशीन और संबंधित उपकरणों के बारे में सीखेंगे।",
            "ka": "ಇದು ರಾಷ್ಟ್ರೀಯ ಕೌನ್ಸಿಲ್ ವೊಕೇಶನಲ್ ಟ್ರೈನಿಂಗ್ ಅನುಮೋದಿತ ಕೋರ್ಸ್ ಆಗಿದೆ. ಇದು ವಿಶೇಷ ತರಬೇತಿ ಕೋರ್ಸ್ ಆಗಿದ್ದು, ಕಟ್ಟಡಗಳ ವಿದ್ಯುತ್ ವೈರಿಂಗ್, ಪ್ರಸರಣ ಮಾರ್ಗಗಳು, ಸ್ಥಾಯಿ ಯಂತ್ರಗಳು ಮತ್ತು ಸಂಬಂಧಿತ ಸಲಕರಣೆಗಳ ಬಗ್ಗೆ ನೀವು ಕಲಿಯುವಿರಿ.",
            "te": "ఇది నేషనల్ కౌన్సిల్ వొకేషనల్ ట్రైనింగ్ ఆమోదించబడిన కోర్సు. ఇది ప్రత్యేకమైన శిక్షణా కోర్సు, ఇక్కడ మీరు భవనాల విద్యుత్ వైరింగ్, ట్రాన్స్‌మిషన్ లైన్లు, స్టేషనరీ మెషీన్లు మరియు సంబంధిత పరికరాల గురించి నేర్చుకుంటారు.",
            "or": "ଏହା ଏକ ନ୍ୟାସନାଲ୍‌ କାଉନସିଲ୍ ଭୋକେସନାଲ୍ ଟ୍ରେନିଂ ଅନୁମୋଦିତ ପାଠ୍ୟକ୍ରମ ଅଟେ | ଏହା ଏକ ବିଶେଷ ପ୍ରଶିକ୍ଷଣ ପାଠ୍ୟକ୍ରମ ଅଟେ ଯେଉଁଠାରେ ଆପଣ ବିଲ୍ଡିଂର ଇଲେକ୍ଟ୍ରିକାଲ୍‍ ୱାଏରିଂ, ଟ୍ରାନ୍ସମିସନ୍ ଲାଇନ୍, ଷ୍ଟେସ୍‌ନାରୀ ମେସିନ୍ ଏବଂ ଆନୁଷଙ୍ଗିକ ଉପକରଣ ବିଷୟରେ ଜାଣିବେ |",
            "as": "এইটো এটা ৰাষ্ট্ৰীয় পৰিষদ বৃত্তিমূলক প্ৰশিক্ষণ অনুমোদিত পাঠ্যক্ৰম। এইটো এক বিশেষ প্ৰশিক্ষণ পাঠ্যক্ৰম য'ত তুমি অট্টালিকা, ট্ৰেন্সমিচন লাইন, স্থিৰ যন্ত্ৰ, আৰু সম্পৰ্কিত সঁজুলিৰ বৈদ্যুতিক ৱায়াৰিংৰ বিষয়ে শিকিবা।",
            "gu": "આ નેશનલ કાઉન્સિલ વોકેશનલ ટ્રેનિંગ દ્વારા માન્ય અભ્યાસક્રમ છે. આ એક વિશિષ્ટ તાલીમ અભ્યાસક્રમ છે જ્યાં તમે ઇમારતો, પાવર લાઇન્સ, સ્થિર વાહનો અને સંબંધિત સાધનોના ઇલેક્ટ્રિકલ વાયરિંગ વિશે શીખવા મળશે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.afterReveal",
          "value": {
            "en": "This is a National Council Vocational Training approved course. In this course you will learn about different types of machines, servicing and repairing different kinds of machines.",
            "hi": "यह नैशनल काउंसिल वोकेशनल ट्रेनिंग द्वार मान्य कोर्स है। इस कोर्स में आप अलग-अलग तरह के मशीन, और उनके सर्विसिंग और रिपेअरिंग के बारे में सीखेंगे।",
            "ka": "ಇದು ರಾಷ್ಟ್ರೀಯ ಕೌನ್ಸಿಲ್ ವೊಕೇಶನಲ್ ಟ್ರೈನಿಂಗ್ ಅನುಮೋದಿತ ಕೋರ್ಸ್ ಆಗಿದೆ. ಈ ಕೋರ್ಸ್‌ನಲ್ಲಿ ನೀವು ವಿವಿಧ ರೀತಿಯ ಯಂತ್ರಗಳ ಬಗ್ಗೆ ಕಲಿಯುವಿರಿ, ವಿವಿಧ ರೀತಿಯ ಯಂತ್ರಗಳ ಸೇವೆ ಮತ್ತು ದುರಸ್ತಿ.",
            "te": "ఇది నేషనల్ కౌన్సిల్ వొకేషనల్ ట్రైనింగ్ ఆమోదించబడిన కోర్సు. ఈ కోర్సులో మీరు వివిధ రకాల యంత్రాల గురించి, వివిధ రకాల యంత్రాలకు సర్వీసింగ్ మరియు రిపేర్ చేయడం గురించి నేర్చుకుంటారు.",
            "or": "ଏହା ଏକ ନ୍ୟାସନାଲ୍‌ କାଉନସିଲ୍ ଭୋକେସନାଲ୍ ଟ୍ରେନିଂ ଅନୁମୋଦିତ ପାଠ୍ୟକ୍ରମ ଅଟେ | ଏହି ପାଠ୍ୟକ୍ରମରେ ଆପଣ ବିଭିନ୍ନ ପ୍ରକାରର ମେସିନ୍, ସର୍ଭିସିଂ ଏବଂ ବିଭିନ୍ନ ପ୍ରକାରର ମେସିନ୍ ମରାମତି ବିଷୟରେ ଶିଖିବେ |",
            "as": "এইটো এটা ৰাষ্ট্ৰীয় পৰিষদ বৃত্তিমূলক প্ৰশিক্ষণ অনুমোদিত পাঠ্যক্ৰম। এই পাঠ্যক্ৰমত তুমি বিভিন্ন প্ৰকাৰৰ যন্ত্ৰ আৰু সেইবোৰৰ সেৱা আৰু মেৰামতিৰ বিষয়ে শিকিবা ৷",
            "gu": "આ નેશનલ કાઉન્સિલ વોકેશનલ ટ્રેનિંગ દ્વારા માન્ય અભ્યાસક્રમ છે. આ કોર્સમાં તમે અલગ પ્રકારના મશીનો, અને તેની મશીનોની સર્વિસિંગ એને રિપેરિંગ વિશે શીખવા મળશે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_2_6",
            "hi": "C1_2_6",
            "ka": "C1_2_6",
            "te": "C1_2_6",
            "or": "C1_2_6",
            "as": "C1_2_6",
            "gu": "C1_2_6"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen decides to apply for the mechanic course as there are many job opportunities available for a mechanic in Bangalore.",
            "hi": "नवीन मैकेनिक के कोर्स के लिए अप्लाई करने का निर्णय लेता है, क्योंकि बैंगलोर में मैकेनिक के लिए कई नौकरियां उपलब्ध हैं।",
            "ka": "ಬೆಂಗಳೂರಿನಲ್ಲಿ ಮೆಕ್ಯಾನಿಕ್ ಗೆ ಸಾಕಷ್ಟು ಉದ್ಯೋಗಾವಕಾಶಗಳು ಲಭ್ಯವಿರುವುದರಿಂದ ಮೆಕ್ಯಾನಿಕ್ ಕೋರ್ಸ್ ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನವೀನ್ ನಿರ್ಧರಿಸಿದ್ದಾನೆ.",
            "te": "బెంగుళూరులో మెకానిక్‌కి చాలా ఉద్యోగావకాశాలు అందుబాటులో ఉన్నందున నవీన్ మెకానిక్ కోర్సుకు దరఖాస్తు చేయాలని నిర్ణయించుకున్నాడు.",
            "or": "ଯେହେତୁ ବାଙ୍ଗାଲୋରରେ ଏକ ମେକାନିକ୍ ପାଇଁ ଅନେକ ଚାକିରିର ସୁଯୋଗ ଥିବାରୁ ନବୀନ ମେକାନିକ୍ ପାଠ୍ୟକ୍ରମ ପାଇଁ ଆବେଦନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
            "as": "নবীনে মেকানিক পাঠ্যক্ৰম বাছি লয় কিয়নো বাংগালোৰত মেকানিকৰ বাবে বহুতো চাকৰিৰ সুযোগ আছে।",
            "gu": "નવીન નક્કી કરે છે તે મિકેનિક કોર્સ કરે કારણ કે બેંગ્લોરમાં મિકેનિક માટે નોકરીની ઘણી તકો છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_2_7",
            "hi": "C1_2_7",
            "ka": "C1_2_7",
            "te": "C1_2_7",
            "or": "C1_2_5b",
            "as": "C1_2_7",
            "gu": "C1_2_7"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "And moreover, since he enjoys repairing things, he feels he understands motors and machines well. He has spent a lot of time repairing and cleaning his brother’s auto.",
            "hi": "इसके अलावा, चूँकि उसे वस्तुओं को रिपेअर करना अच्छा लगता है, तो उसे लगता है कि वह मोटर और मशीन को भी अच्छे से समझ सकेगा। उसने अपने भाई की रिक्शा को बहुत बार रिपेअर और साफ किया है।",
            "ka": "ಮತ್ತು ಮೇಲಾಗಿ, ಅವನು ವಸ್ತುಗಳನ್ನು ರಿಪೇರಿ ಮಾಡುವುದನ್ನು ಆನಂದಿಸುವುದರಿಂದ, ಅವನು ಮೋಟಾರ್ ಮತ್ತು ಯಂತ್ರಗಳನ್ನು ಚೆನ್ನಾಗಿ ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದಾನೆಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ. ಅಣ್ಣನ ಆಟೋ ರಿಪೇರಿ ಮತ್ತು ಕ್ಲೀನ್ ಮಾಡುವುದರಲ್ಲಿ ಕಾಲ ಕಳೆದಿದ್ದಾನೆ.",
            "te": "అంతేకాకుండా, అతను వస్తువులను రిపేర్ చేయడం ఆనందిస్తాడు కాబట్టి, అతను మోటార్లు మరియు మెషీన్లను బాగా అర్థం చేసుకోగలనని అనుకుంటాడు. అతను తన సోదరుడి ఆటోను రిపేర్ చేయడం మరియు శుభ్రం చేయడంలో చాలా సమయం గడిపాడు.",
            "or": "ଏବଂ ତା’ଛଡ଼ା, ଯେହେତୁ ସେ ଜିନିଷଗୁଡ଼ିକର ମରାମତି କରିବାକୁ ଉପଭୋଗ କରନ୍ତି, ସେ ଅନୁଭବ କରନ୍ତି ଯେ ସେ ମୋଟର ଏବଂ ମେସିନ୍ଗୁଡ଼ିକୁ ଭଲଭାବେ ବୁଝନ୍ତି | ସେ ନିଜ ଭାଇର ଅଟୋ ମରାମତି ଏବଂ ସଫା କରିବାରେ ବହୁତ ସମୟ ଅତିବାହିତ କରିଛନ୍ତି |",
            "as": "আৰু তাৰোপৰি, যিহেতু সি বস্তুবোৰ মেৰামতি কৰি ভাল পায়, সি ভাৱে যে মটৰ আৰু যন্ত্ৰবোৰ সি ভালদৰে বুজি পায়। সি তাৰ ভায়েকৰ অটোখন মেৰামতি আৰু পৰিষ্কাৰ কৰাত বহুত সময় পাৰ কৰিছে।",
            "gu": "વધુમાં, કારણ કે તેને વસ્તુઓનું સમારકામ કરવાનું પસંદ છે, તેને લાગે છે કે તે એન્જિન અને મશીનને સારી રીતે સમજે છે. તેણે તેના ભાઈની કારને રિપેર કરવામાં અને સાફ કરવામાં ઘણો સમય પસાર કર્યો."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "Research",
            "hi": "रिसर्च",
            "ka": "ಸಂಶೋಧನೆ",
            "te": "పరిశోధన",
            "or": "ଗବେଷଣା",
            "as": "গৱেষণা",
            "gu": "સંશોધન"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained research experience!",
            "hi": "आपने अभी-अभी रिसर्च करने का अनुभव प्राप्त किया हैं!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಸಂಶೋಧನಾ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే పరిశోధన అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତମାନ ଗବେଷଣା ଅଭିଜ୍ଞତା ହାସଲ କରିଛନ୍ତି !",
            "as": "তুমি এইমাত্ৰ গৱেষণাৰ অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "હમણાં જ તમે સંશોધનનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Research",
            "hi": "रिसर्च",
            "ka": "ಸಂಶೋಧನೆ",
            "te": "పరిశోధన",
            "or": "ଗବେଷଣା",
            "as": "গৱেষণা",
            "gu": "સંશોધન"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर विकास कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "వృత్తి అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ଯାରିୟର୍‌ ବିକାଶ କୌଶଳ",
            "as": "জীৱিকা বিকাশৰ দক্ষতা",
            "gu": "કારકિર્દી વિકાસ કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Naveen finds out about the ITI course when he talks to his friend. He later reads the course prospectus to expand his understanding. Reading to get information is a form of research. The world is changing at lightning speed. Research helps to find new ways to overcome difficulties and challenges. You can research by talking to people (teachers, family, people working in a particular field) or finding sources of information such as books and the internet.",
            "hi": "नवीन अपने दोस्त से बात करके ITI कोर्स के बारे में जानकारी लेता है। बाद में वह कोर्स के प्रोस्पेक्टस को पढ़कर इसे और अच्छे से समझ लेता है। पढ़कर जानकारी हासिल करना भी रिसर्च होता है। दुनिया तेजी से बदल रही है। रिसर्च करके आप मुश्किलों और चुनौतियों का समान करने के नए रैके ढूंढ सकते हैं। आप लोगों से (शिक्षक, परिवार, विशेष क्षेत्र में काम करनेवाले लोगों से) बात करके या किताबें और इंटरनेट जसी जानकारी देनेवाले साधनों की मदद से रिसर्च कर सकते हैं।",
            "ka": "ನವೀನ್ ತನ್ನ ಸ್ನೇಹಿತನೊಂದಿಗೆ ಮಾತನಾಡುವಾಗ ಐಟಿಐ ಕೋರ್ಸ್ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆ. ನಂತರ ಅವನು ತನ್ನ ತಿಳುವಳಿಕೆಯನ್ನು ವಿಸ್ತರಿಸಲು ಕೋರ್ಸ್ ಪ್ರಾಸ್ಪೆಕ್ಟಸ್ ಅನ್ನು ಓದುತ್ತಾನೆ. ಮಾಹಿತಿ ಪಡೆಯಲು ಓದುವುದು ಒಂದು ರೀತಿಯ ಸಂಶೋಧನೆ. ಜಗತ್ತು ಮಿಂಚಿನ ವೇಗದಲ್ಲಿ ಬದಲಾಗುತ್ತಿದೆ. ತೊಂದರೆಗಳು ಮತ್ತು ಸವಾಲುಗಳನ್ನು ಜಯಿಸಲು ಹೊಸ ಮಾರ್ಗಗಳನ್ನು ಕಂಡುಹಿಡಿಯಲು ಸಂಶೋಧನೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಜನರೊಂದಿಗೆ ಮಾತನಾಡುವ ಮೂಲಕ (ಶಿಕ್ಷಕರು, ಕುಟುಂಬ, ನಿರ್ದಿಷ್ಟ ಕ್ಷೇತ್ರದಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಜನರು) ಅಥವಾ ಪುಸ್ತಕಗಳು ಮತ್ತು ಇಂಟರ್ನೆಟ್‌ನಂತಹ ಮಾಹಿತಿಯ ಮೂಲಗಳನ್ನು ಕಂಡುಹಿಡಿಯುವ ಮೂಲಕ ನೀವು ಸಂಶೋಧನೆ ಮಾಡಬಹುದು.",
            "te": "నవీన్ తన స్నేహితుడితో మాట్లాడినప్పుడు ITI కోర్సు గురించి తెలుసుకుంటాడు. అతను మరింత అవగాహన కోసం కోర్సు ప్రాస్పెక్టస్‌ని తర్వాత చదివాడు. సమాచారాన్ని పొందడానికి చదవడం అనేది ఒక రకమైన పరిశోధన. ప్రపంచం మెరుపు వేగంతో మారుతోంది. కష్టాలు మరియు సవాళ్లను అధిగమించడానికి కొత్త మార్గాలను కనుగొనడంలో పరిశోధన సహాయపడుతుంది. మీరు వ్యక్తులతో (ఉపాధ్యాయులు, కుటుంబం, నిర్దిష్ట రంగంలో పనిచేస్తున్న వ్యక్తులు) మాట్లాడటం ద్వారా లేదా పుస్తకాలు మరియు ఇంటర్నెట్ వంటి వాటిలో సమాచారం కనుగొనడం ద్వారా పరిశోధన చేయవచ్చు.",
            "or": "ନବୀନ ITI ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ଜାଣନ୍ତି ଯେତେବେଳେ ସେ ତାଙ୍କ ସାଙ୍ଗଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରନ୍ତି | ନିଜର ବୁଝାମଣାକୁ ବଢାଇବା ପାଇଁ ପରେ ସେ କୋର୍ସ ପ୍ରୋସପେକ୍ଟସ ପଢନ୍ତି | ସୂଚନା ପାଇବା ପାଇଁ ପଢିବା ଏକ ଗବେଷଣାର ଏକ ରୂପ ଅଟେ | ବଜ୍ର ବେଗରେ ଦୁନିଆ ବଦଳୁଛି | ଗବେଷଣା ଅସୁବିଧା ଏବଂ ଚ୍ୟାଲେଞ୍ଜଗୁଡିକୁ ଦୂର କରିବା ପାଇଁ ଉପାୟ ଖୋଜିବାରେ ସାହାଯ୍ୟ କରେ | ଆପଣ ଲୋକମାନଙ୍କ (ଶିକ୍ଷକ, ପରିବାର, ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ କ୍ଷେତ୍ରରେ କାର୍ଯ୍ୟ କରୁଥିବା ବ୍ୟକ୍ତି) କିମ୍ବା ପୁସ୍ତକ ଏବଂ ଇଣ୍ଟରନେଟ୍ ପରି ସୂଚନାର ଉତ୍ସ ଖୋଜି ଗବେଷଣା କରିପାରିବେ |",
            "as": "নবীনে তাৰ বন্ধুৰ সৈতে কথা পাতোঁতে ITI পাঠ্যক্ৰমৰ বিষয়ে গম পালে। পিছত তাৰ বুজাবুজি সম্প্ৰসাৰিত কৰিবলৈ সি পাঠ্যক্ৰমৰ প্ৰস্পেক্টাচ পঢ়ে। তথ্য লাভ কৰিবলৈ পঢ়াটোও এক প্ৰকাৰৰ গৱেষণা। পৃথিৱীখন বিজুলী গতিত সলনি হৈ গৈ আছে। গৱেষণাই অসুবিধা আৰু প্ৰত্যাহ্বানবোৰ দূৰ কৰাৰ নতুন উপায় বিচাৰি উলিওৱাত সহায় কৰে। তুমি (শিক্ষক, পৰিয়াল, নিৰ্দিষ্ট ক্ষেত্ৰত কাম কৰা লোক) আদিৰ সৈতে কথা পাতি বা কিতাপ আৰু ইণ্টাৰনেটৰপৰা তথ্যৰ উৎস বিচাৰি গৱেষণা কৰিব পাৰা।",
            "gu": "નવીન જ્યારે તેના મિત્ર સાથે વાત કરે છે ત્યારે તેને ITI કોર્સ વિશે ખબર પડે છે. પછી તે તેની સમજને વધારવા માટે કોર્સનું પ્સ્તક વાંચે છે. માહિતી માટે વાંચન એ સંશોધનનું એક સ્વરૂપ છે. વિશ્વ વીજળીની ઝડપે બદલાઈ રહ્યું છે. સંશોધન કઠિનાઈઓ અને પડકારોને દૂર કરવાની નવી રીતો શોધવામાં મદદ કરે છે. તો તમે લોકો (શિક્ષકો, કુટુંબીજનો, કોઈ ચોક્કસ ક્ષેત્રમાં કામ કરતા લોકો) સાથે વાત કરીને અથવા પુસ્તકો અને ઈન્ટરનેટ જેવી માહિતીના સ્ત્રોતો શોધીને સંશોધન કરી શકો છો."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ",
            "hi": "MCQ",
            "ka": "ಎಂಸಿಕ್ಯೂ",
            "te": "MCQ",
            "or": "MCQ",
            "as": "MCQ",
            "gu": "MCQ"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Select which of the statement about research is TRUE:",
            "hi": "रिसर्च के बारे में निम्नलिखित में से सही विधान चुनें:",
            "ka": "ಸಂಶೋಧನೆಯ ಕುರಿತ ಹೇಳಿಕೆಗಳಲ್ಲಿ ಯಾವುದು ನಿಜ ಎಂಬುದನ್ನು ಆಯ್ಕೆಮಾಡಿ:",
            "te": "పరిశోధన గురించిన ప్రకటనలలో ఏది నిజమో ఎంచుకోండి:",
            "or": "ବାଛନ୍ତୁ ଗବେଷଣା ବିଷୟରେ କେଉଁ ବିବୃତ୍ତି ସତ୍ୟ ଅଟେ:",
            "as": "গৱেষণাৰ বিষয়ে কোনটো মন্তব্য সঁচা বাছনি কৰা:",
            "gu": "સંશોધન વિશે કયું વાક્ય સાચું છે તે પસંદ કરો:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "The internet is the only way to do research and find information",
            "hi": "रिसर्च करने और जानकारी हासिल करने के लिए केवल इंटरनेट ही एकमेव मार्ग है",
            "ka": "ಸಂಶೋಧನೆ ಮಾಡಲು ಮತ್ತು ಮಾಹಿತಿಯನ್ನು ಹುಡುಕಲು ಇಂಟರ್ನೆಟ್ ಏಕೈಕ ಮಾರ್ಗವಾಗಿದೆ",
            "te": "పరిశోధన చేయడానికి మరియు సమాచారం కనుగొనడానికి ఇంటర్నెట్ ఏకైక మార్గం",
            "or": "ଇଣ୍ଟରନେଟ୍ ହେଉଛି ଗବେଷଣା କରିବା ଏବଂ ସୂଚନା ଖୋଜିବା ପାଇଁ ଏକମାତ୍ର ଉପାୟ",
            "as": "গৱেষণা আৰু তথ্য বিচাৰি উলিওৱাৰ একমাত্ৰ উপায় হৈছে ইণ্টাৰনেট",
            "gu": "ઈન્ટરનેટ એ એવું માધ્યમ છે જ્યાં સંશોધન કરવા અને માહિતી શોધવાનો સરળ રસ્તો છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "To do research we can talk to people and read books and newspapers",
            "hi": "रिसर्च करने के लिए हम लोगों से बात कर सकते हैं उया किताबें और अख़बार भी पढ़ सकते हैं।",
            "ka": "ಸಂಶೋಧನೆ ಮಾಡಲು ನಾವು ಜನರೊಂದಿಗೆ ಮಾತನಾಡಬಹುದು ಮತ್ತು ಪುಸ್ತಕಗಳು ಮತ್ತು ಪತ್ರಿಕೆಗಳನ್ನು ಓದಬಹುದು",
            "te": "పరిశోధన చేయడానికి మనం ప్రజలతో మాట్లాడవచ్చు మరియు పుస్తకాలు మరియు వార్తాపత్రికలు చదవవచ్చు",
            "or": "ଗବେଷଣା କରିବା ପାଇଁ ଆମେ ଲୋକଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରିପାରିବା ଏବଂ ବହି ଏବଂ ଖବରକାଗଜ ପଢିପାରିବା",
            "as": "গৱেষণা কৰিবলৈ আমি মানুহৰ সৈতে কথা পাতিব পাৰোঁ আৰু কিতাপ আৰু বাতৰি কাকত পঢ়িব পাৰোঁ",
            "gu": "લોકો સાથે વાત કરીને અને પુસ્તકો અને સમાચારો વાંચીને સંશોધન કરી શકાય."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Research is required only when one needs to find a scholarship to study",
            "hi": "रिसर्च केवल तभी किया जाता है जब आपको पढ़ाई के लिए स्कॉलरशिप ढूंढनी होती है।",
            "ka": "ಅಧ್ಯಯನ ಮಾಡಲು ಸ್ಕಾಲರ್‌ಶಿಪ್ ಹುಡುಕಬೇಕಾದಾಗ ಮಾತ್ರ ಸಂಶೋಧನೆ ಅಗತ್ಯವಿದೆ",
            "te": "చదువుకోవడానికి స్కాలర్‌షిప్‌ (ఉపకారవేతనం) ను కనుగొనవలసి వచ్చినప్పుడు మాత్రమే పరిశోధన అవసరం",
            "or": "ଯେତେବେଳେ ଅଧ୍ୟୟନ ପାଇଁ ଛାତ୍ରବୃତ୍ତି ଖୋଜିବା ଆବଶ୍ୟକ ହୁଏ ସେତେବେଳେ ଗବେଷଣା ଆବଶ୍ୟକ |",
            "as": "গৱেষণাৰ প্ৰয়োজন তেতিয়াহে যেতিয়া অধ্যয়নৰ বাবে বৃত্তি বিচাৰিব লাগে",
            "gu": "સંશોધન જરૂરી છે જ્યારે કોઈને અભ્યાસ માટે શિષ્યવૃત્તિ મેળવવાની જરૂર હોય ત્યારે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Think again! To do research we can talk to people and read books and newspapers.",
            "hi": "फिर से सोचो! रिसर्च करने के लिए हम लोगों से बात कर सकते हैं और किताब और अख़बार भी पढ़ सकते हैं।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಸಂಶೋಧನೆ ಮಾಡಲು ನಾವು ಜನರೊಂದಿಗೆ ಮಾತನಾಡಬಹುದು ಮತ್ತು ಪುಸ್ತಕಗಳು ಮತ್ತು ಪತ್ರಿಕೆಗಳನ್ನು ಓದಬಹುದು.",
            "te": "మళ్లీ ఆలోచించండి! పరిశోధన చేయడానికి మనం ప్రజలతో మాట్లాడవచ్చు మరియు పుస్తకాలు మరియు వార్తాపత్రికలు చదవవచ్చు.",
            "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ଗବେଷଣା କରିବା ପାଇଁ ଆମେ ଲୋକଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରିପାରିବା ଏବଂ ବହି ଏବଂ ଖବରକାଗଜ ପଢ଼ିବା |",
            "as": "আকৌ চিন্তা কৰা! গৱেষণা কৰিবলৈ আমি মানুহৰ সৈতে কথা পাতিব পাৰোঁ আৰু কিতাপ আৰু বাতৰি কাকত পঢ়িব পাৰোঁ ৷",
            "gu": "વિચારો ફરીથી! લોકો સાથે વાત કરી શકીએ છીએ અને પુસ્તકો અને અખબારો વાંચી શકીએ છીએ જેથી સંશોધન કરી શકાય."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "बहुत बढ़िया!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "బాగా చెప్పారు!",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ !",
            "as": "ভাল কৰিছা!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! Research can help us be aware of opportunities throughout our careers.",
            "hi": "फिर से सोचो! रिसर्च हमें अपने करियर के किसी भी मोड़ पर मिलनेवाले अवसरों के बारे में जानकारी दे सकता है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ನಮ್ಮ ವೃತ್ತಿಜೀವನದುದ್ದಕ್ಕೂ ಅವಕಾಶಗಳ ಬಗ್ಗೆ ಅರಿವು ಮೂಡಿಸಲು ಸಂಶೋಧನೆ ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
            "te": "మళ్లీ ఆలోచించండి! మన కెరీర్‌లో అవకాశాల గురించి తెలుసుకోవడంలో పరిశోధన సహాయం చేస్తుంది.",
            "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ଗବେଷଣା ଆମ କ୍ୟାରିଅର୍‌ ସାରା ସୁଯୋଗଗୁଡିକ ବିଷୟରେ ସଚେତନ ହେବାରେ ସାହାଯ୍ୟ କରିବ |",
            "as": "আকৌ চিন্তা কৰা! গৱেষণাই আমাক আমাৰ সমগ্ৰ জীৱিকাৰ সুযোগৰ বিষয়ে সজাগ হোৱাত সহায় কৰিব পাৰে।",
            "gu": "વિચારો ફરીથી! તકોથી વાકેફ રહેવામાં સંશોધન આપણી સમગ્ર કારકિર્દી દરમિયાન મદદ કરી શકે છે."
          }
        }
      ]
    },
    "scene14": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_1_1",
            "hi": "C2_1_1",
            "ka": "C2_1_1",
            "te": "C2_1_1",
            "or": "C2_1_1",
            "as": "C2_1_1",
            "gu": "C2_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "A year has passed.",
            "hi": "एक साल बीत चूका है।",
            "ka": "ಒಂದು ವರ್ಷ ಕಳೆದಿದೆ.",
            "te": "ఒక సంవత్సరం గడిచింది.",
            "or": "ଗୋଟିଏ ବର୍ଷ ବିତିଗଲା",
            "as": "এবছৰ পাৰ হৈ গ'ল।",
            "gu": "એક વર્ષ પ્રસાર થાય છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_1_2",
            "hi": "C2_1_2",
            "ka": "C2_1_2",
            "te": "C2_1_2",
            "or": "C2_1_2",
            "as": "C2_1_2",
            "gu": "C2_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "At the ITI, Naveen learns the theoretical and practical aspects of different machines, parts and other technical details.",
            "hi": "नवीन ITI में अलग-अलग मशीनों और उनके पार्ट्स तथा टेक्निकल विवरणों के बारे में थ्योअरी और प्रैक्टिकल जानकारी सीखता है।",
            "ka": "ಐಟಿಐ ನಲ್ಲಿ, ನವೀನ್ ವಿವಿಧ ಯಂತ್ರಗಳು, ಭಾಗಗಳು ಮತ್ತು ಇತರ ತಾಂತ್ರಿಕ ವಿವರಗಳ ಸೈದ್ಧಾಂತಿಕ ಮತ್ತು ಪ್ರಾಯೋಗಿಕ ಅಂಶಗಳನ್ನು ಕಲಿಯುತ್ತಾನೆ.",
            "te": "ITIలో, నవీన్ వివిధ యంత్రాలు, భాగాలు మరియు ఇతర సాంకేతిక వివరాల యొక్క సిద్ధాంతపరమైన మరియు ఆచరణాత్మక అంశాలను నేర్చుకుంటాడు.",
            "or": "ITI ରେ, ନବୀନ ବିଭିନ୍ନ ମେସିନ୍, ପାର୍ଟସ୍ ଏବଂ ଅନ୍ୟାନ୍ୟ ଯାନ୍ତ୍ରିକ ବିବରଣୀଗୁଡିକର ଥିଓରିଟିକାଲ୍‌ ଏବଂ ପ୍ରାକ୍ଟିକାଲ୍‌ ଦିଗଗୁଡିକ ଶିଖନ୍ତି |",
            "as": "ITI-ত, নবীনে বিভিন্ন যন্ত্ৰ, অংশ আৰু অন্যান্য কাৰিকৰী বিৱৰণৰ তাত্ত্বিক আৰু ব্যৱহাৰিক দিশবোৰ শিকে।",
            "gu": "ITIમાં, નવીન કેટલાક મશીનો, ભાગો અને બીજા તકનીકી વિગતોના સૈદ્ધાંતિક અને પ્રાયોગિક કરવાનું શીખે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_1_3",
            "hi": "C2_1_3",
            "ka": "C2_1_3",
            "te": "C2_1_3",
            "or": "C2_1_3",
            "as": "C2_1_3",
            "gu": "C2_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He likes the course as he gets a lot of time in the workshop.",
            "hi": "उसे यह कोर्स पसदं है क्योंकि वह बहुत सारा समय वर्कशॉप में बिताता है।",
            "ka": "ವರ್ಕ್‌ಶಾಪ್‌ನಲ್ಲಿ ಹೆಚ್ಚು ಸಮಯ ಸಿಗುವುದರಿಂದ ಅವರು ಕೋರ್ಸ್ ಅನ್ನು ಇಷ್ಟಪಡುತ್ತಾನೆ.",
            "te": "వర్క్ షాప్ లో ఎక్కువ సమయం దొరికినందున అతను కోర్సును ఇష్టపడతాడు.",
            "or": "ଯେହେତୁ କର୍ମଶାଳାରେ ସେ ଅଧିକ ସମୟ ପାଆନ୍ତି ସେ ପାଠ୍ୟକ୍ରମକୁ ପସନ୍ଦ କରନ୍ତି |",
            "as": "সি পাঠ্যক্ৰমটো ভাল পাইছে কিয়নো সি কৰ্মশালাত যথেষ্ট সময় কটাব পায়।",
            "gu": "તેને તે કોર્સ ગમે છે કારણ કે તેને ગણો સમય વર્કશોપમાં વીતવા મળે છે. "
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_1_4",
            "hi": "C2_1_4",
            "ka": "C2_1_4",
            "te": "C2_1_4",
            "or": "C2_1_4",
            "as": "C2_1_4",
            "gu": "C2_1_4"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Occasionally when the welding students are also in the lab, they make funny things with the scraps they find in the workshop.",
            "hi": "कभी-कभी जब वेल्डिंग सीखनेवाले विद्यार्थी लैब में जाते हैं, तब वे वर्कशॉप में मिलनेवाले पुराने मटेरियल से अजीब चीजें भी बनाते हैं।",
            "ka": "ಸಾಂದರ್ಭಿಕವಾಗಿ ವೆಲ್ಡಿಂಗ್ ವಿದ್ಯಾರ್ಥಿಗಳು ಲ್ಯಾಬ್‌ನಲ್ಲಿರುವಾಗ, ಅವನು ಕಾರ್ಯಾಗಾರದಲ್ಲಿ ಸಿಗುವ ಸ್ಕ್ರ್ಯಾಪ್‌ಗಳೊಂದಿಗೆ ತಮಾಷೆಯ ವಿಷಯಗಳನ್ನು ಮಾಡುತ್ತಾನೆ.",
            "te": "అప్పుడప్పుడు వెల్డింగ్ విద్యార్థులు కూడా ల్యాబ్‌లో ఉన్నప్పుడు వర్క్‌షాప్‌లో దొరికిన స్క్రాప్‌లతో తమాషాగా తయారు చేస్తుంటారు.",
            "or": "ବେଳେବେଳେ ଯେବେ ୱେଲ୍ଡିଂ ଛାତ୍ରମାନେ ମଧ୍ଯ ଲ୍ୟାବରେ ଥାଆନ୍ତି, ସେମାନେ କର୍ମଶାଳାରେ ପାଇଥିବା ସ୍କ୍ରାପ୍ ସହିତ ମଜାଳିଆ ଜିନିଷ ତିଆରି କରନ୍ତି |",
            "as": "মাজে মাজে ৱেল্ডিং শিক্ষাৰ্থীসকলেও পৰীক্ষাগাৰত থকা সময়ত, তেওঁলোকে কৰ্মশালাত পোৱা স্ক্ৰেপৰপৰা ধেমেলীয়া বস্তু প্ৰস্তুত কৰে।",
            "gu": "ક્યારેક ક્યારેક જ્યારે વેલ્ડીંગ વિભાગના વિદ્યાર્થીઓ પણ લેબમાં હોય છે, ત્યારે તેઓ ભેગા થઈને ભંગારથી રમુજી વસ્તુઓ બનાવે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Sometimes he makes some pieces with the scrap inspired by the hip hop dance moves.",
            "hi": "कभी-कभी वह ऐसे पुराने मटेरियल से हिप-हॉप डान्स मूव से प्रेरित कुछ चीजें भी बनाता है।",
            "ka": "ಕೆಲವೊಮ್ಮೆ ಅವನು ಹಿಪ್ ಹಾಪ್ ನೃತ್ಯ ಚಲನೆಗಳಿಂದ ಪ್ರೇರಿತವಾದ ಸ್ಕ್ರ್ಯಾಪ್ನೊಂದಿಗೆ ಕೆಲವು ತುಣುಕುಗಳನ್ನು ಮಾಡುತ್ತಾನೆ.",
            "te": "కొన్నిసార్లు అతను హిప్ హాప్ డ్యాన్స్ కదలికల నుండి స్పూర్తి పొంది స్క్రాప్‌తో కొన్ని ముక్కలు చేస్తాడు.",
            "or": "ବେଳେବେଳେ ସେ ହିପ୍ ହପ୍ ଡ୍ୟାନ୍ସ ମୁଭ୍ ଦ୍ୱାରା ଅନୁପ୍ରାଣିତ ସ୍କ୍ରାପ୍ ସହିତ କିଛି ଖଣ୍ଡ ତିଆରି କରନ୍ତି |",
            "as": "কেতিয়াবা সি হিপ হপ নৃত্যৰ দ্বাৰা অনুপ্ৰাণিত কিছু টুকুৰা স্ক্ৰেপৰপৰা তৈয়াৰ কৰে।",
            "gu": "તે હિપ હોપ ડાન્સ મૂવ્સથી પ્રેરિત સ્ક્રેપ સાથે કેટલાક ટુકડા બનાવે છે કેટલીકવાર."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_2_1",
            "hi": "C2_2_1",
            "ka": "C2_2_1",
            "te": "C2_2_1",
            "or": "C2_2_1",
            "as": "C2_2_1",
            "gu": "C2_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He also learns about the different jobs he can apply for after completing the course.",
            "hi": "उसे अलग-अलग जॉब के बारे में भी पता चलता है जहाँ वह कोर्स पूरा होने के बाद अप्लाई कर सकता है।",
            "ka": "ಕೋರ್ಸ್ ಮುಗಿದ ನಂತರ ಅವನು ಅರ್ಜಿ ಸಲ್ಲಿಸಬಹುದಾದ ವಿವಿಧ ಉದ್ಯೋಗಗಳ ಬಗ್ಗೆಯೂ ಕಲಿಯುತ್ತಾನೆ.",
            "te": "అతను కోర్సు పూర్తి చేసిన తర్వాత అతను దరఖాస్తు చేసుకోగల వివిధ ఉద్యోగాల గురించి కూడా తెలుసుకుంటాడు.",
            "or": "ସେ ପାଠ୍ୟକ୍ରମ ସମାପ୍ତ କରିବା ପରେ ସେ ଆବେଦନ କରିପାରୁଥିବା ବିଭିନ୍ନ ଚାକିରି ବିଷୟରେ ମଧ୍ୟ ଜାଣନ୍ତି |",
            "as": "পাঠ্যক্ৰমটো সম্পূৰ্ণ কৰাৰ পিছত সি আবেদন কৰিব পৰা বিভিন্ন চাকৰিৰ বিষয়েও শিকে।",
            "gu": "તે તેના વિશે પણ તે શીખે છે કોર્સ પૂરો કર્યા પછી જે કેટલીક નોકરીઓ માટે અરજી કરી શકે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_2_2",
            "hi": "C2_2_2",
            "ka": "C2_2_2",
            "te": "C2_2_2",
            "or": "C2_2_2",
            "as": "C2_2_2",
            "gu": "C2_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "They go for industry visits to see the process followed in the factories and also to get an understanding of the working environment.",
            "hi": "वे फैक्टरी में कैसे प्रक्रिया की जाती है यह देखने के लिए और काम के माहौल को समझने के लिए इंडस्ट्री में विजीट करते हैं।",
            "ka": "ಕಾರ್ಖಾನೆಗಳಲ್ಲಿ ಅನುಸರಿಸುವ ಪ್ರಕ್ರಿಯೆಯನ್ನು ನೋಡಲು ಮತ್ತು ಕೆಲಸದ ವಾತಾವರಣದ ಬಗ್ಗೆ ತಿಳುವಳಿಕೆಯನ್ನು ಪಡೆಯಲು ಅವರು ಉದ್ಯಮಕ್ಕೆ ಭೇಟಿ ನೀಡುತ್ತಾರೆ.",
            "te": "ఇండస్ట్రీలో(కర్మాగారాల్లో) అనుసరించే ప్రక్రియను చూడటానికి మరియు పని వాతావరణంపై అవగాహన పొందడానికి వారు పరిశ్రమ సందర్శనలకు వెళతారు.",
            "or": "କାରଖାନାଗୁଡ଼ିକରେ ଅନୁସରଣ କରାଯାଉଥିବା ପ୍ରକ୍ରିୟା ଏବଂ କାର୍ଯ୍ୟ ପରିବେଶ ବିଷୟରେ ମଧ୍ଯ ବୁଝିବାକୁ ସେମାନେ ଶିଳ୍ପ ପରିଦର୍ଶନ ପାଇଁ ଯାଆନ୍ତି |",
            "as": "তেওঁলোকে কাৰখানাবোৰে অনুসৰণ কৰা প্ৰক্ৰিয়াবোৰ চাবলৈ আৰু কামৰ পৰিৱেশবোৰ বুজি পাবলৈ উদ্যোগ ভ্ৰমণৰ বাবেও যায়।",
            "gu": "તે ઉદ્યોગની મુલાકાતે જાય છે જ્યાં ફેક્ટરીઓમાં કામ કરવામાં આવતી પ્રક્રિયા જોવા અને કાર્યકારી વાતાવરણની સમજ મેળવવા માટે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_3_1",
            "hi": "C2_3_1",
            "ka": "C2_3_1",
            "te": "C2_3_1",
            "or": "C2_3_1",
            "as": "C2_3_1",
            "gu": "C2_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "While doing his ITI course, he sometimes works in the evening to earn some pocket money. He takes his brother’s auto and does evening shifts.",
            "hi": "ITI कोर्स करते समय, कभी-कभी वह शाम को काम करके पॉकेट मनी भी कमाता है। वह शाम को अपने भाई की रिक्शा चलाता है।",
            "ka": "ತನ್ನ ಐಟಿಐ ಕೋರ್ಸ್ ಮಾಡುವಾಗ, ಅವನು ಕೆಲವೊಮ್ಮೆ ಪಾಕೆಟ್ ಮನಿ ಗಳಿಸಲು ಸಂಜೆ ಕೆಲಸ ಮಾಡುತ್ತಾನೆ. ಅವನು ತನ್ನ ಸಹೋದರನ ಆಟೋವನ್ನು ತೆಗೆದುಕೊಂಡು ಸಂಜೆ ಪಾಳಿಗಳನ್ನು ಮಾಡುತ್ತಾನೆ.",
            "te": "ITI కోర్సు చేస్తున్నప్పుడు, అతను కొన్నిసార్లు కొంత పాకెట్ మనీ సంపాదించడానికి సాయంత్రం పని చేస్తాడు. అతను తన సోదరుడి ఆటో తీసుకొని సాయంత్రం షిఫ్టులు చేస్తాడు.",
            "or": "ନିଜର ITI ପାଠ୍ୟକ୍ରମ କରିବାବେଳେ, ସେ ବେଳେବେଳେ ସନ୍ଧ୍ୟାରେ କିଛି କାମ କରି ପକେଟ୍‌ ମନି ରୋଜଗାର କରନ୍ତି | ସେ ନିଜ ଭାଇର ଅଟୋ ନେଇ ସନ୍ଧ୍ୟା ଶିଫ୍ଟ କରନ୍ତି |",
            "as": "ITI পাঠ্যক্ৰম কৰি থাকোঁতে সি কেতিয়াবা কিছু হাত খৰছ উলিয়াবলৈ সন্ধিয়াও কাম কৰে। সি তাৰ ভায়েকৰ অটোখন সন্ধিয়া চলাই দিয়ে।",
            "gu": "જ્યારે ITI કરી રહ્યો હતો ત્યારે તે કોઈક વખત પોતાના ખર્ચના પૈસા માટે સાંજે કામ કરે છે. તે તેના ભાઈની ગાડી ચાલાવવામાં લઈને સાંજની પાલી કરે છે"
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_3_2",
            "hi": "C2_3_2",
            "ka": "C2_3_2",
            "te": "C2_3_2",
            "or": "C2_3_2",
            "as": "C2_3_2",
            "gu": "C2_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He earns enough to buy a new phone for himself. He’s happy that he can earn even while studying.",
            "hi": "वह खुद के लिए नया फोन लेने के पैसे इकठ्ठा करता है। वह खुश है कि वह पढ़ाई करते-करते पैसे कमा सकता है।",
            "ka": "ತನಗಾಗಿ ಹೊಸ ಫೋನ್ ಖರೀದಿಸುವಷ್ಟು ಸಂಪಾದಿಸುತ್ತಾನೆ. ಓದುವಾಗಲೂ ಗಳಿಸಬಹುದು ಎಂಬ ಖುಷಿ ಅವನದು.",
            "te": "అతను తన కోసం ఒక కొత్త ఫోన్ కొనడానికి తగినంత సంపాదిస్తాడు. చదువుకుంటూ కూడా సంపాదించగలనని సంతోషిస్తున్నాడు.",
            "or": "ସେ ନିଜ ପାଇଁ ଏକ ନୂଆ ଫୋନ୍ କିଣିବା ପାଇଁ ଯଥେଷ୍ଟ ରୋଜଗାର କରନ୍ତି | ସେ ଖୁସି ଯେ ଅଧ୍ୟୟନ କରିବା ସମୟରେ ମଧ୍ୟ ସେ ରୋଜଗାର କରିପାରନ୍ତି |",
            "as": "সি নিজৰ বাবে এটা নতুন ফোন কিনিবলৈ পৰ্যাপ্ত উপাৰ্জন কৰে। পঢ়ি থাকোঁতেও উপাৰ্জন কৰিব পাৰি সি সুখী।",
            "gu": "તે પોતાનો ફોન લાવવા માટે પૂરતું કમાઈ લે છે. તે ખુશ છે ભણતા સમયે થોડાક પૈસા કમાઈ લે છે."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_3_3//",
            "hi": "C2_3_3//",
            "ka": "C2_3_3//",
            "te": "C2_3_3//",
            "or": "C2_3_3//",
            "as": "C2_3_3//",
            "gu": "C2_3_3//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "One evening, when Naveen returns with the auto, Roshan meets him outside their basti.",
            "hi": "एक दिन शाम को, जब नवीन रिक्शा चलाकर वापस आता है तो रोशन उसे बस्ती के बाहर ही मिलता है।",
            "ka": "ಒಂದು ಸಂಜೆ, ನವೀನ್ ಆಟೋದೊಂದಿಗೆ ಹಿಂದಿರುಗಿದಾಗ, ರೋಷನ್ ಬಸ್ತಿಯ ಹೊರಗೆ ಭೇಟಿಯಾಗುತ್ತಾನೆ.",
            "te": "ఒక సాయంత్రం, నవీన్ ఆటోతో తిరిగి వచ్చినప్పుడు, రోషన్ అతనిని వారి బస్తీ బయట కలుస్తాడు.",
            "or": "ଦିନେ ସନ୍ଧ୍ୟାରେ, ଯେତେବେଳେ ନବୀନ ଅଟୋ ସହିତ ଫେରନ୍ତି, ରୋଶନ ତାଙ୍କୁ ସେମାନଙ୍କ ବସ୍ତି ବାହାରେ ଭେଟନ୍ତି |",
            "as": "এদিন সন্ধিয়া, নবীনে অটোখন লৈ উভতি আহোতে, ৰোশনে তাক বস্তিৰ বাহিৰত লগ পায়।",
            "gu": "એક સાંજે જ્યારે નવીન તેની ગાડી લઈને પાછો આવે છે ત્યારે રોશન તેને વસાવતની બહાર મળે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Roshan",
            "hi": "रोशन",
            "ka": "ರೋಶನ್",
            "te": "రోషన్",
            "or": "ରୋଶନ",
            "as": "ৰোশন",
            "gu": "રોશન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Roshan",
            "hi": "रोशन",
            "ka": "ರೋಶನ್",
            "te": "రోషన్",
            "or": "ରୋଶନ",
            "as": "ৰোশন",
            "gu": "રોશન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Ah, good, you came back just in time.",
            "hi": "ओह, अच्छा हुआ, तुम सही समय पर आ गए।",
            "ka": "ಆಹ್, ಒಳ್ಳೆಯದು, ನೀನು ಸಮಯಕ್ಕೆ ಹಿಂತಿರುಗಿದ್ದೀಯ.",
            "te": "హా, బాగుంది, మీరు సమయానికి తిరిగి వచ్చారు.",
            "or": "ଆହା, ଭଲ, ତୁମେ ଠିକ୍ ସମୟରେ ଫେରି ଆସିଛ |",
            "as": "আহ, বঢ়িয়া, তুমি ঠিক সময়ত ঘূৰি আহিলা।",
            "gu": "ઓહ સરસ, તું ટાઈમમાં પાછો આવી ગયો."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Yes, I need to catch up on a lot of work, so I had to come back early.",
            "hi": "हाँ, मुझे थोड़ा काम पूरा करना था, इसलिए मैं जल्दी वापस आ गया।",
            "ka": "ಹೌದು, ನಾನು ಬಹಳಷ್ಟು ಕೆಲಸವನ್ನು ಹಿಡಿಯಬೇಕಾಗಿದೆ, ಹಾಗಾಗಿ ನಾನು ಬೇಗನೆ ಹಿಂತಿರುಗಬೇಕಾಗಿತ್ತು.",
            "te": "అవును, నేను చాలా పని చేయాలి, కాబట్టి నేను త్వరగా తిరిగి రావాల్సి వచ్చింది.",
            "or": "ହଁ, ମୋତେ ଅନେକ କାମ କରିବାକୁ ଅଛି ତେଣୁ ମୋତେ ଶୀଘ୍ର ଫେରିବାକୁ ପଡିଲା |",
            "as": "হয়, মোৰ বহুত কাম আছে, সেয়েহে মই সোনকালে ঘূৰি আহিলো।",
            "gu": "હા, મને ગણું કામ મળી શકે છે, તેથી માટે જલ્દી પાછા આવું પડે છે. "
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "I meant to ask you, now that your course is almost over, what have you been planning.",
            "hi": "मुझे तुमसे पूछना था, अब तुम्हारा कोर्स तकरीबन पूरा हो गया है, तो अब तुमने आगे क्या करने का सोचा है।",
            "ka": "ನಾನು ನಿಮ್ಮನ್ನು ಕೇಳಲು ಉದ್ದೇಶಿಸಿದೆ, ಈಗ ನಿಮ್ಮ ಕೋರ್ಸ್ ಬಹುತೇಕ ಮುಗಿದಿದೆ, ನೀವು ಏನು ಯೋಜಿಸುತ್ತಿದ್ದೀರಿ.",
            "te": "నేను నిన్ను ఒకటి అడగాలనుకుంటున్నాను, ఇప్పుడు నీ కోర్సు దాదాపుగా ముగిసింది, నువ్వు ఏమి ప్రణాళిక చేస్తున్నావు.",
            "or": "ମୁଁ ତୁମକୁ ପଚାରିବାକୁ ଚାହୁଁଥିଲି, ବର୍ତ୍ତମାନ ତୁମର ପାଠ୍ୟକ୍ରମ ପ୍ରାୟ ସମାପ୍ତ ହୋଇଛି, ତୁମେ କଣ ଯୋଜନା କରୁଛ |",
            "as": "মই তোমাক সুধিব বিচাৰিছিলো, এতিয়া তোমাৰ পাঠ্যক্ৰম প্ৰায় শেষ হ’ব হৈছে, তুমি কি পৰিকল্পনা কৰি আছা।",
            "gu": "હું તમને એ પૂછવા માગું છું કે હવે તારો કોર્સ લગભગ પૂરો થઈ ગયો છે, તમે આગળ શું કરવા જય રહ્યા છો."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.text",
          "value": {
            "en": "I really want to apply for a government job, but…",
            "hi": "मुझे सरकारी नौकरी के लिए अप्लाई करना है, लेकिन...",
            "ka": "ನಾನು ನಿಜವಾಗಿಯೂ ಸರ್ಕಾರಿ ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಬಯಸುತ್ತೇನೆ, ಆದರೆ...",
            "te": "నేను ప్రభుత్వ ఉద్యోగానికి దరఖాస్తు చేయాలనుకుంటున్నాను, కానీ…",
            "or": "ମୁଁ ପ୍ରକୃତରେ ଏକ ସରକାରୀ ଚାକିରି ପାଇଁ ଆବେଦନ କରିବାକୁ ଚାହୁଁଛି, କିନ୍ତୁ…",
            "as": "মই চৰকাৰী চাকৰিৰ বাবে আবেদন কৰিব বিচাৰো, কিন্তু...",
            "gu": "મારા મતલબ કે હું સરકારી નોકરી મારે અરજી કરવા માગું છે, પણ...."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_3_4////",
            "hi": "C2_3_4////",
            "ka": "C2_3_4////",
            "te": "C2_3_4////",
            "or": "C2_3_4////",
            "as": "C2_3_4////",
            "gu": "C2_3_4////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Government job? Haha, Naveen. People spend years trying to get these jobs. Do you have that kind of time to waste on this? Why don’t you take up something easier?",
            "hi": "सरकारी नौकरी? हाहा, नवीन। लोग कई साल प्रयास करते हैं सरकारी नौकरी पाने के लिए। क्या तुम इतने साल बरबाद कर सकते हो? कोई आसानी से मिलनेवाली नौकरी क्यों नहीं ढूंढ लेते?",
            "ka": "ಸರ್ಕಾರಿ ಕೆಲಸ? ಹ್ಹಾ, ನವೀನ್. ಈ ಉದ್ಯೋಗಗಳನ್ನು ಪಡೆಯಲು ಜನರು ವರ್ಷಗಳನ್ನು ಕಳೆಯುತ್ತಾರೆ. ಇದಕ್ಕಾಗಿ ವ್ಯರ್ಥ ಮಾಡಲು ನಿಮಗೆ ಅಂತಹ ಸಮಯವಿದೆಯೇ? ನೀವು ಏನನ್ನಾದರೂ ಸುಲಭವಾಗಿ ಏಕೆ ತೆಗೆದುಕೊಳ್ಳಬಾರದು?",
            "te": "ప్రభుత్వ ఉద్యోగమా? హహ, నవీన్. ప్రజలు ఈ ఉద్యోగాల కోసం సంవత్సరాల పాటు చూస్తున్నారు. దీని కోసం వృథా చేయడానికి మీకు అలాంటి సమయం ఉందా? నువ్వు ఏదైనా సులభంగా ఉండేది తీసుకోవచ్చు?",
            "or": "ସରକାରୀ ଚାକିରି ? ହାହା, ନବୀନ | ଲୋକମାନେ ଏହି ଚାକିରୀ ପାଇବାକୁ ବର୍ଷ ବର୍ଷ ଚେଷ୍ଟା କରନ୍ତି | ଏଥିରେ ତୁମର ସେହି ପ୍ରକାରର ସମୟ ନଷ୍ଟ କରିବାକୁ ଅଛି କି ? ତୁମେ କାହିଁକି କିଛି ସହଜ କରୁ ନାହଁ ?",
            "as": "চৰকাৰী চাকৰি? হাহা, নবীন। মানুহে এই চাকৰিবোৰ পাবলৈ বহু বছৰ বৰবাদ কৰে। ইয়াৰ ওপৰত ইমান সময় বৰবাদ কৰিবলৈ তোমাৰ সময় আছে নে? তুমি সহজ কিবা নোলোৱা কিয়?",
            "gu": "સરકારી નોકરી? હા હા, નવીન. લોકો વર્ષો વિતાવી દે છે સરકારી નોકરી માટે. શું તારી માટે એટલો સમય બગાડવા માટે છે આના માટે? કેમ તું કઈ અલગ નથી કરી લેતો?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "But Anna, where do I go? Most of these places pay their employees much less.",
            "hi": "लेकिन अन्ना, मैं क्या करूँगा? ऐसी ज्यादातर नौकरियों में कम सैलरी दी जाती है।",
            "ka": "ಆದರೆ ಅಣ್ಣಾ, ನಾನು ಎಲ್ಲಿಗೆ ಹೋಗಲಿ? ಈ ಸ್ಥಳಗಳಲ್ಲಿ ಹೆಚ್ಚಿನವು ತಮ್ಮ ಉದ್ಯೋಗಿಗಳಿಗೆ ಕಡಿಮೆ ವೇತನ ನೀಡುತ್ತವೆ.",
            "te": "కానీ అన్నా, నేను ఎక్కడికి వెళ్ళాలి? ఈ ప్రదేశాలలో చాలా వరకు తమ ఉద్యోగులకు చాలా తక్కువ జీతం ఇస్తున్నాయి.",
            "or": "କିନ୍ତୁ ଆନ୍ନା, ମୁଁ କୁଆଡେ ଯିବି ? ଏହି ସ୍ଥାନଗୁଡିକ ମଧ୍ୟରୁ ଅଧିକାଂଶ ସେମାନଙ୍କ କର୍ମଚାରୀମାନଙ୍କୁ ବହୁତ କମ୍ ଦରମା ଦିଅନ୍ତି |",
            "as": "কিন্তু ভাই, মই ক'ত যাম? এই ঠাইবোৰৰ বেছিভাগেই তেওঁলোকৰ কৰ্মচাৰীক বহুত কম দৰমহা দিয়ে।",
            "gu": "પણ ભાઈ, હું ક્યાં જાઉં? આમાં ગણી બધી કંપનીઓ બહુજ ઓછું વેતન આપે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "You will have your ITI degree in hand. People don’t take that for granted. You bring quality. They will pay.",
            "hi": "तुम्हारे पास ITI की डिग्री होगी। लोग इसे अनदेखा नहीं कर सकते। तुम उन्हें अच्छी क्वालिटी का काम करोगे। तो वे तुम्हें पैसे देंगे।",
            "ka": "ನಿನ್ನ ಕೈಯಲ್ಲಿ ನಿನ್ನ ಐಟಿಐ ಪದವಿ ಇರುತ್ತದೆ. ಜನರು ಅದನ್ನು ಲಘುವಾಗಿ ತೆಗೆದುಕೊಳ್ಳುವುದಿಲ್ಲ. ನೀನು ಗುಣಮಟ್ಟವನ್ನು ತರುತ್ತೀಯ. ಅವರು ಪಾವತಿಸುತ್ತಾರೆ.",
            "te": "మీ చేతిలో మీ ITI డిగ్రీ ఉంటుంది. ప్రజలు దీనిని పెద్దగా పట్టించుకోరు. మీరు నాణ్యతను తీసుకురండి. వారు చెల్లిస్తారు.",
            "or": "ତୁମର ITI ଡିଗ୍ରୀ ତୁମ ହାତରେ ଥିବ | ଲୋକମାନେ ତାକୁ ମାନ୍ୟତା ଦେବେ ନାହିଁ | ତୁମେ ଗୁଣବତ୍ତା ଆଣ | ସେମାନେ ଦେୟ ଦେବେ |",
            "as": "তোমাৰ হাতত ITI ডিগ্ৰী থাকিব। মানুহে সেইটো সহজ ভাৱে নলয়। তোমাৰ মানদণ্ড আছে। তেওঁলোকে পৰিশোধ কৰিবই।",
            "gu": "તારી પાસે ITI ની ડિગ્રી હશે. લોકો અનુદાન નથી કરતાં તેના માટે. તે ચૂકવશે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "All my friends are applying for government jobs. I’m still going to try my luck.",
            "hi": "मेरे सारे दोस्त सरकारी नौकरी के लिए अप्लाई कर रहे हैं। मैं भी अपना किस्मत आजमाना चाहता हूँ।",
            "ka": "ನನ್ನ ಸ್ನೇಹಿತರೆಲ್ಲರೂ ಸರ್ಕಾರಿ ಉದ್ಯೋಗಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಿದ್ದಾರೆ. ನಾನು ಇನ್ನೂ ನನ್ನ ಅದೃಷ್ಟವನ್ನು ಪ್ರಯತ್ನಿಸಲಿದ್ದೇನೆ.",
            "te": "నా స్నేహితులందరూ ప్రభుత్వ ఉద్యోగాలకు దరఖాస్తు చేస్తున్నారు. నేను ఇంకా నా అదృష్టాన్ని పరీక్షించుకోబోతున్నాను.",
            "or": "ମୋର ସମସ୍ତ ସାଙ୍ଗମାନେ ସରକାରୀ ଚାକିରି ପାଇଁ ଆବେଦନ କରୁଛନ୍ତି | ମୁଁ ତଥାପି ମୋର ଭାଗ୍ୟ ପରୀକ୍ଷା କରିବାକୁ ଯାଉଛି |",
            "as": "মোৰ সকলো বন্ধুৱে চৰকাৰী চাকৰিৰ বাবে আবেদন কৰিছে। মইও মোৰ ভাগ্য পৰীক্ষা কৰি চাওঁ।",
            "gu": "મારા બધા જ મિત્રો સરકારી નોકરી માટે અરજી કરે છે. હું પણ મારૂ નસીબ અપનાવા માગું છું."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "It is unlikely for you to get a job there. Instead you go meet Omi's father. He’s the best mechanic on this side of town. I always go to him for any repair work. He’s a first-class professional. He will advice you on how to apply for jobs elsewhere.",
            "hi": "तुम्हें यह जॉब मिलना काफी मुश्किल है। इसके बजाय तुम ओमी के पिता के पास जाओ। वह इस शहर के सबसे अच्छे मैकेनिक हैं। कुछ रिपेअर करना होता है तो मैं उन्ही के पास जाता हूँ। वे फर्स्ट-क्लास प्रोफेशनल हैं। वे तुम्हें कहीं और अप्लाई कैसे करते हैं इसके बारे में सलाह दे सकते हैं।",
            "ka": "ನಿನಗೆ ಅಲ್ಲಿ ಕೆಲಸ ಸಿಗುವುದು ಅಸಂಭವ. ಬದಲಾಗಿ ನೀನು ಓಮಿಯ ತಂದೆಯನ್ನು ಭೇಟಿಯಾಗು. ಅವರು ಪಟ್ಟಣದ ಈ ಬದಿಯಲ್ಲಿ ಅತ್ಯುತ್ತಮ ಮೆಕ್ಯಾನಿಕ್. ಯಾವುದೇ ರಿಪೇರಿ ಕೆಲಸಕ್ಕಾಗಿ ನಾನು ಯಾವಾಗಲೂ ಅವನ ಬಳಿಗೆ ಹೋಗುತ್ತೇನೆ. ಅವನು ಪ್ರಥಮ ದರ್ಜೆ ವೃತ್ತಿಪರರು. ಬೇರೆಡೆ ಉದ್ಯೋಗಗಳಿಗೆ ಹೇಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಬೇಕು ಎಂಬುದರ ಕುರಿತು ಅವರು ನಿನಗೆ ಸಲಹೆ ನೀಡುತ್ತಾರೆ.",
            "te": "అక్కడ నీకు ఉద్యోగం వచ్చే అవకాశం లేదు. బదులుగా మీరు ఓమి తండ్రిని కలవండి. అతను పట్టణానికి ఇటువైపు అత్యుత్తమ మెకానిక్. నేను ఎప్పుడూ ఏదైనా రిపేర్ పని కోసం అతని దగ్గరకు వెళ్తాను. అతను ఫస్ట్ క్లాస్ ప్రొఫెషనల్. వేరే చోట ఉద్యోగాల కోసం ఎలా దరఖాస్తు చేసుకోవాలో అతను మీకు సలహా ఇస్తాడు.",
            "or": "ସେଠାରେ ଚାକିରି ପାଇବା ତୁମ ପାଇଁ ସମ୍ଭବ ନୁହେଁ | ଏହା ବଦଳରେ ତୁମେ ଓମିଙ୍କ ପିତାଙ୍କୁ ଭେଟିବାକୁ ଯାଅ | ସହରର ଏହି ପାର୍ଶ୍ୱରେ ସେ ହେଉଛନ୍ତି ସର୍ବୋତ୍ତମ ମେକାନିକ୍ | କୌଣସି ମରାମତି କାମ ପାଇଁ ମୁଁ ସବୁବେଳେ ତାଙ୍କ ପାଖକୁ ଯାଏ | ସେ ଜଣେ ପ୍ରଥମ ଶ୍ରେଣୀର ପ୍ରୋଫେସ୍‍ନାଲ୍‌ | ଅନ୍ୟତ୍ର ଚାକିରି ପାଇଁ କିପରି ଆବେଦନ କରିବ‍ ସେ ବିଷୟରେ ସେ ତୁମକୁ ପରାମର୍ଶ ଦେବେ |",
            "as": "তুমি তাত চাকৰি পোৱাৰ সম্ভাৱনা কম। তাতকে তুমি অমিৰ দেউতাকক লগ ধৰা। তেওঁ চহৰৰ এই ফালে সৰ্বশ্ৰেষ্ঠ মেকানিক। মই সদায় যিকোনো মেৰামতিৰ বাবে তেওঁৰ ওচৰলৈ যাওঁ। তেওঁ এজন প্ৰথম শ্ৰেণীৰ পেছাদাৰী মানুহ। তেওঁ তোমাক বেলেগ ঠাইত চাকৰিৰ বাবে কেনেদৰে আবেদন কৰিব লাগে সেই বিষয়ে পৰামৰ্শ দিব পাৰিব।",
            "gu": "તને ત્યાં નોકરી મળવાની કઠિન છે. તેના બદલે તું ઓમીના પિતાને મળી લે. તેઓ આ બાજુનો શ્રેષ્ઠ મિકેનિક છે શહેરમાં. હું કોઈપણ સમારકામ માટે જઉં છું હંમેશા તેમની પાસે. તે ફર્સ્ટ ક્લાસ પ્રોફેશનલ છે. તે તને બીજી જગ્યાએ નોકરી માટે કેવી રીતે અરજી કરવી તે અંગે સલાહ સૂચન આપશે."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_3_5_D",
            "hi": "C2_3_5_D",
            "ka": "C2_3_5_D",
            "te": "C2_3_5_D",
            "or": "C2_3_5_D",
            "as": "C2_3_5_D",
            "gu": "C2_3_5_D"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Naveen do?",
            "hi": "नवीन को क्या करना चाहिए?",
            "ka": "ನವೀನ್ ಏನು ಮಾಡಬೇಕು?",
            "te": "నవీన్ ఏం చేయాలి?",
            "or": "ନବୀନ କଣ କରିବା ଉଚିତ୍ ?",
            "as": "নবীনে কি কৰা উচিত?",
            "gu": "નવીને શું કરવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Apply for government jobs",
            "hi": "सरकारी नौकरी के लिए अप्लाई करना",
            "ka": "ಸರ್ಕಾರಿ ಉದ್ಯೋಗಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ",
            "te": "ప్రభుత్వ ఉద్యోగాల కోసం దరఖాస్తు చేసుకోవాలి",
            "or": "ସରକାରୀ ଚାକିରି ପାଇଁ ଆବେଦନ କରନ୍ତୁ",
            "as": "চৰকাৰী চাকৰিৰ বাবে আবেদন",
            "gu": "સરકારી નોકરી માટે અરજી કરવી જોઈએ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Go to Omi’s father to learn more from him",
            "hi": "ओमी के पिता के पास जाकर उनसे जानकारी प्राप्त करना।",
            "ka": "ಅವನಿಂದ ಇನ್ನಷ್ಟು ಕಲಿಯಲು ಓಮಿಯ ತಂದೆಯ ಬಳಿಗೆ ಹೋಗಿ",
            "te": "అతని నుండి మరింత తెలుసుకోవడానికి ఓమి తండ్రి వద్దకు వెళ్ళాలి",
            "or": "ତାଙ୍କଠାରୁ ଅଧିକ ଜାଣିବା ପାଇଁ ଓମିଙ୍କ ପିତାଙ୍କ ପାଖକୁ ଯାଆନ୍ତୁ",
            "as": "অধিক জানিবলৈ অমিৰ দেউতাকৰ ওচৰলৈ যাব",
            "gu": "ઓમનીના પિતા જોડે જાઉં જોઈએ વધારે માહિતી માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.tag",
          "value": {
            "en": "determination but uncertainty",
            "hi": "दृढ़ संकल्प लेकिन अनिश्चितता",
            "ka": "ನಿರ್ಣಯ ಆದರೆ ಅನಿಶ್ಚಿತತೆ",
            "te": "సంకల్పం కానీ అయోమయం",
            "or": "ନିଷ୍ଠା କିନ୍ତୁ ଅନିଶ୍ଚିତତା",
            "as": "সংকল্প কিন্তু অনিশ্চয়তা",
            "gu": "નિશ્ચય પરંતુ ચંચળતા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.tag",
          "value": {
            "en": "exploration with certainty",
            "hi": "निश्चित होकर एक्सप्लोर करना",
            "ka": "ಖಚಿತವಾಗಿ ಪರಿಶೋಧನೆ",
            "te": "నిశ్చయతతో అన్వేషణ",
            "or": "ନିଶ୍ଚିତତା ସହିତ ଏକ୍ସପ୍ଲୋରେସନ୍‍",
            "as": "নিশ্চিতভাৱে অন্বেষণ",
            "gu": "નિશ્ચિતતા સાથે સંશોધન"
          }
        }
      ]
    },
    "scene15": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C3_1_1_Go to Omi's father",
            "hi": "ओमी के पिता के पास जाना",
            "ka": "C3_1_1_ಓಮಿ ತಂದೆಯ ಬಳಿಗೆ ಹೋಗು",
            "te": "C3_1_1_ఓమి తండ్రి వద్దకు వెళ్ళాలి",
            "or": "C3_1_1_Go to Omi's father",
            "as": "C3_1_1_অমিৰ দেউতাকৰ ওচৰলৈ যাব",
            "gu": "C3_1_1_ઓમનીના પિતા જોડે જાઉં જોઈએ"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen decides to give up on applying for a government job given that its unlikely for him to get one.",
            "hi": "नवीन सरकारी नौकरी के लिए अप्लाई करने का विचार छोड़ देता है क्योंकि यह मिलने की संभावना कम है।",
            "ka": "ನವೀನ್ ಸರ್ಕಾರಿ ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದನ್ನು ಬಿಟ್ಟುಬಿಡಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ, ಏಕೆಂದರೆ ಅದು ಅವನಿಗೆ ಸಿಗುವ ಸಾಧ್ಯತೆಯಿಲ್ಲ.",
            "te": "నవీన్ ప్రభుత్వ ఉద్యోగం పొందే అవకాశం లేనందున దాని కోసం దరఖాస్తు చేయకూడదని నిర్ణయించుకున్నాడు.",
            "or": "ନବୀନ ଏକ ସରକାରୀ ଚାକିରି ପାଇଁ ଆବେଦନ କରିବା ଛାଡ଼ିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି କାରଣ ତାଙ୍କ ପାଇଁ ଗୋଟିଏ ପାଇବା ସମ୍ଭବ ନୁହେଁ |",
            "as": "নবীনে চৰকাৰী চাকৰিৰ আশা এৰি দিয়াৰ সিদ্ধান্ত লয় যিহেতু তাৰ বাবে এইটো পোৱাৰ সম্ভাৱনা কম।",
            "gu": "નવીન સરકારી નોકરી કરવાનું નકારે છે કારના કે તે એને મળે એમ નથી."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C3_1_2",
            "hi": "C3_1_2",
            "ka": "C3_1_2",
            "te": "C3_1_2",
            "or": "C3_1_2",
            "as": "C3_1_2",
            "gu": "C3_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He follows Roshan’s advice and approaches  Omi’s father, an experienced mechanic working in a local electronics shop.",
            "hi": "वह रोशन की सलाह मानकर ओमी के पिता के पास जाता है, जो स्थानिक इलेक्ट्रॉनिक दूकान में काम करनेवाले अनुभवी मैकेनिक हैं।",
            "ka": "ಅವನು ರೋಶನ್ ಅವರ ಸಲಹೆಯನ್ನು ಅನುಸರಿಸುತ್ತಾನೆ ಮತ್ತು ಸ್ಥಳೀಯ ಎಲೆಕ್ಟ್ರಾನಿಕ್ಸ್ ಅಂಗಡಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಅನುಭವಿ ಮೆಕ್ಯಾನಿಕ್ ಓಮಿಯ ತಂದೆಯನ್ನು ಸಂಪರ್ಕಿಸುತ್ತಾನೆ.",
            "te": "అతను రోషన్ సలహాను అనుసరిస్తాడు మరియు స్థానిక ఎలక్ట్రానిక్స్ దుకాణంలో పనిచేసే అనుభవజ్ఞుడైన మెకానిక్ అయిన ఓమి తండ్రిని సంప్రదించాడు.",
            "or": "ସେ ରୋଶନଙ୍କ ପରାମର୍ଶକୁ ଅନୁସରଣ କରନ୍ତି ଏବଂ ସ୍ଥାନୀୟ ଇଲେକ୍ଟ୍ରୋନିକ୍ସ ଦୋକାନରେ କାମ କରୁଥିବା ଓମିଙ୍କ ବାପା, ଏକ ଅଭିଜ୍ଞ ମେକାନିକ୍‍ ଙ୍କ ନିକଟକୁ ଯାଆନ୍ତି |",
            "as": "সি ৰোশনৰ কথা মতে অমিৰ দেউতাকৰ ওচৰলৈ যায় যি স্থানীয় ইলেক্ট্ৰনিক্স দোকান এখনৰ এজন অভিজ্ঞ মেকানিক ।",
            "gu": "તે રોશનની સલાહ લઈને અનુસરે છે અને ઓમીના પિતા સાથે જોડાય છે, જે એક અનુભવી મિકેનિક છે જેઓ સ્થાનિક ઇલેક્ટ્રોનિક્સ સ્ટોરમાં કામ કરે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "C3_1_3",
            "hi": "C3_1_3",
            "ka": "C3_1_3",
            "te": "C3_1_3",
            "or": "C3_1_3",
            "as": "C3_1_3",
            "gu": "C3_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Omi’s father offers Naveen an opportunity to shadow him for a week to see if he likes the work. Naveen gladly accepts the offer.",
            "hi": "ओमी के पिता नवीन को एक हफ्ते उनके साथ जॉब शैडो का मौका देते हैं ताकि उसे पता चले कि यह काम उसे पसंद है या नहीं। वह खुशी से यह ऑफर स्वीकार करता है।",
            "ka": "ಓಮಿಯ ತಂದೆ ನವೀನ್‌ಗೆ ಕೆಲಸವನ್ನು ಇಷ್ಟಪಡುತ್ತಾರೆಯೇ ಎಂದು ನೋಡಲು ಒಂದು ವಾರದವರೆಗೆ ಅವನಿಗೆ ನೆರಳು ನೀಡುವ ಅವಕಾಶವನ್ನು ನೀಡುತ್ತಾರೆ. ನವೀನ್ ಈ ಪ್ರಸ್ತಾಪವನ್ನು ಸಂತೋಷದಿಂದ ಸ್ವೀಕರಿಸುತ್ತಾನೆ.",
            "te": "ఓమి తండ్రి నవీన్‌కు పని నచ్చిందో లేదో చూడటానికి అతనిని ఒక వారం పాటు దగ్గర గా గమనిస్తూ ఉంటాడు . నవీన్ ఈ ఆఫర్‌ని సంతోషంగా అంగీకరించాడు.",
            "or": "ଓମିଙ୍କ ପିତା, ସେ କାମକୁ ପସନ୍ଦ କରନ୍ତି କି ନାହିଁ ଦେଖିବା ପାଇଁ ନବୀନ ଙ୍କୁ ଛାଇ ଭାବରେ ଏକ ସପ୍ତାହ ପାଇଁ ସୁଯୋଗ ଦିଅନ୍ତି | ନବୀନ ଆନନ୍ଦରେ ଏହି ଅଫର୍‌କୁ ଗ୍ରହଣ କରନ୍ତି |",
            "as": "অমিৰ দেউতাকে নবীনক কামটো সি কেনে পায় চাবলৈ এসপ্তাহৰ বাবে লগত লাগি দিয়াৰ সুযোগ দিয়ে। নবীনে আনন্দেৰে প্ৰস্তাৱটো গ্ৰহণ কৰিলে।",
            "gu": "ઓમીના પિતા નવીનને એક અઠવાડિયા માટે તક છે જોવા માગે છે કે તે કામ બરાબર કરશે કે નહીં. નવીન ખુશીથી તક સ્વીકારે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C3_2_1",
            "hi": "C3_2_1",
            "ka": "C3_2_1",
            "te": "C3_2_1",
            "or": "C3_2_1",
            "as": "C3_2_1",
            "gu": "C3_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Over the next week, Naveen observes how Omi’s father performs his essential duties like identifying the problems in machines, getting the price of parts, and quoting labour charges to the customer.",
            "hi": "अगले हफ्ते, नवीन ओमी के पिता का महत्वपूर्ण काम करते हुए निरिक्षण करता है जैसे कि, मशीन में क्या प्रॉब्लम है यह पता लगाना, पार्ट्स की कीमत पता करना और ग्राहकों को मजदूरी कितनी होगी यह बताना।",
            "ka": "ಮುಂದಿನ ವಾರದಲ್ಲಿ, ಓಮಿಯ ತಂದೆ ಯಂತ್ರಗಳಲ್ಲಿನ ಸಮಸ್ಯೆಗಳನ್ನು ಗುರುತಿಸುವುದು, ಬಿಡಿಭಾಗಗಳ ಬೆಲೆಯನ್ನು ಪಡೆಯುವುದು ಮತ್ತು ಗ್ರಾಹಕರಿಗೆ ಕಾರ್ಮಿಕ ಶುಲ್ಕವನ್ನು ಉಲ್ಲೇಖಿಸುವುದು ಮುಂತಾದ ತನ್ನ ಅಗತ್ಯ ಕರ್ತವ್ಯಗಳನ್ನು ಹೇಗೆ ನಿರ್ವಹಿಸುತ್ತಾನೆ ಎಂಬುದನ್ನು ನವೀನ್ ಗಮನಿಸುತ್ತಾನೆ.",
            "te": "తర్వాతి వారంలో, ఓమి తండ్రి మెషీన్లలోని సమస్యలను గుర్తించడం, పార్ట్స్ ధరను పొందడం మరియు కస్టమర్‌కు లేబర్ ఛార్జీలను కోట్ చేయడం వంటి తన ముఖ్యమైన విధులను ఎలా చేస్తాడో నవీన్ గమనిస్తాడు.",
            "or": "ଆଗାମୀ ସପ୍ତାହରେ, ନବୀନ ନଜର ରଖନ୍ତି ଯେ ଓମିଙ୍କ ପିତା କିପରି ଯନ୍ତ୍ରରେ ସମସ୍ୟା ଚିହ୍ନଟ କରିବା, ଅଂଶର ମୂଲ୍ୟ ପାଇବା ଏବଂ ଗ୍ରାହକଙ୍କୁ ଶ୍ରମ ଶୁଳ୍କ ଉଦ୍ଧୃତ କରିବା ଭଳି ଜରୁରୀ କାର୍ଯ୍ୟ କରନ୍ତି |",
            "as": "পৰৱৰ্তী সপ্তাহত, নবীনে লক্ষ্য কৰে যে কেনেদৰে অমিৰ দেউতাকে যন্ত্ৰৰ সমস্যাবোৰ চিনাক্ত কৰা, অংশবোৰৰ মূল্য প্ৰাপ্ত কৰা, আৰু গ্ৰাহকক শ্ৰমিক মাচুল উদ্ধৃত কৰাৰ দৰে তেওঁৰ প্ৰয়োজনীয় কৰ্তব্য পালন কৰে।",
            "gu": "એક અઠવાડીયામાં, નવીન દેખે છે ઓમનીના પિતા કેવી રીતે કામને સંભાળે છે જેમ કે મશીનમાં શું ખામી છે, વસ્તુ ની કિમ્મત, ગ્રાહકોને મજૂરીના ખર્ચનો ઉલ્લેખ કેવી રીતે કરવો."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "C3_2_2//",
            "hi": "C3_2_2//",
            "ka": "C3_2_2//",
            "te": "C3_2_2//",
            "or": "C3_2_2//",
            "as": "C3_2_2//",
            "gu": "C3_2_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "While observing, he also gets the opportunity to ask questions.",
            "hi": "निरिक्षण करते हुए, उसे प्रश्न पूछने का भी मौका मिलता है।",
            "ka": "ಗಮನಿಸುತ್ತಲೇ ಪ್ರಶ್ನೆಗಳನ್ನು ಕೇಳುವ ಅವಕಾಶವೂ ಸಿಗುತ್ತದೆ.",
            "te": "గమనిస్తూనే ప్రశ్నలు అడిగే అవకాశం కూడా కలుగుతుంది.",
            "or": "ନଜର ରଖିବାବେଳେ, ସେ ପ୍ରଶ୍ନ ପଚାରିବାର ସୁଯୋଗ ମଧ୍ୟ ପାଇଥାନ୍ତି |",
            "as": "পৰ্যবেক্ষণ কৰোঁতে, সি প্ৰশ্ন সোধাৰ সুযোগও পায়।",
            "gu": "તે બધુ જોતી વખતે, એને તક પણ મળે છે કોઈ પ્રશ્નો પૂછવાનો."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Omi's Father",
            "hi": "ओमी के पिता",
            "ka": "ಓಮಿ ತಂದೆ",
            "te": "ఓమి తండ్రి",
            "or": "ଓମିଙ୍କ ପିତା",
            "as": "অমিৰ দেউতাক",
            "gu": "ઓમીના પિતા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "How much do we get for a job like this, after removing our expenses?",
            "hi": "इस तरह के जॉब के लिए अपना खर्च निकालने के बाद हमें कितने पैसे मिल सकते हैं?",
            "ka": "ನಮ್ಮ ಖರ್ಚುಗಳನ್ನು ತೆಗೆದ ನಂತರ ನಾವು ಅಂತಹ ಕೆಲಸಕ್ಕೆ ಎಷ್ಟು ಪಡೆಯುತ್ತೇವೆ?",
            "te": "మన ఖర్చులు తీసివేస్తే, ఇలాంటి పనికి మనకు ఎంత వస్తుంది?",
            "or": "ଆମର ଖର୍ଚ୍ଚ ହଟାଇବା ପରେ, ଏହି ପରି ଚାକିରି ପାଇଁ ଆମେ କେତେ ପାଇବୁ?",
            "as": "আমাৰ খৰচবোৰ বাদ দিয়াৰ পিছত আমি এনে ধৰণৰ কাম এটাৰ বাবে কিমান টকা পাম?",
            "gu": "આપણે કેટલા પૈસા કમાઈ શકીએ બધા ખર્ચા કાઢતા?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "For this? Anywhere between 1200- 1500",
            "hi": "इसके लिए? तकरीबन 1200- 1500",
            "ka": "ಇದಕ್ಕಾಗಿ? 1200- 1500 ನಡುವೆ ಎಲ್ಲಿಯಾದರೂ",
            "te": "దీనికి? 1200-1500 మధ్య ఎంతైనా రావచ్చు",
            "or": "ଏଥି ପାଇଁ ? କିଛି 1200- 1500 ମଧ୍ୟରେ",
            "as": "ইয়াৰ বাবে? ১২০০-১৫০০-ৰ ভিতৰত পাম",
            "gu": "આના માટે? લગભગ 1200-1500"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "Oh wow, that’s a good price!",
            "hi": "अरे वा, यह तो अच्छी कीमत हैं!",
            "ka": "ಓಹ್, ಇದು ಉತ್ತಮ ಬೆಲೆ!",
            "te": "ఓహ్, ఇది మంచి ధర!",
            "or": "ଓହୋ, ତାହା ଏକ ଭଲ ମୂଲ୍ୟ !",
            "as": "অহ ৱাও, ভাল টকা দেখোন!",
            "gu": "ખૂબ સરસ, તે સારી કિમ્મત છે!"
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "C3_2_3////",
            "hi": "C3_2_3////",
            "ka": "C3_2_3////",
            "te": "C3_2_3////",
            "or": "C3_2_3////",
            "as": "C3_2_3////",
            "gu": "C3_2_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "The customers don’t bargain?",
            "hi": "ग्राहक मोल तोल नहीं करतें?",
            "ka": "ಗ್ರಾಹಕರು ಚೌಕಾಸಿ ಮಾಡುವುದಿಲ್ಲವೇ?",
            "te": "కొనుగోలుదారులు బేరం చేయరా?",
            "or": "ଗ୍ରାହକମାନେ ମୁଲଚାଲ କରନ୍ତି ନାହିଁ କି?",
            "as": "গ্ৰাহকবোৰে দৰদাম নকৰে নেকি?",
            "gu": "ગ્રાહકો ભાવતાલ નથી કરતાં?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "They do. Customers bargain a lot these days over the price. There is only a 50/50 chance of getting the job.",
            "hi": "करते हैं। आजकल ग्राहक कीमतों के लेकर बहुत मोलतोल करते हैं। काम मिलने का बस 50/50 चान्स होता है।",
            "ka": "ಅವರು ಮಾಡುತ್ತಾರೆ. ಈ ದಿನಗಳಲ್ಲಿ ಗ್ರಾಹಕರು ಬೆಲೆಯ ಮೇಲೆ ಸಾಕಷ್ಟು ಚೌಕಾಶಿ ಮಾಡುತ್ತಾರೆ. 50/50 ಮಾತ್ರ ಕೆಲಸ ಸಿಗುವ ಅವಕಾಶವಿದೆ.",
            "te": "వారు చేస్తారు. ఈ రోజుల్లో ధరపై కస్టమర్లు చాలా బేరం చేస్తున్నారు. ఉద్యోగం పొందడానికి 50/50 మాత్రమే అవకాశం ఉంది.",
            "or": "ସେମାନେ କରନ୍ତି | ଗ୍ରାହକମାନେ ମୂଲ୍ୟ ଉପରେ ଆଜିକାଲି ବହୁତ ମୁଲଚାଲ କରନ୍ତି | ଚାକିରି ପାଇବା ପାଇଁ କେବଳ 50/50 ସୁଯୋଗ ଥାଏ |",
            "as": "কৰে। গ্ৰাহকবোৰে আজিকালি দামৰ ওপৰত বহুত দৰদাম কৰে। কামটো পোৱাৰ মাত্ৰ ৫০/৫০ সম্ভাৱনা থাকে।",
            "gu": "તે કરે છે. અત્યારના દિવશોમાં ગ્રાહકો ખૂબ ભાવતાલ કરે છે પૈસા માટે. પણ અહિયાં 50/50 શક્યતા છે નોકરી મળવામાં."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Hmm",
            "hi": "हम्म",
            "ka": "ಹಾಂ",
            "te": "అవును",
            "or": "ହଁ",
            "as": "হুমম",
            "gu": "બરાબર"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "That’s how it is here. You turn up every day without knowing if the day will offer a profit or a loss.",
            "hi": "यहाँ ऐसे काम होता है। आप हमेशा काम पर आते हो पर आपको पता नहीं होता कि आज आपको फायदा होगा या नुकसान।",
            "ka": "ಅದು ಇಲ್ಲಿ ಹೇಗಿದೆ. ದಿನವು ಲಾಭ ಅಥವಾ ನಷ್ಟವನ್ನು ನೀಡುತ್ತದೆಯೇ ಎಂದು ತಿಳಿಯದೆ ನೀವು ಪ್ರತಿದಿನ ತಿರುಗುತ್ತೀರಿ.",
            "te": "అది ఇక్కడ ఇలా ఉంటుంది. ఆ రోజు లాభమో నష్టమో తెలియకుండానే మీరు ప్రతిరోజూ తిరుగుతారు.",
            "or": "ତାହା ଏଠାରେ ଏହିପରି ଅଛି | ଦିନଟି ଲାଭ କିମ୍ବା କ୍ଷତି ଦେବ ନଜାଣି ଆପଣ ପ୍ରତିଦିନ ଟର୍ନ୍ ଅପ୍ କରନ୍ତି |",
            "as": "ইয়াত এনেধৰণৰেই আৰু। দিনটোত লাভ বা লোকচান কি হ’ব নজনাকৈয়ে তুমি সদায় আহিব লাগিব।",
            "gu": "તે અહીં તેવી રીતે છે. અહી દરરોજ નફો કે નુકસાનની થાય છે કે તે જાણ્યા વિના દરરોજ ધંધો કરવો પડે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "Ohhh",
            "hi": "ओह",
            "ka": "ಓಹ್",
            "te": "అవునా",
            "or": "ଓହ",
            "as": "অহ",
            "gu": "ઓહ"
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "C3_2_4",
            "hi": "C3_2_4",
            "ka": "C3_2_4",
            "te": "C3_2_4",
            "or": "C3_2_4",
            "as": "C3_2_4",
            "gu": "C3_2_4"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Omi's Father",
            "hi": "ओमी के पिता",
            "ka": "ಓಮಿ ತಂದೆ",
            "te": "ఓమి తండ్రి",
            "or": "ଓମିଙ୍କ ପିତା",
            "as": "অমিৰ দেউতাক",
            "gu": "ઓમીના પિતા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Why don’t you pass me that wire cutter?",
            "hi": "क्या तुम मुझे वह वायर कटर दोगे?",
            "ka": "ಆ ವೈರ್ ಕಟರ್ ಅನ್ನು ನೀನು ನನಗೆ ಏಕೆ ರವಾನಿಸಬಾರದು?",
            "te": "ఆ వైర్ కట్టర్‌ని మీరు నాకు ఇస్తారా?",
            "or": "ତୁମେ ମୋତେ ସେହି ତାର କଟର କାହିଁକି ଦେଉନାହଁ?",
            "as": "সেই তাঁৰ কাটাৰটো দিয়াছোন?",
            "gu": "કેમ તું મને વાયર કટર આપતો નથી?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Sure.",
            "hi": "जरुर।",
            "ka": "ಖಂಡಿತ.",
            "te": "తప్పకుండా.",
            "or": "ନିଶ୍ଚିତ",
            "as": "নিশ্চয়।",
            "gu": "કેમ નહીં."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "C3_2_5",
            "hi": "C3_2_5",
            "ka": "C3_2_5",
            "te": "C3_2_5",
            "or": "C3_2_5",
            "as": "C3_2_5",
            "gu": "C3_2_5"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The job shadowing experience has helped him understand the many nuances of working as a mechanic and interacting with customers.",
            "hi": "जॉब शैडो करने के अनुभव ने उसे मैकेनिक के तौर पर काम करने और ग्राहकों के साथ सौदेबाजी करने की बारीकियों को समझने में मदद मिली।",
            "ka": "ಕೆಲಸದ ನೆರಳು ಅನುಭವವು ಮೆಕ್ಯಾನಿಕ್ ಆಗಿ ಕೆಲಸ ಮಾಡುವ ಮತ್ತು ಗ್ರಾಹಕರೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸುವ ಹಲವು ಸೂಕ್ಷ್ಮ ವ್ಯತ್ಯಾಸಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡಿದೆ.",
            "te": "ఉద్యోగం షాడోయింగ్ అనుభవం అతనికి మెకానిక్‌గా పని చేయడం మరియు కొనుగోలుదారులతో మాట్లాడడం వంటి అనేక సూక్ష్మ నైపుణ్యాలను అర్థం చేసుకోవడంలో సహాయపడుతుంది.",
            "or": "ଜବ୍‌ ଶାଡୋଇଂ ଅଭିଜ୍ଞତା ତାଙ୍କୁ ମେକାନିକ୍ ଭାବରେ କାର୍ଯ୍ୟ କରିବା ଏବଂ ଗ୍ରାହକଙ୍କ ସହିତ ଯୋଗାଯୋଗର ଅନେକ ସୂକ୍ଷ୍ମ ପାର୍ଥକ୍ୟ ବୁ୍ଝିବାରେ ସାହାଯ୍ୟ କରିଛି |",
            "as": "কামত লাগি দিয়া অভিজ্ঞতাই তাক মেকানিক হিচাপে কাম কৰা আৰু গ্ৰাহকৰ সৈতে বাৰ্তালাপ কৰাৰ বহুতো সৰু সৰু কথা বুজাত সহায় কৰিলে।",
            "gu": "અહિયાં કોઈના નીચે મિકેનિક તરીકે કામ કરવાથી અને ગ્રાહકો સાથે વાતચીત કરવાથી ઘણી સૂક્ષ્મતા સમજવામાં મદદ કરી છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "JobShadowing",
            "hi": "जॉब शैडो करना",
            "ka": "ಉದ್ಯೋಗ ನೆರಳು",
            "te": "ఉద్యోగం షాడోయింగ్",
            "or": "ଜବ୍‌ ଶାଡୋଇଂ",
            "as": "কামত লাগি দিয়া",
            "gu": "કોઈના નીચે કામા કરવાનું"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Job Shadowing experience!",
            "hi": "आपने अभी-अभी जॉब शैडो करने का अनुभव प्राप्त किया हैं!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಉದ್ಯೋಗ ನೆರಳು ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే ఉద్యోగం షాడోయింగ్ అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ଜବ୍‌ ଶାଡୋଇଂ ଅଭିଜ୍ଞତା ହାସଲ କରିଛନ୍ତି !",
            "as": "তুমি এইমাত্ৰ কামত লাগি দিয়াৰ অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "તમે હમણાં કોઈના નીચે કામ કરવાનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Job Shadowing",
            "hi": "जॉब शैडो करना",
            "ka": "ಉದ್ಯೋಗ ನೆರಳು",
            "te": "ఉద్యోగం షాడోయింగ్",
            "or": "ଜବ୍‌ ଶାଡୋଇଂ",
            "as": "কামত লাগি দিয়া",
            "gu": "કોઈના નીચે કામા કરવાનું"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Suitability Assessment Tools",
            "hi": "उचित करियर के लिए मूल्यांकन टूल",
            "ka": "ವೃತ್ತಿ ಸೂಕ್ತತೆಯ ಮೌಲ್ಯಮಾಪನ ಪರಿಕರಗಳು",
            "te": "వృత్తి అనుకూలత అంచనా సాధనాలు",
            "or": "କ୍ଯାରିୟର୍‍ ସୁଇଟାବିଲିଟି ଆସେସ୍‌ମେଣ୍ଟ୍‌ ଟୁଲ୍ସ",
            "as": "জীৱিকাৰ উপযুক্ততা মূল্যায়ন সঁজুলি",
            "gu": "કારકિર્દી યોગ્યતા મૂલ્યાંકન સાધનો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Naveen works with Omi's father for a week, where Naveen follows and observes his work. This is called job shadowing. In job shadowing, you watch a professional while they do their work. Job shadowing will help you learn about the work they do and how they face different situations. Recording your observations while job shadowing will help you document your learning and have it for future reference. It can help you in your career decision-making process.",
            "hi": "नवीन एक हफ्ते ओमी के पिता के साथ काम करता है और उनके साथ रहकर उनके काम का निरिक्षण करता है। इसे जॉब शैडो करना कहते हैं। जॉब शैडो करके का मतलब है, किसी प्रोफेशनल व्यक्ति को काम करते हुए देखना। जॉब शैडो करने से आपको यह जानने में मदद होगी कि, वह कैसे काम करते हैं और अलग-अलग परिस्थितियों का सामना कैसे करते हैं। जॉब शैडो करते हुए अपने निरीक्षणों को रेकॉर्ड करने से आपने जो कुछ भी सिखा उसे आप डोक्युमेंट कर सकते हैं ताकि आप इसे भविष्य में रेफ़रन्स के लिए इस्तेमाल कर सकें। या आपको करियर के बारे में निर्णय लेने में मदद कर सकता है।",
            "ka": "ನವೀನ್ ಓಮಿಯ ತಂದೆಯೊಂದಿಗೆ ಒಂದು ವಾರ ಕೆಲಸ ಮಾಡುತ್ತಾನೆ, ಅಲ್ಲಿ ನವೀನ್ ಅವನ ಕೆಲಸವನ್ನು ಅನುಸರಿಸುತ್ತಾನೆ ಮತ್ತು ಗಮನಿಸುತ್ತಾನೆ. ಇದನ್ನು ಕೆಲಸದ ನೆರಳು ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ. ಕೆಲಸದ ನೆರಳಿನಲ್ಲಿ, ಅವರು ತಮ್ಮ ಕೆಲಸವನ್ನು ಮಾಡುವಾಗ ನೀವು ವೃತ್ತಿಪರರನ್ನು ನೋಡುತ್ತೀರಿ. ಕೆಲಸದ ನೆರಳು ಅವರು ಮಾಡುವ ಕೆಲಸ ಮತ್ತು ಅವರು ಹೇಗೆ ವಿಭಿನ್ನ ಸನ್ನಿವೇಶಗಳನ್ನು ಎದುರಿಸುತ್ತಾರೆ ಎಂಬುದರ ಕುರಿತು ತಿಳಿದುಕೊಳ್ಳಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಕೆಲಸದ ನೆರಳಿನಲ್ಲಿ ನಿಮ್ಮ ಅವಲೋಕನಗಳನ್ನು ರೆಕಾರ್ಡ್ ಮಾಡುವುದರಿಂದ ನಿಮ್ಮ ಕಲಿಕೆಯನ್ನು ದಾಖಲಿಸಲು ಮತ್ತು ಭವಿಷ್ಯದ ಉಲ್ಲೇಖಕ್ಕಾಗಿ ಅದನ್ನು ಹೊಂದಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಇದು ನಿಮ್ಮ ವೃತ್ತಿ ನಿರ್ಧಾರ ಮಾಡುವ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ನಿಮಗೆ ಸಹಾಯ ಮಾಡಬಹುದು.",
            "te": "నవీన్ ఓమి తండ్రితో ఒక వారం పాటు పని చేస్తాడు, అక్కడ నవీన్ అతనిని అనుసరిస్తూ అతని పనిని గమనిస్తాడు. దీనినే ఉద్యోగం షాడోయింగ్ అంటారు. ఉద్యోగం షాడోయింగ్‌లో, ఒక ప్రొఫెషనల్ తమ పనిని చేస్తున్నప్పుడు మీరు గమనిస్తారు. ఉద్యోగం షాడోయింగ్ వారు చేసే పని గురించి మరియు వారు వివిధ పరిస్థితులను ఎలా ఎదుర్కొంటారు అనే దాని గురించి తెలుసుకోవడానికి మీకు సహాయపడుతుంది. ఉద్యోగం షాడోయింగ్‌లో ఉన్నప్పుడు మీ పరిశీలనలను రికార్డ్ చేయడం వలన మీరు నేర్చుకున్నవి డాక్యుమెంట్ చేయడంలో మరియు భవిష్యత్తులో చూసుకోవడానికి మీకు సహాయపడుతుంది. ఇది మీ కెరీర్ నిర్ణయం తీసుకునే ప్రక్రియలో మీకు సహాయపడుతుంది.",
            "or": "ନବୀନ ଏକ ସପ୍ତାହ ପାଇଁ ଓମିଙ୍କ ପିତାଙ୍କ ସହ କାମ କରନ୍ତି, ଯେଉଁଠାରେ ନବୀନ ତାଙ୍କ କାର୍ଯ୍ୟକୁ ଅନୁସରଣ ଏବଂ ନଜର କରନ୍ତି | ଏହାକୁ ଜବ୍‌ ଶାଡୋଇଂ କୁହାଯାଏ | ଜବ୍‌ ଶାଡୋଇଂରେ , ଆପଣ ଜଣେ ପ୍ରୋଫେସ୍‌ନାଲ୍‌ଙ୍କୁ ଦେଖନ୍ତି ଯେତେବେଳେ ସେ ତାଙ୍କର କାର୍ଯ୍ୟ କରନ୍ତି | ସେମାନେ କରୁଥିବା କାର୍ଯ୍ୟ ଏବଂ କିପରି ବିଭିନ୍ନ ପରିସ୍ଥିତିର ସମ୍ମୁଖୀନ ହୁଅନ୍ତି ସେ ବିଷୟରେ ଶିଖିବାରେ ଜବ୍‌ ଶାଡୋଇଂ ସାହାଯ୍ୟ କରିବେ | ଜବ୍‌ ଶାଡୋଇଂ ସମୟରେ ଆପଣ ପର୍ଯ୍ୟବେକ୍ଷଣକୁ ରେକର୍ଡିଂ କରିବା ଆପଣଙ୍କର ଶିକ୍ଷଣକୁ ଡକ୍ୟୁମେଣ୍ଟ୍ କରିବାରେ ସାହାଯ୍ୟ କରିବ ଏବଂ ଭବିଷ୍ୟତର ରେଫରେନ୍ସ ପାଇଁ ଏହାକୁ ରଖିବ | ଏହା ତୁମ କ୍ୟାରିୟର ନିଷ୍ପତ୍ତି ନେବା ପ୍ରକ୍ରିୟାରେ ସାହାଯ୍ୟ କରିପାରିବ |",
            "as": "নবীনে অমিৰ দেউতাকৰ সৈতে এসপ্তাহ কাম কৰিলে, য'ত নবীনে তেওঁৰ কাম অনুসৰণ আৰু পালন কৰিলে। ইয়াকে কামত লাগি দিয়া বুলি কয় ৷ ইয়াত, তুমি এজন পেছাদাৰী মানুহে কাম কৰাটো প্ৰত্যক্ষ কৰা। ই তোমাক তেওঁলোকে কৰা কাম আৰু তেওঁলোকে কেনেদৰে বিভিন্ন পৰিস্থিতিৰ সন্মুখীন হয় সেই বিষয়ে শিকাত সহায় কৰিব। কামত লাগি দিয়াৰ সময়ত তোমাৰ পৰ্যৱেক্ষণবোৰ ৰেকৰ্ডিং কৰিলে তোমাক শিকিবলগীয়াবোৰ নথিভুক্ত কৰাত আৰু ভৱিষ্যতৰ প্ৰসংগৰ বাবে ইয়াক ব্যৱহাৰ কৰাত সহায় কৰিব। ই তোমাক তোমাৰ জীৱিকাৰ সিদ্ধান্ত লোৱাৰ প্ৰক্ৰিয়াত সহায় কৰিব।",
            "gu": "નવીન એક અઠવાડીયા માટે ઓમનીના પિતા સાથે કામ કરે છે અને બધુ નિહાળે છે અને અનુસરે છે. આને કહેવાય કોઈના નીચે કામ કેવીરીતે કરવું. કાઈના નીચે કામ કરવાથી તમે અનુભવી લોકોને જોશો કે તે કેવી રીતે કામ કરે છે. આમાં એ પણ શીખવા મળશે કે કામ કેવી રીતે કરવું અને વિવિધ પરિસ્તીથી કેવી રીતે સંભાળવી. આ બધુ નોધવાથી તમને શીખવા મળશે અને આગળ ભવિષ્યમાં મદદ રૂપ થશે. તે તમને કારકિર્દી નિર્ણય લેવાની પ્રક્રિયામાં તમને મદદ કરી શકે છે"
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ",
            "hi": "MCQ",
            "ka": "ಎಂಸಿಕ್ಯೂ",
            "te": "MCQ",
            "or": "MCQ",
            "as": "MCQ",
            "gu": "MCQ"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Select the statement below which is ‘true’:",
            "hi": "निम्नलिखित में से ‘सही’ विधान चुनें:",
            "ka": "ಕೆಳಗಿನ ಹೇಳಿಕೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ ಅದು 'ನಿಜ':",
            "te": "దిగువన ఉన్నవాటిలో సరైనవి సెలెక్ట్ చేయండి:",
            "or": "ନିମ୍ନରେ ଥିବା ବିବୃତି ଚୟନ କରନ୍ତୁ ଯାହା ‘ସତ୍ୟ’ ଅଟେ:",
            "as": "তলত দিয়া বিবৃতিবোৰৰপৰা 'সঁচা'টো বাছনি কৰা:",
            "gu": "નીચેના વાક્યોમાંથી કયો સાચો છે તે જુવો:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "To job shadow someone and to do an internship is the same.",
            "hi": "किसी को जॉब शैडो करना और इन्टर्नशिप करना एक ही है।",
            "ka": "ಯಾರಿಗಾದರೂ ಉದ್ಯೋಗ ನೀಡುವುದು ಮತ್ತು ಇಂಟರ್ನ್‌ಶಿಪ್ ಮಾಡುವುದು ಒಂದೇ.",
            "te": "ఎవరిదైనా ఉద్యోగం షాడో చేయడం, ఇంటర్న్‌షిప్ చేయడం ఒకటే.",
            "or": "କାହାକୁ ଜବ୍‍ ଶାଡୋ କରିବା ଏବଂ ଏକ ଇଣ୍ଟର୍ନସିପ୍ କରିବା ସମାନ |",
            "as": "কাৰোবাক কামত লাগি দিয়া আৰু ইণ্টাৰ্নশ্বিপ কৰাটো একে।",
            "gu": "કોઈના નીચે કામા કરવાનું અને ઈન્ટર્નશીપ સરખા છે. "
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "During job shadowing you can write down your thoughts for reading them later",
            "hi": "जॉब शैडो करते समय आप अपने विचार लिखकर रख सकते हैं ताकि आप बाद में इसे पढ़ सके।",
            "ka": "ಕೆಲಸದ ನೆರಳಿನ ಸಮಯದಲ್ಲಿ ನೀವು ಅವುಗಳನ್ನು ನಂತರ ಓದಲು ನಿಮ್ಮ ಆಲೋಚನೆಗಳನ್ನು ಬರೆಯಬಹುದು",
            "te": "ఉద్యోగం షాడోయింగ్ సమయంలో మీరు వాటిని తర్వాత చదవడానికి మీ ఆలోచనలను వ్రాయవచ్చు",
            "or": "ଜବ୍‌ ଶାଡୋଇଂ ସମୟରେ ପରେ ପଢିବା ପାଇଁ ଆପଣ ଆପଣଙ୍କର ଚିନ୍ତାଧାରା ଲେଖିପାରିବେ |",
            "as": "কামত লাগি দিয়াৰ সময়ত তুমি তোমাৰ চিন্তাবোৰ পিছত পঢ়াৰ বাবে লিখি ৰাখিব পাৰা",
            "gu": "કોઈના નીચે કામ કરતી વખતે તમે તમારી નોધ બનાવી શકો છો જે તમારે આગળ વાંચવા માટે કામ આવે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Job shadowing allows you to know the tasks done and personality required for the job",
            "hi": "जॉब शैडो करने से आपको यह पता चलता है कि काम कैसे किया जाता है और इस काम के लिए कैसे व्यक्तिमत्व की आवश्यकता होती है।",
            "ka": "ಕೆಲಸದ ನೆರಳು ನಿಮಗೆ ಮಾಡಿದ ಕಾರ್ಯಗಳನ್ನು ಮತ್ತು ಕೆಲಸಕ್ಕೆ ಅಗತ್ಯವಾದ ವ್ಯಕ್ತಿತ್ವವನ್ನು ತಿಳಿಯಲು ಅನುಮತಿಸುತ್ತದೆ",
            "te": "ఉద్యోగం షాడోయింగ్ మీరు చేసిన పనులు మరియు ఉద్యోగానికి అవసరమైన వ్యక్తిత్వాన్ని తెలుసుకోవడానికి అనుమతిస్తుంది",
            "or": "ଜବ୍‌ ଶାଡୋଇଂ ଆପଣଙ୍କୁ ଚାକିରି ପାଇଁ ଆବଶ୍ୟକ କାର୍ଯ୍ୟ ଏବଂ ବ୍ୟକ୍ତିତ୍ୱ ଜାଣିବାକୁ ଅନୁମତି ଦିଏ |",
            "as": "কামত লাগি দিয়া ব্যৱস্থাই তোমাক কামটোৰ বাবে প্ৰয়োজনীয় দায়িত্ব আৰু ব্যক্তিত্ব জানিবলৈ অনুমতি দিয়ে",
            "gu": "કોઈના નીચે કામ કરતી વખતે તમને તમારા કર્યો અને વ્યક્તિવ્યની મજૂરી આપે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Think again! Job shadowing would be for a few days versus an internship which could be for weeks/months.",
            "hi": "फिर से सोचो! जॉब शैडो कुछ दिनों के लिए ही किया जाता है और इन्टर्नशिप कुछ हफ्ते/महीनों तक चल सकती है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಉದ್ಯೋಗದ ನೆರಳು ಕೆಲವು ದಿನಗಳವರೆಗೆ ಇರುತ್ತದೆ ಮತ್ತು ಇಂಟರ್ನ್‌ಶಿಪ್ ವಾರಗಳು/ತಿಂಗಳವರೆಗೆ ಇರಬಹುದು.",
            "te": "మళ్లీ ఆలోచించండి! వారాలు/నెలల పాటు ఉండే ఇంటర్న్‌షిప్‌ లాగా కాకుండా ఉద్యోగం షాడోయింగ్ కొన్ని రోజులు ఉంటాయి.",
            "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ଜବ୍‌ ଶାଡୋଇଂ କିଛି ଦିନ ପାଇଁ ହୁଏ ବନାମ ଏକ ଇଣ୍ଟର୍ନସିପ୍ ଯାହା ସପ୍ତାହ / ମାସ ପାଇଁ ହୋଇପାରେ |",
            "as": "আকৌ চিন্তা কৰা! কামত লাগি দিয়াতো কেইদিনমানৰ বাবে কিন্তু এটা ইণ্টাৰ্নশ্বিপ সপ্তাহ/মাহৰ বাবে হ'ব পাৰে।",
            "gu": "વિચારો ફરીથી! કોઈના નીચે કામ કરતી વખતે ઇન્ટર્નશિપ સામે થોડા દિવસો માટે  હોય શકે છે જે અઠવાડિયા/મહિનાઓ માટે હોઈ શકે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "बहुत बढ़िया!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "బాగా చెప్పారు!",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ !",
            "as": "ভাল কৰিছা!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "बहुत बढ़िया!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "బాగా చెప్పారు!",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ !",
            "as": "ভাল কৰিছা!",
            "gu": "સરસ કામ!"
          }
        }
      ]
    },
    "scene16": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_1_1",
            "hi": "C4_1_1",
            "ka": "C4_1_1",
            "te": "C4_1_1",
            "or": "C4_1_1",
            "as": "C4_1_1",
            "gu": "C4_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Omi’s father is happy with Naveen’s work. He puts a word out to see if there are any opportunities available for him.",
            "hi": "ओमी के पिता नवीन के काम से बहुत खुश हैं। वह नवीन के लिए जॉब के अवसर ढूंढने के लिए पूछताछ करते हैं।",
            "ka": "ಓಮಿಯ ತಂದೆ ನವೀನ್‌ನ ಕೆಲಸದಿಂದ ಸಂತಸಗೊಂಡಿದ್ದಾರೆ. ತನಗೆ ಏನಾದರೂ ಅವಕಾಶಗಳು ಲಭ್ಯವಿವೆಯೇ ಎಂದು ಅವನು ಒಂದು ಮಾತನ್ನು ಹಾಕುತ್ತಾನೆ.",
            "te": "నవీన్ పని పట్ల ఓమి తండ్రి సంతోషిస్తున్నాడు. తనకి అవకాశాల కోసం ఒక మాట చెప్పి పెట్టాడు.",
            "or": "ନବୀନଙ୍କ କାର୍ଯ୍ୟରେ ଓମିଙ୍କ ପିତା ଖୁସି ଅଛନ୍ତି | ତାଙ୍କ ପାଇଁ କୌଣସି ସୁଯୋଗ ଉପଲବ୍ଧ ଅଛି କି ନାହିଁ ଜାଣିବା ପାଇଁ ସେ ସୁପାରିଶ କରନ୍ତି |",
            "as": "অমিৰ দেউতাক নবীনৰ কামত সুখী। তাৰ বাবে কোনো সুযোগ আছে নেকি চাবলৈ তেওঁ খবৰ দিয়ে।",
            "gu": "નવીનના કામથી ઓમીના પિતા ખુશ છે. તે જોવા માટે તે એક શબ્દ મૂકે છે જેના માટે કોઈ તકો ઉપલબ્ધ છે કે કેમ."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_2_1",
            "hi": "C4_2_1",
            "ka": "C4_2_1",
            "te": "C4_2_1",
            "or": "C4_2_1",
            "as": "C4_2_1",
            "gu": "C4_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Through Omi’s father’s contacts, Naveen gets a job at a small electronics shop looking for a junior mechanic.",
            "hi": "ओमी के पिता के संपर्क के जरिए, नवीन को एक छोटी सी इलेक्ट्रॉनिक दुकान में ज्युनिअर मैकेनिक की जॉब मिल जाती है।",
            "ka": "ಓಮಿಯ ತಂದೆಯ ಸಂಪರ್ಕಗಳ ಮೂಲಕ ನವೀನ್‌ಗೆ ಜೂನಿಯರ್ ಮೆಕ್ಯಾನಿಕ್‌ಗಾಗಿ ಸಣ್ಣ ಎಲೆಕ್ಟ್ರಾನಿಕ್ಸ್ ಅಂಗಡಿಯಲ್ಲಿ ಕೆಲಸ ಸಿಗುತ್ತದೆ.",
            "te": "ఓమి తండ్రి పరిచయాల ద్వారా, నవీన్‌కి జూనియర్ మెకానిక్ కోసం వెతుకుతున్న ఒక చిన్న ఎలక్ట్రానిక్స్ షాప్‌లో ఉద్యోగం వస్తుంది.",
            "or": "ଓମିଙ୍କ ପିତାଙ୍କ ଯୋଗାଯୋଗ ମାଧ୍ୟମରେ, ନବୀନ ଏକ ଜୁନିଅର ମେକାନିକ୍ ଖୋଜୁଥିବା ଏକ ଛୋଟ ଇଲେକ୍ଟ୍ରୋନିକ୍ସ ଦୋକାନରେ ଚାକିରି ପାଆନ୍ତି |",
            "as": "অমিৰ দেউতাকৰ সম্পৰ্কৰ জৰিয়তে নবীনে কনিষ্ঠ মেকানিক হিচাপে এখন সৰু ইলেক্ট্ৰনিক্স দোকানত চাকৰি পায়।",
            "gu": "ઓમીના પિતાના લીધે, નવીનને જુનિયર મિકેનિકની નોકરી મળે છે એક નાની ઇલેક્ટ્રોનિક્સની દુકાનમાં."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_2_2",
            "hi": "C4_2_2",
            "ka": "C4_2_2",
            "te": "C4_2_2",
            "or": "C4_2_2",
            "as": "C4_2_2",
            "gu": "C4_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen continues to work at the shop comfortably for 2 years. However, now he feels like his career is not moving forward.",
            "hi": "नवीन उस दूकान में 2 साल तक आराम से काम करता है। लेकिन उसे लगता है कि उसका करियर आगे नहीं बढ़ रहा।",
            "ka": "ನವೀನ್ 2 ವರ್ಷಗಳಿಂದ ಆರಾಮವಾಗಿ ಅಂಗಡಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಲೇ ಇದ್ದಾನೆ. ಆದರೆ, ಈಗ ಅವನ ವೃತ್ತಿಜೀವನ ಮುಂದೆ ಸಾಗುತ್ತಿಲ್ಲ ಎಂದು ಅನಿಸುತ್ತಿದೆ.",
            "te": "నవీన్ 2 సంవత్సరాలుగా దుకాణంలో హాయిగా పని చేస్తూనే ఉన్నాడు. అయితే, ఇప్పుడు తన కెరీర్ ముందుకు సాగడం లేదని అనుకుంటున్నాడు.",
            "or": "ନବୀନ ଦୋକାନରେ 2 ବର୍ଷ ଆରାମରେ କାମ ଜାରି ରଖନ୍ତି | ଅଥଚ, ବର୍ତ୍ତମାନ ସେ ଅନୁଭବ କରନ୍ତି ଯେ ତାଙ୍କ କ୍ୟାରିଅର୍ ଆଗକୁ ବଢୁନାହିଁ |",
            "as": "নবীনে দোকানখনত ২ বছৰ ধৰি আৰামেৰে কাম কৰি আছে। অৱশ্যে, এতিয়া সি অনুভৱ কৰিছে যে তাৰ জীৱিকা আগবাঢ়ি যোৱা নাই।",
            "gu": "નવીન સરળતાથી 2 વર્ષ સુધી તે દુકામમાં કામ કરે છે. જોકે, તેની કારકિર્દી આગળ વધી રહી નથી હવે તેને લાગે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_2_3",
            "hi": "C4_2_3",
            "ka": "C4_2_3",
            "te": "C4_2_3",
            "or": "C4_2_3",
            "as": "C4_2_3",
            "gu": "C4_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen feels like he needs a raise. He shares it with his employer, who listens to him and tells him that he’ll think about it and get back to him.",
            "hi": "नवीन को लगता है कि अब उसकी सैलरी बढ़नी चाहिए। वह अपने मालिक से इस बारे में बात करता है। उसके मालिक उसे कहते हैं कि वह इस बात पर विचार करेंगे।",
            "ka": "ನವೀನ್‌ಗೆ ತನಗೆ ಏರಿಕೆ ಬೇಕು ಎಂದು ಅನಿಸುತ್ತದೆ. ಅವನು ಅದನ್ನು ತನ್ನ ಉದ್ಯೋಗದಾತರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳುತ್ತಾನೆ, ಅವನು ಅವನ ಮಾತನ್ನು ಕೇಳುತ್ತಾನೆ ಮತ್ತು ಅವನು ಅದರ ಬಗ್ಗೆ ಯೋಚಿಸುತ್ತೇನೆ ಮತ್ತು ಅವನ ಬಳಿಗೆ ಹಿಂತಿರುಗುತ್ತೇನೆ ಎಂದು ಹೇಳುತ್ತಾನೆ.",
            "te": "నవీన్‌కి తన పెంపుదల అవసరమనిపిస్తోంది. అతను దానిని తన యజమానితో పంచుకుంటాడు, అతను అతని మాట వింటాడు మరియు అతను దాని గురించి ఆలోచిస్తానని మరియు అతనిని తిరిగి సంప్రదిస్తానని చెప్పాడు.",
            "or": "ନବୀନ ଅନୁଭବ କରନ୍ତି ଯେ ତାଙ୍କୁ ଏକ ବୃଦ୍ଧି ଆବଶ୍ୟକ | ସେ ଏହାକୁ ତାଙ୍କ ନିଯୁକ୍ତିଦାତା ସହିତ ଅଂଶୀଦାର କରନ୍ତି, ଯିଏ ତାଙ୍କ କଥା ଶୁଣନ୍ତି ଏବଂ ତାଙ୍କୁ କୁହନ୍ତି ଯେ ସେ ଏହା ବିଷୟରେ ଚିନ୍ତା କରିବେ ଏବଂ ତାଙ୍କ ନିକଟକୁ ଫେରିବେ |",
            "as": "নবীনে অনুভৱ কৰে যে সি আগবাঢ়ি যোৱাৰ প্ৰয়োজন। সি এইবিষয়ে তাৰ নিয়োগকৰ্তাৰ সৈতে আলোচনা কৰে, তেওঁ তাৰ কথা শুনিলে আৰু তাক ক’লে যে তেওঁ এই বিষয়ে চিন্তা কৰিব আৰু তাক জনাব।",
            "gu": "નવીનને લાગે છે કે હવે તેને કઈક વધારવાની જરૂર છે. તેને વિષે તે તેના માલિકને વાત કરે છે, જે તેની વાત શાંમભળે છે અને તેને કહે છે કે તે તેના વિશે વિચારશે અને પછી બતાવશે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_3_1",
            "hi": "C4_3_1",
            "ka": "C4_3_1",
            "te": "C4_3_1",
            "or": "C4_3_1",
            "as": "C4_3_1",
            "gu": "C4_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "In the meantime, one day, when Naveen is returning from a customer’s home after doing a repair job, he comes across a hiring poster outside Alliance Digital, a big electronics retail store.",
            "hi": "इस दौरान, एक दिन नवीन एक ग्राहक के घर पर रिपेअरिंग का काम करके वापस लौट रहा होता है, तब उसे अलायन्स डिजिटिल में नौकरी के लिए एक पोस्टर दिखता है, जो एक बड़ा इलेक्ट्रॉनिक रिटेल स्टोअर है।",
            "ka": "ಈ ಮಧ್ಯೆ, ಒಂದು ದಿನ, ನವೀನ್ ರಿಪೇರಿ ಕೆಲಸ ಮುಗಿಸಿ ಗ್ರಾಹಕರ ಮನೆಯಿಂದ ಹಿಂತಿರುಗುತ್ತಿರುವಾಗ, ದೊಡ್ಡ ಎಲೆಕ್ಟ್ರಾನಿಕ್ಸ್ ಚಿಲ್ಲರೆ ಅಂಗಡಿಯಾದ ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್‌ನ ಹೊರಗೆ ಬಾಡಿಗೆ ಪೋಸ್ಟರ್ ಕಾಣಿಸುತ್ತದೆ.",
            "te": "ఈలోగా, ఒక రోజు, నవీన్ రిపేర్ ఉద్యోగం చేసి కొనుగోలుదారు ఇంటి నుండి తిరిగి వస్తున్నప్పుడు, అతను పెద్ద ఎలక్ట్రానిక్స్ రిటైల్ స్టోర్ అయిన అలయన్స్ డిజిటల్ బయట ఒక హైరింగ్ పోస్టర్‌ని చూశాడు.",
            "or": "ଇତି ମଧ୍ୟରେ, ଦିନେ, ଯେତେବେଳେ ନବୀନ ଏକ ମରାମତି କାମ କରିବା ପରେ ଗ୍ରାହକଙ୍କ ଘରୁ ଫେରୁଥିଲେ, ସେ ଏକ ବଡ଼ ଇଲେକ୍ଟ୍ରୋନିକ୍ସ ରିଟେଲ୍‌ ଷ୍ଟୋର୍ ଆଲାଇନ୍ସ ଡିଜିଟାଲ୍ ବାହାରେ ଏକ ପୋଷ୍ଟର କୁ ଦେଖନ୍ତି |",
            "as": "ইয়াৰ মাজতে, এদিন, নবীনে গ্ৰাহকৰ ঘৰৰ পৰা মেৰামতিৰ কাম কৰি উভতি আহোঁতে, এলায়েন্স ডিজিটেলৰ বাহিৰত এক ডাঙৰ ইলেক্ট্ৰনিক্স ৰিটেইল দোকানৰ বাবে এটা চাকৰিৰ বিজ্ঞাপন দেখা পালে ৷",
            "gu": "તે દરમિયાન, એક દિવશ, જ્યારે નવીન ગ્રાહકના ઘરેથી કામ કરીને પાછો આવતો હતો ત્યારે તેને એક એલાયન્સ ડિજિટલની બાહર એક પોસ્ટર દેખે છે જે, એક ઇલેક્ટ્રોનિક્સ રીટેલ મોટો સ્ટોર છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_3_2",
            "hi": "C4_3_2",
            "ka": "C4_3_2",
            "te": "C4_3_2",
            "or": "C4_3_2",
            "as": "C4_3_2",
            "gu": "C4_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He sees that the store is hiring a Jr. Mechanic at a decent salary. Naveen applies and gets called for the interview.",
            "hi": "उसे पता चलता है कि स्टोअर में जूनियर मैकेनिक के लिए अच्छी सैलरी वाली जॉब उपलब्ध है। नवीन इस जॉब के लिए अप्लाई करता है और उसे इंटरव्यू के लिए बुलाया जाता है।",
            "ka": "ಅಂಗಡಿಯು ಜೂನಿಯರ್ ಮೆಕ್ಯಾನಿಕ್ ಅನ್ನು ಯೋಗ್ಯ ಸಂಬಳದಲ್ಲಿ ನೇಮಿಸಿಕೊಳ್ಳುತ್ತಿರುವುದನ್ನು ಅವನು ನೋಡುತ್ತಾನೆ. ನವೀನ್ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ ಅವನ್ನನು ಸಂದರ್ಶನಕ್ಕೆ ಕರೆದರು.",
            "te": "ఆ స్టోర్ ఒక జూనియర్ మెకానిక్‌ని మంచి జీతంతో హైర్ చేస్తున్నట్టు అతను చూస్తాడు. నవీన్ దరఖాస్తు చేసుకున్నాడు మరియు ఇంటర్వ్యూకి పిలవబడతాడు.",
            "or": "ସେ ଦେଖନ୍ତି ଯେ ଦୋକାନ ଏକ ଭଲ ବେତନରେ ଏକ ଜୁନିୟର୍‍ ମେକାନିକ୍ ନିଯୁକ୍ତ କରୁଛି | ନବୀନ ଆବେଦନ କରନ୍ତି ଏବଂ ସାକ୍ଷାତକାର ପାଇଁ ଡକରା ପାଆନ୍ତି |",
            "as": "সি দেখিলে যে দোকানখনে এজন জুনিয়ৰ মেকানিকক ভাল দৰমহাত নিয়োগ কৰিব। নবীনে আবেদন কৰিলে আৰু সাক্ষাৎকাৰৰ বাবে তাক মাতিলে।",
            "gu": "તે જોવે છે કે સ્ટોર જુનિયર મિકેનિકની નોકરી રાખે જેમાં યોગ્ય પગારે મળશે. નવીન તેમાં અરજી કરે છે અને ઇન્ટરવ્યુ માટે બોલાવવામાં આવે છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_4_1",
            "hi": "C4_4_1",
            "ka": "C4_4_1",
            "te": "C4_4_1",
            "or": "C4_4_1",
            "as": "C4_4_1",
            "gu": "C4_4_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The interviewer doesn’t look friendly. She doesn’t even ask Naveen to introduce himself.",
            "hi": "इंटरव्यू लेने वाली महिला ज्यादा मिलनसार नहीं है। वह नवीन को खुद का परिचय देने के लिए भी नहीं कहती।",
            "ka": "ಸಂದರ್ಶಕನು ಸ್ನೇಹಪರನಾಗಿ ಕಾಣುವುದಿಲ್ಲ. ನವೀನ್‌ನನ್ನು ಪರಿಚಯಿಸಲು ಅವಳು ಕೇಳುವುದಿಲ್ಲ.",
            "te": "ఇంటర్వ్యూ చేసే వ్యక్తి ఫ్రెండ్లీగా కనిపించదు. నవీన్‌ని పరిచయం చేసుకోమని కూడా ఆమె అడగదు.",
            "or": "ସାକ୍ଷାତକାରକାରୀ ବନ୍ଧୁତ୍ୱପୂର୍ଣ୍ଣ ଦେଖାଯାନ୍ତି ନାହିଁ | ଏପରିକି ସେ ନବୀନଙ୍କୁ ନିଜକୁ ପରିଚିତ କରାଇବାକୁ କୁହନ୍ତି ନାହିଁ |",
            "as": "সাক্ষাৎকাৰ লওঁতাজন বন্ধুসুলভ নাছিল। তেখেতে আনকি নবীনক নিজৰ পৰিচয় দিবলৈও নক’লে।",
            "gu": "ઇન્ટરવ્યુ લેનારે નવીન જોડે મૈત્રીપૂર્ણ દેખવ ના કર્યો. તેને નવીનને પોતાનો પરિચય આપવાનું પણ ના કહ્યું."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_4_2//",
            "hi": "C4_4_2//",
            "ka": "C4_4_2//",
            "te": "C4_4_2//",
            "or": "C4_4_2//",
            "as": "C4_4_2//",
            "gu": "C4_4_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Help Naveen answer her questions.",
            "hi": "नवीन को उसके प्रश्नों का उत्तर देने के लिए मदद करें।",
            "ka": "ನವೀನ್ ಅವಳ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಲು ಸಹಾಯ ಮಾಡಿ.",
            "te": "నవీన్ ఆమె ప్రశ్నలకు సమాధానమివ్వడానికి సహాయం చేయండి.",
            "or": "ନବୀନଙ୍କୁ ତାଙ୍କ ପ୍ରଶ୍ନର ଉତ୍ତର ଦେବାକୁ ସାହାଯ୍ୟ କରନ୍ତୁ |",
            "as": "তেখেতৰ প্ৰশ্নবোৰৰৰ উত্তৰ দিবলৈ নবীনক সহায় কৰা।",
            "gu": "નવીનને તેને જવાબ સોધવામાં મદદ કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Interviewer",
            "hi": "इंटरव्यूवर",
            "ka": "Interviewer",
            "te": "ఇంటర్వ్యూయర్",
            "or": "ସାକ୍ଷାତକାରକାରୀ",
            "as": "সাক্ষাৎকাৰ লওঁতা",
            "gu": "ઇન્ટરવ્યુ લેનાર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "So, What is good customer service according to you?",
            "hi": "तो, अच्छी ग्राहक सेवा क्या होती है ऐसा तुम्हें लगता है?",
            "ka": "ಹಾಗಾದರೆ, ನಿಮ್ಮ ಪ್ರಕಾರ ಉತ್ತಮ ಗ್ರಾಹಕ ಸೇವೆ ಯಾವುದು?",
            "te": "కాబట్టి, మీ అభిప్రాయం ప్రకారం మంచి కస్టమర్ సేవ ఏమిటి?",
            "or": "ତେବେ, ଆପଣଙ୍କ ଅନୁଯାୟୀ ଭଲ ଗ୍ରାହକ ସେବା କ’ଣ ?",
            "as": "বাৰু, তোমাৰ মতে ভাল গ্ৰাহক সেৱা কি?",
            "gu": "તો, તારા મત પ્રમાણે સારી ગ્રાહક સેવા કઈ કહેવાય?"
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_4_2d",
            "hi": "C4_4_2d",
            "ka": "C4_4_2d",
            "te": "C4_4_2d",
            "or": "C4_4_2d",
            "as": "C4_4_2d",
            "gu": "C4_4_2d"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Naveen reply?",
            "hi": "नवीन को क्या उत्तर देना चाहिए?",
            "ka": "ನವೀನ್ ಏನು ಉತ್ತರಿಸಬೇಕು?",
            "te": "నవీన్ ఏం సమాధానం చెప్పాలి?",
            "or": "ନବୀନ କ’ଣ ଉତ୍ତର ଦେବା ଉଚିତ୍ ?",
            "as": "নবীনে কি উত্তৰ দিব?",
            "gu": "નવીનને શું જવાબ આપવો જોઇયે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Providing timely and attentive service.",
            "hi": "समय पर और उचित सेवा प्रदान करना।",
            "ka": "ಸಮಯೋಚಿತ ಮತ್ತು ಗಮನದ ಸೇವೆಯನ್ನು ಒದಗಿಸುವುದು.",
            "te": "సకాలంలో మరియు శ్రద్ధగల సేవను అందించడం.",
            "or": "ସମୟାନୁବର୍ତ୍ତୀ ଏବଂ ଯତ୍ନଶୀଳ ସେବା ପ୍ରଦାନ |",
            "as": "সময়মতে আৰু মনোযোগী সেৱা প্ৰদান কৰা।",
            "gu": "સમયસર અને ચોક્કસ સેવા પૂરી કરી દેવી જોઇયે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Providing honest and patient service to customers.",
            "hi": "ग्राहकों को ईमानदारी और धैर्य के साथ सेवा प्रदान करना।",
            "ka": "ಗ್ರಾಹಕರಿಗೆ ಪ್ರಾಮಾಣಿಕ ಮತ್ತು ತಾಳ್ಮೆಯ ಸೇವೆಯನ್ನು ಒದಗಿಸುವುದು.",
            "te": "కొనుగోలుదారులకు నిజాయితీగా మరియు ఓపిగ్గా సేవ ను అందించడం.",
            "or": "ଗ୍ରାହକମାନଙ୍କୁ ସଚ୍ଚୋଟ ଏବଂ ଧୈର୍ଯ୍ୟବାନ ସେବା ଯୋଗାଇବା |",
            "as": "গ্ৰাহকসকলক সৎ আৰু শান্তভাৱে সেৱা প্ৰদান কৰা।",
            "gu": "ગ્રાહકોને પ્રમાણિક અને ધૈર્યવાનસેવા પૂરી પાડવી."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_4_3//",
            "hi": "C4_4_3//",
            "ka": "C4_4_3//",
            "te": "C4_4_3//",
            "or": "C4_4_3//",
            "as": "C4_4_3//",
            "gu": "C4_4_3//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Interviewer",
            "hi": "इंटरव्यूवर",
            "ka": "ಸಂದರ್ಶಕ",
            "te": "ఇంటర్వ్యూయర్",
            "or": "ସାକ୍ଷାତକାରକାରୀ",
            "as": "সাক্ষাৎকাৰ লওঁতা",
            "gu": "ઇન્ટરવ્યુ લેનાર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Are you aware of all the responsibilities this job requires?",
            "hi": "क्या तुम्हें इस जॉब के लिए आवशयक जिम्मेदारियों के बारे में जानते हो?",
            "ka": "ಈ ಕೆಲಸಕ್ಕೆ ಅಗತ್ಯವಿರುವ ಎಲ್ಲಾ ಜವಾಬ್ದಾರಿಗಳ ಬಗ್ಗೆ ನಿಮಗೆ ತಿಳಿದಿದೆಯೇ?",
            "te": "ఈ ఉద్యోగానికి అవసరమైన అన్ని బాధ్యతల గురించి మీకు తెలుసా?",
            "or": "ଏହି ଚାକିରି ଆବଶ୍ୟକ କରୁଥିବା ସମସ୍ତ ଦାୟିତ୍ବ ବିଷୟରେ ଆପଣ ଅବଗତ କି ?",
            "as": "এই চাকৰিটোৰ বাবে প্ৰয়োজনীয় সকলো দায়িত্বৰ বিষয়ে তুমি জানানে?",
            "gu": "તને ખ્યાલ છે કે આ નોકરીમાં જવાબદારી શું છે?"
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_4_3d",
            "hi": "C4_4_3d",
            "ka": "C4_4_3d",
            "te": "C4_4_3d",
            "or": "C4_4_3d",
            "as": "C4_4_3d",
            "gu": "C4_4_3d"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Naveen reply?",
            "hi": "नवीन को क्या उत्तर देना चाहिए?",
            "ka": "ನವೀನ್ ಏನು ಉತ್ತರಿಸಬೇಕು?",
            "te": "నవీన్ ఏం సమాధానం చెప్పాలి?",
            "or": "ନବୀନ କ’ଣ ଉତ୍ତର ଦେବା ଉଚିତ୍ ?",
            "as": "নবীনে কি উত্তৰ দিব?",
            "gu": "નવીનને શું જવાબ આપવો જોઇયે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Yes, Ma’am. After understanding the issue with the product, we quote the price to the customer. If they approve, we proceed with the job.",
            "hi": "हाँ, मैम, प्रोडक्ट में क्या खराबी है यह पता लगाने के बाद, ग्राहक को इसकी कीमत बताना और अगर वे मंजूरी देते हैं तो यह काम पूरा करना।",
            "ka": "ಹೌದು ಮೇಡಂ. ಉತ್ಪನ್ನದ ಸಮಸ್ಯೆಯನ್ನು ಅರ್ಥಮಾಡಿಕೊಂಡ ನಂತರ, ನಾವು ಗ್ರಾಹಕರಿಗೆ ಬೆಲೆಯನ್ನು ಉಲ್ಲೇಖಿಸುತ್ತೇವೆ. ಅವರು ಅನುಮೋದಿಸಿದರೆ, ನಾವು ಕೆಲಸವನ್ನು ಮುಂದುವರಿಸುತ್ತೇವೆ.",
            "te": "అవును మేడమ్. ఉత్పత్తికి సంబంధించిన సమస్యను అర్థం చేసుకున్న తర్వాత, మనము కస్టమర్‌కు ధరను కోట్ చేస్తాము. వారు అప్రూవ్ చేసినట్లైతే, మనము పనిని కొనసాగిస్తాము.",
            "or": "ହଁ, ମ୍ଯାଡମ | ଉତ୍ପାଦ ସହିତ ସମସ୍ୟା ବୁଝିବା ପରେ, ଆମେ ଗ୍ରାହକଙ୍କୁ ମୂଲ୍ୟ ଉଦ୍ଧୃତ କରୁ | ଯଦି ସେମାନେ ଅନୁମୋଦନ କରନ୍ତି, ଆମେ କାର୍ଯ୍ୟ ସହିତ ଅଗ୍ରଗତି କରୁ |",
            "as": "হয় মেডাম। সামগ্ৰীটোৰ সৈতে সমস্যাটো বুজি পোৱাৰ পিছত, আমি গ্ৰাহকক মূল্যটো জনাওঁ। যদি তেওঁলোকে অনুমোদন জনায়, আমি কামটো আগবঢ়াই নিওঁ।",
            "gu": "હા મેડમ. વસ્તુની કોઈ ખામીને સમજીને આપણે ગ્રાહકને તે વસ્તુનો ભાવ બતાવીએ છીયે. અગર તે માની લે તો આગળ વધીએ છીયે તે કામ સાથે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Yes, Ma’am. I will look at the product to find out the problem. Then I will repair the product.",
            "hi": "हाँ, मैम, मैं प्रोडक्ट को देख कर उसमें क्या समस्या है यह पता करूंगा और फिर उस प्रोडक्ट को रिपेयर करूंगा।",
            "ka": "ಹೌದು ಮೇಡಂ. ಸಮಸ್ಯೆಯನ್ನು ಕಂಡುಹಿಡಿಯಲು ನಾನು ಉತ್ಪನ್ನವನ್ನು ನೋಡುತ್ತೇನೆ. ನಂತರ ನಾನು ಉತ್ಪನ್ನವನ್ನು ಸರಿಪಡಿಸುತ್ತೇನೆ.",
            "te": "అవును మేడమ్. సమస్యను తెలుసుకోవడానికి నేను ఉత్పత్తిని చూస్తాను. తర్వాత నేను ఉత్పత్తిని రిపేర్ చేస్తాను.",
            "or": "ହଁ, ମ୍ୟାଡାମ | ସମସ୍ୟା ଜାଣିବା ପାଇଁ ମୁଁ ଉତ୍ପାଦକୁ ଦେଖିବି | ତା’ପରେ ମୁଁ ଉତ୍ପାଦକୁ ମରାମତି କରିବି |",
            "as": "হয় মেডাম। সমস্যাটো জানিবলৈ মই বস্তুটো চাম। তাৰ পিছত মই বস্তুটো মেৰামতি কৰিম।",
            "gu": "હા મેડમ. હું તે વસ્તુ જોઇસ કે જેમાં ખામી છે. પછી જ તેને સરખી કરીશ."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_4_4//",
            "hi": "C4_4_4//",
            "ka": "C4_4_4//",
            "te": "C4_4_4//",
            "or": "C4_4_4//",
            "as": "C4_4_4//",
            "gu": "C4_4_4//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Interviewer",
            "hi": "इंटरव्यूवर",
            "ka": "ಸಂದರ್ಶಕ",
            "te": "ఇంటర్వ్యూయర్",
            "or": "ସାକ୍ଷାତକାରକାରୀ",
            "as": "সাক্ষাৎকাৰ লওঁতা",
            "gu": "ઇન્ટરવ્યુ લેનાર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Can you tell me why you are applying to work here?",
            "hi": "क्या तुम मुझे बता सकते हो कि, तुम यहाँ काम करने के लिए अप्लाई क्यों कर रहे हो?",
            "ka": "ನೀವು ಇಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಏಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಿದ್ದೀರಿ ಎಂದು ನನಗೆ ತಿಳಿಸುವಿರಾ?",
            "te": "మీరు ఇక్కడ పని చేయడానికి ఎందుకు దరఖాస్తు చేస్తున్నారో చెప్పగలరా?",
            "or": "ତୁମେ ମୋତେ କହିପାରିବ କି ତୁମେ ଏଠାରେ କାମ କରିବାକୁ କାହିଁକି ଆବେଦନ କରୁଛ |",
            "as": "মোক কোৱাচোন তুমি ইয়াত কাম কৰিবলৈ কিয় বিচাৰিছা?",
            "gu": "તું મને બતાવી શકે છે કે તું આ કામ માટે અરજી કરી છે?"
          }
        }
      ],
      "page12": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_4_4d",
            "hi": "C4_4_4d",
            "ka": "C4_4_4d",
            "te": "C4_4_4d",
            "or": "C4_4_4d",
            "as": "C4_4_4d",
            "gu": "C4_4_4d"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Naveen reply?",
            "hi": "नवीन को क्या उत्तर देना चाहिए?",
            "ka": "ನವೀನ್ ಏನು ಉತ್ತರಿಸಬೇಕು?",
            "te": "నవీన్ ఏం సమాధానం చెప్పాలి?",
            "or": "ନବୀନ କ’ଣ ଉତ୍ତର ଦେବା ଉଚିତ୍ ?",
            "as": "নবীনে কি উত্তৰ দিব?",
            "gu": "નવીનને શું જવાબ આપવો જોઇયે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "I’ve been working for a long time and have good experience with different products. I feel I am fit for this job.",
            "hi": "मैं बहुत सालों से काम कर रहा हूँ और मुझे अलग-अलग प्रोडक्ट का अच्छा अनुभव है। इसलिए मुझे लगता है कि, मैं इस जॉब के लिए सही उम्मीदवार हूँ।",
            "ka": "ನಾನು ಬಹಳ ಸಮಯದಿಂದ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೇನೆ ಮತ್ತು ವಿಭಿನ್ನ ಉತ್ಪನ್ನಗಳೊಂದಿಗೆ ಉತ್ತಮ ಅನುಭವವನ್ನು ಹೊಂದಿದ್ದೇನೆ. ನಾನು ಈ ಕೆಲಸಕ್ಕೆ ಫಿಟ್ ಎಂದು ಭಾವಿಸುತ್ತೇನೆ.",
            "te": "నేను చాలా కాలంగా పని చేస్తున్నాను మరియు విభిన్న ఉత్పత్తులతో మంచి అనుభవం ఉంది. నేను ఈ ఉద్యోగానికి సరిపోతానని ఫీల్ అవుతున్నాను.",
            "or": "ମୁଁ ଦୀର୍ଘ ସମୟ ଧରି କାମ କରୁଛି ଏବଂ ବିଭିନ୍ନ ଉତ୍ପାଦ ସହିତ ଭଲ ଅଭିଜ୍ଞତା ଅଛି | ମୁଁ ଅନୁଭବ କରୁଛି ଯେ ମୁଁ ଏହି କାମ ପାଇଁ ଫିଟ୍ ଅଟେ |",
            "as": "মই দীৰ্ঘদিন ধৰি কাম কৰি আছোঁ আৰু বিভিন্ন সামগ্ৰীৰ সৈতে মোৰ ভাল অভিজ্ঞতা আছে। মই ভাৱো যে মই এই কামটোৰ বাবে উপযুক্ত।",
            "gu": "હું આ કામમાં ગણા સમયથી કરી રહ્યો છું અને મને તેના વિષે સારો અનુભવ છે વિવિધ વસ્તુ વિષે. મને લાગે છે કે આ કામ માટે યોગ્ય છું."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Alliance Digital is known for its good customer service. It also offers an environment where youngsters like me get a chance to grow.",
            "hi": "अलायन्स डिजिटल ग्राहकों के सेवा के लिए बहुत लोकप्रिय है। और यहाँ मेरे जैसे नौजवानों को काम करके करियर बनाने का मौका दिया जाता है।",
            "ka": "ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್ ತನ್ನ ಉತ್ತಮ ಗ್ರಾಹಕ ಸೇವೆಗೆ ಹೆಸರುವಾಸಿಯಾಗಿದೆ. ಇದು ನನ್ನಂತಹ ಯುವಕರು ಬೆಳೆಯಲು ಅವಕಾಶವನ್ನು ಪಡೆಯುವ ವಾತಾವರಣವನ್ನು ನೀಡುತ್ತದೆ.",
            "te": "అలయన్స్ డిజిటల్ దాని మంచి కస్టమర్ సర్వీస్ కు ప్రసిద్ధి చెందింది. నాలాంటి యువకులు అభివృద్ధి అవ్వడానికి అవకాశం లభించే వాతావరణాన్ని కూడా ఇది అందిస్తుంది.",
            "or": "ଆଲାଇନ୍ସ ଡିଜିଟାଲ୍ ଏହାର ଭଲ ଗ୍ରାହକ ସେବା ପାଇଁ ଜଣାଶୁଣା | ଏହା ଏକ ପରିବେଶ ମଧ୍ୟ ପ୍ରଦାନ କରେ ଯେଉଁଠାରେ ମୋ ଭଳି ଯୁବକମାନେ ବଢିବାର ସୁଯୋଗ ପାଆନ୍ତି |",
            "as": "এলায়েন্স ডিজিটেল ইয়াৰ ভাল গ্ৰাহক সেৱাৰ বাবে জনাজাত। ই এনে এক পৰিৱেশো প্ৰদান কৰে য'ত মোৰ দৰে যুৱক-যুৱতীসকলে বিকাশৰ সুযোগ পায়।",
            "gu": "એલાયન્સ ડિજિટલ તેના ગ્રાહકોને સારી સેવા આપવા માટે જાણીતું છે. તે આવું પણ આપે છે કે નવજુવાનોને અહી પ્રગતિ કરવાનો એક તક મળી શકે છે."
          }
        }
      ],
      "page13": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_4_5//",
            "hi": "C4_4_5//",
            "ka": "C4_4_5//",
            "te": "C4_4_5//",
            "or": "C4_4_5//",
            "as": "C4_4_5//",
            "gu": "C4_4_5//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "label text",
            "hi": "लेबल टेक्स्ट",
            "ka": "ಲೇಬಲ್ ಪಠ್ಯ",
            "te": "లేబుల్ టెక్స్ట్",
            "or": "ଲେବଲ୍ ଟେକ୍ସଟ୍",
            "as": "লেবেল লিখনী",
            "gu": "શબ્દને લેબલ કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "According to you, what is your greatest strength?",
            "hi": "तुम्हारे मुताबिक तुम्हारी सबसे अच्छी खूबी क्या है?",
            "ka": "ನಿಮ್ಮ ಪ್ರಕಾರ, ನಿಮ್ಮ ದೊಡ್ಡ ಶಕ್ತಿ ಯಾವುದು?",
            "te": "మీ ప్రకారం, మీ గొప్ప బలం ఏమిటి?",
            "or": "ଆପଣଙ୍କ ଅନୁଯାୟୀ, ଆପଣଙ୍କର ସବୁଠାରୁ ବଡ ଶକ୍ତି କଣ ?",
            "as": "তোমাৰ মতে, তোমাৰ আটাইতকৈ ডাঙৰ শক্তি কি?",
            "gu": "તારા મત પ્રમાણે તારી સૌથી વધારે તાકાત શું છે?"
          }
        }
      ],
      "page14": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_4_5d",
            "hi": "C4_4_5d",
            "ka": "C4_4_5d",
            "te": "C4_4_5d",
            "or": "C4_4_5d",
            "as": "C4_4_5d",
            "gu": "C4_4_5d"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Naveen reply?",
            "hi": "नवीन को क्या उत्तर देना चाहिए?",
            "ka": "ನವೀನ್ ಏನು ಉತ್ತರಿಸಬೇಕು?",
            "te": "నవీన్ ఏం సమాధానం చెప్పాలి?",
            "or": "ନବୀନ କ’ଣ ଉତ୍ତର ଦେବା ଉଚିତ୍ ?",
            "as": "নবীনে কি উত্তৰ দিব?",
            "gu": "નવીનને શું જવાબ આપવો જોઇયે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "I am a fast learner and very adaptable to the work environment",
            "hi": "मैं काम को बहुत जल्दी सीखता हूँ और काम के माहौल में बहुत जल्दी घुल-मिल जाता हूँ।",
            "ka": "ನಾನು ವೇಗವಾಗಿ ಕಲಿಯುವವನು ಮತ್ತು ಕೆಲಸದ ವಾತಾವರಣಕ್ಕೆ ಹೊಂದಿಕೊಳ್ಳಬಲ್ಲವನು",
            "te": "నేను వేగంగా నేర్చుకునేవాడిని మరియు పని వాతావరణానికి చాలా అనుకూలతను కలిగి ఉన్నాను",
            "or": "ମୁଁ ଜଣେ ଦ୍ରୁତ ଶିକ୍ଷାର୍ଥୀ ଏବଂ କାର୍ଯ୍ୟ ପରିବେଶ ସହିତ ଅତ୍ୟନ୍ତ ପ୍ରୟୋଜନ ଅନୁଯାୟୀ ଅଟେ |",
            "as": "মই অতি সোনকালে শিকোঁ আৰু কামৰ পৰিৱেশৰ সৈতে খাপ খুৱাই ল'ব পাৰো ৷",
            "gu": "હું જલ્દી શિખનાર છું અને કામના વાતાવરણને જલ્દી સ્વીકારી લઉં છું"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "I have the practical knowledge of being a mechanic",
            "hi": "मेरे पास मैकेनिक का व्यवहारिक ज्ञान है।",
            "ka": "ನಾನು ಮೆಕ್ಯಾನಿಕ್ ಆಗಿರುವ ಪ್ರಾಯೋಗಿಕ ಜ್ಞಾನವನ್ನು ಹೊಂದಿದ್ದೇನೆ",
            "te": "నాకు మెకానిక్‌గా ప్రాక్టికల్ జ్ఞానం ఉంది",
            "or": "ମେକାନିକ୍ ହେବାର ମୋର ବ୍ୟବହାରିକ ଜ୍ଞାନ ଅଛି |",
            "as": "মেকানিক হোৱাৰ ব্যৱহাৰিক জ্ঞানো মোৰ আছে",
            "gu": "મને પ્રાયોગિક જ્ઞાન છે મેકેનિકના કામ તરીકે"
          }
        }
      ],
      "page17": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_4_7//",
            "hi": "C4_4_7//",
            "ka": "C4_4_7//",
            "te": "C4_4_7//",
            "or": "C4_4_7//",
            "as": "C4_4_7//",
            "gu": "C4_4_7//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Interviewer",
            "hi": "इंटरव्यूवर",
            "ka": "ಸಂದರ್ಶಕ",
            "te": "ఇంటర్వ్యూయర్",
            "or": "ସାକ୍ଷାତକାରକାରୀ",
            "as": "সাক্ষাৎকাৰ লওতা",
            "gu": "ઇન્ટરવ્યુ લેનાર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Good, Naveen, you seem like a good fit.  I’m happy to offer you this position.",
            "hi": "अच्छी बात है, नवीन, तुम अच्छे उम्मीदवार लग रहे हो। मुझे तुम्हें यह जॉब देते हुए खुशी हो रही है।",
            "ka": "ಚೆನ್ನಾಗಿದೆ, ನವೀನ್, ನೀವು ಚೆನ್ನಾಗಿ ಹೊಂದಿಕೊಳ್ಳುತ್ತೀರಿ. ನಿಮಗೆ ಈ ಸ್ಥಾನವನ್ನು ನೀಡಲು ನನಗೆ ಸಂತೋಷವಾಗಿದೆ.",
            "te": "బాగుంది, నవీన్, మీరు బాగా సరిపోతారనిపిస్తోంది. మీకు ఈ స్థానాన్ని అందించడం నాకు సంతోషంగా ఉంది.",
            "or": "ଭଲ, ନବୀନ, ତୁମେ ଏକ ଭଲ ଫିଟ୍ ପରି ଦେଖାଯାଉଛ | ମୁଁ ତୁମକୁ ଏହି ପଦବୀ ପ୍ରଦାନ କରିବାକୁ ଖୁସି ଅଟେ |",
            "as": "ভাল, নবীন, তুমি ফিট হ’বা যেন লাগিছে। মই তোমাক এই পদটো দিবলৈ পালে সুখী হ’ম।",
            "gu": "સરસ નવીન, લાગે છે કે તું કામ માટે યોગ્ય છે. મને ખુશી થસે કે હું તને આ જગ્યા માટે તને સ્વીકારું છું"
          }
        }
      ],
      "page19": [
        {
          "valuePath": "display",
          "value": {
            "en": "Interview",
            "hi": "इंटरव्यू",
            "ka": "ಸಂದರ್ಶನ",
            "te": "ఇంటర్వ్యూ",
            "or": "ସାକ୍ଷାତକାର",
            "as": "সাক্ষাৎকাৰ",
            "gu": "ઇન્ટરવ્યુ લેનાર"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Interview experience!",
            "hi": "आपने अभी-अभी इंटरव्यू का अनुभव प्राप्त किया हैं!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಸಂದರ್ಶನದ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే ఇంటర్వ్యూ అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ସାକ୍ଷାତକାର ଅଭିଜ୍ଞତା ହାସଲ କରିଛନ୍ତି !",
            "as": "তুমি এইমাত্ৰ সাক্ষাৎকাৰৰ অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "ઇન્ટરવ્યુનો અનુભવ મેળવ્યો!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Interview",
            "hi": "इंटरव्यू",
            "ka": "ಸಂದರ್ಶನ",
            "te": "ఇంటర్వ్యూ",
            "or": "ସାକ୍ଷାତକାର",
            "as": "সাক্ষাৎকাৰ",
            "gu": "ઇન્ટરવ્યુ લેનાર"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Suitability Assessment Tools",
            "hi": "उचित करियर के लिए मूल्यांकन टूल",
            "ka": "ವೃತ್ತಿ ಸೂಕ್ತತೆಯ ಮೌಲ್ಯಮಾಪನ ಪರಿಕರಗಳು",
            "te": "వృత్తి అనుకూలత అంచనా సాధనాలు",
            "or": "କ୍ଯାରିୟର୍‍ ସୁଇଟାବିଲିଟି ଆସେସ୍‌ମେଣ୍ଟ୍‌ ଟୁଲ୍ସ",
            "as": "জীৱিকাৰ উপযুক্ততা মূল্যায়ন সঁজুলি",
            "gu": "કારકિર્દી યોગ્યતા મૂલ્યાંકન સાધનો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Naveen gives an interview at the Alliance Digital store. An interview is usually part of the process of getting a job. It is a formal meeting to know if the applicant is suited for the role or not. It is essential to prepare for the interview and understand your aspirations for the job and the technical knowledge required for the position. During the interview as an applicant, you can better understand the job role and how the job will fit your career goals.",
            "hi": "नवीन अलायन्स डिजिटल में इंटरव्यू देता है। इंटरव्यू जॉब पाने की प्रक्रिया का एक भाग होता है। यह एक तरह की फॉर्मल मीटिंग होती है, जिससे यह पता चलता है कि, उम्मीदवार इस रोल के लिए उचित है या नहीं। इंटरव्यू देने के लिए तैयारी करना और इस जॉब से आपको क्या उम्मीदें हैं और इस पोजीशन के लिए कौन सा तकनीकी ज्ञान आवश्यक है, यह जानना महत्वपूर्ण होता है। जिसके कारण, इंटरव्यू के दौरान उम्मीदवार के तौर पर आप जॉब के रोल को और यह जॉब आपके करियर के लिए कैसे बेहतर साबित हो सकता है यह समझ सकते हैं।",
            "ka": "ನವೀನ್ ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್ ಸ್ಟೋರ್‌ನಲ್ಲಿ ಸಂದರ್ಶನವನ್ನು ನೀಡುತ್ತಾನೆ. ಸಂದರ್ಶನವು ಸಾಮಾನ್ಯವಾಗಿ ಉದ್ಯೋಗ ಪಡೆಯುವ ಪ್ರಕ್ರಿಯೆಯ ಭಾಗವಾಗಿದೆ. ಅರ್ಜಿದಾರರು ಪಾತ್ರಕ್ಕೆ ಸೂಕ್ತರೇ ಅಥವಾ ಇಲ್ಲವೇ ಎಂಬುದನ್ನು ತಿಳಿಯಲು ಇದು ಔಪಚಾರಿಕ ಸಭೆಯಾಗಿದೆ. ಸಂದರ್ಶನಕ್ಕೆ ತಯಾರಿ ಮಾಡುವುದು ಮತ್ತು ಉದ್ಯೋಗಕ್ಕಾಗಿ ನಿಮ್ಮ ಆಕಾಂಕ್ಷೆಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು ಮತ್ತು ಹುದ್ದೆಗೆ ಅಗತ್ಯವಾದ ತಾಂತ್ರಿಕ ಜ್ಞಾನವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು ಅತ್ಯಗತ್ಯ. ಅರ್ಜಿದಾರರಾಗಿ ಸಂದರ್ಶನದ ಸಮಯದಲ್ಲಿ, ನೀವು ಕೆಲಸದ ಪಾತ್ರವನ್ನು ಚೆನ್ನಾಗಿ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಬಹುದು ಮತ್ತು ನಿಮ್ಮ ವೃತ್ತಿಜೀವನದ ಗುರಿಗಳಿಗೆ ಉದ್ಯೋಗವು ಹೇಗೆ ಸರಿಹೊಂದುತ್ತದೆ.",
            "te": "అలయన్స్ డిజిటల్ స్టోర్‌లో నవీన్ ఇంటర్వ్యూ ఇచ్చాడు. ఇంటర్వ్యూ అనేది సాధారణంగా ఉద్యోగం పొందే ప్రక్రియలో భాగం. అభ్యర్థి పాత్రకు సరిపోతుందో లేదో తెలుసుకోవడానికి ఇది అధికారిక సమావేశం. ఇంటర్వ్యూకు సిద్ధం కావడం మరియు ఉద్యోగం కోసం మీ ఆకాంక్షలు మరియు స్థానానికి అవసరమైన సాంకేతిక పరిజ్ఞానాన్ని అర్థం చేసుకోవడం చాలా అవసరం. దరఖాస్తుదారుగా ఇంటర్వ్యూ సమయంలో, మీరు ఉద్యోగ పాత్రను మరియు ఉద్యోగం మీ కెరీర్ లక్ష్యాలకు ఎలా సరిపోతుందో బాగా అర్థం చేసుకోవచ్చు.",
            "or": "ନବୀନ ଆଲାଇନ୍ସ ଡିଜିଟାଲ୍ ଷ୍ଟୋର୍‌ରେ ଏକ ସାକ୍ଷାତକାର ଦିଅନ୍ତି | ଏକ ସାକ୍ଷାତକାର ସାଧାରଣତଃ ଏକ ଚାକିରି ପାଇବା ପ୍ରକ୍ରିୟାର ଏକ ଅଂଶ ଅଟେ | ଆବେଦନକାରୀ ଭୂମିକା ପାଇଁ ଉପଯୁକ୍ତ କି ନୁହେଁ ତାହା ଜାଣିବା ଏହା ଏକ ଆନୁଷ୍ଠାନିକ ବୈଠକ | ସାକ୍ଷାତକାର ପାଇଁ ପ୍ରସ୍ତୁତ ହେବା ଏବଂ ଚାକିରି ପାଇଁ ତୁମର ଆକାଂକ୍ଷା ଏବଂ ପଦ ପାଇଁ ଆବଶ୍ୟକ ବୈଷୟିକ ଜ୍ଞାନ ବୁଝିବା ଏକାନ୍ତ ଆବଶ୍ୟକ କରେ | ଜଣେ ଆବେଦନକାରୀ ଭାବରେ ସାକ୍ଷାତକାର ସମୟରେ, ତୁମେ ଚାକିରିର ଭୂମିକାକୁ ଭଲ ଭାବରେ ବୁଝିପାରିବ ଏବଂ ଚାକିରିଟି ତୁମର କ୍ୟାରିୟର ଲକ୍ଷ୍ୟକୁ କିପରି ଫିଟ୍ କରିବ |",
            "as": "নবীনে এলায়েন্স ডিজিটেল দোকানত এটা সাক্ষাৎকাৰ দিয়ে। সাক্ষাৎকাৰ হৈছে সাধাৰণতে চাকৰি এটা পোৱাৰ প্ৰক্ৰিয়াৰ অংশ। আবেদনকাৰীজন পদটোৰ বাবে উপযুক্ত হয় নে নহয় জানিবলৈ ই এখন আনুষ্ঠানিক সভা। সাক্ষাৎকাৰৰ বাবে প্ৰস্তুতি চলোৱা আৰু চাকৰিটোৰ বাবে তোমাৰ আকাংক্ষা আৰু পদটোৰ বাবে প্ৰয়োজনীয় কাৰিকৰী জ্ঞান বুজি পোৱাটো অত্যাৱশ্যকীয়। এজন আবেদনকাৰী হিচাপে সাক্ষাৎকাৰৰ সময়ত, তুমি চাকৰিৰ ভূমিকা আৰু চাকৰিটো তোমাৰ জীৱিকাৰ লক্ষ্যৰ সৈতে কেনেদৰে খাপ খাব সেয়া ভালদৰে বুজি পাব পাৰা।",
            "gu": "નવીન એલાયન્સ ડિજિટલ સ્ટોરમાં ઇન્ટરવ્યુ પૂરું કરે છે. ઇન્ટરવ્યુના લીધે નોકરી મેળવવાની સામાન્ય પ્રક્રિયાનો એક ભાગ હોય છે. અરજી કરનાર આ કામ માટે લાયક છે કે નહીં તે જાણવા માટે તે વાર્તાલાભ થાય છે. ઇન્ટરવ્યુની તૈયારી કરવી અને નોકરી માટેની તમારી પ્રાણવિધાઓ અને પદ માટે જરૂરી તકનીકી જ્ઞાનને સમજવું જરૂરી છે. અરજી કરનાર તરીકે ઇન્ટરવ્યુ દરમિયાન, તમે નોકરીનું કામ અને નોકરી તમારા કારકિર્દીના લક્ષ્યોને કેવી રીતે લાયક છે કે નહીં તે વધુ સારી રીતે સમજી શકશો."
          }
        }
      ],
      "page18": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_4_8",
            "hi": "C4_4_8",
            "ka": "C4_4_8",
            "te": "C4_4_8",
            "or": "C4_4_8",
            "as": "C4_4_8",
            "gu": "C4_4_8"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen thanks the interviewer and tells her that he will get back to them after some consideration.",
            "hi": "नवीन इंटरव्यू लेने वाली महिला को धन्यवाद कहता है और उसे बताता है कि, वह विचार करके उत्तर देगा।",
            "ka": "ನವೀನ್ ಸಂದರ್ಶಕರಿಗೆ ಧನ್ಯವಾದಗಳನ್ನು ಅರ್ಪಿಸಿದನು ಮತ್ತು ಸ್ವಲ್ಪ ಪರಿಗಣನೆಯ ನಂತರ ಅವನನ್ನು ಹಿಂತಿರುಗಿಸುವುದಾಗಿ ಹೇಳಿದರು.",
            "te": "నవీన్ ఇంటర్వ్యూయర్‌కి ధన్యవాదాలు చెప్పాడు మరియు కొంచెం ఆలోచించుకొని తిరిగి వస్తానని అతను ఆమెకు చెప్పాడు.",
            "or": "ନବୀନ ସାକ୍ଷାତକାରକାରୀକୁ ଧନ୍ୟବାଦ ଜଣାନ୍ତି ଏବଂ ତାଙ୍କୁ କହନ୍ତି ଯେ କିଛି ବିଚାର ପରେ ସେ ସେମାନଙ୍କ ନିକଟକୁ ଫେରିବେ |",
            "as": "নবীনে সাক্ষাৎকাৰ লওতাক ধন্যবাদ জনালে আৰু তেখেতক ক’লে যে সি কিছুমান বিবেচনাৰ পিছতহে তেওঁলোকৰ ওচৰলৈ আহিব।",
            "gu": "નવીન ઇન્ટરવ્યૂ લેનાર ને ધન્યવાદ કહે છે અને એ પણ જણાવે છે કે તે થોડા સમય પછી વિચારીને તેમને મળશે."
          }
        }
      ],
      "page20": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_2_4",
            "hi": "C4_2_4",
            "ka": "C4_2_4",
            "te": "C4_2_4",
            "or": "C4_2_4",
            "as": "C4_2_4",
            "gu": "C4_2_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "When he returns to the electronic store, his employer tells him that he has decided to promote him and give him a decent raise in his salary.",
            "hi": "जब वह इलेक्ट्रॉनिक स्टोअर वापस जाता है, तब उसके मालिक उसे कहते हैं कि, उन्होंने उसे प्रमोशन देने का निर्णय लिया है और उसे अच्छी सैलरी भी दी जाएगी।",
            "ka": "ಅವನು ಎಲೆಕ್ಟ್ರಾನಿಕ್ ಅಂಗಡಿಗೆ ಹಿಂದಿರುಗಿದಾಗ, ಅವನ ಉದ್ಯೋಗದಾತನು ಅವನಿಗೆ ಬಡ್ತಿ ನೀಡಲು ಮತ್ತು ಅವನ ಸಂಬಳದಲ್ಲಿ ಯೋಗ್ಯವಾದ ಹೆಚ್ಚಳವನ್ನು ನೀಡಲು ನಿರ್ಧರಿಸಿದೆ ಎಂದು ಹೇಳುತ್ತಾನೆ.",
            "te": "అతను ఎలక్ట్రానిక్ షాప్ కి తిరిగి వచ్చినప్పుడు, అతని ఎంప్లాయర్ అతనికి ప్రమోషన్ ఇవ్వాలని మరియు అతని జీతం పెంచాలని నిర్ణయించుకున్నట్లు చెప్పాడు.",
            "or": "ଯେତେବେଳେ ସେ ଇଲେକ୍ଟ୍ରୋନିକ୍ ଷ୍ଟୋର୍‌କୁ ଫେରନ୍ତି, ତାଙ୍କ ନିଯୁକ୍ତିଦାତା ତାଙ୍କୁ କହନ୍ତି ଯେ ସେ ତାଙ୍କୁ ପଦୋନ୍ନତି ଦେବା ଏବଂ ତାଙ୍କ ବେତନରେ ଏକ ଉପଯୁକ୍ତ ବୃଦ୍ଧି କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
            "as": "যেতিয়া সি ইলেক্ট্ৰনিক দোকানলৈ ঘূৰি আহে,তাৰ নিয়োগকৰ্তাই তাক জনালে যে তাৰ পদোন্নতি কৰা হৈছে আৰু তাৰ দৰমহাও ভালেখিনি বঢ়াই দিয়া হ’ব।",
            "gu": "જ્યારે તે ઈલેક્ટ્રોનિક સ્ટોર પર પાછો આવે છે, એના માલિકે તેને એમ જણાવે છે કે તેનો પગારમાં વધારો કરી આપ્યો છે અને ઉપરી લયકાત આપી છે."
          }
        }
      ],
      "page21": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_1_2",
            "hi": "C4_1_2",
            "ka": "C4_1_2",
            "te": "C4_1_2",
            "or": "C4_1_2",
            "as": "C4_1_2",
            "gu": "C4_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen is confused. He approaches Omi’s father to seek advice.",
            "hi": "नवीन कन्फ्यूज होता है। वह ओमी के पिता के पास सलाह लेने के लिए जाता है।",
            "ka": "ನವೀನ್ ಗೊಂದಲದಲ್ಲಿದ್ದಾರೆ. ಅವನು ಸಲಹೆ ಪಡೆಯಲು ಓಮಿಯ ತಂದೆಯನ್ನು ಸಂಪರ್ಕಿಸುತ್ತಾನೆ.",
            "te": "నవీన్ అయోమయంలో పడ్డాడు. అతను సలహా కోసం ఓమి తండ్రి దగ్గరకు వెళ్తాడు.",
            "or": "ନବୀନ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛନ୍ତି | ପରାମର୍ଶ ନେବା ପାଇଁ ସେ ଓମିଙ୍କ ପିତାଙ୍କ ନିକଟକୁ ଯାଆନ୍ତି |",
            "as": "নবীন বিমোৰত পৰিল ৷ সি পৰামৰ্শ ল'বলৈ অমিৰ দেউতাকৰ ওচৰলৈ গ’ল।",
            "gu": "નવીન મુંજવાનમાં ભરાઈ જાય છે. તેને ઓમિસના પિતા જોડે સલાહ લેવા જાય છે."
          }
        }
      ],
      "page22": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_1_3//",
            "hi": "C4_1_3//",
            "ka": "C4_1_3//",
            "te": "C4_1_3//",
            "or": "C4_1_3//",
            "as": "C4_1_3//",
            "gu": "C4_1_3//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Omi's Dad",
            "hi": "ओमी के पिता",
            "ka": "ಓಮಿಯ ತಂದೆ",
            "te": "ఓమి తండ్రి",
            "or": "ଓମିଙ୍କ ପିତା",
            "as": "অমিৰ দেউতাক",
            "gu": "ઓમિસના પિતા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "So you’re getting a higher pay now at the store, but you’re confused because Alliance Digital is a big brand.",
            "hi": "तो अब तुम्हें स्टोअर में अच्छी सैलरी दी जाएगी, लेकिन तुम कंफ्यूज हो क्योंकि, अलायन्स डिजिटल एक बहुत बड़ा ब्रांड है।",
            "ka": "ಆದ್ದರಿಂದ ನೀವು ಈಗ ಅಂಗಡಿಯಲ್ಲಿ ಹೆಚ್ಚಿನ ವೇತನವನ್ನು ಪಡೆಯುತ್ತಿರುವಿರಿ, ಆದರೆ ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್ ದೊಡ್ಡ ಬ್ರ್ಯಾಂಡ್ ಆಗಿರುವುದರಿಂದ ನೀವು ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದೀರಿ.",
            "te": "అంటే ఇపుడు నీకు స్టోర్‌లో ఎక్కువ జీతం వస్తుంది, కానీ అలయన్స్ డిజిటల్ పెద్ద బ్రాండ్ అయినందున నువ్వు అయోమయంగా ఉన్నావు.",
            "or": "ତେବେ ତୁମେ ବର୍ତ୍ତମାନ ଷ୍ଟୋର୍‌ରେ ଅଧିକ ବେତନ ପାଉଛ, କିନ୍ତୁ ତୁମେ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛ କାରଣ ଆଲାଇନ୍ସ ଡିଜିଟାଲ୍ ଏକ ବଡ ବ୍ରାଣ୍ଡ |",
            "as": "গতিকে তুমি এতিয়া দোকানত বেছি দৰমহা পাবা, কিন্তু বিমোৰত পৰিছা কিয়নো এলায়েন্স ডিজিটেল এটা ডাঙৰ ব্ৰেণ্ড।",
            "gu": "તો તને હવે વધારે પગાર આ દુકાન પર મળે છે, પણ તને મુંજવન છે કે એલાયન્સ ડિજિટલ એક મોટી બ્રાન્ડ છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Yes, but my employer has also promoted me, whereas in Alliance, I’ll be working as a Junior Mechanic again.",
            "hi": "और मेरे मालिक ने मुझे प्रमोशन भी दिया है, लेकिन अलायन्स में मुझे फिर से ज्युनियर मैकेनिक के तौर पर काम करना होगा।",
            "ka": "ಹೌದು, ಆದರೆ ನನ್ನ ಉದ್ಯೋಗದಾತರೂ ನನಗೆ ಬಡ್ತಿ ನೀಡಿದ್ದಾರೆ, ಆದರೆ ಅಲಯನ್ಸ್‌ನಲ್ಲಿ ನಾನು ಮತ್ತೆ ಜೂನಿಯರ್ ಮೆಕ್ಯಾನಿಕ್ ಆಗಿ ಕೆಲಸ ಮಾಡುತ್ತೇನೆ.",
            "te": "అవును, కానీ నా ఎంప్లాయర్ నాకు ప్రమోషన్ కూడా ఇచ్చారు, అయితే అలయన్స్ లో, నేను మళ్లీ జూనియర్ మెకానిక్‌గా పని చేయాలి.",
            "or": "ହଁ, କିନ୍ତୁ ମୋର ନିଯୁକ୍ତିଦାତା ମୋତେ ପଦୋନ୍ନତି ମଧ୍ୟ ଦେଇଛନ୍ତି, କିନ୍ତୁ ଆଲାଇନ୍ସ ମୁଁ ପୁଣି ଏକ ଜୁନିଅର ମେକାନିକ୍ ଭାବରେ କାମ କରିବି |",
            "as": "হয়, মোৰ নিয়োগকৰ্তাই মোক পদোন্নতিও দিছে, আনহাতে এলায়েন্সত, মই পুনৰ জুনিয়ৰ মেকানিক হিচাপে কাম কৰিব লাগিব।",
            "gu": "હા, પણ મારા માલિકે મારો વધારો પણ કર્યો છે પગારમાં, જ્યારે એલાયન્સમાં, હું ફરીથી એક જુનિયર મેકેનિક તરીકે કામ કરીશ."
          }
        }
      ],
      "page23": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_1_4////",
            "hi": "C4_1_4////",
            "ka": "C4_1_4////",
            "te": "C4_1_4////",
            "or": "C4_1_4////",
            "as": "C4_1_4////",
            "gu": "C4_1_4////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Hmm... Though at Alliance, there’ll be more opportunities for you to grow in your career.",
            "hi": "हम्म….लेकिन अलायन्स में, तुम्हें करियर बेहतर करने के कई अवसर मिलेंगे.",
            "ka": "ಹಾಂ... ಅಲಯನ್ಸ್‌ನಲ್ಲಿದ್ದರೂ, ನಿಮ್ಮ ವೃತ್ತಿಜೀವನದಲ್ಲಿ ನೀವು ಬೆಳೆಯಲು ಹೆಚ್ಚಿನ ಅವಕಾಶಗಳಿವೆ.",
            "te": "అయ్యో... అలయన్స్ లో అయినా, మీరు మీ కెరీర్‌లో ఎదగడానికి ఇంకా అవకాశాలు ఉంటాయి.",
            "or": "ହମ୍ ... ଯଦିଓ ଆଲାୟନ୍ସରେ, ତୁମ କ୍ୟାରିୟର୍‌ରେ ବଢିବା ‍ପାଇଁ ତୁମକୁ ଅଧିକ ସୁଯୋଗ ମିଳିବ |",
            "as": "হুমম... কিন্তু এলায়েন্সত, তোমাৰ জীৱিকা বিকাশৰ সুযোগ বেছি থাকিব।",
            "gu": "બરાબર.. તેમ છતાં અલાયન્સમાં તારા કારકિર્દીમાં વૃદ્ધિ થવાની વધુ તકો છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "That’s true. But at a big store like Alliance, the work might be more hectic. But here, I’ve gotten comfortable with my employer and the work.",
            "hi": "यह तो सच बात है. लेकिन, अलायन्स जैसे बड़े स्टोअर में काम भी बहुत ज्यादा होगा और यहाँ मैं अपने मालिक और काम के साथ कंफर्टेबल हूँ.",
            "ka": "ಅದು ಸತ್ಯ. ಆದರೆ ಅಲೈಯನ್ಸ್‌ನಂತಹ ದೊಡ್ಡ ಅಂಗಡಿಯಲ್ಲಿ ಕೆಲಸವು ಹೆಚ್ಚು ಬಿರುಸಾಗಿರಬಹುದು. ಆದರೆ ಇಲ್ಲಿ, ನನ್ನ ಉದ್ಯೋಗದಾತ ಮತ್ತು ಕೆಲಸದೊಂದಿಗೆ ನಾನು ಆರಾಮದಾಯಕವಾಗಿದ್ದೇನೆ.",
            "te": "అది నిజం. కానీ అలయన్స్ వంటి పెద్ద షాప్ లో, పని ఇంకా ఒత్తిడి అవుతుంది. కానీ ఇక్కడ, నేను నా ఎంప్లాయర్ మరియు పని లో సౌకర్యంగా ఉన్నాను.",
            "or": "ତାହା ସତ୍ୟ ଅଟେ | କିନ୍ତୁ ଆଲାଇନ୍ସ ପରି ଏକ ବଡ ଷ୍ଟୋର୍‌ରେ, କାମ ଅଧିକ ବ୍ୟସ୍ତବହୂଳ ହୋଇପାରେ | କିନ୍ତୁ ଏଠାରେ, ମୁଁ ମୋର ନିଯୁକ୍ତିଦାତା ଏବଂ କାର୍ଯ୍ୟ ସହିତ ଆରାମ ରେ ଅଛି |",
            "as": "সেয়া সঁচা। কিন্তু এলায়েন্সৰ দৰে ডাঙৰ দোকান এখনত, কামটো অধিক কষ্টকৰ হ'ব। কিন্তু ইয়াত, মোৰ কামটো যথেষ্ট আৰামদায়ক।",
            "gu": "તે સાચું છે. પણ મોટા સ્ટોર જેવા કે અલાયન્સમાં કામ વધુ હશે. અને અહી હું સરળતા મારા માલિક સાથે અને કામ સાથે અનુભવું છું."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Look, Naveen, there are positives and negatives in every such decision. Sometimes what is important is to listen to your heart and make a call.",
            "hi": "देखो, नवीन, हर निर्णय के कुछ पॉजिटिव और नेगेटिव साइड होते हैं. कभी-कभी अपने दिल की बात सुनना भी महत्वपूर्ण होता है.",
            "ka": "ನೋಡಿ ನವೀನ್, ಅಂತಹ ಪ್ರತಿಯೊಂದು ನಿರ್ಧಾರದಲ್ಲೂ ಧನಾತ್ಮಕ ಮತ್ತು ನಕಾರಾತ್ಮಕ ಅಂಶಗಳಿವೆ. ಕೆಲವೊಮ್ಮೆ ನಿಮ್ಮ ಹೃದಯವನ್ನು ಆಲಿಸುವುದು ಮತ್ತು ನಿರ್ಧಾರ ಮಾಡುವುದು ಮುಖ್ಯವಾದುದು.",
            "te": "చూడు, నవీన్, ఇలాంటి ప్రతి నిర్ణయంలో పాజిటివ్స్ (సానుకూలతలు) మరియు నెగెటివ్స్ (ప్రతికూలతలు) ఉంటాయి. కొన్నిసార్లు మీ మనసు చెప్పేది వినడం ముఖ్యం.",
            "or": "ଦେଖ, ନବୀନ, ଏହିପରି ପ୍ରତ୍ୟେକ ନିଷ୍ପତ୍ତିରେ ସକରାତ୍ମକ ଏବଂ ନକାରାତ୍ମକତା ଅଛି | ବେଳେବେଳେ ଯାହା ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ତାହା ହେଉଛି ତୁମର ହୃଦୟକୁ ଶୁଣିବା ଏବଂ କଲ୍ କରିବା |",
            "as": "চোৱা, নবীন, এনেকুৱা সিদ্ধান্তত ভাল বেয়া দুয়োতা থাকে। কেতিয়াবা তোমাৰ হৃদয়ৰ কথা মতে সিদ্ধান্ত লোৱাতো জৰুৰী ।",
            "gu": "જો નવીન, ફાઇદા અને ગેરફાઇદા બંને હોય છે આ નિર્ણયમાં. પણ તું તારા મનને શાંતિથી પૂછ કે તારા માટે શું યોગ્ય છે."
          }
        }
      ],
      "page24": [
        {
          "valuePath": "display",
          "value": {
            "en": "C4_1_5_D",
            "hi": "C4_1_5_D",
            "ka": "C4_1_5_D",
            "te": "C4_1_5_D",
            "or": "C4_1_5_D",
            "as": "C4_1_5_D",
            "gu": "C4_1_5_D"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Naveen do?",
            "hi": "नवीन को क्या करना चाहिए?",
            "ka": "ನವೀನ್ ಏನು ಮಾಡಬೇಕು?",
            "te": "నవీన్ ఏం చేయాలి?",
            "or": "ନବୀନ କଣ କରିବା ଉଚିତ୍ ?",
            "as": "নবীনে কি কৰা ভাল হ’ব?",
            "gu": "નવીને શું કરવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Stay back at the store",
            "hi": "स्टोअर में काम करना जारी रखना चाहिए",
            "ka": "ಮತ್ತೆ ಅಂಗಡಿಯಲ್ಲಿ ಇರಿ",
            "te": "షాప్ లోనే ఉండాలి",
            "or": "ଦୋକାନରେ ରୁହନ୍ତୁ",
            "as": "দোকানৰ কামটোতে থাকক",
            "gu": "સ્ટોર પર જ કામ કરવું જોઇયે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Join Alliance Digital",
            "hi": "अलायन्स डिजिटल का जॉब स्वीकार करना चाहिए.",
            "ka": "ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್‌ಗೆ ಸೇರಿ",
            "te": "అలయన్స్ డిజిటల్‌లో చేరాలి",
            "or": "ଆଲିଆନ୍ସ ଡିଜିଟାଲରେ ଯୋଗ ଦିଅନ୍ତୁ",
            "as": "এলায়েন্স ডিজিটেলত যোগদান কৰক",
            "gu": "યા એલાયન્સ ડિજિટલમાં જોડાવું જોઈએ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.tag",
          "value": {
            "en": "Stability",
            "hi": "स्थिरता",
            "ka": "ಸ್ಥಿರತೆ",
            "te": "స్థిరత్వం",
            "or": "ସ୍ଥିରତା",
            "as": "স্থিৰতা",
            "gu": "સ્થિરતા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.tag",
          "value": {
            "en": "Career growth",
            "hi": "करियर वृद्धि",
            "ka": "ವೃತ್ತಿ ಬೆಳವಣಿಗೆ",
            "te": "కెరీర్ అభివృద్ధి",
            "or": "କ୍ଯାରିୟର୍‌ ଅଭିବୃଦ୍ଧି",
            "as": "জীৱিকাৰ বিকাশ",
            "gu": "કારકિર્દી વૃદ્ધિ"
          }
        }
      ]
    },
    "scene17": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C5_1_1_Stay back at the store",
            "hi": "स्टोअर में काम करना जारी रखना",
            "ka": "C5_1_1_ಮತ್ತೆ ಅಂಗಡಿಯಲ್ಲಿ ಇರಿ",
            "te": "C5_1_1_స్టోర్ లోనే ఉండాలి",
            "or": "C5_1_1_Stay back at the store",
            "as": "C5_1_1_দোকানৰ কামটোতে থাকক",
            "gu": "C5_1_1_સ્ટોર પર પાછા રહેવું જોઈએ"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen continues working at the store. The employer’s decision to give him a raise makes him feel valued, and he enjoys working as a senior mechanic.",
            "hi": "नवीन स्टोर में काम करना जारी रखता है. मालिक के सैलरी बढ़ाने के कारण उसे लगता है कि यहाँ उसके काम की सराहना की जाती है और सीनियर मैकेनिक के तौर पर काम करना उसे अच्छा लगता है.",
            "ka": "ನವೀನ್ ಅಂಗಡಿಯಲ್ಲಿ ಕೆಲಸ ಮುಂದುವರೆಸಿದ್ದಾನೆ. ಉದ್ಯೋಗದಾತನು ಅವನಿಗೆ ಹೆಚ್ಚಳವನ್ನು ನೀಡುವ ನಿರ್ಧಾರವು ಅವನನ್ನು ಮೌಲ್ಯಯುತವಾಗಿಸುತ್ತದೆ ಮತ್ತು ಅವನು ಹಿರಿಯ ಮೆಕ್ಯಾನಿಕ್ ಆಗಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಆನಂದಿಸುತ್ತಾನೆ.",
            "te": "నవీన్ షాప్ లోనే పని చేస్తున్నాడు. అతనికి జీతం పెంచాలని ఎంప్లాయర్ తీసుకున్న నిర్ణయం అతనికి విలువైన ఫీలింగ్ కలిగిస్తుంది మరియు అతను సీనియర్ మెకానిక్‌గా పని చేయడం సంతోషిస్తున్నాడు.",
            "or": "ନବୀନ ଦୋକାନରେ କାମ ଜାରି ରଖିଛନ୍ତି | ତାଙ୍କୁ ନିଯୁକ୍ତିଦାତାର ଦରମା ବଢାଇବା ନିଷ୍ପତ୍ତି ତାଙ୍କୁ ମୂଲ୍ୟବାନ ମନେ କରାଏ, ଏବଂ ସେ ଜଣେ ସିନିୟର ମେକାନିକ୍ ଭାବରେ କାମ କରିବା ଉପଭୋଗ କରନ୍ତି |",
            "as": "নবীনে দোকানখনতে কাম কৰি আছে। নিয়োগকৰ্তাই তাৰ পদোন্নতি কৰা বাবে সি নিজকে মূল্যৱান বুলি ভাৱিছে, আৰু এজন জ্যেষ্ঠ মেকানিক হিচাপে কাম কৰি সি ভাল পাইছে।",
            "gu": "નવીન સ્ટોર પર કામ કરવાનું ચાલુ રાખે છે. માલિક તેને વધારે પગાર આપવાનું નક્કી કરે છે અને તેને તેનું માન વધે એવું અનુભાવે છે અને તે ઉપરી મેકેનિક તરીકે કામ કરે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C5_1_2",
            "hi": "C5_1_2",
            "ka": "C5_1_2",
            "te": "C5_1_2",
            "or": "C5_1_2",
            "as": "C5_1_2",
            "gu": "C5_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "It is a small independent business, the kind of work he gets is limited. Last month his pay got delayed because the store couldn’t make enough profits.",
            "hi": "यह एक छोटा सा स्वतंत्र व्यवसाय है जहाँ उसे सीमित मात्रा में काम करना पड़ता है. पिछले महीने उससे सैलरी देर से मिली थी, क्योंकि स्टोअर को ज्यादा मुनाफा नहीं हुआ था.",
            "ka": "ಇದು ಸಣ್ಣ ಸ್ವತಂತ್ರ ವ್ಯವಹಾರವಾಗಿದೆ, ಅವನಿಗೆ ಸಿಗುವ ಕೆಲಸವು ಸೀಮಿತವಾಗಿದೆ. ಅಂಗಡಿಯವರು ಸಾಕಷ್ಟು ಲಾಭ ಗಳಿಸದ ಕಾರಣ ಕಳೆದ ತಿಂಗಳು ಅವನ ವೇತನ ವಿಳಂಬವಾಯಿತು.",
            "te": "ఇది చిన్న స్వతంత్ర వ్యాపారం, అతనికి వచ్చే పని తక్కువ. షాప్ కి తగినంత లాభాలు రాలేకపోయినందున పోయిన నెల అతని జీతం ఆలస్యం అయింది.",
            "or": "ଏହା ଏକ ଛୋଟ ସ୍ୱାଧୀନ ବ୍ୟବସାୟ, ସେ ଯେଉଁ ପ୍ରକାରର କାମ ପାଇଥାନ୍ତି ତାହା ସୀମିତ ଅଟେ | ଗତ ମାସରେ ତାଙ୍କ ଦରମା ବିଳମ୍ବ ହୋଇଗଲା କାରଣ ଷ୍ଟୋର୍‌ଟି ପର୍ଯ୍ୟାପ୍ତ ଲାଭ କରିପାରିଲା ନାହିଁ |",
            "as": "এইটো এটা সৰু স্বতন্ত্ৰ ব্যৱসায়, সি যি বোৰ কাম পায় সেয়া সীমিত। যোৱা মাহত সি দৰমহা দেৰিকৈ পাইছিল কাৰণ দোকানখনে পৰ্যাপ্ত লাভ কৰিব নোৱাৰিলে।",
            "gu": "તે એક નાનો સ્વતંત્ર ધંધો છે, અને ત્યાં ઓછા પ્રમાણમાં કામ મળે હોય છે. ગયા મહિને તેના પગારમાં મોડો મળ્યો કારણ કે સ્ટોર પર સારો ફાઈદો ન હતો."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "C5_2_1",
            "hi": "C5_2_1",
            "ka": "C5_2_1",
            "te": "C5_2_1",
            "or": "C5_2_1",
            "as": "C5_2_1",
            "gu": "C5_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "However, as a senior employee, he learns many new aspects of the business. He understands how to file taxes, buys and maintain the store inventory.",
            "hi": "लेकिन, सीनियर कर्मचारी के तौर पर वह व्यवसाय के बहुत सारे पहलुओं को सीख चूका है. अब उसे टैक्स कैसे फाइल करते हैं और स्टोअर की इन्वेंटरी को कैसे खरीदतें और संभालते हैं यह सीख चुका है.",
            "ka": "ಆದಾಗ್ಯೂ, ಹಿರಿಯ ಉದ್ಯೋಗಿಯಾಗಿ, ಅವನು ವ್ಯವಹಾರದ ಅನೇಕ ಹೊಸ ಅಂಶಗಳನ್ನು ಕಲಿಯುತ್ತಾನೆ. ತೆರಿಗೆಗಳನ್ನು ಹೇಗೆ ಸಲ್ಲಿಸುವುದು, ಅಂಗಡಿ ದಾಸ್ತಾನುಗಳನ್ನು ಖರೀದಿಸುವುದು ಮತ್ತು ನಿರ್ವಹಿಸುವುದು ಹೇಗೆ ಎಂದು ಅವನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾನೆ.",
            "te": "అయితే, ఒక సీనియర్ ఉద్యోగిగా, అతను వ్యాపారం యొక్క అనేక కొత్త అంశాలను నేర్చుకుంటాడు. అతను పన్నులను ఎలా ఫైల్ చేయాలో, స్టోర్ ఇన్వెంటరీని ఎలా కొనుగోలు చేయాలో మరియు నిర్వహించాలో అర్థం చేసుకున్నాడు.",
            "or": "ତଥାପି, ଜଣେ ବରିଷ୍ଠ କର୍ମଚାରୀ ଭାବରେ ସେ ବ୍ୟବସାୟର ଅନେକ ନୂତନ ଦିଗ ଶିଖନ୍ତି | ସେ ବୁଝନ୍ତି ଯେ କିପରି ଟ୍ୟାକ୍ସ ଦାଖଲ କରାଯାଏ, କ୍ରୟ କରାଯାଏ ଏବଂ ଷ୍ଟୋର୍‌ର ସବିଶେଷ ତାଲିକା ପରିଚାଳନା କରାଯାଏ |",
            "as": "অৱশ্যে, এজন জ্যেষ্ঠ কৰ্মচাৰী হিচাপে, সি ব্যৱসায়ৰ বহুতো নতুন দিশ শিকিছে। সি কৰ কেনেকৈ দাখিল কৰিব লাগে, দোকানৰ ইনভেণ্টৰী কেনেদৰে কিনা আৰু বাহাল ৰাখিব লাগে বুজি পায়।",
            "gu": "તેમ છતાં ઉપરી કામદાર તરીકે તે ધંધા વિષે ગણું નવું શીખે છે. તે એ આપણ જાણે છે ટેક્સ ફાઇલ કરવો, ખરીદી કરવી અને સ્ટોરની યાદી કેવી રીતે જાળવવી."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C5_2_2",
            "hi": "C5_2_2",
            "ka": "C5_2_2",
            "te": "C5_2_2",
            "or": "C5_2_2",
            "as": "C5_2_2",
            "gu": "C5_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen builds a good relationship with his employer and clients. His family is happy that he is consistent with his job.",
            "hi": "नवीन ने अपने मालिक और ग्राहकों के साथ अच्छे संबंध बना लिए हैं. वह एक ही जॉब पर टिका हुआ है इसके कारण उसका परिवार भी खुश है क्योंकि वह एक अच्छा जॉब कर रहा है.",
            "ka": "ನವೀನ್ ತನ್ನ ಉದ್ಯೋಗದಾತ ಮತ್ತು ಗ್ರಾಹಕರೊಂದಿಗೆ ಉತ್ತಮ ಸಂಬಂಧವನ್ನು ಬೆಳೆಸುತ್ತಾನೆ. ಅವನು ತನ್ನ ಕೆಲಸದಲ್ಲಿ ಸ್ಥಿರವಾಗಿರುವುದಕ್ಕೆ ಅವನ ಕುಟುಂಬವು ಸಂತೋಷವಾಗಿದೆ.",
            "te": "నవీన్ తన యజమాని మరియు ఖాతాదారులతో మంచి సంబంధాన్ని ఏర్పరుచుకుంటాడు. అతను తన ఉద్యోగంలో స్థిరంగా ఉన్నాడని అతని కుటుంబం సంతోషంగా ఉంది.",
            "or": "ନବୀନ ତାଙ୍କ ନିଯୁକ୍ତିଦାତା ଏବଂ କ୍ଳାଏଣ୍ଟ‍ମାନଙ୍କ ସହିତ ଏକ ଭଲ ସମ୍ପର୍କ ସ୍ଥାପନ କରନ୍ତି | ସେ ତାଙ୍କ ଚାକିରି ସହିତ ଅନୁରୂପ ଥିବାରୁ ତାଙ୍କ ପରିବାର ଖୁସି ଅଛନ୍ତି |",
            "as": "নবীনে তেওঁৰ নিয়োগকৰ্তা আৰু গ্ৰাহকৰ সৈতে এটা ভাল সম্পৰ্ক গঢ়ি তোলিছে। চাকৰিটো নিয়মীয়াকৈ কৰি থকা বাবে তাৰ পৰিয়ালো সুখী ৷",
            "gu": "નવીન સારા સંબંધ બનાવે છે એના માલિક જોડે અને ગ્રાહકો જોડે. તેનો પરિવાર ખુશ થાય છે કે તે એકધારું નોકરી કરે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "C5_2_3",
            "hi": "C5_2_3",
            "ka": "C5_2_3",
            "te": "C5_2_3",
            "or": "C5_2_3",
            "as": "C5_2_3",
            "gu": "C5_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The owner trusts Naveen and has given him full responsibility of running the shop. Naveen is also very comfortable working in the showroom and does not feel like looking for jobs elsewhere.",
            "hi": "मालिक को नवीन पर पूरा भरोसा है और इसलिए उन्होंने दुकान चलाने की पूरी जिम्मेदारी उसे सौंपी है. नवीन भी इस शोरूम के काम को लेकर कम्फर्टेबल है और वह कहीं और जॉब नहीं करना चाहता.",
            "ka": "ಮಾಲೀಕರು ನವೀನ್ ಮೇಲೆ ನಂಬಿಕೆ ಇಟ್ಟು ಅಂಗಡಿ ನಡೆಸುವ ಸಂಪೂರ್ಣ ಜವಾಬ್ದಾರಿ ನೀಡಿದ್ದಾರೆ. ನವೀನ್‌ಗೆ ಶೋರೂಮ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದು ತುಂಬಾ ಆರಾಮದಾಯಕವಾಗಿದೆ ಮತ್ತು ಬೇರೆಡೆ ಕೆಲಸ ಹುಡುಕಲು ಅನಿಸುವುದಿಲ್ಲ.",
            "te": "ఓనర్ నవీన్‌ను నమ్మి షాప్ నడిపే పూర్తి బాధ్యత అతనికి అప్పగించాడు. నవీన్‌కి కూడా షోరూమ్‌లో పని చేయడం చాలా సౌకర్యంగా ఉంటుంది మరియు వేరే చోట ఉద్యోగాలు వెతకాలని అనుకోవడం లేదు.",
            "or": "ମାଲିକ ନବୀନଙ୍କୁ ବିଶ୍ୱାସ କରନ୍ତି ଏବଂ ତାଙ୍କୁ ଦୋକାନ ଚଳାଇବା ପାଇଁ ସମ୍ପୂର୍ଣ୍ଣ ଦାୟିତ୍ୱ ଦିଅନ୍ତି | ଶୋରୁମରେ କାମ କରିବା ପାଇଁ ନବୀନ ମଧ୍ୟ ଅତ୍ୟନ୍ତ ଆରାମଦାୟକ ଏବଂ ଅନ୍ୟତ୍ର ଚାକିରି ଖୋଜିବା ଭଳି ଅନୁଭବ କରନ୍ତି ନାହିଁ |",
            "as": "মালিকে নবীনক বিশ্বাস কৰে আৰু দোকানখন চলোৱাৰ সম্পূৰ্ণ দায়িত্ব তাক দিছে। নবীনেও শ্ব'ৰুমত কাম কৰি আৰাম পাইছে আৰু আন ঠাইত চাকৰি বিচাৰিবলৈ মন নকৰা হৈছে।",
            "gu": "નવીના માલિકે તેના ઉપર પૂરો ભરોશો આવે છે અને તેને પૂરી દુકાન ચાલાવવાની જવાબદારી આપે છે. નવીન પણ આરામદાયક અનુભવે છે શોરૂમમાં કામ કરવામાં અને બીજે નોકરી સોધવામાં કોશિસ પણ નથી કરતો."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Senior mechanic at the store",
            "hi": "स्टोअर में सीनियर मैकेनिक",
            "ka": "**THREAD_END**_ಅಂಗಡಿಯಲ್ಲಿ ಹಿರಿಯ ಮೆಕ್ಯಾನಿಕ್",
            "te": "**THREAD_END**_స్టోర్‌లో సీనియర్ మెకానిక్",
            "or": "**THREAD_END**_Senior mechanic at the store",
            "as": "**থ্ৰেড_সমাপ্ত**_দোকানত জ্যেষ্ঠ মেকানিক",
            "gu": "**THREAD_END**_સ્ટોર પર વરિષ્ઠ મિકેનિક"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Naveen that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने नवीन के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँचा है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकता है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n  ನೀವು ನವೀನ್‌ಗಾಗಿ ಮಾಡಿರುವ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు నవీన్ కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ !\n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି !\n \n ଆପଣ ନବୀନଙ୍କ ପାଇଁ ବାଛିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତି ଘଟାଇଛି | ଆପଣ କରୁଥିବା ପସନ୍ଦ ଉପରେ ଆଧାର କରି ଭିନ୍ନ ଭିନ୍ନ ସମାପ୍ତି ଅଛି | ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକୁ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ?",
            "as": "অভিনন্দন! তুমি এটা যাত্ৰা সমাপ্ত কৰিছা ৷ তুমি নবীনৰ বাবে কৰা বাছনিবোৰৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত ভিত্তি কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ চাব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક મુસાફરી પૂરી કરી છે!\n\nતમે નવીન માટે પસંદગીઓ કરી છે જે આ સંભવિત અંત તરફ દોરી ગઈ છે. તમે જે પસંદગી કરી છે તેનાથી અલગ અલગ અંત છે. શું તમે બીજા દ્રશ્યો ખોલવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ରକୁ ଦେଖନ୍ତୁ |",
            "as": "মানচিত্ৰখনৰ পিনে চোৱা",
            "gu": "ચાલો નકશા પર એક નજર નાખીએ"
          }
        }
      ]
    },
    "scene18": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C6_1_1_Join Alliance Digital",
            "hi": "अलायन्स डिजिटल का जॉब स्वीकार करना",
            "ka": "C6_1_1_ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್‌ಗೆ ಸೇರಿ",
            "te": "C6_1_1_అలయన్స్ డిజిటల్‌లో చేరడం",
            "or": "C6_1_1_Join Alliance Digital",
            "as": "C6_1_1_এলায়েন্স ডিজিটেলত যোগদান কৰে",
            "gu": "એલાયન્સ ડિજિટલમાં જોડાવવું"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen starts working at Alliance Digital full time. He works with a diverse set of employees.",
            "hi": "नवीन अलायन्स डिजिटल में फुल टाइम काम करना शुरू कर देता है. वह तरह-तरह के कर्मचारियों के साथ काम करता है.",
            "ka": "ನವೀನ್ ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್‌ನಲ್ಲಿ ಪೂರ್ಣ ಸಮಯ ಕೆಲಸ ಮಾಡಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ. ಅವನು ವೈವಿಧ್ಯಮಯ ಉದ್ಯೋಗಿಗಳೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುತ್ತಾನೆ.",
            "te": "నవీన్ అలయన్స్ డిజిటల్‌లో పూర్తి సమయం పని చేయడం మొదలు పెట్టాడు. అతను వివిధ రకాల ఉద్యోగులతో పని చేస్తాడు.",
            "or": "ନବୀନ ଆଲାଇନ୍ସ ଡିଜିଟାଲ୍‌ରେ ଫୁଲ୍ ଟାଇମ୍ କାମ କରିବା ଆରମ୍ଭ କରନ୍ତି | ସେ ବିଭିନ୍ନ ପ୍ରକାରର କର୍ମଚାରୀଙ୍କ ସହିତ କାମ କରନ୍ତି |",
            "as": "নবীনে এলায়েন্স ডিজিটেলত সম্পূৰ্ণভাৱে কাম কৰা আৰম্ভ কৰে। সি বিভিন্ন ধৰণৰ কৰ্মচাৰীৰ সৈতে কাম কৰে।",
            "gu": "નવીન એલાયન્સ ડિજિટલમાં પૂરા સમય માટે કામ કરે છે. તે કર્મચારીઓના અલગ અલગ જુથ સાથે કામ કરે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C6_1_2",
            "hi": "C6_1_2",
            "ka": "C6_1_2",
            "te": "C6_1_2",
            "or": "C6_1_2",
            "as": "C6_1_2",
            "gu": "C6_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The store is usually packed with customers. Sometimes, it gets difficult for him to deal with the variety of work he gets.",
            "hi": "तोर में आमतौर पर बहुत सारे ग्राहक होते हैं. कभी-कभी उसे तरह-तरह के काम पूरे करने में कठिनाई होती है.",
            "ka": "ಅಂಗಡಿಯು ಸಾಮಾನ್ಯವಾಗಿ ಗ್ರಾಹಕರಿಂದ ತುಂಬಿರುತ್ತದೆ. ಕೆಲವೊಮ್ಮೆ, ಅವನು ಪಡೆಯುವ ವಿವಿಧ ಕೆಲಸವನ್ನು ನಿಭಾಯಿಸಲು ಅವನಿಗೆ ಕಷ್ಟವಾಗುತ್ತದೆ.",
            "te": "స్టోర్ సాధారణంగా కొనుగోలుదారులతో నిండి ఉంటుంది. కొన్నిసార్లు, అతనికి వచ్చే వివిధ రకాల పనిని ఎదుర్కోవడం అతనికి కష్టమవుతుంది.",
            "or": "ଷ୍ଟୋରଟି ସାଧାରଣତଃ ଗ୍ରାହକମାନଙ୍କ ସହିତ ପ୍ୟାକ୍ ହୋଇଥାଏ | ବେଳେବେଳେ, ସେ ପାଇଥିବା ବିଭିନ୍ନ କାର୍ଯ୍ୟର ମୁକାବିଲା କରିବା ତାଙ୍କ ପାଇଁ କଷ୍ଟକର ହୋଇଯାଏ |",
            "as": "দোকানখন সাধাৰণতে গ্ৰাহকেৰে ঠাহ খাই থাকে। কেতিয়াবা, বিভিন্ন কামৰ সৈতে মোকাবিলা কৰাটো তাৰ বাবে কঠিন হৈ পৰে।",
            "gu": "સ્ટોર ગ્રાહકોથી ભરાઈ જાય છે. ગણી વખત થોડું કઠિન થઈ જાય છે બધાને સાંભળવા અલગ અલગ કામ માટે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "C6_1_3",
            "hi": "C6_1_3",
            "ka": "C6_1_3",
            "te": "C6_1_3",
            "or": "C6_1_3",
            "as": "C6_1_3",
            "gu": "C6_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The work hours are also tiring sometimes. However, since it is a steady business, Naveen gets his pay on time. He also has benefits such as discounts and employee insurances.",
            "hi": "कभी-कभी बहुत देर तक काम करना पड़ता है. लेकिन, व्यवसाय अच्छे से होने के कारण नवीन को समय पर सैलरी मिलती है. और उसे डिस्काउंट और कर्मचारी इंश्योरेंस जैसे लाभ भी मिलते हैं.",
            "ka": "ಕೆಲಸದ ಸಮಯವೂ ಕೆಲವೊಮ್ಮೆ ಆಯಾಸದಾಯಕವಾಗಿರುತ್ತದೆ. ಆದಾಗ್ಯೂ, ಇದು ಸ್ಥಿರವಾದ ವ್ಯವಹಾರವಾಗಿರುವುದರಿಂದ, ನವೀನ್ ತನ್ನ ವೇತನವನ್ನು ಸಮಯಕ್ಕೆ ಪಡೆಯುತ್ತಾನೆ. ಅವನು ರಿಯಾಯಿತಿಗಳು ಮತ್ತು ಉದ್ಯೋಗಿ ವಿಮೆಗಳಂತಹ ಪ್ರಯೋಜನಗಳನ್ನು ಹೊಂದಿದ್ದಾನೆ.",
            "te": "పని గంటలు కూడా కొన్నిసార్లు అలసిపోతాయి. అయితే, ఇది స్థిరమైన వ్యాపారం కాబట్టి, నవీన్ తన వేతనాన్ని సకాలంలో పొందుతాడు. అతనికి తగ్గింపులు మరియు ఉద్యోగుల బీమా వంటి ప్రయోజనాలు కూడా ఉన్నాయి.",
            "or": "କାର୍ଯ୍ୟ ସମୟ ମଧ୍ୟ ବେଳେବେଳେ କ୍ଳାନ୍ତ ହୋଇଯାଏ | ଯେହେତୁ, ଏହା ଏକ ସ୍ଥିର ବ୍ୟବସାୟ ହୋଇଥିବାରୁ ନବୀନ ଠିକ୍ ସମୟରେ ତାଙ୍କ ଦରମା ପାଆନ୍ତି | ତାଙ୍କର ରିହାତି ଏବଂ କର୍ମଚାରୀଙ୍କ ବୀମା ଭଳି ସୁବିଧା ମଧ୍ୟ ଅଛି |",
            "as": "কামৰ সময়বোৰো কেতিয়াবা ক্লান্তিকৰ হয়। অৱশ্যে, যিহেতু এইটো এটা স্থিৰ ব্যৱসায়, নবীনে তাৰ দৰমহা সময়মতে পায়। তাৰ বিশেষ ৰেহাই আৰু কৰ্মচাৰী বীমাৰ দৰে লাভো আছে।",
            "gu": "કામનો સમય પણ ગણી વખત તેને થકવાડી દે છે. જોકે, તે એક સ્થિર ધંધો છે. નવીને તેનો પગાર ટાઈમ પ્રમાણે મળે છે. તેને ગ્રાહકો પાશેથી છૂટ મળે છે અને વીમાના ફાઈદા પણ."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C6_2_1",
            "hi": "C6_2_1",
            "ka": "C6_2_1",
            "te": "C6_2_1",
            "or": "C6_2_1",
            "as": "C6_2_1",
            "gu": "C6_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Here he gets to use his knowledge of mechanics and gets good feedback on his customer service.",
            "hi": "यहाँ वह अपने मैकेनिकल ज्ञान का इस्तेमाल कर सकता है और उसे ग्राहकों की सेवा के लिए अच्छा फीडबैक भी मिलता है.",
            "ka": "ಇಲ್ಲಿ ಅವನು ಯಂತ್ರಶಾಸ್ತ್ರದ ಜ್ಞಾನವನ್ನು ಬಳಸುತ್ತಾನೆ ಮತ್ತು ಅವನ ಗ್ರಾಹಕ ಸೇವೆಯಲ್ಲಿ ಉತ್ತಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಪಡೆಯುತ್ತಾನೆ.",
            "te": "ఇక్కడ అతను మెకానిక్స్ గురించి తన పరిజ్ఞానాన్ని ఉపయోగించుకుంటాడు మరియు అతని కస్టమర్ సేవపై మంచి అభిప్రాయాన్ని పొందుతాడు",
            "or": "ଏଠାରେ ସେ ମେକାନିକ୍ସ ବିଷୟରେ ତାଙ୍କର ଜ୍ଞାନକୁ ବ୍ୟବହାର କରିବାକୁ ପାଆନ୍ତି ଏବଂ ତାଙ୍କ ଗ୍ରାହକ ସେବା ଉପରେ ଭଲ ମତାମତ ପାଆନ୍ତି |",
            "as": "ইয়াত সি যান্ত্ৰিকতাৰ জ্ঞান ব্যৱহাৰ কৰিবলৈ পায় আৰু তাৰ গ্ৰাহক সেৱাৰ ওপৰত ভাল প্ৰতিক্ৰিয়া লাভ কৰে।",
            "gu": "અહી તે તેનું મગજ દોડાવીને મેકેનિકના કામો સરળતાથી પતાવી છે અને ગ્રાહકો જોડેથી સારો પ્રતિસાદ મળે છે એની સેવાથી"
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "C6_2_2",
            "hi": "C6_2_2",
            "ka": "C6_2_2",
            "te": "C6_2_2",
            "or": "C6_2_2",
            "as": "C6_2_2",
            "gu": "C6_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "After two years, he gets promoted to a senior mechanic at a bigger showroom of Alliance Digital.",
            "hi": "दो साल के बाद, उसे अलायन्स डिजिटल के बड़े शोरूम में, सीनियर मैकेनिक के तौर पर प्रमोशन मिलता है.",
            "ka": "ಎರಡು ವರ್ಷಗಳ ನಂತರ, ಅವನು ಅಲಯನ್ಸ್ ಡಿಜಿಟಲ್‌ನ ದೊಡ್ಡ ಶೋರೂಮ್‌ನಲ್ಲಿ ಹಿರಿಯ ಮೆಕ್ಯಾನಿಕ್ ಆಗಿ ಬಡ್ತಿ ಪಡೆಯುತ್ತಾನೆ.",
            "te": "రెండు సంవత్సరాల తర్వాత, అతనికి అలయన్స్ డిజిటల్ యొక్క పెద్ద షోరూమ్‌లో సీనియర్ మెకానిక్‌గా ప్రమోషన్ వచ్చింది .",
            "or": "ଦୁଇ ବର୍ଷ ପରେ, ସେ ଆଲାଇନ୍ସ ଡିଜିଟାଲର ଏକ ବଡ଼ ଶୋ’ରୁମରେ ଜଣେ ସିନିୟର ମେକାନିକ୍ ଭାବରେ ପଦୋନ୍ନତି ପାଆନ୍ତି |",
            "as": "দুবছৰৰ পিছত, সি এলায়েন্স ডিজিটেলৰ এটা ডাঙৰ শ্ব'ৰুমত এজন জ্যেষ্ঠ মেকানিক হিচাপে পদোন্নতি লাভ কৰে।",
            "gu": "બે વર્ષ પછી, એને ઉપરી મેકેનિક તરીકે બઢતી મોટા શોરૂમમાં મળે છે એલાયન્સ ડિજિટલમાં"
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "C6_2_3",
            "hi": "C6_2_3",
            "ka": "C6_2_3",
            "te": "C6_2_3",
            "or": "C6_2_3",
            "as": "C6_2_3",
            "gu": "C6_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "After working there for a few years, he moves to another international brand showroom. He gets paid well and joins there as a repair floor supervisor.",
            "hi": "यहाँ पर कुछ साल काम करने के बाद, वह एक इंटरनेशनल ब्रांड के शोरूम में जॉब स्वीकार करता है. यहाँ उसे अच्छी सैलरी मिलती है और वह रिपेयर फ्लोअर सुपरवाइजर के तौर पर काम करता है",
            "ka": "ಕೆಲವು ವರ್ಷಗಳ ಕಾಲ ಅಲ್ಲಿ ಕೆಲಸ ಮಾಡಿದ ನಂತರ, ಅವನು ಮತ್ತೊಂದು ಅಂತರರಾಷ್ಟ್ರೀಯ ಬ್ರ್ಯಾಂಡ್ ಶೋರೂಮ್‌ಗೆ ತೆರಳುತ್ತಾನೆ. ಉತ್ತಮ ಸಂಬಳ ಪಡೆದು ಅಲ್ಲಿ ರಿಪೇರಿ ಮಹಡಿ ಮೇಲ್ವಿಚಾರಕನಾಗಿ ಸೇರುತ್ತಾನೆ.",
            "te": "అక్కడ కొన్నాళ్లు పనిచేసిన తర్వాత మరో అంతర్జాతీయ బ్రాండ్ షోరూమ్‌కి వెళ్లాడు. అతను బాగా జీతం పొంది అక్కడ రిపేర్ ఫ్లోర్ సూపర్‌వైజర్‌గా చేరాడు.",
            "or": "ସେଠାରେ କିଛି ବର୍ଷ କାମ କରିବା ପରେ, ସେ ଅନ୍ୟ ଏକ ଆନ୍ତର୍ଜାତୀୟ ବ୍ରାଣ୍ଡ ଶୋ’ରୁମକୁ ଚାଲିଯାଆନ୍ତି | ସେ ଭଲ ଦରମା ପାଆନ୍ତି ଏବଂ ସେଠାରେ ଏକ ରିପେୟାର୍‌ ଫ୍ଲୋର୍‌ ସୁପରଭାଇଜର ଭାବରେ ଯୋଗ ଦିଅନ୍ତି |",
            "as": "তাত কেইবছৰমান কাম কৰাৰ পিছত, সি আন এটা আন্তৰ্জাতিক ব্ৰেণ্ডৰ শ্ব'ৰুমলৈ বদলি হয় ৷ সি ভাল দৰমহা পাইছে আৰু তাত মেৰামতিৰ ফ্ল’ৰ চুপাৰভাইজাৰ হিচাপে যোগদান কৰে।",
            "gu": "ત્યાં થોડા વર્ષો કામા કર્યા પછી, તે આંતરરાષ્ટ્રીય બ્રાન્ડના શોરૂમમાં જાય છે. તેને સારો પગાર મળે છે અને ત્યાં રિપેર ફ્લોર સુપરવાઈઝર તરીકે કામ કરે છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Supervisor at an international store",
            "hi": "इंटरनेशनल स्टोअर में सुपरवाइजर",
            "ka": "**THREAD_END**_ಅಂತರಾಷ್ಟ್ರೀಯ ಅಂಗಡಿಯಲ್ಲಿ ಮೇಲ್ವಿಚಾರಕ",
            "te": "**THREAD_END**_ఇంటర్నేషనల్ స్టోర్‌లో సూపర్‌వైజర్",
            "or": "**THREAD_END**_Supervisor at an international store",
            "as": "**থ্ৰেড_সমাপ্ত**_আন্তৰ্জাতিক দোকান এখনত চুপাৰভাইজাৰ",
            "gu": "**THREAD_END**_આંતરરાષ્ટ્રીય સ્ટોર પર સુપરવાઇઝર"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Naveen that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने नवीन के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँचा है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकता है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n  ನೀವು ನವೀನ್‌ಗಾಗಿ ಮಾಡಿರುವ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు నవీన్ కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ !\n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି !\n \n ଆପଣ ନବୀନଙ୍କ ପାଇଁ ବାଛିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତି ଘଟାଇଛି | ଆପଣ କରୁଥିବା ପସନ୍ଦ ଉପରେ ଆଧାର କରି ଭିନ୍ନ ଭିନ୍ନ ସମାପ୍ତି ଅଛି | ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକୁ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ?",
            "as": "অভিনন্দন! তুমি এটা যাত্ৰা সমাপ্ত কৰিছা ৷ তুমি নবীনৰ বাবে কৰা বাছনিবোৰৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত ভিত্তি কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ চাব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક મુસાફરી પૂરી કરી છે!\n\nતમે નવીન માટે પસંદગીઓ કરી છે જે આ સંભવિત અંત તરફ દોરી ગઈ છે. તમે જે પસંદગી કરી છે તેનાથી અલગ અલગ અંત છે. શું તમે બીજા દ્રશ્યો ખોલવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ରକୁ ଦେଖନ୍ତୁ |",
            "as": "মানচিত্ৰখনৰ পিনে চোৱা",
            "gu": "ચાલો નકશા પર એક નજર નાખીએ"
          }
        }
      ]
    },
    "scene19": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C7_1_Apply for government job",
            "hi": "सरकारी जॉब के लिए अप्लाई करना",
            "ka": "C7_1_ಸರ್ಕಾರಿ ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ",
            "te": "C7_1_ప్రభుత్వ ఉద్యోగం కోసం దరఖాస్తు చేసుకోవడం",
            "or": "C7_1_Apply for government job",
            "as": "C7_1_চৰকাৰী চাকৰিৰ বাবে আবেদন",
            "gu": "C7_1_સરકારી નોકરીમાં અરજી કરવી"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen is sitting with his friend Puneet in the computer centre at their college.",
            "hi": "नवीन अपने दोस्त पुनीत के साथ कॉलेज के कंप्यूटर सेंटर में बैठा हुआ है.",
            "ka": "ನವೀನ್ ತನ್ನ ಸ್ನೇಹಿತ ಪುನೀತ್ ಜೊತೆ ತಮ್ಮ ಕಾಲೇಜಿನ ಕಂಪ್ಯೂಟರ್ ಸೆಂಟರ್ ನಲ್ಲಿ ಕುಳಿತಿದ್ದಾನೆ.",
            "te": "నవీన్ తన స్నేహితుడు పునీత్‌తో కలిసి తమ కళాశాలలోని కంప్యూటర్ సెంటర్‌లో కూర్చున్నాడు.",
            "or": "ନବୀନ ତାଙ୍କ ବନ୍ଧୁ ପୁନିତଙ୍କ ସହିତ ସେମାନଙ୍କ କଲେଜ ଠାରେ କମ୍ପ୍ୟୁଟର ସେଣ୍ଟରରେ ବସିଛନ୍ତି |",
            "as": "নবীন তাৰ বন্ধু পুনীতৰ সৈতে তেওঁলোকৰ মহাবিদ্যালয়ৰ কম্পিউটাৰ কেন্দ্ৰত বহি আছে।",
            "gu": "નવીન તેના મિત્ર પુનિત જોડે કમ્પ્યુટર સેંટરમાં બેઠો છે તેની કોલેજમાં"
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C7_1_2",
            "hi": "C7_1_2",
            "ka": "C7_1_2",
            "te": "C7_1_2",
            "or": "C7_1_2",
            "as": "C7_1_2",
            "gu": "C7_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "They are both applying for job openings at various government institutions. They are both unsure about the possibilities of getting a job.",
            "hi": "वे दोनों अलग-अलग सरकारी संस्थाओं में जॉब के लिए अप्लाई कर रहे हैं. दोनों को भी जॉब मिलने की संभावना कम लग रही हैं.",
            "ka": "ಇಬ್ಬರೂ ವಿವಿಧ ಸರ್ಕಾರಿ ಸಂಸ್ಥೆಗಳಲ್ಲಿ ಉದ್ಯೋಗಾವಕಾಶಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಿದ್ದಾರೆ. ಉದ್ಯೋಗ ಪಡೆಯುವ ಸಾಧ್ಯತೆಗಳ ಬಗ್ಗೆ ಇಬ್ಬರೂ ಖಚಿತವಾಗಿಲ್ಲ.",
            "te": "వీరిద్దరూ వివిధ ప్రభుత్వ సంస్థలలో ఉద్యోగాల కోసం దరఖాస్తు చేసుకున్నారు. ఉద్యోగం పొందే అవకాశాలపై వారిద్దరూ ఆలోచిస్తున్నారు.",
            "or": "ସେମାନେ ଉଭୟ ବିଭିନ୍ନ ସରକାରୀ ଅନୁଷ୍ଠାନରେ ଚାକିରି ପାଇଁ ଆବେଦନ କରୁଛନ୍ତି। ଏକ ଚାକିରି ପାଇବା ସମ୍ଭାବନା ବିଷୟରେ ସେମାନେ ଦୁହେଁ ଅନିଶ୍ଚିତ |",
            "as": "তেওঁলোক দুয়োজনে বিভিন্ন চৰকাৰী প্ৰতিষ্ঠানত চাকৰিৰ বাবে আবেদন কৰিছে। তেওঁলোক দুয়ো চাকৰি পোৱাৰ সম্ভাৱনাৰ বিষয়ে নিশ্চিত নহয়।",
            "gu": "તે બંને સરકારી સંસ્થાઓમાં જે ભરતી પડી છે તેમાં અરજી કરે છે. બંનેને પૂરી ખાત્રી નથી કે નોકરી મળશે કે નહીં."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "C7_1_3//",
            "hi": "C7_1_3//",
            "ka": "C7_1_3//",
            "te": "C7_1_3//",
            "or": "C7_1_3//",
            "as": "C7_1_3//",
            "gu": "C7_1_3//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Puneet",
            "hi": "पुनीत",
            "ka": "ಪುನೀತ್",
            "te": "పునీత్",
            "or": "ପୁନିତ",
            "as": "পুনীত",
            "gu": "પુનિત"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Did you apply for both jobs and apprenticeships?",
            "hi": "क्या तुमने जॉब और अप्रेंटिसशिप दोनों के लिए अप्लाई किया है?",
            "ka": "ನೀವು ಉದ್ಯೋಗಗಳು ಮತ್ತು ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್‌ಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ್ದೀರಾ?",
            "te": "మీరు ఉద్యోగాలు మరియు అప్రెంటిస్‌షిప్‌లు రెండింటికీ దరఖాస్తు చేసారా?",
            "or": "ଆପଣ ଉଭୟ ଚାକିରି ଏବଂ ଆପ୍ରେଣ୍ଟିସିପ୍ ପାଇଁ ଆବେଦନ କରିଛନ୍ତି କି?",
            "as": "তুমি চাকৰি আৰু এপ্ৰেণ্টিছশ্বিপ দুয়োটাৰ বাবে আবেদন কৰিছিলানে?",
            "gu": "શું તે બંનેમાં અરજી કરી છે નોકરીમાં અને એપ્રેન્ટિસશીપમાં"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Apprenticeships? That’s not permanent da. And we will have to go through more training. Remember the industry visit? Apprentices learn on the job.",
            "hi": "अप्रेंटिसशिप ? यह परमानेंट नहीं होता है, दा. और हमें अधिक ट्रेनिंग लेनी पड़ेगी. याद है, हमने इंडस्ट्री को विजिट किया था? अप्रेंटिस करनेवाले जॉब पर सीखते हैं",
            "ka": "ಶಿಷ್ಯವೇತನ? ಅದು ಶಾಶ್ವತ ಅಲ್ಲ. ಮತ್ತು ನಾವು ಹೆಚ್ಚಿನ ತರಬೇತಿಯ ಮೂಲಕ ಹೋಗಬೇಕಾಗುತ್ತದೆ. ಉದ್ಯಮ ಭೇಟಿ ನೆನಪಿದೆಯೇ? ಅಪ್ರೆಂಟಿಸ್‌ಗಳು ಕೆಲಸದ ಮೇಲೆ ಕಲಿಯುತ್ತಾರೆ.",
            "te": "అప్రెంటిస్‌షిప్‌ లా?అది శాశ్వతం కాదు. మరియు మేము మరింత శిక్షణ పొందవలసి ఉంటుంది. ఇండస్ట్రీ సందర్శన గుర్తుందా? అప్రెంటిస్‌లు ఉద్యోగంలో నేర్చుకుంటారు.",
            "or": "ଆପ୍ରେଣ୍ଟିସିପ୍? ତାହା ସ୍ଥାୟୀ ନୁହେଁ | ଏବଂ ଆମକୁ ଅଧିକ ତାଲିମ ଦେଇ ଗତି କରିବାକୁ ପଡିବ | ଶିଳ୍ପ ପରିଦର୍ଶନ ମନେ ଅଛି କି? ଆପ୍ରେଣ୍ଟିସ୍‌ ଚାକିରିରେ ଶିଖନ୍ତି |",
            "as": "এপ্ৰেণ্টিছশ্বিপ? সেইটো স্থায়ী নহয় বন্ধু। আৰু আমি বহুতো প্ৰশিক্ষণৰ মাজেৰে পাৰ হ’ব লাগিব। উদ্যোগ ভ্ৰমণলৈ মনত পেলোৱা? শিক্ষানবিশসকলে কামৰ মাজতেই শিকিব লাগে।",
            "gu": "એપ્રેન્ટિસશીપ? તે કાઇમી નથી. અને ત્યાં આપણે તાલીમમાં પ્રસાર થાઉં પડે. તને યાદ છે કંપનીની મુલાકાત. એપ્રેન્ટિસ નોકરીમાં શીખે છે. "
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C7_1_4////",
            "hi": "C7_1_4////",
            "ka": "C7_1_4////",
            "te": "C7_1_4////",
            "or": "C7_1_4////",
            "as": "C7_1_4////",
            "gu": "C7_1_4////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "What is the harm in training more? We’re not experts anyway. Plus, I doubt we will get through in our first attempt for government jobs. The criteria for selection looks so tough.",
            "hi": "ज्यादा ट्रेनिंग लेने में दिक्कत ही क्या है? हम कोई विशेषज्ञ भी तो नहीं है. और मुझे नहीं लगता कि, हमें पहले ही प्रयास में सरकारी जॉब मिल जाएगी. इसके चुनाव के निकष बहुत कड़े हैं.",
            "ka": "ಹೆಚ್ಚು ತರಬೇತಿಯಿಂದ ಹಾನಿ ಏನು? ನಾವು ಹೇಗಾದರೂ ತಜ್ಞರಲ್ಲ. ಜೊತೆಗೆ, ಸರ್ಕಾರಿ ಉದ್ಯೋಗಗಳಿಗಾಗಿ ನಮ್ಮ ಮೊದಲ ಪ್ರಯತ್ನದಲ್ಲಿ ನಾವು ಯಶಸ್ವಿಯಾಗುತ್ತೇವೆ ಎಂದು ನನಗೆ ಅನುಮಾನವಿದೆ. ಆಯ್ಕೆಯ ಮಾನದಂಡವು ತುಂಬಾ ಕಠಿಣವಾಗಿದೆ.",
            "te": "ఎక్కువ ట్రైనింగ్ ఇవ్వడం వల్ల వచ్చే నష్టం ఏమిటి? మనము నిపుణులం కాదు. అదనంగా, ప్రభుత్వ ఉద్యోగాల కోసం మన మొదటి ప్రయత్నంలో మనం విజయం సాధిస్తామా అనే సందేహం నాకు ఉంది. ఎంపిక విధానం చాలా కష్టంగా ఉంది.",
            "or": "ଅଧିକ ଟ୍ରେଣିଂରେ କ’ଣ କ୍ଷତି ଅଛି ? ଆମେ କୌଣସି ପ୍ରକାରେ ବିଶେଷଜ୍ଞ ନୁହଁ | ଏଥିସହ, ସରକାରୀ ଚାକିରୀ ପାଇଁ ଆମର ପ୍ରଥମ ପ୍ରୟାସରେ ଆମେ ଅତିକ୍ରମ କରିବୁ ବୋଲି ମୋର ସନ୍ଦେହ ଅଛି | ଚୟନର ମାନଦଣ୍ଡ ଏତେ କଠିନ ଦେଖାଯାଉଛି |",
            "as": "আৰু প্ৰশিক্ষণ লোৱাত ক্ষতি কি? আমি এনেও ওস্তাদ নহয়। লগতে, মোৰ সন্দেহ আছে যে আমি এবাৰতে চৰকাৰী চাকৰি পাই যাম। বাছনিৰ চৰ্তবোৰ বহুত কঠিন যেন লাগিছে।",
            "gu": "તાલીમમાં વધુ નુકશાન શું છે? આપણે એટલા બધા નિષ્ણાત નથી. વધારાનું મને શંકા છે કે આપણને પહેલાજ પ્રયાસમાં સરકારી નોકરી મળી જશે. માપદંડ જે અગારા હોય છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "What are you suggesting?",
            "hi": "आप क्या कहना चाहते हैं?",
            "ka": "ನೀವು ಏನು ಸೂಚಿಸುತ್ತಿದ್ದೀರಿ?",
            "te": "నువ్వు ఏమి సలహా ఇస్తున్నావు?",
            "or": "ତୁମେ କ’ଣ ପରାମର୍ଶ ଦେଉଛ?",
            "as": "তুমি কি পৰামৰ্শ দিবা?",
            "gu": "તમે શું સલાહ આપવા માગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Let us apply for apprenticeships as well, just for a backup.",
            "hi": "क्यों ना हम अप्रेंटिसशिप के लिए भी अप्लाई करें, बस बैकअप के तौर पर.",
            "ka": "ಕೇವಲ ಬ್ಯಾಕ್‌ಅಪ್‌ಗಾಗಿ ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್‌ಗಳಿಗೂ ಅರ್ಜಿ ಸಲ್ಲಿಸೋಣ.",
            "te": "అప్రెంటిస్‌షిప్‌ల కోసం కూడా దరఖాస్తు చేద్దాం, కేవలం బ్యాకప్ కోసం.",
            "or": "ଆସନ୍ତୁ ଆପ୍ରେଣ୍ଟିସିପ୍ ପାଇଁ ମଧ୍ଯ ଆବେଦନ କରିବା, କେବଳ ଏକ ବ୍ୟାକଅପ୍ ପାଇଁ |",
            "as": "আহাঁ আমি এপ্ৰেণ্টিছশ্বিপ বাবেও আবেদন কৰোঁ, বেকআপ হিচাবে।",
            "gu": "ચાલો એપ્રેન્ટિસશીપમાં અરજી કરી નાખીએ એક બેકઅપ તરીકે રાખી શકાય"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "You might be right. At least for the next 1 year, we will be secure. Plus, it is not like the pay is bad.",
            "hi": "शायद आप सही कह रहे हैं. कम से कम अगले 1 साल तक हमारी जॉब सुरक्षित होगी. और सैलरी भी बुरी नहीं है.",
            "ka": "ನೀನು ಸರಿ ಇರಬಹುದು. ಮುಂದಿನ 1 ವರ್ಷವಾದರೂ ನಾವು ಸುರಕ್ಷಿತವಾಗಿರುತ್ತೇವೆ. ಜೊತೆಗೆ, ವೇತನವು ಕೆಟ್ಟದ್ದಲ್ಲ.",
            "te": "నువ్వు చెప్పింది నిజమే కావచ్చు. కనీసం వచ్చే ఒక సంవత్సరం వరకు, మనము భద్రతగా ఉంటాము. అదనంగా, చెల్లింపు కూడా బాగానే ఉంటుంది.",
            "or": "ଆପଣ ଠିକ୍ ହୋଇପାରନ୍ତି | ଅତିକମରେ ଆଗାମୀ 1 ବର୍ଷ ପାଇଁ, ଆମେ ସୁରକ୍ଷିତ ରହିବୁ | ଏହାସହିତ, ଦରମା ଏତେ ଖରାପ ଭି ନୁହେଁ |",
            "as": "তুমি শুদ্ধও হ'ব পাৰা। কমেও পৰৱৰ্তী ১বছৰৰ বাবে আমি সুৰক্ষিত থাকিম। লগতে, দৰমহা ইমানো বেয়া নহয়।",
            "gu": "તું સાચો છે. ઓછામાં ઓછા 1 વર્ષ માટે આપણે સુરક્ષિત રહીશું. વત્તા એ પણ નથી તે ઓછો પગાર આપે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "C7_1_5_apply",
            "hi": "C7_1_5_अप्लाई करना",
            "ka": "C7_1_5_ಅನ್ವಯಿಸು",
            "te": "C7_1_5_దరఖాస్తు",
            "or": "C7_1_5_apply",
            "as": "C7_1_5_আবেদন",
            "gu": "C7_1_5_અરજી કરવી"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Portal 1",
            "hi": "पोर्टल 1",
            "ka": "ಪೋರ್ಟಲ್ 1",
            "te": "పోర్టల్ 1",
            "or": "ପୋର୍ଟାଲ୍‌ 1",
            "as": "পৰ্টেল 1",
            "gu": "પોર્ટલ 1"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "KPCL - Boiler Mechanic",
            "hi": "KPCL - बॉईलर मैकेनिक",
            "ka": "ಕೆಪಿಸಿಲ್ - ಬಾಯ್ಲರ್ ಮೆಕ್ಯಾನಿಕ್",
            "te": "KPCL - బాయిలర్ మెకానిక్",
            "or": "KPCL - ବଏଲର ମେକାନିକ୍",
            "as": "KPCL - বয়লাৰ মেকানিক",
            "gu": "KPCL - બોઈલર મિકેનિક"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "BEL - ITI Mechanic",
            "hi": "BEL - ITI मैकेनिक",
            "ka": "ಬಿಇಲ್ - ಐಟಿಐ ಮೆಕ್ಯಾನಿಕ್",
            "te": "BEL - ITI మెకానిక్",
            "or": "BEL - ITI ମେକାନିକ୍",
            "as": "BEL - ITI মেকানিক",
            "gu": "BEL - ITI મિકેનિક"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "KPCL - Mechanic Apprenticeship",
            "hi": "KPCL - मशीन अप्रेंटिसशिप",
            "ka": "ಕೆಪಿಸಿಲ್ - ಮೆಕ್ಯಾನಿಕ್ ಶಿಷ್ಯವೃತ್ತಿ",
            "te": "KPCL - మెకానిక్ అప్రెంటిస్‌షిప్",
            "or": "KPCL - ମେକାନିକ୍ ଆପ୍ରେଣ୍ଟିସିପ୍",
            "as": "KPCL - মেকানিক এপ্ৰেণ্টিছশ্বিপ",
            "gu": "KPCL - મિકેનિક એપ્રેન્ટિસશિપ"
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "C7_1_6_apply",
            "hi": "C7_1_6_अप्लाई करना",
            "ka": "C7_1_6_ಅನ್ವಯಿಸು",
            "te": "C7_1_6_దరఖాస్తు",
            "or": "C7_1_6_apply",
            "as": "C7_1_6_আবেদন",
            "gu": "C7_1_6_અરજી કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Portal 2",
            "hi": "पोर्टल 2",
            "ka": "ಪೋರ್ಟಲ್ 2",
            "te": "పోర్టల్ 2",
            "or": "ପୋର୍ଟାଲ୍‌ 2",
            "as": "পৰ্টেল 2",
            "gu": "પોર્ટલ 2"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "SW Railways - Jr. Machine mechanic",
            "hi": "SW रेलवेज - ज्यू. मशीन मैकेनिक",
            "ka": "ಎಸ್ ಡಬ್ಲ್ಯೂ ರೈಲ್ವೇಸ್ - ಜೂನಿಯರ್ ಮೆಷಿನ್ ಮೆಕ್ಯಾನಿಕ್",
            "te": "SW రైల్వేస్ - జూనియర్ మెషిన్ మెకానిక్",
            "or": "SW Railways - ଜୁନିୟର ମେସିନ୍ ମେକାନିକ୍",
            "as": "SW ৰেলৱেজ - জুনিয়ৰ মেচিন মেকানিক",
            "gu": "SW રેલ્વે - જુનિયર મશીન મિકેનિક"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "FFS MOTORS - Jr. Motor Mechanic",
            "hi": "FFS मोटर्स - ज्यू. मोटर मैकेनिक",
            "ka": "ಎಫ್ ಎಫ್ ಎಸ್ ಮೋಟಾರ್ಸ್ - ಜೂನಿಯರ್ ಮೋಟಾರ್ ಮೆಕ್ಯಾನಿಕ್",
            "te": "FFS మోటార్స్ - జూనియర్ మోటార్ మెకానిక్",
            "or": "FFS MOTORS - ଜୁନିୟର ମୋଟର ମେକାନିକ୍",
            "as": "FFS মটৰ্চ - জুনিয়ৰ মটৰ মেকানিক",
            "gu": "FFS મોટર્સ - જુનિયર મોટર મિકેનિક"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "SW Railways - Mechanic Apprenticeship",
            "hi": "SW रेलवेज - मशीन अप्रेंटिसशिप",
            "ka": "ಎಸ್ ಡಬ್ಲ್ಯೂ ರೈಲ್ವೇಸ್ - ಮೆಕ್ಯಾನಿಕ್ ಶಿಷ್ಯವೃತ್ತಿ",
            "te": "SW రైల్వేస్ - మెకానిక్ అప్రెంటిస్‌షిప్",
            "or": "SW Railways - ମେକାନିକ୍ ଆପ୍ରେଣ୍ଟିସିପ୍",
            "as": "SW ৰেলৱেজ - মেকানিক এপ্ৰেণ্টিছশ্বিপ",
            "gu": "SW રેલ્વે - મિકેનિક એપ્રેન્ટિસશીપ"
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "C7_2_1",
            "hi": "C7_2_1",
            "ka": "C7_2_1",
            "te": "C7_2_1",
            "or": "C7_2_1",
            "as": "C7_2_1",
            "gu": "C7_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen and Puneet apply for apprenticeships as well. They don’t get selected for the government and PSU jobs as they don't meet the qualification criteria.",
            "hi": "नवीन और पुनीत अप्रेंटिसशिप के लिए भी अप्लाई करते हैं. वह सरकारी और PSU जॉब के लिए सिलेक्ट नहीं होते, क्योंकि वह पात्रता के निकष पूरे नहीं करते हैं.",
            "ka": "ನವೀನ್ ಮತ್ತು ಪುನೀತ್ ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್‌ಗೂ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಾರೆ. ಅವರು ಅರ್ಹತಾ ಮಾನದಂಡಗಳನ್ನು ಪೂರೈಸದ ಕಾರಣ ಅವರು ಸರ್ಕಾರಿ ಮತ್ತು ಪಿಎಸ್ ಯು ಉದ್ಯೋಗಗಳಿಗೆ ಆಯ್ಕೆಯಾಗುವುದಿಲ್ಲ.",
            "te": "నవీన్ మరియు పునీత్ అప్రెంటిస్‌షిప్ కోసం కూడా దరఖాస్తు చేసుకున్నారు. వారు అర్హత ప్రమాణాలకు అనుగుణంగా లేనందున వారు ప్రభుత్వ మరియు PSU ఉద్యోగాలకు ఎంపిక అవ్వలేదు.",
            "or": "ନବୀନ ଏବଂ ପୁଣେ ମଧ୍ୟ ଆପ୍ରେଣ୍ଟିସିପ୍ ପାଇଁ ଆବେଦନ କରନ୍ତି | ସେମାନେ ଯୋଗ୍ୟତା ମାନଦଣ୍ଡ ପୂରଣ କରୁ ନ ଥିବାରୁ ସେମାନେ ସରକାରୀ ଏବଂ PSU ଚାକିରି ପାଇଁ ମନୋନୀତ ହୁଅନ୍ତି ନାହିଁ |",
            "as": "নবীন আৰু পুনীতে এপ্ৰেণ্টিছশ্বিপৰ বাবেও আবেদন কৰে। সিহঁত চৰকাৰী আৰু PSU চাকৰিৰ বাবে নিৰ্বাচিত নহ’ল কিয়নো সিহঁতৰ অৰ্হতা নাছিল ৷",
            "gu": "નવીન અને પુનીત બંને એપ્રેન્ટિસશીપમાં પણ અરજી કરે છે. તે બંને સરકારી નોકરી માટે પસંદ નથી બનતા અને PSU નોકરી માટે તેમની લાયકાતના માપદંડોમાં નથી આવતા."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "C7_2_2",
            "hi": "C7_2_2",
            "ka": "C7_2_2",
            "te": "C7_2_2",
            "or": "C7_2_2",
            "as": "C7_2_2",
            "gu": "C7_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "But after interviews for the apprenticeships, they both get selected at KPCL as apprentices.",
            "hi": "अप्रेंटिसशिप के लिए इंटरव्यू देने के बाद, दोनों भी KPCL में अप्रेंटिस के तौर पर सिलेक्ट हो जाते हैं.",
            "ka": "ಆದರೆ ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್‌ಗಳಿಗೆ ಸಂದರ್ಶನದ ನಂತರ, ಅವರಿಬ್ಬರೂ ಕೆಪಿಸಿಎಲ್‌ನಲ್ಲಿ ಅಪ್ರೆಂಟಿಸ್‌ಗಳಾಗಿ ಆಯ್ಕೆಯಾಗುತ್ತಾರೆ.",
            "te": "కానీ అప్రెంటిస్‌షిప్‌ల కోసం ఇంటర్వ్యూల తర్వాత, వారిద్దరూ KPCLలో అప్రెంటిస్‌లుగా ఎంపిక అయ్యారు.",
            "or": "କିନ୍ତୁ ଆପ୍ରେଣ୍ଟିସିପ୍ ପାଇଁ ସାକ୍ଷାତକାର ପରେ, ସେମାନେ ଉଭୟ KPCL ରେ ଆପ୍ରେଣ୍ଟିସ୍ ଭାବରେ ମନୋନୀତ ହୁଅନ୍ତି |",
            "as": "কিন্তু এপ্ৰেণ্টিছশ্বিপৰ বাবে সাক্ষাৎকাৰৰ পিছত, সিহঁত দুয়োটা KPCLত শিক্ষানবিশ হিচাপে নিৰ্বাচিত হয়।",
            "gu": "પરંતુ KPCLમાં એપ્રેન્ટીસશીપ માટેના ઈન્ટરવ્યુ પછી, તેઓ બંને એપ્રેન્ટીસ તરીકે પસંદગી પામે છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "Apprencticeship",
            "hi": "अप्रेंटिसशिप",
            "ka": "ಶಿಷ್ಯವೃತ್ತಿ",
            "te": "అప్రెంటిస్‌షిప్",
            "or": "ଆପ୍ରେଣ୍ଟିସିପ୍",
            "as": "এপ্ৰেণ্টিছশ্বিপ",
            "gu": "એપ્રેન્ટીસશીપ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Apprenticeship experience!",
            "hi": "आपने अभी-अभी अप्रेंटिसशिप का अनुभव प्राप्त किया है!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే అప్రెంటిస్‌షిప్ అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ କେବଳ ଆପ୍ରେଣ୍ଟିସିପ୍ ଅଭିଜ୍ଞତା ହାସଲ କରିଛନ୍ତି!",
            "as": "তুমি এইমাত্ৰ এপ্ৰেণ্টিছশ্বিপৰ অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "તમે એપ્રેન્ટીસશીપનું જ્ઞાન મેળવ્યું!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Apprenticeship",
            "hi": "अप्रेंटिसशिप",
            "ka": "ಶಿಷ್ಯವೃತ್ತಿ",
            "te": "అప్రెంటిస్‌షిప్",
            "or": "ଆପ୍ରେଣ୍ଟିସିପ୍",
            "as": "এপ্ৰেণ্টিছশ্বিপ",
            "gu": "એપ્રેન્ટીસશીપ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर विकास कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "వృత్తి అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ଯାରିୟର୍‌ ବିକାଶ କୌଶଳ",
            "as": "জীৱিকা বিকাশৰ দক্ষতা",
            "gu": "કારકિર્દી વિકાસ કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Naveen gets an apprenticeship at KPCL. Professions like Naveen’s and others like welding, carpentry often have apprenticeships. An apprenticeship is like an internship, where one learns by doing, getting exposed to the work environment and the everyday experience of the work. These can be six months to 2 years long. During an apprenticeship, you work with an experienced and skilled professional to learn the details of the profession.",
            "hi": "नवीन को KPCL में अप्रेंटिसशिप मिल जाती है. वेल्डिंग, कारपेंटरी जैसे प्रोफेशन के लिए अक्सर अप्रेंटिसशिप दी जाती है. अप्रेंटिसशिप एक तरह से इन्टर्नशिप के जैसे ही होती है, जहाँ आप काम करके सीखते हैं, काम के माहौल को देखते हैं और हर दिन कंपनी में कैसे काम चलता है इसका अनुभव प्राप्त करते हैं. अप्रेंटिसशिप छः महीने से लेकर 2 साल तक हो सकती है. अप्रेंटिसशिप के दौरान, आप अनुभवी और कुशल प्रोफेशनल व्यक्तियों के साथ इस व्यवसाय के बारे में बारीकियों के साथ सीखते हैं.",
            "ka": "ನವೀನ್‌ಗೆ ಕೆಪಿಸಿಎಲ್‌ನಲ್ಲಿ ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್ ಸಿಗುತ್ತದೆ.ನವೀನ್ ಅವರಂತಹ ವೃತ್ತಿಗಳು ಮತ್ತು ವೆಲ್ಡಿಂಗ್, ಮರಗೆಲಸದಂತಹ ಇತರ ವೃತ್ತಿಗಳು ಸಾಮಾನ್ಯವಾಗಿ ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್‌ಗಳನ್ನು ಹೊಂದಿವೆ.ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್ ಒಂದು ಇಂಟರ್ನ್‌ಶಿಪ್‌ನಂತಿದೆ, ಅಲ್ಲಿ ಒಬ್ಬರು ಮಾಡುವ ಮೂಲಕ ಕಲಿಯುತ್ತಾರೆ, ಕೆಲಸದ ವಾತಾವರಣ ಮತ್ತು ಕೆಲಸದ ದೈನಂದಿನ ಅನುಭವಕ್ಕೆ ಒಡ್ಡಿಕೊಳ್ಳುತ್ತಾರೆ.ಇವು ಆರು ತಿಂಗಳಿಂದ 2 ವರ್ಷಗಳವರೆಗೆ ಇರಬಹುದು. ಶಿಷ್ಯವೃತ್ತಿಯ ಸಮಯದಲ್ಲಿ, ವೃತ್ತಿಯ ವಿವರಗಳನ್ನು ತಿಳಿದುಕೊಳ್ಳಲು ನೀವು ಅನುಭವಿ ಮತ್ತು ನುರಿತ ವೃತ್ತಿಪರರೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುತ್ತೀರಿ.",
            "te": "నవీన్ KPCLలో అప్రెంటిస్‌షిప్ పొందాడు. నవీన్ మరియు వెల్డింగ్ మరియు వడ్రంగి వంటి ఇతర వృత్తులు తరచుగా అప్రెంటిస్‌షిప్‌లను కలిగి ఉంటాయి. అప్రెంటిస్‌షిప్ అనేది ఇంటర్న్‌షిప్ లాంటిది, ఇక్కడ ఒకరు స్వయంగా పని చేయడం, పని వాతావరణం మరియు రోజువారీ పని అనుభవాన్ని అలవరచుకోవడం ద్వారా నేర్చుకుంటారు. ఇవి ఆరు నెలల నుండి 2 సంవత్సరాల వరకు ఉండవచ్చు. అప్రెంటిస్‌షిప్ సమయంలో, వృత్తి వివరాలను తెలుసుకోవడానికి మీరు అనుభవజ్ఞుడైన మరియు నైపుణ్యం కలిగిన నిపుణులతో కలిసి పని చేస్తారు.",
            "or": "ନବୀନ KPCL ରେ ଏକ ଆପ୍ରେଣ୍ଟିସିପ୍ ପ୍ରାପ୍ତ କରନ୍ତି | ନବୀନଙ୍କ ବୃତ୍ତି ପରି ଏବଂ ଅନ୍ୟ ଯଥା ୱେଲଡିଂ, କାର୍ପେଣ୍ଟ୍ରିଂ ଭଳି ବୃତ୍ତିଗୁଡ଼ିକରେ ପ୍ରାୟତଃ ଆପ୍ରେଣ୍ଟିସିପ୍ ଥାଏ | ଆପ୍ରେଣ୍ଟିସିପ୍ ଏକ ଇଣ୍ଟର୍ନସିପ୍ ପରି, ଯେଉଁଠାରେ ଜଣେ କାର୍ଯ୍ୟ କରି, କାର୍ଯ୍ୟ ପରିବେଶକୁ ଏକ୍ସପୋଜ୍‌ ହୋଇ ଏବଂ କାର୍ଯ୍ୟର ଦୈନନ୍ଦିନ ଅଭିଜ୍ଞତା ଦ୍ୱାରା ଶିଖେ | । ଏଗୁଡିକ ଛଅ ମାସରୁ 2 ବର୍ଷ ଦୀର୍ଘ ହୋଇପାରେ | ଆପ୍ରେଣ୍ଟିସିପ୍ ସମୟରେ, ଆପଣ ବୃତ୍ତିର ସବିଶେଷ ତଥ୍ୟ ଜାଣିବା ପାଇଁ ଜଣେ ଅଭିଜ୍ଞ ଏବଂ କୁଶଳୀ ବୃତ୍ତିଗତଙ୍କ ସହିତ କାର୍ଯ୍ୟ କରନ୍ତି |",
            "as": "নবীনে KPCLত এটা এপ্ৰেণ্টিছশ্বিপ পদ লাভ কৰে। নবীনৰ দৰে বৃত্তি আৰু ৱেল্ডিং, কাৰ্পেণ্ট্ৰীৰ দৰে আন বৃত্তিত প্ৰায়ে এপ্ৰেণ্টিছশ্বিপ থাকে। এপ্ৰেণ্টিছশ্বিপ হৈছে এক ইণ্টাৰ্নশ্বিপৰ দৰে, য'ত এজনে কামৰ দ্বাৰা শিকে, কামৰ পৰিৱেশ আৰু কামৰ দৈনন্দিন অভিজ্ঞতাৰ সংস্পৰ্শলৈ আহে। এইবোৰ ছয় মাহৰ পৰা ২ বছৰীয়া হ'ব পাৰে। এপ্ৰেণ্টিছশ্বিপৰ সময়ছোৱাত, তুমি বৃত্তিটোৰ বিষয়ে শিকিবলৈ এজন অভিজ্ঞ আৰু দক্ষ পেছাদাৰীৰ সৈতে কাম কৰিব লাগিব।",
            "gu": "નવીનને KPCLમાં એપ્રેન્ટિસશિપ મળે છે. ધંધા જેમ કે જે નવીનને લગતા વળગતા હોય અને વેલ્ડીંગ, સુથારીકામ જેવા ધંધામાં ઘણીવાર એપ્રેન્ટીસશીપ હોય છે. એપ્રેન્ટિસશીપ એ ઇન્ટર્નશીપ જેવી છે, જ્યાં શીખવા મળે છે વ્યક્તિને, કામનું વાતાવરણ અને દરોજનું કામના  અનુભવ સાથે સંપર્કમાં આવીને શીખે છે. આનો સમય છ મહિનાથી બે વર્ષનો સુધીનો હોઈ શકે છે. એપ્રેન્ટિસશીપ દરમિયાન, તમે વ્યવસાયની વિગતો જાણવા માટે અનુભવી અને કુશળ ધંધાદારી સાથે કામ કરો છો."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "mcQ",
            "hi": "mcQ",
            "ka": "ಎಂಸಿಕ್ಯೂ",
            "te": "mcQ",
            "or": "mcQ",
            "as": "mcQ",
            "gu": "mcQ"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Select which statement about apprenticeships is TRUE:",
            "hi": "अप्रेंटिसशिप के लिए निम्नलिखित में से ‘सही’ विधान चुने:",
            "ka": "ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್‌ಗಳ ಕುರಿತು ಯಾವ ಹೇಳಿಕೆ ನಿಜವಾಗಿದೆ ಎಂಬುದನ್ನು ಆಯ್ಕೆಮಾಡಿ:",
            "te": "అప్రెంటిస్‌షిప్‌ల గురించి ఏ ప్రకటన సరైనదో ఎంచుకోండి :",
            "or": "ଆପ୍ରେଣ୍ଟିସିପ୍ ବିଷୟରେ କେଉଁ ଷ୍ଟେଟମେଣ୍ଟ ଟି ସତ୍ଯ ଅଟେ ତାହା ବାଛନ୍ତୁ:",
            "as": "এপ্ৰেণ্টিছশ্বিপ বিষয়ে কোনটো মন্তব্য সঁচা বাছনি কৰা:",
            "gu": "નીચેના વાક્યોમાંથી કયો એપ્રેન્ટિસશિપ માટે સાચો છે તે જુવો:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "The timeline of an internship and apprenticeship is the same.",
            "hi": "इन्टर्नशिप और अप्रेंटिसशिप का कालावधि समान होता है.",
            "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಮತ್ತು ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್‌ನ ಟೈಮ್‌ಲೈನ್ ಒಂದೇ ಆಗಿರುತ್ತದೆ.",
            "te": "ఇంటర్న్‌షిప్ మరియు అప్రెంటిస్‌షిప్ కాలక్రమం ఒకేలా ఉంటుంది.",
            "or": "ଏକ ଇଣ୍ଟର୍ନସିପ୍ ଏବଂ ଆପ୍ରେଣ୍ଟିସିପ୍ ର ଟାଇମ୍ ଲାଇନ୍ ସମାନ ଅଟେ |",
            "as": "ইণ্টাৰ্নশ্বিপ আৰু এপ্ৰেণ্টিছশ্বিপৰ সময়সীমা একে।",
            "gu": "ઇન્ટર્નશિપ અને એપ્રેન્ટિસશિપની સમયગાળો સરખો છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "The goal of an apprenticeship is to learn the details of the profession from a professional.",
            "hi": "अप्रेंटिसशिप का उद्देश्य है प्रोफेशनल व्यक्तियों से व्यवसाय के बारे में बारीकी से सीखना.",
            "ka": "ವೃತ್ತಿಪರರಿಂದ ವೃತ್ತಿಯ ವಿವರಗಳನ್ನು ಕಲಿಯುವುದು ಶಿಷ್ಯವೃತ್ತಿಯ ಗುರಿಯಾಗಿದೆ.",
            "te": "అప్రెంటిస్‌షిప్ యొక్క లక్ష్యం ప్రొఫెషనల్ నుండి వృత్తి వివరాలను తెలుసుకోవడం.",
            "or": "ଏକ ଇଣ୍ଟର୍ନସିପ୍ ର ଲକ୍ଷ୍ୟ ହେଉଛି ଜଣେ ବୃତ୍ତିଗତଙ୍କଠାରୁ ବୃତ୍ତିର ସବିଶେଷ ତଥ୍ୟ ଶିଖିବା |",
            "as": "এজন এপ্ৰেণ্টিছশ্বিপৰ লক্ষ্য হ'ল এজন পেছাদাৰীৰ পৰা বৃত্তিটোৰ বিৱৰণ শিকা।",
            "gu": "એપ્રેન્ટિસશીપનું ધ્યેય એ છે કે ધંધાદારી પાસેથી ધંધાની વિગતો શીખ શકાય છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Apprenticeships are a requirement for some courses to earn their learning certification.",
            "hi": "कुछ कोर्सेस में लर्निंग सर्टिफिकेट प्राप्त करने के लिए अप्रेंटिसशिप जरूरी होती है",
            "ka": "ಕೆಲವು ಕೋರ್ಸ್‌ಗಳಿಗೆ ತಮ್ಮ ಕಲಿಕೆಯ ಪ್ರಮಾಣೀಕರಣವನ್ನು ಪಡೆಯಲು ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್‌ಗಳು ಅಗತ್ಯವಾಗಿವೆ.",
            "te": "కొన్ని కోర్సులు తమ లెర్నింగ్ సర్టిఫికేషన్‌ను పొందేందుకు అప్రెంటిస్‌షిప్‌లు తప్పనిసరి.",
            "or": "ସେମାନଙ୍କର ଶିକ୍ଷଣ ପ୍ରମାଣପତ୍ର ଅର୍ଜନ କରିବା ପାଇଁ କିଛି ପାଠ୍ୟକ୍ରମ ପାଇଁ ଆପ୍ରେଣ୍ଟିସିପ୍‌ ଏକ ଆବଶ୍ୟକତା ଅଟେ |",
            "as": "কিছুমান পাঠ্যক্ৰমৰ বাবে শিকাৰ প্ৰমাণপত্ৰ অৰ্জন কৰাৰ বাবে এপ্ৰেণ্টিছশ্বিপ কৰাটো প্ৰয়োজনীয়।",
            "gu": "કેટલાક અભ્યાસક્રમો માટે તેમના શિક્ષણ પ્રમાણપત્ર મેળવવા માટે એપ્રેન્ટિસશીપ આવશ્યક છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Think again! Internships are typically a shorter duration than an apprenticeship.",
            "hi": "फिर से सोचो! इन्टर्नशिप आमतौर पर अप्रेंटिसशिप से कम समय के लिए होती है.",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳು ಸಾಮಾನ್ಯವಾಗಿ ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್‌ಗಿಂತ ಕಡಿಮೆ ಅವಧಿಯಾಗಿರುತ್ತದೆ.",
            "te": "మళ్లీ ఆలోచించండి! ఇంటర్న్‌షిప్‌లు సాధారణంగా అప్రెంటిస్‌షిప్ కంటే తక్కువ కాలం ఉంటాయి.",
            "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ଇଣ୍ଟର୍ନସିପ୍ ସାଧାରଣତଃ ଏକ ଆପ୍ରେଣ୍ଟିସିପ୍ ଅପେକ୍ଷା ଏକ ସ୍ୱଳ୍ପ ସମୟ ଅଟେ |",
            "as": "আকৌ চিন্তা কৰা! ইণ্টাৰ্নশ্বিপ সাধাৰণতে এপ্ৰেণ্টিছশ্বিপতকৈ কম সময়ৰ হয়।",
            "gu": "વિચારો ફરીથી! ઇન્ટર્નશીપ સામાન્ય રીતે એપ્રેન્ટીસશીપ કરતાં ટૂંકા સમયગાળાની હોય છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "बहुत बढ़िया!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "బాగా చెప్పారు!",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ !",
            "as": "ভাল কৰিছা!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "बहुत बढ़िया!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "బాగా చెప్పారు!",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ !",
            "as": "ভাল কৰিছা!",
            "gu": "સરસ કામ!"
          }
        }
      ]
    },
    "scene20": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_1_1",
            "hi": "C8_1_1",
            "ka": "C8_1_1",
            "te": "C8_1_1",
            "or": "C8_1_1",
            "as": "C8_1_1",
            "gu": "C8_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen and Puneet begin working at KPCL as apprentices. They both like the work that they have to do.",
            "hi": "नवीन और पुनीत KPCL में अप्रेंटिसशिप करना शुरू कर देते हैं. उन दोनों को अपना काम पसंद हैं.",
            "ka": "ನವೀನ್ ಮತ್ತು ಪುನೀತ್ ಕೆಪಿಸಿಲ್ ನಲ್ಲಿ ಅಪ್ರೆಂಟಿಸ್ ಆಗಿ ಕೆಲಸ ಮಾಡಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ. ಅವರಿಬ್ಬರಿಗೂ ತಾವು ಮಾಡಬೇಕಾದ ಕೆಲಸ ಇಷ್ಟ.",
            "te": "నవీన్ మరియు పునీత్ KPCLలో అప్రెంటిస్‌లుగా పని చేయడం మొదలు పెట్టారు. వారిద్దరూ చేయాల్సిన పనిని ఇష్టపడతారు.",
            "or": "ନବୀନ ଏବଂ ପୁନିତ ଆପ୍ରେଣ୍ଟିସ୍‌ ଭାବରେ KPCL ରେ କାମ କରିବା ଆରମ୍ଭ କରନ୍ତି | ସେମାନେ କରିବାକୁ ଥିବା କାର୍ଯ୍ୟକୁ ଉଭୟେ ପସନ୍ଦ କରନ୍ତି |",
            "as": "নবীন আৰু পুনীতে KPCLত শিক্ষানবিশ হিচাপে কাম কৰা আৰম্ভ কৰে। তেওঁলোক দুয়োজনে কৰিব লগা কামটো ভাল পাইছে।",
            "gu": "નવીન અને પુનીત KPCLમાં એપ્રેન્ટિસ તરીકે કામ કરવાનું શરૂ કરે છે. બંનેને જે કામ કરવાનું હોય તે ગમે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_1_2",
            "hi": "C8_1_2",
            "ka": "C8_1_2",
            "te": "C8_1_2",
            "or": "C8_1_2",
            "as": "C8_1_2",
            "gu": "C8_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen is happy that Puneet got the apprenticeship with him. He enjoys his company at work. He is glad to have a friend around at work.",
            "hi": "नवीन को खुशी है कि पुनीत को भी उसके साथ अप्रेंटिसशिप मिली. वह वह पुनीत के साथ काम करना पसंद करता है. उसे खुशी है कि, काम की जगह पर उसका एक दोस्त भी है.",
            "ka": "ಪುನೀತ್ ತನ್ನ ಬಳಿ ಶಿಷ್ಯವೃತ್ತಿ ಪಡೆದಿದ್ದಕ್ಕೆ ನವೀನ್ ಖುಷಿಯಾಗಿದ್ದಾನೆ. ಅವನು ಕೆಲಸದಲ್ಲಿ ಅವನ ಕಂಪನಿಯನ್ನು ಆನಂದಿಸುತ್ತಾನೆ. ಕೆಲಸದಲ್ಲಿ ಸ್ನೇಹಿತನನ್ನು ಹೊಂದಲು ಅವನು ಸಂತೋಷಪಡುತ್ತಾನೆ.",
            "te": "పునీత్ కి కూడా తనతో పాటు అప్రెంటిస్‌షిప్ వచ్చినందుకు నవీన్ సంతోషంగా ఉన్నాడు. అతను పనిలో తన కంపెనీని ఎంజాయ్ చేస్తున్నాడు. అతను పని వద్ద తన మిత్రుడు కూడా ఉన్నందుకు సంతోషిస్తున్నాడు.",
            "or": "ପୁନିତ ତାଙ୍କ ସହ ଆପ୍ରେଣ୍ଟିସିପ୍ ପାଇଥିବାରୁ ନବୀନ ଖୁସି ଅଛନ୍ତି। କାର୍ଯ୍ୟରେ ସେ ତାଙ୍କ କମ୍ପାନୀକୁ ଉପଭୋଗ କରନ୍ତି | କାର୍ଯ୍ୟକ୍ଷେତ୍ରରେ ତାଙ୍କର ବନ୍ଧୁ ଥିବାରୁ ସେ ଖୁସି |",
            "as": "নবীন সুখী যে পুনীতে তাৰ সৈতে এপ্ৰেণ্টিছশ্বিপ পালে। সি কামত তাৰ সংগ উপভোগ কৰে। কামত বন্ধু এজন পাই সি আনন্দিত।",
            "gu": "નવીન અને પુનિત બંનેને એક સાથે એપ્રેન્ટિસશિપ મળી તેથી તે ખુશ  છે. કામ પર તેઓ તેની કંપનીનો આનંદ માણે છે. કામ પર મિત્ર મળવાથી તે ખુશ છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_1_3",
            "hi": "C8_1_3",
            "ka": "C8_1_3",
            "te": "C8_1_3",
            "or": "C8_1_3",
            "as": "C8_1_3",
            "gu": "C8_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen likes the apprenticeship as he is getting trained under experienced engineers. It is practice-oriented and an effective learning experience.",
            "hi": "नवीन को अप्रेंटिसशिप अच्छी लगती है, क्योंकि उसे अनुभवी इंजीनियरिंग के साथ ट्रेनिंग करने का मौका मिलता है. यह प्रैक्टिस-ओरिएंटेड काम है और वह प्रभावी रूप से सीख सकता है.",
            "ka": "ಅನುಭವಿ ಇಂಜಿನಿಯರ್‌ಗಳ ಬಳಿ ತರಬೇತಿ ಪಡೆಯುತ್ತಿರುವುದರಿಂದ ನವೀನ್‌ಗೆ ಶಿಷ್ಯವೇತನ ಇಷ್ಟವಾಗಿದೆ. ಇದು ಅಭ್ಯಾಸ-ಆಧಾರಿತ ಮತ್ತು ಪರಿಣಾಮಕಾರಿ ಕಲಿಕೆಯ ಅನುಭವವಾಗಿದೆ.",
            "te": "అనుభవజ్ఞులైన ఇంజనీర్ల వద్ద శిక్షణ పొందుతున్నందున నవీన్‌కి శిష్యరికం ఇష్టం. ఇది అభ్యాస-ఆధారిత మరియు సమర్థవంతమైన అభ్యాస అనుభవం.",
            "or": "ଅଭିଜ୍ଞ ଇଞ୍ଜିନିୟରମାନଙ୍କ ଅଧୀନରେ ତାଲିମ ନେଉଥିବାରୁ ନବୀନ ଆପ୍ରେଣ୍ଟିସିପ୍କୁ ପସନ୍ଦ କରନ୍ତି | ଏହା ଅଭ୍ୟାସ ଭିତ୍ତିକ ଏବଂ ଏକ ପ୍ରଭାବଶାଳୀ ଶିକ୍ଷଣ ଅଭିଜ୍ଞତା ଅଟେ |",
            "as": "নবীনে এপ্ৰেণ্টিছশ্বিপ ভাল পাইছে কিয়নো সি অভিজ্ঞ অভিযন্তাসকলৰ অধীনত প্ৰশিক্ষণ লাভ কৰি আছে। ই অনুশীলনমুখী আৰু এক কাৰ্যকৰী শিক্ষাৰ অভিজ্ঞতা।",
            "gu": "નવીનને એપ્રેન્ટિસશિપ ગમે છે કારણ કે તે અનુભવી એન્જિનિયરો હેઠળ તાલીમ મેળવી રહ્યો છે. તે અભ્યાસ લક્ષી અને અસરકારક શીખવાનો અનુભવ છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_1_4",
            "hi": "C8_1_4",
            "ka": "C8_1_4",
            "te": "C8_1_4",
            "or": "C8_1_4",
            "as": "C8_1_4",
            "gu": "C8_1_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He learns a range of skills. He gets the confidence to handle and fix simple and complex machines. Because of his nature, he is also considered as the most helpful apprentices at KPCL.",
            "hi": "वह कई तरह के कौशल सीखता है. वह सरल और जटिल मशीन हैंडल और रिपेयर कर सकता करता है. उसके स्वभाव के कारण उसे KPCL में सबसे मददगार अप्रेंटिस समझा जाता है.",
            "ka": "ಅವನು ಹಲವಾರು ಕೌಶಲ್ಯಗಳನ್ನು ಕಲಿಯುತ್ತಾನೆ. ಸರಳ ಮತ್ತು ಸಂಕೀರ್ಣ ಯಂತ್ರಗಳನ್ನು ನಿಭಾಯಿಸಲು ಮತ್ತು ಸರಿಪಡಿಸಲು ಅವನು ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಪಡೆಯುತ್ತಾನೆ. ಅವನ ಸ್ವಭಾವದಿಂದಾಗಿ, ಅವನನ್ನು ಕೆಪಿಸಿಲ್ ನಲ್ಲಿ ಅತ್ಯಂತ ಸಹಾಯಕವಾದ ಅಪ್ರೆಂಟಿಸ್ ಎಂದು ಪರಿಗಣಿಸಲಾಗಿದೆ.",
            "te": "అతను అనేక రకాల నైపుణ్యాలను నేర్చుకుంటాడు. అతను సాధారణ మరియు సంక్లిష్టమైన యంత్రాలను నిర్వహించడానికి మరియు పరిష్కరించగల విశ్వాసాన్ని పొందుతాడు. అతని స్వభావం కారణంగా, అతను KPCLలో అత్యంత సహాయకరమైన అప్రెంటిస్‌గా కూడా పరిగణించబడ్డాడు.",
            "or": "ସେ ବିଭିନ୍ନ ପ୍ରକାରର କୌଶଳ ଶିଖନ୍ତି | ସରଳ ଏବଂ ଜଟିଳ ଯନ୍ତ୍ରଗୁଡ଼ିକୁ ପରିଚାଳନା ଏବଂ ଠିକ୍ କରିବା ପାଇଁ ସେ ଆତ୍ମବିଶ୍ୱାସ ପାଆନ୍ତି | ତାଙ୍କର ପ୍ରକୃତି ହେତୁ, ସେ KPCL ରେ ସବୁଠାରୁ ସାହାଯ୍ୟକାରୀ ଆପ୍ରେଣ୍ଟିସ୍‌ ଭାବରେ ମଧ୍ୟ ବିବେଚିତ ହୁଅନ୍ତି |",
            "as": "সি বিভিন্ন দক্ষতাবিলাক শিকে। সি সৰল আৰু জটিল যন্ত্ৰবোৰ চম্ভালিবলৈ আৰু ঠিক কৰিবলৈ আত্মবিশ্বাস লাভ কৰে। তাৰ স্বভাৱৰ বাবে,তাক KPCLৰ আটাইতকৈ সহায়ক শিক্ষানবিশ হিচাপেও গণ্য কৰা হয়।",
            "gu": "તે વિવિધ પ્રકારની કુશળતા શીખે છે. તેને સરળ અને જટિલ મશીનોને સંભળવાનું અને સરખું કરવાનો આત્મવિશ્વાસ મળે છે. તેમના સ્વભાવને કારણે, તેમને KPCLમાં સૌથી મદદરૂપ તાલીમાર્થી તરીકે પણ ગણવામાં આવે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_2_1",
            "hi": "C8_2_1",
            "ka": "C8_2_1",
            "te": "C8_2_1",
            "or": "C8_2_1",
            "as": "C8_2_1",
            "gu": "C8_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "They both work as apprentices for a year. At the end of their term, they find that KPCL has a few permanent positions open. They both decide to apply for them.",
            "hi": "वे दोनों एक साल तक अप्रेंटिस के तौर पर काम करते हैं. उन की अवधि समाप्त होने पर, उन्हें पता चलता है कि, KPCL में कुछ परमानेंट जॉब भी उपलब्ध हैं. वे दोनों इसके लिए अप्लाई करने का निर्णय लेते हैं.",
            "ka": "ಇಬ್ಬರೂ ಒಂದು ವರ್ಷ ಅಪ್ರೆಂಟಿಸ್‌ಗಳಾಗಿ ಕೆಲಸ ಮಾಡುತ್ತಾರೆ. ಅವರ ಅವಧಿಯ ಕೊನೆಯಲ್ಲಿ, ಕೆಪಿಸಿಲ್ ಕೆಲವು ಖಾಯಂ ಹುದ್ದೆಗಳನ್ನು ತೆರೆದಿರುವುದನ್ನು ಅವರು ಕಂಡುಕೊಂಡಿದ್ದಾರೆ. ಇಬ್ಬರೂ ಅದಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿರ್ಧರಿಸಿದ್ದಾರೆ",
            "te": "వారిద్దరూ ఏడాదిపాటు అప్రెంటిస్‌లుగా పనిచేస్తున్నారు. వారి పదవీకాలం ముగిసే సమయానికి, KPCL కొన్ని శాశ్వత స్థానాలను తెరిచినట్లు వారు కనుగొన్నారు. వారిద్దరూ వాటి కోసం దరఖాస్తు చేసుకోవాలని నిర్ణయించుకున్నారు.",
            "or": "ଉଭୟେ ଏକ ବର୍ଷ ପାଇଁ ଆପ୍ରେଣ୍ଟିସ୍‌ ଭାବରେ କାର୍ଯ୍ୟ କରନ୍ତି | ସେମାନଙ୍କର କାର୍ଯ୍ୟକାଳ ଶେଷରେ, ସେମାନେ ପାଇଲେ ଯେ KPCL ର କିଛି ସ୍ଥାୟୀ ପଦବୀ ଖୋଲା ଅଛି | ଉଭୟେ ସେମାନଙ୍କ ପାଇଁ ଆବେଦନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
            "as": "তেওঁলোক দুয়োটাই এবছৰৰ বাবে শিক্ষানবিশ হিচাপে কাম কৰে। তেওঁলোকৰ কাৰ্যকালৰ শেষত, তেওঁলোকে দেখিলে যে KPCLত কেইটামান স্থায়ী পদ ওলাইছে। তেওঁলোক দুয়োজনে আবেদন কৰাৰ সিদ্ধান্ত লয়।",
            "gu": "તેઓ બંને એક વર્ષ માટે એપ્રેન્ટીસ તરીકે કામ કરે છે. તેમના કાર્યકાળના અંતે, તેમણે જાણવા મળ્યું કે KPCLમાં કેટલીક કાયમી જગ્યાઓ ભરવાની બાકી છે. તેથી બંનેએ તેના માટે અરજી કરવાનું નક્કી કર્યું."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_2_2//",
            "hi": "C8_2_2//",
            "ka": "C8_2_2//",
            "te": "C8_2_2//",
            "or": "C8_2_2//",
            "as": "C8_2_2//",
            "gu": "C8_2_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Puneet",
            "hi": "पुनीत",
            "ka": "ಪುನೀತ್",
            "te": "పునీత్",
            "or": "ପୁନିତ",
            "as": "পুনীত",
            "gu": "પુનિત"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "I’m so nervous about this Puneet. I have a feeling I might not get the role.",
            "hi": "मुझे डर लग रहा है, पुनीत. मुझे लग रहा है कि, मुझे यह जॉब नहीं मिलेगी.",
            "ka": "ಈ ಪುನೀತ್ ಬಗ್ಗೆ ನನಗೆ ತುಂಬಾ ನರ್ವಸ್ ಆಗಿದೆ. ನನಗೆ ಪಾತ್ರ ಸಿಗುವುದಿಲ್ಲ ಎಂಬ ಭಾವನೆ ಇದೆ.",
            "te": "పునీత్, దీని గురించి నేను చాలా భయంగా ఉన్నాను. నాకు ఆ స్థానం రాదని అనిపిస్తుంది.",
            "or": "ମୁଁ ଏହି ବିଷୟରେ ବହୁତ ଚିନ୍ତିତ ପୁନିତ | ମୋତେ ଲାଗୁଛି ମୁଁ ଭୂମିକା ପାଇ ନପାରେ |",
            "as": "মই এইটো লৈ বহুত চিন্তিত পুনীত। মোৰ এটা অনুভৱ হৈছে যে মই পদটো নাপাম।",
            "gu": "હું ખૂબ જ ઉદાસ છું આ વિશે, પુનીત. મને એવો અહેસાસ છે કે કદાચ મને આ કર્તવ્ય નહીં મળે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Don’t stress Naveen, you realise how popular you are at KPCL? You will definitely get the job.",
            "hi": "चिंता मत करो नवीन, तुम्हें पता है कि तुम KPCL में कितने लोकप्रिय हो? तुम्हें यह जॉब जरूर मिल जाएगी.",
            "ka": "ಒತ್ತಡ ಹೇರಬೇಡ ನವೀನ್‌, ಕೆಪಿಸಿಎಲ್‌ನಲ್ಲಿ ನೀನು ಎಷ್ಟು ಜನಪ್ರಿಯನಾಗಿದ್ದೀಯ ಎಂದು ನಿನಗೆ ತಿಳಿದಿದೆಯೇ? ನಿನಗೆ ಕೆಲಸ ಖಂಡಿತ ಸಿಗುತ್ತದೆ.",
            "te": "నవీన్‌ను ఒత్తిడి చేయకు, నువ్వు KPCLలో ఎంత పాపులర్ అయ్యావో నీకు తెలుసు కదా? నీకు తప్పకుండా ఉద్యోగం వస్తుంది.",
            "or": "ସ୍ଟ୍ରେସ୍‌ ନିଅ ନାହିଁ ନବୀନ, ତୁମେ ଜାଣିଛ KPCL ରେ ତୁମେ କେତେ ଲୋକପ୍ରିୟ? ତୁମେ ନିଶ୍ଚିତ ଭାବରେ ଚାକିରି ପାଇବ |",
            "as": "চাপত নপৰিবা নবীন, তুমি জানানে যে KPCLত তুমি কিমান জনপ্ৰিয়? তুমি নিশ্চিতভাৱে চাকৰিটো পাবা।",
            "gu": "નવીન બહુજ ગભરાઈ નહીં, તને ખ્યાલ છે કે તું KPCLમાં કેટલા લોકપ્રિય છો? તને ચોક્કસ નોકરી મળશે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "I wish it was like that da! You know that it is based on the test results, extra courses and our 10th marks, not the popularity.",
            "hi": "काश यह सच हो, दा! पर आपको पता है ना कि, यह परीक्षा के रिजल्ट, अतिरिक्त कोर्स और हमारे 10वीं के गुणों पर आधारित है, लोकप्रियता पर नहीं.",
            "ka": "ಅದು ಹಾಗೆ ಇರಬೇಕೆಂದು ನಾನು ಬಯಸುತ್ತೇನೆ! ಇದು ಪರೀಕ್ಷಾ ಫಲಿತಾಂಶಗಳು, ಹೆಚ್ಚುವರಿ ಕೋರ್ಸ್‌ಗಳು ಮತ್ತು ನಮ್ಮ 10 ನೇ ಅಂಕಗಳನ್ನು ಆಧರಿಸಿದೆ ಎಂದು ನಿನಗೆ ತಿಳಿದಿದೆ ಹೊರತು ಜನಪ್ರಿಯತೆಯಲ್ಲ.",
            "te": "అలా అయితే బాగుండు కదా! ఇది పాపులారిటీ పైన కాకుండా పరీక్ష ఫలితాలు, అదనపు కోర్సులు మరియు మన 10వ తరగతి మార్కులపై ఆధారపడి ఉంటుందని నీకు తెలుసు.",
            "or": "ମୋ ଇଚ୍ଛା ଏହା ସେହି ପରି ହୋଇଥାନ୍ତା ଦା ! ତୁମେ ଜାଣିଛ ଯେ ଏହା ପରୀକ୍ଷା ଫଳାଫଳ, ଅତିରିକ୍ତ ପାଠ୍ୟକ୍ରମ ଏବଂ ଆମର 10th ମାର୍କ ଉପରେ ଆଧାରିତ, ଲୋକପ୍ରିୟତା ଉପରେ ନୁହେଁ |",
            "as": "মই আশা কৰোঁ যদি তেনে হ'লেহেঁতেন! তুমি জানা যে পৰীক্ষাৰ ফলাফল, অতিৰিক্ত পাঠ্যক্ৰম আৰু আমাৰ দশম শ্ৰেণীৰ নম্বৰৰ ওপৰত ভিত্তি কৰিহে ল’ব, জনপ্ৰিয়তাৰ ওপৰত নহয়।",
            "gu": "હું માનું છું કે એ કાશ એવું હોત ભાઈ! તું જાણો છે કે તે કસોટીના પરિણામો, વધારાના અભ્યાસક્રમો અને આપના 10મા ધોરણના ગુણ પર આધારિત છે, લોકપ્રિયતા પર નહીં."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_2_3////",
            "hi": "C8_2_3////",
            "ka": "C8_2_3////",
            "te": "C8_2_3////",
            "or": "C8_2_3////",
            "as": "C8_2_3////",
            "gu": "C8_2_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Yes, of course, but that isn’t the only thing that matters.",
            "hi": "हाँ पता है, लेकिन केवल यहीं चीजें मायने नहीं रखती",
            "ka": "ಹೌದು, ಸಹಜವಾಗಿ, ಆದರೆ ಇದು ಮುಖ್ಯವಾದ ಏಕೈಕ ವಿಷಯವಲ್ಲ.",
            "te": "అవును, వాస్తవానికి, కానీ అది మాత్రమే ముఖ్యమైనది కాదు.",
            "or": "ହଁ, ଅବଶ୍ୟ, କିନ୍ତୁ ତାହା ଏକମାତ୍ର ଜିନିଷ ନୁହେଁ ଯାହା ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ଅଟେ |",
            "as": "হয় অৱশ্যে, কিন্তু সেইটোৱেই একমাত্ৰ গুৰুত্বপূৰ্ণ কথা নহয়।",
            "gu": "હા, ચોક્કસ, પરંતુ તે એકમાત્ર એવું નથી જે મહત્વપૂર્ણ છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "There are ITI marks and our experience as well. All these are taken into account for selection. I haven't done extra courses like you.",
            "hi": "आईटीआई के मार्क्स और हमारे अनुभव भी मायने रखते हैं. सिलेक्शन के वक्त इन सब को ध्यान में रखा जाता है. मैंने आपके जैसा कोई भी अतिरिक्त कोर्स नहीं किया था.",
            "ka": "ಐಟಿಐ ಅಂಕಗಳು ಮತ್ತು ನಮ್ಮ ಅನುಭವವೂ ಇದೆ. ಆಯ್ಕೆಗಾಗಿ ಇವೆಲ್ಲವನ್ನೂ ಗಣನೆಗೆ ತೆಗೆದುಕೊಳ್ಳಲಾಗುತ್ತದೆ. ನಾನು ನಿನಂತೆ ಹೆಚ್ಚುವರಿ ಕೋರ್ಸ್‌ಗಳನ್ನು ಮಾಡಿಲ್ಲ.",
            "te": "ITI మార్కులు మరియు మా అనుభవం కూడా ఉన్నాయి. ఎంపిక కోసం ఇవన్నీ పరిగణనలోకి తీసుకుంటారు. నేను మీలాగా అదనపు కోర్సులు చేయలేదు.",
            "or": "ସେଠାରେ ITI ମାର୍କ ଏବଂ ଆମର ଅଭିଜ୍ଞତା ମଧ୍ୟ ଅଛି | ଚୟନ ପାଇଁ ଏସବୁକୁ ବିଚାରକୁ ନିଆଯାଏ | ମୁଁ ତୁମ ପରି ଅତିରିକ୍ତ ପାଠ୍ୟକ୍ରମ କରିନାହିଁ |",
            "as": "ITIৰ নম্বৰ আৰু লগতে আমাৰ অভিজ্ঞতাও আছে। এই সকলোবোৰ বাছনিৰ বাবে বিবেচনা কৰা হ’ব। মই তোমাৰ দৰে অতিৰিক্ত পাঠ্যক্ৰম কৰা নাই।",
            "gu": "ITIના ગુણ અને આપણો અનુભવ પણ છે. આ બધુ પસંદગી માટે ધ્યાનમાં લેવામાં આવે છે. મેં તારા જેવા વધારાના અભ્યાસક્રમો નથી કર્યા."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Don’t think so negatively.",
            "hi": "ऐसी नकारात्मक बातें मत सोचो.",
            "ka": "ಹಾಗೆ ನಕಾರಾತ್ಮಕವಾಗಿ ಯೋಚಿಸಬೇಡ.",
            "te": "అలా నెగెటివ్‌ (ప్రతికూలం) గా ఆలోచించవద్దు.",
            "or": "ଏତେ ନକାରାତ୍ମକ ଭାବ ନାହିଁ |",
            "as": "ইমান নেতিবাচকভাৱে নাভাবিবা।",
            "gu": "આવા નકારાત્મક વિચારો ના કર."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "I’m just realistic. I am sure you will get the job, Puneet. Trust me.",
            "hi": "मैं बस सच बात कर रहा हूँ। मुझे यकीन है कि तुम्हें यह जॉब जरुर मिल जाएगी, पुनीत। विश्वास रखो।",
            "ka": "ನಾನು ಕೇವಲ ವಾಸ್ತವಿಕ. ಪುನೀತ್ ನಿನಗೆ ಕೆಲಸ ಸಿಗುವುದು ಖಚಿತ. ನನ್ನನ್ನು ನಂಬು.",
            "te": "నేను వాస్తవికంగా ఉన్నాను. నీకు ఉద్యోగం వస్తుందని నేను నమ్ముతున్నాను పునీత్. నన్ను నమ్ము.",
            "or": "ମୁଁ କେବଳ ବାସ୍ତବବାଦୀ ଅଟେ | ମୁଁ ନିଶ୍ଚିତ ଯେ ତୁମେ ଚାକିରି ପାଇବ, ପୁନିତ | ମୋତେ ବିଶ୍ୱାସ କର।",
            "as": "মই কেৱল বাস্তৱবাদী। মই নিশ্চিত যে তুমি চাকৰিটো পাবা, পুনীত। মোক বিশ্বাস কৰা।",
            "gu": "હું માત્ર એક સાચાઈ પર આધાર રાખું છું. મને ખાતરી છે કે તને નોકરી મળી જશે, પુનીત. મારા પર વિશ્વાસ કર."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_2_4",
            "hi": "C8_2_4",
            "ka": "C8_2_4",
            "te": "C8_2_4",
            "or": "C8_2_4",
            "as": "C8_2_4",
            "gu": "C8_2_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "A week passes. It's the day of the result. Naveen and Puneet have been called to the office. Their senior manager is going to sit with them and discuss the possibilities.",
            "hi": "एक हफ्ता हो चूका है। आज रिजल्ट का दिन है। नवीन आणि पुनीत ऑफिस में बुलाया गया। उनके सीनियर मैनेजर उनके साथ बैठकर संभावनों की चर्चा करनेवाले हैं।",
            "ka": "ಒಂದು ವಾರ ಕಳೆಯುತ್ತದೆ. ಇದು ಫಲಿತಾಂಶದ ದಿನ. ನವೀನ್ ಮತ್ತು ಪುನೀತ್ ಅವರನ್ನು ಕಚೇರಿಗೆ ಕರೆಸಲಾಗಿದೆ. ಅವರ ಹಿರಿಯ ವ್ಯವಸ್ಥಾಪಕರು ಅವರೊಂದಿಗೆ ಕುಳಿತು ಸಾಧ್ಯತೆಗಳನ್ನು ಚರ್ಚಿಸಲಿದ್ದಾರೆ.",
            "te": "ఒక వారం గడిచిపోతుంది. అది ఫలితం వచ్చే రోజు. నవీన్, పునీత్‌లను ఆఫీసుకు పిలిపించారు. వారితో సీనియర్ మేనేజర్ కూర్చుని అవకాశాలపై మాట్లాడబోతున్నారు.",
            "or": "ଏକ ସପ୍ତାହ ବିତିଯାଏ | ଏହା ଫଳାଫଳର ଦିନ | ନବୀନ ଏବଂ ପୁନିତଙ୍କୁ କାର୍ଯ୍ୟାଳୟକୁ ଡକାଯାଇଛି। ସେମାନଙ୍କର ସିନିୟର ମ୍ୟାନେଜର ସେମାନଙ୍କ ସହିତ ବସି ସମ୍ଭାବ୍ୟତା ଉପରେ ଆଲୋଚନା କରିବେ |",
            "as": "এসপ্তাহ পাৰ হ'ল। এইটো ফলাফলৰ দিন। নবীন আৰু পুনীতক কাৰ্যালয়লৈ মাতি অনা হ’ল। তেওঁলোকৰ জ্যেষ্ঠ মেনেজাৰে তেওঁলোকৰ সৈতে বহি সম্ভাৱনাবোৰ আলোচনা কৰিব।",
            "gu": "એક અઠવાડિયું પસાર થઈ જાય છે. પરિણામનો દિવસ હોય છે. નવીન અને પુનીતને ઓફિમાં બોલાવે છે. તેમના ઉપરી અધિકારી તે બંનેને તેમની સાથે બેસીને શક્યતાઓ અંગે વાત કરશે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_3_1",
            "hi": "C8_3_1",
            "ka": "C8_3_1",
            "te": "C8_3_1",
            "or": "C8_3_1",
            "as": "C8_3_1",
            "gu": "C8_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Puneet is called in first. Naveen is feeling restless. He is watching a video to keep himself distracted and sees an advertisement.",
            "hi": "पुनीत को पहले बुलाया गया। नवीन बैचैन हो रहा था। वह अपना मन बहलाने के लिए वीडियो देख रहा होता है, तभी एक एडवर्टाइज देखता है।",
            "ka": "ಮೊದಲು ಪುನೀತ್ ಅವನನ್ನು ಕರೆಸಿಕೊಳ್ಳುತ್ತಾರೆ. ನವೀನ್ ಚಡಪಡಿಸುತ್ತಿದ್ದಾನೆ. ಅವನು ವಿಚಲಿತರಾಗಲು ವೀಡಿಯೊವನ್ನು ವೀಕ್ಷಿಸುತ್ತಿದ್ದಾನೆ ಮತ್ತು ಜಾಹೀರಾತನ್ನು ನೋಡುತ್ತಾನೆ.",
            "te": "ముందుగా పునీత్‌ని పిలిచారు. నవీన్ ఒత్తిడితో ఉన్నాడు. అతను దీని గురించి ఆలోచించకుండా ఉండేందుకు వీడియో చూస్తున్నాడు మరియు ఒక ప్రకటన చూస్తాడు.",
            "or": "ପୁନିତକୁ ପ୍ରଥମେ ଭିତରକୁ ଡକାଯାଏ | ନବୀନ ଅଶାନ୍ତ ଅନୁଭବ କରୁଛନ୍ତି | ନିଜକୁ ବିଭ୍ରାନ୍ତ କରିବା ପାଇଁ ସେ ଏକ ଭିଡିଓ ଦେଖୁଛନ୍ତି ଏବଂ ଏକ ବିଜ୍ଞାପନ ଦେଖନ୍ତି |",
            "as": "পুনীতক প্ৰথমে মাতা হ’ল। নবীন অস্থিৰ হৈ পৰিল। সি নিজকে অন্যমনস্ক কৰি ৰাখিবলৈ এটা ভিডিঅ' চাই আছে আৰু এটা বিজ্ঞাপন দেখিলে।",
            "gu": "પુનીતને પહેલા બોલાવે છે. નવીન બેચેની અનુભવે છે. તે વિડિયો જોઈ રહ્યો છે અને પોતાની જાતને ગભરાયેલ ના હોય તેમ રાખવા માટે જાહેરાત જુએ છે."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_3_2",
            "hi": "C8_3_2",
            "ka": "C8_3_2",
            "te": "C8_3_2",
            "or": "C8_3_2",
            "as": "C8_3_2",
            "gu": "C8_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Click to Open the advertisement.",
            "hi": "एडवर्टाइज देखने के लिए क्लिक करें।",
            "ka": "ಜಾಹೀರಾತನ್ನು ತೆರೆಯಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "te": "ప్రకటన ఓపెన్ చేయడానికి క్లిక్ చేయండి (నొక్కండి).",
            "or": "ବିଜ୍ଞାପନ ଖୋଲିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
            "as": "বিজ্ঞাপনটো খুলিবলৈ ক্লিক কৰা।",
            "gu": "જાહેરાત ખોલવા માટે ક્લિક કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Become a Dial-A-Mechanic partner.",
            "hi": "डायल-ए-मैकेनिक पार्टनर बनें।",
            "ka": "ಡಯಲ್-ಎ-ಮೆಕ್ಯಾನಿಕ್ ಪಾಲುದಾರ ಆಗಿ",
            "te": "డయల్-ఎ-మెకానిక్ భాగస్వామి అవ్వండి.",
            "or": "ଏକ ଡାଏଲ୍-ଏ-ମେକାନିକ୍ ପାର୍ଟନର୍‌ ହୁଅନ୍ତୁ |",
            "as": "ডায়েল-এ-মেকানিকৰ অংশীদাৰ হওক।",
            "gu": "ડાયલ-એ-મેકેનિક ભાગીદાર બનો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "“Are you an experienced mechanic, plumber or electrician? Make up to 30,000 a month. Take up jobs when you want. Be your own boss! Become a Dial-A-Mechanic partner.”",
            "hi": "“क्या आप अनुभवी मैकेनिक, प्लंबर या इलेक्ट्रिशियन हैं? महीना 30,000 तक कमाएं। जब चाहे काम करें। खुद के बॉस बनें! डायल-ए-मैकेनिक पार्टनर बनें।”",
            "ka": "“ನೀವು ಅನುಭವಿ ಮೆಕ್ಯಾನಿಕ್, ಪ್ಲಂಬರ್ ಅಥವಾ ಎಲೆಕ್ಟ್ರಿಷಿಯನ್ ಆಗಿದ್ದೀರಾ? ತಿಂಗಳಿಗೆ 30,000 ವರೆಗೆ ಮಾಡಿ. ನಿಮಗೆ ಬೇಕಾದಾಗ ಉದ್ಯೋಗಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ. ನಿಮ್ಮ ಸ್ವಂತ ಬಾಸ್ ಆಗಿರಿ! ಡಯಲ್-ಎ-ಮೆಕ್ಯಾನಿಕ್ ಪಾಲುದಾರರಾಗಿ.",
            "te": "మీరు అనుభవం కల మెకానిక్, ప్లంబర్ లేదా ఎలక్ట్రీషియన్ హా? నెలకు 30,000 వరకు సంపాదించండి. మీకు కావలసినప్పుడు ఉద్యోగాలు తీసుకోండి. మీరే బాస్ గా ఉండండి! డయల్-ఎ-మెకానిక్ భాగస్వామి అవ్వండి.",
            "or": "“ଆପଣ ଜଣେ ଅଭିଜ୍ଞ ମେକାନିକ୍, ପ୍ଲମ୍ବର କିମ୍ବା ଇଲେକ୍ଟ୍ରିସିଆନ୍‌ କି ? ମାସକୁ 30,000 ପର୍ଯ୍ୟନ୍ତ କମାନ୍ତୁ | ଆପଣ ଯେତେବେଳେ ଚାହାଁନ୍ତି କାମ ନିଅନ୍ତୁ | ଆପଣ ନିଜର ମାଲିକ ହୁଅନ୍ତୁ ! ଏକ ଡାଏଲ୍-ଏ-ମେକାନିକ୍ ପାର୍ଟନର ହୁଅନ୍ତୁ |”",
            "as": "“আপুনি এজন অভিজ্ঞ মেকানিক, প্লাম্বাৰ বা ইলেক্ট্ৰিচিয়ান নেকি? মাহে ৩০০০০লৈকে ঘটক। যেতিয়াই বিচাৰে চাকৰি লওক। আপোনাৰ নিজৰ বছ হওক! ডায়েল-এ-মেকানিকৰ অংশীদাৰ হওক।\"",
            "gu": "“શું તમે અનુભવી મિકેનિક, પ્લમ્બર કે ઇલેક્ટ્રિશિયન છો? મહિને 30,000 કામવો છો. જ્યારે તમે ઇચ્છો ત્યારે નોકરીઓ કરો. તમારા પોતાના બોસ બનો! ડાયલ-એ-મેકેનિક ભાગીદાર બનો.”"
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_3_3",
            "hi": "C8_3_3",
            "ka": "C8_3_3",
            "te": "C8_3_3",
            "or": "C8_3_3",
            "as": "C8_3_3",
            "gu": "C8_3_3"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "He stops and thinks.",
            "hi": "वह सोच में पड़ जाता है।",
            "ka": "ಅವನು ನಿಲ್ಲಿಸಿ ಯೋಚಿಸುತ್ತಾನೆ.",
            "te": "అతను ఆగి ఆలోచిస్తున్నాడు.",
            "or": "ସେ ଅଟକି ଯାଆନ୍ତି ଏବଂ ଭାବନ୍ତି |",
            "as": "সি ৰৈ চিন্তা কৰিলে।",
            "gu": "તે અટકી ને વિચારે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "30,000 is something! But there might be a lot of struggle. But at least I will be my own boss.",
            "hi": "30,000 कोई छोटी रकम नहीं है! लेकिन इसमें बहुत मेहनत करनी पड़ेगी। लेकिन कम से कम मैं खुद का बॉस बनूंगा।",
            "ka": "30,000 ಏನೋ! ಆದರೆ ಸಾಕಷ್ಟು ಹೋರಾಟ ಇರಬಹುದು. ಆದರೆ ಕನಿಷ್ಠ ನಾನು ನನ್ನ ಸ್ವಂತ ಬಾಸ್ ಆಗುತ್ತೇನೆ.",
            "te": "30,000 అంటే! కానీ చాలా ఉండి ఉంటుంది. కానీ నేనే బాస్ అవుతాను.",
            "or": "30,000 ହେଉଛି କିଛି ! କିନ୍ତୁ ବହୁତ ସଂଘର୍ଷ ହୋଇପାରେ | କିନ୍ତୁ ଅତିକମରେ ମୁଁ ନିଜର ମାଲିକ ହେବି |",
            "as": "৩০০০০ টো বাৰু হ’ব! কিন্তু তাত যথেষ্ট সংগ্ৰাম হ'ব। কিন্তু অন্ততঃ মই মোৰ নিজৰ বছ হ'ম।",
            "gu": "30,000 કંઈક મોટી રકમ છે! પરંતુ તેમાં ઘણો સંઘર્ષ કરવો પડે. પણ હું મારો પોતાનો બોસ ક્યારેક જ બનીશ."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_4_1//",
            "hi": "C8_4_1//",
            "ka": "C8_4_1//",
            "te": "C8_4_1//",
            "or": "C8_4_1//",
            "as": "C8_4_1//",
            "gu": "C8_4_1//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "While he is lost in thought, Puneet comes out from the meeting thrilled.",
            "hi": "जब वह सोच में डूबा हुआ होता है तभी पुनीत मिटिंग से खुश होकर बाहर आता है।",
            "ka": "ಆಲೋಚನೆಯಲ್ಲಿ ಮುಳುಗಿರುವಾಗಲೇ ಪುನೀತ್ ಥ್ರಿಲ್ ಆಗಿ ಮೀಟಿಂಗ್ ನಿಂದ ಹೊರ ಬರುತ್ತಾನೆ.",
            "te": "అతను ఆలోచనలో ఉన్నాడు, పునీత్ ఉత్సాహంగా సమావేశం నుండి బయటకు వచ్చాడు.",
            "or": "ସେ ଭାବନାରେ ହଜିଯାଇଥିବାବେଳେ, ପୁନିତ ସଭାରୁ ଆନନ୍ଦିତ ହୋଇ ବାହାରକୁ ଆସନ୍ତି |",
            "as": "সি চিন্তাত ডুবি থাকোঁতে, পুনীত সভাৰ পৰা ৰোমাঞ্চিত হৈ ওলাই আহে।",
            "gu": "જ્યારે તે વિચારોમાં ખોવાયેલો હોય છે, ત્યારે પુનીત રોમાંચિત મૂડમાંથી મીટીંગમાંથી બહાર આવે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Puneet",
            "hi": "पुनीत",
            "ka": "ಪುನೀತ್",
            "te": "పునీత్",
            "or": "ପୁନିତ",
            "as": "পুনীত",
            "gu": "પુનિત"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Puneet",
            "hi": "पुनीत",
            "ka": "ಪುನೀತ್",
            "te": "పునీత్",
            "or": "ପୁନିତ",
            "as": "পুনীত",
            "gu": "પુનિત"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Puneet",
            "hi": "पुनीत",
            "ka": "ಪುನೀತ್",
            "te": "పునీత్",
            "or": "ପୁନିତ",
            "as": "পুনীত",
            "gu": "પુનિત"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "I got it da! I got it. My life is set!",
            "hi": "मुझे मिला गया दा! मेरी लाइफ तो सेट है!",
            "ka": "ನನಗೆ ಸಿಕ್ಕಿತು ಡಾ! ನನಗೆ ಸಿಕ್ಕಿತು. ನನ್ನ ಜೀವನವನ್ನು ಹೊಂದಿಸಲಾಗಿದೆ!",
            "te": "నాకు అర్థమైంది దా! నాకు అది అర్థమైంది. నా జీవితం సెట్ అయింది!",
            "or": "ମୁଁ ଏହା ପାଇଲି ଦା ! ମୂଁ ପାଇଗଲି। ମୋ ଜୀବନ ସ୍ଥିର !",
            "as": "মই পাই গ’লো! মই পাই গ’লো। মোৰ জীৱন নিৰ্ধাৰণ হৈ গ’ল!",
            "gu": "ભાઈ મને મળી ગઈ! મને મળી ગઈ! મારૂ જીવન બની ગયું."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "I knew it! Well done, Puneet, you deserve it.",
            "hi": "मुझे पता था! शाबास, पुनीत, यह तो होना ही था।",
            "ka": "ನನಗೆ ಗೊತ್ತಿತ್ತು! ಚೆನ್ನಾಗಿದೆ, ಪುನೀತ್, ನೀನು ಅದಕ್ಕೆ ಅರ್ಹನು.",
            "te": "నాకు తెలుసు! బాగా చేసారు, పునీత్, నువ్వు దానికి అర్హుడవు.",
            "or": "ମୁଁ ଏହା ଜାଣିଥିଲି ! ଭଲ କାମ, ପୁନିତ, ତୁମେ ଏହାର ଯୋଗ୍ୟ |",
            "as": "মই জানিছিলোঁ! চাব্বাছ, পুনীত, তুমি ইয়াৰ যোগ্য।",
            "gu": "મને ખબર હતી! ખૂબ સરસ, પુનિત, તું હકદાર છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "You will get it too! I’m sure of it! Go, Sir is waiting for you.",
            "hi": "तुम्हें भी जॉब मिल जाएगा! मुझे यकीन है! जाओ, सर तुम्हारा इतंजार कर रहे हैं।",
            "ka": "ನಿನಗೂ ಸಿಗುತ್ತದೆ! ನನಗೆ ಖಚಿತವಾಗಿದೆ! ಹೋಗು, ಸರ್ ನಿನಗಾಗಿ ಕಾಯುತ್ತಿದ್ದಾರೆ.",
            "te": "నీకు కూడా వస్తుంది! నేను ఖచ్చితంగా అనుకుంటున్నాను! వెళ్ళు, సార్ నీ కోసం చూస్తున్నారు.",
            "or": "ତୁମେ ମଧ୍ୟ ଏହା ପାଇବ ! ମୁଁ ଏହା ନିଶ୍ଚିତ ! ଯାଅ, ସାର୍ ତୁମକୁ ଅପେକ୍ଷା କରିଛନ୍ତି |",
            "as": "তুমিও পাবা! মই নিশ্চিত! যোৱা, ছাৰ তোমাৰ বাবে ৰৈ আছে।",
            "gu": "તને તે પણ મળશે જ! મને તે વિશ્વાસ છે! જા સાહેબ તમારી રાહ જુએ છે."
          }
        }
      ],
      "page12": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_5_1//",
            "hi": "C8_5_1//",
            "ka": "C8_5_1//",
            "te": "C8_5_1//",
            "or": "C8_5_1//",
            "as": "C8_5_1//",
            "gu": "C8_5_1//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Naveen enters the senior manager's office and takes a seat.",
            "hi": "नवीन सीनियर मैनेजर के ऑफिस में अंदर जाता है और बैठ जाता है।",
            "ka": "ನವೀನ್ ಸೀನಿಯರ್ ಮ್ಯಾನೇಜರ್ ಆಫೀಸ್ ಪ್ರವೇಶಿಸಿ ಆಸನ ತೆಗೆದುಕೊಂಡ.",
            "te": "నవీన్ సీనియర్ మేనేజర్ ఆఫీస్ లోకి వెళ్ళి సీట్లో కూర్చున్నాడు.",
            "or": "ନବୀନ ସିନିୟର ମ୍ୟାନେଜର୍‌ଙ୍କ କାର୍ଯ୍ୟାଳୟରେ ପ୍ରବେଶ କରି ଏକ ଆସନ ନିଅନ୍ତି।",
            "as": "নবীন জ্যেষ্ঠ মেনেজাৰৰ কাৰ্যালয়ত প্ৰৱেশ কৰিলে আৰু বহি ল’লে ৷",
            "gu": "નવીન ઉપરી અધિકારીની ઓફિસમાં જાય છે અને બેશે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Manager",
            "hi": "मैनेजर",
            "ka": "ಮ್ಯಾನೇಜರ್",
            "te": "మేనేజర్",
            "or": "ମ୍ୟାନେଜର୍‌",
            "as": "মেনেজাৰ",
            "gu": "મેનેજર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Manager",
            "hi": "मैनेजर",
            "ka": "ಮ್ಯಾನೇಜರ್",
            "te": "మేనేజర్",
            "or": "ମ୍ୟାନେଜର୍‌",
            "as": "মেনেজাৰ",
            "gu": "મેનેજર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Naveen, we like your work.",
            "hi": "नवीन हमें तुम्हारा काम पसंद है।",
            "ka": "ನವೀನ್, ನಿಮ್ಮ ಕೆಲಸ ನಮಗೆ ಇಷ್ಟವಾಗಿದೆ.",
            "te": "నవీన్, మీ పని మాకు నచ్చింది.",
            "or": "ନବୀନ, ଆମେ ତୁମର କାମ ପସନ୍ଦ କରୁ |",
            "as": "নবীন, আমি তোমাৰ কাম ভাল পাইছোঁ।",
            "gu": "નવીન, તારું કામ અમને ગમે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Thank you, Sir.",
            "hi": "थैंक यू सर।",
            "ka": "ಧನ್ಯವಾದಗಳು, ಸರ್.",
            "te": "ధన్యవాదాలు సర్.",
            "or": "ଧନ୍ୟବାଦ, ସାର୍ |",
            "as": "ধন্যবাদ ছাৰ।",
            "gu": "તમારો ધન્યવાદ, સાહેબ."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "But your application does not fulfill the criteria for the role. Hence we cannot select you for the permanent role.",
            "hi": "लेकिन तुम्हारा एप्लीकेशन इस रोल के क्राइटेरिया को पूरा नहीं कर रहा। इसलिए हम तुम्हें परमानेंट जॉब के लिए सिलेक्ट नहीं कर सकते।",
            "ka": "ಆದರೆ ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಪಾತ್ರದ ಮಾನದಂಡಗಳನ್ನು ಪೂರೈಸುವುದಿಲ್ಲ. ಆದ್ದರಿಂದ ನಾವು ನಿಮ್ಮನ್ನು ಶಾಶ್ವತ ಪಾತ್ರಕ್ಕೆ ಆಯ್ಕೆ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ.",
            "te": "కానీ మీ దరఖాస్తు పాత్రకు సంబంధించిన ప్రమాణాలను నెరవేర్చలేదు. కాబట్టి మేము మిమ్మల్ని శాశ్వత పాత్రకు ఎంపిక చేయలేము.",
            "or": "କିନ୍ତୁ ଭୂମିକା ପାଇଁ ଆବେଦନ ମାନଦଣ୍ଡ ପୂରଣ କରେ ନାହିଁ | ତେଣୁ ଆମେ ସ୍ଥାୟୀ ଭୂମିକା ପାଇଁ ଆପଣଙ୍କୁ ଚୟନ କରିପାରିବୁ ନାହିଁ |",
            "as": "কিন্তু তোমাৰ আবেদনপত্ৰখনে পদটোৰ চৰ্তবোৰ পূৰণ নকৰে। সেয়েহে আমি স্থায়ী পদৰ বাবে তোমাক বাছনি কৰিব নোৱাৰোঁ।",
            "gu": "પણ તારી અરજી આ કામના માપદંડ પ્રમાણે બંધ બેસતી નથી. તેથી અમે તને આ કામ માટે કાઇમી કરી ના શકીએ. "
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.text",
          "value": {
            "en": "Yes, Sir. I understand.",
            "hi": "हाँ, सर, मैं समझ सकता हूँ।",
            "ka": "ಹೌದು, ಸರ್. ನಾನು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೇನೆ",
            "te": "సరే సర్. నాకు అర్థమైంది.",
            "or": "ହଁ, ସାର୍ | ମୁ ବୁଝିଲି।",
            "as": "হয়, ছাৰ। মই বুজি পাইছোঁ।",
            "gu": "હા સાહેબ. હું સમજુ છું."
          }
        }
      ],
      "page13": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_5_2////",
            "hi": "C8_5_2////",
            "ka": "C8_5_2////",
            "te": "C8_5_2////",
            "or": "C8_5_2////",
            "as": "C8_5_2////",
            "gu": "C8_5_2////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "However, we know your work is good. I have heard the mechanics praise your work and eagerness to learn.",
            "hi": "लेकिन, हम जानते हैं कि, तुम्हारा काम अच्छा है। मैंने सूना है कि, मैकेनिक तुम्हारे काम की और सीखने की चाहत की तारीफ़ करते हैं।",
            "ka": "ಆದಾಗ್ಯೂ, ನಿಮ್ಮ ಕೆಲಸವು ಉತ್ತಮವಾಗಿದೆ ಎಂದು ನಮಗೆ ತಿಳಿದಿದೆ. ಮೆಕ್ಯಾನಿಕ್ಸ್ ನಿಮ್ಮ ಕೆಲಸವನ್ನು ಮತ್ತು ಕಲಿಯುವ ಉತ್ಸಾಹವನ್ನು ಹೊಗಳುವುದನ್ನು ನಾನು ಕೇಳಿದ್ದೇನೆ.",
            "te": "కానీ, నీ పని బాగుందని నాకు తెలుసు. మెకానిక్‌లు మీ పని ను మరియు నేర్చుకోవాలనే ఆసక్తిని మెచ్చుకోవడం నేను విన్నాను.",
            "or": "ଅଥଚ, ଆମେ ଜାଣିଛୁ ତୁମର କାମ ଭଲ ଅଟେ | ମୁଁ ଶୁଣିଛି ମେକାନିକ୍‍ ମାନେ ତୁମର କାମ ଏବଂ ଶିଖିବାକୁ ଆଗ୍ରହକୁ ପ୍ରଶଂସା କରନ୍ତି |",
            "as": "অৱশ্যে, আমি জানো যে তোমাৰ কাম ভাল। মই শুনিছোঁ মেকানিকসকলে তোমাৰ কাম আৰু শিকাৰ আগ্ৰহক প্ৰশংসা কৰে।",
            "gu": "જો કે, અમે ખબર છે કે તમારું કામ સારું છે. મેં મિકેનિક્સને તમારા કામ અને શીખવાની આતુરતાની પ્રશંસા કરતા સાંભળ્યા છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Thank you, Sir.",
            "hi": "थैंक यू, सर।",
            "ka": "ಧನ್ಯವಾದಗಳು, ಸರ್.",
            "te": "ధన్యవాదాలు సర్.",
            "or": "ଧନ୍ୟବାଦ, ସାର୍ |",
            "as": "ধন্যবাদ ছাৰ।",
            "gu": "તમારો ધન્યવાદ, સાહેબ."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "A 1-year contract employee role is open, and you fit the criteria. You can try for the permanent position next time.",
            "hi": "कर्मचारी के रोल के लिए 1 साल का कॉन्ट्रैक्ट ओपन है और यह तुम्हारे क्राइटेरिया के अनुसार फिट भी है। तुम परमानेंट पोजीशन के लिए अगली बार फिर से कोशिश कर सकते हो।",
            "ka": "1-ವರ್ಷದ ಗುತ್ತಿಗೆ ಉದ್ಯೋಗಿ ಪಾತ್ರವು ತೆರೆದಿರುತ್ತದೆ ಮತ್ತು ನೀವು ಮಾನದಂಡಕ್ಕೆ ಸರಿಹೊಂದುತ್ತೀರಿ. ಮುಂದಿನ ಬಾರಿ ನೀವು ಶಾಶ್ವತ ಸ್ಥಾನಕ್ಕಾಗಿ ಪ್ರಯತ್ನಿಸಬಹುದು.",
            "te": "1-సంవత్సరం కాంట్రాక్ట్ ఉద్యోగి పాత్ర ఉంది మరియు మీరు అర్హతకు సరిపోతున్నారు. మీరు తదుపరి సారి శాశ్వత స్థానం కోసం ప్రయత్నించవచ్చు.",
            "or": "1 ବର୍ଷର ଚୁକ୍ତିଭିତ୍ତିକ କର୍ମଚାରୀଙ୍କ ଏକ ଭୂମିକା ଫାଙ୍କା ଅଛି, ଏବଂ ତୁମେ ମାନଦଣ୍ଡକୁ ଫିଟ୍ କର | ପରବର୍ତ୍ତୀ ସମୟରେ ତୁମେ ସ୍ଥାୟୀ ପଦ ପାଇଁ ଚେଷ୍ଟା କରିପାର |",
            "as": "১ বছৰৰ চুক্তি কৰ্মচাৰীৰ এটা পদ খালী আছে, আৰু তোমাৰ চৰ্তবোৰ তাৰ বাবে খাপ খায়। তুমি পৰৱৰ্তী সময়ত স্থায়ী পদৰ বাবে চেষ্টা কৰিব পাৰিবা।",
            "gu": "1-વર્ષનો કરાર માટે કર્મચારીની ભૂમિકા ચાલુ છે અને તું માપદંડમાં લાયક છો. તમે આવતા વર્ષે કાયમી પદ માટે પ્રયાસ કરી શકો છો."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "But Sir, what guarantee is there of getting the permanent role next time?",
            "hi": "लेकिन सर, अगली बार परमानेंट जॉब मिल जाएगी इसकी क्या गारंटी है?",
            "ka": "ಆದರೆ ಸರ್, ಮುಂದಿನ ಬಾರಿ ಖಾಯಂ ಪಾತ್ರ ಸಿಗುತ್ತದೆ ಎಂಬುದಕ್ಕೆ ಏನು ಗ್ಯಾರಂಟಿ?",
            "te": "అయితే సార్, తదుపరి సారి శాశ్వత పాత్ర వస్తుందనే గ్యారంటీ ఏమిటి?",
            "or": "କିନ୍ତୁ ସାର୍, ପରବର୍ତ୍ତୀ ସମୟରେ ସ୍ଥାୟୀ ଭୂମିକା ପାଇବାର କ’ଣ ଗ୍ୟାରେଣ୍ଟି ଅଛି ?",
            "as": "কিন্তু ছাৰ, পৰৱৰ্তী সময়ত স্থায়ী পদ পোৱাৰ কিবা নিশ্চয়তা আছেনে?",
            "gu": "પણ સાહેબ, આગલી વખતે કાયમી નોકરી મળવાની શું ગેરંટી છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "Your chances to get a permanent job might increase after being on contract. But there is no surety.",
            "hi": "कॉन्ट्रैक्ट पर काम करने से तुम्हें परमानेंट जॉब मिलने की संभावना बढ़ जाएगी। लेकिन जॉब मिल ही जाएगी यह निश्चित नहीं है।",
            "ka": "ಒಪ್ಪಂದದ ನಂತರ ಶಾಶ್ವತ ಕೆಲಸವನ್ನು ಪಡೆಯುವ ನಿಮ್ಮ ಅವಕಾಶಗಳು ಹೆಚ್ಚಾಗಬಹುದು. ಆದರೆ ಗ್ಯಾರಂಟಿ ಇಲ್ಲ.",
            "te": "కాంట్రాక్ట్ లో ఉన్న తర్వాత శాశ్వత ఉద్యోగం రావడానికి మీకు అవకాశాలు పెరుగుతాయి. కానీ ష్యూరిటీ లేదు.",
            "or": "ଚୁକ୍ତିରେ ରହିବା ପରେ ତୁମର ସ୍ଥାୟୀ ଚାକିରି ପାଇବା ପାଇଁ ସମ୍ଭାବନା ବଢିପାରେ | କିନ୍ତୁ ଏଥିରେ କୌଣସି ନିଶ୍ଚିତତା ନାହିଁ।",
            "as": "চুক্তিত থকাৰ পিছত তোমাৰ স্থায়ী চাকৰি পোৱাৰ সম্ভাৱনা বৃদ্ধি হ'ব পাৰে। কিন্তু কোনো নিশ্চয়তা নাই।",
            "gu": "તારા નોકરીમાં કાયમી થવાની તકો વધી જશે એક વર્ષના આ કોન્ટ્રાક્ટ પર રહ્યા પછી. પણ આમાં કોઈ ચોક્કસ નથી.  "
          }
        }
      ],
      "page14": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_5_3",
            "hi": "C8_5_3",
            "ka": "C8_5_3",
            "te": "C8_5_3",
            "or": "C8_5_3",
            "as": "C8_5_3",
            "gu": "C8_5_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen did not expect this. He is keen to continue working at KPCL. He has invested a lot of time in creating contacts there.",
            "hi": "नवीन को इस बात की उम्मीद नहीं थी। वह KPCL में काम करना जारी रखना चाहता था। उसने यहाँ पर अच्छे संबंध बनाएं थे।",
            "ka": "ನವೀನ್ ಇದನ್ನು ನಿರೀಕ್ಷಿಸಿರಲಿಲ್ಲ. ಕೆಪಿಸಿಎಲ್‌ನಲ್ಲಿ ಕೆಲಸ ಮುಂದುವರಿಸಲು ಅವನು ಉತ್ಸುಕರಾಗಿದ್ದಾನೆ. ಅಲ್ಲಿ ಸಂಪರ್ಕಗಳನ್ನು ಸೃಷ್ಟಿಸಲು ಅವನು ಸಾಕಷ್ಟು ಸಮಯವನ್ನು ಹೂಡಿಕೆ ಮಾಡಿದ್ದಾನೆ.",
            "te": "నవీన్ దీన్ని ఊహించలేదు. అతను KPCLలో పని కొనసాగించడానికి ఆసక్తిగా ఉన్నాడు. అక్కడ పరిచయాలను ఏర్పరచుకోవడానికి అతను చాలా సమయాన్ని వెచ్చించాడు.",
            "or": "ନବୀନ ଏହା ଆଶା କରିନଥିଲେ। ସେ KPCL ରେ କାର୍ଯ୍ୟ ଜାରି ରଖିବାକୁ ଆଗ୍ରହୀ ଅଛନ୍ତି | ସେ ସେଠାରେ ସମ୍ପର୍କ ସୃଷ୍ଟି କରିବାରେ ବହୁ ସମୟ ବିନିଯୋଗ କରିଛନ୍ତି |",
            "as": "নবীনে এইটো আশা কৰা নাছিল। সি KPCLত কাম অব্যাহত ৰাখিবলৈ আগ্ৰহী ৷ সি ইয়াত সম্পৰ্ক গঢ়িবলৈ যথেষ্ট সময় অতিবাহিত কৰিছে।",
            "gu": "નવીનને આવી અપેક્ષા નહોતી. તે KPCLમાં કામ કરવાનું ચાલુ રાખવા માગે છે. તેણે ત્યાં સંપર્કો બનાવવામાં ઘણો સમય વિતાવ્યો છે."
          }
        }
      ],
      "page15": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_5_4",
            "hi": "C8_5_4",
            "ka": "C8_5_4",
            "te": "C8_5_4",
            "or": "C8_5_4",
            "as": "C8_5_4",
            "gu": "C8_5_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen is confused. He is considering the offer in the advertisement to become an on call Mechanic.",
            "hi": "नवीन कंफ्यूज है। वह एडवर्टाइज में देखे हुए, ऑन कॉल मैकेनिक बनने के ऑफर के बारे में सोच रहा है।",
            "ka": "ನವೀನ್ ಗೊಂದಲದಲ್ಲಿದ್ದಾನೆ. ಅವರು ಆನ್ ಕಾಲ್ ಮೆಕ್ಯಾನಿಕ್ ಆಗಲು ಜಾಹೀರಾತಿನಲ್ಲಿರುವ ಪ್ರಸ್ತಾಪವನ್ನು ಪರಿಗಣಿಸುತ್ತಿದ್ದಾನೆ.",
            "te": "నవీన్ కంగారు పడ్డాడు. అతను ఆన్ కాల్ మెకానిక్ కావడానికి ప్రకటనలోని ఆఫర్‌ను పరిశీలిస్తున్నాడు.",
            "or": "ନବୀନ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛନ୍ତି | ବିଜ୍ଞାପନରେ ଅନ୍‌ କଲ୍ ମେକାନିକ୍ ହେବା ପାଇଁ ସେ ବିଚାର କରନ୍ତି |",
            "as": "নবীন বিমোৰত পৰিল ৷ সি বিজ্ঞাপনত পোৱা অন কল মেকানিক হোৱাৰ প্ৰস্তাৱটো বিবেচনা কৰিলে।",
            "gu": "નવીન મૂંઝવણમાં લાગે છે. અને તે કોલ મિકેનિક બનવા માટે જાહેરાતમાં માગણી પર વિચાર કરી રહ્યો છે."
          }
        }
      ],
      "page16": [
        {
          "valuePath": "display",
          "value": {
            "en": "C8_5_5_D",
            "hi": "C8_5_5_D",
            "ka": "C8_5_5_D",
            "te": "C8_5_5_D",
            "or": "C8_5_5_D",
            "as": "C8_5_5_D",
            "gu": "C8_5_5_D"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should he do?",
            "hi": "उसे क्या करना चाहिए?",
            "ka": "ಅವನು ಏನು ಮಾಡಬೇಕು?",
            "te": "అతను ఏమి చేయాలి?",
            "or": "ସେ କ’ଣ କରିବା ଉଚିତ୍ ?",
            "as": "সি কি কৰা উচিত?",
            "gu": "તેને શું કરવું જોઇયે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Get the government contract role",
            "hi": "सरकारी कॉन्ट्रैक्ट रोल स्वीकार करना चाहिए",
            "ka": "ಸರ್ಕಾರಿ ಗುತ್ತಿಗೆ ಪಾತ್ರವನ್ನು ಪಡೆಯಿರಿ",
            "te": "ప్రభుత్వ కాంట్రాక్ట్ పాత్రను పొందాలి",
            "or": "ସରକାରୀ ଚୁକ୍ତିଭିତ୍ତିକ ଭୂମିକା ଗ୍ରହଣ କରନ୍ତୁ |",
            "as": "চৰকাৰী চুক্তিৰ কামটো লওক",
            "gu": "સરકારી કરારની કામગીરી મેળવો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Register himself at the Dial-A-Mechanic",
            "hi": "डायल- ए- मैकेनिक के तौर पर खुद को रजिस्टर करना चाहिए",
            "ka": "ಡಯಲ್-ಎ-ಮೆಕ್ಯಾನಿಕ್‌ನಲ್ಲಿ ಸ್ವತಃ ನೋಂದಾಯಿಸಿಕೊಳ್ಳಬೇಕು",
            "te": "డయల్-ఎ-మెకానిక్ వద్ద రిజిస్టర్ చేసుకోవాలి",
            "or": "ଡାଏଲ୍-ଏ-ମେକାନିକ୍ ରେ ନିଜକୁ ପଞ୍ଜିକରଣ କରିବା |",
            "as": "ডায়েল-এ-মেকানিকত নিজকে পঞ্জীয়ন কৰক",
            "gu": "ડાયલ-એ-મેકેનિક પર પોતાની રીતે નોંધણી કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.tag",
          "value": {
            "en": "FOLLOWING TRENDS",
            "hi": "ट्रेंड फॉलो करना",
            "ka": "ಟ್ರೆಂಡ್‌ಗಳನ್ನು ಅನುಸರಿಸಲಾಗುತ್ತಿದೆ",
            "te": "ట్రెండ్‌లను అనుసరించడం",
            "or": "ଟ୍ରେଣ୍ଡଗୁଡିକ ଅନୁସରଣ କରିବା",
            "as": "নিম্নলিখিত প্ৰৱণতাসমূহ",
            "gu": "વલણોને અનુસરી રહ્યાં છે"
          }
        }
      ]
    },
    "scene21": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_1_1_Government Contract Role",
            "hi": "सरकारी कॉन्ट्रैक्ट रोल",
            "ka": "C9_1_1_ಸರ್ಕಾರಿ ಗುತ್ತಿಗೆ ಪಾತ್ರ",
            "te": "C9_1_1_ప్రభుత్వ కాంట్రాక్ట్ పాత్ర",
            "or": "C9_1_1_Government Contract Role",
            "as": "C9_1_1_চৰকাৰী চুক্তিৰ কাম",
            "gu": "C9_1_1_સરકારી કરારની કામગીરી"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen has been working on the contract job for a couple of months. He has gained a lot of experience, but he is not happy with the work environment.",
            "hi": "नवीन कुछ महीनों से कॉन्ट्रैक्ट जॉब पर काम कर रहा है। उसने बहुत अनुभव प्राप्त कर लिया है, लेकिन वह काम के माहौल से खुश नहीं है।",
            "ka": "ನವೀನ್ ಒಂದೆರಡು ತಿಂಗಳಿನಿಂದ ಗುತ್ತಿಗೆ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾನೆ. ಅವನು ಸಾಕಷ್ಟು ಅನುಭವವನ್ನು ಗಳಿಸಿದ್ದಾನೆ, ಆದರೆ ಅವನು ಕೆಲಸದ ವಾತಾವರಣದಿಂದ ಸಂತೋಷವಾಗಿಲ್ಲ.",
            "te": "నవీన్‌ రెండు నెలలుగా కాంట్రాక్ట్ ఉద్యోగం చేస్తున్నాడు. అతనికి చాలా అనుభవం వచ్చింది, కానీ అతను పని వాతావరణంతో సంతోషంగా లేడు.",
            "or": "ନବୀନ ଦୁଇମାସ ଧରି ଚୁକ୍ତିଭିତ୍ତିକ ଚାକିରିରେ କାମ କରୁଛନ୍ତି। ସେ ବହୁତ ଅଭିଜ୍ଞତା ହାସଲ କରିଛନ୍ତି, କିନ୍ତୁ କାର୍ଯ୍ୟ ପରିବେଶ ସହିତ ସେ ଖୁସି ନୁହଁନ୍ତି |",
            "as": "নবীনে কেইমাহমানৰ পৰা চুক্তিৰ চাকৰিত কাম কৰি আছে। সি যথেষ্ট অভিজ্ঞতা লাভ কৰিছে, কিন্তু তাৰ কামৰ পৰিৱেশত সুখী নহয়।",
            "gu": "બે મહિનાથી કોન્ટ્રાક્ટ પર કામ કરી રહ્યો છે નવીન. તેણે ઘણો અનુભવ થયો છે, પરંતુ તે કામના વાતાવરણથી ખુશ નથી."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_1_2",
            "hi": "C9_1_2",
            "ka": "C9_1_2",
            "te": "C9_1_2",
            "or": "C9_1_2",
            "as": "C9_1_2",
            "gu": "C9_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The contract employees are treated differently, and there are no benefits like leaves, medical help, and union support.",
            "hi": "कॉन्ट्रैक्ट कर्मचारियों के साथ अलग तरह से व्यवहार किया जाता है और उन्हें छुट्टी, वैद्यकीय सहायता और यूनियन सपोर्ट जैसे लाभ नहीं मिलते।",
            "ka": "ಗುತ್ತಿಗೆ ನೌಕರರನ್ನು ವಿಭಿನ್ನವಾಗಿ ಪರಿಗಣಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ರಜೆಗಳು, ವೈದ್ಯಕೀಯ ಸಹಾಯ ಮತ್ತು ಯೂನಿಯನ್ ಬೆಂಬಲದಂತಹ ಯಾವುದೇ ಪ್ರಯೋಜನಗಳಿಲ್ಲ",
            "te": "కాంట్రాక్ట్ ఉద్యోగులను భిన్నంగా చూస్తారు మరియు సెలవులు, వైద్య సహాయం మరియు యూనియన్ సపోర్ట్ వంటి ప్రయోజనాలు ఉండవు.",
            "or": "ଚୁକ୍ତିଭିତ୍ତିକ କର୍ମଚାରୀଙ୍କୁ ଭିନ୍ନ ଭାବରେ ବ୍ୟବହାର କରାଯାଏ, ଏବଂ ସେଠାରେ ଛୁଟି, ଚିକିତ୍ସା ସହାୟତା, ଏବଂ ୟୁନିଅନ୍ ସହାୟତା ଭଳି କୌଣସି ସୁବିଧା ନଥାଏ |",
            "as": "ঠিকা কৰ্মচাৰীসকলক বেলেগ ধৰণে ব্যৱহাৰ কৰা হয়, আৰু ছুটি, চিকিৎসা সাহাৰ্য, আৰু ইউনিয়ন সমৰ্থনৰ দৰে কোনো সুবিধা নাই।",
            "gu": "કોન્ટ્રાક્ટ વાળા કર્મચારીઓ સાથે જીદી રીતે વ્યવહાર કરવામાં આવે છે, અને રજાઓ, તબીબી સહાય અને યુનિયનની સહાય જેવા કોઈ લાભો નથી."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_1_3",
            "hi": "C9_1_3",
            "ka": "C9_1_3",
            "te": "C9_1_3",
            "or": "C9_1_3",
            "as": "C9_1_3",
            "gu": "C9_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Every now and then, Naveen questions his decision to take up this job. He aspires for the permanent role.",
            "hi": "नवीन को अक्सर यह ख्याल आता है कि उसने इस जॉब को लेकर गलत निर्णय तो नहीं लिया। वह परमानेंट जॉब करना चाहता है।",
            "ka": "ಆಗೊಮ್ಮೆ ಈಗೊಮ್ಮೆ, ನವೀನ್ ಈ ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳುವ ನಿರ್ಧಾರವನ್ನು ಪ್ರಶ್ನಿಸುತ್ತಾನೆ. ಅವನು ಶಾಶ್ವತ ಪಾತ್ರಕ್ಕಾಗಿ ಹಾತೊರೆಯುತ್ತಾನೆ.",
            "te": "అప్పుడప్పుడూ నవీన్ ఈ ఉద్యోగంలో చేరాలనే తన నిర్ణయాన్ని ప్రశ్నిస్తూ ఉంటాడు. అతను శాశ్వత పాత్ర కోసం చూస్తున్నాడు.",
            "or": "ପ୍ରତ୍ୟେକ ସମୟରେ, ନବୀନ ଏହି ଚାକିରି ନେବା ପାଇଁ ତାଙ୍କ ନିଷ୍ପତ୍ତି ଉପରେ ପ୍ରଶ୍ନ କରନ୍ତି | ସେ ସ୍ଥାୟୀ ଭୂମିକା ପାଇଁ ଇଚ୍ଛା କରନ୍ତି |",
            "as": "সকলো সময়তে নবীনে এই চাকৰিটো গ্ৰহণ কৰাৰ বাবে নিজকে প্ৰশ্ন সুধি থাকে। সি স্থায়ী চাকৰিৰ বাবে আশা কৰে।",
            "gu": "સમયાંતરે, નવીને આ નોકરી લીધેલા નિર્ણય પર સવાલ કરે છે. તે કાયમી કામ માટે ઈચ્છા ધરાવે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_2_1",
            "hi": "C9_2_1",
            "ka": "C9_2_1",
            "te": "C9_2_1",
            "or": "C9_2_1",
            "as": "C9_2_1",
            "gu": "C9_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "To divert his mind, Naveen goes to meet his dance friends. He is attending their dance practice after a long time and is surprised to see new faces.",
            "hi": "अपना दिल बहलाने के लिए वह अपने डान्स के दोस्तों से मिलता है। वह लंबे समय के बाद डान्स प्रैक्टिस के लिए जाता है और वहाँ नए चेहरे देखकर चकित हो जाता है।",
            "ka": "ಅವನ ಮನಸ್ಸನ್ನು ಬೇರೆಡೆಗೆ ತಿರುಗಿಸಲು, ನವೀನ್ ತನ್ನ ನೃತ್ಯ ಸ್ನೇಹಿತರನ್ನು ಭೇಟಿಯಾಗಲು ಹೋಗುತ್ತಾನೆ. ಅವನು ಬಹಳ ಸಮಯದ ನಂತರ ಅವರ ನೃತ್ಯ ಅಭ್ಯಾಸಕ್ಕೆ ಹಾಜರಾಗಿದ್ದಾನೆ ಮತ್ತು ಹೊಸ ಮುಖಗಳನ್ನು ನೋಡಿ ಆಶ್ಚರ್ಯಚಕಿತರಾಗಿದ್ದಾನೆ.",
            "te": "తన మనసును మళ్లించడానికి, నవీన్ తన డ్యాన్స్ స్నేహితులను కలవడానికి వెళ్తాడు. చాలా కాలం తర్వాత వారి డ్యాన్స్ ప్రాక్టీస్‌కు హాజరైన అతను కొత్త ముఖాలను చూసి ఆశ్చర్యపోతున్నాడు.",
            "or": "ତାଙ୍କ ମନକୁ ବିଭ୍ରାନ୍ତ କରିବାକୁ, ନବୀନ ତାଙ୍କ ନୃତ୍ୟ ସାଙ୍ଗମାନଙ୍କୁ ଭେଟିବାକୁ ଯାଆନ୍ତି | ଦୀର୍ଘ ସମୟ ପରେ ସେ ସେମାନଙ୍କ ନୃତ୍ୟ ଅଭ୍ୟାସରେ ଯୋଗ ଦିଅନ୍ତି ଏବଂ ନୂତନ ଚେହେରା ଦେଖି ଆଶ୍ଚର୍ଯ୍ୟ ହୋଇଯାନ୍ତି |",
            "as": "মনটো সলনি কৰিবলৈ, নবীনে তাৰ নৃত্য বন্ধুসকলক লগ ধৰিবলৈ যায়। সি বহু দিনৰ পিছত তেওঁলোকৰ নৃত্য অনুশীলনত অংশগ্ৰহণ কৰে আৰু নতুন মুখ দেখি আচৰিত হয়।",
            "gu": "તે મનને બીજી દિશા તરફ વાળવા માટે, નવીન તેના ડાન્સવાળા મિત્રોને મળવા જાય છે. તે લાંબા સમય પછી તેમની નૃત્ય પ્રેક્ટિસમાં હાજરી આપી છે અને નવા લોકોને જોઈને આશ્ચર્યચકિત થાય છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_2_2//",
            "hi": "C9_2_2//",
            "ka": "C9_2_2//",
            "te": "C9_2_2//",
            "or": "C9_2_2//",
            "as": "C9_2_2//",
            "gu": "C9_2_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Omi",
            "hi": "ओमी",
            "ka": "ಓಮಿ",
            "te": "ఓమి",
            "or": "ଓମି",
            "as": "অমি",
            "gu": "ઓમીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Zaid",
            "hi": "जाएद",
            "ka": "ಜೈದ್",
            "te": "జైద్",
            "or": "ଜାଏଦ୍‌",
            "as": "জাইদ",
            "gu": "ઝાઈદ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "The dance scene has really grown here! So many new talented people have joined.",
            "hi": "डान्स ग्रुप तो बहुत अच्छा चल रहा है! इसलिए कई टैलेंटेड लोगों ने हमारे ग्रुप को ज्वाइन किया है।",
            "ka": "ಇಲ್ಲಿ ನೃತ್ಯದ ದೃಶ್ಯ ನಿಜವಾಗಿಯೂ ಬೆಳೆದಿದೆ! ಎಷ್ಟೋ ಹೊಸ ಪ್ರತಿಭಾವಂತರು ಸೇರಿಕೊಂಡಿದ್ದಾರೆ.",
            "te": "ఇక్కడ డ్యాన్స్ షో నిజంగా పెరిగింది! చాలా ప్రతిభ కల వాళ్లు కొత్తగా చేరారు.",
            "or": "ପ୍ରକୃତରେ ଏଠାରେ ନୃତ୍ୟ ଦୃଶ୍ୟ ବିକାଶ ଲାଭ କରିଛି ! ତେଣୁ ଅନେକ ନୂତନ ପ୍ରତିଭାବାନ ବ୍ୟକ୍ତି ଯୋଗ ଦେଇଛନ୍ତି |",
            "as": "নৃত্যৰ দৃশ্যটো সঁচাকৈয়ে বহুত আগবাঢ়িছে! বহু নতুন প্ৰতিভাশালী মানুহে যোগদান কৰিছে।",
            "gu": "અહીં નૃત્ય દ્રશ્ય ખરેખર વિકસિત થયું છે! ઘણા નવા પ્રતિભાશાળી લોકો જોડાયા છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "You missed a lot of action da. Since we won the runners up prize in that competition, our popularity has increased in the locality.",
            "hi": "तुमने बहुत कुछ मिस किया, दा। जब से हमने स्पर्धा में दूसरा क्रमाँक प्राप्त किया है, अड़ोस-पड़ोस में हमारी लोकप्रियता बहुत बढ़ चुकी हैं।",
            "ka": "ನೀನು ಸಾಕಷ್ಟು ಕ್ರಿಯೆಯನ್ನು ಕಳೆದುಕೊಂಡಿದ್ದೀಯ. ಆ ಸ್ಪರ್ಧೆಯಲ್ಲಿ ನಾವು ರನ್ನರ್ಸ್ ಅಪ್ ಬಹುಮಾನವನ್ನು ಗೆದ್ದಾಗಿನಿಂದ, ನಮ್ಮ ಜನಪ್ರಿಯತೆ ಸ್ಥಳೀಯವಾಗಿ ಹೆಚ್ಚಾಗಿದೆ.",
            "te": "నువ్వు చాలా యాక్షన్ మిస్సయ్యావు దా. ఆ పోటీలో మనము రన్నరప్ బహుమతిని గెలుచుకున్నాము కాబట్టి, స్థానికంగా మనకు ప్రజాదరణ పెరిగింది",
            "or": "ତୁମେ ବହୁତ ଆକ୍ସନ୍‍ ମିସ୍‍ କରିଛ ଦା | ଯେହେତୁ ଆମେ ସେହି ପ୍ରତିଯୋଗିତାରେ ରନର୍ସ ଅପ୍ ପୁରସ୍କାର ଜିତିଥିଲୁ, ସ୍ଥାନୀୟ ଅଞ୍ଚଳରେ ଆମର ଲୋକପ୍ରିୟତା ବୃଦ୍ଧି ପାଇଛି |",
            "as": "তুমি বহুত এক্সন নেদেখিলা বন্ধু। আমি সেই প্ৰতিযোগিতাখনত ৰানাৰ্ছ আপ পুৰস্কাৰ পোৱাৰ পৰাই, এই এলেকাত আমাৰ জনপ্ৰিয়তা বৃদ্ধি পাইছে।",
            "gu": "તું ઘણી બધા એક્શન ચૂકી ગયો છે. આપણે પેલી સ્પર્ધામાં રનર્સ અપ ઈનામ જીત્યા હતા ત્યારથી, આપણી લોકપ્રિયતા સ્થાનિક લોકોમાં વધી છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "Anyway, you tell us about your job. How is it going? Did you manage to get that full-time role?",
            "hi": "खैर, तुम अपनी जॉब के बारे में बताओ। कैसा चल रहा है? क्या तुम्हें फुल टाइम जॉब मिला?",
            "ka": "ಹೇಗಿದ್ದರೂ, ನಿಮ್ಮ ಕೆಲಸದ ಬಗ್ಗೆ ನಮಗೆ ತಿಳಿಸಿ. ಇದು ಹೇಗೆ ನಡೆಯುತ್ತಿದೆ? ನೀವು ಪೂರ್ಣ ಸಮಯದ ಪಾತ್ರವನ್ನು ಪಡೆಯಲು ನಿರ್ವಹಿಸಿದ್ದೀರಾ?",
            "te": "ఏమైనా, నువ్వు నీ ఉద్యోగం గురించి మాకు చెప్పు. ఎలా జరుగుతోంది? నీకు ఆ పూర్తి సమయం పాత్ర వచ్చిందా?",
            "or": "ଯାହା ବି ହେଉ, ତୁମେ ତୁମର କାମ ବିଷୟରେ ଆମକୁ କୁହ | ଏହା କିପରି ଚାଲିଛି? ତୁମେ ସେହି ପୂର୍ଣ୍ଣକାଳୀନ ଭୂମିକା ପାଇବାରେ ସଫଳ ହୋଇଛ କି?",
            "as": "যি কি নহওক, তোমাৰ কামৰ বিষয়ে কোৱা। কেনে চলিছে? তুমি সেই পূৰ্ণ-সময়ৰ কামটো পালা নে?",
            "gu": "ચાલ હવે, તું અમને તારી નોકરી વિશે બતાવ. કેવુ ચાલે છે? શું તું તે કાયમી ભૂમિકા મેળવવાનું મેનેજ કર્યું?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.text",
          "value": {
            "en": "No, it looks complicated. As of now, I'm still on contract. It hasn’t been easy.",
            "hi": "नहीं, यह इतना आसान नहीं है। फिलहाल मैं कॉन्ट्रैक्ट पर काम कर रहा हूँ। यह बहुत मुश्किल है।",
            "ka": "ಇಲ್ಲ, ಇದು ಸಂಕೀರ್ಣವಾಗಿ ಕಾಣುತ್ತದೆ. ಸದ್ಯಕ್ಕೆ, ನಾನು ಇನ್ನೂ ಒಪ್ಪಂದದಲ್ಲಿದ್ದೇನೆ. ಇದು ಸುಲಭವಾಗಿರಲಿಲ್ಲ.",
            "te": "లేదు, ఇది క్లిష్టం అనిపిస్తుంది. ప్రస్తుతానికి, నేను ఇంకా కాంట్రాక్ట్ లోనే ఉన్నాను. ఇది ఈజీ కాదు.",
            "or": "ନା, ଏହା ଜଟିଳ ଦେଖାଯାଉଛି | ବର୍ତ୍ତମାନ ସୁଦ୍ଧା, ମୁଁ ତଥାପି ଚୁକ୍ତିରେ ଅଛି | ଏହା ସହଜ ହୋଇନାହିଁ |",
            "as": "নাপালো, এইটো অলপ জটিল। মই এতিয়াও চুক্তিত আছোঁ। এইটো সহজ নাছিল।",
            "gu": "ના, તે ગૂંચવણ ભર્યું લાગે છે. હમણાં સુધી, હું હજી પણ કરાર ઉપર છું. તે એટલું સરળ રહ્યું નથી."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_2_3////",
            "hi": "C9_2_3////",
            "ka": "C9_2_3////",
            "te": "C9_2_3////",
            "or": "C9_2_3////",
            "as": "C9_2_3////",
            "gu": "C9_2_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "That sounds bad. Why are you sticking with this job if you aren’t happy?",
            "hi": "यह तो बुरी बात है। तुम्हें यह काम पसंद नहीं है, फिर भी तुम इसे करना जारी रखोगे?",
            "ka": "ಅದು ಕೆಟ್ಟದಾಗಿ ಧ್ವನಿಸುತ್ತದೆ. ನಿನಗೆ ಸಂತೋಷವಿಲ್ಲದಿದ್ದರೆ ಈ ಕೆಲಸಕ್ಕೆ ಏಕೆ ಅಂಟಿಕೊಳ್ಳುತ್ತಿದ್ದೀಯ?",
            "te": "అది బాలేదు. నువ్వు సంతోషంగా లేకుంటే ఈ ఉద్యోగం ఎందుకు చేస్తున్నావు?",
            "or": "ତାହା ଖରାପ ଲାଗୁଛି | ଯଦି ତୁମେ ଖୁସି ନୁହଁ ତେବେ ତୁମେ କାହିଁକି ଏହି ଚାକିରି ସହିତ ଜଡିତ?",
            "as": "শুনি বেয়া লাগিল ৷ তুমি ভাল পোৱা নাই যদি কামটোত কিয় লাগি আছা?",
            "gu": "તે સારું નથી લાગતું. તો તું ખુશ ન હોવ તો તું કેમ આ નોકરી સાથે વળગી રહ્યા છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "What other option do I have, Omi? I can’t find anything out there that fits my capability. I need to stick to what’s available.",
            "hi": "मेरे पास और कोई ऑप्शन है क्या, ओमी? मुझे अपनी क्षमता के अनुसार कुछ भी नहीं मिल रहा। इसलिए जो उपलब्ध है वही जॉब करनी पड़ेगी।",
            "ka": "ನನಗೆ ಬೇರೆ ಯಾವ ಆಯ್ಕೆ ಇದೆ, ಓಮಿ? ನನ್ನ ಸಾಮರ್ಥ್ಯಕ್ಕೆ ಸರಿಹೊಂದುವ ಯಾವುದನ್ನೂ ನಾನು ಅಲ್ಲಿ ಹುಡುಕಲು ಸಾಧ್ಯವಿಲ್ಲ. ನಾನು ಲಭ್ಯವಿರುವುದಕ್ಕೆ ಅಂಟಿಕೊಳ್ಳಬೇಕಾಗಿದೆ.",
            "te": "నాకు ఇంకా ఏ ఎంపిక ఉంది, ఓమి? నా సామర్థ్యానికి సరిపోయే ఏదీ నేను కనుగొనలేకపోయాను. నేను నాకు అందుబాటులో ఉన్న వాటికి కట్టుబడి ఉండాలి.",
            "or": "ତାହା ଖରାପ ଲାଗୁଛି | ଯଦି ତୁମେ ଖୁସି ନୁହଁ ତେବେ ତୁମେ କାହିଁକି ଏହି ଚାକିରି ସହିତ ଜଡିତ ଅଛନ୍ତି?",
            "as": "অমি, মোৰ ওচৰত আৰু কি বিকল্প আছে? মই তাত এনে একো বিচাৰি পোৱা নাই যি মোৰ সামৰ্থ্যৰ সৈতে খাপ খায়। যি আছে তাকেই ল’ব লাগিব।",
            "gu": "બીજા આના શિવાય કયા રસ્તા છે, ઓમી? હું બીજું એવું કઈ નથી શોધી શકતો કે જે મારા લાયક બેસતું હોય. જે છે તેને મારે વળગ્યું રહેવું પડે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "See, that's the problem. You keep trying to fit into jobs that do not challenge you, and you get bored soon. Why don’t you try doing something of your own?",
            "hi": "देखो, यही तो तुम्हारी प्रॉब्लम है। जो जॉब तुम्हें अच्छी नहीं लगती, तुम बहुत जॉब करना चाहते हो और इसलिए जल्द ही बोर हो जाते हो। तुम खुद का कुछ क्यों नहीं शुरू क्यों नहीं करते?",
            "ka": "ನೋಡು, ಅದೇ ಸಮಸ್ಯೆ. ನಿನಗೆ ಸವಾಲಾಗದ ಕೆಲಸಗಳಿಗೆ ಹೊಂದಿಕೊಳ್ಳಲು ನೀನು ಪ್ರಯತ್ನಿಸುತ್ತಿರುತ್ತೀಯ ಮತ್ತು ನೀನು ಬೇಗನೆ ಬೇಸರಗೊಳ್ಳುತ್ತೀಯ. ನಿನದೇ ಆದದ್ದನ್ನು ಮಾಡಲು ನೀನು ಏಕೆ ಪ್ರಯತ್ನಿಸಬಾರದು?",
            "te": "చూడండి, అదే సమస్య. మీకు సవాలు చేయని ఉద్యోగాల్లోకి ప్రవేశించడానికి మీరు ప్రయత్నిస్తూనే ఉంటారు మరియు మీరు త్వరలో విసుగు చెందుతారు. మీరు మీ స్వంతంగా ఏదైనా చేయడానికి ఎందుకు ప్రయత్నించకూడదు?",
            "or": "ଦେଖନ୍ତୁ, ତାହା ହିଁ ହେଉଛି ସମସ୍ୟା | ତୁମେ ଚାକିରିରେ ଫିଟ୍ ହେବାକୁ ଚେଷ୍ଟା ଜାରି ରଖ, ଯାହା ତୁମକୁ ଚ୍ୟାଲେଞ୍ଜ କରିବ ନାହିଁ, ଏବଂ ତୁମେ ଶୀଘ୍ର ବିରକ୍ତ ହେବ ନାହିଁ | ଆପଣ ନିଜର କିଛି କରିବାକୁ ଚେଷ୍ଟା କରୁନାହାଁନ୍ତି କାହିଁକି?",
            "as": "দেখিলা, সেইটোৱেই সমস্যা। তুমি ভাল নোপোৱা চাকৰিবোৰত খাপ খাবলৈ চেষ্টা কৰি থাকোতে থাকোতে সোনকালেই আমনি পাই যাবা। তুমি নিজাকৈ কিবা এটা নকৰা কিয়?",
            "gu": "જો, આ તો સમસ્યા છે. તું એવું નોકરી કરે છે જે તારા માટે પડકાર નથી અને લાયક પણ નથી અને તું એમાં કંટાળે છે. કેમ તું પોતાનું કઈક તારા રીતે નથી કરતો?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Zaid, have you lost your mind?",
            "hi": "जाएद, तुम्हारा दिमाग ठिकाने पर तो है?",
            "ka": "ಝೈದ್, ನೀನು ನಿನ್ನ ಮನಸ್ಸನ್ನು ಕಳೆದುಕೊಂಡಿದ್ದೀಯಾ?",
            "te": "జైద్, నీకు మతి పోయిందా?",
            "or": "ଜାଏଦ୍‌, ତୁମେ ତୁମର ମନ ହରାଇଛ କି?",
            "as": "জাইদ, তোমাৰ মগজ বেয়া হৈছে নেকি?",
            "gu": "ઝાઈદ, તારી પાસે સારું જ્ઞાન છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "Come, there’s someone I'd like you to meet. I think he will be able to help you out.",
            "hi": "यहाँ आओ, मैं तुम्हें किसी से मिलवा ना चाहता हूँ। शायद वह तुम्हारी मदद करेगा।",
            "ka": "ಬಾ, ನೀನು ಭೇಟಿಯಾಗಬೇಕೆಂದು ನಾನು ಬಯಸುವ ಯಾರೋ ಇದ್ದಾರೆ. ಅವನು ನಿಮಗೆ ಸಹಾಯ ಮಾಡಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
            "te": "రా, నేను నిన్ను ఒక వ్యక్తికి పరిచయం చేయాలనుకుంటున్నాను. అతను నీకు సహాయం చేయగలడని నేను అనుకుంటున్నాను.",
            "or": "ଆସନ୍ତୁ, ସେଠାରେ କେହି ଜଣେ ଅଛନ୍ତି ଯାହାକୁ ମୁଁ ତୁମକୁ ଭେଟିବାକୁ ଚାହେଁ | ମୁଁ ଭାବୁଛି ସେ ଆପଣଙ୍କୁ ସାହାଯ୍ୟ କରିବାକୁ ସମର୍ଥ ହେବେ |",
            "as": "আহাঁ, তোমাক কাৰোবাৰ সৈতে লগ কৰাওঁ ৷ তেওঁ তোমাক সহায় কৰিব পাৰিব।",
            "gu": "ચાલ, હું તને એક વ્યક્તિને મળાવું. મને લાગે છે તે તને કઈક મદદ કરશે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_3_1",
            "hi": "C9_3_1",
            "ka": "C9_3_1",
            "te": "C9_3_1",
            "or": "C9_3_1",
            "as": "C9_3_1",
            "gu": "C9_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "They go up to the platform where a group of dancers are practising. Zaid walks up to the area where the speaker system is placed.",
            "hi": "भी प्लेटफार्म पर जाते हैं जहाँ डान्सर्स के ग्रुप प्रैक्टिस कर रहे होते हैं। जाएद स्टेज पर स्पीकर सिस्टम के पास चला जाता है।",
            "ka": "ಅವರು ನೃತ್ಯಗಾರರ ಗುಂಪು ಅಭ್ಯಾಸ ಮಾಡುತ್ತಿರುವ ವೇದಿಕೆಗೆ ಹೋಗುತ್ತಾರೆ. ಝೈದ್ ಸ್ಪೀಕರ್ ಸಿಸ್ಟಮ್ ಅನ್ನು ಇರಿಸಲಾಗಿರುವ ಪ್ರದೇಶದವರೆಗೆ ನಡೆಯುತ್ತಾನೆ.",
            "te": "వారు నృత్యకారుల బృందం ప్రాక్టీస్ చేస్తున్న ప్లాట్‌ఫారమ్‌పైకి వెళతారు. జైద్ స్పీకర్ సిస్టమ్ ఉంచబడిన ప్రాంతం వరకు నడుస్తాడు.",
            "or": "ସେମାନେ ପ୍ଲାଟଫର୍ମକୁ ଯାଆନ୍ତି ଯେଉଁଠାରେ ଏକ ନୃତ୍ୟଶିଳ୍ପୀ ଗୋଷ୍ଠୀ ଅଭ୍ୟାସ କରନ୍ତି | ସ୍ପିକର ସିଷ୍ଟମ ରଖାଯାଇଥିବା ସ୍ଥାନକୁ ଜାଏଦ୍‌ ଚାଲିଥାଏ |",
            "as": "তেওঁলোকে প্লেটফৰ্মলৈ যায় য'ত নৃত্যশিল্পীৰ এটা দলে অনুশীলন কৰি আছিল। জাইদে স্পীকাৰটোৰ ওচৰলৈ আগবাঢ়ি যায়।",
            "gu": "તે પ્લેટફોર્મ પર જાય છે જ્યાં ડાંસરનું એક જૂથ પ્રેક્ટિસ કરી રહ્યું છે. ઝાઈદ ત્યાં સુધી ચાલે છે જ્યાં સ્પીકર સિસ્ટમ મૂકવામાં આવી છે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_3_2//",
            "hi": "C9_3_2//",
            "ka": "C9_3_2//",
            "te": "C9_3_2//",
            "or": "C9_3_2//",
            "as": "C9_3_2//",
            "gu": "C9_3_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Zaid",
            "hi": "जाएद",
            "ka": "ಝೈದ್",
            "te": "జైద్",
            "or": "ଜାଏଦ୍‌",
            "as": "জাইদ",
            "gu": "ઝાઈદ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Sameer",
            "hi": "समीर",
            "ka": "ಸಮೀರ್",
            "te": "సమీర్",
            "or": "ସମୀର",
            "as": "সমীৰ",
            "gu": "સમીર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Zaid",
            "hi": "जाएद",
            "ka": "ಝೈದ್",
            "te": "జైద్",
            "or": "ଜାଏଦ୍‌",
            "as": "জাইদ",
            "gu": "ઝાઈદ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Naveen, meet Sameer. He’s been helping us with the sound mixing lately.",
            "hi": "नवीन, इसे मिलो, यह है समीर। वह आजकल साउंड मिक्स करने में हमारी मदद कर रहा है।",
            "ka": "ನವೀನ್, ಸಮೀರ್ ಭೇಟಿ. ಅವರು ಇತ್ತೀಚೆಗೆ ಧ್ವನಿ ಮಿಶ್ರಣದಲ್ಲಿ ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತಿದ್ದಾನೆ.",
            "te": "నవీన్, సమీర్‌ని కలువు. అతను ఇటీవల సౌండ్ మిక్సింగ్‌లో మాకు సహాయం చేస్తున్నాడు.",
            "or": "ନବୀନ, ସମୀରଙ୍କୁ ଭେଟନ୍ତୁ | ନିକଟ ଅତୀତରେ ଶବ୍ଦ ମିଶ୍ରଣରେ ସେ ଆମକୁ ସାହାଯ୍ୟ କରୁଛନ୍ତି |",
            "as": "নবীন এয়া সমীৰ ৷ তেওঁ শেহতীয়াকৈ শব্দ মিশ্ৰণত আমাক সহায় কৰি আহিছে।",
            "gu": "નવીન, સમીરને મળ. આ આપણને હમણાંથી સાઉન્ડ મિકસીંગ કરવામાં મદદ કરે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Hi Naveen, nice to meet you.",
            "hi": "हाय नवीन, तुमसे मिलकर खुशी हुई।",
            "ka": "ನಮಸ್ಕಾರ ನವೀನ್, ನಿನ್ನನ್ನು ಭೇಟಿಯಾಗಲು ಸಂತೋಷವಾಗಿದೆ.",
            "te": "హాయ్ నవీన్, మిమ్మల్ని కలవడం ఆనందంగా ఉంది.",
            "or": "ନମସ୍କାର ନବୀନ, ତୁମକୁ ଭେଟି ଭଲ ଲାଗିଲା |",
            "as": "হাই নবীন, তোমাক লগ পাই ভাল পালো।",
            "gu": "કેમ છે નવીન, તને મળી ને મજા આવી."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "Sameer has been working as a freelancer for the last couple of years.",
            "hi": "समीर पिछले कुछ सालों से फ्रीलांसर के तौर पर काम कर रहा है।",
            "ka": "ಸಮೀರ್ ಕಳೆದೆರಡು ವರ್ಷಗಳಿಂದ ಫ್ರೀಲ್ಯಾನ್ಸರ್ ಆಗಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾನೆ.",
            "te": "సమీర్ గత రెండేళ్లుగా ఫ్రీలాన్సర్‌గా పనిచేస్తున్నాడు.",
            "or": "ସମୀର ଗତ ଦୁଇ ବର୍ଷ ଧରି ଜଣେ ଫ୍ରିଲାନ୍ସର୍ ଭାବରେ କାର୍ଯ୍ୟ କରିଆସୁଛନ୍ତି |",
            "as": "সমীৰে যোৱা কেইবছৰমান ধৰি ফ্ৰিলাঞ্চাৰ হিচাপে কাম কৰি আছে।",
            "gu": "સમીર છેલ્લા કેટલાક સમયથી ફ્રીલાન્સર તરીકે કામ કરી રહ્યો છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_3_3////",
            "hi": "C9_3_3////",
            "ka": "C9_3_3////",
            "te": "C9_3_3////",
            "or": "C9_3_3////",
            "as": "C9_3_3////",
            "gu": "C9_3_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Freelancer?",
            "hi": "फ्रीलांसर?",
            "ka": "ಫ್ರೀಲ್ಯಾನ್ಸರ್?",
            "te": "ఫ్రీలాన్సర్?",
            "or": "ଫ୍ରିଲାନ୍ସର୍?",
            "as": "ফ্ৰিলাঞ্চাৰ?",
            "gu": "ફ્રીલાન્સર?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Yes, basically, I work independently. I don’t work under anyone. I get employed on specific projects by different organisations. I can decide what I want to work on.",
            "hi": "हाँ, मैं स्वतंत्र रूप से काम करता हूँ। मैं किसी के अंडर काम नहीं करता। मुझे अलग-अलग संस्थाओं से विशेष प्रोजेक्ट के लिए चुना जाता है। मुझे किस प्रोजेक्ट पर काम करना है, इसका निर्णय मैं खुद ले सकता हूँ।",
            "ka": "ಹೌದು, ಮೂಲತಃ, ನಾನು ಸ್ವತಂತ್ರವಾಗಿ ಕೆಲಸ ಮಾಡುತ್ತೇನೆ. ನಾನು ಯಾರ ಅಡಿಯಲ್ಲಿಯೂ ಕೆಲಸ ಮಾಡುವುದಿಲ್ಲ. ನಾನು ವಿವಿಧ ಸಂಸ್ಥೆಗಳಿಂದ ನಿರ್ದಿಷ್ಟ ಯೋಜನೆಗಳಲ್ಲಿ ಉದ್ಯೋಗ ಪಡೆಯುತ್ತೇನೆ. ನಾನು ಏನು ಕೆಲಸ ಮಾಡಬೇಕೆಂದು ನಾನು ನಿರ್ಧರಿಸಬಹುದು.",
            "te": "అవును, సాధారణంగా, నేను స్వతంత్రంగా పని చేస్తాను. నేను ఎవరి కింద పని చేయను. నేను వివిధ సంస్థల ద్వారా వివిధ ప్రాజెక్ట్ లలో ఉద్యోగం పొందుతాను. నేను ఏమి పని చేయాలనుకుంటున్నానో నేను నిర్ణయించుకోగలను.",
            "or": "ହଁ, ମୌଳିକ ଭାବରେ, ମୁଁ ନିରପେକ୍ଷ ଭାବରେ କାର୍ଯ୍ୟ କରେ | ମୁଁ କାହା ଅଧୀନରେ କାମ କରେ ନାହିଁ | ବିଭିନ୍ନ ସଂସ୍ଥା ଦ୍ୱାରା ମୁଁ ନିର୍ଦ୍ଦିଷ୍ଟ ପ୍ରୋଜେକ୍ଟରେ ନିଯୁକ୍ତି ପାଇଥାଏ | ମୁଁ କ’ଣ କାମ କରିବାକୁ ଚାହୁଁଛି ତାହା ମୁଁ ସ୍ଥିର କରିପାରିବି |",
            "as": "হয়, আচলতে মই স্বতন্ত্ৰভাৱে কাম কৰোঁ। মই কাৰো অধীনত কাম নকৰো। মই বিভিন্ন সংগঠনৰপৰা নিৰ্দিষ্ট প্ৰকল্পৰ কাম পাওঁ। মই কোনতো কৰিম নিজে সিদ্ধান্ত ল'ব পাৰো।",
            "gu": "હા, હું મૂળભૂત રીતે સ્વતંત્ર રીતે કામ કરું છું. હું કોઈની નીચે કામ કરતો નથી. હું કેટલીક સંસ્થાઓ દ્વારા ચોક્કસ પ્રોજેક્ટ માટે કામ કરું છું. હું નક્કી કરી શકું છું કે મારે શું કામ કરવું."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Oh, that's so cool, but how do you manage your finances? Isn’t it risky?",
            "hi": "यह तो बहुत अच्छी बात है, लेकिन तुम अपने फायनांस को कैसे मैनेज करते हो? इसमें जोखिम नहीं है?",
            "ka": "ಓಹ್, ಅದು ತುಂಬಾ ಚೆನ್ನಾಗಿದೆ, ಆದರೆ ನಿನ್ನ ಹಣಕಾಸುವನ್ನು ನೀನು ಹೇಗೆ ನಿರ್ವಹಿಸುತ್ತೀಯ? ಇದು ಅಪಾಯಕಾರಿ ಅಲ್ಲವೇ?",
            "te": "ఓహ్, ఇది చాలా బాగుంది, అయితే మీరు మీ ఫైనాన్స్ వ్యవహారాలను ఎలా నిర్వహిస్తారు? ఇది రిస్కీ కాదా?",
            "or": "ଓହ, ଏହା ବହୁତ ଶାନ୍ତ, କିନ୍ତୁ ତୁମେ ତୁମର ଆର୍ଥିକ ପରିଚାଳନା କିପରି କରିବ? ଏହା ବିପଦପୂର୍ଣ୍ଣ ନୁହେଁ କି?",
            "as": "অহ, খুবেই ভাল, কিন্তু তুমি তোমাৰ টকা-পইচাৰ লেনদেন কেনেকৈ কৰা? এইটো বিপদজ্জনক নহয়নে?",
            "gu": "ઓહ તે સરસ છે, પરંતુ તમે તમારી નાણાકીય વ્યવસ્થા કેવી રીતે કરો છે? તે જોખમી નથી?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Yes, until you establish yourself. I have a good reputation in Bangalore, so I keep getting commissions for events. I’m happy because I get to do what I like.",
            "hi": "है, जब तक तुम अपनी पहचान नहीं बना लेते। बैंगलोर में मेरा अच्छा नाम है। इसलिए मुझे इवेंट के लिए कमीशन मिलता रहता है। मैं खुश हूँ, क्योंकि मैं जो काम करता हूँ वह मुझे पसंद है।",
            "ka": "ಹೌದು, ನೀವು ನಿಮ್ಮನ್ನು ಸ್ಥಾಪಿಸುವವರೆಗೆ. ಬೆಂಗಳೂರಿನಲ್ಲಿ ನನಗೆ ಒಳ್ಳೆಯ ಹೆಸರು ಇದೆ, ಹಾಗಾಗಿ ಕಾರ್ಯಕ್ರಮಗಳಿಗೆ ಕಮಿಷನ್ ಪಡೆಯುತ್ತಿದ್ದೇನೆ. ನಾನು ಸಂತೋಷವಾಗಿದ್ದೇನೆ ಏಕೆಂದರೆ ನಾನು ಇಷ್ಟಪಡುವದನ್ನು ನಾನು ಮಾಡುತ್ತೇನೆ.",
            "te": "అవును, మీరు మిమ్మల్ని మీరు ఒక స్థానానికి వెళ్ళేవరకు. బెంగుళూరులో నాకు మంచి పేరు ఉంది, కాబట్టి నాకు ఈవెంట్‌లకు కమీషన్లు వస్తాయి. నేను సంతోషంగా ఉన్నాను ఎందుకంటే నేను ఇష్టపడేదాన్ని నేను చేయగలను.",
            "or": "ହଁ, ଯେପର୍ଯ୍ୟନ୍ତ ତୁମେ ନିଜକୁ ପ୍ରତିଷ୍ଠିତ କରି ନାହଁ | ବାଙ୍ଗାଲୋରରେ ମୋର ଏକ ଭଲ ପ୍ରତିଷ୍ଠା ଅଛି, ତେଣୁ ମୁଁ ଇଭେଣ୍ଟ ପାଇଁ କମିଶନ ପାଉଥାଏ | ମୁଁ ଖୁସି କାରଣ ମୁଁ ଯାହା ପସନ୍ଦ କରେ ତାହା କରିବାକୁ ପାଏ |",
            "as": "হয়, যেতিয়ালৈকে তুমি নিজকে প্ৰতিষ্ঠা নকৰা। বাংগালোৰত মোৰ নাম আছে, সেয়েহে মই অনুষ্ঠানবোৰৰ বাবে কমিছন লাভ কৰো। মই সুখী কাৰণ মই যি ভাল পাওঁ সেয়াই কৰিবলৈ পাওঁ।",
            "gu": "હા, જ્યાં સુધી તમે પોતાની જાતને બાહર નહીં લાવો. મારી બેંગ્લોરમાં પ્રતિષ્ઠા સારી છે, તેથી મને ઈવેન્ટ્સ માટે કમિશન મળતું રહે છે. હું ખુશ છું કારણ કે મને જે ગમે છે તે કામ મને મળે છે."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_3_4",
            "hi": "C9_3_4",
            "ka": "C9_3_4",
            "te": "C9_3_4",
            "or": "C9_3_4",
            "as": "C9_3_4",
            "gu": "C9_3_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen has never heard of a career like this before, and he feels the need to inquire further. He takes Sameer’s contact details to continue this conversation over the phone.",
            "hi": "नवीन ने इससे पहले ऐसे कैरियर के बारे में कभी नहीं सुना था। वह इसके बारे में अधिक जानना चाहता है। वह समीर का फोन नंबर लेता है, ताकि उन पर उससे अधिक जानकारी प्राप्त कर सके।",
            "ka": "ನವೀನ್‌ ಈ ರೀತಿಯ ವೃತ್ತಿಜೀವನದ ಬಗ್ಗೆ ಹಿಂದೆಂದೂ ಕೇಳಿಲ್ಲ, ಮತ್ತು ಮತ್ತಷ್ಟು ವಿಚಾರಿಸಬೇಕೆಂದು ಅವನಿಗೆ ಅನಿಸುತ್ತದೆ. ಫೋನ್‌ನಲ್ಲಿ ಈ ಸಂಭಾಷಣೆಯನ್ನು ಮುಂದುವರಿಸಲು ಅವನು ಸಮೀರ್‌ನ ಸಂಪರ್ಕ ವಿವರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾನೆ.",
            "te": "నవీన్ ఇంతకు ముందు ఇలాంటి కెరీర్ గురించి వినలేదు మరియు మరింత విచారించాల్సిన అవసరం ఉందని అతను భావిస్తున్నాడు. అతను ఫోన్‌లో ఈ సంభాషణను కొనసాగించడానికి సమీర్ సంప్రదింపు వివరాలను తీసుకుంటాడు.",
            "or": "ନବୀନ ଏପରି କ୍ୟାରିୟର୍‌ ବିଷୟରେ କେବେ ଶୁଣି ନାହାଁନ୍ତି, ଏବଂ ସେ ଅଧିକ ତଦନ୍ତ କରିବାର ଆବଶ୍ୟକତା ଅନୁଭବ କରନ୍ତି | ଏହି ବାର୍ତ୍ତାଳାପକୁ ଫୋନରେ ଜାରି ରଖିବା ପାଇଁ ସେ ସମୀରଙ୍କ ଯୋଗାଯୋଗ ବିବରଣୀ ନେଇଥାନ୍ତି |",
            "as": "নবীনে আগতে কেতিয়াও এনে ধৰণৰ জীৱিকাৰ বিষয়ে শুনা নাছিল, সি এই বিষয়ে অধিক অনুসন্ধান কৰাৰ চিন্তা কৰে। সি ফোনত এই কথাখিনি আকৌ পাতিবৰ কাৰণে সমীৰৰ নম্বৰটো লয়।",
            "gu": "નવીનને પહેલાં ક્યારેય સાંભળ્યું ન હતું આવી કારકિર્દી વિશે, અને તે વધુ પૂછપરછ કરવાની જરૂરી માને છે. તેથી તે ફોન પર આ વાતચીત કરવા માટે તે સમીરનો સંપર્ક માટે વિગતો લે છે."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_4_1",
            "hi": "C9_4_1",
            "ka": "C9_4_1",
            "te": "C9_4_1",
            "or": "C9_4_1",
            "as": "C9_4_1",
            "gu": "C9_4_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "When he reaches home that day, he messages Sameer to know more about freelancing.",
            "hi": "जब वह घर पहुंचता है वह समीर को मेसेज करता है कि वह फ्रीलांसिंग के बारे में अधिक जानना चाहता है।",
            "ka": "ಆ ದಿನ ಅವನು ಮನೆಗೆ ಬಂದಾಗ, ಅವನು ಸಮೀರ್‌ಗೆ ಫ್ರೀಲ್ಯಾನ್ಸಿಂಗ್ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಸಂದೇಶ ಕಳುಹಿಸುತ್ತಾನೆ.",
            "te": "అతను ఆ రోజు ఇంటికి చేరుకున్నప్పుడు, ఫ్రీలాన్సింగ్ గురించి మరింత తెలుసుకోవాలని సమీర్‌కు మెసేజ్ చేస్తాడు.",
            "or": "ସେଦିନ ଯେତେବେଳେ ସେ ଘରେ ପହଞ୍ଚନ୍ତି, ସେ ଫ୍ରିଲାନ୍ସିଙ୍ଗ୍ ବିଷୟରେ ଅଧିକ ଜାଣିବା ପାଇଁ ସମୀରଙ୍କୁ ମେସେଜ୍ କରନ୍ତି |.",
            "as": "সেইদিনা সি ঘৰ আহি পোৱাৰ পিছত, সমীৰক ফ্ৰীলাঞ্চিংৰ বিষয়ে অধিক জানিবলৈ মেচেজ কৰে।",
            "gu": "જ્યારે તે એ દિવશે ઘરે પહોંચે છે, તે સમીર ને મેસેજ કરે છે ફ્રીલાન્સિંગ વિશે વધુ જાણવા માટે. "
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_4_2_message",
            "hi": "C9_4_2_मेसेज",
            "ka": "C9_4_2_ಸಂದೇಶ",
            "te": "C9_4_2_మెస్సేజ్",
            "or": "C9_4_2_message",
            "as": "C9_4_2_মেচেজ",
            "gu": "C9_4_2_મેસેજ"
          }
        },
        {
          "valuePath": "blocks.0.props.messageSentTo",
          "value": {
            "en": "Sameer",
            "hi": "समीर",
            "ka": "ಸಮೀರ್‌",
            "te": "సమీర్",
            "or": "ସମୀର",
            "as": "সমীৰ",
            "gu": "સમીર"
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.0.messageText",
          "value": {
            "en": "Hi Sameer, I was hoping to find out a little more about your line of work. I have a few questions for you…",
            "hi": "हाय समीर, मैं तुम्हारे काम के तरीके के बारे में अधिक जानना चाहता हूँ। मुझे तुमसे कुछ सवाल पूछने हैं…",
            "ka": "ನಮಸ್ಕಾರ ಸಮೀರ್, ನಿನ್ನ ಕೆಲಸದ ಬಗ್ಗೆ ಸ್ವಲ್ಪ ಹೆಚ್ಚು ತಿಳಿದುಕೊಳ್ಳಲು ನಾನು ಆಶಿಸಿದ್ದೆ. ನಾನು ನಿನಗೆ ಕೆಲವು ಪ್ರಶ್ನೆಗಳನ್ನು ಹೊಂದಿದ್ದೇನೆ…",
            "te": "హాయ్ సమీర్, నేను మీ పని తీరు గురించి కొంచెం తెలుసుకోవాలని అనుకున్నాను. మీ కోసం నా దగ్గర కొన్ని ప్రశ్నలు ఉన్నాయి...",
            "or": "ନମସ୍କାର ସମୀର, ମୁଁ ତୁମର କାର୍ଯ୍ୟର ଧାଡି ବିଷୟରେ ଟିକିଏ ଅଧିକ ଜାଣିବାକୁ ଆଶା କରୁଥିଲି | ମୋର ତୁମ ପାଇଁ କିଛି ପ୍ରଶ୍ନ ଅଛି…",
            "as": "হাই সমীৰ, মই তোমাৰ কামৰ বিষয়ে আৰু অলপ জানিবলৈ বিচাৰিছিলো। তোমাৰ বাবে মোৰ কেইটামান প্ৰশ্ন আছিলে...",
            "gu": "કેમ છે સમીર, મને આશા છે કે હું તારા આ કામ વિશે થોડુક વધુ જાણું. મારી જોડે થોડા પ્રશ્નો છે તને પૂછવા..."
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.1.messageText",
          "value": {
            "en": "Hi Naveen, glad you messaged. Sure, ask me whatever you have in mind.",
            "hi": "हाय नवीन, मुझे खुशी है है की तुमने मुझे मेसेज किया। तुम मुझे जो चाहे पूछ सकते हो।",
            "ka": "ನಮಸ್ಕಾರ ನವೀನ್, ನೀನು ಮೆಸೇಜ್ ಮಾಡಿದ್ದಕ್ಕೆ ಖುಷಿಯಾಗಿದೆ. ಖಂಡಿತ, ನಿನ್ನ ಮನಸ್ಸಿನಲ್ಲಿ ಏನಿದೆಯೋ ಅದನ್ನು ಕೇಳು.",
            "te": "హాయ్ నవీన్, మీరు మెసేజ్ చేసినందుకు సంతోషం. తప్పకుండా, మీ మనసులో ఉన్నదంతా నన్ను అడగండి.",
            "or": "ନମସ୍କାର ନବୀନ, ତୁମେ ମେସେଜ୍ ପାଇ ଖୁସି ହେଲି | ନିଶ୍ଚିତ, ତୁମ ମନରେ ଯାହା ଅଛି ମୋତେ ପଚା୍ରିପାର |",
            "as": "হাই নবীন, মেচেজ পাই ভাল লাগিল। কোৱা, কি আছিলে।",
            "gu": "કેમ છે નવીન, તારા મેસેજથી ખુશ છું. ચોક્કસ, પૂછ જે તારા મનમાં હોય."
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.2.messageText",
          "value": {
            "en": "What did you do before you started freelancing?",
            "hi": "तुम फ्रीलांसिंग शुरू करने से पहले क्या काम करते थे?",
            "ka": "ನೀನು ಸ್ವತಂತ್ರವಾಗಿ ಪ್ರಾರಂಭಿಸುವ ಮೊದಲು ನೀನು ಏನು ಮಾಡಿದ್ದೀಯ?",
            "te": "ఫ్రీలాన్సింగ్ ప్రారంభించడానికి ముందు మీరు ఏమి చేసారు?",
            "or": "ତୁମେ ଫ୍ରିଲାନ୍ସିଂ ଆରମ୍ଭ କରିବା ପୂର୍ବରୁ କଣ କରୁଥିଲ ?",
            "as": "তুমি ফ্ৰীলাঞ্চিং আৰম্ভ কৰাৰ আগতে কি কৰিছিলা?",
            "gu": "તે શું કર્યું હતું આ ફ્રીલાન્સિંગ ચાલુ કર્યા પહેલા?"
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.3.messageText",
          "value": {
            "en": "I used to work in a two-wheeler plant as a painter. Since I was interested in music, I used to do sound mixing and stuff.",
            "hi": "मैं एक टू व्हीलर प्लांट में पेंटर के तौर पर काम करता था। लेकिन मुझे म्यूजिक में रुचि थी, इसलिए मैं साउंड मिक्सिंग भी किया करता था।",
            "ka": "ನಾನು ಪೈಂಟರ್ ಆಗಿ ದ್ವಿಚಕ್ರ ವಾಹನ ಘಟಕದಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೆ. ನನಗೆ ಸಂಗೀತದಲ್ಲಿ ಆಸಕ್ತಿ ಇದ್ದುದರಿಂದ ಸೌಂಡ್ ಮಿಕ್ಸಿಂಗ್ ಮತ್ತು ಸ್ಟಫ್ ಮಾಡುತ್ತಿದ್ದೆ.",
            "te": "నేను పెయింటర్‌గా ద్విచక్ర వాహనాల ప్లాంట్‌లో పని చేసేవాడిని. నాకు సంగీతం అంటే ఆసక్తి ఉండడంతో సౌండ్ మిక్సింగ్, స్టఫ్ చేసేదాన్ని.",
            "or": "ମୁଁ ଚିତ୍ରକାର ଭାବରେ ଦୁଇ ଚକିଆ ପ୍ଲାଣ୍ଟରେ କାମ କରୁଥିଲି | ଯେହେତୁ ମୁଁ ସଙ୍ଗୀତ ପ୍ରତି ଆଗ୍ରହ ପ୍ରକାଶ କରୁଥିଲି, ମୁଁ ସାଉଣ୍ଡ୍ ମିକ୍ସିଂ ଏବଂ ଷ୍ଟଫ୍ କରୁଥିଲି |",
            "as": "মই পেইণ্টাৰ হিচাপে দুচকীয়া বাহনৰ কাৰখানাত কাম কৰিছিলো। যিহেতু মই সংগীতৰ প্ৰতি আগ্ৰহী আছিলোঁ, মই শব্দ মিশ্ৰণো কৰিছিলো।",
            "gu": "મે ટુ-વ્હીલર પ્લાન્ટમાં કલર કરનારા તરીકે કામ કરતો હતો. મને સંગીતમાં રસ હોવાથી હું સાઉન્ડ મિક્સિંગ અને સ્ટફ કરતો હતો."
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.4.messageText",
          "value": {
            "en": "Where did you learn this skill?",
            "hi": "तुमने यह कौशल कहाँ से सीखा?",
            "ka": "ಈ ಕೌಶಲ್ಯವನ್ನು ನೀವು ಎಲ್ಲಿ ಕಲಿತಿದ್ದೀರಿ?",
            "te": "మీరు ఈ నైపుణ్యాలు ఎక్కడ నేర్చుకున్నారు?",
            "or": "ତୁମେ ଏହି କୌଶଳ କେଉଁଠୁ ଶିଖିଲ ?",
            "as": "তুমি এই দক্ষতাটো ক'ত শিকিলা?",
            "gu": "તે આ આવડત ક્યાં શીખી હતી?"
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.5.messageText",
          "value": {
            "en": "I came across a youtube channel some years back that taught the basics of sound editing and mixing.",
            "hi": "मैंने कुछ साल पहले एक यूट्यूब चैनल पर इसके बारे में देखा था, जिसमें साउंड एडिटिंग और मिक्सिंग के बेसिक के बारे में बताया गया था।",
            "ka": "ನಾನು ಕೆಲವು ವರ್ಷಗಳ ಹಿಂದೆ ಯೂಟ್ಯೂಬ್ ಚಾನೆಲ್ ಅನ್ನು ನೋಡಿದೆ, ಅದು ಧ್ವನಿ ಸಂಪಾದನೆ ಮತ್ತು ಮಿಶ್ರಣದ ಮೂಲಭೂತ ಅಂಶಗಳನ್ನು ಕಲಿಸಿತು.",
            "te": "నేను కొన్ని సంవత్సరాల క్రితం సౌండ్ ఎడిటింగ్ మరియు మిక్సింగ్ యొక్క బేసిక్స్ గురించి యూట్యూబ్ ఛానెల్‌ని చూశాను.",
            "or": "ମୁଁ କିଛି ବର୍ଷ ପୂର୍ବେ ଏକ ୟୁଟ୍ୟୁବ୍ ଚ୍ୟାନେଲ୍ ସାମ୍ନାକୁ ଆସିଥିଲି ଯାହା ସାଉଣ୍ଡ୍ ଏଡିଟିଂ ଏବଂ ମିଶ୍ରଣର ମୌଳିକ ଶିକ୍ଷା ଦେଇଥିଲା |",
            "as": "মই কিছু বছৰ আগতে এটা ইউটিউব চেনেল চাইছিলো যিয়ে শব্দ সম্পাদনা আৰু মিশ্ৰণৰ মৌলিক বিষয়বোৰ শিকাইছিল।",
            "gu": "હું થોડાક વર્ષો પહેલા એક યુટ્યુબ ચેનલ પર જોયું હતું જેમાં ધ્વનિ સંપાદન અને મિશ્રણની મૂળભૂત બાબતો શીખવાડવામાં આવી હતી."
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.6.messageText",
          "value": {
            "en": "When I started learning sound editing and mixing, it felt like a whole new path had opened up..",
            "hi": "जब मैंने साउंड एडिटिंग और मिक्सिंग करना शुरू किया, तब मुझे ऐसा लगा कि मुझे एक नया मार्ग मिल गया है।",
            "ka": "ನಾನು ಸೌಂಡ್ ಎಡಿಟಿಂಗ್ ಮತ್ತು ಮಿಕ್ಸಿಂಗ್ ಕಲಿಯಲು ಪ್ರಾರಂಭಿಸಿದಾಗ, ಹೊಸ ಹಾದಿ ತೆರೆದಂತೆ ಭಾಸವಾಯಿತು.",
            "te": "నేను సౌండ్ ఎడిటింగ్ మరియు మిక్సింగ్ నేర్చుకోవడం మొదలు చేసినప్పుడు, ఒక కొత్త మార్గం తెరుచుకున్నట్లు అనిపించింది.",
            "or": "ଯେତେବେଳେ ମୁଁ ସାଉଣ୍ଡ୍ ଏଡିଟିଂ ଏବଂ ମିଶ୍ରଣ ଶିଖିବା ଆରମ୍ଭ କଲି, ସେତେବେଳେ ଲାଗିଲା ଯେ ଏକ ନୂତନ ରାସ୍ତା ଖୋଲିଛି |",
            "as": "যেতিয়া মই শব্দ সম্পাদনা আৰু মিশ্ৰণ শিকিবলৈ আৰম্ভ কৰিছিলো, এনে লাগিছিল যেন এটা সম্পূৰ্ণ নতুন ৰাস্তা মোৰ কাৰণে মুকলি হৈছে..",
            "gu": "જ્યારે મેં ધ્વનિ સંપાદન અને મિશ્રણ શીખવાનું ચાલુ કર્યું, ત્યારે એવું લાગ્યું કે એક એકદમ મારો નવો રસ્તો ખુલી ગયો છે.."
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.7.messageText",
          "value": {
            "en": "How did you learn about freelancing?",
            "hi": "तुमने फ्रीलांसिंग के बारे में कहाँ से सीखा?",
            "ka": "ಫ್ರೀಲ್ಯಾನ್ಸಿಂಗ್ ಬಗ್ಗೆ ನೀನು ಹೇಗೆ ಕಲಿತಿದ್ದೀಯ?",
            "te": "మీరు ఫ్రీలాన్సింగ్ గురించి ఎలా నేర్చుకున్నారు?",
            "or": "ଫ୍ରିଲାନ୍ସିଂ ବିଷୟରେ ତୁମେ କିପରି ଶିଖିଲ ?",
            "as": "তুমি ফ্ৰীলাঞ্চিংৰ বিষয়ে কেনেকৈ শিকিলা?",
            "gu": "તે કેવી રીતે સિખ્યો ફ્રીલાન્સિંગ વિશે?"
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.8.messageText",
          "value": {
            "en": "For a long time, I managed my factory job and did freelance projects on the side.. But when I started getting good customer ratings and word of mouth, the number of projects increased.  I started working as a full-time sound editor 5 years ago.",
            "hi": "मैं बहुत सालों से फैक्ट्री में जॉब करते-करते, फ्रीलांसिंग के प्रोजेक्ट किया करता था। लेकिन जब मुझे कस्टमर से अच्छी रेटिंग मिलने लगे और लोग मेरे तारीफ करने लगे, तब मुझे मिलने वाले प्रोजेक्ट की संख्या भी बढ़ गई। 5 साल पहले मैंने फुल टाइम साउंड एडिटर के तौर पर काम करना शुरू किया।",
            "ka": "ಬಹಳ ದಿನಗಳವರೆಗೆ ನಾನು ಫ್ಯಾಕ್ಟರಿ ಕೆಲಸವನ್ನು ನಿರ್ವಹಿಸುತ್ತಿದ್ದೆ ಮತ್ತು ಬದಿಯಲ್ಲಿ ಸ್ವತಂತ್ರ ಯೋಜನೆಗಳನ್ನು ಮಾಡಿದೆ.ಆದರೆ ನಾನು ಉತ್ತಮ ಗ್ರಾಹಕ ರೇಟಿಂಗ್ ಮತ್ತು ಬಾಯಿ ಮಾತುಗಳನ್ನು ಪಡೆಯಲು ಪ್ರಾರಂಭಿಸಿದಾಗ, ಯೋಜನೆಗಳ ಸಂಖ್ಯೆ ಹೆಚ್ಚಾಯಿತು. ನಾನು 5 ವರ್ಷಗಳ ಹಿಂದೆ ಪೂರ್ಣ ಸಮಯದ ಧ್ವನಿ ಸಂಪಾದಕನಾಗಿ ಕೆಲಸ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿದೆ.",
            "te": "చాలా కాలంగా ఫ్యాక్టరీ ఉద్యోగం ని మేనేజ్ చేస్తూ, మరోపక్క ఫ్రీలాన్స్ ప్రాజెక్ట్స్ చేశాను.. కానీ నాకు మంచి కస్టమర్ రేటింగ్స్ రావడం, నోటి మాట ద్వారా కూడా ప్రాజెక్ట్ ల సంఖ్య పెరిగింది. నేను 5 సంవత్సరాల క్రితం పూర్తి సమయం సౌండ్ ఎడిటర్‌గా పనిచేయడం ప్రారంభించాను.",
            "or": "ଦୀର୍ଘ ସମୟ ଧରି, ମୁଁ ମୋର କାରଖାନା କାର୍ଯ୍ୟ ପରିଚାଳନା କଲି ଏବଂ ପାର୍ଶ୍ୱରେ ଫ୍ରିଲାନ୍ସ ପ୍ରୋଜେକ୍ଟ କଲି .. କିନ୍ତୁ ଯେତେବେଳେ ମୁଁ ଗ୍ରାହକଙ୍କ ଭଲ ରେଟିଙ୍ଗ ଏବଂ ମୁହଁ ରୁ କଥା ପାଇବା ଆରମ୍ଭ କଲି, ପ୍ରକଳ୍ପ ସଂଖ୍ୟା ବଢିଗଲା | ମୁଁ 5 ବର୍ଷ ପୂର୍ବେ ଏକ ଫୁଲ୍ ଟାଇମ୍ ସାଉଣ୍ଡ୍ ଏଡିଟର୍ ଭାବରେ କାମ କରିବା ଆରମ୍ଭ କରିଥିଲି |",
            "as": "বহুদিন ধৰি মই মোৰ কাৰখানাৰ কাম পৰিচালনা কৰাৰ লগতে ফ্ৰিলেন্স প্ৰকল্পও কৰি আছিলো। কিন্তু যেতিয়া মই গ্ৰাহকৰ মুখৰপৰা ভাল ৰেটিং পাবলৈ আৰম্ভ কৰিলো, প্ৰকল্পৰ সংখ্যাও বেছিকৈ পাবলৈ ল’লো। মই 5 বছৰ আগতে এজন পূৰ্ণ-সময়ৰ ধ্বনি সম্পাদক হিচাপে কাম কৰা আৰম্ভ কৰিছিলো।",
            "gu": "ગણા સમય માટે, મેં મારી ફેક્ટરીની નોકરીનું કામ ચાલુ રાખ્યું અને બાજુમાં ફ્રીલાન્સ પ્રોજેક્ટ્સ કર્યા.. પરંતુ જ્યારે મને સારા ગ્રાહકોના આવકાર અને એમના તરફથી વખાણ, ત્યારે પ્રોજેક્ટ્સની સંખ્યામાં વધારો થયો. મેં 5 વર્ષ પહેલા પૂરા સમય માટે સાઉન્ડ એડિટર તરીકે કામ કરવાનું શરૂ કર્યું."
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.9.messageText",
          "value": {
            "en": "Are there times when you don’t get projects? What do you do then?",
            "hi": "क्या कभी ऐसा समय भी आता है कि तुम्हें प्रोजेक्ट नहीं मिलते? ऐसे समय पर तुम क्या करते हो?",
            "ka": "ನೀನು ಪ್ರಾಜೆಕ್ಟ್‌ಗಳನ್ನು ಪಡೆಯದ ಸಂದರ್ಭಗಳಿವೆಯೇ? ಹಾಗಾದರೆ ನೀನು ಏನು ಮಾಡುತ್ತೀಯ?",
            "te": "మీకు ప్రాజెక్ట్‌లు రాని సందర్భాలు ఏమైనా ఉన్నాయా? అప్పుడు మీరు ఏమి చేస్తారు?",
            "or": "ଏମିତି କିଛି ସମୟ ଅଛି କି ଯେତେବେଳେ ତୁମେ ପ୍ରୋଜେକ୍ଟ ପାଇ ନାହିଁ? ତାପରେ ତୁମେ କ’ଣ କରିଛ?",
            "as": "এনেকুৱা সময় আহিছিল নে যেতিয়া তুমি প্ৰকল্পৰ কাম পোৱা নাছিলা? তেতিয়া তুমি কি কৰিছিলা?",
            "gu": "એવો કોઈ સમય છે જ્યારે તને કોઈ પ્રોજેકટ ના મળે? ત્યારે તું શું કરે છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.10.messageText",
          "value": {
            "en": "Yes, there are specific periods when I don’t get any projects. During these times, I focus on networking and connecting to more people.  I also look to upskill or learn new skills to increase the scope of my services.",
            "hi": "हाँ, कभी-कभी ऐसा सयम भी आता है, जब मुझे कोई प्रोजेक्ट नहीं मिलता। ऐसे समय में, मैं अपना नेटवर्क बढ़ाने और लोगों से संपर्क आने की कोशिश करता हूँ। मैं अपने सर्विस के स्कोप को बढ़ाने के लिए, अपस्किल करता हूँ या कुछ नए स्किल सीखता हूँ।",
            "ka": "ಹೌದು, ನಾನು ಯಾವುದೇ ಪ್ರಾಜೆಕ್ಟ್‌ಗಳನ್ನು ಪಡೆಯದೇ ಇರುವ ನಿರ್ದಿಷ್ಟ ಅವಧಿಗಳಿವೆ. ಈ ಸಮಯದಲ್ಲಿ, ನಾನು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮತ್ತು ಹೆಚ್ಚಿನ ಜನರನ್ನು ಸಂಪರ್ಕಿಸುವುದರ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸುತ್ತೇನೆ. ನನ್ನ ಸೇವೆಗಳ ವ್ಯಾಪ್ತಿಯನ್ನು ಹೆಚ್ಚಿಸಲು ನಾನು ಕೌಶಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸಲು ಅಥವಾ ಹೊಸ ಕೌಶಲ್ಯಗಳನ್ನು ಕಲಿಯಲು ಸಹ ನೋಡುತ್ತೇನೆ.",
            "te": "అవును, నేను ఎటువంటి ప్రాజెక్ట్‌లను పొందని నిర్దిష్ట కాలాలు ఉన్నాయి.. ఈ సమయాల్లో, నేను నెట్‌వర్కింగ్ మరియు ఎక్కువ మంది వ్యక్తులతో కనెక్ట్ కావడంపై దృష్టి సారిస్తాను. నా సేవల పరిధిని పెంచుకోవడానికి నేను నైపుణ్యం పెంచుకోవాలని లేదా కొత్త నైపుణ్యాలను నేర్చుకోవాలని కూడా చూస్తున్నాను.",
            "or": "ହଁ, କିଛି ନିର୍ଦ୍ଦିଷ୍ଟ ସମୟ ଅଛି ଯେତେବେଳେ ମୁଁ କୌଣସି ପ୍ରୋଜେକ୍ଟ ପାଇ ନାହିଁ | ଏହି ସମୟରେ, ମୁଁ ନେଟୱାର୍କିଂ ଏବଂ ଅଧିକ ଲୋକଙ୍କ ସହିତ ଯୋଗାଯୋଗ ଉପରେ ଧ୍ୟାନ ଦେଇଥାଏ | ମୋର ସେବାଗୁଡିକର ପରିସର ବଢାଇବା ପାଇଁ ମୁଁ ଅପ୍ ସ୍କିଲ୍ କିମ୍ବା ନୂତନ କୌଶଳ ଶିଖିବାକୁ ମଧ୍ୟ ଚାହେଁ |",
            "as": "হয়, তেনেকুৱা সময় আহিছিল যেতিয়া মই প্ৰকল্পৰ কাম পোৱা নাছিলো। সেই সময়ছোৱাত, মই অধিক লোকৰ সৈতে নেটৱৰ্কিং আৰু সংযোগ বঢ়োৱাৰ ওপৰত গুৰুত্ব দিছিলো। মই মোৰ সেৱাৰ পৰিসৰ বৃদ্ধি কৰিবলৈ দক্ষতা বৃদ্ধি বা নতুন দক্ষতা শিকিবলৈও চেষ্টা কৰিছিলো।",
            "gu": "હા, એવા થોડા ચોક્કસ સમય છે જ્યારે મને કોઈ કામ ના મળે. એ સમયે હું લોકો સાથે મળવાનું અને સંબંધો બનાવવાનું ચાલુ રાખું. હું કઈક નવું કરવાનું શીખું છું જે મારા કામમાં વધારો કરે."
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.11.messageText",
          "value": {
            "en": "What do you think your career will look like in the future?",
            "hi": "तुम्हारे कैरियर का भविष्य कैसा होगा, ऐसा तुम्हें लगता है?",
            "ka": "ಭವಿಷ್ಯದಲ್ಲಿ ನಿನು ವೃತ್ತಿಜೀವನ ಹೇಗಿರುತ್ತದೆ ಎಂದು ನೀನು ಯೋಚಿಸುತ್ತೀಯ?",
            "te": "భవిష్యత్తులో మీ కెరీర్ ఎలా ఉంటుందని మీరు అనుకుంటున్నారు?",
            "or": "ଭବିଷ୍ୟତରେ ତୁମର କ୍ୟାରିୟର କିପରି ଦେଖାଯିବ ବୋଲି ତୁମେ ଭାବୁଛ?",
            "as": "ভৱিষ্যতে তোমাৰ জীৱিকা কেনেকুৱা হ'ব বুলি তুমি ভাবা?",
            "gu": "તને શું લાગે છે કે તારું ભવિષય કેવું હશે?"
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.12.messageText",
          "value": {
            "en": "I intend to continue working like this because I really enjoy my work and the fact that I can choose the kind of projects I can work on.",
            "hi": "मैं ऐसे ही काम करना जारी रखना चाहता हूँ क्योंकि मुझे मेरा यह काम बहुत पसंद हैं और मुझे किस प्रोजेक्ट पर काम करना है यह मैं खुद चुन सकता हूँ।",
            "ka": "ನಾನು ಈ ರೀತಿಯ ಕೆಲಸವನ್ನು ಮುಂದುವರಿಸಲು ಉದ್ದೇಶಿಸಿದ್ದೇನೆ ಏಕೆಂದರೆ ನನ್ನ ಕೆಲಸವನ್ನು ನಾನು ನಿಜವಾಗಿಯೂ ಆನಂದಿಸುತ್ತೇನೆ ಮತ್ತು ನಾನು ಕೆಲಸ ಮಾಡಬಹುದಾದ ಯೋಜನೆಗಳ ಪ್ರಕಾರವನ್ನು ನಾನು ಆಯ್ಕೆ ಮಾಡಬಹುದು.",
            "te": "నా పనిని నేను నిజంగా ఆస్వాదిస్తున్నాను మరియు నేను పని చేయగల ప్రాజెక్ట్‌లను ఎంచుకోగలను అనే వాస్తవాన్ని నేను ఇలాగే పనిని కొనసాగించాలనుకుంటున్నాను.",
            "or": "ମୁଁ ଏହିପରି କାର୍ଯ୍ୟ ଜାରି ରଖିବାକୁ ଇଚ୍ଛା କରେ କାରଣ ମୁଁ ପ୍ରକୃତରେ ମୋର କାର୍ଯ୍ୟକୁ ଉପଭୋଗ କରେ ଏବଂ ତାହା ସତ୍ୟ ଅଟେ କି ମୁଁ କେଉଁ ପ୍ରକାରର ପ୍ରକଳ୍ପ ବାଛିବି ଯାହା ଉପରେ ମୁଁ କାର୍ଯ୍ୟ କରିପାରିବି |",
            "as": "মই এনেদৰেই কাম কৰি থাকিব বিচাৰো কাৰণ মই সঁচাকৈয়ে এইটো কাম কৰি ভাল পাওঁ আৰু মই কেনে ধৰণৰ প্ৰকল্পত কাম কৰিম সেইটো নিজেই বাছিব পাৰো।",
            "gu": "હું માનું છું કે આ કામ ચાલુ રાખીશ કેમ કે મને આ કામમાં મજા આવે છે અને હકીકત એ છે કે હું મારૂ ગમતું કામ કરી શકુ."
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.13.messageText",
          "value": {
            "en": "If someday I can save enough, I would like to open my small production house and employ a few people to manage different kinds of projects at a time.",
            "hi": "अगर मेरे पास पर्याप्त पैसे जमा हुए, तो मैं एक छोटा सा प्रोडक्शन हाउस खोलना चाहता हूँ और कुछ लोगों को काम पर रखकर अलग-अलग प्रोजेक्ट लेना चाहता हूँ।",
            "ka": "ಒಂದು ದಿನ ನಾನು ಸಾಕಷ್ಟು ಉಳಿಸಬಹುದಾದರೆ, ನನ್ನ ಸಣ್ಣ ಪ್ರೊಡಕ್ಷನ್ ಹೌಸ್ ಅನ್ನು ತೆರೆಯಲು ಮತ್ತು ಒಂದೇ ಸಮಯದಲ್ಲಿ ವಿವಿಧ ರೀತಿಯ ಯೋಜನೆಗಳನ್ನು ನಿರ್ವಹಿಸಲು ಕೆಲವು ಜನರನ್ನು ನೇಮಿಸಿಕೊಳ್ಳಲು ನಾನು ಬಯಸುತ್ತೇನೆ.",
            "te": "ఏదో ఒక రోజు నేను తగినంతగా ఆదా చేసుకోగలిగితే, నేను నా చిన్న ప్రొడక్షన్ హౌస్‌ని తెరిచి, ఒకేసారి వివిధ రకాల ప్రాజెక్ట్‌లను నిర్వహించడానికి కొంతమంది వ్యక్తులను నియమించాలనుకుంటున్నాను.",
            "or": "ଯଦି ଦିନେ ମୁଁ ଯଥେଷ୍ଟ ସଞ୍ଚୟ କରିପାରିବି,ତେବେ ମୁଁ ମୋର ଛୋଟ ଉତ୍ପାଦନ ଘର ଖୋଲିବାକୁ ଏବଂ ଏକ ସମୟରେ ବିଭିନ୍ନ ପ୍ରକାରର ପ୍ରକଳ୍ପ ପରିଚାଳନା କରିବାକୁ କିଛି ଲୋକଙ୍କୁ ନିଯୁକ୍ତି ଦେବାକୁ ଚାହେଁ |",
            "as": "যদি কোনোদিন মই টকা জমা কৰিব পাৰো, মই এটা সৰু প্ৰডাকচন হাউচ খুলিম আৰু একেসময়তে বিভিন্ন ধৰণৰ প্ৰকল্প পৰিচালনা কৰিবলৈ কেইজনমান লোকক নিযুক্তি দিম।",
            "gu": "ક્યારેક જો હું પૂરતો સમય બચાવી શકીશ, તો હું મારું નાનું પ્રોડક્શન હાઉસ ખોલવા અને તે સમયે અલગ અલગ પ્રકારના પ્રોજેક્ટ્સનું સંચાલન કરવા માટે થોડા લોકોને રોજગાર આપવા માંગું છું."
          }
        }
      ],
      "page12": [
        {
          "valuePath": "display",
          "value": {
            "en": "informational_interview",
            "hi": "इन्फॉर्मेशनल_इंटरव्यू",
            "ka": "ಮಾಹಿತಿ_ಸಂದರ್ಶನ",
            "te": "సమాచార_ఇంటర్వ్యూ",
            "or": "informational_interview",
            "as": "তথ্যমূলক_সাক্ষাৎকাৰ",
            "gu": "માહિતીપૂર્વક_ઇન્ટરવ્યુ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Informational Interview(Research) experience!",
            "hi": "आपने अभी-अभी इन्फॉर्मेशनल इंटरव्यू (रिसर्च) का अनुभव प्राप्त किया हैं!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಮಾಹಿತಿ ಸಂದರ್ಶನ (ಸಂಶೋಧನೆ) ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే సమాచార_ఇంటర్వ్యూ (పరిశోధన) అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ କେବଳ ସୂଚନାପୂର୍ଣ୍ଣ ସାକ୍ଷାତକାର (ଗବେଷଣା) ଅଭିଜ୍ଞତା ହାସଲ କରିଛନ୍ତି!",
            "as": "তুমি এইমাত্ৰ তথ্যমূলক সাক্ষাৎকাৰ (গৱেষণা) অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "તમે હમણાં જ માહિતીલક્ષી ઇન્ટરવ્યુનો(સંશોધનનો) અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Informational Interview(Research)",
            "hi": "इन्फॉर्मेशनल_इंटरव्यू (रिसर्च)",
            "ka": "ಮಾಹಿತಿ ಸಂದರ್ಶನ (ಸಂಶೋಧನೆ)",
            "te": "సమాచార_ఇంటర్వ్యూ (పరిశోధన)",
            "or": "ସୂଚନାଯୋଗ୍ୟ ସାକ୍ଷାତକାର (ଗବେଷଣା)",
            "as": "তথ্যমূলক সাক্ষাৎকাৰ (গৱেষণা)",
            "gu": "માહિતીપૂર્વક ઇન્ટરવ્યુનો(સંશોધનનો)"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर विकास कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "కెరీర్ అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ଯାରିୟର୍‌ ଡେଭ୍‌ଲପ୍‌ମେଣ୍ଟ୍‌ କୌଶଳ",
            "as": "জীৱিকা বিকাশৰ দক্ষতা",
            "gu": "કારકિર્દી વિકાસ કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Naveen is curious to learn more from Sameer about what it means to be a freelancer and establish himself in the field. To do this, he treats the conversation like an interview and asks precise questions to ‘research’ this new career direction. In a discussion like this, it is essential to focus on the information you need and ask straightforward questions while keeping comfortable with the person you are interviewing.",
            "hi": "नवीन समीर से और जानकारी प्राप्त करना चाहता है कि, फ्रीलांसिंग बनने का अनुभव कैसा होता है और इस फील्ड में अपनी की पहचान कैसे बनाते हैं। इसलिए वह अपनी बातचीत को एक इंटरव्यू का रूप देता है और इस नए कैरियर के बारे में ‘रिसर्च’ करने के लिए विशेष प्रश्न पूछता है। इस तरह की चर्चा में, आपको कौन सी जानकारी चाहिए इस पर ध्यान केंद्रित करना महत्वपूर्ण होता है। आप जिस व्यक्ति का इंटरव्यू ले रहे हैं उसके कम्फर्ट को ध्यान में रखते हुए स्पष्ट प्रश्न पूछने चाहिए।",
            "ka": "ಫ್ರೀಲ್ಯಾನ್ಸರ್ ಆಗುವುದು ಮತ್ತು ಕ್ಷೇತ್ರದಲ್ಲಿ ತನ್ನನ್ನು ತಾನು ಸ್ಥಾಪಿಸಿಕೊಳ್ಳುವುದು ಎಂದರೆ ಏನು ಎಂದು ಸಮೀರ್‌ನಿಂದ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ನವೀನ್‌ಗೆ ಕುತೂಹಲವಿದೆ.ಇದನ್ನು ಮಾಡಲು, ಅವನು ಸಂಭಾಷಣೆಯನ್ನು ಸಂದರ್ಶನದಂತೆ ಪರಿಗಣಿಸುತ್ತಾನೆ ಮತ್ತು ಈ ಹೊಸ ವೃತ್ತಿಜೀವನದ ದಿಕ್ಕನ್ನು 'ಸಂಶೋಧಿಸಲು' ನಿಖರವಾದ ಪ್ರಶ್ನೆಗಳನ್ನು ಕೇಳುತ್ತಾನೆ.ಈ ರೀತಿಯ ಚರ್ಚೆಯಲ್ಲಿ, ನಿಮಗೆ ಅಗತ್ಯವಿರುವ ಮಾಹಿತಿಯ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸುವುದು ಮತ್ತು ನೀವು ಸಂದರ್ಶಿಸುತ್ತಿರುವ ವ್ಯಕ್ತಿಯೊಂದಿಗೆ ಆರಾಮದಾಯಕವಾದ ಪ್ರಶ್ನೆಗಳನ್ನು ಕೇಳುವುದು ಅತ್ಯಗತ್ಯ.",
            "te": "నవీన్‌కి ఫ్రీలాన్సర్‌గా ఉండటం మరియు ఆ ఫీల్డ్ లో స్థిరపడడం అంటే ఏమిటి అనే దాని గురించి సమీర్ నుండి తెలుసుకోవడానికి ఆసక్తిగా ఉన్నాడు. దీన్ని చేయడానికి, అతను సంభాషణను ఇంటర్వ్యూ లాగా పరిగణిస్తాడు మరియు ఈ కొత్త కెరీర్ దిశను 'పరిశోధన' చేయడానికి అవసరమైన ప్రశ్నలను అడుగుతాడు. ఇలాంటి చర్చలో, మీకు అవసరమైన సమాచారంపై దృష్టి పెట్టడం మరియు మీరు ఇంటర్వ్యూ చేస్తున్న వ్యక్తితో సౌకర్యవంతంగా ఉంటూనే సూటిగా ప్రశ్నలు అడగడం చాలా అవసరం.",
            "or": "ଜଣେ ଫ୍ରିଲାନ୍ସର୍ ହେବା ଏବଂ ଏହି କ୍ଷେତ୍ରରେ ନିଜକୁ ପ୍ରତିଷ୍ଠିତ କରିବାର ଅର୍ଥ ବିଷୟରେ ସମୀରଙ୍କଠାରୁ ଅଧିକ ଜାଣିବାକୁ ନବୀନ ଆଗ୍ରହୀ | ଏହା କରିବା ପାଇଁ, ସେ ବାର୍ତ୍ତାଳାପକୁ ଏକ ସାକ୍ଷାତକାର ପରି ବ୍ୟବହାର କରନ୍ତି ଏବଂ ଏହି ନୂତନ କ୍ୟାରିୟର ଦିଗକୁ ‘ଗବେଷଣା’ କରିବାକୁ ସଠିକ୍ ପ୍ରଶ୍ନ ପଚାରନ୍ତି | ଏହି ପରି ଏକ ଆଲୋଚନାରେ, ଆପଣ ଆବଶ୍ୟକ କରୁଥିବା ସୂଚନା ଉପରେ ଧ୍ୟାନ ଦେବା ଏବଂ ଆପଣ ସାକ୍ଷାତକାର କରୁଥିବା ବ୍ୟକ୍ତିଙ୍କ ସହ ଆରାମରେ ରହିବାବେଳେ ସରଳ ପ୍ରଶ୍ନ ପଚାରିବା ଏକାନ୍ତ ଆବଶ୍ୟକ |",
            "as": "নবীনে সমীৰৰ পৰা এজন ফ্ৰিলাঞ্চাৰ হোৱাৰ অৰ্থ কি আৰু নিজকে ক্ষেত্ৰখনত প্ৰতিষ্ঠা কৰিবলৈ অধিক জানিবলৈ আগ্ৰহী। এনে কৰিবলৈ, সি কথোপকথনটো সাক্ষাৎকাৰৰ দৰে ব্যৱহাৰ কৰে আৰু এই নতুন জীৱিকাৰ দিশটো 'গৱেষণা' কৰিবলৈ সঠিক প্ৰশ্ন কৰে। এনে ধৰণৰ আলোচনাত, তুমি সাক্ষাৎকাৰ লোৱা ব্যক্তিজনক অসুবিধাত নেপেলোৱাকৈ তোমাৰ প্ৰয়োজনীয় তথ্যৰ ওপৰত গুৰুত্ব দিয়া আৰু পোনপটীয়া প্ৰশ্ন সোধাটো আৱশ্যকীয়।",
            "gu": "નવીન સમીર જોડેથી વધુ જાણવા માટે આતુર છે કે ફ્રીલાન્સર બનવાનો અને આ ક્ષેત્રમાં પોતાની રીતે કામ કરવાનો શીખવું છે. આ કરવા માટે, તે વાતચીતને ઇન્ટરવ્યુની જેમ લે છે અને કારકિર્દીની આ નવી દિશાને ‘સંશોધન’ કરવા માટે ચોક્કસ પ્રશ્નો પૂછે છે. આના જેવી ચર્ચામાં, તમને જોઈતી માહિતી પર ધ્યાન રાખવું જોઈએ અને જે વ્યક્તિનો ઇન્ટરવ્યુ લઈ રહ્યા છો તેની સાથે સરળતાથી સીધા પ્રશ્નો પૂછવા જરૂરી છે."
          }
        }
      ],
      "page16": [
        {
          "valuePath": "display",
          "value": {
            "en": "mcq_copy_copy",
            "hi": "mcq_copy_copy",
            "ka": "ಎಂಸಿಕ್ಯೂ_ಕಾಪಿ_ಕಾಪಿ",
            "te": "mcq_copy_copy",
            "or": "mcq_copy_copy",
            "as": "mcq_copy_copy",
            "gu": "mcq_નકલ_નકલ"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "An informational interview is helpful when:",
            "hi": "इंफॉर्मेशनल इंटरव्यू तब मददगार साबित होता है जब:",
            "ka": "ಮಾಹಿತಿಯ ಸಂದರ್ಶನವು ಯಾವಾಗ ಸಹಾಯಕವಾಗಿರುತ್ತದೆ:",
            "te": "ఒక సమాచార ఇంటర్వ్యూ ఈ సమయాలలో ఉపయోగపడుతుంది:",
            "or": "ଏକ ସୂଚନାପୂର୍ଣ୍ଣ ସାକ୍ଷାତକାର ସାହାଯ୍ୟକାରି ହୁଏ ଯେବେ:",
            "as": "তথ্যমূলক সাক্ষাৎকাৰ এটা সহায়ক হয় যেতিয়া:",
            "gu": "માહિતીપૂર્વક ઇન્ટરવ્યુ મદદરૂપ થાય છે જ્યારે:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "You are looking to change to a new job",
            "hi": "आप नया जॉब ढूंढना चाहते हैं",
            "ka": "ನೀವು ಹೊಸ ಉದ್ಯೋಗಕ್ಕೆ ಬದಲಾಯಿಸಲು ಬಯಸುತ್ತೀರಿ",
            "te": "మీరు కొత్త ఉద్యోగానికి మారాలని చూస్తున్నప్పుడు",
            "or": "ଏକ ସୂଚନାପୂର୍ଣ୍ଣ ସାକ୍ଷାତକାର ସାହାଯ୍ୟକାରି ହୁଏ ଯେବେ:",
            "as": "তুমি এটা নতুন চাকৰি ল'ব বিচাৰিছা",
            "gu": "જ્યારે તમે નવી નોકરી સોધ કરી રહ્યા છો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "You need to apply to a college for your studies",
            "hi": "आपको पढ़ाई के लिए कॉलेज में अप्लाई करना होता हैं",
            "ka": "ನಿಮ್ಮ ಅಧ್ಯಯನಕ್ಕಾಗಿ ನೀವು ಕಾಲೇಜಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಬೇಕು",
            "te": "మీరు మీ చదువుల కోసం కళాశాలలకు దరఖాస్తు చేసుకోవాల్సినప్పుడు",
            "or": "ତୁମର ଅଧ୍ୟୟନ ପାଇଁ ତୁମେ ଏକ କଲେଜରେ ଆବେଦନ କରିବା ଆବଶ୍ୟକ |",
            "as": "তুমি অধ্যয়নৰ বাবে এখন মহাবিদ্যালয়ত আবেদন কৰিব বিচাৰিছা",
            "gu": "તમારે કોલેજમાં અરજી કરવી જોઈએ ભણવા માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "You want to know more about a career direction",
            "hi": "आपको करियर की दिशा के बारे में अधिक जानकारी चाहिए होती हैं",
            "ka": "ನೀವು ವೃತ್ತಿ ನಿರ್ದೇಶನದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಬಯಸುತ್ತೀರಿ",
            "te": "మీరు కెరీర్ దిశ గురించి మరింత తెలుసుకోవాలనుకుంటున్నప్పుడు",
            "or": "ଆପଣ କ୍ୟାରିୟର ଦିଗ ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ଚାହାଁନ୍ତି |",
            "as": "তুমি জীৱিকাৰ দিশৰ বিষয়ে অধিক জানিব বিচাৰিছা",
            "gu": "તમે વધુ જાણવા માંગો છો કારકિર્દીની દિશા વિશે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.display",
          "value": {
            "en": "You want to know how you would feel in a particular role",
            "hi": "आपको यह जानना होता है कि किसी विशेष जॉब में आपको कैसा अनुभव मिलेगा",
            "ka": "ನಿರ್ದಿಷ್ಟ ಪಾತ್ರದಲ್ಲಿ ನೀವು ಹೇಗೆ ಭಾವಿಸುತ್ತೀರಿ ಎಂದು ತಿಳಿಯಲು ನೀವು ಬಯಸುತ್ತೀರಿ",
            "te": "ఒక నిర్దిష్ట పాత్రలో మీరు ఎలా ఫీల్ అవుతున్నారో తెలుసుకోవాలనుకుంటున్నప్పుడు",
            "or": "ଆପଣ ଜାଣିବାକୁ ଚାହାଁନ୍ତି ଯେ ଆପଣ ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ଭୂମିକାରେ କିପରି ଅନୁଭବ କରିବେ |",
            "as": "তুমি জানিব বিচাৰিছা যে এটা নিৰ্দিষ্ট ভূমিকাত তুমি কেনে অনুভৱ কৰিবা",
            "gu": "તમે કોઈ ચોક્કસ ભૂમિકામાં કેવું અનુભવશો તે જાણવા માંગો છો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "बहुत बढ़िया!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "బాగా చెప్పారు!",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ !",
            "as": "ভাল কৰিছা!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "बहुत बढ़िया!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "బాగా చెప్పారు!",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ !",
            "as": "ভাল কৰিছা!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "बहुत बढ़िया!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "బాగా చెప్పారు!",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ !",
            "as": "ভাল কৰিছা!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.popUpText",
          "value": {
            "en": "Try again! For an in-depth experience, an internship would be better.",
            "hi": "फिर से सोचो! एक अच्छा अनुभव के लिए इन्टर्नशिप अच्छा विकल्प साबित होगा।",
            "ka": "ಮತ್ತೆ ಪ್ರಯತ್ನಿಸು! ಆಳವಾದ ಅನುಭವಕ್ಕಾಗಿ, ಇಂಟರ್ನ್‌ಶಿಪ್ ಉತ್ತಮವಾಗಿರುತ್ತದೆ.",
            "te": "మళ్లీ ప్రయత్నించండి! లోతైన అనుభవం కోసం, ఇంటర్న్‌షిప్ మంచిది.",
            "or": "ପୁଣିଥରେ ଚେଷ୍ଟା କରନ୍ତୁ! ଏକ ଗଭୀର ଅନୁଭୂତି ପାଇଁ, ଏକ ଇଣ୍ଟର୍ନସିପ୍ ଭଲ ହେବ |",
            "as": "পুনৰ চেষ্টা কৰা! এক গভীৰ অভিজ্ঞতাৰ বাবে, ইণ্টাৰ্নশ্বিপ এটা কৰাটো ভাল হ'ব।",
            "gu": "ફરીથી પ્રયાસ કરો! વધુ ઊંડા અનુભવ માટે, ઇન્ટર્નશિપ વધુ સારી રહેશે."
          }
        }
      ],
      "page13": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_1_4",
            "hi": "C9_1_4",
            "ka": "C9_1_4",
            "te": "C9_1_4",
            "or": "C9_1_4",
            "as": "C9_1_4",
            "gu": "C9_1_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The conversation with Sameer gets Naveen thinking about his own career. He feels he is stuck and has to decide if he wants to continue the contract job or do something else.",
            "hi": "समीर के साथ बातचीत करके नवीन अपने करियर के बारे में सोचने लगा। उसे लगा कि, वह इस काम में उलझ चुका है और अब उसे यह निर्णय लेना होगा कि, वह कॉन्ट्रैक्ट जॉब जारी रखें या और कुछ नया काम करें।",
            "ka": "ಸಮೀರ್ ಜೊತೆಗಿನ ಸಂಭಾಷಣೆಯು ನವೀನ್ ತನ್ನ ವೃತ್ತಿಜೀವನದ ಬಗ್ಗೆ ಯೋಚಿಸುವಂತೆ ಮಾಡುತ್ತದೆ. ತಾನು ಸಿಕ್ಕಿಹಾಕಿಕೊಂಡಿದ್ದೇನೆ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ ಮತ್ತು ಅವನು ಗುತ್ತಿಗೆ ಕೆಲಸವನ್ನು ಮುಂದುವರಿಸಲು ಅಥವಾ ಇನ್ನೇನಾದರೂ ಮಾಡಲು ಬಯಸುತ್ತಾನೆಯೇ ಎಂದು ನಿರ್ಧರಿಸಬೇಕು.",
            "te": "సమీర్‌తో జరిగిన సంభాషణ నవీన్‌ను తన కెరీర్‌ గురించి ఆలోచించేలా చేస్తుంది. అతను ఇరుక్కుపోయానని అనుకుంటాడు మరియు అతను కాంట్రాక్ట్ ఉద్యోగం కొనసాగించాలా లేదా మరేదైనా చేయాలా అని నిర్ణయించుకోవాలి.",
            "or": "ସମୀରଙ୍କ ସହ ବାର୍ତ୍ତାଳାପ ନବୀନଙ୍କୁ ନିଜ କ୍ୟାରିୟର ବିଷୟରେ ଚିନ୍ତା କରାଏ | ସେ ଅନୁଭବ କରିଛନ୍ତି ଯେ ସେ ଅଟକି ଯାଇଛନ୍ତି ଏବଂ ଚୁକ୍ତିଭିତ୍ତିକ କାର୍ଯ୍ୟ ଜାରି ରଖିବାକୁ କିମ୍ବା ଅନ୍ୟ କିଛି କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ନାହିଁ ତାହା ସ୍ଥିର କରିବାକୁ ପଡିବ |",
            "as": "সমীৰৰ সৈতে হোৱা কথা-বতৰাই নবীনক নিজৰ জীৱিকাৰ বিষয়ে ভাবিবলৈ বাধ্য কৰিলে। সি অনুভৱ কৰিলে যে সি আবদ্ধ হৈ আছে আৰু চুক্তিৰ কামটো কৰি থাকিব নে আন কিবা বিচাৰিব সেইটো ভাবিবৰ হ’ল।",
            "gu": "સમીર સાથેની વાતચીતથી નવીનને પોતાની કારકિર્દી વિશે વિચારવા ચાલુ કરે છે. તેને લાગે છે કે તે ભરાઈ ગયો છે અને તેણે એ નક્કી કરવાનું છે કે તે કોન્ટ્રાક્ટની નોકરી ચાલુ રાખવી કે બીજું કંઈક કરવું."
          }
        }
      ],
      "page14": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_1_5",
            "hi": "C9_1_5",
            "ka": "C9_1_5",
            "te": "C9_1_5",
            "or": "C9_1_5",
            "as": "C9_1_5",
            "gu": "C9_1_5"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Meanwhile, he gets to know about 15 new job opening for permanent maintenance technicians at KPCL.",
            "hi": "इसी दौरान, उससे KPCL में परमानेंट मेंटेनेंस टेक्नीशियन के पोस्ट के लिए 15 नए जॉब ओपनिंग के बारे में पता चलता है।",
            "ka": "ಏತನ್ಮಧ್ಯೆ, ಅವರು ಕೆಪಿಸಿಲ್L ನಲ್ಲಿ ಖಾಯಂ ನಿರ್ವಹಣೆ ತಂತ್ರಜ್ಞರಿಗೆ 15 ಹೊಸ ಉದ್ಯೋಗಾವಕಾಶಗಳ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆ.",
            "te": "ఇంతలో, అతను KPCLలో శాశ్వత నిర్వహణ సాంకేతిక నిపుణుల కోసం 15 కొత్త ఉద్యోగాల గురించి తెలుసుకుంటాడు.",
            "or": "ଏହି ସମୟରେ, ସେ KPCL ରେ ସ୍ଥାୟୀ ରକ୍ଷଣାବେକ୍ଷଣ ଟେକ୍ନିସିଆନମାନଙ୍କ ପାଇଁ ପ୍ରାୟ 15 ଟି ନୂତନ ଚାକିରି ଖୋଲିବା ବିଷୟରେ ଜାଣନ୍ତି |",
            "as": "তেনেকুৱাতে, সি KPCLত স্থায়ী তত্বাৱধায়ক টেকনিচিয়ানসকলৰ বাবে 15 টা নতুন চাকৰি ওলোৱা বুলি জানিব পাৰে।",
            "gu": "દરમિયાન, તેને KPCLમાં કાયમી મેન્ટેનેન્સ ટેકનિશિયન માટે નવી 15 નોકરીઓ વિશે જાણવા મળ્યું."
          }
        }
      ],
      "page15": [
        {
          "valuePath": "display",
          "value": {
            "en": "C9_1_6_D",
            "hi": "C9_1_6_D",
            "ka": "C9_1_6_D",
            "te": "C9_1_6_D",
            "or": "C9_1_6_D",
            "as": "C9_1_6_D",
            "gu": "C9_1_6_D"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should he do?",
            "hi": "उसे क्या करना चाहिए?",
            "ka": "ಅವನು ಏನು ಮಾಡಬೇಕು?",
            "te": "అతను ఏమి చేయాలి?",
            "or": "ସେ କ’ଣ କରିବା ଉଚିତ୍ ?",
            "as": "সি কি কৰা উচিত?",
            "gu": "તેને શું કરવું જોઇયે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Apply for a government job, which will offer stability",
            "hi": "सरकारी जॉब के लिए अप्लाई करना चाहिए, जो उसे स्थिरता प्रदान करेगा।",
            "ka": "ಸರ್ಕಾರಿ ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ, ಇದು ಸ್ಥಿರತೆಯನ್ನು ನೀಡುತ್ತದೆ",
            "te": "ప్రభుత్వ ఉద్యోగం కోసం దరఖాస్తు చేసుకోవాలి, ఇది స్థిరత్వం ఇస్తుంది",
            "or": "ଏକ ସରକାରୀ ଚାକିରି ପାଇଁ ଆବେଦନ କରନ୍ତୁ, ଯାହା ସ୍ଥିରତା ପ୍ରଦାନ କରିବ",
            "as": "চৰকাৰী চাকৰি এটাৰ বাবে আবেদন, যি স্থিৰতা আনিব",
            "gu": "સરકારી નોકરીમાં જોડાવું જોઈએ જે સ્થિરતાવાળી નોકરી આપે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Explores the freelance options based on his interest",
            "hi": "अपनी दिलचस्पी के आधार पर फ्रीलांसिंग के विकल्प को अपनाना चाहिए",
            "ka": "ಅವರ ಆಸಕ್ತಿಯ ಆಧಾರದ ಮೇಲೆ ಸ್ವತಂತ್ರ ಆಯ್ಕೆಗಳನ್ನು ಅನ್ವೇಷಿಸುತ್ತದೆ",
            "te": "అతని ఆసక్తి ఆధారంగా ఫ్రీలాన్స్ ఎంపికలు వెతకాలి",
            "or": "ତାଙ୍କ ଆଗ୍ରହକୁ ଆଧାର କରି ଫ୍ରିଲାନ୍ସ ବିକଳ୍ପଗୁଡିକ ଏକ୍ସପ୍ଲୋର୍ କରନ୍ତୁ",
            "as": "তাৰ আগ্ৰহৰ ওপৰত ভিত্তি কৰি ফ্ৰিলেন্স বিকল্পবোৰ বিচাৰিব",
            "gu": "ફ્રીલાન્સ વિકલ્પોની શોધવા જોઈએ જે તેની રુચિના આધારે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.tag",
          "value": {
            "en": "Stability",
            "hi": "स्थिरता",
            "ka": "ಸ್ಥಿರತೆ",
            "te": "స్థిరత్వం",
            "or": "ସ୍ଥିରତା",
            "as": "স্থিৰতা",
            "gu": "સ્થિરતા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.tag",
          "value": {
            "en": "Expermentation",
            "hi": "प्रयोग करना",
            "ka": "ಪ್ರಯೋಗ",
            "te": "ప్రయోగీకరణం",
            "or": "ପରୀକ୍ଷଣ",
            "as": "পৰীক্ষা-নিৰীক্ষা",
            "gu": "પ્રયોગ"
          }
        }
      ]
    },
    "scene22": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C10_1_1_Apply for government job",
            "hi": "सरकारी जॉब के लिए अप्लाई करना",
            "ka": "C10_1_1_ಸರ್ಕಾರಿ ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ",
            "te": "C10_1_1_ప్రభుత్వ ఉద్యోగం కోసం దరఖాస్తు చేసుకోవడం",
            "or": "C10_1_1_Apply for government job",
            "as": "C10_1_1_চৰকাৰী চাকৰিৰ বাবে আবেদন",
            "gu": "C10_1_1_સરકારી નોકરી માટે અરજી કરવી"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Naveen has become very proficient in the process of applying for these jobs. He opens the KPCL portal on his phone and applies for the job.\n Click to apply.",
            "hi": "नवीन इस तरह की जॉब के लिए अप्लाई करने की प्रक्रिया में बहुत प्रवीण हो चुका है। वह अपने फोन से केपीसीएल का होटल ओपन करता है और जॉब के लिए अप्लाई करता है। \n अप्लाई करने के लिए क्लिक करें।",
            "ka": "‘‘ನವೀನ್ ಈ ಉದ್ಯೋಗಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ಬಹಳ ಪರಿಣತಿ ಹೊಂದಿದ್ದಾನೆ, ಅವನು ತನ್ನ ಫೋನ್‌ನಲ್ಲಿ ಕೆಪಿಸಿಎಲ್ ಪೋರ್ಟಲ್ ಅನ್ನು ತೆರೆದು ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಾನೆ.\n  ಅನ್ವಯಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.\"",
            "te": "‘‘ఈ ఉద్యోగాల కోసం దరఖాస్తు చేసుకునే ప్రక్రియలో నవీన్ చాలా ప్రావీణ్యం సంపాదించాడు. తన ఫోన్‌లో కేపీసీఎల్ పోర్టల్‌ని ఓపెన్ చేసి జాబ్ కోసం దరఖాస్తు చేసుకుంటాడు.\n  దరఖాస్తు చేయడానికి క్లిక్ (నొక్కండి). చేయండి.\"\"",
            "or": "ଏହି ଚାକିରୀ ପାଇଁ ଆବେଦନ କରିବା ପ୍ରକ୍ରିୟାରେ ନବୀନ ଅତ୍ୟନ୍ତ ପାରଦର୍ଶୀ ହୋଇପାରିଛନ୍ତି। ସେ ନିଜ ଫୋନରେ KPCL ପୋର୍ଟାଲ ଖୋଲନ୍ତି ଏବଂ ଚାକିରି ପାଇଁ ଆବେଦନ କରନ୍ତି।\n  ଆବେଦନ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ",
            "as": "নবীন এই চাকৰিবোৰৰ বাবে আবেদন কৰা প্ৰক্ৰিয়াতোত অতি দক্ষ হৈ পৰিছে। সি তাৰ ফোনত KPCL পৰ্টেলতো খোলে আৰু কামটোৰ বাবে আবেদন কৰে। আবেদন কৰিবলৈ ক্লিক কৰক।",
            "gu": "નવીન આ પ્રક્રિયામાં ખૂબ જ પ્રવીણ ​બની ગયો છે જેમાં તે નોકરીઓ માટે અરજી કરવાની છે. તે તેના ફોન પર KPCL પોર્ટલ ખોલીને અને નોકરી માટે અરજી કરે છે.\n  અરજી કરવા માટે ક્લિક કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Maintenance Technician - KPCL",
            "hi": "मेंटेनेंस टेक्नीशियन - KPCL",
            "ka": "ನಿರ್ವಹಣೆ ತಂತ್ರಜ್ಞ - ಕೆಪಿಸಿಲ್",
            "te": "నిర్వహణ సాంకేతిక నిపుణుడు - - KPCL",
            "or": "Maintenance Technician - KPCL",
            "as": "তত্বাৱধায়ক টেকনিচিয়ান - KPCL",
            "gu": "મેન્ટેનેન્સ ટેકનિશિયન - KPCL"
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C10_1_2_interaction",
            "hi": "C10_1_2_संवाद",
            "ka": "C10_1_2_ಪರಸ್ಪರ ಕ್ರಿಯೆ",
            "te": "C10_1_2_ఇంటరాక్షన్",
            "or": "C10_1_2_interaction",
            "as": "C10_1_2_আন্তঃক্ৰিয়া",
            "gu": "C10_1_2_પરસ્પર ક્રિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Help Naveen fill the application for the job. Click on the blocks to fill the information.",
            "hi": "नवीन को इस जॉब के लिए एप्लीकेशन भरने में मदद करें। जानकारी भरने के लिए ब्लॉक पर क्लिक करें।",
            "ka": "ಉದ್ಯೋಗಕ್ಕಾಗಿ ಅರ್ಜಿಯನ್ನು ಭರ್ತಿ ಮಾಡಲು ನವೀನ್‌ಗೆ ಸಹಾಯ ಮಾಡಿ. ಮಾಹಿತಿಯನ್ನು ತುಂಬಲು ಬ್ಲಾಕ್‌ಗಳ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "te": "ఉద్యోగం కోసం దరఖాస్తు నింపడానికి నవీన్‌కి సహాయం చేయండి. సమాచారం పూరించడానికి బ్లాక్‌లపై క్లిక్ చేయండి (నొక్కండి).",
            "or": "ଚାକିରି ପାଇଁ ଆବେଦନ ପୂରଣ କରିବାକୁ ନବୀନଙ୍କୁ ସାହାଯ୍ୟ କରନ୍ତୁ | ସୂଚନା ପୂରଣ କରିବାକୁ ବ୍ଲକଗୁଡିକ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ |",
            "as": "নবীনক চাকৰিটোৰ বাবে আবেদনপত্ৰ পূৰণ কৰাত সহায় কৰা। তথ্য পূৰণ কৰিবলৈ ব্লকবোৰত ক্লিক কৰা।",
            "gu": "નોકરીની કરવા માટે નવીનને અરજી ભરવામાં મદદ કરો. માહિતી ભરવા માટે બ્લોક્સ પર ક્લિક કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Name of the applicant",
            "hi": "अप्लाई करने वाले का नाम",
            "ka": "ಅರ್ಜಿದಾರರ ಹೆಸರು",
            "te": "దరఖాస్తుదారుని పేరు",
            "or": "ଆବେଦନକାରୀଙ୍କ ନାମ",
            "as": "আবেদনকাৰীৰ নাম",
            "gu": "અરજદારનું નામ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "Address",
            "hi": "पता",
            "ka": "ವಿಳಾಸ",
            "te": "చిరునామా",
            "or": "ଠିକଣା",
            "as": "ঠিকনা",
            "gu": "સરનામું"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.beforeReveal",
          "value": {
            "en": "Gender",
            "hi": "लिंग",
            "ka": "ಲಿಂಗ",
            "te": "లింగం",
            "or": "ଲିଙ୍ଗ",
            "as": "লিঙ্গ",
            "gu": "જાતિ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.beforeReveal",
          "value": {
            "en": "Educational qualification",
            "hi": "शैक्षणिक योग्यता",
            "ka": "ಶೈಕ್ಷಣಿಕ ಅರ್ಹತೆ",
            "te": "విద్యార్హతలు",
            "or": "ଶିକ୍ଷାଗତ ଯୋଗ୍ୟତା",
            "as": "শিক্ষাগত অৰ্হতা",
            "gu": "શૈક્ષણિક લાયકાત"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.beforeReveal",
          "value": {
            "en": "Position applied for",
            "hi": "किस पोजीशन के लिए अप्लाई किया है",
            "ka": "ಅರ್ಜಿ ಹಾಕಿದ ಹುದ್ದೆ",
            "te": "దరఖాస్తు చేస్తున్న స్థానం",
            "or": "ପଦବୀ ପାଇଁ ଆବେଦନ କରାଯାଇଛି",
            "as": "আবেদন কৰা পদ",
            "gu": "કયા પદ માટે અરજી કરવી"
          }
        },
        {
          "valuePath": "blocks.0.props.options.5.beforeReveal",
          "value": {
            "en": "Eligibility Documents",
            "hi": "पात्रता दस्तावेज",
            "ka": "ಅರ್ಹತಾ ದಾಖಲೆಗಳು",
            "te": "అర్హత పత్రాలు",
            "or": "ଯୋଗ୍ୟତା ଡକ୍ୟୁମେଣ୍ଟ୍ଗୁଡିକ",
            "as": "যোগ্যতাৰ দস্তাবেজসমূহ",
            "gu": "લાયકાત દસ્તાવેજો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "Marathahalli, Bangalore",
            "hi": "मराथाहल्ली, बैंगलोर",
            "ka": "ಮಾರತಹಳ್ಳಿ, ಬೆಂಗಳೂರು",
            "te": "మారతహళ్లి, బెంగళూరు",
            "or": "ମରାଠାହଲ୍ଲି, ବାଙ୍ଗାଲୋର",
            "as": "মাৰাঠাহাল্লি, বাংগালোৰ",
            "gu": "મરાઠાહલ્લી, બેંગ્લોર"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.afterReveal",
          "value": {
            "en": "Male",
            "hi": "पुरुष",
            "ka": "ಪುರುಷ",
            "te": "పురుషుడు",
            "or": "ପୁରୁଷ",
            "as": "পুৰুষ",
            "gu": "પુરુષ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.afterReveal",
          "value": {
            "en": "10th pass, ITI",
            "hi": "10वीं पास, ITI",
            "ka": "10 ನೇ ತೇರ್ಗಡೆ, ಐಟಿಐ",
            "te": "10వ తరగతి పాస్, ITI",
            "or": "10th ପାସ୍, ITI",
            "as": "দশম উত্তীৰ্ণ, ITI",
            "gu": "10 પાસ, ITI"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.afterReveal",
          "value": {
            "en": "Painter,  Jr. mechanic",
            "hi": "पेंटर, ज्युनिअर मैकेनिक",
            "ka": "ಪೇಂಟರ್, ಜೂನಿಯರ್ ಮೆಕ್ಯಾನಿಕ್",
            "te": "పెయింటర్, జూనియర్ మెకానిక్",
            "or": "ପେଣ୍ଟର୍‌, ଜୁନିୟର ମେକାନିକ୍‌",
            "as": "পেইণ্টাৰ, জুনিয়ৰ মেকানিক",
            "gu": "પેઇન્ટર, જુનિયર મિકેનિક"
          }
        },
        {
          "valuePath": "blocks.0.props.options.5.afterReveal",
          "value": {
            "en": "Aadhaar card, ITI certificate, 10th std certificate",
            "hi": "आधार कार्ड, ITI प्रमाण पत्र, 10वीं कक्षा का प्रमाण पत्र",
            "ka": "ಆಧಾರ್ ಕಾರ್ಡ್, ಐಟಿಐ ಪ್ರಮಾಣಪತ್ರ, 10 ನೇ ತರಗತಿ ಪ್ರಮಾಣಪತ್ರ",
            "te": "ఆధార్ కార్డు, ITI సర్టిఫికెట్, 10వ తరగతి సర్టిఫికెట్",
            "or": "ଆଧାର କାର୍ଡ, ITI ସାର୍ଟିଫିକେଟ୍, 10th ଶ୍ରେଣୀ ସାର୍ଟିଫିକେଟ୍",
            "as": "আধাৰ কাৰ্ড, ITI প্ৰমাণপত্ৰ, দশম শ্ৰেণীৰ প্ৰমাণপত্ৰ",
            "gu": "આધાર કાર્ડ, ITI પ્રમાણપત્ર, 10માંનું પ્રમાણપત્ર"
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "C10_1_3",
            "hi": "C10_1_3",
            "ka": "C10_1_3",
            "te": "C10_1_3",
            "or": "C10_1_3",
            "as": "C10_1_3",
            "gu": "C10_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He feels confident that he will be able to clear the test required to get the job. He prepares for it diligently and can answer all the questions without any hesitation.",
            "hi": "उसे विश्वास है कि इस जॉब के लिए आवश्यक टेस्ट को वह पास कर सकेगा। वह बहुत कड़ी मेहनत से इसके लिए तैयारी करता है और किसी भी प्रश्न का उत्तर बिना झिझक दे सकता है।",
            "ka": "ಕೆಲಸ ಪಡೆಯಲು ಅಗತ್ಯವಿರುವ ಪರೀಕ್ಷೆಯಲ್ಲಿ ಉತ್ತೀರ್ಣರಾಗಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ ಎಂದು ಅವನು ವಿಶ್ವಾಸ ಹೊಂದಿದ್ದಾನೆ. ಅವನು ಅದಕ್ಕೆ ಶ್ರದ್ಧೆಯಿಂದ ತಯಾರಿ ನಡೆಸುತ್ತಾನೆ ಮತ್ತು ಯಾವುದೇ ಹಿಂಜರಿಕೆಯಿಲ್ಲದೆ ಎಲ್ಲಾ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸುತ್ತಾನೆ.",
            "te": "అతను ఉద్యోగం పొందడానికి అవసరమైన పరీక్షను ఉత్తీర్ణత సాధించగలనని అతను నమ్మకంగా ఉన్నాడు. అతను దాని కోసం శ్రద్ధగా సిద్ధం అవుతున్నాడు మరియు ఎటువంటి సందేహం లేకుండా అన్ని ప్రశ్నలకు సమాధానం ఇవ్వగలడు.",
            "or": "ଚାକିରି ପାଇବା ପାଇଁ ଆବଶ୍ୟକ ପରୀକ୍ଷାକୁ ସେ ପାସ୍‌ କରିବାକୁ ସକ୍ଷମ ହେବେ ବୋଲି ସେ ଆତ୍ମବିଶ୍ୱାସ ଅନୁଭବ କରନ୍ତି | ସେ ଏହାକୁ ଯତ୍ନର ସହ ପ୍ରସ୍ତୁତ କରନ୍ତି ଏବଂ ବିନା ଦ୍ୱିଧାରେ ସମସ୍ତ ପ୍ରଶ୍ନର ଉତ୍ତର ଦେଇପାରନ୍ତି |",
            "as": "সি আত্মবিশ্বাসী যে চাকৰিটো পাবলৈ প্ৰয়োজনীয় পৰীক্ষাটো সি পাছ কৰিব পাৰিব। সি ইয়াৰ বাবে নিষ্ঠাৰে প্ৰস্তুতি চলায় আৰু কোনো দ্বিধা নোহোৱাকৈ সকলো প্ৰশ্নৰ উত্তৰ দিব পাৰিব।",
            "gu": "તે પરીક્ષા પાસ કરીને નોકરી મળી જશે તેઓ તેને વિશ્વાસ છે. તે તેના માટે ખંતપૂર્વક તૈયારી કરે છે અને કોઈપણ અડચણ વિના તમામ પ્રશ્નોના જવાબ આપી શકે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C10_1_4",
            "hi": "C10_1_4",
            "ka": "C10_1_4",
            "te": "C10_1_4",
            "or": "C10_1_4",
            "as": "C10_1_4",
            "gu": "C10_1_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Within a few weeks, the results are out. Naveen logs into the portal again and screams with happiness when he sees the result. He has finally got the job!",
            "hi": "कुछ हफ्तों बाद रिजल्ट लगता है। नवीन फिर से पोर्टल में लॉगिन करता है और रिजल्ट देखने के बाद खुशी से चिल्लाता है। आखिरकार उससे जॉब मिल ही गया!",
            "ka": "ಕೆಲವೇ ವಾರಗಳಲ್ಲಿ, ಫಲಿತಾಂಶಗಳು ಹೊರಬರುತ್ತವೆ. ನವೀನ್ ಮತ್ತೆ ಪೋರ್ಟಲ್‌ಗೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ ಫಲಿತಾಂಶ ನೋಡಿ ಖುಷಿಯಿಂದ ಕಿರುಚುತ್ತಾನೆ. ಕೊನೆಗೂ ಅವನಿಗೆ ಕೆಲಸ ಸಿಕ್ಕಿದೆ!",
            "te": "కొన్ని వారాల్లోనే ఫలితాలు వెలువడనున్నాయి. నవీన్ మళ్లీ పోర్టల్‌లోకి లాగిన్ అయ్యాడు మరియు ఫలితం చూడగానే ఆనందంతో కేకలు వేస్తాడు. అతనికి చివరిగా ఉద్యోగం వచ్చింది!",
            "or": "କିଛି ସପ୍ତାହ ମଧ୍ୟରେ, ଫଳାଫଳଗୁଡିକ ବାହାରି ଯାଏ | ନବୀନ ପୁନର୍ବାର ପୋର୍ଟାଲରେ ଲଗ୍ କରନ୍ତି ଏବଂ ଫଳାଫଳ ଦେଖିବା ପରେ ଖୁସିରେ ଚିତ୍କାର କରନ୍ତି | ଶେଷରେ ସେ ଚାକିରି ପାଇଛନ୍ତି!",
            "as": "কেইসপ্তাহমানৰ ভিতৰত, ফলাফলবোৰ ওলাল। নবীনে পুনৰ পৰ্টেলত লগ ইন কৰে আৰু ফলাফল দেখি চিঞৰি উঠে। সি অৱশেষত চাকৰিটো পালে!",
            "gu": "એક અઠવાડિયામાં પરિણામ આવે છે. નવીન ફરીથી તે પોર્ટમમાં લોગ કરે છે અને ખુશીથી ચીસો પાડે છે જ્યારે તે પરિણામ જોઈને. છેલ્લે તેને નોકરી મળી જાય છે!"
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "C10_2_1",
            "hi": "C10_2_1",
            "ka": "C10_2_1",
            "te": "C10_2_1",
            "or": "C10_2_1",
            "as": "C10_2_1",
            "gu": "C10_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He joins the government service as a technician at KPCL.",
            "hi": "KPCL में टेक्नीशियन के तौर पर सरकारी नौकरी करने लगता है।",
            "ka": "ಕೆಪಿಸಿಎಲ್ ನಲ್ಲಿ ತಂತ್ರಜ್ಞರಾಗಿ ಸರ್ಕಾರಿ ಸೇವೆಗೆ ಸೇರುತ್ತಾರೆ.",
            "te": "KPCLలో టెక్నీషియన్‌గా ప్రభుత్వ ఉద్యోగంలో చేరాడు.",
            "or": "ସେ KPCL ଠାରେ ଟେକ୍ନିସିଆନ ଭାବରେ ସରକାରୀ ସେବାରେ ଯୋଗ ଦିଅନ୍ତି |",
            "as": "তেওঁ KPCLত টেকনিচিয়ান হিচাপে চৰকাৰী সেৱাত যোগদান কৰে।",
            "gu": "ટેકનિશિયન તરીકે તે KPCLમાં સરકારી નોકરીમાં જોડાય છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "C10_2_2",
            "hi": "C10_2_2",
            "ka": "C10_2_2",
            "te": "C10_2_2",
            "or": "C10_2_2",
            "as": "C10_2_2",
            "gu": "C10_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He undergoes one-year probation and then gets a permanent role with all benefits upon satisfactory completion of the probation period. His family is proud of him.",
            "hi": "उसे एक साल प्रोबेशन के तौर पर काम करना पड़ता है और प्रोबेशन की अवधि को अच्छी तरह से पूरा कर लेने के बाद, उसे सभी लाभों के साथ परमानेंट रोल दिया जाता है। उसके परिवार को उस पर गर्व है।",
            "ka": "ಅವನು ಒಂದು ವರ್ಷದ ಪರೀಕ್ಷೆಗೆ ಒಳಗಾಗುತ್ತಾನೆ ಮತ್ತು ಪರೀಕ್ಷಾ ಅವಧಿಯನ್ನು ತೃಪ್ತಿಕರವಾಗಿ ಪೂರ್ಣಗೊಳಿಸಿದ ನಂತರ ಎಲ್ಲಾ ಪ್ರಯೋಜನಗಳೊಂದಿಗೆ ಶಾಶ್ವತ ಪಾತ್ರವನ್ನು ಪಡೆಯುತ್ತಾನೆ. ಅವನ ಬಗ್ಗೆ ಅವನ ಕುಟುಂಬಕ್ಕೆ ಹೆಮ್ಮೆ ಇದೆ.",
            "te": "అతను ఒక-సంవత్సరం పరిశీలనకు లోనవుతాడు మరియు ప్రొబేషన్ వ్యవధిని సంతృప్తికరంగా పూర్తి చేసిన తర్వాత అన్ని ప్రయోజనాలతో శాశ్వత పాత్రను పొందుతాడు. అతని గురించి అతని కుటుంబం గర్వపడుతుంది.",
            "or": "ସେ ଏକ ବର୍ଷର ପ୍ରୋବେସନ ରେ ଯାଆନ୍ତି ଏବଂ ତାପରେ ପ୍ରୋବେସନ ଅବଧି ସନ୍ତୋଷଜନକ ସମାପ୍ତ ହେବା ପରେ ସମସ୍ତ ସୁବିଧା ସହିତ ଏକ ସ୍ଥାୟୀ ଭୂମିକା ଗ୍ରହଣ କରନ୍ତି | ତାଙ୍କ ପରିବାର ତାଙ୍କ ପାଇଁ ଗର୍ବିତ |",
            "as": "সি এবছৰীয়া প্ৰবেচন সম্পূৰ্ণ কৰে আৰু প্ৰবেচন ম্যাদ ভালদৰে সম্পূৰ্ণ হোৱাৰ পিছত সকলো সুবিধাৰ সৈতে এক স্থায়ী পদ লাভ কৰে। তাৰ পৰিয়াল তাক লৈ গৌৰৱান্বিত।",
            "gu": "તે એક વર્ષના પ્રોબેશનમાં સમય પ્રસાર કરે છે અને તે સમયગાળો સંતોષકારક પૂર્ણ થયા પછી તમામ લાભો સાથે કાયમી ભૂમિકા મેળવે છે. પરિવારને ગર્વ છે તેના પર."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "C10_2_3",
            "hi": "C10_2_3",
            "ka": "C10_2_3",
            "te": "C10_2_3",
            "or": "C10_2_3",
            "as": "C10_2_3",
            "gu": "C10_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen feels that they will build a better life with a steady income, and their social and financial status will improve.",
            "hi": "नवीन को लगता है कि, एक स्थिर इन्कम से उनकी जिंदगी बेहतर हो सकती है और उनकी सामाजिक और वित्तीय स्थिति अच्छी हो जाएगी।",
            "ka": "ಸ್ಥಿರ ಆದಾಯದೊಂದಿಗೆ ಉತ್ತಮ ಜೀವನ ಕಟ್ಟಿಕೊಳ್ಳುತೆನೆ, ಸಾಮಾಜಿಕ ಮತ್ತು ಆರ್ಥಿಕ ಸ್ಥಿತಿ ಸುಧಾರಿಸುತ್ತದೆ ಎಂದು ನವೀನ್ ಅಭಿಪ್ರಾಯಪಟ್ಟಿದ್ದಾನೆ.",
            "te": "స్థిరమైన ఆదాయంతో మంచి జీవితాన్ని నిర్మించుకుంటామని, తమ సామాజిక, ఆర్థిక స్థితిగతులు మెరుగుపడతాయని నవీన్ భావిస్తున్నాడు.",
            "or": "ନବୀନ ଅନୁଭବ କରନ୍ତି ଯେ ସେମାନେ ଏକ ସ୍ଥିର ଆୟ ସହିତ ଏକ ଉତ୍ତମ ଜୀବନ ଗଠନ କରିବେ ଏବଂ ସେମାନଙ୍କର ସାମାଜିକ ଏବଂ ଆର୍ଥିକ ସ୍ଥିତିରେ ଉନ୍ନତି ଆସିବ |",
            "as": "নবীনে অনুভৱ কৰে যে তেওঁলোকে স্থিৰ উপাৰ্জনৰ সৈতে এক উন্নত জীৱন গঢ়ি তুলিব, আৰু তেওঁলোকৰ সামাজিক আৰু বিত্তীয় স্থিতি উন্নত হ'ব।",
            "gu": "નવીનને એવું લાગે છે કે તેનું જીવન સ્થિર આવકથી સારું થઈ જશે અને સામાજિક અને આર્થિક આવક સુધરશે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Government Employee",
            "hi": "सरकारी कर्मचारी",
            "ka": "**THREAD_END**_ಸರಕಾರೀ ನೌಕರ",
            "te": "**THREAD_END**_ప్రభుత్వ ఉద్యోగి",
            "or": "**THREAD_END**_Government Employee",
            "as": "**থ্ৰেড_সমাপ্ত**_চৰকাৰী কৰ্মচাৰী",
            "gu": "**THREAD_END**_સરકારી કર્મચારી"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Naveen that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने नवीन के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँचा है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकता है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n  ನೀವು ನವೀನ್‌ಗಾಗಿ ಮಾಡಿರುವ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు నవీన్ కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ !\n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି !\n \n ଆପଣ ନବୀନଙ୍କ ପାଇଁ ବାଛିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତି ଘଟାଇଛି | ଆପଣ କରୁଥିବା ପସନ୍ଦ ଉପରେ ଆଧାର କରି ଭିନ୍ନ ଭିନ୍ନ ସମାପ୍ତି ଅଛି | ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକୁ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ?",
            "as": "অভিনন্দন! তুমি এটা যাত্ৰা সমাপ্ত কৰিছা ৷ তুমি নবীনৰ বাবে কৰা বাছনিবোৰৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত ভিত্তি কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ চাব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક મુસાફરી પૂરી કરી છે!\n\nતમે નવીન માટે પસંદગીઓ કરી છે જે આ સંભવિત અંત તરફ દોરી ગઈ છે. તમે જે પસંદગી કરી છે તેનાથી અલગ અલગ અંત છે. શું તમે બીજા દ્રશ્યો ખોલવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମ୍ଯାପ୍‍ ଉପରେ ଏକ ନଜର ପକାନ୍ତୁ |",
            "as": "মানচিত্ৰখনৰ পিনে চোৱা",
            "gu": "ચાલો નકશા પર એક નજર નાખીએ"
          }
        }
      ]
    },
    "scene23": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_1_1_Explore freelance option",
            "hi": "फ्रीलांसिंग के विकल्प को एक्सप्लोर करना",
            "ka": "C11_1_1_ಸ್ವತಂತ್ರ ಆಯ್ಕೆಯನ್ನು ಅನ್ವೇಷಿಸಿ",
            "te": "C11_1_1_ఫ్రీలాన్స్ ఆప్షన్ అన్వేషించండి",
            "or": "C11_1_1_Explore freelance option",
            "as": "C11_1_1_ফ্ৰিলেন্স বিকল্পৰ অন্বেষণ",
            "gu": "C11_1_1_ફ્રીલાન્સ વિકલ્પની શોધ"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen feels that the contract job offers him a steady income but not the stability he was looking for in the government jobs.",
            "hi": "नवीन को लगता है कि, कॉन्ट्रैक्ट जॉब उसे फिर इनकम तो देता है, लेकिन सरकारी जॉब में जिस तरह की स्थिरता मिलती है वैसी स्थिरता इस जॉब में नहीं है।",
            "ka": "ಗುತ್ತಿಗೆ ಕೆಲಸವು ತನಗೆ ಸ್ಥಿರವಾದ ಆದಾಯವನ್ನು ನೀಡುತ್ತದೆ ಎಂದು ನವೀನ್ ಭಾವಿಸುತ್ತಾನೆ ಆದರೆ ಸರ್ಕಾರಿ ಉದ್ಯೋಗಗಳಲ್ಲಿ ತಾನು ಹುಡುಕುತ್ತಿದ್ದ ಸ್ಥಿರತೆ ಅಲ್ಲ.",
            "te": "కాంట్రాక్ట్ ఉద్యోగం తనకు స్థిరమైన ఆదాయాన్ని ఇస్తుంది కానీ ప్రభుత్వ ఉద్యోగాల్లో తాను వెతుకుతున్న స్థిరత్వం ఇవ్వదు అని నవీన్ భావిస్తున్నాడు.",
            "or": "ନବୀନ ଅନୁଭବ କରନ୍ତି ଯେ ଚୁକ୍ତିଭିତ୍ତିକ ଚାକିରି ତାଙ୍କୁ ଏକ ସ୍ଥାୟୀ ଆୟ ପ୍ରଦାନ କରେ କିନ୍ତୁ ଏହା ସ୍ଥିରତା ନୁହେଁ ଯାହା ସେ ସରକାରୀ ଚାକିରିରେ ଥାଏ |",
            "as": "নবীনে অনুভৱ কৰে যে চুক্তিৰ চাকৰিটোৱে তাক ভাল উপাৰ্জন দিছে কিন্তু সি বিচাৰি থকা চৰকাৰী চাকৰিৰ দৰে স্থিৰতা ইয়াত নাই।",
            "gu": "નવીનને લાગે છે કે જે નોકરી કોન્ટ્રાક્ટની તેને સ્થિર આવક આપે છે પરંતુ એવું સ્થિરતા નથી જે તે સરકારી નોકરીઓમાં શોધી રહ્યો હતો."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_1_2",
            "hi": "C11_1_2",
            "ka": "C11_1_2",
            "te": "C11_1_2",
            "or": "C11_1_2",
            "as": "C11_1_2",
            "gu": "C11_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He is good at the job and does well, but he wonders if this is what he wants to do for the rest of his life.",
            "hi": "वह अपने काम में अच्छा है और अच्छे पैसे कमा रहा है, लेकिन वह सोचता है कि, क्या वह जिंदगी भर यही काम करना चाहता है।",
            "ka": "ಅವನು ಕೆಲಸದಲ್ಲಿ ಚೆನ್ನಾಗಿರುತ್ತಾನೆ ಮತ್ತು ಚೆನ್ನಾಗಿ ಮಾಡುತ್ತಾನೆ, ಆದರೆ ಅವನು ತನ್ನ ಜೀವನದುದ್ದಕ್ಕೂ ಇದನ್ನು ಮಾಡಲು ಬಯಸುತ್ತಾನೆಯೇ ಎಂದು ಅವನು ಆಶ್ಚರ್ಯ ಪಡುತ್ತಾನೆ.",
            "te": "అతను ఉద్యోగం బాగా చేస్తాడు కానీ, జీవితాంతం ఇలాగే చేయాలా అని అనుకుంటాడు.",
            "or": "ସେ ଚାକିରିରେ ଭଲ ଏବଂ ଭଲ କାମ କରନ୍ତି, କିନ୍ତୁ ସେ ଭାବୁଛନ୍ତି ଯେ ସେ ସାରା ଜୀବନ ଏହା କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
            "as": "সি তাৰ কামটোত ভাল কিন্তু সি ভাবি আচৰিত হয় যে গোটেই জীৱনতো সি এনেকৈয়ে থাকিব নেকি।",
            "gu": "તએ કામ બાબતે સારો છે અને સારું કરે છે, તે વિચારે છે કે તેને આ કામ આગળના જીવન માટે કરવા માગે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_1_3",
            "hi": "C11_1_3",
            "ka": "C11_1_3",
            "te": "C11_1_3",
            "or": "C11_1_3",
            "as": "C11_1_3",
            "gu": "C11_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He wants to take a chance at finding a job where he can balance both his interests and earn good money.",
            "hi": "वह ऐसा जॉब ढूंढना चाहता है, जहाँ वह अपनी दिलचस्पी पूरी करने के साथ-साथ अच्छे पैसे भी कमा सकें।",
            "ka": "ಅವನು ತನ್ನ ಆಸಕ್ತಿಗಳನ್ನು ಸಮತೋಲನಗೊಳಿಸಬಹುದಾದ ಮತ್ತು ಉತ್ತಮ ಹಣವನ್ನು ಗಳಿಸುವ ಕೆಲಸವನ್ನು ಹುಡುಕುವ ಅವಕಾಶವನ್ನು ಪಡೆಯಲು ಬಯಸುತ್ತಾನೆ.",
            "te": "అతను తన ఆసక్తులను బ్యాలెన్స్ చేస్తూ మంచి డబ్బు సంపాదించగల ఉద్యోగాన్ని కనుగొనే అవకాశాన్ని పొందాలనుకుంటున్నాడు.",
            "or": "ସେ ଚାକିରି ଖୋଜିବାରେ ଏକ ସୁଯୋଗ ନେବାକୁ ଚାହାଁନ୍ତି ଯେଉଁଠାରେ ସେ ତାଙ୍କର ଆଗ୍ରହକୁ ସନ୍ତୁଳିତ କରି ଭଲ ଅର୍ଥ ଉପାର୍ଜନ କରିପାରିବେ |",
            "as": "সি এনে এটা চাকৰি বিচাৰে য'ত সি নিজৰ দুয়োটা আগ্ৰহৰ কাম একেলগে চলাই যাব পাৰে আৰু ভাল টকা উপাৰ্জন কৰিব পাৰে।",
            "gu": "તે નોકરી શોધવાની તક લેવા માંગે છે જ્યાં તે તેની રુચિઓ બંનેને સંતુલિત કરી શકે અને સારા પૈસા કમાઈ શકે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_2_1",
            "hi": "C11_2_1",
            "ka": "C11_2_1",
            "te": "C11_2_1",
            "or": "C11_2_1",
            "as": "C11_2_1",
            "gu": "C11_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Following Sameer’s advice, he searches on Freelance-desk, an online platform that connects freelancers to customers, and finds exciting work opportunities.\nClick on search.",
            "hi": "समीर की सलाह को मानते हुए, वह फ्रीलांस-डेस्क पर काम ढूंढना शुरू करता है, जो एक ऑनलाइन प्लेटफॉर्म है। यहाँ फ्रीलांसर ग्राहकों को संपर्क कर सकते हैं और काम करने के लिए दिल जब दिलचस्प अवसर ढूंढ सकते हैं।\n सर्च करने के लिए क्लिक करें।",
            "ka": "\"ಸಮೀರ್ ಸಲಹೆಯನ್ನು ಅನುಸರಿಸಿ, ಅವನು ಫ್ರೀಲ್ಯಾನ್ಸ್-ಡೆಸ್ಕ್ ಅನ್ನು ಹುಡುಕುತ್ತಾನೆ, ಇದು ಸ್ವತಂತ್ರೋದ್ಯೋಗಿಗಳನ್ನು ಗ್ರಾಹಕರಿಗೆ ಸಂಪರ್ಕಿಸುವ ಆನ್‌ಲೈನ್ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಮತ್ತು ಅತ್ಯಾಕರ್ಷಕ ಕೆಲಸದ ಅವಕಾಶಗಳನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತದೆ.\n ಹುಡುಕಾಟದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ.\"",
            "te": "సమీర్ సలహాను అనుసరించి, అతను ఫ్రీలాన్సర్‌లను కస్టమర్‌లకు కనెక్ట్ చేసే ఆన్‌లైన్ ప్లాట్‌ఫారమ్ అయిన ఫ్రీలాన్స్-డెస్క్‌లో శోధించాడు మరియు ఉత్తేజకరమైన పని అవకాశాలను కనుగొంటాడు.\n శోధనపై క్లిక్ చేయండి.",
            "or": "ସମୀରଙ୍କ ଉପଦେଶ ଅନୁସରଣ କରି ସେ ଫ୍ରିଲାନ୍ସ-ଡେସ୍କରେ ଖୋଜନ୍ତି, ଏକ ଅନଲାଇନ୍ ପ୍ଲାଟଫର୍ମ ଯାହା ଫ୍ରିଲାନ୍ସର୍ ମାନଙ୍କୁ ଗ୍ରାହକଙ୍କ ସହ ଯୋଡିଥାଏ ଏବଂ ଉତ୍ସାହଜନକ କାର୍ଯ୍ୟ ସୁଯୋଗ ପାଇଥାଏ |\n ସର୍ଚରେ କ୍ଲିକ୍ କରନ୍ତୁ।",
            "as": "সমীৰৰ পৰামৰ্শ মতে, সি ফ্ৰিলেন্স-ডেস্কত অনুসন্ধান কৰে, ই এক অনলাইন প্লেটফৰ্ম যি ফ্ৰিলাঞ্চাৰসকলক গ্ৰাহকৰ সৈতে সংযোগ কৰে, আৰু আকৰ্ষণীয় কামৰ সুযোগ বিচাৰে। সন্ধান কৰিবলৈ ক্লিক কৰা।",
            "gu": "સમીરની સલાહથી, તે ફ્રીલાન્સ-ડેસ્ક પર શોધ કરે છે, એક ઓનલાઈન પ્લેટફોર્મ જે ફ્રીલાન્સર્સને ગ્રાહકો સાથે જોડે છે અને આકર્ષક કામની તકો શોધે છે.\nશોધ પર ક્લિક કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.strings.0.string",
          "value": {
            "en": "Mechanics and machines",
            "hi": "मैकेनिक और मशीन्स",
            "ka": "ಯಂತ್ರಶಾಸ್ತ್ರ ಮತ್ತು ಯಂತ್ರಗಳು",
            "te": "మెకానిక్స్ మరియు యంత్రాలు",
            "or": "ମେକାନିକ୍ସ ଏବଂ ମେସିନ୍",
            "as": "মেকানিকচ্ আৰু যন্ত্ৰসমূহ",
            "gu": "મિકેનિક્સ અને મશીનો"
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_2_2",
            "hi": "C11_2_2",
            "ka": "C11_2_2",
            "te": "C11_2_2",
            "or": "C11_2_2",
            "as": "C11_2_2",
            "gu": "C11_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Naveen comes across the profiles of some freelancers. \nClick to read what they do.",
            "hi": "नवीन कुछ फ्रीलांसर्स के प्रोफाइल दिखता है। \n वे क्या करते हैं जानने के लिए क्लिक करें।",
            "ka": "\"ನವೀನ್ ಕೆಲವು ಸ್ವತಂತ್ರೋದ್ಯೋಗಿಗಳ ಪ್ರೊಫೈಲ್‌ಗಳನ್ನು ನೋಡುತ್ತಾನೆ.\n ಅವರು ಏನು ಮಾಡುತ್ತಾರೆ ಎಂಬುದನ್ನು ಓದಲು ಕ್ಲಿಕ್ ಮಾಡಿ.\"",
            "te": "కొందరు ఫ్రీలాన్సర్ల ప్రొఫైల్‌లను నవీన్ చూసాడు. వారు ఏమి చేస్తారో చదవడానికి క్లిక్ చేయండి (నొక్కండి).",
            "or": "ନବୀନ କିଛି ଫ୍ରିଲାନ୍ସର୍ ର ପ୍ରୋଫାଇଲ୍ କୁ ଦେଖନ୍ତି |\n ସେମାନେ କଣ କରନ୍ତି ପଢିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
            "as": "নবীনে কিছুমান ফ্ৰীলেন্সাৰৰ প্ৰ'ফাইল দেখা পায়। তেওঁলোকে কি কৰে পঢ়িবলৈ ক্লিক কৰা।",
            "gu": "“નવીન કેટલાક ફ્રીલાન્સર્સની પ્રોફાઇલમાં આવે છે.\nક્લિક કરીને વાંચો તેઓ શું કરે છે.\""
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Shalini Y",
            "hi": "शालिनी वाई",
            "ka": "ಶಾಲಿನಿ ವೈ",
            "te": "షాలిని Y",
            "or": "ଶାଲିନୀ ୱାଇ",
            "as": "শালিনী Y",
            "gu": "શાલિની વાય"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "Jasjyot A",
            "hi": "जसज्योत ए",
            "ka": "ಜಸ್ಜ್ಯೋತ್ ಎ",
            "te": "జస్ జ్యోత్ ఎ",
            "or": "ଜସ୍‌ଜ୍ଯୋତ୍‌ ଏ",
            "as": "জচজ্যোত A",
            "gu": "જસજ્યોત એ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.beforeReveal",
          "value": {
            "en": "Rashmi S",
            "hi": "रश्मी एस",
            "ka": "ರಶ್ಮಿ ಎಸ್",
            "te": "రష్మి S",
            "or": "ରଶ୍ମୀ ଏସ୍",
            "as": "ৰশ্মি S",
            "gu": "રશ્મિ એસ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "I make Mechanical toys out of scrap",
            "hi": "मैं स्क्रैप से मैकेनिकल टॉयज बनाती हूँI",
            "ka": "ನಾನು ಸ್ಕ್ರ್ಯಾಪ್‌ನಿಂದ ಯಾಂತ್ರಿಕ ಆಟಿಕೆಗಳನ್ನು ತಯಾರಿಸುತ್ತೇನೆ",
            "te": "నేను స్క్రాప్‌ నుండి మెకానికల్ బొమ్మలను తయారు చేస్తాను",
            "or": "ମୁଁ ସ୍କ୍ରାପରୁ ମେକାନିକାଲ୍ ଖେଳନା ତିଆରି କରେ |",
            "as": "মই স্ক্ৰেপৰ পৰা যান্ত্ৰিক পুতলা তৈয়াৰ কৰোঁ",
            "gu": "હું ભંગારમાંથી મેકેનિક રમકડાં બનાવું છું"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "I make miniature machines for smaller jobs",
            "hi": "मैं छोटे जॉब के लिए मिनिएचर मशीन बनाता हूँ",
            "ka": "ನಾನು ಚಿಕ್ಕ ಕೆಲಸಗಳಿಗಾಗಿ ಚಿಕಣಿ ಯಂತ್ರಗಳನ್ನು ತಯಾರಿಸುತ್ತೇನೆ",
            "te": "నేను చిన్న పనుల కోసం సూక్ష్మ యంత్రాలను తయారు చేస్తాను",
            "or": "ଛୋଟ କାର୍ଯ୍ୟ ପାଇଁ ମୁଁ କ୍ଷୁଦ୍ର ଯନ୍ତ୍ର ତିଆରି କରେ |",
            "as": "মই সৰু সৰু কামৰ বাবে ক্ষুদ্ৰ যন্ত্ৰ তৈয়াৰ কৰোঁ",
            "gu": "હું લઘુચિત્ર મશીનોમાઠી નાના સાધનો બનાવું છું"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.afterReveal",
          "value": {
            "en": "I build sets for architects",
            "hi": "मैं आर्किटेक्ट्स के लिए सेट बनाती हूँ।",
            "ka": "ನಾನು ವಾಸ್ತುಶಿಲ್ಪಿಗಳಿಗಾಗಿ ಸೆಟ್ಗಳನ್ನು ನಿರ್ಮಿಸುತ್ತೇನೆ",
            "te": "నేను ఆర్కిటెక్ట్‌ల కోసం సెట్‌లను నిర్మిస్తాను",
            "or": "ମୁଁ ଆର୍କିଟେକ୍ଟ୍‌ମାନଙ୍କ ପାଇଁ ସେଟ୍ ନିର୍ମାଣ କରେ |",
            "as": "মই স্থপতিবিদসকলৰ বাবে ছেট নিৰ্মাণ কৰোঁ",
            "gu": "હું સેટ બનાવું છું આર્કિટેક્ટ્સ માટે"
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_2_3",
            "hi": "C11_2_3",
            "ka": "C11_2_3",
            "te": "C11_2_3",
            "or": "C11_2_3",
            "as": "C11_2_3",
            "gu": "C11_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "He is surprised to see a few people doing different things like making mechanical toys, miniature machines etc. He is reminded of his workshop time during ITI tinkering with scrap.",
            "hi": "वह यह देखकर हैरान होता है कि कुछ लोग अलग चीजें कर रहे हैं, जैसे कि, मैकेनिकल खिलौने बनाना, मिनिएचर मशीन बनाना आदि। उसे ITI के वर्कशॉप में बिताया हुआ समय याद आता है, जब वह स्क्रैप से कुछ चीजें बनाया करता था।",
            "ka": "ಮೆಕ್ಯಾನಿಕಲ್ ಆಟಿಕೆಗಳು, ಮಿನಿಯೇಚರ್ ಯಂತ್ರಗಳು ಇತ್ಯಾದಿಗಳನ್ನು ಮಾಡುವ ಕೆಲವು ಜನರನ್ನು ನೋಡಿ ಅವನು ಆಶ್ಚರ್ಯ ಪಡುತ್ತಾನೆ. ಐಟಿಐನಲ್ಲಿ ಸ್ಕ್ರ್ಯಾಪ್ನೊಂದಿಗೆ ಟಿಂಕರಿಂಗ್ ಮಾಡುವಾಗ ಅವನು ತಮ್ಮ ಕಾರ್ಯಾಗಾರದ ಸಮಯವನ್ನು ನೆನಪಿಸಿಕೊಳ್ಳುತ್ತಾನೆ.",
            "te": "మెకానికల్ బొమ్మలు, మినియేచర్ మెషీన్లు వంటి డిఫరెంట్ పనులు చేస్తున్న కొంతమందిని చూసి అతను ఆశ్చర్యపోయాడు. ITI స్క్రాప్‌తో టింకరింగ్ చేస్తున్నప్పుడు అతను తన పనిషాప్ సమయాన్ని గుర్తు చేసుకున్నాడు.",
            "or": "ସେ କିଛି ଲୋକଙ୍କୁ ଦେଖି ଆଶ୍ଚର୍ଯ୍ୟ ହୋଇଯାଆନ୍ତି ଯେପରିକି ଯାନ୍ତ୍ରିକ ଖେଳନା, କ୍ଷୁଦ୍ର ଯନ୍ତ୍ର ଇତ୍ୟାଦି ତିଆରି କରିବା |ସ୍କ୍ରାପ୍ ସହିତ ITI ମରାମତି ସମୟରେ ତାଙ୍କୁ ତାଙ୍କ ୱାର୍କସପ୍‌ ସମୟ ବିଷୟରେ ମନେ ପକାଯାଏ |",
            "as": "সি আচৰিত হ’ল যে কিছুমান মানুহে যান্ত্ৰিক পুতলা, ক্ষুদ্ৰ যন্ত্ৰ আদি তৈয়াৰ কৰাৰ দৰে বিভিন্ন কাম কৰি আছে। তাৰ ITIৰ কৰ্মশালাত থাকোঁতে স্ক্ৰেপৰ সৈতে খেলা কথাবোৰ মনত পৰি যায়।",
            "gu": "ગણી વખત આશ્ચર્યચકિત થાય છે કેમ કે લોકો મેકેનિકલ રમકડાં, અલ્પાકૃતિ મશીનો વગેરે બનાવવા જેવી વિવિધ વસ્તુઓ કરતા જોઈને તે. તેને ITIમાં ભંગાર સાથે કામગીરી દરમિયાનનો તેમનો વર્કશોપ સમય યાદ આવે છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_2_4",
            "hi": "C11_2_4",
            "ka": "C11_2_4",
            "te": "C11_2_4",
            "or": "C11_2_4",
            "as": "C11_2_4",
            "gu": "C11_2_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He decides to call Samir to meet him and discuss his options.",
            "hi": "वह समीर से मिलकर उसके साथ इस विकल्प के बारे में चर्चा करने का निर्णय लेता है।",
            "ka": "ಸಮೀರ್ ಅವರನ್ನು ಭೇಟಿ ಮಾಡಲು ಮತ್ತು ಅವರ ಆಯ್ಕೆಗಳನ್ನು ಚರ್ಚಿಸಲು ಅವರು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
            "te": "అతను సమీర్‌ని కలవాలని మరియు అతని ఎంపికలను చర్చించాలని నిర్ణయించుకున్నాడు.",
            "or": "ତାଙ୍କୁ ସାକ୍ଷାତ କରିବାକୁ ଏବଂ ତାଙ୍କ ଅପ୍‌ସନ୍‌ ବିଷୟରେ ଆଲୋଚନା କରିବାକୁ ସେ ସମୀରଙ୍କୁ ଡାକିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
            "as": "সি সমীৰক লগ ধৰি তাৰ বিকল্পবোৰ আলোচনা কৰিবলৈ ফোন কৰাৰ সিদ্ধান্ত লয়।",
            "gu": "તેણે સમીરને મળીને તેના વિકલ્પોની ચર્ચા કરવા માટે વાત કરવાનું નક્કી કર્યું."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_2_5////",
            "hi": "C11_2_5////",
            "ka": "C11_2_5////",
            "te": "C11_2_5////",
            "or": "C11_2_5////",
            "as": "C11_2_5////",
            "gu": "C11_2_5////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "It’s fantastic to see what people are doing. There is a lady who creates miniature set designs for architect firms and students. What a job!",
            "hi": "लोग क्या-क्या चीजें बना रहे हैं यह देखना सच में अद्भुत है। एक लेडी है जो आर्किटेक्ट फर्म और स्टूडेंट के लिए मिनिएचर सेट डिजाइन तैयार करती है। कितना अच्छा जॉब है!",
            "ka": "ಜನರು ಏನು ಮಾಡುತ್ತಿದ್ದಾರೆಂದು ನೋಡಲು ಇದು ಅದ್ಭುತವಾಗಿದೆ. ವಾಸ್ತುಶಿಲ್ಪಿ ಸಂಸ್ಥೆಗಳು ಮತ್ತು ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಚಿಕಣಿ ಸೆಟ್ ವಿನ್ಯಾಸಗಳನ್ನು ರಚಿಸುವ ಮಹಿಳೆಯೊಬ್ಬರು ಇದ್ದಾರೆ. ಎಂಥಹ ಕೆಲಸ!",
            "te": "ప్రజలు ఏమి చేస్తున్నారో చూడటం అద్భుతంగా ఉంది. ఆర్కిటెక్ట్ సంస్థలు మరియు విద్యార్థుల కోసం సూక్ష్మ సెట్ డిజైన్‌లను రూపొందించే ఒక మహిళ ఉంది. ఏం పని!. ఎంత మంచి పని!",
            "or": "ଲୋକମାନେ କଣ କରୁଛନ୍ତି ତାହା ଦେଖିବା ଚମତ୍କାର | ସେଠାରେ ଜଣେ ମହିଳା ଅଛନ୍ତି ଯିଏ ସ୍ଥାପତ୍ୟ ସଂସ୍ଥା ଏବଂ ଛାତ୍ରମାନଙ୍କ ପାଇଁ କ୍ଷୁଦ୍ର ସେଟ୍ ଡିଜାଇନ୍ ସୃଷ୍ଟି କରନ୍ତି | କି କାମ!",
            "as": "মানুহবোৰে যি কৰি আছে চাই বহুত ভাল লাগে ৷ এগৰাকী মহিলা আছে যিয়ে স্থাপত্যকলা প্ৰতিষ্ঠান আৰু শিক্ষাৰ্থীসকলৰ বাবে ক্ষুদ্ৰ ডিজাইন তৈয়াৰ কৰে। কি যে কাম!",
            "gu": "લોકો શું કરી રહ્યા છે તે જોવાનું મજા આવે છે. એક મહિલા છે જે આર્કિટેક્ટ કંપનીઓ અને વિદ્યાર્થીઓ માટે અલ્પાકૃતિ માળખાની ડિઝાઇન બનાવે છે. શું કહેવું કામનું!"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Yes, man! The possibilities are endless. You just need to find your own speciality.",
            "hi": "हाँ दोस्त! यहाँ तुम कुछ भी कर सकते हो। बस तुम्हें अपनी स्पेशलिटी ढूंढनी होगी।",
            "ka": "ಹೌದು! ಸಾಧ್ಯತೆಗಳಿಗೆ ಅಂತ್ಯವಿಲ್ಲ. ನಿನ್ನ ಸ್ವಂತ ವಿಶೇಷತೆಯನ್ನು ನೀನು ಕಂಡುಹಿಡಿಯಬೇಕು.",
            "te": "అవునండి! అవకాశాలు అంతులేనివి. మీరు మీ స్వంత ప్రత్యేకతను కనుగొనవలసి ఉంటుంది.",
            "or": "ହଁ, ମଣିଷ! ସମ୍ଭାବନାଗୁଡ଼ିକ ଅସୀମ | ତୁମକୁ କେବଳ ନିଜର ବିଶେଷତା ଖୋଜିବାକୁ ପଡିବ |",
            "as": "হয় দে! সম্ভাৱনাবোৰৰ অন্ত নাই। তুমি কেৱল তোমাৰ নিজৰ বিশেষত্বতো বিচাৰিব লাগিব।",
            "gu": "હા, ભાઈ! શક્યતાઓનો કોઈ અંત નથી. તમારે ફક્ત તમારી પોતાની વિશેષતા શોધવાની જરૂર છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Do you think I can also pursue something like this?",
            "hi": "क्या तुम्हें लगता है मैं भी ऐसा कुछ कर सकता हूँ?",
            "ka": "ನಾನು ಕೂಡ ಈ ರೀತಿಯದನ್ನು ಮುಂದುವರಿಸಬಹುದೆಂದು ನೀನು ಭಾವಿಸುತ್ತೀಯ?",
            "te": "నేను కూడా ఇలాంటివి చేయాలని నువ్వు అనుకుంటున్నావా?",
            "or": "ତୁମେ ଭାବୁଛ କି ମୁଁ ମଧ୍ୟ ଏହିପରି କିଛି ଅନୁସରଣ କରିପାରିବି?",
            "as": "মইও এনেকুৱা কিবা এটা কৰিব পাৰিম বুলি তুমি ভাবানে?",
            "gu": "તમને લાગે છે કે હું પણ કઈક આવું કરીને કામ ચાલુ રાખું?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Of course, but it won’t happen overnight. Why don't you get started with some projects that can help you build your profile?",
            "hi": "क्यों नहीं, लेकिन इसके लिए समय लगेगा। तुम कुछ प्रोजेक्ट क्यों नहीं बनाते, जो तुम्हें अपना प्रोफाइल बनाने में मदद करेंगे।",
            "ka": "ಸಹಜವಾಗಿ, ಆದರೆ ಇದು ರಾತ್ರಿಯಲ್ಲಿ ಸಂಭವಿಸುವುದಿಲ್ಲ. ನಿನ್ನ ಪ್ರೊಫೈಲ್ ಅನ್ನು ನಿರ್ಮಿಸಲು ಸಹಾಯ ಮಾಡುವ ಕೆಲವು ಯೋಜನೆಗಳೊಂದಿಗೆ ನೀನು ಏಕೆ ಪ್ರಾರಂಭಿಸಬಾರದು?",
            "te": "అయితే, ఇది ఒకే రాత్రిలో జరగదు. మీ ప్రొఫైల్‌ను రూపొందించడంలో మీకు సహాయపడే కొన్ని ప్రాజెక్ట్‌లను మీరు ఎందుకు ప్రారంభించకూడదు?",
            "or": "ଅବଶ୍ୟ, କିନ୍ତୁ ଏହା ରାତାରାତି ହେବ ନାହିଁ | ଆପଣ କାହିଁକି କିଛି ପ୍ରୋଜେକ୍ଟ ସହିତ ଆରମ୍ଭ କରୁ ନାହାନ୍ତି ଯାହା ଆପଣଙ୍କୁ ଆପଣଙ୍କର ପ୍ରୋଫାଇଲ୍ ତିଆରି କରିବାରେ ସାହାଯ୍ୟ କରିପାରିବ?",
            "as": "অৱশ্যে, কিন্তু এইটো ৰাতিটোৰ ভিতৰতে নহ'ব। তুমি কিছুমান প্ৰকল্পৰ সৈতে আৰম্ভ নকৰা কিয় যিয়ে তোমাৰ প্ৰ'ফাইল বনোৱাত সহায় কৰিব পাৰে?",
            "gu": "હા કેમ નહીં, પરંતુ તે રાતોરાત ના થઈ શકે. શા માટે તમે થોડાક પ્રોજેક્ટ્સ સાથે ચાલુ કરતા નથી જે તમને કાબિલિયત બનાવવામાં મદદ કરી શકે?"
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_3_1",
            "hi": "C11_3_1",
            "ka": "C11_3_1",
            "te": "C11_3_1",
            "or": "C11_3_1",
            "as": "C11_3_1",
            "gu": "C11_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Sameer helps him get a small project through his contacts with a wedding planner. He works with the set construction crew and provides his expertise in creating a very detailed setup.",
            "hi": "समीर अपने पहचान के वेडिंग प्लानर के साथ उसे एक छोटा प्रोजेक्ट दिलवाने में उसकी मदद करता है। वह सेट के कंस्ट्रक्शन ग्रुप के साथ काम करता है और एक अच्छा सेटअप तैयार करने के लिए अपने कौशल का इस्तेमाल करता है।",
            "ka": "ಸಮೀರ್ ಮದುವೆಯ ಯೋಜಕನೊಂದಿಗಿನ ತನ್ನ ಸಂಪರ್ಕಗಳ ಮೂಲಕ ಸಣ್ಣ ಪ್ರಾಜೆಕ್ಟ್ ಪಡೆಯಲು ಸಹಾಯ ಮಾಡುತ್ತಾನೆ. ಅವನು ಸೆಟ್ ನಿರ್ಮಾಣ ಸಿಬ್ಬಂದಿಯೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುತ್ತಾನೆ ಮತ್ತು ವಿವರವಾದ ಸೆಟಪ್ ಅನ್ನು ರಚಿಸುವಲ್ಲಿ ಅವನ ಪರಿಣತಿಯನ್ನು ಒದಗಿಸುತ್ತಾನೆ.",
            "te": "వెడ్డింగ్ ప్లానర్‌తో ఉన్న పరిచయాల ద్వారా సమీర్ అతనికి చిన్న ప్రాజెక్ట్‌ను పొందడానికి సహాయం చేస్తాడు. అతను సెట్ నిర్మాణ సిబ్బందితో కలిసి పని చేస్తాడు మరియు చాలా వివరణాత్మక సెటప్‌ను సెట్ చేయడంలో తన అనుభవాన్ని ఉపయోగిస్తాడు.",
            "or": "ସମୀର ତାଙ୍କୁ ଏକ ବିବାହ ଯୋଜନାକାରୀଙ୍କ ସହିତ ଯୋଗାଯୋଗ ମାଧ୍ୟମରେ ଏକ ଛୋଟ ପ୍ରୋଜେକ୍ଟ ପାଇବାରେ ସାହାଯ୍ୟ କରନ୍ତି | ସେ ସେଟ୍ ନିର୍ମାଣକାରୀ ଦଳ ସହିତ କାର୍ଯ୍ୟ କରନ୍ତି ଏବଂ ଏକ ବିସ୍ତୃତ ସେଟଅପ୍ ସୃଷ୍ଟି କରିବାରେ ତାଙ୍କର ପାରଦର୍ଶିତା ପ୍ରଦାନ କରନ୍ତି |",
            "as": "সমীৰে তাৰ সম্পৰ্কৰ জৰিয়তে এজন বিবাহ পৰিকল্পনাকাৰীৰ লগত নবীনৰ বাবে এটা সৰু প্ৰকল্প লাভ কৰাত সহায় কৰে। তেওঁ নিৰ্মাণ কৰ্মীৰ সৈতে কাম কৰে আৰু এটা সম্পূৰ্ণ ছেটআপ তৈয়াৰ কৰাত তেওঁৰ দক্ষতা ব্যৱহাৰ কৰে।",
            "gu": "સમીર વેડિંગ પ્લાનર સાથેના તેના સંપર્કો દ્વારા તેને એક નાનો પ્રોજેક્ટ મેળવવામાં મદદ કરે છે. તે સેટ કન્સ્ટ્રક્શન ટીમ સાથે કામ કરે છે અને ખૂબ જ વિગતવાર સેટઅપ બનાવવામાં તેમની કુશળતા પ્રદાન કરે છે."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_3_2",
            "hi": "C11_3_2",
            "ka": "C11_3_2",
            "te": "C11_3_2",
            "or": "C11_3_2",
            "as": "C11_3_2",
            "gu": "C11_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He does many such projects and then updates his profile on the freelance platform as a set designer for weddings and concerts.",
            "hi": "ऐसे कई प्रोजेक्ट करता है और फ्रीलांसर प्लेटफार्म पर वेडिंग और कॉन्सर्ट के लिए सेट डिजाइनर के तौर पर अपना प्रोफाइल अपडेट करता है।",
            "ka": "ಅವನು ಅಂತಹ ಅನೇಕ ಯೋಜನೆಗಳನ್ನು ಮಾಡುತ್ತಾನೆ ಮತ್ತು ನಂತರ ಮದುವೆಗಳು ಮತ್ತು ಸಂಗೀತ ಕಚೇರಿಗಳಿಗೆ ಸೆಟ್ ಡಿಸೈನರ್ ಆಗಿ ಸ್ವತಂತ್ರ ವೇದಿಕೆಯಲ್ಲಿ ತನ್ನ ಪ್ರೊಫೈಲ್ ಅನ್ನು ನವೀಕರಿಸುತ್ತಾನೆ.",
            "te": "అతను అలాంటి అనేక ప్రాజెక్ట్‌లను చేసి వివాహాలు మరియు కాన్సర్ట్స్ కోసం సెట్ డిజైనర్‌గా ఫ్రీలాన్స్ ప్లాట్‌ఫారమ్‌లో తన ప్రొఫైల్‌ను అప్‌డేట్ చేస్తాడు.",
            "or": "ସେ ଏହିପରି ଅନେକ ପ୍ରୋଜେକ୍ଟ କରନ୍ତି ଏବଂ ତା’ପରେ ବିବାହ ଏବଂ କନ୍ସର୍ଟ ପାଇଁ ଏକ ସେଟ୍ ଡିଜାଇନର୍ ଭାବରେ ଫ୍ରିଲାନ୍ସ ପ୍ଲାଟଫର୍ମରେ ତାଙ୍କର ପ୍ରୋଫାଇଲ୍ ଅପଡେଟ୍ କରନ୍ତି |",
            "as": "তেওঁ এনেকুৱা বহুতো প্ৰকল্পত কাম কৰে আৰু তাৰ পিছত বিবাহ আৰু সংগীতানুষ্ঠানৰ বাবে ছেট ডিজাইনাৰ বুলি ফ্ৰিলেন্স প্লেটফৰ্মত তেওঁৰ প্ৰ'ফাইল আপডেট কৰে।",
            "gu": "તેણે આવા ઘણા પ્રોજેક્ટ કર્યા અને પછીથી લગ્ન અને કોન્સર્ટ માટે સેટ ડિઝાઇનર તરીકે ફ્રીલાન્સ પ્લેટફોર્મ પર તેની પ્રોફાઇલ બદલવાનું કરે છે."
          }
        }
      ],
      "page18": [
        {
          "valuePath": "display",
          "value": {
            "en": "Experimentation 2",
            "hi": "प्रयोग करना 2",
            "ka": "ಪ್ರಯೋಗ 2",
            "te": "ప్రయోగీకరణం 2",
            "or": "Experimentation 2",
            "as": "পৰীক্ষা-নিৰীক্ষা 2",
            "gu": "પ્રયોગ 2"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Experimentation experience!",
            "hi": "आपने अभी-अभी प्रयोग करने के अनुभव को प्राप्त किया है!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಪ್ರಯೋಗದ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే ప్రయోగీకరణ అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ କେବଳ ପରୀକ୍ଷଣ ଅଭିଜ୍ଞତା ହାସଲ କରିଛନ୍ତି!",
            "as": "তুমি এইমাত্ৰ পৰীক্ষা-নিৰীক্ষাৰ অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "તમે હમણાં જ પ્રયોગનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Experimentation",
            "hi": "प्रयोग करना",
            "ka": "ಪ್ರಯೋಗ",
            "te": "ప్రయోగీకరణం",
            "or": "ପରୀକ୍ଷଣ",
            "as": "পৰীক্ষা-নিৰীক্ষা",
            "gu": "પ્રયોગ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर विकास कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "వృత్తి అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ଯାରିୟର୍‌ ଡେଭଲପ୍‌ମେଣ୍ଟ୍‌ କୌଶଳ",
            "as": "জীৱিকা বিকাশৰ দক্ষতা",
            "gu": "કારકિર્દી વિકાસ કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Naveen starts his career exploration first through dance and moves to becoming a mechanic as he wants a stable job. He does an apprentinship to get enough experience to qualify for government jobs. When he does not get the government job, he decides to do something on his own. Naveen experiments and explores with these jobs and they help him understand the job best suited for him. One of the ways you can explore other options in your career journey is by looking for opportunities to work either over the weekend or part-time.",
            "hi": "नवीन ने सबसे पहले डान्स के तौर पर करियर को एक्सप्लोर किया और फिर मैकेनिक बना, क्योंकि वह स्थिर जॉब करना चाहता था। उसने सरकारी जॉब के लिए योग्य होने के लिए, अप्रेंटिसशिप के तौर पर पर्याप्त अनुभव प्राप्त कर लिया। जब उसे सरकारी जॉब नहीं मिली, तब उसने खुद का व्यवसाय शुरू करने का निर्णय लिया। नवीन इन जॉब के साथ प्रयोग करता है और एक्सप्लोर करता है कि, उसके लिए कौन सा जॉब सही रहेगा।अपने करियर के लिए दूसरे विकल्प एक्सप्लोर करने का और एक तरीका यह है कि, सप्ताह की छुट्टी के दिन या पार्ट टाइम जॉब करके देखना।",
            "ka": "ನವೀನ್ ತನ್ನ ವೃತ್ತಿಜೀವನದ ಅನ್ವೇಷಣೆಯನ್ನು ಮೊದಲು ನೃತ್ಯದ ಮೂಲಕ ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ ಮತ್ತು ಸ್ಥಿರವಾದ ಕೆಲಸವನ್ನು ಬಯಸಿದ್ದರಿಂದ ಮೆಕ್ಯಾನಿಕ್ ಆಗಲು ಚಲಿಸುತ್ತಾನೆ. ಸರ್ಕಾರಿ ಉದ್ಯೋಗಗಳಿಗೆ ಅರ್ಹತೆ ಪಡೆಯಲು ಸಾಕಷ್ಟು ಅನುಭವವನ್ನು ಪಡೆಯಲು ಅವನು ಅಪ್ರೆಂಟಿನ್‌ಶಿಪ್ ಮಾಡುತ್ತಾನೆ. ಸರ್ಕಾರಿ ನೌಕರಿ ಸಿಗದಿದ್ದಾಗ ತಾನೇ ಏನಾದರೂ ಮಾಡಬೇಕೆಂದು ನಿರ್ಧರಿಸುತ್ತಾನೆ.\n ನವೀನ್ ಈ ಉದ್ಯೋಗಗಳೊಂದಿಗೆ ಪ್ರಯೋಗಗಳನ್ನು ಮತ್ತು ಪರಿಶೋಧನೆಗಳನ್ನು ಮಾಡುತ್ತಾನೆ ಮತ್ತು ಅವವು ಅವನಿಗೆ ಸೂಕ್ತವಾದ ಕೆಲಸವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತದ್ದೆ.ವಾರಾಂತ್ಯದಲ್ಲಿ ಅಥವಾ ಅರೆಕಾಲಿಕವಾಗಿ ಕೆಲಸ ಮಾಡಲು ಅವಕಾಶಗಳನ್ನು ಹುಡುಕುವ ಮೂಲಕ ನಿಮ್ಮ ವೃತ್ತಿಜೀವನದ ಪ್ರಯಾಣದಲ್ಲಿ ನೀವು ಇತರ ಆಯ್ಕೆಗಳನ್ನು ಅನ್ವೇಷಿಸುವ ಒಂದು ಮಾರ್ಗವಾಗಿದೆ.",
            "te": "నవీన్ తన కెరీర్ అన్వేషణను మొదట డ్యాన్స్ ద్వారా ప్రారంభించాడు మరియు అతను స్థిరమైన ఉద్యోగం కోసం మెకానిక్‌గా మారాడు. అతను ప్రభుత్వ ఉద్యోగాలకు అర్హత సాధించడానికి తగినంత అనుభవం పొందడానికి అప్రెంటిన్‌షిప్ చేస్తాడు. ప్రభుత్వ ఉద్యోగం రానప్పుడు సొంతంగా ఏదైనా చేయాలని నిర్ణయించుకుంటాడు. నవీన్ చాలా ఉద్యోగాలను అన్వేషిస్తాడు మరియు అవి అతనికి బాగా సరిపోయే ఉద్యోగాన్ని అర్థం చేసుకోవడంలో సహాయపడతాయి. మీ కెరీర్ లో మీరు ఇతర ఎంపికలను అన్వేషించగల మార్గాలలో ఒకటి, వారాంతంలో లేదా పార్ట్ టైమ్‌లో పని చేయగల అవకాశాల కోసం వెతకడం.",
            "or": "ନବୀନ ପ୍ରଥମେ ନୃତ୍ୟ ମାଧ୍ୟମରେ ନିଜର କ୍ୟାରିୟର୍‌ ଏକ୍ସପ୍ଲୋରେସନ୍‌ ଆରମ୍ଭ କରନ୍ତି ଏବଂ ଏକ ସ୍ଥିର ଚାକିରି ଚାହୁଁଥିବାରୁ ମେକାନିକ୍ ହେବାକୁ ଆଗେଇ ଆସନ୍ତି | ସରକାରୀ ଚାକିରି ପାଇଁ ଯୋଗ୍ୟତା ଅର୍ଜନ କରିବା ପାଇଁ ସେ ଏକ ଆପ୍ରେଣ୍ଟିସିପ୍‌ କରନ୍ତି | ଯେତେବେଳେ ସେ ସରକାରୀ ଚାକିରି ପାଆନ୍ତି ନାହିଁ, ସେତେବେଳେ ସେ ନିଜେ କିଛି କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି | ନବୀନ ଏହି କାର୍ଯ୍ୟଗୁଡିକ ସହିତ ପରୀକ୍ଷଣ ଏବଂ ଏକ୍ସପ୍ଲୋର୍‌ କରନ୍ତି ଏବଂ ସେଗୁଡିକ ତାଙ୍କୁ ତାଙ୍କ ପାଇଁ ସର୍ବୋତ୍ତମ କାର୍ଯ୍ୟ ବୁଝିବାରେ ସାହାଯ୍ୟ କରନ୍ତି | ତୁମର କ୍ୟାରିୟର୍‌ ଯାତ୍ରାରେ ତୁମେ ଅନ୍ୟ ବିକଳ୍ପଗୁଡିକ ଏକ୍ସପ୍ଲୋର୍‌ କରିବାର ଗୋଟିଏ ଉପାୟ ହେଉଛି ସପ୍ତାହାନ୍ତରେ କିମ୍ବା ପାର୍ଟ ଟାଇ୍ମ୍‌ରେ କାମ କରିବାର ସୁଯୋଗ ଖୋଜିବା |",
            "as": "নবীনে নৃত্যৰ জৰিয়তে প্ৰথমে তাৰ জীৱিকা আৰম্ভ কৰে আৰু মেকানিক হোৱাৰ দিশে আগবাঢ়ে কিয়নো তাক এটা স্থিৰ চাকৰি লাগে। সি চৰকাৰী চাকৰি পাবলৈ অভিজ্ঞতাৰ বাবে এটা এপ্ৰেণ্টিছশ্বিপো কৰে। সি যেতিয়া চৰকাৰী চাকৰি নাপালে, তেতিয়া নিজে কিবা এটা কৰাৰ সিদ্ধান্ত লয়। নবীনে এই চাকৰিবোৰৰ সৈতে পৰীক্ষা-নিৰীক্ষা কৰে আৰু সেইবোৰে তাক তাৰ বাবে উপযুক্ত কামটো বুজি পোৱাত সহায় কৰে। তুমি তোমাৰ জীৱিকাৰ যাত্ৰাত আন বিকল্পবোৰ অন্বেষণ কৰাৰ এটা উপায় হ'ল সপ্তাহান্তত বা আংশিক সময়ৰ বাবে কাম কৰাৰ সুযোগ বিচৰা।",
            "gu": "નવીને તેની કારકિર્દીની શોધ સૌપ્રથમ નૃત્ય દ્વારા શરૂ કરી અને તે મિકેનિક બની ગયો કારણ કે તેને સ્થિર નોકરી જોઈતી હતી. સરકારી નોકરી માટે લાયક બનવા માટે પૂરતો અનુભવ મેળવવા માટે તેણે ઇન્ટર્નશિપ કરી. જ્યારે તેને સરકારી નોકરી ન મળી ત્યારે તેણે જાતે જ કંઈક કરવાનું નક્કી કર્યું. નવીને આ નોકરીઓ સાથે પ્રયોગ અને શોધખોળ કરી અને તેઓએ તેણીને તેના માટે સૌથી યોગ્ય નોકરીઓ સમજવામાં મદદ કરી. તમારા કારકિર્દીના માર્ગમાં તમે અન્ય વિકલ્પોની શોધખોળ કરી શકો તે એક રીત છે કે સપ્તાહના અંતે અથવા પાર્ટ ટાઇમમાં કામ કરવાની તકો શોધવી."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_4_1//",
            "hi": "C11_4_1//",
            "ka": "C11_4_1//",
            "te": "C11_4_1//",
            "or": "C11_4_1//",
            "as": "C11_4_1//",
            "gu": "C11_4_1//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Naveen’s mother is concerned about this kind of work. She feels like it doesn’t offer any financial security.",
            "hi": "नवीन की माँ को इस तरह के काम के कारण चिंता होती है। उसे लगता है कि, यह काम किसी भी तरह की आर्थिक सुरक्षा प्रदान नहीं करता।",
            "ka": "ನವೀನ್ ಅವರ ತಾಯಿ ಈ ರೀತಿಯ ಕೆಲಸದ ಬಗ್ಗೆ ಕಾಳಜಿ ವಹಿಸುತ್ತಾರೆ. ಇದು ಯಾವುದೇ ಆರ್ಥಿಕ ಭದ್ರತೆಯನ್ನು ನೀಡುವುದಿಲ್ಲ ಎಂದು ಅವರು ಭಾವಿಸುತ್ತಾರೆ.",
            "te": "ఇలాంటి పని పట్ల నవీన్ తల్లి ఆందోళన చెందుతోంది. ఇది ఎలాంటి ఆర్థిక భద్రతను అందించదని ఆమె భావిస్తోంది.",
            "or": "ଏହି ପ୍ରକାରର କାମ ପାଇଁ ନବୀନଙ୍କ ମା ଚିନ୍ତିତ | ସେ ଅନୁଭବ କରୁଛନ୍ତି ଯେ ଏହା କୌଣସି ଆର୍ଥିକ ସୁରକ୍ଷା ପ୍ରଦାନ କରେ ନାହିଁ |",
            "as": "নবীনৰ মাক এই ধৰণৰ কামৰ বিষয়ে চিন্তিত। তেওঁ অনুভৱ কৰে যে ই কোনো বিত্তীয় সুৰক্ষা প্ৰদান নকৰে।",
            "gu": "નવીનની માતા આ પ્રકારના કામથી ચિંતિત છે. તેને લાગે છે કે તેનાથી કોઈ નાણાકીય સુરક્ષા મળતી નથી."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Mother",
            "hi": "माँ",
            "ka": "ತಾಯಿ",
            "te": "తల్లి",
            "or": "ମା",
            "as": "মাক",
            "gu": "માતા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "You had a good job at KPCL. I don’t know what I did wrong that you decided to leave all of that to be a construction worker.",
            "hi": "KPCL में तुम्हारी अच्छी जॉब थी। मुझे नहीं पता कि, क्या गलत हुआ जो तुमने इतना अच्छा जॉब छोड़ कर, कंस्ट्रक्शन वर्कर के तौर पर काम करने का निर्णय लिया।",
            "ka": "ಕೆಪಿಸಿಎಲ್‌ನಲ್ಲಿ ನಿನಗೆ ಒಳ್ಳೆಯ ಕೆಲಸ ಇತ್ತು. ಅದನ್ನೆಲ್ಲ ಬಿಟ್ಟು ಕಟ್ಟಡ ಕಾರ್ಮಿಕನಾಗಿರಲು ನೀನು ನಿರ್ಧರಿಸಿದ್ದಕ್ಕೆ ನಾನೇನು ತಪ್ಪು ಮಾಡಿದೆನೋ ಗೊತ್ತಿಲ್ಲ.",
            "te": "నీకు KPCLలో మంచి ఉద్యోగం వచ్చింది. వాటన్నింటిని విడిచిపెట్టి భవన నిర్మాణ కార్మికునిగా ఉండాలని నిర్ణయించుకున్నందుకు నేనేం తప్పు చేశానో నాకు తెలియదు.",
            "or": "KPCL ରେ ତୁମର ଭଲ କାମ ଥିଲା | ମୁଁ ଜାଣେ ନାହିଁ ମୁଁ କ’ଣ ଭୁଲ୍ କରିଥିଲି ଯେ ତୁମେ ନିର୍ମାଣ ଶ୍ରମିକ ହେବା ପାଇଁ ସେସବୁକୁ ଛାଡିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛ |",
            "as": "KPCLত তোমাৰ এটা ভাল চাকৰি আছিল। মই নাজানো মই কি ভুল কৰিলো যে তুমি সেই সকলোবোৰ এৰি নিৰ্মাণ কৰ্মী হ'বলৈ বিচাৰিছা।",
            "gu": "KPCLમાં તારી સારી નોકરી હતી . મને ખબર નથી કે મેં શું ખોટું કર્યું છે કે તે એ બધું છોડીને બાંધકામ કામદાર બનવાનું નક્કી કર્યું."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "No, no, Amma, it’s not construction work. It’s set design. There’s a big difference.",
            "hi": "नहीं, नहीं, अम्मा, यह कंस्ट्रक्शन वर्क नहीं है। यह सेट डिजाइनिंग है। इसमें बहुत अंतर है।",
            "ka": "ಇಲ್ಲ, ಇಲ್ಲ, ಅಮ್ಮಾ, ಇದು ನಿರ್ಮಾಣ ಕೆಲಸವಲ್ಲ. ಇದು ಸೆಟ್ ವಿನ್ಯಾಸವಾಗಿದೆ. ದೊಡ್ಡ ವ್ಯತ್ಯಾಸವಿದೆ.",
            "te": "లేదు, లేదు, అమ్మా, ఇది నిర్మాణ పని కాదు. ఇది సెట్ డిజైన్. పెద్ద తేడా ఉంది.",
            "or": "ନା, ନା, ଆମ୍ମା, ଏହା ନିର୍ମାଣ କାର୍ଯ୍ୟ ନୁହେଁ | ଏହା ସେଟ୍ ଡିଜାଇନ୍ | ସେଠାରେ ଏକ ବଡ଼ ପାର୍ଥକ୍ୟ ଅଛି |",
            "as": "নহয়, নহয়, মা, এইটো নিৰ্মাণকাৰ্য নহয়। এইটো ছেট ডিজাইন। এটা ডাঙৰ পাৰ্থক্য আছে।",
            "gu": "ના, ના, માતા, આ બાંધકામનું કામ નથી. આ એક સ્ટેજ ડિઝાઇન છે. એક મોટો તફાવત છે."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_4_2////",
            "hi": "C11_4_2////",
            "ka": "C11_4_2////",
            "te": "C11_4_2////",
            "or": "C11_4_2////",
            "as": "C11_4_2////",
            "gu": "C11_4_2////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Why should I care? All I know is that you wasted an excellent opportunity.",
            "hi": "मुझे इससे क्या लेना देना? मैं बस यही जानती हूँ कि तुमने एक अच्छे अवसर को खो दिया है।",
            "ka": "ನಾನು ಯಾಕೆ ಕಾಳಜಿ ವಹಿಸಬೇಕು? ನೀವು ಒಂದು ಅತ್ಯುತ್ತಮ ಅವಕಾಶವನ್ನು ವ್ಯರ್ಥ ಮಾಡಿದ್ದೀಯ ಎಂದು ನನಗೆ ತಿಳಿದಿದೆ.",
            "te": "అవన్నీ నాకెందుకు? మీరు ఒక అద్భుతమైన అవకాశాన్ని వృధా చేశారని నాకు తెలుసు.",
            "or": "ମୁଁ କାହିଁକି ଖାତିର କରିବି? ମୁଁ କେବଳ ଜାଣେ ତୁମେ ଏକ ଉତ୍ତମ ସୁଯୋଗ ନଷ୍ଟ କରିଛ |",
            "as": "মই খাতিৰ নকৰোঁ? মই এয়াই জানো যে তুমি এটা উৎকৃষ্ট সুযোগ নষ্ট কৰিছা।",
            "gu": "મારે શા માટે કાળજી લેવી જોઈએ? હું માત્ર એટલું જ જાણું છું કે તે એક મહાન તક ખોઈ દીધી છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Please, can you trust me? It isn’t as risky. I have spoken to people in this field. And I know I'm good at the work I do.",
            "hi": "मुझ पर भरोसा रखो। इसमें ज्यादा जोखिम नहीं है। मैंने इस फिल्ड के लोगों के साथ बात की है। और मैं इस काम में बेहतर भी हूँ।",
            "ka": "ದಯವಿಟ್ಟು, ನೀವು ನನ್ನನ್ನು ನಂಬಬಹುದೇ? ಇದು ಅಪಾಯಕಾರಿ ಅಲ್ಲ. ಈ ಕ್ಷೇತ್ರದ ಜನರೊಂದಿಗೆ ಮಾತನಾಡಿದ್ದೇನೆ. ಮತ್ತು ನಾನು ಮಾಡುವ ಕೆಲಸದಲ್ಲಿ ನಾನು ಉತ್ತಮ ಎಂದು ನನಗೆ ತಿಳಿದಿದೆ.",
            "te": "దయచేసి, నువ్వు నన్ను నమ్ముతావా? ఇది అంత రిస్క్ కాదు. ఈ ఫీల్డ్ లోని వ్యక్తులతో మాట్లాడాను మరియు నేను చేసే పనిలో నాకు తెలివి ఉంది నాకు తెలుసు.",
            "or": "ଦୟାକରି, ତୁମେ ମୋତେ ବିଶ୍ୱାସ କରିବ କି? ଏହା ଏତେ ବିପଦପୂର୍ଣ୍ଣ ନୁହେଁ | ମୁଁ ଏହି କ୍ଷେତ୍ରର ଲୋକଙ୍କ ସହ କଥା ହୋଇଛି ଏବଂ ମୁଁ ଜାଣେ ମୁଁ କରୁଥିବା କାର୍ଯ୍ୟରେ ମୁଁ ଠିକ୍‌ ଅଛି |",
            "as": "অনুগ্ৰহ কৰি, মোক বিশ্বাস কৰিবানে? ই সিমান বিপদজনক নহয়। মই এই ক্ষেত্ৰৰ মানুহৰ সৈতে কথা পাতিছো। আৰু মই জানো যে মই কৰা কামত মই ভাল।",
            "gu": "કૃપા કરીને તમે મારા પર વિશ્વાસ કરી શકો છો? તે એટલું જોખમી નથી. મેં આ વિસ્તારના લોકો સાથે વાત કરી છે. અને હું જાણું છું કે હું મારી નોકરીમાં સારો છું."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "It’s not a stable job Naveen, how are you going to run a house, have a family with such an unreliable source of income?",
            "hi": "लेकिन यह स्थिर जॉब नहीं है, नवीन। तुम घर कैसे चलाओगे, इस तरह के अनिश्चित सोर्स के उत्पन्न के साथ फैमिली कैसे बनाओगे?",
            "ka": "ಇದು ಸ್ಥಿರವಾದ ಕೆಲಸವಲ್ಲ ನವೀನ್, ಅಂತಹ ವಿಶ್ವಾಸಾರ್ಹವಲ್ಲದ ಆದಾಯದ ಮೂಲವನ್ನು ಹೊಂದಿ ನೀನು ಮನೆಯನ್ನು ಹೇಗೆ ನಡೆಸುತ್ತೀರಿಯ, ಕುಟುಂಬವನ್ನು ಹೇಗೆ ಹೊಂದುತಿಯಾ?",
            "te": "ఇది స్థిరమైన ఉద్యోగం కాదు నవీన్, మీరు ఇంటిని ఎలా నడిపించబోతున్నారు, ఇంత నమ్మశక్యం కాని ఆదాయ వనరుతో కుటుంబాన్ని కలిగి ఉన్నారు?",
            "or": "ଏହା ଏକ ସ୍ଥିର ଚାକିରି ନୁହେଁ ନବୀନ, ତୁମେ କିପରି ଏକ ଘର ଚଳାଇବାକୁ ଯାଉଛନ୍ତି, ଏପରି ଏକ ଅବିଶ୍ୱସନୀୟ ଆୟ ସହିତ ପରିବାର କିପରି ରହିବ?",
            "as": "এইটো এটা স্থিৰ কাম নহয় নবীন, তুমি কেনেকৈ এক অনিৰ্ভৰযোগ্য উপাৰ্জনৰে এখন ঘৰ এটা পৰিয়াল চলাব পাৰিবা?",
            "gu": "તે સ્થિર નોકરી નથી, નવીન, તું ઘર કેવી રીતે ચલાવશો, તારી પાસે આવકનો આટલો અવિશ્વસનીય સ્ત્રોત ધરાવતો પરિવાર છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Amma, I'm happy doing this. Just trust me that it will lead to stability when I establish myself. I know it looks risky as of now, but just give it some time.",
            "hi": "अम्मा, मुझे यह जॉब करने में खुशी मिलती हैं। मुझपर विश्वास रखो, जब मैं अच्छा नाम कमा लूंगा तब हमें स्थिरता भी प्राप्त हो जाएगी। मुझे पता है इसमें अभी जोखिम ज्यादा है लेकिन मुझे थोड़ा समय दो।",
            "ka": "ಅಮ್ಮಾ, ನಾನು ಇದನ್ನು ಮಾಡಲು ಸಂತೋಷಪಡುತ್ತೇನೆ. ನಾನು ನನ್ನನ್ನು ಸ್ಥಾಪಿಸಿದಾಗ ಅದು ಸ್ಥಿರತೆಗೆ ಕಾರಣವಾಗುತ್ತದೆ ಎಂದು ನನ್ನನ್ನು ನಂಬಿರಿ. ಸದ್ಯಕ್ಕೆ ಇದು ಅಪಾಯಕಾರಿ ಎಂದು ನನಗೆ ತಿಳಿದಿದೆ, ಆದರೆ ಸ್ವಲ್ಪ ಸಮಯ ನೀಡಿ.",
            "te": "అమ్మా, నేను ఇలా చేయడం సంతోషంగా ఉంది. నన్ను నేను స్థాపించుకున్నప్పుడు అది స్థిరత్వానికి దారితీస్తుందని నన్ను నమ్మండి. ప్రస్తుతానికి ఇది ప్రమాదకరమని నాకు తెలుసు, కానీ కొంచెం సమయం ఇవ్వండి.",
            "or": "ଆମ୍ମା, ମୁଁ ଏହା କରି ଖୁସି ଅଟେ | କେବଳ ମୋତେ ବିଶ୍ୱାସ କରନ୍ତୁ ଯେ ଯେତେବେଳେ ମୁଁ ନିଜକୁ ପ୍ରତିଷ୍ଠିତ କରେ ସେତେବେଳେ ଏହା ସ୍ଥିରତାକୁ ଆଣିବ | ମୁଁ ଜାଣେ ଏହା ବର୍ତ୍ତମାନ ପରି ବିପଦପୂର୍ଣ୍ଣ ଦେଖାଯାଉଛି, କିନ୍ତୁ ଏହାକୁ କିଛି ସମୟ ଦିଅନ୍ତୁ |",
            "as": "মা, মই এইটো কৰি সুখী। মাত্ৰ মোক বিশ্বাস কৰা যে যেতিয়া মই নিজকে প্ৰতিষ্ঠা কৰিম তেতিয়া ই স্থিৰতা লৈ যাব। মই জানো যে এইটো এতিয়া বিপদজনক যেন লাগিছে, কিন্তু ইয়াক অলপ সময় দিয়ক।",
            "gu": "અમ્મા, મને આ કરવાનું ગમે છે. ફક્ત મારા પર વિશ્વાસ કરો કે એકવાર હું સ્થાપિત થઈશ તે સ્થિરતા તરફ દોરી જશે. હું જાણું છું કે તે હવે જોખમી લાગે છે, પરંતુ આને થોડો સમય આપો."
          }
        }
      ],
      "page12": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_5_1",
            "hi": "C11_5_1",
            "ka": "C11_5_1",
            "te": "C11_5_1",
            "or": "C11_5_1",
            "as": "C11_5_1",
            "gu": "C11_5_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen is determined to find a space of expertise within this field of set design.",
            "hi": "नवीन में सेट डिजाइनिंग के क्षेत्र में कौशल्य हासिल करने का निर्णय ले लिया है।",
            "ka": "ನವೀನ್ ಸೆಟ್ ವಿನ್ಯಾಸದ ಈ ಕ್ಷೇತ್ರದಲ್ಲಿ ಪರಿಣತಿಯ ಜಾಗವನ್ನು ಕಂಡುಕೊಳ್ಳಲು ನಿರ್ಧರಿಸಿದ್ದಾನೆ.",
            "te": "నవీన్ ఈ సెట్ డిజైన్ రంగంలో నైపుణ్యం ఉన్న స్థలాన్ని కనుగొనాలని నిశ్చయించుకున్నాడు.",
            "or": "ସେଟ୍ ଡିଜାଇନ୍ ର ଏହି କ୍ଷେତ୍ର ମଧ୍ୟରେ ଜ୍ଞାନର ଏକ ସ୍ଥାନ ଖୋଜିବାକୁ ନବୀନ ସଂକଳ୍ପବଦ୍ଧ |",
            "as": "নবীনে ছেট ডিজাইনৰ এই ক্ষেত্ৰখনত বিশেষজ্ঞৰ স্থান বিচাৰিবলৈ সংকল্পবদ্ধ।",
            "gu": "નવીન સેટ ડિઝાઇનના આ ક્ષેત્રમાં નિપુણતાનો વિસ્તાર શોધવા માટે મક્કમ છે."
          }
        }
      ],
      "page13": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_5_2",
            "hi": "C11_5_2",
            "ka": "C11_5_2",
            "te": "C11_5_2",
            "or": "C11_5_2",
            "as": "C11_5_2",
            "gu": "C11_5_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "His experience in dance and hip-hop gives him an idea regarding what kind of sets dance competitions require. He reaches out to people through Zaid and Omi.",
            "hi": "डान्स और हिप-हॉप में उसके अनुभव के कारण, उसे कल्पना है कि डान्स की स्पर्धा के लिए किस तरह के सेट की आवश्यकता होती है। वह जाएद और ओमी के जरिए कुछ लोगों से संपर्क करता है।",
            "ka": "ನೃತ್ಯ ಮತ್ತು ಹಿಪ್-ಹಾಪ್‌ನಲ್ಲಿನ ಅವನ ಅನುಭವವು ನೃತ್ಯ ಸ್ಪರ್ಧೆಗಳಿಗೆ ಯಾವ ರೀತಿಯ ಸೆಟ್‌ಗಳ ಅಗತ್ಯವಿದೆ ಎಂಬುದರ ಕುರಿತು ಅವರಿಗೆ ಕಲ್ಪನೆಯನ್ನು ನೀಡುತ್ತದೆ. ಅವನು ಝೈದ್ ಮತ್ತು ಓಮಿ ಮೂಲಕ ಜನರನ್ನು ತಲುಪುತ್ತಾನೆ.",
            "te": "డ్యాన్స్ మరియు హిప్-హాప్‌లో అతని అనుభవం డ్యాన్స్ పోటీలకు ఎలాంటి సెట్‌లు అవసరమో అతనికి ఒక ఆలోచన ఇస్తుంది. అతను జైద్ మరియు ఓమి ద్వారా ప్రజలకు చేరువయ్యాడు.",
            "or": "ନୃତ୍ୟ ଏବଂ ହିପ୍-ହପ୍ କ୍ଷେତ୍ରରେ ତାଙ୍କର ଅଭିଜ୍ଞତା ତାଙ୍କୁ କେଉଁ ପ୍ରକାରର ନୃତ୍ୟ ପ୍ରତିଯୋଗିତା ଆବଶ୍ୟକ କରେ ସେ ସମ୍ବନ୍ଧରେ ଏକ ଧାରଣା ଦେଇଥାଏ | ସେ ଜାଏଦ୍‌ ଏବଂ ଓମି ମାଧ୍ୟମରେ ଲୋକଙ୍କ ନିକଟରେ ପହଞ୍ଚନ୍ତି |",
            "as": "নৃত্য আৰু হিপ-হপৰ তাৰ অভিজ্ঞতাই তাক কেনে ধৰণৰ ছেট নৃত্য প্ৰতিযোগিতাৰ বাবে প্ৰয়োজন সেই সম্পৰ্কে এক ধাৰণা দিয়ে। সি জাইদ আৰু অমিৰ জৰিয়তে মানুহৰ ওচৰলৈ যায়।",
            "gu": "નૃત્ય અને હિપ-હોપનો તેણીનો અનુભવ તેણીને નૃત્ય સ્પર્ધાઓ માટે કયા સેટની જરૂર છે તેનો ખ્યાલ આપે છે. તે ઝાઈદે અને ઓમી દ્વારા લોકો સુધી પહોંચે છે."
          }
        }
      ],
      "page14": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_5_3",
            "hi": "C11_5_3",
            "ka": "C11_5_3",
            "te": "C11_5_3",
            "or": "C11_5_3",
            "as": "C11_5_3",
            "gu": "C11_5_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "One of his first assignments is to create a set for a local talent competition. It comes from one of the organisers who have heard about their dance crew through social media.",
            "hi": "उसका सबसे पहला असाइनमेंट है, एक लोकल टैलेंट स्पर्धा के लिए सेट तैयार करना। यह काम उसे एक ऑर्गेनाइजर के द्वारा मिलता है, जिसने उनके डान्स ग्रुप के बारे में सोशल मीडिया पर पढ़ा था।",
            "ka": "ಸ್ಥಳೀಯ ಪ್ರತಿಭಾ ಸ್ಪರ್ಧೆಗೆ ಒಂದು ಸೆಟ್ ಅನ್ನು ರಚಿಸುವುದು ಅವನ ಮೊದಲ ಕಾರ್ಯಯೋಜನೆಗಳಲ್ಲಿ ಒಂದಾಗಿದೆ. ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮದ ಮೂಲಕ ಅವರ ನೃತ್ಯ ತಂಡದ ಬಗ್ಗೆ ಕೇಳಿದ ಸಂಘಟಕರೊಬ್ಬರು ಇದು ಬಂದಿದೆ.",
            "te": "స్థానిక ప్రతిభ పోటీ కోసం ఒక సెట్‌ను రూపొందించడం అతని మొదటి అసైన్‌మెంట్‌లలో ఒకటి. ఇది సోషల్ మీడియా ద్వారా వారి డ్యాన్స్ సిబ్బంది గురించి విన్న ఆర్గనైజర్స్ నుండి వచ్చింది.",
            "or": "ତାଙ୍କର ପ୍ରଥମ କାର୍ଯ୍ୟଗୁଡ଼ିକ ମଧ୍ୟରୁ ଗୋଟିଏ ହେଉଛି ଏକ ସ୍ଥାନୀୟ ପ୍ରତିଭା ପ୍ରତିଯୋଗିତା ପାଇଁ ଏକ ସେଟ୍ ସୃଷ୍ଟି କରିବା | ସୋସିଆଲ ମିଡିଆ ମାଧ୍ୟମରେ ସେମାନଙ୍କ ନୃତ୍ଯ କ୍ରିୟୁ ବିଷୟରେ ଶୁଣିଥିବା ଜଣେ ସଂଗଠକଙ୍କଠାରୁ ଏହା ଆସିଛି |",
            "as": "তাৰ প্ৰথম কামবোৰৰ ভিতৰত এটা হ'ল স্থানীয় প্ৰতিভা প্ৰতিযোগিতাৰ বাবে এটা ছেট তৈয়াৰ কৰা। এইটো এজন আয়োজকৰ পৰা আহিছে যিয়ে সামাজিক মাধ্যমৰ জৰিয়তে তেওঁলোকৰ নৃত্য কৰ্মীৰ বিষয়ে শুনিছে।",
            "gu": "સ્થાનિક ટેલેન્ટ શો માટે સેટ બનાવવો એ તેના પ્રથમ કાર્યોમાંનું એક છે. આ આયોજકોમાંથી એક તરફથી આવે છે જેણે સોશિયલ મીડિયા દ્વારા તેના ડાન્સના સ્ભયો વિશે સાંભળ્યું હતું."
          }
        }
      ],
      "page15": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_5_4",
            "hi": "C11_5_4",
            "ka": "C11_5_4",
            "te": "C11_5_4",
            "or": "C11_5_4",
            "as": "C11_5_4",
            "gu": "C11_5_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "After Naveen designs and constructs the set, the organisers are highly impressed with what he has created.",
            "hi": "जब नवीन सेट डिजाइन करके तैयार करता है, तब उसके बनाए हुए सेट को देखकर ऑर्गेनाइजर्स बहुत खुश होते हैं।",
            "ka": "ನವೀನ್ ಸೆಟ್ ಅನ್ನು ವಿನ್ಯಾಸಗೊಳಿಸಿದ ಮತ್ತು ನಿರ್ಮಿಸಿದ ನಂತರ, ಅವನು ರಚಿಸಿದ ವಿಷಯದಿಂದ ಸಂಘಟಕರು ಹೆಚ್ಚು ಪ್ರಭಾವಿತರಾಗಿದ್ದಾರೆ.",
            "te": "నవీన్ సెట్‌ని డిజైన్ చేసి, నిర్మించిన తర్వాత, అతను రూపొందించిన దానితో నిర్వాహకులు బాగా ఆకట్టుకున్నారు.",
            "or": "ନବୀନ ସେଟ୍କୁ ଡିଜାଇନ୍ ଏବଂ ନିର୍ମାଣ କରିବା ପରେ, ସେ ଯାହା ସୃଷ୍ଟି କରିଛନ୍ତି ସେଥିରେ ଆୟୋଜକମାନେ ଅତ୍ୟଧିକ ପ୍ରଭାବିତ ହୁଅନ୍ତି |",
            "as": "নবীনে ছেটটো ডিজাইন আৰু নিৰ্মাণ কৰাৰ পিছত, আয়োজকসকলে তাৰ কামত যথেষ্ট প্ৰভাৱিত হয়।",
            "gu": "નવીને સેટ ડિઝાઇન અને બનાવ્યા પછી, આયોજકોએ જે બનાવ્યું છે તેનાથી ખૂબ જ પ્રભાવિત થયા છે."
          }
        }
      ],
      "page16": [
        {
          "valuePath": "display",
          "value": {
            "en": "C11_5_5",
            "hi": "C11_5_5",
            "ka": "C11_5_5",
            "te": "C11_5_5",
            "or": "C11_5_5",
            "as": "C11_5_5",
            "gu": "C11_5_5"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Word quickly spreads, and Naveen gets many more offers to create set designs for events across Bangalore. Naveen is pleased with the way his career is shaping up.",
            "hi": "बहुत जल्द उसके काम के बारे में लोगों को पता चलता है और नवीन को बेंगलुरु में अलग-अलग इवेंट के लिए सेट डिजाइन करने और तैयार करने के लिए ऑफर मिलते हैं। अपने करियर की कामयाबी को लेकर नवीन खुश है।",
            "ka": "ಮಾತು ತ್ವರಿತವಾಗಿ ಹರಡುತ್ತದೆ ಮತ್ತು ಬೆಂಗಳೂರಿನಾದ್ಯಂತ ಈವೆಂಟ್‌ಗಳಿಗೆ ಸೆಟ್ ವಿನ್ಯಾಸಗಳನ್ನು ರಚಿಸಲು ನವೀನ್ ಹೆಚ್ಚಿನ ಕೊಡುಗೆಗಳನ್ನು ಪಡೆಯುತ್ತಾನೆ. ನವೀನ್ ತನ್ನ ವೃತ್ತಿಜೀವನವನ್ನು ರೂಪಿಸುವ ರೀತಿಯಲ್ಲಿ ಸಂತಸಗೊಂಡಿದ್ದಾನೆ.",
            "te": "మాట త్వరగా వ్యాపిస్తుంది మరియు బెంగళూరు అంతటా ఈవెంట్‌ల కోసం సెట్ డిజైన్‌లను రూపొందించడానికి నవీన్ మరిన్ని ఆఫర్‌లను పొందాడు. నవీన్ తన కెరీర్‌ను తీర్చిదిద్దుతున్న తీరు పట్ల సంతోషం వ్యక్తం చేశాడు.",
            "or": "କଥା ଶୀଘ୍ର ବିସ୍ତାର ହୁଏ, ଏବଂ ବାଙ୍ଗାଲୋରରେ ଇଭେଣ୍ଟଗୁଡିକ ପାଇଁ ସେଟ୍ ଡିଜାଇନ୍ ସୃଷ୍ଟି କରିବାକୁ ନବୀନ ଆହୁରି ଅନେକ ଅଫର୍ ପାଇଲେ | ତାଙ୍କ କ୍ୟାରିୟରର ରୂପରେଖରେ ନବୀନ ସନ୍ତୁଷ୍ଟ |",
            "as": "কথাটো দ্ৰুতগতিত বিয়পি পৰে, আৰু নবীনে বাংগালোৰৰ অনুষ্ঠানবোৰৰ বাবে নিৰ্ধাৰিত ডিজাইন সৃষ্টি কৰিবলৈ আৰু বহুতো অফাৰ লাভ কৰে। নবীন তাৰ জীৱিকা যিধৰণে গঢ় লৈ উঠিছে তাত সন্তুষ্ট।",
            "gu": "આ વાત ઝડપથી ફેલાઈ ગઈ અને નવીનને સમગ્ર બેંગલોરમાં ઈવેન્ટ્સ માટે સેટ બનાવવા માટે ઘણી વધુ તકો મળી. નવીન તેની કારકિર્દીના વિકાસથી ખુશ છે."
          }
        }
      ],
      "page17": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Freelance Set Designer",
            "hi": "फ्रीलांस सेट डिजाइनर",
            "ka": "**THREAD_END**_ಫ್ರೀಲಾನ್ಸ್ ಸೆಟ್ ಡಿಸೈನರ್",
            "te": "**THREAD_END**_ఫ్రీలాన్స్ సెట్ డిజైనర్",
            "or": "**THREAD_END**_Freelance Set Designer",
            "as": "**থ্ৰেড_সমাপ্ত**_ফ্ৰিলেন্স ছেট ডিজাইনাৰ",
            "gu": "**THREAD_END**_ફ્રીલાન્સ સેટ ડિઝાઇનર"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Naveen that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने नवीन के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँचा है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकता है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n  ನೀವು ನವೀನ್‌ಗಾಗಿ ಮಾಡಿರುವ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు నవీన్ కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ !\n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି !\n \n ଆପଣ ନବୀନଙ୍କ ପାଇଁ ବାଛିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତି ଘଟାଇଛି | ଆପଣ କରୁଥିବା ପସନ୍ଦ ଉପରେ ଆଧାର କରି ଭିନ୍ନ ଭିନ୍ନ ସମାପ୍ତି ଅଛି | ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକୁ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ?",
            "as": "অভিনন্দন! তুমি এটা যাত্ৰা সমাপ্ত কৰিছা ৷ তুমি নবীনৰ বাবে কৰা বাছনিবোৰৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত ভিত্তি কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ চাব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક મુસાફરી પૂરી કરી છે!\n\nતમે નવીન માટે પસંદગીઓ કરી છે જે આ સંભવિત અંત તરફ દોરી ગઈ છે. તમે જે પસંદગી કરી છે તેનાથી અલગ અલગ અંત છે. શું તમે બીજા દ્રશ્યો ખોલવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ରକୁ ଦେଖନ୍ତୁ |",
            "as": "মানচিত্ৰখনৰ পিনে চোৱা",
            "gu": "ચાલો નકશા પર એક નજર નાખીએ"
          }
        }
      ]
    },
    "scene24": {
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "Experimentation 1_Dial a Mechanic",
            "hi": "डायल ए मैकेनिक",
            "ka": "ಪ್ರಯೋಗ 1_ಡಯಲ್ ಎ ಮೆಕ್ಯಾನಿಕ್",
            "te": "ఎక్స్పెరిమెంటేషన్ 1_డయల్ ఎ మెకానిక్",
            "or": "Experimentation 1_Dial a Mechanic",
            "as": "পৰীক্ষা-নিৰীক্ষা 1_ ডায়েল এ মেকানিক",
            "gu": "પ્રયોગ 1_મિકેનિકને ડાયલ કરો"
          }
        }
      ],
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C12_1_1",
            "hi": "C12_1_1",
            "ka": "C12_1_1",
            "te": "C12_1_1",
            "or": "C12_1_1",
            "as": "C12_1_1",
            "gu": "C12_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen downloads the Dial-A-Mechanic partner app and creates his account.",
            "hi": "नवीन डायल-ए-मैकेनिक पार्टनर एप डाउनलोड करता है और खुद का अकाउंट बनाता है।",
            "ka": "ನವೀನ್ ಡಯಲ್-ಎ-ಮೆಕ್ಯಾನಿಕ್ ಪಾಲುದಾರ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡುತ್ತಾನೆ ಮತ್ತು ಅವನ ಖಾತೆಯನ್ನು ರಚಿಸುತ್ತಾನೆ.",
            "te": "నవీన్ డయల్-ఎ-మెకానిక్ పార్టనర్ యాప్‌ని డౌన్‌లోడ్ చేసి, తన అకౌంట్ ను క్రియేట్ చేసుకుంటాడు.",
            "or": "ନବୀନ ଡାଏଲ୍-ଏ-ମେକାନିକ୍ ପାର୍ଟନର ଆପ୍ ଡାଉନଲୋଡ୍ କରନ୍ତି ଏବଂ ତାଙ୍କ ଆକାଉଣ୍ଟ୍ ତିଆରି କରନ୍ତି |",
            "as": "নবীনে ডায়েল-এ-মেকানিক অংশীদাৰ এপটো ডাউনলোড কৰে আৰু তাৰ একাউণ্ট এটা খোলে।",
            "gu": "નવીન એપ ડાઉનલોડ કરે છે અને તેનું એકાઉન્ટ બનાવે છે ડાયલ-એ-મેકેનિક પાર્ટનર પર."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C12_1_2",
            "hi": "C12_1_2",
            "ka": "C12_1_2",
            "te": "C12_1_2",
            "or": "C12_1_2",
            "as": "C12_1_2",
            "gu": "C12_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Once he is registered, he gives a telephonic interview to verify his details. Naveen is then called for a 2 week-long training course.",
            "hi": "रजिस्टर करने के बाद वह अपने डिटेल्स को वेरीफाई करने के लिए टेलीफोन पर इंटरव्यू देता है। इसके बाद नवीन को 2 हफ्ते के ट्रेनिंग कोर्स के लिए बुलाया जाता है।",
            "ka": "ಅವನು ನೋಂದಾಯಿಸಿದ ನಂತರ, ಅವನು ತನ್ನ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಲು ಟೆಲಿಫೋನಿಕ್ ಸಂದರ್ಶನವನ್ನು ನೀಡುತ್ತಾನೆ. ನಂತರ ನವೀನ್ ನನ್ನು 2 ವಾರಗಳ ತರಬೇತಿ ಕೋರ್ಸ್‌ಗೆ ಕರೆಯುತ್ತಾರೆ.",
            "te": "అతను నమోదు చేసుకున్న తర్వాత, అతను తన వివరాలను ధృవీకరించడానికి టెలిఫోనిక్ ఇంటర్వ్యూ ఇస్తాడు. నవీన్‌ను 2 వారాల శిక్షణా కోర్సుకు పిలుస్తారు.",
            "or": "ଥରେ ସେ ପଞ୍ଜୀକୃତ ହୋଇଗଲେ, ସେ ତାଙ୍କର ସବିଶେଷ ତଥ୍ୟ ଯାଞ୍ଚ କରିବାକୁ ଏକ ଟେଲିଫୋନିକ୍ ସାକ୍ଷାତକାର ଦିଅନ୍ତି | ଏହା ପରେ ନବୀନଙ୍କୁ 2 ସପ୍ତାହ ବ୍ୟାପୀ ତାଲିମ ପାଠ୍ୟକ୍ରମ ପାଇଁ ଡକାଯାଏ |",
            "as": "সি পঞ্জীয়ন হোৱাৰ পিছত, তাৰ বিৱৰণ পৰীক্ষা কৰাবলৈ টেলিফোনিক সাক্ষাৎকাৰ দিয়ে। তাৰ পিছত নবীনক 2 সপ্তাহৰ দীঘলীয়া এক প্ৰশিক্ষণ পাঠ্যক্ৰমৰ বাবে মাতা হয়।",
            "gu": "એકવાર નોંધણી કરાવ્યા પછી, તે તેની વિગતો ચકાસવા માટે ફોન પર ઇન્ટરવ્યુ આપે છે. ત્યાર પછી નવીનને બે અઠવાડીયાના ટ્રેનિંગ કોર્સમાં બોલાવવામાં આવે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "C12_2_1",
            "hi": "C12_2_1",
            "ka": "C12_2_1",
            "te": "C12_2_1",
            "or": "C12_2_1",
            "as": "C12_2_1",
            "gu": "C12_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen’s trainer has the experience of working at the service centres of big brands for 10 years and has been a trainer at Dial-A-Mechanic for the past 1 year.",
            "hi": "नवीन के प्रशिक्षक को 10 साल एक बड़े ब्रांड के साथ सर्विस सेंटर में काम करने का अनुभव है और वह पिछले 1 साल से डायल-ए-मैकेनिक में प्रशिक्षक के तौर पर काम कर रहा है।",
            "ka": "ನವೀನ್ ಅವರ ತರಬೇತುದಾರರು 10 ವರ್ಷಗಳಿಂದ ದೊಡ್ಡ ಬ್ರ್ಯಾಂಡ್‌ಗಳ ಸೇವಾ ಕೇಂದ್ರಗಳಲ್ಲಿ ಕೆಲಸ ಮಾಡಿದ ಅನುಭವವನ್ನು ಹೊಂದಿದ್ದಾರೆ ಮತ್ತು ಕಳೆದ 1 ವರ್ಷದಿಂದ ಡಯಲ್-ಎ-ಮೆಕಾನಿಕ್‌ನಲ್ಲಿ ತರಬೇತುದಾರರಾಗಿದ್ದಾರೆ.",
            "te": "నవీన్ ట్రైనర్‌కు 10 సంవత్సరాలుగా పెద్ద బ్రాండ్‌ల సర్వీస్ సెంటర్‌లలో పనిచేసిన అనుభవం ఉంది మరియు గత 1 సంవత్సరం నుండి డయల్-ఎ-మెకానిక్‌లో ట్రైనర్‌గా ఉన్నారు.",
            "or": "ନବୀନଙ୍କ ଟ୍ରେନର୍‌ଙ୍କ ପାଖରେ 10 ବର୍ଷ ଧରି ବଡ ବ୍ରାଣ୍ଡର ସେବା କେନ୍ଦ୍ରରେ କାର୍ଯ୍ୟ କରିବାର ଅଭିଜ୍ଞତା ରହିଛି ଏବଂ ଗତ 1 ବର୍ଷ ଧରି Dial-A-Mechanic ରେ ଜଣେ ଟ୍ରେନର୍‌ ରହିଛନ୍ତି |",
            "as": "নবীনৰ প্ৰশিক্ষকজনৰ 10 বছৰ ধৰি ডাঙৰ ব্ৰেণ্ডৰ সেৱা কেন্দ্ৰত কাম কৰাৰ অভিজ্ঞতা আছে আৰু যোৱা 1 বছৰ ধৰি ডায়েল-এ-মেকানিকত প্ৰশিক্ষক হৈ আছে।",
            "gu": "તે ટ્રેનર પાસે 10 વર્ષથી મોટી બ્રાન્ડના સર્વિસ સેન્ટરમાં કામ કરવાનો અનુભવ છે અને નવીનના છેલ્લા 1 વર્ષથી ડાયલ-એ-મેકેનિકમાં ટ્રેનર છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C12_2_2//",
            "hi": "C12_2_2//",
            "ka": "C12_2_2//",
            "te": "C12_2_2//",
            "or": "C12_2_2//",
            "as": "C12_2_2//",
            "gu": "C12_2_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Trainer",
            "hi": "प्रशिक्षक",
            "ka": "ತರಬೇತುದಾರ",
            "te": "శిక్షకుడు",
            "or": "ଟ୍ରେନର",
            "as": "প্ৰশিক্ষক",
            "gu": "ટ્રેનર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Trainer",
            "hi": "प्रशिक्षक",
            "ka": "ತರಬೇತುದಾರ",
            "te": "శిక్షకుడు",
            "or": "ଟ୍ରେନର",
            "as": "প্ৰশিক্ষক",
            "gu": "ટ્રેનર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Now that we have looked over the safety procedures, let us talk about handling customer complaints.\nYou should remember that unresolved customer complaints can lead to the cancellation of your registration.",
            "hi": "अब हमने सुरक्षा प्रक्रिया के बारे में सीख लिया हैं। आगे हम ग्राहकों की शिकायतों से निपटना सीखेंगे। \n तुम्हें यह बात ध्यान में रखनी होगी कि, ग्राहकों की शिकायत हल ना करने पर तुम्हारा रजिस्ट्रेशन कैंसिल हो सकता है।",
            "ka": "\"ಈಗ ನಾವು ಸುರಕ್ಷತಾ ಕಾರ್ಯವಿಧಾನಗಳನ್ನು ನೋಡಿದ್ದೇವೆ, ಗ್ರಾಹಕರ ದೂರುಗಳನ್ನು ನಿರ್ವಹಿಸುವ ಬಗ್ಗೆ ಮಾತನಾಡೋಣ.\n ಪರಿಹರಿಸದ ಗ್ರಾಹಕರ ದೂರುಗಳು ನಿಮ್ಮ ನೋಂದಣಿಯನ್ನು ರದ್ದುಗೊಳಿಸುವುದಕ್ಕೆ ಕಾರಣವಾಗಬಹುದು ಎಂಬುದನ್ನು ನೀವು ನೆನಪಿನಲ್ಲಿಟ್ಟುಕೊಳ್ಳಬೇಕು.\"",
            "te": "ఇప్పుడు మేము భద్రతా విధానాలను పరిశీలించాము, కస్టమర్ ఫిర్యాదులను నిర్వహించడం గురించి మాట్లాడుకుందాం.\n పరిష్కరించని కస్టమర్ ఫిర్యాదులు మీ రిజిస్ట్రేషన్ రద్దుకు దారితీస్తాయని మీరు గుర్తుంచుకోవాలి.",
            "or": "ବର୍ତ୍ତମାନ ଯେହେତୁ ଆମେ ସୁରକ୍ଷା ପଦ୍ଧତି ଉପରେ ଦୃଷ୍ଟି ରଖିଛୁ, ଆସନ୍ତୁ ଗ୍ରାହକଙ୍କ ଅଭିଯୋଗ ପରିଚାଳନା ବିଷୟରେ ଆଲୋଚନା କରିବା |\n ତୁମେ ମନେ ରଖିବା ଉଚିତ୍ ଯେ ସମାଧାନ ହୋଇନଥିବା ଗ୍ରାହକଙ୍କ ଅଭିଯୋଗ ଆପଣଙ୍କ ପଞ୍ଜୀକରଣ ବାତିଲ୍ କରିପାରେ |",
            "as": "এতিয়া যেতিয়া আমি সুৰক্ষা প্ৰক্ৰিয়াবোৰ চোৱা-চিতা কৰিছোঁ, আমি গ্ৰাহকৰ অভিযোগবোৰ নিয়ন্ত্ৰণ কৰাৰ বিষয়ে কথা পাতো আহাঁ। তুমি মনত ৰাখিব লাগে যে অমীমাংসিত গ্ৰাহকৰ অভিযোগৰ ফলত তোমাৰ পঞ্জীয়ন বাতিল হ'ব পাৰে।",
            "gu": "“હવે અમે સુરક્ષા પ્રક્રિયાઓ જોઈ છે, ચાલો ગ્રાહકની ફરિયાદોને નિયંત્રિત કરવા વિશે વાત કરીએ.\nતમારે ધ્યાનમાં રાખવું જોઈએ કે ગ્રાહકની ફરિયાદો ના ઉકેલાયેલી હોય તે તમારી નોંધણી રદ કરવામાં  આવી શકે છે.\""
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Sir, what to do if the customer is unresponsive or isn’t picking up calls?",
            "hi": "सर, अगर ग्राहक कोई प्रतिक्रिया ना दें या फोन ना उठाए तो क्या करना चाहिए?",
            "ka": "ಸರ್, ಗ್ರಾಹಕರು ಪ್ರತಿಕ್ರಿಯಿಸದಿದ್ದರೆ ಅಥವಾ ಕರೆಗಳನ್ನು ಪಿಕಪ್ ಮಾಡದಿದ್ದರೆ ಏನು ಮಾಡಬೇಕು?",
            "te": "సర్, కస్టమర్ స్పందించకపోతే లేదా కాల్స్ తీయకపోతే ఏమి చేయాలి?",
            "or": "ସାର୍, ଯଦି ଗ୍ରାହକ ଉତ୍ତରହୀନ ହୁଅନ୍ତି କିମ୍ବା କଲ୍ ଉଠାଉ ନାହାଁନ୍ତି ତେବେ କଣ କରିବି ?",
            "as": "চাৰ, যদি গ্ৰাহকে সঁহাৰি নিদিয়ে বা ফোন নধৰে তেন্তে কি কৰিব লাগিব?",
            "gu": "સાહેબ, જો ગ્રાહક જવાબ ન આપતો હોય અથવા કોલનો જવાબ ન આપતો હોય તો શું કરવું?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "After you have made 3 calls to a customer in a day and are still unresponsive, you need to inform the project manager as soon as possible.",
            "hi": "तुम्हें ग्राहक को 1 दिन में तीन कॉल करने होंगे। अगर वह फिर भी जवाब नहीं देता, तो तुम्हें इसके बारे में तुरंत अपने प्रोजेक्ट मैनेजर को बताना चाहिए।",
            "ka": "ಒಂದು ದಿನದಲ್ಲಿ ನೀವು ಗ್ರಾಹಕರಿಗೆ 3 ಕರೆಗಳನ್ನು ಮಾಡಿದ ನಂತರ ಮತ್ತು ಇನ್ನೂ ಪ್ರತಿಕ್ರಿಯಿಸದ ನಂತರ, ನೀವು ಸಾಧ್ಯವಾದಷ್ಟು ಬೇಗ ಪ್ರಾಜೆಕ್ಟ್ ಮ್ಯಾನೇಜರ್‌ಗೆ ತಿಳಿಸಬೇಕು.",
            "te": "మీరు ఒక రోజులో కస్టమర్‌కు 3 కాల్‌లు చేసిన తర్వాత మరియు ఇప్పటికీ స్పందించనట్లయితే, మీరు వీలైనంత త్వరగా ప్రాజెక్ట్ మేనేజర్‌కి తెలియజేయాలి.",
            "or": "ଆପଣ ଗୋଟିଏ ଦିନରେ ଜଣେ ଗ୍ରାହକଙ୍କୁ 3 ଟି କଲ କରିସାରିବା ପରେ ମଧ୍ୟ ଉତ୍ତରହୀନ ହେଲେ, ଆପଣଙ୍କୁ ପ୍ରୋଜେକ୍ଟ ମ୍ୟାନେଜର୍‌ଙ୍କୁ ଯଥାଶୀଘ୍ର ଜଣାଇବାକୁ ପଡିବ |",
            "as": "তুমি এদিনত গ্ৰাহক এজনক 3 টা ফোন কৰাৰ পিছতো তেওঁ যদি সঁহাৰি নিদিয়ে, তুমি যিমান পাৰি সোনকালে প্ৰকল্প মেনেজাৰক জনাব লাগিব।",
            "gu": "તમે ક્લાયન્ટને દિવસમાં 3 કોલ કરો અને તેમ છતાં કોઈ પ્રતિસાદ મળતો નથી, તમારે શક્ય તેટલી વહેલી તકે પ્રોજેક્ટ મેનેજરને સૂચિત કરવાની જરૂર છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "C12_2_3",
            "hi": "C12_2_3",
            "ka": "C12_2_3",
            "te": "C12_2_3",
            "or": "C12_2_3",
            "as": "C12_2_3",
            "gu": "C12_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "At the end of two weeks, Naveen gets a certificate of completion of training, and starts his job at Dial-A-Mechanic.",
            "hi": "दो हफ्तों के बाद नवीन को प्रशिक्षण पूरा करने का प्रमाण पत्र मिल जाता है और वह डायल-ए-मैकेनिक में जॉब करना शुरू करता है।",
            "ka": "ಎರಡು ವಾರಗಳ ಕೊನೆಯಲ್ಲಿ, ನವೀನ್ ತರಬೇತಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ ಪ್ರಮಾಣಪತ್ರವನ್ನು ಪಡೆಯುತ್ತಾನೆ ಮತ್ತು ಡಯಲ್-ಎ-ಮೆಕ್ಯಾನಿಕ್‌ನಲ್ಲಿ ತನ್ನ ಕೆಲಸವನ್ನು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ.",
            "te": "రెండు వారాల ముగింపులో, నవీన్ శిక్షణ పూర్తయినట్లు సర్టిఫికేట్ పొందాడు మరియు డయల్-ఎ-మెకానిక్‌లో తన ఉద్యోగాన్ని ప్రారంభించాడు.",
            "or": "ଦୁଇ ସପ୍ତାହ ଶେଷରେ, ନବୀନ ତାଲିମ ସମାପ୍ତ କରିବାର ଏକ ପ୍ରମାଣପତ୍ର ପାଆନ୍ତି, ଏବଂ Dial-A-Mechanic ରେ ଚାକିରି ଆରମ୍ଭ କରନ୍ତି |",
            "as": "দুসপ্তাহৰ শেষত, নবীনে প্ৰশিক্ষণ সম্পূৰ্ণ হোৱাৰ প্ৰমাণপত্ৰ লাভ কৰে, আৰু ডায়েল-এ-মেকানিকত কাম আৰম্ভ কৰে।",
            "gu": "બે અઠવાડિયાના અંતે, નવીનને તાલીમ પૂર્ણ થવાનું પ્રમાણપત્ર મળે છે, અને ડાયલ-એ-મેકેનિકમાં તેનું કામ શરૂ કરે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "C12_3_1",
            "hi": "C12_3_1",
            "ka": "C12_3_1",
            "te": "C12_3_1",
            "or": "C12_3_1",
            "as": "C12_3_1",
            "gu": "C12_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen takes time to get used to the app’s features, like accepting job requests and online payments.",
            "hi": "नवीन आपके सभी फीचर्स को अच्छे से सीखता है, जैसे कि, जॉब रिक्वेस्ट स्वीकार करना और ऑनलाइन पेमेंट प्राप्त करना।",
            "ka": "ಉದ್ಯೋಗ ವಿನಂತಿಗಳನ್ನು ಸ್ವೀಕರಿಸುವುದು ಮತ್ತು ಆನ್‌ಲೈನ್ ಪಾವತಿಗಳಂತಹ ಅಪ್ಲಿಕೇಶನ್‌ನ ವೈಶಿಷ್ಟ್ಯಗಳಿಗೆ ಬಳಸಿಕೊಳ್ಳಲು ನವೀನ್ ಸಮಯವನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾನೆ.",
            "te": "ఉద్యోగ అభ్యర్థనలను అంగీకరించడం మరియు ఆన్‌లైన్ చెల్లింపులు వంటి యాప్ ఫీచర్‌లను అలవాటు చేసుకోవడానికి నవీన్ సమయం తీసుకుంటాడు.",
            "or": "କାର୍ଯ୍ୟ ଅନୁରୋଧ ଏବଂ ଅନ୍‌ଲାଇନ୍ ପେମେଣ୍ଟ ଗ୍ରହଣ କରିବା ପରି ନବୀନ ଆପ୍‌ର ବୈଶିଷ୍ଟ୍ୟରେ ଅଭ୍ୟସ୍ତ ହେବା ପାଇଁ ସମୟ ନିଅନ୍ତି |",
            "as": "নবীনে এপৰ সুবিধাবোৰৰ সৈতে অভ্যস্ত হ'বলৈ সময় লয়, যেনে কামৰ অনুৰোধ গ্ৰহণ কৰা আৰু অনলাইন পৰিশোধ কৰা।",
            "gu": "નવીન એપની વિશેષતાઓમાં ટેવાઈ જવા માટે સમય લે છે, જેમ કે નોકરીની વિનંતીઓ સ્વીકારવી અને ઓનલાઈન પેમેન્ટ."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "C12_3_2",
            "hi": "C12_3_2",
            "ka": "C12_3_2",
            "te": "C12_3_2",
            "or": "C12_3_2",
            "as": "C12_3_2",
            "gu": "C12_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Slowly through practice, he gets used to the system. Each month the number of clients he attends increases steadily.",
            "hi": "धीरे-धीरे प्रेक्टिस के साथ वह इस सिस्टम को समझ जाता है। हर महीने उसके ग्राहकों की संख्या बढ़ती रहती है।",
            "ka": "ನಿಧಾನವಾಗಿ ಅಭ್ಯಾಸದ ಮೂಲಕ ವ್ಯವಸ್ಥೆಗೆ ಒಗ್ಗಿಕೊಳ್ಳುತ್ತಾನೆ. ಪ್ರತಿ ತಿಂಗಳು ಅವನು ಹಾಜರಾಗುವ ಗ್ರಾಹಕರ ಸಂಖ್ಯೆ ಸ್ಥಿರವಾಗಿ ಹೆಚ್ಚಾಗುತ್ತದೆ.",
            "te": "మెల్లమెల్లగా ప్రాక్టీస్‌ ద్వారా వ్యవస్థకు అలవాటు పడిపోతాడు. ప్రతి నెలా అతను హాజరయ్యే ఖాతాదారుల సంఖ్య క్రమంగా పెరుగుతోంది.",
            "or": "ଅଭ୍ୟାସ ମାଧ୍ୟମରେ ଧୀରେ ଧୀରେ ସେ ସିଷ୍ଟମରେ ଅଭ୍ୟସ୍ତ | ପ୍ରତ୍ୟେକ ମାସରେ ସେ ଧ୍ଯାନଦେଇପାରୁଥିବା କ୍ଲାଏଣ୍ଟ୍‌ଙ୍କ ସଂଖ୍ୟା କ୍ରମାଗତ ଭାବରେ ବୃଦ୍ଧି ପାଇଥାଏ |",
            "as": "লাহে লাহে অনুশীলনৰ জৰিয়তে, সি প্ৰণালীটোৰ সৈতে অভ্যস্ত হৈ পৰে। প্ৰতি মাহে তাৰ ওচৰত উপস্থিত থকা গ্ৰাহকৰ সংখ্যা ক্ৰমান্বয়ে বৃদ্ধি পায়।",
            "gu": "ધીમે ધીમે પ્રેક્ટિસ સાથે તે સિસ્ટમથી ટેવાઈ ગયો. દર મહિને, બધા સુધી સેવા પહોંચતા ગ્રાહકોની સંખ્યા સતત વધતી જાય છે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "C12_3_3",
            "hi": "C12_3_3",
            "ka": "C12_3_3",
            "te": "C12_3_3",
            "or": "C12_3_3",
            "as": "C12_3_3",
            "gu": "C12_3_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "His good behaviour with customers also gets him good ratings, and as a result, his income increases every month.",
            "hi": "ग्राहकों के साथ उसके अच्छे बर्ताव के कारण उसे अच्छी रेटिंग मिलती है और इसके परिणामस्वरूप उसकी इन्कम भी हर महीने बढ़ जाती है।",
            "ka": "ಗ್ರಾಹಕರೊಂದಿಗೆ ಅವನ ಉತ್ತಮ ನಡವಳಿಕೆಯು ಅವನಿಗೆ ಉತ್ತಮ ರೇಟಿಂಗ್‌ಗಳನ್ನು ಸಹ ಕೊಡುತ್ತದೆ ಮತ್ತು ಇದರ ಪರಿಣಾಮವಾಗಿ, ಅವನ ಆದಾಯವು ಪ್ರತಿ ತಿಂಗಳು ಹೆಚ್ಚಾಗುತ್ತದೆ.",
            "te": "కస్టమర్‌లతో అతని మంచి ప్రవర్తన కూడా అతనికి మంచి రేటింగ్‌లను ఇస్తుంది మరియు ఫలితంగా, అతని జీతం ప్రతి నెల పెరుగుతుంది.",
            "or": "ଗ୍ରାହକଙ୍କ ସହିତ ତାଙ୍କର ଭଲ ଆଚରଣ ତାଙ୍କୁ ଭଲ ରେଟିଂ ମଧ୍ୟ ଦେଇଥାଏ ଏବଂ ଫଳସ୍ୱରୂପ, ତାଙ୍କର ଆୟ ପ୍ରତି ମାସ ବଢିଥାଏ |",
            "as": "গ্ৰাহকৰ সৈতে তাৰ ভাল আচৰণৰ বাবে সি ভাল ৰেটিং পায়, আৰু ইয়াৰ ফলস্বৰূপে, প্ৰতি মাহে তাৰ উপাৰ্জন বৃদ্ধি হয়।",
            "gu": "એના સારા સ્વભાવના કારણે ગ્રાહકો તેને સારો પ્રતીભાવ આપે છે, તેનાથી તેના પગારમાં વધારો થાય છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "C12_3_4",
            "hi": "C12_3_4",
            "ka": "C12_3_4",
            "te": "C12_3_4",
            "or": "C12_3_4",
            "as": "C12_3_4",
            "gu": "C12_3_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen’s family is impressed at the setup of his job and the use of technology. They’re happy that Naveen can adjust to the changing times.",
            "hi": "नवीन के परिवार को उसके इस जॉब पर और टेक्नोलॉजी के इस्तेमाल करने पर गर्व है। वे खुश है क्योंकि नवीन अपने समय को जरूरत के अनुसार एडजस्ट कर सकता है।",
            "ka": "ನವೀನ್ ಕುಟುಂಬವು ಅವನ ಕೆಲಸದ ಸೆಟಪ್ ಮತ್ತು ತಂತ್ರಜ್ಞಾನದ ಬಳಕೆಯಿಂದ ಪ್ರಭಾವಿತವಾಗಿದೆ. ಬದಲಾದ ಕಾಲಕ್ಕೆ ನವೀನ್ ಹೊಂದಿಕೊಳ್ಳಬಲ್ಲರು ಎಂದು ಅವರು ಸಂತೋಷಪಡುತ್ತಾರೆ.",
            "te": "నవీన్ కుటుంబం అతని ఉద్యోగం యొక్క సెటప్ మరియు సాంకేతిక పరిజ్ఞానాన్ని ఉపయోగించి ఆకట్టుకుంది. మారుతున్న కాలానికి అనుగుణంగా నవీన్‌ సర్దుకుపోతాడని సంతోషిస్తున్నారు.",
            "or": "ତାଙ୍କ କାର୍ଯ୍ୟର ସେଟଅପ୍ ଏବଂ ଟେକ୍ନୋଲୋଜିର ବ୍ୟବହାରରେ ନବୀନଙ୍କ ପରିବାର ପ୍ରଭାବିତ ହୋଇଛନ୍ତି | ସେମାନେ ଖୁସି ଯେ ନବୀନ ପରିବର୍ତ୍ତିତ ସମୟ ସହିତ ଆଡଜଷ୍ଟ କରିପାରିବେ |",
            "as": "নবীনৰ পৰিয়ালে তাৰ চাকৰিৰ কাৰুকাৰ্য্য আৰু প্ৰযুক্তিৰ ব্যৱহাৰত প্ৰভাৱিত হয়। তেওঁলোক সুখী যে নবীনে পৰিৱৰ্তনশীল সময়ৰ সৈতে খাপ খাব পাৰে।",
            "gu": "નવીનનું કુટુંબ આ સેટઅપથી અને ટેક્નોલોજી શીખવાથી ખુશ થઈ જાય છે. તે એટલા માટે ખુશ છે કારણ કે તે આ કઠિન સમયના પ્રમાણે વિચારે છે ."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "FutureofWork",
            "hi": "फ्यूचर ऑफ वर्क",
            "ka": "ಫ್ಯೂಚರ್ ಆಫ್ ವರ್ಕ್",
            "te": "పని యొక్క భవిష్యత్తు",
            "or": "କାର୍ଯ୍ୟର ଭବିଷ୍ୟତ",
            "as": "কামৰভৱিষ্যত",
            "gu": "કાર્યનું ભવિષ્ય"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained knowledge about Future of Work!",
            "hi": "आपने अभी-अभी फ्यूचर ऑफ वर्क के बारे में अनुभव प्राप्त किया हैं!",
            "ka": "ನೀವು ಕೆಲಸದ ಭವಿಷ್ಯದ ಬಗ್ಗೆ ಜ್ಞಾನವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు పని యొక్క భవిష్యత్తు గురించి ఇప్పుడే జ్ఞానాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ କାର୍ଯ୍ୟର ଭବିଷ୍ୟତ ବିଷୟରେ ଜ୍ଞାନ ହାସଲ କରିଲେ !",
            "as": "তুমি এইমাত্ৰ কামৰ ভৱিষ্যতৰ বিষয়ে জ্ঞান লাভ কৰিলা!",
            "gu": "તમે હમણાં જ કાર્યના ભવિષ્ય વિશે જ્ઞાન મેળવ્યું છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Future of Work",
            "hi": "फ्यूचर ऑफ वर्क",
            "ka": "ಫ್ಯೂಚರ್ ಆಫ್ ವರ್ಕ್",
            "te": "పని యొక్క భవిష్యత్తు",
            "or": "କାର୍ଯ୍ୟର ଭବିଷ୍ୟତ",
            "as": "কামৰ ভৱিষ্যত",
            "gu": "કાર્યનું ભવિષ્ય"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Understanding Work Environment",
            "hi": "काम के माहौल को समझना",
            "ka": "ಕೆಲಸದ ಪರಿಸರವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು",
            "te": "పని వాతావరణాన్ని అర్థం చేసుకోవడం",
            "or": "କାର୍ଯ୍ୟ ପରିବେଶ ବୁଝିବା",
            "as": "কামৰ পৰিৱেশ বুজা",
            "gu": "કાર્યના વાતાવરણને સમજવું"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Naveen feels that it is essential for him to keep up with the changing times to stay updated with the job opportunities by following the new work trends. As the world progresses, especially with new technologies and the internet, the type of jobs and how people work keeps changing. New opportunities unlock that are not available or known even a few months ago. Even if you are on a specific career path, it is essential to be open to new ways of working.",
            "hi": "नवीन को लगता है कि काम के नए नए ट्रेंड्स को फॉलो करके, बदलते ज़माने में जॉब के अवसरों के साथ अपडेट रहना जरूरी है। जैसे-जैसे दुनिया प्रगति करती है, खासतौर पर नई टेक्नोलॉजी और इंटरनेट के साथ, वैसे ही लोगों के काम करने का तरीका और जॉब के प्रकार बदलते रहते हैं। अब काम के ऐसे नए अवसर उपलब्ध हैं जो कुछ महीने पहले उपलब्ध नहीं थे। किसी विशेष कैरियर को चुनने के बाद भी आपको काम करने के नए-नए तरीकों के बारे में जानकारी रखना आवश्यक है।",
            "ka": "ಹೊಸ ಕೆಲಸದ ಟ್ರೆಂಡ್‌ಗಳನ್ನು ಅನುಸರಿಸುವ ಮೂಲಕ ಉದ್ಯೋಗಾವಕಾಶಗಳೊಂದಿಗೆ ನವೀಕೃತವಾಗಿರಲು ಬದಲಾಗುತ್ತಿರುವ ಕಾಲಕ್ಕೆ ತಕ್ಕಂತೆ ಮುಂದುವರಿಯುವುದು ಅತ್ಯಗತ್ಯ ಎಂದು ನವೀನ್ ಅಭಿಪ್ರಾಯಪಟ್ಟಿದ್ದಾನೆ. ಜಗತ್ತು ಮುಂದುವರೆದಂತೆ, ವಿಶೇಷವಾಗಿ ಹೊಸ ತಂತ್ರಜ್ಞಾನಗಳು ಮತ್ತು ಇಂಟರ್ನೆಟ್‌ನೊಂದಿಗೆ, ಉದ್ಯೋಗಗಳ ಪ್ರಕಾರ ಮತ್ತು ಜನರು ಹೇಗೆ ಕೆಲಸ ಮಾಡುತ್ತಾರೆ ಎಂಬುದು ಬದಲಾಗುತ್ತಲೇ ಇರುತ್ತದೆ. ಕೆಲವು ತಿಂಗಳುಗಳ ಹಿಂದೆಯೂ ಲಭ್ಯವಿಲ್ಲದ ಅಥವಾ ತಿಳಿದಿಲ್ಲದ ಹೊಸ ಅವಕಾಶಗಳನ್ನು ಅನ್‌ಲಾಕ್ ಆಗುತ್ತದೆ.ನೀವು ನಿರ್ದಿಷ್ಟ ವೃತ್ತಿಜೀವನದ ಹಾದಿಯಲ್ಲಿದ್ದರೂ ಸಹ, ಕೆಲಸದ ಹೊಸ ವಿಧಾನಗಳಿಗೆ ತೆರೆದುಕೊಳ್ಳುವುದು ಅತ್ಯಗತ್ಯ.",
            "te": "కొత్త పని ట్రెండ్‌లను అనుసరించడం ద్వారా మారుతున్న కాలానికి అనుగుణంగా ఉండే ఉద్యోగ అవకాశాల గురించి అప్‌డేట్ గా తనకు చాలా అవసరమని నవీన్ భావిస్తున్నాడు. ప్రపంచం ముఖ్యంగా కొత్త సాంకేతికతలు మరియు ఇంటర్నెట్‌తో అభివృద్ధి చెందుతున్నప్పుడు, ఉద్యోగాల తీరు మరియు వ్యక్తులు పని చేసే విధానం మారుతూనే ఉంటుంది. కొన్ని నెలల క్రితం కూడా అందుబాటులో లేని లేదా తెలియని కొత్త అవకాశాలు అన్‌లాక్ చేయబడతాయి. మీరు ఒక నిర్దిష్ట కెరీర్ మార్గంలో ఉన్నప్పటికీ, కొత్త పనితీరుల గురించి తెలుసుకోవడం చాలా అవసరం.",
            "or": "ନବୀନ ଅନୁଭବ କରନ୍ତି ଯେ ନୂତନ କାର୍ଯ୍ୟ ଟ୍ରେଣ୍ଡକୁ ଅନୁସରଣ କରି ଚାକିରି ସୁଯୋଗ ସହିତ ଅଦ୍ୟତନ ହେବା ପାଇଁ ପରିବର୍ତ୍ତିତ ସମୟ ସହିତ ତାଳ ଦେବା ତାଙ୍କ ପାଇଁ ଜରୁରୀ ଅଟେ | ବିଶ୍ବ ଅଗ୍ରଗତି କଲାବେଳେ, ବିଶେଷତଃ ନୂତନ ଟେକ୍ନୋଲୋଜି ଏବଂ ଇଣ୍ଟରନେଟ୍ ସହିତ, ଚାକିରିର ପ୍ରକାର ଏବଂ ଲୋକମାନେ କିପରି କାର୍ଯ୍ୟ କରନ୍ତି ତାହା ବଦଳିବାରେ ଲାଗେ | ନୂତନ ସୁଯୋଗଗୁଡିକ ଅନଲକ୍ ହୁଏ ଯାହାକି କିଛି ମାସ ପୂର୍ବରୁ ଉପଲବ୍ଧ ନଥିଲା କିମ୍ବା ଜଣାନଥିଲା | ଯଦିଓ ଆପଣ ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ କ୍ୟାରିଅର୍ ପଥରେ ଅଛନ୍ତି, କାର୍ଯ୍ୟ କରିବାର ନୂତନ ଉପାୟ ପାଇଁ ଖୋଲା ରହିବା ଜରୁରୀ |",
            "as": "নবীনে অনুভৱ কৰে যে নতুন কামৰ প্ৰৱণতা অনুসৰণ কৰি চাকৰিৰ সুযোগৰ সৈতে আপডেট হৈ থকাৰ বাবে পৰিৱৰ্তনশীল সময়ৰ সৈতে খাপ খোৱাটো তাৰ বাবে আৱশ্যকীয়। পৃথিৱীখন আগবাঢ়ি যোৱাৰ লগে লগে, বিশেষকৈ নতুন প্ৰযুক্তি আৰু ইণ্টাৰনেটৰ সৈতে, চাকৰিৰ প্ৰকাৰ আৰু মানুহে কেনেদৰে কাম কৰে সলনি হৈ থাকে। নতুন সুযোগবোৰ খোল খায় যিবোৰ কেইমাহমান আগতে উপলব্ধ নাছিল বা জনা নগৈছিল। আনকি তুমি এক নিৰ্দিষ্ট জীৱিকাৰ পথত থাকিলেও, কাম কৰাৰ ন ন উপায়বোৰৰ বাবে মুক্ত হোৱাটো অত্যাৱশ্যকীয়।",
            "gu": "નવીન માને છે કે નવા કામના ટ્રેન્ડને અનુસરીને ખાલી જગ્યાઓ સાથે અદ્યતન રહેવા માટે બદલાતા સમય સાથે તાલમેલ રાખવો તેના માટે મહત્વપૂર્ણ છે. જેમ જેમ વિશ્વ વિકસિત થાય છે, ખાસ કરીને નવી તકનીકો અને ઇન્ટરનેટ સાથે, નોકરીઓની પ્રકૃતિ અને લોકોની કામ કરવાની રીત સતત બદલાતી રહે છે. નવી શક્યતાઓ ખુલી રહી છે જે થોડા મહિના પહેલા ઉપલબ્ધ ન હતી અથવા જાણીતી ન હતી. જો તમે ચોક્કસ કારકિર્દીના માર્ગ પર હોવ તો પણ, કામ કરવાની નવી રીતો માટે ખુલ્લા રહેવું મહત્વપૂર્ણ છે."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Mechanic at Dial a Mechanic",
            "hi": "डायल-ए-मैकेनिक में मैकेनिक",
            "ka": "**THREAD_END**_ಡಯಲ್ ಎ ಮೆಕ್ಯಾನಿಕ್ ನಲ್ಲಿ ಮೆಕ್ಯಾನಿಕ್",
            "te": "**THREAD_END**_డయల్ ఎ మెకానిక్ వద్ద మెకానిక్",
            "or": "**THREAD_END**_Mechanic at Dial a Mechanic",
            "as": "**থ্ৰেড_সমাপ্ত**_ডায়েল এ মেকানিকত মেকানিক",
            "gu": "**THREAD_END**_મેકેનિક ડાયલ-એ-મેકેનિક પર"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Naveen that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने नवीन के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँचा है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकता है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n  ನೀವು ನವೀನ್‌ಗಾಗಿ ಮಾಡಿರುವ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు నవీన్ కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ !\n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି !\n \n ଆପଣ ନବୀନଙ୍କ ପାଇଁ ବାଛିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତି ଘଟାଇଛି | ଆପଣ କରୁଥିବା ପସନ୍ଦ ଉପରେ ଆଧାର କରି ଭିନ୍ନ ଭିନ୍ନ ସମାପ୍ତି ଅଛି | ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକୁ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ?",
            "as": "অভিনন্দন! তুমি এটা যাত্ৰা সমাপ্ত কৰিছা ৷ তুমি নবীনৰ বাবে কৰা বাছনিবোৰৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত ভিত্তি কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ চাব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક મુસાફરી પૂરી કરી છે!\n\nતમે નવીન માટે પસંદગીઓ કરી છે જે આ સંભવિત અંત તરફ દોરી ગઈ છે. તમે જે પસંદગી કરી છે તેનાથી અલગ અલગ અંત છે. શું તમે બીજા દ્રશ્યો ખોલવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମ୍ଯାପ୍‍ ଉପରେ ଏକ ନଜର ପକାନ୍ତୁ |",
            "as": "মানচিত্ৰখনৰ পিনে চোৱা",
            "gu": "ચાલો નકશા પર એક નજર નાખીએ"
          }
        }
      ]
    },
    "scene25": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D1_1_1//_focus on earning",
            "hi": "कमाई पर ध्यान देना",
            "ka": "D1_1_1//_ಅಧ್ಯಯನಗಳ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಿ",
            "te": "D1_1_1//_సంపాదనపై దృష్టి పెట్టండి",
            "or": "D1_1_1//_focus on earning",
            "as": "D1_1_1//_উপাৰ্জনৰ ওপৰত গুৰুত্ব",
            "gu": "D1_1_1//_કમાણી પર ધ્યાન કેન્દ્રિત કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Zaid",
            "hi": "जाएद",
            "ka": "ಝೈದ್",
            "te": "జైద్",
            "or": "ଜାଏଦ୍‌",
            "as": "জাইদ",
            "gu": "ઝાઈદ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "That means more studying. I would instead earn through other means.",
            "hi": "इसका मतलब है और ज्यादा पढ़ाई करना। इससे अच्छा है कि, मैं किसी और तरीके से पैसे कमा लूंगा।",
            "ka": "ಅಂದರೆ ಹೆಚ್ಚು ಅಧ್ಯಯನ ಮಾಡುವುದು. ಬದಲಿಗೆ ಬೇರೆ ವಿಧಾನಗಳ ಮೂಲಕ ಗಳಿಸುತ್ತೇನೆ.",
            "te": "అంటే ఎక్కువ చదువుకోవాలి. నేను బదులుగా ఇతర మార్గాల ద్వారా సంపాదిస్తాను.",
            "or": "ଏହାର ଅର୍ଥ ଅଧିକ ଅଧ୍ୟୟନ | ମୁଁ ଏହା ବଦଳରେ ଅନ୍ୟ ଉପାୟ ମାଧ୍ୟମରେ ରୋଜଗାର କରିବି |",
            "as": "তাৰ অৰ্থ হৈছে অধিক অধ্যয়ন কৰা। মই তাৰ সলনি আন উপায়েৰে উপাৰ্জন কৰিম।",
            "gu": "એટલે કે વધુ શીખવું. તેના બદલે હું કોઈ બીજી રીતે કમાઈશ."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "You have your heart set on dance, so I agree. It will just give you more time to practice.",
            "hi": "तुम्हें डान्स करना दिल से पसंद है, इसके लिए मैं सहमत हूँ। इस तरीके से तुम्हें प्रैक्टिस करने के लिए थोड़ा और समय मिल जाएगा।",
            "ka": "ನೀವು ನೃತ್ಯದಲ್ಲಿ ನಿಮ್ಮ ಹೃದಯವನ್ನು ಹೊಂದಿದ್ದೀರಿ, ಆದ್ದರಿಂದ ನಾನು ಒಪ್ಪುತ್ತೇನೆ. ಇದು ನಿಮಗೆ ಅಭ್ಯಾಸ ಮಾಡಲು ಹೆಚ್ಚಿನ ಸಮಯವನ್ನು ನೀಡುತ್ತದೆ.",
            "te": "మీ హృదయం డ్యాన్స్‌పై ఉంది, కాబట్టి నేను అంగీకరిస్తున్నాను. ఇది మీకు సాధన చేయడానికి ఎక్కువ సమయం ఇస్తుంది.",
            "or": "ତୁମର ହୃଦୟ ନୃତ୍ୟ ଉପରେ ସ୍ଥିର ହୋଇଛି, ତେଣୁ ମୁଁ ରାଜି | ଏହା ଆପଣଙ୍କୁ ଅଭ୍ୟାସ କରିବାକୁ ଅଧିକ ସମୟ ଦେବ |",
            "as": "তোমাৰ বুকুত নৃত্য সোমাই আছে, সেয়েহে মই সন্মত। ই তোমাক অনুশীলন কৰিবলৈ অধিক সময় দিব।",
            "gu": "તમે નૃત્ય પર તારું મન નક્કી કર્યું છે તેથી હું સંમત છું. તે ફક્ત તમને પ્રેક્ટિસ કરવા માટે વધુ સમય આપશે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D1_1_2//",
            "hi": "D1_1_2//",
            "ka": "D1_1_2//",
            "te": "D1_1_2//",
            "or": "D1_1_2//",
            "as": "D1_1_2//",
            "gu": "D1_1_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Zaid",
            "hi": "जाएद",
            "ka": "ಝೈದ್",
            "te": "జైద్",
            "or": "ଜାଏଦ୍‌",
            "as": "জাইদ",
            "gu": "ઝાઈદ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.label",
          "value": {
            "en": "Zaid",
            "hi": "जाएद",
            "ka": "ಝೈದ್",
            "te": "జైద్",
            "or": "ଜାଏଦ୍‌",
            "as": "জাইদ",
            "gu": "ઝાઈદ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "But I want to start earning real money, Zaid. Live a comfortable life.",
            "hi": "लेकिन मैं पैसे कमाना शुरू करना चाहता हूँ, जाएद। मैं आराम की जिंदगी जीना चाहता हूँ।",
            "ka": "ಆದರೆ ನಾನು ನಿಜವಾದ ಹಣವನ್ನು ಗಳಿಸಲು ಬಯಸುತ್ತೇನೆ, ಝೈದ್. ನೆಮ್ಮದಿಯ ಜೀವನ ನಡೆಸಬೇಕು.",
            "te": "కానీ నేను నిజమైన డబ్బు సంపాదించడం ప్రారంభించాలనుకుంటున్నాను , జైద్. కంఫర్టేబుల్ జీవితాన్ని గడుపాలి అని.",
            "or": "କିନ୍ତୁ ମୁଁ ପ୍ରକୃତ ଟଙ୍କା ରୋଜଗାର କରିବାକୁ ଚାହୁଁଛି, ଜାଏଦ୍‌ | ଆରାମଦାୟକ ଜୀବନଯାପନ କର |",
            "as": "কিন্তু মই প্ৰকৃততে ধন উপাৰ্জন কৰা আৰম্ভ কৰিব বিচাৰোঁ, জাইদ। এক আৰামদায়ক জীৱন যাপন কৰিব বিচাৰো।",
            "gu": "પરંતુ હું સાચા પૈસા કમાવવાનું શરૂ કરવા માંગુ છું, ઝાઈદ. આરામદાયક જીવન જીવવા માટે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "If money is the concern, why don’t you take up delivery services like Omi?",
            "hi": "अगर तुम्हें पैसों की चिंता है, तो तुम ओमी के जैसे डिलीवरी सर्विस क्यों नहीं करते?",
            "ka": "ಹಣವು ಕಾಳಜಿಯಾಗಿದ್ದರೆ, ಓಮಿಯಂತಹ ವಿತರಣಾ ಸೇವೆಗಳನ್ನು ನೀನು ಏಕೆ ತೆಗೆದುಕೊಳ್ಳಬಾರದು?",
            "te": "డబ్బు సమస్య అయితే, మీరు Omi వంటి డెలివరీ సేవలను ఎందుకు చేపట్టకూడదు?",
            "or": "ଯଦି ଟଙ୍କା ଚିନ୍ତାର ବିଷୟ ଅଛି, ତୁମେ କାହିଁକି ଓମି ପରି ଡେଲିଭରୀ ସେବା ଗ୍ରହଣ କରୁ ନାହଁ ?",
            "as": "যদি টকা চিন্তাৰ বিষয়, তুমি অমিৰ দৰে ডেলিভাৰী সেৱাৰ কাম কিয় নকৰা?",
            "gu": "જો પૈસા જ ચિંતા છે, તો તું ઓમી જેવી ડિલિવરી સેવાઓ કેમ નથી કરતો?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "I did think of that, but I’m worried it might take up too much time and energy away from dance.",
            "hi": "मैंने इसके बारे में सोचा है, लेकिन मुझे चिंता है कि, डान्स के बजाय इसके लिए बहुत समय और एनर्जी देनी पड़ेगी।",
            "ka": "ನಾನು ಅದರ ಬಗ್ಗೆ ಯೋಚಿಸಿದೆ, ಆದರೆ ಇದು ನೃತ್ಯದಿಂದ ಹೆಚ್ಚು ಸಮಯ ಮತ್ತು ಶಕ್ತಿಯನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ ಎಂದು ನಾನಗೆ ಚಿಂತೆ.",
            "te": "నేను దాని గురించి ఆలోచించాను, కానీ అది డ్యాన్స్‌కు దూరంగా చాలా సమయం మరియు శక్తిని తీసుకుంటుందని నేను భయపడుతున్నాను.",
            "or": "ମୁଁ ସେ ବିଷୟରେ ଚିନ୍ତା କଲି, କିନ୍ତୁ ମୁଁ ଚିନ୍ତିତ ଯେ ଏହା ନୃତ୍ୟଠାରୁ ଅଧିକ ସମୟ ଏବଂ ଶକ୍ତି ନେଇପାରେ |",
            "as": "মই সেইটো ভাবিছিলো, কিন্তু মোৰ চিন্তা যে ই বহুত সময় আৰু শক্তি নৃত্যৰ পৰা কাঢ়ি ল’ব।",
            "gu": "મેં તેના વિશે વિચાર્યું છે, પરંતુ મને ચિંતા છે કે કદાચ તે નૃત્ય કરતાં ઘણો સમય અને શક્તિ લેશે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.text",
          "value": {
            "en": "I agree. If we stick together and work hard on our dance, we might become big really soon and start getting paid for our performance.",
            "hi": "मैं इस बात से सहमत हूँ। अगर हम एक साथ काम करें और बहुत मेहनत करें, तो जल्द ही हम अच्छे डान्सर बन जाएंगे और हमें अपने परफॉर्मेंस के लिए पैसे भी मिलेंगे।",
            "ka": "ನಾನು ಸಮ್ಮತಿಸುವೆ. ನಾವು ಒಟ್ಟಿಗೆ ಸೇರಿ ಮತ್ತು ನಮ್ಮ ನೃತ್ಯದಲ್ಲಿ ಕಷ್ಟಪಟ್ಟು ಕೆಲಸ ಮಾಡಿದರೆ, ನಾವು ಶೀಘ್ರದಲ್ಲೇ ದೊಡ್ಡವರಾಗಬಹುದು ಮತ್ತು ನಮ್ಮ ಅಭಿನಯಕ್ಕಾಗಿ ಹಣವನ್ನು ಪಡೆಯಲು ಪ್ರಾರಂಭಿಸಬಹುದು.",
            "te": "నేను ఒప్పుకుంటాను. మనం కలిసికట్టుగా ఉండి, మన డ్యాన్స్‌పై కష్టపడి పని చేస్తే, మనం త్వరలో పెద్దవాళ్లమవుతాము మరియు మన ప్రదర్శనకు డబ్బును పొందడం ప్రారంభించవచ్చు.",
            "or": "ମୁଁ ରାଜି। ଯଦି ଆମେ ଏକାଠି ରହି ଆମର ନୃତ୍ୟ ଉପରେ କଠିନ ପରିଶ୍ରମ କରୁ, ହୁଏତ ଆମେ ଶୀଘ୍ର ବଡ ହୋଇପାରିବା ଏବଂ ଆମର ପ୍ରଦର୍ଶନ ପାଇଁ ଦରମା ପାଇବା ଆରମ୍ଭ କରିପାରିବା",
            "as": "মই একমত। যদি আমি একেলগে থাকোঁ আৰু আমাৰ নৃত্যত কঠোৰ পৰিশ্ৰম কৰোঁ, আমি সঁচাকৈয়ে সোনকালে ডাঙৰ হ'ব পাৰিম আৰু আমাৰ প্ৰদৰ্শনৰ বাবে উপাৰ্জন কৰা আৰম্ভ কৰিব পাৰিম।",
            "gu": "હું સહમત છુ. જો આપણે સાથે રહીએ અને આ ડાન્સમાં સખત મહેનત કરીએ, તો કદાચ આપણે તેને મોટું બનાવીશું અને આપણા પ્રદર્શન માટે ચૂકવણી કરીશું."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D1_1_3(D)",
            "hi": "D1_1_3(D)",
            "ka": "D1_1_3(D)",
            "te": "D1_1_3(D)",
            "or": "D1_1_3(D)",
            "as": "D1_1_3(D)",
            "gu": "D1_1_3(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Naveen say?",
            "hi": "नवीन को क्या कहना चाहिए?",
            "ka": "ನವೀನ್ ಏನು ಹೇಳಬೇಕು?",
            "te": "నవీన్ ఏం చెప్పాలి?",
            "or": "ନବୀନ କଣ କହିବା ଉଚିତ୍ ?",
            "as": "নবীনে কি কোৱা উচিত?",
            "gu": "નવીને શું કરવું જોઇયે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "I will not be able to earn much with dance. I really need to take up this job.",
            "hi": "मैं डान्स करके ज्यादा पैसे नहीं कमा पाऊंगा। मुझे सच में यह जॉब करना पड़ेगा।",
            "ka": "ನಾನು ನೃತ್ಯದಿಂದ ಹೆಚ್ಚು ಸಂಪಾದಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ. ನಾನು ನಿಜವಾಗಿಯೂ ಈ ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕಾಗಿದೆ.",
            "te": "డ్యాన్స్‌తో పెద్దగా సంపాదించలేను. నేను నిజంగా ఈ ఉద్యోగం ని చేయాలి.",
            "or": "ମୁଁ ନୃତ୍ୟ ରେ ଅଧିକ ରୋଜଗାର କରିବାକୁ ସମର୍ଥ ହେବି ନାହିଁ | ମୁଁ ପ୍ରକୃତରେ ଏହି ଚାକିରି କରିବା ଆବଶ୍ଯକ |",
            "as": "মই নৃত্যৰ দ্বাৰা বেছি উপাৰ্জন কৰিব নোৱাৰিম। মই সঁচাকৈয়ে এই কামটো ল'ব লাগিব।",
            "gu": "હું નૃત્યમાંથી વધુ કમાણી કરી શકીશ નહીં. મારે આ નોકરી ચોક્કસ લેવી પડશે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Yes, you’re right, da! Let’s aim for the stars! We should focus on our dance.",
            "hi": "हाँ, तुम सही कह रहे हो, दा! हमें डान्स पर ध्यान देना चाहिए।",
            "ka": "ಹೌದು, ನೀನು ಹೇಳಿದ್ದು ಸರಿ, ಡಾ! ನಕ್ಷತ್ರಗಳಿಗೆ ಗುರಿಹಾಕೋಣ! ನಾವು ನಮ್ಮ ನೃತ್ಯದತ್ತ ಗಮನ ಹರಿಸಬೇಕು.",
            "te": "అవును, మీరు చెప్పింది నిజమే, డా! నక్షత్రాలను లక్ష్యంగా చేసుకుందాం! మనం డ్యాన్స్‌పై దృష్టి పెట్టాలి.",
            "or": "ହଁ, ଆପଣ ଠିକ୍ , ଦା! ଆସନ୍ତୁ ଷ୍ଟାରମାନଙ୍କ ପାଇଁ ଲକ୍ଷ୍ୟ କରିବା ! ଆମେ ଆମର ନୃତ୍ୟ ଉପରେ ଧ୍ୟାନ ଦେବା ଉଚିତ୍ |",
            "as": "হয়, তুমি ঠিকেই কৈছে, বন্ধু! আহাঁ তৰাবোৰ লক্ষ্য হওক! আমি আমাৰ নৃত্যত মনোনিবেশ কৰা উচিত।",
            "gu": "હા, તું સાચો છે ભાઈ! કઈક ચાલુ કરવાનું ધારીએ! આપણે આપણાં નૃત્ય પર ધ્યાન આપવું જોઇયે."
          }
        }
      ]
    },
    "scene26": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_1_1_Take up a job",
            "hi": "जॉब करना",
            "ka": "D2_1_1_ಕೆಲಸವನ್ನು ಕೈಗೆತ್ತಿಕೊಳ್ಳಿ",
            "te": "D2_1_1_ఉద్యోగాన్ని చేయడం",
            "or": "D2_1_1_Take up a job",
            "as": "D2_1_1_এটা কাম লোৱা",
            "gu": "D2_1_1_નોકરી કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen has seen a few older boys in the community who work as food delivery agents. He finds out from some of them that Pizza Palace has an opening.",
            "hi": "नवीन में आसपास के कुछ बड़े लड़कों को फूड डिलीवरी एजेंट के तौर पर काम करते हुए देखा था। उनसे नवीन को पता चलता है कि पिज़्ज़ा पैलेस में एक नौकरी उपलब्ध है।",
            "ka": "ಆಹಾರ ವಿತರಣಾ ಏಜೆಂಟ್‌ಗಳಾಗಿ ಕೆಲಸ ಮಾಡುವ ಸಮುದಾಯದ ಕೆಲವು ಹಿರಿಯ ಹುಡುಗರನ್ನು ನವೀನ್ ನೋಡಿದ್ದಾನೆ. ಅವರಲ್ಲಿ ಕೆಲವರಿಂದ ಪಿಜ್ಜಾ ಪ್ಯಾಲೇಸ್‌ಗೆ ತೆರೆಯುವಿಕೆ ಇದೆ ಎಂದು ಅವನು ಕಂಡುಕೊಂಡನು.",
            "te": "కమ్యూనిటీలో ఫుడ్ డెలివరీ ఏజెంట్లుగా పనిచేసే కొంతమంది పెద్ద అబ్బాయిలను నవీన్ చూశాడు. పిజ్జా ప్యాలెస్‌లో ఓపెనింగ్ ఉందని కొందరి నుండి అతను తెలుసుకుంటాడు.",
            "or": "ନବୀନ ସମ୍ପ୍ରଦାୟର କିଛି ବୟସ୍କ ବାଳକଙ୍କୁ ଦେଖିଛନ୍ତି ଯେଉଁମାନେ ଖାଦ୍ୟ ଡେଲିଭରି ଏଜେଣ୍ଟ ଭାବରେ କାର୍ଯ୍ୟ କରନ୍ତି | ସେ ସେମାନଙ୍କର କିଛିଙ୍କ ମଧ୍ୟରୁ ଜାଣିବାକୁ ପାଇଲେ ଯେ pizza ପ୍ୟାଲେସର ଏକ ଉଦଘାଟନ ଅଛି |",
            "as": "নবীনে সমাজৰ কেইজনমান ডাঙৰ ল'ৰাক দেখিছে যিয়ে খাদ্য বিতৰণ এজেণ্ট হিচাপে কাম কৰে। সি তেওঁলোকৰ কিছুমানৰ পৰা গম পালে যে পিজ্জা পেলেচত এটা কাম খালী আছে।",
            "gu": "નવીને તેનાથી કેટલાક મોટા છોકરાઓને જોયા છે જેઓ જમવાનું પહોંચડાવાના એજન્ટ તરીકે કામ કરે છે. તેમાંથી કેટલાક પાસેથી તેને ખબર પડી કે પિઝા પેલેસમાં નોકરી ચાલુ થવાની છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_1_2",
            "hi": "D2_1_2",
            "ka": "D2_1_2",
            "te": "D2_1_2",
            "or": "D2_1_2",
            "as": "D2_1_2",
            "gu": "D2_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He goes to a Pizza Palace for a walk-in-interview.",
            "hi": "वह पिज़्ज़ा पैलेस में वॉक- इन- इंटरव्यू के लिए जाता है।",
            "ka": "ಅವರು ವಾಕ್-ಇನ್-ಇಂಟರ್ವ್ಯೂಗಾಗಿ ಪಿಜ್ಜಾ ಪ್ಯಾಲೇಸ್‌ಗೆ ಹೋಗುತ್ತಾನೆ.",
            "te": "అతను వాక్-ఇన్-ఇంటర్వ్యూ కోసం పిజ్జా ప్యాలెస్‌కి వెళ్తాడు.",
            "or": "ସେ ଏକ ସାକ୍ଷାତକାର ପାଇଁ ଏକ pizza ପ୍ୟାଲେସକୁ ଯାଆନ୍ତି |",
            "as": "সি ৱাক-ইন-ইণ্টাৰভিউৰ বাবে পিজ্জা পেলেচলৈ যায়।",
            "gu": "તે ઇન્ટરવ્યુ માટે પિઝા પેલેસમાં જાય છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_1_3//",
            "hi": "D2_1_3//",
            "ka": "D2_1_3//",
            "te": "D2_1_3//",
            "or": "D2_1_3//",
            "as": "D2_1_3//",
            "gu": "D2_1_3//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Manager",
            "hi": "मैनेजर",
            "ka": "ಮ್ಯಾನೇಜರ್",
            "te": "మేనేజర్",
            "or": "ମ୍ଯାନେଜର୍‌",
            "as": "মেনেজাৰ",
            "gu": "મેનેજર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Manager",
            "hi": "मैनेजर",
            "ka": "ಮ್ಯಾನೇಜರ್",
            "te": "మేనేజర్",
            "or": "ମ୍ଯାନେଜର୍‌",
            "as": "মেনেজাৰ",
            "gu": "મેનેજર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Hi Naveen, you have come here for the delivery agent job? Do you have any prior experience?",
            "hi": "हाय नवीन, तुम डिलीवरी एजेंट के जॉब के लिए आए हो, है ना? तुम्हें इस काम का कोई अनुभव है?",
            "ka": "ನಮಸ್ಕಾರ ನವೀನ್, ನೀವು ಡೆಲಿವರಿ ಏಜೆಂಟ್ ಕೆಲಸಕ್ಕಾಗಿ ಇಲ್ಲಿಗೆ ಬಂದಿದ್ದೀರಾ? ನಿಮಗೆ ಯಾವುದೇ ಪೂರ್ವ ಅನುಭವವಿದೆಯೇ?",
            "te": "హాయ్ నవీన్, మీరు డెలివరీ ఏజెంట్ ఉద్యోగం కోసం ఇక్కడకు వచ్చారా? మీకు ముందు ఏదైనా అనుభవం ఉందా?",
            "or": "ହାଏ ନବୀନ, ତୁମେ ଡେଲିଭରି ଏଜେଣ୍ଟ ଚାକିରି ପାଇଁ ଏଠାକୁ ଆସିଛ କି? ତୁମର କିଛି ପୂର୍ବ ଅଭିଜ୍ଞତା ଅଛି କି ?",
            "as": "হাই নবীন, তুমি ডেলিভাৰী এজেণ্টৰ চাকৰিৰ বাবে ইয়ালৈ আহিছা? তোমাৰ কোনো আগতীয়া অভিজ্ঞতা আছে নেকি?",
            "gu": "કેમ છે નવીન તું અહી ડિલિવેરી એજેંટ ની નોકરી માટે અહી આવ્યો છે? તારા જોડે કોઈ જૂનો આના જેવો અનુભવ છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "No, Sir! This is my first job. But I have driven my brother’s auto for some time.",
            "hi": "नहीं, सर! यह मेरा पहला जॉब है। लेकिन मैं कभी-कभी अपने भाई की ऑटो चलाता था।",
            "ka": "ಇಲ್ಲ, ಸರ್! ಇದು ನನ್ನ ಮೊದಲ ಕೆಲಸ. ಆದರೆ ನಾನು ನನ್ನ ಅಣ್ಣನ ಆಟೋವನ್ನು ಕೆಲಕಾಲ ಓಡಿಸಿದ್ದೇನೆ.",
            "te": "లేదు సర్! ఇది నా మొదటి ఉద్యోగం. కానీ నేను కొంత కాలంగా మా అన్నయ్య ఆటో నడుపుతున్నాను.",
            "or": "ନା, ସାର୍ ! ଏହା ମୋର ପ୍ରଥମ ଚାକିରି | କିନ୍ତୁ ମୁଁ କିଛି ସମୟ ପାଇଁ ମୋ ଭାଇର ଅଟୋ ଚଲାଇଛି |",
            "as": "নাই চাৰ! এইটো মোৰ প্ৰথম কাম হ’ব। কিন্তু মই কিছু দিনৰ বাবে মোৰ ভাইটিৰ অটো খন চলাইছো।",
            "gu": "ના, શાહેબ! આ મારી પહેલી નોકરી છે. પણ મે મારા ભાઈની રિક્ષા ચલાવી છે ગણી વખત."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "Good good! I assume you have your Driver’s license. You have to submit a copy of your DL and proof of your residence.",
            "hi": "अच्छा! तो तुम्हारे पास ड्राइवर लाइसेंस होगा। तुम्हें अपने डीएल की कॉपी और अपने घर के पते का प्रूफ सबमिट करना होगा।",
            "ka": "ಒಳ್ಳೆಯದು ಒಳ್ಳೆಯದು! ನಿಮ್ಮ ಚಾಲಕ ಪರವಾನಗಿಯನ್ನು ನೀವು ಹೊಂದಿದ್ದೀರಿ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ನಿಮ್ಮ ಡಿ ಎಲ್ ನ ಪ್ರತಿಯನ್ನು ಮತ್ತು ನಿಮ್ಮ ನಿವಾಸದ ಪುರಾವೆಯನ್ನು ನೀವು ಸಲ್ಲಿಸಬೇಕು.",
            "te": "మంచిది ! మీకు మీ డ్రైవింగ్ లైసెన్స్ ఉందని నేను అనుకుంటున్నాను. మీరు మీ DL కాపీని మరియు మీ నివాస రుజువును సమర్పించాలి.",
            "or": "ଭଲ ଭଲ! ମୋତେ ଲାଗୁଛି ତୁମର ଡ୍ରାଇଭିଂ ଲାଇସେନ୍ସ ଅଛି | ତୁମେ ତୁମର ଡ୍ରାଇଭିଂ ଲାଇସେନ୍ସ ର ଏକ ନକଲ ଏବଂ ତୁମର ବାସସ୍ଥାନର ପ୍ରମାଣ ଦାଖଲ କରିବାକୁ ପଡିବ |",
            "as": "ভাল ভাল! মই ধৰি লৈছো যে তোমাৰ ড্ৰাইভাৰ লাইচেঞ্চ আছে। তুমি তোমাৰ DLৰ এটা প্ৰতিলিপি আৰু তোমাৰ বাসস্থানৰ প্ৰমাণ দাখিল কৰিব লাগিব।",
            "gu": "સરસ સરસ! આશા છે કે તારી જોડે ડ્રાઇવિંગ લાઇસન્સ છે. તારા ડ્રાઇવિંગ લાઇસન્સ અને રહેઠાણનો પુરાવોઓની નકલ આપવી પડશે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.text",
          "value": {
            "en": "Okay, Sir! Also, I don’t have a bike.",
            "hi": "ठीक है, सर! लेकिन मेरे पास बाइक नहीं है।",
            "ka": "ಸರಿ, ಸರ್! ಅಲ್ಲದೆ, ನನ್ನ ಬಳಿ ಬೈಕ್ ಇಲ್ಲ.",
            "te": "సరే, సర్! అలాగే, నా దగ్గర బైక్ లేదు.",
            "or": "ଠିକ ଅଛି, ସାର୍ ! ଆହୁରି ମଧ୍ୟ, ମୋ ପାଖରେ ଏକ ବାଇକ୍ ନାହିଁ |",
            "as": "ঠিক আছে, চাৰ! কিন্তু, মোৰ ওচৰত বাইক নাই।",
            "gu": "ઠીક છે શાહેબ! પણ મારી જોડે કોઈ બાઇક નથી."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_1_4a////",
            "hi": "D2_1_4a////",
            "ka": "D2_1_4a////",
            "te": "D2_1_4a////",
            "or": "D2_1_4a////",
            "as": "D2_1_4a////",
            "gu": "D2_1_4a////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Don’t worry about that. For the delivery, you will be using our customised bike.",
            "hi": "तुम इसकी चिंता मत करो। डिलीवरी के लिए तुम्हें हमारी कस्टमाइज बाईक का इस्तेमाल करना होगा।",
            "ka": "ಅದರ ಬಗ್ಗೆ ಚಿಂತಿಸಬೇಡಿ. ವಿತರಣೆಗಾಗಿ, ನೀವು ನಮ್ಮ ಕಸ್ಟಮೈಸ್ ಮಾಡಿದ ಬೈಕ್ ಅನ್ನು ಬಳಸುತ್ತೀರಿ",
            "te": "దాని గురించి ఆందోళన చెందకండి. డెలివరీ కోసం, మీరు మా అనుకూలీకరించిన బైక్‌ని ఉపయోగిస్తుంటారు.",
            "or": "ସେଥିପାଇଁ ଚିନ୍ତା କରନ୍ତୁ ନାହିଁ | ଡେଲିଭରି ପାଇଁ, ଆପଣ ଆମର କଷ୍ଟୋମାଇଜ୍ ବାଇକ୍ ବ୍ୟବହାର କରିବେ |",
            "as": "সেইটো চিন্তা নকৰিবা। ডেলিভাৰীৰ বাবে, তুমি আমাৰ অনুকূলিত বাইক ব্যৱহাৰ কৰিবা।",
            "gu": "એની ચિંતા ના કર. ડિલિવરી માટે અમારા કસ્ટમાઇઝ્ડ બાઇકને વાપરી શકે છે."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_1_4b//",
            "hi": "D2_1_4b//",
            "ka": "D2_1_4b//",
            "te": "D2_1_4b//",
            "or": "D2_1_4b//",
            "as": "D2_1_4b//",
            "gu": "D2_1_4b//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Manager",
            "hi": "मैनेजर",
            "ka": "ಮ್ಯಾನೇಜರ್",
            "te": "మేనేజర్",
            "or": "ମ୍ଯାନେଜର୍‌",
            "as": "মেনেজাৰ",
            "gu": "મેનેજર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "You will be given a 2-day training followed by 2 days of job shadowing on how to receive orders and interact with customers. After that, you will be on your own. We will review your work at the end of the first month, and based on your performance and ratings, you will be given the confirmation letter. \n\nDo you have any questions for us?",
            "hi": "तुम्हें 2 दिन की ट्रेनिंग दी जाएगी और उसके बाद 2 दिन जॉब शैडो करके तुम्हें यह सीखना होगा कि, ऑर्डर कैसे लेते हैं और ग्राहकों के साथ कैसे बात करते हैं। इसके बाद तुम्हें अपना काम खुद करना पड़ेगा। पहले महीने के अंत में हम तुम्हारे काम को रिव्यू करेंगे और तुम्हारी परफॉर्मेंस के और रेटिंग के आधार पर तुम्हें कंफर्मेशन लेटर दिया जाएगा। \n \n क्या तुम्हें कोई सवाल पूछना है?",
            "ka": "ಆರ್ಡರ್‌ಗಳನ್ನು ಹೇಗೆ ಸ್ವೀಕರಿಸುವುದು ಮತ್ತು ಗ್ರಾಹಕರೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸುವುದು ಹೇಗೆ ಎಂಬುದರ ಕುರಿತು 2 ದಿನಗಳ ಕೆಲಸದ ನೆರಳಿನ ನಂತರ ನಿಮಗೆ 2-ದಿನದ ತರಬೇತಿಯನ್ನು ನೀಡಲಾಗುತ್ತದೆ. ಅದರ ನಂತರ, ನೀವು ನಿಮ್ಮ ಸ್ವಂತ ಇರುತ್ತೀರಿ.ನಾವು ಮೊದಲ ತಿಂಗಳ ಕೊನೆಯಲ್ಲಿ ನಿಮ್ಮ ಕೆಲಸವನ್ನು ಪರಿಶೀಲಿಸುತ್ತೇವೆ ಮತ್ತು ನಿಮ್ಮ ಕಾರ್ಯಕ್ಷಮತೆ ಮತ್ತು ರೇಟಿಂಗ್‌ಗಳ ಆಧಾರದ ಮೇಲೆ ನಿಮಗೆ ದೃಢೀಕರಣ ಪತ್ರವನ್ನು ನೀಡಲಾಗುವುದು.\n \n ನೀವು ನಮಗೆ ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳನ್ನು ಹೊಂದಿದ್ದೀರಾ?",
            "te": "మీకు 2-రోజుల శిక్షణ ఇవ్వబడుతుంది, ఆపై ఆర్డర్‌లను ఎలా స్వీకరించాలి మరియు కస్టమర్‌లతో ఎలా పరస్పరం వ్యవహరించాలి అనే దానిపై 2 రోజుల జాబ్ షాడోయింగ్ ఇవ్వబడుతుంది. ఆ తర్వాత, మీరు మీ స్వంతంగా ఉంటారు. మేము మొదటి నెల చివరిలో మీ పనిని సమీక్షిస్తాము, మరియు మీ పనితీరు మరియు రేటింగ్‌ల ఆధారంగా, మీకు నిర్ధారణ లేఖ ఇవ్వబడుతుంది.\n \n మా కోసం మీకు ఏవైనా ప్రశ్నలు ఉన్నాయా?",
            "or": "ଆପଣଙ୍କୁ 2 ଦିନିଆ ତାଲିମ ଦିଆଯିବ ଏବଂ ତା’ପରେ 2 ଦିନ ଚାକିରି ଛାୟା ଦିଆଯିବ ଏବଂ କିପରି ଗ୍ରାହକମାନଙ୍କ ସହିତ ଯୋଗାଯୋଗ କରିବେ ଏବଂ କିପରି ଯୋଗାଯୋଗ କରିବେ |\n କିପରି ଅର୍ଡର୍‌ ଗ୍ରହଣ କରିବେ ଏବଂ ଗ୍ରାହକମାନଙ୍କ ସହିତ ପାରସ୍ପରିକ ବାର୍ତା କରିବେ ତା ଉପରେ ଆପଣଙ୍କୁ 2 ଦିନିଆ ତାଲିମ ଦିଆଯିବ ଏବଂ ତା’ପରେ 2 ଦିନ ଚାକିରି ଛାୟା ଦିଆଯିବ | ଏହା ପରେ, ତୁମେ ନିଜେ ରହିବେ | ଆମେ ପ୍ରଥମ ମାସର ଶେଷରେ ତୁମ କାର୍ଯ୍ୟର ସମୀକ୍ଷା କରିବୁ ଏବଂ ତୁମ କାର୍ଯ୍ୟଦକ୍ଷତା ଏବଂ ମୂଲ୍ୟାୟନ ଉପରେ ଆଧାର କରି ତୁମର ନିଶ୍ଚିତକରଣ ଚିଠି ଦିଆଯିବ |\n \n ଆମ ପାଇଁ ତୁମର କୌଣସି ପ୍ରଶ୍ନ ଅଛି କି?",
            "as": "তোমাক ২ দিনৰ এটা প্ৰশিক্ষণ দিয়া হ'ব আৰু তাৰ পিছত ২ দিনৰ কাৰণে বেলেগ এজনৰ লগত একেলগে থাকি অৰ্ডাৰ কেনেকৈ ল’ব লাগে আৰু গ্ৰাহকৰ সৈতে কেনেকৈ কথা পাতিব লাগে তাৰ ওপৰত শিকিবলৈ দিয়া হ'ব। তাৰ পিছত, তুমি নিজাববীয়াকৈ কাম কৰিব লাগিব। আমি প্ৰথম মাহৰ শেষত তোমাৰ কামৰ সমীক্ষা চলাম, আৰু তোমাৰ প্ৰদৰ্শন আৰু ৰেটিংৰ ওপৰত ভিত্তি কৰি, তোমাক নিশ্চিতকৰণ পত্ৰ দিয়া হ'ব। তোমাৰ কিবা প্ৰশ্ন সুধিবলগীয়া আছে নেকি?",
            "gu": "તને બે દિવસની તાલીમ આપવામાં આવશે અને બીજા 2 દિવસ કોઈના દેખરેખ નીચે તને રાખવામા આવશે જેમ કે ઓર્ડર કેવી રીતે મેળવવા અને ગ્રાહક જોડે કઈ રીતે વાતો કરવી. અમે તારું કામ કાજ જોઈ શું અને મહિના છેલ્લા દિવસે અને તારા કામના દેખાવ અને પ્રતીભાવ પ્રમાણે અમે તને એક નોકરીમાં કાયમી કરવા માટે પત્ર આપી શું. શું તારે કઈ પૂછવું છે?"
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_1_5",
            "hi": "D2_1_5",
            "ka": "D2_1_5",
            "te": "D2_1_5",
            "or": "D2_1_5",
            "as": "D2_1_5",
            "gu": "D2_1_5"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen has many questions. He feels he should know everything about the work and workplace before joining.",
            "hi": "नवीन को कई सवाल पूछने हैं। उसे लगता है कि नौकरी स्वीकार करने से पहले उसे का और काम की जगह के बारे में जानकारी हासिल करनी चाहिए।",
            "ka": "ನವೀನ್‌ಗೆ ಹಲವು ಪ್ರಶ್ನೆಗಳಿವೆ. ಸೇರುವ ಮೊದಲು ಕೆಲಸ ಮತ್ತು ಕೆಲಸದ ಸ್ಥಳದ ಬಗ್ಗೆ ಎಲ್ಲವನ್ನೂ ತಿಳಿದಿರಬೇಕು ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ.",
            "te": "నవీన్‌కి చాలా ప్రశ్నలు ఉన్నాయి. చేరడానికి ముందు అతను పని మరియు పని స్థలం గురించి ప్రతిదీ తెలుసుకోవాలని అనుకుంటున్నాడు.",
            "or": "ନବୀନଙ୍କର ଅନେକ ପ୍ରଶ୍ନ ଅଛି | ସେ ଅନୁଭବ କରନ୍ତି ଯେ ଯୋଗଦେବା ପୂର୍ବରୁ ସେ କାର୍ଯ୍ୟ ଏବଂ କର୍ମକ୍ଷେତ୍ର ବିଷୟରେ ସବୁକିଛି ଜାଣିବା ଉଚିତ୍ |",
            "as": "নবীনৰ বহুতো প্ৰশ্ন আছে। সি অনুভৱ কৰে যে যোগদান কৰাৰ আগতে সি কাম আৰু কৰ্মস্থানৰ বিষয়ে সকলো জানিব লাগিব।",
            "gu": "નવીનને ગણા પ્રશ્નો થાય છે. એને જાણવું છે કામ કઈ રીતનું છે અને કેવું છે તે ચાલુ કર્યા પહેલા."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_1_6_revealblock",
            "hi": "D2_1_6_रिविलब्लॉक",
            "ka": "D2_1_6_ರಿವೀಲ್ ಬ್ಲಾಕ್",
            "te": "D2_1_6_బహిర్గతం చేయడం బ్లాక్",
            "or": "D2_1_6_revealblock",
            "as": "D2_1_6_উন্মোচনখণ্ড",
            "gu": "D2_1_6_ખાનું બતાવવું"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Find out by clicking on the buttons.",
            "hi": "बटन पर क्लिक करके जानिए।",
            "ka": "ಬಟನ್‌ಗಳ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ ಕಂಡುಹಿಡಿಯಿರಿ.",
            "te": "బటన్లపై క్లిక్ చేయడం ద్వారా కనుక్కోండి.",
            "or": "ବଟନ୍ଗୁଡିକ ଉପରେ କ୍ଲିକ୍ କରି ଖୋଜନ୍ତୁ |",
            "as": "বুটামবোৰত ক্লিক কৰি বিচাৰি উলিওৱা।",
            "gu": "બટન ઉપર ક્લિક કરીને સોધો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Q1: What will be the work hours?",
            "hi": "Q1: कितने घंटे काम करना होगा?",
            "ka": "Q1: ಕೆಲಸದ ಸಮಯ ಹೇಗಿರುತ್ತದೆ?",
            "te": "Q1: పని వేళలు ఎలా ఉంటాయి?",
            "or": "ପ୍ରଶ୍ନ1: କାର୍ଯ୍ୟ ସମୟ କ’ଣ ହେବ ?",
            "as": "Q1: কামৰ সময় কিমান হ'ব?",
            "gu": "પ્રશ્ન 1: કામના કલાકો કેટલા?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "Q2: What about insurance for the vehicle?",
            "hi": "Q2: गाड़ी का इंश्योरेंस है या नहीं?",
            "ka": "Q2: ವಾಹನದ ವಿಮೆಯ ಬಗ್ಗೆ ಏನು?",
            "te": "Q2: వాహనానికి బీమా సంగతి ఏమిటి?",
            "or": "ପ୍ରଶ୍ନ2: ଗାଡି ପାଇଁ ବୀମା ବିଷୟରେ କଣ ?",
            "as": "Q2: বাহনখনৰ বাবে বীমাৰ সুবিধা কি?",
            "gu": "પ્રશ્ન 2: સાધનના વીમા વિષે શું?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.beforeReveal",
          "value": {
            "en": "Q3: Any other benefits for employees?",
            "hi": "Q3: कर्मचारियों को क्या लाभ मिलते हैं?",
            "ka": "Q3: ಉದ್ಯೋಗಿಗಳಿಗೆ ಯಾವುದೇ ಇತರ ಪ್ರಯೋಜನಗಳು?",
            "te": "Q3: ఉద్యోగులకు ఇతర ప్రయోజనాలు ఏమైనా ఉన్నాయా?",
            "or": "ପ୍ରଶ୍ନ3: କର୍ମଚାରୀଙ୍କ ପାଇଁ ଅନ୍ୟ କୌଣସି ଲାଭ ?",
            "as": "Q3: কৰ্মচাৰীসকলৰ বাবে আন কোনো সুবিধা?",
            "gu": "પ્રશ્ન 3: બીજા કોઈ ફાઇદા નોકરિયાતને?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.beforeReveal",
          "value": {
            "en": "Q4: Can we take the bike with us at night after we finish our shift?",
            "hi": "Q4: क्या हम शिफ्ट खत्म होने के बाद रात को बाइक अपने साथ ले जा सकते हैं?",
            "ka": "Q4: ನಾವು ಶಿಫ್ಟ್ ಮುಗಿಸಿದ ನಂತರ ರಾತ್ರಿ ಬೈಕ್ ಅನ್ನು ನಮ್ಮೊಂದಿಗೆ ತೆಗೆದುಕೊಂಡು ಹೋಗಬಹುದೇ?",
            "te": "Q4: షిఫ్ట్ పూర్తయిన తర్వాత రాత్రిపూట బైక్‌ని మాతో తీసుకెళ్లవచ్చా?",
            "or": "ପ୍ରଶ୍ନ4: ଆମେ ଆମର ସିଫ୍ଟ ଶେଷ କରିବା ପରେ ରାତିରେ ବାଇକ୍ ନେଇପାରିବା କି ?",
            "as": "Q4: আমি আমাৰ শ্বিফ্ট শেষ কৰাৰ পিছত ৰাতি বাইকখন আমাৰ লগত লৈ যাব পাৰিমনে?",
            "gu": "પ્રશ્ન 4: શું કામ પતી ગયા પછી રાત્રે હું બાઇક ઘરે લઈ જય શકું?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.beforeReveal",
          "value": {
            "en": "Q5:  Will there be training on how to talk to customers?",
            "hi": "Q5: ग्राहकों के साथ बातचीत करने के लिए प्रशिक्षण दिया जाएगा?",
            "ka": "Q5: ಗ್ರಾಹಕರೊಂದಿಗೆ ಹೇಗೆ ಮಾತನಾಡಬೇಕು ಎಂಬುದರ ಕುರಿತು ತರಬೇತಿ ಇರುತ್ತದೆಯೇ?",
            "te": "Q5: కస్టమర్‌లతో ఎలా మాట్లాడాలనే దానిపై శిక్షణ ఉంటుందా?",
            "or": "ପ୍ରଶ୍ନ5: ଗ୍ରାହକମାନଙ୍କ ସହିତ କିପରି କଥାବାର୍ତ୍ତା ହେବ ସେ ବିଷୟରେ ତାଲିମ ଦିଆଯିବ କି ?",
            "as": "Q5: গ্ৰাহকৰ সৈতে কেনেদৰে কথা পাতিব লাগে তাৰ প্ৰশিক্ষণ থাকিব নেকি?",
            "gu": "પ્રશ્ન 5: શું કોઈ તાલીમ મળશે કે ગ્રાહક જોડે કઈ રીતે વાત કરવી?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "11am - 3pm and then 5pm-9pm.",
            "hi": "सुबह 11- 3 और फिर शाम को 5-9 बजे तक।",
            "ka": "11 ಬೆಳಿಗ್ಗೆ - ಸಂಜೆ 3 ಮತ್ತು ನಂತರ 5 ಸಂಜೆ - ರಾತ್ರಿ 9.",
            "te": "11am - 3pm మరియు తరువాత 5pm-9pm.",
            "or": "11am - 3pm ଏବଂ ତାପରେ 5 pm-9pm |",
            "as": "ৰাতিপুৱা ১১ বজাৰ পৰা আবেলি ৩ বজালৈ আৰু তাৰ পিছত সন্ধিয়া ৫বজাৰ পৰা নিশা ৯বজালৈ।",
            "gu": "11am - 3pm અને પછી 5pm-9pm."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "All the bikes are insured.",
            "hi": "सभी बाइक का इंश्योरेंस किया हुआ है।",
            "ka": "ಎಲ್ಲಾ ಬೈಕ್‌ಗಳಿಗೆ ವಿಮೆ ಮಾಡಿಸಲಾಗಿದೆ.",
            "te": "అన్ని బైక్‌లకు బీమా ఉందా",
            "or": "ସମସ୍ତ ବାଇକ୍ ବୀମାଭୁକ୍ତ |",
            "as": "সকলো বাইকৰ বীমা কৰোৱা হৈছে।",
            "gu": "બધાજ બાઇકનો વીમો લેવામાં આવ્યો છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.afterReveal",
          "value": {
            "en": "You will get Provident Fund, a type of investment and overtime pay",
            "hi": "तुम्हें प्रोविडेंट फंड दिया जाएगा जो एक तरह का निवेश है और ओवरटाइम के लिए पेमेंट भी दिया जाएगा।",
            "ka": "ನೀವು ಪ್ರಾವಿಡೆಂಟ್ ಫಂಡ್, ಒಂದು ರೀತಿಯ ಹೂಡಿಕೆ ಮತ್ತು ಅಧಿಕಾವಧಿ ವೇತನವನ್ನು ಪಡೆಯುತ್ತೀರಿ",
            "te": "మీరు ప్రావిడెంట్ ఫండ్, ఒక రకమైన పెట్టుబడి మరియు ఓవర్ టైం చెల్లింపు పొందుతారు",
            "or": "ତୁମେ ପ୍ରୋଭିଡେଣ୍ଟ ଫଣ୍ଡ, ଏକ ପ୍ରକାର ବିନିଯୋଗ ଏବଂ ଓଭରଟାଇମ୍ ପେ ପାଇବ |",
            "as": "তুমি প্ৰভিডেণ্ট ফাণ্ড, এক প্ৰকাৰৰ বিনিয়োগ আৰু অভাৰটাইম দৰমহা পাবা",
            "gu": "તને પ્રોવિડન્ટ ફંડ મળશે, એક પ્રકારનું રોકાણ અને વધારે કલાકના નો પગાર મળશે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.afterReveal",
          "value": {
            "en": "No, you cannot use the bike for any personal use.",
            "hi": "नहीं तुम अपने निजी इस्तेमाल के लिए बाइक नहीं ले जा सकते।",
            "ka": "ಇಲ್ಲ, ನೀವು ಯಾವುದೇ ವೈಯಕ್ತಿಕ ಬಳಕೆಗಾಗಿ ಬೈಕು ಬಳಸುವಂತಿಲ್ಲ.",
            "te": "లేదు, మీరు బైక్‌ను ఎలాంటి వ్యక్తిగత ఉపయోగం కోసం ఉపయోగించలేరు.",
            "or": "ନା, ତୁମେ କୌଣସି ବ୍ୟକ୍ତିଗତ ବ୍ୟବହାର ପାଇଁ ବାଇକ୍ ବ୍ୟବହାର କରିପାରିବ ନାହିଁ |",
            "as": "নোৱাৰা, তুমি কোনো ব্যক্তিগত ব্যৱহাৰৰ বাবে বাইকখন ব্যৱহাৰ কৰিব নোৱাৰিবা।",
            "gu": "ના, તમે કોઈ પોતાના ઉપયોગ માટે બાઇકનો ઉપયોગ કરી શકતા નથી."
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.afterReveal",
          "value": {
            "en": "The job shadowing, where you will observe someone, will cover all aspects.",
            "hi": "जॉब शैडो करते समय तुम किसी कर्मचारी का निरीक्षण कर के इन पहलुओं के बारे में सीख सकते हो।",
            "ka": "ಕೆಲಸ ನೆರಳು, ಅಲ್ಲಿ ನೀವು ಯಾರನ್ನಾದರೂ ಗಮನಿಸಬಹುದು,ಇದು ಎಲ್ಲಾ ಅಂಶಗಳನ್ನು ಒಳಗೊಂಡಿದೆ.",
            "te": "మీరు గమనించే ఉద్యోగం షాడోవింగ్ అన్ని అంశాలను కవర్ చేస్తుంది.",
            "or": "ଜବ୍‍ ଶାଡୋଇଂ, ଯେଉଁଠାରେ ଆପଣ କାହାକୁ ନଜର ରଖିବେ, ସମସ୍ତ ଦିଗକୁ ଆବୃତ କରିବ |",
            "as": "তুমি বেলেগ এজনৰ লগত একেলগে কাম কৰি থাকোঁতে সকলোখিনি সামৰি লোৱা হ’ব ৷",
            "gu": "કોઈના નીચે કામ કરવું અને જોતાં રહેવું, અને બધા જ મુદ્દાઓ યાદ કરવા"
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_1_7//",
            "hi": "D2_1_7//",
            "ka": "D2_1_7//",
            "te": "D2_1_7//",
            "or": "D2_1_7//",
            "as": "D2_1_7//",
            "gu": "D2_1_7//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Manager",
            "hi": "मैनेजर",
            "ka": "ಮ್ಯಾನೇಜರ್",
            "te": "మేనేజర్",
            "or": "ମ୍ୟାନେଜର୍",
            "as": "মেনেজাৰ",
            "gu": "મેનેજર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Thank you!",
            "hi": "थैंक यू!",
            "ka": "ಧನ್ಯವಾದ!",
            "te": "ధన్యవాదాలు!",
            "or": "ଧନ୍ୟବାଦ !",
            "as": "ধন্যবাদ!",
            "gu": "ધન્યવાદ!"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Good luck and welcome to the team.",
            "hi": "गुड लक, हमारी टीम में तुम्हारा स्वागत है।",
            "ka": "ಶುಭವಾಗಲಿ ಮತ್ತು ತಂಡಕ್ಕೆ ಸ್ವಾಗತ.",
            "te": "శుభాకాంక్షలు మరియు టీం కు స్వాగతం.",
            "or": "ଶୁଭକାମନା ଏବଂ ଦଳକୁ ସ୍ୱାଗତ |",
            "as": "শুভকামনা আৰু দললৈ স্বাগতম।",
            "gu": "શુભેચ્છા ટીમમાં આવવા માટે સ્વાગત છે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "Walk-in-interview",
            "hi": "वॉक-इन- इंटरव्यू",
            "ka": "ವಾಕ್-ಇನ್-ಇಂಟರ್ವ್ಯೂ",
            "te": "వాక్-ఇన్-ఇంటర్వ్యూ",
            "or": "ୱାକ୍‍-ଇନ୍‌-ଇଣ୍ଟରଭ୍ୟୁ",
            "as": "ৱাক-ইন-সাক্ষাৎকাৰ",
            "gu": "વોક-ઇન-ઇન્ટરવ્યુ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Walk-in Interview experience!",
            "hi": "आपने अभी-अभी वॉक इन इंटरव्यू का अनुभव प्राप्त किया है!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ವಾಕ್-ಇನ್ ಸಂದರ್ಶನದ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే వాక్-ఇన్ ఇంటర్వ్యూ అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ୱାକ-ଇନ୍ ଇଣ୍ଟରଭ୍ୟୁ ଅଭିଜ୍ଞତା ହାସଲ କରିଛନ୍ତି !",
            "as": "তুমি এইমাত্ৰ ৱাক-ইন সাক্ষাৎকাৰৰ অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "તમે હમણાં જ વોક-ઇન ઇન્ટરવ્યૂનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Walk-in Interview",
            "hi": "वॉक-इन- इंटरव्यू",
            "ka": "ವಾಕ್-ಇನ್-ಇಂಟರ್ವ್ಯೂ",
            "te": "వాక్-ఇన్ ఇంటర్వ్యూ",
            "or": "ୱାକ୍‍-ଇନ୍‌-ଇଣ୍ଟରଭ୍ୟୁ",
            "as": "ৱাক-ইন-সাক্ষাৎকাৰ",
            "gu": "વોક-ઇન-ઇન્ટરવ્યુ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Suitability Assessment Tools",
            "hi": "उचित करियर के लिए मूल्यांकन टूल",
            "ka": "ವೃತ್ತಿ ಸೂಕ್ತತೆಯ ಮೌಲ್ಯಮಾಪನ ಪರಿಕರಗಳು",
            "te": "కెరీర్ అనుకూలత అంచనా సాధనాలు",
            "or": "କ୍ଯାରିୟର୍‌ ସୁଇଟାବିଲିଟି ଆସେସ୍‌ମେଣ୍ଟ୍‌ ଟୁଲ୍ସ",
            "as": "জীৱিকা উপযুক্ততা মূল্যায়ন সঁজুলি",
            "gu": "કારકિર્દી યોગ્યતા મૂલ્યાંકન સાધનો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Naveen walks in for an interview when he decides to apply for the pizza delivery agent job. Walk-in interviews are not scheduled beforehand. You can walk into the interview venue and wait for your turn, provided you have the right qualifications. Depending on the role, the interview process can be an interview or a test followed by the interview. You need to know that interviews are also a space for you to ask questions and get clarity on the nature of the job, hours of work, benefits and pay.",
            "hi": "नवीन पिज़्ज़ा डिलीवरी एजेंट के जॉब के लिए अप्लाई करने का निर्णय लेने के बाद इंटरव्यू के लिए जाता है। वॉक-इन-इंटरव्यू पहले से निर्धारित नहीं होते हैं। आप इंटरव्यू की जगह पर जाते हैं और अपनी बारी आने का इंतजार करते हैं, लेकिन इसके लिए आपके पास उचित योग्यता होनी चाहिए। रोल के अनुसार, इंटरव्यू की प्रक्रिया में केवल इंटरव्यू हो सकता है या इंटरव्यू के बाद में टेस्ट भी लिया जा सकता है। आपको यह पता होना चाहिए कि, इंटरव्यू के दौरान आप भी सवाल पूछ सकते हैं और काम के स्वरूप, काम के घंटे, लाभ और सैलरी के बारे में जान सकते हैं।",
            "ka": "ನವೀನ್ ಪಿಜ್ಜಾ ಡೆಲಿವರಿ ಏಜೆಂಟ್ ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿರ್ಧರಿಸಿದಾಗ ಸಂದರ್ಶನಕ್ಕೆ ತೆರಳುತ್ತಾನೆ. ವಾಕ್-ಇನ್ ಸಂದರ್ಶನಗಳನ್ನು ಮೊದಲೇ ನಿಗದಿಪಡಿಸಲಾಗಿರುವುದ್ದಿಲ.ನೀವು ಸರಿಯಾದ ಅರ್ಹತೆಗಳನ್ನು ಹೊಂದಿದ್ದರೆ, ನೀವು ಸಂದರ್ಶನದ ಸ್ಥಳಕ್ಕೆ ಹೋಗಬಹುದು ಮತ್ತು ನಿಮ್ಮ ಸರದಿಗಾಗಿ ಕಾಯಬಹುದು. ಪಾತ್ರವನ್ನು ಅವಲಂಬಿಸಿ, ಸಂದರ್ಶನ ಪ್ರಕ್ರಿಯೆಯು ಸಂದರ್ಶನ ಅಥವಾ ಸಂದರ್ಶನದ ನಂತರ ಪರೀಕ್ಷೆಯಾಗಿರಬಹುದು. ಸಂದರ್ಶನಗಳು ನಿಮಗೆ ಪ್ರಶ್ನೆಗಳನ್ನು ಕೇಳಲು ಮತ್ತು ಕೆಲಸದ ಸ್ವರೂಪ, ಕೆಲಸದ ಸಮಯ, ಪ್ರಯೋಜನಗಳು ಮತ್ತು ವೇತನದ ಬಗ್ಗೆ ಸ್ಪಷ್ಟತೆಯನ್ನು ಪಡೆಯಲು ಒಂದು ಸ್ಥಳವಾಗಿದೆ ಎಂದು ನೀವು ತಿಳಿದುಕೊಳ್ಳಬೇಕು.",
            "te": "పిజ్జా డెలివరీ ఏజెంట్ ఉద్యోగానికి దరఖాస్తు చేయాలని నిర్ణయించుకున్నప్పుడు నవీన్ ఇంటర్వ్యూ కోసం వెళతాడు. వాక్-ఇన్ ఇంటర్వ్యూలు ముందుగా షెడ్యూల్ చేయబడవు. మీరు సరైన అర్హతలను కలిగి ఉన్నట్లయితే, మీరు ఇంటర్వ్యూ వేదికకి వెళ్లి మీ వంతు కోసం వేచి ఉండవచ్చు. పాత్రపై ఆధారపడి, ఇంటర్వ్యూ ప్రక్రియ ఇంటర్వ్యూ లేదా పరీక్ష తర్వాత ఇంటర్వ్యూ కావచ్చు. ఇంటర్వ్యూలు మీకు ప్రశ్నలు అడగడానికి మరియు ఉద్యోగం తీరు, పని గంటలు, ప్రయోజనాలు మరియు వేతనంపై స్పష్టత పొందడానికి కూడా ఒక స్థలం అని మీరు తెలుసుకోవాలి.",
            "or": "ନବୀନ ଏକ ସାକ୍ଷାତକାର ପାଇଁ ଯାଆନ୍ତି ଯେତେବେଳେ ସେ pizza ଡେଲିଭରି ଏଜେଣ୍ଟ ଚାକିରି ପାଇଁ ଆବେଦନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି | ୱାକ୍‌-ଇନ୍ ଇଣ୍ଟରଭ୍ୟୁ ପୂର୍ବରୁ ଧାର୍ଯ୍ୟ କରାଯା‍ଏ ନାହିଁ | ଯଦି ତୁମର ସଠିକ୍ ଯୋଗ୍ୟତା ଅଛି, ତେବେ ତୁମେ ସାକ୍ଷାତକାର ସ୍ଥାନକୁ ଯାଇ ନିଜ ପର୍ଯ୍ୟାୟକୁ ଅପେକ୍ଷା କରିପାରିବ | । ଭୂମିକା ଉପରେ ନିର୍ଭର କରି, ସାକ୍ଷାତକାର ପ୍ରକ୍ରିୟା ଏକ ସାକ୍ଷାତକାର କିମ୍ବା ଏକ ପରୀକ୍ଷା ପରେ ସାକ୍ଷାତକାର ହୋଇପାରେ | ଆପଣଙ୍କୁ ଜାଣିବା ଆବଶ୍ୟକ ଯେ ସାକ୍ଷାତକାରଗୁଡ଼ିକ ପ୍ରଶ୍ନଗୁଡିକ ପଚାରିବା ଏବଂ ଚାକିରିର ପ୍ରକୃତି, କାର୍ଯ୍ୟ ଘଣ୍ଟା, ଲାଭ ଏବଂ ବେତନ ଉପରେ ସ୍ପଷ୍ଟତା ପାଇବା ପାଇଁ ମଧ୍ୟ ଏକ ସ୍ଥାନ ଅଟେ |",
            "as": "নবীনে সাক্ষাৎকাৰৰ বাবে আগবাঢ়ে যেতিয়া সি পিজ্জা ডেলিভাৰী এজেণ্টৰ চাকৰিৰ বাবে আবেদন কৰাৰ সিদ্ধান্ত লয়। ৱাক-ইন সাক্ষাৎকাৰবোৰ আগতিয়াকৈ অনুসূচীত কৰা নহয়। যদিহে তোমাৰ সঠিক অৰ্হতা থাকে, তুমি সাক্ষাৎকাৰস্থানলৈ যাব পাৰা আৰু তোমাৰ পালৰ বাবে অপেক্ষা কৰিব পাৰা। ভূমিকাৰ ওপৰত নিৰ্ভৰ কৰি, সাক্ষাৎকাৰৰ প্ৰক্ৰিয়াটো কেৱল সাক্ষাৎকাৰ বা সাক্ষাৎকাৰৰ পিছত পৰীক্ষাও হ'ব পাৰে। তুমি জানিব লাগিব যে সাক্ষাৎকাৰবোৰ হৈছে তোমাৰ বাবে প্ৰশ্ন সুধিবলৈ আৰু চাকৰিৰ প্ৰকৃতি, কামৰ সময়, লাভালাভ আৰু দৰমহাৰ বিষয়ে স্পষ্টকৈ জানিবলৈ এক ব্যৱস্থা।",
            "gu": "નવીન ઇન્ટરવ્યુમાં જાય છે જ્યારે તે પિઝા ડિલિવરી નોકરી માટે અરજી કરવાનું નક્કી કરે છે. ઇન્ટરવ્યુ અગાઉથી સુનિશ્ચિત થયેલ નથી. જો તમારી પાસે યોગ્ય લાયકાત હોય તો તમે રમતના મેદાનમાં જઈ શકો છો અને તમારા તકની રાહ જોઈ શકો છો. ભૂમિકા પર આધાર રાખીને, ઇન્ટરવ્યુ પ્રક્રિયામાં ઇન્ટરવ્યુ અથવા ઇન્ટરવ્યૂ પછીની પરીક્ષા હોઇ શકે છે. તમારે જાણવું જોઈએ કે ઇન્ટરવ્યુ એ પ્રશ્નો પૂછવા અને નોકરીના પ્રકાર, કલાકો, લાભો અને પગાર વિશે પણ એક જગ્યા છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_2_1",
            "hi": "D2_2_1",
            "ka": "D2_2_1",
            "te": "D2_2_1",
            "or": "D2_2_1",
            "as": "D2_2_1",
            "gu": "D2_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He joins Pizza Palace as a delivery agent. He is pretty thrilled to be working and earning money. \nHe does not mind the work as it gives him a chance to move around.",
            "hi": "वह पिज़्ज़ा पैलेस में डिलीवरी एजेंट के तौर पर काम करना शुरू करता है। वह अपने काम और सैलरी को लेकर खुश है। \n उसे मेहनत से कोई फर्क नहीं पड़ता क्योंकि वह यहाँ वहाँ जा सकता है।",
            "ka": "\"ಅವನು ಪಿಜ್ಜಾ ಪ್ಯಾಲೇಸ್‌ಗೆ ಡೆಲಿವರಿ ಏಜೆಂಟ್ ಆಗಿ ಸೇರುತ್ತಾನೆ. ಅವನು ಕೆಲಸ ಮಾಡುತ್ತಿರುವುದಕ್ಕೆ ಮತ್ತು ಹಣ ಗಳಿಸುತ್ತಿರುವುದಕ್ಕೆ ತುಂಬಾ ಥ್ರಿಲ್ ಆಗಿದ್ದಾನೆ.\n ಅವನು ಕೆಲಸದ ಬಗ್ಗೆ ತಲೆಕೆಡಿಸಿಕೊಳ್ಳುವುದಿಲ್ಲ, ಏಕೆಂದರೆ ಅದು ಅವನಿಗೆ ತಿರುಗಾಡಲು ಅವಕಾಶ ನೀಡುತ್ತದೆ.",
            "te": "అతను పిజ్జా ప్యాలెస్‌లో డెలివరీ ఏజెంట్‌గా చేరాడు. అతను పని చేస్తూ డబ్బు సంపాదిస్తున్నందుకు చాలా థ్రిల్‌గా ఉన్నాడు.\n అతను పనిని పట్టించుకోడు, ఎందుకంటే ఇది అతనికి చుట్టూ తిరగడానికి అవకాశం ఇస్తుంది.",
            "or": "ସେ ଏକ ବିତଅଣ ଏଜେଣ୍ଟ ଭାବରେ pizza ପ୍ୟାଲେସରେ ଯୋଗ ଦେଇଛନ୍ତି। କାମ କରି ଟଙ୍କା ରୋଜଗାର କରି ସେ ବହୁତ ଖୁସି ଅଛନ୍ତି।\n ତାଙ୍କର କାମ ବିଷୟରେ କିଛି ଭାବନା ନାହିଁ କାରଣ ଏହା ତାଙ୍କୁ ବୁଲିବା ପାଇଁ ସୁଯୋଗ ଦେଇଥାଏ |",
            "as": "সি পিজ্জা পেলেচত ডেলিভাৰী এজেণ্ট হিচাপে যোগদান কৰে। সি কাম কৰি আৰু টকা উপাৰ্জন কৰিবলৈ ৰোমাঞ্চিত হৈ পৰে। তাৰ কামটোত কোনো আপত্তি নাই কিয়নো ই তাক ঘূৰি ফুৰিবলৈ সুযোগ দিব।",
            "gu": "તે પિઝા પેલેસમાં ડિલિવરી એજન્ટ તરીકે જોડાય છે. તે કામ કરવા અને પૈસા કમાવવા માટે ખૂબ જ ઉત્સાહિત છે.\nતેને કામ વિષે નથી વિચારતો કારણ કે તે તેને ફરવાની તક મળે છે. "
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_1_2",
            "hi": "D2_1_2",
            "ka": "D2_1_2",
            "te": "D2_1_2",
            "or": "D2_1_2",
            "as": "D2_1_2",
            "gu": "D2_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "A few months into the job, Naveen realises that he is unable to practise dance as much as he did before and the work is starting to get unexciting.",
            "hi": "कुछ महीनों बाद, नवीन को यह एहसास होता है की वह पहले की तरह डान्स प्रैक्टिस के लिए ज्यादा समय नहीं दे पाता और काम भी अब ज्यादा दिलचस्प नहीं लग रहा।",
            "ka": "ಕೆಲಸಕ್ಕೆ ಸೇರಿದ ಕೆಲವು ತಿಂಗಳುಗಳಲ್ಲಿ, ನವೀನ್ ಮೊದಲಿನಷ್ಟು ನೃತ್ಯವನ್ನು ಅಭ್ಯಾಸ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ ಎಂದು ಅರಿತುಕೊಳುತ್ತಾನೆ ಮತ್ತು ಕೆಲಸವು ರೋಮಾಂಚನಗೊಳ್ಳಲು ಪ್ರಾರಂಭಿಸುತ್ತದೆ.",
            "te": "ఉద్యోగంలో చేరిన కొన్ని నెలలకే, నవీన్‌కి అంతకు ముందులాగా డ్యాన్స్ సాధన చేయలేకపోతున్నానని గ్రహించి, పనిలో ఉత్సాహం లేకుండా పోతుంది.",
            "or": "ଚାକିରିରେ କିଛି ମାସ ପରେ, ନବୀନ ହୃଦୟଙ୍ଗମ କଲେ ଯେ ସେ ପୂର୍ବ ପରି ନୃତ୍ୟ ଅଭ୍ୟାସ କରିବାରେ ଅସମର୍ଥ ଏବଂ କାର୍ଯ୍ୟଟି ଉତ୍ତେଜନାପୂର୍ଣ୍ଣ ହେବାକୁ ଲାଗୁଛି |",
            "as": "চাকৰিটোৰ কেইমাহমানৰ ভিতৰতে, নবীনে উপলব্ধি কৰে যে সি আগৰ দৰে নৃত্যৰ অনুশীলন কৰিব পৰা নাই আৰু কামটোও বিৰক্তিকৰ হ'বলৈ আৰম্ভ কৰিছে।",
            "gu": "કામના થોડા મહિના પછી, નવીનને ખબર પડે છે કે તે પહેલાની જેમ નૃત્યની પ્રેક્ટિસ કરી શકતો નથી, અને કામમાં તેને અરુચિ થવા લાગે છે."
          }
        }
      ]
    },
    "scene27": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D3_1_1",
            "hi": "D3_1_1",
            "ka": "D3_1_1",
            "te": "D3_1_1",
            "or": "D3_1_1",
            "as": "D3_1_1",
            "gu": "D3_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "One day after practice at the local Community Hall, Zaid and Naveen wait back to clean up. Zaid has been curious to hear about Naveen's job.",
            "hi": "एक दिन लोकल कम्युनिटी हॉल में प्रैक्टिस करने के बाद, जाएद और नवीन सफाई करने के लिए रुकते हैं। जाएद नवीन के जॉब के बारे में जानना चाहता है।",
            "ka": "ಸ್ಥಳೀಯ ಸಮುದಾಯ ಭವನದಲ್ಲಿ ಅಭ್ಯಾಸದ ನಂತರ ಒಂದು ದಿನ, ಝೈದ್ ಮತ್ತು ನವೀನ್ ಮತ್ತೆ ಸ್ವಚ್ಛಗೊಳಿಸಲು ಕಾಯುತ್ತಾರೆ. ಝೈದ್‌ಗೆ ನವೀನ್‌ನ ಕೆಲಸದ ಬಗ್ಗೆ ಕೇಳಲು ಕುತೂಹಲವಿದೆ.",
            "te": "స్థానిక కమ్యూనిటీ హాల్‌లో ప్రాక్టీస్ తర్వాత ఒక రోజు, జైద్ మరియు నవీన్ శుభ్రం చేయడానికి తిరిగి వేచి ఉన్నారు. నవీన్ ఉద్యోగం గురించి తెలుసుకోవాలని జైద్ ఆసక్తిగా ఉన్నాడు.",
            "or": "ସ୍ଥାନୀୟ କମ୍ୟୁନିଟି ହଲରେ ଅଭ୍ୟାସ କରିବାର ଗୋଟିଏ ଦିନ ପରେ, ଜାୟଦ୍‌ ଏବଂ ନବୀନ ସଫା କରିବାକୁ ଅପେକ୍ଷା କରିଛନ୍ତି | ନବୀନଙ୍କ ଚାକିରି ବିଷୟରେ ଶୁଣିବାକୁ ଜାୟଦ୍‌ ଆଗ୍ରହ ପ୍ରକାଶ କରିଛନ୍ତି",
            "as": "এদিন স্থানীয় কমিউনিটি হলত অনুশীলনৰ পিছত, জাইদ আৰু নবীনে পৰিষ্কাৰ কৰিবলৈ তাতে অপেক্ষা কৰে। জাইদে নবীনৰ চাকৰিৰ বিষয়ে শুনিবলৈ আগ্ৰহী হৈ আছে।",
            "gu": "એક દિવસ પછી, ઝાઈદ અને નવીન સ્થાનિક કોમ્યુનિટી હોલમાં તાલીમ લીધાના પછી તે સાફ થવાની રાહ જોઈ રહ્યા છે. ઝાઈદને નવીનના કામ વિશે સાંભળવામાં રસ પડ્યો."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D3_1_2",
            "hi": "D3_1_2",
            "ka": "D3_1_2",
            "te": "D3_1_2",
            "or": "D3_1_2",
            "as": "D3_1_2",
            "gu": "D3_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "But Naveen looks discouraged. Zaid is concerned for him.",
            "hi": "लेकिन नवीन खुश नहीं दिखाई दे रहा। जाएद को उसकी चिंता होती है।",
            "ka": "ಆದರೆ ನವೀನ್ ನಿರುತ್ಸಾಹ ತೋರುತ್ತಾನೆ. ಝೈದ್ ಅವನ ಬಗ್ಗೆ ಕಾಳಜಿ ವಹಿಸುತ್ತಾನೆ.",
            "te": "కానీ నవీన్ నిరుత్సాహంగా కనిపిస్తున్నాడు. జైద్ అతని గురించి ఆందోళన చెందుతున్నాడు.",
            "or": "କିନ୍ତୁ ନବୀନ ନିରାଶ ଦେଖାଯାଉଛନ୍ତି | ଜାୟଦ୍‌ ତାଙ୍କ ପାଇଁ ଚିନ୍ତିତ |‍",
            "as": "কিন্তু নবীনক নিৰুৎসাহিত দেখা গৈছে। জাইদ তাৰ বাবে চিন্তিত।",
            "gu": "પરંતુ નવીન નિરાશ દેખાય છે. ઝાઈદ તેના માટે ચિંતિત છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D3_1_3//",
            "hi": "D3_1_3//",
            "ka": "D3_1_3//",
            "te": "D3_1_3//",
            "or": "D3_1_3//",
            "as": "D3_1_3//",
            "gu": "D3_1_3//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Zaid",
            "hi": "जाएद",
            "ka": "ಝೈದ್",
            "te": "జైద్",
            "or": "ଜାଏଦ୍‌",
            "as": "জাইদ",
            "gu": "ઝાઈદ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "What happened, Naveen? I thought you liked your job.",
            "hi": "क्या हुआ, नवीन? मुझे लगा था कि तुम्हें यह जॉब पसंद है।",
            "ka": "ಏನಾಯ್ತು ನವೀನ್? ನಿನ್ನ ಕೆಲಸವನ್ನು ನೀನು ಇಷ್ಟಪಟ್ಟಿದ್ದೀಯ ಎಂದು ನಾನು ಭಾವಿಸಿದೆ.",
            "te": "ఏమైంది నవీన్? నీ పని నీకు నచ్చిందనుకున్నాను.",
            "or": "କ’ଣ ହେଲା, ନବୀନ? ମୁଁ ଭାବିଲି ତୁମକୁ ତୁମର କାମ ପସନ୍ଦ ଆସୁଛି |",
            "as": "কি হ'ল, নবীন? মই ভাবিছিলো তুমি কামটো ভাল পাইছা।",
            "gu": "શું થયું નવીન? મને લાગ્યું કે તને તારી નવી નોકરીમાં મજા આવે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "I did for some time when it was new but now, I am so tired of running around delivering pizzas and then coming here for practice.\nI cannot do well at work, and I'm unable to concentrate on dance.",
            "hi": "जब काम में शुरू किया था तब मुझे बहुत अच्छा लगा था। लेकिन अब मैं पिज़्ज़ा डिलीवरी के लिए यहाँ-वहाँ दौड़ कर और फिर यहाँ प्रैक्टिस के लिए आने के बाद थक जाता हूँ। \n मैं ठीक से काम नहीं कर पाता हूँ और डान्स पर भी ध्यान नहीं दे सकता।",
            "ka": "\"ಹೊಸದಾಗಿದ್ದಾಗ ನಾನು ಸ್ವಲ್ಪ ಸಮಯದವರೆಗೆ ಮಾಡಿದ್ದೇನೆ ಆದರೆ ಈಗ, ನಾನು ಪಿಜ್ಜಾಗಳನ್ನು ವಿತರಿಸಲು ಮತ್ತು ಅಭ್ಯಾಸಕ್ಕಾಗಿ ಇಲ್ಲಿಗೆ ಬರಲು ತುಂಬಾ ಆಯಾಸಗೊಂಡಿದ್ದೇನೆ.\n ನಾನು ಕೆಲಸದಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ, ಮತ್ತು ನನಗೆ ನೃತ್ಯದ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ.",
            "te": "కొత్తగా ఉన్నప్పుడు నేను కొంతకాలం చేసాను, కానీ ఇప్పుడు, పిజ్జాలు డెలివరీ చేస్తూ, ప్రాక్టీస్ కోసం ఇక్కడికి రావడంతో నేను చాలా అలసిపోయాను.\n నేను పనిలో బాగా చేయలేను మరియు నేను డ్యాన్స్‌పై దృష్టి పెట్టలేకపోతున్నాను.",
            "or": "ମୁଁ କିଛି ସମୟ ପାଇଁ କରିଥିଲି ଯେତେବେଳେ ଏହା ନୂଆ ଥିଲା କିନ୍ତୁ ବର୍ତ୍ତମାନ, ମୁଁ pizza ଡେଲିଭରି କରିବା ଏବଂ ପରେ ଅଭ୍ୟାସ ପାଇଁ ଏଠାକୁ ଆସିବାରେ ବହୁତ କ୍ଲାନ୍ତ ଅଛି |\n ମୁଁ କାମରେ ଭଲ କରିପାରିବି ନାହିଁ, ଏବଂ ମୁଁ ନୃତ୍ୟ ଉପରେ ଧ୍ୟାନ ଦେବାକୁ ଅସମର୍ଥ।",
            "as": "মই কিছু দিনৰ বাবে ভাল পাইছিলো যেতিয়া কামটো নতুন আছিল কিন্তু এতিয়া, মই পিজ্জা বিতৰণ কৰিবলৈ দৌৰি ফুৰি আৰু তাৰ পিছত অনুশীলনৰ বাবে ইয়ালৈ আহি ভাগৰি পৰিছো। মই কামটো ভালকৈ কৰিব পৰা নাই, আৰু মই নৃত্যত মনোনিবেশ কৰিবও পৰা নাই।",
            "gu": "જ્યારે તે નવું હતું ત્યારે હું થોડા સમય માટે તે કરતો હતો, પરંતુ હવે હું પિઝાની ડિલિવરી કરીને અને પછી અહીં પ્રેક્ટિસ કરવા માટે ખૂબ થાકી ગયો છું.\nહું કામ પર સારી રીતે કરી શકતો નથી અને હું નૃત્ય પર ધ્યાન કેન્દ્રિત કરી શકતો નથી.\""
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D3_1_4////",
            "hi": "D3_1_4////",
            "ka": "D3_1_4////",
            "te": "D3_1_4////",
            "or": "D3_1_4////",
            "as": "D3_1_4////",
            "gu": "D3_1_4////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Why didn’t you tell me this before Naveen? This must be so difficult for you.",
            "hi": "तुमने यह बात मुझे पहले क्यों नहीं बताई नवीन? तुम्हारे लिए यह करना बहुत मुश्किल होता होगा।",
            "ka": "ಇದನ್ನು ಮೊದಲು ನನಗೆ ಏಕೆ ಹೇಳಲಿಲ್ಲ ನವೀನ್‌? ಇದು ನಿನಗೆ ತುಂಬಾ ಕಷ್ಟಕರವಾಗಿರಬೇಕು.",
            "te": "ఈ విషయం నాకు ముందే ఎందుకు చెప్పలేదు నవీన్? ఇది నీకు చాలా కష్టంగా ఉండి ఉండాలి.",
            "or": "ନବୀନ ପୂର୍ବରୁ ତୁମେ ମୋତେ ଏହା କାହିଁକି କହି ନାହଁ? ଏହା ଆପଣଙ୍କ ପାଇଁ ତ କଷ୍ଟକର ହେବା ଜରୁରୀ |",
            "as": "নবীন তুমি আগতে মোক এইটো কিয় কোৱা নাছিলা? এইটো তোমাৰ বাবে বহুত টান হৈছে।",
            "gu": "તે મને પહેલા કેમ આ બધુ ના કહ્યું? આ થોડું કઠિન હોય શકે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "I wanted to, but I did not want it to look like I gave up.",
            "hi": "मैं बताना चाहता था, लेकिन मैं नहीं चाहता कि ऐसा लगे कि मैंने हार मान ली है।",
            "ka": "ನಾನು ಬಯಸಿದ್ದೆ, ಆದರೆ ನಾನು ಬಿಟ್ಟುಕೊಟ್ಟಂತೆ ಕಾಣುವುದು ನನಗೆ ಇಷ್ಟವಿರಲಿಲ್ಲ.",
            "te": "నేను చెప్పాలనుకున్నాను, కానీ నేను వదులుకున్నట్లు కనిపించడం నాకు ఇష్టం లేదు.",
            "or": "ମୁଁ ଚାହୁଁଥିଲି, କିନ୍ତୁ ମୁଁ ଚାହୁଁ ନଥିଲି ଯେ ଏପରି ଲାଗୁକି ମୁଁ ପରିତ୍ୟାଗ କରିଥିଲି |",
            "as": "মই ক’ব বিচাৰিছিলো, কিন্তু মই কোৱা নাছিলো কাৰণ মই এৰি দিয়াৰ দৰে লাগিব।",
            "gu": "હું ઇચ્છતો હતો, પણ હું હાર માની રહ્યો હોય તેમ અભિનય કરવા માંગતો ન હતો."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Bro, who cares about leaving? If you aren’t happy here, you have to find something else.",
            "hi": "भाई, किसे फर्क पड़ता है? अगर तुम यहाँ खुश नहीं हो तो तुम्हें कुछ और ढूंढना चाहिए।",
            "ka": "ಸಹೋದರ, ಬಿಡುವುದರ ಬಗ್ಗೆ ಯಾರು ಕಾಳಜಿ ವಹಿಸುತ್ತಾರೆ? ನೀನು ಇಲ್ಲಿ ಸಂತೋಷವಾಗಿಲ್ಲದಿದ್ದರೆ, ನೀನು ಬೇರೆ ಯಾವುದನ್ನಾದರೂ ಕಂಡುಹಿಡಿಯಬೇಕು.",
            "te": "బ్రో, ఎవరు వెళ్లిపోవడాన్ని పట్టించుకుంటారు? మీరు ఇక్కడ సంతోషంగా లేకుంటే, మీరు వేరేదాన్ని కనుగొనవలసి ఉంటుంది.",
            "or": "ଭାଇ, ଯିବା ବିଷୟରେ କିଏ ଚିନ୍ତା କରେ? ଯଦି ତୁମେ ଏଠାରେ ଖୁସି ନୁହଁ, ତୁମକୁ ଆଉ କିଛି ଖୋଜିବାକୁ ପଡିବ |",
            "as": "ভাই, এৰি যোৱাৰ কথা কোনে চিন্তা কৰিছে? যদি তুমি ইয়াত সুখী নহয়, তুমি বেলেগ কিবা বিচাৰিব লাগিব।",
            "gu": "ભાઈ, જવાની કોને પડી છે? જો તમે અહીં ખુશ નથી, તો તમારે બીજું કંઈક શોધવું પડશે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "I did. My friend who left this job has taken up delivery services at Bunzo. He is getting a better salary. I was planning to apply there.",
            "hi": "मैंने ढूंढा है। मेरा एक दोस्त पुरानी जॉब छोड़ कर, बंजो में डिलीवरी सर्विस कर रहा है। उससे अच्छी सैलरी भी मिल रही है। मैंने वहाँ अप्लाई किया है।",
            "ka": "ನಾನು ಮಾಡಿದ್ದೆನೆ. ಈ ಕೆಲಸವನ್ನು ತೊರೆದ ನನ್ನ ಸ್ನೇಹಿತ ಬುಂಝೋ ನಲ್ಲಿ ವಿತರಣಾ ಸೇವೆಗಳನ್ನು ತೆಗೆದುಕೊಂಡಿದ್ದಾನೆ. ಉತ್ತಮ ಸಂಬಳ ಪಡೆಯುತ್ತಿದ್ದಾನೆ. ಅಲ್ಲಿಗೆ ಅರ್ಜಿ ಹಾಕಲು ಯೋಚಿಸಿದ್ದೆ.",
            "te": "నేను చేశాను. ఈ ఉద్యోగాన్ని విడిచిపెట్టిన నా స్నేహితుడు బంజోలో డెలివరీ సర్వీస్ చేస్తున్నాడు. అతనికి మంచి జీతం వస్తోంది. నేను అక్కడ దరఖాస్తు చేసుకోవాలని అనుకున్నాను.",
            "or": "ମୁଁ କରିଥିଲି | ଏହି ଚାକିରି ଛାଡିଥିବା ମୋର ବନ୍ଧୁ Bunzo ରେ ଡେଲିଭରୀ ସେବା କରୁଛି | ସେ ଏକ ଭଲ ଦରମା ପାଉଛି | ମୁଁ ସେଠାରେ ଆବେଦନ କରିବାକୁ ଯୋଜନା କରୁଥିଲି |",
            "as": "মই বিচাৰিছিলোঁ। এই চাকৰি এৰি যোৱা মোৰ বন্ধুৱে বুঞ্জোত ডেলিভাৰী সেৱা গ্ৰহণ কৰিছে। সি এটা ভাল দৰমহা পাইছে। মই তাত আবেদন কৰাৰ কথা ভাবি আছিলো।",
            "gu": "મેં કર્યું. મારા મિત્ર જેણે આ નોકરી છોડી દીધી છે તેણે બંઝો ખાતે ડિલિવરી સેવાઓ લીધી છે. તેને સારો પગાર મળી રહ્યો છે. હું ત્યાં અરજી કરવાનું વિચારી રહ્યો હતો."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "I’m not sure if running more deliveries is the answer for you. But here, my brother had got this pamphlet home. He had gone to buy some sports equipment from this shop.",
            "hi": "मुझे नहीं लगता कि, डिलीवरी करने के लिए यहाँ वहाँ जाना तुम्हारे लिए सही है। यह लो, मेरा भाई यह टेंपलेट घर लाया था। वह एक स्पोर्ट्स का सामान खरीदने के लिए एक शॉप पर गया था।",
            "ka": "ಹೆಚ್ಚು ವಿತರಣೆಗಳನ್ನು ನಡೆಸುವುದು ನಿನಗೆ ಉತ್ತರವಾಗಿದೆಯೇ ಎಂದು ನನಗೆ ಖಚಿತವಿಲ್ಲ. ಆದರೆ ಇಲ್ಲಿ, ನನ್ನ ಸಹೋದರನಿಗೆ ಈ ಕರಪತ್ರವು ಮನೆಗೆ ಬಂದಿತ್ತು. ಈ ಅಂಗಡಿಯಿಂದ ಕೆಲವು ಕ್ರೀಡಾ ಸಾಮಗ್ರಿಗಳನ್ನು ಖರೀದಿಸಲು ಹೋಗಿದ್ದನು.",
            "te": "మరిన్ని డెలివరీలను అమలు చేయడం అనేది మీ కోసం సమాధానమని నాకు ఖచ్చితంగా తెలియదు. కానీ ఇక్కడ, నా సోదరుడు ఈ కరపత్రాన్ని ఇంటికి తెచ్చుకున్నాడు. అతను ఈ దుకాణం నుండి కొన్ని క్రీడా సామగ్రిని కొనుగోలు చేయడానికి వెళ్ళాడు.",
            "or": "ଅଧିକ ଡେଲିଭରି ଚଳାଇବା ଆପଣଙ୍କ ପାଇଁ ଉତ୍ତର କି ନୁହେଁ ମୁଁ ନିଶ୍ଚିତ ନୁହେଁ | କିନ୍ତୁ ଏଠାରେ, ମୋ ଭାଇ ଏହି ପାମ୍ପଲେଟ୍ ଘର ପାଇଥିଲେ | ସେ ଏହି ଦୋକାନରୁ କିଛି କ୍ରୀଡା ଉପକରଣ କିଣିବାକୁ ଯାଇଥିଲେ |",
            "as": "মই নিশ্চিত নহয় যে অধিক ডেলিভাৰী চলোৱাটো তোমাৰ বাবে উপায় হ’ব। কিন্তু চোৱা, মোৰ ভাইটিয়ে ঘৰত এই পত্ৰিকাখন পাইছিল। সি এই দোকানখনৰ পৰা কিছুমান ক্ৰীড়া সঁজুলি কিনিবলৈ গৈছিল।",
            "gu": "ખાતરી નથી કે વધુ ડિલિવરી ચલાવવી એ તમારા માટે જવાબ છે. પણ અહીં મારો ભાઈ આ પેમ્ફલેટ ઘરે લઈ આવ્યો. તે આ સ્ટોરમાંથી રમતગમતના કેટલાક સાધનો ખરીદવા ગયો હતો."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D3_1_5",
            "hi": "D3_1_5",
            "ka": "D3_1_5",
            "te": "D3_1_5",
            "or": "D3_1_5",
            "as": "D3_1_5",
            "gu": "D3_1_5"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Zaid hands him a printed job description from a company called MoveMore. It has 5 parts.\nNaveen has never seen such an elaborate requirement for a job.",
            "hi": "जावेद उसे मुव्हमोर नाम की एक कंपनी का प्रिंटेड जॉब वर्णन दिखाता है। इसके 5 भाग है। \n नवीन ने आज तक जॉब के एडवर्टाइजिंग के लिए इतना विस्तृत विवरण कभी नहीं देखा था।",
            "ka": "ಝೈದ್ ಅವರಿಗೆ ಮೂವ್ ಮೊರ್ ಎಂಬ ಕಂಪನಿಯಿಂದ ಮುದ್ರಿತ ಉದ್ಯೋಗ ವಿವರಣೆಯನ್ನು ಹಸ್ತಾಂತರಿಸುತ್ತಾನೆ. ಇದು 5 ಭಾಗಗಳನ್ನು ಹೊಂದಿದೆ.\n ನವೀನ್ ಉದ್ಯೋಗಕ್ಕಾಗಿ ಅಂತಹ ವಿಸ್ತಾರವಾದ ಅಗತ್ಯವನ್ನು ನೋಡಿಲ್ಲ.",
            "te": "మూవ్‌మోర్ అనే కంపెనీ నుండి ముద్రించిన ఉద్యోగ వివరణను జైద్ అతనికి అందజేస్తాడు. ఇందులో 5 భాగాలు ఉన్నాయి.\n ఉద్యోగం కోసం ఇంత విస్తృతమైన అవసరాన్ని నవీన్ ఎప్పుడూ చూడలేదు.",
            "or": "ଜାଏଦ୍‌ ତାଙ୍କୁ MoveMore ନାମକ ଏକ କମ୍ପାନୀରୁ ଏକ ମୁଦ୍ରିତ କାର୍ଯ୍ୟ ବର୍ଣ୍ଣନା ଦେଇଥାଏ | । ଏହାର 5ଟି ଭାଗ ଅଛି |\n ଚାକିରି ପାଇଁ ଏପରି ଏକ ବିସ୍ତୃତ ଆବଶ୍ୟକତା ନବୀନ କେବେ ଦେଖି ନାହାଁନ୍ତି |",
            "as": "জাইদে তাক মুভমোৰ নামৰ এটা কোম্পানীৰ পৰা মুদ্ৰিত চাকৰিৰ বিৱৰণ দিলে। ইয়াৰ 5 টা অংশ আছে। নবীনে কেতিয়াও চাকৰিৰ বাবে ইমান বিস্তাৰিত বিৱৰণী দেখা নাই।",
            "gu": "ઝાઈદ તેને મૂવમોર નામની કંપનીમાંથી પ્રિન્ટેડની નોકરીનું વર્ણન આપે છે. તેના 5 ભાગો છે.\nનવીને નોકરી માટે આટલી વિસ્તૃત જરૂરિયાત ક્યારેય જોઈ નથી."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "D3_1_6_popup",
            "hi": "D3_1_6_पॉपअप",
            "ka": "D3_1_6_ಪಾಪ್ಅಪ್",
            "te": "D3_1_6_పాప్అప్",
            "or": "D3_1_6_popup",
            "as": "D3_1_6_পপ্আপ্",
            "gu": "D3_1_6_પોપઅપ"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Click to know more about the MoveMore job.",
            "hi": "मुव्हमोर जॉब के बारे में अधिक जानने के लिए क्लिक करें।",
            "ka": "ಮೂವ್ ಮೊರ್ ಕೆಲಸದ ಕುರಿತು ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "te": "మూవ్‌మోర్ ఉద్యోగం గురించి ఇంకా తెలుసుకోవడానికి క్లిక్ చేయండి.",
            "or": "MoveMore କାର୍ଯ୍ୟ ବିଷୟରେ ଅଧିକ ଜାଣିବା ପାଇଁ କ୍ଲିକ୍ କରନ୍ତୁ |",
            "as": "মুভমোৰ চাকৰিৰ বিষয়ে অধিক জানিবলৈ ক্লিক কৰা।",
            "gu": "મૂવમોર જોબ વિશે વધુ જાણવા માટે ક્લિક કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "About the company",
            "hi": "कंपनी के बारे में",
            "ka": "ಕಂಪನಿಯ ಬಗ್ಗೆ",
            "te": "కంపెనీ గురించి",
            "or": "କମ୍ପାନୀ ବିଷୟରେ",
            "as": "কোম্পানীৰ বিষয়ে",
            "gu": "કંપની વિશે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Role description",
            "hi": "जॉब का विवरण",
            "ka": "ಪಾತ್ರ ವಿವರಣೆ",
            "te": "పాత్ర వివరణ",
            "or": "ଭୂମିକା ବର୍ଣ୍ଣନା",
            "as": "ভূমিকাৰ বিৱৰণ",
            "gu": "ભૂમિકા વર્ણન"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Qualifications needed",
            "hi": "आवश्यक योग्यता",
            "ka": "ಅಗತ್ಯವಿರುವ ಅರ್ಹತೆಗಳು",
            "te": "అవసరమైన అర్హతలు",
            "or": "ଯୋଗ୍ୟତା ଆବଶ୍ୟକ",
            "as": "প্ৰয়োজনীয় অৰ্হতা",
            "gu": "લાયકાત જરૂરી છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.display",
          "value": {
            "en": "Ideal candidate for this",
            "hi": "इसके लिए आदर्श उम्मीदवार",
            "ka": "ಇದಕ್ಕೆ ಸೂಕ್ತ ಅಭ್ಯರ್ಥಿ",
            "te": "దీనికి అనువైన అభ్యర్థి",
            "or": "ଏଥିପାଇଁ ଆଦର୍ଶ ପ୍ରାର୍ଥୀ",
            "as": "ইয়াৰ বাবে আদৰ্শ প্ৰাৰ্থী",
            "gu": "આ માટે આદર્શ ઉમેદવાર"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.display",
          "value": {
            "en": "Benefits",
            "hi": "लाभ",
            "ka": "ಪ್ರಯೋಜನಗಳು",
            "te": "ప్రయోజనాలు",
            "or": "ଲାଭ",
            "as": "লাভালাভসমূহ",
            "gu": "લાભો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "MoveMore is a family-owned company started in 1995. The company was created with the belief that sports equipment should be affordable to all.",
            "hi": "मुव्हमोर एक परिवार द्वारा चलाई जाने वाली कंपनी है जिसे 1995 मैं शुरू किया गया था। स्पोर्ट्स के उपकरण सब को उपलब्ध होने चाहिए इस विश्वास के साथ कंपनी की स्थापना हुई थी।",
            "ka": "ಮೂವ್‌ಮೋರ್ 1995 ರಲ್ಲಿ ಪ್ರಾರಂಭವಾದ ಕುಟುಂಬ-ಮಾಲೀಕತ್ವದ ಕಂಪನಿಯಾಗಿದೆ. ಕ್ರೀಡಾ ಸಾಮಗ್ರಿಗಳು ಎಲ್ಲರಿಗೂ ಕೈಗೆಟುಕುವ ಬೆಲೆಯಲ್ಲಿರಬೇಕು ಎಂಬ ನಂಬಿಕೆಯೊಂದಿಗೆ ಕಂಪನಿಯನ್ನು ರಚಿಸಲಾಗಿದೆ.",
            "te": "మూవ్‌మోర్ అనేది 1995లో స్టార్ట్ చేసిన కుటుంబ యాజమాన్య కంపెనీ. క్రీడా సామగ్రిని అందరికీ అందుబాటులో ఉండాలనే నమ్మకంతో ఈ కంపెనీ సృష్టించబడింది.",
            "or": "MoveMore ହେଉଛି 1995 ରେ ଆରମ୍ଭ ହୋଇଥିବା ଏକ ପରିବାର-ମାଲିକାନା କମ୍ପାନୀ | କମ୍ପାନୀ ସୃଷ୍ଟି ହୋଇଥିଲା ଏହି ବିଶ୍ୱାସ ସହିତ ଯେ କ୍ରୀଡା ଉପକରଣ ସମସ୍ତଙ୍କ ପାଇଁ ସୁଲଭ ହେବା ଉଚିତ୍‌ |",
            "as": "মুভমোৰ হৈছে 1995 চনত আৰম্ভ কৰা এটা পৰিয়ালৰ মালিকানাধীন কোম্পানী। কোম্পানীটো এই বিশ্বাসেৰে খোলা হৈছিল যে ক্ৰীড়া সঁজুলিবোৰ সকলোৰে বাবে সুলভ হ'ব লাগে।",
            "gu": "મૂવમોર એ એક પારિવારિક વ્યવસાય છે જે 1995 માં શરૂ થયો હતો. કંપનીની રચના એ આધાર પર કરવામાં આવી હતી કે રમતગમતના સાધનો દરેક માટે સુલભ હોવા જોઈએ."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Create a “WOW” customer experience by engaging and providing passionate service to customers who are visiting our store.\nBuild trust and educate your customer about the different offers.",
            "hi": "हमारे दौर में आने वाले ग्राहकों के साथ अच्छे से बातचीत करके और उन्हें बेहतर सेवा प्रदान करके ग्राहकों के लिए ” बढ़िया” अनुभव प्रदान करें।\n ग्राहकों का विश्वास हासिल करें और उन्हें अलग-अलग ऑफिस के बारे में जानकारी दें।",
            "ka": "ನಮ್ಮ ಅಂಗಡಿಗೆ ಭೇಟಿ ನೀಡುವ ಗ್ರಾಹಕರಿಗೆ ಉತ್ಸಾಹಭರಿತ ಸೇವೆಯನ್ನು ತೊಡಗಿಸಿಕೊಳ್ಳುವ ಮೂಲಕ ಮತ್ತು ಒದಗಿಸುವ ಮೂಲಕ \"ವಾವ್\" ಗ್ರಾಹಕರ ಅನುಭವವನ್ನು ರಚಿಸಿ.\n ನಂಬಿಕೆಯನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳಿ ಮತ್ತು ನಿಮ್ಮ ಗ್ರಾಹಕರಿಗೆ ವಿವಿಧ ಕೊಡುಗೆಗಳ ಬಗ್ಗೆ ಶಿಕ್ಷಣ ನೀಡಿ.",
            "te": "మా స్టోర్‌ను విసిట్ చేసే కస్టమర్‌లకు బాగా మాట్లాడడం మరియు వారితో ఆసక్తిని కలిగించే సేవను అందించడం ద్వారా \"వావ్\" కస్టమర్ అనుభవం క్రియేట్ చేయాలి. మీ కస్టమర్‌కు నమ్మకాన్ని పెంచాలి మరియు వివిధ ఆఫర్‌ల గురించి అవగాహన కల్పించాలి.",
            "or": "ଆମ ଷ୍ଟୋରକୁ ପରିଦର୍ଶନ କରୁଥିବା ଗ୍ରାହକମାନଙ୍କୁ ଉତ୍ସାହିତ ଏବଂ ଉତ୍ସାହଜନକ ସେବା ଯୋଗାଇ ଏକ “WOW” ଗ୍ରାହକ ଅଭିଜ୍ଞତା ସୃଷ୍ଟି କରନ୍ତୁ |\n ବିଶ୍ୱାସ ଗଢନ୍ତୁ ଏବଂ ବିଭିନ୍ନ ଅଫର୍‌ ବିଷୟରେ ଆପଣଙ୍କ ଗ୍ରାହକଙ୍କୁ ଶିକ୍ଷା ଦିଅନ୍ତୁ |",
            "as": "আমাৰ দোকানলৈ অহা গ্ৰাহকসকলক আৱেগিক সেৱা প্ৰদান কৰি \"ৱাও\" গ্ৰাহকৰ অভিজ্ঞতা সৃষ্টি কৰা। বিশ্বাস গঢ়ি তোলা আৰু বিভিন্ন অফাৰৰ বিষয়ে তোমাৰ গ্ৰাহকক শিক্ষিত কৰা।",
            "gu": "અમારા સ્ટોરની મુલાકાત લેતા ગ્રાહકોને સંલગ્ન અને જુસ્સાદાર સેવા પ્રદાન કરીને એક 'અદ્ભુત' ગ્રાહક અનુભવ બનાવો.\nવિશ્વાસ ઊભો કરવો અને ગ્રાહકોને વિવિધ ઑફર વિશે શિક્ષિત કરવું. \""
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "10th pass\n0 to 2 years of experience in Retail\nFluent in English and Kannada",
            "hi": "10वीं पास \n  रिटेल में 0 से 2 साल का अनुभव \n अंग्रेजी और कन्नड भाषा पर प्रभुत्व",
            "ka": "10 ನೇ ತೇರ್ಗಡೆ ರಿಟೇಲ್ ವ್ಯಾಪಾರದಲ್ಲಿ 0 ರಿಂದ 2 ವರ್ಷಗಳ ಅನುಭವ ಇಂಗ್ಲಿಷ್ ಮತ್ತು ಕನ್ನಡದಲ್ಲಿ ನಿರರ್ಗಳವಾಗಿ ಮಾತನಾಡಬಲ್ಲರು",
            "te": "10వ తరగతి పాస్\n రిటైల్‌లో 0 నుండి 2 సంవత్సరాల అనుభవం\n ఇంగ్లీష్ మరియు కన్నడ భాషలలో నిష్ణాతులు\"",
            "or": "10th ପାସ୍\n ରିଟେଲରେ 0 ରୁ 2 ବର୍ଷର ଅଭିଜ୍ଞତା \n ଇଂରାଜୀ ଏବଂ କନ୍ନଡ ଭାଷାରେ ପାରଙ୍ଗମ",
            "as": "10ম উত্তীৰ্ণ ৰিটেইলত 0 ৰ পৰা 2 বছৰৰ অভিজ্ঞতা ইংৰাজী আৰু কানাড়াত সাবলীলতা",
            "gu": "10મું પાસ\nરિટેલમાં 0 થી 2 વર્ષનો અનુભવ\nઅંગ્રેજી અને કન્નડમાં પ્રભુતવા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.popUpText",
          "value": {
            "en": "Strong understanding of and passion for sports/physical activity\nCustomer-oriented\nA great team player",
            "hi": "स्पोर्ट/ फिजिकल एक्टिविटी के बारे में अच्छी समझ और जुनून \n ग्राहक- केंद्री \n अच्छा टीम प्लेयर",
            "ka": "ಕ್ರೀಡೆ/ದೈಹಿಕ ಚಟುವಟಿಕೆಯ ಬಗ್ಗೆ ಬಲವಾದ ತಿಳುವಳಿಕೆ ಮತ್ತು ಉತ್ಸಾಹ\n ಗ್ರಾಹಕ-ಆಧಾರಿತ\n ಶ್ರೇಷ್ಠ ತಂಡದ ಆಟಗಾರ",
            "te": "\"క్రీడలు/శారీరక కార్యకలాపాల పట్ల బలమైన అవగాహన మరియు అభిరుచి\n కస్టమర్-ఆధారిత\n గొప్ప జట్టు ఆటగాడు\"",
            "or": "କ୍ରୀଡା/ଶାରୀରିକ କାର୍ଯ୍ୟକଳାପ ପାଇଁ ଦୃଢ ବୁଝାମଣା ଏବଂ ଉତ୍ସାହ \n ଗ୍ରାହକ-ଆଧାରିତ \n ଏକ ଉତ୍ତମ ଦଳ ଖେଳାଳି",
            "as": "ক্ৰীড়া/শাৰীৰিক সক্ৰিয়তাৰ প্ৰতি শক্তিশালী বুজাবুজি আৰু আগ্ৰহ গ্ৰাহক-মুখী এজন মহান দলীয় খেলুৱৈ",
            "gu": "રમત/શારીરિક પ્રવૃત્તિની મજબૂત સમજ અને જુસ્સો\nગ્રાહક લક્ષી\nએક મહાન ટીમ ખેલાડી"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.popUpText",
          "value": {
            "en": "Full Healthcare Package\nPaid Time Off\n25% Off All sports products",
            "hi": "संपूर्ण हेल्थ केयर पैकेज\n पेड़ टाइम ऑफ \n सभी स्पोर्ट्स प्रोडक्ट पर 25% छूट",
            "ka": "ಸಂಪೂರ್ಣ ಆರೋಗ್ಯ ಪ್ಯಾಕೇಜ್\n ಪಾವತಿಸಿದ ಸಮಯ ರಜೆ\n ಎಲ್ಲಾ ಕ್ರೀಡಾ ಉತ್ಪನ್ನಗಳಿಗೆ 25% ರಿಯಾಯಿತಿ",
            "te": "పూర్తి ఆరోగ్య సంరక్షణ ప్యాకేజీ\n చెల్లించవలసిన సమయం ముగిసింది\n అన్ని క్రీడా ఉత్పత్తులపై 25% తగ్గింపు",
            "or": "ସମ୍ପୂର୍ଣ୍ଣ ସ୍ୱାସ୍ଥ୍ୟଯତ୍ନ ପ୍ୟାକେଜ୍ \n ପେଡ୍ ଟାଇମ୍ ଅଫ୍ |\n ସମସ୍ତ କ୍ରୀଡା ଉତ୍ପାଦଗୁଡିକ 25% ଛାଡ୍‍",
            "as": "সম্পূৰ্ণ স্বাস্থ্যসেৱা পেকেজ পৰিশোধ কৰা সময় বন্ধ সকলো ক্ৰীড়া সামগ্ৰীৰ ওপৰত 25% ৰেহাই",
            "gu": "સંપૂર્ણ હેલ્થકેર પેકેજ\nચૂકવેલ સમય બંધ\nતમામ રમતગમત ઉત્પાદનો પર 25% છૂટ"
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "D3_2_1",
            "hi": "D3_2_1",
            "ka": "D3_2_1",
            "te": "D3_2_1",
            "or": "D3_2_1",
            "as": "D3_2_1",
            "gu": "D3_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen is quite impressed with the job description. He feels this is just for him, but the salary is less than he can make in the new delivery job.",
            "hi": "नवीन जॉब के विवरण को लेकर काफी इंप्रेस हुआ। उसे लगता है कि यह चौक उसके लिए सही है, लेकिन यहाँ मिलने वाली सैलरी नए डिलीवरी जॉब की सैलरी से कम है",
            "ka": "ಕೆಲಸದ ವಿವರಣೆಯಿಂದ ನವೀನ್ ಸಾಕಷ್ಟು ಪ್ರಭಾವಿತರಾಗಿದ್ದಾರೆ. ಇದು ತನಗೆ ಮಾತ್ರ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ, ಆದರೆ ಹೊಸ ವಿತರಣಾ ಕೆಲಸದಲ್ಲಿ ಅವನು ಮಾಡಬಹುದಾದ ಸಂಬಳಕ್ಕಿಂತ ಕಡಿಮೆ ಸಂಬಳ.",
            "te": "ఉద్యోగ వివరణతో నవీన్ బాగా ఆకట్టుకున్నాడు. ఇది తన కోసమేనని అతను భావిస్తున్నాడు, అయితే కొత్త డెలివరీ ఉద్యోగంలో అతను పొందగలిగే దానికంటే జీతం తక్కువగా ఉంది.",
            "or": "ଚାକିରିର ବର୍ଣ୍ଣନାରେ ନବୀନ ବହୁତ ପ୍ରଭାବିତ | ସେ ଅନୁଭବ କରୁଛନ୍ତି ଯେ ଏହା କେବଳ ତାଙ୍କ ପାଇଁ, କିନ୍ତୁ ନୂତନ ଡେଲିଭରି ଚାକିରିରେ ଯେତିକି କମେଇବା କଥା ତାଠୁ ଦରମା କମ୍ ଅଛି|",
            "as": "নবীন চাকৰিৰ বিৱৰণত যথেষ্ট প্ৰভাৱিত হ’ল। সি অনুভৱ কৰে যে এইটো কেৱল তাৰ বাবে, কিন্তু নতুন ডেলিভাৰী চাকৰিতোত সি পাবলগীয়াতকৈ ইয়াত দৰমহা কম।",
            "gu": "નવીન નોકરીના વર્ણનથી ખૂબ પ્રભાવિત છે. તેને લાગે છે કે આ ફક્ત તેના માટે છે, પરંતુ નવી ડિલિવરી જોબમાં તે કરી શકે તેટલો પગાર ઓછો છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "D3_2_2",
            "hi": "D3_2_2",
            "ka": "D3_2_2",
            "te": "D3_2_2",
            "or": "D3_2_2",
            "as": "D3_2_2",
            "gu": "D3_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He has to either choose a job that has growth if he sticks to it for a few years or take up a job that pays him more.",
            "hi": "उससे ऐसी जॉब ढूंढनी होगी जहाँ पर कुछ साल काम करने के बाद उसे प्रमोशन मिले या फिर उसे बेहतर सैलरी वाली जॉब लेनी होगी",
            "ka": "ಅವನು ಕೆಲವು ವರ್ಷಗಳವರೆಗೆ ಕೆಲಸಮಾಡಿದರೆ ಬೆಳವಣಿಗೆಯನ್ನು ಹೊಂದಿರುವ ಕೆಲಸವನ್ನು ಆರಿಸಿಕೊಳ್ಳಬೇಕು ಅಥವಾ ಅವನಿಗೆ ಹೆಚ್ಚು ಸಂಬಳ ನೀಡುವ ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕು.",
            "te": "అతను కొన్నేళ్లపాటు అంటిపెట్టుకుని ఉంటే ఎదుగుదల ఉన్న ఉద్యోగాన్ని ఎంచుకోవాలి లేదా ఎక్కువ జీతం వచ్చే ఉద్యోగాన్ని చేపట్టాలి.",
            "or": "ତାଙ୍କୁ ଏମିତି ଏକ ଚାକିରି ବାଛିବାକୁ ପଡିବ ଯାହାର ଅଭିବୃଦ୍ଧି ଅଛି ଯଦି ସେ କିଛି ବର୍ଷ ପର୍ଯ୍ୟନ୍ତ ଲାଗି ରୁହନ୍ତି କିମ୍ବା ଏମିତି ଏକ ଚାକିରି ନିଅନ୍ତି ଯେ ତାଙ୍କୁ ଅଧିକ ପାଉଣା ଦେବ |",
            "as": "সি হয় এনে এটা চাকৰি ল’ব লাগিব য'ত বিকাশ আছে যদি সি কেইবছৰমান অপেক্ষা কৰে বা তাক অধিক দৰমহা দিয়া চাকৰি এটা ল'ব লাগিব।",
            "gu": "જો તે થોડા વર્ષો સુધી તેને વળગી રહે તો તેણે કાં તો વૃદ્ધિ સાથે નોકરી પસંદ કરવી પડશે અથવા તેને વધુ પગાર આપતી નોકરી લેવી પડશે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "D3_2_3_D",
            "hi": "D3_2_3_D",
            "ka": "D3_2_3_D",
            "te": "D3_2_3_D",
            "or": "D3_2_3_D",
            "as": "D3_2_3_D",
            "gu": "D3_2_3_D"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Naveen do?",
            "hi": "नवीन को क्या करना चाहिए?",
            "ka": "ನವೀನ್ ಏನು ಮಾಡಬೇಕು?",
            "te": "నవీన్ ఏం చేయాలి?",
            "or": "ନବୀନ କଣ କରିବା ଉଚିତ୍ ?",
            "as": "নবীনে কি কৰা উচিত?",
            "gu": "નવીને શું કરવી જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Join the sports company and work for better career growth",
            "hi": "स्पोर्ट्स कंपनी में ज्वाइन होना चाहिए और बेहतर करियर वृद्धि के लिए काम करना चाहिए",
            "ka": "ಕ್ರೀಡಾ ಕಂಪನಿಗೆ ಸೇರಿ ಮತ್ತು ಉತ್ತಮ ವೃತ್ತಿ ಬೆಳವಣಿಗೆಗೆ ಕೆಲಸ ಮಾಡಿ",
            "te": "క్రీడా సంస్థలో చేరాలి మరియు మెరుగైన కెరీర్ వృద్ధి కోసం పని చేయాలి",
            "or": "କ୍ରୀଡା କମ୍ପାନୀରେ ଯୋଗ ଦିଅନ୍ତୁ ଏବଂ ଉନ୍ନତ କ୍ଯାରିୟର୍‌ ଅଭିବୃଦ୍ଧି ପାଇଁ କାର୍ଯ୍ୟ କରନ୍ତୁ",
            "as": "ক্ৰীড়া কোম্পানীত যোগদান কৰিব আৰু উন্নত জীৱিকা বিকাশৰ বাবে কাম কৰিব",
            "gu": "રમતગમતની કંપનીમાં જોડાવું જોઇયે અને કારકિર્દીની સારી વૃદ્ધિ માટે કામ કરવું"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Join as a delivery agent in Bunzo with a higher salary",
            "hi": "बंझो में अधिक सैलरी वाली डिलीवरी एजेंट की जॉब करनी चाहिए",
            "ka": "ಹೆಚ್ಚಿನ ಸಂಬಳದೊಂದಿಗೆ ಬುಂಝೋ ನಲ್ಲಿ ಡೆಲಿವರಿ ಏಜೆಂಟ್ ಆಗಿ ಸೇರಿರಿ",
            "te": "అధిక జీతంతో బంజోలో డెలివరీ ఏజెంట్‌గా చేరాలి",
            "or": "ଅଧିକ ବେତନ ସହିତ Bunzo ରେ ଏକ ଡେଲିଭରୀ ଏଜେଣ୍ଟ ଭାବରେ ଯୋଗ ଦିଅନ୍ତୁ",
            "as": "অধিক দৰমহাৰ সৈতে বুঞ্জোত ডেলিভাৰী এজেণ্ট হিচাপে যোগদান কৰিব",
            "gu": "ઉચ્ચ પગાર સાથે બંઝો ડિલિવરી એજન્ટ તરીકે જોડાઓ"
          }
        }
      ]
    },
    "scene28": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D4_1_1_Join the sports company",
            "hi": "D4_1_1_स्पोर्ट्स कंपनी में ज्वाइन होना",
            "ka": "D4_1_1_ಕ್ರೀಡಾ ಕಂಪನಿಗೆ ಸೇರಿ",
            "te": "D4_1_1_క్రీడా సంస్థలో చేరాలి",
            "or": "D4_1_1_Join the sports company",
            "as": "D4_1_1_ক্ৰীড়া কোম্পানীত যোগদান",
            "gu": "D4_1_1_રમતગમતની કંપનીમાં જોડાવું"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen applies to the sports company. After a quick interview, they were impressed with his enthusiasm and passion. He joins MoveMore as a fitness sales assistant.",
            "hi": "नवीन स्पोर्ट्स कंपनी में जॉब के लिए अप्लाई करता है। इंटरव्यू होने के बाद वह नवीन के उत्सुकता और जुनून को देखकर इंप्रेस होते हैं। वह मुव्हमोर में फिटनेस सेल्स असिस्टेंट के तौर पर काम करना शुरू करता है।",
            "ka": "ನವೀನ್ ಕ್ರೀಡಾ ಕಂಪನಿಗೆ ಅನ್ವಯಿಸುತ್ತಾನೆ. ತ್ವರಿತ ಸಂದರ್ಶನದ ನಂತರ, ಅವರು ಅವನ ಉತ್ಸಾಹ ಮತ್ತು ಉತ್ಸಾಹದಿಂದ ಪ್ರಭಾವಿತರಾದರು. ಅವನು ಮೂವ್‌ಮೋರ್‌ಗೆ ಫಿಟ್‌ನೆಸ್ ಮಾರಾಟ ಸಹಾಯಕರಾಗಿ ಸೇರುತ್ತಾನೆ.",
            "te": "నవీన్ స్పోర్ట్స్ కంపెనీకి దరఖాస్తు చేశాడు. శీఘ్ర ఇంటర్వ్యూ తర్వాత, వారు అతని ఉత్సాహం మరియు అభిరుచికి ముగ్ధులయ్యారు. అతను మూవ్‌మోర్‌లో ఫిట్‌నెస్ సేల్స్ అసిస్టెంట్‌గా చేరతాడు",
            "or": "ନବୀନ କ୍ରୀଡା କମ୍ପାନୀ ପାଇଁ ଆବେଦନ କରିଛି | ତୁରନ୍ତ ସାକ୍ଷାତକାର ପରେ, ସେମାନେ ତାଙ୍କର ଉତ୍ସାହ ଏବଂ ଆଗ୍ରହ ରେ ପ୍ରଭାବିତ ହୋଇଥିଲେ | ସେ MoveMore ଫିଟନେସ୍ ବିକ୍ରୟ ସହାୟକ ଭାବରେ ରେ ଯୋଗ ଦିଅନ୍ତି |",
            "as": "নবীনে ক্ৰীড়া কোম্পানীৰ বাবে আবেদন কৰে। এক দ্ৰুত সাক্ষাৎকাৰৰ পিছত, তেওঁলোক তাৰ উৎসাহ আৰু আবেগত প্ৰভাৱিত হ’ল। সি মুভমোৰত ফিটনেছ বিক্ৰী সহায়ক হিচাপে যোগদান কৰে।",
            "gu": "નવીન રમતગમત કંપનીમાં કામ કરવા માટે અરજી કરી રહ્યો છે. એક ઝડપી મુલાકાત પછી, તેઓ તેમના ઉત્સાહ અને જુસ્સાથી પ્રભાવિત થયા. ફિટનેસ સેલ્સ સહાયક તરીકે મૂવમોરમાં જોડાઓ."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D4_1_2",
            "hi": "D4_1_2",
            "ka": "D4_1_2",
            "te": "D4_1_2",
            "or": "D4_1_2",
            "as": "D4_1_2",
            "gu": "D4_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He undergoes a week-long training on how to interact with customers.",
            "hi": "उसे ग्राहकों के साथ बातचीत कैसे की जाती है, यह सीखने के लिए एक हफ्ते का प्रशिक्षण दिया जाता है।",
            "ka": "ಗ್ರಾಹಕರೊಂದಿಗೆ ಹೇಗೆ ಸಂವಹನ ನಡೆಸಬೇಕು ಎಂಬುದರ ಕುರಿತು ಅವನು ಒಂದು ವಾರದ ತರಬೇತಿಯನ್ನು ಪಡೆಯುತ್ತಾನೆ.",
            "te": "కస్టమర్‌లతో ఎలా ఇంటరాక్ట్ అవ్వాలనే దానిపై వారం రోజుల పాటు శిక్షణ తీసుకుంటాడు.",
            "or": "ଗ୍ରାହକଙ୍କ ସହ କିପରି ପାରସ୍ପରିକ କଥାବାର୍ତ୍ତା କରିବେ ସେ ବିଷୟରେ ସେ ଏକ ସପ୍ତାହ ବ୍ୟାପୀ ତାଲିମ ନିଅନ୍ତି |",
            "as": "সি গ্ৰাহকৰ সৈতে কেনেদৰে বাৰ্তালাপ কৰিব লাগে তাৰ ওপৰত এক সপ্তাহজোৰা প্ৰশিক্ষণ ল’লে।",
            "gu": "ગ્રાહકો સાથે કેવી રીતે વાતચીત કરવી તે અંગે તે એક સપ્તાહની તાલીમ લે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D4_1_3",
            "hi": "D4_1_3",
            "ka": "D4_1_2",
            "te": "D4_1_3",
            "or": "D4_1_3",
            "as": "D4_1_3",
            "gu": "D4_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He reports to a senior executive, Praveen. He observes Praveen for a few days.",
            "hi": "वह अपनी सीनियर एक्जीक्यूटिव प्रवीण को रिपोर्ट करता है। वह कुछ दिनों तक प्रवीण का निरीक्षण करता है।",
            "ka": "ಅವನು ಹಿರಿಯ ಕಾರ್ಯನಿರ್ವಾಹಕ ಪ್ರವೀಣ್ ಅವರಿಗೆ ವರದಿ ಮಾಡುತ್ತಾನೆ. ಅವನು ಪ್ರವೀಣ್‌ನನ್ನು ಕೆಲವು ದಿನ ಗಮನಿಸುತ್ತಾನೆ.",
            "te": "అతను సీనియర్ ఎగ్జిక్యూటివ్ ప్రవీణ్‌కి రిపోర్ట్ చేసేవాడు. కొన్ని రోజులుగా ప్రవీణ్‌ని గమనిస్తాడు.",
            "or": "ସେ ଜଣେ ସିନିୟର ଏକ୍ସିକ୍ୟୁଟିଭ୍‍ ପ୍ରବୀଣଙ୍କୁ ରିପୋର୍ଟ କରନ୍ତି। ସେ ପ୍ରବୀଣଙ୍କୁ କିଛି ଦିନ ପାଇଁ ଦେଖନ୍ତି |",
            "as": "সি এজন জ্যেষ্ঠ কাৰ্যবাহী প্ৰবীণক প্ৰতিবেদন দিব লাগে। সি প্ৰবীণক কেইদিনমানৰ বাবে পৰ্যবেক্ষণ কৰে।",
            "gu": "તે ઉપરી અધિકારી પ્રવીણને રિપોર્ટ કરે છે. તે થોડા દિવસ પ્રવીણનું નિરીક્ષણ કરે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D4_2_1",
            "hi": "D4_2_1",
            "ka": "D4_1_2",
            "te": "D4_2_1",
            "or": "D4_2_1",
            "as": "D4_2_1",
            "gu": "D4_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "As a sales assistant, Naveen has to keep updating himself on the different fitness techniques. He attends training and sports meets that are conducted by the organisation.",
            "hi": "सेल असिस्टेंट के तौर पर, नवीन को फिटनेस टेक्निक के अलग-अलग तरीकों के बारे में खुद को अपडेट रखना पड़ता है। वह संस्था द्वारा आयोजित ट्रेनिंग और स्पोर्ट्स के मीटिंग में हिस्सा लेता है।",
            "ka": "ಮಾರಾಟ ಸಹಾಯಕನಾಗಿ, ನವೀನ್ ವಿಭಿನ್ನ ಫಿಟ್‌ನೆಸ್ ತಂತ್ರಗಳ ಬಗ್ಗೆ ತನ್ನನ್ನು ತಾನು ನವೀಕರಿಸಿಕೊಳ್ಳುತ್ತಿರಬೇಕು. ಅವನು ಸಂಸ್ಥೆಯು ನಡೆಸುವ ತರಬೇತಿ ಮತ್ತು ಕ್ರೀಡಾಕೂಟಗಳಿಗೆ ಹಾಜರಾಗುತ್ತಾನೆ.",
            "te": "సేల్స్ అసిస్టెంట్‌గా, నవీన్ విభిన్నమైన ఫిట్‌నెస్ టెక్నిక్‌లపై తనను తాను అప్‌డేట్ చేసుకుంటూ ఉండాలి. అతను సంస్థ నిర్వహించే శిక్షణ మరియు క్రీడా సమావేశాలకు హాజరవుతున్నాడు.",
            "or": "ଜଣେ ସେଲ୍ସ ଆସିସ୍‍ଟାଣ୍ଟ୍‌ ଭାବରେ, ନବୀନଙ୍କୁ ବିଭିନ୍ନ ଫିଟନେସ୍ କୌଶଳରେ ନିଜକୁ ଅପଡେଟ୍ କରିବାକୁ ପଡିବ | ସେ ପ୍ରଶିକ୍ଷଣ ଏବଂ କ୍ରୀଡା ସଭାଗୁଡ଼ିକରେ ଯୋଗ ଦିଅନ୍ତି ଯାହା ସଂସ୍ଥା ଦ୍ୱାରା ପରିଚାଳିତ ହୁଏ |",
            "as": "এজন বিক্ৰী সহায়ক হিচাপে, নবীনে বিভিন্ন ফিটনেছ কৌশলৰ বিষয়ে নিজকে আপডেট কৰি থাকিব লাগিব। তেওঁ সংগঠনটোৰ দ্বাৰা পৰিচালিত প্ৰশিক্ষণ আৰু ক্ৰীড়া সভাত অংশগ্ৰহণ কৰে।",
            "gu": "સેલ્સ સહાયક તરીકે, નવીને પોતાની જાતને અલગ-અલગ ફિટનેસ ટેકનિક વિશે બદલાવ કરતા રહેવું પડશે. તે સંસ્થા દ્વારા આયોજિત તાલીમ અને રમતગમતની બેઠકોમાં હાજરી આપે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D4_2_2",
            "hi": "D4_2_2",
            "ka": "D4_2_2",
            "te": "D4_2_2",
            "or": "D4_2_2",
            "as": "D4_2_2",
            "gu": "D4_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Naveen gets good feedback from the customers. After his initial effort of upskilling himself, he wins the employee of the month award.",
            "hi": "नवीन को ग्राहकों द्वारा अच्छा फीडबैक मिलता है। खुद को अपील करने के प्रयास के बाद वह एंप्लॉयी ऑफ द मंथ का पुरस्कार जीत जाता है।",
            "ka": "ನವೀನ್ ಗ್ರಾಹಕರಿಂದ ಉತ್ತಮ ಪ್ರತಿಕ್ರಿಯೆ ಪಡೆಯುತ್ತಾನೆ. ತನ್ನನ್ನು ತಾನೇ ಉನ್ನತೀಕರಿಸುವ ಆರಂಭಿಕ ಪ್ರಯತ್ನದ ನಂತರ, ಅವನು ತಿಂಗಳ ಉದ್ಯೋಗಿ ಪ್ರಶಸ್ತಿಯನ್ನು ಗೆಲ್ಲುತ್ತಾನೆ.",
            "te": "నవీన్‌కి కస్టమర్ల నుంచి మంచి ఫీడ్‌బ్యాక్ వస్తుంది. తనను తాను నైపుణ్యం పెంచుకోవడానికి అతని ప్రారంభ ప్రయత్నం తర్వాత, అతను నెలలో ఉద్యోగి అవార్డును గెలుచుకున్నాడు.",
            "or": "ଗ୍ରାହକଙ୍କ ଠାରୁ ନବୀନ ଭଲ ମତାମତ ପାଆନ୍ତି | ନିଜର କୌଶଳ ବଢେଇବା ପ୍ରାରମ୍ଭିକ ପ୍ରୟାସ ପରେ, ସେ ମାସର କର୍ମଚାରୀ ଆୱାର୍ଡ ଜିତନ୍ତି |",
            "as": "নবীনে গ্ৰাহকৰ পৰা ভাল প্ৰতিক্ৰিয়া লাভ কৰে। নিজকে উন্নত কৰাৰ প্ৰাৰম্ভিক প্ৰচেষ্টাৰ পিছত, সি মাহটোৰ শ্ৰেষ্ঠ কৰ্মচাৰী বঁটা লাভ কৰে।",
            "gu": "નવીનને ગ્રાહકો તરફથી સારો પ્રતિસાદ મળે છે. તેમની કુશળતા સુધારવાના તેમના પ્રારંભિક પ્રયાસો પછી, તે મહિનાના સારા કર્મચારીનો એવોર્ડ જીત્યો."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "D4_2_3",
            "hi": "D4_2_3",
            "ka": "D4_2_3",
            "te": "D4_2_3",
            "or": "D4_2_3",
            "as": "D4_2_3",
            "gu": "D4_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen has heard about people joining in as sales assistants and getting promoted because of their hard work and talent.",
            "hi": "नवीन ने सुना था कि लोग सेल्स असिस्टेंट के तौर पर ज्वाइन करके, अपनी मेहनत और कौशल के बल पर प्रमोशन पा सकते हैं।",
            "ka": "ತಮ್ಮ ಕಠಿಣ ಪರಿಶ್ರಮ ಮತ್ತು ಪ್ರತಿಭೆಯಿಂದಾಗಿ ಮಾರಾಟ ಸಹಾಯಕರಾಗಿ ಸೇರ್ಪಡೆಗೊಳ್ಳುವ ಮತ್ತು ಬಡ್ತಿ ಪಡೆಯುವವರ ಬಗ್ಗೆ ನವೀನ್ ಕೇಳಿದ್ದಾನೆ.",
            "te": "సేల్స్ అసిస్టెంట్లుగా చేరి, కష్టపడి, ప్రతిభతో ప్రమోషన్ పొందుతున్న వారి గురించి నవీన్ విన్నాడు.",
            "or": "ନବୀନ ଲୋକମାନଙ୍କର ବିକ୍ରୟ ସହାୟକ ଭାବରେ ଯୋଗଦେବା ଏବଂ ସେମାନଙ୍କର କଠିନ ପରିଶ୍ରମ ଏବଂ ପ୍ରତିଭା ହେତୁ ପଦୋନ୍ନତି ପାଇବା ବିଷୟରେ ଶୁଣିଛନ୍ତି |",
            "as": "নবীনে শুনিছে যে মানুহে বিক্ৰী সহায়ক হিচাপে যোগদান কৰাৰ পিছত তেওঁলোকৰ কঠোৰ পৰিশ্ৰম আৰু প্ৰতিভাৰ বাবে পদোন্নতি লাভ কৰিছে।",
            "gu": "નવીને એવા લોકો વિશે સાંભળ્યું જેઓ સેલ્સ સહાયક તરીકે બિઝનેસમાં જોડાયા હતા અને તેમની મહેનત અને પ્રતિભાને કારણે પ્રમોટ કરવામાં આવ્યા હતા."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "D4_2_4",
            "hi": "D4_2_4",
            "ka": "D4_2_4",
            "te": "D4_2_4",
            "or": "D4_2_4",
            "as": "D4_2_4",
            "gu": "D4_2_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He feels hopeful he can also take up a senior position soon but Naveen is unable to practice dance as much.",
            "hi": "उसे उम्मीद है कि वह जल्द ही सीनियर पोजीशन हासिल कर लेगा लेकिन नवीन को डान्स की प्रैक्टिस करने के लिए ज्यादा समय नहीं मिलता।",
            "ka": "ಅವನು ಶೀಘ್ರದಲ್ಲೇ ಉನ್ನತ ಸ್ಥಾನವನ್ನು ಪಡೆದುಕೊಳ್ಳಬಹುದು ಎಂದು ಅವರು ಭಾವಿಸುತ್ತಾನೆ ಆದರೆ ನವೀನ್ ನೃತ್ಯವನ್ನು ಹೆಚ್ಚು ಅಭ್ಯಾಸ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ.",
            "te": "అతను త్వరలో సీనియర్ పదవిని కూడా చేయగలనని అతను నమ్మకంతో ఉన్నాడు కానీ నవీన్ డ్యాన్స్ సాధన అంతగా చేయలేకపోయాడు.",
            "or": "ସେ ଆଶା କରୁଛନ୍ତି ଯେ ସେ ଶୀଘ୍ର ଏକ ବରିଷ୍ଠ ପଦବୀ ମଧ୍ୟ ନେଇପାରିବେ କିନ୍ତୁ ନବୀନ ସେତେ ଅଧିକ ନୃତ୍ୟ ଅଭ୍ୟାସ କରିବାରେ ଅସମର୍ଥ |",
            "as": "সি আশাবাদী যে সি সোনকালেই জ্যেষ্ঠ পদ পাব কিন্তু নবীনে নৃত্যৰ অনুশীলন কৰিবলৈ সময় নোপোৱা হৈছে।",
            "gu": "તેને આશા છે કે તે ટૂંક સમયમાં ઉપરી અધિકારી તરીકાનો હોદ્દો પણ સંભાળી શકે છે પરંતુ નવીન તેટલી ડાન્સ પ્રેક્ટિસ કરવામાં અસમર્થ છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "Upskilling",
            "hi": "अपस्किल करना",
            "ka": "ಉನ್ನತ ಕೌಶಲ್ಯ",
            "te": "నైపుణ్యం పెంచడం",
            "or": "ଅପ୍‌ସ୍କିଲିଂ",
            "as": "আপস্কিলিং",
            "gu": "ઉચ્ચ કૌશલ્ય"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Upskilling experience!",
            "hi": "आपने अभी-अभी अपस्किल करने का अनुभव हासिल किया हैं!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಕೌಶಲ್ಯದ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే నైపుణ్యం పెంచుకునే అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ଅପ୍‌ସ୍କିଲିଂ ଅଭିଜ୍ଞତା ହାସଲ କରିଛନ୍ତି !",
            "as": "তুমি এইমাত্ৰ আপস্কিলিং অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "તમે હમણાં જ ઉચ્ચ કૌશલ્યનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Upskilling",
            "hi": "अपस्किल करना",
            "ka": "ಉನ್ನತ ಕೌಶಲ್ಯ",
            "te": "నైపుణ్యం పెంచడం",
            "or": "ଅପ୍‌ସ୍କିଲିଂ",
            "as": "আপস্কিলিং",
            "gu": "ઉચ્ચ કૌશલ્ય"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर विकास कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "కెరీర్ అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ଯାରିୟର୍‌ ବିକାଶ କୌଶଳ",
            "as": "জীৱিকা বিকাশৰ দক্ষতা",
            "gu": "કારકિર્દી વિકાસ કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Naveen joins MoveMore as a fitness sales assistant. As part of his job, he needs to know about the different sports equipment and fitness techniques. He also attends sports training and sports meet to know more. As you start working or look to change your role, you might realise that you need to improve or learn a new skill. Upskilling will help you learn a new skill or get certified in a specific area. You can upskill to better in your current job or to apply for a new role.",
            "hi": "नवीन मुव्हमोर मैं फिटनेस सेल्स असिस्टेंट के तौर पर काम करना शुरू करता है। उसकी काम के लिए, उसे अलग-अलग स्पोर्ट्स के उपकरण और फिटनेस तकनीक के बारे में जानकारी हासिल करनी पड़ती है। वह स्पोर्ट्स ट्रेनिंग और स्पोर्ट्स मीटिंग में भी हिस्सा लेता है, ताकि वह अधिक जानकारी प्राप्त कर सके। जब आप काम करना शुरू करते हैं या अपना रोल बदलना करना चाहते हैं, तब आपको लगेगा कि ,आपको मैं नए कौशल सीखने या उनमें सुधार करने की आवश्यकता है। अपस्किल करने से, आप नए कौशल सीखते हैं और किसी विशेष क्षेत्र में प्रमाण पत्र हासिल कर सकते हैं। आप अपने मौजूदा जॉब में अच्छा कार्य करने के लिए या नए रोल के लिए अप्लाई करने के लिए अब स्किल कर सकते हैं।",
            "ka": "ನವೀನ್ ಮೂವ್‌ಮೋರ್‌ಗೆ ಫಿಟ್‌ನೆಸ್ ಮಾರಾಟ ಸಹಾಯಕನಾಗಿ ಸೇರುತ್ತಾನೆ. ಅವನ ಕೆಲಸದ ಭಾಗವಾಗಿ, ಅವನು ವಿವಿಧ ಕ್ರೀಡಾ ಉಪಕರಣಗಳು ಮತ್ತು ಫಿಟ್ನೆಸ್ ತಂತ್ರಗಳ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಬೇಕು.ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಅವನು ಕ್ರೀಡಾ ತರಬೇತಿ ಮತ್ತು ಕ್ರೀಡಾಕೂಟಕ್ಕೆ ಹಾಜರಾಗುತ್ತಾನೆ. ನೀವು ಕೆಲಸ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿದಾಗ ಅಥವಾ ನಿಮ್ಮ ಪಾತ್ರವನ್ನು ಬದಲಾಯಿಸಲು ನೋಡುತ್ತಿರುವಾಗ, ನೀವು ಹೊಸ ಕೌಶಲ್ಯವನ್ನು ಸುಧಾರಿಸಬೇಕು ಅಥವಾ ಕಲಿಯಬೇಕು ಎಂದು ನೀವು ಅರಿತುಕೊಳ್ಳಬಹುದು. ಉನ್ನತ ಕೌಶಲ್ಯವು ನಿಮಗೆ ಹೊಸ ಕೌಶಲ್ಯವನ್ನು ಕಲಿಯಲು ಅಥವಾ ನಿರ್ದಿಷ್ಟ ಪ್ರದೇಶದಲ್ಲಿ ಪ್ರಮಾಣೀಕರಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನಿಮ್ಮ ಪ್ರಸ್ತುತ ಕೆಲಸದಲ್ಲಿ ಉತ್ತಮಗೊಳ್ಳಲು ಅಥವಾ ಹೊಸ ಪಾತ್ರಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನೀವು ಕೌಶಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸಬಹುದು.",
            "te": "నవీన్ మూవ్‌మోర్‌లో ఫిట్‌నెస్ సేల్స్ అసిస్టెంట్‌గా చేరాడు. తన ఉద్యోగంలో భాగంగా, అతను వివిధ క్రీడా పరికరాలు మరియు ఫిట్‌నెస్ టెక్నిక్‌ల గురించి తెలుసుకోవాలి. అతను మరింత తెలుసుకోవడానికి స్పోర్ట్స్ ట్రైనింగ్ మరియు స్పోర్ట్స్ మీట్‌కి కూడా హాజరయ్యాడు. మీరు పని చేయడం ప్రారంభించినప్పుడు లేదా మీ పాత్రను మార్చుకోవాలని చూస్తున్నప్పుడు, మీరు నైపుణ్యం పెంచుకోవడం లేదా కొత్త నైపుణ్యం నేర్చుకోవాలని మీరు గ్రహించవచ్చు. నైపుణ్యం పెంచడం మీకు కొత్త నైపుణ్యం నేర్చుకోవడంలో లేదా ఏదైనా అంశంలో సర్టిఫికేట్ పొందడంలో సహాయపడుతుంది. మీరు మీ ప్రస్తుత ఉద్యోగంలో మెరుగ్గా ఉండటానికి లేదా కొత్త పాత్ర కోసం దరఖాస్తు చేసుకోవడానికి నైపుణ్యం పెంచుకోవచ్చు.",
            "or": "ନବୀନ MoveMore ଫିଟନେସ୍ ସେଲ୍ସ ଆସିସ୍‌ଟେଣ୍ଟ୍‌ ଭାବରେ ଯୋଗ ଦିଅନ୍ତି | ତାଙ୍କ କାର୍ଯ୍ୟର ଏକ ଅଂଶ ଭାବରେ, ସେ ବିଭିନ୍ନ କ୍ରୀଡା ଉପକରଣ ଏବଂ ଫିଟନେସ୍ କୌଶଳ ବିଷୟରେ ଜାଣିବା ଆବଶ୍ୟକ କରନ୍ତି | ଅଧିକ ଜାଣିବା ପାଇଁ ସେ କ୍ରୀଡା ତାଲିମ ଏବଂ କ୍ରୀଡା ମିଟ୍ ରେ ମଧ୍ୟ ଯୋଗ ଦିଅନ୍ତି | । ଯେବେ ତୁମେ କାମ କରିବା ଆରମ୍ଭ କରୁଛ କିମ୍ବା ତୁମର ଭୂମିକା ବଦଳାଇବାକୁ ଚାହୁଁଛ, ତୁମେ ଅନୁଭବ କରିପାରିବ ଯେ ତୁମକୁ ଏକ ନୂତନ କୌଶଳର ଶିଖିବା କିମ୍ବା ଉନ୍ନତି ଆବଶ୍ୟକ | ଅପସ୍କିଲିଂ ତୁମକୁ ଏକ ନୂତନ କୌଶଳ ଶିଖିବାରେ କିମ୍ବା ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ କ୍ଷେତ୍ରରେ ସାର୍ଟିଫିକେଟ୍ ପାଇବାରେ ସାହାଯ୍ୟ କରିବ | ତୁମର ସାମ୍ପ୍ରତିକ ଚାକିରିରେ ଉନ୍ନତି ଆଣିବା ପାଇଁ କିମ୍ବା ଏକ ନୂତନ ଭୂମିକା ପାଇଁ ଆବେଦନ କରିବା ପାଇଁ ତୁମେ ଅପ୍‌ସ୍କିଲ୍ କରିପାରିବେ |",
            "as": "নবীনে মুভমোৰত ফিটনেছ বিক্ৰী সহায়ক হিচাপে যোগদান কৰে। তাৰ চাকৰিৰ অংশ হিচাপে, সি বিভিন্ন ক্ৰীড়া সঁজুলি আৰু ফিটনেছ কৌশলৰ বিষয়ে জানিব লাগিব। সি অধিক জানিবলৈ ক্ৰীড়া প্ৰশিক্ষণ আৰু ক্ৰীড়া সভাতো অংশগ্ৰহণ কৰে। তুমি কাম কৰিবলৈ আৰম্ভ কৰাৰ লগে লগে বা তোমাৰ ভূমিকা সলনি কৰিব বিচাৰিলে, তুমি ভাৱিব পাৰা যে তুমি এক নতুন দক্ষতা উন্নত কৰিব বা শিকিব লাগিব। আপস্কিলিংয়ে তোমাক এটা নতুন দক্ষতা শিকাত বা এটা নিৰ্দিষ্ট ক্ষেত্ৰত প্ৰমাণিত হোৱাত সহায় কৰিব। তুমি তোমাৰ বৰ্তমানৰ চাকৰিত ভাল কৰিবলৈ বা নতুন ভূমিকাৰ বাবে আবেদন কৰিবলৈ দক্ষতা বৃদ্ধি কৰিব পাৰা।",
            "gu": "નવીન ફિટનેસ સેલ્સ સહાયક તરીકે મૂવમોરમાં જોડાય છે. તેની નોકરીના ભાગ રૂપે, તેને રમતગમતના વિવિધ સાધનો અને ફિટનેસ તકનીકો જાણવાની જરૂર છે. તે રમતગમત તાલીમમાં પણ હાજરી આપે વધુ જાણવા માટે. જ્યારે તમે કામ કરવાનું શરૂ કરો છો અથવા તમારી ભૂમિકા બદલવાનું વિચારી રહ્યા છો, ત્યારે તમને ખ્યાલ આવી શકે છે કે તમારે નવી કુશળતા સુધારવાની અથવા શીખવાની જરૂર છે. તમારી કુશળતાને બદલાવ કરવાથી તમને નવી કુશળતા શીખવામાં અથવા ચોક્કસ ક્ષેત્રમાં ડિગ્રી મેળવવામાં મદદ મળશે. તમે તમારી વર્તમાન નોકરીને સુધારવા અથવા નવી નોકરી માટે અરજી કરવા માટે તમારી કુશળતા સુધારી શકો છો."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Sales assistant at MoveMore",
            "hi": "मुव्हमोर में सेल्स असिस्टेंट",
            "ka": "**THREAD_END**_ಮೂವ್ ಮೊರ್ ನಲ್ಲಿ ಮಾರಾಟ ಸಹಾಯಕ",
            "te": "**THREAD_END**_మూవ్‌మోర్‌ లో సేల్స్ అసిస్టెంట్",
            "or": "**THREAD_END**_Sales assistant at MoveMore",
            "as": "**থ্ৰেড_সমাপ্ত**_মুভমোৰত বিক্ৰী সহায়ক",
            "gu": "**THREAD_END**_મૂવમોર પર વેચાણ સહાયક"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Naveen that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने नवीन के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँचा है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकता है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n  ನೀವು ನವೀನ್‌ಗಾಗಿ ಮಾಡಿರುವ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "కంగ్రాచులేశన్స్!\n మీరు ఒక జర్నీని పూర్తి చేసారు !\n \n మీరు నవీన్ కోసం ఎంపికలు చేసారు, అది ఈ పాసిబుల్ ఎండింగ్ ని ఇచ్చింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ఎండింగ్స్ ఉన్నాయి. మీరు ఇతర సన్నివేశాలను ఎక్స్ప్లోర్ చేయాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ !\n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି !\n \n ଆପଣ ନବୀନଙ୍କ ପାଇଁ ବାଛିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତି ଘଟାଇଛି | ଆପଣ କରୁଥିବା ପସନ୍ଦ ଉପରେ ଆଧାର କରି ଭିନ୍ନ ଭିନ୍ନ ସମାପ୍ତି ଅଛି | ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକୁ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ?",
            "as": "অভিনন্দন! তুমি এটা যাত্ৰা সমাপ্ত কৰিছা ৷ তুমি নবীনৰ বাবে কৰা বাছনিবোৰৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত ভিত্তি কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ চাব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક મુસાફરી પૂરી કરી છે!\n\nતમે નવીન માટે પસંદગીઓ કરી છે જે આ સંભવિત અંત તરફ દોરી ગઈ છે. તમે જે પસંદગી કરી છે તેનાથી અલગ અલગ અંત છે. શું તમે બીજા દ્રશ્યો ખોલવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମ୍ଯାପ୍‍ ଉପରେ ଏକ ନଜର ପକାନ୍ତୁ |",
            "as": "মানচিত্ৰখনৰ পিনে চোৱা",
            "gu": "ચાલો નકશા પર એક નજર નાખીએ"
          }
        }
      ]
    },
    "scene29": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D5_1_1_Delivery agent at Bunzo",
            "hi": "बंझो में डिलीवरी एजंट",
            "ka": "D5_1_1_ಬುಂಝೋ ನಲ್ಲಿ ಡೆಲಿವರಿ ಏಜೆಂಟ್",
            "te": "D5_1_1_బంజో వద్ద డెలివరీ ఏజెంట్",
            "or": "D5_1_1_Delivery agent at Bunzo",
            "as": "D5_1_1_বুঞ্জোত ডেলিভাৰী এজেণ্ট",
            "gu": "D5_1_1_બુંઝો ખાતે ડિલિવરી એજન્ટ"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen joins Bunzo as a delivery agent.",
            "hi": "नवीन बंझो में डिलीवरी एजेंट के तौर पर काम करना शुरू करता है।",
            "ka": "ನವೀನ್ ಬುಂಝೋಗೆ ಡೆಲಿವರಿ ಏಜೆಂಟ್ ಆಗಿ ಸೇರುತ್ತಾನೆ.",
            "te": "నవీన్ బంజోలో డెలివరీ ఏజెంట్‌గా చేరాడు.",
            "or": "ନବୀନ ଏକ ଡେଲିଭରୀ ଏଜେଣ୍ଟ ଭାବରେ Bunzoରେ ଯୋଗ ଦିଅନ୍ତି |",
            "as": "নবীনে বুঞ্জোত ডেলিভাৰী এজেণ্ট হিচাপে যোগদান কৰে।",
            "gu": "નવીન બુંઝોમાં ડિલિવરી એજન્ટ ખાતે જોડાય છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D5_1_2",
            "hi": "D5_1_2",
            "ka": "D5_1_2",
            "te": "D5_1_2",
            "or": "D5_1_2",
            "as": "D5_1_2",
            "gu": "D5_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The style of work here is very different as he can choose his work hours, the number of deliveries he does, and whether he wants to work in a night or morning shift.",
            "hi": "यहाँ काम करने का तरीका अलग है क्योंकि यहाँ वह काम के घंटे, डिलीवरी की संख्या चुन सकता है और उसे सुबह की शिफ्ट करनी है या रात की यह भी तय कर सकता है।",
            "ka": "ಇಲ್ಲಿ ಕೆಲಸದ ಶೈಲಿಯು ತುಂಬಾ ವಿಭಿನ್ನವಾಗಿದೆ ಏಕೆಂದರೆ ಅವನು ತನ್ನ ಕೆಲಸದ ಸಮಯ, ಅವನು ಮಾಡುವ ಡೆಲಿವರಿಗಳ ಸಂಖ್ಯೆ ಮತ್ತು ಅವನು ರಾತ್ರಿ ಅಥವಾ ಬೆಳಗಿನ ಪಾಳಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಬಯಸುತ್ತಾನೆ.",
            "te": "అతను తన వర్కింగ్ హవర్స్, అతను చేసే డెలివరీల సంఖ్య మరియు అతను రాత్రి లేదా ఉదయం షిఫ్ట్‌లో పని చేయాలా వద్దా అనేదాన్ని చూస్ చేస్కోగలడు కాబట్టి ఇక్కడ పని విధానం చాలా డిఫరెంట్ గా ఉంటుంది.",
            "or": "ଏଠାରେ କାର୍ଯ୍ୟର ଶୈଳୀ ବହୁତ ଅଲଗା ଅଟେ କାରଣ ସେ ତାଙ୍କର କାର୍ଯ୍ୟ ସମୟ, ସେ କରିଥିବା ଡେଲିଭରୀଗୁଡିକର ସଂଖ୍ୟା, ଏବଂ ସେ ରାତି କିମ୍ବା ସକାଳ ସିଫ୍ଟରେ କାମ କରିବାକୁ ଚାହାଁନ୍ତି କି ତାହା ଚୟନ କରିପାରିବେ |",
            "as": "ইয়াত কামৰ শৈলী বহুত বেলেগ কিয়নো সি তাৰ কামৰ সময়, সি কৰা ডেলিভাৰীৰ সংখ্যা, আৰু সি ৰাতি বা ৰাতিপুৱাৰ শ্বিফ্টত কাম কৰিব বিচাৰে নে নাই নিজেই বাছনি কৰিব পাৰে।",
            "gu": "અહીં કામ કરવાની શૈલી ખૂબ જ બદલાય છે કારણ કે તે કયા કલાકો કામ કરે છે, તેની પાસે કેટલી ડિલિવરી છે અને તે રાત્રિ કે સવારની પાળીમાં કામ કરવા માંગે છે કે કેમ તે પસંદ કરી શકે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D5_1_3",
            "hi": "D5_1_3",
            "ka": "D5_1_3",
            "te": "D5_1_3",
            "or": "D5_1_3",
            "as": "D5_1_3",
            "gu": "D5_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He also gets added benefits like a joining bonus, accident insurance (company pays if Naveen meets with a road accident), and a referral bonus (gets money if someone referred by him gets hired).",
            "hi": "उसे कुछ अतिरिक्त लाभ भी मिलते हैं, जैसे कि, जॉइनिंग बोनस, एक्सीडेंट इंश्योरेंस (अगर नवीन का रोड एक्सीडेंट होता है तो कंपनी पैसा देगी) और रिफेरल बोनस (अगर उसके द्वारा रेफर किया हुआ कोई व्यक्ति उनके साथ काम करने के लिए नियुक्त हो जाए)।",
            "ka": "ಅವನು ಸೇರುವ ಬೋನಸ್, ಅಪಘಾತ ವಿಮೆ (ನವೀನ್ ರಸ್ತೆ ಅಪಘಾತವನ್ನು ಎದುರಿಸಿದರೆ ಕಂಪನಿಯು ಪಾವತಿಸುತ್ತದೆ), ಮತ್ತು ರೆಫರಲ್ ಬೋನಸ್ (ಅವನು ಉಲ್ಲೇಖಿಸಿದ ಯಾರಾದರೂ ಕೆಲಸ ಪಡೆದರೆ ಹಣವನ್ನು ಪಡೆಯುತ್ತಾನೆ) ನಂತಹ ಹೆಚ್ಚುವರಿ ಪ್ರಯೋಜನಗಳನ್ನು ಪಡೆಯುತ್ತಾನೆ.",
            "te": "అతను జాయినింగ్ బోనస్, యాక్సిడెంట్ ఇన్సూరెన్స్ (నవీన్ రోడ్డు ప్రమాదానికి గురైతే కంపెనీ చెల్లిస్తుంది) మరియు రిఫరల్ బోనస్ (అతను సూచించిన ఎవరైనా ఉద్యోగంలోకి తీసుకుంటే డబ్బు వస్తుంది) వంటి అదనపు ప్రయోజనాలను కూడా పొందుతాడు.",
            "or": "ସେ ଏକ ଯୋଗଦାନ ବୋନସ୍, ଦୁର୍ଘଟଣା ବୀମା (ଯଦି ନବୀନ ଏକ ସଡ଼କ ଦୁର୍ଘଟଣାର ସମ୍ମୁଖୀନ ହୁଏ ତେବେ କମ୍ପାନୀ ଦେୟ ପ୍ରଦାନ କରିବ) ଏବଂ ଏକ ରେଫରାଲ୍ ବୋନସ୍ (ଯଦି ତାଙ୍କ ଦ୍ୱାରା ରେଫର୍ ହୋଇଥିବା ବ୍ୟକ୍ତି ନିଯୁକ୍ତି ପାଇଥାଏ ତେବେ ଟଙ୍କା ମିଳେ) ଭଳି ଅତିରିକ୍ତ ସୁବିଧା ମଧ୍ୟ ପାଆନ୍ତି |",
            "as": "সি লগতে যোগদান বোনাচ, দুৰ্ঘটনা বীমা (নবীনে পথ দুৰ্ঘটনাত পতিত হ'লে কোম্পানীয়ে পৰিশোধ কৰিব), আৰু ৰেফাৰেল বোনাচ (যদি তাৰ দ্বাৰা প্ৰেৰণ কৰা কাৰোবাক নিযুক্তি দিয়া হয় তেন্তে টকা পায়)ৰ দৰে অতিৰিক্ত লাভালাভ লাভ কৰে।",
            "gu": "તેને જોડાવાનું બોનસ, અકસ્માત વીમો (નવીનને માર્ગ અકસ્માતમાં થાય તો કંપની ચૂકવણી કરે છે), અને કોઈને જાણ કરવાનું બોનસ (તેના દ્વારા ઉલ્લેખિત કોઈને નોકરી પર રાખવામાં આવે તો પૈસા મળે છે) જેવા વધારાના લાભો પણ મળે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D5_1_4",
            "hi": "D5_1_4",
            "ka": "D5_1_4",
            "te": "D5_1_4",
            "or": "D5_1_4",
            "as": "D5_1_4",
            "gu": "D5_1_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He can also choose different tasks from the app, such as delivering packages and food, or provide a service of bike taxis for passengers.",
            "hi": "वह आपसे अलग अलग कार्य भी चुन सकता है, जैसे कि पॅकेज और फूड की डिलीवरी करना या ग्राहकों के लिए बाइक टैक्सी की सेवा प्रदान करना।",
            "ka": "ಅವನು ಅಪ್ಲಿಕೇಶನ್‌ನಿಂದ ವಿವಿಧ ಕಾರ್ಯಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಬಹುದು, ಉದಾಹರಣೆಗೆ ಪ್ಯಾಕೇಜ್‌ಗಳು ಮತ್ತು ಆಹಾರವನ್ನು ವಿತರಿಸುವುದು ಅಥವಾ ಪ್ರಯಾಣಿಕರಿಗೆ ಬೈಕ್ ಟ್ಯಾಕ್ಸಿಗಳ ಸೇವೆಯನ್ನು ಒದಗಿಸುವುದು.",
            "te": "అతను యాప్ నుండి ప్యాకేజీలు మరియు ఆహారాన్ని డెలివరీ చేయడం లేదా ప్రయాణీకులకు బైక్ టాక్సీల సేవను అందించడం వంటి వివిధ పనులను కూడా ఎంచుకోవచ్చు.",
            "or": "ସେ ଆପ୍ ରୁ ବିଭିନ୍ନ କାର୍ଯ୍ୟ ମଧ୍ୟ ବାଛି ପାରିବେ ଯେପରିକି ପ୍ୟାକେଜ୍ ଏବଂ ଖାଦ୍ୟ ଡେଲିଭରି କରିବା କିମ୍ବା ଯାତ୍ରୀମାନଙ୍କ ପାଇଁ ବାଇକ୍ ଟ୍ୟାକ୍ସିର ସେବା ଯୋଗାଇବା |",
            "as": "সি এপ্পৰ পৰা বিভিন্ন কাম বাছনি কৰিব পাৰে, যেনে পেকেজ আৰু খাদ্য বিতৰণ কৰা, বা যাত্ৰীসকলৰ বাবে বাইক টেক্সিৰ সেৱা প্ৰদান কৰা।",
            "gu": "તે એપમાંથી વિવિધ કાર્યો પણ પસંદ કરી શકે છે, જેમ કે પેકેજ અને ફૂડ ડિલિવરી કરવી અથવા પેસેન્જરો માટે બાઈક ટેક્સીની સેવા પ્રદાન કરવી."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D5_2_1",
            "hi": "D5_2_1",
            "ka": "D5_2_1",
            "te": "D5_2_1",
            "or": "D5_2_1",
            "as": "D5_2_1",
            "gu": "D5_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen makes a decent earning of around Rs. 22,000 - 24,000 every month. Naveen enjoys the flexibility of the work as it allows him to spend time practising dance.",
            "hi": "नवीन हर महीने तकरीबन Rs। 22,000 - 24,000 कमा लेता है। नवीन को इस काम की फ्लैक्सिबिलिटी पसंद है क्योंकि उसे डान्स की प्रैक्टिस करने के लिए समय मिलता है",
            "ka": "ನವೀನ್ ಪ್ರತಿ ತಿಂಗಳು ಸುಮಾರು ರೂ.22,000 - 24,000ಗಳ ಯೋಗ್ಯ ಗಳಿಕೆಯನ್ನು ಮಾಡುತ್ತಾನೆ.ನವೀನ್ ಕೆಲಸದ ನಮ್ಯತೆಯನ್ನು ಆನಂದಿಸುತ್ತಾನೆ ಏಕೆಂದರೆ ಇದು ನೃತ್ಯವನ್ನು ಅಭ್ಯಾಸ ಮಾಡಲು ಸಮಯವನ್ನು ಕಳೆಯಲು ಅನುವು ಮಾಡಿಕೊಡುತ್ತದೆ.",
            "te": "నవీన్ దాదాపు రూ. ప్రతి నెల 22,000 - 24,000 సంపాదిస్తున్నాడు. నవీన్ డ్యాన్స్ సాధన చేస్తూ సమయాన్ని వెచ్చించగలిగేలా పని యొక్క ఫ్లెక్సిబిలిటీని ఆనందిస్తాడు.",
            "or": "ନବୀନ ସମ୍ମାନସ୍ପଦଭାବରେ ପ୍ରତି ମାସରେ ପ୍ରାୟ Rs. 22,000 - 24,000 ଟଙ୍କା ରୋଜଗାର କରନ୍ତି। ନବୀନ କାର୍ଯ୍ୟର ନମନୀୟତାକୁ ଉପଭୋଗ କରନ୍ତି କାରଣ ଏହା ତାଙ୍କୁ ନୃତ୍ୟ ଅଭ୍ୟାସ କରିବାକୁ ସମୟ ଦେଇଥାଏ |",
            "as": "নবীনে প্ৰতি মাহে প্ৰায় 22,000-24,000 টকা উপাৰ্জন কৰে। নবীনে কামটোৰ নমনীয়তা উপভোগ কৰে কিয়নো ই তাক নৃত্যৰ অনুশীলনত সময় কটাবলৈ অনুমতি দিয়ে।",
            "gu": "નવીન લગભગ રૂ.22,000 - 24,000 ની યોગ્ય કમાણી કરે છે દર મહિને. નવીન કામની સરળતાનો આનંદ માણે છે કારણ કે તે તેને નૃત્યની પ્રેક્ટિસ કરવામાં સમય મળી રહે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Delivery agent",
            "hi": "डिलीवरी एजंट",
            "ka": "**THREAD_END**_ವಿತರಣಾ ಏಜೆಂಟ್",
            "te": "**THREAD_END**_డెలివరీ ఏజెంట్",
            "or": "**THREAD_END**_Delivery agent",
            "as": "**থ্ৰেড_সমাপ্ত**_ডেলিভাৰী এজেণ্ট",
            "gu": "**THREAD_END**_ડિલિવરી એજન્ટ"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Naveen that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने नवीन के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँचा है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकता है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n  ನೀವು ನವೀನ್‌ಗಾಗಿ ಮಾಡಿರುವ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు నవీన్ కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ !\n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି !\n \n ଆପଣ ନବୀନଙ୍କ ପାଇଁ ବାଛିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତି ଘଟାଇଛି | ଆପଣ କରୁଥିବା ପସନ୍ଦ ଉପରେ ଆଧାର କରି ଭିନ୍ନ ଭିନ୍ନ ସମାପ୍ତି ଅଛି | ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକୁ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ?",
            "as": "অভিনন্দন! তুমি এটা যাত্ৰা সমাপ্ত কৰিছা ৷ তুমি নবীনৰ বাবে কৰা বাছনিবোৰৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত ভিত্তি কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ চাব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક મુસાફરી પૂરી કરી છે!\n\nતમે નવીન માટે પસંદગીઓ કરી છે જે આ સંભવિત અંત તરફ દોરી ગઈ છે. તમે જે પસંદગી કરી છે તેનાથી અલગ અલગ અંત છે. શું તમે બીજા દ્રશ્યો ખોલવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମ୍ଯାପ୍‍ ଉପରେ ଏକ ନଜର ପକାନ୍ତୁ |",
            "as": "মানচিত্ৰখনৰ পিনে চোৱা",
            "gu": "ચાલો નકશા પર એક નજર નાખીએ"
          }
        }
      ]
    },
    "scene30": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_1_1_Focus on Dance",
            "hi": "डान्स पर ध्यान देना",
            "ka": "D6_1_1_ನೃತ್ಯದ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಿ",
            "te": "D6_1_1_డ్యాన్స్ పైదృష్టి పెట్టడం",
            "or": "D6_1_1_Focus on Dance",
            "as": "D6_1_1_নৃত্যৰ ওপৰত গুৰুত্ব",
            "gu": "D6_1_1_નૃત્ય પર ધ્યાન આપો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen’s group aims to become popular in Hip Hop dance. They start conducting open dance sessions every Sunday in the busiest streets in the city.",
            "hi": "नवीन का ग्रुप हिप हॉप डान्स में लोकप्रिय होने का लक्ष्य रखता है। वे शहर के सबसे भीड़भाड़ वाली सड़क पर ओपन डान्स सेशन आयोजित करना शुरू करते हैं।",
            "ka": "ನವೀನ್ ಗುಂಪು ಹಿಪ್ ಹಾಪ್ ನೃತ್ಯದಲ್ಲಿ ಜನಪ್ರಿಯವಾಗಲು ಗುರಿ ಹೊಂದಿದೆ. ಅವರು ಪ್ರತಿ ಭಾನುವಾರ ನಗರದ ಅತ್ಯಂತ ಜನನಿಬಿಡ ಬೀದಿಗಳಲ್ಲಿ ಮುಕ್ತ ನೃತ್ಯ ಅಧಿವೇಶನಗಳನ್ನು ನಡೆಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ.",
            "te": "నవీన్ బృందం హిప్ హాప్ డ్యాన్స్‌లో ప్రజాదరణ పొందాలని లక్ష్యంగా పెట్టుకుంది. నగరంలోని అత్యంత రద్దీ వీధుల్లో ప్రతి ఆదివారం ఓపెన్ డ్యాన్స్ సెషన్స్ నిర్వహించడం ప్రారంభిస్తారు.",
            "or": "ନବୀନଙ୍କ ଗ୍ରୁପ୍‌ ହିପ୍ ହପ୍ ନୃତ୍ୟରେ ଲୋକପ୍ରିୟ ହେବାକୁ ଲକ୍ଷ୍ୟ ରଖିଛି | ସେମାନେ ପ୍ରତି ରବିବାର ସହରର ସବୁଠୁ ବ୍ୟସ୍ତବହୁଳ ରାସ୍ତାରେ ଖୋଲା ନୃତ୍ୟ ଅଧିବେଶନ କରିବା ଆରମ୍ଭ କରନ୍ତି |",
            "as": "নবীনৰ গোটটোৱে হিপ হপ নৃত্যত জনপ্ৰিয় হোৱাৰ লক্ষ্য লয়। তেওঁলোকে চহৰখনৰ ব্যস্ততম ৰাস্তাবোৰত প্ৰতি দেওবাৰে মুকলি নৃত্য প্ৰদৰ্শন আৰম্ভ কৰে।",
            "gu": "નવીનનું જૂથ હિપ હોપ ડાન્સમાં લોકપ્રિય બનવાનું લક્ષ્ય ધરાવે છે. તેઓ દર રવિવારે શહેરની સૌથી વ્યસ્ત શેરીઓમાં નૃત્ય સત્રો યોજવાનું શરૂ કરે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_1_2",
            "hi": "D6_1_2",
            "ka": "D6_1_2",
            "te": "D6_1_2",
            "or": "D6_1_2",
            "as": "D6_1_2",
            "gu": "D6_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "They also set up social media accounts and call themselves ‘All stars’ where they post short clips of their dance and share what they are doing.",
            "hi": "वे सोशल मीडिया अकाउंट भी शुरू करते हैं और खुद को ऑल स्टार कहते हैं, जहाँ वे अपने डान्स के और कार्य के छोटे क्लिप पोस्ट करते हैं।",
            "ka": "ಅವರು ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಖಾತೆಗಳನ್ನು ಸಹ ಸ್ಥಾಪಿಸುತ್ತಾರೆ ಮತ್ತು ತಮ್ಮನ್ನು 'ಆಲ್ ಸ್ಟಾರ್' ಎಂದು ಕರೆದುಕೊಳ್ಳುತ್ತಾರೆ, ಅಲ್ಲಿ ಅವರು ತಮ್ಮ ನೃತ್ಯದ ಸಣ್ಣ ತುಣುಕುಗಳನ್ನು ಪೋಸ್ಟ್ ಮಾಡುತ್ತಾರೆ ಮತ್ತು ಅವರು ಏನು ಮಾಡುತ್ತಿದ್ದಾರೆಂದು ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ.",
            "te": "వారు సోషల్ మీడియా ఖాతాలను కూడా సెటప్ చేస్తారు మరియు తమను తాము 'ఆల్ స్టార్స్' అని పిలుచుకుంటారు, అక్కడ వారు తమ డ్యాన్స్ యొక్క చిన్న క్లిప్‌లను పోస్ట్ చేస్తారు మరియు వారు ఏమి చేస్తున్నారో పంచుకుంటారు.",
            "or": "ସେମାନେ ସୋସିଆଲ ମିଡିଆ ଆକାଉଣ୍ଟ ମଧ୍ୟ ସ୍ଥାପନ କରନ୍ତି ଏବଂ ନିଜକୁ ‘ଅଲ୍‍ ଷ୍ଟାର୍ସ୍‍’ ବୋଲି ଡାକନ୍ତି ଯେଉଁଠାରେ ସେମାନେ ସେମାନଙ୍କର ନୃତ୍ୟର ଛୋଟ କ୍ଲିପ୍ ପୋଷ୍ଟ କରନ୍ତି ଏବଂ ସେମାନେ ଯାହା କରୁଛନ୍ତି ତାହା ଅଂଶୀଦାର କରନ୍ତି |",
            "as": "তেওঁলোকে সামাজিক মাধ্যমৰ একাউণ্টো খুলি লয় আৰু নিজকে 'অল-ষ্টাৰ' বুলি কয় য'ত তেওঁলোকে তেওঁলোকৰ নৃত্যৰ চমু ক্লিপ পোষ্ট কৰে আৰু তেওঁলোকে কি কৰি আছে সেয়া ভাগ-বতৰা কৰে।",
            "gu": "તેઓ સોશિયલ મીડિયા એકાઉન્ટ્સ પણ બનાવે છે અને પોતાને 'ઓલ સ્ટાર્સ' કહે છે જ્યાં તેઓ તેમના ડાન્સની ટૂંકી ક્લિપ્સ મૂકે છે અને તેઓ શું કરી રહ્યાં છે તે બધાને કહે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_2_1",
            "hi": "D6_2_1",
            "ka": "D6_2_1",
            "te": "D6_2_1",
            "or": "D6_2_1",
            "as": "D6_2_1",
            "gu": "D6_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Looking at the response of people on their accounts, they decide to apply for a reality show.",
            "hi": "उनके अकाउंट पर लोगों की प्रतिक्रिया को देखकर, वे एक रियलिटी शो के लिए अप्लाई करने का निर्णय लेते हैं।",
            "ka": "ಅವರ ಖಾತೆಗಳಲ್ಲಿ ಜನರ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ನೋಡಿ, ಅವರು ರಿಯಾಲಿಟಿ ಶೋಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾರೆ.",
            "te": "వారి ఖాతాలపై వ్యక్తుల ప్రతిస్పందనను చూసి, వారు రియాలిటీ షో కోసం దరఖాస్తు చేసుకోవాలని నిర్ణయించుకున్నారు.",
            "or": "ସେମାନଙ୍କ ଆକାଉଣ୍ଟରେ ଲୋକଙ୍କ ପ୍ରତିକ୍ରିୟାକୁ ଦେଖି ସେମାନେ ଏକ ରିଏଲିଟି ଶୋ ପାଇଁ ଆବେଦନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
            "as": "তেওঁলোকৰ একাউণ্টত লোকসকলৰ সঁহাৰিলৈ লক্ষ্য কৰি, তেওঁলোকে এটা ৰিয়েলিটি শ্ব'ৰ বাবে আবেদন কৰাৰ সিদ্ধান্ত লয়।",
            "gu": "તેમના એકાઉન્ટ્સ પરના લોકોના પ્રતિભાવને જોઈને, તેઓ રિયાલિટી શો માટે અરજી કરવાનું નક્કી કરે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_2_2",
            "hi": "D6_2_2",
            "ka": "D6_2_2",
            "te": "D6_2_2",
            "or": "D6_2_2",
            "as": "D6_2_2",
            "gu": "D6_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "But when they get there, they become nervous looking at the performances of other dance groups. Everyone looks pretty professional and well trained.",
            "hi": "लेकिन जब वे वहाँ जाते हैं, तब दूसरे डान्स ग्रुप का परफॉर्मेंस देख कर परेशान हो जाते हैं। अन्य सभी ग्रुप बहुत प्रोफेशनल और अच्छी तरह से प्रशिक्षित लग रहे हैं",
            "ka": "ಆದರೆ ಅವರು ಅಲ್ಲಿಗೆ ಹೋದಾಗ, ಅವರು ಇತರ ನೃತ್ಯ ಗುಂಪುಗಳ ಪ್ರದರ್ಶನಗಳನ್ನು ನೋಡಿ ನರ್ವಸ್ ಆಗುತ್ತಾರೆ. ಪ್ರತಿಯೊಬ್ಬರೂ ಸಾಕಷ್ಟು ವೃತ್ತಿಪರವಾಗಿ ಮತ್ತು ಚೆನ್ನಾಗಿ ತರಬೇತಿ ಪಡೆದಂತೆ ಕಾಣುತ್ತಾರೆ.",
            "te": "కానీ అక్కడికి చేరుకోగానే ఇతర డ్యాన్స్ గ్రూపుల ప్రదర్శనలు చూసి కంగారుపడతారు. ప్రతి ఒక్కరూ చాలా ప్రొఫెషనల్ మరియు బాగా శిక్షణ పొందినవారుగా కనిపిస్తారు.",
            "or": "କିନ୍ତୁ ଯେତେବେଳେ ସେମାନେ ସେଠାରେ ପହଞ୍ଚନ୍ତି, ସେମାନେ ଅନ୍ୟ ନୃତ୍ୟ ଗ୍ରୁପ୍‌ମାନଙ୍କ ପ୍ରଦର୍ଶନକୁ ଦେଖି ନର୍ଭସ ହୋଇଯାଆନ୍ତି | ସମସ୍ତେ ବହୁତ ପେସାଦାର ଏବଂ ଭଲ ତାଲିମପ୍ରାପ୍ତ ଦେଖାଯାନ୍ତି |",
            "as": "কিন্তু যেতিয়া তেওঁলোক তাত উপস্থিত হয়, তেওঁলোকে আন নৃত্য গোটবোৰৰ প্ৰদৰ্শনবোৰ চাই নাৰ্ভাছ হৈ পৰে। সকলোকে যথেষ্ট পেছাদাৰী আৰু ভালদৰে প্ৰশিক্ষিত যেন দেখা যায়।",
            "gu": "પરંતુ જ્યારે તેઓ ત્યાં પહોંચે છે, ત્યારે તેઓ બીજા નૃત્ય જૂથોના પ્રદર્શનને જોઈને નર્વસ થઈ જાય છે. દરેક વ્યક્તિ ખૂબ જ વ્યાવસાયિક અને સારી રીતે તાલીમપામેલા લાગે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_2_3",
            "hi": "D6_2_3",
            "ka": "D6_2_3",
            "te": "D6_2_3",
            "or": "D6_2_3",
            "as": "D6_2_3",
            "gu": "D6_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "As expected by Naveen, in the selection process, they get disqualified.",
            "hi": "जैसा कि नवीन को लगा था, वे सिलेक्शन प्रोसेस में क्वालीफाई नहीं हो पाते।",
            "ka": "ನವೀನ್ ನಿರೀಕ್ಷೆಯಂತೆ, ಆಯ್ಕೆ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ, ಅವರು ಅನರ್ಹರಾಗುತ್ತಾರೆ.",
            "te": "నవీన్ ఊహించినట్లుగానే, ఎంపిక ప్రక్రియలో, వారు అనర్హులు అవుతారు.",
            "or": "ନବୀନଙ୍କ ଆଶା ଅନୁଯାୟୀ, ଚୟନ ପ୍ରକ୍ରିୟାରେ, ସେମାନେ ଅଯୋଗ୍ୟ ହୁଅନ୍ତି |",
            "as": "নবীনে ভবা মতে, বাছনি প্ৰক্ৰিয়াত, তেওঁলোক অযোগ্য ঘোষিত হয়।",
            "gu": "નવીનની અપેક્ષા મુજબ, પસંદગી પ્રક્રિયામાં, તેઓ દૂર થઈ જાય છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_3_1//",
            "hi": "D6_3_1//",
            "ka": "D6_3_1//",
            "te": "D6_3_1//",
            "or": "D6_3_1//",
            "as": "D6_3_1//",
            "gu": "D6_3_1//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Naveen takes it in the right spirit and decides to go talk to one of the selection judges.",
            "hi": "लेकिन नवीन हिम्मत नहीं होता और एक सिलेक्शन जज से बात करने का निर्णय लेता है।",
            "ka": "ನವೀನ್ ಅದನ್ನು ಸರಿಯಾದ ಉತ್ಸಾಹದಲ್ಲಿ ತೆಗೆದುಕೊಳ್ಳುತ್ತಾನೆ ಮತ್ತು ಆಯ್ಕೆ ತೀರ್ಪುಗಾರರೊಬ್ಬರೊಂದಿಗೆ ಮಾತನಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
            "te": "నవీన్ దానిని సరైన స్ఫూర్తితో తీసుకొని సెలక్షన్ జడ్జిలలో ఒకరితో మాట్లాడాలని నిర్ణయించుకున్నాడు.",
            "or": "ନବୀନ ଏହାକୁ ସଠିକ୍ ମନୋଭାବରେ ଗ୍ରହଣ କରନ୍ତି ଏବଂ ଚୟନ ବିଚାରପତିଙ୍କ ମଧ୍ଯରୁ ଜଣଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
            "as": "নবীনে ইয়াক সঠিক মনোভাৱত লয় আৰু এজন বিচাৰকৰ সৈতে কথা পাতিবলৈ সিদ্ধান্ত লয়।",
            "gu": "નવીન આ બાબતે યોગ્ય ભાવનાથી સંપર્ક કરે છે અને પસંદગીના નિર્ણાયકોમાંથી એક સાથે વાત કરવાનું નક્કી કરે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Judge",
            "hi": "जज",
            "ka": "ನ್ಯಾಯಾಧೀಶರು",
            "te": "తీర్పు ఇచ్చేవారు",
            "or": "ଜଜ୍",
            "as": "বিচাৰক",
            "gu": "નિર્ણયાક"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Sir, we’ve been practising really hard to get here. What were we missing?",
            "hi": "सर, यहाँ आने के लिए हमने बहुत प्रेक्टिस की है। हमारे डान्स में क्या कमी रह गई थी?",
            "ka": "ಸರ್, ನಾವು ಇಲ್ಲಿಗೆ ಬರಲು ತುಂಬಾ ಕಷ್ಟಪಟ್ಟು ಅಭ್ಯಾಸ ಮಾಡುತ್ತಿದ್ದೇವೆ. ನಾವು ಏನು ಕಳೆದುಕೊಂಡಿದ್ದೇವೆ?",
            "te": "సర్, మేము ఇక్కడికి రావడానికి చాలా కష్టపడి సాధన చేస్తున్నాము. మేము ఏమి మిస్ చేసాము?",
            "or": "ସାର୍, ଆମେ ଏଠାରେ ପହଞ୍ଚିବା ପାଇଁ ପ୍ରକୃତରେ କଠିନ ଅଭ୍ୟାସ କରିଛୁ | ଆମେ କ’ଣ ହରାଉଥିଲୁ?",
            "as": "চাৰ, আমি ইয়ালৈ আহিবলৈ সঁচাকৈয়ে কঠিন অনুশীলন কৰিছিলো। আমি কি ক্ষেত্ৰত পিচ পৰি আছো বাৰু?",
            "gu": "સાહેબ, અમે અહીં પહોંચવા માટે ખરેખર સખત મહેનત કરી છે. અમે સેમાં પાછા પડીએ છીયે?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Your group is very talented, but sometimes it's essential to get polished. You all are missing your original style.",
            "hi": "तुम्हारा ग्रुप काफी टैलेंटेड है लेकिन यह अच्छा भी दिखना चाहिए। तुम अपने ओरिजिनल स्टाइल में डान्स नहीं कर रहे थे।",
            "ka": "ನಿಮ್ಮ ಗುಂಪು ತುಂಬಾ ಪ್ರತಿಭಾವಂತವಾಗಿದೆ, ಆದರೆ ಕೆಲವೊಮ್ಮೆ ನಯಗೊಳಿಸುವುದು ಅತ್ಯಗತ್ಯ. ನೀವೆಲ್ಲರೂ ನಿಮ್ಮ ಮೂಲ ಶೈಲಿಯನ್ನು ಕಳೆದುಕೊಂಡಿದ್ದೀರಿ.",
            "te": "మీ సమూహం చాలా ప్రతిభావంతులైనది, కానీ కొన్నిసార్లు మెరుగుపడటం చాలా అవసరం. మీరందరూ మీ అసలు స్టైల్ ని కోల్పోతున్నారు.",
            "or": "ତୁମର ଗ୍ରୁପ୍‌ ଅତ୍ୟନ୍ତ ପ୍ରତିଭାଶାଳୀ, କିନ୍ତୁ ବେଳେବେଳେ ପଲିସ୍ ହେବା ଜରୁରୀ | ତୁମେ ସମସ୍ତେ ତୁମର ମୂଳ ଶୈଳୀ ହରାଉଛ |",
            "as": "তোমাৰ গোটটো অতি প্ৰতিভাশালী, কিন্তু কেতিয়াবা পলিচ কৰাটো অত্যাৱশ্যকীয়। তোমালোক সকলোৱে তোমালোকৰ মূল শৈলী হেৰুৱাইছা।",
            "gu": "તમારું જૂથ ખૂબ જ પ્રતિભાશાળી છે, પરંતુ કેટલીકવાર તેને નવા સુધારાની જરૂરી છે. તમે બધા તમારી મૂળભૂત ઢબ ભૂલી ચૂક્યા છો."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_3_2////",
            "hi": "D6_3_2////",
            "ka": "D6_3_2////",
            "te": "D6_3_2////",
            "or": "D6_3_2////",
            "as": "D6_3_2////",
            "gu": "D6_3_2////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "You’re right, Sir. But developing this style will take time, and we don’t have any source of income. We need to also earn for our families.",
            "hi": "आप सही कह रहे, सर। लेकिन इस स्टाइल को विकसित करने के लिए समय लगता है और हमारे पास इनकम का कोई मार्ग नहीं है। हमें अपने परिवार के लिए पैसे भी कमाने पड़ते हैं।",
            "ka": "ನೀವು ಹೇಳಿದ್ದು ಸರಿ, ಸರ್. ಆದರೆ ಈ ಶೈಲಿಯನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಲು ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ ಮತ್ತು ನಮಗೆ ಯಾವುದೇ ಆದಾಯದ ಮೂಲವಿಲ್ಲ. ನಾವು ಕೂಡ ನಮ್ಮ ಕುಟುಂಬಕ್ಕಾಗಿ ಸಂಪಾದಿಸಬೇಕು.",
            "te": "మీరు చెప్పింది నిజమే సార్. కానీ ఈ స్టైల్ ని అభివృద్ధి చేయడానికి సమయం పడుతుంది మరియు మాకు ఎటువంటి ఆదాయ వనరు లేదు. మన కుటుంబాల కోసం మనం కూడా సంపాదించాలి.",
            "or": "ଆପଣ ଠିକ୍ କହିଛନ୍ତି, ସାର୍ | କିନ୍ତୁ ଏହି ଶୈଳୀର ବିକାଶ ପାଇଁ ସମୟ ଲାଗିବ, ଏବଂ ଆମର ଆୟର କୌଣସି ଉତ୍ସ ନାହିଁ | ଆମ ପରିବାର ପାଇଁ ମଧ୍ୟ ଆମକୁ ରୋଜଗାର କରିବାକୁ ପଡିବ |",
            "as": "আপুনি ঠিকেই কৈছে, চাৰ। কিন্তু এই শৈলীটো বিকশিত কৰিবলৈ সময় লাগিব, আৰু আমাৰ ওচৰত উপাৰ্জনৰ কোনো উৎস নাই। আমি আমাৰ পৰিয়ালৰ বাবেও উপাৰ্জন কৰিব লাগিব।",
            "gu": "તમે સાચા છો, સાહેબ. પરંતુ આ ઢબ વિકસાવવામાં સમય લાગશે, અને અમારી પાસે આવકનો કોઈ સ્ત્રોત નથી. અમે અમારા પરિવાર માટે પણ કમાવાની જરૂર છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "That has been my journey too, Naveen. I had done multiple odd jobs like working at gyms, assisting trainers before I got popular. You will have to put night and day together to make this dream of yours a reality.",
            "hi": "मैं भी इस सब से गुजर चुका हूँ, नवीन। मैंने भी लोकप्रिय होने से पहले कई जॉब्स किए थे, जैसे कि, जिम में काम करना, ट्रेनर को असिस्ट करना। अपने सपने को हकीकत बनाने के लिए तुम्हें दिन-रात एक करने होगे,",
            "ka": "ನನ್ನ ಪಯಣವೂ ಹಾಗೆಯೇ ಆಯಿತು ನವೀನ್. ನಾನು ಜನಪ್ರಿಯವಾಗುವ ಮೊದಲು ಜಿಮ್‌ಗಳಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದು, ತರಬೇತುದಾರರಿಗೆ ಸಹಾಯ ಮಾಡುವುದು ಮುಂತಾದ ಅನೇಕ ಬೆಸ ಕೆಲಸಗಳನ್ನು ಮಾಡಿದ್ದೇನೆ. ನಿಮ್ಮ ಈ ಕನಸನ್ನು ನನಸಾಗಿಸಲು ನೀವು ರಾತ್ರಿ ಮತ್ತು ಹಗಲನ್ನು ಒಟ್ಟಿಗೆ ಸೇರಿಸಬೇಕಾಗುತ್ತದೆ.",
            "te": "నా జర్నీ కూడా అలాగే ఉంది నవీన్. నేను పాపులారిటీ పొందకముందు జిమ్‌లలో పని చేయడం, ట్రైనర్స్ కు హెల్ప్ చేయడం వంటి అనేక ఉద్యోగాలు చేశాను. మీ ఈ కలను నిజం చేసుకోవడానికి మీరు రాత్రి మరియు పగలు కష్టపడాలి.",
            "or": "ଏହି ଯାତ୍ରା ମୋର ମଧ୍ୟ, ନବୀନ | ମୁଁ ଲୋକପ୍ରିୟ ହେବା ପୂର୍ବରୁ ଜିମ୍ ରେ କାମ କରିବା, ପ୍ରଶିକ୍ଷକମାନଙ୍କୁ ସାହାଯ୍ୟ କରିବା ଭଳି ଏକାଧିକ ଅଦ୍ଭୁତ କାର୍ଯ୍ୟ କରିଥିଲି | ତୁମର ଏହି ସ୍ୱପ୍ନକୁ ବାସ୍ତବ ରୂପ ଦେବା ପାଇଁ ତୁମକୁ ରାତି ଓ ଦିନ ଏକାଠି କରିବାକୁ ପଡିବ |",
            "as": "নবীন, মোৰ যাত্ৰাও তেনেকুৱাই আছিল। মই জনপ্ৰিয় হোৱাৰ আগতে জিমত কাম কৰা, প্ৰশিক্ষকসকলক সহায় কৰাৰ দৰে অদ্ভুত কাম কৰিছিলো। তোমাৰ এই সপোনটো বাস্তৱত পৰিণত কৰিবলৈ তুমি দিন ৰাতি একাকাৰ কৰিব লাগিব।",
            "gu": "મારી પણ એ સફર હતી, નવીન. હું પ્રખ્યાત થયા પહેલા જીમમાં કામ કરવું, કોચને મદદ કરવી જેવી ઘણી એવી નોકરીઓ કરી. આ સ્વપ્નને સાકાર કરવા માટે તમારે દિવસ અને રાત એક કરીને કામ કરવું પડશે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Hmm, yes, Sir, I understand.",
            "hi": "ठीक है, सर। मैं समझ गया।",
            "ka": "ಹಾಂ, ಹೌದು, ಸರ್, ನನಗೆ ಅರ್ಥವಾಯಿತು.",
            "te": "అవును, సార్, నాకు అర్థమైంది.",
            "or": "ହମ୍, ହଁ, ସାର୍, ମୁଁ ବୁଝିପାରୁଛି |",
            "as": "হুম, হয়, চাৰ, মই বুজি পাইছোঁ।",
            "gu": "બરાબર, સાહેબ, હું સમજુ છું."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "It is difficult, but not impossible. Nobody else has come to ask me what they were missing. Seeing your potential, I would like to make you an offer.",
            "hi": "यह मुश्किल है, लेकिन ना मुमकिन नहीं।अभी तक किसी ने भी मुझसे यह नहीं पूछा कि उनमें क्या कमी रह गई थी। तुम्हारे काबिलियत को देखकर मैं तुम्हें एक ऑफर देना चाहता हूँ।",
            "ka": "ಇದು ಕಷ್ಟ, ಆದರೆ ಅಸಾಧ್ಯವಲ್ಲ. ಅವರು ಏನು ಕಳೆದುಕೊಂಡಿದ್ದಾರೆ ಎಂದು ಕೇಳಲು ಬೇರೆ ಯಾರೂ ಬಂದಿಲ್ಲ. ನಿನ್ನ ಸಾಮರ್ಥ್ಯವನ್ನು ನೋಡಿ, ನಾನು ನಿನಗೆ ಪ್ರಸ್ತಾಪವನ್ನು ನೀಡಲು ಬಯಸುತ್ತೇನೆ.",
            "te": "ఇది కష్టం, కానీ అసాధ్యం కాదు. మేము ఏమి మిస్ అయ్యామని నన్ను అడగడానికి మరెవరూ రాలేదు. మీ సామర్థ్యాన్ని చూసి, నేను మీకు ఆఫర్ చేయాలనుకుంటున్నాను.",
            "or": "ଏହା କଷ୍ଟସାଧ୍ୟ, କିନ୍ତୁ ଅସମ୍ଭବ ନୁହେଁ | ମୋତେ ପଚାରିବାକୁ କେହି ଆସିନାହାଁନ୍ତି କି ସେମାନେ କ’ଣ ହରାଉଛନ୍ତି | ତୁମର ସାମର୍ଥ୍ୟ ଦେଖି ମୁଁ ତୁମକୁ ଏକ ଅଫର୍ ଦେବାକୁ ଚାହେଁ |",
            "as": "এইটো কঠিন, কিন্তু অসম্ভৱ নহয়। আন কোনেও মোক সুধিবলৈ অহা নাই যে তেওঁলোক কিহত পিচ পৰি আছে। তোমাৰ সম্ভাৱনা দেখি, মই তোমাক এটা প্ৰস্তাৱ দিব বিচাৰোঁ ।",
            "gu": "આ કઠિન છે પણ અશક્ય નથી. હજી સુધી કોઈ મને એવું નથી પૂછ્યું કે અમે સેમાં પાછા પડીએ છીયે. તમારામાં ક્ષમતા જોઈ, હું તમેન એક નવી તક આપવા માગું છું."
          }
        }
      ],
      "page12": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_3_3//",
            "hi": "D6_3_3//",
            "ka": "D6_3_3//",
            "te": "D6_3_3//",
            "or": "D6_3_3//",
            "as": "D6_3_3//",
            "gu": "D6_3_3//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Judge",
            "hi": "जज",
            "ka": "ನ್ಯಾಯಾಧೀಶರು",
            "te": "తీర్పు ఇచ్చేవారు",
            "or": "ଜଜ୍",
            "as": "বিচাৰক",
            "gu": "નિરીક્ષક"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Why don’t you join my dance studio? I can’t pay you much, but in exchange for your services, I can teach you.",
            "hi": "तुम मेरा डान्स स्टूडियो क्यों नहीं ज्वाइन करते? मैं तुम्हें ज्यादा सैलरी नहीं दे पाऊंगा। लेकिन तुम्हारे काम के बदले मैं तुम्हें डान्स सीखाऊंगा।",
            "ka": "ನೀನು ನನ್ನ ಡ್ಯಾನ್ಸ್ ಸ್ಟುಡಿಯೋಗೆ ಏಕೆ ಸೇರಬಾರದು? ನಾನು ನಿನಗೆ ಹೆಚ್ಚು ಪಾವತಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ, ಆದರೆ ನಿನ್ನ ಸೇವೆಗಳಿಗೆ ಬದಲಾಗಿ, ನಾನು ನಿನಗೆ ಕಲಿಸಬಲ್ಲೆ.",
            "te": "మీరు నా డ్యాన్స్ స్టూడియోలో ఎందుకు చేరకూడదు? నేను మీకు ఎక్కువ డబ్బు ఇవ్వలేను, కానీ మీ సర్వీసెస్ కు బదులుగా, నేను మీకు నేర్పించగలను.",
            "or": "ତୁମେ ମୋର ଡ୍ୟାନ୍ସ ଷ୍ଟୁଡିଓରେ କାହିଁକି ଯୋଗ ଦେଉ ନାହଁ? ମୁଁ ତୁମକୁ ଅଧିକ ଦେୟ ଦେଇ ପାରିବି ନାହିଁ, କିନ୍ତୁ ତୁମର ସେବା ବଦଳରେ ମୁଁ ତୁମକୁ ଶିକ୍ଷା ଦେଇପାରେ |",
            "as": "তুমি মোৰ নৃত্য ষ্টুডিঅ'ত কিয় যোগদান নকৰা? মই তোমাক বেছি পৰিশোধ কৰিব নোৱাৰিম, কিন্তু তোমাৰ সেৱাৰ বিনিময়ত, মই তোমাক শিকাব পাৰিম।",
            "gu": "કેમ તું મારા ડાન્સ સ્ટુડિયોમાં કેમ જોડાતા નથી? હું તમને વધુ પૈસા નથી આપી શકુ, પરંતુ તમારી સેવાઓના બદલામાં, હું તમને શીખવી શકું છું."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "Networking_jobOpp",
            "hi": "नेटवर्किंग_जॉब अवसर",
            "ka": "ನೆಟ್ವರ್ಕಿಂಗ್_ಜಾಬ್ಒಪ್ಪ",
            "te": "Networking_jobOpp",
            "or": "Networking_jobOpp",
            "as": "নেটৱৰ্কিং_জবঅপ্",
            "gu": "નેટવર્કિંગ_નોકરીની તક"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Networking and Job Opportunity experience!",
            "hi": "आपने अभी-अभी नेटवर्किंग और जॉब के अवसर का अनुभव प्राप्त किया हैं!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮತ್ತು ಉದ್ಯೋಗ ಅವಕಾಶದ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే నెట్‌వర్కింగ్ మరియు ఉద్యోగ అవకాశాల గురించి తెలుసుకున్నారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ନେଟୱାର୍କିଂ ଏବଂ ଚାକିରି ସୁଯୋଗ ଅଭିଜ୍ଞତା ହାସଲ କରିଛନ୍ତି !",
            "as": "তুমি এইমাত্ৰ নেটৱৰ্কিং আৰু চাকৰিৰ সুযোগৰ অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "તમે હમણાં જ નેટવર્કિંગ અને નોકરીની તકોનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Networking and Job Opportunity",
            "hi": "नेटवर्किंग और जॉब के अवसर",
            "ka": "ನೆಟ್ವರ್ಕಿಂಗ್ ಮತ್ತು ಉದ್ಯೋಗ ಅವಕಾಶ",
            "te": "నెట్‌వర్కింగ్ మరియు ఉద్యోగ అవకాశం",
            "or": "ନେଟୱାର୍କିଂ ଏବଂ ଚାକିରି ସୁଯୋଗ",
            "as": "নেটৱৰ্কিং আৰু চাকৰিৰ সুযোগ",
            "gu": "નેટવર્કિંગ અને નોકરીની તકો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर विकास कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "కెరీర్ అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ଯାରିୟର୍‌ ବିକାଶ କୌଶଳ",
            "as": "জীৱিকা বিকাশৰ দক্ষতা",
            "gu": "કારકિર્દી વિકાસ કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "For Naveen, meeting Judge Sir and getting feedback on his dance form is how he builds a network. This is called Networking. It is a skill to start and develop meaningful professional relationships. These connections can sometimes lead to opportunities for growth in your career ladder. A network also helps in knowing what is happening in your professional circle. You can network by meeting people at an event, social media, meeting people in the neighbourhood or alumni at your institute.",
            "hi": "नवीन के द्वारा जज से मिलना और अपने डान्स फॉर्म के बारे में फीडबैक प्राप्त करना एक तरह से नेटवर्क बिल्ड करना है। इसे नेटवर्किंग कहते हैं। यह प्रोफेशनल संबंध बनाने और विकसित करने का कौशल है। इस तरह के संपर्क कभी-कभी आपके कैरियर की वृद्धि के लिए मददगार साबित हो सकते हैं। नेटवर्क से आपको यह जानने में भी मदद मिलती है कि, प्रोफेशनल दुनिया में क्या हो रहा है। आप लोगों से इवेंट में मिलकर, सोशल मीडिया पर, अपने अड़ोस पड़ोस के और अपने इंस्टिट्यूट के पुराने विद्यार्थियों से मिलकर नेटवर्क बना सकते हैं।",
            "ka": "ನವೀನ್‌ಗೆ, ಜಡ್ಜ್ ಸರ್ ಅವರನ್ನು ಭೇಟಿಯಾಗುವುದು ಮತ್ತು ಅವನ ನೃತ್ಯ ಪ್ರಕಾರದ ಬಗ್ಗೆ ಪ್ರತಿಕ್ರಿಯೆ ಪಡೆಯುವುದು ಅವನು ಹೇಗೆ ಜಾಲವನ್ನು ನಿರ್ಮಿಸುತ್ತಾನೆ ಎಂಬುದಾಗಿದೆ.ಇದನ್ನು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ.ಅರ್ಥಪೂರ್ಣ ವೃತ್ತಿಪರ ಸಂಬಂಧಗಳನ್ನು ಪ್ರಾರಂಭಿಸಲು ಮತ್ತು ಅಭಿವೃದ್ಧಿಪಡಿಸಲು ಇದು ಕೌಶಲ್ಯವಾಗಿದೆ. ಈ ಸಂಪರ್ಕಗಳು ಕೆಲವೊಮ್ಮೆ ನಿಮ್ಮ ವೃತ್ತಿಜೀವನದ ಏಣಿಯ ಬೆಳವಣಿಗೆಗೆ ಅವಕಾಶಗಳಿಗೆ ಕಾರಣವಾಗಬಹುದು.ನಿಮ್ಮ ವೃತ್ತಿಪರ ವಲಯದಲ್ಲಿ ಏನಾಗುತ್ತಿದೆ ಎಂಬುದನ್ನು ತಿಳಿದುಕೊಳ್ಳಲು ನೆಟ್‌ವರ್ಕ್ ಸಹಾಯ ಮಾಡುತ್ತದೆ.ಈವೆಂಟ್‌ನಲ್ಲಿ ಜನರನ್ನು ಭೇಟಿ ಮಾಡುವ ಮೂಲಕ, ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ, ನೆರೆಹೊರೆಯ ಜನರನ್ನು ಅಥವಾ ನಿಮ್ಮ ಸಂಸ್ಥೆಯಲ್ಲಿ ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಭೇಟಿ ಮಾಡುವ ಮೂಲಕ ನೀವು ನೆಟ್‌ವರ್ಕ್ ಮಾಡಬಹುದು.",
            "te": "నవీన్ కోసం, జడ్జి సర్‌ని కలవడం మరియు అతని డ్యాన్స్‌పై ఫీడ్‌బ్యాక్ పొందడం అంటే అతను నెట్‌వర్క్‌ని ఎలా నిర్మించుకుంటాడు. దీనినే నెట్‌వర్కింగ్ అంటారు. అర్ధవంతమైన వృత్తిపరమైన సంబంధాలను ప్రారంభించడం మరియు అభివృద్ధి చేయడం ఒక నైపుణ్యం. ఈ కనెక్షన్లు కొన్నిసార్లు మీ కెరీర్ నిచ్చెనలో వృద్ధికి అవకాశాలకు దారి తీయవచ్చు. మీ వృత్తిపరమైన సర్కిల్‌లో ఏమి జరుగుతుందో తెలుసుకోవడంలో కూడా నెట్‌వర్క్ సహాయపడుతుంది. మీరు ఈవెంట్‌లో వ్యక్తులను కలవడం, సోషల్ మీడియా, పరిసరాల్లోని వ్యక్తులను లేదా మీ ఇన్‌స్టిట్యూట్‌లోని పూర్వ విద్యార్థులను కలవడం ద్వారా నెట్‌వర్క్ చేయవచ్చు",
            "or": "ନବୀନଙ୍କ ପାଇଁ, ଜଜ୍ ସାର୍‌ ଙ୍କୁ ଭେଟିବା ଏବଂ ତାଙ୍କ ନୃତ୍ୟ ଫର୍ମ ଉପରେ ମତାମତ ପାଇବା ହେଉଛି ସେ କିପରି ଏକ ନେଟୱାର୍କ ଗଠନ କରନ୍ତି | ଏହାକୁ ନେଟୱାର୍କିଂ କୁହାଯାଏ | ଏହା ଅର୍ଥପୂର୍ଣ୍ଣ ପ୍ରୋଫେସ୍‍ନାଲ୍‌ ସମ୍ପର୍କ ଆରମ୍ଭ ଏବଂ ବିକାଶ କରିବା ଏକ କୌଶଳ ଅଟେ | ଏହି ସଂଯୋଗଗୁଡ଼ିକ ବେଳେବେଳେ ତୁମ କ୍ୟାରିୟର୍‌ ସିଡ଼ିରେ ଅଭିବୃଦ୍ଧି ପାଇଁ ସୁଯୋଗ ଆଣିପାରେ | ତୁମ ପ୍ରଫେସନାଲ୍ ସର୍କଲରେ କ’ଣ ଘଟୁଛି ଜାଣିବାରେ ଏକ ନେଟୱାର୍କ ସାହାଯ୍ୟ ମଧ୍ୟ କରେ |",
            "as": "নবীনৰ বাবে, বিচাৰক চাৰক লগ কৰা আৰু তেওঁৰ নৃত্যৰ বিষয়ে মতামত পোৱাটো হৈছে সি কেনেদৰে এটা নেটৱৰ্ক গঢ়ি তোলে। ইয়াক নেটৱৰ্কিং বুলি কোৱা হয়। ই হৈছে অৰ্থপূৰ্ণ পেছাদাৰী সম্পৰ্ক আৰম্ভ কৰা আৰু বিকশিত কৰা এটা দক্ষতা। এই সংযোগবোৰে কেতিয়াবা তোমাৰ জীৱিকাৰ জখলাত বিকাশৰ সুযোগলৈ লৈ যাব পাৰে। এটা নেটৱৰ্কে তোমাৰ পেছাদাৰী বৃত্তত কি ঘটি আছে জানিবলৈও সহায় কৰে। তুমি কোনো অনুষ্ঠানত লোকসকলক লগ কৰি, সামাজিক মাধ্যমত, চুবুৰীয়াৰ লোকসকলক লগ কৰি বা তোমাৰ প্ৰতিষ্ঠানত প্ৰাক্তন ছাত্ৰ-ছাত্ৰীসকলক লগ কৰি নেটৱৰ্ক গঢ়িব পাৰা।",
            "gu": "નવીન માટે, નિરીક્ષક સાહેબને મળવું અને તેના ડાન્સના દેખાવ પર પ્રતિસાદ મેળવવો એ છે કે તે કેવી રીતે નેટવર્ક બનાવે છે. આને નેટવર્કીંગ કહે છે. અર્થપૂર્ણ ધંધાદારી સંબંધો શરૂ કરવા અને વિકસાવવા તે એક કૌશલ્ય છે. આ જોડાણો ક્યારેક તમારી કારકિર્દીની સીડીમાં વૃદ્ધિની તકો તરફ દોરી શકે છે. નેટવર્ક તમારા ધંધાદારી ચક્ર શું થઈ રહ્યું છે તે જાણવામાં પણ મદદ કરે છે. તમે ઇવેન્ટમાં લોકોને મળીને, સોશિયલ મીડિયા પર, પડોશના લોકોને અથવા તમારી સંસ્થાના ભૂતપૂર્વ વિદ્યાર્થીઓને મળીને નેટવર્ક કરી શકો છો."
          }
        }
      ],
      "page13": [
        {
          "valuePath": "display",
          "value": {
            "en": "mcQ",
            "hi": "mcQ",
            "ka": "ಎಂಸಿಕ್ಯೂ",
            "te": "mcQ",
            "or": "mcQ",
            "as": "mcQ",
            "gu": "mcQ"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Select a statements about networking that is TRUE:",
            "hi": "नेटवर्किंग के बारे में सही विधान चुने:",
            "ka": "ನೆಟ್‌ವರ್ಕಿಂಗ್ ಕುರಿತು ನಿಜವಾಗಿರುವ ಹೇಳಿಕೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ:",
            "te": "నెట్‌వర్కింగ్ గురించి నిజం అయిన ప్రకటనలను ఎంచుకోండి:",
            "or": "ନେଟୱର୍କିଙ୍ଗ ବିଷୟରେ ଏକ ବିବୃତ୍ତି ବାଛନ୍ତୁ ଯାହା ସତ୍ୟ ଅଟେ:",
            "as": "নেটৱৰ্কিং সম্পৰ্কে এটা বিবৃতি বাছনি কৰা যিটো সঁচা:",
            "gu": "નેટવર્કિંગ વિશેના વાક્યોને પસંદ કરો જે સાચું છે:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Networking is one method to do research for your career throughout your life",
            "hi": "नेटवर्किंग, जीवनभर अपने करियर के लिए रिसर्च करने का एक तरीका है",
            "ka": "ನಿಮ್ಮ ಜೀವನದುದ್ದಕ್ಕೂ ನಿಮ್ಮ ವೃತ್ತಿಜೀವನಕ್ಕಾಗಿ ಸಂಶೋಧನೆ ಮಾಡಲು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಒಂದು ವಿಧಾನವಾಗಿದೆ",
            "te": "మీ జీవితాంతం మీ కెరీర్ కోసం పరిశోధన చేయడానికి నెట్‌వర్కింగ్ ఒక పద్ధతి",
            "or": "ଜୀବନସାରା ଆପଣଙ୍କ କ୍ଯାରିୟର୍‌ ପାଇଁ ଗବେଷଣା କରିବା ପାଇଁ ନେଟୱାର୍କିଂ ହେଉଛି ଏକ ପଦ୍ଧତି |",
            "as": "নেটৱৰ্কিং হৈছে তোমাৰ গোটেই জীৱনজুৰি জীৱিকাৰ বাবে গৱেষণা কৰাৰ এটা পদ্ধতি",
            "gu": "નેટવર્કિંગ એ તમારા જીવનભર તમારી કારકિર્દી માટે સંશોધન કરવાની એક રીત છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "You can network at events, conferences, alumni meets or professional online networks.",
            "hi": "आप इवेंट, कॉन्फ्रेंस, एल्यूमनी मीटिंग या प्रोफेशनल ऑनलाइन नेटवर्क के जरिए नेटवर्क बना सकते हैं।",
            "ka": "ನೀವು ಈವೆಂಟ್‌ಗಳು, ಸಮ್ಮೇಳನಗಳು, ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳ ಭೇಟಿಗಳು ಅಥವಾ ವೃತ್ತಿಪರ ಆನ್‌ಲೈನ್ ನೆಟ್‌ವರ್ಕ್‌ಗಳಲ್ಲಿ ನೆಟ್‌ವರ್ಕ್ ಮಾಡಬಹುದು.",
            "te": "మీరు ఈవెంట్‌లు, సమావేశాలు, పూర్వ విద్యార్థుల సమావేశాలు లేదా ప్రొఫెషనల్ ఆన్‌లైన్ నెట్‌వర్క్‌లలో నెట్‌వర్క్ చేయవచ్చు.",
            "or": "ଇଭେଣ୍ଟ, ସମ୍ମିଳନୀ, ଆଲୁମ୍ନି ମିଟ୍ କିମ୍ବା ପେସାଦାର ଅ୍ନ୍‌ଲାଇନ୍ ନେଟୱାର୍କରେ ଆପଣ ନେଟୱାର୍କ କରିପାରିବେ |",
            "as": "তুমি অনুষ্ঠান, সন্মিলন, প্ৰাক্তন শিক্ষাৰ্থীৰ সভা বা পেছাদাৰী অনলাইন নেটৱৰ্কত নেটৱৰ্ক গঢ়িব পাৰা।",
            "gu": "તમે ઇવેન્ટ્સ, કોન્ફરન્સ, ભૂતપૂર્વ વિદ્યાર્થીઓની મળવાનું અથવા ધંધાદારી ઓનલાઇન નેટવર્ક્સમાં નેટવર્ક કરી શકો છો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "There is no difference between a networking conversation, a sales meeting or a job interview.",
            "hi": "नेटवर्किंग संवाद, सेल्स मीटिंग या जॉब इंटरव्यू में कोई अंतर नहीं है।",
            "ka": "ನೆಟ್‌ವರ್ಕಿಂಗ್ ಸಂಭಾಷಣೆ, ಮಾರಾಟ ಸಭೆ ಅಥವಾ ಉದ್ಯೋಗ ಸಂದರ್ಶನದ ನಡುವೆ ಯಾವುದೇ ವ್ಯತ್ಯಾಸವಿಲ್ಲ.",
            "te": "నెట్‌వర్కింగ్ సంభాషణ, సేల్స్ మీటింగ్ లేదా ఉద్యోగ ఇంటర్వ్యూ మధ్య తేడా లేదు.",
            "or": "ଏକ ନେଟୱାର୍କିଂ ବାର୍ତ୍ତାଳାପ, ସେଲ୍ସ ମିଟିଙ୍ଗ୍‌ କିମ୍ବା ଚାକିରି ସାକ୍ଷାତକାର ମଧ୍ୟରେ କୌଣସି ପାର୍ଥକ୍ୟ ନାହିଁ |",
            "as": "নেটৱৰ্কিং কথোপকথন, বিক্ৰী সভা বা চাকৰিৰ সাক্ষাৎকাৰৰ মাজত কোনো পাৰ্থক্য নাই।",
            "gu": "નેટવર્કિંગ વાતચીત, વેચાણ માટે મીટિંગ અથવા નોકરીનું ઇન્ટરવ્યુ વચ્ચે કોઈ તફાવત નથી."
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.display",
          "value": {
            "en": "Networking is important only when someone is looking for a job",
            "hi": "नेटवर्किंग केवल तभी आवश्यक है जब आप जॉब ढूंढ रहे हो",
            "ka": "ಯಾರಾದರೂ ಕೆಲಸ ಹುಡುಕುತ್ತಿರುವಾಗ ಮಾತ್ರ ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮುಖ್ಯವಾಗುತ್ತದೆ",
            "te": "ఎవరైనా ఉద్యోగం కోసం చూస్తున్నప్పుడు మాత్రమే నెట్‌వర్కింగ్ ముఖ్యం",
            "or": "ନେଟୱାର୍କିଂ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ଅଟେ କେବଳ ଯେତେବେଳେ କେହି ଏକ ଚାକିରି ଖୋଜନ୍ତି",
            "as": "নেটৱৰ্কিং কেৱল তেতিয়াহে গুৰুত্বপূৰ্ণ যেতিয়া কোনোবাই চাকৰি বিচাৰি থাকে",
            "gu": "નેટવર્કીંગ ત્યારે જ મહત્વનું છે જ્યારે કોઈ વ્યક્તિ નોકરી શોધી રહી હોય"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.display",
          "value": {
            "en": "Career growth through networking is dishonest and for those who don’t work hard",
            "hi": "नेटवर्किंग के जरिए करियर में वृद्धि करना बेईमानी है और उन लोगों के लिए है जो मेहनत नहीं करना चाहते",
            "ka": "ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮೂಲಕ ವೃತ್ತಿ ಬೆಳವಣಿಗೆಯು ಅಪ್ರಾಮಾಣಿಕವಾಗಿದೆ ಮತ್ತು ಕಷ್ಟಪಟ್ಟು ಕೆಲಸ ಮಾಡದವರಿಗೆ",
            "te": "నెట్‌వర్కింగ్ ద్వారా కెరీర్ పెరుగుదల నిజాయితీ లేనిది మరియు కష్టపడి పని చేయని వారికి",
            "or": "ନେଟୱାର୍କିଂ ମାଧ୍ୟମରେ କ୍ୟାରିୟର ଅଭିବୃଦ୍ଧି ଅସାଧୁ ଏବଂ ସେହିମାନଙ୍କ ପାଇଁ ଯେଉଁମାନେ କଠିନ ପରିଶ୍ରମ କରନ୍ତି ନାହିଁ",
            "as": "নেটৱৰ্কিংৰ জৰিয়তে জীৱিকাৰ বিকাশ অসৎ আৰু যিসকলে কঠোৰ পৰিশ্ৰম নকৰে ই তেওঁলোকৰ বাবে",
            "gu": "નેટવર્કિંગ દ્વારા કારકિર્દી વૃદ્ધિ સાચી નથી જેઓ સખત મહેનત કરતા નથી"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Great job! Networking conversations are great ways to know more about an industry.",
            "hi": "बहुत बढ़िया! नेटवर्किंग संवाद से हमें उद्योग के बारे में अधिक जानकारी प्राप्त होती हैं।",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ! ನೆಟ್‌ವರ್ಕಿಂಗ್ ಸಂಭಾಷಣೆಗಳು ಉದ್ಯಮದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಉತ್ತಮ ಮಾರ್ಗಗಳಾಗಿವೆ.",
            "te": "బాగా చెప్పారు! పరిశ్రమ. గురించి మరింత తెలుసుకోవడానికి నెట్‌వర్కింగ్ సంభాషణలు గొప్ప మార్గాలు.",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ ! ଏକ ଶିଳ୍ପ ବିଷୟରେ ଅଧିକ ଜାଣିବା ପାଇଁ ନେଟୱାର୍କିଙ୍ଗ୍ ବାର୍ତ୍ତାଳାପଗୁଡିକ ଏକ ଉତ୍ତମ ଉପାୟ ଅଟେ |",
            "as": "ভাল কৰিছা! নেটৱৰ্কিং কথোপকথন এটা উদ্যোগৰ বিষয়ে অধিক জানিবলৈ উৎকৃষ্ট উপায়।",
            "gu": "સરસ કામ! નેટવર્કિંગ વાર્તાલાપ એ ઉદ્યોગ વિશે વધુ જાણવા માટેની શ્રેષ્ઠ રીતો છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job! Events, conferences, alumni meets etc are great places to develop your network.",
            "hi": "बहुत बढ़िया! इवेंट, कॉन्फ्रेंस, एल्यूमनी मीटिंग आपके नेटवर्क को विकसित करने के लिए सही स्थान है।",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ! ಈವೆಂಟ್‌ಗಳು, ಸಮ್ಮೇಳನಗಳು, ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳ ಸಭೆಗಳು ಇತ್ಯಾದಿಗಳು ನಿಮ್ಮ ನೆಟ್‌ವರ್ಕ್ ಅನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಲು ಉತ್ತಮ ಸ್ಥಳಗಳಾಗಿವೆ.",
            "te": "గొప్ప పని! ఈవెంట్‌లు, సమావేశాలు, పూర్వ విద్యార్థుల సమావేశాలు మొదలైనవి మీ నెట్‌వర్క్‌ను అభివృద్ధి చేయడానికి గొప్ప స్థలాలు.",
            "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ ! ଇଭେଣ୍ଟ, ସମ୍ମିଳନୀ, ଆଲୁମ୍ନି ମିଟ୍ ଇତ୍ୟାଦି ତୁମର ନେଟୱାର୍କ ବିକାଶ ପାଇଁ ଉତ୍ତମ ସ୍ଥାନ |",
            "as": "ভাল কৰিছা! অনুষ্ঠান, সন্মিলন, প্ৰাক্তন শিক্ষাৰ্থীৰ সভা আদি তোমাৰ নেটৱৰ্ক বিকশিত কৰাৰ বাবে উৎকৃষ্ট স্থান।",
            "gu": "સરસ કામ! ઇવેન્ટ્સ, કોન્ફરન્સ, ભૂતપૂર્વ વિદ્યાર્થીઓની મળવું વગેરે તમારા નેટવર્કને વિકસાવવા માટે શ્રેષ્ઠ સ્થાનો છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! The goals of each of them are different.",
            "hi": "फिर से सोचो! इसमें से हर एक का लक्ष्य अलग है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಅವುಗಳಲ್ಲಿ ಪ್ರತಿಯೊಂದರ ಗುರಿಗಳು ವಿಭಿನ್ನವಾಗಿವೆ.",
            "te": "మరలా ఆలోచించు! ఒక్కొక్కరి లక్ష్యాలు ఒక్కో విధంగా ఉంటాయి.",
            "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ସେମାନଙ୍କ ମଧ୍ୟରୁ ପ୍ରତ୍ୟେକର ଲକ୍ଷ୍ୟ ଅଲଗା ଅଟେ |",
            "as": "আকৌ চিন্তা কৰা! তেওঁলোকৰ প্ৰত্যেকৰ লক্ষ্য বেলেগ।",
            "gu": "ફરીથી વિચારો! તેમાંના દરેકના લક્ષ્યો જુદા જુદા હોય છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.popUpText",
          "value": {
            "en": "Think again! Networking is required throughout your career to stay in touch with the industry",
            "hi": "फिर से सोचो! नेटवर्किंग की जरूरत आपके पूरे करियर में होती है ताकि आप इंडस्ट्री के बारे में नई जानकारी हासिल कर सके।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಉದ್ಯಮದೊಂದಿಗೆ ಸಂಪರ್ಕದಲ್ಲಿರಲು ನಿಮ್ಮ ವೃತ್ತಿಜೀವನದುದ್ದಕ್ಕೂ ನೆಟ್‌ವರ್ಕಿಂಗ್ ಅಗತ್ಯವಿದೆ",
            "te": "మరలా ఆలోచించు! పరిశ్రమతో సన్నిహితంగా ఉండటానికి మీ కెరీర్ మొత్తంలో నెట్‌వర్కింగ్ అవసరం",
            "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ଇଣ୍ଡଷ୍ଟ୍ରି ସହିତ ଯୋଗାଯୋଗରେ ରହିବା ପାଇଁ ଆପଣଙ୍କ ସାରା କ୍ୟାରିଅରରେ ନେଟୱାର୍କିଂ ଆବଶ୍ୟକ",
            "as": "আকৌ চিন্তা কৰা! উদ্যোগটোৰ সৈতে সম্পৰ্ক থাকিবলৈ তোমাৰ সমগ্ৰ জীৱনত নেটৱৰ্কিং প্ৰয়োজনীয়",
            "gu": "ફરીથી વિચારો! ઉદ્યોગ સાથે સંપર્કમાં રહેવા માટે તમારી સમગ્ર કારકિર્દી દરમિયાન વાર્તાલાભ જરૂરી છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.popUpText",
          "value": {
            "en": "Think again! Networking is a requirement for career growth even for those who work hard",
            "hi": "फिर से सोचो! जो लोग मेहनत करते हैं उन्हें भी करियर में वृद्धि के लिए नेटवर्किंग की जरूरत पड़ती है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಕಷ್ಟಪಟ್ಟು ಕೆಲಸ ಮಾಡುವವರಿಗೂ ವೃತ್ತಿ ಬೆಳವಣಿಗೆಗೆ ನೆಟ್‌ವರ್ಕಿಂಗ್ ಅಗತ್ಯವಾಗಿದೆ",
            "te": "మళ్లీ ఆలోచించండి! కష్టపడి పనిచేసే వారికి కూడా కెరీర్ వృద్ధికి నెట్‌వర్కింగ్ అవసరం",
            "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ଯେଉଁମାନେ କଠିନ ପରିଶ୍ରମ କରନ୍ତି ସେମାନଙ୍କର କ୍ୟାରିୟର ଅଭିବୃଦ୍ଧି ପାଇଁ ନେଟୱାର୍କିଂ ଆବଶ୍ୟକତା ଅଟେ |",
            "as": "আকৌ চিন্তা কৰা! নেটৱৰ্কিং হৈছে জীৱিকাৰ বিকাশৰ বাবে এক প্ৰয়োজনীয়তা আনকি যিসকলে কঠোৰ পৰিশ্ৰম কৰে তেওঁলোকৰ বাবেও",
            "gu": "ફરીથી વિચાર! જેઓ સખત મહેનત કરે છે તેમના માટે પણ કારકિર્દી વૃદ્ધિ માટે નેટવર્કિંગ જરૂરી છે"
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_3_4",
            "hi": "D6_3_4",
            "ka": "D6_3_4",
            "te": "D6_3_4",
            "or": "D6_3_4",
            "as": "D6_3_4",
            "gu": "D6_3_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen is very excited about this offer, but he decides to think it over before confirming with the judge.",
            "hi": "नवीन इस ऑफर को लेकर बहुत खुश है, लेकिन वह हाँ बोलने से पहले सोचना चाहता है।",
            "ka": "ನವೀನ್ ಈ ಪ್ರಸ್ತಾಪದ ಬಗ್ಗೆ ತುಂಬಾ ಉತ್ಸುಕನಾಗಿದ್ದಾನೆ, ಆದರೆ ನ್ಯಾಯಾಧೀಶರೊಂದಿಗೆ ದೃಢೀಕರಿಸುವ ಮೊದಲು ಅದನ್ನು ಯೋಚಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
            "te": "నవీన్ ఈ ఆఫర్ గురించి చాలా ఉత్సాహంగా ఉన్నాడు, కానీ అతను జడ్జితో ధృవీకరించే ముందు దాని గురించి ఆలోచించాలని నిర్ణయించుకున్నాడు.",
            "or": "ନବୀନ ଏହି ଅଫର୍‌କୁ ନେଇ ବହୁତ ଉତ୍ସାହିତ ଅଛନ୍ତି, କିନ୍ତୁ ବିଚାରପତିଙ୍କ ସହିତ ନିଶ୍ଚିତ କରିବା ପୂର୍ବରୁ ସେ ଏହା ଉପରେ ଚିନ୍ତା କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
            "as": "নবীন এই প্ৰস্তাৱটোক লৈ অতি উৎসাহিত, কিন্তু সি বিচাৰকক নিশ্চিতি দিয়াৰ আগতে এইটো চিন্তা কৰাৰ সিদ্ধান্ত লয়।",
            "gu": "નવીન આ ઑફર વિશે ખૂબ જ ઉત્સાહિત છે, પરંતુ તેણે નિર્ણાયક સાથે સમર્થન કરવું પહેલાં તેના પર વિચાર કરવાનું નક્કી કર્યું."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_4_1",
            "hi": "D6_4_1",
            "ka": "D6_4_1",
            "te": "D6_4_1",
            "or": "D6_4_1",
            "as": "D6_4_1",
            "gu": "D6_4_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "When he reaches home that day, his mother asks him how it went. He tells her that they didn’t make it through.",
            "hi": "उस दिन जब वह घर पहुंचता है तो उसकी माँ पूछती है कि इवेंट कैसे था। वह उसे बताता है कि वह क्वालीफाई नहीं हो सके।",
            "ka": "ಆ ದಿನ ಅವನು ಮನೆಗೆ ಬಂದಾಗ, ಅದು ಹೇಗೆ ಹೋಯಿತು ಎಂದು ಅವನ ತಾಯಿ ಅವನನ್ನು ಕೇಳುತ್ತಾಳೆ. ಅವರು ಅದನ್ನು ಸಾಧಿಸಲಿಲ್ಲ ಎಂದು ಅವನು ಅವಳಿಗೆ ಹೇಳುತ್ತಾನೆ.",
            "te": "ఆ రోజు అతను ఇంటికి చేరుకోగానే, ఎలా జరిగింది అని అతని తల్లి అడుగుతుంది. వారు దానిని సాధించలేదని అతను ఆమెకు చెప్పాడు.",
            "or": "ଯେବେ ସେହି ଦିନ ସେ ଘରେ ପହଞ୍ଚନ୍ତି , ତାଙ୍କ ମା ତାଙ୍କୁ ପଚାରିଲେ ଏହା କେମିତି ହେଲା | ସେ ତାଙ୍କୁ କୁହନ୍ତି ଯେ ସେମାନେ ତାହା କରିପାରିନାହନ୍ତି |",
            "as": "সেইদিনা যেতিয়া সি ঘৰত উপস্থিত হয়, তাৰ মাকে তাক এইটো কেনে হ’ল সুধিলে। সি তেখেতক ক’লে যে সিহঁতে এইটো উত্তীৰ্ণ কৰিব নোৱাৰিলে।",
            "gu": "તે દિવસે જ્યારે તે ઘરે પહોંચે છે, ત્યારે તેની માતા તેને પૂછે છે કે તે કેવું રહ્યું. તે એને કહે છે કે તેઓ તેમાંથી પસાર થયા નથી."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_4_2////",
            "hi": "D6_4_2////",
            "ka": "D6_4_2////",
            "te": "D6_4_2////",
            "or": "D6_4_2////",
            "as": "D6_4_2////",
            "gu": "D6_4_2////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "It’s all a part of life, Naveen. You will only learn through these experiences.",
            "hi": "जिंदगी में हार जीत तो होती ही रहती है, नवीन। तुम इससे अनुभव प्राप्त कर सकते हो।",
            "ka": "ಇದೆಲ್ಲವೂ ಜೀವನದ ಒಂದು ಭಾಗ, ನವೀನ್. ಈ ಅನುಭವಗಳ ಮೂಲಕ ಮಾತ್ರ ನೀವು ಕಲಿಯುವಿರಿ.",
            "te": "అదంతా జీవితంలో ఒక భాగం, నవీన్. నువ్వు ఈ అనుభవాల ద్వారా మాత్రమే నేర్చుకుంటావు.",
            "or": "ଏହା ଜୀବନର ଏକ ଅଙ୍ଗ, ନବୀନ | ତୁମେ କେବଳ ଏହି ଅନୁଭୂତିଗୁଡ଼ିକ ମାଧ୍ୟମରେ ଶିଖିବେ |",
            "as": "এয়া সকলো জীৱনৰ এক অংশ, নবীন। তুমি কেৱল এই অভিজ্ঞতাবোৰৰ জৰিয়তেই শিকিব পাৰিবা।",
            "gu": "આ બધું જીવનનો એક ભાગ છે, નવીન. તમે આ અનુભવો દ્વારા જ શીખી શકશો."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "I know. But right now, it feels too challenging to earn and pursue my passion. I know you have been keen that I take up a job, but I have been busy practising.",
            "hi": "मुझे पता है। लेकिन फिलहाल मुझे पैसे कमाना और अपने जुनून को हासिल करना बहुत मुश्किल लग रहा है। मुझे पता है कि आप चाहती हैं कि मैं जॉब करूं, लेकिन मैं प्रेक्टिस करने में बिजी था।",
            "ka": "ನನಗೆ ಗೊತ್ತು. ಆದರೆ ಇದೀಗ, ನನ್ನ ಉತ್ಸಾಹವನ್ನು ಗಳಿಸುವುದು ಮತ್ತು ಮುಂದುವರಿಸುವುದು ತುಂಬಾ ಸವಾಲಿನ ಸಂಗತಿಯಾಗಿದೆ. ನಾನು ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ನೀವು ಉತ್ಸುಕರಾಗಿದ್ದೀರಿ ಎಂದು ನನಗೆ ತಿಳಿದಿದೆ, ಆದರೆ ನಾನು ಅಭ್ಯಾಸದಲ್ಲಿ ನಿರತನಾಗಿದ್ದೆ.",
            "te": "నాకు తెలుసు. కానీ ప్రస్తుతం, సంపాదించడం మరియు నా అభిరుచిని కొనసాగించడం చాలా సవాలుగా అనిపిస్తుంది. నేను ఉద్యోగంలో చేరాలని మీరు ఆసక్తిగా ఉన్నారని నాకు తెలుసు, కానీ నేను సాధన చేయడంలో బిజీగా ఉన్నాను.",
            "or": "ମୁଁ ଜାଣେ। କିନ୍ତୁ ବର୍ତ୍ତମାନ, ମୁଁ ଚ୍ୟାଲେଞ୍ଜିଙ୍ଗ୍ ଅନୁଭବ କରେ ରୋଜଗାର କରିବା ଏବଂ ମୋ ଆବେଗକୁ ଅନୁସରଣ କରିବା | ମୁଁ ଜାଣେ ତୁମେ ଆଗ୍ରହ ପ୍ରକାଶ କରିଛ ଯେ ମୁଁ ଏକ ଚାକିରି କରେ, କିନ୍ତୁ ମୁଁ ଅଭ୍ୟାସ କରିବାରେ ବ୍ୟସ୍ତ ଅଛି |",
            "as": "মই জানো। কিন্তু এই মুহূৰ্তত, মোৰ উপাৰ্জন কৰা আৰু আবেগ অনুসৰণ কৰাটো যথেষ্ট প্ৰত্যাহ্বানমূলক যেন লাগিছে। মই জানো তুমি ইচ্ছা কৰিছিলা যে মই এটা চাকৰি লওঁ, কিন্তু মই অনুশীলনত ব্যস্ত আছিলো।",
            "gu": "હું જાણું છું. પરંતુ અત્યારે, મારા આવેગને કમાવવા અને તેને આગળ ધપાવવાનું ખૂબ જ કઠિન લાગે છે. હું જાણું છું કે તમે મને નોકરી કરવા માટે ઉત્સુક છો, પરંતુ હું મહેનતમાં વ્યસ્ત છું."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Naveen, I’m your mother. I want you to take up a job only because I don't want you to live life the way I have. Constantly having to struggle. It’s not a respectable way to live.",
            "hi": "नवीन, मैं तुम्हारी माँ हूँ। मैं चाहती हूँ कि तुम जॉब करो क्योंकि तुम मुझसे बेहतर जिंदगी जी सको।मुझे जिंदगी में हमेशा संघर्ष करना पड़ा है। इस तरह से जीने पर लोग तुम्हारी ज्यादा इज्जत नहीं करते।",
            "ka": "ನವೀನ್, ನಾನು ನಿನ್ನ ತಾಯಿ. ನೀನು ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕೆಂದು ನಾನು ಬಯಸುತ್ತೇನೆ ಏಕೆಂದರೆ ನೀನು ನನ್ನಂತೆಯೇ ಜೀವನವನ್ನು ನಡೆಸಬೇಕೆಂದು ನಾನು ಬಯಸುವುದಿಲ್ಲ. ನಿರಂತರವಾಗಿ ಹೋರಾಟ ಮಾಡಬೇಕಾಗುತ್ತದೆ. ಇದು ಗೌರವಯುತವಾಗಿ ಬದುಕುವ ಮಾರ್ಗವಲ್ಲ",
            "te": "నవీన్, నేను నీ అమ్మని. నువ్వు ఉద్యోగంలో చేరాలని నేను కోరుకుంటున్నాను ఎందుకంటే నువ్వు నాలాంటి జీవితాన్ని గడపాలని నేను కోరుకోను. నిరంతరం కష్టపడాల్సి వస్తుంది. ఇది గౌరవప్రదంగా జీవించే మార్గం కాదు.",
            "or": "ନବୀନ, ମୁଁ ତୁମର ମାତା | ମୁଁ ଚାହେଁ ତୁମେ କେବଳ ଏକ ଚାକିରି କର କାରଣ ମୁଁ ଚାହେଁ ନାହିଁ ତୁମେ ମୋ ଭଳି ଜୀବନଯାପନ କର | ନିରନ୍ତର ସଂଘର୍ଷ କରିବାକୁ ପଡିବ | ଏହା ବଞ୍ଚିବାର ଏକ ସମ୍ମାନଜନକ ଉପାୟ ନୁହେଁ |",
            "as": "নবীন, মই তোমাৰ মা হয়। মই বিচাৰো যে তুমি চাকৰি এটা লোৱা কাৰণ মই নিবিচাৰো যে তুমি মোৰ দৰে জীৱন যাপন কৰা। নিৰন্তৰ সংগ্ৰাম কৰিব লগা হয়। এইটো জীয়াই থকাৰ এক সন্মানীয় উপায় নহয়।",
            "gu": "નવીન, હું તારી મા છું. હું ઈચ્છું છું કે તમે માત્ર નોકરી કરો કારણ કે હું નથી ઈચ્છતી કે તું મારી જેમ જીવન જીવવું. સતત સંઘર્ષ કરવો પડે છે. તે જીવવાની આદરણીય રીત નથી."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "I want to start earning Amma, I do! I’m just feeling confused between supporting my dance group or working towards improving myself.",
            "hi": "मैं भी पैसे कमाना चाहता हूँ अम्मा, सच में! लेकिन मैं अपने डान्स ग्रुप को सपोर्ट करने और अपने लिए पैसा कमाने को लेकर कंफ्यूज हूँ।",
            "ka": "ನಾನು ಸಂಪಾದಿಸಲು ಪ್ರಾರಂಭಿಸಲು ಬಯಸುತ್ತೇನೆ ಅಮ್ಮ,ನಾನು ಮಾಡುತ್ತೇನೆ! ನನ್ನ ನೃತ್ಯ ಗುಂಪನ್ನು ಬೆಂಬಲಿಸುವ ಅಥವಾ ನನ್ನನ್ನು ಸುಧಾರಿಸುವ ನಿಟ್ಟಿನಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ನಡುವೆ ನಾನು ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದೇನೆ.",
            "te": "నేను అమ్మను సంపాదించడం ప్రారంభించాలనుకుంటున్నాను, నేనే! నా డ్యాన్స్ గ్రూప్‌కి సపోర్ట్ చేయడం లేదా నన్ను నేను మెరుగుపరుచుకునే పనిలో నేను గందరగోళంగా ఉన్నాను.",
            "or": "ମୁଁ ରୋଜଗାର ଆରମ୍ଭ କରିବାକୁ ଚାହୁଁଛି ଆମ୍ମା, ମୁଁ କରିବି ! ମୋର ଡ୍ଯାନ୍ସ ଗ୍ରୁପ୍‌କୁ ସମର୍ଥନ କରି୍ବି କିମ୍ବା ନିଜକୁ ଉନ୍ନତ କରିବା ଦିଗରେ କାର୍ଯ୍ୟ କରିବି ମଧ୍ୟରେ ମୁଁ ବର୍ତ୍ତମାନ ଦ୍ୱନ୍ଦ୍ୱରେ ଅନୁଭବ କରୁଛି |",
            "as": "মই উপাৰ্জন আৰম্ভ কৰিব বিচাৰো মা, সঁচাকৈ! মই কেৱল বিমোৰত পৰিছো যে মই মোৰ নৃত্য গোটক সমৰ্থন কৰো নে নিজকে উন্নত কৰাৰ দিশত কাম কৰো।",
            "gu": "માં મારે કમાવવાનું ચાલુ કરવું છે, હું કરું છું! હું મારા ડાન્સ ગ્રૂપને ટેકો આપવા અથવા મારી જાતને સુધારવાની દિશામાં કામ કરવા વચ્ચે મૂંઝવણ અનુભવું છું."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "It’s your decision Naveen. You have to build your own life. I am just happy to see you take this much effort towards something.",
            "hi": "यह तुम्हारा निर्णय है नवीन। तुम्हें अपनी जिंदगी खुद बेहतर बनानी होगी। लेकिन मैं खुश हूँ कि तुम किसी चीज के लिए इतना प्रयास कर रहे हो।",
            "ka": "ಇದು ನಿನ್ನ ನಿರ್ಧಾರ ನವೀನ್. ನಿನ್ನ ಸ್ವಂತ ಜೀವನವನ್ನು ನೀನು ನಿರ್ಮಿಸಿಕೊಳ್ಳಬೇಕು. ನೀನು ಏನನ್ನಾದರೂ ಮಾಡಲು ಇಷ್ಟು ಪ್ರಯತ್ನವನ್ನು ಮಾಡುತ್ತಿರುವುದನ್ನು ನೋಡಿ ನನಗೆ ಸಂತೋಷವಾಗಿದೆ.",
            "te": "ఇది నీ నిర్ణయం నవీన్. మీరు మీ స్వంత జీవితాన్ని నిర్మించుకోవాలి. మీరు దేనికోసమైనా ఇంత ప్రయత్నం చేయడం చూసి నేను సంతోషిస్తున్నాను.",
            "or": "ଏହା ତୁମର ନିଷ୍ପତ୍ତି ନବୀନ | ତୁମକୁ ନିଜ ଜୀବନ ଗଢିବାକୁ ପଡିବ | ତୁମେ କୌଣସି ଜିନିଷ ପ୍ରତି ଏତେ ପରିଶ୍ରମ କରୁଥିବାର ଦେଖି ମୁଁ ଖୁସି |",
            "as": "এয়া তোমাৰ সিদ্ধান্ত নবীন। তুমি তোমাৰ জীৱন নিজেই গঢ়িব লাগিব। তুমি কিবা এটা কৰাৰ দিশত ইমান পৰিশ্ৰম কৰা দেখি মই সুখী হৈছোঁ।",
            "gu": "આ તારો નિર્ણય છે નવીન. તારે તારું જીવન જાતે બનાવવું પડશે. તું કંઈક માટે તરફ આટલી મહેનત કરતા જોઈને મને આનંદ થાય છે."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_4_3(D)",
            "hi": "D6_4_3(D)",
            "ka": "D6_4_3(D)",
            "te": "D6_4_3(D)",
            "or": "D6_4_3(D)",
            "as": "D6_4_3(D)",
            "gu": "D6_4_3(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Naveen do about the judge’s offer?",
            "hi": "नवीन को जज के ऑफर के बारे में क्या करना चाहिए?",
            "ka": "ನ್ಯಾಯಾಧೀಶರ ಪ್ರಸ್ತಾಪಕ್ಕೆ ನವೀನ್ ಏನು ಮಾಡಬೇಕು?",
            "te": "జడ్జ్ ఆఫర్ గురించి నవీన్ ఏమి చేయాలి?",
            "or": "ଜଜ୍‌ଙ୍କ ଅଫ‍ର୍‌ ବିଷୟରେ ନବୀନ କ’ଣ କରିବା ଉଚିତ୍ ?",
            "as": "বিচাৰকৰ প্ৰস্তাৱটোৰ বিষয়ত নবীনে কি কৰা উচিত?",
            "gu": "નવીને નિરીક્ષકે જે તક આપેલી છે એના વિશે શું કરવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Turn down the offer and try to get a job where he can earn more and support his group",
            "hi": "ऑफर को मना करना चाहिए। और ऐसी जॉब ढूंढने चाहिए जहाँ वह ज्यादा पैसे कमा सके और अपने ग्रुप को सपोर्ट करना चाहिए।",
            "ka": "ಪ್ರಸ್ತಾಪವನ್ನು ತಿರಸ್ಕರಿಸಿ ಮತ್ತು ಅವನು ಹೆಚ್ಚು ಗಳಿಸುವ ಮತ್ತು ಅವನ ಗುಂಪನ್ನು ಬೆಂಬಲಿಸುವ ಕೆಲಸವನ್ನು ಪಡೆಯಲು ಪ್ರಯತ್ನಿಸಿ",
            "te": "ఆఫర్‌ను తిరస్కరించి, అతను మరింత సంపాదించగల మరియు అతని సమూహానికి మద్దతు ఇవ్వగల ఉద్యోగం పొందడానికి ప్రయత్నించాలా",
            "or": "ଅଫରକୁ ମନା କରନ୍ତୁ ଏବଂ ଏକ ଚାକିରି ପାଇବାକୁ ଚେଷ୍ଟା କରନ୍ତୁ ଯେଉଁଠାରେ ସେ ଅଧିକ ରୋଜଗାର କରିପାରିବେ ଏବଂ ତାଙ୍କ ଗ୍ରୁପ୍‌କୁ ସମର୍ଥନ କରିପାରିବେ |",
            "as": "প্ৰস্তাৱটো প্ৰত্যাখ্যান কৰিব আৰু চাকৰি এটা পাবলৈ চেষ্টা কৰিব য'ত সি অধিক উপাৰ্জন কৰিব পাৰে আৰু তাৰ গোটটোক সমৰ্থন কৰিব পাৰে",
            "gu": "આ તકને નકારી કાઢ અને નોકરી મેળવવાનો પ્રયાસ કર જ્યાં તે વધુ કમાણી કરી શકે અને તેના જૂથને ટેકો આપી શકે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Take up the judge’s offer and join the dance studio, and improve his own technique",
            "hi": "जज की ऑफर को स्वीकार करना चाहिए और उसकी डान्स स्टूडियो में जाकर अपनी तकनीक को सुधारना चाहिए।",
            "ka": "ನ್ಯಾಯಾಧೀಶರ ಪ್ರಸ್ತಾಪವನ್ನು ಸ್ವೀಕರಿಸಿ ಮತ್ತು ಡ್ಯಾನ್ಸ್ ಸ್ಟುಡಿಯೊಗೆ ಸೇರಿ ಮತ್ತು ಅವನ ಸ್ವಂತ ತಂತ್ರವನ್ನು ಸುಧಾರಿಸಿ",
            "te": "న్యాయనిర్ణేత ఆఫర్‌ను స్వీకరించి, డ్యాన్స్ స్టూడియోలో చేరండి మరియు అతని స్వంత సాంకేతికతను మెరుగుపరచుకోవాలి",
            "or": "ଜଜ୍‌ଙ୍କ ଅଫର ନିଅନ୍ତୁ ଏବଂ ଡ୍ୟାନ୍ସ ଷ୍ଟୁଡିଓରେ ଯୋଗ ଦିଅନ୍ତୁ, ଏବଂ ନିଜର କୌଶଳର ଉନ୍ନତି କରନ୍ତୁ",
            "as": "বিচাৰকৰ প্ৰস্তাৱ গ্ৰহণ কৰিব আৰু নৃত্য ষ্টুডিঅ'ত যোগদান কৰিব, আৰু তাৰ নিজৰ কৌশল উন্নত কৰিব",
            "gu": "નિરીક્ષકે આપેલી તક સ્વીકાર અને ડાન્સ સ્ટુડિયોમાં જોડાઓ અને પોતાની ટેકનિકમાં સુધારો કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.tag",
          "value": {
            "en": "personal growth",
            "hi": "स्वयं विकास",
            "ka": "ವೈಯಕ್ತಿಕ ಬೆಳವಣಿಗೆ",
            "te": "వ్యక్తిగత వృద్ధి",
            "or": "ବ୍ୟକ୍ତିଗତ ଅଭିବୃଦ୍ଧି",
            "as": "ব্যক্তিগত বিকাশ",
            "gu": "વ્યક્તિગત વિકાસ"
          }
        }
      ]
    },
    "scene31": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_1_1_Side Job to support the group",
            "hi": "ग्रुप को सपोर्ट करने के लिए साइड जॉब करना",
            "ka": "D7_1_1_ಗುಂಪನ್ನು ಬೆಂಬಲಿಸಲು ಸೈಡ್ ಜಾಬ್",
            "te": "సమూహానికి మద్దతు ఇవ్వడానికి సైడ్ ఉద్యోగం",
            "or": "D7_1_1_Side Job to support the group",
            "as": "D7_1_1_গোটটো সমৰ্থন কৰিবলৈ একাষৰীয়া চাকৰি",
            "gu": "D7_1_1_જૂથને ટેકો આપવા માટે બાજુમાં બીજી નોકરી"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Taking inspiration from the judge’s story, Naveen decides to find work to earn some money to support his group. On the side, he learn more dance skills and techniques.",
            "hi": "जज की कहानी से प्रभावित होने के बाद, नवीन ने यह निर्णय लिया कि वह अपने ग्रुप को सपोर्ट करने के लिए पैसे कमाने के लिए जॉब करेगा। और साथ ही साथ डांस के\n कौशल और तकनीक भी सीखेगा।",
            "ka": "ನ್ಯಾಯಾಧೀಶರ ಕಥೆಯಿಂದ ಸ್ಫೂರ್ತಿ ಪಡೆದ ನವೀನ್ ತನ್ನ ಗುಂಪನ್ನು ಬೆಂಬಲಿಸಲು ಸ್ವಲ್ಪ ಹಣವನ್ನು ಗಳಿಸಲು ಕೆಲಸವನ್ನು ಹುಡುಕಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಬದಿಯಲ್ಲಿ, ಅವನು ಹೆಚ್ಚು ನೃತ್ಯ ಕೌಶಲ್ಯ ಮತ್ತು ತಂತ್ರಗಳನ್ನು ಕಲಿಯುತ್ತಾನೆ.",
            "te": "న్యాయమూర్తి కథ నుండి ప్రేరణ పొంది, నవీన్ తన సమూహానికి మద్దతుగా కొంత డబ్బు సంపాదించడానికి పనిని కనుగొనాలని నిర్ణయించుకున్నాడు. మరోవైపు, అతను మరింత నృత్య నైపుణ్యాలు మరియు సాంకేతికతలను నేర్చుకుంటాడు.",
            "or": "ବିଚାରପତିଙ୍କ କାହାଣୀରୁ ପ୍ରେରଣା ନେଇ ନବୀନ ତାଙ୍କ ଗୋଷ୍ଠୀକୁ ସମର୍ଥନ କରିବା ପାଇଁ କିଛି ଟଙ୍କା ରୋଜଗାର କରିବା ପାଇଁ କାର୍ଯ୍ୟ ଖୋଜିବାକୁ ସ୍ଥିର କରିଛି |ପାର୍ଶ୍ୱରେ, ସେ ଅଧିକ ନୃତ୍ୟ ଦକ୍ଷତା ଏବଂକୌଶଳ ଶିଖନ୍ତି |",
            "as": "বিচাৰকৰ কাহিনীৰ পৰা অনুপ্ৰেৰণা লৈ, নবীনে তাৰ গোটটোক সমৰ্থন কৰিবৰ বাবে কিছু টকা উপাৰ্জন কৰিবলৈ কাম বিচাৰিবলৈ সিদ্ধান্ত লয়। লগতে, সি অধিক নৃত্য দক্ষতা আৰু কৌশল শিকে।",
            "gu": "નિર્ણાયકની વાર્તામાંથી પ્રેરણા લઈને, નવીન તેના જૂથને ટેકો આપવા માટે કેટલાક પૈસા કમાવવા માટે કામ શોધવાનું નક્કી કરે છે. બાજુ પર, તે વધુ નૃત્ય કૌશલ્યો અને તકનીકો શીખે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_1_2",
            "hi": "D7_1_2",
            "ka": "D7_1_2",
            "te": "D7_1_2",
            "or": "D7_1_2",
            "as": "D7_1_2",
            "gu": "D7_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "He joins a gym as an assistant trainer. Every evening after the gym closes, he uses that time to learns dance online.",
            "hi": "वह असिस्टेंट ट्रेनर के रूप में एक जिम में काम करना शुरू करता है। जिम बंद होने के बाद शाम को ऑनलाइन डांस सीखने में समय बिताता है।।",
            "ka": "ಅವನು ಸಹಾಯಕ ತರಬೇತುದಾರರಾಗಿ ಜಿಮ್‌ಗೆ ಸೇರುತ್ತಾನೆ. ಪ್ರತಿದಿನ ಸಂಜೆ ಜಿಮ್ ಮುಚ್ಚಿದ ನಂತರ, ಅವನು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ನೃತ್ಯ ಕಲಿಯಲು ಆ ಸಮಯವನ್ನು ಬಳಸುತ್ತಾನೆ.",
            "te": "అతను జిమ్‌లో అసిస్టెంట్ ట్రైనర్‌గా చేరాడు. ప్రతిరోజూ సాయంత్రం జిమ్ మూసివేసిన తర్వాత, అతను ఆన్‌లైన్‌లో డ్యాన్స్ నేర్చుకోవడానికి ఆ సమయాన్ని ఉపయోగించుకుంటాడు.",
            "or": "ସେ ଏକ ଜିମ୍‌ରେ ଆସିସ୍‌ଟେଣ୍ଟ ଟ୍ରେନର ଭାବରେ ଯୋଗ ଦିଅନ୍ତି | ଜିମ୍ ବନ୍ଦ ହେବା ପରେ ପ୍ରତ୍ୟେକ ସନ୍ଧ୍ୟାରେ, ସେ ସେହି ସମୟକୁ ଅନ୍‌ଲାଇନ୍‌ରେ ନୃତ୍ୟ ଶିଖିବା ପାଇଁ ବ୍ୟବହାର କରନ୍ତି |",
            "as": "সি সহকাৰী প্ৰশিক্ষক হিচাপে এটা জিমত যোগদান কৰে। সদায় সন্ধিয়া জিম বন্ধ হোৱাৰ পিছত, সি সেই সময়খিনি অনলাইনত নৃত্য শিকিবলৈ ব্যৱহাৰ কৰে।",
            "gu": "તે સહાયક ટ્રેનર તરીકે જીમમાં જોડાય છે. દરરોજ સાંજે જીમ બંધ થયા પછી, તે તેનો સમયનો ઉપયોગ ઓનલાઈન ડાન્સ શીખવા માટે કરે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_1_3",
            "hi": "D7_1_3",
            "ka": "D7_1_3",
            "te": "D7_1_3",
            "or": "D7_1_3",
            "as": "D7_1_3",
            "gu": "D7_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Over weekends he goes to teach these techniques to the rest of his group. They all have been practising harder after they failed at the reality show.",
            "hi": "छुट्टी के दिन वह अपने ग्रुप के अन्य सदस्यों को यह तकनीक सिखाता है। रियलिटी शो में हारने के बाद, वे सब बहुत मेहनत से प्रैक्टिस कर रहे हैं।",
            "ka": "ವಾರಾಂತ್ಯದಲ್ಲಿ ಅವನು ತನ್ನ ಗುಂಪಿನ ಉಳಿದವರಿಗೆ ಈ ತಂತ್ರಗಳನ್ನು ಕಲಿಸಲು ಹೋಗುತ್ತಾನೆ. ರಿಯಾಲಿಟಿ ಶೋನಲ್ಲಿ ವಿಫಲರಾದ ನಂತರ ಅವರೆಲ್ಲರೂ ಕಠಿಣ ಅಭ್ಯಾಸ ನಡೆಸುತ್ತಿದ್ದಾರೆ.",
            "te": "వారాంతాల్లో అతను తన బృందంలోని మిగిలిన వారికి ఈ పద్ధతులను నేర్పడానికి వెళ్తాడు. రియాల్టీ షోలో ఫెయిల్ అయిన తర్వాత వారంతా మరింత కష్టపడి సాధన చేస్తున్నారు.",
            "or": "ସପ୍ତାହ ଶେଷରେ ସେ ତାଙ୍କ ଅବଶିଷ୍ଟ ଗୋଷ୍ଠୀକୁ ଏହି କୌଶଳଗୁଡ଼ିକ ଶିଖାଇବାକୁ ଯାଆନ୍ତି | ରିୟଲିଟି ସୋ’ରେ ବିଫଳ ହେବା ପରେ ସେମାନେ ସମସ୍ତେ ଅଧିକ ଅଭ୍ୟାସ କରୁଛନ୍ତି |",
            "as": "সপ্তাহান্তত সি এই কৌশলবোৰ তাৰ গোটৰ বাকী সকলক শিকাবলৈ যায়। ৰিয়েলিটি শ্ব'ত বিফল হোৱাৰ পিছত তেওঁলোক সকলোৱে কঠোৰ অনুশীলন কৰি আহিছে।",
            "gu": "અઠવાડીયાના અંતે તે તેના બાકીના જૂથને આ રીતો શીખવવા જાય છે. રિયાલિટી શોમાં નિષ્ફળ ગયા પછી તેઓ બધા સખત પ્રેક્ટિસ કરી રહ્યા છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_1_4//",
            "hi": "D7_1_4//",
            "ka": "D7_1_4//",
            "te": "D7_1_4//",
            "or": "D7_1_4//",
            "as": "D7_1_4//",
            "gu": "D7_1_4//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "One day at the gym.",
            "hi": "एक दिन जिम में",
            "ka": "ಒಂದು ದಿನ ಜಿಮ್‌ನಲ್ಲಿ.",
            "te": "ఒక రోజు జిమ్‌లో.",
            "or": "ଗୋଟିଏ ଦିନ ଜିମ୍‌ରେ",
            "as": "এদিন জিমত।",
            "gu": "એક દિવસ જીમ પર."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Gym Manager",
            "hi": "जिम मैनेजर",
            "ka": "ಜಿಮ್ ಮ್ಯಾನೇಜರ್",
            "te": "జిమ్ మేనేజర్",
            "or": "ଜିମ୍ ମ୍ୟାନେଜର୍",
            "as": "জিম মেনেজাৰ",
            "gu": "જીમનો મેનેજર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Gym Manager",
            "hi": "जिम मैनेजर",
            "ka": "ಜಿಮ್ ಮ್ಯಾನೇಜರ್",
            "te": "జిమ్ మేనేజర్",
            "or": "ଜିମ୍ ମ୍ୟାନେଜର୍",
            "as": "জিম মেনেজাৰ",
            "gu": "જીમનો મેનેજર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.4.label",
          "value": {
            "en": "Gym Manager",
            "hi": "जिम मैनेजर",
            "ka": "ಜಿಮ್ ಮ್ಯಾನೇಜರ್",
            "te": "జిమ్ మేనేజర్",
            "or": "ଜିମ୍ ମ୍ୟାନେଜର୍",
            "as": "জিম মেনেজাৰ",
            "gu": "જીમનો મેનેજર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Hey Naveen! Shashee is on leave today. Do you think you can train Mr Lewis today?",
            "hi": "अरे नवीन! आज शशि काम पर नहीं आया है। क्या आज तुम उसकी जगह मिस्टर लुई को ट्रेन कर सकते हो?",
            "ka": "ಹೇ ನವೀನ್! ಶಶಿ ಇಂದು ರಜೆಯಲ್ಲಿದ್ದಾನೆ. ಇಂದು ನೀನು ಶ್ರೀ ಲೆವಿಸ್‌ಗೆ ತರಬೇತಿ ನೀಡಬಹುದೆಂದು ನೀನು ಭಾವಿಸುತ್ತೀಯ?",
            "te": "హే నవీన్! శశి ఈరోజు సెలవులో ఉన్నాడు. మీరు ఈరోజు మిస్టర్ లూయిస్‌కు శిక్షణ ఇవ్వగలరని అనుకుంటున్నారా?",
            "or": "ହେ ନବୀନ ! ଶଶି ଆଜି ଛୁଟିରେ ଅଛନ୍ତି। ତୁମେ ଭାବୁଛ କି ତୁମେ ଆଜି ଶ୍ରୀ ଲୁଇସ୍ଙ୍କୁ ତାଲିମ ଦେଇ ପାରିବ ?",
            "as": "হেই নবীন! শাচী আজি ছুটিত আছে। তুমি আজি মিঃ লুইছক প্ৰশিক্ষণ দিব পাৰিবানে?",
            "gu": "કેમ છે નવીન! શશિ એક દિવસની રજા ઉયર છે. તને લાગે છે કે તું શ્રીમાન લેવિસને તાલીમ આપી શકીશ?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Sure! Would love to! Possibly I can try a new style too? Something inspired by my dance training.",
            "hi": "श्योर! मुझे खुशी होगी! हो सकता है कि, मैं कुछ नया स्टाइल भी ट्राई करू? ऐसा कुछ जो मेरे डांस ट्रेनिंग से प्रभावित है।",
            "ka": "ಖಂಡಿತ! ಇಷ್ಟಪಡುತ್ತೇನೆ! ಬಹುಶಃ ನಾನು ಹೊಸ ಶೈಲಿಯನ್ನು ಸಹ ಪ್ರಯತ್ನಿಸಬಹುದೇ? ನನ್ನ ನೃತ್ಯ ತರಬೇತಿಯಿಂದ ಪ್ರೇರಿತವಾದದ್ದೇನೋ.",
            "te": "తప్పకుండా! ఇష్టపడతారు! బహుశా నేను కూడా కొత్త స్టైల్ ని ప్రయత్నించవచ్చా? ఏదో నా డ్యాన్స్ ట్రైనింగ్ స్ఫూర్తి.",
            "or": "ନିଶ୍ଚିତ ! ଭଲ ପାଆନ୍ତି ! ସମ୍ଭବତ ମୁଁ ଏକ ନୂତନ ଶୈଳୀ ଚେଷ୍ଟା ମଧ୍ୟ କରିପାରିବି ? କିଛି ମୋର ନୃତ୍ୟ ତାଲିମ ଠାରୁ ଅନୁପ୍ରାଣିତ |",
            "as": "নিশ্চয়! ভাল লাগিব! মই এটা নতুন ষ্টাইলো চেষ্টা কৰিব পাৰো? মোৰ নৃত্য প্ৰশিক্ষণৰ দ্বাৰা অনুপ্ৰাণিত কিবা এটা।",
            "gu": "ચોક્કસ! મને આ કરવું ગમશે! કદાચ હું પણ નવી રીત અજમાવી શકું? મારી નૃત્ય તાલીમથી પ્રેરિત થઈ શકે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "Why don’t you ask him? Mr Lewis seems open to new styles.",
            "hi": "तुम उनसे क्यों नहीं पूछते? मिस्टर लुई नई स्टाइल ट्राय कर सकते हैं।",
            "ka": "ನೀನು ಅವನನ್ನು ಏಕೆ ಕೇಳಬಾರದು? ಶ್ರೀ ಲೆವಿಸ್ ಹೊಸ ಶೈಲಿಗಳಿಗೆ ತೆರೆದಿರುವಂತೆ ತೋರುತ್ತಿದೆ.",
            "te": "మీరు అతన్ని ఎందుకు అడగకూడదు? మిస్టర్ లూయిస్ కొత్త స్టైల్ చేయడానికి ఇష్టపడతారు.",
            "or": "ତୁମେ ତାଙ୍କୁ କାହିଁକି ପଚାରୁ ନାହଁ ? ଶ୍ରୀ ଲୁଇସ୍ ନୂତନ ଶୈଳୀ ପାଇଁ ଖୋଲା ଥିବା ପରି ମନେହୁଏ |",
            "as": "তুমি তাক নোসোধা কিয়? মিঃ লুইছে নতুন ষ্টাইলৰ বাবে আগ্ৰহী যেন লাগিছে।",
            "gu": "તું તેને કેમ પૂછતો નથી? શ્રીમાન લેવિસ નવી રીતો માટે તૈયાર લાગે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.text",
          "value": {
            "en": "Oh, that would be great. I will go talk to him.",
            "hi": "ओह, यह तो बहुत अच्छी बात है। मैं उनसे जाकर बात करता हूँ।",
            "ka": "ಓಹ್, ಅದು ಉತ್ತಮವಾಗಿರುತ್ತದೆ. ನಾನು ಅವರೊಂದಿಗೆ ಮಾತನಾಡಲು ಹೋಗುತ್ತೇನೆ.",
            "te": "ఓహ్, అలా అయితే బాగుంటుంది. నేను వెళ్లి అతనితో మాట్లాడతాను.",
            "or": "ଓହ, ଏହା ବହୁତ ଭଲ ହେବ | ମୁଁ ତାଙ୍କ ସହ କଥା ହେବାକୁ ଯିବି |",
            "as": "অহ, সেইটো ভাল হ'ব। মই তেওঁৰ সৈতে কথা পাতিম।",
            "gu": "ઓહ, તે સરસ થસે. હું તેની સાથે વાત કરવા માગીશ."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.4.text",
          "value": {
            "en": "But keep it simple and keep our reputation intact. We don’t want complaints of our assistants giving difficult exercises to our clients.",
            "hi": "लेकिन कुछ आसान ट्राई करना और अपनी रेप्यूटेशन का ध्यान रखना। हम नहीं चाहते कि ग्राहक शिकायत करें कि हमारे असिस्टेंट उनसे कठिन एक्सरसाइज करवा रहे हैं।",
            "ka": "ಆದರೆ ಅದನ್ನು ಸರಳವಾಗಿ ಇರಿಸಿ ಮತ್ತು ನಮ್ಮ ಖ್ಯಾತಿಯನ್ನು ಉಳಿಸಿ. ನಮ್ಮ ಸಹಾಯಕರು ನಮ್ಮ ಕ್ಲೈಂಟ್‌ಗಳಿಗೆ ಕಷ್ಟಕರವಾದ ವ್ಯಾಯಾಮಗಳನ್ನು ನೀಡುವ ದೂರುಗಳನ್ನು ನಾವು ಬಯಸುವುದಿಲ್ಲ",
            "te": "కానీ దానిని సరళంగా ఉంచండి మరియు మా కీర్తిని చెక్కుచెదరకుండా ఉంచండి. మా క్లయింట్‌లకు మా సహాయకులు కష్టమైన వ్యాయామాలు చేస్తున్నారనే ఫిర్యాదులు మనకు రావద్దు.",
            "or": "କିନ୍ତୁ ଏହାକୁ ସରଳ ରଖନ୍ତୁ ଏବଂ ଆମର ପ୍ରତିଷ୍ଠାକୁ ଅକ୍ଷୁର୍ଣ୍ଣ ରଖନ୍ତୁ | ଆମର କ୍ଲାଏଣ୍ଟମାନଙ୍କୁ କଷ୍ଟଦାୟକ ବ୍ୟାୟାମ ଦେଉଥିବା ଆମର ସହାୟକଙ୍କ ଅଭିଯୋଗ ଆମେ ଚାହୁଁନାହୁଁ |",
            "as": "কিন্তু ইয়াক সহজকে ৰাখিবা আৰু আমাৰ খ্যাতি বজাই ৰাখিবা। আমি আমাৰ সহায়কসকলে আমাৰ গ্ৰাহকসকলক কঠিন ব্যায়াম দিয়াৰ অভিযোগ নিবিচাৰোঁ।",
            "gu": "પરંતુ તેને સરળ રાખજે અને આપણી પ્રતિષ્ઠા અકબંધ રાખજે. આપણે આપણા સહાયકોની ફરિયાદો આપણા ગ્રાહકોને કઠિન કસરતો આપવા માંગતા નથી."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_2_1",
            "hi": "D7_2_1",
            "ka": "D7_2_1",
            "te": "D7_2_1",
            "or": "D7_2_1",
            "as": "D7_2_1",
            "gu": "D7_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "That day Mr Lewis was pleased with Naveen’s inputs in his fitness routine. This leads Naveen to believe that he can become a fitness trainer and earn more at the gym.",
            "hi": "उस दिन मिस्टर लुई नवीन के द्वारा दिए हुए फिटनेस रूटीन से बहुत खुश थे। इस वजह से नवीन को यह विश्वास हुआ कि वह फिटनेस ट्रेनर बन सकता है और जिम में ज्यादा पैसे कमा सकता है।",
            "ka": "ಆ ದಿನ ಶ್ರೀ ಲೆವಿಸ್ ತಮ್ಮ ಫಿಟ್‌ನೆಸ್ ದಿನಚರಿಯಲ್ಲಿ ನವೀನ್ ಒಳಹರಿವುಗಳಿಂದ ಸಂತೋಷಪಟ್ಟರು. ಇದು ನವೀನ್‌ಗೆ ಫಿಟ್‌ನೆಸ್ ತರಬೇತುದಾರನಾಗಬಹುದು ಮತ್ತು ಜಿಮ್‌ನಲ್ಲಿ ಹೆಚ್ಚು ಗಳಿಸಬಹುದು ಎಂಬ ನಂಬಿಕೆಗೆ ಕಾರಣವಾಗುತ್ತದೆ.",
            "te": "ఆ రోజు మిస్టర్ లూయిస్ తన ఫిట్‌నెస్ రొటీన్‌లో నవీన్ ఇన్‌పుట్‌లతో సంతోషించాడు. దీంతో నవీన్ ఫిట్‌నెస్ ట్రైనర్‌గా మారి జిమ్‌లో ఎక్కువ సంపాదించగలనని నమ్ముతున్నాడు.",
            "or": "ସେଦିନ ଶ୍ରୀ ଲୁଇସ୍ ତାଙ୍କ ଫିଟନେସ୍ ରୁଟିନ୍‌ରେ ନବୀନଙ୍କ ଇନପୁ୍ଟ୍‌ରେ ସନ୍ତୁଷ୍ଟ ହୋଇଥିଲେ | ଏହା ନବୀନଙ୍କୁ ବିଶ୍ୱାସ କରାଏ ଯେ ସେ ଜଣେ ଫିଟନେସ୍ ଟ୍ରେନର୍‌ ହୋଇ ଜି‍ମ୍‌ରେ ଅଧିକ ରୋଜଗାର କରିପାରିବେ |",
            "as": "সেইদিনা মিঃ লুইছে নবীনৰ ফিটনেছ ৰুটিনত থকা ইনপুটবোৰত সন্তুষ্ট হৈছিল। এইটোৱে নবীনক বিশ্বাস জন্মালে যে সি এজন ফিটনেছ প্ৰশিক্ষক হ'ব পাৰিব আৰু জিমত অধিক উপাৰ্জন কৰিব পাৰিব।",
            "gu": "તે દિવસે શ્રીમાન લુઈસ તેમની ફિટનેસ નિત્યક્રમમાં નવીનના ઇનપુટ્સથી ખુશ હતા. આનાથી નવીન માને છે કે તે ફિટનેસ ટ્રેનર બની શકે છે અને જીમમાં વધુ કમાણી કરી શકે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_2_2",
            "hi": "D7_2_2",
            "ka": "D7_2_2",
            "te": "D7_2_2",
            "or": "D7_2_2",
            "as": "D7_2_2",
            "gu": "D7_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "However, the gym manager tells him that he needs to be certified to become a trainer.",
            "hi": "लेकिन, जिम मैनेजर ने उसे बताया की ट्रेनर बनने के लिए उसे प्रमाणपत्र की जरूरत पड़ेगी।",
            "ka": "ಆದಾಗ್ಯೂ, ಜಿಮ್ ಮ್ಯಾನೇಜರ್ ಅವನು ತರಬೇತುದಾರನಾಗಲು ಪ್ರಮಾಣೀಕರಿಸಬೇಕು ಎಂದು ಹೇಳುತ್ತಾರೆ.",
            "te": "కానీ, ట్రైనర్ కావాలంటే అతనికి సర్టిఫికేట్ కావాలని జిమ్ మేనేజర్ చెప్పాడు.",
            "or": "ଅଥଚ, ଜିମ୍‌ ମ୍ୟାନେଜ‍ର୍‌ ତାଙ୍କୁ କୁହନ୍ତି ଯେ ଜଣେ ଟ୍ରେନର୍‌ ହେବା ପାଇଁ ତାଙ୍କୁ ସାର୍ଟିଫାଇଡ୍‌ ହେବା ଆବଶ୍ୟକ |",
            "as": "অৱশ্যে, জিম মেনেজাৰে তাক ক’লে যে প্ৰশিক্ষক হ'বলৈ তাক প্ৰমাণপত্ৰ লাগিব।",
            "gu": "જો કે, જિમ મેનેજર તેને જણાવે છે કે તેણે ટ્રેનર બનવા માટે પ્રમાણિત હોવું જરૂરી છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_2_3",
            "hi": "D7_2_3",
            "ka": "D7_2_3",
            "te": "D7_2_3",
            "or": "D7_2_3",
            "as": "D7_2_3",
            "gu": "D7_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen gets discouraged, but this also tells him that he needs to look for better opportunities that suit his talent.",
            "hi": "नवीन निराश हो जाता है, लेकिन इससे उसे यह पता चल जाता है कि उसे ऐसे अवसर ढूंढने होंगे जो उसके कौशल के अनुरूप हो।",
            "ka": "ನವೀನ್ ನಿರುತ್ಸಾಹಗೊಳ್ಳುತ್ತಾನೆ, ಆದರೆ ಇದು ಅವನ ಪ್ರತಿಭೆಗೆ ಸರಿಹೊಂದುವ ಉತ್ತಮ ಅವಕಾಶಗಳನ್ನು ಹುಡುಕಬೇಕಾಗಿದೆ ಎಂದು ಹೇಳುತ್ತದೆ.",
            "te": "నవీన్ నిరుత్సాహానికి గురవుతాడు, అయితే ఇది అతని ప్రతిభకు తగిన మంచి అవకాశాల కోసం వెతకాలని కూడా అతనికి చెబుతుంది.",
            "or": "ନବୀନ ହତାଶ ହୁଅନ୍ତି, କିନ୍ତୁ ଏହା ମଧ୍ୟ ତାଙ୍କୁ କହିଥାଏ ଯେ ସେ ତାଙ୍କ ପ୍ରତିଭାକୁ ଅନୁକୂଳ ସୁଯୋଗଗୁଡିକ ଖୋଜିବା ଆବଶ୍ୟକ କରନ୍ତି |",
            "as": "নবীন নিৰুৎসাহিত হ’ল, কিন্তু ইয়াৰপৰা সি বুজিলে যে সি তাৰ প্ৰতিভাৰ সৈতে খাপ খোৱা উন্নত সুযোগ বিচাৰিব লাগিব।",
            "gu": "નવીન ઉદાસ થઈ જાય છે, પરંતુ આ તેને એમ પણ કહે છે કે તેણે તેની પ્રતિભાને અનુરૂપ વધુ સારી તકો શોધવાની જરૂર છે."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_3_1_1",
            "hi": "D7_3_1_1",
            "ka": "D7_3_1_1",
            "te": "D7_3_1_1",
            "or": "D7_3_1_1",
            "as": "D7_3_1_1",
            "gu": "D7_3_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Then one day at work, Naveen gets two text messages on his phone. One is from Omi and one from Mr Lewis.",
            "hi": "फिर एक दिन, काम करते समय नवीन को फोन पर 2 मैसेज आते हैं। एक उसके दोस्त ओमी का था और दूसरा मिस्टर लूई का।",
            "ka": "ನಂತರ ಒಂದು ದಿನ ಕೆಲಸದ ಸ್ಥಳದಲ್ಲಿ ನವೀನ್ ಫೋನ್‌ಗೆ ಎರಡು ಸಂದೇಶಗಳು ಬರುತ್ತವೆ. ಒಬ್ಬರು ಓಮಿಯವರು ಮತ್ತು ಒಬ್ಬರು ಶ್ರೀ ಲೆವಿಸ್ ಅವರಿಂದ.",
            "te": "ఆ తర్వాత ఒకరోజు పనిలో ఉన్న నవీన్ ఫోన్‌కి రెండు మెసేజ్‌లు వచ్చాయి. ఒకటి ఓమి నుండి మరియు మరొకటి మిస్టర్ లూయిస్ నుండి.",
            "or": "ତା’ପରେ ଦିନେ କାମରେ, ନବୀନ ତାଙ୍କ ଫୋନରେ ଦୁଇଟି ଟେକ୍ସଟ୍ ମେସେଜ୍ ପାଆନ୍ତି | ଗୋଟିଏ ଓମିଙ୍କ ଠାରୁ ଏବଂ ଅନ୍ୟଟି ଶ୍ରୀ ଲୁଇସ୍‌ଙ୍କ ଠାରୁ |",
            "as": "তাৰ পিছত এদিন কামত নবীনে তাৰ ফোনত দুটা টেক্সট মেচেজ পায়। এটা অমিৰ আৰু এটা মিঃ লুইছৰ।",
            "gu": "પછી એક દિવસ કામ પર, નવીનને તેના ફોન પર બે ટેક્સ્ટ સંદેશા મળે છે. એક ઓમી તરફથી અને એક શ્રીમાન લેવિસ તરફથી છે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_3_1_2",
            "hi": "D7_3_1_2",
            "ka": "D7_3_1_2",
            "te": "D7_3_1_2",
            "or": "D7_3_1_2",
            "as": "D7_3_1_2",
            "gu": "D7_3_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.0.messageText",
          "value": {
            "en": "Yo Naveen! Guess what! The group got a gig! A local event manager was really impressed by our videos, and she wants us to be the supporting act in some college fests they’re organising next month! The pay is not huge, but this will be our first contract. What do you think?",
            "hi": "हाय नवीन! एक खुश खबर है! हमारी ग्रुप को एक अच्छा काम मिला है! एक लोकल इवेंट मैनेजर हमारे वीडियोज को देख कर बहुत इंप्रेस हुई है, और वह चाहती है की, हम अगले महीने उनके द्वारा आयोजित एक कॉलेज फेस्ट में सपोर्टिंग एक्ट करें! पैसे तो ज्यादा नहीं मिलेंगे, लेकिन यह हमारा फर्स्ट कॉन्ट्रैक्ट है। क्या ख्याल है?",
            "ka": "ಯೋ ನವೀನ್! ಊಹಿಸು ನೋಡೋಣ! ಗುಂಪಿಗೆ ಗಿಗ್ ಸಿಕ್ಕಿತು! ನಮ್ಮ ವೀಡಿಯೊಗಳಿಂದ ಸ್ಥಳೀಯ ಈವೆಂಟ್ ಮ್ಯಾನೇಜರ್ ನಿಜವಾಗಿಯೂ ಪ್ರಭಾವಿತರಾಗಿದ್ದಾರೆ ಮತ್ತು ಮುಂದಿನ ತಿಂಗಳು ಅವರು ಆಯೋಜಿಸುವ ಕೆಲವು ಕಾಲೇಜು ಫೆಸ್ಟ್‌ಗಳಲ್ಲಿ ನಾವು ಪೋಷಕ ಕ್ರಿಯೆಯಾಗಬೇಕೆಂದು ಅವರು ಬಯಸುತ್ತಾರೆ! ವೇತನವು ದೊಡ್ಡದಲ್ಲ, ಆದರೆ ಇದು ನಮ್ಮ ಮೊದಲ ಒಪ್ಪಂದವಾಗಿದೆ. ನೀನು ಏನು ಯೋಚಿಸುತ್ತೀಯ?",
            "te": "యో నవీన్! ఊహించండి! సమూహానికి ఒక ప్రదర్శన వచ్చింది! స్థానిక ఈవెంట్ మేనేజర్ మా వీడియోలను చూసి నిజంగా ఆకట్టుకున్నారు మరియు వచ్చే నెలలో వారు నిర్వహించే కొన్ని కళాశాల ఫెస్ట్‌లలో మేము సపోర్టింగ్ యాక్ట్‌గా ఉండాలని ఆమె కోరుకుంటుంది! జీతం పెద్దది కాదు, కానీ ఇది మా మొదటి ఒప్పందం. మీరు ఏమనుకుంటున్నారు?",
            "or": "ୟୋ ନବୀନ! ଅନୁମାନ ଲଗାଅ ! ଗ୍ରୁପ୍‌ ଏକ ଗିଗ୍ ପାଇଲା ! ଏକ ସ୍ଥାନୀୟ ଇଭେଣ୍ଟ ମ୍ୟାନେଜର ପ୍ରକୃତରେ ଆମର ଭିଡିଓ ଦ୍ୱାରା ପ୍ରଭାବିତ ହୋଇଥିଲେ ଏବଂ ସେ ଚାହାଁନ୍ତି ଯେ ସେମାନେ ଆସନ୍ତା ମାସରେ ଆୟୋଜନ କରୁଥିବା କିଛି କଲେଜ ଫେଷ୍ଟରେ ଆମେ ସହାୟକ କାର୍ଯ୍ୟ ହେଉ ! ଦରମା ଅଧିକ ନୁହେଁ, କିନ୍ତୁ ଏହା ଆମର ପ୍ରଥମ ଚୁକ୍ତି ହେବ | ତୁମେ କ 'ଣ ଭାବୁଛ ?",
            "as": "ইঅ’ নবীন! অনুমান কৰা! গোটটোৱে এটা কাম পালে! এজন স্থানীয় ইভেণ্ট মেনেজাৰ আমাৰ ভিডিঅ'বোৰৰ দ্বাৰা সঁচাকৈয়ে প্ৰভাৱিত হ’ল, আৰু তেওঁ বিচাৰে যে তেওঁলোকে অহা মাহত আয়োজন কৰা কিছুমান মহাবিদ্যালয় উৎসৱত আমি সহায়ক হিচাপে কাম কৰোঁ! দৰমহা বৃহৎ নহয়, কিন্তু এইটো আমাৰ প্ৰথম চুক্তি হ'ব। তুমি কি ভাবা?",
            "gu": "હા નવીન! ધારો તો! જૂથને એક રમત મળી! એક સ્થાનિક ઇવેન્ટ મેનેજર અમારા વીડિયોથી ખરેખર પ્રભાવિત થયા હતા, અને તે ઇચ્છે છે કે તેઓ આવતા મહિને યોજાનાર કેટલાક કૉલેજ ઉત્સવમાં અમે સહાયક બનીએ! પગાર બહુ મોટો નથી, પરંતુ આ અમારો પ્રથમ કરાર હશે. તમે શું વિચારો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.messageSentTo",
          "value": {
            "en": "Omi",
            "hi": "ओमी",
            "ka": "ಓಮಿ",
            "te": "ఓమి",
            "or": "ଓମି",
            "as": "অমি",
            "gu": "ઓમી"
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_3_1_3",
            "hi": "D7_3_1_3",
            "ka": "D7_3_1_3",
            "te": "D7_3_1_3",
            "or": "D7_3_1_3",
            "as": "D7_3_1_3",
            "gu": "D7_3_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.0.messageText",
          "value": {
            "en": "Hi Naveen! So one of my friends who runs a dance studio is looking for a Jr. instructor. I was wondering if you’d be interested? ! I can write a recommendation for you. You’ll have to give an interview, but the role will be better than what you have now.  Think about it. If you’re interested, I’ll share more details...",
            "hi": "हाय नवीन! मेरा एक दोस्त डांस स्टूडियो चलाता है और वह जूनियर इंस्ट्रक्टर के तलाश में है। मैं सोच रहा था कि क्या तुम यह जॉब कर सकते हो? मैं तुम्हारे लिए रिकमेंडेशन लिखकर दे सकता हूँ। तुम्हें इंटरव्यू देना होगा लेकिन यह जॉब तुम्हारे अभी के जॉब से बेहतर होगा। इसके बारे में सोच कर बताना। अगर तुम दिलचस्प हो तो मैं तुम्हें डिटेल्स दूंगा।",
            "ka": "ನಮಸ್ಕಾರ ನವೀನ್! ಹಾಗಾಗಿ ಡ್ಯಾನ್ಸ್ ಸ್ಟುಡಿಯೋ ನಡೆಸುತ್ತಿರುವ ನನ್ನ ಸ್ನೇಹಿತರಲ್ಲಿ ಒಬ್ಬರು ಜೂನಿಯರ್ ಬೋಧಕರನ್ನು ಹುಡುಕುತ್ತಿದ್ದಾರೆ. ನಿಮಗೆ ಆಸಕ್ತಿ ಇದೆಯೇ ಎಂದು ನಾನು ಆಶ್ಚರ್ಯ ಪಡುತ್ತಿದ್ದೆ? ! ನಾನು ನಿಮಗಾಗಿ ಶಿಫಾರಸು ಬರೆಯಬಲ್ಲೆ. ನೀವು ಸಂದರ್ಶನವನ್ನು ನೀಡಬೇಕಾಗುತ್ತದೆ, ಆದರೆ ಪಾತ್ರವು ಈಗ ನೀವು ಹೊಂದಿರುವದಕ್ಕಿಂತ ಉತ್ತಮವಾಗಿರುತ್ತದೆ. ಅದರ ಬಗ್ಗೆ ಯೋಚಿಸು. ನಿಮಗೆ ಆಸಕ್ತಿ ಇದ್ದರೆ, ನಾನು ಹೆಚ್ಚಿನ ವಿವರಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತೇನೆ...",
            "te": "హాయ్ నవీన్! డ్యాన్స్ స్టూడియో నడుపుతున్న నా స్నేహితుల్లో ఒకరు జూనియర్ శిక్షకుడి కోసం వెతుకుతున్నారు. మీకు ఆసక్తి ఉందా అని నేను ఆశ్చర్యపోతున్నాను? ! నేను మీ కోసం ఒక సిఫార్సు వ్రాయగలను. మీరు ఇంటర్వ్యూ ఇవ్వవలసి ఉంటుంది, కానీ మీరు ఇప్పుడు కలిగి ఉన్న పాత్ర కంటే మెరుగ్గా ఉంటుంది. దాని గురించి ఆలోచించు. మీకు ఆసక్తి ఉంటే, నేను మరిన్ని వివరాలను పంచుకుంటాను...",
            "or": "ନମସ୍କାର ନବୀନ! ତେଣୁ ମୋର ଜଣେ ବନ୍ଧୁ ଯିଏ ଏକ ଡ୍ୟାନ୍ସ ଷ୍ଟୁଡିଓ ଚଳାଉଛନ୍ତି, ଏକ ଜୁନିୟର୍‌ ପ୍ରଶିକ୍ଷକ ଖୋଜୁଛନ୍ତି | ମୁଁ ଭାବୁଥିଲି ତୁମେ ଆଗ୍ରହୀ କି ? ମୁଁ ତୁମ ପାଇଁ ଏକ ସୁପାରିଶ ଲେଖିପାରିବି | ତୁମକୁ ଏକ ସାକ୍ଷାତକାର ଦେବାକୁ ପଡିବ, କିନ୍ତୁ ତୁମେ ଯାହା ଅଛ, ତା’ଠାରୁ ଭୂମିକା ଭଲ ହେବ | ଏହା ବିଷୟରେ ଭାବ। ଯଦି ତୁମେ ଆଗ୍ରହୀ, ମୁଁ ଅଧିକ ବିବରଣୀ ଅଂଶୀଦାର କରିବି ...",
            "as": "হাই নবীন! মোৰ এজন বন্ধু যিয়ে এটা নৃত্য ষ্টুডিঅ চলায় তেওঁ এজন জুনিয়ৰ প্ৰশিক্ষক বিচাৰি আছে। মই ভাবি আছিলো তুমি আগ্ৰহী হ'বানে? ! মই তোমাৰ বাবে এটা পৰামৰ্শ লিখি দিব পাৰিম। তুমি এটা সাক্ষাৎকাৰ দিব লাগিব, কিন্তু ভূমিকাটো এতিয়াৰ তুলনাত ভাল হ'ব। ইয়াৰ বিষয়ে ভাবিবাছোন। যদি তুমি আগ্ৰহী, মই অধিক বিৱৰণ জনাম...",
            "gu": "કેમ નવીન! તો મારા એક મિત્ર જે ડાન્સ સ્ટુડિયો ચલાવે છે તેને જુનિયર પ્રશિક્ષક\nની શોધે છે. હું આશ્ચર્ય પામી રહ્યો હતો કે શું તને રસ હશે? ! હું તમારા માટે ભલામણ લખી શકું છું. તમારે એક ઇન્ટરવ્યુ આપવું પડશે, પરંતુ ભૂમિકા તારી પાસે જે છે તેના કરતાં વધુ સારી હશે. એના વિશે વિચારો. જો તને રસ હોય, તો હું વધુ વિગતો બતાવીશ..."
          }
        },
        {
          "valuePath": "blocks.0.props.messageSentTo",
          "value": {
            "en": "Mr. Lewis",
            "hi": "मि. लुई",
            "ka": "ಶ್ರೀ.ಲೆವಿಸ್",
            "te": "మిస్టర్ లూయిస్",
            "or": "ଶ୍ରୀ ଲୁଇସ୍",
            "as": "মিঃ লুইছ",
            "gu": "શ્રી લેવિસ"
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_3_2 (D)",
            "hi": "D7_3_2 (D)",
            "ka": "D7_3_2 (D)",
            "te": "D7_3_2 (D)",
            "or": "D7_3_2 (D)",
            "as": "D7_3_2 (D)",
            "gu": "D7_3_2 (D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Naveen is confused. His hard work in the past few months is finally paying off. But he is finding it hard to decide. What should he do?",
            "hi": "नवीन कंफ्यूज है। उसके पिछले कई महीनों के मेहनत का फल उससे मिलने वाला है। लेकिन उसे समझ नहीं आ रहा कि उसे क्या करना चाहिए?",
            "ka": "ನವೀನ್ ಗೊಂದಲದಲ್ಲಿದ್ದಾನೆ. ಕಳೆದ ಕೆಲವು ತಿಂಗಳುಗಳ ಅವನ ಶ್ರಮ ಕೊನೆಗೂ ಫಲ ನೀಡಿದೆ. ಆದರೆ ಅವನು ನಿರ್ಧರಿಸಲು ಕಷ್ಟಪಡುತ್ತಾನೆ. ಅವನು ಏನು ಮಾಡಬೇಕು?",
            "te": "నవీన్ అయోమయంలో పడ్డాడు. గత కొన్ని నెలలుగా ఆయన కష్టానికి ఫలితం దక్కింది. కానీ అతను నిర్ణయించుకోవడం కష్టంగా ఉంది. అతను ఏమి చేయాలి?",
            "or": "ନବୀନ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛନ୍ତି | ଗତ କିଛି ମାସ ମଧ୍ୟରେ ତାଙ୍କର କଠିନ ପରିଶ୍ରମ ଶେଷରେ ଫଳ ଦେଉଛି | କିନ୍ତୁ ନିଷ୍ପତ୍ତି ନେବା ତାଙ୍କୁ କଷ୍ଟକର ହେଉଛି | ସେ କ’ଣ କରିବା ଉଚିତ୍ ?",
            "as": "নবীন বিমোৰত পৰে। যোৱা কেইমাহমানৰ তাৰ কঠোৰ পৰিশ্ৰমৰ অৱশেষত ফল পাইছে। কিন্তু সি সিদ্ধান্ত ল'বলৈ টান পাইছে। সি কি কৰা উচিত?",
            "gu": "નવીન મૂંઝવણમાં છે. છેલ્લા કેટલાક મહિનાથી તેની મહેનત આખરે રંગ લાવી રહી છે. પરંતુ તેને નિર્ણય લેવામાં મુશ્કેલી પડી રહી છે. તેણે શું કરવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Say yes to Omi",
            "hi": "ओमी को हाँ कहना चाहिए",
            "ka": "ಓಮಿಗೆ ಹೌದು ಎಂದು ಹೇಳಿ",
            "te": "ఓమికి అవును అని చెప్పడం",
            "or": "ଓମି କୁ ହଁ କୁହନ୍ତୁ",
            "as": "অমিক হ’ব বুলি ক’ব",
            "gu": "ઓમીને હા કહો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Say yes to Mr Lewis",
            "hi": "मि. लुई को हाँ कहना चाहिए",
            "ka": "ಶ್ರೀ ಲೆವಿಸ್‌ಗೆ ಹೌದು ಎಂದು ಹೇಳಿ",
            "te": "మిస్టర్ లూయిస్‌కి అవును అని చెప్పడం",
            "or": "ଶ୍ରୀ ଲୁଇସ୍ କୁ ହଁ କୁହନ୍ତୁ",
            "as": "মিঃ লুইছক হ’ব বুলি ক’ব",
            "gu": "શ્રી લેવિસને હા કહો"
          }
        }
      ]
    },
    "scene32": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D8_1_1_Join Judge Vishnu",
            "hi": "जज विष्णु हे पास काम करना",
            "ka": "D8_1_1_ಜಡ್ಜ್ ವಿಷ್ಣು ಸೇರಿ",
            "te": "D8_1_1_జడ్జి విష్ణుతో చేరడం",
            "or": "D8_1_1_Join Judge Vishnu",
            "as": "D8_1_1_বিচাৰক বিষ্ণুৰ তাত যোগদান",
            "gu": "D8_1_1_નિરીક્ષક વિષ્ણુ સાથે જોડાઓ"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen joins the dance studio, owned by Judge Vishnu, as a trainee.",
            "hi": "नवीन जज विष्णु द्वारा चलाए जाने वाले डांस स्टूडियो में ट्रेनी के तौर पर काम करना शुरू करता है।",
            "ka": "ನ್ಯಾಯಾಧೀಶ ವಿಷ್ಣು ಒಡೆತನದ ಡ್ಯಾನ್ಸ್ ಸ್ಟುಡಿಯೋಗೆ ನವೀನ್ ಟ್ರೈನಿಯಾಗಿ ಸೇರುತ್ತಾನೆ.",
            "te": "నవీన్ జడ్జి విష్ణుకు చెందిన డ్యాన్స్ స్టూడియోలో ట్రైనీగా చేరాడు.",
            "or": "ଜଜ୍ ବିଷ୍ଣୁଙ୍କ ମାଲିକାନାରେ ଥିବା ନୃତ୍ୟ ଷ୍ଟୁଡିଓରେ ନବୀନ ଏକ ଟ୍ରେଣି ଭାବରେ ଯୋଗ ଦେଇଛନ୍ତି।",
            "as": "নবীনে প্ৰশিক্ষাৰ্থী হিচাপে বিচাৰক বিষ্ণুৰ মালিকানাধীন ডান্স ষ্টুডিঅ'ত যোগদান কৰে।",
            "gu": "નવીન એક તાલીમાર્થી તરીકે નિરીક્ષક વિષ્ણુની માલિકીના ડાન્સ સ્ટુડિયોમાં જોડાય છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D8_1_2",
            "hi": "D8_1_2",
            "ka": "D8_1_2",
            "te": "D8_1_2",
            "or": "D8_1_2",
            "as": "D8_1_2",
            "gu": "D8_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Judge Vishnu doesn’t pay him much but makes sure that Naveen learns  proper techniques and gets time to practise in between sessions.",
            "hi": "जज विष्णु उससे ज्यादा सैलरी नहीं देते लेकिन नवीन सही तकनीक सीखें और सेशन के बीच में उसे प्रैक्टिस करने के लिए समय मिले इस बात का ध्यान रखते हैं।",
            "ka": "ನ್ಯಾಯಾಧೀಶ ವಿಷ್ಣು ಅವರಿಗೆ ಹೆಚ್ಚು ಹಣ ನೀಡುವುದಿಲ್ಲ ಆದರೆ ನವೀನ್ ಸರಿಯಾದ ತಂತ್ರಗಳನ್ನು ಕಲಿಯುತ್ತಾನೆ ಮತ್ತು ಸೆಷನ್‌ಗಳ ನಡುವೆ ಅಭ್ಯಾಸ ಮಾಡಲು ಸಮಯವನ್ನು ಪಡೆಯುತ್ತಾನೆ ಎಂದು ಖಚಿತಪಡಿಸುತ್ತಾರೆ.",
            "te": "జడ్జి విష్ణు అతనికి ఎక్కువ జీతం ఇవ్వడు కానీ నవీన్ సరైన మెళకువలు నేర్చుకునేలా మరియు సెషన్ల మధ్య సాధన చేయడానికి సమయాన్ని పొందేలా చూసుకున్నాడు.",
            "or": "ଜଜ୍ ବିଷ୍ଣୁ ତାଙ୍କୁ ଅଧିକ ଦେୟ ଦିଅନ୍ତି ନାହିଁ କିନ୍ତୁ ନିଶ୍ଚିତ କରନ୍ତି ଯେ ନବୀନ ଉପଯୁକ୍ତ କୌଶଳ ଶିଖନ୍ତି ଏବଂ ଅଧିବେଶନ ମଧ୍ୟରେ ଅଭ୍ୟାସ କରିବାକୁ ସମୟ ପାଆନ୍ତୁ |",
            "as": "বিচাৰক বিষ্ণুৱে তাক বেছি পৰিশোধ নকৰে কিন্তু এইটো নিশ্চিত কৰে যে নবীনে সঠিক কৌশল শিকে আৰু অধিৱেশনৰ মাজত অনুশীলন কৰিবলৈ সময় পায়।",
            "gu": "નિરીક્ષક વિષ્ણુ તેને વધુ ચૂકવણી કરતા નથી પરંતુ ખાતરી કરે છે કે નવીન યોગ્ય તકનીકો શીખે અને સત્રો વચ્ચે પ્રેક્ટિસ કરવા માટે સમય મળે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D8_2_1",
            "hi": "D8_2_1",
            "ka": "D8_2_1",
            "te": "D8_2_1",
            "or": "D8_2_1",
            "as": "D8_2_1",
            "gu": "D8_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "His group ensures that their social media accounts are active. He can’t practice with them as much but keeps teaching his friends some of the things he is learning.",
            "hi": "उसका ग्रुप सोशल मीडिया अकाउंट पर सक्रीय रहता है। वह उनके साथ ज्यादा प्रैक्टिस नहीं कर पाता लेकिन वह जो कुछ भी सीखता है उसमें से कुछ तकनीक अपने दोस्तों को भी सिखाता है।",
            "ka": "ಅವರ ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಖಾತೆಗಳು ಸಕ್ರಿಯವಾಗಿವೆ ಎಂದು ಅವನ ಗುಂಪು ಖಚಿತಪಡಿಸುತ್ತದೆ. ಅವನು ಅವರೊಂದಿಗೆ ಹೆಚ್ಚು ಅಭ್ಯಾಸ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ ಆದರೆ ಅವನು ಕಲಿಯುತ್ತಿರುವ ಕೆಲವು ವಿಷಯಗಳನ್ನು ತನ್ನ ಸ್ನೇಹಿತರಿಗೆ ಕಲಿಸುತ್ತಲೇ ಇರುತ್ತಾನೆ.",
            "te": "అతని బృందం వారి సోషల్ మీడియా ఖాతాలు యాక్టివ్‌గా ఉండేలా చూస్తుంది. అతను వారితో ఎక్కువగా ప్రాక్టీస్ చేయలేడు, కానీ అతను నేర్చుకుంటున్న కొన్ని విషయాలను తన స్నేహితులకు బోధిస్తూనే ఉంటాడు.",
            "or": "ତାଙ୍କ ଗ୍ରୁପ୍‍ ନିଶ୍ଚିତ କରେ ଯେ ସେମାନଙ୍କର ସୋସିଆଲ ମିଡିଆ ଆକାଉଣ୍ଟଗୁଡ଼ିକ ଆକ୍ଟିଭ୍‌ ଅଛି | ସେ ସେମାନଙ୍କ ସହିତ ସେତିକି ଅଭ୍ୟାସ କରିପାରି ନାହିଁ କିନ୍ତୁ ସେ ନିଜ ସାଙ୍ଗମାନଙ୍କୁ କିଛି ଜିନିଷ ଶିଖାନ୍ତି ଯାହା ସେ ଶିଖୁଛନ୍ତି |",
            "as": "তাৰ গোটে নিশ্চিত কৰে যে তেওঁলোকৰ সামাজিক মাধ্যমৰ একাউণ্টবোৰ সক্ৰিয় হৈ থাকে। সি তেওঁলোকৰ সৈতে সিমান অনুশীলন কৰিব নাপায় কিন্তু সি শিকি থকা কিছুমান বস্তু তাৰ বন্ধুবৰ্গক শিকাই থাকে।",
            "gu": "તેમનું જૂથ ખાતરી કરે છે કે તેમના સોશિયલ મીડિયા એકાઉન્ટમાં સક્રિય છે. તે તેમની સાથે વધુ પ્રેક્ટિસ કરી શકતો નથી પરંતુ તે જે શીખી રહ્યો છે તેમાંથી કેટલીક બાબતો તેના મિત્રોને શીખવતો રહે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D8_2_2",
            "hi": "D8_2_2",
            "ka": "D8_2_2",
            "te": "D8_2_2",
            "or": "D8_2_2",
            "as": "D8_2_2",
            "gu": "D8_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "On the side, to earn some extra money, Naveen works at a nearby apparel store that sells performance wear.",
            "hi": "साथ ही, थोड़े और पैसे कमाने के लिए, नवीन नजदीक के एक कपड़े की दुकान पर काम करता है, जहाँ परफॉर्मेंस के लिए इस्तेमाल होने वाले कपड़े बेचे जाते हैं।",
            "ka": "ಕಡೆಯಲ್ಲಿ, ಸ್ವಲ್ಪ ಹೆಚ್ಚುವರಿ ಹಣವನ್ನು ಗಳಿಸಲು, ನವೀನ್ ಹತ್ತಿರದ ಕಾರ್ಯಕ್ಷಮತೆಯ ಉಡುಗೆಗಳನ್ನು ಮಾರಾಟ ಮಾಡುವ ಬಟ್ಟೆ ಅಂಗಡಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಾನೆ.",
            "te": "మరో వైపు, కొంత అదనపు డబ్బు సంపాదించడానికి, నవీన్ సమీపంలోని పర్ఫామెన్స్ వేర్ అమ్మే దుస్తుల దుకాణంలో పని చేస్తున్నాడు.",
            "or": "ପାର୍ଶ୍ୱରେ, କିଛି ଅତିରିକ୍ତ ଟଙ୍କା ରୋଜଗାର କରିବାକୁ, ନବୀନ ନିକଟସ୍ଥ ଏକ ପୋଷାକ ଦୋକାନରେ କାମ କରନ୍ତି ଯାହା ପରିବେଷଣ ପୋଷାକ ବିକ୍ରି କରିଥାଏ |",
            "as": "আনহাতে, কিছু অতিৰিক্ত টকা উপাৰ্জন কৰিবলৈ, নবীনে ওচৰৰ পোছাকৰ দোকান এখনত কাম কৰে যিয়ে প্ৰদৰ্শনৰ পোছাক বিক্ৰী কৰে।",
            "gu": "બાજુમાં, કેટલાક વધારાના પૈસા કમાવવા માટે, નવીન નજીકના વસ્ત્રોની દુકાનમાં કામ કરે છે જે પરફોર્મન્સ પહેરવેશ વેચે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D8_2_3",
            "hi": "D8_2_3",
            "ka": "D8_2_3",
            "te": "D8_2_3",
            "or": "D8_2_3",
            "as": "D8_2_3",
            "gu": "D8_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "After a few months at the studio, Vishnu trusts Naveen by helping him plan routines for his other students. They plan sessions together in the evenings.",
            "hi": "स्टूडियो में कुछ महीने काम करने के बाद, विष्णु उसके अन्य स्टूडेंट्स के लिए रूटीन बनाने के लिए नवीन की मदद लेता है। वह दोनों मिलकर शाम के सेशन के लिए प्लान बनाते हैं।",
            "ka": "ಸ್ಟುಡಿಯೋದಲ್ಲಿ ಕೆಲವು ತಿಂಗಳುಗಳ ನಂತರ, ವಿಷ್ಣು ತನ್ನ ಇತರ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ದಿನಚರಿಯನ್ನು ಯೋಜಿಸಲು ಸಹಾಯ ಮಾಡುವ ಮೂಲಕ ನವೀನ್‌ನನ್ನು ನಂಬುತ್ತಾನೆ. ಅವರು ಸಂಜೆಯ ಸಮಯದಲ್ಲಿ ಒಟ್ಟಿಗೆ ಅಧಿವೇಶನಗಳನ್ನು ಯೋಜಿಸುತ್ತಾರೆ.",
            "te": "స్టూడియోలో కొన్ని నెలల తర్వాత, విష్ణు తన ఇతర విద్యార్థుల కోసం రొటీన్‌లను ప్లాన్ చేయడంలో సహాయం చేయడంలో నవీన్‌ను నమ్ముతాడు. వారిద్దరు సాయంత్రం పూట కలిసి సెషన్స్ ప్లాన్ చేసుకుంటారు.",
            "or": "ଷ୍ଟୁଡିଓରେ କିଛି ମାସ ରହିବା ପରେ ବିଷ୍ଣୁ ତାଙ୍କୁ ଅନ୍ୟ ଛାତ୍ରମାନଙ୍କ ପାଇଁ ରୁଟିନ୍ ଯୋଜନା କରିବାରେ ସାହାଯ୍ୟ କରି ନବୀନଙ୍କୁ ବିଶ୍ୱାସ କରନ୍ତି | ସେମାନେ ସନ୍ଧ୍ୟାରେ ଏକାଠି ଅଧିବେଶନ ଯୋଜନା କରନ୍ତି |",
            "as": "ষ্টুডিঅ'ত কেইমাহমান ৰখাৰ পিছত, বিষ্ণুৱে নবীনক বিশ্বাস কৰি তেওঁৰ আন শিক্ষাৰ্থীসকলৰ বাবে ৰুটিন পৰিকল্পনা কৰাত সহায় কৰে। তেওঁলোকে সন্ধিয়া একেলগে অধিৱেশনৰ পৰিকল্পনা কৰে।",
            "gu": "સ્ટુડિયોમાં થોડા મહિનાઓ પછી, વિષ્ણુ નવીન પર વિશ્વાસ કરે છે અને તેને અન્ય વિદ્યાર્થીઓ માટે દિનચર્યાઓનું આયોજન કરવામાં મદદ કરે છે. તેઓ એકસાથે સાંજના સત્રોનું આયોજન કરે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "D8_1_3//",
            "hi": "D8_1_3//",
            "ka": "D8_1_3//",
            "te": "D8_1_3//",
            "or": "D8_1_3//",
            "as": "D8_1_3//",
            "gu": "D8_1_3//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Judge Vishnu giving a demo...",
            "hi": "जज विष्णु डेमो दे रहे हैं...",
            "ka": "ಡೆಮೊ ನೀಡಿದ ನ್ಯಾಯಾಧೀಶ ವಿಷ್ಣು…",
            "te": "డెమో ఇస్తున్న జడ్జి విష్ణు...",
            "or": "ଜଜ୍ ବିଷ୍ଣୁ ଏକ ଡେମୋ ଦିଅନ୍ତି...",
            "as": "বিচাৰক বিষ্ণুৱে এটা প্ৰদৰ্শনী দিছে...",
            "gu": "નિરક્ષક વિષ્ણુ ડેમો આપતા..."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Vishnu",
            "hi": "विष्णु",
            "ka": "ವಿಷ್ಣು",
            "te": "విష్ణు",
            "or": "ବିଷ୍ଣୁ",
            "as": "বিষ্ণু",
            "gu": "વિષ્ણુ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Vishnu",
            "hi": "विष्णु",
            "ka": "ವಿಷ್ಣು",
            "te": "విష్ణు",
            "or": "ବିଷ୍ଣୁ",
            "as": "বিষ্ণু",
            "gu": "વિષ્ણુ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "...And this form of hip hop is called breaking.",
            "hi": "….और हिप हॉप के इस फॉर्म को ब्रेकिंग कहा जाता है।",
            "ka": "...ಮತ್ತು ಹಿಪ್ ಹಾಪ್ನ ಈ ರೂಪವನ್ನು ಬ್ರೇಕಿಂಗ್ ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ.",
            "te": "మరియు హిప్ హాప్ యొక్క ఈ విధానాన్ని బ్రేకింగ్ అంటారు.",
            "or": "... ଏବଂ ହିପ୍ ହପ୍ ର ଏହି ଫର୍ମକୁ ବ୍ରେକିଂ କୁହାଯାଏ |",
            "as": "... আৰু হিপ হপৰ এই প্ৰকাৰটোক ব্ৰেকিং বুলি কোৱা হয়।",
            "gu": "...અને હિપ હોપના આ સ્વરૂપને બ્રેકિંગ કહેવામાં આવે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Ah! I had never heard of this form. And you make it look so easy!",
            "hi": "ओह! मैंने इस फॉर्म के बारे में कभी नहीं सुना था। आप यह कितनी आसानी से कर लेते हैं!",
            "ka": "ಆಹ್! ನಾನು ಈ ರೂಪದ ಬಗ್ಗೆ ಕೇಳಿರಲಿಲ್ಲ. ಮತ್ತು ನೀವು ಅದನ್ನು ತುಂಬಾ ಸುಲಭವಾಗಿ ಕಾಣುವಂತೆ ಮಾಡುತ್ತೀರಿ!",
            "te": "ఆహ్! ఈ విధానం గురించి నేను ఎప్పుడూ వినలేదు. మరియు నువ్వు దీన్ని చాలా సులభంగా కనిపించేలా చేస్తున్నావు!",
            "or": "ଆହ ! ମୁଁ ଏହି ଫର୍ମ ବିଷୟରେ କେବେ ଶୁଣି ନ ଥିଲି | ଏବଂ ଆପଣ ଏହାକୁ ଏତେ ସହଜ ଦେଖାନ୍ତି !",
            "as": "আহ! মই কেতিয়াও এই ৰূপটোৰ বিষয়ে শুনা নাছিলো। আৰু আপুনি ইয়াক ইমান সহজভাৱে দেখাইছে!",
            "gu": "આહ! મેં આ પ્રકાર વિશે ક્યારેય સાંભળ્યું ન હતું. અને તે તેને ખૂબ જ સરળ બનાવ્યું છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "Haha, yes, you just need to have good footwork and then simply follow the rhythm of the music.",
            "hi": "हाहा, हाँ, तुम्हें बस अपने फुटवर्क पर ज्यादा ध्यान देना होगा और फिर म्यूजिक के रिदम को फॉलो करना होगा।",
            "ka": "ಹ್ಹಾ, ಹೌದು, ನೀವು ಕೇವಲ ಉತ್ತಮ ಪಾದಚಲನೆಯನ್ನು ಹೊಂದಿರಬೇಕು ಮತ್ತು ನಂತರ ಸಂಗೀತದ ಲಯವನ್ನು ಅನುಸರಿಸಿ.",
            "te": "హహ్, అవును, మీరు కేవలం మంచి ఫుట్‌వర్క్ కలిగి ఉండాలి మరియు సంగీతం యొక్క రిథమ్‌ను అనుసరించండి.",
            "or": "ହାହା, ହଁ, ତୁମ ପାଖରେ କେବଳ ଭଲ ଫୁଟ୍‌ୱାର୍କ ରହିବା ଆବଶ୍ୟକ ଏବଂ ତା’ପରେ କେବଳ ସଂଗୀତର ରିଦିମ୍‍ ଅନୁସରଣ କର |",
            "as": "হাহা, হয়, তুমি কেৱল ভাল ফুটৱৰ্ক কৰিব লাগিব আৰু তাৰ পিছত কেৱল সংগীতৰ ছন্দ অনুসৰণ কৰিব লাগিব।",
            "gu": "હાહા, હા, તમારે ફક્ત સારા પગલાંઓનો ઉપયોગની જરૂર છે અને પછી ફક્ત સંગીતની લયને અનુસરો."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "D8_1_4////",
            "hi": "D8_1_4////",
            "ka": "D8_1_4////",
            "te": "D8_1_4////",
            "or": "D8_1_4////",
            "as": "D8_1_4////",
            "gu": "D8_1_4////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "I see. Do you have any practice sessions where I can learn the steps?",
            "hi": "अच्छा। क्या आप प्रैक्टिस सेशन लेते हैं जहाँ पर मैं यह स्टेप्स सीख सकता हूँ?",
            "ka": "ಹಾಗೇನು. ನಾನು ಹಂತಗಳನ್ನು ಕಲಿಯಬಹುದಾದ ಯಾವುದೇ ಅಭ್ಯಾಸ ಅವಧಿಗಳನ್ನು ನೀವು ಹೊಂದಿದ್ದೀರಾ?",
            "te": "అలాగా. నేను ఈ స్టెప్స్ నేర్చుకోగలిగే సాధన సెషన్‌లు ఏమైనా ఉన్నాయా?",
            "or": "ମୁଁ ଦେଖେ | ତୁମର କୌଣସି ଅଭ୍ୟାସ ଅଧିବେଶନ ଅଛି ଯେଉଁଠାରେ ମୁଁ ଷ୍ଟେପଗୁଡିକ ଶିଖି ପାରେ ?",
            "as": "আচ্ছা। আপোনাৰ কোনো অনুশীলনী অধিৱেশন আছে নেকি য'ত মই পদক্ষেপবোৰ শিকিব পাৰোঁ?",
            "gu": "મેં જોયું. શું તારી પાસે કોઈ પ્રેક્ટિસ સત્ર છે જ્યાં હું તે રીત શીખી શકું?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Of course, In fact, I’m going to teach it to the batch tomorrow. You can observe me as I teach.",
            "hi": "हाँ, क्यों नहीं, मैं कल के बैच में यह स्टेप सिखाने वाला हूँ। तुम मुझे सिखाते हुए देख सकते हो।",
            "ka": "ಸಹಜವಾಗಿ, ನಾನು ಅದನ್ನು ನಾಳೆ ಬ್ಯಾಚ್‌ಗೆ ಕಲಿಸಲಿದ್ದೇನೆ. ನಾನು ಕಲಿಸುತ್ತಿರುವಂತೆ ನೀವು ನನ್ನನ್ನು ಗಮನಿಸಬಹುದು.",
            "te": "వాస్తవానికి, నేను దానిని రేపు బ్యాచ్‌కి నేర్పించబోతున్నాను. నేను చెప్తున్నప్పుడు మీరు నన్ను గమనించాలి.",
            "or": "ଅବଶ୍ୟ, ବାସ୍ତବରେ, ଆସନ୍ତାକାଲି ମୁଁ ଏହା ବ୍ୟାଚ୍କୁ ଶିଖାଇବାକୁ ଯାଉଛି | ଯେମିତି ମୁଁ ଶିଖାଏ ତୁମେ ମୋତେ ଦେଖିପାର |",
            "as": "অৱশ্যে, আচলতে, মই কাইলৈ এইটো বেচক শিকাবলৈ গৈ আছোঁ। মই শিকোৱাৰ সময়ত তুমি মোক পৰ্যবেক্ষণ কৰিব পাৰা।",
            "gu": "ચોક્કસ, વાસ્તવમાં, હું આવતીકાલે બેચને તે શીખવીશ. તું મને જોઈ શકે છે જ્યારે હું તને શીખવાડુ ત્યારે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Yes, that would be great!",
            "hi": "हाँ, यह अच्छा रहेगा!",
            "ka": "ಹೌದು, ಅದು ಉತ್ತಮವಾಗಿರುತ್ತದೆ!",
            "te": "అవునా, అది చాలా బాగుంటుంది!",
            "or": "ହଁ, ଏହା ଉତ୍ତମ ହେବ !",
            "as": "হয়, সেইটোৱে ভাল হ'ব!",
            "gu": "હા, તે સરસ હશે!"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Good. So then I'll leave.  You’ll clean the studio and close it as usual?",
            "hi": "ठीक है। तो अब मैं निकलता हूँ। तुम हमेशा की तरह स्टूडियो क्लीन करके बंद कर दोगे ना?",
            "ka": "ಒಳ್ಳೆಯದು. ಹಾಗಾದರೆ ನಾನು ಹೊರಡುತ್ತೇನೆ. ನೀನು ಸ್ಟುಡಿಯೋವನ್ನು ಸ್ವಚ್ಛಗೊಳಿಸುತ್ತೀಯಾ ಮತ್ತು ಅದನ್ನು ಎಂದಿನಂತೆ ಮುಚ್ಚುತ್ತೀಯಾ?",
            "te": "మంచిది. కాబట్టి నేను బయలుదేరుతాను. మీరు స్టూడియోని శుభ్రం చేసి, ఎప్పటిలాగే మూసివేస్తారా?",
            "or": "ଭଲ | ତେଣୁ ତାହେଲେ ମୁଁ ଚାଲିଯିବି | ତୁମେ ପୂର୍ବପରି ଷ୍ଟୁଡିଓକୁ ସଫା କରି ଏବଂ ଏହାକୁ ବନ୍ଦ କରିବ ?",
            "as": "ভাল। গতিকে এতিয়া মই যাওঁ। তুমি সদায় কৰাৰ দৰে ষ্টুডিঅ'টো পৰিষ্কাৰ কৰিবা আৰু ইয়াক বন্ধ কৰিবা?",
            "gu": "સારું. તો પછી હું નીકળૂ છું. તું સ્ટુડિયો સાફ કરજે અને તેને હંમેશની જેમ બંધ કરીશ?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "Yes, Vishnu Sir, no worries!!",
            "hi": "हाँ, विष्णु सर, चिंता मत करो!",
            "ka": "ಹೌದು ವಿಷ್ಣು ಸರ್ ಚಿಂತೆಯಿಲ್ಲ!!",
            "te": "సరే విష్ణు సార్ కంగారు పడకండి!!",
            "or": "ହଁ, ବିଷ୍ଣୁ ସାର୍, ଚିନ୍ତା ନାହିଁ !!",
            "as": "হয়, বিষ্ণু চাৰ, চিন্তাৰ কোনো কাৰণ নাই!!",
            "gu": "હા, વિષ્ણુ સાહેબ, ચિંતા ના કરો!!"
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "Internship",
            "hi": "इन्टर्नशिप",
            "ka": "ಇಂಟರ್ನ್ಶಿಪ್",
            "te": "ఇంటర్న్‌షిప్",
            "or": "ଇଣ୍ଟର୍ନସିପ୍",
            "as": "ইন্টাৰ্ণশ্বিপ",
            "gu": "ઇન્ટર્નશિપ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Internship experience!",
            "hi": "आपने अभी-अभी इन्टर्नशिप का अनुभव प्राप्त किया हैं!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಇಂಟರ್ನ್‌ಶಿಪ್ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే ఇంటర్న్‌షిప్ అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ଇଣ୍ଟର୍ନସିପ୍ ଅଭିଜ୍ଞତା ହାସଲ କରିଛନ୍ତି !",
            "as": "তুমি এইমাত্ৰ ইণ্টাৰ্নশ্বিপৰ অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "તમે હમણાં જ ઇન્ટર્નશિપનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Internship",
            "hi": "इन्टर्नशिप",
            "ka": "ಇಂಟರ್ನ್ಶಿಪ್",
            "te": "ఇంటర్న్‌షిప్",
            "or": "ଇଣ୍ଟର୍ନସିପ୍",
            "as": "ইন্টাৰ্ণশ্বিপ",
            "gu": "ઇન્ટર્નશિપ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Suitability Assessment Tools",
            "hi": "उचित करियर के लिए मूल्यांकन टूल",
            "ka": "ವೃತ್ತಿ ಸೂಕ್ತತೆಯ ಮೌಲ್ಯಮಾಪನ ಪರಿಕರಗಳು",
            "te": "కెరీర్ అనుకూలత అంచనా సాధనాలు",
            "or": "କ୍ଯାରିୟର୍‌ ସୁଇଟାବିଲିଟି ଆସେସ୍‌ମେଣ୍ଟ୍‌ ଟୁଲ୍ସ",
            "as": "জীৱিকা উপযুক্ততা মূল্যায়ন সঁজুলি",
            "gu": "કારકિર્દી યોગ્યતા મૂલ્યાંકન સાધનો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Naveen gets an internship with Vishnu Sir. An internship is an opportunity to work in an organisation on a specific project/role for a short period. An intern usually reports to a supervisor who acts as a mentor/coach. It is the closest way to learn more about a job and gain valuable workplace skills. An internship can be quite helpful when looking for new jobs. You can also look for an internship if you need to make a career shift.",
            "hi": "नवीन को विष्णु सर के पास इन्टर्नशिप मिल जाती है। इन्टर्नशिप का मतलब होता है, किसी संस्था के साथ विशेष प्रोजेक्ट/रोल के लिए कम समय के लिए काम करने का अवसर। आमतौर पर इन्टर्न अपने सुपरवाइजर को रिपोर्ट करते हैं, जो उनके लिए कोच/उपदेशक के तौर पर काम करते हैं। यह किसी भी जॉब के बारे में सीखने का और महत्वपूर्ण कौशल प्राप्त करने का सबसे आसान तरीका है। नए जॉब ढूंढने के लिए इन्टर्नशिप काफी मददगार साबित हो सकती है। अगर आप करियर शिफ्ट करना चाहते हैं तो इन्टर्नशिप का तारिका अपना सकते हैं।",
            "ka": "ನವೀನ್ ವಿಷ್ಣು ಸರ್ ಜೊತೆ ಇಂಟರ್ನ್ ಶಿಪ್ ಪಡೆಯುತ್ತಾನೆ. ಇಂಟರ್ನ್‌ಶಿಪ್ ಎನ್ನುವುದು ಸಂಸ್ಥೆಯಲ್ಲಿ ಅಲ್ಪಾವಧಿಗೆ ನಿರ್ದಿಷ್ಟ ಯೋಜನೆ/ಪಾತ್ರದಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಅವಕಾಶವಾಗಿದೆ. ಒಬ್ಬ ಇಂಟರ್ನ್ ಸಾಮಾನ್ಯವಾಗಿ ಸಲಹೆಗಾರ/ತರಬೇತುದಾರನಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುವ ಮೇಲ್ವಿಚಾರಕನಿಗೆ ವರದಿ ಮಾಡುತ್ತಾನೆ. ಕೆಲಸದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಮತ್ತು ಮೌಲ್ಯಯುತವಾದ ಕೆಲಸದ ಕೌಶಲ್ಯಗಳನ್ನು ಪಡೆಯಲು ಇದು ಹತ್ತಿರದ ಮಾರ್ಗವಾಗಿದೆ. ಹೊಸ ಉದ್ಯೋಗಗಳನ್ನು ಹುಡುಕುತ್ತಿರುವಾಗ ಇಂಟರ್ನ್‌ಶಿಪ್ ಸಾಕಷ್ಟು ಸಹಾಯಕವಾಗಬಹುದು. ನೀವು ವೃತ್ತಿಜೀವನದ ಬದಲಾವಣೆಯನ್ನು ಮಾಡಬೇಕಾದರೆ ನೀವು ಇಂಟರ್ನ್‌ಶಿಪ್ ಅನ್ನು ಸಹ ನೋಡಬಹುದು.",
            "te": "నవీన్ విష్ణు సర్ దగ్గర ఇంటర్న్‌షిప్ పొందాడు. ఇంటర్న్‌షిప్ అనేది ఒక నిర్దిష్ట ప్రాజెక్ట్/పాత్రపై తక్కువ వ్యవధిలో ఒక సంస్థలో పని చేసే అవకాశం. ఒక ఇంటర్న్ సాధారణంగా మెంటర్/కోచ్‌గా పనిచేసే సూపర్‌వైజర్‌కి నివేదిస్తాడు. ఉద్యోగం గురించి మరింత తెలుసుకోవడానికి మరియు విలువైన కార్యాలయ నైపుణ్యాలను పొందేందుకు ఇది సన్నిహిత మార్గం. కొత్త ఉద్యోగాల కోసం చూస్తున్నప్పుడు ఇంటర్న్‌షిప్ చాలా ఉపయోగకరంగా ఉంటుంది. మీరు కెరీర్‌లో మార్పు చేయవలసి వస్తే మీరు ఇంటర్న్‌షిప్ కోసం కూడా చూడవచ్చు.",
            "or": "ନବୀନ ବିଷ୍ଣୁ ସାର୍ ସହିତ ଏକ ଇଣ୍ଟର୍ନସିପ୍ ପାଆନ୍ତି | ଏକ ଇଣ୍ଟର୍ନସିପ୍ ଏକ ସ୍ୱଳ୍ପ ସମୟ ପାଇଁ ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ପ୍ରୋଜେକ୍ଟ/ଭୂମିକାରେ ଏକ ସଂସ୍ଥାରେ କାର୍ଯ୍ୟ କରିବାର ଏକ ସୁଯୋଗ ଅଟେ | । ଜଣେ ଇଣ୍ଟରନେଟ୍ ସାଧାରଣତ a ଜଣେ ସୁପରଭାଇଜରଙ୍କୁ ରିପୋର୍ଟ କରେ ଯିଏ ଜଣେ ମେଣ୍ଟର / କୋଚ୍ ଭାବରେ କାର୍ଯ୍ୟ କରେ | ଚାକିରି ବିଷୟରେ ଅଧିକ ଜାଣିବା ଏବଂ ମୂଲ୍ୟବାନ କର୍ମକ୍ଷେତ୍ରରେ କୌଶଳ ହାସଲ କରିବା ହେଉଛି ଏହା ନିକଟତମ ଉପାୟ | ନୂତନ ଚାକିରି ଖୋଜୁଥିବା ସମୟରେ ଏକ ଇଣ୍ଟର୍ନସିପ୍ ଯଥେଷ୍ଟ ସହାୟକ ହୋଇପାରେ | ଯଦି ଆପଣ କ୍ୟାରିଅର୍ ସିଫ୍ଟ କରିବାକୁ ଆବଶ୍ୟକ କରନ୍ତି ତେବେ ଆପଣ ଏକ ଇଣ୍ଟର୍ନସିପ୍ ମଧ୍ୟ ଖୋଜି ପାରିବେ |",
            "as": "নবীনে বিষ্ণু ছাৰৰ সৈতে ইন্টাৰ্ণশ্বিপ লাভ কৰে। ইণ্টাৰ্নশ্বিপ হৈছে এক নিৰ্দিষ্ট প্ৰকল্প/ভূমিকাত কম সময়ৰ বাবে কাম কৰাৰ এক সুযোগ। এজন ইন্টাৰ্ণে সাধাৰণতে এজন চুপাৰভাইজাৰক প্ৰতিবেদন দিয়ে যি এজন পৰামৰ্শদাতা/প্ৰশিক্ষক হিচাপে কাম কৰে। চাকৰি এটাৰ বিষয়ে অধিক শিকিবলৈ আৰু কৰ্মস্থানৰ মূল্যৱান দক্ষতা প্ৰাপ্ত কৰাৰ এইটো আটাইতকৈ নিকটতম উপায়। নতুন চাকৰি এটা বিচাৰাৰ সময়ত ইন্টাৰ্ণশ্বিপ যথেষ্ট সহায়ক হ'ব পাৰে। যদি তুমি জীৱিকাৰ পৰিৱৰ্তন কৰিব বিচাৰা তেন্তে তুমিও এটা ইন্টাৰ্ণশ্বিপ বিচাৰিব পাৰে।",
            "gu": "નવીન વિષ્ણુ સર સાથે ઇન્ટર્નશિપ કરી રહ્યો છે. ઇન્ટર્નશિપ એ ટૂંકા ગાળા માટે સંસ્થામાં ચોક્કસ પ્રોજેક્ટ / ભૂમિકા પર કામ કરવાની તક છે. ઈન્ટર્ન સામાન્ય રીતે સુપરવાઈઝરને જાણ કરે છે જે માર્ગદર્શક/કોચ તરીકે કામ કરે છે. નોકરી વિશે વધુ શીખવાની અને કાર્યસ્થળે મૂલ્યવાન કૌશલ્યો મેળવવાની આ સૌથી નજીકની રીત છે. નવી નોકરીની શોધમાં ઇન્ટર્નશિપ ખૂબ મદદરૂપ થઈ શકે છે. જો તમને કારકિર્દી શિફ્ટની જરૂર હોય તો તમે ઇન્ટર્નશિપ પણ જોઈ શકો છો."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "D8_3_1",
            "hi": "D8_3_1",
            "ka": "D8_3_1",
            "te": "D8_3_1",
            "or": "D8_3_1",
            "as": "D8_3_1",
            "gu": "D8_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "While clearing up that day. Naveen gets 2 messages on his phone.\nOne is from Omi, the other is from Judge Vishnu.",
            "hi": "उस दिन का काम खत्म करते समय नवीन को फोन पर 2 मैसेज प्राप्त हुए।\n एक ओमी का था और दूसरा जज विष्णु का।",
            "ka": "ಆ ದಿನ ಕ್ಲಿಯರ್ ಮಾಡುವಾಗ ನವೀನ್ ಫೋನ್ ಗೆ 2 ಮೆಸೇಜ್ ಬಂದಿತ್ತು.\n ಒಂದು ಓಮಿಯಿಂದ, ಇನ್ನೊಂದು ನ್ಯಾಯಾಧೀಶ ವಿಷ್ಣು ಅವರಿಂದ.",
            "te": "ఆ రోజు శుభ్రం చేస్తున్నప్పుడు నవీన్ ఫోన్‌కి 2 మెసేజ్‌లు వచ్చాయి. ఒకటి ఓమి దగ్గరనుండి మరియు ఇంకొకటి జడ్జ్ విష్ణు దగ్గరనుండి.",
            "or": "ସେଦିନ ସଫା କରିବା ସମୟରେ | ନବୀନ ତାଙ୍କ ଫୋନରେ 2 ଟି ମେସେଜ୍ ପାଆନ୍ତି |\n ଗୋଟିଏ ଓମି ଠାରୁ, ଅନ୍ୟଟି ଜଜ୍ ବିଷ୍ଣୁଙ୍କ ଠାରୁ ।",
            "as": "সেইদিনা চাফা কৰি থাকোতে। নবীনে তাৰ ফোনত ২ টা মেচেজ পায়। এটা অমিৰ আৰু আনটো বিচাৰক বিষ্ণুৰ ৷",
            "gu": "તે દિવસે સાફ કરતી વખતે. નવીનને તેના ફોન પર 2 સંદેશા મળે છે.\nએક ઓમીનો છે, બીજો નિરીક્ષક વિષ્ણુનો છે."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "D8_3_2",
            "hi": "D8_3_2",
            "ka": "D8_3_2",
            "te": "D8_3_2",
            "or": "D8_3_2",
            "as": "D8_3_2",
            "gu": "D8_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.messageSentTo",
          "value": {
            "en": "Omi",
            "hi": "ओमी",
            "ka": "ಓಮಿ",
            "te": "ఓమి",
            "or": "ଓମି",
            "as": "অমি",
            "gu": "ઓમની"
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.0.messageText",
          "value": {
            "en": "Yo Naveen! Guess what! The group got a gig! A local event manager was really impressed by our videos, and she wants us to be the supporting act in some college fests they’re organising next month!  The pay is not huge, but this will be our first big performance. . What do you think?",
            "hi": "हाय नवीन! एक खुश खबर है! हमारी ग्रुप को एक अच्छा काम मिला है! एक लोकल इवेंट मैनेजर हमारे वीडियोज को देख कर बहुत इंप्रेस हुई है, और वह चाहती है की, हम अगले महीने उनके द्वारा आयोजित एक कॉलेज फेस्ट में सपोर्टिंग एक्ट करें! पैसे तो ज्यादा नहीं मिलेंगे, लेकिन यह हमारा फर्स्ट कॉन्ट्रैक्ट है। क्या ख्याल है?",
            "ka": "ಯೋ ನವೀನ್! ಊಹಿಸು ನೋಡೋಣ! ಗುಂಪಿಗೆ ಗಿಗ್ ಸಿಕ್ಕಿತು! ನಮ್ಮ ವೀಡಿಯೊಗಳಿಂದ ಸ್ಥಳೀಯ ಈವೆಂಟ್ ಮ್ಯಾನೇಜರ್ ನಿಜವಾಗಿಯೂ ಪ್ರಭಾವಿತರಾಗಿದ್ದಾರೆ ಮತ್ತು ಮುಂದಿನ ತಿಂಗಳು ಅವರು ಆಯೋಜಿಸುವ ಕೆಲವು ಕಾಲೇಜು ಫೆಸ್ಟ್‌ಗಳಲ್ಲಿ ನಾವು ಪೋಷಕ ಕ್ರಿಯೆಯಾಗಬೇಕೆಂದು ಅವರು ಬಯಸುತ್ತಾರೆ! ವೇತನವು ದೊಡ್ಡದಲ್ಲ, ಆದರೆ ಇದು ನಮ್ಮ ಮೊದಲ ಒಪ್ಪಂದವಾಗಿದೆ. ನೀನು ಏನು ಯೋಚಿಸುತ್ತೀಯ?",
            "te": "యో నవీన్! ఊహించండి! సమూహానికి ఒక ప్రదర్శన వచ్చింది! స్థానిక ఈవెంట్ మేనేజర్ మా వీడియోలను చూసి నిజంగా ఆకట్టుకున్నారు మరియు వచ్చే నెలలో వారు నిర్వహించే కొన్ని కళాశాల ఫెస్ట్‌లలో మేము సపోర్టింగ్ యాక్ట్‌గా ఉండాలని ఆమె కోరుకుంటుంది! జీతం పెద్దది కాదు, కానీ ఇది మా మొదటి పెద్ద ప్రదర్శన. . మీరు ఏమనుకుంటున్నారు?",
            "or": "ୟୋ ନବୀନ ! ଅନୁମାନ କରିପାର ! ଗ୍ରୁପ୍ ଏକ ଗିଗ୍ ପାଇଲା ! ଏକ ସ୍ଥାନୀୟ ଇଭେଣ୍ଟ ମ୍ୟାନେଜର ପ୍ରକୃତରେ ଆମର ଭିଡିଓ ଦ୍ୱାରା ପ୍ରଭାବିତ ହୋଇଥିଲେ ଏବଂ ସେ ଚାହାଁନ୍ତି ଯେ ସେମାନେ ଆସନ୍ତା ମାସରେ ଆୟୋଜନ କରୁଥିବା କିଛି କଲେଜ ଫେଷ୍ଟରେ ଆମେ ସହାୟକ କାର୍ଯ୍ୟ କରୁ ! ଦରମା ବଡ଼ ନୁହେଁ, କିନ୍ତୁ ଏହା ଆମର ପ୍ରଥମ ବଡ ପରିବେଷଣ ହେବ... ତୁମେ କ 'ଣ ଭାବୁଛ ?",
            "as": "ইঅ’ নবীন! অনুমান কৰা! গোটটোৱে এটা কাম পালে! এজন স্থানীয় ইভেণ্ট মেনেজাৰ আমাৰ ভিডিঅ'বোৰৰ দ্বাৰা সঁচাকৈয়ে প্ৰভাৱিত হ’ল, আৰু তেওঁ বিচাৰে যে তেওঁলোকে অহা মাহত আয়োজন কৰা কিছুমান মহাবিদ্যালয় উৎসৱত আমি সহায়ক হিচাপে কাম কৰোঁ! দৰমহা বৃহৎ নহয়, কিন্তু এইটো আমাৰ প্ৰথম চুক্তি হ'ব। তুমি কি ভাবা?",
            "gu": "યો નવીન! શું તું જાણે છે! બેન્ડને કોન્સર્ટ મળ્યો! સ્થાનિક ઇવેન્ટ મેનેજર આપણા વિડિયોઝ વિશે ખરેખર ઉત્સાહિત છે અને તેઓ ઇચ્છે છે કે આપણે આવતા મહિને તેઓ આયોજિત કેટલાક કોલેજ ફેસ્ટિવલના સમર્થક બનીએ! વળતર સારું નથી, પરંતુ તે આપણો પ્રથમ મોટો શો હશે. . તું શું વિચારો છો?"
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "D8_3_3",
            "hi": "D8_3_3",
            "ka": "D8_3_3",
            "te": "D8_3_3",
            "or": "D8_3_3",
            "as": "D8_3_3",
            "gu": "D8_3_3"
          }
        },
        {
          "valuePath": "blocks.0.props.messageSentTo",
          "value": {
            "en": "Vishnu Sir",
            "hi": "विष्णु सर",
            "ka": "ವಿಷ್ಣು ಸರ್",
            "te": "విష్ణు సర్",
            "or": "ବିଷ୍ଣୁ ସାର୍",
            "as": "বিষ্ণু চাৰ",
            "gu": "વિષ્ણુ સાહેબ"
          }
        },
        {
          "valuePath": "blocks.0.props.allMessages.0.messageText",
          "value": {
            "en": "Hi Naveen! I completely forgot to tell you something important today! So one of my friends who runs a dance studio is looking for a Jr instructor. I was wondering if you’d be interested? I can write a recommendation for you. You’ll have to give an interview, but I think you’re ready to take up a better role now.  Think about it. If you’re interested, I’ll share more details...",
            "hi": "हाय नवीन! मैं तुम्हें एक जरूरी बात बताना भूल गया! मेरा एक दोस्त डांस स्टूडियो चलाता है और वह जूनियर इंस्ट्रक्टर के तलाश में है। मैं सोच रहा था कि क्या तुम यह जॉब कर सकते हो? मैं तुम्हारे लिए रिकमेंडेशन लिखकर दे सकता हूँ। तुम्हें इंटरव्यू देना होगा लेकिन मुझे लगता है कि अब तुम बेहतर रोल के लिए तैयार हो। इसके बारे में सोच कर बताना। अगर तुम दिलचस्प हो तो मैं तुम्हें डिटेल्स दूंगा।",
            "ka": "ನಮಸ್ಕಾರ ನವೀನ್! ನಾನು ಇಂದು ನಿಮಗೆ ಒಂದು ಮುಖ್ಯವಾದ ವಿಷಯವನ್ನು ಹೇಳಲು ಸಂಪೂರ್ಣವಾಗಿ ಮರೆತಿದ್ದೇನೆ! ಹಾಗಾಗಿ ಡ್ಯಾನ್ಸ್ ಸ್ಟುಡಿಯೋ ನಡೆಸುತ್ತಿರುವ ನನ್ನ ಸ್ನೇಹಿತರಲ್ಲಿ ಒಬ್ಬರು ಜೂನಿಯರ್ ಬೋಧಕರನ್ನು ಹುಡುಕುತ್ತಿದ್ದಾರೆ. ನಿಮಗೆ ಆಸಕ್ತಿ ಇದೆಯೇ ಎಂದು ನಾನು ಆಶ್ಚರ್ಯ ಪಡುತ್ತಿದ್ದೆ? ! ನಾನು ನಿಮಗಾಗಿ ಶಿಫಾರಸು ಬರೆಯಬಲ್ಲೆ. ನೀವು ಸಂದರ್ಶನವನ್ನು ನೀಡಬೇಕಾಗುತ್ತದೆ, ಆದರೆ ಪಾತ್ರವು ಈಗ ನೀವು ಹೊಂದಿರುವದಕ್ಕಿಂತ ಉತ್ತಮವಾಗಿರುತ್ತದೆ. ಅದರ ಬಗ್ಗೆ ಯೋಚಿಸು. ನಿಮಗೆ ಆಸಕ್ತಿ ಇದ್ದರೆ, ನಾನು ಹೆಚ್ಚಿನ ವಿವರಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತೇನೆ...",
            "te": "హాయ్ నవీన్! ఈరోజు మీకు ఒక ముఖ్యమైన విషయం చెప్పడం పూర్తిగా మర్చిపోయాను! కాబట్టి డ్యాన్స్ స్టూడియో నడుపుతున్న నా స్నేహితుల్లో ఒకరు జూనియర్ బోధకుడి కోసం వెతుకుతున్నారు. మీకు ఆసక్తి ఉందా అని నేను ఆశ్చర్యపోతున్నాను? నేను మీ కోసం ఒక సిఫార్సు వ్రాయగలను. మీరు ఇంటర్వ్యూ ఇవ్వవలసి ఉంటుంది, కానీ మీరు ఇప్పుడు మంచి పాత్రను స్వీకరించడానికి సిద్ధంగా ఉన్నారని నేను భావిస్తున్నాను. దాని గురించి ఆలోచించు. మీకు ఆసక్తి ఉంటే, నేను మరిన్ని వివరాలను పంచుకుంటాను...",
            "or": "ନମସ୍କାର ନବୀନ ! ଆଜି ତୁମକୁ କିଛି ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ କଥା କହିବାକୁ ମୁଁ ସମ୍ପୂର୍ଣ୍ଣ ଭୁଲିଗଲି ! ତେଣୁ ମୋର ଜଣେ ବନ୍ଧୁ ଯିଏ ଏକ ଡ୍ୟାନ୍ସ ଷ୍ଟୁଡିଓ ଚଳାନ୍ତି ଜୁନିୟର ପ୍ରଶିକ୍ଷକ ଖୋଜନ୍ତି | ମୁଁ ଭାବୁଥିଲି ତୁମେ ଆଗ୍ରହୀ କି ? ମୁଁ ତୁମ ପାଇଁ ଏକ ସୁପାରିଶ ଲେଖିପାରିବି | ତୁମକୁ ଏକ ସାକ୍ଷାତକାର ଦେବାକୁ ପଡିବ,କିନ୍ତୁ ମୁଁ ଭାବୁଛି ତୁମେ ବର୍ତ୍ତମାନ ଏକ ଉତ୍ତମ ଭୂମିକା ଗ୍ରହଣ କରିବାକୁ ପ୍ରସ୍ତୁତ | ଏହା ବିଷୟରେ ଭାବ। ଯଦି ତୁମେ ଆଗ୍ରହୀ, ମୁଁ ଅଧିକ ବିବରଣୀ ଅଂଶୀଦାର କରିବି ...",
            "as": "হাই নবীন! মই আজি তোমাক গুৰুত্বপূৰ্ণ কথা এটা ক'বলৈ একেবাৰে পাহৰি গৈছিলো! মোৰ এজন বন্ধু যিয়ে এটা নৃত্য ষ্টুডিঅ চলায় তেওঁ এজন জুনিয়ৰ প্ৰশিক্ষক বিচাৰি আছে। মই ভাবি আছিলো তুমি আগ্ৰহী হ'বানে? ! মই তোমাৰ বাবে এটা পৰামৰ্শ লিখি দিব পাৰিম। তুমি এটা সাক্ষাৎকাৰ দিব লাগিব, কিন্তু মই ভাবো তুমি এতিয়া এটা ভাল ভূমিকা ল'বলৈ সাজু। ইয়াৰ বিষয়ে ভাবিবাছোন। যদি তুমি আগ্ৰহী, মই অধিক বিৱৰণ জনাম...",
            "gu": "કેમ છે નવીન! આજે હું તને એક મહત્વપૂર્ણ વાત કહેવાનું ભૂલી ગયો! તો મારા એક મિત્ર જે ડાન્સ સ્ટુડિયો ચલાવે છે તે જુનિયર ઈન્સ્ટ્રક્ટરની શોધમાં છે. હું આશ્ચર્ય પામી રહ્યો હતો કે શું તને રસ છે? હું તમારા માટે ભલામણ કરી શકું છું. તારે એક ઇન્ટરવ્યુ આપવો પડશે, પરંતુ મને લાગે છે કે તમે હવે વધુ સારી ભૂમિકા નિભાવવા માટે તૈયાર છો. એના વિશે વિચારો. જો તમને રસ હોય, તો હું વધુ વિગતો બતાવું..."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "D8_3_4_D",
            "hi": "D8_3_4_D",
            "ka": "D8_3_4_D",
            "te": "D8_3_4_D",
            "or": "D8_3_4_D",
            "as": "D8_3_4_D",
            "gu": "D8_3_4_D"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Naveen is confused. His hard work in the past few months is finally paying off. But he is finding it hard to decide. What should he do?",
            "hi": "नवीन कंफ्यूज है। उसके पिछले कई महीनों के मेहनत का फल उससे मिलने वाला है। लेकिन उसे समझ नहीं आ रहा कि उसे क्या करना चाहिए?",
            "ka": "ನವೀನ್ ಗೊಂದಲದಲ್ಲಿದ್ದಾನೆ. ಕಳೆದ ಕೆಲವು ತಿಂಗಳುಗಳ ಅವನ ಶ್ರಮ ಕೊನೆಗೂ ಫಲ ನೀಡಿದೆ. ಆದರೆ ಅವನು ನಿರ್ಧರಿಸಲು ಕಷ್ಟಪಡುತ್ತಾನೆ. ಅವನು ಏನು ಮಾಡಬೇಕು?",
            "te": "నవీన్ కంగారు పడ్డాడు. గత కొన్ని నెలలుగా ఆయన కష్టానికి ఫలితం దక్కింది. కానీ అతను నిర్ణయించుకోవడం కష్టంగా ఉంది. అతను ఏమి చేయాలి?",
            "or": "ନବୀନ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛନ୍ତି | ଗତ କିଛି ମାସ ମଧ୍ୟରେ ତାଙ୍କର କଠିନ ପରିଶ୍ରମ ଶେଷରେ ଫଳ ଦେଉଛି | କିନ୍ତୁ ନିଷ୍ପତ୍ତି ନେବା ତାଙ୍କୁ କଷ୍ଟକର ହେଉଛି | ସେ କ’ଣ କରିବା ଉଚିତ୍ ?",
            "as": "নবীন বিমোৰত পৰে। যোৱা কেইমাহমানৰ তাৰ কঠোৰ পৰিশ্ৰমৰ অৱশেষত ফল পাইছে। কিন্তু সি সিদ্ধান্ত ল'বলৈ টান পাইছে। সি কি কৰা উচিত?",
            "gu": "નવીન મૂંઝવણમાં છે. છેલ્લા કેટલાક મહિનાથી તેની મહેનત આખરે રંગ લાવી રહી છે. પરંતુ તેને નિર્ણય લેવામાં કઠીનાઈ પડી રહી છે. તેણે શું કરવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Say yes to Omi",
            "hi": "ओमी को हाँ कहना चाहिए",
            "ka": "ಓಮಿಗೆ ಹೌದು ಎಂದು ಹೇಳಿ",
            "te": "ఓమికి అవును అని చెప్పడం",
            "or": "ଓମିକୁ ହଁ କୁହନ୍ତୁ |",
            "as": "অমিক হ’ব বুলি ক’ব",
            "gu": "ઓમી ને હા પાડો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Say yes to Judge Vishnu",
            "hi": "जज विष्णु को हाँ कहना चाहिए",
            "ka": "ಜಡ್ಜ್ ವಿಷ್ಣುವಿಗೆ ಹೌದು ಎಂದು ಹೇಳಿ",
            "te": "జడ్జి విష్ణుకి అవును అని చెప్పడం",
            "or": "ଜଜ୍ ବିଷ୍ଣୁଙ୍କୁ ହଁ କୁହନ୍ତୁ",
            "as": "মিঃ লুইছক হ’ব বুলি ক’ব",
            "gu": "સાહેબ વિષ્ણુને હા પાડો"
          }
        }
      ]
    },
    "scene33": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D9_1_1//_Appear for the job interview",
            "hi": "जॉब इंटरव्यू के लिए जाना",
            "ka": "D9_1_1//_ಉದ್ಯೋಗ ಸಂದರ್ಶನಕ್ಕೆ ಹಾಜರಾಗಿ",
            "te": "D9_1_1//_ఉద్యోగ ఇంటర్వ్యూ కోసం హాజరు అవడం",
            "or": "D9_1_1//_Appear for the job interview",
            "as": "D9_1_1//_চাকৰিৰ বাবে সাক্ষাৎকাৰৰ",
            "gu": "D9_1_1//_નોકરીના ઇન્ટરવ્યુ માટે હાજર થાઓ"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Naveen appears for the interview at the Groove And Go dance studio.",
            "hi": "नवीन ग्रुव एंड गो डांस स्टूडियो में इंटरव्यू के लिए जाता है।",
            "ka": "ನವೀನ್ ಗ್ರೂವ್ ಅಂಡ್ ಗೋ ಡ್ಯಾನ್ಸ್ ಸ್ಟುಡಿಯೋದಲ್ಲಿ ಸಂದರ್ಶನಕ್ಕೆ ಹೋಗುತ್ತಾನೆ.",
            "te": "నవీన్ గ్రూవ్ అండ్ గో డ్యాన్స్ స్టూడియోలో ఇంటర్వ్యూకి అటెండ్ అయ్యాడు.",
            "or": "ନବୀନ ଗ୍ରୁଭ୍ ଆଣ୍ଡ୍ ଗୋ ଡ୍ୟାନ୍ସ ଷ୍ଟୁଡିଓରେ ସାକ୍ଷାତକାର ପାଇଁ ଉପସ୍ଥିତ ହୁଅନ୍ତି |",
            "as": "নবীন গ্ৰুভ এণ্ড গো ডান্স ষ্টুডিঅ'ত সাক্ষাৎকাৰৰ বাবে উপস্থিত হয়।",
            "gu": "નવીન ગ્રુવ એન્ડ ગો ડાન્સ સ્ટુડિયોમાં ઇન્ટરવ્યુ માટે પસંદ થાય છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Interviewer",
            "hi": "इंटरव्यूवर",
            "ka": "ಸಂದರ್ಶಕ",
            "te": "ఇంటర్వ్యూయర్",
            "or": "ସାକ୍ଷାତକାରକାରୀ",
            "as": "সাক্ষাৎকাৰ লওতা",
            "gu": "ઈન્ટરવ્યુ લેનાર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Interviewer",
            "hi": "इंटरव्यूवर",
            "ka": "ಸಂದರ್ಶಕ",
            "te": "ఇంటర్వ్యూయర్",
            "or": "ସାକ୍ଷାତକାରକାରୀ",
            "as": "সাক্ষাৎকাৰ লওতা",
            "gu": "ઈન્ટરવ્યુ લેનાર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.4.label",
          "value": {
            "en": "Interviewer",
            "hi": "इंटरव्यूवर",
            "ka": "ಸಂದರ್ಶಕ",
            "te": "ఇంటర్వ్యూయర్",
            "or": "ସାକ୍ଷାତକାରକାରୀ",
            "as": "সাক্ষাৎকাৰ লওতা",
            "gu": "ઈન્ટરવ્યુ લેનાર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.5.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "What qualities do you have that will be an asset to our studio?",
            "hi": "तुम्हारे पास ऐसी कौन सी खूबी है जो हमारे स्टूडियो के लिए अच्छी साबित हो सकती है?",
            "ka": "ನಮ್ಮ ಸ್ಟುಡಿಯೋಗೆ ಆಸ್ತಿಯಾಗಿರುವ ಯಾವ ಗುಣಗಳನ್ನು ನೀವು ಹೊಂದಿದ್ದೀರಿ?",
            "te": "మా స్టూడియోకి అసెట్‌గా మారే ఏ లక్షణాలు మీకు ఉన్నాయి?",
            "or": "ଆପଣଙ୍କ ପାଖରେ କେଉଁ ଗୁଣ ଅଛି ଯାହା ଆମ ଷ୍ଟୁଡିଓ ପାଇଁ ଏକ ସମ୍ପତ୍ତି ହେବ ?",
            "as": "তোমাৰ এনে কি গুণ আছে যি আমাৰ ষ্টুডিঅ'ৰ বাবে সম্পদ হ'ব?",
            "gu": "તારી પાસે એવા કયા ગુણો છે જે આપણા સ્ટુડિયોની સંપત્તિ રહી શકે હશે?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Umm, Ma’am, I can confidently say that I am a fast learner and adapt to situations quickly.",
            "hi": "मैम मैं विश्वास के साथ कह सकता हूँ कि मैं जल्दी सीखता हूँ और परिस्थिति के अनुसार जल्द ही खुद को अनुकूल बना सकता हूँ।",
            "ka": "ಉಮ್, ಮೇಡಂ, ನಾನು ವೇಗವಾಗಿ ಕಲಿಯುವವ ಮತ್ತು ತ್ವರಿತವಾಗಿ ಸನ್ನಿವೇಶಗಳಿಗೆ ಹೊಂದಿಕೊಳ್ಳುತ್ತೇನೆ ಎಂದು ನಾನು ಆತ್ಮವಿಶ್ವಾಸದಿಂದ ಹೇಳಬಲ್ಲೆ.",
            "te": "మేడమ్, నేను వేగంగా నేర్చుకునేవాడినని మరియు పరిస్థితులకు త్వరగా అలవాటు పడతానని నమ్మకంగా చెప్పగలను.",
            "or": "ଉମ୍, ମ୍ଯାଡାମ, ମୁଁ ଆତ୍ମବିଶ୍ୱାସରେ କହିପାରେ ଯେ ମୁଁ ଜଣେ ଦ୍ରୁତ ଶିକ୍ଷାର୍ଥୀ ଏବଂ ଶୀଘ୍ର ପରିସ୍ଥିତିକୁ ଅନୁକୂଳ କରେ |",
            "as": "উম, মেডাম, মই আত্মবিশ্বাসেৰে ক'ব পাৰোঁ যে মই এজন দ্ৰুত শিকাৰু আৰু পৰিস্থিতিৰ সৈতে সোনকালে খাপ খাওঁ।",
            "gu": "હા, મેડમ, હું વિશ્વાસપૂર્વક કહી શકું છું કે હું એક ઝડપી શીખનાર છું અને પરિસ્થિતિને ઝડપથી સ્વીકારી લઉં છું."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "Are you a certified trainer? Have you had any work experience in a studio before?",
            "hi": "क्या तुम प्रमाणित ट्रेनर हो?क्या तुम्हारे पास स्टूडियो में काम करने का कोई अनुभव है?",
            "ka": "ನೀವು ಪ್ರಮಾಣೀಕೃತ ತರಬೇತುದಾರರೇ? ನೀವು ಮೊದಲು ಸ್ಟುಡಿಯೋದಲ್ಲಿ ಯಾವುದೇ ಕೆಲಸದ ಅನುಭವವನ್ನು ಹೊಂದಿದ್ದೀರಾ?",
            "te": "మీరు సర్టిఫైడ్ శిక్షకులా? ఇంతకు ముందు మీకు స్టూడియోలో పని అనుభవం ఉందా?",
            "or": "ଆପଣ ଜଣେ ସାର୍ଟିଫାଏଡ୍ ପ୍ରଶିକ୍ଷକ କି? ଆପଣଙ୍କ ପୂର୍ବରୁ ଏକ ଷ୍ଟୁଡିଓରେ କୌଣସି କାର୍ଯ୍ୟ ଅଭିଜ୍ଞତା ଅଛି କି ?",
            "as": "তুমি এজন প্ৰমাণিত প্ৰশিক্ষক নে? তোমাৰ আগতে ষ্টুডিঅ'ত কাম কৰা অভিজ্ঞতা আছে নেকি?",
            "gu": "શું તું પ્રમાણિત ટ્રેનર છે? શું તને પહેલાં સ્ટુડિયોમાં કોઈ કામનો અનુભવ છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.text",
          "value": {
            "en": "I don’t have a certificate, ma’am,but I have a recommendation letter from my previous workplace.",
            "hi": "मेरे पास कोई प्रमाण पत्र नहीं है, मैम, लेकिन मैं जहाँ पर काम करता हूँ वहाँ से मुझे रेकमेंडेशन लेटर दिया गया हैI",
            "ka": "ನನ್ನ ಬಳಿ ಪ್ರಮಾಣಪತ್ರವಿಲ್ಲ, ಮೇಡಂ, ಆದರೆ ನನ್ನ ಹಿಂದಿನ ಕೆಲಸದ ಸ್ಥಳದಿಂದ ಶಿಫಾರಸು ಪತ್ರವಿದೆ.",
            "te": "నా దగ్గర సర్టిఫికేట్ లేదు, మేడమ్, కానీ నా మునుపటి వర్క్‌ప్లేస్ నుండి నాకు సిఫార్సు లేఖ ఉంది.",
            "or": "ମୋର ସାର୍ଟିଫିକେଟ୍ ନାହିଁ, ମ୍ଯାଡାମ, କିନ୍ତୁ ମୋର ପୂର୍ବ କାର୍ଯ୍ୟକ୍ଷେତ୍ରରୁ ମୋ ପାଖରେ ଏକ ସୁପାରିଶ ପତ୍ର ଅଛି |",
            "as": "মোৰ ওচৰত প্ৰমাণপত্ৰ নাই, মেডাম, কিন্তু মোৰ আগৰ কৰ্মস্থানৰ পৰা দিয়া এখন পৰামৰ্শ পত্ৰ আছে।",
            "gu": "મારી પાસે પ્રમાણપત્ર નથી, મેડમ, પરંતુ મારી પાસે મારા અગાઉના કાર્યસ્થળનો ભલામણ પત્ર છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.4.text",
          "value": {
            "en": "Hmm, this is good, Naveen. This helps. We really value ability over any kind of certification.",
            "hi": "हम्म, अच्छी बात है, नवीन। इससे तुम्हें मदद मिलेगी। हम किसी भी प्रमाण पत्र की तुलना में क्षमता को ज्यादा महत्व देते हैं।",
            "ka": "ಹಾಂ ಇದು ಚೆನ್ನಾಗಿದೆ ನವೀನ್. ಇದು ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಯಾವುದೇ ರೀತಿಯ ಪ್ರಮಾಣೀಕರಣಕ್ಕಿಂತ ನಾವು ನಿಜವಾಗಿಯೂ ಸಾಮರ್ಥ್ಯವನ್ನು ಗೌರವಿಸುತ್ತೇವೆ.",
            "te": "హ్మ్, ఇది బాగుంది నవీన్. ఇది సహాయపడుతుంది. మేము ఏ రకమైన ధృవీకరణ కంటే సామర్థ్యానికి నిజంగా విలువిస్తాము.",
            "or": "ହମ୍, ଏହା ଭଲ, ନବୀନ | ଏହା ସାହାଯ୍ୟ କରିବ | ଆମେ ପ୍ରକୃତରେ ଯେକୌଣସି ପ୍ରକାରର ପ୍ରମାଣପତ୍ର ଉପରେ ଦକ୍ଷତାକୁ ଗୁରୁତ୍ୱ ଦେଇଥାଉ |",
            "as": "হুম, এইটো ভাল, নবীন। ই সহায় কৰে। আমি যিকোনো ধৰণৰ প্ৰমাণপত্ৰতকৈ সামৰ্থ্যক বেচিকৈ গুৰুত্ব দিওঁ।",
            "gu": "હા, આ સારું છે, નવીનને આ મદદ કરશે. અમે ખરેખર કોઈપણ પ્રકારના પ્રમાણપત્ર કરતાં ક્ષમતાને મહત્ત્વ આપીએ છીએ."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.5.text",
          "value": {
            "en": "Thank you, ma’am.",
            "hi": "थैंक यू, मैम।",
            "ka": "ಧನ್ಯವಾದಗಳು, ಮೇಡಮ್.",
            "te": "ధన్యవాదాలు, మేడమ్.",
            "or": "ଧନ୍ୟବାଦ, ମ୍ଯାଡାମ |",
            "as": "ধন্যবাদ, মেডাম।",
            "gu": "આભાર, મેડમ."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D9_1_2////",
            "hi": "D9_1_2////",
            "ka": "D9_1_2////",
            "te": "D9_1_2////",
            "or": "D9_1_2////",
            "as": "D9_1_2////",
            "gu": "D9_1_2////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "So have you ever choreographed for dance shows or performances?",
            "hi": "क्या तुमने कभी डांस शो या परफॉर्मेंस के लिए कोरियोग्राफ किया है?",
            "ka": "ಹಾಗಾದರೆ ನೀವು ಎಂದಾದರೂ ನೃತ್ಯ ಪ್ರದರ್ಶನಗಳು ಅಥವಾ ಪ್ರದರ್ಶನಗಳಿಗಾಗಿ ನೃತ್ಯ ಸಂಯೋಜನೆ ಮಾಡಿದ್ದೀರಾ?",
            "te": "కాబట్టి మీరు ఎప్పుడైనా నృత్య ప్రదర్శనలు లేదా ప్రదర్శనల కోసం కొరియోగ్రఫీ చేశారా?",
            "or": "ତେବେ ତୁମେ କେବେ ଡ୍ୟାନ୍ସ ଶୋ କିମ୍ବା ପରିବେଷଣ ପାଇଁ କୋରିଓଗ୍ରାଫ୍ କରିଛ କି ?",
            "as": "তেন্তে তুমি কেতিয়াবা নৃত্য অনুষ্ঠান বা প্ৰদৰ্শনৰ বাবে নৃত্য পৰিচালনা কৰিছা নেকি?",
            "gu": "તો શું તે ક્યારેય ડાન્સ શો કે પરફોર્મન્સ માટે કોરિયોગ્રાફ કામ કર્યું છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Yes, Ma’am, I have choreographed many songs for my dance group, the All-stars.",
            "hi": "हाँ, मैम। मैंने अपने डांस ग्रुप, ऑल-स्टार्स के लिए कई गानों को कोरियोग्राफ किया हुआ है।",
            "ka": "ಹೌದು, ಮೇಡಂ, ನನ್ನ ಡ್ಯಾನ್ಸ್ ಗ್ರೂಪ್, ಆಲ್-ಸ್ಟಾರ್‌ಗಳಿಗಾಗಿ ನಾನು ಅನೇಕ ಹಾಡುಗಳಿಗೆ ನೃತ್ಯ ಸಂಯೋಜನೆ ಮಾಡಿದ್ದೇನೆ.",
            "te": "అవును, మేడమ్, నా డ్యాన్స్ బృందం, ఆల్-స్టార్స్ కోసం నేను చాలా పాటలకు కొరియోగ్రఫీ చేశాను.",
            "or": "ହଁ, ମ୍ଯାଡାମ, ମୁଁ ମୋର ଡ୍ୟାନ୍ସ ଗ୍ରୁପ୍, ଅଲ୍-ଷ୍ଟାର୍ସ ପାଇଁ ଅନେକ ଗୀତ କୋରିଓଗ୍ରାଫ୍ କରିଛି |",
            "as": "হয়, মেডাম, মই মোৰ নৃত্য গোট, অল-ষ্টাৰৰ বাবে বহুতো গান পৰিচালনা কৰিছোঁ।",
            "gu": "હા, મેડમ, મેં મારા ડાન્સ ગ્રુપ, ઓલ-સ્ટાર્સ માટે ઘણા ગીતો કોરિયોગ્રાફ કર્યા છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Here, this is our social media page…",
            "hi": "देखिए, यह हमारा सोशल मीडिया पेज है…",
            "ka": "ಇಲ್ಲಿ, ಇದು ನಮ್ಮ ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಪುಟ..",
            "te": "ఇదిగోండి, ఇది మా సోషల్ మీడియా పేజీ…",
            "or": "ଏଠାରେ, ଏହା ଆମର ସୋସିଆଲ ମିଡିଆ ପେଜ୍‌…",
            "as": "ইয়াত, এইটো আমাৰ সামাজিক মাধ্যম পৃষ্ঠা...",
            "gu": "અહીં, આ અમારું સોશિયલ મીડિયા પેજ છે..."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D9_1_3//",
            "hi": "D9_1_3//",
            "ka": "D9_1_3//",
            "te": "D9_1_3//",
            "or": "D9_1_3//",
            "as": "D9_1_3//",
            "gu": "D9_1_3//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Interviewer",
            "hi": "इंटरव्यूवर",
            "ka": "ಸಂದರ್ಶಕ",
            "te": "ఇంటర్వ్యూయర్",
            "or": "ସାକ୍ଷାତକାରକାରୀ",
            "as": "সাক্ষাৎকাৰ লওঁতা",
            "gu": "ઈન્ટરવ્યુ લેનાર"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Naveen",
            "hi": "नवीन",
            "ka": "ನವೀನ್",
            "te": "నవీన్",
            "or": "ନବୀନ",
            "as": "নবীন",
            "gu": "નવીન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "This is wonderful. I will like to see a demo of some of your steps if that is fine with you.",
            "hi": "यह बहुत अच्छा है। अगर तुम्हें कोई एतराज नहीं है तो मैं तुम्हारे कुछ स्टेप्स का डेमो देखना चाहती हूँ।",
            "ka": "ಇದು ಅದ್ಭುತವಾಗಿದೆ. ನಿಮಗೆ ಸರಿಯಾದರೇ ನಿಮ್ಮ ಕೆಲವು ಹೆಜ್ಜೆಗಳ ಡೆಮೊವನ್ನು ನೋಡಲು ನಾನು ಬಯಸುತ್ತೇನೆ.",
            "te": "ఇది అద్భుతం. మీకు ఓకే అయితే నేను మీ కొన్ని స్టెప్స్ డెమోని చూడాలనుకుంటున్నాను.",
            "or": "ଏହା ଚମତ୍କାର ଅଟେ | ଯଦି ତୁମେ ଠିକ୍‌ ଅଛ ମୁଁ ତୁମର କିଛି ସ୍ଟେପ୍ସର ଏକ ଡେମୋ ଦେଖିବାକୁ ପସନ୍ଦ କରିବି |",
            "as": "এইটো আশ্চৰ্যজনক। মই তোমাৰ কিছুমান পদক্ষেপৰ এটা প্ৰদৰ্শন চাব বিচাৰিম যদিহে তোমাৰ একো সমস্যা নাই।",
            "gu": "આ સરસ છે. જો તે તારી સાથે ઠીક હોય તો હું તમારા કેટલાક સ્ટેસનો ડેમો જોવા માંગીશ."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Definitely. Let me show you some steps I recently choreographed for my group….",
            "hi": "जरूर। मैंने अपने ग्रुप के लिए हाल ही में कोरियोग्राफ किए हुए कुछ स्टेप्स आपको दिखाता हूँ….",
            "ka": "ಖಂಡಿತವಾಗಿ. ನನ್ನ ಗುಂಪಿಗೆ ನಾನು ಇತ್ತೀಚೆಗೆ ನೃತ್ಯ ಸಂಯೋಜನೆ ಮಾಡಿದ ಕೆಲವು ಹಂತಗಳನ್ನು ನಿಮಗೆ ತೋರಿಸುತ್ತೇನೆ….",
            "te": "ఖచ్చితంగా. నా బృందం కోసం నేను ఇటీవల కొరియోగ్రాఫ్ చేసిన కొన్ని స్టెప్స్ మీకు చూపిస్తాను.",
            "or": "ନିଶ୍ଚିତ ଭାବରେ | ମୋର ଗ୍ରୁପ୍‌ ପାଇଁ ମୁଁ କୋରିଓଗ୍ରାଫ୍ କରିଥିବା କିଛି ସ୍ଟେପ୍ସ ଆପଣଙ୍କୁ ଦେଖାଇବାକୁ ଦିଅନ୍ତୁ…",
            "as": "নিশ্চিতভাৱে। মই অলপতে মোৰ গোটৰ বাবে নৃত্য পৰিচালনা কৰা কিছুমান পদক্ষেপ আপোনাক দেখুৱাওঁ...",
            "gu": "ચોક્કસપણે. ચાલો હું તમને મારા જૂથ માટે તાજેતરમાં કોરિયોગ્રાફ કરેલા કેટલાક સ્ટેપ્સ બતાવું…."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D9_1_4////",
            "hi": "D9_1_4////",
            "ka": "D9_1_4////",
            "te": "D9_1_4////",
            "or": "D9_1_4////",
            "as": "D9_1_4////",
            "gu": "D9_1_4////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Excellent, Naveen. I’m really convinced that you are a good fit for the role! Congratulations!",
            "hi": "बहुत बढ़िया, नवीन। अब मुझे पूरा यकीन है कि तुम इस रोल के लिए सही हो! बधाई हो!",
            "ka": "ಅದ್ಭುತ, ನವೀನ್. ನೀವು ಪಾತ್ರಕ್ಕೆ ಸೂಕ್ತರು ಎಂದು ನನಗೆ ಮನವರಿಕೆಯಾಗಿದೆ! ಅಭಿನಂದನೆಗಳು!",
            "te": "అద్భుతం, నవీన్. మీరు ఆ పాత్రకు సరిగ్గా సరిపోతారని నేను నిజంగా నమ్ముతున్నాను! అభినందనలు!",
            "or": "ଉତ୍କୃଷ୍ଟ, ନବୀନ | ମୁଁ ବାସ୍ତବରେ ନିଶ୍ଚିତ ଯେ ତୁମେ ଭୂମିକା ପାଇଁ ଏକ ଭଲ ଫିଟ୍! ଅଭିନନ୍ଦନ!",
            "as": "উৎকৃষ্ট, নবীন। মই সঁচাকৈয়ে নিশ্চিত যে তুমি কামটোৰ বাবে উপযুক্ত! অভিনন্দন!",
            "gu": "ઉત્તમ, નવીની. મને ખાતરી છે કે તને આ ભૂમિકા માટે સારા છો! અભિનંદન!"
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "RecommendationLetter",
            "hi": "रेकमेंडेशनलेटर",
            "ka": "ಶಿಫಾರಸು ಪತ್ರ",
            "te": "సిఫారసు లేఖ",
            "or": "ସୁପାରିଶ ପତ୍ର",
            "as": "পৰামৰ্শপত্ৰ",
            "gu": "ભલામણ પત્ર"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Recommendation Letter experience!",
            "hi": "आपने अभी-अभी रेकमेंडेशन लेटर का अनुभव प्राप्त किया हैं!",
            "ka": "ನೀವು ಇದೀಗ ಶಿಫಾರಸು ಪತ್ರದ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే సిఫారసు లేఖ అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ସୁପାରିଶ ପତ୍ରର ଅଭିଜ୍ଞତା ହାସଲ କରିଛନ୍ତି !",
            "as": "তুমি এইমাত্ৰ পৰামৰ্শ পত্ৰৰ অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "તમે હમણાં જ ભલામણ પત્રનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Recommendation Letter",
            "hi": "रेकमेंडेशनलेटर",
            "ka": "ಶಿಫಾರಸು ಪತ್ರ",
            "te": "సిఫారసు లేఖ",
            "or": "ସୁପାରିଶ ପତ୍ର",
            "as": "পৰামৰ্শ পত্ৰ",
            "gu": "ભલામણ પત્ર"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Understanding Work Environment",
            "hi": "काम के माहौल को समझना",
            "ka": "ಕೆಲಸದ ಪರಿಸರವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು",
            "te": "పని వాతావరణాన్ని అర్థం చేసుకోవడం",
            "or": "କାର୍ଯ୍ୟ ପରିବେଶକୁ ବୁଝିବା |",
            "as": "কামৰ পৰিৱেশ বুজোঁ",
            "gu": "કાર્ય પર્યાવરણને સમજવું"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Vishnu Sir gives a letter of recommendation to Naveen when there is an opening in a dance studio. While interviewing, employers look for the right candidate who can fit into their organizations. A recommendation letter talks about the skills, character, experiences, and achievements of a person. If you have a recommendation from your previous employer, it strengthens your application. Whenever you do an internship or a job, try and get a recommendation letter at the end for future job applications.",
            "hi": "जब डांस स्टूडियो में ओपनिंग होती है तब विष्णु सर नवीन को रेकमेंडेशन लेटर देते हैं। इंटरव्यू लेते समय बॉस ऐसे उम्मीदवार को चुनना चाहते हैं, जो उनकी संस्था के लिए सही साबित हो। रेकमेंडेशन लेटर में आपके कौशल, चरित्र, अनुभव और आप की उपलब्धियों के बारे में लिखा हुआ होता है। अगर आपके पिछले बॉस द्वारा रेकमेंडेशन लेटर दिया जाता है, तो यह आपके एप्लीकेशन को मजबूत बनाता है। आप जब भी कोई इंटर्नशिप या जॉब करें, तब अपने भविष्य के जॉब एप्लीकेशन के लिए रिकमेंडेशन लेटर प्राप्त करने की कोशिश करें।",
            "ka": "ಡ್ಯಾನ್ಸ್ ಸ್ಟುಡಿಯೋದಲ್ಲಿ ತೆರೆಯುವಿಕೆ ಇದ್ದಾಗ ವಿಷ್ಣು ಸರ್ ಅವರು ನವೀನ್‌ಗೆ ಶಿಫಾರಸು ಪತ್ರ ನೀಡುತ್ತಾರೆ.ಸಂದರ್ಶನ ಮಾಡುವಾಗ, ಉದ್ಯೋಗದಾತರು ತಮ್ಮ ಸಂಸ್ಥೆಗಳಿಗೆ ಹೊಂದಿಕೊಳ್ಳುವ ಸರಿಯಾದ ಅಭ್ಯರ್ಥಿಯನ್ನು ಹುಡುಕುತ್ತಾರೆ.ಶಿಫಾರಸು ಪತ್ರವು ವ್ಯಕ್ತಿಯ ಕೌಶಲ್ಯ, ಪಾತ್ರ, ಅನುಭವಗಳು ಮತ್ತು ಸಾಧನೆಗಳ ಬಗ್ಗೆ ಮಾತನಾಡುತ್ತದೆ.ನಿಮ್ಮ ಹಿಂದಿನ ಉದ್ಯೋಗದಾತರಿಂದ ನೀವು ಶಿಫಾರಸುಗಳನ್ನು ಹೊಂದಿದ್ದರೆ, ಅದು ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಬಲಪಡಿಸುತ್ತದೆ. ನೀವು ಇಂಟರ್ನ್‌ಶಿಪ್ ಅಥವಾ ಕೆಲಸವನ್ನು ಮಾಡಿದಾಗಲೆಲ್ಲಾ, ಭವಿಷ್ಯದ ಉದ್ಯೋಗ ಅರ್ಜಿಗಳಿಗಾಗಿ ಕೊನೆಯಲ್ಲಿ ಶಿಫಾರಸು ಪತ್ರವನ್ನು ಪಡೆಯಲು ಪ್ರಯತ್ನಿಸಿ.",
            "te": "ఓ డ్యాన్స్‌ స్టూడియోలో ఓపెనింగ్‌ ఉన్నప్పుడు విష్ణు సర్‌ నవీన్‌కి సిఫారసు లేఖ ఇచ్చాడు. ఇంటర్వ్యూ చేస్తున్నప్పుడు, యజమానులు తమ సంస్థలకు సరిపోయే సరైన అభ్యర్థి కోసం చూస్తారు. సిఫార్సు లేఖ ఒక వ్యక్తి యొక్క నైపుణ్యాలు, పాత్ర, అనుభవాలు మరియు విజయాల గురించి మాట్లాడుతుంది. మీ మునుపటి యజమాని నుండి మీకు సిఫార్సు ఉంటే, అది మీ దరఖాస్తును బలపరుస్తుంది. మీరు ఇంటర్న్‌షిప్ లేదా ఉద్యోగం చేసినప్పుడల్లా, భవిష్యత్తులో ఉద్యోగ దరఖాస్తుల కోసం చివరిలో సిఫార్సు లేఖను పొందడానికి ప్రయత్నించండి.",
            "or": "ଏକ ଡ୍ୟାନ୍ସ ଷ୍ଟୁଡିଓରେ ଯେତେବେଳେ ଏକ ପଦବୀ ଖାଲି ହୁଏ, ବିଷ୍ଣୁ ସାର୍ ନବୀନଙ୍କୁ ଏକ ସୁପାରିଶ ପତ୍ର ଦିଅନ୍ତି | ସାକ୍ଷାତକାର ବେଳେ, ନିଯୁକ୍ତିଦାତାମାନେ ସଠିକ୍ ପ୍ରାର୍ଥୀ ଖୋଜନ୍ତି ଯିଏ ସେମାନଙ୍କ ସଂସ୍ଥାରେ ଫିଟ୍ ହୋଇପାରନ୍ତି | ଏକ ସୁପାରିଶ ପତ୍ରରେ ଜଣେ ବ୍ୟକ୍ତିର ଦକ୍ଷତା, ଚରିତ୍ର, ଅଭିଜ୍ଞତା ଏବଂ ସଫଳତା ବିଷୟରେ କୁହେ | । ଯଦି ଆପଣଙ୍କର ପୂର୍ବ ନିଯୁକ୍ତିଦାତାଙ୍କ ଠାରୁ ଆପଣଙ୍କ ପାଖରେ ଏକ ସୁପାରିଶ ଥାଏ, ଏହା ଆପଣଙ୍କର ଆବେଦନକୁ ମଜବୁତ କରେ | ଯେତେବେଳେ ଆପଣ ଏକ ଇଣ୍ଟର୍ନସିପ୍ କିମ୍ବା ଚାକିରି କରନ୍ତି, ଭବିଷ୍ୟତର ଚାକିରି ଆବେଦନ ପାଇଁ ଶେଷରେ ଏକ ସୁପାରିଶ ପତ୍ର ପାଇବାକୁ ଚେଷ୍ଟା କରନ୍ତୁ |",
            "as": "বিষ্ণু ছাৰে নবীনক এখন পৰামৰ্শ পত্ৰ দিয়ে যেতিয়া এটা নৃত্য ষ্টুডিঅ'ত কাম মুকলি হয়। সাক্ষাৎকাৰ লওঁতে, নিয়োগকৰ্তাই সঠিক প্ৰাৰ্থী বিচাৰে যি তেওঁলোকৰ কৰ্মস্থানত খাপ খাব পাৰে। এখন পৰামৰ্শ পত্ৰত এজন ব্যক্তিৰ দক্ষতা, চৰিত্ৰ, অভিজ্ঞতা আৰু প্ৰাপ্তিৰ বিষয়ে কোৱা হয়। যদি তোমাৰ আগৰ নিয়োগকৰ্তাৰ পৰা পৰামৰ্শ পত্ৰ আছে, ই তোমাৰ আবেদন শক্তিশালী কৰে। যেতিয়াই তুমি ইণ্টাৰ্নশ্বিপ বা চাকৰি কৰা, ভৱিষ্যতৰ চাকৰিৰ আবেদনৰ বাবে শেষত এখন পৰামৰ্শ পত্ৰ লাভ কৰিবলৈ চেষ্টা কৰিবা।",
            "gu": "જ્યારે ડાન્સ સ્ટુડિયો ખુલે છે ત્યારે વિષ્ણુ સર નવીનને ભલામણનો પત્ર આપે છે. ઇન્ટરવ્યુ દરમિયાન, નોકરીદાતાઓ યોગ્ય ઉમેદવારની શોધમાં હોય છે જે તેમની સંસ્થામાં ફિટ થશે. ભલામણ પત્ર વ્યક્તિની કુશળતા, પાત્ર, અનુભવ અને સિદ્ધિઓ વિશે વાત કરે છે. જો તમારી પાસે તમારા અગાઉના એમ્પ્લોયર તરફથી ભલામણ હોય, તો આ તમારી અરજીને મજબૂત બનાવે છે. જ્યારે તમે ઇન્ટર્નશિપ અથવા નોકરી પર જાઓ છો, ત્યારે પ્રયાસ કરો અને અંતે ભાવિ નોકરીની અરજીઓ માટે ભલામણનો પત્ર મેળવો."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D9_2_1",
            "hi": "D9_2_1",
            "ka": "D9_2_1",
            "te": "D9_2_1",
            "or": "D9_2_1",
            "as": "D9_2_1",
            "gu": "D9_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen clears the interview and becomes an assistant instructor at the academy. He enjoys the work as he has to regularly come up with new steps for his students.",
            "hi": "नवीन इंटरव्यू में पास हो जाता है और अकैडमी में असिस्टेंट इंस्ट्रक्टर के तौर पर काम करना शुरू करता है। उसे यहाँ काम करना पसंद है क्योंकि उसे अपने विद्यार्थियों को सिखाने के लिए नए नए स्टेप्स ढूंढने पड़ते हैं।",
            "ka": "ನವೀನ್ ಸಂದರ್ಶನವನ್ನು ತೆರವುಗೊಳಿಸುತ್ತಾನೆ ಮತ್ತು ಅಕಾಡೆಮಿಯಲ್ಲಿ ಸಹಾಯಕ ಬೋಧಕನಾಗುತ್ತಾನೆ. ಅವನು ತಮ್ಮ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ನಿಯಮಿತವಾಗಿ ಹೊಸ ಹೆಜ್ಜೆಗಳೊಂದಿಗೆ ಬರಬೇಕಾಗಿರುವುದರಿಂದ ಅವನು ಕೆಲಸವನ್ನು ಆನಂದಿಸುತ್ತಾನೆ.",
            "te": "నవీన్ ఇంటర్వ్యూను క్లియర్ చేసి అకాడమీలో అసిస్టెంట్ ఇన్‌స్ట్రక్టర్‌గా మారాడు. అతను తన విద్యార్థుల కోసం క్రమం తప్పకుండా కొత్త స్టెప్స్ తో ముందుకు రావాలి కాబట్టి అతను పనిని ఆనందిస్తాడు.",
            "or": "ନବୀନ ସାକ୍ଷାତକାର କ୍ଲିୟର୍‌ କରନ୍ତି ଏବଂ ଏକାଡେମୀରେ ସହକାରୀ ପ୍ରଶିକ୍ଷକ ହୁଅନ୍ତି | ସେ ଏହି କାର୍ଯ୍ୟକୁ ଉପଭୋଗ କରନ୍ତି କାରଣ ତାଙ୍କୁ ନିୟମିତ ଭାବରେ ତାଙ୍କ ଛାତ୍ରମାନଙ୍କ ପାଇଁ ନୂତନ ସ୍ଟେପ୍‌ ଆଣିବାକୁ ପଡେ |",
            "as": "নবীনে সাক্ষাৎকাৰটো সম্পূৰ্ণ কৰে আৰু একাডেমীত সহকাৰী প্ৰশিক্ষক হয়। সি কামটো উপভোগ কৰে কিয়নো সি নিয়মীয়াকৈ তাৰ শিক্ষাৰ্থীসকলৰ বাবে নতুন পদক্ষেপ ল'ব লাগে।",
            "gu": "નવીન ઇન્ટરવ્યુ પાસ કરે છે અને એકેડમીમાં સહાયક ઇન્સ્ટ્રક્ટર બને છે. તે કામનો આનંદ માણે છે કારણ કે તેણે નિયમિતપણે તેના વિદ્યાર્થીઓ માટે નવા પગલાઓ સાથે આવવાનું હોય છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "D9_2_2",
            "hi": "D9_2_2",
            "ka": "D9_2_2",
            "te": "D9_2_2",
            "or": "D9_2_2",
            "as": "D9_2_2",
            "gu": "D9_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "It takes time for him to understand all the different dance forms that his studio offers. His senior instructors are tough on him, but only because they want him to improve.",
            "hi": "उसे स्टूडियो में ऑफर किए जाने वाले सभी अलग-अलग डान्स फार्म को समझने में थोड़ा समय लगता है। उसके सीनियर इन स्ट्रक्चर काफी व्यवहार करते हैं लेकिन यह इसलिए ताकि वह अपने आप में सुधार कर सके।",
            "ka": "ಅವನ ಸ್ಟುಡಿಯೋ ನೀಡುವ ಎಲ್ಲಾ ವಿಭಿನ್ನ ನೃತ್ಯ ಪ್ರಕಾರಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಅವನಿಗೆ ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ. ಅವನ ಹಿರಿಯ ಬೋಧಕರು ಅವನ ಮೇಲೆ ಕಠಿಣರಾಗಿದ್ದಾರೆ, ಆದರೆ ಅವನು ಸುಧಾರಿಸಬೇಕೆಂದು ಅವರು ಬಯಸುತ್ತಾರೆ.",
            "te": "అతని స్టూడియో అందించే అన్ని విభిన్న నృత్య రూపాలను అర్థం చేసుకోవడానికి అతనికి సమయం పడుతుంది. అతని సీనియర్ ఇన్‌స్ట్రక్టర్‌లు అతని పట్ల కఠినంగా ఉంటారు, కానీ అతను మెరుగుపడాలని వారు కోరుకున్నారు",
            "or": "ତାଙ୍କ ଷ୍ଟୁଡିଓ ପ୍ରଦାନ କରୁଥିବା ସମସ୍ତ ଭିନ୍ନ ନୃତ୍ୟ ଫର୍ମ ବୁଝିବା ପାଇଁ ତାଙ୍କ ପାଇଁ ସମୟ ଲାଗେ | ତାଙ୍କର ସିନିୟର ପ୍ରଶିକ୍ଷକମାନେ ତାଙ୍କ ଉପରେ କଠୋର, କିନ୍ତୁ କେବଳ ସେମାନେ ଚାହୁଁଛନ୍ତି ଯେ ସେ ଉନ୍ନତି କରନ୍ତୁ |",
            "as": "তাৰ ষ্টুডিঅ'ই আগবঢ়োৱা বিভিন্ন সকলো বোৰ নৃত্যৰচনা বুজিবলৈ তাৰ সময় লাগে। তাৰ জ্যেষ্ঠ প্ৰশিক্ষকসকল তাৰ প্ৰতি কঠোৰ, কাৰণ তেওঁলোকে তাক উন্নত কৰিব বিচাৰে।",
            "gu": "તેણીનો સ્ટુડિયો ઓફર કરે છે તે તમામ વિવિધ નૃત્ય સ્વરૂપોને સમજવા માટે તેણીને સમયની જરૂર છે. તેમના વરિષ્ઠ પ્રશિક્ષકો તેમના માટે સખત છે, પરંતુ માત્ર એટલા માટે કે તેઓ તેમને સુધારવા માંગે છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "D9_2_3",
            "hi": "D9_2_3",
            "ka": "D9_2_3",
            "te": "D9_2_3",
            "or": "D9_2_3",
            "as": "D9_2_3",
            "gu": "D9_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "In his first few months, he mostly had to assist his seniors and help out with smaller tasks like arranging the equipment etc.",
            "hi": "पहले कुछ महीनों में, वह केवल अपने सीनियर्स की सहायता करता था और उपकरण अरेंज करने जैसे छोटे-मोटे काम किया करता था।",
            "ka": "ಅವನ ಮೊದಲ ಕೆಲವು ತಿಂಗಳುಗಳಲ್ಲಿ, ಅವನು ಹೆಚ್ಚಾಗಿ ತಮ್ಮ ಹಿರಿಯರಿಗೆ ಸಹಾಯ ಮಾಡಬೇಕಾಗಿತ್ತು ಮತ್ತು ಸಲಕರಣೆಗಳನ್ನು ಜೋಡಿಸುವುದು ಮುಂತಾದ ಸಣ್ಣ ಕೆಲಸಗಳಲ್ಲಿ ಸಹಾಯ ಮಾಡಬೇಕಾಗಿತ್ತು.",
            "te": "అతని మొదటి కొన్ని నెలల్లో, అతను ఎక్కువగా తన సీనియర్‌లకు సహాయం చేయాల్సి వచ్చింది మరియు పరికరాలను అమర్చడం వంటి చిన్న పనులలో సహాయం చేయాల్సి వచ్చింది.",
            "or": "ତାଙ୍କର ପ୍ରଥମ କିଛି ମାସରେ, ତାଙ୍କୁ ପ୍ରାୟତ ତାଙ୍କ ସିନିୟରମାନଙ୍କୁ ସାହାଯ୍ୟ କରିବାକୁ ପଡୁଥିଲା ଏବଂ ଯନ୍ତ୍ରାଂଶଗୁଡ଼ିକର ବ୍ୟବସ୍ଥା କରିବା ଭଳି ଛୋଟ କାର୍ଯ୍ୟରେ ସାହାଯ୍ୟ କରିବାକୁ ପଡୁଥିଲା |",
            "as": "তাৰ প্ৰথম কেইমাহমানত, সি বেছিভাগ তাৰ জ্যেষ্ঠসকলক সহায় কৰিব লগা হৈছিল আৰু সঁজুলি সজোৱা আদিৰ দৰে সৰু কাম কৰিব লগা হৈছিল।",
            "gu": "તેના પ્રથમ થોડા મહિનામાં, તેણે મોટાભાગે તેના વરિષ્ઠોને મદદ કરવી પડી હતી અને સાધનોની ગોઠવણી વગેરે જેવા નાના કાર્યોમાં મદદ કરવી પડી હતી."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "D9_3_1",
            "hi": "D9_3_1",
            "ka": "D9_3_1",
            "te": "D9_3_1",
            "or": "D9_3_1",
            "as": "D9_3_1",
            "gu": "D9_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "One day he is asked to lead a class of junior students. Naveen realises that teaching helps him improve his own skills as well.",
            "hi": "1 दिन उससे जूनियर स्टूडेंट्स के क्लास को लीड करने के लिए कहा गया। नवीन को एहसास हुआ कि सीखने की वजह से उसके कौशल बहुत ही अच्छा हो गया है।",
            "ka": "ಒಂದು ದಿನ ಜೂನಿಯರ್ ವಿದ್ಯಾರ್ಥಿಗಳ ವರ್ಗವನ್ನು ಮುನ್ನಡೆಸಲು ಅವನನ್ನು ಕೇಳಲಾಗುತ್ತದೆ. ಬೋಧನೆಯು ತನ್ನ ಸ್ವಂತ ಕೌಶಲ್ಯಗಳನ್ನು ಸುಧಾರಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ ಎಂದು ನವೀನ್ ಅರಿತುಕೊಂಡನು.",
            "te": "ఒకరోజు జూనియర్ విద్యార్థుల తరగతికి నాయకత్వం వహించమని అడిగారు. బోధన తన నైపుణ్యాలను మెరుగుపరుచుకోవడంలో సహాయపడుతుందని నవీన్ గ్రహించాడు.",
            "or": "ଦିନେ ତାଙ୍କୁ କନିଷ୍ଠ ଛାତ୍ରମାନଙ୍କର ଏକ ଶ୍ରେଣୀର ନେତୃତ୍ୱ ନେବାକୁ କୁହାଯାଏ | ନବୀନ ହୃଦୟଙ୍ଗମ କରନ୍ତି ଯେ ଶିକ୍ଷାଦାନ ତାଙ୍କୁ ନିଜର ଦକ୍ଷତାକୁ ମଧ୍ୟ ଉନ୍ନତ କରିବାରେ ସାହାଯ୍ୟ କରେ |",
            "as": "এদিন তেওঁক কনিষ্ঠ শিক্ষাৰ্থীৰ এটা শ্ৰেণীৰ নেতৃত্ব দিবলৈ কোৱা হয়। নবীনে উপলব্ধি কৰে যে শিক্ষাদানে তাক নিজৰ দক্ষতা উন্নত কৰাত সহায় কৰে।",
            "gu": "એક દિવસ તેને જુનિયર વિદ્યાર્થી વર્ગનું નેતૃત્વ કરવાનું કહેવામાં આવે છે. નવીન સમજે છે કે તાલીમ તેને તેની પોતાની કુશળતા સુધારવામાં પણ મદદ કરે છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "D9_3_2",
            "hi": "D9_3_2",
            "ka": "D9_3_2",
            "te": "D9_3_2",
            "or": "D9_3_2",
            "as": "D9_3_2",
            "gu": "D9_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Over time he becomes one of the lead instructors and starts getting paid decently.",
            "hi": "कुछ समय बाद वह भी लीड इंस्ट्रक्टर हो जाता है और उसे अच्छी सैलरी भी मिलती है।",
            "ka": "ಕಾಲಾನಂತರದಲ್ಲಿ ಅವನು ಪ್ರಮುಖ ಬೋಧಕರಲ್ಲಿ ಒಬ್ಬರಾಗುತ್ತಾನೆ ಮತ್ತು ಯೋಗ್ಯವಾಗಿ ಪಾವತಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ.",
            "te": "కాలక్రమేణా అతను ప్రధాన ఇంస్ట్రక్టర్లతో ఒకడు అయ్యాడు మరియు మంచి జీతం పొందడం ప్రారంభిస్తాడు.",
            "or": "ସମୟ ସହିତ ସେ ଅଗ୍ରଣୀ ପ୍ରଶିକ୍ଷକମାନଙ୍କ ମଧ୍ୟରୁ ଜଣେ ହୁଅନ୍ତି ଏବଂ ଉପଯୁକ୍ତ ଭାବରେ ଦରମା ପାଇବା ଆରମ୍ଭ କରନ୍ତି |",
            "as": "সময়ৰ লগে লগে সি এজন মুখ্য প্ৰশিক্ষক হৈ পৰে আৰু ভালদৰে দৰমহা পাবলৈ আৰম্ভ কৰে।",
            "gu": "સમય જતાં તે અગ્રણી પ્રશિક્ષકોમાંનો એક બની જાય છે અને સન્માન સાથે પગાર મેળવવાનું શરૂ કરે છે."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Dance Instructor",
            "hi": "डांस इंस्ट्रक्टर",
            "ka": "**THREAD_END**_ನೃತ್ಯ ತರಬೇತುದಾರ",
            "te": "**THREAD_END**_నృత్య శిక్షకుడు",
            "or": "**THREAD_END**_Dance Instructor",
            "as": "**থ্ৰেড_সমাপ্ত**_নৃত্য প্ৰশিক্ষক",
            "gu": "** THREAD_END ** _ નૃત્ય પ્રશિક્ષક"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Naveen that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने नवीन के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँचा है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकता है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n  ನೀವು ನವೀನ್‌ಗಾಗಿ ಮಾಡಿರುವ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు నవీన్ కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ !\n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି !\n \n ଆପଣ ନବୀନଙ୍କ ପାଇଁ ବାଛିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତି ଘଟାଇଛି | ଆପଣ କରୁଥିବା ପସନ୍ଦ ଉପରେ ଆଧାର କରି ଭିନ୍ନ ଭିନ୍ନ ସମାପ୍ତି ଅଛି | ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକୁ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ?",
            "as": "অভিনন্দন! তুমি এটা যাত্ৰা সমাপ্ত কৰিছা ৷ তুমি নবীনৰ বাবে কৰা বাছনিবোৰৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত ভিত্তি কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ চাব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક મુસાફરી પૂરી કરી છે!\n\nતમે નવીન માટે પસંદગીઓ કરી છે જે આ સંભવિત અંત તરફ દોરી ગઈ છે. તમે જે પસંદગી કરી છે તેનાથી અલગ અલગ અંત છે. શું તમે બીજા દ્રશ્યો ખોલવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମ୍ଯାପ୍‍ ଉପରେ ଏକ ନଜର ପକାନ୍ତୁ |",
            "as": "মানচিত্ৰখনৰ পিনে চোৱা",
            "gu": "ચાલો નકશા પર એક નજર નાખીએ"
          }
        }
      ]
    },
    "scene34": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D10_1_1_Perform with the group",
            "hi": "ग्रुप के साथ परफॉर्म करना",
            "ka": "D10_1_1_ಗುಂಪಿನೊಂದಿಗೆ ಪ್ರದರ್ಶನ ನೀಡಿ",
            "te": "D10_1_1_బృందం తో ప్రదర్శన చేయడం",
            "or": "D10_1_1_Perform with the group",
            "as": "D10_1_1_গোটৰ সৈতে প্ৰদৰ্শন",
            "gu": "D10_1_1_જૂથ સાથે અભિનય કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Naveen, Zaid, Omi and the rest of their group have choreographed a special performance for the big concert at the college fest.",
            "hi": "नवीन, जाएद, ओमी और उनका पूरा ग्रुप कॉलेज फेस्ट में एक बड़े विशेष परफॉर्मेंस के लिए कोरियोग्राफी करता है",
            "ka": "ನವೀನ್, ಜೈದ್, ಓಮಿ ಮತ್ತು ಅವರ ತಂಡದ ಉಳಿದವರು ಕಾಲೇಜು ಫೆಸ್ಟ್‌ನಲ್ಲಿ ದೊಡ್ಡ ಸಂಗೀತ ಕಚೇರಿಗೆ ವಿಶೇಷ ಪ್ರದರ್ಶನವನ್ನು ನೀಡಿದ್ದಾರೆ.",
            "te": "నవీన్, జైద్, ఓమి మరియు వారి బృందం లోని మిగిలిన వారు కళాశాల ఫెస్ట్ లో పెద్ద కచేరీ కోసం ప్రత్యేక ప్రదర్శన అందించారు.",
            "or": "ନବୀନ, ଜାଏଦ୍‌, ଓମି ଏବଂ ସେମାନଙ୍କ ଗ୍ରୁପ୍‌ର ଅନ୍ୟମାନେ କଲେଜ ଫେଷ୍ଟରେ ବଡ କନ୍ସର୍ଟ ପାଇଁ ଏକ ବିଶେଷ ପରିବେଷଣ କୋରିଓଗ୍ରାଫ୍ କରିଛନ୍ତି |",
            "as": "নবীন, জাইদ, অমি আৰু তেওঁলোকৰ গোটৰ বাকী সকলে মহাবিদ্যালয় উৎসৱত ডাঙৰ সংগীতানুষ্ঠানৰ বাবে এক বিশেষ প্ৰদৰ্শন পৰিচালনা কৰিছে।",
            "gu": "નવીન, ઝાઈદ, ઓમી અને તેમના જૂથના બાકીના લોકોએ કૉલેજના ઉત્સવમાં મોટા કોન્સર્ટ માટે ખાસ અભિનય કોરિયોગ્રાફ કર્યું છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D10_1_2////",
            "hi": "D10_1_2////",
            "ka": "D10_1_2////",
            "te": "D10_1_2////",
            "or": "D10_1_2////",
            "as": "D10_1_2////",
            "gu": "D10_1_2////"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Before the event, Naveen gets them together.",
            "hi": "इवेंट से पहले, नवीन सब को एक साथ बुलाता है।",
            "ka": "ಕಾರ್ಯಕ್ರಮದ ಮೊದಲು, ನವೀನ್ ಅವರನ್ನು ಒಟ್ಟಿಗೆ ಸೇರಿಸುತ್ತಾರೆ.",
            "te": "ఈవెంట్‌కు ముందు, నవీన్ వారిని కలుసుకున్నాడు.",
            "or": "ଇଭେଣ୍ଟ ପୂର୍ବରୁ, ନବୀନ ସେମାନଙ୍କୁ ଏକାଠି କରନ୍ତି |",
            "as": "অনুষ্ঠানটোৰ আগতে, নবীনে তেওঁলোকক একত্ৰিত কৰে।",
            "gu": "ઇવેન્ટ પહેલાં, નવીન તેમને એકસાથે મેળવે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Guys, we have been dreaming of this day for such a long time. We will finally be performing on a stage in front of students from colleges from Bangalore!",
            "hi": "दोस्तों, हम कितने दिनों से इस मौके की राह देख रहे थे। आज हम बेंगलुरु के कॉलेज के विद्यार्थियों के सामने स्टेज पर परफॉर्म करने वाले हैं!",
            "ka": "ಗೆಳೆಯರೇ, ನಾವು ಈ ದಿನದ ಬಗ್ಗೆ ಬಹಳ ದಿನಗಳಿಂದ ಕನಸು ಕಾಣುತ್ತಿದ್ದೇವೆ. ನಾವು ಅಂತಿಮವಾಗಿ ಬೆಂಗಳೂರಿನ ಕಾಲೇಜುಗಳ ವಿದ್ಯಾರ್ಥಿಗಳ ಮುಂದೆ ವೇದಿಕೆಯಲ್ಲಿ ಪ್ರದರ್ಶನ ನೀಡುತ್ತೇವೆ!",
            "te": "గయ్స్, మనం ఈ రోజు కోసం చాలా కాలంగా కలలు కంటున్నాము. మనం చివరకు బెంగళూరు నుండి కళాశాల నుండి విద్యార్థుల ముందు ఒక వేదికపై ప్రదర్శన చేస్తున్నాము!",
            "or": "ବନ୍ଧୁଗଣ, ଆମେ ଏତେ ଦିନ ଧରି ଏହି ଦିନର ସ୍ୱପ୍ନ ଦେଖୁଛୁ | ଶେଷରେ ଆମେ ବାଙ୍ଗାଲୋରର କଲେଜଗୁଡ଼ିକର ଛାତ୍ରମାନଙ୍କ ସାମ୍ନାରେ ଏକ ମଞ୍ଚରେ ପରିବେଷଣ କରିବା !",
            "as": "বন্ধুসকল, আমি ইমান দীঘলীয়া সময়ধৰি এই দিনটোৰ বাবে সপোন দেখি আছিলো। আমি অৱশেষত বাংগালোৰৰ মহাবিদ্যালয়ৰ শিক্ষাৰ্থীসকলৰ সন্মুখত এখন মঞ্চত প্ৰদৰ্শন কৰিম!",
            "gu": "મિત્રો, આપણે આ દિવસનું સપનું જોઈ રહ્યા હતા ગણા લાંબા સમયથી. આપણે આખરે બેંગ્લોરની કોલેજોના વિદ્યાર્થીઓની સામે સ્ટેજ પર અભિનય કરીશું!"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Let’s show them what the All-Stars are made of!",
            "hi": "हम उन्हें दिखा देंगे कि ऑल स्टार्स क्या चीज है!",
            "ka": "ಆಲ್-ಸ್ಟಾರ್‌ಗಳು ಯಾವುದರಿಂದ ಮಾಡಲ್ಪಟ್ಟಿವೆ ಎಂಬುದನ್ನು ಅವರಿಗೆ ತೋರಿಸೋಣ!",
            "te": "ఆల్-స్టార్స్‌ను అంటే ఎలా ఉంటుందో వారికి చూపిద్దాం!",
            "or": "ଆସ ସେମାନଙ୍କୁ ଦେଖାଇବା ଅଲ-ଷ୍ଟାର୍ସ କେଉଁଥିରେ ତିଆରି ହୋଇଛି !",
            "as": "তেওঁলোকক দেখুৱাওঁ আহা অল-ষ্টাৰ কিহৰ দ্বাৰা বনিছে !",
            "gu": "ચાલો તેમને બતાવીએ કે ઓલ-સ્ટાર શેના બનેલા છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Yes! Let's set the stage on fire today!",
            "hi": "हाँ! चलो तेज पर आज धूम मचा देते हैं!",
            "ka": "ಹೌದು! ಇಂದು ವೇದಿಕೆಯಲ್ಲಿ ಬೆಂಕಿ ಅಂತಹ ಪ್ರದರ್ಶನ ನೀಡೋಣ",
            "te": "అవును! ఈరోజు స్టేజ్ పై అదరగొడదాం!",
            "or": "ହଁ! ଆସ ଆଜି ମଞ୍ଚରେ ନିଆଁ ଲଗାଇବା !",
            "as": "হয়! আজি মঞ্চত জুই লগাই দিম আহা!",
            "gu": "હા! ચાલો આજે સ્ટેજ ઉપર બતાવીએ!"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "It’s an opportunity we might never get again. Let's do our best.",
            "hi": "हमें ऐसा मौका शायद फिर से ना मिले। इसलिए हमें आज अपना बेस्ट परफॉर्मर्स देना होगा।",
            "ka": "ಇದು ನಮಗೆ ಮತ್ತೆ ಸಿಗದ ಅವಕಾಶ. ನಮ್ಮ ಕೈಲಾದಷ್ಟು ಮಾಡೋಣ.",
            "te": "ఇది మనం మళ్లీ పొందలేని అవకాశం. మన బెస్ట్ ఇవ్వాలి.",
            "or": "ଏହା ଏକ ସୁଯୋଗ ଆମେ କେବେ ଆଉ ପାଇପାରିବା ନାହିଁ | ଚାଲ ଆମର ସର୍ବୋତ୍ତମ କରିବା |",
            "as": "এইটো এনে এটা সুযোগ যিটো আমি আকৌ নাপাবও পাৰো। আহক আমাৰ সৰ্বশ্ৰেষ্ঠ উজাৰি দিওঁ।",
            "gu": "આ એક એવી તક છે જે આપણને કદાચ ફરી ક્યારેય ન મળે. ચાલો આપણા શ્રેષ્ઠ પ્રયાસો કરીએ."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D10_2_1",
            "hi": "D10_2_1",
            "ka": "D10_2_1",
            "te": "D10_2_1",
            "or": "D10_2_1",
            "as": "D10_2_1",
            "gu": "D10_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "When they go up on stage, they are welcomed by the audience with loud screams and claps. They already have many fans in the crowd.",
            "hi": "जब वे स्टेज पर चाहते हैं, तब प्रेक्षक जोर से चिल्लाकर और तालियां बजाकर उनका स्वागत करते हैं। भीड़ में पहले से ही उनके बहुत से फैन हैं।",
            "ka": "ಅವರು ವೇದಿಕೆಯ ಮೇಲೆ ಹೋದಾಗ, ಅವರನ್ನು ಪ್ರೇಕ್ಷಕರು ಜೋರಾಗಿ ಕಿರುಚುತ್ತಾ ಚಪ್ಪಾಳೆ ತಟ್ಟಿ ಸ್ವಾಗತಿಸುತ್ತಾರೆ. ಅವರು ಈಗಾಗಲೇ ಗುಂಪಿನಲ್ಲಿ ಸಾಕಷ್ಟು ಅಭಿಮಾನಿಗಳನ್ನು ಹೊಂದಿದ್ದಾರೆ.",
            "te": "వేదిక పైకి వెళ్లగానే ప్రేక్షకులు పెద్ద పెద్దగా కేకలు, చప్పట్లతో స్వాగతం పలికారు. వీరికి ఇప్పటికే చాలా మంది అభిమానులు ఉన్నారు.",
            "or": "ଯେତେବେଳେ ସେମାନେ ମଞ୍ଚ ଉପରକୁ ଯାଆନ୍ତି, ଦର୍ଶକମାନେ ଉଚ୍ଚ ସ୍ୱରରେ ଚିତ୍କାର କରି କରତାଳି ଦେଇ ସେମାନଙ୍କ ସ୍ୱାଗତ କରନ୍ତି | ଭିଡ଼ରେ ସେମାନଙ୍କର ପୂର୍ବରୁ ଅନେକ ପ୍ରଶଂସକ ଅଛନ୍ତି |",
            "as": "যেতিয়া তেওঁলোক মঞ্চলৈ উঠি যায়, তেওঁলোকক দৰ্শকে জোৰেৰে চিঞৰি আৰু হাততালিৰে আদৰণি জনায়। তেওঁলোকৰ ইতিমধ্যে ভিৰৰ মাজত বহুতো অনুৰাগী আছিল।",
            "gu": "સ્ટેજ પર પ્રવેશ્યા પછી, પ્રેક્ષકોનું જોરથી ચીસો અને તાળીઓના ગડગડાટથી સ્વાગત કરવામાં આવે છે. ભીડમાં તેમના પહેલાથી જ ઘણા ચાહકો છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D10_2_2///",
            "hi": "D10_2_2///",
            "ka": "D10_2_2///",
            "te": "D10_2_2///",
            "or": "D10_2_2///",
            "as": "D10_2_2///",
            "gu": "D10_2_2///"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "They perform for 20 minutes. The audience is very excited seeing the complexity of their dance that they perform with such ease.",
            "hi": "वे 20 मिनट तक परफॉर्म करते हैं। कठिन स्टेप्स को इतनी सहजता से करते हुए दे स्टूडेंट्स बहुत खुश हो जाते हैं।",
            "ka": "ಅವರು 20 ನಿಮಿಷಗಳ ಕಾಲ ಪ್ರದರ್ಶನ ನೀಡುತ್ತಾರೆ. ಅವರ ನೃತ್ಯದ ಸಂಕೀರ್ಣತೆಯನ್ನು ನೋಡಿ ಪ್ರೇಕ್ಷಕರು ತುಂಬಾ ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ.",
            "te": "వారు 20 నిమిషాల పాటు ప్రదర్శిస్తారు. వారు చాలా సులభంగా ప్రదర్శించే వారి డ్యాన్స్‌లోని సంక్లిష్టతను చూసి ప్రేక్షకులు చాలా సంతోషిస్తున్నారు.",
            "or": "ସେମାନେ 20 ମିନିଟ୍ ପାଇଁ ପରିବେଷଣ କରନ୍ତି | ସେମାନଙ୍କର ନୃତ୍ୟର ଜଟିଳତାକୁ ଦେଖି ଦର୍ଶକମାନେ ବହୁତ ଉତ୍ସାହିତ ହୁଅନ୍ତି ଯେହେତୁ ସେମାନେ ଏତେ ସହଜରେ ପରିବେଷଣ କରନ୍ତି |",
            "as": "তেওঁলোকে 20 মিনিটৰ বাবে প্ৰদৰ্শন কৰে। দৰ্শকসকলে তেওঁলোকৰ নৃত্যৰ জটিলতা যিটো তেওঁলোকে ইমান সহজে প্ৰদৰ্শন কৰিলে দেখি অতি উৎসাহিত হয়।",
            "gu": "તેઓ 20 મિનિટ સુધી રમે છે. પ્રેક્ષકો તેમના નૃત્યની જટિલતાને જોઈને રોમાંચિત થાય છે, જે તેઓ આટલી સરળતાથી કરે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D10_2_3",
            "hi": "D10_2_3",
            "ka": "D10_2_3",
            "te": "D10_2_3",
            "or": "D10_2_3",
            "as": "D10_2_3",
            "gu": "D10_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Their social media page gains over 20000 new followers.",
            "hi": "उनके सोशल मीडिया फेस पर 20000 ने फॉलोअर्स जुड़ जाते हैं।",
            "ka": "ಅವರ ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಪುಟವು 20000 ಹೊಸ ಅನುಯಾಯಿಗಳನ್ನು ಪಡೆಯುತ್ತದೆ.",
            "te": "వారి సోషల్ మీడియా పేజీకి 20000 మంది కొత్త ఫాలోవర్లు వచ్చారు.",
            "or": "ସେମାନଙ୍କର ସୋସିଆଲ ମିଡିଆ ପେଜ୍‌ 20000 ରୁ ଅଧିକ ନୂତନ ଅନୁଗାମୀ ଲାଭ କରେ |",
            "as": "তেওঁলোকৰ সামাজিক মাধ্যমৰ পৃষ্ঠাই ২০০০০ তকৈও অধিক নতুন অনুসৰণকাৰী লাভ কৰিলে।",
            "gu": "તેઓનું સોશિયલ મીડિયા પેજ પર 20,000 થી વધુ નવા ફોલોઅર્સ મેળવી રહ્યું છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "D10_2_4",
            "hi": "D10_2_4",
            "ka": "D10_2_4",
            "te": "D10_2_4",
            "or": "D10_2_4",
            "as": "D10_2_4",
            "gu": "D10_2_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Their performance gets a lot of praise, and they start getting enquiries about dance workshops from the colleges in Bangalore.",
            "hi": "उनके परफॉर्मेंस की बहुत तारीफ होती है और बेंगलुरु के कई कॉलेज इससे उन्हें डांस के वर्कशॉप के लिए आने शुरू होते हैं।",
            "ka": "ಅವರ ಅಭಿನಯವು ಬಹಳಷ್ಟು ಪ್ರಶಂಸೆಗಳನ್ನು ಪಡೆಯುತ್ತದೆ ಮತ್ತು ಅವರು ಬೆಂಗಳೂರಿನ ಕಾಲೇಜುಗಳಿಂದ ನೃತ್ಯ ಕಾರ್ಯಾಗಾರಗಳ ಬಗ್ಗೆ ವಿಚಾರಣೆಗಳನ್ನು ಪಡೆಯಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ.",
            "te": "వారి ప్రదర్శనకు చాలా ప్రశంసలు లభిస్తాయి మరియు వారు బెంగుళూరులోని కళాశాలల నుండి డ్యాన్స్ వర్క్‌షాప్‌ల గురించి విచారణలు పొందడం ప్రారంభిస్తారు.",
            "or": "ସେମାନଙ୍କର ପରିବେଷଣ ଅନେକ ପ୍ରଶଂସା ପାଇଥାଏ, ଏବଂ ସେମାନେ ବାଙ୍ଗାଲୋରର କଲେଜଗୁଡ଼ିକରୁ ନୃତ୍ୟ କର୍ମଶାଳା ବିଷୟରେ ଅନୁସନ୍ଧାନ ପାଇବା ଆରମ୍ଭ କରନ୍ତି |",
            "as": "তেওঁলোকৰ প্ৰদৰ্শনে যথেষ্ট প্ৰশংসা লাভ কৰে, আৰু তেওঁলোকে বাংগালোৰৰ মহাবিদ্যালয়সমূহৰ পৰা নৃত্য কৰ্মশালাৰ বাবে খবৰ পাবলৈ আৰম্ভ কৰে।",
            "gu": "તેમનું પ્રદર્શન લોકપ્રિયતા મેળવી રહ્યું છે અને તેઓને બેંગલોરની કોલેજોમાંથી ડાન્સ વર્કશોપ વિશે પ્રશ્નો મળવા લાગ્યા છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "D10_3_1",
            "hi": "D10_3_1",
            "ka": "D10_3_1",
            "te": "D10_3_1",
            "or": "D10_3_1",
            "as": "D10_3_1",
            "gu": "D10_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Soon the group starts conducting dance workshops and summer camps in colleges where they teach people the basics of hip hop.",
            "hi": "जल्द ही उनका ग्रुप कॉलेजों में डांस के वर्कशॉप और समर कैंप आयोजित करने लगता है जहाँ पर वे लोगों को बेसिक हिप हॉप सिखाते हैं।",
            "ka": "ಶೀಘ್ರದಲ್ಲೇ ಗುಂಪು ಕಾಲೇಜುಗಳಲ್ಲಿ ನೃತ್ಯ ಕಾರ್ಯಾಗಾರಗಳು ಮತ್ತು ಬೇಸಿಗೆ ಶಿಬಿರಗಳನ್ನು ನಡೆಸಲು ಪ್ರಾರಂಭಿಸುತ್ತದೆ, ಅಲ್ಲಿ ಅವರು ಹಿಪ್ ಹಾಪ್ನ ಮೂಲಭೂತ ಅಂಶಗಳನ್ನು ಜನರಿಗೆ ಕಲಿಸುತ್ತಾರೆ.",
            "te": "త్వరలో ఈ బృందం కళాశాలల్లో డ్యాన్స్ వర్క్‌షాప్‌లు మరియు వేసవి శిబిరాలను నిర్వహించడం ప్రారంభిస్తుంది, అక్కడ వారు హిప్ హాప్ యొక్క ప్రాథమికాలను ప్రజలకు బోధిస్తారు.",
            "or": "ଖୁବ୍ ଶୀଘ୍ର ଗ୍ରୁପ୍‍ କଲେଜଗୁଡିକରେ ନୃତ୍ୟ କର୍ମଶାଳା ଏବଂ ଗ୍ରୀଷ୍ମ ଶିବିର କରିବା ଆରମ୍ଭ କରେ ଯେଉଁଠାରେ ସେମାନେ ଲୋକମାନଙ୍କୁ ହିପ୍ ହପ୍ ର ମୌଳିକ ଶିକ୍ଷା ଦିଅନ୍ତି |",
            "as": "সোনকালেই দলটোৱে মহাবিদ্যালয়সমূহত নৃত্য কৰ্মশালা আৰু গ্ৰীষ্মকালীন শিবিৰ অনুষ্ঠিত কৰিবলৈ আৰম্ভ কৰে য'ত তেওঁলোকে মানুহক হিপ হপৰ মৌলিক বিষয়বোৰ শিকায়।",
            "gu": "ટૂંક સમયમાં જ જૂથ કોલેજોમાં ડાન્સ વર્કશોપ અને સમર કેમ્પ યોજવાનું શરૂ કરે છે જ્યાં તેઓ લોકોને હિપ હોપની મૂળભૂત બાબતો શીખવે છે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "D10_3_2",
            "hi": "D10_3_2",
            "ka": "D10_3_2",
            "te": "D10_3_2",
            "or": "D10_3_2",
            "as": "D10_3_2",
            "gu": "D10_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The income from workshops helps them to sustain their performances.",
            "hi": "वर्कशॉप से मिलने वाले इन्कम के कारण वे अपना परफॉर्मेंस जारी रख सकते हैं।",
            "ka": "ಕಾರ್ಯಾಗಾರಗಳಿಂದ ಬರುವ ಆದಾಯವು ಅವರ ಕಾರ್ಯಕ್ಷಮತೆಯನ್ನು ಉಳಿಸಿಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
            "te": "వర్క్‌షాప్ నుండి వచ్చే ఆదాయం వారి పనితీరును నిలబెట్టుకోవడానికి సహాయపడుతుంది.",
            "or": "କର୍ମଶାଳାରୁ ଆୟ ସେମାନଙ୍କୁ ସେମାନଙ୍କର ପରିବେଷଣ ବଜାୟ ରଖିବାରେ ସାହାଯ୍ୟ କରେ |",
            "as": "কৰ্মশালাৰ পৰা পোৱা উপাৰ্জনে তেওঁলোকক তেওঁলোকৰ প্ৰদৰ্শন বজাই ৰখাত সহায় কৰে।",
            "gu": "વર્કશોપની આવક તેમને તેમના કામને જાળવી રાખવામાં મદદ કરે છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "D10_4_1",
            "hi": "D10_4_1",
            "ka": "D10_4_1",
            "te": "D10_4_1",
            "or": "D10_4_1",
            "as": "D10_4_1",
            "gu": "D10_4_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "After a couple of years of juggling between workshops and event performances, the group set up their own dance studio under The All-Star dance academy.",
            "hi": "कुछ वर्षों तक वर्कशॉप और इवेंट परफॉर्म पर मेहनत लेने के बाद आखिरकार ग्रुप उनका अपना डांस स्टूडियो शुरू करता है जिसका नाम होता है ऑल स्टार्स डांस अकैडमी।",
            "ka": "ಕಾರ್ಯಾಗಾರಗಳು ಮತ್ತು ಈವೆಂಟ್ ಪ್ರದರ್ಶನಗಳ ನಡುವೆ ಒಂದೆರಡು ವರ್ಷಗಳ ಕುಶಲತೆಯ ನಂತರ, ಗುಂಪು ದಿ ಆಲ್-ಸ್ಟಾರ್ ಡ್ಯಾನ್ಸ್ ಅಕಾಡೆಮಿ ಅಡಿಯಲ್ಲಿ ತಮ್ಮದೇ ಆದ ನೃತ್ಯ ಸ್ಟುಡಿಯೊವನ್ನು ಸ್ಥಾಪಿಸಿತು.",
            "te": "వర్క్‌షాప్‌లు మరియు ఈవెంట్ ప్రదర్శనల మధ్య కొన్ని సంవత్సరాల గారడీ తర్వాత, సమూహం ది ఆల్-స్టార్ డ్యాన్స్ అకాడమీ క్రింద వారి స్వంత డ్యాన్స్ స్టూడియోను ఏర్పాటు చేసింది.",
            "or": "କର୍ମଶାଳା ଏବଂ ଇଭେଣ୍ଟ ପ୍ରଦର୍ଶନ ମଧ୍ୟରେ ଦୁଇ ବର୍ଷ ଏପଟ-ସେପଟ ହେବା ପରେ, ଗ୍ରୁପ୍ ଅଲ୍-ଷ୍ଟାର୍ ଡ୍ୟାନ୍ସ ଏକାଡେମୀ ଅଧୀନରେ ନିଜସ୍ୱ ଡ୍ୟାନ୍ସ ଷ୍ଟୁଡିଓ ସ୍ଥାପନ କଲା |",
            "as": "কৰ্মশালা আৰু অনুষ্ঠান প্ৰদৰ্শনৰ মাজতে কেইবছৰমান পাৰ হোৱাৰ পিছত, গোটটোৱে অল-ষ্টাৰ নৃত্য একাডেমীৰ অধীনত তেওঁলোকৰ নিজা নৃত্য ষ্টুডিঅ' স্থাপন কৰে।",
            "gu": "વર્કશોપ અને ઈવેન્ટ પર્ફોર્મન્સ વચ્ચે ઘણા વર્ષોના સંઘર્ષ પછી, ગ્રુપે ઓલ-સ્ટાર ડાન્સ એકેડમી હેઠળ પોતાનો ડાન્સ સ્ટુડિયો સ્થાપ્યો છે."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "D10_4_3",
            "hi": "D10_4_3",
            "ka": "D10_4_3",
            "te": "D10_4_3",
            "or": "D10_4_3",
            "as": "D10_4_3",
            "gu": "D10_4_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "They slowly build their brand and start getting more people to learn hip hop.",
            "hi": "खुद का ब्रांड बनाते हैं और बहुत सारे लोगों को हिप हॉप दिखने के लिए प्रेरित करते हैं।",
            "ka": "ಅವರು ನಿಧಾನವಾಗಿ ತಮ್ಮ ಬ್ರ್ಯಾಂಡ್ ಅನ್ನು ನಿರ್ಮಿಸುತ್ತಾರೆ ಮತ್ತು ಹಿಪ್ ಹಾಪ್ ಕಲಿಯಲು ಹೆಚ್ಚಿನ ಜನರನ್ನು ಪಡೆಯಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ.",
            "te": "వారు నెమ్మదిగా తమ బ్రాండ్‌ను నిర్మించుకుంటారు మరియు ఎక్కువ మంది వ్యక్తులకు హిప్ హాప్ నేర్పడం ప్రారంభిస్తారు.",
            "or": "ସେମାନେ ଧିରେ ଧିରେ ସେମାନଙ୍କର ବ୍ରାଣ୍ଡ ଗଠନ କରନ୍ତି ଏବଂ ଅଧିକ ଲୋକଙ୍କୁ ହିପ୍ ହପ୍ ଶିଖାଇବା ଆରମ୍ଭ କରନ୍ତି |",
            "as": "তেওঁলোকে লাহে লাহে নিজৰ এটা ব্ৰেণ্ড নিৰ্মাণ কৰে আৰু অধিক লোকে হিপ হপ শিকিবলৈ আৰম্ভ কৰে।",
            "gu": "તેઓ ધીમે ધીમે તેમની બ્રાન્ડ બનાવી રહ્યા છે અને હિપ હોપની શોધખોળ કરવા માટે વધુ લોકો મેળવવાનું શરૂ કરી રહ્યા છે."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Open Dance Studio",
            "hi": "डांस स्टूडियो ओपन करना",
            "ka": "**THREAD_END**_ಡ್ಯಾನ್ಸ್ ಸ್ಟುಡಿಯೋ ತೆರೆಯಿರಿ",
            "te": "**THREAD_END**_డాన్స్ స్టూడియోని తెరవండి",
            "or": "**THREAD_END**_Open Dance Studio",
            "as": "**থ্ৰেড_সমাপ্ত**_ডান্স ষ্টুডিঅ' খোলা",
            "gu": "**THREAD_END**_ડાન્સ સ્ટુડિયો ખોલો"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Naveen that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने नवीन के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँचा है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकता है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n  ನೀವು ನವೀನ್‌ಗಾಗಿ ಮಾಡಿರುವ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు నవీన్ కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ !\n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି !\n \n ଆପଣ ନବୀନଙ୍କ ପାଇଁ ବାଛିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତି ଘଟାଇଛି | ଆପଣ କରୁଥିବା ପସନ୍ଦ ଉପରେ ଆଧାର କରି ଭିନ୍ନ ଭିନ୍ନ ସମାପ୍ତି ଅଛି | ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକୁ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ?",
            "as": "অভিনন্দন! তুমি এটা যাত্ৰা সমাপ্ত কৰিছা ৷ তুমি নবীনৰ বাবে কৰা বাছনিবোৰৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত ভিত্তি কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ চাব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક મુસાફરી પૂરી કરી છે!\n\nતમે નવીન માટે પસંદગીઓ કરી છે જે આ સંભવિત અંત તરફ દોરી ગઈ છે. તમે જે પસંદગી કરી છે તેનાથી અલગ અલગ અંત છે. શું તમે બીજા દ્રશ્યો ખોલવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ରକୁ ଦେଖନ୍ତୁ |",
            "as": "মানচিত্ৰখনৰ পিনে চোৱা",
            "gu": "ચાલો નકશા પર એક નજર નાખીએ"
          }
        }
      ]
    }
  }
