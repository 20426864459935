import { useEffect, useRef, Fragment, createRef, useContext } from "react";
import gsap, { Power3 } from "gsap";

import { theme } from "../../Theme";
import { icons } from "../../importGroups/icons";
import { SoundContext } from "../../contexts/SoundContext";

export const animObjectBaseStyle = {
  width: 0,
  height: 0,
  borderRadius: "50%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const CirclesAnimation = ({ circlesData, trigger, size }) => {
  const circleTween = (idx, delay) => [
    {
      width: 0,
      height: 0,
      opacity: 0.7,
    },
    {
      width: size || 1000,
      height: size || 1000,
      opacity: 0,
      duration: 1.4,
      ease: Power3.easeOut,
      paused: true,
      /** reset all animations, once the last circle is done animating */
      onComplete: () => animations.current[idx].seek(0, false).pause(),
    },
  ];

  let circleRefs = circlesData.map((d) => createRef());
  let animations = useRef([]);

  useEffect(() => {
    circlesData.forEach((d, i) => {
      animations.current[i] = gsap.fromTo(
        circleRefs[i].current,
        ...circleTween(i, d.delay)
      );
    });
  }, []);

  useEffect(() => {
    if (trigger) {
      animations.current.forEach((d, i) =>
        setTimeout(() => d.play(0, false), (circlesData[i].delay || 0) * 1000)
      );
    }
  }, [trigger]);

  const genCircles = () => (
    <Fragment>
      {circlesData.map((d, i) => (
        <div
          ref={circleRefs[i]}
          style={{ ...animObjectBaseStyle, backgroundColor: d.color }}
        />
      ))}
    </Fragment>
  );

  return genCircles();
};

export const GlowAnimation = ({ size, color, blur, opacity, style, trigger, triggerReverse , delay, onReverseComplete }) => {
  const glowTween = () => [
    {
      width: 0,
      height: 0,
      opacity: 0,
    },
    {
      width: size,
      height: size,

      opacity: opacity || 0.5,
      duration: 1.4,
      
      ease: Power3.easeOut,
      paused: true,
      onReverseComplete: () => {
        console.log('glow rev complete')
        onReverseComplete && onReverseComplete()
      }
    },
  ];

  const glowRef = useRef(null);
  const anim = useRef(undefined);

  useEffect(() => {
    anim.current = gsap.fromTo(glowRef.current, ...glowTween());
  }, []);

  useEffect(() => {
    if (trigger) {
      setTimeout(() => anim.current.play(0, false), (delay || 0) * 1000)
    }
  }, [trigger]);

  useEffect(() => {
    if (triggerReverse) {
      setTimeout(() => anim.current.reverse(), (delay || 0) * 1000)
    }
  }, [triggerReverse]);

  return (
    <div
      ref={glowRef}
      style={{
        ...animObjectBaseStyle,
        ...(style || {}),
        filter: `blur(${blur || 10}px)`,
        backgroundColor: color,
      }}
    />
  );
};

export const MetricsAddedAnimation = ({ data, trigger, onCompleteAll, style }) => {

  
const {triggerSoundEffect} = useContext(SoundContext)
  

  const rotateTween = (idx) => [
    {
      // y: -10,
      
      rotationY: 0,
    },
    {
      // y: 0,
      rotationY: 360,
     
      duration: 2,
      paused: true,
      ease: Power3.easeOut,
      
    },
  ];

  const opacityInTween = (idx, comp) => [
    {
      y: 10,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      duration: 1,
      paused: true,
      ease: Power3.easeOut,
      onComplete: () => {
        if(comp === 'icon' ){
          opacityOutAnim.current[idx].play(0, false) 
          upAnim.current[idx].play(0, false)
        }else if (comp === 'text'){
          TEXT_opacityOutAnim.current[idx].play(0, false); 
          TEXT_upAnim.current[idx].play(0, false);
        }
        if(idx === data.length - 1 ){
          setTimeout(() => {
            wrapperRef.current.style.display = 'none'
            onCompleteAll && onCompleteAll()
          }, 500)
        }
      },
    },
  ];

  const opacityOutTween = () => [
    {
      
      opacity: 1,
      // scale: 1
    },
    {
      opacity: 0,
      // scale: 0.6,
      
      duration: 0.7,
      paused: true,
      ease: Power3.easeOut,
      /** reset all animations, once the last circle is done animating */
      // onComplete: () => anim.current.seek(0, false).pause(),
    },
  ]

 //prolong this for slightly longer than opacity out so that the opacity fade looks cool
  const upTween = () => [
    {
      y: 0,
      
      // scale: 1
    },
    {
     
      // scale: 0.6,
      y: -70,
      duration: 1,
      paused: true,
      ease: Power3.easeOut,
      /** reset all animations, once the last circle is done animating */
      // onComplete: () => anim.current.seek(0, false).pause(),
    },
  ]

  const wrapperRef = useRef(null)

  const iconRefs = data.map(d => createRef())
  const textRefs = data.map(d => createRef())
  const anim = useRef([])
  const opacityInAnim = useRef([]);
  const opacityOutAnim = useRef([])
  const upAnim = useRef([]) 


  //textAnim
  const TEXT_opacityInAnim = useRef([]);
  const TEXT_opacityOutAnim = useRef([])
  const TEXT_upAnim = useRef([])

  useEffect(() => {
    opacityOutAnim.current = iconRefs.map((iconRef,i) =>gsap.fromTo(iconRef.current, ...opacityOutTween(i)));
    anim.current = iconRefs.map((iconRef, i) => gsap.fromTo(iconRef.current, ...rotateTween(i)));
    opacityInAnim.current = iconRefs.map((iconRef,i) =>gsap.fromTo(iconRef.current, ...opacityInTween(i, 'icon')));
    upAnim.current = iconRefs.map((iconRef,i) =>gsap.fromTo(iconRef.current, ...upTween(i, 'icon')));

    TEXT_opacityOutAnim.current = textRefs.map((textRef,i) =>gsap.fromTo(textRef.current, ...opacityOutTween(i)));
    TEXT_opacityInAnim.current = textRefs.map((textRef,i) =>gsap.fromTo(textRef.current, ...opacityInTween(i, 'text')));
    TEXT_upAnim.current = textRefs.map((textRef,i) =>gsap.fromTo(textRef.current, ...upTween(i, 'text')));
  
  }, []);

    useEffect(() => {
    if (trigger) {
      wrapperRef.current.style.display = 'block'
      data.forEach((d,i) => {
        setTimeout(() => {
          triggerSoundEffect(parseInt(d.value) >= 0 ? 'positiveMetrics' : 'negativeMetrics')
          anim.current[i].play(0, false)
          opacityInAnim.current[i].play(0, false)
          TEXT_opacityInAnim.current[i].play(0, false)
        }, (d.delay || 0) * 1000);
      })  
      
    }
  }, [trigger]);

  const genIcon = (icon) => {
    let Icon = icons[icon];
    return (
      <div style={{ maxWidth: "10rem" }}>
        <Icon viewBox={"0 0 20 20"} width={"100%"} height={undefined} />
      </div>
    );
  };

  return (
    <div style={{display:"none"}} ref={wrapperRef}>
      <div style={{position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, zIndex: 1000000}}/>
      {data.map((d,i) => (
        <div
        style={{
          position: 'fixed',
          left: '50vw',
          top: '50vh',
          transform: 'translate(-50%, -50%)',
          // width: "100vw",
          // height: "100vh",
          display: "flex",
          flexDirection: 'column',
          alignItems: "center",
          justifyContent: "center",
          ...style
        }}
        
      >
        
        <div style={{opacity: 0, position: 'relative'}} ref={iconRefs[i]}>
          {genIcon(d.icon)}
          </div>
        <h4 style={{opacity: 0}} ref={textRefs[i]} className='h4 bold'>{d.value}</h4>
      </div>
      ))}
    </div>
  );
};



export const AlertAnimation = ({removeAlert, alert, color}) => {
  
  const onReverseComplete = () => removeAlert()

  const alertTweenProps = [
    {
      opacity: 0,
      scale: 0
    },
    {
      opacity: 1,
      scale: 1,
      duration: 1,
      ease: "elastic.out(0.5, 0.3)",
      onReverseComplete
    }
  ]

  const alertRef = useRef(null);

  useEffect(() => {
      const alertTween = gsap.fromTo(
        alertRef.current,
        ...alertTweenProps
      )

      
      
      setTimeout(() => {
        alertTween.reverse();
      }, 3000);
  },[])

  return (
    <div ref={alertRef} className={`invAlert ${color ? `-${color}-` : ''}`}>
      {alert && <h5 className="h5">{alert}</h5>}
    </div>
  )
}