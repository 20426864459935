import styled, { css } from "styled-components/macro";

export const StyledNavContainer = styled.div`
    transform-origin: top right;
    background: linear-gradient(${({theme}) => `${theme.colors.BG_YELLOW}, ${theme.colors.BG_YELLOW_80}, ${theme.colors.BG_YELLOW_0}`} );
    position: fixed;
    width: 100vw;
    z-index: 10;
    ${({theme}) => theme.transition('transform')}

`

export const StyledNavWrapper = styled.nav`
    position: relative;
    z-index: 1;
    max-width: 1240px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem 0 2rem;
    margin: 0 auto;
`

export const StyledNav = styled.div`
    // position: fixed;
    // top: 1rem;
    display: flex;
    gap: 1rem;
    // align-items: center;
`


// export const StyledNavWrapper = styled.nav`
//     position: relative;
//     z-index: 1;
//     max-width: 1200px
// `

// export const StyledNav = styled.div`
//     position: fixed;
//     top: 1rem;
//     display: flex;
//     align-items: center;
//     ${({navRight}) => navRight && navRightStyle}
//     ${({navLeft}) => navLeft && navLeftStyle}
// `

// const navRightStyle = css`
//     right: 2rem;
//     column-gap: 1rem;

// `

// const navLeftStyle = css`
//     left: 2rem;
// `

// .nav-wrapper{
    
//     .nav{
        
    
//         &.nav--right{
    
//         }
    
//         &.nav--left{
    
//         }
    
//         .iconButton{
//             margin: 0 0.5rem;
    
//             &:first-of-type{ margin-left: 0};
//             &:last-of-type{ margin-right: 0}
//         }
//     }
// }



// .Nav {
//   width: 100%;
//   height: 60px;
//   position: relative;
//   background: $lightYellow;
//   z-index: 999;
//   .nav-wrapper {
//     padding: 1rem 2rem;
//     width: 100%;
//     position: fixed;
//     top: 0;
//     left: 0;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }
// }