
class LocalStorage {
  static getItem(storageName) {
    let items = localStorage.getItem(storageName);
    items = parseLocalStorageItem(items);
    return items;
  }

  static setItem(storageName, item) {
    localStorage.setItem(storageName, item);
  }

  static setJSONItem(storageName, item) {
    const params = new URLSearchParams(window.location.search);
    if(!params.has('previewData')){
      localStorage.setItem(storageName, JSON.stringify(item));
    }
  }

  static getJSONItem(storageName) { 
    let item = localStorage.getItem(storageName);
    return item && JSON.parse(item);
  }

  static lastItem(storageName) {
    let items = localStorage.getItem(storageName);
    if (items) {
      items = parseLocalStorageItem(items);
      return items[items.length - 1];
    }
    return null;
  }
  static removeItem(storageName) {
    localStorage.removeItem(storageName);
  }
}

const parseLocalStorageItem = (items) => {
  if (items) {
    items = items.includes(",") ? items.split(",") : items.split(" ");
    return items;
  }
  return null;
};

export default LocalStorage;
