import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={30}
      height={31}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={15} cy={10} r={8} stroke="#B8B59F" strokeWidth={4} />
      <rect y={10} width={30} height={21} rx={4} fill="#B8B59F" />
      <circle cx={15} cy={19} r={3} fill="#fff" />
      <path d="M12 25l3-6 3 6h-6z" fill="#fff" />
    </svg>
  )
}

export default SvgComponent
