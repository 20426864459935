import { createGlobalStyle } from "styled-components";


const GlobalStyle = createGlobalStyle`



body {
  font-family: 'Baloo 2', sans-serif;
}
`;

export default GlobalStyle;
