import React from 'react';
import { SplashTitle, SplashTitleWrapper } from './style';
import splashImg from '../../assets/splash.jpg';
import { __LOC } from '../../utils/locale';
import {colors} from '../../Theme/general';
const {BG_YELLOW} = colors;

const Splash = props => {
  return (
    <div style={{ backgroundColor: BG_YELLOW, position: 'relative', overflow: 'hidden', display: 'flex', justifyContent: 'center',}}>
      <SplashTitleWrapper>
      <SplashTitle style={{marginLeft: '-3rem'}} children={__LOC('Career')}/>
      <SplashTitle style={{paddingLeft: '4rem'}} children={__LOC(' Quest')}/>
      </SplashTitleWrapper>
          <img src={splashImg} style={{height: '100vh', width: 'auto'}}/>
          </div>
  )
}

export default Splash;