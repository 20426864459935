import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="19.5" fill="#F4DEB0" stroke="#222"></circle>
      <path
        fill="#fff"
        stroke="#000"
        d="M13.226 29.017C13.763 26.475 15.434 23 20 23c4.525 0 6.206 3.508 6.76 6.019C26.996 30.098 26.104 31 25 31H15c-1.105 0-2.003-.902-1.774-1.983z"
      ></path>
      <path
        fill="#fff"
        stroke="#000"
        d="M24 19a4 4 0 11-8 0 4 4 0 018 0zM13 23a2 2 0 11-4 0 2 2 0 014 0zM13 17a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 11-4 0 2 2 0 014 0zM27 17a2 2 0 104 0 2 2 0 00-4 0zM24 12a2 2 0 104 0 2 2 0 00-4 0zM31 23a2 2 0 11-4 0 2 2 0 014 0zM22 10a2 2 0 11-4 0 2 2 0 014 0z"
      ></path>
    </svg>
  );
}

export default Icon;