import React, { useState } from "react";
import * as translationsData from '../data/translationExports';
import { __SGVal } from "../utils/getters";
import LocalStorage from "../utils/LocalStorage";

export const EnvContext = React.createContext();

export const EnvContextProvider = ({children}) => {
    
    const [webpSupported, setWebpSupported] = useState(false);

    const languageOptions = [
        { display: 'English', value: 'en' },
        { display: 'हिंदी', value: 'hi' },
        { display: 'తెలుగు', value: 'te' },
        { display: 'ಕನ್ನಡ', value: 'ka' },
        { display: 'ଓଡିଆ', value: 'or' },
        { display: 'ગુજરાતી', value: 'gu' }
    ]

    const [activeLang, setActiveLang] = useState(
        LocalStorage.getJSONItem('activeLang') || languageOptions[0]
    );

    const translatePageData = (storyId, sceneId, pageId, pageData, activeLang) => {
        //if is english, return the default text in the config
        if(activeLang.value === 'en') return pageData;
        let toReturnPageData = JSON.parse(JSON.stringify(pageData));
        let thisPageTranslations = translationsData[`${storyId}_translations`][sceneId][pageId];
        thisPageTranslations.forEach(d => {
          __SGVal('set', toReturnPageData, d.valuePath, d.value[activeLang.value])
        })
        return toReturnPageData;
      }

    return (
        <EnvContext.Provider
            value={{
                webpSupported, setWebpSupported,
                languageOptions, activeLang, setActiveLang,
                translatePageData
            }}
            >
            {children}
        </EnvContext.Provider>
    )
}