
    export default {
      "scene0": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X_1_1",
              "hi": "X_1_1",
              "ka": "X_1_1",
              "te": "X_1_1",
              "or": "X_1_1",
              "as": "X_1_1",
              "gu": "X_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.desc",
            "value": {
              "en": "placeholder desc",
              "hi": "प्लेसहोल्डर डेस्क ",
              "ka": "placeholder desc",
              "te": "ప్లేస్‌హోల్డర్ డెస్క్",
              "or": "placeholder desc",
              "as": "placeholder desc",
              "gu": "placeholder desc"
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.0.text",
            "value": {
              "en": "Mira is a 14-year-old girl from a small town in Assam near Tezpur. Her parents work in a tea factory in the packaging unit.",
              "hi": "मीरा असम के एक छोटे से शहर तेजपुर के पास की 14 वर्षीय लड़की है। उसके माता-पिता एक चाय कारखाने की पैकेजिंग इकाई में काम करते हैं।",
              "ka": "ಅಸ್ಸಾಮಿನ ತೇಜ್ಪುರ್ ಹತ್ತಿರದ ಒಂದು ಸಣ್ಣ ಪಟ್ಟಣದ 14 ವರ್ಷದ ಹುಡುಗಿ ಮೀರಾ. ಅವಳ ತಂದೆ ತಾಯಿಯರು ಅಲ್ಲಿಯ ಒಂದು ಟೀ ಫ್ಯಾಕ್ಟರಿಯಲ್ಲಿ ಪ್ಯಾಕೇಜಿಂಗ್ ಯೂನಿಟ್ನಲ್ಲಿ ಕೆಲಸ ಮಾಡ್ತಾರೆ.",
              "te": "మీరా 14 ఏళ్ల అమ్మాయి మరియు ఆమె తేజ్‌పూర్ దగ్గరలోని అస్సాంలో ఉన్న ఒక చిన్న పట్టణానికి చెందినది. ఆమె తల్లిదండ్రులు టీ ఫ్యాక్టరీలోప్యాకేజింగ్ యూనిట్‌లో పనిచేస్తున్నారు.",
              "or": "ମୀରା ତେଜପୁର ନିକଟ ଆସାମର ଏକ ଛୋଟ ସହରର ୧୪ବର୍ଷର ଝିଅ | \n ତାଙ୍କ ପିତାମାତା ପ୍ୟାକେଜିଂ ୟୁନିଟରେ ଏକ ଚା କାରଖାନାରେ କାମ କରନ୍ତି |",
              "as": "মীৰা অসমৰ এখন সৰু চহৰৰ এজনী 14 বছৰীয়া ছোৱালী। তাইৰ মাক দেউতাকে পেকেজিং গোটৰ এটা চাহ কাৰখানাত কাম কৰে।",
              "gu": "મીરા તેજપુર નજીક આસામના એક નાના શહેરમાં રહેનારી 14 વર્ષની છોકરી છે. તેના માતા-પિતા પેકેજિંગ વિભાગમાં ચાના કારખાનામાં કામ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.1.text",
            "value": {
              "en": "She has a younger brother who is studying in 7th standard.  She feels like she should be a good role model for her brother.",
              "hi": "उसका एक छोटा भाई है जो सातवीं कक्षा में पढ़ रहा है। वह महसूस करती है कि उसे अपने भाई के सामने अपने आपको एक अच्छा आदर्श साबित करना चाहिए ।",
              "ka": "ಅವಳಿಗೆ ಒಬ್ಬ ತಮ್ಮ ಇದ್ದಾನೆ. ಅವನು 7 ನೇ ತರಗತಿಯಲ್ಲಿ ಓದುತ್ತಾ ಇದ್ದಾನೆ.ಅವಳಿಗೆ ಅನ್ನಿಸುತ್ತೆ ತಾನು ಅವನಿಗೆ ಒಬ್ಬ ಮಾರ್ಗದರ್ಶಿ ಆಗಿರಬೇಕು ಅಂತ.",
              "te": "ఆమెకు 7వ తరగతి చదువుతున్న తమ్ముడు ఉన్నాడు. ఆమె తన సోదరుడికి ఆదర్శంగా ఉండాలని భావిస్తుంది.",
              "or": "ତାଙ୍କର ଏକ ସାନ ଭାଇ ଅଛି ଯିଏ ସପ୍ତମ ଶ୍ରେଣୀରେ ପଢୁଛନ୍ତି | ସେ ଅନୁଭବ\n  କରୁଛନ୍ତି ଯେ ସେ ତାଙ୍କ ଭାଇ ପାଇଁ ଏକ ଭଲ ଆଦର୍ଶ ହେବା ଉଚିତ୍ |",
              "as": "তাইৰ এটা সৰু ভায়েক আছে। সি ক্লাছ 7thত পঢ়ি আছে। তাই ভায়েকৰ বাবে এক আদৰ্শ হবলৈ ইচ্ছা কৰে।",
              "gu": "તેનો એક નાનો ભાઈ છે જે 7મા ધોરણમાં અભ્યાસ કરે છે. તેને લાગે છે કે તે તેના ભાઈ માટે એક સારી પ્રેરણાદાયક વ્યક્તિ હોવી જોઈએ."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.2.text",
            "value": {
              "en": "Mira loves reading. She is a very organised person who likes to plan her day. She puts up a timetable for the week on the wall of her room.",
              "hi": "मीरा को पढ़ना पसंद है। वह एक बहुत ही सुव्यवस्थित लड़की है जो अपने दिन की योजना बनाना पसंद करती है। वह अपने कमरे की दीवार पर पूरे सप्ताह के लिए एक समय सारिणी रखती है।",
              "ka": "ಮೀರಾಗೆ ಓದುವುದು ಅಂದ್ರೆ ಇಷ್ಟ. ಅವಳು ಒಬ್ಬ ವ್ಯವಸ್ಥಿತ ವ್ಯಕ್ತಿ ಆಗಿದ್ದು, ಅವಳು ಆ ದಿನದ ಬಗ್ಗೆ ಮೊದಲೇ ಯೋಚನೆ ಮಾಡ್ತಾಳೆ. ಅವಳ ಕೊಟಡಿಯ ಗೋಡೆಯಲ್ಲಿ ಪೂರ್ತಿ ವಾರದ ಬಗ್ಗೆ ವೇಳಾ ಪಟ್ಟಿ ಬರೆದಿರ್ತಾಳೆ.",
              "te": "మీరాకు చదవడం అంటే చాలా ఇష్టం. ఆమె ప్రతి ఒక్కటి ప్లాన్ ప్రకారం చేసే వ్యక్తి, ఆమెకి రోజులో చేయాల్సినవి ప్లాన్ చేసుకోవడం అంటే ఇష్టం. తన గది గోడపై వారానికి సంబంధించిన టైమ్‌టేబుల్‌ను ఉంచింది.",
              "or": "ମୀରା ପଢିବାକୁ ଭଲ ପାଆନ୍ତି | ସେ ବହୁତ ସଂଗଠିତ ବ୍ୟକ୍ତି ଯିଏ ତାଙ୍କ\n  ଦିନର ଯୋଜନା କରିବାକୁ ପସନ୍ଦ କରନ୍ତି | ସେ ନିଜ ରୁମର କାନ୍ଥରେ ସପ୍ତାହ ପାଇଁ \n ଏକ ସମୟ ସାରଣୀ ରଖନ୍ତି |",
              "as": "মীৰাই পঢ়ি ভাল পায়। তাই শৃংখলাবদ্ধকৈ দিনটোৰ কামবোৰ পৰিকল্পনা কৰি ভাল পায়। তাই তাইৰ কোঠাৰ দেৱালত সপ্তাহটোৰ বাবে সময়সূচী ৰাখে।",
              "gu": "મીરાને વાંચવાનો શોખ છે. તે ખૂબ જ આયજન પ્રમાણે ચાલનારી વ્યક્તિ છે જે તેના દિવસનું આયોજન કરવાનું પસંદ કરે છે. તે તેના રૂમની દિવાલ પર અઠવાડિયાનું સમયપત્રક લાગેલું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.3.text",
            "value": {
              "en": "Mira is fascinated with science and dreams of studying further. Her aunt and uncle inspire her, and they live in the city. Her aunt is a biology teacher, and her uncle is an engineer.",
              "hi": "मीरा  विज्ञान के प्रति बहुत आकर्षित  है और आगे अध्ययन करने के सपने देखती है। उसकी चाची और चाचा ने उसे प्रेरित किया, और वे शहर में रहते हैं। उसकी चाची जीवविज्ञान शिक्षिका हैं, और उसके चाचा एक इंजीनियर हैं।",
              "ka": "ಮೀರಾ ವಿಜ್ಞಾನದ ಬಗ್ಗೆ ಅತ್ಯಂತ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದು ಅದರಲ್ಲೇ ಮುಂದೆ ಓದಬೇಕು ಅಂತ ಕನಸು ಕಾಣ್ತಾ ಇರ್ತಾಳೆ. ಅವಳ ಮಾವ ಮತ್ತು ಆಂಟಿ ಅವಳಿಗೆ ಒಂದು ಸ್ಪೂರ್ತಿ.ಅವರು ನಗರದಲ್ಲಿ ಇರ್ತಾರೆ. ಅವಳ ಆಂಟಿ ಬಯಾಲಜಿ ಟೀಚರ್ ಆಗಿದ್ದು, ಅವಳ ಮಾವ ಇಂಜಿನಿಯರ್ ಆಗಿದ್ದಾರೆ. ",
              "te": "మీరా సైన్స్ పట్ల ఆసక్తిని పెంచుకొని ఇంకా చదవాలని కలలు కంటుంది. ఆమె అత్త మరియు మామని చూసి ఇన్స్పైర్ అయింది. వారు నగరంలో నివసిస్తున్నారు. ఆమె అత్త జీవశాస్త్ర ఉపాధ్యాయురాలు మరియు ఆమె మామ ఇంజనీర్.",
              "or": "ମୀରା ବିଜ୍ଞାନ ଏବଂ ଅଧିକ ଅଧ୍ୟୟନର ସ୍ୱପ୍ନ ସହିତ ଆକର୍ଷିତ | ତାଙ୍କ ମାଉସୀ ଏବଂ\n  ମାମୁଁ ତାଙ୍କୁ ପ୍ରେରଣା ଦିଅନ୍ତି ଏବଂ ସେମାନେ ସହରରେ ରୁହନ୍ତି | ତାଙ୍କ ମାଉସୀ ଜଣେ ଜୀବ ବିଜ୍ଞାନ ଶିକ୍ଷକ ଏବଂ ମାମୁଁ ଜଣେ ଇଞ୍ଜିନିୟର |",
              "as": "মীৰাই বিজ্ঞান বিষয়টো ভাল পায় আৰু ভৱিষ্যতে এই বিষয়টো ভালদৰে পঢ়িম বুলি সপোন দেখিছে। তাইৰ খুড়ীয়েক আৰু খুড়াকে তাইক অনুপ্ৰাণিত কৰে, তেওঁলোক চহৰত বাস কৰে। তাইৰ খুড়ীয়েক এগৰাকী জীৱবিজ্ঞানৰ শিক্ষয়িত্ৰী, আৰু তাইৰ খুৰাক এগৰাকী অভিযন্তা।",
              "gu": "મીરાને વિજ્ઞાન પ્રત્યે રસ  છે અને તેને આગળ અભ્યાસ કરવાનું સપનું છે. તેના કાકી અને કાકા તેને પ્રેરણા આપે છે, અને તેઓ શહેરમાં રહે છે. તેની કાકી જીવવિજ્ઞાનના શિક્ષક છે, અને તેના કાકા એન્જિનિયર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.4.text",
            "value": {
              "en": "However, schools in her town have classes only until the 10th standard. Her parents want to send Mira to the city school for her 10th to make sure her basics in science are strong.",
              "hi": "हालांकि, उसके नगर के स्कूल में केवल दसवीं तक की कक्षाएं हैं। उसके माता-पिता मीरा को दसवीं के लिए किसी शहर के स्कूल में भेजना चाहते हैं, ताकि विज्ञान में उसका आधार सुनिश्चित रूप से  मजबूत हो सके।",
              "ka": "ಆದರೆ, ಅವಳ ಪಟ್ಟಣದಲ್ಲಿ ಇರುವ ಶಾಲೆಗಳಲ್ಲಿ 10 ನೇ ತರಗತಿವರೆಗೆ ಮಾತ್ರ ಇದೆ. ಅವರ ಅಪ್ಪ ಅಮ್ಮ ಅವಳನ್ನ ನಗರದ ಶಾಲೆಗೆ 10 ನೇ ತರಗತಿಗೆ ಕಲಿಸಬೇಕು ಅದರಿಂದ ವಿಜ್ಞಾನದ ಬಗ್ಗೆ ಅವಳ ಮೂಲ ಜ್ಞಾನ ದೃಢವಾಗಿರತ್ತೆ ಅಂತ.",
              "te": "అయితే, ఆమె పట్టణంలోని పాఠశాలల్లో 10వ తరగతి వరకు మాత్రమే తరగతులు ఉన్నాయి. ఆమె తల్లిదండ్రులు మీరా సైన్స్ గురించి మరింత తెలుసుకోవాలని 10వ తరగతి చదవడం కోసం పట్టణానికి పంపాలని కోరుకుంటారు.",
              "or": "ତଥାପି, ତାଙ୍କ ସହରର ବିଦ୍ୟାଳୟଗୁଡ଼ିକରେ କେବଳ ଦଶମ ମାନ୍ୟତା ପର୍ଯ୍ୟନ୍ତ କ୍ଲାସ୍\n  ଅଛି | ବିଜ୍ଞାନରେ ତାଙ୍କର ମୌଳିକତାକୁ ସୁନିଶ୍ଚିତ କରିବାକୁ ତାଙ୍କ ପିତାମାତା ମୀରାଙ୍କୁ\n  ଦଶମ ପାଇଁ ସିଟି ସ୍କୁଲକୁ ପଠାଇବାକୁ ଚାହୁଁଛନ୍ତି |",
              "as": "সিহঁতৰ নগৰৰ বিদ্যালয়বোৰত দশম শ্ৰেণীলৈকে শ্ৰেণী আছে যদিও তাইৰ মাক দেউতাকে মীৰাক তাইৰ দশম শ্ৰেণীৰ বাবে চহৰৰ বিদ্যালয়লৈ পঠিয়াবলৈ বিচাৰে যাতে বিজ্ঞানত তাইৰ মৌলিক বিষয়বোৰ শক্তিশালী হয়।",
              "gu": "જો કે, તેના શહેરની શાળાઓમાં માત્ર 10મા ધોરણ સુધી જ વર્ગો છે. તેના માતા-પિતા મીરાને તેના 10મા માટે શહેરની શાળામાં મોકલવા માંગે છે જેથી કરીને તે વિજ્ઞાનમાં મૂળ પાયો મજબૂત થાય"
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.5.text",
            "value": {
              "en": "Mira is looking forward to seeing the schools in the city. But her parents are worried about Mira staying away from home.",
              "hi": "मीरा शहर के स्कूल में जाना चाहती है। लेकिन उसके माता-पिता मीरा के घर से दूर रहने के विषय में चिंतित हैं।",
              "ka": "ಮೀರಾಗೆ ನಗರದ ಶಾಲೆಗಳನ್ನು ನೋಡ್ಬೇಕು ಅಂತ ಕಾತರ ಇದೆ. ಆದ್ರೆ ಅವರ ಅಪ್ಪ ಅಮ್ಮ ಮೀರಾ ಮನೆಯಿಂದ ಹೊರಗೆ ಇರೋದರ ಬಗ್ಗೆ ಚಿಂತೆ ಮಾಡ್ತಾ ಇದ್ದಾರೆ.",
              "te": "నగరంలోని పాఠశాలలను చూడాలని మీరా ఎదురుచూస్తోంది. అయితే మీరా ఇంటికి దూరంగా ఉండడంతో ఆమె తల్లిదండ్రులు ఆందోళన చెందుతున్నారు.",
              "or": "ମୀରା ସହରର ବିଦ୍ୟାଳୟଗୁଡ଼ିକୁ ଦେଖିବା ପାଇଁ ଅପେକ୍ଷା କରିଛି | କିନ୍ତୁ ମୀରା ଘରୁ\n  ଦୂରରେ ରହିବା ପାଇଁ ତାଙ୍କ ପିତାମାତା ଚିନ୍ତିତ ଅଛନ୍ତି |",
              "as": "মীৰাই চহৰৰ বিদ্যালয়সমূহ চাবলৈ বৰ আশা কৰে। কিন্তু তাইৰ মাক দেউতাকে মীৰা ঘৰৰ পৰা আঁতৰত থাকিলে চিন্তা কৰে।",
              "gu": "મીરા શહેરની શાળાઓ જોવા માટે આતુર છે. પરંતુ તેના માતા-પિતાને મીરાના ઘરથી દૂર રહેવાની ચિંતા રહે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.6.text",
            "value": {
              "en": "In this story, we look at how Mira explores taking up a professional course like engineering or medicine while facing the challenges that come along with it.",
              "hi": "इस कहानी में, हम देखते हैं कि कैसे  मीरा ने अपने सामने आने वाली चुनौतियों का सामना करते हुए इंजीनियरिंग या मेडिकल जैसे प्रोफेशनल कोर्स को लेने के उपाय खोजे।",
              "ka": "ಈ ಕತೆನಲ್ಲಿ, ನಾವು ಮೀರಾ ಹೇಗೆ ವೃತ್ತಿ ನಿರತ ಪಠ್ಯ ಕ್ರಮಗಳಾದ ಇಂಜಿನಿಯರಿಂಗ್ ಅಥವಾ ವೈದ್ಯಕೀಯ ಶಿಕ್ಷಣಗಳನ್ನ ಆರಿಸ್ಕೊಳ್ತಾಳೆ ಮತ್ತು ಅದನ್ನು ಮಾಡೋವಾಗ ಯಾವ ರೀತಿ ಕಷ್ಟಗಳನ್ನು ಎದುರಿಸ್ತಾಳೆ ಅನ್ನೋದನ್ನ ನೋಡ್ತೀವಿ.",
              "te": "ఈ కథలో, మీరా ఇంజినీరింగ్ లేదా మెడిసిన్ వంటి వృత్తిపరమైన కోర్సులను దానితో పాటు వచ్చే సవాళ్లను ఎలా ఎదుర్కొంటుందో తెలుసుకుందాం.",
              "or": "ଏହି କାହାଣୀରେ, ଆମେ ଦେଖୁ ଯେ ମୀରା ଇଞ୍ଜିନିୟରିଂ କିମ୍ବା ମେଡିସିନ୍ ପରି ଏକ\n  ବୃତ୍ତିଗତ ପାଠ୍ୟକ୍ରମ ଗ୍ରହଣ କରିବା ସହିତ ଏହା ସହିତ ଆସୁଥିବା ଚ୍ୟାଲେଞ୍ଜର ସମ୍ମୁଖୀନ ହୁଏ |",
              "as": "এই কাহিনীটোত, আমি দোখোঁ যে মীৰাই কেনেদৰে অভিযান্ত্ৰিক বা ঔষধৰ দৰে পেছাদাৰী পাঠ্যক্ৰম গ্ৰহণ কৰিবলৈ আগবাঢ়ে, আৰু ইয়াৰ সৈতে অহা প্ৰত্যাহ্বানবোৰৰ সন্মুখীন হয়।",
              "gu": "આ વાર્તામાં, આપણે જોઈએ છીએ કે કેવી રીતે મીરા તેની સાથે આવતા પડકારોનો સામનો કરતી વખતે એન્જિનિયરિંગ અથવા તબીબી જેવા વ્યવસાયિક અભ્યાસક્રમ કેવી રીતે લે છે."
            }
          }
        ]
      },
      "scene1": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_1",
              "hi": "X1_1_1",
              "ka": "X1_1_1",
              "te": "X1_1_1",
              "or": "X1_1_1",
              "as": "X1_1_1",
              "gu": "X1_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira and her mother are visiting the school in Guwahati, where her aunt works as a teacher. Her aunt, Neetu, is taking them around the school to show Mira the classrooms and laboratories.",
              "hi": "मीरा और उनकी मां गुवाहाटी के स्कूल जा रही हैं, जहां उसकी चाची एक शिक्षिका के रूप में काम करती हैं। उसकी चाची नीतू, मीरा को कक्षाएं और प्रयोगशालाएं  दिखाने के लिए स्कूल के चारों ओर ले जा रही है।",
              "ka": "ಗುವಹಾಟಿನಲ್ಲಿ ಮೀರಾ ಅವರಮ್ಮ ಶಾಲೆ ಬಳಿ ಕಾಯ್ತಾ ಇದ್ದಾರೆ, ಅಲ್ಲಿ ಅವಳ ಆಂಟಿ ಟೀಚರ್ ಆಗಿ ಕೆಲಸ ಮಾಡ್ತಾರೆ. ಅವಳ ಆಂಟಿ ನೀತು, ಮೀರಾಗೆ ಅಲ್ಲಿ ಇರುವ ತರಗತಿ ಕೊಠಡಿಗಳು ಮತ್ತು ಪ್ರಯೋಗ ಶಾಲೆಯನ್ನು ತೋರಿಸೋಕ್ಕೆ ಅವರನ್ನ ಶಾಲೆ ಸುತ್ತಲೂ ಕರೆದುಕೊಂಡು ಹೋಗ್ತಾ ಇದ್ದಾರೆ.",
              "te": "మీరా మరియు ఆమె తల్లి గౌహతిలోని ఆమె అత్త టీచర్‌గా పనిచేస్తున్న పాఠశాలను సందర్శిస్తున్నారు. మీరాకు తరగతి గదులు, ల్యాబొరేటరీలు చూపించేందుకు ఆమె అత్త నీతూ వారిని స్కూల్ చుట్టూ తిప్పుతుంది",
              "or": "ମୀରା ଏବଂ ତାଙ୍କ ମା ଗୁଆହାଟୀରେ ବିଦ୍ୟାଳୟ ପରିଦର୍ଶନ କରୁଛନ୍ତି, ଯେଉଁଠାରେ \n ତାଙ୍କ ମାଉସୀ ଶିକ୍ଷକ ଭାବରେ କାର୍ଯ୍ୟ କରନ୍ତି | ମୀରାଙ୍କୁ ଶ୍ରେଣୀଗୃହ ଏବଂ ଲାବୋରେଟୋରୀ ଦେଖାଇବା ପାଇଁ ତାଙ୍କ ମାଉସୀ ନୀତୁ ସେମାନଙ୍କୁ ବିଦ୍ୟାଳୟ ପରିସରରେ ନେଉଛନ୍ତି |",
              "as": "মীৰা তাইৰ মাকৰ সৈতে গুৱাহাটীৰ সেই বিদ্যালয়খনলৈ আহিছে, য'ত তাইৰ খুড়ীয়েকে এগৰাকী শিক্ষয়িত্ৰী হিচাপে কাম কৰে। তাইৰ খুড়ীয়েক নীতুৱে তেওঁলোকক বিদ্যালয়ৰ চাৰিওফালে মীৰাক শ্ৰেণীকোঠা আৰু পৰীক্ষাগাৰবোৰ দেখুৱাবলৈ লৈ গৈ আছে।",
              "gu": "મીરા અને તેની માતા ગુવાહાટીની શાળાની મુલાકાતે લે છે, જ્યાં તેની કાકી શિક્ષક તરીકે કામ કરે છે. તેની કાકી, નીતુ, મીરાને વર્ગખંડો અને પ્રયોગશાળાઓ બતાવવા માટે તેને શાળામાં લઈ જાય છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_1b",
              "hi": "X1_1_1b",
              "ka": "X1_1_1b",
              "te": "X1_1_1b",
              "or": "X1_1_1b",
              "as": "X1_1_1b",
              "gu": "X1_1_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is fascinated by the facilities that are available in this school. Her old school never had so many facilities for the students.",
              "hi": "मीरा इस स्कूल में उपलब्ध सुविधाओं से प्रभावित है। उसके पुराने स्कूल में छात्रों के लिए इतनी सारी सुविधाएं नहीं थीं।",
              "ka": "ಆ ಶಾಲೆನಲ್ಲಿರೋ ಸೌಕರ್ಯಗಳನ್ನ ನೋಡಿ ಮೀರಾ ಆಕರ್ಷಿತಳಾಗ್ತಾಳೆ. ಅವಳ ಹಳೆ ಶಾಲೆನಲ್ಲಿ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಇಷ್ಟೆಲ್ಲಾ ಸೌಕರ್ಯ ಇಲ್ಲ.",
              "te": "ఈ పాఠశాలలో అందుబాటులో ఉన్న సౌకర్యాలు మీరాను ఆకర్షిస్తున్నాయి. ఆమె పాత పాఠశాలలో విద్యార్థులకు ఇన్ని సౌకర్యాలు లేవు.",
              "or": "ଏହି ବିଦ୍ୟାଳୟରେ ଉପଲବ୍ଧ ସୁବିଧା ଦ୍ୱାରା ମୀରା ଆକର୍ଷିତ | ତାଙ୍କ ପୁରାତନ\n  ବିଦ୍ୟାଳୟରେ ଛାତ୍ରମାନଙ୍କ ପାଇଁ ଏତେ ସୁବିଧା ନଥିଲା |",
              "as": "মীৰাই এই বিদ্যালয়খনত থকা সুবিধাবোৰ দেখি আকৰ্ষিত হয়। তাইৰ পুৰণি বিদ্যালয়খনত শিক্ষাৰ্থীসকলৰ বাবে ইমান সুবিধা নাছিল।",
              "gu": "મીરા આ શાળામાં ઉપલબ્ધ સુવિધાઓથી મોહિત થઈ છે. તેની જૂની શાળામાં વિદ્યાર્થીઓ માટે આટલી સુવિધાઓ ક્યારેય ન હતી."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_2//",
              "hi": "X1_1_2//",
              "ka": "X1_1_2//",
              "te": "X1_1_2//",
              "or": "X1_1_2//",
              "as": "X1_1_2//",
              "gu": "X1_1_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Neetu Aunty",
              "hi": "नीतू चाची",
              "ka": "ನೀತು ಆಂಟಿ",
              "te": "నీతూ ఆంటీ",
              "or": "ନୀତୁ ମାଉସୀ",
              "as": "নীতু খুড়ী",
              "gu": "નીતુ કાકી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "This laboratory is so cool, Aunty!",
              "hi": "यह प्रयोगशाला बहुत अच्छी है, चाची !",
              "ka": "ಈ ಪ್ರಯೋಗ ಶಾಲೆ ಎಷ್ಟು ಚೆನ್ನಾಗಿದೆ ಆಂಟಿ!",
              "te": "ఈ లేబొరేటరీ చాలా బాగుంది ఆంటీ!",
              "or": "ଏହି ଲାବୋରେଟୋରୀ ବହୁତ ସୁନ୍ଦର, ମାଉସୀ!",
              "as": "এই পৰীক্ষাগাৰটো ইমান ধুনীয়া, খুড়ী!",
              "gu": "આ પ્રયોગશાળા ખૂબ સરસ છે, કાકી!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I’m so glad you liked it, Mira. I have been trying hard to make this laboratory enjoyable for all the kids in our school. I recently got a few microscopes for everyone to use.",
              "hi": "मुझे खुशी है कि तुमको यह पसंद आई, मीरा। मैं अपने स्कूल में सभी बच्चों के लिए इस प्रयोगशाला को सुखद बनाने के लिए कड़ी मेहनत कर रही हूं। मैं हाल ही में सब के उपयोग के लिए कुछ माइक्रोस्कोप लाई हूँ।",
              "ka": "ನಂಗೆ ತುಂಬಾ ಸಂತೋಷ ಆಯ್ತು ನೀನು ಅದನ್ನು ಮೆಚ್ಚಿಕೊಂಡಿದ್ದಕ್ಕೆ, ಮೀರಾ, ನಾನು ಈ ಪ್ರಯೋಗ ಶಾಲೆಗೆ ಬಂದ ನಮ್ಮ ಶಾಲೆಯ ಎಲ್ಲಾ ಹುಡುಗರೂ ಆನಂದಿಸ್ಬೇಕು ಅಂತ ತುಂಬಾ ಕಷ್ಟ ಪಟ್ಟಿದ್ದೀನಿ. ನಾನು ಇತ್ತೀಚೆಗೆ ಕೆಲವು ಮೈಕ್ರೋಸ್ಕೋಪನ್ನ ಎಲ್ಲರೂ ಉಪಯೋಗೀಶಕ್ಕೋಸ್ಕರ ತರಿಸಿದ್ದೀನಿ. ",
              "te": "మీరా, నువ్వు దీన్ని ఇష్టపడినందుకు నేను చాలా సంతోషిస్తున్నాను. ఈ లేబొరేటరీని మా పాఠశాలలోని పిల్లలందరికీ ఆనందించేలా చేయడానికి నేను తీవ్రంగా ప్రయత్నిస్తున్నాను. ప్రతి ఒక్కరూ ఉపయోగించడానికి ఇటీవల కొన్ని మైక్రోస్కోప్‌లు వచ్చాయి.",
              "or": "ମୀରା, ତୁମେ ଏହାକୁ ପସନ୍ଦ କରିଥିବାରୁ ମୁଁ ବହୁତ ଖୁସି | ଆମ ବିଦ୍ୟାଳୟର ସମସ୍ତ\n  ପିଲାମାନଙ୍କ ପାଇଁ ଏହି ଲାବୋରେଟୋରୀକୁ ଉପଭୋଗ୍ୟ କରିବା ପାଇଁ ମୁଁ କଠିନ \n ଚେଷ୍ଟା କରୁଛି | ସମସ୍ତେ ବ୍ୟବହାର କରିବାକୁ ମୁଁ ନିକଟରେ କିଛି ମାଇକ୍ରୋସ୍କୋପ୍ ପାଇଲି |",
              "as": "তুমি এইটো ভাল পোৱা বাবে মোৰো বহুত ভাল লাগিছে মীৰা। মই আমাৰ বিদ্যালয়ৰ সকলো ল'ৰা-ছোৱালীয়ে ভাল পাব পৰাকৈ এই পৰীক্ষাগাৰটো ধুনীয়া কৰিবলৈ বহুত চেষ্টা কৰি আছোঁ। মই অলপতে সকলোৱে ব্যৱহাৰ কৰিবলৈ কেইটামান অণুবীক্ষণ যন্ত্ৰ পাইছিলো।",
              "gu": "મીરા. મને ખૂબ આનંદ થયો કે તને તે ગમ્યું, અમારી શાળાના તમામ બાળકો માટે આ પ્રયોગશાળાને આનંદપ્રદ બનાવવા માટે હું સખત પ્રયાસ કરી રહી છું. મને તાજેતરમાં દરેક માટે ઉપયોગ કરવા માટે થોડા માઇક્રોસ્કોપ મળ્યા છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Oh wow, I have never seen a real microscope. Can I look through it?",
              "hi": "ओह वाह, मैंने कभी वास्तविक माइक्रोस्कोप नहीं देखा है। क्या मैं इसे देख सकती हूँ?",
              "ka": "ಓ,ವಾವ್, ನಾನು ನಿಜವಾದ ಮೈಕ್ರೋ ಸ್ಕೋಪನ್ನು ನೋಡೇ ಇಲ್ಲ. ನಾನು ಅದರ ಮೂಲಕ ನೋಡ್ಬಹುದೇ?",
              "te": "హొ వావ్, నేను ఎప్పుడూ నిజమైన మైక్రోస్కోప్‌ని చూడలేదు. నేను దాని ద్వారా చూడవచ్చా?",
              "or": "ଓଃ ବାଃ, ମୁଁ ପ୍ରକୃତ ମାଇକ୍ରୋସ୍କୋପ୍ ଦେଖି ନାହିଁ | ମୁଁ ଏହା ଦେଖି ପାରିବି କି?",
              "as": "অ'হ ৱাও, মই কেতিয়াও অণুবীক্ষণ যন্ত্ৰ দেখা নাই। মই ইয়াৰ মাজেৰে চাব পাৰিম নেকি?",
              "gu": "ઓહ વાહ, મેં ક્યારેય વાસ્તવિક માઇક્રોસ્કોપ જોયો નથી. શું હું તેના દ્વારા જોઈ શકું?"
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_3////",
              "hi": "X1_1_3////",
              "ka": "X1_1_3////",
              "te": "X1_1_3////",
              "or": "X1_1_3////",
              "as": "X1_1_3////",
              "gu": "X1_1_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Aunty prepares a slide for her to look through the microscope.",
              "hi": "चाची माइक्रोस्कोप की सहायता से देखने के लिए एक स्लाइड तैयार करती हैं।",
              "ka": "ಆಂಟಿ ಒಂದು ಸ್ಲೈಡ್ನ ತಯಾರಿಸಿ ಮೈಕ್ರೋ ಸ್ಕೋಪ್ ಮೂಲಕ ನೋಡಕ್ಕೆ ಹೇಳ್ತಾರೆ.",
              "te": "ఆంటీ మైక్రోస్కోప్‌లో చూసేందుకు స్లయిడ్‌ని సిద్ధం చేసింది.",
              "or": "ମାଉସୀ ମାଇକ୍ରୋସ୍କୋପ୍ ଦେଖିବା ପାଇଁ ଏକ ସ୍ଲାଇଡ୍ ପ୍ରସ୍ତୁତ କରନ୍ତି |",
              "as": "তাইক অণুবীক্ষণ যন্ত্ৰৰ মাজেৰে চাবলৈ খুড়ীয়েকে এখন শ্লাইড প্ৰস্তুত কৰি দিয়ে।",
              "gu": "કાકી તેના માટે માઇક્રોસ્કોપ દ્વારા જોવા માટે એક સ્લાઇડ તૈયાર કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Wow!!",
              "hi": "बहुत खूब!!",
              "ka": "ವಾವ್!!",
              "te": "వావ్!!",
              "or": "ବାଃ",
              "as": "ৱাও!!",
              "gu": "વાહ!!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "That is the shape of an Amoeba; you must have seen that in your textbook. Now you get to see it in reality!",
              "hi": "यह एक अमीबा का आकार है; तुमने इसे अपनी पाठ्यपुस्तक में देखा होगा। अब तुम  इसे वास्तविकता में देख रही हो!",
              "ka": "ಅದು ಅಮೀಬಾದ ಒಂದು ರೂಪ, ಅದನ್ನು ನೀನು ನಿನ್ನ ಪಠ್ಯ ಪುಸ್ತಕದಲ್ಲಿ ನೋಡಿರಬಹುದು. ಅದನ್ನು ನೀನು ಈಗ ನಿಜ ರೂಪದಲ್ಲಿ ನೋಡ್ತೀಯ!",
              "te": "అది అమీబా ఆకారం; నువ్వు దానిని నీ పాఠ్యపుస్తకంలో చూసి ఉండొచ్చు. ఇప్పుడు నువ్వు దీన్ని నేరుగా చూడవచ్చు!",
              "or": "ତାହା ହେଉଛି ଆମୋବାଙ୍କର ଆକୃତି; ତୁମ ପାଠ୍ୟପୁସ୍ତକରେ ତୁମେ ନିଶ୍ଚୟ ଦେଖିଥିବ \n | ବର୍ତ୍ତମାନ ଆପଣ ଏହାକୁ ବାସ୍ତବରେ ଦେଖିବାକୁ ପାଇବେ!",
              "as": "এয়া হৈছে এমিবাৰ আকৃতি। তুমি নিশ্চয় সেইটো তোমাৰ কিতাপত দেখিছা। এতিয়া ইয়াক তুমি বাস্তৱত দেখিবলৈ পাবা।",
              "gu": "તે અમીબાનો આકાર છે; તે તારા પાઠ્યપુસ્તકમાં તે જોયું જ હશે. હવે તું તેને વાસ્તવિકતામાં જોવે છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "I can’t wait to start my studies in this school next year!",
              "hi": "मैं अगले वर्ष इस स्कूल में अपनी पढ़ाई शुरू करने के लिए इंतजार नहीं कर सकती!",
              "ka": "ನಾನು ಈ ಶಾಲೆನಲ್ಲಿ ಮುಂದಿನ ವರ್ಷ ಓದಕ್ಕೆ ಕಾಯಕ್ಕೆ ಆಗಲ್ಲ!",
              "te": "ఈ పాఠశాలలో నా చదవు ప్రారభించడానికి వచ్చే సంవత్సరం దాకా నేను ఎదురు చూడలేను!",
              "or": "ଆସନ୍ତା ବର୍ଷ ଏହି ବିଦ୍ୟାଳୟରେ ମୋର ଅଧ୍ୟୟନ ଆରମ୍ଭ କରିବାକୁ ମୁଁ ଅପେକ୍ଷା କରି\n ପାରିବି ନାହିଁ!",
              "as": "এই বিদ্যালয়ত পঢ়িবলৈ আহিবলৈ মই অহা বছৰলৈকে অপেক্ষা কৰিব নোৱাৰো কিন্তু দেই!",
              "gu": "હું આવતા વર્ષે  મારો અભ્યાસ આ શાળામાં શરૂ કરવા માટે રાહ જોઈ રાહ જોઈ શકતી નથી "
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_2_1//",
              "hi": "X1_2_1//",
              "ka": "X1_2_1//",
              "te": "X1_2_1//",
              "or": "X1_2_1//",
              "as": "X1_2_1//",
              "gu": "X1_2_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "While Mira is busy seeing the laboratory, her mother starts talking to Neetu Aunty.",
              "hi": " मीरा जब प्रयोगशाला को देखने में व्यस्त है, उसकी मां नीतू चाची से बात करना शुरू कर देती है।",
              "ka": "ಮೀರಾ ಪ್ರಯೋಗ ಶಾಲೆನ ನೋಡುವುದರಲ್ಲಿ ಮಗ್ನಳಾಗಿದ್ದಾಗ, ಅವಳ ಅಮ್ಮ ನೀತು ಆಂಟಿ ಜೊತೆ ಮಾತಾಡಕ್ಕೆ ಶುರು ಮಾಡ್ತಾರೆ.",
              "te": "మీరా లేబొరేటరీని చూడటంలో బిజీగా ఉండగా, ఆమె తల్లి నీతూ ఆంటీతో మాట్లాడటం ప్రారంభించింది.",
              "or": "ମୀରା ଲାବୋରେଟୋରୀ ଦେଖିବାରେ ବ୍ୟସ୍ତ ଥିବାବେଳେ ତାଙ୍କ ମା ନୀତୁ ମାଉସୀଙ୍କ\n  ସହ କଥା ହେବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "মীৰাই লেবৰেটৰি চাই থকাৰ সময়ত মাকে নীতু খুড়ী সৈতে কথা পাতিবৈ আৰম্ভ কৰে।",
              "gu": "જ્યારે મીરા લેબોરેટરી જોવામાં વ્યસ્ત હોય છે, ત્યારે તેની માતા નીતુ કાકી સાથે વાત કરવાનું શરૂ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mother",
              "hi": "मां",
              "ka": "ಅಮ್ಮ",
              "te": "తల్లి",
              "or": "ମା",
              "as": "মাক",
              "gu": "માતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Neetu Aunty",
              "hi": "नीतू चाची",
              "ka": "ನೀತು ಆಂಟಿ",
              "te": "నీతూ ఆంటీ",
              "or": "ନୀତୁ ମାଉସୀ",
              "as": "নীতু খুড়ী",
              "gu": "નીતુ કાકી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Mother",
              "hi": "मां",
              "ka": "ಅಮ್ಮ",
              "te": "తల్లి",
              "or": "ମା",
              "as": "মাক",
              "gu": "માતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Neetu Aunty",
              "hi": "नीतू चाची",
              "ka": "ನೀತು ಆಂಟಿ",
              "te": "నీతూ ఆంటీ",
              "or": "ନୀତୁ ମାଉସୀ",
              "as": "নীতু খুড়ী",
              "gu": "નીતુ કાકી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Many parents I know are planning to send their children together to stay in a hostel. Are hostels safe? How do the children manage all by themself?",
              "hi": "मुझे पता है कि अनेक माता-पिता अपने बच्चों को छात्रावास में रहने के लिए एक साथ भेजने की योजना बना रहे हैं। हॉस्टल सुरक्षित हैं? बच्चे स्वयं ही सभी चीज़ों का प्रबंधन कैसे करते हैं?",
              "ka": "ನನಗೆ ಗೊತ್ತಿರೋ ಅನೇಕ ಪೋಷಕರು ತಮ್ಮ ಮಕ್ಕಳನ್ನ ಒಟ್ಟಿಗೆ ಹಾಸ್ಟೆಲ್ನಲ್ಲಿ ಇರುವುದಕ್ಕೆ ಕಳಿಸೋ ಯೋಚನೆ ಮಾಡ್ತಾ ಇದ್ದಾರೆ. ಹಾಸ್ಟೆಲ್ಗಳು ಸುರಕ್ಷಿತವಾಗಿ ಇರತ್ತಾ? ಮಕ್ಕಳು ತಮ್ಮನ್ನು ತಾವೇ ಹೇಗೆ ನಿಭಾಯಿಸ್ಕೊಳ್ತಾರೆ?",
              "te": "నాకు తెలిసిన చాలా మంది తల్లిదండ్రులు తమ పిల్లలను హాస్టల్‌లో కలిపి ఉంచడానికి పంపాలని ప్లాన్ చేస్తున్నారు. హాస్టళ్లు సురక్షితంగా ఉంటాయా? పిల్లలు వారిని వారు ఎలా చూసుకుంటారు?",
              "or": "ମୁଁ ଜାଣିଥିବା ଅନେକ ପିତାମାତା ସେମାନଙ୍କ ପିଲାମାନଙ୍କୁ ଏକ ହଷ୍ଟେଲରେ ରହିବାକୁ\n  ଯୋଜନା କରୁଛନ୍ତି | ହଷ୍ଟେଲଗୁଡିକ ସୁରକ୍ଷିତ କି? ପିଲାମାନେ ନିଜେ କିପରି ପରିଚାଳନା\n  କରିବେ?",
              "as": "মোৰ চিনাকীৰ কিছুমান অভিভাৱকে তেওঁলোকৰ ল'ৰা-ছোৱালীক হোষ্টেলত থাকিবলৈ একেলগে পঠোৱাৰ কথা ভাৱি আছে। হোষ্টেলবোৰ সুৰক্ষিত হয়নে বাৰু? ল'ৰা-ছোৱালীবোৰে এইবোৰ নিজে নিজে চম্ভালিব পাৰে জানো?",
              "gu": "હું જાણું છું એવા ઘણા વાલીઓ તેમના બાળકોને છાત્રાલયોમા રહેવા માટે સાથે મોકલવાનું આયોજન કરી રહ્યા છે. શું છાત્રાલયો સુરક્ષિત છે? બાળકો જાતે જ બધું  સંચાલન કેવી રીતે કરે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "They manage well, Aarti. They learn a lot from each other. Many of the students come from the same village, so they know each other, and it is easy for them to adjust.",
              "hi": "वे अच्छी तरह से प्रबंधन करते हैं, आरती। वे एक दूसरे से बहुत कुछ सीखते हैं। कई छात्र एक ही गांव से आते हैं, इसलिए वे एक-दूसरे को जानते हैं, और उनके लिए आपस में तालमेल बैठाना सहज है।",
              "ka": "ಅವರು ಚೆನ್ನಾಗಿ ನಿಭಾಯಿಸ್ಕೊಳ್ತಾರೆ, ಆರತಿ. ಅವರು ಒಬ್ಬರಿಂದ ಒಬ್ಬರು ಸಾಕಷ್ಟು ಕಲಿತುಕೊಳ್ಳುತ್ತಾರೆ. ಬೇಕಾದಷ್ಟೂ ಮಕ್ಕಳು ಅದೇ ಹಳ್ಳಿಯಿಂದ ಬಂದಿರೋದ್ರಿಂದ, ಅವರು ಒಬ್ಬರನ್ನೊಬ್ಬರು ನೋಡಿರ್ತಾರೆ, ಮತ್ತು ಹೊಂದಿಕೊಳ್ಳೋದಕ್ಕೆ ಸುಲಭ. ",
              "te": "వాళ్ళు బాగా చూసుకుంటారు ఆర్తి. ఒకరి నుంచి ఒకరు చాలా నేర్చుకుంటారు. చాలా మంది విద్యార్థులు ఒకే ఊరి నుంచి రావడంతో ఒకరికొకరు తెలుసు కాబట్టి సర్దుకుపోవడం సులువుగా ఉంటుంది.",
              "or": "ସେମାନେ ଭଲ ପରିଚାଳନା କରନ୍ତି, ଆରତୀ | ସେମାନେ ପରସ୍ପରଠାରୁ ବହୁତ କିଛି\n  ଶିଖନ୍ତି | ଅନେକ ଛାତ୍ର ଗୋଟିଏ ଗାଁରୁ ଆସିଛନ୍ତି, ତେଣୁ ସେମାନେ ପରସ୍ପରକୁ ଜାଣନ୍ତି,\n  ଏବଂ ସେମାନଙ୍କ ପାଇଁ ନିୟନ୍ତ୍ରଣ କରିବା ସହଜ ଅଟେ |",
              "as": "তেওঁলোকে ভালদৰে চম্ভালি ল'ব পাৰে, আৰতি। তেওঁলোকে ইজনে সিজনৰ পৰা বহুত শিকে। বহুতো ল'ৰা-ছোৱালী একেখন গাওঁৰ পৰা আহে, সেয়েহে তেওঁলোকে ইজনে সিজনক জানে, আৰু তেওঁলোকৰ বাবে মিলা-মিছা কৰা সহজ।",
              "gu": "આરતી.તેઓ સારી રીતે સંચાલન કરે છે,  તેઓ એકબીજા પાસેથી ઘણું શીખે છે. ઘણા વિદ્યાર્થીઓ એક જ ગામમાંથી આવે છે, તેથી તેઓ એકબીજાને ઓળખે છે, અને તેમના માટે સમજવુ સરળ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Neetu, this school is very far from home. Mira has never stayed away from us.",
              "hi": "नीतू, यह स्कूल घर से बहुत दूर है। मीरा कभी हमसे दूर नहीं रही।",
              "ka": "ನೀತು, ಈ ಶಾಲೆ ಮನೆಯಿಂದ ತುಂಬಾ ದೂರ. ಮೀರಾ ಯಾವತ್ತೂ ನಮ್ಮನ್ನು ಬಿಟ್ಟು ದೂರ ಇಲ್ಲ.",
              "te": "నీతూ, ఈ స్కూల్ ఇంటికి చాలా దూరం. మీరా ఎప్పుడూ మాకు దూరంగా ఉండలేదు.",
              "or": "ନୀତୁ, ଏହି ବିଦ୍ୟାଳୟ ଘରଠାରୁ ବହୁତ ଦୂରରେ | ମୀରା ଆମଠାରୁ କେବେ ବି ଦୂରରେ ରହିନାହାଁନ୍ତି |",
              "as": "নীতু, এই বিদ্যালয়খন আমাৰ ঘৰৰ পৰা বহু দূৰত। মীৰা কেতিয়াও আমাৰ পৰা আঁতৰি থকা নাই।",
              "gu": "નીતુ, આ શાળા ઘરથી ઘણી દૂર છે. મીરા ક્યારેય અમારાથી દૂર ગઈ નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "Aarti, why are you worrying about that? Till we are here, Mira will stay with us! There’s no need to send her to the hostel.",
              "hi": "आरती, आप इसके बारे में चिंता क्यों कर रही हैं? जब तक हम यहां हैं, मीरा हमारे साथ रहेगी! उसे छात्रावास में भेजने की आवश्यकता नहीं है।",
              "ka": "ಆರತಿ, ನೀನು ಯಾಕೆ ಅದರ ಬಗ್ಗೆ ಅಷ್ಟು ಯೋಚನೆ ಮಾಡ್ತಾ ಇದ್ದೀಯಾ? ನಾವು ಇಲ್ಲಿ ಇರುವ ತನಕ, ಮೀರಾ ನಮ್ಮ ಜೊತೆ ಇರ್ತಾಳೆ. ಅವಳನ್ನ ಹಾಸ್ಟೆಲ್ಗೆ ಕಳಿಸೋ ಪ್ರಮೇಯ ಇಲ್ಲ.",
              "te": "ఆర్తీ, దాని గురించి ఎందుకు చింతిస్తున్నావు? మేము ఇక్కడ ఉన్నంత వరకు, మీరా మాతో ఉంటుంది! ఆమెను హాస్టల్‌కి పంపాల్సిన అవసరం లేదు.",
              "or": "ଆରତୀ, ତୁମେ ସେ ବିଷୟରେ କାହିଁକି ଚିନ୍ତା କରୁଛ? ଆମେ ଏଠାରେ ରହିବା \n  ପର୍ଯ୍ୟନ୍ତ, ମୀରା ଆମ ସହିତ ରହିବେ! ତାଙ୍କୁ ହଷ୍ଟେଲକୁ ପଠାଇବା ଆବଶ୍ୟକ ନାହିଁ |",
              "as": "আৰতি, তুমি সেইটো কথা লৈ কিয় ইমান চিন্তা কৰিছা? আমি ইয়াত থকালৈকে, মীৰা আমাৰ সৈতে থাকিব! তাইক হোষ্টেললৈ পঠোৱাৰ কোনো প্ৰয়োজন নাই।",
              "gu": "આરતી, તું એની ચિંતા કેમ કરે છે? અમે અહીં છીએ ત્યાં સુધી મીરા અમારી સાથે રહેશે! તેને છાત્રાલયમા મોકલવાની જરૂર નથી."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_2_2",
              "hi": "X1_2_2",
              "ka": "X1_2_2",
              "te": "X1_2_2",
              "or": "X1_2_2",
              "as": "X1_2_2",
              "gu": "X1_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira has a great time taking a tour of the school. In a few months now, she will be moving to Guwahati to study in this school.",
              "hi": "मीरा स्कूल का दौरा करने में अच्छा समय बिताती है। कुछ महीनों में, वह इस स्कूल में अध्ययन करने के लिए गुवाहाटी जा रही है।",
              "ka": "ಮೀರಾ ಶಾಲೆ ಸುತ್ತಲೂ ಓಡಾಡೋದ್ರಲ್ಲಿ ಒಳ್ಳೆ ಸಮಯ ಕಳೆಯುತ್ತಾ ಇದ್ದಾಳೆ. ಇನ್ನೂ ಕೆಲವೇ ತಿಂಗಳುಗಳಲ್ಲಿ ಅವಳು ಗುವಹಟಿಗೆ ಈ ಶಾಲೆನಲ್ಲಿ ಓದಕ್ಕೆ ಬರ್ತಾಳೆ.",
              "te": "మీరా పాఠశాలను సందర్శించడానికి చాలా సమయం తీసుకుంటుంది. కొన్ని నెలల్లో, ఆమె ఈ పాఠశాలలో చదువుకోవడానికి గౌహతి వెళ్లనుంది",
              "or": "ମୀରା ବିଦ୍ୟାଳୟ ପରିଦର୍ଶନ କରିବା ପାଇଁ ବହୁତ ଭଲ ସମୟ | ବର୍ତ୍ତମାନ କିଛି ମାସ\n  ମଧ୍ୟରେ, ସେ ଏହି ବିଦ୍ୟାଳୟରେ ପଢିବା ପାଇଁ ଗୁଆହାଟୀକୁ ଯିବେ |",
              "as": "বিদ্যালয়খনত ফুৰিবলৈ আহি মীৰাই বৰ ভাল সময় কিছুমান পাৰ কৰিলে। এতিয়া কেইমাহমানৰ ভিতৰতে, তাই এই বিদ্যালয়খনতত পঢ়িবলৈ গুৱাহাটীলৈ আহিব।",
              "gu": "મીરા પાસે શાળાનો પ્રવાસ લેવામાં ખૂબ સરસ સમય છે હવે થોડા મહિનામાં, તે આ શાળામાં અભ્યાસ કરવા માટે ગુવાહાટી જશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_3_1",
              "hi": "X1_3_1",
              "ka": "X1_3_1",
              "te": "X1_3_1",
              "or": "X1_3_1",
              "as": "X1_3_1",
              "gu": "X1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is back home packing up for Guwahati. Her brother is helping her out.",
              "hi": "मीरा गुवाहाटी जाने के लिए पैकिंग करने घर  वापस आ गई है। उसका भाई उसकी मदद कर रहा है।",
              "ka": "ಮೀರಾ ಮನೆಗೆ ವಾಪಸ್ಸು ಬಂದು ಗುವಹಾಟಿಗೆ ಹೋಗೋಕ್ಕೆ ಪ್ಯಾಕ್ ಮಾಡಿಕೊಳ್ತಾ ಇದ್ದಾಳೆ. ಅವಳ ತಮ್ಮ ಆವಳಿಗೆ ಸಹಾಯ ಮಾಡ್ತಾ ಇದ್ದಾನೆ.",
              "te": "మీరా గౌహతి వెళ్ళడానికి తన లగేజీని సర్దుకోవడానికి ఇంటికి తిరిగి వచ్చింది. ఆమె సోదరుడు ఆమెకు సహాయం చేస్తున్నాడు.",
              "or": "ମୀରା ଗୁଆହାଟି ପାଇଁ ପ୍ୟାକ୍ କରି ଘରକୁ ଫେରିଛନ୍ତି | ତାଙ୍କ ଭାଇ ତାଙ୍କୁ ସାହାଯ୍ୟ \n କରୁଛନ୍ତି |",
              "as": "মীৰা ঘৰলৈ উভতি আহি গুৱাহাটীৰ বাবে বয়-বস্তু পেকিং কৰিবলৈ লাগিছে। তাইৰ ভায়েকে তাইক সহায় কৰি দিছে।",
              "gu": "મીરા ગુવાહટી માટે ઘરે પાછી આવી ગઈ છે. તેનો ભાઈ તેની મદદ કરી રહ્યો છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_3_2//",
              "hi": "X1_3_2//",
              "ka": "X1_3_2//",
              "te": "X1_3_2//",
              "or": "X1_3_2//",
              "as": "X1_3_2//",
              "gu": "X1_3_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shantanu",
              "hi": "शांतनु",
              "ka": "ಶಂತನು",
              "te": "షాంతను",
              "or": "ଶାନ୍ତନୁ",
              "as": "শান্তনু",
              "gu": "શાંતનુ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Shantanu",
              "hi": "शांतनु",
              "ka": "ಶಂತನು",
              "te": "షాంతను",
              "or": "ଶାନ୍ତନୁ",
              "as": "শান্তনু",
              "gu": "શાંતનુ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Are you sure you want to go there in your 10th standard itself?",
              "hi": "क्या आप वाकई अपनी दसवीं कक्षा में वहां जाना चाहती  हैं?",
              "ka": "ನೀನು ಅಲ್ಲಿಗೆ 10 ನೇ ತರಗತಿಗೆ ಹೋಗ್ಬೇಕು ಅಂತ ನಿರ್ಧಾರ ಮಾಡಿದ್ದೀಯಾ?",
              "te": "నువ్వు ఖచ్చితంగా నీ 10వ తరగతిలోనే అక్కడికి వెళ్లాలనుకుంటున్నావా?",
              "or": "ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ନିଜ ଦଶମ ଷ୍ଟାଣ୍ଡାର୍ଡରେ ସେଠାକୁ ଯିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
              "as": "তুমি তাৰমানে সঁচাকৈ দশম শ্ৰেণী গুৱাহাটীত পঢ়িবলৈ যাবা ?",
              "gu": "શું તને ખાતરી છે કે તું તમારા 10મા ધોરણમાં જ ત્યાં જવા માંગો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Why? Are you going to miss me?",
              "hi": "क्यों? क्या तुम मुझे याद करोगे?",
              "ka": "ಯಾಕೆ? ನೀನು ನನ್ನನ್ನು ಮಿಸ್ ಮಾಡಿಕೊಳ್ತೀಯಾ/",
              "te": "ఎందుకు? నువ్వు నన్ను మిస్ అవుతున్నావా?",
              "or": "କାହିଁକି? ତୁମେ ମୋତେ ଝୁରିବ କି?",
              "as": "কিয়? মোলৈ তোমাৰ মনত পৰি থাকিব?",
              "gu": "શા માટે? શું તું મને યાદ કરીશ?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Miss you? Not really. It's just that when you are gone, Ma and Papa's attention will be on my studies, and I am not ready for it.",
              "hi": "तुम्हारी याद आएगी? बिलकुल नहीं। यह सिर्फ इतना है कि जब तुम चली जाओगी, माँ और पापा का ध्यान मेरे अध्ययन पर होगा, और मैं इसके लिए तैयार नहीं हूं।",
              "ka": "ನಿನ್ನನ್ನು ಮಿಸ್ ಮಾಡಿಕೊಳ್ಳೋದು? ನಿಜವಾಗಿ ಇಲ್ಲ. ಏನಿಲ್ಲ, ನೀನು ಹೋದ ಮೇಲೆ ಅಮ್ಮ ಮತ್ತು ಅಪ್ಪನ ಗಮನ ನನ್ನ ಓದಿನ ಮೇಲೆ ಪೂರ್ತಿ ಇರಟ್ಟೆ. ನಾನು ಈಗಲೇ ಅದಕ್ಕೆ ತಯಾರಾಗಿಲ್ಲ. ",
              "te": "మిస్ యూ? నిజంగా కాదు. నువ్వు వెళ్ళాక అమ్మ, నాన్నల దృష్టి నా చదువుపైనే ఉంటుంది, నేను దానికి సిద్ధంగా లేను.",
              "or": "ତୁମକୁ ଝୁରି ହେବା? ବାସ୍ତବରେ ସେମିତି ନୁହେ। ଏହା ଠିକ୍ ଯେ ଯେତେବେଳେ\n  ତୁମେ ଚାଲିଯାଅ, ମା ଏବଂ ପାପାଙ୍କ ଧ୍ୟାନ ମୋ ଅଧ୍ୟୟନରେ ରହିବ, ଏବଂ ମୁଁ ଏହା\n  ପାଇଁ ପ୍ରସ୍ତୁତ ନୁହେଁ |",
              "as": "তোমালৈ মনত পৰাটো আচল কথা নহয়। কিন্তু এইটো ঠিক যে যেতিয়া তুমি গুছি যাবা তেতিয়া, মা আৰু পাপাৰ মনোযোগ মোৰ পঢ়াশুনাত থাকিব, আৰু মই ইয়াৰ বাবে সাজু নহয়।",
              "gu": "તારી યાદ સતાવે છે? ખાતરી નથી. બસ એટલું જ કે જ્યારે તું જઈશ ત્યારે મા અને પપ્પાનું ધ્યાન મારા અભ્યાસ પર રહેશે, અને હું તેના માટે તૈયાર નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "When will you get serious about your studies? You have three years before your board exams.",
              "hi": "तुम अपनी पढ़ाई के बारे में कब गंभीर होंगे? तुम्हारे पास बोर्ड परीक्षा से पहले  तीन वर्ष  हैं।",
              "ka": "ನೀನು ನಿನ್ನ ಓದಿನ ಬಗ್ಗೆ ಯಾವಾಗ ಜವಾಬ್ದಾರಿ ತಗೊಳ್ಳೋದು? ನಿನಗೆ ಮೂರು ವರ್ಷ ಮಾತ್ರ ಇದೆ ಬೋರ್ಡ್ ಪರೀಕ್ಷೆ ತಗೊಳ್ಳೋಕ್ಕೆ.",
              "te": "నువ్వు చదువు విషయంలో ఇంకా ఎప్పుడు సీరియస్ గా ఉంటావు? నీ బోర్డు పరీక్షలకు ఇంకా మూడు సంవత్సరాలే సమయం ఉంది.",
              "or": "ତୁମର ଅଧ୍ୟୟନ ବିଷୟରେ ତୁମେ କେବେ ଗମ୍ଭୀର ହେବ? ତୁମର ବୋର୍ଡ ପରୀକ୍ଷା\n  ପୂର୍ବରୁ ତୁମର ତିନି ବର୍ଷ ଅଛି |",
              "as": "তুমি তোমাৰ পঢ়াশুনাবোৰ লৈ কেতিয়া চিৰিয়াছ হ'বা বাৰু? ব'ৰ্ডৰ পৰীক্ষা দিবলৈ তোমাৰ সন্মুখত আৰু কিন্তু তিনি বছৰহে আছে।",
              "gu": "તું તારા  અભ્યાસ વિશે ક્યારે ગંભીર બનીશ ? તમારી બોર્ડની પરીક્ષા પહેલા ત્રણ વર્ષ છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_3_3////",
              "hi": "X1_3_3////",
              "ka": "X1_3_3////",
              "te": "X1_3_3////",
              "or": "X1_3_3////",
              "as": "X1_3_3////",
              "gu": "X1_3_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "THREE years! I can start studying next year. You first tell me why you need to go to Guwahati.",
              "hi": "तीन साल! मैं अगले साल पढ़ाई शुरू कर सकता हूं। तुम पहले मुझे बताओ  कि तुमको गुवाहाटी जाने की आवश्यकता क्यों है।",
              "ka": "ಮೂರು ವರ್ಷಗಳು! ನಾನು ಮುಂದಿನ ವರ್ಷ ಓದಕ್ಕೆ ಶುರು ಮಾಡ್ತೀನಿ.ಮೊದಲು ನೀನು ನನಗೆ ಹೇಳು ನೀನು ಗುವಹತಿಗೆ ಯಾಕೆ ಹೋಗ್ಬೇಕು ಅಂತ.",
              "te": "మూడు సంవత్సరాలు! నేను వచ్చే సంవత్సరమే చదవడం మొదలు పెట్టగలను. నువ్వు గౌహతి ఎందుకు వెళ్లాలని అనుకుంటున్నావో ముందు చెప్పు.",
              "or": "ତିନି ବର୍ଷ! ମୁଁ ଆସନ୍ତା ବର୍ଷ ଅଧ୍ୟୟନ ଆରମ୍ଭ କରିପାରିବି | ତୁମେ ପ୍ରଥମେ ମୋତେ\n  କୁହ ଯେ ତୁମେ ଗୁଆହାଟିକୁ କାହିଁକି ଯିବା ଦରକାର |",
              "as": "তিনি বছৰ! মই অহা বছৰৰ পৰা পঢ়িবলৈ আৰম্ভ কৰিলেও হ'ব। তুমি কিয় গুৱাহাটীলৈ যাবলৈ ওলাইছা সেইটো প্ৰথমে মোক কৈ লোৱা।",
              "gu": "ત્રણ વર્ષ! હું આવતા વર્ષથી અભ્યાસ શરૂ કરી શકું છું.તો તું પહેલા મને કહો કે તારે શા  માટે ગુવાહાટી જવાની જરૂર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Ranjan uncle says that moving to the city will help me with my studies, and I can learn a lot from him and Neetu Aunty.",
              "hi": "रंजन चाचा का कहना है कि शहर जाने से मेरी पढ़ाई में मुझे सहायता मिलेगी, और मैं उनसे और नीतू चाची से बहुत कुछ सीख सकती हूं।",
              "ka": "ರಂಜನ್ ಅಂಕಲ್ ಹೇಳ್ತಾ ಇದ್ರು ನಾನು ನಗರಕ್ಕೆ ಹೋದ್ರೆ ನನ್ನ ಓದಿಗೆ ಸಹಾಯ ಆಗತ್ತೆ ಅಂತ, ನಾನು ಅವರಿಂದ ಮತ್ತು ನೀತು ಆಂಟಿ ಇಂದ ಬೇಕಾದಷ್ಟೂ ಕಲೀಬಹ್ದು",
              "te": "రంజన్ అంకుల్ సిటీకి వెళ్లడం వల్ల నా చదువుకు బాగుంటుందని చెప్పారు. అతని నుంచి మరియు నీతూ ఆంటీ నుంచి చాలా నేర్చుకోవచ్చు.",
              "or": "ରଂଜନ ମାମୁଁ କହିଛନ୍ତି ଯେ ସହରକୁ ଯିବା ମୋର ଅଧ୍ୟୟନରେ ସାହାଯ୍ୟ କରିବ,\n  ଏବଂ ମୁଁ ତାଙ୍କ ଏବଂ ନୀତୁମାଉସୀଙ୍କଠାରୁ ବହୁତ କିଛି ଶିଖିପାରେ |",
              "as": "ৰঞ্জন খুৰায়ে কৈছে যে চহৰলৈ যোৱাটোৱে মোক মোৰ পঢ়াশুনাত সহায় কৰিব, আৰু মই খুড়া আৰু নীতু আণ্টিৰ পৰা বহুতো শিকিব পাৰিম।",
              "gu": "રંજન કાકા કહે છે કે શહેરમાં જવાથી મને મારા અભ્યાસમાં મદદ મળશે, અને હું તેમની અને નીતુ કાકી પાસેથી ઘણું શીખી શકું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Uff, why are you always so serious about studies?",
              "hi": "उफ्फ, तुम हमेशा अध्ययन के बारे में इतना गंभीर क्यों रहती हो?",
              "ka": "ಉಫ್, ನೀನು ಯಾಕೆ ಓದೋದ್ರ ಬಗ್ಗೆ ಯಾವಾಗ್ಲೂ ಅಷ್ಟು ಸೀರಿಯಸ್ ಆಗಿರ್ತೀಯಾ?",
              "te": "అయ్యో, నువ్వు ఎప్పుడూ చదువు విషయంలో ఎందుకు అంత సీరియస్‌గా ఉంటావు?",
              "or": "ଆରେ , ଅଧ୍ୟୟନ ପାଇଁ ତୁମେ ସବୁବେଳେ ଏତେ ଗମ୍ଭୀର କାହିଁକି?",
              "as": "উস্। তুমি তোমাৰ পঢ়া-শুনাবোৰ লৈয়েই কিয় ইমান ব্যস্ত হৈ থাকানো?",
              "gu": "ઉફ્ફ, તું હંમેશા અભ્યાસ માટે આટલા ગંભીર કેમ રહે છે ?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Because, unlike you, Shantanu, she is studying hard to make a great career for herself.\nLearn something from her. It’s high time you also get serious.",
              "hi": "क्योंकि, शांतनु,  तुम्हारे विपरीत, वह स्वयं के लिए एक महान करियर बनाने के लिए कड़ी मेहनत कर रही है।\nउससे कुछ सीखो। अब समय है कि तुम भी गंभीर हो जाओ।",
              "ka": "ಯಾಕೆ ಅಂದ್ರೆ, ಶಂತನು, ಅವಳು ನಿನ್ನ ತರ ಅಲ್ಲ, ಅವಳು ತನ್ನ ಮುಂದಿನ ಜೀವನ ಅಭಿವೃದ್ಧಿ ಮಾಡ್ಕೊಳ್ಳೋಕೆ ಶ್ರದ್ಧೆ ಇತ್ತು ಓದುತ್ತಾ ಇದ್ದಾಳೆ. ಅವಳಿಂದ ಸ್ವಲ್ಪ ಕಲಿತುಕೊ. ನೀನು ಕೂಡಾ ಸೀರಿಯಸ್ ಆಗೋ ಕಾಲ ಬಂದಿದೆ. ",
              "te": "“ఎందుకంటే, షాంతను, నీలా కాకుండా, తనకంటూ ఒక గొప్ప కెరీర్ ని సంపాదించుకోవడానికి కష్టపడి చదువుతోంది. తనని చూసి ఏదైనా నేర్చుకో. నువ్వు కూడా సీరియస్‌గా ఉండాల్సిన సమయం వచ్చింది.\"",
              "or": "କାରଣ, ଶାନ୍ତନୁଙ୍କ ପରି, ସେ ନିଜ ପାଇଁ ଏକ ମହାନ କ୍ୟାରିଅର୍ କରିବାକୁ କଠିନ ଅଧ୍ୟୟନ କରୁଛନ୍ତି |\n \n ତାଙ୍କଠାରୁ କିଛି ଶିଖନ୍ତୁ | ଏହା ମଧ୍ୟ ସମୟ ଅଟେ ଯେ ଆପଣ ମଧ୍ୟ ଗମ୍ଭୀର ହୁଅନ୍ତି |",
              "as": "কিয়নো, শান্তনু, তোমাৰ বিপৰীতে তাই নিজৰ বাবে এটা ভাল কেৰিয়াৰ গঢ়িবলৈ কষ্ট কৰি আছে।\n তাইৰ পৰা কিবা শিকাঁ। তুমিও মনোযোগ দিয়াৰ সময় হৈছে।",
              "gu": "કારણ કે, તારાથી વિપરીત, શાંતનુ, તે પોતાની જાતને સારી કારકિર્દી બનાવવા માટે સખત અભ્યાસ કરી રહી છે. તેની પાસેથી કંઈક શીખ . આ સમય છે કે તું પણ ગંભીર બન"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "This is exactly what I was talking about.",
              "hi": "यह वही है जिसके विषय में मैं बात कर रहा था।",
              "ka": "ಇದನ್ನೇ ನಾನು ಮಾತಾಡ್ತಾ ಇದ್ದದ್ದು",
              "te": "నేను చెబుతుంది సరిగ్గా ఇదే.",
              "or": "ମୁଁ ଠିକ୍ ସେହି ବିଷୟରେ କହୁଥିଲି |",
              "as": "ময়ো ঠিক এইটো কথায়েই কৈ আছিলোঁ।",
              "gu": "આ તે જ છે જેની હું વાત કરતો હતો."
            }
          }
        ]
      },
      "scene2": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_1_1",
              "hi": "X2_1_1",
              "ka": "X2_1_1",
              "te": "X2_1_1",
              "or": "X2_1_1",
              "as": "X2_1_1",
              "gu": "X2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira moves to Guwahati for her 10th standard. She lives with her aunt and joins the school where her aunt is a teacher.",
              "hi": "मीरा अपनी दसवीं कक्षा के लिए गुवाहाटी जाती है। वह अपनी चाची के साथ रहती है और उस स्कूल में दाखिला लेती है जहां उसकी चाची एक शिक्षिका हैं।",
              "ka": "ಮೀರಾ 10 ನೇ ತರಗತಿಗೆ ಗುವಹತಿಗೆ ಹೋದಳು. ಅವಳು ಅವಳ ಆಂಟಿ ಜೊತೆ ಇದ್ದು ಅವಳ ಆಂಟಿ ಕೆಲಸ ಮಾಡ್ತೀರೊ ಶಾಲೆಗೆ ಸೇರಿಕೊಳ್ತಾಳೆ.",
              "te": "మీరా తన 10వ తరగతి కోసం గౌహతికి వెళ్లింది. ఆమె తన అత్తతో కలిసి ఉంటుంది మరియు ఆమె అత్త ఉపాధ్యాయురాలిగా ఉన్న పాఠశాలలో చేరింది.",
              "or": "ମୀରା ନିଜର ଦଶମ ପଢିବା ପାଇଁ ଗୁଆହାଟିକୁ ଚାଲିଆସନ୍ତି | ସେ ତାଙ୍କ ମାଉସୀ \n ସହିତ ରୁହନ୍ତି ଏବଂ ଯେଉଁ ମାଉସୀ ଶିକ୍ଷକ ଅଟନ୍ତି ସେହି ବିଦ୍ୟାଳୟରେ ଯୋଗ ଦିଅନ୍ତି |",
              "as": "মীৰাই তাইৰ দশম শ্ৰেণীৰ শিক্ষাৰ বাবে গুৱাহাটীলৈ যায়। তাই তাইৰ খুড়ীয়েকৰ সৈতে থাকে আৰু বিদ্যালয়ত নামভৰ্তি কৰে। তাইৰ খুড়ীয়েকো সেইখন বিদ্যালয়ৰে শিক্ষয়িত্ৰী।",
              "gu": "મીરા તેના 10મા ધોરણ માટે ગુવાહાટી જાય છે. તે તેની કાકી સાથે રહે છે અને તે શાળામાં જોડાય છે જ્યાં તેની કાકી શિક્ષક છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_2_1",
              "hi": "X2_2_1",
              "ka": "X2_2_1",
              "te": "X2_2_1",
              "or": "X2_2_1",
              "as": "X2_2_1",
              "gu": "X2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She is working hard to get good marks. She usually waits for her practical science classes. She loves doing experiments in the lab.",
              "hi": "वह अच्छे अंक प्राप्त करने के लिए कड़ी मेहनत कर रही है। वह सामान्यतः  विज्ञान  की प्रयोगात्मक कक्षाओं  की प्रतीक्षा करती है। वह प्रयोगशाला में प्रयोग करना पसंद करती है।",
              "ka": "ಅವಳು ಸಾಕಷ್ಟು ಕಷ್ಟ ಪಡ್ತಾ ಇದ್ದಾಳೆ ಒಳ್ಳೆ ಮಾರ್ಕ್ಸ್ ತೆಗೆದುಕೊಳ್ಳೋಕ್ಕೆ. ಅವಳು ಯಾವಾಗಲೂ ಪ್ರಾಯೋಗಿಕ ವಿಜ್ಞಾನ ತರಗತಿಗೆ ಕಾಯ್ತಾ ಇರ್ತಾಳೆ. ಅವಳಿಗೆ ಪ್ರಯೋಗ ಶಾಲೆಯಲ್ಲಿ ಪ್ರಯೋಗ ಮಾಡುವುದು ಅಂದ್ರೆ ತುಂಬಾ ಇಷ್ಟ. ",
              "te": "మంచి మార్కులు తెచ్చుకోవాలని ఆమె బాగా కష్టపడుతుంది. ఆమె ఎక్కువగా తన ప్రాక్టికల్ సైన్స్ క్లాసుల కోసం ఎదురు చూస్తూ ఉంటుంది. ల్యాబ్‌లో ప్రయోగాలు చేయడం ఆమెకు చాలా ఇష్టం.",
              "or": "ଭଲ ମାର୍କ ପାଇବା ପାଇଁ ସେ କଠିନ ପରିଶ୍ରମ କରୁଛନ୍ତି | ସେ ସାଧାରଣତ \n  ନିଜର ବ୍ୟବହାରିକ ବିଜ୍ଞାନ ଶ୍ରେଣୀ ପାଇଁ ଅପେକ୍ଷା କରନ୍ତି | ସେ ଲ୍ୟାବରେ ପରୀକ୍ଷଣ \n କରିବାକୁ ଭଲ ପାଆନ୍ତି |",
              "as": "তাই ভাল নম্বৰ পাবলৈ বৰ কষ্ট কৰি পঢ়িছে। তাই সাধাৰণতে ব্যৱহাৰিক বিজ্ঞানৰ শ্ৰেণীটো বৰ ভাল পায়। তাই পৰীক্ষাগাৰত পৰীক্ষা-নিৰীক্ষা কৰিবলৈ ভাল পায়।",
              "gu": "સારા ગુણ મેળવવા માટે તે સખત મહેનત કરી રહી છે. તે સામાન્ય રીતે તેના પ્રાયોગિક વિજ્ઞાનના વર્ગોની રાહ જુએ છે. તેને લેબમાં પ્રયોગો કરવાનું પસંદ છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_3_1",
              "hi": "X2_3_1",
              "ka": "X2_3_1",
              "te": "X2_3_1",
              "or": "X2_3_1",
              "as": "X2_3_1",
              "gu": "X2_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Preeti and Lina, who stay in the hostel, are her classmates and lab partners. They are helping her adjust to the new school. They spend a lot of time together and have become good friends.",
              "hi": "प्रीति और लीना, जो छात्रावास में रहती हैं, उसकी सहपाठी और प्रयोगशाला की साथी हैं। वे उसे नए स्कूल में एडजस्ट करने में मदद कर रही हैं। वे एक साथ बहुत समय बिताते हैं और अच्छे दोस्त बन गए हैं।",
              "ka": "ಪ್ರೀತಿ ಮತ್ತು ಲೀನ, ಹಾಸ್ಟೆಲ್ನಲ್ಲಿ ಇರುವವರು, ಅವಳ ಸಹಪಾಠಿಗಳು ಮತ್ತು  ಪ್ರಯೋಗ ಶಾಲೆಯಲ್ಲಿ ಜೊತೆಗಾರ್ತಿಗಳು  ಆಗಿದ್ದಾರೆ.",
              "te": "హాస్టల్‌లో ఉంటున్న ప్రీతి, లీనా ఆమె క్లాస్‌మేట్స్ మరియు ల్యాబ్ పార్టనర్‌లు. వారు కొత్త పాఠశాలలో అడ్జస్ట్ అవ్వడానికి ఆమెకు సహాయం చేస్తున్నారు. వారు చాలా సమయం కలిసి గడిపారు మరియు మంచి స్నేహితులయ్యారు.",
              "or": "ହଷ୍ଟେଲରେ ରହୁଥିବା ପ୍ରୀତି ଏବଂ ଲୀନା ତାଙ୍କର ସହପାଠୀ ଏବଂ ଲ୍ୟାବ ପାର୍ଟନର\n  ଅଟନ୍ତି | ସେମାନେ ତାଙ୍କୁ ନୂତନ ବିଦ୍ୟାଳୟରେ ଆଡଜଷ୍ଟ କରିବାରେ ସାହାଯ୍ୟ କରୁଛନ୍ତି\n  | ସେମାନେ ଏକାଠି ବହୁତ ସମୟ ବିତାଇ ଭଲ ବନ୍ଧୁ ହୋଇଗଲେ |",
              "as": "হোষ্টেলত থকা প্ৰীতি আৰু লীনা তাইৰ সহপাঠী আৰু পৰীক্ষাগাৰৰ লগৰী। তেওঁলোকে তাইক নতুন বিদ্যালয়খনৰ পৰিবেশৰ সৈতে খাপ খোৱাত সহায় কৰি আছে। তেওঁলোকে একেলগে বহুসময় অতিবাহিত কৰে। তেওঁলোক ভাল বন্ধু হৈ পৰিছে।",
              "gu": "છાત્રાલયમા રહેતી પ્રીતિ અને લીના તેના વર્ગ સાથીઓ અને લેબ ભાગીદારો છે. તેઓ તેને નવી શાળામાં સમજાવવામા મદદ કરી રહ્યા છે. તેઓ સાથે ઘણો સમય વિતાવે છે અને સારા મિત્રો બની ગયા છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_3_2//",
              "hi": "X2_3_2//",
              "ka": "X2_3_2//",
              "te": "X2_3_2//",
              "or": "X2_3_2//",
              "as": "X2_3_2//",
              "gu": "X2_3_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Preeti, Lina and Mira are conducting experiments in the lab with a microscope when Preeti suddenly turns to her.",
              "hi": "प्रीति, लीना और मीरा एक माइक्रोस्कोप के साथ प्रयोगशाला में प्रयोग कर रहे हैं जब प्रीति अचानक उसकी तरफ पलटती है।",
              "ka": "ಪ್ರೀತಿ, ಲೀನ ಮತ್ತು ಮೀರಾ ಪ್ರಯೋಗ ಶಾಲೆನಲ್ಲಿ ಮೈಕ್ರೋ ಸ್ಕೋಪ್ ಜೊತೆಗೆ ಪ್ರಯೋಗಗಳನ್ನು ಮಾಡ್ತೀರೊವಾಗ ಪ್ರೀತಿ ಸಡನ್ ಆಗಿ ಅವಳ ಕಡೆ ತಿರುಗುತ್ತಾಳೆ.",
              "te": "ప్రీతి, లీనా మరియు మీరా మైక్రోస్కోప్‌తో ల్యాబ్‌లో ప్రయోగాలు చేస్తుండగా, ప్రీతి ఒక్కసారిగా ఆమె వైపు తిరిగింది.",
              "or": "ପ୍ରୀତି, ଲୀନା ଏବଂ ମୀରା ମାଇକ୍ରୋସ୍କୋପ୍ ସାହାଯ୍ୟରେ ଲ୍ୟାବ୍ ରେ ପରୀକ୍ଷଣ \n କରୁଥିଲେ ଯେତେବେଳେ ପ୍ରୀତି ତା ଆଡକୁ ଫେରେ |",
              "as": "প্ৰীতি, লীনা আৰু মীৰাই অণুবীক্ষণ যন্ত্ৰৰ দ্বাৰা পৰীক্ষা-নিৰীক্ষা চলাই থকাৰ মাজত প্ৰীতিয়ে হঠাতে তাইৰ ফালে ঘূৰি যায়।",
              "gu": "પ્રીતિ, લીના અને મીરા માઈક્રોસ્કોપ વડે પ્રયોગશાળામાં પ્રયોગો કરી રહ્યા છે ત્યારે પ્રીતિ અચાનક તેની તરફ ફરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Preeti",
              "hi": "प्रीति ",
              "ka": "ಪ್ರೀತಿ",
              "te": "ప్రీతి",
              "or": "ପ୍ରୀତି",
              "as": "প্ৰীতি",
              "gu": "પ્રીતિ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Lina",
              "hi": "लीना",
              "ka": "ಲೀನ",
              "te": "లీనా",
              "or": "ଲୀନା",
              "as": "লীনা",
              "gu": "લીના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Teacher",
              "hi": "अध्यापक",
              "ka": "ಟೀಚರ್",
              "te": "టీచర్",
              "or": "ଶିକ୍ଷକ",
              "as": "শিক্ষয়ত্ৰী",
              "gu": "શિક્ષક"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Preeti",
              "hi": "प्रीति",
              "ka": "ಪ್ರೀತಿ",
              "te": "ప్రీతి",
              "or": "ପ୍ରୀତି",
              "as": "প্ৰীতি",
              "gu": "પ્રીતિ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Mira, if you won’t talk to your aunt, I will",
              "hi": "मीरा, अगर तुम अपनी चाची से बात नहीं करोगी, तो मैं करूंगी ",
              "ka": "ಮೀರಾ, ನೀನು ನಿನ್ನ ಆಂಟಿ ಜೊತೆ ಮಾತಾಡ್ಡೆ ಇದ್ದರೆ, ನಾನು ಮಾತಾಡ್ತೀನಿ",
              "te": "మీరా, నువ్వు మీ అత్తతో మాట్లాడకపోతే నేను మాట్లాడతాను.",
              "or": "ମୀରା, ଯଦି ତୁମେ ତୁମର ମାଉସୀ ସହିତ କଥା ହେବ ନାହିଁ, ମୁଁ କରିବି |",
              "as": "মীৰা, যদি তুমি তোমাৰ খুড়ীৰ সৈতে কথা নাপাতা, তেন্তে ময়ে পাতিম।",
              "gu": "મીરા, જો તું તારી કાકી સાથે વાત નહિ કરે તો હું કરીશ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Yes, Mira, you have to come for the sleepover to the hostel. We will both talk to her.",
              "hi": "हां, मीरा, तुमको स्लीपओवर के लिए छात्रावास में आना होगा। हम दोनों उनसे बात करेंगे।",
              "ka": "ಹೌದು, ಮೀರಾ, ನೀನು ನಮ್ಮ ಜೊತೆ ಹಾಸ್ಟೆಲ್ಲಿಗೆ ಮಲಗಿಕೊಳ್ಳೋಕ್ಕೆ ಬರ್ಬೆಕು. ನಾವು ಇಬ್ಬರೂ ಅವರ ಜೊತೆ ಮಾತಾಡ್ತೀವಿ",
              "te": "అవును మీరా, నువ్వు హాస్టల్‌కి స్లీప్‌ఓవర్‌కి రావాలి. మేమిద్దరం ఆమెతో మాట్లాడతాం.",
              "or": "ହଁ, ମୀରା, ତୁମେ ହଷ୍ଟେଲକୁ ଶୋଇବା ପାଇଁ ଆସିବାକୁ ପଡିବ | ଆମେ ଦୁହେଁ ତାଙ୍କ\n  ସହ କଥା ହେବା |",
              "as": "হয়, মীৰা, তুমি হোষ্টেলত থাকিবলৈ আহিব লাগিব। আমি দুয়ো তেওঁৰ সৈতে কথা পাতিম।",
              "gu": "હા, મીરા, તારે છાત્રાલયમા ઊંઘવામાટે ઉપર આવવું પડશે. અમે બંને તેમની  સાથે વાત કરીશું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "You three, why are you talking? Preeti, did you dissect the leaf?",
              "hi": "तुम तीन, तुम क्यों बात कर रहे हो? प्रीति, क्या तुमने पत्ती का विच्छेदन किया?",
              "ka": "ನೀವು ಮೂವರು, ಯಾಕೆ ಮಾತಾಡ್ತಾ ಇದ್ದೀರಿ? ಪ್ರೀತಿ, ನೀನು ಎಲೆಯನ್ನು ಡಿಸೆಕ್ಟ್ ಮಾಡಿದ್ದು ಆಯ್ತಾ?",
              "te": "మీ ముగ్గురూ ఎందుకు మాట్లాడుతున్నారు? ప్రీతీ, ఆకును విడదీశావా?",
              "or": "ହଁ, ମୀରା, ତୁମେ ହଷ୍ଟେଲକୁ ଶୋଇବା ପାଇଁ ଆସିବାକୁ ପଡିବ | ଆମେ ଦୁହେଁ ତାଙ୍କ\n  ସହ କଥା ହେବା |",
              "as": "তোমালোক তিনিজনীয়ে কিয় কথা পাতি আছা? প্ৰীতি, তুমি পাতটো বিভাজন কৰিছিলানে?",
              "gu": "તમે  ત્રણ, કેમ વાત કરો છો? પ્રીતિ, તેં પાનનું છેદન કર્યું?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "Yes, yes, ma’am, I am dissecting it!",
              "hi": "हाँ, हाँ, महोदया, मैं इसका विच्छेदन कर रही हूँ!",
              "ka": "ಹೌದು, ಹೌದು, ಮೇಡಂ, ನಾನು ಡಿಸೆಕ್ಟ್ ಮಾಡ್ತಾ ಇದ್ದೀನಿ!",
              "te": "అవును, అవును, మేడమ్, నేను దానిని విడదీస్తున్నాను!",
              "or": "ହଁ, ହଁ, ମା, ମୁଁ ଏହାକୁ ବିଛିନ୍ନ କରୁଛି!",
              "as": "হয় হয় মেডাম। মই বিভাজন কৰি আছোঁ।",
              "gu": "હા, હા, મેડમ, હું પાનનું છેદન કરું છું!"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_3_3////",
              "hi": "X2_3_3////",
              "ka": "X2_3_3////",
              "te": "X2_3_3////",
              "or": "X2_3_3////",
              "as": "X2_3_3////",
              "gu": "X2_3_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Mira, help me. I don't know what to do.",
              "hi": "मीरा, मेरी सहायता करो। मुझे नहीं पता क्या करना है।",
              "ka": "ಮೀರಾ, ನನಗೆ ಸಹಾಯ ಮಾಡು. ನನಗೆ ಏನು ಮಾಡ್ಬೇಕು ಅಂತ ತಿಳೀತಾ ಇಲ್ಲ.",
              "te": "మీరా, నాకు సహాయం చెయ్యి. ఏం చేయాలో తెలియడం లేదు.",
              "or": "ମୀରା, ମୋତେ ସାହାଯ୍ୟ କର | ମୁଁ କ’ଣ କରିବି ଜାଣେ ନାହିଁ |",
              "as": "মীৰা, মোক সহায় কৰাচোন। কি কৰিব লাগে মই একো বুজি পোৱা নাই।",
              "gu": "મીરા, મને મદદ કર. મને ખબર નથી કે શું કરવું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Wait Preeti, give me the blade.",
              "hi": "रुको प्रीति, मुझे ब्लेड दो ।",
              "ka": "ಪ್ರೀತಿ, ಸ್ವಲ್ಪ ತಡಿ, ನನಗೆ ಬ್ಲೇಡ್ ಕೊಡು. ",
              "te": "ఆగు ప్రీతీ, నాకు బ్లేడ్ ఇవ్వు.",
              "or": "ପ୍ରୀତି ଅପେକ୍ଷା କର, ମୋତେ ବ୍ଲେଡ୍ ଦିଅ |",
              "as": "ৰ'বা প্ৰীতি, ব্লেডখন মোক দিয়া।",
              "gu": "રાહ જુઓ પ્રીતિ, મને બ્લેડ આપો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Mira dissects the leaf.",
              "hi": "मीरा पत्ती का विच्छेदन करती है।",
              "ka": "ಮೀರಾ ಎಲೆಯನ್ನು ಡಿಸೆಕ್ಟ್ ಮಾಡ್ತಾಳೆ.",
              "te": "మీరా ఆకును విడదీస్తుంది.",
              "or": "ମୀରା ପତ୍ରକୁ ଅଲଗା କରେ |",
              "as": "মীৰাই পাতটো বিভাজন কৰি দিয়ে।",
              "gu": "મીરા પાન કાપી નાખે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "So should we talk to your aunt? We need to plan this quickly!",
              "hi": "तो क्या हमें तुम्हारी चाची से बात करनी चाहिए? हमें इसकी योजना जल्दी से बनाने की आवश्यकता है!",
              "ka": "ಅಂದ್ರೆ ನಾವು ನಿನ್ನ ಆಂಟಿ ಜೊತೆ ಮಾತಾದ್ಬೆಕಾ? ಇದನ್ನು ನಾವು ಬೇಗ ಯೋಚಿಸ್ಬೇಕು",
              "te": "అయితే మేము మీ అత్తతో మాట్లాడాలా? మనం దీన్ని త్వరగా ప్లాన్ చేసుకోవాలి!",
              "or": "ତେବେ ଆମେ ତୁମ ମାଉସୀ ସହିତ କଥା ହେବା ଉଚିତ୍ କି? ଆମେ ଏହାକୁ ଶୀଘ୍ର\n  ଯୋଜନା କରିବା ଆବଶ୍ୟକ!",
              "as": "গতিকে, আমি খুড়ীৰ লগত কথা পাতিব পাৰোঁনে? আমি সোনকালে কামটো কৰাটো ভাল হ'ব!",
              "gu": "તો તારી કાકી સાથે વાત કરવી જોઈએ? અમારે આ ઝડપથી યોજના કરવાની જરૂર છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Can you both calm down? I spoke to my aunt. She needs the warden's phone number.",
              "hi": "क्या तुम दोनों शांत हो सकते हो? मैंने अपनी चाची से बात की। उन्हें  वार्डन के फोन नंबर की जरूरत है।",
              "ka": "ನೀವಿಬ್ಬರೂ ಸ್ವಲ್ಪ ಶಾಂತವಾಗ್ತೀರ? ನಾನು ಇದರ ಬಗ್ಗೆ ಆಗ್ಲೆ ಆಂಟಿ ಹತ್ತಿರ ಮಾತಾಡಿದ್ದೀನಿ. ಅವರಿಗೆ ವಾರ್ಡನ್ ಫೋನ್ ನಂಬರ್ ಬೇಕಂತೆ. ",
              "te": "మీరిద్దరూ సైలెంట్ గా ఉండగలరా? నేను మా అత్తతో మాట్లాడాను. ఆమెకు వార్డెన్ ఫోన్ నంబర్ కావాలి.",
              "or": "ତୁମେ ଦୁହେଁ ଶାନ୍ତ ହୋଇପାରିବ କି? ମୁଁ ମୋର ମାଉସୀ ସହିତ କଥା ହେଲି | ତାଙ୍କୁ\n  ୱାର୍ଡେନର ଫୋନ୍ ନମ୍ବର ଦରକାର |",
              "as": "ফোন নম্বৰ।",
              "gu": "શું તમે બંને શાંત થઈ શકશો? મેં મારી કાકી સાથે વાત કરી. તેને વોર્ડનના ફોન નંબરની જરૂર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.5.text",
            "value": {
              "en": "Really!!",
              "hi": "सच में!!",
              "ka": "ನಿಜವಾಗ್ಲೂ!!",
              "te": "నిజమే!!",
              "or": "ସତରେ!!",
              "as": "সঁচা!!",
              "gu": "ખાતરી"
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_3_4//",
              "hi": "X2_3_4//",
              "ka": "X2_3_4//",
              "te": "X2_3_4//",
              "or": "X2_3_4//",
              "as": "X2_3_4//",
              "gu": "X2_3_4//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Preeti and Lina",
              "hi": "प्रीति और लीना",
              "ka": "ಪ್ರೀತಿ ಮತ್ತು ಲೀನ ",
              "te": "ప్రీతి మరియు లీనా",
              "or": "ପ୍ରୀତି ଓ ଲୀନା",
              "as": "প্ৰীতি আৰু লীনা",
              "gu": "પ્રીતિ અને લીના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Teacher",
              "hi": "अध्यापक",
              "ka": "ಟೀಚರ್",
              "te": "టీచర్",
              "or": "ଶିକ୍ଷକ",
              "as": "শিক্ষয়ত্ৰী",
              "gu": "શિક્ષક"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Wohoo!!",
              "hi": "वोहूहू!!",
              "ka": "ಓಹೋ!!",
              "te": "అయ్యో!!",
              "or": "ଆରେରେ!!",
              "as": "ওহু!!",
              "gu": "વાહ!!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "KEEP QUIET!",
              "hi": "शांत रहें!",
              "ka": "ಸ್ವಲ್ಪ ಸುಮ್ಮನಿರಿ!",
              "te": "నిశ్శబ్దంగా ఉండండి!",
              "or": "ଶାନ୍ତ ହୁଅ!",
              "as": "মনে মনে থাকা!",
              "gu": "શાંતિ જાળવો!"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_4_1",
              "hi": "X2_4_1",
              "ka": "X2_4_1",
              "te": "X2_4_1",
              "or": "X2_4_1",
              "as": "X2_4_1",
              "gu": "X2_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The next day in school, the teacher informs them that choosing the subjects for Class 11 and 12 is the first of the many big career decisions they have to make in their lives.",
              "hi": "अगले दिन स्कूल में, शिक्षिका उन्हें  सूचित करती हैं कि कक्षा 11 और 12 के विषयों का चुनाव उनके जीवन के कई बड़े करियर निर्णयों में से पहला निर्णय है।",
              "ka": "ಮುಂದಿನ ದಿವಸ ಶಾಲೆಯಲ್ಲಿ, ಟೀಚರ್ ಅವರಿಗೆ ಹೇಳಿದ್ರು, 11 ಮತ್ತು 12 ನೇ ತರಗತಿಗಳಲ್ಲಿ ಅವರು ಆಯ್ಕೆ ಮಾಡಿಕೊಳ್ಳುವ ವಿಷಯಗಳು ಅವರ ಮುಂದಿನ ಜೀವನದ ಬಗೆಗಿನ ಅತ್ಯಂತ ಪ್ರಮುಖ ನಿರ್ಧಾರಗಳಲ್ಲಿ ಒಂದಾಗಿರುತ್ತದೆ ಎಂದು.",
              "te": "మరుసటి రోజు పాఠశాలలో 11 మరియు 12 తరగతులకు సంబంధించిన సబ్జెక్టులు సెలెక్ట్ చేసుకోవడం అనేది వారి జీవితంలో వారు తీసుకోవలసిన అతి పెద్ద నిర్ణయాలలో మొదటిది అని ఉపాధ్యాయురాలు వారికి తెలియజేస్తుంది.",
              "or": "ପରଦିନ ବିଦ୍ୟାଳୟରେ, ଶିକ୍ଷକ ସେମାନଙ୍କୁ ସୂଚନା ଦିଅନ୍ତି ଯେ ୧୧ ଏବଂ ୧୨ଶ୍ରେଣୀ ପାଇଁ ବିଷୟ ବାଛିବା ସେମାନଙ୍କ ଜୀବନରେ ଅନେକ ବଡ଼ କ୍ୟାରିୟର ନିଷ୍ପତ୍ତି ମଧ୍ୟରୁ ପ୍ରଥମ |",
              "as": "পিছদিনা বিদ্যালয়ত, শিক্ষকে তেওঁলোকক জনায় যে একাদশ আৰু দ্বাদশ শ্ৰেণীৰ বাবে বিষয়বাছনি কৰাটো হৈছে, তেওঁলোকে তেওঁলোকৰ জীৱনত ল'ব লগা বহুতো ডাঙৰ কেৰিয়াৰৰ সিদ্ধান্তৰ ভিতৰতে প্ৰথম সিদ্ধান্ত।",
              "gu": "શાળામાં બીજા દિવસે, શિક્ષક તેમને જાણ કરે છે કે ધોરણ 11 અને 12 માટે વિષયોની પસંદગી એ તેમના જીવનમાં કારકિર્દીના ઘણા મોટા નિર્ણયોમાંથી પ્રથમ છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_4_2",
              "hi": "X2_4_2",
              "ka": "X2_4_2",
              "te": "X2_4_2",
              "or": "X2_4_2",
              "as": "X2_4_2",
              "gu": "X2_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She explains to them that they need to choose between three fields - Science, Arts and Commerce. Mira knows she wants to study science.",
              "hi": "वह उन्हें बताती है कि उन्हें तीन विषयों - विज्ञान, कला और वाणिज्य के बीच चयन करने की आवश्यकता है। मीरा जानती है कि वह विज्ञान का अध्ययन करना चाहती है।",
              "ka": "ಅವರುಗಳಿಗೆ ವಿವರವಾಗಿ ತಿಳಿಸಿದ್ರು, ವಿಜ್ಞಾನ, ಕಲೆ ಮತ್ತು ವಾಣಿಜ್ಯ ಇವುಗಳಲ್ಲಿ ಯಾವುದನ್ನಾದರೂ ಒಂದನ್ನು ಆರಿಸಿಕೊಳ್ಳಬೇಕೆಂದು. ಮೀರಾಗೆ ತಿಳಿದಿದೆ ತಾನು ವಿಜ್ಞಾನವನ್ನೇ ಓದುತ್ತೇನೆಂದು.",
              "te": "సైన్స్, ఆర్ట్స్ మరియు కామర్స్ అనే మూడు రంగాలను ఎంచుకోవాలని ఆమె వారికి వివరిస్తుంది. మీరా కి తెలుసు తను సైన్స్ చదవాలి అనుకుతుంది",
              "or": "ସେ ସେମାନଙ୍କୁ ବୁଝାନ୍ତି ଯେ ସେମାନେ ତିନୋଟି କ୍ଷେତ୍ର ମଧ୍ୟରେ ଚୟନ\n  କରିବା ଆବଶ୍ୟକ କରନ୍ତି - ବିଜ୍ଞାନ, କଳା ଏବଂ ବାଣିଜ୍ୟ | ମୀରା ଜାଣନ୍ତି ଯେ ସେ \n ବିଜ୍ଞାନ ଅଧ୍ୟୟନ କରିବାକୁ ଚାହୁଁଛନ୍ତି |",
              "as": "তেখেতে তেওঁলোকক বুজাই দিয়ে যে তেওঁলোকে তিনিটা ক্ষেত্ৰৰ মাজত বাছনি কৰিব লাগিব - বিজ্ঞান, কলা আৰু বাণিজ্য। মীৰাই জানে যে তাই বিজ্ঞানহে অধ্যয়ন কৰিব বিচাৰে।",
              "gu": "તે તેમને સમજાવે છે કે તેઓએ ત્રણ ક્ષેત્રો - વિજ્ઞાન, કલા અને વાણિજ્યમાંથી એક પસંદ કરવાની જરૂર છે. મીરા જાણે છે કે તે વિજ્ઞાનનો અભ્યાસ કરવા માંગે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_4_3//",
              "hi": "X2_4_3//",
              "ka": "X2_4_3//",
              "te": "X2_4_3//",
              "or": "X2_4_3//",
              "as": "X2_4_3//",
              "gu": "X2_4_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Teacher",
              "hi": "अध्यापक",
              "ka": "ಟೀಚರ್",
              "te": "టీచర్",
              "or": "ଶିକ୍ଷକ",
              "as": "শিক্ষয়ত্ৰী",
              "gu": "શિક્ષક"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Preeti",
              "hi": "प्रीति",
              "ka": "ಪ್ರೀತಿ",
              "te": "ప్రీతి",
              "or": "ପ୍ରୀତି",
              "as": "প্ৰীতি",
              "gu": "પ્રીતિ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "If you choose to take up science, you will have to decide what subjects you are interested in taking up. This will help you select the course of your choice in college.",
              "hi": "यदि आप विज्ञान लेना चुनते हैं, तो आपको यह तय करना होगा कि आप किस विषय को लेने में रुचि रखते हैं। यह आपको कॉलेज में अपनी पसंद के कोर्स का चयन करने में सहायक होगा ।",
              "ka": "ನೀನು ವಿಜ್ಞಾನವನ್ನು ಆಯ್ಕೆ ಮಾಡಿದರೆ, ಅದರಲ್ಲಿ ಯಾವ ವಿಷಯಗಳನ್ನು ಓದಲು ಇಚ್ಚೆ ಪಡುತ್ತೇನೆಂದು. ಅದು ನಿನಗೆ ಕಾಲೇಜಿನಲ್ಲಿ ಯಾವ ಕೋರ್ಸ್ ಆಯ್ಕೆ ಮಾಡಬೇಕು ಎಂದು ತೀರ್ಮಾನ ಮಾಡಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "నువ్వు సైన్స్ ని సెలెక్ట్ చేసుకోవాలనుకుంటే, నీకు ఏ సబ్జెక్టులు తీసుకోవాలనుకుంటున్నావో నిర్ణయించుకోవాలి. కాలేజీలో నీకు నచ్చిన కోర్సును ఎంచుకోవడానికి ఇది నీకు సహాయం చేస్తుంది.",
              "or": "ଯଦି ଆପଣ ବିଜ୍ଞାନ ଗ୍ରହଣ କରିବାକୁ ବାଛିଛନ୍ତି, ତେବେ ଆପଣ କେଉଁ ବିଷୟ ଗ୍ରହଣ\n  କରିବାକୁ ଆଗ୍ରହୀ ତାହା ସ୍ଥିର କରିବାକୁ ପଡିବ | ଏହା ଆପଣଙ୍କୁ କଲେଜରେ ଆପଣଙ୍କ\n  ପସନ୍ଦର ପାଠ୍ୟକ୍ରମ ବାଛିବାରେ ସାହାଯ୍ୟ କରିବ |",
              "as": "যদি তুমি বিজ্ঞান ল'ব বিচাৰা, তেন্তে তুমি কি বিষয় পঢ়িবলৈ আগ্ৰহী সেয়া সিদ্ধান্ত ল'ব লাগিব। এইটোৱে তোমাক মহাবিদ্যালয়ত নিজৰ পচন্দৰ পাঠ্যক্ৰম বাছনি কৰাত সহায় কৰিব।",
              "gu": "જો તું વિજ્ઞાન લેવાનું પસંદ કરો છો, તો તમારે નક્કી કરવું પડશે કે તને કયા વિષયો લેવામા રસ છે. આ તને કૉલેજમાં તમારી પસંદગીનો કોર્સ પસંદ કરવામાં મદદ કરશે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "What are the options, ma’am?",
              "hi": "विकल्प क्या हैं, महोदया?",
              "ka": "ಯಾವ ಯಾವ ಆಯ್ಕೆ ಇವೆ ಮೇಡಂ?",
              "te": "అందుబాటులో ఉన్న ఎంపికలు ఏమిటి మేడమ్?",
              "or": "ବିକଳ୍ପଗୁଡ଼ିକ କ’ଣ, ମ୍ୟାମ?",
              "as": "মেডাম, বিকল্পবোৰনো কি?",
              "gu": "શું વિકલ્પો છે, મેડમ?"
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_4_4////",
              "hi": "X2_4_4////",
              "ka": "X2_4_4////",
              "te": "X2_4_4////",
              "or": "X2_4_4////",
              "as": "X2_4_4////",
              "gu": "X2_4_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Physics and Chemistry are compulsory, but you can choose between Biology and Maths.",
              "hi": "भौतिकी और रसायन शास्त्र अनिवार्य हैं, लेकिन आप जीवविज्ञान और गणित के बीच चयन कर सकते हैं।",
              "ka": "ಭೌತ ಶಾಸ್ತ್ರ ಹಾಗೂ ರಸಾಯನ ಶಾಸ್ತ್ರ ಎರಡೂ ಕಡ್ಡಾಯ, ಆದರೆ ನೀವು ಜೀವ ವಿಜ್ಞಾನ ಮತ್ತು ಗಣಿತ ಶಾತ್ರದ ಮಧ್ಯೆ ಆಯ್ಕೆ ಮಾಡಿಕೊಳ್ಳಬೇಕು.",
              "te": "ఫిజిక్స్ మరియు కెమిస్ట్రీ తప్పనిసరి, కానీ మీరు బయాలజీ మరియు మ్యాథ్స్ మధ్య ఎంచుకోవచ్చు.",
              "or": "ପଦାର୍ଥ ବିଜ୍ଞାନ ଏବଂ ରସାୟନ ବିଜ୍ଞାନ ବାଧ୍ୟତାମୂଳକ, କିନ୍ତୁ ଆପଣ ବାୟୋଲୋଜି \n ଏବଂ ଗଣିତ ମଧ୍ୟରେ ବାଛିପାରିବେ |",
              "as": "পদাৰ্থ বিজ্ঞান আৰু ৰসায়ন বিজ্ঞান বাধ্যতামূলক, কিন্তু তুমি জীৱবিজ্ঞান আৰু গণিতৰ মাজত কিবা এটা বাছি ল'ব পাৰিবা।",
              "gu": "ભૌતિકશાસ્ત્ર અને રસાયણશાસ્ત્ર ફરજિયાત છે, પરંતુ તું જીવવિજ્ઞાન અને ગણિત વચ્ચે પસંદગી કરી શકો છો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "But I don’t know what I want to do after the 12th.",
              "hi": "लेकिन मुझे नहीं पता कि मैं बारहवीं के बाद क्या करना चाहती हूं।",
              "ka": "ಆದರೆ, ನನಗೆ 12 ನೇ ತರಗತಿ ಆದ ಮೇಲೆ ಏನು ಮಾಡಬೇಕು ಎಂದು ತಿಳಿಯುತ್ತಿಲ್ಲ",
              "te": "కానీ 12వ క్లాస్ తర్వాత నేను ఏమి చేయాలనుకుంటున్నానో నాకు తెలియదు.",
              "or": "କିନ୍ତୁ ମୁଁ ଜାଣେ ନାହିଁ ଦ୍ୱାଦଶ ପରେ ମୁଁ କଣ କରିବାକୁ ଚାହୁଁଛି |",
              "as": "কিন্তু মই নাজানো দ্বাদশ শ্ৰেণীৰ পিছত মই কি কৰিব বিচাৰোঁ।",
              "gu": "પરંતુ મને ખબર નથી કે હું 12મા ધોરણ પછી શું કરવા માંગુ છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Why don’t you take an aptitude test? These tests are available online, and they help you understand what subjects are suited for you based on your abilities.",
              "hi": "तुम योग्यता परीक्षा क्यों नहीं लेतीं? ये परीक्षण ऑनलाइन उपलब्ध हैं, और आपकी क्षमताओं के आधार पर आपके लिए कौन से विषय अनुकूल हैं, वे समझने में सहायता करती हैं।",
              "ka": "ನೀನು ಒಂದು ಅಭಿರುಚಿ ಪರೀಕ್ಷೆಯನ್ನು ಏಕೆ ತೆಗೆದುಕೊಳ್ಳಬಾರದು? ಅವುಗಳು ಆನ್ ಲೈನಿನಲ್ಲಿ ಲಭ್ಯತೆ ಇವೆ, ಹಾಗೂ ಅವು ನಿನ್ನ ಸಾಮರ್ಥ್ಯದ ಮೇಲೆ ಯಾವ ವಿಷಯವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು ಎಂದು ನಿರ್ಧರಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತವೆ, ",
              "te": "మీరు ఆప్టిట్యూడ్ పరీక్ష ఎందుకు తీసుకోరు? ఈ పరీక్షలు ఆన్‌లైన్‌లో అందుబాటులో ఉన్నాయి మరియు మీ సామర్థ్యాల ఆధారంగా మీకు ఏ సబ్జెక్టులు సరిపోతాయో అర్థం చేసుకోవడంలో ఇవి సహాయపడతాయి.",
              "or": "ଆପଣ କାହିଁକି ଏକ ଦକ୍ଷତା ପରୀକ୍ଷା ନଉ ନାହାନ୍ତି ? ଏହି ପରୀକ୍ଷାଗୁଡ଼ିକ ଅନ୍ଲାଇନ ରେ ଉପଲବ୍ଧ, \n ଏବଂ ତୁମର ଦକ୍ଷତା ଉପରେ ଆଧାର କରି କେଉଁ ବିଷୟଗୁଡ଼ିକ ତୁମ ପାଇଁ ଉପଯୁକ୍ତ ତାହା ବୁଝିବାରେ \n ସାହାଯ୍ୟ କରେ |",
              "as": "তুমিচোন এপ্টিটিউড পৰীক্ষা দিব পাৰা? এই পৰীক্ষাবোৰ অনলাইনতে কৰিব পাৰি, আৰু সেইবোৰে তোমাক নিজৰ সামৰ্থ্যৰ ওপৰত আধাৰ কৰি তোমাৰ বাবে কি বিষয় উপযুক্ত হ'ব বুজাত সহায় কৰে।",
              "gu": "તું યોગ્યતા ટેસ્ટ  કેમ નથી કરાવતી ? આ પરીક્ષઓ ઓનલાઈન ઉપલબ્ધ છે, અને તે તને સમજવામાં મદદ કરે છે કે તારી ક્ષમતાઓના આધારે તારા માટે કયા વિષયો યોગ્ય છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_1_2",
              "hi": "X2_1_2",
              "ka": "X2_1_2",
              "te": "X2_1_2",
              "or": "X2_1_2",
              "as": "X2_1_2",
              "gu": "X2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is confused about which subjects she should consider. When she reaches home, she sees her Uncle and Aunty sitting in the hall.",
              "hi": "मीरा दुविधा में है कि उसे किस विषय पर विचार करना चाहिए। जब वह घर पहुंचती है, तो वह हॉल में बैठे अपने चाचा और चाची को देखती है।",
              "ka": "ಮೀರಾಳಿಗೆ ತಾನು ಯಾವ ವಿಷಯಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿಕೊಳ್ಳಬೇಕು ಎನ್ನುವುದರ ಬಗ್ಗೆ ಸ್ವಲ್ಪ ಸಂದೇಹವಿದೆ. ಅವಳು ಮನೆಗೆ ತಲುಪಿದಾಗ, ಆಂಟಿ ಮತ್ತು ಅಂಕಲ್ ಹಾಲಿನಲ್ಲಿ ಕುಳಿತಿರುವುದನ್ನು ನೋಡುತ್ತಾಳೆ. ",
              "te": "మీరా ఏ సబ్జెక్ట్‌లను పరిగణనలోకి తీసుకోవాలో తెలియక అయోమయంలో ఉంది. ఇంటికి రాగానే హాల్లో కూర్చున్న అంకుల్, ఆంటీని చూసింది.",
              "or": "ସେ କେଉଁ ବିଷୟ ଉପରେ ଧ୍ୟାନ ଦେବା ଉଚିତ୍ ସେ ବିଷୟରେ ମୀରା ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛନ୍ତି |\n  ଯେତେବେଳେ ସେ ଘରେ ପହଞ୍ଚନ୍ତି, ସେତେବେଳେ ସେ ତାଙ୍କ ମାମୁଁ ଏବଂ ମାଉସୀ \n ହଲରେ ବସିଥିବାର ଦେଖନ୍ତି |",
              "as": "মীৰাই কোনবোৰ বিষয় বিবেচনা কৰা উচিত হ'ব তাক লৈ বিভ্ৰান্ত হ'ল। তাই আহি ঘৰত উপস্থিত হোৱাত, খুৰাক আৰু খুৰীয়েকক হলঘৰটোত বহি থকা দেখিলে।",
              "gu": "મીરા મૂંઝવણમાં છે કે તે કયા વિષયો પર વિચાર કરવો જોઈએ. જ્યારે તે ઘરે પહોંચે છે, ત્યારે તે તેના કાકા અને કાકીને હોલમાં બેઠેલા જુએ છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_2_2_D",
              "hi": "X2_2_2_D",
              "ka": "X2_2_2_D",
              "te": "X2_2_2_D",
              "or": "X2_2_2_D",
              "as": "X2_2_2_D",
              "gu": "X2_2_2_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should she do?",
              "hi": "उसे क्या करना चाहिए?",
              "ka": "ಅವಳು ಏನು ಮಾಡಬೇಕು?",
              "te": "ఆమె ఏమి చేయాలి?",
              "or": "ସେ କଣ କରିବା ଉଚ୍ଚିତ ?",
              "as": "তাই কি কৰিলেনো ভাল হয়?",
              "gu": "તેને શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Talk to her uncle and aunt about her subjects",
              "hi": "अपने विषयों  के बारे में अपने चाचा और चाची से बात करे",
              "ka": "ಅವಳು ತೆಗೆದುಕೊಳ್ಳಬಹುದಾದ ವಿಷಯಗಳ ಬಗ್ಗೆ ಆಂಟಿ ಮತ್ತು ಅಂಕಲ್ ಹತ್ತಿರ ಮಾತಾಡಬೇಕೆ ",
              "te": "ఆమె విషయాల గురించి ఆమె అంకుల్ మరియు ఆంటీతో మాట్లాడటం.",
              "or": "ତାଙ୍କ ମଉସା ମାଉସୀ ଙ୍କ ସହିତ ତାର ବିଷୟ କଥା ହେବା |",
              "as": "তাই খুৰাক, খুড়ীয়েকৰ সৈতে তাইৰ বিষয়বোৰৰ বিষয়ে কথা পাতক",
              "gu": "તેના કાકા અને કાકી સાથે તેના વિષયો વિશે વાત કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Get ready for the sleepover and think about the subjects later",
              "hi": "स्लीप ओवर के लिए तैयार हो जाए और बाद में विषयों के बारे में सोचे",
              "ka": "ಅಥವಾ ಹಾಸ್ಟೆಲ್ಲಿನಲ್ಲಿ ಮಲಗುವ ಬಗ್ಗೆ ಮಾತನಾಡಿ ನಂತರ ವಿಷಯಗಳ ಬಗ್ಗೆ ಮಾತನಾಡುವುದೇ ",
              "te": "ఇప్పుడు నిద్ర పోవడం కోసం సిద్ధంగా అవ్వండి మరియు ఈ \n  విషయాలను గురించి తర్వాత ఆలోచిదాం",
              "or": "ଶୋଇବା ପାଇଁ ପ୍ରସ୍ତୁତ ହୁଅ ଓ ବିଷୟ ଗୁଡିକ ବାବଦରେ ପରେ ଚିନ୍ତା କରିବା|",
              "as": "হোষ্টেল আহিবলৈ সাজু হওক আৰু পিছত বিষয়বোৰৰ বিষয়ে চিন্তা কৰক",
              "gu": "સુવાની તૈયારી કરો  અને વિષયો વિશે પછીથી વિચારી શું"
            }
          }
        ]
      },
      "scene3": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Talk to uncle and aunt",
              "hi": "चाचा और चाची से बात करे",
              "ka": "ಅಂಕಲ್ ಮತ್ತು ಆಂಟಿಯ ಬಳಿ",
              "te": "అంకుల్ మరియు ఆంటీతో మాట్లాడటం.",
              "or": "ମଉସା ମାଉସୀଙ୍କ ସହ କଥା ହୁଅ|",
              "as": "খুড়া খুড়ীৰ সৈতে কথা পাতা",
              "gu": "કાકા અને કાકી સાથે વાત કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira decides to talk to her Uncle and Aunty about the subjects she has to take in the 11th and 12th.",
              "hi": "मीरा ग्यारहवीं और बारहवीं में लेने वाले विषयों के बारे में अपने चाचा और चाची से बात करने का फैसला करती है।",
              "ka": "ಮೀರಾ 11 ಮತ್ತು 12 ನೇ ತರಗತಿಗಳಲ್ಲಿ ತಾನು ತೆಗೆದುಕೊಳ್ಳಬಹುದಾದ ವಿಷಯಗಳ ಬಗ್ಗೆ ಅಂಕಲ್ ಮತ್ತು ಆಂಟಿ ಜೊತೆ ಮಾತನಾಡಲು ನಿರ್ಧಾರ ಮಾಡುತ್ತಾಳೆ.",
              "te": "మీరా 11వ మరియు 12వ తరగతిలో తాను తీసుకోవలసిన సబ్జెక్ట్‌ల గురించి తన అంకుల్ మరియు ఆంటీతో మాట్లాడాలని నిర్ణయించుకుంది.",
              "or": "୧୧ ଓ ୧୨ ଶ୍ରେଣୀରେ ନେବାକୁ ଥିବା ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ଏ ନିଜ ମଉସା ମାଉସୀ \n ଙ୍କ ସହିତ କଥା ହେବ ବୋଲି ନିଷ୍ପତ୍ତି ନେଲା|",
              "as": "মীৰাই তাইৰ খুড়াক আৰু খুডীয়েকৰ সৈতে 11 আৰু 12 তাৰিখে ল'ব লগা বিষয়বোৰৰ বিষয়ে কথা পাতিবলৈ সিদ্ধান্ত লয়।",
              "gu": "મીરાએ 11મા અને 12મા ધોરણમાં જે વિષયો લેવાના છે તેના વિશે તેના કાકા અને કાકી સાથે વાત કરવાનું નક્કી કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_2//",
              "hi": "X3_1_2//",
              "ka": "X3_1_2//",
              "te": "X3_1_2//",
              "or": "X3_1_2//",
              "as": "X3_1_2//",
              "gu": "X3_1_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Ranjan Uncle",
              "hi": "रंजन चाचा ",
              "ka": "ರಂಜನ್ ಅಂಕಲ್ ",
              "te": "రంజన్ అంకుల్",
              "or": "ରଞ୍ଜନ ମଉସା",
              "as": "ৰঞ্জন খুড়া",
              "gu": "રંજન કાકા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Neetu Aunty",
              "hi": "नीतू चाची",
              "ka": "ನೀತು ಆಂಟಿ",
              "te": "నీతూ ఆంటీ",
              "or": "ନୀତୁ ମାଉସୀ",
              "as": "নীতু খুড়ী",
              "gu": "નીતુ કાકી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Maths is such an important subject. More importantly, it’s something you would use every day, regardless of what job you take up.",
              "hi": "गणित एक महत्वपूर्ण विषय है। सबसे महत्वपूर्ण बात यह है कि यह कुछ ऐसा है जो आप हर दिन उपयोग करेंगे, भले ही आप कोई भी नौकरी करते हैं।",
              "ka": "ಗಣಿತ ಶಾಸ್ತ್ರವು ಒಂದು ಪ್ರಮುಖ ವಿಷಯ. ಅದಕ್ಕಿಂತಲೂ ಮುಖ್ಯವಾದುದೆಂದರೆ, ಅದು ನಿಮ್ಮ ದಿನ ನಿತ್ಯದಲ್ಲಿ ಉಪಯೋಗಿಸುವಂತದು, ನೀವು ಬೇರೆ ಏನು ಕೆಲಸವನ್ನು ಮಾಡಿದರೂ ಸಹ.",
              "te": "గణితం అంత ముఖ్యమైన సబ్జెక్ట్. మరీ ముఖ్యంగా మీరు ఏ ఉద్యోగంలో చేరినా దీన్ని ప్రతిరోజూ ఉపయోగించాల్సి ఉంటుంది.",
              "or": "ଗଣିତ ହେଉଛି ଏକ ମୁଖ୍ୟ ବିଷୟ| ମୁଖ୍ଯତଃ ଦେଖିବାକୁ ଗଲେ, କେଉଁ ଚାକିରି କରିବା ଠାରୁ \n  ତୁମେ ଏହାକୁ ପ୍ରତିଦିନ ବ୍ୟବହାର \n କରିବ",
              "as": "অংক ইমান গুৰুত্বপূৰ্ণ বিষয়। তাতোকৈ ডাঙৰ কথা, তুমি যি কামেই নকৰা কিয়, অংক সদায় লাগিবই।",
              "gu": "ગણિત એ એક મહત્વનો વિષય છે. વધુ મહત્ત્વની વાત એ છે કે તું તું દરરોજ ઉપયોગ કરીશ, પછી જે પણ નોકરી લો છો તેને ધ્યાનમાં લીધા વિના,  ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "But Ranjan, that’s the case with any of the sciences. I think she should think about what she plans to do for a career and decide accordingly.",
              "hi": "लेकिन रंजन,  विज्ञान के किसी भी विषय के साथ ऐसा ही है। मुझे लगता है कि उसे इस बारे में सोचना चाहिए कि वह करियर के लिए क्या करने की योजना बना रही है और उसी के अनुसार तय करे।",
              "ka": "ಆದರೆ, ರಂಜನ್, ಅದು ಎಲ್ಲಾ ವಿಜ್ಞಾನದ ವಿಷಯಗಳಿಗೂ ಹೊಂದುತ್ತದೆ. ನನ್ನ ಪ್ರಕಾರ ಅವಳು ತನ್ನ ಮುಂದಿನ ಭವಿಷ್ಯ ಹಾಗೂ ಯಾವ ವೃತ್ತಿಯಲ್ಲಿ ಮುಂದುವರೆಯಬೇಕು ಎನ್ನುವುದನ್ನು ಗಮನದಲ್ಲಿಟ್ಟುಕೊಂಡು ನಿರ್ಧಾರ ತೆಗೆದುಕೊಳ್ಳಬೇಕು.  ",
              "te": "కానీ రంజన్, సైన్స్ విషయంలోనూ అలానే ఉంటుంది. ఆమె కెరీర్ కోసం ఆమె ఏమి చేయాలనే దాని గురించి ఆలోచించి దాని ప్రకారం నిర్ణయం తీసుకోవాలని నేను అనుకుంటున్నాను.",
              "or": "କିନ୍ତୁ ରଂଜନ, ଯେ କୌଣସି ବିଜ୍ଞାନରେ ତାହା ହେଉଛି | ମୁଁ ଭାବୁଛି ସେ କ୍ୟାରିୟର \n ପାଇଁ କଣ କରିବାକୁ ଯୋଜନା କରୁଛନ୍ତି ସେ ବିଷୟରେ ଚିନ୍ତା କରିବା ଉଚିତ ଏବଂ \n ସେହି ଅନୁଯାୟୀ ନିଷ୍ପତ୍ତି ନେବା ଉଚିତ୍ |",
              "as": "কিন্তু ৰঞ্জন, যিকোনো বিজ্ঞানৰ ক্ষেত্ৰত এনেকুৱাই হয়। মই ভাবো তাই কেৰিয়াৰৰ বাবে কি কৰাৰ পৰিকল্পনা কৰিছে সেই বিষয়েহে চিন্তা কৰা উচিত আৰু সেই অনুসৰিয়েই সিদ্ধান্ত লোৱা উচিত।",
              "gu": "પણ રંજન, કોઈપણ વિજ્ઞાનમાં એવું જ છે. મને લાગે છે કે તેને કારકિર્દી માટે શું કરવાની યોજના છે તે વિશે વિચારવું જોઈએ અને તે મુજબ નિર્ણય લેવો જોઈએ."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_3////",
              "hi": "X3_1_3////",
              "ka": "X3_1_3////",
              "te": "X3_1_3////",
              "or": "X3_1_3////",
              "as": "X3_1_3////",
              "gu": "X3_1_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Taking Maths will open up so many career options like Engineering, Architecture or even Statistics!",
              "hi": "गणित लेने से इंजीनियरिंग, वास्तुकला या यहां तक ​​कि स्टैटिसटिक्स/ सांख्यिकी जैसे कई करियर विकल्प खुलेंगे!",
              "ka": "ಗಣಿತ ಶಾಸ್ತ್ರವನ್ನು ತೆಗೆದುಕೊಳ್ಳುವುದರಿಂದ ಮುಂದೆ ಸಾಕಷ್ಟು ವೃತ್ತಿಗಳಲ್ಲಿ ಮುಂದುವರೆಯಲು ಆಯ್ಕೆಗಳಿರುತ್ತವೆ ಅಂದರೆ ಇಂಜಿನಿಯರಿಂಗ್, ಆರ್ಕಿಟೆಕ್ಚರ್ ಅಥವಾ ಸಂಖ್ಯಾ ಶಾಸ್ತಕ್ಕೆ ಸಂಬಂಧಪಟ್ಟಂತೆ!",
              "te": "మ్యాథ్స్ తీసుకోవడం వల్ల ఇంజినీరింగ్, ఆర్కిటెక్చర్ లేదా స్టాటిస్టిక్స్ వంటి అనేక కెరీర్ ఆప్షన్‌లు ఉంటాయి!",
              "or": "ଗଣିତ ଗ୍ରହଣ କରିବା ଦ୍ୱାରା ଇଞ୍ଜିନିୟରିଂ, ସ୍ଥାପତ୍ୟ କିମ୍ବା ପରିସଂଖ୍ୟାନ ପରି ଅନେକ\n  କ୍ୟାରିୟର ବିକଳ୍ପ ଖୋଲିବ |",
              "as": "অংক পঢ়িলে অভিযান্ত্ৰিক, স্থাপত্যবিদ্যা আনকি পৰিসংখ্যাৰ দৰে বহুতো কেৰিয়াৰৰ বাবে সুবিধা হ'ব!",
              "gu": "ગણિત એન્જિનિયરિંગ, આર્કિટેક્ચર અથવા તો આકડાશાસ્ત્ર જેવા કારકિર્દીના ઘણા વિકલ્પો ખુલશે!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, I agree, but what if she wants to take up a career in medical sciences? She has always been so interested in the practicals that we do in the biology class. What if she wants to do botany or zoology?",
              "hi": "हां, मैं सहमत हूं, लेकिन यदि वह मेडिकल साइंसेज में करियर लेना चाहती है? वह हमेशा प्रयोग के लिए इतनी उत्सुक रहती है जो  हम जीवविज्ञान की कक्षा में करते हैं। क्या होगा यदि वह बॉटनी या जूओलॉजी/ प्राणिशास्त्र में आगे बढ़ना चाहती है?",
              "ka": "ಹೌದು, ನಾನು ಒಪ್ಪುತ್ತೇನೆ. ಆದರೆ ಅವಳು ವೈದ್ಯಕೀಯ ವೃತ್ತಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಇಷ್ಟ ಪಟ್ಟಲ್ಲಿ? ಅವಳು ನಾವು ಮಾಡುವ ಜೀವ ಶಾಸ್ತ್ರದ ಪ್ರಯೋಗಗಳಲ್ಲಿ ಹೆಚ್ಚಿನ ಆಸಕ್ತಿಯನ್ನು ಯಾವಾಗಲೂ ಹೊಂದಿದ್ದಾಳೆ. ಅವಳು ಸಸ್ಯ ವಿಜ್ಞಾನ ಅಥವಾ ಪ್ರಾಣಿ ಶಾಸ್ತ್ರವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಬಯಸಿದಲ್ಲಿ ಏನು ಮಾಡುವುದು?",
              "te": "అవును, నేను అంగీకరిస్తున్నాను, అయితే ఆమె వైద్య శాస్త్రాలలో వృత్తిని చేపట్టాలనుకుంటే? బయాలజీ క్లాస్‌లో మేము చేసే ప్రాక్టికల్స్ పై ఆమెకు ఎప్పుడూ చాలా ఆసక్తిగా ఉంటుంది. ఆమె వృక్షశాస్త్రం లేదా జంతుశాస్త్రం చేయాలనుకుంటే?",
              "or": "ହଁ, ମୁଁ ରାଜି, କିନ୍ତୁ ଯଦି ସେ ଡାକ୍ତରୀ ବିଜ୍ଞାନରେ କ୍ୟାରିୟର କରିବାକୁ ଚାହୁଁଛନ୍ତି ତେବେ\n  କଣ ହେବ? ସେ ସବୁବେଳେ ଅଭ୍ୟାସ ପାଇଁ ଏତେ ଆଗ୍ରହୀ ଥିଲେ ଯେ ଆମେ ଜୀବବିଜ୍ଞାନ\n  ଶ୍ରେଣୀରେ କରିଥାଉ | ଯଦି ସେ ଉଦ୍ଭିଦ କିମ୍ବା ପ୍ରାଣୀ ବିଜ୍ଞାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି ତେବେ କଣ ହେବ?",
              "as": "হয়, কথাটো মিছা নহয়, কিন্তু তাই যদি চিকিৎসা বিজ্ঞানক কেৰিয়াৰ হিচাপে ল'ব বিচাৰে তেনেহ'লে কি হ'ব? তাই সদায় জীৱবিজ্ঞানৰ ব্যৱহাৰিক জ্ঞানৰ শ্ৰেণীত কৰা কামবোৰ ভাল পাইছিল। যদি তাই উদ্ভিদবিজ্ঞান বা প্ৰাণীবিজ্ঞান ল'ব বিচাৰে তেন্তে কি হ'ব?",
              "gu": "હા, હું સંમત છું, પરંતુ જો તે તબીબી વિજ્ઞાનમાં કારકિર્દી બનાવવા માંગતી હોય તો શું? અમે જીવવિજ્ઞાન વર્ગમાં કરીએ છીએ તે પ્રયોગોમાં તેને હંમેશા એટલો રસ છે. જો તે વનસ્પતિશાસ્ત્ર અથવા પ્રાણીશાસ્ત્ર કરવા માંગે તો શું?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "So let her only tell us. What do you want to do, Mira?",
              "hi": "तो उसको हमें यह बताने दीजिए । तुम क्या करना चाहती हो, मीरा?",
              "ka": "ಅಂದರೆ ಅವಳೇ ನಮಗೆ ಹೇಳಲಿ, ನೀನು ಏನು ಮಾಡಬೇಕೆಂದು ಇದ್ದೀಯಾ, ಮೀರಾ?",
              "te": "కాబట్టి మనకి ఆమెనే చెప్పనివ్వండి. నువ్వు ఏమి చేయాలనుకుంటున్నావు మీరా?",
              "or": "ତେଣୁ ସେ କେବଳ ଆମକୁ କୁହନ୍ତୁ | ମୀରା, ତୁମେ କଣ କରିବାକୁ ଚାହୁଁଛ?",
              "as": "ঠিক আছে। তাইক নিজেই আমাক ক'বলৈ দিয়া। তুমি নিজে কি বিচাৰা, কোৱাচোন মীৰা?",
              "gu": "તેથી તેને ફક્ત અમને કહેવા દો. તું શું કરવા માંગે છે મીરા?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "This decision looks tougher than I thought. I’m even more confused now.",
              "hi": "यह निर्णय जितना मैंने सोचा था उसकी तुलना में ज्यादा कठिन लग रहा है। मैं अब और भी दुविधा में हूं।",
              "ka": "ಇದರ ಬಗ್ಗೆ ನಿರ್ಧಾರ ಮಾಡುವುದು ಇನ್ನೂ ಕಷ್ಟವಾಯಿತು, ಈಗ ನಾನು ಮತ್ತಷ್ಟು ಜಿಜ್ಞಾಸೆಗೆ ಒಳಗಾಗಿದ್ದೇನೆ. ",
              "te": "ఈ నిర్ణయం నేను అనుకున్నదానికంటే కష్టంగా కనిపిస్తోంది. నేను ఇప్పుడు మరింత గందరగోళంలో ఉన్నాను.",
              "or": "ମୁଁ ଭାବିଥିବା ଅପେକ୍ଷା ଏହି ନିଷ୍ପତ୍ତି କଠୋର ଦେଖାଯାଏ | ମୁଁ ବର୍ତ୍ତମାନ ଆହୁରି \n ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛି |",
              "as": "এই সিদ্ধান্তটো মই ভবাটোতকৈও বেছি টান দেখোন। মোৰ এতিয়া আৰু বেছিহে খেলিমেলি লাগিছে।",
              "gu": "આ નિર્ણય મેં વિચાર્યું તેના કરતાં વધુ અઘરો લાગે છે. હું હવે વધુ મૂંઝવણમાં છું."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_2_1",
              "hi": "X3_2_1",
              "ka": "X3_2_1",
              "te": "X3_2_1",
              "or": "X3_2_1",
              "as": "X3_2_1",
              "gu": "X3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira decides to take some time to understand her options better. She goes to her room to get ready for the sleepover.",
              "hi": "मीरा अपने विकल्पों को ज्यादा अच्छे ढंग से समझने के लिए कुछ समय लेने का निश्चय करती है। वह स्लीपओवर के लिए तैयार होने के लिए  अपने कमरे में जाती है।",
              "ka": "ಮೀರಾ ತನ್ನ ಆಯ್ಕೆಗಳ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಲು ಇನ್ನಷ್ಟು ಸಮಯವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ನಿರ್ಧರಿಸಿದಳು. ಅವಳು ಸ್ಲೀಪ್ ಓವರ್ ಬಗ್ಗೆ ತಯಾರಿಯಾಗಲು ತನ್ನ ಕೊಠಡಿಗೆ ಹೋದಳು.",
              "te": "మీరా తన ఎంపికలను బాగా అర్థం చేసుకోవడానికి కొంత సమయం తీసుకోవాలని నిర్ణయించుకుంది. నిద్రపోవడని సిద్ధం కావడానికి ఆమె తన గదికి వెళుతుంది.",
              "or": "ମୀରା ତାଙ୍କ ବିକଳ୍ପକୁ ଭଲ ଭାବରେ ବୁଝିବା ପାଇଁ କିଛି ସମୟ ନେବାକୁ ସ୍ଥିର \n କରେ | ଶୋଇବା ପାଇଁ ପ୍ରସ୍ତୁତ ହେବାକୁ ସେ ନିଜ କୋଠରୀକୁ ଯାଆନ୍ତି |",
              "as": "মীৰাই তাইৰ বিকল্পবোৰৰ বিষয়ে ভালদৰে বুজিবলৈ কিছু সময় লোৱাৰ সিদ্ধান্ত লয়। তাই শুবৰ বাবে সাজু হৈ নিজৰ কোঠালৈ যায়।",
              "gu": "મીરા તેના વિકલ્પોને વધુ સારી રીતે સમજવા માટે થોડો સમય લેવાનું નક્કી કરે છે. તે સુવા માટે તૈયાર થવા તેના રૂમમાં જાય છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "DiscussionwithSignificantOthers",
              "hi": "अपनेपरिजनोंसेचर्चा",
              "ka": "ಮತ್ತೊಬ್ಬ ದೊಡ್ಡವರ ಜೊತೆಗೆ ಚರ್ಚೆ ಮಾಡೋಣ ",
              "te": "ముఖ్యమైన వ్యక్తులతో సమావేశం.",
              "or": "ଅନ୍ୟାନ୍ୟ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ବିଷୟଗୁଡ଼ିକ ସହିତ ଆଲୋଚନା |",
              "as": "আনৰ সৈতে গুৰুত্বপূৰ্ণ আলোচনা",
              "gu": "મહત્વના અન્ય લોકો સાથે ચર્ચા"
            }
          }
        ]
      },
      "scene4": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Think about subjects later",
              "hi": "बाद में विषयों के बारे में सोचे",
              "ka": "ವಿಷಯಗಳ ಬಗ್ಗೆ ಮುಂದೆ ಯೋಚಿಸೋಣ ",
              "te": "సబ్జెక్టుల గురించి తర్వాత ఆలోచించండి",
              "or": "ପରେ ବିଷୟଗୁଡ଼ିକ ବିଷୟରେ ଚିନ୍ତା କର |",
              "as": "পিছত বিষয়বোৰৰ বিষয়ে চিন্তা কৰা হওক",
              "gu": "વિષયો વિશે પછીથી વિચારો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira goes over to the hostel for the sleepover. When she reaches, she sees Preeti and Lina are busy doing something on the laptop.",
              "hi": "मीरा स्लीपओवर के लिए छात्रावास जाती है। जब वह पहुंचती है, तो वह देखती है प्रीति और लीना लैपटॉप पर कुछ करने में व्यस्त हैं।",
              "ka": "ಮೀರಾ ಹಾಸ್ಟೆಲ್ಲಿಗೆ ಸ್ಲೀಪ್ ಓವರ್ ಸಲುವಾಗಿ ಹೋದಳು. ಅಲ್ಲಿಗೆ ತಲುಪಿದಾಗ, ಅವಳು ಪ್ರೀತಿ ಹಾಗೂ ಲೀನ ಲ್ಯಾಪ್ಟಾಪ್ ಮೇಲೆ ಏನೋ ಮಾಡುತ್ತಿರುವುದನ್ನು ಕಂಡಳು.",
              "te": "మీరా స్లీప్ ఓవర్ కోసం హాస్టల్‌కి వెళుతుంది. ఆమె అక్కడికి వెళ్లినప్పుడు, ప్రీతి మరియు లీనా ల్యాప్‌టాప్‌లో ఏదో పనిలో బిజీగా ఉండటం చూస్తుంది.",
              "or": "ମିରା ଶୋଇବା ପାଇଁ ହଷ୍ଟେଲକୁ ଯାଏ | ଯେତେବେଳେ ସେ ପହଞ୍ଚେ, ସେ ଦେଖନ୍ତି \n ପ୍ରୀତି ଏବଂ ଲୀନା ଲାପଟପ୍ ରେ କିଛି କରିବାରେ ବ୍ୟସ୍ତ ଅଛନ୍ତି |",
              "as": "মীৰাই শুবলৈ হোষ্টেললৈ যায়। তাই হোস্টেলত উপস্থিত হৈ দেখিলে যে, প্ৰীতি আৰু লীনাই লেপটপত কিবা এটা কৰাত ব্যস্ত হৈ আছে।",
              "gu": "મીરા ઊંઘવામાટે માટે છાત્રાલયમાં જાય છે. જ્યારે તે પહોંચે છે, ત્યારે તે જુએ છે કે પ્રીતિ અને લીના લેપટોપ પર કંઈક કરવામાં વ્યસ્ત છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_2",
              "hi": "X4_1_2",
              "ka": "X4_1_2",
              "te": "X4_1_2",
              "or": "X4_1_2",
              "as": "X4_1_2",
              "gu": "X4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Both of them are taking an online aptitude test. The test is to know Preeti and Lina's personalities and aptitude (natural ability to do something). They have to answer many questions. Mira also takes the test.",
              "hi": "वे दोनों एक ऑनलाइन योग्यता परीक्षा दे रहे हैं। परीक्षा प्रीति और लीना का व्यक्तित्व और योग्यता (कुछ करने की प्राकृतिक क्षमता) को जांचने के लिए है। उन्हें कई सवालों का जवाब देना होगा। मीरा भी परीक्षा देती  है।",
              "ka": "ಇಬ್ಬರೂ ಅಭಿರುಚಿ ಪರೀಕ್ಷೆಯನ್ನು ತೆಗೆದುಕೊಳ್ಳುವಲ್ಲಿ ಮಗ್ನರಾಗಿದ್ದರು. ಆ ಪರೀಕ್ಷೆಯು ಪ್ರೀತಿ ಹಾಗೂ ಲೀನ ಅವರ ಅಭಿರುಚಿ ಮತ್ತು ವ್ಯಕ್ತಿತ್ವವನ್ನು ಅರಿಯಲು (ಏನನ್ನಾದರೂ ಮಾಡಲು ಇರುವ ಸಹಜ ಸಾಮರ್ಥ್ಯ) ಆಗಿರುತ್ತದೆ. ಅವರು ಅನೇಕ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರವನ್ನು ಹೇಳಬೇಕು. ಮೀರಾ ಕೂಡಾ ಪರೀಕ್ಷೆಯನ್ನು ತೆಗೆದುಕೊಂಡಳು. ",
              "te": "వారిద్దరూ ఆన్‌లైన్‌లో ఆప్టిట్యూడ్ టెస్ట్ కు హాజరవుతున్నారు. ప్రీతి మరియు లీనా యొక్క వ్యక్తిత్వాలు మరియు ఆప్టిట్యూడ్ (ఏదైనా చేయగల సహజ సామర్థ్యం) తెలుసుకోవడం కోసం పరీక్షలో పాల్గొంటున్నారు. వారు చాలా ప్రశ్నలకు సమాధానం చెప్పాలి. మీరా కూడా పరీక్ష తీసుకుంటుంది.",
              "or": "ଦୁହେଁ ଏକ ଅନଲାଇନ୍ ଦକ୍ଷତା ପରୀକ୍ଷା ଦେଉଛନ୍ତି | ପରୀକ୍ଷା ହେଉଛି ପ୍ରୀତି ଏବଂ\n  ଲୀନାଙ୍କ ବ୍ୟକ୍ତିତ୍ୱ ଏବଂ ଦକ୍ଷତା (କିଛି କରିବାର ପ୍ରାକୃତିକ ଦକ୍ଷତା) ଜାଣିବା | ସେମାନଙ୍କୁ\n  ଅନେକ ପ୍ରଶ୍ନର ଉତ୍ତର ଦେବାକୁ ପଡିବ | ମୀରା ମଧ୍ୟ ପରୀକ୍ଷା ଦିଅନ୍ତି |",
              "as": "তেওঁলোক দুয়ো অনলাইনত এটা দক্ষতা পৰীক্ষা কৰি আছে। পৰীক্ষাটো হ'ল, প্ৰীতি আৰু লীনাৰ ব্যক্তিত্ব আৰু দক্ষতা (কিবা কৰাৰ প্ৰাকৃতিক সামৰ্থ্য) জনা। তেওঁলোকে বহুতো প্ৰশ্নৰ উত্তৰ দিব লাগিব। মীৰাইও পৰীক্ষা টো লয়।",
              "gu": "આ બંને ઓનલાઈન પરીક્ષા ટેસ્ટ આપી રહ્યા છે. પરીક્ષણ પ્રીતિ અને લીનાના વ્યક્તિત્વ અને યોગ્યતા (કંઈક કરવાની કુદરતી ક્ષમતા) જાણવાની છે. તેમને ઘણા પ્રશ્નોના જવાબ આપવાના છે. મીરા પણ પરીક્ષા આપે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_3",
              "hi": "X4_1_3",
              "ka": "X4_1_3",
              "te": "X4_1_3",
              "or": "X4_1_3",
              "as": "X4_1_3",
              "gu": "X4_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira finishes the spatial, numerical and visual processing test. Help Mira answer some of the other questions:",
              "hi": "मीरा स्पेशियल, न्यूमेरिकल और विजुअल प्रोसेसिंग परीक्षा को पूरा करती है। कुछ अन्य प्रश्नों के उत्तर देने में मीरा की सहायता करे:",
              "ka": "ಮೀರಾ ಗಾತ್ರಕ್ಕೆ, ಸಂಖ್ಯೆಗೆ ಹಾಗೂ ದೃಷ್ಟಿಗೆ ಗೋಚರಿಸುವ ಸಂಗತಿಗಳ ಬಗ್ಗೆ ಇರುವ ಪರೀಕ್ಷೆಯನ್ನು ಮುಗಿಸಿದಳು. ಮೀರಾಳಿಗೆ ಇನ್ನಿತರ ಪ್ರಶ್ನೆಗಳನ್ನು ಉತ್ತರಿಸಲು ಸಹಾಯ ಮಾಡೋಣ.",
              "te": "మీరా ప్రాదేశిక, సంఖ్యా మరియు విజువల్ ప్రాసెసింగ్ పరీక్షను పూర్తి చేసింది. మీరాకు కొన్ని ఇతర ప్రశ్నలకు సమాధానమివ్వడంలో సహాయపడండి:",
              "or": "ମୀରା ସ୍ଥାନିକ, ସାଂଖ୍ୟିକ ଏବଂ ଭିଜୁଆଲ୍ ପ୍ରକ୍ରିୟାକରଣ ପରୀକ୍ଷା ସମାପ୍ତ କରେ | \n ମୀରାଙ୍କୁ ଅନ୍ୟ କେତେକ ପ୍ରଶ୍ନର ଉତ୍ତର ଦେବାକୁ ସାହାଯ୍ୟ କରନ୍ତୁ:",
              "as": "মীৰাই স্থানিক, সাংখ্যিক আৰু দৃশ্যমান প্ৰক্ৰিয়াকৰণ পৰীক্ষা সমাপ্ত কৰে। মীৰাক আন কিছুমান প্ৰশ্নৰ উত্তৰ দিয়াত সহায় কৰক:",
              "gu": "મીરા અવકાશી, સંખ્યાત્મક અને દ્રશ્ય પ્રક્રિયા પરીક્ષણ પૂરી કરે છે. મીરાને અન્ય કેટલાક પ્રશ્નોના જવાબ આપવામાં મદદ કર."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_3a_singleselect",
              "hi": "X4_1_3a_सिंगलसिलेक्ट ",
              "ka": "X4_1_3a_singleselect",
              "te": "X4_1_3a_singleselect",
              "or": "X4_1_3a_singleselect",
              "as": "X4_1_3a_একক নিৰ্বাচিত",
              "gu": "X4_1_3a_એક પસંદગી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "When there are a lot of things to be done, what do you do?",
              "hi": "जब बहुत सारी चीजें होनी होती हैं, तो आप क्या करते हैं?",
              "ka": "ಅನೇಕ ಕೆಲಸಗಳನ್ನು ಮಾಡಬೇಕಾದಾಗ ನೀನು ಏನು ಮಾಡುತ್ತೀಯೇ?",
              "te": "చేయవలసిన పనులు చాలా ఉన్నప్పుడు, మీరు ఏమి చేస్తారు?",
              "or": "ଯେତେବେଳେ ଅନେକ ଜିନିଷ କରିବାକୁ ହେବ, ତୁମେ କ’ଣ କରିବ?",
              "as": "যেতিয়া বহুতো কাম একেলগে কৰিব লগা থাকে, তেতিয়া তুমি কি কৰা?",
              "gu": "જ્યારે ઘણું બધું કરવાનું હોય છે, ત્યારે તમે શું કરો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.0.display",
            "value": {
              "en": "Make a list of the work to be done",
              "hi": "किए जाने वाले काम की एक सूची बनाएं",
              "ka": "ಆ ಕೆಲಸಗಳ ಒಂದು ಪಟ್ಟಿಯನ್ನು ಮಾಡು.",
              "te": "చేయవలసిన పనుల జాబితాను తయారు చేయండి",
              "or": "କରାଯିବାକୁ ଥିବା କାର୍ଯ୍ୟର ଏକ ତାଲିକା ପ୍ରସ୍ତୁତ କର |",
              "as": "কৰিবলগীয়া কামৰ এখন তালিকা প্ৰস্তুত কৰা।",
              "gu": "જે કામ કરવાના છે તેની યાદી બનાવો"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.1.display",
            "value": {
              "en": "Just keep doing it",
              "hi": " करते रहो",
              "ka": "ಅದನ್ನು ಮಾಡುತ್ತಲೇಇರು",
              "te": "ఇలాగే కొనసాగించండి",
              "or": "କେବଳ ଏହା ଜାରି ରଖ |",
              "as": "এইটোকে কৰি থাকা",
              "gu": "બસ તે કરવાનું ચાલુ રાખો"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_3b_singleselect",
              "hi": "X4_1_3b_सिंगलसिलेक्ट",
              "ka": "X4_1_3b_singleselect",
              "te": "X4_1_3b_singleselect",
              "or": "X4_1_3b_singleselect",
              "as": "X4_1_3b_একক নিৰ্বাচিত",
              "gu": "X4_1_3b_એક પસંદગી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "How do you make a decision?",
              "hi": "आप निर्णय कैसे लेते हैं?",
              "ka": "ನೀನು ಯಾವ ರೀತಿಯಲ್ಲಿ ಒಂದು ನಿರ್ಧಾರವನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತೀ?",
              "te": "మీరు నిర్ణయం ఎలా తీసుకుంటారు?",
              "or": "ଆପଣ କିପରି ନିଷ୍ପତ୍ତି ନେବେ?",
              "as": "তুমি কেনেদৰে সিদ্ধান্ত লোৱা?",
              "gu": "તું નિર્ણય કેવી રીતે લઈશ ?"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.0.display",
            "value": {
              "en": "I think about the positives and negatives, talk to people close to me",
              "hi": "मैं सकारात्मक और नकारात्मक मुद्दों के बारे में सोचता हूं, अपने करीबी लोगों से बात करता हूं",
              "ka": "ನಾನು ಅದರ ಲಾಭ ಹಾಗೂ ನಷ್ಟವನ್ನು ಯೋಚಿಸುತ್ತೇನೆ, ಇತರರ ಜೊತೆಗೆ ಮಾತನಾಡುತ್ತೇನೆ. ",
              "te": "నేను సానుకూలతలు మరియు ప్రతికూలతల గురించి ఆలోచిస్తాను, నాకు దగ్గరగా ఉన్న వ్యక్తులతో మాట్లాడటం.",
              "or": "ମୁଁ ସକରାତ୍ମକ ଏବଂ ନକାରାତ୍ମକ ବିଷୟରେ ଚିନ୍ତା କରେ, ମୋ ନିକଟତର ଲୋକଙ୍କ\n  ସହିତ କଥାବାର୍ତ୍ତା କରେ |",
              "as": "মই ইতিবাচক আৰু নেতিবাচকবোৰৰ বিষয়ে চিন্তা কৰো, মোৰ ওচৰৰ লোকসকলৰ সৈতে কথা পাতা",
              "gu": "હું હકારાત્મક અને નકારાત્મક વિશે વિચારું છું, મારી નજીકના લોકો સાથે વાત કરું છું"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.1.display",
            "value": {
              "en": "I will decide based on what I feel is correct at that time",
              "hi": "उस समय मुझे क्या सही लगता है मैं उसके आधार पर निर्णय लूंगा  ",
              "ka": "ನಂತರ ಆ ಸಮಯಕ್ಕೆ ಸರಿ ಅನಿಸಿದ ನಿರ್ಧಾರವನ್ನು ತೆಗೆದುಕೊಳ್ತೇನೆ. ",
              "te": "ఆ సమయంలో నాకు ఏది కరెక్ట్ అనిపిస్తుందో దాని ఆధారంగా నేను నిర్ణయిస్తాను",
              "or": "ମୁଁ ସେହି ସମୟରେ ଯାହା ଠିକ୍ ଅନୁଭବ କରେ ତାହା ଉପରେ ଆଧାର କରି ନିଷ୍ପତ୍ତି ନେବି |",
              "as": "মই সেই সময়ত যিটোৱেই শুদ্ধ বুলি অনুভৱ কৰোঁ তাৰ ওপৰত সিদ্ধান্ত লম",
              "gu": "તે સમયે મને જે સાચું લાગે છે તેના આધારે હું નિર્ણય લઈશ"
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_3c_singleselect",
              "hi": "X4_1_3c_सिंगलसिलेक्ट",
              "ka": "X4_1_3c_singleselect",
              "te": "X4_1_3c_singleselect",
              "or": "X4_1_3c_singleselect",
              "as": "X4_1_3c_একক নিৰ্বাচিত",
              "gu": "X4_1_3c_એક પસંદગી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "When you are confused about a topic, you like to ___ .",
              "hi": "जब आप किसी विषय के बारे में दुविधा में होते हैं, तो आप ___ पसंद करते हैं।",
              "ka": "ನಿನಗೆ ಯಾವುದೇ ವಿಷಯದಲ್ಲಿ ಜಿಜ್ಞಾಸೆ ಉಂಟಾದಾಗ, ನೀನು _____",
              "te": "మీరు ఒక అంశం గురించి గందరగోళంగా ఉన్నప్పుడు, మీరు ___ని ఇష్టపడతారు.",
              "or": "ଯେତେବେଳେ ଆପଣ ଏକ ବିଷୟ ବିଷୟରେ ଦ୍ୱନ୍ଦ୍ୱରେ ପଡ଼ନ୍ତି, ଆପଣ ___ କରିବାକୁ\n  ପସନ୍ଦ କରନ୍ତି |",
              "as": "যেতিয়া তুমি কোনো এটা বিষয়ৰ ক্ষেত্ৰত বিভ্ৰান্ত হোৱা, তুমি ____ কৰিব বিচাৰা।",
              "gu": "જ્યારે તું કોઈ વિષય વિશે મૂંઝવણમાં હોવ, ત્યારે તને ગમે છે.____"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.0.display",
            "value": {
              "en": "Talk to an older person",
              "hi": "एक अनुभवी व्यक्ति से बात करना",
              "ka": "ಒಬ್ಬ ಹಿರಿಯರ ಜೊತೆಗೆ ಮಾತನಾಡುತ್ತೇನೆ.",
              "te": "ఒక పెద్ద వ్యక్తితో మాట్లాడటం",
              "or": "ଜଣେ ବୟସ୍କ ବ୍ୟକ୍ତିଙ୍କ ସହ କଥା ହୁଅନ୍ତୁ |",
              "as": "ডাঙৰৰ পৰামৰ্শ লোৱা",
              "gu": "કોઈ મોટી વ્યક્તિ સાથે વાત કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.1.display",
            "value": {
              "en": "Read about the topic",
              "hi": "विषय के बारे में पढ़ना",
              "ka": "ಅದರ ಬಗ್ಗೆ ಓದುತ್ತೇನೆ",
              "te": "అంశం గురించి చదవడం",
              "or": "ବିଷୟ ବିଷୟରେ ପଢନ୍ତୁ |",
              "as": "বিষয়টোৰ বিষয়ে পঢ়ি-শুনি লোৱা",
              "gu": "વિષય વિશે વાંચો"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_4",
              "hi": "X4_1_4",
              "ka": "X4_1_4",
              "te": "X4_1_4",
              "or": "X4_1_4",
              "as": "X4_1_4",
              "gu": "X4_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After this, Mira also answers some questions about her ability. \nThe next day they all get a report from their aptitude test.",
              "hi": "इसके बाद, मीरा भी अपनी क्षमता से सम्बंधित कुछ सवालों का जवाब देती है।\nअगले दिन उन सभी को उनकी योग्यता परीक्षा से एक रिपोर्ट मिलती है।",
              "ka": "ನಂತರ, ಮೀರಾ ತನ್ನ ಸಾಮರ್ಥ್ಯದ ಬಗ್ಗೆಗಿನ ಕೆಲವು ಪ್ರಶ್ನೆಗಳಿಗೆ ಮೀರಾ ಉತ್ತರಿಸುತ್ತಾಳೆ. ಮುಂದಿನ ದಿವಸ ಅವರ ಅಭಿರುಚಿಯ ಪರೀಕ್ಷೆಯ ವರದಿ ಸಿಗುತ್ತದೆ.",
              "te": "దీని తర్వాత, మీరా తన సామర్థ్యం గురించి కొన్ని ప్రశ్నలకు కూడా సమాధానమిచ్చింది.\n \n మరుసటి రోజు వారందరికీ వారి ఆప్టిట్యూడ్ టెస్ట్ నుండి ఫలితాలు వస్తాయి.",
              "or": "ଏହା ପରେ ମୀରା ତାଙ୍କ ଦକ୍ଷତା ବିଷୟରେ କିଛି ପ୍ରଶ୍ନର ଉତ୍ତର ମଧ୍ୟ ଦେଇଛନ୍ତି।\n ପରଦିନ ସେମାନେ ସମସ୍ତେ ସେମାନଙ୍କର ଦକ୍ଷତା ପରୀକ୍ଷଣରୁ ଏକ ରିପୋର୍ଟ ପାଆନ୍ତି |",
              "as": "ইয়াৰ পিছত, মীৰাই তাইৰ সামৰ্থ্যসম্পৰ্কে কিছুমান প্ৰশ্নৰ উত্তৰো দিয়ে। \n পিছদিনা তেওঁলোক সকলোৱে তেওঁলোকৰ দক্ষতা পৰীক্ষাৰ পৰা প্ৰতিবেদন লাভ কৰে।",
              "gu": "આ પછી મીરા પોતાની ક્ષમતા વિશે કેટલાક સવાલોના જવાબ પણ આપે છે. બીજા દિવસે તેઓ બધાને તેમની યોગ્યતા ટેસ્ટનો અહેવાલ મળે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_2_1//",
              "hi": "X4_2_1//",
              "ka": "X4_2_1//",
              "te": "X4_2_1//",
              "or": "X4_2_1//",
              "as": "X4_2_1//",
              "gu": "X4_2_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Preeti",
              "hi": "प्रीति",
              "ka": "ಪ್ರೀತಿ",
              "te": "ప్రీతి",
              "or": "ପ୍ରୀତି",
              "as": "প্ৰীতি",
              "gu": "પ્રીતિ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Lina",
              "hi": "लीना",
              "ka": "ಲೀನ",
              "te": "లీనా",
              "or": "ଲୀନା",
              "as": "লীনা",
              "gu": "લીના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "This was great! The test shows that I am good with Mathematics and science. The report says my strength is numbers, patterns and data, i.e. both numerical and analytical ability. My visual processing, ability to recognise visual patterns, is low. But it's evident to me. I will take Physics, Chemistry, and Maths as my major subjects.",
              "hi": "यह बहुत अच्छा था! परीक्षण से पता चलता है कि मैं गणित और विज्ञान में अच्छी हूं। रिपोर्ट में कहा गया है कि मेरी क्षमता नम्बर्स, पैटर्न और डेटा है, यानी न्यूमेरिकल और एनालिटिकल क्षमता दोनों। मेरी विजुअल प्रोसेसिंग, विजुअल पैटर्न को पहचानने की क्षमता कम है। लेकिन यह मेरे लिए स्पष्ट है। मैं भौतिकी, रसायन शास्त्र और गणित को अपने प्रमुख विषयों के रूप में लूंगी।",
              "ka": "ಇದು ನಿಜವಾಗಲೂ ಸಂತೋಷದಾಯಕ! ಈ ಪರೀಕ್ಷೆಯು ನಾನು ಗಣಿತ ಶಾಸ್ತ್ರದಲ್ಲಿ ಹಾಗೂ ವಿಜ್ಞಾನದಲ್ಲಿ ಮುಂದೆ ಇದ್ದೇನೆ ಎಂದು ತಿಳಿಸುತ್ತದೆ. ವರದಿಯು ನನ್ನ ಸಾಮರ್ಥ್ಯ ಸಂಖ್ಯೆಗಳು, ಚಿತ್ರಾಕೃತಿಗಳು ಹಾಗೂ ಮಾಹಿತಿಗಳು ಎಂದು ತಿಳಿಸುತ್ತದೆ. ನನ್ನ ದೃಷ್ಟಿ ಸಂಸ್ಕರಣೆ ಹಾಗೂ ದೃಷ್ಟಿ ಚಿತ್ರಣಗಳನ್ನು ಗುರುತಿಸುವ ಸಾಮರ್ಥ್ಯ ಸ್ವಲ್ಪ ಕಮ್ಮಿ ಎಂದು ಹೇಳುತ್ತದೆ. ಆದರೆ ಇದು ನನಗೆ ಸ್ಪಷ್ಟವಾಗಿ ಗೊತ್ತಿದೆ. ನಾನು ಭೌತ ಶಾಸ್ತ್ರ, ರಸಾಯನ ಶಾಸ್ತ್ರ ಹಾಗೂ ಗಣಿತ ಶಾಸ್ತ್ರವನ್ನು ನನ್ನ ಮುಖ್ಯ ವಿಷಯವಾಗಿ ತೆಗೆದುಕೊಳ್ಳುತ್ತೇನೆ ಎಂದು. ",
              "te": "ఇది చాలా బాగుంది! నాకు గణితం మరియు సైన్స్ లో ప్రతిభ ఉన్నట్లు ఫలితం వచ్చింది. నా బలం సంఖ్యలు, నమూనాలు మరియు డేటా, అంటే సంఖ్యా మరియు విశ్లేషణాత్మక సామర్థ్యం రెండూ అని రిపోర్ట్ చెబుతోంది. నా విజువల్ ప్రాసెసింగ్, విజువల్ ప్యాట్రన్ లను గుర్తించే సామర్థ్యం తక్కువగా ఉంది. కానీ అది నాకు స్పష్టంగా ఉంది. నేను ఫిజిక్స్, కెమిస్ట్రీ మరియు గణితంను నా ప్రధాన సబ్జెక్టులుగా తీసుకుంటాను.",
              "or": "ଏହା ବହୁତ ଭଲ ଥିଲା! ପରୀକ୍ଷା ଦର୍ଶାଏ ଯେ ମୁଁ ଗଣିତ ଏବଂ ବିଜ୍ଞାନ ରେ ଭଲ ଅଟେ |\n  ରିପୋର୍ଟରେ କୁହାଯାଇଛି ଯେ ମୋର ଶକ୍ତି ହେଉଛି ସଂଖ୍ୟା , ଢାଞ୍ଚା ଏବଂ ତଥ୍ୟ,\n  ଯଥା ଉଭୟ ସାଂଖ୍ୟିକ ଏବଂ ବିଶ୍ଳେଷଣାତ୍ମକ ଦକ୍ଷତା | ମୋର ଭିଜୁଆଲ୍ ପ୍ରକ୍ରିୟାକରଣ,\n  ଭିଜୁଆଲ ଢାଞ୍ଚାଗୁଡ଼ିକୁ ଚିହ୍ନିବା କ୍ଷମତା କମ୍ ଅଟେ | କିନ୍ତୁ ଏହା ମୋ ପାଇଁ ସ୍ପଷ୍ଟ ହୋଇଛି |\n  ମୁଁ ପଦାର୍ଥ ବିଜ୍ଞାନ, ରସାୟନ ବିଜ୍ଞାନ ଏବଂ ଗଣିତକୁ ମୋର ମୁଖ୍ୟ ବିଷୟ ଭାବରେ ଗ୍ରହଣ କରିବି |",
              "as": "এইটো বঢ়িয়া লাগিল দেই! পৰীক্ষাটোৰ মতে মই গণিত আৰু বিজ্ঞানৰ ক্ষেত্ৰত ভাল। ৰিপৰ্টৰ মতে মোৰ শক্তি হৈছে সংখ্যা, আৰ্হি আৰু তথ্য, অৰ্থাৎ সাংখ্যিক আৰু বিশ্লেষণাত্মক দুয়োটাতে দক্ষতা। মোৰ ভিজুৱেল প্ৰচেছিং, ভিজুৱেল পেটাৰ্ণ চিনাক্ত কৰাৰ দক্ষতা কম। কিন্তু এইটো মোৰ বাবে স্পষ্ট হ'ল। মই পদাৰ্থ বিজ্ঞান, ৰসায়ন বিজ্ঞান আৰু গণিতক মোৰ মুখ্য বিষয় হিচাপে গ্ৰহণ কৰিম।",
              "gu": "આ સરસ હતું! પરીક્ષણ દર્શાવે છે કે હું ગણિત અને વિજ્ઞાનમાં સારી છું. અહેવાલ કહે છે કે મારી યોગ્યતા સંખ્યાઓ, રીત અને ડેટા છે, એટલે કે સંખ્યાત્મક અને વિશ્લેષણાત્મક બંને ક્ષમતા. મારી દ્રશ્ય પ્રક્રિયા, દ્રશ્યથી રીતને ઓળખવાની ક્ષમતા ઓછી છે. પરંતુ તે મારા માટે સ્પષ્ટ છે. હું ભૌતિકશાસ્ત્ર, રસાયણશાસ્ત્ર અને ગણિતને મારા મુખ્ય વિષયો તરીકે લઈશ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Mine is very different from you. It is funny we both like science, but we are so different. Mine says I'm good at recognising and analysing visual patterns, which is visual processing, and I have a good memory. The report suggests that I should take up biology!",
              "hi": "मेरा तुमसे बहुत अलग है। यह मजेदार है हम दोनों विज्ञान पसंद करते हैं, लेकिन हम बहुत अलग हैं। मेरी रिपोर्ट कहती है कि मैं विजुअल पैटर्न को पहचानने और विश्लेषण करने में अच्छी हूं, जो विजुअल प्रोसेसिंग है, और मेरी याददाश्त अच्छी है। रिपोर्ट का सुझाव है कि मुझे जीवविज्ञान लेना चाहिए!",
              "ka": "ನನ್ನದು ನಿನಗಿಂತ ಭಿನ್ನವಾಗಿದೆ, ನನಗೆ ತಮಾಷೆ ಅನಿಸುತ್ತದೆ, ನಾವಿಬ್ಬರೂ ವಿಜ್ಞಾನವನ್ನು ಇಷ್ಟ ಪಡುತ್ತೇವೆ. ಆದರೆ, ನಮ್ಮಿಬ್ಬರಲ್ಲಿ ಎಷ್ಟು ವ್ಯತ್ಯಾಸವಿದೆ ಎಂದು. ನನ್ನದು ತಿಳಿಸುತ್ತದೆ ನಾನು ದೃಷ್ಟಿ ಸಂಸ್ಕರಣೆ ಹಾಗೂ ದೃಷ್ಟಿ ಚಿತ್ರಣಗಳನ್ನು ಬಿಡಿಸಿ ನೋಡುವುದರಲ್ಲಿ ಮುಂದಿದ್ದೇನೆ ಎಂದು, ಮತ್ತು ನಾನು ಒಳ್ಳೆಯ ಜ್ಞಾಪಕ ಶಕ್ತಿಯನ್ನು ಹೊಂದಿದ್ದೇನೆ ಎಂದು. ವರದಿಯು ನಾನು ಸಸ್ಯ ಶಾಸ್ತ್ರವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕು ಎಂದು ತಿಳಿಸುತ್ತದೆ. ",
              "te": "నాది నీకంటే చాలా భిన్నంగా ఉంది. ఇది తమాషాగా ఉంది, మా ఇద్దరికీ సైన్స్ ఇష్టం, కానీ మేము చాలా భిన్నంగా ఉన్నాము. విజువల్ ప్రాసెసింగ్ అయిన విజువల్ ప్యాట్రన్ లను గుర్తించడంలో మరియు విశ్లేషించడంలో నాకు ప్రతిభ మరియు మంచి జ్ఞాపకశక్తి ఉందని ఫలితం వచ్చింది. నేను జీవశాస్త్రం తీసుకోవాలని రిపోర్ట్ సూచించింది!",
              "or": "ମୋର ତୁମଠାରୁ ବହୁତ ଅଲଗା | ଏହା ମଜାଦାର ଯେ ଆମେ ଦୁହେଁ ବିଜ୍ଞାନକୁ ପସନ୍ଦ \n କରୁ, କିନ୍ତୁ ଆମେ ବହୁତ ଅଲଗା | ମୋର କହିଛି ଯେ ଭିଜୁଆଲ ଢାଞ୍ଚା ଗୁଡ଼ିକୁ ଚିହ୍ନିବା \n ଏବଂ ବିଶ୍ଳେଷଣ କରିବାରେ ମୁଁ ଭଲ, ଯାହା ଭିଜୁଆଲ୍ ପ୍ରକ୍ରିୟାକରଣ ଅଟେ, ଏବଂ ମୋର\n  ଭଲ ସ୍ମୃତି ଅଛି | ରିପୋର୍ଟରେ ଦର୍ଶାଯାଇଛି ଯେ ମୁଁ ଜୀବବିଜ୍ଞାନ ଗ୍ରହଣ କରିବା ଉଚିତ୍!",
              "as": "মোৰটো তোমাতকৈ বহুত বেলেগ। মজাৰ কথাটো হ’ল যে আমি দুয়ো বিজ্ঞান ভাল পাওঁ, কিন্তু আমি বহুত বেলেগ। মোৰ মতে মই দৃশ্যমান আৰ্হি চিনাক্তকৰণ আৰু বিশ্লেষণ কৰাত ভাল, যি হৈছে দৃশ্যমান প্ৰক্ৰিয়াকৰণ, আৰু মোৰ এটা ভাল স্মৃতি শক্তি আছে। ৰিপৰ্টটোৱে পৰামৰ্শ দিছে যে মই জীৱবিজ্ঞানহে গ্ৰহণ কৰা উচিত!",
              "gu": "મારું તારાથી ઘણું અલગ છે. તે સારું છે કે આપણને બંનેને વિજ્ઞાન ગમે છે, પરંતુ અમે ઘણા અલગ છીએ. મારૂ એ છે કે હું દ્રશ્ય રીતને ઓળખવામાં અને તેનું વિશ્લેષણ કરવામાં સારી છું, જે દ્રશ્ય પ્રક્રિયા છે અને મારી યાદશક્તિ સારી છે. રિપોર્ટ સૂચવે છે કે મારે જીવ વિજ્ઞાન લેવું જોઈએ!"
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_2_2////",
              "hi": "X4_2_2////",
              "ka": "X4_2_2////",
              "te": "X4_2_2////",
              "or": "X4_2_2////",
              "as": "X4_2_2////",
              "gu": "X4_2_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "What did you get, Mira?",
              "hi": "आपको क्या मिला, मीरा?",
              "ka": "ನಿನಗೆ ಏನು ಬಂದಿದೆ, ಮೀರಾ?",
              "te": "నీకేం వచ్చింది మీరా?",
              "or": "ମୀରା, ତୁମେ କ’ଣ ପାଇଲ?",
              "as": "তুমি কি পালা মীৰা?",
              "gu": "તને શું મળ્યું, મીરા?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I got an equal score in analytical and visual processing. What does that mean?",
              "hi": "मुझे एनालिटिकल और विजुअल प्रोसेसिंग में बराबर अंक मिले। इसका क्या अर्थ है?",
              "ka": "ನನಗೆ ದೃಷ್ಟಿ ಸಂಸ್ಕರಣೆ ಮತ್ತು ಅನ್ಯಾಲಿಟಿಕಲ್ ಎರಡರಲ್ಲೂ ಸಮನಾಗಿ ಸ್ಕೋರ್ ಬಂದಿದೆ. ಅಂದರೆ ಅದರ ಅರ್ಥ ಏನು?",
              "te": "నాకు ఎనలిటికల్ మరియు విజువల్ ప్రాసెసింగ్‌లో స్కోర్ ఒకే లా వచ్చింది. అంటే ఏమిటి?",
              "or": "ଆନାଲିଟିକାଲ୍ ଏବଂ ଭିଜୁଆଲ୍ ପ୍ରକ୍ରିୟାକରଣରେ ମୁଁ ସମାନ ସ୍କୋର ପାଇଲି |\n  ଏହାର ଅର୍ଥ କ’ଣ?",
              "as": "মই বিশ্লেষণাত্মক আৰু চাক্ষুষ প্ৰক্ৰিয়াকৰণত সমান নম্বৰ পাইছিলোঁ। সেইটো মানে কি?",
              "gu": "મને વિશ્લેષણાત્મક અને દ્રશ્ય પ્રક્રિયામાં સરખા ગુણ મળ્યા. તેનો અર્થ શું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Preeti and Lina both read Mira's report.",
              "hi": "प्रीति और लीना दोनों ने मीरा की रिपोर्ट पढ़ी।",
              "ka": "ಪ್ರೀತಿ ಹಾಗೂ ಲೀನ ಇಬ್ಬರೂ ಮೀರಾಳ ವರದಿಯನ್ನು ಓದುತ್ತಾರೆ.",
              "te": "ప్రీతి మరియు లీనా ఇద్దరూ మీరా రిపోర్ట్ ని చదివారు.",
              "or": "ପ୍ରୀତି ଏବଂ ଲୀନା ଦୁହେଁ ମୀରାଙ୍କ ରିପୋର୍ଟ ପଢନ୍ତି |",
              "as": "প্ৰীতি আৰু লীনা দুয়োয়ে মীৰাৰ ৰিপৰ্টটো পঢ়ি চালে।",
              "gu": "પ્રીતિ અને લીના બંનેએ મીરાનો અહેવાલ વાંચ્યો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "That means you can take either Maths or Biology.",
              "hi": "इसका अर्थ है कि तुम या तो गणित या जीवविज्ञान ले सकती हो।",
              "ka": "ಅಂದರೆ ನೀನು ಸಸ್ಯ ಶಾಸ್ತ್ರ ಅಥವಾ ಗಣಿತ ಶಾಸ್ತ್ರ ಎರಡರಲ್ಲಿ ಯಾವುದನ್ನು ಬೇಕಾದರೂ ತೆಗೆದುಕೊಳ್ಳಬಹುದು ಎಂದು.",
              "te": "అంటే నువ్వు మ్యాథ్స్ లేదా బయాలజీని తీసుకోవచ్చు.",
              "or": "ଏହାର ଅର୍ଥ ଆପଣ ଗଣିତ କିମ୍ବା ବାୟୋଲୋଜି ନେଇପାରିବେ |",
              "as": "তাৰমানে তুমি অংক বা জীৱবিজ্ঞান ল'ব পাৰা।",
              "gu": "તેનો અર્થ એ કે તું ગણિત અથવા જીવ વિજ્ઞાન બંનેમાંથી એક લઈ શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Now you have to decide if you want to join Preeti or me.",
              "hi": "अब तुमको यह तय करना होगा कि तुम किसके साथ जुड़ना चाहती  हो प्रीति या मेरे।",
              "ka": "ಈಗ ನೀನು ನಿರ್ಧರಿಸಬೇಕು ಪ್ರೀತಿಯ ಜೊತೆಗೆ ಇರುತ್ತೀಯೋ ಅಥವಾ ನನ್ನ ಜೊತೆ ಸೇರುತ್ತೀಯೋ ಎಂದು ",
              "te": "ఇప్పుడు నువ్వు ప్రీతితో చేరాలా లేక నాతో చేరాలా అనేది నిర్ణయించుకోవాలి.",
              "or": "ବର୍ତ୍ତମାନ ତୁମକୁ ସ୍ଥିର କରିବାକୁ ପଡିବ କି ତୁମେ ପ୍ରୀତି କିମ୍ବା ମୋ ସହିତ ଯୋଗଦେବାକୁ ଚାହୁଁଛ କି |",
              "as": "এতিয়া তুমি সিদ্ধান্ত ল'ব লাগিব যে তুমি প্ৰীতি বা মোৰ সৈতে যোগদান কৰিব বিচাৰা নে নাই।",
              "gu": "હવે તારે નક્કી કરવાનું છે કે તારે પ્રીતિ સાથે જોડાવું છે કે મારી સાથે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.5.text",
            "value": {
              "en": "Hmmmm",
              "hi": "हम्म्म्म",
              "ka": "ಹ್ಮ್ಮ",
              "te": "అవును",
              "or": "ହଁ",
              "as": "উম্",
              "gu": "હમમમ"
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "AptitudeTest",
              "hi": "योग्यता परीक्षा",
              "ka": "ಅಭಿರುಚಿ ಪರೀಕ್ಷೆ",
              "te": "ఆప్టిట్యూడ్ టెస్ట్",
              "or": "ଦକ୍ଷତା ପରୀକ୍ଷା",
              "as": "দক্ষতা পৰীক্ষা",
              "gu": "અભિરુચિ કસોટી"
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_2_3",
              "hi": "X4_2_3",
              "ka": "X4_2_3",
              "te": "X4_2_3",
              "or": "X4_2_3",
              "as": "X4_2_3",
              "gu": "X4_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is left more confused.",
              "hi": "मीरा की दुविधा और बढ़ गई है।",
              "ka": "ಮೀರಾ ಮತ್ತಷ್ಟು ಜಿಜ್ಞಾಸೆಗೆ ಒಳಗಾಗುತ್ತಾಳೆ ",
              "te": "మీరా మరింత అయోమయంలో పడింది.",
              "or": "ମୀରା ଅଧିକ ଦ୍ୱନ୍ଦ୍ୱରେ ରହିଯାଇଛନ୍ତି |",
              "as": "মীৰা অধিক বিভ্ৰান্ত হৈ পৰিছে।",
              "gu": "મીરા વધુ મૂંઝવણમાં મુકાઈ ગઈ."
            }
          }
        ]
      },
      "scene5": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_1_1",
              "hi": "X5_1_1",
              "ka": "X5_1_1",
              "te": "X5_1_1",
              "or": "X5_1_1",
              "as": "X5_1_1",
              "gu": "X5_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "When she reaches home the next day, she tells her Aunt and uncle about the aptitude test and the results.",
              "hi": "जब वह अगले दिन घर पहुंचती है, तो वह योग्यता परीक्षा और परिणामों के बारे में अपनी चाची और चाचा को बताती है।",
              "ka": "ಮುಂದಿನ ದಿವಸ ಅವಳು ಮನೆಗೆ ತಲುಪಿದಾಗ, ಅಂಕಲ್ ಹಾಗೂ ಆಂಟಿ ಜೊತೆಗೆ ಅವಳು ತನ್ನ ಅಭಿರುಚಿ ಪರೀಕ್ಷೆ ಮತ್ತು ಅದರಿಂದ ಬಂದಿರುವ ಫಲಿತಾಂಶದ ಬಗ್ಗೆ ಹೇಳುತ್ತಾಳೆ.",
              "te": "మరుసటి రోజు ఇంటికి చేరుకోగానే అత్త, మామలకు ఆప్టిట్యూడ్ టెస్ట్, ఫలితాల గురించి చెప్పింది.",
              "or": "ପରଦିନ ଯେତେବେଳେ ସେ ଘରେ ପହଞ୍ଚନ୍ତି, ସେତେବେଳେ ସେ ମାଉସୀ ଏବଂ \n ମାମୁଁଙ୍କୁ ଦକ୍ଷତା ପରୀକ୍ଷା ଏବଂ ଫଳାଫଳ ବିଷୟରେ କୁହନ୍ତି |",
              "as": "তাই পিছদিনা ঘৰ গৈ পোৱাৰ পিছত খুড়ীয়েক, খুড়াকক তাইৰ দক্ষতা পৰীক্ষা আৰু ফলাফলৰ বিষয়ে বিবৰি ক’লে।",
              "gu": "જ્યારે તે બીજા દિવસે ઘરે પહોંચે છે, ત્યારે તે તેની કાકી અને કાકાને પરીક્ષા ટેસ્ટ અને પરિણામો વિશે કહે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_1_2//",
              "hi": "X5_1_2//",
              "ka": "X5_1_2//",
              "te": "X5_1_2//",
              "or": "X5_1_2//",
              "as": "X5_1_2//",
              "gu": "X5_1_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Ranjan Uncle",
              "hi": "रंजन चाचा ",
              "ka": "ರಂಜನ್ ಅಂಕಲ್",
              "te": "రంజన్ అంకుల్",
              "or": "ରଞ୍ଜନ ମାମୁଁ",
              "as": "ৰঞ্জন খুড়া",
              "gu": "રંજન કાકા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Neetu Aunty",
              "hi": "नीतू चाची",
              "ka": "ನೀತು ಆಂಟಿ",
              "te": "నీతూ ఆంటీ",
              "or": "ନିତୁ ମାଉସୀ",
              "as": "নীতু খুড়ী",
              "gu": "નીતુ કાકી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "That’s the problem with these aptitude tests. They help if you are good at one thing, but it doesn’t help you decide if you are good at everything.",
              "hi": "इन योग्यता परीक्षाओं के साथ यह समस्या है। यदि आप एक चीज में अच्छे हैं तो वे सहायता करते हैं, यदि आप अनेक चीज़ों में अच्छे हैं तो यह तय कर पाने में आपके लिए सहायक नहीं होते हैं।",
              "ka": "ಈ ಅಭಿರುಚಿ ಪರೀಕ್ಷೆಗಳ ತೊಂದರೆಯೇ ಇದು. ಅವು ನೀವು ಯಾವುದರಲ್ಲಿ ಮುಂದೆ ಇದ್ದೀರಿ ಅಂತ ತಿಳ್ಕೊಳ್ಳೋಕ್ಕೆ ಸಹಾಯ ಮಾಡುತ್ತವೆ, ಆದರೆ ಅದು ನೀವು ಎಲ್ಲದರಲ್ಲೂ ಮುಂದೆ ಇದ್ದೀರಾ ಅಂತ ನಿರ್ಧಾರ ಮಾಡೋಕ್ಕೆ ಸಹಾಯ ಮಾಡಲ್ಲ. ",
              "te": "ఈ ఆప్టిట్యూడ్ పరీక్షలతో వచ్చే సమస్యే అది. మీరు ఒక విషయంలో మంచివారైతే అది సహాయపడుతుంది, కానీ మీరు ప్రతిదానిలో మంచివారైతే అది మీకు సహాయం చేయలేదు.",
              "or": "ଏହି ଦକ୍ଷତା ପରୀକ୍ଷଣରେ ତାହା ହେଉଛି ସମସ୍ୟା | ଯଦି ତୁମେ ଗୋଟିଏ ଜିନିଷରେ\n  ଭଲ ତେବେ ସେମାନେ ସାହାଯ୍ୟ କରନ୍ତି, କିନ୍ତୁ ତୁମେ ସବୁକିଛିରେ ଭଲ କି ନାହିଁ ତାହା\n  ସ୍ଥିର କରିବାରେ ସାହାଯ୍ୟ କରେ ନାହିଁ |",
              "as": "এই দক্ষতা পৰীক্ষাবোৰৰ এইখিনিতে সমস্যাটো আছে। যদি তুমি এটা বিষয়ত ভাল হয় তেন্তে ই সহায় কৰে, কিন্তু তুমি সকলো বিষয়তে ভাল হয়নে নহয়, সেই সিদ্ধান্ত কিন্তু দিব নোৱাৰে।",
              "gu": "આ અભિરુચિ પરીક્ષણોની સમસ્યા છે. જો તું એક વસ્તુમાં સારી છે તો તેઓ મદદ કરે છે, પરંતુ તે તને તે નક્કી કરવામાં મદદ કરતું નથી કે તું દરેક બાબતમાં સારી છે કે નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Back when we were in school, there were no such tests even available to us.",
              "hi": "पहले जब हम स्कूल में थे, तो हमारे लिए ऐसे कोई परीक्षाएं  भी उपलब्ध नहीं थीं।",
              "ka": "ನಾವು ಹಿಂದೆ ಶಾಲೆಯಲ್ಲಿ ಇದ್ದಾಗ, ಈ ತರಹದ ಪರೀಕ್ಷೆಗಳು ಯಾವುದೂ ಇರಲಿಲ್ಲ. ",
              "te": "మేము పాఠశాలలో ఉన్నసమయంలో మాకు అలాంటి పరీక్షలు కూడా అందుబాటులో లేవు.",
              "or": "ଯେତେବେଳେ ଆମେ ସ୍କୁଲରେ ଥିଲୁ, ଆମ ପାଇଁ ସେପରି କୌଣସି ପରୀକ୍ଷା ନଥିଲା |",
              "as": "আমি স্কুলত পঢ়াৰ দিনত আমাৰ বাবে হ'লে এনে কোনো পৰীক্ষা নাছিল দেই।",
              "gu": "જ્યારે અમે શાળામાં હતા ત્યારે અમારા માટે આવી કોઈ પરીક્ષાઓ ઉપલબ્ધ નહોતી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_1_3////",
              "hi": "X5_1_3////",
              "ka": "X5_1_3////",
              "te": "X5_1_3////",
              "or": "X5_1_3////",
              "as": "X5_1_3////",
              "gu": "X5_1_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "So then, how did you both decide which subjects to pick?",
              "hi": "तो फिर, आप दोनों ने कैसे तय किया कि कौन से विषय चुनने चाहिए?",
              "ka": "ಅಂದ ಮೇಲೆ, ನೀವಿಬ್ಬರೂ ಯಾವೇ ರೀತಿಯಲ್ಲಿ ನಿಮ್ಮ ವಿಷಯವನ್ನು ಆಯ್ಕೆ ಮಾಡಿಕೊಂಡಿರಿ?",
              "te": "అయితే, మీరిద్దరూ ఏ సబ్జెక్ట్ లను ఎంచుకోవాలో ఎలా నిర్ణయించుకున్నారు?",
              "or": "ତେବେ, କେଉଁ ବିଷୟ ବାଛିବାକୁ ତୁମେ ଦୁହେଁ କିପରି ସ୍ଥିର କଲ?",
              "as": "গতিকে, আপোনালোক দুয়োজনে কোনবোৰ বিষয় বাছনি কৰিব লাগে বুলি সিদ্ধান্ত ল'লে?",
              "gu": "તો પછી, તમે બંનેએ કયો વિષય પસંદ કરવો તે કેવી રીતે નક્કી કર્યું?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "It was simple for me. I was fascinated with how engineering makes life easy in every field. I enjoyed mathematics a lot. It also had a lot of job openings, so it made sense to take up mathematics.",
              "hi": "यह मेरे लिए सरल था। मैं इस बात से प्रभावित था कि इंजीनियरिंग हर क्षेत्र में जीवन को कैसे आसान बनाती है। मैंने गणित का भी बहुत आनंद लिया। इसमें नौकरी के बहुत सारे अवसर भी थे, इसलिए गणित लेना समझ में आया।",
              "ka": "ನನಗೆ ಅದು ಬಹಳ ಸುಲಭ ಆಯ್ತು. ಮೊದಳಿಂದಲೂ ನನಗೆ ಇಂಜಿನಿಯರಿಂಗ್ ಹೇಗೆ ಎಲ್ಲಾ ಕ್ಷೇತ್ರದಲ್ಲೂ ಜೀವನ ಸುಲಭ ಮಾಡ್ತಾ ಇದೆ ಅಂತ ನೋಡಿ ಅದರ ಬಗ್ಗೆ ತೀವ್ರ ಆಸಕ್ತಿ ಇತ್ತು. ಅಲ್ಲದೆ ನಾನು ಗಣಿತವನ್ನು ಬಹಳ ಇಷ್ಟ ಪಡ್ತಾ ಇದ್ದೆ. ಅದೂ ಅಲ್ಲದೆ ಅದರಲ್ಲಿ ತುಂಬಾ ಉದ್ಯೋಗಗಳು ಲಭ್ಯ ಇದ್ದವು, ಹಾಗಾಗಿ ಗಣಿತವನ್ನು ಆಯ್ಕೆ ಮಾಡಿಕೊಳ್ಳೋದು ಸೂಕ್ತ ಅಂತ ಅನ್ನಿಸ್ತು. ",
              "te": "ఇది నాకు సులువైనది. ఇంజినీరింగ్ ప్రతి రంగంలో జీవితాన్ని ఎలా సులువుగా చేస్తుందో చూసి నేను ఆకర్షితుడయ్యాను. నేను గణితాన్ని చాలా ఆనందించాను. ఇది చాలా ఉద్యోగలలో ఉపయోగపడుతుందని గణితాన్ని ఎంచుకోవాలని అర్ధమైంది.",
              "or": "ଏହା ମୋ ପାଇଁ ସରଳ ଥିଲା | ପ୍ରତ୍ୟେକ କ୍ଷେତ୍ରରେ ଇଞ୍ଜିନିୟରିଂ କିପରି ଜୀବନକୁ \n ସହଜ କରିଥାଏ ମୁଁ ଆକର୍ଷିତ ହୋଇଥିଲି | ମୁଁ ଗଣିତକୁ ବହୁତ ଉପଭୋଗ କଲି | \n ଏହାର ଅନେକ ଚାକିରି ଖୋଲିବା ମଧ୍ୟ ଥିଲା, ତେଣୁ ଗଣିତ ଗ୍ରହଣ କରିବା ଠିକ |",
              "as": "এই কথাটো মোৰ বাবে সহজ আছিল। অভিযান্ত্ৰিক বিদ্যাই কেনেকৈ প্ৰতিটো ক্ষেত্ৰতে জীৱন সহজ কৰি তোলে তাক লৈ মই আকৰ্ষিত হৈছিলোঁ। মই গণিত বহুত ভাল পাইছিলোঁ। ইয়াত বহুতো চাকৰিৰ আৰম্ভণি ও আছিল, সেয়েহে গণিত গ্ৰহণ কৰাটো প্ৰয়োজনীয় আছিল।",
              "gu": "તે મારા માટે સરળ હતું. એન્જિનિયરિંગ દરેક ક્ષેત્રમાં જીવનને કેવી રીતે સરળ બનાવે છે તે જોઈને હું આકર્ષિત થયો. મને ગણિતમા ખૂબ મજા આવતી. તેની પાસે ઘણી નોકરીઓ પણ હતી, તેથી તેને ગણિત લેવાનો વિચાર હતો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I never saw myself becoming a teacher, but back then, I was very interested in the science of the natural world. I knew that if I took biology, I could take up something in medicine, but more importantly, it allowed me to gain more knowledge about everything that fascinated me.",
              "hi": "मैंने कभी खुद को शिक्षक के रूप में नहीं देखा, लेकिन उस समय, मुझे प्राकृतिक दुनिया के विज्ञान में बहुत रुचि थी। मुझे पता था कि अगर मैंने जीवविज्ञान ले लिया, तो मैं मेडिसिन में कुछ ले सकूंगा, लेकिन इससे भी महत्वपूर्ण बात यह है कि इसने मुझे सब चीज़ों के बारे में अधिक जानकारी प्राप्त करने का अवसर दिया जिसके प्रति मैं आकर्षित था।",
              "ka": "ನಾನು ಶಿಕ್ಷಕಿಯಾಗುತ್ತೇನೆ ಅಂತ ಎಂದಿಗೂ ಅಂದುಕೊಂಡಿರಲಿಲ್ಲ, ಆದರೆ ಆಗ, ನಾನು ನಿಸರ್ಗ ಪ್ರಪಂಚದ ವಿಜ್ಞಾನದಲ್ಲಿ ತುಂಬಾ ಆಸಕ್ತಿ ಇತ್ತು. ಹಾಗಾಗಿ ನಾನು ಜೀವಶಾಸ್ತ್ರವನ್ನು ತೆಗೆದುಕೊಂಡರೆ, ನಾನು ಔಷಧ ಕ್ಷೇತ್ರದಲ್ಲಿ ಯಾವುದನ್ನಾದರೂ ತೆಗೆದುಕೊಳ್ಳಬಹುದು ಅಂತ ನನಗೆ ತಿಳಿದಿತ್ತು, ಆದರೆ ಅದಕ್ಕಿಂತ ಹೆಚ್ಚು, ನನ್ನನ್ನು ಆಕರ್ಷಿಸಿದ ಎಲ್ಲ ವಿಷಯಗಳ ಬಗ್ಗೆ ಹೆಚ್ಚಿನ ಜ್ಞಾನವನ್ನು ಪಡೆಯಲು ಅದು ನನಗೆ ಅವಕಾಶ ಮಾಡಿಕೊಟ್ಟಿತು",
              "te": "నేనెప్పుడూ టీచర్‌గా అవ్వాలని అనుకోలేదు, కానీ అప్పటికి నాకు సైన్స్ మీద చాలా ఆసక్తి ఉండేది. నేను జీవశాస్త్రం తీసుకుంటే, నేను మెడిసిన్‌లో ఏదైనా తీసుకోవచ్చని నాకు తెలిసింది. కానీ మరీ ముఖ్యంగా నన్ను ఆకర్షించిన ప్రతిదాని గురించి మరింత జ్ఞానాన్ని పొందేలా చేసింది.",
              "or": "ମୁଁ ନିଜକୁ ଶିକ୍ଷକ ହେବାକୁ କେବେ ଦେଖି ନଥିଲି, କିନ୍ତୁ ସେତେବେଳେ, ମୁଁ ପ୍ରାକୃତିକ\n  ଜଗତର ବିଜ୍ଞାନ ପ୍ରତି ବହୁତ ଆଗ୍ରହୀ ଥିଲି | ମୁଁ ଜାଣିଥିଲି ଯେ ଯଦି ମୁଁ ଜୀବବିଜ୍ଞାନ \n ଗ୍ରହଣ କରେ, ତେବେ ମୁଁ medicine ଷଧରେ କିଛି ନେଇପାରେ, କିନ୍ତୁ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ\n  କଥା ହେଉଛି, ଏହା ମୋତେ ଆକର୍ଷିତ କରୁଥିବା ସମସ୍ତ ବିଷୟ ବିଷୟରେ ଅଧିକ \n ଜ୍ଞାନ ଆହରଣ କରିବାକୁ ଦେଇଥାଏ |",
              "as": "মই কেতিয়াও নিজকে শিক্ষক হ’ম বুলি ভৱা নাছিলোঁ, কিন্তু সেই সময়ত, মই আমাৰ প্ৰাকৃতিক পৃথিৱীখনৰ বিজ্ঞানৰ প্ৰতি যথেষ্ট আগ্ৰহী আছিলোঁ। মই জানিছিলো যে, যদি মই জীৱবিজ্ঞান গ্ৰহণ কৰোঁ, মই ঔষধৰ বিষয়ত কিবা এটা ল'ব পাৰোঁ, কিন্তু অধিক গুৰুত্বপূৰ্ণভাৱে ই, মোক আকৰ্ষিত কৰা সকলো বিষয়ৰ ক্ষেত্ৰত অধিক জ্ঞান আহৰণ কৰিবলৈ আগ্ৰহ জন্মাইছিল।",
              "gu": "મેં મારી જાતને ક્યારેય શિક્ષક બનતા જોયા નથી, પરંતુ તે સમયે, મને કુદરતી વિશ્વના વિજ્ઞાનમાં ખૂબ જ રસ હતો. હું જાણતો હતો કે જો હું જીવ વિજ્ઞાન લઈશ, તો હું દવામાં કંઈક લઈ શકીશ, પરંતુ વધુ મહત્ત્વની વાત એ છે કે તે મને આકર્ષિત કરતી દરેક વસ્તુ વિશે વધુ જ્ઞાન મેળવવાની મંજૂરી આપો છો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Hmmm, maybe that’s how I should choose as well. I should take the subjects I am most interested in.",
              "hi": "हमम, शायद इसी तरह मुझे भी चुनाव करना चाहिए। मुझे उन विषयों को लेना चाहिए जिनमें मुझे सबसे ज्यादा रुचि है।",
              "ka": "ಹ್ಮ್ಮ, ಬಹುಶಃ ನಾನು ಕೂಡ ಹೀಗೆಯೇ ಆರಿಸಿಕೊಳ್ಳಬೇಕೋ ಏನೋ. ನಾನು ಹೆಚ್ಚು ಆಸಕ್ತಿ ಹೊಂದಿರುವ ವಿಷಯಗಳನ್ನು ನಾನು ತೆಗೆದುಕೊಳ್ಳಬೇಕು.",
              "te": "అవును, నేను కూడా అలానే ఎంచుకోవాలి. నాకు అత్యంత ఆసక్తి ఉన్న సబ్జెక్టులను సెలెక్ట్ చేసుకోవాలి.",
              "or": "ହମ୍, ବୋଧହୁଏ ସେପରି ଭାବରେ ମୁଁ ମଧ୍ୟ ବାଛିବା ଉଚିତ୍ | ମୁଁ ଯେଉଁ ବିଷୟଗୁଡ଼ିକରେ \n ଅଧିକ ଆଗ୍ରହୀ, ମୁଁ ତାହା ଗ୍ରହଣ କରିବା ଉଚିତ୍ |",
              "as": "হুম্, হয়তো ময়ো এনেদৰে বাছনি কৰাটোৱেই ভাল হ'ব। মই আটাইতকৈ আগ্ৰহী বিষয়বোৰহে লোৱা উচিত।",
              "gu": "હમમમ, કદાચ આ રીતે મારે પણ પસંદ કરવું જોઈએ. મારે એવા વિષયો લેવા જોઈએ જેમાં મને સૌથી વધુ રસ છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_1_4_D",
              "hi": "X5_1_4_D",
              "ka": "X5_1_4_D",
              "te": "X5_1_4_D",
              "or": "X5_1_4_D",
              "as": "X5_1_4_D",
              "gu": "X5_1_4_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Mira do?",
              "hi": "मीरा को क्या करना चाहिए?",
              "ka": "ಮೀರಾ ಈಗ ಏನು ಮಾಡಬೇಕು?",
              "te": "మీరా ఏమి చేయాలి?",
              "or": "ମୀରା କଣ କରିବା ଉଚିତ?",
              "as": "মীৰাই কি কৰাটো উচিত?",
              "gu": "મીરાએ શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Take up Physics, Chemistry, Maths",
              "hi": "भौतिकी, रसायन विज्ञान, गणित ले ",
              "ka": "ಭೌತ ಶಾಸ್ತ್ರ, ರಸಾಯನ ಶಾಸ್ತ್ರ ಹಾಗೂ ಗಣಿತ ಶಾಸ್ತ್ರವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕೇ",
              "te": "ఫిజిక్స్, కెమిస్ట్రీ, మ్యాథ్స్ తీసుకోండి",
              "or": "ପଦାର୍ଥ ବିଜ୍ଞାନ, ରସାୟନ ବିଜ୍ଞାନ, ଗଣିତ ନିଅ |",
              "as": "পদাৰ্থ বিজ্ঞান, ৰসায়ন বিজ্ঞান, গণিত গ্ৰহণ কৰাটো",
              "gu": "ભૌતિકશાસ્ત્ર, રસાયણશાસ્ત્ર, ગણિત લેવું"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Take up Physics, Chemistry, Biology",
              "hi": "भौतिकी, रसायन विज्ञान, जीवविज्ञान ले",
              "ka": "ಭೌತ ಶಾಸ್ತ್ರ, ರಸಾಯನ ಶಾಸ್ತ್ರ ಹಾಗೂ ಸಸ್ಯ ಶಾಸ್ತ್ರವನ್ನು ತೆಗೆದುಕೊಳ್ಳಕೇ",
              "te": "ఫిజిక్స్, కెమిస్ట్రీ, బయాలజీని తీసుకోండి",
              "or": "ପଦାର୍ଥ ବିଜ୍ଞାନ, ରସାୟନ ବିଜ୍ଞାନ, ଜୀବ ବିଜ୍ଞାନ ନିଅ |",
              "as": "পদাৰ্থ বিজ্ঞান, ৰসায়ন বিজ্ঞান, জীৱবিজ্ঞান গ্ৰহণ কৰাটো",
              "gu": "ભૌતિકશાસ્ત્ર, રસાયણશાસ્ત્ર, જીવવિજ્ઞાન લેવું"
            }
          }
        ]
      },
      "scene6": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Take up Maths",
              "hi": "गणित ले ",
              "ka": "ಗಣಿತ ಶಾಸ್ತ್ರವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕೇ",
              "te": "గణితం తీసుకోండి",
              "or": "ଗଣିତ ନିଅନ୍ତୁ |",
              "as": "গণিত গ্ৰহণ কৰাটো",
              "gu": "ગણિત લેવું"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira took Maths with Physics and Chemistry in her 11th. She enjoys studying Physics the most, especially the subjects where practicals are involved.",
              "hi": "मीरा ने ग्यारहवीं  में भौतिकी और रसायन शास्त्र के साथ गणित लिया। वह भौतिकी का  अध्ययन करने में सबसे अधिक आनंद लेती है, विशेष रूप से उन विषयों में जहां प्रयोग शामिल हैं।",
              "ka": "ಮೀರಾ 11 ನೆಯ ತರಗತಿಯಲ್ಲಿ ಭೌತ ಶಾಸ್ತ್ರ ಹಾಗೂ ರಸಾಯನ ಶಾಸ್ತ್ರವನ್ನು ತೆಗೆದುಕೊಂಡಳು. ಅವಳು ಭೌತ ಶಾಸ್ತ್ರವನ್ನು ಓಡುವುದರಲ್ಲಿ ಅತ್ಯಂತ ಸಂತೃಪ್ತಿಯನ್ನು ಹೊಂದುತ್ತಾಳೆ, ಅದರಲ್ಲಿಯೂ ಪ್ರಯೋಗಗಳನ್ನು ಒಳಗೊಂಡ ವಿಷಯಗಳು.",
              "te": "మీరా తన 11వ తరగతిలో ఫిజిక్స్ మరియు కెమిస్ట్రీతో మ్యాథ్స్ చదివింది. ఆమె భౌతిక శాస్త్రాన్ని మరియు ప్రాక్టికల్స్ తో ఉన్న సబ్జెక్టులను ఎక్కువగా చదువుతుంది.",
              "or": "ମୀରା ଏକାଦଶରେ ପଦାର୍ଥ ବିଜ୍ଞାନ ଏବଂ ରସାୟନ ବିଜ୍ଞାନ ସହିତ ଗଣିତ ନେଇଥିଲେ |\n  ସେ ପଦାର୍ଥ ବିଜ୍ଞାନକୁ ଅଧିକ ଅଧ୍ୟୟନ କରିବାକୁ ଉପଭୋଗ କରନ୍ତି, ବିଶେଷତ \n  ଯେଉଁଠାରେ ପ୍ରାକ୍ଟିକାଲ୍ସ ଜଡିତ |",
              "as": "মীৰাই 11th শ্ৰেণীত পঢ়োঁতে পদাৰ্থ বিজ্ঞান আৰু ৰসায়ন বিজ্ঞানৰ সৈতে গণিত লৈছিল। তাই পদাৰ্থ বিজ্ঞান বিষয়টো আটাইতকৈ বেছি ভাল পায়, বিশেষকৈ সেইবোৰ বিষয় য'ত ব্যৱহাৰিক বিজ্ঞান জড়িত থাকে।",
              "gu": "મીરાએ 11મા ધોરણમાં ભૌતિકશાસ્ત્ર અને રસાયણશાસ્ત્ર સાથે ગણિત લીધું હતું. તેને ભૌતિકશાસ્ત્રનો અભ્યાસ કરવાનો સૌથી વધારે આનંદ છે, ખાસ કરીને તે વિષયો જ્યાં પ્રયોગો સામેલ છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_1_2",
              "hi": "A1_1_2",
              "ka": "A1_1_2",
              "te": "A1_1_2",
              "or": "A1_1_2",
              "as": "A1_1_2",
              "gu": "A1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She experiments with heat transfer, static electricity, and circuits.",
              "hi": "वह हीट ट्रांसफर, स्टैटिक इलेक्ट्रिसिटी , और सर्किट के साथ प्रयोग करती है।",
              "ka": "ಅವಳು ಶಾಖ ವರ್ಗಾವಣೆ, ತಟಸ್ತ ವಿದ್ಯುತ್ ಮತ್ತು ಸರ್ಕ್ಯೂಟ್ ಪ್ರಯೋಗಗಳನ್ನು ಮಾಡುತ್ತಾಳೆ",
              "te": "ఆమె హీట్ ట్రాన్స్ఫర్ , స్టాటిక్ ఎలక్ట్రిసిటీ, మరియు సర్క్యూట్లతో ప్రయోగాలు చేస్తుంది.",
              "or": "ସେ ଉତ୍ତାପ ସ୍ଥାନାନ୍ତର, ଷ୍ଟାଟିକ୍ ବିଦ୍ୟୁତ୍ ଏବଂ ସର୍କିଟ୍ ସହିତ ପରୀକ୍ଷଣ କରନ୍ତି |",
              "as": "তাই তাপ স্থানান্তৰ, স্থিৰ বিদ্যুৎ, আৰু বৰ্তনীবোৰৰ সৈতে পৰীক্ষা কৰে।",
              "gu": "તે ઉષ્મા સંક્રમણ, સ્થિર વીજળી અને સર્કિટ સાથે પ્રયોગ કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_1_3",
              "hi": "A1_1_3",
              "ka": "A1_1_3",
              "te": "A1_1_3",
              "or": "A1_1_3",
              "as": "A1_1_3",
              "gu": "A1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Learning practical applications of Physics and Mathematics help her improve her problem-solving skills.",
              "hi": "भौतिकी और गणित के व्यावहारिक अनुप्रयोग सीखना उसकी समस्या सुलझाने के कौशल में सुधार करने में सहायता करता है।",
              "ka": "ಭೌತಶಾಸ್ತ್ರ ಮತ್ತು ಗಣಿತದ ಪ್ರಾಯೋಗಿಕ ಅಪ್ಲಿಕೇಷನ್ ಅನ್ನು ಕಲಿಯುವುದು ಅವಳ ಸಮಸ್ಯೆ-ಪರಿಹರಿಸುವ ಕೌಶಲ್ಯಗಳನ್ನು ಸುಧಾರಿಸಲು ಸಹಾಯ ಮಾಡಬಹುದು.",
              "te": "ఫిజిక్స్ మరియు మ్యాథమెటిక్స్ యొక్క ప్రాక్టికల్ అప్లికేషన్లను నేర్చుకోవడం వలన ఆమె సమస్య పరిష్కార నైపుణ్యాలను మెరుగుపరుస్తుంది.",
              "or": "ପଦାର୍ଥ ବିଜ୍ଞାନ ଏବଂ ଗଣିତର ବ୍ୟବହାରିକ ପ୍ରୟୋଗ ଶିଖିବା ତାଙ୍କୁ ସମସ୍ୟା ସମାଧାନ\n  କୌଶଳର ଉନ୍ନତି କରିବାରେ ସାହାଯ୍ୟ କରେ |",
              "as": "পদাৰ্থ বিজ্ঞান আৰু গণিতৰ ব্যৱহাৰিক প্ৰয়োগ শিকাটোৱে তাইক তাইৰ সমস্যা সমাধানৰ দক্ষতা উন্নত কৰাত সহায় কৰে।",
              "gu": "ભૌતિકશાસ્ત્ર અને ગણિતના વ્યવહારુ ઉપયોગો શીખવાથી તેને તેની સમસ્યા હલ કરવાની કુશળતા સુધારવામાં મદદ મળે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_2_1",
              "hi": "A1_2_1",
              "ka": "A1_2_1",
              "te": "A1_2_1",
              "or": "A1_2_1",
              "as": "A1_2_1",
              "gu": "A1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira works hard in school as she wants to get good marks. Meanwhile, students in her class have joined coaching centres to prepare for entrance exams for colleges.",
              "hi": "मीरा स्कूल में कड़ी मेहनत करती है क्योंकि वह अच्छे अंक प्राप्त करना चाहती है। इस बीच, कॉलेज में छात्राएं  प्रवेश परीक्षा की तैयारी के लिए कोचिंग केंद्रों में दाखिला लेती हैं।",
              "ka": "ಮೀರಾ ಉತ್ತಮ ಅಂಕಗಳನ್ನು ಪಡೆಯಬೇಕೆಂದು ಶಾಲೆಯಲ್ಲಿ ಕಠಿಣ ಪರಿಶ್ರಮ ಪಡ್ತಾ ಇದ್ದಾಳೆ. ಇದರ ಮಧ್ಯದಲ್ಲಿ, ಅವಳ ತರಗತಿಯ ವಿದ್ಯಾರ್ಥಿಗಳು ಕಾಲೇಜುಗಳಿಗೆ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಗಳಿಗೆ ತಯಾರಿ ಮಾಡಿಕೊಳ್ಳಲು ಕೋಚಿಂಗ್ ಸೆಂಟರ್‌ಗಳಿಗೆ ಸೇರಿಕೊಂಡಿದ್ದಾರೆ.",
              "te": "మంచి మార్కులు తెచ్చుకోవాలని మీరా స్కూల్‌లో కష్టపడుతుంది. ఇంతలో, ఆమె తరగతిలోని విద్యార్థులు కళాశాలల ప్రవేశ పరీక్షలకు సిద్ధం కావడానికి కోచింగ్ సెంటర్‌లలో చేరారు.",
              "or": "ମୀରା ଭଲ ମାର୍କ ପାଇବାକୁ ଚାହୁଁଥିବାରୁ ସ୍କୁଲରେ କଠିନ ପରିଶ୍ରମ କରନ୍ତି | ଏହି \n ସମୟରେ, ତାଙ୍କ ଶ୍ରେଣୀର ଛାତ୍ରମାନେ କଲେଜଗୁଡ଼ିକ ପାଇଁ ପ୍ରବେଶିକା ପରୀକ୍ଷା \n ପାଇଁ କୋଚିଂ ସେଣ୍ଟରରେ ଯୋଗ ଦେଇଛନ୍ତି |",
              "as": "মীৰাই স্কুলত ভাল নম্বৰ পোৱাৰ আশাৰে কঠোৰ পৰিশ্ৰম কৰে। ইফালে, তাইৰ শ্ৰেণীৰ শিক্ষাৰ্থীসকলে মহাবিদ্যালয়ৰ প্ৰৱেশ পৰীক্ষাৰ বাবে প্ৰস্তুতি কৰিবলৈ প্ৰশিক্ষণ কেন্দ্ৰত যোগদান কৰিছে।",
              "gu": "મીરા શાળામાં સખત મહેનત કરે છે કારણ કે તે સારા ગુણ મેળવવા માંગે છે. દરમિયાન, તેના વર્ગના વિદ્યાર્થીઓ કોલેજોની પ્રવેશ પરીક્ષાની તૈયારી કરવા માટે કોચિંગ કેન્દ્રોમાં જોડાયા છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_2_2",
              "hi": "A1_2_2",
              "ka": "A1_2_2",
              "te": "A1_2_2",
              "or": "A1_2_2",
              "as": "A1_2_2",
              "gu": "A1_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is concerned because she has made no plans for college so far. Her friend Preeti is also facing a similar problem.",
              "hi": "मीरा चिंतित है क्योंकि उसने अब तक कॉलेज के लिए कोई योजना नहीं बनाई है। उसकी मित्र प्रीति को भी इसी तरह की समस्या का सामना करना पड़ रहा है।",
              "ka": "ಮೀರಾ ಇದುವರೆಗೆ ಕಾಲೇಜಿಗೆ ಸೇರುವ ಯಾವುದೇ ಯೋಜನೆಯನ್ನು ಮಾಡಿಕೊಳ್ಳದ ಕಾರಣ ಕಳವಳಗೊಂಡಿದ್ದಾಳೆ. ಆಕೆಯ ಸ್ನೇಹಿತೆ ಪ್ರೀತಿ ಕೂಡ ಇದೇ ಸಮಸ್ಯೆ ಎದುರಿಸುತ್ತಿದ್ದಾಳೆ.",
              "te": "ఇప్పటి వరకు కాలేజీకి సంబంధించి ఎలాంటి ప్లాన్ వేసుకోనందున మీరా ఆందోళన చెందుతోంది. ఆమె స్నేహితురాలు ప్రీతి కూడా ఇదే సమస్యను ఎదుర్కొంటోంది.",
              "or": "ମୀରା ଚିନ୍ତିତ କାରଣ ସେ ଏପର୍ଯ୍ୟନ୍ତ କଲେଜ ପାଇଁ କୌଣସି ଯୋଜନା କରିନାହାଁନ୍ତି |\n  ତାଙ୍କ ବନ୍ଧୁ ପ୍ରୀତି ମଧ୍ୟ ସମାନ ସମସ୍ୟାର ସମ୍ମୁଖୀନ ହେଉଛନ୍ତି।",
              "as": "মীৰাই এতিয়ালৈকে মহাবিদ্যালয়ৰ বাবে কোনো পৰিকল্পনা কৰা নাই।তাইৰ চিন্তা সেইখিনিতেই। তাইৰ বান্ধৱী প্ৰীতিও একে ধৰণৰ সমস্যাৰ সন্মুখীন হৈছে।",
              "gu": "મીરા ચિંતિત છે કારણ કે તેને અત્યાર સુધી કોલેજ માટે કોઈ યોજના બનાવી. તેની મિત્ર પ્રીતિ પણ આવી જ સમસ્યાનો સામનો કરી રહી છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_2_3",
              "hi": "A1_2_3",
              "ka": "A1_2_3",
              "te": "A1_2_3",
              "or": "A1_2_3",
              "as": "A1_2_3",
              "gu": "A1_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After class, they sit back to work on their assignments while everyone else rushes for tuition and coaching classes.",
              "hi": "कक्षा के बाद, वे अपने असाइनमेंट पर काम करने के लिए वापस बैठती हैं जबकि हर कोई ट्यूशन और कोचिंग क्लासेस के लिए जाता है।",
              "ka": "ತರಗತಿಯ ನಂತರ, ಎಲ್ಲರೂ ಟ್ಯೂಷನ್ ಮತ್ತು ಕೋಚಿಂಗ್ ತರಗತಿಗಳಿಗೆ ಹೋಗುತ್ತಿರುವಾಗ ಅವರು ತಮ್ಮ ಅಸೈನ್‌ಮೆಂಟ್‌ಗಳ ಮೇಲೆ ಕೆಲಸ ಮಾಡಲು ಕುಳಿತುಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "తరగతి తర్వాత, వారు తమ అసైన్‌మెంట్‌లపై పని చేయడానికి తిరిగి కూర్చుంటారు, అయితే అందరూ ట్యూషన్ మరియు కోచింగ్ క్లాసులకు వెళతారు.",
              "or": "କ୍ଲାସ ପରେ, ସେମାନେ ନିଜ ଦାୟିତ୍ୱରେ କାମ କରିବାକୁ ଫେରି ଆସନ୍ତି ଯେତେବେଳେ \n ଅନ୍ୟ ସମସ୍ତେ ଟ୍ୟୁସନ ଏବଂ କୋଚିଂ କ୍ଲାସ ପାଇଁ ଦୌଡ଼ନ୍ତି |",
              "as": "শ্ৰেণীৰ পিছত, তেওঁলোকে তেওঁলোকৰ এচাইনমেণ্টৰ কামত কাম কৰিবলৈ উভতি বহে, আৰু আন সকলোৱে টিউচন আৰু প্ৰশিক্ষণ শ্ৰেণীৰ বাবে দৌৰি ওলাই যায়।",
              "gu": "વર્ગ પછી, તેઓ તેમની સોંપણીઓ પર કામ કરવા પાછા બેસે છે જ્યારે બાકીના બધા ટ્યુશન અને કોચિંગ વર્ગો માટે દોડી જાય છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_1",
              "hi": "A1_3_1",
              "ka": "A1_3_1",
              "te": "A1_3_1",
              "or": "A1_3_1",
              "as": "A1_3_1",
              "gu": "A1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "One day Mira returns home late. She has been studying with Preeti for a test. Neetu Aunty sees her enter and notices that she looks a little upset.",
              "hi": "एक दिन मीरा देर से घर लौटती है। वह एक परीक्षा के लिए प्रीति के साथ अध्ययन कर रही थी । नीतू चाची उसे आते देखती हैं और ध्यान देती हैं  कि वह थोड़ा परेशान है।",
              "ka": "ಒಂದು ದಿನ ಮೀರಾ ತಡವಾಗಿ ಮನೆಗೆ ಬರ್ತಾಳೆ. ಅವಳು ಪ್ರೀತಿಯೊಂದಿಗೆ ಪರೀಕ್ಷೆಗಾಗಿ ಓದ್ತಾ ಕೂತಿದ್ಲು. ನೀತು ಆಂಟಿ ಅವಳು ಬರ್ತಾ ಇರುವುದನ್ನು ನೋಡ್ತಾರೆ ಮತ್ತು ಅವಳು ಸ್ವಲ್ಪ ಅಸಮಾಧಾನಗೊಂಡಂತೆ ಕಾಣುತ್ತಾಳೆ.",
              "te": "ఒకరోజు మీరా ఆలస్యంగా ఇంటికి తిరిగి వస్తుంది. ఆమె ప్రీతితో కలిసి పరీక్ష కోసం చదువుతోంది. మీరా లోపలికి రావడం మరియు ఆమె కొంచెం కంగారుగా కనిపించడం నీతూ ఆంటీ చూసింది.",
              "or": "ଦିନେ ମୀରା ବିଳମ୍ବରେ ଘରକୁ ଫେରିଯାଏ | ସେ ଏକ ପରୀକ୍ଷା ପାଇଁ ପ୍ରୀତିଙ୍କ ସହ\n  ଅଧ୍ୟୟନ କରୁଛନ୍ତି | ନୀତୁ ମାଉସୀ ତାଙ୍କ ପ୍ରବେଶକୁ ଦେଖି ଲକ୍ଷ୍ୟ କଲେ ଯେ ସେ \n ଟିକେ ଚିନ୍ତିତ ଲାଗୁଛି |",
              "as": "এদিন মীৰা পলমকৈ ঘৰলৈ উভতি আহিল। তাই প্ৰীতিৰ সৈতে এটা পৰীক্ষাৰ বাবে পঢ়ি আছে। নীতু আণ্টিয়ে তাইক প্ৰৱেশ কৰা দেখিলে আৰু তাইৰ মনটো যেন বৰ ভাল নহয়, তেনে অনুমান কৰিলে।",
              "gu": "એક દિવસ મીરા મોડી ઘરે પરત ફરે છે. તે પ્રીતિ સાથે કસોટી માટે અભ્યાસ કરી રહી છે. નીતુ કાકી તેને પ્રવેશતા જુએ છે અને નોંધે છે કે તે થોડી અસ્વસ્થ દેખાય છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_2//",
              "hi": "A1_3_2//",
              "ka": "A1_3_2//",
              "te": "A1_3_2//",
              "or": "A1_3_2//",
              "as": "A1_3_2//",
              "gu": "A1_3_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Neetu Aunty",
              "hi": "नीतू चाची",
              "ka": "ನೀತು ಆಂಟಿ",
              "te": "నీతూ ఆంటీ",
              "or": "ନିତୁ ମାଉସୀ",
              "as": "নীতু খুড়ী",
              "gu": "નીતુ કાકી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Neetu Aunty",
              "hi": "नीतू चाची",
              "ka": "ನೀತು ಆಂಟಿ",
              "te": "నీతూ ఆంటీ",
              "or": "ନିତୁ ମାଉସୀ",
              "as": "নীতু খুড়ী",
              "gu": "નીતુ કાકી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Meera, you look so tired. What is bothering you? Are you finding your subjects too difficult?",
              "hi": "मीरा, तुम बहुत थकी हुई लग रही हो । तुमको क्या बात परेशान कर रही है? क्या तुमको अपने विषय बहुत मुश्किल लग रहे हैं?",
              "ka": "ಮೀರಾ, ನೀನು ತುಂಬಾ ಸುಸ್ತಾಗಿ ಕಾಂತಾ ಇದ್ದೀಯಾ. ನಿನಗೆ ಏನು ತೊಂದರೆಯಾಗುತ್ತಿದೆ? ನಿಮ್ಮ ವಿಷಯಗಳು ತುಂಬಾ ಕಷ್ಟಕರವೆಂದು ನಿಮಗೆ ಅನ್ನಿಸ್ತಾ ಇದೆಯೇ?",
              "te": "మీరా, నువ్వు చాలా అలసిపోయినట్టు కనిపిస్తున్నావు. నిన్ను బాధపెడుతున్నది ఏమిటి? నీకు సబ్జెక్ట్ లు కష్టంగా ఉన్నాయా?",
              "or": "ମିରା, ତୁମେ ବହୁତ କ୍ଳାନ୍ତ ଦେଖାଯାଉଛ | ତୁମକୁ କ’ଣ କଷ୍ଟ ଦେଉଛି? ତୁମେ ତୁମର\n  ବିଷୟଗୁଡିକ ବହୁତ କଷ୍ଟକର କି?",
              "as": "মীৰা, তোমাক ইমান ভাগৰুৱা যেন লাগিছে। কিবা হৈছে নেকি? তুমি তোমাৰ বিষয়বোৰ বৰ টান পাইছা নেকি বাৰু?",
              "gu": "મીરા, તું બહુ થાકેલી લાગે છે. તને શું પરેશાન કરે છે? શું તને તારા વિષયો ખૂબ મુશ્કેલ લાગે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "No, aunty, I’m doing okay in school. I understand the subjects well.",
              "hi": "नहीं, चाची, मैं स्कूल में ठीक कर रही हूँ। मैं विषयों को अच्छी तरह से समझ रही हूं।",
              "ka": "ಇಲ್ಲ, ಆಂಟಿ, ನಾನು ಶಾಲೆಯಲ್ಲಿ ಚೆನ್ನಾಗಿಯೇ ಇದ್ದೇನೆ. ನಾನು ವಿಷಯಗಳನ್ನು ಚೆನ್ನಾಗಿ ಅರ್ಥಮಾಡಿಕೊಳ್ತಾ ಇದ್ದೀನಿ.",
              "te": "లేదు ఆంటీ, నేను స్కూల్లో బాగానే ఉన్నాను. సబ్జెక్ట్స్ కూడా బాగా అర్థం చేసుకుంటున్నాను.",
              "or": "ନା, ମାଉସୀ, ମୁଁ ସ୍କୁଲରେ ଠିକ ଅଛି | ମୁଁ ବିଷୟଗୁଡିକ ଭଲ ଭାବରେ ବୁଝିପାରୁଛି |",
              "as": "নাই খুড়ী। মই স্কুলত ভালেই পাইছোঁ। বিষয়বোৰো ভালকৈ বুজি পাইছোঁ।",
              "gu": "ના, કાકી, હું શાળામાં ઠીક છું. હું વિષયોને સારી રીતે સમજું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Then what is it?",
              "hi": "तो फिर क्या है?",
              "ka": "ಹಾಗಾದರೆ ಮತ್ತೇನು?",
              "te": "మరి కారణం ఏమిటి?",
              "or": "ତାହେଲେ କଣ?",
              "as": "তেন্তে কি হৈছে?",
              "gu": "પછી તે શું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "I don’t have a plan! I plan everything. I even plan my market trips, but I don’t know what to do with my studies.",
              "hi": "मेरे पास कोई योजना नहीं है! मैं सारी योजना बनाती हूं। मैं बाजार जाने की भी योजना बनाती हूं, लेकिन मुझे नहीं पता कि मुझे  पढ़ाई के लिए क्या करना चाहिए।",
              "ka": "ನನ್ನ ಬಳಿ ಯೋಜನೆ ಇಲ್ಲ! ನಾನು ಎಲ್ಲವನ್ನೂ ಯೋಜಿಸುತ್ತೇನೆ. ನಾನು ನನ್ನ ಮಾರುಕಟ್ಟೆಗೆ ಹೋಗಿ ಬರುವುದನ್ನು ಕೂಡಾ ಯೋಜಿಸುತ್ತೇನೆ, ಆದರೆ ನಾನು ನನ್ನ ಮುಂದಿನ ಅಧ್ಯಯನ ಏನು ಮಾಡಬೇಕು ಅಂತ ತಿಳಿದಿಲ್ಲ.",
              "te": "నా దగ్గర ప్లాన్ లేదు! అన్నీ ప్లాన్ చేస్తాను. చివరికి నా మార్కెట్ కి వెళ్ళేది కూడా ప్లాన్ చేస్తాను, కానీ నా చదువు విషయంలో ఏమి చేయాలో నాకు తెలియదు.",
              "or": "ମୋର କୌଣସି ଯୋଜନା ନାହିଁ! ମୁଁ ସବୁକିଛି ଯୋଜନା କରୁଛି | ମୁଁ ମୋର ବଜାର\n  ଯାତ୍ରା ପାଇଁ ମଧ୍ୟ ଯୋଜନା କରେ, କିନ୍ତୁ ମୋର ଅଧ୍ୟୟନ ସହିତ କ’ଣ କରିବାକୁ ମୁଁ \n ଜାଣେ ନାହିଁ |",
              "as": "মই কোনো পৰিকল্পনা কৰিব পৰা নাই! মই সকলো কথাই পৰিকল্পনা কৰিহে কৰোঁ। আনকি মোৰ বজাৰলৈ যাব লগা হলেও পৰিকল্পনা কৰোঁ, কিন্তু মোৰ পঢ়াৰ ক্ষেত্ৰতহে কি কৰিলে ভাল হয় ধৰিব পৰা নাই।",
              "gu": "મારી પાસે કોઈ યોજના નથી! હું બધી યોજના કરું છું. હું મારી માર્કેટ ટ્રિપ્સની પણ યોજના કરું છું, પણ મને ખબર નથી કે મારા અભ્યાસ સાથે શું કરવું."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_3////",
              "hi": "A1_3_3////",
              "ka": "A1_3_3////",
              "te": "A1_3_3////",
              "or": "A1_3_3////",
              "as": "A1_3_3////",
              "gu": "A1_3_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "It’s okay not to have a plan. At least you aren’t blindly taking up something you are not interested in. Take your time to find what you love doing.",
              "hi": " योजना न होना ठीक है। कम से कम तुम कुछ ऐसा नहीं कर रही हो, जिसमें तुम रुचि नहीं रखती। तुम जो करना चाहती हो उसे समझने के लिए और समय लो।",
              "ka": "ಯೋಜನೆ ಇಲ್ಲದಿದ್ದರೂ ಪರವಾಗಿಲ್ಲ. ಕನಿಷ್ಠ ಪಕ್ಷ ನಿನಗೆ ಆಸಕ್ತಿಯಿಲ್ಲದ ಯಾವುದನ್ನಾದರೂ ನೀನು ಕುರುಡಾಗಿ ತೆಗೆದುಕೊಳ್ತಾ ಇಲ್ಲ. ನಿನಗೆ ಯಾವುದು ಇಷ್ಟ ಅಂತ  ಕಂಡುಹಿಡಿಯೋಕ್ಕೆ ನಿನ್ನ ಸಮಯ ತೆಗೆದುಕೊ.",
              "te": "ప్లాన్ లేకపోయినా ఫర్వాలేదు. కనీసం నీకు ఆసక్తి లేని విషయాన్ని గుడ్డిగా తీసుకోకు. నువ్వు ఇష్టపడే పనిని తెలుసుకోవడానికి సమయం తీసుకో.",
              "or": "ଯୋଜନା ନକରିବା ଠିକ୍ ଅଟେ | ଅନ୍ତତ ତୁମେ ଅନ୍ଧ ଭାବରେ କିଛି ଗ୍ରହଣ କରୁନାହଁ \n  ଯାହା ପାଇଁ ତୁମେ ନୁହଁନ୍ତି | ତୁମେ ଯାହା କରିବାକୁ ଭଲ ପାଅ ତାହା ଖୋଜିବା \n ପାଇଁ ସମୟ ନିଅ|",
              "as": "পৰিকল্পনা নাইকৰা ঠিকেই আছে। অন্ততঃ তুমি অন্ধভাৱে এনে কিবা এটা কৰা নাই, যত তোমাৰ আগ্ৰহ নাই। আমি কি কৰিবলৈ ভাল পোৱা বিচাৰিবলৈ অলপ সময় লৈ লোঁৱা।",
              "gu": "યોજના ન હોય તે ઠીક છે. ઓછામાં ઓછું તું આંખ આડા કાન કરી રહી નથી જેમાં તને રસ નથી. તને જે કરવાનું ગમે છે તે શોધવા માટે તારો સમય કાઢ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Neetu Aunty, other students in my school are already attending coaching for further studies. Everyone works so hard. It feels like Preeti and I are the only one’s going to be left behind.",
              "hi": "नीतू चाची, मेरे स्कूल की अन्य छात्राएं पहले से ही आगे के अध्ययन के लिए कोचिंग ले रही हैं। हर कोई इतना परिश्रम करता है। ऐसा लगता है प्रीति और मैं ही अकेले  पीछे छूटे जा रहे हैं।",
              "ka": "ನೀತು ಆಂಟಿ, ನನ್ನ ಶಾಲೆಯ ಬೇರೆ ವಿದ್ಯಾರ್ಥಿಗಳು ಈಗಾಗಲೇ ಹೆಚ್ಚಿನ ಅಧ್ಯಯನಕ್ಕಾಗಿ ಕೋಚಿಂಗ್‌ಗೆ ಹೋಗ್ತಾ ಇದ್ದಾರೆ. ಎಲ್ಲರೂ ತುಂಬಾ ಕಷ್ಟಪಟ್ಟು ಕೆಲಸ ಮಾದ್ತಾ ಇದ್ದಾರೆ. ಪ್ರೀತಿ ಮತ್ತು ನಾನು ಮಾತ್ರ ಹಿಂದೆ ಉಳಿದಿದ್ದೀವಿ ಅಂತ ಅನ್ನಿಸ್ತಾ ಇದೆ.",
              "te": "నీతూ ఆంటీ, నా స్కూల్‌లో ఉన్న విద్యార్థులు ఇప్పటికే తర్వాత చదువుల కోసం కోచింగ్‌కి వెళుతున్నారు. అందరూ చాలా కష్టపడి పని చేస్తారు. ప్రీతి మరియు నేను మాత్రమే మిగిలి ఉన్నాము అనిపిస్తుంది.",
              "or": "ନୀତୁ ମାଉସୀ, ମୋ ସ୍କୁଲର ଅନ୍ୟ ଛାତ୍ରମାନେ ପରବର୍ତ୍ତୀ ଅଧ୍ୟୟନ ପାଇଁ କୋଚିଂରେ \n ଯୋଗଦେଉଛନ୍ତି | ସମସ୍ତେ ବହୁତ ପରିଶ୍ରମ କରନ୍ତି | ଏମିତି ଲାଗୁଛି ପ୍ରୀତି ଏବଂ ମୁଁ ପଛରେ ରହିଯିବୁ |",
              "as": "নীতু খুড়ী আমাৰ স্কুলৰ আন শিক্ষাৰ্থীসকলে ইতিমধ্যে পিছৰ পঢ়াবোৰৰ বাবে প্ৰশিক্ষণ লৈ আছে। সকলোৱে ইমান কঠোৰ পৰিশ্ৰম কৰে। এনেকুৱা অনুভৱ হয় যেন প্ৰীতি আৰু মইহে একমাত্ৰ পিছ পৰি থাকিম।",
              "gu": "નીતુ કાકી, મારી શાળાના અન્ય વિદ્યાર્થીઓ પહેલાથી જ આગળના અભ્યાસ માટે કોચિંગમાં જાય છે. દરેક વ્યક્તિ ખૂબ મહેનત કરે છે. એવું લાગે છે કે પ્રીતિ અને હું એકલા જ પાછળ રહી જઈશું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Oho Mira. That’s all? Everyone has a different career journey. People discover their paths at different stages in life. You’re not late. But it is good that you are thinking about it at the right time.",
              "hi": "ओहो मीरा। बस इतना ही? हर किसी की एक अलग कैरियर यात्रा है। लोग जीवन के विभिन्न चरणों में अपने पथ खोजते हैं। तुमको देरी नहीं हुई है। लेकिन अच्छा है कि तुम इसे सही समय पर सोच रही हो।",
              "ka": "ಓಹೋ ಮೀರಾ. ಅಷ್ಟೇನಾ? ಪ್ರತಿಯೊಬ್ಬರೂ ಬೇರೆ ಬೇರೆ ವೃತ್ತಿಜೀವನವನ್ನು ಹೊಂದಿದ್ದಾರೆ. ಜನರು ಜೀವನದ ಬೇರೆ ಬೇರೆ ಹಂತದಲ್ಲಿ ತಮ್ಮ ಮಾರ್ಗಗಳನ್ನು ಕಂಡುಕೊಳ್ತಾರೆ ನಿಮಗೇನು ತಡವಾಗಿಲ್ಲ. ಆದರೆ ನೀವು ಸರಿಯಾದ ಸಮಯದಲ್ಲಿ ಅದರ ಬಗ್ಗೆ ಯೋಚಿಸೋದು ಒಳ್ಳೆದು.",
              "te": "ఓహో మీరా. అంతే కదా? ప్రతి ఒక్కరికి వేరు వేరు లైఫ్ ఉంటుంది. అందరూ జీవితంలోని వివిధ దశలలో తమ మార్గాలను తెలుసుకుంటారు. నువ్వు ఆలస్యం చేయలేదు. కానీ నువ్వు సరైన సమయంలో దాని గురించి ఆలోచించడం మంచిది.",
              "or": "ଓହୋ ମୀରା | ଖାଲି ଏତିକି? ସମସ୍ତଙ୍କର ଏକ ଭିନ୍ନ କ୍ୟାରିୟର ଯାତ୍ରା ଅଛି | ଲୋକମାନେ\n  ଜୀବନର ବିଭିନ୍ନ ପର୍ଯ୍ୟାୟରେ ସେମାନଙ୍କର ପଥ ଆବିଷ୍କାର କରନ୍ତି | ତୁମେ ବିଳମ୍ବ କରିନାହଁ କିନ୍ତୁ ଏହା ଭଲ ଯେ ତୁମେ ଠିକ୍ ସମୟରେ ଏହା ବିଷୟରେ ଚିନ୍ତା କରୁଛ। |",
              "as": "আচ্ছা। মীৰা, তেন্তে কথা সেইখিনিয়েই। সকলোৰে কেৰিয়াৰৰ যাত্ৰা বেলেগ বেলেগ। মানুহে জীৱনৰ বিভিন্ন পৰ্যায়ত তেওঁলোকৰ পথ আৱিষ্কাৰ কৰে। তোমাৰ পলম হোৱা নাই। কিন্তু এইটো ভাল হৈছে যে তুমি সঠিক সময়ত ইয়াৰ বিষয়ে চিন্তা কৰিব পাৰিছা।",
              "gu": "ઓહો મીરા. આટલું જ? દરેક વ્યક્તિની કારકિર્દીની સફર અલગ-અલગ હોય છે. લોકો જીવનના વિવિધ તબક્કે તેમના માર્ગો શોધે છે. તે મોડું નથી કર્યું. પરંતુ તે સારું છે કે તું તેના વિશે યોગ્ય સમયે વિચારી રહી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "So then what should I do now?",
              "hi": "तो फिर मुझे अब क्या करना चाहिए?",
              "ka": "ಹಾಗಾದರೆ ನಾನು ಈಗ ಏನು ಮಾಡಬೇಕು?",
              "te": "కాబట్టి నేను ఇప్పుడు ఏమి చేయాలి?",
              "or": "ତାହେଲେ ମୁଁ କଣ ବର୍ତ୍ତମାନ କଣ କରିବି?",
              "as": "তেন্তে মই এতিয়া কি কৰা উচিত?",
              "gu": "તો હવે મારે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Why don’t you start with the college fair that the school has every year? It’s happening next week. All colleges from different cities of Assam come and share information about their courses.",
              "hi": "तुम कॉलेज मेले से क्यों नहीं शुरू करती जो हर वर्ष आयोजित किया जाता है? यह अगले सप्ताह हो रहा है। असम के अलग - अलग शहरों के सभी कॉलेज आते हैं और अपने कोर्स के बारे में जानकारी साझा करते हैं।",
              "ka": "ಪ್ರತಿ ವರ್ಷ ಶಾಲೆ ನಡೆಸುವ ಕಾಲೇಜು ಮೇಳದಿಂದಲೇ ನೀವು ಏಕೆ ಪ್ರಾರಂಭಿಸಬಾರದು? ಆದು ಮುಂದಿನ ವಾರ ನಡೆಯುತ್ತದೆ. ಅಸ್ಸಾಂನ ವಿವಿಧ ನಗರಗಳಿಂದ ಎಲ್ಲಾ ಕಾಲೇಜುಗಳು ಬಂದು ತಮ್ಮ ಕೋರ್ಸ್‌ಗಳ ಬಗ್ಗೆ ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಳ್ತಾವೆ",
              "te": "పాఠశాలలో ప్రతి సంవత్సరం జరిగే కళాశాల ఫెయిర్‌తో నువ్వు ఎందుకు మొదలు పెట్టకూడదు? ఇది వచ్చే వారం జరుగుతుంది. అస్సాంలోని వివిధ నగరాల నుండి అన్ని కళాశాలలు వచ్చి తమ కోర్సుల గురించి సమాచారాన్ని తెలుపుతాయి.",
              "or": "ପ୍ରତିବର୍ଷ ବିଦ୍ୟାଳୟରେ ଥିବା କଲେଜ ମେଳା ସହିତ ତୁମେ କାହିଁକି ଆରମ୍ଭ କରୁନାହିଁ? ଏହା ଆସନ୍ତା ସପ୍ତାହରେ ହେବାକୁ ଯାଉଛି। | ଆସାମର ବିଭିନ୍ନ ସହରର ସମସ୍ତ କଲେଜ \n ଆସି ସେମାନଙ୍କ ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ସୂଚନା ବାଣ୍ଟନ୍ତି |",
              "as": "বিদ্যালয়খনত প্ৰতি বছৰে হৈ অহা মহাবিদ্যালয় মেলাৰ সৈতে তুমি কিয় যোগাযোগ নকৰা? এই মেলা অহা সপ্তাহত হ’বলৈ গৈ আছে। অসমৰ বিভিন্ন চহৰৰ সকলো মহাবিদ্যালয়ে আহি তেওঁলোকৰ পাঠ্যক্ৰমৰ বিষয়ে তথ্য ভাগ বতৰা কৰে।",
              "gu": "શાળામાં દર વર્ષે યોજાતા કોલેજ મેળાથી તું શા માટે શરૂઆત કરટી નથી? તે આવતા અઠવાડિયે થઈ રહ્યું છે. આસામના વિવિધ શહેરોમાંથી તમામ કોલેજો આવે છે અને તેમના અભ્યાસક્રમો વિશે માહિતી બતાવે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_4//",
              "hi": "A1_3_4//",
              "ka": "A1_3_4//",
              "te": "A1_3_4//",
              "or": "A1_3_4//",
              "as": "A1_3_4//",
              "gu": "A1_3_4//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Neetu Aunty",
              "hi": "नीतू चाची",
              "ka": "ನೀತು ಆಂಟಿ",
              "te": "నీతూ ఆంటీ",
              "or": "ନିତୁ ମାଉସୀ",
              "as": "নীতু খুড়ী",
              "gu": "નીતુ કાકી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "But how will that help me?",
              "hi": "लेकिन इससे मेरी सहायता कैसे होगी?",
              "ka": "ಆದರೆ ಅದು ನನಗೆ ಯಾವ ರೀತಿ ಸಹಾಯ ಮಾಡುತ್ತದೆ?",
              "te": "కానీ అది నాకు ఎలా సహాయం చేస్తుంది?",
              "or": "କିନ୍ତୁ ତାହା ମତେ କିପରି ସାହାଯ୍ୟ କରିବା?",
              "as": "কিন্তু ই বাৰু মোক কেনেকৈ সহায় কৰিব?",
              "gu": "પરંતુ તે મને કેવી રીતે મદદ કરશે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Once you know more about the courses, you will see which one’s get you excited.",
              "hi": "एक बार जब तुम्हें अलग अलग कोर्स के बारे में स्पष्ट जानकारी प्राप्त हो जाएगी, तो तुम यह देखना कि तुम किसके प्रति उत्साहित हो।",
              "ka": "ಕೋರ್ಸ್‌ಗಳ ಕುರಿತು ನೀನು ಹೆಚ್ಚು ತಿಳಿದುಕೊಂಡ ನಂತರ, ಯಾವುದು ನಿನಗೆ ಇಷ್ಟ ಆಯ್ತು ಎಂದು ನೋಡಬಹುದು.",
              "te": "నీకు కోర్సుల గురించి మరింత తెలిస్తే ఏది సెలెక్ట్ చేసుకోవలో అర్ధమవుతుంది.",
              "or": "ଥରେ ତୁମେ ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ଅଧିକ ଜାଣିବା ପରେ ତୁମେ ଦେଖିବେ କେଉଁଟି\n  ଆପଣଙ୍କୁ ଉତ୍ସାହିତ କରେ |",
              "as": "তুমি এবাৰ বিষয়বোৰ বুজি পোৱাৰ পিছত, কোনটো বিষয় তোমাৰ বেছি প্ৰিয় বাছি উলিয়াই ল'ব পাৰিবা।",
              "gu": "એકવાર તું અભ્યાસક્રમો વિશે વધુ જાણી લે, પછી તું જોશો કે કયો અભ્યાસક્રમ તને ઉત્સાહિત કરે છે."
            }
          }
        ]
      },
      "scene7": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_1",
              "hi": "A2_1_1",
              "ka": "A2_1_1",
              "te": "A2_1_1",
              "or": "A2_1_1",
              "as": "A2_1_1",
              "gu": "A2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is excited about going to the college fair. The next day she calls her mother.",
              "hi": "मीरा कॉलेज मेले जाने के बारे में उत्साहित है। अगले दिन वह अपनी माँ को कॉल करती है।",
              "ka": "ಮೀರಾ ಕಾಲೇಜು ಮೇಳಕ್ಕೆ ಹೋಗುವ ಉತ್ಸಾಹದಲ್ಲಿದ್ದಾಳೆ. ಮರುದಿನ ಅವಳು ತನ್ನ ತಾಯಿಗೆ ಕಾಲ್ ಮಾಡ್ತಾಳೆ",
              "te": "మీరా కాలేజ్ ఫెయిర్‌కి వెళ్లాలని ఉత్సాహంగా ఉంది. మరుసటి రోజు ఆమె తన తల్లికి ఫోన్ చేస్తుంది.",
              "or": "କଲେଜ ମେଳାକୁ ନେଇ ମୀରା ଉତ୍ସାହିତ ଅଛନ୍ତି | ପରଦିନ ସେ ତାଙ୍କ ମାଙ୍କୁ ଡାକନ୍ତି |",
              "as": "মীৰা মহাবিদ্যালয় মেলালৈ যোৱাৰ কথাটো লৈ উৎসাহিত হৈ পৰিল। পিছদিনা তাই তাইৰ মাকক মাতি পথালে।",
              "gu": "મીરા કોલેજના મેળામાં જવા માટે ઉત્સાહિત છે. બીજા દિવસે તે તેની માતાને બોલાવે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_2//",
              "hi": "A2_1_2//",
              "ka": "A2_1_2//",
              "te": "A2_1_2//",
              "or": "A2_1_2//",
              "as": "A2_1_2//",
              "gu": "A2_1_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mother",
              "hi": "मां",
              "ka": "ಅಮ್ಮ",
              "te": "తల్లి",
              "or": "ମା",
              "as": "মাক",
              "gu": "માતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Mother",
              "hi": "मां",
              "ka": "ಅಮ್ಮ",
              "te": "తల్లి",
              "or": "ମା",
              "as": "মাক",
              "gu": "માતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Mira, what am I to tell Shantanu. He failed another exam, and he didn’t even feel any regret.",
              "hi": "मीरा, मैं शांतनु को क्या समझाऊँ। वह एक और परीक्षा में असफल हो गया, और उसे कोई पछतावा भी नहीं है।",
              "ka": "ಮೀರಾ, ಶಂತನುಗೆ ನಾನು ಏನು ಹೇಳಲಿ. ಅವನು ಮತ್ತೊಂದು ಪರೀಕ್ಷೆಯಲ್ಲಿ ಅನುತ್ತೀರ್ಣನಾಗಿದ್ದಾನೆ ಮತ್ತು ಅವನು ಆದರ ಬಗ್ಗೆ ವಿಷಾದವನ್ನು ಕೂಡ ಹೊಂದಿಲ್ಲ.",
              "te": "మీరా, షాంతను గురించి నేను ఏమి చెప్పాను. అతను మరొక పరీక్షలో ఫెయిల్ అయ్యాడు మరియు దాని గురించి కొంచెం కూడా బాధ పడటం లేదు.",
              "or": "ମୀରା, ମୁଁ ଶାନ୍ତନୁଙ୍କୁ କ’ଣ କହିବି | ସେ ଅନ୍ୟ ଏକ ପରୀକ୍ଷାରେ ବିଫଳ ହୋଇଛି , \n ଏବଂ ସେ କୌଣସି ଦୁଃଖ ମଧ୍ୟ ଅନୁଭବ କରିନଥିଲେ |",
              "as": "মীৰা, শান্তনুক মই কি ক'ম। সি এটা পৰীক্ষাত ফেইল কৰিছিল, আৰু সি আনকি তাৰ বাবে কোনো অনুশোচনাও অনুভৱ কৰা নাছিল।",
              "gu": "મીરા, હું શાંતનુને શું કહું. તે બીજી પરીક્ષામાં નાપાસ થયો, અને તેને કોઈ અફસોસ પણ નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "He failed another exam?!",
              "hi": "वह एक और परीक्षा में विफल रहा?!",
              "ka": "ಅವನು ಮತ್ತೊಂದು ಪರೀಕ್ಷೆಯಲ್ಲಿ ಅನುತ್ತೀರ್ಣನಾದನೇ?",
              "te": "అతను మరో పరీక్షలో ఫెయిల్ అయ్యాడా?!",
              "or": "ସେ ଆଉ ଏକ ପରୀକ୍ଷାରେ ବିଫଳ ହୋଇଥିଲେ ?!",
              "as": "সি এটা পৰীক্ষাত ফেইল কৰিছিল?!",
              "gu": "તે બીજી પરીક્ષામાં નાપાસ થયો ?!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Yes, the teacher called me to school today. Shantanu doesn’t concentrate in class at all, and neither does he make any effort at home. I wish he would learn something from you and have an aim in life.",
              "hi": "हां, शिक्षक ने आज मुझे स्कूल बुलाया। शांतनु कक्षा में ध्यान केंद्रित नहीं करता है, और न ही वह घर पर कोई प्रयास करता है। काश वह तुमसे कुछ सीखता और जीवन में उसका एक लक्ष्य होता।",
              "ka": "ಹೌದು, ಇಂದು ಶಿಕ್ಷಕರು ನನ್ನನ್ನು ಶಾಲೆಗೆ ಕರೆದಿದ್ರು. ಶಂತನು ತರಗತಿಯಲ್ಲಿ ಗಮನ ನೀಡಲ್ಲ ಮತ್ತು ಮನೆಯಲ್ಲಿ ಕೂಡ ಯಾವುದೇ ಪ್ರಯತ್ನವನ್ನೂ ಮಾಡುವುದಿಲ್ಲ. ಅವನು ನಿನ್ನಿಂದ ಸ್ವಲ್ಪನಾದರೂ ಕಲಿಯಲಿ ಮತ್ತು ಜೀವನದಲ್ಲಿ ಒಂದು ಗುರಿ ಹೊಂದಾಳಿ ಅಂತ ನನ್ನ ಅಪೇಕ್ಷೆ .",
              "te": "అవును, ఈ రోజు ఉపాధ్యాయురాలు నన్ను పాఠశాలకు పిలిచారు. షాంతనుకి క్లాసులో ఏకాగ్రత లేదు, ఇంట్లో కూడా చదవడం లేదు.అతను ని నుండి ఏదైనా నేర్చుకుని జీవితంలో ఒక లక్ష్యాన్ని కలిగి ఉండాలని నేను కోరుకుంటున్నాను.",
              "or": "ହଁ, ଶିକ୍ଷକ ମୋତେ ଆଜି ସ୍କୁଲକୁ ଡାକିଲେ | ଶାନ୍ତନୁ ଆଦୌ ଶ୍ରେଣୀରେ ଧ୍ୟାନ ଦିଅନ୍ତି ନାହିଁ, \n ଏବଂ ଘରେ ମଧ୍ୟ ସେ କୌଣସି ପ୍ରୟାସ କରନ୍ତି ନାହିଁ | ମୁଁ ଇଚ୍ଛା କରେ ସେ ତୁମଠାରୁ କିଛି ଶିଖିବେ ଏବଂ ଜୀବନରେ ଏକ ଲକ୍ଷ୍ୟ ରଖିବେ |",
              "as": "হয়, শিক্ষকজনে মোক আজি স্কুললৈ মাতিছিল। শান্তনুৱে শ্ৰেণীত একেবাৰে মনোযোগ নিদিয়ে, আৰু সি ঘৰতো একেবাৰে চেষ্টা নকৰে। মই আশা কৰোঁ সি তোমাৰ পৰা পৰা কিবা এটা শিকিব আৰু অন্ততঃ জীৱনৰ লক্ষ্য বান্ধি ল'ব।",
              "gu": "હા, શિક્ષકે આજે મને શાળાએબોલાવી હતી . શાંતનુ વર્ગમાં જરા પણ ધ્યાન આપતો નથી, અને ન તો તે ઘરમાં કોઈ પ્રયત્નો કરતો નથી. હું ઈચ્છું છું કે તે તારી પાસેથી કંઈક શીખે અને જીવનમાં એક લક્ષ્ય રાખે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_3",
              "hi": "A2_1_3",
              "ka": "A2_1_3",
              "te": "A2_1_3",
              "or": "A2_1_3",
              "as": "A2_1_3",
              "gu": "A2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is concerned about Shantanu. She knows that he is smart but also very lazy. She tells her mother to not worry about him, that he will find his way eventually.",
              "hi": "मीरा शांतनु के बारे में चिंतित है। वह जानती है कि वह स्मार्ट है लेकिन बहुत आलसी भी है। वह अपनी मां को उसके बारे में चिंता न करने के लिए कहती है, कि वह अंततः अपना रास्ता खोज लेगा।",
              "ka": "ಮೀರಾ, ಶಂತನು ಬಗ್ಗೆ ಕಳಕಳಿ ಹೊಂದುತ್ತಾಳೆ. ಅವನು ಬುದ್ಧಿವಂತನಾದರೂ ತುಂಬಾ ಸೋಮಾರಿ ಅಂತ ಅವಳಿಗೆ ತಿಳಿದಿದೆ. ಅವಳು ತನ್ನ ತಾಯಿಗೆ ಅವನ ಬಗ್ಗೆ ಚಿಂತಿಸಬೇಡ, ಅವನು ಕೊನೆಯಲ್ಲಿ ತನ್ನ ದಾರಿಯನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾನೆ ಅಂತ ಹೇಳ್ತಾಳೆ.",
              "te": "మీరా శంతను గురించి ఆందోళన చెందుతోంది. అతను తెలివిగలవాడని మరయూ చాలా సోమరి అని ఆమెకు తెలుసు. అతని గురించి చింతించవద్దని,చివరికి అతను తన మార్గాన్ని కనుగొంటాడు. ఆమె తన తల్లికి చెబుతుంది",
              "or": "ମୀରା ଶାନ୍ତନୁଙ୍କ ବିଷୟରେ ଚିନ୍ତିତ | ସେ ଜାଣନ୍ତି ଯେ ସେ ସ୍ମାର୍ଟ କିନ୍ତୁ ବହୁତ ଅଳସୁଆ | ସେ ତାଙ୍କ ମା’ଙ୍କୁ ତାଙ୍କ ବିଷୟରେ ଚିନ୍ତା ନକରିବାକୁ କୁହନ୍ତି, ଯେ ସେ ଶେଷରେ ତାଙ୍କ ବାଟ ପାଇବେ |",
              "as": "মীৰাই শান্তনুক লৈ বৰ চিন্তা কৰে। তাই জানে যে সি স্মাৰ্ট কিন্তু অতি এলেহুৱাও। তাই তাইৰ মাকক তাৰ বিষয়ে চিন্তা নকৰিবলৈ কৈ সি যে অৱশেষত নিজৰ পথ এটা বিচাৰি পাব, সেইবিষয়ে বুজালে।",
              "gu": "મીરાને શાંતનુની ચિંતા છે. તે જાણે છે કે તે હોશિયાર છે પણ ખૂબ આળસુ પણ છે. તે તેની માતાને તેની ચિંતા ન કરવાનું કહે છે, કે તે આખરે તેનો માર્ગ શોધી લેશે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_4",
              "hi": "A2_1_4",
              "ka": "A2_1_4",
              "te": "A2_1_4",
              "or": "A2_1_4",
              "as": "A2_1_4",
              "gu": "A2_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira brings up the confusion regarding her career with her mother.",
              "hi": "मीरा मां को अपने करियर के विषय में अपने मन की दुविधा बताती है।",
              "ka": "ಮೀರಾ ತನ್ನ ತಾಯಿಯೊಂದಿಗೆ ತನ್ನ ವೃತ್ತಿಜೀವನದ ಬಗ್ಗೆ ಇರುವ ಗೊಂದಲವನ್ನು ಹೇಳ್ತಾಳೆ.",
              "te": "మీరా తన తల్లితో, తన కెరీర్ గురించి పడుతున్న గందరగోలాన్ని చెబుతుంది",
              "or": "ମୀରା ତାଙ୍କ ମା ସହିତ ତାଙ୍କ କ୍ୟାରିୟର ଦ୍ଵନ୍ଦ୍ଵ ବିଷୟରେ ଆଲୋଚନା କରନ୍ତି |",
              "as": "মীৰাই তাইৰ নিজৰ কেৰিয়াৰৰ সম্পৰ্কে বিমোৰত পৰি থকা কথাটো মাকক ক'লে।",
              "gu": "મીરા તેની માતા સાથે તેની કારકિર્દીને લગતી મૂંઝવણો લાવે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_5////",
              "hi": "A2_1_5////",
              "ka": "A2_1_5////",
              "te": "A2_1_5////",
              "or": "A2_1_5////",
              "as": "A2_1_5////",
              "gu": "A2_1_5////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Hmm. I’m not sure, Ma. I need more information before I decide.",
              "hi": "हम्म। मुझे समझ नहीं आ रहा है, माँ । निश्चय करने से पहले मुझे अधिक जानकारी चाहिए।",
              "ka": "ಹ್ಮ. ನನಗೆ ಖಚಿತವಾಗಿ ತಿಳಿದಿಲ್ಲ, ಅಮ್ಮ. ನಾನು ನಿರ್ಧರಿಸುವ ಮೊದಲು ನನಗೆ ಇನ್ನೂ ಹೆಚ್ಚಿನ ಮಾಹಿತಿ ಬೇಕು",
              "te": "అవును. నాకు ఖచ్చితంగా తెలియదు, అమ్మ. నేను నిర్ణయించుకునే ముందు మరింత ఎక్కువ సమాచారం తెలుసుకోవాలి.",
              "or": "ହଁ । ମୁଁ ନିଶ୍ଚିତ ନୁହେଁ, ମା ମୁଁ ନିଷ୍ପତ୍ତି ନେବା ପୂର୍ବରୁ ମୋର ଅଧିକ ସୂଚନା ଦରକାର |",
              "as": "হুম। মই নিশ্চিত হ'ব পৰা নাই, মা। মই সিদ্ধান্ত লোৱাৰ আগতে মোক আৰু তথ্যৰ প্ৰয়োজন আছে।",
              "gu": "હમમમ. મને ખાતરી નથી, મા. હું નક્કી કરું તે પહેલાં મને વધુ માહિતીની જરૂર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Mira, I trust that you will make a good decision. But I am worried about how will we afford these coaching classes? I have heard they cost a lot.",
              "hi": "मीरा, मुझे विश्वास है कि तुम एक सही निर्णय लोगी। लेकिन मैं इस बारे में चिंतित हूं कि हम इन कोचिंग कक्षाओं का शुल्क कैसे भरेंगे? मैंने सुना है कि वे बहुत महंगी होते हैं।",
              "ka": "ಮೀರಾ, ನೀನು ಒಳ್ಳೆಯ ನಿರ್ಧಾರ ತೆಗೆದುಕೊಳ್ಳುಟ್ಟಿ ಅಂತ  ನನಗೆ ನಂಬಿಕೆ ಇದೆ. ಆದರೆ ಈ ಕೋಚಿಂಗ್ ತರಗತಿಗಳಿಗೆ ಹಣ ಹೇಗೆ ಹೊಂದಿಸೋದು ಅಂತ  ನಾನು ಚಿಂತೆ ಮಾಡ್ತಾ ಇದ್ದೀನಿ? ಅದಕ್ಕೆ ತುಂಬಾ ಖರ್ಚಾತ್ತೆ ಅಂತ ನಾನು ಕೇಳಿದ್ದೇನೆ.",
              "te": "మీరా, నువ్వు మంచి నిర్ణయం తీసుకుంటావని నేను నమ్ముతున్నాను. కానీ నేను ఈ కోచింగ్ క్లాసులకు డబ్బులు ఎలా కట్టాలో అని భయపడుతున్నాను? వాటికి చాలా ఖర్చవుతుందని విన్నాను.",
              "or": "ମୀରା, ମୁଁ ବିଶ୍ୱାସ କରେ ତୁମେ ଏକ ଭଲ ନିଷ୍ପତ୍ତି ନେବ | କିନ୍ତୁ ମୁଁ ଚିନ୍ତିତ ଯେ ଏହି\n  କୋଚିଂ କ୍ଲାସଗୁଡିକ ପାଇଁ ଆମେ କିପରି ଏତେ ଖରଚ କରିପାରିବୁ ? ମୁଁ ଶୁଣିଛି\n  ଯେ ଏସବୁରେ ବହୁତ ଖର୍ଚ୍ଚ ହୁଏ |",
              "as": "মীৰা, তুমি যে এটা ভাল সিদ্ধান্ত ল'বা তাত মোৰ বিশ্বাস আছে।। কিন্তু এই প্ৰশিক্ষণ শ্ৰেণীবোৰৰ খৰচ কেনেকৈ বহন কৰিম সেইটোহে চিন্তা কৰিছোঁ। মই শুনিছোঁ তেওঁলোকৰ বহুত খৰচ হৈছে।",
              "gu": "મીરા, મને વિશ્વાસ છે કે તું સારો નિર્ણય લઇશ. પણ મને ચિંતા થાય છે કે આ કોચિંગ ક્લાસ આપણને કેવી રીતે પોસાય? મેં સાંભળ્યું છે કે તેમની કિંમત ઘણી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Don’t worry, Ma. Let me first see what courses are available.",
              "hi": "चिंता मत कीजिए, माँ। मुझे पहले देखने दीजिए कि कौन से कोर्स उपलब्ध हैं।",
              "ka": "ಚಿಂತಿಸಬೇಡಮ್ಮ. ಯಾವ ಕೋರ್ಸ್‌ಗಳು ಇದೆ ಅಂತ ಮೊದಲು ನೋಡೋಣ.",
              "te": "బాధ పడకు అమ్మ. ముందు ఏ కోర్సులు అందుబాటులో ఉన్నాయో చూద్దాం.",
              "or": "ବ୍ୟସ୍ତ ହୁଅନାହିଁ, ମା କେଉଁ ପାଠ୍ୟକ୍ରମ ଉପଲବ୍ଧ ଅଛି ମୁଁ ପ୍ରଥମେ ଦେଖିସାରେ |",
              "as": "তুমি চিন্তা নকৰিবা, মা। কি কি পাঠ্যক্ৰমনো আছে মই আগতে চাই লওঁ।",
              "gu": "ચિંતા કરશો નહીં, મા. મને પહેલા જોવા દો કે કયા કોર્સ ઉપલબ્ધ છે."
            }
          }
        ]
      },
      "scene8": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_1",
              "hi": "A3_1_1",
              "ka": "A3_1_1",
              "te": "A3_1_1",
              "or": "A3_1_1",
              "as": "A3_1_1",
              "gu": "A3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The following week, Preeti and Mira attend the college fair together.",
              "hi": "अगले हफ्ते, प्रीति और मीरा एक साथ कॉलेज मेले में भाग लेती  हैं।",
              "ka": "ಮುಂದಿನ ವಾರದಲ್ಲಿ, ಪ್ರೀತಿ ಮತ್ತು ಮೀರಾ ಒಟ್ಟಿಗೆ ಕಾಲೇಜು ಮೇಳಕ್ಕೆ ಹೋಗ್ತಾರೆ.",
              "te": "తర్వాత వారం, ప్రీతి మరియు మీరా కలిసి కాలేజీ ఫెయిర్‌కి వెళతారు.",
              "or": "ପରବର୍ତ୍ତୀ ସପ୍ତାହରେ, ପ୍ରୀତି ଏବଂ ମୀରା ଏକାଠି କଲେଜ ମେଳାରେ ଯୋଗ ଦେଇଥିଲେ |",
              "as": "পৰৱৰ্তী সপ্তাহত, প্ৰীতি আৰু মীৰাই একেলগে মহাবিদ্যালয় মেলালৈ গৈ উপস্থিত হ'ল।",
              "gu": "પછીના અઠવાડિયે, પ્રીતિ અને મીરા કોલેજના મેળામાં સાથે જાય છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_2",
              "hi": "A3_1_2",
              "ka": "A3_1_2",
              "te": "A3_1_2",
              "or": "A3_1_2",
              "as": "A3_1_2",
              "gu": "A3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They make a list of things they want to do at the fair. They are both interested in finding out more about engineering, but Preeti is also keen on the other courses.",
              "hi": "वे उन चीजों की एक सूची बनाती हैं जो वे मेले में करना चाहती हैं। वे दोनों इंजीनियरिंग के बारे में अधिक जानकारी लेने में रुचि रखती हैं, लेकिन प्रीति अन्य कोर्स के लिए भी उत्सुक है।",
              "ka": "ಅವರು ಮೇಳದಲ್ಲಿ ಮಾಡಲು ಬಯಸುವ ಪಟ್ಟಿ ತಯಾರು ಮಾಡ್ತಾರೆ ಅವರಿಬ್ಬರೂ ಇಂಜಿನಿಯರಿಂಗ್ ಬಗ್ಗೆ ಹೆಚ್ಚು ತಿಳಿದುಕೊಳ್ಳಲು ಆಸಕ್ತಿ ಹೊಂದಿದ್ದಾರೆ, ಆದರೆ ಪ್ರೀತಿ ಇತರ ಕೋರ್ಸ್‌ಗಳ ಬಗ್ಗೆಯೂ ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ",
              "te": "వారు ఫెయిర్ లో చేయాలనుకునే వాటిని లిస్ట్ తయారు చేస్తారు. ఇద్దరికి ఇంజినీరింగ్ గురించి మరింత తెలుసుకోవాలనే ఆసక్తి ఉంది, కానీ ప్రీతికి ఇతర కోర్సులపై కూడా ఆసక్తి ఉంది.",
              "or": "ସେମାନେ ମେଳାରେ କରିବାକୁ ଚାହୁଁଥିବା ଜିନିଷଗୁଡ଼ିକର ଏକ ତାଲିକା ପ୍ରସ୍ତୁତ କରନ୍ତି | \n ଇଞ୍ଜିନିୟରିଂ ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ଉଭୟେ ଆଗ୍ରହୀ, କିନ୍ତୁ ପ୍ରୀତି ଅନ୍ୟ \n ପାଠ୍ୟକ୍ରମଗୁଡ଼ିକ ପାଇଁ ମଧ୍ୟ ଆଗ୍ରହୀ |",
              "as": "তেওঁলোকে মেলাত কৰিব বিচৰা কামবোৰৰ এখন তালিকা প্ৰস্তুত কৰে। তেওঁলোক দুয়ো অভিযান্ত্ৰিকৰ বিষয়ত অধিক জানিবলৈ আগ্ৰহী, কিন্তু প্ৰীতিৰ আন পাঠ্যক্ৰমবোৰ বিষয়ে জানিবৰ বাবেও উত্সুকতা আছে।",
              "gu": "તેઓ મેળામાં જે વસ્તુઓ કરવા માગે છે તેની યાદી બનાવે છે. તેઓ બંને એન્જિનિયરિંગ વિશે વધુ જાણવામાં રસ ધરાવે છે, પરંતુ પ્રીતિ અન્ય અભ્યાસક્રમો માટે પણ ઉતસાહિત છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_3",
              "hi": "A3_1_3",
              "ka": "A3_1_3",
              "te": "A3_1_3",
              "or": "A3_1_3",
              "as": "A3_1_3",
              "gu": "A3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They decide that Mira will go to the Engineering stall to get brochures, ask questions and make notes. Preeti would do the same for non-engineering options.",
              "hi": "वे निश्चय करते हैं कि मीरा ब्रोशर पाने, प्रश्न पूछने और नोट्स बनाने के लिए इंजीनियरिंग स्टॉल में जाएगी। प्रीति गैर-इंजीनियरिंग विकल्पों के लिए भी ऐसा ही करेगी।",
              "ka": "ಬ್ರೋಷರ್‌ಗಳನ್ನು ಪಡೆಯಲು, ಪ್ರಶ್ನೆಗಳನ್ನು ಕೇಳಲು ಮತ್ತು ಟಿಪ್ಪಣಿಗಳನ್ನು ಮಾಡಲು ಮೀರಾ ಎಂಜಿನಿಯರಿಂಗ್ ಸ್ಟಾಲ್‌ಗೆ ಹೋಗಬೇಕೆಂದು ಅವರು ನಿರ್ಧರಿಸುತ್ತಾರೆ. ಪ್ರೀತಿ ಇಂಜಿನಿಯರಿಂಗ್ ಅಲ್ಲದ ಆಯ್ಕೆಗಳಿಗೆ ಅದೇ ರೀತಿ ಮಾಡುತ್ತಾಳೆ.",
              "te": "బ్రోచర్లు తీసుకోవడానికి, ప్రశ్నలు అడగడానికి మరియు నోట్స్ చేయడానికి మీరా ఇంజనీరింగ్ స్టాల్‌కి వెళ్లాలని నిర్ణయించుకుంది. ప్రీతి నాన్-ఇంజనీరింగ్ ఎంపికల కోసంకూడా అదే పని చేస్తుంది.",
              "or": "ସେମାନେ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି ଯେ ମୀରା ବ୍ରୋଚର ପାଇବା, ପ୍ରଶ୍ନ ପଚାରିବା ଏବଂ \n ନୋଟ୍ କରିବା ପାଇଁ ଇଞ୍ଜିନିୟରିଂ ଷ୍ଟଲକୁ ଯିବେ | ଅଣ ଇଞ୍ଜିନିୟରିଂ ବିକଳ୍ପ ପାଇଁ ପ୍ରୀତି ସମାନ କାର୍ଯ୍ୟ କରିବେ |",
              "as": "তেওঁলোকে সিদ্ধান্ত লয় যে মীৰাই পুস্তিকা পাবলৈ, প্ৰশ্ন সুধিবলৈ আৰু টোকা বনাবলৈ অভিযান্ত্ৰিক ষ্টললৈ যাব। প্ৰীতিয়ে অ-অভিযান্ত্ৰিক বিকল্পৰ বাবে একেটা কামকে কৰিব।",
              "gu": "તેઓ નક્કી કરે છે કે મીરા એન્જિનિયરિંગ વિભાગ પર પુસ્તિકાઓ મેળવવા, પ્રશ્નો પૂછવા અને નોંધો બનાવવા જશે. પ્રીતિ એન્જિનિયરિંગ વગરના વિકલ્પો માટે પણ આવું જ કરશે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_2_1",
              "hi": "A3_2_1",
              "ka": "A3_2_1",
              "te": "A3_2_1",
              "or": "A3_2_1",
              "as": "A3_2_1",
              "gu": "A3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They see many students and parents gathered around the stalls at the fair. College representatives are standing with placards of their colleges, inviting people to visit their stall.",
              "hi": "वे कई छात्रों और उनके माता-पिता को मेले में स्टाल के चारों ओर इकट्ठा देखते हैं। कॉलेज के प्रतिनिधि अपने कॉलेज के प्लेकार्ड के साथ खड़े हैं, जो लोगों को अपने स्टाल पर आने के लिए आमंत्रित कर रहे हैं।",
              "ka": "ಮೇಳದ ಸ್ಟಾಲ್‌ಗಳ ಸುತ್ತಲೂ ಅನೇಕ ವಿದ್ಯಾರ್ಥಿಗಳು ಮತ್ತು ಪೋಷಕರು ಜಮಾಯಿಸಿರುವುದನ್ನು ಅವರು ನೋಡುತ್ತಾರೆ. ಕಾಲೇಜು ಪ್ರತಿನಿಧಿಗಳು ತಮ್ಮ ಕಾಲೇಜುಗಳ ಪ್ಲೆಕಾರ್ಡ್‌ಗಳೊಂದಿಗೆ ನಿಂತು ತಮ್ಮ ಸ್ಟಾಲ್‌ಗೆ ಭೇಟಿ ನೀಡುವಂತೆ ಜನರನ್ನು ಆಹ್ವಾನಿಸುತ್ತಿದ್ದಾರೆ",
              "te": "వారు ఫెయిర్‌లోని స్టాళ్ల చుట్టూ చాలా మంది విద్యార్థులు మరియు తల్లిదండ్రులు గుమిగూడటం చూస్తారు. కళాశాల ప్రతినిధులు తమ కళాశాలల ప్లకార్డులతో నిలబడి తమ స్టాల్స్ ను చూడాలని అందరినీ ఆహ్వానిస్తున్నారు.",
              "or": "ସେମାନେ ଦେଖନ୍ତି ଯେ ମେଳାରେ ଷ୍ଟଲ ଚାରିପାଖରେ ବହୁ ଛାତ୍ର ଏବଂ ଅଭିଭାବକ \n ଏକତ୍ରିତ ହୋଇଥିଲେ | କଲେଜ ପ୍ରତିନିଧୀମାନେ ନିଜ କଲେଜଗୁଡ଼ିକର ପ୍ଲାକାର୍ଡ ସହିତ\n  ଠିଆ ହୋଇଛନ୍ତି, ଲୋକଙ୍କୁ ସେମାନଙ୍କ ଷ୍ଟଲ୍ ପରିଦର୍ଶନ କରିବାକୁ ଆମନ୍ତ୍ରଣ କରୁଛନ୍ତି |",
              "as": "তেওঁলোকে মেলাৰ ষ্টলবোৰৰ চাৰিওফালে বহুতো শিক্ষাৰ্থী আৰু অভিভাৱক আহি গোট খোৱা দেখিলে। মহাবিদ্যালয়ৰ প্ৰতিনিধিসকলে তেওঁলোকৰ মহাবিদ্যালয়ৰ প্লেকাৰ্ড লৈ থিয় হৈ আছে আৰু লোকসকলক তেওঁলোকৰ ষ্টললৈ যাবলৈ আমন্ত্ৰণ জনাই আছে।",
              "gu": "તેઓ મેળામાં સ્ટોલની આસપાસ ઘણા વિદ્યાર્થીઓ અને વાલીઓ એકઠા થયેલા જુએ છે. કોલેજના પ્રતિનિધિઓ તેમની કોલેજોના રમતનાકાર્ડ સાથે ઉભા છે, લોકોને તેમના સ્ટોલની મુલાકાત લેવા આમંત્રણ આપે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_2_2",
              "hi": "A3_2_2",
              "ka": "A3_2_2",
              "te": "A3_2_2",
              "or": "A3_2_2",
              "as": "A3_2_2",
              "gu": "A3_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira approaches the Engineering stall. She takes the college prospectus given to her by the college representative.",
              "hi": "मीरा इंजीनियरिंग स्टाल तक पहुंचती है। वह कॉलेज के प्रतिनिधि द्वारा दी गई कॉलेज प्रॉस्पेक्टस को लेती है।",
              "ka": "ಮೀರಾ ಇಂಜಿನಿಯರಿಂಗ್ ಸ್ಟಾಲ್ ಹತ್ತಿರ ಬರ್ತಾಳೆ. ಕಾಲೇಜು ಪ್ರತಿನಿಧಿ ನೀಡಿದ ಕಾಲೇಜು ಪ್ರಾಸ್ಪೆಕ್ಟಸ್ ಅನ್ನು ಅವಳು ತಗೋತಾಳೆ.",
              "te": "మీరా ఇంజనీరింగ్ స్టాల్ దగ్గరికి వెళుతుంది. కాలేజీ ప్రతినిధి ఇచ్చిన కాలేజీ ప్రాస్పెక్టస్ తీసుకుంటుంది.",
              "or": "ମୀରା ଇଞ୍ଜିନିୟରିଂ ଷ୍ଟଲ୍ ନିକଟକୁ ଆସନ୍ତି | ସେ କଲେଜ ପ୍ରତିନିଧୀଙ୍କ ଦ୍ଵାରା ଦିଆଯାଇଥିବା କଲେଜ ପ୍ରୋସପେକ୍ଟସ୍ ନିଅନ୍ତି |",
              "as": "মীৰা ইঞ্জিনিয়াৰিং ষ্টলৰ ওচৰলৈ আহিল। তাই মহাবিদ্যালয়ৰ প্ৰতিনিধিয়ে দিয়া মহাবিদ্যালয়ৰ প্ৰস্পেক্টাচখন লৈ আহিল।",
              "gu": "મીરા એન્જિનિયરિંગ સ્ટોલ પાસે પહોંચી. તે કોલેજના પ્રતિનિધિ દ્વારા તેને આપેલ કોલેજ પુસ્તિકા બતાવે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_2_2_notebookComponent",
              "hi": "A3_2_2_नोटबुककॉम्पोनेन्ट ",
              "ka": "A3_2_2_notebookComponent",
              "te": "A3_2_2_notebook భాగం",
              "or": "A3_2_2_ ନୋଟ୍ ବୁକ୍ ଉପାଦାନ |",
              "as": "A3_2_2_টোকাবহী উপাদান",
              "gu": "A3_2_2_નોટબુક અંશ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click on each page to learn about the courses offered:",
              "hi": "प्रस्तावित कोर्स के बारे में जानने के लिए प्रत्येक पृष्ठ पर क्लिक करें:",
              "ka": "ನೀಡಲಾಗುವ ಕೋರ್ಸ್‌ಗಳ ಕುರಿತು ತಿಳಿಯಲು ಪ್ರತಿ ಪುಟದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "అందించే కోర్సుల గురించి తెలుసుకోవడానికి ప్రతి పేజీపై క్లిక్ చేయండి:",
              "or": "ଦିଆଯାଇଥିବା ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ଜାଣିବା ପାଇଁ ପ୍ରତ୍ୟେକ ପୃଷ୍ଠାରେ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "আগবঢ়োৱা পাঠ্যক্ৰমবোৰৰ বিষয়ে জানিবলৈ প্ৰতিটো পৃষ্ঠাত ক্লিক কৰক:",
              "gu": "ઓફર કરેલા અભ્યાસક્રમો વિશે જાણવા માટે દરેક પેજ પર ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "CE",
              "hi": "सीई",
              "ka": "ಸಿ‌ಈ",
              "te": "CE",
              "or": "CE",
              "as": "CE",
              "gu": "CE"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "ME",
              "hi": "एमई ",
              "ka": "ಎಮ್‌ಈ",
              "te": "ME",
              "or": "ME",
              "as": "ME",
              "gu": "ME"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "CSIT",
              "hi": "सीएसआईटी",
              "ka": "ಸಿ‌ಎಸ್‌ಐ‌ಟಿ",
              "te": "CSIT",
              "or": "CSIT",
              "as": "CSIT",
              "gu": "CSIT"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "**Chemical Engineering**   \n \n- Course Details:Involves development and design of processes to manufacture chemicals.\n\n\n- Job Opportunities: Oil and gas companies, food and drink companies",
              "hi": "**केमिकल इंजीनियरिंग**\n \n- पाठ्यक्रम विवरण: रसायनों के निर्माण के लिए प्रक्रिया का विकास और डिजाइन शामिल है।\n\n\n- नौकरी के अवसर: तेल और गैस कंपनियां, खाद्य और पेय कंपनियां ",
              "ka": "**ರಾಸಾಯನಿಕ ಎಂಜಿನಿಯರಿಂಗ್** -- ಕೋರ್ಸ್ ವಿವರಗಳು: ರಾಸಾಯನಿಕಗಳನ್ನು ತಯಾರಿಸುವ ಪ್ರಕ್ರಿಯೆಗಳ ಅಭಿವೃದ್ಧಿ ಮತ್ತು ವಿನ್ಯಾಸವನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.-ಉದ್ಯೋಗಾವಕಾಶಗಳು: ತೈಲ ಮತ್ತು ಅನಿಲ ಕಂಪನಿಗಳು, ಆಹಾರ ಮತ್ತು ಪಾನೀಯ ಕಂಪನಿಗಳು",
              "te": "**కెమికల్ ఇంజనీరింగ్**\n \n  \n \n - కోర్సు వివరాలు: రసాయనాల తయారీ ప్రక్రియల అభివృద్ధి మరియు రూపకల్పనను కలిగి ఉంటుంది.\n \n \n \n \n \n - ఉద్యోగ అవకాశాలు: ఆయిల్ అండ్ గ్యాస్ కంపెనీలు, ఫుడ్ అండ్ డ్రింక్ కంపెనీలు",
              "or": "** କେମିକାଲ ଇଂଜିନିୟରିଂ **\n \n  \n \n - ପାଠ୍ୟକ୍ରମର ବିବରଣୀ: ରାସାୟନିକ ପଦାର୍ଥ ଉତ୍ପାଦନ ପାଇଁ ପ୍ରକ୍ରିୟାର ବିକାଶ ଏବଂ ଡିଜାଇନ୍ ସହିତ ଜଡିତ |\n \n \n \n \n \n - ଚାକିରି ସୁଯୋଗ: ତଇଳ ଏବଂ ଗ୍ୟାସ କମ୍ପାନୀ, ଖାଦ୍ୟ ଏବଂ ପାନୀୟ କମ୍ପାନୀଗୁଡିକ",
              "as": "**ৰাসায়নিক অভিযান্ত্ৰিক** \n  \n - পাঠ্যক্ৰমৰ বিৱৰণ: ৰাসায়নিক পদাৰ্থ নিৰ্মাণৰ প্ৰক্ৰিয়াৰ বিকাশ আৰু ডিজাইন অন্তৰ্ভুক্ত থাকে।\n \n - চাকৰিৰ সুযোগ: তেল আৰু গেছ কোম্পানী, খাদ্য আৰু পানীয় কোম্পানী",
              "gu": "**કેમિકલ એન્જિનિયરિંગ** - અભ્યાસક્રમની વિગતો:રસાયણોનું ઉત્પાદન કરવાની પ્રક્રિયાના વિકાસ અને ડિઝાઇનનો સમાવેશ થાય છે.. - નોકરીની તકો: તેલ અને ગેસ કંપનીઓ, ખાણી-પીણીની કંપનીઓ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "**Mechanical Engineering** \n\n- Course Details: \nInvolves design, manufacturing and maintenance of machines and mechanical systems.\n\n- Job Opportunities:\nPSUs, Factories",
              "hi": "**मैकेनिकल इंजीनियरिंग**\n\n- पाठ्यक्रम विवरण:\nमशीनों और यांत्रिक प्रणालियों के डिजाइन, मैनुफक्चरिंग और रखरखाव शामिल है।\n\n- रोजगार के अवसर:\nपीएसयू, कारखानों",
              "ka": "**ಯಾಂತ್ರಿಕ ಎಂಜಿನಿಯರಿಂಗ್**- ಕೋರ್ಸ್ ವಿವರಗಳು:\nಯಂತ್ರಗಳು ಮತ್ತು ಯಾಂತ್ರಿಕ ವ್ಯವಸ್ಥೆಗಳ ವಿನ್ಯಾಸ, ಉತ್ಪಾದನೆ ಮತ್ತು ನಿರ್ವಹಣೆಯನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.\n- ಉದ್ಯೋಗಾವಕಾಶಗಳು:\nಪಿಎಸ್ಯುಗಳು, ಕಾರ್ಖಾನೆಗಳು",
              "te": "**మెకానికల్ ఇంజనీరింగ్**\n \n \n \n - కోర్సు వివరాలు:\n \n యంత్రాలు మరియు యాంత్రిక వ్యవస్థల రూపకల్పన, తయారీ మరియు నిర్వాహణను కలిగి ఉంటుంది.\n \n \n \n - ఉద్యోగావకాశాలు:\n \n PSUలు, ఫ్యాక్టరీలు",
              "or": "** ମେକାନିକାଲ୍ ଇଞ୍ଜିନିୟରିଂ **\n \n \n \n - ପାଠ୍ୟକ୍ରମର ବିବରଣୀ:\n \n ମେସିନ୍ ଏବଂ ଯାନ୍ତ୍ରିକ ପ୍ରଣାଳୀର ଡିଜାଇନ୍, ଉତ୍ପାଦନ ଏବଂ ରକ୍ଷଣାବେକ୍ଷଣ ସହିତ ଜଡିତ |\n \n \n \n - ଚାକିରି ସୁଯୋଗ:\n \n PSU, କାରଖାନା",
              "as": "**মেকানিকেল ইঞ্জিনিয়াৰিং** \n \n - পাঠ্যক্ৰমৰ বিৱৰণ: \n যন্ত্ৰ আৰু যান্ত্ৰিক প্ৰণালীৰ ডিজাইন, নিৰ্মাণ আৰু তত্বাৱধান অন্তৰ্ভুক্ত থাকে।\n \n - চাকৰিৰ সুযোগ:\n পিএচইউ(PSU), কাৰখানাসমূহ",
              "gu": "**મિકેનિકલ એન્જિનિયરિંગ** - અભ્યાસક્રમની વિગતો: મશીનો અને મિકેનિકલ સિસ્ટમ્સની ડિઝાઇન, ઉત્પાદન અને જાળવણીનો સમાવેશ થાય છે. - નોકરીની તકો: PSUs, કારખાનના"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "**Computer science and Information technology**\n\n- Course Details: \n Involves programming and software development. \n\n- Job Opportunities:\n\nYou get to work with different technology companies.",
              "hi": "** कंप्यूटर साइंस और इन्फोर्मेशन टेक्नोलॉजी **\n\n- पाठ्यक्रम विवरण:\n प्रोग्रामिंग और सॉफ्टवेयर डेवलपमेंट शामिल है।\n\n- रोजगार के अवसर:\n\n विभिन्न तकनीकी कंपनियों के साथ काम करने के लिए मिलता है।",
              "ka": "**ಕಂಪ್ಯೂಟರ್ ವಿಜ್ಞಾನ ಮತ್ತು ಮಾಹಿತಿ ತಂತ್ರಜ್ಞಾನ** - ಕೋರ್ಸ್ ವಿವರಗಳು:\n ಪ್ರೋಗ್ರಾಮಿಂಗ್ ಮತ್ತು ಸಾಫ್ಟ್‌ವೇರ್ ಅಭಿವೃದ್ಧಿಯನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.\n- ಉದ್ಯೋಗಾವಕಾಶಗಳು:\nನೀವು ವಿವಿಧ ತಂತ್ರಜ್ಞಾನ ಕಂಪನಿಗಳೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುತ್ತೀರಿ",
              "te": "**కంప్యూటర్ సైన్స్ అండ్ ఇన్ఫర్మేషన్ టెక్నాలజీ**\n \n \n \n - కోర్సు వివరాలు:\n \n  ప్రోగ్రామింగ్ మరియు సాఫ్ట్ వేర్ అభివృద్ధిని కలిగి ఉంటుంది.\n \n \n \n -ఉద్యోగావకాశాలు:\n \n \n \n మీరు వివిధ సాంకేతిక సంస్థలతో పని చేయవచ్చు.",
              "or": "** କମ୍ପ୍ୟୁଟର ବିଜ୍ଞାନ ଏବଂ ସୂଚନା ପ୍ରଯୁକ୍ତିବିଦ୍ୟା **\n \n \n \n - ପାଠ୍ୟକ୍ରମର ବିବରଣୀ:\n \n  ପ୍ରୋଗ୍ରାମିଂ ଏବଂ ସଫ୍ଟୱେର୍ ବିକାଶ ସହିତ ଜଡିତ |\n \n \n \n - ଚାକିରି ସୁଯୋଗ:\n \n \n \n ଆପଣ ବିଭିନ୍ନ ଟେକ୍ନୋଲୋଜି କମ୍ପାନୀଗୁଡିକ ସହିତ କାର୍ଯ୍ୟ କରିବେ |",
              "as": "**কম্পিউটাৰ বিজ্ঞান আৰু তথ্য প্ৰযুক্তি**\n \n - পাঠ্যক্ৰমৰ বিৱৰণ: \n  প্ৰ'গ্ৰামিং আৰু ছফ্টৱেৰ বিকাশ অন্তৰ্ভুক্ত থাকে। \n \n - চাকৰিৰ সুযোগ:\n \n আপুনি বিভিন্ন প্ৰযুক্তি প্ৰতিষ্ঠানৰ সৈতে কাম কৰিবলৈ পায়।",
              "gu": "**કમ્પ્યુટર વિજ્ઞાન અને માહિતી ટેકનોલોજી** - કોર્સ વિગતો: પ્રોગ્રામિંગ અને સોફ્ટવેર ડેવલપમેન્ટ સામેલ છે. - નોકરીની તકો: તને વિવિધ ટેક્નોલોજી કંપનીઓ સાથે કામ કરવાની તક મળે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_2_3_reveal",
              "hi": "A3_2_3_रिवील ",
              "ka": "A3_2_3_reveal",
              "te": "A3_2_3_reveal",
              "or": "A3_2_3_reveal",
              "as": "A3_2_3_reveal",
              "gu": "A3_2_3_બતાવે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Mira has a list of questions she wants to get answered from the college representative.\n Click on the questions to read the answers she gets.",
              "hi": " मीरा के पास प्रश्नों की एक सूची है जिनका उत्तर उसे कॉलेज प्रतिनिधि से लेना है।\n उन उत्तरों को पढ़ने के लिए प्रश्नों पर क्लिक करें।",
              "ka": "ಮೀರಾ ತನಗೆ ಕಾಲೇಜು ಪ್ರತಿನಿಧಿಯಿಂದ ಉತ್ತರವನ್ನು ಪಡೆಯಲು ಬಯಸುವ ಪ್ರಶ್ನೆಗಳ ಪಟ್ಟಿಯನ್ನು ಹೊಂದಿದ್ದಾಳೆ. ಅವಳು ಪಡೆಯುವ ಉತ್ತರಗಳನ್ನು ಓದಲು ಪ್ರಶ್ನೆಗಳ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "మీరా కళాశాల ప్రతినిధి నుండి సమాధానం పొందాలనుకునే ప్రశ్నల లిస్ట్ కలిగి ఉంది.\n \n  ఆమె పొందే సమాధానాలను చదవడానికి ప్రశ్నలపై క్లిక్ చేయండి.",
              "or": "କଲେଜ ପ୍ରତିନିଧୀଙ୍କଠାରୁ ଉତ୍ତର ପାଇବାକୁ ଚାହୁଁଥିବା ପ୍ରଶ୍ନର ଏକ ତାଲିକା ମୀରା ପାଖରେ ଅଛି। \n  ସେ ପାଇଥିବା ଉତ୍ତରଗୁଡିକ ପ to ିବା ପାଇଁ ପ୍ରଶ୍ନଗୁଡିକ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ",
              "as": "মীৰাৰ হাতত মহাবিদ্যালয়ৰ প্ৰতিনিধিৰ পৰা উত্তৰ পাবলৈ বিচৰা প্ৰশ্নবোৰৰ এখন তালিকা আছে।\n  তাই পোৱা উত্তৰবোৰ পঢ়িবলৈ প্ৰশ্নবোৰত ক্লিক কৰক।",
              "gu": "મીરા પાસે એવા પ્રશ્નોની યાદી છે જે તે કોલેજના પ્રતિનિધિ પાસેથી જવાબ મેળવવા માંગે છે. તેને મળેલા જવાબો વાંચવા માટે પ્રશ્નો પર ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "What is the most popular course?",
              "hi": "सबसे लोकप्रिय पाठ्यक्रम क्या है?",
              "ka": "ಅತ್ಯಂತ ಜನಪ್ರಿಯ ಕೋರ್ಸ್ ಯಾವುದು?",
              "te": "అత్యంత ప్రజాదరణ పొందిన కోర్సు ఏది?",
              "or": "କେଉଁଟି ସବୁଠାରୁ ଲୋକପ୍ରିୟ ବିଷୟ?",
              "as": "আটাইতকৈ জনপ্ৰিয় পাঠ্যক্ৰমটো কি?",
              "gu": "સૌથી લોકપ્રિય અભ્યાસક્રમ કયો છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "How do we get admission to your college?",
              "hi": "हम आपके कॉलेज में प्रवेश कैसे प्राप्त करते हैं?",
              "ka": "ನಿಮ್ಮ ಕಾಲೇಜಿಗೆ ನಾವು ಹೇಗೆ ಪ್ರವೇಶವನ್ನು ಪಡೆಯುತ್ತೇವೆ?",
              "te": "మేము మీ కళాశాలలో ఎలా ప్రవేశం పొందుతాము?",
              "or": "ଆମେ କିପରି ତୁମ କଲେଜରେ ଆଡମିଶନ ପାଇବୁ?",
              "as": "আমি আপোনাৰ মহাবিদ্যালয়ত কেনেকৈ নামভৰ্তি কৰিম?",
              "gu": "અમને તમારી કોલેજમાં પ્રવેશ કેવી રીતે મેળવીશું?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "What is a cut off rank?",
              "hi": " कट ऑफ रैंक क्या है?",
              "ka": "ಕಟ್ ಆಫ್ ಶ್ರೇಣಿ ಎಷ್ಟು ಇರುತ್ತದೆ?",
              "te": "కట్ ఆఫ్ ర్యాంక్ అంటే ఏమిటి?",
              "or": "କଟ୍ ଅଫ୍ ରାଙ୍କ୍ କ’ଣ?",
              "as": "কাট অফ ৰেংক কি?",
              "gu": "કટ ઓફ ગુણ શું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "What is the fee structure for these courses?",
              "hi": "इन कोर्स के लिए शुल्क संरचना क्या है?",
              "ka": "ಈ ಕೋರ್ಸ್‌ಗಳಿಗೆ ಶುಲ್ಕ ರಚನೆ ಏನು?",
              "te": "ఈ కోర్సులో ఫీజులు ఎంత ఉంటాయి?",
              "or": "ଏହି ପାଠ୍ୟକ୍ରମଗୁଡ଼ିକ ପାଇଁ ଦେୟ ଗଠନ କ’ଣ?",
              "as": "এই পাঠ্যক্ৰমবোৰৰ বাবে মাচুলৰ গাঁথনি কি?",
              "gu": "આ અભ્યાસક્રમોની ફી માળખું શું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "CS and IT is pretty popular and has a very high demand. To get admission the competition is very high and the jobs pay well.",
              "hi": "सीएस और इन्फोर्मेशन टेक्नोलॉजी बहुत लोकप्रिय है और इसकी बहुत अधिक मांग है। प्रवेश पाने के लिए प्रतियोगिता बहुत अधिक है और नौकरियां अच्छा वेतन देती हैं।",
              "ka": "CS ಮತ್ತು IT ಬಹಳ ಜನಪ್ರಿಯವಾಗಿದೆ ಮತ್ತು ಹೆಚ್ಚಿನ ಬೇಡಿಕೆಯನ್ನು ಹೊಂದಿದೆ. ಪ್ರವೇಶ ಪಡೆಯಲು ಸ್ಪರ್ಧೆಯು ತುಂಬಾ ಹೆಚ್ಚಾಗಿರುತ್ತದೆ ಮತ್ತು ಉದ್ಯೋಗಗಳು ಉತ್ತಮವಾದ ವೇತನವನ್ನು ನೀಡುತ್ತವೆ.",
              "te": "CS మరియు IT కి చాలా మంచి పేరు ఉంది మరియు చాలా ఎక్కువ డిమాండ్ ఉంది. ఇందులో చేరడానికి పోటీ చాలా ఎక్కువగా ఉంటుంది మరియు జీతాలు బాగా వస్తాయి.",
              "or": "CS ଏବଂ IT ବହୁତ ଲୋକପ୍ରିୟ ଏବଂ ଏହାର ବହୁତ ଚାହିଦା ଅଛି | ଆଡମିଶନ\n  ପାଇବାକୁ ପ୍ରତିଯୋଗିତା ବହୁତ ଅଧିକ ଏବଂ ଚାକିରୀ ଭଲ ଦରମା ଦିଏ |",
              "as": "চিএছ(CS) আৰু আইটি(IT) যথেষ্ট জনপ্ৰিয় আৰু ইয়াৰ চাহিদা বহুত বেছি। নামভৰ্তি কৰিবলৈ প্ৰতিযোগিতা বেছি আৰু চাকৰিবোৰে ভাল দৰমহা দিয়ে।",
              "gu": "CS અને IT ખૂબ જ લોકપ્રિય છે અને તેની ખૂબ જ માંગ છે. પ્રવેશ મેળવવા માટે સ્પર્ધા ખૂબ ઊંચી છે અને નોકરીઓ સારી ચૂકવણી કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "We accept only the Joint Entrance Exam(JEE) results. And we have a strict cut off rank.",
              "hi": "हम केवल संयुक्त प्रवेश परीक्षा (जेईई) के परिणाम स्वीकार करते हैं। और हमारे पास एक सख्त कट ऑफ रैंक है।",
              "ka": "ನಾವು ಜಂಟಿ ಪ್ರವೇಶ ಪರೀಕ್ಷೆ (ಜೆಇಇ) ಫಲಿತಾಂಶಗಳನ್ನು ಮಾತ್ರ ಸ್ವೀಕರಿಸುತ್ತೇವೆ. ಮತ್ತು ನಾವು ಕಟ್ಟುನಿಟ್ಟಾದ ಕಟ್ ಆಫ್ ಶ್ರೇಣಿಯನ್ನು ಹೊಂದಿದ್ದೇವೆ.",
              "te": "మేము జాయింట్ ఎంట్రన్స్ ఎగ్జామ్(JEE) ఫలితాలను మాత్రమే తీసుకుంటాము. మరియు మాకు ఖచ్చితమైన కట్ ఆఫ్ ర్యాంక్ ఉంది.",
              "or": "ଆମେ କେବଳ ଯୁଗ୍ମ ପ୍ରବେଶିକା ପରୀକ୍ଷା (JEE) ଫଳାଫଳ ଗ୍ରହଣ କରୁ | ଏବଂ\n  ଆମର ଏକ କଠୋର କଟ୍ ଅଫ୍ ର୍ୟାଙ୍କ୍ ଅଛି |",
              "as": "আমি কেৱল যুটীয়া প্ৰৱেশ পৰীক্ষাৰ (JEE) ফলাফল গ্ৰহণ কৰোঁ। আৰু আমাৰ এটা কঠোৰ কাট অফ ৰেংক আছে।",
              "gu": "અમે ફક્ત સંયુક્ત પ્રવેશ પરીક્ષા (JEE) પરિણામો સ્વીકારીએ છીએ. અને અમારી પાસે ચુસ્ત કડક કટ ઓફ ગુણ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "Cut off means the minimum rank required in an exam to be eligible for admission to a college. Each college sets its cut off rank for different streams.",
              "hi": "कट ऑफ का तात्पर्य एक कॉलेज में प्रवेश योग्यता पाने के लिए एक परीक्षा में आवश्यक न्यूनतम रैंक का होना। प्रत्येक कॉलेज विभिन्न धाराओं के लिए अपने कट ऑफ रैंक निर्धारित करता है।",
              "ka": "ಕಟ್ ಆಫ್ ಎಂದರೆ ಕಾಲೇಜು ಪ್ರವೇಶಕ್ಕೆ ಅರ್ಹತೆ ಪಡೆಯಲು ಪರೀಕ್ಷೆಯಲ್ಲಿ ಅಗತ್ಯವಿರುವ ಕನಿಷ್ಠ ಶ್ರೇಣಿ. ಪ್ರತಿಯೊಂದು ಕಾಲೇಜು ಬೇರೆ ಬೇರೆ ಸ್ಟ್ರೀಮ್‌ಗಳಿಗೆ ಅವರದೆ ಆದ ಕಟ್ ಆಫ್ ಶ್ರೇಣಿಯನ್ನು ಹೊಂದಿರುತ್ತವೆ.",
              "te": "కట్ ఆఫ్ అంటే కళాశాలలో చేరడానికి సెలెక్ట్ అవ్వడానికి పరీక్షలో రావాల్సిన కనీస ర్యాంక్. ప్రతి కళాశాల వేర్వేరు స్ట్రీమ్ లకు దాని కట్ ఆఫ్ ర్యాంక్‌ను సెట్ చేస్తుంది.",
              "or": "କଟ୍ ଅଫ୍ ଅର୍ଥ ଏକ କଲେଜରେ ଆଡମିଶନ ପାଇବାକୁ ଯୋଗ୍ୟ ହେବା ପାଇଁ ଏକ \n ପରୀକ୍ଷାରେ ଆବଶ୍ୟକ ସର୍ବନିମ୍ନ ପଦବୀ | ପ୍ରତ୍ୟେକ କଲେଜ ବିଭିନ୍ନ ଷ୍ଟ୍ରିମ୍ ପାଇଁ ଏହାର\n  କଟ୍ ଅଫ୍ ର୍ୟାଙ୍କ୍ ସେଟ୍ କରେ |",
              "as": "কাট অফ ৰ অৰ্থ হৈছে এখন মহাবিদ্যালয়ত নামভৰ্তিৰ বাবে যোগ্য হ'বলৈ পৰীক্ষাত প্ৰয়োজনীয় নিম্নতম স্থান। প্ৰতিখন মহাবিদ্যালয়ে বিভিন্ন শাখাৰ বাবে ইয়াৰ কাট অফ ৰেংক নিৰ্ধাৰণ কৰে।",
              "gu": "કટ ઓફ એટલે કૉલેજમાં પ્રવેશ માટે લાયક બનવા માટે પરીક્ષામાં જરૂરી ન્યૂનતમ ગુણ. દરેક કોલેજ અલગ-અલગ સ્ટ્રીમ્સ માટે તેની કટ ઓફ ગુણ નક્કી કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "It is different for different subjects. You’ll find the details in the prospectus you’re holding. The fees are higher for popular subjects like Information Technology and Computer Science.",
              "hi": "यह विभिन्न विषयों के लिए अलग है। आपको उस प्रॉस्पेक्टस में विवरण मिल जाएगा जो आपके पास है। इनफार्मेशन टेक्नोलॉजी और कंप्यूटर साइंस जैसे लोकप्रिय विषयों के लिए शुल्क अधिक है।",
              "ka": "ಬೇರೆ ಬೇರೆ ವಿಷಯಗಳಿಗೆ ಇದು ಬೇರೆಯಾಗಿರುತ್ತದೆ. ನೀವು ತೆಗೆದುಕೊಂಡಿರುವ ಪ್ರಾಸ್ಪೆಕ್ಟಸ್‌ನಲ್ಲಿ ನೀವು ವಿವರಗಳನ್ನು ನೋಡಬಹುದು. ಮಾಹಿತಿ ತಂತ್ರಜ್ಞಾನ ಮತ್ತು ಕಂಪ್ಯೂಟರ್ ವಿಜ್ಞಾನದಂತಹ ಜನಪ್ರಿಯ ವಿಷಯಗಳಿಗೆ ಶುಲ್ಕಗಳು ಹೆಚ್ಚು ಇರುತ್ತವೆ.",
              "te": "ఇది వివిధ సబ్జెక్టులతో వేరువేరుగా ఉంటుంది. మీరు కలిగి ఉన్న ప్రాస్పెక్టస్‌లో మీరు వివరాలను కనుగొంటారు. ఇన్ఫర్మేషన్ టెక్నాలజీ మరియు కంప్యూటర్ సైన్స్ వంటి ప్రముఖ సబ్జెక్టులకు ఫీజులు ఎక్కువగా ఉంటాయి.",
              "or": "ବିଭିନ୍ନ ବିଷୟ ପାଇଁ ଏହା ଭିନ୍ନ ଅଟେ | ଆପଣ ଧାରଣ କରିଥିବା ପ୍ରୋସପେକ୍ଟସରେ\n  ଆପଣ ସବିଶେଷ ତଥ୍ୟ ପାଇବେ | ସୂଚନା ପ୍ରଯୁକ୍ତିବିଦ୍ୟା ଏବଂ କମ୍ପ୍ୟୁଟର ସାଇନ୍ସ ପରି ଲୋକପ୍ରିୟ ବିଷୟଗୁଡ଼ିକ ପାଇଁ ଦେୟ ଅଧିକ |",
              "as": "এইটো বিভিন্ন বিষয়ৰ বাবে পৃথক। আপোনাৰ হাতত থকা প্ৰস্পেক্টাচত ইয়াৰ বিৱৰণ বিচাৰি পাব। তথ্য প্ৰযুক্তি আৰু কম্পিউটাৰ বিজ্ঞানৰ দৰে জনপ্ৰিয় বিষয়ৰ বাবে মাচুল অধিক।",
              "gu": "તે વિવિધ વિષયો માટે અલગ છે. તું જે પ્રોસ્પેક્ટસ ધરાવો છો તેમાં તને વિગતો મળશે. ઈન્ફોર્મેશન ટેક્નોલોજી અને કોમ્પ્યુટર સાયન્સ જેવા લોકપ્રિય વિષયો માટે ફી વધારે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_4",
              "hi": "A3_1_4",
              "ka": "A3_1_4",
              "te": "A3_1_4",
              "or": "A3_1_4",
              "as": "A3_1_4",
              "gu": "A3_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira and Preeti meet after the fair. They are very excited to share the information from the different stalls.",
              "hi": "मीरा और प्रीति मेले के बाद मिलते हैं। वे विभिन्न स्टाल से प्राप्त जानकारी साझा करने के लिए बहुत उत्साहित हैं।",
              "ka": "ಮೇಳದ ನಂತರ ಮೀರಾ ಮತ್ತು ಪ್ರೀತಿ ಭೇಟಿಯಾಗುತ್ತಾರೆ. ಬೇರೆ ಬೇರೆ ಸ್ಟಾಲ್‌ಗಳಿಂದ ಪಡೆದ ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ಅವರು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ",
              "te": "మీరా మరియు ప్రీతి ఫెయిర్ తర్వాత కలుసుకుంటారు. వివిధ స్టాల్స్ నుండి సమాచారాన్ని తెలుసుకోవడానికి వారు చాలా ఉత్సాహంగా ఉన్నారు.",
              "or": "ମେଳା ପରେ ମୀରା ଏବଂ ପ୍ରୀତି ଭେଟନ୍ତି | ବିଭିନ୍ନ ଷ୍ଟଲରୁ ସୂଚନା ବାଣ୍ଟିବାକୁ \n  ସେମାନେ ବହୁତ ଉତ୍ସାହିତ |",
              "as": "মেলাৰ পিছত মীৰা আৰু প্ৰীতি লগ হয়। তেওঁলোকে বিভিন্ন ষ্টলৰ পৰা অনা তথ্য ভাগ বতৰা কৰিবলৈ উত্সুকতাৰে বাট চাই আছে।",
              "gu": "મીરા અને પ્રીતિ મેળા પછી મળે છે. તેઓ વિવિધ સ્ટોલ પરથી માહિતી બતાવા માટે ખૂબ જ ઉત્સાહિત છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_5",
              "hi": "A3_1_5",
              "ka": "A3_1_5",
              "te": "A3_1_5",
              "or": "A3_1_5",
              "as": "A3_1_5",
              "gu": "A3_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira takes Preeti through the information she got about engineering. Preeti then tells her about the different courses in B.Sc that are available.",
              "hi": "मीरा इंजीनियरिंग के बारे में प्राप्त जानकारी प्रीति से साझा करती है। प्रीति फिर उसे बीएससी के उपलब्ध विभिन्न कोर्स के बारे में बताती है।",
              "ka": "ಮೀರಾ ತಾನು ಇಂಜಿನಿಯರಿಂಗ್ ಪಫವಿ ಬಗ್ಗೆ ಪಡೆದ ಮಾಹಿತಿಯನ್ನು ಪ್ರೀತಿಯ ಜೊತೆ ಹಂಚಿಕೊಳ್ಳುತ್ತಾಳೆ. ಪ್ರೀತಿಯು  ನಂತರ B.Sc ನಲ್ಲಿ ಲಭ್ಯವಿರುವ ವಿವಿಧ ಕೋರ್ಸ್‌ಗಳ ಬಗ್ಗೆ ಹೇಳುತ್ತಾಳೆ.",
              "te": "ఇంజినీరింగ్ గురించి తనకు లభించిన సమాచారం ద్వారా మీరా ప్రీతిని కలుసుకుంటుంది. B.Scలో అందుబాటులో ఉన్న వివిధ కోర్సుల గురించి ప్రీతీ ఆమెకు చెప్పింది.",
              "or": "ଇଞ୍ଜିନିୟରିଂ ବିଷୟରେ ପାଇଥିବା ସୂଚନା ବିଷୟରେ ମୀରା ପ୍ରୀତିଙ୍କୁ କୁହେ। | ଏହା ପରେ \n ପ୍ରୀତି ତାଙ୍କୁ B.Sc ର ବିଭିନ୍ନ ପାଠ୍ୟକ୍ରମ ବିଷୟରେ କୁହନ୍ତି ଯାହା ଉପଲବ୍ଧ |",
              "as": "মীৰাই প্ৰীতিক অভিযান্ত্ৰিক সম্পৰ্কে পোৱা তথ্যৰ বিষয়ে জনালে। আৰু প্ৰীতিয়ে B.Sc ৰ বাবে থকা বিভিন্ন পাঠ্যক্ৰমৰ বিষয়ে ক’লে।",
              "gu": "મીરા પ્રીતિને એન્જીનિયરિંગ વિશે મળેલી માહિતી દ્વારા લે છે. ત્યારબાદ પ્રીતિ તેને B.Sc માં ઉપલબ્ધ વિવિધ અભ્યાસક્રમો વિશે જણાવે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "CollegeFair",
              "hi": "कॉलेजफेअर ",
              "ka": "ಕಾಲೇಜ್ ಮೇಳ",
              "te": "కాలేజీ ఫెయిర్",
              "or": "କଲେଜ୍ ଫେୟାର |",
              "as": "মহাবিদ্যালয় মেলা",
              "gu": "કોલેજનો મેળો"
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "ಎಮ್‌ಸಿ‌ಕ್ಯೂ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Mira’s aunt advises her to attend a college fair to get a clear idea about which exams to take to meet her career goal. Which of the following is a good reason to visit a career fair:",
              "hi": "मीरा की चाची ने उसे अपने करियर के लक्ष्य को पूरा करने हेतु परीक्षाओं के बारे में स्पष्ट जानकारी प्राप्त करने के लिए एक कॉलेज मेले में भाग लेने की सलाह दी।  करियर मेले में जाने का निम्नलिखित में से कौन सा अच्छा कारण है:",
              "ka": "ಮೀರಾಳ ಆಂಟಿ ತನ್ನ ವೃತ್ತಿಜೀವನದ ಗುರಿಯನ್ನು ತಲುಪಲು ಯಾವ ಪರೀಕ್ಷೆಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕು ಎಂಬ ಬಗ್ಗೆ ಸರಿಯಾದ ಮಾಹಿತಿ ಪಡೆಯಲು ಕಾಲೇಜು ಮೇಳಕ್ಕೆ ಹಾಜರಾಗಲು ಸಲಹೆ ನೀಡುತ್ತಾರೆ. ವೃತ್ತಿ ಮೇಳಕ್ಕೆ ಭೇಟಿ ನೀಡಲು ಕೆಳಗಿನವುಗಳಲ್ಲಿ ಯಾವುದು ಒಂದು ಉತ್ತಮ ಕಾರಣವಾಗಿದೆ:",
              "te": "మీరా అత్త తన కెరీర్ లక్ష్యాన్ని చేరుకోవడానికి ఏ పరీక్షలు రాయాలనే దాని గురించి సరైన ఆలోచన పొందడానికి కళాశాల ఫెయిర్‌కు వెళ్లాలని ఆమెకు సలహా ఇస్తుంది. కెరీర్ ఫెయిర్‌ను చూడటానికి కింది వాటిలో ఏది మంచి కారణం:",
              "or": "କ୍ୟାରିୟରର ଲକ୍ଷ୍ୟ ପୂରଣ କରିବା ପାଇଁ କେଉଁ ପରୀକ୍ଷା ଦେବେ ସେ ବିଷୟରେ ଏକ \n ସ୍ପଷ୍ଟ ଧାରଣା ପାଇବାକୁ ମୀରାଙ୍କ ମାଉସୀ ତାଙ୍କୁ ଏକ କଲେଜ ମେଳାରେ ଯୋଗଦେବାକୁ\n  ପରାମର୍ଶ ଦେଇଛନ୍ତି | କ୍ୟାରିୟର ମେଳା ପରିଦର୍ଶନ କରିବା ପାଇଁ ନିମ୍ନଲିଖିତ ମଧ୍ୟରୁ\n  କେଉଁଟି ଏକ ଉତ୍ତମ କାରଣ:",
              "as": "মীৰাৰ খুড়ীয়েকে তাইক তাইৰ কেৰিয়াৰৰ লক্ষ্য পূৰণ কৰিবলৈ কোনবোৰ পৰীক্ষা ল'ব লাগে সেই বিষয়ে স্পষ্ট ধাৰণা পাবলৈ এখন মহাবিদ্যালয় মেলাত উপস্থিত থাকিবলৈ পৰামৰ্শ দিয়ে। কেৰিয়াৰ মেলালৈ যোৱাৰ বাবে নিম্নলিখিত কোনটো এক ভাল কাৰণ:",
              "gu": "મીરાની કાકી તેને તેના કારકિર્દીના ધ્યેયને પૂર્ણ કરવા માટે કઈ પરીક્ષાઓ આપવી તે અંગે સ્પષ્ટ ખ્યાલ મેળવવા માટે તેને કોલેજના મેળામાં હાજરી આપવાની સલાહ આપે છે. કારકિર્દી મેળાની મુલાકાત લેવા માટે નીચેનામાંથી કયું સારું કારણ છે:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "To know about the various colleges and the courses offered",
              "hi": "विभिन्न कॉलेजों और प्रस्तावित कोर्स के बारे में जानने के लिए",
              "ka": "ಬೇರೆ ಬೇರೆ  ಕಾಲೇಜುಗಳು ಮತ್ತು ಅವುಗಳ ಕೋರ್ಸ್‌ಗಳ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಲು",
              "te": "వివిధ కళాశాలలు అందించే కోర్సుల గురించి తెలుసుకోవడానికి",
              "or": "ବିଭିନ୍ନ କଲେଜ ଏବଂ ଦିଆଯାଇଥିବା ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ଜାଣିବା |",
              "as": "বিভিন্ন মহাবিদ্যালয়ৰ বিষয়ে আৰু সেইসমূহে আগবঢ়োৱা পাঠ্যক্ৰমবোৰৰ বিষয়ে জানিবলৈ",
              "gu": "વિવિધ કોલેજો અને તક મળેલો અભ્યાસક્રમો વિશે જાણવા માટે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "To understand the tasks done in a particular job role & industry",
              "hi": "किसी विशेष पद पर नौकरी और इंडस्ट्री में किए गए कार्यों को समझने के लिए",
              "ka": "ನಿರ್ದಿಷ್ಟ ಕೆಲಸದ ಕರ್ತವ್ಯದಲ್ಲಿ ಮಾಡಬೇಕಾದ  ಕಾರ್ಯಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು",
              "te": "ఒక మంచి ఉద్యోగం గురించి & పరిశ్రమలో చేసిన పనులను అర్థం చేసుకోవడానికి",
              "or": "ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ଚାକିରି ଭୂମିକା ଏବଂ ଶିଳ୍ପରେ କରାଯାଇଥିବା କାର୍ଯ୍ୟଗୁଡ଼ିକୁ ବୁଝିବା |",
              "as": "এক নিৰ্দিষ্ট চাকৰিৰ ভূমিকা আৰু উদ্যোগত কৰা কামবোৰ বুজিবলৈ",
              "gu": "ચોક્કસ નોકરીની ભૂમિકા અને ઉદ્યોગમાં કરેલા કાર્યોને સમજવા માટે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "To mostly get to know future prospects about a particular course",
              "hi": "अधिकांशतः किसी विशेष पाठ्यक्रम के बारे में भविष्य की संभावनाओं को जानने के लिए",
              "ka": "ನಿರ್ದಿಷ್ಟ ಕೋರ್ಸ್ ಬಗ್ಗೆ ಇರುವ ಭವಿಷ್ಯವನ್ನು ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು",
              "te": "భవిష్యత్తులో వచ్చే మంచి ఉద్యోగ అవకాశాల గురించి తెలుసుకోవడం",
              "or": "ପ୍ରାୟତ ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ପାଠ୍ୟକ୍ରମର ଭବିଷ୍ୟତ ବିଷୟରେ ଜାଣିବା |",
              "as": "বিশেষকৈ এটা নিৰ্দিষ্ট পাঠ্যক্ৰমৰ বিষয়ে ভৱিষ্যতৰ সম্ভাৱনাবোৰ জানিবলৈ",
              "gu": "મોટે ભાગે કોઈ ચોક્કસ અભ્યાસક્રમ વિશે ભવિષ્યની સંભાવનાઓ જાણવા માટે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "To find a mentor who is an expert in the field of your choice",
              "hi": "आपकी पसंद के क्षेत्र में एक सलाहकार खोजने के लिए जो  एक विशेषज्ञ है",
              "ka": "ನಿಮ್ಮ ಆಯ್ಕೆಯ ಕ್ಷೇತ್ರದಲ್ಲಿ ಪರಿಣಿತರಾಗಿರುವ ಮಾರ್ಗದರ್ಶಕರನ್ನು ಹುಡುಕಿಕೊಳ್ಳಲು",
              "te": "మీకు నచ్చిన రంగంలో ప్రతిభ కలిగిన మెంటర్‌ని వెతకడం కోసం",
              "or": "ତୁମର ପସନ୍ଦ କ୍ଷେତ୍ରରେ ଜଣେ ବିଶେଷଜ୍ଞ ଥିବା ଜଣେ ମେଣ୍ଟର ଖୋଜିବା ପାଇଁ |",
              "as": "আপোনাৰ পচন্দৰ ক্ষেত্ৰত এজন বিশেষজ্ঞ পৰামৰ্শদাতা বিচাৰি উলিওৱা",
              "gu": "તમારી પસંદગીના ક્ષેત્રમાં નિષ્ણાત એવા માર્ગદર્શકને શોધવા માટે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ବହୁତ ଭଲ କାମ!",
              "as": "ভাল চাকৰি!",
              "gu": "સરસ કામ!"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Think again! For in-depth information talk to professionals from the industry",
              "hi": "फिर से विचार करो! गहन जानकारी के लिए इंडस्ट्री के प्रोफेशनल से बात करो",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಆಳವಾದ ಮಾಹಿತಿಗಾಗಿ ಉದ್ಯಮದ ವೃತ್ತಿಪರರೊಂದಿಗೆ ಮಾತನಾಡಿ",
              "te": "మళ్ళీ ఆలోచించు! ఎక్కువ సమాచారం కోసం పరిశ్రమలోని నిపుణులతో మాట్లాడండి",
              "or": "ପୁଣି ଥରେ ଭାବ! ଶିଳ୍ପରୁ ବୃତ୍ତିଗତମାନଙ୍କ ସହିତ ଗଭୀର ସୂଚନା ପାଇଁ କଥାବାର୍ତ୍ତା |",
              "as": "পুণৰ চিন্তা কৰক! গভীৰ তথ্যৰ বাবে উদ্যোগটোৰ পেছাদাৰীসকলৰ সৈতে কথা পাতক",
              "gu": "ફરીથી વિચારો! ઊંડાણપૂર્વકની માહિતી માટે ઉદ્યોગના વ્યાવસાયિકો સાથે વાત કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Think again! For in-depth information talk to course alumni & college officials",
              "hi": "फिर से विचार करो! गहन जानकारी के लिए कोर्स के पूर्व छात्रों और कॉलेज के अधिकारियों से बात करो",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸು! ಆಳವಾದ ಮಾಹಿತಿಗಾಗಿ  ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳೊಂದಿಗೆ ಹಾಗೂ ಕಾಲೇಜಿನ ಅಧಿಕಾರಿಗಳೊಂದಿಗ  ಮಾತನಾಡಿ",
              "te": "మళ్ళీ ఆలోచించు! ఎక్కువ సమాచారం కోసం కోర్సు పూర్వ విద్యార్థులు & కళాశాల అధికారులతో మాట్లాడండి",
              "or": "ପୁଣି ଥରେ ଭାବ! ପାଠ୍ୟକ୍ରମର ଆଲୁମିନି ଏବଂ କଲେଜ ଅଧିକାରୀଙ୍କ ସହିତ ଗଭୀର\n  ସୂଚନା ପାଇଁ କଥାବାର୍ତ୍ତା |",
              "as": "পুণৰ চিন্তা কৰক! গভীৰ তথ্যৰ বাবে সেই পাঠ্যক্ৰমৰ প্ৰাক্তন ছাত্ৰ আৰু মহাবিদ্যালয়ৰ বিষয়াসকলৰ সৈতে কথা পাতক",
              "gu": "ફરીથી વિચારો! ઊંડાણપૂર્વકની માહિતી માટે અભ્યાસક્રમના ભૂતપૂર્વ વિદ્યાર્થીઓ અને કૉલેજ અધિકારીઓ સાથે વાત કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Think again! At a college fair, we can find information about courses & colleges",
              "hi": "फिर से विचार करो! एक कॉलेज मेले में, हमें कोर्स और कॉलेज के बारे में जानकारी मिल सकती हैं",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಕಾಲೇಜು ಮೇಳದಲ್ಲಿ, ನಮಗೆ ಕೋರ್ಸ್‌ಗಳ ಬಗ್ಗೆ ಮಾಹಿತಿಯು ಸಿಗುತ್ತದೆ.",
              "te": "మళ్ళీ ఆలోచించు! కళాశాల ఫెయిర్‌లో మనం కోర్సులు & కళాశాలల గురించిన సమాచారాన్ని తెలుసుకోవచ్చు.",
              "or": "ପୁଣି ଥରେ ଭାବ! ଏକ କଲେଜ ମେଳାରେ, ଆମେ ପାଠ୍ୟକ୍ରମ ଏବଂ କଲେଜଗୁଡ଼ିକ\n  ବିଷୟରେ ସୂଚନା ପାଇପାରିବା |",
              "as": "পুণৰ চিন্তা কৰক! এখন মহাবিদ্যালয় মেলাত, আমি পাঠ্যক্ৰম আৰু মহাবিদ্যালয়ৰ বিষয়ে তথ্য বিচাৰি পাম",
              "gu": "ફરીથી વિચારો! કૉલેજ મેળામાં, અમે અભ્યાસક્રમો અને કૉલેજ વિશે માહિતી મેળવી શકીએ છીએ"
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_6////",
              "hi": "A3_1_6////",
              "ka": "A3_1_6////",
              "te": "A3_1_6////",
              "or": "A3_1_6////",
              "as": "A3_1_6////",
              "gu": "A3_1_6////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I never knew that there are so many options other than Engineering.",
              "hi": "मैं कभी नहीं जानती थी कि इंजीनियरिंग के अलावा भी अनेक विकल्प हैं।",
              "ka": "ಇಂಜಿನಿಯರಿಂಗ್ ಬಿಟ್ಟರೆ ಇಷ್ಟೊಂದು ಆಯ್ಕೆಗಳಿವೆ ಎನ್ನುವುದು ನನಗೆ ತಿಳಿದಿರಲಿಲ್ಲ",
              "te": "ఇంజినీరింగ్ కాకుండా చాలా ఆప్షన్స్ ఉన్నాయని నాకు ఎప్పుడూ తెలియదు.",
              "or": "ମୁଁ କଦାପି ଜାଣି ନଥିଲି ଯେ ଇଞ୍ଜିନିୟରିଂ ବ୍ୟତୀତ ଅନେକ ବିକଳ୍ପ ଅଛି |",
              "as": "অভিযান্ত্ৰিকৰ বাহিৰে আন বহুতো বিকল্প আছে বুলি মই কেতিয়াও জনা নাছিলোঁ।",
              "gu": "મને ક્યારેય ખબર ન હતી કે એન્જિનિયરિંગ સિવાયના ઘણા બધા વિકલ્પો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, but I think I want to do a core engineering subject like mechanical engineering. The idea of working with machines sounds exciting.",
              "hi": "हां, लेकिन मुझे लगता है कि मैं मैकेनिकल इंजीनियरिंग जैसे कोर इंजीनियरिंग विषय करना चाहती हूं। मशीनों के साथ काम करने का विचार रोमांचक लगता है।",
              "ka": "ಹೌದು, ಆದರೆ ಮೆಕ್ಯಾನಿಕಲ್ ಎಂಜಿನಿಯರಿಂಗ್‌ನಂತಹ ಕೋರ್ ಎಂಜಿನಿಯರಿಂಗ್ ವಿಷಯವನ್ನು ಮಾಡಲು ಬಯಸುತ್ತೇನೆ ಎಂದು ನನ್ನ ಅನಿಸಿಕೆ. ಯಂತ್ರಗಳೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುವ ಕಲ್ಪನೆಯು ಉತ್ತೇಜಕವೆನಿಸುತ್ತದೆ.",
              "te": "అవును, కానీ నేను మెకానికల్ ఇంజనీరింగ్ వంటి కోర్ ఇంజనీరింగ్ సబ్జెక్ట్ చేయాలనుకుంటున్నాను. యంత్రాలతో పని చేయాడం ఆసక్తిగా ఉంటుంది.",
              "or": "ହଁ, କିନ୍ତୁ ମୁଁ ଭାବୁଛି ମୁଁ ମେକାନିକାଲ୍ ଇଞ୍ଜିନିୟରିଂ ପରି ଏକ ମୂଳ ଇଞ୍ଜିନିୟରିଂ ବିଷୟ\n  କରିବାକୁ ଚାହୁଁଛି | ମେସିନ୍ ସହିତ କାମ କରିବାର କଳ୍ପନା ରୋମାଞ୍ଚକର ଲାଗେ |",
              "as": "হয়, কিন্তু মই ভাবো মই মেকানিকেল ইঞ্জিনিয়াৰিঙৰ দৰে এটা মূল অভিযান্ত্ৰিক বিষয় কৰিব বিচাৰোঁ। যন্ত্ৰৰ সৈতে কাম কৰাৰ ধাৰণাটো ৰোমাঞ্চকৰ যেন লাগিছে।",
              "gu": "હા, પણ મને લાગે છે કે મારે મિકેનિકલ એન્જિનિયરિંગ જેવો કોર એન્જિનિયરિંગ વિષય કરવો છે. મશીનો સાથે કામ કરવાનો વિચાર રોમાંચક લાગે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Same! I’m excited to study engineering too.",
              "hi": "वही! मैं भी इंजीनियरिंग का अध्ययन करने के लिए  उत्साहित हूं।",
              "ka": "ಅದೇ! ನನಗೂ ಕೂಡ ಇಂಜಿನಿಯರಿಂಗ್ ಓದುವ ಉತ್ಸುಕತೆ ಇದೆ.",
              "te": "అదే! నేను కూడా ఇంజినీరింగ్ చదవడానికి ఉత్సాహంగా ఉన్నాను.",
              "or": "ସମାନ! ମୁଁ ମଧ୍ୟ ଇଞ୍ଜିନିୟରିଂ ପଢିବାକୁ ଉତ୍ସାହିତ |",
              "as": "একেই! ময়ো অভিযান্ত্ৰিক পঢ়িবলৈ উৎসাহিত।",
              "gu": "સમાન! હું એન્જિનિયરિંગનો અભ્યાસ કરવા માટે પણ ઉત્સાહિત છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "That’s great, Preeti! So now, what is the plan?",
              "hi": "यह बहुत अच्छा है, प्रीति! तो अब, योजना क्या है?",
              "ka": "ಅದು ಅದ್ಭುತ, ಪ್ರೀತಿ! ಹಾಗಾದರೆ ಈಗ, ಮುಂದಿನ ಯೋಜನೆ ಏನು?",
              "te": "చాలా బాగుంది, ప్రీతీ! ఇప్పుడు, ప్లాన్ ఏమిటి?",
              "or": "ତାହା ବହୁତ ଭଲ, ପ୍ରୀତି! ତେବେ ବର୍ତ୍ତମାନ ଯୋଜନା କ’ଣ?",
              "as": "এইটো ভাল হ'ব। তেন্তে প্ৰীতি, এতিয়া কি কৰা যায়?",
              "gu": "તે મહાન છે, પ્રીતિ! તો હવે, શું યોજના છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "I think I will start approaching coaching centres. I want to get the best material to prepare for the entrance exams.",
              "hi": "मैं सोचती हूँ कि मैं कोचिंग सेंटर देखना शुरू कर दूं। मैं प्रवेश परीक्षा की तैयारी करने के लिए सबसे अच्छी सामग्री प्राप्त करना चाहती हूं।",
              "ka": "ನಾನು ಕೋಚಿಂಗ್ ಸೆಂಟರ್‌ಗಳಿಗೆ ಭೇಟಿ ನೀಡಲು ಪ್ರಾರಂಭಿಸುತ್ತೇನೆ ಎಂದು ನನ್ನ ಅನಿಸಿಕೆ. ಪ್ರವೇಶ ಪರೀಕ್ಷೆಗಳಿಗೆ ತಯಾರಾಗಲು ಉತ್ತಮವಾದ ಮಾಹಿತಿ ಹಾಗೂ ವಿಷಯಗಳನ್ನು ಪಡೆಯುವುದು ನನ್ನ ಬಯಕೆ.",
              "te": "నేను కోచింగ్ సెంటర్లను సంప్రదించడం ప్రారంభిస్తాను. నేను ప్రవేశ పరీక్షలకు సిద్ధం కావడానికి మంచి మెటీరియల్‌ని పొందాలనుకుంటున్నాను.",
              "or": "ମୁଁ ଭାବୁଛି ମୁଁ କୋଚିଂ ସେଣ୍ଟର ନିକଟକୁ ଯିବା ଆରମ୍ଭ କରିବି | ପ୍ରବେଶିକା ପରୀକ୍ଷା\n  ପାଇଁ ପ୍ରସ୍ତୁତ ହେବାକୁ ମୁଁ ସର୍ବୋତ୍ତମ ସାମଗ୍ରୀ ପାଇବାକୁ ଚାହୁଁଛି |",
              "as": "মই ভাবো মই প্ৰশিক্ষণ কেন্দ্ৰৰ কাষ চপাটোৱেই ভাল হ'ব। মই প্ৰৱেশ পৰীক্ষাৰ বাবে প্ৰস্তুতি কৰিবলৈ একেবাৰে ভাল বস্তুবোৰ পাব বিচাৰোঁ।",
              "gu": "મને લાગે છે કે હું કોચિંગ સેન્ટરોનો સંપર્ક કરવાનું શરૂ કરીશ. હું પ્રવેશ પરીક્ષાઓની તૈયારી માટે શ્રેષ્ઠ સામગ્રી મેળવવા માંગુ છું."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_3_1",
              "hi": "A3_3_1",
              "ka": "A3_3_1",
              "te": "A3_3_1",
              "or": "A3_3_1",
              "as": "A3_3_1",
              "gu": "A3_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira feels a little disheartened. She knows her parents won’t be able to afford coaching classes for her. Preeti supports Mira with the entrance preparation by sharing the timetable and other study material.",
              "hi": "मीरा को थोड़ी निराशा लगती है। वह जानती है कि उसके माता-पिता उसकी कोचिंग क्लासेस का शुल्क नहीं दे पाएंगे। प्रीति प्रवेश की तैयारी के लिए समय सारिणी और अन्य अध्ययन सामग्री साझा करके मीरा की सहायता करती है।",
              "ka": "ಮೀರಾ ಸ್ವಲ್ಪ ನಿರಾಶೆಗೊಳ್ಳುತ್ತಾಳೆ. ಅವಳಿಗೆ ಕೋಚಿಂಗ್ ತರಗತಿಗಳಿಗೆ ಕಳಿಸಲು ತನ್ನ ಹೆತ್ತವರಿಗೆ ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ ಅಂತ ಅವಳಿಗೆ ಗೊತ್ತಿದೆ. ಪ್ರೀತಿ ತಾನು ವೇಳಾಪಟ್ಟಿ ಮತ್ತು ಇತರ ಅಧ್ಯಯನ ಸಾಮಗ್ರಿಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುವ ಮೂಲಕ ಪ್ರವೇಶ ತಯಾರಿಯನ್ನು ಮಾಡಿಕೊಳ್ಳಲು ಮೀರಾಗೆ ಬೆಂಬಲ ನೀಡ್ತೀನಿ ಅಂತ ಹೇಳ್ತಾಳೆ.",
              "te": "మీరా కొంచెం నిరుత్సాహంగా ఉంది. తన తల్లిదండ్రులు తనకు కోచింగ్ క్లాసులుకి డబ్బులు ఇవ్వలేరని ఆమెకు తెలుసు. ప్రీతి టైమ్‌టేబుల్ మరియు ఇతర స్టడీ మెటీరియల్‌ని మీరాకు ఇచ్చి ఎంట్రన్స్ ప్రిపరేషన్‌లో సపోర్ట్ చేస్తుంది.",
              "or": "ମୀରା ଟିକେ ହତାଶ ଅନୁଭବ କରନ୍ତି | ସେ ଜାଣନ୍ତି ଯେ ତାଙ୍କ ପିତାମାତା ତାଙ୍କ ପାଇଁ\n  କୋଚିଂ କ୍ଲାସ୍ ଦେବାକୁ ସକ୍ଷମ ହେବେ ନାହିଁ | ପ୍ରୀତି ସମୟସୀମା ଏବଂ ଅନ୍ୟାନ୍ୟ \n ଅଧ୍ୟୟନ ସାମଗ୍ରୀ ଅଂଶୀଦାର କରି ପ୍ରବେଶ ପ୍ରସ୍ତୁତି ସହିତ ମୀରାକୁ ସମର୍ଥନ କରେ |",
              "as": "মীৰাই অলপ হতাশ অনুভৱ কৰে। তাই জানে যে তাইৰ মাক দেউতাকে তাইৰ বাবে প্ৰশিক্ষণৰ খৰছ বহন কৰিব নোৱাৰিব। প্ৰীতিয়ে সময়সূচী আৰু অন্যান্য অধ্যয়ন সামগ্ৰী ভাগ বতৰা কৰি মীৰাক প্ৰৱেশ প্ৰস্তুতিৰ সৈতে সমৰ্থন কৰে।",
              "gu": "મીરા થોડી નિરાશ લાગે છે. તે જાણે છે કે તેના માતાપિતા તેના માટે કોચિંગ વર્ગો પરવડી શકશે નહીં. પ્રીતિ સમયપત્રક અને અન્ય અભ્યાસ સામગ્રી કહીને પ્રવેશની તૈયારીમાં મીરાને ટેકો આપે છે."
            }
          }
        ]
      },
      "scene9": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4_1_1",
              "hi": "A4_1_1",
              "ka": "A4_1_1",
              "te": "A4_1_1",
              "or": "A4_1_1",
              "as": "A4_1_1",
              "gu": "A4_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira soon starts feeling the pressure of managing school and entrance preparation together. Her scores in her school exams start decreasing while her classmates are doing well.",
              "hi": "मीरा जल्द ही स्कूल और प्रवेश परीक्षा की तैयारी एक साथ करने का दबाव अनुभव करती है। स्कूल परीक्षा में उसके स्कोर में कमी आई है, जबकि उसके सहपाठी अच्छा प्रदर्शन कर रहे हैं।",
              "ka": "ಮೀರಾ ಶೀಘ್ರದಲ್ಲೇ ಶಾಲೆ ಮತ್ತು ಪ್ರವೇಶ ತಯಾರಿಯನ್ನು ಒಟ್ಟಿಗೆ  ನಿರ್ವಹಿಸುವ ಒತ್ತಡ ಅನುಭವಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ. ಆಕೆಯ ಸಹಪಾಠಿಗಳು ಉತ್ತಮವಾಗಿ ಮಾಡುತ್ತಿರುವಾಗ ಶಾಲಾ ಪರೀಕ್ಷೆಗಳಲ್ಲಿ ಆಕೆಯ ಅಂಕಗಳು ಕಡಿಮೆ ಆಗಲು ಪ್ರಾರಂಭ ಆಗುತ್ತದೆ.",
              "te": "మీరా పాఠశాల మరియు ప్రవేశ పరీక్ష కోసం ప్రిపేర్ అవుతున్నప్పుడు ఒత్తిడిగా భావిస్తుంది. ఆమె క్లాస్‌మేట్స్ బాగా చదువుతున్న సమయంలో పాఠశాల పరీక్షలలో తన మార్కులు తగ్గుతూ వస్తాయి.",
              "or": "ମୀରା ଶୀଘ୍ର ବିଦ୍ୟାଳୟ ପରିଚାଳନା ଏବଂ ପ୍ରବେଶ ପ୍ରସ୍ତୁତିର ଚାପ ଅନୁଭବ କରିବା\n  ଆରମ୍ଭ କରେ | ତାଙ୍କ ସହପାଠୀମାନେ ଭଲ କାମ କରୁଥିବାବେଳେ ତାଙ୍କ ସ୍କୁଲ \n ପରୀକ୍ଷାରେ ତାଙ୍କର ସ୍କୋର କମିବା ଆରମ୍ଭ କରେ |",
              "as": "স্কুল আৰু প্ৰৱেশ প্ৰস্তুতি এই দুয়োটা একেলগে আগবঢ়াই নিওঁতে মীৰাৰ ওপৰত অধিক মানসিক চাপ আহি পৰে। ফলত স্কুলীয়া পৰীক্ষাত তাই আন সহপাঠীসকলতকৈ কম নম্বৰ পাবলৈ ধৰে।",
              "gu": "મીરા ટૂંક સમયમાં શાળાનું સંચાલન અને પ્રવેશની તૈયારી એકસાથે કરવાનું દબાણ અનુભવવા લાગે છે. તેની શાળાની પરીક્ષાઓમાં તેના ગુણ ઘટવા માંડે છે જ્યારે તેના ક્લાસમેટ્સ  સારું પ્રદર્શન કરી રહ્યા છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4_1_2",
              "hi": "A4_1_2",
              "ka": "A4_1_2",
              "te": "A4_1_2",
              "or": "A4_1_2",
              "as": "A4_1_2",
              "gu": "A4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "One day, Ranjan uncle sees Mira crying in her room. He feels concerned and asks what is bothering her.",
              "hi": "एक दिन, रंजन चाचा मीरा को अपने कमरे में रोते हुए देखते हैं। वह चिंतित होते हैं और पूछते हैं कि उसे क्या परेशान कर रहा है।",
              "ka": "ಒಂದು ದಿನ, ರಂಜನ್ ಅಂಕಲ್, ಮೀರಾ ತನ್ನ ಕೋಣೆಯಲ್ಲಿ ಅಳುವುದನ್ನು ನೋಡುತ್ತಾರೆ. ಅವರು ಕಳಕಳಿ ಹೊಂದುತ್ತಾರೆ ಹಾಗೂ ಅವಳಿಗೆ  ಏನು ತೊಂದರೆ ಇದೆ ಅಂತ ಕೇಳುತ್ತಾರೆ.",
              "te": "ఒకరోజు, రన్జన్ మామయ్య మీరా తన గదిలో ఏడుస్తూ ఉండటం చూస్తాడు. అతను ఆందోళన చెందుతాడు మరియు ఆమె ఎందుకు బాధపడుతున్నదో అడుగుతాడు.",
              "or": "ଦିନେ, ରଂଜନ ମାମୁଁ ମୀରାଙ୍କୁ ତାଙ୍କ କୋଠରୀରେ କାନ୍ଦୁଥିବା ଦେଖିଲେ | ସେ ଚିନ୍ତିତ\n  ଅନୁଭବ କରନ୍ତି ଏବଂ ତାଙ୍କୁ କ’ଣ କଷ୍ଟ ଦେଉଛି ବୋଲି ପଚାରନ୍ତି |",
              "as": "এদিন ৰঞ্জন খুৰাকে মীৰাক তাইৰ কোঠাত কান্দি থকা দেখিলে। তেওঁ চিন্তিত হৈ ইয়াৰ কাৰণ কি তাইক সুধিলে।",
              "gu": "એક દિવસ રંજન કાકા મીરાને તેના રૂમમાં રડતી જુએ છે. તે ચિંતિત લાગે છે અને પૂછે છે કે તેને શું નડતર  લાગે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4_1_3//",
              "hi": "A4_1_3//",
              "ka": "A4_1_3//",
              "te": "A4_1_3//",
              "or": "A4_1_3//",
              "as": "A4_1_3//",
              "gu": "A4_1_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Ranjan",
              "hi": "रंजन",
              "ka": "ರಂಜನ್",
              "te": "రన్జన్",
              "or": "ରଞ୍ଜନ",
              "as": "ৰঞ্জন",
              "gu": "રંજન"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "How will I prepare for the entrance exams, Uncle? I have heard that lakhs and lakhs of students sit every year. How will I compete with them on my own?",
              "hi": "चाचा, मैं प्रवेश परीक्षा के लिए कैसे तैयारी करूंगी? मैंने सुना है कि लाखों छात्र हर वर्ष परीक्षा देते हैं। मैं अपने आपसे उनके साथ कैसे प्रतिस्पर्धा करूंगी?",
              "ka": "ನಾನು ಪ್ರವೇಶ ಪರೀಕ್ಷೆಗೆ ಹೇಗೆ ತಯಾರಿ ನಡೆಸಲಿ ಅಂಕಲ್? ಪ್ರತಿ ವರ್ಷ ಲಕ್ಷ ಲಕ್ಷ ವಿದ್ಯಾರ್ಥಿಗಳು ಕುಳಿತುಕೊಳ್ಳುತ್ತಾರೆ ಅಂತ ಕೇಳಿದ್ದೇನೆ. ನಾನು ಅವರೊಂದಿಗೆ ಹೇಗೆ ಸ್ಪರ್ಧೆ ಮಾಡ್ತೇನೆ?",
              "te": "నేను ప్రవేశ పరీక్షలకు ఎలా ప్రిపేర్ అవుతాను అంకుల్? ఏటా లక్షలాది మంది విద్యార్థులు కూర్చుంటారని విన్నాను. నేను నా స్వంతంగా వారితో ఎలా పోటీ పడగలను?",
              "or": "ମାମୁଁ, ପ୍ରବେଶିକା ପରୀକ୍ଷା ପାଇଁ ମୁଁ କିପରି ପ୍ରସ୍ତୁତ ହେବି? ମୁଁ ଶୁଣିଛି ଯେ ପ୍ରତିବର୍ଷ\n  ଲକ୍ଷ ଲକ୍ଷ ଛାତ୍ର ବସିଥାନ୍ତି | ମୁଁ ନିଜେ ସେମାନଙ୍କ ସହିତ କିପରି ପ୍ରତିଦ୍ୱନ୍ଦ୍ୱିତା କରିବି?",
              "as": "খুৰা, মই প্ৰৱেশ পৰীক্ষাৰ বাবে কেনেকৈ প্ৰস্তুতি কৰোঁ? প্ৰতি বছৰে হেনো লাখ লাখ শিক্ষাৰ্থী পৰীক্ষাত বহে। মই তেওঁলোকৰ সৈতে নিজে কেনেকৈ প্ৰতিযোগিতা কৰিম?",
              "gu": "હું પ્રવેશ પરીક્ષાની તૈયારી કેવી રીતે કરીશ, કાકા? મેં સાંભળ્યું છે કે દર વર્ષે લાખો વિદ્યાર્થીઓ બેસે છે. હું મારી જાતે તેમની સાથે કેવી રીતે સ્પર્ધા કરીશ?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Mira, I know it is not easy. But you are a smart girl. If you try your best, you will clear the entrance exam.",
              "hi": "मीरा, मुझे पता है कि यह सरल नहीं है। लेकिन तुम एक बुद्धिमान लड़की हो। यदि तुम अपना सर्वश्रेष्ठ प्रयास करती हो, तो  प्रवेश परीक्षा उत्तीर्ण कर लोगी।",
              "ka": "ಮೀರಾ, ಇದು ಸುಲಭವಲ್ಲ ಅಂತ ನನಗೂ ತಿಳಿದಿದೆ. ಆದರೆ ನೀನು ಬುದ್ಧಿವಂತ ಹುಡುಗಿ. ನೀನು ನಿನ್ನ ಕೈಲಾದಷ್ಟು ಪ್ರಯತ್ನ ಮಾಡಿದರೆ, ನೀನು ಪ್ರವೇಶ ಪರೀಕ್ಷೆಯನ್ನು ದಾಟಬಹುದು.",
              "te": "మీరా, ఇది అంత సులభం కాదని నాకు తెలుసు. కానీ నువ్వు తెలివైన అమ్మాయి. నువ్వురు నీ వంతు ప్రయత్నం చేస్తే ప్రవేశ పరీక్షలో క్లియర్ అవుతావు.",
              "or": "ମୀରା, ମୁଁ ଜାଣେ ଏହା ସହଜ ନୁହେଁ | କିନ୍ତୁ ତୁମେ ଜଣେ ସ୍ମାର୍ଟ ଝିଅ | ଯଦି ତୁମେ\n  ଯଥାସମ୍ଭବ ଚେଷ୍ଟା କର, ତୁମେ ପ୍ରବେଶିକା ପରୀକ୍ଷା ପାସ କରିବ |",
              "as": "মীৰা, এইটোযে সহজ কথা নহয়, সেইটো ময়ো জানো। কিন্তু তুমিটো এজনী স্মাৰ্ট ছোৱালী। যদি তুমি তোমাৰ সৰ্বশ্ৰেষ্ঠ চেষ্টা কৰা, প্ৰৱেশ পৰীক্ষাত নিশ্চয় উত্তীৰ্ণ হ'বা।",
              "gu": "મીરા, હું જાણું છું કે તે સરળ નથી. પણ તું હોશિયાર છોકરી છે. જો તું તારો શ્રેષ્ઠ પ્રયાસ કરશે, તો તું પ્રવેશ પરીક્ષા પાસ કરી શકીશ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "But uncle, all my friends go for coaching classes. They have been solving hundreds of papers in a month, and here I'm barely able to get through even one practice paper.",
              "hi": "लेकिन चाचा, मेरे सभी मित्र कोचिंग क्लासेस के लिए जाती हैं। वे एक महीने में सैकड़ों प्रश्नपत्र हल कर रही हैं, और यहां  मैं मुश्किल से  एक अभ्यास पत्र कर पा रही हूं।",
              "ka": "ಆದರೆ ಅಂಕಲ್, ನನ್ನ ಸ್ನೇಹಿತರೆಲ್ಲ ಕೋಚಿಂಗ್ ಕ್ಲಾಸ್‌ಗಳಿಗೆ ಹೋಗ್ತಾ ಇದ್ದಾರೆ. ಅವರು ಒಂದು ತಿಂಗಳಲ್ಲಿ ನೂರಾರು ಪೇಪರ್‌ಗಳನ್ನು ಸಾಲ್ವ್ ಮಾಡ್ತಾ ಇದ್ದಾರೆ ಮತ್ತು ಇಲ್ಲಿ ನಾನು ಕೇವಲ ಒಂದು ಅಭ್ಯಾಸ ಪತ್ರಿಕೆಯನ್ನು ಕೂಡಾ ಮಾಡಲು ಸಾಧ್ಯ ಆಗ್ತಾ ಇಲ್ಲ.",
              "te": "కానీ మామయ్య, నా స్నేహితులందరూ కోచింగ్ క్లాసులకు వెళతారు. వారు ఒక నెలలో వందలాది పేపర్‌లను సాల్వ్ చేస్తున్నారు మరియు ఇక్కడ నేను ఒక్క ప్రాక్టీస్ పేపర్‌ను కూడా పొందలేకపోయాను.",
              "or": "କିନ୍ତୁ ମାମୁଁ, ମୋର ସମସ୍ତ ସାଙ୍ଗ କୋଚିଂ କ୍ଲାସ୍ ପାଇଁ ଯାଆନ୍ତି | ସେମାନେ ଏକ \n ମାସରେ ଶହ ଶହ କାଗଜପତ୍ର ସମାଧାନ କରୁଛନ୍ତି, ଏବଂ ଏଠାରେ ମୁଁ କେବଳ\n  ଗୋଟିଏ ଅଭ୍ୟାସ କାଗଜ ମଧ୍ୟ ଦେଇ ପାରିବି ନାହିଁ |",
              "as": "কিন্তু খুৰা, মোৰ সকলো বন্ধু প্ৰশিক্ষণ শ্ৰেণীলৈ যায়। তেওঁলোকে এমাহত শ শ প্ৰশ্নকাকত সমাধান কৰি আহিছে, আৰু মই এতিয়ালৈকে এটাহে অনুশীলনী কৰিবগৈ পৰা হৈছোঁ।",
              "gu": "પણ કાકા, મારા બધા મિત્રો ભણવા માટે વર્ગોમાં જાય છે. તેઓ એક મહિનામાં100 પેપર હલ કરી રહ્યાં છે, અને અહીં હું ભાગ્યે જ એક પ્રેક્ટિસ પેપર પણ હલ કરી શકું છું."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4_1_4////",
              "hi": "A4_1_4////",
              "ka": "A4_1_4////",
              "te": "A4_1_4////",
              "or": "A4_1_4////",
              "as": "A4_1_4////",
              "gu": "A4_1_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Don't feel threatened by these coaching classes Mira, Your interest and determination are essential.",
              "hi": "इन कोचिंग क्लासेस से डरने की आवश्यकता नहीं  मीरा, तुम्हारी रुचि और दृढ़ संकल्प आवश्यक है।",
              "ka": "ಈ ಕೋಚಿಂಗ್ ಕ್ಲಾಸ್‌ಗಳಿಂದ ಹೆದರ್ಕೊಬೇಡ ಮೀರಾ, ನಿನ್ನ ಆಸಕ್ತಿ ಮತ್ತು ನಿರ್ಣಯ ಅತ್ಯಗತ್ಯ ಆಗತ್ತೆ.",
              "te": "ఈ కోచింగ్ క్లాసుల వల్ల బెదిరింపులకు గురికావద్దు మీరా, నీ ఆసక్తి మరియు సంకల్పం చాలా అవసరం.",
              "or": "ମୀରା ଏହି କୋଚିଂ କ୍ଲାସ ପାଇଁ ବିପଦ ଅନୁଭବ କର ନାହିଁ, ଏହା ପାଇଁ ତୁମର ଆଗ୍ରହ\n  ଏବଂ ନିଷ୍ଠା ଜରୁରୀ |",
              "as": "এই প্ৰশিক্ষণ শ্ৰেণীবোৰৰ ভাৱুকিক প্ৰশ্ৰয় নিদিবা মীৰা, এইক্ষেত্ৰত তোমাৰ আগ্ৰহ আৰু সংকল্প খুৱেই আৱশ্যকীয়।",
              "gu": "આ કોચિંગ વર્ગોથી ડરીશ  નહીં મીરા, તારી રુચિ અને સંકલ્પ જરૂરી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I struggle to do this alone, Ranjan uncle. It's too much.",
              "hi": "मैं  ऐसा करने के लिए अकेले संघर्ष करती हूं, रंजन चाचा। यह बहुत ज्यादा है।",
              "ka": "ನಾನು ಇದನ್ನು ಒಬ್ಬಂಟಿಯಾಗಿ ಮಾಡಲು ಕಷ್ಟಪಡ್ತಾ ಇದ್ದೀನಿ ರಂಜನ್ ಅಂಕಲ್. ಇದು ನನಗೆ ತುಂಬಾ ಕಷ್ಟ ಅನ್ನಿಸ್ತಾ ಇದೆ.",
              "te": "రన్ జన్ అంకుల్, నేను ఒంటరిగా దీన్ని చేయడానికి కష్టపడుతున్నాను. ఇది చాల ఎక్కువ.",
              "or": "ମୁଁ ଏକାକୀ ଏହା କରିବାକୁ ସଂଘର୍ଷ କରେ, ରଂଜନ ମାମୁଁ | ଏହା ବହୁତ ଅଧିକ |",
              "as": "মই এইটো অকলে কৰিবলৈ সংগ্ৰাম কৰি আছোঁ, ৰঞ্জন খুৰা। বহুত বেছি কষ্ট কৰোঁ।",
              "gu": "રંજન કાકા, હું એકલો આ કરવા માટે સંઘર્ષ કરું છું. આ વધુ પડતુ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "You know I'm here, right? In case you feel stuck, we can figure the problem out together!",
              "hi": "तुम जानती हो कि मैं यहाँ हूँ, है ना? यदि तुम कहीं फंस जाती हो, तो हम समस्या को एक साथ सुलझा सकते हैं!",
              "ka": "ನಾನು ಇಲ್ಲಿದ್ದೇನೆ ಅಂತ ನಿಂಗೆ ತಿಳಿದಿದೆ, ಸರಿ? ನೀನು ಯಾವುದರಲ್ಲಾದರೂ ಕಷ್ಟದಲ್ಲಿ  ಸಿಕ್ಕಿಕೊಂಡರೆ ನಾವು ಒಟ್ಟಿಗೆ ಸಮಸ್ಯೆಗೆ ಪರಿಹಾರ ಕಂಡುಹಿಡಿಯಬಹುದು!",
              "te": "నేను ఇక్కడ ఉన్నానని నీకు తెలుసా, సరేనా? ఒకవేళ నీకు ఇరుక్కుపోయినట్లు అనిపిస్తే, మనం కలిసి సమస్యను పరిష్కరిద్దాము!",
              "or": "ଆପଣ ଜାଣନ୍ତି ମୁଁ ଏଠାରେ ଅଛି, ଠିକ୍? ଯଦି ତୁମେ ଅସୁବିଧା ରେ ପଡି ଯାଇଥିବାର\n  ଅନୁଭବ କର, ଆମେ ଏକତ୍ର ସମସ୍ୟାକୁ ଆକଳନ କରିପାରିବା!",
              "as": "মই আছোঁ নহয়। যদি তোমাৰ কিবা অসুবিধা হয় মোক কবাচোন, আমি একেলগে সমস্যাটো সমাধান কৰিব পাৰিম!",
              "gu": "તું જાણે છે કે હું અહીં છું, બરાબર? જો તને અંગે કે તું ક્યાંક અટવાઈ છું , તો આપણે સાથે મળીને સમસ્યાનો ઉકેલ લાવી શકીએ છીએ!"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4_2_1",
              "hi": "A4_2_1",
              "ka": "A4_2_1",
              "te": "A4_2_1",
              "or": "A4_2_1",
              "as": "A4_2_1",
              "gu": "A4_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira continues her preparation for the entrance exams. She works hard to complete all the topics covered in the study material.",
              "hi": "मीरा प्रवेश परीक्षा के लिए अपनी तैयारी जारी रखती है। वह अध्ययन सामग्री में शामिल सभी उपविषयों को पढने के लिए कड़ी मेहनत करती है।",
              "ka": "ಮೀರಾ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಗಳಿಗೆ ತನ್ನ ತಯಾರಿಯನ್ನು ಮುಂದುವರೆಸಿದಳು. ಅಧ್ಯಯನ ಸಾಮಗ್ರಿಯಲ್ಲಿ ಒಳಗೊಂಡಿರುವ ಎಲ್ಲಾ ವಿಷಯಗಳನ್ನು ಪೂರ್ತಿ ಮಾಡಕ್ಕೆ ಅವಳು ತುಂಬಾ ಶ್ರಮ ಪಡ್ತಾ ಇದ್ದಾಳೆ.",
              "te": "మీరా ప్రవేశ పరీక్షల కోసం తన ప్రయత్నాన్ని చేస్తుంది. స్టడీ మెటీరియల్‌లో కవర్ చేయబడిన అన్ని అంశాలను పూర్తి చేయడానికి ఆమె తీవ్రంగా కృషి చేస్తుంది.",
              "or": "ମୀରା ପ୍ରବେଶିକା ପରୀକ୍ଷା ପାଇଁ ପ୍ରସ୍ତୁତି ଜାରି ରଖିଛନ୍ତି | ଅଧ୍ୟୟନ ସାମଗ୍ରୀରେ\n  ଆବୃତ ସମସ୍ତ ବିଷୟ ସମାପ୍ତ କରିବାକୁ ସେ କଠିନ ପରିଶ୍ରମ କରନ୍ତି |",
              "as": "মীৰাই প্ৰৱেশ পৰীক্ষাৰ বাবে প্ৰস্তুতি অব্যাহত ৰাখিছে। অধ্যয়ন সামগ্ৰীত অন্তৰ্ভুক্ত সকলো বিষয় সম্পূৰ্ণ কৰিবলৈ তাই কঠোৰ পৰিশ্ৰম কৰিছে।",
              "gu": "મીરાએ પ્રવેશ પરીક્ષાની તૈયારી ચાલુ રાખી છે. તે અભ્યાસ સામગ્રીમાં આવરી લેવામાં આવેલા તમામ વિષયોને પૂર્ણ કરવા સખત મહેનત કરે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4_3_1",
              "hi": "A4_3_1",
              "ka": "A4_3_1",
              "te": "A4_3_1",
              "or": "A4_3_1",
              "as": "A4_3_1",
              "gu": "A4_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Ranjan uncle guides her; they sit to work on the mathematics and physics problems every day after school. He also takes tests for her every month.",
              "hi": "रंजन चाचा उसे गाइड करते हैं; वे स्कूल के बाद हर दिन गणित और भौतिकी विषयों पर समस्याओं को हल करने के लिए बैठते हैं। वह हर महीने उसकी परीक्षा भी लेते हैं।",
              "ka": "ರಂಜನ್ ಅಂಕಲ್ ಅವಳಿಗೆ ಮಾರ್ಗದರ್ಶನ ನೀಡ್ತಾರೆ. ಅವರು ಪ್ರತಿದಿನ ಶಾಲೆಯ ನಂತರ ಗಣಿತ ಮತ್ತು ಭೌತಶಾಸ್ತ್ರದ ಸಮಸ್ಯೆಗಳ ಮೇಲೆ ಕೆಲಸ ಮಾಡೊಕ್ಕೆ ಕುಳಿತುಕೊಳ್ತಾರೆ. ಪ್ರತಿ ತಿಂಗಳು ಅವಳಿಗೆ ಪರೀಕ್ಷೆಗಳನ್ನೂ ತೆಗೆದುಕೊಳ್ತಾರೆ.",
              "te": "రన్జన్ మామ ఆమెకు గైడ్ చేస్తాడు; వారు పాఠశాల తర్వాత ప్రతిరోజూ గణితం మరియు భౌతిక సమస్యలపై పని చేయడానికి కూర్చుంటారు. ప్రతి నెలా ఆమెకు పరీక్షలు కూడా పెట్టేవాడు.",
              "or": "ରଂଜନ ମାମୁଁ ତାଙ୍କୁ ମାର୍ଗଦର୍ଶନ କରନ୍ତି; ସେମାନେ ବିଦ୍ୟାଳୟ ପରେ ପ୍ରତିଦିନ ଗଣିତ\n  ଏବଂ ପଦାର୍ଥ ବିଜ୍ଞାନ ସମସ୍ୟା ଉପରେ କାର୍ଯ୍ୟ କରିବାକୁ ବସିଥା’ନ୍ତି | ସେ ମଧ୍ୟ ପ୍ରତି ମାସରେ ପରୀକ୍ଷା ଦିଅନ୍ତି |",
              "as": "ৰঞ্জন খুৰাকে তাইক পঢ়াশুনা তদাৰক কৰিছে; তেওঁলোকে বিদ্যালয়ৰ পিছত প্ৰতিদিনে গণিত আৰু পদাৰ্থ বিজ্ঞানৰ সমস্যাৰ ওপৰত কাম কৰিবলৈ বহে। তেওঁ প্ৰতি মাহে তাইৰ পৰীক্ষাও লয়।",
              "gu": "રંજન કાકા તેને માર્ગદર્શન આપે છે; તે વર્ગ પછી દરરોજ ગણિત અને ભૌતિકશાસ્ત્રની સમસ્યાઓ પર કામ કરવા બેસે છે. તે દર મહિને તેના માટે ટેસ્ટ  કરે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4_3_2//",
              "hi": "A4_3_2//",
              "ka": "A4_3_2//",
              "te": "A4_3_2//",
              "or": "A4_3_2//",
              "as": "A4_3_2//",
              "gu": "A4_3_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "One day while they are solving a paper together...",
              "hi": "एक दिन जब वे एक प्रश्नपत्र को एक साथ हल कर रहे हैं ...",
              "ka": "ಒಂದು ದಿನ ಇಬ್ಬರೂ ಒಟ್ಟಿಗೆ ಪೇಪರ್ ಸಾಲ್ವ್ ಮಾಡ್ತಾ ಇರೋವಾಗ ...",
              "te": "ఒకరోజు వాళ్ళిద్దరూ కలిసి పేపర్ సాల్వ్ చేస్తుండగా...",
              "or": "ଦିନେ ସେମାନେ ଏକତ୍ର କାଗଜ ସମାଧାନ କରୁଥିବାବେଳେ ...",
              "as": "এদিন তেওঁলোকে একেলগে এখন কাগজ সমাধান কৰি থাকোঁতে...",
              "gu": "એક દિવસ જ્યારે તેઓ સાથે મળીને પેપર ઉકેલી રહ્યા હતા..."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Ranjan Uncle",
              "hi": "रंजन चाचा",
              "ka": "ರಂಜನ್ ಅಂಕಲ್",
              "te": "రన్జన్ అంకుల్",
              "or": "ରଞ୍ଜନ ମାମୁଁ",
              "as": "ৰঞ্জন খুড়া",
              "gu": "રંજન કાકા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Mira, you need to remember that your rank is very critical.",
              "hi": "मीरा, आपको याद रखना होगा कि आपकी रैंक बहुत महत्वपूर्ण है।",
              "ka": "ಮೀರಾ, ನೀನು ಪಡೆಯೋ ಶ್ರೇಣಿ ತುಂಬಾ ನಿರ್ಣಾಯಕವಾಗತ್ತೆ ಎಂದು ನೀನು ನೆನಪಿಟ್ಟುಕೊಳ್ಳಬೇಕು.",
              "te": "మీరా, నీ ర్యాంక్ చాలా కష్టమైనదని నువ్వు గుర్తుంచుకోవాలి.",
              "or": "ମୀରା, ତୁମକୁ ମନେ ରଖିବାକୁ ହେବ ଯେ ତୁମର ପଦବୀ ଅତ୍ୟନ୍ତ ଗୁରୁତର ଅଟେ |",
              "as": "মীৰা, তুমি মনত ৰাখিব লাগিব যে আপোনাৰ স্থানটো অতি দোমোজাত আছে।",
              "gu": "મીરા, તારે યાદ રાખવાની જરૂર છે કે તારો નંબર ખૂબ જ જટીલ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I know Ranjan Uncle. I'll get a good college based on my rank.",
              "hi": " रंजन चाचा मुझे पता है। मुझे अपने रैंक के आधार पर ही एक अच्छा कॉलेज मिलेगा।",
              "ka": "ನನಗೆ  ಗೊತ್ತು ರಂಜನ್ ಅಂಕಲ್. ನನ್ನ ಶ್ರೇಣಿಯ ಆಧಾರದ ಮೇಲೆ ನಾನು ಉತ್ತಮ ಕಾಲೇಜು ಪಡೆಯುತ್ತೇನೆ ಅಂತ.",
              "te": "నాకు తెలుసు రన్ జన్ అంకుల్. నా ర్యాంకు ఆధారంగానే మంచి కాలేజీని పొందగలను.",
              "or": "ମୁଁ ଜାଣେ ରଂଜନ ଅଙ୍କଲ | ମୋର ରାଙ୍କ ଉପରେ ଆଧାର କରି ମୁଁ ଏକ ଭଲ କଲେଜ ପାଇବି |",
              "as": "মই জানো ৰঞ্জন খুড়া মই মোৰ স্থানৰ ওপৰতে ভিত্তি কৰি এখন ভাল মহাবিদ্যালয় পাম।",
              "gu": "હું રંજન કાકાને ઓળખું છું. મને મારા નંબર આધારે સારી કોલેજ મળશે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4_3_3////",
              "hi": "A4_3_3/////",
              "ka": "A4_3_3////",
              "te": "A4_3_3////",
              "or": "A4_3_3////",
              "as": "A4_3_3////",
              "gu": "A4_3_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Not just that, Mira. Your rank will also determine what stream of engineering you can take up. If you want a stream like Computer Science or Aeronautical Engineering, you have to get a higher rank.",
              "hi": "सिर्फ इतना नहीं, मीरा। तुम्हारी रैंक यह भी निर्धारित करेगी कि तुम इंजीनियरिंग की किस धारा को ले सकती हो। यदि तुम कंप्यूटर विज्ञान या वैमानिकी इंजीनियरिंग की तरह एक धारा चाहती हो, तो तुमको उच्च रैंक प्राप्त करना होगा।",
              "ka": "ಅಷ್ಟೇ ಅಲ್ಲ ಮೀರಾ. ನಿನ್ನ ಶ್ರೇಣಿಯು ನೀವು ಯಾವ ಎಂಜಿನಿಯರಿಂಗ್ ಸ್ಟ್ರೀಮ್ ಅನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೋದು ಅಂತ ನಿರ್ಧರಿಸತ್ತೆ. ನೀನು ಕಂಪ್ಯೂಟರ್ ಸೈನ್ಸ್ ಅಥವಾ ಏರೋನಾಟಿಕಲ್ ಎಂಜಿನಿಯರಿಂಗ್‌ನಂತಹ ಸ್ಟ್ರೀಮ್ ಬಯಸಿದಲ್ಲಿ, ನೀನು ಉನ್ನತ ಶ್ರೇಣಿಯನ್ನು ಪಡೆಯಬೇಕು.",
              "te": "అంతే కాదు మీరా. నీ ర్యాంక్ నువ్వు ఏ ఇంజనీరింగ్ స్ట్రీమ్‌ని ఎంచుకోవచ్చో కూడా నిర్ణయిస్తుంది. నీకు కంప్యూటర్ సైన్స్ లేదా ఏరోనాటికల్ ఇంజనీరింగ్ వంటి స్ట్రీమ్ కావాలంటే నువ్వు మంచి ర్యాంక్ సాధించాలి.",
              "or": "କେବଳ ସେତିକି ନୁହେଁ ମୀରା। ତୁମର ରାଙ୍କ୍ ମଧ୍ୟ ନିର୍ଣ୍ଣୟ କରିବ ଯେ ଆପଣ କେଉଁ\n  ଇଂଜିନିୟରିଂର ଷ୍ଟ୍ରିମ୍ ନେଇପାରିବେ | ଯଦି ଆପଣ କମ୍ପ୍ୟୁଟର ସାଇନ୍ସ କିମ୍ବା \n ଏରୋନେଟିକାଲ୍ ଇଞ୍ଜିନିୟରିଂ ପରି ଏକ ଷ୍ଟ୍ରିମ୍ ଚାହୁଁଛନ୍ତି, ତେବେ ଆପଣଙ୍କୁ ଏକ ଉଚ୍ଚ ପଦବୀ ପାଇବାକୁ ପଡିବ |",
              "as": "কেৱল সেয়াই নহয়, মীৰা। তুমি লাভ কৰা স্থানটোৱে তুমি অভিযান্ত্ৰিকৰ কি ধাৰা গ্ৰহণ কৰিব পাৰিবা সেয়াও নিৰ্ধাৰণ কৰিব। যদি তুমি কম্পিউটাৰ বিজ্ঞান বা এৰোনটিকেল ইঞ্জিনিয়াৰিঙৰ দৰে এটা স্ট্ৰিম বিচাৰা, তেন্তেতুমি ভাল স্থান লাভ কৰিব লাগিব।",
              "gu": "એટલું જ નહિ, મીરા. તમારો નંબર એ પણ નક્કી કરશે કે તું એન્જિનિયરિંગનો કયો પ્રવાહ લઈ શકે છે. જો તું કોમ્પ્યુટર સાયન્સ અથવા એરોનોટિકલ એન્જિનિયરિંગ જેવી પ્રવાહ ઈચ્છે તો, તો તારે ઉચ્ચ નંબર મેળવવો પડશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I'm not sure if I'm interested in computer science. I feel like mechanical engineering might be a good option for me.",
              "hi": "मुझे स्पष्ट नहीं है कि मुझे कंप्यूटर विज्ञान में दिलचस्पी है या नहीं। मुझे लगता है कि मैकेनिकल इंजीनियरिंग मेरे लिए एक अच्छा विकल्प हो सकता है।",
              "ka": "ನಾನು ಕಂಪ್ಯೂಟರ್ ವಿಜ್ಞಾನದಲ್ಲಿ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದೇನೆಯೇ ಅಂತ ನನಗೆ ಸರಿಯಾಗಿ ಗೊತ್ತಿಲ್ಲ. ಮೆಕ್ಯಾನಿಕಲ್ ಎಂಜಿನಿಯರಿಂಗ್ ನನಗೆ ಉತ್ತಮ ಆಯ್ಕೆ ಅಂತ ನನ್ನ ಅನಿಸಿಕೆ.",
              "te": "నాకు కంప్యూటర్ సైన్స్ పట్ల ఆసక్తి ఉందో లేదో నాకు ఖచ్చితంగా తెలియదు. మెకానికల్ ఇంజనీరింగ్ నాకు మంచి ఆప్షన్ అని నేను భావిస్తున్నాను.",
              "or": "ମୁଁ ନିଶ୍ଚିତ ନୁହେଁ ଯେ ମୁଁ କମ୍ପ୍ୟୁଟର ବିଜ୍ଞାନ ପ୍ରତି ଆଗ୍ରହୀ କି? ମୋତେ ଲାଗୁଛି ଯେ \n ମେକାନିକାଲ୍ ଇଞ୍ଜିନିୟରିଂ ମୋ ପାଇଁ ଏକ ଭଲ ବିକଳ୍ପ ହୋଇପାରେ |",
              "as": "মই কম্পিউটাৰ বিজ্ঞানত আগ্ৰহী হয় নে নহয় মই নিজেই অনুমান কৰিব পৰা নাই। মোৰ মতে মেকানিকেল ইঞ্জিনিয়াৰিং মোৰ বাবে এটা ভাল বিকল্প হ'ব পাৰে।",
              "gu": "મને ખાતરી નથી કે મને કમ્પ્યુટર વિજ્ઞાનમાં રસ છે કે નહીં. પણ મને લાગે છે કે મિકેનિકલ એન્જિનિયરિંગ મારા માટે સારો વિકલ્પ હોઈ શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "But Mira, the entire tech industry is running on computer sciences. And besides, you don't find many girls in mechanical engineering.",
              "hi": "लेकिन मीरा, पूरी तकनीकी इंडस्ट्री कंप्यूटर विज्ञान पर चल रही है। और इसके अलावा, तुमको मैकेनिकल इंजीनियरिंग में अधिक लड़कियां नहीं मिलती हैं।",
              "ka": "ಆದರೆ ಮೀರಾ, ಇಡೀ ಟೆಕ್ ಉದ್ಯಮವು ಕಂಪ್ಯೂಟರ್ ವಿಜ್ಞಾನದ ಮೂಲಕನೇ ನಡೀತಾ ಇದೆ. ಇದಲ್ಲದೆ, ನೀನು ಮೆಕ್ಯಾನಿಕಲ್ ಎಂಜಿನಿಯರಿಂಗ್‌ನಲ್ಲಿ ಹೆಚ್ಚಿನ ಹುಡುಗಿಯರನ್ನು ನೋಡುವುದಿಲ್ಲ.",
              "te": "కానీ మీరా, మొత్తం టెక్ పరిశ్రమ కంప్యూటర్ సైన్స్ పై నడుస్తోంది. అంతేకాకుండా, నీకు మెకానికల్ ఇంజనీరింగ్‌లో అమ్మాయిలు ఎక్కువగా కనిపించరు.",
              "or": "କିନ୍ତୁ ମୀରା, ସମଗ୍ର ଟେକ୍ ଇଣ୍ଡଷ୍ଟ୍ରି କମ୍ପ୍ୟୁଟର ସାଇନ୍ସରେ ଚାଲିଛି | ଏବଂ ଏହା ବ୍ୟତୀତ,\n  ଆପଣ ମେକାନିକାଲ୍ ଇଞ୍ଜିନିୟରିଂରେ ଅନେକ ଝିଅଙ୍କୁ ପାଇବ ନାହିଁ |",
              "as": "কিন্তু মীৰা, সমগ্ৰ প্ৰযুক্তি উদ্যোগটো কম্পিউটাৰ বিজ্ঞানতে চলি আছে। তাৰোপৰি, তুমি মেকানিকেল ইঞ্জিনিয়াৰিংত বেছি ছোৱালী বিচাৰি নাপাবা।",
              "gu": "પણ મીરા, આખી  ટેક ઈન્ડસ્ટ્રી કોમ્પ્યુટર સાયન્સ પર ચાલે છે. અને ઉપરાંત, તને મિકેનિકલ એન્જિનિયરિંગમાં ઘણી છોકરીઓ જોવા મળતી નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Hmmm, I'll keep that in mind.",
              "hi": "हमम, मैं इसे ध्यान में रखूंगी।",
              "ka": "ಹ್ಮ ಇದನ್ನು ನಾನು ಮನಸ್ಸಿನಲ್ಲಿ ಇಟ್ಟ್ಕೋತೆನೆ",
              "te": "అవును, నేను దానిని గుర్తుంచుకోవాలి.",
              "or": "ହମ୍, ମୁଁ ଏହାକୁ ଧ୍ୟାନରେ ରଖିବି |",
              "as": "হুমমম, মই বাৰু সেইটো মনত ৰাখিম।",
              "gu": "હમમમ, હું તેને ધ્યાનમાં રાખીશ."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4_3_4_checklist",
              "hi": "A4_3_4_चेकलिस्ट ",
              "ka": "A4_3_4_checklist",
              "te": "A4_3_4_చెక్‌లిస్ట్",
              "or": "A4_3_4_checklist",
              "as": "A4_3_4_ পৰীক্ষা তালিকা",
              "gu": "A4_3_4_તપાસવા માટે યાદી"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "She still feels like she should talk to someone regarding her decision. She decides to talk to both Preeti and her mother. Who should she talk to first?",
              "hi": "उसे अभी भी ऐसा लगता है कि उसे अपने निर्णय के बारे में किसी से बात करनी चाहिए। वह प्रीति और अपनी मां दोनों से बात करने का निश्चय करती है। उसे पहले किससे बात करनी चाहिए?",
              "ka": "ತನ್ನ ನಿರ್ಧಾರದ ಬಗ್ಗೆ ಯಾರೊಂದಿಗಾದರೂ ಮಾತನಾಡಬೇಕು ಅಂತ ಅವಳ ಮನಸ್ನಲ್ಲಿದೆ. ಅವಳು ಪ್ರೀತಿ ಮತ್ತು ಅವಳ ತಾಯಿ ಇಬ್ಬರ ಜೊತೆಗೂ ಮಾತನಾಡಕ್ಕೆ ನಿರ್ಧರಿಸ್ತಾಳೆ. ಅವಳು ಮೊದಲು ಯಾರೊಂದಿಗೆ ಮಾತನಾಡಬೇಕು?",
              "te": "తన నిర్ణయానికి సంబంధించి ఎవరితోనైనా మాట్లాడాలని ఆమెకు అనిపిస్తుంది. ఆమె ప్రీతి మరియు ఆమె తల్లితో మాట్లాడాలని నిర్ణయించుకుంది. ఆమె ముందుగా ఎవరితో మాట్లాడాలి?",
              "or": "ସେ ତଥାପି ଅନୁଭବ କରୁଛନ୍ତି ଯେ ସେ ତାଙ୍କ ନିଷ୍ପତ୍ତି ବିଷୟରେ କାହା ସହିତ କଥା\n  ହେବା ଉଚିତ୍ | ସେ ଉଭୟ ପ୍ରୀତି ଏବଂ ତାଙ୍କ ମାଙ୍କ ସହ କଥା ହେବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ସେ ପ୍ରଥମେ କାହା ସହିତ କଥା ହେବା ଉଚିତ୍?",
              "as": "তাই এতিয়াও অনুভৱ কৰে যে তাই নিজৰ সিদ্ধান্ত সম্পৰ্কে কাৰোবাৰ সৈতে কথা পাতিব লাগে। তাই প্ৰীতি আৰু তাইৰ মাক দুয়োৰে সৈতে কথা পাতিবলৈ সিদ্ধান্ত লয়। তাই প্ৰথমে কাৰ সৈতে কথা পাতিব লাগে?",
              "gu": "તેને હજી પણ લાગે છે કે તેને તેના નિર્ણય વિશે કોઈની સાથે વાત કરવી જોઈએ. તે પ્રીતિ અને તેની માતા બંને સાથે વાત કરવાનું નક્કી કરે છે. તેને પ્રથમ કોની સાથે વાત કરવી જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Talk to Preeti",
              "hi": "प्रीति से बात करे",
              "ka": "ಪ್ರೀತಿಯ ಜೊತೆಗೆ ಮಾತನಾಡು",
              "te": "ప్రీతితో మాట్లాడండి",
              "or": "ପ୍ରୀତିଙ୍କ ସହ କଥା ହୁଅ |",
              "as": "প্ৰীতিৰ সৈতে কথা পতাটো",
              "gu": "પ્રીતિ સાથે વાત કરવી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Talk to Mother",
              "hi": "माँ से बात करे",
              "ka": "ಅಮ್ಮನ ಜೋಟ್ಗೆ ಮಾತನಾಡು",
              "te": "తల్లితో మాట్లాడండి",
              "or": "ମା ସହିତ କଥା ହୁଅ |",
              "as": "মাকৰ সৈতে কথা পতাটো",
              "gu": "માતા સાથે વાત કરવી"
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4_2_2",
              "hi": "A4_2_2",
              "ka": "A4_2_2",
              "te": "A4_2_2",
              "or": "A4_2_2",
              "as": "A4_2_2",
              "gu": "A4_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira starts preparing for her entrance exams.",
              "hi": "मीरा अपनी प्रवेश परीक्षा के लिए तैयारी शुरू कर देती है।",
              "ka": "ಮೀರಾ ತನ್ನ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಗೆ ತಯಾರಿ ಆರಂಭ ಮಾಡ್ತಾಳೆ.",
              "te": "మీరా తన ప్రవేశ పరీక్షల కోసం ప్రిపేర్ అవుతోంది.",
              "or": "ମୀରା ତାଙ୍କ ପ୍ରବେଶିକା ପରୀକ୍ଷା ପାଇଁ ପ୍ରସ୍ତୁତି ଆରମ୍ଭ କରନ୍ତି |",
              "as": "মীৰাই তাইৰ প্ৰৱেশ পৰীক্ষাৰ বাবে প্ৰস্তুতি আৰম্ভ কৰে।",
              "gu": "મીરા તેની પ્રવેશ પરીક્ષાની તૈયારી કરવા લાગે છે."
            }
          }
        ]
      },
      "scene10": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4i_1_1",
              "hi": "A4i_1_1",
              "ka": "A4i_1_1",
              "te": "A4i_1_1",
              "or": "A4i_1_1",
              "as": "A4i_1_1",
              "gu": "A4i_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira decides to talk to Preeti about the choice of departments. She feels like Preeti might know more about these choices from her coaching classes.",
              "hi": "मीरा विभागों के चयन के विषय में  प्रीति से बात करने का निर्णय लेती है। उसे लगता है कि प्रीति को अपने कोचिंग क्लासेस से इन विकल्पों के बारे में अधिक जानकारी मिल सकती है।",
              "ka": "ವಿಭಾಗಗಳ ಆಯ್ಕೆಯ ಬಗ್ಗೆ ಪ್ರೀತಿಯೊಂದಿಗೆ ಮಾತಾಡಲು ಮೀರಾ ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಪ್ರೀತಿ ತನ್ನ ಕೋಚಿಂಗ್ ತರಗತಿಗಳಿಂದ ಈ ಆಯ್ಕೆಗಳ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಂಡಿರಬಹುದು ಎಂದು ಅವಳ ಅನಿಸಿಕೆ.",
              "te": "మీరా డిపార్ట్ మెంట్ సెలెక్ట్ చేసుకోవడం గురించి ప్రీతితో మాట్లాడాలని నిర్ణయించుకుంది. ప్రీతికి తన కోచింగ్ క్లాసుల ద్వారా దీని గురించి బాగా తెలిసి ఉంటుందని ఆమె అనుకుంటుంది.",
              "or": "ମୀରା ବିଭାଗର ପସନ୍ଦ ବିଷୟରେ ପ୍ରୀତିଙ୍କ ସହ କଥା ହେବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | \n ସେ ଅନୁଭବ କରୁଛନ୍ତି ଯେ ପ୍ରୀତି ତାଙ୍କ କୋଚିଂ କ୍ଲାସରୁ ଏହି ପସନ୍ଦଗୁଡ଼ିକ ବିଷୟରେ ଅଧିକ ଜାଣିଥିବେ |",
              "as": "মীৰাই পছন্দৰ বিভাগসমূহৰ বিষয়ে প্ৰীতিৰ সৈতে কথা পাতিবলৈ সিদ্ধান্ত লয়। তাই ভাৱে প্ৰীতিয়ে তাইৰ প্ৰশিক্ষণ শ্ৰেণীৰ পৰা এই বিকল্পবোৰৰ বিষয়ে যেন অধিক জানিব পাৰে।",
              "gu": "મીરા વિભાગોની પસંદગી વિશે પ્રીતિ સાથે વાત કરવાનું નક્કી કરે છે. તેને લાગે છે કે પ્રીતિ તેના કોચિંગ ક્લાસમાંથી આ પસંદગીઓ વિશે વધુ જાણતી હશે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4i_1_2",
              "hi": "A4i_1_2",
              "ka": "A4i_1_2",
              "te": "A4i_1_2",
              "or": "A4i_1_2",
              "as": "A4i_1_2",
              "gu": "A4i_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She meets her during lunch break the next day and asks her which department she has opted for.",
              "hi": "वह अगले दिन दोपहर के भोजन के समय  उससे मिलती है और उससे पूछती है कि उसने किस विभाग का चयन किया है।",
              "ka": "ಮರುದಿನ ಊಟದ ವಿರಾಮದ ಸಮಯದಲ್ಲಿ ಅವಳು ಅವಳನ್ನು ಭೇಟಿಯಾಗುತ್ತಾಳೆ ಮತ್ತು ಅವಳು ಯಾವ ವಿಭಾಗವನ್ನು ಆರಿಸಿಕೊಂಡಿದ್ದಾಳೆ ಎಂದು ಕೇಳುತ್ತಾಳೆ.",
              "te": "ఆమె మరుసటి రోజు లంచ్ టైమ్ లో తనని కలుసి ఏ డిపార్ట్ మెంట్ ని సెలెక్ట్ చేసుకున్నవాని అడుగుతుంది.",
              "or": "ପରଦିନ ମଧ୍ୟାହ୍ନ ଭୋଜନ ବିରତି ସମୟରେ ସେ ତାଙ୍କୁ ଭେଟି ପଚାରନ୍ତି ଯେ ସେ \n କେଉଁ ବିଭାଗକୁ ବାଛିଛନ୍ତି |",
              "as": "তাই পিছদিনা দুপৰীয়াৰ আহাৰৰ বিৰতিৰ সময়ত তাই কোনটো বিভাগ বাছনি কৰিছে তাইক লগ পাই সুধিলে।",
              "gu": "તે બીજા દિવસે જમવાના સમય દરમિયાન તેને મળે છે અને તેને પૂછે છે કે તેને કયો વિભાગ પસંદ કર્યો છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4i_1_3//",
              "hi": "A4i_1_3//",
              "ka": "A4i_1_3//",
              "te": "A4i_1_3//",
              "or": "A4i_1_3//",
              "as": "A4i_1_3//",
              "gu": "A4i_1_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Preeti",
              "hi": "प्रीति",
              "ka": "ಪ್ರೀತಿ",
              "te": "ప్రీతి",
              "or": "ପ୍ରୀତି",
              "as": "প্ৰীতি",
              "gu": "પ્રીતિ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Hmmm, it’s too early to say. But I would like to take up chemical engineering.",
              "hi": "हमम, यह बताना बहुत जल्दी होगा। लेकिन मैं केमिकल इंजीनियरिंग लेना चाहती हूं।",
              "ka": "ಹ್ಮ, ಇದನ್ನು ಹೇಳೋದಕ್ಕೆ ತುಂಬಾ ಮುಂಚೆಯೇ. ಆದರೆ ನಾನು ಕೆಮಿಕಲ್ ಇಂಜಿನಿಯರಿಂಗ್ ತಗೋಬೇಕು ಅಂತ ಬಯಸ್ಟೀನಿ.",
              "te": "అవును, చెప్పడానికి ఇంకా సమయం ఉంది. కానీ, నేను కెమికల్ ఇంజినీరింగ్ చేయాలనుకుంటున్నాను.",
              "or": "ହଁ ଏବେ କହିବା ବହୁତ ଶୀଘ୍ର ହୋଇଯିବ | କିନ୍ତୁ ମୁଁ ରାସାୟନିକ ଇଞ୍ଜିନିୟରିଂ ନେବାକୁ ଚାହୁଁଛି |",
              "as": "হুমমম, এইটো এতিয়াই কোৱাটো টান। কিন্তু মই ৰাসায়নিক অভিযান্ত্ৰিক গ্ৰহণ কৰিব বিচাৰোঁ।",
              "gu": "હમમ, લાગે છે કે હું બહુ જલદી કહી રહી છું પરંતુ હું કેમિકલ એન્જિનિયરિંગ કરવા માંગુ છું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "How did you decide that? Isn’t it tough to select one department?",
              "hi": "तुमने यह कैसे निश्चित किया? क्या एक विभाग का चयन करना मुश्किल नहीं है?",
              "ka": "ನೀನು ಅದನ್ನು ಹೇಗೆ ನಿರ್ಧರಿಸಿದ್ದೀಯಾ? ಒಂದು ವಿಭಾಗವನ್ನು ಆಯ್ಕೆ ಮಾಡುವುದು ಕಷ್ಟ ಅಲ್ಲವಾ?",
              "te": "నువ్వు దానిని ఎలా నిర్ణయించుకున్నావు? ఒక డిపార్ట్ మెంట్ ని సెలెక్ట్ చేసుకోవడం కష్టం కాదా?",
              "or": "ଆପଣ ତାହା କିପରି ସ୍ଥିର କଲେ? ଗୋଟିଏ ବିଭାଗ ବାଛିବା କଷ୍ଟକର ନୁହେଁ କି?",
              "as": "তুমি সেইটো কেনেকৈ সিদ্ধান্ত ল'লা? এটা বিভাগ বাছনি কৰাটো কঠিন নহয় নেকি?",
              "gu": "તું તે કેવી રીતે નક્કી કર્યું? શું તે વિભાગ પસંદ કરવાનું અઘરું નથી?"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4i_1_4////",
              "hi": "A4i_1_4////",
              "ka": "A4i_1_4////",
              "te": "A4i_1_4////",
              "or": "A4i_1_4////",
              "as": "A4i_1_4////",
              "gu": "A4i_1_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I’m very interested in chemistry, and I want to learn more about the application of chemistry in the production of materials and industrial appliances. It’s only a temporary plan though Mira, I might change my mind depending on how well I do in my entrance exam.",
              "hi": "मुझे केमिस्ट्री में बहुत दिलचस्पी है, और मैं सामग्री और औद्योगिक उपकरणों के उत्पादन में केमिस्ट्री के प्रयोग के बारे में अधिक जानना चाहती हूं। यह केवल एक अस्थायी योजना है हालांकि मीरा, इस पर निर्भर करता है कि मैं अपनी प्रवेश परीक्षा में कितनी अच्छी तरह से करती हूं,  मैं अपना मन बदल सकती हूं।",
              "ka": "ನಾನು ರಸಾಯನಶಾಸ್ತ್ರದಲ್ಲಿ ತುಂಬಾ ಆಸಕ್ತಿ ಹೊಂದಿದೀನಿ ಮತ್ತು ವಸ್ತುಗಳು ಹಾಗೂ ಕೈಗಾರಿಕಾ ಉಪಕರಣಗಳ ಉತ್ಪಾದನೆ ಮಾಡೋವಾಗ ರಸಾಯನಶಾಸ್ತ್ರಹೇಗೆ ಉಪಯೋಗಕ್ಕೆ ಬರತ್ತೆ ಅಂತ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ನಾನು ಬಯಸುತ್ತೇನೆ. ಮೀರಾ ಆದರೂ ಕೂಡ ಇದು ತಾತ್ಕಾಲಿಕ ಯೋಜನೆ ಅಷ್ಟೇ, ನನ್ನ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಯಲ್ಲಿ ನಾನು ಎಷ್ಟು ಚೆನ್ನಾಗಿ ಮಾಡ್ತೀನಿ ಅನ್ನೋದರ ಮೇಲೆ ನಾನು ನನ್ನ ಮನಸ್ಸನ್ನು ಬದಲಾಯಿಸ್ಬೋದು.",
              "te": "నాకు కెమిస్ట్రీ పైన చాలా ఆసక్తిగా ఉంది. మెటీరియల్స్ మరియు ఇండస్ట్రియల్ ఉత్పత్తిలో కెమిస్ట్రీ అప్లికేషన్ గురించి మరింత తెలుసుకోవాలనుకుంటున్నాను. మీరా ఇది నా చివరి నిర్ణయం కాదు, నేను నా ప్రవేశ పరీక్షలు ఎంత బాగా రాస్తాను అనేదానిపై ఆధారపడి నా నిర్ణయం మార్చుకోవచ్చు.",
              "or": "ମୁଁ ରସାୟନ ବିଜ୍ଞାନ ପ୍ରତି ବହୁତ ଆଗ୍ରହୀ, ଏବଂ ସାମଗ୍ରୀ ଏବଂ ଶିଳ୍ପ ଉପକରଣ \n ଉତ୍ପାଦନରେ ରସାୟନ ବିଜ୍ଞାନର ପ୍ରୟୋଗ ବିଷୟରେ ମୁଁ ଅଧିକ ଜାଣିବାକୁ ଚାହେଁ | \n ଏହା କେବଳ ଏକ ଅସ୍ଥାୟୀ ଯୋଜନା ଯଦିଓ ମୀରା, ମୁଁ ମୋର ପ୍ରବେଶିକା ପରୀକ୍ଷାରେ\n  କେତେ ଭଲ କରେ ତାହା ଉପରେ ନିର୍ଭର କରି ମୋର ମନ ପରିବର୍ତ୍ତନ କରିପାରେ |",
              "as": "মই ৰসায়ন বিজ্ঞানৰ প্ৰতি যথেষ্ট আগ্ৰহী, আৰু মই সামগ্ৰী আৰু ঔদ্যোগিক সঁজুলি উৎপাদনত ৰসায়ন বিজ্ঞানৰ প্ৰয়োগৰ বিষয়ে অধিক জানিব বিচাৰোঁ। মীৰা, এইটো কেৱল এটা অস্থায়ী পৰিকল্পনা যদিও মই মোৰ প্ৰৱেশ পৰীক্ষাত কিমান ভাল কৰিম তাৰ ওপৰত নিৰ্ভৰ কৰি মোৰ মন সলনি কৰিব পাৰোঁ।",
              "gu": "મને રસાયણશાસ્ત્રમાં ખૂબ જ રસ છે, અને હું સામગ્રી અને ઔદ્યોગિક ઉપકરણોના ઉત્પાદનમાં રસાયણશાસ્ત્રના ઉપયોગ વિશે વધુ જાણવા માંગુ છું. તે મારી અચોક્કસ યોજના છે, જોકે મીરા, હું મારી પ્રવેશ પરીક્ષામાં કેટલૂ સારૂ દેખાવ કરું છું તેના આધારે હું મારો વિચાર બદલી શકું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Ahh, okay. I am confused about what I should choose.",
              "hi": "ठीक है। मैं इस बारे में उलझन में हूं कि मुझे क्या चुनना चाहिए।",
              "ka": "ಆಹ್, ಸರಿ. ನಾನು ಯಾವುದನ್ನು ಆರಿಸಬೇಕು ಅನ್ನೋ ಗೊಂದಲದಲ್ಲಿದ್ದೇನೆ.",
              "te": "ఆహ్, సరే. నేను ఏది సెలెక్ట్ చేసుకోవలో తెలియక అయోమయంలో ఉన్నాను.",
              "or": "ଆହା, ଠିକ ଅଛି | ମୁଁ କ’ଣ ବାଛିବି ସେ ବିଷୟରେ ମୁଁ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛି |",
              "as": "বাৰু, ঠিক আছে। মই কি বাছনি কৰা উচিত তাক লৈহে মই বিমোৰত পৰিছোঁ।",
              "gu": "બરાબર, ઠીક છે. મારે શું પસંદ કરવું જોઈએ તે અંગે હું મૂંઝવણમાં છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "There are many options, Mira; you should read up more about them. But why don’t you take up computer science?",
              "hi": "कई विकल्प हैं, मीरा; तुमको उनके बारे में और अधिक पढ़ना चाहिए। लेकिन तुम कंप्यूटर विज्ञान क्यों नहीं लेतीं?",
              "ka": "ಸಾಕಷ್ಟು ಆಯ್ಕೆ ಇದೆ, ಮೀರಾ; ನೀನು ಅದರ ಬಗ್ಗೆ ಹೆಚ್ಚು ತಿಳ್ಕೋಬೇಕು ಅಂದರೆ ಹೆಚ್ಚು ಓದಬೇಕು. ಆದರೆ ನೀನು ಕಂಪ್ಯೂಟರ್ ವಿಜ್ಞಾನವನ್ನು ಏಕೆ ತೆಗೆದುಕೊಳ್ಳಬಾರದು?",
              "te": "చాలా ఆప్షన్స్ ఉన్నాయి, మీరా; నువ్వు వాటి గురించి ఇంకా చదవాలి. కానీ నువ్వు కంప్యూటర్ సైన్స్ ఎందుకు తీసుకోవడం లేదు?",
              "or": "ଅନେକ ବିକଳ୍ପ ଅଛି, ମୀରା; ତୁମେ ସେଗୁଡ଼ିକ ବିଷୟରେ ଅଧିକ ପଢିବା ଉଚିତ୍ |\n  କିନ୍ତୁ ଆପଣ କାହିଁକି କମ୍ପ୍ୟୁଟର ସାଇନ୍ସ ଗ୍ରହଣ କରୁନାହାଁନ୍ତି?",
              "as": "মীৰা, বহুতো বিকল্প আছে; তুমি সেইবোৰৰবিষয়ে আৰু অলপ পঢ়ি শুনি লোৱাঁ। কিন্তু তুমি কম্পিউটাৰ বিজ্ঞান কিয় নোলোৱা?",
              "gu": "ઘણા વિકલ્પો છે મીરા; તારે તેના વિશે વધુ વાંચવું જોઈએ. પણ તું કોમ્પ્યુટર સાયન્સ કેમ નથી લેતી?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "I want to experiment with and apply physics concepts in real life. I felt that I could do that in mechanical engineering from the information we got at the career fair.",
              "hi": "मैं वास्तविक जीवन में फिजिक्स कांसेप्ट्स को प्रयोग करना और लागू करना चाहती हूं। मुझे लगा कि करियर मेले में मिली जानकारी के अनुसार मैं मैकेनिकल इंजीनियरिंग में ऐसा कर सकती हूँ।",
              "ka": "ನಾನು ಭೌತಶಾಸ್ತ್ರದ ಪರಿಕಲ್ಪನೆಗಳನ್ನು ನಿಜ ಜೀವನದಲ್ಲಿ ಪ್ರಯೋಗಿಸಕ್ಕೆ ಮತ್ತು ಅನ್ವಯಿಸಕ್ಕೆ ಬಯಸ್ಟೇನೆ. ವೃತ್ತಿ ಮೇಳದಲ್ಲಿ ಸಿಕ್ಕ ಮಾಹಿತಿಯಿಂದ ಮೆಕ್ಯಾನಿಕಲ್ ಇಂಜಿನಿಯರಿಂಗ್ ಮಾಡಬಹುದು ಅಂತ ಅನಿಸಿತು",
              "te": "నేను నిజ జీవితంలో ఫిజిక్స్ కాన్సెప్ట్ లతో ప్రయోగాలు చేయాలనుకుంటున్నాను. కెరీర్ ఫెయిర్‌లో మనం తెలుసుకున్న సమాచారం వలన నేను మెకానికల్ ఇంజనీరింగ్‌ చేయగలనని అనుకున్నాను.",
              "or": "ମୁଁ ବାସ୍ତବ ଜୀବନରେ ପଦାର୍ଥ ବିଜ୍ଞାନ ଧାରଣା ସହିତ ପରୀକ୍ଷା କରିବାକୁ ଏବଂ\n  ପ୍ରୟୋଗ କରିବାକୁ ଚାହୁଁଛି | ମୁଁ ଅନୁଭବ କଲି ଯେ କ୍ୟାରିୟର ମେଳାରେ ପାଇଥିବା ସୂଚନାରୁ ମୁଁ ମେକାନିକାଲ୍ ଇଞ୍ଜିନିୟରିଂରେ ତାହା କରିପାରିବି |",
              "as": "মই বাস্তৱ জীৱনত পদাৰ্থ বিজ্ঞানৰ ধাৰণাবোৰৰ সৈতে পৰীক্ষা-নিৰীক্ষা আৰু প্ৰয়োগ কৰিব বিচাৰো। আমি কেৰিয়াৰ মেলাত পোৱা তথ্যৰ পৰা মেকানিকেল ইঞ্জিনিয়াৰিংত সেইটো কৰিব পাৰোঁ বুলি মোৰ অনুভৱ হয়।",
              "gu": "હું વાસ્તવિક જીવનમાં ભૌતિકશાસ્ત્રના ખ્યાલો સાથે પ્રયોગ કરવા અને લાગુ કરવા માંગુ છું. કારકિર્દી મેળામાં અમને મળેલી માહિતી પરથી મને લાગ્યું કે હું મિકેનિકલ એન્જિનિયરિંગમાં તે કરી શકું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "So then you already know what you want. It’s much easier to get into mechanical engineering than computer science anyway.",
              "hi": "तो फिर तुम पहले से ही जानती हो कि तुम क्या चाहती हो। वैसे भी कंप्यूटर विज्ञान की तुलना में मैकेनिकल इंजीनियरिंग में जाना बहुत सहज है।",
              "ka": "ಹಾಗಾದರೆ ನಿನಗೆ ಬೇಕಾದ್ದನ್ನ ನೀನು ಈಗಾಗಲೇ ತಿಳ್ಕೊಂಡಿದ್ದೀಯಾ.. ಎನಿದ್ದರೂ ಕಂಪ್ಯೂಟರ್ ವಿಜ್ಞಾನಕ್ಕಿಂತ ಮೆಕ್ಯಾನಿಕಲ್ ಎಂಜಿನಿಯರಿಂಗ್‌ಗೆ ಪ್ರವೇಶ ಪಡೆಯೋದು ತುಂಬಾ ಸುಲಭ.",
              "te": "కాబట్టి నీకు ఏమి కావాలో నీకు ఇప్పటికే తెలుసు. ఏది ఏమైనప్పటికీ కంప్యూటర్ సైన్స్ కంటే మెకానికల్ ఇంజనీరింగ్‌లోకి వెళ్ళడం చాలా సులభం.",
              "or": "ତେବେ ତୁମେ ଯାହା ଚାହୁଁଛ ତାହା ତୁମେ ଆଗରୁ ଜାଣିଛ | ତେବେ କମ୍ପ୍ୟୁଟର ବିଜ୍ଞାନ\n  ଅପେକ୍ଷା ଯାନ୍ତ୍ରିକ ଇଞ୍ଜିନିୟରିଂରେ ପ୍ରବେଶ କରିବା ବହୁତ ସହଜ ଅଟେ |",
              "as": "তেনেহ’লে তুমি কি বিচাৰা ইতিমধ্যে বুজি পাইছা। যিকোনো ধৰণে কম্পিউটাৰ বিজ্ঞানতকৈ যান্ত্ৰিক অভিযান্ত্ৰিকত প্ৰৱেশ কৰাটো বহুত সহজ।",
              "gu": "તો પછી તું પહેલાથી જ જાણું છું કે તને શું જોઈએ છે. કોઈપણ રીતે કમ્પ્યુટર સાયન્સ કરતાં મિકેનિકલ એન્જિનિયરિંગમાં પ્રવેશ મેળવવો ખૂબ સરળ છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4i_1_5",
              "hi": "A4i_1_5",
              "ka": "A4i_1_5",
              "te": "A4i_1_5",
              "or": "A4i_1_5",
              "as": "A4i_1_5",
              "gu": "A4i_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is happy to hear this from one of her closest friends. She feels confident about her decision.",
              "hi": "मीरा अपनी सबसे करीबी मित्रों में से एक से यह सुनकर खुश है। वह अपने निर्णय के विषय में आश्वस्त अनुभव करती है।",
              "ka": "ಮೀರಾಗೆ ತನ್ನ ಆತ್ಮೀಯ ಸ್ನೇಹಿತೆಯೊಬ್ಬಳಿಂದ ಇದನ್ನು ಕೇಳಿ ಸಂತೋಷ ಆಗತ್ತೆ. ಅವಳು ತನ್ನ ನಿರ್ಧಾರದ ಬಗ್ಗೆ ವಿಶ್ವಾಸ ಹೊಂದುತ್ತಾಳೆ.",
              "te": "మీరాకి తన సన్నిహితులలో ఒకరి నుండి ఈ విషయాన్ని విన్నందుకు చాలా సంతోషంగా ఉంది. ఆమె తన నిర్ణయంపై నమ్మకంగా ఉంది.",
              "or": "ମୀରା ତାଙ୍କର ଜଣେ ଘନିଷ୍ଠ ବନ୍ଧୁଙ୍କଠାରୁ ଏହା ଶୁଣି ଖୁସି | ସେ ତାଙ୍କ ନିଷ୍ପତ୍ତି ଉପରେ\n  ଆତ୍ମବିଶ୍ୱାସ ଅନୁଭବ କରନ୍ତି |",
              "as": "মীৰাই তাইৰ এজন নিকটতম বন্ধুৰ মুখেৰে এই কথা শুনি বৰ সুখী হৈ পৰিল। তাই তাইৰ সিদ্ধান্তৰ বিষয়টোত নিজকে আত্মবিশ্বাসী যেন অনুভৱ কৰিলে।",
              "gu": "મીરા તેના નજીકના મિત્રમાંથી આ સાંભળીને ખુશ છે. તે તેના નિર્ણય અંગે આત્મવિશ્વાસ અનુભવે છે."
            }
          }
        ]
      },
      "scene11": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4ii_1_1",
              "hi": "A4ii_1_1",
              "ka": "A4ii_1_1",
              "te": "A4ii_1_1",
              "or": "A4ii_1_1",
              "as": "A4ii_1_1",
              "gu": "A4ii_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira talks to her mother as she was the one who sent her to the city to get a good education. Her mother sounds tired when she picks up the call.",
              "hi": "मीरा अपनी मां से बात करती है क्योंकि वही थीं जिन्होंने उसे अच्छी शिक्षा पाने के लिए शहर में भेजा था। जब वह कॉल उठाती है तो उसे अपनी मां थकी हुई सी लगती हैं।",
              "ka": "ಅಮ್ಮ ತನ್ನನ್ನು ಚೆನ್ನಾಗಿ ಓದಿಸಲು ನಗರಕ್ಕೆ ಕಳುಹಿಸಿದಳು ಅಂತ ಮೀರಾ ಅಮ್ಮನ ಬಳಿ ಇದರ ಬಗ್ಗೆ ಮಾತನಾಡ್ತಾಳೆ. ಅವಳ ಕರೆಯನ್ನು ತೆಗೆದುಕೊಂಡಾಗ ಅವಳ ತಾಯಿ ಸುಸ್ತಾಗಿರುವಂತೆ ಕಾಣುತ್ತಾಳೆ.",
              "te": "తనని బాగా చదవడానికి సిటీకి పంపమని అడగడానికి మీరా తన తల్లితో మాట్లాడాలని అనుకుంటుంది. ఆమె కాల్ తీయగానే ఆమె తల్లి అలసిపోయినట్లు అనిపిస్తుంది.",
              "or": "ମୀରା ଏକ ଭଲ ଶିକ୍ଷା ପାଇବା ପାଇଁ ତାଙ୍କୁ ସହରକୁ ପଠାଇଥିବାରୁ ସେ ତାଙ୍କ ମା\n  ସହିତ କଥାବାର୍ତ୍ତା କରନ୍ତି | ଯେତେବେଳେ ସେ କଲ ଉଠାନ୍ତି ସେତେବେଳେ ତାଙ୍କ ମା କ୍ଲାନ୍ତ ଲାଗନ୍ତି |",
              "as": "মীৰাই তাইৰ মাকৰ সৈতে কথা পাতিছিল কিয়নো মাকেই তাইক ভাল শিক্ষা এটা পাবলৈ চহৰলৈ পঠিয়াইছিল। মাকৰ সৈতে কথা পাতিবলৈ ফোনকলটো ধৰোঁতে মাকক তাই বৰ ভাগৰুৱা হৈ থকা যেন অনুমান কৰিলে।",
              "gu": "મીરા તેની માતા સાથે વાત કરે છે કારણ કે તે છે જને સારું શિક્ષણ મેળવવા શહેરમાં મોકલી હતી. જ્યારે તે કોલ ઉપાડે છે ત્યારે તેની માતા થાકેલી લાગે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4ii_1_2//",
              "hi": "A4ii_1_2//",
              "ka": "A4ii_1_2//",
              "te": "A4ii_1_2//",
              "or": "A4ii_1_2//",
              "as": "A4ii_1_2//",
              "gu": "A4ii_1_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mother",
              "hi": "मां",
              "ka": "ಅಮ್ಮ",
              "te": "తల్లి",
              "or": "ମା",
              "as": "মাক",
              "gu": "માતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Mother",
              "hi": "मां",
              "ka": "ಅಮ್ಮ",
              "te": "తల్లి",
              "or": "ମା",
              "as": "মাক",
              "gu": "માતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Why do you sound so exhausted, Ma?",
              "hi": "आप इतनी थकी हुई क्यों लग रही हैं, माँ ?",
              "ka": "ನೀವು ಯಾಕೆ ತುಂಬಾ ದಣಿದಿದ್ದೀರಿ ಅಂತ ಅನ್ನಿಸ್ತಿದೆ, ಅಮ್ಮ?",
              "te": "ఎందుకలా అలసిపోయావు అమ్మా?",
              "or": "ତୁମେ ଏତେ କ୍ଳାନ୍ତ କାହିଁକି ଶବ୍ଦ କରୁଛ, ମା?",
              "as": "তোমাক কিয় ইমান ভাগৰুৱা যেন লাগিছে মা?",
              "gu": "તું આટલી થાકેલી કેમ લાગે છે, મા?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I have been working overtime for this week, Mira. We usually get a bonus if we put in a total of 5 hours more every week.",
              "hi": "मैं इस सप्ताह के लिए ओवरटाइम काम कर रही हूं, मीरा। यदि हम हर सप्ताह कुल 5 घंटे अधिक काम करते हैं तो हमें सामान्यतः बोनस मिलता है।",
              "ka": "ನಾನು ಈ ವಾರ ಓವರ್‌ಟೈಮ್ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೇನೆ ಮೀರಾ. ನಾವು ಪ್ರತಿ ವಾರ ಒಟ್ಟು 5 ಗಂಟೆಗಳಧ್ತು ಒವರ್ಟೈಮ್ ಮಾಡಿದರೆ ನಮಗೆ  ಸಾಮಾನ್ಯವಾಗಿ ಬೋನಸ್ ನೀಡಲಾಗತ್ತೆ.",
              "te": "నేను ఈ వారం ఓవర్ టైం పని చేస్తున్నాను, మీరా. మనం మామూలుగా ప్రతి వారం మొత్తం 5 గంటలు ఎక్కువ పని చేస్తే బోనస్ వస్తుంది.",
              "or": "ମୀରା, ମୁଁ ଏହି ସପ୍ତାହ ପାଇଁ ଅଧିକ ସମୟ କାମ କରୁଛି | ଯଦି ଆମେ ପ୍ରତି ସପ୍ତାହରେ\n  ସମୁଦାୟ ୫ ଘଣ୍ଟା ଅଧିକ ରଖୁ ତେବେ ଆମେ ସାଧାରଣତ ଏକ ବୋନସ ପାଇଥାଉ |",
              "as": "মই এই সপ্তাহৰ বাবে অভাৰটাইম কাম কৰি আছো, মীৰা। যদি আমি প্ৰতি সপ্তাহত মুঠ ৫ ঘণ্টা বেছিকৈ কাম কৰিব পাৰোঁ, তেন্তে আমি সাধাৰণতে এটা বোনাচ পাওঁ।",
              "gu": "હું આ અઠવાડિયાથી વધારાનો સમય કામ કરી રહી છું, મીરા. જો અમે દર અઠવાડિયે કુલ 5 કલાક વધુ મૂકીએ તો અમને સામાન્ય રીતે બોનસ મળે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "But Ma, how long can you do this? It isn't good for your health.",
              "hi": "लेकिन माँ, आप यह कब तक कर सकती हैं? यह आपके स्वास्थ्य के लिए अच्छा नहीं है।",
              "ka": "ಆದರೆ ಅಮ್ಮ, ನೀವು ಇದನ್ನು ಎಷ್ಟು ದಿನ ಮಾಡಬಹುದು? ಇದು ನಿಮ್ಮ ಆರೋಗ್ಯಕ್ಕೆ ಒಳ್ಳೆಯದಲ್ಲ",
              "te": "కానీ అమ్మ, నువ్వు దీన్ని ఎంతకాలం చేయగలరు? ఇది నీ ఆరోగ్యానికి మంచిది కాదు.",
              "or": "କିନ୍ତୁ ମା, ଆପଣ କେତେ ଦିନ ପର୍ଯ୍ୟନ୍ତ ଏହା କରିପାରିବେ? ଏହା ଆପଣଙ୍କ ସ୍ୱାସ୍ଥ୍ୟ ପାଇଁ ଭଲ ନୁହେଁ |",
              "as": "কিন্তু মা, কিমান দিন এনেকৈ কাম কৰি থাকিবা আৰু? এইটো কিন্তু তোমাৰ স্বাস্থ্যৰ বাবে ভাল কথা নহয়।",
              "gu": "પણ મા, તું ક્યાં સુધી આ કરી શકીશ? તે તારા સ્વાસ્થ્ય માટે સારું નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "Don't worry about me, Mira, you tell me why you called.",
              "hi": "मेरे बारे में चिंता मत करो, मीरा, तुम मुझे बताओ कि तुमने क्यों कॉल किया।",
              "ka": "ನನ್ನ ಬಗ್ಗೆ ಚಿಂತಿಸಬೇಡ ಮೀರಾ, ನೀನು ಯಾಕೆ ಕರೆ ಮಾಡಿದೆ ಅಂತ ಹೇಳು.",
              "te": "నా గురించి కంగారుపడకు మీరా, నువ్వు ఎందుకు ఫోన్ చేశావో చెప్పు.",
              "or": "ମୀରା, ମୋ ବିଷୟରେ ଚିନ୍ତା କର ନାହିଁ, ତୁମେ ମୋତେ କାହିଁକି ଡାକିଲ ତାହା କୁହ |",
              "as": "মোৰ বিষয়ে চিন্তা কৰি নাথাকিবা, মীৰা, তুমি মোক কিয় ফোন কৰিছিলা কোৱাচোন।",
              "gu": "મારી ચિંતા ના કર, મીરા, તું મને કહે કે તેં કેમ ફોન કર્યો."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4ii_1_3////",
              "hi": "A4ii_1_3////",
              "ka": "A4ii_1_3////",
              "te": "A4ii_1_3////",
              "or": "A4ii_1_3////",
              "as": "A4ii_1_3////",
              "gu": "A4ii_1_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Ma, I have been trying to decide what department to choose in engineering. And I want to do mechanical engineering.",
              "hi": "माँ, मैं यह निर्णय लेने की कोशिश कर रही  हूं कि इंजीनियरिंग में किस विभाग को चुनना है। और मैं मैकेनिकल इंजीनियरिंग करना चाहती हूं।",
              "ka": "ಅಮ್ಮ, ನಾನು ಎಂಜಿನಿಯರಿಂಗ್‌ನಲ್ಲಿ ಯಾವ ವಿಭಾಗವನ್ನು ಆಯ್ಕೆ ಮಾಡಬೇಕೆಂದು ನಿರ್ಧಾರ ಮಾಡಕ್ಕೆ  ಪ್ರಯತ್ನ ಮಾಡ್ತಾ ಇದ್ದೀನಿ ಮತ್ತು ನಾನು ಮೆಕ್ಯಾನಿಕಲ್ ಎಂಜಿನಿಯರಿಂಗ್ ಮಾಡಲು ಬಯಸುತ್ತೇನೆ.",
              "te": "అమ్మ, నేను ఇంజినీరింగ్‌లో ఏ డిపార్ట్ మెంట్ లో చేరాలో నిర్ణయించుకోవడానికి ప్రయత్నిస్తున్నాను. మరియు నేను మెకానికల్ ఇంజనీరింగ్ చేయాలనుకుంటున్నాను.",
              "or": "ମା, ମୁଁ ଇଞ୍ଜିନିୟରିଂରେ କେଉଁ ବିଭାଗ ବାଛିବି ତାହା ସ୍ଥିର କରିବାକୁ ଚେଷ୍ଟା କରୁଛି | \n ଏବଂ ମୁଁ ମେକାନିକାଲ୍ ଇଞ୍ଜିନିୟରିଂ କରିବାକୁ ଚାହୁଁଛି |",
              "as": "মা, মই ইঞ্জিনিয়াৰিঙত কি বিভাগ বাছনি কৰিব লাগে সিদ্ধান্ত ল'বলৈ চেষ্টা কৰি আছোঁ। আৰু মোৰ মেকানিকেল ইঞ্জিনিয়াৰিং কৰিবলৈ ইচ্ছা আছে।",
              "gu": "મા, હું એન્જીનિયરીંગમાં કયો વિભાગ પસંદ કરવો તે નક્કી કરવાનો પ્રયાસ કરી રહી છું. અને મારે મિકેનિકલ એન્જિનિયરિંગ કરવું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Oh, perfect Mira, I'm happy that you sound confident about this decision. But tell me, what kind of jobs could you expect after mechanical engineering?",
              "hi": "ओह, बिलकुल सही मीरा, मुझे खुशी है कि तुम इस निर्णय के बारे में आश्वस्त दिखाई दे रही हो। लेकिन मुझे बताओ, मैकेनिकल इंजीनियरिंग के बाद तुम किस तरह की नौकरियों की उम्मीद कर सकती हो?",
              "ka": "ಓಹ್, ಸರಿಯಾದ ನಿರ್ಧಾರ ಮೀರಾ, ಈ ನಿರ್ಧಾರದ ಬಗ್ಗೆ ನೀನು ವಿಶ್ವಾಸ ಹೊಂದಿದ್ದೀ ಎಂದು ನನಗೆ ಸಂತೋಷ ಆಗಿದೆ. ಆದರೆ ಹೇಳು, ಮೆಕ್ಯಾನಿಕಲ್ ಎಂಜಿನಿಯರಿಂಗ್ ನಂತರ ನೀನು ಯಾವ ರೀತಿಯ ಉದ್ಯೋಗಗಳನ್ನು ನಿರೀಕ್ಷಿಸಬಹುದು?",
              "te": "ఓహ్, సరైనది మీరా, నువ్వు ఈ నిర్ణయం పట్ల నమ్మకంగా ఉన్నందుకు నేను సంతోషిస్తున్నాను. అయితే చెప్పు, మెకానికల్ ఇంజనీరింగ్ తర్వాత నువ్వు ఎలాంటి ఉద్యోగాలు ఆశిస్తున్నావు?",
              "or": "ଓହ, ଠିକ ମୀରା, ମୁଁ ଖୁସି ଯେ ତୁମେ ଏହି ନିଷ୍ପତ୍ତି ବିଷୟରେ ଆତ୍ମବିଶ୍ୱାସୀ | କିନ୍ତୁ ମୋତେ କୁହ, ମେକାନିକାଲ୍ ଇଞ୍ଜିନିୟରିଂ ପରେ ତୁମେ କେଉଁ ପ୍ରକାରର ଚାକିରି ଆଶା କରିପାର?",
              "as": "ওহ, বৰ ভাল কথা মীৰা। তুমি যে এই সিদ্ধান্তটো ল’ব পৰাকৈ আত্মবিশ্বাসী হৈছা মই বৰ ভাল পাইছোঁ। কিন্তু মেকানিকেল ইঞ্জিনিয়াৰিঙৰ পিছত তুমি কেনে ধৰণৰ চাকৰি আশা কৰিব পাৰা মোক বুজাই দিয়াচোন।",
              "gu": "ઓહ, જોરદાર મીરા, હું ખુશ છું કે તું આ નિર્ણય વિશે આત્મવિશ્વાસ ધરાવે છે. પણ મને બતાવ કે મિકેનિકલ એન્જિનિયરિંગ પછી તું કેવા પ્રકારની નોકરીઓની અપેક્ષા રાખી શકે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I could work in any large factory that works with machines and mechanical tools or any large manufacturer that uses mechanical appliances. They pay very well, and there are many jobs available.",
              "hi": "मैं किसी भी बड़े कारखाने में काम कर सकती हूं जो मशीनों और मैकेनिकल उपकरण का उपयोग करते हैं या किसी भी बड़े निर्माता के साथ काम कर सकती हूँ जो यांत्रिक उपकरणों का उपयोग करता है। वे बहुत अच्छा वेतन देते हैं, और उसमें अनेक नौकरियां उपलब्ध हैं।",
              "ka": "ಯಂತ್ರಗಳು ಮತ್ತು ಯಾಂತ್ರಿಕ ಉಪಕರಣಗಳೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುವ ಯಾವುದೇ ದೊಡ್ಡ ಕಾರ್ಖಾನೆನಲ್ಲಿ ಅಥವಾ ಯಾಂತ್ರಿಕ ಉಪಕರಣ ಬಳಸೋ ಯಾವುದೇ ದೊಡ್ಡ ತಯಾರಕರಲ್ಲಿ ನಾನು ಕೆಲಸ ಮಾಡಬಹುದು. ಅವರು ಚೆನ್ನಾಗಿ ಸಂಬಳ ನೀಡ್ತಾರೆ ಮತ್ತು ಅನೇಕ ಉದ್ಯೋಗಗಳು ಲಭ್ಯ ಇವೆ.",
              "te": "నేను యంత్రాలు మరియు మెకానికల్ ఉపకరణాలతో పనిచేసే ఏదైనా పెద్ద ఫ్యాక్టరీలో లేదా మెకానికల్ ఉపకరణాలను ఉపయోగించే ఏదైనా పెద్ద తయారీదారులో పని చేయగలను. వారు జీతం ఎక్కువ ఇస్తారు మరియు చాలా ఉద్యోగాలు అందుబాటులో ఉన్నాయి.",
              "or": "ମୁଁ ଯେକୌଣସି ବୃହତ କାରଖାନାରେ କାମ କରିପାରିବି ଯାହା ମେସିନ୍ ଏବଂ ଯାନ୍ତ୍ରିକ\n  ଉପକରଣ କିମ୍ବା କୌଣସି ବୃହତ ଉତ୍ପାଦକ ସହିତ ଯାନ୍ତ୍ରିକ ଉପକରଣ ବ୍ୟବହାର କରେ | ସେମାନେ ବହୁତ ଭଲ ଦେୟ ଦିଅନ୍ତି, ଏବଂ ସେଠାରେ ଅନେକ ଚାକିରି ଉପଲବ୍ଧ |",
              "as": "মই যন্ত্ৰ আৰু যান্ত্ৰিক সঁজুলিৰ সৈতে কাম কৰা যিকোনো ডাঙৰ কাৰখানা বা যান্ত্ৰিক সঁজুলি ব্যৱহাৰ কৰা বা নিৰ্মাণ কৰা যিকোনো কোম্পানীত কাম কৰিব পাৰোঁ। তেওঁলোকে খুব ভাল দৰমহা দিয়ে। এনেকুৱা আৰু বহুতো চাকৰি আছে।",
              "gu": "હું કોઈપણ મોટા કારખાનામાં કામ કરી શકું છું જે મશીનો અને યાંત્રિક સાધનો સાથે કામ કરે છે અથવા કોઈપણ મોટા ઉત્પાદક કે જે યાંત્રિક ઉપકરણોનો ઉપયોગ કરે છે. તેઓ ખૂબ જ સારો પગાર ચૂકવે છે, અને ત્યાં ઘણી નોકરીઓ ઉપલબ્ધ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Work in a factory? Mira, I sent you to the city to get a respectable job that you would be happy doing. The factory floor is no place for a girl with your potential. These spaces aren't very kind to us. You have seen how difficult my life has been.",
              "hi": "एक कारखाने में काम ? मीरा, मैंने तुमको एक सम्मानजनक नौकरी पाने के लिए शहर भेजा जिसे कर के तुम खुश होगी। कारखाने में काम करना तुम्हारी जैसी क्षमता वाली लड़की के लिए सही नहीं है। ये स्थान हमारे लिए बहुत ठीक नहीं हैं। तुमने देखा है कि मेरा जीवन कितना कठिन रहा है।",
              "ka": "ಕಾರ್ಖಾನೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದೇ? ಮೀರಾ, ನಾನು ನಿನ್ನನ್ನು ನಗರಕ್ಕೆ ಕಳಿಸಿದ್ದು ಗೌರವಾನ್ವಿತ ಕೆಲಸ ಪಡೆದು ನೀನು ಸಂತೋಷವಾಗಿರೋದಕ್ಕೆ. ಫ್ಯಾಕ್ಟರಿಯು ನಿನ್ನ ಸಾಮರ್ಥ್ಯವನ್ನು ಹೊಂದಿರುವ ಹುಡುಗಿಗೆ ಸೂಕ್ತವಾದ ಸ್ಥಳವಲ್ಲ. ಈ ಜಾಗಗಳು ನಮ್ಮ ಬಗ್ಗೆ ದಯೆಯನ್ನು ಹೊಂದಿರುವುದಿಲ್ಲ.. ನನ್ನ ಜೀವನ ಎಷ್ಟು ಕಷ್ಟಕರವಾಗಿತ್ತು ಅನ್ನೋದನ್ನ ನೀನು ನೋಡಿದ್ದೀಯಾ.",
              "te": "ఫ్యాక్టరీలో పని చేస్తావా? మీరా, నువ్వు సంతోషంగా పని చేసే మంచి ఉద్యోగం కోసం నేను నిన్ను నగరానికి పంపాను. ఫ్యాక్టరీ ఫ్లోర్ నీ సామర్థ్యాన్ని కలిగి ఉన్న అమ్మాయికి సరైనది కాదు. ఈ ఉద్యోగాలు నాకు మంచివని అనిపించడం లేదు. నా జీవితం ఎంత కష్టతరంగా ఉందో నువ్వు చూశావు.",
              "or": "ଏକ କାରଖାନାରେ କାମ ? ମୀରା, ମୁଁ ତୁମକୁ ଏକ ସମ୍ମାନଜନକ ଚାକିରି ପାଇବା ପାଇଁ\n  ସହରକୁ ପଠାଇଥିଲି ଯାହା କୁ କରି ତୁମେ ଖୁସି ହେବ | ତୁମ ପରି ସାମର୍ଥ୍ୟ ଥିବା ଝିଅ ପାଇଁ କାରଖାନା ଚଟାଣ କୌଣସି ସ୍ଥାନ ନୁହେଁ | ଏହି ସ୍ଥାନଗୁଡିକ ଆମ ପାଇଁ ଅତ୍ୟନ୍ତ ଦୟାଳୁ ନୁହଁନ୍ତି | ତୁମେ ଦେଖିଛ ମୋ ଜୀବନ କେତେ କଷ୍ଟଦାୟକ |",
              "as": "তুমি কাৰখানাতহে কাম কৰিবা নেকি? মীৰা, মই তোমাক চহৰলৈ এটা সন্মানীয় চাকৰি পাবলৈহে পঠিয়াইছিলো যিটো কৰি তুমি সুখী হ'বা। তোমাৰ দৰে সম্ভাৱনা থকা ছোৱালী এজনীৰ বাবে কাৰখানাৰ মজিয়া একেবাৰে ভাল ঠাই নহয়। এই ঠাইবোৰত আমাৰ বাবে মৰম নাই। মোৰ জীৱনটো কিমান কঠিন আছিল তুমিচোন দেখিছাই।",
              "gu": "કારખાનામાં કામ કરીશ? મીરા, મેં તને એક સન્માનજનક નોકરી મેળવવા શહેરમાં મોકલી છે જે કરવાથી તું ખુશ થઇશ. કારખાનામાં તારી સંભવિતતા ધરાવતી છોકરી માટે કોઈ સ્થાન નથી. આ જગ્યાઓ આપણાં માટે બહુ સારું નથી. તે જોયું છે કે મારું જીવન કેટલું મુશ્કેલ હતું."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4ii_1_4//",
              "hi": "A4ii_1_4//",
              "ka": "A4ii_1_4//",
              "te": "A4ii_1_4//",
              "or": "A4ii_1_4//",
              "as": "A4ii_1_4//",
              "gu": "A4ii_1_4//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mother",
              "hi": "मां",
              "ka": "ಅಮ್ಮ",
              "te": "తల్లి",
              "or": "ମା",
              "as": "মাক",
              "gu": "માતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Ma, the kind of work I would be doing is different.",
              "hi": "माँ, जिस तरह का काम मैं कर रही हूं वह अलग है।",
              "ka": "ಅಮ್ಮಾ, ನಾನು ಅಲ್ಲಿ ಮಾಡುವ ಕೆಲಸವೇ ಬೇರೆ ಆಗಿರತ್ತೆ.",
              "te": "అమ్మా, నేను చేసే పని వేరు.",
              "or": "ମା, ମୁଁ ଯେଉଁ ପ୍ରକାର କାମ କରିବି ତାହା ଅଲଗା ଅଟେ |",
              "as": "মা, মই যি ধৰণৰ কাম কৰিম সেয়া বেলেগ।",
              "gu": "મા, હું જે પ્રકારનું કામ કરીશ તે અલગ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Please choose any other department. Like me, I don't want you getting a job in a factory",
              "hi": "कृपया कोई अन्य विभाग चुनो।  मैं नहीं चाहती कि तुमको मेरी तरह, एक कारखाने में नौकरी मिले ",
              "ka": "ದಯವಿಟ್ಟು ಬೇರೆ ಯಾವುದೇ ವಿಭಾಗವನ್ನು ಆಯ್ಕೆಮಾಡ್ಕೋ. ನಿನಗೆ ಫ್ಯಾಕ್ಟರಿಯಲ್ಲಿ ಕೆಲಸ ಸಿಗುವುದು ನನಗೆ ಇಷ್ಟವಿಲ್ಲ.",
              "te": "దయచేసి ఏదైనా వేరే డిపార్ట్ మెంట్ ఎంచుకోండి. నాలాగే నీకు ఫ్యాక్టరీలో ఉద్యోగం రావడం నాకు ఇష్టం లేదు",
              "or": "ଦୟାକରି ଅନ୍ୟ କୌଣସି ବିଭାଗ ବାଛନ୍ତୁ | ମୋ ପରି, ମୁଁ ଚାହେଁ ନାହିଁ ତୁମେ ଏକ କାରଖାନାରେ ଚାକିରି କର |",
              "as": "অনুগ্ৰহ কৰি তুমি আন বেলেগ যিকোনো এটা বিভাগ বাছনি কৰা। এটা কাৰখানাত চাকৰি কৰি তুমি মোৰ দৰে জীৱন এটা পোৱাটো মই কেতিয়াও নিবিছাৰোঁ।",
              "gu": "કૃપા કરીને કોઈપણ અન્ય વિભાગ પસંદ કર. મારી જેમ, હું નથી ઇચ્છતી કે તું કારખાનામાં નોકરી મેળવે"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4ii_1_5",
              "hi": "A4ii_1_5",
              "ka": "A4ii_1_5",
              "te": "A4ii_1_5",
              "or": "A4ii_1_5",
              "as": "A4ii_1_5",
              "gu": "A4ii_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is confused and discouraged. She doesn't know how to convince her mother. She also understands why her mother is against the idea of her working in a factory.",
              "hi": "मीरा दुविधा में है, और निराश है। वह नहीं जानती कि अपनी माँ को कैसे मनाए। वह यह भी समझती है कि क्यों उसकी मां कारखाने में उसके काम करने के विचार के विरुद्ध हैं।",
              "ka": "ಮೀರಾ ಗೊಂದಲಕ್ಕೊಳಗಾಗುತ್ತಾಳೆ ಹಾಗೂ ನಿರುತ್ಸಾಹಗೊಳ್ಳುತ್ತಾಳೆ ತಾಯಿಯನ್ನು ಹೇಗೆ ಒಪ್ಪಿಸಬೇಕು ಅಂತ ಅವಳಿಗೆ ತಿಳಿದಿಲ್ಲ. ಅವಳು ಕಾರ್ಖಾನೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಕಲ್ಪನೆಗೆ ತನ್ನ ತಾಯಿ ಏಕೆ ವಿರುದ್ಧವಾಗಿದ್ದಾರೆನ್ನುವುದನ್ನು ಕೂಡ ಅವಳು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದಾಳೆ.",
              "te": "మీరా అయోమయంలో పడి నిరుత్సాహపడింది. తల్లిని ఎలా ఒప్పించాలో ఆమెకు తెలియదు. ఆమె ఫ్యాక్టరీలో పనిచేసే ఆలోచనని తన తల్లి ఎందుకు కాదంటుందో కూడా ఆమెకు అర్థమైంది.",
              "or": "ମୀରା ଦ୍ୱନ୍ଦ୍ୱରେ ପଡ଼ି ନିରାଶ ହେଲେ | ସେ ନିଜ ମା’ଙ୍କୁ କିପରି ବିଶ୍ୱାସ କରାଇବେ\n  ଜାଣନ୍ତି ନାହିଁ | ସେ ମଧ୍ୟ ବୁଝନ୍ତି ଯେ ତାଙ୍କ ମା ଏକ କାରଖାନାରେ କାମ କରିବାର \n ଧାରଣା ବିରୁଦ୍ଧରେ କାହିଁକି?",
              "as": "মীৰা বিভ্ৰান্ত আৰু নিৰুৎসাহিত হৈছে। তাই তাইৰ মাকক কেনেকৈ সৈমান কৰিব লাগে নাজানে। তাইৰ মাক কিয় তাই এটা কাৰখানাত কাম কৰাৰ ধাৰণাটোৰ বিৰোধী হৈছে, সেইকথাও তাই বুজি পায়।",
              "gu": "મીરા મૂંઝવણમાં છે અને નિરાશ છે. તેને તેની માતાને કેવી રીતે સમજાવવી તે ખબર નથી. તે એ પણ સમજે છે કે શા માટે તેની માતા તેને કારખાનામાં કામ કરવાના વિચારની વિરુદ્ધ છે."
            }
          }
        ]
      },
      "scene12": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_1_1",
              "hi": "A5_1_1",
              "ka": "A5_1_1",
              "te": "A5_1_1",
              "or": "A5_1_1",
              "as": "A5_1_1",
              "gu": "A5_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira attempts the entrance exams for engineering. She is confident that she will get good scores.",
              "hi": "मीरा इंजीनियरिंग के लिए प्रवेश परीक्षा देती है। उसे विश्वास है कि उसे अच्छे नंबर मिलेंगे।",
              "ka": "ಮೀರಾ ಇಂಜಿನಿಯರಿಂಗ್ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಗೆ ಪ್ರಯತ್ನಿಸುತ್ತಾಳೆ. ಉತ್ತಮ ಅಂಕ ಗಳಿಸುವ ವಿಶ್ವಾಸ ಆವಳಿಗಿದೆ.",
              "te": "మీరా ఇంజనీరింగ్ ప్రవేశ పరీక్షల కోసం ప్రయత్నిస్తుంది. మంచి స్కోర్లు సాధిస్తామన్న నమ్మకంతో ఉంది.",
              "or": "ମୀରା ଇଞ୍ଜିନିୟରିଂ ପାଇଁ ପ୍ରବେଶିକା ପରୀକ୍ଷା କରିବାକୁ ଚେଷ୍ଟା କରନ୍ତି | ସେ ନିଶ୍ଚିତ\n  ଯେ ଭଲ ସ୍କୋର ପାଇବେ |",
              "as": "মীৰাই অভিযান্ত্ৰিকৰ প্ৰৱেশ পৰীক্ষাৰ বাবে চেষ্টা কৰিছে। তাই আত্মবিশ্বাসী যে তাই ভাল নম্বৰ পাব।",
              "gu": "મીરા એન્જિનિયરિંગ માટે પ્રવેશ પરીક્ષાનો પ્રયાસ કરે છે. તેને વિશ્વાસ છે કે તે સારા ગુણ મેળવશે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_1_2",
              "hi": "A5_1_2",
              "ka": "A5_1_2",
              "te": "A5_1_2",
              "or": "A5_1_2",
              "as": "A5_1_2",
              "gu": "A5_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "When she applies to the various colleges, she realises that the different departments' cut-off scores are quite high, as most students have performed extremely well.",
              "hi": "जब वह विभिन्न कॉलेज में आवेदन करती है, तो उसे पता चलता है कि अलग अलग विभागों के कट ऑफ स्कोर काफी अधिक हैं, क्योंकि अधिकांश छात्रों ने बेहद अच्छी तरह से प्रदर्शन किया है।",
              "ka": "ಅವಳು ವಿವಿಧ ಕಾಲೇಜುಗಳಿಗೆ ಅರ್ಜಿ ಹಾಕಿದಾಗ, ಹೆಚ್ಚಿನ ವಿದ್ಯಾರ್ಥಿಗಳು ಅತ್ಯುತ್ತಮವಾಗಿ ಅಂಕವನ್ನು ಗಳಿಸಿರುವುದರಿಂದ ವಿವಿಧ ವಿಭಾಗಗಳ ಕಟ್-ಆಫ್ ಸ್ಕೋರ್‌ಗಳು ಸಾಕಷ್ಟು ಹೆಚ್ಚಿವೆ ಎಂದು ಅವಳು ಅರಿತುಕೊಂಡಳು.",
              "te": "ఆమె వివిధ కళాశాలలకు అప్లై చేసినప్పుడు, చాలా మంది విద్యార్థులు చాలా బాగా పనిచేసినందు వలన వివిధ విభాగాల కట్-ఆఫ్ స్కోర్లు చాలా ఎక్కువగా ఉన్నాయని ఆమెకి తెలుస్తుంది.",
              "or": "ଯେତେବେଳେ ସେ ବିଭିନ୍ନ କଲେଜରେ ଆବେଦନ କରନ୍ତି, ସେତେବେଳେ ସେ \n ଅନୁଭବ କରନ୍ତି ଯେ ବିଭିନ୍ନ ବିଭାଗର କଟ୍ ଅଫ୍ ସ୍କୋର ବହୁତ ଅଧିକ, କାରଣ ଅଧିକାଂଶ ଛାତ୍ର ଅତ୍ୟନ୍ତ ଭଲ ପ୍ରଦର୍ଶନ କରିଛନ୍ତି |",
              "as": "তাই বিভিন্ন মহাবিদ্যালয়ত আবেদন কৰি থাকোঁতে, তাই উপলব্ধি কৰিছে যে বিভিন্ন বিভাগৰ কাট-অফ নম্বৰ যথেষ্ট বেছি, কিয়নো বেছিভাগ শিক্ষাৰ্থীয়ে অত্যন্ত ভাল প্ৰদৰ্শন কৰিছে।",
              "gu": "જ્યારે તે વિવિધ કોલેજોમાં અરજી કરે છે, ત્યારે તેને ખ્યાલ આવે છે કે વિવિધ વિભાગોના કટ-ઓફ ગુણ ખૂબ ઊંચા છે, કારણ કે મોટાભાગના વિદ્યાર્થીઓએ ખૂબ સારું પ્રદર્શન કર્યું છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_1_3_apply",
              "hi": "A5_1_3_अप्लाइ ",
              "ka": "A5_1_3_apply",
              "te": "A5_1_3_apply",
              "or": "A5_1_3_apply",
              "as": "A5_1_3_ আৱেদন",
              "gu": "A5_1_3_લાગુ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to apply to different colleges.",
              "hi": "विभिन्न कॉलेजों में आवेदन करने के लिए क्लिक करे।",
              "ka": "ವಿವಿಧ ಕಾಲೇಜುಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "వివిధ కళాశాలలకు అప్లై చేయడానికి క్లిక్ చేయండి.",
              "or": "ବିଭିନ୍ନ କଲେଜରେ ଆବେଦନ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "বিভিন্ন মহাবিদ্যালয়ত আবেদন কৰিবলৈ ক্লিক কৰক।",
              "gu": "વિવિધ કોલેજોમાં અરજી કરવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "IID Guwahati",
              "hi": "आईआईडी गुवाहाटी",
              "ka": "ಐ‌ಐ‌ಡಿ ಗುವಹಟಿ",
              "te": "IID గౌహతి",
              "or": "IID Guwahati",
              "as": "IID গুৱাহাটী",
              "gu": "IID ગુવાહાટી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "New Assam Engineering University",
              "hi": "न्यू असम इंजीनियरिंग यूनिवर्सिटी",
              "ka": "ನ್ಯೂ ಅಸ್ಸಾಂ ಎಂಜಿನಿಯರಿಂಗ್ ಯುನಿವರ್ಸಿಟಿ",
              "te": "న్యూ అస్సాం ఇంజనీరింగ్ విశ్వవిద్యాలయం",
              "or": "ନ୍ନ୍ୟୁ ଆସାମ ଇଞ୍ଜିନିୟରିଂ ବିଶ୍ୱବିଦ୍ୟାଳୟ |",
              "as": "নতুন অসম ইঞ্জিনিয়াৰিং বিশ্ববিদ্যালয়",
              "gu": "નવી આસામ એન્જિનિયરિંગ યુનિવર્સિટી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "NIMD Technology College",
              "hi": "एनआईएमडी टेक्नोलॉजी कॉलेज",
              "ka": "ಎನ್‌ಐ‌ಎಮ್‌ಡಿ ಟೆಕ್ನಾಲಜಿ ಕಾಲೇಜ್",
              "te": "NIMD టెక్నాలజీ కళాశాల",
              "or": "NIMD ଟେକ୍ନୋଲୋଜି କଲେଜ |",
              "as": "NIMD প্ৰযুক্তি মহাবিদ্যালয়",
              "gu": "NIMD ટેકનોલોજી કોલેજ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "MITE College",
              "hi": "एमआइईटी कॉलेज",
              "ka": "ಎಮ್‌ಐ‌ಟಿ‌ಈ ಕಾಲೇಜ್",
              "te": "MITE కళాశాల",
              "or": "MITE କଲେଜ",
              "as": "MITE মহাবিদ্যালয়",
              "gu": "MITE કોલેજ"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_1_4_reveal",
              "hi": "A5_1_4_रिवील ",
              "ka": "A5_1_4_reveal",
              "te": "A5_1_4_reveal",
              "or": "A5_1_4_reveal",
              "as": "A5_1_4_ প্ৰকাশ কৰক",
              "gu": "A5_1_4_લાગુ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "The following week Mira gets responses from all colleges she has applied to.\nClick to reveal her results.",
              "hi": "अगले सप्ताह मीरा को उन सभी कॉलेजों से प्रतिक्रिया मिलती है जिसमें  उसनेआवेदन किया है।\nउसके परिणाम देखने के लिए क्लिक करे।",
              "ka": "ಮುಂದಿನ ವಾರದಲ್ಲಿ  ಮೀರಾ  ತಾನು ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ ಎಲ್ಲಾ ಕಾಲೇಜುಗಳಿಂದ ಪ್ರತಿಕ್ರಿಯೆಗಳನ್ನು ಪಡೆಯುತ್ತಾಳೆ. ಅವಳ ಫಲಿತಾಂಶಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "వారం తరువాత మీరాకి, ఆమె అప్లై చేసుకున్న అన్ని కళాశాలల నుండి జవాబు వచ్చింది. \n \n ఆమె ఫలితాలను చూడటానికి క్లిక్ చేయండి.",
              "or": "ପରବର୍ତ୍ତୀ ସପ୍ତାହରେ ମୀରା ଆବେଦନ କରିଥିବା ସମସ୍ତ କଲେଜରୁ ପ୍ରତିକ୍ରିୟା ପାଇଥାଏ।\n ତାର ଫଳାଫଳ ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ। \"",
              "as": "পৰৱৰ্তী সপ্তাহত মীৰাই তাই আবেদন কৰা সকলো মহাবিদ্যালয়ৰ পৰা সঁহাৰি লাভ কৰে।",
              "gu": "પછીના અઠવાડિયે મીરાને તેને અરજી કરેલી તમામ કોલેજોમાંથી પ્રતિસાદ મળે છે. તેના પરિણામો જાહેર કરવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "IIID, Guwahati",
              "hi": "आइआइआइडी, गुवाहाटी",
              "ka": "ಐ‌ಐಐ‌ಡಿ ಗುವಹಟಿ",
              "te": "IIID, గౌహతి",
              "or": "IIID, Guwahati",
              "as": "IIID, গুৱাহাটী",
              "gu": "IIID, ગુવાહાટી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "New Assam Engineering University, Guwahati",
              "hi": "न्यू असम इंजीनियरिंग यूनिवर्सिटी, गुवाहाटी",
              "ka": "ನ್ಯೂ ಅಸ್ಸಾಂ ಎಂಜಿನಿಯರಿಂಗ್ ಯುನಿವರ್ಸಿಟಿ",
              "te": "న్యూ అస్సాం ఇంజనీరింగ్ విశ్వవిద్యాలయం, గౌహతి",
              "or": "ନ୍ନ୍ୟୁ ଆସାମ ଇଞ୍ଜିନିୟରିଂ ବିଶ୍ୱବିଦ୍ୟାଳୟ |",
              "as": "নতুন অসম ইঞ্জিনিয়াৰিং বিশ্ববিদ্যালয়",
              "gu": "ન્યૂ આસામ એન્જિનિયરિંગ યુનિવર્સિટી, ગુવાહાટી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "NIMD Technology College",
              "hi": "एनआईएमडी टेक्नोलॉजी कॉलेज",
              "ka": "ಎನ್‌ಐ‌ಎಮ್‌ಡಿ ಟೆಕ್ನಾಲಜಿ ಕಾಲೇಜ್",
              "te": "NIMD టెక్నాలజీ కళాశాల",
              "or": "NIMD ଟେକ୍ନୋଲୋଜି କଲେଜ |",
              "as": "NIMD প্ৰযুক্তি মহাবিদ্যালয়",
              "gu": "NIMD ટેકનોલોજી કોલેજ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "MITE College",
              "hi": "एमआइटीई कॉलेज",
              "ka": "ಎಮ್‌ಐ‌ಟಿ‌ಈ ಕಾಲೇಜ್",
              "te": "MITE కళాశాల",
              "or": "MITE କଲେଜ",
              "as": "MITE মহাবিদ্যালয়",
              "gu": "MITE કોલેજ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Rejected due to low rank",
              "hi": "कम रैंक के कारण अस्वीकृत",
              "ka": "ಕಡಿಮೆ ಶ್ರೇಣಿಯ ಕಾರಣದಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ",
              "te": "తక్కువ ర్యాంక్ కారణంగా సెలెక్ట్ అవ్వలేదు",
              "or": "ନିମ୍ନ ମାନ୍ୟତା ହେତୁ ପ୍ରତ୍ୟାଖ୍ୟାନ |",
              "as": "নিম্ন স্থানৰ বাবে প্ৰত্যাখ্যান কৰা হৈছে",
              "gu": "નીચા ગુણને કારણે નામંજૂર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Eligible for SC reservation Quota, cleared cut off for mechanical engineering,",
              "hi": "एससी आरक्षण कोटा के योग्य, मैकेनिकल इंजीनियरिंग के लिए कट ऑफ उत्तीर्ण,",
              "ka": "SC ಮೀಸಲಾತಿ ಕೋಟಾಕ್ಕೆ ಅರ್ಹತೆ, ಮೆಕ್ಯಾನಿಕಲ್ ಇಂಜಿನಿಯರಿಂಗ್‌ಗೆ ಕಟ್ ಆಫ್ ತೆರವುಗೊಳಿಸಲಾಗಿದೆ.",
              "te": "SC రిజర్వేషన్ కోటాకు అర్హులు, మెకానికల్ ఇంజినీరింగ్ కోసం కట్ ఆఫ్ క్లియర్ చేయబడింది,",
              "or": "ଏସସି ସଂରକ୍ଷଣ କୋଟା ପାଇଁ ଯୋଗ୍ୟ, ମେକାନିକାଲ୍ ଇଞ୍ଜିନିୟରିଂ ପାଇଁ କଟ୍ ଅଫ୍,",
              "as": "অভিযান্ত্ৰিক",
              "gu": "SC અનામત ક્વોટા માટે પાત્ર, મિકેનિકલ એન્જિનિયરિંગ માટે કટ ઓફ ગુણ ,"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "Rejected due to low rank",
              "hi": "कम रैंक के कारण अस्वीकृत",
              "ka": "ಕಡಿಮೆ ಶ್ರೇಣಿಯ ಕಾರಣದಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ",
              "te": "తక్కువ ర్యాంక్ కారణంగా సెలెక్ట్ అవ్వలేదు",
              "or": "ନିମ୍ନ ମାନ୍ୟତା ହେତୁ ପ୍ରତ୍ୟାଖ୍ୟାନ",
              "as": "নিম্ন স্থানৰ বাবে প্ৰত্যাখ্যান কৰা হৈছে",
              "gu": "નીચા ગુણને કારણે નામંજૂર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "No reservation applicable, cleared the cut off for computer science",
              "hi": "कोई आरक्षण लागू नहीं, कंप्यूटर विज्ञान के लिए कट ऑफ उत्तीर्ण ",
              "ka": "ಯಾವುದೇ ಮೀಸಲಾತಿ ಅನ್ವಯಿಸುವುದಿಲ್ಲ, ಕಂಪ್ಯೂಟರ್ ವಿಜ್ಞಾನಕ್ಕೆ ಕಟ್ ಆಫ್ ಅನ್ನು ತೆರವುಗೊಳಿಸಲಾಗಿದೆ",
              "te": "రిజర్వేషన్ వర్తించదు, కంప్యూటర్ సైన్స్ కోసం కట్ ఆఫ్ క్లియర్ చేయబడింది",
              "or": "କୌଣସି ସଂରକ୍ଷଣ ପ୍ରଯୁଜ୍ୟ ନୁହେଁ, କମ୍ପ୍ୟୁଟର ବିଜ୍ଞାନ ପାଇଁ କଟ୍ ପା କରାଯାଇଛି |",
              "as": "কোনো সংৰক্ষণ প্ৰযোজ্য নহয়, কম্পিউটাৰ বিজ্ঞানৰ বাবে কাট অফ সম্পূৰ্ণ কৰিলে",
              "gu": "કોઈ આરક્ષણ લાગુ પડતું નથી, કોમ્પ્યુટર સાયન્સ માટેનો ચોખ્ખા બાદ કરેલા ગુણ"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_2_1",
              "hi": "A5_2_1",
              "ka": "A5_2_1",
              "te": "A5_2_1",
              "or": "A5_2_1",
              "as": "A5_2_1",
              "gu": "A5_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "With her rank and reservation quota, Mira is eligible for mechanical engineering in a government college. With her rank without the reservation quota, she is eligible for computer science in a private college.",
              "hi": "अपने रैंक और आरक्षण कोटा के साथ, मीरा एक सरकारी कॉलेज में मैकेनिकल इंजीनियरिंग में प्रवेश पाने के योग्य है। आरक्षण कोटा के बिना अपनी रैंक के साथ, वह एक निजी कॉलेज में कंप्यूटर विज्ञान के लिए योग्य है।",
              "ka": "ತನ್ನ ಶ್ರೇಣಿ ಮತ್ತು ಮೀಸಲಾತಿ ಕೋಟಾದೊಂದಿಗೆ, ಮೀರಾ ಸರ್ಕಾರಿ ಕಾಲೇಜಿನಲ್ಲಿ ಮೆಕ್ಯಾನಿಕಲ್ ಎಂಜಿನಿಯರಿಂಗ್‌ಗೆ ಅರ್ಹಳಾಗಿದ್ದಾಳೆ ಮೀಸಲಾತಿ ಕೋಟಾ ಇಲ್ಲದೆ ತನ್ನ ಶ್ರೇಣಿಯೊಂದಿಗೆ, ಅವಳು ಖಾಸಗಿ ಕಾಲೇಜಿನಲ್ಲಿ ಕಂಪ್ಯೂಟರ್ ಸೈನ್ಸ್‌ಗೆ ಅರ್ಹಳಾಗಿದ್ದಾಳೆ.",
              "te": "మీరా, ఆమె ర్యాంక్ మరియు రిజర్వేషన్ కోటాతో ప్రభుత్వ కళాశాలలో మెకానికల్ ఇంజనీరింగ్‌కు అర్హత సాధించింది. రిజర్వేషన్ కోటా లేకుండా ఆమె ర్యాంక్‌తో ఒక ప్రైవేట్ కళాశాలలో కంప్యూటర్ సైన్స్ కి అర్హత సాధించింది.",
              "or": "ତାଙ୍କ ପଦବୀ ଏବଂ ସଂରକ୍ଷଣ କୋଟା ସହିତ ମୀରା ଏକ ସରକାରୀ କଲେଜରେ \n ମେକାନିକାଲ୍ ଇଞ୍ଜିନିୟରିଂ ପାଇଁ ଯୋଗ୍ୟ ଅଟନ୍ତି | ସଂରକ୍ଷଣ କୋଟା ବିନା ତାଙ୍କ ପଦବୀ ସହିତ ସେ ଏକ ଘରୋଇ କଲେଜରେ କମ୍ପ୍ୟୁଟର ସାଇନ୍ସ ପାଇଁ ଯୋଗ୍ୟ ଅଟନ୍ତି |",
              "as": "তাইৰ স্থান আৰু সংৰক্ষণ কোটাৰ সৈতে, মীৰা এখন চৰকাৰী মহাবিদ্যালয়ত মেকানিকেল ইঞ্জিনিয়াৰিঙৰ বাবে যোগ্য। সংৰক্ষণ কোটা নোহোৱাকৈ তাইৰ স্থানৰ সৈতে, তাই এখন ব্যক্তিগত মহাবিদ্যালয়ত কম্পিউটাৰ বিজ্ঞানৰ বাবে যোগ্য।",
              "gu": "તેના નંબર અને આરક્ષણ ક્વોટા સાથે, મીરા સરકારી કોલેજમાં મિકેનિકલ એન્જિનિયરિંગ માટે લાયક છે. આરક્ષણ ક્વોટા વિના તેના નંબર સાથે, તે ખાનગી કોલેજમાં કમ્પ્યુટર સાયન્સ માટે પાત્ર છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_2_2",
              "hi": "A5_2_2",
              "ka": "A5_2_2",
              "te": "A5_2_2",
              "or": "A5_2_2",
              "as": "A5_2_2",
              "gu": "A5_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is happy that she got into the colleges she was looking for. However, after the talk with her mother, several thoughts are going through her head.",
              "hi": "मीरा खुश है कि वह उस कॉलेज में गई जिसे वह ढूंढ रही थीं। हालांकि, अपनी मां के साथ बात करने के बाद, उसके दिमाग में अनेक विचार आ रहे हैं।",
              "ka": "ಮೀರಾ ತಾನು ಹುಡುಕುತ್ತಿದ್ದ ಕಾಲೇಜುಗಳಿಗೆ ಪ್ರವೇಶ ದೊರಕಿರುವುದರಿಂದ  ಸಂತೋಷದಿಂದಿರುತ್ತಾಳೆ. ಆದಾಗ್ಯೂ, ತಾಯಿಯೊಂದಿಗೆ ಆದ ಮಾತುಕತೆಯ ನಂತರ, ಅವಳ ತಲೆಯಲ್ಲಿ ಹಲವಾರು ಆಲೋಚನೆಗಳು ಬಂದು ಹೋಗುತ್ತಿವೆ.",
              "te": "తను వెతుకుతున్న కాలేజీల్లో చేరినందుకు మీరా సంతోషిస్తోంది. అయితే, ఆమె తల్లితో మాట్లాడిన తర్వాత అనేక ఆలోచనలు వచ్చాయి.",
              "or": "ମୀରା ଖୁସି ଯେ ସେ ଖୋଜୁଥିବା କଲେଜଗୁଡ଼ିକରେ ପ୍ରବେଶ କଲେ | ତେବେ, ତାଙ୍କ\n  ମା ସହିତ କଥାବାର୍ତ୍ତା ପରେ, ଅନେକ ଚିନ୍ତାଧାରା ତାଙ୍କ ମୁଣ୍ଡ ଦେଇ ଗତି କରୁଛି |",
              "as": "মীৰা সুখী যে তাই বিচাৰি থকা মহাবিদ্যালয়সমূহত সুবিধা পাইছে। অৱশ্যে, তাইৰ মাকৰ সৈতে হোৱা বাৰ্তালাপৰ পিছত, তাইৰ মূৰৰ মাজেৰে কেইবাটাও চিন্তা চলি আছে।",
              "gu": "મીરા ખુશ છે કે તે જે કોલેજો શોધી રહી હતી તેમાં પ્રવેશ મેળવ્યો. જો કે, તેની માતા સાથે વાત કર્યા પછી, તેના મગજમાં ઘણા વિચારો ચાલી રહ્યા છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_2_3_reveal",
              "hi": "A5_2_3_रिवील ",
              "ka": "A5_2_3_reveal",
              "te": "A5_2_3_reveal",
              "or": "A5_2_3_reveal",
              "as": "A5_2_3_ প্ৰকাশ",
              "gu": "A5_2_3_લાગુ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal her thoughts.",
              "hi": "उसके विचारों को प्रकट करने के लिए क्लिक करें।",
              "ka": "ಅವಳ ಯೋಚನೆಯನ್ನು ಅರಿಯಲು ಕ್ಲಿಕ್ ಮಾಡಿ ",
              "te": "ఆమె ఆలోచనలను చూడటానికి క్లిక్ చేయండి.",
              "or": "ତା’ର ଚିନ୍ତାଧାରା ଜାଣିବା ପାଇଁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "তাইৰ চিন্তাবোৰ প্ৰকাশ কৰিবলৈ ক্লিক কৰক।",
              "gu": "તેના વિચારો જણાવવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Thought 1",
              "hi": "विचार 1",
              "ka": "ಯೋಚನೆ 1",
              "te": "ఆలోచన 1",
              "or": "ଚିନ୍ତାଧାରା ୧",
              "as": "চিন্তা 1",
              "gu": "વિચાર 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Thought 2",
              "hi": "विचार 2 ",
              "ka": "ಯೋಚನೆ 2",
              "te": "ఆలోచన 2",
              "or": "ଚିନ୍ତାଧାରା ୨",
              "as": "চিন্তা 2",
              "gu": "વિચાર 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Thought 3",
              "hi": "विचार 3 ",
              "ka": "ಯೋಚನೆ 3",
              "te": "ఆలోచన 3",
              "or": "ଚିନ୍ତାଧାରା ୩",
              "as": "চিন্তা 3",
              "gu": "વિચાર 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "I think mechanical is the department for me. I have always been interested in practical subjects, and in this department, I'll finally get to work on these subjects more seriously.\"\"",
              "hi": "मुझे लगता है कि मैकेनिकल विभाग मेरे लिए सही है। मैं हमेशा प्रयोगात्मक विषयों में रुचि रखती हूं, और इस विभाग में, मुझे अंत में इन विषयों पर अधिक गंभीरता से काम करने को मिलेगा। \"\"",
              "ka": "ಮೆಕ್ಯಾನಿಕಲ್ ನನ್ನ ಇಲಾಖೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ನಾನು ಯಾವಾಗಲೂ ಪ್ರಾಯೋಗಿಕ ವಿಷಯಗಳಲ್ಲಿ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದೇನೆ ಹಾಗೂ ಈ ವಿಭಾಗದಲ್ಲಿ, ನಾನು ಅಂತಿಮವಾಗಿ ಈ ವಿಷಯಗಳ ಬಗ್ಗೆ ಹೆಚ್ಚು ಗಂಭೀರವಾಗಿ ಕೆಲಸವನ್ನು ಮಾಡುತ್ತೇನೆ.\"\"",
              "te": "నేను మెకానికల్ డిపార్ట్ మెంట్ లో చేరాలి అనుకుంటున్నాను. నేను ఎప్పుడూ ప్రాక్టికల్ సబ్జెక్ట్స్ పైన ఆసక్తి కలిగి ఉంటాను మరియు ఈ డిపార్ట్ మెంట్ లో, నేను చివరకి ఈ సబ్జెక్ట్స్ మీద మరింత ఎక్కువగా పని చేస్తాను.\"",
              "or": "ମୁଁ ଭାବୁଛି ଯାନ୍ତ୍ରିକ ବିଭାଗ ମୋ ପାଇଁ ଅଟେ | ମୁଁ ସର୍ବଦା ବ୍ୟବହାରିକ ବିଷୟ ପ୍ରତି\n  ଆଗ୍ରହୀ ଥିଲି ଏବଂ ଏହି ବିଭାଗରେ ମୁଁ ଶେଷରେ ଏହି ବିଷୟଗୁଡ଼ିକ ଉପରେ ଅଧିକ ଗମ୍ଭୀରତାର ସହ କାର୍ଯ୍ୟ କରିବାକୁ ଆସିବି। \"",
              "as": "মই ভাবোঁ মেকানিকেল বিভাগটো মোৰ কাৰনে ভাল হ'ব। কাৰন মোৰ সদয় প্ৰেক্টিকেল বষয়বোৰ ভাল লাগে,আৰু এইটো বিভাগত মই এইবোৰ বষয়ত ভালকৈ কাম কৰিব পাৰিম।",
              "gu": "મને લાગે છે કે મારા માટે મિકેનિકલ વિભાગ છે. મને હંમેશા પ્રેક્ટિકલ વિષયોમાં રસ રહ્યો છે, અને આ વિભાગમાં, હું આખરે આ વિષયો પર વધુ ગંભીરતાથી કામ કરીશ.\""
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Computer science is a popular option which people don't get easily. Maybe I shouldn't give it up. It also has high paying jobs.\"\"",
              "hi": "कंप्यूटर विज्ञान एक लोकप्रिय विकल्प है जो लोगों को सरलता से नहीं मिलता है। शायद मुझे इसे नहीं छोड़ना चाहिए। इसमें अधिक वेतन वाली नौकरियां भी हैं। \"\"",
              "ka": "ಕಂಪ್ಯೂಟರ್ ವಿಜ್ಞಾನವು ಒಂದು ಜನಪ್ರಿಯ ಆಯ್ಕೆಯಾಗಿದ್ದು ಅದು ಜನರಿಗೆ ಸುಲಭವಾಗಿ ಸಿಗುವುದಿಲ್ಲ. ಬಹುಶಃ ನಾನು ಅದನ್ನು ಬಿಟ್ಟುಕೊಡಬಾರದು. ಇದು ಹೆಚ್ಚಿನ ಸಂಬಳವನ್ನು ನೀಡುವ  ಉದ್ಯೋಗಗಳನ್ನು ಸಹ ಹೊಂದಿದೆ.\"\"",
              "te": "కంప్యూటర్ సైన్స్ అనేది అందరూ సులువుగా పొందలేని ఒక మంచి అవకాశం. బహుశా నేను దానిని వదులుకోకూడదు. దీంట్లో ఎక్కువ జీతం ఇచ్చే ఉద్యోగాలు కూడా ఉన్నాయి.\"",
              "or": "କମ୍ପ୍ୟୁଟର ସାଇନ୍ସ ଏକ ଲୋକପ୍ରିୟ ବିକଳ୍ପ ଯାହାକୁ ଲୋକମାନେ ସହଜରେ ପାଆନ୍ତି \n ନାହିଁ | ବୋଧହୁଏ ମୁଁ ଏହାକୁ ଛାଡିବା ଉଚିତ୍ ନୁହେଁ | ଏଥିରେ ଅଧିକ ବେତନ ପ୍ରାପ୍ତ କରୁଥିବା ଚାକିରୀ ମଧ୍ୟ ରହିଛି। \"",
              "as": "কম্পিউটাৰবিজ্ঞান এটা ভাল বিষয় যিটো মানুহে সহজে নাপায়। হয়তো মই এইটো এৰি দিব নালাগে। ইয়াত ভাল চাকৰিও পোৱা আশা আছে।",
              "gu": "કોમ્પ્યુટર સાયન્સ એક લોકપ્રિય વિકલ્પ છે જે લોકોને સરળતાથી મળતો નથી. કદાચ મારે તેને છોડવું ન જોઈએ. તેમાં ઉચ્ચ પગારવાળી નોકરીઓ પણ છે.\""
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "The private college is expensive, and I'll have to take a loan to fund my education. Paying off the loan will put pressure on me after graduation\"\"",
              "hi": "निजी कॉलेज महंगा है, और मुझे अपनी शिक्षा का शुल्क देने के लिए ऋण लेना होगा। ऋण का भुगतान ग्रेजुएशन के बाद मेरे ऊपर दबाव बनाएगा \"\"",
              "ka": "ಖಾಸಗಿ ಕಾಲೇಜು ದುಬಾರಿಯಾಗಿರುತ್ತದೆ ಮತ್ತು ನನ್ನ ಶಿಕ್ಷಣಕ್ಕಾಗಿ ನಾನು ಸಾಲವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕಾಗುತ್ತದೆ. ಸಾಲವನ್ನು ಪಾವತಿಸುವುದು ಪದವಿಯ ನಂತರ ನನ್ನ ಮೇಲೆ ಒತ್ತಡವನ್ನು ಉಂಟುಮಾಡುತ್ತದೆ\"\"",
              "te": "ప్రైవేట్ కళాశాలలో ఎక్కువ ఫీజులు కట్టాలి. నా చదువు కోసం అప్పు చేయవలసి ఉంటుంది. అప్పు కట్టడానికి నా గ్రాడ్యుయేషన్ తర్వాత నాపైన బారం పడుతుంది\"\"",
              "or": "ଘରୋଇ କଲେଜ ମହଙ୍ଗା, ଏବଂ ମୋର ଶିକ୍ଷା ପାଇଁ ମୋତେ ଏକ ରୁଣ ନେବାକୁ ପଡିବ | ରୁଣ ପରିଶୋଧ କରିବା ସ୍ନାତକ ପରେ ମୋ ଉପରେ ଚାପ ପକାଇବ \"\"",
              "as": "প্ৰাইভেট কলেজ বোৰ বহুত দামি হয়, আৰু সেইকাৰনে ল'ন লব লাগিব। ডিগ্ৰী পিছত ল'নৰ পইচা ঘুৰাই দিবলৈ যঠেষ্ট কস্ত হ'ব।",
              "gu": "ખાનગી કોલેજ મોંઘી છે, અને મારે મારા શિક્ષણને ખર્ચ પૂરું પાડવા માટે લોન લેવી પડશે. ગ્રેજ્યુએશન પછી લોન ચૂકવવાથી મારા પર દબાણ આવશે\"\""
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_2_4_D",
              "hi": "A5_2_4_D",
              "ka": "A5_2_4_D",
              "te": "A5_2_4_D",
              "or": "A5_2_4_D",
              "as": "A5_2_4_D",
              "gu": "A5_2_4_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Mira do?",
              "hi": "मीरा को क्या करना चाहिए?",
              "ka": "ಮೀರಾ ಈಗ ಏನು ಮಾಡಬೇಕು?",
              "te": "మీరా ఏమి చేయాలి?",
              "or": "ମୀରା କଣ କରିବା ଉଚିତ?",
              "as": "মীৰাই কি কৰিব লাগে?",
              "gu": "મીરાએ શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Take mechanical engineering and join the government college",
              "hi": "मैकेनिकल इंजीनियरिंग ले ले और सरकारी कॉलेज में भर्ती हो जाए ",
              "ka": "ಮೆಕ್ಯಾನಿಕಲ್ ಇಂಜಿನಿಯರಿಂಗ್ ತೆಗೆದುಕೊಂಡು ಸರ್ಕಾರಿ ಕಾಲೇಜಿಗೆ ಸೇರಿಕೊಳ್ಳುವುದೇ",
              "te": "మెకానికల్ ఇంజినీరింగ్ తీసుకొని ప్రభుత్వ కళాశాలలో చేరాడం.",
              "or": "ମେକାନିକାଲ୍ ଇଞ୍ଜିନିୟରିଂ ନିଅ ଏବଂ ସରକାରୀ କଲେଜରେ ଯୋଗ ଦିଅ |",
              "as": "মেকানিকেল ইঞ্জিনিয়াৰিং লওঁক আৰু চৰকাৰী কলেজ যোগদান কৰক",
              "gu": "મિકેનિકલ એન્જિનિયરિંગ રાખો અને સરકારી કોલેજમાં જોડાઓ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Take a loan, join the private college and pursue computer science",
              "hi": "ऋण ले, निजी कॉलेज में भर्ती हो और कंप्यूटर विज्ञान पढ़े ",
              "ka": "ಸಾಲ ಪಡೆದು ಖಾಸಗಿ ಕಾಲೇಜಿಗೆ ಸೇರಿ ಕಂಪ್ಯೂಟರ್ ಸೈನ್ಸ್ ಓದುವುದೇ",
              "te": "అప్పు తీసుకుని ప్రైవేట్ కాలేజీలో చేరి కంప్యూటర్ సైన్స్ చదవడం",
              "or": "ରୁଣ ନିଅ, ଘରୋଇ କଲେଜରେ ଯୋଗ ଦିଅ ଏବଂ କମ୍ପ୍ୟୁଟର ବିଜ୍ଞାନ ଅନୁସରଣ କର |",
              "as": "ঋণ লওঁক, ব্যক্তিগত মহাবিদ্যালয়ত যোগদান কৰক আৰু কম্পিউটাৰ বিজ্ঞান পঢ়ক।",
              "gu": "લોન મેળવો, ખાનગી કોલેજમાં જોડાઓ અને કોમ્પ્યુટર સાયન્સ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.tag",
            "value": {
              "en": "Passion and Curiosity",
              "hi": "जुनून और जिज्ञासा",
              "ka": "ಸಾಲ ಪಡೆದು ಖಾಸಗಿ ಕಾಲೇಜಿಗೆ ಸೇರಿ ಕಂಪ್ಯೂಟರ್ ಸೈನ್ಸ್ ಓದುವುದೇ",
              "te": "లక్ష్యం మరియు ఆసక్తికరమైనది",
              "or": "ଉତ୍ସାହ ଏବଂ କୌତୁହଳ |",
              "as": "আগ্ৰহ আৰু জনাৰ ইচ্ছা",
              "gu": "ઉત્કટ અને જિજ્ઞાસા"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.tag",
            "value": {
              "en": "Course popularity",
              "hi": "कोर्स लोकप्रियता",
              "ka": "ಕೋರ್ಸ್ ಜನಪ್ರಿಯತೆ",
              "te": "మంచి పేరున్న కోర్సు",
              "or": "ପାଠ୍ୟକ୍ରମର ଲୋକପ୍ରିୟତା |",
              "as": "পাঠ্যক্ৰমৰ জনপ্ৰিয়তা",
              "gu": "કોર્સ લોકપ્રિયતા"
            }
          }
        ]
      },
      "scene13": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Take up Mechanical Engineering",
              "hi": "मैकेनिकल इंजीनियरिंग ले ले ",
              "ka": "ಮೆಕ್ಯಾನಿಕಲ್ ಇಂಜಿನಿಯರಿಂಗ್ ತೆಗೆದುಕೊಳ್ಳುವುದೇ",
              "te": "మెకానికల్ ఇంజినీరింగ్ తీసుకోండి",
              "or": "ମେକାନିକାଲ୍ ଇଞ୍ଜିନିୟରିଂ ନିଅ |",
              "as": "মেকানিকেল ইঞ্জিনিয়াৰিং লওঁক",
              "gu": "મિકેનિકલ એન્જિનિયરિંગ રાખો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira takes up mechanical engineering at the local government college.",
              "hi": "मीरा स्थानीय सरकारी कॉलेज में मैकेनिकल इंजीनियरिंग लेती है।",
              "ka": "ಮೀರಾ ಸ್ಥಳೀಯ ಸರ್ಕಾರಿ ಕಾಲೇಜಿನಲ್ಲಿ ಮೆಕ್ಯಾನಿಕಲ್ ಎಂಜಿನಿಯರಿಂಗ್ ತೆಗೆದುಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "మీరా తమ ఊరులోని ప్రభుత్వ కళాశాలలో మెకానికల్ ఇంజనీరింగ్ చదువుతుంది.",
              "or": "ମୀରା ସ୍ଥାନୀୟ ସରକାରୀ କଲେଜରେ ମେକାନିକାଲ୍ ଇଞ୍ଜିନିୟରିଂ ନିଅନ୍ତି |",
              "as": "মেকানিকেল ইঞ্জিনিয়াৰিং লয় মীৰাই চৰকাৰি কলেজত",
              "gu": "મીરા સ્થાનિક સરકારી કોલેજમાં મિકેનિકલ એન્જિનિયરિંગ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_1_2",
              "hi": "D1_1_2",
              "ka": "D1_1_2",
              "te": "D1_1_2",
              "or": "D1_1_2",
              "as": "D1_1_2",
              "gu": "D1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She has to attend a lot of lectures in college. She spends a lot of her time studying and works hard.",
              "hi": "उसे कॉलेज में बहुत सारे लेक्चर्स में उपस्थित होना है। वह अपना अधिक समय अध्ययन में लगाती है और कड़ी मेहनत करती है।",
              "ka": "ಅವಳು ಕಾಲೇಜಿನಲ್ಲಿ ಬಹಳಷ್ಟು ಉಪನ್ಯಾಸಗಳಿಗೆ ಹಾಜರಾಗಬೇಕು. ಅವಳು ತನ್ನ ಹೆಚ್ಚಿನ ಸಮಯವನ್ನು ಅಧ್ಯಯನದಲ್ಲಿ ಕಳೆಯುತ್ತಾಳೆ ಮತ್ತು ಕಷ್ಟಪಟ್ಟು ಕೆಲಸ ಮಾಡುತ್ತಾಳೆ.",
              "te": "ఆమె కాలేజీలో చాలా క్లాసులకి వెళ్ళాలి. ఆమె చాలా సమయం చదువుతో పాటు కష్టపడి పని చేస్తుంది.",
              "or": "ତାଙ୍କୁ କଲେଜରେ ଅନେକ ବକ୍ତୃତା ଦେବାକୁ ପଡିବ | ସେ ଅନେକ ସମୟ ଅଧ୍ୟୟନ\n  କରି କଠିନ ପରିଶ୍ରମ କରନ୍ତି |",
              "as": "কলেজত বহুত ক্লাচ কৰিব লগা হয়। তাই বহুত সময় পঢ়া শুনা কৰে আৰু কষ্ট কৰে।",
              "gu": "તેને કોલેજમાં ઘણા પ્રવચનોમાં ભાગ લેવો પડશે.. તે પોતાનો ઘણો સમય અભ્યાસમાં વિતાવે છે અને સખત મહેનત કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_1_3",
              "hi": "D1_1_3",
              "ka": "D1_1_3",
              "te": "D1_1_3",
              "or": "D1_1_3",
              "as": "D1_1_3",
              "gu": "D1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She visits many factories where she sees different manufacturing processes like welding, moulding, and casting. The big machines in the factories fascinate her, and she hopes to work there someday.",
              "hi": "वह कई कारखानों का दौरा करती है जहां वह वेल्डिंग, मोल्डिंग और कास्टिंग जैसी विभिन्न विनिर्माण प्रक्रियाओं को देखती है। कारखानों में बड़ी मशीनें उसे आकर्षित करती हैं, और वह किसी दिन वहां काम करने की उम्मीद करती है।",
              "ka": "ಅವಳು ಅನೇಕ ಕಾರ್ಖಾನೆಗಳಿಗೆ ಭೇಟಿ ನೀಡುತ್ತಾಳೆ, ಅಲ್ಲಿ ಅವಳು ವೆಲ್ಡಿಂಗ್, ಮೋಲ್ಡಿಂಗ್ ಮತ್ತು ಎರಕದಂತಹ ವಿಭಿನ್ನ ಉತ್ಪಾದನಾ ಪ್ರಕ್ರಿಯೆಗಳನ್ನು ನೋಡುತ್ತಾಳೆ. ಕಾರ್ಖಾನೆಗಳಲ್ಲಿನ ದೊಡ್ಡ ಯಂತ್ರಗಳು ಅವಳನ್ನು ಆಕರ್ಷಿಸುತ್ತವೆ, ಮತ್ತು ಅವಳು ಒಂದು ದಿನ ಅಲ್ಲಿ ಕೆಲಸ ಮಾಡಬೇಕೆಂದು ಆಶಿಸುತ್ತಾಳೆ",
              "te": "ఆమె అనేక కర్మాగారాలను చూసింది, అక్కడ ఆమె వెల్డింగ్, మౌల్డింగ్ మరియు కాస్టింగ్ వంటి వేరువేరు తయారీ పనులని చూస్తుంది. కర్మాగారాల్లోని పెద్ద పెద్ద యంత్రాలు ఆమెను ఆకర్షిస్తున్నాయి మరియు ఆమె ఏదో ఒక రోజు అక్కడ పని చేయాలని భావిస్తోంది.",
              "or": "ସେ ଅନେକ କାରଖାନା ପରିଦର୍ଶନ କରନ୍ତି ଯେଉଁଠାରେ ସେ ୱେଲଡିଂ, ମୋଲିଡିଂ \n ଏବଂ କାଷ୍ଟିଂ ଭଳି ବିଭିନ୍ନ ଉତ୍ପାଦନ ପ୍ରକ୍ରିୟା ଦେଖନ୍ତି | କାରଖାନାଗୁଡ଼ିକରେ ଥିବା ବଡ ମେସିନ ଗୁଡିକ ତାଙ୍କୁ ଆକର୍ଷିତ କରିଥାଏ ଏବଂ ସେ ଦିନେ ସେଠାରେ କାମ କରିବାକୁ ଆଶା କରନ୍ତି |",
              "as": "তাই বহুতো কাৰখানা চাবলৈ যায় য'ত তাই ৱেল্ডিং, মোল্ডিং, আৰু কাষ্টিংৰ দৰে বিভিন্ন নিৰ্মাণ কাম দেখে। কাৰখানাবোৰৰ ডাঙৰ যন্ত্ৰবোৰ তাইৰ ভাল লাগে আৰু তাই কোনোবা দিনা তাত কাম কৰিব বুলি আশা কৰে।",
              "gu": "તે ઘણા કારખાનોની મુલાકાત લે છે જ્યાં તે વેલ્ડીંગ, મોલ્ડિંગ અને કાસ્ટિંગ જેવી વિવિધ ઉત્પાદન પ્રક્રિયાઓ જુએ છે. કારખાનોમાં મોટા મશીનો તેને આકર્ષિત કરે છે, અને તે કોઈક દિવસ ત્યાં કામ કરવાની આશા રાખે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_2_1",
              "hi": "D1_2_1",
              "ka": "D1_2_1",
              "te": "D1_2_1",
              "or": "D1_2_1",
              "as": "D1_2_1",
              "gu": "D1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shantanu is happy for his sister since she has taken a department she knows she’ll enjoy.",
              "hi": "शांतनु अपनी बहन के लिए खुश है क्योंकि उसने एक ऐसा विभाग ले लिया है जिसमें वह जानती है कि वह खुश रहेगी।",
              "ka": "ಶಂತನುವಿಗೆ ತನ್ನ ಅಕ್ಕನ ಬಗ್ಗೆ ಸಂತೋಷವಾಗಿದೆ ಏಕೆಂದರೆ ಅವಳಿಗೆ ಬೇಕಾದ  ವಿಭಾಗವನ್ನು ತೆಗೆದುಕೊಂದಿದ್ದಾಳೆ ಎಂದು.",
              "te": "షంతను తన సోదరికి నచ్చిన డిపార్ట్ మెంట్ లో చేరడం వలన సంతోషంగా ఉన్నాడు.",
              "or": "ଶାନ୍ତନୁ ତାଙ୍କ ଭଉଣୀ ପାଇଁ ଖୁସି ଯେହେତୁ ସେ ଏକ ବିଭାଗ ନେଇଛନ୍ତି ଯାହା ସେ\n  ଜାଣନ୍ତି ଯେ ସେ ଉପଭୋଗ କରିବେ |",
              "as": "শান্তনু তাৰ ভনীয়েকৰ কাৰনে সুখী কিয়নো তাই এটা বিভাগ লৈছে যিটো তাই জানে যে তাই ভাল পাব।",
              "gu": "શાંતનુ તેની બહેન માટે ખુશ છે કારણ કે તેને એક વિભાગ લીધો છે જે તે જાણે છે કે તેને આનંદ થશે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_2_2////",
              "hi": "D1_2_2////",
              "ka": "D1_2_2////",
              "te": "D1_2_2////",
              "or": "D1_2_2////",
              "as": "D1_2_2////",
              "gu": "D1_2_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "One day while talking to him on call...",
              "hi": "कॉल पर उससे बात करते हुए एक दिन ...",
              "ka": "ಒಂದು ದಿನ ಅವನೊಂದಿಗೆ ಕರೆಯಲ್ಲಿ ಮಾತನಾಡ್ತಿದ್ದಾಗ",
              "te": "ఒకరోజు అతనితో కాల్‌లో మాట్లాడుతూ...",
              "or": "ଦିନେ ତାଙ୍କ ସହିତ କଲ୍ ସମୟରେ କଥା ହେବାବେଳେ ...",
              "as": "এদিন তাৰ সৈতে কলত কথা পাতি থাকোতে...",
              "gu": "એક દિવસ કોલ પર તેની સાથે વાત કરતી વખતે..."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Shantanu, do you know that we’re making a robot model in our workshop!",
              "hi": "शांतनु, क्या तुम जानते हो कि हम अपनी कार्यशाला में रोबोट मॉडल बना रहे हैं!",
              "ka": "ಶಂತನು, ನಮ್ಮ ವರ್ಕ್‌ಶಾಪ್‌ನಲ್ಲಿ ನಾವು ರೋಬೋಟ್ ಮಾಡೆಲ್ ಅನ್ನು ತಯಾರಿಸುತ್ತಿದ್ದೇವೆ ಎಂದು ನಿನಗೆ ತಿಳಿದಿದೆಯೇ!",
              "te": "షాంతను, మా వర్క్ షాప్‌లో మేము రోబో మోడల్‌ని తయారు చేస్తున్నామని తెలుసా!",
              "or": "ଶାନ୍ତନୁ, ଆପଣ ଜାଣନ୍ତି କି ଆମେ ଆମର କର୍ମଶାଳାରେ ଏକ ରୋବଟ୍ ମଡେଲ୍\n  ତିଆରି କରୁଛୁ!",
              "as": "শান্তনু, তুমি জানেনে যে আমি আমাৰ কৰ্মশালাত এটা ৰবট মডেল বনাই আছোঁ!",
              "gu": "શાંતનુ, શું તું જાણો છો કે અમે અમારા કારખાનામાં રોબોટ મોડલ બનાવી રહ્યા છીએ!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Wow! I didn’t know that there are such exciting workshops in engineering. Why don’t you tell Ma about all this?",
              "hi": "वाह! मुझे नहीं पता था कि इंजीनियरिंग में ऐसी रोमांचक कार्यशालाएं हैं। आप इस सब के बारे में माँ को क्यों नहीं बतातीं?",
              "ka": "ವಾವ್! ಇಂಜಿನಿಯರಿಂಗ್‌ನಲ್ಲಿ ಇಂತಹ ರೋಚಕ ಕಾರ್ಯಾಗಾರಗಳಿವೆ ಎಂದು ನನಗೆ ತಿಳಿದೇ ಇರಲಿಲ್ಲ. ಈ ಎಲ್ಲದರ ಬಗ್ಗೆ ನೀನು ಅಮ್ಮನಿಗೆ ಏಕೆ ಹೇಳಬಾರದು?",
              "te": "వావ్! ఇంజనీరింగ్‌లో ఇలాంటి అద్భుతమైన వర్క్ షాప్‌లు ఉన్నాయని నాకు తెలియదు. నువ్వు వీటి గురించి నాతో ఎందుకు చెప్పవు?",
              "or": "ବାହା! ମୁଁ ଜାଣି ନଥିଲି ଯେ ଇଞ୍ଜିନିୟରିଂରେ ଏପରି ରୋମାଞ୍ଚକର କର୍ମଶାଳା ଅଛି |\n  ଆପଣ ଏସବୁ ବିଷୟରେ ମା’ଙ୍କୁ କାହିଁକି କୁହନ୍ତି ନାହିଁ?",
              "as": "বাঃ! মই নাজানিছিলো যে ইঞ্জিনিয়াৰিংত এনে ধৰণৰ বঢ়িয়া কৰ্মশালা আছে। তুমি মাক এই সকলোবোৰৰ বিষয়ে নোকোৱা কিয়?",
              "gu": "વાહ! મને ખબર ન હતી કે એન્જિનિયરિંગમાં આવા જોરદાર કારખાના છે. તું માને આ બધું કેમ નથી કહેતા?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I wish Ma would understand. If only she could see how happy I am and how much I’m learning. Maybe once I get a good job, she won’t be this upset.",
              "hi": "काश माँ समझ जाए। अगर वह केवल देख सकती कि मैं कितनी खुश हूं और मैं कितना कुछ सीख रही हूं। हो सकता है कि एक बार मुझे अच्छी नौकरी मिल जाए,  तो वह ऐसे परेशान नहीं होगी।",
              "ka": "ಅಮ್ಮನಿಗೆ ಅರ್ಥವಾಗತ್ತೆ. ನಾನು ಎಷ್ಟು ಸಂತೋಷವಾಗಿದ್ದೇನೆ ಮತ್ತು ನಾನು ಎಷ್ಟು ಕಲಿಯುತ್ತಿದ್ದೇನೆ ಎನ್ನುವುದನ್ನು ಅವಳು ನೋಡಬಹುದಾದಲ್ಲಿ. ಬಹುಶಃ ಒಮ್ಮೆ ನನಗೆ ಒಳ್ಳೆ ಕೆಲಸ ಸಿಕ್ಕರೆ ಆಕೆ ಈ ರೀತಿ ಬೇಸರಿಸಿಕೊಳ್ಳುವುದಿಲ್ಲ.",
              "te": "అమ్మ అర్థం చేసుకుంటే బాగుంటుంది. నేను ఎంత సంతోషంగా ఉన్నానో మరియు నేను ఎంత నేర్చుకుంటున్నానో ఆమె చూడగలిగితే బాగుంటుంది. బహుశా నీకు మంచి ఉద్యోగం వస్తే ఆమె ఇంత బాధపడదు అనిపిస్తుంది.",
              "or": "ମୁଁ ଇଚ୍ଛା କରେ ମା ବୁଝିଯିବେ | ଯଦି ସେ ଦେଖିଥାନ୍ତେ ମୁଁ କେତେ ଖୁସି ଏବଂ ମୁଁ କେତେ \n ଶିଖୁଛି | ବୋଧହୁଏ ଥରେ ମୁଁ ଭଲ ଚାକିରି ପାଇବା ପରେ ସେ ଏହି ବିବ୍ରତ ହେବେ ନାହିଁ |",
              "as": "মই আশা কৰোঁ কিজানি মায়ে বুজি পালেহেঁতেন। যদি তেওঁ দেখিলে হয় যে মই কিমান সুখী আৰু মই কিমান শিকি আছোঁ। যদি এবাৰ মই এটা ভাল চাকৰি পাওঁ, তেওঁ ইমান বেয়া পাই নাথাকিলে চাগৈ।",
              "gu": "કાશ મા સમજે. જો તે જોઈ શકે કે હું કેટલી ખુશ છું અને હું કેટલું શીખી રહી છું. કદાચ એકવાર મને સારી નોકરી મળી જશે, તે આટલી નારાજ નહીં થાય."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_3_1",
              "hi": "D1_3_1",
              "ka": "D1_3_1",
              "te": "D1_3_1",
              "or": "D1_3_1",
              "as": "D1_3_1",
              "gu": "D1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is excited and happy, but a few weeks into her semester, she realises that some professors are partial towards other students.",
              "hi": "मीरा उत्साहित और खुश है, लेकिन सेमेस्टर के कुछ सप्ताह बाद, उसे पता चलता है कि कुछ प्रोफेसर अन्य छात्रों के प्रति पक्षपाती हैं।",
              "ka": "ಮೀರಾ ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ ಮತ್ತು ಸಂತೋಷವಾಗಿದ್ದಾಳೆ, ಆದರೆ ತನ್ನ ಸೆಮಿಸ್ಟರ್‌ನಲ್ಲಿ ಕೆಲವು ವಾರಗಳಲ್ಲಿಯೇ, ಕೆಲವು ಪ್ರಾಧ್ಯಾಪಕರುಗಳು ಇತರ ವಿದ್ಯಾರ್ಥಿಗಳ ಕಡೆಗೆ ಪಕ್ಷಪಾತವನ್ನು ಹೊಂದಿದ್ದಾರೇ ಅನ್ನೋದನ್ನ ಅವಳು ಅರ್ಥ ಮಾಡಿಕೊಂಡಳು.",
              "te": "మీరా ఉత్సాహంగా మరియు సంతోషంగా ఉంది, కానీ ఆమెకి కొన్ని వారాలలో సెమిస్టర్‌ ఉన్న సమయంలో కొంతమంది ప్రొఫెసర్లు కొందరి విద్యార్థులని వేరువేరుగా చూస్తున్నారని ఆమె తెలుసుకుంటుంది.",
              "or": "ମୀରା ଉତ୍ସାହିତ ଏବଂ ଖୁସି, କିନ୍ତୁ କିଛି ସପ୍ତାହ ମଧ୍ୟରେ ସେମିଷ୍ଟାରରେ, ସେ ଅନୁଭବ\n  କଲେ ଯେ କେତେକ ପ୍ରଫେସର ଅନ୍ୟ ଛାତ୍ରମାନଙ୍କ ପ୍ରତି ଆଂଶିକ |",
              "as": "মীৰা আগ্ৰহ বহুত আৰু সুখী, কিন্তু তাইৰ ছেমিষ্টাৰৰ কেইসপ্তাহমানৰ ভিতৰতে, তাই পালে যে যে কিছুমান অধ্যাপক আন শিক্ষাৰ্থীক বেছি গুৰুত্ব দিয়ে।",
              "gu": "મીરા ઉત્સાહિત અને ખુશ છે, પરંતુ તેના સેમેસ્ટરના થોડા અઠવાડિયા પછી, તેને સમજાયું કે કેટલાક પ્રોફેસરો અન્ય વિદ્યાર્થીઓ પ્રત્યે આંશિક છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_3_2",
              "hi": "D1_3_2",
              "ka": "D1_3_2",
              "te": "D1_3_2",
              "or": "D1_3_2",
              "as": "D1_3_2",
              "gu": "D1_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "They allow specific students to spend more time in the lab after their class, pay more attention to their questions.",
              "hi": "वे विशिष्ट छात्रों को अपनी कक्षा के बाद प्रयोगशाला में अधिक समय बिताने की अनुमति देते हैं, उनके प्रश्नों पर अधिक ध्यान देते हैं।",
              "ka": "ಕೆಲವು ನಿರ್ದಿಷ್ಟ ವಿದ್ಯಾರ್ಥಿಗಳು ತಮ್ಮ ತರಗತಿಯ ನಂತರ ಪ್ರಯೋಗಾಲಯದಲ್ಲಿ ಹೆಚ್ಚು ಸಮಯ ಕಳೆಯಲು ಅವರು ಅವಕಾಶ ಮಾಡಿಕೊಡುತ್ತಾರೆ, ಅವರ ಪ್ರಶ್ನೆಗಳಿಗೆ ಹೆಚ್ಚು ಗಮನ ಕೊಡುತ್ತಾರೆ.",
              "te": "వారు కొంతమంది విద్యార్థులను వారి తరగతి తర్వాత ల్యాబ్‌లో ఎక్కువ సమయం ఉండటానికి ఒప్పుకుంటున్నారు మరియు వారు అడిగిన ప్రశ్నలపై ఎక్కువ శ్రద్ధ చూపుతున్నారు.",
              "or": "ସେମାନେ ନିର୍ଦ୍ଦିଷ୍ଟ ଛାତ୍ରମାନଙ୍କୁ ସେମାନଙ୍କ ଶ୍ରେଣୀ ପରେ ଲ୍ୟାବ ରେଅଧିକ ସମୟ\n  ବିତାଇବାକୁ ଅନୁମତି ଦିଅନ୍ତି, ସେମାନଙ୍କ ପ୍ରଶ୍ନ ପ୍ରତି ଅଧିକ ଧ୍ୟାନ ଦିଅନ୍ତି |",
              "as": "তেওঁলোকে কিছুমান শিক্ষাৰ্থীক তেওঁলোকৰ ক্লাচৰ পিছত লেবত বেছি সময় থাকিব দিয়ে, সিহঁতৰ প্ৰশ্নবোৰক বেছি মনোযোগ দিয়ে।",
              "gu": "તેઓ ચોક્કસ વિદ્યાર્થીઓને તેમના વર્ગ પછી લેબમાં વધુ સમય પસાર કરવા, તેમના પ્રશ્નો પર વધુ ધ્યાન આપવાની મંજૂરી આપે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_3_3",
              "hi": "D1_3_3",
              "ka": "D1_3_3",
              "te": "D1_3_3",
              "or": "D1_3_3",
              "as": "D1_3_3",
              "gu": "D1_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira applies for the position of Teaching Assistant, a paid job, and the faculty members deny her the role. The job is given only to the students that the faculty favours.",
              "hi": "मीरा, एक शिक्षण सहायिका के पद के लिए, वेतनभोगी नौकरी, आवेदन करती है, और संकाय सदस्य उसको नौकरी देने से इनकार कर देते हैं । नौकरी केवल उन छात्रों को दी जाती है जिनका संकाय पक्षपात करते हैं।",
              "ka": "ಮೀರಾ ಟೀಚಿಂಗ್ ಅಸಿಸ್ಟೆಂಟ್ ಹುದ್ದೆಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಾಳೆ, ಇದು ಸಂಬಳದ ಕೆಲಸ, ಮತ್ತು ಅಧ್ಯಾಪಕರು ಅವಳ ಪಾತ್ರವನ್ನು ನಿರಾಕರಿಸುತ್ತಾರೆ. ಅಧ್ಯಾಪಕರು ಒಲವು ತೋರುವ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಮಾತ್ರ ಕೆಲಸವನ್ನು ನೀದುತ್ತಾರೆ.",
              "te": "మీరా టీచింగ్ అసిస్టెంట్ ఉద్యోగానికి అప్లై చేసింది మరియు ఫ్యాకల్టీ సభ్యులు ఆమెను తిరస్కరించారు. అధ్యాపకులు ఇష్టపడే విద్యార్థులకు మాత్రమే ఉద్యోగం ఇవ్వబడుతుంది.",
              "or": "ମୀରା ଶିକ୍ଷାଦାନ ସହକାରୀ, ଦରମା ପ୍ରାପ୍ତ ଚାକିରି ପାଇଁ ଆବେଦନ କରନ୍ତି ଏବଂ\n  ଅଧ୍ୟାପକମାନେ ତାଙ୍କୁ ଏହି ଭୂମିକାକୁ ଅସ୍ୱୀକାର କରନ୍ତି | ଚାକିରିଟି କେବଳ ଛାତ୍ରମାନଙ୍କୁ ଦିଆଯାଏ ଯାହାକୁ ଅଧ୍ୟାପକମାନେ ଅନୁଗ୍ରହ କରନ୍ତି |",
              "as": "মীৰাই শিক্ষক সহায়কৰ বাবে আবেদন কৰে, এইটো চাকৰিত পইচাও পাব, আৰু শিক্ষকসকলে তাইক অস্বীকাৰ কৰে। চাকৰিটো কেৱল শিক্ষাৰ্থীসকলক দিয়া হয় যিটো শিক্ষকসকলে পছন্দ কৰে।",
              "gu": "મીરા શિક્ષક સહાયકની સ્થિતિ માટે, નોકરીમાં પૈસા મળે તે માટે અરજી કરે છે, અને ફેકલ્ટી સભ્યો તેની ભૂમિકાને નકારે છે. નોકરી ફક્ત વિદ્યાર્થીઓને આપવામાં આવે છે જે શિક્ષક પસંદ કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_4_1",
              "hi": "D1_4_1",
              "ka": "D1_4_1",
              "te": "D1_4_1",
              "or": "D1_4_1",
              "as": "D1_4_1",
              "gu": "D1_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is quite upset. She doesn’t understand what she has done wrong until some seniors approach her during lunch.",
              "hi": "मीरा काफी परेशान है। जब तक कि कुछ सीनियर दोपहर के भोजन के समय उससे संपर्क नहीं करते, उसे समझ नहीं आता कि उसने क्या गलत किया है ।",
              "ka": "ಮೀರಾ ತುಂಬಾ ಬೇಸರಗೊಂಡಿದ್ದಾಳೆ. ಊಟದ ಸಮಯದಲ್ಲಿ ಕೆಲವು ಸೀನಿಯರ್ಸ್ ಅವಳನ್ನು ಸಂಪರ್ಕಿಸುವವರೆಗೂ ಅವಳು ಏನು ತಪ್ಪು ಮಾಡಿದ್ದಾಳೆಂದು ಅವಳಿಗೆ ಅರ್ಥವಾಗಿರುವುದಿಲ್ಲ.",
              "te": "మీరా చాలా బాధ పడుతుంది. మధ్యాహ్న భోజన సమయంలో కొందరు సీనియర్లు ఆమె దగ్గరకి వచ్చేంత వరకు ఆమె చేసిన తప్పేమిటో తెలియలేదు.",
              "or": "ମୀରା ବହୁତ ବିରକ୍ତ | ସେ କିଛି ବୁଝିପାରୁ ନାହାଁନ୍ତି ଯେପର୍ଯ୍ୟନ୍ତ ମଧ୍ୟାହ୍ନ ଭୋଜନ \n ସମୟରେ କିଛି ବୟସ୍କ ବ୍ୟକ୍ତି ତାଙ୍କ ନିକଟକୁ ଆସନ୍ତି |",
              "as": "মীৰা বহুত মন বেয়া। দুপৰীয়াৰ আহাৰৰ সময়ত কিছুমান চিনিয়ৰ ই তাইৰ ওচৰলৈ নহালৈকে তাই কি ভুল কৰিছে তাই বুজি নাপায়।",
              "gu": "મીરા એકદમ પરેશાન છે. લંચ દરમિયાન કેટલાક વરિષ્ઠ લોકો તેનો સંપર્ક કરે ત્યાં સુધી તેને શું ખોટું કર્યું છે તે સમજાતું નથી."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_4_1b",
              "hi": "D1_4_1b",
              "ka": "D1_4_1b",
              "te": "D1_4_1b",
              "or": "D1_4_1b",
              "as": "D1_4_1b",
              "gu": "D1_4_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They introduce themselves as members of the Students Democratic Support Group, which supports students facing discrimination.",
              "hi": "वे खुद को छात्रों के लोकतांत्रिक सहायता समूह के सदस्यों के रूप में परिचय देते हैं, जो भेदभाव का सामना करने वाले छात्रों का समर्थन करता है।",
              "ka": "ತಾರತಮ್ಯ ಎದುರಿಸುತ್ತಿರುವ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಬೆಂಬಲಿಸುವ ಸ್ಟೂಡೆಂಟ್ಸ್ ಡೆಮಾಕ್ರಟಿಕ್ ಸಪೋರ್ಟ್ ಗ್ರೂಪ್‌ನ ಸದಸ್ಯರೆಂದು ಅವರು ತಮ್ಮನ್ನು ತಾವು ಪರಿಚಯಿಸಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "వారు తమను తాము స్టూడెంట్స్ డెమోక్రటిక్ సపోర్ట్ గ్రూప్ సభ్యులుగా పరిచయం చేసుకుంటారు, ఇది చిన్న చూపు చూసిన విద్యార్థులకు మద్దతు ఇస్తుంది.",
              "or": "ସେମାନେ ନିଜକୁ ଛାତ୍ର ଗଣତାନ୍ତ୍ରିକ ସହାୟତା ଗୋଷ୍ଠୀର ସଦସ୍ୟ ଭାବରେ ପରିଚିତ \n କରାନ୍ତି, ଯାହା ଭେଦଭାବର ସମ୍ମୁଖୀନ ହେଉଥିବା ଛାତ୍ରମାନଙ୍କୁ ସମର୍ଥନ କରେ |",
              "as": "তেওঁলোকে নিজকে ষ্টুডেণ্টছ ডেমক্ৰেটিক চাপোৰ্ট গ্ৰুপৰ সদস্য বুলি পৰিচয় দিয়ে, যি ভেদ ভাৱ সন্মুখীন হোৱা শিক্ষাৰ্থীসকলক সহায় কৰে।",
              "gu": "તેઓ પોતાને સ્ટુડન્ટ્સ ડેમોક્રેટિક સપોર્ટ ગ્રુપના સભ્યો તરીકે ઓળખાવે છે, જે ભેદભાવનો સામનો કરી રહેલા વિદ્યાર્થીઓને સમર્થન આપે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_4_2//",
              "hi": "D1_4_2//",
              "ka": "D1_4_2//",
              "te": "D1_4_2//",
              "or": "D1_4_2//",
              "as": "D1_4_2//",
              "gu": "D1_4_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Senior Student",
              "hi": "वरिष्ठ छात्र",
              "ka": "ಸೀನಿಯರ್ ವಿದ್ಯಾರ್ಥಿ",
              "te": "సీనియర్ విద్యార్థి",
              "or": "ସିନିୟର ଛାତ୍ର",
              "as": "চিনিয়ৰ",
              "gu": "ભૂતપૂર્વ વિદ્યાર્થીઓ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Nice to meet you, Mira! We usually approach students in the first year who come from SC, ST and OBC backgrounds to join our weekly discussion groups.",
              "hi": "आपसे मिलकर अच्छा लगा, मीरा! हम सामान्यतः प्रथम वर्ष के छात्रों से संपर्क करते हैं जो एससी, एसटी और ओबीसी पृष्ठभूमि से हमारे साप्ताहिक चर्चा समूहों में शामिल होने के लिए आते हैं।",
              "ka": "ನಿನ್ನನ್ನು ಭೇಟಿಯಾಗಲು ಸಂತೋಷವಾಗಿದೆ, ಮೀರಾ! ನಾವು ಸಾಮಾನ್ಯವಾಗಿ ನಮ್ಮ ಸಾಪ್ತಾಹಿಕ ಚರ್ಚಾ ಗುಂಪುಗಳಿಗೆ ಸೇರಲು SC, ST ಮತ್ತು OBC ಹಿನ್ನೆಲೆಯಿಂದ ಬಂದ ಮೊದಲ ವರ್ಷದ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಸಂಪರ್ಕಿಸುತ್ತೇವೆ.",
              "te": "నిన్ను కలవడం ఆనందంగా ఉంది, మీరా! మేము సాధారణంగా ప్రతి వారం పెట్టె సమావేశాలలో చేరడానికి మొదటి సంవత్సరంలోని SC, ST మరియు OBC కులాలకి చెందిన విద్యార్థులను కలుసుకుంటాము.",
              "or": "ମିରା, ତୁମକୁ ଭେଟିବାକୁ ଭଲ ଲାଗିଲା! ଆମେ ସାଧାରଣତ ପ୍ରଥମ ବର୍ଷରେ\n  ଛାତ୍ରମାନଙ୍କ ନିକଟକୁ ଯାଇଥାଉ, ଯେଉଁମାନେ ଆମର ସାପ୍ତାହିକ ଆଲୋଚନା ଗୋଷ୍ଠୀରେ ଯୋଗଦେବା ପାଇଁ SC, ST ଏବଂ OBC ପୃଷ୍ଠଭୂମିରୁ ଆସନ୍ତି |",
              "as": "তোমাক লগ পাই ভাল লাগিছে, মীৰা! আমি সাধাৰণতে প্ৰথম বছৰত SC, ST আৰু OBC শিক্ষাৰ্থীসকলৰ ওচৰলৈ আমি সাপ্তাহিক আলোচনা গোটত যোগ কৰিবলৈ যাওঁ।",
              "gu": "તને મળીને આનંદ થયો, મીરા! અમે સામાન્ય રીતે અમારા સાપ્તાહિક ચર્ચા જૂથોમાં જોડાવા માટે SC, ST અને OBC પૃષ્ઠભૂમિમાંથી આવતા પ્રથમ વર્ષમાં વિદ્યાર્થીઓનો સંપર્ક કરીએ છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I don’t understand.",
              "hi": "मैं समझ नहीं पा रही हूँ।",
              "ka": "ನನಗೆ ಅರ್ಥವಾಗುತ್ತಿಲ್ಲ..",
              "te": "నాకు అర్థం కాలేదు.",
              "or": "ମୁଁ ବୁଝିପାରୁନାହି।",
              "as": "মই বুজি পোৱা নাই।",
              "gu": "મને સમજાતું નથી."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_4_3////",
              "hi": "D1_4_3////",
              "ka": "D1_4_3////",
              "te": "D1_4_3////",
              "or": "D1_4_3////",
              "as": "D1_4_3////",
              "gu": "D1_4_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "There is some bitterness towards students who have rightfully claimed reservations, and we are here to support you through that.",
              "hi": "उन छात्रों के प्रति कुछ कड़वाहट है जिन्होंने अधिकारपूर्वक आरक्षण की मांग की है, और हम उसके लिए आपका समर्थन करने आए हैं। ",
              "ka": "ಮೀಸಲಾತಿಯನ್ನು ನ್ಯಾಯಯುತವಾಗಿ ಕ್ಲೈಮ್ ಮಾಡಿದ ವಿದ್ಯಾರ್ಥಿಗಳ ಬಗ್ಗೆ ಸ್ವಲ್ಪ ಕಹಿ ವಾತಾವರಣ ಇದೆ ಮತ್ತು ಆ ಮೂಲಕ ನಿನಗೆ ಬೆಂಬಲ ಕೊಡೊಕ್ಕೆ ನಾವು ಇಲ್ಲಿದ್ದೇವೆ.",
              "te": "ఇక్కడ రిజర్వేషన్‌ లను న్యాయబద్ధంగా పొందిన విద్యార్థుల పట్ల కొంత ద్వేషం ఉంది మరియు దాని వల్ల నీకు మద్దతు ఇవ్వడానికి మేము ఇక్కడ ఉన్నాము.",
              "or": "ଛାତ୍ରମାନଙ୍କ ପ୍ରତି କିଛି ତିକ୍ତତା ଅଛି, ଯେଉଁମାନେ ସଠିକ୍ ଭାବରେ ସଂରକ୍ଷଣ ଦାବି\n  କରିଛନ୍ତି, ଏବଂ ଆମେ ଆପଣଙ୍କୁ ତାହା ମାଧ୍ୟମରେ ସମର୍ଥନ କରିବାକୁ ଏଠାରେ ଅଛୁ |",
              "as": "যিয়ে সংৰক্ষনত চিট লৈছে সিহঁতৰ প্ৰতি বেয়া আছে শিক্ষক সকলৰ, আৰু সেইকাৰনে আমি আছোঁ",
              "gu": "જે વિદ્યાર્થીઓએ યોગ્ય રીતે આરક્ષણનો દાવો કર્યો છે તેમના પ્રત્યે થોડીક કડવાશ છે, અને અમે અહીં તને સમર્થન આપવા માટે છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "That explains why the professors were behaving differently towards me.",
              "hi": "इससे पता चलता है कि प्रोफेसर मेरे प्रति अलग व्यवहार क्यों कर रहे थे।",
              "ka": "ಪ್ರಾಧ್ಯಾಪಕರು ನನ್ನೊಂದಿಗೆ ಏಕೆ ವಿಭಿನ್ನವಾಗಿ ವರ್ತಿಸುತ್ತಿದ್ದಾರೆ ಎನ್ನುವುದನ್ನು ಅದು ವಿವರಿಸುತ್ತದೆ.",
              "te": "ప్రొఫెసర్లు ఆమెని వేరుగా ఎందుకు చూస్తున్నారో వివరిస్తుంది.",
              "or": "ପ୍ରଫେସରମାନେ ମୋ ପ୍ରତି ଭିନ୍ନ ଆଚରଣ କରୁଥିବା ହେତୁ ତାହା ବ୍ୟାଖ୍ୟା କରେ |",
              "as": "এইকাৰনে প্ৰেফচাৰ সকলে মোৰ লগত এনেকুৱা কৰি আছে।",
              "gu": "તે સમજાવે છે કે શા માટે પ્રોફેસરો મારી સાથે અલગ વર્તન કરી રહ્યા હતા."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "You should bring this to the discussion group! It allows all students to have a safe space to discuss these issues to make the campus safer and more inclusive.",
              "hi": "आपको इस विषय को चर्चा समूह में लाना चाहिए! यह सभी छात्रों को परिसर को सुरक्षित और अधिक समावेशी बनाने के लिए इन मुद्दों पर चर्चा करने के लिए एक सुरक्षित स्थान की अवसर देता है।",
              "ka": "ನೀನು ಇದನ್ನು ಚರ್ಚಾ ಗುಂಪಿನ ಮುಂದೆ ಇಡಬೇಕು! ಕ್ಯಾಂಪಸ್ ಅನ್ನು ಸುರಕ್ಷಿತ ಮತ್ತು ಹೆಚ್ಚು ಒಳಗೊಳ್ಳುವಂತೆ ಮಾಡಲು ಹಾಗೂ ಈ ಸಮಸ್ಯೆಗಳನ್ನು ಚರ್ಚಿಸಲು ಎಲ್ಲಾ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಸುರಕ್ಷಿತ ಸ್ಥಳವನ್ನು ಹೊಂದಲು ಇದು ಅನುಮತಿ ನೀಡುತ್ತದೆ.",
              "te": "నువ్వు దీన్ని చర్చా సమావేశానికి తీసుకురావాలి! ఇది క్యాంపస్‌ ను సురక్షితంగా మరియు మరింత కలుసుకోనెలా చేయడానికి ఈ సమస్యలను చర్చించడానికి విద్యార్థులందరికీ సురక్షితమైన స్థలం ఉంది.",
              "or": "ଆପଣ ଏହାକୁ ଆଲୋଚନା ଗୋଷ୍ଠୀକୁ ଆଣିବା ଉଚିତ୍! କ୍ୟାମ୍ପସକୁ ଅଧିକ ନିରାପଦ \n ଏବଂ ଅଧିକ ଅନ୍ତର୍ଭୂକ୍ତ କରିବା ପାଇଁ ଏହା ସମସ୍ତ ଛାତ୍ରମାନଙ୍କୁ ଏକ ନିରାପଦ ସ୍ଥାନ ପାଇବାକୁ ଅନୁମତି ଦେଇଥାଏ |",
              "as": "তুমি ইয়াক আলোচনা গোটলৈ অনা ভাল হ'ব! ই সকলো শিক্ষাৰ্থীক সুৰক্ষিত অনুভৱ কৰিব চৌহদত।",
              "gu": "તમારે આને ચર્ચા જૂથમાં લાવવું જોઈએ! તે તમામ વિદ્યાર્થીઓને કેમ્પસને વધુ સુરક્ષિત અને વધુ સમાવિષ્ટ બનાવવા માટે આ મુદ્દાઓ પર ચર્ચા કરવા માટે સુરક્ષિત જગ્યાની મંજૂરી આપે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_4_4",
              "hi": "D1_4_4",
              "ka": "D1_4_4",
              "te": "D1_4_4",
              "or": "D1_4_4",
              "as": "D1_4_4",
              "gu": "D1_4_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira attends the weekly discussions of the Students Democratic Support Group. She realises that many other students like her have faced similar situations of discrimination, sometimes even worse.",
              "hi": "मीरा छात्र डेमोक्रेटिक सपोर्ट ग्रुप की साप्ताहिक चर्चाओं में भाग लेती है। वह अनुभव करती है कि उसके जैसे अनेक अन्य छात्रों ने भेदभाव की समान स्थितियों का सामना किया है, कभी कभी और भी बुरा।",
              "ka": "ಸ್ಟೂಡೆಂಟ್ಸ್ ಡೆಮಾಕ್ರಟಿಕ್ ಸಪೋರ್ಟ್ ಗ್ರೂಪ್‌ನ ಸಾಪ್ತಾಹಿಕ ಚರ್ಚೆಗಳಿಗೆ ಮೀರಾ ಹಾಜರಾಗುತ್ತಾಳೆ. ತನ್ನಂತೆಯೇ ಇತರ ಅನೇಕ ವಿದ್ಯಾರ್ಥಿಗಳು ತಾರತಮ್ಯದ ಇದೇ ರೀತಿಯ ಸಂದರ್ಭಗಳನ್ನು ಎದುರಿಸಿದ್ದಾರೆ ಎನ್ನುವುದನ್ನು ಅವಳು ಅರಿತುಕೊಂಡಳು, ಕೆಲವೊಮ್ಮೆ ಇನ್ನೂ ಕೆಟ್ಟದಾದ ಸಂದರ್ಭಗಳನ್ನು.",
              "te": "మీరా స్టూడెంట్స్ డెమోక్రటిక్ సపోర్ట్ గ్రూప్ యొక్క వారపు చర్చలకు వెళుతుంది. తనలాంటి అనేకమంది ఇతర విద్యార్ధులు వివక్ష ఇలాంటి పరిస్థితులను ఎదుర్కొన్నారని, కొన్నిసార్లు మరింత దారుణంగా ఉంటారని ఆమె తెలుసుకుంది.",
              "or": "ମୀରା ଷ୍ଟୁଡେଣ୍ଟସ୍ ଡେମୋକ୍ରାଟିକ୍ ସପୋର୍ଟ ଗ୍ରୁପ୍ ର ସାପ୍ତାହିକ ଆଲୋଚନାରେ \n ଯୋଗ ଦିଅନ୍ତି | ସେ ହୃଦୟଙ୍ଗମ କରିଛନ୍ତି ଯେ ତାଙ୍କ ପରି ଅନ୍ୟ ଅନେକ ଛାତ୍ର ମଧ୍ୟ ଭେଦଭାବର ସମାନ ପରିସ୍ଥିତିର ସମ୍ମୁଖୀନ ହୋଇଛନ୍ତି, ବେଳେବେଳେ ଆହୁରି ଖରାପ ମଧ୍ୟ |",
              "as": "মীৰাই ষ্টুডেণ্টছ ডেমক্ৰেটিক চাপোৰ্ট গ্ৰুপৰ সাপ্তাহিক আলোচনাত অংশ গ্ৰহণ কৰে। তাই বুজি পায় যে তাইৰ নিচিনাকৈ আন বহুতো শিক্ষাৰ্থীয়ে একে ধৰণৰ ভেদ ভাৱৰ সন্মুখীন হৈছে, কেতিয়াবা ইয়াতকৈ আৰু বেয়া।",
              "gu": "મીરા સ્ટુડન્ટ્સ ડેમોક્રેટિક સપોર્ટ ગ્રુપની સાપ્તાહિક ચર્ચાઓમાં ભાગ લે છે. તેને અહેસાસ થાય છે કે તેના જેવા અન્ય ઘણા વિદ્યાર્થીઓએ ભેદભાવની સમાન પરિસ્થિતિઓનો સામનો કરવો પડ્યો છે, ક્યારેક તો તેનાથી પણ ખરાબ."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_3_4",
              "hi": "D1_3_4",
              "ka": "D1_3_4",
              "te": "D1_3_4",
              "or": "D1_3_4",
              "as": "D1_3_4",
              "gu": "D1_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Over the following few semesters, she gains the confidence to stand up for herself.",
              "hi": "आने वाले कुछ सेमेस्टर में, वह अपना पक्ष रख पाने के आत्मविश्वास को प्राप्त करती है।",
              "ka": "ಮುಂದಿನ ಕೆಲವು ಸೆಮಿಸ್ಟರ್‌ಗಳಲ್ಲಿ, ಅವಳು ತನಗಾಗಿ ನಿಲ್ಲುವ ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "కొన్ని సెమిస్టర్‌ల తర్వాత, ఆమె తన కోసం తను నిలబడాలనే విశ్వాసాన్ని పొందుతుంది.",
              "or": "ନିମ୍ନଲିଖିତ କିଛି ସେମିଷ୍ଟାରରେ, ସେ ନିଜ ପାଇଁ ଠିଆ ହେବାର ଆତ୍ମବିଶ୍ୱାସ ଲାଭ\n  କରନ୍ତି |",
              "as": "পিচৰ কেইটামান ছেমিষ্টাৰত, তাই নিজৰ বাবে থিয় হোৱাৰ আত্মবিশ্বাস পাব ধৰে।",
              "gu": "પછીના થોડા સેમેસ્ટરમાં, તેને પોતાને માટે ઊભા રહેવાનો આત્મવિશ્વાસ મેળવ્યો."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_5_1",
              "hi": "D1_5_1",
              "ka": "D1_5_1",
              "te": "D1_5_1",
              "or": "D1_5_1",
              "as": "D1_5_1",
              "gu": "D1_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She finds many close friends in the Support Group. Some are from her batch and some are seniors;  some are from reserved categories and some from general category.",
              "hi": "उसे समर्थन समूह में अनेक घनिष्ठ मित्र मिलते हैं। कुछ उसके बैच से हैं और कुछ वरिष्ठ हैं; कुछ आरक्षित श्रेणियों से हैं और कुछ सामान्य श्रेणी से हैं।",
              "ka": "ಅವಳು ಬೆಂಬಲ ಗುಂಪಿನಲ್ಲಿ ಅನೇಕ ನಿಕಟ ಸ್ನೇಹಿತರನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾಳೆ. ಕೆಲವರು ಅವಳ ಬ್ಯಾಚಿನವರು ಮತ್ತು ಕೆಲವರು ಸೀನಿಯರ್ಸ್; ಕೆಲವು ಮೀಸಲು ವರ್ಗಗಳಿಂದ ಮತ್ತು ಕೆಲವು ಸಾಮಾನ್ಯ ವರ್ಗದಿಂದ.",
              "te": "ఆమెకు సపోర్ట్ గ్రూప్‌లో చాలా మంది సన్నిహితులు ఉన్నారు. కొందరు ఆమె బ్యాచ్‌కు చెందినవారు మరియు కొందరు సీనియర్లు; కొంతమంది రిజర్వ్డ్ కేటగిరీల నుండి మరియు మరికొంతమంది సాధారణ వర్గం నుండి.",
              "or": "ସେ ସପୋର୍ଟ ଗ୍ରୁପରେ ଅନେକ ଘନିଷ୍ଠ ବନ୍ଧୁ ଖୋଜନ୍ତି | କେତେକ ତାଙ୍କ ବ୍ୟାଚରୁ \n ଏବଂ କେତେକ ସିନିୟର; କେତେକ ସଂରକ୍ଷିତ ବର୍ଗର ଏବଂ କେତେକ ସାଧାରଣ ବର୍ଗର |",
              "as": "তাই সেইটো গোটত বহুতো ভাল বন্ধু বিচাৰি পায়। কিছুমান তাইৰ বেচৰ আৰু কিছুমান ডাঙৰ; কিছুমান সংৰক্ষিত শ্ৰেণীৰ আৰু কিছুমান সাধাৰণ শ্ৰেণীৰ",
              "gu": "તેના સપોર્ટ ગ્રુપમાં ઘણા નજીકના મિત્રો મળે છે. કેટલાક તેના બેચના છે અને કેટલાક વરિષ્ઠ છે; કેટલાક આરક્ષિત શ્રેણીમાંથી અને કેટલાક સામાન્ય શ્રેણીમાંથી છે."
            }
          }
        ]
      },
      "scene14": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_1_1",
              "hi": "D2_1_1",
              "ka": "D2_1_1",
              "te": "D2_1_1",
              "or": "D2_1_1",
              "as": "D2_1_1",
              "gu": "D2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira works hard throughout her college years. While she doesn’t perform well in a few subjects, she tries many things like internships, live projects and participating in competitions.",
              "hi": "मीरा अपने कॉलेज के वर्षों में  निरंतर कठिन परिश्रम करती है। हालांकि कुछ विषयों में वह अच्छी तरह से प्रदर्शन नहीं करती, वह इंटर्नशिप, लाइव प्रोजेक्ट्स और प्रतियोगिताओं में भाग लेने जैसी अनेक चीजों की कोशिश करती है।",
              "ka": "ಮೀರಾ ತನ್ನ ಕಾಲೇಜು ವರ್ಷಗಳಲ್ಲಿ ಕಷ್ಟಪಟ್ಟು ವಿಧ್ಯಾಭ್ಯಾಸ ಮಾಡುತ್ತಾಳೆ. ಅವಳು ಕೆಲವು ವಿಷಯಗಳಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಅಂಕಗಳನ್ನು ಪಡೆಯದಿದ್ದರೂ , ಅವಳು ಇಂಟರ್ನ್‌ಶಿಪ್, ಲೈವ್ ಪ್ರಾಜೆಕ್ಟ್‌ಗಳು ಮತ್ತು ಸ್ಪರ್ಧೆಗಳಲ್ಲಿ ಭಾಗವಹಿಸುವಂತಹ ಅನೇಕ ವಿಷಯಗಳಲ್ಲಿ ಪ್ರಯತ್ನವನ್ನು ಮುಂದುವರೆಸುತ್ತಾಳೆ.",
              "te": "మీరా తన కాలేజీ సంవత్సరాల్లో కష్టపడి పని చేస్తుంది. ఆమె కొన్ని సబ్జెక్ట్‌ లు బాగా చదవకపోయినా, ఇంటర్న్ షిప్‌లు, లైవ్ ప్రాజెక్ట్ లు మరియు పోటీలలో పాల్గొనడం వంటి అనేక విషయాలను ప్రయత్నిస్తుంది.",
              "or": "ମୀରା ତାଙ୍କ କଲେଜ ବର୍ଷରେ କଠିନ ପରିଶ୍ରମ କରନ୍ତି | ଯେତେବେଳେ ସେ କିଛି \n ବିଷୟଗୁଡ଼ିକରେ ଭଲ ପ୍ରଦର୍ଶନ କରନ୍ତି ନାହିଁ, ସେ ଇଣ୍ଟର୍ନସିପ୍, ଲାଇଭ୍ ପ୍ରୋଜେକ୍ଟ ଏବଂ ପ୍ରତିଯୋଗିତାରେ ଅଂଶଗ୍ରହଣ କରିବା ଭଳି ଅନେକ ଜିନିଷ ଚେଷ୍ଟା କରନ୍ତି |",
              "as": "মীৰাই তাইৰ কলেজৰ গোটেই বছৰবোৰত বহুত কষ্ট কৰে। যদিও তেওঁ কেইটামান বিষয়ত ভাল ফল নাপায়, তাই ইণ্টাৰ্নশ্বিপ, লাইভ প্ৰকল্প আৰু প্ৰতিযোগিতাত ভাগ লোৱাৰ দৰে বহুতো কাম চেষ্টা কৰে।",
              "gu": "મીરા તેના સમગ્ર કોલેજના વર્ષોમાં સખત મહેનત કરે છે. જ્યારે તે કેટલાક વિષયોમાં સારું પ્રદર્શન કરી શકતી નથી, ત્યારે તે ઇન્ટર્નશીપ, લાઇવ પ્રોજેક્ટ્સ અને સ્પર્ધાઓમાં ભાગ લેવા જેવી ઘણી બાબતોનો પ્રયાસ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_1_2",
              "hi": "D2_1_2",
              "ka": "D2_1_2",
              "te": "D2_1_2",
              "or": "D2_1_2",
              "as": "D2_1_2",
              "gu": "D2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She gets limited calls for interviews in her campus placements, although she has good marks and a diverse resume. Her experience with the placement cell is unpleasant.",
              "hi": "उन्हें अपने कैंपस प्लेसमेंट में साक्षात्कार के लिए सीमित कॉल मिलती है, हालांकि उसके पास अच्छे अंक और एक डाइवर्स रेज्यूमे है। प्लेसमेंट सेल के साथ उसका अनुभव अप्रिय है।",
              "ka": "ಅವಳು ಉತ್ತಮ ಅಂಕಗಳನ್ನು ಮತ್ತು ವೈವಿಧ್ಯಮಯ ರೆಸ್ಯೂಮ್‌ಗಳನ್ನು ಹೊಂದಿದ್ದರೂ, ಅವಳ ಕ್ಯಾಂಪಸ್ ಉದ್ಯೋಗಗಳಲ್ಲಿ ಸಂದರ್ಶನಗಳಿಗೆ ಸೀಮಿತ ಕರೆಗಳನ್ನು ಪಡೆಯುತ್ತಾಳೆ. ಪ್ಲೇಸ್‌ಮೆಂಟ್ ಸೆಲ್‌ನೊಂದಿಗಿನ ಅವಳ ಅನುಭವವು ಅಹಿತಕರವಾಗಿದೆ.",
              "te": "ఆమెకు మంచి మార్కులు మరియు విభిన్నమైన రెజ్యూమ్ ఉన్నప్పటికీ, ఆమెకి క్యాంపస్ ప్లేస్‌ మెంట్‌లలో ఇంటర్వ్యూల కోసం తక్కువ కాల్స్ వస్తాయి. ప్లేస్‌మెంట్ సెల్‌తో ఆమెకి దారుణమైన అనుభవం ఉంది.",
              "or": "ସେ ତାଙ୍କ କ୍ୟାମ୍ପସ୍ ପ୍ଲେସମେଣ୍ଟରେ ସାକ୍ଷାତକାର ପାଇଁ ସୀମିତ କଲ୍ ପାଆନ୍ତି, \n ଯଦିଓ ତାଙ୍କର ଭଲ ମାର୍କ ଏବଂ ଏକ ବିବିଧ ରିଜ୍ୟୁମ୍ ଅଛି | ପ୍ଲେସମେଣ୍ଟ ସେଲ୍ ସହିତ ତାଙ୍କର ଅଭିଜ୍ଞତା ଅପ୍ରୀତିକର ଅଟେ |",
              "as": "তাই তাইৰ কেম্পাচ প্লেচমেন্ট সাক্ষাৎকাৰৰ কাৰনে বহুত কম কল পায়, যদিও তাইৰ ভাল নম্বৰ আৰু এখন সুন্দৰ ৰিজিউমি আছে। প্লেচমেণ্ট চেলৰ লগত তাইৰ অভিজ্ঞতা বৰ বেয়া।",
              "gu": "તેને તેના કેમ્પસ પ્લેસમેન્ટમાં ઇન્ટરવ્યુ માટે ખૂબ ઓછા કોલ આવે છે, જો કે તે પાસે સારા માર્કસ અને વૈવિધ્યસભર રિઝ્યુમ છે. પ્લેસમેન્ટ સેલ સાથેનો તેનો અનુભવ અપ્રિય છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_1",
              "hi": "D2_2_1",
              "ka": "D2_2_1",
              "te": "D2_2_1",
              "or": "D2_2_1",
              "as": "D2_2_1",
              "gu": "D2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She realises that she is being discriminated against because of her gender and community.",
              "hi": "उसे लगता है कि उसके साथ लिंग और समुदाय के कारण भेदभाव किया जा रहा है।",
              "ka": "ತನ್ನ ಲಿಂಗ ಮತ್ತು ಸಮುದಾಯದ ಕಾರಣದಿಂದ ತನಗೆ ತಾರತಮ್ಯ ಮಾಡಲಾಗುತ್ತಿದೆ ಎನ್ನುವುದನ್ನು ಅವಳು ಅರಿತುಕೊಂಡಳು.",
              "te": "తను ఆడపిల్ల అవ్వడం మరియు కులం కారణంగా తాను వివక్షకు గురవుతున్నట్లు ఆమె గ్రహించింది.",
              "or": "ସେ ହୃଦୟଙ୍ଗମ କରିଛନ୍ତି ଯେ ତାଙ୍କର ଲିଙ୍ଗ ଏବଂ ସମ୍ପ୍ରଦାୟ ହେତୁ ତାଙ୍କୁ ଭେଦଭାବ\n  କରାଯାଉଛି।",
              "as": "তাই বুজি পালে যে তাই মহিলা হোৱা আৰু সম্প্ৰদায়ৰ কাৰণে তাইৰ সৈতে ভেদ ভাৱ কৰা হৈছে।",
              "gu": "તેને અહેસાસ થાય છે કે તેના જાતિ અને સમુદાયના કારણે તેની સાથે ભેદભાવ કરવામાં આવી રહ્યો છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_2",
              "hi": "D2_2_2",
              "ka": "D2_2_2",
              "te": "D2_2_2",
              "or": "D2_2_2",
              "as": "D2_2_2",
              "gu": "D2_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She reaches out to her friends in the Support Group. With their help, she applies to companies that offer her an equal opportunity as the rest of the students.",
              "hi": "वह  समर्थन समूह में अपने मित्रों तक पहुंचती है। उनकी सहायता से, वह उन कंपनियों में आवेदन करती है जो उन्हें शेष छात्रों के समान अवसर प्रदान करती हैं।",
              "ka": "ಅವಳು ಬೆಂಬಲ ಗುಂಪಿನಲ್ಲಿರುವ ತನ್ನ ಸ್ನೇಹಿತರನ್ನು ಅದರ ಬಗ್ಗೆ ಸಂಪರ್ಕಿಸುತ್ತಾಳೆ. ಅವರ ಸಹಾಯದಿಂದ, ಅವಳು ಉಳಿದ ವಿದ್ಯಾರ್ಥಿಗಳಂತೆ ಸಮಾನ ಅವಕಾಶವನ್ನು ನೀಡುವ ಕಂಪನಿಗಳಿಗೆ ಅರ್ಜಿ ಹಾಕುತ್ತಾಳೆ.",
              "te": "ఆమె సహాయం కోసం సపోర్ట్ గ్రూప్‌లోని తన స్నేహితులను కలుసుకుంటుంది. ఆమె మిగిలిన విద్యార్థులతో సమానమైన అవకాశాన్ని అందించే సంస్థలకు వర్తిస్తుంది.",
              "or": "ସେ ସପୋର୍ଟ ଗ୍ରୁପ୍ ରେ ଥିବା ସାଙ୍ଗମାନଙ୍କ ପାଖରେ ପହଞ୍ଚନ୍ତି | \n ସେମାନଙ୍କ ସାହାଯ୍ୟରେ, ସେ ସେହି କମ୍ପାନୀଗୁଡିକ ପାଇଁ ପ୍ରୟୋଗ କରନ୍ତି ଯାହା ତାଙ୍କୁ ଅନ୍ୟ ଛାତ୍ରମାନଙ୍କ ପରି ସମାନ ସୁଯୋଗ ପ୍ରଦାନ କରେ |",
              "as": "তাই সহায় গোটত তাইৰ বন্ধুবৰ্গৰ ওচৰলৈ গৈছিল। তেওঁলোকৰ সহায়ত, তেওঁ কোম্পানীবোৰৰ কাৰনে আবেদন কৰে যিয়ে তাইক বাকী শিক্ষাৰ্থীৰ নিচিনা সমান সুযোগ দিব বিচাৰে।",
              "gu": "તે સપોર્ટ ગ્રુપમાં તેના મિત્રો સુધી પહોંચે છે. તેમની મદદથી, તે એવી કંપનીઓને લાગુ પડે છે જે તેને બાકીના વિદ્યાર્થીઓની જેમ સમાન તક આપે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_3",
              "hi": "D2_2_3",
              "ka": "D2_2_3",
              "te": "D2_2_3",
              "or": "D2_2_3",
              "as": "D2_2_3",
              "gu": "D2_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "With their support, she gains the confidence to stand for herself. She manages to get a job at a big automobile manufacturing company in Guwahati.",
              "hi": "उनके समर्थन के साथ, वह अपने पक्ष को रख पाने का आत्मविश्वास प्राप्त करती है। वह गुवाहाटी में एक बड़ी ऑटोमोबाइल विनिर्माण कंपनी में नौकरी पाने में सफल होती है।",
              "ka": "ಅವರ ಬೆಂಬಲದಿಂದ ಅವಳು ತನ್ನ ಪರವಾಗಿ ತಾನು ನಿಲ್ಲುವ ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಪಡೀತಾಳೆ. ಗುವಾಹಟಿಯ ದೊಡ್ಡ ಆಟೋಮೊಬೈಲ್ ತಯಾರಿಕಾ ಕಂಪನಿಯಲ್ಲಿ ಅವಳು ಕೆಲಸ ಗಿಟ್ಟಿಸಿಕೊಳ್ತಾಳೆ.",
              "te": "వారి సపోర్ట్ తో, ఆమెకి తన కోసం తను నిలబడాలనే నమ్మకం వస్తుంది. ఆమె గౌహతిలోని ఒక పెద్ద ఆటోమొబైల్ తయారీ కంపెనీలో ఉద్యోగం సంపాదించింది.",
              "or": "ସେମାନଙ୍କ ସହଯୋଗରେ, ସେ ନିଜ ପାଇଁ ଠିଆ ହେବାର ଆତ୍ମବିଶ୍ୱାସ ଲାଭ କରନ୍ତି |\n  ସେ ଗୁଆହାଟୀର ଏକ ବଡ ଅଟୋମୋବାଇଲ୍ ଉତ୍ପାଦନକାରୀ କମ୍ପାନୀରେ ଚାକିରି ପାଇବାକୁ ନିଜକୁ ପ୍ରସ୍ତୁତ କରନ୍ତି |",
              "as": "তেওঁলোকৰ সহায়ত, তাই নিজৰ বাবে থিয় হোৱাৰ আত্মবিশ্বাস লাভ কৰে। তাই গুৱাহাটীৰ এটা ডাঙৰ অটোমোবাইল নিৰ্মাণ প্ৰতিষ্ঠানত চাকৰি পায়।",
              "gu": "તેમના સમર્થનથી, તે પોતાને માટે ઊભા રહેવાનો આત્મવિશ્વાસ મેળવે છે. તે ગુવાહાટીમાં એક મોટી ઓટોમોબાઈલ ઉત્પાદન કંપનીમાં નોકરી મેળવવાનું સંચાલન કરે છે."
            }
          }
        ]
      },
      "scene15": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_1",
              "hi": "D3_1_1",
              "ka": "D3_1_1",
              "te": "D3_1_1",
              "or": "D3_1_1",
              "as": "D3_1_1",
              "gu": "D3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "On the first day, Mira is given a tour of the company and its factory. Mira is fascinated to see all the machines and manufacturing of automobile parts.",
              "hi": "पहले दिन, मीरा को कंपनी और उसके कारखाने का दौरा दिया जाता है। मीरा सभी मशीनों और ऑटोमोबाइल भागों के निर्माण को देखने के बाद मोहित हो जाती है।",
              "ka": "ಮೊದಲ ದಿನ, ಮೀರಾಗೆ ಕಂಪನಿ ಮತ್ತು ಅದರ ಕಾರ್ಖಾನೆಯ ಪ್ರವಾಸವನ್ನು ಮಾಡಿಸಲಾಗುತ್ತದೆ. ಮೀರಾ ಎಲ್ಲಾ ಯಂತ್ರಗಳು ಮತ್ತು ಆಟೋಮೊಬೈಲ್ ಬಿಡಿಭಾಗಗಳ ತಯಾರಿಕೆಯನ್ನು ನೋಡಿ ಆಕರ್ಷಿತಳಾಗುತ್ತಾಳೆ",
              "te": "మొదటి రోజున, మీరా కంపెనీ మరియు దాని ఫ్యాక్టరీని చూసి వస్తారు. మీరా అన్ని యంత్రాలు మరియు ఆటోమొబైల్ విడిభాగాల తయారీని చూసి ఆకర్షితురాలైంది.",
              "or": "ପ୍ରଥମ ଦିନରେ ମୀରାଙ୍କୁ କମ୍ପାନୀ ଏବଂ ଏହାର କାରଖାନା ପରିଭ୍ରମଣ କରାଯାଏ |\n  ସମସ୍ତ ମେସିନ୍ ଏବଂ ଅଟୋମୋବାଇଲ୍ ପାର୍ଟସ୍ ଉତ୍ପାଦନ ଦେଖି ମୀରା ଆକର୍ଷିତ |",
              "as": "প্ৰথম দিনা, মীৰাক কোম্পানী ভালকৈ ঘুৰি চাবলৈ দিয়ে। মীৰাই সকলো যন্ত্ৰ আৰু অটোমোবাইলৰ অংশ কেনেকৈ বনাই তাকেই দেখি বহুত ভাল পাইছে।",
              "gu": "પહેલા દિવસે મીરાને કંપની અને તેના કારખાનામાં ફેવરે છે. મીરા તમામ મશીનો અને ઓટોમોબાઈલ ભાગોનું ઉત્પાદન જોઈને મંત્રમુગ્ધ છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_2",
              "hi": "D3_1_2",
              "ka": "D3_1_2",
              "te": "D3_1_2",
              "or": "D3_1_2",
              "as": "D3_1_2",
              "gu": "D3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Since the factory is a bit far from the city, she has to travel for long hours every day.",
              "hi": "चूंकि कारखाना शहर से थोड़ा दूर है, इसलिए उसे हर दिन लंबे समय तक यात्रा करनी पड़ती है।",
              "ka": "ಫ್ಯಾಕ್ಟರಿಯು ನಗರದಿಂದ ಸ್ವಲ್ಪ ದೂರದಲ್ಲಿರುವುದರಿಂದ, ಅವಳು ಪ್ರತಿದಿನ ಹಲವು ಗಂಟೆಗಳ ಕಾಲ ಪ್ರಯಾಣ ಮಾಡಬೇಕಾಗುತ್ತದೆ.",
              "te": "ఫ్యాక్టరీ సిటీకి కాస్త దూరంగా ఉండడంతో ప్రతిరోజూ చాలా గంటలు ప్రయాణం చేయాల్సి వస్తోంది.",
              "or": "କାରଖାନା ସହରଠାରୁ ଟିକିଏ ଦୂରରେ ଥିବାରୁ ତାଙ୍କୁ ପ୍ରତିଦିନ ଦୀର୍ଘ ଘଣ୍ଟା ଯାତ୍ରା\n  କରିବାକୁ ପଡିବ |",
              "as": "যিহেতু কাৰখানাটো চহৰৰ পৰা অলপ দূৰত, তাই প্ৰতিদিনে দীঘলীয়া সময় খৰচ কৰি অহা যোৱা কৰিব লাগিব।",
              "gu": "કારખાનુ શહેરથી થોડે દૂર હોવાથી તેણે દરરોજ લાંબા કલાકો સુધી મુસાફરી કરવી પડે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_3",
              "hi": "D3_1_3",
              "ka": "D3_1_3",
              "te": "D3_1_3",
              "or": "D3_1_3",
              "as": "D3_1_3",
              "gu": "D3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "On her job, she has a variety of tasks she is supposed to do, like making drawings for new components, monitoring the assembly line, and responding to the factory floor supervisor.",
              "hi": "अपनी नौकरी पर, उसके पास विभिन्न प्रकार के कार्य हैं, जैसे कि नए घटकों के लिए चित्र बनाना, असेंबली लाइन की निगरानी करना, और फैक्ट्री फर्श सुपरवाइज़र को प्रतिक्रया देना।",
              "ka": "ತನ್ನ ಕೆಲಸದಲ್ಲಿ, ಹೊಸ ಘಟಕಗಳಿಗೆ ರೇಖಾಚಿತ್ರಗಳನ್ನು ಮಾಡುವುದು, ಅಸೆಂಬ್ಲಿ ಲೈನ್ ಅನ್ನು ಮೇಲ್ವಿಚಾರಣೆ ಮಾಡುವುದು ಮತ್ತು ಕಾರ್ಖಾನೆಯ ಮೇಲ್ವಿಚಾರಕರಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುವಂತಹ ವಿವಿಧ ಕಾರ್ಯಗಳನ್ನು ಅವಳು ಮಾಡಬೇಕಾಗುತ್ತದೆ.",
              "te": "ఆమె ఉద్యోగంలో, కొత్త భాగాల కోసం డ్రాయింగ్‌లను రూపొందించడం, అసెంబ్లీ లైన్‌ను పర్యవేక్షించడం మరియు ఫ్యాక్టరీ ఫ్లోర్ సూపర్‌వైజర్‌కు సహాయం చేయడం వంటి అనేక రకాల పనులను ఆమె చేయవలసి ఉంటుంది.",
              "or": "ତାଙ୍କ ଚାକିରିରେ, ତାଙ୍କର ବିଭିନ୍ନ କାର୍ଯ୍ୟ ଅଛି ଯାହା ସେ କରିବା ଉଚିତ, ଯେପରିକି \n ନୂତନ ଉପାଦାନଗୁଡ଼ିକ ପାଇଁ ଚିତ୍ର ଆଙ୍କିବା, ଆସେମ୍ବଲି ଲାଇନ୍ ଉପରେ ନଜର ରଖିବା ଏବଂ କାରଖାନା ସୁପରଭାଇଜରଙ୍କୁ ପ୍ରତିକ୍ରିୟା ଦେବା |",
              "as": "তাইৰ চাকৰিত, তাই কৰিব লগা বহুত কাম আছে, যেনে নতুন উপাদানৰ কাৰনে ছবি অঁকা, এচেম্বলী লাইন চোৱা চিটা কৰা, আৰু কাৰখানাৰ ফ্লোৰ চুপাৰভাইজাৰক সঁহাৰি দিয়া।",
              "gu": "તેની પાસે નોકરી પર વિવિધ કાર્યો છે જેને તેણે  કરવાનું છે, જેમ કે નવી વસ્તુ માટે ડ્રોઇંગ બનાવવી, સરખું કરવાની લાઇનનું નિરીક્ષણ કરવું અને કારખાના જગ્યાનો ઉપરી સાહેબને જવાબ આપવો."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_4",
              "hi": "D3_1_4",
              "ka": "D3_1_4",
              "te": "D3_1_4",
              "or": "D3_1_4",
              "as": "D3_1_4",
              "gu": "D3_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She plans very well for these and makes schedules to do everything efficiently.",
              "hi": "वह इनके लिए बहुत अच्छी योजना बनाती है और सब कुछ कुशलता से करने के लिए शेड्यूल बनाती  है।",
              "ka": "ಅವಳು ಇವುಗಳ ಬಗ್ಗೆ ಚೆನ್ನಾಗಿ ಯೋಜಿಸುತ್ತಾಳೆ ಮತ್ತು ಎಲ್ಲವನ್ನೂ ಸಮರ್ಥವಾಗಿ ಮಾಡಲು ವೇಳಾಪಟ್ಟಿಗಳನ್ನು ಮಾಡಿಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "ఆమె వీటి కోసం చాలా బాగా ప్లాన్ చేస్తుంది మరియు ప్రతి ఒక్కటి సమర్థవంతంగా చేయడానికి షెడ్యూల్‌ లను చేస్తుంది.",
              "or": "ସେ ଏଗୁଡିକ ପାଇଁ ବହୁତ ଭଲ ଯୋଜନା କରନ୍ତି ଏବଂ ସବୁକିଛି ଦକ୍ଷତାର ସହିତ \n କରିବା ପାଇଁ କାର୍ଯ୍ୟସୂଚୀ ପ୍ରସ୍ତୁତ କରନ୍ତି |",
              "as": "তাই এইবোৰৰ বাবে বহুত ভাল চিন্তা কৰিছে আৰু সকলোবোৰ কৰিবলৈ ৰুটিন বনাই লৈছে।",
              "gu": "તે આ માટે ખૂબ સારી રીતે આયોજન કરે છે અને બધું કાર્યક્ષમ રીતે કરવા માટે સમયપત્રક બનાવે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_2_1",
              "hi": "D3_2_1",
              "ka": "D3_2_1",
              "te": "D3_2_1",
              "or": "D3_2_1",
              "as": "D3_2_1",
              "gu": "D3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira enjoys her job initially, but after a few months, she feels tired because she is given all the desk work. She has been to the factory floor only twice in the last few months.",
              "hi": "मीरा शुरुआत में अपनी नौकरी का आनंद लेती है, लेकिन कुछ महीनों में ही, वह थक जाती है क्योंकि उसे सभी डेस्क का काम दिया जाता है। वह पिछले कुछ महीनों में केवल दो बार कारखाने गई है।",
              "ka": "ಮೀರಾ ಆರಂಭದಲ್ಲಿ ತನ್ನ ಕೆಲಸದಿಂದಾಗಿ ತೃಪ್ತಿ ಹೊಂದುತ್ತಾಳೆ, ಆದರೆ ಕೆಲವು ತಿಂಗಳುಗಳ ನಂತರ ಅವಳು ದಣಿದಿದ್ದಾಳೆ ಏಕೆಂದರೆ ಅವಳಿಗೆ ಬರೀ ಡೆಸ್ಕ್ ಕೆಲಸವನ್ನು ನೀಡಲಾಗಿದೆ. ಅವಳು ಕಳೆದ ಕೆಲವು ತಿಂಗಳುಗಳಲ್ಲಿ ಎರಡು ಬಾರಿ ಮಾತ್ರ ಕಾರ್ಖಾನೆಯ ಅಂಗಣಕ್ಕೆ ಹೋಗಲು ಆಗಿದೆ.",
              "te": "మీరా మొదట్లో తన ఉద్యోగాన్ని ఆనందిస్తుంది, కానీ కొన్ని నెలల తర్వాత ఆమెకు డెస్క్ వర్క్ అంతా ఇవ్వడం వలన అలసిపోతుంది. ఆమె గత కొన్ని నెలల్లో కేవలం రెండుసార్లు మాత్రమే ఫ్యాక్టరీ ఫ్లోర్‌కి వెళ్లింది.",
              "or": "ମୀରା ପ୍ରାରମ୍ଭରେ ତାଙ୍କ ଚାକିରୀକୁ ଉପଭୋଗ କରନ୍ତି, କିନ୍ତୁ କିଛି ମାସ ପରେ, ସେ\n  କ୍ଲାନ୍ତ ଅନୁଭବ କରନ୍ତି କାରଣ ତାଙ୍କୁ ସମସ୍ତ ଡେସ୍କ କାମ ଦିଆଯାଏ | ସେ ଗତ କିଛି ମାସ ମଧ୍ୟରେ କେବଳ ଦୁଇଥର କାରଖାନା ଚଟାଣକୁ ଯାଇଥିଲେ |",
              "as": "মীৰাই প্ৰথমতে তাইৰ চাকৰিটো কৰি বিৰাট ভাল পায়, কিন্তু কেইমাহমানৰ পিছত, তাই ভাগৰুৱা অনুভৱ কৰে কিয়নো তাইক সকলো ডেস্কত বহি। কৰা কাম দিয়া হয়। তাই যোৱা কেইমাহমানত মাত্ৰ দুবাৰকাৰখানালৈ গৈছে।",
              "gu": "મીરા શરૂઆતમાં તેની નોકરીનો આનંદ માણે છે, પરંતુ થોડા મહિનાઓ પછી, તે થાક અનુભવે છે કારણ કે તેને આગળનું તમામ કામ આપવામાં આવે છે. છેલ્લા કેટલાક મહિનામાં તે માત્ર બે વાર કારખાનની જગ્યા પર આવી છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_2_2//",
              "hi": "D3_2_2//",
              "ka": "D3_2_2//",
              "te": "D3_2_2//",
              "or": "D3_2_2//",
              "as": "D3_2_2//",
              "gu": "D3_2_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "One day during the morning meeting...",
              "hi": "सुबह मीटिंग के समय एक दिन ...",
              "ka": "ಒಂದು ದಿವಸ ಮುಂಜಾನೆಯ ಮೀಟಿಂಗಿನಲ್ಲಿ",
              "te": "ఒకరోజు ఉదయం మీటింగ్ సమయంలో...",
              "or": "ଦିନେ ସକାଳର ସାକ୍ଷାତ ସମୟରେ ...",
              "as": "এদিন ৰাতিপুৱা মিটিংৰ সময়ত...",
              "gu": "એક દિવસ સવારની સભા દરમિયાન..."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Production Manager",
              "hi": "उत्पादन प्रबंधक",
              "ka": "ಪ್ರೊಡಕ್ಷನ್ ಮ್ಯಾನೇಜರ್",
              "te": "ప్రొడక్షన్ మేనేజర్",
              "or": "ଉତ୍ପାଦନ ପରିଚାଳକ",
              "as": "প্ৰডাকচন মেনেজাৰ",
              "gu": "ઉત્પાદક સંચાલક"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Supervisor",
              "hi": "सुपरवाइज़र ",
              "ka": "ಸೂಪರ್ವೈಸರ್",
              "te": "సూపర్‌వైజర్",
              "or": "ପର୍ଯ୍ୟବେକ୍ଷକ",
              "as": "চুপাৰভাইজাৰ",
              "gu": "સુપરવાઈઝર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "There’s been a delay in the production cycle.",
              "hi": "उत्पादन साइकिल में देर हुई है।",
              "ka": "ಉತ್ಪಾದನಾ ಚಕ್ರದಲ್ಲಿ ವಿಳಂಬವಾಗಿದೆ.",
              "te": "ప్రొడక్షన్ సైకిల్ ఆలస్యమవుతుంది. .",
              "or": "ଉତ୍ପାଦନ ଚକ୍ରରେ ବିଳମ୍ବ ହୋଇଛି |",
              "as": "উৎপাদনৰ কামত পলম হৈছে।",
              "gu": "ઉત્પાદન ચક્રમાં વિલંબ થયો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "We changed as per the new plan, but we’re still facing issues.",
              "hi": "हमने नई योजना के अनुसार बदलाव किया है, लेकिन हम अभी भी कठिनाइयों का सामना कर रहे हैं।",
              "ka": "ಹೊಸ ಯೋಜನೆಯ ಪ್ರಕಾರ ನಾವು ವಣೆ ಮಾಡಿದ್ದೇವೆ, ಆದರೆ ನಾವು ಇನ್ನೂ ಕೂಡ ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುತ್ತಿದ್ದೇವೆ",
              "te": "మనం కొత్త ప్లాన్ ప్రకారం మార్చాము, కానీ మనం ఇప్పటికీ సమస్యలను ఎదుర్కొంటున్నాము.",
              "or": "ନୂତନ ଯୋଜନା ଅନୁଯାୟୀ ଆମେ ପରିବର୍ତ୍ତନ କରିଛୁ, କିନ୍ତୁ ଆମେ ତଥାପି ସମସ୍ୟାର\n  ସମ୍ମୁଖୀନ ହେଉଛୁ |",
              "as": "আমি নতুন পৰিকল্পনা নিচিনাকৈ বেলেগকৈ কাম কৰিছিলোঁ, কিন্তু আমি এতিয়াও সমস্যাৰ সন্মুখীন হৈ আছোঁ।",
              "gu": "આપણે નવી યોજના મુજબ બદલાયા છીએ, પરંતુ આપણે હજુ પણ સમસ્યાઓનો સામનો કરી રહ્યાં છીએ."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_2_3////",
              "hi": "D3_2_3////",
              "ka": "D3_2_3////",
              "te": "D3_2_3////",
              "or": "D3_2_3////",
              "as": "D3_2_3////",
              "gu": "D3_2_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "What do you mean? We can’t have another production delay.",
              "hi": "आप क्या कहना चाहते हैं? हम एक और बार उत्पादन में देरी नहीं कर सकते हैं।",
              "ka": "ನಿನ್ನ ಮಾತಿನ ಅರ್ಥವೇನು? ನಾವು ಇನ್ನೊಂದು ಉತ್ಪಾದನಾ ವಿಳಂಬವನ್ನು ಹೊಂದಲು ಸಾಧ್ಯವಿಲ್ಲ.",
              "te": "మీ ఉద్దేశ్యం ఏమిటి? మనం మరొక ఉత్పత్తిని ఆలస్యం చేయకూడదు.",
              "or": "ତୁମେ କହିବାର ଅର୍ଥ କଣ? ଆମେ ଆଉ ଡେରି କରିପାରିବା ନାହି।",
              "as": "তুমি কি বুজাইছা? আমি আৰু কাম দেৰি কৰাব নোৱাৰোঁ।",
              "gu": "તું શું કહેવા માગે છે? અમે બીજા ઉત્પાદનમાં વિલંબ કરી શકતા નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Sir, I was reading about a new tool. This tool will give us real-time data from the assembly line and help us identify what is causing the delay.",
              "hi": "महोदय, मैं एक नए टूल के बारे में पढ़ रही थी। यह टूल हमें असेंबली लाइन से वास्तविक समय डेटा देगा और हमें देरी का कारण पहचानने में  सहायक होगा।",
              "ka": "ಸರ್, ನಾನು ಒಂದು ಹೊಸ ಉಪಕರಣದ ಬಗ್ಗೆ ಓದುತ್ತಿದ್ದೆ. ಈ ಉಪಕರಣವು ಅಸೆಂಬ್ಲಿ ಲೈನ್‌ನಿಂದ ನಮಗೆ ನೈಜ-ಸಮಯದ ಡೇಟಾವನ್ನು ನೀಡುತ್ತದೆ ಮತ್ತು ವಿಳಂಬಕ್ಕೆ ಕಾರಣವೇನು ಎನ್ನುವುದನ್ನು ಗುರುತಿಸಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "సార్, నేను కొత్త టూల్ గురించి చదువుతున్నాను. ఇది మనకి అసెంబ్లీ లైన్ నుండి రియల్-టైం డేటాను అందిస్తుంది మరియు ఆలస్యానికి కారణమేమిటో గుర్తించడంలో మనకి సహాయపడుతుంది.",
              "or": "ସାର, ମୁଁ ଏକ ନୂତନ ଉପକରଣ ବିଷୟରେ ପଢୁଥିଲି। ଏହି ଉପକରଣ ଟି ଆମକୁ \n ଆସେମ୍ବଲି ଲାଇନ ରେ ଏକ ସମୟ ସାପେକ୍ଷ ଡାଟା ଦେବା ଏବଂ ଡେରି ହେଇଥିବା ର କାରଣ \n ମଧ୍ୟ ଜଣାଇବା।",
              "as": "চাৰ, মই এটা নতুন সঁজুলিৰ কথা পঢ়ি আছিলো। এই সঁজুলিটোৱে আমাক এচেম্বলী লাইনৰ পৰা আমাৰ সঁচা ডাটা দিব আৰু কিহৰ কাৰনে পলম হৈছে তাক চিনি পোৱাত আমাক সহায় কৰিব।",
              "gu": "સર, હું એક નવા સાધન વિશે વાંચી રહી હતી  આ સાધન અમને ગોઠવણી કરવાની લાઇનમાંથી સાચા સમયના ડેટા આપશે અને વિલંબનું કારણ શું છે તે ઓળખવામાં મદદ કરશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "That sounds promising. Anuj, why don’t you do some more research on it to see if we can use the tool in our factory?",
              "hi": "यह सुनने में सही लगता है। अनुज, आप इस पर कुछ और शोध क्यों नहीं करते कि क्या हम अपने कारखाने में टूल का उपयोग कर सकते हैं?",
              "ka": "ಅದು ಭರವಸೆ ಮೂಡಿಸುತ್ತದೆ. ಅನುಜ್, ನಾವು ನಮ್ಮ ಫ್ಯಾಕ್ಟರಿಯಲ್ಲಿ ಉಪಕರಣವನ್ನು ಬಳಸಬಹುದೇ ಎನ್ನುವುದನ್ನು ನೋಡಲು ನೀವು ಅದರ ಬಗ್ಗೆ ಇನ್ನೂ ಸ್ವಲ್ಪ ಸಂಶೋಧನೆ ಏಕೆ ಮಾಡಬಾರದು?",
              "te": "అది ఆశాజనకంగా ఉంది. అనూజ్, మన ఫ్యాక్టరీలో ఆ టూల్ ఉపయోగించవచ్చో లేదో తెలుసుకోవడానికి మీరు దానిపై మరికొంత పరిశోధన ఎందుకు చేయకూడదు?",
              "or": "ତାହା ଲାଗୁଛି। ଅନୁଜ, ଯେପରି ଆମେ ଏହାକୁ ଆମ ଫ୍ୟାକ୍ତ୍ରି ରେ ବ୍ୟବହାର କରିପାରିବ,\n  ତୁମେ ଏହା ଉପରେ ଅଧିକ ଅନୁସନ୍ଧାନ କାହିଁକି କରୁନ?",
              "as": "এইটো শুনি ভাল লাগিছে। অনুজ, আমি আমাৰ কাৰখানাত এইটো সঁজুলি ব্যৱহাৰ কৰিব পাৰিম নে নাই গ'ম পাবলৈ আপুনি ইয়াৰ ওপৰত আৰু অলপ বিচাৰ নকৰে?",
              "gu": "તે આશાસ્પદ લાગે છે. અનુજ, તું તેના પર વધુ સંશોધન કેમ નથી કરતો કે આપને આપણાં કારખાનના સાધનનો ઉપયોગ કરી શકીએ કે કેમ?"
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_2_4",
              "hi": "D3_2_4",
              "ka": "D3_2_4",
              "te": "D3_2_4",
              "or": "D3_2_4",
              "as": "D3_2_4",
              "gu": "D3_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Both Mira and Anuj are shocked to hear that Anuj is given the responsibility even though it was Mira’s idea. They don’t know how to react.",
              "hi": "मीरा और अनुज दोनों यह सुनकर चौंक गए हैं कि जिम्मेदारी अनुज को दी गई है, भले ही यह मीरा का विचार था। वे नहीं जानते कि प्रतिक्रिया कैसे करें।",
              "ka": "ಇದು ಮೀರಾ ಅವರ ಆಲೋಚನೆಯಾಗಿದ್ದರೂ ಸಹ ಅನುಜ್‌ಗೆ ಜವಾಬ್ದಾರಿಯನ್ನು ನೀಡಲಾಗಿದೆ ಎಂದು ತಿಳಿದು ಮೀರಾ ಮತ್ತು ಅನುಜ್ ಇಬ್ಬರೂ ಆಘಾತಕ್ಕೊಳಗಾಗಿದ್ದಾರೆ. ಅವರಿಗೆ ಹೇಗೆ ಪ್ರತಿಕ್ರಿಯಿಸಬೇಕೆಂದು ತಿಳಿಯುತ್ತಿಲ್ಲ.",
              "te": "మీరా ఆలోచనే అయినప్పటికీ అనుజ్‌కి ఆ బాధ్యత అప్పగించడం విని మీరా మరియు అనుజ్ ఇద్దరూ షాక్ అయ్యారు. ఎలా స్పందించాలో వారికి తెలియలేదు.",
              "or": "ଉଭୟ ମୀରା ଏବଂ ଅନୁଜ ଶୁଣି ଆଶ୍ଚର୍ଯ୍ୟ ହୋଇଗଲେ ଯେ ଅନୁଜଙ୍କୁ ଦାୟିତ୍ଵ \n ଦିଆଯାଉଥିଲେ ହେଁ ଏହା ମୀରାଙ୍କ ପ୍ରସ୍ତାବ ଥିଲା | ସେମାନେ କିପରି ପ୍ରତିକ୍ରିୟା କରିବେ ଜାଣନ୍ତି ନାହିଁ |",
              "as": "মীৰা আৰু অনুজ দুয়োজনেই শুনি আচৰিত হৈছিল যে অনুজক দায়িত্ব দিয়া হৈছে যদিও এইটো মীৰায়ে কোৱা কথা আছিল। তেওঁলোকে কেনেদৰে বুলি কব লাগে নাজানে।",
              "gu": "મીરા અને અનુજ બંને એ સાંભળીને ચોંકી જાય છે કે અનુજને જવાબદારી સોંપવામાં આવે છે તેમ છતાં તે મીરાનો મંતવ્ય હતો. તેઓ જાણતા નથી કે કેવી રીતે પ્રતિક્રિયા કરવી."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_2_5",
              "hi": "D3_2_5",
              "ka": "D3_2_5",
              "te": "D3_2_5",
              "or": "D3_2_5",
              "as": "D3_2_5",
              "gu": "D3_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira feels the boss is being unfair to her. She feels that after two years of working hard, she deserves more responsibilities at her workplace.",
              "hi": "मीरा को लगता है कि बॉस उसके लिए सही नहीं है। वह अनुभव करती है कि दो वर्ष की कड़ी मेहनत के  बाद, वह अपने कार्यस्थल पर अधिक जिम्मेदारियों की अधिकारी  है।",
              "ka": "ಬಾಸ್ ತನಗೆ ಅನ್ಯಾಯ ಮಾಡುತ್ತಿದ್ದಾರೆ ಎಂದು ಮೀರಾ ಭಾವಿಸುತ್ತಾಳೆ. ಎರಡು ವರ್ಷಗಳ ಕಠಿಣ ಪರಿಶ್ರಮದ ನಂತರ, ಅವಳು ತನ್ನ ಕೆಲಸದ ಸ್ಥಳದಲ್ಲಿ ಹೆಚ್ಚಿನ ಜವಾಬ್ದಾರಿಗಳಿಗೆ ಅರ್ಹಳಾಗಿದ್ದೇನೆ ಎನ್ನುವುದು ಅವಳ ಭಾವನೆ ಆಗಿರುತ್ತದೆ.",
              "te": "బాస్ తనకు అన్యాయం చేస్తున్నాడని మీరా భావించింది. రెండేళ్లపాటు కష్టపడి పనిచేసిన తర్వాత, తన వర్క్ ప్లేస్ లో మరిన్ని బాధ్యతలకు ఆమెకి అర్హత ఉందని అనుకుంటుంది.",
              "or": "ମୀରା ଅନୁଭବ କରନ୍ତି ଯେ ମାଲିକ ତାଙ୍କ ପ୍ରତି ଅନ୍ୟାୟ କରୁଛନ୍ତି। ସେ ଅନୁଭବ କରନ୍ତି\n  ଯେ ଦୁଇ ବର୍ଷ ପରିଶ୍ରମ କରିବା ପରେ ସେ ନିଜ କର୍ମକ୍ଷେତ୍ରରେ ଅଧିକ ଦାୟିତ୍ଵର ଯୋଗ୍ୟ ଅଟନ୍ତି |",
              "as": "মীৰাৰ এনে লাগিছে যেন বছে তাইৰ প্ৰতি অন্যায় কৰিছে। তাই অনুভৱ কৰে যে দুবছৰ কঠোৰ পৰিশ্ৰম কৰাৰ পিছত, তাই ইয়াতকৈ বেছি কাম পাব লাগে।",
              "gu": "મીરાને લાગે છે કે સાહેબ તેની સાથે અન્યાય કરી રહ્યા છે. તેને લાગે છે કે બે વર્ષ સખત મહેનત કર્યા પછી, તે તેના કાર્યસ્થળ પર વધુ જવાબદારીઓને પાત્ર છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_3_1//",
              "hi": "D3_3_1//",
              "ka": "D3_3_1//",
              "te": "D3_3_1//",
              "or": "D3_3_1//",
              "as": "D3_3_1//",
              "gu": "D3_3_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "She is upset and decides to share the incident with Preeti and Lina.",
              "hi": "वह परेशान है और घटना को प्रीति और लीना के साथ साझा करने का निर्णय करती है।",
              "ka": "ಅವಳು ಅಸಮಾಧಾನಗೊಂಡಿದ್ದಾಳೆ ಹಾಗೂ ಈ  ಘಟನೆಯನ್ನು ಪ್ರೀತಿ ಮತ್ತು ಲೀನಾ ಜೊತೆ ಹಂಚಿಕೊಳ್ಳಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
              "te": "ఆమె బాధ పడింది మరియు జరిగిన విషయాన్ని ప్రీతి మరియు లీనాతో చెప్పాలని నిర్ణయించుకుంది.",
              "or": "ସେ ବିରକ୍ତ ହୋଇ ପ୍ରୀତି ଏବଂ ଲୀନାଙ୍କ ସହ ଏହି ଘଟଣା ବାଣ୍ଟିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି।",
              "as": "তাই মনত দুখ পাইছে আৰু ঘটনাটো প্ৰীতি আৰু লীনাক ক'ম বুলি ভাবিছে।",
              "gu": "તે નારાજ છે અને પ્રીતિ અને લીના સાથે આ ઘટના કહેવાનું નક્કી કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "After all this time and seeing my work, I thought they’d give me more responsibilities. But nothing seems to change.",
              "hi": "इतने समय के बाद और मेरे काम को देखकर, मैंने सोचा था कि वे मुझे और अधिक जिम्मेदारियां देंगे। लेकिन कुछ भी नहीं बदल रहा है।",
              "ka": "ಇಷ್ಟು ಸಮಯದ ನಂತರ ಮತ್ತು ನನ್ನ ಕೆಲಸವನ್ನು ನೋಡಿ, ಅವರು ನನಗೆ ಹೆಚ್ಚಿನ ಜವಾಬ್ದಾರಿಗಳನ್ನು ನೀಡುತ್ತಾರೆ ಎಂದು ನಾನು ಭಾವಿಸಿದ್ದೆ. ಆದರೆ ಏನೂ ಬದಲಾವಣೆ ಕಾಣುತ್ತಿಲ್ಲ.",
              "te": "ఇంత కాలం అయిన తర్వాత మరియు నా పనిని చూసి, వారు నాకు మరిన్ని బాధ్యతలు ఇస్తారని అనుకున్నాను. కానీ ఏమీ మారడం లేదు.",
              "or": "ଏହି ସବୁ ସମୟ ପରେ ଏବଂ ମୋର କାର୍ଯ୍ୟ ଦେଖିବା ପରେ, ମୁଁ ଭାବିଲି ସେମାନେ\n  ମୋତେ ଅଧିକ ଦାୟିତ୍ଵ ଦେବେ | କିନ୍ତୁ କିଛି ପରିବର୍ତ୍ତନ ହେଉନାହିଁ |",
              "as": "এই সকলো সময়ৰ পিছত আৰু মোৰ কাম দেখি, মই ভাবিছিলো যে তেওঁলোকে মোক বেছি কামৰ দায়িত্ব দিব। কিন্তু একো বদলি যেন নালাগে।",
              "gu": "આટલા સમય પછી અને મારું કામ જોઈને મને લાગ્યું કે તેઓ મને વધુ જવાબદારીઓ આપશે. પણ કશું બદલાતું જણાતું નથી."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_3_2////",
              "hi": "D3_3_2////",
              "ka": "D3_3_2////",
              "te": "D3_3_2////",
              "or": "D3_3_2////",
              "as": "D3_3_2////",
              "gu": "D3_3_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Mira, don't keep quiet about this. I think you should speak to your manager.",
              "hi": "मीरा, इस विषय में शांत मत रहो। मुझे लगता है कि तुमको अपने प्रबंधक से बात करनी चाहिए।",
              "ka": "ಮೀರಾ, ಈ ಬಗ್ಗೆ ಸುಮ್ಮನಿರಬೇಡ. ನೀನು ನಿಮ್ಮ ವ್ಯವಸ್ಥಾಪಕರೊಂದಿಗೆ ಮಾತನಾಡಬೇಕು ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "మీరా, దీని గురించి సైలెంట్ గా ఉండవద్దు. నువ్వు మీ మేనేజర్‌తో మాట్లాడాలని నేను అనుకుంటున్నాను.",
              "or": "ମୀରା, ଏ ବିଷୟରେ ଚୁପ୍ ରୁହ ନାହିଁ | ମୁଁ ଭାବୁଛି ତୁମେ ତୁମର ମ୍ୟାନେଜର ସହିତ\n  କଥା ହେବା ଉଚିତ୍ |",
              "as": "মীৰা, এই বিষয়ে চুপ নাথাকিবা। মই ভাবো তুমি তোমাৰ মেনেজাৰৰ লগত কথা পাতিব লাগে।",
              "gu": "મીરા, આ બાબતે ચૂપ ના રહે. મને લાગે છે કે તારે તારા મેનેજર સાથે વાત કરવી જોઈએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I agree with Lina. But I feel you should prepare for the conversation before going.",
              "hi": "मैं लीना से सहमत हूं। लेकिन मुझे लगता है कि तुमको जाने से पहले बातचीत के लिए तैयार होना चाहिए।",
              "ka": "ನಾನು ಲೀನಾ ಮಾತಿಗೆ ಒಪ್ಪುತ್ತೇನೆ. ಆದರೆ ಹೋಗುವ ಮೊದಲು ನೀನು ಸಂಭಾಷಣೆಗೆ ಸಿದ್ಧಳಾಗಿರಬೇಕು ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ",
              "te": "నేను లీనా చెప్పినదానికి ఒప్పుకుంటున్నాను. కానీ నువ్వు వెళ్లే ముందు ఏం మాట్లాడాలో ముందే సిద్ధం అవ్వాలని అనుకుంటున్నాను.",
              "or": "ମୁଁ ଲୀନା ସହିତ ସହମତ | କିନ୍ତୁ ମୁଁ ଅନୁଭବ କରୁଛି ଆପଣ ଯିବା ପୂର୍ବରୁ ବାର୍ତ୍ତାଳାପ\n  ପାଇଁ ପ୍ରସ୍ତୁତ ହେବା ଉଚିତ୍ |",
              "as": "মইও লীনাৰ কথাটোৱে ক'ম। কিন্তু মই অনুভৱ কৰোঁ যে তুমি যোৱাৰ আগতে কথা পতাৰ কাৰনে সাঁজু হোৱা ভাল।",
              "gu": "હું લીના સાથે સંમત છું. પરંતુ મને લાગે છે કે તારે જતા પહેલા વાતચીતની તૈયારી કરવી જોઈએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I’m so scared of sir. I don’t know how I’ll talk to him.",
              "hi": "मैं सर से बहुत डरती हूँ। मुझे नहीं पता कि मैं उनसे कैसे बात करूंगी।",
              "ka": "ನನಗೆ ಸಾರ್ ಕಂಡರೆ ತುಂಬಾ ಭಯ. ನಾನು ಅವರೊಂದಿಗೆ ಹೇಗೆ ಮಾತನಾಡುತ್ತೇನೆ ಎನ್ನುವುದು ನನಗೆ ತಿಳಿದಿಲ್ಲ.",
              "te": "సార్ అంటే నాకు చాలా భయంగా ఉంటుంది. నేను అతనితో ఎలా మాట్లాడాలో నాకు తెలియదు.",
              "or": "ମୁଁ ସାର୍ କୁ ବହୁତ ଡରୁଛି | ମୁଁ ଜାଣେ ନାହିଁ ମୁଁ ତାଙ୍କ ସହିତ କିପରି କଥା ହେବି |",
              "as": "মই চাৰক বহুত ভয় কৰোঁ। মই নাজানো মই তেওঁৰ লগত কেনেকৈ কথা পাতিম।",
              "gu": "મને સાહેબનો બહુ ડર લાગે છે. મને ખબર નથી કે હું તેની સાથે કેવી રીતે વાત કરીશ."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_3_4_multiselect",
              "hi": "D3_3_4_मल्टीसिलेक्ट ",
              "ka": "D3_3_4_multiselect",
              "te": "D3_3_4_multiselect",
              "or": "D3_3_4_multiselect",
              "as": "D3_3_4_multiselect",
              "gu": "D3_3_4_બહુ પસંદ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "All three of them come up with points that Mira can discuss with her manager. Pick two points that Mira should focus on for her discussion.",
              "hi": "वे तीनों उन बातों पर विचार करते हैं जो मीरा अपने प्रबंधक के साथ चर्चा कर सकती है।  उन दो विचारों को चुनें जिन पर मीरा को उनकी चर्चा के लिए ध्यान देना चाहिए।",
              "ka": "ಮೂವರೂ ಮೀರಾ ತನ್ನ ಮ್ಯಾನೇಜರ್‌ನೊಂದಿಗೆ ಚರ್ಚಿಸಬಹುದಾದ ಅಂಶಗಳ ಬಗ್ಗೆ ಒಮ್ಮತಕ್ಕೆ ಬರುತ್ತಾರೆ. ಮೀರಾ ತನ್ನ ಚರ್ಚೆಗೆ ಗಮನಹರಿಸಬೇಕಾದ ಎರಡು ಅಂಶಗಳನ್ನು ಆರಿಸಿ.",
              "te": "వారు ముగ్గురూ మీరా తన మేనేజర్‌తో మాట్లాడాల్సిన పాయింట్‌లతో ముందుకు వచ్చారు. మీరా తను మాట్లాడటం కోసం ఫోకస్ చేయాల్సిన రెండు పాయింట్లను ఎంచుకోండి.",
              "or": "ଏହି ତିନୋଟି ପଏଣ୍ଟ ସହିତ ମୀରା ତାଙ୍କ ମ୍ୟାନେଜରଙ୍କ ସହ ଆଲୋଚନା କରିପାରନ୍ତି |\n  ଦୁଇଟି ପଏଣ୍ଟ ବାଛନ୍ତୁ ଯାହା ମୀରା ତାଙ୍କ ଆଲୋଚନା ପାଇଁ ଧ୍ୟାନ ଦେବା ଉଚିତ୍ |",
              "as": "তেওঁলোক তিনিওজনে মীৰাই তাইৰ মেনেজাৰৰ লগত আলোচনা কৰিব পৰা বিষয়বোৰ লৈ আহে। মীৰাই তাইৰ আলোচনাৰ বাবে গুৰুত্ব দিব লগা দুটা বিষয় বাছনি কৰক।",
              "gu": "તે ત્રણેય મુદ્દાઓ સાથે આવે છે જેની મીરા તેના મેનેજર સાથે ચર્ચા કરી શકે છે. મીરાએ તેની ચર્ચા માટે બે મુદ્દાઓ પસંદ કરો જેના પર ધ્યાન કેન્દ્રિત કરવું જોઈએ."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Highlight your contributions to the company in the past two years.",
              "hi": "पिछले दो वर्षों में कंपनी में अपने योगदान की विशेष रूप से चर्चा करे।",
              "ka": "ಕಳೆದ ಎರಡು ವರ್ಷಗಳಲ್ಲಿ ಕಂಪನಿಗೆ ನಿನ್ನ ಕೊಡುಗೆಗಳನ್ನು ಹೈಲೈಟ್ ಮಾಡು.",
              "te": "గత రెండు సంవత్సరాలలో కంపెనీకి మీ సహకారాన్ని హైలైట్ చేయండి.",
              "or": "ବିଗତ ଦୁଇ ବର୍ଷ ମଧ୍ୟରେ କମ୍ପାନୀ ପାଇଁ ଆପଣଙ୍କର ଅବଦାନକୁ ହାଇଲାଇଟ୍ କରନ୍ତୁ |",
              "as": "যোৱা দুবছৰত কোম্পানীৰ কাৰনে কৰা ভাল কামবোৰ ভালকৈ দেখুৱাই দিয়া।",
              "gu": "છેલ્લા બે વર્ષમાં કંપનીમાં તમારા યોગદાનને હાઇલાઇટ કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Ask if there is a specific reason why you weren’t assigned the research.",
              "hi": "उसको शोध कार्य नहीं सौंपे जाने का विशिष्ट कारण पूछे।",
              "ka": "ನಿನಗೆ ಸಂಶೋಧನೆಯನ್ನು ಏಕೆ ನಿಯೋಜಿಸಲಾಗಿಲ್ಲ ಎನ್ನುವುದಕ್ಕೆ ನಿರ್ದಿಷ್ಟ ಕಾರಣವಿದೆಯೇ ಎಂದು ಕೇಳು.",
              "te": "మీకు పరిశోధన ఎందుకు అప్పగించలేదో సరైన కారణం ఉందా అని అడగండి.",
              "or": "ଅନୁସନ୍ଧାନର ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ କାରଣ ଅଛି କି ନାହିଁ ପଚାରନ୍ତୁ |",
              "as": "তোমাক বিচাৰ কৰা কামটো কিয় দিয়া হোৱা নাছিল তাৰ কোনো নিৰ্দিষ্ট কাৰণ আছে নেকি সুধিবা।",
              "gu": "પૂછો કે શું કોઈ ચોક્કસ કારણ છે કે શા માટે તને સંશોધન સોંપવામાં આવ્યું નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Share your knowledge about the tool and that you are capable to lead the research.",
              "hi": "टूल के बारे में अपनी जानकारी साझा करे और इस बात को कि वह अनुसंधान का नेतृत्व करने में सक्षम है।",
              "ka": "ಉಪಕರಣದ ಕುರಿತು ನಿನ್ನ ಜ್ಞಾನವನ್ನು ಹಂಚಿಕೊ ಮತ್ತು ಸಂಶೋಧನೆಯನ್ನು ಮುನ್ನಡೆಸಲು ನೀನು ಸಮರ್ಥಲಾಗಿದ್ದೇಯೇ ಎಂದು ತಿಳಿಸು.",
              "te": "టూల్ గురించి మీకు తెలిసిన విషయాలు చెప్పడం మరియు మీరు పరిశోధనకు నాయకత్వం వహించగల సామర్ధ్యం ఉందని నిరూపించుకోవడం.",
              "or": "ସାଧନ ବିଷୟରେ ଆପଣଙ୍କର ଜ୍ଞାନ ଅଂଶୀଦାର କରନ୍ତୁ ଏବଂ ଆପଣ ଅନୁସନ୍ଧାନର \n ନେତୃତ୍ୱ ନେବାକୁ ସକ୍ଷମ ଅଟନ୍ତି |",
              "as": "সঁজুলিটোৰ বিষয়ে আপোনাৰ জ্ঞান ভাগ বতৰা কৰক আৰু আপুনি গৱেষণাৰ নেতৃত্ব দিবলৈ সক্ষম।",
              "gu": "સાધન વિશે તમારા જ્ઞાનને બતાવો અને તું સંશોધનનું નેતૃત્વ કરવા સક્ષમ છો."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_3_4",
              "hi": "D3_3_4",
              "ka": "D3_3_4",
              "te": "D3_3_4",
              "or": "D3_3_4",
              "as": "D3_3_4",
              "gu": "D3_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira feels confident about talking to the manager. She realises that if they cannot value her role in the company, she will find a better workplace.",
              "hi": "मीरा प्रबंधक से बात करने के बारे में विश्वस्त है। वह सोचती है कि अगर वे कंपनी में उसको महत्वपूर्ण पद नहीं दे सकते, तो वह एक बेहतर कार्यस्थल ढूंढेगी।",
              "ka": "ಮೀರಾ ಮ್ಯಾನೇಜರ್ ಜೊತೆ ಮಾತನಾಡುವ ಬಗ್ಗೆ ವಿಶ್ವಾಸವನ್ನು ಹೊಂದಿದ್ದಾಳೆ. ಕಂಪನಿಯಲ್ಲಿ ತನ್ನ ಪಾತ್ರವನ್ನು ಗೌರವಿಸಲು ಸಾಧ್ಯವಾಗದಿದ್ದಲ್ಲಿ, ಅವಳು ಇನ್ನಷ್ಟು ಉತ್ತಮ ಕೆಲಸದ ಸ್ಥಳವನ್ನು ಕಂಡುಕೊಳ್ಳಬೇಕು ಎನ್ನುವುದನ್ನು ಆವಳು ಅರಿತುಕೊಂಡಿದ್ದಾಳೆ.",
              "te": "మీరా మేనేజర్‌తో మాట్లాడటం కోసం చాలా నమ్మకంగా ఉంది. కంపెనీలో తన జాబ్ కి విలువ ఇవ్వకపోతే వేరే జాబ్ వెతుక్కోవడం మంచిదని అనుకుంటుంది.",
              "or": "ମ୍ୟାନେଜରଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବାକୁ ମୀରା ଆତ୍ମବିଶ୍ୱାସୀ ଅନୁଭବ କରନ୍ତି | ସେ\n  ହୃଦୟଙ୍ଗମ କରିଛନ୍ତି ଯେ ଯଦି ସେମାନେ କମ୍ପାନୀରେ ତାଙ୍କ ଭୂମିକାକୁ ଗୁରୁତ୍ୱ ଦେଇ ପାରିବେ ନାହିଁ, ତେବେ ସେ ଏକ ଉତ୍ତମ କାର୍ଯ୍ୟକ୍ଷେତ୍ର ପାଇବେ |",
              "as": "মীৰাই মেনেজাৰৰ লগত কথা পাতি বলৈ আত্মবিশ্বাসী অনুভৱ কৰে। তাই বুজি পায় যে যদি তেওঁলোকে কোম্পানীটোত তাইৰ কামৰ মূল্য দিব নোৱাৰে, তেন্তে তাই বেলেগ এটা ভাল কৰ্মস্থান বিচাৰি যাব।",
              "gu": "મેનેજર સાથે વાત કરતાં મીરા આત્મવિશ્વાસ અનુભવે છે. તેને સમજાયું કે જો તેઓ કંપનીમાં તેની ભૂમિકાને મહત્વ આપી શકતા નથી, તો તે બીજી વધુ સારું કાર્યસ્થળ ખોળશે."
            }
          }
        ]
      },
      "scene16": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_1_1",
              "hi": "D4_1_1",
              "ka": "D4_1_1",
              "te": "D4_1_1",
              "or": "D4_1_1",
              "as": "D4_1_1",
              "gu": "D4_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira requests a meeting with her manager. She knows she is doing the right thing but is anxious about how the conversation will turn out.",
              "hi": "मीरा अपने प्रबंधक से मीटिंग करने का अनुरोध करती है। वह जानती है कि वह सही काम कर रही है लेकिन बातचीत क्या रूप ले लेगी, इस बारे में चिंतित है।",
              "ka": "ಮೀರಾ ಮ್ಯಾನೇಜರ್ ಜೊತೆ ಮಾತನಾಡುವ ಬಗ್ಗೆ ವಿಶ್ವಾಸವನ್ನು ಹೊಂದಿದ್ದಾಳೆ. ಕಂಪನಿಯಲ್ಲಿ ತನ್ನ ಕೆಲಸವನ್ನು ಗೌರವಿಸಲು ಸಾಧ್ಯವಾಗದಿದ್ದಲ್ಲಿ, ಇನ್ನಷ್ಟು ಉತ್ತಮ ಕೆಲಸದ ಸ್ಥಳವನ್ನು ಕಂಡುಕೊಳ್ಳಬೇಕು ಎನ್ನುವುದನ್ನು ಆವಳು ಅರಿತುಕೊಂಡಿದ್ದಾಳೆ.",
              "te": "మీరా తన మేనేజర్‌తో సమావేశం కోసం రిక్వెస్ట్ చేస్తుంది. ఆమె సరైన పని చేస్తుందని ఆమెకు తెలుసు, కానీ సమావేశం ఎలా జరుగుతుందో అని కంగారు పడుతుంది.",
              "or": "ମୀରା ତାଙ୍କ ପରିଚାଳକଙ୍କ ସହିତ ଏକ ସାକ୍ଷାତ ପାଇଁ ଅନୁରୋଧ କରନ୍ତି | ସେ ଜାଣନ୍ତି \n ଯେ ସେ ଠିକ୍ କାର୍ଯ୍ୟ କରୁଛନ୍ତି କିନ୍ତୁ ବାର୍ତ୍ତାଳାପ କିପରି ହେବ ସେ ବିଷୟରେ ଚିନ୍ତିତ |",
              "as": "মীৰাই তাইৰ মেনেজাৰৰ লগত এখন মিটিং কৰিবলৈ অনুৰোধ কৰিছে। তাই জানে যে তাই ভাল কাম কৰি আছে কিন্তু কথা খিনি কিদৰে কব তাক লৈ চিন্তাত আছে।",
              "gu": "મીરા તેના મેનેજર સાથે મીટિંગની વિનંતી કરે છે. તે જાણે છે કે તે યોગ્ય વસ્તુ કરી રહી છે પરંતુ વાતચીત કેવી રીતે બહાર આવશે તે અંગે ચિંતિત છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_1_2",
              "hi": "D4_1_2",
              "ka": "D4_1_2",
              "te": "D4_1_2",
              "or": "D4_1_2",
              "as": "D4_1_2",
              "gu": "D4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira starts the conversation by talking to him about how she feels stuck in her current position and feels ready to take up more responsibilities.",
              "hi": "मीरा  उनसे वार्तालाप शुरू कर यह बताती है कि उसको ऐसा लगता है कि वह वर्तमान पद पर काम करते हुए एक स्थान पर ही अटक गई है, और वह अधिक जिम्मेदारियों को लेने के लिए स्वयं को तैयार पाती है।",
              "ka": "ಮೀರಾ ತನ್ನ ಪ್ರಸ್ತುತ ಸ್ಥಾನದಲ್ಲಿ ತಾನು ಹೇಗೆ ಸಿಲುಕಿಕೊಂಡಿದ್ದೇನೆ ಮತ್ತು ಹೆಚ್ಚಿನ ಜವಾಬ್ದಾರಿಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಸಿದ್ಧಳಾಗಿದ್ದೇನೆ ಎನ್ನುವುದರ ಕುರಿತು ಮಾತನಾಡುವ ಮೂಲಕ ಸಂಭಾಷಣೆಯನ್ನು ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ.",
              "te": "మీరా తన ప్రస్తుత పొజిషన్‌లో ఎలా ఇబ్బంది పడుతున్నది మరియు మరిన్ని బాధ్యతలను తీసుకోవడానికి సిద్ధంగా ఉన్నట్లు అతనితో మాట్లాడటం ప్రారంభిస్తుంది.",
              "or": "ମୀରା ତାଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରି ତାଙ୍କ ସାମ୍ପ୍ରତିକ ସ୍ଥିତିରେ କିପରି ଅଟକି ରହିଛନ୍ତି\n  ଏବଂ ଅଧିକ ଦାୟିତ୍ take ଗ୍ରହଣ କରିବାକୁ ପ୍ରସ୍ତୁତ ଅନୁଭବ କରୁଛନ୍ତି |",
              "as": "মীৰাই তেওঁৰ সৈতে কথা পতা আৰম্ভ কৰে যে তাই কেনেদৰে তাইৰ বৰ্তমানৰ স্থিতিতে যেন বন্ধ হৈ আছে তেনে অনুভৱ কৰে আৰু অধিক দায়িত্ব ল'ব পাৰিব বুলি ভাবে।",
              "gu": "મીરા તેની સાથે વાત કરીને વાતચીત શરૂ કરે છે કે તે તેની વર્તમાન સ્થિતિમાં કેવી રીતે અટવાયેલી લાગે છે અને વધુ જવાબદારીઓ લેવા માટે તૈયાર છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_1_3////",
              "hi": "D4_1_3////",
              "ka": "D4_1_3////",
              "te": "D4_1_3////",
              "or": "D4_1_3////",
              "as": "D4_1_3////",
              "gu": "D4_1_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "You, youngsters, want everything to move so quickly. Do you know how many years we worked before we got responsibilities given to us?",
              "hi": "आप, युवा, सब कुछ इतनी जल्दी बदलना चाहते हैं। क्या आप जानते हैं कि हमें जिम्मेदारियां मिलने से पहले हमने कितने वर्षों तक काम किया था?",
              "ka": "ನೀವು, ಯುವಕರು, ಎಲ್ಲವೂ ಬೇಗನೆ ಚಲಿಸಬೇಕೆಂದು ಬಯಸುತ್ತೀರಿ. ನಮಗೆ ನೀಡಿರುವ ಜವಾಬ್ದಾರಿಯನ್ನು ಪಡೆಯುವ ಮೊದಲು ನಾವು ಎಷ್ಟು ವರ್ಷ ಕೆಲಸ ಮಾಡಿದ್ದೇವೆ ಗೊತ್ತಾ?",
              "te": "మీరు యువకులు, ప్రతి ఒక్కటి చాలా త్వరగా జరగాలనుకుంటారు. మనకిచ్చిన బాధ్యతలు రాకముందు మనం ఎన్ని సంవత్సరాలు పనిచేశామో తెలుసా?",
              "or": "ତୁମେ, ଯୁବକମାନେ, ସବୁକିଛି ଏତେ ଶୀଘ୍ର ଗତି କରିବାକୁ ଚାହୁଁଛ | ଆମକୁ ଦିଆଯାଇଥିବା\n  ଦାୟିତ୍ got ପାଇବା ପୂର୍ବରୁ ଆମେ କେତେ ବର୍ଷ କାମ କରିଥିଲୁ ଆପଣ ଜାଣନ୍ତି କି?",
              "as": "তোমালোক, যুৱকসকলে, সকলোবোৰ ইমান সোনকালে আগবাঢ়িব বিচাৰে। তুমি জাননানে আমি আমাক দিয়া দায়িত্ব পোৱাৰ আগতে কিমান বছৰ কাম কৰিছিলোঁ?",
              "gu": "તમે, યુવાનો, ઇચ્છો છો કે બધું ઝડપથી આગળ વધે. શું તમે જાણો છો કે આપણને જવાબદારીઓ મળી તે પહેલા આપણે કેટલા વર્ષ કામ કર્યું?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Sir, I have been working from the office mostly doing desk work for a long time now. I was told that once we complete our probation, we could start working in the production facility.",
              "hi": "महोदय, मैं कार्यालय में लंबे समय से अधिकतर काम डेस्क पर कर रही हूं। मुझे बताया गया था कि एक बार जब हम प्रोबेशन पूरा कर लेंगे, तो हम उत्पादन सुविधा में काम करना शुरू कर सकते हैं।",
              "ka": "ಸರ್, ನಾನು ಬಹಳ ದಿನಗಳಿಂದ ಕಛೇರಿಯಿಂದ ಹೆಚ್ಚಾಗಿ ಡೆಸ್ಕ್ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೇನೆ. ನಾವು ನಮ್ಮ ಪ್ರೊಭೇಷನರಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ ನಂತರ, ನಾವು ಉತ್ಪಾದನಾ ಸೌಲಭ್ಯದಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಬಹುದು ಎಂದು ನನಗೆ ತಿಳಿಸಲಾಗಿತ್ತು.",
              "te": "సార్, నేను చాలా కాలంగా ఆఫీసు నుండి ఎక్కువగా డెస్క్ వర్క్ చేస్తున్నాను. నేను నా ప్రొబేషన్ పూర్తి చేసిన తర్వాత ప్రొడక్షన్ ఫెసిలిటీలో పని చేయడం ప్రారంభించవచ్చని చెప్పబడి ఉంది.",
              "or": "ସାର୍, ମୁଁ ଦୀର୍ଘ ଦିନ ଧରି ଅଫିସ୍ ଠାରୁ ଅଧିକାଂଶ ସମୟ ଡେସ୍କ କାମ କରୁଥିଲି |\n  ମୋତେ କୁହାଯାଇଥିଲା ଯେ ଥରେ ଆମେ ଆମର ପରୀକ୍ଷା ସମାପ୍ତ କରିବା ପରେ, ଆମେ ଉତ୍ପାଦନ ସୁବିଧାରେ କାମ କରିବା ଆରମ୍ଭ କରିପାରିବା |",
              "as": "চাৰ, মই অফিচতে বহি বহুদিন ধৰি ডেস্কৰ কামে কৰি আছোঁ। মোক কোৱা হৈছিল যে আমি আমাৰ প্ৰবেচন সম্পূৰ্ণ কৰাৰ পিছত, আমি উৎপাদনত কাম কৰা আৰম্ভ কৰিব পাৰিম।",
              "gu": "સર, હું ઘણા સમયથી ઓફિસમાંથી કામ કરું છું અને મોટાભાગે ડેસ્કનું કામ કરું છું. મને કહેવામાં આવ્યું કે એકવાર અમે અમારું પ્રોબેશન પૂર્ણ કરી લઈએ, અમે ઉત્પાદન સુવિધામાં કામ કરવાનું શરૂ કરી શકીએ છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "The work you’re doing is also critical. What else do you want to work on?",
              "hi": "आप जो काम कर रही हैं वह भी महत्वपूर्ण है। आप और क्या काम करना चाहती हैं?",
              "ka": "ನೀವು ಮಾಡುತ್ತಿರುವ ಕೆಲಸವೂ ಮಹತ್ವದ್ದಾಗಿದೆ. ನೀವು ಇನ್ನೇನು ಕೆಲಸ ಮಾಡಲು ಬಯಸುತ್ತೀರಿ?",
              "te": "మీరు చేస్తున్న పని కూడా కీలకమైనదే. మీరు ఇంకా ఏం పని చేయాలనుకుంటున్నారు?",
              "or": "ଆପଣ କରୁଥିବା କାର୍ଯ୍ୟ ମଧ୍ୟ ଗୁରୁତର ଅଟେ | ଆପଣ ଆଉ କ’ଣ କାମ କରିବାକୁ \n ଚାହୁଁଛନ୍ତି?",
              "as": "তুমি কৰি থকা কামটোও গুৰুত্বপূৰ্ণ। তুমি আৰু কিহৰ ওপৰত কাম কৰিব বিচাৰা?",
              "gu": "તું જે કામ કરી રહ્યા છો તે પણ મહત્વપૂર્ણ છે. તું બીજું શું કામ કરવા માંગે છે?"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_1_4//",
              "hi": "D4_1_4//",
              "ka": "D4_1_4//",
              "te": "D4_1_4//",
              "or": "D4_1_4//",
              "as": "D4_1_4//",
              "gu": "D4_1_4//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Manager",
              "hi": "प्रबंधक",
              "ka": "ಮ್ಯಾನೇಜರ್",
              "te": "మేనేజర్",
              "or": "ମ୍ୟାନେଜର",
              "as": "মেনেজাৰ",
              "gu": "મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Sir, I would like to talk about the new tool I mentioned last week.",
              "hi": "महोदय, मैंने  पिछले सप्ताह जिस नए टूल के बारे में कहा था उस विषय में बात करना चाहूंगी।",
              "ka": "ಸರ್, ನಾನು ಕಳೆದ ವಾರ ಪ್ರಸ್ತಾಪಿಸಿದ ಹೊಸ ಉಪಕರಣದ ಬಗ್ಗೆ ಮಾತನಾಡಲು ಬಯಸುತ್ತೇನೆ.",
              "te": "సార్, నేను గత వారం చెప్పిన కొత్త టూల్ గురించి మాట్లాడాలనుకుంటున్నాను.",
              "or": "ସାର୍, ମୁଁ ଗତ ସପ୍ତାହରେ ଉଲ୍ଲେଖ କରିଥିବା ନୂତନ ଉପକରଣ ବିଷୟରେ କହିବାକୁ ଚାହେଁ |",
              "as": "চাৰ, মই যোৱা সপ্তাহত উল্লেখ কৰা নতুন সঁজুলিটোৰ বিষয়ে কথা পাতিব বিচাৰো।",
              "gu": "સાહેબ, હું ગયા અઠવાડિયે ઉલ્લેખિત નવા સાધન વિશે વાત કરવા માંગુ છું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "What about the tool? I have asked Anuj to do the feasibility study.",
              "hi": "टूल के बारे में क्या? मैंने अनुज से उसके उपयोग होने की संभावना पर अध्ययन करने के लिए कहा है।",
              "ka": "ಉಪಕರಣದ ಬಗ್ಗೆ ಏನು? ಕಾರ್ಯಸಾಧ್ಯತೆಯ ಅಧ್ಯಯನವನ್ನು ಮಾಡಲು ನಾನು ಅನುಜ್ ಅವರನ್ನು ಕೇಳಿದ್ದೇನೆ.",
              "te": "టూల్ గురించి ఏమిటి? నేను అనూజ్‌ని పరిశీలించమని అడిగాను.",
              "or": "ସାଧନ ବିଷୟରେ କଣ? ମୁଁ ଅନୁଜଙ୍କୁ ସମ୍ଭାବ୍ୟତା ଅଧ୍ୟୟନ କରିବାକୁ କହିଛି |",
              "as": "সঁজুলিটোৰ বিষয়ে কি? মই অনুজক তাৰ বিষয়ে অধ্যয়ন কৰিবলৈ কৈছো।",
              "gu": "સાધન વિશે શું? મેં અનુજને ફિઝિબિલિટી સ્ટડી કરવા કહ્યું છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_1_5_d",
              "hi": "D4_1_5_d",
              "ka": "D4_1_5_d",
              "te": "D4_1_5_d",
              "or": "D4_1_5_d",
              "as": "D4_1_5_d",
              "gu": "D4_1_5_d"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Mira say?",
              "hi": "मीरा को क्या कहना चाहिए?",
              "ka": "ಮೀರಾ ಏನು ಹೇಳಬೇಕು?",
              "te": "మీరా ఏం చెప్పాలి?",
              "or": "ମୀରା କଣ କହିବା ଉଚିତ?",
              "as": "মীৰাই কি কব লাগে?",
              "gu": "મીરાએ શું કહેવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Sir, I have researched the tool, so If I lead the study, it will be faster, and Anuj can assist me.",
              "hi": "महोदय, मैंने टूल का शोध किया है, इसलिए यदि मैं अध्ययन का नेतृत्व करती हूं, तो यह जल्दी होगा, और अनुज मेरी सहायता कर सकता है।",
              "ka": "ಸರ್, ನಾನು ಉಪಕರಣವನ್ನು ಸಂಶೋಧಿಸಿದ್ದೇನೆ, ಹಾಗಾಗಿ ನಾನು ಅಧ್ಯಯನವನ್ನು ಮುನ್ನಡೆಸಿದರೆ, ಅದು ವೇಗವಾಗಿರುತ್ತದೆ ಮತ್ತು ಅನುಜ್ ನನಗೆ ಸಹಾಯ ಮಾಡಬಹುದು.",
              "te": "సర్, నేను టూల్ పరిశోధించాను, కాబట్టి నేను స్టడీని లీడ్ చేస్తే అది త్వరగా జరుగుతుంది మరియు అనూజ్ నాకు సహాయం చేస్తాడు.",
              "or": "ସାର୍, ମୁଁ ସାଧନ ଉପରେ ଅନୁସନ୍ଧାନ କରିଛି, ତେଣୁ ଯଦି ମୁଁ ଅଧ୍ୟୟନର ନେତୃତ୍ୱ \n ନେବି, ଏହା ଶୀଘ୍ର ହେବ ଏବଂ ଅନୁଜ ମୋତେ ସାହାଯ୍ୟ କରିପାରିବେ |",
              "as": "চাৰ, মই সঁজুলিটোৰ ওপৰত গৱেষণা কৰিছো, গতিকে যদি মই অধ্যয়নটো কৰো , ই বেছি সোনকালেই হ'ব, আৰু অনুজে মোক সহায় কৰিব পাৰে।",
              "gu": "સાહેબ, મેં સાધન પર સંશોધન કર્યું છે, તેથી જો હું અભ્યાસનું નેતૃત્વ કરીશ, તો તે ઝડપી થશે, અને અનુજ મને મદદ કરી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Sir, any specific reason Anuj is leading the study and not me, while I have done the basic research already?",
              "hi": "महोदय, क्या किसी विशिष्ट कारण से अनुज अध्ययन का नेतृत्व कर रहा है और मैं नहीं, जबकि मैंने पहले से ही बुनियादी शोध किया है?",
              "ka": "ಸರ್, ನಾನು ಈಗಾಗಲೇ ಮೂಲಭೂತ ಸಂಶೋಧನೆಯನ್ನು ಮಾಡಿರುವಾಗ ಅನುಜ್ ಅಧ್ಯಯನವನ್ನು ಮುನ್ನಡೆಸುತ್ತಾರೆ ಹೊರತು ನಾನಲ್ಲ ಎನ್ನುವುದಕ್ಕೆ ಏನಾದರೂ ನಿರ್ದಿಷ್ಟ ಕಾರಣ ಇದೆಯೇ?",
              "te": "సార్, నేను ఇప్పటికే కొంత పరిశోధన పూర్తి చేసి ఉండగా, నేను కాకుండా అనుజ్ పరిశోదానని లీడ్ చేయడానికి ఏదైనా సరైన కారణం ఉందా?",
              "or": "ସାର୍, କୌଣସି ନିର୍ଦ୍ଦିଷ୍ଟ କାରଣ ଅନୁଜ୍ ଅଧ୍ୟୟନର ନେତୃତ୍ୱ ନେଉଛନ୍ତି ଏବଂ ମୁଁ ନୁହେଁ,\n  ଯେତେବେଳେ ମୁଁ ମୌଳିକ ଅନୁସନ୍ଧାନ କରିସାରିଛି?",
              "as": "চাৰ, অনুজে অধ্যয়নটো দিয়াৰ কোনো বিশেষ কাৰণ আছে নেকি, যে মই নাপালোঁ, যদিও মই ইয়াৰ বিষয়ে ভালকৈ পঢ়িছোঁ।",
              "gu": "સાહેબ, કોઈ ચોક્કસ કારણ અનુજ અભ્યાસમાં આગળ છે અને હું નહીં, જ્યારે મેં પહેલાથી જ મૂળભૂત સંશોધન કર્યું છે?"
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_1_6//",
              "hi": "D4_1_6//",
              "ka": "D4_1_6//",
              "te": "D4_1_6//",
              "or": "D4_1_6//",
              "as": "D4_1_6//",
              "gu": "D4_1_6//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Manager",
              "hi": "प्रबंधक",
              "ka": "ಮ್ಯಾನೇಜರ್",
              "te": "నిర్వాహకుడు",
              "or": "ମ୍ୟାନେଜର",
              "as": "মেনেজাৰ",
              "gu": "મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Mira, these are complex things, and I am only trying to protect you. Working on the factory floor is not the best job for girls.",
              "hi": "मीरा, ये जटिल चीजें हैं, और मैं केवल आपको सुरक्षित रखने की कोशिश कर रहा हूं। कारखाने के तल पर काम करना लड़कियों के लिए सबसे अच्छा काम नहीं है।",
              "ka": "ಮೀರಾ, ಇವು ಸಂಕೀರ್ಣ ವಿಷಯಗಳು, ಮತ್ತು ನಾನು ನಿಮ್ಮನ್ನು ರಕ್ಷಿಸಲು ಮಾತ್ರ ಪ್ರಯತ್ನಿಸುತ್ತಿದ್ದೇನೆ. ಕಾರ್ಖಾನೆಯ ಅಂಕಣದಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದು ಹುಡುಗಿಯರಿಗೆ ಉತ್ತಮ ಕೆಲಸವಲ್ಲ.",
              "te": "మీరా, ఇవి కష్టమైన విషయాలు మరియు నేను నిన్ను రక్షించడానికి మాత్రమే ప్రయత్నిస్తున్నాను. ఫ్యాక్టరీ అంతస్తులో పని చేయడం అమ్మాయిలకు మంచి పని కాదు.",
              "or": "ମୀରା, ଏଗୁଡ଼ିକ ଜଟିଳ ଜିନିଷ, ଏବଂ ମୁଁ କେବଳ ତୁମକୁ ସୁରକ୍ଷା ଦେବାକୁ ଚେଷ୍ଟା କରୁଛି |\n  କାରଖାନା ଚଟାଣରେ କାମ କରିବା ଝିଅମାନଙ୍କ ପାଇଁ ସର୍ବୋତ୍ତମ କାମ ନୁହେଁ |",
              "as": "মীৰা, এইবোৰ টান বস্তু, আৰু মই কেৱল তোমাৰ ভালৰ কাৰনে কৰি আছোঁ। কাৰখানাৰ মজিয়াত কাম কৰাটো ছোৱালীৰ কাসনে ভাল কাম নহয়।",
              "gu": "મીરા, આ જટિલ વસ્તુઓ છે, અને હું ફક્ત તારી સુરક્ષા કરવાનો પ્રયાસ કરી રહ્યો છું. ફેક્ટરીનામાં પર કામ કરવું એ છોકરીઓ માટે શ્રેષ્ઠ કામ નથી."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_1_7_D",
              "hi": "D4_1_7_D",
              "ka": "D4_1_7_D",
              "te": "D4_1_7_D",
              "or": "D4_1_7_D",
              "as": "D4_1_7_D",
              "gu": "D4_1_7_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Mira say?",
              "hi": "मीरा को क्या कहना चाहिए?",
              "ka": "ಮೀರಾ ಏನು ಹೇಳಬೇಕು?",
              "te": "మీరా ఏం చెప్పాలి?",
              "or": "ମୀରା କଣ କହିବା ଉଚିତ?",
              "as": "মীৰাই কি কব লাগে?",
              "gu": "મીરાએ શું કહેવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Sir, I don’t understand why you would hire women if you feel like they can’t work in the factory?",
              "hi": "महोदय, मुझे समझ में नहीं आता कि यदि आपको लगता है कि महिलाएं कारखाने में काम नहीं कर सकती तो आप उनको नौकरी पर क्यों लेते हैं?",
              "ka": "ಸರ್, ಫ್ಯಾಕ್ಟರಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ ಎಂದು ನಿಮಗೆ ಅನಿಸಿದರೆ ನೀವು ಮಹಿಳೆಯರನ್ನು ಏಕೆ ನೇಮಿಸಿಕೊಳ್ಳುತ್ತೀರಿ ಎಂದು ನನಗೆ ಅರ್ಥವಾಗುತ್ತಿಲ್ಲ?",
              "te": "సార్, మహిళలు ఫ్యాక్టరీలో పని చేయలేరని మీకు అనిపిస్తే మీరు వారిని ఎందుకు తీసుకుంటారో నాకు అర్థం కావడం లేదు?",
              "or": "ସାର୍, ମୁଁ ବୁଝିପାରୁ ନାହିଁ ଯଦି ଆପଣ ଅନୁଭବ କରନ୍ତି ଯେ ସେମାନେ କାରଖାନାରେ\n  କାମ କରିପାରିବେ ନାହିଁ?",
              "as": "চাৰ, মই বুজি নাই পোৱা যে আপুনি মহিলাসকলক কিয় নিযুক্তি দিয়ে যদি আপুনি ভাবে তেওঁলোকে কাৰখানাত কাম কৰিব নোৱাৰে?",
              "gu": "સાહેબ, મને સમજાતું નથી કે જો તેમને લાગે કે તેઓ કારખાનામાં કામ કરી શકે નહીં તો તું શા માટે મહિલાઓને નોકરીએ રાખશો?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Sir, I have prior internship experience working in a factory. I got this job based on that experience.",
              "hi": "महोदय, मेरे पास कारखाने में काम करने का पूर्व इंटर्नशिप अनुभव है। मुझे उस अनुभव के आधार पर यह काम मिला।",
              "ka": "ಸರ್, ನಾನು ಕಾರ್ಖಾನೆಯಲ್ಲಿ ಮೊದಲೇ ಇಂಟರ್ನ್‌ಶಿಪ್  ಕೆಲಸ ಮಾಡಿದ ಅನುಭವವನ್ನು ಹೊಂದಿದ್ದೇನೆ. ಆ ಅನುಭವದ ಆಧಾರದ ಮೇಲೆ ನನಗೆ ಈ ಕೆಲಸ ಸಿಕ್ಕಿದೆ.",
              "te": "సర్, నాకు ముందుగా ఫ్యాక్టరీలో పనిచేసిన అనుభవం ఉంది. ఆ అనుభవంతో నాకు ఈ ఉద్యోగం వచ్చింది.",
              "or": "ସାର୍, ମୋର ଏକ କାରଖାନାରେ କାର୍ଯ୍ୟ କରିବାର ପୂର୍ବ ଇଣ୍ଟର୍ନସିପ୍ ଅଭିଜ୍ଞତା ଅଛି | \n ସେହି ଅଭିଜ୍ଞତା ଉପରେ ଆଧାର କରି ମୁଁ ଏହି ଚାକିରି ପାଇଲି |",
              "as": "চাৰ, মোৰ এটা কাৰখানাত কাম কৰাৰ আগতে ইণ্টাৰ্নশ্বিপৰ অভিজ্ঞতা আছে। মই সেই অভিজ্ঞতালৈ এই চাকৰিটো পাইছিলো।",
              "gu": "સર, મને કારખાનામાં કામ કરવાનો અગાઉનો અનુભવ છે. એ અનુભવના આધારે મને આ નોકરી મળી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.tag",
            "value": {
              "en": "Challenging Stereotypes",
              "hi": "रूढ़िवादिता को चुनौती",
              "ka": "ಚಾಲೆಂಜಿಂಗ್ ಸ್ಟೀರಿಯೊಟೈಪ್ಸ್",
              "te": "ఛాలెంజింగ్ స్టీరియోటైప్స్",
              "or": "ଷ୍ଟେରିଓଟାଇପ୍ ଚ୍ୟାଲେଞ୍ଜ୍ |",
              "as": "ষ্টেৰিওটাইপৰ বাধা",
              "gu": "પડકારરૂપ સ્ટીરિયોટાઇપ્સ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.tag",
            "value": {
              "en": "Workspace Negotiation",
              "hi": "कार्यक्षेत्र  वार्ता ",
              "ka": "ಕಾರ್ಯಸ್ಥಳದಲ್ಲಿನ  ಸಂಧಾನ",
              "te": "వర్క్ స్పేస్ నెగోషియేషన్",
              "or": "କାର୍ଯ୍ୟକ୍ଷେତ୍ର ବୁଝାମଣା |",
              "as": "কৰ্মক্ষেত্ৰ মীমাংসা",
              "gu": "કામ કરવાની જગ્યા વાટાઘાટો"
            }
          }
        ]
      },
      "scene17": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Workspace Negotiation",
              "hi": "कार्यक्षेत्र वार्ता ",
              "ka": "ಕಾರ್ಯಸ್ಥಳದಲ್ಲಿನ  ಸಂಧಾನ",
              "te": "వర్క్ స్పేస్ నెగోషియేషన్",
              "or": "କାର୍ଯ୍ୟକ୍ଷେତ୍ର ବୁଝାମଣା |",
              "as": "কৰ্মক্ষেত্ৰ মীমাংসা",
              "gu": "કામ કરવાની જગ્યા વાટાઘાટો"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Manager",
              "hi": "प्रबंधक",
              "ka": "ಮ್ಯಾನೇಜರ್",
              "te": "మేనేజర్",
              "or": "ମ୍ୟାନେଜର",
              "as": "মেনেজাৰ",
              "gu": "મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Hmm. Let me think about it, and I'll let you know in a few days about my decision.",
              "hi": "हम्म। मुझे इसके बारे में सोचने दो, और मैं आपको अपना निर्णय कुछ दिनों में बता दूंगा।",
              "ka": "ಹ್ಮ್ಮ. ನಾನು ಅದರ ಬಗ್ಗೆ ಯೋಚಿಸುತ್ತೇನೆ ಮತ್ತು ನನ್ನ ನಿರ್ಧಾರದ ಬಗ್ಗೆ ಕೆಲವೇ ದಿನಗಳಲ್ಲಿ ನಿಮಗೆ ತಿಳಿಸುತ್ತೇನೆ.",
              "te": "సరే. నేను దాని గురించి ఆలోచించనివ్వండి మరియు నా నిర్ణయాన్ని కొన్ని రోజుల్లో మీకు తెలియజేస్తాను.",
              "or": "ହମ୍। ମୋତେ ଏହା ବିଷୟରେ ଚିନ୍ତା କରିବାକୁ ଦିଅ, ଏବଂ ମୁଁ ମୋର ନିଷ୍ପତ୍ତି ବିଷୟରେ \n ଆପଣଙ୍କୁ କିଛି ଦିନ ମଧ୍ୟରେ ଜଣାଇବି |",
              "as": "হুম। মোক এই বিষয়ে ভাবিবলৈ দিয়া, আৰু মই তোমাক মোৰ সিদ্ধান্তৰ বিষয়ে কেইদিনমানৰ ভিতৰত জনাম।",
              "gu": "હમમ. મને તેના વિશે વિચારવા દો, અને હું તમને મારા નિર્ણય વિશે થોડા દિવસોમાં જણાવીશ."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4a_1_2//",
              "hi": "D4a_1_2//",
              "ka": "D4a_1_2//",
              "te": "D4a_1_2//",
              "or": "D4a_1_2//",
              "as": "D4a_1_2//",
              "gu": "D4a_1_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "A few days later, the manager calls Mira for a meeting.",
              "hi": "कुछ दिनों बाद, प्रबंधक मीरा को एक मीटिंग के लिए बुलाता है।",
              "ka": "ಕೆಲವು ದಿನಗಳ ನಂತರ, ಮ್ಯಾನೇಜರ್ ಮೀರಾ ಅವರನ್ನು ಸಭೆಗೆ ಕರೆಯುತ್ತಾರೆ.",
              "te": "కొన్ని రోజుల తరువాత, మేనేజర్ మీరాను మీటింగ్ కోసం పిలుస్తారు.",
              "or": "କିଛି ଦିନ ପରେ, ମ୍ୟାନେଜର ମିରାଙ୍କୁ ଏକ ସଭା ପାଇଁ ଡାକିଲେ |",
              "as": "কেইদিনমানৰ পিছত, মেনেজাৰে মীৰাক এখন সভাৰ বাবে মাতিলে।",
              "gu": "થોડા દિવસો પછી, મેનેજર મીરાને મીટિંગ માટે બોલાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Manager",
              "hi": "प्रबंधक",
              "ka": "ಮ್ಯಾನೇಜರ್",
              "te": "మేనేజర్",
              "or": "ମାନେଜର",
              "as": "মেনেজাৰ",
              "gu": "મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Manager",
              "hi": "प्रबंधक",
              "ka": "ಮ್ಯಾನೇಜರ್",
              "te": "మేనేజర్",
              "or": "ମ୍ୟାନେଜର",
              "as": "মেনেজাৰ",
              "gu": "મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Mira, since Anuj has already started working on the tool, I suggest you work under him.",
              "hi": "मीरा, चूंकि अनुज ने पहले ही टूल पर काम करना शुरू कर दिया है, मेरा सुझाव है कि आप उसके अधीन काम करें।",
              "ka": "ಮೀರಾ, ಅನುಜ್ ಈಗಾಗಲೇ ಟೂಲ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿರುವುದರಿಂದ, ಅವನ ಅಡಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ನಾನು ನಿಮಗೆ ಸಲಹೆ ನೀಡುತ್ತೇನೆ.",
              "te": "మీరా, అనుజ్ ఇప్పటికే టూల్‌పై పని చేయడం ప్రారంభించాడు కాబట్టి, నువ్వు అతని క్రింద పని చేయాలని నేను సూచిస్తున్నాను.",
              "or": "ମୀରା, ଯେହେତୁ ଅନୁଜ ସାଧନ ଉପରେ କାମ କରିବା ଆରମ୍ଭ କରିସାରିଛନ୍ତି, ମୁଁ \n ପରାମର୍ଶ ଦେଉଛି ତୁମେ ତାଙ୍କ ଅଧୀନରେ କାମ କର |",
              "as": "মীৰা, যিহেতু অনুজে টুলটোত কাম কৰা আৰম্ভ কৰিছে, মই তোমাক তাৰ সহায় কৰিবলৈ পৰামৰ্শ দিওঁ।",
              "gu": "મીરા, અનુજે પહેલેથી જ ટૂલ પર કામ કરવાનું શરૂ કરી દીધું છે, તેથી હું તને તેની નીચે કામ કરવાની સલાહ આપું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "But sir, as I shared in our last meeting, I feel it'll be faster if I lead the project.",
              "hi": "लेकिन महोदय, जैसा कि मैंने अपनी पिछली मीटिंग में साझा किया था, मुझे लगता है कि अगर मैं प्रोजेक्ट का नेतृत्व करती हूं तो यह जल्दी होगा।",
              "ka": "ಆದರೆ ಸರ್, ನಮ್ಮ ಕೊನೆಯ ಸಭೆಯಲ್ಲಿ ನಾನು ಹಂಚಿಕೊಂಡಂತೆ, ನಾನು ಯೋಜನೆಯನ್ನು ಮುನ್ನಡೆಸಿದರೆ ಅದು ವೇಗವಾಗಿರುತ್ತದೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "కానీ సార్, మనకి చివరిగా జరిగిన సమావేశంలో నేను చెప్పినట్టుగా, నేను ప్రాజెక్ట్ కి నాయకత్వం వహిస్తే అది త్వరగా జరుగుతుందని అనుకుంటున్నాను.",
              "or": "କିନ୍ତୁ ସାର୍, ଯେହେତୁ ମୁଁ ଆମର ଶେଷ ସଭାରେ ଅଂଶୀଦାର କରିଥିଲି, ମୁଁ ଅନୁଭବ\n  କରେ ଯଦି ମୁଁ ଏହି ପ୍ରକଳ୍ପର ନେତୃତ୍ୱ ନେବି ତେବେ ଏହା ଶୀଘ୍ର ହେବ |",
              "as": "কিন্তু চাৰ, মই সেইদিনা কোৱাৰ দৰে, মই অনুভৱ কৰো যে যদি মই প্ৰকল্পটোৰ নেতৃত্ব দিওঁ তেন্তে ই দবেছি সোনকালে হ'ব।",
              "gu": "પરંતુ સાહેબ, જેમ મેં આપણી છેલ્લી મીટિંગમાં બતાવ્યુ હતું, મને લાગે છે કે જો હું પ્રોજેક્ટનું નેતૃત્વ કરીશ તો તે વધુ ઝડપી બનશે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Wait, let me finish. There is also another option - if you want to work in the factory, I have an opportunity for you.",
              "hi": "रुको, मुझे खत्म करने दो। एक और विकल्प भी है - यदि आप कारखाने में काम करना चाहती हैं, तो मेरे पास आपके लिए एक अवसर है।",
              "ka": "ನಿರೀಕ್ಷಿಸಿ, ನಾನು ಮುಗಿಸುತ್ತೇನೆ. ಇನ್ನೊಂದು ಆಯ್ಕೆಯೂ ಇದೆ - ನೀವು ಕಾರ್ಖಾನೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಬಯಸಿದರೆ, ನಿಮಗಾಗಿ ನನ್ನ ಬಳಿ ಒಂದು ಅವಕಾಶವಿದೆ.",
              "te": "ఆగండి, నన్ను పూర్తి చేయనివ్వండి. మరొక ఆప్షన్ కూడా ఉంది - నువ్వు ఫ్యాక్టరీలో పని చేయాలనుకుంటే, నీ కోసం నా దగ్గర ఒక అవకాశం ఉంది.",
              "or": "ଅପେକ୍ଷା କର, ମୋତେ ସମାପ୍ତ କରିବାକୁ ଦିଅ | ଅନ୍ୟ ଏକ ବିକଳ୍ପ ମଧ୍ୟ ଅଛି - ଯଦି \n ଆପଣ କାରଖାନାରେ କାମ କରିବାକୁ ଚାହାଁନ୍ତି, ତେବେ ଆପଣଙ୍କ ପାଇଁ ମୋ ପାଖେ ଏକ ସୁଯୋଗ ଅଛି |",
              "as": "ৰবা, মোক শেষ কৰিবলৈ দিয়ক। আন এটা উপায়ও আছে - যদি তুমি কাৰখানাত কাম কৰিব বিচাৰা, মোৰ ওচৰত তোমাৰ বাবে এটা সুযোগ আছে।",
              "gu": "રાહ જુઓ, મને પૂરું કરવા દો. બીજો વિકલ્પ પણ છે - જો તું કારખાનામાં કામ કરવા માગે છે, તો મારી પાસે તારી માટે એક તક છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4a_1_3////",
              "hi": "D4a_1_3////",
              "ka": "D4a_1_3////",
              "te": "D4a_1_3////",
              "or": "D4a_1_3////",
              "as": "D4a_1_3////",
              "gu": "D4a_1_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "My colleague, from the other factory, wants an assistant engineer and I think you are a good fit. If you are interested in that position, I can inform him. But you'll have to move out of Guwahati for that.",
              "hi": "अन्य कारखाने से मेरा सहयोगी, एक सहायक अभियंता/ असिस्टेंट इंजीनिअर चाहता है और मुझे लगता है कि आप उसके लिए पूरी तरह सही हैं। यदि आप उस पद में रुचि रखती हैं, तो मैं उसे सूचित कर सकता हूं। लेकिन आपको इसके लिए गुवाहाटी से बाहर निकलना होगा।",
              "ka": "ನನ್ನ ಸಹೋದ್ಯೋಗಿ, ಇನ್ನೊಂದು ಕಾರ್ಖಾನೆಯಿಂದ, ಸಹಾಯಕ ಇಂಜಿನಿಯರ್ ಬೇಕು ಎಂದಿದ್ದು  ನೀವು ಅದಕ್ಕೆ ಚೆನ್ನಾಗಿ ಹೊಂಡುತ್ತೀರಿ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ನೀವು ಆ ಸ್ಥಾನದಲ್ಲಿ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದರೆ, ನಾನು ಅವರಿಗೆ ತಿಳಿಸಬಹುದು. ಆದರೆ ಅದಕ್ಕಾಗಿ ನೀವು ಗುವಾಹಟಿಯಿಂದ ಹೊರ ಹೋಗಬೇಕಾಗುತ್ತದೆ.",
              "te": "వేరే ఫ్యాక్టరీకి చెందిన నా సహోద్యోగికి అసిస్టెంట్ ఇంజనీర్ కావాలి మరియు నువ్వు దానికి బాగా సరిపోతావని నేను భావిస్తున్నాను. నీకు ఆ జాబ్ మీద ఆసక్తి ఉంటే నేను అతనికి చెబుతాను. కానీ నువ్వు దాని కోసం గౌహతి వదిలి వెళ్ళాలి.",
              "or": "ମୋର ସହକର୍ମୀ, ଅନ୍ୟ କାରଖାନାରୁ ଆସିଷ୍ଟାଣ୍ଟ ଇଞ୍ଜିନିୟର ଚାହୁଁଛନ୍ତି ଏବଂ ମୁଁ ଭାବୁଛି\n  ତୁମେ ଜଣେ ଭଲ ଫିଟ୍ | ଯଦି ତୁମେ ସେହି ପଦ ପାଇଁ ଆଗ୍ରହୀ, ମୁଁ ତାଙ୍କୁ ଜଣାଇବି | କିନ୍ତୁ ଏଥିପାଇଁ ଆପଣଙ୍କୁ ଗୁଆହାଟୀରୁ ବାହାରକୁ ଯିବାକୁ ପଡିବ |",
              "as": "মোৰ সহকৰ্মীয়ে, আনটো কাৰখানাৰ পৰা, এজন সহকাৰী অভিযন্তা বিচাৰিছে আৰু মই ভাবো তুমি এইটো কামৰ কাৰনে ভাল হবা। যদি তোমাৰ মন আছে, মই তেওঁক জনাব পাৰো। কিন্তু তাৰ কাৰনে আপুনি গুৱাহাটীৰ পৰা ওলাই যাব লাগিব।",
              "gu": "મારા સાથીદાર, અન્ય કારખાનામાં, મદદનીશ ઈજનેર ઈચ્છે છે અને મને લાગે છે કે તું યોગ્ય છે. જો તને તે પદમાં રસ હોય, તો હું તેને જાણ કરી શકું છું. પરંતુ તારે તેના માટે ગુવાહાટીથી બહાર જવું પડશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Move out? Are you talking about our factory in Nashik?",
              "hi": "बाहर निकलना? क्या आप नासिक में हमारे कारखाने के बारे में बात कर रहे हैं?",
              "ka": "ಹೊರ ಹೋಗಬೇಕು? ನೀವು ನಾಸಿಕ್‌ನಲ್ಲಿರುವ ನಮ್ಮ ಕಾರ್ಖಾನೆಯ ಬಗ್ಗೆ ಮಾತನಾಡುತ್ತಿದ್ದೀರಾ?",
              "te": "బయటకు వెళ్లడమా? నువ్వు నాసిక్‌లో ఉన్న మన ఫ్యాక్టరీ గురించి మాట్లాడుతున్నావా?",
              "or": "ବାହାର କରିଦିଅ? ଆପଣ ନାଶିକରେ ଆମର କାରଖାନା ବିଷୟରେ କହୁଛନ୍ତି କି?",
              "as": "বাহিৰ ওলোৱা? আপুনি নাছিকত থকা আমাৰ কাৰখানাৰ বিষয়ে কৈ আছে নেকি?",
              "gu": "બહાર જવાનું? તમે નાસિકમાં આપણાં કારખાના વિશે વાત કરો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Yes, exactly.",
              "hi": "हाँ बिल्कुल।",
              "ka": "ಹೌದು, ಅದೇ",
              "te": "అవును, ఖచ్చితంగా.",
              "or": "ହଁ, ଠିକ୍ |",
              "as": "হয়, একদম ঠিক",
              "gu": "હા, બરાબર."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "Negotiation",
              "hi": "बातचीत",
              "ka": "ಸಂಧಾನ",
              "te": "చర్చలు",
              "or": "ବୁଝାମଣା",
              "as": "মীমাংসা",
              "gu": "વાટાઘાટો"
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ_copy",
              "hi": "एमसीक्यू_कॉपी ",
              "ka": "ಎಮ್‌ಸಿ‌ಕ್ಯೂ ಪ್ರತಿ",
              "te": "MCQ_కాపీ",
              "or": "MCQ_copy",
              "as": "MCQ_copy",
              "gu": "MCQ_નકલ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "When her supervisor gives her research project to a colleague, Mira negotiates with her supervisor as she wants to lead the project. Which of the following is the goal of negotiation?",
              "hi": "जब उसका सुपरवाइज़र एक सहयोगी को अपनी शोध प्रोजेक्ट  देता है, तो मीरा अपने सुपरवाइज़र के साथ बातचीत करती है क्योंकि वह प्रोजेक्ट का नेतृत्व करना चाहती है। निम्नलिखित में से बातचीत का लक्ष्य कौन सा है?",
              "ka": "ತನ್ನ ಮೇಲ್ವಿಚಾರಕರು ತನ್ನ ಸಂಶೋಧನಾ ಯೋಜನೆಯನ್ನು ಸಹೋದ್ಯೋಗಿಗೆ ನೀಡಿದಾಗ, ಮೀರಾ ತನ್ನ ಮೇಲ್ವಿಚಾರಕರೊಂದಿಗೆ ಮಾತುಕತೆ ನಡೆಸುತ್ತಾಳೆ, ಅವಳು ಯೋಜನೆಯನ್ನು ಮುನ್ನಡೆಸಲು ಬಯಸುತ್ತಾಳೆ. ಕೆಳಗಿನವುಗಳಲ್ಲಿ ಯಾವುದು ಮಾತುಕತೆಯ ಗುರಿಯಾಗಿದೆ?",
              "te": "ఆమె సూపర్‌వైజర్ తన పరిశోధన ప్రాజెక్ట్ ను సహోద్యోగికి ఇచ్చినప్పుడు, మీరా ప్రాజెక్ట్ కి నాయకత్వం వహించాలని కోరుకోవడం వలన తన సూపర్‌వైజర్‌తో చర్చలు జరుపుతుంది. కింది వాటిలో చర్చల లక్ష్యం ఏది?",
              "or": "ଯେତେବେଳେ ତାଙ୍କ ସୁପରଭାଇଜର ଜଣେ ସହକର୍ମୀଙ୍କୁ ତାଙ୍କର ଅନୁସନ୍ଧାନ ପ୍ରୋଜେକ୍ଟ\n  ଦିଅନ୍ତି, ମୀରା ଏହି ପ୍ରକଳ୍ପର ନେତୃତ୍ୱ ନେବାକୁ ଚାହୁଁଥିବାରୁ ତାଙ୍କ ସୁପରଭାଇଜରଙ୍କ ସହ ଆଲୋଚନା କରନ୍ତି | ନିମ୍ନଲିଖିତ ମଧ୍ୟରୁ କେଉଁଟି ବୁଝାମଣାର ଲକ୍ଷ୍ୟ?",
              "as": "যেতিয়া তাইৰ চুপাৰভাইজাৰে তাইৰ ভাবি উলিওৱা বস্তু টো সহকৰ্মী এজনক দিয়ে, মীৰাই তাইৰ চুপাৰভাইজাৰৰ সৈতে মীমাংসা কৰে কিয়নো তাই পকামটোত আগ ভাগ লব বিচাৰে। তলৰ কোনটো মীমাংসাৰ লক্ষ্য?",
              "gu": "જ્યારે તેનો સુપરવાઈઝર તેનો સંશોધન પ્રોજેક્ટ એક બીજા કારીગરને આપે છે, ત્યારે મીરા તેના સુપરવાઈઝર સાથે વાટાઘાટો કરે છે કારણ કે તે પ્રોજેક્ટનું નેતૃત્વ કરવા માંગે છે. નીચેનામાંથી કયું વાટાઘાટનું લક્ષ્ય છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "To make sure your idea is communicated with confidence",
              "hi": "यह सुनिश्चित करना कि आपका विचार आत्मविश्वास के साथ बताया गया है ",
              "ka": "ನಿಮ್ಮ ಕಲ್ಪನೆಯನ್ನು ವಿಶ್ವಾಸದಿಂದ ಸಂವಹಿಸಲಾಗಿದೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು",
              "te": "ఆమె ఆలోచన నమ్మకంతో కమ్యూనికేట్ చేయబడిందని నిర్ధారించుకోవడానికి",
              "or": "ନିଶ୍ଚିତ କର ଯେ ତୁମର ଧାରଣା ଆତ୍ମବିଶ୍ୱାସ ସହିତ ଯୋଗାଯୋଗ ହୋଇଛି |",
              "as": "আপোনাৰ ধাৰণাটো আত্মবিশ্বাসেৰে জনোৱা",
              "gu": "તમારા વિચારને આત્મવિશ્વાસ સાથે વાર્તાલાભ કરવામાં આવે તેની ખાતરી કરવા માટે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "To give up on your point of view to do what another says",
              "hi": "अपने दृष्टिकोण से अधिक दूसरे के विचार को महत्त्व देना ",
              "ka": "ಇನ್ನೊಬ್ಬರು ಹೇಳುವುದನ್ನು ಮಾದುವ ಸಲುವಾಗಿ ನಿಮ್ಮ ದೃಷ್ಟಿಕೋನವನ್ನು ಬಿಟ್ಟುಕೊಡಲು",
              "te": "మరొకరు చెప్పింది చేయడానికి మీ పాయింట్ వదులుకోవడం",
              "or": "ଅନ୍ୟ କ’ଣ କହୁଛନ୍ତି ତାହା କରିବାକୁ ତୁମର ଦୃଷ୍ଟିକୋଣରୁ ତ୍ୟାଗ କରିବା |",
              "as": "আন এজনে কোৱা কামকৰিবলৈ আপোনাৰ নিজৰ মনে কোৱা কথা এৰি দিয়া",
              "gu": "બીજા જે કહે તે કરવા માટે તમારા દૃષ્ટિકોણને છોડી દો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "To find an agreeable solution between people with different opinions",
              "hi": "विभिन्न विचार वाले लोगों के बीच एक स्वीकार्य समाधान खोजना ",
              "ka": "ವಿಭಿನ್ನ ಅಭಿಪ್ರಾಯಗಳನ್ನು ಹೊಂದಿರುವ ಜನರ ನಡುವೆ ಸಮ್ಮತವಾದ ಪರಿಹಾರವನ್ನು ಕಂಡುಕೊಳ್ಳುವ ಸಲುವಾಗಿ.",
              "te": "వేరు వేరు అభిప్రాయాలున్న వ్యక్తుల మధ్య ఒక మంచి పరిష్కారాన్ని కనుగొనడం.",
              "or": "ବିଭିନ୍ନ ମତ ଥିବା ଲୋକଙ୍କ ମଧ୍ୟରେ ଏକ ସହମତ ସମାଧାନ ଖୋଜିବା |",
              "as": "বিভিন্ন মতামত থকা লোকৰ মাজত এটা সমাধান বিচাৰি উলিওৱা",
              "gu": "વિવિધ અભિપ્રાયો ધરાવતા લોકો વચ્ચે સહમત ઉકેલ શોધવા માટે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "To keep an open mind while arguing with another",
              "hi": "खुले मन के साथ दूसरे के साथ वाद विवाद करना",
              "ka": "ಇನ್ನೊಬ್ಬರೊಂದಿಗೆ ವಾದ ಮಾಡುವಾಗ ಮುಕ್ತ ಮನಸ್ಸನ್ನು ಇಟ್ಟುಕೊಳ್ಳುವುದು",
              "te": "మరొకరితో వాదించేటప్పుడు మనసులో ఉన్నది చెప్పేయాలి",
              "or": "ଅନ୍ୟ ସହିତ ଯୁକ୍ତିତର୍କ କରିବା ସମୟରେ ଏକ ଖୋଲା ମନ ରଖିବା |",
              "as": "আন এজনৰ সৈতে তৰ্ক কৰোঁতে খোলা মন ৰখা",
              "gu": "બીજા સાથે દલીલ કરતી વખતે ખુલ્લું મન રાખવું"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Think again! Negotiation helps make decisions when people have different points of view.",
              "hi": "फिर से विचार करो! लोगों के अलग-अलग दृष्टिकोण होते हैं ऐसे में वार्तालाप कई बार निर्णय लेने में सहायता करता है। ",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಜನರು ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳನ್ನು ಹೊಂದಿರುವಾಗ ಸಂಧಾನವು ನಿರ್ಧಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "మళ్లీ ఆలోచించు! వ్యక్తులు వేరువేరు ఆలోచనలు కలిగి ఉన్నప్పుడు నిర్ణయాలు తీసుకోవడంలో చర్చలు సహాయపడతాయి.",
              "or": "ପୁଣି ଥରେ ଭାବ! ଯେତେବେଳେ ଲୋକମାନଙ୍କର ଭିନ୍ନ ଭିନ୍ନ ଦୃଷ୍ଟିକୋଣ ଥାଏ, \n ବୁଝାମଣା ନିଷ୍ପତ୍ତି ନେବାରେ ସାହାଯ୍ୟ କରେ |",
              "as": "আকৌ চিন্তা কৰক! যেতিয়া মানুহৰ বিভিন্ন চিন্তা ধাৰা থাকে তেতিয়া মীমাংসাই সিদ্ধান্ত লোৱাত সহায় কৰে।",
              "gu": "ફરીથી વિચારો! જ્યારે લોકો જુદા જુદા દ્રષ્ટિકોણ ધરાવતા હોય ત્યારે વાટાઘાટો નિર્ણય લેવામાં મદદ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Think again! Negotiation helps make decisions when people have different points of view.",
              "hi": "फिर से विचार करो! जब लोगों के अलग-अलग दृष्टिकोण होते हैं तब बातचीत कई बार निर्णय लेने मे सहायता करती है।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಜನರು ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳನ್ನು ಹೊಂದಿರುವಾಗ ಸಂಧಾನವು ನಿರ್ಧಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "మళ్లీ ఆలోచించు! వ్యక్తులు వేరువేరు ఆలోచనలు కలిగి ఉన్నప్పుడు నిర్ణయాలు తీసుకోవడంలో చర్చలు సహాయపడతాయి.",
              "or": "ପୁଣି ଥରେ ଭାବ! ଯେତେବେଳେ ଲୋକମାନଙ୍କର ଭିନ୍ନ ଭିନ୍ନ ଦୃଷ୍ଟିକୋଣ ଥାଏ, ବୁଝାମଣା ନିଷ୍ପତ୍ତି ନେବାରେ ସାହାଯ୍ୟ କରେ |",
              "as": "আকৌ চিন্তা কৰক! যেতিয়া মানুহৰ বিভিন্ন চিন্তা ধাৰা থাকে তেতিয়া মীমাংসাই সিদ্ধান্ত লোৱাত সহায় কৰে।",
              "gu": "ફરીથી વિચારો! જ્યારે લોકો જુદા જુદા દ્રષ્ટિકોણ ધરાવતા હોય ત્યારે વાટાઘાટો નિર્ણય લેવામાં મદદ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम  काम!",
              "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ବହୁତ ଭଲ କାମ!",
              "as": "সুন্দৰ কাম!",
              "gu": "સરસ કામ!"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "hink again! Negotiation helps make decisions when people have different points of view.",
              "hi": "फिर से विचार करो! जब लोगों के अलग-अलग दृष्टिकोण होते हैं तब बातचीत कई बार निर्णय लेने मे सहायता करती है।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಜನರು ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳನ್ನು ಹೊಂದಿರುವಾಗ ಸಂಧಾನವು ನಿರ್ಧಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "మళ్లీ ఆలోచించు! వ్యక్తులు వేరువేరు ఆలోచనలు కలిగి ఉన్నప్పుడు నిర్ణయాలు తీసుకోవడంలో చర్చలు సహాయపడతాయి.",
              "or": "ପୁଣି ଥରେ ଭାବ! ଯେତେବେଳେ ଲୋକମାନଙ୍କର ଭିନ୍ନ ଭିନ୍ନ ଦୃଷ୍ଟିକୋଣ ଥାଏ, ବୁଝାମଣା\n  ନିଷ୍ପତ୍ତି ନେବାରେ ସାହାଯ୍ୟ କରେ |",
              "as": "আকৌ চিন্তা কৰক! যেতিয়া মানুহৰ বিভিন্ন চিন্তা ধাৰা থাকে তেতিয়া মীমাংসাই সিদ্ধান্ত লোৱাত সহায় কৰে।",
              "gu": "ફરીથી વિચારો! જ્યારે લોકો જુદા જુદા દ્રષ્ટિકોણ ધરાવતા હોય ત્યારે વાટાઘાટો નિર્ણય લેવામાં મદદ કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4a_2_1",
              "hi": "D4a_2_1",
              "ka": "D4a_2_1",
              "te": "D4a_2_1",
              "or": "D4a_2_1",
              "as": "D4a_2_1",
              "gu": "D4a_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She is happy that talking to the manager helped her get a good opportunity, but she is confused about her options.",
              "hi": "वह खुश है कि प्रबंधक से बात करके उसे एक अच्छा अवसर मिला, लेकिन वह अपने विकल्पों के बारे में दुविधा में है।",
              "ka": "ಮ್ಯಾನೇಜರ್ ಜೊತೆ ಮಾತನಾಡಿದ್ದು ತನಗೆ ಒಳ್ಳೆಯ ಅವಕಾಶ ಸಿಕ್ಕಿತು ಎಂದು ಖುಷಿಪಟ್ಟರೂ ತನ್ನ ಆಯ್ಕೆಗಳ ಬಗ್ಗೆ ಗೊಂದಲದಲ್ಲಿದ್ದಾಳೆ.",
              "te": "మేనేజర్‌తో మాట్లాడడం వల్ల మంచి అవకాశం వచ్చిందని ఆమె సంతోషిస్తుంది అలానే ఆమెకి వచ్చిన ఆప్షన్స్ విషయంలో అయోమయంలో పడుతుంది.",
              "or": "ସେ ଖୁସି ଯେ ମ୍ୟାନେଜରଙ୍କ ସହ କଥାବାର୍ତ୍ତା ତାଙ୍କୁ ଏକ ଭଲ ସୁଯୋଗ ପାଇବାରେ \n ସାହାଯ୍ୟ କଲା, କିନ୍ତୁ ସେ ତାଙ୍କ ବିକଳ୍ପ ବିଷୟରେ ଦ୍ୱନ୍ଦ୍ୱରେ ପଡିଗଲେ |",
              "as": "তাই সুখী যে মেনেজাৰৰ লগত কথা পতাটোয়ে তাইক এটা ভাল সুযোগ পোৱাত সহায় কৰিলে, কিন্তু তাই তাই কি কৰিব তাকে ভাবি খেলিমেলিত আছে।",
              "gu": "તે ખુશ છે કે મેનેજર સાથે વાત કરવાથી તેને સારી તક મળી, પરંતુ તે તેના વિકલ્પો વિશે મૂંઝવણમાં છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4a_2_1b",
              "hi": "D4a_2_1b",
              "ka": "D4a_2_1b",
              "te": "D4a_2_1b",
              "or": "D4a_2_1b",
              "as": "D4a_2_1b",
              "gu": "D4a_2_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira speaks about the incident with Ranjan uncle. She feels that since he is an experienced engineer, he might help make the right decision.",
              "hi": "मीरा रंजन चाचा को  इस घटना के बारे में बताती है। उसे लगता है कि चूंकि वह एक अनुभवी इंजीनियर हैं, इसलिए वह सही निर्णय लेने में सहयोग दे सकते हैं।",
              "ka": "ರಂಜನ್ ಅಂಕಲ್ ನೊಂದಿಗೆ ಈ ಘಟನೆಯ ಬಗ್ಗೆ ಮೀರಾ ಮಾತನಾಡುತ್ತಾಳೆ. ಅವರು ಅನುಭವಿ ಇಂಜಿನಿಯರ್ ಆಗಿರುವುದರಿಂದ ಸರಿಯಾದ ನಿರ್ಧಾರವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಅವರು ಸಹಾಯ ಮಾಡಬಹುದು ಎಂದು ಅವಳು ಭಾವಿಸುತ್ತಾಳೆ.",
              "te": "మీరా జరిగిన విషయం గురించి రన్జన్ మామయ్యతో చెప్పింది. అతను అనుభవజ్ఞుడైన ఇంజనీర్ కాబట్టి, అతను సరైన నిర్ణయం తీసుకోవడంలో సహాయం చేస్తాడని ఆమె భావిస్తుంది.",
              "or": "ମୀରା ରଂଜନ ମାମୁଁଙ୍କ ସହ ଏହି ଘଟଣା ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି | ସେ ଅନୁଭବ\n  କରନ୍ତି ଯେହେତୁ ସେ ଜଣେ ଅଭିଜ୍ଞ ଇଞ୍ଜିନିୟର, ସେ ସଠିକ୍ ନିଷ୍ପତ୍ତି ନେବାରେ ସାହାଯ୍ୟ କରିପାରନ୍ତି |",
              "as": "মীৰাই ৰঞ্জন খুৰাক ঘটনাটোৰ বিষয়ে কয়। তাইৰ মতেৰে যিহেটো তেওঁ এজন অভিজ্ঞ অভিযন্তা, তেওঁ কি কৰিলে ভাল হব সেইটো জনাত সহায় কৰিব পাৰে।",
              "gu": "મીરા રંજન કાકા સાથેની ઘટના વિશે વાત કરે છે. તેને લાગે છે કે તે અનુભવી એન્જિનિયર હોવાથી, તે યોગ્ય નિર્ણય લેવામાં મદદ કરી શકે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4a_2_2//",
              "hi": "D4a_2_2//",
              "ka": "D4a_2_2//",
              "te": "D4a_2_2//",
              "or": "D4a_2_2//",
              "as": "D4a_2_2//",
              "gu": "D4a_2_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Ranjan Uncle",
              "hi": "रंजन चाचा ",
              "ka": "ರಂಜನ್ ಅಂಕಲ್",
              "te": "రన్జన్ అంకుల్",
              "or": "ରଞ୍ଜନ ମାମୁଁ",
              "as": "ৰঞ্জন খুৰা",
              "gu": "રંજન કાકા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I'm happy that you could talk to your manager and get this opportunity. Otherwise, I've seen so many people who complain about their situation and do nothing.",
              "hi": "मुझे खुशी है कि तुम अपने प्रबंधक से बात करके इस अवसर को प्राप्त कर सकी। अन्यथा, मैंने बहुत सारे लोगों को देखा है जो अपनी स्थिति के बारे में शिकायत के अलावा कुछ भी नहीं करते हैं।",
              "ka": "ನೀನು ನಿಮ್ಮ ಮ್ಯಾನೇಜರ್‌ನೊಂದಿಗೆ ಮಾತನಾಡಿ ಈ ಅವಕಾಶವನ್ನು ಪಡೆದಿರುವುದು ನನಗೆ ಸಂತೋಷವಾಗಿದೆ. ಇಲ್ಲದಿದ್ದಲ್ಲಿ, ತಮ್ಮ ಪರಿಸ್ಥಿತಿಯ ಬಗ್ಗೆ ದೂರನ್ನು ಹೇಳುತ್ತಾ  ಹಾಗೂ ಏನನ್ನೂ ಮಾಡದಿರುವ ಅನೇಕ ಜನರನ್ನು ನಾನು ನೋಡಿದ್ದೇನೆ.",
              "te": "నువ్వు మీ మేనేజర్‌తో మాట్లాడి ఈ అవకాశాన్ని పొందినందుకు నేను సంతోషిస్తున్నాను. లేకపోతే, సమస్య వచ్చినప్పుడు దాని గురించి కంప్లైంట్ చేయని వ్యక్తులను నేను చాలా మందిని చూశాను.",
              "or": "ମୁଁ ଖୁସି ଯେ ତୁମେ ତୁମର ମ୍ୟାନେଜରଙ୍କ ସହ କଥା ହୋଇ ଏହି ସୁଯୋଗ ପାଇ ପାରିବ |\n  ଅନ୍ୟଥା, ମୁଁ ବହୁତ ଲୋକଙ୍କୁ ଦେଖିଛି ଯେଉଁମାନେ ସେମାନଙ୍କ ଅବସ୍ଥା ବିଷୟରେ ଅଭିଯୋଗ କରନ୍ତି ଏବଂ କିଛି କରନ୍ତି ନାହିଁ |",
              "as": "মই জানি ভাল পাইছোঁ যে তুমি তোমাৰ মেনেজাৰৰ লগত কথা পাতিব পাৰা আৰু এই সুযোগ পাব পাৰিছা। কিয়নো, মই বহুতো লোকক দেখিছোঁ যিয়ে নিজৰ পৰিস্থিতিৰ লৈ দুখ কৰে আৰু একো নকৰে।",
              "gu": "હું ખુશ છું કે તું તમારા મેનેજર સાથે વાત કરી શકી અને આ તક મેળવી. નહિંતર, મેં ઘણા લોકો જોયા છે જેઓ તેમની પરિસ્થિતિ વિશે ફરિયાદ કરે છે અને કંઈ કરતા નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Thanks, uncle, but I'm not sure if I should take up this opportunity. Nashik is in Maharashtra! 2500kms away! This factory is closer to home, so it is comfortable for me. However, I'll get to work on projects that I like if I move out.",
              "hi": "धन्यवाद, चाचा, लेकिन मुझे समझ नहीं आ रहा है कि मुझे इस अवसर का लाभ लेना चाहिए। नासिक महाराष्ट्र में है! 2500 किमी दूर! यह कारखाना घर के पास है, इसलिए यह मेरे लिए सुविधाजनक है। हालांकि, मैं उन प्रोजेक्ट्स पर काम करूंगी जो मुझे पसंद हैं अगर मैं बाहर जाती हूं।",
              "ka": "ಧನ್ಯವಾದಗಳು, ಅಂಕಲ್, ಆದರೆ ನಾನು ಈ ಅವಕಾಶವನ್ನು ಬಳಸಿಕೊಳ್ಳಬೇಕೇ ಎಂದು ನನಗೆ ಇನ್ನೂ ಖಚಿತವಿಲ್ಲ. ನಾಸಿಕ್ ಮಹಾರಾಷ್ಟ್ರದಲ್ಲಿದೆ! 2500 ಕಿಮೀ ದೂರ! ಈ ಕಾರ್ಖಾನೆಯು ಮನೆಗೆ ಹತ್ತಿರದಲ್ಲಿದೆ, ಆದ್ದರಿಂದ ಇದು ನನಗೆ ಆರಾಮದಾಯಕವಾಗಿದೆ. ಹೇಗಾದರೂ, ನಾನು ಅಲ್ಲಿಗೆ ಹೋದರೆ ನಾನು ಇಷ್ಟಪಡುವ ಯೋಜನೆಗಳಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತೇನೆ.",
              "te": "ధన్యవాదాలు అంకుల్, కానీ నేను ఈ అవకాశాన్ని ఉపయోగించాలా వద్దా అని నాకు ఖచ్చితంగా తెలియదు. నాసిక్ మహారాష్ట్రలో ఉంది! 2500 కిలోమీటర్ల దూరం! ఈ పరిశ్రమ ఇంటికి దగ్గరగా ఉంది, కాబట్టి ఇది నాకు సౌకర్యంగా ఉంటుంది. అయితే, నేను అక్కడికి వెళితే ఇష్టమైన ప్రాజెక్ట్‌ లలో పని చేస్తాను.",
              "or": "ଧନ୍ୟବାଦ, ମାମୁଁ, କିନ୍ତୁ ମୁଁ ନିଶ୍ଚିତ ନୁହେଁ ଯେ ମୁଁ ଏହି ସୁଯୋଗ ନେବା ଉଚିତ୍ କି? \n ନାଶିକ୍ ମହାରାଷ୍ଟ୍ରରେ ଅଛି! 2500 କିଲୋମିଟର ଦୂରରେ! ଏହି କାରଖାନା ଘରର ନିକଟତର, ତେଣୁ ଏହା ମୋ ପାଇଁ ଆରାମଦାୟକ | ତଥାପି, ମୁଁ ପ୍ରୋଜେକ୍ଟରେ କାମ କରିବାକୁ ପାଇବି, ଯଦି ମୁଁ ବାହାରକୁ ଯାଏ ତେବେ ମୋତେ ଭଲ ଲାଗିବ |",
              "as": "ধন্যবাদ, খুৰা, কিন্তু মই বুজি পোৱা নাই যে মই এই সুযোগটো লোৱা ভাল নে নহয়। নাছিক মহাৰাষ্ট্ৰত আছে! 2500 কিমি দূৰত! এই কাৰখানাটো ঘৰৰ উচৰত কাৰনে মই আৰামত আছিলোঁ। পিছে, মই বাহিৰলৈ গ'লে মই ভাল পোৱা কাম কৰিবলৈ পাম।",
              "gu": "આભાર, કાકા, પણ મને ખાતરી નથી કે મારે આ તક લેવી જોઈએ કે નહીં. નાસિક મહારાષ્ટ્રમાં છે! 2500 કિમી દૂર! આ કારખાનું ઘરની નજીક છે, તેથી તે મારા માટે સારું છે. જો કે, જો હું બહાર જઈશ તો મને ગમતા પ્રોજેક્ટ પર કામ કરી શકીશ "
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4a_2_3////",
              "hi": "D4a_2_3////",
              "ka": "D4a_2_3////",
              "te": "D4a_2_3////",
              "or": "D4a_2_3////",
              "as": "D4a_2_3////",
              "gu": "D4a_2_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "That's true, but you should also consider the challenges of working in a new place. You will have to get familiar with your workplace all over again. And since there'll be new people in the office, you'll have to establish new relationships.",
              "hi": "यह सच है, लेकिन तुमको एक नई जगह पर काम करने की चुनौतियों पर भी विचार करना चाहिए। तुमको अपने कार्यस्थल से फिर से परिचित होना होगा। और चूंकि कार्यालय में नए लोग होंगे, इसलिए तुमको नए रिश्ते स्थापित करने होंगे।",
              "ka": "ಅದು ನಿಜ, ಆದರೆ ನೀನು ಹೊಸ ಸ್ಥಳದಲ್ಲಿ ಕೆಲಸ ಮಾಡುವಾಗ ಬರುವ ಸವಾಲುಗಳನ್ನು ಸಹ ಪರಿಗಣಿಸಬೇಕು. ನಿನ್ನ ಕೆಲಸದ ಸ್ಥಳದೊಂದಿಗೆ ನೀನು ಮತ್ತೆ ಪರಿಚಿತಗೊಳ್ಳಬೇಕಾಗುತ್ತದೆ. ಮತ್ತು ಕಚೇರಿಯಲ್ಲಿ ಹೊಸ ಜನರು ಇರುವುದರಿಂದ, ನೀನು ಹೊಸ ಸಂಬಂಧಗಳನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳಬೇಕಾಗುತ್ತದೆ",
              "te": "అది నిజం, కానీ నువ్వు కొత్త స్థలంలో పని చేయడంలో ఉన్న సవాళ్ల గురించి కూడా ఆలోచించాలి. నువ్వు నీ కార్యాలయాన్ని మళ్లీ మళ్లీ తెలుసుకోవాలి. మరియు కార్యాలయంలో కొత్త వ్యక్తులు ఉంటారు కాబట్టి, నువ్వు కొత్త సంబంధాలను ఏర్పరచుకోవాలి.",
              "or": "ତାହା ସତ୍ୟ, କିନ୍ତୁ ଆପଣ ଏକ ନୂତନ ସ୍ଥାନରେ କାମ କରିବାର ଆହ୍ୱାନଗୁଡ଼ିକୁ ମଧ୍ୟ \n ବିଚାର କରିବା ଉଚିତ୍ | ତୁମ କର୍ମକ୍ଷେତ୍ର ସହିତ ପୁନର୍ବାର ପରିଚିତ ହେବାକୁ ପଡିବ | ଏବଂ ଯେହେତୁ ଅଫିସରେ ନୂତନ ଲୋକ ଥିବେ, ଆପଣଙ୍କୁ ନୂତନ ସମ୍ପର୍କ ସ୍ଥାପନ କରିବାକୁ ପଡିବ |",
              "as": "এইটো সঁচা, কিন্তু তুমি নতুন জেগা কাম কৰাৰ অসুবিধা বোৰো চাই ল'লে ভাল। তুমি নতুন কৰ্মস্থানৰ লগত ভালকৈ চিনাকি হ'ব লাগিব। আৰু যিহেতু কাৰ্যালয়ত নতুন মানুহ থাকিব, তাত নতুন সম্পৰ্ক বনাব লাগিব।",
              "gu": "તે સાચું છે, પરંતુ તારે નવી જગ્યાએ કામ કરવાના પડકારોને પણ ધ્યાનમાં લેવા જોઈએ. તારે તારા કાર્યસ્થળથી ફરીથી પરિચિત થવું પડશે. અને ઓફિસમાં નવા લોકો આવશે, તેથી તારે નવા સંબંધો સ્થાપિત કરવા પડશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "It's scary to think about starting again. But my growth will be limited if I continue to work here.",
              "hi": "फिर से शुरू करने के बारे में सोचना डरावना है। लेकिन अगर मैं यहां काम करना जारी रखती हूं तो मेरी तरक्की सीमित होगी।",
              "ka": "ಮತ್ತೆ ಹೊಸದಾಗಿ ಪ್ರಾರಂಭಿಸುವ ಬಗ್ಗೆ ಯೋಚಿಸುವುದು ಭಯಾನಕವೆನಿಸುತ್ತದೆ ಆದರೆ ಇಲ್ಲಿ ಕೆಲಸ ಮುಂದುವರೆಸಿದರೆ ನನ್ನ ಬೆಳವಣಿಗೆ ಸೀಮಿತವಾಗಿರುತ್ತದೆ.",
              "te": "మళ్లీ మొదలు పెట్టాలంటే భయంగా ఉంది. కానీ నేను ఇక్కడ పని చేస్తూనే ఉంటే నేను సరిగ్గా ఎదగలేను.",
              "or": "ପୁନର୍ବାର ଆରମ୍ଭ କରିବା ବିଷୟରେ ଚିନ୍ତା କରିବା ଭୟଭୀତ | କିନ୍ତୁ ଯଦି ମୁଁ ଏଠାରେ କାର୍ଯ୍ୟ ଜାରି ରଖେ ତେବେ ମୋର ଅଭିବୃଦ୍ଧି ସୀମିତ ରହିବ |",
              "as": "পুনৰ আৰম্ভ কৰাৰ বিষয়ে ভাবিবলৈ ভয় লাগে। কিন্তু যদি মই ইয়াত কাম কৰি থাকো তেনেহলে মই আগ বাঢ়ি যাব নোৱাৰিম।",
              "gu": "ફરી શરૂ કરવા વિશે વિચારવું ભય જેવુ છે. પરંતુ જો હું અહીં કામ કરવાનું ચાલુ રાખું તો મારી વૃદ્ધિ મર્યાદિત રહેશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "It's a tough decision, but you've done well in your career so far. Regardless of what decision you take, it will help you grow.",
              "hi": "यह एक कठिन निर्णय है, लेकिन तुमने अब तक अपने करियर में अच्छा प्रदर्शन किया है। भले ही तुम कुछ भी निर्णय लो, यह तुम्हारे विकास में सहायक होगा।",
              "ka": "ಇದು ಕಠಿಣ ನಿರ್ಧಾರ, ಆದರೆ ನೀನು ಇಲ್ಲಿಯವರೆಗೆ ನಿನ್ನ ವೃತ್ತಿಜೀವನದಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸಿದ್ದೀಯಾ. ನೀನು ಯಾವ ನಿರ್ಧಾರವನ್ನು ತೆಗೆದುಕೊಂಡರೂ ಅದು ನಿನ್ನ ಬೆಳವಣಿಗೆಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "ఇది చాలా కష్టమైన నిర్ణయం, కానీ నువ్వు ఇప్పటివరకు నీ కెరీర్‌లో బాగానే ఉన్నావు. నువ్వు ఏ నిర్ణయం తీసుకున్నా, అది నీ ఎదుగుదలకు సహాయపడుతుంది.",
              "or": "ଏହା ଏକ କଠିନ ନିଷ୍ପତ୍ତି, କିନ୍ତୁ ଆପଣ ଏପର୍ଯ୍ୟନ୍ତ ନିଜ କ୍ୟାରିଅରରେ ଭଲ କରିଛନ୍ତି\n  | ଆପଣ କେଉଁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତୁ ନା କାହିଁକି, ଏହା ଆପଣଙ୍କୁ ଆଗକୁ ବଢିବାରେ ସାହାଯ୍ୟ କରିବ |",
              "as": "এইটো এটা টান সিদ্ধান্ত, কিন্তু তুমি এতিয়ালৈকে নিজৰ কেৰিয়াৰত ভাল কৰি আছা। গতিকে, তুমি যিয়ে নকৰা কিয়, ই তোমাক সহায়ে কৰিব।",
              "gu": "આ એક અઘરો નિર્ણય છે, તે અત્યાર સુધી તારી કારકિર્દીમાં સારું પ્રદર્શન કર્યું છે. તું જે પણ નિર્ણય લે છો  તે તને વૃદ્ધિ કરવામાં મદદ કરશે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "DiscussionWithSignificantOthers",
              "hi": "अन्य महत्त्वपूर्ण लोगों के साथ चर्चा ",
              "ka": "ಮಹತ್ವದ ಇತರರೊಂದಿಗೆ ಚರ್ಚೆ",
              "te": "ముఖ్యమైన ఇతరులతో చర్చ",
              "or": "ଆଲୋଚନା ସହିତ ୱିଥ୍ ସାଇନ୍ସିଫାଣ୍ଟ ଅନ୍ୟାନ୍ୟ |",
              "as": "গুৰুত্বপূৰ্ণ অন্যান্যৰ সৈতে আলোচনা",
              "gu": "અન્ય સાથે મહત્વની ચર્ચા"
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4a_2_4_D",
              "hi": "D4a_2_4_D",
              "ka": "D4a_2_4_D",
              "te": "D4a_2_4_D",
              "or": "D4a_2_4_D",
              "as": "D4a_2_4_D",
              "gu": "D4a_2_4_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Mira do?",
              "hi": "मीरा को क्या करना चाहिए?",
              "ka": "ಮೀರಾ ಈಗ ಏನು ಮಾಡಬೇಕು?",
              "te": "మీరా ఏమి చేయాలి?",
              "or": "ମୀରା କଣ କରିବା ଉଚିତ?",
              "as": "মীৰাই কি কৰিলে ভাল হব?",
              "gu": "મીરાએ શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Accept the offer and move out of state",
              "hi": "प्रस्ताव स्वीकार करे और राज्य से बाहर निकले",
              "ka": "ಬಂದಿರುವ ಆಫರ್ ಅನ್ನು ಸ್ವೀಕರಿಸಿ ರಾಜ್ಯದಿಂದ ಹೊರಗೆ ಹೋಗಬೇಕೆ/",
              "te": "ఆఫర్‌ను అంగీకరించి, రాష్ట్రం నుండి బయటకు వెళ్లండి",
              "or": "ଅଫରକୁ ଗ୍ରହଣ କରନ୍ତୁ ଏବଂ ରାଜ୍ୟ ବାହାରକୁ ଯାଆନ୍ତୁ |",
              "as": "কামটো লৈ লওঁক আৰু অসম বাহিৰলৈ যাওক",
              "gu": "તક સ્વીકારવી અને રાજ્યની બહાર જાવું જોઇયે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Work under Anuj on the project",
              "hi": "प्रोजेक्ट पर अनुज के अधीन काम करे",
              "ka": "ಅನುಜ್ ಕೆಳಗೆ ಯೋಜನೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಿಕೊಂಡು ಇರಬೇಕೆ ",
              "te": "ప్రాజెక్ట్ లో అనుజ్ క్రింద పని చేయండి",
              "or": "ପ୍ରୋଜେକ୍ଟରେ ଅନୁଜଙ୍କ ଅଧୀନରେ କାମ କରନ୍ତୁ |",
              "as": "ইয়াতে অনুজৰ তলত কাম কৰক",
              "gu": "પ્રોજેક્ટ પર અનુજ નીચે કામ કરવું"
            }
          }
        ]
      },
      "scene18": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Challenging Stereotypes",
              "hi": " रूढ़िवादितापूर्ण चुनौती",
              "ka": "ಚಾಲೆಂಜಿಂಗ್ ಸ್ಟೀರಿಯೊಟೈಪ್ಸ್",
              "te": "ఛాలెంజింగ్ స్టీరియోటైప్స్",
              "or": "ଷ୍ଟେରିଓଟାଇପ୍ ଚ୍ୟାଲେଞ୍ଜ୍ |",
              "as": "ষ্টেৰিওটাইপৰ বাধা",
              "gu": "પડકારરૂપ સ્ટીરિયોટાઇપ્સ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Manager",
              "hi": "प्रबंधक",
              "ka": "ಮ್ಯಾನೇಜರ್",
              "te": "మేనేజర్",
              "or": "ମ୍ୟାନେଜର",
              "as": "মেনেজাৰ",
              "gu": "મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Manager",
              "hi": "प्रबंधक",
              "ka": "ಮ್ಯಾನೇಜರ್",
              "te": "మేనేజర్",
              "or": "ମ୍ୟାନେଜର",
              "as": "মেনেজাৰ",
              "gu": "મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "That is no way to talk, Mira. Even if you're skilled, you'll progress only if you respect your superiors.",
              "hi": "यह बात करने का कोई तरीका नहीं है, मीरा। यदि आप में कौशल है, तो भी आप केवल तभी प्रगति करेंगे जब आप अपने वरिष्ठों का सम्मान करते हैं।",
              "ka": "ಹಾಗೆ ಮಾತನಾಡಲು ಸಾಧ್ಯವೇ ಇಲ್ಲ ಮೀರಾ. ನೀನು ನುರಿತವಳಾಗಿದ್ದರೂ ಸಹ, ನಿನ್ನ ಮೇಲಧಿಕಾರಿಗಳನ್ನು ಗೌರವಿಸಿದರೆ ಮಾತ್ರ ನೀನು ಪ್ರಗತಿ ಹೊಂದುತ್ತೀಯೇ",
              "te": "మీరా ఇలా మాట్లాడటం మంచిది కాదు. నువ్వు నైపుణ్యం కలిగి ఉన్నప్పటికీ, నువ్వు నీ ఉన్నతాధికారులను గౌరవిస్తేనే నువ్వు అభివృద్ధి చెందుతావు.",
              "or": "ମୀରା, କଥାବାର୍ତ୍ତା କରିବାର ଏହା କୌଣସି ଉପାୟ ନୁହେଁ | ଯଦିଓ ତୁମେ କୁଶଳୀ,\n  ତୁମେ ଯଦି ତୁମର ଉଚ୍ଚକର୍ମଚାରୀଙ୍କୁ ସମ୍ମାନ କର ତେବେ ତୁମେ ଅଗ୍ରଗତି କରିବ |",
              "as": "এনেদৰে কথা নকবা মীৰা। যদিও তোমাৰ ক্ষমতা আছে তুমি তেতিয়াহে আগবাঢ়ি যাব পাৰিবা যেতিয়া তোমাতকৈ ডাঙৰ সকলক সন্মান কৰা।",
              "gu": "તે વાત કરવાનો કોઈ મતલબ નથી, મીરા. જો તું કુશળ હોવા છતાં પણ, તું તારા ઉપરી અધિકારીઓનો આદર કરશે તો જ તું પ્રગતિ કરીશ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": ". . .",
              "hi": ". . .",
              "ka": ", , , ",
              "te": ". . .",
              "or": ". . .",
              "as": "…….",
              "gu": ". . ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Please leave.",
              "hi": "कृपया जाएँ।",
              "ka": "ಈಗ ನೀನು ಹೊರಡು",
              "te": "దయచేసి వెళ్ళండి",
              "or": "ଦୟାକରି ଚାଲିଯାଅ |",
              "as": "ইয়াৰ পৰা যোৱা।",
              "gu": "કૃપા કરીને છોડી દો."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4b_1_2",
              "hi": "D4b_1_2",
              "ka": "D4b_1_2",
              "te": "D4b_1_2",
              "or": "D4b_1_2",
              "as": "D4b_1_2",
              "gu": "D4b_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is unhappy with herself. She feels she could've done better to convince her supervisor to let her lead the project.",
              "hi": "मीरा खुद से नाखुश है। उसे लगता है कि वह  इस प्रोजेक्ट का नेतृत्व करने हेतु अपने सुपरवाइज़र को मनाने के लिए बेहतर कर सकती थी।",
              "ka": "ಮೀರಾ ತನ್ನ ಬಗ್ಗೆ ಅತೃಪ್ತಿ ಹೊಂದಿದ್ದಾಳೆ. ತನ್ನ ಮೇಲ್ವಿಚಾರಕರನ್ನು ತನ್ನ ಯೋಜನೆಯನ್ನು ಮುನ್ನಡೆಸುವಂತೆ ಮನವೊಲಿಸಲು ತಾನು ಇನ್ನೂ ಉತ್ತಮವಾಗಿ ಮಾತನಾಡಬಹುದಿತ್ತೆಂದು ಅವಳು ಭಾವಿಸುತ್ತಾಳೆ.",
              "te": "మీరా తన పట్ల ఆనందంగా లేదు. ప్రాజెక్ట్ కి నాయకత్వం వహించేలా తన సూపర్‌వైజర్‌ను ఒప్పించేందుకు తాను మరింత మెరుగ్గా చేయగలనని ఆమె భావిస్తోంది.",
              "or": "ମୀରା ନିଜ ଉପରେ ଅସନ୍ତୁଷ୍ଟ | ସେ ଅନୁଭବ କରୁଛନ୍ତି ଯେ ସେ ତାଙ୍କ ସୁପରଭାଇଜରଙ୍କୁ ଏହି ପ୍ରକଳ୍ପର ନେତୃତ୍ୱ ଦେବା ପାଇଁ ମନାଇବା ପାଇଁ ଚେଷ୍ଟା କରିଥିଲେ ଭଲ ହୋଇଥାନ୍ତା |",
              "as": "মীৰা নিজকে লৈ অসন্তুষ্ট। তাই ভাবিছে যে তাই যদি তাইৰ চুপাৰভাইজাৰক আৰু ভালকৈ সৈমান কৰাব পাৰিলে হয় তাইক কামটো কৰিব দিবলৈ।",
              "gu": "મીરા પોતાની જાતથી  નાખુશ છે. તેને લાગે છે કે તેણે તેના સુપરવાઈજારને પ્રોજેક્ટ લીડ કરવા વધારે સમજાવવાની જરૂર હતી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4b_2_1",
              "hi": "D4b_2_1",
              "ka": "D4b_2_1",
              "te": "D4b_2_1",
              "or": "D4b_2_1",
              "as": "D4b_2_1",
              "gu": "D4b_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Anuj does well with the project and gets promoted because of his efforts. Mira decides to continue working hard and wait for another opportunity to prove herself.",
              "hi": "अनुज कुशलता से प्रोजेक्ट पूरा करता है और अपने प्रयासों के कारण पदोन्नत हो जाता है। मीरा कड़ी मेहनत करते रहने का निर्णय करती है और स्वयं को साबित करने के लिए एक और अवसर की प्रतीक्षा करती है।",
              "ka": "ಅನುಜ್ ಆ ಪ್ರಾಜೆಕ್ಟ್‌ ಅನ್ನು ಉತ್ತಮವಾಗಿ ನಿರ್ವಹಿಸುತ್ತಾನೆ ಹಾಗೂ ಅವನ ಪ್ರಯತ್ನದಿಂದಾಗಿ ಬಡ್ತಿ ಪಡೆಯುತ್ತಾನೆ. ಮೀರಾ ಕಠಿಣ ಪರಿಶ್ರಮವನ್ನು ಮುಂದುವರಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ ಮತ್ತು ತನ್ನನ್ನು ತಾನು ಸಾಬೀತುಪಡಿಸಲು ಮತ್ತೊಂದು ಅವಕಾಶಕ್ಕಾಗಿ ಕಾಯುತ್ತಾಳೆ",
              "te": "అనూజ్ ప్రాజెక్ట్ ను బాగా చేసాడు మరియు అతని ప్రయత్నాల వలన ప్రమోషన్ పొందాడు. మీరా కష్టపడి పనిచేయాలని మరియు తనను తాను నిరూపించుకోవడానికి మరొక అవకాశం కోసం ఎదురు చూడాలని నిర్ణయించుకుంది.",
              "or": "ଅନୁଜ ଏହି ପ୍ରୋଜେକ୍ଟ ସହିତ ଭଲ କରନ୍ତି ଏବଂ ତାଙ୍କ ଉଦ୍ୟମ ହେତୁ ପଦୋନ୍ନତି ପାଆନ୍ତି |\n  ମୀରା କଠିନ ପରିଶ୍ରମ ଜାରି ରଖିବାକୁ ସ୍ଥିର କରେ ଏବଂ ନିଜକୁ ପ୍ରମାଣ କରିବାକୁ ଅନ୍ୟ ଏକ ସୁଯୋଗକୁ ଅପେକ୍ଷା କରେ |",
              "as": "অনুজে প্ৰকল্পটো ভালকৈ কৰে আৰু চেষ্টাৰ কাৰনে চাকৰিত উপৰৰ পদ ও লাভ কৰে। মীৰাই বহুত কষ্ট কৰি থাকিবলৈ অব্যাহত ৰাখিবলৈ আৰু নিজকে প্ৰমাণ কৰাৰ বেলেগ এটা সুযোগৰ কাৰনে অপেক্ষা কৰিম বুলি ভাবে ।",
              "gu": "અનુજ પ્રોજેક્ટ સાથે સારી રીતે કામ કરે છે અને તેના પ્રયત્નોને કારણે તેને પ્રોત્સાહન મળે છે. મીરા સખત મહેનત કરવાનું ચાલુ રાખે છે અને પોતાને સાબિત કરવાની બીજી તકની રાહ જોવાનું નક્કી કરે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4b_2_2",
              "hi": "D4b_2_2",
              "ka": "D4b_2_2",
              "te": "D4b_2_2",
              "or": "D4b_2_2",
              "as": "D4b_2_2",
              "gu": "D4b_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "A few months go by, and she still doesn't get the work she is looking for. She feels it's time to look for a new job.",
              "hi": "कुछ महीने बीत जाते हैं, और उसे अभी भी वह काम नहीं मिला है जिसे वह ढूंढ रही है। वह अनुभव करती है कि यह एक नई नौकरी की तलाश करने का समय है।",
              "ka": "ಕೆಲವು ತಿಂಗಳುಗಳು ಕಳೆದರೂ ಆಕೆಗೆ ತಾನು ಹುಡುಕುತ್ತಿರುವ ಕೆಲಸ ಇನ್ನೂ ಸಿಗುತ್ತಿಲ್ಲ. ಹೊಸ ಕೆಲಸವನ್ನು ಹುಡುಕುವ ಸಮಯ ಬಂದಿದೆ ಎಂದು ಅವಳು ಭಾವಿಸುತ್ತಾಳೆ.",
              "te": "కొన్ని నెలలు గడుస్తున్నా, ఆమె వెతుకుతున్న పని ఇంకా ఆమెకు లభించలేదు. కొత్త ఉద్యోగం కోసం వెతకాల్సిన సమయం వచ్చిందని ఆమె భావిస్తోంది.",
              "or": "କିଛି ମାସ ବିତିଗଲା, ଏବଂ ସେ ଖୋଜୁଥିବା କାମକୁ ଏପର୍ଯ୍ୟନ୍ତ ପାଇଲା ନାହିଁ | ସେ \n ଅନୁଭବ କରନ୍ତି ଯେ ଏକ ନୂତନ ଚାକିରି ଖୋଜିବା ସମୟ |",
              "as": "কেইমাহমান পাৰ হৈ যায়, আৰু তাই এতিয়াও তাই বিচাৰি থকা কামটো নাপায়। তাই গ'ম পাইছে যে এতিয়া নতুন চাকৰি বিচাৰি বলৈ সময় হৈ আহিছে।",
              "gu": "થોડા મહિનાઓ વીતી જાય છે, અને હજુ પણ તે જે કામ શોધી રહી છે તે મળતું નથી. તેને લાગે છે કે હવે નવી નોકરી શોધવાનો સમય આવી ગયો છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4b_2_3",
              "hi": "D4b_2_3",
              "ka": "D4b_2_3",
              "te": "D4b_2_3",
              "or": "D4b_2_3",
              "as": "D4b_2_3",
              "gu": "D4b_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "However, it's a difficult decision for her. She doesn't know if she should look for a new job, especially after working in the same company for so long.",
              "hi": "हालांकि, यह उसके लिए एक कठिन निर्णय है। वह नहीं समझ पा रही  कि उसे एक नई नौकरी की तलाश करनी चाहिए, विशेषतः  एक ही कंपनी में इतने समय तक काम करने के बाद।",
              "ka": "ಆದಾಗ್ಯೂ, ಇದು ಅವಳಿಗೆ ಕಷ್ಟಕರವಾದ ನಿರ್ಧಾರವಾಗಿದೆ. ಅದೇ ಕಂಪನಿಯಲ್ಲಿ ಇಷ್ಟು ದಿನ ಕೆಲಸ ಮಾಡಿದ ನಂತರ ಹೊಸ ಕೆಲಸ ಹುಡುಕಬೇಕೋ ಇಲ್ಲವೋ ಗೊತ್ತಾಗುತ್ತಿಲ್ಲ.",
              "te": "అయితే, ఆమెకు ఇది చాలా కష్టమైన నిర్ణయం. ముఖ్యంగా ఇంత కాలం ఒకే కంపెనీలో పనిచేసి ఇప్పుడు కొత్త ఉద్యోగం వెతుక్కోవడం మంచిదో కాదో ఆమెకు తెలియదు.",
              "or": "ତଥାପି, ଏହା ତାଙ୍କ ପାଇଁ ଏକ କଷ୍ଟକର ନିଷ୍ପତ୍ତି | ବିଶେଷକରି ଏକ ସମାନ କମ୍ପାନୀରେ ଏତେ ଦିନ କାମ କରିବା ପରେ ସେ ଏକ ନୂତନ ଚାକିରି ଖୋଜିବା ଉଚିତ କି ନାହିଁ ସେ ଜାଣନ୍ତି ନାହିଁ |",
              "as": "পিচে, এইটো তাইৰ বাবে এটা টান সিদ্ধান্ত। তাই নাজানে যে তাই নতুন চাকৰি বিচাৰিব লাগে নে নাই, বিশেষকৈ একেটা কোম্পানীত ইমান দিন কাম কৰাৰ পিছত।",
              "gu": "જો કે, તે તેના માટે મુશ્કેલ નિર્ણય છે. તેને ખબર નથી કે તેને નવી નોકરી શોધવી જોઈએ કે કેમ, ખાસ કરીને આટલા લાંબા સમય સુધી એક જ કંપનીમાં કામ કર્યા પછી."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4b_3_1",
              "hi": "D4b_3_1",
              "ka": "D4b_3_1",
              "te": "D4b_3_1",
              "or": "D4b_3_1",
              "as": "D4b_3_1",
              "gu": "D4b_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She decides to talk to Ranjan uncle as she feels that since he is an experienced engineer, he might help make the right decision.",
              "hi": "वह रंजन चाचा से बात करने का निश्चय करती है क्योंकि उसे लगता है कि वह एक अनुभवी इंजीनियर है, इसलिए वह सही निर्णय लेने में मदद कर सकते हैं।",
              "ka": "ರಂಜನ್ ಅಂಕಲ್ ಒಬ್ಬ ಅನುಭವಿ ಇಂಜಿನಿಯರ್ ಆಗಿರುವುದರಿಂದ ಸರಿಯಾದ ನಿರ್ಧಾರವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಅವರು ಸಹಾಯ ಮಾಡಬಹುದೆಂದು ಭಾವಿಸಿ ಅವಳು ಅವರೊಂದಿಗೆ ಮಾತನಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ",
              "te": "రన్జన్ అంకుల్ అనుభవం ఉన్న ఇంజనీర్ కాబట్టి సరైన నిర్ణయం తీసుకోవడంలో సహాయం చేయగలడని మీరా భావించి అతనితో మాట్లాడాలని నిర్ణయించుకుంది.",
              "or": "ସେ ରଞ୍ଜନ ମାମୁଁଙ୍କ ସହ କଥା ହେବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି ଯେହେତୁ ସେ ଅନୁଭବ କରନ୍ତି ଯେ ସେ ଜଣେ ଅଭିଜ୍ଞ ଇଞ୍ଜିନିୟର, ତେଣୁ ସେ ସଠିକ୍ ନିଷ୍ପତ୍ତି ନେବାରେ ସାହାଯ୍ୟ କରିପାରନ୍ତି |",
              "as": "তাই ৰঞ্জন খুৰাৰ সৈতে কথা পাতিব বিচাৰে কাৰন তাই অনুভৱ কৰে যে যিহেতু তেওঁ এজন অভিজ্ঞ অভিযন্তা, তেওঁ কি কৰিলে ভাল হব তাক গম পোৱাত সহায় কৰিব পাৰে।",
              "gu": "તેને રંજન કાકા સાથે વાત કરવાનું નક્કી કર્યું કારણ કે તેને લાગે છે કે તે એક અનુભવી એન્જીનીયર હોવાથી તે યોગ્ય નિર્ણય લેવામાં મદદ કરી શકે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4b_3_2//",
              "hi": "D4b_3_2//",
              "ka": "D4b_3_2//",
              "te": "D4b_3_2//",
              "or": "D4b_3_2//",
              "as": "ক",
              "gu": "D4b_3_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Ranjan",
              "hi": "रंजन",
              "ka": "ರಂಜನ್",
              "te": "రన్జన్",
              "or": "ରଞ୍ଜନ",
              "as": "ৰঞ্জন",
              "gu": "રંજન"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Since when has this been happening? Why are you still going to the office Mira? They don't deserve you.",
              "hi": "यह सब कब से हो रहा है? मीरा तुम अभी भी कार्यालय क्यों जा रही हो? वे तुम्हारे लायक नहीं हैं।",
              "ka": "ಇದು ಯಾವಾಗಿನಿಂದ ನಡೆಯುತ್ತಿದೆ? ಮೀರಾ ಇನ್ನೂ ಯಾಕೆ ಆಫೀಸಿಗೆ ಹೋಗುತ್ತಿದ್ದೀಯ? ಅವರು ನಿನಗೆ ಅರ್ಹರಲ್ಲ.",
              "te": "ఇది ఎప్పటి నుండి జరుగుతోంది? ఇంకా ఆఫీస్ కి ఎందుకు వెళ్తున్నావ్ మీరా? వారు నీకు అర్హులు కారు.",
              "or": "କେବେଠାରୁ ଏହା ଘଟୁଛି? ଆପଣ କାହିଁକି ମୀରା ଅଫିସକୁ ଯାଉଛନ୍ତି? ସେମାନେ\n  ତୁମର ଯୋଗ୍ୟ ନୁହଁନ୍ତି |",
              "as": "কেতিয়াৰ পৰা এইটো হৈ আছে? তুমি এতিয়াও অফিচলৈ কিয় গৈ আছা? তেওঁলোক তোমাৰ কাৰনে যোগ্য নহয়।",
              "gu": "આવું ક્યારથી થઈ રહ્યું છે? મીરા તું હજી ઓફિસે કેમ જાય છે? તે તારા લાયક નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "You're right, uncle, but I'm not sure about leaving this place and starting afresh.",
              "hi": "आप सही हैं, चाचा, लेकिन मैं इस जगह को छोड़ने और फिर से शुरू करने के बारे में निश्चित नहीं हूं।",
              "ka": "ನೀವು ಹೇಳಿದ್ದು ಸರಿ, ಅಂಕಲ್, ಆದರೆ ಈ ಕೆಲಸವನ್ನು ಬಿಟ್ಟು ಮತ್ತೆ ಪ್ರಾರಂಭಿಸುವ ಬಗ್ಗೆ ನನಗೆ ಖಚಿತವಾದ ಮನಸ್ಸಿಲ್ಲ.",
              "te": "నువ్వు చెప్పింది నిజమే అంకుల్, కానీ ఈ చోటుని వదిలి మళ్లీ మొదలుపెట్టడం మంచిదో కాదో నాకు ఖచ్చితంగా తెలియదు.",
              "or": "ତୁମେ ଠିକ୍, ମାମୁଁ, କିନ୍ତୁ ମୁଁ ଏହି ସ୍ଥାନ ଛାଡି ପୁନର୍ବାର ଆରମ୍ଭ କରିବା ବିଷୟରେ\n  ନିଶ୍ଚିତ ନୁହେଁ |",
              "as": "আপুনি ঠিকেই কৈছে, খুৰা, কিন্তু মই এই ঠাইএৰি নতুনকৈ আৰম্ভ কৰাৰ বকথাটো হে বুজি পোৱা নাই কি কৰোঁ।",
              "gu": "તમે સાચા છો, કાકા, પણ મારુ કઈ નક્કી નથી આ કામ છોડીને નવેસરથી શરૂઆત કરવા માટે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4b_3_3////",
              "hi": "D4b_3_3////",
              "ka": "D4b_3_3////",
              "te": "D4b_3_3////",
              "or": "D4b_3_3////",
              "as": "D4b_3_3////",
              "gu": "D4b_3_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "That's true; changing jobs does have its challenges. You will have to get familiar with your workplace all over again.",
              "hi": "यह सच है; नौकरी बदलने में  उसकी चुनौतियां होती हैं। तुमको अपने कार्यस्थल से फिर से परिचित होना होगा।",
              "ka": "ಅದು ನಿಜ; ಉದ್ಯೋಗಗಳನ್ನು ಬದಲಾಯಿಸುವುದು ಅದರದೇ ಆದ ಸವಾಲುಗಳನ್ನು ಹೊಂದಿರುತ್ತದೆ. ನಿನ್ನ ಕೆಲಸದ ಸ್ಥಳದೊಂದಿಗೆ ನೀನು ಮತ್ತೆ ಪರಿಚಿತಗೊಳ್ಳಬೇಕಾಗುತ್ತದೆ.",
              "te": "అది నిజం; ఉద్యోగాలను మార్చడం వలన చాలా సవాళ్ళు వస్తాయి. నువ్వు నీ కార్యాలయాన్ని మళ్లీ మళ్లీ తెలుసుకోవాలి.",
              "or": "ତାହା ସତ୍ୟ; ଚାକିରି ବଦଳାଇବା ଏହାର ଚ୍ୟାଲେଞ୍ଜଗୁଡିକ | ତୁମ କର୍ମକ୍ଷେତ୍ର ସହିତ\n  ପୁନର୍ବାର ପରିଚିତ ହେବାକୁ ପଡିବ |",
              "as": "এইটো সঁচা; চাকৰি সলনি কৰা অলপ টান আছে। তুমি তোমাৰ কৰ্মস্থানৰ লগত আকৌ চিনাকি হ'ব লাগিব।",
              "gu": "તે સાચું છે; નોકરી બદલવામાં તેના પડકારો હોય છે. તમારે તમારા કાર્યસ્થળથી ફરીથી પરિચિત થવું પડશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "It’s scary to think about starting again. But my growth will be limited if I continue to work here.",
              "hi": "फिर से शुरू करने के बारे में सोचना डरावना है। लेकिन अगर मैं यहां काम करना जारी रखती हूं तो मेरी तरक्की सीमित होगी।",
              "ka": "ಮತ್ತೆ ಪ್ರಾರಂಭಿಸುವ ಬಗ್ಗೆ ಯೋಚಿಸುವುದು ಭಯಾನಕವೆನಿಸುತ್ತದೆ. ಆದರೆ ನಾನು ಇಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಮುಂದುವರೆಸಿದರೆ ನನ್ನ ಬೆಳವಣಿಗೆ ಸೀಮಿತವಾಗಿರುತ್ತದೆ.",
              "te": "మళ్లీ ప్రారంభించడం గురించి ఆలోచించడమంటేనే భయంగా ఉంది. కానీ నేను ఇక్కడ పని చేస్తే నేను సరిగ్గా ఎదగలేను.",
              "or": "ପୁନର୍ବାର ଆରମ୍ଭ କରିବା ବିଷୟରେ ଚିନ୍ତା କରିବା ଭୟାନକ ଅଟେ | କିନ୍ତୁ ଯଦି ମୁଁ\n  ଏଠାରେ କାର୍ଯ୍ୟ ଜାରି ରଖେ ତେବେ ମୋର ଅଭିବୃଦ୍ଧି ସୀମିତ ରହିବ |",
              "as": "পুনৰ আৰম্ভ কৰাৰ বিষয়ে ভাবিবলৈ ভয় লাগে। কিন্তু যদি মই ইয়াত কাম কৰি থাকো তেন্তে মই আগবাঢ়িব নোৱাৰিম।",
              "gu": "ફરી શરૂ કરવા વિશે વિચારવું ભય જેવુ લાગે છે. પરંતુ જો હું અહીં કામ કરવાનું ચાલુ રાખું તો મારી વૃદ્ધિ મર્યાદિત રહેશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "It’s a tough decision, but you’ve done well in your career so far. Regardless of what decision you take, it will help you grow.",
              "hi": "एक कठिन निर्णय है, लेकिन तुमने अब तक अपने करियर में अच्छा प्रदर्शन किया है। भले ही तुम कुछ भी निर्णय लो, यह तुम्हारे विकास में सहायक होगा।",
              "ka": "ಇದು ಕಠಿಣ ನಿರ್ಧಾರ, ಆದರೆ ನೀನು ಇಲ್ಲಿಯವರೆಗೆ ನಿನ್ನ ವೃತ್ತಿಜೀವನದಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸಿದ್ದೀಯಾ. ನೀನು ಯಾವ ನಿರ್ಧಾರವನ್ನು ತೆಗೆದುಕೊಂಡರೂ ಅದು ನಿನ್ನ ಬೆಳವಣಿಗೆಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "ఇది చాలా కష్టమైన నిర్ణయం, కానీ నువ్వు ఇప్పటివరకు నీ కెరీర్‌లో ప్రతి ఒక్కటి బాగా చేశావు. నువ్వు ఏ నిర్ణయం తీసుకున్నా అది నువ్వు ఎదగడానికి సహాయపడుతుంది.",
              "or": "ଏହା ଏକ କଠିନ ନିଷ୍ପତ୍ତି, କିନ୍ତୁ ଆପଣ ଏପର୍ଯ୍ୟନ୍ତ ନିଜ କ୍ୟାରିଅରରେ ଭଲ କରିସାରିଛନ୍ତି |\n  ଆପଣ କେଉଁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତୁ ନା କାହିଁକି, ଏହା ଆପଣଙ୍କୁ ଆଗକୁ ବଢିବାରେ ସାହାଯ୍ୟ କରିବ |",
              "as": "এইটো এটা টান সিদ্ধান্ত, কিন্তু তুমি এতিয়ালৈকে নিজৰ কেৰিয়াৰত ভাল কৰি আছা। গতিকে, তুমি যিয়ে নকৰা কিয়, ই তোমাক সহায়ে কৰিব।",
              "gu": "આ એક અઘરો નિર્ણય છે, પરંતુ તું અત્યાર સુધી તારી કારકિર્દીમાં સારું પ્રદર્શન કર્યું છે. ધ્યાનમાં રાખજે કે જે પણ નિર્ણય લે છે  પણ તે તને વૃદ્ધિ કરવામાં મદદ કરશે."
            }
          }
        ]
      },
      "scene19": {
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "Move out of state",
              "hi": "राज्य से बाहर निकले",
              "ka": "ಈ ರಾಜ್ಯವನ್ನು ಬಿಟ್ಟು ಹೊರಡು",
              "te": "రాష్ట్రం బయటకి వెళ్లండి",
              "or": "ରାଜ୍ୟ ବାହାରକୁ ଯାଆନ୍ତୁ |",
              "as": "ৰাজ্যৰ বাহিৰলৈ যাওক",
              "gu": "રાજ્ય બહાર જવાનું"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira accepts the manager’s offer. She moves to the new city to work as an assistant in the factory.",
              "hi": "मीरा प्रबंधक के प्रस्ताव को स्वीकार करती है। वह कारखाने में सहायक के रूप में काम करने के लिए नए शहर में जाती है।",
              "ka": "ಮೀರಾ ಮ್ಯಾನೇಜರ್ ನೀಡಿರುವ  ಪ್ರಸ್ತಾಪವನ್ನು ಸ್ವೀಕರಿಸುತ್ತಾಳೆ. ಕಾರ್ಖಾನೆಯಲ್ಲಿ ಸಹಾಯಕಿಯಾಗಿ ಕೆಲಸ ಮಾಡಲು ಹೊಸ ನಗರಕ್ಕೆ ತೆರಳುತ್ತಾಳೆ.",
              "te": "మీరా మేనేజర్ ఇచ్చిన ఆఫర్‌ను తీసుకుంటుంది. ఆమె ఫ్యాక్టరీలో అసిస్టెంట్‌గా పనిచేయడానికి కొత్త నగరానికి వెళుతుంది.",
              "or": "ମୀରା ମ୍ୟାନେଜରଙ୍କ ଅଫର ଗ୍ରହଣ କରନ୍ତି | ସେ କାରଖାନାରେ ଆସିଷ୍ଟାଣ୍ଟ ଭାବରେ\n  କାମ କରିବାକୁ ନୂଆ ସହରକୁ ଚାଲିଆସନ୍ତି |",
              "as": "মীৰাই মেনেজাৰৰ প্ৰস্তাৱ লৈ লয়। তাই কাৰখানাত সহায়ক হিচাপে কাম কৰিবলৈ নতুন চহৰলৈ যায়।",
              "gu": "મીરાએ મેનેજરની તક સ્વીકારી. તે કારખાનામાં સહાયક તરીકે કામ કરવા માટે નવા શહેરમાં જાય છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_1_2",
              "hi": "D5_1_2",
              "ka": "D5_1_2",
              "te": "D5_1_2",
              "or": "D5_1_2",
              "as": "D5_1_2",
              "gu": "D5_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Since it is her first time outside Assam, she has difficulty settling into the new city. She has to figure out the stay and food and manage expenses like rent, electricity bills all independently.",
              "hi": "चूंकि यह असम के बाहर उसके लिए पहली बार है, उसे नए शहर में बसने में कठिनाई हो रही है। उसे रहने एवं भोजन  के विषय पर सोचना है, किराए और  बिजली के बिल जैसे सभी खर्चों  का स्वतंत्र रूप से  प्रबंधन करना है, ।",
              "ka": "ಅಸ್ಸಾಂನ ಹೊರಗೆ ಇದು ಮೊದಲ ಬಾರಿಗೆ ಆದ್ದರಿಂದ,ಆಕೆಗೆ ಹೊಸ ನಗರದಲ್ಲಿ ನೆಲೆಸಲು ಕಷ್ಟವೆನಿಸುತ್ತದೆ. ಅವಳು ಉಳಿದುಕೊಳ್ಳುವ ಹಾಗೂ  ಆಹಾರದ ಬಗ್ಗೆ ಲೆಕ್ಕಾಚಾರ ಮಾಡಬೇಕು ಮತ್ತು ಬಾಡಿಗೆ, ವಿದ್ಯುತ್ ಬಿಲ್‌ಗಳಂತಹ ವೆಚ್ಚಗಳನ್ನು ಸ್ವತಂತ್ರವಾಗಿ ನಿರ್ವಹಿಸಬೇಕು.",
              "te": "అస్సాం నుండి బయటకి వెళ్ళడం ఆమెకి ఇదే మొదటి సారి కాబట్టి, కొత్త నగరంలో స్థిరపడటం ఆమెకు కష్టంగా ఉంది. ఆమె ఉండటానికి, తినడానికి, అద్దె, విద్యుత్ బిల్లులు వంటి ఖర్చులను ఒంటరిగానే చూసుకోవాలి.",
              "or": "ଆସାମ ବାହାରେ ଏହା ପ୍ରଥମ ଥର ହୋଇଥିବାରୁ ତାଙ୍କୁ ନୂଆ ସହରରେ ବସବାସ\n  କରିବାରେ ଅସୁବିଧା ହେଉଛି | ତାଙ୍କୁ ରହିବା ଏବଂ ଖାଦ୍ୟର ଆକଳନ କରିବାକୁ ପଡିବ ଏବଂ ଭଡା, ବିଦ୍ୟୁତ୍ ବିଲ୍ ପରି ଖର୍ଚ୍ଚ ପରିଚାଳନା କରିବାକୁ ପଡିବ |",
              "as": "যিহেতু এইটো তাইৰ অসমৰ বাহিৰত প্ৰথমবাৰৰ বাবে গৈছে, তাই নতুন চহৰখনত মিলিবলৈ অসুবিধা পাইছে। তাই থাকিব লাগিব আৰু খাদ্য বিচাৰি উলিয়াব লাগিব আৰু ভাড়া, বিদ্যুতৰ বিলৰ দৰে খৰচবোৰ অকলেই চাব লাগিব।",
              "gu": "આસામની બહાર તે પ્રથમ વખત જવાથી તેને નવા શહેરમાં સ્થાયી થવામાં મુશ્કેલી પડી રહી છે. તેને રહેવા અને ખાવાનું નક્કી કરવું પડશે અને ભાડું, વીજળીના બિલ જેવા ખર્ચાઓનું સંચાલન સ્વતંત્ર રીતે કરવું પડશે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_1_3",
              "hi": "D5_1_3",
              "ka": "D5_1_3",
              "te": "D5_1_3",
              "or": "D5_1_3",
              "as": "D5_1_3",
              "gu": "D5_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Her new supervisor has worked for the government and private companies and is very experienced. She gets to learn a lot about the industry.",
              "hi": "उसके नए सुपरवाइज़र ने सरकार और निजी कंपनियों के लिए काम किया है और वह बहुत अनुभवी हैं। वह इंडस्ट्री के बारे में बहुत कुछ सीखती है।",
              "ka": "ಅವಳ ಹೊಸ ಮೇಲ್ವಿಚಾರಕರು ಸರ್ಕಾರಿ ಮತ್ತು ಖಾಸಗಿ ಕಂಪನಿಗಳಲ್ಲಿ ಕೆಲಸ ಮಾಡಿದ್ದಾರೆ ಹಾಗೂ ತುಂಬಾ ಅನುಭವಿಯಾಗಿದ್ದಾರೆ. ಅವಳು ಉದ್ಯಮದ ಬಗ್ಗೆ ಸಾಕಷ್ಟು ಕಲಿಯುತ್ತಾಳೆ.",
              "te": "ఆమె కొత్త సూపర్‌వైజర్ ప్రభుత్వ మరియు ప్రైవేట్ కంపెనీలలో పనిచేశారు మరియు చాలా అనుభవం ఉన్నవారు. ఆమె పరిశ్రమ గురించి చాలా నేర్చుకుంటుంది.",
              "or": "ତାଙ୍କର ନୂତନ ସୁପରଭାଇଜର ସରକାରୀ ଏବଂ ବେସରକାରୀ କମ୍ପାନୀଗୁଡିକରେ\n  କାର୍ଯ୍ୟ କରିଛନ୍ତି ଏବଂ ବହୁତ ଅଭିଜ୍ଞ ଅଟନ୍ତି | ସେ ଇଣ୍ଡଷ୍ଟ୍ରି ବିଷୟରେ ବହୁତ କିଛି ଜାଣିବାକୁ ପାଇଲେ |",
              "as": "তাইৰ নতুন চুপাৰভাইজাৰে চৰকাৰী আৰু ব্যক্তিগত কোম্পানীত কাম কৰিছে আৰু বহুত অভিজ্ঞ। তাই উদ্যোগটোৰ বিষয়ে বহুত শিকিবলৈ পায়।",
              "gu": "તેના નવા સુપરવાઈઝર સરકારી અને ખાનગી કંપનીઓ માટે કામ કરી ચૂક્યા છે અને ખૂબ જ અનુભવી છે. તેને કારખાના વિશે ઘણું શીખવા મળે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_2_1",
              "hi": "D5_2_1",
              "ka": "D5_2_1",
              "te": "D5_2_1",
              "or": "D5_2_1",
              "as": "D5_2_1",
              "gu": "D5_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira’s planning skills turn out to be very useful to the team. She is often consulted by seniors when making project schedules and setting deadlines.",
              "hi": "मीरा का योजना कौशल टीम के लिए बहुत उपयोगी हो जाता है। प्रोजेक्ट शेड्यूल और समय सीमा निर्धारित करते समय वरिष्ठ साथी अक्सर उससे  परामर्श करते हैं।",
              "ka": "ಮೀರಾಳ ಯೋಜನಾ ಕೌಶಲ್ಯವು ತಂಡಕ್ಕೆ ತುಂಬಾ ಉಪಯುಕ್ತವಾಗುತ್ತದೆ. ಪ್ರಾಜೆಕ್ಟ್ ಶೆಡ್ಯೂಲ್‌ಗಳನ್ನು ಮಾಡುವಾಗ ಮತ್ತು ಗಡುವನ್ನು ಹೊಂದಿಸುವಾಗ ಆಕೆಯ ಜೊತೆಗೆ ಹೆಚ್ಚಾಗಿ ಸೀನಿಯರ್ಸ್ ಸಮಾಲೋಚಿಸುತ್ತಾರೆ.",
              "te": "మీరా ప్లానింగ్ స్కిల్స్ తన టీంకు చాలా ఉపయోగకరంగా ఉన్నాయి. ప్రాజెక్ట్ షెడ్యూల్‌ లను రూపొందించేటప్పుడు మరియు గడువులను నిర్ణయించేటప్పుడు ఆమెను తరచుగా సీనియర్లు కలుస్తారు.",
              "or": "ମୀରାଙ୍କ ଯୋଜନା ଦକ୍ଷତା ଦଳ ପାଇଁ ଅତ୍ୟନ୍ତ ଉପଯୋଗୀ ହୋଇଯାଏ | ପ୍ରୋଜେକ୍ଟ \n କାର୍ଯ୍ୟସୂଚୀ ପ୍ରସ୍ତୁତ କରିବା ଏବଂ ସମୟସୀମା ସ୍ଥିର କରିବା ସମୟରେ ତାଙ୍କୁ ସିନିୟରମାନଙ୍କ ଦ୍ୱାରା ପରାମର୍ଶ ଦିଆଯାଏ |",
              "as": "মীৰাৰ কৰিব বিচৰা কামবোৰ দলটোৰ কাৰনে বহুত দৰকাৰি হৈ পৰে। প্ৰকল্পৰ ৰুটিন বনাওতে আৰু নিৰ্ধাৰণ কৰোঁতে আৰু সময়ৰ ডেডলাইন বনাওতে তাইক সৈতে প্ৰায়ে চিনিয়ৰ সকলে সুধে ।",
              "gu": "મીરાનું આયોજન કૌશલ્ય ટીમ માટે ખૂબ જ ઉપયોગી સાબિત થાય છે. પ્રોજેક્ટ સમયપત્રક બનાવતી વખતે અને સમયમર્યાદા નક્કી કરતી વખતે તે ઘણીવાર સિનિયર  લોકો દ્વારા સલાહ લેવામાં આવે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_2_2",
              "hi": "D5_2_2",
              "ka": "D5_2_2",
              "te": "D5_2_2",
              "or": "D5_2_2",
              "as": "D5_2_2",
              "gu": "D5_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She soon becomes a significant employee in her new workplace and is responsible for managing the production of all the new parts manufactured in the factory.",
              "hi": "वह जल्द ही अपने नए कार्यस्थल में एक महत्वपूर्ण कर्मचारी बन जाती है और कारखाने में निर्मित सभी नए हिस्सों के उत्पादन के प्रबंधन के लिए जिम्मेदार है।",
              "ka": "ಅವಳು ಶೀಘ್ರದಲ್ಲೇ ತನ್ನ ಹೊಸ ಕೆಲಸದ ಸ್ಥಳದಲ್ಲಿ ಗಮನ ಸೆಳೆಯುವ ಉದ್ಯೋಗಿಯಾಗುತ್ತಾಳೆ ಮತ್ತು ಕಾರ್ಖಾನೆಯಲ್ಲಿ ತಯಾರಿಸಲಾದ ಎಲ್ಲಾ ಹೊಸ ಭಾಗಗಳ ಉತ್ಪಾದನೆಯನ್ನು ನಿರ್ವಹಿಸುವ ಜವಾಬ್ದಾರಿಯನ್ನು ಹೊಂದಿದ್ದಾಳೆ.",
              "te": "ఆమె కొద్ది సమయంలోనే తన కొత్త కంపెనీలో మంచి పేరు తెచ్చుకుంటుంది. ఫ్యాక్టరీలో తయారు చేసిన అన్ని కొత్త భాగాల ఉత్పత్తిని మేనేజ్ చేస్తుంది.",
              "or": "ସେ ଶୀଘ୍ର ନିଜର ନୂତନ କର୍ମକ୍ଷେତ୍ରରେ ଏକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ କର୍ମଚାରୀ ହୁଅନ୍ତି ଏବଂ\n  କାରଖାନାରେ ଉତ୍ପାଦିତ ସମସ୍ତ ନୂତନ ଅଂଶର ଉତ୍ପାଦନ ପରିଚାଳନା ପାଇଁ ଦାୟୀ |",
              "as": "তাই সোনকালেই তাইৰ নতুন অফিচত এজনী দৰকাৰি কৰ্মচাৰী হৈ পৰে আৰু কাৰখানাত বনোৱা সকলো নতুন অংশৰ উৎপাদন চোৱাৰ কাম কৰে।",
              "gu": "તે ટૂંક સમયમાં તેના નવા કાર્યસ્થળમાં મહત્વની કર્મચારી બની જાય છે અને કારખાનામાં ઉત્પાદિત તમામ નવા ભાગોના ઉત્પાદનનું સંચાલન કરવા માટે જવાબદાર છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_2_3",
              "hi": "D5_2_3",
              "ka": "D5_2_3",
              "te": "D5_2_3",
              "or": "D5_2_3",
              "as": "D5_2_3",
              "gu": "D5_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is happy with the promotion. It has been a tough challenge getting here, but she is satisfied that she has made it.",
              "hi": "मीरा पदोन्नति से खुश है। यह सब एक कठिन चुनौती रही है, लेकिन वह संतुष्ट है कि उसने इसे बनाया है।",
              "ka": "ದೊರಕಿರುವ ಪ್ರೊಮೋಷನ್ನಿನಿಂದ ಮೀರಾ ಖುಷಿಯಾಗಿದ್ದಾಳೆ. ಇಲ್ಲಿಗೆ ಬರುವುದು ಕಠಿಣ ಸವಾಲಾಗಿತ್ತು, ಆದರೆ ಅವಳು ಅದನ್ನು ಸಾಧಿಸಿದ ತೃಪ್ತಿ ಹೊಂದಿದ್ದಾಳೆ.",
              "te": "మీరాకి ప్రమోషన్‌ రావడం వలన సంతోషంగా ఉంది. మొదట్లో ఇక్కడికి రావడం తనకి చాలా కష్టమైన సవాలుగా ఉంది, కానీ ఇప్పుడు ఆమె దాన్ని సాధించానని సంతృప్తి చెందింది.",
              "or": "ମୀରା ପଦୋନ୍ନତିରେ ଖୁସି ଅଛନ୍ତି | ଏଠାରେ ପହଞ୍ଚିବା ଏକ କଷ୍ଟସାଧ୍ୟ ଆହ୍ଵାନ କିନ୍ତୁ ସେ ଏହାକୁ ପ୍ରସ୍ତୁତ କରି ସନ୍ତୁଷ୍ଟ ଅଛନ୍ତି |",
              "as": "মীৰা আগবাঢ়িব পায় সুখী। ইয়ালৈ অহাটো অলপ টান আছিল, কিন্তু তাই সুখি যে তাই এইটো কৰিছে।",
              "gu": "મીરા પ્રમોશનથી ખુશ છે. અહીં પહોંચવું એક અઘરો પડકાર રહ્યો છે, પરંતુ તે સંતુષ્ટ છે કે તેનણે  તે કરી બતાવ્યુ છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "Assistant Engineer",
              "hi": "असिस्टेंट इंजीनियर              ",
              "ka": "ಸಹಾಯಕ ಎಂಜಿನಿಯರ್",
              "te": "అసిస్టెంట్ ఇంజనీర్",
              "or": "ସହକାରୀ ଯନ୍ତ୍ରୀ",
              "as": "সহকাৰী অভিযন্তা",
              "gu": "મદદનીશ ઈજનેર"
            }
          }
        ]
      },
      "scene20": {
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "Work under Anuj",
              "hi": "अनुज के अधीन काम",
              "ka": "ಅನುಜ್ ಕೆಳಗೆ ಕೆಲಸ ಮಾಡುವುದೂ",
              "te": "అనుజ్ కింద పని",
              "or": "ଅନୁଜଙ୍କ ଅଧୀନରେ କାମ କର |",
              "as": "অনুজৰ তলত কাম কৰা",
              "gu": "અનુજ હેઠળ કામ કરે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She continues working hard on the project, hoping the manager will give her more projects on the factory floor.",
              "hi": "वह प्रोजेक्ट पर कड़ी मेहनत कर रही है, उम्मीद है कि प्रबंधक फैक्ट्री में उसे और अधिक प्रोजेक्ट देंगे।",
              "ka": "ಮ್ಯಾನೇಜರ್ ತನ್ನ ಕಾರ್ಖಾನೆಯ ಅಂಕಣದಲ್ಲಿ ಹೆಚ್ಚಿನ ಯೋಜನೆಗಳನ್ನು ನೀಡುತ್ತಾನೆ ಎಂದು ಆಶಿಸುತ್ತಾ ಅವಳು ಯೋಜನೆಯಲ್ಲಿ ಶ್ರಮಿಸುತ್ತಿದ್ದಾಳೆ.",
              "te": "ఫ్యాక్టరీ ఫ్లోర్‌లో మేనేజర్ ఆమెకు మరిన్ని ప్రాజెక్ట్ లు ఇస్తారని ఆశతో ప్రాజెక్ట్ కోసం కష్టపడి పని చేస్తూనే ఉంది.",
              "or": "ମ୍ୟାନେଜର ତାଙ୍କୁ କାରଖାନା ଚଟାଣରେ ଅଧିକ ପ୍ରୋଜେକ୍ଟ ଦେବେ ବୋଲି ଆଶା କରି ସେ ଏହି ପ୍ରକଳ୍ପରେ କଠିନ ପରିଶ୍ରମ ଜାରି ରଖିଛନ୍ତି |",
              "as": "তাই প্ৰকল্পটোত বহুক কষ্ট কৰিছে, আশা কৰে যে মেনেজাৰে তাইক কাৰখানাৰ কাম আৰু দিব।",
              "gu": "તે પ્રોજેક્ટ પર સખત મહેનત કરવાનું ચાલુ રાખે છે, આશા છે કે મેનેજર તેને કારખાનની જગ્યા પર વધુ પ્રોજેક્ટ્સ આપશે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_1_2",
              "hi": "D6_1_2",
              "ka": "D6_1_2",
              "te": "D6_1_2",
              "or": "D6_1_2",
              "as": "D6_1_2",
              "gu": "D6_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "However, a few months go by, and she still doesn’t get the work she is looking for. She feels it’s time to look for a new job.",
              "hi": "कुछ महीने बीत जाते हैं, और उसे अभी भी वह काम नहीं मिला है जिसे वह ढूंढ रही है। वह अनुभव करती है कि यह एक नई नौकरी की तलाश करने का समय है।",
              "ka": "ಆದಾಗ್ಯೂ, ಕೆಲವು ತಿಂಗಳುಗಳು ಜಾರುತ್ತವೆ, ಮತ್ತು ಅವಳು ಹುಡುಕುತ್ತಿರುವ ಕೆಲಸವನ್ನು ಅವಳು ಇನ್ನೂ ಪಡೆಯಲು ಆಗಿರುವುದಿಲ್ಲ. ಹೊಸ ಕೆಲಸವನ್ನು ಹುಡುಕುವ ಸಮಯ ಬಂದಿದೆ ಎಂದು ಅವಳು ಭಾವಿಸುತ್ತಾಳೆ.",
              "te": "కానీ కొన్ని నెలలు గడిచినా, ఆమె వెతుకుతున్న పని ఇంకా ఆమెకు లభించలేదు. కొత్త ఉద్యోగం కోసం వెతకాల్సిన సమయం వచ్చిందని ఆమె భావిస్తోంది.",
              "or": "ତଥାପି, କିଛି ମାସ ବିତିଯାଏ, ଏବଂ ସେ ଖୋଜୁଥିବା କାମକୁ ଏପର୍ଯ୍ୟନ୍ତ ପାଇପାରୁ ନାହିଁ | ସେ ଅନୁଭବ କରନ୍ତି ଯେ ଏକ ନୂତନ ଚାକିରି ଖୋଜିବା ସମୟ |",
              "as": "কিন্তু কেইমাহমান পাৰ হৈ যায়, আৰু তাই এতিয়াও তাই বিচাৰি থকা কামটো নাপায়। তাই বুজি পায় যে এতিয়া নতুন চাকৰি বিচাৰি বলৈ সময় হৈছে।",
              "gu": "જો કે, થોડા મહિનાઓ વીતી જાય છે, અને હજુ પણ તે જે કામ શોધી રહી છે તે મળતું નથી. તેને લાગે છે કે હવે નવી નોકરી શોધવાનો સમય આવી ગયો છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_1_3",
              "hi": "D6_1_3",
              "ka": "D6_1_3",
              "te": "D6_1_3",
              "or": "D6_1_3",
              "as": "D6_1_3",
              "gu": "D6_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira starts looking for places where there are opportunities for women to work in the factory.",
              "hi": "मीरा उन स्थानों की तलाश शुरू कर रही है जहां महिलाओं के पास कारखाने में काम करने के अवसर हैं।",
              "ka": "ಕಾರ್ಖಾನೆಯಲ್ಲಿ ಮಹಿಳೆಯರಿಗೆ ಕೆಲಸ ಮಾಡಲು ಅವಕಾಶವಿರುವ ಕೆಲಸಗಳನ್ನು ಮೀರಾ ಹುಡುಕಲಾರಂಭಿಸುತ್ತಾಳೆ.",
              "te": "మీరా ఫ్యాక్టరీలో పనిచేసే మహిళలకు అవకాశాలు ఉన్న ప్రదేశాలను వెతకడం ప్రారంభిస్తుంది.",
              "or": "ମୀରା ସେହି ସ୍ଥାନ ଖୋଜିବା ଆରମ୍ଭ କରନ୍ତି ଯେଉଁଠାରେ କାରଖାନାରେ ମହିଳାମାନଙ୍କ ପାଇଁ କାମ କରିବାର ସୁଯୋଗ ଅଛି |",
              "as": "মীৰাই এনে ঠাই বিচাৰিবলৈ আৰম্ভ কৰে য'ত মহিলাসকলে কাৰখানাত কাম কৰাৰ সুযোগ পায়।",
              "gu": "મીરા એવી જગ્યાઓ શોધવાનું શરૂ કરે છે જ્યાં કારખાનામાં મહિલાઓ માટે કામ કરવાની તક હોય."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_1_3b",
              "hi": "D6_1_3b",
              "ka": "D6_1_3b",
              "te": "D6_1_3b",
              "or": "D6_1_3b",
              "as": "D6_1_3b",
              "gu": "D6_1_3b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She has a hard time finding such a place near her home and wonders if she should have moved to the other factory when she had the chance.",
              "hi": "उसे अपने घर के पास ऐसी जगह खोजने में कठिनाई होती है, वह सोचती है, उसे अन्य कारखाने में तभी चले जाना चाहिए था, जब उसे मौका मिला था।",
              "ka": "ತನ್ನ ಮನೆಯ ಸಮೀಪ ಅಂತಹ ಕೆಲಸವನ್ನು ಹುಡುಕಲು ಅವಳು ಕಷ್ಟಪಡುತ್ತಾಳೆ ಮತ್ತು ಅವಕಾಶ ಸಿಕ್ಕಾಗ ಅವಳು ಬೇರೆ ಕಾರ್ಖಾನೆಗೆ ಹೋಗಬೇಕಾಗಿತ್ತೇ ಎಂದು ಯೋಚಿಸುತ್ತಾಳೆ.",
              "te": "ఆమె తన ఇంటికి దగ్గరలో అలాంటి స్థలాన్ని కనుక్కోవడం చాలా కష్టమైంది మరియు తనకు అవకాశం వచ్చినప్పుడు ఆమె ఇతర ఫ్యాక్టరీకి వెళ్లి ఉండాలని అనుకుంటుంది.",
              "or": "ତାଙ୍କ ଘର ନିକଟରେ ଏପରି ସ୍ଥାନ ଖୋଜିବାରେ ତାଙ୍କୁ ବହୁତ କଷ୍ଟ ହୁଏ ଏବଂ ଯେତେବେଳେ ସେ ସୁଯୋଗ ପାଇଲେ ସେ ଅନ୍ୟ କାରଖାନାକୁ ଯାଇଥାନ୍ତେ କି ବୋଲି ପ୍ରଶ୍ନ କରନ୍ତି |",
              "as": "তাইৰ ঘৰৰ ওচৰত এনেকুৱা ঠাই বিচাৰি পাবলৈ বৰ টান পাইছে আৰু আচৰিত হয় যে তাই সুযোগ পোৱা আনটো কাৰখানালৈ যাব লাগিছিল নেকি।",
              "gu": "તેને તેના ઘરની નજીક આવી જગ્યા શોધવામાં મુશ્કેલી પડે છે અને આશ્ચર્ય થાય છે કે જ્યારે તેને તક મળી ત્યારે તેને બીજા કારખાનામાં કામ કરવા જવું જોઈએ."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_2_1",
              "hi": "D6_2_1",
              "ka": "D6_2_1",
              "te": "D6_2_1",
              "or": "D6_2_1",
              "as": "D6_2_1",
              "gu": "D6_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "In a few months, she finds a job in a smaller factory near home and quits her current job.",
              "hi": "कुछ महीनों में, उसे घर के पास एक छोटे से कारखाने में नौकरी मिलती है और वह वर्तमान नौकरी छोड़ती है।",
              "ka": "ಕೆಲವು ತಿಂಗಳುಗಳಲ್ಲಿ, ಅವಳು ಮನೆಯ ಸಮೀಪವಿರುವ ಒಂದು ಸಣ್ಣ ಕಾರ್ಖಾನೆಯಲ್ಲಿ ಕೆಲಸವನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾಳೆ ಮತ್ತು ತನ್ನ ಪ್ರಸ್ತುತ ಕೆಲಸವನ್ನು ಬಿಡುತ್ತಾಳೆ.",
              "te": "కొన్ని నెలలకి ఆమె ప్రస్తుతం చేస్తున్నఉద్యోగాన్ని మానివేసి ఇంటికి దగ్గరలో ఉన్న ఒక చిన్న ఫ్యాక్టరీలో చేరింది.",
              "or": "କିଛି ମାସ ମଧ୍ୟରେ, ସେ ଘର ନିକଟରେ ଥିବା ଏକ ଛୋଟ କାରଖାନାରେ ଚାକିରି\n  ପାଇଲେ ଏବଂ ବର୍ତ୍ତମାନର ଚାକିରି ଛାଡିଦେଲେ |",
              "as": "কেইমাহমানৰ ভিতৰতে, তাই ঘৰৰ ওচৰৰ এটা সৰু কাৰখানাত চাকৰি এটা বিচাৰি পায় আৰু বৰ্তমানৰ চাকৰি টো এৰি দিয়ে।",
              "gu": "થોડા મહિનામાં, તેને ઘરની નજીક એક નાના કારખાનામાં કામ મળે છે અને તેની વર્તમાન નોકરી છોડી દે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_2_1b",
              "hi": "D6_2_1b",
              "ka": "D6_2_1b",
              "te": "D6_2_1b",
              "or": "D6_2_1b",
              "as": "D6_2_1b",
              "gu": "D6_2_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Her pay is lesser than the previous job, but since she has the experience of working in a big manufacturing company, she gets the role of a manager in her new place.",
              "hi": "उसका वेतन पिछली नौकरी से कम है, लेकिन चूंकि उसे एक बड़ी विनिर्माण कंपनी में काम करने का अनुभव है, इसलिए उसे अपनी नई जगह में एक प्रबंधक की भूमिका मिलती है।",
              "ka": "ಹಿಂದಿನ ಕೆಲಸಕ್ಕಿಂತ ಅವಳ ಸಂಬಳ ಕಡಿಮೆ, ಆದರೆ ಅವಳು ದೊಡ್ಡ ಉತ್ಪಾದನಾ ಕಂಪನಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಿದ ಅನುಭವವನ್ನು ಹೊಂದಿದ್ದರಿಂದ, ಅವಳು ತನ್ನ ಹೊಸ ಸ್ಥಳದಲ್ಲಿ ಮ್ಯಾನೇಜರ್ ಕರ್ತವ್ಯವನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
              "te": "ఆమె జీతం గతంలో కంటే తక్కువ, కానీ ఆమెకు పెద్ద తయారీ కంపెనీలో పనిచేసిన అనుభవం ఉన్నందుకు కొత్త కంపెనీలో మేనేజర్ జాబ్ వస్తుంది.",
              "or": "ତା’ର ଦରମା ପୂର୍ବ ଚାକିରି ଅପେକ୍ଷା କମ୍, କିନ୍ତୁ ଏକ ବଡ ଉତ୍ପାଦନକାରୀ କମ୍ପାନୀରେ \n କାମ କରିବାର ଅଭିଜ୍ଞତା ଥିବାରୁ ସେ ତାଙ୍କ ନୂତନ ସ୍ଥାନରେ ମ୍ୟାନେଜର ଭୂମିକା ଗ୍ରହଣ କରନ୍ତି |",
              "as": "তাইৰ দৰমহা আগৰ চাকৰিতকৈ কম, কিন্তু যিহেতু তাইৰ এটা ডাঙৰ কোম্পানী এটাত কাম কৰিছে, তাই তাইৰ নতুন স্থানত মেনেজাৰ বনি যায়।",
              "gu": "તનો પગાર અગાઉની નોકરી કરતા ઓછો છે, પરંતુ તેને મોટી ઉત્પાદન કંપનીમાં કામ કરવાનો અનુભવ હોવાથી તેને તેની નવી જગ્યાએ મેનેજરની ભૂમિકા મળે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_2_2",
              "hi": "D6_2_2",
              "ka": "D6_2_2",
              "te": "D6_2_2",
              "or": "D6_2_2",
              "as": "D6_2_2",
              "gu": "D6_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira proves to be a good manager due to her planning skills. She makes very detailed project plans which help her team work efficiently and meet deadlines.",
              "hi": "मीरा अपने प्लानिंग कौशल के कारण एक अच्छी प्रबंधक साबित होती है। वह बहुत विस्तृत प्रोजेक्ट योजना बनाती है जो उसकी टीम को कुशलतापूर्वक और समय सीमा में काम करने में सहायक होती है।",
              "ka": "ಮೀರಾ ತನ್ನ ಯೋಜನಾ ಕೌಶಲ್ಯದಿಂದಾಗಿ ಉತ್ತಮ ಮ್ಯಾನೇಜರ್ ಎಂದು ಸಾಬೀತುಪಡಿಸುತ್ತಾಳೆ. ಅವಳು ತನ್ನ ತಂಡವು ಪರಿಣಾಮಕಾರಿಯಾಗಿ ಕೆಲಸ ಮಾಡಲು ಮತ್ತು ಗಡುವನ್ನು ಪೂರೈಸಲು ಸಹಾಯ ಮಾಡುವ ಅತ್ಯಂತ ವಿವರವಾದ ಯೋಜನೆಗಳನ್ನು ತಯಾರಿಸುತ್ತಾಳೆ.",
              "te": "మీరా తన ప్లానింగ్ స్కిల్స్ కారణంగా మంచిగా మేనేజర్ గా పేరు పొందుతుంది. ఆమె చాలా ప్రాజెక్ట్ ప్లాన్‌లను పక్కాగా చేస్తుంది, ఇది ఆమె టీం బాగా పని చేయడానికి మరియు పూర్తి చేయడానికి సహాయపడుతుంది.",
              "or": "ମୀରା ତାଙ୍କର ଯୋଜନା ଦକ୍ଷତା ହେତୁ ଜଣେ ଭଲ ପରିଚାଳକ ବୋଲି ପ୍ରମାଣ କରେ | \n ସେ ବହୁତ ବିସ୍ତୃତ ପ୍ରୋଜେକ୍ଟ ଯୋଜନା ପ୍ରସ୍ତୁତ କରନ୍ତି ଯାହା ତାଙ୍କ ଦଳକୁ ଦକ୍ଷତାର ସହିତ କାର୍ଯ୍ୟ କରିବାରେ ଏବଂ ସମୟସୀମା ପୂରଣ କରିବାରେ ସାହାଯ୍ୟ କରେ |",
              "as": "মীৰা তাইৰ ভাল গুনৰ কাৰনে এগৰাকী ভাল মেনেজাৰ বনিব পাৰে। তাই বহুত ধৰনৰ প্ৰকল্প পৰিকল্পনা কৰে যিয়ে তাইৰ দলটোক ভালকৈ কাম কৰাত আৰু সময় মতেৰে শেষ কৰাত সহায় কৰে।",
              "gu": "મીરા તેના આયોજન કૌશલ્યને કારણે એક સારી મેનેજર સાબિત થાય છે. તે ખૂબ વિગતવાર પ્રોજેક્ટ યોજનાઓ બનાવે છે જે તેની ટીમને કાર્યક્ષમ રીતે કાર્ય કરવામાં અને સમયમર્યાદા પૂરી કરવામાં મદદ કરે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_2_3",
              "hi": "D6_2_3",
              "ka": "D6_2_3",
              "te": "D6_2_3",
              "or": "D6_2_3",
              "as": "D6_2_3",
              "gu": "D6_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is happy with her progress and that she has made it so far.",
              "hi": "मीरा अपनी प्रगति से खुश है जो उसने अपने बल पर हासिल की है।",
              "ka": "ಮೀರಾ ತನ್ನ ಪ್ರಗತಿಯಿಂದ ಹಾಗೂ ಅವಳು ಇಲ್ಲಿಯವರೆಗೆ ಸಾಧಿಸಿರುವುದನ್ನು ಕಂಡು ಸಂತೋಷಗೊಂಡಿದ್ದಾಳೆ.",
              "te": "మీరా తను ఎదుగుదలకి మరియు తాను ఇప్పటివరకు సాధించినవాటికి సంతోషంగా ఉంది.",
              "or": "ମୀରା ତାଙ୍କ ପ୍ରଗତିରେ ଖୁସି ଏବଂ ସେ ଏହାକୁ ଏପର୍ଯ୍ୟନ୍ତ କରିପାରିଛନ୍ତି |",
              "as": "মীৰা তাইৰ কামত সুখী যে তাই ইমান খিনিলৈ আগবাঢ়িব পাৰিছে।",
              "gu": "મીરા તેની પ્રગતિથી ખુશ છે અને તેને અત્યાર સુધી આ સફળતા મેળવી છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "Production Manager",
              "hi": "उत्पादन प्रबंधक",
              "ka": "ಪ್ರೊಡಕ್ಷನ್ ಮ್ಯಾನೇಜರ್",
              "te": "ప్రొడక్షన్ మేనేజర్",
              "or": "ଉତ୍ପାଦନ ପରିଚାଳକ",
              "as": "প্ৰডাকচন মেনেজাৰ",
              "gu": "ઉત્પાદક સંચાલક"
            }
          }
        ]
      },
      "scene21": {
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "Look for a new job",
              "hi": "एक नई नौकरी के लिए देखे ",
              "ka": "ಒಂದು ಹೊಸ ಕೆಲಸಕ್ಕೆ ಪ್ರಯತ್ನಿಸು",
              "te": "కొత్త ఉద్యోగం కోసం చూడండి",
              "or": "ଏକ ନୂତନ ଚାକିରି ଖୋଜ |",
              "as": "এটা নতুন চাকৰি বিচাৰক",
              "gu": "નવી નોકરી માટે જુઓ"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira starts looking for places where there are opportunities for women to work in the factory.",
              "hi": "मीरा उन स्थानों की तलाश शुरू कर रही है जहां महिलाओं के पास कारखाने में काम करने के अवसर हैं।",
              "ka": "ಕಾರ್ಖಾನೆಯಲ್ಲಿ ಮಹಿಳೆಯರಿಗೆ ಕೆಲಸ ಮಾಡಲು ಅವಕಾಶವಿರುವ ಕೆಲಸಗಳನ್ನು ಮೀರಾ ಹುಡುಕಲಾರಂಭಿಸುತ್ತಾಳೆ.",
              "te": "మీరా ఫ్యాక్టరీలో పనిచేసే మహిళలకు అవకాశాలు ఉన్న ప్రదేశాలను వెతకడం ప్రారంభిస్తుంది.",
              "or": "ମୀରା ସେହି ସ୍ଥାନ ଖୋଜିବା ଆରମ୍ଭ କରନ୍ତି ଯେଉଁଠାରେ କାରଖାନାରେ ମହିଳାମାନଙ୍କ ପାଇଁ କାମ କରିବାର ସୁଯୋଗ ଅଛି |",
              "as": "মীৰাই এনে ঠাই বিচাৰিবলৈ আৰম্ভ কৰে য'ত মহিলাসকলে কাৰখানাত কাম কৰাৰ সুযোগ পায়।",
              "gu": "મીરા એવી જગ્યાઓ શોધવાનું શરૂ કરે છે જ્યાં કારખાનામાં મહિલાઓ માટે કામ કરવાની તક હોય."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_1_3b",
              "hi": "D7_1_3b",
              "ka": "D7_1_3b",
              "te": "D7_1_3b",
              "or": "D7_1_3b",
              "as": "D7_1_3b",
              "gu": "D7_1_3b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She has a hard time finding such a place near her home and wonders if she should have moved to the other factory when she had the chance.",
              "hi": "उसे अपने घर के पास ऐसी जगह खोजने में कठिनाई होती है, वह सोचती है, उसे अन्य कारखाने में तभी चले जाना चाहिए था, जब उसे मौका मिला था।",
              "ka": "ತನ್ನ ಮನೆಯ ಸಮೀಪ ಅಂತಹ ಕೆಲಸವನ್ನು ಹುಡುಕಲು ಅವಳು ಕಷ್ಟಪಡುತ್ತಾಳೆ ಮತ್ತು ಅವಕಾಶ ಸಿಕ್ಕಾಗ ಅವಳು ಬೇರೆ ಕಾರ್ಖಾನೆಗೆ ಹೋಗಬೇಕಾಗಿತ್ತೇ ಎಂದು ಯೋಚಿಸುತ್ತಾಳೆ.",
              "te": "ఆమె తన ఇంటికి దగ్గరలో అలాంటి స్థలాన్ని కనుగొనడం చాలా కష్టమైంది మరియు తనకు అవకాశం వచ్చినప్పుడు ఆమె ఇతర ఫ్యాక్టరీకి వెళ్లి ఉండాలని ఆలోచిస్తుంది.",
              "or": "ତାଙ୍କ ଘର ନିକଟରେ ଏପରି ସ୍ଥାନ ଖୋଜିବାରେ ତାଙ୍କୁ ବହୁତ କଷ୍ଟ ହୁଏ ଏବଂ ଯେତେବେଲେ ସେ ସୁଯୋଗ ପାଇଲେ ସେ ଅନ୍ୟ କାରଖାନାକୁ ଯାଇଥାନ୍ତେ କି ବୋଲି ପ୍ରଶ୍ନ କରନ୍ତି |",
              "as": "তাইৰ ঘৰৰ ওচৰত এনেকুৱা ঠাই বিচাৰি পাবলৈ বৰ টান পাইছে আৰু আচৰিত হয় যে তাই সুযোগ পোৱা আনটো কাৰখানালৈ যাব লাগিছিল নেকি।",
              "gu": "તેને તેના ઘરની નજીક આવી જગ્યા શોધવામાં મુશ્કેલી પડે છે અને આશ્ચર્ય થાય છે કે જ્યારે તેને તક મળી ત્યારે તેને બીજા કારખાનામાં કામ કરવા જવું જોઈએ."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_2_1",
              "hi": "D7_2_1",
              "ka": "D7_2_1",
              "te": "D7_2_1",
              "or": "D7_2_1",
              "as": "D7_2_1",
              "gu": "D7_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "However, she soon finds a job in a smaller factory near home and quits her current position.",
              "hi": "हालांकि, उसे जल्द ही घर के पास एक छोटे कारखाने में नौकरी मिलती है और वह वर्तमान नौकरी छोड़ देती है।",
              "ka": "ಕೆಲವು ತಿಂಗಳುಗಳಲ್ಲಿ, ಅವಳು ಮನೆಯ ಸಮೀಪವಿರುವ ಒಂದು ಸಣ್ಣ ಕಾರ್ಖಾನೆಯಲ್ಲಿ ಕೆಲಸವನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾಳೆ ಮತ್ತು ತನ್ನ ಪ್ರಸ್ತುತ ಕೆಲಸವನ್ನು ಬಿಡುತ್ತಾಳೆ.",
              "te": "అయితే, ఆమె ప్రస్తుతం చేస్తున్న ఉద్యోగాన్ని మానివేసి ఇంటికి దగ్గ్రరలో ఉన్న ఒక చిన్న ఫ్యాక్టరీలో ఉద్యోగంలో చేరుతుంది.",
              "or": "ତଥାପି, ସେ ଶୀଘ୍ର ଘର ନିକଟରେ ଥିବା ଏକ ଛୋଟ କାରଖାନାରେ ଚାକିରି ପାଇଲେ\n ଏବଂ ବର୍ତ୍ତମାନର ସ୍ଥିତି ଛାଡିଦେଲେ |",
              "as": "অৱশ্যে, তাই সোনকালেই ঘৰৰ ওচৰৰ এটা সৰু কাৰখানাত চাকৰি এটা বিচাৰি পায় আৰু তাইৰ বৰ্তমানৰ চাকৰি এৰি দিয়ে।",
              "gu": "જો કે, તેને ટૂંક સમયમાં ઘરની નજીકની એક નાના કારખાનામાં નોકરી મળે છે અને તેની વર્તમાન સ્થિતિ છોડી દે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_1_2b",
              "hi": "D7_1_2b",
              "ka": "D7_1_2b",
              "te": "D7_1_2b",
              "or": "D7_1_2b",
              "as": "D7_1_2b",
              "gu": "D7_1_2b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Her pay is lesser than the previous job, but since she has the experience of working in a big manufacturing company, she gets the role of a manager in her new place.",
              "hi": "उसका वेतन पिछली नौकरी से कम है, लेकिन चूंकि उसे एक बड़ी विनिर्माण कंपनी में काम करने का अनुभव है, इसलिए उसे अपनी नई जगह में एक प्रबंधक की भूमिका मिलती है।",
              "ka": "ಹಿಂದಿನ ಕೆಲಸಕ್ಕಿಂತ ಅವಳ ಸಂಬಳ ಕಡಿಮೆ, ಆದರೆ ಅವಳು ದೊಡ್ಡ ಉತ್ಪಾದನಾ ಕಂಪನಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಿದ ಅನುಭವವನ್ನು ಹೊಂದಿದ್ದರಿಂದ, ಅವಳು ತನ್ನ ಹೊಸ ಸ್ಥಳದಲ್ಲಿ ಮ್ಯಾನೇಜರ್ ಕರ್ತವ್ಯವನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
              "te": "ఆమె జీతం గతంలో కంటే కంటే తక్కువ, కానీ ఆమెకు పెద్ద కంపెనీలో పనిచేసిన అనుభవం ఉండటం వలన కొత్త కంపెనీలో మేనేజర్ జాబ్ ని సాధిస్తుంది.",
              "or": "ତା’ର ଦରମା ପୂର୍ବ ଚାକିରି ଅପେକ୍ଷା କମ୍, କିନ୍ତୁ ଏକ ବଡ ଉତ୍ପାଦନକାରୀ କମ୍ପାନୀରେ \n କାମ କରିବାର ଅଭିଜ୍ has ତା ଥିବାରୁ ସେ ତାଙ୍କ ନୂତନ ସ୍ଥାନରେ ମ୍ୟାନେଜର ଭୂମିକା ଗ୍ରହଣ କରନ୍ତି |",
              "as": "তাইৰ দৰমহা আগৰ চাকৰিতকৈ কম, কিন্তু যিহেতু তাইৰ এটা ডাঙৰ কোম্পানীত কাম কৰি আহিছে, তাই তাইৰ নতুন স্থানত মেনেজাৰৰ পদটো লাভ কৰে।",
              "gu": "તેનો પગાર અગાઉની નોકરી કરતા ઓછો છે, પરંતુ તેને મોટી ઉત્પાદન કંપનીમાં કામ કરવાનો અનુભવ હોવાથી તેને તેની નવી જગ્યાએ મેનેજરની ભૂમિકા મળે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_2_2",
              "hi": "D7_2_2",
              "ka": "D7_2_2",
              "te": "D7_2_2",
              "or": "D7_2_2",
              "as": "D7_2_2",
              "gu": "D7_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira proves to be a good manager due to her organisational skills. She makes very detailed project plans which help her team work efficiently and meet deadlines.",
              "hi": "मीरा अपने संगठनात्मक कौशल के कारण एक अच्छी प्रबंधक साबित होती है। वह बहुत विस्तृत प्रोजेक्ट योजना बनाती है जो उसकी टीम को कुशलतापूर्वक और समय सीमा में काम करने में सहायक होती है।",
              "ka": "ಮೀರಾ ತನ್ನ ಯೋಜನಾ ಕೌಶಲ್ಯದಿಂದಾಗಿ ಉತ್ತಮ ಮ್ಯಾನೇಜರ್ ಎಂದು ಸಾಬೀತುಪಡಿಸುತ್ತಾಳೆ. ಅವಳು ತನ್ನ ತಂಡವು ಪರಿಣಾಮಕಾರಿಯಾಗಿ ಕೆಲಸ ಮಾಡಲು ಮತ್ತು ಗಡುವನ್ನು ಪೂರೈಸಲು ಸಹಾಯ ಮಾಡುವ ಅತ್ಯಂತ ವಿವರವಾದ ಯೋಜನೆಗಳನ್ನು ತಯಾರಿಸುತ್ತಾಳೆ.",
              "te": "మీరా తన నైపుణ్యాల వలన మంచి మేనేజర్ అని పేరు తెచ్చుకుంటుంది. ఆమె చాలా ప్రాజెక్ట్ ప్లాన్‌ లను బాగా చేస్తుంది, ఇది ఆమె టీం బాగా పని చేయడానికి మరియు పూర్తి చేయడానికి సహాయపడుతుంది.",
              "or": "ମୀରା ତାଙ୍କର ସାଂଗଠନିକ ଦକ୍ଷତା ହେତୁ ଜଣେ ଭଲ ପରିଚାଳକ ବୋଲି ପ୍ରମାଣ କରନ୍ତି |\n  ସେ ବହୁତ ବିସ୍ତୃତ ପ୍ରୋଜେକ୍ଟ ଯୋଜନା ପ୍ରସ୍ତୁତ କରନ୍ତି ଯାହା ତାଙ୍କ ଦଳକୁ ଦକ୍ଷତାର ସହିତ କାର୍ଯ୍ୟ କରିବାରେ ଏବଂ ସମୟସୀମା ପୂରଣ କରିବାରେ ସାହାଯ୍ୟ କରେ |",
              "as": "মীৰা তাইৰ ভাল গুনৰ কাৰনে এগৰাকী ভাল মেনেজাৰ বনিব পাৰে। তাই বহুত ধৰনৰ প্ৰকল্প পৰিকল্পনা কৰে যিয়ে তাইৰ দলটোক ভালকৈ কাম কৰাত আৰু সময় মতেৰে শেষ কৰাত সহায় কৰে।",
              "gu": "મીરા તેના યોજનાત્મક કૌશલ્યના કારણે એક સારી મેનેજર સાબિત થાય છે. તેને ખૂબ વિગતવાર પ્રોજેક્ટ યોજનાઓ બનાવે છે જે તેની ટીમને કાર્યક્ષમ રીતે કાર્ય કરવામાં અને સમયમર્યાદા પૂરી કરવામાં મદદ કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_2_3",
              "hi": "D7_2_3",
              "ka": "D7_2_3",
              "te": "D7_2_3",
              "or": "D7_2_3",
              "as": "D7_2_3",
              "gu": "D7_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is happy with her progress and that she has made it so far.",
              "hi": "मीरा अपनी प्रगति से खुश है जो उसने अपने बल पर हासिल की है।",
              "ka": "ಮೀರಾ ತನ್ನ ಪ್ರಗತಿಯಿಂದ ಹಾಗೂ ಅವಳು ಇಲ್ಲಿಯವರೆಗೆ ಸಾಧಿಸಿರುವುದನ್ನು ಕಂಡು ಸಂತೋಷಗೊಂಡಿದ್ದಾಳೆ.",
              "te": "మీరా తన ఎదుగుదలకి మరియు తాను ఇప్పటివరకు సాధించినవాటికి సంతోషంగా ఉంది.",
              "or": "ମୀରା ତାଙ୍କ ପ୍ରଗତିରେ ଖୁସି ଏବଂ ସେ ଏହାକୁ ଏପର୍ଯ୍ୟନ୍ତ କରିପାରିଛନ୍ତି |",
              "as": "মীৰা তাইৰ কামত সুখী যে তাই ইমান খিনিলৈ আগবাঢ়িব পাৰিছে।",
              "gu": "મીરા તેની પ્રગતિથી ખુશ છે અને તેણે અત્યાર સુધી આ સફળતા મેળવી છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "Production Manager",
              "hi": "उत्पादन प्रबंधक",
              "ka": "ಪ್ರೊಡಕ್ಷನ್ ಮ್ಯಾನೇಜರ್",
              "te": "ప్రొడక్షన్ మేనేజర్",
              "or": "ଉତ୍ପାଦନ ପରିଚାଳକ",
              "as": "প্ৰডাকচন মেনেজাৰ",
              "gu": "ઉત્પાદક સંચાલક"
            }
          }
        ]
      },
      "scene22": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Take up Computer Science",
              "hi": "कंप्यूटर विज्ञान ले ले ",
              "ka": "ಕಂಪ್ಯೂಟರ್ ಸೈನ್ಸ್ ತೆಗೆದುಕೋ",
              "te": "కంప్యూటర్ సైన్స్ తీసుకోండి",
              "or": "କମ୍ପ୍ୟୁଟର ସାଇନ୍ସ ନିଅ |",
              "as": "কম্পিউটাৰ বিজ্ঞান গ্লওঁক",
              "gu": "કમ્પ્યુટર સાયન્સ રાખવું"
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "trends above_C1_1_1",
              "hi": "ट्रेंड्स के ऊपर _C1_1_1",
              "ka": "trends above_C1_1_1",
              "te": "పైన ట్రెండ్‌లు_C1_1_1",
              "or": "trends above_C1_1_1",
              "as": "ট্ৰেণ্ড above_C1_1_1",
              "gu": "ઉપરના વલણો_C1_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira takes up computer science as she feels it is the most popular stream in engineering. Her mother is happy with her decision.",
              "hi": "मीरा कंप्यूटर विज्ञान लेती है क्योंकि उसे लगता है कि यह इंजीनियरिंग में सबसे लोकप्रिय धारा है। उसकी माँ उसके निर्णय से खुश है।",
              "ka": "ಮೀರಾ  ಇಂಜಿನಿಯರಿಂಗ್‌ನಲ್ಲಿ ಅತ್ಯಂತ ಜನಪ್ರಿಯ ಸ್ಟ್ರೀಮ್ ಎಂದು ಭಾವಿಸಿ ರುವ ಕಂಪ್ಯೂಟರ್ ಸೈನ್ಸ್ ಅನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾಳೆ. ಆಕೆಯ ನಿರ್ಧಾರದಿಂದ ತಾಯಿ ಸಂತಸಗೊಂಡಿದ್ದಾರೆ.",
              "te": "మీరా ఇంజినీరింగ్‌లో కంప్యూటర్ సైన్స్ ని మంచి పేరు ఉన్న స్ట్రీమ్‌గా భావించి తీసుకుంటుంది. ఆమె నిర్ణయం పట్ల ఆమె తల్లి సంతోషంగా ఉంది.",
              "or": "ମୀରା ଇଞ୍ଜିନିୟରିଂର ସବୁଠାରୁ ଲୋକପ୍ରିୟ ଷ୍ଟ୍ରିମ୍ ବୋଲି ଅନୁଭବ କରୁଥିବାରୁ କମ୍ପ୍ୟୁଟର\n  ବିଜ୍ଞାନ ଗ୍ରହଣ କରେ | ତାଙ୍କ ନିଷ୍ପତ୍ତିରେ ତାଙ୍କ ମା ଖୁସି ଅଛନ୍ତି।",
              "as": "মীৰাই কম্পিউটাৰ বিজ্ঞান পঢ়িব লয় কিয়নো তাই ভাবে যে এইটো অভিযান্ত্ৰিকৰ আটাইতকৈ জনপ্ৰিয় শাখা। তাইৰ মাক তাইৰ সিদ্ধান্তত সুখী।",
              "gu": "મીરા કમ્પ્યુટર સાયન્સ લે છે કારણ કે તેને લાગે છે કે તે એન્જિનિયરિંગમાં સૌથી લોકપ્રિય પ્રવાહ છે. તેની માતા તેના નિર્ણયથી ખુશ છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_1_2",
              "hi": "C1_1_2",
              "ka": "C1_1_2",
              "te": "C1_1_2",
              "or": "C1_1_2",
              "as": "C1_1_2",
              "gu": "C1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Although she has to take a loan to fund her education, she is sure that she will get a well-paying job in a MultiNational Company.",
              "hi": "यद्यपि उसे अपनी शिक्षा का खर्च उठाने के लिए ऋण लेना पड़ता है, लेकिन उसे विश्वास है कि उसे एक बहुराष्ट्रीय कंपनी में एक अच्छा वेतन देने वाली नौकरी मिल जाएगी।",
              "ka": "ತನ್ನ ವಿದ್ಯಾಭ್ಯಾಸಕ್ಕಾಗಿ ಸಾಲ ಮಾಡಬೇಕಾಗಿ ಬಂದರೂ ಕೂಡಾ ಬಹುರಾಷ್ಟ್ರೀಯ ಕಂಪನಿಯಲ್ಲಿ ಉತ್ತಮ ಸಂಬಳದ ಕೆಲಸ ಸಿಗುವುದು ಖಚಿತವೆಂದು ಅವಳಿಗೆ ತಿಳಿದಿದೆ..",
              "te": "తన చదువు కోసం అప్పు తీసుకోవాల్సి వచ్చినప్పటికీ, మీరా కి భవిష్యత్తులో మల్టీనేషనల్ కంపెనీలో మంచి జీతంతో ఉద్యోగం వస్తుందని ఆమె తల్లి నమ్ముతోంది.",
              "or": "ଯଦିଓ ତାଙ୍କ ଶିକ୍ଷାକୁ ପାଣ୍ଠି ଯୋଗାଇବା ପାଇଁ ତାଙ୍କୁ ରୁଣ ନେବାକୁ ପଡିବ, ସେ ନିଶ୍ଚିତ \n ଯେ ସେ ମଲ୍ଟି ନ୍ୟାସନାଲ୍ କମ୍ପାନୀରେ ଏକ ଭଲ ବେତନ ପ୍ରାପ୍ତ କରିବେ |",
              "as": "যদিও তাই তাইৰ শিক্ষাৰ কাৰনে ঋণ ল'ব লাগিব, তাই জানে যে তাই মাল্টিনেচনেল কোম্পানীএটাত ভাল দৰমহাৰ চাকৰি পাব।",
              "gu": "જો કે તે તેના શિક્ષણ માટે લોન લેવી પડશે, તેને ખાતરી છે કે તેને બહુરાષ્ટ્રીય કંપનીમાં સારા પગારવાળી નોકરી મળશે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_1_3",
              "hi": "C1_1_3",
              "ka": "C1_1_3",
              "te": "C1_1_3",
              "or": "C1_1_3",
              "as": "C1_1_3",
              "gu": "C1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Her curriculum involves the study of computer programming and mathematics. She loves the Mathematics lectures the most.",
              "hi": "उसके पाठ्यक्रम में कंप्यूटर प्रोग्रामिंग और गणित का अध्ययन शामिल है। वह गणित के लेक्चर्स को सबसे ज्यादा पसंद करती है।",
              "ka": "ಆಕೆಯ ಪಠ್ಯಕ್ರಮವು ಕಂಪ್ಯೂಟರ್ ಪ್ರೋಗ್ರಾಮಿಂಗ್ ಮತ್ತು ಗಣಿತಶಾಸ್ತ್ರದ ಅಧ್ಯಯನವನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ. ಅವಳು ಗಣಿತ ಉಪನ್ಯಾಸಗಳನ್ನು ಹೆಚ್ಚು ಇಷ್ಟಪಡುತ್ತಾಳೆ.",
              "te": "ఆమె డిపార్ట్ మెంట్ లో కంప్యూటర్ ప్రోగ్రామింగ్ మరియు మ్యాథమెటిక్స్ నేర్చుకోవడం ఉంటుంది. ఆమెకు గణిత ఉపన్యాసాలు అంటే చాలా ఇష్టం.",
              "or": "ତାଙ୍କ ପାଠ୍ୟକ୍ରମରେ କମ୍ପ୍ୟୁଟର ପ୍ରୋଗ୍ରାମିଂ ଏବଂ ଗଣିତର ଅଧ୍ୟୟନ ଅନ୍ତର୍ଭୁକ୍ତ | ସେ\n  ଗଣିତ ବକ୍ତବ୍ୟକୁ ଅଧିକ ଭଲ ପାଆନ୍ତି |",
              "as": "তাইৰ পাঠ্যক্ৰমত কম্পিউটাৰ প্ৰগ্ৰামিং আৰু গণিতৰ অধ্যয়ন আছে। তাই গণিতৰ পাঠদানবোৰ আটাইতকৈ বেছি ভাল পায়।",
              "gu": "તેના અભ્યાસક્રમમાં કમ્પ્યુટર પ્રોગ્રામિંગ અને ગણિતનો અભ્યાસ સામેલ છે. તેને ગણિતના લેક્ચર સૌથી વધુ ગમે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_1",
              "hi": "C1_2_1",
              "ka": "C1_2_1",
              "te": "C1_2_1",
              "or": "C1_2_1",
              "as": "C1_2_1",
              "gu": "C1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Since Mira doesn’t have a laptop of her own, she has to spend a lot of time in the computer lab when she wants to practice. Because of this, she can’t spend much time with her friends in the hostel.",
              "hi": "चूंकि मीरा के पास अपना लैपटॉप नहीं है, इसलिए जब वह अभ्यास करना चाहती है तो उसे कंप्यूटर प्रयोगशाला में बहुत समय बिताना पड़ता है। इस वजह से, वह छात्रावास में अपने मित्रों के साथ ज्यादा समय नहीं बिता सकती है।",
              "ka": "ಮೀರಾ ಬಳಿ ಸ್ವಂತ ಲ್ಯಾಪ್‌ಟಾಪ್ ಇಲ್ಲದ ಕಾರಣ, ಅಭ್ಯಾಸ ಮಾಡಲು ಬಯಸಿದಾಗ ಕಂಪ್ಯೂಟರ್ ಲ್ಯಾಬ್‌ನಲ್ಲಿ ಸಾಕಷ್ಟು ಸಮಯ ಕಳೆಯಬೇಕಾಗುತ್ತದೆ. ಇದರಿಂದಾಗಿ ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿ ತನ್ನ ಸ್ನೇಹಿತರೊಂದಿಗೆ ಹೆಚ್ಚು ಸಮಯ ಕಳೆಯಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ.",
              "te": "మీరాకి సొంత ల్యాప్‌టాప్ లేకపోవడం వలన ఆమె ప్రాక్టీస్ చేయాలనుకున్నప్పుడు కంప్యూటర్ ల్యాబ్‌లో ఎక్కువ సమయం ఉండాల్సి వస్తుంది. దీంతో హాస్టల్‌లో తన స్నేహితులతో ఎక్కువ సమయం గడపలేకపోతోంది.",
              "or": "ଯେହେତୁ ମୀରାଙ୍କର ନିଜର ଲାପଟପ୍ ନାହିଁ, ସେ ଅଭ୍ୟାସ କରିବାକୁ ଚାହୁଁଥିବାବେଳେ ତାଙ୍କୁ କମ୍ପ୍ୟୁଟର ଲ୍ୟାବରେ ବହୁତ ସମୟ ବିତାଇବାକୁ ପଡିବ | ଏହି କାରଣରୁ, ସେ ହଷ୍ଟେଲରେ ଥିବା ସାଙ୍ଗମାନଙ୍କ ସହିତ ଅଧିକ ସମୟ ବିତାଇ ପାରିବେ ନାହିଁ |",
              "as": "যিহেতু মীৰাৰ নিজৰ লেপটপ নাই, তাই শিকিব বিচাৰিলে কম্পিউটাৰ লেবত যথেষ্ট সময় থাকিব লাগিব। সেইকাৰনে, তাই হোষ্টেলত তাইৰ বন্ধুৰ লগত বেছি সময় কটাব নোৱাৰে।",
              "gu": "મીરા પાસે પોતાનું લેપટોપ ન હોવાથી, જ્યારે તે પ્રેક્ટિસ કરવા માંગે છે ત્યારે તેણે કમ્પ્યુટર લેબમાં ઘણો સમય પસાર કરવો પડે છે. આ કારણે તે છાત્રાલયમાં તેના મિત્રો સાથે વધુ સમય વિતાવી શકતી નથી."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_3///",
              "hi": "C1_2_3///",
              "ka": "C1_2_3///",
              "te": "C1_2_3///",
              "or": "C1_2_3///",
              "as": "C1_2_3///",
              "gu": "C1_2_3///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "One weekend while working late at night in the computer lab, she gets a call from Shantanu.",
              "hi": "कंप्यूटर प्रयोगशाला में रात में देर तक काम करते समय एक सप्ताह के अंत में, उसे शांतनु से एक कॉल आती है।",
              "ka": "ಒಂದು ವಾರಾಂತ್ಯದಲ್ಲಿ ಕಂಪ್ಯೂಟರ್ ಲ್ಯಾಬ್‌ನಲ್ಲಿ ತಡರಾತ್ರಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿರುವಾಗ, ಶಂತನುವಿನಿಂದ ಅವಳಿಗೆ ಕರೆ ಬರುತ್ತದೆ.",
              "te": "ఆమె ఒక రోజు కంప్యూటర్ ల్యాబ్‌లో అర్థరాత్రి పని చేస్తున్నప్పుడు షాంతాను నుండి కాల్ వచ్చింది.",
              "or": "ଗୋଟିଏ ସପ୍ତାହ ଶେଷରେ କମ୍ପ୍ୟୁଟର ଲ୍ୟାବରେ ବିଳମ୍ବିତ ରାତିରେ କାମ କରୁଥିବାବେଳେ ସେ ଶାନ୍ତନୁଙ୍କଠାରୁ ଏକ କଲ ପାଇଥାନ୍ତି |",
              "as": "এটা সপ্তাহান্তত কম্পিউটাৰ লেবত ৰাতি পলমকৈ কাম কৰোঁতে, তাই শান্তনুৰ পৰা ফোন পালে",
              "gu": "એક સપ્તાહના અંતે કોમ્પ્યુટર લેબમાં મોડી રાત્રે કામ કરતી વખતે તેને શાંતનુનો ફોન આવ્યો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Did you know I came second in the gaming tournament held recently!",
              "hi": "क्या आप जानती थीं कि मैं हाल ही में हुए गेमिंग टूर्नामेंट में दूसरे नंबर पर आया था!",
              "ka": "ಇತ್ತೀಚೆಗೆ ನಡೆದ ಗೇಮಿಂಗ್ ಟೂರ್ನಮೆಂಟ್‌ನಲ್ಲಿ ನಾನು ಎರಡನೇ ಸ್ಥಾನ ಪಡೆದಿದ್ದೇನೆ ಎಂದು ನಿಮಗೆ ತಿಳಿದಿದೆಯೇ!",
              "te": "ఈ మధ్యనే జరిగిన గేమింగ్ టోర్నమెంట్‌లో నాకు రెండో స్థానం వచ్చిందని నీకు తెలుసా!",
              "or": "ଆପଣ ଜାଣିଛନ୍ତି କି ମୁଁ ନିକଟରେ ଅନୁଷ୍ଠିତ ଖେଳ ଟୁର୍ନାମେଣ୍ଟରେ ଦ୍ୱିତୀୟରେ ଆସିଛି!",
              "as": "তুমি জানানে যে মই কেইদিন আগত পতা গেমিং টুৰ্ণামেণ্টত দ্বিতীয় স্থান লাভ কৰিছো!",
              "gu": "શું તું જાણો છે  કે તાજેતરમાં યોજાયેલી ગેમિંગ ટુર્નામેન્ટમાં હું બીજા ક્રમે આવ્યો હતો!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "No. But I do know that you came second from the bottom in your semester exams.\nPlease stop gaming and focus on your studies.",
              "hi": "नहीं। लेकिन मुझे पता है कि तुम अपनी सेमेस्टर परीक्षाओं में नीचे से दूसरे स्थान पर आए थे।\nकृपया गेमिंग को रोको और अपनी पढ़ाई पर ध्यान केंद्रित करो।",
              "ka": "ಇಲ್ಲ. ಆದರೆ ನಿಮ್ಮ ಸೆಮಿಸ್ಟರ್ ಪರೀಕ್ಷೆಗಳಲ್ಲಿ ನೀನು ಕೆಳಗಿನಿಂದ ಎರಡನೇ ಸ್ಥಾನ ಪಡೆದಿದ್ದೀ ಅಂತ ನಂಗೆ ಗೊತ್ತು.                                                           ದಯವಿಟ್ಟು ಗೇಮಿಂಗ್ ಅನ್ನು ನಿಲ್ಲಿಸು ಮತ್ತು ನಿನ್ನ ಪಾಠದ ಕಡೆ ಗಮನ ಕೊಡು.",
              "te": "లేదు. కానీ నీకు సెమిస్టర్ పరీక్షల్లో చివరి నుండి రెండో ర్యాంకు వచ్చిందని తెలుసు. \n దయచేసి గేమింగ్‌ని ఆపి నీ చదువుపై దృష్టి పెట్టు.",
              "or": "ନା। କିନ୍ତୁ ମୁଁ ଜାଣେ ଯେ ତୁମେ ତୁମର ସେମିଷ୍ଟାର ପରୀକ୍ଷାରେ ତଳୁ ଦ୍ୱିତୀୟରୁ ଆସିଛ |\n \n ଦୟାକରି ଖେଳ ବନ୍ଦ କରନ୍ତୁ ଏବଂ ଆପଣଙ୍କର ଅଧ୍ୟୟନ ଉପରେ ଧ୍ୟାନ ଦିଅନ୍ତୁ |",
              "as": "নাজানো। কিন্তু মই জানো যে তুমি তোমাৰ ছেমিষ্টাৰ পৰীক্ষাত তলৰ পৰা দ্বিতীয় স্থানত আছা। অলপ গেমিং বন্ধ কৰা আৰু তোমাৰ পঢ়া শুনাত মন দিয়া।",
              "gu": "ના. પણ હું જાણું છું કે તું તારી સેમેસ્ટરની પરીક્ષામાં નીચેથી બીજા નંબરે આવ્યા છે. કૃપા કરીને ગેમ રમવાનું બંધ કર અને તારા અભ્યાસ પર ધ્યાન કેન્દ્રિત કર."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "You need to relax a little, didi! Learn from me. I enjoy everything I do.",
              "hi": "आपको थोड़ा आराम करने की ज़रूरत है, दीदी! मुझसे सीखो। मैं जो कुछ भी करता हूं उसका आनंद लेता हूं।",
              "ka": "ನೀವು ಸ್ವಲ್ಪ ವಿಶ್ರಾಂತಿ ತಗೋಬೇಕು, ದೀದಿ! ನನ್ನಿಂದ ಕಲಿಯಿರಿ. ನಾನು ಏನು ಮಾಡ್ತೀನೋ, ಅದನ್ನು ನಾನು ಆನಂದಿಸುತ್ತೇನೆ",
              "te": "నువ్వు కొంచెం విశ్రాంతి తీసుకోవాలి, దీదీ! నన్ను చూసి నేర్చుకో. నేను చేసే ప్రతి పనిని ఎంజాయ్ చేస్తాను.",
              "or": "ତୁମେ ଟିକିଏ ଆରାମ କରିବାକୁ ପଡିବ, ଦିଦି! ମୋ ଠାରୁ ଶିଖ | ମୁଁ କରୁଥିବା ସମସ୍ତ\n  କାର୍ଯ୍ୟକୁ ଉପଭୋଗ କରେ |",
              "as": "তুমি অলপকে চিন্তা কৰা, বাইদেউ, মোৰ পৰা শিকা। মই যিয়ে কৰো ফূৰ্টিত কৰোঁ।",
              "gu": "તારે થોડો શાંતિ રાખવાની જરૂર છે, દીદી! મારી પાસેથી શીખ. હું જે કંઈ કરું છું તેનો મને આનંદ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "I’ve taken a big loan to study in this college, so I can’t afford to enjoy it. If I wanted to enjoy it, I would have taken another department.",
              "hi": "मैंने इस कॉलेज में अध्ययन करने के लिए एक बड़ा ऋण लिया है, इसलिए मैं इसका आनंद लेने का जोखिम नहीं उठा सकती । अगर मैं इसका आनंद लेना चाहती, तो मैंने कोई और विभाग लिया होता।",
              "ka": "ನಾನು ಈ ಕಾಲೇಜಿನಲ್ಲಿ ಓದಲು ದೊಡ್ಡ ಸಾಲವನ್ನು ತೆಗೆದುಕೊಂಡಿದ್ದೇನೆ, ಹಾಗಾಗಿ ಅದನ್ನು ಆನಂದಿಸಲು ನನಗೆ ಸಾಧ್ಯವಿಲ್ಲ. ಎಂಜಾಯ್ ಮಾಡಬೇಕೆಂದಿದ್ದರೆ ಬೇರೆ ಡಿಪಾರ್ಟ್ ಮೆಂಟ್ ತೆಗೆದುಕೊಳ್ತಾ ಇದ್ದೆ,",
              "te": "నేను ఈ కళాశాలలో చదవడానికి చాలా అప్పు చేశాను. కాబట్టి నేను దానిని ఎంజాయ్ చేయలేను. ఎంజాయ్ చేయాలనుకుంటే వేరే డిపార్ట్ మెంట్ లో చేరేదాన్ని.",
              "or": "ମୁଁ ଏହି କଲେଜରେ ପଢିବା ପାଇଁ ଏକ ବଡ ରୁଣ ନେଇଛି, ତେଣୁ ମୁଁ ଏହାକୁ\n  ଉପଭୋଗ କରିବାକୁ ସକ୍ଷମ ନୁହେଁ | ଯଦି ମୁଁ ଏହାକୁ ଉପଭୋଗ କରିବାକୁ ଚାହେଁ, ତେବେ ମୁଁ ଅନ୍ୟ ବିଭାଗ ନେଇଥାନ୍ତେ |",
              "as": "মই এই কলেজত পঢ়িবলৈ এটা ডাঙৰ ঋণ লৈছো, গতিকে মই ইয়াক ফূৰ্তি কৰিব নোৱাৰো। যদি মই ইযয়াত ফূৰ্তি কৰিব বিচাৰিলোঁ হয়, মই আন এটা বিভাগ ল'লোহেঁতেন।",
              "gu": "મેં આ કૉલેજમાં ભણવા માટે મોટી લોન લીધી છે, તેથી હું તેનો આનંદ ઉઠાવી શકું તેમ નથી. જો મારે તેનો આનંદ માણવો હોય, તો મેં બીજો વિભાગ લીધો હોત."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_4",
              "hi": "C1_2_4",
              "ka": "C1_2_4",
              "te": "C1_2_4",
              "or": "C1_2_4",
              "as": "C1_2_4",
              "gu": "C1_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shantanu is upset that Mira did not appreciate his achievement. Mira realises her mistake and messages him later to apologise.",
              "hi": "शांतनु दुखी है कि मीरा ने उसकी उपलब्धि की सराहना नहीं की। मीरा अपनी गलती महसूस करती है और बाद में माफी मांगने के लिए उसे संदेश भेजती है।",
              "ka": "ಮೀರಾ ತನ್ನ ಸಾಧನೆ ಮೆಚ್ಚಲಿಲ್ಲ ಅಂತ ಶಂತನು ಬೇಸರಗೊಂಡಿದ್ದಾನೆ. ಮೀರಾ ತನ್ನ ತಪ್ಪನ್ನು ಅರಿತು ನಂತರ ಕ್ಷಮೆಯಾಚಿಸಿ ಅವನಿಗೆ ಸಂದೇಶವನ್ನು ಕಲಿಸಿದಳು..",
              "te": "మీరా తన విజయాన్ని మెచ్చుకోలేదని షాంతన్ బాధపడతాడు. మీరా తన తప్పును తెలుసుకొని క్షమాపణ చెబుతూ అతనికి మెసేజ్ పంపింది.",
              "or": "ମୀରା ତାଙ୍କ ସଫଳତାକୁ ପ୍ରଶଂସା କରିନଥିବାରୁ ଶାନ୍ତନୁ ବିରକ୍ତ ହୋଇଛନ୍ତି। ମୀରା\n  ତାଙ୍କ ଭୁଲକୁ ଅନୁଭବ କଲେ ଏବଂ ପରେ ତାଙ୍କୁ କ୍ଷମା ମାଗିବାକୁ ବାର୍ତ୍ତା ଦିଅନ୍ତି |",
              "as": "মীৰাই তাক প্ৰশংসা নকৰাত শান্তনুয়ে মন বেয়া কৰিছে। মীৰাই তাইৰ ভুল অনুভৱ কৰে আৰু পিছত বেয়া নাপাবলৈ মেছেজ দিয়ে।",
              "gu": "શાંતનુ નારાજ છે કે મીરાએ તેની સિદ્ધિની કદર ન કરી. મીરાને તેની ભૂલનો અહેસાસ થાય છે અને બાદમાં તેને માફી માંગવા માટે મેસેજ કરે છે."
            }
          }
        ]
      },
      "scene23": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_1",
              "hi": "C2_1_1",
              "ka": "C2_1_1",
              "te": "C2_1_1",
              "or": "C2_1_1",
              "as": "C2_1_1",
              "gu": "C2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira works hard throughout college. Even though she doesn’t fully enjoy the subjects, she scores decently in them. She reads science books in her spare time, which she has always enjoyed doing.",
              "hi": "मीरा कॉलेज में  पूरे समय कड़ी मेहनत करती है। भले ही वह पूरी तरह से विषयों का आनंद नहीं लेती है, फिर भी वह उनमें ठीक - ठाक स्कोर करती है। वह अपने खाली समय में विज्ञान की किताबें पढ़ती है, जिन्होंने उसे हमेशा आनंदित किया है।",
              "ka": "ಮೀರಾ ಕಾಲೇಜಿನಲ್ಲಿ ಕಷ್ಟಪಟ್ಟು ಕೆಲಸ ಮಾಡ್ತಾಳೆ ಅವಳು ವಿಷಯಗಳನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಆನಂದಿಸದಿದ್ದರೂ ಸಹ, ಅವಳು ಅವುಗಳಲ್ಲಿ ಸಾಕಷ್ಟು ಅಂಕಗಳನ್ನು ಗಳಿಸ್ತಾಳೆ. ಅವಳು ತನ್ನ ಬಿಡುವಿನ ವೇಳೆಯಲ್ಲಿ ವಿಜ್ಞಾನ ಪುಸ್ತಕಗಳನ್ನು ಓದುತ್ತಾಳೆ, ಹಾಗೆ  ಮಾಡುವುದನ್ನು ಅವಳು ಯಾವಾಗಲೂ ಆನಂದಿಸ್ತಾಳೆ.",
              "te": "మీరా కాలేజీలో రోజంతా కష్టపడి పని చేస్తుంది. ఆమె సబ్జెక్ట్ లను ఎక్కువగా ఇష్టపడక పోయినప్పటకి, ఆమె వాటిలో మంచి స్కోర్ తెచ్చుకుంటుంది. ఆమె తన ఖాళీ సమయంలో సైన్స్ పుస్తకాలను చదువుతుంది మరియు దీనిని ఆమె ఎప్పుడూ ఆనందిస్తుంది.",
              "or": "ମୀରା କଲେଜରେ କଠିନ ପରିଶ୍ରମ କରେ | ଯଦିଓ ସେ ବିଷୟଗୁଡିକୁ ସମ୍ପୂର୍ଣ୍ଣରୂପେ\n  ଉପଭୋଗ କରନ୍ତି ନାହିଁ, ସେ ସେଗୁଡ଼ିକରେ ଭଲ ଭାବରେ ସ୍କୋର କରନ୍ତି | ସେ ଖାଲି ସମୟରେ ବିଜ୍ଞାନ ପୁସ୍ତକ ପଢନ୍ତି, ଯାହା ସେ କରିବାକୁ ସର୍ବଦା ଉପଭୋଗ କରନ୍ତି |",
              "as": "মীৰাই গোটেই কলেজৰ দিন কেইটাত কষ্ট কৰে। যদিও তাই বিষয়বোৰ সম্পূৰ্ণৰূপে ভাল নাপায়, তাই সেইবোৰত ভালনম্বৰ পাই। তাই তাইৰ খালী সময়ত বিজ্ঞানৰ কিতাপ পঢ়ে, যিবোৰ তাই সদায় কৰি ভাল পাই।",
              "gu": "મીરા સમગ્ર કોલેજમાં સખત મહેનત કરે છે. તેમ છતાં તેને વિષયોનો સંપૂર્ણ આનંદ નથી, તે તેમાં યોગ્ય રીતે ગુણ મેળવે છે. તે તેના ફ્રી સમયમાં વિજ્ઞાનના પુસ્તકો વાંચે છે, જે તેને એ કરવામાં હંમેશા આનંદ આવે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_2_1",
              "hi": "C2_2_1",
              "ka": "C2_2_1",
              "te": "C2_2_1",
              "or": "C2_2_1",
              "as": "C2_2_1",
              "gu": "C2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "In her college placements, she gets a job at KCS. It is a huge company that offers IT services to Indian and International companies.",
              "hi": "अपने कॉलेज प्लेसमेंट में, उसे के सी एस में नौकरी मिलती है। यह एक बड़ी कंपनी है जो भारतीय और अंतरराष्ट्रीय कंपनियों को आईटी सेवाएं प्रदान करती है।",
              "ka": "ಅವಳ ಕಾಲೇಜು ನಿಯೋಜನೆಗಳಲ್ಲಿ, ಅವಳು KCS ನಲ್ಲಿ ಕೆಲಸ ಪಡೆಯುತ್ತಾಳೆ. ಇದು ಭಾರತೀಯ ಮತ್ತು ಅಂತರಾಷ್ಟ್ರೀಯ ಕಂಪನಿಗಳಿಗೆ ಐಟಿ ಸೇವೆಗಳನ್ನು ಒದಗಿಸುವ ಬೃಹತ್ ಕಂಪನಿಯಾಗಿದೆ",
              "te": "కాలేజ్ ప్లేస్ మెంట్స్ లో ఆమెకి KCSలో ఉద్యోగం వస్తుంది. ఇది భారతీయ మరియు అంతర్జాతీయ కంపెనీలకు IT సేవలను అందించే పెద్ద కంపెనీ.",
              "or": "ତାଙ୍କ କଲେଜ ପ୍ଲେସମେଣ୍ଟରେ, ସେ KCS ରେ ଚାକିରି ପାଆନ୍ତି | ଏହା ଏକ ବିଶାଳ କମ୍ପାନୀ ଯାହା ଭାରତୀୟ ଏବଂ ଆନ୍ତର୍ଜାତୀୟ କମ୍ପାନୀଗୁଡିକୁ ଆଇଟି ସେବା ପ୍ରଦାନ କରେ |",
              "as": "তাইৰ কলেজৰ প্লেচমেণ্টত, তাই কেচিএছত চাকৰি পায়। এইটো এটা বৃহৎ কোম্পানী যিয়ে ভাৰতীয় আৰু আন্তঃৰাষ্ট্ৰীয় কোম্পানীবোৰক সেৱা আগবঢ়ায়।",
              "gu": "તેના કોલેજ પ્લેસમેન્ટમાં, તેને KCS ખાતે નોકરી મળે છે. તે એક વિશાળ કંપની છે જે ભારતીય અને આંતરરાષ્ટ્રીય કંપનીઓને IT સેવાઓ પ્રદાન કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_3_1",
              "hi": "C2_3_1",
              "ka": "C2_3_1",
              "te": "C2_3_1",
              "or": "C2_3_1",
              "as": "C2_3_1",
              "gu": "C2_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is earning well at her job. She is able to pay her education loan instalments that will get over in the next two years.",
              "hi": "मीरा अपनी नौकरी में अच्छा वेतन पा रही है। वह अपनी शिक्षा ऋण की किश्तों का भुगतान करने में सक्षम है जो अगले दो वर्षों में खत्म हो जाएगी।",
              "ka": "ಮೀರಾ ತನ್ನ ಕೆಲಸದಲ್ಲಿ ಚೆನ್ನಾಗಿ ಸಂಪಾದನೆ ಮಾಡ್ತಾ ಇದ್ದಾಳೆ. ಮುಂದಿನ ಎರಡು ವರ್ಷಗಳಲ್ಲಿ ಶಿಕ್ಷಣ ಸಾಲದ ಕಂತುಗಳನ್ನು ಪಾವತಿ ಮಾಡಲು ಆಕೆ ಶಕ್ತಳಾಗಿದ್ದಾಳೆ.",
              "te": "మీరా తన ఉద్యోగంలో బాగా సంపాదిస్తోంది. ఆమె చదువు కోసం తీసుకున్న అప్పుని వచ్చే రెండు సంవత్సరాలలో కట్టేయగలదు.",
              "or": "ମୀରା ନିଜ ଚାକିରିରେ ଭଲ ରୋଜଗାର କରୁଛନ୍ତି | ସେ ତାଙ୍କ ଶିକ୍ଷା ରୁଣ କିସ୍ତି ପ୍ରଦାନ\n  କରିବାକୁ ସକ୍ଷମ ଅଟନ୍ତି ଯାହା ଆସନ୍ତା ଦୁଇ ବର୍ଷ ମଧ୍ୟରେ ଶେଷ ହେବ |",
              "as": "মীৰাই তাইৰ চাকৰিত ভাল দৰমহা পায় আছে। তাই তাইৰ শিক্ষা ঋণ লাহে লাহে ঘুৰাই দিব পাৰিছে যি আৰু দুবছৰত শেষ হ'ব।",
              "gu": "મીરા તેની નોકરીમાં સારી કમાણી કરી રહી છે. તે તેના એજ્યુકેશન લોનના હપ્તાઓ ચૂકવવા સક્ષમ છે જે આગામી બે વર્ષમાં પૂરા થશે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_3_2",
              "hi": "C2_3_2",
              "ka": "C2_3_2",
              "te": "C2_3_2",
              "or": "C2_3_2",
              "as": "C2_3_2",
              "gu": "C2_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She is also able to support Shantanu’s education. Her parents and friends are happy for her as she has been doing well.",
              "hi": "वह शांतनु की शिक्षा में सहयोग करने में भी सक्षम है। उसके माता-पिता और मित्र उसके लिए खुश हैं क्योंकि वह अच्छी तरह से काम कर रही है।",
              "ka": "ಶಂತನುವಿನ ವಿದ್ಯಾಭ್ಯಾಸಕ್ಕೂ ಆಕೆ ಬೆಂಬಲ ನೀಡ್ತಾ ಇದ್ದಾಳೆ. ಅವಳು ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಿರುವುದರಿಂದ ಅವಳ ಪೋಷಕರು ಮತ್ತು ಸ್ನೇಹಿತರು ಅವಳ ಬಗ್ಗೆ ಸಂತೋಷ ಹೊಂದಿದ್ದಾರೆ.",
              "te": "ఆమె కూడా షాంతాను చదువుకు సహాయపడగలదు. ఆమె బాగా చేస్తుండటంతో ఆమె తల్లిదండ్రులు మరియు స్నేహితులు సంతోషంగా ఉన్నారు.",
              "or": "ସେ ଶାନ୍ତନୁଙ୍କ ଶିକ୍ଷାକୁ ମଧ୍ୟ ସମର୍ଥନ କରିବାକୁ ସକ୍ଷମ ଅଟନ୍ତି | ସେ ଭଲ କାମ କରୁଥିବାରୁ\n  ତାଙ୍କ ପିତାମାତା ଏବଂ ସାଙ୍ଗମାନେ ତାଙ୍କ ପାଇଁ ଖୁସି ଅଛନ୍ତି |",
              "as": "তাই শান্তনুৰ শিক্ষা খৰচো দিব পাৰিছে। তাইৰ মাক দেউতাক আৰু বন্ধুবৰ্গ তাইৰ কাৰনে সুখী কিয়নো তাই ভাল কৰি আছে।",
              "gu": "તે શાંતનુના શિક્ષણમાં પણ મદદ કરી શકે છે. તેના માતાપિતા અને મિત્રો તેના માટે ખુશ છે કારણ કે તે સારી સફળતા મેળવે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_3_3",
              "hi": "C2_3_3",
              "ka": "C2_3_3",
              "te": "C2_3_3",
              "or": "C2_3_3",
              "as": "C2_3_3",
              "gu": "C2_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira, however, is not too happy with her work. She spends all of her time sitting at her desk. Most of her work involves either going through a lot of code or responding to emails.",
              "hi": "मीरा, हालांकि, अपने काम से बहुत खुश नहीं है। वह पूरा समय अपने डेस्क पर बैठकर बिताती है। उसके अधिकांश कार्य में या तो बहुत सारे कोड या ईमेल का जवाब देना शामिल है।",
              "ka": "ಆದಾಗ್ಯೂ, ಮೀರಾ ತನ್ನ ಕೆಲಸದ ಬಗ್ಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿಲ್ಲ. ಅವಳು ತನ್ನ ಮೇಜಿನ ಬಳಿಯೇ ಕುಳಿತು ತನ್ನ ಸಮಯವನ್ನು ಕಳೆಯಬೇಕಾಗುತ್ತದೆ. ಆಕೆಯ ಹೆಚ್ಚಿನ ಕೆಲಸವು ಬಹಳಷ್ಟು ಕೋಡ್‌ಗಳನ್ನು ಪರಿಶೀಲ್ಸೋದು ಅಥವಾ ಇಮೇಲ್‌ಗಳಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುವುದನ್ನು ಮಾತ್ರವೇ ಒಳಗೊಂಡಿದೆ.",
              "te": "అయితే మీరాకి తన పని అంటే తనకి పెద్దగా ఇష్టం లేదు. ఆమె తన డెస్క్ వద్ద కూర్చుని మొత్తం సమయాన్ని గడుపుతుంది. ఆమె పనిలో ఎక్కువగా కొడ్స్ లేదా ఇమెయిల్‌లకు రెస్పాండ్ అవ్వడం ఉంటుంది.",
              "or": "ମୀରା କିନ୍ତୁ ନିଜ କାମରେ ବହୁତ ଖୁସି ନୁହଁନ୍ତି | ସେ ନିଜ ଡେସ୍କରେ ବସି ସମସ୍ତ\n  ସମୟ ବିତାଇଥାଏ | ତାଙ୍କର ଅଧିକାଂଶ କାର୍ଯ୍ୟ ଅନେକ ସଂକେତ ଦେଇ ଯିବା କିମ୍ବା ଇମେଲଗୁଡିକର ପ୍ରତିକ୍ରିୟା ସହିତ ଜଡିତ |",
              "as": "অৱশ্যে মীৰা তাইৰ কামত বেছি সুখী নহয়। তাই তাইৰ সকলো সময় তাইৰ ডেস্কত বহি কটায়। তাইৰ বেছিভাগ কামত কোডৰ পৰা হয় বা ইমেইলৰ সঁহাৰি দিয়া হয়।",
              "gu": "જોકે મીરા પોતાના કામથી બહુ ખુશ નથી. તે તેનો બધો સમય તેના ડેસ્ક પર બેસીને વિતાવે છે. તેના મોટા ભાગના કાર્યમાં કાં તો ઘણા બધા કોડમાંથી પસાર થવું અથવા ઈમેલનો જવાબ આપવાનો સમાવેશ થાય છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_4_1",
              "hi": "C2_4_1",
              "ka": "C2_4_1",
              "te": "C2_4_1",
              "or": "C2_4_1",
              "as": "C2_4_1",
              "gu": "C2_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shantanu is doing a course in animation from Delhi. In his semester break he comes to visit his sister.",
              "hi": "शांतनु दिल्ली से एनीमेशन में एक कोर्स कर रहा है। अपने सेमेस्टर ब्रेक में वह अपनी बहन से मिलने के लिए आता है।",
              "ka": "ಶಂತನು ದೆಹಲಿಯಲ್ಲಿ ಅನಿಮೇಷನ್ ಕೋರ್ಸ್ ಮಾಡ್ತಿದ್ದಾನೆ. ತನ್ನ ಸೆಮಿಸ್ಟರ್ ವಿರಾಮದಲ್ಲಿ ಅವನು ತನ್ನ ಅಕ್ಕನನ್ನ ಭೇಟಿ ಮಾಡಲು ಬರ್ತಾನೆ.",
              "te": "షాంతాను ఢిల్లీలో యానిమేషన్‌ కోర్సు చేస్తున్నాడు. తన సెమిస్టర్ సెలవుల్లో సోదరిని చూడటానికి వస్తాడు.",
              "or": "ଶାନ୍ତନୁ ଦିଲ୍ଲୀରୁ ଆନିମେସନ୍ ରେ ଏକ ପାଠ୍ୟକ୍ରମ କରୁଛନ୍ତି | ତାଙ୍କ ସେମିଷ୍ଟାର \n ବିରତିରେ ସେ ନିଜ ଭଉଣୀଙ୍କୁ ଦେଖା କରିବାକୁ ଆସନ୍ତି |",
              "as": "শান্তনুৱে দিল্লীৰ পৰা এনিমেছনৰ এটা পাঠ্যক্ৰম কৰি আছে। ছেমিষ্টাৰৰ বিৰতিত সি তাৰ বায়েকক লগ কৰিবলৈ আহে।",
              "gu": "શાંતનુ દિલ્હીથી એનિમેશનનો કોર્સ કરી રહ્યો છે. તેના સેમેસ્ટર બ્રેકમાં તે તેની બહેનને મળવા આવે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_4_2 bg",
              "hi": "C2_4_2 बीजी",
              "ka": "C2_4_2 bg",
              "te": "C2_4_2 bg",
              "or": "C2_4_2 bg",
              "as": "C2_4_2 bg",
              "gu": "C2_4_2 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shantanu",
              "hi": "शांतनु",
              "ka": "ಶಂತನು",
              "te": "షాంతాను",
              "or": "ଶାନ୍ତନୁ",
              "as": "শান্তনু",
              "gu": "શાંતનુ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Didi, how is work going?",
              "hi": "दीदी, काम कैसा चल रहा है?",
              "ka": "ಅಕ್ಕ, ಕೆಲಸ ಹೇಗೆ ಸಾಗುತ್ತಿದೆ?",
              "te": "దీదీ, పని ఎలా జరుగుతోంది?",
              "or": "ଦିଦି, କାମ କେମିତି ଚାଲିଛି?",
              "as": "বাইদেউ, কাম কেনেকৈ চলি আছে?",
              "gu": "દીદી, કામ કેવું ચાલે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "It’s not so great, Shantanu. Sometimes I wish I had done mechanical engineering instead. How are your studies going?",
              "hi": "यह इतना अच्छा भी नहीं है, शांतनु। कभी-कभी मैं चाहती हूं कि मैं इसके बजाय मैकेनिकल इंजीनियरिंग करती। तुम्हारी पढ़ाई कैसी चल रही है?",
              "ka": "ಇದು ಅಷ್ಟೇನೂ ಹೇಳಿಕೊಳ್ಳುವ ಹಾಗಿಲ್ಲ, ಶಂತನು. ಅದರ ಬದಲು ಮೆಕ್ಯಾನಿಕಲ್ ಇಂಜಿನಿಯರಿಂಗ್ ಮಾಡಿದ್ರೆ ಚೆನ್ನಾಗಿತ್ತು ಅಂತ  ಕೆಲವೊಮ್ಮೆ ಅನ್ಸತ್ತೆ. ನಿನ್ನ ಪಾಠಗಳು ಹೇಗೆ ನಡೆಯುತ್ತಿವೆ?",
              "te": "ఇది అంత గొప్పది కాదు, షాంతాను. కొన్నిసార్లు నేను దీనికి బదులుగా మెకానికల్ ఇంజనీరింగ్ చేసి ఉంటే బాగుండేది అని అనుకుంటున్నాను. నువ్వు ఎలా చదువుతున్నావు?",
              "or": "ଶାନ୍ତନୁ ଏହା ଏତେ ଭଲ ନୁହେଁ | ବେଳେବେଳେ ମୁଁ ଇଚ୍ଛା କରେ ମୁଁ ଏହା ବଦଳରେ \n ଯାନ୍ତ୍ରିକ ଇଞ୍ଜିନିୟରିଂ କରିଥା’ନ୍ତି | ତୁମର ଅଧ୍ୟୟନ କେମିତି ଚାଲିଛି?",
              "as": "শান্তনু, ইমান ভাল নহয়। কেতিয়াবা মই ভাবোঁ তাৰ সলনি মেকানিকেল ইঞ্জিনিয়াৰিং কৰিলোঁহেতেন। তোমাৰ পঢ়া কেনেদৰে চলি আছে?",
              "gu": "તે એટલું સારું નથી, શાંતનુ. ક્યારેક હું ઈચ્છું છું કે મેં મિકેનિકલ એન્જિનિયરિંગ કર્યું હોત. તારો અભ્યાસ કેવો ચાલે છે?"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_4_3///",
              "hi": "C2_4_3///",
              "ka": "C2_4_3///",
              "te": "C2_4_3///",
              "or": "C2_4_3///",
              "as": "C2_4_3///",
              "gu": "C2_4_3///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Very good, didi! Next semester we have a course on Game Design where they will teach all the principles of making a video game! I have already started reading about it.",
              "hi": "बहुत अच्छी, दीदी! अगले सेमेस्टर हमारे पास गेम डिज़ाइन पर एक कोर्स है जहां वे एक वीडियो गेम बनाने के सभी सिद्धांतों को सिखाएंगे! मैंने पहले ही इसके बारे में पढ़ना शुरू कर दिया है।",
              "ka": "ತುಂಬಾ ಚೆನ್ನಾಗಿದೆ, ದೀದಿ! ಮುಂದಿನ ಸೆಮಿಸ್ಟರ್‌ನಲ್ಲಿ ನಾವು ಗೇಮ್ ವಿನ್ಯಾಸದ ಕೋರ್ಸ್ ಅನ್ನು ಹೊಂದಿದ್ದೇವೆ, ಅಲ್ಲಿ ಅವರು ವೀಡಿಯೊ ಗೇಮ್ ಮಾಡುವ ಎಲ್ಲಾ ತತ್ವಗಳನ್ನು ಕಲಿಸ್ತಾರೆ! ನಾನು ಈಗಾಗಲೇ ಅದರ ಬಗ್ಗೆ ಓದಲು ಪ್ರಾರಂಭ ಮಾಡಿದ್ದೇನೆ.",
              "te": "చాలా బాగుంది, దీదీ! తర్వాత సెమిస్టర్‌లో మాకు గేమ్ డిజైన్‌పై ఒక కోర్సు ఉంది, అక్కడ వారు వీడియో గేమ్‌ను చేయడానికి కావల్సిన అన్నీటి గురించి చెబుతారు! నేను ఇప్పటికే దాని గురించి చదవడం ప్రారంభించాను.",
              "or": "ବହୁତ ଭଲ, ଦିଦି! ପରବର୍ତ୍ତୀ ସେମିଷ୍ଟାରରେ ଆମର ଗେମ୍ ଡିଜାଇନ୍ ଉପରେ ଏକ \n ପାଠ୍ୟକ୍ରମ ଅଛି ଯେଉଁଠାରେ ସେମାନେ ଏକ ଭିଡିଓ ଗେମ୍ ତିଆରି କରିବାର ସମସ୍ତ ନୀତି ଶିକ୍ଷା ଦେବେ! ମୁଁ ଏହା ବିଷୟରେ ପଢିବା ଆରମ୍ଭ କରିସାରିଛି |",
              "as": "খুব ভাল, বা! অহা ছেমিষ্টাৰত আমাৰ গেম ডিজাইনৰ ওপৰত এটা কৰ্চ আছে য'ত তেওঁলোকে ভিডিঅ' গেম বনোৱাৰ সকলো কথা শিকাব! মই ইতিমধ্যে ইয়াৰ বিষয়ে পঢ়িবলৈ আৰম্ভ কৰিছো।",
              "gu": "બહુ સારું, દીદી! આગામી સેમેસ્ટરમાં અમારી પાસે ગેમ ડિઝાઇનનો કોર્સ છે જ્યાં તેઓ વીડિયો ગેમ બનાવવાના તમામ સિદ્ધાંતો શીખવશે! મેં તેના વિશે વાંચવાનું શરૂ કર્યું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Really?! I never thought you could also take your studies seriously.",
              "hi": "सच में?! मैंने कभी सोचा नहीं कि तुम अपनी पढ़ाई को गंभीरता से भी ले सकते हो।",
              "ka": "ನಿಜವಾಗಿಯೂ?! ನೀನು ನಿನ್ನ ಅಧ್ಯಯನವನ್ನು ಗಂಭೀರವಾಗಿ ತೆಗೆದುಕೊಳ್ತೀಯಾ ಅಂತ ನಾನು ಯಾವತ್ತೂ ಯೋಚಿಸಿರಲಿಲ್ಲ.",
              "te": "నిజమేనా?! నువ్వు నీ చదువులో ఇంత సీరియస్‌గా ఉంటావని నేను ఎప్పుడూ అనుకోలేదు.",
              "or": "ପ୍ରକୃତରେ ?! ମୁଁ କେବେ ଭାବି ନଥିଲି ତୁମେ ମଧ୍ୟ ତୁମର ଅଧ୍ୟୟନକୁ ଗମ୍ଭୀରତାର ସହିତ ନେଇ ପାରିବ |",
              "as": "সঁচাকৈয়ে?! মই কেতিয়াও ভবা নাছিলো যে তুমি তোমাৰ পঢ়াশুনাত গুৰুত্ব দিয়া।",
              "gu": "ખરેખર?! મેં ક્યારેય વિચાર્યું ન હતું કે તું પણ તારા અભ્યાસને ગંભીરતાથી લઈ શકશો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "It still feels all fun to me. Did you know, they use the C ++ coding language to make games? Didn’t they teach this to you in college?",
              "hi": "यह अभी भी मुझे मजेदार लगता है। क्या आप जानती थी, वे गेम बनाने के लिए सी ++ कोडिंग भाषा का उपयोग करते हैं? क्या उन्होंने आपको कॉलेज में यह नहीं सिखाया?",
              "ka": "ಇದು ನನಗೆ ತುಂಬಾ ಮೋಜಿನ ಸಂಗತಿ ಅನ್ಸತ್ತೆ. ನಿನಗೆ ಗೊತ್ತಾ, ಅವರು ಆಟಗಳನ್ನು ಮಾಡಲು ಸಿ ಕೋಡಿಂಗ್ ಭಾಷೆಯನ್ನು ಬಳಸುತ್ತಾರೆ? ಅವರು ಇದನ್ನು ನಿನಗೆ ಕಾಲೇಜಿನಲ್ಲಿ ಕಲಿಸಲಿಲ್ಲವೇ?",
              "te": "నాకు ఇది ఇప్పటికీ సరదాగా అనిపిస్తుంది. నీకు తెలుసా, వారు గేమ్‌లను రూపొందించడానికి C ++ కోడింగ్ భాషను ఉపయోగిస్తున్నారు? కాలేజీలో వాళ్లు మీకు ఇది నేర్పించలేదా?",
              "or": "ତଥାପି ଏହା ମୋ ପାଇଁ ସବୁ ମଜା ଲାଗୁଛି | ଆପଣ ଜାଣିଛନ୍ତି କି, ସେମାନେ ଖେଳ\n  ତିଆରି କରିବାକୁ C ++ କୋଡିଂ ଭାଷା ବ୍ୟବହାର କରନ୍ତି | ସେମାନେ ଆପଣଙ୍କୁ କଲେଜରେ ଏହା ଶିଖାଇ ନାହାଁନ୍ତି କି?",
              "as": "এইটো মোৰ কাৰনে ফূৰ্তি কথা। তুমি জানানে, তেওঁলোকে গেম তৈয়াৰ কৰিবলৈ চি ++ কোডিং ব্যৱহাৰ কৰে? এইটো কলেজত তোমাক শিকোৱা নাছিল নেকি?",
              "gu": "તે હજી પણ મને આનંદદાયક લાગે છે. શું તું જાણે છે, તેઓ ગેમ્સ બનાવવા માટે C++ કોડિંગ ભાષાનો ઉપયોગ કરે છે? શું તેઓએ તને કોલેજમાં આ શીખવ્યું ન હતું?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Oh wow! We learnt C++, but I never thought it could be used for making games.",
              "hi": "अरे वाह! हमने सी ++ सीखा, लेकिन मैंने कभी नहीं सोचा कि इसका इस्तेमाल गेम बनाने के लिए किया जा सकता है।",
              "ka": "ಓಹ್ ವಾವ್! ನಾವು ಸಿ ++ ಕಲಿತಿದ್ದೇವೆ, ಆದರೆ ಅದನ್ನು ಆಟಗಳನ್ನು ತಯಾರಿಸಲು ಬಳಸಬಹುದು ಅಂತ ನಾನು ಎಂದಿಗೂ ಯೋಚಿಸಿರಲಿಲ್ಲ.",
              "te": "ఆహా అధ్బుతం! మేము C++ నేర్చుకున్నాము, కానీ అది గేమ్‌ల తయారీకి ఉపయోగపడుతుందని నేను ఎప్పుడూ అనుకోలేదు.",
              "or": "ଓଃ ବାଃ! ଆମେ C ++ ଶିଖିଲୁ, କିନ୍ତୁ ମୁଁ କେବେ ଭାବି ନଥିଲି ଯେ ଏହା ଖେଳ ତିଆରି\n  ପାଇଁ ବ୍ୟବହୃତ ହୋଇପାରେ |",
              "as": "ওহ ৱাও! আমি চি++ শিকিছিলো, কিন্তু মই কেতিয়াও ভবা নাছিলো যে ইয়াক গেম বনোৱাৰ বাবে ব্যৱহাৰ কৰিব পাৰি।",
              "gu": "અરે વાહ! અમે C++ શીખ્યા, પરંતુ મેં ક્યારેય વિચાર્યું ન હતું કે તેનો ઉપયોગ રમતો બનાવવા માટે થઈ શકે છે."
            }
          }
        ]
      },
      "scene24": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_1",
              "hi": "C3_1_1",
              "ka": "C3_1_1",
              "te": "C3_1_1",
              "or": "C3_1_1",
              "as": "C3_1_1",
              "gu": "C3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shantanu wants to know more about computer programming, so Mira decides to help him out by explaining the basics.",
              "hi": "शांतनु कंप्यूटर प्रोग्रामिंग के बारे में और जानना चाहता है, इसलिए मीरा मूल बातें समझाकर उसकी सहायता करने का निर्णय करती है।",
              "ka": "ಶಂತನು ಕಂಪ್ಯೂಟರ್ ಪ್ರೋಗ್ರಾಮಿಂಗ್ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಬಯಸುತ್ತಾನೆ, ಆದ್ದರಿಂದ ಮೀರಾ ಮೂಲಭೂತ ಅಂಶಗಳನ್ನು ವಿವರಿಸುವ ಮೂಲಕ ಅವನಿಗೆ ಸಹಾಯ ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
              "te": "షాంతాను కంప్యూటర్ ప్రోగ్రామింగ్ గురించి మరింత తెలుసుకోవాలనుకుంటాడు, కాబట్టి మీరా ముఖ్యమైనవాటిని అతనికి నేర్పించి సహాయం చేయాలని నిర్ణయించుకుంది.",
              "or": "ଶାନ୍ତନୁ କମ୍ପ୍ୟୁଟର ପ୍ରୋଗ୍ରାମିଂ ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ଚାହାଁନ୍ତି, ତେଣୁ ମୀରା ମୌଳିକ\n  ବିଷୟରେ ବ୍ୟାଖ୍ୟା କରି ତାଙ୍କୁ ସାହାଯ୍ୟ କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "শান্তনুৱে কম্পিউটাৰ প্ৰ'গ্ৰামিংৰ বিষয়ে বেছি জানিব বিচাৰে, সেয়ে মীৰাই প্ৰাথমিক কথাবোৰ বুজাই দি তাক সহায় কৰাৰ সিদ্ধান্ত লয়।",
              "gu": "શાંતનુ કમ્પ્યુટર પ્રોગ્રામિંગ વિશે વધુ જાણવા માંગે છે, તેથી મીરા તેને મૂળભૂત બાબતો સમજાવીને મદદ કરવાનું નક્કી કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_2",
              "hi": "C3_1_2",
              "ka": "C3_1_2",
              "te": "C3_1_2",
              "or": "C3_1_2",
              "as": "C3_1_2",
              "gu": "C3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She helps him out in his preparation for his game design course. In that process, she learns a lot and finds coding for game design very interesting.",
              "hi": "वह खेल डिजाइन कोर्स के लिए उसकी तैयारी में उसकी सहायता करती है। इस प्रक्रिया में, वह बहुत कुछ सीखती है और गेम डिज़ाइन के लिए कोडिंग करने को बहुत रोचक मानती है।",
              "ka": "ಅವನ ಆಟದ ವಿನ್ಯಾಸ ಕೋರ್ಸ್‌ಗೆ ತಯಾರಿ ಮಾಡಲು ಅವಳು ಅವನಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತಾಳೆ. ಆ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ, ಅವಳು ಬಹಳಷ್ಟು ಕಲಿಯುತ್ತಾಳೆ ಮತ್ತು ಆಟದ ವಿನ್ಯಾಸಕ್ಕಾಗಿ ಕೋಡಿಂಗ್ ತುಂಬಾ ಆಸಕ್ತಿದಾಯಕ ಅನ್ನುವುದನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "అతని గేమ్ డిజైన్ కోర్సు కోసం ఆమె అతనికి సహాయం చేస్తుంది. అదే సమయంలో, ఆమె చాలా నేర్చుకుంటుంది మరియు గేమ్ డిజైన్ కోసం కోడింగ్‌ను చాలా ఆసక్తికరంగా కనుక్కుంటుంది.",
              "or": "ସେ ତାଙ୍କ ଖେଳ ଡିଜାଇନ୍ ପାଠ୍ୟକ୍ରମ ପାଇଁ ପ୍ରସ୍ତୁତିରେ ତାଙ୍କୁ ସାହାଯ୍ୟ କରନ୍ତି | ସେହି\n  ପ୍ରକ୍ରିୟାରେ, ସେ ବହୁତ କିଛି ଶିଖନ୍ତି ଏବଂ ଖେଳ ଡିଜାଇନ୍ ପାଇଁ କୋଡିଂକୁ ବହୁତ ଆକର୍ଷଣୀୟ ମନେ କରନ୍ତି |",
              "as": "তাই তাক গেম ডিজাইন পাঠ্যক্ৰমৰ কাৰনে তৈয়াৰিত সহায় কৰে। সেই প্ৰক্ৰিয়াটোত, তাই বহুত শিকে আৰু খেলৰ বাবে কোডিং দৰকাৰ হোৱা দেখি খুব ভাল পায়।",
              "gu": "તે તેના ગેમ ડિઝાઇન કોર્સની તૈયારીમાં તેને મદદ કરે છે. તે પ્રક્રિયામાં, તે ઘણું શીખે છે અને તેને ગેમ ડિઝાઇન માટે કોડિંગ ખૂબ જ રસપ્રદ લાગે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_3",
              "hi": "C3_1_3",
              "ka": "C3_1_3",
              "te": "C3_1_3",
              "or": "C3_1_3",
              "as": "C3_1_3",
              "gu": "C3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "One day while they’re looking at different game design companies in India, Mira notices that some companies have a few openings for computer programmers.",
              "hi": "एक दिन जब वे भारत में अलग-अलग गेम डिजाइन कंपनियों को देख रहे हैं, मीरा ध्यान देती है कि कुछ कंपनियों के पास कंप्यूटर प्रोग्रामर के लिए कुछ नौकरियाँ रिक्त हैं।",
              "ka": "ಒಂದು ದಿನ ಅವರು ಭಾರತದಲ್ಲಿನ ವಿವಿಧ ಆಟದ ವಿನ್ಯಾಸ ಕಂಪನಿಗಳನ್ನು ನೋಡುತ್ತಿರುವಾಗ, ಕಂಪ್ಯೂಟರ್ ಪ್ರೋಗ್ರಾಮರ್‌ಗಳಿಗೆ ಕೆಲವು ಕಂಪನಿಗಳಲ್ಲಿ  ತೆರೆಯುವಿಕೆಗಳು ಇವೆ ಎಂದು ಮೀರಾ ಗಮನಿಸಿದಳು.",
              "te": "ఒకరోజు వారు భారతదేశంలోని వివిధ గేమ్ డిజైన్ కంపెనీలను చూస్తున్నప్పుడు, కొన్ని కంపెనీలు కంప్యూటర్ ప్రోగ్రామర్‌ల కోసం కొన్ని ఉద్యోగ అవకాశాలని ఇస్తున్నాయని మీరా తెలుసుకుంటుంది.",
              "or": "ଦିନେ ଯେତେବେଳେ ସେମାନେ ଭାରତର ବିଭିନ୍ନ ଗେମ୍ ଡିଜାଇନ୍ କମ୍ପାନୀଗୁଡିକୁ \n ଦେଖୁଛନ୍ତି, ମୀରା ଲକ୍ଷ୍ୟ କରିଛନ୍ତି ଯେ କମ୍ପ୍ୟୁଟର ପ୍ରୋଗ୍ରାମରମାନଙ୍କ ପାଇଁ କିଛି କମ୍ପାନୀର କିଛି ଖୋଲା ଅଛି |",
              "as": "এদিন যেতিয়া সিহঁতে ভাৰতৰ বিভিন্ন গেম ডিজাইন কোম্পানীবোৰ চাই আছিল মীৰাই দেখিলে যে কিছুমান কোম্পানীত কম্পিউটাৰ প্ৰগ্ৰামাৰসকলৰ বকাৰনে পদ কেইটামান খালি আছে।",
              "gu": "એક દિવસ જ્યારે તેઓ ભારતમાં અલગ-અલગ ગેમ ડિઝાઇન કંપનીઓને જોઈ રહ્યા હતા, ત્યારે મીરાએ નોંધ્યું કે કેટલીક કંપનીઓ પાસે કમ્પ્યુટર પ્રોગ્રામરો માટે થોડી જગ્યાઓ છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_4 d",
              "hi": "C3_1_4 d",
              "ka": "C3_1_4 d",
              "te": "C3_1_4 డి",
              "or": "C3_1_4 d",
              "as": "C3_1_4 d",
              "gu": "C3_1_4 ડી"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to learn more about the openings:",
              "hi": "उद्घाटन के बारे में और जानने के लिए क्लिक करे:",
              "ka": "ಹೆಚ್ಚು ಅವಕಾಶಗಳಿಗಾಗಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
              "te": "ఓపెనింగ్స్ గురించి మరింత తెలుసుకోవడానికి క్లిక్ చేయండి:",
              "or": "ବିବରଣୀ ବିଷୟରେ ଅଧିକ ଜାଣିବା ପାଇଁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "খালিপদৰ বিষয়ে অধিক জানিবলৈ ক্লিক কৰক:",
              "gu": "શરૂઆત વિશે વધુ જાણવા માટે ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Physics programmer",
              "hi": "फिजिक्स  प्रोग्रामर",
              "ka": "ಭೌತ ಶಾಸ್ತ್ರ  ಪ್ರೋಗ್ರ್ಯಾಮರ್",
              "te": "ఫిజిక్స్ ప్రోగ్రామర్",
              "or": "ପଦାର୍ଥ ବିଜ୍ଞାନ ପ୍ରୋଗ୍ରାମର୍ |",
              "as": "পদাৰ্থ বিজ্ঞানৰ প্ৰগ্ৰেমাৰ",
              "gu": "ભૌતિકશાસ્ત્ર પ્રોગ્રામર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "C++ Programmer",
              "hi": "सी ++ प्रोग्रामर",
              "ka": "ಸಿ ++ ಪ್ರೋಗ್ರ್ಯಾಮರ್",
              "te": "C++ ప్రోగ్రామర్",
              "or": "C ++ ପ୍ରୋଗ୍ରାମର୍ |",
              "as": "চি++ প্ৰগ্ৰামাৰ",
              "gu": "C++ પ્રોગ્રામર"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_4a notebook",
              "hi": "C3_1_4A नोटबुक",
              "ka": "C3_1_4a notebook",
              "te": "C3_1_4a నోట్‌బుక్",
              "or": "C3_1_4a ନୋଟବୁକ୍ |",
              "as": "C3_1_4a টোকাবহী",
              "gu": "C3_1_4a નોટબુક"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Physics programmer",
              "hi": "फिजिक्स प्रोग्रामर",
              "ka": "ಭೌತ ಶಾಸ್ತ್ರ  ಪ್ರೋಗ್ರ್ಯಾಮರ್",
              "te": "ఫిజిక్స్ ప్రోగ్రామర్",
              "or": "ପଦାର୍ଥ ବିଜ୍ଞାନ ପ୍ରୋଗ୍ରାମର୍ |",
              "as": "পদাৰ্থ বিজ্ঞানৰ প্ৰগ্ৰেমাৰ",
              "gu": "ભૌતિકશાસ્ત્ર પ્રોગ્રામર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Responsibilities",
              "hi": "जिम्मेदारियां ",
              "ka": "ಕರ್ತವ್ಯಗಳು",
              "te": "బాధ్యతలు",
              "or": "ଦାୟିତ୍ଵ ଗୁଡିକ |",
              "as": "দায়িত্বসমূহ",
              "gu": "જવાબદારીઓ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Qualifications",
              "hi": "योग्यता",
              "ka": "ವಿದ್ಯಾರ್ಹತೆಗಳು",
              "te": "అర్హతలు",
              "or": "ଯୋଗ୍ୟତା",
              "as": "অৰ্হতা",
              "gu": "લાયકાત"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Additional",
              "hi": "अतिरिक्त",
              "ka": "ಹೆಚ್ಚುವರಿ",
              "te": "అదనపు",
              "or": "ଅତିରିକ୍ତ",
              "as": "বাকিবোৰ",
              "gu": "વધારાનુ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "**Job Responsibilities:**\n- Collaborate with other departments such as design, animation & AI to implement complex game features  \n\n- Work with artists to create new interactive objects and vehicles for the game",
              "hi": "**नौकरी की जिम्मेदारियां:**\n- जटिल गेम सुविधाओं को लागू करने के लिए डिजाइन, एनीमेशन और एआई जैसे अन्य विभागों के साथ सहयोग करना \n\n- खेल के लिए नई इंटरैक्टिव ऑब्जेक्ट्स और वाहन बनाने के लिए कलाकारों के साथ काम करना ",
              "ka": "**ಉದ್ಯೋಗದ ಜವಾಬ್ದಾರಿಗಳು:**- ಸಂಕೀರ್ಣ ಆಟದ ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಕಾರ್ಯಗತಗೊಳಿಸಲು ವಿನ್ಯಾಸ, ಅನಿಮೇಷನ್ ಮತ್ತು AI ನಂತಹ ಇತರ ವಿಭಾಗಗಳೊಂದಿಗೆ ಸಹಕರಿಸಿ- ಆಟಕ್ಕಾಗಿ ಹೊಸ ಸಂವಾದಾತ್ಮಕ ವಸ್ತುಗಳು ಮತ್ತು ವಾಹನಗಳನ್ನು ರಚಿಸಲು ಕಲಾವಿದರೊಂದಿಗೆ ಕೆಲಸ ಮಾಡಿ",
              "te": "**ఉద్యోగ బాధ్యతలు:** \n - కష్టమైన గేమ్ ఫీచర్‌లను అమలు చేయడానికి డిజైన్, యానిమేషన్ & AI వంటి ఇతర విభాగాలకి సహకరిచడం. \n \n \n \n - గేమ్ కోసం కొత్త ఇంటరాక్టివ్ వస్తువులు మరియు వాహనాలను రూపొందించడానికి కళాకారులతో కలిసి పని చేయాలి.",
              "or": "** କାର୍ଯ୍ୟର ଦାୟିତ୍ଵ :: **\n \n - ଜଟିଳ ଖେଳ ବଇଶିଷ୍ଟ୍ୟଗୁଡିକ କାର୍ଯ୍ୟକାରୀ କରିବା ପାଇଁ ଡିଜାଇନ୍, ଆନିମେସନ୍ ଏବଂ AI ପରି ଅନ୍ୟ ବିଭାଗ ସହିତ ସହଯୋଗ କରନ୍ତୁ |\n \n \n \n - ଖେଳ ପାଇଁ ନୂତନ ଇଣ୍ଟରାକ୍ଟିଭ୍ ବସ୍ତୁ ଏବଂ ଯାନ ତିଆରି କରିବାକୁ କଳାକାରମାନଙ୍କ ସହିତ କାର୍ଯ୍ୟ କରନ୍ତୁ |",
              "as": "**চাকৰিৰ দায়িত্ব:** - টান গেম বোৰৰ কাৰনে ডিজাইন, এনিমেচন আৰু এআইৰ দৰে অন্যান্য বিভাগৰ লগত মিলি কাম কৰা - গেমটোৰ বাবে নতুন ইন্টাৰেক্টিভ অবজেক্ট আৰু বাহন বনাবলৈ শিল্পীসকলৰ সৈতে কাম কৰক",
              "gu": "**નોકરીની જવાબદારીઓ:** - જટિલ રમત સુવિધાઓને અમલમાં મૂકવા માટે ડિઝાઇન, એનિમેશન અને AI જેવા અન્ય વિભાગો સાથે સહયોગ કરો - રમત માટે નવા ઇન્ટરેક્ટિવ ઑબ્જેક્ટ્સ અને વાહનો બનાવવા માટે કલાકારો સાથે કામ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "**Qualifications needed:**\n- Physics, mathematics, computer science or equivalent degree\n\n- Game programming experience\n\n- Strong math skills and knowledge of Newtonian mechanics",
              "hi": "** योग्यता की आवश्यकता: **\n- भौतिकी, गणित, कंप्यूटर विज्ञान या समकक्ष डिग्री\n\n- खेल प्रोग्रामिंग में अनुभव\n\n- न्यूटनियन यांत्रिकी का मजबूत गणित कौशल और ज्ञान",
              "ka": "**ಅಗತ್ಯವಿರುವ ವಿದ್ಯಾರ್ಹತೆಗಳು:**- ಭೌತಶಾಸ್ತ್ರ, ಗಣಿತ, ಕಂಪ್ಯೂಟರ್ ವಿಜ್ಞಾನ ಅಥವಾ ತತ್ಸಮಾನ ಪದವಿ- ಗೇಮ್ ಪ್ರೋಗ್ರಾಮಿಂಗ್ ಅನುಭವ- ಪ್ರಬಲ ಗಣಿತ ಕೌಶಲ್ಯ ಮತ್ತು ನ್ಯೂಟೋನಿಯನ್ ಮೆಕ್ಯಾನಿಕ್ಸ್‌ನ ಜ್ಞಾನ",
              "te": "**కావాల్సిన అర్హతలు:**\n \n - ఫిజిక్స్, మ్యాథమెటిక్స్, కంప్యూటర్ సైన్స్ లేదా డిగ్రీ పూర్తి చేసి ఉండాలి. \n \n \n \n - గేమ్ ప్రోగ్రామింగ్ అనుభవం\n \n \n \n - మంచి గణిత నైపుణ్యాలు మరియు న్యూటోనియన్ మెకానిక్స్ పరిజ్ఞానం ఉండాలి.",
              "or": "** ଯୋଗ୍ୟତା ଆବଶ୍ୟକ: **\n \n - ପଦାର୍ଥ ବିଜ୍ଞାନ, ଗଣିତ, କମ୍ପ୍ୟୁଟର ବିଜ୍ଞାନ କିମ୍ବା ସମାନ ଡିଗ୍ରୀ |\n \n \n \n - ଖେଳ ପ୍ରୋଗ୍ରାମିଂ ଅଭିଜ୍ଞତା |\n \n \n \n - ଶକ୍ତିଶାଳୀ ଗଣିତ କ skills ଶଳ ଏବଂ ନ୍ୟୁଟୋନିୟନ୍ ମେକାନିକ୍ସର ଜ୍ଞାନ",
              "as": "**অৰ্হতাৰ প্ৰয়োজন:** - পদাৰ্থ বিজ্ঞান, গণিত, কম্পিউটাৰ বিজ্ঞান বা তেনেকুৱা ডিগ্ৰী - গেম প্ৰগ্ৰামিং অভিজ্ঞতা - ভাল গণিতৰ জ্ঞান আৰু নিউটনীয় বিজ্ঞানৰ জ্ঞান",
              "gu": "**લાયકાત જરૂરી છે:** - ભૌતિકશાસ્ત્ર, ગણિત, કમ્પ્યુટર વિજ્ઞાન અથવા સમકક્ષ ડિગ્રી - ગેમ પ્રોગ્રામિંગ અનુભવ - મજબૂત ગણિત કૌશલ્ય અને ન્યુટોનિયન મિકેનિક્સનું જ્ઞાન"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "**The ideal candidate for this:**\n- Excellent problem-solving skills\n\n- Passionate about implementing physics-based systems",
              "hi": "** इस के लिए आदर्श उम्मीदवार: **\n- उत्कृष्ट समस्या सुलझाने का कौशल\n\n- फिजिक्स-आधारित प्रणालियों को लागू करने के बारे में जोशिलापन  ",
              "ka": "**ಇದಕ್ಕೆ ಸೂಕ್ತವಾದ ಅಭ್ಯರ್ಥಿ:**- ಅತ್ಯುತ್ತಮ ಸಮಸ್ಯೆ-ಪರಿಹರಿಸುವ ಕೌಶಲ್ಯಗಳು- ಭೌತಶಾಸ್ತ್ರ ಆಧಾರಿತ ವ್ಯವಸ್ಥೆಗಳನ್ನು ಅಳವಡಿಸಲು ಉತ್ಸಾಹ",
              "te": "**దీనికి సరిపోయే అభ్యర్ధి:** \n \n - అద్భుతమైన సమస్య పరిష్కార నైపుణ్యాలు ఉండాలి. \n \n \n \n - భౌతిక-ఆధారిత సిస్టమ్స్ ని అమలు చేయడం పట్ల ఇష్టముండాలి.",
              "or": "** ଏଥିପାଇଁ ଆଦର୍ଶ ପ୍ରାର୍ଥୀ: **\n \n - ଉତ୍କୃଷ୍ଟ ସମସ୍ୟା ସମାଧାନ କୌଶଳ |\n \n \n \n - ପଦାର୍ଥ ବିଜ୍ଞାନ-ଆଧାରିତ ପ୍ରଣାଳୀକୁ କାର୍ଯ୍ୟକାରୀ କରିବା ପାଇଁ ଆଗ୍ରହୀ |",
              "as": "**ইয়াৰ বাবে আদৰ্শ প্ৰাৰ্থী:** - উৎকৃষ্ট সমস্যা সমাধান কৰিব জনা - পদাৰ্থ বিজ্ঞান আধাৰিত বস্তুৰ প্ৰতি উৎসাহী",
              "gu": "**આના માટે આદર્શ ઉમેદવાર:** - ઉત્તમ સમસ્યા હલ કરવાની કુશળતા - ભૌતિકશાસ્ત્ર-આધારિત પ્રણાલીઓને અમલમાં મૂકવા પ્રત્યે ઉત્સાહી"
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_4b notebook",
              "hi": "C3_1_4B नोटबुक",
              "ka": "C3_1_4b notebook",
              "te": "C3_1_4b నోట్‌బుక్",
              "or": "C3_1_4b ନୋଟବୁକ୍ |",
              "as": "C3_1_4b টোকাবহী",
              "gu": "C3_1_4b નોટબુક"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "C++ programmer",
              "hi": "सी ++ प्रोग्रामर",
              "ka": "ಸಿ ++ ಪ್ರೋಗ್ರ್ಯಾಮರ್",
              "te": "C++ ప్రోగ్రామర్",
              "or": "C ++ ପ୍ରୋଗ୍ରାମର୍ |",
              "as": "চি++ প্ৰগ্ৰেমাৰ",
              "gu": "C++ પ્રોગ્રામર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Responsibilities",
              "hi": "जिम्मेदारियों",
              "ka": "ಕರ್ತವ್ಯಗಳು",
              "te": "బాధ్యతలు",
              "or": "ଦାୟିତ୍ଵ ଗୁଡିକ |",
              "as": "দায়িত্বসমূহ",
              "gu": "જવાબદારીઓ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Qualifications",
              "hi": "योग्यता",
              "ka": "ವಿದ್ಯಾರ್ಹತೆಗಳು",
              "te": "అర్హతలు",
              "or": "ଯୋଗ୍ୟତା",
              "as": "অৰ্হতা",
              "gu": "લાયકાત"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Additional",
              "hi": "अतिरिक्त",
              "ka": "ಹೆಚ್ಚುವರಿ",
              "te": "అదనపు",
              "or": "ଅତିରିକ୍ତ",
              "as": "অতিৰিক্ত",
              "gu": "વધારાનુ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "**Job Responsibilities:**\n- Design and implement solutions to help us meet the challenges of fast-moving game development studio/company\n\n- Learn new technologies to help advance our applications",
              "hi": "**नौकरी की जिम्मेदारियां:**\n- तेजी से बढ़ने वाली गेम डेवलपमेंट स्टूडियो / कंपनी की चुनौतियों को पूरा करने में हमारी सहायता के लिए समाधान डिज़ाइन और उनका कार्यान्वयन\n\n- हमारे अनुप्रयोगों को आगे बढ़ाने में मदद करने के लिए नई तकनीक को जाने",
              "ka": "**ಉದ್ಯೋಗದ ಜವಾಬ್ದಾರಿಗಳು:**- ವೇಗವಾಗಿ ಮುಂದುವರೆಯುತ್ತಿರುವ  ಆಟದ ಅಭಿವೃದ್ಧಿ ಸ್ಟುಡಿಯೋ/ಕಂಪನಿಯ ಸವಾಲುಗಳನ್ನು ಎದುರಿಸಲು ನಮಗೆ ಸಹಾಯ ಮಾಡಲು ಪರಿಹಾರಗಳನ್ನು ವಿನ್ಯಾಸಗೊಳಿಸುವುದು ಮತ್ತು ಕಾರ್ಯಗತಗೊಳಿಸುವುದು- ನಮ್ಮ ಅಪ್ಲಿಕೇಶನ್‌ಗಳನ್ನು ಮುನ್ನಡೆಸಲು ಸಹಾಯ ಮಾಡಲು ಹೊಸ ತಂತ್ರಜ್ಞಾನಗಳನ್ನು ಕಲಿಯುವುದು",
              "te": "**ఉద్యోగ బాధ్యతలు:**\n \n - వేగంగా కదిలే గేమ్ డెవలప్‌మెంట్ స్టూడియో/కంపెనీ సవాళ్లను ఎదుర్కోవడంలో మాకు సహాయపడే పరిష్కారాలను రూపొందించండి మరియు అమలు చేయండి\n \n \n \n - మా అప్లికేషన్‌లను ముందుకు తీసుకెళ్లడంలో సహాయపడటానికి కొత్త టెక్నాలజీ గురించి నేర్చువడం.",
              "or": "** କାର୍ଯ୍ୟର ଦାୟିତ୍ଵ :: **\n \n - ଦ୍ରୁତ ଗତିଶୀଳ ଖେଳ ବିକାଶ ଷ୍ଟୁଡିଓ / କମ୍ପାନୀର ଆହ୍ଵାନକୁ ପୂରଣ କରିବାରେ ଆମକୁ ସାହାଯ୍ୟ କରିବାକୁ ସମାଧାନର ପରିକଳ୍ପନା ଏବଂ କାର୍ଯ୍ୟକାରୀ କର |\n \n \n \n - ଆମର ପ୍ରୟୋଗଗୁଡ଼ିକୁ ଆଗକୁ ନେବାରେ ସାହାଯ୍ୟ କରିବାକୁ ନୂତନ ଟେକ୍ନୋଲୋଜି ଶିଖନ୍ତୁ |",
              "as": "**চাকৰিৰ দায়িত্ব:** - দ্ৰুত গতিত চলি থকা গেম ডেভেলপমেণ্ট ষ্টুডিঅ'/কোম্পানীৰ প্ৰত্যাহ্বানৰ মোকাবিলা কৰাত আমাক সহায় কৰিবলৈ সমাধানবোৰ ডিজাইন আৰু ৰূপায়ণ কৰক - আমাৰ এপ্লিকেচনবোৰ আগবঢ়োৱাত সহায় কৰিবলৈ নতুন প্ৰযুক্তি শিকক",
              "gu": "**નોકરીની જવાબદારીઓ:** - ઝડપી ગતિશીલ ગેમ ડેવલપમેન્ટ સ્ટુડિયો/કંપનીના પડકારોને પહોંચી વળવામાં મદદ કરવા માટે ઉકેલો ડિઝાઇન કરો અને અમલ કરો - અમારી એપ્લિકેશનને આગળ વધારવામાં મદદ કરવા માટે નવી તકનીકો શીખો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "**Qualifications needed:**\n- A degree in computer science or equivalent\n\n- Good with C++ or JAVA coding",
              "hi": "** योग्यता की आवश्यकता: **\n- कंप्यूटर विज्ञान या समकक्ष में एक डिग्री\n\n- सी++ या जावा कोडिंग का अच्छा ज्ञान ",
              "ka": "**ಅಗತ್ಯವಿರುವ ವಿದ್ಯಾರ್ಹತೆಗಳು:**- ಕಂಪ್ಯೂಟರ್ ವಿಜ್ಞಾನದಲ್ಲಿ ಪದವಿ ಅಥವಾ ತತ್ಸಮಾನ- ಸಿ ಅಥವಾ ಜಾವಾ ಕೋಡಿಂಗ್‌ನಲ್ಲಿ ಉತ್ತಮ ಜ್ಞಾನ ಹೊಂದಿರುವವರು",
              "te": "**కావాల్సిన అర్హతలు:**\n \n - కంప్యూటర్ సైన్స్ లో డిగ్రీ లేదా అక్వాలెంట్ \n \n \n - C++ లేదా JAVA కోడింగ్‌ బాగా తెలిసి ఉండాలి.",
              "or": "** ଯୋଗ୍ୟତା ଆବଶ୍ୟକ: **\n \n - କମ୍ପ୍ୟୁଟର ବିଜ୍ଞାନରେ ଏକ ଡିଗ୍ରୀ କିମ୍ବା ସମାନ |\n \n \n \n - C ++ କିମ୍ବା JAVA କୋଡିଂ ସହିତ ଭଲ",
              "as": "**অৰ্হতাৰ প্ৰয়োজন:** - কম্পিউটাৰ বিজ্ঞান বা সমতুল্য ডিগ্ৰী - চি++ বা জাভা কোডিঙৰ সৈতে ভাল",
              "gu": "**લાયકાત જરૂરી છે:** - કોમ્પ્યુટર સાયન્સમાં ડિગ્રી અથવા સમકક્ષ - C++ અથવા JAVA કોડિંગ સાથે સારી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "**The ideal candidate for this:**\n- Organized about work\n\n- Capable of providing timely progress updates",
              "hi": "** इस के लिए आदर्श उम्मीदवार: **\n- काम के बारे में संगठित\n\n- समय पर प्रगति की नवीनतम जानकारी प्रदान करने में सक्षम",
              "ka": "**ಇದಕ್ಕೆ ಸೂಕ್ತ ಅಭ್ಯರ್ಥಿ:**- ಕೆಲಸದ ಬಗ್ಗೆ ಆಯೋಜಿಸುವವರು- ಸಮಯೋಚಿತ ಪ್ರಗತಿ ನವೀಕರಣಗಳನ್ನು ಒದಗಿಸುವ ಸಾಮರ್ಥ್ಯ ಉಳ್ಳವರು",
              "te": "**దీనికి అనువైన అభ్యర్థి:**\n \n - పనిని బాగా నిర్వహించాలి. \n \n \n - సరైన సమయంలో ప్రోగ్రెస్ అప్డేట్స్ అందించగలగాలి",
              "or": "** ଏଥିପାଇଁ ଆଦର୍ଶ ପ୍ରାର୍ଥୀ: **\n \n - କାର୍ଯ୍ୟ ବିଷୟରେ ସଂଗଠିତ |\n \n \n \n - ଠିକ ସମୟରେ ପ୍ରଗତି ଅଦ୍ୟତନ ପ୍ରଦାନ କରିବାରେ ସକ୍ଷମ",
              "as": "**ইয়াৰ বাবে আদৰ্শ প্ৰাৰ্থী:** - কামৰ চিজিলকৈ কৰে",
              "gu": "**આ માટે આદર્શ ઉમેદવાર:** - કાર્ય વિશે વ્યવસ્થિત - સમયસર પ્રગતિ અપડેટ્સ પ્રદાન કરવામાં સક્ષમ"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_2_1",
              "hi": "C3_2_1",
              "ka": "C3_2_1",
              "te": "C3_2_1",
              "or": "C3_2_1",
              "as": "C3_2_1",
              "gu": "C3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She reads more about the role of a physics programmer, and she finds out that it involves bringing realism into video games by using physics concepts.",
              "hi": "वह फिजिक्स प्रोग्रामर की भूमिका के बारे में अधिक पढ़ती है, और उसे पता चलता है कि इसमें फिजिक्स अवधारणाओं का उपयोग करके वीडियो गेम में वास्तविकता लाना शामिल है।",
              "ka": "ಭೌತಶಾಸ್ತ್ರದ ಪ್ರೋಗ್ರಾಮರ್ ಪಾತ್ರದ ಬಗ್ಗೆ ಅವಳು ಹೆಚ್ಚು ಓದುತ್ತಾಳೆ ಮತ್ತು ಭೌತಶಾಸ್ತ್ರದ ಪರಿಕಲ್ಪನೆಗಳನ್ನು ಬಳಸಿಕೊಂಡು ವೀಡಿಯೊ ಆಟಗಳಲ್ಲಿ ವಾಸ್ತವಿಕತೆಯನ್ನು ತರುವುದನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ ಎನ್ನುವುದನ್ನು ಅವಳು ಕಂಡುಕೊಂಡಳು.",
              "te": "ఆమె ఫిజిక్స్ ప్రోగ్రామర్ రోల్ గురించి మరింత చదువుతుంది మరియు ఫిజిక్స్ కాన్సెప్ట్ లను ఉపయోగించడం ద్వారా వీడియో గేమ్‌లని నిజమైనదిగా చూపించవచ్చని ఆమె తెలుసుకుంటుంది.",
              "or": "ସେ ଏକ ପଦାର୍ଥ ବିଜ୍ଞାନ ପ୍ରୋଗ୍ରାମରଙ୍କ ଭୂମିକା ବିଷୟରେ ଅଧିକ ପଢନ୍ତି, ଏବଂ ସେ \n ଜାଣିବାକୁ ପାଇଲେ ଯେ ପଦାର୍ଥ ବିଜ୍ଞାନ ଧାରଣା ବ୍ୟବହାର କରି ଏହା ବାସ୍ତବତାକୁ ଭିଡିଓ ଗେମରେ ଅନ୍ତର୍ଭୁକ୍ତ କରେ |",
              "as": "তাই পদাৰ্থ বিজ্ঞানৰ প্ৰগ্ৰামাৰ এজনৰ ভূমিকাৰ বিষয়ে ভালকৈ পঢ়ে, আৰু তাই গম পায় যে ইয়াত পদাৰ্থ বিজ্ঞানৰ ধাৰণা ব্যৱহাৰ কৰি ভিডিঅ' গেমলৈ ৰিয়েলিজম আনে।",
              "gu": "તે ભૌતિકશાસ્ત્ર પ્રોગ્રામરની ભૂમિકા વિશે વધુ વાંચે છે, અને તેને શોધી કાઢ્યું છે કે તેમાં ભૌતિકશાસ્ત્રના ખ્યાલોનો ઉપયોગ કરીને વિડિયો ગેમ્સમાં વાસ્તવિકતા લાવવાનો સમાવેશ થાય છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_2_2",
              "hi": "C3_2_2",
              "ka": "C3_2_2",
              "te": "C3_2_2",
              "or": "C3_2_2",
              "as": "C3_2_2",
              "gu": "C3_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She reads, A physics programmer uses their coding skills and knowledge of physics to create interactive and moving objects in games. It requires math skills like trigonometry and algebra.\"\"",
              "hi": "वह पढ़ती है, एक फिजिक्स प्रोग्रामर गेम में इंटरैक्टिव और चलित वस्तुओं को बनाने के लिए फिजिक्स के अपने कोडिंग कौशल और ज्ञान का उपयोग करता है। इसमें ट्रिगनोमेट्री और अलजेबरा की तरह गणित कौशल की आवश्यकता होती है। \"\"",
              "ka": "ಅವಳು ಓದಿದಳು, ಭೌತಶಾಸ್ತ್ರದ ಪ್ರೋಗ್ರಾಮರ್ ಆಟಗಳಲ್ಲಿ ಸಂವಾದಾತ್ಮಕ ಮತ್ತು ಚಲಿಸುವ ವಸ್ತುಗಳನ್ನು ರಚಿಸಲು ತಮ್ಮ ಕೋಡಿಂಗ್ ಕೌಶಲ್ಯ ಮತ್ತು ಭೌತಶಾಸ್ತ್ರದ ಜ್ಞಾನವನ್ನು ಬಳಸುತ್ತಾರೆ. ಇದಕ್ಕೆ ತ್ರಿಕೋನಮಿತಿ ಮತ್ತು ಬೀಜಗಣಿತದಂತಹ ಗಣಿತ ಕೌಶಲ್ಯಗಳು ಬೇಕಾಗುತ್ತವೆ.\"\"",
              "te": "ఆమె చదువుతుంది, ఫిజిక్స్ ప్రోగ్రామర్ ఆటలలో ఇంటరాక్టివ్ మరియు కదిలే వస్తువులను రూపొందించడానికి వారి కోడింగ్ నైపుణ్యాలు మరియు భౌతిక శాస్త్ర పరిజ్ఞానాన్ని ఉపయోగిస్తుంది. దీనికి త్రికోణమితి మరియు బీజగణితం వంటి గణిత నైపుణ్యాలు అవసరం.\"\"",
              "or": "ସେ ପଢନ୍ତି, ଜଣେ ପଦାର୍ଥ ବିଜ୍ଞାନ ପ୍ରୋଗ୍ରାମର୍ ସେମାନଙ୍କର କୋଡିଂ କୌଶଳ ଏବଂ\n  ପଦାର୍ଥ ବିଜ୍ଞାନ ବିଷୟରେ ଜ୍ଞାନ ବ୍ୟବହାର କରି ଖେଳଗୁଡିକରେ ଇଣ୍ଟରାକ୍ଟିଭ୍ ଏବଂ ଗତିଶୀଳ ବସ୍ତୁ ସୃଷ୍ଟି କରନ୍ତି | ଏହା ଟ୍ରାଇଗୋନେଟ୍ରି ଏବଂ ବୀଜ ବିବେଚନା ପରି ଗଣିତ କୌଶଳ ଆବଶ୍ୟକ କରେ। \"",
              "as": "তাই পঢ়ে, এজন পদাৰ্থ বিজ্ঞান প্ৰগ্ৰেমাৰে তেওঁলোকৰ কোডিং দক্ষতা আৰু পদাৰ্থ বিজ্ঞানৰ জ্ঞান ব্যৱহাৰ কৰি গেমবোৰত ইন্টাৰেক্টিভ আৰু চলি থকা বস্তু সৃষ্টি কৰে। ইয়াৰ বাবে ত্ৰিকোণমিতি আৰু বীজগণিতৰ দৰে গণিতৰ জনাটো প্ৰয়োজন।\"",
              "gu": "તે વાંચે છે, એક ભૌતિકશાસ્ત્ર પ્રોગ્રામર રમતોમાં ઇન્ટરેક્ટિવ અને મૂવિંગ ઑબ્જેક્ટ્સ બનાવવા માટે તેમની કોડિંગ કુશળતા અને ભૌતિકશાસ્ત્રના જ્ઞાનનો ઉપયોગ કરે છે. તેને ત્રિકોણમિતિ અને બીજગણિત જેવા ગણિત કૌશલ્યોની જરૂર છે.\"\""
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_2_3",
              "hi": "C3_2_3",
              "ka": "C3_2_3",
              "te": "C3_2_3",
              "or": "C3_2_3",
              "as": "C3_2_3",
              "gu": "C3_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She finds the job very interesting, but she is more confident about the job profile for a C++ programmer.",
              "hi": "वह नौकरी को बहुत रोचक पाती है, लेकिन वह सी ++ प्रोग्रामर के लिए नौकरी प्रोफाइल के लिए अधिक विश्वस्त है।",
              "ka": "ಅವಳು ಆ ಕೆಲಸವನ್ನು ತುಂಬಾ ಆಸಕ್ತಿದಾಯಕ ಅಂದುಕೊಳ್ತಾಳೆ, ಆದರೆ ಸಿ ++ ಪ್ರೋಗ್ರಾಮರ್‌ನ ಕೆಲಸದ ಪ್ರೊಫೈಲ್ ಬಗ್ಗೆ ಅವಳು ಹೆಚ್ಚು ವಿಶ್ವಾಸ ಹೊಂದಿದ್ದಾಳೆ.",
              "te": "ఆమె కనుగొన్న ఉద్యోగం చాలా ఆసక్తికరంగా ఉంది, కానీ ఆమె C++ ప్రోగ్రామర్‌కి సంబంధించిన జాబ్ ప్రొఫైల్‌పై మరింత నమ్మకంగా ఉంది.",
              "or": "ସେ ଚାକିରୀକୁ ଅତ୍ୟନ୍ତ କୌତୁହଳଜନକ ମନେ କରନ୍ତି, କିନ୍ତୁ ସେ ଏକ C ++ \n ପ୍ରୋଗ୍ରାମର୍ ପାଇଁ ଚାକିରି ପ୍ରୋଫାଇଲ୍ ଉପରେ ଅଧିକ ଆତ୍ମବିଶ୍ୱାସୀ |",
              "as": "তাই কামটো বৰ ভাল পায়, কিন্তু তাই চি++ প্ৰগ্ৰামাৰ এজনৰ বাবে চাকৰিৰ প্ৰ'ফাইলৰ বিষয়ে বেছি আত্মবিশ্বাসী।",
              "gu": "તેને નોકરી ખૂબ જ રસપ્રદ લાગે છે, પરંતુ તે C++ પ્રોગ્રામર માટેની જોબ પ્રોફાઇલ વિશે વધુ વિશ્વાસ ધરાવે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "Research for jobs",
              "hi": "नौकरियों की खोज ",
              "ka": "ಉದ್ಯೋಗಗಳಿಗಾಗಿ ಸಂಶೋಧನೆ",
              "te": "ఉద్యోగాల కోసం పరిశోధన",
              "or": "ଚାକିରି ପାଇଁ ଗବେଷଣା |",
              "as": "চাকৰিৰ বাবে গৱেষণা",
              "gu": "નોકરીઓ માટે સંશોધન"
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_2_4",
              "hi": "C3_2_4",
              "ka": "C3_2_4",
              "te": "C3_2_4",
              "or": "C3_2_4",
              "as": "C3_2_4",
              "gu": "C3_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira decides to learn more about coding in the gaming industry.",
              "hi": "मीरा गेमिंग इंडस्ट्री  में कोडिंग के बारे में और जानने का निश्चय करती है।",
              "ka": "ಗೇಮಿಂಗ್ ಉದ್ಯಮದಲ್ಲಿ ಕೋಡಿಂಗ್ ಕುರಿತು ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಮೀರಾ ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
              "te": "గేమింగ్ పరిశ్రమలో కోడింగ్ గురించి మరింత తెలుసుకోవాలని మీరా నిర్ణయించుకుంది.",
              "or": "ଖେଳ ଇଣ୍ଡଷ୍ଟ୍ରିରେ କୋଡିଂ ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ମୀରା ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "মীৰাই গেমিংৰ পৃথি্ৱীত কোডিঙৰ বিষয়ে অধিক শিকিম বুলি ভাবে।",
              "gu": "મીરાએ ગેમિંગ ઉદ્યોગમાં કોડિંગ વિશે વધુ શીખવાનું નક્કી કર્યું."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_5",
              "hi": "C3_1_5",
              "ka": "C3_1_5",
              "te": "C3_1_5",
              "or": "C3_1_5",
              "as": "C3_1_5",
              "gu": "C3_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Together with Shantanu, she plans to learn more about game programming to become eligible for this role.",
              "hi": "शांतनु के साथ, वह इस पद को पाने के योग्य बनने के लिए गेम प्रोग्रामिंग के बारे में अधिक जानने की योजना बना रही है।",
              "ka": "ಶಂತನು ಜೊತೆಗೆ, ಈ ಕೆಲಸಕ್ಕೆ ಅರ್ಹತೆ ಹೊಂದಲು ಅವಳು ಗೇಮ್ ಪ್ರೋಗ್ರಾಮಿಂಗ್ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಯೋಚಿಸ್ತಾಳೆ.",
              "te": "షాంతనుతో కలిసి, ఆమె ఈ రోల్ కి అర్హత సాధించడానికి గేమ్ ప్రోగ్రామింగ్ గురించి మరింత తెలుసుకోవాలని ప్లాన్ చేస్తుంది.",
              "or": "ଶାନ୍ତନୁଙ୍କ ସହ ମିଶି ସେ ଏହି ଭୂମିକା ପାଇଁ ଯୋଗ୍ୟ ହେବା ପାଇଁ ଖେଳ ପ୍ରୋଗ୍ରାମିଂ \n ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ଯୋଜନା କରିଛନ୍ତି |",
              "as": "শান্তনুৰ সৈতে একেলগে, তেওঁ এই পদটোৰ কাৰনে যোগ্য হ'বলৈ গেম প্ৰ'গ্ৰামিংৰ বিষয়ে অধিক শিকাৰ কথা ভাবিছে।",
              "gu": "શાંતનુ સાથે મળીને, તે આ ભૂમિકા માટે પાત્ર બનવા માટે ગેમ પ્રોગ્રામિંગ વિશે વધુ શીખવાની યોજના ધરાવે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_6 check list",
              "hi": "C3_1_6 चेकलिस्ट ",
              "ka": "C3_1_6 check list",
              "te": "C3_1_6 చెక్ లిస్ట్",
              "or": "C3_1_6 check list",
              "as": "C3_1_6 পৰীক্ষা তালিকা",
              "gu": "C3_1_6 ચેક લિસ્ટ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Help her decide what she should do next -",
              "hi": "उसे यह तय करने में मदद करे कि उसे आगे क्या करना चाहिए -",
              "ka": "ಅವಳು ಮುಂದೆ ಏನು ಮಾಡಬೇಕೆಂದು ನಿರ್ಧರಿಸಲು ಸಹಾಯ ಮಾಡಿ -",
              "te": "ఆమె తర్వాత ఏమి చేయాలో నిర్ణయించడంలో ఆమెకు సహాయపడండి -",
              "or": "ସେ ପରବର୍ତ୍ତୀ ସମୟରେ କଣ କରିବା ଉଚିତ୍ ତାହା ସ୍ଥିର କରିବାରେ ତାଙ୍କୁ ସାହାଯ୍ୟ କରନ୍ତୁ -",
              "as": "তাই আহিব লগা সময়ত কি কৰা উচিত সিদ্ধান্ত লোৱাত সহায় কৰক -",
              "gu": "તેને આગળ શું કરવું જોઈએ તે નક્કી કરવામાં મદદ કરો -"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Learn to make a game by video tutorials",
              "hi": "वीडियो ट्यूटोरियल द्वारा एक गेम बनाना सीखे",
              "ka": "ವೀಡಿಯೊ ಟ್ಯುಟೋರಿಯಲ್‌ಗಳ ಮೂಲಕ ಆಟವನ್ನು ಮಾಡಲು ಕಲಿಯುವುದೇ",
              "te": "వీడియో ట్యుటోరియల్స్ ద్వారా గేమ్ చేయడం నేర్చుకోండి",
              "or": "ଭିଡିଓ ଟ୍ୟୁଟୋରିଆଲ୍ ଦ୍ୱାରା ଏକ ଖେଳ ତିଆରି କରିବାକୁ ଶିଖ |",
              "as": "ভিডিঅ' টিউটোৰিয়েলৰ দ্বাৰা গেম এটা বনাবলৈ শিকক",
              "gu": "વિડિયો ટ્યુટોરિયલ્સ દ્વારા ગેમ બનાવતા શીખો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Talk to people in the gaming industry",
              "hi": "गेमिंग इंडस्ट्री के लोगों से बात करे",
              "ka": "ಗೇಮಿಂಗ್ ಉದ್ಯಮದಲ್ಲಿರುವ ಜನರೊಂದಿಗೆ ಮಾತನಾಡುವುದು",
              "te": "గేమింగ్ కంపెనీలోని వ్యక్తులతో మాట్లాడండి",
              "or": "ଖେଳ ଇଣ୍ଡଷ୍ଟ୍ରିର ଲୋକଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରନ୍ତୁ |",
              "as": "গেমিং উদ্যোগৰ লোকসকলৰ সৈতে কথা পাতক",
              "gu": "ગેમિંગ ઉદ્યોગના લોકો સાથે વાત કરો"
            }
          }
        ]
      },
      "scene25": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_1",
              "hi": "C3_1_1",
              "ka": "C3_1_1",
              "te": "C3_1_1",
              "or": "C3_1_1",
              "as": "C3_1_1",
              "gu": "C3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira and Shantanu have watched videos online to understand the game development process. Together they figure out how they can make video games.",
              "hi": "मीरा और शांतनु ने गेम डेवलपमेंट प्रक्रिया को समझने के लिए ऑनलाइन वीडियो देखे हैं।  वे साथ में समझते हैं कि वे वीडियो गेम कैसे बना सकते हैं।",
              "ka": "ಮೀರಾ ಮತ್ತು ಶಂತನು ಆಟದ ಅಭಿವೃದ್ಧಿ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳೋಕ್ಕೆ ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ವೀಡಿಯೊಗಳನ್ನು ವೀಕ್ಷಿಸ್ತಾರೆ. ಅವರು ವೀಡಿಯೊ ಗೇಮ್‌ಗಳನ್ನು ಹೇಗೆ ಮಾಡಬಹುದು ಎನ್ನುವುದನ್ನು ಒಟ್ಟಿಗೆ ಲೆಕ್ಕಾಚಾರ ಮಾಡುತ್ತಾರೆ.",
              "te": "మీరా మరియు షాంతను గేమ్ అభివృద్ధి ప్రక్రియను అర్థం చేసుకోవడానికి ఆన్‌లైన్‌లో వీడియోలను చూశారు. వారు కలిసి వీడియో గేమ్‌లను ఎలా తయారు చేయవచ్చో తెలుసుకుంటారు.",
              "or": "ଖେଳର ବିକାଶ ପ୍ରକ୍ରିୟା ବୁଝିବା ପାଇଁ ମୀରା ଏବଂ ଶାନ୍ତନୁ ଅନଲାଇନରେ ଭିଡିଓ ଦେଖିଛନ୍ତି |\n  ସେମାନେ ଏକତ୍ର ଭିଡିଓ ଗେମ୍ କିପରି କରିପାରିବେ ତାହା ଆକଳନ କରନ୍ତି |",
              "as": "মীৰা আৰু শান্তনুৱে খগেম কেনেকৈ বনাই তাকে বুজিবলৈ অনলাইনভিডিঅ' চাইছে। সিহঁতে একেলগে কেনেকৈ ভিডিঅ' গেম বনাব পাৰে সেইটো বিচাৰি উলিয়ায়।",
              "gu": "મીરા અને શાંતનુએ ગેમ ડેવલપમેન્ટ પ્રક્રિયાને સમજવા માટે ઓનલાઈન વીડિયો જોયા છે. તેઓ સાથે મળીને ડિઝાઇન કરે છે કે તેઓ કેવી રીતે વિડિયો ગેમ્સ બનાવી શકે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_2///",
              "hi": "C3_1_2///",
              "ka": "C3_1_2///",
              "te": "C3_1_2///",
              "or": "C3_1_2///",
              "as": "C3_1_2///",
              "gu": "C3_1_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Mira is slowly able to make a simple game using C++ coding. However, her game is not easy to understand.",
              "hi": "मीरा धीरे-धीरे सी ++ कोडिंग का उपयोग करके एक साधारण गेम बनाने में सक्षम होती है। हालांकि, उसके खेल को समझना सरल नहीं है।",
              "ka": "ಮೀರಾ ನಿಧಾನವಾಗಿ C ++ ಕೋಡಿಂಗ್ ಅನ್ನು ಬಳಸಿಕೊಂಡು ಸರಳವಾದ ಆಟವನ್ನು ಮಾಡಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ. ಆದರೆ, ಆಕೆಯ ಆಟ ಸುಲಭವಾಗಿ ಅರ್ಥವಾಗುವುದಿಲ್ಲ.",
              "te": "మీరా నెమ్మదిగా C++ కోడింగ్‌ని ఉపయోగించి ఒక సాధారణ గేమ్‌ను చేస్తుంది. అయితే, ఆమె చేసిన గేమ్ అర్ధం చేసుకోవడం అంత తేలిక కాదు.",
              "or": "ମୀରା ଧୀରେ ଧୀରେ C ++ କୋଡିଂ ବ୍ୟବହାର କରି ଏକ ସରଳ ଖେଳ ତିଆରି \n କରିବାରେ ସକ୍ଷମ | ତଥାପି, ତାଙ୍କ ଖେଳ ବୁଝିବା ସହଜ ନୁହେଁ |",
              "as": "মীৰাই লাহে লাহে চি++ কোডিং ব্যৱহাৰ কৰি এটা সৰল গেম বনাবলৈ পৰা হয়। কিন্তু, তাইৰ খেলটো বুজিবলৈ সহজ নহয়।",
              "gu": "મીરા ધીમે ધીમે C++ કોડિંગનો ઉપયોગ કરીને એક સરળ ગેમ બનાવવામાં સક્ષમ છે. જો કે, તેની રમત સમજવી સરળ નથી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_3 bg",
              "hi": "C3_1_3 बीजी",
              "ka": "C3_1_3 bg",
              "te": "C3_1_3 bg",
              "or": "C3_1_3 bg",
              "as": "C3_1_3 bg",
              "gu": "C3_1_3 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shantanu",
              "hi": "शांतनु",
              "ka": "ಶಂತನು",
              "te": "షాంతనుడు",
              "or": "ଶାନ୍ତନୁ",
              "as": "শান্তনু",
              "gu": "શાંતનુ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Didi, what is this? Without onboarding your player, how do you think they’ll understand it?",
              "hi": "दीदी, यह क्या है? अपने खिलाड़ी को ऑनबोर्ड किए बिना, आपको कैसे लगता है कि वे इसे समझेंगे?",
              "ka": "ದೀದಿ, ಇದು ಏನು? ನೀನು ಪ್ಲೇಯರ್ ಅನ್ನು ಆನ್‌ಬೋರ್ಡ್ ಮಾಡದೆಯೇ, ಅವರು ಅದನ್ನು ಹೇಗೆ ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ ಎಂದು ನೀನು ಭಾವಿಸುತ್ತೀ?",
              "te": "దీదీ, ఇది ఏమిటి? మీ ప్లేయర్‌ని ఆన్‌బోర్డ్ చేయకుండా ఉంటే, వారు దానిని ఎలా అర్థం చేసుకుంటారని నువ్వు అనుకుంటున్నావు?",
              "or": "ଦିଦି, ଏହା କ’ଣ? ତୁମର ପ୍ଲେୟାରକୁ ଅନ୍ ବୋର୍ଡିଙ୍ଗ ନକରି, ସେମାନେ କିପରି ବୁଝିବେ ବୋଲି ଆପଣ ଭାବୁଛନ୍ତି?",
              "as": "বা, এয়া কি? তোমাৰ খেলুৱৈক অনবৰ্ডিং নকৰাকৈ, তুমি কেনেকৈ ভাবিছা যে তেওঁলোকে এইটো বুজি পাব?",
              "gu": "દીદી, આ શું છે? તારા પ્લેયરને ઉપર લાવ્યા વિના, તું કેવી રીતે વિચારે છે કે તેઓ તેને સમજી શકશે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "What do you mean by onboarding?",
              "hi": "ऑनबोर्डिंग से तुम्हारा क्या मतलब है?",
              "ka": "ನೀನು ಹೇಳುವ ಆನ್ ಬೋರ್ಡಿಂಗ್ ಅಂದರೆ ಏನು?",
              "te": "ఆన్‌బోర్డింగ్ అంటే ఏమిటి?",
              "or": "ଅନ ବୋର୍ଡିଂ ଦ୍ୱାରା ଆପଣ କ’ଣ କହିବାକୁ ଚାହୁଁଛନ୍ତି?",
              "as": "অনবোৰ্ডিং মানে কি?",
              "gu": "ઓનબોર્ડિંગ દ્વારા તમારો અર્થ શું છે?"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_4///",
              "hi": "C3_1_4///",
              "ka": "C3_1_4///",
              "te": "C3_1_4///",
              "or": "C3_1_4///",
              "as": "C3_1_4///",
              "gu": "C3_1_4///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "It means you need to instruct the players on playing your game. You can add tutorials or simple introductions on 'how to play'.",
              "hi": "इसका मतलब है कि आपको अपने खेल को खेलने के लिए खिलाड़ियों को निर्देश देने की आवश्यकता है। आप 'कैसे खेलें' पर ट्यूटोरियल या सरल परिचय दे सकते हैं।",
              "ka": "ನಿನ್ನ ಆಟವನ್ನು ಆಡುವ ಬಗ್ಗೆ ನೀನು ಆಟಗಾರರಿಗೆ ಸೂಚನೆ ನೀಡಬೇಕು ಎಂದರ್ಥ. ನೀನು ಟ್ಯುಟೋರಿಯಲ್ ಅಥವಾ ಸರಳ ಪರಿಚಯಗಳನ್ನು ಸೇರಿಸಬಹುದು 'ಹೇಗೆ ಆಡುವುದು' ಎಂದು.",
              "te": "అంటే నువ్వు నీ గేమ్‌ను ఆడేందుకు ఆటగాళ్లకు సూచించాలి. నువ్వు 'ఎలా ఆడాలి' అనే అంశంపై ట్యుటోరియల్స్ లేదా సాధారణ పరిచయాలను యాడ్ చేయవచ్చు.",
              "or": "ଏହାର ଅର୍ଥ ତୁମେ ତୁମର ଖେଳ ଖେଳିବା ପାଇଁ ଖେଳାଳୀଙ୍କୁ ନିର୍ଦ୍ଦେଶ ଦେବା ଆବଶ୍ୟକ |\n  ଆପଣ 'କିପରି ଖେଳିବେ' ଉପରେ ଟ୍ୟୁଟୋରିଆଲ୍ କିମ୍ବା ସରଳ ପରିଚୟ ଯୋଗ କରିପାରିବେ |",
              "as": "তাৰ মানে হৈছে তোমাৰ খেলুৱৈসকলক তুমি খেল খেলিবলৈ নিৰ্দেশ দিব লাগিব। তুমি 'কেনেদৰে খেলিব লাগে' তাৰ ওপৰত টিউটোৰিয়েল বা সৰল পৰিচয় যোগ দিব পাৰা।",
              "gu": "તેનો અર્થ એ છે કે તમારે તમારી રમત રમવા માટે ખેલાડીઓને સૂચના આપવાની જરૂર છે. તું 'કેવી રીતે રમવું' પર ટ્યુટોરિયલ્સ અથવા સરળ પરિચય ઉમેરી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "That makes sense!",
              "hi": "यह सही कह रहे हो!",
              "ka": "ಅದು ಸರಿ ಅನಿಸುತ್ತದೆ!",
              "te": "ఇది అర్థవంతంగా ఉంది!",
              "or": "ଏହା ଠିକ ଜଣ ପଡୁଛି!",
              "as": "এতিয়া বুজিলোঁ!",
              "gu": "તે કઈક વાળું છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Also, your game looks dull! Let me see if I can help you add some animations. A little bit of colour and some nice sound effects will make it so much more enjoyable.",
              "hi": "इसके अलावा, आपका खेल ज्यादा रोचक नहीं लगता! मुझे देखने दीजिए यदि मैं कुछ एनिमेशन जोड़ने में आपकी सहायता कर सकूँ। थोड़ा सा रंग और कुछ अच्छे ध्वनि प्रभाव इसे और अधिक आनंददायक बना देंगे।",
              "ka": "ಅಲ್ಲದೆ, ನಿನ್ನ ಆಟವು ಅಷ್ಟೇನೂ ಅಂದವಾಗಿ ಕಾಣುವುದಿಲ್ಲ! ಕೆಲವು ಅನಿಮೇಷನ್‌ಗಳನ್ನು ಸೇರಿಸಲು ನಾನು ನಿಮಗೆ ಸಹಾಯ ಮಾಡಬಹುದೇ ಎಂದು ನೋಡೋಣ. ಸ್ವಲ್ಪ ಬಣ್ಣ ಮತ್ತು ಕೆಲವು ಉತ್ತಮವಾದ ಧ್ವನಿ ಪರಿಣಾಮಗಳು ಅದನ್ನು ಹೆಚ್ಚು ಆನಂದಿಸುವಂತೆ ಮಾಡುತ್ತದೆ.",
              "te": "అలాగే, నీ గేమ్ డల్ గా కనిపిస్తోంది! కొన్ని యానిమేషన్‌లను జోడించడంలో నేను నీకు సహాయం చేయగలnEమొ చూద్దాం. కొద్దిగా రంగు మరియు కొన్ని మంచి సౌండ్ ఎఫెక్ట్స్ దీన్ని మరింత ఆనందించేలా మారుస్తాయి.",
              "or": "ଆହୁରି ମଧ୍ୟ, ତୁମର ଖେଳ ଦୁର୍ବଳ ଦେଖାଯାଏ! ମୁଁ ଆପଣଙ୍କୁ କିଛି ଆନିମେସନ୍ ଯୋଡିବାରେ \n ସାହାଯ୍ୟ କରିପାରିବି କି ନାହିଁ ଦେଖିବା | ଟିକିଏ ରଙ୍ଗ ଏବଂ କିଛି ସୁନ୍ଦର ଧ୍ୱନି ପ୍ରଭାବ ଏହାକୁ ଅଧିକ ଉପଭୋଗ୍ୟ କରିବ |",
              "as": "লগতে, তোমাৰ খেলটো ৰস নোহোৱা যেন লাগিছে! মই কিছুমান এনিমেচন যোগ কৰাত সহায় কৰিব পাৰো নেকি চাওঁ আহা অলপ ৰং আৰু শব্দৰ কিছু প্ৰভাৱই ইয়াক বহুত বেছি ভাল কৰি তুলিব।",
              "gu": "પણ, તમારી રમત નિષ્ક્રિય લાગે છે! મને જોવા દો કે શું હું તને કેટલાક એનિમેશન ઉમેરવામાં મદદ કરી શકું છું. થોડો રંગ અને કેટલીક સરસ ધ્વનિ અસરો તેને વધુ આનંદપ્રદ બનાવશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "There’s so much work that goes into making a game, Shantanu.",
              "hi": " एक खेल बनाने के लिए बहुत अधिक काम करना होता है, शांतनु।",
              "ka": "ಶಂತನು ಒಂದು ಆಟವನ್ನು ವಿನ್ಯಾಸ ಮಾಡಲು ಎಷ್ಟು ಕೆಲಸ ಮಾಡ್ಬೇಕು ಅಲ್ಲವಾ.",
              "te": "ఆటను తయారు చేయడానికి చాలా పని ఉంది, షాంతాను.",
              "or": "ସେଠାରେ ବହୁତ କାମ ଅଛି ଯାହା ଏକ ଖେଳ ତିଆରି କରିବାକୁ ଯାଉଛି, ଶାନ୍ତନୁ |",
              "as": "শান্তনু, এটা খেল তৈয়াৰ কৰোতে বহুতো কাম থাকে ন।",
              "gu": "રમત બનાવવા માટે ઘણું કામ છે, શાંતનુ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Yes. Making a game is not as much fun as playing it didi.",
              "hi": "हां। एक खेल बनाना उतना मजेदार नहीं है जितना इसे खेलना।",
              "ka": "ಹೌದು. ದೀದಿ,ಆಟವನ್ನು ವಿನ್ಯಾಸ ಮಾಡುವುದು ಅದನ್ನು ಆಡುವಷ್ಟು ಮೋಜು ಅಲ್ಲ.",
              "te": "అవును దీదీ, ఆడినంత సరదాగా ఆటను తయారు చేయలేము.",
              "or": "ହଁ ଏକ ଖେଳ ତିଆରି କରିବା ଯେତିକି ମଜାଦାର ନୁହେଁ, ଖେଳିବା ସେତିକି ମଜାଦାର ଦିଦି|",
              "as": "হয়। গেম এটা বনোৱাটো গেম খেলাৰ দৰে ইমান আনন্দদায়ক নহয়।",
              "gu": "હા. રમત બનાવવાની એટલી મજા નથી જેટલી દીદીને રમવાની છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_5",
              "hi": "C3_1_5",
              "ka": "C3_1_5",
              "te": "C3_1_5",
              "or": "C3_1_5",
              "as": "C3_1_5",
              "gu": "C3_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "With Shantanu’s help and some practise, Mira finishes her first game! Both of them are happy.",
              "hi": "शांतनु के सहयोग और कुछ अभ्यास के साथ, मीरा ने अपना पहला गेम बना लिया! दोनों खुश हैं।",
              "ka": "ಶಂತನುವಿನ ಸಹಾಯ ಮತ್ತು ಸ್ವಲ್ಪ ಅಭ್ಯಾಸದಿಂದ, ಮೀರಾ ತನ್ನ ಮೊದಲ ಆಟದ ವಿನ್ಯಾಸವನ್ನೂ ಮುಗಿಸುತ್ತಾಳೆ! ಇಬ್ಬರೂ ಖುಷಿಯಾಗಿದ್ದಾರೆ.",
              "te": "షాంతాను సహాయంతో మరియు కొంత అభ్యాసంతో, మీరా తన మొదటి గేమ్‌ను పూర్తి చేసింది! ఇద్దరూ సంతోషంగా ఉన్నారు.",
              "or": "ଶାନ୍ତନୁଙ୍କ ସାହାଯ୍ୟ ଏବଂ କିଛି ଅଭ୍ୟାସ ସହିତ, ମୀରା ତାଙ୍କର ପ୍ରଥମ ଖେଳ ସମାପ୍ତ \n କଲା! ଦୁହେଁ ଖୁସି ଅଛନ୍ତି |",
              "as": "শান্তনুৰ সহায় আৰু অলপ চেষ্টাৰ ফলত মীৰাই তাইৰ প্ৰথমটো গেম বনাই শেষ কৰে! সিহঁত দুয়ো সুখী।",
              "gu": "શાંતનુની મદદ અને થોડી પ્રેક્ટિસ સાથે, મીરાએ તેની પ્રથમ રમત પૂરી કરી! બંને ખુશ છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_6",
              "hi": "C3_1_6",
              "ka": "C3_1_6",
              "te": "C3_1_6",
              "or": "C3_1_6",
              "as": "C3_1_6",
              "gu": "C3_1_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She continues creating more games, and Shantanu helps her make it more fun. Together they make around four games by the end of two months.",
              "hi": "वह और अधिक गेम बनाना जारी रखती है, उन्हें और अधिक रोचक बनाने में  शांतनु उसकी सहायता करता है।  वे दो महीने के अंत तक साथ में चार खेल बनाते हैं।",
              "ka": "ಅವಳು ಇನ್ನಷ್ಟು ಆಟಗಳನ್ನು ರಚಿಸುವುದನ್ನು ಮುಂದುವರೆಸುತ್ತಾಳೆ ಮತ್ತು ಶಂತನು ಅವಳಿಗೆ ಅದನ್ನು ಹೆಚ್ಚು ಮೋಜು ಮಾಡಲು ಸಹಾಯ ಮಾಡುತ್ತಾನೆ. ಎರಡು ತಿಂಗಳ ಅಂತ್ಯದ ವೇಳೆಗೆ ಅವರು ಒಟ್ಟಿಗೆ ನಾಲ್ಕು ಪಂದ್ಯಗಳನ್ನು ವಿನ್ಯಾಸ ಮಾಡುತ್ತಾರೆ.",
              "te": "ఆమె మరిన్ని గేమ్‌లను తయారుచేస్తూనే ఉంది మరియు షాంతాను దానిని మరింత సరదాగా ఉండేటట్టు చేయడానికి ఆమెకు సహాయం చేస్తున్నాడు. వారు కలిసి రెండు నెలల్లో దాదాపు నాలుగు గేమ్‌లు తయారు చేశారు.",
              "or": "ସେ ଅଧିକ ଖେଳ ସୃଷ୍ଟି ଜାରି ରଖିଛନ୍ତି, ଏବଂ ଶାନ୍ତନୁ ଏହାକୁ ଅଧିକ ମଜାଳିଆ କରିବାରେ\n  ସାହାଯ୍ୟ କରନ୍ତି | ଏକତ୍ର ସେମାନେ ଦୁଇମାସ ଶେଷ ସୁଦ୍ଧା ପ୍ରାୟ ଚାରିଟି ଖେଳ କରନ୍ତି |",
              "as": "তাই অধিক গেম বনাই আছে, আৰু শান্তনুৱে তাইক ইয়াক অধিক ভাল কৰাত সহায় কৰে। তেওঁলোকে একেলগে দুমাহত প্ৰায় চাৰিখন গেম বনাই শেষ কৰে।",
              "gu": "તે વધુ રમતો બનાવવાનું ચાલુ રાખે છે, અને શાંતનુ તેને વધુ મનોરંજક બનાવવામાં મદદ કરે છે. સાથે મળીને તેઓ બે મહિનાના અંત સુધીમાં લગભગ ચાર રમતો બનાવે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "Upskilling",
              "hi": "अपस्किलिंग",
              "ka": "ಉನ್ನತ ಕುಶಲತೆ",
              "te": "నైపుణ్యం పెంచడం",
              "or": "ଅପସ୍କିଲିଙ୍ଗ୍ |",
              "as": "আপস্কিলিং",
              "gu": "ઉચ્ચ કૌશલ્ય"
            }
          }
        ]
      },
      "scene26": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3ii_1_1",
              "hi": "C3ii_1_1",
              "ka": "C3ii_1_1",
              "te": "C3ii_1_1",
              "or": "C3ii_1_1",
              "as": "C3ii_1_1",
              "gu": "C3ii_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shantanu gives Mira the contact number of a professor in his college who has worked in a gaming company before. Mira is talking to the professor on chat to know more about the gaming industry.",
              "hi": "शांतनु मीरा को अपने कॉलेज के एक प्रोफेसर का संपर्क नंबर देता है जिन्होंने पहले गेमिंग कंपनी में काम किया है। मीरा गेमिंग इंडस्ट्री के बारे में अधिक जानने के लिए चैट पर प्रोफेसर से बात कर रही है।",
              "ka": "ಶಂತನು ಮೀರಾಗೆ ಮೊದಲು ಗೇಮಿಂಗ್ ಕಂಪನಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಿದ ತನ್ನ ಕಾಲೇಜಿನ ಪ್ರಾಧ್ಯಾಪಕರ ಸಂಪರ್ಕ ಸಂಖ್ಯೆಯನ್ನು ನೀಡುತ್ತಾನೆ. ಗೇಮಿಂಗ್ ಉದ್ಯಮದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಮೀರಾ ಚಾಟ್‌ನಲ್ಲಿ ಪ್ರಾಧ್ಯಾಪಕರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಿದ್ದಾಳೆ.",
              "te": "షాంతను మీరాకి తన కాలేజీలో గతంలో గేమింగ్ కంపెనీలో పనిచేసిన ఒక ప్రొఫెసర్ ఫోన్ నంబర్ ఇస్తాడు. గేమింగ్ కంపెనీ గురించి మరింత తెలుసుకోవడానికి మీరా ప్రొఫెసర్‌తో చాట్‌లో మాట్లాడుతోంది.",
              "or": "ଶାନ୍ତନୁ ମୀରାଙ୍କୁ ତାଙ୍କ କଲେଜର ଜଣେ ପ୍ରଫେସରଙ୍କ ଯୋଗାଯୋଗ ନମ୍ବର ଦିଅନ୍ତି ଯିଏ\n  ପୂର୍ବରୁ ଏକ ଖେଳ କମ୍ପାନୀରେ କାମ କରିଥିଲେ | ଖେଳ ଶିଳ୍ପ ବିଷୟରେ ଅଧିକ ଜାଣିବା ପାଇଁ ମୀରା ଚାଟରେ ପ୍ରଫେସରଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରୁଛନ୍ତି |",
              "as": "শান্তনুৱে মীৰাক তেওঁৰ মহাবিদ্যালয়ৰ এজন অধ্যাপকৰ ফোনৰ নম্বৰ দিয়ে যিয়ে আগতে এটা গেমিং কোম্পানীত কাম কৰিছিল। মীৰাই গেমিং উদ্যোগৰ বিষয়ে ভালকৈ জানিবলৈ অধ্যাপকৰ লগত মেছেজত কথা পাতি আছে।",
              "gu": "શાંતનુ મીરાને તેની કોલેજના એક પ્રોફેસરનો ફોન નંબર આપે છે જેઓ અગાઉ ગેમિંગ કંપનીમાં કામ કરી ચૂક્યા છે. મીરા ગેમિંગ ઈન્ડસ્ટ્રી વિશે વધુ જાણવા માટે પ્રોફેસર સાથે ફોન પર વાત કરી રહી છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3ii_1_2",
              "hi": "C3ii_1_2",
              "ka": "C3ii_1_2",
              "te": "C3ii_1_2",
              "or": "C3ii_1_2",
              "as": "C3ii_1_2",
              "gu": "C3ii_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.allMessages.0.messageText",
            "value": {
              "en": "I have never worked in a game development company. How difficult do you think it will be for me to shift into the game industry as a physics programmer?",
              "hi": "मैंने कभी एक गेम डेवलपमेंट कंपनी में काम नहीं किया है। आपके अनुसार एक फिजिक्स प्रोग्रामर के रूप में गेम इंडस्ट्री में बदलाव करना मेरे लिए कितना मुश्किल होगा?",
              "ka": "ನಾನು ಎಂದಿಗೂ ಆಟ ಅಭಿವೃದ್ಧಿ ಕಂಪನಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಿಲ್ಲ. ಭೌತಶಾಸ್ತ್ರ ಪ್ರೋಗ್ರಾಮರ್ ಆಗಿ ಆಟದ ಉದ್ಯಮಕ್ಕೆ ಬದಲಾಗುವುದು ನನಗೆ ಎಷ್ಟು ಕಷ್ಟ ಎಂದು ನಿಮ್ಮ ಭಾವನೆ?",
              "te": "నేను ఇంతవరకు గేమ్ డెవలప్‌మెంట్ కంపెనీలో పని చేయలేదు. ఫిజిక్స్ ప్రోగ్రామర్‌ నుండి గేమ్ కంపెనీలోకి మారడం నేను ఎంత కష్టపడ్డానని మీరు అనుకుంటున్నారు?",
              "or": "ମୁଁ କଦାପି ଖେଳ ବିକାଶ କମ୍ପାନୀରେ କାମ କରି ନାହିଁ | ଆପଣ ଭାବୁଥିବେ ଯେ ପଦାର୍ଥ \n ବିଜ୍ଞାନ ପ୍ରୋଗ୍ରାମର ଭାବରେ ଖେଳ ଶିଳ୍ପକୁ ଯିବା ମୋ ପାଇଁ କେତେ କଷ୍ଟକର?",
              "as": "মই কেতিয়াও গেম ডেভেলপমেণ্ট কোম্পানীত কাম কৰা নাই। পদাৰ্থ বিজ্ঞানৰ প্ৰগ্ৰেমাৰ হিচাপে গেম উদ্যোগলৈ আহি যোৱাটো মোৰ কাৰনে কিমান কঠিন হ'ব বুলি আপুনি ভাবে?",
              "gu": "મેં ક્યારેય ગેમ ડેવલપમેન્ટ કંપનીમાં કામ કર્યું નથી. તમને લાગે છે કે ફિઝિક્સ પ્રોગ્રામર તરીકે રમત ઉદ્યોગમાં શિફ્ટ થવું મારા માટે કેટલું મુશ્કેલ હશે?"
            }
          },
          {
            "valuePath": "blocks.0.props.allMessages.1.messageText",
            "value": {
              "en": "Knowing how to code is the first step. With that, you can get a job as a programmer. However, you may not get to work directly on game development right away.",
              "hi": "कोड कैसे करना है, इसको जानना पहला कदम है। इसके साथ, आप एक प्रोग्रामर के रूप में नौकरी प्राप्त कर सकते हैं। हालांकि, आप  गेम डेवलपमेंट पर सीधे काम नहीं कर सकते हैं।",
              "ka": "ಕೋಡ್ ಮಾಡುವುದು ಹೇಗೆ ಎಂದು ತಿಳಿದುಕೊಳ್ಳುವುದು ಮೊದಲ ಹಂತವಾಗಿದೆ. ಅದರೊಂದಿಗೆ, ನೀವು ಪ್ರೋಗ್ರಾಮರ್ ಆಗಿ ಕೆಲಸ ಪಡೆಯಬಹುದು. ಆದಾಗ್ಯೂ, ನೀವು ಈಗಿನಿಂದಲೇ ಆಟದ ಅಭಿವೃದ್ಧಿಯಲ್ಲಿ ನೇರವಾಗಿ ಕೆಲಸ ಮಾಡಲು ಸಾಧ್ಯವಾಗದಿರಬಹುದು.",
              "te": "కోడ్ ఎలా చేయాలో తెలుసుకోవడం మొదటి స్టెప్. దాంతో ప్రోగ్రామర్‌గా ఉద్యోగం పొందవచ్చు. అయితే, మీరు వెంటనే గేమ్ లని అభివృద్ధి చేయడానికి నేరుగా పని చేయలేరు.",
              "or": "କିପରି କୋଡ୍ କରିବେ ଜାଣିବା ହେଉଛି ପ୍ରଥମ ପଦକ୍ଷେପ | ତାହା ସହିତ, ଆପଣ ଏ\n କ ପ୍ରୋଗ୍ରାମର୍ ଭାବରେ ଚାକିରି ପାଇପାରିବେ | ତଥାପି, ଆପଣ ତୁରନ୍ତ ଖେଳ ବିକାଶ ଉପରେ ସିଧାସଳଖ କାମ କରିବାକୁ ପାଇପାରନ୍ତି ନାହିଁ |",
              "as": "কোড কেনেদৰে কৰিব লাগে জনাটো হৈছে প্ৰথম পদক্ষেপ। ইয়াৰ লগতে, তুমি প্ৰগ্ৰামাৰ হিচাপেও চাকৰি এটা পাব পাৰা। পিছে, তুমি লগে লগে গেম বিকাশত পোনপটীয়াকৈ কাম কৰিব নোৱাৰিব পাৰা",
              "gu": "કોડ કેવી રીતે બનાવવો તે જાણવું એ પ્રથમ પગલું છે. તેની સાથે, તું પ્રોગ્રામર તરીકે નોકરી મેળવી શકે છો. જો કે, તું તરત જ રમતના વિકાસ પર સીધું કામ કરી શકશ નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.allMessages.2.messageText",
            "value": {
              "en": "Once you start working, there’ll be opportunities to move into the role of a physics programmer. You’ll need to practise and learn more on the job to learn the fundamentals. It would help if you looked at spaces such as Youtube videos or other online courses to learn these.",
              "hi": "एक बार काम शुरू करने के बाद, फिजिक्स प्रोग्रामर के पद पर नौकरी मिलने के अवसर मिलेंगे। नौकरी में और अधिक जानने के लिए आपको  बुनियादी बातों को सीखने की आवश्यकता होगी। यदि आप इनको सीखने के लिए यू ट्यूब वीडियो या अन्य ऑनलाइन कोर्स जैसे माध्यमों  को देखते हैं तो यह  सहायक होगा।",
              "ka": "ಒಮ್ಮೆ ನೀವು ಕೆಲಸ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿದರೆ, ಭೌತಶಾಸ್ತ್ರದ ಪ್ರೋಗ್ರಾಮರ್ ಪಾತ್ರಕ್ಕೆ ಹೋಗಲು ಅವಕಾಶಗಳಿವೆ. ಮೂಲಭೂತ ಅಂಶಗಳನ್ನು ಕಲಿಯಲು ನೀವು ಕೆಲಸದಲ್ಲಿ ಹೆಚ್ಚು ಅಭ್ಯಾಸ ಮಾಡಬೇಕು ಮತ್ತು ಕಲಿಯಬೇಕು. ಇವುಗಳನ್ನು ಕಲಿಯಲು ನೀವು ಯೂ ಟ್ಯೂಬ್ ವೀಡಿಯೊಗಳು ಅಥವಾ ಇತರ ಆನ್‌ಲೈನ್ ಕೋರ್ಸ್‌ಗಳಂತಹ ಸ್ಥಳಗಳನ್ನು ನೋಡಿದರೆ ಅವು ಸಹಾಯ ಮಾಡುತ್ತವೆ.",
              "te": "మీరు పని చేయడం ప్రారంభించిన తర్వాత ఫిజిక్స్ ప్రోగ్రామర్ రోల్ లోకి వెళ్లే అవకాశాలు ఉంటాయి. ముఖ్యమైన విషయాలని తెలుసుకోవడానికి మీరు ఉద్యోగంలో మరింత అభ్యాసం చేయాలి మరియు నేర్చుకోవాలి. మీరు వీటిని నేర్చుకోవడానికి యూట్యూబ్ వీడియోలు లేదా ఇతర ఆన్‌లైన్ కోర్సుల వంటి స్పేస్‌ లు చూస్తే సహాయపడతాయి.",
              "or": "ଥରେ ଆପଣ କାମ ଆରମ୍ଭ କରିବା ପରେ, ଏକ ପଦାର୍ଥ ବିଜ୍ଞାନ ପ୍ରୋଗ୍ରାମରଙ୍କ ଭୂମିକାରେ\n  ଯିବାର ସୁଯୋଗ ମିଳିବ | ମ ament ଳିକତା ଶିଖିବା ପାଇଁ ତୁମକୁ ଅଭ୍ୟାସ ଏବଂ ଚାକିରି ଉପରେ ଅଧିକ ଶିଖିବାକୁ ପଡିବ | ଯଦି ଆପଣ ଏଗୁଡିକ ଶିଖିବା ପାଇଁ ୟୁଟ୍ୟୁବ୍ ଭିଡିଓ କିମ୍ବା ଅନ୍ୟାନ୍ୟ ଅନଲାଇନ୍ ପାଠ୍ୟକ୍ରମ ପରି ସ୍ଥାନଗୁଡିକୁ ଦେଖନ୍ତି ତେବେ ଏହା ସାହାଯ୍ୟ କରିବ |",
              "as": "তুমি কাম কৰা আৰম্ভ কৰাৰ পিছত, পদাৰ্থ বিজ্ঞানৰ প্ৰগ্ৰামাৰ বনাৰ সুযোগ থাকিব। চাকৰি কথা ভালকৈ শিকিবলৈ অনুশীলন কৰিব লাগিব আৰু কামটোৰ বিষয়ে শিকিব লাগিব। যদি তুমি এইবোৰ শিকিবলৈ ইউটিউব ভিডিঅ' বা অন্যান্য অনলাইন পাঠ্যক্ৰমবোৰ চাব পাৰা ই সহায় কৰিব।",
              "gu": "એકવાર તું કામ કરવાનું શરૂ કર, પછી ભૌતિકશાસ્ત્ર પ્રોગ્રામરની ભૂમિકામાં જવાની તકો હશે. મૂળભૂત બાબતો શીખવા માટે તારે નોકરી પર પ્રેક્ટિસ કરવાની અને વધુ શીખવાની જરૂર પડશે. જો તું આ શીખવા માટે યુટ્યુબ વિડિયો અથવા અન્ય ઑનલાઇન અભ્યાસક્રમો જેવી જગ્યાઓ જોશે તો તે મદદરૂપ થશે."
            }
          }
        ]
      },
      "scene27": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_1_1",
              "hi": "C4_1_1",
              "ka": "C4_1_1",
              "te": "C4_1_1",
              "or": "C4_1_1",
              "as": "C4_1_1",
              "gu": "C4_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Initially, Mira finds it challenging to upskill herself on game programming because she has to manage her job as well. But with Shantanu's help, she makes a lot of progress.",
              "hi": "प्रारंभ में, मीरा को गेम प्रोग्रामिंग पर खुद को अपस्किल करना चुनौतीपूर्ण लगता है क्योंकि उसे अपनी नौकरी का प्रबंधन भी करना है। लेकिन शांतनु के सहयोग से, वह बहुत प्रगति करती है।",
              "ka": "ಆರಂಭದಲ್ಲಿ, ಮೀರಾ ತನ್ನ ಕೆಲಸವನ್ನು ಸಹ ನಿರ್ವಹಿಸಬೇಕಾಗಿರುವುದರಿಂದ ಆಟದ ಪ್ರೋಗ್ರಾಮಿಂಗ್‌ನಲ್ಲಿ ತನ್ನನ್ನು ತಾನು ಪರಿಣತಿ ಗಳಿಸುವ ಸವಾಲನ್ನು ಎದುರಿಸುತ್ತಾಳೆ. ಆದರೆ ಶಂತನುವಿನ ಸಹಾಯದಿಂದ ಅವಳು ಸಾಕಷ್ಟು ಪ್ರಗತಿಯನ್ನು ಸಾಧಿಸುತ್ತಾಳೆ.",
              "te": "ఆమె తన జాబ్ కూడా చూసుకోవాలి కాబట్టి మొదట్లో మీరాకు గేమ్ ప్రోగ్రామింగ్‌లో నైపుణ్యం పెంచుకోవడం కష్టంగా ఉంది. కానీ షాంతను సహాయంతో ఆమె చాలా అభివృద్ధి చెందుతుంది.",
              "or": "ପ୍ରାରମ୍ଭରେ, ମୀରା ନିଜକୁ ଖେଳ ପ୍ରୋଗ୍ରାମିଂରେ ନିଜକୁ ଉନ୍ନତ କରିବା ଏକ ଚ୍ୟାଲେଞ୍ଜ\n  ବୋଲି ମନେ କରନ୍ତି କାରଣ ତାଙ୍କୁ ମଧ୍ୟ ତାଙ୍କ କାର୍ଯ୍ୟ ପରିଚାଳନା କରିବାକୁ ପଡିବ | କିନ୍ତୁ ଶାନ୍ତନୁଙ୍କ ସାହାଯ୍ୟରେ ସେ ବହୁତ ଅଗ୍ରଗତି କରନ୍ତି |",
              "as": "আৰম্ভণিতে, মীৰাই গেম প্ৰ'গ্ৰামিংত নিজকে আপস্কিল কৰাটো টান বুলি ভাবে কাৰন তাই তাইৰ চাকৰিটোও চলায় থাকিব লাগিব। কিন্তু শান্তনুৰ সহায়ত, তাই যথেষ্ট উন্নতি কৰে।",
              "gu": "શરૂઆતમાં, મીરાને ગેમ પ્રોગ્રામિંગમાં પોતાની જાતને અપકુશળ બનાવવી પડકારજનક લાગે છે કારણ કે તેને તેની નોકરી પણ સંચાલન કરવી પડે છે. પરંતુ શાંતનુની મદદથી તે ઘણી પ્રગતિ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_1_2",
              "hi": "C4_1_2",
              "ka": "C4_1_2",
              "te": "C4_1_2",
              "or": "C4_1_2",
              "as": "C4_1_2।",
              "gu": "C4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shantanu returns to his college after his break, but he continues to help Mira through video calls. It takes Mira 6 months until she feels ready to apply for the role.",
              "hi": "शांतनु ब्रेक के बाद अपने कॉलेज लौट गया, लेकिन वह वीडियो कॉल के माध्यम से मीरा की सहायता करना जारी रखता है। मीरा को  6 महीने लग जाते हैं नौकरी के लिए आवेदन करने हेतु अपने को तैयार करने में।",
              "ka": "ಶಂತನು ತನ್ನ ರಜೆಯ ನಂತರ ಕಾಲೇಜಿಗೆ ಹಿಂದಿರುಗುತ್ತಾನೆ, ಆದರೆ ಅವನು ವೀಡಿಯೊ ಕರೆಗಳ ಮೂಲಕ ಮೀರಾಗೆ ಸಹಾಯ ಮಾಡುವುದನ್ನು ಮುಂದುವರೆಸುತ್ತಾನೆ. ಮೀರಾ ಕೆಲಸಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಸಿದ್ಧವಾಗಲು 6 ತಿಂಗಳು ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ.",
              "te": "షాంతను సెలవుల తర్వాత తన కళాశాలకు తిరిగి వస్తాడు, కానీ అతను వీడియో కాల్స్ ద్వారా మీరాకు సహాయం చేస్తూనే ఉంటాడు. మీరా జాబ్ కోసం అప్లై చేసుకోవడానికి సిద్ధం అవ్వడానికి 6 నెలలు పడుతుంది.",
              "or": "ବିରତି ପରେ ଶାନ୍ତନୁ ତାଙ୍କ କଲେଜକୁ ଫେରିଛନ୍ତି, କିନ୍ତୁ ଭିଡିଓ କଲ ମାଧ୍ୟମରେ ସେ\n  ମୀରାଙ୍କୁ ସାହାଯ୍ୟ ଜାରି ରଖିଛନ୍ତି | ସେ ଭୂମିକା ପାଇଁ ଆବେଦନ କରିବାକୁ ପ୍ରସ୍ତୁତ ନହେବା ପର୍ଯ୍ୟନ୍ତ ମୀରାଙ୍କୁ 6 ମାସ ସମୟ ଲାଗେ |",
              "as": "শান্তনু বন্ধৰ পিছত নিজৰ কলেজলৈ উভতি আহে, কিন্তু সি ভিডিঅ' কলতে মীৰাক সহায় কৰি থাকে। পদটোৰ বাবে আবেদন কৰিবলৈ মীৰাক ৬ মাহ সময় লাগে।",
              "gu": "શાંતનુ તેના વિરામ પછી તેની કૉલેજમાં પાછો ફરે છે, પરંતુ તે વીડિયો કૉલ્સ દ્વારા મીરાને મદદ કરવાનું ચાલુ રાખે છે. મીરાને ભૂમિકા માટે અરજી કરવા માટે તૈયાર થવામાં 6 મહિના લાગે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_1",
              "hi": "C4_2_1",
              "ka": "C4_2_1",
              "te": "C4_2_1",
              "or": "C4_2_1",
              "as": "C4_2_1",
              "gu": "C4_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She returns to the job profiles she has seen. Although she found the profile of a physics programmer more interesting, she is more confident with the role of a C++ programmer.",
              "hi": "वह नौकरी प्रोफाइल में लौटती है जिसे उसने देखा है। यद्यपि उसे एक फिजिक्स प्रोग्रामर की प्रोफ़ाइल अधिक रोचक लगी, वह सी ++ प्रोग्रामर के पद की नौकरी ले लिए अधिक विश्वस्त है।",
              "ka": "ಅವಳು ನೋಡಿದ ಕೆಲಸದ ಪ್ರೊಫೈಲ್‌ಗಳನ್ನು ಮತ್ತೆ ನೋಡಲು ಶುರು ಮಾಡುತ್ತಾಳೆ. ಅವಳು ಭೌತಶಾಸ್ತ್ರದ ಪ್ರೋಗ್ರಾಮರ್ನ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಹೆಚ್ಚು ಆಸಕ್ತಿಕರವೆಂದು ಕಂಡುಕೊಂಡರೂ ಕೂಡ, ಸಿ ಪ್ರೋಗ್ರಾಮರ್ನ ಕೆಲಸದ ಬಗ್ಗೆ ಹೆಚ್ಚು ವಿಶ್ವಾಸ ಹೊಂದಿದ್ದಾಳೆ.",
              "te": "ఆమె గతంలోనే చూసిన జాబ్ ప్రొఫైల్‌లకు మళ్ళి చూస్తుంది. ఆమె ఫిజిక్స్ ప్రోగ్రామర్ యొక్క ప్రొఫైల్‌ను మరింత ఆసక్తికరంగా కనుగొన్నప్పటికీ, ఆమె C++ ప్రోగ్రామర్ జాబ్ పై మరింత నమ్మకంగా ఉంది.",
              "or": "ସେ ଦେଖିଥିବା ଚାକିରି ପ୍ରୋଫାଇଲକୁ ଫେରି ଆସନ୍ତି | ଯଦିଓ ସେ ଏକ ପଦାର୍ଥ ବିଜ୍ଞାନ\n  ପ୍ରୋଗ୍ରାମରର ପ୍ରୋଫାଇଲ୍କୁ ଅଧିକ ଆକର୍ଷଣୀୟ ପାଇଲେ, ସେ ଏକ C ++ ପ୍ରୋଗ୍ରାମରଙ୍କ ଭୂମିକା ସହିତ ଅଧିକ ଆତ୍ମବିଶ୍ୱାସୀ |",
              "as": "তাই দেখা চাকৰিৰ প্ৰফাইললৈ ঘূৰি আহে। যদিও ততাই পদাৰ্থ বিজ্ঞানৰ প্ৰগ্ৰামাৰ এটাৰ পদটো বেছি ভাল পাইছিল, তেওঁ চি++ প্ৰগ্ৰেমাৰৰ হবলৈ আত্মবিশ্বাসী।",
              "gu": "તેને જોયેલી નોકરીની લયકાત પર પાછી ફરે છે. જો કે તેને ભૌતિકશાસ્ત્રના પ્રોગ્રામરની પ્રોફાઇલ વધુ રસપ્રદ લાગી, તે C++ પ્રોગ્રામરની ભૂમિકામાં વધુ વિશ્વાસ ધરાવે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_2",
              "hi": "C4_2_2",
              "ka": "C4_2_2",
              "te": "C4_2_2",
              "or": "C4_2_2",
              "as": "C4_2_2",
              "gu": "C4_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She knows that physics has always been her passion, and to have a chance to do that for a job would be a dream. But she has limited experience with the technical knowledge required for the job.",
              "hi": "वह जानती है कि फिजिक्स हमेशा उसका जुनून रहा है, और नौकरी के लिए ऐसा अवसर मिलना एक सपना होगा। लेकिन उसके पास नौकरी के लिए आवश्यक तकनीकी ज्ञान के साथ अनुभव सीमित  है।",
              "ka": "ಭೌತಶಾಸ್ತ್ರವು ಯಾವಾಗಲೂ ಅವಳಿಗೆ ಇಷ್ಟವಾದುದು ಎಂದು ಅವಳಿಗೆ ತಿಳಿದಿದೆ ಹಾಗೂ ಉದ್ಯೋಗಕ್ಕಾಗಿ ಅದನ್ನು ಉಪಯೋಗಿಸಿಕೊಳ್ಳುವ  ಅವಕಾಶವನ್ನು ಹೊಂದುವುದು ಅವಳ ಒಂದು ಕನಸಾಗಿರುತ್ತದೆ. ಆದರೆ ಆಕೆಗೆ ಉದ್ಯೋಗಕ್ಕೆ ಬೇಕಾದ ತಾಂತ್ರಿಕ ಜ್ಞಾನದಲ್ಲಿ ಸೀಮಿತ ಅನುಭವ ಇದೆ.",
              "te": "భౌతికశాస్త్రం ఎప్పుడూ తనకి ఇష్టమైనదని, ఉద్యోగం కోసం అలా చేసే అవకాశం కలగడం ఒక కల అని ఆమెకు తెలుసు. కానీ ఉద్యోగానికి అవసరమైన సాంకేతిక పరిజ్ఞానం మీద ఆమెకు తక్కువ అనుభవం ఉంది.",
              "or": "ସେ ଜାଣନ୍ତି ଯେ ପଦାର୍ଥ ବିଜ୍ଞାନ ସର୍ବଦା ତାଙ୍କର ଆଗ୍ରହ ଥିଲା, ଏବଂ \n ଏକ ଚାକିରି ପାଇଁ ଏପରି କରିବାର ସୁଯୋଗ ପାଇବା ଏକ ସ୍ୱପ୍ନ ହେବ | କିନ୍ତୁ ଚାକିରି ପାଇଁ ଆବଶ୍ୟକ ବଇଷୟିକ ଜ୍ଞାନ ସହିତ ତାଙ୍କର ସୀମିତ ଅଭିଜ୍ଞତା ଅଛି |",
              "as": "তাই জানে যে পদাৰ্থ বিজ্ঞান সদায়ে তাইৰ আগ্ৰহ আছিল, আৰু চাকৰিএটাৰ কাৰনে সেইটো কৰাৰ সুযোগ পোৱাটো এটা সপোনৰ দৰে। কিন্তু চাকৰিটোৰ বাবে দৰকাৰি কাৰিকৰী জ্ঞানৰ লগত তাইৰ কম অভিজ্ঞতা আছে।",
              "gu": "તે જાણે છે કે ભૌતિકશાસ્ત્ર હંમેશા તેનો જુસ્સો રહ્યો છે, અને નોકરી માટે તે કરવાની તક મેળવવી એ એક સ્વપ્ન હશે. પરંતુ તેની પાસે નોકરી માટે જરૂરી તકનીકી જ્ઞાન સાથે મર્યાદિત અનુભવ છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_3",
              "hi": "C4_2_3",
              "ka": "C4_2_3",
              "te": "C4_2_3",
              "or": "C4_2_3",
              "as": "C4_2_3",
              "gu": "C4_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She has also been enjoying coding small games using C++ and has a good portfolio to show.",
              "hi": "वह सी ++ का उपयोग करके छोटे खेलों में कोडिंग का भी आनंद ले रही है और इसमें दिखाने के लिए एक अच्छा पोर्टफोलियो है।",
              "ka": "ಅವಳು ಸಿ ++ ಬಳಸಿ ಸಣ್ಣ ಆಟಗಳನ್ನು ಕೋಡಿಂಗ್ ಮಾಡುವುದನ್ನು ಆನಂದಿಸ್ತಾಳೆ ಹಾಗೂ ಅದನ್ನು ತೋರಿಸಲು ಉತ್ತಮ ಪೋರ್ಟ್‌ಫೋಲಿಯೊವನ್ನು ಕೂಡ ಹೊಂದಿದ್ದಾಳೆ.",
              "te": "ఆమె C++ని ఉపయోగించి చిన్న గేమ్‌లను కోడింగ్ చేయడం కూడా ఆనందిస్తోంది మరియు పోర్ట్‌ఫోలియో కూడా చూడటానికి బాగుంది.",
              "or": "ସେ C ++ ବ୍ୟବହାର କରି ଛୋଟ ଖେଳଗୁଡିକର କୋଡିଂକୁ ମଧ୍ୟ ଉପଭୋଗ କରୁଛନ୍ତି ଏବଂ ଦେଖାଇବାକୁ ଏକ ଭଲ ପୋର୍ଟଫୋଲିଓ ଅଛି |",
              "as": "তাই চি++ ব্যৱহাৰ কৰি সৰু গেমৰ কোডিং কৰি ভাল পাই আছে আৰু দেখুৱাবলৈ এটা ভাল পৰ্টফলিঅ' আছে।",
              "gu": "તે C++ નો ઉપયોગ કરીને નાની રમતો કોડિંગનો પણ આનંદ માણી રહી છે અને બતાવવા માટે તેની પાસે સારો પોર્ટફોલિયો છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_4 D",
              "hi": "C4_2_4 D",
              "ka": "C4_2_4 D",
              "te": "C4_2_4 D",
              "or": "C4_2_4 D",
              "as": "C4_2_4 D",
              "gu": "C4_2_4 D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "Which role should she apply for?",
              "hi": "उसे किस पद के लिए आवेदन करना चाहिए?",
              "ka": "ಅವಳು ಯಾವ ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಹಾಕಬೇಕು?",
              "te": "ఆమె ఏ రోల్ కోసం అప్లై చేయాలి?",
              "or": "ସେ କେଉଁ ଭୂମିକା ପାଇଁ ଆବେଦନ କରିବା ଉଚିତ୍?",
              "as": "তাই কোনটো কামৰ কাৰনে আবেদন কৰা ভাল হব?",
              "gu": "તેને કઈ ભૂમિકા માટે અરજી કરવી જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Apply for the Physics Programmer role",
              "hi": "फिजिक्स प्रोग्रामर के पद के लिए आवेदन करे",
              "ka": "ಭೌತ ಶಾಸ್ತ್ರ  ಪ್ರೋಗ್ರ್ಯಾಮರ್ ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಹಾಕುವುದೇ",
              "te": "ఫిజిక్స్ ప్రోగ్రామర్ రోల్ కోసం అప్లై చేసుకోండి",
              "or": "ଫିଜିକ୍ସ ପ୍ରୋଗ୍ରାମର ଭୂମିକା ପାଇଁ ଆବେଦନ କରନ୍ତୁ |",
              "as": "পদাৰ্থ বিজ্ঞান প্ৰগ্ৰামাৰ বাবে আবেদন কৰক",
              "gu": "ભૌતિકશાસ્ત્ર પ્રોગ્રામરની ભૂમિકા માટે અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Apply for the C++ Programmer role",
              "hi": "C ++ प्रोग्रामर के पद के लिए आवेदन करे",
              "ka": "ಸಿ ++ ಪ್ರೋಗ್ರ್ಯಾಮರ್ ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಹಾಕುವುದೇ",
              "te": "C++ ప్రోగ్రామర్ రోల్ కోసం అప్లై చేసుకోండి",
              "or": "C ++ ପ୍ରୋଗ୍ରାମର ଭୂମିକା ପାଇଁ ଆବେଦନ କରନ୍ତୁ |",
              "as": "চি++ প্ৰগ্ৰেমাৰ বাবে আবেদন কৰক",
              "gu": "C++ પ્રોગ્રામરની ભૂમિકા માટે અરજી કરો"
            }
          }
        ]
      },
      "scene28": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Apply for the role of physics programmer",
              "hi": "फिजिक्स प्रोग्रामर के पद के लिए आवेदन करे",
              "ka": "ಭೌತ ಶಾಸ್ತ್ರ  ಪ್ರೋಗ್ರ್ಯಾಮರ್ ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಹಾಕುವುದೇ",
              "te": "ఫిజిక్స్ ప్రోగ్రామర్ రోల్ కోసం అప్లై చేసుకోండి",
              "or": "ପଦାର୍ଥ ବିଜ୍ଞାନ ପ୍ରୋଗ୍ରାମରଙ୍କ ଭୂମିକା ପାଇଁ ଆବେଦନ କରନ୍ତୁ |",
              "as": "পদাৰ্থ বিজ্ঞানৰ প্ৰগ্ৰেমাৰ বাবে আবেদন কৰক",
              "gu": "ભૌતિકશાસ્ત્ર પ્રોગ્રામરની ભૂમિકા માટે અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She applies to Fullstar games in Bangalore for the role of a physics programmer. She shares some samples of games she has coded herself in her application.",
              "hi": "वह फिजिक्स प्रोग्रामर के पद के लिए फुलस्टार गेम बैंगलोर में आवेदन करती है। वह अपने आवेदन में स्वयं कोड किए  कुछ नमूने साझा करती है।",
              "ka": "ಭೌತಶಾಸ್ತ್ರದ ಪ್ರೋಗ್ರಾಮರ್ ಕೆಲಸಕ್ಕಾಗಿ ಅವಳು ಬೆಂಗಳೂರಿನ ಫುಲ್‌ಸ್ಟಾರ್ ಆಟದ ಕಂಪನಿಗೆ ಅರ್ಜಿ ಹಾಕುತ್ತಾಳೆ.. ಅವಳು ತನ್ನ ಅಪ್ಲಿಕೇಶನ್‌ ಜೊತೆಗೆ ಸ್ವತಃ ಕೋಡ್ ಮಾಡಿದ ಆಟಗಳ ಕೆಲವು ಮಾದರಿಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "ఫిజిక్స్ ప్రోగ్రామర్ రోల్ కోసం ఆమె బెంగళూరులోని ఫుల్‌స్టార్ గేమ్‌ కంపెనీకి అప్లై చేసింది. ఆమె తన అప్లికేషన్‌లో తనకు తానుగా కోడ్ చేసిన కొన్ని గేమ్‌ల నమూనాలను ఉంచింది.",
              "or": "ସେ ପଦାର୍ଥ ବିଜ୍ଞାନ ପ୍ରୋଗ୍ରାମରଙ୍କ ଭୂମିକା ପାଇଁ ବାଙ୍ଗାଲୋରର ଫୁଲ୍ ଷ୍ଟାର୍ ଗେମ୍ସରେ\n  ଆବେଦନ କରନ୍ତି | ସେ ନିଜ ପ୍ରୟୋଗରେ ନିଜକୁ କୋଡ୍ କରିଥିବା ଖେଳର କିଛି ନମୁନା ଅଂଶୀଦାର କରନ୍ତି |",
              "as": "তাই পদাৰ্থ বিজ্ঞানৰ প্ৰগ্ৰেমাৰৰ পদত বাংগালোৰৰ ফুলষ্টাৰ গেমছত আবেদন কৰে। তাই তাইৰ আবেদনত নিজকে কোড কৰা গেম কিছুমানৰ নমুনা দেখায়।",
              "gu": "તે ભૌતિકશાસ્ત્ર પ્રોગ્રામરની ભૂમિકા માટે બેંગ્લોરમાં ફુલસ્ટાર ગેમ્સમાં અરજી કરે છે. તેને તેની એપ્લિકેશનમાં પોતાને કોડ કરેલ રમતોના કેટલાક નમૂનાઓ શેર કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_1_2",
              "hi": "C5_1_2",
              "ka": "C5_1_2",
              "te": "C5_1_2",
              "or": "C5_1_2",
              "as": "C5_1_2",
              "gu": "C5_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The company has called her for an online interview. The interviewer is testing her knowledge about physics, coding and game development.",
              "hi": "कंपनी ने उसे ऑनलाइन साक्षात्कार के लिए बुलाया है। साक्षात्कारकर्ता फिजिक्स, कोडिंग और गेम डेवलपमेंट के बारे में उसके ज्ञान का परीक्षण कर रहा है।",
              "ka": "ಕಂಪನಿಯು ಅವಳನ್ನು ಆನ್‌ಲೈನ್ ಸಂದರ್ಶನಕ್ಕೆ ಕರೆದಿದೆ. ಸಂದರ್ಶಕರು ಭೌತಶಾಸ್ತ್ರ, ಕೋಡಿಂಗ್ ಮತ್ತು ಗೇಮ್ ಡೆವಲಪ್‌ಮೆಂಟುಗಳ ಬಗ್ಗೆ ಅವಳ ಜ್ಞಾನವನ್ನು ಪರೀಕ್ಷಿಸುತ್ತಿದ್ದಾರೆ",
              "te": "కంపెనీ ఆమెను ఆన్‌లైన్ ఇంటర్వ్యూకి పిలిచింది. ఇంటర్వ్యూయర్ ఫిజిక్స్, కోడింగ్ మరియు గేమ్ డెవలప్‌మెంట్ గురించి ఆమెకున్న జ్ఞానాన్ని పరీక్షిస్తున్నారు.",
              "or": "ଏକ ଅନଲାଇନ୍ ସାକ୍ଷାତକାର ପାଇଁ କମ୍ପାନୀ ତାଙ୍କୁ ଡାକିଛି | ସାକ୍ଷାତକାର ପଦାର୍ଥ ବିଜ୍ଞାନ\n , କୋଡିଂ ଏବଂ ଖେଳ ବିକାଶ ବିଷୟରେ ତାଙ୍କ ଜ୍ଞାନ ପରୀକ୍ଷା କରୁଛି |",
              "as": "কোম্পানীটোৱে তাইক এটা অনলাইন সাক্ষাৎকাৰৰ কাৰনে মাতিছে। সাক্ষাৎকাৰ লওঁতাই পদাৰ্থ বিজ্ঞান, কোডিং আৰু গেম বনোৱাৰ উপৰত তাইৰ জ্ঞান পৰীক্ষা কৰি আছে।",
              "gu": "કંપનીએ તેને ઓનલાઈન ઈન્ટરવ્યુ માટે બોલાવ્યો છે. ઇન્ટરવ્યુઅર ભૌતિકશાસ્ત્ર, કોડિંગ અને રમત વિકાસ વિશે તેના જ્ઞાનનું પરીક્ષણ કરી રહી છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_1_3 bg",
              "hi": "C5_1_3 बीजी",
              "ka": "C5_1_3 bg",
              "te": "C5_1_3 bg",
              "or": "C5_1_3 bg",
              "as": "C5_1_3 bg",
              "gu": "C5_1_3 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Interviewer",
              "hi": "साक्षात्कारकर्ता",
              "ka": "ಸಂದರ್ಶನಕಾರ",
              "te": "ఇంటర్వ్యూయర్",
              "or": "ସାକ୍ଷାତକାର",
              "as": "সাক্ষাৎকাৰ লওঁতা",
              "gu": "ઇન્ટરવ્યુઅર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Interviewer",
              "hi": "साक्षात्कारकर्ता",
              "ka": "ಸಂದರ್ಶನಕಾರ",
              "te": "ఇంటర్వ్యూయర్",
              "or": "ସାକ୍ଷାତକାର",
              "as": "সাক্ষাৎকাৰ লওঁতা",
              "gu": "ઇન્ટરવ્યુઅર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Interviewer - Your work experience is good, Mira. But we usually hire people who have experience working in the gaming industry.",
              "hi": "साक्षात्कारकर्ता - आपका कार्य अनुभव अच्छा है, मीरा। लेकिन हम सामान्यतः उन लोगों को नौकरी पर लेते हैं जिनके पास गेमिंग इंडस्ट्री में काम करने का अनुभव होता है।",
              "ka": "ಸಂದರ್ಶನಕಾರರು - ನಿಮ್ಮ ಕೆಲಸದ ಅನುಭವ ಚೆನ್ನಾಗಿದೆ ಮೀರಾ ಆವರೆ. ಆದರೆ ನಾವು ಸಾಮಾನ್ಯವಾಗಿ ಗೇಮಿಂಗ್ ಉದ್ಯಮದಲ್ಲಿ ಕೆಲಸ ಮಾಡಿದ ಅನುಭವ ಹೊಂದಿರುವ ಜನರನ್ನು ಮಾತ್ರವೇ ನೇಮಿಸಿಕೊಳ್ಳುತ್ತೇವೆ.",
              "te": "ఇంటర్వ్యూయర్ - మీరా మీ పని అనుభవం బాగుంది, కానీ మేము సాధారణంగా గేమింగ్ కంపెనీలో పనిచేసిన అనుభవం ఉన్న వ్యక్తులను మాత్రమే తీసుకుంటాము.",
              "or": "ସାକ୍ଷାତକାର - ମୀରା, ତୁମର କାର୍ଯ୍ୟ ଅଭିଜ୍ଞତା ଭଲ | କିନ୍ତୁ ଆମେ ସାଧାରଣତ \n ଲୋକଙ୍କୁ ନିଯୁକ୍ତି ଦେଇଥାଉ ଯେଉଁମାନଙ୍କର ଖେଳ ଶିଳ୍ପରେ କାର୍ଯ୍ୟ କରିବାର ଅଭିଜ୍ଞତା ଅଛି |",
              "as": "সাক্ষাৎকাৰ লওঁতা - আপোনাৰ কামৰ অভিজ্ঞতা ভাল, মীৰা। কিন্তু আমি সাধাৰণতে গেমিং উদ্যোগত কাম কৰাৰ অভিজ্ঞতা থকা লোকসকলক নিয়োগ কৰোঁ।",
              "gu": "ઇન્ટરવ્યુઅર - તારો કામનો અનુભવ સારો છે મીરા. પરંતુ અમે સામાન્ય રીતે એવા લોકોને નોકરીએ રાખીએ છીએ જેમને ગેમિંગ ઉદ્યોગમાં કામ કરવાનો અનુભવ હોય."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Sir, I’ve been working on using C++ to code some games over the last couple of months. I am a quick learner.",
              "hi": "महोदय, मैं पिछले कुछ महीनों से गेम कोड करने के लिए सी ++ का उपयोग करने पर काम कर रही हूं। मैं जल्दी सीखने में सक्षम हूं।",
              "ka": "ಸರ್, ನಾನು ಕಳೆದ ಎರಡು ತಿಂಗಳುಗಳಲ್ಲಿ ಕೆಲವು ಆಟಗಳನ್ನು ಕೋಡ್ ಮಾಡಲು ಸಿ ++ ಅನ್ನು ಬಳಸುತ್ತಿದ್ದೇನೆ. ನಾನು ಬೇಗನೆ ಕಲಿಯುವವಳು.",
              "te": "సర్, నేను గత కొన్ని నెలలుగా కొన్ని గేమ్‌లను కోడ్ చేయడానికి C++ని ఉపయోగించే పనిలో ఉన్నాను. నేను ఏదైనా త్వరగా నేర్చుకోగలను.",
              "or": "ସାର୍, ମୁଁ ଗତ ଦୁଇମାସ ମଧ୍ୟରେ କିଛି ଗେମ୍ କୋଡ୍ କରିବାକୁ C ++ ବ୍ୟବହାର \n କରିବାରେ କାର୍ଯ୍ୟ କରୁଛି | ମୁଁ ଶୀଘ୍ର ଶିଖେ ।",
              "as": "চাৰ, মই যোৱা কেইমাহমানধৰি কিছুমান গেম কোড কৰিবলৈ চি++ ব্যৱহাৰ কৰাৰ ওপৰত কাম কৰি আছোঁ। মই সোনকালে কথাবোৰ শিকি লব পাৰোঁ।",
              "gu": "સર, હું છેલ્લા કેટલાક મહિનાઓથી કેટલીક રમતો કોડ કરવા માટે C++ નો ઉપયોગ કરવા પર કામ કરી રહી છું . હું ઝડપી શીખનાર છું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "What makes you feel you are a good fit for the role?",
              "hi": "आपको कैसे लगता है कि आप इस पद के लिए एक सही चुनाव हैं?",
              "ka": "ಈ ಕೆಲಸಕ್ಕೆ ನೀವು ಸೂಕ್ತರು ಎಂದು ನಿಮಗೆ ಅನಿಸಲು ಕಾರಣವೇನು?",
              "te": "మీరు ఈ జాబ్ కి సరిపోతారని మీకు అనిపించే విషయం ఏమిటి?",
              "or": "ତୁମେ ଭୂମିକା ପାଇଁ ଏକ ଭଲ ଫିଟ୍ ବୋଲି କ’ଣ ଅନୁଭବ କରାଏ?",
              "as": "কিহে আপোনাক অনুভৱ কৰায় যে আপুনি কামটো বাবে উপযুক্ত?",
              "gu": "તને શું લાગે છે કે તું ભૂમિકા માટે યોગ્ય છો?"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_1_4 d",
              "hi": "C5_1_4 d",
              "ka": "C5_1_4 d",
              "te": "C5_1_4 డి",
              "or": "C5_1_4 d",
              "as": "C5_1_4 d",
              "gu": "C5_1_4 ડી"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Mira say?",
              "hi": "मीरा को क्या कहना चाहिए?",
              "ka": "ಮೀರಾ ಏನು ಹೇಳಬೇಕು?",
              "te": "మీరా ఏం చెప్పాలి?",
              "or": "ମୀରା କ’ଣ କହିବା ଉଚିତ୍?",
              "as": "মীৰাই কি কব লাগে?",
              "gu": "મીરાએ શું કહેવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "I always had a good understanding of physics as a child. Combining that with my experience in programming makes me a good candidate for this role.",
              "hi": "मुझे हमेशा एक बचपन से फिजिक्स की अच्छी समझ थी। प्रोग्रामिंग में मेरे अनुभव का मेल मुझे इस पद के लिए एक अच्छा उम्मीदवार बनाता है।",
              "ka": "ಬಾಲ್ಯದಲ್ಲಿ ನಾನು ಯಾವಾಗಲೂ ಭೌತಶಾಸ್ತ್ರದ ಬಗ್ಗೆ ಉತ್ತಮ ತಿಳುವಳಿಕೆಯನ್ನು ಹೊಂದಿದ್ದೆ. ಪ್ರೋಗ್ರಾಮಿಂಗ್‌ನಲ್ಲಿನ ನನ್ನ ಅನುಭವದೊಂದಿಗೆ ಅದನ್ನು ಸಂಯೋಜನೆ ಮಾಡುವ ನನ್ನ ಸಾಮರ್ಥ್ಯವು ಈ ಕೆಲಸಕ್ಕೆ ನನ್ನನ್ನು ಉತ್ತಮ ಅಭ್ಯರ್ಥಿಯನ್ನಾಗಿ ಮಾಡುತ್ತದೆ.",
              "te": "నాకు చిన్నతనంలో ఫిజిక్స్ పై మంచి అవగాహన ఉండేది. ప్రోగ్రామింగ్‌లో నా అనుభవంతో కలిపి ఈ జాబ్ కు నన్ను మంచి అభ్యర్థిగా మార్చారు.",
              "or": "ମୁଁ ପିଲାଦିନେ ପଦାର୍ଥ ବିଜ୍ଞାନ ବିଷୟରେ ସର୍ବଦା ଭଲ ବୁଝୁଥିଲି | ପ୍ରୋଗ୍ରାମିଂରେ ମୋର\n  ଅଭିଜ୍ଞତା ସହିତ ଏହାକୁ ମିଶାଇ ମୋତେ ଏହି ଭୂମିକା ପାଇଁ ଏକ ଭଲ ପ୍ରାର୍ଥୀ କରେ |",
              "as": "সৰুৰ পৰায়ে মই পদাৰ্থ বিজ্ঞানৰ বিষয়তো ভাল দৰে বুজি পাই আহিছোঁ। প্ৰ'গ্ৰামিংত মোৰ অভিজ্ঞতাৰ সৈতে ইয়াক দিলে মোক এই কামৰ কাৰনে এজন ভাল প্ৰাৰ্থী কৰি তোলে।",
              "gu": "મને બાળપણમાં ભૌતિકશાસ્ત્રની હંમેશા સારી સમજ હતી. પ્રોગ્રામિંગના મારા અનુભવ સાથે તે સંયોજન મને આ ભૂમિકા માટે મને સારો ઉમેદવાર બનાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "I could learn how to code games on my own. That experience made me realise that I can adapt to this role well.",
              "hi": "कोड कैसे किया जाता है मैंने इसको अपने बल पर सीखा है। उस अनुभव से मुझे ज्ञात हुआ कि मैं अपने आप को इस पद के लिए अच्छी तरह से अनुकूल कर सकती हूं।",
              "ka": "ನನ್ನದೇ ಆದ ಆಟಗಳನ್ನು ಹೇಗೆ ಕೋಡ್ ಮಾಡಬೇಕೆಂದು ನಾನು ಕಲಿಯಬಲ್ಲೆ. ಆ ಅನುಭವವೇ ಈ ಕೆಲಸಕ್ಕೆ ನಾನು ಚೆನ್ನಾಗಿ ಹೊಂದಿಕೊಳ್ಳಬಲ್ಲೆ ಎನ್ನುವುದನ್ನು ಅರಿತುಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡ್ತು.",
              "te": "నేను స్వంతంగా గేమ్‌లను ఎలా కోడ్ చేయాలో నేర్చుకోగలిగాను. ఆ అనుభవం వల్ల ఈ పాత్రకు నేను బాగా సరిపోతానని తెలుసుకున్నాను.",
              "or": "ମୁଁ ନିଜେ ଖେଳଗୁଡିକ କିପରି କୋଡ୍ କରିବାକୁ ଶିଖିପାରେ | ସେହି ଅଭୀଙ୍ଗ୍ୟତା ମୋତେ \n ଅନୁଭବ କଲା ଯେ ମୁଁ ଏହି ଭୂମିକାକୁ ଭଲ ଭାବରେ ଅନୁକୂଳ କରିପାରିବି |",
              "as": "মই নিজে খেলবোৰ কেনেদৰে কোড কৰিব লাগে শিকিব পাৰিলোঁ সেই অভিজ্ঞতাই মোক উপলব্ধি কৰাইছিল যে মই এই কামটোৰ লগত ভালদৰে খাপ খাব পাৰো।",
              "gu": "હું મારા જાતેજ રમતો કોડ કેવી રીતે કરવી શીખી શકૂ છું. એ અનુભવે મને અહેસાસ કરાવ્યો કે હું આ ભૂમિકાને સારી રીતે સ્વીકારી શકું છું."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_1_5///",
              "hi": "C5_1_5///",
              "ka": "C5_1_5///",
              "te": "C5_1_5///",
              "or": "C5_1_5///",
              "as": "C5_1_5///",
              "gu": "C5_1_5///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "The game samples you’ve sent do show that you understand this work. However, being a physics programmer is a difficult role. We have another position we can offer you - a junior physics programmer.",
              "hi": "आपके द्वारा भेजे गए गेम नमूने दिखाते हैं कि आप इस काम को समझती हैं। हालांकि, एक फिजिक्स प्रोग्रामर के पद पर काम करना अत्यंत कठिन है। हमारे पास एक और पद है जिसे हम आपको दे सकते हैं - एक जूनियर फिजिक्स प्रोग्रामर।",
              "ka": "ನೀವು ಕಳುಹಿಸಿದ ಆಟದ ಮಾದರಿಗಳು ನೀವು ಈ ಕೆಲಸವನ್ನು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೀರಿ ಎನ್ನುವುದನ್ನು ತೋರಿಸುತ್ತವೆ. ಆದಾಗ್ಯೂ, ಭೌತಶಾಸ್ತ್ರ ಪ್ರೋಗ್ರಾಮರ್ ಆಗುವುದು ಕಷ್ಟಕರವಾದ ಕೆಲಸವಾಗಿದೆ. ನಿಮಗೆ ನೀಡಬಹುದಾದ ಮತ್ತೊಂದು ಕೆಲಸವನ್ನು ನಾವು ಹೊಂದಿದ್ದೇವೆ - ಜೂನಿಯರ್ ಭೌತಶಾಸ್ತ್ರ ಪ್ರೋಗ್ರಾಮರ್",
              "te": "మీరు ఈ పని అర్థం అయినట్లు మీరు పంపిన గేమ్ నమూనాలు చూస్తే అర్ధం అవుతుంది. కానీ, ఫిజిక్స్ ప్రోగ్రామర్‌గా ఉండటం చాలా కష్టమైన పాత్ర. మేము మీకు జూనియర్ ఫిజిక్స్ ప్రోగ్రామర్ జాబ్ ఇవ్వగలము.",
              "or": "ଆପଣ ପଠାଇଥିବା ଖେଳ ନମୁନାଗୁଡିକ ଦର୍ଶାଏ ଯେ ଆପଣ ଏହି କାର୍ଯ୍ୟକୁ ବୁଝିଛନ୍ତି |\n  ତଥାପି, ପଦାର୍ଥ ବିଜ୍ଞାନ ପ୍ରୋଗ୍ରାମର ହେବା ଏକ କଷ୍ଟକର ଭୂମିକା | ଆମର ଅନ୍ୟ ଏକ ସ୍ଥିତି ଅଛି ଯାହାକୁ ଆମେ ଆପଣଙ୍କୁ ପ୍ରଦାନ କରିପାରିବା - ଏକ ଜୁନିଅର ଫିଜିକ୍ସ ପ୍ରୋଗ୍ରାମର୍ |",
              "as": "আপুনি দেখুৱা গেমৰ নমুনাবোৰে দেখুৱায় যে আপুনি এই কামটো বুজি পাইছে। অৱশ্যে, পদাৰ্থ বিজ্ঞানৰ প্ৰগ্ৰামাৰ হোৱাটো এক কঠিন কাম। আমাৰ আন এটা পদ আছে যিটো আমি আপোনাক আগবঢ়াব পাৰোঁ - এজন কনিষ্ঠ পদাৰ্থ বিজ্ঞান প্ৰগ্ৰেমাৰ।",
              "gu": "તે મોકલેલા રમતના નમૂનાઓ દર્શાવે છે કે તું આ કાર્યને સમજે છે. જો કે, ભૌતિકશાસ્ત્ર પ્રોગ્રામર બનવું એ એક મુશ્કેલ ભૂમિકા છે. અમારી પાસે બીજી સ્થિતિ છે જે અમે તને તક આપી શકીએ છીએ - એક જુનિયર ભૌતિકશાસ્ત્ર પ્રોગ્રામર."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Sir, I come with industry experience. If I take up a junior role at my second job, it might not help my portfolio.",
              "hi": "महोदय, मुझे इंडस्ट्री का अनुभव है। अगर मैं अपनी दूसरी नौकरी में एक जूनियर का पद लेती हूं, तो यह मेरे पोर्टफोलियो के लिए ठीक नहीं है।",
              "ka": "ಸರ್, ನಾನು ಉದ್ಯಮದ ಅನುಭವದೊಂದಿಗೆ ಬಂದಿದ್ದೇನೆ. ನನ್ನ ಎರಡನೇ ಕೆಲಸದಲ್ಲಿ ನಾನು ಜೂನಿಯರ್ ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಂಡರೆ, ಅದು ನನ್ನ ಪೋರ್ಟ್‌ಫೋಲಿಯೊಗೆ ಸಹಾಯ ಮಾಡದಿರಬಹುದು.",
              "te": "సార్, నేను ఇండస్ట్రీ అనుభవంతో వచ్చాను. నేను నా రెండవ ఉద్యోగంలో జూనియర్ రోల్ ను తీసుకుంటే, అది నా పోర్ట్ ఫోలియోకు సహాయపడదు.",
              "or": "ସାର୍, ମୁଁ ଶିଳ୍ପ ଅଭିଜ୍ଞତା ସହିତ ଆସିଛି | ଯଦି ମୁଁ ମୋର ଦ୍ୱିତୀୟ ଚାକିରିରେ କନିଷ୍ଠ\n  ଭୂମିକା ଗ୍ରହଣ କରେ, ଏହା ହୁଏତ ମୋ ପୋର୍ଟଫୋଲିଓକୁ ସାହାଯ୍ୟ କରିନପାରେ |",
              "as": "চাৰ, মই উদ্যোগৰ অভিজ্ঞতা লৈ আহিছোঁ। যদি মই মোৰ দ্বিতীয় চাকৰিত কনিষ্ঠ পদ লওঁ, ই মোৰ পৰ্টফলিঅ'ত সহায় নকৰিব পাৰে।",
              "gu": "સર, હું ઉદ્યોગનો અનુભવ લઈને આવી છું. જો હું મારી બીજી નોકરીમાં જુનિયરની ભૂમિકા નિભાવીશ, તો તે મારા પોર્ટફોલિયોને મદદ કરશે નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "It might seem like a compromise Mira, but if you like this kind of work, we will need you to work with someone more experienced before you take up a senior position. We can always move you to a higher position based on your performance.",
              "hi": "यह एक समझौते की तरह प्रतीत हो सकता है मीरा, लेकिन यदि आपको इस तरह के काम पसंद हैं, तो सीनियर पद लेने से पहले आपको  किसी अधिक अनुभवी के साथ काम करने की आवश्यकता होगी। आपके प्रदर्शन के आधार पर हम हमेशा आपको उच्च पद पर ले जा सकते हैं।",
              "ka": "ಮೀರಾ,ಇದು ರಾಜಿ ಎಂದು ತೋರಬಹುದು, ಆದರೆ ನೀವು ಈ ರೀತಿಯ ಕೆಲಸವನ್ನು ಇಷ್ಟಪಟ್ಟರೆ, ನೀವು ಹಿರಿಯ ಸ್ಥಾನವನ್ನು ತೆಗೆದುಕೊಳ್ಳುವ ಮೊದಲು ಹೆಚ್ಚು ಅನುಭವವುಳ್ಳ ಯಾರೊಂದಿಗಾದರೂ ಕೆಲಸ ಮಾಡಬೇಕಾಗುತ್ತದೆ. ನಿಮ್ಮ ಕಾರ್ಯಕ್ಷಮತೆಯ ಆಧಾರದ ಮೇಲೆ ನಾವು ಯಾವಾಗಲೂ ನಿಮ್ಮನ್ನು ಉನ್ನತ ಸ್ಥಾನಕ್ಕೆ ಏರಿಸಬಹುದು.",
              "te": "ఇది రాజీ పడాలని అనిపించవచ్చు, కానీ మీరు ఈ రకమైన జాబ్ ఇష్టపడితే, మీరు సీనియర్ పదవిని చేపట్టే ముందు మరింత అనుభవం ఉన్న వారితో కలిసి పని చేయాల్సి ఉంటుంది. మీ పనిని బట్టీ మేము మిమ్మల్ని ఎప్పుడూ మంచి పొజిషన్ కి పంపిస్తాము.",
              "or": "ଏହା ଏକ ଆପୋଷ ବୁଝାମଣା ଭଳି ମନେହୁଏ, କିନ୍ତୁ ଯଦି ଆପଣ ଏହି ପ୍ରକାରର କାମ\n  ପସନ୍ଦ କରନ୍ତି, ତେବେ ଆପଣ ଏକ ବରିଷ୍ଠ ପଦବୀ ଗ୍ରହଣ କରିବା ପୂର୍ବରୁ ଆପଣଙ୍କୁ ଅଧିକ ଅଭିଜ୍ଞ ବ୍ୟକ୍ତିଙ୍କ ସହିତ କାମ କରିବା ଆବଶ୍ୟକ | ଆପଣଙ୍କ କାର୍ଯ୍ୟଦକ୍ଷତା ଉପରେ ଆଧାର କରି ଆମେ ସବୁବେଳେ ଆପଣଙ୍କୁ ଏକ ଉଚ୍ଚ ସ୍ଥିତିକୁ ନେଇପାରିବା |",
              "as": "এইটো মীৰাৰ কাৰনে কম্প্ৰোমাইজ যেন লাগিব পাৰে, কিন্তু যদি আপুনি এনে ধৰণৰ কাম ভাল পায়, আপুনি জ্যেষ্ঠ পদগ্ৰহণ কৰাৰ আগতে অধিক অভিজ্ঞ কাৰোবাৰ লগত কাম কৰাৰ প্ৰয়োজন আছে। আমি সদায় আপোনাৰ প্ৰদৰ্শনৰ ওপৰত আধাৰিত কৰি আপোনাক উচ্চ স্থানলৈ স্থানান্তৰ কৰিব পাৰোঁ।",
              "gu": "તે કદાચ મીરા સાથે સમાધાન જેવું લાગે, પરંતુ જો તને આ પ્રકારનું કામ ગમતું હોય, તો અમારે જરૂર પડશે કે તું કોઈ વરિષ્ઠ પદ સંભાળતા પહેલા વધુ અનુભવી વ્યક્તિ સાથે કામ કર. અમે હંમેશા તમારા પ્રદર્શનના આધારે તને ઉચ્ચ સ્થાન પર લઈ જઈ શકીએ છીએ."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "Negotation for a role",
              "hi": "एक पद के लिए वार्ता ",
              "ka": "ಒಂದು ಕೆಲಸಕ್ಕಾಗಿ ಸಂಧಾನ",
              "te": "పాత్ర కోసం చర్చలు",
              "or": "ଏକ ଭୂମିକା ପାଇଁ ବୁଝାମଣା |",
              "as": "মীমাংসা",
              "gu": "ભૂમિકા માટે વાટાઘાટો"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "ಎಮ್‌ಸಿ‌ಕ್ಯೂ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "On applying for the role of a physics programmer, Mira negotiates with the employer so that she does not need to start with a junior position. Which of the following is TRUE about negotiation?",
              "hi": "फिजिक्स प्रोग्रामर के पद के लिए आवेदन करने पर, मीरा नियोक्ता के साथ बातचीत करती है ताकि उसे जूनियर के पद से शुरू करने की आवश्यकता न हो। बातचीत के बारे में निम्नलिखित में से कौन सा सत्य है?",
              "ka": "ಭೌತಶಾಸ್ತ್ರದ ಪ್ರೋಗ್ರಾಮರ್ ಪಾತ್ರಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿದಾಗ, ಮೀರಾ ಉದ್ಯೋಗದಾತರೊಂದಿಗೆ ಕಿರಿಯ ಸ್ಥಾನದೊಂದಿಗೆ ಪ್ರಾರಂಭಿಸಬೇಕಾಗಿಲ್ಲ ಅನ್ನುವ ಬಗ್ಗೆ ಮಾತುಕತೆ ನಡೆಸುತ್ತಾಳೆ. ಸಂಧಾನದ ಬಗ್ಗೆ ಈ ಕೆಳಗಿನವುಗಳಲ್ಲಿ ಯಾವುದು ನಿಜ?",
              "te": "ఫిజిక్స్ ప్రోగ్రామర్ పాత్ర కోసం అప్లై చేసుకున్నప్పుడు, మీరా యాజమాన్యంతో చర్చలు జరుపుతుంది, తద్వారా ఆమె జూనియర్ హోదాతో ప్రారంభించాల్సిన అవసరం లేదు. చర్చల విషయంలో కింది వాటిలో ఏది నిజం?",
              "or": "ଏକ ପଦାର୍ଥ ବିଜ୍ଞାନ ପ୍ରୋଗ୍ରାମର ଭୂମିକା ପାଇଁ ଆବେଦନ କରିବାବେଳେ\n , ମୀରା ନିଯୁକ୍ତିଦାତା ସହିତ ବୁଝାମଣା କରନ୍ତି ଯାହାଦ୍ୱାରା ତାଙ୍କୁ କନିଷ୍ଠ ପଦବୀରୁ ଆରମ୍ଭ କରିବା ଆବଶ୍ୟକ ନାହିଁ | ବୁଝାମଣା ବିଷୟରେ ନିମ୍ନଲିଖିତ ମଧ୍ୟରୁ କେଉଁଟି ସତ୍ୟ?",
              "as": "পদাৰ্থ বিজ্ঞানৰ প্ৰগ্ৰামাৰৰ ভূমিকাৰ বাবে আবেদন কৰাৰ পিছত, মীৰাই চাকৰি দিয়া জনৰ সৈতে মীমাংসা কৰে যাতে তাই কনিষ্ঠ পদেৰে আৰম্ভ কৰিব লগীয়া নহয়। মীমাংসাৰ বিষয়ে নিম্নলিখিত কোনটো সঁচা?",
              "gu": "ભૌતિકશાસ્ત્ર પ્રોગ્રામરની ભૂમિકા માટે અરજી કરવા પર, મીરા નોકરીદાતા સાથે વાટાઘાટો કરે છે જેથી તેને જુનિયર પદથી શરૂઆત કરવાની જરૂર ન પડે. વાટાઘાટો વિશે નીચેનામાંથી કયું સાચું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "In the end, it is important that your idea is accepted",
              "hi": "अंत में, यह महत्वपूर्ण है कि आपका विचार स्वीकार किया जाए",
              "ka": "ಕೊನೆಯಲ್ಲಿ, ನಿಮ್ಮ ಕಲ್ಪನೆಯನ್ನು ಅವರು ಒಪ್ಪಿಕೊಳ್ಳುವುದು ಮುಖ್ಯವಾಗಿರುತ್ತದೆ.",
              "te": "చివరి వరాళి మీ ఆలోచనను అంగీకరించడం ముఖ్యం",
              "or": "ଶେଷରେ, ଏହା ଜରୁରୀ ଅଟେ ଯେ ତୁମ ବିଚାର କୁ ଗ୍ରହଣ କରାଯିବ।",
              "as": "শেষত, আপোনাৰ ধাৰণাটো গ্ৰহণ কৰাটো জৰুৰী",
              "gu": "અંતે, તમારા વિચારને સ્વીકારવામાં આવે તે મહત્વનું છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "It is important not to agree with the other side while negotiating",
              "hi": "वार्ता करते समय दूसरे पक्ष से सहमत नहीं होना महत्वपूर्ण है",
              "ka": "ಮಾತುಕತೆ ಅಥವಾ ಸಂಧಾನ ನಡೆಸುವಾಗ ಎದುರು ಪಕ್ಷದ ವಾದವನ್ನು ಒಪ್ಪಿಕೊಳ್ಳದಿರುವುದು ಮುಖ್ಯವಾಗುತ್ತದೆ.",
              "te": "చర్చలు జరుపుతున్నప్పుడు ఎదుటివారితో ఏకీభవించకపోవడం ముఖ్యం",
              "or": "ବୁଝାମଣା ବେଳେ ଅନ୍ୟ ପକ୍ଷ ସହିତ ସହମତ ନ ହେବା ଜରୁରୀ |",
              "as": "মীমাংসা কৰোঁতে আনটো পক্ষৰ সৈতে সন্মত হব নালাগে",
              "gu": "વાટાઘાટો કરતી વખતે બીજી બાજુ સાથે સહમત ન થવું મહત્વપૂર્ણ છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "It always means to give up on your point of view and do what another says",
              "hi": "यह हमेशा ही अपने दृष्टिकोण से अधिक दूसरे की बात को महत्त्व देना है ",
              "ka": "ಹಾಗೆ ಮಾಡಿದಲ್ಲಿ, ಯಾವಾಗಲೂ ನಿಮ್ಮ ದೃಷ್ಟಿಕೋನವನ್ನು ಬಿಟ್ಟುಕೊಡುವುದು ಮತ್ತು ಇನ್ನೊಬ್ಬರು ಏನು ಹೇಳುತ್ತಾರೋ ಅದನ್ನು ಮಾಡುವುದು ಎಂದರ್ಥ",
              "te": "ఇది ఎప్పుడూ మీ ఆలోచనని వదులుకోవడం మరియు మరొకరు చెప్పేది చేయడం అని అర్థం",
              "or": "ଏହାର ଅର୍ଥ ସର୍ବଦା ତୁମର ଦୃଷ୍ଟିକୋଣରୁ ତ୍ୟାଗ କରିବା ଏବଂ ଅନ୍ୟ ଜଣକ ଯାହା\n  କହନ୍ତି ତାହା କରିବା |",
              "as": "ইয়াৰ অৰ্থ হৈছে সদায়ে আপোনাৰ দৃষ্টিভংগী বাদ দিয়া আৰু আন এজনে কোৱা কাম কৰা",
              "gu": "તેનો અર્થ હંમેશા તમારા દૃષ્ટિકોણને છોડી દેવો અને બીજા જે કહે છે તે કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "Finding an agreeable solution between people with different opinions",
              "hi": "अलग-अलग विचार वाले लोगों के बीच एक स्वीकार्य समाधान ढूँढना",
              "ka": "ವಿಭಿನ್ನ ಅಭಿಪ್ರಾಯಗಳನ್ನು ಹೊಂದಿರುವ ಜನರ ನಡುವೆ ಸಮ್ಮತವಾದ ಪರಿಹಾರವನ್ನು ಕಂಡುಹಿಡಿಯುವುದು ಮುಖ್ಯವಾಗುತ್ತದೆ.",
              "te": "వేరు వేరు అభిప్రాయాలు ఉన్న వ్యక్తుల మధ్య ఒక మంచి పరిష్కారాన్ని కనుగొనడం",
              "or": "ବିଭିନ୍ନ ମତ ଥିବା ଲୋକଙ୍କ ମଧ୍ୟରେ ଏକ ସହମତ ସମାଧାନ ଖୋଜିବା |",
              "as": "বিভিন্ন মতামত থকা লোকৰ মাজত এক সন্মত সমাধান বিচাৰি উলিওৱা",
              "gu": "વિવિધ અભિપ્રાયો ધરાવતા લોકો વચ્ચે સહમત ઉકેલ શોધવો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Think again! It helps to have an open mind while negotiating",
              "hi": "फिर से विचार करो! बातचीत करते समय खुले मन से सोचना सहायता करता है",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಯೋಚಿಸು! ಮಾತುಕತೆ ನಡೆಸುವಾಗ ಮುಕ್ತ ಮನಸ್ಸನ್ನು ಹೊಂದಿರುವುದು ಯಾವಾಗಲೂ ಒಳ್ಳೆಯದು  ",
              "te": "మళ్ళి ఆలోచించు! చర్చలు జరుపుతున్నప్పుడు అనుకున్నది చెప్పడానికి ఇది సహాయపడుతుంది",
              "or": "ପୁଣି ଥରେ ଭାବ! ବୁଝାମଣା କରିବା ସମୟରେ ଏହା ଏକ ଖୋଲା ମନ ରଖିବାରେ\n  ସାହାଯ୍ୟ କରେ |",
              "as": "আকৌ চিন্তা কৰক! ই মীমাংসা কৰোঁতে খোলা মন ৰখাত সহায় কৰে",
              "gu": "ફરીથી વિચારો! વાટાઘાટો કરતી વખતે તે ખુલ્લું મન રાખવામાં મદદ કરે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Think again! It helps to have an open mind while negotiating",
              "hi": "फिर से विचार करो! बातचीत करते समय खुले मन से सोचना सहायता करता है",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಯೋಚಿಸು! ಮಾತುಕತೆ ನಡೆಸುವಾಗ ಮುಕ್ತ ಮನಸ್ಸನ್ನು ಹೊಂದಿರುವುದು ಯಾವಾಗಲೂ ಒಳ್ಳೆಯದು  ",
              "te": "మళ్ళి ఆలోచించు! చర్చలు జరుపుతున్నప్పుడు అనుకున్నది చెప్పడానికి ఇది సహాయపడుతుంది",
              "or": "ପୁଣି ଥରେ ଭାବ! ବୁଝାମଣା କରିବା ସମୟରେ ଏହା ଏକ ଖୋଲା ମନ ରଖିବାରେ \n ସାହାଯ୍ୟ କରେ |",
              "as": "আকৌ চিন্তা কৰক! ই মীমাংসা কৰোঁতে খোলা মন ৰখাত সহায় কৰে",
              "gu": "ફરીથી વિચારો! વાટાઘાટો કરતી વખતે તે ખુલ્લું મન રાખવામાં મદદ કરે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Think again! It could mean that sometimes, but not always",
              "hi": "फिर से विचार करो! इसका अर्थ कभी कभी हो सकता है, लेकिन हमेशा नहीं",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಯೋಚಿಸು! ಮಾತುಕತೆ ನಡೆಸುವಾಗ ಮುಕ್ತ ಮನಸ್ಸನ್ನು ಹೊಂದಿರುವುದು ಯಾವಾಗಲೂ ಒಳ್ಳೆಯದು  ",
              "te": "మళ్ళి ఆలోచించు! ఇది కొన్నిసార్లు మాత్రమే అని అర్ధం కావచ్చు, కానీ ఎప్పుడూ కాదు",
              "or": "ପୁଣି ଥରେ ଭାବ! ଏହାର ଅର୍ଥ ହୁଏତ ବେଳେବେଳେ, କିନ୍ତୁ ସବୁବେଳେ ନୁହେଁ |",
              "as": "আকৌ চিন্তা কৰক! ইয়াৰ অৰ্থ হ'ব পাৰে কেতিয়াবা, কিন্তু সদায়ে নহয়",
              "gu": "ફરીથી વિચારો! તેનો અર્થ એ હોઈ શકે કે ક્યારેક, પરંતુ હંમેશા નહીં"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ବହୁତ ଭଲ କାମ!",
              "as": "সুন্দৰ কাম!",
              "gu": "મહાન કામ!"
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_1_6 D",
              "hi": "C5_1_6 D",
              "ka": "C5_1_6 D",
              "te": "C5_1_6 డి",
              "or": "C5_1_6 D",
              "as": "C5_1_6 D",
              "gu": "C5_1_6 D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "Mira is not very satisfied with the offer, but she is keen on taking up the job. \n\nWhat should she do?",
              "hi": "मीरा प्रस्ताव से बहुत संतुष्ट नहीं है, लेकिन वह नौकरी करने के लिए उत्सुक है।\n\nउसे क्या करना चाहिए?",
              "ka": "ಮೀರಾ ಈ ಆಫರ್‌ನಿಂದ ಹೆಚ್ಚು ತೃಪ್ತಿ ಹೊಂದಿಲ್ಲ. ಆದರೆ ಅವಳು ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ. ಅವಳು ಏನು ಮಾಡಬೇಕು",
              "te": "మీరా ఆ జాబ్ ఆఫర్‌తో సంతృప్తి చెందలేదు, కానీ ఆమె ఉద్యోగం చేయడానికి ఆసక్తిగా ఉంది. \n \n \n \n ఆమె ఏమి చేయాలి?",
              "or": "ମୀରା ଏହି ଅଫରରେ ସନ୍ତୁଷ୍ଟ ନୁହଁନ୍ତି, କିନ୍ତୁ ସେ ଚାକିରି ନେବାକୁ ଆଗ୍ରହୀ ଅଟନ୍ତି।\n ସେ କଣ କରିବା ଉଚ୍ଚିତ?",
              "as": "মীৰা এই প্ৰস্তাৱটো বৰ ভাল পোৱা নাই, কিন্তু তাই চাকৰিটো গ্ৰহণ কৰিবলৈ আগ্ৰহ দেখুৱাই। তাই কি কৰা উচিত?",
              "gu": "મીરા આ ઓફરથી બહુ સંતુષ્ટ નથી, પરંતુ તે આ કામ લેવા માટે ઉત્સુક છે. તેને શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Take up the job",
              "hi": "काम करे ",
              "ka": "ಕೆಲಸಕ್ಕೆ ಸೇರಿಕೊಳ್ಳುವುದೇ",
              "te": "ఉద్యోగంలో చేరండి",
              "or": "ଚାକିରି ନିଅ |",
              "as": "কামটো লওক",
              "gu": "કામ ચાલુ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Apply to the other company as a C++ programmer",
              "hi": "सी ++ प्रोग्रामर के रूप में दूसरी कंपनी में आवेदन करे",
              "ka": "ಸಿ ++ ಪ್ರೋಗ್ರ್ಯಾಮರ್ ಕೆಲಸಕ್ಕೆ ಬೇರೆ ಕಂಪನಿಯಲ್ಲಿ ಅರ್ಜಿ ಹಾಕುವುದೇ",
              "te": "C++ ప్రోగ్రామర్‌గా ఇతర కంపెనీకి అప్లై చేసుకోండి",
              "or": "ଅନ୍ୟ କମ୍ପାନୀକୁ C ++ ପ୍ରୋଗ୍ରାମର୍ ଭାବରେ ଆବେଦନ କରନ୍ତୁ |",
              "as": "চি++ প্ৰগ্ৰেমাৰ হিচাপে আন কোম্পানীত আবেদন কৰক",
              "gu": "C++ પ્રોગ્રામર તરીકે અન્ય કંપનીમાં અરજી કરો"
            }
          }
        ]
      },
      "scene29": {
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "Take up the job",
              "hi": "काम करे ",
              "ka": "ಕೆಲಸಕ್ಕೆ ಸೇರಿಕೋ",
              "te": "ఉద్యోగంలో చేరండి",
              "or": "ଚାକିରି ନିଅ |",
              "as": "কামটো লওক",
              "gu": "કામ ચાલુ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira gets the job of a Junior Physics Programmer at the gaming company. The environment is very different from her previous job. Everyone is much less formal and more friendly.",
              "hi": "मीरा को गेमिंग कंपनी में जूनियर फिजिक्स प्रोग्रामर का काम मिलता है। वातावरण उसकी  पिछली नौकरी से बहुत अलग है। हर कोई बहुत कम औपचारिक और अधिक मित्रतापूर्ण है।",
              "ka": "ಮೀರಾಗೆ ಒಂದು ಗೇಮಿಂಗ್ ಕಂಪನಿಯಲ್ಲಿ ಜೂನಿಯರ್ ಫಿಸಿಕ್ಸ್ ಪ್ರೋಗ್ರಾಮರ್ ಕೆಲಸ ಸಿಗುತ್ತದೆ. ಅವಳ ಹಿಂದಿನ ಕೆಲಸಕ್ಕಿಂತ ಪರಿಸರವು ತುಂಬಾ ಭಿನ್ನವಾಗಿದೆ. ಇಲ್ಲಿ ಪ್ರತಿಯೊಬ್ಬರೂ ಕಡಿಮೆ ಔಪಚಾರಿಕ ಮತ್ತು ಹೆಚ್ಚು ಸ್ನೇಹಪರರಾಗಿದ್ದಾರೆ.",
              "te": "మీరా గేమింగ్ కంపెనీలో జూనియర్ ఫిజిక్స్ ప్రోగ్రామర్ ఉద్యోగంలో చేరింది. ఆమె గతంలో చేసిన ఉద్యోగం కంటే ఇది చాలా వేరుగా ఉంది. ప్రతి ఒక్కరూ చాలా తక్కువ అధికారికంగా మరియు మరింత స్నేహపూర్వకంగా ఉంటారు.",
              "or": "ମୀରା ଖେଳ କମ୍ପାନୀରେ ଜୁନିଅର ଫିଜିକ୍ସ ପ୍ରୋଗ୍ରାମରର ଚାକିରି ପାଇଥାଏ | ତା’ର \n ପୂର୍ବ ଚାକିରିଠାରୁ ପରିବେଶ ବହୁତ ଅଲଗା | ସମସ୍ତେ ବହୁତ କମ୍ ଆନୁଷ୍ଠାନିକ ଏବଂ ଅଧିକ ବନ୍ଧୁତ୍ୱପୂର୍ଣ୍ଣ |",
              "as": "মীৰাই গেমিং কোম্পানীত এজন জুনিয়ৰ ফিজিক্স প্ৰগ্ৰেমাৰৰ চাকৰি পায়। পৰিৱেশটো তাইৰ আগৰ কামতকৈ বহুত বেলেগ। সকলোৱে বহুত ফূৰ্তি কৰি লগে ভাগে থাকে।",
              "gu": "મીરાને ગેમિંગ કંપનીમાં જુનિયર ફિઝિક્સ પ્રોગ્રામરની નોકરી મળે છે. તેની પાછલી નોકરી કરતાં વાતાવરણ ઘણું અલગ છે. દરેક વ્યક્તિ ઘણી ઓછી ઔપચારિક અને વધુ મૈત્રીપૂર્ણ છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_1_2",
              "hi": "C6_1_2",
              "ka": "C6_1_2",
              "te": "C6_1_2",
              "or": "C6_1_2",
              "as": "C6_1_2",
              "gu": "C6_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Her workload is much more than her previous job as she has to learn all about making realistic dynamics in games. The timelines are much shorter in gaming projects.",
              "hi": "उसका वर्कलोड अपनी पिछली नौकरी से कहीं अधिक है क्योंकि उसे गेम में वास्तविक गतिशीलता बनाने के बारे में सब कुछ सीखना है। गेमिंग प्रोजेक्ट में समयसीमा बहुत कम होती है।",
              "ka": "ಆಟಗಳಲ್ಲಿ ವಾಸ್ತವಿಕ ಡೈನಾಮಿಕ್ಸ್ ನೀಡುವ ಬಗ್ಗೆ ಎಲ್ಲವನ್ನೂ ಕಲಿಯಬೇಕಾಗಿರುವುದರಿಂದ ಅವಳ ಕೆಲಸದ ಹೊರೆ ಅವಳ ಹಿಂದಿನ ಕೆಲಸಕ್ಕಿಂತ ಹೆಚ್ಚಾಗಿದೆ. ಗೇಮಿಂಗ್ ಪ್ರಾಜೆಕ್ಟ್‌ಗಳಲ್ಲಿ ಟೈಮ್‌ಲೈನ್‌ಗಳು ತುಂಬಾ ಕಡಿಮೆ ಇರುತ್ತದೆ.",
              "te": "గేమ్‌లలో రియల్ డైనమిక్‌లను ఎలా చేయాలో ఆమె నేర్చుకోవాలి కాబట్టి ఆమె పాత జాబ్ కంటే పనిభారం చాలా ఎక్కువ ఉంటుంది. గేమింగ్ ప్రాజెక్ట్ లలో టైమ్‌లైన్‌లు చాలా తక్కువగా ఉంటాయి.",
              "or": "ତା’ର କାର୍ଯ୍ୟଭାର ତା’ର ପୂର୍ବ କାର୍ଯ୍ୟ ଅପେକ୍ଷା ବହୁତ ଅଧିକ, କାରଣ ତାଙ୍କୁ ଖେଳଗୁଡିକରେ\n  ବାସ୍ତବବାଦୀ ଗତିଶୀଳତା ବିଷୟରେ ସବୁ ଜାଣିବାକୁ ପଡିବ | ଖେଳ ପ୍ରୋଜେକ୍ଟରେ ସମୟସୀମା ବହୁତ ଛୋଟ ଅଟେ |",
              "as": "তাইৰ কামৰ বোজা তাইৰ আগৰ কামতকৈ বহুত বেছি কিয়নো তাই গেম বনোৱাৰ বিষয়ে শিকিব লাগিব। গেমিং প্ৰকল্পত সময়সীমা বহুত কম।",
              "gu": "તેનો કામનો ભાર તેની પાછલી નોકરી કરતાં ઘણો વધારે છે કારણ કે તેને રમતોમાં વાસ્તવિક ગતિશીલતા બનાવવા વિશે બધું શીખવું પડશે. ગેમિંગ પ્રોજેક્ટ્સમાં સમયરેખા ઘણી ટૂંકી હોય છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_1_3",
              "hi": "C6_1_3",
              "ka": "C6_1_3",
              "te": "C6_1_3",
              "or": "C6_1_3",
              "as": "C6_1_3",
              "gu": "C6_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shantanu is very excited about his sister working in a gaming company. He visits her city, and she gives him a tour of the company. He jokes that one day they might work together in the same company.",
              "hi": "शांतनु एक गेमिंग कंपनी में काम करने वाली अपनी बहन के बारे में बहुत उत्साहित है। वह उसके शहर जाता है, और वह उसे कंपनी में सब कुछ दिखाती है। वह मज़ाक करता है कि एक दिन वे एक ही कंपनी में एक साथ काम कर सकते हैं।",
              "ka": "ಶಂತನು ತನ್ನ ಅಕ್ಕ ಗೇಮಿಂಗ್ ಕಂಪನಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿರುವ ಬಗ್ಗೆ ತುಂಬಾ ಉತ್ಸುಕನಾಗಿದ್ದಾನೆ. ಅವನು ಅವಳ ನಗರಕ್ಕೆ ಭೇಟಿ ನೀಡುತ್ತಾನೆ ಮತ್ತು ಅವಳು ಅವನಿಗೆ ಕಂಪನಿಯನ್ನು ತೋರಿಸುತ್ತಾಳೆ. ಮುಂದೊಂದು ದಿನ ಅವರು ಒಂದೇ ಕಂಪನಿಯಲ್ಲಿ ಒಟ್ಟಿಗೆ ಕೆಲಸ ಮಾಡಬಹುದು ಎಂದು ಅವನು ತಮಾಷೆ ಮಾಡುತ್ತಾನೆ.",
              "te": "షాంతాను తన సోదరి గేమింగ్ కంపెనీలో పనిచేస్తున్నందుకు చాలా ఉత్సాహంగా ఉన్నాడు. అతను ఆమె నగరాన్ని చూస్తాడు మరియు ఆమె అతనికి కంపెనీని తిప్పి చూపిస్తుంది. అతను మాట్లాడుతూ, ఏదో ఒకరోజు ఒకే కంపెనీలో కలిసి పని చేసే అవకాశం ఉందని జోక్ చేస్తాడు.",
              "or": "ଏକ ଖେଳ କମ୍ପାନୀରେ କାମ କରୁଥିବା ଭଉଣୀ ବିଷୟରେ ଶାନ୍ତନୁ ବହୁତ ଉତ୍ସାହିତ |\n  ସେ ତାଙ୍କ ସହର ପରିଦର୍ଶନ କରନ୍ତି, ଏବଂ ସେ ତାଙ୍କୁ କମ୍ପାନୀ ପରିଭ୍ରମଣ କରନ୍ତି | ସେ ଥଟ୍ଟା କରନ୍ତି ଯେ ଦିନେ ସେମାନେ ସମାନ କମ୍ପାନୀରେ ଏକାଠି କାମ କରିପାରନ୍ତି |",
              "as": "শান্তনু তেওঁৰ বায়েকে এটা গেমিং কোম্পানীত কাম কৰাৰ বাবে অতি সুখী। সি তাইৰ চহৰলৈ যায়, আৰু তাই তাক কোম্পানীটো ফুৰায়। তসি ধেমালি কৰে যে এদিন সিহঁতে একেটা কোম্পানীতে একেলগে কাম কৰিব পাৰে।",
              "gu": "શાંતનુ એક ગેમિંગ કંપનીમાં કામ કરતી તેની બહેનને લઈને ખૂબ જ ઉત્સાહિત છે. તે તેના શહેરની મુલાકાત લે છે, અને તે તેની કંપનીની મુલાકાત કરાવે છે. તે મજાક કરે છે કે એક દિવસ તેઓ એક જ કંપનીમાં સાથે કામ કરી શકે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_2_1",
              "hi": "C6_2_1",
              "ka": "C6_2_1",
              "te": "C6_2_1",
              "or": "C6_2_1",
              "as": "C6_2_1",
              "gu": "C6_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira moves into the role of a senior physics programmer after a year. Eventually, she can pay off her education loan.",
              "hi": "मीरा एक वर्ष के बाद एक सीनियर फिजिक्स  प्रोग्रामर के पदभार को संभालती है। अंततः, वह अपने शिक्षा ऋण का भुगतान कर सकती है।",
              "ka": "ಮೀರಾ ಒಂದು ವರ್ಷದ ನಂತರ ಹಿರಿಯ ಭೌತಶಾಸ್ತ್ರ ಪ್ರೋಗ್ರಾಮರ್ ಕೆಲಸಕ್ಕೆ ಮುಂದುವರೆಯುತ್ತಾಳೆ. ಕೊನೆಗೂ, ಅವಳು ತನ್ನ ಶಿಕ್ಷಣ ಸಾಲವನ್ನು ಪಾವತಿಸಲು ಆಗಬಹುದು.",
              "te": "మీరా ఒక సంవత్సరం తర్వాత సీనియర్ ఫిజిక్స్ ప్రోగ్రామర్ రోల్ లోకి వెళుతుంది. చివరికి, ఆమె తన చదువుకోసం తీసుకున్న అప్పుని చెల్లించవచ్చు.",
              "or": "ମୀରା ଏକ ବର୍ଷ ପରେ ଜଣେ ବରିଷ୍ଠ ପଦାର୍ଥ ବିଜ୍ଞାନ ପ୍ରୋଗ୍ରାମରଙ୍କ ଭୂମିକାକୁ ଉତ୍ତୀର୍ଣ୍ଣ ହୁଅନ୍ତି |\n  ପରିଶେଷରେ, ସେ ତାଙ୍କ ଶିକ୍ଷା ରୁଣ ପରିଶୋଧ କରିପାରନ୍ତି |",
              "as": "মীৰাই এবছৰৰ পিছত এজন জ্যেষ্ঠ পদাৰ্থ বিজ্ঞান প্ৰগ্ৰেমাৰ বনি যায়। অৱশেষত, তাই তাইৰ শিক্ষা লোন পৰিশোধ কৰিব পাৰে।",
              "gu": "મીરા એક વર્ષ પછી સિનિયર ભૌતિકશાસ્ત્ર  પ્રોગ્રામરની ભૂમિકામાં આવે છે. આખરે, તે તેની એજ્યુકેશન લોન ચૂકવી શકશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_2_2",
              "hi": "C6_2_2",
              "ka": "C6_2_2",
              "te": "C6_2_2",
              "or": "C6_2_2",
              "as": "C6_2_2",
              "gu": "C6_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "While doing computer science, Mira had never imagined that she’d work in a gaming company someday. But now that she is here, she is happy about discovering this option and following it.",
              "hi": "कंप्यूटर विज्ञान कोर्स करते समय, मीरा ने कभी कल्पना नहीं की थी कि वह किसी दिन एक गेमिंग कंपनी में काम करेगी। लेकिन अब वह यहां है, वह इस विकल्प को खोजने और इसको अपनाने के लिए खुश है।",
              "ka": "ಕಂಪ್ಯೂಟರ್ ಸೈನ್ಸ್ ಮಾಡುವಾಗ, ಮೀರಾ ತಾನು ಒಂದು ದಿನ ಗೇಮಿಂಗ್ ಕಂಪನಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತೇನೆ ಎಂದು ಊಹಿಸಿಯೇ ಇರಲಿಲ್ಲ. ಆದರೆ ಈಗ ಅವಳು ಇಲ್ಲಿರುವುದರಿಂದ, ಈ ಆಯ್ಕೆಯನ್ನು ಕಂಡುಹಿಡಿದ ಹಾಗೂ ಅದನ್ನು ಮುಂದುವರಿರಿಸಲು ಅವಳು ಸಂತೋಷಗೊಂಡಿದ್ದಾಳೆ.",
              "te": "కంప్యూటర్ సైన్స్ చేస్తున్నప్పుడు, మీరా ఏదో ఒక గేమింగ్ కంపెనీలో పని చేస్తుందని ఊహించలేదు. కానీ ఇప్పుడు ఆమె ఇక్కడ ఉంది, ఆమె ఈ ఆప్షన్ తెలుసుకొని దానిని ఫాలో అవ్వడం పట్ల సంతోషంగా ఉంది.",
              "or": "କମ୍ପ୍ୟୁଟର ସାଇନ୍ସ କରିବାବେଳେ, ମୀରା କଳ୍ପନା କରିନଥିଲେ ଯେ ସେ ଦିନେ ଏକ\n  ଗେମିଂ କମ୍ପାନୀରେ କାମ କରିବେ | କିନ୍ତୁ ବର୍ତ୍ତମାନ ସେ ଏଠାରେ ଥିବାରୁ ସେ ଏହି ବିକଳ୍ପ ଆବିଷ୍କାର କରି ଏହାକୁ ଅନୁସରଣ କରି ଖୁସି ଅଛନ୍ତି |",
              "as": "কম্পিউটাৰ বিজ্ঞান কৰোঁতে, মীৰাই কেতিয়াও কল্পনা কৰা নাছিল যে তাই কোনোবা দিনা এটা গেমিং কোম্পানীত কাম কৰিব। কিন্তু এতিয়া যেতিয়া তাই ইয়াত আছে, তাই এই কথা টো গ'ম পায় আৰু তাক অনুসৰণ কৰি সুখী।",
              "gu": "કોમ્પ્યુટર સાયન્સ કરતી વખતે મીરાએ ક્યારેય કલ્પના નહોતી કરી કે તે કોઈ દિવસ ગેમિંગ કંપનીમાં કામ કરશે. પરંતુ હવે તે અહીં છે, તે આ વિકલ્પ શોધવા અને તેને અનુસરવાથી ખુશ છે."
            }
          }
        ],
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Senior physics programmer",
              "hi": "सीनियर फिजिक्स प्रोग्रामर",
              "ka": "ಹಿರಿಯ ಭೌತಶಾಸ್ತ್ರ ಪ್ರೋಗ್ರಾಮರ್",
              "te": "సీనియర్ ఫిజిక్స్ ప్రోగ్రామర్",
              "or": "ସିନିୟର ଫିଜିକ୍ସ ପ୍ରୋଗ୍ରାମର |",
              "as": "জ্যেষ্ঠ পদাৰ্থ বিজ্ঞান প্ৰগ্ৰেমাৰ",
              "gu": "વરિષ્ઠ ભૌતિકશાસ્ત્ર પ્રોગ્રામર"
            }
          }
        ]
      },
      "scene30": {
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "Apply to the other company",
              "hi": "दूसरी कंपनी में आवेदन करे",
              "ka": "ಬೇರೆ ಕಂಪನಿಗೆ ಅರ್ಜಿ ಹಾಕುವುದೇ",
              "te": "ఇతర కంపెనీకి అప్లై చేసుకోండి",
              "or": "ଅନ୍ୟ କମ୍ପାନୀରେ ଆବେଦନ କରନ୍ତୁ |",
              "as": "অন্য কোম্পানীত আবেদন কৰক",
              "gu": "બીજી કંપનીમાં અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira applies to Bandit Games for the role of C++ Programmer. They are very impressed with her explorations in game design and praise her for how organised her code is.",
              "hi": "मीरा सी ++ प्रोग्रामर के पद के लिए बैंडिट गेम्स में आवेदन करती है। वे गेम डिज़ाइन में उसकी खोज से बहुत प्रभावित हैं और उसकी प्रशंसा करते हैं कि उसका कोड बहुत व्यवस्थित है।",
              "ka": "ಮೀರಾ ಸಿ ಪ್ರೋಗ್ರಾಮರ್ ಕೆಲಸಕ್ಕಾಗಿ ಬ್ಯಾಂಡಿಟ್ ಆಟ ಕಂಪನಿಗೆ ಅರ್ಜಿ ಹಾಕುತ್ತಾಳೆ. ಆಟದ ವಿನ್ಯಾಸದಲ್ಲಿನ ಆಕೆಯ ಪರಿಶೋಧನೆಗಳಿಂದ ಅವರು ತುಂಬಾ ಪ್ರಭಾವಿತರಾಗಿದ್ದಾರೆ ಮತ್ತು ಆಕೆಯ ಕೋಡ್ ಎಷ್ಟು ಸಂಘಟಿತವಾಗಿದೆ ಎಂದು ಅವಳನ್ನು ಹೊಗಳುತ್ತಾರೆ.",
              "te": "మీరా C++ ప్రోగ్రామర్ రోల్ కోసం బండిట్ ఆటలకు అప్లై చేస్తుంది. గేమ్ డిజైన్‌లో ఆమె చేసిన అన్వేషణలతో వారు చాలా ఇంప్రెస్ అవుతారు మరియు ఆమె కోడ్ ఎలా నిర్వహించగలిగిందో ఆమెను ప్రశంసించారు.",
              "or": "C ++ ପ୍ରୋଗ୍ରାମରଙ୍କ ଭୂମିକା ପାଇଁ ମୀରା ବ୍ୟାଣ୍ଡିଟ୍ ଗେମ୍ସ ରେ ଆବେଦନ କରନ୍ତି | \n ଖେଳ ଡିଜାଇନ ରେ ତାଙ୍କର ଅନୁସନ୍ଧାନରେ ସେମାନେ ଅତ୍ୟନ୍ତ ପ୍ରଭାବିତ ହୁଅନ୍ତି ଏବଂ ତାଙ୍କ କୋଡ୍ କିପରି ସଂଗଠିତ ହୋଇଥିବାରୁ ତାଙ୍କୁ ପ୍ରଶଂସା କରନ୍ତି |",
              "as": "মীৰাই চি++ প্ৰগ্ৰামাৰৰ ভূমিকাৰ বাবে বেণ্ডিট গেমছত আবেদন কৰে। তেওঁলোকে গেম ডিজাইনত তাইৰ বিচাৰ দেখি বৰ ভাল পাইছে আৰু তাইৰ কোডটো কিমান চিজিল হয় তাৰ বাবে তাইক প্ৰশংসা কৰে।",
              "gu": "મીરા C++ પ્રોગ્રામરની ભૂમિકા માટે નાચીલ ગેમ્સમાં અરજી કરે છે. તેઓ ગેમ ડિઝાઇનમાં તેના સંશોધનોથી ખૂબ પ્રભાવિત છે અને તેનો કોડ કેટલો વ્યવસ્થિત છે તેના માટે તેની પ્રશંસા કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_1_2",
              "hi": "C7_1_2",
              "ka": "C7_1_2",
              "te": "C7_1_2",
              "or": "C7_1_2",
              "as": "C7_1_2",
              "gu": "C7_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They offer her a good package (salary and other job benefits). They are keen to have someone help them create and code simple games for smartphones.",
              "hi": "वे उसे एक अच्छा पैकेज (वेतन और अन्य नौकरी लाभ) प्रदान करते हैं। वे स्मार्टफोन के लिए सरल गेम बनाने और कोड बनाने में सहायता करने हेतु किसी को रखना चाहते हैं।",
              "ka": "ಅವರು ಆಕೆಗೆ ಉತ್ತಮವಾದ ಪ್ಯಾಕೇಜ್ (ಸಂಬಳ ಮತ್ತು ಇತರ ಉದ್ಯೋಗ ಪ್ರಯೋಜನಗಳನ್ನು) ನೀಡುತ್ತಾರೆ. ಅವರಿಗೆ ಸ್ಮಾರ್ಟ್‌ಫೋನ್‌ಗಳಿಗಾಗಿ ಸರಳ ಆಟಗಳನ್ನು ರಚಿಸುವ ಹಾಗೂ  ಕೋಡ್ ಮಾಡಲು ಯಾರದಾದರೂ ಸಹಾಯ ಬೇಕಾಗಿದ್ದುದರಿಂದ ಅವರು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ.",
              "te": "వారు ఆమెకు మంచి ప్యాకేజీ (జీతం మరియు ఇతర ఉద్యోగ ప్రయోజనాలు) అందిస్తారు. స్మార్ట్ ఫోన్‌ల కోసం సాధారణ గేమ్‌లను చేయడానికి మరియు కోడ్ చేయడానికి ఎవరైనా సహాయం చేయాలని వారు ఎదురు చూస్తారు.",
              "or": "ସେମାନେ ତାଙ୍କୁ ଏକ ଭଲ ପ୍ୟାକେଜ୍ (ଦରମା ଏବଂ ଅନ୍ୟାନ୍ୟ ଚାକିରି ସୁବିଧା) ପ୍ରଦାନ\n  କରନ୍ତି | ସ୍ମାର୍ଟଫୋନ୍ ପାଇଁ ସରଳ ଖେଳ ସୃଷ୍ଟି ଏବଂ କୋଡ୍ କରିବାକୁ ସେମାନଙ୍କୁ ସାହାଯ୍ୟ କରିବାକୁ ସେମାନେ ଆଗ୍ରହୀ |",
              "as": "তেওঁলোকে তাইক এটা ভাল পেকেজ আগবঢ়ায় (দৰমহা আৰু অন্যান্য চাকৰিৰ লাভালাভ)।",
              "gu": "તેઓ તેને સારો પગાર આપે છે (પગાર અને અન્ય નોકરીના લાભો). તેઓ સ્માર્ટફોન માટે સરળ ગેમ્સ બનાવવા અને કોડ કરવા માટે કોઈની મદદ કરવા આતુર છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_2_1",
              "hi": "C7_2_1",
              "ka": "C7_2_1",
              "te": "C7_2_1",
              "or": "C7_2_1",
              "as": "C7_2_1",
              "gu": "C7_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shantanu is very excited about his sister working in a gaming company. He visits her city, and she gives him a tour of the company. He jokes that one day they might work together in the same company.",
              "hi": "शांतनु एक गेमिंग कंपनी में काम करने वाली अपनी बहन के बारे में बहुत उत्साहित है। वह उसके शहर जाता है, और वह उसे कंपनी में सब कुछ दिखाती है। वह मज़ाक करता है कि एक दिन वे एक ही कंपनी में एक साथ काम कर सकते हैं।",
              "ka": "ಶಂತನು ತನ್ನ ಅಕ್ಕ ಗೇಮಿಂಗ್ ಕಂಪನಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿರುವ ಬಗ್ಗೆ ತುಂಬಾ ಉತ್ಸುಕನಾಗಿದ್ದಾನೆ. ಅವನು ಅವಳ ನಗರಕ್ಕೆ ಭೇಟಿ ನೀಡುತ್ತಾನೆ ಮತ್ತು ಅವಳು ಅವನಿಗೆ ಕಂಪನಿಯನ್ನು ತೋರಿಸುತ್ತಾಳೆ. ಮುಂದೊಂದು ದಿನ ಅವರು ಒಂದೇ ಕಂಪನಿಯಲ್ಲಿ ಒಟ್ಟಿಗೆ ಕೆಲಸ ಮಾಡಬಹುದು ಎಂದು ಅವನು ತಮಾಷೆ ಮಾಡುತ್ತಾನೆ.",
              "te": "షాంతాను తన సోదరి గేమింగ్ కంపెనీలో పనిచేస్తున్నందుకు చాలా ఉత్సాహంగా ఉన్నాడు. అతను ఆమె నగరాన్ని చూస్తాడు మరియు ఆమె అతనికి కంపెనీని తిప్పి చూపిస్తుంది. అతను మాట్లాడుతూ, ఏదో ఒకరోజు ఒకే కంపెనీలో కలిసి పని చేసే అవకాశం ఉందని జోక్ చేస్తాడు.",
              "or": "ଏକ ଖେଳ କମ୍ପାନୀରେ କାମ କରୁଥିବା ଭଉଣୀ ବିଷୟରେ ଶାନ୍ତନୁ ବହୁତ ଉତ୍ସାହିତ | \n ସେ ତାଙ୍କ ସହର ପରିଦର୍ଶନ କରନ୍ତି, ଏବଂ ସେ ତାଙ୍କୁ କମ୍ପାନୀ ପରିଭ୍ରମଣ କରନ୍ତି | ସେ ଥଟ୍ଟା କରନ୍ତି ଯେ ଦିନେ ସେମାନେ ସମାନ କମ୍ପାନୀରେ ଏକାଠି କାମ କରିପାରନ୍ତି |",
              "as": "শান্তনু তেওঁৰ বায়েকে এটা গেমিং কোম্পানীত কাম কৰাৰ বাবে অতি সুখী। সি তাইৰ চহৰলৈ যায়, আৰু তাই তাক কোম্পানীটো ফুৰায়। তসি ধেমালি কৰে যে এদিন সিহঁতে একেটা কোম্পানীতে একেলগে কাম কৰিব পাৰে।",
              "gu": "શાંતનુ એક ગેમિંગ કંપનીમાં કામ કરતી તેની બહેનને લઈને ખૂબ જ ઉત્સાહિત છે. તે તેના શહેરની મુલાકાત લે છે, અને તે તેની કંપનીની મુલાકાત આપે છે. તે મજાક કરે છે કે એક દિવસ તેઓ એક જ કંપનીમાં સાથે કામ કરી શકે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_2_2",
              "hi": "C7_2_2",
              "ka": "C7_2_2",
              "te": "C7_2_2",
              "or": "C7_2_2",
              "as": "C7_2_2",
              "gu": "C7_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "When she had taken up computer science in college, Mira had never imagined that she’d work in a gaming company someday. But now that she is here, she is happy about discovering this option and following it.",
              "hi": "जब उसने कॉलेज में कंप्यूटर साइंस लिया था, तो मीरा ने कभी कल्पना नहीं की थी कि वह किसी दिन एक गेमिंग कंपनी में काम करेगी। लेकिन अब वह यहां है, वह इस विकल्प को खोजने और इसको अपनाने के लिए खुश है।",
              "ka": "ಕಂಪ್ಯೂಟರ್ ಸೈನ್ಸ್ ಮಾಡುವಾಗ, ಮೀರಾ ತಾನು ಒಂದು ದಿನ ಗೇಮಿಂಗ್ ಕಂಪನಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತೇನೆ ಎಂದು ಊಹಿಸಿಯೇ ಇರಲಿಲ್ಲ. ಆದರೆ ಈಗ ಅವಳು ಇಲ್ಲಿರುವುದರಿಂದ, ಈ ಆಯ್ಕೆಯನ್ನು ಕಂಡುಹಿಡಿದ ಹಾಗೂ ಅದನ್ನು ಮುಂದುವರಿರಿಸಲು ಅವಳು ಸಂತೋಷಗೊಂಡಿದ್ದಾಳೆ.",
              "te": "కంప్యూటర్ సైన్స్ చేస్తున్నప్పుడు, మీరా ఏదో ఒక గేమింగ్ కంపెనీలో పని చేస్తుందని ఊహించలేదు. కానీ ఇప్పుడు ఆమె ఇక్కడ ఉంది, ఆమె ఈ ఆప్షన్ తెలుసుకొని దానిని ఫాలో అవ్వడం పట్ల సంతోషంగా ఉంది.",
              "or": "ଯେତେବେଳେ ସେ କଲେଜରେ କମ୍ପ୍ୟୁଟର ସାଇନ୍ସ ଗ୍ରହଣ କରିଥିଲେ, ମୀରା କେବେ\n  ଭାବି ନଥିଲେ ଯେ ସେ ଦିନେ ଏକ ଗେମିଂ କମ୍ପାନୀରେ କାମ କରିବେ | କିନ୍ତୁ ବର୍ତ୍ତମାନ ସେ ଏଠାରେ ଥିବାରୁ ସେ ଏହି ବିକଳ୍ପ ଆବିଷ୍କାର କରି ଏହାକୁ ଅନୁସରଣ କରି ଖୁସି ଅଛନ୍ତି |",
              "as": "শান্তনু তেওঁৰ বায়েকে এটা গেমিং কোম্পানীত কাম কৰাৰ বাবে অতি সুখী। সি তাইৰ চহৰলৈ যায়, আৰু তাই তাক কোম্পানীটো ফুৰায়। তসি ধেমালি কৰে যে এদিন সিহঁতে একেটা কোম্পানীতে একেলগে কাম কৰিব পাৰে।",
              "gu": "જ્યારે તેએ કોલેજમાં કોમ્પ્યુટર સાયન્સ લીધું હતું, ત્યારે મીરાએ ક્યારેય કલ્પના કરી ન હતી કે તે કોઈ દિવસ ગેમિંગ કંપનીમાં કામ કરશે. પરંતુ હવે તે અહીં છે, તે આ વિકલ્પ શોધવા અને તેને અનુસરવાથી ખુશ છે."
            }
          }
        ],
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C++ Programmer",
              "hi": "सी ++ प्रोग्रामर",
              "ka": "ಸಿ ++ ಪ್ರೋಗ್ರ್ಯಾಮರ್",
              "te": "C++ ప్రోగ్రామర్",
              "or": "C ++ ପ୍ରୋଗ୍ରାମର୍ |",
              "as": "চি++ প্ৰগ্ৰামাৰ",
              "gu": "C++ પ્રોગ્રામર"
            }
          }
        ]
      },
      "scene31": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Take up Biology",
              "hi": "जीवविज्ञान ले ले ",
              "ka": "ಸಸ್ಯ ಶಾಸ್ತ್ರವನ್ನು ತೆಗೆದುಕೊಳ್ಳುವುದೇ",
              "te": "జీవశాస్త్రం తీసుకోండి",
              "or": "ବାୟୋଲୋଜି ନିଅ |",
              "as": "জীৱবিজ্ঞান পঢ়ক",
              "gu": "જીવ વિજ્ઞાન રાખો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira decides to take Biology in her 11th and 12th. She studies well and works hard in all the subjects. She spends a lot of time studying with Lina.",
              "hi": "मीरा ने ग्यारहवीं और बारहवीं में जीवविज्ञान लेने का फैसला किया। वह अच्छे से अध्ययन करती है और सभी विषयों में कड़ी मेहनत करती है। वह लीना के साथ अध्ययन करने में काफी समय बिताती है।",
              "ka": "ಮೀರಾ ತನ್ನ 11 ಮತ್ತು 12 ನೇ ವಯಸ್ಸಿನಲ್ಲಿ ಜೀವಶಾಸ್ತ್ರವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಅವಳು ಚೆನ್ನಾಗಿ ಓದುತ್ತಾಳೆ ಮತ್ತು ಎಲ್ಲಾ ವಿಷಯಗಳಲ್ಲಿ ಕಷ್ಟಪಟ್ಟು ಕೆಲಸ ಮಾಡುತ್ತಾಳೆ. ಅವಳು ಲೀನಾಳೊಂದಿಗೆ ಪಾಠಗಳನ್ನು ಅಧ್ಯಯನ ಮಾಡಲು ಸಾಕಷ್ಟು ಸಮಯವನ್ನು ಕಳೆಯುತ್ತಾಳೆ.",
              "te": "మీరా తన 11వ మరియు 12వ వయస్సులో జీవశాస్త్రం తీసుకోవాలని నిర్ణయించుకుంది. ఆమె బాగా చదువుతుంది మరియు అన్ని సబ్జెక్టులలో కష్టపడి పని చేస్తుంది. ఆమె లీనాతో చాలా సమయం చదువుతుంది.",
              "or": "ମୀରା ଏକାଦଶ ଏବଂ ଦ୍ୱାଦଶରେ ବାୟୋଲୋଜି ନେବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ସେ\n  ଭଲ ଅଧ୍ୟୟନ କରନ୍ତି ଏବଂ ସମସ୍ତ ବିଷୟଗୁଡ଼ିକରେ କଠିନ ପରିଶ୍ରମ କରନ୍ତି | ସେ ଲୀନାଙ୍କ ସହିତ ପଢିବା ପାଇଁ ବହୁତ ସମୟ ବିତାନ୍ତି |",
              "as": "মীৰাই তাইৰ ১১ আৰু দ্বাদশ ত জীৱবিজ্ঞান লোৱাৰ সিদ্ধান্ত ল'লে। তাই ভালদৰে অধ্যয়ন কৰে আৰু সকলো বিষয়তে ভালকৈ কষ্ট কৰে। তাই লীনাৰ সৈতে অধ্যয়ন ত বহুসময় পাৰ কৰে।",
              "gu": "મીરાએ 11મા અને 12મા ધોરણમાં જીવ વિજ્ઞાન લેવાનું નક્કી કર્યું. તે સારી રીતે અભ્યાસ કરે છે અને તમામ વિષયોમાં સખત મહેનત કરે છે. તે લીના સાથે અભ્યાસ કરવામાં ઘણો સમય વિતાવે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_1_2",
              "hi": "B1_1_2",
              "ka": "B1_1_2",
              "te": "B1_1_2",
              "or": "B1_1_2",
              "as": "B1_1_2",
              "gu": "B1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "While they are studying at home, Lina’s aunt, Vasudha, comes home one day. She is a doctor.",
              "hi": "एक दिन जब वे घर पर पढ़ रहे हैं, लीना की चाची, वसुधा, घर आती हैं। वह एक डॉक्टर हैं।",
              "ka": "ಅವರು ಮನೆಯಲ್ಲಿ ಓದುತ್ತಿರುವಾಗ, ಲೀನಾಳ ಆಂಟಿ ವಸುಧಾ ಒಂದು ದಿನ ಮನೆಗೆ ಬರುತ್ತಾಳೆ. ಅವಳು ಒಬ್ಬ ವೈದ್ಯೆ ಆಗಿದ್ದಾಳೆ.",
              "te": "వాళ్ళు ఇంట్లో చదువుకుంటున్నప్పుడు, లీనా అత్త వసుధ ఒకరోజు ఇంటికి వస్తుంది. ఆమె వైద్యురాలు.",
              "or": "ସେମାନେ ଘରେ ପଢୁଥିବାବେଳେ ଲୀନାଙ୍କ ମାଉସୀ ବସୁଦା ଦିନେ ଘରକୁ ଆସନ୍ତି | \n ସେ ଜଣେ ଡାକ୍ତର।",
              "as": "সিহঁতে ঘৰত পঢ়ি থাকোতে, লীনাৰ খুড়ী বসুধা এদিন ঘৰলৈ আহে। তাই এগৰাকী চিকিৎসক।",
              "gu": "જ્યારે તેઓ ઘરે અભ્યાસ કરી રહ્યા હતા, ત્યારે લીનાની કાકી, વસુધા, એક દિવસ ઘરે આવે છે. તે ડોક્ટર છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_1_3",
              "hi": "B1_1_3",
              "ka": "B1_1_3",
              "te": "B1_1_3",
              "or": "B1_1_3",
              "as": "B1_1_3",
              "gu": "B1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She talks to both the girls about how she used to help her friends with their studies after school. She tells them about how she joined medical college and what she learned.",
              "hi": "वह दोनों लड़कियों से बात करती है कि वह अध्ययन के साथ स्कूल के बाद अपने मित्रों की सहायता कैसे किया करती थीं। वह उन्हें बताती हैं कि वह कैसे मेडिकल कॉलेज में  शामिल हुई और उन्होंने क्या सीखा।",
              "ka": "ತಾನು  ಶಾಲೆಯ ನಂತರ ತನ್ನ ಸ್ನೇಹಿತರಿಗೆ ಅವರ ಅಧ್ಯಯನದಲ್ಲಿ ಹೇಗೆ ಸಹಾಯ ಮಾಡುತ್ತಿದ್ದೆ ಎನ್ನುವುದರ ಕುರಿತು ಆಕೆಯೂ ಇಬ್ಬರೂ ಹುಡುಗಿಯರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾಳೆ. ಅವಳು ವೈದ್ಯಕೀಯ ಕಾಲೇಜಿಗೆ ಹೇಗೆ ಸೇರಿಕೊಂಡಳು ಹಾಗೂ ಹೇಗೆ ಎಲ್ಲವನ್ನೂ ಕಳಿತಳು ಎಂದು ಹೇಳುತ್ತಾಳೆ.",
              "te": "స్కూల్ తర్వాత తన స్నేహితుల చదువులో ఎలా సహాయం చేసేది అనే విషయం గురించి ఆమె ఇద్దరు అమ్మాయిలతో మాట్లాడుతుంది. తను మెడికల్ కాలేజీలో ఎలా చేరింది, నేర్చుకున్న విషయాల గురించి చెబుతుంది.",
              "or": "ସେ ଉଭୟ ଝିଅଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରନ୍ତି ଯେ ସେ କିପରି ନିଜ ସାଙ୍ଗମାନଙ୍କୁ ବିଦ୍ୟାଳୟ\n  ପରେ ସେମାନଙ୍କ ଅଧ୍ୟୟନରେ ସାହାଯ୍ୟ କରୁଥିଲେ | ସେ କିପରି ମେଡିକାଲ୍ କଲେଜରେ ଯୋଗ ଦେଇଥିଲେ ଏବଂ ସେ ଯାହା ଶିଖିଲେ ସେ ବିଷୟରେ ସେ ସେମାନଙ୍କୁ କୁହନ୍ତି |",
              "as": "তাই দুয়োজনী ছোৱালীৰ লগত কথা পাতিছিল যে তাই কেনেকৈ তাইৰ বন্ধুবোৰক স্কুলৰ পিছত পঢ়াশুনাত সহায় কৰিছিল। তেওঁ সিহঁতক কৈছিল কেনেকৈ তাই চিকিৎসা মহাবিদ্যালয়ত যোগদান কৰিছিল আৰু তাত কি শিকিছিল।",
              "gu": "તે બંને છોકરીઓ સાથે વાત કરે છે કે તે શાળા પછી તેના મિત્રોને તેમના અભ્યાસમાં કેવી રીતે મદદ કરતી હતી. તે મેડિકલ કોલેજમાં કેવી રીતે જોડાઈ અને  શું શીખ્યા તે વિશે તે તેમને જણાવે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_1_4",
              "hi": "B1_1_4",
              "ka": "B1_1_4",
              "te": "B1_1_4",
              "or": "B1_1_4",
              "as": "B1_1_4",
              "gu": "B1_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is very curious about Vasudha aunty's journey to becoming a doctor. Vasudha invites them to help her at a medical camp in a nearby village.",
              "hi": "मीरा वसुधा चाची की डॉक्टर बनने की यात्रा के बारे में बहुत उत्सुक है। वसुधा ने उन्हें पास के गांव में एक चिकित्सा शिविर में सहायता करने के लिए आमंत्रित किया।",
              "ka": "ವಸುಧಾ ಆಂಟಿ ಡಾಕ್ಟರ್ ಆಗಲು ಕೈಗೊಂಡ ಪ್ರಯಾಣದ ಬಗ್ಗೆ ತಿಳಿಯಲು ಮೀರಾಗೆ ತುಂಬಾ ಕುತೂಹಲವಿದೆ. ವಸುಧಾ ಅವರನ್ನು ಹತ್ತಿರದ ಹಳ್ಳಿಯ ವೈದ್ಯಕೀಯ ಶಿಬಿರದಲ್ಲಿ ತನಗೆ ಸಹಾಯ ಮಾಡಲು ಆಹ್ವಾನಿಸುತ್ತಾಳೆ.",
              "te": "వసుధ ఆంటీ డాక్టర్ అయ్యే ప్రయాణం గురించి మీరా చాలా ఆసక్తిగా ఉంది. వసుధ దగ్గ్రర గ్రామంలోని వైద్య శిబిరంలో ఆమెకు సహాయం చేయమని వారిని ఆహ్వానిస్తుంది.",
              "or": "ଡାକ୍ତର ହେବା ପାଇଁ ବସୁଧା ମାଉସୀଙ୍କ ଯାତ୍ରା ବିଷୟରେ ମୀରା ବହୁତ ଆଗ୍ରହୀ |\n  ନିକଟସ୍ଥ ଏକ ଗାଁର ଏକ ମେଡିକାଲ କ୍ୟାମ୍ପରେ ତାଙ୍କୁ ସାହାଯ୍ୟ କରିବାକୁ ଭାସୁଦା ସେମାନଙ୍କୁ ନିମନ୍ତ୍ରଣ କରନ୍ତି |",
              "as": "মীৰা বসুধা আণ্টিৰ চিকিৎসক হোৱাৰ ঘটনা বিষয়ে বহুত জানিবলৈ বিচাৰে। বসুধাই তেওঁলোকক ওচৰৰ এখন গাঁৱৰ চিকিৎসা শিবিৰত সহায় কৰিবলৈ মাতিলে।",
              "gu": "મીરા વસુધા માસીની ડૉક્ટર બનવાની સફર વિશે ખૂબ જ ઉત્સુક છે. વસુધા તેમને નજીકના ગામમાં મેડિકલ કેમ્પમાં મદદ કરવા આમંત્રણ આપે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_2_1",
              "hi": "B1_2_1",
              "ka": "B1_2_1",
              "te": "B1_2_1",
              "or": "B1_2_1",
              "as": "B1_2_1",
              "gu": "B1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "At the camp, Lina and Mira help at the registration desk. They write the patients' names, give them token numbers, and send them to their respective doctors.",
              "hi": "शिविर में, लीना और मीरा पंजीकरण डेस्क में सहायता करते हैं। वे रोगियों के नाम लिखते हैं, उन्हें टोकन नंबर देते हैं, और उन्हें उनके संबंधित डॉक्टर के पास भेजते हैं।",
              "ka": "ಶಿಬಿರದಲ್ಲಿ, ಲೀನಾ ಮತ್ತು ಮೀರಾ ನೋಂದಣಿ ಮೇಜಿನ ಬಳಿ ಸಹಾಯ ಮಾಡುತ್ತಾರೆ. ಅವರು ರೋಗಿಗಳ ಹೆಸರನ್ನು ಬರೆದು, ಟೋಕನ್ ಸಂಖ್ಯೆಗಳನ್ನು ನೀಡಿ, ಆಯಾ ವೈದ್ಯರುಗಳ ಬಳಿ ಕಳುಹಿಸುತ್ತಾರೆ.",
              "te": "శిబిరంలో, లినా మరియు మీరా రిజిస్ట్రేషన్ డెస్క్ వద్ద సహాయం చేస్తారు. పేషెంట్ల పేర్లు రాసి, టోకెన్ నెంబర్లు ఇచ్చి దానికి సంబందించిన వైద్యుల వద్దకు పంపిస్తారు.",
              "or": "କ୍ୟାମ୍ପରେ ଲୀନା ଏବଂ ମୀରା ପଂଜୀକରଣ ଡେସ୍କରେ ସାହାଯ୍ୟ କରନ୍ତି | \n ସେମାନେ ରୋଗୀଙ୍କ ନାମ ଲେଖନ୍ତି, ସେମାନଙ୍କୁ ଟୋକନ୍ ନମ୍ବର ଦିଅନ୍ତି ଏବଂ ସେମାନଙ୍କୁ ନିଜ ନିଜ ଡାକ୍ତରଙ୍କ ନିକଟକୁ ପଠାନ୍ତି |",
              "as": "শিবিৰত, লীনা আৰু মীৰাই পঞ্জীয়ন ডেস্কত সহায় কৰে। তেওঁলোকে ৰোগীসকলৰ নাম লিখিছে, তেওঁলোকক টোকেন নম্বৰ দিয়ে, আৰু সেইবোৰ তেওঁলোকৰ নিজ নিজ চিকিৎসকলৈ পঠাই দিয়ে।",
              "gu": "શિબિરમાં, લીના અને મીરા રજીસ્ટ્રેશન ડેસ્ક પર મદદ કરે છે. તેઓ દર્દીઓના નામ લખે છે, તેમને ટોકન નંબર આપે છે અને તેમના સંબંધિત ડોકટરોને મોકલે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_2_2",
              "hi": "B1_2_2",
              "ka": "B1_2_2",
              "te": "B1_2_2",
              "or": "B1_2_2",
              "as": "B1_2_2",
              "gu": "B1_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Within a few hours, the camp is filled with people. There is a mobile medical lab at the camp that does basic blood tests and immunizations for children.",
              "hi": "कुछ घंटों के भीतर, शिविर लोगों से भर जाता है। शिविर में एक मोबाइल मेडिकल लैब है जो बच्चों के लिए मूल रक्त परीक्षण और टीकाकरण करता है।",
              "ka": "ಕೆಲವೇ ಗಂಟೆಗಳಲ್ಲಿ, ಶಿಬಿರವು ಜನರಿಂದ ತುಂಬಿರುತ್ತದೆ. ಶಿಬಿರದಲ್ಲಿ ಒಂದು ಸಂಚಾರಿ ವೈದ್ಯಕೀಯ ಪ್ರಯೋಗಾಲಯವಿದ್ದು, ಇದು ಮಕ್ಕಳಿಗೆ ಮೂಲಭೂತ ರಕ್ತ ಪರೀಕ್ಷೆ ಮಾಡುತ್ತದೆ ಹಾಗೂ ಲಸಿಕೆಗಳನ್ನು ನೀಡುತ್ತದೆ.",
              "te": "కొన్ని గంటల్లోనే శిబిరం జనంతో నిండిపోయింది. శిబిరంలో మొబైల్ మెడికల్ ల్యాబ్ ఉంది, ఇది పిల్లలకు ప్రాథమిక రక్త పరీక్షలు మరియు వ్యాధి నిరోధక టీకాలు చేస్తుంది.",
              "or": "କିଛି ଘଣ୍ଟା ମଧ୍ୟରେ, ଶିବିର ଲୋକମାନଙ୍କ ସହିତ ପରିପୂର୍ଣ୍ଣ | ଶିବିରରେ ଏକ ମୋବାଇଲ୍\n  ମେଡିକାଲ୍ ଲ୍ୟାବ୍ ଅଛି ଯାହା ପିଲାମାନଙ୍କ ପାଇଁ ମ basic ଳିକ ରକ୍ତ ପରୀକ୍ଷା ଏବଂ ପ୍ରତିରୋପଣ କରିଥାଏ |",
              "as": "কেইঘণ্টামানৰ ভিতৰতে, শিবিৰটো মানুহেৰে ভৰি পৰিলে। শিবিৰত এটা চলন্ত চিকিৎসা পৰীক্ষাগাৰ আছে যিয়ে শিশুসকলৰ বাবে তেজ পৰীক্ষা আৰু টিকাকৰণ কৰে।",
              "gu": "થોડા જ કલાકોમાં કેમ્પ લોકોથી ભરાઈ જાય છે. કેમ્પમાં એક મોબાઈલ મેડિકલ લેબ છે જે બાળકો માટે મૂળભૂત રક્ત પરીક્ષણો અને રસીકરણ કરે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_2_3",
              "hi": "B1_2_3",
              "ka": "B1_2_3",
              "te": "B1_2_3",
              "or": "B1_2_3",
              "as": "B1_2_3",
              "gu": "B1_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Four doctors attend to the adults, and two doctors attend to the children. By the end of the day, Mira is tired but happy.",
              "hi": "चार डॉक्टर वयस्कों का परीक्षण करते हैं, और दो डॉक्टर बच्चों को देखते हैं। दिन के अंत तक, मीरा थक गई लेकिन खुश है।",
              "ka": "ನಾಲ್ಕು ವೈದ್ಯರು ವಯಸ್ಕರಿಗೆ ಮತ್ತು ಇಬ್ಬರು ವೈದ್ಯರು ಮಕ್ಕಳಿಗೆ ಚಿಕಿತ್ಸೆ ನೀಡುತ್ತಾರೆ. ದಿನದ ಅಂತ್ಯದ ವೇಳೆಗೆ, ಮೀರಾ ದಣಿದಿದ್ದರೂ ತುಂಬಾ ಸಂತೋಷವಾಗಿರುತ್ತಾಳೆ.",
              "te": "పెద్దలకు నలుగురు డాక్టర్లు, పిల్లలకు ఇద్దరు డాక్టర్లు. రోజు ముగిసే సమయానికి మీరా అలసిపోయినప్పటికీ సంతోషంగా ఉంది.",
              "or": "ଚାରିଜଣ ଡାକ୍ତର ବୟସ୍କଙ୍କ ନିକଟକୁ ଯାଆନ୍ତି ଏବଂ ଦୁଇଜଣ ଡାକ୍ତର ପିଲାମାନଙ୍କ \n ନିକଟରେ ଉପସ୍ଥିତ ହୁଅନ୍ତି | ଦିନ ଶେଷରେ, ମୀରା କ୍ଳାନ୍ତ କିନ୍ତୁ ଖୁସି |",
              "as": "চাৰিজন চিকিৎসকে বেছি বয়সৰ মানুহখিনিৰ ওচৰলৈ যায়, আৰু দুজন চিকিৎসকে শিশুসকলৰ ওচৰলৈ যায়। দিনটোৰ শেষলৈ, মীৰাৰ ভাগৰ লাগে কিন্তু তাই সুখী।",
              "gu": "ચાર ડોકટરો પુખ્ત વયના લોકો માટે અને બે ડોકટરો બાળકો માટે હાજરી આપે છે. દિવસના અંત સુધીમાં મીરા થાકેલી છે પણ ખુશ છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_1",
              "hi": "B1_3_1",
              "ka": "B1_3_1",
              "te": "B1_3_1",
              "or": "B1_3_1",
              "as": "B1_3_1",
              "gu": "B1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They decide to take a bus on the way back. Mira sits next to Vasudha aunty.",
              "hi": "वे वापस जाने के लिए बस लेने का फैसला करते हैं। मीरा वसुधा चाची के बगल में बैठती है।",
              "ka": "ಅವರು ಹಿಂತಿರುಗುವ ಮಾರ್ಗದಲ್ಲಿ ಬಸ್ ತೆಗೆದುಕೊಳ್ಳಲು ನಿರ್ಧರಿಸುತ್ತಾರೆ. ಮೀರಾ ವಸುಧಾ ಆಂಟಿ ಪಕ್ಕದಲ್ಲಿ ಕುಳಿತುಕೊಲ್ಲುತ್ತಾಳೆ.",
              "te": "వారు తిరుగు ప్రయాణంలో బస్సు ఎక్కాలని నిర్ణయించుకున్నారు. మీరా వసుధ ఆంటీ పక్కన కూర్చుంది.",
              "or": "ସେମାନେ ଫେରିବା ବାଟରେ ଏକ ବସ୍ ନେବାକୁ ସ୍ଥିର କରନ୍ତି | ମୀରା ବସୁଧା ମାଉସୀ\n  ପାଖରେ ବସିଥାଏ |",
              "as": "তেওঁলোকে উভতি অহাৰ ৰাষ্টাত এখন বাছ উঠে। মীৰা বসুধা আণ্টিৰ কাষত বহে।",
              "gu": "તેઓ પાછા ફરતી વખતે બસ લેવાનું નક્કી કરે છે. મીરા વસુધા કાકી પાસે બેઠી."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_2 bg",
              "hi": "B1_3_2 बीजी",
              "ka": "B1_3_2 bg",
              "te": "B1_3_2 bg",
              "or": "B1_3_2 bg",
              "as": "B1_3_2 bg",
              "gu": "B1_3_2 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Vasudha aunt",
              "hi": "वसुधा चाची",
              "ka": "ವಸುದ ಆಂಟಿ",
              "te": "వసుధ ఆంటీ",
              "or": "ବସୁଧା ମାଉସୀ",
              "as": "বসুধা খুড়ী",
              "gu": "વસુધા કાકી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Vasudha aunty, did you always know you wanted to be a doctor?",
              "hi": "वसुधा चाची, क्या आप हमेशा से जानती थीं कि आप डॉक्टर बनना चाहती हैं?",
              "ka": "ವಸುಧಾ ಆಂಟಿ, ನೀವು ಮೊದಲಿನಿಂದಲೂ ಡಾಕ್ಟರ್ ಆಗಬೇಕೆಂದು ನಿಮಗೆ ತಿಳಿದಿತ್ತೇ?",
              "te": "వసుధ ఆంటీ, మీకు డాక్టర్ కావాలని ఎప్పుడు అనిపించింది?",
              "or": "ବସୁଧା ମାଉସୀ, ତୁମେ ସବୁବେଳେ ଜାଣିଥିଲ କି ତୁମେ ଡାକ୍ତର ହେବାକୁ ଚାହୁଁଛ?",
              "as": "বসুধা খুড়ী, আপুনি সদায় জানিছিলনে যে আপুনি এজন চিকিৎসক হ'ব বিচাৰে বুলি?",
              "gu": "વસુધા કાકી, શું તમે હંમેશા જાણતા હતા કે તમે ડૉક્ટર બનવા માંગો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "The town I grew up in had no big hospital, and we had to travel a long distance to reach a medical facility. This bothered me. My family also wanted me to do medicine and supported me throughout my journey.",
              "hi": "जिस शहर में मैं बड़ी हई, उसके पास कोई बड़ा अस्पताल नहीं था, और हमें चिकित्सा सुविधा तक पहुंचने के लिए लंबी दूरी की यात्रा करनी पड़ती थी। इस बात ने मुझे परेशान किया। मेरा परिवार भी चाहता था कि मैं मेडिकल की पढाई करूं और उन्होंने मेरी यात्रा में मुझे समर्थन दिया।",
              "ka": "ನಾನು ಬೆಳೆದ ಪಟ್ಟಣದಲ್ಲಿ ದೊಡ್ಡ ಆಸ್ಪತ್ರೆ ಇರಲಿಲ್ಲ, ಮತ್ತು ವೈದ್ಯಕೀಯ ಸೌಲಭ್ಯವನ್ನು ಪಡೆಯಲು ನಾವು ಬಹಳ ದೂರ ಹೋಗಬೇಕಾಗಿತ್ತು. ಇದು ನನಗೆ ಬೇಸರ ತಂದಿತು. ನನ್ನ ಕುಟುಂಬದವರು ಸಹ ನಾನು ವೈದ್ಯಕೀಯವನ್ನು  ಮಾಡಬೇಕೆಂದು ಬಯಸಿದ್ದರು ಹಾಗೂ ನನ್ನ ಸಂಪೂರ್ಣ ದಾರಿಯಲ್ಲಿ ನನಗೆ ಬೆಂಬಲ ನೀಡಿದರು",
              "te": "నేను పెరిగిన ఊరికి పెద్ద హాస్పిటల్ లేదు, వైద్య సదుపాయం కోసం మేము చాలా దూరం ప్రయాణించాల్సి వచ్చింది. ఇది నన్ను ఇబ్బంది పెట్టింది. నా కుటుంబం కూడా నేను మెడిసిన్ చేయాలని కోరుకుంది మరియు నా ప్రయాణంలో నాకు సపోర్ట్ దొరికింది.",
              "or": "ମୁଁ ଯେଉଁ ସହରରେ ବଢିଥିଲି ସେଠାରେ କୌଣସି ବଡ ଡାକ୍ତରଖାନା ନଥିଲା, ଏବଂ ଏ\n କ ଚିକିତ୍ସା ସୁବିଧା ପାଇଁ ଆମକୁ ବହୁ ଦୂର ଯାତ୍ରା କରିବାକୁ ପଡିଲା | ଏହା ମୋତେ ବ୍ୟଥିତ କଲା | ମୋ ପରିବାର ମଧ୍ୟ ଚାହୁଁଥିଲେ ଯେ ମୁଁ ଔଷଧ ପଢିବି ଏବଂ ମୋର ସମସ୍ତ ଯାତ୍ରା ସମୟରେ ମୋତେ ସାହାଯ୍ୟ କରିବ |",
              "as": "মই ডাঙৰ হোৱা চহৰখনত কোনো ডাঙৰ চিকিৎসালয় নাছিল, আৰু আমি চিকিৎসা পাবলৈ বহুত দূৰ যাব লগীয়া হৈছিল। এইটোৱে মোক চিন্তাত পেলাইছিল। মোৰ পৰিয়ালেও বিচাৰিছিল যে মই মেডিকেল পঢ়ো আৰু গোটেই সময়খিনিত মোক লগ দিছিল।",
              "gu": "હું જે શહેરમાં ઉછરી હટી ત્યાં કોઈ મોટી હોસ્પિટલ નહોતી, અને અમારે તબીબી સુવિધા સુધી પહોંચવા માટે લાંબું અંતર કાપવું પડતું હતું. આ મને હેરાન કરતું હતું. મારો પરિવાર પણ ઇચ્છતો હતો કે હું તબીબી વિષે ભાણું અને સમગ્ર પ્રવાસ દરમિયાન મને સાથ આપ્યો."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_3///",
              "hi": "B1_3_3///",
              "ka": "B1_3_3///",
              "te": "B1_3_3///",
              "or": "B1_3_3///",
              "as": "B1_3_3///",
              "gu": "B1_3_3///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Lina says you studied for ten years.",
              "hi": "लीना का कहना है कि आपने दस साल तक अध्ययन किया।",
              "ka": "ಲೀನ ಹೇಳ್ತಾ ಇದ್ದಳು, ನೀವು ಪೂರ್ತಿ ಹತ್ತು ವರ್ಷ ಒದಿದ್ದೀರಿ ಅಂತ.",
              "te": "మీరు పదేళ్లు చదువుకున్నారని లీనా చెప్పింది.",
              "or": "ଲୀନା କୁହନ୍ତି ତୁମେ ଦଶ ବର୍ଷ ପର୍ଯ୍ୟନ୍ତ ପଢିଛ |",
              "as": "লীনাই কৈছে আপুনি দহ বছৰ পঢ়িছিল।",
              "gu": "લીના કહે છે કે તમે દસ વર્ષ ભણ્યા."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes! Most doctors do that. In fact, I am going to England next year for one more year to study.",
              "hi": "हां! अधिकांश डॉक्टर ऐसा करते हैं। वास्तव में, मैं अगले वर्ष इंग्लैंड में एक और साल के लिए अध्ययन करने जा रही हूं।",
              "ka": "ಹೌದು! ಹೆಚ್ಚಿನ ವೈದ್ಯರು ಇದನ್ನು ಮಾಡುತ್ತಾರೆ. ವಾಸ್ತವವಾಗಿ, ನಾನು ಇನ್ನೂ ಒಂದು ವರ್ಷ ಅಧ್ಯಯನ ಮಾಡಲು ಮುಂದಿನ ವರ್ಷ ಇಂಗ್ಲೆಂಡ್‌ಗೆ ಹೋಗುತ್ತಿದ್ದೇನೆ.",
              "te": "సరైన! చాలామంది వైద్యులు అలా చేస్తారు. నిజానికి, నేను ఇంకో సంవత్సరం చదువుకోవడానికి వచ్చే ఏడాది ఇంగ్లండ్ వెళ్తున్నాను.",
              "or": "ହଁ! ଅଧିକାଂଶ ଡାକ୍ତର ତାହା କରନ୍ତି | ବାସ୍ତବରେ, ମୁଁ ପଢି\n ବାକୁ ଆଉ ଏକ ବର୍ଷ ପାଇଁ ଆସନ୍ତା ବର୍ଷ ଇଂଲଣ୍ଡ ଯାଉଛି |",
              "as": "হয়! বেছিভাগ চিকিৎসকে এনে কৰে। আচলতে, মই অহা বছৰ পঢ়িবলৈ আৰু এবছৰৰ বাবে ইংলেণ্ডলৈ যাম।",
              "gu": "હા! મોટાભાગના ડોકટરો તે કરે છે. વાસ્તવમાં, હું અભ્યાસ માટે આવતા વર્ષે વધુ એક વર્ષ માટે ઈંગ્લેન્ડ જઈ રહી છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Oh! But don’t you get tired? It must be so much stress!",
              "hi": "ओह! लेकिन क्या आप थकती नहीं हैं? यह कितना तनावपूर्ण होगा!",
              "ka": "ಓಹ್! ಆದರೆ ನಿಮಗೆ ಸುಸ್ತಾಗುವುದಿಲ್ಲವೇ? ಇದು ತುಂಬಾ ಒತ್ತಡ ನೀಡುತ್ತಿರಬೇಕು!",
              "te": "ఓ! కానీ మీరు అలసిపోలేదా? ఇది చాలా ఒత్తిడి ఉండాలి!",
              "or": "ଓଃ ! କିନ୍ତୁ ତୁମେ କ୍ଳାନ୍ତ ହୁଅ ନାହିଁ କି? ଏହା ନିଶ୍ଚୟ ଏତେ ଚାପ ଦାଯକ!",
              "as": "ওহ! কিন্তু আপুনাৰ ভাগৰ নালাগে নেকি? এইটোত বহুত টেন্চন হব চাগে।",
              "gu": "ઓહ! પણ તમે થાકતા નથી? તે ખૂબ જ તણાવ હશે!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Of course, I am tired, and it is stressful, but nothing beats the satisfaction you get when patients begin to recover.",
              "hi": "बेशक, मैं थक गई हूं, और यह तनावपूर्ण है, लेकिन रोगियों को पुनर्जीवन देने के लिए आपको प्राप्त होने वाली संतुष्टि के सामने कुछ भी नहीं  है।",
              "ka": "ಸಹಜವಾಗಿ, ನಾನು ದಣಿದಿದ್ದೇನೆ ಮತ್ತು ಅದು ಒತ್ತಡದಿಂದಲೂ ಕೂಡಿರುತ್ತದೆ, ಆದರೆ ರೋಗಿಗಳು ಚೇತರಿಸಿಕೊಳ್ಳಲು ಪ್ರಾರಂಭಿಸಿದಾಗ ನೀವು ಪಡೆಯುವ ತೃಪ್ತಿಯನ್ನು ಯಾವುದೂ ಮೀರಿಸುವುದಿಲ್ಲ.",
              "te": "వాస్తవానికి నేను అలసిపోయాను, అది కూడా ఒత్తిడితో కూడుకున్నది, కానీ మీ రోగులు కోలుకుంటున్నారని చూడటం కంటే సంతృప్తికరంగా ఏమీ లేదు.",
              "or": "ଅବଶ୍ୟ, ମୁଁ କ୍ଳାନ୍ତ ହୋଇପଡ଼ିଛି, ଏବଂ ଏହା ଚାପଗ୍ରସ୍ତ, କିନ୍ତୁ ରୋଗୀମାନେ ସୁସ୍ଥ \n ହେବାକୁ ଆରମ୍ଭ କଲାବେଳେ ତୁମେ ପାଇଥିବା ସନ୍ତୁଷ୍ଟିକୁ ଏହା କିଛି ବି ଆଘାତ କରେ ନାହିଁ |",
              "as": "হুম , মই সঁচাকৈয়ে ভাগৰুৱা, আৰু ই কষ্টকৰ, কিন্তু ৰোগীসকলে ভাল হ'বলৈ আৰম্ভ কৰিলে আপুনি লাভ কৰা সন্তুষ্টিক একোৱেই হৰুৱাব নোৱাৰে।",
              "gu": "અલબત્ત, હું થાકી ગઇ છું, અને તે તણાવપૂર્ણ છે, પરંતુ જ્યારે દર્દીઓ સાજા થવાનું શરૂ કરે છે ત્યારે તને જે સંતોષ મળે છે તેના કરતાં કંઈપણ વધારે મને મળે છે હરાવી શકતું નથી."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_4",
              "hi": "B1_3_4",
              "ka": "B1_3_4",
              "te": "B1_3_4",
              "or": "B1_3_4",
              "as": "B1_3_4",
              "gu": "B1_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is so inspired by the work the doctors do and feels she should also do something similar in her life. She cannot wait to inform Neetu Aunty that she will be the first doctor in the family.",
              "hi": "मीरा डॉक्टरों के काम से इतनी प्रेरित है और अनुभव करती है कि उसे भी अपने जीवन में कुछ  करना चाहिए। वह नीतू चाची को सूचित करने के लिए प्रतीक्षा नहीं कर सकती है कि वह परिवार में पहली डॉक्टर होगी।",
              "ka": "ವೈದ್ಯರು ಮಾಡುವ ಕೆಲಸದಿಂದ ಮೀರಾ ತುಂಬಾ ಪ್ರೇರಿತಳಾಗಿದ್ದಾಳೆ ಮತ್ತು ಅವಳು ತನ್ನ ಜೀವನದಲ್ಲಿ ಇದೇ ರೀತಿಯ ಏನಾದರೂ ಮಾಡಬೇಕು ಎಂದು ಭಾವಿಸುತ್ತಾಳೆ. ನೀತು ಆಂಟಿಗೆ ತಾನು ಕುಟುಂಬದಲ್ಲಿನ ಮೊದಲ ವೈದ್ಯೆ ಎಂದು ತಿಳಿಸಲು ಅವಳು ಕಾತುರದಿಂದಿದ್ದಾಳೆ.",
              "te": "మీరా వైద్యులు చేసే పని నుండి చాలా ఇన్స్పైర్ అయింది. ఆమె తన జీవితంలో కూడా అలాంటిదే ఏదైనా చేయాలని భావిస్తుంది. ఆమె కుటుంబంలో మొదటి డాక్టర్ అవుతానని నీతు ఆంటీకి వెంటనే చెప్పింది.",
              "or": "ଡାକ୍ତରମାନେ କରୁଥିବା କାର୍ଯ୍ୟ ଦ୍ୱାରା ମୀରା ଏତେ ଅନୁପ୍ରାଣିତ ଏବଂ ତାଙ୍କ ଜୀବନରେ\n  ମଧ୍ୟ ସେପରି କିଛି କରିବା ଉଚିତ୍ ବୋଲି ଅନୁଭବ କରନ୍ତି | ସେ ନୀଟୁ ମାଉସୀଙ୍କୁ ଜଣାଇବାକୁ ଅପେକ୍ଷା କରିପାରିବେ ନାହିଁ ଯେ ସେ ପରିବାରର ପ୍ରଥମ ଡାକ୍ତର ହେବେ |",
              "as": "মীৰা চিকিৎসকসকলে কৰা কামৰ দ্বাৰা ইমান অনুপ্ৰাণিত আৰু অনুভৱ কৰে যে তাইও তাইৰ জীৱনত একেধৰণৰ কিবা কৰা উচিত। তাই নীতু আণ্টিক জনাবলৈ অপেক্ষা কৰিব নোৱাৰে যে তাই পৰিয়ালৰ প্ৰথম চিকিৎসক হ'ব।",
              "gu": "મીરા ડોકટરોના કામથી ખૂબ પ્રેરિત છે અને તેને લાગે છે કે તેને પણ તેના જીવનમાં કંઈક આવું જ કરવું જોઈએ. તે નીતુ કાકીને જણાવવા માટે રાહ જોઈ શકતી નથી કે તે પરિવારમાં પ્રથમ ડૉક્ટર હશે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "job shadowing",
              "hi": "नौकरी का अनुसरण ",
              "ka": "ಕೆಲಸದ ನೆರಳು",
              "te": "ఉద్యోగం నీడ",
              "or": "ଚାକିରି ଛାୟା",
              "as": "চাকৰিৰ ছাঁ",
              "gu": "કોઈના નીચે કામ કરવું"
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "ಎಮ್‌ಸಿ‌ಕ್ಯೂ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Mira spends time shadowing Lina’s aunt which helps her to pick medicine and make a career out of it. Which of the following is a good reason to do a job shadow:",
              "hi": "मीरा ने लीना की चाची का अनुसरण करने में समय बिताया जो उसे मेडिकल लेने और इससे करियर बनाने में सहायक होता है। निम्नलिखित में से कौन सा नौकरी का अनुसरण करने का एक अच्छा कारण है:",
              "ka": "ಮೀರಾ ಲೀನಾಳ ಆಂಟಿಯ ಸಹವಾಸದಲ್ಲಿ ಸಮಯವನ್ನು ಕಳೆಯುತ್ತಾಳೆ, ಇದು ವೈದ್ಯಕೀಯವನ್ನು ಆರಿಸಿಕೊಳ್ಳಲು ಮತ್ತು ಅದರ ಮೂಲಕ ವೃತ್ತಿಯನ್ನು ಮಾಡಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಕೆಳಗಿನವುಗಳಲ್ಲಿ ಯಾವುದು ಕೆಲಸದ ನೆರಳು ಎನ್ನಲು ಉತ್ತಮ ಕಾರಣವಾಗಿದೆ:",
              "te": "మీరా లీనా ఆంటీకి నీడలాగా ఉండి సమయాన్ని గడుపుతుంది. ఇది ఆమెకు మెడిసిన్ సెలెక్ట్ చేసుకోవడానికి దాని నుండి జాబ్ సంపాదించుకోవడానికి సహాయపడుతుంది. జాబ్ షాడో చేయడానికి కింది వాటిలో ఏది మంచి కారణం:",
              "or": "ମୀରା ଲୀନାଙ୍କ ମାଉସୀଙ୍କୁ ଛାଇ ଦେବାରେ ସମୟ ବିତାଇଥାଏ ଯାହା ତାଙ୍କୁ ଔଷଧ ବାଛିବା\n  ଏବଂ ଏଥିରୁ କ୍ୟାରିୟର କରିବାରେ ସାହାଯ୍ୟ କରେ | ଚାକିରି ଛାୟା କରିବା ପାଇଁ ନିମ୍ନଲିଖିତ ମଧ୍ୟରୁ କେଉଁଟି ଏକ ଉତ୍ତମ କାରଣ:",
              "as": "ইয়াৰ পৰা কেৰিয়াৰ গঢ়ি তোলাত সহায় কৰে। নিম্নলিখিত কোনটো কামৰ ছাঁ দিয়াৰ এটা ভাল কাৰণ:",
              "gu": "મીરા લીનાની કાકીના નીચે કામ કરવામાં સમય વિતાવે છે જે તેને તબીબી પસંદ કરવામાં અને તેમાંથી કારકિર્દી બનાવવામાં મદદ કરે છે. નીચેનામાંથી કયું કામ કોઈના નીચે કરવાનું સારું કારણ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "To decide if you would like to pursue a particular job and role",
              "hi": "यह निश्चित करने के लिए कि क्या आप किसी विशेष कार्य और पदभार को आगे बढ़ाना चाहते हैं",
              "ka": "ನೀವು ನಿರ್ದಿಷ್ಟ ಕೆಲಸ ಮತ್ತು ಕೆಲಸವನ್ನು ಮುಂದುವರಿಸಲು ಬಯಸುತ್ತೀರಾ ಎನ್ನುವುದನ್ನು ನಿರ್ಧರಿಸುವುದು.",
              "te": "పర్టీక్యులర్ జాబ్ మరియు రోల్ ని కొనసాగించాలా, వద్దా అని నిర్ణయించుకోవడానికి",
              "or": "ଆପଣ ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ଚାକିରି ଏବଂ ଭୂମିକା ଗ୍ରହଣ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ନାହିଁ ତାହା\n  ସ୍ଥିର କରିବାକୁ |",
              "as": "আপুনি এটা নিৰ্দিষ্ট চাকৰি আৰু ভূমিকা পালন কৰিব বিচাৰে নে",
              "gu": "તું કોઈ ચોક્કસ નોકરી અને ભૂમિકાને અનુસરવા માંગે છે કે કેમ તે નક્કી કરવા"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "To finalise which course and college you would like to attend",
              "hi": "यह सुनिश्चित करने के लिए कि आप किस कोर्स और कॉलेज में भाग लेना चाहते हैं",
              "ka": "ನೀವು ಯಾವ ಕೋರ್ಸ್ ಮತ್ತು ಕಾಲೇಜಿಗೆ ಹಾಜರಾಗಲು ಬಯಸುತ್ತೀರಿ ಎನ್ನುವುದನ್ನು ಅಂತಿಮಗೊಳಿಸುವುದು.",
              "te": "మీరు ఏ కోర్సు మరియు కళాశాలకు వెళ్ళాలి అనుకుంటున్నారో నిర్ణయించుకోవడానికి.",
              "or": "କେଉଁ ପାଠ୍ୟକ୍ରମ ଏବଂ କଲେଜରେ ଆପଣ ଯୋଗଦେବାକୁ ଚାହୁଁଛନ୍ତି ତାହା ଚୂଡାନ୍ତ\n  କରିବାକୁ |",
              "as": "আপুনি কোনটো পাঠ্যক্ৰম আৰু মহাবিদ্যালয়ত পঢ়িব বিচাৰে তাক বিচাৰে ৰোৰচ",
              "gu": "તું કયા કોર્સ અને કોલેજમાં હાજરી આપવા માંગો છે તે નક્કી કરવા"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "To add the experience on your resume & CV",
              "hi": "अपने रेज़्यूमे और सीवी पर अनुभव जोड़ने के लिए",
              "ka": "ನಿಮ್ಮ ರೆಸ್ಯೂಮೇಯಲ್ಲಿ ಅನುಭವವನ್ನು ಸೇರಿಸುವುದು",
              "te": "మీ రెజ్యూమ్ & CVలో అనుభవాన్ని ఉంచడానికి",
              "or": "ତୁମର ରିଜ୍ୟୁମ୍ ଏବଂ ସିଭିରେ ଅଭିଜ୍ଞତା ଯୋଡିବାକୁ |",
              "as": "আপোনাৰ ৰিজিউমি আৰু চিভিত অভিজ্ঞতা যোগ কৰিবলৈ",
              "gu": "તમારા રેઝ્યૂમે અને CV પર અનુભવ ઉમેરવા માટે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "To gain work experience for a particular job role",
              "hi": "किसी विशेष नौकरी की भूमिका के लिए कार्य अनुभव प्राप्त करने के लिए",
              "ka": "ನಿರ್ದಿಷ್ಟ ಕೆಲಸದ ಕರ್ತವ್ಯಕ್ಕಾಗಿ ಕೆಲಸದ ಅನುಭವವನ್ನು ಪಡೆಯಲು",
              "te": "నిర్దిష్ట జాబ్ కోసం పని అనుభవాన్ని పొందడానికి",
              "or": "ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ଚାକିରି ଭୂମିକା ପାଇଁ କାର୍ଯ୍ୟର ଅଭିଜ୍ଞତା ହାସଲ କରିବା |",
              "as": "এটা নিৰ্দিষ্ট চাকৰিৰ ভূমিকাৰ বাবে কামৰ অভিজ্ঞতা প্ৰাপ্ত কৰা",
              "gu": "નોકરીની ચોક્કસ ભૂમિકા માટે કામનો અનુભવ મેળવવો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ବହୁତ ଭଲ କାମ!",
              "as": "সুন্দৰ কাম!",
              "gu": "સરસ કામ!"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Think again! For information on courses talk to alumni & college officials",
              "hi": "फिर से विचार करो! कोर्स की जानकारी के लिए पूर्व छात्रों और कॉलेज के अधिकारियों से बात करे",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಯೋಚಿಸು!  ಕೊರ್ಸುಗಳ ಬಗ್ಗೆ ಮಾಹಿತಿಗಾಗಿ ಮಾಜಿ ವಿದ್ಯಾರ್ಥಿಗಳು ಹಾಗೂ ಕಾಲೇಜಿನ ಅಧಿಕಾರಿಗಳ ಜೊತೆ ಚರ್ಚಿಸು",
              "te": "మళ్ళి ఆలోచించు! కోర్సుల సమాచారం కోసం పూర్వ విద్యార్థులు & కళాశాల అధికారులతో మాట్లాడండి",
              "or": "ପୁଣି ଥରେ ଭାବ! ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ସୂଚନା ପାଇଁ ଆଲୁମିନି ଏବଂ କଲେଜ\n  ଅଧିକାରୀଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରନ୍ତୁ |",
              "as": "আকৌ চিন্তা কৰক! পাঠ্যক্ৰমৰ তথ্যৰ বাবে প্ৰাক্তন ছাত্ৰ আৰু মহাবিদ্যালয়ৰ বিষয়াসকলৰ সৈতে কথা পাতক",
              "gu": "ફરીથી વિચારો! અભ્યાસક્રમોની માહિતી માટે ભૂતપૂર્વ વિદ્યાર્થીઓ અને કૉલેજ અધિકારીઓ સાથે વાત કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Think again! Job shadowing is not considered work experience typically",
              "hi": "फिर से विचार करो! नौकरी अनुसरण को सामान्यतः कार्य अनुभव नहीं माना जाता है",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸು! ಕೆಲಸದ ನೆರಳಿನಲ್ಲಿ ಇರುವವರನ್ನು ಸಾಮಾನ್ಯವಾಗಿ ಕೆಲಸದ ಅನುಭವವಿರುವವರೆಂದು ಪರಿಗಣಿಸಲಾಗುವುದಿಲ್ಲ",
              "te": "మళ్ళి ఆలోచించు! జాబ్ షాడోయింగ్ సాధారణంగా పని అనుభవంగా పరిగణించబడదు",
              "or": "ପୁଣି ଥରେ ଭାବ! ଚାକିରି ଛାୟା ସାଧାରଣତ work କାର୍ଯ୍ୟର ଅଭିଜ୍ଞତା ଭାବରେ \n ବିବେଚନା କରାଯାଏ ନାହିଁ |",
              "as": "আকৌ চিন্তা কৰক! চাকৰিৰ ছাঁ দিয়াটো সাধাৰণতে কামৰ অভিজ্ঞতা বুলি গণ্য কৰা নহয়",
              "gu": "ફરીથી વિચારો! કોઈના નીચે કામ કરવું સામાન્ય રીતે કામનો અનુભવ ગણવામાં આવતો નથી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Think again! Job shadowing is not considered work experience typically",
              "hi": "फिर से विचार करो! नौकरी अनुसरण को सामान्यतः कार्य अनुभव नहीं माना जाता है",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸು! ಕೆಲಸದ ನೆರಳಿನಲ್ಲಿ ಇರುವವರನ್ನು ಸಾಮಾನ್ಯವಾಗಿ ಕೆಲಸದ ಅನುಭವವಿರುವವರೆಂದು ಪರಿಗಣಿಸಲಾಗುವುದಿಲ್ಲ",
              "te": "మళ్ళి ఆలోచించు! జాబ్ షాడోయింగ్ సాధారణంగా పని అనుభవంగా పరిగణించబడదు",
              "or": "ପୁଣି ଥରେ ଭାବ! ଚାକିରି ଛାୟା ସାଧାରଣତ work କାର୍ଯ୍ୟର ଅଭିଜ୍ଞତା ଭାବରେ\n  ବିବେଚନା କରାଯାଏ ନାହିଁ |",
              "as": "আকৌ চিন্তা কৰক! চাকৰিৰ ছাঁ দিয়াটো সাধাৰণতে কামৰ অভিজ্ঞতা বুলি গণ্য কৰা নহয়",
              "gu": "ફરીથી વિચારો! કોઈના નીચે કામ કરવું સામાન્ય રીતે કામનો અનુભવ ગણવામાં આવતો નથી"
            }
          }
        ]
      },
      "scene32": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_1",
              "hi": "B2_1_1",
              "ka": "B2_1_1",
              "te": "B2_1_1",
              "or": "B2_1_1",
              "as": "B2_1_1",
              "gu": "B2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Both Lina and Mira decide to give the medical entrance exams after the 12th standard. They discuss their plan on preparing for the entrance.",
              "hi": "लीना और मीरा दोनों बारहवीं कक्षा के बाद मेडिकल प्रवेश परीक्षा देने का फैसला करती हैं। वे प्रवेश परीक्षा की तैयारी के लिए अपनी योजना पर चर्चा करती हैं।",
              "ka": "ಲೀನಾ ಮತ್ತು ಮೀರಾ ಇಬ್ಬರೂ 12 ನೇ ತರಗತಿಯ ನಂತರ ವೈದ್ಯಕೀಯ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ನಿರ್ಧರಿಸಿದರು. ಅವರು ಪ್ರವೇಶ ಪರೀಕ್ಷೆಯನ್ನು ತೆಗೆದುಕೊಳ್ಳುವ  ತಯಾರಿ ಬಗ್ಗೆ  ಚರ್ಚಿಸುತ್ತಾರೆ.",
              "te": "లీనా మరియు మీరా ఇద్దరూ 12వ తరగతి తర్వాత మెడికల్ ప్రవేశ పరీక్షలు రాయాలని నిర్ణయించుకున్నారు. వారు పరీక్షలకి సిద్ధమయ్యే ప్లాన్ ని చర్చిస్తారు.",
              "or": "ଦ୍ୱାଦଶ ମାନକ ପରେ ଉଭୟ ଲୀନା ଏବଂ ମୀରା ଡାକ୍ତରୀ ପ୍ରବେଶିକା ପରୀକ୍ଷା ଦେବାକୁ\n  ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ସେମାନେ ପ୍ରବେଶ ପାଇଁ ପ୍ରସ୍ତୁତି ଉପରେ ସେମାନଙ୍କର ଯୋଜନା ବିଷୟରେ ଆଲୋଚନା କରନ୍ତି |",
              "as": "লীনা আৰু মীৰা দুয়োজনে দ্বাদশ শ্ৰেণীৰ পিছত চিকিৎসা প্ৰৱেশ পৰীক্ষা দিয়াৰ সিদ্ধান্ত লয়। সিহঁতে পৰীক্ষা কাৰনে কেনেকৈ প্ৰস্তুতি কৰিব আলোচনা কৰে।",
              "gu": "લીના અને મીરા બંનેએ 12મા ધોરણ પછી મેડિકલ શાસ્ત્રમાં પ્રવેશ પરીક્ષા આપવાનું નક્કી કર્યું. તેઓ પ્રવેશ માટેની તૈયારી અંગે તેમની યોજનાની ચર્ચા કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_2",
              "hi": "B2_1_2",
              "ka": "B2_1_2",
              "te": "B2_1_2",
              "or": "B2_1_2",
              "as": "B2_1_2",
              "gu": "B2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Lina is planning on joining a coaching class. Mira knows her parents will not be able to afford it.",
              "hi": "लीना एक कोचिंग क्लास में दाखिले की योजना बना रही है। मीरा जानती है कि उसके माता-पिता इसे वहन नहीं कर पाएंगे।",
              "ka": "ಲೀನಾ ಕೋಚಿಂಗ್ ತರಗತಿಗೆ ಸೇರಲು ಯೋಚಿಸುತ್ತಿದ್ದಾಳೆ. ತನ್ನನ್ನು ಹೆತ್ತವರಿಗೆ ಅದನ್ನು ಭರಿಸಲು ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ ಎನ್ನುವುದು ಮೀರಾಗೆ ತಿಳಿದಿದೆ.",
              "te": "లీనా కోచింగ్ క్లాస్‌లో చేరాలని ప్లాన్ చేస్తోంది. తన తల్లిదండ్రులకు అంత ఆర్థిక స్థోమత లేదని మీరాకు తెలుసు.",
              "or": "ଲୀନା ଏକ କୋଚିଂ କ୍ଲାସରେ ଯୋଗଦେବାକୁ ଯୋଜନା କରୁଛନ୍ତି। ମୀରା ଜାଣନ୍ତି ଯେ\n  ତାଙ୍କ ପିତାମାତା ଏହାକୁ ସମ୍ଭାଳି ପାରିବେ ନାହିଁ |",
              "as": "লীনাই এটা কোচিংত যোগ কৰিম বুলি ভাবি আছে। মীৰাই জানে যে তাইৰ মাক দেউতাকে এইটো কাৰনে খৰচ কৰিব নোৱাৰিব।",
              "gu": "લીના કોચિંગ ક્લાસમાં જોડાવાની યોજના બનાવી રહી છે. મીરા જાણે છે કે તેના માતા-પિતા તે પરવડી શકશે નહીં."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_3",
              "hi": "B2_1_3",
              "ka": "B2_1_3",
              "te": "B2_1_3",
              "or": "B2_1_3",
              "as": "B2_1_3",
              "gu": "B2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Lina decides to help her by providing her with the material and studying for the exam together on weekends.",
              "hi": "लीना उसे पाठ्यसामग्री प्रदान करके और सप्ताहांत पर परीक्षा के लिए साथ अध्ययन करके उसकी सहायता करने का निश्चय करती है।",
              "ka": "ಲೀನಾ ಅವಳಿಗೆ ಅದಕ್ಕೆ ಸಂಬಂಧಪಟ್ಟ ವಸ್ತುಗಳನ್ನು ಒದಗಿಸುವ ಮೂಲಕ ಮತ್ತು ವಾರಾಂತ್ಯದಲ್ಲಿ ಪರೀಕ್ಷೆಗೆ ಒಟ್ಟಿಗೆ ಅಧ್ಯಯನ ಮಾಡುವ ಮೂಲಕ ಅವಳಿಗೆ ಸಹಾಯ ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
              "te": "లీనా ఆమెకు మెటీరియల్ అందించడం మరియు వారం చివరిలో కలిసి పరీక్ష కోసం చదువుకోవడం ద్వారా సహాయం చేయాలని నిర్ణయించుకుంది.",
              "or": "ଲୀନା ତାଙ୍କୁ ସାମଗ୍ରୀ ଯୋଗାଇ ସପ୍ତାହକ ମଧ୍ୟରେ ଏକାଠି ପରୀକ୍ଷା ପାଇଁ ଅଧ୍ୟୟନ \n କରି ତାଙ୍କୁ ସାହାଯ୍ୟ କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "লীনাই তাইক পঢ়া বস্তু দি আৰু সপ্তাহৰ শেষত একেলগে পৰীক্ষাৰ কাৰনে পঢ়ি তাইক সহায় কৰিব লয়।",
              "gu": "લીનાએ તેની સામગ્રી પૂરી પાડીને અને સપ્તાહના અંતે એકસાથે પરીક્ષા માટે અભ્યાસ કરીને મદદ કરવાનું નક્કી કર્યું."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_4",
              "hi": "B2_1_4",
              "ka": "B2_1_4",
              "te": "B2_1_4",
              "or": "B2_1_4",
              "as": "B2_1_4",
              "gu": "B2_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Lina is very thorough with her explanations, and whenever Mira is confused, she makes an effort to explain the concepts.",
              "hi": "लीना विषय पर अपने विचारों को स्पष्टता से व्यक्त करती है, और जब भी मीरा दुविधा में होती है, वह अवधारणाओं को समझाने का प्रयास करती है।",
              "ka": "ಲೀನಾ ತನ್ನ ವಿವರಣೆಗಳೊಂದಿಗೆ ತುಂಬಾ ಪಕ್ಕಾ ಆಗಿದ್ದಾಳೆ ಮತ್ತು ಮೀರಾ ಗೊಂದಲಕ್ಕೊಳಗಾದಾಗ, ಅವಳು ಅದರ ಭಾವರೂಪಗಳನ್ನು ವಿವರಿಸಲು ಪ್ರಯತ್ನಿಸುತ್ತಾಳೆ.",
              "te": "లీనా ప్రతి ఒక్కటి వివరించి చెబుతుంది మరియు మీరా గందరగోళానికి గురైనప్పుడు కాన్సెప్ట్ ని వివరించే ప్రయత్నం చేస్తుంది.",
              "or": "ଲୀନା ତାଙ୍କ ବ୍ୟାଖ୍ୟା ସହିତ ବହୁତ ପୁଙ୍ଖାନୁପୁଙ୍ଖ, ଏବଂ ଯେତେବେଳେ ବି ମୀରା \n ଦ୍ୱନ୍ଦ୍ୱରେ ପଡ଼ନ୍ତି, ସେ ଧାରଣାଗୁଡ଼ିକୁ ବ୍ୟାଖ୍ୟା କରିବାକୁ ଏକ ପ୍ରୟାସ କରନ୍ତି |",
              "as": "লীনাই বৰ ভালকৈ বুজাই, আৰু যেতিয়াই মীৰাৰ খেলিমেলি লাগে তাই ধাৰণাবোৰ বুজাই দিবলৈ চেষ্টা কৰে।",
              "gu": "લીના તેના ખુલાસા સાથે ખૂબ જ સંપૂર્ણ છે, અને જ્યારે પણ મીરા મૂંઝવણમાં હોય છે, ત્યારે તે ખ્યાલો સમજાવવાનો પ્રયાસ કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_5 bg",
              "hi": "B2_1_5 बीजी",
              "ka": "B2_1_5 bg",
              "te": "B2_1_5 bg",
              "or": "B2_1_5 bg",
              "as": "B2_1_5 bg",
              "gu": "B2_1_5 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Lina",
              "hi": "लीना",
              "ka": "ಲೀನ",
              "te": "లీనా",
              "or": "ଲୀନା",
              "as": "লিনা",
              "gu": "લીના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I don’t think I will be able to pass. There is too much competition, and seats are very less.",
              "hi": "मुझे नहीं लगता कि मैं पास कर पाऊंगी। बहुत अधिक प्रतिस्पर्धा है, और सीटें बहुत कम हैं।",
              "ka": "ನಾನು ಉತ್ತೀರ್ಣಳಾಗಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ ಎಂದು ನಾನು ಭಾವಿಸುವುದಿಲ್ಲ. ತುಂಬಾ ಸ್ಪರ್ಧೆ ಇದೆ, ಮತ್ತು ಸೀಟುಗಳು ಕೂಡ ತುಂಬಾ ಕಡಿಮೆ ಇದೆ.",
              "te": "నేను పాస్ అవుతానని అనుకోవడం లేదు. చాలా పోటీ మరియు సీట్లు చాలా తక్కువ ఉన్నాయి.",
              "or": "ମୁଁ ଭାବୁନାହିଁ ଯେ ମୁଁ ପାସ୍ କରିବାକୁ ସମର୍ଥ ହେବି | ବହୁତ ପ୍ରତିଯୋଗିତା ଅଛି, ଏବଂ\n  ସିଟ୍ ବହୁତ କମ୍ ଅଟେ |",
              "as": "মই নাভাবো যে মই পাছ হ'ব পাৰিম। বহুত প্ৰতিযোগিতা আছে, আৰু ছিট বোৰ বহুত কম।",
              "gu": "મને નથી લાગતું કે હું પાસ થઈ શકીશ. ત્યાં ખૂબ સ્પર્ધા છે, અને બેઠકો ઘણી ઓછી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I feel the same! The general category also has few seats. But my teachers in the coaching class told us that if one prepares smartly, everyone has a fair chance of getting admission.",
              "hi": "मुझे भी ऐसा ही लगता है! सामान्य श्रेणी में कम सीट भी हैं। लेकिन कोचिंग क्लास में शिक्षकों ने हमें बताया कि यदि कोई बुद्धिमानी से तैयारी करता है, तो हर किसी को प्रवेश  मिल सकता है।",
              "ka": "ನನಗೂ ಹಾಗೇ ಅನ್ನಿಸುತ್ತದೆ! ಸಾಮಾನ್ಯ ವರ್ಗಕ್ಕೂ ಕಡಿಮೆ ಸೀಟುಗಳಿವೆ. ಆದರೆ ಕೋಚಿಂಗ್ ಕ್ಲಾಸ್‌ನಲ್ಲಿರುವ ನನ್ನ ಶಿಕ್ಷಕರು ಒಬ್ಬರು ಬುದ್ಧಿವಂತಿಕೆಯಿಂದ ತಯಾರಿ ನಡೆಸಿದರೆ, ಎಲ್ಲರಿಗೂ ಪ್ರವೇಶ ಪಡೆಯಲು ಸಾಕಷ್ಟು ಅವಕಾಶವಿದೆ ಎಂದು ನಮಗೆ ಹೇಳಿದ್ದಾರೆ.",
              "te": "నాకూ అలాగే అనిపిస్తుంది! జనరల్ కేటగిరీలో కూడా తక్కువ సీట్లు ఉన్నాయి. కానీ కోచింగ్ క్లాస్‌లో నా టీచర్లు మాతో మాట్లాడుతూ, ఎవరైనా తెలివిగా ప్రిపేర్ అయితే ప్రతి ఒక్కరూ అడ్మిషన్ పొందే అవకాశం ఉందని చెప్పారు.",
              "or": "ମୁଁ ମଧ୍ୟ ସମାନ ଅନୁଭବ କରୁଛି! ସାଧାରଣ ବର୍ଗରେ ମଧ୍ୟ କିଛି ସିଟ୍ ଅଛି | କିନ୍ତୁ\n  କୋଚିଂ ଶ୍ରେଣୀର ମୋର ଶିକ୍ଷକମାନେ ଆମକୁ କହିଥିଲେ ଯେ ଯଦି ଜଣେ ସ୍ମାର୍ଟ ଭାବରେ ପ୍ରସ୍ତୁତ ହୁଏ, ତେବେ ସମସ୍ତଙ୍କ ଆଡମିଶନ ହେବାର ଯଥେଷ୍ଟ ସୁଯୋଗ ଅଛି |",
              "as": "মইও একেই অনুভৱ কৰোঁ! সাধাৰণ শ্ৰেণীতো বহুত আসন আছে। কিন্তু কোচিংৰ শিক্ষকসকলে আমাক কৈছে যে যদি এজনে চতুৰতাৰে পঢ়ে সকলোৰে ছিট পোৱাৰ সুযোগ আছে।",
              "gu": "હુ પણ એજ અનુભવુ છુ! જનરલ કેટેગરીમાં પણ થોડી બેઠકો છે. પરંતુ કોચિંગ ક્લાસમાં મારા શિક્ષકોએ અમને કહ્યું હતું કે જો કોઈ હોસિયારીથી તૈયારી કરે તો દરેકને પ્રવેશ મેળવવાની વાજબી તક છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_6///",
              "hi": "B2_1_6///",
              "ka": "B2_1_6///",
              "te": "B2_1_6///",
              "or": "B2_1_6///",
              "as": "B2_1_6///",
              "gu": "B2_1_6///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Lina, If I get admission through the SC reservation quota, people will think I only got it because of the reserved seats.",
              "hi": "लीना, अगर मुझे एससी आरक्षण कोटा के माध्यम से प्रवेश मिलता है, तो लोग सोचेंगे कि मुझे आरक्षित सीटों के कारण ही प्रवेश मिला है।",
              "ka": "ಲೀನಾ, ನಾನು ಎಸ್‌ಸಿ ಮೀಸಲಾತಿ ಕೋಟಾದ ಮೂಲಕ ಪ್ರವೇಶ ಪಡೆದರೆ, ಮೀಸಲಾತಿ ಸೀಟುಗಳಿಂದಾಗಿ ನಾನು ಅದನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೇನೆ ಎಂದು ಜನರು ಭಾವಿಸುತ್ತಾರೆ.",
              "te": "లీనా, నేను SC రిజర్వేషన్ కోటా ద్వారా అడ్మిషన్ పొందినట్లయితే అందరూ నేను రిజర్వ్డ్ సీట్ల వల్ల మాత్రమే దానిని పొందానని అనుకుంటారు.",
              "or": "ଲୀନା, ଯଦି ମୁଁ ଏସସି ସଂରକ୍ଷଣ କୋଟା ମାଧ୍ୟମରେ ଆଡମିଶନ ପାଏ, ଲୋକମାନେ\n  ଭାବିବେ ଯେ ମୁଁ ସଂରକ୍ଷିତ ସିଟ୍ ଯୋଗୁଁ ଏହା ପାଇଛି |",
              "as": "লীনা, যদি মই সংৰক্ষণ কোটাৰ সহায়ত নামভৰ্তি কৰোঁ, মানুহে ভাবিব যে মই কেৱল সংৰক্ষিত আসনৰ হোৱা কাৰনে পাইছো।",
              "gu": "લીના, જો મને એસસી અનામત ક્વોટા દ્વારા પ્રવેશ મળે છે, તો લોકો વિચારશે કે મને તે માત્ર અનામત સીટોના કારણે મળ્યું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Listen, you will get the seat because you are working hard. Don’t let anyone make you think you don't deserve this.",
              "hi": "सुनो, तुमको सीट मिल जाएगी क्योंकि तुम कड़ी मेहनत कर रही हो। किसी को भी ऐसा कहने का अवसर न दो कि तुम इसके योग्य नहीं।",
              "ka": "ಇಲ್ಲಿ ಕೇಳು, ನೀನು ಕಷ್ಟಪಟ್ಟು ಓದುವುದರಿಂದ ಸೀಟು ಸಿಗುತ್ತದೆ. ನೀನು ಇದಕ್ಕೆ ಅರ್ಹಳಾಗಿರುವುದಿಲ್ಲ ಎಂದು ಯಾವತ್ತೂ ಭಾವಿಸಬೇಡ.",
              "te": "విను, నువ్వు కష్టపడి పనిచేయడం వల్ల నీకు సీటు వస్తుంది. నీకు ఇది వచ్చే అర్హత లేదని ఎవరినీ అలా అనుకునేటట్లు చేయకు.",
              "or": "ଶୁଣ, ତୁମେ କଠିନ ପରିଶ୍ରମ କରୁଥିବାରୁ ଆସନ ପାଇବ | କାହାକୁ ତୁମକୁ ଏହା \n ଯୋଗ୍ୟ ନୁହେଁ ବୋଲି ଭାବିବାକୁ ଦିଅ ନାହିଁ |",
              "as": "শুনা, তুমি ছিট পাবা কাৰন বহুত পৰিশ্ৰম কৰি আহিছা। কাকো ভাবিবলৈ নিদিবা যে তুমি ইয়াৰ যোগ্য নহয়।",
              "gu": "સાંભળ, તને તક મળશે કારણ કે તું સખત મહેનત કરી રહી છે. કોઈને પણ એવું ન થવા દે કે તું આના લાયક નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "This is the most nervous I’ve felt in my life.",
              "hi": "इससे अधिक घबराहट का अनुभव मैंने पहले कभी नहीं किया।",
              "ka": "ಇದು ನನ್ನ ಜೀವನದಲ್ಲಿ ನಾನು ಅನುಭವಿಸಿದ ಅತ್ಯಂತ ಉದ್ವಿಗ್ನತೆಯ ಸಮಯವಾಗಿದೆ.",
              "te": "ఇది నా జీవితంలో నేను అనుభవించిన అతి పెద్ద చెడు అనుభవం.",
              "or": "ଏହା ମୋ ଜୀବନରେ ଅତ୍ୟଧିକ ଭୟାନକ |",
              "as": "জীৱনত ইমান বেছি ভয় কেতিয়াও লগা নাছিল।",
              "gu": "આ સૌથી વધુ ઉદારતાની વાત છે જે મેં મારા જીવનમાં અનુભવ્યું છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_2_1",
              "hi": "B2_2_1",
              "ka": "B2_2_1",
              "te": "B2_2_1",
              "or": "B2_2_1",
              "as": "B2_2_1",
              "gu": "B2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira decides to work even harder. She knows how much of an advantage other students have as they can access coaching classes and teachers with many years of experience.",
              "hi": "मीरा और भी अधिक परिश्रम करने का निर्णय करती है। वह जानती है कि अन्य छात्र अधिक अनुकूल स्थिति में हैं क्योंकि वे कोचिंग क्लासेस एवं कई वर्षों के अनुभव वाले शिक्षकों तक पहुंच सकते हैं।",
              "ka": "ಮೀರಾ ಇನ್ನೂ ಹೆಚ್ಚು ಕಷ್ಟಪತ್ತು ಓದಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಅನೇಕ ವರ್ಷಗಳ ಅನುಭವ ಇರುವ ಕೋಚಿಂಗ್ ತರಗತಿಗಳು ಮತ್ತು ಶಿಕ್ಷಕರ ಸಹಾಯವನ್ನು ಪಡೆಯುವುದರಿಂದ ಇತರ ವಿದ್ಯಾರ್ಥಿಗಳು ಎಷ್ಟು ಪ್ರಯೋಜನವನ್ನು ಹೊಂದುತ್ತಾರೆಂದು ಆಕೆಗೆ ತಿಳಿದಿದೆ.",
              "te": "మీరా మరింత కష్టపడి పనిచేయాలని నిర్ణయించుకుంది. అనేక సంవత్సరాల అనుభవంతో ఇతర విద్యార్థులు కోచింగ్ తరగతులు మరియు ఉపాధ్యాయులను పొందడం వలన వారికి ఎంత ప్రయోజనం ఉందో ఆమెకు తెలుసు.",
              "or": "ମୀରା ଆହୁରି କଠିନ ପରିଶ୍ରମ କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ସେ ଜାଣନ୍ତି ଯେ ଅନ୍ୟ\n  ଛାତ୍ରମାନଙ୍କର କେତେ ସୁବିଧା ଅଛି ଯେହେତୁ ସେମାନେ ବହୁ ବର୍ଷର ଅଭିଜ୍ଞତା ସହିତ କୋଚିଂ କ୍ଲାସ୍ ଏବଂ ଶିକ୍ଷକମାନଙ୍କୁ ପ୍ରବେଶ କରିପାରିବେ |",
              "as": "মীৰাই আৰু কষ্ট কৰাৰ সিদ্ধান্ত লয়। তাই জানে যে আন শিক্ষাৰ্থীসকলৰ বেছি সুবিধা আছে কাৰন সিহঁতে বহু বছৰৰ অভিজ্ঞতাৰ কোচিং শ্ৰেণী আৰু শিক্ষকসকলৰ পৰা শিকিব পাৰে।",
              "gu": "મીરા વધુ મહેનત કરવાનું નક્કી કરે છે. તે જાણે છે કે અન્ય વિદ્યાર્થીઓને કેટલો ફાયદો છે કારણ કે તેઓ ઘણા વર્ષોના અનુભવ સાથે કોચિંગ વર્ગો અને શિક્ષકોનો સહારો મળ્યો છે. "
            }
          }
        ]
      },
      "scene33": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_1_1",
              "hi": "B3_1_1",
              "ka": "B3_1_1",
              "te": "B3_1_1",
              "or": "B3_1_1",
              "as": "B3_1_1",
              "gu": "B3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira and Lina appear for the NEET exam and find it very tough. Unfortunately, both of them don’t clear the exams as they score less than the required cut off marks.",
              "hi": "मीरा और लीना एन ई ई टी की परीक्षा देते हैं और यह उन्हें बहुत कठिन लगती है। दुर्भाग्यवश, दोनों परीक्षा को उत्तीर्ण नहीं कर पाते हैं, वे आवश्यक कट ऑफ नंबर से कम स्कोर करते हैं।",
              "ka": "ಮೀರಾ ಮತ್ತು ಲೀನಾ NEET ಪರೀಕ್ಷೆಗೆ ಹಾಜರಾಗುತ್ತಾರೆ ಮತ್ತು ಅದು ತುಂಬಾ ಕಠಿಣವಾಗಿರುತ್ತದೆ. ದುರದೃಷ್ಟವಶಾತ್, ಇಬ್ಬರೂ ಪರೀಕ್ಷೆಗಳನ್ನು ಪಾಸು ಮಾಡಲು ಆಗುವುದಿಲ್ಲ. ಏಕೆಂದರೆ ಅವರು ಅಗತ್ಯವಿರುವ ಕಟ್ ಆಫ್ ಅಂಕಗಳಿಗಿಂತ ಕಡಿಮೆ ಅಂಕಗಳನ್ನು ಗಳಿಸಿರುತ್ತಾರೆ",
              "te": "మీరా మరియు లీనా NEET పరీక్షను రాస్తారు మరియు అది కష్టంగా ఉందని తెలుసుకున్నారు. దురదృష్టవశాత్తు, వారిద్దరూ అవసరమైన కటాఫ్ మార్కుల కంటే తక్కువ స్కోర్ చేసినందువలన పరీక్షలు పాస్ అవ్వలేదు.",
              "or": "ମୀରା ଏବଂ ଲୀନା NEET ପରୀକ୍ଷା ପାଇଁ ଉପସ୍ଥିତ ହୁଅନ୍ତି ଏବଂ ଏହାକୁ ବହୁତ କଠିନ\n  ମନେ କରନ୍ତି | ଦୁର୍ଭାଗ୍ୟବଶତଃ , ଉଭୟ ଆବଶ୍ୟକୀୟ କଟ୍ ଅଫ୍ ମାର୍କଠାରୁ କମ୍ ସ୍କୋର କରୁଥିବାରୁ ଉଭୟେ ପରୀକ୍ଷା ସଫଳ କରନ୍ତି ନାହିଁ |",
              "as": "মীৰা আৰু লীনাই NEET পৰীক্ষাত দিয়ে আৰু বহুত টান যেন লাগে। কিন্তু , দুখৰ শথা দুয়োজনিয়ে পৰীক্ষাত পাছ নহয় কিয়নো সিহঁতৰ নম্বৰ প্ৰয়োজনীয় কাট অফত কৈ বহুত কম।",
              "gu": "મીરા અને લીના NEET ની પરીક્ષા આપે છે અને તે ખૂબ જ અઘરી લાગે છે. કમનસીબે, તે બંને પરીક્ષા પાસ કરતા નથી કારણ કે તેઓ જરૂરી ગુણના કાપ કરતા ઓછા ગુણ આવે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_1_2",
              "hi": "B3_1_2",
              "ka": "B3_1_2",
              "te": "B3_1_2",
              "or": "B3_1_2",
              "as": "B3_1_2",
              "gu": "B3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After this, Mira is very stressed about her career. This is the first time she has failed an exam.",
              "hi": "इससे, मीरा अपने करियर को लेकर बहुत तनावग्रस्त है। यह पहली बार है जब वह किसी परीक्षा में विफल रही है।",
              "ka": "ಇದರ ನಂತರ, ಮೀರಾ ತನ್ನ ವೃತ್ತಿಜೀವನದ ಬಗ್ಗೆ ತುಂಬಾ ಒತ್ತಡವನ್ನು ಹೊಂಡುತ್ತಾಳೆ. ಪರೀಕ್ಷೆಯಲ್ಲಿ ಅನುತ್ತೀರ್ಣಳಾಗಿರುವುದು ಇದೇ ಮೊದಲ ಬಾರಿ ಆಗಿರುವುದರಿಂದ.",
              "te": "దీని తరువాత, మీరా తన కెరీర్ గురించి చాలా ఒత్తిడికి గురవుతుంది. ఆమె పరీక్షలో ఫెయిల్ కావడం ఇదే తొలిసారి.",
              "or": "ଏହା ପରେ ମୀରା ତାଙ୍କ କ୍ୟାରିୟର ବିଷୟରେ ବହୁତ ଚାପଗ୍ରସ୍ତ | ସେ ପ୍ରଥମ ଥର\n  ପାଇଁ ଏକ ପରୀକ୍ଷାରେ ବିଫଳ ହୋଇଛନ୍ତି।",
              "as": "ইয়াৰ পিছত, মীৰা তাইৰ কেৰিয়াৰৰ বিষয়ে যথেষ্ট চিন্তা কৰে। এইটো প্ৰথমবাৰৰ বাবে তাই পৰীক্ষাত ফেইল হৈছে।",
              "gu": "આ પછી મીરા પોતાની કારકિર્દીને લઈને ખૂબ જ તણાવમાં છે. આ પહેલીવાર છે જ્યારે તે પરીક્ષામાં નાપાસ થય છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_1_3",
              "hi": "B3_1_3",
              "ka": "B3_1_3",
              "te": "B3_1_3",
              "or": "B3_1_3",
              "as": "B3_1_3",
              "gu": "B3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "There are too many negative thoughts coming to her mind. Her family is being supportive, but they want her to think about her next steps.",
              "hi": "उसके मन में बहुत सारे नकारात्मक विचार आ रहे हैं। उसका परिवार सहायक है, लेकिन वे चाहते हैं कि वह अपने अगले चरणों के बारे में सोचे।",
              "ka": "ಅವಳ ಮನಸ್ಸಿನಲ್ಲಿ ಹಲವಾರು ನಕಾರಾತ್ಮಕ ಆಲೋಚನೆಗಳು ಬರುತ್ತಿವೆ. ಆಕೆಯ ಕುಟುಂಬವು ಬೆಂಬಲ ನೀಡುತ್ತಿದೆ, ಆದರೆ ಆಕೆಯ ಮುಂದಿನ ಹೆಜ್ಜೆಗಳ ಬಗ್ಗೆ ಯೋಚಿಸಬೇಕೆಂದು ಅವರು ಬಯಸುತ್ತಾರೆ.",
              "te": "ఆమె మనసులో చాలా నెగిటివ్ ఆలోచనలు వస్తున్నాయి. ఆమె కుటుంబం సపోర్ట్ గా ఉంది, కానీ ఆమె తన తర్వాత చేయాల్సిన వాటి గురించి ఆలోచించాలని కోరుకుంటున్నారు.",
              "or": "ତାଙ୍କ ମନକୁ ବହୁତ ନକାରାତ୍ମକ ଚିନ୍ତାଧାରା ଆସୁଛି | ତାଙ୍କ ପରିବାର ସହାୟତା କରୁଛନ୍ତି,\n  କିନ୍ତୁ ସେମାନେ ଚାହୁଁଛନ୍ତି ଯେ ସେ ତାଙ୍କ ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପ ବିଷୟରେ ଚିନ୍ତା କରନ୍ତୁ |",
              "as": "তাইৰ মনলৈ বহুতো বেয়া চিন্তা আহি আছে। তাইৰ পৰিয়ালে সহায় কৰি আছে, কিন্তু তেওঁলোকে বিচাৰে যে তাই তাইৰ আহিব লগীয়া সময়বোৰৰ কথা চিন্তা কৰক।",
              "gu": "તેના મગજમાં ઘણા બધા નકારાત્મક વિચારો આવે છે. તેનો પરિવાર સહાયક છે, પરંતુ તેઓ ઇચ્છે છે કે તે એના આગળ શું કરવું એના વિશે વિચારે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_2_1",
              "hi": "B3_2_1",
              "ka": "B3_2_1",
              "te": "B3_2_1",
              "or": "B3_2_1",
              "as": "B3_2_1",
              "gu": "B3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "When she meets Lina the next week, she is surprised to find her excited.",
              "hi": "जब वह अगले सप्ताह लीना से मिलती है, तो वह उसे उत्साहित देखकर आश्चर्यचकित है।",
              "ka": "ಮುಂದಿನ ವಾರ ಅವಳು ಲೀನಾಳನ್ನು ಭೇಟಿಯಾದಾಗ, ಅವಳು ಉತ್ಸುಕಳಾಗಿರುವುದನ್ನು ಕಂಡು ಆಶ್ಚರ್ಯ ಪಡುತ್ತಾಳೆ.",
              "te": "వారం తర్వాత ఆమె లీనాను కలిసినప్పుడు లీనా ఉత్సాహంగా ఉండటం చూసి ఆశ్చర్యపోతుంది.",
              "or": "ପରବର୍ତ୍ତୀ ସପ୍ତାହରେ ଯେତେବେଳେ ସେ ଲୀନାଙ୍କୁ ଭେଟିବେ, ସେତେବେଳେ ସେ\n  ଉତ୍ସାହିତ ହୋଇ ଆଶ୍ଚର୍ଯ୍ୟ ହୋଇଯିବେ |",
              "as": "যেতিয়া তাই পিছৰ সপ্তাহত লীনাক লগ পায়, তাই তাইক ফূৰ্তি মনত দেখি আচৰিত হয়।",
              "gu": "જ્યારે તે આવતા અઠવાડિયે લીનાને મળે છે, ત્યારે તે ઉત્સાહિત જોઈને આશ્ચર્ય થાય છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_2_2 bg",
              "hi": "B3_2_2 बीजी",
              "ka": "B3_2_2 bg",
              "te": "B3_2_2 bg",
              "or": "B3_2_2 bg",
              "as": "B3_2_2 bg",
              "gu": "B3_2_2 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Lina",
              "hi": "लीना",
              "ka": "ಲೀನ",
              "te": "లీనా",
              "or": "ଲୀନା",
              "as": "লিনা",
              "gu": "લીના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Why are you so excited, Lina? I thought you would be sad about failing.",
              "hi": "तुम इतनी उत्साहित क्यों हो, लीना? मुझे लगा कि तुम असफल होने के कारण दुखी होगी।",
              "ka": "ನೀನು ಯಾಕೆ ತುಂಬಾ ಉತ್ಸುಕಳಾಗಿದ್ದೀಯ, ಲೀನಾ? ನೀನು ವಿಫಲಳಾಗಿರುವ ಬಗ್ಗೆ ದುಃಖಿತಲಾಗಿರುತ್ತೀ ಎಂದು ನಾನು ಭಾವಿಸಿದ್ದೆ.",
              "te": "లీనా, నువ్వు ఎందుకు అంత ఉత్సాహంగా ఉన్నావు? నువ్వు ఫెయిల్ అయినందుకు బాధపడతావని అనుకున్నాను.",
              "or": "ଲୀନା, ତୁମେ ଏତେ ଉତ୍ସାହିତ କାହିଁକି? ମୁଁ ଭାବିଲି ତୁମେ ବିଫଳ ହେବା ପାଇଁ ଦୁଃଖିତ ହେବ |",
              "as": "তোমাৰ মনটো ইমান ফূৰ্তি যে, লীনা? মই ভাবিছিলো তুমি ফেইল হোৱাৰ কাৰনে দুখী হ'ব।",
              "gu": "લીના, તું આટલી ઉત્સાહિત કેમ છે? મેં વિચાર્યું કે તું નિષ્ફળ થવાથી દુઃખી થઈશ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Guess what, Mira! I got my admissions today!",
              "hi": "सोचो ज़रा, मीरा! मुझे आज प्रवेश मिल गया!",
              "ka": "ಏನೆಂದು ಊಹಿಸು, ಮೀರಾ! ನಾನು ಇಂದು ನನ್ನ ಪ್ರವೇಶವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೇನೆ!",
              "te": "ఏం జరిగిందో చెప్పు, మీరా! నాకు ఈరోజు అడ్మిషన్లు వచ్చాయి!",
              "or": "ଅନୁମାନ କର, ମୀରା! ମୁଁ ଆଜି ମୋର ଆଡମିଶନ ପାଇଲି!",
              "as": "মীৰা, জানানে আজি মোৰ কলেজত নামভৰ্তি হৈ গ'ল।",
              "gu": "ધાર કે શું હસે, મીરા! આજે મને પ્રવેશ મળી ગયો!"
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_2_2///",
              "hi": "B3_2_2///",
              "ka": "B3_2_2///",
              "te": "B3_2_2///",
              "or": "B3_2_2///",
              "as": "B3_2_2///",
              "gu": "B3_2_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "What!!! But didn’t we both fail to meet the cut-off?",
              "hi": "क्या!!! लेकिन क्या हम दोनों कट ऑफ को पूरा करने में विफल नहीं हुए?",
              "ka": "ಏನು!!! ಆದರೆ ನಾವಿಬ್ಬರೂ ಕಟ್-ಆಫ್ ಅನ್ನು ಪೂರೈಸಲು ವಿಫಲರಾಗಿದ್ದೇವೆ ಅಲ್ಲವೇ?",
              "te": "ఏంటి!!! కానీ మనం ఇద్దరం కట్-ఆఫ్‌ లో ఫెయిల్ అవ్వలేదా?",
              "or": "କଣ!!! କିନ୍ତୁ ଆମେ ଦୁହେଁ କଟ୍ ଅଫ୍ ପୂରଣ କରିବାରେ ବିଫଳ ହୋଇ ନାହୁଁ କି?",
              "as": "কি!!! কিন্তু আমি দুয়ো কাট-অফত কৈ কম নম্বৰ পোৱা নাছিলো নেকি?",
              "gu": "શું!!! પરંતુ શું આપણે કટ ઓફ ગુણ સુધી પાઓહચી નથી શકયા?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, but then I changed my mind.  I will be doing B.Sc. Botany at the University of Guwahati!",
              "hi": "हाँ, लेकिन फिर मैंने अपना मन बदल दिया। मैं गुवाहाटी विश्वविद्यालय से बॉटनी में बीएससी कर रही हूँ !",
              "ka": "ಹೌದು, ಆದರೆ ನಂತರ ನಾನು ನನ್ನ ಮನಸ್ಸನ್ನು ಬದಲಾಯಿಸಿದೆ. ನಾನು ಬಿ.ಎಸ್ಸಿ ಮಾಡುತ್ತೇನೆ. ಗುವಾಹಟಿ ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಸಸ್ಯಶಾಸ್ತ್ರದಲ್ಲಿ!",
              "te": "అవును, కానీ నేను నా మనసు మార్చుకున్నాను. నేను గౌహతి విశ్వవిద్యాలయంలో B.Sc బోటనీ చేస్తాను!",
              "or": "ହଁ, କିନ୍ତୁ ତା’ପରେ ମୁଁ ମୋର ମତ ବଦଳାଇଲି | ମୁଁ ବି.ଏସ୍। ଗୁଆହାଟି ବିଶ୍ୱବିଦ୍ୟାଳୟରେ \n ଉଦ୍ଭିଦ ବିଜ୍ଞାନ ପଢିବି!",
              "as": "হয়, কিন্তু তাৰ পিছত মই মোৰ মন সলনি কৰিলোঁ। মই গুৱাহাটী বিশ্ববিদ্যালয়ত B.Sc উদ্ভিদবিজ্ঞান পঢ়িম!",
              "gu": "હા, પણ પછી મેં મારો વિચાર બદલી નાખ્યો. હું B.Sc કરીશ. ગુવાહાટી યુનિવર્સિટીમાં વનસ્પતિશાસ્ત્ર!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "How did you decide that overnight? Are you even happy about doing Botany after preparing for medicine??",
              "hi": "तुमने रात भर में कैसे निश्चय किया? क्या तुम मेडिकल की तैयारी के बाद बॉटनी करने के बारे में भी खुश हो ??",
              "ka": "ರಾತ್ರೋರಾತ್ರಿ ನೀನುಅದನ್ನು ಹೇಗೆ ನಿರ್ಧರಿಸಿದೀ? ವೈದ್ಯಕೀಯಕ್ಕೆ ತಯಾರಾದ ನಂತರ ಸಸ್ಯಶಾಸ್ತ್ರವನ್ನು ಮಾಡಲು ನಿನಗೆ ಸಂತೋಷವಿದೆಯೇ ??",
              "te": "నువ్వు ఒక్క రాత్రిలోనే దానిని ఎలా నిర్ణయించుకున్నావు? మెడిసిన్‌కి ప్రిపేర్ అయ్యాక బొటనీ చేయడం కూడా నీకు సంతోషమేనా??",
              "or": "ରାତାରାତି ତୁମେ କିପରି ସ୍ଥିର କଲ? ଔଷଧ ପାଇଁ ପ୍ରସ୍ତୁତ ହେବା ପରେ ଆପଣ ବୋଟାନି ଚୟନ କରି ଖୁସି କି ??",
              "as": "তুমি ৰাতিটোৰ ভিতৰতে সেইটো কেনেদৰে ভাবিলা? ডাক্তৰ হবৰ বাবে প্ৰস্তুতি কৰাৰ পিছত তুমি উদ্ভিদবিজ্ঞান পঢ়িবলৈ সুখী হয় জানো?",
              "gu": "તે એ રાતોરાત કેવી રીતે નક્કી કર્યું? શું તે તબીબી શાસ્ત્રની તૈયારી કર્યા પછી વનસ્પતિશાસ્ત્રની કરવામાં પણ ખુશ છે??"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Mira, I would have loved to be a doctor, but I honestly cannot take the pressure of giving the exams again. I tried, and that is important to me. My next option was to study Botany, so I want to focus on that.",
              "hi": "मीरा, मुझे डॉक्टर बनना अच्छा लगेगा, लेकिन ईमानदारी से मैं परीक्षा का दबाव नहीं ले सकती। मैंने कोशिश की, और यह मेरे लिए महत्वपूर्ण है। मेरा अगला विकल्प बॉटनी का अध्ययन करना था, इसलिए मैं उस पर ध्यान केंद्रित करना चाहती हूं।",
              "ka": "ಮೀರಾ, ನಾನು ವೈದ್ಯಳಾಗಲು ಇಷ್ಟಪಡುತ್ತಿದ್ದೆ, ಆದರೆ ಮತ್ತೆ ಪರೀಕ್ಷೆಗಳನ್ನು ನೀಡುವ ಒತ್ತಡವನ್ನು ನಾನು ನಿಜವಾಗಿಯೂ ತೆಗೆದುಕೊಳ್ಳಲು ಸಾಧ್ಯವಿಲ್ಲ. ನಾನು ಪ್ರಯತ್ನಿಸಿದೆ, ಮತ್ತು ಅದು ನನಗೆ ಮುಖ್ಯವಾಗುತ್ತದೆ ಸಸ್ಯಶಾಸ್ತ್ರವನ್ನು ಅಧ್ಯಯನ ಮಾಡುವುದು ನನ್ನ ಮುಂದಿನ ಆಯ್ಕೆಯಾಗಿದೆ, ಆದ್ದರಿಂದ ನಾನು ಅದರ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಲು ಬಯಸುತ್ತೇನೆ.",
              "te": "మీరా, నాకు డాక్టర్ అవ్వాలంటే ఇష్టం. కానీ నిజంగా చెప్పాలంటే మళ్ళి పరీక్షల ఒత్తిడిని భరించలేను. నేను ప్రయత్నించాను, అది నాకు ముఖ్యం. నా తర్వాత ఆప్షన్ బొటనీ చదవడం కాబట్టి నేను దానిపై దృష్టి పెట్టాలనుకుంటున్నాను.",
              "or": "ମୀରା, ମୁଁ ଜଣେ ଡାକ୍ତର ହେବାକୁ ଭଲ ପାଇଥା’ନ୍ତି, କିନ୍ତୁ ସତ କହିବାକୁ ଗଲେ ମୁଁ \n ପୁନର୍ବାର ପରୀକ୍ଷା ଦେବାର ଚାପ ନେଇ ପାରିବି ନାହିଁ | ମୁଁ ଚେଷ୍ଟା କଲି, ଏବଂ ତାହା ମୋ ପାଇଁ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ | ମୋର ପରବର୍ତ୍ତୀ ବିକଳ୍ପ ଥିଲା ବୋଟାନି ଅଧ୍ୟୟନ କରିବା, ତେଣୁ ମୁଁ ତାହା ଉପରେ ଧ୍ୟାନ ଦେବାକୁ ଚାହୁଁଛି |",
              "as": "মীৰা, মই এজন চিকিৎসক হ'বলৈ ভাল পালোঁহেঁতেন, কিন্তু মই আকৌ পৰীক্ষা দিয়াৰ চিন্তা ল'ব নোৱাৰো। মই চেষ্টা কৰিছিলো, আৰু সেয়া মোৰ কাৰনে দৰকাৰী। মোৰ পিছৰ বিকল্প আছিল উদ্ভিদবিজ্ঞান পঢ়াৰ, সেয়েহে মই তাৰ ওপৰত গুৰুত্ব দিব বিচাৰো।",
              "gu": "મીરા, મને ડૉક્ટર બનવું ગમ્યું હોત, પણ હું પ્રામાણિકપણે ફરી પરીક્ષા આપવાનું દબાણ સહન કરી શકતી નથી. મેં પ્રયત્ન કર્યો, અને તે મારા માટે મહત્વપૂર્ણ છે. મારો આગળનો વિકલ્પ વનસ્પતિશાસ્ત્રનો અભ્યાસ કરવાનો હતો, તેથી હું તેના પર ધ્યાન કેન્દ્રિત કરવા માંગુ છું."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_3_1",
              "hi": "B3_3_1",
              "ka": "B3_3_1",
              "te": "B3_3_1",
              "or": "B3_3_1",
              "as": "B3_3_1",
              "gu": "B3_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After her conversation with Lina, Mira feels she should also explore other options. Mira discusses with her aunt, who asks her which subjects she wants to study more.",
              "hi": "लीना के साथ बातचीत के बाद, मीरा को लगता है कि उन्हें अन्य विकल्पों का भी पता लगाना चाहिए। मीरा अपनी चाची के साथ चर्चा करती है, जो उससे पूछती हैं कि वह कौन से विषयों का अध्ययन करना चाहती है।",
              "ka": "ಲೀನಾಳೊಂದಿಗಿನ ಸಂಭಾಷಣೆಯ ನಂತರ, ಮೀರಾ ತಾನು ಇತರ ಆಯ್ಕೆಗಳನ್ನು ಅನ್ವೇಷಿಸಬೇಕು ಎಂದು ಭಾವಿಸುತ್ತಾಳೆ. ಮೀರಾ ತನ್ನ ಆಂಟಿಯೊಂದಿಗೆ ಚರ್ಚಿಸುತ್ತಾಳೆ, ನೀನು ಯಾವ ವಿಷಯಗಳನ್ನು ಹೆಚ್ಚಾಗಿ ಅಧ್ಯಯನ ಮಾಡಲು ಬಯಸುತ್ತೀಯಾ ಎಂದು ಕೇಳುತ್ತಾರೆ",
              "te": "లీనాతో ఆమె మాట్లాడిన తర్వాత, మీరా వేరే ఆప్షన్స్ కూడా చూడాలని భావిస్తుంది. మీరా తన ఆంటీతో చర్చిస్తుంది, ఆమె ఏ సబ్జెక్టులు ఎక్కువగా చదవాలనుకుంటున్నది అని అడుగుతుంది.",
              "or": "ଲୀନାଙ୍କ ସହ ବାର୍ତ୍ତାଳାପ ପରେ ମୀରା ଅନୁଭବ କରନ୍ତି ଯେ ସେ ଅନ୍ୟ ବିକଳ୍ପଗୁଡ଼ିକୁ\n  ମଧ୍ୟ ଅନୁସନ୍ଧାନ କରିବା ଉଚିତ୍ | ମୀରା ତାଙ୍କ ମାଉସୀ ସହିତ ଆଲୋଚନା କରନ୍ତି, ଯିଏ ତାଙ୍କୁ ପଚାରିଥାଏ ଯେ ସେ କେଉଁ ବିଷୟ ଉପରେ ଅଧିକ ଅଧ୍ୟୟନ କରିବାକୁ ଚାହୁଁଛନ୍ତି |",
              "as": "লীনাৰ সৈতে বাৰ্তালাপৰ পিছত, মীৰাই অনুভৱ কৰে যে তাইয়ো বেলেগ কিবা কৰা ভাল হ'ব। মীৰাই তাইৰ খুড়ীৰ সৈতে কথা পাতে, যিয়ে তাইক সুধিলে যে তাই কোনবোৰ বিষয় আৰু বেছি পঢ়িব বিচাৰে।",
              "gu": "લીના સાથેની તેની વાતચીત પછી, મીરાને લાગે છે કે તેને અન્ય વિકલ્પોની પણ શોધ કરવી જોઈએ. મીરા તેની કાકી સાથે ચર્ચા કરે છે, જેઓ તેને પૂછે છે કે તે કયા વિષયોમાં વધુ અભ્યાસ કરવા માંગે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_3_2",
              "hi": "B3_3_2",
              "ka": "B3_3_2",
              "te": "B3_3_2",
              "or": "B3_3_2",
              "as": "B3_3_2",
              "gu": "B3_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She suggests they can map her career journey for that subject to understand the opportunities available.",
              "hi": "वह सुझाव देती हैं कि वे उपलब्ध अवसरों को समझने के लिए उस विषय के लिए अपनी करियर यात्रा को मैप कर सकते हैं।",
              "ka": "ಲಭ್ಯವಿರುವ ಅವಕಾಶಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಅವರು ಆ ವಿಷಯಗಳ ಬಗ್ಗೆ ತನ್ನ ವೃತ್ತಿಜೀವನದ ಮುಂದುವರಿಕೆಯನ್ನು ನಕ್ಷೆ ಮಾಡಬಹುದು ಎಂದು ಅವರು ಸೂಚಿಸುತ್ತಾರೆ.",
              "te": "అందుబాటులో ఉన్న అవకాశాలను బట్టి అర్థం చేసుకోవడం వలన ఆ సబ్జెక్ట్ కోసం తమ కెరీర్ జర్నీని మ్యాప్ చేయవచ్చని ఆమె సూచిస్తుంది.",
              "or": "ଉପଲବ୍ଧ ସୁଯୋଗକୁ ବୁଝିବା ପାଇଁ ସେମାନେ ସେହି ବିଷୟ ପାଇଁ ତାଙ୍କ କ୍ୟାରିଅର୍ ଯାତ୍ରାକୁ\n  ମାନଚିତ୍ର କରିପାରିବେ ବୋଲି ସେ ମତ ଦେଇଛନ୍ତି |",
              "as": "তেওঁ কৈছে যে সেই বিষয়টোত থকা লাভবোৰ গ'ম পাবলৈ তাইৰ কেৰিয়াৰযাত্ৰাৰ মানচিত্ৰ বনাই চাব পাৰে।",
              "gu": "એની કાકી સૂચવે છે કે તે ઉપલબ્ધ તકોને સમજવા માટે તે વિષય માટે તેની કારકિર્દીની આગળનો નકશો બનાવી શકે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_3_3",
              "hi": "B3_3_3",
              "ka": "B3_3_3",
              "te": "B3_3_3",
              "or": "B3_3_3",
              "as": "B3_3_3",
              "gu": "B3_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "After thinking about this, she feels she likes Physics the most. Her aunt shows her what is possible to pursue after completing her bachelors.",
              "hi": "इस बारे में सोचकर, वह अनुभव करती है कि वह फिजिक्स को सबसे ज्यादा पसंद करती है। उसकी चाची उसे दिखाती है कि उनके बैचलर्स को पूरा करने के बाद क्या संभावनाएं  है।",
              "ka": "ಈ ಬಗ್ಗೆ ಯೋಚಿಸಿದ ನಂತರ, ಅವಳು ಭೌತಶಾಸ್ತ್ರವನ್ನು ಹೆಚ್ಚು ಇಷ್ಟಪಡುತ್ತೇನೆ ಎನ್ನುವುದನ್ನು ತಿಳಿದುಕೊಳ್ಳುತ್ತಾಳೆ. ಅವಳ ಆಂಟಿಯು ಆಕೆ ಪದವಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ ನಂತರ ಏನನ್ನು ಮುಂದುವರಿಸಲು ಸಾಧ್ಯ ಎಂಬುದನ್ನು ತೋರಿಸುತ್ತಾಳೆ.",
              "te": "దీని గురించి ఆలోచించిన తర్వాత, ఆమెకు ఫిజిక్స్ అంటే చాలా ఇష్టం అనిపిస్తుంది. ఆమె అత్త తన బ్యాచిలర్స్ పూర్తి చేసిన తర్వాత ఏమి చేయగలదో చూపిస్తుంది.",
              "or": "ଏ ବିଷୟରେ ଚିନ୍ତା କରିବା ପରେ ସେ ଅନୁଭବ କରନ୍ତି ଯେ ସେ ଫିଜିକ୍ସକୁ ଅଧିକ\n  ପସନ୍ଦ କରନ୍ତି | ସ୍ନାତକୋତ୍ତର ସମାପ୍ତ କରିବା ପରେ କ’ଣ ସମ୍ଭବ ତାହା ତା’ର ମାଉସୀ ତାଙ୍କୁ ଦେଖାଏ |",
              "as": "এই বিষয়ে চিন্তা কৰাৰ পিছত, তাইৰ লাগে যে তাইৰ কাৰনে পদাৰ্থ বিজ্ঞানআটাইতকৈ বেছি ভাল পায়। তাইৰ খুৰীয়ে তাইক দেখুৱাইছে যে তাই ডিগ্ৰী পিছত কি কি কৰিব পাৰে।",
              "gu": "આ વિશે વિચાર્યા પછી, તેને લાગે છે કે તે ભૌતિકશાસ્ત્ર સૌથી વધુ ગમે છે. તેની કાકી તેને બતાવે છે કે તેની સ્નાતક પૂર્ણ કર્યા પછી શું કરવાનું શક્ય છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "mapvisual",
              "hi": "मैपविज़ुअल",
              "ka": "ಮ್ಯಾಪ್ ವಿಷುವಲ್",
              "te": "మ్యాప్ విజువల్",
              "or": "ମାନଚିତ୍ର",
              "as": "মানচিত্ৰদৃশ্য",
              "gu": "નકશામાં દ્રષ્ટિ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "She makes a career map with her aunt.",
              "hi": "वह अपनी चाची के साथ करियर को मैप करती है।",
              "ka": "ಅವಳು ತನ್ನ ಆಂಟಿಯ ಜೊತೆಗೆ ವೃತ್ತಿ ಮ್ಯಾಪ್ ಹಾಕುತ್ತಾಳೆ.",
              "te": "ఆమె తన ఆంటీతో కలిసి కెరీర్ మ్యాప్ తయారు చేస్తుంది.",
              "or": "ସେ ତାଙ୍କ ମାଉସୀ ସହିତ କ୍ୟାରିୟର ମାନଚିତ୍ର ତିଆରି କରେ |",
              "as": "তাই তাইৰ খুড়ীৰ লগত কেৰিয়াৰৰ মানচিত্ৰ বনায়।",
              "gu": "તેની કાકી સાથે કારકિર્દીનો નકશો બનાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "B.Sc. in Physics, 3 years",
              "hi": " फिजिक्स में बीएससी, 3 साल",
              "ka": "ಬಿ‌ಎಸ್‌ಸಿ ಬೌತಶಾಸ್ತ್ರದಲ್ಲಿ - 3 ವರ್ಷಗಳು ",
              "te": "B.Sc. భౌతికశాస్త్రంలో, 3 సంవత్సరాలు",
              "or": "B.Sc. ପଦାର୍ଥ ବିଜ୍ଞାନରେ, 3 ବର୍ଷ",
              "as": "পদাৰ্থ বিজ্ঞানত B.Sc, 3 বছৰ",
              "gu": "B.Sc.. ભૌતિકશાસ્ત્રમાં, 3 વર્ષ"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Apply for a job",
              "hi": "नौकरी के लिए आवेदन करना",
              "ka": "ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಹಾಕುವುದು",
              "te": "ఉద్యోగానికి అప్లై చేయడం",
              "or": "ଚାକିରି ପାଇଁ ଆବେଦନ କରନ୍ତୁ |",
              "as": "চাকৰি এটাৰ বাবে আবেদন কৰক",
              "gu": "નોકરી માટે અરજી"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Short term course",
              "hi": "अल्पावधि कोर्स ",
              "ka": "ಅಲ್ಪಾವಧಿ ಕೋರ್ಸ್",
              "te": "తక్కువ కాలం పట్టే కోర్సు",
              "or": "ସ୍ୱଳ୍ପ ମିଆଦି ପାଠ୍ୟକ୍ରମ |",
              "as": "চুটি পাঠ্যক্ৰম",
              "gu": "ટૂંકા ગાળાનો કોર્સ"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Masters in Science",
              "hi": "विज्ञान में मास्टर्स ",
              "ka": "ವಿಜ್ಞಾನದಲ್ಲಿ ಮಾಸ್ಟರ್ಸ್",
              "te": "సైన్స్ లో మాస్టర్స్",
              "or": "ବିଜ୍ଞାନରେ ମାଷ୍ଟର୍ସ |",
              "as": "বিজ্ঞানত স্নাতকোত্তৰ",
              "gu": "વિજ્ઞાનમાં માસ્ટર્સ"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Jobs in research, non research, academic, private and non private",
              "hi": "अनुसंधान, गैर अनुसंधान, अकादमिक, निजी और गैर निजी में नौकरियां",
              "ka": "ಸಂಶೋಧನೆ, ಸಂಶೋಧನೆಯಲ್ಲದ, ಶೈಕ್ಷಣಿಕ, ಖಾಸಗಿ ಮತ್ತು ಖಾಸಗಿಯಲ್ಲದ ಉದ್ಯೋಗಗಳು",
              "te": "పరిశోధన, పరిశోధనేతర, అకడమిక్, ప్రైవేట్ మరియు నాన్ ప్రైవేట్‌లో ఉద్యోగాలు",
              "or": "ଅନୁସନ୍ଧାନ, ଅଣ ଅନୁସନ୍ଧାନ, ଏକାଡେମିକ୍, ବେସରକାରୀ ଏବଂ ଅଣ ବ୍ୟକ୍ତିଗତ ଚାକିରି |",
              "as": "গৱেষণা, শৈক্ষিক, ব্যক্তিগত আৰু অব্যক্তিগত চাকৰি",
              "gu": "સંશોધન, બિન સંશોધન, શૈક્ષણિક, ખાનગી અને બિન ખાનગીમાં નોકરીઓ"
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "Career map",
              "hi": "कैरियर मैप ",
              "ka": "ವೃತ್ತಿ ಮ್ಯಾಪ್ ",
              "te": "కెరీర్ మ్యాప్",
              "or": "ବୃତ୍ତି ମାନଚିତ୍ର",
              "as": "কেৰিয়াৰ মানচিত্ৰ",
              "gu": "કારકિર્દી નકશો"
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "ಎಮ್‌ಸಿ‌ಕ್ಯೂ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "To help Mira decide her next steps, Mira’s aunt helps her make a career map. Which of the following is TRUE about making career maps?",
              "hi": "अपने अगले कदम का निर्णय करने में मीरा की सहायता करने हेतु, मीरा की चाची उसे करियर मैप करने में सहायता करती है। कैरियर के मैप करने के बारे में निम्नलिखित में से कौन सा कथन सत्य है?",
              "ka": "ಮೀರಾ ತನ್ನ ಮುಂದಿನ ಹಂತಗಳನ್ನು ನಿರ್ಧರಿಸಲು ಸಹಾಯ ಮಾಡಲು, ಮೀರಾಳ ಆಂಟಿ ಅವಳ ವೃತ್ತಿಜೀವನದ ನಕ್ಷೆಯನ್ನು ತಯಾರಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತಾರೆ. ವೃತ್ತಿ ನಕ್ಷೆಗಳನ್ನು ಮಾಡುವ ಕುರಿತು ಈ ಕೆಳಗಿನವುಗಳಲ್ಲಿ ಯಾವುದು ನಿಜ?",
              "te": "మీరా తన తర్వాత స్టెప్స్ నిర్ణయించడంలో సహాయపడటానికి, మీరా ఆంటీ ఆమె కెరీర్ మ్యాప్‌ను తయారుచేయడానికి సహాయపడుతుంది. కెరీర్ మ్యాప్‌లను తయారుచేయడానికి కింది వాటిలో ఏది నిజం?",
              "or": "ମୀରାଙ୍କୁ ତାଙ୍କର ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପ ସ୍ଥିର କରିବାରେ ସାହାଯ୍ୟ କରିବାକୁ, ମୀରାଙ୍କ\n  ମାଉସୀ ତାଙ୍କୁ କ୍ୟାରିୟର ମାନଚିତ୍ର ତିଆରି କରିବାରେ ସାହାଯ୍ୟ କରନ୍ତି | କ୍ୟାରିୟର ମାନଚିତ୍ର ତିଆରି କରିବା ବିଷୟରେ ନିମ୍ନଲିଖିତ ମଧ୍ୟରୁ କେଉଁଟି ସତ୍ୟ?",
              "as": "মীৰাক তাইৰ আহিব লগীয়া দিনবোৰত কি কৰিব লাগে তাক বুজি পোৱাত সহায় কৰিবলৈ, মীৰাৰ খুৰীয়ে তাইক কেৰিয়াৰৰ মানচিত্ৰ তৈয়াৰ কৰে। কেৰিয়াৰৰ মানচিত্ৰ তৈয়াৰ কৰাৰ ক্ষেত্ৰত তলৰ কোনটো সঁচা?",
              "gu": "મીરાને તેના આગળનું શું કરવું તે નક્કી કરવામાં મદદ કરવા માટે, મીરાની કાકી તેને કારકિર્દીનો નકશો બનાવવામાં મદદ કરે છે. કારકિર્દીના નકશા બનાવવા માટે નીચેનામાંથી કયું સાચું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "It helps know more about a particular job, role and industry",
              "hi": "यह किसी विशेष नौकरी, पदभार और इंडस्ट्री के बारे में अधिक जानने में सहायता करता है",
              "ka": "ನಿರ್ದಿಷ್ಟ ಕೆಲಸ, ಪಾತ್ರ ಮತ್ತು ಉದ್ಯಮದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಇದು ಸಹಾಯ ಮಾಡುತ್ತದೆ",
              "te": "ఇది నిర్దిష్టమైన ఉద్యోగం, రోల్ మరియు కంపెనీ గురించి మరింత తెలుసుకోవడానికి సహాయపడుతుంది",
              "or": "ଏହା ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ କାର୍ଯ୍ୟ, ଭୂମିକା ଏବଂ ଶିଳ୍ପ ବିଷୟରେ ଅଧିକ ଜାଣିବାରେ ସାହାଯ୍ୟ କରେ |",
              "as": "ই এক নিৰ্দিষ্ট চাকৰি, ভূমিকা আৰু উদ্যোগৰ বিষয়ে অধিক জনাত সহায় কৰে",
              "gu": "તે ચોક્કસ નોકરી, ભૂમિકા અને ઉદ્યોગ વિશે વધુ જાણવામાં મદદ કરે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "It is made only once in our careers that is while choosing a course",
              "hi": "यह केवल एक बार हमारे करियर में एक कोर्स चुनते समय बनाया गया है ",
              "ka": "ಇದನ್ನು ನಮ್ಮ ವೃತ್ತಿಜೀವನದಲ್ಲಿ ಒಮ್ಮೆ ಮಾತ್ರ ಮಾಡಬಹುದು, ಅಂದರೆ ಕೋರ್ಸ್ ಅನ್ನು ಆಯ್ಕೆಮಾಡುವಾಗ.",
              "te": "ఇది మన కెరీర్‌లో ఒక కోర్సును ఎంచుకునే సమయంలో మాత్రమే తయారు చేయబడుతుంది",
              "or": "ଏହା ଆମ କ୍ୟାରିଅରରେ କେବଳ ଥରେ ତିଆରି ହୋଇଥାଏ ଯାହାକି ଏକ ପାଠ୍ୟକ୍ରମ ବାଛିବା ସମୟରେ |",
              "as": "এইটো আমাৰ কেৰিয়াৰত কেৱল এবাৰহে বনাব পাৰে যিটো পাঠ্যক্ৰম বাছনি কৰোঁতে হয়",
              "gu": "તે આપણી કારકિર્દીમાં ફક્ત એક જ વાર બનાવવામાં આવે છે જે કોર્સ પસંદ કરતી વખતે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "It helps meet new professionals from a particular industry",
              "hi": "यह किसी विशेष इंडस्ट्री से नए प्रोफेशनल्स से मिलने में सहायक है",
              "ka": "ನಿರ್ದಿಷ್ಟ ಉದ್ಯಮದಲ್ಲಿನ ಹೊಸ ವೃತ್ತಿಪರರನ್ನು ಭೇಟಿ ಮಾಡಲು ಇದು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "ఇది నిర్దిష్ట పరిశ్రమ నుండి కొత్త నిపుణులను కలవడంలో సహాయపడుతుంది",
              "or": "ଏହା ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ଶିଳ୍ପରୁ ନୂତନ ବୃତ୍ତିଗତମାନଙ୍କୁ ଭେଟିବାରେ ସାହାଯ୍ୟ କରେ |",
              "as": "ই এটা নিৰ্দিষ্ট উদ্যোগৰ নতুন পেছাদাৰীসকলক লগ পোৱাত সহায় কৰে",
              "gu": "તે ચોક્કસ ઉદ્યોગના નવા વ્યાવસાયિકોને મળવામાં મદદ કરે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "It helps detail out the steps required to meet one’s career goals",
              "hi": "यह किसी के करियर के लक्ष्यों को पूरा करने के लिए आवश्यक चरणों को विस्तारित करने में सहायता करता है",
              "ka": "ಒಬ್ಬರ ವೃತ್ತಿಜೀವನದ ಗುರಿಗಳನ್ನು ಪೂರೈಸಲು ಅಗತ್ಯವಿರುವ ಹಂತಗಳನ್ನು ವಿವರವಾಗಿ ತಿಳಿದುಕೊಳ್ಳಲು ಇದು ಸಹಾಯ ಮಾಡುತ್ತದೆ",
              "te": "ఇది ఒకరి కెరీర్ లక్ష్యాలను చేరుకోవడానికి అవసరమైన దశలను వివరించడంలో సహాయపడుతుంది",
              "or": "ଏହା ଜଣଙ୍କର କ୍ୟାରିୟର ଲକ୍ଷ୍ୟ ପୂରଣ କରିବା ପାଇଁ ଆବଶ୍ୟକ ପଦକ୍ଷେପଗୁଡ଼ିକୁ\n  ବିସ୍ତୃତ ଭାବରେ ସାହାଯ୍ୟ କରେ |",
              "as": "ই এজনৰ কেৰিয়াৰৰ মূল লক্ষ্য পূৰণ কৰিবলৈ কি কৰিব লাগে তাকে দেখুৱাই",
              "gu": "તે વ્યક્તિના કારકિર્દીના ધ્યેયોને પૂરા કરવા માટે જરૂરી પગલાંની વિગતો આપવામાં મદદ કરે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Think again! For such information talk with professionals or do an internship",
              "hi": "फिर से विचार करो! ऐसी जानकारी के लिए प्रोफेशनल्स के साथ बात करे या इंटर्नशिप करे",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಅಂತಹ ಮಾಹಿತಿಗಾಗಿ ವೃತ್ತಿಪರರೊಂದಿಗೆ ಮಾತನಾಡಿ ಅಥವಾ ಇಂಟರ್ನ್‌ಶಿಪ್ ಮಾಡಿ",
              "te": "మళ్ళి ఆలోచించు! అటువంటి సమాచారం కోసం నిపుణులతో మాట్లాడండి లేదా ఇంటర్న్ షిప్ చేయండి",
              "or": "ପୁଣି ଥରେ ଭାବ! ଏହିପରି ସୂଚନା ପାଇଁ ବୃତ୍ତିଗତମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରନ୍ତୁ\n  କିମ୍ବା ଏକ ଇଣ୍ଟର୍ନସିପ୍ କରନ୍ତୁ |",
              "as": "আকৌ চিন্তা কৰক! এনে কথা গ'ম পাবলৈ পেছাদাৰীসকলৰ লগত কথা পাতক বা ইণ্টাৰ্নশ্বিপ কৰক",
              "gu": "ફરીથી વિચારો! આવી માહિતી માટે વ્યાવસાયિકો સાથે વાત કરો અથવા ઇન્ટર્નશિપ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Think again! Career maps can help at any point in our professional lives.",
              "hi": "फिर से विचार करो! करियर मैप हमारे प्रोफेशनल जीवन में किसी भी बिंदु पर सहायता कर सकते हैं।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ವೃತ್ತಿ ನಕ್ಷೆಗಳು ನಮ್ಮ ವೃತ್ತಿಪರ ಜೀವನದಲ್ಲಿ ಯಾವುದೇ ಹಂತದಲ್ಲಿ ಸಹಾಯ ಮಾಡಬಹುದು.",
              "te": "మళ్ళి ఆలోచించు! కెరీర్ మ్యాప్‌లు మన వృత్తి జీవితంలో ఏ సమయంలోనైనా సహాయపడతాయి.",
              "or": "ପୁଣି ଥରେ ଭାବ! କ୍ୟାରିୟର ମାନଚିତ୍ରଗୁଡିକ ଆମର ବୃତ୍ତିଗତ ଜୀବନର ଯେକୌଣସି\n  ସମୟରେ ସାହାଯ୍ୟ କରିଥାଏ |",
              "as": "আকৌ চিন্তা কৰক! কেৰিয়াৰৰ মানচিত্ৰই আমাৰ পেছাদাৰী জীৱনৰ যিকোনো সময়তে সহায় কৰিব পাৰে।",
              "gu": "ફરીથી વિચારો! કારકિર્દીના નકશા અમારા વ્યાવસાયિક જીવનમાં કોઈપણ સમયે મદદ કરી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Think again! To meet new professionals attend networking events",
              "hi": "फिर से विचार करो! नए प्रोफेशनल्स से मिलने के लिए नेटवर्किंग घटनाक्रम में शामिल हो",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಹೊಸ ವೃತ್ತಿಪರರನ್ನು ಭೇಟಿ ಮಾಡಲು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಈವೆಂಟ್‌ಗಳಿಗೆ ಭೇಟಿ ನೀಡಿ.",
              "te": "మళ్ళి ఆలోచించు! కొత్త నిపుణులను కలవడానికి నెట్‌వర్కింగ్ ఈవెంట్‌లకు వెళతారు",
              "or": "ପୁଣି ଥରେ ଭାବ! ନେଟୱାର୍କିଂ ଇଭେଣ୍ଟରେ ଯୋଗଦେବାକୁ ନୂତନ ବୃତ୍ତିଗତମାନଙ୍କୁ ଭେଟିବା |",
              "as": "আকৌ চিন্তা কৰক! নেটৱৰ্কিং অনুষ্ঠানত উপস্থিত থকা নতুন পেছাদাৰীসকলক লগ কৰিবলৈ সহায় কৰিব",
              "gu": "ફરીથી વિચારો! નવા વ્યાવસાયિકને મળવા માટે નેટવર્કિંગ ઇવેન્ટ્સમાં હાજરી આપો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ବହୁତ ଭଲ କାମ!",
              "as": "সুন্দৰ কাম!",
              "gu": "સરસ કામ!"
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_3_4",
              "hi": "B3_3_4",
              "ka": "B3_3_4",
              "te": "B3_3_4",
              "or": "B3_3_4",
              "as": "B3_3_4",
              "gu": "B3_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The options look promising, but this is not her first choice. She is keen on taking up medicine, but she can't risk failing if she attempts it a second time.",
              "hi": "विकल्प सही लगते हैं, लेकिन यह उसकी पहली पसंद नहीं है। वह मेडिकल करने के लिए उत्साहित है, लेकिन दुबारा प्रयास करके वह असफल होने का जोखिम नहीं उठा सकती है।",
              "ka": "ಆಯ್ಕೆಗಳು ಭರವಸೆಯುಳ್ಳವಂತೆ ಕಾಣುತ್ತವೆ, ಆದರೆ ಇದು ಅವಳ ಮೊದಲ ಆಯ್ಕೆಯಲ್ಲ. ಅವಳು ವೈದ್ಯಕೀಯವನ್ನು  ತೆಗೆದುಕೊಳ್ಳಲು ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ, ಆದರೆ ಅವಳು ಅದನ್ನು ಎರಡನೇ ಬಾರಿಗೆ ಪ್ರಯತ್ನಿಸಿಯೂ ಕೂಡ ಅವಳು ವಿಫಲಗೊಳ್ಳುವ ಅಪಾಯವು ಇದೆ ಎನ್ನುವುದನ್ನು ಅರಿತುಕೊಳ್ಳಬೇಕು.",
              "te": "ఆప్షన్స్ బాగున్నాయి, కానీ ఇది ఆమె మొదటి ఆప్షన్ కాదు. ఆమె మెడిసెన్ తీసుకోవడానిపై ఆసక్తి ఉంది, కానీ ఆమె రెండవసారి ప్రయత్నించినట్లయితే ఆమె ఫెయిల్ అయ్యే ప్రమాదం లేదు.",
              "or": "ବିକଳ୍ପଗୁଡିକ ପ୍ରତିଜ୍ଞାକାରୀ ଦେଖାଯାଏ, କିନ୍ତୁ ଏହା ତାଙ୍କର ପ୍ରଥମ ପସନ୍ଦ ନୁହେଁ | \n ସେ ମେଡ଼ିସିନ ପଢିବାକୁ କରିବାକୁ ଆଗ୍ରହୀ, କିନ୍ତୁ ଯଦି ସେ ଦ୍ୱିତୀୟ ଥର ଚେଷ୍ଟା କରନ୍ତି ତେବେ ସେ ବିଫଳ ହେବାର ଆଶଙ୍କା କରିପାରିବେ ନାହିଁ |",
              "as": "কথাবোৰ ভাল যেন লাগিছে, কিন্তু এইটো তাইৰ প্ৰথম পছন্দ নহয়। তাইৰ মেডিকেল পঢ়াৰ বহুত ইচ্ছা আছে কিন্তু তাই এবাৰ বিফল হলে কি হব তাক লৈ ভয় কৰি আছে।",
              "gu": "વિકલ્પો આશાસ્પદ લાગે છે, પરંતુ આ તેની પ્રથમ પસંદગી નથી. તે તબીબી વિષે ભણવા માટે ઉત્સુક છે, પરંતુ જો તેને બીજી વખત તેનો પ્રયાસ કરે તો તે નિષ્ફળ થવાનું જોખમ લઈ શકતી નથી."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_4_1",
              "hi": "B3_4_1",
              "ka": "B3_4_1",
              "te": "B3_4_1",
              "or": "B3_4_1",
              "as": "B3_4_1",
              "gu": "B3_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She knows her parents will be relieved if she takes this course because it would be difficult for them to afford another year of preparation.",
              "hi": "वह जानती है कि अगर वह इस कोर्स को लेती है तो उसके माता-पिता को राहत मिलेगी क्योंकि उनके लिए तैयारी के एक और वर्ष का खर्च उठाना मुश्किल होगा।",
              "ka": "ಅವಳು ಈ ಕೋರ್ಸ್ ತೆಗೆದುಕೊಂಡರೆ ತನ್ನ ಹೆತ್ತವರಿಗೆ ಸಮಾಧಾನವಾಗುತ್ತದೆ ಎಂದು ಅವಳು ತಿಳಿದುಕೊಂಡಿದ್ದಾಳೆ. ಏಕೆಂದರೆ ಅವರಿಗೆ ಇನ್ನೊಂದು ವರ್ಷದ ತಯಾರಿಯನ್ನು ಭರಿಸುವುದು ಕಷ್ಟ.",
              "te": "ఆమె ఈ కోర్సును తీసుకుంటే తన తల్లిదండ్రులకి ఒత్తిడి తగ్గుతుందని ఆమెకు తెలుసు, ఎందుకంటే వారికి మరో సంవత్సరం ప్రిపరేషన్‌ను కోసం డబ్బులు ఇవ్వడం కష్టం.",
              "or": "ସେ ଜାଣନ୍ତି ଯଦି ସେ ଏହି ପାଠ୍ୟକ୍ରମ ଗ୍ରହଣ କରନ୍ତି ତେବେ ତାଙ୍କ ପିତାମାତା ଆରାମ\n  ପାଇବେ କାରଣ ଅନ୍ୟ ଏକ ବର୍ଷର ପ୍ରସ୍ତୁତି ସେମାନଙ୍କ ପାଇଁ କଷ୍ଟକର ହେବ |",
              "as": "তাই জানে যে যদি তাই এই পাঠ্যক্ৰমটো লয় তেন্তে তাইৰ মাক দেউতাক শান্তি পাব কাৰন তেওঁলোকৰ বাবে আৰু এবছৰৰ প্ৰস্তুতি ৰ খৰচ কৰাটো টান হ'ব।",
              "gu": "તે જાણે છે કે જો તે આ અભ્યાસક્રમ લેશે તો તેના માતા-પિતાને રાહત થશે કારણ કે તેમના માટે બીજા વર્ષની તૈયારી માટે આર્થિક સહારો મુશ્કેલ હશે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_4_2 D",
              "hi": "B3_4_2 D",
              "ka": "B3_4_2 D",
              "te": "B3_4_2 D",
              "or": "B3_4_2 D",
              "as": "B3_4_2 D",
              "gu": "B3_4_2 D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Mira do?",
              "hi": "मीरा को क्या करना चाहिए?",
              "ka": "ಮೀರಾ ಈಗ ಏನು ಮಾಡಬೇಕು?",
              "te": "మీరా ఏమి చేయాలి?",
              "or": "ମୀରା କ’ଣ କରିବା ଉଚିତ୍?",
              "as": "মীৰাই কি কৰিব লাগে?",
              "gu": "મીરાએ શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Apply for the same college as Lina for bachelors in physics",
              "hi": " फिजिक्स में बैचलर कोर्स करने के लिए लीना की तरह कॉलेज में आवेदन करे",
              "ka": "ಭೌತಶಾಸ್ತ್ರದಲ್ಲಿ ಪದವಿಗಾಗಿ ಲೀನಾಳ ಕಾಲೇಜಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದು.",
              "te": "ఫిజిక్స్ లో బ్యాచిలర్స్ కోసం లీనా చదివే కళాశాలకు అప్లై చేసుకోండి",
              "or": "ପଦାର୍ଥ ବିଜ୍ଞାନରେ ସ୍ନାତକୋତ୍ତର ପାଇଁ ଲୀନା ସହିତ ସମାନ କଲେଜ ପାଇଁ ଆବେଦନ\n  କରନ୍ତୁ |",
              "as": "পদাৰ্থ বিজ্ঞানত স্নাতকৰ বাবে লীনাৰ দৰে একেখন মহাবিদ্যালয়তে নাম ভৰ্তি কৰক",
              "gu": "ફિઝિક્સમાં સ્નાતક માટે લીના જેવી જ કોલેજ માટે અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Try for medical entrance again",
              "hi": "मेडिकल में प्रवेश के लिए फिर से प्रयास करे",
              "ka": "ವೈದ್ಯಕೀಯ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಯನ್ನು ಮತ್ತೆ ತೆಗೆದುಕೊಳ್ಳುವುದೇ",
              "te": "మెడిసెన్ రాయడం కోసం మళ్ళి ప్రయత్నించండి",
              "or": "ପୁନର୍ବାର ଡାକ୍ତରୀ ପ୍ରବେଶ ପାଇଁ ଚେଷ୍ଟା କରନ୍ତୁ |",
              "as": "পুনৰ চিকিৎসা প্ৰৱেশৰ পৰীক্ষা কাৰনে চেষ্টা কৰক",
              "gu": "તબીબી શાસ્ત્રમાં પ્રવેશ માટે ફરી પ્રયાસ કરો"
            }
          }
        ]
      },
      "scene34": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Try Medical entrance again",
              "hi": "फिर से मेडिकल में प्रवेश के लिए प्रयास करे",
              "ka": "ವೈದ್ಯಕೀಯ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಯನ್ನು ಮತ್ತೆ ತೆಗೆದುಕೊಳ್ಳುವುದೇ",
              "te": "మెడిసెన్ రాయడం కోసం మళ్ళి ప్రయత్నించండి",
              "or": "ପୁନର୍ବାର ମେଡିକାଲ୍ ପ୍ରବେଶକୁ ଚେଷ୍ଟା କରନ୍ତୁ |",
              "as": "পুনৰ চিকিৎসা প্ৰৱেশৰ পৰীক্ষা কাৰনে চেষ্টা কৰক",
              "gu": "તબીબી શાસ્ત્રમાં પ્રવેશ માટે ફરી પ્રયાસ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She decides to appear for the medical exams once again. She still can’t afford the coaching but gets all the books from Lina.",
              "hi": "वह एक बार फिर मेडिकल परीक्षा देने का निश्चय  करती है। वह अभी भी कोचिंग का खर्च वहन नहीं कर सकती है लेकिन लीना से सभी किताबें मिल जाती हैं।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ವೈದ್ಯಕೀಯ ಪರೀಕ್ಷೆಗೆ ಹಾಜರಾಗಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಅವಳು ಈಗಲೂ ತರಬೇತಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ ಆದರೆ ಲೀನಾಳಿಂದ ಎಲ್ಲಾ ಪುಸ್ತಕಗಳನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
              "te": "ఆమె మరోసారి మెడికల్ ఎంట్రన్స్ కి హాజరు కావాలని నిర్ణయించుకుంది. ఆమె ఇప్పటికీ కోచింగ్‌ను భరించలేకపోయింది కానీ లీనా నుండి అన్ని పుస్తకాలను తీసుకుంటుంది.",
              "or": "ସେ ପୁଣି ଥରେ ଡାକ୍ତରୀ ପରୀକ୍ଷା ପାଇଁ ହାଜର ହେବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି। ସେ ତଥାପି କୋଚିଂ ଦେଇପାରୁନାହାଁନ୍ତି କିନ୍ତୁ ଲୀନାଙ୍କଠାରୁ ସମସ୍ତ ବହି ପାଇଛନ୍ତି |",
              "as": "তাই আকৌ এবাৰ চিকিৎসা পৰীক্ষা দিম বুলি সিদ্ধান্ত লয়। তাই এতিয়াও কোচিংৰ খৰচ কৰিব নোৱাৰে কিন্তু লীনাৰ পৰা সকলো কিতাপ পায়।",
              "gu": "તેને ફરી એકવાર તબીબી શાસ્ત્રમાં પરીક્ષા આપવાનું નક્કી કર્યું. તે હજી પણ કોચિંગ પરવડી શકતી નથી પરંતુ લીના પાસેથી તમામ પુસ્તકો મેળવે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_2_1",
              "hi": "B4_2_1",
              "ka": "B4_2_1",
              "te": "B4_2_1",
              "or": "B4_2_1",
              "as": "B4_2_1",
              "gu": "B4_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She studies very hard throughout the year. She wants her dream of being the first doctor in her family to come true.",
              "hi": "वह पूरे वर्ष कठिन परिश्रम करती है। वह अपने परिवार में पहली डॉक्टर बनने  का अपना सपना सच करना चाहती है।",
              "ka": "ವರ್ಷವಿಡೀ ತುಂಬಾ ಕಷ್ಟಪಟ್ಟು ಓದುತ್ತಾಳೆ. ತನ್ನ ಕುಟುಂಬದ ಮೊದಲ ವೈದ್ಯೆ ಎಂಬ ತನ್ನ ಕನಸು ನನಸಾಗಲಿ ಎಂದು ಬಯಸುತ್ತಾಳೆ.",
              "te": "ఆమె ఏడాది పొడవునా చాలా కష్టపడి చదువుతుంది. తన కుటుంబంలో మొదటి డాక్టర్ కావాలన్న తన కల నెరవేరాలని కోరుకుంటోంది.",
              "or": "ସେ ବର୍ଷସାରା ବହୁତ କଠିନ ଅଧ୍ୟୟନ କରନ୍ତି | ସେ ଚାହୁଁଛନ୍ତି ଯେ ତାଙ୍କ ପରିବାରର \n ପ୍ରଥମ ଡାକ୍ତର ହେବାର ସ୍ୱପ୍ନ ସାକାର ହେଉ।",
              "as": "তাই গোটেই বছৰটো বহুত কষ্ট কৰি পঢ়া শুনা কৰে। তাই বিচাৰে যে তাই নিজৰ পৰিয়ালৰ প্ৰথম চিকিৎসক হোৱাৰ সপোন সঁচা হ'ব।",
              "gu": "તે આખા વર્ષ દરમિયાન ખૂબ જ સખત અભ્યાસ કરે છે. તે ઈચ્છે છે કે તેના પરિવારમાં પ્રથમ ડૉક્ટર બનવાનું તેનું સ્વપ્ન સાકાર થાય."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_3_1",
              "hi": "B4_3_1",
              "ka": "B4_3_1",
              "te": "B4_3_1",
              "or": "B4_3_1",
              "as": "B4_3_1",
              "gu": "B4_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "On the day of the result, Ranjan Uncle, Neetu Aunty and Mira sit together to check the rank. Ranjan Uncle opens the portal on his phone.",
              "hi": "परीक्षा परिणाम वाले दिन , रंजन चाचा, नीतू चाची और मीरा रैंक की जांच के लिए एक साथ बैठते हैं। रंजन चाचा अपने फोन पर पोर्टल खोलते हैं।",
              "ka": "ಫಲಿತಾಂಶದ ದಿನ, ರಂಜನ್ ಅಂಕಲ್, ನೀತು ಆಂಟಿ ಮತ್ತು ಮೀರಾ ಒಟ್ಟಿಗೆ ಕುಳಿತು ಶ್ರೇಣಿಯನ್ನು ಪರಿಶೀಲಿಸುತ್ತಾರೆ. ರಂಜನ್ ಅಂಕಲ್ ತನ್ನ ಫೋನ್‌ನಲ್ಲಿ ಪೋರ್ಟಲ್ ಅನ್ನು ತೆರೆಯುತ್ತಾರೆ.",
              "te": "రిజల్ట్స్ వచ్చిన రోజు, రన్జన్ అంకుల్, నీతు ఆంటీ మరియు మీరా కలిసి ర్యాంక్ చెక్ చేయడానికి కూర్చున్నారు. రన్జన్ అంకుల్ తన ఫోన్‌లో పోర్టల్‌ని ఓపెన్ చేశారు.",
              "or": "ଫଳାଫଳ ଦିନ, ରଂଜନ ଅଙ୍କଲ, ନୀଟୁ ମାଉସୀ ଏବଂ ମୀରା ଏକାଠି ବସି ରାଙ୍କ ଯାଞ୍ଚ \n କରନ୍ତି | ରଂଜନ ଅଙ୍କଲ ତାଙ୍କ ଫୋନରେ ପୋର୍ଟାଲ ଖୋଲନ୍ତି |",
              "as": "ৰিজাল্টত দিনা, ৰঞ্জন আংকল, নীতু আণ্টি আৰু মীৰা একেলগে বহি ৰেংক টো পৰীক্ষা কৰে। ৰঞ্জন খুৰাকে তেওঁৰ ফোনত পৰ্টেলটো খুলিলে।",
              "gu": "રિઝલ્ટના દિવસે રંજન કાકા, નીતુ કાકી અને મીરા સાથે બેસીને ક્રમ જુવે છે. રંજન કાકા તેમના ફોન પર પોર્ટલ ખોલે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_3_2///",
              "hi": "B4_3_2///",
              "ka": "B4_3_2///",
              "te": "B4_3_2///",
              "or": "B4_3_2///",
              "as": "B4_3_2///",
              "gu": "B4_3_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Mira! This is a good score!",
              "hi": "मीरा! यह एक अच्छा स्कोर है!",
              "ka": "ಮೀರಾ! ಇದು ಒಳ್ಳೆಯ ಸ್ಕೋರ್!",
              "te": "మీరా! ఇది మంచి స్కోరే!",
              "or": "ମୀରା! ଏହା ଏକ ଭଲ ସ୍କୋର!",
              "as": "মীৰা! এইটো এটা ভাল নম্বৰ!",
              "gu": "મીરા! આ એક સારા ગુણ છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Do you think? Will I get a seat?",
              "hi": "क्या आपको ऐसा लगता है? क्या मुझे सीट मिलेगी?",
              "ka": "ಹೌದೇ? ನನಗೆ ಸೀಟು ಸಿಗಬಹುದೇ? ",
              "te": "మీరు అనుకుంటున్నారా? నాకు సీటు వస్తుందా?",
              "or": "ତୁମେ ଭାବୁଛ କି? ମୁଁ ଗୋଟିଏ ଆସନ ପାଇବି କି?",
              "as": "আপুনি ভাবেনে বাৰু? মই আসন পাম নেকি?",
              "gu": "શું તું વિચારો છો? શું મને સીટ મળશે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I’m pretty sure of it. This is a good score to get admission into a government college.",
              "hi": "मुझे पूरा विश्वास है। एक सरकारी कॉलेज में प्रवेश पाने के लिए यह एक अच्छा स्कोर है।",
              "ka": "ನನಗೆ ಅದರ ಬಗ್ಗೆ ಬಹಳ ಖಚಿತವಿದೆ. ಸರ್ಕಾರಿ ಕಾಲೇಜಿಗೆ ಪ್ರವೇಶ ಪಡೆಯಲು ಇದು ಉತ್ತಮ ಅಂಕವಾಗಿದೆ.",
              "te": "నేను ఖచ్చితంగా చెబుతున్నాను. ప్రభుత్వ కళాశాలలో ప్రవేశం పొందేందుకు ఇది మంచి స్కోరు.",
              "or": "ମୁଁ ଏହା ବିଷୟରେ ନିଶ୍ଚିତ ଅଟେ | ଏକ ସରକାରୀ କଲେଜରେ ଆଡମିଶନ ପାଇବା\n ପାଇଁ ଏହା ଏକ ଭଲ ସ୍କୋର |",
              "as": "মই ইয়াৰ বিষয়ে নিশ্চিত। চৰকাৰী মহাবিদ্যালয়ত নামভৰ্তি কৰিবলৈ এইটো এটা ভাল নম্বৰ।",
              "gu": "મને તેની ખૂબ ખાતરી છે. સરકારી કોલેજમાં પ્રવેશ મેળવવા માટે આ સારા ગુણ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Start applying and see what you get.",
              "hi": "आवेदन करना शुरू करे और देखे कि उसको क्या मिलता है।",
              "ka": "ಅರ್ಜಿಯನ್ನು ಹಾಕಲು ಶುರು ಮಾಡು ಮತ್ತು ನಿನಗೆ ಏನು ಸಿಗುತ್ತದೆ ಎನ್ನುವುದನ್ನು ನೋಡು ",
              "te": "అప్లై చేయడం స్టార్ట్ చేయి మరియు నీకు ఏమి వస్తుందో చూడు.",
              "or": "ଆପଣ ପ୍ରୟୋଗ କରିବା ଆରମ୍ଭ କରନ୍ତୁ ଏବଂ ଦେଖନ୍ତୁ |",
              "as": "আবেদন কৰা আৰম্ভ কৰক আৰু ক'ত পোৱা চোৱা।",
              "gu": "અરજી કરવાનું શરૂ કર અને જો કે તને શું મળે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_4_1",
              "hi": "B4_4_1",
              "ka": "B4_4_1",
              "te": "B4_4_1",
              "or": "B4_4_1",
              "as": "B4_4_1",
              "gu": "B4_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is eligible for reservation, and with her rank, she gets admission to a newly opened government college in Assam. She also decides to apply to government colleges in other states.",
              "hi": "मीरा को आरक्षण मिल सकता है, और अपनी  रैंक के कारण, उसे असम में एक नए सरकारी कॉलेज में प्रवेश मिलता है। वह अन्य राज्यों के सरकारी कॉलेज में भी आवेदन करने का निर्णय लेती है।",
              "ka": "ಮೀರಾ ಮೀಸಲಾತಿಗೆ ಅರ್ಹಳಾಗಿದ್ದಾಳೆ ಮತ್ತು ತನ್ನ ಶ್ರೇಣಿಯೊಂದಿಗೆ ಅಸ್ಸಾಂನಲ್ಲಿ ಹೊಸದಾಗಿ ತೆರೆದ ಸರ್ಕಾರಿ ಕಾಲೇಜಿಗೆ ಪ್ರವೇಶ ಪಡೆಯುತ್ತಾಳೆ. ಇತರ ರಾಜ್ಯಗಳ ಸರ್ಕಾರಿ ಕಾಲೇಜುಗಳಿಗೂ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
              "te": "మీరాకి రిజర్వేషన్‌ ఉంది మరియు ఆమె ర్యాంక్‌తో అస్సాంలో కొత్తగా ప్రారంభించబడిన ప్రభుత్వ కళాశాలలో ప్రవేశం పొందింది. ఆమె ఇతర రాష్ట్రాల్లోని ప్రభుత్వ కళాశాలలకు కూడా అప్లై చేయాలని నిర్ణయించుకుంది.",
              "or": "ମୀରା ସଂରକ୍ଷଣ ପାଇଁ ଯୋଗ୍ୟ ଏବଂ ତାଙ୍କ ପଦବୀ ସହିତ ସେ ଆସାମର ଏକ ନୂତନ\n  ଖୋଲାଯାଇଥିବା ସରକାରୀ କଲେଜରେ ଆଡମିଶନ ପାଆନ୍ତି | ସେ ଅନ୍ୟ ରାଜ୍ୟର ସରକାରୀ କଲେଜଗୁଡ଼ିକରେ ଆବେଦନ କରିବାକୁ ମଧ୍ୟ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି।",
              "as": "মীৰা সংৰক্ষণৰ বাবে যোগ্য, আৰু সেইটো পৰা তাই অসমৰ এখন নতুনকৈ খোলা চৰকাৰী মহাবিদ্যালয়ত নামভৰ্তি কৰে। তাই আন ৰাজ্যৰ চৰকাৰী মহাবিদ্যালয়সমূহতো আবেদন কৰাৰ কথা ভাবে।",
              "gu": "મીરા આરક્ષણ માટે લાયક છે, અને તેના ગુણ સાથે, તેને આસામમાં નવી ખુલેલી સરકારી કોલેજમાં પ્રવેશ મળે છે. તેને અન્ય રાજ્યોની સરકારી કોલેજોમાં પણ અરજી કરવાનું નક્કી કરે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_4_2",
              "hi": "B4_4_2",
              "ka": "B4_4_2",
              "te": "B4_4_2",
              "or": "B4_4_2",
              "as": "B4_4_2",
              "gu": "B4_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Much to her surprise, she gets admission to a well-known government college in Karnataka. She is keen on going to Karnataka to study, and it would be the first time she would leave Assam.",
              "hi": "वह चकित है, उसको कर्नाटक के एक प्रसिद्ध सरकारी कॉलेज में प्रवेश मिल जाता है। वह अध्ययन हेतु कर्नाटक जाने के लिए उत्सुक है, और यह पहली बार है जब वह असम से बाहर जाएगी।",
              "ka": "ಅವಳಿಗೆ ಆಶ್ಚರ್ಯವಾಗುವಂತೆ, ಅವಳು ಕರ್ನಾಟಕದ ಪ್ರಸಿದ್ಧ ಸರ್ಕಾರಿ ಕಾಲೇಜಿಗೆ ಪ್ರವೇಶ ಪಡೆಯುತ್ತಾಳೆ. ಅವಳು ಕರ್ನಾಟಕಕ್ಕೆ ಓದಲು ಹೋಗಬೇಕೆಂದು ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ ಮತ್ತು ಅವಳು ಅಸ್ಸಾಂನಿಂದ ಮೊದಲ ಬಾರಿಗೆ ಹೊರಹೋಗಬೇಕಾಗುತ್ತದೆ.",
              "te": "ఆమెను చాలా ఆశ్చర్యపరుస్తూ, కర్ణాటకలోని ఒక ప్రసిద్ధ ప్రభుత్వ కళాశాలలో ప్రవేశం పొందింది. ఆమె చదువుకోవడానికి కర్ణాటకకు వెళ్లాలని ఆసక్తిగా ఉంది మరియు అస్సాం వదిలి వెళ్లడం తనకి అదే మొదటిసారి.",
              "or": "ତାଙ୍କୁ ବହୁତ ଆଶ୍ଚର୍ଯ୍ୟ ଲାଗିଲା, ସେ କର୍ଣ୍ଣାଟକର ଏକ ଜଣାଶୁଣା ସରକାରୀ କଲେଜରେ ଆଡମିଶନ ପାଆନ୍ତି | ସେ ଅଧ୍ୟୟନ ପାଇଁ କର୍ଣ୍ଣାଟକ ଯିବାକୁ ଆଗ୍ରହୀ, ଏବଂ ସେ ପ୍ରଥମ ଥର ଆସାମ ଛାଡିବେ |",
              "as": "তাই আচৰিত হৈ কৰ্ণাটকৰ এখন প্ৰসিদ্ধ চৰকাৰী মহাবিদ্যালয়ত নামভৰ্তি কৰে। তাই কৰ্ণাটকলৈ পঢ়িবলৈ যাবলৈ আগ্ৰহী, আৰু প্ৰথমবাৰৰ বাবে অসম এৰিব।",
              "gu": "તેને ખૂબ જ આશ્ચર્ય થાય છે, તેને કર્ણાટકની એક જાણીતી સરકારી કોલેજમાં પ્રવેશ મળે છે. તે કર્ણાટક ભણવા માટે આતુર છે અને તે આસામ છોડશે તે પહેલી વાર હશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_5_1 bg",
              "hi": "B4_5_1 बीजी",
              "ka": "B4_5_1 bg",
              "te": "B4_5_1 bg",
              "or": "B4_5_1 bg",
              "as": "B4_5_1 bg",
              "gu": "B4_5_1 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "That day, she speaks to her mother about her options.",
              "hi": "उस दिन, वह अपनी मां से अपने विकल्पों के बारे में बात करती है।",
              "ka": "ಆ ದಿನ, ಅವಳು ತನ್ನ ಆಯ್ಕೆಗಳ ಬಗ್ಗೆ ತನ್ನ ತಾಯಿಯೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾಳೆ.",
              "te": "ఆ రోజు, ఆమె తన ఆప్షన్స్ గురించి తల్లితో మాట్లాడుతుంది.",
              "or": "ସେହି ଦିନ, ଏହି ବିଷୟରେ ସେ ମା ଙ୍କ ସହ କଥା ହେଲା।",
              "as": "সেইদিনা, তাই তাইৰ মাকৰ সৈতে তাই কি কি কৰিব পাৰে তাৰ বিষয়ে কথা পাতি আছিলে।",
              "gu": "તે દિવસે, તે તેની માતા સાથે તેના વિકલ્પો વિશે વાત કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mother",
              "hi": "मां",
              "ka": "ಅಮ್ಮ",
              "te": "తల్లి",
              "or": "ମା",
              "as": "মা",
              "gu": "માતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Why do you want to go to Karnataka? How will you manage your food? The expenses will be higher, and we won’t meet for months.",
              "hi": "तुम कर्नाटक क्यों जाना चाहती हो? तुम अपने भोजन का प्रबंधन कैसे करोगी? खर्च अधिक होगा, और हम महीनों तक नहीं मिल पाएंगे।",
              "ka": "ಕರ್ನಾಟಕಕ್ಕೆ ಯಾಕೆ ಹೋಗಬೇಕು? ನಿನ್ನ ಆಹಾರವನ್ನು ನೀನು ಹೇಗೆ ನಿಭಾಯಿಸುತ್ತೀ ? ವೆಚ್ಚಗಳು ಹೆಚ್ಚಾಗುತ್ತವೆ ಮತ್ತು ನಾವು ತಿಂಗಳುಗಳವರೆಗೆ ಭೇಟಿಯಾಗುವುದಿಲ್ಲ.",
              "te": "నువ్వు కర్ణాటకకు ఎందుకు వెళ్లాలనుకుంటున్నారు? నువ్వు నీ ఆహారాన్ని ఎలా మేనేజ్ చేస్తావు? ఖర్చులు ఎక్కువగా ఉంటాయి మరియు మనం నెలల తరబడి కలుసుకోలేము.",
              "or": "ଆପଣ କର୍ଣ୍ଣାଟକକୁ କାହିଁକି ଯିବାକୁ ଚାହୁଁଛନ୍ତି? ତୁମେ ତୁମର ଖାଦ୍ୟ କିପରି ପରିଚାଳନା \n କରିବ? ଖର୍ଚ୍ଚ ଅଧିକ ହେବ, ଏବଂ ଆମେ ମାସ ମାସ ଧରି ତୁମକୁଭେଟିବୁ ନାହିଁ |",
              "as": "তুমি কৰ্ণাটকলৈ কিয় যাব বিচাৰিছা? তাত খোৱা বোৱা কেনেকৈ চাবা? খৰচ বহুত হ'ব, আৰু আমি বহুত মাহ লগ নাপাম",
              "gu": "તું કર્ણાટક કેમ જવા માંગે છે? તું તારું જમવાનું શું કરીશ? ખર્ચ વધુ હશે, અને આપણે મહિનાઓ સુધી મળીશું નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "But Ma, can we manage for some time? The college in Karnataka is extremely well recognised, and this new college in Assam is still being set up.",
              "hi": "लेकिन माँ, क्या हम कुछ समय के लिए प्रबंधन कर सकते हैं? कर्नाटक में कॉलेज पूर्णतः मान्यता प्राप्त है, और असम में यह नया कॉलेज अभी  बनाया जा रहा है।",
              "ka": "ಆದರೆ ಅಮ್ಮ, ನಾವು ಸ್ವಲ್ಪ ಸಮಯದವರೆಗೆ ನಿರ್ವಹಿಸಬಹುದೇ? ಕರ್ನಾಟಕದಲ್ಲಿನ ಕಾಲೇಜನ್ನು ಅತ್ಯಂತ ಉತ್ತಮ ಎಂದು ಗುರುತಿಸಲಾಗಿದೆ ಮತ್ತು ಅಸ್ಸಾಂನಲ್ಲಿ ಈ ಹೊಸ ಕಾಲೇಜನ್ನು ಇನ್ನೂ ಸ್ಥಾಪಿಸಲಾಗುತ್ತಿದೆ.",
              "te": "అయితే అమ్మ, మనం కొంత సమయం మేనేజ్ చేయగలమా? కర్ణాటకలోని కళాశాలకు మంచి గుర్తింపు ఉంది మరియు అస్సాంలో ఈ కొత్త కళాశాల ఇప్పటికీ ఏర్పాటు చేస్తూనే ఉన్నారు.",
              "or": "କିନ୍ତୁ ମା, ଆମେ କିଛି ସମୟ ପାଇଁ ପରିଚାଳନା କରିପାରିବା କି? କର୍ଣ୍ଣାଟକର ଏହି କଲେଜ ଅତ୍ୟନ୍ତ ସ୍ୱୀକୃତିପ୍ରାପ୍ତ ଏବଂ ଆସାମର ଏହି ନୂତନ କଲେଜଟି ବର୍ତ୍ତମାନ ମଧ୍ୟ ସ୍ଥାପିତ ହେଉଛି |",
              "as": "কিন্তু মা, আমি কিছু সময়ৰ কাৰনে মিলাব পাৰোঁনে? কৰ্ণাটকৰ মহাবিদ্যালয়খন বহুত জনাজাত, আৰু অসমৰ এই নতুন মহাবিদ্যালয়খন এতিয়াও বনাইয়ে আছে।",
              "gu": "પણ મા, આપણે થોડો સમય ભ્ગવવું પડશે? કર્ણાટકમાં કોલેજ ખૂબ જ સારી રીતે ઓળખાય છે, અને આસામમાં આ નવી કોલેજ હજુ પણ સ્થાપિત કરવામાં આવી રહી છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_5_2 ///",
              "hi": "B4_5_2 ///",
              "ka": "B4_5_2 ///",
              "te": "B4_5_2 ///",
              "or": "B4_5_2 ///",
              "as": "B4_5_2 ///",
              "gu": "B4_5_2 ///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Mira, you don't know the place, you don't know the language well. How will you manage?",
              "hi": "मीरा, तुम जगह को नहीं जानती हो, भाषा को अच्छी तरह से नहीं समझतीं। तुम कैसे प्रबंध करोगी?",
              "ka": "ಮೀರಾ, ನಿನಗೆ ಸ್ಥಳ ಗೊತ್ತಿಲ್ಲ, ನಿನಗೆ ಭಾಷೆ ಗೊತ್ತಿಲ್ಲ. ನೀನು ಹೇಗೆ ನಿಭಾಯಿಸುತ್ತೀ?",
              "te": "మీరా, నీకు ఆ ప్రదేశం తెలియదు, నీకు భాష బాగా రాదు. నువ్వు ఎలా మేనేజ్ చేస్తావు?",
              "or": "ମୀରା, ତୁମେ ସ୍ଥାନ ଜାଣି ନାହଁ, ତୁମେ ଭାଷାକୁ ଭଲଭାବେ ଜାଣି ନାହଁ | ଆପଣ କିପରି \n ପରିଚାଳନା କରିବେ?",
              "as": "মীৰা, তুমি নতুন ঠাই খন নাজানা, তুমি ভাষাটো ভালদৰে নাজানা। তুমি কেনেকৈ মিলাবা?",
              "gu": "મીરા, તને એ જગ્યા વિષે નથી ખબર, તને તે ભાષા આવડતી નથી. તું કેવી રીતે ચલાવી લઈશ?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I will learn on the job. I will be meeting so many patients every day. I'll pick up the language. And students from across India come to study there. Everyone will be facing similar challenges.",
              "hi": "मैं नौकरी पर सीखूंगी। मैं हर दिन इतने सारे रोगियों से मिलूंगी। मैं भाषा समझूँगी। और पूरे भारत के छात्र वहां अध्ययन करने आते हैं। हर किसी को समान चुनौतियों का सामना करना पड़ेगा।",
              "ka": "ನಾನು ಕೆಲಸವನ್ನು ಕಲಿಯುತ್ತೇನೆ. ನಾನು ಪ್ರತಿದಿನ ಹಲವಾರು ರೋಗಿಗಳನ್ನು ಭೇಟಿಯಾಗುತ್ತೇನೆ. ನಾನು ಭಾಷೆಯನ್ನು ಕಲಿತುಕೊಳ್ಳುತ್ತೇನೆ. ಮತ್ತು ಭಾರತದಾದ್ಯಂತ ವಿದ್ಯಾರ್ಥಿಗಳು ಅಲ್ಲಿ ಅಧ್ಯಯನ ಮಾಡಲು ಬರುತ್ತಾರೆ. ಪ್ರತಿಯೊಬ್ಬರೂ ಇದೇ ರೀತಿಯ ಸವಾಲುಗಳನ್ನು ಎದುರಿಸುತ್ತಾರೆ.",
              "te": "నేను ఉద్యోగంలో నేర్చుకుంటాను. నేను ప్రతిరోజూ చాలా మంది రోగులను కలుస్తాను. నేను భాషను మెరుగు పరుచుకుంటాను మరియు భారతదేశంలో ఉన్న విద్యార్థులు అందరూ అక్కడకి చదువుకోవడానికి వస్తారు. ప్రతి ఒక్కరూ ఇలాంటి సవాళ్లను ఎదుర్కొంటారు.",
              "or": "ମୁଁ ଚାକିରିରେ ଶିଖିବି | ମୁଁ ପ୍ରତିଦିନ ଏତେ ସଂଖ୍ୟକ ରୋଗୀଙ୍କୁ ଭେଟିବି | ମୁଁ ଭାଷା ଉଠାଇବି\n  ଏବଂ ଭାରତର ବିଭିନ୍ନ ସ୍ଥାନରୁ ଛାତ୍ରମାନେ ସେଠାରେ ଅଧ୍ୟୟନ କରିବାକୁ ଆସନ୍ତି | ସମସ୍ତେ ସମାନ ଆହ୍ଵାନର ସମ୍ମୁଖୀନ ହେବେ |",
              "as": "মই কামটো শিকিম। মই প্ৰতিদিনে বহুতো ৰোগীক লগ কৰিম। মই ভাষাটো শিকি লম। আৰু গোটেই ভাৰতৰ শিক্ষাৰ্থীসকল তাত পঢ়িবলৈ আহে। সকলোৱে একে ধৰণৰ বাধা সন্মুখীন হ'ব।",
              "gu": "હું નોકરી પર શીખીશ. હું દરરોજ ઘણા દર્દીઓને મળીશ. હું ભાષા શીખી લઈશ. અને ભારતભરમાંથી વિદ્યાર્થીઓ ત્યાં અભ્યાસ કરવા આવે છે. દરેક વ્યક્તિ સમાન પડકારોનો સામનો કરશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "All I know is that you will be an outsider there. You have never lived away from family. I am not comfortable with you going to Karnataka.",
              "hi": "मुझे सिर्फ इतना पता है कि तुम  वहां एक बाहरी व्यक्ति होगी। तुम कभी भी परिवार से दूर नहीं रही हो। मैं तुम्हारे कर्नाटक जाने के लिए सहज नहीं हूं।",
              "ka": "ಅಲ್ಲಿ ನೀನು ಹೊರಗಿನವನಾಗಿರುತ್ತೀರಿ ಎಂಬುದು ನನಗೆ ಗೊತ್ತು. ನೀವು ಎಂದಿಗೂ ಕುಟುಂಬದಿಂದ ದೂರ ಬದುಕಿಲ್ಲ. ನೀನು ಕರ್ನಾಟಕಕ್ಕೆ ಹೋಗುವುದು ನನಗೆ ಸಮಾಧಾನವಿಲ್ಲ",
              "te": "అక్కడ నువ్వు బయటవారిగా అవుతావని నాకు తెలుసు. నువ్వు ఎప్పుడూ కుటుంబానికి దూరంగా ఉండలేదు. నువ్వు కర్నాటక వెళ్ళడం నాకు ఇష్టం లేదు.",
              "or": "ମୁଁ କେବଳ ଏତିକି ଜାଣେ ତୁମେ ସେଠାରେ ଜଣେ ବାହାର ଲୋକ ହେବ | ତୁମେ \n କେବେ ପରିବାରଠାରୁ ଦୂରରେ ରହିନାହଁ | ମୁଁ ତୁମ ସହ କର୍ଣ୍ଣାଟକ ଯିବାରେ ଆରାମଦାୟକ ନୁହେଁ |",
              "as": "মই এয়া জানো যে তুমি তাত বাহিৰা মানুহ হৈ থাকিবা । তুমি কেতিয়াও পৰিয়ালৰ পৰা আঁতৰি থকা নাই। তুমি কৰ্ণাটকলৈ যোৱাতো মই পচন্দ নকৰো।",
              "gu": "હું માત્ર એટલું જાણું છું કે તું ત્યાં બહારના વ્યક્તિ તરીકે હશે. તું ક્યારેય પરિવારથી દૂર રહેલી નથી. તારા કર્ણાટક જવાથી મને યોગ્ય નથી લાગતું."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_5_3",
              "hi": "B4_5_3",
              "ka": "B4_5_3",
              "te": "B4_5_3",
              "or": "B4_5_3",
              "as": "B4_5_3",
              "gu": "B4_5_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is confused. She knows what her mother is saying is true. Not knowing a language in a new city can be very isolating. And to live so far away from her family would be difficult.",
              "hi": "मीरा दुविधा में है। वह जानती है कि उसकी माँ जो कह रही हैं सच है। एक नए शहर में वहां की भाषा नहीं जानना बहुत अलग हो सकता है। और अपने  परिवार से दूर रहना कठिन होगा।",
              "ka": "ಮೀರಾ ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದಾಳೆ. ಅಮ್ಮ ಹೇಳುತ್ತಿರುವುದು ನಿಜ ಎಂದು ಅವಳಿಗೆ ಗೊತ್ತು. ಹೊಸ ನಗರದಲ್ಲಿ ಭಾಷೆ ತಿಳಿಯದಿರುವುದು ತುಂಬಾ ಪ್ರತ್ಯೇಕವಾಗಿರುತ್ತದೆ. ಮತ್ತು ಅವಳ ಕುಟುಂಬದಿಂದ ದೂರದಲ್ಲಿ ಬದುಕುವುದು ಕಷ್ಟ.",
              "te": "మీరా అయోమయంలో పడింది. తన తల్లి చెప్పేది నిజమని ఆమెకు తెలుసు. కొత్త నగరంలో భాష తెలియకపోవడం చాలా ఒంటరిగా అనిపిస్తుంది. మరియు ఆమె కుటుంబానికి దూరంగా జీవించడం కష్టం.",
              "or": "ମୀରା ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛନ୍ତି | ସେ ଜାଣନ୍ତି ଯେ ତାଙ୍କ ମା ଯାହା କହୁଛନ୍ତି ତାହା ସତ ଅଟେ |\n  ଏକ ନୂତନ ସହରରେ ଏକ ଭାଷା ନ ଜାଣିବା ଅତ୍ୟନ୍ତ ଅସୁବିଧାଜନକ ହୋଇପାରେ | ଏବଂ ତାଙ୍କ ପରିବାରଠାରୁ ଏତେ ଦୂରରେ ରହିବା କଷ୍ଟସାଧ୍ୟ ହେବ |",
              "as": "মীৰা খেলিমেলি লাগিছে। তাই জানে তাইৰ মাকে কি কৈছে সেয়া সঁচা। এখন নতুন চহৰত ভাষা নজনাটো বহুত অকলশৰীয়া অনুভৱ কৰাব পাৰে আৰু তাইৰ পৰিয়ালৰ পৰা ইমান দূৰত থকাটো কঠিন হ'ব।",
              "gu": "મીરા મૂંઝવણમાં છે. તે જાણે છે કે તેની માતા જે કહે છે તે સાચું છે. નવા શહેરમાં ભાષા ન આવડવી એ ખૂબ જ અલગ થઈ શકે છે. અને તેના પરિવારથી આટલું દૂર રહેવું મુશ્કેલ હશે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_2_2",
              "hi": "B4_2_2",
              "ka": "B4_2_2",
              "te": "B4_2_2",
              "or": "B4_2_2",
              "as": "B4_2_2",
              "gu": "B4_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "But she is confident that the education she will get in Karnataka would be good. The college in Assam is new, and she isn't sure what the experience would be like.",
              "hi": "लेकिन उसे विश्वास है कि उसे कर्नाटक में जो शिक्षा मिलेगी वह अच्छी ही होगी। असम में कॉलेज नया है, और वह निश्चित नहीं है कि अनुभव कैसा होगा।",
              "ka": "ಆದರೆ ಆಕೆಗೆ ಕರ್ನಾಟಕದಲ್ಲಿ ಸಿಗುವ ಶಿಕ್ಷಣ ಚೆನ್ನಾಗಿರುತ್ತದೆ ಎಂಬ ವಿಶ್ವಾಸವಿದೆ. ಅಸ್ಸಾಂನಲ್ಲಿರುವ ಕಾಲೇಜು ಹೊಸದು, ಮತ್ತು ಅನುಭವ ಹೇಗಿರುತ್ತದೆ ಎಂದು ಆಕೆಗೆ ಖಚಿತವಾಗಿಲ್ಲ.",
              "te": "అయితే కర్ణాటకలో తాన చదువు బాగుంటుందన్న నమ్మకంతో ఉంది. అస్సాంలోని కళాశాల కొత్తది మరియు అనుభవం ఎలా ఉంటుందో ఆమెకు ఖచ్చితంగా తెలియదు.",
              "or": "କିନ୍ତୁ ସେ ନିଶ୍ଚିତ ଯେ କର୍ଣ୍ଣାଟକରେ ସେ ଯେଉଁ ଶିକ୍ଷା ପାଇବେ ତାହା ଭଲ ହେବ। ଆସାମର\n  କଲେଜ ନୂଆ, ଏବଂ ଅଭିଜ୍ଞତା କ’ଣ ହେବ ସେ ନିଶ୍ଚିତ ନୁହଁନ୍ତି |",
              "as": "কিন্তু তাই জানে যে তাই কৰ্ণাটকত লাভ কৰা শিক্ষা ভাল হ'ব। অসমৰ মহাবিদ্যালয়খন নতুন, আৰু তাই বুজি পোৱা নাই যে অভিজ্ঞতাটো কেনেকুৱা হ'ব।",
              "gu": "પરંતુ તેને વિશ્વાસ છે કે તેને કર્ણાટકમાં જે શિક્ષણ મળશે તે સારું રહેશે. આસામની કોલેજ નવી છે, અને તેને ખાતરી નથી કે અનુભવ કેવો હશે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_2_3_D",
              "hi": "B4_2_3_D",
              "ka": "B4_2_3_D",
              "te": "B4_2_3_D",
              "or": "B4_2_3_D",
              "as": "B4_2_3_D",
              "gu": "B4_2_3_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Mira do?",
              "hi": "मीरा को क्या करना चाहिए?",
              "ka": "ಮೀರಾ ಏನು ಮಾಡಬೇಕು?",
              "te": "మీరా ఏమి చేయాలి?",
              "or": "ମୀରା କ’ଣ କରିବା ଉଚିତ୍?",
              "as": "মীৰাই কি কৰিব লাগে?",
              "gu": "મીરાએ શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Take admission in college in Assam",
              "hi": "असम के कॉलेज में प्रवेश ले",
              "ka": "ಅಸ್ಸಾಮಿನಲ್ಲಿಯೇ ಕಾಲೇಜಿಗೆ ಪ್ರವೇಶವನ್ನು ಪಡೆಯುವುದೇ",
              "te": "అస్సాంలోని కాలేజీలో అడ్మిషన్ తీసుకోండి",
              "or": "ଆସାମରେ କଲେଜରେ ଆଡମିଶନ ନିଅ |",
              "as": "অসমৰ কলেজত নামভৰ্তি কৰক",
              "gu": "આસામની કોલેજમાં એડમિશન લેવું"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Take admission in college in Karnataka",
              "hi": "कर्नाटक के कॉलेज में प्रवेश ले",
              "ka": "ಕರ್ನಾಟಕದಲ್ಲಿ ಕಾಲೇಜಿಗೆ ಪ್ರವೇಶವನ್ನು ಪಡೆಯುವುದೇ",
              "te": "కర్ణాటకలోని కాలేజీలో అడ్మిషన్ తీసుకోండి",
              "or": "କର୍ଣ୍ଣାଟକର କଲେଜରେ ଆଡମିଶନ ନିଅ |",
              "as": "কৰ্ণাটকৰ মহাবিদ্যালয়ত নামভৰ্তি কৰক",
              "gu": "કર્ણાટકની કોલેજમાં એડમિશન લેવું"
            }
          }
        ]
      },
      "scene35": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Join the college in Assam",
              "hi": "असम के कॉलेज में दाखिला ले ",
              "ka": "ಅಸ್ಸಾಮಿನಲ್ಲಿ ಕಾಲೇಜಿಗೆ ಸೇರಿಕೋ",
              "te": "అస్సాంలోని కాలేజీలో చేరండి",
              "or": "ଆସାମର କଲେଜରେ ଯୋଗ ଦିଅନ୍ତୁ |",
              "as": "অসমৰ মহাবিদ্যালয়ত যোগদান কৰক",
              "gu": "આસામની કોલેજમાં જોડાઓ"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The college experience is very different from what Mira expected. The new college has good infrastructure and facilities.",
              "hi": "कॉलेज का अनुभव, जैसा मीरा ने सोचा था उससे बहुत अलग है। नए कॉलेज में अच्छा बुनियादी ढांचा और सुविधाएं हैं।",
              "ka": "ಕಾಲೇಜಿನ ಅನುಭವ ಮೀರಾ ನಿರೀಕ್ಷಿಸಿದ್ದಕ್ಕಿಂತ ತುಂಬಾ ಭಿನ್ನವಾಗಿದೆ. ಹೊಸ ಕಾಲೇಜು ಉತ್ತಮ ಮೂಲಸೌಕರ್ಯ ಮತ್ತು ಸೌಲಭ್ಯಗಳನ್ನು ಹೊಂದಿದೆ.",
              "te": "కాలేజీ గురించి మీరా అనుకున్నదానికి చాలా భిన్నంగా ఉంది. కొత్త కళాశాలలో మంచి మౌలిక సదుపాయాలు మరియు సౌకర్యాలు ఉన్నాయి.",
              "or": "ମୀରା ଯାହା ଆଶା କରିଥିଲେ ତାହାଠାରୁ କଲେଜ ଅଭିଜ୍ଞତା ବହୁତ ଭିନ୍ନ | ନୂତନ \n କଲେଜରେ ଭଲ ଭିତ୍ତିଭୂମି ଏବଂ ସୁବିଧା ରହିଛି |",
              "as": "মহাবিদ্যালয়ৰ অভিজ্ঞতা মীৰাই আশা কৰাতকৈ বহুত বেলেগ। নতুন মহাবিদ্যালয়খনত ভাল সুবিধা আছে।",
              "gu": "કોલેજનો અનુભવ મીરાની અપેક્ષા કરતા ઘણો અલગ છે. નવી કોલેજમાં સારી ઈન્ફ્રાસ્ટ્રક્ચર અને સુવિધાઓ છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_1_2",
              "hi": "B5i_1_2",
              "ka": "B5i_1_2",
              "te": "B5i_1_2",
              "or": "B5i_1_2",
              "as": "B5i_1_2",
              "gu": "B5i_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "There are students from different parts of Assam. Some of the students are toppers, and others from the city have more exposure.",
              "hi": "असम के विभिन्न जिलों के छात्र हैं। कुछ छात्र टॉपर्स हैं, और शहर के अन्य लोगों के पास अधिक जानकारी है।",
              "ka": "ಅಸ್ಸಾಂನ ವಿವಿಧ ಭಾಗಗಳ ವಿದ್ಯಾರ್ಥಿಗಳಿದ್ದಾರೆ. ಕೆಲವು ವಿದ್ಯಾರ್ಥಿಗಳು ಟಾಪರ್‌ಗಳಾಗಿದ್ದು, ನಗರದ ಇತರರು ಹೆಚ್ಚು ಮಾನ್ಯತೆ ಹೊಂದಿದ್ದಾರೆ.",
              "te": "అస్సాంలోని వివిధ ప్రాంతాల నుండి విద్యార్థులు ఉన్నారు. కొంతమంది విద్యార్థులు టాపర్‌లు కాగా, మరికొందరు నగరానికి చెందిన వారు ఎక్కువ ఎక్స్ పోజర్‌ను కలిగి ఉన్నారు.",
              "or": "ଆସାମର ବିଭିନ୍ନ ସ୍ଥାନରୁ ଛାତ୍ର ଅଛନ୍ତି | କେତେକ ଛାତ୍ର ଟପ୍ପର, ଏବଂ ସହରର \n ଅନ୍ୟମାନେ ଅଧିକ ଏକ୍ସପୋଜର୍ କରନ୍ତି |",
              "as": "অসমৰ বিভিন্ন প্ৰান্তৰ ছাত্ৰ-ছাত্ৰী আছে। কিছুমান শিক্ষাৰ্থী শীৰ্ষস্থান পোৱা, আৰু চহৰখনৰ আন কিছুমানৰ অধিক এক্সপোজাৰ আছে।",
              "gu": "આસામના વિવિધ ભાગોમાંથી વિદ્યાર્થીઓ છે. કેટલાક વિદ્યાર્થીઓ વધારે ગુણ વાળા છે, અને અન્ય શહેરમાંથી વધુ વિસ્તારથી જાણવા જેવુ છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_1_3",
              "hi": "B5i_1_3",
              "ka": "B5i_1_3",
              "te": "B5i_1_3",
              "or": "B5i_1_3",
              "as": "B5i_1_3",
              "gu": "B5i_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira makes many new friends during the lectures in her first semester. She enjoys the practical classes, where she learns many new things about the human body.",
              "hi": "मीरा अपने पहले सेमेस्टर में लेक्चर्स के दौरान कई नए मित्र बनाती है। वह प्रयोगात्मक कक्षाओं का आनंद लेती है, जहां वह मानव शरीर के बारे में कई नई चीजें सीखती है।",
              "ka": "ಮೀರಾ ತನ್ನ ಮೊದಲ ಸೆಮಿಸ್ಟರ್‌ನಲ್ಲಿ ಉಪನ್ಯಾಸಗಳ ಸಮಯದಲ್ಲಿ ಅನೇಕ ಹೊಸ ಸ್ನೇಹಿತರನ್ನು ಮಾಡಿಕೊಳ್ಳುತ್ತಾಳೆ. ಅವಳು ಪ್ರಾಯೋಗಿಕ ತರಗತಿಗಳನ್ನು ಆನಂದಿಸುತ್ತಾಳೆ, ಅಲ್ಲಿ ಅವಳು ಮಾನವ ದೇಹದ ಬಗ್ಗೆ ಅನೇಕ ಹೊಸ ವಿಷಯಗಳನ್ನು ಕಲಿಯುತ್ತಾಳೆ.",
              "te": "మీరా తన మొదటి సెమిస్టర్‌లో క్లాస్ సమయంలో చాలా మంది కొత్త స్నేహితులను సంపాదించుకుంది. ఆమె ప్రాక్టికల్ క్లాస్ లను ఆనందిస్తుంది, అక్కడ ఆమె మానవ శరీరం గురించి చాలా కొత్త విషయాలను నేర్చుకుంటుంది.",
              "or": "ମୀରା ତାଙ୍କର ପ୍ରଥମ ସେମିଷ୍ଟାରରେ ବକ୍ତୃତା ସମୟରେ ଅନେକ ନୂତନ ବନ୍ଧୁ ସୃଷ୍ଟି କରନ୍ତି |\n  ସେ ବ୍ୟବହାରିକ ଶ୍ରେଣୀଗୁଡିକ ଉପଭୋଗ କରନ୍ତି, ଯେଉଁଠାରେ ସେ ମାନବ ଶରୀର ବିଷୟରେ ଅନେକ ନୂତନ ଜିନିଷ ଶିଖନ୍ତି |",
              "as": "মীৰাই তাইৰ প্ৰথম ছেমিষ্টাৰত সময়ত বহুতো নতুন বন্ধু বনালে। তাই প্ৰেক্টিকেল বোৰ কৰি বৰ ভাল পায়, য'ত তাই মানৱ শৰীৰৰ বিষয়ে বহুতো নতুন কথা শিকে।",
              "gu": "મીરા તેના પહેલા સેમેસ્ટરમાં લેક્ચર દરમિયાન ઘણા નવા મિત્રો બનાવે છે. તે વ્યવહારિક વર્ગોનો આનંદ માણે છે, જ્યાં તે માનવ શરીર વિશે ઘણી નવી વસ્તુઓ શીખે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_2_1",
              "hi": "B5i_2_1",
              "ka": "B5i_2_1",
              "te": "B5i_2_1",
              "or": "B5i_2_1",
              "as": "B5i_2_1",
              "gu": "B5i_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is overwhelmed by the amount she has to study. Becoming a doctor isn’t easy, as she has to remember many different symptoms, treatments, and ailments.",
              "hi": "मीरा अध्ययन की मात्रा से व्याकुल है जितना उसे करना है। डॉक्टर बनना सरल नहीं है, क्योंकि उसे कई अलग-अलग लक्षण, उपचार और बीमारियों को याद रखना है।",
              "ka": "ಮೀರಾ ತಾನು ಓದಬೇಕಾದ ಹೆಚ್ಚಿನ ವಿಷಯಗಳಿಂದ ಸ್ವಲ್ಪ ಎದೆಗುಂದಿದ್ದಾಳೆ. ವೈದ್ಯರಾಗುವುದು ಸುಲಭವಲ್ಲ, ಏಕೆಂದರೆ ಅವರು ವಿವಿಧ ರೋಗಲಕ್ಷಣಗಳು, ಚಿಕಿತ್ಸೆಗಳು ಮತ್ತು ಕಾಯಿಲೆಗಳನ್ನು ನೆನಪಿಟ್ಟುಕೊಳ್ಳಬೇಕು.",
              "te": "మీరా చదవాల్సిన మొత్తం చూసి ఆశ్చర్యపోతుంది. డాక్టర్‌గా అవ్వడం అంత సులభం కాదు, ఎందుకంటే ఆమె అనేక రకాల లక్షణాలు, చికిత్సలు మరియు జబ్బుల పేర్లు గుర్తుంచుకోవాలి.",
              "or": "ତାଙ୍କୁ ପଢିବାକୁ ଥିବା ପରିମାଣରେ ମୀରା ଅତିଷ୍ଠ ହୋଇପଡିଛନ୍ତି | ଡାକ୍ତର \n ହେବା ସହଜ ନୁହେଁ, କାରଣ ତାଙ୍କୁ ଅନେକ ଭିନ୍ନ ଲକ୍ଷଣ, ଚିକିତ୍ସା ଏବଂ ଅସୁସ୍ଥତା ମନେ ରଖିବାକୁ ପଡିବ |",
              "as": "মীৰাই পঢ়িব লগা বোৰ দেখি ভয় কৰিছে। চিকিৎসক হোৱাটো সহজ নহয়, কিয়নো তাই বিভিন্ন লক্ষণ, চিকিৎসা আৰু ৰোগ মনত ৰাখিব লাগিব।",
              "gu": "મીરાને ભણવાની રકમ જોઈને અભિભૂત લાગે છે. ડૉક્ટર બનવું સહેલું નથી, કારણ કે તેને ઘણાં વિવિધ લક્ષણો, સારવારો અને બિમારીઓ યાદ રાખવાની હોય છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_2_2",
              "hi": "B5i_2_2",
              "ka": "B5i_2_2",
              "te": "B5i_2_2",
              "or": "B5i_2_2",
              "as": "B5i_2_2",
              "gu": "B5i_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is excited and happy, but a few weeks into her semester, some students she spoke to initially aren’t talking to her anymore.",
              "hi": "मीरा उत्साहित और खुश है, लेकिन अपने सेमेस्टर में कुछ सप्ताह के बाद देखती है कि, कुछ छात्र जो शुरू में बात करते थे, अब उससे बात नहीं कर रहे हैं।",
              "ka": "ಮೀರಾ ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ ಮತ್ತು ಸಂತೋಷವಾಗಿದ್ದಾಳೆ, ಆದರೆ ಅವಳ ಸೆಮಿಸ್ಟರ್‌ನಲ್ಲಿ ಕೆಲವು ವಾರಗಳು ಕಳೆದ ನಂತರ, ಅವಳು ಆರಂಭದಲ್ಲಿ ಮಾತನಾಡಿದ ಕೆಲವು ವಿದ್ಯಾರ್ಥಿಗಳು ಅವಳೊಂದಿಗೆ ಇನ್ನು ಮುಂದೆ ಮಾತನಾಡುತ್ತಿಲ್ಲ ಎನ್ನುವುದನ್ನು ಗಮನಿಸುತ್ತಾಳೆ.",
              "te": "మీరా ఉత్సాహంగా మరియు సంతోషంగా ఉంది, కానీ ఆమె సెమిస్టర్‌లోకి ప్రవేశించిన కొన్ని వారాలల్లో, మొదట్లో ఆమెతో మాట్లాడిన కొంతమంది విద్యార్థులు ఇప్పుడు మాట్లాడటం లేదు.",
              "or": "ମୀରା ଉତ୍ସାହିତ ଏବଂ ଖୁସି, କିନ୍ତୁ କିଛି ସପ୍ତାହ ମଧ୍ୟରେ ସେମିଷ୍ଟାରରେ, ସେ କିଛି ଛାତ୍ର ତାଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କଲେ ନାହିଁ |",
              "as": "মীৰা সুখী, কিন্তু তাইৰ ছেমিষ্টাৰৰ কেইসপ্তাহমানৰ পিছৰ পৰা, তাই প্ৰথমতে কথা পতা কিছুমান লগৰে তাইৰ সৈতে আৰু কথা পতা নাই।",
              "gu": "મીરા ઉત્સાહિત અને ખુશ છે, પરંતુ તેના સેમેસ્ટરના થોડા અઠવાડિયા પછી, તે શરૂઆતમાં જે વિદ્યાર્થીઓ સાથે વાત કરી હતી તે હવે તેની સાથે વાત કરતા નથી."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_2_3",
              "hi": "B5i_2_3",
              "ka": "B5i_2_3",
              "te": "B5i_2_3",
              "or": "B5i_2_3",
              "as": "B5i_2_3",
              "gu": "B5i_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She also senses that some students are rude and inconsiderate towards her.",
              "hi": "उसे यह भी लगता है कि कुछ छात्र असभ्य हैं और उसका ध्यान नहीं रखते हैं।",
              "ka": "ಕೆಲವು ವಿದ್ಯಾರ್ಥಿಗಳು ತನ್ನ ಕಡೆಗೆ ಅಸಹನೆ ಮತ್ತು ಅಸಡ್ಡೆ ತೋರುತ್ತಿದ್ದಾರೆಂದು ಅವಳು ಗ್ರಹಿಸುತ್ತಾಳೆ.",
              "te": "కొంతమంది విద్యార్థులు తన పట్ల అసభ్యంగా మరియు అనాలోచితంగా ప్రవర్తిస్తున్నారని కూడా ఆమె గ్రహించింది.",
              "or": "ସେ ଏହା ମଧ୍ୟ ଅନୁଭବ କରନ୍ତି ଯେ କିଛି ଛାତ୍ର ତାଙ୍କ ପ୍ରତି ଅଶାଳୀନ ଏବଂ ଅବହେଳିତ |",
              "as": "তাই এইটোও অনুভৱ কৰে যে কিছুমান শিক্ষাৰ্থী তাইৰ প্ৰতি অভদ্ৰ আৰু বেয়া।",
              "gu": "તે એ પણ અનુભવે છે કે કેટલાક વિદ્યાર્થીઓ તેના પ્રત્યે અસંસ્કારી અને અવિચારી છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_3_1",
              "hi": "B5i_3_1",
              "ka": "B5i_3_1",
              "te": "B5i_3_1",
              "or": "B5i_3_1",
              "as": "B5i_3_1",
              "gu": "B5i_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is quite upset. She doesn’t understand what she did wrong. Some seniors approach her during lunch. They introduce themselves as members of the Students Democratic Study Circle.",
              "hi": "मीरा काफी परेशान है। उसे समझ में नहीं आता कि उसने क्या गलत किया। कुछ सीनियर दोपहर के भोजन के समय उससे संपर्क करते हैं। वे खुद का छात्र डेमोक्रेटिक स्टडी सर्कल के सदस्यों के रूप में परिचय देते  हैं।",
              "ka": "ಮೀರಾ ತುಂಬಾ ಬೇಸರಗೊಂಡಿದ್ದಾಳೆ. ಅವಳು ಏನು ತಪ್ಪು ಮಾಡಿದ್ದಾಳೆಂದು ಅವಳಿಗೆ ಅರ್ಥವಾಗುತ್ತಿಲ್ಲ. ಊಟದ ಸಮಯದಲ್ಲಿ ಕೆಲವು ಸೀನಿಯರ್ಸ್ ಅವಳನ್ನು ಸಂಪರ್ಕಿಸುತ್ತಾರೆ. ಅವರು ಸ್ಟೂಡೆಂಟ್ಸ್ ಡೆಮಾಕ್ರಟಿಕ್ ಸ್ಟಡಿ ಸರ್ಕಲ್‌ನ ಸದಸ್ಯರಾಗಿ ತಮ್ಮನ್ನು ಪರಿಚಯಿಸಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "మీరా చాలా బాధ పడింది. ఆమె చేసిన తప్పేమిటో అర్థం కావడం లేదు. మధ్యాహ్న భోజన సమయంలో కొందరు సీనియర్లు ఆమె వద్దకు వెళతారు. స్టూడెంట్స్ డెమోక్రటిక్ స్టడీ సర్కిల్ సభ్యులుగా తమను తాము పరిచయం చేసుకుంటారు.",
              "or": "ମୀରା ବହୁତ ବିରକ୍ତ | ସେ କଣ ଭୁଲ୍ କରିଛନ୍ତି ସେ ବୁଝିପାରନ୍ତି ନାହିଁ | କିଛି ବୟସ୍କମାନେ\n  ମଧ୍ୟାହ୍ନ ଭୋଜନ ସମୟରେ ତାଙ୍କ ନିକଟକୁ ଆସନ୍ତି | ସେମାନେ ନିଜକୁ ଛାତ୍ର ଡେମୋକ୍ରାଟିକ୍ ଷ୍ଟଡି ସର୍କଲର ସଦସ୍ୟ ଭାବରେ ପରିଚିତ କରନ୍ତି |",
              "as": "মীৰা যথেষ্ট বিচলিত। তাই বুজি পোৱা নাই কি ভুল কৰিলে। কিছুমান চিনিয়ৰে দুপৰীয়াৰ আহাৰৰ সময়ত তাইৰ ওচৰলৈ যায়। তেওঁলোকে নিজকে ষ্টুডেণ্টছ ডেমক্ৰেটিক ষ্টাডি চাৰ্কলৰ সদস্য বুলি পৰিচয় দিয়ে।",
              "gu": "મીરા એકદમ પરેશાન છે. તેને એવું તો શું ખોટું કર્યું તે તે સમજી શકતી નથી. કેટલાક વરિષ્ઠ લોકો બપોરના જમવાના સમય દરમિયાન તેની પાસે જાય છે. તે પોતાનો પરિચય સ્ટુડન્ટ્સ ડેમોક્રેટિક સ્ટડી સર્કલના સભ્યો તરીકે આપે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_3_2 bg",
              "hi": "B5i_3_2 बीजी",
              "ka": "B5i_3_2 bg",
              "te": "B5i_3_2 bg",
              "or": "B5i_3_2 bg",
              "as": "B5i_3_2 bg",
              "gu": "B5i_3_2 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Student",
              "hi": "विद्यार्थी",
              "ka": "ವಿದ್ಯಾರ್ಥಿ",
              "te": "విద్యార్థి",
              "or": "ଛତ୍ର",
              "as": "শিক্ষাৰ্থী",
              "gu": "વિદ્યાર્થી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Nice to meet you, Mira! We usually approach students in the first year who come from SC, ST and OBC backgrounds to join our weekly discussion groups.",
              "hi": "आपसे मिलकर अच्छा लगा, मीरा! हम सामान्यतः प्रथम वर्ष के विद्यार्थियों से संपर्क करते हैं जो एससी, एसटी और ओबीसी पृष्ठभूमि से हमारे साप्ताहिक चर्चा समूहों में शामिल होने के लिए आते हैं।",
              "ka": "ನಿನ್ನನ್ನು ಭೇಟಿಯಾಗಲು ಸಂತೋಷವಾಗಿದೆ, ಮೀರಾ! ನಾವು ಸಾಮಾನ್ಯವಾಗಿ ನಮ್ಮ ಸಾಪ್ತಾಹಿಕ ಚರ್ಚಾ ಗುಂಪುಗಳಿಗೆ ಸೇರಲು SC, ST ಮತ್ತು OBC ಹಿನ್ನೆಲೆಯಿಂದ ಬರುವ ಮೊದಲ ವರ್ಷದ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಸಂಪರ್ಕಿಸುತ್ತೇವೆ",
              "te": "నిన్ను కలవడం ఆనందంగా ఉంది, మీరా! మేము సాధారణంగా మా వారపు చర్చా గ్రూప్స్ లో చేరడానికి SC, ST మరియు OBC ద్వారా వచ్చిన మొదటి సంవత్సరంలోని విద్యార్థులను కలుసుకుంటాము.",
              "or": "ମିରା, ତୁମକୁ ଭେଟିବାକୁ ଭଲ ଲାଗିଲା! ଆମେ ସାଧାରଣତଃ ପ୍ରଥମ ବର୍ଷରେ \n ଛାତ୍ରମାନଙ୍କ ନିକଟକୁ ଯାଇଥାଉ, ଯେଉଁମାନେ ଆମର ସାପ୍ତାହିକ ଆଲୋଚନା ଗୋଷ୍ଠୀରେ ଯୋଗଦେବା ପାଇଁ SC, ST ଏବଂ OBC ପୃଷ୍ଠଭୂମିରୁ ଆସନ୍ତି |",
              "as": "আপোনাক লগ পাই ভাল লাগিছে, মীৰা! আমি সাধাৰণতে প্ৰথম বছৰত SC,ST আৰু OBC পৰা অহা শিক্ষাৰ্থীসকলৰ ওচৰলৈ আমাৰ সাপ্তাহিক আলোচনা গোটত যোগদান কৰিবলৈ কওঁ।",
              "gu": "તને મળીને આનંદ થયો, મીરા! અમે સામાન્ય રીતે અમારા સાપ્તાહિક ચર્ચા જૂથોમાં જોડાવા માટે SC, ST અને OBC વર્ગથી આવતા પ્રથમ વર્ષમાં વિદ્યાર્થીઓનો સંપર્ક કરીએ છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I don’t understand.",
              "hi": "मैं समझ नहीं पा रही हूँ।",
              "ka": "ನನಗೆ ತಿಳಿಯದು",
              "te": "నాకు అర్థం కాలేదు.",
              "or": "ମୁଁ ବୁଝିପାରୁ ନାହିଁ",
              "as": "মই বুজি পোৱা নাই।",
              "gu": "મને સમજાતું નથી."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_3_3///",
              "hi": "B5i_3_3///",
              "ka": "B5i_3_3///",
              "te": "B5i_3_3///",
              "or": "B5i_3_3///",
              "as": "B5i_3_3///",
              "gu": "B5i_3_3///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "There is some resistance against students who have rightfully claimed reservations. We are here to support you through that initial phase.",
              "hi": "उन छात्रों के खिलाफ कुछ प्रतिरोध है जो आरक्षण पाने के अधिकारी हैं। हम यहां उस प्रारंभिक चरण में आपका समर्थन करने के लिए हैं।",
              "ka": "ನ್ಯಾಯಯುತವಾಗಿ ಮೀಸಲಾತಿಯನ್ನು ಪಡೆದ ವಿದ್ಯಾರ್ಥಿಗಳ ವಿರುದ್ಧ ಕೆಲವು ಪ್ರತಿರೋಧವಿದೆ. ಆ ಆರಂಭಿಕ ಹಂತದ ಮೂಲಕ ನಿಮ್ಮನ್ನು ಬೆಂಬಲಿಸಲು ನಾವು ಇಲ್ಲಿದ್ದೇವೆ.",
              "te": "ఇక్కడ రిజర్వేషన్లను న్యాయబద్ధంగా పొందిన విద్యార్థులపై కొంత వ్యతిరేకత ఉంది. మొదటి దశలోనే నీకు మద్దతు ఇవ్వడానికి మేము ఇక్కడ ఉన్నాము.",
              "or": "ଛାତ୍ରମାନଙ୍କ ବିରୁଦ୍ଧରେ କିଛି ପ୍ରତିରୋଧ ଅଛି ଯେଉଁମାନେ ସଠିକ୍ ଭାବରେ ସଂରକ୍ଷଣ\n  ଦାବି କରିଛନ୍ତି | ସେହି ପ୍ରାରମ୍ଭିକ ପର୍ଯ୍ୟାୟରେ ଆପଣଙ୍କୁ ସମର୍ଥନ କରିବାକୁ ଆମେ ଏଠାରେ ଅଛୁ |",
              "as": "যিসকল শিক্ষাৰ্থীয়ে সঠিকভাৱে সংৰক্ষণদাবী কৰিছে তেওঁলোকৰ বিৰুদ্ধে কিছুমানৰ বেয়া ভাৱ আছে। আমি সেই প্ৰথম অৱস্থাত তোমাক সমৰ্থন কৰিবলৈ ইয়াত আছোঁ।",
              "gu": "અનામતનો હકથી દાવો કરનારા વિદ્યાર્થીઓ સામે થોડો પ્રતિકાર છે. અમે તે પ્રારંભિક તબક્કામાં તને સમર્થન આપવા માટે અહીં છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "That explains why the students were behaving differently towards me.",
              "hi": "अब स्पष्ट हुआ कि छात्र मेरे साथ अलग व्यवहार क्यों कर रहे थे।",
              "ka": "ವಿದ್ಯಾರ್ಥಿಗಳು ನನ್ನೊಂದಿಗೆ ಏಕೆ ವಿಭಿನ್ನವಾಗಿ ವರ್ತಿಸುತ್ತಿದ್ದರು ಎನ್ನುವುದನ್ನು ಅದು ವಿವರಿಸುತ್ತದೆ",
              "te": "విద్యార్థులు తన పట్ల ఎందుకు వ్యతిరేకతతో ప్రవర్తిస్తున్నారో వివరిస్తుంది.",
              "or": "ଛାତ୍ରମାନେ କାହିଁକି ମୋ ପ୍ରତି ଭିନ୍ନ ଆଚରଣ କରୁଥିଲେ ତାହା ବ୍ୟାଖ୍ୟା କରେ |",
              "as": "এইটোৱে বৰ্ণনা কৰে যে শিক্ষাৰ্থীসকলে কিয় মোৰ প্ৰতি বেলেগ আচৰণ কৰিছে।",
              "gu": "તે સમજાવે છે કે શા માટે વિદ્યાર્થીઓ મારી સાથે અલગ વર્તન કરી રહ્યા હતા."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "You should bring this to the discussion group! It allows all students to have a safe space to discuss these issues to make the campus safer and more inclusive.",
              "hi": "आपको इसे चर्चा समूह में लाना चाहिए! यह सभी छात्रों को कैंपस को सुरक्षित और अधिक समावेशी बनाने जैसे मुद्दों पर चर्चा करने के लिए एक सुरक्षित स्थान देता है।",
              "ka": "ನೀವು ಇದನ್ನು ಚರ್ಚಾ ಗುಂಪಿಗೆ ತರಬೇಕು! ಕ್ಯಾಂಪಸ್ ಅನ್ನು ಸುರಕ್ಷಿತ ಮತ್ತು ಹೆಚ್ಚು ಒಳಗೊಳ್ಳುವಂತೆ ಮಾಡಲು ಈ ಸಮಸ್ಯೆಗಳನ್ನು ಚರ್ಚಿಸಲು ಎಲ್ಲಾ ವಿದ್ಯಾರ್ಥಿಗಳು ಒಂದು ಸುರಕ್ಷಿತ ಸ್ಥಳವನ್ನು ಹೊಂದಲು ಇದು ಅನುಮತಿಸುತ್ತದೆ",
              "te": "నువ్వు దీన్ని చర్చా సమూహానికి తీసుకురావాలి! ఇది క్యాంపస్‌ సురక్షితంగా కలిసి ఉండేలా చేయడానికి మరియు ఇలాంటి సమస్యలను చర్చించడానికి విద్యార్థులందరికీ సురక్షితమైన స్థలాన్ని కలిగి ఉంటుంది.",
              "or": "ଆପଣ ଏହାକୁ ଆଲୋଚନା ଗୋଷ୍ଠୀକୁ ଆଣିବା ଉଚିତ୍! କ୍ୟାମ୍ପସକୁ ଅଧିକ ନିରାପଦ \n ଏବଂ ଅଧିକ ଅନ୍ତର୍ଭୂକ୍ତ କରିବା ପାଇଁ ଏହା ସମସ୍ତ ଛାତ୍ରମାନଙ୍କୁ ଏକ ନିରାପଦ ସ୍ଥାନ ପାଇବାକୁ ଅନୁମତି ଦେଇଥାଏ |",
              "as": "তুমি এই বিষয়টো আলোচনা গোটলৈ অনা উচিত! ই সকলো শিক্ষাৰ্থীক কলেজত চৌহদটো সুৰক্ষিত অনুভৱ কৰাই দিয়ে।",
              "gu": "તમારે આને ચર્ચા જૂથમાં લાવવું જોઈએ! તે તમામ વિદ્યાર્થીઓને કેમ્પસને વધુ સુરક્ષિત અને વધુ સમાવિષ્ટ બનાવવા માટે આ મુદ્દાઓ પર ચર્ચા કરવા માટે સુરક્ષિત જગ્યાની મંજૂરી આપે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_3_4",
              "hi": "B5i_3_4",
              "ka": "B5i_3_4",
              "te": "B5i_3_4",
              "or": "B5i_3_4",
              "as": "B5i_3_4",
              "gu": "B5i_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira attends the weekly discussions of the Students Democratic Study Circle. She realises that many other students like her have faced similar situations of discrimination, sometimes even worse.",
              "hi": "मीरा छात्र डेमोक्रेटिक स्टडी सर्कल की साप्ताहिक चर्चाओं में भाग लेती है। उसे लगता है कि उसके जैसे कई अन्य छात्रों ने भेदभाव की समान स्थितियों का सामना किया है, कभी उससे भी बुरा ।",
              "ka": "ಸ್ಟೂಡೆಂಟ್ಸ್ ಡೆಮಾಕ್ರಟಿಕ್ ಸ್ಟಡಿ ಸರ್ಕಲ್‌ನ ಸಾಪ್ತಾಹಿಕ ಚರ್ಚೆಗಳಿಗೆ ಮೀರಾ ಹಾಜರಾಗುತ್ತಾಳೆ. ತನ್ನಂತೆಯೇ ಇತರ ಅನೇಕ ವಿದ್ಯಾರ್ಥಿಗಳು ತಾರತಮ್ಯದ ಇದೇ ರೀತಿಯ ಸಂದರ್ಭಗಳನ್ನು ಎದುರಿಸಿದ್ದಾರೆ ಎನ್ನುವುದನ್ನು ಅವಳು ಅರಿತುಕೊಂಡಳು, ಕೆಲವೊಂದು ಇನ್ನೂ ಕೆಟ್ಟ ಸಂದರ್ಭಗಳನ್ನು ಎದುರಿಸಿದ್ದಾರೆ.",
              "te": "మీరా స్టూడెంట్స్ డెమోక్రటిక్ స్టడీ సర్కిల్ లోని వారం చర్చలకు వెళుతుంది. తనలాంటి అనేకమంది ఇతర విద్యార్ధులు వివక్ష యొక్క ఇలాంటి పరిస్థితులను ఎదుర్కొన్నారని, కొన్నిసార్లు మరింత దారుణంగా ఉంటారని ఆమె తెలుసుకుంది.",
              "or": "ମୀରା ଷ୍ଟୁଡେଣ୍ଟସ୍ ଡେମୋକ୍ରାଟିକ୍ ଷ୍ଟଡି ସର୍କଲର ସାପ୍ତାହିକ ଆଲୋଚନାରେ ଯୋଗ \n ଦିଅନ୍ତି | ସେ ହୃଦୟଙ୍ଗମ କରିଛନ୍ତି ଯେ ତାଙ୍କ ପରି ଅନ୍ୟ ଅନେକ ଛାତ୍ର ମଧ୍ୟ ଭେଦଭାବର ସମାନ ପରିସ୍ଥିତିର ସମ୍ମୁଖୀନ ହୋଇଛନ୍ତି, ବେଳେବେଳେ ଆହୁରି ଖରାପ |",
              "as": "মীৰাই ষ্টুডেণ্টছ ডেমক্ৰেটিক ষ্টাডি চাৰ্কলৰ সাপ্তাহিক আলোচনাত অংশ গ্ৰহণ কৰে। তাই বুজি পাই যে তাইৰ দৰে আন বহুতো শিক্ষাৰ্থীয়ে একে ধৰণৰ ভেদ ভাৱ পৰিস্থিতিৰ সন্মুখীন হৈছে, কেতিয়াবা আৰু বেয়া।",
              "gu": "મીરા સ્ટુડન્ટ્સ ડેમોક્રેટિક સ્ટડી સર્કલની સાપ્તાહિક ચર્ચાઓમાં ભાગ લે છે. તેને અહેસાસ થાય છે કે તેના જેવા અન્ય ઘણા વિદ્યાર્થીઓએ ભેદભાવની સમાન પરિસ્થિતિઓનો સામનો કરવો પડ્યો છે, ક્યારેક તો તેનાથી પણ ખરાબ."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_1_4",
              "hi": "B5i_1_4",
              "ka": "B5i_1_4",
              "te": "B5i_1_4",
              "or": "B5i_1_4",
              "as": "B5i_1_4",
              "gu": "B5i_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Over the following few semesters, she gains the confidence to stand up for herself.",
              "hi": "आने वाले कुछ सेमेस्टर में, वह अपना पक्ष रख पाने के आत्मविश्वास को प्राप्त करती है।",
              "ka": "ಮುಂದಿನ ಕೆಲವು ಸೆಮಿಸ್ಟರ್‌ಗಳಲ್ಲಿ, ಅವಳು ತನಗಾಗಿಯೇ ನಿಲ್ಲುವ ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
              "te": "కొన్ని సెమిస్టర్‌ల తరువాత ఆమె తనకు తానుగా నిలబడాలనే విశ్వాసాన్ని పొందుతుంది.",
              "or": "ନିମ୍ନଲିଖିତ କିଛି ସେମିଷ୍ଟାରରେ, ସେ ନିଜ ପାଇଁ ଠିଆ ହେବାର ଆତ୍ମବିଶ୍ୱାସ ଲାଭ କରନ୍ତି |",
              "as": "পিছৰ কেইটামান ছেমিষ্টাৰত, তাই নিজৰ বাবে থিয় হোৱাৰ আত্মবিশ্বাস লাভ কৰে।",
              "gu": "નીચેના થોડા સેમેસ્ટરમાં, તેને પોતાને માટે ઊભા રહેવાનો આત્મવિશ્વાસ મેળવ્યો."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_1_5",
              "hi": "B5i_1_5",
              "ka": "B5i_1_5",
              "te": "B5i_1_5",
              "or": "B5i_1_5",
              "as": "B5i_1_5",
              "gu": "B5i_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She finds many close friends in the Study Circle. Some are from her batch and some are seniors; some are from reserved categories and some from general category.",
              "hi": "उसे अध्ययन सर्कल में कई घनिष्ठ मित्र मिलते हैं। कुछ उसके बैच से हैं और कुछ वरिष्ठ हैं; कुछ आरक्षित श्रेणियों से हैं और कुछ सामान्य श्रेणी से हैं।",
              "ka": "ಅವಳು ಸ್ಟಡಿ ಸರ್ಕಲ್‌ನಲ್ಲಿ ಅನೇಕ ಆಪ್ತ ಸ್ನೇಹಿತರನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾಳೆ. ಕೆಲವರು ಅವಳ ಬ್ಯಾಚಿನವರು ಮತ್ತು ಕೆಲವರು ಸೀನಿಯರ್ಸ್; ಕೆಲವರು ಮೀಸಲು ವರ್ಗದವರು ಮತ್ತು ಕೆಲವರು ಸಾಮಾನ್ಯ ವರ್ಗದವರು.",
              "te": "ఆమెకు స్టడీ సర్కిల్‌లో చాలా మంది సన్నిహితులు ఉన్నారు. కొందరు ఆమె బ్యాచ్‌కు చెందినవారు మరియు కొందరు సీనియర్లు; కొందరు రిజర్వ్డ్ కేటగిరీల నుండి మరియు మరికొంతమంది సాధారణ వర్గం నుండి ఉన్నారు.",
              "or": "ସେ ଅଧ୍ୟୟନ ସର୍କଲରେ ଅନେକ ଘନିଷ୍ଠ ବନ୍ଧୁ ଖୋଜନ୍ତି | କେତେକ ତାଙ୍କ ବ୍ୟାଚରୁ\n  ଏବଂ କେତେକ ସିନିୟର; କେତେକ ସଂରକ୍ଷିତ ବର୍ଗର ଏବଂ କେତେକ ସାଧାରଣ ବର୍ଗର |",
              "as": "তাই অধ্যয়ন চক্ৰত বহুতো ঘনিষ্ঠ বন্ধু বিচাৰি পায়। কিছুমান তাইৰ বেচৰ আৰু কিছুমান ডাঙৰ; কিছুমান সংৰক্ষিত শ্ৰেণীৰ আৰু কিছুমান সাধাৰণ শ্ৰেণীৰ।",
              "gu": "તેને સ્ટડી સર્કલમાં ઘણા નજીકના મિત્રો મળે છે. કેટલાક તેના બેચના છે અને કેટલાક સિનિયર ; કેટલાક આરક્ષિત શ્રેણીમાંથી અને કેટલાક સામાન્ય શ્રેણીમાંથી છે."
            }
          }
        ]
      },
      "scene36": {
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "Join the college in Karnataka",
              "hi": "कर्नाटक में कॉलेज में शामिल हो",
              "ka": "ಕರ್ನಾಟಕದಲ್ಲಿ ಕಾಲೇಜಿಗೆ ಸೇರಿಕೋ",
              "te": "కర్ణాటకలోని కాలేజీలో చేరండి",
              "or": "କର୍ଣ୍ଣାଟକର କଲେଜରେ ଯୋଗ ଦିଅନ୍ତୁ |",
              "as": "কৰ্ণাটকৰ মহাবিদ্যালয়ত যোগদান কৰক",
              "gu": "કર્ણાટકની કોલેજમાં જોડાઓ"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The college experience is very different from what Mira expected. The new college has good infrastructure and facilities.",
              "hi": "कॉलेज का अनुभव, जैसा मीरा ने सोचा था उससे बहुत अलग है। नए कॉलेज में अच्छा बुनियादी ढांचा और सुविधाएं हैं।",
              "ka": "ಕಾಲೇಜಿನ ಅನುಭವ ಮೀರಾ ನಿರೀಕ್ಷಿಸಿದ್ದಕ್ಕಿಂತ ತುಂಬಾ ಭಿನ್ನವಾಗಿದೆ. ಹೊಸ ಕಾಲೇಜು ಉತ್ತಮ ಮೂಲಸೌಕರ್ಯ ಮತ್ತು ಸೌಲಭ್ಯಗಳನ್ನು ಹೊಂದಿದೆ.",
              "te": "కాలేజీ గురించి మీరా అనుకున్నదానికి చాలా భిన్నంగా ఉంది. కొత్త కళాశాలలో మంచి మౌలిక సదుపాయాలు మరియు సౌకర్యాలు ఉన్నాయి.",
              "or": "ମୀରା ଯାହା ଆଶା କରିଥିଲେ ତାହାଠାରୁ କଲେଜ ଅଭିଜ୍ଞତା ବହୁତ ଭିନ୍ନ | ନୂତନ\n  କଲେଜରେ ଭଲ ଭିତ୍ତିଭୂମି ଏବଂ ସୁବିଧା ରହିଛି |",
              "as": "মহাবিদ্যালয়ৰ অভিজ্ঞতা মীৰাই আশা কৰাতকৈ বহুত বেলেগ। নতুন মহাবিদ্যালয়খনত ভাল সুবিধা আছে।",
              "gu": "કોલેજનો અનુભવ મીરાની અપેક્ષા કરતા ઘણો અલગ છે. નવી કોલેજમાં સારી ઈન્ફ્રાસ્ટ્રક્ચર અને સુવિધાઓ છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5ii_1_2",
              "hi": "B5II_1_2",
              "ka": "B5ii_1_2",
              "te": "B5ii_1_2",
              "or": "B5ii_1_2",
              "as": "B5ii_1_2",
              "gu": "B5ii_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "There are students from different parts of the country. Some of the students are toppers and others from other states, and some seem to have more exposure.",
              "hi": "देश के अलग - अलग कोने से  विद्यार्थी हैं। कुछ विद्यार्थी टॉपर्स और अन्य राज्यों से दूसरे विद्यार्थी आए हैं, और कुछ लोग अधिक जानकार प्रतीत होते हैं।",
              "ka": "ದೇಶದ ವಿವಿಧ ಭಾಗಗಳ ವಿದ್ಯಾರ್ಥಿಗಳಿದ್ದಾರೆ. ಕೆಲವು ವಿದ್ಯಾರ್ಥಿಗಳು ಟಾಪರ್‌ಗಳಾಗಿದ್ದು,ಹಾಗೂ  ಇತರರು ಹೆಚ್ಚು ಮಾನ್ಯತೆ ಹೊಂದಿದ್ದಾರೆ. ಅನಿಸುತ್ತದೆ",
              "te": "అస్సాంలోని వివిధ ప్రాంతాల నుండి విద్యార్థులు ఉన్నారు. కొంతమంది విద్యార్థులు టాపర్‌లు కాగా, మరికొందరు నగరానికి చెందిన వారు ఎక్కువ ఎక్స్ పోజర్‌ను కలిగి ఉన్నారు.",
              "or": "ଦେଶର ବିଭିନ୍ନ ପ୍ରାନ୍ତରୁ ଛାତ୍ର ଅଛନ୍ତି | କେତେକ ଛାତ୍ର ଟପ୍ପର ଏବଂ ଅନ୍ୟମାନେ ଅନ୍ୟ\n  ରାଜ୍ୟରୁ ଆସିଛନ୍ତି ଏବଂ କେତେକଙ୍କର ଅଧିକ ଏକ୍ସପୋଜର୍ ଥିବା ପରି ମନେହୁଏ |",
              "as": "দেশৰ বিভিন্ন ঠাইৰ শিক্ষাৰ্থী আছে। কিছুমান শিক্ষাৰ্থী শীৰ্ষস্থানপ্ৰাপ্ত আৰু আন ৰাজ্যৰ আন কিছুমান, আৰু কিছুমানৰবেছি এক্সপোজাৰ থকা দেখা যায়।",
              "gu": "જેમાં દેશના વિવિધ ભાગોમાંથી વિદ્યાર્થીઓ આવે છે. કેટલાક વિદ્યાર્થીઓ ઉચ્ચ ગુણવાળા છે અને કેટલાક અન્ય રાજ્યોના છે, અને કેટલાક વધુ એક્સપોઝર હોવાનું જણાય છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_1_3_copy_copy",
              "hi": "B5i_1_3_कॉपी_कॉपी ",
              "ka": "B5i_1_3_copy_copy",
              "te": "B5i_1_3_copy_copy",
              "or": "B5i_1_3_copy_copy",
              "as": "B5i_1_3_copy_copy",
              "gu": "B5i_1_3_નકલ_નકલ"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira makes many new friends during the lectures in her first semester. She enjoys the practical classes, where she learns many new things about the human body.",
              "hi": "मीरा अपने पहले सेमेस्टर में लेक्चर्स के दौरान कई नए मित्र बनाती है। वह प्रयोगात्मक कक्षाओं का आनंद लेती है, जहां वह मानव शरीर के बारे में कई नई चीजें सीखती है।",
              "ka": "ಮೀರಾ ತನ್ನ ಮೊದಲ ಸೆಮಿಸ್ಟರ್‌ನಲ್ಲಿ ಉಪನ್ಯಾಸಗಳ ಸಮಯದಲ್ಲಿ ಅನೇಕ ಹೊಸ ಸ್ನೇಹಿತರನ್ನು ಮಾಡಿಕೊಳ್ಳುತ್ತಾಳೆ. ಅವಳು ಪ್ರಾಯೋಗಿಕ ತರಗತಿಗಳನ್ನು ಆನಂದಿಸುತ್ತಾಳೆ, ಅಲ್ಲಿ ಅವಳು ಮಾನವ ದೇಹದ ಬಗ್ಗೆ ಅನೇಕ ಹೊಸ ವಿಷಯಗಳನ್ನು ಕಲಿಯುತ್ತಾಳೆ.",
              "te": "మీరా తన మొదటి సెమిస్టర్‌లో క్లాస్ సమయంలో చాలా మంది కొత్త స్నేహితులను సంపాదించుకుంది. ఆమె ప్రాక్టికల్ క్లాస్ లను ఆనందిస్తుంది, అక్కడ ఆమె మానవ శరీరం గురించి చాలా కొత్త విషయాలను నేర్చుకుంటుంది.",
              "or": "ମୀରା ତାଙ୍କର ପ୍ରଥମ ସେମିଷ୍ଟାରରେ ବକ୍ତୃତା ସମୟରେ ଅନେକ ନୂତନ ବନ୍ଧୁ ସୃଷ୍ଟି କରନ୍ତି |\n  ସେ ବ୍ୟବହାରିକ ଶ୍ରେଣୀଗୁଡିକ ଉପଭୋଗ କରନ୍ତି, ଯେଉଁଠାରେ ସେ ମାନବ ଶରୀର ବିଷୟରେ ଅନେକ ନୂତନ ଜିନିଷ ଶିଖନ୍ତି |",
              "as": "মীৰাই তাইৰ প্ৰথম ছেমিষ্টাৰত সময়ত বহুতো নতুন বন্ধু বনালে। তাই প্ৰেক্টিকেল বোৰ কৰি বৰ ভাল পায়, য'ত তাই মানৱ শৰীৰৰ বিষয়ে বহুতো নতুন কথা শিকে।",
              "gu": "મીરા તેના પહેલા સેમેસ્ટરમાં લેક્ચર દરમિયાન ઘણા નવા મિત્રો બનાવે છે. તે વ્યવહારિક વર્ગોનો આનંદ માણે છે, જ્યાં તે માનવ શરીર વિશે ઘણી નવી વસ્તુઓ શીખે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira has a tough time initially, but she makes many new friends during the lectures in her first semester. She enjoys the practical classes, where she learns so many new things about the human body.",
              "hi": " प्रारंभ में मीरा को कठिनाई होती है, मीरा अपने पहले सेमेस्टर में लेक्चर्स के दौरान कई नए मित्र बनाती है। वह प्रयोगात्मक कक्षाओं का आनंद लेती है, जहां वह मानव शरीर के बारे में कई नई चीजें सीखती है।",
              "ka": "ಮೊದ ಮೊದಲು ಮೀರಾ ಸ್ವಲ್ಪ ತೊಂದರೆ ಅನುಭವಿಸುತ್ತಾಳೆ. ಆದರೆ ಅವಳು ತನ್ನ ಮೊದಲ ಸೆಮಿಸ್ಟರ್‌ನಲ್ಲಿ ಉಪನ್ಯಾಸಗಳ ಸಮಯದಲ್ಲಿ ಅನೇಕ ಹೊಸ ಸ್ನೇಹಿತರನ್ನು ಮಾಡಿಕೊಳ್ಳುತ್ತಾಳೆ. ಅವಳು ಪ್ರಾಯೋಗಿಕ ತರಗತಿಗಳನ್ನು ಆನಂದಿಸುತ್ತಾಳೆ, ಅಲ್ಲಿ ಅವಳು ಮಾನವ ದೇಹದ ಬಗ್ಗೆ ಅನೇಕ ಹೊಸ ವಿಷಯಗಳನ್ನು ಕಲಿಯುತ್ತಾಳೆ.",
              "te": "మీరాకి మొదట్లో చాలా కష్టమైన సమయం ఉంది, కానీ మీరా తన మొదటి సెమిస్టర్‌లో క్లాస్ సమయంలో చాలా మంది కొత్త స్నేహితులను సంపాదించుకుంది. ఆమె ప్రాక్టికల్ క్లాస్ లను ఆనందిస్తుంది, అక్కడ ఆమె మానవ శరీరం గురించి చాలా కొత్త విషయాలను నేర్చుకుంటుంది.",
              "or": "ମୀରାଙ୍କର ପ୍ରାରମ୍ଭରେ କଠିନ ସମୟ ଅଛି, କିନ୍ତୁ ସେ ପ୍ରଥମ ସେମିଷ୍ଟାରରେ ବକ୍ତୃତା \n ସମୟରେ ଅନେକ ନୂତନ ବନ୍ଧୁ ସୃଷ୍ଟି କରନ୍ତି | ସେ ପ୍ରାକ୍ଟିକାଲ୍ କ୍ଲାସ୍ ଉପଭୋଗ କରନ୍ତି, ଯେଉଁଠାରେ ସେ ମାନବ ଶରୀର ବିଷୟରେ ଅନେକ ନୂତନ ଜିନିଷ ଶିଖନ୍ତି |",
              "as": "মীৰাৰ আৰম্ভণিতে কঠিন সময় আছিল, কিন্তু তাই তাইৰ প্ৰথম ছেমিষ্টাৰত ক্লাচৰ সময়ত বহুতো নতুন বন্ধু বনাইছিল। তাই প্ৰেক্টিকেল উপভোগ কৰে, য'ত তাই মানৱ শৰীৰৰ বিষয়ে বহুতো নতুন কথা শিকে।",
              "gu": "મીરાને શરૂઆતમાં મુશ્કેલ સમય હોય છે, પરંતુ તે તેના પ્રથમ સેમેસ્ટરમાં લેક્ચર દરમિયાન ઘણા નવા મિત્રો બનાવે છે. તે વ્યવહારિક વર્ગોનો આનંદ માણે છે, જ્યાં તે માનવ શરીર વિશે ઘણી નવી વસ્તુઓ શીખે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_2_1_copy_copy",
              "hi": "B5i_2_1_कॉपी_कॉपी ",
              "ka": "B5i_2_1_copy_copy",
              "te": "B5i_2_1_copy_copy",
              "or": "B5i_2_1_copy_copy",
              "as": "B5i_2_1_copy_copy",
              "gu": "B5i_2_1_નકલ_નકલ"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is overwhelmed by the amount she has to study. Becoming a doctor isn’t easy, as she has to remember many different symptoms, treatments, and ailments.",
              "hi": "मीरा उस मात्रा से व्याकुल है जितना उसे अध्ययन करना है। डॉक्टर बनना सरल नहीं है, क्योंकि उसे कई अलग-अलग लक्षण, उपचार और बीमारियों को याद रखना है।",
              "ka": "ಮೀರಾ ತಾನು ಓದಬೇಕಾದ ಹೆಚ್ಚಿನ ವಿಷಯಗಳಿಂದ ಸ್ವಲ್ಪ ಎದೆಗುಂದಿದ್ದಾಳೆ. ವೈದ್ಯರಾಗುವುದು ಸುಲಭವಲ್ಲ, ಏಕೆಂದರೆ ಅವರು ವಿವಿಧ ರೋಗಲಕ್ಷಣಗಳು, ಚಿಕಿತ್ಸೆಗಳು ಮತ್ತು ಕಾಯಿಲೆಗಳನ್ನು ನೆನಪಿಟ್ಟುಕೊಳ್ಳಬೇಕು.",
              "te": "మీరా చదవాల్సిన మొత్తం చూసి ఆశ్చర్యపోతుంది. డాక్టర్‌గా అవ్వడం అంత సులభం కాదు, ఎందుకంటే ఆమె అనేక రకాల లక్షణాలు, చికిత్సలు మరియు జబ్బుల పేర్లు గుర్తుంచుకోవాలి.",
              "or": "ତାଙ୍କୁ ପଢିବାକୁ ଥିବା ପରିମାଣରେ ମୀରା ଅତିଷ୍ଠ ହୋଇପଡିଛନ୍ତି | \n ଡାକ୍ତର ହେବା ସହଜ ନୁହେଁ, କାରଣ ତାଙ୍କୁ ଅନେକ ଭିନ୍ନ ଲକ୍ଷଣ, ଚିକିତ୍ସା ଏବଂ ଅସୁସ୍ଥତା ମନେ ରଖିବାକୁ ପଡିବ |",
              "as": "মীৰাই পঢ়িব লগা বোৰ দেখি ভয় কৰিছে। চিকিৎসক হোৱাটো সহজ নহয়, কিয়নো তাই বিভিন্ন লক্ষণ, চিকিৎসা আৰু ৰোগ মনত ৰাখিব লাগিব।",
              "gu": "મીરાને ભણવાની રકમ જોઈને અભિભૂત છે. ડૉક્ટર બનવું સહેલું નથી, કારણ કે તેને ઘણાં વિવિધ લક્ષણો, સારવારો અને બિમારીઓ યાદ રાખવાની હોય છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_2_2_copy_copy",
              "hi": "B5i_2_2_कॉपी_कॉपी ",
              "ka": "B5i_2_2_copy_copy",
              "te": "B5i_2_2_copy_copy",
              "or": "B5i_2_2_copy_copy",
              "as": "B5i_2_2_copy_copy",
              "gu": "B5i_2_2_નકલ_નકલ"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is excited and happy, but a few weeks into her semester, some students she spoke to initially aren’t talking to her anymore.",
              "hi": "मीरा उत्साहित और खुश है, लेकिन अपने सेमेस्टर में कुछ सप्ताह के बाद देखती है कि, कुछ छात्र जो शुरू में बात करते थे, अब उससे बात नहीं कर रहे हैं।",
              "ka": "ಮೀರಾ ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ ಮತ್ತು ಸಂತೋಷವಾಗಿದ್ದಾಳೆ, ಆದರೆ ಅವಳ ಸೆಮಿಸ್ಟರ್‌ನಲ್ಲಿ ಕೆಲವು ವಾರಗಳು ಕಳೆದ ನಂತರ, ಅವಳು ಆರಂಭದಲ್ಲಿ ಮಾತನಾಡಿದ ಕೆಲವು ವಿದ್ಯಾರ್ಥಿಗಳು ಅವಳೊಂದಿಗೆ ಇನ್ನು ಮುಂದೆ ಮಾತನಾಡುತ್ತಿಲ್ಲ ಎನ್ನುವುದನ್ನು ಗಮನಿಸುತ್ತಾಳೆ.",
              "te": "మీరా ఉత్సాహంగా మరియు సంతోషంగా ఉంది, కానీ ఆమె సెమిస్టర్‌లోకి ప్రవేశించిన కొన్ని వారాలల్లో, మొదట్లో ఆమెతో మాట్లాడిన కొంతమంది విద్యార్థులు ఇప్పుడు మాట్లాడటం లేదు.",
              "or": "ମୀରା ଉତ୍ସାହିତ ଏବଂ ଖୁସି, କିନ୍ତୁ କିଛି ସପ୍ତାହ ମଧ୍ୟରେ ସେମିଷ୍ଟାରରେ, ଆରମ୍ଭରୁ\n  କଥା ହେଉଥିବା କିଛି ଛାତ୍ର ତାଙ୍କ \n  ସହିତ କଥାବାର୍ତ୍ତା କଲେ ନାହିଁ ।",
              "as": "মীৰা সুখী, কিন্তু তাইৰ ছেমিষ্টাৰৰ কেইসপ্তাহমানৰ পিছৰ পৰা, তাই প্ৰথমতে কথা পতা কিছুমান লগৰে তাইৰ সৈতে আৰু কথা পতা নাই।",
              "gu": "મીરા ઉત્સાહિત અને ખુશ છે, પરંતુ તેના સેમેસ્ટરના થોડા અઠવાડિયા પછી, તે શરૂઆતમાં જે વિદ્યાર્થીઓ સાથે વાત કરી હતી તે હવે તેની સાથે વાત કરતી નથી."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_2_3_copy_copy",
              "hi": "B5i_2_3_कॉपी_कॉपी ",
              "ka": "B5i_2_3_copy_copy",
              "te": "B5i_2_3_copy_copy",
              "or": "B5i_2_3_copy_copy",
              "as": "B5i_2_3_copy_copy",
              "gu": "B5i_2_3_નકલ_નકલ"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She also senses that some students are rude and inconsiderate towards her.",
              "hi": "उसे यह भी लगता है कि कुछ छात्र असभ्य हैं और उसका ध्यान नहीं रखते हैं।",
              "ka": "ಕೆಲವು ವಿದ್ಯಾರ್ಥಿಗಳು ತನ್ನ ಕಡೆಗೆ ಅಸಹನೆ ಮತ್ತು ಅಸಡ್ಡೆ ತೋರುತ್ತಿದ್ದಾರೆಂದು ಅವಳು ಗ್ರಹಿಸುತ್ತಾಳೆ.",
              "te": "కొంతమంది విద్యార్థులు తన పట్ల అసభ్యంగా మరియు అనాలోచితంగా ప్రవర్తిస్తున్నారని కూడా ఆమె గ్రహించింది.",
              "or": "ସେ ଏହା ମଧ୍ୟ ଅନୁଭବ କରନ୍ତି ଯେ କିଛି ଛାତ୍ର ତାଙ୍କ ପ୍ରତି ଅଶାଳୀନ ଏବଂ ଅବହେଳିତ\n  ବ୍ୟବହାର କରୁଛନ୍ତି\n |",
              "as": "তাই এইটোও অনুভৱ কৰে যে কিছুমান শিক্ষাৰ্থী তাইৰ প্ৰতি অভদ্ৰ আৰু বেয়া।",
              "gu": "તે એ પણ અનુભવે છે કે કેટલાક વિદ્યાર્થીઓ તેના પ્રત્યે અસંસ્કારી અને અવિચારી છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_3_1_copy_copy",
              "hi": "B5i_3_1_कॉपी_कॉपी ",
              "ka": "B5i_3_1_copy_copy",
              "te": "B5i_3_1_copy_copy",
              "or": "B5i_3_1_copy_copy",
              "as": "B5i_3_1_copy_copy",
              "gu": "B5i_3_1_નકલ_નકલ"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is quite upset. She doesn’t understand what she did wrong. Some seniors approach her during lunch. They introduce themselves as members of the Students Democratic Study Circle.",
              "hi": "मीरा काफी परेशान है। उसे समझ में नहीं आता कि उसने क्या गलत किया। कुछ सीनियर दोपहर के भोजन के दौरान उससे संपर्क करते हैं। वे खुद को छात्र डेमोक्रेटिक स्टडी सर्कल के सदस्यों के रूप में परिचय देते हैं।",
              "ka": "ಮೀರಾ ತುಂಬಾ ಬೇಸರಗೊಂಡಿದ್ದಾಳೆ. ಅವಳು ಏನು ತಪ್ಪು ಮಾಡಿದ್ದಾಳೆಂದು ಅವಳಿಗೆ ಅರ್ಥವಾಗುತ್ತಿಲ್ಲ. ಊಟದ ಸಮಯದಲ್ಲಿ ಕೆಲವು ಸೀನಿಯರ್ಸ್ ಅವಳನ್ನು ಸಂಪರ್ಕಿಸುತ್ತಾರೆ. ಅವರು ಸ್ಟೂಡೆಂಟ್ಸ್ ಡೆಮಾಕ್ರಟಿಕ್ ಸ್ಟಡಿ ಸರ್ಕಲ್‌ನ ಸದಸ್ಯರಾಗಿ ತಮ್ಮನ್ನು ಪರಿಚಯಿಸಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "మీరా చాలా బాధ పడింది. ఆమె చేసిన తప్పేమిటో అర్థం కావడం లేదు. మధ్యాహ్న భోజన సమయంలో కొందరు సీనియర్లు ఆమె వద్దకు వెళతారు. స్టూడెంట్స్ డెమోక్రటిక్ స్టడీ సర్కిల్ సభ్యులుగా తమను తాము పరిచయం చేసుకుంటారు.",
              "or": "ମୀରା ବହୁତ ବିରକ୍ତ | ସେ କଣ ଭୁଲ୍ କରିଛନ୍ତି ସେ ବୁଝିପାରାନ୍ତି ନାହିଁ | କିଛି ବୟସ୍କମାନେ \n ମଧ୍ୟାହ୍ନ ଭୋଜନ ସମୟରେ ତାଙ୍କ ନିକଟକୁ ଆସନ୍ତି | ସେମାନେ ନିଜକୁ ଛାତ୍ର ଡେମୋକ୍ରାଟିକ୍ ଷ୍ଟଡି ସର୍କଲର ସଦସ୍ୟ ଭାବରେ ପରିଚିତ କରନ୍ତି |",
              "as": "মীৰা যথেষ্ট বিচলিত। তাই বুজি পোৱা নাই কি ভুল কৰিলে। কিছুমান চিনিয়ৰে দুপৰীয়াৰ আহাৰৰ সময়ত তাইৰ ওচৰলৈ যায়। তেওঁলোকে নিজকে ষ্টুডেণ্টছ ডেমক্ৰেটিক ষ্টাডি চাৰ্কলৰ সদস্য বুলি পৰিচয় দিয়ে।",
              "gu": "મીરા એકદમ પરેશાન છે. તેને શું ખોટું કર્યું તે તે સમજી શકતો નથી. કેટલાક વરિષ્ઠ લોકો લંચ દરમિયાન તેની પાસે જાય છે. તેઓ પોતાનો પરિચય સ્ટુડન્ટ્સ ડેમોક્રેટિક સ્ટડી સર્કલના સભ્યો તરીકે આપે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_3_2 bg_copy_copy",
              "hi": "B5i_3_2 बीजी_कॉपी_कॉपी ",
              "ka": "B5i_3_2 bg_copy_copy",
              "te": "B5i_3_2 bg_copy_copy",
              "or": "B5i_3_2 bg_copy_copy",
              "as": "B5i_3_2 bg_copy_copy",
              "gu": "B5i_3_2 bg_નકલ_નકલ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Student",
              "hi": "विद्यार्थी",
              "ka": "ವಿದ್ಯಾರ್ಥಿ",
              "te": "విద్యార్థి",
              "or": "ଛତ୍ର",
              "as": "শিক্ষাৰ্থী",
              "gu": "વિદ્યાર્થી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Nice to meet you, Mira! We usually approach students in the first year who come from SC, ST and OBC backgrounds to join our weekly discussion groups.",
              "hi": "आपसे मिलकर अच्छा लगा, मीरा! हम सामान्यतः प्रथम वर्ष में छात्रों से संपर्क करते हैं जो एससी, एसटी और ओबीसी पृष्ठभूमि से हमारे साप्ताहिक चर्चा समूहों में शामिल होने के लिए आते हैं।",
              "ka": "ನಿನ್ನನ್ನು ಭೇಟಿಯಾಗಲು ಸಂತೋಷವಾಗಿದೆ, ಮೀರಾ! ನಾವು ಸಾಮಾನ್ಯವಾಗಿ ನಮ್ಮ ಸಾಪ್ತಾಹಿಕ ಚರ್ಚಾ ಗುಂಪುಗಳಿಗೆ ಸೇರಲು SC, ST ಮತ್ತು OBC ಹಿನ್ನೆಲೆಯಿಂದ ಬರುವ ಮೊದಲ ವರ್ಷದ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಸಂಪರ್ಕಿಸುತ್ತೇವೆ",
              "te": "నిన్ను కలవడం ఆనందంగా ఉంది, మీరా! మేము సాధారణంగా మా వారపు చర్చా గ్రూప్స్ లో చేరడానికి SC, ST మరియు OBC ద్వారా వచ్చిన మొదటి సంవత్సరంలోని విద్యార్థులను కలుసుకుంటాము.",
              "or": "ମିରା, ତୁମକୁ ଭେଟିବାକୁ ଭଲ ଲାଗିଲା! ଆମେ ସାଧାରଣତଃ \n ପ୍ରଥମ ବର୍ଷରେ ଛାତ୍ରମାନଙ୍କ ନିକଟକୁ ଯାଇଥାଉ, ଯେଉଁମାନେ ଆମର ସାପ୍ତାହିକ ଆଲୋଚନା ଗୋଷ୍ଠୀରେ ଯୋଗଦେବା ପାଇଁ SC, ST ଏବଂ OBC ପୃଷ୍ଠଭୂମିରୁ ଆସନ୍ତି |",
              "as": "আপোনাক লগ পাই ভাল লাগিছে, মীৰা! আমি সাধাৰণতে প্ৰথম বছৰত SC,ST আৰু OBC পৰা অহা শিক্ষাৰ্থীসকলৰ ওচৰলৈ আমাৰ সাপ্তাহিক আলোচনা গোটত যোগদান কৰিবলৈ কওঁ।",
              "gu": "તને મળીને આનંદ થયો, મીરા! અમે સામાન્ય રીતે અમારા સાપ્તાહિક ચર્ચા જૂથોમાં જોડાવા માટે SC, ST અને OBC પૃષ્ઠભૂમિમાંથી આવતા પ્રથમ વર્ષમાં વિદ્યાર્થીઓનો સંપર્ક કરીએ છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I don’t understand.",
              "hi": "मैं समझ नहीं पा रही हूँ।",
              "ka": "ನನಗೆ ಅದು ತಿಳಿಯದು",
              "te": "నాకు అర్థం కాలేదు.",
              "or": "ମୁଁ ବୁଝିପାରୁ ନାହିଁ",
              "as": "মই বুজি পোৱা নাই।",
              "gu": "મને સમજાતું નથી."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_3_3///_copy_copy",
              "hi": "B5i_3_3///_ कॉपी_कॉपी ",
              "ka": "B5i_3_3///_copy_copy",
              "te": "B5i_3_3///_copy_copy",
              "or": "B5i_3_3///_copy_copy",
              "as": "B5i_3_3///",
              "gu": "B5i_3_3///_નકલ_નકલ"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "There is some resistance against students who have rightfully claimed reservations. We are here to support you through that initial phase.",
              "hi": "उन छात्रों के खिलाफ कुछ प्रतिरोध है जो आरक्षण पाने के अधिकारी हैं। हम यहां उस प्रारंभिक चरण में आपका समर्थन करने के लिए हैं।",
              "ka": "ನ್ಯಾಯಯುತವಾಗಿ ಮೀಸಲಾತಿಯನ್ನು ಪಡೆದ ವಿದ್ಯಾರ್ಥಿಗಳ ವಿರುದ್ಧ ಕೆಲವು ಪ್ರತಿರೋಧವಿದೆ. ಆ ಆರಂಭಿಕ ಹಂತದ ಮೂಲಕ ನಿಮ್ಮನ್ನು ಬೆಂಬಲಿಸಲು ನಾವು ಇಲ್ಲಿದ್ದೇವೆ.",
              "te": "ఇక్కడ రిజర్వేషన్లను న్యాయబద్ధంగా పొందిన విద్యార్థులపై కొంత వ్యతిరేకత ఉంది. మొదటి దశలోనే మీకు మద్దతు ఇవ్వడానికి మేము ఇక్కడ ఉన్నాము.",
              "or": "ଛାତ୍ରମାନଙ୍କ ବିରୁଦ୍ଧରେ କିଛି ପ୍ରତିରୋଧ ଅଛି ଯେଉଁମାନେ ସଠିକ୍ ଭାବରେ ସଂରକ୍ଷଣ\n  ଦାବି କରିଛନ୍ତି | ସେହି ପ୍ରାରମ୍ଭିକ ପର୍ଯ୍ୟାୟରେ ଆପଣଙ୍କୁ ସମର୍ଥନ କରିବାକୁ ଆମେ ଏଠାରେ ଅଛୁ |",
              "as": "যিসকল শিক্ষাৰ্থীয়ে সঠিকভাৱে সংৰক্ষণদাবী কৰিছে তেওঁলোকৰ বিৰুদ্ধে কিছুমানৰ বেয়া ভাৱ আছে। আমি সেই প্ৰথম অৱস্থাত তোমাক সমৰ্থন কৰিবলৈ ইয়াত আছোঁ।",
              "gu": "અનામતનો હકથી દાવો કરનારા વિદ્યાર્થીઓ સામે થોડો પ્રતિકાર છે. અમે તે પ્રારંભિક તબક્કામાં તને સમર્થન આપવા માટે અહીં છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "That explains why the students were behaving differently towards me.",
              "hi": "अब स्पष्ट हुआ कि छात्र मेरे साथ अलग व्यवहार क्यों कर रहे थे।",
              "ka": "ವಿದ್ಯಾರ್ಥಿಗಳು ನನ್ನೊಂದಿಗೆ ಏಕೆ ವಿಭಿನ್ನವಾಗಿ ವರ್ತಿಸುತ್ತಿದ್ದರು ಎನ್ನುವುದನ್ನು ಅದು ವಿವರಿಸುತ್ತದೆ",
              "te": "విద్యార్థులు తన పట్ల ఎందుకు వ్యతిరేకతతో ప్రవర్తిస్తున్నారో వివరిస్తుంది.",
              "or": "ଛାତ୍ରମାନେ କାହିଁକି ମୋ ପ୍ରତି ଭିନ୍ନ ଆଚରଣ କରୁଥିଲେ ତାହା ବ୍ୟାଖ୍ୟା କରେ |",
              "as": "এইটোৱে বৰ্ণনা কৰে যে শিক্ষাৰ্থীসকলে কিয় মোৰ প্ৰতি বেলেগ আচৰণ কৰিছে।",
              "gu": "તે સમજાવે છે કે શા માટે વિદ્યાર્થીઓ મારી સાથે અલગ વર્તન કરી રહ્યા હતા."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "You should bring this to the discussion group! It allows all students to have a safe space to discuss these issues to make the campus safer and more inclusive.",
              "hi": "आपको इसे चर्चा समूह में लाना चाहिए! यह सभी छात्रों को कैंपस को सुरक्षित और अधिक समावेशी बनाने जैसे मुद्दों पर चर्चा करने के लिए एक सुरक्षित स्थान देता है।",
              "ka": "ನೀವು ಇದನ್ನು ಚರ್ಚಾ ಗುಂಪಿಗೆ ತರಬೇಕು! ಕ್ಯಾಂಪಸ್ ಅನ್ನು ಸುರಕ್ಷಿತ ಮತ್ತು ಹೆಚ್ಚು ಒಳಗೊಳ್ಳುವಂತೆ ಮಾಡಲು ಈ ಸಮಸ್ಯೆಗಳನ್ನು ಚರ್ಚಿಸಲು ಎಲ್ಲಾ ವಿದ್ಯಾರ್ಥಿಗಳು ಒಂದು ಸುರಕ್ಷಿತ ಸ್ಥಳವನ್ನು ಹೊಂದಲು ಇದು ಅನುಮತಿಸುತ್ತದೆ",
              "te": "మీరు దీన్ని చర్చా సమూహానికి తీసుకురావాలి! ఇది క్యాంపస్‌ సురక్షితంగా కలిసి ఉండేలా చేయడానికి మరియు ఇలాంటి సమస్యలను చర్చించడానికి విద్యార్థులందరికీ సురక్షితమైన స్థలాన్ని కలిగి ఉంటుంది.",
              "or": "ଆପଣ ଏହାକୁ ଆଲୋଚନା ଗୋଷ୍ଠୀକୁ ଆଣିବା ଉଚିତ୍! କ୍ୟାମ୍ପସକୁ ଅଧିକ ନିରାପଦ \n ଏବଂ ଅଧିକ ଅନ୍ତର୍ଭୂକ୍ତ କରିବା ପାଇଁ ଏହା ସମସ୍ତ ଛାତ୍ରମାନଙ୍କୁ ଏକ ନିରାପଦ ସ୍ଥାନ ପାଇବାକୁ ଅନୁମତି ଦେଇଥାଏ |",
              "as": "তুমি এই বিষয়টো আলোচনা গোটলৈ অনা উচিত! ই সকলো শিক্ষাৰ্থীক কলেজত চৌহদটো সুৰক্ষিত অনুভৱ কৰাই দিয়ে।",
              "gu": "તમારે આને ચર્ચા જૂથમાં લાવવું જોઈએ! તે તમામ વિદ્યાર્થીઓને કેમ્પસને વધુ સુરક્ષિત અને વધુ સમાવિષ્ટ બનાવવા માટે આ મુદ્દાઓ પર ચર્ચા કરવા માટે સુરક્ષિત જગ્યાની મંજૂરી આપે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_3_4_copy_copy",
              "hi": "B5i_3_4_कॉपी_कॉपी",
              "ka": "B5i_3_4_copy_copy",
              "te": "B5i_3_4_copy_copy",
              "or": "B5i_3_4_copy_copy",
              "as": "B5i_3_4",
              "gu": "B5i_3_4_નકલ_નકલ"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira attends the weekly discussions of the Students Democratic Study Circle. She realises that many other students like her have faced similar situations of discrimination, sometimes even worse.",
              "hi": "मीरा छात्र डेमोक्रेटिक स्टडी सर्कल की साप्ताहिक चर्चाओं में भाग लेती है। वह अनुभव  करती है कि उनके जैसे कई अन्य छात्रों ने भेदभाव की समान स्थितियों का सामना किया है, कभी उससे बुरा भी।",
              "ka": "ಸ್ಟೂಡೆಂಟ್ಸ್ ಡೆಮಾಕ್ರಟಿಕ್ ಸ್ಟಡಿ ಸರ್ಕಲ್‌ನ ಸಾಪ್ತಾಹಿಕ ಚರ್ಚೆಗಳಿಗೆ ಮೀರಾ ಹಾಜರಾಗುತ್ತಾಳೆ. ತನ್ನಂತೆಯೇ ಇತರ ಅನೇಕ ವಿದ್ಯಾರ್ಥಿಗಳು ತಾರತಮ್ಯದ ಇದೇ ರೀತಿಯ ಸಂದರ್ಭಗಳನ್ನು ಎದುರಿಸಿದ್ದಾರೆ ಎನ್ನುವುದನ್ನು ಅವಳು ಅರಿತುಕೊಂಡಳು, ಕೆಲವೊಂದು ಇನ್ನೂ ಕೆಟ್ಟ ಸಂದರ್ಭಗಳನ್ನು ಎದುರಿಸಿದ್ದಾರೆ.",
              "te": "మీరా స్టూడెంట్స్ డెమోక్రటిక్ స్టడీ సర్కిల్ లోని వారం చర్చలకు వెళుతుంది. తనలాంటి అనేకమంది ఇతర విద్యార్ధులు వివక్ష యొక్క ఇలాంటి పరిస్థితులను ఎదుర్కొన్నారని, కొన్నిసార్లు మరింత దారుణంగా ఉంటారని ఆమె తెలుసుకుంది.",
              "or": "ମୀରା ଷ୍ଟୁଡେଣ୍ଟସ୍ ଡେମୋକ୍ରାଟିକ୍ ଷ୍ଟଡି ସର୍କଲର ସାପ୍ତାହିକ ଆଲୋଚନାରେ ଯୋଗ\n  ଦିଅନ୍ତି | ସେ ହୃଦୟଙ୍ଗମ କରିଛନ୍ତି ଯେ ତାଙ୍କ ପରି ଅନ୍ୟ ଅନେକ ଛାତ୍ର ମଧ୍ୟ ଭେଦଭାବର ସମାନ ପରିସ୍ଥିତିର ସମ୍ମୁଖୀନ ହୋଇଛନ୍ତି, ବେଳେବେଳେ ଆହୁରି ଖରାପ |ମଧ୍ୟ",
              "as": "মীৰাই ষ্টুডেণ্টছ ডেমক্ৰেটিক ষ্টাডি চাৰ্কলৰ সাপ্তাহিক আলোচনাত অংশ গ্ৰহণ কৰে। তাই বুজি পাই যে তাইৰ দৰে আন বহুতো শিক্ষাৰ্থীয়ে একে ধৰণৰ ভেদ ভাৱ পৰিস্থিতিৰ সন্মুখীন হৈছে, কেতিয়াবা আৰু বেয়া।",
              "gu": "મીરા સ્ટુડન્ટ્સ ડેમોક્રેટિક સ્ટડી સર્કલની સાપ્તાહિક ચર્ચાઓમાં ભાગ લે છે. તેને અહેસાસ થાય છે કે તેના જેવા અન્ય ઘણા વિદ્યાર્થીઓએ ભેદભાવની સમાન પરિસ્થિતિઓનો સામનો કરવો પડ્યો છે, ક્યારેક તો તેનાથી પણ ખરાબ."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5i_1_4_copy_copy",
              "hi": "B5i_1_4_कॉपी_कॉपी ",
              "ka": "B5i_1_4_copy_copy",
              "te": "B5i_1_4_copy_copy",
              "or": "B5i_1_4_copy_copy",
              "as": "B5i_1_4_copy_copy",
              "gu": "B5i_1_4_નકલ_નકલ"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Over the following few semesters, she gains the confidence to stand up for herself.",
              "hi": "आने वाले कुछ सेमेस्टर में, वह अपना पक्ष रख पाने के आत्मविश्वास को प्राप्त करती है।",
              "ka": "ಮುಂದಿನ ಕೆಲವು ಸೆಮಿಸ್ಟರ್‌ಗಳಲ್ಲಿ, ಅವಳು ತನಗಾಗಿಯೇ ನಿಲ್ಲುವ ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
              "te": "కొన్ని సెమిస్టర్‌ల తరువాత, ఆమె తనకు తానుగా నిలబడాలనే విశ్వాసాన్ని పొందుతుంది.",
              "or": "ନିମ୍ନଲିଖିତ କିଛି ସେମିଷ୍ଟାରରେ, ସେ ନିଜ ପାଇଁ ଠିଆ ହେବାର ଆତ୍ମବିଶ୍ୱାସ ଲାଭ କରନ୍ତି |",
              "as": "পিছৰ কেইটামান ছেমিষ্টাৰত, তাই নিজৰ বাবে থিয় হোৱাৰ আত্মবিশ্বাস লাভ কৰে।",
              "gu": "નીચેના થોડા સેમેસ્ટરમાં, તેને પોતાને માટે ઊભા રહેવાનો આત્મવિશ્વાસ મેળવ્યો."
            }
          }
        ]
      },
      "scene37": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_1_1",
              "hi": "B6_1_1",
              "ka": "B6_1_1",
              "te": "B6_1_1",
              "or": "B6_1_1",
              "as": "B6_1_1",
              "gu": "B6_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The 5th year of college is one of the most memorable experiences for Mira. They intern at a hospital in different departments.",
              "hi": "कॉलेज का पांचवां वर्ष मीरा के लिए सबसे यादगार अनुभवों में से एक है। वे विभिन्न विभागों के एक अस्पताल में प्रशिक्षण प्राप्त करते हैं।",
              "ka": "ಮೀರಾಗೆ ಕಾಲೇಜಿನ 5ನೇ ವರ್ಷವು ಒಂದು ಅವಿಸ್ಮರಣೀಯ ಅನುಭವ. ಅವಳು ವಿವಿಧ ವಿಭಾಗಗಳಲ್ಲಿ ಆಸ್ಪತ್ರೆಯಲ್ಲಿ ಇಂಟರ್ನ್ ಮಾಡುತ್ತಾಳೆ.",
              "te": "మీరాకు కాలేజీలో 5వ సంవత్సరం మర్చిపోలేని అనుభవాలలో ఒకటి. వారు ఆసుపత్రిలో వివిద డిపార్ట్ మెంట్స్ లో ఇంటర్న్ చేస్తారు.",
              "or": "କଲେଜର ୫ମ ବର୍ଷ ହେଉଛି ମୀରା ପାଇଁ ସ୍ମରଣୀୟ ଅନୁଭୂତି | ସେମାନେ ବିଭିନ୍ନ ବିଭାଗର\n  ଏକ ଡାକ୍ତରଖାନାରେ ଇଣ୍ଟରନେଟ୍ କରନ୍ତି |",
              "as": "মহাবিদ্যালয়ৰ ৫ম বৰ্ষটো মীৰাৰ বাবে আটাইতকৈ স্মৰণীয় অভিজ্ঞতা। তেওঁলোকে বিভিন্ন বিভাগৰ এখন চিকিৎসালয়ত ইণ্টাৰ্ন কৰে।",
              "gu": "કોલેજનું 5મું વર્ષ મીરા માટે સૌથી યાદગાર અનુભવોમાંથી એક છે. તેઓ અલગ-અલગ વિભાગોમાં હોસ્પિટલમાં ઈન્ટર્ન કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_1_2",
              "hi": "B6_1_2",
              "ka": "B6_1_2",
              "te": "B6_1_2",
              "or": "B6_1_2",
              "as": "B6_1_2",
              "gu": "B6_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They spend one month in each department and learn about surgery, gynaecology, pathology, among others.",
              "hi": "वे प्रत्येक विभाग में एक महीना बिताते हैं और दूसरों के साथ सर्जरी, स्त्री रोग, पैथोलॉजी के बारे में जानते हैं।",
              "ka": "ಅವಳು ಪ್ರತಿ ವಿಭಾಗದಲ್ಲಿ ಒಂದೊಂದು ತಿಂಗಳು ಕಳೆಯುತ್ತಾಳೆ ಮತ್ತು ಶಸ್ತ್ರಚಿಕಿತ್ಸೆ, ಸ್ತ್ರೀರೋಗ ಶಾಸ್ತ್ರ, ರೋಗಶಾಸ್ತ್ರ ಇತ್ಯಾದಿಗಳ ಬಗ್ಗೆ ಕಲಿಯುತ್ತಾಳೆ.",
              "te": "ఒక్కో విభాగంలో ఒక్కో నెలరోజులు ఉండి సర్జరీ, గైనకాలజీ, పాథాలజీ తదితర విషయాలను నేర్చుకుంటారు.",
              "or": "ସେମାନେ ପ୍ରତ୍ୟେକ ବିଭାଗରେ ଗୋଟିଏ ମାସ ବିତାନ୍ତି ଏବଂ ଅନ୍ୟମାନଙ୍କ ମଧ୍ୟରେ \n ଅସ୍ତ୍ରୋପଚାର, ସ୍ତ୍ରୀ ରୋଗ, ପାଥୋଲୋଜି ବିଷୟରେ ଜାଣନ୍ତି |",
              "as": "তেওঁলোকে প্ৰতিটো বিভাগত এমাহ কাম কৰে আৰু অস্ত্ৰোপচাৰ, স্ত্ৰীৰোগ, ৰোগ বিজ্ঞান, আনবোৰৰ বিষয়ে শিকে।",
              "gu": "તેઓ દરેક વિભાગમાં એક મહિનો વિતાવે છે અને સર્જરી, ગાયનેકોલોજી, પેથોલોજી વગેરે વિશે શીખે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_1_3",
              "hi": "B6_1_3",
              "ka": "B6_1_3",
              "te": "B6_1_3",
              "or": "B6_1_3",
              "as": "B6_1_3",
              "gu": "B6_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Their duty hours are very long, and they hardly sleep, but they all have a lot of fun learning and assisting senior doctors.",
              "hi": "उनके कर्तव्य घंटे बहुत लंबे हैं, और वे शायद ही सोते हैं, लेकिन उन सभी में बहुत रोचक सीखना और वरिष्ठ डॉक्टरों की सहायता करना है।",
              "ka": "ಅವಳ ಕರ್ತವ್ಯದ ಸಮಯವು ತುಂಬಾ ಉದ್ದವಾಗಿರುತ್ತದೆ, ಮತ್ತು ಅವಳು ಅಷ್ಟೇನೂ ನಿದ್ರಿಸಲು ಆಗುವುದಿಲ್ಲ, ಆದರೆ ಅವರೆಲ್ಲರೂ ಹಿರಿಯ ವೈದ್ಯರಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತಾ ಕಲಿಯುವಿಕೆಯಲ್ಲಿ ಬಹಳಷ್ಟು ವಿನೋದವನ್ನು ಹೊಂದುತ್ತಾರೆ.",
              "te": "వారి డ్యూటీ గంటలు చాలా ఎక్కువగా ఉంటాయి. వారు నిద్రపోరు కానీ వారందరికీ నేర్చుకోవడం మరియు సీనియర్ వైద్యులకు సహాయం చాలా సరదాగా ఉంటుంది.",
              "or": "ସେମାନଙ୍କର ଡ୍ୟୁଟି ସମୟ ବହୁତ ଲମ୍ବା, ଏବଂ ସେମାନେ ଶୋଇପାରୁନାହାଁନ୍ତି, କିନ୍ତୁ\n  ସମସ୍ତେ ବରିଷ୍ଠ ଡାକ୍ତରମାନଙ୍କୁ ଶିକ୍ଷା ଏବଂ ସାହାଯ୍ୟ କରିବାରେ ବହୁତ ମଜା କରନ୍ତି |",
              "as": "তেওঁলোকৰ কৰ্তব্যৰ সময় অতি দীঘল, আৰু তেওঁলোকে খুব কমেই টোপনি যায়, কিন্তু তেওঁলোক সকলোৱে জ্যেষ্ঠ চিকিৎসকসকলক শিকা আৰু সহায় কৰিবলৈ বহুত আনন্দ পায়।",
              "gu": "તેમની ફરજ બજાવવાનો સમય ઘણો લાંબો છે, અને તેઓ ભાગ્યે જ ઊંઘે છે, પરંતુ તેઓ બધાને ઉપરી ડૉક્ટરોને શીખવામાં અને મદદ કરવામાં ખૂબ મજા આવે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_2_1",
              "hi": "B6_2_1",
              "ka": "B6_2_1",
              "te": "B6_2_1",
              "or": "B6_2_1",
              "as": "B6_2_1",
              "gu": "B6_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The experience teaches them what they like and understand what specialisation they want to do in the future.",
              "hi": "अनुभव उन्हें सिखाता है कि वे क्या पसंद करते हैं और समझते हैं कि भविष्य में वे किस विशेषज्ञता को प्राप्त करना चाहते हैं।",
              "ka": "ಅವರು ಇಷ್ಟಪಡುವದನ್ನೇ ಅನುಭವವು ಕಲಿಸುತ್ತದೆ ಮತ್ತು ಭವಿಷ್ಯದಲ್ಲಿ ಅವರು ಯಾವ ವಿಶೇಷತೆಯನ್ನು ಮಾಡಲು ಬಯಸುತ್ತಾರೆ ಎನ್ನುವುದನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "అనుభవం వారు ఇష్టపడే వాటిని నేర్పుతుంది మరియు భవిష్యత్తులో వారు ఏ స్పెషలైజేషన్ చేయాలనుకుంటున్నారో అర్థం చేసుకుంటారు.",
              "or": "ଅଭିଜ୍ଞତା ସେମାନଙ୍କୁ ଶିଖାଏ ଯାହା ସେମାନେ ପସନ୍ଦ କରନ୍ତି ଏବଂ ଭବିଷ୍ୟତରେ \n ସେମାନେ କେଉଁ ବିଶେଷତା କରିବାକୁ ଚାହୁଁଛନ୍ତି ତାହା ବୁଝିପାରନ୍ତି |",
              "as": "অভিজ্ঞতাই সিহঁতক সিহঁতে কি ভাল পায় শিকায় আৰু বুজি পায় যে তেওঁলোকে ভৱিষ্যতে কি বিশেষত্ব কৰিব বিচাৰে।",
              "gu": "અનુભવ તેમને શીખવે છે કે તેઓ શું પસંદ કરે છે અને સમજે છે કે તેઓ ભવિષ્યમાં કઈ વિશેષતા કરવા માગે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_2_2",
              "hi": "B6_2_2",
              "ka": "B6_2_2",
              "te": "B6_2_2",
              "or": "B6_2_2",
              "as": "B6_2_2",
              "gu": "B6_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "In one of the departments, Mira is assigned to a senior doctor, Suhail. He helps Mira map her future career options.",
              "hi": "विभागों में से एक में, मीरा को एक वरिष्ठ डॉक्टर, सुहेल के साथ नियुक्त किया गया है। वह मीरा को अपने भविष्य के कैरियर विकल्पों को मैप करने में सहायता करता है।",
              "ka": "ವಿಭಾಗವೊಂದರಲ್ಲಿ, ಮೀರಾಳನ್ನು  ಅವಳ ಹಿರಿಯ ವೈದ್ಯರಾದ ಸುಹೇಲ್‌ಗೆ ನಿಯೋಜಿಸಲಾಗಿದೆ. ಅವರು ಮೀರಾ ಅವರ ಭವಿಷ್ಯದ ವೃತ್ತಿ ಆಯ್ಕೆಗಳನ್ನು ನಕ್ಷೆ ಮಾಡಿಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತಾರೆ.",
              "te": "డిపార్ట్‌మెంట్‌ లలో ఒకదానిలో, మీరా సీనియర్ వైద్యుడు సుహైల్‌కి కేటాయించబడింది. అతను మీరాకు తన భవిష్యత్ కెరీర్ ఆప్షన్స్ మ్యాప్ చేయడంలో సహాయం చేస్తాడు.",
              "or": "ଗୋଟିଏ ବିଭାଗରେ ମୀରାଙ୍କୁ ଜଣେ ବରିଷ୍ଠ ଡାକ୍ତର ସୁହେଲଙ୍କୁ ନିଯୁକ୍ତ କରାଯାଇଛି। \n ସେ ମୀରାଙ୍କୁ ତାଙ୍କ ଭବିଷ୍ୟତର କ୍ୟାରିୟର ବିକଳ୍ପ ଦିଗରେ ସାହାଯ୍ୟ କରନ୍ତି |",
              "as": "এটা বিভাগত, মীৰাক এজন জ্যেষ্ঠ চিকিৎসক সুহাইলক তলত নিযুক্ত কৰা হয়। তেওঁ মীৰাক তাইৰ ভৱিষ্যতৰ কেৰিয়াৰৰ বিকল্পবোৰ মেপ কৰাত সহায় কৰে।",
              "gu": "એક વિભાગમાં, મીરાને એક વરિષ્ઠ ડૉક્ટર, સુહેલને સોંપવામાં આવે છે. તે મીરાને તેના ભાવિ કારકિર્દી વિકલ્પોનો નકશો બનાવવામાં મદદ કરે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "Career map",
              "hi": "कैरियर मैप ",
              "ka": "ವೃತ್ತಿ ನಕ್ಷೆ",
              "te": "కెరీర్ మ్యాప్",
              "or": "ବୃତ୍ତି ର ଦିଗ",
              "as": "কেৰিয়াৰ মানচিত্ৰ",
              "gu": "કારકિર્દી નકશો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Mira tells Dr Suhail that she is interested in diagnostic medicine, and he makes a journey map for her.",
              "hi": "मीरा डॉ सुहेल को बताती है कि वह डाइगनोस्टिक ​​मेडिसिन में रुचि रखती है, और वह उसके लिए एक जर्नी मैप बनाते हैं।",
              "ka": "ತನಗೆ ರೋಗನಿರ್ಣಯದ ಔಷಧದಲ್ಲಿ ಆಸಕ್ತಿ ಇದೆ ಎಂದು ಮೀರಾ ಡಾ ಸುಹೇಲ್‌ಗೆ ಹೇಳುತ್ತಾಳೆ ಮತ್ತು ಅವರು ಅವಳಿಗಾಗಿ ಮುಂದಿನ ನಕ್ಷೆಯನ್ನು ತಯಾರಿಸುತ್ತಾರೆ.",
              "te": "మీరా డాక్టర్ సుహైల్‌కి డయాగ్నస్టిక్ మెడిసిన్ పట్ల ఆసక్తి ఉందని చెబుతుంది మరియు అతను ఆమె కోసం ఒక ప్రయాణ మ్యాప్‌ను తయారు చేశాడు.",
              "or": "ମୀରା ଡ Dr ସୁହେଲଙ୍କୁ କହିଛନ୍ତି ଯେ ସେ ଡାଇଗ୍ନୋଷ୍ଟିକ୍ ମେଡିସିନ୍ ପାଇଁ ଆଗ୍ରହୀ,\n  ଏବଂ ସେ ତାଙ୍କ ପାଇଁ ଏକ ଯାତ୍ରାର ଦିଗ ପ୍ରସ୍ତୁତ କରନ୍ତି |",
              "as": "মীৰাই ডাঃ সুহাইলক কয় যে তেওঁ ডায়গনষ্টিক ঔষধৰ প্ৰতি আগ্ৰহী, আৰু তেওঁ তাইৰ বাবে এটা যাত্ৰাৰ মানচিত্ৰ প্ৰস্তুত কৰে।",
              "gu": "મીરા ડૉ. સુહેલને કહે છે કે તેને ડાયગ્નોસ્ટિક દવામાં રસ છે અને તે તેના માટે પ્રવાસનો નકશો બનાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "MBBS doctor has 2 options. Either work at a public health center or at a clinic",
              "hi": "एम बी बी एस डॉक्टर के पास 2 विकल्प हैं। या तो वे सार्वजनिक स्वास्थ्य केंद्र में या क्लिनिक में काम करते हैं",
              "ka": "ಎಂಬಿಬಿಎಸ್ ವೈದ್ಯರಿಗೆ 2 ಆಯ್ಕೆಗಳಿವೆ. ಒಂದೋ ಸಾರ್ವಜನಿಕ ಆರೋಗ್ಯ ಕೇಂದ್ರದಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದು ಅಥವಾ ಕ್ಲಿನಿಕ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದು.",
              "te": "MBBS వైద్యుడికి 2 ఎంపికలు ఉన్నాయి. పబ్లిక్ హెల్త్ సెంటర్‌లో లేదా క్లినిక్‌లో పని చేయడం",
              "or": "MBBS ଡାକ୍ତରଙ୍କର 2 ଟି ବିକଳ୍ପ ଅଛି | ଜନସ୍ୱାସ୍ଥ୍ୟ କେନ୍ଦ୍ରରେ କିମ୍ବା କ୍ଲିନିକରେ କାମ କରନ୍ତୁ |",
              "as": "এম.বি.বি.এছ. চিকিৎসকৰ ওচৰত 2 টা বিকল্প আছে। হয় ৰাজহুৱা স্বাস্থ্য কেন্দ্ৰত বা ক্লিনিকত কাম কৰক",
              "gu": "MBBS ડૉક્ટર પાસે 2 વિકલ્પો છે. કાં તો સાર્વજનિક આરોગ્ય કેન્દ્રમાં અથવા દવાખાનામાં કામ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "You can also get trained, study further become a Specialised Doctor",
              "hi": "आप भी प्रशिक्षित हो सकती हैं, आगे अध्ययन करें और एक विशेषज्ञ डॉक्टर बनें",
              "ka": "ನೀನು ಕೂಡ ತರಬೇತಿ ಪಡೆಯಬಹುದು, ಮುಂದೆ ಅಧ್ಯಯನ ಮಾಡಿ ವಿಶೇಷ ವೈದ್ಯಳಾಗಬಹುದು.",
              "te": "మీరు శిక్షణ కూడా పొందవచ్చు, మరింత అధ్యయనం చేసి స్పెషలైజ్డ్ డాక్టర్ గా అవ్వవచ్చు.",
              "or": "ଆପଣ ମଧ୍ୟ ତାଲିମ ପ୍ରାପ୍ତ ହୋଇପାରିବେ, ଅଧ୍ୟୟନ ଏକ ବିଶେଷଜ୍ଞ ଡାକ୍ତର ହୋଇପାରିବେ |",
              "as": "তুমি আৰু অধ্যয়ন কৰিব পাৰে আৰু এজন বিশেষচিকিৎসক হ'ব পাৰে",
              "gu": "તું તાલીમ પણ મેળવી શકે છે, આગળ અભ્યાસ કરીને વિશેષ ડૉક્ટર બની શકે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "After your specialisation, you can do your Super Specialisation or become a Practicing Doctor",
              "hi": "आपकी विशेषज्ञता के बाद, आप अपनी सुपर विशेषज्ञता कर सकती  हैं या एक सामान्य चिकित्सक बन सकती हैं",
              "ka": "ನೀವು ವಿಶೇಷತೆಯನ್ನು ಪಡೆದ ನಂತರ, ನಿಮ್ಮ ಸೂಪರ್ ಸ್ಪೆಷಲೈಸೇಶನ್ ಅನ್ನು ಮಾಡಬಹುದು ಅಥವಾ ಅಭ್ಯಾಸ ಮಾಡುವ ವೈದ್ಯರಾಗಬಹುದು.",
              "te": "మీ స్పెషలైజేషన్ తర్వాత మీరు మీ సూపర్ స్పెషలైజేషన్ చేయవచ్చు లేదా ప్రాక్టీసింగ్ డాక్టర్ గా అవ్వవచ్చు.",
              "or": "ତୁମର ବିଶେଷଜ୍ଞତା ପରେ, ତୁମେ ତୁମର ସୁପର ସ୍ପେସିଆଲାଇଜେସନ୍ କରିପାରିବ \n କିମ୍ବା ଅଭ୍ୟାସକାରୀ ଡାକ୍ତର ହୋଇପାରିବ |",
              "as": "আপোনাৰ বিশেষত্বৰ পিছত, আপুনি আপোনাৰ চুপাৰ স্পেচিয়েলাইজেচন কৰিব পাৰে বা এজন কাম কৰি থকা চিকিৎসক হ'ব পাৰে",
              "gu": "તારી વિશેષતા પછી, તું તારું ઉત્તમ વિશેષતા કરી શકે છે અથવા પ્રેક્ટિસિંગ ડૉક્ટર બની શકો છો"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "career journey map",
              "hi": "कैरियर यात्रा मैप ",
              "ka": "ವೃತ್ತಿ ಪಯಣದ ಮ್ಯಾಪ್ ",
              "te": "కెరీర్ ప్రయాణం మ్యాప్",
              "or": "ବୃତ୍ତି ର ଦିଗ",
              "as": "কেৰিয়াৰ যাত্ৰাৰ মানচিত্ৰ",
              "gu": "કારકિર્દી પ્રવાસ નકશો"
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_2_4 bg",
              "hi": "B6_2_4 बीजी",
              "ka": "B6_2_4 bg",
              "te": "B6_2_4 bg",
              "or": "B6_2_4 bg",
              "as": "B6_2_4 bg",
              "gu": "B6_2_4 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Dr Suhail",
              "hi": "डॉ सुहेल",
              "ka": "ಡಾ. ಸುಹೈಲ್",
              "te": "డాక్టర్ సుహైల్",
              "or": "ଡାଃ ସୁହେଲ",
              "as": "ডাঃ সুহাইল",
              "gu": "ડૉ સુહેલ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "This feels never-ending. It is such a long journey for doctors!",
              "hi": "यह कभी समाप्त न होने वाली लगती है। यह डॉक्टरों के लिए बहुत लंबी यात्रा है!",
              "ka": "ಇದು ಮುಗಿಯುವ ಹಾಗೆ ಕಾಣುವುದಿಲ್ಲ. ಡಾಕ್ಟರುಗಳಿಗೆ ಇದು ಒಂದು ಧೀರ್ಘ ಪಯಣ!",
              "te": "ఇది ఎప్పటికీ పూర్తి కాదని అనిపిస్తుంది. వైద్యులకు ఇది చాలా సుదీర్ఘ ప్రయాణం!",
              "or": "ଏହା କଦାପି ଶେଷ ହୁଏ ନାହିଁ | ଡାକ୍ତରଙ୍କ ପାଇଁ ଏହା ଏକ ଦୀର୍ଘ ଯାତ୍ରା!",
              "as": "ই কেতিয়াও শেষ নহ'ব যেন লাগিছে। এইটো চিকিৎসকসকলৰ বাবে ইমান দীঘলীয়া যাত্ৰা!",
              "gu": "આ ક્યારેય અંત ન અનુભવે છે. ડોકટરો માટે આટલી લાંબી મુસાફરી છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "It is a difficult path for doctors. The post-graduation exams are way more challenging than the first entrance you gave. The seats are limited, and the competition is extremely high.",
              "hi": "डॉक्टरों के लिए यह एक कठिन रास्ता है। स्नातकोत्तर परीक्षाएं आपके द्वारा दिए गए पहली प्रवेश परीक्षा की तुलना में अधिक चुनौतीपूर्ण हैं। सीट सीमित हैं, और प्रतिस्पर्धा बेहद अधिक है।",
              "ka": "ವೈದ್ಯರಿಗೆ ಇದು ಕಷ್ಟಕರವಾದ ಮಾರ್ಗವಾಗಿರುತ್ತದೆ. ನೀವು ನೀಡಿದ ಮೊದಲ ಪ್ರವೇಶಕ್ಕಿಂತ ಸ್ನಾತಕೋತ್ತರ ಪರೀಕ್ಷೆಗಳು ಹೆಚ್ಚು ಸವಾಲನ್ನು ಒಡ್ಡುತ್ತವೆ.. ಆಸನಗಳು ಸೀಮಿತವಾಗಿರುತ್ತವೆ ಮತ್ತು ಸ್ಪರ್ಧೆಯು ತುಂಬಾ ಹೆಚ್ಚಾಗಿರೂತ್ತದೆ.",
              "te": "వైద్యులకు ఇది కష్టమైన మార్గం. మీరు ఇచ్చిన మొదటి ప్రవేశం కంటే పోస్ట్-గ్రాడ్యుయేషన్ పరీక్షలు చాలా సవాలుగా ఉన్నాయి. సీట్లు తక్కువుగా మరియు పోటీ చాలా ఎక్కువగా ఉంది.",
              "or": "ଡାକ୍ତରଙ୍କ ପାଇଁ ଏହା ଏକ କଷ୍ଟକର ରାସ୍ତା। ସ୍ନାତକୋତ୍ତର ପରୀକ୍ଷଣଗୁଡିକ ଆପଣ\n  ଦେଇଥିବା ପ୍ରଥମ ପ୍ରବେଶ ପଥ ଅପେକ୍ଷା ଅଧିକ ଚ୍ୟାଲେଞ୍ଜିଙ୍ଗ୍ | ଆସନଗୁଡ଼ିକ ସୀମିତ ଏବଂ ପ୍ରତିଯୋଗିତା ଅତ୍ୟନ୍ତ ଉଚ୍ଚ ଅଟେ |",
              "as": "এইটো চিকিৎসকসকলৰ কাৰনে টান পথ। স্নাতকোত্তৰ পৰীক্ষাবোৰ তুমি দিয়া প্ৰথম প্ৰৱেশ পৰীক্ষাতকৈ অধিক কঠিন। আসনবোৰ কম, আৰু প্ৰতিযোগিতা বহুত উচ্চ।",
              "gu": "ડોકટરો માટે તે મુશ્કેલ માર્ગ છે. પોસ્ટ-ગ્રેજ્યુએશન પરીક્ષાઓ તું આપેલ પ્રથમ પ્રવેશ કરતાં ઘણી વધુ પડકારરૂપ છે. બેઠકો મર્યાદિત છે, અને સ્પર્ધા અત્યંત ઊંચી છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_2_5///",
              "hi": "B6_2_5///",
              "ka": "B6_2_5///",
              "te": "B6_2_5///",
              "or": "B6_2_5///",
              "as": "B6_2_5///",
              "gu": "B6_2_5///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Yes, that's what everyone else keeps saying. Meanwhile, my friend will be finishing her Master's in Botany and start working at a company, and here I am, still planning my Master's.",
              "hi": "हां, यही बात हर कोई कहता रहता है। इस बीच, मेरी मित्र वनस्पति विज्ञान में स्नातकोत्तर कोर्स खत्म कर चुकी होगी और एक कंपनी में काम करना शुरू कर देगी, और यहां मैं अपने स्नातकोत्तर की योजना ही बना रही हूं।",
              "ka": "ಹೌದು, ಅದನ್ನೇ ಎಲ್ಲರೂ ಹೇಳುತ್ತಲೇ ಇರುತ್ತಾರೆ. ಏತನ್ಮಧ್ಯೆ, ನನ್ನ ಸ್ನೇಹಿತೆ ಸಸ್ಯಶಾಸ್ತ್ರದಲ್ಲಿ ತನ್ನ ಸ್ನಾತಕೋತ್ತರ ಪದವಿಯನ್ನು ಮುಗಿಸಿದ್ದಾಳೆ ಮತ್ತು ಕಂಪನಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿದ್ದಾಳೆ ಮತ್ತು ನಾನು ಇಲ್ಲಿದ್ದೇನೆ, ಇನ್ನೂ ನನ್ನ ಮಾಸ್ಟರ್ಸ್ ಬಗ್ಗೆ ಯೋಚಿಸುತ್ತಿದ್ದೇನೆ.",
              "te": "అవును, అందరూ చెప్పేది అదే. ఇప్పుడు నా స్నేహితురాలు వృక్షశాస్త్రంలో మాస్టర్స్ పూర్తి చేసి ఒక కంపెనీలో పని చేయడం ప్రారంభిస్తుంది మరియు నేను ఇక్కడ ఉన్నాను. నేను ఇప్పటికీ నా మాస్టర్స్ ప్లాన్ చేస్తున్నాను.",
              "or": "ହଁ, ତାହା ଅନ୍ୟ ସମସ୍ତେ କହୁଛନ୍ତି | ଏହି ସମୟରେ, ମୋର ବନ୍ଧୁ ଉଦ୍ଭିଦ ବିଜ୍ଞାନରେ\n  ମାଷ୍ଟର ସମାପ୍ତ କରିବେ ଏବଂ ଏକ କମ୍ପାନୀରେ କାମ କରିବା ଆରମ୍ଭ କରିବେ, ଏବଂ ମୁଁ ଏଠାରେ ଅଛି, ତଥାପି ମୋର ମାଷ୍ଟର ଯୋଜନା କରୁଛି |",
              "as": "হয়, বাকী সকলোৱে এইটোৱেই কৈ থাকে। ইফালে, মোৰ বন্ধুৱে উদ্ভিদবিজ্ঞানত স্নাতকোত্তৰ শেষ কৰিব আৰু এটা কোম্পানীত কাম কৰা আৰম্ভ কৰিব, আৰু ইয়াত মই এতিয়াও মোৰ মাষ্টাৰৰ পৰিকল্পনা কৰি আছোঁ।",
              "gu": "હા, બીજા બધા એ જ કહેતા રહે છે. તે દરમિયાન, મારી મિત્ર વનસ્પતિશાસ્ત્રમાં તેનું ઉચ્ચ સ્નાતક પૂર્ણ કરશે અને કંપનીમાં કામ કરવાનું શરૂ કરશે, અને હું અહીં છું, હજુ પણ મારા માસ્ટર્સનું આયોજન કરી રહ્યો છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Mira, the journey for doctors is very different. You can't compare it to any other field. Are you planning to give your post-graduation exam this year?",
              "hi": "मीरा, डॉक्टरों की यात्रा बहुत अलग है। आप इसकी तुलना किसी अन्य क्षेत्र से नहीं कर सकती हैं। क्या आप इस साल अपनी स्नातकोत्तर परीक्षा देने की योजना बना रही हैं?",
              "ka": "ಮೀರಾ, ವೈದ್ಯರ ಪ್ರಯಾಣವು ತುಂಬಾ ವಿಭಿನ್ನವಾಗಿರುತ್ತದೆ. ಇದನ್ನು ಬೇರೆ ಯಾವುದೇ ಕ್ಷೇತ್ರಕ್ಕೆ ಹೋಲಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ. ಈ ವರ್ಷ ಸ್ನಾತಕೋತ್ತರ ಪರೀಕ್ಷೆಯನ್ನು ನೀಡಲು ನೀವು ಯೋಚಿಸುತ್ತಿದ್ದೀರಾ?",
              "te": "మీరా, వైద్యుల ప్రయాణం చాలా వేరుగా ఉంటుంది. మీరు దానిని మరే ఇతర రంగంతో పోల్చలేరు. మీరు ఈ సంవత్సరం మీ పోస్ట్-గ్రాడ్యుయేషన్ పరీక్షను ఇవ్వాలనుకుంటున్నారా?",
              "or": "ମୀରା, ଡାକ୍ତରଙ୍କ ପାଇଁ ଯାତ୍ରା ବହୁତ ଅଲଗା | ଆପଣ ଏହାକୁ ଅନ୍ୟ କୌଣସି କ୍ଷେତ୍ର\n  ସହିତ ତୁଳନା କରିପାରିବେ ନାହିଁ | ଆପଣ ଏହି ବର୍ଷ ସ୍ନାତକୋତ୍ତର ପରୀକ୍ଷା ଦେବାକୁ ଯୋଜନା କରୁଛନ୍ତି କି?",
              "as": "মীৰা, চিকিৎসকসকলৰ বাবে যাত্ৰা বহুত বেলেগ। ইয়াক আন কোনো বেলেগৰ সৈতে তুলনা কৰিব নোৱাৰা। তুমি এই বছৰ আপোনাৰ স্নাতকোত্তৰ পৰীক্ষা দিয়াৰ পৰিকল্পনা কৰিছে নেকি?",
              "gu": "મીરા, ડોકટરોની સફર ઘણી અલગ છે. તું તેની તુલના અન્ય કોઈ ક્ષેત્ર સાથે કરી શકટી નથી. શું તું આ વર્ષે તારી અનુસ્નાતકની પરીક્ષા આપવાનું વિચારી રહી છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "No, Sir, not this year. With such long hours, we hardly get time to study. I haven't got a chance to prepare.",
              "hi": "नहीं, महोदय, इस साल नहीं। ऐसे लम्बे घंटों के काम के साथ, हमें शायद ही अध्ययन के लिए समय मिलता है। मुझे तैयारी करने का मौका नहीं मिलता है।",
              "ka": "ಇಲ್ಲ ಸರ್, ಈ ವರ್ಷ ಅಲ್ಲ. ಇಷ್ಟು ದೀರ್ಘಾವಧಿಯ ಕೆಲಸವನ್ನು ಮಾಡಿಕೊಂಡು ನನಗೆ ಅಧ್ಯಯನ ಮಾಡಲು ಸಮಯ ಸಿಗುತ್ತಿಲ್ಲ. ತಯಾರಾಗಲು ನನಗೆ ಅವಕಾಶ ಸಿಗುತ್ತಿಲ್ಲ.",
              "te": "లేదు సార్ ఈ సంవత్సరం కాదు. ఇంత ఎక్కువ గంటలు ఉండడం వల్ల మనకు చదువుకోవడానికి సమయం దొరకదు. నాకు ప్రిపేర్ అయ్యే అవకాశం లేదు.",
              "or": "ନା, ସାର୍, ଏହି ବର୍ଷ ନୁହେଁ | ଏତେ ଲମ୍ବା ସମୟ ସହିତ, ଆମେ ଅଧ୍ୟୟନ ପାଇଁ \n ସମୟ ପାଇପାରୁ ନାହିଁ | ମୋର ପ୍ରସ୍ତୁତି କରିବାର ସୁଯୋଗ ନାହିଁ |",
              "as": "নহয়, ছাৰ, এই বছৰ নহয়। ইমান দীঘলীয়া সময় কাম কৰি, আমি পঢ়িবলৈ খুব কমেই সময় পাওঁ। মই প্ৰস্তুতি কৰাৰ সুযোগ পোৱা নাই।",
              "gu": "ના, સાહેબ, આ વર્ષે નહીં. આટલા લાંબા કલાકો સાથે, અમને અભ્યાસ માટે ભાગ્યે જ સમય મળે છે. મને તૈયારી કરવાની તક મળી નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Yes, the same happened to me. I took a drop year and then appeared for it. Luckily I got admission on the first attempt.",
              "hi": "हाँ, वही मेरे साथ हुआ। मैंने एक वर्ष ड्राप किया और फिर यह परीक्षा दी। सौभाग्य से मुझे पहले प्रयास में प्रवेश मिल गया।",
              "ka": "ಹೌದು, ನನಗೂ ಅದೇ ಆಯಿತು. ನಾನು ಒಂದು ವರ್ಷಡ್ರಾಪ್ ತೆಗೆದುಕೊಂಡೆ ಮತ್ತು ನಂತರ ಅದಕ್ಕಾಗಿ ಪ್ರಯತ್ನಿಸಿದೆ. ಅದೃಷ್ಟವಶಾತ್ ಮೊದಲ ಪ್ರಯತ್ನದಲ್ಲೇ ಪ್ರವೇಶ ಸಿಕ್ಕಿತು.",
              "te": "అవును, నాకు అదే జరిగింది. నేను ఒక సంవత్సరం డ్రాప్ తీసుకున్నాను మరియు దాని కోసం కృషిచేశాను. అదృష్టవశాత్తూ మొదటి ప్రయత్నంలోనే సెలెక్ట్ అయ్యాను.",
              "or": "ହଁ, ମୋ ସହିତ ମଧ୍ୟ ସମାନ ଘଟଣା ଘଟିଲା | ମୁଁ ଏକ ଡ୍ରପ୍ ବର୍ଷ ନେଇଥିଲି ଏବଂ \n ତା’ପରେ ଏହା ପାଇଁ ଦେଖା ଦେଲି | ସୌଭାଗ୍ୟବଶତ I ମୁଁ ପ୍ରଥମ ପ୍ରୟାସରେ ଆଡମିଶନ ପାଇଲି |",
              "as": "হয়, মোৰ ক্ষেত্ৰতো একেই ঘটিছিল। মই এবছৰ ড্ৰপ লৈছিলো আৰু তাৰ পিছত পৰীক্ষাত বহিছিলোঁ সৌভাগ্যক্ৰমে মই প্ৰথম প্ৰচেষ্টাত নামভৰ্তি পাইছিলো।",
              "gu": "હા, મારી સાથે પણ એવું જ થયું. મેં એક વર્ષ ખાલી જવા દીધો અને પછી તે મારા માટે સારું દેખાયું. નસીબજોગે મને પ્રથમ પ્રયાસમાં જ પ્રવેશ મળી ગયો."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_1_4",
              "hi": "B6_1_4",
              "ka": "B6_1_4",
              "te": "B6_1_4",
              "or": "B6_1_4",
              "as": "B6_1_4",
              "gu": "B6_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The year-long internship is coming to an end. Mira is looking forward to her graduation ceremony, and she is finally going to be a Practicing doctor.",
              "hi": "एक साल की इंटर्नशिप खत्म हो रही है। मीरा अपने स्नातक समारोह की उम्मीद कर रही है, और अंततः वह एक प्रोफेशनल डॉक्टर बनने जा रही है।",
              "ka": "ವರ್ಷಪೂರ್ತಿ ಇಂಟರ್ನ್‌ಶಿಪ್ ಮುಗಿಯಲು ಬರುತ್ತಿದೆ. ಮೀರಾ ತನ್ನ ಪದವಿ ಸಮಾರಂಭಕ್ಕಾಗಿ ಎದುರು ನೋಡುತ್ತಿದ್ದಾಳೆ ಹಾಗೂ ಅವಳು ಅಂತಿಮವಾಗಿ ಅಭ್ಯಾಸ ಮಾಡುವ ವೈದ್ಯೆಯಾಗಲಿದ್ದಾಳೆ.",
              "te": "ఏడాది పాటు సాగుతున్న ఇంటర్న్ షిప్ ముగింపు దశకు చేరుకుంది. మీరా తన గ్రాడ్యుయేషన్ వేడుక కోసం ఎదురుచూస్తోంది, చివరకు ఆమె ప్రాక్టీసింగ్ డాక్టర్ పూర్తి కాబోతుంది.",
              "or": "ବର୍ଷସାରା ଇଣ୍ଟର୍ନସିପ୍ ଶେଷ ହେବାକୁ ଯାଉଛି | ମୀରା ସ୍ନାତକୋତ୍ତର ସମାରୋହକୁ \n ଅପେକ୍ଷା କରିଛନ୍ତି ଏବଂ ଶେଷରେ ସେ ଜଣେ ଅଭ୍ୟାସକାରୀ ଡାକ୍ତର ହେବାକୁ ଯାଉଛନ୍ତି।",
              "as": "বছৰজোৰা ইন্টাৰ্ণশ্বিপৰ সমাপ্তি হল। মীৰাই তাইৰ স্নাতক সমাৰোহৰ বাবে অপেক্ষা কৰি আছে, আৰু তাই অৱশেষত এগৰাকী অনুশীলনী চিকিৎসক হ'বলৈ গৈ আছে।",
              "gu": "વર્ષ-લાંબી ઇન્ટર્નશિપનો અંત આવી રહ્યો છે. મીરા તેના સ્નાતક પદવીના સમારોહની રાહ જોઈ રહી છે, અને તે આખરે પ્રેક્ટિસિંગ ડૉક્ટર બનવા જઈ રહી છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_1_5",
              "hi": "B6_1_5",
              "ka": "B6_1_5",
              "te": "B6_1_5",
              "or": "B6_1_5",
              "as": "B6_1_5",
              "gu": "B6_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira’s parents are coming for her graduation ceremony, and she can’t wait to celebrate with them.",
              "hi": "मीरा के माता-पिता उसके स्नातक समारोह के लिए आ रहे हैं, और वह उनके साथ ख़ुशी मनाने का इंतजार नहीं कर पा रही है।",
              "ka": "ಮೀರಾಳ ಪೋಷಕರು ಅವಳ ಪದವಿ ಸಮಾರಂಭಕ್ಕೆ ಬರುತ್ತಿದ್ದಾರೆ ಮತ್ತು ಅವರೊಂದಿಗೆ ಅದನ್ನು ಆಚರಿಸಲು ಅವಳಿಗೆ ಕಾಯುವುದು ಕಷ್ಟವಾಗುತ್ತಿದೆ.",
              "te": "మీరా తల్లిదండ్రులు ఆమె గ్రాడ్యుయేషన్ వేడుకకు వస్తున్నారు కానీ ఆమె వారితో జరుపుకోవడానికి వేచి ఉండదు.",
              "or": "ମିରାଙ୍କ ପିତାମାତା ତାଙ୍କ ସ୍ନାତକୋତ୍ତର ସମାରୋହ ପାଇଁ ଆସୁଛନ୍ତି, ଏବଂ ସେ ସେମାନଙ୍କ\n  ସହ ଉତ୍ସବ ପାଳନ କରିବାକୁ ଅପେକ୍ଷା କରିପାରିବେ ନାହିଁ |",
              "as": "মীৰাৰ মাক-দেউতাক তাইৰ স্নাতক সমাৰোহৰ কাৰনে আহি আছে, আৰু তাই তেওঁলোকৰ কাৰনে অপেক্ষা কৰিব পৰা নাই।",
              "gu": "મીરાના માતા-પિતા તેના સ્નાતક પદવીદાન સમારોહ માટે આવી રહ્યા છે, અને તે તેમની સાથે ઉજવણી કરવા માટે રાહ જોઈ શકતી નથી."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_3_1",
              "hi": "B6_3_1",
              "ka": "B6_3_1",
              "te": "B6_3_1",
              "or": "B6_3_1",
              "as": "B6_3_1",
              "gu": "B6_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "At the graduation ceremony, her mother and Shantanu come to meet her. They look happy, but Mira senses that something is bothering them.",
              "hi": "स्नातक समारोह में, उसकी मां और शांतनु उससे मिलने आए। वे खुश दिखते हैं, लेकिन मीरा अनुभव करती है कि कोई बात उन्हें परेशान कर रहा है।",
              "ka": "ಪದವಿ ಪ್ರದಾನ ಸಮಾರಂಭದಲ್ಲಿ, ಅವಳ ತಾಯಿ ಮತ್ತು ಶಂತನು ಅವಳನ್ನು ಭೇಟಿಯಾಗಲು ಬರುತ್ತಾರೆ. ಅವರು ಬಹಳ ಸಂತೋಷವಾಗಿ ಇರುತ್ತಾರೆ, ಆದರೆ ಮೀರಾ ಅವರಿಗೆ ಏನೋ ತೊಂದರೆಯಾಗುತ್ತಿದೆ ಎಂದು ಗ್ರಹಿಸುತ್ತಾಳೆ.",
              "te": "గ్రాడ్యుయేషన్ వేడుకలో, ఆమె తల్లి మరియు షాంతాను ఆమెను కలవడానికి వచ్చారు. వారు సంతోషంగా కనిపిస్తున్నారు, కానీ మీరా తమను ఏదో ఇబ్బంది పెడుతుందని పసిగట్టింది.",
              "or": "ସ୍ନାତକୋତ୍ତର ସମାରୋହରେ ତାଙ୍କ ମା ଏବଂ ଶାନ୍ତନୁ ତାଙ୍କୁ ଭେଟିବାକୁ ଆସନ୍ତି | ସେମାନେ\n  ଖୁସି ଦେଖାଯାନ୍ତି, କିନ୍ତୁ ମୀରା ଅନୁଭବ କରନ୍ତି ଯେ କିଛି ସେମାନଙ୍କୁ ହଇରାଣ କରୁଛି |",
              "as": "স্নাতক সমাৰোহত, তাইৰ মাক আৰু শান্তনু তাইক লগ কৰিবলৈ আহে। তেওঁলোকক সুখী যেন লাগিছে, কিন্তু মীৰাই অনুভৱ কৰিছে যে কিবা এটাৱে তেওঁলোকক আমনি কৰিছে।",
              "gu": "પદવીદાન સમારોહમાં, તેની માતા અને શાંતનુ તેને મળવા આવે છે. તેઓ ખુશ દેખાય છે, પરંતુ મીરાને લાગે છે કે કંઈક તેમને પરેશાન કરી રહ્યું છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_3_2bg",
              "hi": "B6_3_2बीजी ",
              "ka": "B6_3_2bg",
              "te": "B6_3_2bg",
              "or": "B6_3_2bg",
              "as": "B6_3_2bg",
              "gu": "B6_3_2bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Mother",
              "hi": "मां",
              "ka": "ಅಮ್ಮ",
              "te": "తల్లి",
              "or": "ମା",
              "as": "মা",
              "gu": "માતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Ma, what happened? You aren’t telling me something.",
              "hi": "मा, क्या हुआ? आप मुझे कुछ नहीं बता रही हैं।",
              "ka": "ಅಮ್ಮ, ಏನಾಯಿತು? ನೀನು ಏನೋ ಹೇಳಕ್ಕೆ ಹೊರಟಿದ್ದೀಯಾ",
              "te": "అమ్మా, ఏమైంది? మీరు నాకు ఏదో చెప్పడం లేదు.",
              "or": "ମା, କ’ଣ ହେଲା? ତୁମେ ମୋତେ କିଛି କହୁ ନାହଁ |",
              "as": "মা, কি হ'ল? তুমি মোক কিবা কোৱা নাই।",
              "gu": "મા, શું થયું? તું મને કંઈક કહેટી નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "It’s nothing, Mira. Today is an important day. I’m so happy to see you graduate, and you are taking your first step into the professional world.",
              "hi": "ऐसा कुछ भी नहीं है, मीरा। आज एक महत्वपूर्ण दिन है। मैं तुमको स्नातक देखकर बहुत खुश हूं, और तुम प्रोफेशनल दुनिया में अपना पहला कदम रख रही हो।",
              "ka": "ಏನಿಲ್ಲ ಮೀರಾ. ಇಂದು ಮಹತ್ವದ ದಿನ. ನೀನು ಪದವೀಧರಳಾಗಿರುವುದನ್ನು ನೋಡಲು ನನಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿದೆ ಮತ್ತು ನೀನು ವೃತ್ತಿಪರ ಜಗತ್ತಿನಲ್ಲಿ ನಿಮ್ಮ ಮೊದಲ ಹೆಜ್ಜೆ ಇಡುತ್ತಿದ್ದೀರಿ",
              "te": "ఏమీ కాదు, మీరా. ఈరోజు ముఖ్యమైన రోజు. మీరు గ్రాడ్యుయేట్‌గా ఉన్నందుకు నేను చాలా సంతోషంగా ఉన్నాను మరియు మీరు వృత్తిపరమైన ప్రపంచంలోకి మీ మొదటి అడుగు వేస్తున్నారు.",
              "or": "ଏହା କିଛି ନୁହେଁ, ମୀରା | ଆଜି ଏକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ଦିନ | ତୁମେ ସ୍ନାତକ ଦେଖି ମୁଁ ବହୁତ\n  ଖୁସି, ଏବଂ ତୁମେ ବୃତ୍ତିଗତ ଦୁନିଆରେ ତୁମର ପ୍ରଥମ ପଦକ୍ଷେପ ନେଉଛ |",
              "as": "এয়া একো নহয়, মীৰা। আজিৰ দিনটো এক ভাল দিন। তোমাক স্নাতক হোৱা দেখি মই বহুত সুখী, আৰু তুমি পেছাদাৰী পৃথিৱীত তোমাৰ প্ৰথম পদক্ষেপ লৈছে।",
              "gu": "તે કંઈ નથી, મીરા. આજનો દિવસ મહત્વપૂર્ણ છે. તને સ્નાતક થયેલી જોઈને હું ખૂબ જ ખુશ છું, અને તું વ્યાવસાયિક વિશ્વમાં તારું પ્રથમ પગલું ભરી રહી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Shantanu, you tell me. What happened?",
              "hi": "शांतनु, तुम मुझे बताओ। क्या हुआ?",
              "ka": "ಶಂತನು, ನೀನು ನನಗೆ ಹೇಳು, ಏನಾಯಿತು ಎಂದು?",
              "te": "షాంతాను, నువ్వు చెప్పు. ఏమి జరిగింది?",
              "or": "ଶାନ୍ତନୁ, ତୁମେ ମୋତେ କୁହ | କଣ ହେଲା?",
              "as": "শান্তনু, তুমি মোক কোঁৱা। কি হ'ল?",
              "gu": "શાંતનુ, તું મને કહે. શું થયું?"
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_3_3///",
              "hi": "B6_3_3///",
              "ka": "B6_3_3///",
              "te": "B6_3_3///",
              "or": "B6_3_3///",
              "as": "B6_3_3///",
              "gu": "B6_3_3///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Papa lost his job.",
              "hi": "पापा ने अपनी नौकरी खो दी।",
              "ka": "ಪಾಪಾ ತಮ್ಮ ಕೆಲಸವನ್ನು ಕಳೆದುಕೊಂಡರು.",
              "te": "పాప ఉద్యోగం పోయింది.",
              "or": "ପାପା ନିଜ ଚାକିରି ହରାଇଦେଇଛନ୍ତି।",
              "as": "পাপাই চাকৰি হেৰুৱালে।",
              "gu": "પપ્પાની નોકરી છૂટી ગઈ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "What??",
              "hi": "क्या??",
              "ka": "ಏನು?",
              "te": "ఏమిటి??",
              "or": "କଣ??",
              "as": "কি??",
              "gu": "શું??"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "The factory let go of some people in the packaging unit because they have some fancy new machine that does the job.",
              "hi": "कारखाने की पैकेजिंग इकाई से कुछ लोगों को निकाल दिया गया है क्योंकि उनके पास कोई फैंसी नई मशीन है जो सब काम करती है।",
              "ka": "ಏನಿಲ್ಲ ಮೀರಾ. ಇಂದು ಮಹತ್ವದ ದಿನ. ನೀನು ಪದವೀಧರಳಾಗುವುದನ್ನು ನೋಡಲು ನನಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿದೆ ಮತ್ತು ನೀನು ವೃತ್ತಿಪರ ಜಗತ್ತಿನಲ್ಲಿ ನಿಮ್ಮ ಮೊದಲ ಹೆಜ್ಜೆಯನ್ನು ಇಡುತ್ತಿದ್ದೀಯಾ.",
              "te": "ఫ్యాక్టరీ ప్యాకేజింగ్ యూనిట్‌లోని కొంతమంది వ్యక్తులను ఉద్యోగం నుండి తీసేశారు. ఎందుకంటే వారి వద్ద పని చేసే కొన్ని కొత్త మెషీన్ ఉంది.",
              "or": "କାରଖାନା ପ୍ୟାକେଜିଂ ୟୁନିଟରେ କିଛି ଲୋକଙ୍କୁ ଛାଡିଦିଏ କାରଣ ସେମାନଙ୍କର କିଛି\n  ନୂଆ ମେସିନ୍ ଅଛି ଯାହା କାମ କରେ |",
              "as": "কাৰখানাটোৱে পেকেজিং গোটৰ কিছুমান লোকক চাকৰি পৰা আঁতৰাই দিলে কাৰণ তেওঁলোকে আকৰ্ষণীয় নতুন যন্ত্ৰ আনিছে যিয়ে কামটো কৰে।",
              "gu": "કારખાનામા પેકેજિંગ યુનિટમાં કેટલાક લોકોને છોડી દીધા કારણ કે તેમની પાસે કેટલાક નવા તકનીકી મશીન છે જે કામ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "So now?",
              "hi": "तो अब?",
              "ka": "ಅಂದರೆ ಈಗ?",
              "te": "కాబట్టి ఇప్పుడు?",
              "or": "ତାହେଲେ ଏବେ?",
              "as": "তেন্তে এতিয়া?",
              "gu": "તો હવે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Now what? He is looking for a job. He also joined a course on computers to learn more about it. But it seems like that’s going to take time.",
              "hi": "अब क्या? वह नौकरी की तलाश में है। वह इसके बारे में अधिक जानने के लिए कंप्यूटर कोर्स भी कर रहे हैं। लेकिन ऐसा लगता है कि इसमें समय लगेगा।",
              "ka": "ಈಗ ಏನು? ಅವನು ಕೆಲಸ ಹುಡುಕುತ್ತಿದ್ದಾನೆ. ಅದರ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಅವನು ಕಂಪ್ಯೂಟರ್‌ಗಳ ಕೋರ್ಸ್‌ಗೆ ಸೇರಿಕೊಂಡಿದ್ದಾನೆ. ಆದರೆ ಇದು ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ ಎಂದು ತೋರುತ್ತದೆ.",
              "te": "ఇప్పుడు ఏమిటి? ఆయన ఉద్యోగం కోసం చూస్తున్నారు. దాని గురించి మరింత తెలుసుకోవడానికి అతను కంప్యూటర్స్ కి సంబాధించిన ఒక కోర్సులో కూడా చేరారు. కానీ దానికి సమయం పడుతుందని తెలుస్తోంది.",
              "or": "ଏବେ କଣ? ସେ ଚାକିରି ଖୋଜୁଛନ୍ତି | ସେ ଏହା ବିଷୟରେ ଅଧିକ ଜାଣିବା ପାଇଁ କମ୍ପ୍ୟୁଟରରେ\n  ଏକ ପାଠ୍ୟକ୍ରମରେ ଯୋଗ ଦେଇଥିଲେ | କିନ୍ତୁ ଏହା ଲାଗୁଛି ଯେ ଏହା ସମୟ ନେବାକୁ ଯାଉଛି |",
              "as": "এতিয়া কি? তেওঁ এটা চাকৰি বিচাৰি আছে। তেওঁ ইয়াৰ বিষযয়ে জানিবলৈ কম্পিউটাৰৰ এটা পাঠ্যক্ৰমত যোগদান কৰিছে। কিন্তু এনেকুৱা লাগিছে যেন সেইটোত সময় লাগিব।",
              "gu": "હવે શું? તે નોકરી શોધી રહા છે. તેના વિશે વધુ જાણવા માટે તે કોમ્પ્યુટર પરના કોર્સમાં પણ જોડાયો. પરંતુ એવું લાગે છે કે તે સમય લેશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.5.text",
            "value": {
              "en": "Mira, don’t worry about this now. I knew you’d be upset, which is why I did not mention it.",
              "hi": "मीरा, अब इस बारे में चिंता मत करो। मुझे पता था कि तुम परेशान हो जाओगी, इसी कारण से मैंने इसका जिक्र नहीं किया।",
              "ka": "ಮೀರಾ, ಈಗ ಇದರ ಬಗ್ಗೆ ಚಿಂತಿಸಬೇಡ. ನೀನು ಅಸಮಾಧಾನಗೊಳ್ಳುತ್ತೀ ಎಂದು ನನಗೆ ತಿಳಿದಿತ್ತು, ಅದಕ್ಕಾಗಿಯೇ ನಾನು ಅದನ್ನು ಹೇಳಿರಲಿಲ್ಲ.",
              "te": "మీరా, ఇప్పుడు దీని గురించి బాధపడకండి. మీరు బాధపడతారని నాకు తెలుసు, అందుకే నేను దాని గురించి చెప్పలేదు.",
              "or": "ମୀରା, ବର୍ତ୍ତମାନ ଏହି ବିଷୟରେ ଚିନ୍ତା କର ନାହିଁ | ମୁଁ ଜାଣିଥିଲି ତୁମେ ବିରକ୍ତ ହେବ, \n ଯେଉଁଥିପାଇଁ ମୁଁ ଏହାକୁ ଉଲ୍ଲେଖ କରି ନାହିଁ |",
              "as": "মীৰা, এতিয়া এই বিষয়ে চিন্তা নকৰিবা। মই জানিছিলো তুমি মন বেয়া কৰিবা, যাৰ কাৰনে মই এইটো উল্লেখ কৰা নাছিলোঁ।",
              "gu": "મીરા, હવે આની ચિંતા ના કર. હું જાણતો હતો કે તું નારાજ હશો, તેથી જ મેં તેનો ઉલ્લેખ કર્યો નથી."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_3_4",
              "hi": "B6_3_4",
              "ka": "B6_3_4",
              "te": "B6_3_4",
              "or": "B6_3_4",
              "as": "B6_3_4",
              "gu": "B6_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is very upset with the news. All through the graduation ceremony, she keeps thinking about how the financial situation of the house will change if her father doesn’t earn.",
              "hi": "मीरा इस खबर से बहुत परेशान है। स्नातक समारोह के दौरान, वह इस बारे में सोचती रहती है कि अगर उसके पिता नहीं कमाते हैं तो घर की वित्तीय स्थिति कैसे बदलेगी। ",
              "ka": "ಈ ಸುದ್ದಿಯಿಂದ ಮೀರಾ ತುಂಬಾ ಬೇಸರಗೊಂಡಿದ್ದಾಳೆ. ಪದವಿ ಪ್ರದಾನ ಸಮಾರಂಭದುದ್ದಕ್ಕೂ, ತನ್ನ ತಂದೆ ಸಂಪಾದಿಸದಿದ್ದರೆ ಮನೆಯ ಆರ್ಥಿಕ ಪರಿಸ್ಥಿತಿ ಹೇಗೆ ಬದಲಾಗುತ್ತದೆ ಎಂದು ಅವಳು ಯೋಚಿಸುತ್ತಲೇ ಇರುತ್ತಾಳೆ.",
              "te": "ఈ విషయంతో మీరా చాలా బాధ పడింది. గ్రాడ్యుయేషన్ వేడుకలో, ఆమె తన తండ్రి సంపాదించకపోతే ఇంటి ఆర్థిక పరిస్థితి ఎలా మారుతుందో అని ఆలోచిస్తూ ఉంటుంది.",
              "or": "ଏହି ଖବର ଉପରେ ମୀରା ବହୁତ ରାଗିଛନ୍ତି | ସ୍ନାତକୋତ୍ତର ସମାରୋହରେ, ଯଦି \n ତାଙ୍କ ପିତା ରୋଜଗାର ନକରନ୍ତି ତେବେ ଘରର ଆର୍ଥିକ ସ୍ଥିତି କିପରି ବଦଳିବ ସେ ବିଷୟରେ ସେ ଚିନ୍ତା କରନ୍ତି |",
              "as": "মীৰা বাতৰিটোত পাই মন বেয়া কৰিছিল। গোটেই স্নাতক সমাৰোহৰ মাজেৰে, তাই ভাবি থাকে যে যদি তাইৰ দেউতাকে উপাৰ্জন নকৰে তেন্তে ঘৰৰ পৰিস্থিতি কেনেদৰে সলনি হ'ব।",
              "gu": "આ સમાચારથી મીરા ખૂબ જ નારાજ છે. સ્નાતક પદવી સમારોહ દરમિયાન તે વિચારતી રહે છે કે જો તેના પિતા કમાતા નથી તો ઘરની આર્થિક પરિસ્થિતિ કેવી રીતે બદલાશે."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_4_1",
              "hi": "B6_4_1",
              "ka": "B6_4_1",
              "te": "B6_4_1",
              "or": "B6_4_1",
              "as": "B6_4_1",
              "gu": "B6_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira was keen to take a drop year, stay at home, and study for her Master’s exam. But that is not an option anymore.",
              "hi": "मीरा एक वर्ष ड्राप लेने, घर पर रहने और अपने स्नाकतोत्तर की परीक्षा के लिए अध्ययन करने की इच्छुक थी। लेकिन अब यह विकल्प नहीं रहा।",
              "ka": "ಮೀರಾ ಒಂದು ಡ್ರಾಪ್ ವರ್ಷವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು, ಮನೆಯಲ್ಲಿಯೇ ಇರಲು ಮತ್ತು ತನ್ನ ಸ್ನಾತಕೋತ್ತರ ಪರೀಕ್ಷೆಗೆ ಅಧ್ಯಯನ ಮಾಡಲು ಉತ್ಸುಕಳಾಗಿದ್ದಳು. ಆದರೆ ಅದು ಈಗ ಮುಂದಿನ ಆಯ್ಕೆಯಾಗಿಲ್ಲ.",
              "te": "మీరా తన మాస్టర్స్ ఎగ్జామ్ కోసం ఒక సంవత్సరం డ్రాప్ చేయడానికి, ఇంట్లోనే ఉండి చదువుకోవాలని ఆసక్తిగా ఉంది. కానీ అది ఇకపై ఆప్షన్ కాదు.",
              "or": "ମୀରା ଏକ ଡ୍ରପ୍ ବର୍ଷ ନେବାକୁ, ଘରେ ରହିବାକୁ ଏବଂ ତାଙ୍କ ମାଷ୍ଟର ପରୀକ୍ଷା ପାଇଁ \n ଅଧ୍ୟୟନ କରିବାକୁ ଆଗ୍ରହୀ ଥିଲେ | କିନ୍ତୁ ତାହା ଆଉ ଏକ ବିକଳ୍ପ ନୁହେଁ |",
              "as": "মীৰাই এবছৰ ড্ৰপ ল'বলৈ, ঘৰত থাকিবলৈ আৰু স্নাতকোত্তৰ পৰীক্ষাৰ বাবে পঢ়িবলৈ আগ্ৰহী আছিল। কিন্তু সেইটো এতিয়া আৰু সম্ভৱ নহয়।",
              "gu": "મીરા એક વર્ષ ખાલી જવા દેવા માગતી હતી, ઘરે રહેવા અને તેના માસ્ટરની પરીક્ષા માટે અભ્યાસ કરવા ઉત્સુક હતી. પરંતુ તે હવે વિકલ્પ નથી."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_4_2",
              "hi": "B6_4_2",
              "ka": "B6_4_2",
              "te": "B6_4_2",
              "or": "B6_4_2",
              "as": "B6_4_2",
              "gu": "B6_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She knows she cannot handle preparing for the entrance and working full time. Considering the situation at home, she might need to take a job to support her family.",
              "hi": "वह जानती है कि वह प्रवेश परिक्षा की तैयारी और पूर्णकालिक काम साथ साथ नहीं कर सकती है। घर की स्थिति को ध्यान में रखते हुए, उसे अपने परिवार की सहायता करने के लिए नौकरी लेने की आवश्यकता हो सकती है।",
              "ka": "ಪ್ರವೇಶಕ್ಕಾಗಿ ತಯಾರಿ ನಡೆಸುವುದು ಮತ್ತು ಪೂರ್ಣ ಸಮಯ ಕೆಲಸ ಮಾಡುವುದು ಅವಳಿಗೆ ನಿಭಾಯಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ ಎಂದು ಅವಳು ತಿಳಿದಿದ್ದಾಳೆ. ಮನೆಯ ಪರಿಸ್ಥಿತಿಯನ್ನು ಪರಿಗಣಿಸಿ, ತನ್ನ ಕುಟುಂಬವನ್ನು ಬೆಂಬಲಿಸಲು ಅವಳು ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕಾಗಬಹುದು.",
              "te": "ఎంట్రన్స్ కోసం ప్రిపేర్ అవ్వడం మరియు పూర్తి సమయం పని చేయడం ఆమె చేయలేదని ఆమెకు తెలుసు. ఇంట్లో పరిస్థితిని బట్టి చూస్తే ఆమె తన కుటుంబాన్ని పోషించడానికి ఉద్యోగం చూసుకోవాల్సి వస్తుంది.",
              "or": "ସେ ଜାଣନ୍ତି ଯେ ସେ ପ୍ରବେସିକା ପରୀକ୍ଷା ପାଇଁ ପ୍ରସ୍ତୁତି ଏବଂ ପୂରା ସମୟ କାମ \n କରିପାରିବେ ନାହିଁ | ଘରେ ଥିବା ପରିସ୍ଥିତିକୁ ଅନୁଧ୍ୟାନ କରି ତାଙ୍କୁ ପରିବାର ଚଳାଇବା ପାଇଁ ଚାକିରି କରିବାକୁ ପଡିପାରେ |",
              "as": "তাই জানে যে তাই পৰীক্ষাৰ কাৰনে প্ৰস্তুতি চম্ভালিব নোৱাৰে আৰু সম্পূৰ্ণ সময় কাম কৰিব নোৱাৰে। ঘৰৰ পৰিস্থিতিৰ কথা ভাবি, তাই তাইৰ পৰিয়ালৰ ভৰণপোষণৰ বাবে চাকৰি এটা ল'ব লাগিব।",
              "gu": "તે જાણે છે કે તે પ્રવેશ માટેની તૈયારી અને સંપૂર્ણ સમય કામ કરવાનું સંભાળી શકતી નથી. ઘરની પરિસ્થિતિને ધ્યાનમાં રાખીને, તેને તેના પરિવારને ટેકો આપવા માટે નોકરી લેવાની જરૂર પડી શકે છે."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_5_1",
              "hi": "B6_5_1",
              "ka": "B6_5_1",
              "te": "B6_5_1",
              "or": "B6_5_1",
              "as": "B6_5_1",
              "gu": "B6_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is sure she will get a full-time job at any government health centre near home. Her marks were good, and the centres always needed more doctors.",
              "hi": "मीरा को विश्वास है कि उसे घर के पास किसी भी सरकारी स्वास्थ्य केंद्र में पूर्णकालिक नौकरी मिल जायेगी। उसके मार्क्स अच्छे हैं, और केंद्रों को डॉक्टरों की आवश्यकता हमेशा होती है।",
              "ka": "ಮನೆ ಸಮೀಪದ ಯಾವುದಾದರೂ ಸರ್ಕಾರಿ ಆರೋಗ್ಯ ಕೇಂದ್ರದಲ್ಲಿ ತನಗೆ ಪೂರ್ಣಾವಧಿ ಕೆಲಸ ಸಿಗುವುದು ಖಚಿತವಾಗಿದೆ. ಮೀರಾಳ ಅಂಕಗಳು ಉತ್ತಮವಾಗಿದೆ ಮತ್ತು ಕೇಂದ್ರಗಳಿಗೆ ಯಾವಾಗಲೂ ಹೆಚ್ಚಿನ ವೈದ್ಯರ ಅಗತ್ಯತೆ ಇದೆ.",
              "te": "ఇంటికి దగ్గరలో ఉన్న ఏదైనా ప్రభుత్వ ఆరోగ్య కేంద్రంలో తనకు ఫుల్ టైమ్ జాబ్ దొరుకుతుందని మీరా ఖచ్చితంగా భావిస్తుంది. ఆమె మార్కులు బాగున్నాయి మరియు కేంద్రాలకు ఎప్పుడూ ఎక్కువ మంది వైద్యుల అవసరం ఉంటుంది.",
              "or": "ମୀରା ନିଶ୍ଚିତ ଯେ ସେ ଘର ନିକଟରେ ଥିବା କୌଣସି ସରକାରୀ\n  ସ୍ୱାସ୍ଥ୍ୟକେନ୍ଦ୍ରରେ ପୂର୍ଣ୍ଣକାଳୀନ ଚାକିରି ପାଇବେ | ତାଙ୍କର ମାର୍କ ଭଲ ଥିଲା ଏବଂ କେନ୍ଦ୍ରଗୁଡ଼ିକ ସର୍ବଦା ଅଧିକ ଡାକ୍ତର ଆବଶ୍ୟକ କରୁଥିଲେ |",
              "as": "মীৰা জানে যে তাইৰ ঘৰৰ ওচৰৰ যিকোনো চৰকাৰী স্বাস্থ্য কেন্দ্ৰত পূৰ্ণ-সময়ৰ চাকৰি পাব। তাইৰ নম্বৰ ভাল আছিল, আৰু কেন্দ্ৰবোৰক সদায়ে অধিক চিকিৎসকৰ প্ৰয়োজন থাকে।",
              "gu": "મીરાને ખાતરી છે કે તેને ઘરની નજીકના કોઈપણ સરકારી આરોગ્ય કેન્દ્રમાં પૂર્ણ-સમયની નોકરી મળશે. તેના ગુણ સારા હતા, અને કેન્દ્રોને હંમેશા વધુ ડોકટરોની જરૂર હતી."
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_5_2",
              "hi": "B6_5_2",
              "ka": "B6_5_2",
              "te": "B6_5_2",
              "or": "B6_5_2",
              "as": "B6_5_2",
              "gu": "B6_5_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "But she also thinks about working part-time, doing home visits within her area while also studying for her post-graduation examination.",
              "hi": "लेकिन वह अंशकालिक काम करने के बारे में भी सोचती है, अपने क्षेत्र में घरों के दौरे करने के साथ साथ अपनी स्नातकोत्तर परीक्षा के लिए भी अध्ययन कर सकती है।",
              "ka": "ಆದರೆ ಅವಳು ಅರೆಕಾಲಿಕ ಕೆಲಸ ಮಾಡುವ ಬಗ್ಗೆ ಯೋಚಿಸುತ್ತಾಳೆ, ತನ್ನ ಸ್ನಾತಕೋತ್ತರ ಪರೀಕ್ಷೆಗೆ ಅಧ್ಯಯನ ಮಾಡುವಾಗ ತನ್ನ ಮನೆಗೆ ಭೇಟಿಯನ್ನು ನೀಡುತ್ತಿರುತ್ತಾಳೆ.",
              "te": "కానీ ఆమె తన పోస్ట్-గ్రాడ్యుయేషన్ పరీక్ష కోసం చదువుతూ పార్ట్ టైమ్ జాబ్ చేయడం, తన ప్రాంతంలో హోమ్ విజిట్ చేయడం గురించి కూడా ఆలోచిస్తుంది.",
              "or": "କିନ୍ତୁ ସେ ପାର୍ଟ ଟାଇମ୍ କାମ କରିବା, ନିଜ ଅଞ୍ଚଳରେ ଘର ପରିଦର୍ଶନ କରିବା ସହିତ\n  ସ୍ନାତକୋତ୍ତର ପରୀକ୍ଷଣ ପାଇଁ ମଧ୍ୟ ଅଧ୍ୟୟନ କରିବା ବିଷୟରେ ଚିନ୍ତା କରନ୍ତି |",
              "as": "কিন্তু তাই আংশিক সময়ৰ বাবে কাম কৰাৰ বিষয়েও চিন্তা কৰে, তাইৰ এলেকাৰ ভিতৰত ঘৰলৈ যোৱাৰ কথা ভাবে আৰু লগতে স্নাতকোত্তৰ পৰীক্ষাৰ বাবে অধ্যয়ন কৰে।",
              "gu": "પરંતુ તે અનુસ્નાતક પરીક્ષા માટે અભ્યાસ કરતી વખતે થોડો સમય કામ કરવા, તેના વિસ્તારમાં ઘરની મુલાકાત લેવાનું પણ વિચારે છે."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_4_3 D",
              "hi": "B6_4_3 D",
              "ka": "B6_4_3 D",
              "te": "B6_4_3 D",
              "or": "B6_4_3 D",
              "as": "B6_4_3 D",
              "gu": "B6_4_3 D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Mira do?",
              "hi": "मीरा को क्या करना चाहिए?",
              "ka": "ಮೀರಾ ಏನು ಮಾಡಬೇಕು?",
              "te": "మీరా ఏమి చేయాలి?",
              "or": "ମୀରା କ’ଣ କରିବା ଉଚିତ୍?",
              "as": "মীৰাই কি কৰিব লাগে?",
              "gu": "મીરાએ શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Take up the job at the health centre in her town",
              "hi": "अपने शहर के स्वास्थ्य केंद्र में नौकरी कर ले",
              "ka": "ಅವಳ ಪಟ್ಟಣದಲ್ಲಿ ಆರೋಗ್ಯ ಕೇಂದ್ರದಲ್ಲಿ ಕೆಲಸವನ್ನು ಮಾಡುವುದೇ",
              "te": "ఆమె పట్టణంలోని ఆరోగ్య కేంద్రంలో ఉద్యోగంలో చేరండి",
              "or": "ତାଙ୍କ ସହରର ସ୍ୱାସ୍ଥ୍ୟକେନ୍ଦ୍ରରେ ଚାକିରି ନିଅନ୍ତୁ |",
              "as": "তাইৰ চহৰৰ স্বাস্থ্য কেন্দ্ৰত চাকৰিটো লব",
              "gu": "તેના શહેરના આરોગ્ય કેન્દ્રમાં નોકરી લેવી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Prepare for PG as per the first plan, along with home visits to earn some money.",
              "hi": "कुछ पैसे कमाने के लिए रोगियों को देखने उनके घर के दौरे के साथ साथ पहली योजना के अनुसार स्नातकोत्तर के लिए तैयारी करे",
              "ka": "ಸ್ವಲ್ಪ ಹಣವನ್ನು ಗಳಿಸಲು ಮನೆ ಭೇಟಿಗಳ ಜೊತೆಗೆ ಮೊದಳು ಯೋಚಿಸಿದಂತೆಯೇ PG ಗೆ ಸಿದ್ಧಳಾಗುವುದು.",
              "te": "కొంత డబ్బు సంపాదించడానికి హోమ్ విజిత్ తో పాటు మొదటి ప్లాన్ ప్రకారం PG కోసం ప్రిపేర్ అవ్వండి.",
              "or": "ପ୍ରଥମ ଯୋଜନା ଅନୁଯାୟୀ PG ପାଇଁ ପ୍ରସ୍ତୁତ ହୁଅନ୍ତୁ, କିଛି ଟଙ୍କା ରୋଜଗାର କରିବା\n  ପାଇଁ ଘର ପରିଦର୍ଶନ ସହିତ |",
              "as": "প্ৰথম ভবাৰ দৰে পিজিৰ বাবে প্ৰস্তুত কৰক, লগতে কিছু টকা উপাৰ্জন কৰিবলৈ ঘৰলৈ যাওঁক।",
              "gu": "થોડા પૈસા કમાવવા માટે ઘરની મુલાકાતો સાથે, પ્રથમ યોજના મુજબ PG માટે તૈયારી કરવી."
            }
          }
        ]
      },
      "scene38": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Work at a health care centre",
              "hi": "एक स्वास्थ्य केंद्र में काम करे",
              "ka": "ಆರೋಗ್ಯ ಕೇಂದ್ರದಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದೇ",
              "te": "ఆరోగ్య సంరక్షణ కేంద్రంలో పని చేయండి",
              "or": "ଏକ ସ୍ୱାସ୍ଥ୍ୟ ସେବା କେନ୍ଦ୍ରରେ କାର୍ଯ୍ୟ କରନ୍ତୁ |",
              "as": "স্বাস্থ্য সেৱা কেন্দ্ৰএটাত কাম কৰা",
              "gu": "આરોગ્ય સંભાળ કેન્દ્રમાં કામ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira decides to go back to her town and joins a primary health centre as a junior doctor. She applies to a few of them, and all of them respond to her.",
              "hi": "मीरा अपने नगर वापस जाने और एक जूनियर डॉक्टर के रूप में प्राथमिक स्वास्थ्य केंद्र में नौकरी करने का निर्णय करती है। वह उनमें से कुछ में आवेदन करती है, और सभी से उसे जवाब मिलता है।",
              "ka": "ಮೀರಾ ತನ್ನ ಪಟ್ಟಣಕ್ಕೆ ಹಿಂತಿರುಗಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ ಮತ್ತು ಕಿರಿಯ ವೈದ್ಯಳಾಗಿ ಪ್ರಾಥಮಿಕ ಆರೋಗ್ಯ ಕೇಂದ್ರಕ್ಕೆ ಸೇರುತ್ತಾಳೆ. ಅವಳು ಅವುಗಳಲ್ಲಿ ಕೆಲವಕ್ಕೆ ಅರ್ಜಿಯನ್ನು ಕಾಕುತ್ತಾಳೆ  ಮತ್ತು ಅವರೆಲ್ಲರೂ ಅವಳಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುತ್ತಾರೆ.",
              "te": "మీరా తన పట్టణానికి తిరిగి వెళ్లాలని నిర్ణయించుకుంది మరియు ఒక ప్రాథమిక ఆరోగ్య కేంద్రంలో జూనియర్ డాక్టర్‌గా చేరింది. ఆమె వారిలో కొందరికి పనిచేస్తుంది మరియు వారందరూ ఆమెకు ప్రతిస్పందిస్తారు.",
              "or": "ମୀରା ନିଜ ସହରକୁ ଫେରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି ଏବଂ କନିଷ୍ଠ ଡାକ୍ତର ଭାବରେ ଏକ\n  ପ୍ରାଥମିକ ସ୍ୱାସ୍ଥ୍ୟକେନ୍ଦ୍ରରେ ଯୋଗ ଦେଇଛନ୍ତି | ସେ ସେମାନଙ୍କ ମଧ୍ୟରୁ ଅଳ୍ପ କିଛି ପାଇଁ ପ୍ରଯୁଜ୍ୟ, ଏବଂ ସମସ୍ତେ ତାଙ୍କୁ ପ୍ରତିକ୍ରିୟା କରନ୍ତି |",
              "as": "মীৰাই তাইৰ চহৰলৈ উভতি যোৱাৰ সিদ্ধান্ত লয় আৰু কনিষ্ঠ চিকিৎসক হিচাপে প্ৰাথমিক স্বাস্থ্য কেন্দ্ৰত যোগদান কৰে। তাই তেওঁলোকৰ কেইটামানত আবেদন কৰে, আৰু তেওঁলোক সকলোৱে তাইক সঁহাৰি দিয়ে।",
              "gu": "મીરા તેના ગામમાં પાછા જવાનું નક્કી કરે છે અને જુનિયર ડૉક્ટર તરીકે પ્રાથમિક આરોગ્ય કેન્દ્રમાં જોડાય છે.તે તેમાંથી કેટલાકને લાગુ પડે છે, અને તે બધા જ તેનો પ્રતિભાવ આપે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_1_2",
              "hi": "B7_1_2",
              "ka": "B7_1_2",
              "te": "B7_1_2",
              "or": "B7_1_2",
              "as": "B7_1_2",
              "gu": "B7_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira takes up the job at the health centre that is closest to home. She earns enough to contribute towards home expenses and ease the pressure on her mother.",
              "hi": "मीरा उस स्वास्थ्य केंद्र में नौकरी कर लेती है जो घर के सबसे करीब है। वह घर के खर्चों में योगदान देने और अपनी मां पर दबाव को कम करने के लिए पर्याप्त धन कमाती है।",
              "ka": "ಮೀರಾ ಮನೆಗೆ ಹತ್ತಿರವಿರುವ ಆರೋಗ್ಯ ಕೇಂದ್ರದಲ್ಲಿ ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾಳೆ. ಮನೆಯ ಖರ್ಚಿಗೆ ಕೊಡುಗೆ ನೀಡಲು ಮತ್ತು ತಾಯಿಯ ಮೇಲಿನ ಒತ್ತಡವನ್ನು ಕಡಿಮೆ ಮಾಡಲು ಅವಳು ಸಾಕಷ್ಟು ಸಂಪಾದಿಸುತ್ತಾಳೆ.",
              "te": "మీరా ఇంటికి దగ్గరగా ఉన్న ఆరోగ్య కేంద్రంలో ఉద్యోగం చేస్తుంది. ఆమె ఇంటి ఖర్చులకు మరియు తన తల్లిపై ఒత్తిడిని తగ్గించడానికి తగినంతగా సంపాదిస్తుంది.",
              "or": "ମୀରା ଘରର ନିକଟସ୍ଥ ସ୍ୱାସ୍ଥ୍ୟକେନ୍ଦ୍ରରେ ଚାକିରି କରନ୍ତି | ଘର ଖର୍ଚ୍ଚରେ ସହଯୋଗ କରିବା \n ଏବଂ ମା’ଙ୍କ ଉପରେ ଚାପକୁ ହ୍ରାସ କରିବା ପାଇଁ ସେ ଯଥେଷ୍ଟ ରୋଜଗାର କରନ୍ତି |",
              "as": "মীৰাই ঘৰৰ আটাইতকৈ ওচৰৰ স্বাস্থ্য কেন্দ্ৰত চাকৰিটো গ্ৰহণ কৰে। তাই ঘৰৰ খৰচত অৰিহণা দিবলৈ আৰু তাইৰ মাকৰ ওপৰত চিন্তা কমাবলৈ উপাৰ্জন কৰে।",
              "gu": "મીરા ઘરની સૌથી નજીક આવેલા આરોગ્ય કેન્દ્રમાં નોકરી કરે છે. તે ઘરના ખર્ચમાં ફાળો આપવા અને તેની માતા પરનું દબાણ ઓછું કરવા માટે પૂરતી કમાણી કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_2_1",
              "hi": "B7_2_1",
              "ka": "B7_2_1",
              "te": "B7_2_1",
              "or": "B7_2_1",
              "as": "B7_2_1",
              "gu": "B7_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shantanu is studying animation at a college in Delhi. His routine is very different from Mira’s, and he is quite surprised to see her busy work schedule.",
              "hi": "शांतनु दिल्ली के एक कॉलेज में एनीमेशन का अध्ययन कर रहा है। उसकी दिनचर्या मीरा से बहुत अलग है, और वह मीरा की व्यस्तता को देखकर काफी आश्चर्यचकित है।",
              "ka": "ಶಂತನು ದೆಹಲಿಯ ಕಾಲೇಜೊಂದರಲ್ಲಿ ಅನಿಮೇಷನ್ ಓದುತ್ತಿದ್ದಾನೆ. ಅವನ ದಿನಚರಿಯು ಮೀರಾಳಿಗಿಂತ ತುಂಬಾ ಭಿನ್ನವಾಗಿದೆ ಮತ್ತು ಅವಳ ಬಿಡುವಿಲ್ಲದ ಕೆಲಸದ ವೇಳಾಪಟ್ಟಿಯನ್ನು ನೋಡಿ ಅವನು ಆಶ್ಚರ್ಯಚಕಿತನಾಗುತ್ತಾನೆ.",
              "te": "షాంతాను ఢిల్లీలోని ఓ కాలేజీలో యానిమేషన్ చదువుతున్నాడు. అతని దినచర్య మీరాకి చాలా వేరుగా ఉంటుంది మరియు ఆమె బిజీ వర్క్ షెడ్యూల్‌ని చూసి అతను చాలా ఆశ్చర్యపోయాడు.",
              "or": "ଶାନ୍ତନୁ ଦିଲ୍ଲୀର ଏକ କଲେଜରେ ଆନିମେସନ୍ ପଢୁଛନ୍ତି | ତାଙ୍କର ନିତ୍ୟକର୍ମ \n ମିରାଙ୍କ ଠାରୁ ବହୁତ ଭିନ୍ନ, ଏବଂ ତାଙ୍କର ବ୍ୟସ୍ତବହୁଳ କାର୍ଯ୍ୟସୂଚୀ ଦେଖି ସେ ବହୁତ ଆଶ୍ଚର୍ଯ୍ୟ ହୋଇଗଲେ |",
              "as": "শান্তনুৱে দিল্লীৰ এখন মহাবিদ্যালয়ত এনিমেছন অধ্যয়ন কৰি আছে। তাৰ ৰুটিন মীৰাতকৈ বহুত বেলেগ, আৰু তাইব্যস্ত কামৰ সময় দেখি তেওঁ যথেষ্ট আচৰিত হৈছে।",
              "gu": "શાંતનુ દિલ્હીની એક કોલેજમાં એનિમેશનનો અભ્યાસ કરી રહ્યો છે. તેની દિનચર્યા મીરા કરતા ઘણી અલગ છે, અને તે તેના વ્યસ્ત કાર્ય શેડ્યૂલને જોઈને ખૂબ જ આશ્ચર્યચકિત છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_2_2",
              "hi": "B7_2_2",
              "ka": "B7_2_2",
              "te": "B7_2_2",
              "or": "B7_2_2",
              "as": "B7_2_2",
              "gu": "B7_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira pays a part of his tuition fees with her salary, and Shantanu is working part-time to pay the rest.",
              "hi": "मीरा अपने वेतन से उसके शिक्षण शुल्क का एक हिस्सा चुकाती है, और बाकी का भुगतान करने के लिए शांतनु अंशकालिक काम करता है।",
              "ka": "ಮೀರಾ ಅವನ  ಬೋಧನಾ ಶುಲ್ಕದ ಒಂದು ಭಾಗವನ್ನು ತನ್ನ ಸಂಬಳದ ಮೂಲಕ  ಪಾವತಿಸುತ್ತಾಳೆ ಮತ್ತು ಉಳಿದ ಹಣವನ್ನು ಪಾವತಿಸಲು ಶಂತನು ಅರೆಕಾಲಿಕ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾನೆ",
              "te": "మీరా తన ట్యూషన్ ఫీజులో కొంత భాగాన్ని తన జీతంతో చెల్లిస్తుంది మరియు మిగిలిన మొత్తాన్ని చెల్లించడానికి షాంతాను పార్ట్ టైమ్‌ జాబ్ చేస్తున్నాడు.",
              "or": "ମୀରା ତାଙ୍କ ବେତନ ସହିତ ତାଙ୍କ ଟ୍ୟୁସନ ଫି ର ଏକ ଅଂଶ ପ୍ରଦାନ କରନ୍ତି ଏବଂ\n  ଶାନ୍ତନୁ ଅବଶିଷ୍ଟ ଅର୍ଥ ଦେବା ପାଇଁ ପାର୍ଟ ଟାଇମ୍ କାମ କରନ୍ତି |",
              "as": "মীৰাই তাইৰ দৰমহাৰ পৰা তাৰ মাচুলৰ এটা অংশ দিয়ে, আৰু শান্তনুৱে বাকীখিনি দিবলৈ পাৰ্ট টাইম কাম কৰি আছে।",
              "gu": "મીરા તેની ટ્યુશન ફીનો એક ભાગ તેના પગારથી ચૂકવે છે, અને શાંતનુ બાકીના ચૂકવવા માટે થોડો સમય કામ કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_1_3///",
              "hi": "B7_1_3///",
              "ka": "B7_1_3///",
              "te": "B7_1_3///",
              "or": "B7_1_3///",
              "as": "B7_1_3///",
              "gu": "B7_1_3///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "As a junior doctor, she sees patients with different illnesses. She has to do the preliminary assessment of the patients.",
              "hi": "एक जूनियर डॉक्टर के रूप में, वह भिन्न भिन्न रोगों वाले रोगियों को देखती है। उसे रोगियों का प्रारंभिक मूल्यांकन करना है।",
              "ka": "ಒಬ್ಬ ಕಿರಿಯ ವೈದ್ಯಕೀಯೆಯಾಗಿ, ಅವಳು ಬೇರೆ ಬೇರೆ ರೀತಿಯ ರೋಗಿಗಳನ್ನು ನೋಡುತ್ತಾಳೆ. ಅವ್ಲು ಪ್ರಾರಂಭಿಕ ತಪಾಸನೆಯನ್ನು ಮಾಡಬೇಕಾಗುತ್ತದೆ. ",
              "te": "జూనియర్ డాక్టర్‌గా, ఆమె వివిధ వ్యాధులతో బాధపడుతున్న రోగులను చూస్తుంది. ఆమె రోగుల ప్రాథమిక అంచనా వేసి ఇవ్వాలి.",
              "or": "ଜଣେ ଜୁନିଅର ଡାକ୍ତର ଭାବରେ ସେ ବିଭିନ୍ନ ରୋଗରେ ପୀଡିତ ରୋଗୀଙ୍କୁ ଦେଖନ୍ତି |\n  ତାଙ୍କୁ ରୋଗୀମାନଙ୍କର ପ୍ରାଥମିକ ମୂଲ୍ୟାଙ୍କନ କରିବାକୁ ପଡିବ |",
              "as": "এজন কনিষ্ঠ চিকিৎসক হিচাপে, তাই বিভিন্ন ৰোগথকা ৰোগীক দেখে। তাই ৰোগীসকলৰ প্ৰাথমিক পৰীক্ষা কৰিব লাগে।",
              "gu": "જુનિયર ડૉક્ટર તરીકે, તે વિવિધ બીમારીઓ ધરાવતા દર્દીઓને જુએ છે. તેને દર્દીઓનું પ્રારંભિક મૂલ્યાંકન કરવું પડશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_1_4///",
              "hi": "B7_1_4///",
              "ka": "B7_1_4///",
              "te": "B7_1_4///",
              "or": "B7_1_4///",
              "as": "B7_1_4///",
              "gu": "B7_1_4///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "She then decides if the patient needs to meet a specialised doctor, do more tests, etc., and accordingly refers the patient to a bigger hospital.",
              "hi": "तब वह तय करती है कि रोगी को एक विशेष डॉक्टर से मिलने, अधिक परीक्षण आदि की जरूरत है, और तदनुसार रोगी को एक बड़े अस्पताल में परामर्श हेतु भेजती है।",
              "ka": "ನಂತರ ಅವಳು, ರೋಗಿಯು ಒಬ್ಬ ಸ್ಪೇಶಲೈಜೇಶನ್ ಮಾಡಿರುವ ವೈಧ್ಯರನ್ನು ನೋಡಬೇಕೆ ಬೇಡವೇ, ಅಥವಾ ಮತ್ತಷ್ಟು ಪರೀಕ್ಷೆ ಮಾಡಬೇಕೆ ನ್ನುವುದನ್ನು ನಿರ್ಧರಿಸುತ್ತಾಳೆ ಹಾಗೂ ಅದರಂತೆ ರೋಗಿಯನ್ನು ದೊಡ್ಡ ಆಸ್ಪತ್ರೆಗೆ ಕಳುಹಿಸುತ್ತಾಳೆ. ",
              "te": "రోగి స్పెషలైజ్డ్ డాక్టర్ ని కలవాలా మరియు మరిన్ని పరీక్షలు చేయించుకోవాలా మొదలైనవాటిని ఆమె నిర్ణయిస్తుంది. పరిస్థితిని బట్టి రోగులని పెద్ద ఆసుపత్రికి పంపుతుంది.",
              "or": "ତା’ପରେ ସେ ସ୍ଥିର କରନ୍ତି ଯେ ରୋଗୀ ଏକ ବିଶେଷଜ୍ଞ ଡାକ୍ତରଙ୍କୁ ଭେଟିବା, ଅଧିକ\n  ପରୀକ୍ଷା କରିବା ଇତ୍ୟାଦି ଆବଶ୍ୟକ କରନ୍ତି ଏବଂ ସେହି ଅନୁଯାୟୀ ରୋଗୀକୁ ଏକ ବଡ଼ ଡାକ୍ତରଖାନାକୁ ପଠାନ୍ତି |",
              "as": "তাৰ পিছত তাই কয় যে ৰোগীয়ে এজন বিশেষজ্ঞ চিকিৎসকক লগ কৰিব লাগিব নেকি, অধিক পৰীক্ষা কৰিব লাগিব, আৰু সেই অনুসৰি ৰোগীক ডাঙৰ চিকিৎসালয়লৈ প্ৰেৰণ কৰিব লাগিব।",
              "gu": "તે પછી તે નક્કી કરે છે કે શું દર્દીને કોઈ વિશેષ ડૉક્ટરને મળવાનીજરૂર છે, વધુ પરીક્ષણો વગેરે કરવાની જરૂર છે, અને તે મુજબ દર્દીને મોટા દવાખાનામાં સંદર્ભ કરે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_3_1",
              "hi": "B7_3_1",
              "ka": "B7_3_1",
              "te": "B7_3_1",
              "or": "B7_3_1",
              "as": "B7_3_1",
              "gu": "B7_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Her work timing is fixed in the hospital from 10 am to 9 pm, but patients know that they can call her anytime if there is an emergency.",
              "hi": "उसका काम का समय अस्पताल में सुबह 10 बजे से शाम 9 बजे तक तय किया गया है, लेकिन रोगियों को पता है कि यदि कोई आपात स्थिति है तो वे कभी भी उसे कॉल कर सकते हैं।",
              "ka": "ಆಸ್ಪತ್ರೆಯಲ್ಲಿ ಆಕೆಯ ಕೆಲಸದ ಸಮಯವನ್ನು ಬೆಳಿಗ್ಗೆ 10 ರಿಂದ ರಾತ್ರಿ 9 ರವರೆಗೆ ನಿಗದಿಪಡಿಸಲಾಗಿದೆ, ಆದರೆ ತುರ್ತು ಪರಿಸ್ಥಿತಿ ಇದ್ದಲ್ಲಿ ಅವರು ಯಾವಾಗ ಬೇಕಾದರೂ ಅವಳಿಗೆ ಕರೆ ಮಾಡಬಹುದು ಎಂದು ರೋಗಿಗಳಿಗೆ ತಿಳಿದಿದೆ.",
              "te": "ఆసుపత్రిలో ఉదయం 10 గంటల నుండి రాత్రి 9 గంటల వరకు ఆమె పని సమయం నిర్ణయించబడింది, అయితే రోగులకు ఏ సమయంలోనైనా అత్యవసరమైతే ఫోన్ చేయవచ్చని తెలుసు.",
              "or": "ତାଙ୍କ କାର୍ଯ୍ୟ ସମୟ ହସ୍ପିଟାଲରେ ସକାଳ ୧୦ ଟାରୁ ରାତି ୯ ଟା ପର୍ଯ୍ୟନ୍ତ ସ୍ଥିର ହୋଇଛି\n , କିନ୍ତୁ ରୋଗୀମାନେ ଜାଣନ୍ତି ଯେ ଜରୁରୀକାଳୀନ ପରିସ୍ଥିତି ହେଲେ ସେମାନେ ତାଙ୍କୁ ଯେକୌଣସି ସମୟରେ ଫୋନ୍ କରିପାରିବେ |",
              "as": "তাইৰ কামৰ সময় পুৱা 10 বজাৰ পৰা নিশা 9 বজালৈ চিকিৎসালয়ত হয়, কিন্তু ৰোগীসকলে জানে যে জৰুৰী কালীন অৱস্থা হ'লে তেওঁলোকে যিকোনো সময়তে তাইক ফোন কৰিব পাৰে।",
              "gu": "દવાખાનામાં તેના કામનો સમય સવારે 10 થી રાત્રે 9 વાગ્યા સુધી નક્કી કરવામાં આવ્યો છે, પરંતુ દર્દીઓ જાણે છે કે જો કોઈ કટોકટી હોય તો તેઓ તેને ગમે ત્યારે ફોન કરી શકે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_3_2",
              "hi": "B7_3_2",
              "ka": "B7_3_2",
              "te": "B7_3_2",
              "or": "B7_3_2",
              "as": "B7_3_2",
              "gu": "B7_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Her initial experiences teach her a lot, and soon she is able to build a connection with the patients. She becomes well known within the town for her approachability and patience.",
              "hi": "उसके शुरुआती अनुभव उसे बहुत कुछ सिखाते हैं, और जल्द ही वह रोगियों के साथ संबंध बनाने में सक्षम है। वह अपनी पहुंच और धैर्य के लिए शहर में अच्छी तरह से जानी जाती है।",
              "ka": "ಅವಳ ಆರಂಭಿಕ ಅನುಭವಗಳು ಅವಳಿಗೆ ಬಹಳಷ್ಟು ಕಲಿಸುತ್ತವೆ ಮತ್ತು ಶೀಘ್ರದಲ್ಲೇ ಅವಳು ರೋಗಿಗಳೊಂದಿಗೆ ಸಂಪರ್ಕವನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳಲು ಆಕೆಯನ್ನು ಸಮೀಪಿಸುವಿಕೆ ಮತ್ತು ತಾಳ್ಮೆಗಾಗಿ ಅವಳು ಪಟ್ಟಣದೊಳಗೆ ಹೆಸರುವಾಸಿಯಾಗುತ್ತಾಳೆ.",
              "te": "ఆమెకి మొదట్లో ఎదురైన అనుభవాలు ఆమెకు చాలా నేర్పుతాయి మరియు త్వరలో ఆమె రోగులతో మంచి సంబంధాన్ని ఏర్పరచుకోగలుగుతుంది. ఆమె రోగులని చూసే విధానం మరియు ఆమెకి ఉన్న సహనం వలన పట్టణంలో మంచి పేరు తెచ్చుకుంటుంది.",
              "or": "ତାଙ୍କର ପ୍ରାରମ୍ଭିକ ଅନୁଭୂତି ତାଙ୍କୁ ବହୁତ କିଛି ଶିଖାଏ, ଏବଂ ଶୀଘ୍ର ସେ ରୋଗୀମାନଙ୍କ\n  ସହିତ ଏକ ସଂଯୋଗ ସ୍ଥାପନ କରିବାରେ ସକ୍ଷମ ହୁଏ | ସେ ନିଜର ନିକଟତରତା ଏବଂ ଧର୍ଯ୍ୟ ପାଇଁ ସହର ମଧ୍ୟରେ ଜଣାଶୁଣା |",
              "as": "তাইৰ অভিজ্ঞতাই তাইক বহুত শিকায়, আৰু সোনকালেই তাই ৰোগীসকলৰ লগত এটা সম্পৰ্ক গঢ়ি তুলিবলৈ পৰা হয়। তাইৰ উপলব্ধিআৰু ধৈৰ্য্যৰ বাবে চহৰত জনাজাত হৈ পৰে।",
              "gu": "તેના પ્રારંભિક અનુભવો તેને ઘણું શીખવે છે, અને ટૂંક સમયમાં તે દર્દીઓ સાથે જોડાણ બનાવવામાં સક્ષમ છે. તેની પહોંચ અને ધીરજ માટે તે ગામમાં જાણીતી બને છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_3_3",
              "hi": "B7_3_3",
              "ka": "B7_3_3",
              "te": "B7_3_3",
              "or": "B7_3_3",
              "as": "B7_3_3",
              "gu": "B7_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is happy at the health centre as she is able to help the people she grew up with. The people in her town are really happy that they now have a doctor they can rely on, especially because she is one of their own.",
              "hi": "मीरा स्वास्थ्य केंद्र में खुश है क्योंकि वह उन लोगों की सहायता कर रही है जिनके साथ वह बड़ी हुई है। उसके शहर के लोग वास्तव में खुश हैं कि अब उनके पास एक डॉक्टर है जिस पर वे भरोसा कर सकते हैं, खासकर क्योंकि वह स्वयं में से एक है।",
              "ka": "ಆರೋಗ್ಯ ಕೇಂದ್ರದಲ್ಲಿ ತಾನು ಬೆಳೆದ ಜಾಗದಲ್ಲಿನ ಜನರಿಗೆ ಸಹಾಯ ಮಾಡಲು ಮೀರಾಗೆ  ಸಂತೋಷವಾಗಿದೆ. ಅವರ ಊರಿನ ಜನರು ಈಗ ತಾವು ಅವಲಂಬಿಸಬಹುದಾದ ವೈದ್ಯರನ್ನು ಹೊಂದಿದ್ದೇವೆ ಎಂದು ನಿಜವಾಗಿಯೂ ಸಂತೋಷವಾಗಿದ್ದಾರೆ. ವಿಶೇಷವಾಗಿ ಅವಳು ತಮ್ಮವಳೇ ಆದ ಕಾರಣ.",
              "te": "మీరా తనతో పెరిగిన వ్యక్తులకు సహాయం చేస్తున్నందుకు ఆరోగ్య కేంద్రంలో సంతోషంగా ఉంది. ఆమె పట్టణంలోని ప్రజలు ఇప్పుడు వారికి మంచి డాక్టర్ ఉన్నదుకు మరియు ప్రత్యేకించి ఆమె వారి స్వంత వ్యక్తి కాబట్టి నిజంగా సంతోషంగా ఉన్నారు.",
              "or": "ମୀରା ସ୍ୱାସ୍ଥ୍ୟ କେନ୍ଦ୍ରରେ ଖୁସି ଥିବାରୁ ସେ ଯେଉଁମାନଙ୍କ ସହ କାମ କରୁଥିଲେ ସେମାନଙ୍କୁ\n  ସାହାଯ୍ୟ କରିବାରେ ସକ୍ଷମ ହୋଇଥିଲେ | ତାଙ୍କ ସହରର ଲୋକମାନେ ପ୍ରକୃତରେ ଖୁସି ଯେ ସେମାନଙ୍କର ବର୍ତ୍ତମାନ ଜଣେ ଡାକ୍ତର ଅଛନ୍ତି ଯାହା ଉପରେ ସେମାନେ ନିର୍ଭର କରିପାରିବେ, ବିଶେଷ କରି ସେ ସେମାନଙ୍କର ନିଜର ଅଟନ୍ତି |",
              "as": "মীৰা স্বাস্থ্য কেন্দ্ৰত সুখী কিয়নো তাই লগতে ডাঙৰ হোৱা লোকসকলক সহায় কৰিবলৈ পাৰিছে। তাইৰ চহৰৰ লোকসকল সঁচাকৈয়ে সুখী যে তেওঁলোকৰ ওচৰত এতিয়া এজন চিকিৎসক আছে যাৰ ওপৰত তেওঁলোকে নিৰ্ভৰ কৰিব পাৰে, বিশেষকৈ তাই তেওঁলোকৰ নিজৰ এজন।",
              "gu": "મીરા આરોગ્ય કેન્દ્રમાં ખુશ છે કારણ કે તે જે લોકોની સાથે મોટી થઈ છે તેમની મદદ કરવામાં તે સક્ષમ છે. તેના ગામના લોકો ખરેખર ખુશ છે કે હવે તેમની પાસે એક ડૉક્ટર છે જેના પર તેઓ ભરોસો કરી શકે છે ખાસ કરીને, કારણ કે તે તેમની પોતાની છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "Doctor in a primary healthcare centre",
              "hi": "एक प्राथमिक स्वास्थ्य केंद्र में डॉक्टर",
              "ka": "ಪ್ರಾಥಮಿಕ ಆರೋಗ್ಯ ಕೇಂದ್ರದಲ್ಲಿನ ಒಬ್ಬ ವೈಧ್ಯರು.",
              "te": "ప్రాథమిక ఆరోగ్య కేంద్రంలో వైద్యుడు",
              "or": "ଏକ ପ୍ରାଥମିକ ସ୍ୱାସ୍ଥ୍ୟକେନ୍ଦ୍ରରେ ଡାକ୍ତର",
              "as": "প্ৰাথমিক স্বাস্থ্যসেৱা কেন্দ্ৰত চিকিৎসক",
              "gu": "પ્રાથમિક આરોગ્ય કેન્દ્રમાં ડૉક્ટર"
            }
          }
        ]
      },
      "scene39": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Prepare for PG",
              "hi": "पी जी के लिए तैयार",
              "ka": "ಪಿ‌ಜಿ ಗೆ ತಯಾರಿ",
              "te": "పీజీకి సిద్ధం",
              "or": "PG ପାଇଁ ପ୍ରସ୍ତୁତ କରନ୍ତୁ |",
              "as": "পিজিৰ বাবে প্ৰস্তুত কৰক",
              "gu": "PG માટે તૈયારી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira decides to study for her post-graduation examination while working part-time. She decides to do home visits in the evening between 4 pm to 7 pm.",
              "hi": "मीरा अंशकालिक कार्य करते समय अपनी स्नातकोत्तर परीक्षा के लिए अध्ययन करने का निर्णय लेती है। वह शाम को 4 बजे से 7 बजे के बीच रोगियों को देखने उनके घर जाने का निश्चय करती है।",
              "ka": "ಅರೆಕಾಲಿಕ ಕೆಲಸ ಮಾಡುವಾಗ ಮೀರಾ ತನ್ನ ಸ್ನಾತಕೋತ್ತರ ಪರೀಕ್ಷೆಗೆ ಅಧ್ಯಯನ ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಸಂಜೆ 4 ರಿಂದ 7 ರವರೆಗೆ ಸಂಜೆ ಮನೆಗಳಿಗೆ ಭೇಟಿ ನೀಡಲು ಅವಳು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
              "te": "మీరా పార్ట్ టైమ్ ఉద్యోగం చేస్తూనే పోస్ట్-గ్రాడ్యుయేషన్ పరీక్ష కోసం చదువుకోవాలని నిర్ణయించుకుంది. సాయంత్రం 4 గంటల నుండి 7 గంటల మధ్య సాయంత్రం హోమ్ విజిట్ చేయాలని ఆమె నిర్ణయించుకుంది.",
              "or": "ପାର୍ଟ ଟାଇମ୍ କାମ କରୁଥିବାବେଳେ ମୀରା ସ୍ନାତକୋତ୍ତର ପରୀକ୍ଷଣ ପାଇଁ ଅଧ୍ୟୟନ \n କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ସେ ସନ୍ଧ୍ୟା ୪ ଟାରୁ ସନ୍ଧ୍ୟା ୭ ଟା ମଧ୍ୟରେ ଘର ପରିଦର୍ଶନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "মীৰাই পাৰ্ট টাইম কাম কৰি স্নাতকোত্তৰ পৰীক্ষাৰ বাবে অধ্যয়ন কৰাৰ সিদ্ধান্ত লয়। তাই সন্ধিয়া ৪ বজাৰ পৰা সন্ধিয়া ৭ বজাৰ ভিতৰত ঘৰলৈ গৈ ৰোগী চোৱাৰ সিদ্ধান্ত লয়।",
              "gu": "મીરા બીજો સેમી કામ કરતી વખતે તેની અનુસ્નાતક પરીક્ષા માટે અભ્યાસ કરવાનું નક્કી કરે છે. તે સાંજે 4 થી 7 વાગ્યાની વચ્ચે ઘરની મુલાકાત લેવાનું નક્કી કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_1_2",
              "hi": "B8_1_2",
              "ka": "B8_1_2",
              "te": "B8_1_2",
              "or": "B8_1_2",
              "as": "B8_1_2",
              "gu": "B8_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Word goes around that Mira is offering such medical services, and soon she has calls coming in every day. Most of them are people she knows, as her town is very small.",
              "hi": "मीरा ऐसी चिकित्सा सेवाएं प्रदान कर रही है, यह बात चारों ओर फैल जाती है, और जल्द ही उसके पास हर दिन रोगियों की कॉल आने लगती  है। उनमें से ज्यादातर लोग उसे जानते हैं, क्योंकि उसका शहर बहुत छोटा है।",
              "ka": "ಮೀರಾ ಇಂತಹ ವೈದ್ಯಕೀಯ ಸೇವೆಗಳನ್ನು ನೀಡುತ್ತಿದ್ದಾಳೆ ಎಂಬ ಮಾತುಗಳು ಎಲ್ಲೆಡೆಯೂ ಕೇಳಬರುತ್ತವೆ ಮತ್ತು ಶೀಘ್ರದಲ್ಲೇ ಆಕೆಗೆ ಪ್ರತಿದಿನ ಕರೆಗಳು ಬರಲು ಪ್ರಾರಂಭವಾಗುತ್ತದೆ.. ಅವರಲ್ಲಿ ಹೆಚ್ಚಿನವರು ಅವಳಿಗೆ ತಿಳಿದಿರುವ ಜನರು, ಏಕೆಂದರೆ ಅವಳ ಊರು ತುಂಬಾ ಚಿಕ್ಕದಾಗಿದೆ.",
              "te": "మీరా మంచి వైద్య సేవలను అందిస్తోందని, త్వరలోనే ఆమెకు ప్రతిరోజూ కాల్స్ వస్తున్నాయని వార్తలు వినిపిస్తున్నాయి. ఆమె ఊరు చాలా చిన్నది కాబట్టి వాళ్లలో చాలామంది ఆమెకు తెలిసిన వాళ్లే.",
              "or": "ଶବ୍ଦଟି ବୁଲୁଛି ଯେ ମୀରା ଏପରି ଚିକିତ୍ସା ସେବା ପ୍ରଦାନ କରୁଛନ୍ତି, ଏବଂ ଶୀଘ୍ର ତାଙ୍କୁ\n ପ୍ରତିଦିନ କଲ୍ ଆସୁଛି | ସେମାନଙ୍କ ମଧ୍ୟରୁ ଅଧିକାଂଶ ଲୋକ ଜାଣନ୍ତି, ଯେହେତୁ ତାଙ୍କ ସହର ବହୁତ ଛୋଟ |",
              "as": "চবেই গম পাইছে যে মীৰাই এনে চিকিৎসা সেৱা আগবঢ়াইছে, আৰু সোনকালেই তাইলৈ সদায়ে ফোন আহে। তেওঁলোকৰ বেছিভাগেই তাই চিনি পোৱা মানুহ, কিয়নো তাইৰ চহৰখন অতি সৰু।",
              "gu": "મીરા આવી તબીબી સેવાઓ પ્રદાન કરે છે, અને ટૂંક સમયમાં જ તેને દરરોજ કૉલ્સ આવવા લાગે છે. તેમાંથી મોટા ભાગના લોકો એવા છે જેમને તે ઓળખે છે, કારણ કે તેનું ગામ ખૂબ નાનું છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_1_3",
              "hi": "B8_1_3",
              "ka": "B8_1_3",
              "te": "B8_1_3",
              "or": "B8_1_3",
              "as": "B8_1_3",
              "gu": "B8_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Her home visits bring in enough money for her to live comfortably. Her mother is also working, but her pressure to support the family reduces.",
              "hi": "रोगियों को उनके घर पर देखने के कारण इसको पर्याप्त धन मिलने लगता है। उसकी मां भी काम कर रही है, लेकिन उस पर परिवार को सहयोग करने का दबाव कम हो जाता है।",
              "ka": "ಮನೆಗಳಿಗೆ ನೀಡುವ ಭೇಟಿಗಳು ಅವಳಿಗೆ ಆರಾಮವಾಗಿ ಬದುಕಲು ಸಾಕಷ್ಟು ಹಣವನ್ನು ತರುತ್ತವೆ. ಅವಳ ತಾಯಿ ಕೂಡ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾಳೆ, ಆದರೆ ಕುಟುಂಬವನ್ನು ಬೆಂಬಲಿಸಲು ಅವಳ ಒತ್ತಡ ಕಡಿಮೆಯಾಗುತ್ತದೆ.",
              "te": "ఆమె హోమ్ విజిట్ వల్ల ఆమె ఇబ్బంది లేకుండా జీవించడానికి తగినంత డబ్బు వస్తుంది. ఆమె తల్లి కూడా పని చేస్తోంది, కానీ కుటుంబాన్ని పోషించాలనే ఒత్తిడి తగ్గుతుంది.",
              "or": "ତାଙ୍କ ଘର ପରିଦର୍ଶନ ତାଙ୍କୁ ଆରାମରେ ବଞ୍ଚିବା ପାଇଁ ପର୍ଯ୍ୟାପ୍ତ ଅର୍ଥ ଆଣିଥାଏ |\n  ତାଙ୍କ ମା ମଧ୍ୟ କାମ କରୁଛନ୍ତି, କିନ୍ତୁ ପରିବାର ପ୍ରତିପୋଷଣ ପାଇଁ ତାଙ୍କର ଚାପ କମିଯାଏ |",
              "as": "তাইৰ ঘৰলৈ যোৱাৰ ফলত তাই আৰামেৰে জীয়াই থাকিবলৈ পইচা পাইছে। তাইৰ মাকেও কাম কৰি আছে, কিন্তু পৰিয়ালটোক চাবলৈ অকলে কষ্ট হয়।",
              "gu": "તેના ઘરની મુલાકાતો તેને આરામથી જીવવા માટે પૂરતા પૈસા મળે છે. તેની માતા પણ નોકરી કરે છે, પરંતુ તેના પર પરિવારને ટેકો આપવાનું દબાણ ઓછું થાય છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_2_1",
              "hi": "B8_2_1",
              "ka": "B8_2_1",
              "te": "B8_2_1",
              "or": "B8_2_1",
              "as": "B8_2_1",
              "gu": "B8_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira plans her day around her work so that she gets to study through the morning and then again when she is back after her visits.",
              "hi": "मीरा अपने काम के अनुसार अपने दिन की योजना बनाती है ताकि वह सुबह के समय अध्ययन कर सके और फिर वह रोगियों को देखने के बाद वापस आ जाए।",
              "ka": "ಮೀರಾ ತನ್ನ ಕೆಲಸದ ಸುತ್ತಲೇ ತನ್ನ ದಿನವನ್ನು ಯೋಜಿಸುತ್ತಾಳೆ, ಇದರಿಂದ ಅವಳು ಬೆಳಿಗ್ಗೆ ಮತ್ತು ತನ್ನ ಭೇಟಿಯ ನಂತರ ಹಿಂತಿರುಗಿದಾಗ ಅಧ್ಯಯನವನ್ನು  ಮಾಡುತ್ತಾಳೆ.",
              "te": "మీరా తను రోజులో చేయాల్సిన పనులను ప్లాన్ చేసుకుంటుంది, దీని ద్వారా ఆమె ఉదయం వరకు చదువుకుంటుంది మరియు ఆమె హోమ్ విజిట్ తర్వాత తిరిగి వచ్చినప్పుడు మళ్ళి చదువుతుంది.",
              "or": "ମୀରା ନିଜ କାମରେ ଦିନସାରା ଯୋଜନା କରନ୍ତି ଯାହା ଦ୍ଵାରା ସେ ସକାଳୁ ପଢିବାକୁ \n ପାଆନ୍ତି ଏବଂ ତା’ପରେ ଯେତେବେଳେ ସେ ପରିଦର୍ଶନ ପରେ ଫେରିବେ |",
              "as": "মীৰাই তাইৰ কামৰ লগত দিনটো মিলাই লৈছে যাতে তাই ৰাতিপুৱা পঢ়িবলৈ সময় পায় আৰু যেতিয়া ৰোগী চায় ঘূৰি আহে।",
              "gu": "મીરા તેના કામની આસપાસ તેના દિવસની યોજના બનાવે છે જેથી તે સવાર સુધી અભ્યાસ કરી શકે અને પછી જ્યારે તે તેની મુલાકાતો પછી પાછી આવે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_2_2",
              "hi": "B8_2_2",
              "ka": "B8_2_2",
              "te": "B8_2_2",
              "or": "B8_2_2",
              "as": "B8_2_2",
              "gu": "B8_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "But even the few home visits are tiring, and she finds it difficult to cope after the first month. Stopping the home visits doesn't seem like an option as her family is still struggling financially.",
              "hi": "लेकिन कुछ घरों तक जाना भी थकाने वाला  है, और उसे पहले महीने के बाद ऐसा कर पाना मुश्किल हो जाता है। रोगी के घर जाने को रोकना एक विकल्प की तरह प्रतीत नहीं होता है क्योंकि उसका परिवार अभी भी आर्थिक रूप से संघर्ष कर रहा है।",
              "ka": "ಆದರೆ ಕೆಲವು ಮನೆ ಭೇಟಿಗಳು ಸಹ ಆಯಾಸವನ್ನುಂಟುಮಾಡುತ್ತವೆ ಮತ್ತು ಮೊದಲ ತಿಂಗಳ ನಂತರ ನಿಭಾಯಿಸಲು ಅವಳಿಗೆ ಕಷ್ಟವಾಗುತ್ತದೆ. ಆಕೆಯ ಕುಟುಂಬವು ಇನ್ನೂ ಆರ್ಥಿಕವಾಗಿ ಹೆಣಗಾಡುತ್ತಿರುವ ಕಾರಣ ಮನೆ ಭೇಟಿಗಳನ್ನು ನಿಲ್ಲಿಸುವುದು ಒಂದು ಆಯ್ಕೆಯಂತೆ ತೋರುತ್ತಿಲ್ಲ.",
              "te": "కానీ కొన్ని హోమ్ విజిట్ లకే అలసిపోతుంది మరియు మొదటి నెల తర్వాత దాన్ని భరించడం ఆమెకు కష్టమవుతుంది. కానీ ఆమె కుటుంబం ఇప్పటికీ ఆర్థికంగా కష్టాల్లో ఉండటం వలన హోమ్ విజిట్ లను ఆపడం మంచి ఆప్షన్ గా కనిపించ లేదు.",
              "or": "କିନ୍ତୁ ଅଳ୍ପ କିଛି ଘର ପରିଦର୍ଶନ ମଧ୍ୟ କ୍ଲାନ୍ତକାରୀ, ଏବଂ ପ୍ରଥମ ମାସ ପରେ ତାଙ୍କୁ\n  ସାମ୍ନା କରିବା କଷ୍ଟକର | ଘର ପରିଦର୍ଶନ ବନ୍ଦ କରିବା ଏକ ବିକଳ୍ପ ଭଳି ମନେ ହେଉନାହିଁ କାରଣ ତାଙ୍କ ପରିବାର ଆର୍ଥିକ ଦୃଷ୍ଟିରୁ ସଂଘର୍ଷ କରୁଛନ୍ତି |",
              "as": "কিন্তু আনকি কেইটামান ঘৰলৈ যোৱাটো কষ্ট হয়, আৰু তাই প্ৰথম মাহৰ পিছত মিলোৱা কঠিন হৈ পৰে। ঘৰলৈ যোৱা বন্ধ কৰাটো ভাল হব যেন নালাগে কিয়নো তাইৰ পৰিয়ালে এতিয়াও আৰ্থিক ভাৱে সংগ্ৰাম কৰি আছে।",
              "gu": "પરંતુ ઘરની થોડીક મુલાકાતો પણ કંટાળાજનક હોય છે, અને તેને પ્રથમ મહિના પછી સામનો કરવો મુશ્કેલ લાગે છે. ઘરની મુલાકાતો બંધ કરવી એ કોઈ વિકલ્પ જેવું લાગતું નથી કારણ કે તેનો પરિવાર હજુ પણ આર્થિક રીતે સંઘર્ષ કરી રહ્યો છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_3_1",
              "hi": "B8_3_1",
              "ka": "B8_3_1",
              "te": "B8_3_1",
              "or": "B8_3_1",
              "as": "B8_3_1",
              "gu": "B8_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After a year of studying, she appears for the exam, but she misses the cut-off by two marks. Mira is very sad.",
              "hi": "अध्ययन के एक वर्ष बाद, वह परीक्षा देती है, लेकिन वह कट ऑफ को दो अंकों से पार नहीं कर पाती। मीरा बहुत दुखी है।",
              "ka": "ಒಂದು ವರ್ಷದ ಅಧ್ಯಯನದ ನಂತರ, ಅವಳು ಪರೀಕ್ಷೆಗೆ ಹಾಜರಾಗುತ್ತಾಳೆ, ಆದರೆ ಅವಳು ಎರಡು ಅಂಕಗಳಿಂದ ಕಟ್-ಆಫ್ ಅನ್ನು ತಪ್ಪಿಸಿಕೊಳ್ಳುತ್ತಾಳೆ. ಮೀರಾ ತುಂಬಾ ದುಃಖಿತಳಾಗಿದ್ದಾಳೆ.",
              "te": "ఒక సంవత్సరం చదివిన తర్వాత, ఆమె పరీక్షలు రాస్తుంది కానీ ఆమె రెండు మార్కులతో కటాఫ్‌ను కోల్పోయింది. మీరా చాలా బాధ పడుతుంది.",
              "or": "ଏକ ବର୍ଷ ଅଧ୍ୟୟନ ପରେ, ସେ ପରୀକ୍ଷା ପାଇଁ ଉପସ୍ଥିତ ହୁଅନ୍ତି, କିନ୍ତୁ ସେ ଦୁଇଟି ମାର୍କ\n  ଦ୍ୱାରା କଟ୍ ଅଫ୍ ମିସ୍ କରନ୍ତି | ମୀରା ବହୁତ ଦୁଃଖୀ |",
              "as": "এবছৰ পঢ়া পিছত, তাই পৰীক্ষাত অৱতীৰ্ণ হয়, কিন্তু তাই দুটা নম্বৰৰ কাট-অফ হেৰুৱায়। মীৰা বৰ দুখী।",
              "gu": "એક વર્ષ અભ્યાસ કર્યા પછી, તે પરીક્ષા આપે છે, પરંતુ તે બે ગુણથી કપાત ગુણ ચૂકી જાય છે. મીરા બહુ દુઃખી છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_3_2",
              "hi": "B8_3_2",
              "ka": "B8_3_2",
              "te": "B8_3_2",
              "or": "B8_3_2",
              "as": "B8_3_2",
              "gu": "B8_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She knows that she must start earning, but she can't believe that an entire year of effort was wasted. She feels if she did not have to do the home visits, she would have cleared the exam.",
              "hi": "वह जानती है कि उसे कमाई शुरू करनी होगी, लेकिन वह विश्वास नहीं कर सकती कि प्रयास का एक पूरा वर्ष बर्बाद हो गया। वह अनुभव करती है कि अगर उसे रोगी के घर तक उसको देखने न जाना होता, तो वह परीक्षा उत्तीर्ण कर लेती।",
              "ka": "ಅವಳು ಸಂಪಾದಿಸಲು ಪ್ರಾರಂಭಿಸಬೇಕು ಎನ್ನುವುದು ಅವಳಿಗೆ ತಿಳಿದಿದೆ, ಆದರೆ ಇಡೀ ವರ್ಷದ ಪ್ರಯತ್ನವು ವ್ಯರ್ಥವಾಯಿತು ಎಂದು ಅವಳು ನಂಬುವುದಿಲ್ಲ. ಅವಳು ಮನೆಗೆ ಭೇಟಿ ನೀಡಬೇಕಾಗಿಲ್ಲದಿದ್ದಲ್ಲಿ, ತಾನು ಪರೀಕ್ಷೆಯಲ್ಲಿ ತೇರ್ಗಡೆಯಾಗುತ್ತಿದ್ದೆ ಎಂದು ಅವಳು ಭಾವಿಸುತ್ತಾಳೆ.",
              "te": "తను సంపాదించడం ప్రారంభించాలని ఆమెకు తెలుసు, కానీ ఒక సంవత్సరం మొత్తం పడిన కష్టం వృధా అయిందని ఆమె ఇంకా నమ్మలేకపోయింది. ఆమె హోమ్ విజిట్ లు చేయకపోతే పరీక్షలో పాస్ అయ్యే దానినని భావిస్తుంది.",
              "or": "ସେ ଜାଣନ୍ତି ଯେ ସେ ନିଶ୍ଚିତ ଭାବରେ ରୋଜଗାର କରିବା ଆରମ୍ଭ କରିବେ, କିନ୍ତୁ \n ସେ ବିଶ୍ଵାସ କରିପାରିବେ ନାହିଁ ଯେ ଏକ ବର୍ଷର ପ୍ରୟାସ ନଷ୍ଟ ହୋଇଛି | ସେ ଅନୁଭବ କରନ୍ତି ଯଦି ତାଙ୍କୁ ଘର ପରିଦର୍ଶନ କରିବାକୁ ପଡିବ ନାହିଁ, ତେବେ ସେ ପରୀକ୍ଷା ସଫା କରିଥାନ୍ତେ |",
              "as": "তাই জানে যে তাই উপাৰ্জন আৰম্ভ কৰিব লাগিব, কিন্তু তাই বিশ্বাস কৰিব নোৱাৰে যে গোটেই বছৰৰ প্ৰচেষ্টা এনেই গ'ল। তাই অনুভৱ কৰে যে যদি তাই ঘৰলৈ যাব লগা নহ'লহেঁতেন, তাই পৰীক্ষাত পাছ হ'লহেঁতেন।",
              "gu": "તે જાણે છે કે તેને કમાવાનું શરૂ કરવું જોઈએ, પરંતુ તે માની શકતી નથી કે આખા વર્ષનો પ્રયત્ન વેડફાયો હતો. તેને લાગે છે કે જો તેને ઘરની મુલાકાત લેવાની જરૂર ન હોત, તો તેને પરીક્ષા પાસ કરી હોત."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_4_1",
              "hi": "B8_4_1",
              "ka": "B8_4_1",
              "te": "B8_4_1",
              "or": "B8_4_1",
              "as": "B8_4_1",
              "gu": "B8_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira applies to all the city's health centres and nursing homes and gets a job close to where she stays.",
              "hi": "मीरा शहर के सभी स्वास्थ्य केंद्रों और नर्सिंग होम में आवेदन करती है और वह जहां रहती है, उसके समीप ही उसे एक नौकरी मिल जाती है।",
              "ka": "ಮೀರಾ ನಗರದ ಎಲ್ಲಾ ಆರೋಗ್ಯ ಕೇಂದ್ರಗಳು ಮತ್ತು ನರ್ಸಿಂಗ್ ಹೋಮ್‌ಗಳಿಗೆ ಅರ್ಜಿ ಹಾಕುತ್ತಾಳೆ ಮತ್ತು ಅವಳು ಉಳಿದುಕೊಂಡಿರುವ ಸ್ಥಳಕ್ಕೆ ಹತ್ತಿರದಲ್ಲಿ ಕೆಲಸ ಪಡೆಯುತ್ತಾಳೆ.",
              "te": "మీరా నగరంలోని అన్ని ఆరోగ్య కేంద్రాలు మరియు నర్సింగ్‌ హోమ్‌లకు వెళ్లడానికి అర్హత ఉంది మరియు ఆమె ఉండే ప్రదేశానికి దగ్గరగా జాబ్ సంపాధిస్తుంది.",
              "or": "ମୀରା ସହରର ସମସ୍ତ ସ୍ୱାସ୍ଥ୍ୟ କେନ୍ଦ୍ର ଏବଂ ନର୍ସିଂହୋମ ପାଇଁ ପ୍ରଯୁଜ୍ୟ ଏବଂ ସେ ରହୁଥିବା\n  ସ୍ଥାନରେ ଏକ ଚାକିରି ପାଇଥାଏ |",
              "as": "মীৰাই চহৰৰ সকলো স্বাস্থ্য কেন্দ্ৰ আৰু নাৰ্চিং হোমত আবেদন কৰে আৰু তাই য'ত থাকে তাৰ ওচৰত চাকৰি পায়।",
              "gu": "મીરા શહેરના તમામ આરોગ્ય કેન્દ્રો અને નર્સિંગ હોમમાં અરજી કરે છે અને જ્યાં તે રહે છે તેની નજીક જ તેને નોકરી મળે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_4_2",
              "hi": "B8_4_2",
              "ka": "B8_4_2",
              "te": "B8_4_2",
              "or": "B8_4_2",
              "as": "B8_4_2",
              "gu": "B8_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She learns a lot on the job. She helps the Senior doctors with the everyday patient rush and assists them. She also earns well to take care of herself and contribute to her family.",
              "hi": "वह नौकरी में बहुत कुछ सीखती है। वह वरिष्ठ डॉक्टरों को रोजमर्रा के रोगियों की भीड़ में सहायता करती है और उनकी सहायक बनती है। वह खुद की देखभाल करने और अपने परिवार में योगदान करने के लिए अच्छी तरह से कमाती है।",
              "ka": "ಅವಳು ಕೆಲಸದಲ್ಲಿ ಬಹಳಷ್ಟು ಕಲಿಯುತ್ತಾಳೆ. ಅವಳು ಹೆಚ್ಚಿದ ದೈನಂದಿನ ರೋಗಿಗಳ ಸಮಯದಲ್ಲಿ  ಹಿರಿಯ ವೈದ್ಯರಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತಾಳೆ ಮತ್ತು ಅವರಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತಾಳೆ. ಅವಳು ತನ್ನನ್ನು ನೋಡಿಕೊಳ್ಳಲು ಮತ್ತು ತನ್ನ ಕುಟುಂಬಕ್ಕೆ ಕೊಡುಗೆ ನೀಡಲು ಚೆನ್ನಾಗಿ ಸಂಪಾಡಾಣೆ ಮಾಡುತ್ತಾಳೆ.",
              "te": "ఆమె ఉద్యోగంలో చాలా నేర్చుకుంటుంది. ఆమె రోగులు ఎక్కువగా ఉన్న రోజు సీనియర్ వైద్యులకు సహాయం చేస్తుంది. ఆమె తనను తాను చూసుకోవడానికి మరియు తన కుటుంబానికి సహకరించడానికి కూడా బాగా సంపాదిస్తుంది.",
              "or": "ସେ ଚାକିରିରେ ବହୁତ କିଛି ଶିଖନ୍ତି | ସେ ସିନିୟର ଡାକ୍ତରମାନଙ୍କୁ ଡୈନନ୍ଦିନ ରୋଗୀ\n  ଦୌଡ଼ିବାରେ ସାହାଯ୍ୟ କରନ୍ତି ଏବଂ ସେମାନଙ୍କୁ ସାହାଯ୍ୟ କରନ୍ତି | ସେ ମଧ୍ୟ ନିଜର ଯତ୍ନ ନେବା ଏବଂ ପରିବାରରେ ଅବଦାନ ପାଇଁ ଭଲ ରୋଜଗାର କରନ୍ତି |",
              "as": "তাই কামটোত বহুত শিকে। তাই জ্যেষ্ঠ চিকিৎসকসকলক দৈনন্দিন ৰোগীৰ চোৱাত সহায় কৰে। তাই নিজৰ যত্ন ল'বলৈ আৰু তাইৰ পৰিয়াললৈ সহায় কৰিবলৈ ভাল উপাৰ্জন কৰে।",
              "gu": "તે કામ પર ઘણું શીખે છે. તે વરિષ્ઠ ડોકટરોને રોજબરોજના દર્દીઓની દોડમાં મદદ કરે છે અને મદદ કરે છે. તે પોતાની સંભાળ રાખવા અને તેના પરિવારમાં યોગદાન આપવા માટે સારી કમાણી પણ કરે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_5_1",
              "hi": "B8_5_1",
              "ka": "B8_5_1",
              "te": "B8_5_1",
              "or": "B8_5_1",
              "as": "B8_5_1",
              "gu": "B8_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She prepares for the exam over the next two years. Her parents are keen that Mira should marry, and they begin looking for grooms.",
              "hi": "वह अगले दो वर्षों में परीक्षा के लिए तैयारी करती है। उसके माता-पिता चाहते हैं कि मीरा को शादी कर लेनी चाहिए, और वे दूल्हे की तलाश शुरू कर देते हैं।",
              "ka": "ಮುಂದಿನ ಎರಡು ವರ್ಷಗಳಲ್ಲಿ ಪರೀಕ್ಷೆಗೆ ತಯಾರಿ ನಡೆಸುತ್ತಾಳೆ. ಆಕೆಯ ಪೋಷಕರು ಮೀರಾಗೆ ಮದುವೆಯಾಗಬೇಕೆಂದು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ ಮತ್ತು ಅವರು ವರಗಳನ್ನು ಹುಡುಕಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ.",
              "te": "ఆమె వచ్చే రెండేళ్లలో పరీక్షకు ప్రిపేర్ అవుతుంది. మీరాకు పెళ్లి చేయాలని ఆమె తల్లిదండ్రులు ఆసక్తిగా ఉన్నారు మరియు వారు వరుల కోసం వెతకడం ప్రారంభించారు.",
              "or": "ସେ ଆଗାମୀ ଦୁଇ ବର୍ଷ ମଧ୍ୟରେ ପରୀକ୍ଷା ପାଇଁ ପ୍ରସ୍ତୁତ ହୁଅନ୍ତି | ତାଙ୍କ ପିତାମାତା\n  ଆଗ୍ରହୀ ଯେ ମୀରା ବିବାହ କରିବା ଉଚିତ୍ ଏବଂ ସେମାନେ ବର ଖୋଜିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "তাই পিছৰ দুবছৰত পৰীক্ষাৰ বাবে প্ৰস্তুতি চলালে। তাইৰ মাক-দেউতাকে মীৰাক বিয়া দিব বিচাৰে, আৰু তেওঁলোকে দৰা বিচাৰিবলৈ আৰম্ভ কৰে।",
              "gu": "તે આગામી બે વર્ષમાં પરીક્ષાની તૈયારી કરે છે. તેના માતાપિતા આતુર છે કે મીરાએ લગ્ન કરવું જોઈએ, અને તેઓ વર શોધવાનું શરૂ કરે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_5_2",
              "hi": "B8_5_2",
              "ka": "B8_5_2",
              "te": "B8_5_2",
              "or": "B8_5_2",
              "as": "B8_5_2",
              "gu": "B8_5_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After going through many options, Mira agrees to marry Raghu, a lawyer who has his practice in Guwahati. Both Mira and Raghu have long working hours.",
              "hi": "अनेक विकल्पों को देखने के बाद, मीरा रघु से शादी करने के लिए सहमत हो जाती है, जो एक वकील है और गुवाहाटी में अपनी वकालत करते है। मीरा और रघु दोनों कई घंटों तक काम में व्यस्त रहते हैं।",
              "ka": "ಹಲವು ಆಯ್ಕೆಗಳನ್ನು ನೋಡಿದ ನಂತರ, ಮೀರಾ ಗುವಾಹಟಿಯಲ್ಲಿ ತನ್ನದೇ ಆದ ಕೆಲಸವನ್ನು ಹೊಂದಿರುವ ವಕೀಲ ರಘುವನ್ನು ಮದುವೆಯಾಗಲು ಒಪ್ಪುತ್ತಾಳೆ. ಮೀರಾ ಮತ್ತು ರಘು ಇಬ್ಬರೂ ಸುದೀರ್ಘ ಸಮಯ ಕೆಲಸವನ್ನು ಮಾಡಬೇಕಾಗುತ್ತದೆ.",
              "te": "చాలా ఆప్షన్స్ చూసిన తర్వాత మీరా గౌహతిలో ప్రాక్టీస్ చేస్తున్న న్యాయవాది రఘుని వివాహం చేసుకోవడానికి అంగీకరిస్తుంది. మీరా మరియు రఘు ఇద్దరికీ ఎక్కువ పని గంటలు ఉంటాయి.",
              "or": "ଅନେକ ବିକଳ୍ପ ଅତିକ୍ରମ କରିବା ପରେ, ମୀରା ଗୁଆହାଟୀରେ ଓକିଲାତି ଅଭ୍ୟାସ \n କରୁଥିବା ଜଣେ ଓକିଲ ରଘୁଙ୍କୁ ବିବାହ କରିବାକୁ ରାଜି ହୋଇଥିଲେ | ଉଭୟ ମୀରା ଏବଂ ରଘୁଙ୍କର ଦୀର୍ଘ କାର୍ଯ୍ୟ ସମୟ ଅଛି |",
              "as": "বহুতক চোৱাৰ পিছত, মীৰাই ৰঘু নামৰ এজন উকীলক বিয়া কৰাবলৈ সন্মত হয় যাৰ গুৱাহাটীত কাম আছে। মীৰা আৰু ৰঘু দুয়োৰে দীঘলীয়া কাম কৰে।",
              "gu": "ઘણા વિકલ્પોમાંથી પસાર થયા પછી, મીરા રઘુ સાથે લગ્ન કરવા સંમત થાય છે, તે એક જે વકીલ છે ગુવાહાટીમાં પ્રેક્ટિસ કરે છે. મીરા અને રાઘૂ બંનેમાં વધારે સમય સુધી કામ કરતા હોય છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_3_3",
              "hi": "B8_3_3",
              "ka": "B8_3_3",
              "te": "B8_3_3",
              "or": "B8_3_3",
              "as": "B8_3_3",
              "gu": "B8_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Before getting married, Mira writes her post graduation entrance exam again. She passes with a good score and gets admission to the Tezpur Medical College.",
              "hi": "शादी करने से पहले, मीरा फिर से स्नातकोत्तर प्रवेश परीक्षा देती है। वह एक अच्छे स्कोर से परीक्षा उत्तीर्ण करती है और तेजपुर मेडिकल कॉलेज में प्रवेश प्राप्त करती है।",
              "ka": "ಮದುವೆಯಾಗುವ ಮೊದಲು, ಮೀರಾ ಮತ್ತೆ ತನ್ನ ಸ್ನಾತಕೋತ್ತರ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಯನ್ನು ಬರೆಯುತ್ತಾಳೆ. ಅವಳು ಉತ್ತಮ ಅಂಕಗಳೊಂದಿಗೆ ತೇಜ್ಪುರ್ ವೈದ್ಯಕೀಯ ಕಾಲೇಜಿಗೆ ಪ್ರವೇಶ ಪಡೆಯುತ್ತಾಳೆ.",
              "te": "పెళ్లి కాకముందే, మీరా మళ్లీ పోస్ట్ గ్రాడ్యుయేషన్ ప్రవేశ పరీక్ష రాస్తుంది. ఆమె ఈసారి మంచి స్కోర్‌తో పాస్ అయ్యి తేజ్‌పూర్ మెడికల్ కాలేజీలో అడ్మిషన్ సాధిస్తుంది.",
              "or": "ବିବାହ ପୂର୍ବରୁ ମୀରା ପୁଣିଥରେ ସ୍ନାତକୋତ୍ତର ପ୍ରବେଶିକା ପରୀକ୍ଷା ଲେଖନ୍ତି | ସେ\n  ଭଲ ସ୍କୋର ଦେଇ ପାସ କରି ତେଜପୁର ମେଡିକାଲ କଲେଜରେ ଆଡମିଶନ ପାଆନ୍ତି |",
              "as": "বিয়া হোৱাৰ আগতে, মীৰাই পুনৰ স্নাতকোত্তৰ প্ৰৱেশ পৰীক্ষা দিলে। তাই ভাল নম্বৰ লৈ পাছ হয় আৰু তেজপুৰ চিকিৎসা মহাবিদ্যালয়ত নামভৰ্তি কৰে।",
              "gu": "લગ્ન કરતા પહેલા, મીરા ફરીથી તેની અનુસ્નાતકની પ્રવેશ પરીક્ષા આપે છે. તે સારા ગુણ સાથે પાસ થાય છે અને તેઝપુર મેડિકલ કોલેજમાં પ્રવેશ મેળવે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_6_1",
              "hi": "B8_6_1",
              "ka": "B8_6_1",
              "te": "B8_6_1",
              "or": "B8_6_1",
              "as": "B8_6_1",
              "gu": "B8_6_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira moves to Tezpur to complete her post-graduation. The course is tough with long working hours. Mira enjoys the course though she finds it difficult getting back to studying after a long gap.",
              "hi": "मीरा अपने स्नातकोत्तर को पूरा करने के लिए तेजपुर चली जाती है। पाठ्यक्रम लंबे कामकाजी घंटों के साथ कठिन है। मीरा कोर्स का आनंद लेती है, हालांकि उसे एक लंबे अंतराल के बाद अध्ययन करने में कठिनाई होती है।",
              "ka": "ಮೀರಾ ತನ್ನ ಸ್ನಾತಕೋತ್ತರ ಪದವಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ತೇಜ್‌ಪುರಕ್ಕೆ ತೆರಳುತ್ತಾಳೆ. ದೀರ್ಘಾವಧಿಯ ಕೆಲಸದ ಅವಧಿಯೊಂದಿಗೆ ಕೋರ್ಸ್ ಕಠಿಣವಾಗಿರುತ್ತದೆ. ದೀರ್ಘಾವಧಿಯ ನಂತರ ಅಧ್ಯಯನಕ್ಕೆ ಮರಳಲು ಕಷ್ಟವಾಗಿದ್ದರೂ ಕೂಡ ಮೀರಾ ಕೋರ್ಸ್ ಅನ್ನು ಆನಂದಿಸುತ್ತಾಳೆ.",
              "te": "మీరా తన పోస్ట్-గ్రాడ్యుయేషన్ పూర్తి చేయడానికి తేజ్‌పూర్‌కి వెళుతుంది. ఆ కోర్సులో చాలా పని గంటలతో కఠినంగా ఉంటుంది. చాలా గ్యాప్ తర్వాత తిరిగి చదువుకోవడం కష్టంగా అనిపించినా మీరా కోర్సును ఎంజాయ్ చేస్తుంది.",
              "or": "ସ୍ନାତକୋତ୍ତର ସମାପ୍ତ କରିବାକୁ ମୀରା ତେଜପୁରକୁ ଚାଲିଗଲେ | ଦୀର୍ଘ କାର୍ଯ୍ୟ ସମୟ\n  ସହିତ ପାଠ୍ୟକ୍ରମ କଠିନ ଅଟେ | ମୀରା ପାଠ୍ୟକ୍ରମକୁ ଉପଭୋଗ କରନ୍ତି ଯଦିଓ ଏକ ଦୀର୍ଘ ବ୍ୟବଧାନ ପରେ ଅଧ୍ୟୟନକୁ ଫେରିବା କଷ୍ଟକର ହୁଏ |",
              "as": "মীৰাই স্নাতকোত্তৰ পৰীক্ষা সম্পূৰ্ণ কৰিবলৈ তেজপুৰলৈ যায়। দীঘলীয়া সময় কাম থকা কাৰনে পাঠ্যক্ৰমটো কঠিন। মীৰাই পাঠ্যক্ৰমটো ভাল পায় যদিও দীঘলীয়া বন্ধৰ পিছত পঢ়িবলৈ উভতি অহাটো কঠিন হৈ পৰে।",
              "gu": "મીરા તેનું અનુસ્નાતક પૂરું કરવા તેજપુર જાય છે. લાંબા કામના કલાકો સાથે અભ્યાસક્રમ અઘરો છે. મીરાને અભ્યાસક્રમનો આનંદ માણે છે, જોકે તેને લાંબા અંતર પછી અભ્યાસમાં પાછા આવવું મુશ્કેલ લાગે છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_6_2",
              "hi": "B8_6_2",
              "ka": "B8_6_2",
              "te": "B8_6_2",
              "or": "B8_6_2",
              "as": "B8_6_2",
              "gu": "B8_6_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She is able to clear her exams and complete her post-graduation. She returns to Guwahati and works in a super speciality hospital.",
              "hi": "वह अपनी परीक्षाओं को उत्तीर्ण करने और अपने स्नातकोत्तर को पूरा करने में सक्षम है। वह गुवाहाटी लौटती है और एक सुपर स्पेशलिटी अस्पताल में काम करती है।",
              "ka": "ಅವಳು ತನ್ನ ಪರೀಕ್ಷೆಗಳನ್ನು ತೇರ್ಗಡೆ ಆಗಲು ಮತ್ತು ತನ್ನ ಸ್ನಾತಕೋತ್ತರ ಪದವಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ. ಅವಳು ಗುವಾಹಟಿಗೆ ಹಿಂದಿರುಗುತ್ತಾಳೆ ಮತ್ತು ಸೂಪರ್ ಸ್ಪೆಷಾಲಿಟಿ ಆಸ್ಪತ್ರೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಾಳೆ.",
              "te": "ఆమె తన పరీక్షలను క్లియర్ చేయగలదు మరియు పోస్ట్ గ్రాడ్యుయేషన్ పూర్తి చేయగలదు. ఆమె గౌహతికి తిరిగి వచ్చి సూపర్ స్పెషాలిటీ హాస్పటల్ లో పని చేస్తుంది.",
              "or": "ସେ ତାଙ୍କର ପରୀକ୍ଷାରେ ସଫଳ କରିବାକୁ ଏବଂ ସ୍ନାତକୋତ୍ତର ସମାପ୍ତ କରିବାକୁ ସକ୍ଷମ\n  ଅଟନ୍ତି | ସେ ଗୁଆହାଟିକୁ ଫେରି ଏକ ସୁପର ସ୍ପେସିଆଲିଟି ହସ୍ପିଟାଲରେ କାମ କରନ୍ତି |",
              "as": "তাই তাইৰ পৰীক্ষাত পাছ হবলৈ আৰু স্নাতকোত্তৰ পৰীক্ষা সম্পূৰ্ণ কৰিবলৈ সক্ষম হৈছে। তাই গুৱাহাটীলৈ উভতি আহে আৰু এখন চুপাৰ স্পেচিয়েলিটি চিকিৎসালয়ত কাম কৰে।",
              "gu": "તે તેની પરીક્ષાઓ પાસ કરી શકે છે અને તેનું પોસ્ટ ગ્રેજ્યુએશન પૂર્ણ કરી શકે છે. તે ગુવાહાટી પરત ફરે છે અને એક સુપર સ્પેશિયાલિટી દવાખાનામા કામ કરે છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "Doctor in a super speciality hospital",
              "hi": "एक सुपर स्पेशलिटी अस्पताल में डॉक्टर",
              "ka": "ಸೂಪರ್ ಸ್ಪೆಷಾಲಿಟಿ ಆಸ್ಪತ್ರೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ವೈದ್ಯರು.",
              "te": "సూపర్ స్పెషాలిటీ ఆసుపత్రిలో డాక్టర్",
              "or": "ଏକ ସୁପର ସ୍ପେସିଆଲିଟି ହସ୍ପିଟାଲରେ ଡାକ୍ତର |",
              "as": "চুপাৰ স্পেচিয়েলিটি চিকিৎসালয়ত চিকিৎসক",
              "gu": "સુપર સ્પેશિયાલિટી દવાખાનામાં ડોક્ટર"
            }
          }
        ]
      },
      "scene40": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Apply for bachelors in physics",
              "hi": "भौतिकी में स्नातक के लिए आवेदन करे",
              "ka": "ಭೌತಶಾಸ್ತ್ರದಲ್ಲಿ ಪದವಿಗಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದು.",
              "te": "ఫిజిక్స్ లో బ్యాచిలర్స్ కోసం అప్లై చేసుకోండి",
              "or": "ପଦାର୍ଥ ବିଜ୍ଞାନରେ ସ୍ନାତକ ପାଇଁ ଆବେଦନ କରନ୍ତୁ |",
              "as": "পদাৰ্থ বিজ্ঞানত স্নাতকৰ বাবে আবেদন কৰা",
              "gu": "ભૌતિકશાસ્ત્રમાં સ્નાતક માટે અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira applies to the same college as Lina for B.Sc Physics and gets admission. Admission is based on the 12th-grade marks.",
              "hi": "मीरा बीएससी भौतिकी के लिए लीना के कॉलेज में आवेदन करती है और प्रवेश प्राप्त करती है। प्रवेश बारहवीं कक्षा के अंकों पर आधारित है।",
              "ka": "ಮೀರಾ ಬಿಎಸ್ಸಿ ಭೌತಶಾಸ್ತ್ರಕ್ಕೆ ಲೀನ ಸೇರಿರುವ ಅದೇ ಕಾಲೇಜಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ ಪ್ರವೇಶ ಪಡೆಯುತ್ತಾಳೆ. ಪ್ರವೇಶವು 12 ನೇ ತರಗತಿಯ ಅಂಕಗಳನ್ನು ಆಧರಿಸಿರುತ್ತದೆ.",
              "te": "మీరా B.Sc ఫిజిక్స్ కోసం లీనా చేరిన కాలేజీకే అప్లై చేసి అడ్మిషన్ పొందింది. ఎంట్రన్స్ 12వ తరగతి మార్కుల ఆధారంగా ఉంటుంది.",
              "or": "ମୀରା ବି.ଏସ୍ ଫିଜିକ୍ସ ପାଇଁ ଲୀନା ସହିତ ସମାନ କଲେଜରେ ପ୍ରଯୁଜ୍ୟ ଏବଂ\n  ଆଡମିଶନ ପାଆନ୍ତି | ଦ୍ୱାଦଶ ଶ୍ରେଣୀ ମାର୍କ ଉପରେ ଆଡମିଶନ ଆଧାରିତ |",
              "as": "মীৰাই পদাৰ্থ বিজ্ঞানৰ B.Sc বাবে লীনাৰ দৰে একেখন মহাবিদ্যালয়ত আবেদন কৰে আৰু নামভৰ্তি কৰে। নামভৰ্তি দ্বাদশ শ্ৰেণীৰ নম্বৰৰ ওপৰত আধাৰিত।",
              "gu": "મીરા લીના જેવી જ કોલેજમાં B.Sc ભૌતિકશાસ્ત્રમાં માટે અરજી કરે છે અને પ્રવેશ મેળવે છે. પ્રવેશ 12મા ધોરણના માર્ક્સ પર આધારિત છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_1_2",
              "hi": "E1_1_2",
              "ka": "E1_1_2",
              "te": "E1_1_2",
              "or": "E1_1_2",
              "as": "E1_1_2",
              "gu": "E1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is unhappy and disappointed that she could not clear her NEET exams.",
              "hi": "मीरा नाखुश और निराश है कि वह अपनी नीट परीक्षा को उत्तीर्ण नहीं कर सकी।",
              "ka": "ಮೀರಾ ತನ್ನ NEET ಪರೀಕ್ಷೆಗಳಲ್ಲಿ ಉತ್ತೀರ್ಣಳಾಗಲು ಸಾಧ್ಯವಾಗಲಿಲ್ಲ ಎಂದು ಅತೃಪ್ತಿ ಮತ್ತು ನಿರಾಶೆಗೊಂಡಿದ್ದಾಳೆ.",
              "te": "NEET పరీక్షల్లో పాస్ అవ్వలేకపోయినందుకు మీరా అసంతృప్తి మరియు నిరాశ చెందుతుంది.",
              "or": "ମୀରା ଅସନ୍ତୁଷ୍ଟ ଏବଂ ନିରାଶ ହୋଇଛନ୍ତି ଯେ ସେ ତାଙ୍କ NEET ପରୀକ୍ଷାରେ\n  ସଫଳ କରିପାରିବେ ନାହିଁ |",
              "as": "মীৰা অসন্তুষ্ট আৰু হতাশ যে তাই তাইৰ NEET পৰীক্ষাত উত্তীৰ্ণ হ'ব নোৱাৰিলে।",
              "gu": "મીરા નાખુશ અને નિરાશ છે કે તે તેની NEET પરીક્ષા પાસ કરી શકી નથી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_1_3",
              "hi": "E1_1_3",
              "ka": "E1_1_3",
              "te": "E1_1_3",
              "or": "E1_1_3",
              "as": "E1_1_3",
              "gu": "E1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The only thing that makes her happy is that she gets to spend a lot of time in the laboratory.  The professors encourage them to do projects and research alongside their studies.",
              "hi": "एकमात्र चीज जो उसे खुश करती है वह यह है कि उसे प्रयोगशाला में बिताने के लिए बहुत समय मिलता है। प्रोफेसर उसे अपनी पढ़ाई के साथ प्रोजेक्ट और शोध करने के लिए प्रोत्साहित करते हैं।",
              "ka": "ಪ್ರಯೋಗಾಲಯದಲ್ಲಿ ಹೆಚ್ಚು ಸಮಯ ಕಳೆಯಲು ಸಿಗುತ್ತದೆ ಎನ್ನುವುದೇ ಅವಳಿಗೆ ಖುಷಿ ಕೊಡುವ ವಿಷಯ. ಪ್ರಾಧ್ಯಾಪಕರು ತಮ್ಮ ಅಧ್ಯಯನದ ಜೊತೆಗೆ ಯೋಜನೆಗಳು ಮತ್ತು ಸಂಶೋಧನೆಗಳನ್ನು ಮಾಡಲು ಪ್ರೋತ್ಸಾಹಿಸುತ್ತಾರೆ.",
              "te": "ప్రయోగశాలలో ఎక్కువ సమయం గడపడం ఆమెకు సంతోషాన్ని కలిగించే ఏకైక విషయం. ప్రొఫెసర్లు తమ చదువులతో పాటు ప్రాజెక్ట్ లు మరియు పరిశోధనలు చేయమని ప్రోత్సహిస్తారు.",
              "or": "ତାଙ୍କୁ ଖୁସି କରୁଥିବା ଏକମାତ୍ର ଜିନିଷ ହେଉଛି ସେ ଲାବୋରେଟୋରୀରେ ବହୁତ ସମୟ\n  ବିତାଇବାକୁ ପାଆନ୍ତି | ପ୍ରଫେସରମାନେ ସେମାନଙ୍କ ଅଧ୍ୟୟନ ସହିତ ପ୍ରୋଜେକ୍ଟ ଏବଂ ଅନୁସନ୍ଧାନ କରିବାକୁ ଉତ୍ସାହିତ କରନ୍ତି |",
              "as": "তাইক সুখী কৰা একমাত্ৰ বস্তুটো হ'ল যে তাই লেবত যথেষ্ট সময় কটাব পাৰে। অধ্যাপকসকলে তেওঁলোকক তেওঁলোকৰ পঢ়াৰ লগতে প্ৰকল্প আৰু গৱেষণা কৰিবলৈ উৎসাহিত কৰে।",
              "gu": "એક જ વસ્તુ જે તેને ખુશ કરે છે તે એ છે કે તેને પ્રયોગશાળામાં ઘણો સમય પસાર કરવો પડે છે. પ્રોફેસરો તેમને તેમના અભ્યાસની સાથે પ્રોજેક્ટ અને સંશોધન કરવા પ્રોત્સાહિત કરે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_2_1",
              "hi": "E1_2_1",
              "ka": "E1_2_1",
              "te": "E1_2_1",
              "or": "E1_2_1",
              "as": "E1_2_1",
              "gu": "E1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "A few months later, there is a lot of excitement in the college as they are getting ready for the annual science week celebrations at the planetarium.",
              "hi": "कुछ महीने बाद, कॉलेज में बहुत उत्साह है क्योंकि वे सब प्लेनेटेरियम  में वार्षिक विज्ञान सप्ताह समारोह के लिए तैयार हो रहे हैं।",
              "ka": "ಇದಾದ ಕೆಲವು ತಿಂಗಳ ನಂತರ ತಾರಾಲಯದಲ್ಲಿ ವಾರ್ಷಿಕ ವಿಜ್ಞಾನ ಸಪ್ತಾಹ ಆಚರಣೆಗೆ ಸಿದ್ಧತೆ ನಡೆಸುತ್ತಿರುವ ಕಾಲೇಜಿನಲ್ಲಿ ಸಂಭ್ರಮ ಮನೆ ಮಾಡಿದೆ.",
              "te": "కొన్ని నెలల తర్వాత ప్లానిటోరియంలో వార్షిక సైన్స్ వారోత్సవాలకు రెడీ అవ్వడంతో కళాశాలలో ఉత్కంఠ నెలకొంది.",
              "or": "କିଛି ମାସ ପରେ, ଗ୍ରହ ଗ୍ରହରେ ବାର୍ଷିକ ବିଜ୍ଞାନ ସପ୍ତାହ ଉତ୍ସବ ପାଇଁ ପ୍ରସ୍ତୁତ ହେଉଥିବାରୁ\n  କଲେଜରେ ବହୁତ ଉତ୍ସାହ ଅଛି |",
              "as": "কেইমাহমানৰ পিছত, কলেজত যথেষ্ট উৎপাত আছে কিয়নো তেওঁলোকে প্লেনেটেৰিয়ামত বাৰ্ষিক বিজ্ঞান সপ্তাহ উদযাপনৰ বাবে সাজু হৈ আছে।",
              "gu": "થોડા મહિનાઓ પછી, કોલેજમાં ભારે ઉત્તેજના છે કારણ કે તેઓ પ્લેનેટોરિયમમાં વાર્ષિક વિજ્ઞાન સપ્તાહની ઉજવણી માટે તૈયાર થઈ રહ્યા છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_2_2",
              "hi": "E1_2_2",
              "ka": "E1_2_2",
              "te": "E1_2_2",
              "or": "E1_2_2",
              "as": "E1_2_2",
              "gu": "E1_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira’s professor informs them that all students must attend the week-long science festival. Many scientists are coming to talk to them about their career journey.",
              "hi": "मीरा के प्रोफेसर ने उन्हें सूचित किया कि सभी छात्रों को पूरे सप्ताह तक चलने वाले  लंबे विज्ञान समारोह में भाग लेना चाहिए। कई वैज्ञानिक उनकी करियर यात्रा के बारे में बात करने के लिए आ रहे हैं।",
              "ka": "ಮೀರಾ ಪ್ರಾಧ್ಯಾಪಕರು ವಾರದ ವಿಜ್ಞಾನ ಉತ್ಸವಕ್ಕೆ ಎಲ್ಲಾ ವಿದ್ಯಾರ್ಥಿಗಳು ಕಡ್ಡಾಯವಾಗಿ ಹಾಜರಾಗಬೇಕು ಎಂದು ತಿಳಿಸುತ್ತಾರೆ. ತಮ್ಮ ವೃತ್ತಿಜೀವನದ ಬಗ್ಗೆ ಮಾತನಾಡಲು ಅನೇಕ ವಿಜ್ಞಾನಿಗಳು ಬರುತ್ತಿದ್ದಾರೆ.",
              "te": "వారం రోజుల పాటు జరిగే సైన్స్ ఫెస్టివల్‌కు విద్యార్థులందరూ తప్పనిసరిగా హాజరు కావాలని మీరా ప్రొఫెసర్ వారికి చెబుతున్నారు. వారి కెరీర్ జర్నీ గురించి మాట్లాడేందుకు చాలా మంది శాస్త్రవేత్తలు వస్తున్నారని తెలిపారు.",
              "or": "ମୀରାଙ୍କ ପ୍ରଫେସର ସେମାନଙ୍କୁ ସୂଚନା ଦେଇଛନ୍ତି ଯେ ସମସ୍ତ ଛାତ୍ରମାନେ ସପ୍ତାହବ୍ୟାପୀ\n  ବିଜ୍ଞାନ ମହୋତ୍ସବରେ ଯୋଗଦେବା ଆବଶ୍ୟକ | ଅନେକ ବଇଜ୍ଞାନିକ ସେମାନଙ୍କ କ୍ୟାରିୟର ଯାତ୍ରା ବିଷୟରେ କଥାବାର୍ତ୍ତା କରିବାକୁ ଆସୁଛନ୍ତି |",
              "as": "মীৰাৰ অধ্যাপকে তেওঁলোকক জনায় যে সকলো শিক্ষাৰ্থীয়ে সপ্তাহজোৰা বিজ্ঞান মহোৎসৱত উপস্থিত থাকিব লাগিব। বহুতো বিজ্ঞানী তেওঁলোকৰ কেৰিয়াৰযাত্ৰাৰ বিষয়ে তেওঁলোকৰ সৈতে কথা পাতিবলৈ আহি আছে।",
              "gu": "મીરાના પ્રોફેસરે તેમને જાણ કરી કે તમામ વિદ્યાર્થીઓએ અઠવાડિયા સુધી ચાલનારા વિજ્ઞાન ઉત્સવમાં હાજરી આપવી આવશ્યક છે. ઘણા વૈજ્ઞાનિકો તેમની સાથે તેમની કારકિર્દીની સફર વિશે વાત કરવા આવી રહ્યા છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_2_3",
              "hi": "E1_2_3",
              "ka": "E1_2_3",
              "te": "E1_2_3",
              "or": "E1_2_3",
              "as": "E1_2_3",
              "gu": "E1_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They have also planned to screen some exciting movies on science as part of the festival.",
              "hi": "उन्होंने महोत्सव के हिस्से के रूप में विज्ञान पर कुछ रोमांचक फिल्मों को स्क्रीन करने की भी योजना बनाई है।",
              "ka": "ಹಬ್ಬದ ಅಂಗವಾಗಿ ವಿಜ್ಞಾನಕ್ಕೆ ಸಂಬಂಧಿಸಿದ ಕೆಲವು ರೋಚಕ ಚಲನಚಿತ್ರಗಳನ್ನು ಪ್ರದರ್ಶಿಸಲು ಅವರು ಯೋಜಿಸಿದ್ದಾರೆ",
              "te": "పండగలో భాగంగా సైన్స్ కు సంబంధించిన కొన్ని ఉత్తేజకరమైన సినిమాలను కూడా చూపించడానికి ప్లాన్ చేశారు.",
              "or": "ଉତ୍ସବର ଏକ ଅଂଶ ଭାବରେ ସେମାନେ ବିଜ୍ଞାନ ଉପରେ କିଛି ରୋମାଞ୍ଚକର ଚଳଚ୍ଚିତ୍ର \n ସ୍କ୍ରିନ କରିବାକୁ ଯୋଜନା କରିଛନ୍ତି |",
              "as": "তেওঁলোকে উৎসৱৰ অংশ হিচাপে বিজ্ঞানৰ ওপৰত চলচ্চিত্ৰ দেখুৱাৰ কথা ভাবিছে ।",
              "gu": "તેઓએ ફેસ્ટિવલના ભાગરૂપે વિજ્ઞાન પર કેટલીક આકર્ષક ફિલ્મો દર્શાવવાનું પણ આયોજન કર્યું છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_2_4////",
              "hi": "E1_2_4////",
              "ka": "E1_2_4////",
              "te": "E1_2_4////",
              "or": "E1_2_4////",
              "as": "E1_2_4////",
              "gu": "E1_2_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I'm so excited about the celebrations at the planetarium! Mira, you will come with me, won't you?",
              "hi": "मैं प्लेनेटेरियम में होने वाले समारोहों के बारे में बहुत उत्साहित हूं! मीरा, तुम मेरे साथ आओगी, है ना?",
              "ka": "ತಾರಾಲಯದಲ್ಲಿನ ಆಚರಣೆಗಳ ಬಗ್ಗೆ ನಾನು ತುಂಬಾ ಉತ್ಸುಕಳಾಗಿದ್ದೇನೆ! ಮೀರಾ, ನೀನು ನನ್ನ ಜೊತೆ ಬರುವೆ ಅಲ್ಲವೇ?",
              "te": "ప్లానిటోరియంలో జరిగే వేడుకల గురించి నేను చాలా సంతోషిస్తున్నాను! మీరా, నువ్వు నాతో వస్తావు కదా?",
              "or": "ନକ୍ଷତ୍ର ଭବନରେ ଉତ୍ସବକୁ ନେଇ ମୁଁ ବହୁତ ଉତ୍ସାହିତ! ମୀରା, ତୁମେ ମୋ ସହିତ ଆସିବ\n , ନୁହେଁ କି?",
              "as": "মই প্লেনেটেৰিয়ামত উদযাপনৰ বিষয়ে বহুত উৎসাহিত! মীৰা, তুমি মোৰ লগত আহিবা, নহয় নে?",
              "gu": "હું પ્લેનેટોરિયમમાં ઉજવણી વિશે ખૂબ જ ઉત્સાહિત છું! મીરા, તું મારી સાથે આવીશ ને?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I think I will stay at home next week, Lina. We have exams coming up, and I am barely coping with the subjects taught in class.",
              "hi": "मुझे लगता है कि मैं अगले हफ्ते घर पर रहूंगी, लीना। हमारी परीक्षाएं पास आ रही हैं, और मैं मुश्किल से कक्षा में पढ़ाए गए विषयों को समझ पा रही हूं।",
              "ka": "ಮುಂದಿನ ವಾರ ನಾನು ಮನೆಯಲ್ಲಿಯೇ ಇರುತ್ತೇನೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ, ಲೀನಾ. ನಮಗೆ ಪರೀಕ್ಷೆಗಳು ಬರುತ್ತಿವೆ ಮತ್ತು ತರಗತಿಯಲ್ಲಿ ಕಲಿಸಿದ ವಿಷಯಗಳನ್ನು ನಾನು ನಿಭಾಯಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ.",
              "te": "నేను వచ్చే వారం ఇంట్లోనే ఉంటానని అనుకుంటున్నాను, లీనా. మాకు పరీక్షలు రాబోతున్నాయి మరియు నేను క్లాస్‌లో చెప్పే సబ్జెక్ట్ లు సరిపోవడం లేదు",
              "or": "ମୁଁ ଭାବୁଛି ମୁଁ ଆସନ୍ତା ସପ୍ତାହରେ ଘରେ ରହିବି, ଲୀନା | ଆମର ପରୀକ୍ଷା ଆସୁଛି, \n ଏବଂ ମୁଁ କ୍ଲାସରେ ପଢାଯାଇଥିବା ବିଷୟଗୁଡ଼ିକ ସହିତ କମ୍ ମୁକାବିଲା କରୁଛି |",
              "as": "মই ভাবিছোঁ মই অহা সপ্তাহত ঘৰত থাকিম, লীনা। আমাৰ পৰীক্ষা আহি আছে, আৰু মই শ্ৰেণীত শিকুৱা কথাবোৰ ভালকৈ বুজি নাই পোৱা।",
              "gu": "મને લાગે છે કે હું આવતા અઠવાડિયે ઘરે રહીશ, લીના. મારે પરીક્ષાઓ આવી રહી છે નજદીકમાં, અને હું વર્ગમાં ભણાવવામાં આવતા વિષયોનો ભાગ્યે જ સામનો કરી રહી છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Are you serious?  Mira, you have to come! A young scientist from Assam who was part of the Chandrayan launch will be talking to us. This doesn't happen all the time!",
              "hi": "क्या तुम सच कह रही हो? मीरा, तुम्हें आना होगा! असम के एक युवा वैज्ञानिक जो चंद्रयान लॉन्च का हिस्सा थे, हमसे बात करेंगे। ऐसा हर बार नहीं होता है!",
              "ka": "ನೀನು ಸೀರಿಯಸ್ ಆಗಿದ್ದೀಯಾ? ಮೀರಾ, ನೀನು ಬರಲೇಬೇಕು! ಚಂದ್ರಯಾನ ಉಡಾವಣೆಯ ಭಾಗವಾಗಿದ್ದ ಅಸ್ಸಾಂನ ಯುವ ವಿಜ್ಞಾನಿಯೊಬ್ಬರು ನಮ್ಮೊಂದಿಗೆ ಮಾತನಾಡಲಿದ್ದಾರೆ. ಇದು ಎಲ್ಲಾ ಸಮಯದಲ್ಲೂ ನಡೆಯುವುದಿಲ್ಲ!",
              "te": "నువ్వు నిజంగా అంటున్నావా? మీరా, నువ్వు రావాలి! చంద్రయాన్ ప్రయోగంలో భాగమైన అస్సాంకు చెందిన యువ శాస్త్రవేత్త మనతో మాట్లాడబోతున్నారు. ఇది అన్ని వేళలా జరగదు!",
              "or": "ତମେ ଗମ୍ଭିର କି? ମୀରା, ତୁମେ ଆସିବାକୁ ପଡିବ! ଆସାମର ଜଣେ ଯୁବ ବଇଜ୍ଞାନିକ \n ଯିଏ ଚନ୍ଦ୍ରଯାନ ଲଞ୍ଚର ଅଂଶ ଥିଲେ ସେ ଆମ ସହ କଥା ହେବେ। ଏହା ସବୁବେଳେ ଘଟେ ନାହିଁ!",
              "as": "সঁচা নে? মীৰা, তুমি আহিব লাগিব! চন্দ্ৰযান মিচনত অংশ হোৱা অসমৰ এজন যুৱ বিজ্ঞানীয়ে আমাৰ সৈতে কথা পাতিব। এইটো সকলো সময়তে নহয়!",
              "gu": "તું ચોક્કસ છું મીરા, તારે આવવું પડશે! આસામના એક યુવા વૈજ્ઞાનિક જે ચંદ્રયાન પ્રક્ષેપણનો ભાગ હતા તે આપની સાથે વાત કરશે. આ બધા સમયે મળતું નથી!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Okay. I'll try to come.",
              "hi": "ठीक। मैं आने की कोशिश करूँगी।",
              "ka": "ಓ‌ಕೆ, ನಾನು ಬರಲು ಪ್ರಯತ್ನಿಸುತ್ತೇನೆ",
              "te": "సరే. నేను రావడానికి ప్రయత్నిస్తాను.",
              "or": "ଠିକ ଅଛି। ମୁଁ ଆସିବାକୁ ଚେଷ୍ଟା କରିବି |",
              "as": "হ'ব। মই আহিবলৈ চেষ্টা কৰিম।",
              "gu": "બરાબર. હું આવવાનો પ્રયત્ન કરીશ."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_3_1",
              "hi": "E1_3_1",
              "ka": "E1_3_1",
              "te": "E1_3_1",
              "or": "E1_3_1",
              "as": "E1_3_1",
              "gu": "E1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Just for Lina, Mira visits the planetarium. She is amazed by the science demonstrations and feels excited about attending talks.",
              "hi": "सिर्फ लीना के लिए, मीरा प्लेनेटेरियम जाती है। वह विज्ञान प्रदर्शनों से आश्चर्यचकित है और बातचीत में भाग लेने के बारे में उत्साहित है।",
              "ka": "ಲೀನಾಗಾಗಿ ಮೀರಾ ತಾರಾಲಯಕ್ಕೆ ಭೇಟಿ ನೀಡುತ್ತಾಳೆ. ಅವರ ವಿಜ್ಞಾನ ಪ್ರದರ್ಶನಗಳಿಂದ ಆಶ್ಚರ್ಯಚಕಿತರಾಗಿದ್ದಾರೆ ಮತ್ತು ಮಾತುಕತೆಗಳಿಗೆ ಹಾಜರಾಗಲು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ.",
              "te": "లీనా కోసం, మీరా ప్లానిటోరియంను చూస్తుంది. ఆమె సైన్స్ ప్రదర్శనలను చూసి ఆశ్చర్యపోయింది మరియు చర్చ్లకి వెళ్ళడం కోసం ఉత్సాహంగా ఉంది.",
              "or": "କେବଳ ଲୀନା ପାଇଁ, ମୀରା ଗ୍ରହ ଗ୍ରହକୁ ପରିଦର୍ଶନ କରନ୍ତି | ବିଜ୍ଞାନ ପ୍ରଦର୍ଶନ ଦ୍ୱାରା\n  ସେ ଆଶ୍ଚର୍ଯ୍ୟ ହୋଇଯାଇଛନ୍ତି ଏବଂ ଆଲୋଚନାରେ ଯୋଗଦେବାକୁ ଉତ୍ସାହିତ ଅନୁଭବ କରୁଛନ୍ତି |",
              "as": "কেৱল লীনাৰ বাবে, মীৰাই প্লেনেটেৰিয়ামপৰিদৰ্শন কৰে। তাই বিজ্ঞানৰ প্ৰদৰ্শনৰ দ্বাৰা আচৰিত হৈছে আৰু আলোচনাত উপস্থিত থকাৰ বাবে উৎসাহিত অনুভৱ কৰে।",
              "gu": "ફક્ત લીના માટે, મીરા પ્લેનેટોરિયમની મુલાકાત લે છે. તે વિજ્ઞાનના પ્રદર્શનોથી આશ્ચર્યચકિત થાય છે અને વાર્તાલાપમાં ભાગ લેવા માટે ઉત્સાહિત લાગે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_3_2///",
              "hi": "E1_3_2///",
              "ka": "E1_3_2///",
              "te": "E1_3_2///",
              "or": "E1_3_2///",
              "as": "E1_3_2///",
              "gu": "E1_3_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "She goes with Lina and attends the talks. Seeing the interest and passion with which the scientists share about their research, Mira is reminded of her excitement in the laboratory.",
              "hi": "वह लीना के साथ जाती है और वार्ता में भाग लेती है। रुचि और जुनून को देखते हुए जिनके साथ वैज्ञानिक अपने शोध के बारे में साझा करते हैं, मीरा को प्रयोगशाला में उनके उत्साह की याद दिलाई जाती है।",
              "ka": "ಅವಳು ಲೀನಾ ಜೊತೆ ಹೋಗಿ ಮಾತುಕತೆಗೆ ಹಾಜರಾಗುತ್ತಾಳೆ. ವಿಜ್ಞಾನಿಗಳು ತಮ್ಮ ಸಂಶೋಧನೆಯ ಬಗ್ಗೆ ಹಂಚಿಕೊಳ್ಳುವ ಆಸಕ್ತಿ ಮತ್ತು ಉತ್ಸಾಹವನ್ನು ನೋಡಿ, ಮೀರಾ ಪ್ರಯೋಗಾಲಯದಲ್ಲಿ ತನ್ನ ಉತ್ಸಾಹವನ್ನು ನೆನಪಿಸಿಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "ఆమె లీనాతో వెళ్లి చర్చలకు హాజరవుతుంది. శాస్త్రవేత్తలు తమ పరిశోధనల గురించి పంచుకునే ఆసక్తి మరియు అభిరుచిని చూసి, మీరా ప్రయోగశాలలో తనకి వచ్చే ఉత్సాహాన్ని గుర్తుచేసుకుంది.",
              "or": "ସେ ଲୀନାଙ୍କ ସହ ଯାଇ ଆଲୋଚନାରେ ଯୋଗ ଦିଅନ୍ତି | ବଇଜ୍ଞାନିକମାନେ ସେମାନଙ୍କ\n  ଅନୁସନ୍ଧାନ ବିଷୟରେ ଆଗ୍ରହ ଏବଂ ଉତ୍ସାହ ଦେଖି ମିରାଙ୍କୁ ଲାବୋରେଟୋରୀରେ ଥିବା ଉତ୍ସାହକୁ ମନେ ପକାଇଲେ |",
              "as": "তাই লীনাৰ সৈতে যায় আৰু আলোচনাত উপস্থিত থাকে। বিজ্ঞানীসকলে তেওঁলোকৰ গৱেষণাৰ বিষয়ে যি আগ্ৰহেৰে কয় সেয়া দেখি মীৰাক তাইৰ লেবত থকা সময়খিনিৰ ভাল লগা খিনি মনত পেলাই দিযয়ে।",
              "gu": "તે લીના સાથે જાય છે અને વાર્તાલાપમાં ભાગ લે છે. વૈજ્ઞાનિકો તેમના સંશોધન વિશે જે રસ અને જુસ્સા સાથે શેર કરે છે તે જોઈને મીરાને પ્રયોગશાળામાં તેની ઉત્તેજના યાદ આવે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_3_3",
              "hi": "E1_3_3",
              "ka": "E1_3_3",
              "te": "E1_3_3",
              "or": "E1_3_3",
              "as": "E1_3_3",
              "gu": "E1_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "From the second day onwards, she plans for herself which talks she is interested in attending.",
              "hi": "दूसरे दिन के बाद से, वह स्वयं के लिए योजना बनाती है कि वह कौन सी वार्ता में भाग लेने में रुचि रखती है।",
              "ka": "ಎರಡನೇ ದಿನದಿಂದ, ಅವಳು ಹಾಜರಾಗಲು ಆಸಕ್ತಿ ಹೊಂದಿರುವ ವಿಷಯಗಳನ್ನು ತಾನೇ ಯೋಜಿಸುತ್ತಾಳೆ",
              "te": "రెండవ రోజు నుండి, ఆమె వెళ్లడానికి ఆసక్తి ఉన్న చర్చలను స్వయంగా ప్లాన్ చేసుకుంటుంది.",
              "or": "ଦ୍ୱିତୀୟ ଦିନଠାରୁ, ସେ ନିଜ ପାଇଁ ଯୋଜନା କରନ୍ତି ଯେଉଁଥିରେ ସେ ଯୋଗଦେବାକୁ \n ଆଗ୍ରହୀ ଅଟନ୍ତି |",
              "as": "দ্বিতীয় দিনৰ পৰা, তাই ভাবে কোনবোৰ লেকচাৰত তাই উপস্থিত থাকিবলৈ আগ্ৰহী হব।",
              "gu": "બીજા દિવસથી, તે પોતાને માટે આયોજન કરે છે કે તેને કઈ વાર્તાઓમાં ભાગ લેવા માટે રસ છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_4_1",
              "hi": "E1_4_1",
              "ka": "E1_4_1",
              "te": "E1_4_1",
              "or": "E1_4_1",
              "as": "E1_4_1",
              "gu": "E1_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Scientists and researchers from different fields interact with the students to share their career journey in science.",
              "hi": "विभिन्न क्षेत्रों के वैज्ञानिक और शोधकर्ता छात्रों के साथ विज्ञान में अपनी करियर यात्रा साझा करने के लिए बातचीत करते हैं।",
              "ka": "ವಿವಿಧ ಕ್ಷೇತ್ರಗಳ ವಿಜ್ಞಾನಿಗಳು ಮತ್ತು ಸಂಶೋಧಕರು ವಿಜ್ಞಾನದಲ್ಲಿ ತಮ್ಮ ವೃತ್ತಿಜೀವನವನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ವಿದ್ಯಾರ್ಥಿಗಳೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸುತ್ತಾರೆ.",
              "te": "వివిధ రంగాలకు చెందిన శాస్త్రవేత్తలు మరియు పరిశోధకులు సైన్స్ లో వారి కెరీర్ జర్నీని పంచుకోవడానికి విద్యార్థులతో మాట్లాడరు.",
              "or": "ବିଭିନ୍ନ କ୍ଷେତ୍ରରେ ବଇଜ୍ଞାନିକ ଏବଂ ଅନୁସନ୍ଧାନକାରୀମାନେ ବିଜ୍ଞାନରେ ସେମାନଙ୍କର\n  କ୍ୟାରିୟର ଯାତ୍ରା ବାଣ୍ଟିବା ପାଇଁ ଛାତ୍ରମାନଙ୍କ ସହିତ ଯୋଗାଯୋଗ କରନ୍ତି |",
              "as": "বিভিন্ন ক্ষেত্ৰৰ বিজ্ঞানী আৰু গৱেষকসকলে বিজ্ঞানত তেওঁলোকৰ কেৰিয়াৰযাত্ৰা বিষয়ে শিক্ষাৰ্থীসকলৰ লগত কথা বতৰা পাতে।",
              "gu": "વિવિધ ક્ષેત્રોના વૈજ્ઞાનિકો અને સંશોધકો વિજ્ઞાનમાં તેમની કારકિર્દીની સફર બતાવે છે વિદ્યાર્થીઓ સાથે વાર્તાલાપ કરે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_4_2",
              "hi": "E1_4_2",
              "ka": "E1_4_2",
              "te": "E1_4_2",
              "or": "E1_4_2",
              "as": "E1_4_2",
              "gu": "E1_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira feels inspired by Dr Nidhi Sharma. Her journey from a small district in Assam to ISRO motivates Mira. Dr Nidhi also talks about the Chandrayan project and how they prepared for the launch.",
              "hi": "मीरा डॉ निधि शर्मा से प्रेरित होती है। असम के एक छोटे जिले से इसरो तक की उनकी यात्रा मीरा को प्रेरित करती है। डॉ निधि भी चंद्रयान प्रोजेक्ट और उसके लॉन्च के लिए कैसी तैयारी करी गई, इसके बारे में बात करती हैं।",
              "ka": "ಮೀರಾ, ಡಾ ನಿಧಿ ಶರ್ಮಾ ಅವರಿಂದ ಸ್ಫೂರ್ತಿ ಪಡೆದಿದ್ದಾಳೆ. ಅಸ್ಸಾಂನ ಸಣ್ಣ ಜಿಲ್ಲೆಯಿಂದ ಇಸ್ರೋಗೆ ಆಕೆಯ ಪ್ರಯಾಣವು ಮೀರಾಗೆ ಪ್ರೇರಣೆ ನೀಡುತ್ತದೆ. ಡಾ ನಿಧಿ ಅವರು ಚಂದ್ರಯಾನ ಯೋಜನೆ ಮತ್ತು ಉಡಾವಣೆಗೆ ಹೇಗೆ ಸಿದ್ಧಪಡಿಸಿದರು ಎಂಬುದರ ಕುರಿತು ಮಾತನಾಡುತ್ತಾರೆ.",
              "te": "మీరా డాక్టర్ నిధి శర్మను ఆదర్శంగా తీసుకుంది. అస్సాంలోని ఒక చిన్న జిల్లా నుండి ఇస్రోకు ఆమె ప్రయాణం మీరాను ప్రేరేపించింది. డాక్టర్ నిధి చంద్రయాన్ ప్రాజెక్ట్ గురించి మరియు వారి ప్రయోగానికి ఎలా సిద్ధమయ్యారు అనే విషయం గురించి కూడా చెప్పారు.",
              "or": "ମୀରା ଡାଃ ନିଧି ଶର୍ମାଙ୍କ ଦ୍ୱାରା ଅନୁପ୍ରାଣିତ ଅନୁଭବ କରନ୍ତି | ଆସାମର ଏକ ଛୋଟ\n  ଜିଲ୍ଲା ରୁ ISRO ପର୍ଯ୍ୟନ୍ତ ତାଙ୍କର ଯାତ୍ରା ମୀରାଙ୍କୁ ଉତ୍ସାହିତ କରେ | ଡାଃ ନିଧି ଚନ୍ଦ୍ରଯାନ ପ୍ରକଳ୍ପ ଏବଂ ଉନ୍ମୋଚନ ପାଇଁ ସେମାନେ କିପରି ପ୍ରସ୍ତୁତ ହୋଇଥିଲେ ସେ ବିଷୟରେ ମଧ୍ୟ ଆଲୋଚନା କରିଛନ୍ତି।",
              "as": "মীৰাই ড০ নিধি শৰ্মাৰ দ্বাৰা অনুপ্ৰাণিত অনুভৱ কৰে। অসমৰ এখন সৰু জিলাৰ পৰা ইছৰোলৈ তাইৰ যাত্ৰাই মীৰাক অনুপ্ৰাণিত কৰে। ড০ নিধিয়ে চন্দ্ৰযান প্ৰকল্পআৰু তেওঁলোকে কেনেকৈ মিচনৰ বাবে প্ৰস্তুতি কৰিছিল তাৰ বিষয়েও কয়।",
              "gu": "મીરા ડૉક્ટર નિધિ શર્માથી પ્રેરિત અનુભવે છે. આસામના નાના જિલ્લામાંથી ISRO સુધીની તેના સફરમાં  મીરાને પ્રેરણા આપે છે. ડૉ. નિધિ ચંદ્રયાન પ્રોજેક્ટ વિશે પણ વાત કરે છે અને તેઓએ તે મોકલવા માટે કેવી રીતે તૈયારી કરી હતી."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_4_3",
              "hi": "E1_4_3",
              "ka": "E1_4_3",
              "te": "E1_4_3",
              "or": "E1_4_3",
              "as": "E1_4_3",
              "gu": "E1_4_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She also talks about her career path and journey as a woman scientist. She shares that she was inspired by the book written by Dr APJ Abdul Kalam to become a scientist.",
              "hi": "वह एक महिला वैज्ञानिक के रूप में अपने करियर पथ और यात्रा के बारे में भी बात करती हैं। वह साझा करती हैं कि वह एक वैज्ञानिक बनने के लिए डॉ ए पी जे अब्दुल कलाम द्वारा लिखी गई पुस्तक से प्रेरित थीं।",
              "ka": "ಅವರು ಮಹಿಳಾ ವಿಜ್ಞಾನಿಯಾಗಿ ತಮ್ಮ ವೃತ್ತಿಜೀವನದ ಹಾದಿ ಮತ್ತು ಪ್ರಯಾಣದ ಬಗ್ಗೆ ಮಾತನಾಡುತ್ತಾರೆ. ಡಾ ಎಪಿಜೆ ಅಬ್ದುಲ್ ಕಲಾಂ ಅವರು ಬರೆದ ಪುಸ್ತಕದಿಂದ ವಿಜ್ಞಾನಿಯಾಗಲು ಸ್ಫೂರ್ತಿ ಪಡೆದಿದ್ದೇನೆ ಎಂದು ಅವರು ಹಂಚಿಕೊಂಡರು.",
              "te": "ఆమె తన కెరీర్ మార్గం మరియు మహిళా శాస్త్రవేత్తగా ప్రయాణం గురించి కూడా మాట్లాడుతుంది. Dr APJ అబ్దుల్ కలాం రాసిన పుస్తకం ద్వారా తాను శాస్త్రవేత్త కావడానికి ప్రేరణ పొందానని ఆమె చెబుతుంది.",
              "or": "ସେ ଜଣେ ମହିଳା ବଇଜ୍ଞାନିକ ଭାବରେ ତାଙ୍କ କ୍ୟାରିଅର୍ ପଥ ଏବଂ ଯାତ୍ରା ବିଷୟରେ ମଧ୍ୟ \n କଥାବାର୍ତ୍ତା କରନ୍ତି | ସେ ଅଂଶୀଦାର କରିଛନ୍ତି ଯେ ଡାଃ ଏପିଜେ ଅବଦୁଲ କଲାମଙ୍କ ଦ୍ୱାରା ଲିଖିତ ପୁସ୍ତକ ଦ୍ୱାରା ସେ ବଜ୍ଞାନିକ ହେବା ପାଇଁ ଅନୁପ୍ରାଣିତ ହୋଇଥିଲେ।",
              "as": "তাই এগৰাকী মহিলা বিজ্ঞানী হিচাপে তাইৰ কেৰিয়াৰৰ পথ আৰু যাত্ৰাৰ বিষয়েও কয়। তাই কৈছিল যে তাই ডঃ এ পি জে আব্দুল কালামৰ দ্বাৰা লিখা কিতাপখনৰ দ্বাৰা এজন বিজ্ঞানী হ'বলৈ মন কৰিছিল।",
              "gu": "તે એક મહિલા વૈજ્ઞાનિક તરીકેની કારકિર્દીના માર્ગ અને પ્રવાસ વિશે પણ વાત કરે છે. તે બતાવે છે કે તેને વૈજ્ઞાનિક બનવા માટે ડૉ એપીજે અબ્દુલ કલામ દ્વારા લખાયેલ પુસ્તકથી પ્રેરણા મળી હતી."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_5_1",
              "hi": "E1_5_1",
              "ka": "E1_5_1",
              "te": "E1_5_1",
              "or": "E1_5_1",
              "as": "E1_5_1",
              "gu": "E1_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira goes to the library the next day and borrows the book Wings of Fire\" by Dr APJ Abdul Kalam. Mira starts reading the book and is engrossed in it.\"",
              "hi": "मीरा अगले दिन लाइब्रेरी जाती है और डॉ ए पी जे अब्दुल कलाम की किताब \"विंग्स ऑफ़ फायर\" पढ़ने के लिए लाती है। मीरा पुस्तक को पढ़ना शुरू कर देती है और उसी में लीन हो जाती है। \"",
              "ka": "ಮೀರಾ ಮರುದಿನ ಲೈಬ್ರರಿಗೆ ಹೋಗಿ ಡಾ ಎಪಿಜೆ ಅಬ್ದುಲ್ ಕಲಾಂ ಅವರ ವಿಂಗ್ಸ್ ಆಫ್ ಫೈರ್ ಪುಸ್ತಕವನ್ನು ಎರವಲು ಪಡೆದಳು. ಮೀರಾ ಪುಸ್ತಕವನ್ನು ಓದಲು ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ ಮತ್ತು ಅದರಲ್ಲಿ ಮಗ್ನಳಾಗಿದ್ದಾಳೆ",
              "te": "మీరా మరుసటి రోజు లైబ్రరీకి వెళ్లి డాక్టర్ APJ అబ్దుల్ కలాం రచించిన వింగ్స్ ఆఫ్ ఫైర్ పుస్తకాన్ని తెచ్చుకుంది. మీరా పుస్తకాన్ని చదవడం ప్రారంభించి దానిలో మునిగిపోయింది.\"",
              "or": "ପରଦିନ ମୀରା ଲାଇବ୍ରେରୀକୁ ଯାଇ ଡାଃ ଏପିଜେ ଅବଦୁଲ କଲାମଙ୍କ ୱିଙ୍ଗ୍ସ ଫାୟାର\n  ପୁସ୍ତକ ମାଗି ଆଣିଥିଲେ। ମୀରା ପୁସ୍ତକ ପଢିବା ଆରମ୍ଭ କରି ସେଥିରେ ବ୍ୟସ୍ତ ଥିଲେ।",
              "as": "মীৰাই পিছদিনা পুথিভঁৰাললৈ যায় আৰু ড০ এ পি জে আব্দুল কালামৰ উইংছ অফ ফায়াৰ\" কিতাপখন ধাৰলৈ লয়। মীৰাই কিতাপখন পঢ়িবলৈ আৰম্ভ কৰে আৰু ইয়াত মগ্ন হৈ থাকে।",
              "gu": "મીરા બીજા દિવસે લાઈબ્રેરીમાં જાય છે અને ડૉ. એપીજે અબ્દુલ કલામનું પુસ્તક વિંગ્સ ઑફ ફાયર\" ઉધાર લે છે. મીરા પુસ્તક વાંચવાનું શરૂ કરે છે અને તેમાં મગ્ન થઈ જાય છે.\""
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_5_2",
              "hi": "E1_5_2",
              "ka": "E1_5_2",
              "te": "E1_5_2",
              "or": "E1_5_2",
              "as": "E1_5_2",
              "gu": "E1_5_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "It inspires her so much that she feels like a new person. She can look at her choice of study from a new angle and wants to become a scientist like Dr Karidhal and Dr Kalam.",
              "hi": "यह उसे इतना प्रेरित करता है कि वह एक नए व्यक्ति की तरह अनुभव करती है। वह अध्ययन की अपनी पसंद को एक नए दृष्टिकोण से  देख सकती है और डॉ करिधल और डॉ कलाम जैसे वैज्ञानिक बनना चाहती है।",
              "ka": "ಇದು ಅವಳನ್ನು ತುಂಬಾ ಪ್ರೇರೇಪಿಸುತ್ತದೆ, ಅವಳು ಹೊಸ ವ್ಯಕ್ತಿಯಂತೆ ಭಾಸವಾಗುತ್ತಾಳೆ. ಅವಳು ತನ್ನ ಅಧ್ಯಯನದ ಆಯ್ಕೆಯನ್ನು ಹೊಸ ಕೋನದಿಂದ ನೋಡಬಹುದು ಮತ್ತು ಡಾ ಕರಿದಾಲ್ ಮತ್ತು ಡಾ ಕಲಾಂ ಅವರಂತೆ ವಿಜ್ಞಾನಿಯಾಗಲು ಬಯಸುತ್ತಾಳೆ.",
              "te": "ఇది ఆమెకు చాలా స్ఫూర్తినిస్తుంది, ఆమె కొత్త వ్యక్తిలా అనిపిస్తుంది. ఆమె తను సెలెక్ట్ చేసుకున్నా డిపార్ట్ మెంట్ ని కొత్త కోణంలో చూడవచ్చు మరియు డాక్టర్ కరిధాల్ మరియు డాక్టర్ కలాం వంటి శాస్త్రవేత్త కావాలని కోరుకుంటుంది.",
              "or": "ଏହା ତାଙ୍କୁ ଏତେ ପ୍ରେରଣା ଦିଏ ଯେ ସେ ଜଣେ ନୂତନ ବ୍ୟକ୍ତି ପରି ଅନୁଭବ କରନ୍ତି |\n  ସେ ଏକ ନୂତନ କୋଣରୁ ଅଧ୍ୟୟନର ପସନ୍ଦକୁ ଦେଖିପାରିବେ ଏବଂ ଡାଃ କରିଦାଲ ଏବଂ ଡାଃ କଲାମଙ୍କ ପରି ବଜ୍ଞାନିକ ହେବାକୁ ଚାହାଁନ୍ତି |",
              "as": "এইটোৱে তাইক ইমান অনুপ্ৰাণিত কৰে যে তাই এজন নতুন ব্যক্তিৰ দৰে অনুভৱ কৰে। তাই তাইৰ পঢ়া শুনা নতুন দিশৰ পৰা চাব আৰম্ভ কৰে আৰু ডঃ কাৰিধল আৰু ড০ কালামৰ দৰে বিজ্ঞানী হ'ব বিচাৰে।",
              "gu": "તે તેને એટલી પ્રેરણા આપે છે કે તે એક નવી વ્યક્તિની જેમ અનુભવે છે. તે તેની અભ્યાસની પસંદગીને નવા ખૂણાથી જોઈ શકે છે અને તે ડૉ. કરીધલ અને ડૉ. કલામ જેવા વૈજ્ઞાનિક બનવા માંગે છે."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "SuccessStory",
              "hi": "सफलता की कहानी",
              "ka": "ಯಶಸ್ಸಿನ ಕಥೆ",
              "te": "విజయ గాధ",
              "or": "ସଫଳତାର କାହାଣୀ",
              "as": "সফলতাৰ কাহিনী",
              "gu": "સફળતાની વાર્તા"
            }
          }
        ]
      },
      "scene41": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_1",
              "hi": "E2_1_1",
              "ka": "E2_1_1",
              "te": "E2_1_1",
              "or": "E2_1_1",
              "as": "E2_1_1",
              "gu": "E2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira concentrates and works hard through all the semesters at college. She learns that there are so many different areas of specialisation in Physics.",
              "hi": "मीरा कॉलेज के सभी सेमेस्टर में ध्यान केंद्रित करती है और कठिन परिश्रम करती है। वह सीखती है कि भौतिकी में विशेषज्ञता के कई अलग-अलग क्षेत्र हैं।",
              "ka": "ಮೀರಾ ಕಾಲೇಜಿನಲ್ಲಿ ಎಲ್ಲಾ ಸೆಮಿಸ್ಟರ್‌ಗಳಲ್ಲಿ ಏಕಾಗ್ರತೆ ಮತ್ತು ಕಷ್ಟಪಟ್ಟು ಓದುತ್ತಾಳೆ. ಭೌತಶಾಸ್ತ್ರದಲ್ಲಿ ವಿಶೇಷತೆಯ ಹಲವಾರು ಕ್ಷೇತ್ರಗಳಿವೆ ಎನ್ನುವುದನ್ನು ಅವಳು ಕಲಿಯುತ್ತಾಳೆ.",
              "te": "మీరా కళాశాలలో అన్ని సెమిస్టర్‌లలో ఏకాగ్రతతో మరియు కష్టపడి పని చేస్తుంది. ఫిజిక్స్ లో స్పెషలైజేషన్‌లో చాలా విభిన్న విభాగాలు ఉన్నాయని ఆమె తెలుసుకుంది.",
              "or": "ମୀରା କଲେଜରେ ସମସ୍ତ ସେମିଷ୍ଟାର ମାଧ୍ୟମରେ ଏକାଗ୍ର ହୋଇ କଠିନ ପରିଶ୍ରମ କରେ | \n ସେ ଜାଣନ୍ତି ଯେ ପଦାର୍ଥ ବିଜ୍ଞାନରେ ବିଶେଷଜ୍ଞତାର ଅନେକ ଭିନ୍ନ କ୍ଷେତ୍ର ଅଛି |",
              "as": "মীৰাই মহাবিদ্যালয়ৰ সকলো ছেমিষ্টাৰত মনোযোগ দিয়ে আৰু বহুত পৰিশ্ৰম কৰে। তাই শিকিলে যে পদাৰ্থ বিজ্ঞানত বিশেষত্বৰ বহুতো ক্ষেত্ৰ আছে।",
              "gu": "મીરા કોલેજમાં તમામ સેમેસ્ટરમાં ધ્યાન કેન્દ્રિત કરે છે અને સખત મહેનત કરે છે. તે શીખે છે કે ભૌતિકશાસ્ત્રમાં વિશેષતાના ઘણા જુદા જુદા ક્ષેત્રો છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_2",
              "hi": "E2_1_2",
              "ka": "E2_1_2",
              "te": "E2_1_2",
              "or": "E2_1_2",
              "as": "E2_1_2",
              "gu": "E2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She is fascinated by the use of nanotechnology in different fields. She reads more about it and how this technology is used in every aspect of life, from mobile to medicine to clothes.",
              "hi": "वह विभिन्न क्षेत्रों में नैनो टेक्नोलॉजी के उपयोग से प्रभावित, है। वह इसके बारे में और अधिक पढ़ती है कि इस टेक्नोलॉजी का उपयोग जीवन के हर पहलू में, मोबाइल से दवा तक, कपड़े तक कैसे किया जाता है।",
              "ka": "ವಿವಿಧ ಕ್ಷೇತ್ರಗಳಲ್ಲಿ ನ್ಯಾನೊ ತಂತ್ರಜ್ಞಾನದ ಬಳಕೆಯಿಂದ ಅವಳು ಆಕರ್ಷಿತಳಾಗಿದ್ದಾಳೆ. ಅವಳು ಅದರ ಬಗ್ಗೆ ಹೆಚ್ಚು ಓದುತ್ತಾಳೆ ಮತ್ತು ಮೊಬೈಲ್‌ನಿಂದ ಔಷಧದಿಂದ ಬಟ್ಟೆಯವರೆಗೆ ಜೀವನದ ಪ್ರತಿಯೊಂದು ಅಂಶದಲ್ಲೂ ಈ ತಂತ್ರಜ್ಞಾನವನ್ನು ಹೇಗೆ ಬಳಸಲಾಗುತ್ತದೆ ಎನ್ನುವುದನ್ನು.",
              "te": "ఆమె వివిధ రంగాలలో నానో టెక్నాలజీని ఉపయోగించడం పట్ల ఆకర్షితురాలైంది. ఆమె దాని గురించి మరింత చదివి మొబైల్, మెడిసిన్, మరియు బట్టలు వరకు జీవితంలోని ప్రతి అంశంలో టెక్నాలజీ ఎలా ఉపయోగించబడుతోందో తెలుసుకుంటుంది.",
              "or": "ବିଭିନ୍ନ କ୍ଷେତ୍ରରେ ନାନୋଟେକ୍ନୋଲୋଜିର ବ୍ୟବହାର ଦ୍ୱାରା ସେ ଆକର୍ଷିତ ହୋଇଥିଲେ |\n  ସେ ଏହା ବିଷୟରେ ଅଧିକ ପଢନ୍ତି ଏବଂ ମୋବାଇଲ୍ ଠାରୁ ମେଡିସିନ୍ ପର୍ଯ୍ୟନ୍ତ ପୋଷାକ ପର୍ଯ୍ୟନ୍ତ ଜୀବନର ପ୍ରତ୍ୟେକ କ୍ଷେତ୍ରରେ ଏହି ଟେକ୍ନୋଲୋଜି କିପରି ବ୍ୟବହୃତ ହୁଏ |",
              "as": "তাই বিভিন্ন ক্ষেত্ৰত নেনোপ্ৰযুক্তিৰ ব্যৱহাৰৰ দেখি আকৰ্ষিত হয়। তাই ইয়াৰ বিষয়ে অধিক পঢ়ে যে কেনেকৈ জীৱনৰ প্ৰতিটো দিশত ব্যৱহাৰ কৰা হয়, মোবাইলৰ পৰা ঔষধৰ পৰা কাপোৰলৈকে।",
              "gu": "તે વિવિધ ક્ષેત્રોમાં નેનો ટેકનોલોજીના ઉપયોગથી આકર્ષિત છે. તે તેના વિશે વધુ વાંચે છે અને કેવી રીતે આ ટેક્નોલોજીનો ઉપયોગ જીવનના દરેક ક્ષેત્રમાં થાય છે, મોબાઈલથી લઈને દવાથી લઈને કપડાં સુધી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_3",
              "hi": "E2_1_3",
              "ka": "E2_1_3",
              "te": "E2_1_3",
              "or": "E2_1_3",
              "as": "E2_1_3",
              "gu": "E2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira starts preparing for the entrance exams. She wants to do her Master’s in Nanotechnology. Her professor suggests that she apply to IISER Pune and Bhopal.",
              "hi": "मीरा प्रवेश परीक्षा के लिए तैयारी शुरू कर देती है। वह नैनो टेक्नोलॉजी में मास्टर्स करना चाहती है। उसके प्रोफेसर से पता चलता है कि वह आई आई एस ई आर पुणे और भोपाल में आवेदन करती है।",
              "ka": "ಮೀರಾ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಗೆ ತಯಾರಿ ಆರಂಭಿಸುತ್ತಾಳೆ. ಅವಳು ನ್ಯಾನೊತಂತ್ರಜ್ಞಾನದಲ್ಲಿ ತನ್ನ ಸ್ನಾತಕೋತ್ತರ ಪದವಿಯನ್ನು ಮಾಡಲು ಬಯಸುತ್ತಾಳೆ. ಆಕೆಯ ಪ್ರೊಫೆಸರ್ ಅವರು IISER ಪುಣೆ ಮತ್ತು ಭೋಪಾಲ್‌ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವಂತೆ ಸೂಚಿಸುತ್ತಾರೆ.",
              "te": "మీరా ప్రవేశ పరీక్షలకు ప్రిపేర్ అవుతోంది. ఆమె నానోటెక్నాలజీలో మాస్టర్స్ చేయాలనుకుంటుంది. ఆమె IISER పూణే మరియు భోపాల్‌లకు అప్లై చేసుకోవాలని ఆమె ప్రొఫెసర్ సూచిస్తున్నారు.",
              "or": "ମୀରା ପ୍ରବେଶିକା ପରୀକ୍ଷା ପାଇଁ ପ୍ରସ୍ତୁତି ଆରମ୍ଭ କରନ୍ତି | ସେ ନାନୋଟେକ୍ନୋଲୋଜିରେ\n  ମାଷ୍ଟର କରିବାକୁ ଚାହୁଁଛନ୍ତି | ତାଙ୍କ ପ୍ରଫେସର ପରାମର୍ଶ ଦେଇଛନ୍ତି ଯେ ସେ IISER ପୁଣେ ଏବଂ ଭୋପାଳରେ ଆବେଦନ କରନ୍ତୁ |",
              "as": "মীৰাই প্ৰৱেশ পৰীক্ষাৰ বাবে প্ৰস্তুতি আৰম্ভ কৰে। তাই নেনো টেকন'লজীত তাইৰ স্নাতকোত্তৰ কৰিব বিচাৰে। তাইৰ অধ্যাপকে পৰামৰ্শ দিছে যে IISER পুনে আৰু ভূপালত আবেদন কৰিব লাগে।",
              "gu": "મીરા પ્રવેશ પરીક્ષાની તૈયારી કરવા લાગે છે. તે નેનોટેકનોલોજીમાં માસ્ટર્સ કરવા માંગે છે. તેના પ્રોફેસર સૂચવે છે કે તે IISER પુણે અને ભોપાલમાં અરજી કરે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_4",
              "hi": "E2_1_4",
              "ka": "E2_1_4",
              "te": "E2_1_4",
              "or": "E2_1_4",
              "as": "E2_1_4",
              "gu": "E2_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After completing the Bachelor's course, both Lina and Mira are keen to study further. Mira goes home to prepare for the entrance.",
              "hi": "स्नातक पाठ्यक्रम पूरा करने के बाद, लीना और मीरा दोनों आगे अध्ययन करने के इच्छुक हैं। मीरा प्रवेश परीक्षा हेतु तैयारी करने के लिए घर जाती है।",
              "ka": "ಬ್ಯಾಚುಲರ್ ಕೋರ್ಸ್ ಮುಗಿದ ನಂತರ, ಲೀನಾ ಮತ್ತು ಮೀರಾ ಇಬ್ಬರೂ ಮುಂದೆ ಓದಲು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ. ಮೀರಾ ಪ್ರವೇಶಕ್ಕೆ ತಯಾರಿ ಮಾಡಲು ಮನೆಗೆ ಹೋಗುತ್ತಾಳೆ.",
              "te": "బ్యాచిలర్ కోర్సు పూర్తి చేసిన తర్వాత, లీనా మరియు మీరా ఇద్దరూ మరింత చదవడానికి ఆసక్తిగా ఉన్నారు. మీరా ఎంట్రన్స్ పరీక్ష కోసం సిద్ధం కావడానికి ఇంటికి వెళుతుంది.",
              "or": "ସ୍ନାତକ ପାଠ୍ୟକ୍ରମ ସମାପ୍ତ କରିବା ପରେ ଉଭୟ ଲୀନା ଏବଂ ମୀରା ଅଧିକ ଅଧ୍ୟୟନ\n  କରିବାକୁ ଆଗ୍ରହୀ | ମୀରା ପ୍ରବେଶ ଦ୍ୱାର ପାଇଁ ପ୍ରସ୍ତୁତ ହେବାକୁ ଘରକୁ ଯାଆନ୍ତି |",
              "as": "বেচেলৰ পাঠ্যক্ৰম সম্পূৰ্ণ কৰাৰ পিছত, লীনা আৰু মীৰা দুয়ো আৰু অধ্যয়ন কৰিবলৈ আগ্ৰহী। মীৰা পৰীক্ষাৰ কাৰনে প্ৰস্তুতি কৰিবলৈ ঘৰলৈ যায়।",
              "gu": "સ્નાતકનો કોર્સ પૂરો કર્યા બાદ લીના અને મીરા બંને આગળ અભ્યાસ કરવા ઉત્સુક છે. મીરા પ્રવેશની તૈયારી કરવા ઘરે જાય છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_2_1",
              "hi": "E2_2_1",
              "ka": "E2_2_1",
              "te": "E2_2_1",
              "or": "E2_2_1",
              "as": "E2_2_1",
              "gu": "E2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As she is preparing for her exams, her parents are concerned about her future. They are unsure what jobs she would get by doing research work.",
              "hi": "जब वह अपनी परीक्षाओं की तैयारी करती है, उसके माता-पिता उसके भविष्य के बारे में चिंतित हैं। वे अनिश्चित हैं कि शोध कार्य करने से उसे कौन सी नौकरियां मिलेंगी।",
              "ka": "ಅವಳು ಪರೀಕ್ಷೆಗೆ ತಯಾರಿ ನಡೆಸುತ್ತಿರುವಾಗ, ಆಕೆಯ ಪೋಷಕರು ಅವಳ ಭವಿಷ್ಯದ ಬಗ್ಗೆ ಚಿಂತಿತರಾಗಿದ್ದಾರೆ. ಸಂಶೋಧನಾ ಕಾರ್ಯವನ್ನು ಮಾಡುವ ಮೂಲಕ ಆಕೆಗೆ ಯಾವ ಉದ್ಯೋಗಗಳು ಸಿಗುತ್ತವೆ ಎನ್ನುವುದು ಅವರಿಗೆ ಖಚಿತವಿಲ್ಲ.",
              "te": "పరీక్షలకు సిద్ధమవుతున్న సమయంలో ఆమె భవిష్యత్తుపై తల్లిదండ్రులు ఆందోళన చెందుతున్నారు. రీసెర్చ్ వర్క్ చేయడం ద్వారా ఆమెకు ఎలాంటి ఉద్యోగాలు వస్తాయని వారు సందేహిస్తున్నారు.",
              "or": "ସେ ତାଙ୍କ ପରୀକ୍ଷା ପାଇଁ ପ୍ରସ୍ତୁତ ହେଉଥିବାବେଳେ ତାଙ୍କ ପିତାମାତା ତାଙ୍କ ଭବିଷ୍ୟତ\n  ପାଇଁ ଚିନ୍ତିତ ଅଛନ୍ତି | ଅନୁସନ୍ଧାନ କାର୍ଯ୍ୟ କରି ସେ କେଉଁ ଚାକିରି ପାଇବେ ତାହା ସେମାନେ ନିଶ୍ଚିତ ନୁହଁନ୍ତି |",
              "as": "যিহেতু তাই তাইৰ পৰীক্ষাৰ বাবে প্ৰস্তুতি চলাই আছে, তাইৰ মাক-দেউতাক তাইৰ ভৱিষ্যতৰ বিষয়ে চিনন্তা কৰিছে। তেওঁলোক নাজানে যে গৱেষণাৰ কাম কৰি তাই কি চাকৰি পাব।",
              "gu": "જેમ તે તેની પરીક્ષાની તૈયારી કરી રહી છે, તેના માતાપિતા તેના ભવિષ્ય વિશે ચિંતિત છે. તેઓ અનિશ્ચિત છે કે સંશોધન કાર્ય કરીને તેને કઈ નોકરી મળશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_2_2",
              "hi": "E2_2_2",
              "ka": "E2_2_2",
              "te": "E2_2_2",
              "or": "E2_2_2",
              "as": "E2_2_2",
              "gu": "E2_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "One day when Mira returns home, she sees her mother and Shantanu sitting at the table, looking very troubled.",
              "hi": "एक दिन जब मीरा घर लौटकर आई, वह अपनी मां और शांतनु को मेज पर बैठे देखती है, जो बहुत परेशान लग रहे हैं।",
              "ka": "ಒಂದು ದಿನ ಮೀರಾ ಮನೆಗೆ ಹಿಂದಿರುಗಿದಾಗ, ಅವಳು ತನ್ನ ತಾಯಿ ಮತ್ತು ಶಂತನು ಮೇಜಿನ ಬಳಿ ಕುಳಿತಿದ್ದು ಸ್ವಲ್ಪ ಚಿಂತಿತರಾಗಿರುವುದನ್ನು ನೋಡುತ್ತಾಳೆ. .",
              "te": "ఒక రోజు మీరా ఇంటికి తిరిగి వచ్చినప్పుడు, ఆమె తన తల్లి మరియు షాంతను టేబుల్ వద్ద కూర్చుని చాలా ఇబ్బందిగా ఉండటం చూస్తుంది.",
              "or": "ଦିନେ ମୀରା ଘରକୁ ଫେରିବା ପରେ ସେ ଦେଖିଲେ ଯେ ତାଙ୍କ ମା ଏବଂ ଶାନ୍ତନୁ ଟେବୁଲ\n  ଉପରେ ବସି ବହୁତ କଷ୍ଟ ଅନୁଭବ କରୁଛନ୍ତି |",
              "as": "এদিন যেতিয়া মীৰা ঘৰলৈ উভতি আহে, তাই তাইৰ মাক আৰু শান্তনুক মেজৰ ওচৰত বহি থকা দেখিলে, বৰ দুখত।",
              "gu": "એક દિવસ જ્યારે મીરા ઘરે પાછી ફરે છે, ત્યારે તે તેની માતા અને શાંતનુને ટેબલ પર બેઠેલા જુએ છે, ખૂબ જ પરેશાન દેખાતા હતા."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_2_3 bg",
              "hi": "E2_2_3 बीजी",
              "ka": "E2_2_3 bg",
              "te": "E2_2_3 bg",
              "or": "E2_2_3 bg",
              "as": "E2_2_3 bg",
              "gu": "E2_2_3 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shantanu",
              "hi": "शांतनु",
              "ka": "ಶಂತನು",
              "te": "షాంతను",
              "or": "ଶାନ୍ତନୁ",
              "as": "শান্তনু",
              "gu": "શાંતનુ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "What happened?",
              "hi": "क्या हुआ?",
              "ka": "ಏನಾಯಿತು?",
              "te": "ఏమి జరిగింది?",
              "or": "କଣ ହେଲା?",
              "as": "কি হ'ল?",
              "gu": "શું થયું?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Didi, I found out that I got admission into the design college in Delhi. There is a course on animation that I will be able to do for three years.",
              "hi": "दीदी, मैंने पता किया कि मुझे दिल्ली में डिजाइन कॉलेज में प्रवेश मिला है। एनीमेशन पर एक कोर्स है जो कि मैं तीन साल में कर पाऊंगा।",
              "ka": "ದೀದಿ, ನನಗೆ ದೆಹಲಿಯ ವಿನ್ಯಾಸ ಕಾಲೇಜಿಗೆ ಪ್ರವೇಶ ಸಿಕ್ಕಿದೆ ಅಂತ ಗೊತ್ತಾಗಿದೆ. ಅನಿಮೇಷನ್ ಕೋರ್ಸ್ ಇದೆ, ನಾನು ಮೂರು ವರ್ಷಗಳಲ್ಲಿ ಅದನ್ನು ಮಾಡಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ",
              "te": "దీదీ, నేను ఢిల్లీలోని డిజైన్ కాలేజీలో అడ్మిషన్ పొందినట్లు తెలుసుకున్నాను. నేను మూడేళ్లపాటు చేయగలిగే యానిమేషన్ కోర్సు ఉంది.",
              "or": "ଦିଦି, ମୁଁ ଜାଣିବାକୁ ପାଇଲି ଯେ ମୁଁ ଦିଲ୍ଲୀର ଡିଜାଇନ୍ କଲେଜରେ ଆଡମିଶନ ପାଇଛି\n  | ଆନିମେସନ୍ ଉପରେ ଏକ ପାଠ୍ୟକ୍ରମ ଅଛି ଯାହାକୁ ମୁଁ ତିନି ବର୍ଷ ପର୍ଯ୍ୟନ୍ତ କରିବାକୁ ସମର୍ଥ ହେବି |",
              "as": "বা, মই গম পাইছোঁ যে মই দিল্লীৰ ডিজাইন কলেজত চিট পালো। এনিমেছনৰ ওপৰত এটা পাঠ্যক্ৰম আছে যিটো মই তিনি বছৰৰ বাবে কৰিব পাৰিম।",
              "gu": "દીદી, મને ખબર પડી કે મને દિલ્હીની ડિઝાઇન કોલેજમાં એડમિશન મળી ગયું છે. એનિમેશનનો કોર્સ છે જે હું ત્રણ વર્ષ માટે કરી શકીશ."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_2_4///",
              "hi": "E2_2_4///",
              "ka": "E2_2_4///",
              "te": "E2_2_4///",
              "or": "E2_2_4///",
              "as": "E2_2_4///",
              "gu": "E2_2_4///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "That’s great news, Shantanu! I’m so proud of you. But why do you both look upset?",
              "hi": "यह अच्छी खबर है, शांतनु! मुझे तुम पर गर्व है। लेकिन तुम दोनों क्यों परेशान हो?",
              "ka": "ಅದೊಂದು ಒಳ್ಳೆಯ ಸುದ್ದಿ, ಶಂತನು! ನನಗೆ ನಿನ್ನ ಬಗ್ಗೆ ಹೆಮ್ಮೆ ಇದೆ. ಆದರೆ ನೀವಿಬ್ಬರೂ ಏಕೆ ಚಿಂತಿತರಾಗಿದ್ದೀರಿ?",
              "te": "అది గొప్ప వార్త, షాంతను! నేను నిన్ను చూసి చాలా గర్వపడుతున్నాను. కానీ మీరిద్దరూ ఎందుకు బాధ పడుతున్నారు?",
              "or": "ଏହା ଏକ ବଡ଼ ଖବର, ଶାନ୍ତନୁ! ମୁଁ ତୁମ ପାଇଁ ବହୁତ ଗର୍ବିତ କିନ୍ତୁ ତୁମେ ଦୁହେଁ କାହିଁକି \n ବିରକ୍ତ ଦେଖାଯାଉଛ?",
              "as": "এইটো এটা ভাল খবৰ, শান্তনু! মই তোমাক লৈ বহুত গৌৰৱান্বিত। কিন্তু তোমালোক ইমান দুখত কিয় আছা?",
              "gu": "તે સારા સમાચાર છે, શાંતનુ! મને તારા પર ગર્વ છે. પણ તમે બંને અસ્વસ્થ કેમ દેખાઓ છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": ". . .",
              "hi": ". . .",
              "ka": ". . .",
              "te": ". . .",
              "or": ". . .",
              "as": ". . .",
              "gu": ". . ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Your father called in the afternoon, he said that the company had to let go of some people in his team.",
              "hi": "तुम्हारे पिता ने दोपहर में कॉल करके बताया, उन्होंने कहा कि कंपनी को अपनी टीम में से कुछ लोगों को हटाना पड़ा",
              "ka": "ನಿಮ್ಮ ತಂದೆ ಮಧ್ಯಾಹ್ನ ಕರೆ ಮಾಡಿದ್ದರು, ಕಂಪನಿಯು ಅವರ ತಂಡದಲ್ಲಿ ಕೆಲವು ಜನರನ್ನು ಬಿದುವ ಯೋಚನೆ ಮಾಡುತ್ತಿದೆ ಎಂದು ಹೇಳಿದರು.",
              "te": "మీ నాన్న మధ్యాహ్నం ఫోన్ చేసాడు, కంపెనీ తన టీమ్‌లోని కొంతమందిని తీసేసినట్లు చెప్పారు.",
              "or": "ତୁମ ବାପା ଅପରାହ୍ନରେ ଫୋନ୍ କରିଥିଲେ, ସେ କହିଥିଲେ ଯେ କମ୍ପାନୀକୁ ତାଙ୍କ\n  ଦଳର କିଛି ଲୋକଙ୍କୁ ଛାଡିବାକୁ ପଡିବ |",
              "as": "আপোনাৰ দেউতাই আবেলি ফোন কৰিছিল, তেওঁ কৈছিল যে কোম্পানীটোৱে তেওঁৰ দলৰ কেইজনমান মানুহক চাকৰি পৰা এৰি দিছে।",
              "gu": "તારા પિતાએ બપોરે ફોન કર્યો, તેમણે કહ્યું કે કંપનીએ તેમની ટીમના કેટલાક લોકોને કાઢી દેવા પડશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "What? Papa lost his job? How?",
              "hi": "क्या? पापा ने अपना काम खो दिया? कैसे?",
              "ka": "ಏನು? ಪಾಪಾ ಕೆಲಸವನ್ನು ಕಳೆದುಕೊಂಡರೆ? ಹೇಗೆ?",
              "te": "ఏమిటి? పాప ఉద్యోగం పోగొట్టుకున్నాడా? ఎలా?",
              "or": "କଣ? ପାପା ଚାକିରି ହରାଇଲେ? କିପରି?",
              "as": "কি? পাপাই চাকৰি হেৰুৱালে নেকি? কেনেকৈ?",
              "gu": "શું? પપ્પાએ નોકરી ગુમાવી દીધી? કેવી રીતે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "The company is upgrading the packaging unit with machines. Papa will need to have a certificate in computer programming to get his job back.",
              "hi": "कंपनी मशीनों के साथ पैकेजिंग इकाई को अपग्रेड कर रही है। पापा को अपनी नौकरी वापस पाने के लिए कंप्यूटर प्रोग्रामिंग में प्रमाण पत्र प्राप्त करना होगा।",
              "ka": "ಕಂಪನಿಯು ಪ್ಯಾಕೇಜಿಂಗ್ ಘಟಕವನ್ನು ಯಂತ್ರಗಳೊಂದಿಗೆ ನವೀಕರಿಸುತ್ತಿದೆ. ನಿನ್ನ ಅಪ್ಪ ತನ್ನ ಕೆಲಸವನ್ನು ಮರಳಿ ಪಡೆಯಲು ಕಂಪ್ಯೂಟರ್ ಪ್ರೋಗ್ರಾಮಿಂಗ್‌ನಲ್ಲಿ ಪ್ರಮಾಣಪತ್ರವನ್ನು ಹೊಂದಿರಬೇಕು.",
              "te": "కంపెనీ ప్యాకేజింగ్ యూనిట్‌ను యంత్రాలతో అప్‌గ్రేడ్ చేస్తోంది. పాప తన ఉద్యోగాన్ని తిరిగి పొందడానికి కంప్యూటర్ ప్రోగ్రామింగ్‌లో సర్టిఫికేట్ కలిగి ఉండాలి.",
              "or": "କମ୍ପାନୀ ମେସିନ୍ ସହିତ ପ୍ୟାକେଜିଂ ୟୁନିଟ୍ କୁ ଅପଗ୍ରେଡ୍ କରୁଛି | ଚାକିରି ପୁନର୍ବାର ପାଇବା \n  ପାଇଁ ପାପାଙ୍କୁ କମ୍ପ୍ୟୁଟର ପ୍ରୋଗ୍ରାମିଂରେ ଏକ ସାର୍ଟିଫିକେଟ୍ ରହିବା ଆବଶ୍ୟକ |",
              "as": "কোম্পানীটোৱে যন্ত্ৰ আনিছে পেকেজিং কাৰনে। পাপাই তেওঁৰ চাকৰি ঘূৰাই পাবলৈ কম্পিউটাৰ প্ৰগ্ৰামিংত এখন প্ৰমাণপত্ৰ লাগিব।",
              "gu": "કંપની મશીનો સાથે પેકેજિંગ યુનિટને બદલાવ કરી રહી છે. પપ્પા પાસે તેમની નોકરી પાછી મેળવવા માટે કમ્પ્યુટર પ્રોગ્રામિંગમાં પ્રમાણપત્ર હોવું જરૂરી છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "Future of work",
              "hi": "काम का भविष्य",
              "ka": "ಕೆಲಸದ ಭವಿಷ್ಯ",
              "te": "పని యొక్క భవిష్యత్తు",
              "or": "କାର୍ଯ୍ୟର ଭବିଷ୍ୟତ |",
              "as": "কামৰ ভৱিষ্যত",
              "gu": "કામનું ભવિષ્ય"
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_2_5",
              "hi": "E2_2_5",
              "ka": "E2_2_5",
              "te": "E2_2_5",
              "or": "E2_2_5",
              "as": "E2_2_5",
              "gu": "E2_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is concerned. She knows that her family will not be able to manage the finances unless she starts earning.",
              "hi": "मीरा चिंतित है। वह जानती है कि जब तक वह कमाई शुरू नहीं करती तब तक उसका परिवार वित्त का प्रबंधन नहीं कर पाएगा।",
              "ka": "ಮೀರಾ ಕಳವಳಗೊಳ್ಳುತ್ತಾಳೆ. ಅವಳು ಸಂಪಾದಿಸಲು ಪ್ರಾರಂಭಿಸದ ಹೊರತು ತನ್ನ ಕುಟುಂಬಕ್ಕೆ ಹಣಕಾಸಿನ ನಿರ್ವಹಣೆ ಮಾಡಲು ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ ಎನ್ನುವುದು ಅವಳಿಗೆ ತಿಳಿದಿದೆ.",
              "te": "మీరా ఆందోళన చెందుతోంది. సంపాదించడం మొదలుపెడితే తప్ప తన కుటుంబం కష్టాల నుండి బయట పడదని ఆమెకు తెలుసు.",
              "or": "ମୀରା ଚିନ୍ତିତ | ସେ ଜାଣନ୍ତି ଯେ ଯଦି ସେ ରୋଜଗାର ଆରମ୍ଭ ନକରନ୍ତି ତେବେ \n ତାଙ୍କ ପରିବାର ଆର୍ଥିକ ପରିଚାଳନା କରିବାରେ ସମର୍ଥ ହେବେ ନାହିଁ |",
              "as": "মীৰা চিন্তিত। তাই জানে যে তাই চাকৰি নকৰালৈকে তাইৰ পৰিয়ালে চলিব নোৱাৰিব।",
              "gu": "મીરા ચિંતિત છે. તે જાણે છે કે જ્યાં સુધી તે કમાવાનું શરૂ નહીં કરે ત્યાં સુધી તેનો પરિવાર પૈસાનું સંચાલન કરી શકશે નહીં."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_3_1",
              "hi": "E2_3_1",
              "ka": "E2_3_1",
              "te": "E2_3_1",
              "or": "E2_3_1",
              "as": "E2_3_1",
              "gu": "E2_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Although she is keen on a Master’s degree, she knows she might have to work to help her family and support Shantanu’s education.",
              "hi": "यद्यपि वह मास्टर की डिग्री के लिए उत्सुक है, लेकिन उसे पता है कि उसे अपने परिवार की मदद करने और शांतनु की शिक्षा में सहयोग करने के लिए काम करना पड़ सकता है।",
              "ka": "ಅವಳು ಸ್ನಾತಕೋತ್ತರ ಪದವಿಯಲ್ಲಿ ಉತ್ಸುಕಳಾಗಿದ್ದರೂ ಕೂಡ, ತನ್ನ ಕುಟುಂಬಕ್ಕೆ ಸಹಾಯ ಮಾಡಲು ಮತ್ತು ಶಂತನುವಿನ ಶಿಕ್ಷಣವನ್ನು ಬೆಂಬಲಿಸಲು ಅವಳು ಕೆಲಸ ಮಾಡಬೇಕಾಗಬಹುದು ಎನ್ನುವುದು ಅವಳಿಗೆ ತಿಳಿದಿದೆ.",
              "te": "ఆమె మాస్టర్స్ డిగ్రీపై ఆసక్తిని కలిగి ఉన్నప్పటికీ, ఆమె తన కుటుంబానికి సహాయం చేయడానికి మరియు షాంతను చదువుకు సపోర్ట్ చేయడానికి పని చేయాల్సి ఉంటుందని ఆమెకు తెలుసు.",
              "or": "ଯଦିଓ ସେ ମାଷ୍ଟର ଡିଗ୍ରୀ ପ୍ରତି ଆଗ୍ରହୀ, ତଥାପି ସେ ଜାଣନ୍ତି ଯେ ତାଙ୍କ ପରିବାରକୁ \n ସାହାଯ୍ୟ କରିବା ଏବଂ ଶାନ୍ତନୁଙ୍କ ଶିକ୍ଷାକୁ ସମର୍ଥନ କରିବା ପାଇଁ ତାଙ୍କୁ କାମ କରିବାକୁ ପଡିପାରେ |",
              "as": "যদিও তাই স্নাতকোত্তৰ ডিগ্ৰী ৰ বাবে আগ্ৰহী, তাই জানে যে তাই তাইৰ পৰিয়ালক সহায় কৰিবলৈ আৰু শান্তনুৰ পঢ়া সহায় কৰিবলৈ কাম কৰিব লাগিব পাৰে।",
              "gu": "જો કે તે માસ્ટર ડિગ્રી મેળવવા આતુર છે, તે જાણે છે કે તેને તેના પરિવારને મદદ કરવા અને શાંતનુના શિક્ષણને ટેકો આપવા માટે કામ કરવું પડશે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_3_2",
              "hi": "E2_3_2",
              "ka": "E2_3_2",
              "te": "E2_3_2",
              "or": "E2_3_2",
              "as": "E2_3_2",
              "gu": "E2_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She decides to get back to doing her Master’s after a few years of work experience. She applies for many jobs and gets a short term project at a Research Organisation working on spacecraft.",
              "hi": "वह कुछ वर्षों के काम के अनुभव के बाद मास्टर्स करने के लिए वापस जाने का निश्चय करती है। वह अनेक नौकरियों के लिए आवेदन करती है और अंतरिक्ष यान पर काम कर रहे एक शोध संगठन में एक अल्पकालिक प्रोजेक्ट प्राप्त करती है।",
              "ka": "ಕೆಲವು ವರ್ಷಗಳ ಕೆಲಸದ ಅನುಭವದ ನಂತರ ಅವಳು ತನ್ನ ಮಾಸ್ಟರ್ಸ್ ಮಾಡಲು ಹಿಂತಿರುಗಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಅವಳು ಅನೇಕ ಉದ್ಯೋಗಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಾಳೆ ಮತ್ತು ಬಾಹ್ಯಾಕಾಶ ನೌಕೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಸಂಶೋಧನಾ ಸಂಸ್ಥೆಯಲ್ಲಿ ಅಲ್ಪಾವಧಿಯ ಯೋಜನೆಯನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
              "te": "కొన్ని సంవత్సరాలు పని చేసి మంచి అనుభవం పొంది ఆమె మాస్టర్స్ చేయడానికి తిరిగి రావాలని నిర్ణయించుకుంది. ఆమె అనేక ఉద్యోగాల కోసం అప్లై చేసింది మరియు అంతరిక్ష నౌకపై పనిచేస్తున్న పరిశోధనా సంస్థలో స్వల్పకాలిక ప్రాజెక్ట్ ను పొందుతుంది.",
              "or": "ସେ କିଛି ବର୍ଷର କାର୍ଯ୍ୟ ଅଭିଜ୍ଞତା ପରେ ନିଜ ମାଷ୍ଟରଙ୍କ କାର୍ଯ୍ୟକୁ ଫେରିବାକୁ ନିଷ୍ପତ୍ତି\n  ନେଇଛନ୍ତି | ସେ ଅନେକ ଚାକିରି ପାଇଁ ଆବେଦନ କରନ୍ତି ଏବଂ ମହାକାଶଯାନରେ କାର୍ଯ୍ୟ କରୁଥିବା ଏକ ଅନୁସନ୍ଧାନ ସଂଗଠନରେ ସ୍ୱଳ୍ପ ମିଆଦି ପ୍ରୋଜେକ୍ଟ ପାଆନ୍ତି |",
              "as": "তাই কেইবছৰমানৰ কামৰ অভিজ্ঞতাৰ পিছত তাইৰ স্নাতকোত্তৰ কৰিবলৈ উভতি যাবলৈ সিদ্ধান্ত লয়। তাই বহুতো চাকৰিৰ বাবে আবেদন কৰে আৰু মহাকাশযানত কাম কৰা এটা গৱেষণা সংস্থাত এটা হকম দিনৰ প্ৰজেক্ট পায়।",
              "gu": "તેને થોડા વર્ષોના કામના અનુભવ પછી તેના માસ્ટર્સ પર પાછા જવાનું નક્કી કર્યું. તે ઘણી નોકરીઓ માટે અરજી કરે છે અને અવકાશયાન પર કામ કરતી સંશોધન સંસ્થામાં ટૂંકા ગાળાનો પ્રોજેક્ટ મેળવે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_3_3",
              "hi": "E2_3_3",
              "ka": "E2_3_3",
              "te": "E2_3_3",
              "or": "E2_3_3",
              "as": "E2_3_3",
              "gu": "E2_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Meanwhile, Lina studies her M.Sc.in horticulture as she had planned. Mira is very happy for her friend, but she wishes to do her Master’s as well.",
              "hi": "इस बीच, लीना हॉर्टीकल्चर में एम एस सी का अध्ययन करती है जैसी कि उसने योजना बनाई थी। मीरा अपनी मित्र के लिए बहुत खुश है, लेकिन वह स्वयं भी मास्टर्स करना चाहती है।",
              "ka": "ಏತನ್ಮಧ್ಯೆ, ಲೀನಾ ತಾನು ಯೋಜಿಸಿದಂತೆ ತನ್ನ M.Sc.ನಲ್ಲಿ ತೋಟಗಾರಿಕೆಯನ್ನು ಅಧ್ಯಯನ ಮಾಡುತ್ತಾರೆ. ಮೀರಾ ತನ್ನ ಸ್ನೇಹಿತಳ ಬಗ್ಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿದ್ದಾಳೆ, ಆದರೆ ಅವಳು ತನ್ನ ಮಾಸ್ಟರ್ಸ್ ಕೂಡ ಮಾಡಲು ಬಯಸುತ್ತಾಳೆ.",
              "te": "ఇంతలో, లీనా తను అనుకున్న ప్రకారం తన M.Sc.in హార్టికల్చర్ చదువుతుంది. మీరా తన స్నేహితురాలి వలన చాలా సంతోషంగా ఉంది, కానీ ఆమె తన మాస్టర్స్ కూడా చేయాలని అనుకుంటుంది.",
              "or": "ଏହି ସମୟରେ, ଲୀନା ତାଙ୍କ ଯୋଜନା ଅନୁଯାୟୀ ଉଦ୍ୟାନ କୃଷି ଉଦ୍ୟାନ ରେ M.Sc\n  ଅଧ୍ୟୟନ କରିଥିଲେ | ମୀରା ତାଙ୍କ ସାଙ୍ଗ ପାଇଁ ବହୁତ ଖୁସି, କିନ୍ତୁ ସେ ମଧ୍ୟ ତାଙ୍କ ମାଷ୍ଟର କରିବାକୁ ଇଚ୍ଛା କରନ୍ତି |",
              "as": "ইফালে, লীনাই পৰিকল্পনা কৰাৰ দৰে তেওঁৰ M.Sc.in হৰ্টিকালচাৰ অধ্যয়ন কৰে। মীৰা তাইৰ বন্ধুৰ বাবে খুব সুখী, কিন্তু তাইও তাইৰ স্নাতকোত্তৰ কৰিব বিচাৰে।",
              "gu": "તે દરમિયાન, લીનાએ યોજના પ્રમાણે તેના M.Sc.in બાગાયતનો અભ્યાસ કર્યો. મીરા તેના મિત્ર માટે ખૂબ જ ખુશ છે, પરંતુ તે તેના માસ્ટર્સ પણ કરવા ઈચ્છે છે."
            }
          }
        ]
      },
      "scene42": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_1_1",
              "hi": "E3_1_1",
              "ka": "E3_1_1",
              "te": "E3_1_1",
              "or": "E3_1_1",
              "as": "E3_1_1",
              "gu": "E3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "At the new job, Mira works in the documentation wing of the lab. She is very organised, and she can fit well in the team and is a good team player.",
              "hi": "नई नौकरी में, मीरा प्रयोगशाला के डॉक्युमेंटेशन  विंग में काम करती  है। वह बहुत व्यवस्थित है, और वह टीम में अच्छी तरह से फिट हो सकती है और एक टीम की अच्छी सदस्या है।",
              "ka": "ಹೊಸ ಕೆಲಸದಲ್ಲಿ, ಮೀರಾ ಲ್ಯಾಬ್‌ನ ದಸ್ತಾವೇಜು ವಿಭಾಗದಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಾಳೆ. ಅವಳು ತುಂಬಾ ಸಂಘಟಿತಳಾಗಿದ್ದಾಳೆ ಮತ್ತು ಅವಳು ತಂಡದಲ್ಲಿ ಚೆನ್ನಾಗಿ ಹೊಂದಿಕೊಳ್ಳುತ್ತಾಳೆ ಹಾಗೂ ಒಬ್ಬ ಉತ್ತಮ ತಂಡದ ಆಟಗಾರ್ತಿ ಆಗಿರುತ್ತಾಳೆ.",
              "te": "కొత్త ఉద్యోగంలో, మీరా ల్యాబ్‌లోని డాక్యుమెంటేషన్ విభాగంలో పని చేస్తుంది. ఆమె చాలా ఆర్గనైజ్డ్ మరియు టీం లో బాగా సరిపోయింది. మంచి టీమ్ ప్లేయర్ గా పేరు తెచ్చుకుంది.",
              "or": "ନୂତନ ଚାକିରିରେ, ମୀରା ଲ୍ୟାବ୍ର ଡକ୍ୟୁମେଣ୍ଟେସନ୍ ୱିଙ୍ଗରେ କାମ କରନ୍ତି | ସେ ବହୁତ\n  ସଂଗଠିତ, ଏବଂ ସେ ଦଳରେ ଭଲ ଫିଟ୍ ହୋଇପାରନ୍ତି ଏବଂ ଜଣେ ଭଲ ଦଳର ଖେଳାଳି |",
              "as": "নতুন চাকৰিটোত, মীৰাই লেব ডকুমেণ্টেচন শাখাত কাম কৰে। তাই অতি চিজিল, আৰু তেওঁ দলত ভালদৰে খাপ খাব পাৰে।",
              "gu": "નવી નોકરી પર, મીરા લેબની દસ્તાવેજીકરણ વિંગમાં કામ કરે છે. તે ખૂબ જ આયોજિત  છે, અને તે ટીમમાં સારી રીતે ફિટ થઈ શકે છે અને ટીમની સારી ખેલાડી છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_1_1b",
              "hi": "E3_1_1b",
              "ka": "E3_1_1b",
              "te": "E3_1_1b",
              "or": "E3_1_1b",
              "as": "E3_1_1b",
              "gu": "E3_1_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira supports other team members in their processes with enthusiasm. She collaborates with them on research assignments.",
              "hi": "मीरा उत्साह के साथ अपनी प्रक्रियाओं में टीम के अन्य सदस्यों का समर्थन करती है। वह शोध असाइनमेंट पर उनके साथ सहयोग करती है।",
              "ka": "ಮೀರಾ ಇತರ ತಂಡದ ಸದಸ್ಯರನ್ನು ತನ್ನ ಪ್ರಕ್ರಿಯೆಗಳಲ್ಲಿ ಉತ್ಸಾಹದಿಂದ ಬೆಂಬಲಿಸುತ್ತಾಳೆ. ಆಕೆಯು ಸಂಶೋಧನಾ ಕಾರ್ಯಯೋಜನೆಗಳಲ್ಲಿ ಅವರೊಂದಿಗೆ ಸಹಕರಿಸುತ್ತಾಳೆ.",
              "te": "మీరా ఇతర టీమ్ సభ్యులకు వారి పనిలో ఉత్సాహంతో సపోర్ట్ ఇస్తుంది. ఆమె వారితో పరిశోధన పనుల్లో సహకరిస్తుంది.",
              "or": "ମୀରା ଉତ୍ସାହର ସହିତ ସେମାନଙ୍କର ପ୍ରକ୍ରିୟାରେ ଅନ୍ୟ ଦଳର ସଦସ୍ୟମାନଙ୍କୁ \n ସମର୍ଥନ କରନ୍ତି | ସେ ଗବେଷଣା ଦାୟିତ୍ୱରେ ସେମାନଙ୍କ ସହିତ ସହଯୋଗ କରନ୍ତି |",
              "as": "মীৰাই দলৰ আন সদস্যসকলক তেওঁলোকৰ কামত ফূৰ্তিৰে সহায় কৰে। তাই গৱেষণাৰ কামত তেওঁলোকৰ লগত সহযোগিতা কৰে।",
              "gu": "મીરા ટીમના અન્ય સભ્યોને તેમની પ્રક્રિયાઓમાં ઉત્સાહથી ટેકો આપે છે. તે સંશોધન સોંપણીઓ પર તેમની સાથે સહયોગ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_1_2",
              "hi": "E3_1_2",
              "ka": "E3_1_2",
              "te": "E3_1_2",
              "or": "E3_1_2",
              "as": "E3_1_2",
              "gu": "E3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After the first year, they give her a permanent role due to her good performance. She works with a senior researcher on the production of a fuel-efficient engine.",
              "hi": "पहले वर्ष के बाद, वे उसे अच्छे प्रदर्शन के कारण स्थायी पद दे देते हैं। वह ईंधन-एफ़िशिएन्ट  इंजन के उत्पादन पर एक वरिष्ठ शोधकर्ता के साथ काम करती है।",
              "ka": "ಮೊದಲ ವರ್ಷದ ನಂತರ, ಅವಳ ಉತ್ತಮ ಕೆಲಸದಿಂದಾಗಿ ಅವಳಿಗೆ ಶಾಶ್ವತ ಕೆಲಸವನ್ನು ನೀಡುತ್ತಾರೆ. ಅವಳು ಇಂಧನ-ಸಮರ್ಥ ಎಂಜಿನ್ ಉತ್ಪಾದನೆಯಲ್ಲಿ ಹಿರಿಯ ಸಂಶೋಧಕರೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುತ್ತಾಳೆ.",
              "te": "మొదటి సంవత్సరం తర్వాత, ఆమె బాగా పని చేయడం వలన వారు ఆమెకు పర్మినెంట్ జాబ్ ఇచ్చారు. ఆమె ఇంధన-సమర్థవంతమైన ఇంజిన్ ఉత్పత్తిపై సీనియర్ పరిశోధకుడితో కలిసి పనిచేస్తుంది.",
              "or": "ପ୍ରଥମ ବର୍ଷ ପରେ, ତାଙ୍କର ଭଲ ପ୍ରଦର୍ଶନ ହେତୁ ସେମାନେ ତାଙ୍କୁ ଏକ ସ୍ଥାୟୀ ଭୂମିକା\n  ଦିଅନ୍ତି | ସେ ଜଣେ ବରିଷ୍ଠ ଅନୁସନ୍ଧାନକାରୀଙ୍କ ସହିତ ଇନ୍ଧନ-ଦକ୍ଷ ଇଞ୍ଜିନ୍ ଉତ୍ପାଦନ ଉପରେ କାର୍ଯ୍ୟ କରନ୍ତି |",
              "as": "প্ৰথম বছৰৰ পিছত, তেওঁলোকে তাইৰ ভাল প্ৰদৰ্শনৰ বাবে তাইক এক চাকৰি দিয়ে। তেওঁ এজন জ্যেষ্ঠ গৱেষকৰ সৈতে ফিউল এফিচিয়েন্ট ওপৰত কাম কৰে।",
              "gu": "પ્રથમ વર્ષ પછી, તેના સારા અભિનયને કારણે તેઓ તેને કાયમી ભૂમિકા આપે છે. તે એક સિનિયર  સંશોધક સાથે બળતણ-કાર્યક્ષમ એન્જિનના ઉત્પાદન પર કામ કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_1_3",
              "hi": "E3_1_3",
              "ka": "E3_1_3",
              "te": "E3_1_3",
              "or": "E3_1_3",
              "as": "E3_1_3",
              "gu": "E3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Although she is happy with her work, she doesn’t feel satisfied. Much of her work is at the office, and she misses working in the lab. She wants to pursue her dream of becoming a scientist.",
              "hi": "हालांकि वह अपने काम से खुश है, पर वह संतुष्ट नहीं है। उसका अधिकांश काम कार्यालय में है, और वह प्रयोगशाला में काम करने से चूक जाती है। वह एक वैज्ञानिक बनने के अपने सपने को आगे बढ़ाना चाहती है।",
              "ka": "ಅವಳು ತನ್ನ ಕೆಲಸದಲ್ಲಿ ಸಂತೋಷವಾಗಿದ್ದರೂ ಕೂಡ, ಅವಳು ತೃಪ್ತಿಯನ್ನು ಹೊಂದಿರುವುದಿಲ್ಲ. ಅವಳ ಹೆಚ್ಚಿನ ಕೆಲಸವು ಕಛೇರಿಯದಾಗಿದ್ದು ಮತ್ತು ಅವಳು ಲ್ಯಾಬ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ತಪ್ಪಿಸಿಕೊಳ್ಳುತ್ತಿದ್ದಾಳೆ. ಅವಳು ವಿಜ್ಞಾನಿಯಾಗುವ ತನ್ನ ಕನಸನ್ನು ಮುಂದುವರಿಸಲು ಬಯಸುತ್ತಾಳೆ.",
              "te": "ఆమె తన పనితో సంతోషంగా ఉన్నప్పటికీ, ఆమె సంతృప్తి చెందలేదు. ఆమెకి ఎక్కువ పని ఆఫీస్ లో ఉంటుంది మరియు ఆమె ల్యాబ్‌లో పనిచేసే అవకాశం లేదు. ఆమె శాస్త్రవేత్త కావాలనే తన కలను కొనసాగించాలనుకుంటోంది.",
              "or": "ଯଦିଓ ସେ ନିଜ କାର୍ଯ୍ୟରେ ଖୁସି, ତଥାପି ସେ ସନ୍ତୁଷ୍ଟ ନୁହଁନ୍ତି | ତାଙ୍କର ଅଧିକାଂଶ\n  କାର୍ଯ୍ୟ ଅଫିସରେ ଅଛି, ଏବଂ ସେ ଲ୍ୟାବରେ କାମ କରିବାକୁ ମିସ୍ କରନ୍ତି | ସେ ବ a ଜ୍ଞାନିକ ହେବାର ସ୍ୱପ୍ନକୁ ସାକାର କରିବାକୁ ଚାହୁଁଛନ୍ତି |",
              "as": "যদিও তাই তাইৰ কামত সুখী, তাই সন্তুষ্ট নহয়। তাইৰ বেছিভাগ কাম অফিচত আছে, আৰু তাই লেবত কাম কৰিবলৈ নাপায়। তাই এগৰাকী বিজ্ঞানী হব বিচাৰে।",
              "gu": "તે તેના કામથી ખુશ હોવા છતાં સંતુષ્ટ નથી અનુભવતી. તેનું મોટાભાગનું કામ ઓફિસમાં છે, અને તે લેબમાં કામ કરવાનું ચૂકી જાય છે. તે વિજ્ઞાનીક બનવાના તેના સપનાને સાકાર કરવા માંગે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_1",
              "hi": "E3_2_1",
              "ka": "E3_2_1",
              "te": "E3_2_1",
              "or": "E3_2_1",
              "as": "E3_2_1",
              "gu": "E3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As the family situation improves with both her father and Shantanu getting a job, she decides to talk to her Manager about quitting her job to apply for her Master’s.",
              "hi": "चूँकि अपने पिता और शांतनु दोनों के नौकरी पाने के साथ पारिवारिक स्थिति में सुधार आ गया है, वह स्नाकोत्तर के लिए आवेदन करने के लिए अपनी नौकरी छोड़ने के बारे में अपने प्रबंधक से बात करने का फैसला करती है।",
              "ka": "ತನ್ನ ತಂದೆ ಮತ್ತು ಶಂತನು ಇಬ್ಬರಿಗೂ ಕೆಲಸ ಸಿಗುವುದರೊಂದಿಗೆ ಕುಟುಂಬದ ಪರಿಸ್ಥಿತಿ ಸುಧಾರಿಸಿದಂತೆ, ತನ್ನ ಮಾಸ್ಟರ್ಸ್‌ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ತನ್ನ ಕೆಲಸವನ್ನು ತ್ಯಜಿಸುವ ಬಗ್ಗೆ ತನ್ನ ಮ್ಯಾನೇಜರ್‌ನೊಂದಿಗೆ ಮಾತನಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
              "te": "తన తండ్రి మరియు షాంతను ఇద్దరికీ ఉద్యోగం రావడంతో కుటుంబ పరిస్థితి మెరుగుపడుతుంది మరియు ఆమె మాస్టర్స్ కోసం అప్లై చేయడానికి తన ఉద్యోగాన్ని మానేయడం గురించి తన మేనేజర్‌తో మాట్లాడాలని నిర్ణయించుకుంది.",
              "or": "ଉଭୟ ପିତା ଏବଂ ଶାନ୍ତନୁ ଚାକିରି ପାଇବା ସହିତ ପାରିବାରିକ ସ୍ଥିତିରେ ସୁଧାର \n ଆସୁଥିବାରୁ ସେ ତାଙ୍କ ମାଷ୍ଟରଙ୍କ ପାଇଁ ଆବେଦନ କରିବା ପାଇଁ ଚାକିରି ଛାଡିବା ବିଷୟରେ ତାଙ୍କ ମ୍ୟାନେଜରଙ୍କ ସହ କଥା ହେବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି।",
              "as": "তাইৰ দেউতাক আৰু শান্তনু দুয়োৰে চাকৰি পোৱাৰ লগে লগে আৰু ঘৰৰ পৰিস্থিতি উন্নত হোৱাৰ লগে লগে, তাই তাইৰ স্নাতকোত্তৰ বাবে আবেদন কৰিবলৈ চাকৰি এৰি দিয়াৰ বিষয়ে তাইৰ মেনেজাৰৰ সৈতে কথা পাতিবলৈ সিদ্ধান্ত লয়।",
              "gu": "તેના પિતા અને શાંતનુ બંનેને નોકરી મળતા પરિવારની પરિસ્થિતિ સુધરી હોવાથી, તે તેના માસ્ટર માટે અરજી કરવા માટે તેની નોકરી છોડવા અંગે તેના મેનેજર સાથે વાત કરવાનું નક્કી કર્યું."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_2 bg",
              "hi": "E3_2_2 बीजी",
              "ka": "E3_2_2 bg",
              "te": "E3_2_2 bg",
              "or": "E3_2_2 bg",
              "as": "E3_2_2 bg",
              "gu": "E3_2_2 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mira",
              "hi": "मीरा",
              "ka": "ಮೀರಾ",
              "te": "మీరా",
              "or": "ମୀରା",
              "as": "মীৰা",
              "gu": "મીરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Manager",
              "hi": "प्रबंधक",
              "ka": "ಮ್ಯಾನೇಜರ್",
              "te": "మేనేజర్",
              "or": "ମ୍ୟାନେଜର",
              "as": "মেনেজাৰ",
              "gu": "મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Sir, I wanted to talk to you about leaving the job. I want to do my Master's, maybe in nanotech or something.",
              "hi": "महोदय, मैं नौकरी छोड़ने के बारे में आपसे बात करना चाहती हूँ। मैं अपना स्नातकोत्तर, शायद नैनोटेक या किसी अन्य में करना चाहती हूं।",
              "ka": "ಸರ್, ನಾನು ಕೆಲಸ ಬಿಡುವ ಬಗ್ಗೆ ನಿಮ್ಮೊಂದಿಗೆ ಮಾತನಾಡಲು ಬಯಸುತ್ತೇನೆ. ನಾನು ನ್ಯಾನೊಟೆಕ್ ಅಥವಾ ಇನ್ನಾವುದಾದರೂ ಸ್ನಾತಕೋತ್ತರ ಪದವಿಯನ್ನು ಮಾಡಲು ಬಯಸುತ್ತೇನೆ.",
              "te": "సార్, నేను ఉద్యోగం మానేయడం గురించి మీతో మాట్లాడాలనుకున్నాను. నేను నానోటెక్‌లో లేదా మరేదైనా మాస్టర్స్ చేయాలనుకుంటున్నాను.",
              "or": "ସାର୍, ମୁଁ ଚାକିରି ଛାଡିବା ବିଷୟରେ ତୁମ ସହ କଥା ହେବାକୁ ଚାହୁଁଥିଲି | ମୁଁ ମୋର\n  ମାଷ୍ଟର କରିବାକୁ ଚାହୁଁଛି, ବୋଧହୁଏ ନାନୋଟେକ୍ କିମ୍ବା କିଛି |",
              "as": "চাৰ, মই চাকৰিটো এৰি যোৱাৰ বিষয়ে আপোনাৰ সৈতে কথা পাতিব বিচাৰিছিলো। মই মোৰ মাষ্টাৰ কৰিব বিচাৰো, হয়তো নেনোটেক বা আন কিবা।",
              "gu": "સાહેબ, મારે તમારી સાથેથી નોકરી છોડવા વિશે વાત કરવી હતી. હું મારુ માસ્ટર્સ કરવા માંગુ છું, કદાચ નેનોટેકમાં કે કંઈક."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Brilliant Mira, I’m so glad you have chosen to study further. We need bright people like you to do your post-graduation and then start your research journey.",
              "hi": "शानदार मीरा, मुझे बहुत खुशी है कि आपने आगे अध्ययन करना चुना है। हमें आपके जैसे उज्ज्वल लोगों की आवश्यकता है जो अपना पोस्ट-ग्रेजुएशन कर के अपनी शोध यात्रा शुरू करे।",
              "ka": "ಬ್ರಿಲಿಯಂಟ್ ಮೀರಾ, ನೀವು ಮತ್ತಷ್ಟು ಅಧ್ಯಯನ ಮಾಡಲು ಆಯ್ಕೆ ಮಾಡಿಕೊಂಡಿದ್ದೀರಿ ಎಂದು ನನಗೆ ತುಂಬಾ ಖುಷಿಯಾಗಿದೆ. ನೀನು ಸ್ನಾತಕೋತ್ತರ ಪದವಿಯನ್ನು ಮುಂದುವರೆಸಲು ಮತ್ತು ನಂತರ ನಿನ್ನ ಸಂಶೋಧನಾ ಪ್ರಯಾಣವನ್ನು ಪ್ರಾರಂಭಿಸಲು ನಿನ್ನಂತಹ ಬುದ್ಧಿವಂತ ಜನರು ನಮಗೆ ಅಗತ್ಯವಿದೆ.",
              "te": "బ్రిలియంట్ మీరా, మీరు మరింత చదువుకోవడానికి ఎంచుకున్నందుకు నేను చాలా సంతోషిస్తున్నాను. మీ పోస్ట్-గ్రాడ్యుయేషన్ చేసి, మీ పరిశోధనా ప్రయాణాన్ని ప్రారంభించడానికి మీలాంటి తెలివైన వ్యక్తులు మాకు అవసరం.",
              "or": "ଚମତ୍କାର ମୀରା, ମୁଁ ବହୁତ ଖୁସି ଯେ ଆପଣ ଅଧିକ ଅଧ୍ୟୟନ କରିବାକୁ ବାଛିଛନ୍ତି |\n  ତୁମର ସ୍ନାତକୋତ୍ତର କରିବା ଏବଂ ତାପରେ ତୁମର ଅନୁସନ୍ଧାନ ଯାତ୍ରା ଆରମ୍ଭ କରିବା ପାଇଁ ତୁମ ପରି ଉଜ୍ଜ୍ୱଳ ଲୋକମାନଙ୍କର ଆବଶ୍ୟକତା ଅଛି |",
              "as": "বৰ ভাল কথা মীৰা, মই বহুত সুখী যে তুমি আৰু পঢ়িব বিচাৰিছা। স্নাতকোত্তৰ কৰিবলৈ আৰু গৱেষণা আৰম্ভ কৰিবলৈ আমাক তোমাৰ নিচিনা উজ্জ্বল লোকৰ প্ৰয়োজন।",
              "gu": "ખૂબ સરસ મીરા, મને ખૂબ આનંદ છે કે તું આગળ અભ્યાસ કરવાનું પસંદ કર્યું છે. તારું અનુસ્નાતક કરવા અને પછી તમારી સંશોધન યાત્રા શરૂ કરવા માટે અમને તમારા જેવા તેજસ્વી લોકોની જરૂર છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_3 ///",
              "hi": "E3_2_3 ///",
              "ka": "E3_2_3 ///",
              "te": "E3_2_3 ///",
              "or": "E3_2_3 ///",
              "as": "E3_2_3 ///",
              "gu": "E3_2_3 ///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Thank you Sir! It was great working here, and I will miss working with the team.",
              "hi": "शुक्रिया महोदय! यहां काम करना बहुत अच्छा था, और मैं इस टीम के साथ काम करना मिस करूंगी l",
              "ka": "ಧನ್ಯವಾದಗಳು ಸರ್! ಇಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದು ಒಂದು ಅದ್ಭುತ ಸಮಯವಾಗಿತ್ತು, ಮತ್ತು ನಾನು ತಂಡದೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಖಂಡಿತ ಮಿಸ್ ಮಾಡಿಕೊಳ್ಳುತ್ತೇನೆ.",
              "te": "ధన్యవాదాలు సార్! ఇక్కడ పని చేయడం చాలా బాగుంది మరియు నేను టీమ్‌తో కలిసి పనిచేయడం మిస్ అవుతాను.",
              "or": "ଧନ୍ୟବାଦ ମହାଶୟ! ଏଠାରେ କାମ କରିବା ବହୁତ ଭଲ ଥିଲା, ଏବଂ ମୁଁ ଦଳ ସହିତ\n  କାମ କରିବା ହରାଇବି |",
              "as": "ধন্যবাদ চাৰ! ইয়াত কাম কৰাটো ভাল লাগিছিল, আৰু মই দলটোৰ সৈতে কাম কৰাটো বহুত মনত পেলাম।",
              "gu": "આભાર સાહેબ! અહીં કામ કરવામાં મજા આવી  અને હું ટીમ સાથે કામ કરવાનું યાદ કરીશ "
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Before you go, Mira, I wanted to tell you about an opportunity our company offers to only a few employees. We have a program for one year with a research centre in France. It is a hands-on training program with the Senior Scientists there. You will get a certificate at the end of the year, but it will not be same as  a Master’s degree.",
              "hi": "जाने से पहले, मीरा, मैं आपको एक अवसर के बारे में बताना चाहता था जो हमारी कंपनी केवल कुछ कर्मचारियों को प्रदान कर रही है। हमारे पास फ्रांस के एक शोध केंद्र में एक वर्ष का एक कार्यक्रम है। यह वरिष्ठ वैज्ञानिकों के साथ में एक प्रशिक्षण कार्यक्रम है। आपको वर्ष के अंत में एक प्रमाण पत्र मिलेगा, लेकिन यह स्नातकोत्तर डिग्री के समान नहीं होगा।",
              "ka": "ನೀನು ಹೋಗುವ ಮೊದಲು, ಮೀರಾ, ನಮ್ಮ ಕಂಪನಿಯು ಕೆಲವೇ ಉದ್ಯೋಗಿಗಳಿಗೆ ನೀಡುವ ಅವಕಾಶದ ಬಗ್ಗೆ ನಾನು ನಿನಗೆ ಹೇಳಲು ಬಯಸುತ್ತೇನೆ. ನಾವು ಫ್ರಾನ್ಸ್‌ನಲ್ಲಿನ ಒಂದು ಸಂಶೋಧನಾ ಕೇಂದ್ರದೊಂದಿಗೆ ಒಂದು ವರ್ಷದ ಕಾರ್ಯಕ್ರಮವನ್ನು ಹೊಂದಿದ್ದೇವೆ. ಇದು ಅಲ್ಲಿನ ಹಿರಿಯ ವಿಜ್ಞಾನಿಗಳೊಂದಿಗೆ ಪ್ರಾಯೋಗಿಕ ತರಬೇತಿ ಕಾರ್ಯಕ್ರಮವಾಗಿರುತ್ತದೆ. ನೀನು ವರ್ಷದ ಕೊನೆಯಲ್ಲಿ ಪ್ರಮಾಣಪತ್ರವನ್ನು ಪಡೆಯುತ್ತೀಯಾ, ಆದರೆ ಇದು ಸ್ನಾತಕೋತ್ತರ ಪದವಿಯ ರೀತಿಯಲ್ಲಿ ಇರುವುದಿಲ್ಲ.",
              "te": "మీరు వెళ్లేముందు మీరా, మా కంపెనీ కొంతమంది ఉద్యోగులకు మాత్రమే అందించే అవకాశం గురించి మీకు చెప్పాలనుకుంటున్నాను. మేము ఫ్రాన్స్ లోని పరిశోధనా కేంద్రంతో ఒక సంవత్సరం పాటు ప్రోగ్రామ్‌ని కలిగి ఉన్నాము. ఇది అక్కడి సీనియర్ సైంటిస్టులతో శిక్షణా కార్యక్రమం. మీరు సంవత్సరం చివరిలో సర్టిఫికేట్ పొందుతారు కానీ అది మాస్టర్స్ డిగ్రీకి సమానంగా ఉండదు.",
              "or": "ତୁମେ ଯିବା ପୂର୍ବରୁ, ମୀରା, ମୁଁ ତୁମ କମ୍ପାନୀକୁ କେବଳ କିଛି କର୍ମଚାରୀଙ୍କୁ ପ୍ରଦାନ\n  କରୁଥିବା ଏକ ସୁଯୋଗ ବିଷୟରେ କହିବାକୁ ଚାହୁଁଥିଲି | ଫ୍ରାନ୍ସରେ ଏକ ଗବେଷଣା କେନ୍ଦ୍ର ସହିତ ଆମର ଏକ ବର୍ଷ ପାଇଁ ଏକ କାର୍ଯ୍ୟକ୍ରମ ଅଛି | ସେଠାରେ ସିନିୟର ସାଇଣ୍ଟିଷ୍ଟମାନଙ୍କ ସହିତ ଏହା ଏକ ହ୍ୟାଣ୍ଡ-ଅନ୍ ଟ୍ରେନିଂ ପ୍ରୋଗ୍ରାମ | ଆପଣ ବର୍ଷ ଶେଷରେ ଏକ ସାର୍ଟିଫିକେଟ୍ ପାଇବେ, କିନ୍ତୁ ଏହା ମାଷ୍ଟର ଡିଗ୍ରୀ ସହିତ ସମାନ ହେବ ନାହିଁ |",
              "as": "তুমি যোৱাৰ আগতে, মীৰা, মই তোমাক আমাৰ কোম্পানীয়ে কেৱল কেইজনমান কৰ্মচাৰীক আগবঢ়োৱা এটা সুযোগৰ বিষয়ে ক'ব বিচাৰিছিলো। ফ্ৰান্সৰ এটা গৱেষণা কেন্দ্ৰৰ সৈতে আমাৰ এবছৰৰ বাবে এটা কাৰ্যসূচী আছে। তাৰ জ্যেষ্ঠ বিজ্ঞানীসকলৰ সৈতে এক প্ৰশিক্ষণ কাৰ্যসূচী। বছৰটোৰ শেষত তুমি প্ৰমাণপত্ৰ এখন পাব, কিন্তু ই স্নাতকোত্তৰ ডিগ্ৰীৰ দৰে একে নহ'ব।",
              "gu": "તું જાય તે પહેલાં, મીરા, હું તને અમારી કંપની માત્ર થોડા જ કર્મચારીઓને આપેલી તક વિશે જણાવવા માંગતો હતો. અમારી પાસે ફ્રાન્સમાં સંશોધન કેન્દ્ર સાથે એક વર્ષ માટે એક પ્રોગ્રામ છે. તે ત્યાંના વરિષ્ઠ વૈજ્ઞાનિકો સાથે હાથ ધરાયેલો તાલીમ કાર્યક્રમ છે. તને વર્ષના અંતે પ્રમાણપત્ર મળશે, પરંતુ તે માસ્ટર ડિગ્રી જેવું નહીં હોય."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Oh, that is a wonderful opportunity. But what about the expenses, Sir?",
              "hi": "ओह, यह एक अद्भुत अवसर है। लेकिन खर्चों के बारे में क्या, महोदय?",
              "ka": "ಓಹ್, ಅದೊಂದು ಅದ್ಭುತ ಅವಕಾಶ. ಆದರೆ ಖರ್ಚು ಯಾವ ರೀತಿ ನೋಡಿಕೊಳ್ಳೋದು  ಸರ್?",
              "te": "ఓహ్, ఇది ఒక అద్భుతమైన అవకాశం. అయితే ఖర్చుల గురించి సార్?",
              "or": "ଓହ, ଏହା ଏକ ଅଦ୍ଭୁତ ସୁଯୋଗ | କିନ୍ତୁ ଖର୍ଚ୍ଚ ବିଷୟରେ, ସାର୍?",
              "as": "ওহ, এইটো এটা ভাল সুযোগ। কিন্তু খৰচৰ বিষয়ে কি হ'ব, চাৰ?",
              "gu": "ઓહ, તે એક અદ્ભુત તક છે. પણ ખર્ચનું શું, સાહેબ?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "We pay for the course, travel, stay, and everything, but you will have to work with us for five years once you come back.",
              "hi": "हम पाठ्यक्रम, यात्रा, रहने और अन्य खर्चों के लिए भुगतान करते हैं, लेकिन वापस आने के बाद आपको पांच साल तक हमारे साथ काम करना होगा।",
              "ka": "ಕೋರ್ಸ್, ಪ್ರಯಾಣ, ಉಳಿದುಕೊಳ್ಳುವುದಕ್ಕೆ ಮತ್ತು ಎಲ್ಲದಕ್ಕೂ ನಾವು ಹಣ ಕೊಡ್ತೀವಿ, ಆದರೆ ನೀವು ಹಿಂತಿರುಗಿದ ನಂತರ ಐದು ವರ್ಷಗಳ ಕಾಲ ನಮ್ಮೊಂದಿಗೆ ಕೆಲಸ ಮಾಡಬೇಕಾಗುತ್ತದೆ.",
              "te": "మేము కోర్సు, ప్రయాణం, బస మరియు అన్నింటికీ చెల్లిస్తాము, కానీ మీరు తిరిగి వచ్చిన తర్వాత మీరు మాతో ఐదు సంవత్సరాలు పని చేయాలి.",
              "or": "ଆମେ ପାଠ୍ୟକ୍ରମ, ଭ୍ରମଣ, ରହିବା, ଏବଂ ସବୁକିଛି ପାଇଁ ଦେୟ ଦେଇଥାଉ, କିନ୍ତୁ\n  ତୁମେ ଫେରିବା ପରେ ତୁମକୁ ଆମ ସହିତ ପାଞ୍ଚ ବର୍ଷ କାମ କରିବାକୁ ପଡିବ |",
              "as": "আমি পাঠ্যক্ৰমৰ বাবে , ভ্ৰমণ, থকা আৰু সকলোবোৰ খৰচ দিম, কিন্তু তুমি ঘূৰি অহাৰ পিছত পাঁচ বছৰৰ বাবে আমাৰ সৈতে কাম কৰিব লাগিব।",
              "gu": "અમે કોર્સ, મુસાફરી, રહેવા અને દરેક વસ્તુ માટે ચૂકવણી કરીએ છીએ, પરંતુ એકવાર તું પાછી આવો ત્યારે તારે અમારી સાથે પાંચ વર્ષ કામ કરવું પડશે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_4",
              "hi": "E3_2_4",
              "ka": "E3_2_4",
              "te": "E3_2_4",
              "or": "E3_2_4",
              "as": "E3_2_4",
              "gu": "E3_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is very interested in this opportunity. But the field of research is very different from Nanotechnology, which she was hoping to study in her Master’s.",
              "hi": "मीरा इस अवसर में बहुत रुचि रखती है। लेकिन अनुसंधान का क्षेत्र नैनोटेक्नोलॉजी से बहुत अलग है, जिसमें वह अपने स्नातकोत्तर में अध्ययन करने की उम्मीद कर रही थी।",
              "ka": "ಈ ಅವಕಾಶವನ್ನು ಬಳಸಿಕೊಳ್ಳಲು ಮೀರಾ ತುಂಬಾ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದಾಳೆ. ಆದರೆ ಸಂಶೋಧನಾ ಕ್ಷೇತ್ರವು ನ್ಯಾನೊತಂತ್ರಜ್ಞಾನಕ್ಕಿಂತ ಬಹಳ ಭಿನ್ನವಾಗಿರುತ್ತದೆ, ಅವಳು ತನ್ನ ಸ್ನಾತಕೋತ್ತರ ಪದವಿಯಲ್ಲಿ ನ್ಯಾನೊತಂತ್ರಜ್ಞಾನವನ್ನು ಅಧ್ಯಯನ ಮಾಡಲು ಆಶಿಸಿದ್ದಳು.",
              "te": "ఈ అవకాశంపై మీరా చాలా ఆసక్తిగా ఉంది. కానీ ఆమె మాస్టర్స్ చదవాలని ఆశించిన నానోటెక్నాలజీకి పరిశోధనా రంగం చాలా వేరుగా ఉంటుంది.",
              "or": "ମୀରା ଏହି ସୁଯୋଗ ପାଇଁ ବହୁତ ଆଗ୍ରହୀ | କିନ୍ତୁ ଗବେଷଣା କ୍ଷେତ୍ର ନାନୋଟେକ୍ନୋଲୋଜି\n  ଠାରୁ ବହୁତ ଭିନ୍ନ, ଯାହା ସେ ତାଙ୍କ ମାଷ୍ଟର ପଢିବାକୁ ଆଶା କରୁଥିଲେ |",
              "as": "মীৰা এই সুযোগত বহুত আগ্ৰহী। কিন্তু গৱেষণাৰ ক্ষেত্ৰটো নেনোটেকন'লজীতকৈ বহুত বেলেগ, যিটো তেওঁ তেওঁৰ স্নাতকোত্তৰ ত অধ্যয়ন কৰাৰ আশা কৰিছিল।",
              "gu": "મીરાને આ તકમાં ખૂબ જ રસ છે. પરંતુ સંશોધનનું ક્ષેત્ર નેનો ટેક્નોલોજીથી ઘણું અલગ છે, જે તે તેના માસ્ટરમાં અભ્યાસ કરવાની આશા રાખતી હતી."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_3_1",
              "hi": "E3_3_1",
              "ka": "E3_3_1",
              "te": "E3_3_1",
              "or": "E3_3_1",
              "as": "E3_3_1",
              "gu": "E3_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira gets in touch with another student who went to France and hears many nice things about the program.",
              "hi": "मीरा एक और छात्र से संपर्क करती है जो फ्रांस जा चुका है और कार्यक्रम के बारे में कई अच्छी चीजें सुनती है।",
              "ka": "ಮೀರಾ ಫ್ರಾನ್ಸ್‌ಗೆ ಹೋದ ಇನ್ನೊಬ್ಬ ವಿದ್ಯಾರ್ಥಿಯೊಂದಿಗೆ ಸಂಪರ್ಕ ಮಾಡುತ್ತಾಳೆ ಮತ್ತು ಕಾರ್ಯಕ್ರಮದ ಬಗ್ಗೆ ಅನೇಕ ಒಳ್ಳೆಯ ವಿಷಯಗಳನ್ನು ಕೇಳುತ್ತಾಳೆ.",
              "te": "మీరా ఫ్రాన్స్ కి వెళ్లిన మరొక విద్యార్థితో సన్నిహితంగా ఉంటుంది మరియు ప్రోగ్రామ్ గురించి చాలా మంచి విషయాలు తెలుసుకుంటుంది.",
              "or": "ମୀରା ଅନ୍ୟ ଜଣେ ଛାତ୍ରଙ୍କ ସହ ଯୋଗାଯୋଗ କରନ୍ତି ଯିଏ ଫ୍ରାନ୍ସ ଯାଇ ଏହି କାର୍ଯ୍ୟକ୍ରମ\n  ବିଷୟରେ ଅନେକ ଭଲ କଥା ଶୁଣନ୍ତି |",
              "as": "মীৰাই আন এজন শিক্ষাৰ্থীৰ সৈতে কথা পাতে যি ফ্ৰান্সলৈ গৈছিল আৰু কাৰ্যসূচীটোৰ বিষয়ে বহুতো ভাল কথা শুনিলে।",
              "gu": "મીરા અન્ય વિદ્યાર્થી સાથે સંપર્કમાં આવે છે જે ફ્રાન્સ ગયો હતો અને પ્રોગ્રામ વિશે ઘણી સરસ વાતો સાંભળે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_3_2",
              "hi": "E3_3_2",
              "ka": "E3_3_2",
              "te": "E3_3_2",
              "or": "E3_3_2",
              "as": "E3_3_2",
              "gu": "E3_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The student shares that the training is not the same as a Master's program, but there is much to learn. Mira has a lot of questions to ask the student.",
              "hi": "छात्र बताता है कि प्रशिक्षण स्नातकोत्तर कार्यक्रम के समान नहीं है, लेकिन सीखने के लिए बहुत कुछ है। छात्र से पूछने के लिए मीरा के पास बहुत सारे प्रश्न हैं।",
              "ka": "ಈ ತರಬೇತಿಯು ಸ್ನಾತಕೋತ್ತರ ಕಾರ್ಯಕ್ರಮದಂತೆಯೇ ಅಲ್ಲ, ಆದರೆ ಕಲಿಯಲು ಬಹಳಷ್ಟು ಇದೆ ಎಂದು ವಿದ್ಯಾರ್ಥಿಯು ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾನೆ. ಮೀರಾಳ ಬಳಿ ವಿದ್ಯಾರ್ಥಿಗೆ ಕೇಳಲು ಸಾಕಷ್ಟು ಪ್ರಶ್ನೆಗಳಿವೆ.",
              "te": "శిక్షణ అనేది మాస్టర్స్ ప్రోగ్రామ్ లాంటిది కాదని, అయితే నేర్చుకోవలసింది చాలా ఉందని విద్యార్థి చెబుతాడు. మీరా విద్యార్థిని అడగడానికి చాలా ప్రశ్నలు ఉన్నాయి.",
              "or": "ଛାତ୍ର ଅଂଶୀଦାର କରିଛନ୍ତି ଯେ ତାଲିମ ଏକ ମାଷ୍ଟର ପ୍ରୋଗ୍ରାମ୍ ସହିତ ସମାନ ନୁହେଁ, \n କିନ୍ତୁ ବହୁତ କିଛି ଶିଖିବାକୁ ଅଛି | ଛାତ୍ରଙ୍କୁ ପଚାରିବା ପାଇଁ ମୀରାଙ୍କର ଅନେକ ପ୍ରଶ୍ନ ଅଛି |",
              "as": "শিক্ষাৰ্থীজনে কয় যে প্ৰশিক্ষণটো স্নাতকোত্তৰ দৰে একে নহয়, কিন্তু শিকিবলৈ বহুত পায়। মীৰাৰ ছাত্ৰজনক সুধিবলৈ বহুতো প্ৰশ্ন আছে।",
              "gu": "વિદ્યાર્થી કહે છે કે તાલીમ એ માસ્ટર પ્રોગ્રામ જેવું નથી, પરંતુ શીખવા માટે ઘણું બધું છે. મીરા પાસે વિદ્યાર્થીને ઘણા પ્રશ્નો પૂછવા છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_3_3 interaction",
              "hi": "E3_3_3 इंटरैक्शन",
              "ka": "E3_3_3 interaction",
              "te": "E3_3_3 ఇద్దరి వ్యాకుల మధ్య సంబాషణ",
              "or": "E3_3_3 interaction",
              "as": "E3_3_3 বাৰ্তালাপ",
              "gu": "E3_3_3 વાર્તાલાભ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click on the questions to find out the answers Mira gets.",
              "hi": "मीरा को प्राप्त जवाब जानने के लिए प्रश्नों पर क्लिक करें।",
              "ka": "ಮೀರಾಳಿಗೆ ದೊರಕುವ ಉತ್ತರಕಾಗಿ ಆಯಾ ಪ್ರಶ್ನೆಗಳ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ ",
              "te": "మీరాకు సమాధానాలు తెలుసుకోవడానికి ప్రశ్నలపై క్లిక్ చేయండి.",
              "or": "ମୀରା ପାଇଥିବା ଉତ୍ତର ଜାଣିବା ପାଇଁ ପ୍ରଶ୍ନଗୁଡ଼ିକ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "মীৰাই পোৱা উত্তৰবোৰ জানিবলৈ প্ৰশ্নবোৰত ক্লিক কৰক।",
              "gu": "મીરાને મળેલા જવાબો જાણવા માટે પ્રશ્નો પર ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "How was your experience with this training?",
              "hi": "इस प्रशिक्षण के साथ आपका अनुभव कैसा रहा?",
              "ka": "ಈ ತರಬೇತಿಯಲ್ಲಿ ನಿನ್ನ ಅನುಭವ ಹೇಗಿತ್ತು?",
              "te": "ఈ శిక్షణతో నీ అనుభవం ఎలా ఉంది?",
              "or": "ଏହି ତାଲିମ ସହିତ ତୁମର ଅଭିଜ୍ଞତା କିପରି ଥିଲା?",
              "as": "এই প্ৰশিক্ষণৰ সৈতে আপোনাৰ অভিজ্ঞতা কেনেকোৱা হৈছিল?",
              "gu": "આ તાલીમ સાથે તમારો અનુભવ કેવો રહ્યો?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "What is the difference between this course and a Master's degree?",
              "hi": "इस कोर्स और स्नातकोत्तर की डिग्री के बीच क्या अंतर है?",
              "ka": "ಈ ಕೋರ್ಸ್ ಮತ್ತು ಸ್ನಾತಕೋತ್ತರ ಪದವಿ ನಡುವಿನ ವ್ಯತ್ಯಾಸವೇನು?",
              "te": "ఈ కోర్సు మరియు మాస్టర్స్ డిగ్రీ మధ్య తేడా ఏమిటి?",
              "or": "ଏହି ପାଠ୍ୟକ୍ରମ ଏବଂ ମାଷ୍ଟର ଡିଗ୍ରୀ ମଧ୍ୟରେ ପାର୍ଥକ୍ୟ କ’ଣ?",
              "as": "এই পাঠ্যক্ৰম আৰু স্নাতকোত্তৰ ডিগ্ৰীৰ মাজত পাৰ্থক্য কি?",
              "gu": "આ કોર્સ અને માસ્ટર ડિગ્રી વચ્ચે શું તફાવત છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "How did you adjust to a new country?",
              "hi": "आपने एक नए देश में कैसे तालमेल बैठाया?",
              "ka": "ನೀನು ಹೊಸ ದೇಶಕ್ಕೆ ಹೇಗೆ ಹೊಂದಿಕೊಂಡಿದ್ದೀಯಾ?",
              "te": "నువ్వు కొత్త దేశంలో ఎలా సర్దుబాటు అయ్యారు?",
              "or": "ଆପଣ ଏକ ନୂତନ ଦେଶକୁ କିପରି ଆଡଜଷ୍ଟ କଲେ?",
              "as": "আপুনি এখন নতুন দেশৰ সৈতে কেনেদৰে খাপ খাইছিল?",
              "gu": "તું નવા દેશમાં કેવી રીતે એડજસ્ટ થયા?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "Do I have to learn French?",
              "hi": "क्या मुझे फ्रेंच सीखना पड़ेगा?",
              "ka": "ನಾನು ಫ್ರೆಂಚ್ ಭಾಷೆಯನ್ನು ಕಲಿಯಬೇಕೆ?",
              "te": "నేను ఫ్రెంచ్ నేర్చుకోవాలా?",
              "or": "ମୋତେ ଫରାସୀ ଶିଖିବାକୁ ପଡିବ କି?",
              "as": "মই ফ্ৰেন্চ শিকিব লাগিব নেকি?",
              "gu": "શું મારે ફ્રેન્ચ શીખવું પડશે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "I enjoyed the training.  It has a good mix of theory and practical. The best thing is you work on an actual project.",
              "hi": "मैंने प्रशिक्षण का आनंद लिया। यह सिद्धांत और प्रयोगात्मकता का एक अच्छा मिश्रण है। सबसे अच्छी बात यह है कि आप एक वास्तविक प्रोजेक्ट पर काम करते हैं।",
              "ka": "ನಾನು ತರಬೇತಿಯನ್ನು ಆನಂದಿಸಿದೆ. ಇದು ಸಿದ್ಧಾಂತ ಮತ್ತು ಪ್ರಾಯೋಗಿಕತೆಯ ಉತ್ತಮ ಮಿಶ್ರಣವನ್ನು ಹೊಂದಿದೆ. ಇಲ್ಲಿ ನೀವು ನಿಜವಾದ ಯೋಜನೆಯ ಮೇಲೆ ಕೆಲಸ ಮಾಡುವುದು ಒಂದು ಉತ್ತಮ ವಿಷಯ.",
              "te": "నేను శిక్షణను ఎంజాయ్ చేశాను. ఇది మంచి సిద్ధాంతం మరియు ప్రాక్టికల్ గా ఉంటుంది. నువ్వు నిజమైన ప్రాజెక్ట్ లో పని చేయడం మంచిది.",
              "or": "ମୁଁ ତାଲିମକୁ ଉପଭୋଗ କଲି | ଏହାର ସିଦ୍ଧାନ୍ତ ଏବଂ ବ୍ୟବହାରିକ ଏକ ଭଲ ମିଶ୍ରଣ ଅଛି \n | ସର୍ବୋତ୍ତମ କଥା ହେଉଛି ଆପଣ ଏକ ପ୍ରକୃତ ପ୍ରୋଜେକ୍ଟରେ କାମ କରନ୍ତି |",
              "as": "মই প্ৰশিক্ষণটো ভাল পাইছিলোঁ। ইয়াত থিয়ৰী আৰু প্ৰেক্টিকেলৰ এটা ভাল মিশ্ৰণ আছে। আটাইতকৈ ভাল কথাটো হ'ল চবেই এটা সঁচা প্ৰকল্পত কাম কৰে।",
              "gu": "મેં તાલીમનો આનંદ માણ્યો. તેમાં થિયરી અને પ્રયોગોનું સારું મિશ્રણ છે. શ્રેષ્ઠ બાબત એ છે કે તું વાસ્તવિક પ્રોજેક્ટ પર કામ કરીશ."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "A Master's degree is similar but a lot more extensive. This is a shorter course, and you spend a lot of time working on a live project. You get valuable work experience too.",
              "hi": "एक स्नातकोत्तर की डिग्री समान है लेकिन बहुत अधिक व्यापक है। यह एक छोटा कोर्स है, और आप एक लाइव प्रोजेक्ट पर काम करने में बहुत समय बिताते हैं। आपको मूल्यवान कार्य अनुभव भी मिलता है।",
              "ka": "ಸ್ನಾತಕೋತ್ತರ ಪದವಿಯು ಇದನ್ನೇ ಹೋಲುತ್ತದೆ ಆದರೆ ಹೆಚ್ಚು ವಿಸ್ತಾರವಾಗಿರುತ್ತದೆ. ಇದು ಕಡಿಮೆ ಸಮಯದ ಕೋರ್ಸ್ ಆಗಿದೆ ಮತ್ತು ನೀವು ಲೈವ್ ಪ್ರಾಜೆಕ್ಟ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಾ ಸಾಕಷ್ಟು ಸಮಯವನ್ನು ಕಳೆಯುತ್ತೀರಿ. ನೀವು ಅಮೂಲ್ಯವಾದ ಕೆಲಸದ ಅನುಭವವನ್ನು ಸಹ ಪಡೆಯುತ್ತೀರಿ.",
              "te": "మాస్టర్స్ డిగ్రీ సారూప్యంగా ఉంటుంది కానీ చాలా విస్తృతమైనది. ఇది ఒక చిన్న కోర్సు, మరియు నువ్వు లైవ్ ప్రాజెక్ట్‌లో ఎక్కువ సమయం వెచ్చిస్తావు. నువ్వు విలువైన పని అనుభవం కూడా పొందుతావు.",
              "or": "ଏକ ମାଷ୍ଟର ଡିଗ୍ରୀ ସମାନ କିନ୍ତୁ ବହୁତ ଅଧିକ ବିସ୍ତୃତ | ଏହା ଏକ କ୍ଷୁଦ୍ର ପାଠ୍ୟକ୍ରମ, \n ଏବଂ ଆପଣ ଏକ ଲାଇଭ୍ ପ୍ରୋଜେକ୍ଟରେ କାମ କରିବାକୁ ବହୁତ ସମୟ ଅତିବାହିତ କରନ୍ତି | ତୁମେ ମଧ୍ୟ ମୂଲ୍ୟବାନ କାର୍ଯ୍ୟ ଅଭିଜ୍ଞତା ପାଇବ |",
              "as": "স্নাতকোত্তৰ ডিগ্ৰী একে কিন্তু বহুত বেছি ডাঙৰ। এইটো এটা চুটি পাঠ্যক্ৰম, আৰু আপুনি এটা লাইভ প্ৰকল্পত কাম কৰিবলৈ বহুত সময় পায়। আপুনি মূল্যৱান কামৰ অভিজ্ঞতাও লাভ কৰে।",
              "gu": "માસ્ટર ડિગ્રી સમાન છે પરંતુ ઘણી વધુ વ્યાપક છે. આ એક નાનો અભ્યાસક્રમ છે, અને તું ચાલુ પ્રોજેક્ટ પર કામ કરવા માટે ઘણો સમય પસાર કરીશ. તને મૂલ્યવાન કામનો અનુભવ પણ મળે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "I like exploring new cultures, so I liked it. But I found it difficult to adjust initially, but that is true for any place you visit.",
              "hi": "मुझे नई संस्कृतियों की खोज करना पसंद है, इसलिए मुझे यह पसंद आया। लेकिन आरम्भ में तालमेल बैठाने में मुझे मुश्किल हुई, पर यह किसी भी स्थान की सच्चाई है जहाँ आप जाते हैं।",
              "ka": "ನಾನು ಹೊಸ ಸಂಸ್ಕೃತಿಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಇಷ್ಟಪಡುತ್ತೇನೆ, ಹಾಗಾಗಿ ನಾನು ಅದನ್ನು ಇಷ್ಟಪಟ್ಟೆ. ಆದರೆ ಆರಂಭದಲ್ಲಿ ಸರಿಹೊಂದಿಕೊಳ್ಳಲು ನನಗೆ ಕಷ್ಟವಾಯಿತು, ಆದರೆ ನೀವು ಭೇಟಿ ನೀಡುವ ಯಾವುದೇ ಸ್ಥಳಕ್ಕೆ ಇದು ಅನ್ವಯಿಸುತ್ತದೆ.",
              "te": "నేకు కొత్త సంస్కృతులను అన్వేషించడం ఇష్టం, కాబట్టి నేను దానిని ఇష్టపడ్డాను. కానీ నాకు మొదట్లో సర్దుబాటు చేసుకోవడం కష్టంగా అనిపించింది, కానీ నువ్వు ఎక్కడికి వెళ్ళినా అలానే ఉంటుంది.",
              "or": "ମୁଁ ନୂତନ ସଂସ୍କୃତି ଅନୁସନ୍ଧାନ କରିବାକୁ ପସନ୍ଦ କରେ, ତେଣୁ ମୁଁ ଏହାକୁ ପସନ୍ଦ କଲି |\n  କିନ୍ତୁ ମୁଁ ପ୍ରାରମ୍ଭରେ ଆଡଜଷ୍ଟ କରିବା କଷ୍ଟକର ହୋଇପଡିଛି, କିନ୍ତୁ ଆପଣ ପରିଦର୍ଶନ କରୁଥିବା ଯେକ place ଣସି ସ୍ଥାନ ପାଇଁ ଏହା ସତ୍ୟ |",
              "as": "মই নতুন জেগা ফুৰিবলৈ ভাল পাওঁ, সেয়েহে মই ভাল পাইছিলো। কিন্তু মই আৰম্ভণিতে মিলা-মিছা কৰাটো টান পাইছিলো, কিন্তু তুমি ভ্ৰমণ কৰা যিকোনো জেগাতেই এনেকুৱা।",
              "gu": "મને નવી સંસ્કૃતિઓ શોધવાનું ગમે છે, તેથી મને તે ગમ્યું. પરંતુ મને શરૂઆતમાં એડજસ્ટ કરવું મુશ્કેલ લાગ્યું, પરંતુ તું મુલાકાત લેવી તે કોઈપણ સ્થળ માટે તે સાચું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "The people in the research centre talk in English, but it will help you if you know at least the basics.",
              "hi": "अनुसंधान केंद्र में लोग अंग्रेजी में बात करते हैं, लेकिन यदि आप कम से कम मूल बातें जानते हैं तो यह आपकी मदद करेगा।",
              "ka": "ಸಂಶೋಧನಾ ಕೇಂದ್ರದಲ್ಲಿರುವ ಜನರು ಇಂಗ್ಲಿಷ್‌ನಲ್ಲಿ ಮಾತನಾಡುತ್ತಾರೆ, ಆದರೆ ನೀವು ಕನಿಷ್ಟ ಮೂಲಭೂತ ಅಂಶಗಳನ್ನು ತಿಳಿದಿದ್ದಲ್ಲಿ ಅದು ನಿಮಗೆ ಸಹಾಯವನ್ನು ಮಾಡುತ್ತದೆ.",
              "te": "పరిశోధనా కేంద్రంలోని వ్యక్తులు ఆంగ్లంలో మాట్లాడతారు, కానీ నీకు కనీసం బేసిక్స్ తెలిస్తే అది నీకు సహాయం చేస్తుంది.",
              "or": "ଅନୁସନ୍ଧାନ କେନ୍ଦ୍ରର ଲୋକମାନେ ଇଂରାଜୀରେ କଥାବାର୍ତ୍ତା କରନ୍ତି, କିନ୍ତୁ ଯଦି ଆପଣ \n ଅତି କମରେ ମ ics ଳିକତା ଜାଣନ୍ତି ତେବେ ଏହା ଆପଣଙ୍କୁ ସାହାଯ୍ୟ କରିବ |",
              "as": "গৱেষণা কেন্দ্ৰৰ লোকসকলে ইংৰাজীত কথা কয়, কিন্তু যদি তুমি অলপ হলেও কবলৈ জানা তেন্তে ই তোমাক সহায় কৰিব।",
              "gu": "સંશોધન કેન્દ્રના લોકો અંગ્રેજીમાં વાત કરે છે, પરંતુ જો તું ઓછામાં ઓછી મૂળભૂત બાબતો જાણટી હોવ તો તે તને મદદ કરશે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "informational interview",
              "hi": "सूचनात्मक साक्षात्कार",
              "ka": "ಮಾಹಿತಿಯ ಸಂದರ್ಶನ",
              "te": "సమాచార ఇంటర్వ్యూ",
              "or": "ସୂଚନାଯୋଗ୍ୟ ସାକ୍ଷାତକାର |",
              "as": "ইনফৰমেচনেল ইনটাৰভিউ",
              "gu": "માહિતીપ્રદ મુલાકાત"
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_4_1",
              "hi": "E3_4_1",
              "ka": "E3_4_1",
              "te": "E3_4_1",
              "or": "E3_4_1",
              "as": "E3_4_1",
              "gu": "E3_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is left confused as Nanotechnology is her passion and the certification is also different from a Master’s degree, but she is also excited to explore a new country to study and learn.",
              "hi": "मीरा दुविधा में है क्योंकि नैनो टेक्नोलॉजी उसका जुनून है और सर्टिफिकेशन स्नातकोत्तर डिग्री से भी अलग है, लेकिन वह अध्ययन और सीखने के लिए एक नए देश के बारे में जानने के लिए भी उत्साहित है।",
              "ka": "ನ್ಯಾನೊತಂತ್ರಜ್ಞಾನವು ಅವಳ ಉತ್ಸಾಹವಾಗಿದ್ದು ಹಾಗೂ ಪ್ರಮಾಣ ಪತ್ರವು ಕೂಡ ಸ್ನಾತಕೋತ್ತರ ಪದವಿಗಿಂತ ಭಿನ್ನವಾಗಿರುವುದರಿಂದ ಮೀರಾ ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದಾಳೆ, ಆದರೆ ಅಧ್ಯಯನ ಮಾಡಲು ಮತ್ತು ಕಲಿಯಲು ಹೊಸ ದೇಶವನ್ನು ಅನ್ವೇಷಿಸಲು ಅವಳು ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ",
              "te": "నానోటెక్నాలజీ తన అభిరుచి మరియు సర్టిఫికేషన్ కూడా మాస్టర్స్ డిగ్రీకి భిన్నంగా ఉన్నందున మీరా అయోమయంలో పడింది, అయితే ఆమె అధ్యయనం చేయడానికి మరియు నేర్చుకోవడానికి కొత్త దేశాన్ని అన్వేషించడానికి కూడా ఉత్సాహంగా ఉంది.",
              "or": "ନାନୋଟେକ୍ନୋଲୋଜି ହେଉଛି ତାଙ୍କର ଉତ୍ସାହ ଏବଂ ସାର୍ଟିଫିକେଟ୍ ମଧ୍ୟ ମାଷ୍ଟର ଡିଗ୍ରୀଠାରୁ\n  ଭିନ୍ନ, କିନ୍ତୁ ଅଧ୍ୟୟନ ଏବଂ ଶିଖିବା ପାଇଁ ସେ ଏକ ନୂତନ ଦେଶ ଅନୁସନ୍ଧାନ କରିବାକୁ ଉତ୍ସାହିତ |",
              "as": "মীৰাৰ মনত খেলিমেলি লাগিছে কিয়নো নেনোটেকন'লজী তেওঁৰ আবেগ আৰু প্ৰমাণপত্ৰখন স্নাতকোত্তৰ ডিগ্ৰীতকৈ বেলেগ, কিন্তু তাই শিকাৰ বাবে এখন নতুন দেশলৈ যাবলৈও সুখি।",
              "gu": "મીરા મૂંઝવણમાં રહે છે કારણ કે નેનોટેકનોલોજી તેનો શોખ છે અને પ્રમાણપત્ર પણ માસ્ટર ડિગ્રીથી અલગ છે, પરંતુ તે અભ્યાસ અને શીખવા માટે નવા દેશની શોધખોળ કરવા માટે પણ ઉત્સાહિત છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_4_2",
              "hi": "E3_4_2",
              "ka": "E3_4_2",
              "te": "E3_4_2",
              "or": "E3_4_2",
              "as": "E3_4_2",
              "gu": "E3_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She shares the information with her family. Her family is happy that she is getting a chance to go out of the country but are worried about her staying alone in a new country.",
              "hi": "वह अपने परिवार के साथ जानकारी साझा करती है। उसका परिवार खुश है कि उसे देश से बाहर जाने का मौका मिल रहा है लेकिन एक नए देश में अकेले रहने के बारे में चिंतित हैं।",
              "ka": "ತನ್ನ ಕುಟುಂಬದವರೊಂದಿಗೆ ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾಳೆ. ಆಕೆಗೆ ದೇಶದಿಂದ ಹೊರಹೋಗುವ ಅವಕಾಶ ಸಿಗುತ್ತಿದೆ ಎಂದು ಆಕೆಯ ಕುಟುಂಬ ಸಂತಸ ವ್ಯಕ್ತಪಡಿಸಿದೆ ಆದರೆ ಹೊಸ ದೇಶದಲ್ಲಿ ಆಕೆ ಏಕಾಂಗಿಯಾಗಿ ಉಳಿಯುವ ಬಗ್ಗೆ ಚಿಂತಿತರಾಗುತ್ತಾರೆ.",
              "te": "ఆమె తన కుటుంబ సభ్యులతో దీని గురించి చెబుతుంది. ఆమె దేశం నుండి బయటకు వెళ్ళే అవకాశం వస్తోందని ఆమె కుటుంబ సభ్యులు సంతోషిస్తున్నారు, అయితే ఆమె కొత్త దేశంలో ఒంటరిగా ఉంటున్నందుకు ఆందోళన చెందుతున్నారు.",
              "or": "ସେ ତାଙ୍କ ପରିବାର ସହିତ ସୂଚନା ସେୟାର କରନ୍ତି | ତାଙ୍କ ପରିବାର ଖୁସି ଯେ ସେ \n ଦେଶ ବାହାରକୁ ଯିବାର ସୁଯୋଗ ପାଇଛନ୍ତି କିନ୍ତୁ ଏକ ନୂତନ ଦେଶରେ ଏକୁଟିଆ ରହିବାକୁ ଚିନ୍ତାରେ ଅଛନ୍ତି।",
              "as": "তাই কথাবোৰ তাইৰ পৰিয়ালক কয়। তাইৰ পৰিয়াল সুখী যে তাই দেশৰ বাহিৰলৈ যোৱাৰ সুযোগ পাইছে কিন্তু তাই এখন নতুন দেশত অকলে থকাৰ কথালৈ চিন্তিত।",
              "gu": "તે તેના પરિવાર સાથે માહિતી બતાવે છે. તેનો પરિવાર ખુશ છે કે તેને દેશની બહાર જવાની તક મળી રહી છે પરંતુ નવા દેશમાં તેને એકલા રહેવાથી ચિંતિત છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_4_3 D",
              "hi": "E3_4_3 D",
              "ka": "E3_4_3 D",
              "te": "E3_4_3 D",
              "or": "E3_4_3 D",
              "as": "E3_4_3 ডি",
              "gu": "E3_4_3 D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Mira do?",
              "hi": "मीरा को क्या करना चाहिए?",
              "ka": "ಮೀರಾ ಏನು ಮಾಡಬೇಕು?",
              "te": "మీరా ఏమి చేయాలి?",
              "or": "ମୀରା କଣ କରିବା ଉଚ୍ଚିତ?",
              "as": "মীৰাই কি কৰিব লাগে?",
              "gu": "મીરાએ શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Do Master’s in Nanotechnology",
              "hi": "नैनो टेक्नोलॉजी में मास्टर करे",
              "ka": "ನಾನೋ ಟೆಕ್ನಾಲಜಿಯಲ್ಲಿ ಮಾಸ್ಟರ್ಸ್ ಮಾಡುವುದೇ",
              "te": "నానోటెక్నాలజీలో మాస్టర్స్ చేయండి",
              "or": "ନାନୋଟେକ୍ନୋଲୋଜିରେ ମାଷ୍ଟର କରନ୍ତୁ |",
              "as": "নেনোটেকন'লজীত স্নাতকোত্তৰ কৰা",
              "gu": "નેનોટેકનોલોજીમાં માસ્ટર્સ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Do the certification program in France",
              "hi": "फ्रांस में सर्टिफिकेशन कार्यक्रम करे",
              "ka": "ಫ್ರ್ಯಾನ್ಸಿನಲ್ಲಿ ಸರ್ಟಿಫಿಕೇಶನ್ ಪ್ರೋಗ್ರಾಮ್ ಮಾಡುವುದೇ ",
              "te": "ఫ్రాన్స్ లో సర్టిఫికేషన్ ప్రోగ్రామ్ చేయండి",
              "or": "ଫ୍ରାନ୍ସରେ ସାର୍ଟିଫିକେଟ୍ ପ୍ରୋଗ୍ରାମ୍ କର |",
              "as": "ফ্ৰান্সত কাৰ্যসূচী কৰক",
              "gu": "ફ્રાન્સમાં પ્રમાણપત્ર કાર્યક્રમ કરો"
            }
          }
        ]
      },
      "scene43": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Do master’s in nanotechnology",
              "hi": "नैनो टेक्नोलॉजी में स्नातकोत्तर करे",
              "ka": "ನಾನೋ ಟೆಕ್ನಾಲಜಿಯಲ್ಲಿ ಮಾಸ್ಟರ್ಸ್ ಮಾಡು",
              "te": "నానోటెక్నాలజీలో మాస్టర్స్ చేయండి",
              "or": "ନାନୋଟେକ୍ନୋଲୋଜିରେ ମାଷ୍ଟର କରନ୍ତୁ |",
              "as": "নেনোটেকন'লজীত স্নাতকোত্তৰ কৰক",
              "gu": "નેનો ટેક્નોલોજીમાં માસ્ટર્સ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira quits her job and prepares for her Master’s. She wants to do it at the best Institute in the country.",
              "hi": "मीरा ने अपनी नौकरी छोड़ दी और अपने स्नातकोत्तर के लिए तैयारी करने लगे। वह इसे देश के सर्वश्रेष्ठ संस्थान में करना चाहती है।",
              "ka": "ಮೀರಾ ತನ್ನ ಕೆಲಸವನ್ನು ತೊರೆದು ತನ್ನ ಮಾಸ್ಟರ್ಸ್‌ಗೆ ತಯಾರಿ ನಡೆಸುತ್ತಾಳೆ. ಅವಳು ಅದನ್ನು ದೇಶದ ಅತ್ಯುತ್ತಮ ಸಂಸ್ಥೆಯಲ್ಲಿ ಮಾಡಲು ಬಯಸುತ್ತಾಳೆ.",
              "te": "మీరా తన ఉద్యోగాన్ని విడిచిపెట్టి, తన మాస్టర్స్ కోసం సిద్ధమవుతోంది. ఆమె దేశంలోని అత్యుత్తమ ఇన్‌స్టిట్యూట్‌లో చేయాలనుకుంటుంది.",
              "or": "ମୀରା ଚାକିରି ଛାଡି ନିଜ ମାଷ୍ଟରଙ୍କ ପାଇଁ ପ୍ରସ୍ତୁତ ହୁଏ | ସେ ଦେଶର ସର୍ବୋତ୍ତମ \n ଅନୁଷ୍ଠାନରେ ଏହା କରିବାକୁ ଚାହୁଁଛନ୍ତି |",
              "as": "মীৰাই চাকৰি এৰি তাইৰ মাষ্টাৰৰ কাৰনে প্ৰস্তুতি চলাইছে। তাই এইটো দেশৰ সকলোতকৈ ভাল প্ৰতিষ্ঠানত কৰিব বিচাৰে।",
              "gu": "મીરા તેની નોકરી છોડી દે છે અને તેના માસ્ટરની તૈયારી કરે છે. તે દેશની શ્રેષ્ઠ સંસ્થામાં કરવા માંગે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_1_2",
              "hi": "E4_1_2",
              "ka": "E4_1_2",
              "te": "E4_1_2",
              "or": "E4_1_2",
              "as": "E4_1_2",
              "gu": "E4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira gets selected at an Institute in Mumbai. She meets people from all over the country, many of whom are younger than her.",
              "hi": "मीरा मुंबई में एक संस्थान में चुनी गयी है। वह पूरे देश के लोगों से मिलती है, जिनमें से कई उससे छोटे भी हैं।",
              "ka": "ಮೀರಾ ಮುಂಬೈನ ಇನ್ಸ್ಟಿಟ್ಯೂಟ್ನಲ್ಲಿ ಆಯ್ಕೆಯಾಗುತ್ತಾಳೆ. ಅವಳು ದೇಶಾದ್ಯಂತದ ಜನರನ್ನು ಭೇಟಿಯಾಗುತ್ತಾಳೆ, ಅವರಲ್ಲಿ ಅನೇಕರು ಅವಳಿಗಿಂತ ಚಿಕ್ಕವರಾಗಿದ್ದಾರೆ.",
              "te": "మీరా ముంబైలోని ఒక ఇన్‌స్టిట్యూట్‌కి సెలెక్ట్ అయింది. ఆమె దేశం నలుమూలల నుండి ప్రజలను కలుస్తుంది, వారిలో చాలా మంది తన కంటే చిన్నవారు.",
              "or": "ମୀରା ମୁମ୍ବାଇର ଏକ ଇନଷ୍ଟିଚ୍ୟୁଟରେ ମନୋନୀତ ହୁଅନ୍ତି | ସେ ଦେଶର ବିଭିନ୍ନ ସ୍ଥାନରୁ\n  ଆସିଥିବା ଲୋକଙ୍କୁ ଭେଟିଥାନ୍ତି, ସେମାନଙ୍କ ମଧ୍ୟରୁ ଅନେକ ତାଙ୍କଠାରୁ ସାନ |",
              "as": "মীৰা মুম্বাইৰ এটা প্ৰতিষ্ঠানত চিট পালে। তাই সমগ্ৰ দেশৰ লোকসকলক লগ পায়, তাৰে মাজৰ বহুতো তাইতকৈ সৰু।",
              "gu": "મીરા મુંબઈની એક સંસ્થામાં પસંદગી થઈ ગઈ. તે દેશભરના લોકોને મળે છે, જેમાંથી ઘણા તેના કરતા નાના છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_1_3",
              "hi": "E4_1_3",
              "ka": "E4_1_3",
              "te": "E4_1_3",
              "or": "E4_1_3",
              "as": "E4_1_3",
              "gu": "E4_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She feels very out of place in Mumbai. The city life is too fast for Mira, and she misses her home a lot.",
              "hi": "वह मुंबई में अपने आपको अनुपयुक्त अनुभव करती है। मीरा के लिए शहर का जीवन बहुत तेज है, और वह अपने घर को बहुत याद करती है।",
              "ka": "ಮುಂಬೈನಲ್ಲಿ ಆಕೆಗೆ ಸ್ಥಾನವಿಲ್ಲ ಎಂದು ಅನಿಸತೊಡಗುತ್ತದೆ. ನಗರ ಜೀವನವು ಮೀರಾಗೆ ತುಂಬಾ ವೇಗವೆನಿಸುತ್ತದೆ ಹಾಗೂ ಅವಳು ತನ್ನ ಮನೆಯನ್ನು ತುಂಬಾ ಮಿಸ್ ಮಾಡಿಕೊಳ್ತಾ ಇದ್ದಾಳೆ.",
              "te": "ఆమెకి ముంబైలో చాలా దూరంగా ఉన్నట్లు అనిపిస్తుంది. మీరాకు నగర జీవితం చాలా వేగంగా ఉంది మరియు ఆమె తన ఇంటిని చాలా మిస్ అవుతుంది.",
              "or": "ସେ ମୁମ୍ବାଇରେ ନିଜକୁ ବହୁତ ଏକୁଟିଆ ଅନୁଭବ କରୁଛନ୍ତି | ମୀରା ପାଇଁ ସହର ଜୀବନ\n  ଅତ୍ୟନ୍ତ ଦ୍ରୁତ ଅଟେ, ଏବଂ ସେ ତାଙ୍କ ଘରକୁ ବହୁତ ମନେ କରନ୍ତି |",
              "as": "তাই মুম্বাইত বহুত বেয়া অনুভৱ কৰে। মীৰাৰ কাৰনে চহৰৰ জীৱন বহুত খৰ, আৰু তাই তাইৰ ঘৰটো বহুত মনত পেলায়।",
              "gu": "તે મુંબઈમાં ખૂબ જ અયોગ્ય લાગે છે. મીરા માટે શહેરનું જીવન ખૂબ જ ઝડપી છે, અને તે તેના ઘરને ખૂબ જ યાદ કરે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_2_1",
              "hi": "E4_2_1",
              "ka": "E4_2_1",
              "te": "E4_2_1",
              "or": "E4_2_1",
              "as": "E4_2_1",
              "gu": "E4_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Most of the students are friendly, but there is a lot of competition. There is limited funding for research, and every student is trying to prove themselves.",
              "hi": "अधिकांश छात्र दोस्ताना हैं, लेकिन बहुत प्रतिस्पर्धा है। अनुसंधान के लिए सीमित धन है, और हर छात्र खुद को साबित करने की कोशिश कर रहा है।",
              "ka": "ಹೆಚ್ಚಿನ ವಿದ್ಯಾರ್ಥಿಗಳು ಸ್ನೇಹಪರರಾಗಿದ್ದಾರೆ, ಆದರೆ ಸಾಕಷ್ಟು ಸ್ಪರ್ಧೆ ಇದೆ. ಸಂಶೋಧನೆಗೆ ಸೀಮಿತ ಹಣವಿದೆ, ಮತ್ತು ಪ್ರತಿ ವಿದ್ಯಾರ್ಥಿಯು ತಮ್ಮನ್ನು ತಾವು ಸಾಬೀತುಪಡಿಸಲು ಪ್ರಯತ್ನಿಸುತ್ತಿದ್ದಾರೆ.",
              "te": "చాలా మంది విద్యార్థులు స్నేహపూర్వకంగా ఉంటారు, కానీ చాలా పోటీ ఉంది. పరిశోధన కోసం తక్కువ నిధులు ఉన్నాయి మరియు ప్రతి విద్యార్థి తమను తాము నిరూపించుకోవడానికి ప్రయత్నిస్తున్నారు.",
              "or": "ଅଧିକାଂଶ ଛାତ୍ର ବନ୍ଧୁତ୍ୱପୂର୍ଣ୍ଣ, କିନ୍ତୁ ସେଠାରେ ଅନେକ ପ୍ରତିଯୋଗିତା ଅଛି | ଅନୁସନ୍ଧାନ\n  ପାଇଁ ସୀମିତ ଅର୍ଥ ଅଛି ଏବଂ ପ୍ରତ୍ୟେକ ଛାତ୍ର ନିଜକୁ ପ୍ରମାଣ କରିବାକୁ ଚେଷ୍ଟା କରୁଛନ୍ତି |",
              "as": "বেছিভাগ শিক্ষাৰ্থী ভাল , কিন্তু বহুত প্ৰতিযোগিতা আছে। গৱেষণাৰ বাবে কম চিট আছে, আৰু প্ৰতিজন শিক্ষাৰ্থীয়ে নিজকে প্ৰমাণ কৰিবলৈ চেষ্টা কৰি আছে।",
              "gu": "મોટાભાગના વિદ્યાર્થીઓ મૈત્રીપૂર્ણ છે, પરંતુ ત્યાં ઘણી સ્પર્ધા છે. સંશોધન માટે મર્યાદિત ભંડોળ છે, અને દરેક વિદ્યાર્થી પોતાને સાબિત કરવાનો પ્રયાસ કરી રહ્યો છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_2_2",
              "hi": "E4_2_2",
              "ka": "E4_2_2",
              "te": "E4_2_2",
              "or": "E4_2_2",
              "as": "E4_2_2",
              "gu": "E4_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She initially feels she has to prove herself to her professors. Her friends, Lina and Preeti, ask her to focus on her studies and learning, not on proving herself to others.",
              "hi": "वह शुरू में अनुभव करती है कि उसे प्रोफेसरों के सामने अपने आपको साबित करना है। उसकी मित्र, लीना और प्रीति, उसे अपने अध्ययन और शिक्षा पर ध्यान केंद्रित करने के लिए कहते हैं, न कि दूसरों को खुद को साबित करने पर।",
              "ka": "ತನ್ನ ಪ್ರಾಧ್ಯಾಪಕರಿಗೆ ತನ್ನನ್ನು ತಾನು ಸಾಬೀತುಪಡಿಸಬೇಕೆಂದು ಅವಳು ಆರಂಭದಲ್ಲಿ ಭಾವಿಸುತ್ತಾಳೆ. ಆದರೆ ಅವಳ ಸ್ನೇಹಿತರಾದ ಲೀನಾ ಮತ್ತು ಪ್ರೀತಿ, ಇತರರಿಗೆ ತನ್ನನ್ನು ತಾನು ಸಾಬೀತುಪಡಿಸುವುದು ಮುಖ್ಯವಲ್ಲ, ತನ್ನ ಅಧ್ಯಯನ ಮತ್ತು ಕಲಿಕೆಯ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸುವುದು ಮುಖ್ಯ ಎಂದು ಅವಳಿಗೆ ಹೇಳುತ್ತಾರೆ. ",
              "te": "ఆమె మొదట్లో తన ప్రొఫెసర్లకు తనను తాను నిరూపించుకోవాలని భావిస్తుంది. ఆమె స్నేహితులు లీనా మరియు ప్రీతి ఇతరులకు తనను తాను నిరూపించుకోవడంపై కాకుండా తన చదువులు మరియు అభ్యాసంపై దృష్టి పెట్టమని ఆమెకి చెబుతారు.",
              "or": "ସେ ପ୍ରଥମେ ଅନୁଭବ କରନ୍ତି ଯେ ତାଙ୍କୁ ନିଜ ପ୍ରଫେସରମାନଙ୍କ ନିକଟରେ ପ୍ରମାଣ\n  କରିବାକୁ ପଡିବ | ତାଙ୍କର ବନ୍ଧୁ ଲୀନା ଏବଂ ପ୍ରୀତି ତାଙ୍କୁ ଅନ୍ୟମାନଙ୍କୁ ପ୍ରମାଣ କରିବା ଉପରେ ନୁହେଁ, ତାଙ୍କ ଅଧ୍ୟୟନ ଏବଂ ଶିକ୍ଷା ଉପରେ ଧ୍ୟାନ ଦେବାକୁ କୁହନ୍ତି |",
              "as": "তাই প্ৰথমতে লাগে যে তাই নিজকে তাইৰ অধ্যাপকসকলৰ ওচৰত প্ৰমাণ কৰিব লাগিব। তাইৰ বন্ধু, লীনা আৰু প্ৰীতিয়ে তাইক তাইৰ পঢ়া-শুনা আৰু শিকাৰ ওপৰত গুৰুত্ব দিবলৈ কয়, আনৰ ওচৰত নিজকে প্ৰমাণ কৰাৰ ওপৰত নহয়।",
              "gu": "તેને શરૂઆતમાં લાગે છે કે તેને તેના પ્રોફેસરો સમક્ષ પોતાને સાબિત કરવાની જરૂર છે. તેના મિત્રો, લીના અને પ્રીતિ, તેને તેના અભ્યાસ અને શીખવા પર ધ્યાન કેન્દ્રિત કરવા કહે છે, પોતાને અન્ય લોકો સમક્ષ સાબિત કરવા પર નહીં."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_2_3",
              "hi": "E4_2_3",
              "ka": "E4_2_3",
              "te": "E4_2_3",
              "or": "E4_2_3",
              "as": "E4_2_3",
              "gu": "E4_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "During the course, she is researching for a paper. She starts reading about how nanotechnology is used to create waterproof and antibacterial clothes.",
              "hi": "कोर्स के दौरान, वह एक पेपर के लिए शोध कर रही है। वह इस बारे में पढ़ना शुरू कर देती है कि कैसे नैनो टेक्नोलॉजी का उपयोग वाटरप्रूफ और जीवाणुरोधी कपड़े बनाने के लिए किया जाता है।",
              "ka": "ಕೋರ್ಸ್ ಸಮಯದಲ್ಲಿ, ಅವಳು ವಿಷಯಕ್ಕಾಗಿ ಸಂಶೋಧನೆ ಮಾಡುತ್ತಿದ್ದಾಳೆ. ಜಲನಿರೋಧಕ ಮತ್ತು ಬ್ಯಾಕ್ಟೀರಿಯಾ ವಿರೋಧಿ ಬಟ್ಟೆಗಳನ್ನು ರಚಿಸಲು ನ್ಯಾನೊತಂತ್ರಜ್ಞಾನವನ್ನು ಹೇಗೆ ಬಳಸಲಾಗುತ್ತದೆ ಎನ್ನುವುದನ್ನೂ ಅವಳು ಓದಲು ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ.",
              "te": "కోర్సు సమయంలో, ఆమె ఒక పేపర్ కోసం పరిశోధన చేస్తోంది. వాటర్ ప్రూఫ్ మరియు యాంటీ బాక్టీరియల్ దుస్తులను చేయడానికి నానోటెక్నాలజీ ఎలా ఉపయోగించబడుతుందో ఆమె చదవడం ప్రారంభించింది.",
              "or": "ପାଠ୍ୟକ୍ରମ ସମୟରେ, ସେ ଏକ କାଗଜ ପାଇଁ ଅନୁସନ୍ଧାନ କରୁଛନ୍ତି | ୱାଟରପ୍ରୁଫ୍\n  ଏବଂ ଆଣ୍ଟିବ୍ୟାକ୍ଟେରିଆଲ୍ ପୋଷାକ ତିଆରି କରିବା ପାଇଁ ନାନୋଟେକ୍ନୋଲୋଜି କିପରି ବ୍ୟବହୃତ ହୁଏ ସେ ବିଷୟରେ ସେ ପଢିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "পাঠ্যক্ৰমৰ সময়ত, তাই এখন পেপাৰৰ কাৰনে গৱেষণা কৰি আছে। তাই পঢ়িবলৈ আৰম্ভ কৰে যে কেনেকৈ জলৰোধী আৰু বেক্টেৰিয়া বিৰোধী কাপোৰ তৈয়াৰ কৰিবলৈ নেনোপ্ৰযুক্তি ব্যৱহাৰ কৰা হয়।",
              "gu": "કોર્સ દરમિયાન, તે એક પેપર માટે સંશોધન કરી રહી છે. તે વોટરપ્રૂફ અને એન્ટીબેક્ટેરિયલ કપડાં બનાવવા માટે નેનો ટેકનોલોજીનો ઉપયોગ કેવી રીતે થાય છે તે વિશે વાંચવાનું શરૂ કરે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_2_4",
              "hi": "E4_2_4",
              "ka": "E4_2_4",
              "te": "E4_2_4",
              "or": "E4_2_4",
              "as": "E4_2_4",
              "gu": "E4_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira is fascinated by it and does more research on the same topic.",
              "hi": "मीरा इससे अत्यंत प्रभावित है और उसी विषय पर अधिक शोध करती है।",
              "ka": "ಮೀರಾ ಅದರಿಂದ ಆಕರ್ಷಿತಳಾಗುತ್ತಾಳೆ ಮತ್ತು ಅದೇ ವಿಷಯದ ಬಗ್ಗೆ ಹೆಚ್ಚಿನ ಸಂಶೋಧನೆಯನ್ನು ಮಾಡುತ್ತಾಳೆ",
              "te": "మీరా దానికి ఆకర్షితులై అదే అంశంపై మరిన్ని పరిశోధనలు చేస్తుంది.",
              "or": "ମୀରା ଏହା ଦ୍ୱାରା ଆକର୍ଷିତ ହୋଇ ସମାନ ବିଷୟ ଉପରେ ଅଧିକ ଗବେଷଣା କରନ୍ତି |",
              "as": "মীৰায়ে এইটো ভাল পায় আৰু একেটা বিষয়ৰ ওপৰত অধিক গৱেষণা কৰে।",
              "gu": "મીરા તેનાથી પ્રભાવિત થાય છે અને તે જ વિષય પર વધુ સંશોધન કરે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_3_1",
              "hi": "E4_3_1",
              "ka": "E4_3_1",
              "te": "E4_3_1",
              "or": "E4_3_1",
              "as": "E4_3_1",
              "gu": "E4_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She also does an internship with a company started by researchers who created a coating on clothes to make them antibacterial and antiviral. She enjoys her internship and learns a lot on the job.",
              "hi": "वह उन शोधकर्ताओं द्वारा शुरू की गई कंपनी में इंटर्नशिप भी करती है जिन्होंने कपडे को जीवाणुरोधी और एंटीवायरल बनाने के लिए एक कोटिंग बनाई। वह अपनी इंटर्नशिप का आनंद लेती है और बहुत कुछ सीखती है।",
              "ka": "ಅವಳು ಬಟ್ಟೆಗಳನ್ನು ಆಂಟಿಬ್ಯಾಕ್ಟೀರಿಯಲ್ ಮತ್ತು ಆಂಟಿವೈರಲ್ ಮಾಡಲು ಬೇಕಾದ ಲೇಪನವನ್ನು ರಚಿಸಿದ ಸಂಶೋಧಕರು ಪ್ರಾರಂಭಿಸಿದ ಕಂಪನಿಯೊಂದಿಗೆ ಇಂಟರ್ನ್‌ಶಿಪ್ ಮಾಡುತ್ತಾಳೆ. ಅವಳು ತನ್ನ ಇಂಟರ್ನ್‌ಶಿಪ್ ಅನ್ನು ಆನಂದಿಸುತ್ತಾಳೆ ಮತ್ತು ಕೆಲಸದಲ್ಲಿ ಬಹಳಷ್ಟು ಕಲಿತುಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "ఆమె బట్టలను యాంటీ బాక్టీరియల్ మరియు యాంటీవైరల్‌గా చేయడానికి కోటింగ్ ని సృష్టించిన పరిశోధకులు ప్రారంభించిన కంపెనీతో ఇంటర్న్ షిప్ కూడా చేస్తుంది. ఆమె తన ఇంటర్న్ షిప్ ని ఆనందిస్తుంది మరియు ఉద్యోగంలో చాలా నేర్చుకుంటుంది.",
              "or": "ଅନୁସନ୍ଧାନକାରୀଙ୍କ ଦ୍ଵାରା ଆରମ୍ଭ ହୋଇଥିବା ଏକ କମ୍ପାନୀ ସହିତ ସେ ଏକ ଇଣ୍ଟର୍ନସିପ୍ ମଧ୍ୟ \n କରନ୍ତି, ଯେଉଁମାନେ ଆଣ୍ଟିବ୍ୟାକ୍ଟେରିଆଲ୍ ଏବଂ ଆଣ୍ଟିଭାଇରାଲ୍ କରିବା ପାଇଁ ପୋଷାକ ଉପରେ ଏକ ଆବରଣ ସୃଷ୍ଟି କରିଥିଲେ | ସେ ନିଜର ଇଣ୍ଟର୍ନସିପ୍ ଉପଭୋଗ କରନ୍ତି ଏବଂ ଚାକିରିରେ ବହୁତ କିଛି ଶିଖନ୍ତି |",
              "as": "তাই গৱেষকসকলে আৰম্ভ কৰা এটা কোম্পানীৰ সৈতে এটা ইন্টাৰ্ণশ্বিপও কৰে যিয়ে কাপোৰৰ ওপৰত এটা আৱৰণ বনাইছিল যাতে তেওঁলোকক এণ্টিবেক্টেৰিয়েল আৰু এণ্টিভাইৰেল কৰিব পাৰি। তাই তাইৰ ইন্টাৰ্ণশ্বিপ কৰি ভাল পাইছিল আৰু কামটোত বহুত শিকিলে।",
              "gu": "તે સંશોધકો દ્વારા શરૂ કરવામાં આવેલી કંપની સાથે ઇન્ટર્નશિપ પણ કરે છે જેમણે કપડાને એન્ટીબેક્ટેરિયલ અને એન્ટિવાયરલ બનાવવા માટે કોટિંગ બનાવ્યું હતું. તે તેની ઇન્ટર્નશીપનો આનંદ માણે છે અને નોકરી પર ઘણું શીખે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_3_2",
              "hi": "E4_3_2",
              "ka": "E4_3_2",
              "te": "E4_3_2",
              "or": "E4_3_2",
              "as": "E4_3_2",
              "gu": "E4_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After her Master’s, Mira decides to work for a few years and pursue her PhD. Her parents want her to get married before starting her PhD, but Mira intends to finish her research first.",
              "hi": "अपने स्नातकोत्तर के बाद, मीरा कुछ साल काम करने और अपनी पीएचडी करने का फैसला करती है। उसके माता-पिता पीएचडी शुरू करने से पहले उसकी शादी करना चाहते हैं, लेकिन मीरा पहले अपने शोध को खत्म करने का इरादा रखती है।",
              "ka": "ತನ್ನ ಸ್ನಾತಕೋತ್ತರ ಪದವಿಯ ನಂತರ, ಮೀರಾ ಕೆಲವು ವರ್ಷಗಳ ಕಾಲ ಕೆಲಸ ಮಾಡಲು ಮತ್ತು ತನ್ನ ಪಿಎಚ್‌ಡಿ ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಆಕೆಯ ಪಿಎಚ್‌ಡಿ ಪ್ರಾರಂಭಿಸುವ ಮೊದಲು ಅವಳನ್ನು ಮದುವೆಯಾಗಬೇಕೆಂದು ಆಕೆಯ ಪೋಷಕರು ಬಯಸುತ್ತಾರೆ, ಆದರೆ ಮೀರಾ ತನ್ನ ಸಂಶೋಧನೆಯನ್ನು ಮೊದಲು ಮುಗಿಸಲು ಉದ್ದೇಶಿಸಿದ್ದಾಳೆ.",
              "te": "తన మాస్టర్స్ తర్వాత, మీరా కొన్ని సంవత్సరాలు పని చేయాలని మరియు తన PhDని కొనసాగించాలని నిర్ణయించుకుంది. ఆమె PhDని ప్రారంభించే ముందు వివాహం చేసుకోవాలని ఆమె తల్లిదండ్రులు కోరుకుంటున్నారు, కానీ మీరా ముందు తన పరిశోధనను పూర్తి చేయాలని భావిస్తుంది.",
              "or": "ତାଙ୍କ ମାଷ୍ଟରଙ୍କ ପରେ, ମୀରା କିଛି ବର୍ଷ କାମ କରିବାକୁ ଏବଂ ପିଏଚଡି କରିବାକୁ\n  ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ତାଙ୍କ ପିତାମାତା ଚାହାଁନ୍ତି ଯେ ସେ ପିଏଚଡି ଆରମ୍ଭ କରିବା ପୂର୍ବରୁ ବିବାହ କରନ୍ତୁ, କିନ୍ତୁ ମୀରା ପ୍ରଥମେ ତାଙ୍କର ଅନୁସନ୍ଧାନ ଶେଷ କରିବାକୁ ଚାହୁଁଛନ୍ତି |",
              "as": "মাষ্টাৰৰ পিছত, মীৰাই কেইবছৰমান কাম কৰাৰ আৰু পিএইচডি কৰাৰ সিদ্ধান্ত লয়। তাইৰ পিতৃ-মাতৃয়ে বিচাৰে যে পিএইচডি আৰম্ভ কৰাৰ আগতে তাই বিয়া হ'ব, কিন্তু মীৰাই প্ৰথমে তাইৰ গৱেষণা সমাপ্ত কৰিব বিচাৰে।",
              "gu": "તેના માસ્ટર્સ પછી, મીરાએ થોડા વર્ષો કામ કરવાનું અને પીએચડી કરવાનું નક્કી કર્યું. તેના માતા-પિતા ઇચ્છે છે કે તે પીએચડી શરૂ કરતા પહેલા લગ્ન કરી લે, પરંતુ મીરા પહેલા તેનું સંશોધન પૂર્ણ કરવા માંગે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_3_3",
              "hi": "E4_3_3",
              "ka": "E4_3_3",
              "te": "E4_3_3",
              "or": "E4_3_3",
              "as": "E4_3_3",
              "gu": "E4_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira completes her PhD in 6 years and becomes the first person in her family to earn the title of ‘Doctor’. Her research paper receives a lot of recognition.",
              "hi": "मीरा 6 वर्ष में पीएचडी पूरा करती है और अपने परिवार में 'डॉक्टर' का खिताब अर्जित करने वाली पहली व्यक्ति बन जाती है। उसके शोध पत्र को बहुत सारी मान्यता प्राप्त होती है। ",
              "ka": "ಮೀರಾ ತನ್ನ ಪಿಎಚ್‌ಡಿಯನ್ನು 6 ವರ್ಷಗಳಲ್ಲಿ ಪೂರ್ಣಗೊಳಿಸುತ್ತಾಳೆ ಮತ್ತು ತನ್ನ ಕುಟುಂಬದಲ್ಲಿ 'ಡಾಕ್ಟರ್' ಎಂಬ ಬಿರುದನ್ನು ಗಳಿಸಿದ ಮೊದಲ ವ್ಯಕ್ತಿಯಾಗಿದ್ದಾಳೆ. ಅವಳ ಸಂಶೋಧನಾ ಪ್ರಬಂಧವು ಸಾಕಷ್ಟು ಮನ್ನಣೆಯನ್ನು ಪಡೆಯುತ್ತದೆ.",
              "te": "మీరా తన PhDని 6 సంవత్సరాలలో పూర్తి చేసి, తన కుటుంబంలో 'డాక్టర్' బిరుదును సంపాదించిన మొదటి వ్యక్తిగా ఉంటుంది. ఆమె రీసర్చ్ పేపర్ చాలా గుర్తింపు పొందింది.",
              "or": "ମୀରା ୬ବର୍ଷରେ ତାଙ୍କର ପିଏଚଡି ସମାପ୍ତ କରନ୍ତି ଏବଂ ତାଙ୍କ ପରିବାରର ପ୍ରଥମ ବ୍ୟକ୍ତି\n  ହୋଇ ‘ଡାକ୍ତର’ ଆଖ୍ୟା ଅର୍ଜନ କରିଥିଲେ | ତାଙ୍କର ଅନୁସନ୍ଧାନ କାଗଜ ବହୁ ମାନ୍ୟତା ପାଇଥାଏ |",
              "as": "মীৰাই ৬ বছৰত পিএইচডি সম্পূৰ্ণ কৰে আৰু 'ডক্টৰ' উপাধি অৰ্জন কৰা পৰিয়ালৰ প্ৰথম ব্যক্তি হয়। তাইৰ গৱেষণা পত্ৰখনে যথেষ্ট স্বীকৃতি লাভ কৰে।",
              "gu": "મીરાએ 6 વર્ષમાં તેની પીએચડી પૂર્ણ કરી અને 'ડોક્ટર'નું બિરુદ મેળવનારી તેના પરિવારની પ્રથમ વ્યક્તિ બની. તેના સંશોધન પેપરને ઘણી માન્યતા મળે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_3_4",
              "hi": "E4_3_4",
              "ka": "E4_3_4",
              "te": "E4_3_4",
              "or": "E4_3_4",
              "as": "E4_3_4",
              "gu": "E4_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She continues her research on using nanotechnology in textiles and works as a consultant in many companies.",
              "hi": "वह वस्त्रों में नैनो टेक्नोलॉजी का उपयोग करने पर अपना शोध जारी रखती है और कई कंपनियों में सलाहकार के रूप में काम करती है।",
              "ka": "ಅವಳು ಜವಳಿಗಳಲ್ಲಿ ನ್ಯಾನೊತಂತ್ರಜ್ಞಾನವನ್ನು ಬಳಸುವ ಬಗ್ಗೆ ತನ್ನ ಸಂಶೋಧನೆಯನ್ನು ಮುಂದುವರೆಸುತ್ತಾಳೆ ಮತ್ತು ಅನೇಕ ಕಂಪನಿಗಳಲ್ಲಿ ಸಲಹೆಗಾರರಾಗಿ ಕೆಲಸ ಮಾಡುತ್ತಾಳೆ.",
              "te": "ఆమె టెక్స్ టైల్స్ లో నానోటెక్నాలజీని ఉపయోగించడంపై తన పరిశోధనను కొనసాగిస్తుంది మరియు అనేక కంపెనీలలో కన్సల్టెంట్‌గా పనిచేస్తుంది.",
              "or": "ସେ ଟେକ୍ସଟାଇଲରେ ନାନୋଟେକ୍ନୋଲୋଜି ବ୍ୟବହାର ଉପରେ ଗବେଷଣା ଜାରି\n ରଖିଛନ୍ତି ଏବଂ ଅନେକ କମ୍ପାନୀରେ ପରାମର୍ଶଦାତା ଭାବରେ କାର୍ଯ୍ୟ କରନ୍ତି |",
              "as": "তাই বস্ত্ৰত নেনোপ্ৰযুক্তি ব্যৱহাৰৰ ওপৰত গৱেষণা কৰি আছে আৰু বহুতো কোম্পানীত এদভাইচাৰ হিচাপে কাম কৰে।",
              "gu": "તેને કાપડમાં નેનો ટેક્નોલોજીનો ઉપયોગ કરવા પર તેનું સંશોધન ચાલુ રાખ્યું છે અને તે ઘણી કંપનીઓમાં સલાહકાર તરીકે કામ કરે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "PhD Scholar",
              "hi": "पीएचडी विद्वान",
              "ka": "ಪಿ‌ಎಚ್‌ಡಿ ಸ್ಕಾಲರ್",
              "te": "పీహెచ్‌డీ స్కాలర్",
              "or": "ପିଏଚଡି ସ୍କୋଲାର୍ |",
              "as": "পিএইচডি স্কলাৰ",
              "gu": "પીએચડી વિદ્વાન"
            }
          }
        ]
      },
      "scene44": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Do the certification program",
              "hi": "सर्टिफिकेशन कार्यक्रम करे",
              "ka": "ಸರ್ಟಿಫಿಕೇಶನ್ ಪ್ರೋಗ್ರಾಮ್ ಮಾಡುವುದೇ",
              "te": "సర్టిఫికేషన్ ప్రోగ్రామ్ చేయండి",
              "or": "ସାର୍ଟିଫିକେସନ୍ ପ୍ରୋଗ୍ରାମ୍ କରନ୍ତୁ |",
              "as": "প্ৰমাণপত্ৰ প্ৰগ্ৰাম কৰক",
              "gu": "પ્રમાણપત્ર કાર્યક્રમ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Mira takes the offer by the company and goes to France. There is a lot of preparation to be done right from packing to getting her visa.",
              "hi": "मीरा कंपनी का प्रस्ताव स्वीकार करती है और फ्रांस जाती है। उसे अपना वीज़ा बनवाने से लेकर पैकिंग करने तक बहुत सारी तैयारी करनी हैं।",
              "ka": "ಮೀರಾ ಕಂಪನಿಯ ಪ್ರಸ್ತಾಪವನ್ನು ತೆಗೆದುಕೊಂಡು ಫ್ರಾನ್ಸ್‌ಗೆ ಹೋಗುತ್ತಾಳೆ. ಪ್ಯಾಕಿಂಗ್‌ನಿಂದ ಹಿಡಿದು ವೀಸಾ ಪಡೆಯುವವರೆಗೆ ಸಾಕಷ್ಟು ಸಿದ್ಧತೆಗಳನ್ನು ಮಾಡಿಕೊಳ್ಳಬೇಕು",
              "te": "మీరా కంపెనీ ఆఫర్‌ని తీసుకుని ఫ్రాన్స్ కి వెళుతుంది. ప్యాకింగ్ నుండి ఆమె వీసా పొందడం వరకు చాలా ప్రిపరేషన్ చేయాల్సి ఉంది.",
              "or": "ମୀରା କମ୍ପାନୀ ଦ୍ୱାରା ଏହି ଅଫର ନେଇ ଫ୍ରାନ୍ସ ଯାଇଥାଏ | ପ୍ୟାକ୍ କରିବା ଠାରୁ ଆରମ୍ଭ କରି ତାଙ୍କ ଭିସା ପାଇବା ପର୍ଯ୍ୟନ୍ତ ଅନେକ ପ୍ରସ୍ତୁତି ଅଛି |",
              "as": "মীৰাই কোম্পানীৰ দ্বাৰা প্ৰস্তাৱটো লয় আৰু ফ্ৰান্সলৈ যায়। পেকিংৰ পৰা ভিছা পোৱালৈকে বহুতো প্ৰস্তুতি কৰিব লাগিব।",
              "gu": "મીરા કંપની દ્વારા ઓફર લે છે અને ફ્રાન્સ જાય છે. પેકિંગથી લઈને તેના વિઝા મેળવવા સુધી ઘણી બધી તૈયારી કરવાની છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_1_1b",
              "hi": "E1_1_1b",
              "ka": "E1_1_1b",
              "te": "E1_1_1b",
              "or": "E1_1_1b",
              "as": "E1_1_1b",
              "gu": "E1_1_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She signs the contract to work with the company for five years after the course is over.",
              "hi": "वह कोर्स समाप्त होने के बाद पांच साल कंपनी के साथ काम करने के लिए अनुबंध पर हस्ताक्षर करती है।",
              "ka": "ಮೀರಾ ಕಂಪನಿಯ ಪ್ರಸ್ತಾಪವನ್ನು ತೆಗೆದುಕೊಂಡು ಫ್ರಾನ್ಸ್‌ಗೆ ಹೋಗುತ್ತಾಳೆ. ಪ್ಯಾಕಿಂಗ್‌ನಿಂದ ಹಿಡಿದು ವೀಸಾ ಪಡೆಯುವವರೆಗೆ ಸಾಕಷ್ಟು ಸಿದ್ಧತೆಗಳನ್ನು ಮಾಡಿಕೊಳ್ಳಬೇಕು",
              "te": "కోర్సు ముగిసిన తర్వాత ఆమె ఐదేళ్ల పాటు కంపెనీతో కలిసి పనిచేయడానికి ఒప్పందంపై సంతకం చేస్తుంది.",
              "or": "ପାଠ୍ୟକ୍ରମ ଶେଷ ହେବା ପରେ କମ୍ପାନୀ ସହିତ ପାଞ୍ଚ ବର୍ଷ କାମ କରିବା ପାଇଁ ସେ \n ଚୁକ୍ତି ସ୍ୱାକ୍ଷର କରନ୍ତି |",
              "as": "পাঠ্যক্ৰম টো শেষ হোৱাৰ পিছত তাই কোম্পানীটোৰ সৈতে পাঁচ বছৰৰ বাবে কাম কৰাৰ চুক্তি কৰে।",
              "gu": "તેનો કોર્સ પૂરો થયા પછી પાંચ વર્ષ માટે કંપની સાથે કામ કરવાના કરાર પર હસ્તાક્ષર કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "E5_1_2",
              "hi": "E5_1_2",
              "ka": "E5_1_2",
              "te": "E5_1_2",
              "or": "E5_1_2",
              "as": "E5_1_2",
              "gu": "E5_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She has been asked to attend classes to learn basic French. There is also a 15-day introduction training before travelling to France. The process is exhausting.",
              "hi": "उसे मूल फ्रेंच सीखने के लिए कक्षा में भाग लेने के लिए कहा गया है। फ्रांस यात्रा करने से पहले 15-दिवसीय परिचय प्रशिक्षण भी है। प्रक्रिया थकान भरी है।",
              "ka": "ಮೂಲಭೂತ ಫ್ರೆಂಚ್ ಕಲಿಯಲು ತರಗತಿಗಳಿಗೆ ಹಾಜರಾಗಲು ಅವಳನ್ನು ಕೇಳಲಾಗುತ್ತದೆ. ಫ್ರಾನ್ಸ್‌ಗೆ ಪ್ರಯಾಣಿಸುವ ಮೊದಲು 15 ದಿನಗಳ ಪರಿಚಯ ತರಬೇತಿಯೂ ಇದೆ. ಈ ಪ್ರಕ್ರಿಯೆಯು ದಣಿವನ್ನು ಉಂಟು ಮಾಡುತ್ತದೆ",
              "te": "ఫ్రెంచ్ భాష బేసిక్స్ నేర్చుకోవడానికి ఆమె తరగతులకు హాజరుకావాలని కోరారు. ఫ్రాన్స్ కు వెళ్లడానికి ముందు 15-రోజుల పరిచయ శిక్షణ కూడా ఉంది. ప్రొసెస్ చాలా ఉంటుంది.",
              "or": "ମୌଳିକ ଫରାସୀ ଶିଖିବା ପାଇଁ ତାଙ୍କୁ କ୍ଲାସରେ ଯୋଗଦେବାକୁ କୁହାଯାଇଛି | ଫ୍ରାନ୍ସ ଯିବା\n  ପୂର୍ବରୁ ଏକ 15 ଦିନିଆ ପରିଚୟ ତାଲିମ ମଧ୍ୟ ଅଛି | ପ୍ରକ୍ରିୟା କ୍ଲାନ୍ତ ଅଟେ |",
              "as": "তাইক ফৰাচী শিকিবলৈ শ্ৰেণীত উপস্থিত থাকিবলৈ কোৱা হৈছে। ফ্ৰান্স ভ্ৰমণ কৰাৰ আগতে 15 দিনৰ পৰিচয় প্ৰশিক্ষণো আছে। প্ৰক্ৰিয়াটো ভাগৰি পৰা।",
              "gu": "તેને મૂળભૂત ફ્રેન્ચ શીખવા માટે વર્ગોમાં હાજરી આપવા માટે કહેવામાં આવ્યું છે. ફ્રાન્સની મુસાફરી કરતા પહેલા 15-દિવસની પરિચય તાલીમ પણ છે. પ્રક્રિયા કંટાળાજનક છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "E5_2_1///",
              "hi": "E5_2_1///",
              "ka": "E5_2_1///",
              "te": "E5_2_1///",
              "or": "E5_2_1///",
              "as": "E5_2_1///",
              "gu": "E5_2_1///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Mira speaks to Preeti and Lina very often. They support her a lot through the process.",
              "hi": "मीरा, प्रीति और लीना से अक्सर बात करती है। वे उसे प्रक्रिया के दौरान बहुत सहयोग करती हैं।",
              "ka": "ಮೀರಾಳು  ಪ್ರೀತಿ ಮತ್ತು ಲೀನಾಳೊಂದಿಗೆ ಆಗಾಗ್ಗೆ ಮಾತನಾಡುತ್ತಾಳೆ. ಅವರು ಆಕೆಯು ಕೈಗೊಳ್ಳಬೇಕಾದ ಪ್ರಕ್ರಿಯೆಯ ಸಮಯದಲ್ಲಿ ಅವಳನ್ನು ಸಾಕಷ್ಟು ಬೆಂಬಲಿಸುತ್ತಾರೆ.",
              "te": "మీరా ప్రీతి మరియు లీనాతో చాలా తరచుగా మాట్లాడుతుంది. వారు ప్రక్రియ ద్వారా ఆమెకు బాగా సపోర్ట్ చేస్తారు.",
              "or": "ମୀରା ପ୍ରୀତି ଏବଂ ଲୀନାଙ୍କ ସହ ବାରମ୍ବାର କଥା ହୁଅନ୍ତି | ପ୍ରକ୍ରିୟା ମାଧ୍ୟମରେ ସେମାନେ\n  ତାଙ୍କୁ ବହୁତ ସମର୍ଥନ କରନ୍ତି |",
              "as": "মীৰাই প্ৰীতি আৰু লীনাৰ সৈতে প্ৰায়ে কথা পাতে। সিহঁতে তাইক বহুত উৎসাহ দিয়ে।",
              "gu": "મીરા ઘણી વાર પ્રીતિ અને લીના સાથે વાત કરે છે. તેઓ પ્રક્રિયા દ્વારા તેને ખૂબ ટેકો આપે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I am already tired and this is supposed to be the easiest part. Ma and Pa are worried about sending me. They are constantly questioning this decision.",
              "hi": "मैं पहले से ही थक गई हूं और यह सबसे सरल हिस्सा माना जाता है। माता और पिता मुझे भेजने के बारे में चिंतित हैं। वे लगातार इस निर्णय पर प्रश्न कर रहे हैं।",
              "ka": "ನಾನು ಈಗಾಗಲೇ ದಣಿದಿದ್ದೇನೆ ಮತ್ತು ಇದು ಸುಲಭವಾದ ಭಾಗವೆಂದು ಪರಿಗಣಿಸಲಾಗುತ್ತದೆ. ಅಮ್ಮ ಮತ್ತು ತಂದೆ ನನ್ನನ್ನು ಕಳುಹಿಸಲು ಚಿಂತಿಸುತ್ತಿದ್ದಾರೆ. ಈ ನಿರ್ಧಾರವನ್ನು ಅವರು ನಿರಂತರವಾಗಿ ಪ್ರಶ್ನಿಸುತ್ತಿದ್ದಾರೆ.",
              "te": "నేను ఇప్పటికే అలసిపోయాను మరియు ఇది చాలా సులభమైన భాగం. నన్ను పంపడానికి అమ్మా నాన్నలు కంగారు పడుతున్నారు. ఈ నిర్ణయాన్ని వారు ఎప్పుడూ ప్రశ్నిస్తునే ఉన్నారు.",
              "or": "ମୁଁ ପୂର୍ବରୁ ଥକି ଯାଇଛି ଏବଂ ଏହା ସବୁଠାରୁ ସହଜ ଅଂଶ ବୋଲି ଅନୁମାନ କରାଯାଏ |\n  ମା ଏବଂ ପା ମୋତେ ପଠାଇବାକୁ ଚିନ୍ତିତ | ସେମାନେ ଏହି ନିଷ୍ପତ୍ତି ଉପରେ କ୍ରମାଗତ ଭାବରେ ପ୍ରଶ୍ନ କରୁଛନ୍ତି |",
              "as": "মই ভাগৰি পৰিছো আৰু এইটো আটাইতকৈ সহজ বুলি ভৱা হয়। মা আৰু পাপা মোক পঠোৱাৰ বিষয়ে চিন্তিত। তেওঁলোকে এইটো ওপৰত বহুত কথা সুধি আছে।",
              "gu": "હું પહેલેથી જ થાકી ગઈ છું અને આ સૌથી સહેલો ભાગ માનવામાં આવે છે. મમ્મી અને પપ્પા મને મોકલવાની ચિંતા કરે છે. તેઓ આ નિર્ણય પર સતત સવાલ ઉઠાવી રહ્યા છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Calm down, Mira. They are only worried for you. Once they realize that you are safe and happy with the course, they will be fine.",
              "hi": "शांत हो जाओ, मीरा। वे आपके लिए केवल चिंतित हैं। एक बार जब उन्हें पता चलेगा कि तुम कोर्स से सुरक्षित और खुश हो, तो उन्हें ठीक लगेगा।",
              "ka": "ಶಾಂತವಾಗಿರು, ಮೀರಾ. ಅವರು ನಿನಗಾಗಿ ಮಾತ್ರವೇ ಚಿಂತಿಸುತ್ತಾರೆ. ನೀನು ಕೋರ್ಸ್‌ನಲ್ಲಿ ಸುರಕ್ಷಿತ ಮತ್ತು ಸಂತೋಷವಾಗಿರುವಿ ಎಂದು ಅವರು ಅರಿತುಕೊಂಡ ನಂತರ, ಅವರು ಚೆನ್ನಾಗಿರುತ್ತಾರೆ.",
              "te": "ప్రశాంతంగా ఉండు మీరా. వారు నీ గురించి మాత్రమే ఆందోళన చెందుతున్నారు. నువ్వు కోర్సులో సురక్షితంగా మరియు సంతోషంగా ఉన్నావని తెలిస్తే వారు బాగానే ఉంటారు.",
              "or": "ଶାନ୍ତ ହୁଅ, ମୀରା | ସେମାନେ କେବଳ ଆପଣଙ୍କ ପାଇଁ ଚିନ୍ତିତ | ଥରେ ସେମାନେ \n ଅନୁଭବ କଲେ ଯେ ତୁମେ ନିରାପଦ ଏବଂ ପାଠ୍ୟକ୍ରମରେ ଖୁସି, ସେମାନେ ଭଲ ହୋଇଯିବେ |",
              "as": "শান্ত হোৱা, মীৰা। তেওঁলোকে তোমাক লৈ চিন্তা কৰে। এবাৰ তেওঁলোকে যেতিয়া গম পাব যে তুমি সুৰক্ষীত আৰু সুখী, তেওঁলোক নিজে ঠিক হ'ব।",
              "gu": "શાંત થા, મીરા. તેઓ ફક્ત તારા માટે જ ચિંતિત છે. એકવાર તેઓ સમજી જાય કે તું કોર્સથી સુરક્ષિત અને ખુશ છો, તેઓ ઠીક થઈ જશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Yeah, you focus on getting things ready. Also, I found this app that will help you learn any language fast. You can write what you want to speak in English, and it will translate it for you.",
              "hi": "हाँ, तुम अपनी तैयारी पर ध्यान केंद्रित करो। इसके अलावा, मुझे यह ऐप मिला जो तुमको किसी भी भाषा को तेजी से सीखने में मदद करेगा। तुम क्या बोलना चाहती हो अंग्रेजी में लिख सकती हो, और यह ऐप आपके लिए उसका अनुवाद करेगा।",
              "ka": "ಹೌದು, ನೀನು ವಿಷಯಗಳನ್ನು ಸಿದ್ಧಪಡಿಸುವುದರ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸು. ಅಲ್ಲದೆ, ಯಾವುದೇ ಭಾಷೆಯನ್ನು ವೇಗವಾಗಿ ಕಲಿಯಲು ನಿನಗೆ ಸಹಾಯ ಮಾಡುವ ಈ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ನಾನು ಕಂಡುಕೊಂಡಿದ್ದೇನೆ. ನೀನು ಇಂಗ್ಲಿಷ್‌ನಲ್ಲಿ ಏನು ಮಾತನಾಡಲು ಬಯಸುತ್ತೀ ಎನ್ನುವುದನ್ನು ನೀನು ಬರೆಯಬಹುದು ಮತ್ತು ಅದು ನಿನಗಾಗಿ ಅದನ್ನು ಅನುವಾದಿಸುತ್ತದೆ.",
              "te": "అవును, నువ్వు ఫ్రాన్స్ వెళ్ళడానికి అవసరమైన విషయాలపై దృష్టి పెట్టు. అలాగే, నువ్వు ఏ భాషనైనా వేగంగా నేర్చుకోవడంలో సహాయపడే ఈ యాప్‌ని నేను తెలుసుకున్నాను. నువ్వు ఆంగ్లంలో ఏమి మాట్లాడాలనుకుంటున్నావో వ్రాయవచ్చు మరియు అది అనువదిస్తుంది.",
              "or": "ହଁ, ଆପଣ ନିଜକୁ ପ୍ରସ୍ତୁତ କରିବାକୁ ଧ୍ୟାନ ଦିଅନ୍ତି | ଆହୁରି ମଧ୍ୟ, ମୁଁ ଏହି ଆପ୍ ପାଇଲି\n  ଯାହା ଆପଣଙ୍କୁ ଯେକୌଣସି ଭାଷା ଶୀଘ୍ର ଶିଖିବାରେ ସାହାଯ୍ୟ କରିବ | ଆପଣ ଯାହା ଇଂରାଜୀରେ କହିବାକୁ ଚାହାଁନ୍ତି ତାହା ଲେଖିପାରିବେ, ଏବଂ ଏହା ଆପଣଙ୍କ ପାଇଁ ଅନୁବାଦ କରିବ |",
              "as": "হয়, তুমি বস্তুবোৰ সাজু কৰা। লগতে, মই এই এপ্পটো বিচাৰি পাইছো যি তোমাক যিকোনো ভাষা সোনকালে শিকাত সহায় কৰিব। আপুনি ইংৰাজীত কি ক'ব বিচাৰা লিখিব পাৰা, আৰু ই ইয়াক আপোনাৰ বাবে অনুবাদ কৰিব।",
              "gu": "હા, તું વસ્તુઓ તૈયાર કરવા પર ધ્યાન કેન્દ્રિત કરે છે. ઉપરાંત, મને આ એપ્લિકેશન મળી છે જે તને કોઈપણ ભાષા ઝડપથી શીખવામાં મદદ કરશે. તું અંગ્રેજીમાં જે બોલવા માંગો છે તે તું લખી શકે છે અને તે તારા માટે તે અનુવાદ કરશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Oh, that will be helpful! I don’t know what I would do without you two. I am going to miss you both a lot.",
              "hi": "ओह, यह सहायक होगा! मुझे नहीं पता कि मैं तुम्हारे बिना क्या करूंगी। मैं तुमको बहुत याद करूंगी।",
              "ka": "ಓಹ್, ಅದು ಸಹಾಯಕವಾಗುತ್ತದೆ! ನಿಮ್ಮಿಬ್ಬರಿಲ್ಲದೆ ನಾನು ಏನು ಮಾಡುತ್ತೇನೆಂದು ನನಗೆ ತಿಳಿದಿಲ್ಲ. ನಾನು ನಿಮ್ಮಿಬ್ಬರನ್ನು ತುಂಬಾ ಮಿಸ್ ಮಾಡಿಕೊಳ್ಳುತ್ತೇನೆ.",
              "te": "ఓహ్, అది సహాయకరంగా ఉంటుంది! మీరిద్దరూ లేకుండా నేను ఏమి చేస్తానో నాకు తెలియదు. నేను మీ ఇద్దరినీ చాలా మిస్ అవుతున్నాను.",
              "or": "ଓ ,, ତାହା ସାହାଯ୍ୟକାରୀ ହେବ! ମୁଁ ଜାଣେ ନାହିଁ ତୁମ ଦୁଇଜଣ ବିନା ମୁଁ କଣ କରିବି\n  | ମୁଁ ତୁମ ଦୁହିଁଙ୍କୁ ବହୁତ ମନେ ପକାଇବାକୁ ଯାଉଛି |",
              "as": "ওহ, সেয়া ভাল হ'ব! মই নাজানো তোমাৰ দুয়োৰে অবিহনে মই কৰিম। তোমালোকক বহুত মনত পেলাম।",
              "gu": "ઓહ, તે મદદરૂપ થશે! મને ખબર નથી કે હું તમારા બંને વિના શું કહું. મને તને બંનેને ખૂબ જ યાદ આવશો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Mira, we are just a call away!",
              "hi": "मीरा, हम सिर्फ एक कॉल दूर हैं!",
              "ka": "ಮೀರಾ, ನಾವು ಕೇವಲ ಒಂದು ಕಾಲ್ ಅಂತರದಲ್ಲಿ ಇದ್ದೇವೆ!",
              "te": "మీరా, మేము కేవలం కాల్ దూరంలో ఉన్నాము!",
              "or": "ମୀରା, ଆମେ କେବଳ ଏକ କଲ ର ଦୂରତାରେ!",
              "as": "মীৰা, আমি মাত্ৰ এটা কল দূৰত আছোঁ!",
              "gu": "મીરા, આપણે માત્ર એક કૉલ દૂર છીએ!"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "E5_3_1",
              "hi": "E5_3_1",
              "ka": "E5_3_1",
              "te": "E5_3_1",
              "or": "E5_3_1",
              "as": "E5_3_1",
              "gu": "E5_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The course in France is very demanding, and Mira is overwhelmed at the amount of reading and independent work she has to do.",
              "hi": "फ्रांस के कोर्स में बहुत मेहनत है, और मीरा पढ़ने और स्वतंत्र काम की मात्रा से बहुत व्याकुल है।",
              "ka": "ಫ್ರಾನ್ಸ್‌ನಲ್ಲಿನ ಕೋರ್ಸ್ ತುಂಬಾ ಕಷ್ಟವಾಗಿದೆ ಮತ್ತು ಮೀರಾ ತನ್ನ  ಓದುವಿಕೆ ಮತ್ತು ಸ್ವತಂತ್ರ ಕೆಲಸದಲ್ಲಿ ಮುಳುಗಿದ್ದಾಳೆ.",
              "te": "ఫ్రాన్స్ లోని కోర్సు చాలా డిమాండ్‌తో ఉన్నది, మరియు మీరా ఆమె చేయవలసిన అభ్యాసం మరియు స్వంత పనిలో మునిగిపోయింది.",
              "or": "ଫ୍ରାନ୍ସରେ ପାଠ୍ୟକ୍ରମର ଅତ୍ୟନ୍ତ ଚାହିଦା, ଏବଂ ମୀରା ତାଙ୍କୁ ପଢିବା ଏବଂ ଶ୍ଵା\n ଧୀନ କାର୍ଯ୍ୟ ପରିମାଣରେ ଅତିଷ୍ଠ ହୋଇପଡିଛନ୍ତି |",
              "as": "ফ্ৰান্সৰ পাঠ্যক্ৰমটোত ডিমাণ্ড আছে, আৰু মীৰাই সিহঁতে কৰিব লগা পঢ়া আৰু কামৰ পৰিমাণ দেখি চিন্তাত পৰিছে।",
              "gu": "ફ્રાન્સમાં અભ્યાસક્રમ ખૂબ જ માંગ છે, અને મીરા તેના વાંચન અને સ્વતંત્ર કાર્યથી અભિભૂત છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "E5_3_2",
              "hi": "E5_3_2",
              "ka": "E5_3_2",
              "te": "E5_3_2",
              "or": "E5_3_2",
              "as": "E5_3_2",
              "gu": "E5_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She finds it very difficult to get adjusted to the culture. She falls sick very often. Her batchmates help her cope with the work.",
              "hi": "उसे वहां की संस्कृति के साथ तालमेल बैठाने में बहुत कठिनाई हो रही है। वह अक्सर बीमार पड़ जाती है। उसके सहपाठी उसे काम निपटाने में मदद करते हैं।",
              "ka": "ಅಲ್ಲಿನ ಸಂಸ್ಕೃತಿಗೆ ಹೊಂದಿಕೊಳ್ಳಲು ಅವಳು ತುಂಬಾ ಕಷ್ಟಪಡುತ್ತಾಳೆ. ಅವಳು ಆಗಾಗ್ಗೆ ಅನಾರೋಗ್ಯಕ್ಕೆ ಒಳಗಾಗುತ್ತಾಳೆ. ಆಕೆಯ ಬ್ಯಾಚ್‌ಮೇಟ್‌ಗಳು ಆಕೆಗೆ ಕೆಲಸವನ್ನು ನಿಭಾಯಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತಾರೆ",
              "te": "ఆమె సంస్కృతికి అడ్జస్ట్ అవ్వడం చాలా కష్టంగా ఉంది. ఆమె చాలా తరచుగా అనారోగ్యానికి గురవుతుంది. ఆమె బ్యాచ్‌మేట్స్ ఆమె పనిలో సహాయం చేస్తారు.",
              "or": "ସଂସ୍କୃତି ସହିତ ଆଡଜଷ୍ଟ ହେବା ତାଙ୍କୁ ବହୁତ କଷ୍ଟକର | ସେ ବହୁତ ଥର ଅସୁସ୍ଥ ହୁଅନ୍ତି\n  | ତାଙ୍କର ବ୍ୟାଚ୍ ସାଥୀମାନେ ତାଙ୍କୁ ଏହି କାର୍ଯ୍ୟରେ ମୁକାବିଲା କରିବାରେ ସାହାଯ୍ୟ କରନ୍ତି |",
              "as": "তাই সংস্কৃতিৰ সৈতে খাপ খোৱাটো বহুত টান পৰিছে। তাই প্ৰায়ে গা বেয়া হৈ থাকে। তাইৰ সহকৰ্মীসকলে তাইক কামটোৰ সৈতে মোকাবিলা কৰাত সহায় কৰে।",
              "gu": "તેને સંસ્કૃતિમાં સમાયોજિત થવું ખૂબ મુશ્કેલ લાગે છે. તે ઘણી વાર બીમાર પડે છે. તેના બેચના સાથીઓ તેને કામનો સામનો કરવામાં મદદ કરે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "E5_3_3",
              "hi": "E5_3_3",
              "ka": "E5_3_3",
              "te": "E5_3_3",
              "or": "E5_3_3",
              "as": "E5_3_3",
              "gu": "E5_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Nevertheless, she learns a lot through the course and gains confidence in writing and presenting papers.",
              "hi": "फिर भी, वह कोर्स से बहुत कुछ सीखती है और शोधपत्र लिखने और प्रस्तुत करने में आत्मबल प्राप्त करती है।",
              "ka": "ಅದೇನೇ ಇದ್ದರೂ, ಅವಳು ಕೋರ್ಸ್ ಮೂಲಕ ಬಹಳಷ್ಟು ಕಲಿಯುತ್ತಾಳೆ ಹಾಗೂ ಪೇಪರ್‌ಗಳನ್ನು ಬರೆಯಲು ಮತ್ತು ಪ್ರಸ್ತುತಪಡಿಸುವಲ್ಲಿ ವಿಶ್ವಾಸವನ್ನು ಗಳಿಸುತ್ತಾಳೆ.",
              "te": "అయినప్పటికీ, ఆమె కోర్సు ద్వారా చాలా నేర్చుకుంటుంది మరియు పత్రాలను వ్రాయడంలో మరియు సమర్పించడంలో విశ్వాసాన్ని పొందుతుంది.",
              "or": "ସଂସ୍କୃତି ସହିତ ଖାପ ଖୁଆଇବା ତାଙ୍କ ପାଇଁ ବହୁତ କଷ୍ଟକର | ସେ ବହୁତ ଥର ଅସୁସ୍ଥ \n ହୁଅନ୍ତି | ତାଙ୍କର ବ୍ୟାଚ୍ ସାଥୀମାନେ ତାଙ୍କୁ ଏହି କାର୍ଯ୍ୟରେ ମୁକାବିଲା କରିବାରେ ସାହାଯ୍ୟ କରନ୍ତି |",
              "as": "তথাপিও, তাই পাঠ্যক্ৰমটোৰ জৰিয়তে বহুত শিকে আৰু পেপাৰ কাগজ লিখা আৰু উপস্থাপন কৰাত মনটো ডাঠ কৰিব পাৰে।",
              "gu": "તેમ છતાં, તે કોર્સ દ્વારા ઘણું શીખે છે અને પેપર લખવામાં અને પ્રસ્તુત કરવામાં આત્મવિશ્વાસ મેળવે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "E5_3_4",
              "hi": "E5_3_4",
              "ka": "E5_3_4",
              "te": "E5_3_4",
              "or": "E5_3_4",
              "as": "E5_3_4",
              "gu": "E5_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As much as she likes the course and living in a new country, she is looking forward to returning to Assam. She struggles but somehow copes with the pressure of the course and completes it.",
              "hi": "जितना अधिक वह कोर्स करना और एक नए देश में रहना पसंद करती है, उतना ही वह असम लौटने के लिए उत्सुक है। वह संघर्ष करती है लेकिन किसी भी तरह कोर्स के दबाव का सामना करती है और उसे पूरा करती है।",
              "ka": "ಅವಳು ಕೋರ್ಸ್ ಅನ್ನು ಇಷ್ಟಪಡುತ್ತಾಳೆ ಮತ್ತು ಹೊಸ ದೇಶದಲ್ಲಿ ವಾಸಿಸುತ್ತಿದ್ದಾಳೆ, ಅವಳು ಅಸ್ಸಾಂಗೆ ಮರಳಲು ಎದುರು ನೋಡುತ್ತಿದ್ದಾಳೆ. ಅವಳು ಕಷ್ಟಪಡುತ್ತಾಳೆ ಆದರೆ ಹೇಗೋ ಒಂದು ರೀತಿಯಲ್ಲಿ  ಕೋರ್ಸ್‌ನ ಒತ್ತಡವನ್ನು ನಿಭಾಯಿಸುತ್ತಾಳೆ ಹಾಗೂ ಅದನ್ನು ಪೂರ್ಣಗೊಳಿಸುತ್ತಾಳೆ.",
              "te": "ఆమె కోర్సును ఇష్టపడుతున్నందున మరియు కొత్త దేశంలో నివసిస్తున్నందున, ఆమె అస్సాంకు తిరిగి రావాలని ఎదురుచూస్తోంది. ఆమె కష్టపడుతుంది కానీ ఏదో ఒకవిధంగా కోర్సు యొక్క ఒత్తిడిని తట్టుకుని దానిని పూర్తి చేస్తుంది.",
              "or": "ସେ ଯେତିକି ପାଠ୍ୟକ୍ରମ ପସନ୍ଦ କରନ୍ତି ଏବଂ ଏକ ନୂତନ ଦେଶରେ ରହନ୍ତି, ସେ ଆସାମକୁ ଫେରିବା ପାଇଁ ଅପେକ୍ଷା କରିଛନ୍ତି | ସେ ସଂଘର୍ଷ କରନ୍ତି କିନ୍ତୁ କୌଣସି ପ୍ରକାରେ ପାଠ୍ୟକ୍ରମର ଚାପକୁ ସାମ୍ନା କରି ଏହାକୁ ସଂପୂର୍ଣ୍ଣ କରନ୍ତି |",
              "as": "তাই পাঠ্যক্ৰমটো আৰু এখন নতুন দেশত থাকি ভাল পায়, কিন্তু অসমলৈ উভতি আহিবলৈ ৰৈ আছোঁ। তাই কষ্ট কৰে আৰু যেনেতেনে পাঠ্যক্ৰমৰ মোকাবিলা কৰে আৰু ইয়াক সম্পূৰ্ণ কৰে।",
              "gu": "તેને કોર્સ અને નવા દેશમાં રહેવું તેટલુજ ગમે છે, તે આસામ પરત ફરવાની રાહ જોઈ રહી છે. તે સંઘર્ષ કરે છે પરંતુ કોઈક રીતે અભ્યાસક્રમના દબાણનો સામનો કરે છે અને તેને પૂર્ણ કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "E5_4_1",
              "hi": "E5_4_1",
              "ka": "E5_4_1",
              "te": "E5_4_1",
              "or": "E5_4_1",
              "as": "E5_4_1",
              "gu": "E5_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She gets back to India and works with the organization for five years. She gets a few more opportunities to travel to places outside the country.",
              "hi": "वह भारत वापस आती है और पांच साल तक संगठन के साथ काम करती है। उसे देश के बाहर की जगहों पर यात्रा करने के कुछ और अवसर मिलते हैं।",
              "ka": "ಅವಳು ಭಾರತಕ್ಕೆ ಹಿಂತಿರುಗುತ್ತಾಳೆ ಮತ್ತು ಐದು ವರ್ಷಗಳ ಕಾಲ ಸಂಸ್ಥೆಯೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುತ್ತಾಳೆ. ಅವಳು ದೇಶದ ಹೊರಗಿನ ಸ್ಥಳಗಳಿಗೆ ಪ್ರಯಾಣಿಸಲು ಇನ್ನೂ ಕೆಲವು ಅವಕಾಶಗಳನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
              "te": "ఆమె భారతదేశానికి తిరిగి వచ్చి ఐదేళ్లపాటు సంస్థలో పని చేస్తుంది. వేరే దేశాలలోని ప్రదేశాలకు వెళ్లేందుకు ఆమెకు మరికొన్ని అవకాశాలు లభిస్తాయి.",
              "or": "ସେ ଭାରତକୁ ଫେରି ସଂଗଠନ ସହିତ ପାଞ୍ଚ ବର୍ଷ କାର୍ଯ୍ୟ କରନ୍ତି। ସେ ଦେଶ ବାହାରେ\n ସ୍ଥାନ ଭ୍ରମଣ କରିବାକୁ ଆଉ କିଛି ସୁଯୋଗ ପାଆନ୍ତି |",
              "as": "তাই ভাৰতলৈ উভতি আহে আৰু সংগঠনটোৰ সৈতে পাঁচ বছৰ কাম কৰে। তাই দেশৰ বাহিৰৰ ঠাইবোৰলৈ ভ্ৰমণ কৰাৰ আৰু কেইটামান সুযোগ পায়।",
              "gu": "તે ભારત પરત ફરે છે અને સંસ્થા સાથે પાંચ વર્ષ સુધી કામ કરે છે. તેને દેશની બહારના સ્થળોની મુસાફરી કરવાની થોડી વધુ તકો મળે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "E5_4_2",
              "hi": "E5_4_2",
              "ka": "E5_4_2",
              "te": "E5_4_2",
              "or": "E5_4_2",
              "as": "E5_4_2",
              "gu": "E5_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She enjoys these as they are shorter durations. After three years, she continues to work in the same organisation. She gets a promotion as a Head Researcher of the Space Team.",
              "hi": "वह इनमें आनंद लेती है क्योंकि वे कम अवधि की होती हैं। तीन साल बाद, वह उसी संगठन में काम करना जारी रखती है। वह अंतरिक्ष टीम की प्रमुख शोधकर्ता के रूप में पदोन्नति प्राप्त करती है।",
              "ka": "ಇವುಗಳು ಕಡಿಮೆ ಅವಧಿಯಾಗಿರುವುದರಿಂದ ಅವಳು ಇವುಗಳನ್ನು ಆನಂದಿಸುತ್ತಾಳೆ. ಮೂರು ವರ್ಷಗಳ ನಂತರ, ಅವಳು ಅದೇ ಸಂಸ್ಥೆಯಲ್ಲಿ ಕೆಲಸ ಮುಂದುವರೆಸುತ್ತಾಳೆ. ಅವಳು ಬಾಹ್ಯಾಕಾಶ ತಂಡದ ಮುಖ್ಯ ಸಂಶೋಧಕಳಾಗಿ ಬಡ್ತಿ ಪಡೆಯುತ್ತಾರೆ.",
              "te": "తక్కువ సమయం ఉన్నందున ఆమె వీటిని ఆనందిస్తుంది. మూడు సంవత్సరాల తర్వాత, ఆమె అదే సంస్థలో పని చేస్తూనే ఉంది. ఆమె అంతరిక్ష బృందం యొక్క ప్రధాన పరిశోధకురాలిగా ప్రమోషన్ పొందింది.",
              "or": "ସେ ଏଗୁଡିକ ଉପଭୋଗ କରନ୍ତି ଯେହେତୁ ସେଗୁଡ଼ିକ ଛୋଟ ସମୟ ଅଟେ | ତିନି ବର୍ଷ\n ପରେ, ସେ ସମାନ ସଂଗଠନରେ କାର୍ଯ୍ୟ ଜାରି ରଖିଛନ୍ତି | ସେ ସ୍ପେସ୍ ଟିମର ହେଡ୍ ରିସର୍ଚ୍ଚର୍ ଭାବରେ ପଦୋନ୍ନତି ପାଆନ୍ତି |",
              "as": "তাই এইবোৰ ভাল পায় কাৰন সেইবোৰ কম সময়ৰ। তিনি বছৰৰ পিছত, তাই একেটা সংগঠনতে কাম কৰি আছে। তাই মহাকাশ দলৰ মুখ্য গৱেষক হিচাপে আগবাঢ়ি যায়।",
              "gu": "તે આનો આનંદ માણે છે કારણ કે તે ટૂંકા સમયગાળો છે. ત્રણ વર્ષ પછી, તે એ જ સંસ્થામાં કામ કરવાનું ચાલુ રાખે છે. તેને સ્પેસ ટીમના મુખ્ય સંશોધક તરીકે પ્રમોશન મળે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "E5_4_3",
              "hi": "E5_4_3",
              "ka": "E5_4_3",
              "te": "E5_4_3",
              "or": "E5_4_3",
              "as": "E5_4_3",
              "gu": "E5_4_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She likes that she has to study constantly to keep herself updated. Her dream is to be part of a spacecraft launch mission, and she is working towards achieving it.",
              "hi": "उसे स्वयं को अपडेटेड (नवीनतम जानकारी से युक्त) रखने के लिए लगातार अध्ययन करना पसंद है। उसका सपना एक अंतरिक्ष यान लॉन्च मिशन का हिस्सा बनना है, और वह इसे प्राप्त करने की दिशा में काम कर रही है।",
              "ka": "ತನ್ನನ್ನು ತಾನು ನವೀಕರಿಸಿಕೊಳ್ಳಲು ನಿರಂತರವಾಗಿ ಅಧ್ಯಯನ ಮಾಡಬೇಕೆನ್ನುವುದನ್ನು ಅವಳು ಇಷ್ಟಪಡುತ್ತಾಳೆ. ಬಾಹ್ಯಾಕಾಶ ನೌಕೆಯ ಉಡಾವಣಾ ಕಾರ್ಯಾಚರಣೆಯ ಭಾಗವಾಗುವುದು ಅವಳ ಕನಸು ಮತ್ತು ಅದನ್ನು ಸಾಧಿಸುವ ನಿಟ್ಟಿನಲ್ಲಿ ಅವಳು ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾಳೆ.",
              "te": "తనను తాను అప్‌డేట్‌ గా ఉంచుకోవడానికి నిరంతరం చదువుకోవాలని ఆమె ఇష్టపడుతుంది. స్పేస్‌క్రాఫ్ట్ లాంచ్ మిషన్‌లో భాగం కావాలనేది ఆమె కల మరియు ఆమె దానిని సాధించే దిశగా కృషి చేస్తోంది.",
              "or": "ସେ ପସନ୍ଦ କରନ୍ତି ଯେ ନିଜକୁ ଅପଡେଟ୍ ରଖିବା ପାଇଁ ତାଙ୍କୁ ନିରନ୍ତର ଅଧ୍ୟୟନ କରିବାକୁ \n ପଡିବ | ତାଙ୍କର ସ୍ୱପ୍ନ ହେଉଛି ଏକ ମହାକାଶଯାନ ଉତକ୍ଷେପଣ ମିଶନର ଅଂଶ ହେବା ଏବଂ ସେ ଏହା ହାସଲ କରିବା ଦିଗରେ କାର୍ଯ୍ୟ କରୁଛନ୍ତି |",
              "as": "তাই ভাল পায় যে তাই নিজকে আপডেট কৰি ৰাখিবলৈ সদায়ে পঢ়ি থাকিব লগা হয়। তাইৰ সপোন হৈছে মহাকাশযান অভিযানৰ অংশ হোৱা, আৰু তাই ইয়াক পাবলৈ কাম কৰি আছে।",
              "gu": "તેને ગમે છે કે બદલાવ લાવવા માટે સતત અભ્યાસ કરવો જોઈએ. તેનું સ્વપ્ન અવકાશયાન પ્રક્ષેપણ મિશનનો ભાગ બનવાનું છે અને તે તેને હાંસલ કરવા માટે કામ કરી રહી છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "Head Researcher of space team",
              "hi": "अंतरिक्ष टीम की प्रमुख शोधकर्ता",
              "ka": "ಬಾಹ್ಯಾಕಾಶ ತಂಡದ ಮುಖ್ಯ ಸಂಶೋಧಕ",
              "te": "అంతరిక్ష బృందం యొక్క ప్రధాన పరిశోధకుడు",
              "or": "ସ୍ପେସ୍ ଦଳର ମୁଖ୍ୟ ଅନୁସନ୍ଧାନକାରୀ |",
              "as": "মহাকাশ দলৰ মুখ্য গৱেষক",
              "gu": "અવકાશ ટીમના મુખ્ય સંશોધક"
            }
          }
        ]
      }
    }
  