import React from "react";
import styled from "styled-components";
import PageContainer from "../PageContainer";
import { theme } from "../../Theme";
import { X } from "phosphor-react";

const StyledPopUpCloseButton = styled.button`
  width: 3.8rem;
  height: 3.8rem;
  background-color: ${({ theme }) => theme.colors.WHITE};
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.darkGrey};
  border-radius: 0.8rem;
  ${({ theme }) => theme.transition("background-color")}
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.YELLOW};
  }
`;

export const PopUp = (props) => {
  return (
    <div
      style={{
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 12,
        margin: "0 auto",
      }}
    >
      <PageContainer style={{maxHeight: '100vh'}}>
        <div
          style={{
            overflow: "hidden",
            position: "relative",
            width: "100%",
            minHeight: "100px",
            maxHeight: 'calc(100vh - 12rem)',
            overflowY: 'scroll',
            borderRadius: "0.5rem",
            backgroundColor: theme.colors.BG_YELLOW,
          }}
        >
          {props.closePopUp && (
            <StyledPopUpCloseButton onClick={props.closePopUp}>
              <div style={{ margin: "0 0 -0.7rem -0.6rem" }}>
                <X size={16} />
              </div>
            </StyledPopUpCloseButton>
          )}
          <div style={{ padding: "4rem 2rem 4rem 2rem" }}>
            {props.children || "helo"}
          </div>
        </div>
      </PageContainer>
    </div>
  );
};
