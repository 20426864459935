export const colors = {

  //final

  BG_YELLOW: "#f6f7f0",
  YELLOW : "#F4DEB0",
  YELLOW2 : "#FFD068",
  ORANGE : "#E7935D",
  WHITE : '#fff',
  BLUE: "#88A9EE",
  RED: "#D3674B",
  CYAN: "#4DBCFF",
  SKY_BLUE: "#E0F4FF",
  SKY_BLUE2: "#ABE1FF",
  GREEN: "#B9E545",
  BROWN: "#DFDBBF",
  BROWN2: "#B8B59F",
  NONE: "rgba(0, 0, 0, 0)",
  DANGER: '#EC946F',
  SUCCESS: '#BDD86B',
  LIGHT_GREY: '#E4E1DA',
  MID_GREY: '#595959',
  BLACK: '#000',
  BG_YELLOW_0: "#f6f7f000",
  BG_YELLOW_80: "#f6f7f0cc",

  //--- end final

  lightYellow: "#f6f7f0",
  yellow: "#eff7c3",
  scrollCaretColor: "#383838",
  namedConvText: "#7bbce0",
  white: "#fff",
  black: "#000",
  darkGrey: "#383838",
  lightGrey: "#c9c9c9",
  extraLightGrey: "rgb(218, 218, 218)",
  blue: "#7bbce0",
  green: "#a2e07b",
  lightGreen: "#c0eca4",

  gridGap: "10px",
  pageMarginHor: "20px",
  pageMarginVer: "60px",
  none: "rgba(0, 0, 0, 0)",
  choiceBackground: "#e8ecf3",
  choiceBtnStroke: "#526b9e",
  choiceBtnSelected: "#9fbeff",
  emailFormatBackground: "#e5e5e5",
  black: "#000",
  disabled: "#D7D7D7",
  active: "#08bd80",
  success: "#08bd80",
  danger: "#f6101a",
  textColor_disabled: "#898989",
  clickFillDivider: "#D1D1D1",
  applyLogoBg: "#E7E6E6",
  appliedBtnColor: "#DDDDDD",
  notebookLine: "#E2E2E2",
  messageTop: "#085E55",
  messagePersonName: "#F3F3F3",
  messagePersonStatus: "#C4C4C4",
  messageDiplay: "#C0FF82",
  messageDetail: "#B7B4B4",
};

export const layout = {
  pageMarginBottom: 60,
  pageMarginTop: 60,
  pageMarginLeft: 20,
  pageMarginRight: 20
}

export const breakpoints = {
  xs: 360,
  sm: 480,
  md: 760,
  lg: 990,
  // xl : ??
};

export const typo = {

  h1: `
  font-size: 5.5rem;
  line-height: 0.8em;
  font-weight: normal;

`,
  
  /* LOCKED */
  h2: `
    font-size: 3rem;
    line-height: 1.4em;
    font-weight: normal;

    @media screen and (min-width: 760px) {
      font-size: 3.4rem;
    }
  `,

  h3: `
    font-size: 2.4rem;
    line-height: 1.4em;
    font-weight: normal;

    @media screen and (min-width: 760px) {
      font-size: 3.0rem;
    }
  `,

  /* LOCKED */
  h4: `
    font-size: 1.6rem;
    line-height: 1.4em;
    font-weight: normal;

    @media screen and (min-width: 760px) {
      font-size: 2.4rem;
    }
  `,

  /* LOCKED */
  p: `
    font-family: 'Baloo 2', sans-serif;
    font-size: 14px;
    line-height: 1.5em;
    font-weight: normal;

    @media screen and (min-width: ${breakpoints.md}px) {
      font-size: 18px;
    }
  `,
  
  /* LOCKED */
  h5: `
    font-family: 'Baloo 2', sans-serif;
    font-size: 1.2rem;
    line-height: 1.3em;
    font-weight: normal;

    @media screen and (min-width: 760px) {
      font-size: 1.4rem;
    }`,
  
  h6: `
    font-family: 'Baloo 2', sans-serif;
    font-size: 1rem;
    line-height: 1.3em;
    font-weight: normal;

    @media screen and (min-width: 760px) {
      font-size: 1rem;
    }`,

  a: `
    color: blue !important;
    text-decoration: underline !important;
    cursor: pointer;
    background-color: $none;
    border: none;

    &:hover{
      color: rgb(65, 65, 255) !important
    }`,

  bold: `font-weight: bold;`,
};

export const transition = (property, options={}) => 
Array.isArray(property)
? `transition: ${property.map((d,i) => `${d} ${options.duration || '0.3s'} ease-out ${i +1 !== property.length ? ',' : ';'}`).join('')}`
: `
  transition: ${property} ${options.duration || '0.3s'} ease-out;
`;

export const base = {
  button: `
    display: block;
    width: max-content;
    border: 1px solid ${colors.black};
    border-radius: 10px;
    background-color: ${colors.YELLOW};
    min-height: 40px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${typo.p}
    ${transition(["background-color", "color", "border-color"])}

    &:hover{
      background-color: ${colors.YELLOW2}
    }

    &:focus{
      background-color: ${colors.YELLOW};
    }
  `,

  iconButton: `
    border: 1.5px solid ${colors.darkGrey};
    background-color: ${colors.none};
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    ${transition("background-color")}
    cursor: pointer;

    &:hover{
      background-color: ${colors.YELLOW};
    }
    &:focus{
      background-color: ${colors.none};  
    }
  `,

  navLinkStyle: (active) => `
    background-color: ${active ? colors.YELLOW2 : colors.YELLOW};
    &:hover{
      background-color: ${colors.YELLOW2}
    }
    &:focus{
      background-color: ${active ? colors.YELLOW2 : colors.YELLOW};  
    }
  `,

  hamburgerStyle: (active) => `
    border: none;
    &:hover{
      background-color: ${colors.YELLOW}
    }
    background-color: ${active ? colors.YELLOW2  : colors.YELLOW};
    &:focus{
      background-color: ${active ? colors.YELLOW2 : colors.YELLOW};  
    }
  `
};
