
    export default {
      "scene0": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X",
              "hi": "X ",
              "ka": "X",
              "te": "X",
              "or": "X",
              "as": "X",
              "gu": "X"
            }
          },
          {
            "valuePath": "blocks.0.props.desc",
            "value": {
              "en": "placeholder desc",
              "hi": "प्लेसहोल्डर डेस्क ",
              "ka": "placeholder desc",
              "te": "placeholder desc",
              "or": "placeholder desc",
              "as": "placeholder desc",
              "gu": "placeholder desc"
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.0.text",
            "value": {
              "en": "Madhav is a 15-year old boy from Kanpur, studying in 10th standard in All-Boys Boarding School. He has been studying in this school since he was in 5th standard.",
              "hi": "माधव कानपुर का रहने वाला 15 साल का लड़का है, जो ऑल बॉयज बोर्डिंग स्कूल में दसवीं कक्षा में पढ़ता है। वह इस स्कूल में तब से पढ़ रहा है जब वह पांचवीं कक्षा में था।",
              "ka": "ಮಾಧವ್ ಕಾನ್ಪುರದ ಒಬ್ಬ 15 ವರ್ಷದ ಬಾಲಕ, ಆಲ್-ಬಾಯ್ಸ್ ಬೋರ್ಡಿಂಗ್ ಶಾಲೆಯಲ್ಲಿ 10 ನೇ ತರಗತಿಯಲ್ಲಿ ಓದುತ್ತಿದ್ದಾನೆ. 5ನೇ ತರಗತಿಯಿಂದಲೂ ಈ ಶಾಲೆಯಲ್ಲಿಯೇ ಅವನು ಓದುತ್ತಿದ್ದಾನೆ.",
              "te": "మాధవ్ కాన్పూర్‌కు చెందిన 15 ఏళ్ల బాలుడు, ఆల్-బాయ్స్ బోర్డింగ్ స్కూల్‌లో 10వ తరగతి చదువుతున్నాడు. అతను 5వ తరగతి నుంచి ఇదే పాఠశాలలో చదువుతున్నాడు.",
              "or": "ମାଧବ କାନପୁରର ଜଣେ 15 ବର୍ଷର ବାଳକ, କେବଳ ପୁଅ ପିଲା ବୋର୍ଡିଂ ସ୍କୁଲରେ\n  ଦଶମ ଷ୍ଟାଣ୍ଡାର୍ଡରେ ପଢୁଛନ୍ତି | ସେ ୫ମ ଷ୍ଟାଣ୍ଡାର୍ଡରୁ ଏହି ବିଦ୍ୟାଳୟରେ ପଢୁଛନ୍ତି।",
              "as": "মাধৱ কানপুৰৰ অল-বয়জ বৰ্ডিং স্কুলৰ ১০ম শ্ৰেণীত অধ্যয়ন কৰি থকা এজন ১৫বছৰীয়া ল'ৰা। তেওঁ ৫ম শ্ৰেণীৰ পৰাই এই বিদ্যালয়ত পঢ়ি আছে।",
              "gu": "માધવ 15 વર્ષનો છોકરો છે, જે કાનપુર થી છે , ઓલ બોયઝ બોર્ડિંગ સ્કૂલમાં 10મા ધોરણમાં અભ્યાસ કરે છે. તે આ શાળામાં 5મા ધોરણથી અભ્યાસ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.1.text",
            "value": {
              "en": "Madhav does not have any siblings. His father is a labourer. After his mother's death, his father sends him to boarding school as he can't take care of him.",
              "hi": "माधव के कोई भाई-बहन नहीं है। उसके पिता एक मजदूर हैं।  उसकी मां की मृत्यु के बाद वह उसकी देखभाल नहीं कर सकते हैं, इसलिए उसके पिता उसे बोर्डिंग स्कूल भेजते हैं।",
              "ka": "ಮಾಧವನಿಗೆ ಯಾವುದೇ ಒಡಹುಟ್ಟಿದವರಿಲ್ಲ. ಇವನ ತಂದೆ ಕೂಲಿ ಕಾರ್ಮಿಕ. ಅವನ ತಾಯಿಯ ಮರಣದ ನಂತರ, ಅವನ ತಂದೆ ಅವನನ್ನು ನೋಡಿಕೊಳ್ಳಲು ಸಾಧ್ಯವಿಲ್ಲ ಎಂದು ತಿಳಿದು ಅವನನ್ನು ಬೋರ್ಡಿಂಗ್ ಶಾಲೆಗೆ ಕಳುಹಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్‌కి తోబుట్టువులు లేరు. అతని తండ్రి కూలీ. అతని తల్లి మరణం తరువాత, అతని తండ్రి అతనిని చూసుకోలేక బోర్డింగ్ స్కూల్‌కు పంపిస్తాడు.",
              "or": "ମାଧବଙ୍କର କୌଣସି ଭାଇଭଉଣୀ ନାହାଁନ୍ତି | ତାଙ୍କ ପିତା ଜଣେ ଶ୍ରମିକ। ମା’ଙ୍କ ମୃତ୍ୟୁ \n ପରେ ତାଙ୍କ ପିତା ତାଙ୍କ ଯତ୍ନ ନେଇ ନ ଥିବାରୁ ତାଙ୍କୁ ବୋର୍ଡିଂ ସ୍କୁଲକୁ ପଠାନ୍ତି |",
              "as": "মাধৱৰ কোনো ভাই-ভনী নাই। তাৰ দেউতাক এজন শ্ৰমিক। মাকৰ মৃত্যুৰ পিছত, তাৰ দেউতাকে তাক বৰ্ডিং স্কুললৈ পঠিয়ায় কিয়নো তেওঁ তাৰ যত্ন ল'ব নোৱাৰে।",
              "gu": "માધવને કોઈ ભાઈ-બહેન નથી. તેના પિતા મજૂર છે. તેની માતાના મૃત્યુ પછી, તેના પિતા તેને બોર્ડિંગ સ્કૂલમાં મોકલે છે કારણ કે તેના પિતા તેની સંભાળ રાખી શકતા નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.2.text",
            "value": {
              "en": "The school is government-funded and charges a basic fee.  It is far from the city but has a good range of facilities.  The rarely goes home to Kanpur.",
              "hi": "स्कूल सरकार द्वारा वित्त पोषित है और बेसिक शुल्क लेता है। यह शहर से बहुत दूर है लेकिन यहां बहुत सी सुविधाएं हैं। वह शायद ही कभी अपने घर कानपुर जाता है।",
              "ka": "ಶಾಲೆಯು ಸರ್ಕಾರದಿಂದ ಅನುದಾನಿತವಾಗಿದೆ ಮತ್ತು ಮೂಲ ಶುಲ್ಕವನ್ನು ವಿಧಿಸುತ್ತದೆ. ಇದು ನಗರದಿಂದ ದೂರವಿದ್ದರೂ ಕೂಡ ಉತ್ತಮ ಶ್ರೇಣಿಯ ಸೌಲಭ್ಯಗಳನ್ನು ಹೊಂದಿದೆ. ಅವನು ಕಾನ್ಪುರಕ್ಕೆ ಮನೆಗೆ ಹೋಗುವುದು ಅಪರೂಪ.",
              "te": "స్కూల్ ప్రభుత్వ-నిధులు కలది మరియు తక్కువ రుసుము వసూలు చేస్తుంది. ఇది నగరానికి దూరంగా ఉన్నప్పటికీ మంచి సౌకర్యాలను కలిగి ఉంది. కాన్పూర్ ఇంటికి చాలా అరుదుగా వెళ్తాడు.",
              "or": "ବିଦ୍ୟାଳୟଟି ସରକାରୀ ଅନୁଦାନ ପ୍ରାପ୍ତ ଏବଂ ଏକ ମୌଳିକ ଶୁଳ୍କ ଆଦାୟ \n କରେ | ଏହା ସହରଠାରୁ ବହୁତ ଦୂରରେ କିନ୍ତୁ ଏହାର ସୁବିଧାଜନକ ପରିସର ଅଛି | କ୍ୱଚିତ୍ କାନପୁରକୁ ଘରକୁ ଯାଏ |",
              "as": "বিদ্যালয়খন চৰকাৰৰ দ্বাৰা অনুদানপ্ৰাপ্ত আৰু এক মৌলিক মাচুলহে আদায় কৰে। এইখন চহৰৰ পৰা বহু দূৰত কিন্তু ইয়াত ভাল পৰিসৰৰ সুবিধা আছে। সি কেতিয়াবাহে কানপুৰৰ ঘৰলৈ যায়।",
              "gu": "શાળા સરકાર દ્વારા ભંડોળ પૂરું પાડવામાં આવે છે અને ઓછી ફી લે છે. તે શહેરથી દૂર છે પરંતુ સુવિધાઓ સારી છે. તે ક્યારેક જ કાનપુર તેના ઘરે જાય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.3.text",
            "value": {
              "en": "His friends, Kuldeep and Badri, are like his family. He spends almost all his time with them.  They attend classes together, eat their meals together and even share the same hostel room.",
              "hi": "उसके मित्र, कुलदीप और बद्री, उसके परिवार की तरह हैं। वह लगभग अपना पूरा समय उनके साथ बिताता है। वे एक साथ कक्षा में भाग लेते हैं, भोजन खाते हैं और यहां तक ​​कि छात्रावास में एक कमरा भी साझा करते हैं।",
              "ka": "ಅವನ ಸ್ನೇಹಿತರು, ಕುಲದೀಪ್ ಮತ್ತು ಬದ್ರಿ ಅವನ ಕುಟುಂಬದಂತೆ. ಅವನು ತನ್ನ ಬಹುತೇಕ ಎಲ್ಲಾ ಸಮಯವನ್ನು ಅವರೊಂದಿಗೆ ಕಳೆಯುತ್ತಾನೆ. ಅವರು ಒಟ್ಟಿಗೆ ತರಗತಿಗಳಿಗೆ ಹಾಜರಾಗುತ್ತಾರೆ, ತಮ್ಮ ಊಟವನ್ನು ಒಟ್ಟಿಗೆ ತಿನ್ನುತ್ತಾರೆ ಮತ್ತು ಒಂದೇ ಹಾಸ್ಟೆಲ್ ಕೊಠಡಿಯನ್ನು ಸಹ ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "అతని స్నేహితులు, కుల్దీప్ మరియు బద్రీ, అతని కుటుంబం వంటివారు. అతను దాదాపు తన సమయం మొత్తం వారితోనే గడుపుతాడు. వారు కలిసి తరగతులకు హాజరవుతారు, కలిసి భోజనం చేస్తారు మరియు ఒకే హాస్టల్ గదిలో ఉంటారు.",
              "or": "ତାଙ୍କର ବନ୍ଧୁ କୁଲଦୀପ ଏବଂ ବଦ୍ରି ତାଙ୍କ ପରିବାର ପରି। ସେ ପ୍ରାୟ ସମସ୍ତ ସମୟ \n ସେମାନଙ୍କ ସହିତ ବିତାନ୍ତି | ସେମାନେ ଏକାଠି କ୍ଲାସରେ ଯୋଗ ଦିଅନ୍ତି, ସେମାନଙ୍କର ଭୋଜନ ଏକାଠି ଖାଆନ୍ତି ଏବଂ ସମାନ ହଷ୍ଟେଲ ରୁମ୍ ମଧ୍ୟ ଅଂଶୀଦାର କରନ୍ତି |",
              "as": "তাৰ বন্ধু, কুলদীপ আৰু বদ্ৰী, তাৰ পৰিয়ালৰ দৰে। সি প্ৰায় সকলো সময় তেওঁলোকৰ সৈতে কটায়। সিহঁতে একেলগে ক্লাছ কৰে, আহাৰ একেলগে খায় আৰু আনকি একেটা হোষ্টেলৰ কোঠাতে থাকে।",
              "gu": "તેના મિત્રો કુલદીપ અને બદ્રી તેના પરિવારના સભ્યો જેવા છે. તે લગભગ તમામ સમય તેમની સાથે વિતાવે છે. તેઓ એકસાથે વર્ગમાં હાજરી આપે છે, તેઓ એકસાથે ભોજન લે છે અને હોસ્ટેલમાં એક જ રૂમ પણ રહે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.4.text",
            "value": {
              "en": "Madhav loves watching action movies, especially films about police officers fighting criminals. He looks forward to the action movies night that the seniors organise at their hostel on Sundays.",
              "hi": "माधव एक्शन फिल्में देखना पसंद करता है, विशेष रूप से अपराधियों से लड़ने वाले  पुलिस अधिकारियों के बारे में फिल्में। वह एक्शन मूवीज़ की उस रात का इंतज़ार कर रहा है जो कि सीनियर रविवार को अपने छात्रावास में आयोजित करते हैं।",
              "ka": "ಮಾಧವ್ ಆಕ್ಷನ್ ಚಲನಚಿತ್ರಗಳನ್ನು ವೀಕ್ಷಿಸಲು ಇಷ್ಟಪಡುತ್ತಾನೆ, ವಿಶೇಷವಾಗಿ ಪೊಲೀಸ್ ಅಧಿಕಾರಿಗಳು ಅಪರಾಧಿಗಳೊಂದಿಗೆ ಹೋರಾಡುವ ಚಲನಚಿತ್ರಗಳನ್ನು ವೀಕ್ಷಿಸುತ್ತಾನೆ. ಭಾನುವಾರದಂದು ಅವರಿಗಿಂತ ಹಿರಿಯರು ತಮ್ಮ ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿ ಆಯೋಜಿಸುವ ಆಕ್ಷನ್ ಚಲನಚಿತ್ರಗಳ ರಾತ್ರಿಯನ್ನು ಅವರು ಎದುರು ನೋಡುತ್ತಾರೆ.",
              "te": "మాధవ్‌కి యాక్షన్ సినిమాలంటే చాలా ఇష్టం, ముఖ్యంగా నేరస్థులతో పోరాడే పోలీస్ ఆఫీసర్స్ సినిమాలంటే చాలా ఇష్టం. ఆదివారం నాడు సీనియర్లు తమ హాస్టల్‌లో పెట్టే యాక్షన్ సినిమాల రాత్రి కోసం అతను ఎదురు చూస్తున్నాడు.",
              "or": "ମାଧବ ଆକ୍ସନ୍ ଚଳଚ୍ଚିତ୍ର ଦେଖିବାକୁ ଭଲ ପାଆନ୍ତି, ବିଶେଷକରି ଅପରାଧୀଙ୍କ ସହ\n  ଲଢୁଥିବା ପୋଲିସ୍ ଅଧିକାରୀଙ୍କ ବିଷୟରେ ଚଳଚ୍ଚିତ୍ର | ସିନିୟରମାନେ ରବିବାର ଦିନ ସେମାନଙ୍କ ହଷ୍ଟେଲରେ ଆୟୋଜନ କରୁଥିବା ଆକ୍ସନ୍ ଚଳଚ୍ଚିତ୍ରକୁ ଦେଖିବାକୁ ସେ ରାତିରେ ସେ ଅପେକ୍ଷା କରନ୍ତି |",
              "as": "মাধৱে একচন চিনেমা চাবলৈ ভাল পায়, বিশেষকৈ অপৰাধীৰ বিৰুদ্ধে যুঁজ দিয়া আৰক্ষী বিষয়াসকলৰ চলচ্চিত্ৰবোৰ। সি দেওবাৰে জ্যেষ্ঠসকলে তেওঁলোকৰ হোষ্টেলত আয়োজন কৰা একচন চিনেমাৰ নিশাটোৰ বাবে অপেক্ষা কৰি আছে।",
              "gu": "માધવને એક્શન ફિલ્મો જોવી ગમે છે, ખાસ કરીને ગુનેગારો સામે લડતા પોલીસ અધિકારીઓની ફિલ્મો. હોસ્ટેલમાં જ્યારે આગળના વર્ગના વિદ્યાર્થી રવિવારે રાત્રે  એક્શન મૂવીઝ લગાવે છે ત્યારે તેની રાહ જોવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.5.text",
            "value": {
              "en": "In this story, we look at how Madhav prepares for the Civil Services (UPSC) Exams and explore different career options.",
              "hi": "इस कहानी में, हम देखते हैं कि माधव सिविल सेवा (यूपीएससी) परीक्षाओं के लिए कैसे तैयारी करता है और विभिन्न करियर विकल्पों का पता लगाता है।",
              "ka": "ಈ ಕಥೆಯಲ್ಲಿ, ಮಾಧವ್  ಸಿವಿಲ್ ಸರ್ವೀಸಸ್ (ಯುಪಿಎಸ್‌ಸಿ) ಪರೀಕ್ಷೆಗಳಿಗೆ ಹೇಗೆ ತಯಾರಿ ನಡೆಸುತ್ತಾನೆ ಮತ್ತು ವಿವಿಧ ವೃತ್ತಿ ಆಯ್ಕೆಗಳನ್ನು ಹೇಗೆ ಅನ್ವೇಷಿಸುತ್ತಾನೆ ಎನ್ನುವುದು.",
              "te": "ఈ కథలో, మాధవ్ సివిల్ సర్వీసెస్ (UPSC) పరీక్షలకు ఎలా సిద్ధమవుతున్నాడో మరియు విభిన్న కెరీర్ ఎంపికలను అన్వేషిస్తాడో చూద్దాం.",
              "or": "ଏହି କାହାଣୀରେ, ଆମେ ଦେଖୁ ଯେ ମାଧବ ସିଭିଲ୍ ସର୍ଭିସେସ୍ (UPSC) ପରୀକ୍ଷା \n ପାଇଁ କିପରି ପ୍ରସ୍ତୁତ ହୁଏ ଏବଂ ବିଭିନ୍ନ କ୍ୟାରିୟର ବିକଳ୍ପଗୁଡିକ ଅନୁସନ୍ଧାନ କରେ |",
              "as": "এই কাহিনীটোত, আমি মাধৱে কেনেদৰে অসামৰিক সেৱা (UPSC) পৰীক্ষাৰ বাবে প্ৰস্তুতি চলায় আৰু জীৱিকাৰ বিভিন্ন বিকল্প অন্বেষণ কৰে সেইটো চাম।",
              "gu": "આ વાર્તામાં, આપણે જોઈશું  કે માધવ કેવી રીતે સિવિલ સર્વિસીસ (UPSC) પરીક્ષાઓની તૈયારી કરે છે અને કારકિર્દીના અલગ અલગ વિકલ્પો શોધે છે."
            }
          }
        ]
      },
      "scene1": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_1",
              "hi": "X1_1_1",
              "ka": "X1_1_1",
              "te": "X1_1_1",
              "or": "X1_1_1",
              "as": "X1_1_1",
              "gu": "X1_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Kuldeep, Madhav and Badri, are watching a movie called Singham along with other students in the hostel.",
              "hi": "कुलदीप, माधव और बद्री, छात्रावास में अन्य छात्रों के साथ सिंघम नामक एक फिल्म देख रहे हैं।",
              "ka": "ಕುಲದೀಪ್, ಮಾಧವ್ ಮತ್ತು ಬದ್ರಿ, ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿ ಇತರ ವಿದ್ಯಾರ್ಥಿಗಳೊಂದಿಗೆ ಸಿಂಗಂ ಎಂಬ ಚಲನಚಿತ್ರವನ್ನು ವೀಕ್ಷಿಸುತ್ತಿದ್ದಾರೆ.",
              "te": "కుల్దీప్, మాధవ్ మరియు బద్రి హాస్టల్‌లో ఇతర విద్యార్థులతో కలిసి సింగం అనే సినిమా చూస్తున్నారు.",
              "or": "କୁଲଦୀପ, ମାଧବ ଏବଂ ବଦ୍ରି ହଷ୍ଟେଲର ଅନ୍ୟ ଛାତ୍ରମାନଙ୍କ ସହିତ ସିଙ୍ଗହମ୍ ନାମକ\n  ଏକ ଚଳଚ୍ଚିତ୍ର ଦେଖୁଛନ୍ତି |",
              "as": "কুলদীপ, মাধৱ আৰু বদ্ৰীয়ে হোষ্টেলৰ আন শিক্ষাৰ্থীসকলৰ সৈতে সিংঘম নামৰ এখন চিনেমা চাই আছে।",
              "gu": "કુલદીપ, માધવ અને બદ્રી હોસ્ટેલમાં બીજા વિદ્યાર્થીઓ સાથે સિંઘમ નામની ફિલ્મ જોઈ રહ્યા છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_2",
              "hi": "X1_1_2",
              "ka": "X1_1_2",
              "te": "X1_1_2",
              "or": "X1_1_2",
              "as": "X1_1_2",
              "gu": "X1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Both Kuldeep and Madhav are cheering for their favourite police officer who is chasing robbers. Badri is tired of them rewatching the same movie.",
              "hi": "कुलदीप और माधव दोनों अपने पसंदीदा पुलिस अधिकारी के लिए उत्साहित हैं जो लुटेरों का पीछा कर रहे हैं। बद्री उनके एक ही फिल्म को फिर से देखने से थक गया है।",
              "ka": "ಕುಲದೀಪ್ ಮತ್ತು ಮಾಧವ್ ಇಬ್ಬರೂ ದರೋಡೆಕೋರರನ್ನು ಬೆನ್ನಟ್ಟುತ್ತಿರುವ ತಮ್ಮ ನೆಚ್ಚಿನ ಪೊಲೀಸ್ ಅಧಿಕಾರಿಗಾಗಿ ಹುರಿದುಂಬಿಸುತ್ತಿದ್ದಾರೆ. ಅದೇ ಸಿನಿಮಾವನ್ನು ಮತ್ತೆ ನೋಡಿ ಬದರಿ ಬೇಸತ್ತಿದ್ದಾನೆ.",
              "te": "కుల్దీప్ మరియు మాధవ్ ఇద్దరూ దొంగలను వెంబడించే తమ అభిమాన పోలీసు అధికారిని ప్రోత్సహిస్తున్నారు. బద్రి అదే సినిమాని మళ్ళీ మళ్ళీ చూసి విసిగిపోయాడు.",
              "or": "ଉଭୟ କୁଲଦୀପ ଏବଂ ମାଧବ ସେମାନଙ୍କ ପ୍ରିୟ ପୋଲିସ ଅଧିକାରୀଙ୍କ ପାଇଁ ଖୁସି\n  ହେଉଛନ୍ତି ଯିଏ ଡକାୟତମାନଙ୍କୁ ଗୋଡ଼ାଉଛନ୍ତି। ସମାନ ଚଳଚ୍ଚିତ୍ରକୁ ପୁନର୍ବାର ଦେଖିବା ପାଇଁ ବଦ୍ରି ସେମାନଙ୍କ ପାଇଁ କ୍ଳାନ୍ତ |",
              "as": "কুলদীপ আৰু মাধৱ দুয়োজনে তেওঁলোকৰ প্ৰিয় আৰক্ষী বিষয়াৰ বাবে উল্লাস কৰি আছে যিয়ে ডকাইতক খেদি ফুৰিছে। বদ্ৰীয়ে একেখন চিনেমাকে চাই চাই ভাগৰি পৰিছে।",
              "gu": "કુલદીપ અને માધવ બંને ખુશ થાય છે જયારે તેમના મનપસંદ પોલીસ અધિકારી લૂંટારાઓનો પાછળ દોડે છે. બદ્રી આ ફિલ્મ ફરી જોઈને થાકી ગયો છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_3//",
              "hi": "X1_1_3 //",
              "ka": "X1_1_3//",
              "te": "X1_1_3//",
              "or": "X1_1_3//",
              "as": "X1_1_3//",
              "gu": "X1_1_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୁଳଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Badri",
              "hi": "बद्री",
              "ka": "ಬದ್ರಿ",
              "te": "బద్రి",
              "or": "ବଦ୍ରି",
              "as": "বদ্ৰী",
              "gu": "બદ્રી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Hey, catch him! Catch him!",
              "hi": "अरे, उसे पकड़ो! उसे पकडो!",
              "ka": "ಹೇ, ಅವನನ್ನು ಹಿಡಿ! ಅವನನ್ನು ಹಿಡಿ!",
              "te": "హే, అతన్ని పట్టుకోండి! అతన్ని పట్టుకోండి!",
              "or": "ହେ, ତାଙ୍କୁ ଧର! ତାଙ୍କୁ ଧର!",
              "as": "হেই, ধৰ তাক! ধৰ তাক!",
              "gu": "અરે, તેને પકડો! તેને પકડો!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "You know what’s going to happen, then why do you need to scream? As if Singham isn’t going to catch him.",
              "hi": "तुम जानते हो कि क्या होने जा रहा है, तो तुमको चीखने की आवश्यकता क्यों है? जैसे कि सिंघम उसे पकड़ने वाला नहीं है।",
              "ka": "ಏನಾಗಲಿದೆ ಎಂದು ನಿಮಗೆ ತಿಳಿದಿದೆ, ಹಾಗಾದರೆ ನೀವು ಏಕೆ ಕಿರುಚಬೇಕು? ಸಿಂಘಮ್ ಅವರನ್ನು ಹಿಡಿಯಲು ಆಗುವುದಿಲ್ಲ ಎನ್ನುವ ರೀತಿ.",
              "te": "ఏమి జరగబోతోందో మీకు తెలుసు, మరి సింగం అతడిని పట్టుకోనట్లు మీరు ఎందుకు అరుస్తున్నారు?",
              "or": "ତୁମେ ଜାଣିଛ କ’ଣ ଘଟିବାକୁ ଯାଉଛି, ତେବେ ତୁମେ ଚିତ୍କାର କରିବାର ଆବଶ୍ୟକତା\n  କାହିଁକି? ଯେପରି ସିଙ୍ଗହମ୍ ତାଙ୍କୁ ଧରିବାକୁ ଯାଉନାହାଁନ୍ତି |",
              "as": "তুমি জানাই কি হ'ব, তেন্তে কিয় চিঞৰিব লাগে? যেন সিংঘমে তাক ধৰিব নোৱাৰিবহে।",
              "gu": "તને પહેલથી જ ખબર છે કે શું થવાનું છે, તો પછી ચીસો પાડવાની શું જરૂર છે? જાણે કે  સિંઘમ તેને પકડવાનો જ  ના હોય  ."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_4////",
              "hi": "X1_1_4 //////",
              "ka": "X1_1_4////",
              "te": "X1_1_4////",
              "or": "X1_1_4////",
              "as": "X1_1_4////",
              "gu": "X1_1_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "You always spoil our fun, Badri. So what if we know what's going to happen? We are excited because Singham is our hero! We scream every time this scene comes.",
              "hi": "तुम हमेशा हमारे मजे को खराब कर देते हो, बद्री। तो क्या हुआ यदि हम जानते हैं कि क्या होने जा रहा है? हम उत्साहित हैं क्योंकि सिंघम हमारा नायक है! हम हर बार चिल्लाते हैं जब यह दृश्य आता है।",
              "ka": "ನೀನು ಯಾವಾಗಲೂ ನಮ್ಮ ಮಜಾ ಹಾಳು ಮಾಡ್ತೀಯಾ ಬದ್ರಿ. ಹಾಗಾದರೆ ಏನಾಗುತ್ತದೆ ಎಂದು ನಮಗೆ ತಿಳಿದಿದ್ದರೆ ಏನು? ನಾವು ಉತ್ಸುಕರಾಗಿದ್ದೇವೆ ಏಕೆಂದರೆ ಸಿಂಗಮ್ ನಮ್ಮ ನಾಯಕ! ಈ ದೃಶ್ಯ ಬಂದಾಗಲೆಲ್ಲಾ ಕಿರುಚುತ್ತೇವೆ.",
              "te": "నువ్వు ఎప్పుడూ మా సరదాలను పాడుచేస్తావు బద్రి. ఏమి జరుగుతుందో మాకు తెలిస్తే మాత్రం ఏమిటి? సింగం మా హీరో కాబట్టి మేము సంతోషిస్తున్నాము! ఈ సీన్ వచ్చిన ప్రతిసారీ అరుస్తాం.",
              "or": "ତୁମେ ସବୁବେଳେ ଆମର ମଜାକୁ ଖରାପ କରିଦିଅ, ବଦ୍ରି | ତେବେ ଯଦି ଆମେ ଜାଣୁ \n କ’ଣ ଘଟିବ? ଆମେ ଉତ୍ସାହିତ କାରଣ ସିଙ୍ଗହମ୍ ଆମର ହିରୋ! ପ୍ରତ୍ୟେକ ଥର ଏହି ଦୃଶ୍ୟ ଆସିବା ପରେ ଆମେ ଚିତ୍କାର କରୁ |",
              "as": "তুমি সদায় আমাৰ ভাললগাবোৰ নষ্ট কৰা, বদ্ৰী। আমি জানিলোৱে যেনিবা কি হ'বনো? আমি উৎসাহিত কাৰণ সিংঘম আমাৰ নায়ক! এই দৃশ্যটো আহিলে আমি প্ৰতিবাৰেই চিঞৰ-বাখৰ কৰোঁ।",
              "gu": "તું હંમેશા અમારી મજા બગાડો છો, બદ્રી. ભલે આપણે જાણતા હોઈએ કે  શું થવાનું છે, તેમાં શું થશે? તો પણ અમે ખુશ છીએ કારણ કે સિંઘમ અમારો હીરો છે! જ્યારે પણ આ દ્રશ્ય આવે છે ત્યારે અમે ચીસો પાડીએ છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "You really think this is what police officers do? Go talk to an actual police officer. Their life isn’t just about chasing villains and dodging flying cars.",
              "hi": "तुम वास्तव में सोचते हो कि पुलिस अधिकारी ऐसा करते हैं? जाओ, एक वास्तविक पुलिस अधिकारी से बात करो। उनका जीवन सिर्फ खलनायकों का पीछा करने और उड़ती हुई कारों को धोखा देने के बारे में नहीं है।",
              "ka": "ಪೊಲೀಸ್ ಅಧಿಕಾರಿಗಳು ಏನು ಮಾಡುತ್ತಾರೆ ಎಂದು ನೀವು ನಿಜವಾಗಿಯೂ ಯೋಚಿಸುತ್ತೀರಾ? ನಿಜವಾದ ಪೊಲೀಸ್ ಅಧಿಕಾರಿಯೊಂದಿಗೆ ಮಾತನಾಡಿ. ಅವರ ಜೀವನವು ಕೇವಲ ಖಳನಾಯಕರನ್ನು ಬೆನ್ನಟ್ಟುವುದು ಮತ್ತು ಹಾರುವ ಕಾರುಗಳನ್ನು ತಪ್ಪಿಸಿಕೊಳ್ಳುವುದು ಮಾತ್ರವಲ್ಲ.",
              "te": "పోలీసు అధికారులు ఇలా చేస్తారని మీరు అనుకుంటున్నారా? వెళ్ళి నిజమైన పోలీసుతో మాట్లాడండి. వారి జీవితం విలన్‌లను వెంబడించడం మరియు ఎగిరే కార్లను తప్పించుకోవడం మాత్రమే కాదు.",
              "or": "ତୁମେ ପ୍ରକୃତରେ ଭାବୁଛ କି ପୋଲିସ୍ କର୍ମଚାରୀମାନେ ଏହା କରନ୍ତି କି? ପ୍ରକୃତ ପୋଲିସ ଅଧିକାରୀଙ୍କ \n ସହ କଥାବାର୍ତ୍ତା କରିବାକୁ ଯାଅ | ସେମାନଙ୍କ ଜୀବନ କେବଳ ଭିଲେନ୍ମାନଙ୍କୁ ଗୋଡ଼ାଇବା ଏବଂ ଫ୍ଲାଇଙ୍ଗ୍ କାର୍ ଚଲାଇବା ବିଷୟରେ ନୁହେଁ |",
              "as": "তুমি সঁচাকৈয়ে ভাবানে যে আৰক্ষী বিষয়াসকলে এনে কৰে? এজন প্ৰকৃত আৰক্ষী বিষয়াৰ সৈতে কথা পাতা। তেওঁলোকৰ জীৱন কেৱল খলনায়কক খেদি যোৱা আৰু উৰণীয়া গাড়ীক আগভেটি ধৰাই নহয়।",
              "gu": "તમે ખરેખર એવું વિચારો છો કે આ બધા  પોલીસ અધિકારીઓ આવું કરતાં હશે? કોઈ વાસ્તવિક પોલીસ અધિકારી સાથે વાત કરો. તેમનું જીવન માત્ર હરામખોરોની પાછળ ભાગવાનુ અને કારોને ઉડાડવાનું નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Why are you getting all serious? We know this isn’t how it actually is.",
              "hi": "तुम इतना गंभीर क्यों हो रहे हो? हम जानते हैं कि यह ऐसा नहीं है जैसा वास्तव में है।",
              "ka": "ನೀನು ಯಾಕೆ ಅಷ್ಟು ಉದ್ವೇಗಗೊಳ್ಳುತ್ತಿದ್ದೀಯಾ? ಇದು ನಿಜವಾಗಿ ಹೀಗೆ ಆಗುವುದಿಲ್ಲ ಎಂದು ನಮಗೆ ತಿಳಿದಿದೆ.",
              "te": "ఎందుకు అంత సీరియస్ అవుతున్నారు? ఇది నిజానికి అలా ఉండదని మాకు తెలుసు.",
              "or": "ଆପଣ କାହିଁକି ସବୁ ଗମ୍ଭୀର ହେଉଛନ୍ତି? ଆମେ ଜାଣୁ ଏହା ପ୍ରକୃତରେ କିପରି ନୁହେଁ |",
              "as": "তুমি সকলো ক্ষেত্ৰতে ইমান গহীন কিয় হৈ যোৱা? আমিও জানো যে এইটো প্ৰকৃততে এনে নহয়।",
              "gu": "તું આટલુ બધુ ગંભીરતાથી કેમ લઈ રહ્યો છે? આમે જાણીએ છીએ કે આ ખરેખર એવું નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Will you 3 keep quiet and let us enjoy the movie?",
              "hi": "क्या तुम तीनों चुप रहोगे और हमें फिल्म का आनंद लेने दोगे?",
              "ka": "ನೀವು 3 ಜನರು ಸುಮ್ಮನಿದ್ದು ನಮಗೆ ಚಲನಚಿತ್ರವನ್ನು ಆನಂದಿಸಲು ಅವಕಾಶ ನೀಡುತ್ತೀರಾ?",
              "te": "మీరు ముగ్గురు మౌనంగా ఉండి మమ్మల్ని సినిమా చూడనిస్తారా?",
              "or": "ଆପଣ 3 ଚୁପ୍ ରହିବେ ଏବଂ ଆମକୁ ଚଳଚ୍ଚିତ୍ର ଉପଭୋଗ କରିବାକୁ ଦେବେ କି?",
              "as": "তোমালোক ৩টা চুপ থাকিবানে আৰু আমাক চিনেমাখন উপভোগ কৰিবলৈ দিবানে?",
              "gu": "શું તમે 3 જણા શાંત રહેશો અને અમને મૂવીની મજા માણવા દેશો?"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_2_1",
              "hi": "X1_2_1",
              "ka": "X1_2_1",
              "te": "X1_2_1",
              "or": "X1_2_1",
              "as": "X1_2_1",
              "gu": "X1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Nobody understands the bond that the three of them share. Even though they argue all the time, they are inseparable. They are very different, yet they understand each other very well.",
              "hi": "कोई भी  इस घनिष्ठता को नहीं समझता है जो वे तीनों साझा करते हैं। भले ही वे हर समय बहस करते हैं, लेकिन उनको बांटा नहीं जा सकता। वे बहुत अलग हैं, फिर भी वे एक-दूसरे को बहुत अच्छी तरह से समझते हैं।",
              "ka": "ಮೂವರೂ ಹಂಚಿಕೊಂಡಿರುವ ಬಂಧವು ಯಾರಿಗೂ ಅರ್ಥವಾಗುವುದಿಲ್ಲ. ಅವರು ಎಲ್ಲಾ ಸಮಯದಲ್ಲೂ ವಾದಿಸಿದರೂ ಸಹ, ಅವರುಗಳನ್ನು ಬೇರ್ಪಡಿಸಲು ಆಗುವುದಿಲ್ಲ. ಅವರು ತುಂಬಾ ವಿಭಿನ್ನರಾಗಿದ್ದಾರೆ, ಆದರೂ ಅವರು ಪರಸ್ಪರರನ್ನು ಚೆನ್ನಾಗಿ ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "వాళ్ళ ముగ్గురి మధ్య బంధం ఎవరికీ అర్థం కాదు. వారు ఎప్పటికీ పోట్లాడుకుంటారు కానీ విడిపోయి ఉండలేరు. వారు చాలా భిన్నంగా ఉంటారు, కానీ వారు ఒకరినొకరు బాగా అర్థం చేసుకుంటారు.",
              "or": "ତିନିଜଣ ଅଂଶୀଦାର କରୁଥିବା ବନ୍ଧନକୁ କେହି ବୁଝନ୍ତି ନାହିଁ | \n ଯଦିଓ ସେମାନେ ସବୁବେଳେ ଯୁକ୍ତିତର୍କ କରନ୍ତି, ସେମାନେ ଅବିଚ୍ଛେଦ୍ୟ | ସେମାନେ ବହୁତ ଅଲଗା, ତଥାପି ସେମାନେ ପରସ୍ପରକୁ ଭଲ ଭାବରେ ବୁଝନ୍ତି |",
              "as": "তেওঁলোক তিনিওজনৰ মাজৰ বান্ধোনটো কোনেও বুজি নাপায়। যদিও তেওঁলোকে সকলো সময়তে তৰ্ক কৰি থাকে, তেওঁলোক অভিন্ন। তেওঁলোকৰ চিন্তা বহুত বেলেগ, তথাপিও তেওঁলোকে ইজনে সিজনক ভালদৰে বুজি পায়।",
              "gu": "તે ત્રણેયના સબંધને કોઈ સમજી શકતું નથી. ભલે તેઓ હંમેશા દલીલ કરે છે, તેઓને કોઈ જુદા પાડી શકે નહિ  છે. તેઓ ખૂબ જ અલગ છે, તેમ છતાં તેઓ એકબીજાને સારી રીતે સમજે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_2_2",
              "hi": "X1_2_2",
              "ka": "X1_2_2",
              "te": "X1_2_2",
              "or": "X1_2_2",
              "as": "X1_2_2",
              "gu": "X1_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Of the three, Badri is the most serious about his studies, and he pushes the other two to keep up with their studies and do well in the exams.",
              "hi": "तीनों में से, बद्री अपनी पढ़ाई के बारे में सबसे गंभीर है, और वह अन्य दो को अपने अध्ययन में रुचि रखने और परीक्षा में अच्छा प्रदर्शन करने के लिए हर समय कहता रहता है।",
              "ka": "ಮೂವರಲ್ಲಿ, ಬದ್ರಿ ತನ್ನ ಅಧ್ಯಯನದ ಬಗ್ಗೆ ಹೆಚ್ಚು ಆಸಕ್ತಿ ಹೊಂದಿದ್ದಾನೆ, ಮತ್ತು ಅವನು ಇತರ ಇಬ್ಬರನ್ನು ತಮ್ಮ ಅಧ್ಯಯನವನ್ನು ಮುಂದುವರಿಸಲು ಮತ್ತು ಪರೀಕ್ಷೆಗಳಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಮಾಡಲು ಪ್ರೇರೇಪಿಸುತ್ತಾನೆ.",
              "te": "ఆ ముగ్గురిలో బద్రి తన చదువుల విషయంలో చాలా సీరియస్‌గా ఉంటాడు, మిగతా ఇద్దరికీ బాగా చదువుకొని పరీక్షల్లో రాణించమని చెప్తాడు.",
              "or": "ଏହି ତିନୋଟି ମଧ୍ୟରୁ ବଦ୍ରି ତାଙ୍କ ଅଧ୍ୟୟନ ପାଇଁ ସବୁଠାରୁ ଗମ୍ଭୀର, ଏବଂ ସେ ଅନ୍ୟ\n  ଦୁଇଜଣଙ୍କୁ ସେମାନଙ୍କ ଅଧ୍ୟୟନକୁ ଜାରି ରଖିବା ଏବଂ ପରୀକ୍ଷାରେ ଭଲ କରିବା ପାଇଁ ଠେଲିଥାନ୍ତି |",
              "as": "তিনিওজনৰ ভিতৰত, বদ্ৰীৰ পঢ়া-শুনাৰ প্ৰতি গুৰুত্ব আটাইতকৈ বেছি, আৰু সি বাকী দুজনক তেওঁলোকৰ পঢ়া-শুনা অব্যাহত ৰাখিবলৈ আৰু পৰীক্ষাত ভাল প্ৰদৰ্শন কৰিবলৈ হেঁচা দিয়ে।",
              "gu": "ત્રણમાંથી, બદ્રી તેના અભ્યાસ પ્રત્યે સૌથી ગંભીર છે, અને તે બીજા બેને અભ્યાસ ચાલુ રાખવા અને પરીક્ષામાં સારો દેખાવ કરવા દબાણ કરે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_2_4",
              "hi": "X1_2_4",
              "ka": "X1_2_4",
              "te": "X1_2_4",
              "or": "X1_2_4",
              "as": "X1_2_4",
              "gu": "X1_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Kuldeep loves to play pranks. He troubles seniors by sticking notes on their backs and mixing up their bags.",
              "hi": "कुलदीप को मज़ाक करना पसंद है। वह सीनियर्स को उनकी पीठ पर नोट चिपका कर और उनके बैग को आपस में बदल कर परेशान करता है।",
              "ka": "ಕುಲ್‌ದೀಪ್‌ಗೆ ತಮಾಷೆ ಮಾಡಲು ತುಂಬಾ ಇಷ್ಟ. ಅವನಿಗಿಂತ ದೊಡ್ಡವರ ಬೆನ್ನಿಗೆ ನೋಟುಗಳನ್ನು ಅಂಟಿಸಿ ಬ್ಯಾಗ್‌ಗಳನ್ನು ಒಂದಕ್ಕೊಂದು ಬೆರೆಸಿ ತೊಂದರೆ ಕೊಡುತ್ತಾನೆ.",
              "te": "కుల్దీప్‌కి ప్రాంక్స్ చేయడం అంటే ఇష్టం. సీనియర్ల వీపుపై నోట్లు తగిలించడం, బ్యాగులు మార్చడం వంటివి చేస్తూ వారిని ఇబ్బంది పెడతాడు.",
              "or": "କୁଲଦୀପ ଖେଳିବାକୁ ଭଲ ପାଆନ୍ତି | ସେ ସେମାନଙ୍କ ପିଠିରେ ନୋଟ୍ ଲଗାଇ \n ସେମାନଙ୍କ ବ୍ୟାଗ୍ ମିଶ୍ରଣ କରି ବୟସ୍କମାନଙ୍କୁ ଅସୁବିଧାରେ ପକାନ୍ତି |",
              "as": "কুলদীপে ধেমালি কৰিবলৈ ভাল পায়। সি জ্যেষ্ঠসকলক তেওঁলোকৰ পিঠিত টোকা লগাই আৰু তেওঁলোকৰ বেগবোৰ সলনা-সলনি কৰি উৎপাত কৰে।",
              "gu": "કુલદીપને ટીખળો રમવાનો શોખ છે. તે સિનિયરને તેમની પીઠ પર કાગળ ચોંટાડીને અને તેમની બેગને અસ્તવ્યસ્ત કરીને પરેશાન કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_2_5",
              "hi": "X1_2_5",
              "ka": "X1_2_5",
              "te": "X1_2_5",
              "or": "X1_2_5",
              "as": "X1_2_5",
              "gu": "X1_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav usually gets himself in trouble by getting into heated discussions with people. In such times, Kuldeep and Badri are always by his side to offer support.",
              "hi": "माधव अक्सर लोगों से तीखी बहस में फंसकर खुद को मुश्किल में डाल लेता है। ऐसे समय में कुलदीप और बद्री हमेशा समर्थन देने के लिए उसके साथ होते हैं।",
              "ka": "ಮಾಧವ್ ಸಾಮಾನ್ಯವಾಗಿ ಜನರೊಂದಿಗೆ ಬಿಸಿ ಚರ್ಚೆಯಲ್ಲಿ ತೊಡಗುವ ಮೂಲಕ ತೊಂದರೆಗೆ ಸಿಲುಕಿಕೊಳ್ಳುತ್ತಾನೆ. ಅಂತಹ ಸಮಯದಲ್ಲಿ, ಕುಲದೀಪ್ ಮತ್ತು ಬದ್ರಿ ಯಾವಾಗಲೂ ಬೆಂಬಲ ನೀಡಲು ಅವನ ಪಕ್ಕದಲ್ಲಿರುತ್ತಾರೆ.",
              "te": "మాధవ్ సాధారణంగా ప్రజలతో వాడివేడిగా చర్చలు జరపడం ద్వారా ఇబ్బందుల్లో పడతాడు. అటువంటి సమయాల్లో, కుల్దీప్ మరియు బద్రి ఎల్లప్పుడూ అతనికి మద్దతుగా ఉంటారు.",
              "or": "ମାଧବ ସାଧାରଣତ people ଲୋକଙ୍କ ସହ ତୀବ୍ର ଆଲୋଚନା କରି ନିଜକୁ \n ଅସୁବିଧାରେ ପକାନ୍ତି | ଏପରି ସମୟରେ, କୁଲଦୀପ ଏବଂ ବଦ୍ରି ସର୍ବଦା ସମର୍ଥନ ପ୍ରଦାନ କରିବାକୁ ତାଙ୍କ ପାଖରେ ଥାଆନ୍ତି |",
              "as": "মাধৱে সাধাৰণতে মানুহৰ সৈতে উত্তপ্ত আলোচনাত লিপ্ত হৈ নিজকে সমস্যাত পেলায়। তেনেকুৱা সময়ত, কুলদীপ আৰু বদ্ৰীয়ে সদায় সমৰ্থন দিবৰ বাবে তাৰ কাষত থাকে।",
              "gu": "માધવ સામાન્ય રીતે લોકો સાથે ઉગ્ર ચર્ચાઓ કરીને પોતાને મુશ્કેલીમાં મૂકે છે. આવા સમયે, કુલદીપ અને બદ્રી હંમેશા તેની પડખે હોય છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_3_1",
              "hi": "X1_3_1",
              "ka": "X1_3_1",
              "te": "X1_3_1",
              "or": "X1_3_1",
              "as": "X1_3_1",
              "gu": "X1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "One day, Madhav goes up to the hostel warden to complain about their living conditions. Kuldeep and Badri are waiting for him outside the office.",
              "hi": "एक दिन, माधव अपने रहन सहन की परिस्थितियों के बारे में शिकायत करने के लिए छात्रावास वार्डन के पास जाता है। कुलदीप और बद्री कार्यालय के बाहर उसके लिए इंतजार कर रहे हैं।",
              "ka": "ಒಂದು ದಿನ, ಮಾಧವ್ ತಾವಿರುವ ಜೀವನ ಪರಿಸ್ಥಿತಿಗಳ ಬಗ್ಗೆ ದೂರು ನೀಡಲು ಹಾಸ್ಟೆಲ್ ವಾರ್ಡನ್ ಬಳಿಗೆ ಹೋಗುತ್ತಾನೆ. ಕುಲದೀಪ್ ಮತ್ತು ಬದ್ರಿ ಕಚೇರಿಯ ಹೊರಗೆ ಅವನಿಗಾಗಿ ಕಾಯುತ್ತಿದ್ದಾರೆ.",
              "te": "ఒకరోజు మాధవ్ హాస్టల్ వార్డెన్ దగ్గరకు వెళ్లి వారి జీవించే పరిస్థితుల గురించి ఫిర్యాదు చేస్తాడు. కుల్దీప్ మరియు బద్రి ఆఫీసు బయట అతని కోసం ఎదురు చూస్తున్నారు.",
              "or": "ଦିନେ ମାଧବ ହଷ୍ଟେଲ ୱାର୍ଡେନଙ୍କ ନିକଟକୁ ଯାଇ ସେମାନଙ୍କ ଜୀବନ ଅବସ୍ଥା ବିଷୟ\n ରେ ଅଭିଯୋଗ କରିଥିଲେ। କୁଲଦୀପ ଏବଂ ବଦ୍ରି ତାଙ୍କୁ ଅଫିସ୍ ବାହାରେ ଅପେକ୍ଷା କରିଛନ୍ତି |",
              "as": "এদিন, মাধৱে তেওঁলোকৰ জীৱন ধাৰণৰ পৰিস্থিতিৰ বিষয়ে অভিযোগ দিবলৈ হোষ্টেলৰ ৱাৰ্ডেনৰ ওচৰলৈ যায়। কুলদীপ আৰু বদ্ৰীয়ে কাৰ্যালয়ৰ বাহিৰত তাৰ বাবে অপেক্ষা কৰি আছে।",
              "gu": "એક દિવસ, માધવ હોસ્ટેલના વોર્ડન પાસે તેમની રહેવાની વ્યવસ્થા  વિષે  ફરિયાદ કરવા જાય છે. કુલદીપ અને બદ્રી ઓફિસની બહાર તેની રાહ જોઈ રહ્યા છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_3_2//",
              "hi": "X1_3_2 //",
              "ka": "X1_3_2//",
              "te": "X1_3_2//",
              "or": "X1_3_2//",
              "as": "X1_3_2//",
              "gu": "X1_3_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव ",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Warden",
              "hi": "वॉर्डन  ",
              "ka": "ವಾರ್ಡನ್",
              "te": "వార్డెన్",
              "or": "ୱ୍ୱାଡେର୍ଣ",
              "as": "ৱাৰ্ডেন",
              "gu": "વોર્ડન"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "How can we live in such bad conditions? There are rats on every floor. The bathrooms are never clean. The electricity is out so often that it makes it difficult to study at night.",
              "hi": "हम इतनी बुरी परिस्थिति में कैसे रह सकते हैं? हर मंजिल पर चूहे हैं। बाथरूम कभी साफ नहीं होते। बिजली अक्सर नहीं होती, रात में अध्ययन करना मुश्किल हो जाता है।",
              "ka": "ಇಂತಹ ಕೆಟ್ಟ ಪರಿಸ್ಥಿತಿಗಳಲ್ಲಿ ನಾವು ಹೇಗೆ ಬದುಕಬಲ್ಲೆವು? ಪ್ರತಿ ಮಹಡಿಯಲ್ಲಿ ಇಲಿಗಳಿವೆ. ಸ್ನಾನಗೃಹಗಳು ಎಂದಿಗೂ ಸ್ವಚ್ಛವಾಗಿರುವುದಿಲ್ಲ. ಆಗಾಗ ವಿದ್ಯುತ್ ಸಂಪರ್ಕ ಕಡಿತಗೊಂಡಿದ್ದು ರಾತ್ರಿ ವೇಳೆ ಓದಲು ತೊಂದರೆಯಾಗುತ್ತಿದೆ.",
              "te": "ఇంత దారుణమైన పరిస్థితుల్లో మేము ఎలా జీవించగలం? ప్రతి అంతస్తులో ఎలుకలు ఉన్నాయి. బాత్‌రూమ్‌లు ఎప్పుడూ శుభ్రంగా ఉండవు. తరచూ కరెంటు పోవడంతో రాత్రిపూట చదువుకోలేని పరిస్థితి నెలకొంది.",
              "or": "ଆମେ ଏପରି ଖରାପ ଅବସ୍ଥାରେ କିପରି ବଞ୍ଚିବା? ପ୍ରତ୍ୟେକ ଚଟାଣରେ \n ମୂଷା ଅଛି | ବାଥରୁମଗୁଡ଼ିକ କେବେ ପରିଷ୍କାର ନୁହେଁ | ବିଦ୍ୟୁତ୍ ଏତେ ବାରମ୍ବାର ବନ୍ଦ ହୋଇଯାଏ ଯେ ରାତିରେ ଅଧ୍ୟୟନ କରିବା କଷ୍ଟକର ହୋଇଯାଏ |",
              "as": "আমি কেনেকৈ এনে বেয়া পৰিস্থিতিত থাকিব পাৰোঁ? প্ৰতিটো মহলাতে নিগনি আছে। বাথৰুমবোৰ কেতিয়াও পৰিষ্কাৰ হৈ নাথাকে। বিদ্যুত ইমান সঘনাই গৈ থাকে যে ই ৰাতি অধ্যয়ন কৰাটো কঠিন কৰি তোলে।",
              "gu": "આવી ખરાબ પરિસ્થિતિમાં અમે કેવી રીતે રહી  શકીએ? દરેક તળિયા પર ઉંદરો છે. બાથરૂમ ક્યારેય સ્વચ્છ હોતા નથી. વીજળી એટલી વાર બંધ થઈ જાય છે કે રાત્રે ભણવામાં મુશ્કેલી પડે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I am aware of the problems in the hostel, Madhav. But solving them is not in my hands. Only when the higher authorities look into it, can we expect things to change.",
              "hi": "मैं छात्रावास की समस्याओं से अवगत हूं, माधव। लेकिन उन्हें हल करना मेरे हाथ में नहीं है। जब उच्च अधिकारी इसे देखेंगे, केवल तभी हम चीजों को बदलने की उम्मीद कर सकते हैं।",
              "ka": "ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿನ ಸಮಸ್ಯೆಗಳ ಬಗ್ಗೆ ನನಗೆ ಅರಿವಿದೆ ಮಾಧವ್. ಆದರೆ ಅವುಗಳನ್ನು ಪರಿಹರಿಸುವುದು ನನ್ನ ಕೈಯಲ್ಲಿಲ್ಲ. ಉನ್ನತ ಅಧಿಕಾರಿಗಳು ಇದನ್ನು ಪರಿಶೀಲಿಸಿದಾಗ ಮಾತ್ರ, ನಾವು ಬದಲಾಗಬಹುದು ಎಂದು ನಿರೀಕ್ಷಿಸಬಹುದು.",
              "te": "హాస్టల్‌లోని సమస్యలు నాకు తెలుసు, మాధవ్. కానీ వాటిని పరిష్కరించడం నా చేతుల్లో లేదు. ఉన్నతాధికారులు అనుకున్నప్పుడే పరిస్థితి మారుతుందని ఆశించవచ్చు.",
              "or": "ହଷ୍ଟେଲରେ ଥିବା ସମସ୍ୟା ବିଷୟରେ ମୁଁ ଅବଗତ ଅଛି, ମାଧବ | କିନ୍ତୁ \n ସେଗୁଡ଼ିକର ସମାଧାନ ମୋ ହାତରେ ନାହିଁ | ଯେତେବେଳେ ଉଚ୍ଚ କର୍ତ୍ତୃପକ୍ଷ ଏହା ଉପରେ ଦୃଷ୍ଟି ଦିଅନ୍ତି, ସେତେବେଳେ ଆମେ ଜିନିଷଗୁଡ଼ିକର ପରିବର୍ତ୍ତନ ହେବାର ଆଶା କରିପାରିବା |",
              "as": "মই হোষ্টেলৰ সমস্যাবোৰৰ বিষয়ে জানো, মাধৱ। কিন্তু সেইবোৰ সমাধান কৰাটো মোৰ হাতত নাই। যেতিয়া উচ্চ কৰ্তৃপক্ষই ইয়াক চোৱা-চিতা কৰিব, তেতিয়াহে আমি বস্তুবোৰ সলনি হোৱাৰ আশা কৰিব পাৰোঁ।",
              "gu": "હું હોસ્ટેલની સમસ્યાઓથી વાકેફ છું, માધવ. પરંતુ તેને ઉકેલવું મારા હાથમાં નથી. જ્યારે ઉચ્ચ સત્તાવાળાઓ તેના પર ધ્યાન આપે છે, ત્યારે જ આ પરિસ્થિતિ બદલાશે તેવી અપેક્ષા આપણે રાખી શકીએ છીએ."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_3_3_d",
              "hi": "X1_3_3_d",
              "ka": "X1_3_3_d",
              "te": "X1_3_3_d",
              "or": "X1_3_3_d",
              "as": "X1_3_3_d",
              "gu": "X1_3_3_d"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Madhav say?",
              "hi": "माधव को क्या कहना चाहिए?",
              "ka": "ಅದಕ್ಕೆ ಮಾಧವ್ ಏನು ಹೇಳಬೇಕು?",
              "te": "మాధవ్ ఏం చెప్పాలి?",
              "or": "ମାଧବ କ’ଣ କହିବା ଉଚିତ୍?",
              "as": "মাধৱে কি কোৱা উচিত?",
              "gu": "માધવે શું કહેવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "But Sir, you are in charge of the hostel. Isn’t it your responsibility to ensure that we have a comfortable stay here? Do you know how important the studies are in 10th standard?",
              "hi": "लेकिन महोदय, आप छात्रावास के वॉर्डन  हैं। क्या यह सुनिश्चित करना आपका दायित्व नहीं है कि हमारे लिए यहां रहना सुविधाजनक रहे? क्या आप जानते हैं कि दसवीं कक्षा में अध्ययन करना कितना महत्वपूर्ण है?",
              "ka": "ಆದರೆ ಸಾರ್ ನೀವು ಹಾಸ್ಟೆಲ್ ಉಸ್ತುವಾರಿ ವಹಿಸಿದ್ದೀರಿ. ನಾವು ಇಲ್ಲಿ ಆರಾಮದಾಯಕ ವಾಸ್ತವ್ಯವನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುವುದು ನಿಮ್ಮ ಜವಾಬ್ದಾರಿಯಲ್ಲವೇ? 10 ನೇ ತರಗತಿಯಲ್ಲಿನ ಅಧ್ಯಯನಗಳು ಎಷ್ಟು ಮುಖ್ಯವೆಂದು ನಿಮಗೆ ತಿಳಿದಿಲ್ಲವೇ?",
              "te": "కానీ సార్, మీరు హాస్టల్ బాధ్యతలు నిర్వహిస్తున్నారు. మేము ఇక్కడ సుఖంగా ఉండేలా చూసుకోవడం మీ బాధ్యత కాదా? 10వ తరగతిలో చదువు ఎంత ముఖ్యమో తెలుసా?",
              "or": "କିନ୍ତୁ ସାର୍, ଆପଣ ହଷ୍ଟେଲର ଦାୟିତ୍ୱରେ ଅଛନ୍ତି | ଆମର ଏଠାରେ \n ଆରାମଦାୟକ ରହିବା ନିଶ୍ଚିତ କରିବା ତୁମର ଦାୟିତ୍ଵ ନୁହେଁ କି? ଦଶମ ମାନାଙ୍କରେ ଅଧ୍ୟୟନ କେତେ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ତାହା ଆପଣ ଜାଣନ୍ତି କି?",
              "as": "কিন্তু ছাৰ, আপুনি হোষ্টেলৰ দায়িত্বত আছে। আমি ইয়াত ভালদৰে থকাটো নিশ্চিত কৰাটো আপোনাৰ দায়িত্ব নহয় নেকি? আপুনি জানেনে যে ১০ম শ্ৰেণীৰ অধ্যয়নবোৰ কিমান গুৰুত্বপূৰ্ণ?",
              "gu": "પણ સાહેબ, તમારે હોસ્ટેલ સંભાળવાની જવાબદારી છે. શું એ સુનિશ્ચિત કરવાની જવાબદારી તમારી નથી કે અમારે અહીં આરામથી રહી શકીએ ? શું તમે જાણો છો કે 10મા ધોરણમાં અભ્યાસ કેટલું મહત્વપૂર્ણ છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Don’t the authorities understand how important studies are? We can’t waste time thinking about rats and bathrooms.",
              "hi": "क्या अधिकारियों को यह नहीं समझ आता कि अध्ययन कितना महत्वपूर्ण है? हम चूहों और बाथरूम के बारे में सोचने में समय व्यर्थ नहीं कर सकते।",
              "ka": "ಅಧ್ಯಯನಗಳು ಎಷ್ಟು ಮುಖ್ಯವೆಂದು ಅಧಿಕಾರಿಗಳಿಗೆ ಅರ್ಥವಾಗುವುದಿಲ್ಲವೇ? ಇಲಿಗಳು ಮತ್ತು ಸ್ನಾನಗೃಹಗಳ ಬಗ್ಗೆ ಯೋಚಿಸುವ ಮೂಲಕವೇ  ನಾವು ಸಮಯವನ್ನು ವ್ಯರ್ಥ ಮಾಡಲು ಆಗುವುದಿಲ್ಲ.",
              "te": "చదువులు ఎంత ముఖ్యమో అధికారులకు అర్థం కాదా? మనం ఎలుకలు మరియు బాత్‌రూమ్‌ల గురించి ఆలోచిస్తూ సమయాన్ని వృథా చేయలేము కదా.",
              "or": "ଅଧ୍ୟୟନ କେତେ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ତାହା କର୍ତ୍ତୃପକ୍ଷ ବୁଝନ୍ତି ନାହିଁ? ମୂଷା ଏବଂ \n ବାଥରୁମ ବିଷୟରେ ଚିନ୍ତା କରିବାକୁ ଆମେ ସମୟ ନଷ୍ଟ କରିପାରିବୁ ନାହିଁ |",
              "as": "অধ্যয়নবোৰ কিমান গুৰুত্বপূৰ্ণ সেয়া কৰ্তৃপক্ষই বুজি নাপায় নেকি? আমি নিগনি আৰু বাথৰুমৰ বিষয়ে চিন্তা কৰি সময় অপচয় কৰিব নোৱাৰোঁ।",
              "gu": "શું સત્તાધીશો સમજતા નથી કે અભ્યાસ કેટલો મહત્વનો છે? આપણે ઉંદરો અને બાથરૂમ વિશે વિચારવામાં સમય બગાડી શકતા નથી."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_3_3",
              "hi": "X1_3_3",
              "ka": "X1_3_3",
              "te": "X1_3_3",
              "or": "X1_3_3",
              "as": "X1_3_3",
              "gu": "X1_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Don’t talk about the importance of studies to me, Madhav, I see you wasting most of your time watching movies. If you want better facilities, go write a letter to the authority yourself.  Let me also see how you can solve this problem.",
              "hi": "अध्ययन के महत्व के बारे में मुझसे बात न करो, माधव, मैं देखता हूँ कि तुम अपना अधिकांश समय फिल्मों को देखकर व्यर्थ करते हो। यदि तुम बेहतर सुविधाएं चाहते हो, तो प्राधिकरण को एक पत्र लिखो। मैं देखता हूँ कि तुम इस समस्या को कैसे हल कर सकते हो।",
              "ka": "ನನ್ನ ಬಳಿ ಅಧ್ಯಯನದ ಪ್ರಾಮುಖ್ಯತೆಯ ಬಗ್ಗೆ ಮಾತನಾಡಬೇಡ, ಮಾಧವ್, ನೀವು ಚಲನಚಿತ್ರಗಳನ್ನು ನೋಡುವುದರಲ್ಲಿ ಹೆಚ್ಚಿನ ಸಮಯವನ್ನು ವ್ಯರ್ಥ ಮಾಡುವುದನ್ನು ನಾನು ನೋಡಿದ್ದೇನೆ. ನಿಮಗೆ ಉತ್ತಮ ಸೌಲಭ್ಯಗಳು ಬೇಕಿದ್ದರೆ ನೀವೇ ಹೋಗಿ ಪ್ರಾಧಿಕಾರಕ್ಕೆ ಪತ್ರ ಬರೆಯಿರಿ. ನೀವು ಈ ಸಮಸ್ಯೆಯನ್ನು ಹೇಗೆ ಪರಿಹರಿಸಬಹುದು ಎನ್ನುವುದನ್ನು ನಾನೂ ಸಹಾ ನೋಡುತ್ತೇನೆ.",
              "te": "నాతో చదువుల ప్రాముఖ్యత గురించి మాట్లాడకు మాధవ్, నువ్వు సినిమాలు చూసి ఎక్కువ సమయం వృధా చేసుకోవడం చూస్తున్నాను. నీకు మెరుగైన సౌకర్యాలు కావాలంటే, నువ్వే వెళ్లి స్వయంగా అధికారులకు లేఖ రాయి. నువ్వు ఈ సమస్యను ఎలా పరిష్కరిస్తావో నేను కూడా చూస్తాను.",
              "or": "ଅଧ୍ୟୟନର ମହତ୍ଵ ବିଷୟରେ ମୋ ସହ କଥାବାର୍ତ୍ତା କର ନାହିଁ,\n  ମାଧବ, ମୁଁ ଦେଖୁଛି ତୁମେ ଅଧିକାଂଶ ସମୟ ଚଳଚ୍ଚିତ୍ର ଦେଖିବାରେ ନଷ୍ଟ କରୁଛ | ଯଦି ଆପଣ ଉନ୍ନତ ସୁବିଧା ଚାହୁଁଛନ୍ତି, ତେବେ ନିଜେ କର୍ତ୍ତୃପକ୍ଷଙ୍କୁ ଏକ ଚିଠି ଲେଖନ୍ତୁ | ମୁଁ ମଧ୍ୟ ଦେଖେ ଆପଣ ଏହି ସମସ୍ୟାର କିପରି ସମାଧାନ କରିପାରିବେ",
              "as": "মোৰ আগত অধ্যয়নৰ গুৰুত্বৰ বিষয়ে কথা নক'বা, মাধৱ, মই দেখিছো তুমি তোমাৰ বেছিভাগ সময় চিনেমা চোৱাত অপচয় কৰা। যদি তুমি উন্নত সুবিধা বিচাৰা, কৰ্তৃপক্ষলৈ নিজে এখন পত্ৰ লিখা। মইও চাওঁ তুমি কেনেকৈ এই সমস্যাটোৰ সমাধান কৰিব পাৰা।",
              "gu": "અભ્યાસનું મહત્વ શું છે તેની મને વાત ના કર, માધવ, મેં જોયું છે  કે તું ફિલ્મો જોવામાં તારો મોટાભાગનો સમય બગાડે છે. જો તને વધુ સારી સુવિધાઓ જોઈતી હોય તો જાતે જ અધિકારીને પત્ર લખ. મારે પણ જોવું છે કે તું આ સમસ્યાને કેવી રીતે હલ કરી શકે છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_3_4",
              "hi": "X1_3_4",
              "ka": "X1_3_4",
              "te": "X1_3_4",
              "or": "X1_3_4",
              "as": "X1_3_4",
              "gu": "X1_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Badri and Kuldeep hear the warden talking loudly and rush into the room to save Madhav before the matter gets too serious.",
              "hi": "बद्री और कुलदीप वार्डन के जोर - जोर से बातें  करने के शोर को सुनकर और इस मामले को गंभीर होने से पहले माधव को बचाने के लिए कमरे में भाग कर आते हैं।",
              "ka": "ಬದ್ರಿ ಮತ್ತು ಕುಲದೀಪ್ ವಾರ್ಡನ್ ಜೋರಾಗಿ ಮಾತನಾಡುವುದನ್ನು ಕೇಳಿಸಿಕೊಂಡರು ಮತ್ತು ವಿಷಯ ತುಂಬಾ ಗಂಭೀರವಾಗುವ ಮೊದಲು ಮಾಧವನನ್ನು ರಕ್ಷಿಸಲು ಕೋಣೆಯ ಒಳಗಡೆಗೆ ಧಾವಿಸಿದರು.",
              "te": "బద్రీ మరియు కుల్దీప్ వార్డెన్ బిగ్గరగా మాట్లాడటం విని, విషయం తీవ్రం అవ్వకముందే మాధవ్‌ను రక్షించడానికి గదిలోకి పరుగెత్తుతారు.",
              "or": "ବଦ୍ରି ଏବଂ କୁଲଦୀପ ୱାର୍ଡେନର ଉଚ୍ଚ ସ୍ୱରରେ କଥା ହେଉଥିବା କଥା\n  ଶୁଣନ୍ତି ଏବଂ ମାମଲାଟି ଗୁରୁତର ହେବା ପୂର୍ବରୁ ମାଧବଙ୍କୁ ବଞ୍ଚାଇବା ପାଇଁ କୋଠରୀକୁ ଦୌଡନ୍ତି |",
              "as": "বদ্ৰী আৰু কুলদীপে ৱাৰ্ডেনক জোৰেৰে কথা কোৱা শুনিলে আৰু বিষয়টো অত্যাধিক গুৰুতৰ হোৱাৰ আগতে মাধৱক বচাবলৈ কোঠাটোলৈ দৌৰ মাৰে।",
              "gu": "બદ્રી અને કુલદીપ વોર્ડનને જોરથી વાત કરતા સાંભળે છે  મામલો વધુ ગંભીર બને કે તે પહેલા માધવને બચાવવા માટે રૂમમાં દોડી જાય છે "
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_4_1",
              "hi": "X1_4_1",
              "ka": "X1_4_1",
              "te": "X1_4_1",
              "or": "X1_4_1",
              "as": "X1_4_1",
              "gu": "X1_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They decide to help Madhav write a complaint letter to the authorities. Even though nothing changes, Madhav is happy that he tried.",
              "hi": "वे अधिकारियों को शिकायत पत्र लिखने में माधव की सहायता करने का निश्चय करते हैं। हालांकि कुछ भी नहीं बदलता है, माधव खुश है कि उसने कोशिश की।",
              "ka": "ಮಾಧವ್ ಅಧಿಕಾರಿಗಳಿಗೆ ಇದರ ಬಗ್ಗೆ ದೂರು ಪತ್ರ ಬರೆಯಲು ಸಹಾಯ ಮಾಡಲು ಅವರು ನಿರ್ಧರಿಸಿದರು. ಏನೂ ಬದಲಾಗದಿದ್ದರೂ, ಮಾಧವ್ ತಾನು ಪ್ರಯತ್ನಿಸಿದ್ದಕ್ಕೆ ಸಂತೋಷಗೊಂಡಿದ್ದಾನೆ.",
              "te": "అధికారులకు ఫిర్యాదు లేఖ రాయడానికి మాధవ్‌కు సహాయం చేయాలని వారు నిర్ణయించుకున్నారు. ఏమీ మారకపోయినా కూడా, మాధవ్ ప్రయత్నించినందుకు సంతోషంగా ఉన్నాడు.",
              "or": "ସେମାନେ ମାଧବଙ୍କୁ କର୍ତ୍ତୃପକ୍ଷଙ୍କୁ ଅଭିଯୋଗ ପତ୍ର ଲେଖିବାରେ ସାହାଯ୍ୟ\n  କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି। ଯଦିଓ କିଛି ପରିବର୍ତ୍ତନ ହୁଏ ନାହିଁ, ସେ ଚେଷ୍ଟା କରି ମାଧବ ଖୁସି |",
              "as": "তেওঁলোকে মাধৱক কৰ্তৃপক্ষলৈ অভিযোগ-পত্ৰ লিখাত সহায় কৰাৰ সিদ্ধান্ত লয়। যদিও একো সলনি নহয়, মাধৱ সুখী যে সি চেষ্টা কৰিছিল।",
              "gu": "તેઓ માધવને અધિકારીઓને ફરિયાદ પત્ર લખવામાં મદદ કરવાનું નક્કી કરે છે. કંઈ બદલાતું ન હોવા છતાં, માધવ ખુશ છે કે તેણે પ્રયત્ન કર્યો."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_4_2",
              "hi": "X1_4_2",
              "ka": "X1_4_2",
              "te": "X1_4_2",
              "or": "X1_4_2",
              "as": "X1_4_2",
              "gu": "X1_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Kuldeep, Badri and Madhav get through their 10th standard board exams and support each other through the 11th and 12th.",
              "hi": "कुलदीप, बद्री और माधव अपनी दसवीं कक्षा की बोर्ड परीक्षाओं को उत्तीर्ण करते हैं और ग्यारहवीं और बारहवीं कक्षा में एक-दूसरे को सहयोग करते हैं।",
              "ka": "ಕುಲದೀಪ್, ಬದ್ರಿ ಮತ್ತು ಮಾಧವ್ ತಮ್ಮ 10 ನೇ ತರಗತಿಯ ಬೋರ್ಡ್ ಪರೀಕ್ಷೆಗಳನ್ನು ಮುಗಿಸುತ್ತಾರೆ ಮತ್ತು 11 ಮತ್ತು 12 ನೇ ತರಗತಿಗಳಲ್ಲಿಯೂ ಒಬ್ಬರಿಗೊಬ್ಬರು ಪರಸ್ಪರ ಬೆಂಬಲಿಸುತ್ತಾರೆ.",
              "te": "కుల్దీప్, బద్రీ మరియు మాధవ్ వారి 10వ తరగతి బోర్డ్ పరీక్షలలో ఉత్తీర్ణత సాధించారు మరియు 11వ మరియు 12వ తరగతి వరకు ఒకరికొకరు సహాయం చేసుకుంటారు.",
              "or": "କୁଲଦୀପ, ବଦ୍ରି ଏବଂ ମାଧବ ସେମାନଙ୍କର ଦଶମ ମାନକ ବୋର୍ଡ ପରୀକ୍ଷାରେ \n ଉତ୍ତୀର୍ଣ୍ଣ ହୁଅନ୍ତି ଏବଂ ଏକାଦଶ ଏବଂ ଦ୍ୱାଦଶ ମାଧ୍ୟମରେ ପରସ୍ପରକୁ ସମର୍ଥନ କରନ୍ତି |",
              "as": "কুলদীপ, বদ্ৰী আৰু মাধৱে তেওঁলোকৰ ১০ম শ্ৰেণীৰ ব'ৰ্ড পৰীক্ষা পাৰ কৰে আৰু একাদশ আৰু দ্বাদশ শ্ৰেণীলৈকে ইজনে সিজনক সমৰ্থন কৰে।",
              "gu": "કુલદીપ, બદ્રી અને માધવ તેમની 10મા ધોરણની બોર્ડની પરીક્ષાઓમાંથી પસાર થાય છે અને 11મા અને 12મા ધોરણમાં એકબીજાને ટેકો આપે છે."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_4_3//",
              "hi": "X1_4_3 //",
              "ka": "X1_4_3//",
              "te": "X1_4_3//",
              "or": "X1_4_3//",
              "as": "X1_4_3//",
              "gu": "X1_4_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୁଲଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Badri",
              "hi": "बद्री",
              "ka": "ಬದ್ರಿ",
              "te": "బద్రి",
              "or": "ବଦ୍ରି",
              "as": "বদ্ৰী",
              "gu": "બદ્રી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Why do we have to make these decisions every two years? As if selecting subjects in school wasn’t difficult enough, now I have to choose what to study after college.",
              "hi": "हमें इन निर्णयों को हर दो साल में क्यों लेना है? जैसे कि स्कूल में विषयों का चयन करना कठिन नहीं था, अब मुझे चुनना होगा कि कॉलेज के बाद क्या अध्ययन करना है।",
              "ka": "ಪ್ರತಿ ಎರಡು ವರ್ಷಗಳಿಗೊಮ್ಮೆ ನಾವು ಈ ನಿರ್ಧಾರಗಳನ್ನು ಏಕೆ ತೆಗೆದುಕೊಳ್ಳಬೇಕು? ಶಾಲೆಯಲ್ಲಿ ವಿಷಯಗಳ ಆಯ್ಕೆ ಬಗ್ಗೆ ಕಷ್ಟ ಪಟ್ಟಿದ್ದು ಅಲ್ಲದೇ , ಈಗ ನಾನು ಕಾಲೇಜು ನಂತರ ಏನು ಓದಬೇಕೆಂದು ಆಯ್ಕೆ ಮಾಡಬೇಕಾಗಿದೆ",
              "te": "ప్రతి రెండేళ్లకొకసారి ఈ నిర్ణయాలు ఎందుకు తీసుకోవాలి? స్కూల్‌లో సబ్జెక్టులను ఎంచుకోవడమే కష్టం అంటే, ఇప్పుడు నేను కాలేజీ తర్వాత ఏమి చదవాలో ఎంచుకోవాలి.",
              "or": "ଆମକୁ ପ୍ରତି ଦୁଇ ବର୍ଷରେ କାହିଁକି ଏହି ନିଷ୍ପତ୍ତି ନେବାକୁ ପଡିବ? ଯେପରି\n  ବିଦ୍ୟାଳୟରେ ବିଷୟ ଚୟନ କରିବା କଷ୍ଟସାଧ୍ୟ ନଥିଲା, ବର୍ତ୍ତମାନ ମୋତେ କଲେଜ ପରେ କ’ଣ ପଢିବାକୁ ହେବ ତାହା ବାଛିବାକୁ ପଡିବ |",
              "as": "আমি প্ৰতি দুবছৰত এই সিদ্ধান্তবোৰ কিয় ল'ব লগা হয়? যেন বিদ্যালয়ত বিষয় বাছনি কৰাটো যথেষ্ট কঠিন নাছিল, এতিয়া মই কলেজৰ পিছত কি পঢ়িব লাগে বাছনি কৰিব লাগিব।",
              "gu": "શા માટે આપણે દર બે વર્ષે આ નિર્ણયો લેવા પડે છે? જેમ કે શાળામાં વિષયો પસંદ કરવાનું એટલું મુશ્કેલ નહોતું, હવે મારે કૉલેજ પછી શું ભણવું તે પસંદ કરવાનું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "That’s how it works, every year you choose a more specific subject to study. That’s how we slowly become experts.",
              "hi": "यह इसी तरह काम करता है, हर साल आप अध्ययन के लिए एक और विशिष्ट विषय चुनते हैं। इस तरह हम धीरे-धीरे विशेषज्ञ बन जाते हैं।",
              "ka": "ಅದು ಹಾಗೆಯೇ ಕಾರ್ಯನಿರ್ವಹಿಸುವುದು, ಪ್ರತಿ ವರ್ಷ ನೀವು ಅಧ್ಯಯನ ಮಾಡಲು ಹೆಚ್ಚು ನಿರ್ದಿಷ್ಟ ವಿಷಯವನ್ನು ಆಯ್ಕೆ ಮಾಡಿಕೊಳ್ಳುತ್ತೀರಾ. ಹೀಗಾಗಿ ನಾವು ನಿಧಾನವಾಗಿ ಪರಿಣಿತರಾಗುತ್ತೇವೆ.",
              "te": "ఇది ఇలానే ఉంటుంది, ప్రతి సంవత్సరం మీరు అధ్యయనం చేయడానికి మరింత నిర్దిష్టమైన సబ్జెక్ట్ ని ఎంచుకోవాలి. అలా అయితేనే మనం నిదానంగా నిపుణులం అవుతాము.",
              "or": "ଏହା ଏହିପରି କାର୍ଯ୍ୟ କରେ, ପ୍ରତିବର୍ଷ ଆପଣ ଅଧ୍ୟୟନ ପାଇଁ ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ\n  ବିଷୟ ବାଛନ୍ତି | ଏହିପରି ଭାବରେ ଆମେ ଧୀରେ ଧୀରେ ବିଶେଷଜ୍ଞ ହେବା |",
              "as": "এনেদৰেই কৰিব লাগিব, প্ৰতি বছৰে তুমি অধ্যয়নৰ বাবে অধিক নিৰ্দিষ্ট বিষয় এটা বাছনি কৰিব লাগিব। এনেদৰেই আমি লাহে লাহে পাৰ্গত হৈ পৰিছোঁ।",
              "gu": "આ રીતે તે કાર્ય કરે છે, દર વર્ષે તમે અભ્યાસ માટે વધુ ચોક્કસ વિષય પસંદ કરો છો. આ રીતે આપણે ધીમે ધીમે નિષ્ણાત બનીએ છીએ."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_4_4////",
              "hi": "X1_4_4 //////",
              "ka": "X1_4_4////",
              "te": "X1_4_4////",
              "or": "X1_4_4////",
              "as": "X1_4_4////",
              "gu": "X1_4_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "You’re right Badri. I understand, but there are so many options! How can I choose?",
              "hi": "तुम सही कह रहे हो बद्री। मैं समझता हूं, लेकिन बहुत सारे विकल्प हैं! मैं कैसे चुन सकता हूं?",
              "ka": "ನೀನು ಹೇಳಿದ್ದು ಸರಿ ಬದ್ರಿ. ನಾನು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೇನೆ, ಆದರೆ ಹಲವು ಆಯ್ಕೆಗಳಿವೆ! ನಾನು ಯಾವುದನ್ನು ಆಯ್ಕೆ ಮಾಡಬಹುದು?",
              "te": "నువ్వు చెప్పింది నిజమే బద్రి. నాకు అర్థం అయింది, కానీ చాలా ఎంపికలు ఉన్నాయి! నేను ఎలా ఎంచుకోగలను?",
              "or": "ତୁମେ ଠିକ୍ ବଦ୍ରି | ମୁଁ ବୁିପାରୁଛି, କିନ୍ତୁ ସେଠାରେ ଅନେକ ବିକଳ୍ପ ଅଛି!\n  ମୁଁ କିପରି ବାଛିବି?",
              "as": "তুমি ঠিকেই কৈছা বদ্ৰী। মই বুজি পাইছোঁ, কিন্তু বহুতো বিকল্প আছে! মই কি বাছনি কৰিম?",
              "gu": "તમે સાચા છો બદ્રી. હું સમજું છું, પરંતુ ઘણા બધા વિકલ્પો છે! હું કેવી રીતે પસંદ કરી શકું?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Please professor Badri tell us how you made up your mind.",
              "hi": "कृपया प्रोफेसर बद्री हमें बताएं कि तुमने अपना मन कैसे बनाया।",
              "ka": "ಪ್ರೊಫೆಸರ್ ಬದ್ರಿ, ನೀನು ಹೇಗೆ ಮನಸ್ಸು ಮಾಡಿದ್ದೀಯಾ ಎನ್ನುವುದನ್ನು ನಮಗೆ ತಿಳಿಸುತ್ತೀಯಾ.",
              "te": "ప్రొఫెసర్ బద్రి గారు, దయచేసి మీరు ఎలా నిర్ణయం తీసుకున్నారో మాకు చెప్పండి.",
              "or": "ଦୟାକରି ପ୍ରଫେସର ବଦ୍ରି ଆମକୁ କୁହ ଯେ ତୁମେ କିପରି ମନ ପ୍ରସ୍ତୁତ କଲ |",
              "as": "অনুগ্ৰহ কৰি অধ্যাপক বদ্ৰী আমাক কওক যে আপুনি কেনেকৈ আপোনাৰ মন স্থিৰ কৰিলে।",
              "gu": "કૃપા કરીને પ્રોફેસર બદ્રી અમને જણાવો કે તમે તમારું મન કેવી રીતે બનાવ્યું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "It’s simple, I want to become a teacher someday and I like literature. So I feel a background in English will help. That’s what I’m going to study in college.",
              "hi": "यह सहज है, मैं शिक्षक बनना चाहता हूं और मुझे साहित्य पसंद है। तो मुझे लगता है कि अंग्रेजी विषय की पृष्ठभूमि मेरी सहायता करेगी। मैं कॉलेज में अध्ययन करने जा रहा हूं।",
              "ka": "ಇದು ಸರಳವಾಗಿದೆ, ನಾನು ಒಂದು ದಿನ ಶಿಕ್ಷಕನಾಗಲು ಬಯಸುತ್ತೇನೆ ಮತ್ತು ನಾನು ಸಾಹಿತ್ಯವನ್ನು ಇಷ್ಟಪಡುತ್ತೇನೆ. ಹಾಗಾಗಿ ಇಂಗ್ಲಿಷ್‌ ಹಿನ್ನೆಲೆಯು ನನಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ಅದನ್ನೇ ನಾನು ಕಾಲೇಜಿನಲ್ಲಿ ಓದುತ್ತೇನೆ.",
              "te": "ఇది చాలా సులభం, నేను ఏదో ఒక రోజు ఉపాధ్యాయుడిని కావాలనుకుంటున్నాను మరియు నాకు సాహిత్యం అంటే ఇష్టం. కాబట్టి ఇంగ్లీష్ బాక్గ్రౌండ్ తీసుకుంటే సహాయపడుతుందని నేను భావిస్తున్నాను. నేను కాలేజీలో అదే చదవబోతున్నాను.",
              "or": "ଏହା ସରଳ, ମୁଁ ଦିନେ ଶିକ୍ଷକ ହେବାକୁ ଚାହେଁ ଏବଂ ମୁଁ ସାହିତ୍ୟ ପସନ୍ଦ \n କରେ | ତେଣୁ ମୁଁ ଅନୁଭବ କରୁଛି ଇଂରାଜୀରେ ଏକ ପୃଷ୍ଠଭୂମି ସାହାଯ୍ୟ କରିବ | ମୁଁ ତାହା କଲେଜରେ ପଢିବାକୁ ଯାଉଛି |",
              "as": "একেবাৰে সহজ, মই এদিন শিক্ষক হ'ব বিচাৰোঁ আৰু মই সাহিত্য ভাল পাওঁ। গতিকে মই অনুভৱ কৰিলো যে ইংৰাজীৰ পৃষ্ঠভূমিয়ে সহায় কৰিব। মই কলেজত সেইটোৱেই পঢ়িবলৈ গৈ আছোঁ।",
              "gu": "તે સરળ છે, મારે કોઈ દિવસ શિક્ષક બનવું છે અને મને સાહિત્ય ગમે છે. તેથી મને લાગે છે કે અંગ્રેજીમાં પૃષ્ઠભૂમિ મદદ કરશે. એ જ હું કોલેજમાં ભણવા જઈ રહ્યો છું."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_4_5",
              "hi": "X1_4_5",
              "ka": "X1_4_5",
              "te": "X1_4_5",
              "or": "X1_4_5",
              "as": "X1_4_5",
              "gu": "X1_4_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav and Kuldeep don’t feel strongly about any profession right now but know how important it is for them to study further. They both decide to apply for the same course as Badri.",
              "hi": "माधव और कुलदीप अभी किसी भी प्रोफेशन के विषय में सुदृढ़ नहीं हैं लेकिन जानते हैं कि उनके लिए आगे का अध्ययन करना कितना महत्वपूर्ण है। वे दोनों बद्री के समान उसी कोर्स के लिए आवेदन करने का निश्चय करते हैं।",
              "ka": "ಮಾಧವ್ ಮತ್ತು ಕುಲದೀಪ್ ಅವರು ಇದೀಗ ಯಾವುದೇ ವೃತ್ತಿಯ ಬಗ್ಗೆ ತೀವ್ರವಾಗಿ ಭಾವಣೆಯನ್ನು ಹೊಂದಿಲ್ಲ. ಆದರೆ ಅವರಿಗೆ ಮುಂದೆ ಅಧ್ಯಯನ ಮಾಡುವುದು ಎಷ್ಟು ಮುಖ್ಯ ಎಂದು ತಿಳಿದಿದೆ. ಇಬ್ಬರೂ ಬದ್ರಿ ತೆಗೆದುಕೊಳ್ಳುತ್ತಿರುವ  ಕೋರ್ಸ್‌ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾರೆ.",
              "te": "మాధవ్ మరియు కుల్దీప్ కి ప్రస్తుతం ఏ వృత్తి గురించి ఎక్కువ అవగాహన లేదు, కానీ వారు మరింత చదవడం ఎంత ముఖ్యమో తెలుసు. వారిద్దరూ బద్రి చేసిన కోర్సుకు అప్లై చేసుకోవాలని నిర్ణయించుకున్నారు.",
              "or": "ମାଧବ ଏବଂ କୁଲଦୀପ ବର୍ତ୍ତମାନ କ୍କୌଣସି ବୃତ୍ତି ବିଷୟରେ ଦୃଢ ଅନୁଭବ କରନ୍ତି \n ନାହିଁ କିନ୍ତୁ ଆଗକୁ ଅଧ୍ୟୟନ କରିବା ସେମାନଙ୍କ ପାଇଁ କେତେ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ତାହା ଜାଣ | ଦୁହେଁ ବଦ୍ରିଙ୍କ ପରି ସମାନ ପାଠ୍ୟକ୍ରମ ପାଇଁ ଆବେଦନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
              "as": "মাধৱ আৰু কুলদীপে এতিয়াই কোনো বৃত্তিৰ বিষয়ে দৃঢ়ভাৱে ভবা নাই কিন্তু জানে যে তেওঁলোকৰ বাবে অধিক অধ্যয়ন কৰাটো কিমান গুৰুত্বপূৰ্ণ। তেওঁলোক দুয়োজনে বদ্ৰীৰ দৰে একে পাঠ্যক্ৰমৰ বাবে আবেদন কৰাৰ সিদ্ধান্ত লয়।",
              "gu": "માધવ અને કુલદીપ અત્યારે કોઈ પણ વ્યવસાય વિશે દ્રઢપણે વિચારતા  નથી પરંતુ તેઓ જાણે છે કે આગળ ભણવું તેમના માટે કેટલું મહત્વનું છે. તેઓ બંને બદ્રી જેવા જ કોર્સ માટે અરજી કરવાનું નક્કી કરે છે."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_4_6",
              "hi": "X1_4_6",
              "ka": "X1_4_6",
              "te": "X1_4_6",
              "or": "X1_4_6",
              "as": "X1_4_6",
              "gu": "X1_4_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Since Madhav gets a minimal amount of money from his father, he decides to apply for a scholarship grant. They fund the fees for students whose parent's annual income is low to help them through college.",
              "hi": "चूंकि माधव को अपने पिता से कम धनराशि मिलती है, इसलिए वह छात्रवृत्ति अनुदान के लिए आवेदन करने का निश्चय करता है। वे कॉलेज की शिक्षा में सहायता करने के लिए उन छात्रों की फीस/ शुल्क देते हैं, जिनके माता-पिता की वार्षिक आय कम है।",
              "ka": "ಮಾಧವ್ ತನ್ನ ತಂದೆಯಿಂದ ಕನಿಷ್ಠ ಮೊತ್ತದ ಹಣವನ್ನು ಪಡೆಯುವುದರಿಂದ, ಅವನು ವಿದ್ಯಾರ್ಥಿವೇತನದ ಅನುದಾನಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಪೋಷಕರ ವಾರ್ಷಿಕ ಆದಾಯವು ಕಡಿಮೆ ಇರುವ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಕಾಲೇಜಿನ ಮೂಲಕ ಸಹಾಯ ಮಾಡಲು ಅವರು ಶುಲ್ಕವನ್ನು ನೀಡುತ್ತಾರೆ.",
              "te": "మాధవ్ కు తన తండ్రి కొంచమే డబ్బు పంపుతున్నందున, అతను స్కాలర్‌షిప్ మంజూరు కోసం అప్లై చేయాలని నిర్ణయించుకున్నాడు. తల్లిదండ్రుల వార్షిక ఆదాయం తక్కువగా ఉన్న విద్యార్థులకు కళాశాల చదువు పూర్తి చేయడానికి వారు ఫీజులను అందజేస్తారు.",
              "or": "ଯେହେତୁ ମାଧବ ତାଙ୍କ ପିତାଙ୍କଠାରୁ ସର୍ବନିମ୍ନ ଟଙ୍କା ପାଇଥାନ୍ତି, ତେଣୁ \n ସେ ଛାତ୍ରବୃତ୍ତି ଅନୁଦାନ ପାଇଁ ଆବେଦନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ସେମାନେ ସେହି ଛାତ୍ରମାନଙ୍କ ପାଇଁ ଫି ପାଣ୍ଠି ଦିଅନ୍ତି ଯାହାର ପିତାମାତାଙ୍କ ବାର୍ଷିକ ଆୟ କମ୍ କଲେଜ ମାଧ୍ୟମରେ ସେମାନଙ୍କୁ ସାହାଯ୍ୟ କରେ |",
              "as": "যিহেতু মাধৱে তাৰ দেউতাকৰ পৰা বহুত কম ধন লাভ কৰে, সি বৃত্তি অনুদানৰ বাবে আবেদন কৰাৰ সিদ্ধান্ত লয়। তেওঁলোকে শিক্ষাৰ্থীসকলৰ কলেজীয়া শিক্ষাৰ বাবে মাচুল পুঁজি যোগান ধৰে যাৰ অভিভাৱকৰ বাৰ্ষিক উপাৰ্জন তেওঁলোকক সহায় কৰিবলৈ কম।",
              "gu": "માધવને તેના પિતા પાસેથી ન્યૂનતમ રકમ મળતી હોવાથી, તેણે શિષ્યવૃત્તિ અનુદાન માટે અરજી કરવાનું નક્કી કર્યું. તેઓ એવા વિદ્યાર્થીઓ માટે ફીનું ભંડોળ પૂરું પાડે છે કે જેમના માતા-પિતાની વાર્ષિક આવક ઓછી હોય તેઓને કૉલેજ દ્વારા મદદ મળે."
            }
          }
        ]
      },
      "scene2": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_1_1",
              "hi": "X2_1_1",
              "ka": "X2_1_1",
              "te": "X2_1_1",
              "or": "X2_1_1",
              "as": "X2_1_1",
              "gu": "X2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Badri takes up admission in BA in English in Lucknow. Madhav and Kuldeep don’t get admission for English so they take BA in History in the same college.",
              "hi": "अंग्रेजी में बीए करने के लिए बद्री लखनऊ में प्रवेश लेता है। माधव और कुलदीप को अंग्रेजी के लिए प्रवेश नहीं मिलता है, इसलिए वे उसी कॉलेज में इतिहास में बीए करने के लिए प्रवेश लेते हैं।",
              "ka": "ಬದ್ರಿ ಲಕ್ನೋದಲ್ಲಿ ಇಂಗ್ಲಿಷ್‌ನಲ್ಲಿ ಬಿಎಗೆ ಪ್ರವೇಶ ಪಡೆಯುತ್ತಾನೆ. ಮಾಧವ್ ಮತ್ತು ಕುಲದೀಪ್ ಇಂಗ್ಲಿಷ್‌ಗೆ ಪ್ರವೇಶ ಪಡೆಯುವುದಿಲ್ಲ ಆದ್ದರಿಂದ ಅವರು ಅದೇ ಕಾಲೇಜಿನಲ್ಲಿ ಇತಿಹಾಸದಲ್ಲಿ ಬಿಎ ತೆಗೆದುಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "బద్రి లక్నోలో ఇంగ్లీషులో BAలో అడ్మిషన్ తీసుకుంటాడు. మాధవ్ మరియు కుల్దీప్‌లకు ఇంగ్లీషులో అడ్మిషన్ లభించలేదు కాబట్టి వారు అదే కళాశాలలో హిస్టరీలో BA తీసుకుంటారు.",
              "or": "ବଦ୍ରି ଲକ୍ଷ୍ନୋ ରେ ଇଂରାଜୀରେ BA ରେ ଆଡମିଶନ ଗ୍ରହଣ କରନ୍ତି | ମାଧବ\n  ଏବଂ କୁଲଦୀପ ଇଂରାଜୀ ପାଇଁ ଆଡମିଶନ ପାଇବେ ନାହିଁ ତେଣୁ ସେମାନେ ସମାନ କଲେଜରେ ଇତିହାସରେ ବିଏ ନିଅନ୍ତି |",
              "as": "বদ্ৰীয়ে লক্ষ্ণৌত ইংৰাজীত BAত নামভৰ্তি কৰে। মাধৱ আৰু কুলদীপে ইংৰাজীৰ বাবে নামভৰ্তি নাপালে সেয়েহে তেওঁলোকে একেখন মহাবিদ্যালয়তে ইতিহাসত BA লয়।",
              "gu": "બદ્રીએ લખનઉ માં અંગ્રેજીમાં BAમાં એડમિશન લીધું. માધવ અને કુલદીપને અંગ્રેજી માટે એડમિશન મળતું નથી તેથી તેઓ એક જ કોલેજમાં ઈતિહાસમાં બીએ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_1_2",
              "hi": "X2_1_2",
              "ka": "X2_1_2",
              "te": "X2_1_2",
              "or": "X2_1_2",
              "as": "X2_1_2",
              "gu": "X2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav gets admission through scholarship and is happy not to depend on anyone for college fees.",
              "hi": "माधव को छात्रवृत्ति के माध्यम से प्रवेश मिलता है और खुश है कि कॉलेज फीस के लिए किसी पर निर्भर नहीं है।",
              "ka": "ಮಾಧವ್ ಸ್ಕಾಲರ್‌ಶಿಪ್ ಮೂಲಕ ಪ್ರವೇಶ ಪಡೆಯುತ್ತಾನೆ ಮತ್ತು ಕಾಲೇಜು ಶುಲ್ಕಕ್ಕಾಗಿ ಯಾರನ್ನೂ ಅವಲಂಬಿಸದೆ ಇರುವುದಕ್ಕಾಗಿ ಸಂತೋಷಪಡುತ್ತಾನೆ.",
              "te": "మాధవ్ స్కాలర్‌షిప్ ద్వారా అడ్మిషన్ పొందాడు మరియు కాలేజీ ఫీజుల కోసం ఎవరిపైనా ఆధారపడకుండా సంతోషంగా ఉన్నాడు.",
              "or": "ମାଧବ ଛାତ୍ରବୃତ୍ତି ମାଧ୍ୟମରେ ଆଡମିଶନ ପାଆନ୍ତି ଏବଂ କଲେଜ ଫି ପାଇଁ\n  କାହା ଉପରେ ନିର୍ଭର ନକରି ଖୁସି ହୁଅନ୍ତି |",
              "as": "মাধৱে বৃত্তিৰ জৰিয়তে নামভৰ্তি কৰে আৰু মহাবিদ্যালয়ৰ মাচুলৰ বাবে কাৰো ওপৰত নিৰ্ভৰ কৰিবলগীয়া নোহোৱা বাবে সুখী।",
              "gu": "માધવ શિષ્યવૃત્તિ દ્વારા પ્રવેશ મેળવે છે અને કૉલેજ ફી માટે કોઈના પર નિર્ભર ન રહેવાથી ખુશ છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_1_3",
              "hi": "X2_1_3",
              "ka": "X2_1_3",
              "te": "X2_1_3",
              "or": "X2_1_3",
              "as": "X2_1_3",
              "gu": "X2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "College is a new experience for all three of them. They find out that there is a lot that they can explore and experience.",
              "hi": "कॉलेज उन तीनों के लिए एक नया अनुभव है। उन्हें पता चलता है कि बहुत कुछ है जिसे वे खोज सकते हैं और अनुभव कर सकते हैं।",
              "ka": "ಮೂವರಿಗೂ ಕಾಲೇಜು ಒಂದು ಹೊಸ ಅನುಭವವಾಗಿದೆ. ಅನ್ವೇಷಿಸಲು ಮತ್ತು ಅನುಭವಿಸಲು ಬಹಳಷ್ಟು ಇದೆ ಎನ್ನುವುದು ಅವರ ಅರಿವಿಗೆ ಬರುತ್ತದೆ. ",
              "te": "ముగ్గురికీ కాలేజీ కొత్త అనుభవం. వారు చూడాల్సినవి చాలా ఉన్నాయని వారు కనుగొంటారు.",
              "or": "ଏହି ତିନିଜଣଙ୍କ ପାଇଁ କଲେଜ ଏକ ନୂତନ ଅନୁଭୂତି | ସେମାନେ ଜାଣିବାକୁ \n ପାଇଲେ ଯେ ବହୁତ କିଛି ଅଛି ଯାହା ସେମାନେ ଅନୁସନ୍ଧାନ କରିପାରିବେ ଏବଂ ଅନୁଭବ କରିପାରିବେ |",
              "as": "মহাবিদ্যালয় তেওঁলোক তিনিওজনৰ বাবে এটা নতুন অভিজ্ঞতা। তেওঁলোকে বিচাৰি উলিয়ায় যে তেওঁলোকে অন্বেষণ আৰু অভিজ্ঞতা লাভ কৰিব পৰা বহুতো বস্তু আছে।",
              "gu": "કોલેજ એ ત્રણેય માટે નવો અનુભવ છે. કે તેઓ જુએ છે કે ઘણું બધું છે જેને તેઓ નિહાળી અને અનુભવ કરી શકે છે "
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_1_4",
              "hi": "X2_1_4",
              "ka": "X2_1_4",
              "te": "X2_1_4",
              "or": "X2_1_4",
              "as": "X2_1_4",
              "gu": "X2_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav joins the movie review clubs and the cultural committee, whereas Kuldeep joins the NCC. Since they actively participate in different activities in college, they meet many new people and learn a lot.",
              "hi": "माधव फिल्म समीक्षा क्लब और सांस्कृतिक समिति में शामिल हो गया, जबकि कुलदीप एनसीसी में शामिल हो गया। चूंकि वे कॉलेज में विभिन्न गतिविधियों में सक्रिय रूप से भाग लेते हैं, इसलिए वे अनेक नए लोगों से मिलते हैं और बहुत कुछ सीखते हैं।",
              "ka": "ಮಾಧವ್ ಚಲನಚಿತ್ರ ವಿಮರ್ಶೆ ಕ್ಲಬ್‌ಗಳು ಮತ್ತು ಸಾಂಸ್ಕೃತಿಕ ಸಮಿತಿಗೆ ಸೇರುತ್ತಾನೆ, ಆದರೆ ಕುಲದೀಪ್ ಎನ್‌ಸಿಸಿಗೆ ಸೇರುತ್ತಾನೆ. ಕಾಲೇಜಿನಲ್ಲಿ ವಿವಿಧ ಚಟುವಟಿಕೆಗಳಲ್ಲಿ ಸಕ್ರಿಯವಾಗಿ ಭಾಗವಹಿಸುವುದರಿಂದ, ಅವರು ಅನೇಕ ಹೊಸ ಜನರನ್ನು ಭೇಟಿಯಾಗುತ್ತಾರೆ ಮತ್ತು ಬಹಳಷ್ಟು ಕಲಿಯುತ್ತಾರೆ.",
              "te": "మాధవ్ మూవీ రివ్యూ క్లబ్‌లు మరియు కల్చరల్ కమిటీలో చేరగా, కుల్దీప్ NCCలో చేరాడు. వారు కళాశాలలో వివిధ కార్యకలాపాలలో చురుకుగా పాల్గొంటారు కాబట్టి, వారు చాలా మంది కొత్త వ్యక్తులను కలుసుకుంటారు మరియు చాలా నేర్చుకుంటారు.",
              "or": "ମାଧବ ଚଳଚ୍ଚିତ୍ର ସମୀକ୍ଷା କ୍ଲବ ଏବଂ ସାଂସ୍କୃତିକ କମିଟିରେ ଯୋଗ ଦେଇଥିବାବେଳେ \n କୁଲଦୀପ ଏନସିସିରେ ଯୋଗ ଦେଇଛନ୍ତି। ଯେହେତୁ ସେମାନେ କଲେଜରେ ବିଭିନ୍ନ କାର୍ଯ୍ୟକଳାପରେ ସକ୍ରିୟ ଭାବରେ ଅଂଶଗ୍ରହଣ କରନ୍ତି, ସେମାନେ ଅନେକ ନୂତନ ଲୋକଙ୍କୁ ଭେଟନ୍ତି ଏବଂ ବହୁତ କିଛି ଶିଖନ୍ତି |",
              "as": "মাধৱে চলচ্চিত্ৰ সমীক্ষা ক্লাব আৰু সাংস্কৃতিক সমিতিত যোগদান কৰে, আনহাতে কুলদীপে NCCত যোগদান কৰে। যিহেতু তেওঁলোকে মহাবিদ্যালয়ত বিভিন্ন কাৰ্যকলাপত সক্ৰিয়ভাৱে অংশগ্ৰহণ কৰে, তেওঁলোকে বহুতো নতুন লোকক লগ পায় আৰু বহুতো শিকে।",
              "gu": "માધવ મૂવી રિવ્યુ ક્લબ અને સાંસ્કૃતિક સમિતિમાં જોડાય છે, જ્યારે કુલદીપ NCCમાં જોડાય છે. તેઓ કૉલેજમાં વિવિધ પ્રવૃત્તિઓમાં સક્રિયપણે ભાગ લેતા હોવાથી, તેઓ ઘણા નવા લોકોને મળે છે અને ઘણું શીખે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_2_1",
              "hi": "X2_2_1",
              "ka": "X2_2_1",
              "te": "X2_2_1",
              "or": "X2_2_1",
              "as": "X2_2_1",
              "gu": "X2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "During their annual college day fest, an IPS officer (Indian Police Services), an alumnus of the college, is coming to give a speech about her experiences of being in the police.",
              "hi": "अपने वार्षिक कॉलेज दिवस उत्सव के दौरान, कॉलेज की पूर्व छात्रा एक आईपीएस अधिकारी (भारतीय पुलिस सेवाएं), पुलिस के अपने अनुभवों के बारे में एक भाषण देने के लिए आ रही हैं।",
              "ka": "ಅವರ ವಾರ್ಷಿಕ ಕಾಲೇಜ್ ಡೇ ಫೆಸ್ಟ್‌ನಲ್ಲಿ, ಕಾಲೇಜಿನ ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಯಾಗಿರುವ ಐಪಿಎಸ್ ಅಧಿಕಾರಿ (ಭಾರತೀಯ ಪೊಲೀಸ್ ಸೇವೆಗಳು) ಅವರು ಪೋಲೀಸ್‌ ಇಲಾಖೆಯಲ್ಲಿನ ತನ್ನ ಅನುಭವಗಳ ಬಗ್ಗೆ ಭಾಷಣ ಮಾಡಲು ಬರುತ್ತಿದ್ದಾರೆ.",
              "te": "వారి వార్షిక కాలేజ్ డే ఫెస్ట్ సందర్భంగా, ఒక IPS అధికారి (ఇండియన్ పోలీస్ సర్వీసెస్), కళాశాల పూర్వ విద్యార్థి, పోలీసుగా తన అనుభవాల గురించి ప్రసంగం చేయడానికి వస్తున్నారు.",
              "or": "ସେମାନଙ୍କର ବାର୍ଷିକ କଲେଜ ଦିବସ ଉତ୍ସବରେ, ଆଇପିଏସ୍ ଅଧିକାରୀ\n  (ଭାରତୀୟ ପୋଲିସ୍ ସର୍ଭିସେସ୍), କଲେଜର ଆଲୁମନାସ୍, ପୋଲିସ୍ ରେ ରହିବାର ଅନୁଭୂତି ବିଷୟରେ ଏକ ଭାଷଣ ଦେବାକୁ ଆସୁଛନ୍ତି |",
              "as": "তেওঁলোকৰ বাৰ্ষিক মহাবিদ্যালয় দিৱস উৎসৱৰ সময়ত, মহাবিদ্যালয়খনৰ এগৰাকী প্ৰাক্তন ছাত্ৰ, এজন IPS বিষয়াই (ভাৰতীয় আৰক্ষী সেৱা) আৰক্ষীত থকাৰ অভিজ্ঞতাৰ বিষয়ে ভাষণ দিবলৈ আহে।",
              "gu": "તેમની કોલેજની વાર્ષિક ઉજવણી દરમિયાન, એક IPS અધિકારી (ભારતીય પોલીસ સેવાઓ), કૉલેજની ભૂતપૂર્વ વિદ્યાર્થી, પોલીસમાં હોવાના તેના અનુભવો વિશે વક્તવ્ય આપવા આવી રહી છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_2_2//",
              "hi": "X2_2_2 //",
              "ka": "X2_2_2//",
              "te": "X2_2_2//",
              "or": "X2_2_2//",
              "as": "X2_2_2//",
              "gu": "X2_2_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Badri",
              "hi": "बद्री",
              "ka": "ಬದ್ರಿ",
              "te": "బద్రి",
              "or": "ବଦ୍ରି",
              "as": "বদ্ৰী",
              "gu": "બદ્રી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Badri",
              "hi": "बद्री",
              "ka": "ಬದ್ರಿ",
              "te": "బద్రి",
              "or": "ବଦ୍ରି",
              "as": "বদ্ৰী",
              "gu": "બદ્રી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Finally, you both will hear a real police officer talk. Now you can come out from the movie world into the real world.",
              "hi": "अंततः, तुम दोनों एक असली पुलिस अधिकारी की बात सुनोगे। अब तुम फिल्मी दुनिया से बाहर वास्तविक दुनिया में आ सकते हो।",
              "ka": "ಅಂತಿಮವಾಗಿ, ನೀವಿಬ್ಬರೂ ನಿಜವಾದ ಪೊಲೀಸ್ ಅಧಿಕಾರಿಯ ಮಾತನ್ನು ಕೇಳುತ್ತೀರಿ. ಈಗ ನೀವು ಚಲನಚಿತ್ರ ಪ್ರಪಂಚದಿಂದ ನೈಜ ಪ್ರಪಂಚಕ್ಕೆ ಬರಬಹುದು.",
              "te": "చివరికి, ఇప్పుడు మీరిద్దరూ నిజమైన పోలీసు అధికారి మాటలు వింటారు. ఇప్పుడు మీరు సినిమా ప్రపంచం నుండి వాస్తవ ప్రపంచంలోకి రావచ్చు.",
              "or": "ଶେଷରେ, ତୁମେ ଦୁହେଁ ପ୍ରକୃତ ପୋଲିସ୍ ଅଧିକାରୀଙ୍କ କଥା ଶୁଣିବ | \n ବର୍ତ୍ତମାନ ଆପଣ ଚଳଚ୍ଚିତ୍ର ଜଗତରୁ ବାସ୍ତବ ଦୁନିଆକୁ ଆସିପାରିବେ |",
              "as": "অৱশেষত, তোমালোক দুয়োজনে এজন প্ৰকৃত আৰক্ষী বিষয়াৰ কথা শুনিবা। এতিয়া তুমি চলচ্চিত্ৰ জগতৰ পৰা বাস্তৱ পৃথিৱীলৈ ওলাই আহিব পাৰিবা।",
              "gu": "હવે , તમે બંને એક વાસ્તવિક પોલીસ અધિકારીની વાત સાંભળશો. હવે તમે ફિલ્મી દુનિયામાંથી વાસ્તવિક દુનિયામાં આવી શકશો "
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "It doesn't matter how the police officer is in reality; Singham will always be Singham, and nothing can change that.",
              "hi": "इससे कोई फर्क नहीं पड़ता कि पुलिस अधिकारी वास्तविकता में कैसे है; सिंघम हमेशा सिंघम होगा, और कुछ भी नहीं बदल सकता है।",
              "ka": "ವಾಸ್ತವದಲ್ಲಿ ಪೊಲೀಸ್ ಅಧಿಕಾರಿ ಹೇಗಿರುತ್ತಾರೆ ಎನ್ನುವುದು ಮುಖ್ಯವಲ್ಲ; ಸಿಂಘಮ್ ಯಾವಾಗಲೂ ಸಿಂಗಮ್ ಆಗಿರುತ್ತಾನೆ ಮತ್ತು ಯಾವುದೂ ಅದನ್ನು ಬದಲಾಯಿಸಾಲು ಸಾಧ್ಯವಿಲ್ಲ.",
              "te": "నిజమైన పోలీసు అధికారి ఎలా ఉన్నారనేది ముఖ్యం కాదు; సింగం ఎప్పుడూ సింగంగానే ఉంటాడు మరియు దానిని ఏదీ మార్చదు.",
              "or": "ବାସ୍ତବରେ ପୋଲିସ ଅଧିକାରୀ କିପରି ଅଛନ୍ତି ତାହା ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ନୁହେଁ; \n ସିଙ୍ଗହମ୍ ସର୍ବଦା ସିଙ୍ଗହମ୍ ହେବ, ଏବଂ ତାହାକୁ କିଛି ପରିବର୍ତ୍ତନ କରିପାରିବ ନାହିଁ |",
              "as": "আৰক্ষী বিষয়াজন বাস্তৱত কেনেকুৱা সেইটো গুৰুত্বপূৰ্ণ নহয়; সিংঘম সদায় সিংঘম, আৰু একোৱেই ইয়াক সলনি কৰিব নোৱাৰে।",
              "gu": "પોલીસ અધિકારી વાસ્તવિકતામાં કેવા છે તેનાથી કોઈ ફરક પડતો નથી; સિંઘમ હંમેશા સિંઘમ રહેશે, અને તેને કંઈપણ બદલી શકતું નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "I can’t believe you still like that movie.",
              "hi": "मैं विश्वास नहीं कर सकता कि तुम अभी भी उस फिल्म को पसंद करते हो।",
              "ka": "ನೀವು ಇನ್ನೂ ಆ ಚಲನಚಿತ್ರವನ್ನು ಇಷ್ಟಪಡುತ್ತೀರಿ ಎಂದು ನನಗೆ ನಂಬಲಾಗುತ್ತಿಲ್ಲ.",
              "te": "మీకు ఇప్పటికీ ఆ సినిమా నచ్చిందని నేను నమ్మలేకపోతున్నాను.",
              "or": "ମୁଁ ବିଶ୍ଵାସସ କରିପାରୁ ନାହିଁ ତୁମେ ଏବେବି ସେହି ଚଳଚ୍ଚିତ୍ର ପସନ୍ଦ କରୁଛ |",
              "as": "মোৰ বিশ্বাস নহয় যে তুমি এতিয়াও সেই চিনেমাখন ভাল পোৱা।",
              "gu": "હું વિશ્વાસ કરી શકતો નથી કે તમને હજી પણ તે મૂવી ગમે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_2_3////",
              "hi": "X2_2_3 ////",
              "ka": "X2_2_3////",
              "te": "X2_2_3////",
              "or": "X2_2_3////",
              "as": "X2_2_3////",
              "gu": "X2_2_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I'm not sure if we spoke about becoming police officers because we wanted to or did that just to irritate Badri.",
              "hi": "मुझे याद नहीं है कि हमने पुलिस अधिकारी बनने के बारे में बात की थी, क्योंकि हमने बद्री को परेशान करने के लिए ऐसा सोचा था।",
              "ka": "ನಾವು ಪೊಲೀಸ್ ಅಧಿಕಾರಿಗಳಾಗಲು ಆಗಲು ಬಯಸಿದ್ದೇವೆಯೇ ಅಥವಾ ಬದ್ರಿಯನ್ನು ಕೆರಳಿಸಲು ಅದನ್ನು ಮಾಡಿದ್ದೇವೆಯೇ ಎನ್ನುವುದು ನನಗೆ ಖಚಿತವಿಲ್ಲ.",
              "te": "మేము పోలీసు అధికారులు కావాలని మాట్లాడుకున్నామా లేక బద్రిని చికాకు పెట్టడానికి అలా చేశామా అనేది నాకు ఖచ్చితంగా తెలియదు.",
              "or": "ମୁଁ ନିଶ୍ଚିତ ନୁହେଁ ଯେ ଆମେ ପୋଲିସ ଅଧିକାରୀ ହେବା ବିଷୟରେ କହିଥିଲୁ\n  କାରଣ ଆମେ ବଦ୍ରିଙ୍କୁ ବିରକ୍ତ କରିବାକୁ ଚାହୁଁଥିଲୁ କିମ୍ବା କରିଥିଲୁ |",
              "as": "মই নাজানো যে আমি আৰক্ষী বিষয়া হোৱাৰ কথা কৈছিলোনে কিয়নো আমি কেৱল বদ্ৰীক বিৰক্ত কৰিবলৈহে বিচাৰিছিলোঁ।",
              "gu": "મને ખાતરી નથી કે આપણે પોલીસ ઓફિસર બનવા વિશે વાત કરી હતી કારણ કે અમે બદ્રીને ચિડાવવા માટે  તેવું કર્યું હતું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Hahahahha",
              "hi": "हाहाहाहा",
              "ka": "ಹ ಹ ಹ ",
              "te": "హహహహ్హ",
              "or": "ହହାହା",
              "as": "হাহাহাহা",
              "gu": "હાહાહાહાહા"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I don’t know why I am still friends with you both!",
              "hi": "मुझे नहीं पता कि मैं अभी भी तुम दोनों का मित्र क्यों हूं!",
              "ka": "ನಾನು ಇನ್ನೂ ನಿಮ್ಮಿಬ್ಬರೊಂದಿಗೆ ಏಕೆ ಸ್ನೇಹಿತರಾಗಿದ್ದೇನೆ ಎಂದು ನನಗೆ ತಿಳಿದಿಲ್ಲ!",
              "te": "నేను ఇప్పటికీ మీ ఇద్దరితో ఎందుకు స్నేహం చేస్తున్నానో నాకు తెలియదు!",
              "or": "ମୁଁ ଜାଣେ ନାହିଁ ମୁଁ କାହିଁକି ତୁମ ଦୁହିଁଙ୍କ ସହ ବନ୍ଧୁ ହୋଇ ରହିଛି!",
              "as": "মই নাজানো মই এতিয়াও কিয় তোমালোক দুয়োৰে সৈতে বন্ধু হৈ আছোঁ!",
              "gu": "મને ખબર નથી કે હું હજી પણ તમારા બંનેનો મિત્ર છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Because we’re brothers Badri!",
              "hi": "क्योंकि हम भाई हैं बद्री!",
              "ka": "ಏಕೆಂದರೆ ನಾವಿಬ್ಬರು ಸಹೋದರರು ಬದ್ರಿ ",
              "te": "ఎందుకంటే మనం సోదరులం బద్రి!",
              "or": "କାରଣ ଆମେ ଭାଇ ଭାଇ ବଦ୍ରି!",
              "as": "কাৰণ আমি ভাই বদ্ৰী!",
              "gu": "કારણ કે આપણે ભાઈઓ છીએ, બદ્રી!"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_2_4",
              "hi": "X2_2_4",
              "ka": "X2_2_4",
              "te": "X2_2_4",
              "or": "X2_2_4",
              "as": "X2_2_4",
              "gu": "X2_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav, Badri and Kuldeep make their way into the hall where the inauguration ceremony is taking place.",
              "hi": "माधव, बद्री और कुलदीप बड़े से कक्ष में पहुँचते हैं, जहां उद्घाटन समारोह हो रहा है।",
              "ka": "ಮಾಧವ್, ಬದ್ರಿ ಮತ್ತು ಕುಲದೀಪ್ ಉದ್ಘಾಟನಾ ಸಮಾರಂಭ ನಡೆಯುತ್ತಿರುವ ಸಭಾಂಗಣಕ್ಕೆ ಹೋಗುತ್ತಾರೆ.",
              "te": "మాధవ్, బద్రి మరియు కుల్దీప్ ప్రారంభోత్సవ వేడుక జరుగుతున్న హాలులోకి ప్రవేశిస్తారు.",
              "or": "ମାଧବ, ବଦ୍ରି ଏବଂ କୁଲଦୀପ ଯେଉଁ ହଲରେ ଉଦଘାଟନୀ ସମାରୋହ\n  ଅନୁଷ୍ଠିତ ହେଉଛି ସେହି ହଲରେ ପ୍ରବେଶ କରନ୍ତି |",
              "as": "মাধৱ, বদ্ৰী আৰু কুলদীপে উদ্বোধনী অনুষ্ঠান অনুষ্ঠিত হোৱা হলটোত প্ৰৱেশ কৰে।",
              "gu": "માધવ, બદ્રી અને કુલદીપ હોલમાં પ્રવેશ કરે છે જ્યાં ઉદ્ઘાટન સમારોહ થઈ રહ્યો છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_3_1",
              "hi": "X2_3_1",
              "ka": "X2_3_1",
              "te": "X2_3_1",
              "or": "X2_3_1",
              "as": "X2_3_1",
              "gu": "X2_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The Assistant Superintendent of Lucknow police, Ms. Prakruti Sharma walks up onto the stage to address the students.",
              "hi": "लखनऊ पुलिस की सहायक अधीक्षिका, सुश्री प्रकृति शर्मा छात्रों को संबोधित करने के लिए मंच पर जाती हैं।",
              "ka": "ಲಕ್ನೋ ಪೊಲೀಸ್‌ನ ಸಹಾಯಕ ಸೂಪರಿಂಟೆಂಡೆಂಟ್, ಶ್ರೀಮತಿ ಪ್ರಕೃತಿ ಶರ್ಮಾ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಉದ್ದೇಶಿಸಿ ಮಾತನಾಡಲು ವೇದಿಕೆಯ ಮೇಲೆ ಬರುತ್ತಾರೆ.",
              "te": "లక్నో పోలీసు అసిస్టెంట్ సూపరింటెండెంట్, శ్రీమతి ప్రకృతి శర్మ విద్యార్థులను ఉద్దేశించి మాట్లాడడానికి వేదికపైకి వెళ్లారు.",
              "or": "ଲକ୍ଷ୍ନୋ ପୋଲିସର ସହକାରୀ ଅଧୀକ୍ଷକ ଶ୍ରୀମତୀ ପ୍ରକୃତି ଶର୍ମା \n ତ୍ରଛାତ୍ରୀଙ୍କୁ ସମ୍ବୋଧିତ କରିବା ପାଇଁ ମଞ୍ଚ ଉପରକୁ ଯାଉଛନ୍ତି।",
              "as": "লক্ষ্ণৌ আৰক্ষীৰ সহকাৰী অধীক্ষক শ্ৰীমতী প্ৰক্ৰোতি শৰ্মাই ছাত্ৰ-ছাত্ৰীসকলক সম্বোধন কৰিবলৈ মঞ্চলৈ খোজ কাঢ়ে।",
              "gu": "લખનઉ  પોલીસના આસિસ્ટન્ટ સુપરિન્ટેન્ડેન્ટ, કુ. પ્રકૃતિ શર્મા વિદ્યાર્થીઓને સંબોધવા માટે સ્ટેજ પર જાય છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_3_2",
              "hi": "X2_3_2",
              "ka": "X2_3_2",
              "te": "X2_3_2",
              "or": "X2_3_2",
              "as": "X2_3_2",
              "gu": "X2_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After greeting everyone present and introducing herself,  the officer shares her experiences that led her to be in the Civil Services.",
              "hi": "सबका अभिवादन करके और स्वयं का परिचय देने के बाद, अधिकारी अपने अनुभवों को साझा करती हैं , जिसने उन्हें जन सेवाओं में रहने के लिए प्रेरित किया।",
              "ka": "ಹಾಜರಿದ್ದ ಎಲ್ಲರಿಗೂ ನಮಸ್ಕರಿಸಿ ಮತ್ತು ತನ್ನನ್ನು ಪರಿಚಯಿಸಿಕೊಂಡ ನಂತರ, ಅಧಿಕಾರಿಯು ತನ್ನ ಅನುಭವಗಳು ತಾನು ಹೇಗೆ ನಾಗರಿಕ ಸೇವೆಗೆ ಸೇರಲು ಕಾರಣವಾಯಿತು.ಎನ್ನುವುದನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ,",
              "te": "హాజరైన ప్రతి ఒక్కరినీ పలకరించి, తనను తాను పరిచయం చేసుకున్న తర్వాత, అధికారి సివిల్ సర్వీసెస్‌లో ఉండటానికి దారితీసిన తన అనుభవాలను పంచుకున్నారు.",
              "or": "ଉପସ୍ଥିତ ସମସ୍ତଙ୍କୁ ଅଭିବାଦନ ଜଣାଇବା ଏବଂ ନିଜକୁ ପରିଚିତ କରାଇବା\n  ପରେ, ଅଧିକାରୀ ତାଙ୍କ ଅନୁଭୂତି ବାଣ୍ଟିଥିଲେ ଯାହା ତାଙ୍କୁ ସିଭିଲ ସର୍ଭିସରେ ରହିବାକୁ ଦେଇଥିଲା |",
              "as": "উপস্থিত সকলোকে সম্ভাষণ জনোৱাৰ পিছত আৰু নিজৰ পৰিচয় দিয়াৰ পিছত, বিষয়াজনে তেখেতৰ অভিজ্ঞতাবোৰ বৰ্ণনা কৰে যাৰ ফলত তেখেত অসামৰিক সেৱাত আহিবলগীয়া হ’ল।",
              "gu": "ઉપસ્થિત દરેકને અભિવાદન કર્યા પછી અને પોતાનો પરિચય કરાવ્યા પછી, અધિકારી તે પોતાનો અનુભવો કહે છે જેના કારણે તે સિવિલ સર્વિસમાં આવી."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_3_3",
              "hi": "X2_3_3",
              "ka": "X2_3_3",
              "te": "X2_3_3",
              "or": "X2_3_3",
              "as": "X2_3_3",
              "gu": "X2_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She wanted to become an IAS officer because she wasn’t happy with the corruption and injustice in society.",
              "hi": "वह आईएएस अधिकारी बनना चाहती थी क्योंकि वह समाज में फैले भ्रष्टाचार और अन्याय से खुश नहीं थीं।",
              "ka": "ಸಮಾಜದಲ್ಲಿನ ಭ್ರಷ್ಟಾಚಾರ ಮತ್ತು ಅನ್ಯಾಯದಿಂದ ಆಕೆಯು ಅಸಂತೋಷಗೊಂಡಿದ್ದ ಕಾರಣ ಅವರು ಐಎಎಸ್ ಅಧಿಕಾರಿಯಾಗಲು ಬಯಸಿದ್ದರು.",
              "te": "సమాజంలో జరుగుతున్న అవినీతి, అన్యాయాలను చూసి తట్టుకోలేక ఆమె IAS కావాలనుకుంది.",
              "or": "ସେ ଆଇଏଏସ୍ ଅଧିକାରୀ ହେବାକୁ ଚାହୁଁଥିଲେ କାରଣ ସେ ସମାଜରେ\n  ହୋଇଥିବା ଦୁର୍ନୀତି ଏବଂ ଅନ୍ୟାୟକୁ ନେଇ ଖୁସି ନଥିଲେ।",
              "as": "তেখেতে এগৰাকী IAS বিষয়া হ'ব বিচাৰিছিল কাৰণ সমাজৰ দুৰ্নীতি আৰু অন্যায়বোৰ দেখি তেওঁ সুখী নাছিল।",
              "gu": "તે IAS અધિકારી બનવા માંગતી હતી કારણ કે તે સમાજમાં ભ્રષ્ટાચાર અને અન્યાયથી ખુશ નો હોતી."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_3_4///",
              "hi": "X2_3_4 ////",
              "ka": "X2_3_4///",
              "te": "X2_3_4///",
              "or": "X2_3_4///",
              "as": "X2_3_4///",
              "gu": "X2_3_4///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I struggled to clear the UPSC exam for 2 years, but I was determined. I even rejected a job at the defence ministry. I finally passed the exam on my third attempt and got selected in the Indian Police Service (IPS).",
              "hi": "मैंने 2 साल तक यूपीएससी परीक्षा को उत्तीर्ण करने के लिए संघर्ष किया, लेकिन मैं दृढ़ निश्चयी थी। मैंने रक्षा मंत्रालय की नौकरी को भी नहीं स्वीकार किया। अंत में मैंने अपने तीसरे प्रयास में परीक्षा उत्तीर्ण की और भारतीय पुलिस सेवा (आईपीएस) में चुनी गई।",
              "ka": "ನಾನು 2 ವರ್ಷಗಳ ಕಾಲ UPSC ಪರೀಕ್ಷೆಯಲ್ಲಿ ತೇರ್ಗಡೆಗೊಳ್ಳಲು ಹೆಣಗಾಡಿದೆ, ಆದರೆ ನಾನು ನಿರ್ಧರಿಸಿದ್ದೆ. ರಕ್ಷಣಾ ಸಚಿವಾಲಯದ ಕೆಲಸವನ್ನು ಕೂಡ ನಾನು ತಿರಸ್ಕರಿಸಿದೆ. ನಾನು ಅಂತಿಮವಾಗಿ ನನ್ನ ಮೂರನೇ ಪ್ರಯತ್ನದಲ್ಲಿ ಪರೀಕ್ಷೆಯಲ್ಲಿ ಉತ್ತೀರ್ಣನಾದೆ ಮತ್ತು ಭಾರತೀಯ ಪೊಲೀಸ್ ಸೇವೆಗೆ (IPS) ಆಯ್ಕೆಯಾದೆ.",
              "te": "నేను 2 సంవత్సరాలు UPSC పరీక్షలో ఉత్తీర్ణత సాధించడానికి చాలా కష్టపడ్డాను, కానీ నేను నిశ్చయించుకున్నాను. నేను రక్షణ మంత్రిత్వ శాఖలో ఉద్యోగాన్ని కూడా తిరస్కరించాను. ఎట్టకేలకు నా మూడవ ప్రయత్నంలో పరీక్షలో ఉత్తీర్ణత సాధించి ఇండియన్ పోలీస్ సర్వీస్ (IPS)కి ఎంపికయ్యాను.",
              "or": "ମୁଁ 2 ବର୍ଷ ପାଇଁ UPSC ପରୀକ୍ଷା ପାସ କରିବାକୁ ସଂଘର୍ଷ କଲି, କିନ୍ତୁ ମୁଁ ସ୍ଥିର\n  କଲି | ଏପରିକି ମୁଁ ପ୍ରତିରକ୍ଷା ମନ୍ତ୍ରଣାଳୟରେ ଚାକିରି ପ୍ରତ୍ୟାଖ୍ୟାନ କରିଛି। ମୁଁ ଶେଷରେ ମୋର ତୃତୀୟ ପ୍ରୟାସରେ ପରୀକ୍ଷାରେ ଉତ୍ତୀର୍ଣ୍ଣ ହୋଇ ଭାରତୀୟ ପୋଲିସ ସେବା (ଆଇପିଏସ୍) ରେ ମନୋନୀତ ହେଲି |",
              "as": "মই ২ বছৰৰ বাবে UPSC পৰীক্ষাত উত্তীৰ্ণ হ'বলৈ সংগ্ৰাম কৰিছিলোঁ, কিন্তু মই দৃঢ় প্ৰতিজ্ঞ আছিলোঁ। মই আনকি প্ৰতিৰক্ষা মন্ত্ৰালয়ৰ এটা চাকৰিও প্ৰত্যাখ্যান কৰিছিলোঁ। অৱশেষত মই মোৰ তৃতীয় প্ৰচেষ্টাত পৰীক্ষাত উত্তীৰ্ণ হৈছিলোঁ আৰু ভাৰতীয় আৰক্ষী সেৱাত (IPS) নিৰ্বাচিত হৈছিলোঁ।",
              "gu": "મેં 2 વર્ષ સુધી UPSC પરીક્ષા પાસ કરવા માટે સંઘર્ષ કર્યો, પરંતુ હું મક્કમ હતી. મેં રક્ષા મંત્રાલયની નોકરી પણ નકારી કાઢી હતી. આખરે મારા ત્રીજા પ્રયાસમાં મેં પરીક્ષા પાસ કરી અને ભારતીય પોલીસ સેવા (IPS)માં પસંદગી પામી."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_3_5",
              "hi": "X2_3_5",
              "ka": "X2_3_5",
              "te": "X2_3_5",
              "or": "X2_3_5",
              "as": "X2_3_5",
              "gu": "X2_3_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After failing 2 times and rejecting a job at the defence ministry, she appeared for the exam again, cleared them and got selected as a Deputy Superintendent of Police.",
              "hi": "2 बार असफल होने और रक्षा मंत्रालय में नौकरी को अस्वीकार करने के बाद, उसने फिर से परीक्षा  दी, उसे उत्तीर्ण किया और पुलिस के उप अधीक्षक के रूप में चुनी गई।",
              "ka": "2 ಬಾರಿ ಅನುತ್ತೀರ್ಣರಾದ ನಂತರ ಮತ್ತು ರಕ್ಷಣಾ ಸಚಿವಾಲಯದ ಕೆಲಸವನ್ನು ತಿರಸ್ಕರಿಸಿದ ನಂತರ, ಆಕೆಯು ಮತ್ತೆ ಪರೀಕ್ಷೆಗೆ ಹಾಜರಾಗಿ, ಅದರಲ್ಲಿ ತೇರ್ಗಡೆ ಹೊಂದಿ ಉಪ ಪೊಲೀಸ್ ವರಿಷ್ಠಾಧಿಕಾರಿಯಾಗಿ ಆಯ್ಕೆಯಾಗಿರುತ್ತಾರೆ.",
              "te": "2 సార్లు ఫెయిల్ అయ్యి, డిఫెన్స్ మినిస్ట్రీలో ఉద్యోగాన్ని తిరస్కరించిన తర్వాత ఆమె మళ్లీ పరీక్షకు హాజరై, వాటిని క్లియర్ చేసి డిప్యూటీ సూపరింటెండెంట్ ఆఫ్ పోలీస్‌గా ఎంపికైంది.",
              "or": "୨ ଥର ବିଫଳ ହେବା ଏବଂ ପ୍ରତିରକ୍ଷା ମନ୍ତ୍ରଣାଳୟରେ ଚାକିରି ପ୍ରତ୍ୟାଖ୍ୟାନ \n କରିବା ପରେ ସେ ପୁନର୍ବାର ପରୀକ୍ଷା ପାଇଁ ହାଜର ହୋଇଥିଲେ, ସେମାନଙ୍କୁ ସଫା କରିଥିଲେ ଏବଂ ଡେପୁଟି ପୋଲିସ୍ ଅଧୀକ୍ଷକ ଭାବରେ ମନୋନୀତ ହୋଇଥିଲେ।",
              "as": "২ বাৰ বিফল হোৱাৰ পিছত আৰু প্ৰতিৰক্ষা মন্ত্ৰালয়ত চাকৰি প্ৰত্যাখ্যান কৰাৰ পিছত, তেখেতে পুনৰ পৰীক্ষাত অৱতীৰ্ণ হৈছিল, উত্তীৰ্ণ হৈছিল আৰু উপ আৰক্ষী অধীক্ষক হিচাপে নিৰ্বাচিত হৈছিল।",
              "gu": "2 વખત નાપાસ થયા પછી અને સંરક્ષણ મંત્રાલયની નોકરીને નકારી કાઢ્યા પછી, તેએ ફરીથી પરીક્ષા આપી, તે પાસ કરી અને નાયબ પોલીસ અધિક્ષક તરીકે પસંદગી પામ્યા."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_3_6////",
              "hi": "X2_3_6 /////////",
              "ka": "X2_3_6////",
              "te": "X2_3_6////",
              "or": "X2_3_6////",
              "as": "X2_3_6////",
              "gu": "X2_3_6////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "After joining the police force, I understood the responsibility and power that comes with the role and what it would mean to misuse it.",
              "hi": "पुलिस बल में शामिल होने के बाद, मैं उस जिम्मेदारी और शक्ति को समझ गई जो पद के साथ आती  है और इसका दुरुपयोग करने का क्या अर्थ होगा।",
              "ka": "ಪೊಲೀಸ್ ಪಡೆಗೆ ಸೇರಿದ ನಂತರ, ಹುದ್ದೆಯೊಂದಿಗೆ ಬರುವ ಜವಾಬ್ದಾರಿ ಮತ್ತು ಶಕ್ತಿ ಮತ್ತು ಅದನ್ನು ದುರುಪಯೋಗಪಡಿಸಿಕೊಳ್ಳುವುದರಿಂದ ಏನಾಗಬಹುದು ಎನ್ನುವುದನ್ನು ನಾನು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೇನೆ.",
              "te": "పోలీస్ ఫోర్స్‌లో చేరిన తర్వాత, పాత్రతో వచ్చే బాధ్యత మరియు శక్తి మరియు దానిని దుర్వినియోగం చేయడం ఎలా ఉంటుందో నాకు అర్థమైంది.",
              "or": "ପୋଲିସ୍ ଫୋର୍ସରେ ଯୋଗଦେବା ପରେ, ମୁଁ ଦାୟିତ୍ଵ ଏବଂ ଶକ୍ତି ବୁଝିଲି ଯାହା\n  ଭୂମିକା ସହିତ ଆସେ ଏବଂ ଏହାର ଅପବ୍ୟବହାର କରିବାର ଅର୍ଥ କ’ଣ?",
              "as": "আৰক্ষী বাহিনীত যোগদান কৰাৰ পিছত, মই ভূমিকাটোৰ সৈতে অহা দায়িত্ব আৰু ক্ষমতা আৰু ইয়াৰ অপব্যৱহাৰ কৰাৰ অৰ্থ কি বুজি পাইছিলোঁ।",
              "gu": "પોલીસ દળમાં જોડાયા પછી, મને સમજાયું કે ભૂમિકા સાથે જે જવાબદારી અને સત્તા આવે છે અને તેનો દુરુપયોગ કરવાનો અર્થ શું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Power doesn’t lie in ordering people around or using violence to maintain law and order. It lies in empowering the society by building a positive, inquisitive and patient mindset for each one of us. Change begins with us.",
              "hi": "कानून और व्यवस्था बनाए रखने के लिए आसपास के लोगों को आदेश देने या हिंसा का उपयोग करने में शक्ति निहित नहीं है। यह हम में से प्रत्येक के लिए सकारात्मक, जिज्ञासु और धैर्यवान मानसिकता का निर्माण करके समाज को सशक्त बनाने में निहित है। परिवर्तन का आरम्भ हमसे होता है।",
              "ka": "ಕಾನೂನು ಮತ್ತು ಸುವ್ಯವಸ್ಥೆಯನ್ನು ಕಾಪಾಡಲು ಸುತ್ತಲೂ ಇರುವ  ಜನರ ಮೇಲೆ ಅಧಿಕಾರವನ್ನು ಚಲಾಯಿಸುವುದರಲ್ಲಿ ಅಥವಾ ಹಿಂಸೆಯನ್ನು ಬಳಸುವುದರಲ್ಲಿ ಅಧಿಕಾರವು ಅಡಗಿರುವುದಿಲ್ಲ. ಇದು ನಮ್ಮಲ್ಲಿ ಪ್ರತಿಯೊಬ್ಬರಿಗೂ ಸಕಾರಾತ್ಮಕ, ಜಿಜ್ಞಾಸೆ ಮತ್ತು ತಾಳ್ಮೆಯ ಮನಸ್ಥಿತಿಯನ್ನು ನಿರ್ಮಿಸುವ ಮೂಲಕ ಸಮಾಜವನ್ನು ಸಶಕ್ತಗೊಳಿಸುವುದರಲ್ಲಿ ಅಡಗಿದೆ. ಬದಲಾವಣೆ ನಮ್ಮಿಂದಲೇ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ.",
              "te": "శాంతిభద్రతలను కాపాడేందుకు ప్రజలను ఆదేశించడంలో లేదా హింసను ఉపయోగించడంలో అధికారం ఉండదు. మనలో ప్రతి ఒక్కరికి సానుకూల, పరిశోధనాత్మక మరియు సహనంతో కూడిన మనస్తత్వాన్ని నిర్మించడం ద్వారా సమాజాన్ని శక్తివంతం చేయడంలో ఇది ఉంది. మార్పు మనతోనే మొదలవుతుంది.",
              "or": "ଶକ୍ତି ର ଅର୍ଥ ଆଖପାଖରେ ଥିବା ଲୋକଙ୍କୁ ଆଦେଶ ଦେବା କିମ୍ବା ଆଇନ ଶୃଙ୍ଖଳା \n ବଜାୟ ରଖିବା ପାଇଁ ହିଂସା ବ୍ୟବହାର କରିବା ନୁହେଁ | ଏହା ଆମ ପ୍ରତ୍ୟେକଙ୍କ ପାଇଁ ଏକ ସକରାତ୍ମକ, ଅନୁସନ୍ଧାନକାରୀ ଏବଂ ଧର୍ଯ୍ୟଶୀଳ ମାନସିକତା ସୃଷ୍ଟି କରି ସମାଜକୁ ସଶକ୍ତିକରଣରେ ଅଛି | ପରିବର୍ତ୍ତନ ଆମଠାରୁ ଆରମ୍ଭ ହୁଏ |",
              "as": "আইন-শৃংখলা বজাই ৰাখিবলৈ চাৰিওফালে থকা লোকসকলক আদেশ দিয়া বা হিংসা ব্যৱহাৰ কৰাতে ক্ষমতা নিহিত নাথাকে। ই আমাৰ প্ৰত্যেকৰ বাবে এক ইতিবাচক, অনুসন্ধিৎসু আৰু ধৈৰ্য্যশীল মানসিকতা গঢ়ি তুলি সমাজক শক্তিশালী কৰাত নিহিত থাকে। পৰিৱৰ্তন আমাৰ পৰাই আৰম্ভ হয়।",
              "gu": "આજુબાજુના લોકોને આદેશ આપવા અથવા કાયદો અને વ્યવસ્થા જાળવવા માટે હિંસાનો ઉપયોગ કરવામાં સત્તા રહેલી નથી. તે આપણામાંના દરેક માટે સકારાત્મક, જિજ્ઞાસુ અને ધીરજપૂર્ણ માનસિકતાનું નિર્માણ કરીને સમાજને સશક્ત કરવામાં આવેલું છે. પરિવર્તન આપણાથી શરૂ થાય છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_3_7////",
              "hi": "X2_3_7 /////",
              "ka": "X2_3_7////",
              "te": "X2_3_7////",
              "or": "X2_3_7////",
              "as": "X2_3_7////",
              "gu": "X2_3_7////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "After the talk, during the student interaction, one of the students asks...",
              "hi": "बात करने के बाद, छात्रों की आपसी बातचीत के समय,  उन छात्रों में से एक पूछता है ...",
              "ka": "ಭಾಷಣದ ನಂತರ, ವಿದ್ಯಾರ್ಥಿ ಸಂವಾದದ ಸಮಯದಲ್ಲಿ, ಒಬ್ಬ ವಿದ್ಯಾರ್ಥಿ ಕೇಳುತ್ತಾನೆ...",
              "te": "ప్రసంగం తర్వాత, విద్యార్థులతో ఇంటరాక్షన్ సమయంలో, విద్యార్థులలో ఒకరు ఇలా అడిగారు...",
              "or": "କଥାବାର୍ତ୍ତା ପରେ, ଛାତ୍ରଙ୍କ କଥାବାର୍ତ୍ତା ସମୟରେ, ଜଣେ ଛାତ୍ର ପଚାରନ୍ତି ...",
              "as": "কথা-বতৰাৰ পিছত, শিক্ষাৰ্থীৰে বাৰ্তালাপৰ সময়ত, এজন শিক্ষাৰ্থীয়ে সুধিলে...",
              "gu": "વાર્તાલાપ પછી, વિદ્યાર્થીઓના વાતચીત દરમિયાન, એક વિદ્યાર્થી પૂછે છે..."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Ma’am, as a woman, did you face any challenges in your journey, is it easy for women to become a police officer?",
              "hi": "मैम, एक महिला के रूप में, क्या आपको अपनी यात्रा में किन्हीं चुनौतियों का सामना करना पड़ा, क्या महिलाओं का पुलिस अधिकारी बनना सरल है?",
              "ka": "ಮೇಡಂ, ಮಹಿಳೆಯಾಗಿ, ನಿಮ್ಮ ಪ್ರಯಾಣದಲ್ಲಿ ನೀವು ಯಾವುದೇ ಸವಾಲುಗಳನ್ನು ಎದುರಿಸಿದ್ದೀರಾ, ಮಹಿಳೆಯರು ಪೊಲೀಸ್ ಅಧಿಕಾರಿಯಾಗುವುದು ಸುಲಭವೇ?",
              "te": "మేడమ్, ఒక మహిళగా, మీ ప్రయాణంలో మీరు ఏవైనా సవాళ్లను ఎదుర్కొన్నారా, మహిళలు పోలీసు అధికారి అవ్వడం సులభమేనా?",
              "or": "ମା, ଜଣେ ମହିଳା ଭାବରେ, ଆପଣ ଆପଣଙ୍କର ଯାତ୍ରାରେ କୌଣସି ଆହ୍ଵାନର\n  ସମ୍ମୁଖୀନ ହୋଇଥିଲେ, ମହିଳାମାନଙ୍କ ପାଇଁ ପୋଲିସ୍ ହେବା ସହଜ କି?",
              "as": "মেডাম, এগৰাকী মহিলা হিচাপে, আপুনি আপোনাৰ যাত্ৰাত কোনো প্ৰত্যাহ্বানৰ সন্মুখীন হৈছিল নেকি, মহিলাসকলৰ বাবে আৰক্ষী বিষয়া হোৱাটো সহজ নেকি?",
              "gu": "મેડમ, એક મહિલા તરીકે, શું તમે તમારા જીવનમાં કોઈ પડકારોનો સામનો કર્યો, શું મહિલાઓ માટે પોલીસ અધિકારી બનવું સરળ છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Initially I faced some resistance from my family and society around me as it is a risky job. But after all these years, they understand why it is important for women to be in the police force. Now they all are proud of what I do.",
              "hi": "आरम्भ में मुझे अपने परिवार और समाज से कुछ प्रतिरोध का सामना करना पड़ा क्योंकि यह एक जोखिम भरा काम है। लेकिन इतने वर्षों के बाद, वे समझते हैं कि महिलाओं के लिए पुलिस बल में भर्ती होना क्यों महत्वपूर्ण है। अब उन सभी को गर्व है जो मैं करती  हूं।",
              "ka": "ಆರಂಭದಲ್ಲಿ ನಾನು ನನ್ನ ಕುಟುಂಬ ಮತ್ತು ನನ್ನ ಸುತ್ತಲಿನ ಸಮಾಜದಿಂದ ಸ್ವಲ್ಪ ಪ್ರತಿರೋಧವನ್ನು ಎದುರಿಸಿದೆ ಏಕೆಂದರೆ ಇದು ಅಪಾಯಕಾರಿ ಕೆಲಸವಾಗಿದೆ. ಆದರೆ ಇಷ್ಟು ವರ್ಷಗಳ ನಂತರ, ಮಹಿಳೆಯರು ಪೊಲೀಸ್ ಪಡೆಯಲ್ಲಿ ಇರುವುದು ಏಕೆ ಮುಖ್ಯ ಎಂದು ಅವರು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದಾರೆ. ಈಗ ಅವರೆಲ್ಲರಿಗೂ ನಾನು ಮಾಡುತ್ತಿರುವುದರ ಬಗ್ಗೆ ಹೆಮ್ಮೆ ಇದೆ,",
              "te": "ఇది రిస్క్‌తో కూడుకున్న పని కాబట్టి మొదట్లో నేను నా కుటుంబం మరియు నా చుట్టూ ఉన్న సమాజం నుండి కొన్ని ఇబ్బందులు ఎదుర్కొన్నాను. అయితే ఇన్నేళ్ల తర్వాత, మహిళలకు పోలీసుశాఖలో ఎందుకు ప్రాధాన్యం ఉందో వాళ్ళకు ఇప్పుడు అర్థమవుతోంది. ఇప్పుడు వాళ్లంతా నేను చేసే పనికి గర్వపడుతున్నారు.",
              "or": "ପ୍ରାରମ୍ଭରେ ମୁଁ ମୋ ପରିବାର ଏବଂ ମୋ ସମାଜର କିଛି ପ୍ରତିରୋଧର ସମ୍ମୁଖୀନ \n ହୋଇଥିଲି କାରଣ ଏହା ଏକ ବିପଦପୂର୍ଣ୍ଣ କାମ | କିନ୍ତୁ ଏତେ ବର୍ଷ ପରେ, ସେମାନେ ବୁଝିଛନ୍ତି ଯେ ମହିଳାମାନେ ପୋଲିସ୍ ଫୋର୍ସରେ ରହିବା କାହିଁକି ଜରୁରୀ | ବର୍ତ୍ତମାନ ମୁଁ ଯାହା କରେ ସେଥିପାଇଁ ସମସ୍ତେ ଗର୍ବିତ |",
              "as": "আৰম্ভণিতে মই মোৰ পৰিয়াল আৰু চাৰিওফালৰ সমাজৰ পৰা কিছু প্ৰতিৰোধৰ সন্মুখীন হৈছিলো কিয়নো এইটো এটা বিপদজনক কাম। কিন্তু ইমান বছৰৰ পিছত, তেওঁলোকে বুজি পালে যে মহিলাসকল আৰক্ষী বাহিনীত থকাটো কিয় গুৰুত্বপূৰ্ণ। এতিয়া তেওঁলোক সকলোৱে মই কৰা কামৰ বাবে গৌৰৱান্বিত।",
              "gu": "શરૂઆતમાં મને મારા પરિવાર અને મારી આસપાસના સમાજ તરફથી કેટલાક પ્રતિકારનો સામનો કરવો પડ્યો કારણ કે તે એક જોખમી કામ છે. પરંતુ આટલા વર્ષો પછી તેઓ સમજે છે કે મહિલાઓ માટે પોલીસ ફોર્સમાં હોવું શા માટે જરૂરી છે. હવે હું જે કરું છું તેના પર બધાને ગર્વ છે."
            }
          }
        ]
      },
      "scene3": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_1",
              "hi": "X3_1_1",
              "ka": "X3_1_1",
              "te": "X3_1_1",
              "or": "X3_1_1",
              "as": "X3_1_1",
              "gu": "X3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Hearing Prakruti Ma'am talk makes Madhav realise that people working in the government are in a position to help others.",
              "hi": "प्रकृति मैम की बात सुनकर माधव को अहसास होता है कि सरकार में काम करने वाले लोग दूसरों की सहायता करने की स्थिति में हैं।",
              "ka": "ಪ್ರಕೃತಿ ಮೇಡಂ ಮಾತು ಕೇಳಿ ಮಾಧವನಿಗೆ  ಸರ್ಕಾರದಲ್ಲಿ ಕೆಲಸ ಮಾಡುವವರು ಇತರರಿಗೆ ಸಹಾಯ ಮಾಡುವ ಸ್ಥಿತಿಯಲ್ಲಿ ಹೇಗೆ ಇರುತ್ತಾರೆ ಎನ್ನುವುದರ ಅರಿವಾಗುತ್ತದೆ.",
              "te": "ప్రకృతి మేడమ్ మాటలు విన్న తర్వాత, ప్రభుత్వంలో పనిచేసే వ్యక్తులు ఇతరులకు సహాయం చేసే స్థితిలో ఉండవచ్చు అని మాధవ్‌కు అర్థమవుతుంది.",
              "or": "ପ୍ରାକୃତୀ ମା’ଙ୍କ କଥା ଶୁଣିବା ମାଧବ ଅନୁଭବ କରେ ଯେ ସରକାରରେ\n  କାର୍ଯ୍ୟ କରୁଥିବା ଲୋକମାନେ ଅନ୍ୟମାନଙ୍କୁ ସାହାଯ୍ୟ କରିବା ସ୍ଥିତିରେ ଅଛନ୍ତି।",
              "as": "প্ৰক্ৰোতি মেডামৰ কথা শুনি মাধৱে উপলব্ধি কৰিলে যে চৰকাৰী কাম কৰা লোকসকল আনক সহায় কৰিব পৰা অৱস্থাত আছে।",
              "gu": "પ્રકૃતિ મેડમની વાત સાંભળીને માધવને અહેસાસ થાય છે કે સરકારમાં કામ કરતા લોકો બીજાને મદદ કરવાની સ્થિતિમાં છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_2",
              "hi": "X3_1_2",
              "ka": "X3_1_2",
              "te": "X3_1_2",
              "or": "X3_1_2",
              "as": "X3_1_2",
              "gu": "X3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The talk moves him. He feels as if Prakruti Ma'am was talking to him and showing him what he should do with his life.",
              "hi": "उसे यह बात हिला देती है। उसे ऐसा लगता है जैसे प्रकृति मैम उससे बात कर रही हैं और बता रही हैं कि उसे अपने जीवन में क्या करना चाहिए।",
              "ka": "ಅವರ ಮಾತು ಅವನನ್ನು ವಿಚಲಿತನನ್ನಾಗಿ ಮಾಡುತ್ತದೆ. ಪ್ರಕೃತಿ ಮೇಡಂ ತನ್ನೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾ ತನ್ನ ಜೀವನದಲ್ಲಿ ಏನು ಮಾಡಬೇಕೆಂದು ತೋರಿಸುತ್ತಿರುವಂತೆ ಅವನಿಗೆ ಭಾಸವಾಗುತ್ತದೆ.",
              "te": "చర్చ అతన్ని కదిలిస్తుంది. ప్రకృతి మేడమ్ తనతో మాట్లాడుతున్నట్లు మరియు తన జీవితాన్ని తాను ఏమి చేయాలో చూపుతున్నట్లు అతనికి అనిపిస్తుంది.",
              "or": "କଥାବାର୍ତ୍ତା ତାଙ୍କୁ ଅସ୍ଥିର | ସେ ଅନୁଭବ କରୁଛନ୍ତି ଯେପରି ପ୍ରାକୃତୀ ମା ’\n ତାଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରୁଛନ୍ତି ଏବଂ ତାଙ୍କୁ ତାଙ୍କ ଜୀବନ ସହିତ କଣ କରିବା ଉଚିତ୍ ତାହା ଦେଖାଉଛନ୍ତି |",
              "as": "কথাটোৱে তাক উত্তেজিত কৰিলে। সি অনুভৱ কৰিলে যেন প্ৰক্ৰোতি মেডামে তাৰ সৈতেই কথা পাতি আছিল আৰু তাক দেখুৱাইছিল যে সি তাৰ জীৱনৰ সৈতে কি কৰা উচিত।",
              "gu": "વાત તેને આગળ લાઇ જાય છે. તેને એવું લાગે છે કે જાણે પ્રકૃતિ મેડમ તેની સાથે વાત કરી રહી છે અને તેને બતાવી રહી છે કે તેણે તેના જીવનમાં શું કરવું જોઈએ."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_3",
              "hi": "X3_1_3",
              "ka": "X3_1_3",
              "te": "X3_1_3",
              "or": "X3_1_3",
              "as": "X3_1_3",
              "gu": "X3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After the speech, the students gather in a queue to meet Prakruti Ma'am. Madhav looks dazed.",
              "hi": "भाषण के बाद, छात्र प्रकृति मैम से मिलने के लिए एक कतार में इकट्ठे होते हैं। माधव अचंभित दिखता है।",
              "ka": "ಭಾಷಣದ ನಂತರ, ಪ್ರಕೃತಿ ಮೇಡಂ ಅವರನ್ನು ಭೇಟಿ ಮಾಡಲು ವಿದ್ಯಾರ್ಥಿಗಳು ಸರತಿ ಸಾಲಿನಲ್ಲಿ ಸೇರುತ್ತಾರೆ. ಮಾಧವ್ ದಿಗ್ಭ್ರಮೆಗೊಂಡಂತೆ ಕಾಣುತ್ತಾನೆ.",
              "te": "ప్రసంగం ముగిసిన తరువాత, ప్రకృతి మేడమ్‌ను కలవడానికి విద్యార్థులు క్యూ కట్టారు. మాధవ్ బిత్తరపోయి చూస్తున్నాడు.",
              "or": "ଭାଷଣ ପରେ ଛାତ୍ରଛାତ୍ରୀମାନେ ପ୍ରାକ୍ରୁତି ମା’ଙ୍କୁ ଭେଟିବା ପାଇଁ ଏକ ଧାଡ଼ିରେ\n  ଏକାଠି ହୁଅନ୍ତି | ମାଧବ ବିସ୍ମିତ ଦେଖାଯାଏ |",
              "as": "ভাষণৰ পিছত, শিক্ষাৰ্থীসকলে প্ৰক্ৰোতি মেডামক লগ কৰিবলৈ শাৰী পাতিলে। মাধৱক হতভম্ব হোৱা দেখা গ’ল।",
              "gu": "ભાષણ પછી, વિદ્યાર્થીઓ પ્રકૃતિ મેડમને મળવા એક કતારમાં ભેગા થાય છે. માધવ સ્તબ્ધ દેખાય છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_4//",
              "hi": "X3_1_4 //",
              "ka": "X3_1_4//",
              "te": "X3_1_4//",
              "or": "X3_1_4//",
              "as": "X3_1_4//",
              "gu": "X3_1_4//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Badri",
              "hi": "बद्री",
              "ka": "ಬದ್ರಿ",
              "te": "బద్రి",
              "or": "ବଦ୍ରି",
              "as": "বদ্ৰী",
              "gu": "બદ્રી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୁଳଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "କୁଳଦୀପ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Badri",
              "hi": "बद्री",
              "ka": "ಬದ್ರಿ",
              "te": "బద్రి",
              "or": "ବଦ୍ରି",
              "as": "বদ্ৰী",
              "gu": "બદ્રી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Madhav wasn't that great!",
              "hi": "माधव इतना महान नहीं था!",
              "ka": "ಮಾಧವ್ ಅದು ತುಂಬಾ ಉತ್ತಮವಾಗಿತ್ತಲ್ಲವೇ!",
              "te": "మాధవ్ అంత గొప్పవాడు కాదు!",
              "or": "ମାଧବ ସେତେ ଭଲ ନଥିଲେ!",
              "as": "মাধৱ এইটো সাংঘাতিক নাছিল নে!",
              "gu": "માધવ, શું તે મહાન ન હતું!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "What happened Madhav, why do you look so lost?",
              "hi": "माधव क्या हुआ, तुम इतने खोए खोए क्यों दिखते हो?",
              "ka": "ಏನಾಯ್ತು ಮಾಧವ್, ಯಾಕೆ ಹೀಗೆ ದಾರಿ ತಪ್ಪಿದಂತೆ ಕಾಣ್ತಿದ್ದೀಯಾ?",
              "te": "ఏమైంది మాధవ్, ఎందుకు అలా ఆందోళనగా కనిపిస్తున్నావు?",
              "or": "ମାଧବ କ’ଣ ହେଲା, ତୁମେ ଏତେ ହଜିଯାଇଥିବା ଭଳି ଦେଖାଯାଉଛ କାହିଁକି?",
              "as": "কি হ'ল মাধৱ, তোক ক’ৰবাত হেৰাই যোৱা যেন লাগিছে?",
              "gu": "શું થયું માધવ, તું આટલો ખોવાયેલો કેમ દેખાય છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "I'm feeling so inspired but also surprised. I had no idea about the work civil servants actually do.",
              "hi": "मैं इतना प्रेरित अनुभव कर रहा हूं लेकिन आश्चर्यचकित हूं। मुझे  कोई जानकारी नहीं थी, कि सिविल सेवक वास्तव में क्या काम करते हैं।",
              "ka": "ನಾನು ಇದರಿಂದ ಎಷ್ಟು ಸ್ಫೂರ್ತಿಪಡೆದಿದ್ದೇನೆಯೋ ಅಷ್ಟೇ ಆಶ್ಚರ್ಯವೂ ಆಗಿದೆ. ಪೌರಕಾರ್ಮಿಕರು ನಿಜವಾಗಿ ಮಾಡುವ ಕೆಲಸದ ಬಗ್ಗೆ ನನಗೆ ತಿಳಿದಿರಲಿಲ್ಲ.",
              "te": "నాకు చాలా ఇన్స్ఫైరింగ్ గా ఉంది కానీ ఆశ్చర్యంగా కూడా ఉంది. వాస్తవానికి సివిల్ సర్వెంట్లు చేసే పని గురించి నాకు తెలియదు.",
              "or": "ମୁଁ ଏତେ ଅନୁପ୍ରାଣିତ କିନ୍ତୁ ଆଶ୍ଚର୍ଯ୍ୟ ମଧ୍ୟ ଅନୁଭବ କରୁଛି | ସରକାରୀ \n କର୍ମଚାରୀମାନେ ପ୍ରକୃତରେ କରୁଥିବା କାର୍ଯ୍ୟ ବିଷୟରେ ମୋର କୌଣସି ଧାରଣା ନଥିଲା |",
              "as": "মই ইমান অনুপ্ৰাণিত হৈছোঁ কিন্তু আচৰিতো হৈছোঁ। অসামৰিক কৰ্মচাৰীসকলে প্ৰকৃততে কৰা কামৰ বিষয়ে মোৰ কোনো ধাৰণা নাছিল।",
              "gu": "હું ખૂબ પ્રેરિત પણ આશ્ચર્ય અનુભવું છું. સિવિલ સેવકો ખરેખર જે કામ કરે છે તેના વિશે મને કોઈ ખ્યાલ ન હતો."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "Obviously! All you know about police officers is through films.",
              "hi": "स्वाभाविक है! पुलिस अधिकारियों के बारे में तुम फिल्मों के माध्यम से जानते हो।",
              "ka": "ನಿಸ್ಸಂಶಯವಾಗಿ! ಪೊಲೀಸ್ ಅಧಿಕಾರಿಗಳ ಬಗ್ಗೆ ನಿನಗೆ ತಿಳಿದಿರುವುದು ಕೇವಲ ಚಲನಚಿತ್ರಗಳ ಮೂಲಕ.",
              "te": "ఇది సహజమే! పోలీసు అధికారుల గురించి మీకు తెలిసినదంతా సినిమాల ద్వారానే.",
              "or": "ଆଜ୍ଞା ହଁ! ପୋଲିସ ଅଧିକାରୀଙ୍କ ବିଷୟରେ ତୁମେ କେବଳ ଜାଣିଛ ଚଳଚ୍ଚିତ୍ର \n ମାଧ୍ୟମରେ |",
              "as": "অৱশ্যেই! আৰক্ষী বিষয়াসকলৰ বিষয়ে তুমি কেৱল চলচ্চিত্ৰৰ জৰিয়তেহে জানা।",
              "gu": "ચોક્કસ પણે! પોલીસ અધિકારીઓ વિશે તમે જે કંઈ જાણો છો તે ફિલ્મો દ્વારા છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "SuccessStories",
              "hi": "सफलता की कहानियां",
              "ka": "ಯಶೋಗಾಥೆ",
              "te": "విజయ గాథలు",
              "or": "ସଫଳତା କାହାଣୀ |",
              "as": "সফলতাৰ কাহিনী",
              "gu": "સફળ વાર્તા"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "ಎಮ್‌ಸಿ‌ಕ್ಯೂ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Madhav is inspired by Prakruti Ma’am’s success story to become a police officer. How are success stories helpful while building a career?",
              "hi": "माधव एक पुलिस अधिकारी बनने के लिए प्रकृति मैम की सफलता की कहानी से प्रेरित है। करियर बनाने के समय सफलता की कहानियां कैसे सहायक होती हैं?",
              "ka": "ಮಾಧವ್ ಪೊಲೀಸ್ ಅಧಿಕಾರಿಯಾದ ಪ್ರಕೃತಿ ಮಾಮ್ ಅವರ ಯಶಸ್ಸಿನ ಕಥೆಯಿಂದ ಸ್ಫೂರ್ತಿ ಪಡೆದಿದ್ದಾನೆ. ವೃತ್ತಿಜೀವನವನ್ನು ನಿರ್ಮಿಸುವಾಗ ಯಶಸ್ಸಿನ ಕಥೆಗಳು ಹೇಗೆ ಸಹಾಯಕವಾಗುತ್ತದೆ?",
              "te": "మాధవ్ పోలీస్ ఆఫీసర్‌గా మారడానికి ప్రకృతి మామ్ విజయగాథ నుండి ప్రేరణ పొందాడు. కెరీర్‌ను నిర్మించుకోవడానికి విజయగాథలు ఎలా ఉపయోగపడతాయి?",
              "or": "ମାଧବ ଜଣେ ପୋଲିସ୍ ହେବା ପାଇଁ ପ୍ରାକୃତୀ ମା’ଙ୍କ ସଫଳତାର କାହାଣୀ\n  ଦ୍ୱାରା ଅନୁପ୍ରାଣିତ | କ୍ୟାରିୟର ଗଢିବାବେଳେ ସଫଳତାର କାହାଣୀ କିପରି ସହାୟକ ହୁଏ?",
              "as": "মাধৱে প্ৰক্ৰোতি মেডামৰ সফলতাৰ কাহিনীৰ দ্বাৰা অনুপ্ৰাণিত হৈ এজন আৰক্ষী বিষয়া হ'ব পাৰে। জীৱিকা গঢ়াৰ সময়ত সফলতাৰ কাহিনীবোৰ কেনেদৰে সহায়ক হয়?",
              "gu": "માધવ પોલીસ અધિકારી બનવા માટે પ્રકૃતિ મેડમની સફળતાની વાર્તાથી પ્રેરિત છે. કારકિર્દી ઘડતી વખતે સફળતાની વાર્તાઓ કેવી રીતે મદદરૂપ થાય છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "We can copy all the career choices of successful people.",
              "hi": "हम सफल लोगों के सभी करियर विकल्पों की  कॉपी/ प्रतिलिपि बना सकते हैं।",
              "ka": "ಯಶಸ್ವಿ ಜನರ ಎಲ್ಲಾ ವೃತ್ತಿ ಆಯ್ಕೆಗಳನ್ನು ನಾವು ನಕಲಿಸಬಹುದು.",
              "te": "మనము విజయవంతమైన వ్యక్తుల కెరీర్ ఎంపికలన్నింటినీ కాపీ చేయవచ్చు.",
              "or": "ଆମେ ସଫଳ ବ୍ୟକ୍ତିଙ୍କ ସମସ୍ତ କ୍ୟାରିଅର୍ ପସନ୍ଦକୁ କପି କରିପାରିବା |",
              "as": "আমি সফল লোকসকলৰ জীৱিকাৰ সকলোবোৰ বাছনি অনুসৰণ কৰিব পাৰোঁ।",
              "gu": "આપણે સફળ લોકોની કારકિર્દીની તમામ પસંદગીઓની નકલ કરી શકીએ છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "A tool to inspire you while also giving you inputs about an industry",
              "hi": "एक इंडस्ट्री के बारे में आपको जानकारी देते हुए आपको प्रेरित करने के लिए एक टूल",
              "ka": "ಉದ್ಯಮದ ಕುರಿತು ಇನ್‌ಪುಟ್‌ಗಳನ್ನು ನೀಡುವಾಗ ನಿಮ್ಮನ್ನು ಪ್ರೇರೇಪಿಸುವ ಸಾಧನ",
              "te": "ఇది ఇండస్ట్రీ గురించి మీకు ఇన్‌పుట్‌లను అందిస్తూ స్ఫూర్తినిచ్చే సాధనం",
              "or": "ଆପଣଙ୍କୁ ଏକ ଶିଳ୍ପ ବିଷୟରେ ଇନପୁଟ୍ ଦେବାବେଳେ ଆପଣଙ୍କୁ ପ୍ରେରଣା \n ଦେବା ପାଇଁ ଏକ ଉପକରଣ |",
              "as": "তোমাক অনুপ্ৰাণিত কৰাৰ আৰু লগতে তোমাক এটা উদ্যোগৰ বিষয়ে ইনপুট দিয়াৰ এক সঁজুলি",
              "gu": "તમને આ ક્ષેત્ર વિશે બતાવતી વખતે તમને પ્રેરણા આપવાનું એક સાધન"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Stories of people who train and become police officers.",
              "hi": "उन लोगों की कहानियां जो प्रशिक्षण लेते हैं और पुलिस अधिकारी बनते हैं।",
              "ka": "ತರಬೇತಿ ಪಡೆದು ಪೊಲೀಸ್ ಅಧಿಕಾರಿಗಳಾದ ಜನರ ಕಥೆಗಳು.",
              "te": "శిక్షణ పొంది పోలీసు అధికారులుగా మారిన వ్యక్తుల కథలు.",
              "or": "ଟ୍ରେନିଂ ଏବଂ ପୋଲିସ୍ ଅଫିସର ହେବାକୁ ଯାଉଥିବା ଲୋକଙ୍କ କାହାଣୀ |",
              "as": "প্ৰশিক্ষণ লৈ আৰক্ষী বিষয়া হোৱা লোকসকলৰ কাহিনী।",
              "gu": "એવા લોકોની વાર્તાઓ જેઓ તાલીમ આપીને પોલીસ ઓફિસર બને છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "Stories of family members who do well in their chosen career.",
              "hi": "परिवार के सदस्यों की कहानियां जो अपने चुने हुए करियर में अच्छा करते हैं।",
              "ka": "ತಾವು ಆಯ್ಕೆಮಾಡಿಕೊಂಡ ವೃತ್ತಿಜೀವನದಲ್ಲಿ ಉತ್ತಮ ಸಾಧನೆ ಮಾಡಿದ ಕುಟುಂಬದ ಸದಸ್ಯರ ಕಥೆಗಳು.",
              "te": "ఎంచుకున్న కెరీర్‌లో బాగా రాణిస్తున్న వారి కుటుంబ సభ్యుల కథలు.",
              "or": "ପରିବାର ସଦସ୍ୟଙ୍କ କାହାଣୀ, ଯେଉଁମାନେ ନିଜ ମନୋନୀତ କ୍ୟାରିଅରରେ \n ଭଲ କରନ୍ତି |",
              "as": "পৰিয়ালৰ সদস্যসকলৰ কাহিনী যিয়ে তেওঁলোকৰ বাছনি কৰা জীৱিকাত ভাল প্ৰদৰ্শন কৰিছে।",
              "gu": "કુટુંબના સભ્યોની વાર્તાઓ જેઓ તેમની પસંદ કરેલી કારકિર્દીમાં સારું પ્રદર્શન કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Think again! We need to make choices suited to our interests and abilities.",
              "hi": "फिर से विचार करो! हमें अपनी रुचियों और क्षमताओं के अनुकूल चुनाव करने की आवश्यकता है।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ನಮ್ಮ ಆಸಕ್ತಿಗಳು ಮತ್ತು ಸಾಮರ್ಥ್ಯಗಳಿಗೆ ಸೂಕ್ತವಾದ ಆಯ್ಕೆಗಳನ್ನು ನಾವು ಮಾಡಬೇಕಾಗಿರುತ್ತದೆ.",
              "te": "మళ్లీ ఆలోచించు! మన అభిరుచులు మరియు సామర్థ్యాలకు తగిన ఎంపికలు చేసుకోవాలి.",
              "or": "ପୁଣି ଥରେ ଭାବ! ଆମକୁ ଆମର ଆଗ୍ରହ ଏବଂ ସାମର୍ଥ୍ୟ ପାଇଁ ଉପଯୁକ୍ତ ବିଷୟ \n  ପସନ୍ଦ କରିବା ଆବଶ୍ୟକ |",
              "as": "আকৌ চিন্তা কৰা! আমি আমাৰ আগ্ৰহ আৰু সামৰ্থ্যৰ বাবে উপযুক্ত বাছনি কৰিব লাগিব।",
              "gu": "ફરીથી વિચારો! આપણે આપણી રુચિઓ અને ક્ષમતાઓને અનુરૂપ પસંદગીઓ કરવાની જરૂર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಉತ್ತಮ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ବହୁତ ବଢିଆ!",
              "as": "ভাল কৰিছা!",
              "gu": "સરસ કામ!"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Think again! Success stories can be of people from any field you wish to study.",
              "hi": "फिर से विचार करो! सफलता की कहानियां किसी भी क्षेत्र से लोगों की हो सकती हैं जिसे आप अध्ययन करना चाहते हैं।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಯಶಸ್ಸಿನ ಕಥೆಗಳು ನೀವು ಅಧ್ಯಯನ ಮಾಡಲು ಬಯಸುವ ಯಾವುದೇ ಕ್ಷೇತ್ರದ ಜನರದ್ದಾಗಿರಬಹುದು.",
              "te": "మళ్లీ ఆలోచించు! మీరు చదువుకోవాలనుకునే ఏ రంగానికి చెందిన వ్యక్తులవైనా విజయ గాథలు కావచ్చు.",
              "or": "ପୁଣି ଥରେ ଭାବ! ଆପଣ ଅଧ୍ୟୟନ କରିବାକୁ ଚାହୁଁଥିବା ଯେକୌଣସି \n  କ୍ଷେତ୍ରର ସଫଳତାର କାହାଣୀ ଲୋକମାନଙ୍କ ହୋଇପାରେ |",
              "as": "আকৌ চিন্তা কৰা! সফলতাৰ কাহিনী তুমি অধ্যয়ন কৰিব বিচৰা যিকোনো ক্ষেত্ৰৰ লোকৰ হ'ব পাৰে।",
              "gu": "ફરીથી વિચારો! સફળતાની વાર્તાઓ તમે અભ્યાસ કરવા માંગો છો તે કોઈપણ ક્ષેત્રના લોકોની હોઈ શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Think again! Success stories can be of anyone and not only our family members",
              "hi": "फिर से विचार करो! सफलता की कहानियां न केवल हमारे परिवार के सदस्यों की बल्कि किसी और की भी  हो सकती हैं",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಯಶಸ್ಸಿನ ಕಥೆಗಳು ಕೇವಲ ನಿಮ್ಮ ಕುಟುಂಬದ ಸದಸ್ಯರದ್ದು ಮಾತ್ರವಲ್ಲದೆ ಬೇರೆ ಯಾರದಾದರೂ ಆಗಿರಬಹುದು",
              "te": "మళ్లీ ఆలోచించు! విజయగాథలు మన కుటుంబ సభ్యులవే కాదు ఎవరివైనా కావచ్చు",
              "or": "ପୁଣି ଥରେ ଭାବ! ସଫଳତାର କାହାଣୀ କେବଳ ଆମର ପରିବାର ସଦସ୍ୟ\n  ନୁହେଁ କାହାର ହୋଇପାରେ |",
              "as": "আকৌ চিন্তা কৰা! সফলতাৰ কাহিনীবোৰ কেৱল আমাৰ পৰিয়ালৰ সদস্যৰ নহয় যিকোনো লোকৰ হ'ব পাৰে।",
              "gu": "ફરીથી વિચારો! સફળતાની વાર્તાઓ કોઈની પણ હોઈ શકે છે અને ફક્ત આપણા પરિવારના સભ્યોની જ નહીં"
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_2_1",
              "hi": "X3_2_1",
              "ka": "X3_2_1",
              "te": "X3_2_1",
              "or": "X3_2_1",
              "as": "X3_2_1",
              "gu": "X3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After getting a selfie with the IPS officer, the three of them discuss and think about their learnings.",
              "hi": "आईपीएस अधिकारी के साथ एक सेल्फी प्राप्त करने के बाद, वे तीनों चर्चा करते हैं और अपनी सीख के बारे में सोचते हैं।",
              "ka": "ಐಪಿಎಸ್ ಅಧಿಕಾರಿಯೊಂದಿಗೆ ಸೆಲ್ಫಿ ತೆಗೆದುಕೊಂಡ ನಂತರ, ಮೂವರೂ ತಮ್ಮ ಕಲಿಕೆಯ ಬಗ್ಗೆ ಚರ್ಚಿಸುತ್ತಾರೆ ಮತ್ತು ಯೋಚಿಸುತ್ತಾರೆ.",
              "te": "IPS అధికారితో సెల్ఫీ దిగిన తర్వాత ముగ్గురూ తమ చదువుల గురించి చర్చించుకుని ఆలోచిస్తారు.",
              "or": "ଆଇପିଏସ୍ ଅଧିକାରୀଙ୍କ ସହିତ ସେଲଫି ପାଇବା ପରେ, ତିନିଜଣ ସେମାନଙ୍କ \n ଶିକ୍ଷା ବିଷୟରେ ଆଲୋଚନା କରନ୍ତି ଏବଂ ଚିନ୍ତା କରନ୍ତି |",
              "as": "IPS বিষয়াগৰাকীৰ সৈতে চেলফি লোৱাৰ পিছত, তেওঁলোক তিনিওজনে তেওঁলোকৰ শিক্ষাৰ বিষয়ে আলোচনা কৰে আৰু চিন্তা কৰে।",
              "gu": "IPS ઓફિસર સાથે સેલ્ફી લીધા પછી, તે ત્રણેય ચર્ચા કરે છે અને તે શીખવા વિશે વિચારે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_2_2_ClickToReveal",
              "hi": "X3_2_2_क्लिकटूरिवील ",
              "ka": "X3_2_2_ClickToReveal",
              "te": "X3_2_2_Reveal కోసం క్లిక్ చేయండి",
              "or": "X3_2_2_ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "X3_2_2_জানিবলৈ ক্লিক কৰা",
              "gu": "X3_2_2_જાહેર કરવા માટે ક્લિક કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal to see their thoughts:",
              "hi": "उनके विचारों को देखने के लिए क्लिक करें:",
              "ka": "ಅವರ ಆಲೋಚನೆಗಳನ್ನು ನೋಡಲು ಹಾಗೂ ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "వారి ఆలోచనలను చూడటానికి రివీల్ చేయడానికి క్లిక్ చేయండి:",
              "or": "ସେମାନଙ୍କର ଚିନ୍ତାଧାରା ଦେଖିବାକୁ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "তেওঁলোকৰ চিন্তাবোৰ জানিবলৈ ক্লিক কৰা:",
              "gu": "તેમના વિચારો જોવા માટે જાહેર કરવા ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Badri",
              "hi": "बद्री",
              "ka": "ಬದ್ರಿ",
              "te": "బద్రి",
              "or": "ବଦ୍ରି",
              "as": "বদ্ৰী",
              "gu": "બદ્રી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୁଳଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "I feel like her talk has given me a direction in life. I did not know there was something called the Union Public Service Commission Exam (UPSC).",
              "hi": "मुझे लगता है कि उनकी बात ने मुझे जीवन में एक दिशा दी है। मुझे नहीं पता था कि संघ लोक सेवा आयोग परीक्षा (यूपीएससी) नामक कुछ था।",
              "ka": "ಆಕೆಯ ಮಾತು ನನಗೆ ಜೀವನದಲ್ಲಿ ಒಂದು ದಿಕ್ಕನ್ನು ನೀಡಿದೆ ಎಂದು ನನಗೆ ಅನಿಸುತ್ತದೆ. ಯೂನಿಯನ್ ಪಬ್ಲಿಕ್ ಸರ್ವಿಸ್ ಕಮಿಷನ್ ಎಕ್ಸಾಮ್ (ಯುಪಿಎಸ್‌ಸಿ) ಎಂಬುದೊಂದು ಇದೆ ಎಂದು ನನಗೆ ತಿಳಿದಿರಲಿಲ್ಲ.",
              "te": "ఆమె మాటలు నాకు జీవితంలో ఒక దిశానిర్దేశం చేసినట్లు నేను భావిస్తున్నాను. యూనియన్ పబ్లిక్ సర్వీస్ కమీషన్ పరీక్ష (UPSC) అని ఏదో ఒకటి ఉందని నాకు తెలియదు.",
              "or": "ମୋତେ ଲାଗୁଛି ଯେ ତାଙ୍କ କଥାବାର୍ତ୍ତା ମୋତେ ଜୀବନର ଏକ ଦିଗ ଦେଇଛି |\n  ୟୁନିୟନ ପବ୍ଲିକ ସର୍ଭିସ କମିଶନ ପରୀକ୍ଷା (UPSC) ନାମକ କିଛି ଅଛି ବୋଲି ମୁଁ ଜାଣି ନଥିଲି |",
              "as": "মই অনুভৱ কৰোঁ যেন তেখেতৰ কথাবোৰে মোক জীৱনৰ এটা দিশ দিছে। মই নাজানিছিলোঁ যে ইউনিয়ন পাব্লিক চাৰ্ভিচ কমিছন পৰীক্ষা (UPSC) নামৰ কিবা এটা আছে।",
              "gu": "મને લાગે છે કે તેણીની વાતોએ મને જીવનમાં એક દિશા આપી છે. મને ખબર ન હતી કે યુનિયન પબ્લિક સર્વિસ કમિશન પરીક્ષા (UPSC) કહેવાય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Listening to her made me realise that focusing on a goal is essential.",
              "hi": "उन्हें सुनकर मुझे अनुभव हुआ कि एक लक्ष्य पर ध्यान केंद्रित करना आवश्यक है।",
              "ka": "ಆಕೆಯ ಮಾತನ್ನು ಕೇಳಿದ ಮೇಲೆ ಒಂದು ನಿರ್ದಿಷ್ಟ ಗುರಿಯತ್ತ ಗಮನ ಹರಿಸುವುದು ಅತ್ಯಗತ್ಯ ಎಂದು ನನಗೆ ಅರಿವಾಯಿತು",
              "te": "ఆమె మాటలు వినడం వల్ల లక్ష్యంపై దృష్టి పెట్టడం చాలా అవసరమని నాకు అర్థమైంది.",
              "or": "ତାଙ୍କ କଥା ଶୁଣିବା ମୋତେ ଅନୁଭବ କଲା ଯେ ଏକ ଲକ୍ଷ୍ୟ ଉପରେ ଧ୍ୟାନ\n  ଦେବା ଜରୁରୀ ଅଟେ |",
              "as": "তেখেতৰ কথা শুনি মই অনুভৱ কৰিছিলোঁ যে এটা লক্ষ্যত মনোনিবেশ কৰাটো দৰকাৰী।",
              "gu": "તેની વાત સાંભળીને મને ખ્યાલ આવ્યો કે ધ્યેય પર ધ્યાન કેન્દ્રિત કરવું જરૂરી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "I want to become an officer like her. She said we need a graduation degree to apply for the exam, that means we still have time!",
              "hi": "मैं उनके जैसा अधिकारी बनना चाहता हूं। उन्होंने कहा कि परीक्षा हेतु आवेदन करने के लिए हमें स्नातक की डिग्री की आवश्यकता है, इसका अर्थ है कि हमारे पास अभी भी समय है!",
              "ka": "ನಾನು ಆಕೆಯಂತೆ ಅಧಿಕಾರಿಯಾಗಲು ಬಯಸುತ್ತೇನೆ. ಪರೀಕ್ಷೆಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಾವು ಪದವೀಧರರಾಗಬೇಕು, ಅಂದರೆ ನಮಗೆ ಇನ್ನೂ ಸಮಯವಿದೆ ಎಂದು ಆರ್ಥ!",
              "te": "నేనూ ఆమెలా ఆఫీసర్‌ కావాలని కోరుకుంటున్నాను. పరీక్షకు అప్లై చేసుకోవడానికి మనకు గ్రాడ్యుయేషన్ డిగ్రీ కావాలి అని ఆమె చెప్పింది, అంటే మనకు ఇంకా సమయం ఉంది!",
              "or": "ମୁଁ ତାଙ୍କ ପରି ଜଣେ ଅଧିକାରୀ ହେବାକୁ ଚାହୁଁଛି | ସେ କହିଛନ୍ତି ଯେ ପରୀକ୍ଷା \n ପାଇଁ ଆବେଦନ କରିବା ପାଇଁ ଆମକୁ ସ୍ନାତକୋତ୍ତର ଡିଗ୍ରୀ ଦରକାର, ଏହାର ଅର୍ଥ ଆମର ତଥାପି ସମୟ ଅଛି!",
              "as": "মই তেখেতৰ দৰে এজন বিষয়া হ'ব বিচাৰোঁ। তেখেতে কৈছিল যে পৰীক্ষাৰ বাবে আবেদন কৰিবলৈ আমাক স্নাতক ডিগ্ৰীৰ প্ৰয়োজন, ইয়াৰ অৰ্থ হৈছে আমাৰ ওচৰত এতিয়াও সময় আছে!",
              "gu": "હું તેના જેવો અધિકારી બનવા માંગુ છું. તેએ કહ્યું કે પરીક્ષા માટે અરજી કરવા માટે અમારે ગ્રેજ્યુએશનની ડિગ્રીની જરૂર છે, તેનો અર્થ એ કે અમારી પાસે હજુ પણ સમય છે!"
            }
          }
        ]
      },
      "scene4": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_1//",
              "hi": "X4_1_1 //",
              "ka": "X4_1_1//",
              "te": "X4_1_1//",
              "or": "X4_1_1//",
              "as": "X4_1_1//",
              "gu": "X4_1_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "There is so much to find out! I feel like I can rest peacefully only after collecting all the information.",
              "hi": "पता लगाने के लिए बहुत कुछ है! मुझे लगता है कि मैं सभी जानकारी एकत्र करने के बाद ही शांतिपूर्वक आराम कर सकता हूं।",
              "ka": "ಕಂಡುಹಿಡಿಯಲು ತುಂಬಾ ಇದೆ! ಎಲ್ಲಾ ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸಿದ ನಂತರವೇ ನಾನು ಶಾಂತವಾಗಿ ವಿಶ್ರಾಂತಿ ಪಡೆಯಬಹುದು ಎಂದು ನನಗೆ ಅನಿಸುತ್ತದೆ.",
              "te": "తెలుసుకోవలసింది చాలా ఉంది! మొత్తం సమాచారాన్ని సేకరించిన తర్వాత మాత్రమే నేను ప్రశాంతంగా విశ్రాంతి తీసుకోగలనని భావిస్తున్నాను.",
              "or": "ଜାଣିବାକୁ ବହୁତ କିଛି ଅଛି! ମୋତେ ଲାଗୁଛି ଯେ ସମସ୍ତ ତଥ୍ୟ ସଂଗ୍ରହ \n କରିବା ପରେ ହିଁ ମୁଁ ଶାନ୍ତିରେ ବିଶ୍ରାମ ନେଇପାରେ |",
              "as": "বিচাৰি উলিওৱাৰ বাবে বহুত আছে! মোৰ এনেকুৱা লাগিছে যেন সকলো তথ্য সংগ্ৰহ কৰাৰ পিছতহে মই শান্তিৰে বিশ্ৰাম ল'ব পাৰিম।",
              "gu": "શોધવા માટે ઘણું બધું છે! મને લાગે છે કે બધી માહિતી એકત્રિત કર્યા પછી જ હું શાંતિથી આરામ કરી શકીશ."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_2",
              "hi": "X4_1_2",
              "ka": "X4_1_2",
              "te": "X4_1_2",
              "or": "X4_1_2",
              "as": "X4_1_2",
              "gu": "X4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "All 3 of them find out more about Civil Services. Badri decides to go straight to the library to read all the books available, and Kuldeep decides to talk to Alumni to learn more.",
              "hi": "वे तीनों सिविल सेवाओं के बारे में अधिक जानकारी प्राप्त करते हैं। बद्री ने सभी पुस्तकों को पढ़ने के लिए पुस्तकालय में सीधे जाने का निर्णय किया, और कुलदीप ने अधिक जानने के लिए पूर्व छात्रों से बात करने का निर्णय किया।",
              "ka": "ಆ ಮೂವರೂ ಸಿವಿಲ್ ಸೇವೆಗಳ ಬಗ್ಗೆ ಹೆಚ್ಚು ತಿಳಿದುಕೊಳ್ಳುತ್ತಾರೆ. ಲಭ್ಯವಿರುವ ಎಲ್ಲಾ ಪುಸ್ತಕಗಳನ್ನು ಓದಲು ಬದ್ರಿ ನೇರವಾಗಿ ಲೈಬ್ರರಿಗೆ ಹೋಗಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ ಮತ್ತು ಕುಲದೀಪ್ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳೊಂದಿಗೆ ಮಾತನಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "ఈ ముగ్గురూ సివిల్ సర్వీసెస్ గురించి మరింత తెలుసుకుంటారు. అందుబాటులో ఉన్న అన్ని పుస్తకాలను చదవడానికి బద్రి నేరుగా లైబ్రరీకి వెళ్లాలని నిర్ణయించుకున్నాడు మరియు కుల్దీప్ మరింత తెలుసుకోవడానికి పూర్వ విద్యార్థులతో మాట్లాడాలని నిర్ణయించుకున్నాడు.",
              "or": "ସିଭିଲ୍ ସର୍ଭିସେସ୍ ବିଷୟରେ ସେମାନଙ୍କ ମଧ୍ୟରୁ 3 ଜଣ ଅଧିକ ଜାଣନ୍ତି |\n  ଉପଲବ୍ଧ ସମସ୍ତ ପୁସ୍ତକ ପଢିବା ପାଇଁ ବଦ୍ରି ସିଧା ଲାଇବ୍ରେରୀକୁ ଯିବାକୁ ସ୍ଥିର କରେ ଏବଂ କୁଲଦୀପ ଅଧିକ ଜାଣିବା ପାଇଁ ଆଲୁମିନି ସହିତ କଥା ହେବାକୁ ନିଷ୍ପତ୍ତି ନେଇଥାଏ |",
              "as": "তেওঁলোক ৩ জনে অসামৰিক সেৱাৰ বিষয়ে আৰু অধিক বিচাৰ খোচাৰ কৰে। বদ্ৰীয়ে উপলব্ধ সকলো কিতাপ পঢ়িবলৈ পোনপটীয়াকৈ পুথিভঁৰাললৈ যোৱাৰ সিদ্ধান্ত লয়, আৰু কুলদীপে অধিক শিকিবলৈ প্ৰাক্তন ছাত্ৰ-ছাত্ৰীৰ সৈতে কথা পাতিবলৈ সিদ্ধান্ত লয়।",
              "gu": "તે 3 એ સિવિલ સર્વિસીસો વિશે વધુ માહિતી મેળવે છે. બદ્રી ઉપલબ્ધ તમામ પુસ્તકો વાંચવા માટે સીધા જ લાઈબ્રેરીમાં જવાનું નક્કી કરે છે, અને કુલદીપ વધુ જાણવા માટે ભૂતપૂર્વ વિદ્યાર્થીઓ સાથે વાત કરવાનું નક્કી કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_3_D",
              "hi": "X4_1_3_d",
              "ka": "X4_1_3_D",
              "te": "X4_1_3_D",
              "or": "X4_1_3_D",
              "as": "X4_1_3_D",
              "gu": "X4_1_3_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Madhav do?",
              "hi": "माधव को क्या करना चाहिए?",
              "ka": "ಮಾಧವ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "మాధవ్ ఏం చేయాలి?",
              "or": "ମାଧବ କ’ଣ କରିବା ଉଚିତ୍?",
              "as": "মাধৱে কি কৰা উচিত?",
              "gu": "માધવે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Read books with Badri to research about Civil Services",
              "hi": "सिविल सेवाओं के बारे में अनुसंधान के लिए बद्री के साथ किताबें पढ़े",
              "ka": "ನಾಗರಿಕ ಸೇವೆಗಳ ಬಗ್ಗೆ ಸಂಶೋಧನೆ ಮಾಡಲು ಬದ್ರಿಯೊಂದಿಗೆ ಪುಸ್ತಕಗಳನ್ನು ಓದುವುದು",
              "te": "సివిల్ సర్వీసెస్ గురించి పరిశోధన చేయడానికి బద్రితో పుస్తకాలు చదవాలి",
              "or": "ସିଭିଲ ସର୍ଭିସେସ୍ ବିଷୟରେ ଗବେଷଣା କରିବା ପାଇଁ ବଦ୍ରି ସହିତ ବହି ପଢନ୍ତୁ |",
              "as": "অসামৰিক সেৱাৰ বিষয়ে গৱেষণা কৰিবলৈ বদ্ৰীৰ সৈতে কিতাপ পঢ়িব",
              "gu": "સિવિલ સર્વિસિસ વિશે સંશોધન કરવા બદ્રી સાથે પુસ્તકો વાંચો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Talk to Alumni with Kuldeep to know about Civil Services",
              "hi": "सिविल  सेवाओं के बारे में जानने के लिए कुलदीप के साथ पूर्व छात्रों से बात करे",
              "ka": "ನಾಗರಿಕ ಸೇವೆಗಳ ಬಗ್ಗೆ ತಿಳಿಯಲು ಕುಲದೀಪ್ನೊಂದಿಗೆಗೆ ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳೊಂದಿಗೆ ಮಾತನಾಡುವುದು",
              "te": "సివిల్ సర్వీసెస్ గురించి తెలుసుకోవడానికి కుల్దీప్‌తో పూర్వ విద్యార్థులతో మాట్లాడాలి",
              "or": "ସିଭିଲ ସର୍ଭିସେସ୍ ବିଷୟରେ ଜାଣିବା ପାଇଁ କୁଲଦୀପଙ୍କ ସହ ଆଲୁମିନି\n  ସହିତ କଥା ହୁଅ |",
              "as": "অসামৰিক সেৱাৰ বিষয়ে জানিবলৈ কুলদীপৰ সৈতে প্ৰাক্তন ছাত্ৰ-ছাত্ৰীৰ লগত কথা পাতিব",
              "gu": "સિવિલ સર્વિસીસ વિશે જાણવા માટે કુલદીપ સાથે ભૂતપૂર્વ વિદ્યાર્થીઓ સાથે વાત કરો"
            }
          }
        ]
      },
      "scene5": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4(i)_1_1",
              "hi": "X4 (i) _1_1",
              "ka": "X4(i)_1_1",
              "te": "X4(i)_1_1",
              "or": "X4(i)_1_1",
              "as": "X4(i)_1_1",
              "gu": "X4(i)_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav and Badri go to the college library to find books about Civil Services. They want to learn more about the work done by IAS officers.",
              "hi": "सिविल सेवाओं के बारे में किताबें खोजने के लिए माधव और बद्री कॉलेज लाइब्रेरी में जाते हैं। वे आईएएस अधिकारियों द्वारा किए गए कार्यों के बारे में अधिक जानना चाहते हैं।",
              "ka": "ಮಾಧವ್ ಮತ್ತು ಬದರಿ ಸಿವಿಲ್ ಸರ್ವೀಸಸ್ ಬಗ್ಗೆ ಪುಸ್ತಕಗಳನ್ನು ಹುಡುಕಲು ಕಾಲೇಜು ಲೈಬ್ರರಿಗೆ ಹೋಗುತ್ತಾರೆ. ಅವರು ಐಎಎಸ್ ಅಧಿಕಾರಿಗಳು ಮಾಡುವ ಕೆಲಸದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಬಯಸುತ್ತಾರೆ.",
              "te": "మాధవ్ మరియు బద్రి సివిల్ సర్వీసెస్ గురించి పుస్తకాలు వెతకడానికి కాలేజీ లైబ్రరీకి వెళతారు. IAS అధికారులు చేసే పని గురించి మరింత తెలుసుకోవాలన్నారు.",
              "or": "ସିଧା ସେବା ବିଷୟରେ ପୁସ୍ତକ ଖୋଜିବା ପାଇଁ ମାଧବ ଏବଂ ବଦ୍ରି କଲେଜ\n  ଲାଇବ୍ରେରୀକୁ ଯାଆନ୍ତି | ସେମାନେ IAS ଅଧିକାରୀଙ୍କ ଦ୍ୱାରା କରାଯାଇଥିବା କାର୍ଯ୍ୟ ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ଚାହାଁନ୍ତି |",
              "as": "মাধৱ আৰু বদ্ৰীয়ে অসামৰিক সেৱাৰ বিষয়ে কিতাপ বিচাৰিবলৈ মহাবিদ্যালয়ৰ পুথিভঁৰাললৈ যায়। তেওঁলোকে IAS বিষয়াসকলৰ দ্বাৰা কৰা কামৰ বিষয়ে অধিক শিকিবলৈ বিচাৰে।",
              "gu": "માધવ અને બદ્રી સિવિલ સર્વિસિસ વિશે પુસ્તકો શોધવા કૉલેજની લાઇબ્રેરીમાં જાય છે. તેઓ IAS અધિકારીઓ દ્વારા કરવામાં આવતા કામ વિશે વધુ જાણવા માંગે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4(i)_1_2",
              "hi": "X4 (i) _1_2",
              "ka": "X4(i)_1_2",
              "te": "X4(i)_1_2",
              "or": "X4(i)_1_2",
              "as": "X4(i)_1_2",
              "gu": "X4(i)_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav finds a book called 'The Steel Frame: A History of the IAS.' He learns a lot about the Indian Administrative Service (IAS) and their work.",
              "hi": "माधव को एक पुस्तक मिलती है 'द स्टील फ्रेम: आईएएस का इतिहास'। वह भारतीय प्रशासनिक सेवा (आईएएस) और उनके काम के बारे में बहुत कुछ सीखता है।",
              "ka": "ಮಾಧವ್  'ದಿ ಸ್ಟೀಲ್ ಫ್ರೇಮ್: ಎ ಹಿಸ್ಟರಿ ಆಫ್ ದಿ ಐಎಎಸ್' ಎಂಬ ಪುಸ್ತಕವನ್ನು ನೋಡುತ್ತಾನೆ. ಅವನು ಭಾರತೀಯ ಆಡಳಿತ ಸೇವೆ (IAS) ಮತ್ತು ಅವರ ಕೆಲಸದ ಬಗ್ಗೆ ಬಹಳಷ್ಟು ಕಲಿಯುತ್ತಾನೆ",
              "te": "మాధవ్‌కి 'ది స్టీల్ ఫ్రేమ్: ఎ హిస్టరీ ఆఫ్ ది IAS' అనే పుస్తకం దొరికింది. అతను ఇండియన్ అడ్మినిస్ట్రేటివ్ సర్వీస్ (IAS) మరియు వారి పని గురించి చాలా నేర్చుకుంటాడు.",
              "or": "ମାଧବ 'ଇସ୍ପାତ ଫ୍ରେମ୍: ଏକ ଇତିହାସ ର IAS' ନାମକ ଏକ ପୁସ୍ତକ ପାଇଲେ |\n  ସେ ଭାରତୀୟ ପ୍ରଶାସନିକ ସେବା (IAS) ଏବଂ ସେମାନଙ୍କ କାର୍ଯ୍ୟ ବିଷୟରେ ବହୁତ କିଛି ଜାଣନ୍ତି |",
              "as": "মাধৱে 'দ্য ষ্টীল ফ্ৰেম: এ হিষ্ট্ৰী অৱ দ্য IAS' নামৰ এখন কিতাপ বিচাৰি পালে। তেওঁ ভাৰতীয় প্ৰশাসনিক সেৱা (IAS) আৰু তেওঁলোকৰ কামৰ বিষয়ে বহুকথা শিকে।",
              "gu": "માધવને 'ધ સ્ટીલ ફ્રેમઃ એ હિસ્ટ્રી ઓફ ધ આઈએએસ' નામનું પુસ્તક મળ્યું. તે ભારતીય વહીવટી સેવા (IAS) અને તેમના કામ વિશે ઘણું શીખે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4(i)_1_3",
              "hi": "X4 (i) _1_3",
              "ka": "X4(i)_1_3",
              "te": "X4(i)_1_3",
              "or": "X4(i)_1_3",
              "as": "X4(i)_1_3",
              "gu": "X4(i)_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is also searching online for e-books about civil services. He has downloaded all the relevant books and made notes.",
              "hi": "माधव सिविल सेवाओं के बारे में ई-किताबें ऑनलाइन खोज रहा है। उसने सभी प्रासंगिक किताबें डाउनलोड की हैं और नोट्स बनाए हैं।",
              "ka": "ಮಾಧವ್  ನಾಗರಿಕ ಸೇವೆಗಳ ಬಗ್ಗೆ ಇ-ಪುಸ್ತಕಗಳಿಗಾಗಿ ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಹುಡುಕುತ್ತಿದ್ದಾನೆ. ಸಂಬಂಧಪಟ್ಟ ಪುಸ್ತಕಗಳನ್ನೆಲ್ಲ ಡೌನ್ ಲೋಡ್ ಮಾಡಿಕೊಂಡು ಟಿಪ್ಪಣಿ ಮಾಡಿಕೊಂಡಿದ್ದಾನೆ.",
              "te": "మాధవ్ సివిల్ సర్వీసెస్ గురించిన ఈ-బుక్స్ కోసం ఆన్‌లైన్‌లో కూడా వెతుకుతున్నాడు. సంబంధిత పుస్తకాలన్నింటినీ డౌన్‌లోడ్ చేసుకుని నోట్స్ తయారు చేసుకున్నాడు.",
              "or": "ନାଗରିକ ସେବା ବିଷୟରେ ଇ-ବୁକ୍ ପାଇଁ ମଧ୍ୟ ମାଧବ ଅନଲାଇନରେ \n ଖୋଜୁଛି | ସେ ସମସ୍ତ ପ୍ରାସଙ୍ଗିକ ପୁସ୍ତକ ଡାଉନଲୋଡ୍ କରି ନୋଟ୍ କରିଛନ୍ତି |",
              "as": "মাধৱে অসামৰিক সেৱাৰ বিষয়ে অনলাইনতো ই-কিতাপ বিচাৰি আছে। তেওঁ সকলো প্ৰাসংগিক কিতাপ ডাউনলোড কৰিছে আৰু টোকা বনাইছে।",
              "gu": "માધવ સિવિલ સર્વિસ વિશેની ઈ-બુક્સ માટે પણ ઓનલાઈન સોધ કરી રહ્યો છે. તેણે તમામ સંબંધિત પુસ્તકો ડાઉનલોડ કરીને નોંધો બનાવી છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4(i)_1_4_notebook",
              "hi": "X4 (i) _1_4_नोटबुक ",
              "ka": "X4(i)_1_4_notebook",
              "te": "X4(i)_1_4_నోట్‌బుక్",
              "or": "X4 (i) _1_4_ ନୋଟବୁକ୍ |",
              "as": "X4(i)_1_4_টোকাবহী",
              "gu": "X4(i)_1_4_નોટબુક"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to have a look at his notes:",
              "hi": "उसके नोट्स पर एक नज़र डालने के लिए क्लिक करें:",
              "ka": "ಅವನ ಟಿಪ್ಪಣಿಗಳನ್ನು ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "అతని నోట్స్ చూడటానికి క్లిక్ చేయండి:",
              "or": "ତାଙ୍କ ନୋଟ୍ ଗୁଡିକୁ ଦେଖିବା ପାଇଁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "তেওঁৰ টোকাবোৰ চাবলৈ ক্লিক কৰা:",
              "gu": "તેની નોંધો જોવા માટે ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page 1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "ପୃଷ୍ଠା ୧",
              "as": "পৃষ্ঠা 1",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page 2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ 2",
              "or": "ପୃଷ୍ଠା ୨",
              "as": "পৃষ্ঠা 2",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Page 3",
              "hi": "पृष्ठ 3",
              "ka": "ಪುಟ 3",
              "te": "పేజీ 3",
              "or": "ପୃଷ୍ଠା ୩",
              "as": "পৃষ্ঠা 3",
              "gu": "પેજ 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "**What is Civil Service, and how can I join it?** \n\n\nCivil service employees work under the government to provide a service to the nation's citizens, for example, police officers, tax collectors etc. You can join the civil services after clearing the Civil Services Exam (UPSC exam).",
              "hi": "** सिविल सेवा क्या है, और मैं इससे कैसे जुड़ सकता हूं? **\n\n\n देश के नागरिकों को एक सेवा प्रदान करने के लिए सिविल सेवा कर्मचारी सरकार के तहत काम करते हैं, उदाहरण के लिए, पुलिस अधिकारी, टैक्स कलेक्टर इत्यादि। सिविल सेवा परीक्षा (यूपीएससी परीक्षा) को उत्तीर्ण के बाद आप सिविल सेवाओं में शामिल हो सकते हैं।",
              "ka": "**ನಾಗರಿಕ ಸೇವೆ ಎಂದರೇನು ಮತ್ತು ನಾನು ಅದನ್ನು ಹೇಗೆ ಸೇರಬಹುದು?**                                                                                                    ನಾಗರಿಕ ಸೇವಾ ನೌಕರರು ರಾಷ್ಟ್ರದ ನಾಗರಿಕರಿಗೆ ಸೇವೆಯನ್ನು ಒದಗಿಸಲು ಸರ್ಕಾರದ ಅಡಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಾರೆ, ಉದಾಹರಣೆಗೆ, ಪೊಲೀಸ್ ಅಧಿಕಾರಿಗಳು, ತೆರಿಗೆ ಸಂಗ್ರಹಕಾರರು ಇತ್ಯಾದಿ. ನೀವು ನಾಗರಿಕ ಸೇವೆಗಳ ಪರೀಕ್ಷೆಯಲ್ಲಿ (UPSC ಪರೀಕ್ಷೆ) ತೇರ್ಗಡೆಗೊಂಡ ನಂತರ ನಾಗರಿಕ ಸೇವೆಗಳಿಗೆ ಸೇರಬಹುದು.\"",
              "te": "**సివిల్ సర్వీస్ అంటే ఏమిటి, నేను అందులో ఎలా చేరగలను?**\n \n \n \n \n \n సివిల్ సర్వీస్ ఉద్యోగులు దేశ పౌరులకు సేవ చేయడానికి ప్రభుత్వం క్రింద పని చేస్తారు, ఉదాహరణకు, పోలీసు అధికారులు, పన్ను వసూలు చేసేవారు మొదలైనవి. మీరు సివిల్ సర్వీసెస్ పరీక్ష (UPSC పరీక్ష) క్లియర్ చేసిన తర్వాత సివిల్ సర్వీసెస్‌లో చేరవచ్చు.",
              "or": "** ସିଭିଲ୍ ସର୍ଭିସ୍ କ’ଣ, ଏବଂ ମୁଁ ଏଥିରେ କିପରି ଯୋଗ ଦେଇ ପାରିବି? **\n \n \n \n \n \n ସିଭିଲ ସର୍ଭିସ କର୍ମଚାରୀମାନେ ଦେଶର ନାଗରିକମାନଙ୍କୁ ଏକ ସେବା ଯୋଗାଇବା ପାଇଁ ସରକାରଙ୍କ ଅଧୀନରେ କାମ କରନ୍ତି, ଉଦାହରଣ ସ୍ୱରୂପ, ପୋଲିସ ଅଫିସର, ଟ୍ୟାକ୍ସ ସଂଗ୍ରହକାରୀ ଇତ୍ୟାଦି ଆପଣ ସିଭିଲ ସର୍ଭିସ ପରୀକ୍ଷା (UPSC ପରୀକ୍ଷା) ପାସ କରିବା ପରେ ସିଭିଲ ସର୍ଭିସରେ ଯୋଗ ଦେଇପାରିବେ |",
              "as": "**অসামৰিক সেৱা কি, আৰু মই ইয়াত কেনেকৈ যোগদান কৰিব পাৰোঁ?** অসামৰিক সেৱাৰ কৰ্মচাৰীসকলে ৰাষ্ট্ৰৰ নাগৰিকৰ সেৱাৰ বাবে চৰকাৰৰ অধীনত কাম কৰে। উদাহৰণস্বৰূপে, আৰক্ষী বিষয়া, কৰ সংগ্ৰাহক আদি। অসামৰিক সেৱাৰ পৰীক্ষা (UPSCপৰীক্ষা) উত্তীৰ্ণ হোৱাৰ পিছত তুমি অসামৰিক সেৱাত যোগদান কৰিব পাৰা।",
              "gu": "**સિવિલ સર્વિસ શું છે, અને હું તેમાં કેવી રીતે જોડાઈ શકું?** સિવિલ સર્વિસના કર્મચારીઓ દેશના નાગરિકોને સેવા પૂરી પાડવા માટે સરકાર હેઠળ કામ કરે છે, ઉદાહરણ તરીકે, પોલીસ અધિકારીઓ, ટેક્સ કલેક્ટર વગેરે. ક્લિયરિંગ કર્યા પછી તમે સિવિલ સર્વિસમાં જોડાઈ શકો છો. સિવિલ સર્વિસ પરીક્ષા (UPSC પરીક્ષા)."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "**What work can I do after joining the Civil Services?**\n\nThere are 24 different types of posts in Civil Services. Some of the popular ones and the work they do are:\n1. Indian Administrative Service (IAS): Responsible for formulating and implementing Government policies.\n2. Indian Police Service (IPS): Responsible for maintenance of public peace and order, crime prevention, investigation, and detection",
              "hi": "** सिविल सेवाओं में शामिल होने के बाद मैं क्या काम कर सकता हूं? **\n\nसिविल सेवाओं में 24 विभिन्न प्रकार के पद हैं। कुछ लोकप्रिय हैं और वे यह काम करते हैं:\n1. भारतीय प्रशासनिक सेवा (आईएएस): सरकारी नीतियों को तैयार करने और कार्यान्वित करने के लिए जिम्मेदार।\n2. भारतीय पुलिस सेवा (आईपीएस): सार्वजनिक शांति और आदेश, अपराध की रोकथाम, जांच, और पहचान के रखरखाव के लिए जिम्मेदार",
              "ka": "**ನಾಗರಿಕ ಸೇವೆಗಳಿಗೆ ಸೇರಿದ ನಂತರ ನಾನು ಯಾವ ಕೆಲಸವನ್ನು ಮಾಡಬಹುದು?**                                                                                         ನಾಗರಿಕ ಸೇವೆಗಳಲ್ಲಿ 24 ವಿವಿಧ ರೀತಿಯ ಹುದ್ದೆಗಳಿವೆ. ಕೆಲವು ಜನಪ್ರಿಯವಾದವುಗಳು ಮತ್ತು ಅವರು ಮಾಡುವ ಕೆಲಸಗಳು ಯಾವುದೆಂದರೆ\n1. ಭಾರತೀಯ ಆಡಳಿತ ಸೇವೆ (IAS): ಸರ್ಕಾರದ ನೀತಿಗಳನ್ನು ರೂಪಿಸುವ ಮತ್ತು ಅನುಷ್ಠಾನಗೊಳಿಸುವ ಜವಾಬ್ದಾರಿ.\n2. ಭಾರತೀಯ ಪೊಲೀಸ್ ಸೇವೆ (IPS): ಸಾರ್ವಜನಿಕ ಶಾಂತಿ ಮತ್ತು ಸುವ್ಯವಸ್ಥೆಯ ನಿರ್ವಹಣೆ, ಅಪರಾಧ ತಡೆಗಟ್ಟುವಿಕೆ, ತನಿಖೆ ಮತ್ತು ಪತ್ತೆಗೆ ಜವಾಬ್ದಾರಿ\"",
              "te": "**సివిల్ సర్వీసెస్‌లో చేరిన తర్వాత నేను ఏ పని చేయగలను?**\n \n \n \n సివిల్ సర్వీసెస్‌లో 24 రకాల పోస్టులు ఉన్నాయి. కొన్ని ప్రసిద్ధమైనవి మరియు వారు చేసే పని:\n \n 1. ఇండియన్ అడ్మినిస్ట్రేటివ్ సర్వీస్ (IAS): ప్రభుత్వ విధానాలను రూపొందించడం మరియు అమలు చేయవలసిన బాధ్యత.\n \n 2. ఇండియన్ పోలీస్ సర్వీస్ (IPS): ప్రజా శాంతి భద్రతల నిర్వహణ, నేరాల నివారణ, దర్యాప్తు మరియు గుర్తింపు వంటి వాటికి బాధ్యత వహిస్తుంది",
              "or": "** ସିଭିଲ ସର୍ଭିସରେ ଯୋଗଦେବା ପରେ ମୁଁ କେଉଁ କାମ କରିପାରିବି? **\n \n \n \n ସିଭିଲ ସର୍ଭିସରେ ୨୪ ଟି ବିଭିନ୍ନ ପ୍ରକାରର ପୋଷ୍ଟ ଅଛି | କେତେକ ଲୋକପ୍ରିୟ ଏବଂ ସେମାନେ କରୁଥିବା କାର୍ଯ୍ୟଗୁଡ଼ିକ ହେଉଛି:\n \n 1. ଭାରତୀୟ ପ୍ରଶାସନିକ ସେବା (IAS): ସରକାରୀ ନୀତି ପ୍ରସ୍ତୁତ ଏବଂ କାର୍ଯ୍ୟକାରୀ କରିବା ପାଇଁ ଦାୟୀ |\n \n 2. ଭାରତୀୟ ପୋଲିସ୍ ସେବା (ଆଇପିଏସ୍): ଜନସାଧାରଣଙ୍କ ଶାନ୍ତି ଏବଂ ଶୃଙ୍ଖଳା ରକ୍ଷା, ଅପରାଧ ରୋକିବା, ଅନୁସନ୍ଧାନ ଏବଂ ଚିହ୍ନଟ ପାଇଁ ଦାୟୀ।",
              "as": "**অসামৰিক সেৱাত যোগদান কৰাৰ পিছত মই কি কাম কৰিব পাৰোঁ?** অসামৰিক সেৱাত 24 টা বিভিন্ন প্ৰকাৰৰ পদ আছে। কিছুমান জনপ্ৰিয় আৰু তেওঁলোকে কৰা কামবোৰ হৈছে: 1. ভাৰতীয় প্ৰশাসনিক সেৱা (IAS): চৰকাৰী নীতি প্ৰস্তুত আৰু ৰূপায়ণৰ বাবে দায়বদ্ধ। 2. ভাৰতীয় আৰক্ষী সেৱা (IPS): ৰাজহুৱা শান্তি আৰু শৃংখলা বজাই ৰখা, অপৰাধ প্ৰতিৰোধ, অনুসন্ধান আৰু চিনাক্তকৰণৰ বাবে দায়বদ্ধ",
              "gu": "**સિવિલ સર્વિસીસમાં જોડાયા પછી હું શું કામ કરી શકું?** સિવિલ સર્વિસીસમાં વિવિધ પ્રકારની 24 જગ્યાઓ છે. કેટલાક લોકપ્રિય અને તેઓ જે કામ કરે છે તે આ પ્રમાણે છે: 1. ભારતીય વહીવટી સેવા (IAS): સરકારી નીતિઓ ઘડવા અને અમલમાં મૂકવા માટે જવાબદાર. 2. ભારતીય પોલીસ સેવા (IPS): જાહેર શાંતિ અને વ્યવસ્થાની જાળવણી, ગુના નિવારણ, તપાસ અને શોધ માટે જવાબદાર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "3. Indian Foreign Service (IFS): Responsible to represent India at an international level and deal with India's external affairs like trade, diplomacy, and cultural relations\n\n4. Indian Revenue Service (IRS): Responsible for collecting and administering direct and indirect taxes for the Government of India.",
              "hi": "3. भारतीय विदेश सेवा (आईएफएस): एक अंतरराष्ट्रीय स्तर पर भारत का प्रतिनिधित्व करने और व्यापार, कूटनीति, और सांस्कृतिक संबंधों जैसे भारत के विदेश मामलों से निपटने के लिए जिम्मेदार\n\n4. भारतीय राजस्व सेवा (आईआरएस): भारत सरकार के लिए प्रत्यक्ष और अप्रत्यक्ष कर एकत्र करने और प्रशासन करने के लिए जिम्मेदार।",
              "ka": "3. ಭಾರತೀಯ ವಿದೇಶಾಂಗ ಸೇವೆ (IFS): ಅಂತರರಾಷ್ಟ್ರೀಯ ಮಟ್ಟದಲ್ಲಿ ಭಾರತವನ್ನು ಪ್ರತಿನಿಧಿಸಲು ಮತ್ತು ವ್ಯಾಪಾರ, ರಾಜತಾಂತ್ರಿಕತೆ ಮತ್ತು ಸಾಂಸ್ಕೃತಿಕ ಸಂಬಂಧಗಳಂತಹ ಭಾರತದ ಬಾಹ್ಯ ವ್ಯವಹಾರಗಳೊಂದಿಗೆ ವ್ಯವಹರಿಸುವ ಜವಾಬ್ದಾರಿ                                                                                           4. ಭಾರತೀಯ ಕಂದಾಯ ಸೇವೆ (IRS): ಭಾರತ ಸರ್ಕಾರಕ್ಕೆ ನೇರ ಮತ್ತು ಪರೋಕ್ಷ ತೆರಿಗೆಗಳನ್ನು ಸಂಗ್ರಹಿಸುವ ಮತ್ತು ನಿರ್ವಹಿಸುವ ಜವಾಬ್ದಾರಿ.\"",
              "te": "3. ఇండియన్ ఫారెన్ సర్వీస్ (IFS): అంతర్జాతీయ స్థాయిలో భారతదేశానికి ప్రాతినిధ్యం వహించే బాధ్యత మరియు ట్రేడ్, డిప్లొమసీ మరియు సాంస్కృతిక సంబంధాల వంటి భారతదేశ విదేశీ వ్యవహారాలను చూడడం\n \n \n \n 4. ఇండియన్ రెవెన్యూ సర్వీస్ (IRS): భారత ప్రభుత్వానికి ప్రత్యక్ష మరియు పరోక్ష పన్నులను వసూలు చేయడం మరియు నిర్వహించవలసిన బాధ్యత.",
              "or": "3. ଭାରତୀୟ ବଇଦେଶିକ ସେବା (IFS): ଭାରତକୁ ଏକ ଆନ୍ତର୍ଜାତୀୟ ସ୍ତରରେ ପ୍ରତିନିଧିତ୍ୱ କରିବା ଏବଂ ବାଣିଜ୍ୟ, କୂଟନୈତିକ ଏବଂ ସାଂସ୍କୃତିକ ସମ୍ପର୍କ ପରି ଭାରତର ବାହ୍ୟ ବ୍ୟାପାର ସହିତ ମୁକାବିଲା କରିବା ଦାୟିତ୍। ଅଟେ |\n \n \n \n 4. ଭାରତୀୟ ରାଜସ୍ୱ ସେବା (ଆଇଆରଏସ): ଭାରତ ସରକାରଙ୍କ ପାଇଁ ପ୍ରତ୍ୟକ୍ଷ ଏବଂ ପରୋକ୍ଷ ଟିକସ ସଂଗ୍ରହ ତଥା ପରିଚାଳନା ପାଇଁ ଦାୟୀ।",
              "as": "3. ভাৰতীয় বৈদেশিক সেৱা (IFS): আন্তঃৰাষ্ট্ৰীয় পৰ্যায়ত ভাৰতক প্ৰতিনিধিত্ব কৰিবলৈ আৰু বাণিজ্য, কূটনীতি আৰু সাংস্কৃতিক সম্পৰ্কৰ দৰে ভাৰতৰ বৈদেশিক বিষয়ৰ সৈতে মোকাবিলা কৰিবলৈ দায়বদ্ধ 4. ভাৰতীয় ৰাজহ সেৱা (IRS): ভাৰত চৰকাৰৰ বাবে প্ৰত্যক্ষ আৰু পৰোক্ষ কৰ সংগ্ৰহ আৰু প্ৰশাসনৰ বাবে দায়বদ্ধ।",
              "gu": "3. ભારતીય વિદેશ સેવા (IFS): આંતરરાષ્ટ્રીય સ્તરે ભારતનું પ્રતિનિધિત્વ કરવા અને વેપાર, રાજનીતિ અને સાંસ્કૃતિક સંબંધો જેવી ભારતની બાહ્ય બાબતો સાથે વ્યવહાર કરવા માટે જવાબદાર 4. ભારતીય મહેસૂલ સેવા (IRS): પ્રત્યક્ષ અને પરોક્ષ કર એકત્રિત કરવા અને વહીવટ કરવા માટે જવાબદાર ભારત સરકાર."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4(i)_2_1",
              "hi": "X4 (i) _2_1",
              "ka": "X4(i)_2_1",
              "te": "X4(i)_2_1",
              "or": "X4(i)_2_1",
              "as": "X4(i)_2_1",
              "gu": "X4(i)_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Meanwhile, Badri has been reading about the Civil Services Exam on the website of UPSC (Union Public Service Commission), which conducts this exam.",
              "hi": "इस बीच, बद्री सिविल सेवा परीक्षा के बारे में यूपीएससी (संघ लोक सेवा आयोग) की वेबसाइट पर  पढ़ रहा है, जो यह परीक्षा आयोजित करता है।",
              "ka": "ಈ ಮಧ್ಯದಲ್ಲಿ, ಈ ಪರೀಕ್ಷೆಯನ್ನು ನಡೆಸುವ ಯುಪಿಎಸ್‌ಸಿ (ಯೂನಿಯನ್ ಪಬ್ಲಿಕ್ ಸರ್ವಿಸ್ ಕಮಿಷನ್) ವೆಬ್‌ಸೈಟ್‌ನಲ್ಲಿ ಬದ್ರಿ ಸಿವಿಲ್ ಸರ್ವೀಸಸ್ ಪರೀಕ್ಷೆಯ ಬಗ್ಗೆ ಓದುತ್ತಿದ್ದಾನೆ.",
              "te": "ఇంతలో, బద్రి ఈ పరీక్షను నిర్వహించే UPSC (యూనియన్ పబ్లిక్ సర్వీస్ కమిషన్) వెబ్‌సైట్‌లో సివిల్ సర్వీసెస్ పరీక్ష గురించి చదువుతున్నాడు.",
              "or": "ଏହି ସମୟରେ, ବଦ୍ରି ଏହି ପରୀକ୍ଷା ପରିଚାଳନା କରୁଥିବା ୟୁପିଏସସି \n (ୟୁନିଅନ ପବ୍ଲିକ ସର୍ଭିସ କମିଶନ) ୱେବସାଇଟରେ ସିଭିଲ ସର୍ଭିସ ପରୀକ୍ଷା ବିଷୟରେ ପଢୁଛନ୍ତି।",
              "as": "ইফালে, বদ্ৰীয়ে এই পৰীক্ষা অনুষ্ঠিত কৰা UPSC (ইউনিয়ন পাব্লিক চাৰ্ভিচ কমিছন)ৰ ৱেবছাইটত অসামৰিক সেৱা পৰীক্ষাৰ বিষয়ে পঢ়ি আছে।",
              "gu": "દરમિયાન, બદ્રી આ પરીક્ષાનું સંચાલન કરતી યુપીએસસી (યુનિયન પબ્લિક સર્વિસ કમિશન) ની વેબસાઇટ પર સિવિલ સર્વિસ પરીક્ષા વિશે વાંચી રહ્યો છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4(i)_2_2",
              "hi": "X4 (i) _2_2",
              "ka": "X4(i)_2_2",
              "te": "X4(i)_2_2",
              "or": "X4(i)_2_2",
              "as": "X4(i)_2_2",
              "gu": "X4(i)_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav goes up to take a look.",
              "hi": "माधव एक नज़र देखने के लिए चला जाता है।",
              "ka": "ಮಾಧವ್ ಆತನ ಬಳಿಗೆ ನೋಡಲು ಹೋದನು.",
              "te": "చూసేందుకు మాధవ్ పైకి వెళ్ళాడు.",
              "or": "ମାଧବ ଦେଖିବା ପାଇଁ ଉପରକୁ ଯାଆନ୍ତି |",
              "as": "মাধৱে এবাৰ চাবলৈ ওপৰলৈ যায়।",
              "gu": "માધવ એક નજર કરવા ઉપર જાય છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4(i)_2_3//",
              "hi": "X4 (i) _2_3 //",
              "ka": "X4(i)_2_3//",
              "te": "X4(i)_2_3//",
              "or": "X4(i)_2_3//",
              "as": "X4(i)_2_3//",
              "gu": "X4(i)_2_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Badri",
              "hi": "बद्री",
              "ka": "ಬದ್ರಿ",
              "te": "బద్రి",
              "or": "ବଦ୍ରି",
              "as": "বদ্ৰী",
              "gu": "બદ્રી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "What did you find so far?",
              "hi": "तुमको अब तक क्या मिला?",
              "ka": "ನೀನು ಇಲ್ಲಿಯವರೆಗೆ ಏನು ಕಂಡುಕೊಂಡಿದ್ದೀಯಾ?",
              "te": "నువ్వు ఇప్పటివరకు ఏమి తెలుసుకున్నావు?",
              "or": "ଆପଣ ଏପର୍ଯ୍ୟନ୍ତ କ’ଣ ପାଇଲେ?",
              "as": "তুমি এতিয়ালৈকে কি বিচাৰি পালা?",
              "gu": "તમને અત્યાર સુધી શું મળ્યું?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "So there are 3 steps in the whole selection process. First is the preliminary exam (prelims). If you clear that exam, you have to give the Main exam. These exams are based on subjects like Current Affairs, History, Geography, Civics and Economics.",
              "hi": "तो पूरी चयन प्रक्रिया में 3 कदम हैं। सबसे पहले प्रारंभिक परीक्षा (Prelims) है। यदि आप उस परीक्षा को उत्तीर्ण करते हैं, तो आपको मुख्य परीक्षा देना होगा। ये परीक्षाएं वर्तमान मामलों, इतिहास, भूगोल, नागरिक और अर्थशास्त्र जैसे विषयों पर आधारित हैं।",
              "ka": "ಅಂದರೆ ಸಂಪೂರ್ಣ ಆಯ್ಕೆ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ 3 ಹಂತಗಳಿವೆ. ಮೊದಲನೆಯದು ಪೂರ್ವಭಾವಿ ಪರೀಕ್ಷೆ (ಪ್ರಿಲಿಮ್ಸ್). ಆ ಪರೀಕ್ಷೆಯಲ್ಲಿ ತೇರ್ಗಡೆಯಾದರೆ ಮುಖ್ಯ ಪರೀಕ್ಷೆ ಬರೆಯಬೇಕು. ಈ ಪರೀಕ್ಷೆಗಳು ಪ್ರಚಲಿತ ವಿದ್ಯಮಾನಗಳು, ಇತಿಹಾಸ, ಭೂಗೋಳ, ನಾಗರಿಕ ಮತ್ತು ಅರ್ಥಶಾಸ್ತ್ರದಂತಹ ವಿಷಯಗಳನ್ನು ಆಧರಿಸಿರೂತ್ತವೆ.",
              "te": "మొత్తం ఎంపిక ప్రక్రియలో 3 దశలు ఉన్నాయి. ముందుగా ప్రిలిమినరీ పరీక్ష (ప్రిలిమ్స్). ఆ పరీక్షలో ఉత్తీర్ణత సాధిస్తే మెయిన్ పరీక్ష రాయాల్సి ఉంటుంది. ఈ పరీక్షలు కరెంట్ అఫైర్స్, హిస్టరీ, జియోగ్రఫీ, సివిక్స్ మరియు ఎకనామిక్స్ వంటి సబ్జెక్టుల ఆధారంగా ఉంటాయి.",
              "or": "ତେଣୁ ସମଗ୍ର ଚୟନ ପ୍ରକ୍ରିୟାରେ 3 ଟି ସୋପାନ ଅଛି | ପ୍ରଥମଟି ହେଉଛି \n ପ୍ରାଥମିକ ପରୀକ୍ଷା (ପ୍ରିଲିମ୍) | ଯଦି ତୁମେ ସେହି ପରୀକ୍ଷାକୁ ସଫା କର, ତୁମକୁ ମୁଖ୍ୟ ପରୀକ୍ଷା ଦେବାକୁ ପଡିବ | ଏହି ପରୀକ୍ଷାଗୁଡ଼ିକ ସାମ୍ପ୍ରତିକ ବ୍ୟାପାର, ଇତିହାସ, ଭୂଗୋଳ, ସିଭିକ୍ସ ଏବଂ ଇକୋନୋମିକ୍ସ ଭଳି ବିଷୟ ଉପରେ ଆଧାରିତ |",
              "as": "গোটেই বাছনি প্ৰক্ৰিয়াটোত 3 টা পদক্ষেপ আছে। প্ৰথমটো হৈছে প্ৰাথমিক পৰীক্ষা (prelims)। যদি তুমি সেই পৰীক্ষাত উত্তীৰ্ণ হোৱা, তেতিয়া মুখ্য পৰীক্ষা দিব লাগিব। এই পৰীক্ষাবোৰ সাম্প্ৰতিক পৰিক্ৰমা, ইতিহাস, ভূগোল, নাগৰিক আৰু অৰ্থনীতিৰ দৰে বিষয়ৰ ওপৰত আধাৰিত।",
              "gu": "અહી સમગ્ર પસંદગી પ્રક્રિયામાં 3 પગલાં છે. પ્રથમ પ્રારંભિક પરીક્ષા (પ્રિલિમ) છે. જો તમે તે પરીક્ષા પાસ કરો છો, તો તમારે મુખ્ય પરીક્ષા આપવી પડશે. આ પરીક્ષાઓ વર્તમાન બાબતો, ઇતિહાસ, ભૂગોળ, નાગરિકશાસ્ત્ર અને અર્થશાસ્ત્ર જેવા વિષયો પર આધારિત છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4(i)_2_4////",
              "hi": "X4 (i) _2_4 /////",
              "ka": "X4(i)_2_4////",
              "te": "X4(i)_2_4////",
              "or": "X4(i)_2_4////",
              "as": "X4(i)_2_4////",
              "gu": "X4(i)_2_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "And what about the third step?",
              "hi": "और तीसरे कदम के बारे में क्या?",
              "ka": "ಮತ್ತು ಮೂರನೇ ಹಂತದ ಬಗ್ಗೆ ಏನು?",
              "te": "మరియు మూడవ దశ గురించి ఏమిటి?",
              "or": "ଏବଂ ତୃତୀୟ ସୋପନ ଟି କଣ?",
              "as": "আৰু তৃতীয় পদক্ষেপটো কি?",
              "gu": "અને ત્રીજા પગલા વિશે શું?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "The third step is the personality test (also known as the interview round). This test is like a discussion between a panel of UPSC interviewers and the candidate. You are called for the personality test only if you clear the main exam.",
              "hi": "तीसरा कदम व्यक्तित्व परीक्षण (साक्षात्कार दौर के रूप में भी जाना जाता है) है। यह परीक्षण यूपीएससी साक्षात्कारकर्ताओं के एक पैनल और उम्मीदवार  के बीच एक चर्चा की तरह है। यदि तुम मुख्य परीक्षा को उत्तीर्ण करते हो, केवल तभी तुमको  व्यक्तित्व परीक्षण के लिए बुलाया जाता है।",
              "ka": "ಮೂರನೇ ಹಂತವು ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಯಾಗಿದೆ (ಇದನ್ನು ಸಂದರ್ಶನ ಸುತ್ತು ಎಂದೂ ಕರೆಯಲಾಗುತ್ತದೆ). ಈ ಪರೀಕ್ಷೆಯು UPSC ಸಂದರ್ಶಕರ ಸಮಿತಿ ಮತ್ತು ಅಭ್ಯರ್ಥಿಯ ನಡುವಿನ ಚರ್ಚೆಯಂತಿದೆ. ನೀವು ಮುಖ್ಯ ಪರೀಕ್ಷೆಯಲ್ಲಿ ತೇರ್ಗಡೆಯಾದರೆ ಮಾತ್ರ ನಿಮ್ಮನ್ನು ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಗೆ ಕರೆಯಲಾಗುತ್ತದೆ",
              "te": "మూడవ దశ పర్సనాలిటీ టెస్ట్ (ఇంటర్వ్యూ రౌండ్ అని కూడా పిలుస్తారు). ఈ పరీక్ష UPSC ఇంటర్వ్యూయర్ల ప్యానెల్ మరియు అభ్యర్థి మధ్య చర్చ లాంటిది. మీరు మెయిన్ పరీక్షలో ఉత్తీర్ణులైతేనే మిమ్మల్ని పర్సనాలిటీ టెస్ట్ కు పిలుస్తారు.",
              "or": "ତୃତୀୟ ସୋପାନ ହେଉଛି ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷା (ସାକ୍ଷାତକାର ରାଉଣ୍ଡ ଭାବରେ \n ମଧ୍ୟ ଜଣାଶୁଣା) | ଏହି ପରୀକ୍ଷା UPSC ସାକ୍ଷାତକାରକାରୀ ଏବଂ ପ୍ରାର୍ଥୀଙ୍କ ମଧ୍ୟରେ ଏକ ଆଲୋଚନା ପରି | ଯଦି ଆପଣ ମୁଖ୍ୟ ପରୀକ୍ଷା ସଫା କରନ୍ତି ତେବେ ଆପଣଙ୍କୁ ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷା ପାଇଁ ଡକାଯାଏ |",
              "as": "তৃতীয় পদক্ষেপটো হ'ল ব্যক্তিত্বৰ পৰীক্ষা (সাক্ষাৎকাৰ ৰাউণ্ড বুলিও জনা যায়)। এই পৰীক্ষাটো UPSCৰ সাক্ষাৎকাৰ লওতাৰ এটা পেনেল আৰু প্ৰাৰ্থীৰ মাজত হোৱা আলোচনাৰ দৰে। তুমি মূল পৰীক্ষাত উত্তীৰ্ণ হ'লেহে তোমাক ব্যক্তিত্বৰ পৰীক্ষাৰ বাবে মাতিব।",
              "gu": "ત્રીજું પગલું વ્યક્તિત્વ પરીક્ષણ છે (જે ઇન્ટરવ્યુ રાઉન્ડ તરીકે પણ ઓળખાય છે). આ કસોટી UPSC ઇન્ટરવ્યુ લેનારાઓની પેનલ અને ઉમેદવાર વચ્ચેની ચર્ચા જેવી છે. જો તમે મુખ્ય પરીક્ષા પાસ કરો તો જ તમને વ્યક્તિત્વ કસોટી માટે બોલાવવામાં આવશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "That's not so bad. If I can get through the first two rounds, the personality test will be a piece of cake!",
              "hi": "ये उतना बुरा नहीं है। अगर मैं पहले दो राउंड उत्तीर्ण कर सकता हूं, तो व्यक्तित्व परीक्षण एक केक के टुकड़े की तरह आसान होगा!",
              "ka": "ಅಂದರೆ ಅದು ಅಷ್ಟೇನೂ ಕಷ್ಟವೆನಿಸುವುದಿಲ್ಲ. ನಾನು ಮೊದಲ ಎರಡು ಸುತ್ತುಗಳನ್ನು ದಾಟಲು ಸಾಧ್ಯವಾದಲ್ಲಿ, ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಯು ಒಂದು ಕೇಕ್ ತುಂಡು ತಿನ್ನುವ ರೀತಿಯಷ್ಟು ಸುಲಭ!",
              "te": "అది అంత కష్టం ఏమీ కాదు. నేను మొదటి రెండు రౌండ్‌లను అధిగమించగలిగితే, పర్సనాలిటీ టెస్ట్ చాలా సులభంగా అవుతుంది!",
              "or": "ତାହା ଏତେ ଖରାପ ନୁହେଁ | ଯଦି ମୁଁ ପ୍ରଥମ ଦୁଇଟି ରାଉଣ୍ଡ ଦେଇ ପାରିବି,\n  ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷା ଏକ ପିଠା ହେବ!",
              "as": "সেয়া ইমান বেয়া নহয়। যদি মই প্ৰথম দুটা ৰাউণ্ড পাৰ হ'ব পাৰোঁ, ব্যক্তিত্বৰ পৰীক্ষাটো কেকৰ টুকুৰাৰ দৰে হ'ব!",
              "gu": "તે એટલું ખરાબ નથી. જો હું પ્રથમ બે રાઉન્ડમાંથી પસાર થઈ જાઓ, તો વ્યક્તિત્વ પરીક્ષણ સરળ હશે!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "It isn't that easy, Madhav. The personality test is one of the toughest interviews in the country. The final score is a total of all three steps. Only if your rank falls within the cut-off rank, you will get appointed to the Civil Services.",
              "hi": "यह आसान नहीं है, माधव। व्यक्तित्व परीक्षण देश के सबसे कठिन साक्षात्कारों में से एक है। अंतिम स्कोर तीन चरणों के स्कोर का योग है। अगर तुम्हारी  रैंक कट ऑफ रैंक के भीतर आती है, तो तुम सिविल सेवाओं में नियुक्त हो जाओगे।",
              "ka": "ಅದು ಅಷ್ಟು ಸುಲಭವಲ್ಲ ಮಾಧವ್. ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಯು ದೇಶದ ಅತ್ಯಂತ ಕಠಿಣ ಸಂದರ್ಶನಗಳಲ್ಲಿ ಒಂದಾಗಿದೆ. ಅಂತಿಮ ಸ್ಕೋರ್ ಎಲ್ಲಾ ಮೂರು ಹಂತಗಳ ಒಟ್ಟು ಮೊತ್ತವಾಗಿರುತ್ತದೆ. ನಿನ್ನ ಶ್ರೇಣಿಯು ಕಟ್-ಆಫ್ ಶ್ರೇಣಿಯೊಳಗೆ ಬಂದರೆ ಮಾತ್ರ, ನೀನು ನಾಗರಿಕ ಸೇವೆಗಳಿಗೆ ನೇಮಕಗೊಳ್ಳುತ್ತೀಯಾ.",
              "te": "అది అంత ఈజీ కాదు మాధవ్. పర్సనాలిటీ టెస్ట్ దేశంలోనే అత్యంత కఠినమైన ఇంటర్వ్యూలలో ఒకటి. మూడు దశల మొత్తం కలిపితే వచ్చేది చివరి స్కోర్. నీ ర్యాంక్ కటాఫ్ ర్యాంక్‌లోకి వస్తే మాత్రమే, నిన్ను సివిల్ సర్వీసెస్‌లో నియమిస్తారు.",
              "or": "ଏହା ସହଜ ନୁହେଁ, ମାଧବ | ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷା ହେଉଛି ଦେଶର ସବୁଠାରୁ\n  କଠିନ ସାକ୍ଷାତକାର | ଅନ୍ତିମ ସ୍କୋର ହେଉଛି ସମସ୍ତ ତିନୋଟି ସୋପାନ | କେବଳ ଯଦି ତୁମର ରାଙ୍କ୍ କଟ୍ ଅଫ୍ ର୍ୟାଙ୍କ ମଧ୍ୟରେ ପଡ଼େ, ତୁମେ ସିଭିଲ୍ ସର୍ଭିସରେ ନିଯୁକ୍ତ ହେବ |",
              "as": "এইটো ইমান সহজ নহয়, মাধৱ। ব্যক্তিত্বৰ পৰীক্ষাটো দেশৰ আটাইতকৈ কঠিন সাক্ষাৎকাৰবোৰৰ ভিতৰত এটা। চূড়ান্ত নম্বৰটো তিনিওটা পদক্ষেপৰ সৰ্বমুঠ। তোমাৰ ৰেংক কাট-অফ ৰেংকৰ ভিতৰত পৰিলেহে, তুমি অসামৰিক সেৱাত নিযুক্তি পাবা।",
              "gu": "તે એટલું સરળ નથી, માધવ. વ્યક્તિત્વ કસોટી એ દેશના સૌથી અઘરા ઇન્ટરવ્યુમાંથી એક છે. અંતિમ સ્કોર ત્રણેય પગલાઓનો કુલ છે. જો તમારો રેન્ક કટ-ઓફ રેન્કમાં આવે તો જ તમને સિવિલ સર્વિસીસમાં નિયુક્ત કરવામાં આવશે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "Research",
              "hi": "शोध ",
              "ka": "ಸಂಶೋಧನೆ",
              "te": "రీసర్చ్",
              "or": "ଅନୁସନ୍ଧାନ |",
              "as": "গৱেষণা",
              "gu": "સંશોધન"
            }
          }
        ]
      },
      "scene6": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4(ii)_1_1",
              "hi": "X4 (ii) _1_1",
              "ka": "X4(ii)_1_1",
              "te": "X4(ii)_1_1",
              "or": "X4(ii)_1_1",
              "as": "X4(ii)_1_1",
              "gu": "X4(ii)_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav and Kuldeep go to the administrative department, where the admin officer shares the records and details of the alumni network on their portal.",
              "hi": "माधव और कुलदीप प्रशासनिक विभाग में जाते हैं, जहां व्यवस्थापक अधिकारी अपने पोर्टल पर पूर्व छात्रों के नेटवर्क के रिकॉर्ड और विवरण साझा करता है।",
              "ka": "ಮಾಧವ್ ಮತ್ತು ಕುಲದೀಪ್ ಆಡಳಿತ ಇಲಾಖೆಗೆ ಹೋಗುತ್ತಾರೆ, ಅಲ್ಲಿ ನಿರ್ವಾಹಕ ಅಧಿಕಾರಿಯು ತಮ್ಮ ಪೋರ್ಟಲ್‌ನಲ್ಲಿ ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳ ನೆಟ್‌ವರ್ಕ್‌ನ ದಾಖಲೆಗಳು ಮತ್ತು ವಿವರಗಳನ್ನು ಅವರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "మాధవ్ మరియు కుల్దీప్ అడ్మినిస్ట్రేటివ్ డిపార్ట్మెంట్‌కి వెళ్తారు, అక్కడ అడ్మిన్ ఆఫీసర్ తమ పోర్టల్‌లో పూర్వ విద్యార్థుల నెట్‌వర్క్ కు సంబంధించిన రికార్డులు మరియు వివరాలను అందిస్తాడు.",
              "or": "ମାଧବ ଏବଂ କୁଲଦୀପ ପ୍ରଶାସନିକ ବିଭାଗକୁ ଯାଆନ୍ତି, ଯେଉଁଠାରେ ଆଡମିନି ଅଫିସର\n  ସେମାନଙ୍କ ପୋର୍ଟାଲରେ ଆଲୁମିନି ନେଟୱାର୍କର ରେକର୍ଡ ଏବଂ ବିବରଣୀ ଅଂଶୀଦାର କରନ୍ତି |",
              "as": "মাধৱ আৰু কুলদীপ প্ৰশাসনিক বিভাগলৈ যায়, য'ত প্ৰশাসকে তেওঁলোকৰ পৰ্টেলত প্ৰাক্তন ছাত্ৰ-ছাত্ৰীৰ নেটৱৰ্কৰ ৰেকৰ্ড আৰু বিৱৰণ তেওঁলোকক দিয়ে।",
              "gu": "માધવ અને કુલદીપ વહીવટી વિભાગમાં જાય છે, જ્યાં એડમિન અધિકારી તેમના પોર્ટલ પર ભૂતપૂર્વ વિદ્યાર્થીઓના નેટવર્કના રેકોર્ડ અને વિગતો બતાવે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4(ii)_1_1b",
              "hi": "X4 (ii) _1_1B",
              "ka": "X4(ii)_1_1b",
              "te": "X4(ii)_1_1b",
              "or": "X4(ii)_1_1b",
              "as": "X4(ii)_1_1b",
              "gu": "X4(ii)_1_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They find that their seniors, Khushboo and Varun, are preparing for IAS. They speak to them and take notes of what they find out.",
              "hi": "वे पाते हैं कि उनके वरिष्ठ साथी, खुशबू और वरुण, आईएएस के लिए तैयारी कर रहे हैं। वे उनसे बात करते हैं और उनसे जो पता लगाते हैं उसके बारे में नोट्स लेते हैं।",
              "ka": "ತನಗಿಂತ ದೊಡ್ಡವರಾದ ಖುಷ್ಬೂ ಮತ್ತು ವರುಣ್ ಐಎಎಸ್ ಗೆ ತಯಾರಿ ನಡೆಸುತ್ತಿರುವುದನ್ನು ಅವರು ಕಂಡುಕೊಂಡಿದ್ದಾರೆ. ಅವರು ಅವರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾರೆ ಮತ್ತುತಾವು ತಿಳಿದುಕೊಂಡದ್ದನ್ನು ಟಿಪ್ಪಣಿ ಮಾಡುತ್ತಾರೆ.",
              "te": "తమ సీనియర్లు ఖుష్బూ మరియు వరుణ్ IAS కోసం ప్రిపేర్ అవుతున్నారని వారు తెలుసుకుంటారు. వారు వారితో మాట్లాడతారు మరియు వారు కనుగొన్న వాటిని నోట్స్ రాసుకుంటారు.",
              "or": "ସେମାନେ ଜାଣିବାକୁ ପାଇଲେ ଯେ ସେମାନଙ୍କ ସିନିୟର, ଖୁସବୋ ଏବଂ\n  ଭାରନ୍, IAS ପାଇଁ ପ୍ରସ୍ତୁତ ହେଉଛନ୍ତି | ସେମାନେ ସେମାନଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରନ୍ତି ଏବଂ ଯାହା ଜାଣନ୍ତି ତାହା ଉପରେ ଧ୍ୟାନ ଦିଅନ୍ତି |",
              "as": "তেওঁলোকে গম পালে যে তেওঁলোকৰ জ্যেষ্ঠ, খুচবু আৰু বৰুণে IASৰ বাবে প্ৰস্তুতি চলাই আছে। সিহঁতে তেওঁলোকৰ সৈতে কথা পাতিলে আৰু জানিবলৈ পোৱা কথাবোৰৰ টোকা বনালে।",
              "gu": "તેમને જાણવા મળ્યું કે તેમના સિનિયર , ખુશ્બુ અને વરુણ, IAS ની તૈયારી કરી રહ્યા છે. તેઓ તેમની સાથે વાત કરે છે અને તેઓ જે જ્ઞાન મેળવે છે અને તેની નોંધ લે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4(ii)_1_2",
              "hi": "X4 (ii) _1_2",
              "ka": "X4(ii)_1_2",
              "te": "X4(ii)_1_2",
              "or": "X4(ii)_1_2",
              "as": "X4(ii)_1_2",
              "gu": "X4(ii)_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is also looking at the videos on the UPSC Shiksha channel and making notes about civil services and how to join them.",
              "hi": "माधव भी यूपीएससी शिक्षा चैनल पर वीडियो देख रहा है और सिविल सेवाओं और उनमें  कैसे शामिल होना है, इसके बारे में नोट्स बना रहा है।",
              "ka": "ಮಾಧವ್  UPSC ಶಿಕ್ಷಾ ಚಾನೆಲ್‌ನಲ್ಲಿನ ವೀಡಿಯೊಗಳನ್ನು ನೋಡುತ್ತಿದ್ದಾನೆ ಮತ್ತು ನಾಗರಿಕ ಸೇವೆಗಳ ಬಗ್ಗೆ ಹಾಗೂ ಅವುಗಳನ್ನು ಹೇಗೆ ಸೇರಬೇಕು ಎನ್ನುವೂದರ ಕುರಿತು ಟಿಪ್ಪಣಿಗಳನ್ನು ಮಾಡುತ್ತಿದ್ದಾನೆ.",
              "te": "మాధవ్ UPSC శిక్షా ఛానెల్‌లోని వీడియోలను కూడా చూస్తూ సివిల్ సర్వీసెస్ మరియు వాటిలో ఎలా చేరాలి అనే విషయాల గురించి నోట్స్ రాస్తున్నాడు.",
              "or": "ମାଧବ UPSC ଶିଖା ଚ୍ୟାନେଲରେ ଥିବା ଭିଡିଓଗୁଡିକୁ ମଧ୍ୟ ଦେଖୁଛନ୍ତି\n  ଏବଂ ସିଭିଲ୍ ସର୍ଭିସେସ୍ ଏବଂ ସେଗୁଡିକରେ କିପରି ଯୋଗଦେବେ ସେ ବିଷୟରେ ନୋଟ୍ ପ୍ରସ୍ତୁତ କରୁଛନ୍ତି |",
              "as": "মাধৱে UPSC শিক্ষা চেনেলৰ ভিডিঅ' বোৰ চাই আছে আৰু অসামৰিক সেৱা আৰু সেইবোৰত কেনেদৰে যোগদান কৰিব লাগে সেই বিষয়ে টোকা প্ৰস্তুত কৰি আছে।",
              "gu": "માધવ UPSC શિક્ષા ચેનલ પરના વિડિયો પણ જોઈ રહ્યો છે અને સિવિલ સેવાઓ અને તેમાં કેવી રીતે જોડાવું તે વિશે નોંધો બનાવી રહ્યો છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4ii_1_3_notebook",
              "hi": "X4ii_1_3_नोटबुक ",
              "ka": "X4ii_1_3_notebook",
              "te": "X4ii_1_3_నోట్‌బుక్",
              "or": "X4ii_1_3_ ନୋଟବୁକ୍ |",
              "as": "X4ii_1_3_টোকাবহী",
              "gu": "X4ii_1_3_નોટબુક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page 1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "ପୃଷ୍ଠା ୧",
              "as": "পৃষ্ঠা 1",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page 2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ 2",
              "or": "ପୃଷ୍ଠା ୨",
              "as": "পৃষ্ঠা 2",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Page 3",
              "hi": "पृष्ठ 3",
              "ka": "ಪುಟ 3",
              "te": "పేజీ 3",
              "or": "ପୃଷ୍ଠା ୩",
              "as": "পৃষ্ঠা 3",
              "gu": "પેજ 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "**What is Civil Service, and how can I join it?** \n                                                                                                    \nCivil Service employees work under the government to provide a service to the nation's citizens, for example, police officers, tax collectors etc. You can join the civil services after clearing the Civil Services Exam (UPSC exam)",
              "hi": "** सिविल सेवा क्या है, और मैं इसमें कैसे शामिल हो सकता हूं? **\n                                                                                                      \n देश के नागरिकों को एक सेवा प्रदान करने के लिए  सिविल सेवा कर्मचारी  सरकार के अधीन काम करते हैं, उदाहरण के लिए, पुलिस अधिकारी, टैक्स कलेक्टर आदि। सिविल सेवा परीक्षा (यूपीएससी परीक्षा) को उत्तीर्ण करने के बाद तुम सिविल सेवाओं में शामिल हो सकते हो ",
              "ka": "**ನಾಗರಿಕ ಸೇವೆ ಎಂದರೇನು ಮತ್ತು ನಾನು ಅದನ್ನು ಹೇಗೆ ಸೇರಬಹುದು?**                                                                                                   ನಾಗರಿಕ ಸೇವಾ ನೌಕರರು ರಾಷ್ಟ್ರದ ನಾಗರಿಕರಿಗೆ ಸೇವೆಯನ್ನು ಒದಗಿಸಲು ಸರ್ಕಾರದ ಅಡಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಾರೆ, ಉದಾಹರಣೆಗೆ, ಪೊಲೀಸ್ ಅಧಿಕಾರಿಗಳು, ತೆರಿಗೆ ಸಂಗ್ರಹಕಾರರು ಇತ್ಯಾದಿ. ನೀವು ನಾಗರಿಕ ಸೇವೆಗಳ ಪರೀಕ್ಷೆಯನ್ನು (UPSC ಪರೀಕ್ಷೆ) ತೆರವುಗೊಳಿಸಿದ ನಂತರ ನಾಗರಿಕ ಸೇವೆಗಳಿಗೆ ಸೇರಬಹುದು.                                             ",
              "te": "**సివిల్ సర్వీస్ అంటే ఏమిటి, నేను అందులో ఎలా చేరగలను?**\n \n  \n \n సివిల్ సర్వీస్ ఉద్యోగులు దేశ పౌరులకు సేవను అందించడానికి ప్రభుత్వం క్రింద పని చేస్తారు, ఉదాహరణకు, పోలీసు అధికారులు, పన్ను వసూలు చేసేవారు మొదలైనవి. మీరు సివిల్ సర్వీసెస్ పరీక్ష (UPSC పరీక్ష) క్లియర్ చేసిన తర్వాత సివిల్ సర్వీసెస్‌లో చేరవచ్చు.",
              "or": "** ସିଭିଲ୍ ସର୍ଭିସ୍ କ’ଣ, ଏବଂ ମୁଁ ଏଥିରେ କିପରି ଯୋଗ ଦେଇ ପାରିବି? **\n \n  \n \n ସିଭିଲ ସର୍ଭିସ କର୍ମଚାରୀମାନେ ଦେଶର ନାଗରିକମାନଙ୍କୁ ଏକ ସେବା ଯୋଗାଇବା ପାଇଁ ସରକାରଙ୍କ ଅଧୀନରେ କାମ କରନ୍ତି, ଉଦାହରଣ ସ୍ୱରୂପ, ପୋଲିସ ଅଧିକାରୀ, କର ସଂଗ୍ରହକାରୀ ଇତ୍ୟାଦି ଆପଣ ସିଭିଲ ସର୍ଭିସ ପରୀକ୍ଷା (UPSC ପରୀକ୍ଷା) ପାସ କରିବା ପରେ ସିଭିଲ ସର୍ଭିସରେ ଯୋଗ ଦେଇପାରିବେ |",
              "as": "**অসামৰিক সেৱা কি, আৰু মই ইয়াত কেনেকৈ যোগদান কৰিব পাৰোঁ?** অসামৰিক সেৱাৰ কৰ্মচাৰীসকলে দেশৰ নাগৰিকসকলক সেৱা প্ৰদান কৰিবলৈ চৰকাৰৰ অধীনত কাম কৰে, উদাহৰণ স্বৰূপে, আৰক্ষী বিষয়া, কৰ সংগ্ৰাহক ইত্যাদি। অসামৰিক সেৱা পৰীক্ষা (UPSC পৰীক্ষা) উত্তীৰ্ণ হোৱাৰ পিছত তুমি অসামৰিক সেৱাত যোগদান কৰিব পাৰা",
              "gu": "**સિવિલ સર્વિસ શું છે અને હું તેમાં કેવી રીતે જોડાઈ શકું?** સિવિલ સર્વિસના કર્મચારીઓ દેશના નાગરિકોને સેવા પૂરી પાડવા માટે સરકાર હેઠળ કામ કરે છે, ઉદાહરણ તરીકે, પોલીસ અધિકારીઓ, ટેક્સ કલેક્ટર વગેરે. ક્લિયરિંગ કર્યા પછી તમે સિવિલ સર્વિસમાં જોડાઈ શકો છો. સિવિલ સર્વિસ પરીક્ષા (UPSC પરીક્ષા)"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "**What work can I do after joining the Civil Services?**\n\nThere are 24 different types of posts in civil services. Some of the popular ones and the work they do are:\n1. Indian Administrative Service (IAS): Responsible for formulating and implementing Government policies.\n2. Indian Police Service (IPS): Responsible for maintenance of public peace and order, crime prevention, investigation, and detection.",
              "hi": "** सिविल सेवाओं में शामिल होने के बाद मैं क्या काम कर सकता हूं? **\n\nसिविल सेवाओं में 24 विभिन्न प्रकार के पद हैं। कुछ लोकप्रिय हैं और वे यह काम करते हैं:\n1. भारतीय प्रशासनिक सेवा (आईएएस): सरकारी नीतियों को तैयार करने और कार्यान्वित करने के लिए जिम्मेदार।\n2. भारतीय पुलिस सेवा (आईपीएस): सार्वजनिक शांति और आदेश, अपराध की रोकथाम, जांच, और पहचान के रखरखाव के लिए जिम्मेदार।",
              "ka": "**ನಾಗರಿಕ ಸೇವೆಗಳಿಗೆ ಸೇರಿದ ನಂತರ ನಾನು ಯಾವ ಕೆಲಸವನ್ನು ಮಾಡಬಹುದು?**                                                                                         ನಾಗರಿಕ ಸೇವೆಗಳಲ್ಲಿ 24 ವಿವಿಧ ರೀತಿಯ ಹುದ್ದೆಗಳಿವೆ. ಕೆಲವು ಜನಪ್ರಿಯವಾದವುಗಳು ಮತ್ತು ಅವರು ಮಾಡುವ ಕೆಲಸಗಳು:\n1. ಭಾರತೀಯ ಆಡಳಿತ ಸೇವೆ (IAS): ಸರ್ಕಾರದ ನೀತಿಗಳನ್ನು ರೂಪಿಸುವ ಮತ್ತು ಅನುಷ್ಠಾನಗೊಳಿಸುವ ಜವಾಬ್ದಾರಿ.\n2. ಭಾರತೀಯ ಪೊಲೀಸ್ ಸೇವೆ (IPS): ಸಾರ್ವಜನಿಕ ಶಾಂತಿ ಮತ್ತು ಸುವ್ಯವಸ್ಥೆಯ ನಿರ್ವಹಣೆ, ಅಪರಾಧ ತಡೆಗಟ್ಟುವಿಕೆ, ತನಿಖೆ ಮತ್ತು ಪತ್ತೆಗೆ ಜವಾಬ್ದಾರಿ.\"",
              "te": "**సివిల్ సర్వీసెస్‌లో చేరిన తర్వాత నేను ఏ పని చేయగలను?**\n \n \n \n సివిల్ సర్వీసెస్‌లో 24 రకాల పోస్టులు ఉన్నాయి. కొన్ని ప్రసిద్ధమైనవి మరియు వారు చేసే పని:\n \n 1. ఇండియన్ అడ్మినిస్ట్రేటివ్ సర్వీస్ (IAS): ప్రభుత్వ విధానాలను రూపొందించడం మరియు అమలు చేయవలసిన బాధ్యత.\n \n 2. ఇండియన్ పోలీస్ సర్వీస్ (IPS): ప్రజా శాంతి భద్రతల నిర్వహణ, నేరాల నివారణ, దర్యాప్తు మరియు గుర్తింపు కోసం బాధ్యత వహిస్తుంది.",
              "or": "** ସିଭିଲ ସର୍ଭିସରେ ଯୋଗଦେବା ପରେ ମୁଁ କେଉଁ କାମ କରିପାରିବି? **\n \n \n \n ସିଭିଲ ସର୍ଭିସରେ 24 ଟି ବିଭିନ୍ନ ପ୍ରକାରର ପୋଷ୍ଟ ଅଛି | କେତେକ ଲୋକପ୍ରିୟ ଏବଂ ସେମାନେ କରୁଥିବା କାର୍ଯ୍ୟଗୁଡ଼ିକ ହେଉଛି:\n \n 1. ଭାରତୀୟ ପ୍ରଶାସନିକ ସେବା (IAS): ସରକାରୀ ନୀତି ପ୍ରସ୍ତୁତ ଏବଂ କାର୍ଯ୍ୟକାରୀ କରିବା ପାଇଁ ଦାୟୀ |\n \n 2. ଭାରତୀୟ ପୋଲିସ୍ ସେବା (ଆଇପିଏସ୍): ଜନ ଶାନ୍ତି ଏବଂ ଶୃଙ୍ଖଳା ରକ୍ଷା, ଅପରାଧ ରୋକିବା, ଅନୁସନ୍ଧାନ ଏବଂ ଚିହ୍ନଟ ପାଇଁ ଦାୟୀ।",
              "as": "**অসামৰিক সেৱাত যোগদান কৰাৰ পিছত মই কি কাম কৰিব পাৰোঁ?** অসামৰিক সেৱাত 24 টা বিভিন্ন প্ৰকাৰৰ পদ আছে। কিছুমান জনপ্ৰিয় আৰু তেওঁলোকে কৰা কামবোৰ হৈছে: 1. ভাৰতীয় প্ৰশাসনিক সেৱা (IAS): চৰকাৰী নীতি প্ৰস্তুত আৰু ৰূপায়ণৰ বাবে দায়বদ্ধ। 2. ভাৰতীয় আৰক্ষী সেৱা (IPS): ৰাজহুৱা শান্তি আৰু শৃংখলা বজাই ৰখা, অপৰাধ প্ৰতিৰোধ, অনুসন্ধান আৰু চিনাক্তকৰণৰ বাবে দায়বদ্ধ",
              "gu": "**સિવિલ સર્વિસીસમાં જોડાયા પછી હું શું કામ કરી શકું?** સિવિલ સર્વિસમાં વિવિધ પ્રકારની 24 પોસ્ટ્સ છે. કેટલાક લોકપ્રિય અને તેઓ જે કામ કરે છે તે આ પ્રમાણે છે: 1. ભારતીય વહીવટી સેવા (IAS): સરકારી નીતિઓ ઘડવા અને અમલ કરવા માટે જવાબદાર છે. 2. ભારતીય પોલીસ સેવા (IPS): જાહેર શાંતિ અને વ્યવસ્થાની જાળવણી, ગુના નિવારણ, તપાસ અને શોધ માટે જવાબદાર."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "3. Indian Foreign Service (IFS): Responsible to represent India at an international level and deal with India's external affairs like trade, diplomacy, and cultural relations\n\n4. Indian Revenue Service (IRS): Responsible for collecting and administering direct and indirect taxes for the Government of India.",
              "hi": "3. भारतीय विदेश सेवा (आईएफएस): एक अंतरराष्ट्रीय स्तर पर भारत का प्रतिनिधित्व करने और व्यापार, कूटनीति, और सांस्कृतिक संबंधों जैसे भारत के विदेश मामलों से निपटने के लिए जिम्मेदार\n\n4. भारतीय राजस्व सेवा (आईआरएस): भारत सरकार के लिए प्रत्यक्ष और अप्रत्यक्ष कर एकत्र करने और प्रशासन करने के लिए जिम्मेदार।",
              "ka": "3. ಭಾರತೀಯ ವಿದೇಶಾಂಗ ಸೇವೆ (IFS): ಅಂತರರಾಷ್ಟ್ರೀಯ ಮಟ್ಟದಲ್ಲಿ ಭಾರತವನ್ನು ಪ್ರತಿನಿಧಿಸಲು ಮತ್ತು ವ್ಯಾಪಾರ, ರಾಜತಾಂತ್ರಿಕತೆ ಮತ್ತು ಸಾಂಸ್ಕೃತಿಕ ಸಂಬಂಧಗಳಂತಹ ಭಾರತದ ಬಾಹ್ಯ ವ್ಯವಹಾರಗಳೊಂದಿಗೆ ವ್ಯವಹರಿಸುವ ಜವಾಬ್ದಾರಿ                                                                                           4. ಭಾರತೀಯ ಕಂದಾಯ ಸೇವೆ (IRS): ಭಾರತ ಸರ್ಕಾರಕ್ಕೆ ನೇರ ಮತ್ತು ಪರೋಕ್ಷ ತೆರಿಗೆಗಳನ್ನು ಸಂಗ್ರಹಿಸುವ ಮತ್ತು ನಿರ್ವಹಿಸುವ ಜವಾಬ್ದಾರಿ.\"",
              "te": "3. ఇండియన్ ఫారిన్ సర్వీస్ (IFS): అంతర్జాతీయ స్థాయిలో భారతదేశానికి ప్రాతినిధ్యం వహించే బాధ్యత మరియు ట్రేడ్, డిప్లొమసీ మరియు సాంస్కృతిక సంబంధాల వంటి భారతదేశ విదేశీ వ్యవహారాలతో వ్యవహరించడం\n \n \n \n 4. ఇండియన్ రెవెన్యూ సర్వీస్ (IRS): భారత ప్రభుత్వానికి ప్రత్యక్ష మరియు పరోక్ష పన్నులను వసూలు చేయడం మరియు నిర్వహించవలసిన బాధ్యత.",
              "or": "3. ଭାରତୀୟ ବଇଦେଶିକ ସେବା (IFS): ଭାରତକୁ ଏକ ଆନ୍ତର୍ଜାତୀୟ ସ୍ତରରେ ପ୍ରତିନିଧିତ୍ୱ କରିବା ଏବଂ ବାଣିଜ୍ୟ, କୂଟନ acy ତିକ ଏବଂ ସାଂସ୍କୃତିକ ସମ୍ପର୍କ ପରି ଭାରତର ବାହ୍ୟ ବ୍ୟାପାର ସହିତ ମୁକାବିଲା କରିବା ଦାୟିତ୍। ଅଟେ |\n \n \n \n 4. ଭାରତୀୟ ରାଜସ୍ୱ ସେବା (ଆଇଆରଏସ): ଭାରତ ସରକାରଙ୍କ ପାଇଁ ପ୍ରତ୍ୟକ୍ଷ ଏବଂ ପରୋକ୍ଷ ଟିକସ ସଂଗ୍ରହ ତଥା ପରିଚାଳନା ପାଇଁ ଦାୟୀ।",
              "as": "3. ভাৰতীয় বৈদেশিক সেৱা (IFS): আন্তঃৰাষ্ট্ৰীয় পৰ্যায়ত ভাৰতক প্ৰতিনিধিত্ব কৰিবলৈ আৰু বাণিজ্য, কূটনীতি আৰু সাংস্কৃতিক সম্পৰ্কৰ দৰে ভাৰতৰ বৈদেশিক বিষয়ৰ সৈতে মোকাবিলা কৰিবলৈ দায়বদ্ধ 4. ভাৰতীয় ৰাজহ সেৱা (IRS): ভাৰত চৰকাৰৰ বাবে প্ৰত্যক্ষ আৰু পৰোক্ষ কৰ সংগ্ৰহ আৰু প্ৰশাসনৰ বাবে দায়বদ্ধ।",
              "gu": "3. ભારતીય વિદેશ સેવા (IFS): આંતરરાષ્ટ્રીય સ્તરે ભારતનું પ્રતિનિધિત્વ કરવા અને વેપાર, મુત્સદ્દીગીરી અને સાંસ્કૃતિક સંબંધો જેવી ભારતની બાહ્ય બાબતો સાથે વ્યવહાર કરવા માટે જવાબદાર 4. ભારતીય મહેસૂલ સેવા (IRS): પ્રત્યક્ષ અને પરોક્ષ કર એકત્રિત કરવા અને વહીવટ કરવા માટે જવાબદાર ભારત સરકાર."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4(ii)_2_1",
              "hi": "X4 (ii) _2_1",
              "ka": "X4(ii)_2_1",
              "te": "X4(ii)_2_1",
              "or": "X4(ii)_2_1",
              "as": "X4(ii)_2_1",
              "gu": "X4(ii)_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Meanwhile, Kuldeep has been watching a video about the Civil Services Exam. Madhav goes up to him to find out more.",
              "hi": "इस बीच, कुलदीप सिविल सेवा परीक्षा के बारे में एक वीडियो देख रहा है। माधव इसके विषय में और जानने के लिए उसके पास जाता है।",
              "ka": "ಈ ಮಧ್ಯದಲ್ಲಿ, ಕುಲದೀಪ್ ಸಿವಿಲ್ ಸರ್ವೀಸಸ್ ಪರೀಕ್ಷೆಯ ವೀಡಿಯೊವನ್ನು ವೀಕ್ಷಿಸುತ್ತಿದ್ದಾನೆ. ಮಾಧವ್ ಹೆಚ್ಚಿನದನ್ನು ಕಂಡುಹಿಡಿಯಲು ಅವನ ಬಳಿಗೆ ಹೋಗುತ್ತಾನೆ.",
              "te": "ఇంతలో, కుల్దీప్ సివిల్ సర్వీసెస్ పరీక్షకు సంబంధించిన వీడియోను చూస్తున్నాడు. మరింత తెలుసుకోవడానికి మాధవ్ అతని దగ్గరకు వెళ్తాడు.",
              "or": "ଏହି ସମୟରେ କୁଲଦୀପ ସିଭିଲ ସର୍ଭିସ ପରୀକ୍ଷା ବିଷୟରେ ଏକ ଭିଡିଓ \n ଦେଖୁଛନ୍ତି। ଅଧିକ ଜାଣିବା ପାଇଁ ମାଧବ ତାଙ୍କ ପାଖକୁ ଯାଆନ୍ତି |",
              "as": "ইফালে, কুলদীপে অসামৰিক সেৱা পৰীক্ষাৰ বিষয়ে এটা ভিডিঅ' চাই আছিলে। মাধৱে অধিক জানিবলৈ তাৰ ওচৰলৈ যায়।",
              "gu": "દરમિયાન, કુલદીપ સિવિલ સર્વિસ પરીક્ષા વિશેનો એક વીડિયો જોઈ રહ્યો હતો. માધવ વધુ જાણવા તેની પાસે જાય છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4(ii)_2_3//",
              "hi": "X4 (ii) _2_3 //",
              "ka": "X4(ii)_2_3//",
              "te": "X4(ii)_2_3//",
              "or": "X4(ii)_2_3//",
              "as": "X4(ii)_2_3//",
              "gu": "X4(ii)_2_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୁଳଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୁଳଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I tried checking the website, but there was too much to read. A video is so much easier.",
              "hi": "मैंने वेबसाइट की जांच करने की कोशिश की, लेकिन पढ़ने के लिए बहुत कुछ था। एक वीडियो देखना बहुत सहज है।",
              "ka": "ನಾನು ವೆಬ್‌ಸೈಟ್ ಅನ್ನು ಪರಿಶೀಲಿಸಲು ಪ್ರಯತ್ನಿಸಿದೆ, ಆದರೆ ಅಲ್ಲಿ ಓದಲು ತುಂಬಾ ಇತ್ತು. ವೀಡಿಯೊ ನೋಡುವುದು ತುಂಬಾ ಸುಲಭ.",
              "te": "నేను వెబ్‌సైట్‌ని తనిఖీ చేయడానికి ప్రయత్నించాను, కానీ చదవవలసింది చాలా ఎక్కువ ఉంది. వీడియో చాలా సులభం.",
              "or": "ମୁଁ ୱେବସାଇଟ୍ ଚେକ୍ କରିବାକୁ ଚେଷ୍ଟା କଲି, କିନ୍ତୁ ପଢିବାକୁ ବହୁତ କିଛି \n ଥିଲା | ଏକ ଭିଡିଓ ବହୁତ ସହଜ ଅଟେ |",
              "as": "মই ৱেবছাইটটো পৰীক্ষা কৰিছিলোঁ, কিন্তু তাত পঢ়িবলৈ বহুত বেছিকৈ থাকে। তাতকৈ এটা ভিডিঅ' চোৱাই সহজ।",
              "gu": "મેં વેબસાઈટ તપાસવાનો પ્રયત્ન કર્યો, પણ વાંચવા માટે ઘણું બધું હતું. વિડિઓ ખૂબ સરળ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Kuldeep, how will you give the UPSC exam? Do you know how much we will have to read?",
              "hi": "कुलदीप, तुम यूपीएससी परीक्षा कैसे दोगे? क्या तुम जानते हो कि हमें कितना पढ़ना होगा?",
              "ka": "ಕುಲದೀಪ್, ನೀನು UPSC ಪರೀಕ್ಷೆಯನ್ನು ಹೇಗೆ ನೀಡುತ್ತೀಯಾ? ನಾವು ಎಷ್ಟು ಓದಬೇಕು ಎಂದು ನಿನಗೆ ತಿಳಿದಿದೆಯೇ?",
              "te": "కుల్దీప్, నువ్వు UPSC పరీక్ష ఎలా రాస్తావు? మనం ఎంత చదవాల్సి వస్తుందో తెలుసా?",
              "or": "କୁଲଦୀପ, ଆପଣ UPSC ପରୀକ୍ଷା କିପରି ଦେବେ? ଆପଣ ଜାଣନ୍ତି କି\n  ଆମକୁ କେତେ ପଢିବାକୁ ପଡିବ?",
              "as": "কুলদীপ, তুমি UPSC পৰীক্ষা কেনেদৰে দিবা? তুমি জানানে আমি কিমান পঢ়িব লাগিব?",
              "gu": "કુલદીપ, તું UPSCની પરીક્ષા કેવી રીતે આપીશ? શું તું જાણે છે કે આપણે કેટલું વાંચવું પડશે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "I'll find some videos to explain that also! You get everything in video format nowadays.",
              "hi": "मुझे यह भी समझाने के लिए कुछ वीडियो मिलेंगे! तुमको आजकल वीडियो प्रारूप में सबकुछ मिलता है।",
              "ka": "ಅದನ್ನು ವಿವರಿಸಲು ನಾನು ಕೆಲವು ವೀಡಿಯೊಗಳನ್ನು ನೋಡುತ್ತೇನೆ! ಇಂದಿನ ದಿನಗಳಲ್ಲಿ ನೀವು ಎಲ್ಲವನ್ನೂ ವೀಡಿಯೊ ರೂಪದಲ್ಲಿ ಪಡೆಯುತ್ತೀರಿ",
              "te": "నేను దానిని వివరించడానికి కొన్ని వీడియోలను కూడా చూస్తాను! ఈ రోజుల్లో మీకు అన్నీ వీడియో ఫార్మాట్‌లో దొరుకుతాయి.",
              "or": "ଏହାକୁ ମଧ୍ୟ ବୁଝାଇବାକୁ ମୁଁ କିଛି ଭିଡିଓ ପାଇବି! ଆଜିକାଲି\n  ତୁମେ ସବୁକିଛି ଭିଡିଓ ଫର୍ମାଟରେ ପାଇବ |",
              "as": "মই সেইটো বুজাবলৈ কিছুমান ভিডিঅ' বিচাৰি পাম! আজিকালি ভিডিঅ' ফৰ্মেটত সকলো পোৱা যায়।",
              "gu": "હું તેને સમજાવવા માટે કેટલાક વિડિઓઝ પણ શોધીશ! આજકાલ તમને બધું જ વિડિયોમાં મળે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "Anyway, what did you find out about the exam?",
              "hi": "खैर, तुमको परीक्षा के बारे में क्या पता चला?",
              "ka": "ಏನಾದರೂ ಇರಲಿ, ಪರೀಕ್ಷೆಯ ಬಗ್ಗೆ ನೀನು ಏನು ಕಂಡುಕೊಂಡಿದ್ದೀಯಾ?",
              "te": "సరే కానీ, పరీక్ష గురించి నువ్వు ఏమి కనుగొన్నావు?",
              "or": "ଯାହା ହେଉ, ପରୀକ୍ଷା ବିଷୟରେ ତୁମେ କ’ଣ ଜାଣିଲ?",
              "as": "যি নহওক, পৰীক্ষাটোৰ বিষয়ে কি কি জানিব পাৰিলা?",
              "gu": "બરાબર તો, તને પરીક્ષા વિશે શું જાણવા મળ્યું?"
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4(ii)_2_4////",
              "hi": "X4 (ii) _2_4 ////",
              "ka": "X4(ii)_2_4////",
              "te": "X4(ii)_2_4////",
              "or": "X4(ii)_2_4////",
              "as": "X4(ii)_2_4////",
              "gu": "X4(ii)_2_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "So there are three steps in the whole selection process. First is the preliminary exam (prelims). If you clear that exam, you have to give the Main exam. These exams are based on subjects like Current Affairs, History, Geography, Civics and Economics.",
              "hi": "तो पूरी चयन प्रक्रिया में तीन कदम हैं। सबसे पहले प्रारंभिक परीक्षा (Prelims) है। यदि तुम उस परीक्षा को उत्तीर्ण करते हो, तो तुमको मुख्य परीक्षा देना होगा। ये परीक्षाएं वर्तमान मामलों, इतिहास, भूगोल, नागरिक और अर्थशास्त्र जैसे विषयों पर आधारित हैं।",
              "ka": "ಅಂದರೆ ಸಂಪೂರ್ಣ ಆಯ್ಕೆ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ 3 ಹಂತಗಳಿವೆ. ಮೊದಲನೆಯದು ಪೂರ್ವಭಾವಿ ಪರೀಕ್ಷೆ (ಪ್ರಿಲಿಮ್ಸ್). ಆ ಪರೀಕ್ಷೆಯಲ್ಲಿ ತೇರ್ಗಡೆಯಾದರೆ ಮುಖ್ಯ ಪರೀಕ್ಷೆ ಬರೆಯಬೇಕು. ಈ ಪರೀಕ್ಷೆಗಳು ಪ್ರಚಲಿತ ವಿದ್ಯಮಾನಗಳು, ಇತಿಹಾಸ, ಭೂಗೋಳ, ನಾಗರಿಕ ಮತ್ತು ಅರ್ಥಶಾಸ್ತ್ರದಂತಹ ವಿಷಯಗಳನ್ನು ಆಧರಿಸಿರೂತ್ತವೆ.",
              "te": "మొత్తం ఎంపిక ప్రక్రియలో మూడు దశలు ఉన్నాయి. ముందుగా ప్రిలిమినరీ పరీక్ష (ప్రిలిమ్స్). ఆ పరీక్షలో ఉత్తీర్ణత సాధిస్తే మెయిన్ పరీక్ష రాయాల్సి ఉంటుంది. ఈ పరీక్షలు కరెంట్ అఫైర్స్, హిస్టరీ, జియోగ్రఫీ, సివిక్స్ మరియు ఎకనామిక్స్ వంటి సబ్జెక్టుల ఆధారంగా ఉంటాయి.",
              "or": "ତେଣୁ ସମଗ୍ର ଚୟନ ପ୍ରକ୍ରିୟାରେ ତିନୋଟି ସୋପାନ ଅଛି | ପ୍ରଥମଟି ହେଉଛି\n  ପ୍ରାଥମିକ ପରୀକ୍ଷା (ପ୍ରିଲିମ୍) | ଯଦି ତୁମେ ସେହି ପରୀକ୍ଷାକୁ ପାସ କର, ତୁମକୁ ମୁଖ୍ୟ ପରୀକ୍ଷା ଦେବାକୁ ପଡିବ | ଏହି ପରୀକ୍ଷାଗୁଡ଼ିକ ସାମ୍ପ୍ରତିକ ବ୍ୟାପାର, ଇତିହାସ, ଭୂଗୋଳ, ସିଭିକ୍ସ ଏବଂ ଇକୋନୋମିକ୍ସ ଭଳି ବିଷୟ ଉପରେ ଆଧାରିତ |",
              "as": "গোটেই বাছনি প্ৰক্ৰিয়াটোত তিনিটা পদক্ষেপ আছে। প্ৰথমটো হৈছে প্ৰাথমিক পৰীক্ষা (prelims)। যদি তুমি সেই পৰীক্ষাত উত্তীৰ্ণ হোৱা, তেতিয়া মুখ্য পৰীক্ষা দিব লাগিব। এই পৰীক্ষাবোৰ সাম্প্ৰতিক পৰিক্ৰমা, ইতিহাস, ভূগোল, নাগৰিক আৰু অৰ্থনীতিৰ দৰে বিষয়ৰ ওপৰত আধাৰিত।",
              "gu": "આમાં સમગ્ર પસંદગી પ્રક્રિયામાં ત્રણ પગલાં છે. પ્રથમ પ્રારંભિક પરીક્ષા (પ્રિલિમ) છે. જો તમે તે પરીક્ષા પાસ કરો છો, તો તમારે મુખ્ય પરીક્ષા આપવી પડશે. આ પરીક્ષાઓ વર્તમાન બાબતો, ઇતિહાસ, ભૂગોળ, નાગરિકશાસ્ત્ર અને અર્થશાસ્ત્ર જેવા વિષયો પર આધારિત છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "And what about the third step?",
              "hi": "और तीसरे कदम के बारे में क्या?",
              "ka": "ಮತ್ತು ಮೂರನೇ ಹಂತದ ಬಗ್ಗೆ ಏನು?",
              "te": "మరియు మూడవ దశ సంగతి ఏమిటి?",
              "or": "ଏବଂ ତୃତୀୟ ସୋପାନ ବିଷୟରେ କଣ?",
              "as": "আৰু তৃতীয় পদক্ষেপটো কি?",
              "gu": "અને ત્રીજા પગલા વિશે શું?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Yes. The third step is the personality test (also known as the interview round). This test is like a discussion between the panel of interviewers from UPSC and the candidate. You are called for the personality test only if you clear the main exam.",
              "hi": "हां। तीसरा कदम व्यक्तित्व परीक्षण (साक्षात्कार दौर के रूप में भी जाना जाता है) है। यह परीक्षण यूपीएससी से साक्षात्कारकर्ताओं के पैनल और उम्मीदवार  के बीच एक चर्चा की तरह है। यदि तुम मुख्य परीक्षा को उत्तीर्ण करते हो,  केवल तभी तुमको व्यक्तित्व परीक्षण के लिए बुलाया जाता है।",
              "ka": "ಮೂರನೇ ಹಂತವು ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಯಾಗಿದೆ (ಇದನ್ನು ಸಂದರ್ಶನ ಸುತ್ತು ಎಂದೂ ಕರೆಯಲಾಗುತ್ತದೆ). ಈ ಪರೀಕ್ಷೆಯು UPSC ಸಂದರ್ಶಕರ ಸಮಿತಿ ಮತ್ತು ಅಭ್ಯರ್ಥಿಯ ನಡುವಿನ ಚರ್ಚೆಯಂತಿದೆ. ನೀವು ಮುಖ್ಯ ಪರೀಕ್ಷೆಯಲ್ಲಿ ತೇರ್ಗಡೆಯಾದರೆ ಮಾತ್ರ ನಿಮ್ಮನ್ನು ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಗೆ ಕರೆಯಲಾಗುತ್ತದೆ",
              "te": "అవును. మూడవ దశ పర్సనాలిటీ టెస్ట్ (ఇంటర్వ్యూ రౌండ్ అని కూడా పిలుస్తారు). ఈ పరీక్ష UPSC నుండి ఇంటర్వ్యూయర్ల ప్యానెల్ మరియు అభ్యర్థి మధ్య చర్చ లాంటిది. మీరు మెయిన్ పరీక్షలో ఉత్తీర్ణులైతేనే మిమ్మల్ని పర్సనాలిటీ టెస్ట్ కు పిలుస్తారు.",
              "or": "ହଁ ତୃତୀୟ ସୋପାନ ହେଉଛି ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷା (ସାକ୍ଷାତକାର ରାଉଣ୍ଡ ଭାବରେ \n ମଧ୍ୟ ଜଣାଶୁଣା) | ଏହି ପରୀକ୍ଷା UPSC ର ସାକ୍ଷାତକାର ପ୍ୟାନେଲ ଏବଂ ପ୍ରାର୍ଥୀଙ୍କ ମଧ୍ୟରେ ଆଲୋଚନା ପରି | ଯଦି ଆପଣ ମୁଖ୍ୟ ପରୀକ୍ଷା ସଫା କରନ୍ତି ତେବେ ଆପଣଙ୍କୁ ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷା ପାଇଁ ଡକାଯାଏ |",
              "as": "হয় ৷ তৃতীয় পদক্ষেপটো হ'ল ব্যক্তিত্বৰ পৰীক্ষা (সাক্ষাৎকাৰ ৰাউণ্ড বুলিও জনা যায়)। এই পৰীক্ষাটো UPSCৰ সাক্ষাৎকাৰ লওতাৰ এটা পেনেল আৰু প্ৰাৰ্থীৰ মাজত হোৱা আলোচনাৰ দৰে। তুমি মূল পৰীক্ষাত উত্তীৰ্ণ হ'লেহে তোমাক ব্যক্তিত্বৰ পৰীক্ষাৰ বাবে মাতিব।",
              "gu": "હા. ત્રીજું પગલું વ્યક્તિત્વ પરીક્ષણ છે (જે ઇન્ટરવ્યુ રાઉન્ડ તરીકે પણ ઓળખાય છે). આ પરીક્ષા યુપીએસસીના ઇન્ટરવ્યુઅરોની પેનલ અને ઉમેદવાર વચ્ચેની ચર્ચા જેવી છે. જો તમે મુખ્ય પરીક્ષા પાસ કરો તો જ તમને વ્યક્તિત્વ કસોટી માટે બોલાવવામાં આવશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "That's not so bad. If we can get through the first two rounds, the personality test will be a piece of cake!",
              "hi": "ये उतना बुरा नहीं है। अगर हम पहले दो राउंड उत्तीर्ण कर सकते हैं, तो व्यक्तित्व परीक्षण केक के एक टुकड़े की तरह आसान होगा!",
              "ka": "ಅಂದರೆ ಅದು ಅಷ್ಟೇನೂ ಕಷ್ಟವೆನಿಸುವುದಿಲ್ಲ. ನಾನು ಮೊದಲ ಎರಡು ಸುತ್ತುಗಳನ್ನು ದಾಟಲು ಸಾಧ್ಯವಾದಲ್ಲಿ, ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಯು ಒಂದು ಕೇಕ್ ತುಂಡು ತಿನ್ನುವ ರೀತಿಯಷ್ಟು ಸುಲಭ!",
              "te": "అది అంత కష్టం ఏమీ కాదు. నేను మొదటి రెండు రౌండ్‌లను అధిగమించగలిగితే, పర్సనాలిటీ టెస్ట్ చాలా సులభంగా అవుతుంది!",
              "or": "ତାହା ଏତେ ଖରାପ ନୁହେଁ | ଯଦି ଆମେ ପ୍ରଥମ ଦୁଇଟି ରାଉଣ୍ଡ ଦେଇ \n ପାରିବା, ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷା ଏକ ଅଂଶ ହେବ!",
              "as": "সেয়া ইমান বেয়া নহয়। যদি মই প্ৰথম দুটা ৰাউণ্ড পাৰ হ'ব পাৰোঁ, ব্যক্তিত্বৰ পৰীক্ষাটো কেকৰ টুকুৰাৰ দৰে হ'ব!",
              "gu": "તે એટલું સરળ નથી. જો આપણે પ્રથમ બે રાઉન્ડમાંથી પસાર થઈ શકીએ, તો વ્યક્તિત્વ પરીક્ષણ સરળ હશે!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Haha, the interviewers will not be that easy to impress. The final score is a total of all three steps. Only if your rank falls within the cut-off rank, you will get appointed to the Civil Services.",
              "hi": "हाहा, साक्षात्कारकर्ता प्रभावित नहीं होंगे। अंतिम स्कोर कुल तीन चरणों के स्कोर का योग है। अगर तुम्हारी  रैंक कट ऑफ रैंक के भीतर आती है, तो तुम सिविल सेवाओं में नियुक्त हो जाओगे।",
              "ka": "ಹಾ ಹಾ, ಸಂದರ್ಶಕರನ್ನು ಮೆಚ್ಚಿಸುವುದು ಅಷ್ಟು ಸುಲಭವಲ್ಲ. ಅಂತಿಮ ಸ್ಕೋರ್ ಎಲ್ಲಾ ಮೂರು ಹಂತಗಳ ಒಟ್ಟು ಮೊತ್ತವಾಗಿರುತ್ತದೆ. ನಿನ್ನ ಶ್ರೇಣಿಯು ಕಟ್-ಆಫ್ ಶ್ರೇಣಿಯೊಳಗೆ ಬಂದರೆ ಮಾತ್ರ, ನೀನು ನಾಗರಿಕ ಸೇವೆಗಳಿಗೆ ನೇಮಕಗೊಳ್ಳುತ್ತೀಯಾ.",
              "te": "ఇంటర్వ్యూయర్ లను ఇంప్రెస్ చేయడం అంత ఈజీ కాదు మాధవ్. మూడు దశల మొత్తం కలిపితే వచ్చేది చివరి స్కోర్. నీ ర్యాంక్ కటాఫ్ ర్యాంక్‌లోకి వస్తే మాత్రమే, నిన్ను సివిల్ సర్వీసెస్‌లో నియమిస్తారు.",
              "or": "ହାହା, ସାକ୍ଷାତକାରକାରୀମାନେ ପ୍ରଭାବିତ କରିବା ଏତେ ସହଜ ହେବ ନାହିଁ |\n  ଅନ୍ତିମ ସ୍କୋର ହେଉଛି ସମସ୍ତ ତିନୋଟି ସୋପାନ | କେବଳ ଯଦି ତୁମର ରାଙ୍କ୍ କଟ୍ ଅଫ୍ ରଙ୍କ ମଧ୍ୟରେ ପଡ଼େ, ତୁମେ ସିଭିଲ୍ ସର୍ଭିସରେ ନିଯୁକ୍ତ ହେବ |",
              "as": "হাহা, সাক্ষাৎকাৰ লওতাসকলক প্ৰভাৱিত কৰিবলৈ ইমান সহজ নহ'ব। চূড়ান্ত নম্বৰটো তিনিওটা পদক্ষেপৰ সৰ্বমুঠ। তোমাৰ ৰেংক কাট-অফ ৰেংকৰ ভিতৰত পৰিলেহে, তুমি অসামৰিক সেৱাত নিযুক্তি পাবা।",
              "gu": "હાહા, ઇન્ટરવ્યુ લેનારાઓને પ્રભાવિત કરવા એટલા સરળ નહીં હોય. અંતિમ સ્કોર ત્રણેય પગલાંનો કુલ છે. જો તમારો રેન્ક કટ-ઓફ રેન્કમાં આવે તો જ તમને સિવિલ સર્વિસીસમાં નિયુક્ત કરવામાં આવશે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "Research",
              "hi": "शोध ",
              "ka": "ಸಂಶೋಧನೆ",
              "te": "రీసర్చ్",
              "or": "ଅନୁସନ୍ଧାନ |",
              "as": "গৱেষণা",
              "gu": "સંશોધન"
            }
          }
        ]
      },
      "scene7": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_1_1",
              "hi": "X5_1_1",
              "ka": "X5_1_1",
              "te": "X5_1_1",
              "or": "X5_1_1",
              "as": "X5_1_1",
              "gu": "X5_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "All 3 of them come together to share their findings.",
              "hi": "वे तीनों अपने निष्कर्ष साझा करने के लिए एक साथ आते हैं।",
              "ka": "ಆ ಮೂವರೂ ತಮ್ಮ ಸಂಶೋಧನೆಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ಒಟ್ಟಿಗೆ ಸೇರುತ್ತಾರೆ.",
              "te": "వారు తెలుసుకున్నవాటి గురించి మాట్లాడడానికి ముగ్గురూ కలిశారు.",
              "or": "ସେମାନଙ୍କର ଫଳାଫଳ ବିଷୟରେ\n  ଆଲୋଚନା କରିବାକୁ ସେମାନେ 3 ଜଣ ଏକତ୍ରିତ ହୁଅନ୍ତି |",
              "as": "তেওঁলোক ৩জনে একেলগে তেওঁলোকৰ ফলাফলবোৰ আলোচনা কৰিবলৈ আহে।",
              "gu": "તે બધા 3 તેમના તારણો કહેવા માટે એક બીજા સાથે મળે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_1_2////",
              "hi": "X5_1_2 /////",
              "ka": "X5_1_2////",
              "te": "X5_1_2////",
              "or": "X5_1_2////",
              "as": "X5_1_2////",
              "gu": "X5_1_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I think I'm even more sure about becoming a teacher.",
              "hi": "मुझे लगता है कि मैं एक शिक्षक बनने के बारे में और भी निश्चित हूं।",
              "ka": "ಶಿಕ್ಷಕನಾಗುವ ಬಗ್ಗೆ ಇನ್ನೂ ಹೆಚ್ಚು ಖಚಿತವಾಗಿದ್ದೇನೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "నేను టీచర్‌గా మారడం మంచిది అని మరింత ఖచ్చితంగా అనుకుంటున్నాను.",
              "or": "ମୁଁ ଭାବୁଛି ମୁଁ ଜଣେ ଶିକ୍ଷକ ହେବା ବିଷୟରେ ଅଧିକ ନିଶ୍ଚିତ ଅଟେ |",
              "as": "মই ভাবো মই শিক্ষক হোৱাৰ বিষয়ে নিশ্চিত।",
              "gu": "મને લાગે છે કે હું શિક્ષક બનવા વિશે વધુ ચોક્કસ છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "And how did you conclude that from reading about Civil Services?",
              "hi": "और तुमने सिविल सेवाओं के बारे में पढ़ने से यह कैसे निष्कर्ष निकाला?",
              "ka": "ಮತ್ತು ನಾಗರಿಕ ಸೇವೆಗಳ ಬಗ್ಗೆ ಓದುವುದರಿಂದ ನೀನು ಅದನ್ನು ಹೇಗೆ ತೀರ್ಮಾನಿಸಿದ್ದೀಯಾ?",
              "te": "మరియు సివిల్ సర్వీసెస్ గురించి చదవడం నుండి నువ్వు ఎలా నిర్ధారించుకున్నావు?",
              "or": "ଏବଂ ସିଭିଲ୍ ସର୍ଭିସେସ୍ ବିଷୟରେ ପଢିବା ଠାରୁ ତୁମେ କିପରି ସିଦ୍ଧାନ୍ତ ନେଇଛ?",
              "as": "আৰু অসামৰিক সেৱাৰ বিষয়ে পঢ়াৰ পৰা তুমি কেনেকৈ এই সিদ্ধান্তত উপনীত হ’লা?",
              "gu": "અને સિવિલ સર્વિસિસ વિશે વાંચીને તું તે કેવી રીતે નિષ્કર્ષ પર આવ્યો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "The more I read, I realised that the intent is similar, which is to serve your country. I would enjoy the roles and responsibilities of being a teacher more.",
              "hi": "जितना अधिक मैंने पढ़ा, मुझे एहसास हुआ कि मेरा इरादा समान है, जो अपने देश की सेवा करना है। मैं एक शिक्षक होने की भूमिका और जिम्मेदारियों का आनंद लूंगा।",
              "ka": "ನಾನು ಹೆಚ್ಚು ಓದುತ್ತಿದ್ದಂತೆ, ನಿಮ್ಮ ದೇಶಕ್ಕೆ ಸೇವೆ ಸಲ್ಲಿಸುವ ಉದ್ದೇಶವನ್ನು ಹೋಲುತ್ತದೆ ಎನ್ನುವುದನ್ನು ನಾನು ಅರಿತುಕೊಂಡೆ. ನಾನು ಒಬ್ಬ ಶಿಕ್ಷಕನ ಹುದ್ದೆಗಳಿ ಹಾಗೂ ಜವಾಬ್ದಾರಿಗಳನ್ನು ಹೆಚ್ಚು ಆನಂದಿಸುತ್ತೇನೆ.",
              "te": "నేను చదివిన కొద్దీ, ఉద్దేశ్యం మాత్రం దేశానికి సేవ చేయడమే అని నేను గ్రహించాను. నేను టీచర్‌గా బాధ్యతలను ఎక్కువగా ఆనందిస్తాను.",
              "or": "ମୁଁ ଯେତେ ଅଧିକ ପଢିଲି, ମୁଁ ଅନୁଭବ କଲି ଯେ ଉଦ୍ଦେଶ୍ୟ ସମାନ, ଯାହା ତୁମର\n  ଦେଶକୁ ସେବା କରିବା | ମୁଁ ଅଧିକ ଶିକ୍ଷକ ହେବାର ଭୂମିକା ଏବଂ ଦାୟିତ୍ଵକୁ ଉପଭୋଗ କରିବି |",
              "as": "মই যিমানে পঢ়িছিলোঁ, মই অনুভৱ কৰিছিলোঁ যে উদ্দেশ্যটো একেটাই, যি হৈছে তোমাৰ দেশৰ সেৱা কৰা। মই এজন শিক্ষক হিচাপে ভূমিকা আৰু দায়িত্ববোৰ অধিক উপভোগ কৰিম।",
              "gu": "હું જેટલું વાંચું છું, મને સમજાયું કે આશય સમાન છે, જે તમારા દેશની સેવા કરવાનો છે. હું શિક્ષક તરીકેની ભૂમિકાઓ અને જવાબદારીઓનો વધુ આનંદ માણીશ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Hmm.. Teaching is one of the noblest things to do, but I think I'm sure I want to prepare for UPSC exams! They have the power to change the system! Imagine all that we can do for the system if we become IAS officers.",
              "hi": "हम्म .. शिक्षण सबसे महान चीजों में से एक है, लेकिन मुझे लगता है कि मुझे विश्वास है कि मैं यूपीएससी परीक्षाओं के लिए तैयारी करना चाहता हूं! उनके पास सिस्टम को बदलने की शक्ति है! कल्पना करो,  सिस्टम के लिए हम तभी कुछ कर सकते हैं अगर हम आईएएस अधिकारी बनेंगे ।",
              "ka": "ಹ್ಮ.. ಬೋಧನೆಯು ನೀನು ಮಾಡಬಹುದಾದ ಉದಾತ್ತ ಕೆಲಸಗಳಲ್ಲಿ ಒಂದಾಗಿದೆ, ಆದರೆ ನಾನು UPSC ಪರೀಕ್ಷೆಗಳಿಗೆ ತಯಾರಿ ಮಾಡಲು ಬಯಸುತ್ತೇನೆ ಎಂದು ಭಾವಿಸುತ್ತೇನೆ! ವ್ಯವಸ್ಥೆಯನ್ನು ಬದಲಾಯಿಸುವ ಶಕ್ತಿ ಅವರಿಗಿದೆ! ನಾವು ಐಎಎಸ್ ಅಧಿಕಾರಿಯಾದರೆ ವ್ಯವಸ್ಥೆಗೆ ಏನೆಲ್ಲಾ ಮಾಡಬಹುದು ಎಂದು ಊಹಿಸಿಕೊಳ್ಳಿ.",
              "te": "హ్మ్.. టీచింగ్ అనేది చాలా గొప్ప పని, కానీ నేను UPSC పరీక్షలకు ప్రిపేర్ అవ్వాలని అనుకుంటున్నాను! వ్యవస్థను మార్చే శక్తి వారికి ఉంది! IAS అధికారిగా మారితే వ్యవస్థకు మనం చేయగలిగినదంతా ఊహించండి.",
              "or": "ହଁ.. ଶିକ୍ଷାଦାନ କରିବା ସବୁଠାରୁ ଉତ୍ତମ କାର୍ଯ୍ୟ ମଧ୍ୟରୁ ଗୋଟିଏ, କିନ୍ତୁ ମୁଁ ଭାବୁଛି\n  ଯେ ମୁଁ UPSC ପରୀକ୍ଷା ପାଇଁ ପ୍ରସ୍ତୁତ ହେବାକୁ ଚାହୁଁଛି! ସିଷ୍ଟମ୍ ବଦଳାଇବାକୁ ସେମାନଙ୍କର ଶକ୍ତି ଅଛି! ଯଦି ଆମେ IAS ଅଧିକାରୀ ହେବା ତେବେ ସିଷ୍ଟମ ପାଇଁ ଆମେ ଯାହା କରିପାରିବା ତାହା କଳ୍ପନା କର |",
              "as": "হুমম.. শিক্ষাদান হৈছে কৰিবপৰা আটাইতকৈ মহৎ কামবোৰৰ ভিতৰত এটা, কিন্তু মই ভাবো যে মই নিশ্চিতভাৱে UPSC পৰীক্ষাৰ বাবে প্ৰস্তুতি চলাব বিচাৰোঁ! তেওঁলোকৰ প্ৰণালীটো সলনি কৰাৰ ক্ষমতা আছে! কল্পনা কৰাচোঁন আমি যদি IAS বিষয়া হওঁ তেন্তে আমি প্ৰণালীটোৰ বাবে কি কি কৰিব পাৰোঁ।",
              "gu": "હમ્મ.. અધ્યાપન એ સૌથી ઉમદા બાબતોમાંની એક છે, પરંતુ મને લાગે છે કે મને ખાતરી છે કે હું UPSC પરીક્ષાની તૈયારી કરવા માંગુ છું! તેની પાસે સત્તા છે સિસ્ટમ બદલવાની. જો આપણે IAS ઓફિસર બનીએ તો સિસ્ટમ માટે આપણે શું કરી શકીએ તેની કલ્પના કરો."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_1_3",
              "hi": "X5_1_3",
              "ka": "X5_1_3",
              "te": "X5_1_3",
              "or": "X5_1_3",
              "as": "X5_1_3",
              "gu": "X5_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav's enthusiasm influences Kuldeep and both of them decide to prepare for the UPSC Exam.",
              "hi": "माधव का उत्साह कुलदीप को प्रभावित करता है और दोनों यूपीएससी परीक्षा के लिए तैयारी करने का फैसला करते हैं।",
              "ka": "ಮಾಧವನಲ್ಲಿ ಈರುವ ಉತ್ಸಾಹವು ಕುಲದೀಪ್ ಮೇಲೆ ಪ್ರಭಾವ ಬೀರುತ್ತದೆ ಮತ್ತು ಇಬ್ಬರೂ UPSC ಪರೀಕ್ಷೆಗೆ ತಯಾರಿ ನಡೆಸಲು ನಿರ್ಧರಿಸುತ್ತಾರೆ.",
              "te": "మాధవ్ యొక్క ఉత్సాహం కుల్దీప్‌పై ప్రభావం చూపుతుంది మరియు వారిద్దరూ UPSC పరీక్షకు సిద్ధం కావాలని నిర్ణయించుకున్నారు.",
              "or": "ମାଧବଙ୍କ ଉତ୍ସାହ କୁଲଦୀପଙ୍କୁ ପ୍ରଭାବିତ କରିଥାଏ ଏବଂ ଉଭୟ UPSC\n  ପରୀକ୍ଷା ପାଇଁ ପ୍ରସ୍ତୁତ ହେବାକୁ ନିଷ୍ପତ୍ତି ନେଇଥିଲେ |",
              "as": "মাধৱৰ উৎসাহে কুলদীপকো উৎসাহিত কৰিলে আৰু তেওঁলোক দুয়োজনে UPSC পৰীক্ষাৰ বাবে প্ৰস্তুতি চলোৱাৰ সিদ্ধান্ত লয়।",
              "gu": "માધવનો ઉત્સાહ કુલદીપને પ્રભાવિત કરે છે અને તે બંને UPSC પરીક્ષાની તૈયારી કરવાનું નક્કી કરે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_1_4",
              "hi": "X5_1_4",
              "ka": "X5_1_4",
              "te": "X5_1_4",
              "or": "X5_1_4",
              "as": "X5_1_4",
              "gu": "X5_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "However, they don't know how to pay for their expenses without a job. They feel that if they work full time, they won't be able to focus on their studies.",
              "hi": "हालांकि, वे नहीं जानते कि नौकरी के बिना अपने खर्चों के लिए भुगतान कैसे करें। उन्हें लगता है कि अगर वे पूर्णकालिक काम करते हैं, तो वे अपने अध्ययन पर ध्यान केंद्रित नहीं कर पाएंगे।",
              "ka": "ಆದರೆ, ಕೆಲಸವನ್ನು ಹೊಂದಿಲ್ಲದೆ ತಮ್ಮ ಖರ್ಚುಗಳನ್ನು ಹೇಗೆ ಪಾವತಿಸಬೇಕೆಂದು ಅವರಿಗೆ ತಿಳಿದಿಲ್ಲ. ಅವರು ಪೂರ್ಣ ಸಮಯ ಕೆಲಸ ಮಾಡಿದರೆ, ತಮ್ಮ ಅಧ್ಯಯನದ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಲು ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ ಎಂದು ಅವರು ಭಾವಿಸುತ್ತಾರೆ.",
              "te": "కానీ ఉద్యోగం లేకపోవడం వల్ల ఖర్చులు ఎలా భరించాలో తెలియడం లేదు. ఫుల్ టైమ్ పనిచేస్తే చదువుపై దృష్టి పెట్టలేమని వారు అనుకుంటున్నారు.",
              "or": "ତଥାପି, ସେମାନେ ଚାକିରୀ ବିନା ସେମାନଙ୍କ ଖର୍ଚ୍ଚ କିପରି ଦେବେ ତାହା \n ଜାଣନ୍ତି ନାହିଁ | ସେମାନେ ଅନୁଭବ କରନ୍ତି ଯେ ଯଦି ସେମାନେ ପୂର୍ଣ୍ଣ ସମୟ କାମ କରନ୍ତି, ତେବେ ସେମାନେ ସେମାନଙ୍କର ଅଧ୍ୟୟନ ଉପରେ ଧ୍ୟାନ ଦେବାକୁ ସମର୍ଥ ହେବେ ନାହିଁ |",
              "as": "অৱশ্যে, তেওঁলোকে চাকৰি অবিহনে তেওঁলোকৰ ব্যয় কেনেদৰে পৰিশোধ কৰিব নাজানে। তেওঁলোকে অনুভৱ কৰিলে যে যদি তেওঁলোকে সম্পূৰ্ণ সময় কাম কৰে, তেওঁলোকে তেওঁলোকৰ অধ্যয়নত মনোযোগ দিব নোৱাৰিব।",
              "gu": "જો કે, તેઓ જાણતા નથી કે નોકરી વિના તેમના ખર્ચાઓ કેવી રીતે ચૂકવવા. તેમને લાગે છે કે જો તેઓ પૂરો સમય કામ કરશે તો તેઓ તેમના અભ્યાસ પર ધ્યાન કેન્દ્રિત કરી શકશે નહીં."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_2_1",
              "hi": "X5_2_1",
              "ka": "X5_2_1",
              "te": "X5_2_1",
              "or": "X5_2_1",
              "as": "X5_2_1",
              "gu": "X5_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Kuldeep reminds Madhav that some of the seniors in the cultural committee are also preparing for Civil Services. The two of them call these seniors to know how they are preparing for the exam.",
              "hi": "कुलदीप माधव को याद दिलाता है कि सांस्कृतिक समिति के कुछ सीनियर्स भी सिविल सेवाओं की तैयारी कर रहे हैं। वे दोनों इन सीनियर्स को यह जानने के लिए कॉल करते हैं कि वे परीक्षा के लिए कैसे तैयारी कर रहे हैं।",
              "ka": "ಸಾಂಸ್ಕೃತಿಕ ಸಮಿತಿಯ ಕೆಲವು ಹಿರಿಯರು ಸಿವಿಲ್ ಸರ್ವೀಸ್‌ಗೂ ತಯಾರಿ ನಡೆಸುತ್ತಿದ್ದಾರೆ ಎಂದು ಕುಲದೀಪ್ ಮಾಧವ್‌ಗೆ ನೆನಪಿಸುತ್ತಾನೆ. ಅವರಿಬ್ಬರು ಈ ಸೀನಿಯರ್ ಗಳಿಗೆ ಫೋನ್ ಮಾಡಿ ಪರೀಕ್ಷೆಗೆ ಹೇಗೆ ತಯಾರಿ ನಡೆಸುತ್ತಿದ್ದಾರೆ ಎನ್ನುವುದನ್ನು ತಿಳಿದುಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "కల్చరల్ కమిటీలోని కొందరు సీనియర్లు కూడా సివిల్ సర్వీసెస్‌కు ప్రిపేర్ అవుతున్నారని కుల్దీప్ మాధవ్‌కు గుర్తు చేశాడు. వాళ్ళిద్దరూ పరీక్షకు ఎలా ప్రిపేర్ అవుతున్నారో తెలుసుకోవడానికి ఈ సీనియర్లకు ఫోన్ చేస్తారు.",
              "or": "କୁଲଦୀପ ମାଧବଙ୍କୁ ମନେ ପକାଇ ଦେଇଛନ୍ତି ଯେ ସାଂସ୍କୃତିକ କମିଟିର \n କିଛି ବୟସ୍କ ବ୍ୟକ୍ତି ମଧ୍ୟ ସିଭିଲ ସର୍ଭିସେସ୍ ପାଇଁ ପ୍ରସ୍ତୁତ ହେଉଛନ୍ତି। ସେମାନେ କିପରି ଏହି ପରୀକ୍ଷା ପାଇଁ ପ୍ରସ୍ତୁତ ହେଉଛନ୍ତି ଜାଣିବା ପାଇଁ ଏହି ସିନିୟରମାନଙ୍କୁ ଡାକନ୍ତି |",
              "as": "কুলদীপে মাধৱক সোঁৱৰাই দিলে যে সাংস্কৃতিক সমিতিৰ কেইজনমান জ্যেষ্ঠই অসামৰিক সেৱাৰ বাবেও প্ৰস্তুতি চলাই আছে। তেওঁলোক দুয়োজনে সেই জ্যেষ্ঠসকলক পৰীক্ষাৰ বাবে কেনেদৰে প্ৰস্তুতি চলাই আছে জানিবলৈ মাতি আনিলে।",
              "gu": "કુલદીપ માધવને યાદ કરાવે છે કે સાંસ્કૃતિક સમિતિના કેટલાક વરિષ્ઠો પણ સિવિલ સર્વિસીસની તૈયારી કરી રહ્યા છે. તેઓ પરીક્ષાની તૈયારી કેવી રીતે કરી રહ્યા છે તે જાણવા માટે તે બંને આ સિનિયરોને ફોન કરે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_2_2",
              "hi": "X5_2_2",
              "ka": "X5_2_2",
              "te": "X5_2_2",
              "or": "X5_2_2",
              "as": "X5_2_2",
              "gu": "X5_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Their seniors, Varun and Khushboo, tell them that they stayed at home for their first year of preparation and just focused on their studies.",
              "hi": "उनके सीनियर्स, वरुण और खुशबू, उन्हें बताते हैं कि वे तैयारी के अपने पहले वर्ष के लिए घर पर रहे और सिर्फ अपने अध्ययन पर ध्यान केंद्रित किया।",
              "ka": "ಅವರ ಹಿರಿಯರಾದ ವರುಣ್ ಮತ್ತು ಖುಷ್ಬೂ, ಅವರು ತಮ್ಮ ಮೊದಲ ವರ್ಷದ ತಯಾರಿಗಾಗಿ ಮನೆಯಲ್ಲಿಯೇ ಇದ್ದರು ಮತ್ತು ತಾವು ಅಧ್ಯಯನದ ಮೇಲೆ ಗಮನವನ್ನು ಕೇಂದ್ರೀಕರಿಸಿದ್ದೇವೆ ಎಂದು ಅವರಿಗೆ ಹೇಳುತ್ತಾರೆ.",
              "te": "వారి సీనియర్లు, వరుణ్ మరియు ఖుష్బూ, వారు తమ మొదటి సంవత్సరం ప్రిపరేషన్ కోసం ఇంట్లోనే ఉండి, కేవలం చదువుపైనే దృష్టి పెట్టారని వారికి చెప్పారు.",
              "or": "ସେମାନଙ୍କର ସିନିୟର, ବରୁଣ ଏବଂ ଖୁସବୋ ସେମାନଙ୍କୁ କୁହନ୍ତି ଯେ\n  ସେମାନେ ପ୍ରଥମ ବର୍ଷର ପ୍ରସ୍ତୁତି ପାଇଁ ଘରେ ରହିଲେ ଏବଂ କେବଳ ସେମାନଙ୍କ ଅଧ୍ୟୟନ ଉପରେ ଧ୍ୟାନ ଦେଇଥିଲେ |",
              "as": "তেওঁলোকৰ জ্যেষ্ঠ, বৰুণ আৰু খুচবুৱে তেওঁলোকক ক’লে যে তেওঁলোকে তেওঁলোকৰ প্ৰস্তুতিৰ প্ৰথম বছৰৰ বাবে ঘৰত আছিল আৰু কেৱল তেওঁলোকৰ পঢ়াশুনাত মনোনিৱেশ কৰিছিল।",
              "gu": "તેમના ઉપરી વિધાર્થી, વરુણ અને ખુશ્બુ, તેમને કહે છે કે તેઓ તેમની તૈયારીના પ્રથમ વર્ષ સુધી ઘરે જ રહ્યા અને માત્ર તેમના અભ્યાસ પર ધ્યાન કેન્દ્રિત કર્યું."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_2_3",
              "hi": "X5_2_3",
              "ka": "X5_2_3",
              "te": "X5_2_3",
              "or": "X5_2_3",
              "as": "X5_2_3",
              "gu": "X5_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "However, when they couldn't clear in the first attempt, they took up part-time jobs on the side to reduce the burden their family for their expenses.",
              "hi": "हालांकि, जब वे पहले प्रयास में उत्तीर्ण नहीं हो सके, तो उन्होंने अपने परिवार के बोझ को कम करने के लिए अपने खर्च के लिए  साथ साथ अंशकालिक नौकरियां कर लीं।",
              "ka": "ಆದಾಗ್ಯೂ, ಮೊದಲ ಪ್ರಯತ್ನದಲ್ಲಿ ಅವರು ತೇರ್ಗಡೆಗೊಳ್ಳಲು ಸಾಧ್ಯವಾಗದಿದ್ದಾಗ, ಅವರು ತಮ್ಮ ವೆಚ್ಚಕ್ಕಾಗಿ ತಮ್ಮ ಕುಟುಂಬದ ಹೊರೆಯನ್ನು ಕಡಿಮೆ ಮಾಡಲು ಅರೆಕಾಲಿಕ ಉದ್ಯೋಗಗಳನ್ನು ಕೈಗೊಂಡಿರುತ್ತಾರೆ.",
              "te": "కానీ, వారు మొదటి ప్రయత్నంలో క్లియర్ చేయలేకపోవటంతో, వారు తమ ఖర్చుల కోసం వారి కుటుంబంపై భారాన్ని తగ్గించడానికి పార్ట్ టైమ్ ఉద్యోగాలు చూసుకున్నారు.",
              "or": "ଅବଶ୍ୟ, ଯେତେବେଳେ ସେମାନେ ପ୍ରଥମ ପ୍ରୟାସରେ ପାସ କରିପାରିନଥିଲେ,\n  ସେମାନେ ନିଜ ପରିବାରର ଖର୍ଚ୍ଚକୁ ହ୍ରାସ କରିବା ପାଇଁ ପାର୍ଟ ଟାଇମ୍ ଚାକିରି କରିଥିଲେ |",
              "as": "অৱশ্যে, যেতিয়া তেওঁলোকে প্ৰথম প্ৰচেষ্টাত পাচ কৰিব নোৱাৰিলে, তেওঁলোকে তেওঁলোকৰ পৰিয়ালৰ ব্যয়ৰ বোজা হ্ৰাস কৰিবলৈ আংশিক সময়ৰ চাকৰি কৰিছিল।",
              "gu": "જો કે, જ્યારે તેઓ પ્રથમ પ્રયાસમાં ક્લિયર ન થઈ શક્યા, ત્યારે તેઓએ તેમના ખર્ચ માટે તેમના પરિવારનો બોજ ઘટાડવા માટે બાજુ પર અડધો-ટાઇમ નોકરીઓ લીધી."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_2_4",
              "hi": "X5_2_4",
              "ka": "X5_2_4",
              "te": "X5_2_4",
              "or": "X5_2_4",
              "as": "X5_2_4",
              "gu": "X5_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav goes back to his room that day and discusses their finances with Kuldeep.",
              "hi": "माधव उस दिन अपने कमरे में वापस चला जाता है और कुलदीप के साथ अपने वित्तीय स्थिति पर चर्चा करता है।",
              "ka": "ಮಾಧವ್ ಆ ದಿನ ತನ್ನ ಕೋಣೆಗೆ ಹಿಂತಿರುಗಿ ಕುಲದೀಪ್ ಜೊತೆ ಅವರ ಹಣಕಾಸಿನ ಸ್ಥಿತಿಯ ಬಗ್ಗೆ ಚರ್ಚಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్ ఆ రోజు తన గదికి తిరిగి వెళ్లి కుల్దీప్‌తో వారి ఆర్థిక విషయాల గురించి చర్చిస్తాడు.",
              "or": "ମାଧବ ସେଦିନ ନିଜ କୋଠରୀକୁ ଯାଇ କୁଲଦୀପଙ୍କ ସହିତ ସେମାନଙ୍କ \n ଆର୍ଥିକ ବିଷୟରେ ଆଲୋଚନା କରନ୍ତି |",
              "as": "মাধৱে সেইদিনা তেওঁৰ কোঠালৈ উভতি গৈছিল আৰু কুলদীপৰ সৈতে তেওঁলোকৰ বিত্তীয় অৱস্থাৰ আলোচনা কৰিছিল।",
              "gu": "તે દિવસે માધવ તેના રૂમમાં પાછો જાય છે અને કુલદીપ સાથે તેમની આર્થિક બાબતો ચર્ચા કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_2_5//",
              "hi": "X5_2_5 //",
              "ka": "X5_2_5//",
              "te": "X5_2_5//",
              "or": "X5_2_5//",
              "as": "X5_2_5//",
              "gu": "X5_2_5//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୂଲଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Without the scholarship money, I don't know how I will manage.",
              "hi": "छात्रवृत्ति के पैसे के बिना, मुझे नहीं पता कि मैं कैसे व्यवस्था करूंगा।",
              "ka": "ವಿದ್ಯಾರ್ಥಿವೇತನದ ಹಣವಿಲ್ಲದೆ, ನಾನು ಹೇಗೆ ನಿರ್ವಹಿಸಬಲ್ಲೆ ಎನ್ನುವುದು ನನಗೆ ತಿಳಿದಿಲ್ಲ.",
              "te": "స్కాలర్‌షిప్ డబ్బు లేకుండా, ఎలా మేనేజ్ చేయాలో నాకు తెలియదు.",
              "or": "ଛାତ୍ରବୃତ୍ତି ଟଙ୍କା ବିନା, ମୁଁ କିପରି ପରିଚାଳନା କରିବି ଜାଣି ନାହିଁ |",
              "as": "বৃত্তিৰ ধন অবিহনে, মই নাজানো মই কেনেদৰে চলিম।",
              "gu": "શિષ્યવૃત્તિના પૈસા વિના, મને ખબર નથી કે હું કેવી રીતે સંભાળીશ "
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "You told your baba?",
              "hi": "तुमने अपने बाबा को बताया?",
              "ka": "ನಿಮ್ಮ ಬಾಬಾನಿಗೆ ಇದರ ಬಗ್ಗೆ ತಿಳಿಸಿದ್ದೀಯಾ?",
              "te": "నువ్వు మీ బాబాకి చెప్పావా?",
              "or": "ତୁମେ ତୁମ ବାବାଙ୍କୁ କହିଲ କି?",
              "as": "তুমি তোমাৰ বাবাক কৈছিলানে?",
              "gu": "તમે તમારા પિતાને કહ્યું?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Umm... No. I don't want to ask him. He manages himself, that's enough. And honestly, I don't think he cares.",
              "hi": "उम्म ... नहीं, मैं उनसे पूछना नहीं चाहता। वह अपने लिए स्वयं व्यवस्था करते हैं, यह पर्याप्त है। और ईमानदारी से, मुझे नहीं लगता कि वह परवाह करते हैं।",
              "ka": "ಉಹುಂ... ಇಲ್ಲ ನಾನು ಅವರನ್ನು ಕೇಳಲು ಬಯಸುವುದಿಲ್ಲ. ಅವರು ತಮ್ಮನ್ನು ತಾವೇ ನಿರ್ವಹಿಸುತ್ತಾರೆ, ಅದು ಸಾಕು. ಮತ್ತು ಪ್ರಾಮಾಣಿಕವಾಗಿ, ಅವರು ಕಾಳಜಿ ವಹಿಸುತ್ತಾನೆ ಎಂದು ನಾನು ಭಾವಿಸುವುದಿಲ್ಲ.",
              "te": "అమ్మో.. లేదు.. నేను అతనిని అడగాలనుకోవట్లేదు. తనను తాను మేనేజ్ చేసుకుంటాడు, అది సరిపోతుంది. మరియు నిజానికి, అతను పట్టించుకుంటాడని నేను అనుకోను.",
              "or": "ଉଁ ... ନା। ମୁଁ ତାଙ୍କୁ ପଚାରିବାକୁ ଚାହେଁ ନାହିଁ | ସେ ନିଜକୁ ପରିଚାଳନା କରନ୍ତି,\n  ତାହା ଯଥେଷ୍ଟ | ଏବଂ ସତ କହିବାକୁ ଗଲେ, ମୁଁ ଭାବୁନାହିଁ ଯେ ସେ ଧ୍ୟାନ ଦିଅନ୍ତି |",
              "as": "উমম... নাই। মই তেওঁক সুধিব নিবিচাৰোঁ। তেওঁ নিজেই চলায়, ই যথেষ্ট। আৰু সঁচাকৈয়ে, মই নাভাবো যে তেওঁ খাতিৰ কৰে।",
              "gu": "અમ્મ... ના. હું તેમને પૂછવા માંગતો નથી. તેઓ પોતાની જાતને સંભાળે છે, તે પૂરતું છે. અને પ્રામાણિકપણે, મને નથી લાગતું કે તે કાળજી લે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_2_6////",
              "hi": "X5_2_6 /////",
              "ka": "X5_2_6////",
              "te": "X5_2_6////",
              "or": "X5_2_6////",
              "as": "X5_2_6////",
              "gu": "X5_2_6////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "You don't take tension! I have asked Ma and baba to provide pocket money for another year. We will split that! Stay in the same room, eat from the same plate and study hard!!",
              "hi": "तुम तनाव मत लो! मैंने मां और बाबा से एक और वर्ष के लिए जेब खर्च देने के लिए कहा है। हम उसे बाँट लेंगे! एक ही कमरे में रहें, एक ही प्लेट से खाएं और मेहनत से अध्ययन करें !!",
              "ka": "ನೀನು ಟೆನ್ಶನ್ ತೆಗೆದುಕೊಳ್ಳಬೇಡ! ಇನ್ನೊಂದು ವರ್ಷಕ್ಕೆ ಪಾಕೆಟ್ ಮನಿ ನೀಡುವಂತೆ ಮಾ ಮತ್ತು ಬಾಬಾರನ್ನು ಕೇಳಿದ್ದೇನೆ. ನಾವು ಅದನ್ನು ವಿಭಜಿಸಿಕೊಳ್ಳೋಣ! ಒಂದೇ ರೂಮಿನಲ್ಲಿ ಇದ್ದು, ಒಂದೇ ತಟ್ಟೆಯಲ್ಲಿ ಊಟ ಮಾಡಿ ಕಷ್ಟಪಟ್ಟು ಓದು!!",
              "te": "నువ్వు టెన్షన్ పడకు! ఇంకో సంవత్సరం పాకెట్ మనీ ఇవ్వమని మా అమ్మానాన్నలను అడిగాను. మనము దానిని పంచుకుందాం! ఒకే గదిలో ఉండి, ఒకే ప్లేట్‌లో తిని కష్టపడి చదువుకుందాం!!",
              "or": "ତୁମେ ଟେନସନ ନିଅ ନାହିଁ! ମୁଁ ମା ଏବଂ ବାବାଙ୍କୁ ଆଉ ଏକ ବର୍ଷ ପାଇଁ \n ପକେଟ ଟଙ୍କା ଯୋଗାଇବାକୁ କହିଛି | ଆମେ ଏହାକୁ ବିଭାଜନ କରିବୁ! ସମାନ କୋଠରୀରେ ରୁହ, ସମାନ ପ୍ଲେଟରୁ ଖାଅ ଏବଂ କଠିନ ଅଧ୍ୟୟନ କର !!",
              "as": "তুমি টেনচন নল’বা! মই মা আৰু বাবাক আৰু এবছৰৰ বাবে পকেট-মানি যোগান ধৰিবলৈ কৈছোঁ। আমি সেইটো ভাগ কৰি ল’ম! একেটা কোঠাতে থাকিম, একেখন প্লেটৰ পৰা খাম আৰু কঠোৰ অধ্যয়ন কৰিম!!",
              "gu": "તું ટેન્શન ન લે! મેં મા અને પિતાને બીજા એક વર્ષ માટે હાથ ખર્ચાના પૈસા આપવાનું કહ્યું છે. આપણે તેને અડધા કરી લઈશું! એક જ રૂમમાં રહીશું કરીશું  એક જ થાળીમાંથી ખાઈશું  અને સખત અભ્યાસ કરીશું!!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Thanks Kuldeep, but it might just be better if I take up a job somewhere.",
              "hi": "धन्यवाद कुलदीप, लेकिन अगर मैं कहीं नौकरी करता हूं तो यह बेहतर हो सकता है।  ",
              "ka": "ಧನ್ಯವಾದಗಳು ಕುಲದೀಪ್, ಆದರೆ ನಾನು ಎಲ್ಲೋ ಒಂದು ಕೆಲಸವನ್ನು ಪಡೆದಲ್ಲಿ ಅದು ಉತ್ತಮವಾಗಿರುತ್ತದೆ.",
              "te": "ధన్యవాదాలు కుల్దీప్, కానీ నేను ఎక్కడైనా ఉద్యోగంలో చేరితే ఇంకా బాగుంటుంది అనుకుంటా.",
              "or": "ଧନ୍ୟବାଦ କୁଲଦୀପ, କିନ୍ତୁ ଯଦି ମୁଁ କୌଣସି ସ୍ଥାନରେ ଚାକିରି ନେବି ତେବେ \n ଏହା ଭଲ ହୋଇପାରେ |",
              "as": "ধন্যবাদ কুলদীপ, কিন্তু মই কৰবাত চাকৰি এটা ল'লে বেছি ভাল হ'ব।",
              "gu": "આભાર કુલદીપ, પણ જો હું ક્યાંક નોકરી કરું તો સારું રહેશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "But Madhav taking up a job isn't easy. How will you make time to study?",
              "hi": "लेकिन माधव नौकरी करना आसान नहीं है। तुम अध्ययन करने के लिए समय कैसे निकाल पाओगे?",
              "ka": "ಆದರೆ ಮಾಧವ್ ಕೆಲಸ ಮಾಡುವುದು ಸುಲಭವಲ್ಲ. ನೀನು ಅಧ್ಯಯನ ಮಾಡಲು ಸಮಯವನ್ನು ಹೇಗೆ ಮಾಡಿಕೊಳ್ಳುತ್ತೀಯಾ?",
              "te": "కానీ మాధవ్ ఉద్యోగం చేయడం అంత ఈజీ కాదు. నువ్వు చదువుకోవడానికి సమయాన్ని ఎలా వెచ్చిస్తావు?",
              "or": "କିନ୍ତୁ ମାଧବ ଚାକିରି ନେବା ସହଜ ନୁହେଁ | ଆପଣ କିପରି ଅଧ୍ୟୟନ ପାଇଂ \n  ସମୟ ଦେବେ?",
              "as": "কিন্তু মাধৱ চাকৰি এটা লোৱাটো সহজ নহয়। তুমি অধ্যয়ন কৰিবলৈ কেনেদৰে সময় উলিয়াবা?",
              "gu": "પરંતુ માધવને નોકરી કરવી સરળ નથી. તું અભ્યાસ માટે કેવી રીતે સમય કાઢીશ?"
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_2_6_D",
              "hi": "X5_2_6_d",
              "ka": "X5_2_6_D",
              "te": "X5_2_6_D",
              "or": "X5_2_6_D",
              "as": "X5_2_6_D",
              "gu": "X5_2_6_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Madhav do?",
              "hi": "माधव को क्या करना चाहिए?",
              "ka": "ಮಾಧವ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "మాధవ్ ఏం చేయాలి?",
              "or": "ମାଧବ କ’ଣ କରିବା ଉଚିତ୍?",
              "as": "মাধৱে কি কৰা উচিত?",
              "gu": "માધવે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Focus only on studies",
              "hi": "केवल अध्ययन पर ध्यान केंद्रित करे",
              "ka": "ಅಧ್ಯಯನದ ಮೇಲೆ ಮಾತ್ರವೇ ಗಮನ ಹರಿಸುವುದೇ",
              "te": "చదువుపై మాత్రమే దృష్టి పెట్టాలి",
              "or": "କେବଳ ଅଧ୍ୟୟନ ଉପରେ ଧ୍ୟାନ ଦିଅନ୍ତୁ |",
              "as": "কেৱল অধ্যয়নত মনোযোগ দিয়ক",
              "gu": "માત્ર અભ્યાસ પર ધ્યાન આપો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Part-time job and studies",
              "hi": "अंशकालिक नौकरी और अध्ययन",
              "ka": "ಅರೆಕಾಲಿಕ ಕೆಲಸ ಮತ್ತು ಅಧ್ಯಯನ",
              "te": "పార్ట్ టైమ్ ఉద్యోగం మరియు చదువు",
              "or": "ପାର୍ଟ ଟାଇମ୍ ଚାକିରି ଏବଂ ଅଧ୍ୟୟନ |",
              "as": "আংশিক সময়ৰ চাকৰি আৰু অধ্যয়ন",
              "gu": "અડધો સમય નોકરી અને અભ્યાસ"
            }
          }
        ]
      },
      "scene8": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(i)_1_1",
              "hi": "X6 (i) _1_1",
              "ka": "X6(i)_1_1",
              "te": "X6(i)_1_1",
              "or": "X6(i)_1_1",
              "as": "X6(i)_1_1",
              "gu": "X6(i)_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to focus on the UPSC preparation without taking up a job. Based on their research Madhav and Kuldeep make a study plan.",
              "hi": "माधव ने नौकरी किए बिना यूपीएससी तैयारी पर ध्यान केंद्रित करने का निश्चय किया। अपनी शोध के आधार पर माधव और कुलदीप  एक अध्ययन योजना बनाते हैं।",
              "ka": "ಮಾಧವ್ ಉದ್ಯೋಗವನ್ನು ತೆಗೆದುಕೊಳ್ಳದೆ UPSC ತಯಾರಿಯತ್ತ ಗಮನ ಹರಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಅವರ ಸಂಶೋಧನೆಯ ಆಧಾರದ ಮೇಲೆ ಮಾಧವ್ ಮತ್ತು ಕುಲದೀಪ್ ಅಧ್ಯಯನ ಯೋಜನೆಯನ್ನು ರೂಪಿಸುತ್ತಾರೆ.",
              "te": "మాధవ్ ఉద్యోగం చేయకుండా UPSC ప్రిపరేషన్‌పై దృష్టి పెట్టాలని నిర్ణయించుకున్నాడు. వారి రీసర్చ్ ఆధారంగా మాధవ్ మరియు కుల్దీప్ ఒక అధ్యయన ప్రణాళికను రూపొందించారు.",
              "or": "ଚାକିରି ନକରି UPSC ପ୍ରସ୍ତୁତି ଉପରେ ଧ୍ୟାନ ଦେବାକୁ ମାଧବ ନିଷ୍ପତ୍ତି\n  ନେଇଛନ୍ତି | ସେମାନଙ୍କର ଅନୁସନ୍ଧାନ ଉପରେ ଆଧାର କରି ମାଧବ ଏବଂ କୁଲଦୀପ ଏକ ଅଧ୍ୟୟନ ଯୋଜନା ପ୍ରସ୍ତୁତ କରନ୍ତି |",
              "as": "মাধৱে চাকৰি নোলোৱাকৈ UPSCৰ প্ৰস্তুতিত মনোনিবেশ কৰাৰ সিদ্ধান্ত লয়। তেওঁলোকৰ গৱেষণাৰ ওপৰত ভিত্তি কৰি মাধৱ আৰু কুলদীপে এটা অধ্যয়ন পৰিকল্পনা প্ৰস্তুত কৰে।",
              "gu": "માધવે નોકરી લીધા વિના UPSC ની તૈયારી પર ધ્યાન કેન્દ્રિત કરવાનું નક્કી કર્યું. તેમના સંશોધનના આધારે માધવ અને કુલદીપ અભ્યાસ માટે યોજના બનાવે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(i)_1_2",
              "hi": "X6 (i) _1_2",
              "ka": "X6(i)_1_2",
              "te": "X6(i)_1_2",
              "or": "X6(i)_1_2",
              "as": "X6(i)_1_2",
              "gu": "X6(i)_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They both have a hard time managing their expenses. Most of the money goes into buying books. They study all day and don't get time to do anything else.",
              "hi": "उन दोनों को अपने खर्च की व्यवस्था करने में कठिनाई होती है। अधिकांश पैसे किताबें खरीदने में खर्च हो जाते हैं। वे पूरे दिन अध्ययन करते हैं और उन्हें कुछ और करने के लिए समय नहीं मिलता है।",
              "ka": "ಇಬ್ಬರೂ ತಮ್ಮ ಖರ್ಚುಗಳನ್ನು ನಿಭಾಯಿಸಲು ಕಷ್ಟಪಡುತ್ತಾರೆ. ಹೆಚ್ಚಿನ ಹಣ ಪುಸ್ತಕಗಳನ್ನು ಖರೀದಿಸಲು ಹೋಗುತ್ತದೆ. ಅವರು ದಿನವಿಡೀ ಅಧ್ಯಯನ ಮಾಡುತ್ತಾರೆ ಮತ್ತು ಬೇರೆ ಏನನ್ನೂ ಮಾಡಲು ಸಮಯ ಸಿಗುವುದಿಲ್ಲ.",
              "te": "ఇద్దరూ తమ ఖర్చులను మేనేజ్ చేయడం చాలా కష్టం అయింది. ఎక్కువ డబ్బు పుస్తకాలు కొనడానికే సరిపోతుంది. వారు రోజంతా చదువుకుంటారు మరియు వేరే పని చేయడానికి సమయం దొరకదు.",
              "or": "ଉଭୟଙ୍କୁ ସେମାନଙ୍କର ଖର୍ଚ୍ଚ ପରିଚାଳନା କରିବାରେ କଷ୍ଟକର | ଅଧିକାଂଶ \n ଟଙ୍କା ବହି କିଣିବାକୁ ଯାଇଥାଏ | ସେମାନେ ଦିନସାରା ଅଧ୍ୟୟନ କରନ୍ତି ଏବଂ ଅନ୍ୟ କିଛି କରିବାକୁ ସମୟ ପାଆନ୍ତି ନାହିଁ |",
              "as": "তেওঁলোক দুয়োয়ে খৰচ পৰিচালনা কৰিবলৈ কঠিন সময় পাৰ কৰিছে। বেছিভাগ টকা কিতাপ কিনোতে খৰচ হৈছে। তেওঁলোকে গোটেই দিনটো অধ্যয়ন কৰে আৰু বেলেগ একো কৰিবলৈ সময় নাপায়।",
              "gu": "તેઓ બંનેને તેમના ખર્ચાઓનું સંચાલન કરવું મુશ્કેલ છે. મોટા ભાગના પૈસા પુસ્તકો ખરીદવામાં જાય છે. તેઓ આખો દિવસ અભ્યાસ કરે છે અને બીજું કંઈ કરવા માટે સમય મળતો નથી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(i)_1_3",
              "hi": "X6 (i) _1_3",
              "ka": "X6(i)_1_3",
              "te": "X6(i)_1_3",
              "or": "X6(i)_1_3",
              "as": "X6(i)_1_3",
              "gu": "X6(i)_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Sometimes Badri gets them snacks that they eat as dinner. Their health gets affected because of their erratic lifestyle.",
              "hi": "कभी-कभी बद्री उनके लिए कुछ खाने को लेकर आता है जिसे वे रात के खाने के रूप में खाते हैं। उनकी अनियमित जीवनशैली के कारण उनका स्वास्थ्य प्रभावित हो जाता है।",
              "ka": "ಕೆಲವೊಮ್ಮೆ ಬದರಿಯು ಅವರಿಗೆ ತಿಂಡಿಗಳನ್ನು ತಿನ್ನಲು ತರುತ್ತಾನೆ, ಅದು ಅವರಿಗೆ ಊಟವಾಗುತ್ತದೆ. ಅವರ ಅಸ್ಥಿರ ಜೀವನಶೈಲಿಯಿಂದಾಗಿ ಅವರ ಆರೋಗ್ಯದ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುತ್ತದೆ.",
              "te": "కొన్నిసార్లు బద్రి వారికి స్నాక్స్ ఇస్తే, వాటినే డిన్నర్ కి తినేవారు. వారి అస్థిర జీవనశైలి కారణంగా వారి ఆరోగ్యం దెబ్బతింటుంది.",
              "or": "ବେଳେବେଳେ ବଦ୍ରି ସେମାନଙ୍କୁ ସ୍ନାକ୍ସ ଦିଅନ୍ତି ଯାହାକୁ ସେମାନେ ରାତ୍ରୀ\n  ଭୋଜନ କରନ୍ତି | ସେମାନଙ୍କର ଭୁଲ ଜୀବନସାଇଳୀ ହେତୁ ସେମାନଙ୍କର ସ୍ୱାସ୍ଥ୍ୟ ପ୍ରଭାବିତ ହୁଏ |",
              "as": "কেতিয়াবা বদ্ৰীয়ে তেওঁলোকক জলপান দিয়ে তেওঁলোকে সেয়া ৰাতিৰ আহাৰ হিচাপে খায়। তেওঁলোকৰ অনিয়মীয়া জীৱনশৈলীৰ বাবে তেওঁলোকৰ স্বাস্থ্যত প্ৰভাৱ পৰিছে।",
              "gu": "કેટલીકવાર બદ્રી તેમને નાસ્તો આપે છે જે તેઓ રાત્રિભોજન તરીકે ખાય છે. તેમની અનિયમિત જીવનશૈલીને કારણે તેમના સ્વાસ્થ્યને અસર થાય છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(i)_1_4//",
              "hi": "X6 (i) _1_4 //",
              "ka": "X6(i)_1_4//",
              "te": "X6(i)_1_4//",
              "or": "X6(i)_1_4//",
              "as": "X6(i)_1_4//",
              "gu": "X6(i)_1_4//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Badri",
              "hi": "बद्री",
              "ka": "ಬದ್ರಿ",
              "te": "బద్రి",
              "or": "ବଦ୍ରି",
              "as": "বদ্ৰী",
              "gu": "બદ્રી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୂଲଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Kuldeep and I are going home next week for Holi.",
              "hi": "कुलदीप और मैं होली के लिए अगले सप्ताह  घर जा रहे हैं।",
              "ka": "ಕುಲದೀಪ್ ಮತ್ತು ನಾನು ಮುಂದಿನ ವಾರ ಹೋಳಿ ಹಬ್ಬಕ್ಕಾಗಿ ಮನೆಗೆ ಹೋಗುತ್ತಿದ್ದೇವೆ.",
              "te": "కుల్దీప్ మరియు నేను వచ్చే వారం హోలీకి ఇంటికి వెళ్తున్నాము.",
              "or": "ଆସନ୍ତା ସପ୍ତାହରେ ହୋଲି ପାଇଁ କୁଲଦୀପ ଏବଂ ମୁଁ ଘରକୁ ଯାଉଛୁ |",
              "as": "কুলদীপ আৰু মই অহা সপ্তাহত হোলীৰ বাবে ঘৰলৈ যাম।",
              "gu": "હું અને કુલદીપ આવતા અઠવાડિયે હોળી માટે ઘરે જઈ રહ્યા છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I don't think I'll go. I have to study!",
              "hi": "मुझे नहीं लगता कि मैं जाऊंगा। मुझे पढाई करनी है!",
              "ka": "ನಾನು ಹೋಗುತ್ತೇನೆ ಎಂದು ನಾನು ಭಾವಿಸುವುದಿಲ್ಲ. ನಾನು ಅಧ್ಯಯನ ಮಾಡಬೇಕು!",
              "te": "నేను వెళ్లాలని అనుకోవట్లేదు. నేను చదవాలి!",
              "or": "ମୁଁ ଭାବୁଛି ମୁଁ ଯିବି ନାହି | ମୋତେ ଅଧ୍ୟୟନ କରିବାକୁ ପଡିବ!",
              "as": "মই নাযাম চাগে। মই পঢ়িব লাগিব!",
              "gu": "મને નથી લાગતું કે હું જઈશ. મારે ભણવું છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "You can study once you come back. We're going home for just three days. Madhav, you've not been home in 3 years.",
              "hi": "एक बार वापस आने के बाद तुम अध्ययन कर सकते हो। हम सिर्फ तीन दिनों के लिए घर जा रहे हैं। माधव, तुम 3 साल से घर नहीं गए हो ।",
              "ka": "ನೀನು ಹಿಂತಿರುಗಿದ ನಂತರವೂ ಅಧ್ಯಯನ ಮಾಡಬಹುದು. ನಾವು ಕೇವಲ ಮೂರು ದಿನಗಳವರೆಗೆ ಮನೆಗೆ ಹೋಗುತ್ತೇವೆ. ಮಾಧವ್, ನೀನು ಮೂರು ವರ್ಷಗಳಿಂದ ಮನೆಗೆ ಹೋಗಿಲ್ಲ",
              "te": "నువ్వు తిరిగి వచ్చిన తర్వాత చదువుకోవచ్చు. మూడు రోజులకే ఇంటికి వెళ్తున్నాం. మాధవ్, నువ్వు 3 సంవత్సరాల నుండి ఇంటికి రాలేదు.",
              "or": "ଆପଣ ଥରେ ଆସିବା ପରେ ଆପଣ ଅଧ୍ୟୟନ କରିପାରିବେ | ଆମେ ମାତ୍ର\n  ତିନି ଦିନ ପାଇଁ ଘରକୁ ଯାଉଛୁ | ମାଧବ, ଆପଣ 3 ବର୍ଷ ହେଲାଣି ଘରକୁ ଯାଇ ନାହାଁନ୍ତି |",
              "as": "তুমি ঘূৰি অহাৰ পিছত অধ্যয়ন কৰিব পাৰিবা। আমি মাত্ৰ তিনি দিনৰ বাবেহে ঘৰলৈ গৈ আছোঁ। মাধৱ, তুমি ৩বছৰে ঘৰলৈ যোৱা নাই।",
              "gu": "એકવાર તું પાછો આવે ત્યારે તું અભ્યાસ કરી શકીશ. અમે ફક્ત ત્રણ દિવસ માટે ઘરે જઈ રહ્યા છીએ. માધવ, તું ત્રણ વર્ષથી ઘરે નથી આવ્યો."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(i)_1_5////",
              "hi": "X6 (i) _1_5 /////////",
              "ka": "X6(i)_1_5////",
              "te": "X6(i)_1_5////",
              "or": "X6(i)_1_5////",
              "as": "X6(i)_1_5////",
              "gu": "X6(i)_1_5////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "It's fine! I don't have anyone at home to go to. You know that. If I go, my dad will only ask me to get a job and send him money.",
              "hi": "यह ठीक है! मेरे पास जाने के लिए घर पर कोई नहीं है। तुम जानते हो कि अगर मैं जाता हूं, तो मेरे पिताजी केवल मुझसे नौकरी करने और उन्हें पैसे भेजने के लिए कहेंगे।",
              "ka": "ಪರವಾಗಿಲ್ಲ!  ಮನೆಗೆ ಹೋಗಲು ಅಲ್ಲಿ ಯಾರೂ ಇಲ್ಲ. ಅದು ನಿಮಗೆ ಗೊತ್ತು. ನಾನು ಹೋದರೆ, ನನ್ನ ತಂದೆ ನನಗೆ ಕೆಲಸ ಪಡೆದುಕೊ ಮತ್ತು  ಹಣ ಕಳುಹಿಸಲು ಮಾತ್ರ ಕೇಳುತ್ತಾರೆ.",
              "te": "పర్లేదు! నేను వెళ్ళడానికి ఇంట్లో ఎవరూ లేరు. అది నీకు తెలుసు. నేను వెళ్తే, మా నాన్న నన్ను ఉద్యోగం ఇప్పించమని, డబ్బు పంపమని మాత్రమే అడుగుతాడు.",
              "or": "ଏହା ଠିକ୍ ଅଛି! ଘରକୁ ଯିବାକୁ ମୋର କେହି ନାହିଁ | ଆପଣ ଏହା ଜାଣିଛନ୍ତି |\n  ଯଦି ମୁଁ ଯାଏ, ମୋ ବାପା ମୋତେ କେବଳ ଚାକିରି ପାଇବାକୁ ଏବଂ ଟଙ୍କା ପଠାଇବାକୁ କହିବେ |",
              "as": "হ’ব দিয়া! মোৰ ঘৰত কোনো নাই যাবলৈ। তুমি সেইটো জানা। যদি মই যাওঁ, মোৰ দেউতাই মোক কেৱল চাকৰি এটা কৰিবলৈ আৰু টকা পঠিয়াবলৈ ক'ব।",
              "gu": "તે બરાબર છે! મારે ઘરે કોઈ નથી જવા માટે. તું જાણે છે. જો હું જાઉં તો મારા પપ્પા જ મને નોકરી સોધવા અને પૈસા મોકલવાનું કહેશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Why don't you come with me? You can stay at my place.",
              "hi": "तुम मेरे साथ क्यों नहीं आते? तुम मेरे घर पर रह सकते हो।",
              "ka": "ನೀನು ನನ್ನ ಜೊತೆ ಏಕೆ ಬರಬಾರದು? ನೀನು ನನ್ನ ಜಾಗದಲ್ಲಿಯೇ ಉಳಿಯಬಹುದು.",
              "te": "నువ్వు నాతో ఎందుకు రాకూడదు? నువ్వు మా ఇంట్లో ఉండవచ్చు కదా..",
              "or": "ତୁମେ ମୋ ସହିତ କାହିଁକି ଆସୁନାହଁ? ତୁମେ ମୋ ସ୍ଥାନରେ ରହିପାରିବ |",
              "as": "তুমি মোৰ সৈতে কিয় নাহা? তুমি মোৰ ঘৰত থাকিব পাৰা।",
              "gu": "તું મારી સાથે કેમ નથી આવતો? તું મારી પાસે રહી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "It's okay Kuldeep, I'm fine here by myself.",
              "hi": "कोई बात नहीं कुलदीप, मैं यहाँ अपने आप से ठीक हूँ।",
              "ka": "ಪರವಾಗಿಲ್ಲ ಕುಲದೀಪ್, ನಾನೊಬ್ಬನೇ ಇಲ್ಲಿ ಚೆನ್ನಾಗಿದ್ದೇನೆ",
              "te": "పర్వాలేదు కుల్దీప్, నేను ఇక్కడ ఒంటరిగా బానే ఉంటాను.",
              "or": "ଠିକ ଅଛି କୁଲଦୀପ, ମୁଁ ନିଜେ ଏଠାରେ ଭଲ ଅଛି |",
              "as": "হ’ব কুলদীপ, মই ইয়াতে ভালে থাকিম।",
              "gu": "ઠીક છે કુલદીપ, હું અહીં જ ઠીક છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Nonsense! We're in this together, Madhav. If you don't come with me, I won't go either.",
              "hi": "बकवास! हम इसमें एक साथ हैं, माधव। यदि तुम मेरे साथ नहीं आओगे, तो मैं भी नहीं जाऊंगा।",
              "ka": "ನಾನ್ಸೆನ್ಸ್! ನಾವಿಬ್ಬರೂ ಜೊತೆಗಿದ್ದೇವೆ, ಮಾಧವ್. ನೀನು ನನ್ನ ಜೊತೆ ಬರದಿದ್ದರೆ ನಾನೂ ಹೋಗುವುದಿಲ್ಲ.",
              "te": "నాన్సెన్స్! ఇది మనం కలిసి చేస్తున్నాం మాధవ్. నువ్వు నాతో రాకపోతే నేనూ వెళ్ళను.",
              "or": "ନିର୍ବୋଧ! ଆମେ ଏକାଠି, ମାଧବ | ଯଦି ତୁମେ ମୋ ସହିତ ଆସିବ ନାହିଁ\n , ମୁଁ ମଧ୍ୟ ଯିବି ନାହିଁ |",
              "as": "বাজে কথা! আমি একেলগে আছোঁ, মাধৱ। যদি তুমি মোৰ সৈতে নাহা, মইও নাযাওঁ।",
              "gu": "બકવાસ! આપણે આમાં સાથે છીએ, માધવ. જો તું મારી સાથે નહીં આવે, તો હું પણ નહીં જાઉં."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(i)_2_1",
              "hi": "X6 (i) _2_1",
              "ka": "X6(i)_2_1",
              "te": "X6(i)_2_1",
              "or": "X6(i)_2_1",
              "as": "X6(i)_2_1",
              "gu": "X6(i)_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to go with Kuldeep to celebrate Holi. Kuldeep lives in a town near Delhi. Both of them have a great time celebrating Holi with Kuldeep's cousins.",
              "hi": "माधव ने होली मनाने के लिए कुलदीप के साथ जाने का निर्णय किया। कुलदीप दिल्ली के पास एक शहर में रहता है। उन दोनों के पास कुलदीप के चचेरे भाई के साथ होली का जश्न मनाने का एक अच्छा समय है।",
              "ka": "ಮಾಧವ್ ಹೋಳಿ ಆಚರಿಸಲು ಕುಲದೀಪ್ ಜೊತೆ ಹೋಗಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಕುಲದೀಪ್ ದೆಹಲಿ ಸಮೀಪದ ಪಟ್ಟಣದಲ್ಲಿ ವಾಸಿಸುತ್ತಿದ್ದಾನೆ. ಇಬ್ಬರೂ ಕುಲದೀಪ್ ಅವರ ಸೋದರಸಂಬಂಧಿಗಳೊಂದಿಗೆ ಹೋಳಿ ಆಚರಿಸುವ ಸಮಯದಲ್ಲಿ ಅತ್ಯಂತ .ಸಂಭ್ರಮಿಸುತ್ತಾರೆ.",
              "te": "మాధవ్ హోలీ జరుపుకోవడానికి కుల్దీప్‌తో కలిసి వెళ్లాలని నిర్ణయించుకున్నాడు. కుల్దీప్ ఢిల్లీ సమీపంలోని ఓ పట్టణంలో నివసిస్తున్నాడు. వారిద్దరూ కుల్దీప్ కజిన్స్ తో కలిసి హోలీని బాగా జరుపుకుంటారు.",
              "or": "ହୋଲି ପାଳନ କରିବାକୁ କୁଲଦୀପଙ୍କ ସହ ଯିବାକୁ ମାଧବ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |\n  କୁଲଦୀପ ଦିଲ୍ଲୀ ନିକଟ ଏକ ସହରରେ ରୁହନ୍ତି। କୁଲଦୀପଙ୍କ ସମ୍ପର୍କୀୟମାନଙ୍କ ସହ ହୋଲି ପାଳନ କରିବାରେ ଉଭୟଙ୍କର ବହୁତ ଭଲ ସମୟ ଅଛି |",
              "as": "মাধৱে হোলী উদযাপন কৰিবলৈ কুলদীপৰ সৈতে যোৱাৰ সিদ্ধান্ত লয়। কুলদীপ দিল্লীৰ ওচৰৰ এখন চহৰত বাস কৰে। তেওঁলোক দুয়োজনে কুলদীপৰ সম্পৰ্কীয় ভাই-ভনীসকলৰ সৈতে ভালদৰে হোলী উদযাপন কৰে।",
              "gu": "માધવ હોળી ઉજવવા કુલદીપ સાથે જવાનું નક્કી કરે છે. કુલદીપ દિલ્હી પાસેના એક શહેરમાં રહે છે. તે બંનેએ કુલદીપના પિતરાઈ ભાઈઓ સાથે હોળીની ઉજવણીનો સરસ સમય પસાર કર્યો."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(i)_2_2",
              "hi": "X6 (i) _2_2",
              "ka": "X6(i)_2_2",
              "te": "X6(i)_2_2",
              "or": "X6(i)_2_2",
              "as": "X6(i)_2_2",
              "gu": "X6(i)_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They feel refreshed after their little break. Kuldeep's family is very fond of Madhav and look after him well. They ask him to visit them more often.",
              "hi": "वे अपने छोटे से अवकाश के बाद ताज़ा अनुभव करते हैं। कुलदीप का परिवार माधव को बहुत पसंद करता है और उसकी बहुत अच्छी तरह से देखभाल करता है। वे उससे अक्सर आने  और मिलने के लिए कहते हैं।",
              "ka": "ತಮ್ಮ ಪುಟ್ಟ ವಿರಾಮದ ನಂತರ ಅವರು ಹೊಸ ಚೈತನ್ಯವನ್ನು ಅನುಭವಿಸುತ್ತಾರೆ. ಕುಲದೀಪ್ ಕುಟುಂಬವು ಮಾಧವನನ್ನು ತುಂಬಾ ಇಷ್ಟಪಡುತ್ತದೆ ಮತ್ತು ಅವನನ್ನು ಚೆನ್ನಾಗಿ ನೋಡಿಕೊಳ್ಳುತ್ತದೆ. ಅವನನ್ನು ತಮ್ಮನ್ನು ಆಗಾಗ ಭೇಟಿ ಮಾಡಲು ಅವರು ಕೇಳಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "వారి చిన్న విరామం తర్వాత వారు రిఫ్రెష్‌ అయినట్టు భావిస్తారు. కుల్దీప్ కుటుంబానికి మాధవ్ బాగా నచ్చాడు మరియు అతన్ని బాగా చూసుకుంటారు. వారు తరచుగా తమ ఇంటికి రమ్మని అడుగుతారు.",
              "or": "ସେମାନଙ୍କର ଛୋଟ ବିରତି ପରେ ସେମାନେ ସତେଜ ଅନୁଭବ କରନ୍ତି |\n  କୁଲଦୀପଙ୍କ ପରିବାର ମାଧବଙ୍କୁ ବହୁତ ଭଲ ପାଆନ୍ତି ଏବଂ ତାଙ୍କୁ ଭଲ ଭାବରେ ଦେଖନ୍ତି | ସେମାନେ ତାଙ୍କୁ ବାରମ୍ବାର ଦେଖା କରିବାକୁ କୁହନ୍ତି |",
              "as": "সামান্য বিৰতিৰ পিছত তেওঁলোকে সতেজ অনুভৱ কৰে। কুলদীপৰ পৰিয়ালে মাধৱক বৰ ভাল পালে আৰু তাক ভালদৰে চোৱা-চিতা কৰে। তেওঁলোকে তাক সঘনাই তেওঁলোকৰ ওচৰলৈ আহিবলৈ কয়।",
              "gu": "તેઓ તેમના નાના વિરામ પછી તાજગી અનુભવે છે. કુલદીપનો પરિવાર માધવને ખૂબ પસંદ કરે છે અને તેની સારી રીતે સંભાળ રાખે છે. તેઓ તેને વધુ વખત મુલાકાત લેવા કહે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(i)_3_1",
              "hi": "X6 (i) _3_1",
              "ka": "X6(i)_3_1",
              "te": "X6(i)_3_1",
              "or": "X6(i)_3_1",
              "as": "X6(i)_3_1",
              "gu": "X6(i)_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After returning to Lucknow, Kuldeep and Madhav decide to relook at the plan. \nThey practice writing exams by attempting the previous year's question papers.",
              "hi": "लखनऊ लौटने के बाद, कुलदीप और माधव अपनी योजना पर पुनः विचार करने का निश्चय करते हैं।\nवे पिछले वर्ष के प्रश्न पत्रों को हल करके लिखित परीक्षा का अभ्यास करते हैं।",
              "ka": "ಲಕ್ನೋಗೆ ಹಿಂದಿರುಗಿದ ನಂತರ, ಕುಲದೀಪ್ ಮತ್ತು ಮಾಧವ್ ತಮ್ಮ ಓದಿನ ಬಗ್ಗೆ ಹಾಕೋಕೊಂಡಿರುವ ಯೋಜನೆಯನ್ನು ಮರುಪರಿಶೀಲಿಸಲು ನಿರ್ಧರಿಸಿದರು. ಅವರು ಹಿಂದಿನ ವರ್ಷದ ಪ್ರಶ್ನೆ ಪತ್ರಿಕೆಗಳನ್ನು ಪ್ರಯತ್ನಿಸುವ ಮೂಲಕ ಪರೀಕ್ಷೆಗಳನ್ನು ಬರೆಯಲು ಅಭ್ಯಾಸ ಮಾಡುತ್ತಾರೆ.",
              "te": "లక్నోకు తిరిగి వచ్చిన తర్వాత, కుల్దీప్ మరియు మాధవ్ ప్లాన్‌ని మళ్లీ చూడాలని నిర్ణయించుకున్నారు.\n \n వారు మునుపటి సంవత్సరం ప్రశ్నపత్రాలను ప్రయత్నించడం ద్వారా పరీక్షలు రాయడం సాధన చేస్తారు.",
              "or": "ଲକ୍ଷ୍ନ now କୁ ଫେରିବା ପରେ କୁଲଦୀପ ଏବଂ ମାଧବ ଏହି ଯୋଜନାକୁ ପୁନଃ ଅବଲମ୍ବନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି।\n ପୂର୍ବ ବର୍ଷର ପ୍ରଶ୍ନପତ୍ର ଚେଷ୍ଟା କରି ସେମାନେ ପରୀକ୍ଷା ଲେଖିବା ଅଭ୍ୟାସ କରନ୍ତି। \"",
              "as": "লক্ষ্ণৌলৈ উভতি অহাৰ পিছত, কুলদীপ আৰু মাধৱে পৰিকল্পনাটো পুনৰ চোৱাৰ সিদ্ধান্ত লয়। তেওঁলোকে আগৰ বছৰৰ প্ৰশ্নকাকতবোৰ সমাধান কৰি পৰীক্ষাত লিখাৰ অনুশীলন কৰে।",
              "gu": "લખનૌ પાછા ફર્યા પછી, કુલદીપ અને માધવ યોજના પર ફરીથી વિચાર કરવાનું નક્કી કરે છે. તેઓ પાછલા વર્ષના પ્રશ્નપત્રો અજમાવીને પરીક્ષા લખવાની પ્રેક્ટિસ કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(i)_3_2",
              "hi": "X6 (i) _3_2",
              "ka": "X6(i)_3_2",
              "te": "X6(i)_3_2",
              "or": "X6(i)_3_2",
              "as": "X6(i)_3_2",
              "gu": "X6(i)_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "With this plan in place, both are busy with their studies. Their day starts very early in the morning, and they study until 10 pm every night.",
              "hi": "इस योजना के साथ, दोनों अपने अपने अध्ययन में व्यस्त हैं। उनका दिन सुबह बहुत जल्दी शुरू होता है, और वे हर रोज़ रात 10 बजे तक अध्ययन करते हैं।",
              "ka": "ಈ ಪ್ರಯತ್ನವನ್ನು ಜಾರಿಯಲ್ಲಿಟ್ಟುಕೊಂಡು, ಇಬ್ಬರೂ ತಮ್ಮ ಅಧ್ಯಯನದಲ್ಲಿ ನಿರತರಾಗಿದ್ದಾರೆ. ಅವರ ದಿನವು ಬೆಳಿಗ್ಗೆ ಬೇಗನೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ ಮತ್ತು ಅವರು ಪ್ರತಿ ರಾತ್ರಿ 10 ಗಂಟೆಯವರೆಗೆ ಅಧ್ಯಯನ ಮಾಡುತ್ತಾರೆ.",
              "te": "ఈ ప్లాన్‌తో ఇద్దరూ తమ చదువుల్లో బిజీగా ఉన్నారు. వారి రోజు ఉదయం చాలా త్వరగా ప్రారంభమవుతుంది మరియు వారు ప్రతిరోజూ రాత్రి 10 గంటల వరకు చదువుతారు.",
              "or": "ଏହି ଯୋଜନା ସହିତ, ଉଭୟ ନିଜ ଅଧ୍ୟୟନରେ ବ୍ୟସ୍ତ ଅଛନ୍ତି | ସେମାନଙ୍କର\n  ଦିନ ସକାଳୁ ଅତି ଶୀଘ୍ର ଆରମ୍ଭ ହୁଏ, ଏବଂ ସେମାନେ ପ୍ରତ୍ୟେକ ରାତି 10 ଟା ପର୍ଯ୍ୟନ୍ତ ଅଧ୍ୟୟନ କରନ୍ତି |",
              "as": "এই পৰিকল্পনাৰ সৈতে, দুয়োয়ে তেওঁলোকৰ পঢ়াশুনাত ব্যস্ত হয়। তেওঁলোকৰ দিনটো ৰাতিপুৱা খুব সোনকালে আৰম্ভ হয়, আৰু তেওঁলোকে প্ৰতি নিশা ১০ বজালৈকে অধ্যয়ন কৰে।",
              "gu": "આ યોજના સાથે, બંને તેમના અભ્યાસમાં વ્યસ્ત છે. તેમનો દિવસ વહેલી સવારે શરૂ થાય છે અને તેઓ દરરોજ રાત્રે 10 વાગ્યા સુધી અભ્યાસ કરે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(i)_3_3",
              "hi": "X6 (i) _3_3",
              "ka": "X6(i)_3_3",
              "te": "X6(i)_3_3",
              "or": "X6(i)_3_3",
              "as": "X6(i)_3_3",
              "gu": "X6(i)_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After college, all three shift to a small room in Lucknow where Madhav and Kuldeep prepare for the UPSC exam, and Badri starts his B.Ed (Bachelor of Education).",
              "hi": "कॉलेज के बाद, लखनऊ में एक छोटे से कमरे में तीनों रहने लगे, जहां माधव और कुलदीप ने यूपीएससी परीक्षा के लिए तैयारी की, और बद्री ने अपने बीएड (बैचलर ऑफ एजुकेशन) शुरू कर दिया।",
              "ka": "ಕಾಲೇಜಿನ ನಂತರ, ಮೂವರೂ ಲಕ್ನೋದ ಒಂದು ಸಣ್ಣ ಕೋಣೆಗೆ ಸ್ಥಳಾಂತರಗೊಂಡರು, ಅಲ್ಲಿ ಮಾಧವ್ ಮತ್ತು ಕುಲದೀಪ್ UPSC ಪರೀಕ್ಷೆಗೆ ತಯಾರಾಗುತ್ತಾರೆ ಮತ್ತು ಬದ್ರಿ ತನ್ನ B.Ed (ಸ್ನಾತಕ ಶಿಕ್ಷಣ) ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ.",
              "te": "కాలేజీ తర్వాత, ముగ్గురూ లక్నోలోని ఒక చిన్న గదికి మారారు, అక్కడ మాధవ్ మరియు కుల్దీప్ UPSC పరీక్షకు సిద్ధమవుతారు మరియు బద్రి తన B.Ed (బ్యాచిలర్ ఆఫ్ ఎడ్యుకేషన్) ప్రారంభిస్తాడు.",
              "or": "କଲେଜ ପରେ, ଲକ୍ଷ୍ନୋ ର ଏକ ଛୋଟ କୋଠରୀକୁ ତିନିଯଣ ସ୍ଥାନାନ୍ତର ହୁଅନ୍ତି ,\n  ଯେଉଁଠାରେ ମାଧବ ଏବଂ କୁଲଦୀପ UPSC ପରୀକ୍ଷା ପାଇଁ ପ୍ରସ୍ତୁତ ହୁଅନ୍ତି, ଏବଂ ବଦ୍ରି ତାଙ୍କର B.Ed (ସ୍ନାତକ ଶିକ୍ଷା) ଆରମ୍ଭ କରନ୍ତି |",
              "as": "মহাবিদ্যালয়ৰ পিছত, তিনিওজন লক্ষ্ণৌৰ এটা সৰু কোঠালৈ স্থানান্তৰিত হয় য'ত মাধৱ আৰু কুলদীপে UPSC পৰীক্ষাৰ বাবে প্ৰস্তুতি চলায়, আৰু বদ্ৰীয়ে তেওঁৰ B.Ed (শিক্ষাৰ স্নাতক) আৰম্ভ কৰে।",
              "gu": "કૉલેજ પછી, ત્રણેય લખનૌમાં એક નાનકડા રૂમમાં સ્થળાંતર થાય છે જ્યાં માધવ અને કુલદીપ UPSC પરીક્ષાની તૈયારી કરે છે, અને બદ્રીએ તેનું B.Ed (શિક્ષણ સ્નાતક) શરૂ કર્યું."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(i)_3_4",
              "hi": "X6 (i) _3_4",
              "ka": "X6(i)_3_4",
              "te": "X6(i)_3_4",
              "or": "X6(i)_3_4",
              "as": "X6(i)_3_4",
              "gu": "X6(i)_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav and Kuldeep spend time reading and preparing for the exam in the City Public Library as they have access to daily newspapers and magazines there.",
              "hi": "माधव और कुलदीप ने शहर के सार्वजनिक पुस्तकालय में परीक्षा के लिए पढ़ने और तैयारी करने में समय बिताया क्योंकि वहां उनको  दैनिक समाचार पत्र और पत्रिकाएं उपलब्ध थे।",
              "ka": "ಮಾಧವ್ ಮತ್ತು ಕುಲದೀಪ್ ಅವರು ಸಿಟಿ ಪಬ್ಲಿಕ್ ಲೈಬ್ರರಿಯಲ್ಲಿ ದಿನಪತ್ರಿಕೆಗಳು ಮತ್ತು ನಿಯತಕಾಲಿಕೆಗಳಿಗೆ ಪ್ರವೇಶವನ್ನು ಹೊಂದಿರುವ ಕಾರಣ ಅಲ್ಲಿ ಓದಲು ಮತ್ತು ಪರೀಕ್ಷೆಗಾಗಿ ತಯಾರಿ ನಡೆಸುತ್ತಿದ್ದಾರೆ.",
              "te": "వార్తాపత్రికలు మరియు మ్యాగజైన్‌ లు అందుబాటులో ఉన్నందున మాధవ్ మరియు కుల్దీప్‌లు సిటీ పబ్లిక్ లైబ్రరీలో చదవుతూ పరీక్షకు సిద్ధమవుతున్నారు.",
              "or": "ମାଧବ ଏବଂ କୁଲଦୀପ ସିଟି ପବ୍ଲିକ ଲାଇବ୍ରେରୀରେ ପହିବା ଏବଂ ପ୍ରସ୍ତୁତି\n  ପାଇଁ ସମୟ ଅତିବାହିତ କରନ୍ତି କାରଣ ସେଠାରେ ସେମାନେ ଦଇନିକ ଖବରକାଗଜ ଏବଂ ପତ୍ରିକା ପାଇପାରିବେ |",
              "as": "মাধৱ আৰু কুলদীপে চিটি পাব্লিক লাইব্ৰেৰীত পৰীক্ষাৰ বাবে পঢ়ি আৰু প্ৰস্তুতি কৰি সময় অতিবাহিত কৰে কিয়নো তেওঁলোকে তাত দৈনিক বাতৰি কাকত আৰু আলোচনী পঢ়িবলৈ পায়।",
              "gu": "માધવ અને કુલદીપ સિટી પબ્લિક લાઇબ્રેરીમાં પરીક્ષાની તૈયારી કરવામાં અને વાંચવામાં સમય પસાર કરે છે કારણ કે તેમને ત્યાં દૈનિક અખબારો અને સામયિકો ઉપલબ્ધ છે."
            }
          }
        ]
      },
      "scene9": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(ii)_1_1",
              "hi": "X6 (ii) _1_1",
              "ka": "X6(ii)_1_1",
              "te": "X6(ii)_1_1",
              "or": "X6(ii)_1_1",
              "as": "X6(ii)_1_1",
              "gu": "X6(ii)_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to take up a part-time job as he doesn't want Kuldeep to worry about him. He approaches a coaching centre for a job.",
              "hi": "माधव ने अंशकालिक नौकरी लेने का फैसला किया क्योंकि वह नहीं चाहता कि कुलदीप उसके बारे में परेशान हो। वह नौकरी के लिए एक कोचिंग सेंटर पहुंचता है।",
              "ka": "ಕುಲದೀಪ್ ತನ್ನ ಬಗ್ಗೆ ಚಿಂತಿಸುವುದನ್ನು ಬಯಸದ ಮಾಧವ್ ಅರೆಕಾಲಿಕ ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಅವನು ಕೆಲಸಕ್ಕಾಗಿ ಕೋಚಿಂಗ್ ಸೆಂಟರ್ ಅನ್ನು ಸಂಪರ್ಕಿಸುತ್ತಾನೆ.",
              "te": "కుల్దీప్ తన గురించి ఆందోళన చెందకూడదని భావించిన మాధవ్ పార్ట్ టైమ్ ఉద్యోగం చేయాలని నిర్ణయించుకున్నాడు. ఉద్యోగం కోసం కోచింగ్ సెంటర్‌ కి వెళ్తాడు.",
              "or": "କୁଲଦୀପ ତାଙ୍କ ବିଷୟରେ ଚିନ୍ତା କରିବାକୁ ଚାହୁଁ ନ ଥିବାରୁ ମାଧବ ଏକ ପାର୍ଟ\n  ଟାଇମ୍ ଚାକିରି କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ଚାକିରି ପାଇଁ ସେ ଏକ କୋଚିଂ ସେଣ୍ଟର ନିକଟକୁ ଆସନ୍ତି |",
              "as": "মাধৱে এটা আংশিক সময়ৰ চাকৰি লোৱাৰ সিদ্ধান্ত লয় কিয়নো সি নিবিচাৰে যে কুলদীপে তাৰ বিষয়ে চিন্তা কৰক। তেওঁ এটা চাকৰিৰ বাবে এটা প্ৰশিক্ষণ কেন্দ্ৰৰ কাষ চাপে।",
              "gu": "માધવ અડધો સમય નોકરી કરવાનું નક્કી કરે છે કારણ કે તે નથી ઈચ્છતો કે કુલદીપ તેની ચિંતા કરે. તે નોકરી માટે કોચિંગ સેન્ટરનો સંપર્ક કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(ii)_1_2",
              "hi": "X6 (ii) _1_2",
              "ka": "X6(ii)_1_2",
              "te": "X6(ii)_1_2",
              "or": "X6(ii)_1_2",
              "as": "X6(ii)_1_2",
              "gu": "X6(ii)_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He applies for the role of the administrative executive. His job is to ensure the smooth functioning of the centre and look into the students' needs.",
              "hi": "वह प्रशासनिक कार्यकारी की भूमिका के लिए आवेदन करता है। उसका काम यह देखना है कि केंद्र सुचारू रूप से  कार्य करे और छात्रों की जरूरतों पूरी हों।",
              "ka": "ಅವನು ಆಡಳಿತಾತ್ಮಕ ಕಾರ್ಯನಿರ್ವಾಹಕರ ಪಾತ್ರಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಾನೆ. ಕೇಂದ್ರದ ಸುಗಮ ಕಾರ್ಯನಿರ್ವಹಣೆಯನ್ನು ಖಚಿತಪಡಿಸುವುದು ಮತ್ತು ವಿದ್ಯಾರ್ಥಿಗಳ ಅಗತ್ಯತೆಗಳನ್ನು ನೋಡುವುದು ಅವನ ಕೆಲಸವಾಗಿರುತ್ತದೆ.",
              "te": "అతను అడ్మినిస్ట్రేటివ్ ఎగ్జిక్యూటివ్ పాత్ర కోసం అప్లై చేస్తాడు. సెంటర్ సరిగ్గా నడిచేలా చూడడం మరియు విద్యార్థుల అవసరాలను పరిశీలించడం అతని పని.",
              "or": "ସେ ପ୍ରଶାସନିକ କାର୍ଯ୍ୟନିର୍ବାହୀ ଭୂମିକା ପାଇଁ ଆବେଦନ କରନ୍ତି। ତାଙ୍କର\n  କାର୍ଯ୍ୟ ହେଉଛି କେନ୍ଦ୍ରର ସୁଗମ କାର୍ଯ୍ୟକୁ ସୁନିଶ୍ଚିତ କରିବା ଏବଂ ଛାତ୍ରମାନଙ୍କର ଆବଶ୍ୟକତା ଉପରେ ଦୃଷ୍ଟି ରଖିବା |",
              "as": "তেওঁ প্ৰশাসনিক কাৰ্যবাহীৰ ভূমিকাৰ বাবে আবেদন কৰে। তেওঁৰ কাম হৈছে কেন্দ্ৰটোৰ মসৃণ কাৰ্যকলাপ নিশ্চিত কৰা আৰু শিক্ষাৰ্থীসকলৰ প্ৰয়োজনীয়তা বোৰ চোৱা।",
              "gu": "તે વહીવટી કારોબારીની ભૂમિકા માટે અરજી કરે છે. તેમનું કાર્ય કેન્દ્રની સરળ કામગીરીને સુનિશ્ચિત કરવાનું અને વિદ્યાર્થીઓની જરૂરિયાતો પર ધ્યાન આપવાનું છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(ii)_1_3",
              "hi": "X6 (ii) _1_3",
              "ka": "X6(ii)_1_3",
              "te": "X6(ii)_1_3",
              "or": "X6(ii)_1_3",
              "as": "X6(ii)_1_3",
              "gu": "X6(ii)_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The coaching centre conducts coaching for different competitive exams. Madhav also gets some guidance for his exams from the teachers.",
              "hi": "कोचिंग सेंटर विभिन्न प्रतिस्पर्धी परीक्षाओं के लिए कोचिंग आयोजित करता है। माधव को शिक्षकों से उसकी परीक्षाओं के लिए कुछ मार्गदर्शन भी मिलता है।",
              "ka": "ಕೋಚಿಂಗ್ ಸೆಂಟರ್ ವಿವಿಧ ಸ್ಪರ್ಧಾತ್ಮಕ ಪರೀಕ್ಷೆಗಳಿಗೆ ತರಬೇತಿಯನ್ನು ನಡೆಸುತ್ತದೆ. ಮಾಧವ್ ತನ್ನ ಪರೀಕ್ಷೆಗಳಿಗೆ ಶಿಕ್ಷಕರಿಂದ ಕೆಲವು ಮಾರ್ಗದರ್ಶನವನ್ನೂ ಪಡೆಯುತ್ತಾನೆ.",
              "te": "కోచింగ్ సెంటర్ వివిధ పోటీ పరీక్షలకు కోచింగ్‌ను ఇస్తుంది. మాధవ్ తన పరీక్షలకు ఉపాధ్యాయుల నుండి కొంత గైడెన్స్ కూడా పొందుతాడు.",
              "or": "ବିଭିନ୍ନ ପ୍ରତିଯୋଗିତାମୂଳକ ପରୀକ୍ଷା ପାଇଁ କୋଚିଂ ସେଣ୍ଟର କୋଚିଂ କରିଥାଏ\n  | ମାଧବ ଶିକ୍ଷକମାନଙ୍କ ଠାରୁ ତାଙ୍କ ପରୀକ୍ଷା ପାଇଁ କିଛି ମାର୍ଗଦର୍ଶନ ମଧ୍ୟ ପାଆନ୍ତି |",
              "as": "প্ৰশিক্ষণ কেন্দ্ৰটোৱে বিভিন্ন প্ৰতিযোগিতামূলক পৰীক্ষাৰ বাবে প্ৰশিক্ষণ পৰিচালনা কৰে। মাধৱে শিক্ষকসকলৰ পৰা তেওঁৰ পৰীক্ষাৰ বাবে কিছু নিৰ্দেশনাও লাভ কৰে।",
              "gu": "કોચિંગ સેન્ટર વિવિધ સ્પર્ધાત્મક પરીક્ષાઓ માટે કોચિંગનું આયોજન કરે છે. માધવને તેની પરીક્ષાઓ માટે શિક્ષકો પાસેથી માર્ગદર્શન પણ મળે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(ii)_1_4",
              "hi": "X6 (ii) _1_4",
              "ka": "X6(ii)_1_4",
              "te": "X6(ii)_1_4",
              "or": "X6(ii)_1_4",
              "as": "X6(ii)_1_4",
              "gu": "X6(ii)_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He gets permission to use the books in the centre. He also talks to the students taking coaching at the centre to understand their preparation and schedule.",
              "hi": "उसे केंद्र में पुस्तकों का उपयोग करने की अनुमति मिलती है। वह अपनी तैयारी और योजना को समझने के लिए केंद्र में कोचिंग लेने वाले छात्रों से भी बात करता है।",
              "ka": "ಅವನು ಕೇಂದ್ರದಲ್ಲಿರುವ ಪುಸ್ತಕಗಳನ್ನು ಬಳಸಲು ಅನುಮತಿ ಪಡೆಯುತ್ತಾನೆ. ಅವನು ತಮ್ಮ ತಯಾರಿ ಮತ್ತು ವೇಳಾಪಟ್ಟಿಯನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಕೇಂದ್ರದಲ್ಲಿ ಕೋಚಿಂಗ್ ತೆಗೆದುಕೊಳ್ಳುವ ವಿದ್ಯಾರ್ಥಿಗಳೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾನೆ.",
              "te": "సెంటర్‌లోని పుస్తకాలను ఉపయోగించుకోవడానికి అతను అనుమతి పొందుతాడు. సెంటర్‌లో కోచింగ్ తీసుకుంటున్న విద్యార్థులతో కూడా మాట్లాడి వారి ప్రిపరేషన్ మరియు షెడ్యూల్‌ను అర్థం చేసుకుంటాడు.",
              "or": "ସେ କେନ୍ଦ୍ରରେ ବହି ବ୍ୟବହାର କରିବାକୁ ଅନୁମତି ପାଆନ୍ତି | ସେ ମଧ୍ୟ\n  ସେମାନଙ୍କ ପ୍ରସ୍ତୁତି ଏବଂ କାର୍ଯ୍ୟସୂଚୀ ବୁଝିବା ପାଇଁ କେନ୍ଦ୍ରରେ କୋଚିଂ ନେଉଥିବା ଛାତ୍ରମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରନ୍ତି |",
              "as": "সি কেন্দ্ৰটোত থকা কিতাপবোৰ ব্যৱহাৰ কৰাৰ অনুমতি লাভ কৰে। সি তেওঁলোকৰ প্ৰস্তুতি আৰু সময়সূচী বুজিবলৈ কেন্দ্ৰত প্ৰশিক্ষণ লোৱা শিক্ষাৰ্থীসকলৰ সৈতেও কথা পাতে।",
              "gu": "તેને સેન્ટરમાં પુસ્તકોનો ઉપયોગ કરવાની પરવાનગી મળે છે. તેઓ સેન્ટરમાં કોચિંગ લઈ રહેલા વિદ્યાર્થીઓ સાથે તેમની તૈયારી અને સમયપત્રક સમજવા માટે પણ વાત કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(ii)_2_1",
              "hi": "X6 (ii) _2_1",
              "ka": "X6(ii)_2_1",
              "te": "X6(ii)_2_1",
              "or": "X6(ii)_2_1",
              "as": "X6(ii)_2_1",
              "gu": "X6(ii)_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "But the stress of managing work and studies is too much. Madhav works for 5-7 hours a day and studies for 8 hours. He barely gets time to eat and sleep, and he keeps falling sick but continues to push himself.",
              "hi": "लेकिन काम और अध्ययन दोनों को संभालने का तनाव बहुत अधिक है। माधव दिन में 5-7 घंटे काम करता है और 8 घंटे अध्ययन करता है। उसे मुश्किल से खाने और सोने के लिए समय मिलता है, और वह बीमार पड़ता रहता है लेकिन खुद प्रयास करना जारी रखता है।",
              "ka": "ಆದರೆ ಕೆಲಸ ಮತ್ತು ಅಧ್ಯಯನದ ನಿರ್ವಹಣೆಯ ಒತ್ತಡ ತುಂಬಾ ಹೆಚ್ಚು. ಮಾಧವ್ ದಿನಕ್ಕೆ 5-7 ಗಂಟೆಗಳ ಕಾಲ ಕೆಲಸ ಮಾಡುತ್ತಾರೆ ಮತ್ತು 8 ಗಂಟೆಗಳ ಕಾಲ ಅಧ್ಯಯನ ಮಾಡುತ್ತಾನೆ. ಅವನಿಗೆ ತಿನ್ನಲು ಮತ್ತು ಮಲಗಲು ಸಮಯ ಸಿಗುವುದಿಲ್ಲ, ಮತ್ತು ಅವನು ಅನಾರೋಗ್ಯಕ್ಕೆ ಒಳಗಾಗುತ್ತಾನೆ ಆದರೆ ತನ್ನನ್ನು ತಾನೇ ಮುನ್ನುಗ್ಗಿಸಿಕೊಳ್ಳಲು ಪ್ರಯತ್ನವನ್ನು ಮುಂದುವರಿಸುತ್ತಾನೆ.",
              "te": "కానీ పని మరియు చదువును మేనేజ్ చేయడం చాలా కష్టం. మాధవ్ రోజుకు 5-7 గంటలు పని చేస్తాడు మరియు 8 గంటలు చదువుతాడు. అతనికి తినడానికి మరియు నిద్రించడానికి సమయం దొరకదు, మరియు అతను అనారోగ్యం పాలవుతూనే ఉంటాడు, కానీ తాను అలానే కొనసాగిస్తాడు.",
              "or": "କିନ୍ତୁ କାର୍ଯ୍ୟ ଏବଂ ଅଧ୍ୟୟନ ପରିଚାଳନା ପାଇଁ ଚାପ ଅତ୍ୟଧିକ | ମାଧବ\n  ଦିନକୁ 5-7 ଘଣ୍ଟା କାମ କରନ୍ତି ଏବଂ 8 ଘଣ୍ଟା ଅଧ୍ୟୟନ କରନ୍ତି | ସେ ଖାଇବା ଏବଂ ଶୋଇବା ପାଇଁ ସମୟ ପାଆନ୍ତି, ଏବଂ ସେ ଅସୁସ୍ଥ ହୋଇପଡନ୍ତି କିନ୍ତୁ ନିଜକୁ ଆଗକୁ ବଢେଇବାକୁ ଲାଗନ୍ତି |",
              "as": "কিন্তু কাম আৰু অধ্যয়ন পৰিচালনাৰ চাপ বহুত বেছি। মাধৱে দিনে 5-7 ঘণ্টা কাম কৰে আৰু 8 ঘণ্টা অধ্যয়ন কৰে। সি কোনোমতে খাবলৈ আৰু শুবলৈ সময় পায়, আৰু সি অসুস্থ হৈয়ে থাকে কিন্তু নিজকে ঠেলি থাকে।",
              "gu": "પણ કામ અને અભ્યાસ સંચાલન કરવાનો તણાવ ઘણો વધારે છે. માધવ દિવસમાં 5-7 કલાક કામ કરે છે અને 8 કલાક અભ્યાસ કરે છે. તેને ખાવા અને સૂવાનો ભાગ્યે જ સમય મળે છે, અને તે બીમાર પડતો રહે છે પણ પોતાને દબાણ કરવાનું ચાલુ રાખે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6(ii)_2_2////",
              "hi": "X6 (ii) _2_2 ////",
              "ka": "X6(ii)_2_2////",
              "te": "X6(ii)_2_2////",
              "or": "X6(ii)_2_2////",
              "as": "X6(ii)_2_2////",
              "gu": "X6(ii)_2_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Madhav, If you continue like this, you will harm yourself",
              "hi": "माधव, यदि तुम इसी तरह करते रहे, तो तुम खुद को नुकसान पहुंचाओगे ",
              "ka": "ಮಾಧವ್, ನೀನು ಹೀಗೆಯೇ ಮುಂದುವರಿದರೆ ನಿನಗೇ ನೀನು ಹಾನಿ ಮಾಡಿಕೊಳ್ಳುತ್ತೀಯಾ",
              "te": "మాధవ్ నువ్వు ఇలాగే కొనసాగితే నీకే నష్టం",
              "or": "ମାଧବ, ଯଦି ତୁମେ ଏହିପରି ଜାରି ରଖ, ତୁମେ ନିଜକୁ କ୍ଷତି ପହଞ୍ଚାଇବ |",
              "as": "মাধৱ, যদি তুমি এনেদৰেই থাকা, তুমি নিজৰে ক্ষতি কৰিবা",
              "gu": "માધવ, જો તું આમ જ કરતો રહિસ તો ઘણું જ નુકસાન થશે"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "You know the students at the centre study for 14 hours a day and also get extra coaching. What I'm doing is barely enough.",
              "hi": "तुम जानते हो कि केंद्र के छात्र दिन में 14 घंटे अध्ययन करते हैं और अतिरिक्त कोचिंग भी प्राप्त करते हैं। मैं जो कर रहा हूं वह मुश्किल से पर्याप्त है।",
              "ka": "ಕೇಂದ್ರದಲ್ಲಿರುವ ವಿದ್ಯಾರ್ಥಿಗಳು ದಿನಕ್ಕೆ 14 ಗಂಟೆಗಳ ಕಾಲ ಅಧ್ಯಯನ ಮಾಡುತ್ತಾರೆ ಮತ್ತು ಹೆಚ್ಚುವರಿ ತರಬೇತಿಯನ್ನು ಪಡೆಯುತ್ತಾರೆ ಎಂದು ನಿನಗೆ ತಿಳಿದಿದೆ. ನಾನು ಮಾಡುತ್ತಿರುವುದು ಅಷ್ಟಕ್ಕೇ ಮಾತ್ರವೇ ಸಾಕಾಗುತ್ತಿದೆ..",
              "te": "సెంటర్‌లోని విద్యార్థులు రోజుకు 14 గంటలు చదువుకోవడంతోపాటు అదనపు కోచింగ్ కూడా తీసుకుంటారని నీకు తెలుసు. నేను చేస్తున్నది సరిపోదు.",
              "or": "ଆପଣ ଜାଣନ୍ତି କେନ୍ଦ୍ରର ଛାତ୍ରମାନେ ଦିନକୁ 14 ଘଣ୍ଟା ଅଧ୍ୟୟନ କରନ୍ତି \n ଏବଂ ଅତିରିକ୍ତ କୋଚିଂ ମଧ୍ୟ ପାଆନ୍ତି | ମୁଁ ଯାହା କରୁଛି ତାହା ଯଥେଷ୍ଟ ନୁହେଁ |",
              "as": "তুমি জানানে কেন্দ্ৰৰ শিক্ষাৰ্থীসকলে দিনে 14 ঘণ্টা অধ্যয়ন কৰে আৰু অতিৰিক্ত প্ৰশিক্ষণো লাভ কৰে। মই যি কৰি আছোঁ সেয়া যথেষ্ট নহয়।",
              "gu": "તું જાણે છે કે સેંટરના વિદ્યાર્થીઓ દિવસના 14 કલાક અભ્યાસ કરે છે અને વધારાનું કોચિંગ પણ મેળવે છે. હું જે કરી રહ્યો છું તે ભાગ્યે જ પૂરતું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "You are doing enough. I am studying as much as them, but you scored more than us in the last practice test. So stop worrying.",
              "hi": "तुम पर्याप्त कर रहे हो। मैं उनके जितना ही अध्ययन कर रहा हूं, लेकिन तुमने पिछले अभ्यास परीक्षण में हमसे अधिक अंक प्राप्त किए हैं। इसलिए चिंता करना बंद करो।",
              "ka": "ನೀನು ಸಾಕಷ್ಟು ಮಾಡುತ್ತಿದ್ದೀಯಾ. ನಾನು ಕೂಡ ಅವರಷ್ಟೇ ಓದುತ್ತಿದ್ದೇನೆ, ಆದರೆ ನೀನು ಕಳೆದ ಅಭ್ಯಾಸ ಪರೀಕ್ಷೆಯಲ್ಲಿ ನನಗಿಂತ ಹೆಚ್ಚು ಅಂಕ ಗಳಿಸಿದ್ದೀಯಾ. ಆದ್ದರಿಂದ ಚಿಂತಿಸುವುದನ್ನು ನಿಲ್ಲಿಸು.",
              "te": "నువ్వు తగినంత చేస్తున్నావు. నేనూ వాళ్లలానే చదువుతున్నాను, కానీ నువ్వు గత ప్రాక్టీస్ టెస్ట్‌లో మాకంటే ఎక్కువ మార్కులు సాధించావు. కాబట్టి చింతించడం మానేయి.",
              "or": "ତୁମେ ଯଥେଷ୍ଟ କରୁଛ | ମୁଁ ସେମାନଙ୍କ ପରି ଯେତିକି ଅଧ୍ୟୟନ କରୁଛି,\n  କିନ୍ତୁ ଆପଣ ଶେଷ ଅଭ୍ୟାସ ପରୀକ୍ଷାରେ ଆମଠାରୁ ଅଧିକ ସ୍କୋର କରିଛନ୍ତି | ତେଣୁ ଚିନ୍ତା କରିବା ବନ୍ଦ କରନ୍ତୁ |",
              "as": "তুমি যথেষ্ট কৰিছা। মই তেওঁলোকৰ দৰেই অধ্যয়ন কৰি আছোঁ, কিন্তু অন্তিম অনুশীলন পৰীক্ষাত তুমি আমাতকৈ অধিক নম্বৰ লাভ কৰিছিলা। গতিকে চিন্তা কৰা বন্ধ কৰা।",
              "gu": "તું પૂરતું કરી રહ્યો છે. હું તેમના જેટલો જ અભ્યાસ કરું છું, પરંતુ તે છેલ્લી પ્રેક્ટિસ ટેસ્ટમાં અમારા કરતાં વધુ ગુણ મેળવ્યા હતા. તેથી ચિંતા કરવાનું બંધ કર."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "I've decided to leave work next month. There are only two months left, and I have some money that I have saved.",
              "hi": "मैंने अगले महीने काम छोड़ने का निश्चय किया है। केवल दो महीने बचे हैं, और मेरे पास कुछ पैसे हैं जिन्हें मैंने बचाया है।",
              "ka": "ನಾನು ಮುಂದಿನ ತಿಂಗಳು ಕೆಲಸ ಬಿಡಲು ನಿರ್ಧರಿಸಿದ್ದೇನೆ. ಇನ್ನು ಎರಡು ತಿಂಗಳು ಮಾತ್ರ ಉಳಿದಿದೆ, ನಾನು ಉಳಿಸಿದ ಸ್ವಲ್ಪ ಹಣವಿದೆ.",
              "te": "నేను వచ్చే నెలలో పనిని వదిలివేయాలని నిర్ణయించుకున్నాను. కేవలం రెండు నెలలు మాత్రమే మిగిలి ఉన్నాయి మరియు నేను పొదుపు చేసిన కొంత డబ్బు ఉంది.",
              "or": "ମୁଁ ଆସନ୍ତା ମାସରେ କାମ ଛାଡିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛି | ଆଉ ମାତ୍ର ଦୁଇମାସ \n ବାକି ଅଛି, ଏବଂ ମୋର କିଛି ଟଙ୍କା ଅଛି ଯାହା ମୁଁ ସଞ୍ଚୟ କରିଛି |",
              "as": "মই অহা মাহত কাম এৰিবলৈ সিদ্ধান্ত লৈছোঁ। মাত্ৰ দুমাহ বাকী আছে, আৰু মোৰ ওচৰত জমা কৰা কিছু টকাও আছে।",
              "gu": "મેં આવતા મહિને કામ છોડવાનું નક્કી કર્યું છે. માત્ર બે મહિના બાકી છે, અને મારી પાસે થોડા પૈસા છે જે મેં બચાવ્યા છે."
            }
          }
        ]
      },
      "scene10": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_1_1",
              "hi": "A1_1_1",
              "ka": "A1_1_1",
              "te": "A1_1_1",
              "or": "A1_1_1",
              "as": "A1_1_1",
              "gu": "A1_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "There are 30 days left for the exam. Madhav and Kuldeep are stressed, but they keep working hard.",
              "hi": "परीक्षा के लिए 30 दिन बचे हैं। माधव और कुलदीप तनाव में हैं, लेकिन वे कड़ी मेहनत करते रहते हैं।",
              "ka": "ಪರೀಕ್ಷೆಗೆ ಇನ್ನು 30 ದಿನಗಳಿವೆ. ಮಾಧವ್ ಮತ್ತು ಕುಲದೀಪ್ ಒತ್ತಡದಲ್ಲಿದ್ದಾರೆ, ಆದರೆ ಅವರು ಕಷ್ಟಪಟ್ಟು ಓದುತ್ತಿದ್ದಾರೆ.",
              "te": "పరీక్షకు ఇంకా 30 రోజుల సమయం ఉంది. మాధవ్ మరియు కుల్దీప్ ఒత్తిడిలో ఉన్నారు, కానీ వారు కష్టపడుతూనే ఉన్నారు.",
              "or": "ପରୀକ୍ଷା ପାଇଁ 30 ଦିନ ବାକି ଅଛି | ମାଧବ ଏବଂ କୁଲଦୀପ ଚାପଗ୍ରସ୍ତ, \n କିନ୍ତୁ ସେମାନେ କଠିନ ପରିଶ୍ରମ କରନ୍ତି |",
              "as": "পৰীক্ষাৰ বাবে ৩০ দিন বাকী আছে। মাধৱ আৰু কুলদীপ চাপত আছে, কিন্তু তেওঁলোকে কঠোৰ পৰিশ্ৰম কৰি আছে।",
              "gu": "પરીક્ષા માટે 30 દિવસ બાકી છે. માધવ અને કુલદીપ તણાવમાં છે, પરંતુ તેઓ સખત મહેનત કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_1_2",
              "hi": "A1_1_2",
              "ka": "A1_1_2",
              "te": "A1_1_2",
              "or": "A1_1_2",
              "as": "A1_1_2",
              "gu": "A1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They have a schedule that they follow most of the days. They concentrate on studying the whole day.",
              "hi": "उनके पास एक कार्यक्रम है जिसका वे लगभग रोज पालन करते हैं। वे पूरे दिन अध्ययन करने पर ध्यान केंद्रित करते हैं।",
              "ka": "ಅವರು ಒಂದು ವೇಳಾಪಟ್ಟಿಯನ್ನು ತಯಾರಿಸಿದ್ದು ಹೆಚ್ಚಿನ ದಿನಗಳಲ್ಲಿ ಅದನ್ನು ಅನುಸರಿಸುತ್ತಾರೆ. ಅವರು ಇಡೀ ದಿನ ಅಧ್ಯಯನದ ಮೇಲೆ ತಮ್ಮ ಗಮನವನ್ನು ಕೇಂದ್ರೀಕರಿಸುತ್ತಾರೆ.",
              "te": "వారు చాలా రోజులు అనుసరించేలా ఒక షెడ్యూల్‌ చేసుకున్నారు. రోజంతా చదువుపైనే దృష్టి పెడతారు.",
              "or": "ସେମାନଙ୍କର ଏକ କାର୍ଯ୍ୟସୂଚୀ ଅଛି ଯାହାକି ସେମାନେ ଅଧିକାଂଶ ଦିନ\n  ଅନୁସରଣ କରନ୍ତି | ସେମାନେ ଦିନସାରା ଅଧ୍ୟୟନ ଉପରେ ଧ୍ୟାନ ଦିଅନ୍ତି |",
              "as": "তেওঁলোকৰ এখন অনুসূচী আছে যিখন তেওঁলোকে বেছিভাগ দিনেই অনুসৰণ কৰে। তেওঁলোকে গোটেই দিনটো অধ্যয়নত মনোনিৱেশ কৰে।",
              "gu": "તેમની પાસે એક સમય પત્રક છે જે તેઓ મોટાભાગના દિવસોમાં અનુસરે છે. તેઓ આખો દિવસ અભ્યાસમાં ધ્યાન આપે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_2_1",
              "hi": "A1_2_1",
              "ka": "A1_2_1",
              "te": "A1_2_1",
              "or": "A1_2_1",
              "as": "A1_2_1",
              "gu": "A1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "One day late at night, when Madhav is studying, he gets a call from his father.",
              "hi": " एक दिन देर रात, जब माधव अध्ययन कर रहा है, तो उसे अपने पिता का कॉल आता है।",
              "ka": "ಒಂದು ದಿನ ತಡರಾತ್ರಿ, ಮಾಧವ್ ಓದುತ್ತಿರುವಾಗ, ಅವನ ತಂದೆಯಿಂದ ಕರೆ ಬರುತ್ತದೆ.",
              "te": "ఒకరోజు అర్థరాత్రి, మాధవ్ చదువుకుంటున్నప్పుడు, అతని తండ్రి నుండి ఫోన్ వచ్చింది.",
              "or": "ଦିନେ ବିଳମ୍ବିତ ରାତିରେ, ଯେତେବେଳେ ମାଧବ ଅଧ୍ୟୟନ କରନ୍ତି, ସେତେବେଳେ \n ସେ ତାଙ୍କ ପିତାଙ୍କଠାରୁ ଏକ କଲ ପାଇଥା’ନ୍ତି |",
              "as": "এদিন ৰাতি পলমকৈ, যেতিয়া মাধৱে পঢ়ি আছিল, তেওঁ তেওঁৰ দেউতাকৰ পৰা এটা ফোন পায়।",
              "gu": "એક દિવસ મોડી રાત્રે માધવ ભણતો હતો ત્યારે તેને તેના પિતાનો ફોન આવ્યો."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_2_2",
              "hi": "A1_2_2",
              "ka": "A1_2_2",
              "te": "A1_2_2",
              "or": "A1_2_2",
              "as": "A1_2_2",
              "gu": "A1_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is worried, and he doesn't talk much to his father. The phone call upsets him as his father demands Madhav to start sending him money soon.",
              "hi": "माधव चिंतित हैं, और वह अपने पिता से ज्यादा बात नहीं करता है। फोन कॉल ने उसे परेशान किया क्योंकि उसके पिता माधव को जल्द ही पैसे भेजने की मांग करते हैं।",
              "ka": "ಮಾಧವ್ ಚಿಂತಿತನಾಗಿದ್ದಾನೆ, ಅವನು ತನ್ನ ತಂದೆಯೊಂದಿಗೆ ಹೆಚ್ಚು ಮಾತನಾಡುವುದಿಲ್ಲ. ಮಾಧವ್ ತನಗೆ ಶೀಘ್ರದಲ್ಲೇ ಹಣ ಕಳುಹಿಸಲು ಪ್ರಾರಂಭಿಸುವಂತೆ ಅವರ ತಂದೆ ಒತ್ತಾಯಿಸಿ ಬಂದಿರುವ ಫೋನ್ ಕರೆಯು ಅವನನ್ನು ಅಸಮಾಧಾನಗೊಳಿಸುತ್ತದೆ.",
              "te": "మాధవ్ ఆందోళన చెందాడు మరియు అతను తన తండ్రితో ఎక్కువగా మాట్లాడడు. మాధవ్‌ని త్వరగా డబ్బు పంపడం ప్రారంభించమని అతని తండ్రి డిమాండ్ చేయడంతో ఫోన్ కాల్ అతన్ని కలవరపెడుతుంది.",
              "or": "ମାଧବ ଚିନ୍ତିତ, ଏବଂ ସେ ତାଙ୍କ ପିତାଙ୍କ ସହ ଅଧିକ କଥାବାର୍ତ୍ତା କରନ୍ତି ନାହିଁ |\n  ତାଙ୍କ ପିତା ମାଧବଙ୍କୁ ଶୀଘ୍ର ଟଙ୍କା ପଠାଇବା ପାଇଁ ଦାବି କରୁଥିବାରୁ ଫୋନ୍ କଲ ତାଙ୍କୁ ବିବ୍ରତ କରିଛି।",
              "as": "মাধৱ চিন্তিত হয়, আৰু সি তাৰ দেউতাকৰ সৈতে বেছি কথা নাপাতে। ফোন কলটোৱে তাক বিচলিত কৰে কিয়নো তাৰ দেউতাকে মাধৱক সোনকালে টকা পঠোৱা আৰম্ভ কৰিবলৈ দাবী কৰে।",
              "gu": "માધવ ચિંતિત છે, અને તે તેના પિતા સાથે વધુ વાત કરતો નથી. ફોન કોલથી તે નારાજ થઈ જાય છે કારણ કે તેના પિતા માધવને તેને જલ્દી પૈસા મોકલવાની માંગ કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_1_3//",
              "hi": "A1_1_3 //",
              "ka": "A1_1_3//",
              "te": "A1_1_3//",
              "or": "A1_1_3//",
              "as": "A1_1_3//",
              "gu": "A1_1_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୂଲଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Concentrate, Madhav. We cannot afford to lose focus with a month left for the exam.",
              "hi": "ध्यान केंद्रित करो, माधव। हम परीक्षा के लिए एक महीने शेष होने के कारण अपना ध्यान भटकाने का जोखिम नहीं उठा सकते।",
              "ka": "ಏಕಾಗ್ರತೆ ಹೊಂದು, ಮಾಧವ್. ಪರೀಕ್ಷೆಗೆ ಒಂದು ತಿಂಗಳು ಬಾಕಿ ಇರುವಾಗ ನಾವು ಗಮನವನ್ನು ಕಳೆದುಕೊಳ್ಳುವುದು ಸರಿಯಲ್ಲ.",
              "te": "ఏకాగ్రత పెట్టు, మాధవ్. పరీక్షకు ఒక నెల మాత్రమే ఉంది కాబట్టి ఫోకస్ కోల్పోవద్దు.",
              "or": "ଏକାଗ୍ରତା ରଖ , ମାଧବ | ପରୀକ୍ଷା ପାଇଁ ଏକ ମାସ ବାକି ଅଛି ଆମେ \n ଧ୍ୟାନ ହରାଇ ହଟେଇ ପାରିବା ନାହିଁ |",
              "as": "মনোযোগ দিয়া, মাধৱ। পৰীক্ষাৰ বাবে এমাহ বাকী থকা অৱস্থাত আমি মনোযোগ হেৰুৱাব নোৱাৰোঁ।",
              "gu": "ધ્યાન રાખ, માધવ. પરીક્ષામાં એક મહિનો બાકી હોવાથી આપણે ધ્યાન નહીં આપીએ તો તે પોષાય તેમ નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I know, but my father said that the contractor has asked him to leave since he's getting old.",
              "hi": "मुझे पता है, लेकिन मेरे पिता ने कहा कि ठेकेदार ने उन्हें काम छोड़ने के लिए कहा है क्यूंकि वे बूढ़े हो गया हैं।",
              "ka": "ನನಗೆ ಗೊತ್ತು, ಆದರೆ ನನ್ನ ತಂದೆ ಹೇಳಿದರು, ಗುತ್ತಿಗೆದಾರನು ಅವನಿಗೆ ವಯಸ್ಸಾಗಿರುವುದರಿಂದ ಅವನನ್ನು ಕೆಲಸವನ್ನು ಬಿಡಲು ಹೇಳಿದ್ದಾನೆ.",
              "te": "నాకు తెలుసు, కానీ మా నాన్నగారి వయసు ఎక్కువ అయిందని కాంట్రాక్టరు పనిని వదిలేయమని చెప్పాడు.",
              "or": "ମୁଁ ଜାଣେ, କିନ୍ତୁ ମୋ ବାପା କହିଛନ୍ତି ଯେ କଣ୍ଟ୍ରାକ୍ଟର ତାଙ୍କୁ ବୃଦ୍ଧ ହେବା କାରଣରୁ \n  ଚାଲିଯିବାକୁ କହିଛନ୍ତି।",
              "as": "মই জানো, কিন্তু মোৰ দেউতাই কৈছিল যে ঠিকাদাৰে তেওঁক বুঢ়া হোৱাৰ কাৰণে কাম এৰি যাবলৈ কৈছে।",
              "gu": "હું જાણું છું, પણ મારા પિતાએ કહ્યું કે કોન્ટ્રાક્ટરે તેને કામ છોડવા માટે કહ્યું છે કારણ કે તે વૃદ્ધ થઈ રહ્યો છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_1_4////",
              "hi": "A1_1_4 //////////////",
              "ka": "A1_1_4////",
              "te": "A1_1_4////",
              "or": "A1_1_4////",
              "as": "A1_1_4////",
              "gu": "A1_1_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "He calls you after all these months to tell you this?",
              "hi": "इन सभी महीनों के बाद वह तुमको यह बताने के लिए कॉल करते हैं?",
              "ka": "ಇಷ್ಟು ತಿಂಗಳ ನಂತರ ಅವರು ನಿನಗೆ ಇದನ್ನು ಹೇಳಲು ಕರೆ ಮಾಡಿದ್ದಾರೆಯೇ?",
              "te": "ఈ విషయం నీకు చెప్పడానికి అతను ఇన్ని నెలల తర్వాత మీకు ఫోన్ చేసాడా?",
              "or": "ତୁମକୁ ଏସବୁ କହିବା ପାଇଁ ସେ ତୁମକୁ ଏତେ ମାସ ପରେ କଲ କରିଛନ୍ତି କି?",
              "as": "তেওঁ তোমাক এইটো কথা ক'বলৈ ইমান মাহৰ পিছত ফোন কৰিছিল?",
              "gu": "તે આટલા મહિનાઓ પછી તને આ કહેવા માટે ફોન કર્યો છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I have to find a way to earn money, Kuldeep. He is my father after all.",
              "hi": "मुझे पैसे कमाने के लिए एक रास्ता खोजना है, कुलदीप। आखिरकार वह मेरे पिता हैं।",
              "ka": "ಕುಲದೀಪ್, ನಾನು ಹಣ ಸಂಪಾದಿಸುವ ಮಾರ್ಗವನ್ನು ಹುಡುಕಬೇಕಾಗಿದೆ. ಎಷ್ಟಾದರೂ ಅವರು ನನ್ನ ತಂದೆ.",
              "te": "నేను డబ్బు సంపాదించడానికి ఒక మార్గం వెతకాలి, కుల్దీప్. ఏదేమైనా ఆయన నా తండ్రి.",
              "or": "ମୋତେ ଟଙ୍କା ରୋଜଗାରର ଏକ ଉପାୟ ଖୋଜିବାକୁ ପଡିବ, କୁଲଦୀପ | \n ଯାହା ହେଲେ ବି ସେ ମୋର ପିତା |",
              "as": "মই টকা উপাৰ্জনৰ এটা উপায় বিচাৰিব লাগিব, কুলদীপ। যি হ’লেও তেওঁ মোৰ দেউতা।",
              "gu": "મારે પૈસા કમાવવાનો રસ્તો શોધવો પડશે, કુલદીપ. છેવટે તે મારા પિતા છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Yes, I know, but we will figure something out in a month. Don't lose focus right now.",
              "hi": "हां, मुझे पता है, लेकिन हम एक महीने में कुछ न कुछ कर लेंगे। अभी ध्यान न भटकाओ।",
              "ka": "ಹೌದು, ನನಗೆ ಗೊತ್ತು, ಆದರೆ ನಾನು ಒಂದು ತಿಂಗಳಲ್ಲಿ ಏನನ್ನಾದರೂ ಲೆಕ್ಕಾಚಾರ ಮಾಡುತ್ತೇನೆ. ಇದೀಗ ಗಮನವನ್ನು ಕಳೆದುಕೊಳ್ಳಬೇಡ.",
              "te": "అవును, నాకు తెలుసు, కానీ మనము ఒక నెలలో ఏదో ఒకటి చూద్దాం. ప్రస్తుతం దృష్టిని కోల్పోవద్దు.",
              "or": "ହଁ, ମୁଁ ଜାଣେ, କିନ୍ତୁ ଏକ ମାସ ମଧ୍ୟରେ ଆମେ କିଛି ଜାଣିବା | ବର୍ତ୍ତମାନ\n  ଧ୍ୟାନ ହରାନ୍ତୁ ନାହିଁ |",
              "as": "হয়, মই জানো, কিন্তু আমি এমাহৰ ভিতৰত কিবা এটা বিচাৰি উলিয়াম। এতিয়া মনোযোগ নেহেৰুৱাবা।",
              "gu": "હા, હું જાણું છું, પરંતુ આપણે એક મહિનામાં કંઈક શોધીશું. અત્યારે ધ્યાન ન ગુમાવો."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_1",
              "hi": "A1_3_1",
              "ka": "A1_3_1",
              "te": "A1_3_1",
              "or": "A1_3_1",
              "as": "A1_3_1",
              "gu": "A1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They study hard over the month and appear for the preliminary exams. They get the result within a month.",
              "hi": "वे महीने भर कड़ी मेहनत करते हैं और प्रारंभिक परीक्षाओं के लिए उपस्थित होते हैं। वे एक महीने के भीतर परिणाम प्राप्त करते हैं।",
              "ka": "ಅವರು ತಿಂಗಲು ಪೂರ್ತಿ ಕಷ್ಟಪಟ್ಟು ಅಧ್ಯಯನ ಮಾಡುತ್ತಾರೆ ಮತ್ತು ಪೂರ್ವಭಾವಿ ಪರೀಕ್ಷೆಗಳಿಗೆ ಹಾಜರಾಗುತ್ತಾರೆ. ಅವರು ಒಂದು ತಿಂಗಳೊಳಗೆ ಫಲಿತಾಂಶವನ್ನು ಪಡೆಯುತ್ತಾರೆ.",
              "te": "నెల రోజులు కష్టపడి చదివి ప్రిలిమినరీ పరీక్షలకు హాజరవుతున్నారు. వారికి ఒక నెలలో ఫలితం వస్తుంది.",
              "or": "ସେମାନେ ମାସକ ମଧ୍ୟରେ କଠିନ ଅଧ୍ୟୟନ କରନ୍ତି ଏବଂ ପ୍ରାଥମିକ ପରୀକ୍ଷା\n  ପାଇଁ ଉପସ୍ଥିତ ହୁଅନ୍ତି | ସେମାନେ ଏକ ମାସ ମଧ୍ୟରେ ଫଳାଫଳ ପାଆନ୍ତି |",
              "as": "তেওঁলোকে মাহটোত কঠোৰ অধ্যয়ন কৰে আৰু প্ৰাথমিক পৰীক্ষাত অৱতীৰ্ণ হয়। তেওঁলোকে এমাহৰ ভিতৰত ফলাফল লাভ কৰে।",
              "gu": "તેઓ આખો મહિનો સખત અભ્યાસ કરે છે અને પ્રારંભિક પરીક્ષા આપે છે. તેમને એક મહિનામાં પરિણામ મળે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_2(reveal)",
              "hi": "A1_3_2 (रिवील)",
              "ka": "A1_3_2(reveal)",
              "te": "A1_3_2(బహిర్గతం)",
              "or": "A1_3_2(reveal)",
              "as": "A1_3_2(প্ৰকাশ)",
              "gu": "A1_3_2(જાહેર કરો)"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal their and the cutoff rank:",
              "hi": "उनके और कटऑफ रैंक को प्रकट करने के लिए क्लिक करें:",
              "ka": "ಅವರ ಮತ್ತು ಕಟ್ಆಫ್ ಶ್ರೇಣಿಯನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ",
              "te": "వారి మరియు కటాఫ్ ర్యాంక్‌ను రివీల్ చేయడానికి క్లిక్ చేయండి:",
              "or": "ସେମାନଙ୍କର ଏବଂ କଟଅଫ୍ ରାଙ୍କ୍ ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "তেওঁলোকৰ ৰেংক আৰু কাটঅফ ৰেংক প্ৰকাশ কৰিবলৈ ক্লিক কৰা:",
              "gu": "તેમના અને કપાત ગુણના ક્રમને જાહેર કરવા માટે ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Madhav's rank",
              "hi": "माधव की रैंक",
              "ka": "ಮಾಧವನ ಶ್ರೇಣಿ",
              "te": "మాధవ్ ర్యాంక్",
              "or": "ମାଧବଙ୍କ ପଦବୀ |",
              "as": "মাধৱৰ ৰেংক",
              "gu": "માધવનો ક્રમ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Kuldeep’s rank",
              "hi": "कुलदीप की रैंक",
              "ka": "ಕುಲ್ದೀಪನ ಶ್ರೇಣಿ",
              "te": "కుల్దీప్ ర్యాంక్",
              "or": "କୁଲଦୀପଙ୍କ ପଦବୀ |",
              "as": "কুলদীপৰ ৰেংক",
              "gu": "કુલદીપનો ક્રમ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Cut off rank",
              "hi": "कट ऑफ रैंक",
              "ka": "ಕಟ್-ಆಫ್ ಶ್ರೇಣಿ",
              "te": "కట్ ఆఫ్ ర్యాంక్",
              "or": "କଟ ଅଫ ରାଙ୍କ",
              "as": "কাট অফ ৰেংক",
              "gu": "કપાત ગુણ ક્રમ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "72,444",
              "hi": "72,444",
              "ka": "72,444",
              "te": "72,444",
              "or": "୭୨,୪୪୪,",
              "as": "72,444",
              "gu": "72,444"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "1,02,567",
              "hi": "1,02,567",
              "ka": "1,02,567",
              "te": "102,567",
              "or": "୧୦୨,୫୬୭",
              "as": "102,567",
              "gu": "1,02,567"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "10,845",
              "hi": "10,845",
              "ka": "10,845",
              "te": "10,845",
              "or": "୧୦,୮୪୫",
              "as": "10,845",
              "gu": "10,845"
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_3",
              "hi": "A1_3_3",
              "ka": "A1_3_3",
              "te": "A1_3_3",
              "or": "A1_3_3",
              "as": "A1_3_3",
              "gu": "A1_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Kuldeep and Madhav are devastated. Not only have they failed to qualify, but they are nowhere near the cut off rank.",
              "hi": "कुलदीप और माधव बुरी तरह व्यथित हो गए। न केवल वे योग्यता प्राप्त करने में असफल रहे हैं, लेकिन वे कट ऑफ रैंक के पास कहीं भी नहीं हैं।",
              "ka": "ಕುಲದೀಪ್ ಮತ್ತು ಮಾಧವ್ ಅತ್ಯಂತ ದಿಗ್ಬ್ರಮೆಗೊಂಡಿದ್ದಾರೆ. ಅವರು ಅರ್ಹತೆ ಪಡೆಯಲು ವಿಫಲರಾಗಿದ್ದಾರೆ ಮಾತ್ರವಲ್ಲ, ಅವರು ಕಟ್ ಆಫ್ ಶ್ರೇಣಿಯ ಸಮೀಪದಲ್ಲಿ ಕೂಡ ಇಲ್ಲ.",
              "te": "కుల్దీప్‌, మాధవ్‌లు నిరాశ చెందారు. వారు అర్హత సాధించడంలో విఫలమవ్వడమే కాకుండా, కటాఫ్ ర్యాంక్‌కు దగ్గరగా కూడా లేరు.",
              "or": "କୁଲଦୀପ ଏବଂ ମାଧବ ବିଚଳିତ ହୋଇଯାଇଛନ୍ତି। ସେମାନେ କେବଳ ଯୋଗ୍ୟତା\n  ଅର୍ଜନ କରିବାରେ ବିଫଳ ହୋଇନାହାଁନ୍ତି, ବରଂ ସେମାନେ କଟ୍ ଅଫ୍ ର୍ୟାଙ୍କ ପାଖାପାଖି ମଧ୍ୟ ନାହାଁନ୍ତି |",
              "as": "কুলদীপ আৰু মাধৱ বিধ্বস্ত হৈ পৰে। তেওঁলোকে কেৱল যোগ্যতা অৰ্জন কৰাত ব্যৰ্থ হোৱাই নহয়, তেওঁলোক কাট অফ ৰেংকৰ ওচৰে পাজৰেও নাই।",
              "gu": "કુલદીપ અને માધવ બરબાદ થઈ ગયા. તેઓ માત્ર ક્વોલિફાય કરવામાં નિષ્ફળ નથી રહ્યા, પરંતુ તેઓ કપાત ગુણની નજીક પણ નથી."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_4//",
              "hi": "A1_3_4 //",
              "ka": "A1_3_4//",
              "te": "A1_3_4//",
              "or": "A1_3_4//",
              "as": "A1_3_4//",
              "gu": "A1_3_4//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୂଲଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୂଲଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I can't do this again, and I will go back and work in the family business.",
              "hi": "मैं इसे फिर से नहीं कर सकता, और मैं वापस जाऊंगा और पारिवारिक व्यवसाय में काम करूंगा।",
              "ka": "ನಾನು ಮತ್ತೆ ಇದನ್ನು ಮುಂದುವರೆಸಲು ಸಾಧ್ಯವಿಲ್ಲ, ಮತ್ತು ನಾನು ಹಿಂತಿರುಗಿ ಕುಟುಂಬ ವ್ಯವಹಾರದಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತೇನೆ.",
              "te": "నేను దీన్ని మళ్లీ చేయలేను, నేను తిరిగి వెళ్లి కుటుంబ వ్యాపారం చూసుకుంటాను.",
              "or": "ମୁଁ ଏହା ଆଉ କରିପାରିବି ନାହିଁ, ଏବଂ ମୁଁ ଫେରି ପାରିବାରିକ ବ୍ୟବସାୟରେ \n କାମ କରିବି |",
              "as": "মই এইটো পুনৰ কৰিব নোৱাৰিম, মই ঘূৰি যাম আৰু পৰিয়ালৰ ব্যৱসায়ত কাম কৰিম।",
              "gu": "હું આ ફરીથી કરી શકતો નથી, અને હું પાછો જઈશ અને પારિવારિક વ્યવસાયમાં કામ કરીશ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "But you worked so hard! Everyone says that the first attempt is mainly to know what the exam is like, and the second attempt is when you have a better chance.",
              "hi": "लेकिन तुमने इतनी मेहनत की! हर कोई कहता है कि पहला प्रयास मुख्य रूप से यह जानना है कि परीक्षा कैसी है, और दूसरा प्रयास में तुम्हारे पास बेहतर अवसर होता है।",
              "ka": "ಆದರೆ ನೀನು ತುಂಬಾ ಶ್ರಮಿಸಿದ್ದೀಯಾ! ಮೊದಲ ಪ್ರಯತ್ನವು ಮುಖ್ಯವಾಗಿ ಪರೀಕ್ಷೆ ಹೇಗಿದೆ ಎಂದು ತಿಳಿಯಲು ಮತ್ತು ಎರಡನೇ ಪ್ರಯತ್ನವು ನಿನಗೆ ಉತ್ತಮ ಅವಕಾಶ ದೊರಕಿಸುತ್ತದೆ ಎಂದು ಎಲ್ಲರೂ ಹೇಳುತ್ತಾರೆ.",
              "te": "కానీ నువ్వు చాలా కష్టపడ్డావు! మొదటి ప్రయత్నం ప్రధానంగా పరీక్ష ఎలా ఉంటుందో తెలుసుకోవడానికి మరియు మంచి అవకాశం వచ్చినప్పుడు రెండో ప్రయత్నం చేయాలి అని అందరూ అంటున్నారు.",
              "or": "କିନ୍ତୁ ତୁମେ ବହୁତ ପରିଶ୍ରମ କରିଛ! ସମସ୍ତେ କୁହନ୍ତି ଯେ ପ୍ରଥମ ପ୍ରୟାସ \n ହେଉଛି ମୁଖ୍ୟତଃ ପରୀକ୍ଷା କ’ଣ ଜାଣିବା, ଏବଂ ଦ୍ୱିତୀୟ ପ୍ରୟାସରେ ତୁମର ଏକ ଭଲ ସୁଯୋଗ ଥାଏ |",
              "as": "কিন্তু তুমি কঠোৰ পৰিশ্ৰম কৰিছিলা! সকলোৱে কয় যে প্ৰথম প্ৰয়াসটো হৈছে মুখ্যতঃ পৰীক্ষাটো কেনেকুৱা জানিবলৈ, আৰু দ্বিতীয় প্ৰয়াসটোত তোমাৰ এটা ভাল সুযোগ থাকে।",
              "gu": "પરંતુ તેને ખૂબ મહેનત કરી! દરેક વ્યક્તિ કહે છે કે પ્રથમ પ્રયાસ મુખ્યત્વે એ જાણવાનો છે કે પરીક્ષા કેવી છે, અને બીજો પ્રયાસ એ છે કે જ્યારે તમારી પાસે વધુ સારી તક હોય."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "I don't think I can focus like this for one more year and my parents are keen that I join their business.",
              "hi": "मुझे नहीं लगता कि मैं इस तरह एक और साल के लिए ध्यान केंद्रित कर सकता हूं और मेरे माता-पिता चाहते हैं कि मैं उनके व्यवसाय में शामिल हो जाऊं।",
              "ka": "ನಾನು ಇನ್ನೂ ಒಂದು ವರ್ಷ ಈ ರೀತಿ ಕೇಂದ್ರೀಕರಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ ಎಂದು ಭಾವಿಸುತ್ತೇನೆ ಮತ್ತು ನನ್ನ ಪೋಷಕರು ನಾನು ಅವರ ವ್ಯವಹಾರಕ್ಕೆ ಸೇರಬೇಕು ಎಂದು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ.",
              "te": "నేను ఇంకొక సంవత్సరం పాటు ఇలా దృష్టి పెట్టగలనని నేను అనుకోవట్లేదు మరియు నేను వారి వ్యాపారంలో చేరాలని నా తల్లిదండ్రులు అనుకుంటున్నారు.",
              "or": "ମୁଁ ଭାବୁନାହିଁ ଯେ ମୁଁ ଆଉ ଏକ ବର୍ଷ ପାଇଁ ଏହିପରି ଧ୍ୟାନ ଦେଇପାରେ \n ଏବଂ ମୋର ପିତାମାତା ଆଗ୍ରହୀ ଯେ ମୁଁ ସେମାନଙ୍କ ବ୍ୟବସାୟରେ ଯୋଗଦେବି |",
              "as": "মই নাভাবো যে মই আৰু এবছৰৰ বাবে এনেদৰে মনোযোগ দিব পাৰিম আৰু মোৰ মা-দেউতা মই তেওঁলোকৰ ব্যৱসায়ত যোগদান কৰিবলৈ আগ্ৰহী।",
              "gu": "મને નથી લાગતું કે હું વધુ એક વર્ષ આ રીતે ધ્યાન કેન્દ્રિત કરી શકું અને મારા માતા-પિતા કહે છે કે હું તેમના વ્યવસાયમાં જોડાઉં."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "That's still a good option. Here my father wants me to send him money. How am I supposed to do that and study for an entire year?",
              "hi": "यह भी एक अच्छा विकल्प है। यहां मेरे पिता चाहते हैं कि मैं उन्हें पैसे भेजूं। मेरे लिए ऐसा करना और पूरे वर्ष के लिए अध्ययन करना कैसे संभव है?",
              "ka": "ಅದು ಒಂದು ಉತ್ತಮ ಆಯ್ಕೆಯಾಗಿದೆ. ಇಲ್ಲಿ ನನ್ನ ತಂದೆ ನಾನು ಅವರಿಗೆ ಹಣವನ್ನು ಕಳುಹಿಸಲು ಬಯಸುತ್ತಾರೆ. ನಾನು ಅದನ್ನು ಹೇಗೆ ಮಾಡಬೇಕು ಮತ್ತು ಇಡೀ ವರ್ಷ ಅಧ್ಯಯನವನ್ನು ಹೇಗೆ  ಮಾಡಬೇಕು?",
              "te": "ఇది కూడా మంచి ఎంపిక. ఇక్కడ మా నాన్న నేను అతనికి డబ్బు పంపాలనుకుంటున్నాడు. నేను అలా చేస్తూ ఒక సంవత్సరం మొత్తం ఎలా చదువుకోవాలి?",
              "or": "ତାହା ଏପର୍ଯ୍ୟନ୍ତ ଏକ ଭଲ ବିକଳ୍ପ | ଏଠାରେ ମୋ ବାପା ଚାହୁଁଛନ୍ତି ଯେ \n ମୁଁ ତାଙ୍କୁ ଟଙ୍କା ପଠାଇବି | ମୁଁ କିପରି ତାହା କରିବି ଏବଂ ଏକ ବର୍ଷ ପାଇଁ ଅଧ୍ୟୟନ କରିବି?",
              "as": "সেইটোও এটা ভাল বিকল্প। মোৰ দেউতাই বিচাৰে যে মই তেওঁলৈ টকা পঠিয়াওঁ। মই তাৰ লগত গোটেই বছৰটো অধ্যয়ন কেনেকৈ কৰিম?",
              "gu": "તે હજુ પણ સારો વિકલ્પ છે. અહીં મારા પિતા ઈચ્છે છે કે હું તેમને પૈસા મોકલું. મારે તે કેવી રીતે કરવું અને આખું વર્ષ અભ્યાસ કરવો?"
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_6////",
              "hi": "A1_3_6 ///////////",
              "ka": "A1_3_6////",
              "te": "A1_3_6////",
              "or": "A1_3_6////",
              "as": "A1_3_6////",
              "gu": "A1_3_6////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Don't bother about your father now Madhav, he hasn't cared about you all these years. You don't owe him anything.",
              "hi": "अब अपने पिता के बारे में परेशान होने की आवश्यकता नहीं है, माधव, उन्होंने इतने वर्षों में तुम्हारे बारे में परवाह नहीं की है। तुमहें उनको कुछ भी नहीं देना है।",
              "ka": "ಈಗ ನಿನ್ನ ತಂದೆಯ ಬಗ್ಗೆ ತಲೆ ಕೆಡಿಸಿಕೊಳ್ಳಬೇಡ ಮಾಧವ್, ಅವರು ಇಷ್ಟು ವರ್ಷ ನಿನ್ನ ಬಗ್ಗೆ ತಲೆಕೆಡಿಸಿಕೊಂಡಿರಲಿಲ್ಲ. ನೀನು ಅವರಿಗೆ ಋಣಿಯಾಗಿರಬೇಕಾಗಿಲ್ಲ.",
              "te": "మీ నాన్నగారి గురించి ఇప్పుడు బాధపడకు మాధవ్, ఇన్నాళ్లూ నిన్ను పట్టించుకోలేదు. నువ్వు అతనికి ఏమీ రుణపడి ఉండవు.",
              "or": "ବର୍ତ୍ତମାନ ତୁମ ବାପାଙ୍କ ବିଷୟରେ ଚିନ୍ତା କର ନାହିଁ ମାଧବ, ସେ ଏତେ\n  ବର୍ଷ ଧରି ତୁମ ବିଷୟରେ ଚିନ୍ତା କରିନାହାଁନ୍ତି | ଆପଣ ତାଙ୍କୁ କିଛି ପାଇଁ ଋଣି ନୁହଁନ୍ତି |",
              "as": "এতিয়া তোমাৰ দেউতাৰ বিষয়ে চিন্তা নকৰিবা মাধৱ, তেওঁ তোমাৰ বিষয়ে বহু বছৰ ধৰি চিন্তা কৰা নাই। তুমি তেওঁৰ ওচৰত একো ঋণী নহয়।",
              "gu": "માધવ હવે તારા પિતાની ચિંતા ન કર, આટલા વર્ષોમાં તેણે તારી પરવા કરી નથી. તું તેને કંઈપણ દેવાનો નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "And what about my finances? I also need to support myself for another year.",
              "hi": "और मेरी वित्तीय आवश्यकताओं का क्या? मुझे एक और वर्ष के लिए स्वयं की आवश्यकताओं को भी पूरा करना है।",
              "ka": "ಮತ್ತು ನನ್ನ ಹಣಕಾಸಿನ ಬಗ್ಗೆ ಏನು? ನಾನು  ಇನ್ನೊಂದು ವರ್ಷ ನನ್ನ ಬಗ್ಗೆ ಯೋಚಿಸಬೇಕು.",
              "te": "మరి నా ఆర్థిక పరిస్థితి ఏమిటి? ఇంకో సంవత్సరం నన్ను నేను చూసుకోవాలి కదా.",
              "or": "ଏବଂ ମୋର ଆର୍ଥିକ ବିଷୟରେ କ’ଣ? ମୋତେ ଆଉ ଏକ ବର୍ଷ ପାଇଁ ନିଜକୁ \n ସମର୍ଥନ କରିବାକୁ ପଡିବ |",
              "as": "আৰু মোৰ পইচা-পাতিৰ কি হ’ব? মই আন এবছৰৰ বাবে নিজকে চলাব লাগিব।",
              "gu": "અને મારી નાણાકીય બાબતોનું શું? મારે બીજા એક વર્ષ માટે પણ મારી જાતને ટેકો આપવાની જરૂર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "You don't worry about that. You concentrate on your studies. I will be earning, and I'll also support you.",
              "hi": "आप इसके बारे में चिंता न करें। आप अपने अध्ययन पर ध्यान केंद्रित करें। मैं कमाऊंगा, और मैं आपकी भी सहायता करूंगा।",
              "ka": "ನೀನು ಅದರ ಬಗ್ಗೆ ಚಿಂತಿಸಬೇಡ. ನೀನು ನಿನ್ನ ಅಧ್ಯಯನದ ಮೇಲೆ ಗಮನವನ್ನು ಕೇಂದ್ರೀಕರಿಸು. ನಾನು ಗಳಿಸುತ್ತೇನೆ ಮತ್ತು ನಾನು ನಿನ್ನನ್ನು ಬೆಂಬಲಿಸುತ್ತೇನೆ.",
              "te": "నువ్వు దాని గురించి చింతించకు. నువ్వు నీ చదువుపై దృష్టి పెట్టు. నేను సంపాదిస్తాను కదా మరియు అలానే నీకు సపోర్ట్ చేస్తాను.",
              "or": "ଆପଣ ସେ ବିଷୟରେ ଚିନ୍ତା କରନ୍ତୁ ନାହିଁ | ତୁମେ ତୁମର ଅଧ୍ୟୟନରେ \n ଧ୍ୟାନ ଦିଅ | ମୁଁ ରୋଜଗାର କରିବି, ଏବଂ ମୁଁ ମଧ୍ୟ ଆପଣଙ୍କୁ ସମର୍ଥନ କରିବି |",
              "as": "তুমি সেইবিষয়ে চিন্তা নকৰিবা। তুমি তোমাৰ অধ্যয়নত মনোনিবেশ কৰা। মই উপাৰ্জন কৰিম, আৰু মই তোমাকো সহায় কৰিম।",
              "gu": "તું તેની ચિંતા કરશ નહીં. તું તારા અભ્યાસ પર ધ્યાન આપ. હું કમાણી કરીશ, અને હું તને ટેકો પણ આપીશ."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_4_1",
              "hi": "A1_4_1",
              "ka": "A1_4_1",
              "te": "A1_4_1",
              "or": "A1_4_1",
              "as": "A1_4_1",
              "gu": "A1_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav appreciates Kuldeep's readiness to provide for him, but he isn't comfortable relying on his friends for money.",
              "hi": "माधव उसे सहायता प्रदान करने के लिए कुलदीप की तत्परता की सराहना करता है, लेकिन वह पैसे के लिए अपने दोस्तों पर निर्भर रहने में सहज नहीं है।",
              "ka": "ಮಾಧವ್ ಕುಲದೀಪ್ ತನಗೆ ಆಸರೆಯಾಗಿ ನಿಲ್ಲಲು ಸಿದ್ಧನಾಗಿರುವುದನ್ನು ಶ್ಲಾಘಿಸುತ್ತಾನೆ, ಆದರೆ ಹಣಕ್ಕಾಗಿ ತನ್ನ ಸ್ನೇಹಿತನನ್ನು ಅವಲಂಬಿಸುವುದು ಅವನಿಗೆಸರಿ ಎನಿಸುವುದಿಲ್ಲ.",
              "te": "మాధవ్ కుల్దీప్ తనకు సహాయం అందించడానికి సిద్ధంగా ఉన్నందుకు మెచ్చుకున్నాడు, కానీ డబ్బు కోసం తన స్నేహితులపై ఆధారపడటం అతనికి సౌకర్యంగా లేదు.",
              "or": "ମାଧବ ତାଙ୍କୁ ସାହାଯ୍ୟ ପାଇଁ କୁଲଦୀପଙ୍କ ପ୍ରସ୍ତୁତିକୁ ପ୍ରଶଂସା କରନ୍ତି, କିନ୍ତୁ\n  ଟଙ୍କା ପାଇଁ ତାଙ୍କ ସାଙ୍ଗମାନଙ୍କ ଉପରେ ନିର୍ଭର କରିବାକୁ ସେ ଆରାମଦାୟକ ନୁହଁନ୍ତି |",
              "as": "মাধৱে কুলদীপে তেওঁক সহায় কৰিবলৈ প্ৰস্তুত হোৱাৰ বাবে প্ৰশংসা কৰে, কিন্তু তেওঁ ধনৰ বাবে তেওঁৰ বন্ধুবৰ্গৰ ওপৰত নিৰ্ভৰশীল হ’বলৈ নিবিচাৰে।",
              "gu": "માધવ તેના માટે કુલદીપની તત્પરતાની પ્રશંસા કરે છે, પરંતુ તે પૈસા માટે તેના મિત્રો પર આધાર રાખવામાં માગતો નથી."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_4_2",
              "hi": "A1_4_2",
              "ka": "A1_4_2",
              "te": "A1_4_2",
              "or": "A1_4_2",
              "as": "A1_4_2",
              "gu": "A1_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "On the other hand, if he decides to take up a part-time job, he doubts he will get enough time to study.",
              "hi": "दूसरी तरफ, यदि वह अंशकालिक नौकरी करने का फैसला करता है, तो उसे संदेह है कि अध्ययन करने के लिए उसे पर्याप्त समय मिलेगा।",
              "ka": "ಮತ್ತೊಂದೆಡೆ, ಅವನು ಅರೆಕಾಲಿಕ ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ನಿರ್ಧರಿಸಿದಲ್ಲಿ, ತಾನು ಅಧ್ಯಯನ ಮಾಡಲು ಸಾಕಷ್ಟು ಸಮಯವನ್ನು ಪಡೆಯುವುದು ಅನುಮಾನವೆನಿಸುತ್ತದೆ. ",
              "te": "మరోవైపు, అతను పార్ట్ టైమ్ ఉద్యోగం చేయాలని నిర్ణయించుకుంటే, అతను చదువుకోవడానికి తగినంత సమయం దొరుకుతుందా అని సందేహం.",
              "or": "ଅନ୍ୟ ପଟେ, ଯଦି ସେ ପାର୍ଟ ଟାଇମ୍ ଚାକିରି କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି, ତେବେ \n ସେ ଅଧ୍ୟୟନ ପାଇଁ ପର୍ଯ୍ୟାପ୍ତ ସମୟ ପାଇବେ ବୋଲି ସନ୍ଦେହ କରୁଛନ୍ତି |",
              "as": "আনহাতে, যদিহে তেওঁ আংশিক সময়ৰ চাকৰি এটা লোৱাৰ সিদ্ধান্ত লয়, তেন্তে তেওঁ অধ্যয়ন কৰিবলৈ পৰ্যাপ্ত সময় নাপাব বুলি সন্দেহ কৰে।",
              "gu": "બીજી બાજુ, જો તે અડધો સમય તે નોકરી કરવાનું નક્કી કરે છે, તો તેને શંકા છે કે તેને અભ્યાસ કરવા માટે પૂરતો સમય મળશે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_4_3_D",
              "hi": "A1_4_3_D",
              "ka": "A1_4_3_D",
              "te": "A1_4_3_D",
              "or": "A1_4_3_D",
              "as": "A1_4_3_D",
              "gu": "A1_4_3_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Madhav do?",
              "hi": "माधव को क्या करना चाहिए?",
              "ka": "ಮಾಧವ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "మాధవ్ ఏం చేయాలి?",
              "or": "ମାଧବ କ’ଣ କରିବା ଉଚିତ୍?",
              "as": "মাধৱে কি কৰা উচিত?",
              "gu": "માધવે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Take up a part-time job and manage his finances",
              "hi": "एक अंशकालिक नौकरी ले ले और अपने वित्त का प्रबंधन करे ",
              "ka": "ಅರೆಕಾಲಿಕ ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳುವುದು ಮತ್ತು ಅವನ ಹಣಕಾಸು ನಿರ್ವಹಣೆಯನ್ನು ಮಾಡಿಕೊಳ್ಳುವುದೇ",
              "te": "పార్ట్ టైమ్ ఉద్యోగాన్ని చేపట్టి అతని ఆర్థిక వ్యవహారాలు చూసుకోవాలి",
              "or": "ଏକ ପାର୍ଟ ଟାଇମ୍ ଚାକିରି ନିଅ ଏବଂ ତାଙ୍କ ଆର୍ଥିକ ପରିଚାଳନା କର |",
              "as": "আংশিক সময়ৰ চাকৰি এটা ল’ব আৰু তাৰ বিত্তীয় পৰিচালনা কৰিব",
              "gu": "અડધો સમય નોકરી કરે અને તેની નાણાકીય વ્યવસ્થા કરે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Study with full focus to appear for his second attempt",
              "hi": "अपने दूसरे प्रयास में उपस्थित होने के लिए पूरे ध्यान के साथ अध्ययन करे",
              "ka": "ತನ್ನ ಎರಡನೇ ಪ್ರಯತ್ನದಲ್ಲಿ ಪೂರ್ಣ ಗಮನದಲ್ಲಿಟ್ಟು ಅಧ್ಯಯನ ಮಾಡುವುದೇ",
              "te": "తన రెండవ ప్రయత్నానికి పూర్తి దృష్టితో చదవాలి",
              "or": "ତାଙ୍କର ଦ୍ୱିତୀୟ ପ୍ରୟାସ ପାଇଁ ଦେଖାଯିବା ପାଇଁ ପୂର୍ଣ୍ଣ ଧ୍ୟାନ ସହିତ ଅଧ୍ୟୟନ କରନ୍ତୁ |",
              "as": "তেওঁৰ দ্বিতীয় প্ৰচেষ্টাৰ বাবে সম্পূৰ্ণ মনোযোগেৰে অধ্যয়ন কৰিব",
              "gu": "તેના બીજા પ્રયાસમાં દેખાવ કરવા માટે સંપૂર્ણ ધ્યાન સાથે અભ્યાસ કરો"
            }
          }
        ]
      },
      "scene11": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(i)_1_1",
              "hi": "A2 (i) _1_1",
              "ka": "A2(i)_1_1",
              "te": "A2(i)_1_1",
              "or": "A2(i)_1_1",
              "as": "A2(i)_1_1",
              "gu": "A2(i)_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to take up a part-time job. This time he goes to a Civil Services coaching centre.",
              "hi": "माधव ने अंशकालिक नौकरी लेने का निश्चय किया। इस बार वह एक सिविल सेवा कोचिंग सेंटर में जाता है।",
              "ka": "ಮಾಧವ್ ಅರೆಕಾಲಿಕ ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಈ ಬಾರಿ ಅವನು ಸಿವಿಲ್ ಸರ್ವಿಸಸ್ ಕೋಚಿಂಗ್ ಸೆಂಟರ್‌ಗೆ ಹೋಗುತ್ತಾನೆ",
              "te": "మాధవ్ పార్ట్ టైమ్ ఉద్యోగం చేయాలని నిర్ణయించుకున్నాడు. ఈసారి సివిల్ సర్వీసెస్ కోచింగ్ సెంటర్‌కి వెళ్లాడు.",
              "or": "ମାଧବ ଏକ ପାର୍ଟ ଟାଇମ୍ ଚାକିରି କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଥାଏ | ଏଥର ସେ\n  ଏକ ସିଭିଲ ସର୍ଭିସେସ୍ କୋଚିଂ ସେଣ୍ଟରକୁ ଯାଆନ୍ତି |",
              "as": "মাধৱে এটা আংশিক সময়ৰ চাকৰি লোৱাৰ সিদ্ধান্ত লয়। এইবাৰ তেওঁ এখন অসামৰিক সেৱাৰ প্ৰশিক্ষণ কেন্দ্ৰলৈ যায়।",
              "gu": "માધવે અડધો સમય નોકરી કરવાનું નક્કી કર્યું. આ વખતે તે સિવિલ સર્વિસ કોચિંગ સેન્ટરમાં જાય છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(i)_1_2",
              "hi": "A2 (i) _1_2",
              "ka": "A2(i)_1_2",
              "te": "A2(i)_1_2",
              "or": "A2(i)_1_2",
              "as": "A2(i)_1_2",
              "gu": "A2(i)_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "With the money he earns, he can support himself as well as send some to his father.",
              "hi": "जो वेतन वह कमाता है, उससे वह खुद का खर्च उठा सकता है और साथ ही कुछ अपने पिता को भेज सकता है।",
              "ka": "ತಾನು ದುಡಿದ ಹಣದಲ್ಲಿ ತನ್ನನ್ನು ಸಾಕಿಕೊಳ್ಳುವುದರ ಜೊತೆಗೆ ಒಂದಿಷ್ಟು ಹಣವನ್ನು ತಂದೆಗೆ ಕೂಡ ಕಳುಹಿಸುತ್ತಾನೆ.",
              "te": "తను సంపాదించిన డబ్బుతో తన పోషణతో పాటు తండ్రికి కూడా కొంత పంపవచ్చు.",
              "or": "ସେ ରୋଜଗାର କରୁଥିବା ଟଙ୍କା ସହିତ ସେ ନିଜକୁ ସମର୍ଥନ କରିବା ସହିତ\n  କିଛି ପିତାଙ୍କ ନିକଟକୁ ପଠାଇ ପାରିବେ |",
              "as": "তেওঁ উপাৰ্জন কৰা ধনেৰে, তেওঁ নিজৰ ভৰণপোষণ কৰাৰ লগতে কিছু টকা তেওঁৰ দেউতাকলৈও পঠাব পাৰিব।",
              "gu": "તે જે પૈસા કમાય છે તેનાથી તે પોતાનું ભરણપોષણ કરી શકે છે અને થોડાક તેના પિતાને પણ મોકલી આપે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(i)_1_3",
              "hi": "A2 (i) _1_3",
              "ka": "A2(i)_1_3",
              "te": "A2(i)_1_3",
              "or": "A2(i)_1_3",
              "as": "A2(i)_1_3",
              "gu": "A2(i)_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "At the centre, he works at the administration department. His job involves enrolling new applicants, documenting their records, and filing their exam papers.",
              "hi": "केंद्र में, वह प्रशासन विभाग में काम करता है। उनके काम में नए आवेदकों क नामांकन करना, उनके रिकॉर्ड का दस्तावेजीकरण करना और उनके परीक्षा पत्र दाखिल करना शामिल है।",
              "ka": "ಆ ಕೇಂದ್ರದಲ್ಲಿ, ಅವನು ಆಡಳಿತ ವಿಭಾಗದಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಾನೆ. ಅವನ ಕೆಲಸವು ಹೊಸ ಅರ್ಜಿದಾರರನ್ನು ದಾಖಲಿಸುವುದು, ಅವರ ದಾಖಲೆಗಳನ್ನು ದಾಖಲಿಸುವುದು ಮತ್ತು ಅವರ ಪರೀಕ್ಷೆಯ ಪತ್ರಿಕೆಗಳನ್ನು ಸಲ್ಲಿಸುವುದನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.",
              "te": "సెంటర్ లో, అతను అడ్మినిస్ట్రేషన్ విభాగంలో పనిచేస్తున్నాడు. అతని ఉద్యోగంలో కొత్త అప్లికెంట్స్ డీటేల్స్ నమోదు చేయడం, వారి రికార్డులను డాక్యుమెంట్ చేయడం మరియు వారి పరీక్ష పత్రాలను ఫైల్ చేయడం వంటివి ఉంటాయి.",
              "or": "କେନ୍ଦ୍ରରେ ସେ ପ୍ରଶାସନ ବିଭାଗରେ କାର୍ଯ୍ୟ କରନ୍ତି। ତାଙ୍କର ଚାକିରି ନୂତନ\n  ଆବେଦନକାରୀଙ୍କୁ ନାମ ଲେଖାଇବା, ସେମାନଙ୍କର ରେକର୍ଡକୁ ଦଲିଲ କରିବା ଏବଂ ସେମାନଙ୍କର ପରୀକ୍ଷା କାଗଜପତ୍ର ଦାଖଲ କରିବା ଏହାର ଅନ୍ତର୍ଭୁକ୍ତ |",
              "as": "কেন্দ্ৰটোত, তেওঁ প্ৰশাসন বিভাগত কাম কৰে। তেওঁৰ চাকৰিৰ কাম হ’ল নতুন আৱেদনকাৰীৰ নাম ভৰ্তি কৰা, তেওঁলোকৰ ৰেকৰ্ড নথিভুক্ত কৰা, আৰু তেওঁলোকৰ পৰীক্ষাৰ প্ৰশ্নকাকত দাখিল কৰা।",
              "gu": "સેંટરમાં, તે વહીવટ વિભાગમાં કામ કરે છે. તેમની નોકરીમાં નવા અરજદારોની નોંધણી, તેમના રેકોર્ડનું દસ્તાવેજીકરણ અને તેમના પરીક્ષા પેપર્સ ફાઇલ કરવા સામેલ છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(i)_1_4",
              "hi": "A2 (i) _1_4",
              "ka": "A2(i)_1_4",
              "te": "A2(i)_1_4",
              "or": "A2(i)_1_4",
              "as": "A2(i)_1_4",
              "gu": "A2(i)_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He is happy with the job as he gets guidance from the teachers and the students whenever he cannot understand something.",
              "hi": "वह नौकरी से खुश है क्योंकि जब भी उसे कुछ समझ नहीं आता है तो उसे शिक्षकों और छात्रों से मार्गदर्शन मिलता है।",
              "ka": "ಏನನ್ನಾದರೂ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಸಾಧ್ಯವಾಗದಿದ್ದಾಗ ಶಿಕ್ಷಕರು ಮತ್ತು ವಿದ್ಯಾರ್ಥಿಗಳಿಂದ ಮಾರ್ಗದರ್ಶನ ಪಡೆಯುವುದರಿಂದ ಅವನು ಕೆಲಸದಲ್ಲಿ ಸಂತೋಷದಿಂದ ಇದ್ದಾನೆ.",
              "te": "అతనికి ఏదైనా అర్థం కానప్పుడు ఉపాధ్యాయులు మరియు విద్యార్థుల నుండి గైడెన్స్ పొందడంతో అతను ఉద్యోగంతో సంతోషంగా ఉన్నాడు.",
              "or": "ଯେତେବେଳେ ସେ କିଛି ବୁଝିପାରୁନାହାଁନ୍ତି ଶିକ୍ଷକ ଓ ଛାତ୍ରମାନଙ୍କଠାରୁ \n ମାର୍ଗଦର୍ଶନ ପାଇ ସେ ଚାକିରିରେ ଖୁସି ହୁଅନ୍ତି |",
              "as": "তেওঁ কামটোত সুখী কিয়নো তেওঁ যেতিয়াই কিবা বুজি নাপায় তেতিয়াই শিক্ষক আৰু শিক্ষাৰ্থীৰ পৰা নিৰ্দেশনা পায়।",
              "gu": "તે નોકરીથી ખુશ છે કારણ કે જ્યારે પણ તે કંઈક સમજી શકતો નથી ત્યારે તેને શિક્ષકો અને વિદ્યાર્થીઓનું માર્ગદર્શન મળે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(i)_2_1",
              "hi": "A2 (i) _2_1",
              "ka": "A2(i)_2_1",
              "te": "A2(i)_2_1",
              "or": "A2(i)_2_1",
              "as": "A2(i)_2_1",
              "gu": "A2(i)_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He studies whenever he gets time through the day. He is also allowed to attend some of their extra sessions where teachers solve doubts for students.",
              "hi": "जब भी उसे दिन में समय मिलता है तब वह अध्ययन करता है। उन्हें अपने कुछ अतिरिक्त सत्रों में भाग लेने की भी अनुमति है जहां शिक्षक छात्रों की समस्या को  हल करते हैं।",
              "ka": "ಅವನು ದಿನವಿಡೀ ಸಮಯ ಸಿಕ್ಕಾಗಲೆಲ್ಲಾ ಓದುತ್ತಾನೆ. ಶಿಕ್ಷಕರು ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಸಂದೇಹಗಳನ್ನು ಪರಿಹರಿಸುವ ಕೆಲವು ಹೆಚ್ಚುವರಿ ಸೆಷನ್‌ಗಳಿಗೆ ಹಾಜರಾಗಲು ಸಹ ಅವನಿಗೆ ಅನುಮತಿಸಲಾಗಿದೆ.",
              "te": "రోజులో సమయం దొరికినప్పుడల్లా చదువుకునేవాడు. ఉపాధ్యాయులు విద్యార్థుల సందేహాలను పరిష్కరించే వారి అదనపు సెషన్‌లలో కొన్నింటికి హాజరు కావడానికి కూడా అతను అనుమతించబడ్డాడు.",
              "or": "ଯେତେବେଳେ ସେ ଦିନସାରା ସମୟ ପାଆନ୍ତି ସେ ଅଧ୍ୟୟନ କରନ୍ତି | \n ତାଙ୍କୁ ସେମାନଙ୍କର କିଛି ଅତିରିକ୍ତ ଅଧିବେଶନରେ ଯୋଗଦେବାକୁ ମଧ୍ୟ ଅନୁମତି ଦିଆଯାଇଛି ଯେଉଁଠାରେ ଶିକ୍ଷକମାନେ ଛାତ୍ରମାନଙ୍କ ପାଇଁ ସନ୍ଦେହର ସମାଧାନ କରନ୍ତି |",
              "as": "দিনটোত যেতিয়াই তেওঁ সময় পায় তেতিয়াই পঢ়ে। তেওঁক তেওঁলোকৰ কিছুমান অতিৰিক্ত অধিৱেশনত উপস্থিত থাকিবলৈ অনুমতি দিয়া হৈছে য'ত শিক্ষকসকলে শিক্ষাৰ্থীসকলৰ সন্দেহবোৰ সমাধান কৰে।",
              "gu": "દિવસભર જ્યારે પણ તેને સમય મળે ત્યારે તે અભ્યાસ કરે છે. તેમને તેમના કેટલાક વધારાના સત્રોમાં હાજરી આપવાની પણ છૂટ છે જ્યાં શિક્ષકો વિદ્યાર્થીઓની પ્રશ્નોનું નિરાકરણ કરે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(i)_2_2",
              "hi": "A2 (i) _2_2",
              "ka": "A2(i)_2_2",
              "te": "A2(i)_2_2",
              "or": "A2(i)_2_2",
              "as": "A2(i)_2_2",
              "gu": "A2(i)_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Seeing other students, he makes a self-learning plan for himself.",
              "hi": "अन्य छात्रों को देखते हुए, वह स्वयं के लिए एक आत्म-शिक्षण योजना बनाता है।",
              "ka": "ಇತರ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ನೋಡಿ, ಅವನು ತನ್ನ ಸ್ವಂತ ಕಲಿಕೆಯ ಯೋಜನೆಯನ್ನು ರೂಪಿಸುತ್ತಾನೆ.",
              "te": "ఇతర విద్యార్థులను చూసి తన స్వంతంగా ఒక ప్లాన్ వేసుకుంటాడు.",
              "or": "ଅନ୍ୟ ଛାତ୍ରମାନଙ୍କୁ ଦେଖି ସେ ନିଜ ପାଇଁ ଏକ ଆତ୍ମ-ଶିକ୍ଷା ଯୋଜନା\n  ପ୍ରସ୍ତୁତ କରନ୍ତି |",
              "as": "আন শিক্ষাৰ্থীসকলক দেখি, তেওঁ নিজৰ বাবে এক স্ব-শিক্ষণ পৰিকল্পনা প্ৰস্তুত কৰে।",
              "gu": "અન્ય વિદ્યાર્થીઓને જોઈને તે પોતાના માટે સ્વ-શિક્ષણ યોજના બનાવે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(i)_2_3(reveal)",
              "hi": "A2 (i) _2_3 (प्रकट)",
              "ka": "A2(i)_2_3(reveal)",
              "te": "A2(i)_2_3(రివీల్)",
              "or": "A2 (i) _2_3 (ପ୍ରକାଶ)",
              "as": "A2(i)_2_3(প্ৰকাশ)",
              "gu": "A2(i)_2_3(જાહેર કરો)"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click here to see the 4 step plan:",
              "hi": "4 चरण योजना देखने के लिए यहां क्लिक करें:",
              "ka": "4 ಹಂತದ ಯೋಜನೆಯನ್ನು ವೀಕ್ಷಿಸಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ maadi",
              "te": "4 దశల ప్లాన్ ను చూడటానికి ఇక్కడ క్లిక్ చేయండి:",
              "or": "4 ଟି ଷ୍ଟେପ୍ ପ୍ଲାନ୍ ଦେଖିବାକୁ ଏଠାରେ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "4 টা পদক্ষেপৰ পৰিকল্পনা চাবলৈ ইয়াত ক্লিক কৰা:",
              "gu": "4 સ્ટેપ યોજના જોવા માટે અહીં ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Step 1: Understand exam details",
              "hi": "चरण 1: परीक्षा विवरण को समझे",
              "ka": "ಹಂತ 1: ",
              "te": "దశ 1: పరీక్ష వివరాలను అర్థం చేసుకోవడం",
              "or": "ପଦାଙ୍କ 1: ପରୀକ୍ଷା ବିବରଣୀ ବୁଝନ୍ତୁ |",
              "as": "পদক্ষেপ 1: পৰীক্ষাৰ বিৱৰণ বুজি লোৱা",
              "gu": "પગલું 1: પરીક્ષાની વિગતો સમજો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Step 2: Build foundation",
              "hi": "चरण 2: फाउंडेशन बनाए",
              "ka": "ಹಂತ 2:",
              "te": "దశ 2: పునాదిని నిర్మించడం",
              "or": "ପଦାଙ୍କ 2: ଭିତ୍ତିଭୂମି ନିର୍ମାଣ କରନ୍ତୁ |",
              "as": "পদক্ষেপ 2: ভেটি নিৰ্মাণ কৰা",
              "gu": "પગલું 2: પાયો બનાવો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Step 3: Upgrade knowledge",
              "hi": "चरण 3: ज्ञान को अपग्रेड करे",
              "ka": "ಹಂತ 3:",
              "te": "దశ 3: జ్ఞానాన్ని అప్‌గ్రేడ్ చేయడం",
              "or": "ପଦାଙ୍କ 3: ଜ୍ଞାନକୁ ନବୀକରଣ କରନ୍ତୁ |",
              "as": "পদক্ষেপ 3: জ্ঞান বঢ়োৱা",
              "gu": "પગલું 3: જ્ઞાનમાં બદલાવ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "Step 4: Practice with mock-test",
              "hi": "चरण 4:  मॉक/ नकली परीक्षण के साथ अभ्यास करे",
              "ka": "ಹಂತ 4:",
              "te": "దశ 4: మాక్-టెస్ట్ తో ప్రాక్టీస్ చేయడం",
              "or": "ପଦାଙ୍କ 4: ମକ୍-ଟେଷ୍ଟ ସହିତ ଅଭ୍ୟାସ କରନ୍ତୁ |",
              "as": "পদক্ষেপ 4: মক-পৰীক্ষাৰ সৈতে অনুশীলন কৰা",
              "gu": "પગલું 4: કુત્રિમ પરીક્ષા સાથે પ્રેક્ટિસ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Collect all the details of both preliminary and main exams like the exam dates, exam pattern, time duration, and assessment framework.",
              "hi": "प्रारंभिक और मुख्य परीक्षा दोनों के सभी विवरण जैसे परीक्षा तिथियों, परीक्षा पैटर्न, समय अवधि, और मूल्यांकन ढांचा एकत्र करे।",
              "ka": "ಪರೀಕ್ಷೆಯ ದಿನಾಂಕಗಳು, ಪರೀಕ್ಷೆಯ ಮಾದರಿ, ಸಮಯದ ಅವಧಿ ಮತ್ತು ಮೌಲ್ಯಮಾಪನ ಚೌಕಟ್ಟಿನಂತಹ ಪ್ರಾಥಮಿಕ ಮತ್ತು ಮುಖ್ಯ ಪರೀಕ್ಷೆಗಳ ಎಲ್ಲಾ ವಿವರಗಳನ್ನು ಸಂಗ್ರಹಿಸುವುದು.",
              "te": "పరీక్ష తేదీలు, పరీక్షా విధానం, సమయ వ్యవధి మరియు మూల్యాంకన ఫ్రేమ్‌వర్క్ వంటి ప్రిలిమినరీ మరియు మెయిన్ పరీక్షల యొక్క అన్ని వివరాలను సేకరించాలి.",
              "or": "ଉଭୟ ପ୍ରାଥମିକ ଏବଂ ମୁଖ୍ୟ ପରୀକ୍ଷାଗୁଡ଼ିକର ସମସ୍ତ ବିବରଣୀ ସଂଗ୍ରହ\n  କରନ୍ତୁ ଯେପରି ପରୀକ୍ଷା ତାରିଖ, ପରୀକ୍ଷା ଢାଞ୍ଚା, ସମୟ ଅବଧି, ଏବଂ ମୂଲ୍ୟାଙ୍କନ ଢାଞ୍ଚା |",
              "as": "প্ৰাথমিক আৰু মুখ্য দুয়োটা পৰীক্ষাৰ সকলো বিৱৰণ সংগ্ৰহ কৰা যেনে পৰীক্ষাৰ তাৰিখ, পৰীক্ষাৰ আৰ্হি, সময়ৰ ম্যাদ, আৰু মূল্যায়ন প্ৰণালী।",
              "gu": "પ્રારંભિક અને મુખ્ય બંને પરીક્ષાઓની તમામ વિગતો જેમ કે પરીક્ષાની તારીખો, પરીક્ષાની પેટર્ન, સમય અવધિ અને મૂલ્યાંકન માળખું એકત્રિત કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Understand the basics of core UPSC subjects. Download all the NCERT books from the website to study. Read all the textbooks twice to be ready.",
              "hi": "कोर यूपीएससी विषयों की मूल बातों को समझे। अध्ययन के लिए वेबसाइट से सभी एनसीईआरटी किताबें डाउनलोड करे। तैयारी के लिए दो बार सभी पाठ्यपुस्तकों को पढ़े।",
              "ka": "ಮುಖ್ಯ UPSC ವಿಷಯಗಳ ಮೂಲಭೂತ ಅಂಶಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು. ಅಧ್ಯಯನ ಮಾಡಲು ವೆಬ್‌ಸೈಟ್‌ನಿಂದ ಎಲ್ಲಾ NCERT ಪುಸ್ತಕಗಳನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿಕೊಳ್ಳುವುದು. ಸಿದ್ಧವಾಗಲು ಎಲ್ಲಾ ಪಠ್ಯಪುಸ್ತಕಗಳನ್ನು ಎರಡು ಬಾರಿ ಓದುವುದು.",
              "te": "ప్రధాన UPSC సబ్జెక్టుల బేసిక్స్ ను అర్థం చేసుకోవడం. చదవడానికి వెబ్‌సైట్ నుండి అన్ని NCERT పుస్తకాలను డౌన్‌లోడ్ చేయండి. రెడీగా ఉండటానికి అన్ని పుస్తకాలను రెండుసార్లు చదవాలి.",
              "or": "ମୂଳ UPSC ବିଷୟଗୁଡ଼ିକର ମୌଳିକତା ବୁଝନ୍ତୁ | ଅଧ୍ୟୟନ ପାଇଁ ୱେବସାଇଟରୁ \n ସମସ୍ତ NCERT ପୁସ୍ତକ ଡାଉନଲୋଡ୍ କରନ୍ତୁ | ପ୍ରସ୍ତୁତ ହେବାକୁ ସମସ୍ତ ପାଠ୍ୟପୁସ୍ତକ ଦୁଇଥର ପଢନ୍ତୁ |",
              "as": "মূল UPSC বিষয়বোৰৰ মৌলিক বিষয়বোৰ বুজি লোৱা। অধ্যয়ন কৰিবলৈ ৱেবছাইটৰ পৰা NCERTৰ সকলো কিতাপ ডাউনলোড কৰা। সাজু হ'বলৈ সকলো পাঠ্যপুথি দুবাৰকৈ পঢ়া।",
              "gu": "મુખ્ય UPSC વિષયોની મૂળભૂત બાબતોને સમજો. અભ્યાસ માટે વેબસાઇટ પરથી તમામ NCERT પુસ્તકો ડાઉનલોડ કરો. તૈયાર થવા માટે તમામ પાઠ્યપુસ્તકો બે વાર વાંચો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "After understanding the core subjects, read other books from the public library to upgrade knowledge. Also, study through free apps.",
              "hi": "कोर विषयों को समझने के बाद, ज्ञान में अभिवृद्धि के लिए सार्वजनिक पुस्तकालय से अन्य पुस्तकें पढ़े। इसके अलावा, मुफ्त ऐप्स के माध्यम से अध्ययन करे।",
              "ka": "ಮುಖ್ಯ ವಿಷಯಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಂಡ ನಂತರ, ಜ್ಞಾನವನ್ನು ನವೀಕರಿಸಲು ಸಾರ್ವಜನಿಕ ಗ್ರಂಥಾಲಯದಿಂದ ಇತರ ಪುಸ್ತಕಗಳನ್ನು ಓದುವುದು. ಅಲ್ಲದೆ, ಉಚಿತ ಅಪ್ಲಿಕೇಶನ್‌ಗಳ ಮೂಲಕ ಅಧ್ಯಯನ ಮಾಡುವುದು",
              "te": "కోర్ సబ్జెక్టులను అర్థం చేసుకున్న తర్వాత, నాలెడ్జ్ అప్‌గ్రేడ్ చేయడానికి పబ్లిక్ లైబ్రరీ నుండి ఇతర పుస్తకాలను చదవాలి. అలాగే, ఉచిత యాప్‌ల ద్వారా చదవాలి.",
              "or": "ମୂଳ ବିଷୟଗୁଡିକ ବୁଝିବା ପରେ, ଜ୍ଞାନକୁ ନବୀକରଣ \n କରିବାକୁ ସର୍ବସାଧାରଣ ଲାଇବ୍ରେରୀରୁ ଅନ୍ୟ ପୁସ୍ତକ ପଢ| ମାଗଣା ଆପ୍ ମାଧ୍ୟମରେ ମଧ୍ୟ ଅଧ୍ୟୟନ କରନ୍ତୁ |",
              "as": "মূল বিষয়বোৰ বুজি পোৱাৰ পিছত, জ্ঞানৰ পৰিসৰ বঢ়াবলৈ ৰাজহুৱা পুথিভঁৰালৰ পৰা অনা কিতাপ পঢ়া। লগতে, বিনামূলীয়া এপ্পৰ জৰিয়তে অধ্যয়ন কৰা।",
              "gu": "મુખ્ય વિષયોને સમજ્યા પછી, જ્ઞાનમાં સુધારો કરવા માટે જાહેર પુસ્તકાલયમાંથી અન્ય પુસ્તકો વાંચો. ઉપરાંત, મફત એપ્લિકેશન દ્વારા અભ્યાસ કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "To help with the preliminary and main exam, take mock tests after preparing for 7-8 months.",
              "hi": " 7-8 महीने की तैयारी के बाद मॉक/ नकली परीक्षण ले, जिससे प्रारंभिक और मुख्य परीक्षा में सहायता  मिलेगी  ।",
              "ka": "ಪೂರ್ವಭಾವಿ ಮತ್ತು ಮುಖ್ಯ ಪರೀಕ್ಷೆಗೆ ಸಹಾಯ ಮಾಡಲು, 7-8 ತಿಂಗಳ ತಯಾರಿ ನಂತರ ಅಣಕು ಪರೀಕ್ಷೆಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುವುದು",
              "te": "ప్రిలిమినరీ మరియు మెయిన్ ఎగ్జామ్‌లో సహాయం చేయడానికి, 7-8 నెలల పాటు ప్రిపేర్ అయిన తర్వాత మాక్ టెస్ట్ లను రాయాలి.",
              "or": "ପ୍ରାଥମିକ ଏବଂ ମୁଖ୍ୟ ପରୀକ୍ଷାରେ ସାହାଯ୍ୟ କରିବାକୁ, 7-8 ମାସ ପାଇଁ\n  ପ୍ରସ୍ତୁତ ହେବା ପରେ ମକ୍ ଟେଷ୍ଟ୍ ନିଅ |",
              "as": "প্ৰাথমিক আৰু মুখ্য পৰীক্ষাত সহায় হ’বলৈ, ৭-৮ মাহৰ বাবে প্ৰস্তুতি কৰাৰ পিছত মক পৰীক্ষা দিবা।",
              "gu": "પ્રારંભિક અને મુખ્ય પરીક્ષામાં મદદ કરવા માટે, 7-8 મહિનાની તૈયારી કર્યા પછી કુત્રિમ પરીક્ષા આપો."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(i)_2_4",
              "hi": "A2 (i) _2_4",
              "ka": "A2(i)_2_4",
              "te": "A2(i)_2_4",
              "or": "A2(i)_2_4",
              "as": "A2(i)_2_4",
              "gu": "A2(i)_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav starts following the plan diligently.",
              "hi": "माधव योजना का परिश्रमपूर्वक अनुसरण करना शुरू करता है।",
              "ka": "ಮಾಧವ್ ಯೋಜನೆಯನ್ನು ಶ್ರದ್ಧೆಯಿಂದ ಅನುಸರಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ",
              "te": "మాధవ్ ప్రణాళికను శ్రద్ధగా అనుసరించడం ప్రారంభించాడు.",
              "or": "ମାଧବ ଯତ୍ନର ସହିତ ଯୋଜନା ଅନୁସରଣ କରିବା ଆରମ୍ଭ କରେ |",
              "as": "মাধৱে পৰিকল্পনাটো নিষ্ঠাৰে অনুসৰণ কৰিবলৈ আৰম্ভ কৰে।",
              "gu": "માધવ યોજનાને ચપળતાથી અનુસરવાનું શરૂ કરે છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(i)_3_1",
              "hi": "A2 (i) _3_1",
              "ka": "A2(i)_3_1",
              "te": "A2(i)_3_1",
              "or": "A2(i)_3_1",
              "as": "A2(i)_3_1",
              "gu": "A2(i)_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Meanwhile, Kuldeep has moved to his family home. Only Madhav and Badri share the room now.",
              "hi": "इस बीच, कुलदीप परिवार के पास अपने घर चला गया है। केवल माधव और बद्री अब कमरे को साझा करते हैं।",
              "ka": "ಈ ಮಧ್ಯದಲ್ಲಿ, ಕುಲದೀಪ್ ತನ್ನ ಕುಟುಂಬದ ಮನೆಗೆ ತೆರಳಿದ್ದಾನೆ. ಮಾಧವ್ ಮತ್ತು ಬದ್ರಿ ಮಾತ್ರ ಈಗ ಕೊಠಡಿಯನ್ನು ಹಂಚಿಕೊಂಡಿದ್ದಾರೆ.",
              "te": "ఇంతలో, కుల్దీప్ తన ఇంటికి వెళ్ళాడు. ఇప్పుడు మాధవ్ మరియు బద్రి మాత్రమే గదిలో ఉంటున్నారు.",
              "or": "ଏହି ସମୟରେ କୁଲଦୀପ ତାଙ୍କ ପରିବାର ଘରକୁ ଯାଇଛନ୍ତି। କେବଳ\n  ମାଧବ ଏବଂ ବଦ୍ରି ବର୍ତ୍ତମାନ ରୁମ୍ ଅଂଶୀଦାର କରନ୍ତି |",
              "as": "ইফালে, কুলদীপ তেওঁৰ পৰিয়ালৰ ঘৰলৈ গুচি যায়। কেৱল মাধৱ আৰু বদ্ৰীয়ে এতিয়া কোঠাটো ভাগ-বতৰা কৰে।",
              "gu": "તે દરમિયાન, કુલદીપ તેના પરિવારના ઘરે રહેવા ગયો હતો. હવે માત્ર માધવ અને બદ્રી જ રૂમમાં રહે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(i)_3_2",
              "hi": "A2 (i) _3_2",
              "ka": "A2(i)_3_2",
              "te": "A2(i)_3_2",
              "or": "A2(i)_3_2",
              "as": "A2(i)_3_2",
              "gu": "A2(i)_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Badri is concerned for Madhav. One day when Kuldeep comes to visit them...",
              "hi": "बद्री माधव के लिए चिंतित है। एक दिन जब कुलदीप उनके पास मिलने आता है ...",
              "ka": "ಬದರಿಗೆ ಮಾಧವ್ ಬಗ್ಗೆ ಕಾಳಜಿ ಇದೆ. ಒಂದು ದಿನ ಕುಲದೀಪ್ ಅವರನ್ನು ಭೇಟಿ ಮಾಡಲು ಬಂದಾಗ...",
              "te": "బద్రి మాధవ్ గురించి ఆందోళన చెందాడు. ఒకరోజు కుల్దీప్ వాళ్ళని పరామర్శించడానికి వచ్చినప్పుడు...",
              "or": "ବଦ୍ରି ମାଧବଙ୍କ ପାଇଁ ଚିନ୍ତିତ | ଦିନେ ଯେତେବେଳେ କୁଲଦୀପ ସେମାନଙ୍କୁ\n \n  ଦେଖା କରିବାକୁ ଆସନ୍ତି ...",
              "as": "বদ্ৰী মাধৱৰ বাবে চিন্তিত। এদিন যেতিয়া কুলদীপ তেওঁলোকক লগ কৰিবলৈ আহে...",
              "gu": "બદ્રીને માધવની ચિંતા છે. એક દિવસ જ્યારે કુલદીપ તેમને મળવા આવ્યો..."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(i)_3_3//",
              "hi": "A2 (i) _3_3 //",
              "ka": "A2(i)_3_3//",
              "te": "A2(i)_3_3//",
              "or": "A2(i)_3_3//",
              "as": "A2(i)_3_3//",
              "gu": "A2(i)_3_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୁଳଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Madhav, you look so pale! Have you been sleeping at all?",
              "hi": "माधव, तुम बीमार दिखाई दे रहे हो! क्या तुम बिलकुल नहीं सो रहे हो?",
              "ka": "ಮಾಧವ್, ನೀನು ತುಂಬಾ ಬಿಳಿಚಿಕೊಂಡಂತೆ ಕಾಣುತ್ತೀಯಾ! ನೀನು ಸರಿಯಾಗಿ ನಿದ್ದೆ ಮಾಡುತ್ತಿದ್ದೀಯಾ?",
              "te": "మాధవ్, నువ్వు చాలా పాలిపోయినట్లు కనిపిస్తున్నావు! నువ్వు అసలు నిద్ర పోతున్నావా?",
              "or": "ମାଧବ, ତୁମେ ଏତେ ଫିକା ଦେଖାଯାଉଛ! ତୁମେ ଆଦ sleep ଶୋଇଛ କି?",
              "as": "মাধৱ, তোমাক ইমান শেঁতা পৰা যেন লাগিছে! তুমি শুইছানে?",
              "gu": "માધવ, તું સાવ નિસ્તેજ લાગે છે! શું તમે બિલકુલ સૂઈ ગયા છો?"
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(i)_3_4////",
              "hi": "A2 (i) _3_4 ////////",
              "ka": "A2(i)_3_4////",
              "te": "A2(i)_3_4////",
              "or": "A2(i)_3_4////",
              "as": "A2(i)_3_4////",
              "gu": "A2(i)_3_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Madhav is silent.",
              "hi": "माधव चुप है।",
              "ka": "ಮಾಧವ್ ಮೌನವಾಗಿದ್ದನು.",
              "te": "మాధవ్ మౌనంగా ఉన్నాడు.",
              "or": "ମାଧବ ଚୁପ୍ ରହିଛନ୍ତି।",
              "as": "মাধৱ নীৰৱ।",
              "gu": "માધવ મૌન છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "He hasn't slept properly for four days. I keep telling him to relax a little, take breaks now and then, but he doesn't listen.",
              "hi": "वह चार दिनों के लिए ठीक से नहीं सोया है। मैं उसे थोड़ा आराम करने के लिए कहता हूं, थोड़ी थोड़ी देर में ब्रेक लेने के लिए कहता हूँ , लेकिन वह नहीं सुनता है।",
              "ka": "ನಾಲ್ಕು ದಿನಗಳಿಂದ ಸರಿಯಾಗಿ ನಿದ್ದೆ ಮಾಡಿಲ್ಲ. ಸ್ವಲ್ಪ ರಿಲ್ಯಾಕ್ಸ್ ಮಾಡಿಕೋ, ಆಗೊಮ್ಮೆ ಈಗೊಮ್ಮೆ ಬಿಡುವು ಮಾಡಿಕೋ ಎಂದು ಹೇಳುತ್ತಲೇ ಇದ್ದೇನೆ, ಆದರೆ ಅವನು ಕೇಳುತ್ತಿಲ್ಲ.",
              "te": "అతను నాలుగు రోజులుగా సరిగ్గా నిద్రపోలేదు. కొంచెం రిలాక్స్ అవ్వమని, అప్పుడప్పుడు బ్రేక్ తీసుకోమని చెబుతూనే ఉన్నాను, అతను వినడు.",
              "or": "ସେ ଚାରି ଦିନ ଧରି ସଠିକ୍ ଶୋଇ ନାହାଁନ୍ତି | ମୁଁ ତାଙ୍କୁ ଟିକିଏ ଆରାମ\n  କରିବାକୁ କହୁଛି, ବର୍ତ୍ତମାନ ଏବଂ ପରେ ବିରତି ନିଅ, କିନ୍ତୁ ସେ ଶୁଣନ୍ତି ନାହିଁ |",
              "as": "সি চাৰি দিন ধৰি ভালদৰে শুৱা নাই। মই তাক অলপ আৰাম কৰিবলৈ, অকমান বিৰতি ল'বলৈ বাৰে বাৰে কৈ থাকো, কিন্তু সি নুশুনে।",
              "gu": "ચાર દિવસથી તે બરાબર ઉંઘી નથી. હું તેને કહું છું કે થોડો આરામ કરો, હવે પછી બ્રેક લો, પણ તે સાંભળતો નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "What are you doing to yourself, Madhav? I told you not to get the job. How will you do well if you don't sleep?",
              "hi": "तुम अपने साथ क्या कर रहे हो माधव? मैंने तुमसे कहा था कि नौकरी मत करो। अगर तुम सोए नहीं तो तुम अच्छा कैसे करोगे?",
              "ka": "ನಿನಗೆ ನೀನು ಏನು ಮಾಡಿಕೊಳ್ಳುತ್ತಿದ್ದೀಯ ಮಾಧವ್? ನಿನಗೆ ಕೆಲಸ ಮಾಡಬೇಡ ಅಂತ ಹೇಳಿದ್ದೆ. ನೀನು ನಿದ್ದೆ ಮಾಡದಿದ್ದರೆ ಹೇಗೆ ಚೆನ್ನಾಗಿ ಮಾಡುತ್ತೀಯಾ?",
              "te": "నువ్వేం చేస్తున్నావు మాధవ్? నీకు ఉద్యోగం చేయకు అని చెప్పాను. నువ్వు నిద్రపోకపోతే పరీక్ష బాగా రాయగలవా?",
              "or": "ତୁମେ ନିଜେ କଣ କରୁଛ, ମାଧବ? ମୁଁ ତୁମକୁ କହିଲି ଚାକିରି ପାଇବ ନାହିଁ |\n  ଯଦି ଆପଣ ଶୋଇପାରୁନାହାଁନ୍ତି ତେବେ ଆପଣ କିପରି ଭଲ କରିବେ?",
              "as": "মাধৱ, তুমি নিজৰ লগত কি কৰি আছা? মই তোমাক চাকৰিটো নকৰিবলৈ কৈছিলোঁ। যদি তুমি নোশুৱা তেন্তে কাম কেনেদৰে ভালকৈ কৰিবা?",
              "gu": "માધવ, તું તારું શું કરે છે? મેં તને કહ્યું હતું કે નોકરી નહીં મળે. જો તમને ઊંઘ ન આવે તો તમે કેવી રીતે સારું કરશો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I'm okay, don't worry. The exam is almost here. After the exam, I'll sleep as much as I want.",
              "hi": "मैं ठीक हूँ, चिंता मत करो। परीक्षा लगभग आ ही गई है। परीक्षा के बाद, मैं जितना चाहूं उतना सोऊंगा।",
              "ka": "ನಾನು ಚೆನ್ನಾಗಿದ್ದೇನೆ, ಚಿಂತಿಸಬೇಡ. ಪರೀಕ್ಷೆ ಬಹುತೇಕ ಹತ್ತಿರದಲ್ಲಿದೆ. ಪರೀಕ್ಷೆಯ ನಂತರ, ನಾನು ಎಷ್ಟು ಬೇಕಾದರೂ ಮಲಗುತ್ತೇನೆ.",
              "te": "నేను బాగానే ఉన్నాను, చింతించకు. పరీక్ష దాదాపు వచ్చేసింది. ఎగ్జామ్ అయ్యాక నాకు కావాల్సినంత నిద్రపోతాను.",
              "or": "ମୁଁ ଠିକ ଅଛି, ଚିନ୍ତା କର ନାହିଁ | ପରୀକ୍ଷା ପ୍ରାୟ ପାଖରେ ଅଛି | ପରୀକ୍ଷା\n  ପରେ, ମୁଁ ଯେତିକି ଚାହିଁବି ଶୋଇବି |",
              "as": "মই ঠিকে আছো, চিন্তা নকৰিবা। পৰীক্ষাটো প্ৰায় আহি পাইছে। পৰীক্ষাৰ পিছত, মই যিমান বিচাৰো শুম।",
              "gu": "હું ઠીક છું, ચિંતા કરશો નહીં. પરીક્ષા લગભગ આવી ગઈ છે. પરીક્ષા પછી, હું ઈચ્છું તેટલી ઊંઘ લઈશ."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(i)_4_1",
              "hi": "A2 (i) _4_1",
              "ka": "A2(i)_4_1",
              "te": "A2(i)_4_1",
              "or": "A2(i)_4_1",
              "as": "A2(i)_4_1",
              "gu": "A2(i)_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav pushes himself to study for longer hours every day. He feels like the other students at the centre are always ahead of him.",
              "hi": "माधव खुद को हर दिन लंबे समय तक अध्ययन करने के लिए प्रेरित करता है। वह अनुभव करता है कि केंद्र के अन्य छात्र हमेशा उसके आगे हैं।",
              "ka": "ಮಾಧವ್ ಪ್ರತಿದಿನ ಹೆಚ್ಚು ಗಂಟೆಗಳ ಕಾಲ ಅಧ್ಯಯನ ಮಾಡಲು ತನ್ನನ್ನು ತಾನು ಮುನ್ನುಗ್ಗಿಸಿಕೊಳ್ಳುತ್ತಾನೆ. ಕೇಂದ್ರದಲ್ಲಿರುವ ಇತರ ವಿದ್ಯಾರ್ಥಿಗಳು ಯಾವಾಗಲೂ ತನಗಿಂತ ಮುಂದಿರುವಂತೆ ಅವನು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "రోజూ ఎక్కువ గంటలు చదువుకోవాలని మాధవ్‌ ఒత్తిడి పెంచుకునేవాడు. సెంటర్‌లోని ఇతర విద్యార్థులు ఎల్లప్పుడూ తన కంటే ముందున్నట్లు అతను భావిస్తాడు.",
              "or": "ମାଧବ ପ୍ରତିଦିନ ଅଧିକ ଘଣ୍ଟା ଅଧ୍ୟୟନ କରିବାକୁ ନିଜକୁ ଉସ୍ଚ୍ଚାହିତ କରନ୍ତି | \n ସେ ଅନୁଭବ କରୁଛନ୍ତି ଯେ କେନ୍ଦ୍ରର ଅନ୍ୟ ଛାତ୍ରମାନେ ସର୍ବଦା ତାଙ୍କଠାରୁ ଆଗରେ ଅଛନ୍ତି |",
              "as": "মাধৱে নিজকে প্ৰতিদিনে দীঘলীয়া সময়ৰ বাবে পঢ়িবলৈ হেঁচা দিয়ে। সি অনুভৱ কৰে যেন কেন্দ্ৰৰ আন শিক্ষাৰ্থীসকল সদায় তাতকৈ আগবাঢ়ি আছে।",
              "gu": "માધવ દરરોજ લાંબા સમય સુધી અભ્યાસ કરવા માટે દબાણ કરે છે. તેને લાગે છે કે કેન્દ્રના અન્ય વિદ્યાર્થીઓ હંમેશા તેની આગળ હોય છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(i)_4_2",
              "hi": "A2 (i) _4_2",
              "ka": "A2(i)_4_2",
              "te": "A2(i)_4_2",
              "or": "A2(i)_4_2",
              "as": "A2(i)_4_2",
              "gu": "A2(i)_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He somehow manages to appear for the preliminary exam one more time.",
              "hi": "वह किसी तरह एक बार फिर प्रारंभिक परीक्षा में बैठने में सफल हो जाता है।",
              "ka": "ಇನ್ನೊಮ್ಮೆ ಪ್ರಿಲಿಮಿನರಿ ಪರೀಕ್ಷೆಗೆ ಹೇಗೋ ಹಾಜರಾಗುತ್ತಾನೆ.",
              "te": "అతను ఏదో ఒక విధంగా ఇంకోసారి ప్రిలిమినరీ పరీక్షకు హాజరయ్యాడు.",
              "or": "ସେ କୌଣସି ପ୍ରକାରେ ପ୍ରାଥମିକ ପରୀକ୍ଷା ପାଇଁ ଆଉ ଥରେ ହାଜର ହେବାକୁ\n  ପରିଚାଳନା କରନ୍ତି |",
              "as": "তেওঁ কিবাকৈ প্ৰাথমিক পৰীক্ষাত আৰু এবাৰ অৱতীৰ্ণ হ'বলৈ সক্ষম হয়।",
              "gu": "તે કોઈક રીતે પ્રિલિમિનરી પરીક્ષા માટે વધુ એક વખત હાજર રહેવાનું વ્યવસ્થાપિત કરે છે."
            }
          }
        ]
      },
      "scene12": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(ii)_1_1",
              "hi": "A2 (II) _1_1",
              "ka": "A2(ii)_1_1",
              "te": "A2(ii)_1_1",
              "or": "A2(ii)_1_1",
              "as": "A2(ii)_1_1",
              "gu": "A2(ii)_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to focus on his studies for another year without having the burden of earning for himself. But he is worried.",
              "hi": "माधव खुद के लिए कमाई का भार लिए बिना एक और साल के लिए अपनी पढ़ाई पर ध्यान केंद्रित करने का फैसला करता है। लेकिन वह चिंतित है",
              "ka": "ಮಾಧವ್ ತನಗಾಗಿ ಸಂಪಾದನೆಯ ಹೊರೆಯನ್ನು ಹೊಂದದೆ ಇನ್ನೂ ಒಂದು ವರ್ಷ ತನ್ನ ಅಧ್ಯಯನದತ್ತ ಗಮನ ಹರಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಆದರೆ ಅವನು ಚಿಂತಿತನಾಗಿದ್ದಾನೆ",
              "te": "తన సంపాదన భారం లేకుండా మరో ఏడాది చదువుపై దృష్టి పెట్టాలని నిర్ణయించుకున్నాడు మాధవ్. కానీ అతను ఆందోళన చెందుతున్నాడు.",
              "or": "ମାଧବ ନିଜ ପାଇଁ ରୋଜଗାରର ଭାର ନପାଇ ଅନ୍ୟ ଏକ ବର୍ଷ ପାଇଁ ଅଧ୍ୟୟନ\n  ଉପରେ ଧ୍ୟାନ ଦେବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | କିନ୍ତୁ ସେ ଚିନ୍ତିତ ଅଛନ୍ତି।",
              "as": "মাধৱে নিজৰ বাবে উপাৰ্জনৰ বোজা নোহোৱাকৈ আৰু এবছৰৰ বাবে পঢ়াশুনাত মনোনিবেশ কৰাৰ সিদ্ধান্ত লয়। কিন্তু তেওঁ চিন্তিত।",
              "gu": "માધવ પોતાના માટે કમાણીનો બોજ રાખ્યા વિના બીજા એક વર્ષ માટે તેના અભ્યાસ પર ધ્યાન કેન્દ્રિત કરવાનું નક્કી કરે છે. પરંતુ તે ચિંતિત છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(ii)_1_2",
              "hi": "A2 (II) _1_2",
              "ka": "A2(ii)_1_2",
              "te": "A2(ii)_1_2",
              "or": "A2(ii)_1_2",
              "as": "A2(ii)_1_2",
              "gu": "A2(ii)_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He sees all of his other friends get into different courses and jobs. Even Badri has completed his 1st year of B.Ed.",
              "hi": "वह देखता है कि उसके अन्य सभी मित्र अलग-अलग पाठ्यक्रमों और नौकरियों में लग गए हैं। बद्री ने भी बीएड का पहला साल पूरा कर लिया है।",
              "ka": "ಅವನು ತನ್ನ ಎಲ್ಲಾ ಸ್ನೇಹಿತರು ವಿವಿಧ ಕೋರ್ಸ್‌ಗಳು ಮತ್ತು ಉದ್ಯೋಗಗಳಲ್ಲಿ ತೊಡಗಿಸಿಕೊಳ್ಳುವುದನ್ನು ನೋಡುತ್ತಾನೆ. ಬದರಿ ಕೂಡ ತನ್ನ 1ನೇ ವರ್ಷದ ಬಿ.ಎಡ್ ಮುಗಿಸಿದ್ದಾನೆ.",
              "te": "అతను తన ఇతర స్నేహితులందరూ వేర్వేరు కోర్సులు మరియు ఉద్యోగాలు చేయడం చూస్తాడు. బద్రి కూడా B.Ed 1వ సంవత్సరం పూర్తి చేసాడు.",
              "or": "ସେ ଦେଖନ୍ତି ତାଙ୍କର ଅନ୍ୟ ସମସ୍ତ ସାଙ୍ଗମାନେ ବିଭିନ୍ନ ପାଠ୍ୟକ୍ରମ ଏବଂ\n  ଚାକିରିରେ ପ୍ରବେଶ କରନ୍ତି | ଏପରିକି ବଦ୍ରି ବି.ଡି.ର ପ୍ରଥମ ବର୍ଷ ପୂରଣ କରିଛନ୍ତି |",
              "as": "তেওঁ তেওঁৰ আন সকলো বন্ধুক বিভিন্ন পাঠ্যক্ৰম আৰু চাকৰিত সোমাই থকা দেখিছে। আনকি বদ্ৰীয়েও তাৰ B.Edৰ ১ম বছৰ সম্পূৰ্ণ কৰিলে।",
              "gu": "તે તેના અન્ય મિત્રોને જુદા જુદા અભ્યાસક્રમો અને નોકરીઓમાં પ્રવેશતા જુએ છે. બદ્રીએ પણ બીએડનું 1 વર્ષ પૂરું કર્યું છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(ii)_1_3",
              "hi": "A2 (II) _1_3",
              "ka": "A2(ii)_1_3",
              "te": "A2(ii)_1_3",
              "or": "A2(ii)_1_3",
              "as": "A2(ii)_1_3",
              "gu": "A2(ii)_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "One day, when Kuldeep and Madhav return to their room, Badri notices that Madhav looks upset.",
              "hi": "एक दिन, जब कुलदीप और माधव अपने कमरे में लौट कर आए, बद्री ने देखा कि माधव परेशान लग रहा है।",
              "ka": "ಒಂದು ದಿನ, ಕುಲದೀಪ್ ಮತ್ತು ಮಾಧವ್ ತಮ್ಮ ಕೋಣೆಗೆ ಹಿಂತಿರುಗಿದಾಗ, ಮಾಧವ್ ಅಸಮಾಧಾನಗೊಂಡಿರುವುದನ್ನು ಬದ್ರಿ ಗಮನಿಸುತ್ತಾನೆ.",
              "te": "ఒక రోజు, కుల్దీప్ మరియు మాధవ్ తమ గదికి తిరిగి వచ్చినప్పుడు, మాధవ్ అప్సెట్ గా ఉండడం బద్రి గమనించాడు.",
              "or": "ଦିନେ, ଯେତେବେଳେ କୁଲଦୀପ ଏବଂ ମାଧବ ନିଜ କୋଠରୀକୁ ଫେରନ୍ତି,\n  ବଦ୍ରି ଦେଖିଲେ ଯେ ମାଧବ ବିରକ୍ତ ଦେଖାଯାଉଛନ୍ତି |",
              "as": "এদিন, যেতিয়া কুলদীপ আৰু মাধৱ তেওঁলোকৰ কোঠালৈ উভতি আহে, বদ্ৰীয়ে লক্ষ্য কৰে যে মাধৱ বিচলিত হৈ আছে।",
              "gu": "એક દિવસ, જ્યારે કુલદીપ અને માધવ તેમના રૂમમાં પાછા ફર્યા, ત્યારે બદ્રીએ જોયું કે માધવ અસ્વસ્થ દેખાય છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(ii)_1_4//",
              "hi": "A2 (ii) _1_4 //",
              "ka": "A2(ii)_1_4//",
              "te": "A2(ii)_1_4//",
              "or": "A2(ii)_1_4//",
              "as": "A2(ii)_1_4//",
              "gu": "A2(ii)_1_4//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Badri",
              "hi": "बद्री",
              "ka": "ಬದ್ರಿ",
              "te": "బద్రి",
              "or": "ବଦ୍ରି",
              "as": "বদ্ৰী",
              "gu": "બદ્રી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୁଳଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "What happened Madhav, why are you so upset?",
              "hi": "माधव क्या हुआ, तुम इतने परेशान क्यों हो?",
              "ka": "ಏನಾಯ್ತು ಮಾಧವ್, ಯಾಕೆ ಇಷ್ಟೊಂದು ತಲೆ ಕೆಡಿಸಿಕೊಂಡಿದ್ದೀಯ?",
              "te": "ఏమైంది మాధవ్, ఎందుకు అంత కంగారు పడుతున్నావు?",
              "or": "ମାଧବ କ’ଣ ହେଲା, ତୁମେ ଏତେ ବିରକ୍ତ କାହିଁକି?",
              "as": "কি হ'ল মাধৱ, তুমি ইমান বিচলিত কিয়?",
              "gu": "શું થયું માધવ, તું આટલો અસ્થવ્યસ્ત કેમ લાગે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "What else do you think it can be, Badri? The poor fellow is going at it again! For another year.",
              "hi": "तुमको क्या लगता है कि क्या हो सकता है? यह फिर से उसी राह पर जा रहा है! एक और वर्ष के लिए।",
              "ka": "ಇನ್ನೇನು ಆಗಬಹುದು ಬದ್ರಿ? ಇವನು ಮತ್ತೆ ಅದರತ್ತ ಹೋಗುತ್ತಿದ್ದಾನೆ! ಇನ್ನೊಂದು ವರ್ಷ.",
              "te": "ఇంకేముందని అనుకుంటున్నావు బద్రి? పాపం వాడు మళ్ళీ దాని వైపు వెళ్తున్నాడు! మరో సంవత్సరానికి.",
              "or": "ବଦ୍ରି ଆଉ କ’ଣ ହୋଇପାରେ ବୋଲି ତୁମେ ଭାବୁଛ? ଗରିବ ଲୋକଟି\n  ପୁଣି ଥରେ ଯାଉଛି! ଆଉ ଏକ ବର୍ଷ ପାଇଁ |",
              "as": "বদ্ৰী, তুমি আৰু কি হ'ব পাৰে বুলি ভাবা? বেচেৰা পুনৰ ইয়ালৈকে গৈ আছে! আন এবছৰৰ বাবে।",
              "gu": "તમને લાગે છે કે તે બીજું શું હોઈ શકે, બદ્રી? ગરીબ મિત્ર ફરી તેના પર જઈ રહ્યો છે! બીજા વર્ષ માટે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(ii)_1_5////",
              "hi": "A2 (ii) _1_5 ////////",
              "ka": "A2(ii)_1_5////",
              "te": "A2(ii)_1_5////",
              "or": "A2(ii)_1_5////",
              "as": "A2(ii)_1_5////",
              "gu": "A2(ii)_1_5////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I felt confident when I decided to study another year, but I feel like it's going to be the same thing all over again. No matter how much I prepare, I won't make it.",
              "hi": "जब मैंने एक और वर्ष अध्ययन करने का निर्णय लिया, मैंने आत्मविश्वास का अनुभव किया, लेकिन मुझे लगता है कि यह फिर से वही चीज होने जा रही है। इससे कोई अंतर नहीं पड़ता कि मैं कितनी तैयारी करता हूं, मैं इसे नहीं कर पाऊंगा।",
              "ka": "ನಾನು ಇನ್ನೊಂದು ವರ್ಷ ಅಧ್ಯಯನ ಮಾಡಲು ನಿರ್ಧರಿಸಿದಾಗ ನನಗೆ ಆತ್ಮವಿಶ್ವಾಸವಿತ್ತು, ಆದರೆ ಅದು ಮತ್ತೆ ಅದೇ ಆಗುತ್ತದೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ಎಷ್ಟೇ ತಯಾರು ಮಾಡಿದ್ರೂ ನನಗೆ ಮಾಡೋಕ್ಕೆ ಆಗುತ್ತಾ ಇಲ್ಲ.",
              "te": "నేను మరొక సంవత్సరం చదువుకోవాలని నిర్ణయించుకున్నప్పుడు నాకు నమ్మకంగా అనిపించింది, కానీ మళ్లీ అలానే జరుగుతుందని అనిపిస్తుంది. ఎంత ప్రిపేర్ అయినా కూడా నాకు రాదేమో.",
              "or": "ଯେତେବେଳେ ମୁଁ ଅନ୍ୟ ଏକ ବର୍ଷ ଅଧ୍ୟୟନ କରିବାକୁ ସ୍ଥିର କଲି, ସେତେବେଳେ \n ମୁଁ ଆତ୍ମବିଶ୍ଵାସୀ ଅନୁଭବ କଲି, କିନ୍ତୁ ମୋତେ ଲାଗୁଛି ଯେ ଏହା ପୁଣି ସମାନ ଜିନିଷ ହେବ | ମୁଁ ଯେତେ ପ୍ରସ୍ତୁତ ହେଉନା କାହିଁକି, ମୁଁ ଏହାକୁ ପ୍ରସ୍ତୁତ କରିବି ନାହିଁ |",
              "as": "যেতিয়া মই আন এবছৰ অধ্যয়ন কৰাৰ সিদ্ধান্ত লৈছিলোঁ তেতিয়া মই আত্মবিশ্বাসী আছিলোঁ, কিন্তু মই অনুভৱ কৰোঁ যে এইটো পুনৰ একে কথাই হ'ব। মই যিমানেই প্ৰস্তুত নহও, মই ইয়াক কৰিব নোৱাৰিম।",
              "gu": "જ્યારે મેં બીજા વર્ષનો અભ્યાસ કરવાનું નક્કી કર્યું ત્યારે મને આત્મવિશ્વાસ અનુભવાયો, પરંતુ મને લાગે છે કે તે ફરીથી એ જ વસ્તુ બનશે. હું ગમે તેટલી તૈયારી કરી શકું મને કઈ પડી નથી, હું તેને નહીં કરી શકીશ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I think we studied a lot, but we didn't study smartly. This time you need to have a better plan and strategy.",
              "hi": "मुझे लगता है कि हमने बहुत अध्ययन किया, लेकिन हमने बुद्धिमानी से अध्ययन नहीं किया। इस बार तुमको एक बेहतर योजना और रणनीति की आवश्यकता है।",
              "ka": "ನಾವು ಬಹಳಷ್ಟು ಅಧ್ಯಯನ ಮಾಡಿದ್ದೇವೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ, ಆದರೆ ನಾವು ಬುದ್ಧಿವಂತಿಕೆಯಿಂದ ಅಧ್ಯಯನ ಮಾಡಲಿಲ್ಲ. ಈ ಸಮಯದಲ್ಲಿ ನೀನು ಉತ್ತಮ ಯೋಜನೆ ಮತ್ತು ತಂತ್ರವನ್ನು ಹೊಂದಿರಬೇಕು.",
              "te": "మనం చాలా చదివాము, కానీ స్మార్ట్ గా చదువుకోలేదు. ఈసారి నువ్వు ఇంకా మంచి ప్లాన్ తయారు చేసుకోవాలి.",
              "or": "ମୁଁ ଭାବୁଛି ଆମେ ବହୁତ ଅଧ୍ୟୟନ କରିଛୁ, କିନ୍ତୁ ଆମେ ସ୍ମାର୍ଟ ଭାବରେ \n ଅଧ୍ୟୟନ କରିନାହୁଁ | ଏଥର ତୁମର ଏକ ଉତ୍ତମ ଯୋଜନା ଏବଂ ରଣନୀତି ରହିବା ଆବଶ୍ୟକ |",
              "as": "মই ভাবো আমি বহুত পঢ়িছিলোঁ, কিন্তু আমি চতুৰতাৰে অধ্যয়ন কৰা নাছিলোঁ। এইবাৰ তোমাৰ এটা উন্নত পৰিকল্পনা আৰু কৌশল থাকিব লাগিব।",
              "gu": "મને લાગે છે કે આપણે ઘણો અભ્યાસ કર્યો છે, પરંતુ આપણે ચતુરાઈથી અભ્યાસ કર્યો નથી. આ સમયે તારી પાસે વધુ સારી યોજના અને વ્યૂહરચના હોવી જરૂરી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I agree, Madhav. Don't lose hope so soon, and you haven't even started preparing yet. Trust me, with a good plan, you will feel more confident.",
              "hi": "मैं सहमत हूं, माधव। जल्द ही निराश मत होना, और तुमने अभी तक तैयारी शुरू नहीं की है।  मुझ पर विश्वास करो, एक अच्छी योजना के साथ, तुम अधिक आत्मविश्वास का अनुभव करोगे।",
              "ka": "ಒಪ್ಪುತ್ತೇನೆ ಮಾಧವ್. ಇಷ್ಟು ಬೇಗ ಭರವಸೆ ಕಳೆದುಕೊಳ್ಳಬೇಡ ಮತ್ತು ನೀನು ಇನ್ನೂ ತಯಾರಿಯನ್ನು ಪ್ರಾರಂಭಿಸಿಲ್ಲ. ನನ್ನನ್ನು ನಂಬು ಉತ್ತಮ ಯೋಜನೆಯೊಂದಿಗೆ, ನೀನು ಹೆಚ್ಚು ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಹೊಂದುತ್ತೀಯಾ.",
              "te": "నేను అంగీకరిస్తున్నాను మాధవ్. అంత త్వరగా ఆశ కోల్పోవద్దు మరియు నువ్వు ఇంకా ప్రిపరేషన్ ప్రారంభించలేదు. నన్ను నమ్ము, మంచి ప్లాన్ తో, నువ్వు మరింత నమ్మకంగా ఉండవచ్చు.",
              "or": "ମୁଁ ବୁଝୁଛି, ମାଧବ | ଏତେ ଶୀଘ୍ର ଆଶା ହରାନ୍ତୁ ନାହିଁ, ଏବଂ ଆପଣ ଏପର୍ଯ୍ୟନ୍ତ\n  ପ୍ରସ୍ତୁତି ଆରମ୍ଭ କରିନାହାଁନ୍ତି | ଏକ ଭଲ ଯୋଜନା ସହିତ ମୋତେ ବିଶ୍ୱାସ କର, ତୁମେ ଅଧିକ ଆତ୍ମବିଶ୍ୱାସ ଅନୁଭବ କରିବ |",
              "as": "মই সন্মত, মাধৱ। ইমান সোনকালে আশা হেৰুৱাব নালাগে, আৰু তুমি এতিয়ালৈকে প্ৰস্তুতি আৰম্ভই কৰা নাই। বিশ্বাস কৰা, এটা ভাল পৰিকল্পনাৰ সৈতে, তুমি অধিক আত্মবিশ্বাসী অনুভৱ কৰিবা।",
              "gu": "હું સંમત છું, માધવ. આટલી જલદી આશા ગુમાવશ નહીં, અને તે હજી તૈયારી પણ શરૂ કરી નથી. મારા પર વિશ્વાસ કર, સારી યોજના સાથે, તમે વધુ આત્મવિશ્વાસ અનુભવીશ."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(ii)_1_6",
              "hi": "A2 (ii) _1_6",
              "ka": "A2(ii)_1_6",
              "te": "A2(ii)_1_6",
              "or": "A2(ii)_1_6",
              "as": "A2(ii)_1_6",
              "gu": "A2(ii)_1_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After talking to his friends, Madhav comes up with a 4 step plan to prepare for his second attempt.",
              "hi": "अपने मित्रों से बात करने के बाद, माधव अपने दूसरे प्रयास की तैयारी के लिए एक 4 चरण योजना बनाता है।",
              "ka": "ತನ್ನ ಸ್ನೇಹಿತರೊಂದಿಗೆ ಮಾತನಾಡಿದ ನಂತರ, ಮಾಧವ್ ತನ್ನ ಎರಡನೇ ಪ್ರಯತ್ನಕ್ಕೆ ತಯಾರಿ ಮಾಡಲು 4 ಹಂತದ ಯೋಜನೆಯನ್ನು ರೂಪಿಸುತ್ತಾನೆ.",
              "te": "తన స్నేహితులతో మాట్లాడిన తర్వాత, మాధవ్ తన రెండవ ప్రయత్నానికి సిద్ధం కావడానికి 4 దశల ప్లాన్ తయారు చేసుకుంటాడు.",
              "or": "ନିଜ ସାଙ୍ଗମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରିବା ପରେ, ମାଧବ ତାଙ୍କର ଦ୍ୱିତୀୟ\n  ପ୍ରୟାସ ପାଇଁ ପ୍ରସ୍ତୁତି ପାଇଁ ୪ ସୋପାନ ବିଶିଷ୍ଟ ଯୋଜନା ପ୍ରସ୍ତୁତ କରନ୍ତି |",
              "as": "বন্ধুবৰ্গৰ সৈতে কথা পতাৰ পিছত, মাধৱে তেওঁৰ দ্বিতীয় প্ৰচেষ্টাৰ প্ৰস্তুতিৰ বাবে এটা 4 টা পদক্ষেপৰ পৰিকল্পনা লৈ আহে।",
              "gu": "તેના મિત્રો સાથે વાત કર્યા પછી, માધવ તેના બીજા પ્રયાસની તૈયારી માટે 4 સ્ટેપ્સની યોજના સાથે આવે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(ii)_2_1(reveal)",
              "hi": "A2 (ii) _2_1 (रिवील)",
              "ka": "A2(ii)_2_1(reveal)",
              "te": "A2(ii)_2_1(రివీల్)",
              "or": "A2(ii)_2_1(reveal)",
              "as": "A2(ii)_2_1(প্ৰকাশ)",
              "gu": "A2(ii)_2_1(જાહેર કરો)"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click here to see the 4 step plan:",
              "hi": "4 चरण योजना देखने के लिए यहां क्लिक करें:",
              "ka": "4 ಹಂತದ ಯೋಜನೆಯನ್ನು ವೀಕ್ಷಿಸಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ maadi",
              "te": "4 దశల ప్లాన్ ను చూడటానికి ఇక్కడ క్లిక్ చేయండి:",
              "or": "4 ଟି ଷ୍ଟେପ୍ ପ୍ଲାନ୍ ଦେଖିବାକୁ ଏଠାରେ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "4 টা পদক্ষেপৰ পৰিকল্পনা চাবলৈ ইয়াত ক্লিক কৰা:",
              "gu": "4 રીતની યોજના જોવા માટે અહીં ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Step 1: Understand the exam",
              "hi": "चरण 1: परीक्षा को समझे",
              "ka": "ಹಂತ 1: ",
              "te": "దశ 1: పరీక్షను అర్థం చేసుకోండి",
              "or": "ପଦକ୍ଷେପ 1: ପରୀକ୍ଷାକୁ ବୁଝ ନ୍ତୁ |",
              "as": "পদক্ষেপ 1: পৰীক্ষাৰ বিৱৰণ বুজি লোৱা",
              "gu": "પગલું 1: પરીક્ષાને સમજો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Step 2:Build foundation",
              "hi": "चरण 2: फाउंडेशन/ आधार बनाए",
              "ka": "ಹಂತ 2:",
              "te": "దశ 2: పునాదిని నిర్మించండి",
              "or": "ପଦାଙ୍କ 2: ଭିତ୍ତିଭୂମି ନିର୍ମାଣ କରନ୍ତୁ |",
              "as": "পদক্ষেপ 2: ভেটি নিৰ্মাণ কৰা",
              "gu": "પગલું 2: પાયો બનાવો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Step 3: Upgrade knowledge",
              "hi": "चरण 3: ज्ञान में अभिवृद्धि करे",
              "ka": "ಹಂತ 3:",
              "te": "దశ 3: జ్ఞానాన్ని అప్‌గ్రేడ్ చేయండి",
              "or": "ପଦାଙ୍କ 3: ଜ୍ଞାନକୁ ନବୀକରଣ କରନ୍ତୁ |",
              "as": "পদক্ষেপ 3: জ্ঞান বঢ়োৱা",
              "gu": "પગલું 3: જ્ઞાનમાં બદલાવ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "Step 4: Practice with take mock-test",
              "hi": "चरण 4:  मॉक/ नकली परीक्षण के साथ अभ्यास करे",
              "ka": "ಹಂತ 4:",
              "te": "దశ 4: మాక్-టెస్ట్‌తో ప్రాక్టీస్ చేయండి",
              "or": "ଷ୍ଟେପ୍ 4: ମକ୍-ଟେଷ୍ଟ ସହିତ ଅଭ୍ୟାସ କରନ୍ତୁ |",
              "as": "পদক্ষেপ 4: মক-পৰীক্ষাৰ সৈতে অনুশীলন কৰা",
              "gu": "પગલું 4: કુત્રિમ પરીક્ષા સાથે પ્રેક્ટિસ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Collect all the details of both preliminary and main exams like the exam dates, exam pattern, time duration, and assessment framework.",
              "hi": "प्रारंभिक और मुख्य परीक्षा दोनों के सभी विवरण जैसे परीक्षा तिथियों, परीक्षा पैटर्न, समय अवधि, और मूल्यांकन ढांचा एकत्र करे।",
              "ka": "ಪರೀಕ್ಷೆಯ ದಿನಾಂಕಗಳು, ಪರೀಕ್ಷೆಯ ಮಾದರಿ, ಸಮಯದ ಅವಧಿ ಮತ್ತು ಮೌಲ್ಯಮಾಪನ ಚೌಕಟ್ಟಿನಂತಹ ಪ್ರಾಥಮಿಕ ಮತ್ತು ಮುಖ್ಯ ಪರೀಕ್ಷೆಗಳ ಎಲ್ಲಾ ವಿವರಗಳನ್ನು ಸಂಗ್ರಹಿಸುವುದು.",
              "te": "పరీక్ష తేదీలు, పరీక్షా విధానం, సమయ వ్యవధి మరియు మూల్యాంకన ఫ్రేమ్‌వర్క్ వంటి ప్రిలిమినరీ మరియు మెయిన్ పరీక్షల యొక్క అన్ని వివరాలను సేకరించాలి.",
              "or": "ଉଭୟ ପ୍ରାଥମିକ ଏବଂ ମୁଖ୍ୟ ପରୀକ୍ଷାଗୁଡ଼ିକର ସମସ୍ତ ବିବରଣୀ ସଂଗ୍ରହ କରନ୍ତୁ \n ଯେପରି ପରୀକ୍ଷା ତାରିଖ, ପରୀକ୍ଷା ଢାଞ୍ଚା, ସମୟ ଅବଧି, ଏବଂ ମୂଲ୍ୟାଙ୍କନ ଢାଞ୍ଚା |",
              "as": "প্ৰাথমিক আৰু মুখ্য দুয়োটা পৰীক্ষাৰ সকলো বিৱৰণ সংগ্ৰহ কৰা যেনে পৰীক্ষাৰ তাৰিখ, পৰীক্ষাৰ আৰ্হি, সময়ৰ ম্যাদ, আৰু মূল্যায়ন প্ৰণালী।",
              "gu": "પ્રારંભિક અને મુખ્ય બંને પરીક્ષાઓની તમામ વિગતો જેમ કે પરીક્ષાની તારીખો, પરીક્ષાની રીત, સમય અવધિ અને મૂલ્યાંકન માળખું એકત્રિત કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Understand the basics of core UPSC subjects. Download all the NCERT books from the website to study. Read all the textbooks twice to be ready.",
              "hi": "कोर यूपीएससी विषयों की मूल बातों को समझे। अध्ययन के लिए वेबसाइट से सभी एनसीईआरटी किताबें डाउनलोड करे। तैयारी के लिए दो बार सभी पाठ्यपुस्तकों को पढ़े।",
              "ka": "ಮುಖ್ಯ UPSC ವಿಷಯಗಳ ಮೂಲಭೂತ ಅಂಶಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು. ಅಧ್ಯಯನ ಮಾಡಲು ವೆಬ್‌ಸೈಟ್‌ನಿಂದ ಎಲ್ಲಾ NCERT ಪುಸ್ತಕಗಳನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿಕೊಳ್ಳುವುದು. ಸಿದ್ಧವಾಗಲು ಎಲ್ಲಾ ಪಠ್ಯಪುಸ್ತಕಗಳನ್ನು ಎರಡು ಬಾರಿ ಓದುವುದು.",
              "te": "ప్రధాన UPSC సబ్జెక్టుల బేసిక్స్ ను అర్థం చేసుకోవాలి. చదవడానికి వెబ్‌సైట్ నుండి అన్ని NCERT పుస్తకాలను డౌన్‌లోడ్ చేసుకోవాలి. రెడీగా ఉండటానికి అన్ని పాఠ్యపుస్తకాలను రెండుసార్లు చదవాలి.",
              "or": "ମୂଳ UPSC ବିଷୟଗୁଡ଼ିକର ମୌଳିକତା ବୁଝନ୍ତୁ | ଅଧ୍ୟୟନ ପାଇଁ \n ୱେବସାଇଟରୁ ସମସ୍ତ NCERT ପୁସ୍ତକ ଡାଉନଲୋଡ୍ କରନ୍ତୁ | ପ୍ରସ୍ତୁତ ହେବାକୁ ସମସ୍ତ ପାଠ୍ୟପୁସ୍ତକ ଦୁଇଥର ପଢନ୍ତୁ |",
              "as": "মূল UPSC বিষয়বোৰৰ মৌলিক বিষয়বোৰ বুজি লোৱা। অধ্যয়ন কৰিবলৈ ৱেবছাইটৰ পৰা NCERTৰ সকলো কিতাপ ডাউনলোড কৰা। সাজু হ'বলৈ সকলো পাঠ্যপুথি দুবাৰকৈ পঢ়া।",
              "gu": "મુખ્ય UPSC વિષયોની મૂળભૂત બાબતોને સમજો. અભ્યાસ માટે વેબસાઇટ પરથી તમામ NCERT પુસ્તકો ડાઉનલોડ કરો. તૈયાર થવા માટે તમામ પાઠ્યપુસ્તકો બે વાર વાંચો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "After understanding the core subjects, read other books from the public library to upgrade knowledge. Also, study through free apps.",
              "hi": "कोर विषयों को समझने के बाद, ज्ञान में अभिवृद्धि के लिए सार्वजनिक पुस्तकालय से अन्य पुस्तकें पढ़े। इसके अलावा, मुफ्त ऐप्स के माध्यम से अध्ययन करे।",
              "ka": "ಮುಖ್ಯ ವಿಷಯಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಂಡ ನಂತರ, ಜ್ಞಾನವನ್ನು ನವೀಕರಿಸಲು ಸಾರ್ವಜನಿಕ ಗ್ರಂಥಾಲಯದಿಂದ ಇತರ ಪುಸ್ತಕಗಳನ್ನು ಓದುವುದು. ಅಲ್ಲದೆ, ಉಚಿತ ಅಪ್ಲಿಕೇಶನ್‌ಗಳ ಮೂಲಕ ಅಧ್ಯಯನ ಮಾಡುವುದು",
              "te": "కోర్ సబ్జెక్టులను అర్థం చేసుకున్న తర్వాత, నాలెడ్జ్ అప్‌గ్రేడ్ చేయడానికి పబ్లిక్ లైబ్రరీ నుండి ఇతర పుస్తకాలను చదవాలి. అలాగే, ఉచిత యాప్‌ల ద్వారా నేర్చుకోవాలి.",
              "or": "ମୂଳ ବିଷୟଗୁଡିକ ବୁଝିବା ପରେ, ଜ୍ଞାନକୁ ନବୀକରଣ କରିବାକୁ ସର୍ବସାଧାରଣ\n  ଲାଇବ୍ରେରୀରୁ ଅନ୍ୟ ପୁସ୍ତକ ପ read | ମାଗଣା ଆପ୍ ମାଧ୍ୟମରେ ମଧ୍ୟ ଅଧ୍ୟୟନ କରନ୍ତୁ |",
              "as": "মূল বিষয়বোৰ বুজি পোৱাৰ পিছত, জ্ঞানৰ পৰিসৰ বঢ়াবলৈ ৰাজহুৱা পুথিভঁৰালৰ পৰা অনা কিতাপ পঢ়া। লগতে, বিনামূলীয়া এপ্পৰ জৰিয়তে অধ্যয়ন কৰা।",
              "gu": "મુખ્ય વિષયોને સમજ્યા પછી, જ્ઞાનમાં સુધારો કરવા માટે જાહેર પુસ્તકાલયમાંથી અન્ય પુસ્તકો વાંચો. ઉપરાંત, મફત એપ્લિકેશન દ્વારા અભ્યાસ કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "To help with the preliminary and main exam, take mock tests after preparing for 7-8 months.",
              "hi": " 7-8 महीने की तैयारी के बाद मॉक/ नकली परीक्षण ले, जिससे प्रारंभिक और मुख्य परीक्षा में सहायता  मिलेगी  ।",
              "ka": "ಪೂರ್ವಭಾವಿ ಮತ್ತು ಮುಖ್ಯ ಪರೀಕ್ಷೆಗೆ ಸಹಾಯ ಮಾಡಲು, 7-8 ತಿಂಗಳ ತಯಾರಿ ನಂತರ ಅಣಕು ಪರೀಕ್ಷೆಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುವುದು",
              "te": "ప్రిలిమినరీ మరియు మెయిన్ ఎగ్జామ్‌లో సహాయం చేయడానికి, 7-8 నెలల పాటు ప్రిపేర్ అయిన తర్వాత మాక్ టెస్ట్‌లను రాయాలి.",
              "or": "ପ୍ରାଥମିକ ଏବଂ ମୁଖ୍ୟ ପରୀକ୍ଷାରେ ସାହାଯ୍ୟ କରିବାକୁ, ୭-୮ ମାସ ପାଇଁ \n ପ୍ରସ୍ତୁତ ହେବା ପରେ ମକ୍ ଟେଷ୍ଟ୍ ନିଅ |",
              "as": "প্ৰাথমিক আৰু মুখ্য পৰীক্ষাত সহায় হ’বলৈ, ৭-৮ মাহৰ বাবে প্ৰস্তুতি কৰাৰ পিছত মক পৰীক্ষা দিবা।",
              "gu": "પ્રારંભિક અને મુખ્ય પરીક્ષામાં મદદ કરવા માટે, 7-8 મહિનાની તૈયારી કર્યા પછી કુત્રિમ પરીક્ષા સાથે પ્રેક્ટિસ કરો."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(ii)_2_2",
              "hi": "A2 (II) _2_2",
              "ka": "A2(ii)_2_2",
              "te": "A2(ii)_2_2",
              "or": "A2(ii)_2_2",
              "as": "A2(ii)_2_2",
              "gu": "A2(ii)_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "With the plan in place, he feels confident about his preparation. Badri notices his study patterns improve over the next few months. Madhav seems to be doing much better.",
              "hi": "योजना के साथ, वह अपनी तैयारी के बारे में आश्वस्त अनुभव करता है। बद्री ने देखा कि अगले कुछ महीनों में उसके अध्ययन के तरीके में सुधार हुआ है। लगता है माधव बहुत अच्छा कर रहा है।",
              "ka": "ಯೋಜನೆಯೊಂದಿಗೆ, ಅವನು ತನ್ನ ಸಿದ್ಧತೆಯ ಬಗ್ಗೆ ವಿಶ್ವಾಸ ಹೊಂದುತ್ತಾನೆ. ಮುಂದಿನ ಕೆಲವು ತಿಂಗಳುಗಳಲ್ಲಿ ಅವನ ಅಧ್ಯಯನದ ಮಾದರಿಯು ಸುಧಾರಿಸುವುದನ್ನು ಬದ್ರಿ ಗಮನಿಸುತ್ತಾನೆ. ಮಾಧವ್  ಉತ್ತಮವಾಗಿ ಓದುವುದರಲ್ಲಿ ತನ್ನನ್ನು ತೊಡಗಿಸಿಕೊಂಡಿದ್ದಾನೆ ಎಂದು  ತೋರುತ್ತದೆ.",
              "te": "ప్లాన్ ప్రకారం, అతను తన ప్రిపరేషన్ గురించి నమ్మకంగా ఉన్నాడు. తర్వాత కొద్ది నెలల్లో తను చదివే పాటర్న్ మెరుగైందని బద్రి గమనించాడు. మాధవ్ చాలా బాగా చేస్తున్నాడు.",
              "or": "ଯୋଜନା ପ୍ରସ୍ତୁତ ହେବା ସହିତ, ସେ ତାଙ୍କର ପ୍ରସ୍ତୁତି ବିଷୟରେ ଆତ୍ମବିଶ୍ୱାସ\n  ଅନୁଭବ କରନ୍ତି | ବଦ୍ରି ଆଗାମୀ କିଛି ମାସ ମଧ୍ୟରେ ତାଙ୍କର ଅଧ୍ୟୟନ ଢାଞ୍ଚାରେ ଉନ୍ନତି ଆଣନ୍ତି | ମାଧବ ବହୁତ ଭଲ କାମ କରୁଥିବା ଜଣାପଡିଛି |",
              "as": "পৰিকল্পনাটোৰ সৈতে, তেওঁ তেওঁৰ প্ৰস্তুতিৰ বিষয়ে আত্মবিশ্বাসী অনুভৱ কৰে। বদ্ৰীয়ে লক্ষ্য কৰিছে যে পৰৱৰ্তী কেইমাহমানত তাৰ অধ্যয়নৰ ধৰণ উন্নত হৈছে। মাধৱে বহুত ভাল কৰি থকা যেন লাগিছে।",
              "gu": "યોજના સાથે, તે તેની તૈયારી વિશે આત્મવિશ્વાસ અનુભવે છે. બદ્રીએ નોંધ્યું છે કે આગામી થોડા મહિનામાં તેનો અભ્યાસ પેટર્નમાં સુધારો થયો છે. માધવ વધુ સારું કરી રહ્યો હોય તેવું લાગે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(ii)_3_1",
              "hi": "A2 (ii) _3_1",
              "ka": "A2(ii)_3_1",
              "te": "A2(ii)_3_1",
              "or": "A2(ii)_3_1",
              "as": "A2(ii)_3_1",
              "gu": "A2(ii)_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Although he gets financial support from Kuldeep, he decides to work odd jobs like writing articles for an online magazine, which gives him some income.",
              "hi": "यद्यपि उसे कुलदीप से वित्तीय सहायता मिलती है, फिर भी वह एक ऑनलाइन पत्रिका के लिए लेख लिखने जैसे अजीब काम को करने का मन बनाता है, जो उसे कुछ आय देता है।",
              "ka": "ಅವನು ಕುಲದೀಪ್‌ನಿಂದ ಆರ್ಥಿಕ ಬೆಂಬಲವನ್ನು ಪಡೆದರೂ ಸಹ, ಆನ್‌ಲೈನ್ ನಿಯತಕಾಲಿಕೆಗೆ ಲೇಖನಗಳನ್ನು ಬರೆಯುವಂತಹ ಇತರೆ ಕೆಲಸಗಳನ್ನು ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ ಅದು ಅವನಿಗೆ ಸ್ವಲ್ಪ ಆದಾಯವನ್ನು ನೀಡುತ್ತದೆ.",
              "te": "అతను కుల్దీప్ నుండి ఆర్థిక సహాయం పొందినప్పటికీ, అతను ఆన్‌లైన్ మ్యాగజైన్‌కి కథనాలు రాయడం వంటి ఉద్యోగాలు చేసి కొంత ఆదాయం సంపాదించాలని నిర్ణయించుకున్నాడు.",
              "or": "ଯଦିଓ ସେ କୁଲଦୀପଙ୍କଠାରୁ ଆର୍ଥିକ ସହାୟତା ପାଆନ୍ତି, ତଥାପି ସେ ଏକ\n  ଅନଲାଇନ୍ ମାଗାଜିନ ପାଇଁ ଆର୍ଟିକିଲ୍ ଲେଖିବା ଭଳି ଅଦ୍ଭୁତ ଚାକିରି କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି, ଯାହା ତାଙ୍କୁ କିଛି ରୋଜଗାର କରିଥାଏ |",
              "as": "যদিও তেওঁ কুলদীপৰ পৰা বিত্তীয় সমৰ্থন লাভ কৰে, সি অনলাইন আলোচনী এখনৰ বাবে প্ৰবন্ধ লিখাৰ দৰে অগতানুগতিক কাম কৰাৰ সিদ্ধান্ত লয়, যিয়ে তাক কিছু উপাৰ্জন দিব।",
              "gu": "તેમ છતાં તેને કુલદીપ તરફથી આર્થિક મદદ મળે છે, તે ઓનલાઈન મેગેઝિન માટે લેખ લખવા જેવી વિચિત્ર નોકરીઓ કરવાનું નક્કી કરે છે, જેનાથી તેને થોડી આવક થાય છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2(ii)_3_2",
              "hi": "A2 (II) _3_2",
              "ka": "A2(ii)_3_2",
              "te": "A2(ii)_3_2",
              "or": "A2(ii)_3_2",
              "as": "A2(ii)_3_2",
              "gu": "A2(ii)_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "His father expects him to send money every month, but Madhav doesn't make enough money to send.",
              "hi": "उसके पिता  आशा करते हैं कि वह उन्हें हर महीने पैसे भेजे , लेकिन माधव हर महीने भेजने लायक पर्याप्त पैसा नहीं कमाता है।",
              "ka": "ಅವನ ತಂದೆಯು ಅವನು ಪ್ರತಿ ತಿಂಗಳು ಹಣವನ್ನು ಕಳುಹಿಸಬೇಕೆಂದು ನಿರೀಕ್ಷಿಸುತ್ತಾನೆ, ಆದರೆ ಮಾಧವ್ ತಂದೆಗೆ ಕಳುಹಿಸುವಷ್ಟು ಹಣವನ್ನು ಗಳಿಸುವುದಿಲ್ಲ.",
              "te": "అతని తండ్రి ప్రతి నెలా డబ్బు పంపాలని ఆశిస్తాడు, కాని మాధవ్ పంపడానికి తగినంత డబ్బు సంపాదించలేదు.",
              "or": "ତାଙ୍କ ବାପା ଆଶା କରନ୍ତି ଯେ ସେ ପ୍ରତି ମାସରେ ଟଙ୍କା ପଠାନ୍ତୁ, କିନ୍ତୁ \n ମାଧବ ପଠାଇବା ପାଇଁ ପର୍ଯ୍ୟାପ୍ତ ଅର୍ଥ ରୋଜଗାର କରନ୍ତି ନାହିଁ |",
              "as": "তেওঁৰ দেউতাকে আশা কৰে যে তেওঁ প্ৰতি মাহে টকা পঠিয়াব, কিন্তু মাধৱে পঠিয়াবলৈ পৰ্যাপ্ত টকা উপাৰ্জন নকৰে।",
              "gu": "તેના પિતા અપેક્ષા રાખે છે કે તે દર મહિને પૈસા મોકલે, પરંતુ માધવ મોકલવા માટે પૂરતા પૈસા કમાતો નથી."
            }
          }
        ]
      },
      "scene13": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_1",
              "hi": "A3_1_1",
              "ka": "A3_1_1",
              "te": "A3_1_1",
              "or": "A3_1_1",
              "as": "A3_1_1",
              "gu": "A3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav appears for the preliminary exam. Since this is his second time, it doesn’t feel new to him. He answers the questions to the best of his ability.",
              "hi": "माधव प्रारंभिक परीक्षा के लिए उपस्थित होता है। चूंकि यह उसका दूसरा प्रयास है, इसलिए यह उसे नया नहीं लगता है। वह अपनी क्षमता के अनुसार सवालों के जवाब देता है।",
              "ka": "ಮಾಧವ್ ಪೂರ್ವಭಾವಿ ಪರೀಕ್ಷೆಗೆ ಹಾಜರಾಗುತ್ತಾನೆ. ಇದು ಅವನ ಎರಡನೇ ಬಾರಿಯಾದ್ದರಿಂದ, ಅವನಿಗೆ ಇದು ಹೊಸದೇನಲ್ಲ. ಅವನು ತನ್ನ ಸಾಮರ್ಥ್ಯಕ್ಕೆ ತಕ್ಕಂತೆ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్ ప్రిలిమినరీ పరీక్షకు హాజరయ్యాడు. ఇది అతనికి రెండోసారి కాబట్టి, అతనికి ఇది కొత్తగా అనిపించలేదు. అతను తన సామర్థ్యం మేరకు ప్రశ్నలకు సమాధానాలు ఇస్తాడు.",
              "or": "ମାଧବ ପ୍ରାଥମିକ ପରୀକ୍ଷା ପାଇଁ ହାଜର ହୁଅନ୍ତି | ଯେହେତୁ ଏହା ତାଙ୍କର\n  ଦ୍ୱିତୀୟ ଥର, ଏହା ତାଙ୍କ ପାଇଁ ନୂଆ ଅନୁଭବ କରେ ନାହିଁ | ସେ ନିଜ ସାମର୍ଥ୍ୟ ଅନୁଯାୟୀ ପ୍ରଶ୍ନର ଉତ୍ତର ଦିଅନ୍ତି |",
              "as": "মাধৱ প্ৰাথমিক পৰীক্ষাত অৱতীৰ্ণ হয়। যিহেতু এইটো তেওঁ দ্বিতীয়বাৰৰ বাবে দিছে, ই তেওঁৰ বাবে নতুন যেন লগা নাই। তেওঁ তেওঁৰ সৰ্বশ্ৰেষ্ঠ সামৰ্থ্যৰে প্ৰশ্নবোৰৰ উত্তৰ দিছে।",
              "gu": "માધવ પ્રારંભિક પરીક્ષા માટે હાજર છે. આ તેની બીજી વખત હોવાથી, તે તેને નવું નથી લાગતું. તે તેની શ્રેષ્ઠ ક્ષમતા મુજબ પ્રશ્નોના જવાબ આપે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_2",
              "hi": "A3_1_2",
              "ka": "A3_1_2",
              "te": "A3_1_2",
              "or": "A3_1_2",
              "as": "A3_1_2",
              "gu": "A3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "On the day of the result, he is feeling very anxious. He is unsure of how he has performed and hopes he has cleared the exam this time.",
              "hi": "परिणाम के दिन, वह बहुत चिंतित अनुभव कर रहा है। वह अनिश्चित है कि उसने कैसा किया है और आशा करता है कि उसने इस बार परीक्षा उत्तीर्ण कर ली है।",
              "ka": "ಫಲಿತಾಂಶದ ದಿನ ಅವನು ತೀವ್ರ ಆತಂಕದಲ್ಲಿದ್ದಾನೆ. ಅವನು ಹೇಗೆ ಓದಿದ್ದಾನೆ ಎನ್ನುವುದರ ಕುರಿತು ಅವನು ಖಚಿತವಾಗಿಲ್ಲ ಮತ್ತು ಈ ಬಾರಿ ಪರೀಕ್ಷೆಯಲ್ಲಿ ಉತ್ತೀರ್ಣರಾಗಬಹುದು ಎಂದು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "రిజల్ట్ వచ్చే రోజు చాలా ఆత్రుతగా ఫీల్ అవుతున్నాడు. అతను ఎలా రాశాడో తెలియదు మరియు అతను ఈసారి పరీక్షలో ఉత్తీర్ణత సాధిస్తాడని ఆశిస్తున్నాడు.",
              "or": "ଫଳାଫଳ ଦିନ, ସେ ବହୁତ ଚିନ୍ତିତ ଅନୁଭବ କରୁଛନ୍ତି | ସେ କିପରି ପ୍ରଦର୍ଶନ \n କରିଛନ୍ତି ସେ ବିଷୟରେ ନିଶ୍ଚିତ ନୁହଁନ୍ତି ଏବଂ ଆଶା କରୁଛନ୍ତି ଯେ ସେ ଏଥର ପରୀକ୍ଷା ପାସକରିଛନ୍ତି।",
              "as": "ফলাফলৰ দিনা, তেওঁ অতি উদ্বিগ্ন অনুভৱ কৰিছে। তেওঁ কেনেদৰে প্ৰদৰ্শন কৰিছিল সেই বিষয়ে নিশ্চিত নহয় আৰু আশা কৰে যে তেওঁ এইবাৰ পৰীক্ষাত উত্তীৰ্ণ হ’ব।",
              "gu": "પરિણામના દિવસે તે ખૂબ જ બેચેની અનુભવી રહ્યો છે. તેણે કેવું પ્રદર્શન કર્યું તે અંગે તેને ખાતરી નથી અને આશા છે કે તેણે આ વખતે પરીક્ષા પાસ કરી છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_3",
              "hi": "A3_1_3",
              "ka": "A3_1_3",
              "te": "A3_1_3",
              "or": "A3_1_3",
              "as": "A3_1_3",
              "gu": "A3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He goes to the centre to see the result.",
              "hi": "वह परिणाम देखने के लिए केंद्र में जाता है।",
              "ka": "ಅವನು ಫಲಿತಾಂಶವನ್ನು ನೋಡಲು ಕೇಂದ್ರಕ್ಕೆ ಹೋಗುತ್ತಾನೆ.",
              "te": "ఫలితాలను చూసేందుకు సెంటర్ కి వెళతాడు.",
              "or": "ଫଳାଫଳ ଦେଖିବାକୁ ସେ କେନ୍ଦ୍ରକୁ ଯାଆନ୍ତି |",
              "as": "তেওঁ ফলাফল চাবলৈ কেন্দ্ৰলৈ যায়।",
              "gu": "તે પરિણામ જોવા માટે સેંટરમાં જાય છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_4(reveal)",
              "hi": "A3_1_4 (रिवील)",
              "ka": "A3_1_4(reveal)",
              "te": "A3_1_4(రివీల్)",
              "or": "A3_1_4 (ପ୍ରକାଶ)",
              "as": "A3_1_4(প্ৰকাশ)",
              "gu": "A3_1_4(જાહેર કરો)"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to see his rank and the cut off rank:",
              "hi": "उसकी रैंक और कट ऑफ रैंक देखने के लिए क्लिक करें:",
              "ka": "ಅವನ ಶ್ರೇಣಿ ಮತ್ತು ಕಟ್ ಆಫ್ ಶ್ರೇಣಿಯನ್ನು ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "అతని ర్యాంక్ మరియు కట్ ఆఫ్ ర్యాంక్ చూడటానికి క్లిక్ చేయండి:",
              "or": "ତାଙ୍କର ର୍ୟାଙ୍କ୍ ଏବଂ କଟ୍ ଅଫ୍ ର୍ୟାଙ୍କ୍ ଦେଖିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "তেওঁৰ ৰেংক আৰু কাটঅফ ৰেংক প্ৰকাশ কৰিবলৈ ক্লিক কৰা:",
              "gu": "તેનો ક્રમ અને કપાત ગુણ જોવા માટે ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Madhav’s Rank",
              "hi": "माधव की रैंक",
              "ka": "ಮಾಧವಣ ಶ್ರೇಣಿ",
              "te": "మాధవ్ ర్యాంక్",
              "or": "ମାଧବଙ୍କ ରାଙ୍କ୍ |",
              "as": "মাধৱৰ ৰেংক",
              "gu": "માધવનો ક્રમ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Cut off rank",
              "hi": "कट ऑफ रैंक",
              "ka": "ಕಟ್ ಆಫ್ ಶ್ರೇಣಿ",
              "te": "కట్ ఆఫ్ ర్యాంక్",
              "or": "କଟ ଅଫ ରାଙ୍କ",
              "as": "কাটঅফ ৰেংক",
              "gu": "કપાત ગુણ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "35,897",
              "hi": "35,897",
              "ka": "35,897",
              "te": "35,897",
              "or": "୩୫,୮୯୭",
              "as": "35,897",
              "gu": "35,897"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "11, 560",
              "hi": "11, 560",
              "ka": "11, 560",
              "te": "11, 560",
              "or": "୧୧,୫୬୦",
              "as": "11, 560",
              "gu": "11, 560 છે"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_5",
              "hi": "A3_1_5",
              "ka": "A3_1_5",
              "te": "A3_1_5",
              "or": "A3_1_5",
              "as": "A3_1_5",
              "gu": "A3_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is very disappointed to see his results. He still has a long way to go to clear the exam.",
              "hi": "माधव अपने परिणाम देखकर बहुत निराश है। परीक्षा उत्तीर्ण करने के लिए उसको अभी भी एक लंबा सफर तय करना है।",
              "ka": "ಮಾಧವ್ ತನ್ನ ಫಲಿತಾಂಶಗಳನ್ನು ನೋಡಿ ತುಂಬಾ ನಿರಾಶೆಗೊಂಡಿದ್ದಾನೆ. ಪರೀಕ್ಷೆಯಲ್ಲಿ ತೇರ್ಗಡೆಯಾಗಲು ಅವನು ಇನ್ನೂ ಬಹಳ ದೂರ ಸಾಗಬೇಕಾಗಿದೆ.",
              "te": "మాధవ్ తన ఫలితాలు చూసి చాలా నిరాశ చెందాడు. అతను పరీక్షలో ఉత్తీర్ణత సాధించడానికి ఇంకా చాలా సమయం పట్టేలా ఉంది.",
              "or": "ତାଙ୍କର ଫଳାଫଳ ଦେଖି ମାଧବ ବହୁତ ନିରାଶ ହୋଇଛନ୍ତି | ପରୀକ୍ଷା ପାସ \n  କରିବାକୁ ତାଙ୍କୁ ଏପର୍ଯ୍ୟନ୍ତ ବହୁତ ଦୂର ବାଟ ଅଛି।",
              "as": "মাধৱে তাৰ ফলাফল দেখি বৰ হতাশ হ’ল। পৰীক্ষাত উত্তীৰ্ণ হ'বলৈ তাৰ এতিয়াও বহুদূৰ বাকী আছে।",
              "gu": "માધવ તેનું પરિણામો જોઈને ખૂબ જ નિરાશ છે. તેણે પરીક્ષા પાસ કરવા માટે હજુ લાંબુ અંતર કાપવાનું છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_2_1",
              "hi": "A3_2_1",
              "ka": "A3_2_1",
              "te": "A3_2_1",
              "or": "A3_2_1",
              "as": "A3_2_1",
              "gu": "A3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Kuldeep and Badri are concerned to see Madhav so upset. They are worried about how stressed Madhav is with his results.",
              "hi": "कुलदीप और बद्री माधव को इतना परेशान देखकर चिंतित है। वे इस बात से व्याकुल हैं कि माधव अपने परिणाम से कितना तनावग्रस्त है।",
              "ka": "ಕುಲದೀಪ್ ಮತ್ತು ಬದ್ರಿ ಮಾಧವ್ ತುಂಬಾ ಅಸಮಾಧಾನಗೊಂಡಿರುವುದನ್ನು ನೋಡಿ ಕಳವಳಗೊಂಡಿದ್ದಾರೆ. ಮಾಧವ್ ತನ್ನ ಫಲಿತಾಂಶಗಳಿಂದ ಎಷ್ಟು ಒತ್ತಡಕ್ಕೊಳಗಾಗಿದ್ದಾನೆ ಎಂದು ಅವರು ಚಿಂತಿತರಾಗಿದ್ದಾರೆ.",
              "te": "మాధవ్‌ని చూసి కుల్దీప్, బద్రి ఆందోళన చెందుతున్నారు. మాధవ్ తన ఫలితాలతో ఎంత ఒత్తిడికి లోనయ్యాడో అని వారు బాధ పడుతున్నారు.",
              "or": "ମାଧବଙ୍କୁ ଏତେ ଚିନ୍ତିତ ଦେଖି କୁଲଦୀପ ଏବଂ ବଦ୍ରି ଚିନ୍ତିତ | ମାଧବ ତାଙ୍କ\n  ଫଳାଫଳ ସହିତ କେତେ ଚାପଗ୍ରସ୍ତ, ସେଥିପାଇଁ ସେମାନେ ଚିନ୍ତିତ |",
              "as": "কুলদীপ আৰু বদ্ৰীয়ে মাধৱক ইমান বিচলিত হোৱা দেখি চিন্তিত হ’ল। তেওঁলোক চিন্তিত যে মাধৱ তাৰ ফলাফলৰ বাবে কিমান চাপত আছে।",
              "gu": "માધવને આટલો અસ્વસ્થ જોઈને કુલદીપ અને બદ્રી ચિંતિત છે. તેઓ ચિંતિત છે કે માધવ તેના પરિણામોથી કેટલો તણાવમાં છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_2_2//",
              "hi": "A3_2_2 //",
              "ka": "A3_2_2//",
              "te": "A3_2_2//",
              "or": "A3_2_2//",
              "as": "A3_2_2//",
              "gu": "A3_2_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୂଲଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I need another plan! I can’t deal with this Badri.",
              "hi": "मुझे एक और योजना चाहिए! मैं इसे सहन नहीं कर सकता, बद्री।",
              "ka": "ನನಗೆ ಇನ್ನೊಂದು ಯೋಜನೆ ಬೇಕು! ಬದ್ರಿ, ನನಗೆ ಇದನ್ನು ನಿಭಾಯಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ.",
              "te": "నాకు మరో ప్లాన్ కావాలి! నేను ఇది తట్టుకోలేకపోతున్నాను బద్రి.",
              "or": "ମୋର ଆଉ ଏକ ଯୋଜନା ଦରକାର! ମୁଁ ଏହି ବଦ୍ରି ସହିତ ମୁକାବିଲା \n କରିପାରିବି ନାହିଁ |",
              "as": "মোক আন এটা পৰিকল্পনাৰ প্ৰয়োজন! মই ইয়াৰ সৈতে মোকাবিলা কৰিব নোৱাৰোঁ বদ্ৰী।",
              "gu": "મારે બીજી યોજના બનાવી જોઈએ છે! હું આ બદ્રી સાથે વ્યવહાર કરી શકતો નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Madhav, you can’t lose hope. You know these exams are difficult, and they allow you so many attempts because it takes time to clear them.",
              "hi": "माधव, तुम आशा खो नहीं सकते। तुम जानते हो कि ये परीक्षाएं कठिन हैं, और वे तुमको कई प्रयासों की अनुमति देते हैं क्योंकि उन्हें पास करने में समय लगता है।",
              "ka": "ಮಾಧವ್, ನೀನು ಭರವಸೆ ಕಳೆದುಕೊಳ್ಳಬೇಡ. ಈ ಪರೀಕ್ಷೆಗಳು ಕಷ್ಟಕರವೆಂದು ನಿನಗೆ ತಿಳಿದಿದೆ ಮತ್ತು ಅವುಗಳು ನಿನಗೆ ಹಲವು ಪ್ರಯತ್ನಗಳಿಗಾಗಿ ಅನುಮತಿಸುತ್ತವೆ ಏಕೆಂದರೆ ಅವುಗಳಲ್ಲಿ ತೇರ್ಗಡೆಗೊಳ್ಳಲು ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ",
              "te": "మాధవ్, నువ్వు ఆశ వదులుకోవద్దు. ఈ పరీక్షలు కష్టమైనవని నీకు తెలుసు మరియు వాటిని క్లియర్ చేయడానికి సమయం పడుతుంది కాబట్టి నీకు చాలాసార్లు ప్రయత్నించే అవకాశం ఉంది.",
              "or": "ମାଧବ, ତୁମେ ଆଶା ହରାଇ ପାରିବ ନାହିଁ | ଆପଣ ଜାଣନ୍ତି ଏହି ପରୀକ୍ଷାଗୁଡ଼ିକ\n  କଷ୍ଟସାଧ୍ୟ, ଏବଂ ସେମାନେ ଆପଣଙ୍କୁ ଅନେକ ଚେଷ୍ଟା କରିବାକୁ ଅନୁମତି ଦିଅନ୍ତି କାରଣ ସେଗୁଡିକ ପାସ କରିବାକୁ ସମୟ ଲାଗେ |",
              "as": "মাধৱ, তুমি আশা হেৰুৱাব নোৱাৰা। তুমি জানা যে এই পৰীক্ষাবোৰ কঠিন, আৰু সেইবাবে তোমাক ইমানবোৰ প্ৰচেষ্টাৰ অনুমতি দিয়ে কিয়নো সেইবোৰ পাচ কৰিবলৈ সময় লাগিবই।",
              "gu": "માધવ, તું આશા ન ગુમાવી શકે. તું જાણે છે કે આ પરીક્ષાઓ મુશ્કેલ છે, અને તે તને ઘણા પ્રયત્નો કરવાની મંજૂરી આપે છે કારણ કે તેને પાસ કરવામાં સમય લાગે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "But what if it is a dead-end. I can't afford coaching classes with what I earn. If I do a full-time job, I won't have time to study.",
              "hi": "लेकिन क्या होगा यदि इसके आगे का रास्ता बंद होगा। मैं जो कमाता हूं, उससे मैं कोचिंग क्लासेस का खर्चा नहीं उठा पाउँगा। यदि मैं पूर्णकालिक नौकरी करता हूं, तो मेरे पास अध्ययन करने का समय नहीं होगा।",
              "ka": "ಆದರೆ ಅದರ  ಅಂತ್ಯವಾದರೂ ಏನು. ನಾನು ಗಳಿಸಿದ ಹಣದಿಂದ ಕೋಚಿಂಗ್ ತರಗತಿಗಳನ್ನು ಸೇರಲು ಸಾಧ್ಯವಿಲ್ಲ. ನಾನು ಪೂರ್ಣಾವಧಿಯ ಕೆಲಸವನ್ನು ಮಾಡಿದಲ್ಲಿ, ನನಗೆ ಓದಲು ಸಮಯವಿರುವುದಿಲ್ಲ",
              "te": "కానీ అది డెడ్ ఎండ్ అయితే ఏమి చేయాలి. నేను సంపాదించిన దానితో కోచింగ్ క్లాసులు తీసుకోలేను. ఫుల్ టైమ్ జాబ్ చేస్తే చదువుకోడానికి సమయం ఉండదు.",
              "or": "କିନ୍ତୁ ଯଦି ଏହା ଏକ ମରାମତି ଅଟେ | ମୁଁ ଯାହା ରୋଜଗାର କରେ ତା\n  ସହିତ କୋଚିଂ କ୍ଲାସ୍ ଦେଇପାରୁ ନାହିଁ | ଯଦି ମୁଁ ଏକ ପୂର୍ଣ୍ଣକାଳୀନ କାର୍ଯ୍ୟ କରେ, ମୋର ପ study ିବାକୁ ସମୟ ନାହିଁ |",
              "as": "কিন্তু কি হ'ব যদি এইটো এটা ৰাস্তাৰ শেষ । মই যি উপাৰ্জন কৰোঁ তাৰে প্ৰশিক্ষণৰ শ্ৰেণীৰ খৰছ বহন কৰিব নোৱাৰোঁ। যদি মই পূৰ্ণ-সময়ৰ কাম কৰোঁ, মোৰ পঢ়িবলৈ সময় নাথাকিব।",
              "gu": "પરંતુ જો તે છેલ્લે આ રીતે થાકવાનું હોય તો શું. હું જે કમાઉં છું તેનાથી હું કોચિંગ ક્લાસ પરવડી શકતો નથી. જો હું પૂરો સમય નોકરી કરું, તો મારી પાસે ભણવાનો સમય નથી."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_2_3////",
              "hi": "A3_2_3 //////",
              "ka": "A3_2_3////",
              "te": "A3_2_3////",
              "or": "A3_2_3////",
              "as": "A3_2_3////",
              "gu": "A3_2_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Madhav, you cannot give up like this. We are here to support you. This year I will also start earning. Kuldeep and I will take care of you and your coaching.",
              "hi": "माधव, तुम इस तरह से हार नहीं मान सकते हैं। हम तुमको सहायता करने के लिए ही हैं। इस साल मैं भी कमाना शुरू कर दूंगा। कुलदीप और मैं तुम्हारा और तुम्हारी कोचिंग का खर्चा उठा लेंगे।",
              "ka": "ಮಾಧವ್ ನೀನು ಪ್ರಯತ್ನವನ್ನು ಬಿಡಬಾರದು. ನಿನ್ನನ್ನು ಬೆಂಬಲಿಸಲು ನಾವು ಇಲ್ಲಿದ್ದೇವೆ. ಈ ವರ್ಷ ನಾನು ಕೂಡ ಗಳಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತೇನೆ. ಕುಲದೀಪ್ ಮತ್ತು ನಾನು ನಿನ್ನನ್ನು ಮತ್ತು ನಿನ್ನ ಕೋಚಿಂಗ್ ಅನ್ನು ನೋಡಿಕೊಳ್ಳುತ್ತೇವೆ.",
              "te": "మాధవ్ నువ్వు ఇలా వదిలేయకూడదు. నీకు సహాయం చేయడానికి మేము ఇక్కడ ఉన్నాము. ఈ సంవత్సరం నేను కూడా సంపాదించడం ప్రారంభిస్తాను. కుల్దీప్ మరియు నేను నిన్ను మరియు నీ కోచింగ్ గురించి చూసుకుంటాము.",
              "or": "ମାଧବ, ତୁମେ ଏହିପରି ଛାଡି ପାରିବ ନାହିଁ | ଆମେ ଆପଣଙ୍କୁ ସମର୍ଥନ\n  କରିବାକୁ ଏଠାରେ ଅଛୁ | ଏହି ବର୍ଷ ମୁଁ ମଧ୍ୟ ରୋଜଗାର ଆରମ୍ଭ କରିବି | କୁଲଦୀପ ଏବଂ ମୁଁ ତୁମର ଏବଂ ତୁମର କୋଚିଂର ଯତ୍ନ ନେବି |",
              "as": "মাধৱ, তুমি এনেদৰে এৰিব নোৱাৰা। আমি ইয়াত তোমাক সমৰ্থন কৰিবলৈ আছোঁ। এই বছৰ মইও উপাৰ্জন কৰা আৰম্ভ কৰিম। কুলদীপ আৰু মই তোমাৰ আৰু তোমাৰ প্ৰশিক্ষণৰ যত্ন লম।",
              "gu": "માધવ, તું આ રીતે હાર ન માની શકે. અમે તમને સમર્થન આપવા માટે અહીં છીએ. આ વર્ષે હું પણ કમાવાનું શરૂ કરીશ. કુલદીપ અને હું તારી અને તારા કોચિંગનું ધ્યાન રાખીશું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "But I can’t do this. I have already tried twice, and I am nowhere close to the cut off rank.",
              "hi": "लेकिन मैं यह नहीं कर सकता। मैंने पहले ही दो बार कोशिश की है, और मैं कट ऑफ रैंक के करीब कहीं भी नहीं हूं।",
              "ka": "ಆದರೆ ನಾನು ಇದನ್ನು ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ. ನಾನು ಈಗಾಗಲೇ ಎರಡು ಬಾರಿ ಪ್ರಯತ್ನಿಸಿದ್ದೇನೆ ಮತ್ತು ನಾನು ಕಟ್ ಆಫ್ ಶ್ರೇಣಿಗೆ ಎಲ್ಲಿಯೂ ಹತ್ತಿರದಲ್ಲಿಲ್ಲ.",
              "te": "కానీ నేను ఇది చేయలేను. నేను ఇప్పటికే రెండుసార్లు ప్రయత్నించాను మరియు నేను కట్ ఆఫ్ ర్యాంక్‌కు దగ్గరగా కూడా లేను.",
              "or": "କିନ୍ତୁ ମୁଁ ଏହା କରିପାରିବି ନାହିଁ ମୁଁ ପୂର୍ବରୁ ଦୁଇଥର ଚେଷ୍ଟା କରିସାରିଛି, ଏବଂ\n  ମୁଁ କଟ୍ ଅଫ୍ ର୍ୟାଙ୍କ ପାଖରେ ନାହିଁ |",
              "as": "কিন্তু মই এইটো কৰিব নোৱাৰোঁ। মই ইতিমধ্যে দুবাৰ চেষ্টা কৰিছোঁ, আৰু মই কাট-অফ ৰেংকৰ ওচৰে পাজৰেও নাই।",
              "gu": "પરંતુ હું આ કરી શકતો નથી. મેં પહેલેથી જ બે વાર પ્રયાસ કર્યો છે, અને હું કપાત ગુણ ક્રમની નજીક નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "You need to relax. We are here for you.",
              "hi": "तुम्हें आराम कर लेना चाहिए। हम तुम्हारे लिए यहां हैं।",
              "ka": "ನೀನು ಸ್ವಲ್ಪ ವಿಶ್ರಾಂತಿ ಪಡೆಯಬೇಕು. ನಾವು ನಿನಗಾಗಿ ಇಲ್ಲಿದ್ದೇವೆ.",
              "te": "నువ్వు రిలాక్స్ అవ్వు. నీకు మేము ఉన్నాం కదా.",
              "or": "ଆପଣଙ୍କୁ ଆରାମ କରିବାକୁ ପଡିବ | ଆମେ ଆପଣଙ୍କ ପାଇଁ ଏଠାରେ ଅଛୁ |",
              "as": "তুমি শান্ত হোৱা। আমি তোমাৰ বাবে আছোঁ।",
              "gu": "તારે આરામ કરવાની જરૂર છે. અમે તારા માટે અહીં છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "What if I don’t do well next year? If I decide to study now, I don’t even know when I will get a job. How long will I live off you both?",
              "hi": "अगर मैं अगले साल अच्छा नहीं करता तो क्या होगा? अगर मैं अब अध्ययन करने का फैसला करता हूं, तो मुझे यह भी नहीं पता कि मुझे नौकरी कब मिलेगी। मैं तुम दोनों पर कब तक आश्रित रहूँगा?",
              "ka": "ಮುಂದಿನ ವರ್ಷ ನಾನು ಚೆನ್ನಾಗಿ ಮಾಡದಿದ್ದರೆ ಏನು? ನಾನು ಈಗ ಅಧ್ಯಯನ ಮಾಡಲು ನಿರ್ಧರಿಸಿದಲ್ಲಿ, ನನಗೆ ಯಾವಾಗ ಕೆಲಸ ಸಿಗುತ್ತದೆ ಎಂದು ನನಗೆ ತಿಳಿದಿಲ್ಲ. ನಾನು ನಿಮ್ಮಿಬ್ಬರ ಹಂಗಿನ ಮೇಲೆ ಎಷ್ಟು ದಿನ ಬದುಕಬೇಕು?",
              "te": "నేను వచ్చే ఏడాది బాగా రాయకపోతే ఎలా? నేను ఇప్పుడు చదువుకోవాలని నిర్ణయించుకుంటే, నాకు ఉద్యోగం ఎప్పుడు వస్తుందో కూడా తెలియదు. మీ ఇద్దరిపై ఆధారపడి నేను ఎంతకాలం బ్రతుకుతాను?",
              "or": "ଯଦି ମୁଁ ଆସନ୍ତା ବର୍ଷ ଭଲ କରିବି ନାହିଁ? ଯଦି ମୁଁ ବର୍ତ୍ତମାନ ଅଧ୍ୟୟନ କରିବାକୁ \n ସ୍ଥିର କରେ, ମୁଁ ଜାଣେ ନାହିଁ ମୁଁ କେବେ ଚାକିରି ପାଇବି | କେତେ ଦିନ ମୁଁ ତୁମ ଦୁହିଁଙ୍କଠାରୁ ବଞ୍ଚିବି?",
              "as": "যদি মই অহা বছৰ ভাল নকৰোঁ তেন্তে কি হ'ব? যদি মই এতিয়া পঢ়িবলৈ সিদ্ধান্ত লওঁ, মই কেতিয়াকৈ চাকৰি পাম নাজানো। মই তোমালোক দুয়োৰে ওপৰত কিমান দিন জীয়াই থাকিম?",
              "gu": "જો હું આવતા વર્ષે સારું નહીં કરું તો? જો હું હવે ભણવાનું નક્કી કરીશ તો મને ક્યારે નોકરી મળશે તે પણ ખબર નથી. હું તમારા બંનેના આધારે ક્યાં સુધી જીવીશ?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Madhav, you are overthinking. You are so close to achieving your dream.",
              "hi": "माधव, तुम अधिक सोच रहे हो। तुम अपने सपने को प्राप्त करने के बहुत करीब हो।",
              "ka": "ಮಾಧವ್, ನೀನು ಅತಿಯಾಗಿ ಯೋಚಿಸುತ್ತಿದ್ದೀಯಾ. ನಿನ್ನ ಕನಸನ್ನು ಸಾಧಿಸಿಕೊಳ್ಳುವಲ್ಲಿ ನೀನು ಅತಿ ಹತ್ತಿರದಲ್ಲಿದ್ದೀಯಾ.",
              "te": "మాధవ్, నువ్వు అతిగా ఆలోచిస్తున్నావు. నువ్వు నీ కలను సాకారం చేసుకోవడానికి చాలా దగ్గరగా ఉన్నావు.",
              "or": "ମାଧବ, ତୁମେ ଅତ୍ୟଧିକ ଚିନ୍ତା କରୁଛ | ତୁମର ସ୍ୱପ୍ନକୁ ସାକାର କରିବାକୁ\n  ତୁମେ ଏତେ ନିକଟତର |",
              "as": "মাধৱ, তুমি অত্যাধিক চিন্তা কৰিছা। তুমি তোমাৰ সপোন প্ৰাপ্ত কৰাৰ ইমান ওচৰত আছা।",
              "gu": "માધવ, તું વધારે પડતું વિચારી રહ્યો છે. તું તારા સ્વપ્નને પૂરું કરવાની ખૂબ નજીક છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_2_4",
              "hi": "A3_2_4",
              "ka": "A3_2_4",
              "te": "A3_2_4",
              "or": "A3_2_4",
              "as": "A3_2_4",
              "gu": "A3_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is unsure if he should depend on his friends for a year. He is also worried about how to provide for his father.",
              "hi": "माधव अनिश्चित है कि उसे एक साल के लिए अपने दोस्तों पर निर्भर होना चाहिए। वह अपने पिता की सहायता करने के बारे में भी चिंतित है।",
              "ka": "ಮಾಧವ್ ತನ್ನ ಸ್ನೇಹಿತರನ್ನು ಒಂದು ವರ್ಷ ಅವಲಂಬಿಸಬೇಕೇ ಎನ್ನುವುದರ ಬಗ್ಗೆ ಇನ್ನೂ ಖಚಿತ ನಿಲುವು ತೆಗೆದುಕೊಂಡಿಲ್ಲ. ತಂದೆಯನ್ನು ಹೇಗೆ ನೋಡಿಕೊಳ್ಳುವುದು ಎಂಬ ಚಿಂತೆಯೂ ಅವನಲ್ಲಿದೆ.",
              "te": "ఒక సంవత్సరం పాటు తన స్నేహితులపై ఆధారపడాలా వద్దా అని మాధవ్‌కి తెలియదు. తండ్రిని ఎలా పోషించాలా అని కూడా దిగులు చెందుతున్నాడు.",
              "or": "ମାଧବ ନିଶ୍ଚିତ ନୁହଁନ୍ତି ଯଦି ସେ ତାଙ୍କ ସାଙ୍ଗମାନଙ୍କ ଉପରେ ଏକ ବର୍ଷ \n ନିର୍ଭର କରନ୍ତି | ତାଙ୍କ ପିତାଙ୍କୁ କିପରି ଯୋଗାଇ ଦେବେ ସେ ନେଇ ମଧ୍ୟ ଚିନ୍ତିତ ଅଛନ୍ତି।",
              "as": "মাধৱ নিশ্চিত নহয় যে তেওঁ এবছৰৰ বাবে তেওঁৰ বন্ধুবৰ্গৰ ওপৰত নিৰ্ভৰ কৰিব নে নাই। তেওঁ তেওঁৰ দেউতাকৰ কেনেকৈ ভৰণপোষণ কৰিব তাক লৈও চিন্তিত।",
              "gu": "માધવને ખાતરી નથી કે તેણે એક વર્ષ માટે તેના મિત્રો પર આધાર રાખવો જોઈએ. તેના પિતાનું ભરણપોષણ કેવી રીતે કરવું તેની પણ તેને ચિંતા છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_2_5",
              "hi": "A3_2_5",
              "ka": "A3_2_5",
              "te": "A3_2_5",
              "or": "A3_2_5",
              "as": "A3_2_5",
              "gu": "A3_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "There is no certainty about clearing the exam next year, so he isn’t sure if he should continue working towards his dreams or be practical and look for other options.",
              "hi": "अगले वर्ष परीक्षा को पास करने के बारे में कोई निश्चितता नहीं है, इसलिए उसे निर्णय लेने में कठिनाई हो रही है कि उसे अपने सपनों को प्राप्त करने की ओर काम करना जारी रखना चाहिए या व्यावहारिक होकर अन्य विकल्पों की तलाश करनी चाहिए।",
              "ka": "ಮುಂದಿನ ವರ್ಷ ಪರೀಕ್ಷೆಯಲ್ಲಿ ತೇರ್ಗಡೆಯಾಗುವ ಬಗ್ಗೆ ಯಾವುದೇ ಖಚಿತತೆಯಿಲ್ಲ, ಆದ್ದರಿಂದ ಅವನು ತನ್ನ ಕನಸುಗಳನ್ನು ಮುಂದುವರೆಸಿಕೊಂಡು ಹೋಗಬೇಕೆ ಅಥವಾ ಪ್ರಾಯೋಗಿಕವಾಗಿರಬೇಕೇ ಮತ್ತು ಇತರ ಆಯ್ಕೆಗಳನ್ನು ಹುಡುಕಬೇಕೆ ಎನ್ನುವುದರ ಬಗ್ಗೆ ಇನ್ನೂ  ಖಚಿತ ನಿಲುವನ್ನು ಹೊಂದಿಲ್ಲ..",
              "te": "వచ్చే ఏడాది పరీక్షలో ఉత్తీర్ణత సాధిస్తాడా లేదా అని నమ్మకం లేదు, కాబట్టి అతను తన కలల కోసం పని చేయడం కొనసాగించాలా లేదా ప్రాక్టికల్ గా ఆలోచించి ఇతర ఎంపికల కోసం వెతకాలా అని అతనికి తెలియడం లేదు.",
              "or": "ଆସନ୍ତା ବର୍ଷ ପରୀକ୍ଷା ସଫା କରିବା ବିଷୟରେ କୌ ଣସି ନିଶ୍ଚିତତା ନାହିଁ,\n  ତେଣୁ ସେ ନିଶ୍ଚିତ ନୁହଁନ୍ତି ଯେ ସେ ତାଙ୍କ ସ୍ୱପ୍ନ ଉପରେ କାର୍ଯ୍ୟ ଜାରି ରଖିବେ କିମ୍ବା ବ୍ୟବହାରିକ ହୋଇ ଅନ୍ୟ ବିକଳ୍ପ ଖୋଜିବେ |",
              "as": "অহা বছৰ পৰীক্ষাত উত্তীৰ্ণ হোৱাৰ কোনো নিশ্চয়তা নাই, সেয়েহে তেওঁ নিশ্চিত নহয় যে তেওঁ তেওঁৰ সপোনৰ বাবে কাম কৰি থাকিব নে ব্যৱহাৰিক হ'ব আৰু আন বিকল্প বিচাৰিব।",
              "gu": "આવતા વર્ષે પરીક્ષા પાસ કરવા અંગે કોઈ નિશ્ચિતતા નથી, તેથી તેને ખાતરી નથી કે તેણે તેના સપનાઓ તરફ કામ કરવાનું ચાલુ રાખવું જોઈએ કે વ્યવહારુ રહેવું જોઈએ અને અન્ય વિકલ્પો શોધવા જોઈએ."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_2_6_D",
              "hi": "A3_2_6_D",
              "ka": "A3_2_6_D",
              "te": "A3_2_6_D",
              "or": "A3_2_6_D",
              "as": "A3_2_6_D",
              "gu": "A3_2_6_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Madhav do?",
              "hi": "माधव को क्या करना चाहिए?",
              "ka": "ಮಾಧವ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "మాధవ్ ఏం చేయాలి?",
              "or": "ମାଧବ କଣ କରିବା ଉଚ୍ଚିତ?",
              "as": "মাধৱে কি কৰা উচিত?",
              "gu": "માધવે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Look for other options",
              "hi": "अन्य विकल्पों की खोज करे",
              "ka": "ಇತರ ಆಯ್ಕೆಗಳನ್ನು ಪರಿಗಣಿಸಬೇಕೆ",
              "te": "ఇతర ఎంపికల కోసం చూడండి",
              "or": "ଅନ୍ୟାନ୍ୟ ବିକଳ୍ପ ଖୋଜ |",
              "as": "অন্য বিকল্পসমূহ বিচাৰিব",
              "gu": "અન્ય વિકલ્પો માટે જુઓ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Stick to the UPSC preparation",
              "hi": "यूपीएससी तैयारी करता रहे",
              "ka": "UPSC ತಯಾರಿಗೆ ಅಂಟಿಕೊಳ್ಳುವುದೇ",
              "te": "UPSC ప్రిపరేషన్‌కు కట్టుబడి ఉండాలి",
              "or": "UPSC ପ୍ରସ୍ତୁତି ସହିତ ଜଡ଼ିତ ରୁହ|",
              "as": "UPSCৰ প্ৰস্তুতিত লাগি থাকিব",
              "gu": "UPSCની તૈયારીને વળગી રહો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.tag",
            "value": {
              "en": "Exploring career alternatives",
              "hi": "कैरियर विकल्पों की खोज",
              "ka": "ವೃತ್ತಿ ಪರ್ಯಾಯಗಳನ್ನು ಅನ್ವೇಷಿಸುವುದೇ",
              "te": "కెరీర్ ప్రత్యామ్నాయాలను అన్వేషించాలి",
              "or": "କ୍ୟାରିୟର ବିକଳ୍ପଗୁଡିକ ଅନୁସନ୍ଧାନ |",
              "as": "জীৱিকাৰ বিকল্প অন্বেষণ কৰিব",
              "gu": "કારકિર્દીના વિકલ્પોની શોધ"
            }
          }
        ]
      },
      "scene14": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Continue preparing  for UPSC",
              "hi": "यूपीएससी के लिए तैयारी जारी रखे",
              "ka": "UPSC ಗಾಗಿ ತಯಾರಿಯನ್ನು ಮುಂದುವರಿಸುವುದು",
              "te": "UPSC ప్రిపరేషన్ కొనసాగించాలి",
              "or": "UPSC ପାଇଁ ପ୍ରସ୍ତୁତି ଜାରି ରଖ |",
              "as": "UPSCৰ বাবে প্ৰস্তুতি অব্যাহত ৰাখিব",
              "gu": "UPSC ની તૈયારી ચાલુ રાખો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "For the third attempt, Madhav joins a coaching centre to help him with a few subjects.",
              "hi": "तीसरे प्रयास के लिए, माधव कुछ विषयों में सहायता के लिए एक कोचिंग सेंटर में दाखिला लेता है।",
              "ka": "ಮೂರನೇ ಪ್ರಯತ್ನಕ್ಕಾಗಿ, ಮಾಧವ್ ಕೆಲವು ವಿಷಯಗಳಿಗೆ ಸಹಾಯ ಪಡೆದುಕೊಳ್ಳಲು ಕೋಚಿಂಗ್ ಸೆಂಟರ್‌ಗೆ ಸೇರುತ್ತಾನೆ.",
              "te": "మూడవ ప్రయత్నంగా, మాధవ్ కొన్ని సబ్జెక్ట్‌లలో అతనికి సహాయం కోసం కోచింగ్ సెంటర్‌లో చేరాడు.",
              "or": "ତୃତୀୟ ପ୍ରୟାସ ପାଇଁ, ମାଧବ ତାଙ୍କୁ କିଛି ପ୍ରସଙ୍ଗରେ ସାହାଯ୍ୟ କରିବା \n ପାଇଁ ଏକ କୋଚିଂ ସେଣ୍ଟରରେ ଯୋଗ ଦେଇଥିଲେ |",
              "as": "তৃতীয় প্ৰচেষ্টাৰ বাবে, মাধৱে তেওঁৰ কেইটামান বিষয়ত সহায় হ’বলৈ এটা প্ৰশিক্ষণ কেন্দ্ৰত যোগদান কৰে।",
              "gu": "ત્રીજા પ્રયાસ માટે, માધવને થોડા વિષયોમાં મદદ મળે તે માટે કોચિંગ સેન્ટરમાં જોડાય છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_1_2",
              "hi": "A5_1_2",
              "ka": "A5_1_2",
              "te": "A5_1_2",
              "or": "A5_1_2",
              "as": "A5_1_2",
              "gu": "A5_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He also works part-time, 2 hours in the morning and 2 hours in the evening. Badri and Kuldeep also support him financially to pay for his coaching.",
              "hi": "वह सुबह 2 घंटे और शाम को 2 घंटे अंशकालिक काम भी करता है। बद्री और कुलदीप भी उसकी कोचिंग का भुगतान करने के लिए वित्तीय रूप से उसकी सहायता करते हैं।",
              "ka": "ಅವನು ಅರೆಕಾಲಿಕ, ಬೆಳಿಗ್ಗೆ 2 ಗಂಟೆ ಮತ್ತು ಸಂಜೆ 2 ಗಂಟೆ ಕೆಲಸ ಮಾಡುತ್ತಾನೆ. ಬದ್ರಿ ಮತ್ತು ಕುಲದೀಪ್ ಅವನ ಕೋಚಿಂಗ್‌ಗೆ ಹಣ ನೀಡಲು ಆರ್ಥಿಕವಾಗಿ ಬೆಂಬಲ ನೀಡುತ್ತಾರೆ.",
              "te": "అతను ఉదయం 2 గంటలు మరియు సాయంత్రం 2 గంటలు పార్ట్ టైమ్ పని చేస్తాడు. బద్రి మరియు కుల్దీప్ కూడా అతని కోచింగ్ కోసం ఆర్థికంగా సహాయం చేస్తారు.",
              "or": "ସେ ପାର୍ଟ ଟାଇମ୍, ସକାଳେ 2 ଘଣ୍ଟା ଏବଂ ସନ୍ଧ୍ୟାରେ 2 ଘଣ୍ଟା କାମ କରନ୍ତି |\n  ବଦ୍ରି ଏବଂ କୁଲଦୀପ ମଧ୍ୟ ତାଙ୍କ କୋଚିଂ ପାଇଁ ଅର୍ଥ ଦେବାକୁ ତାଙ୍କୁ ଆର୍ଥିକ ସହାୟତା କରନ୍ତି |",
              "as": "তেওঁ আংশিক সময়, ৰাতিপুৱা ২ঘণ্টা আৰু সন্ধিয়া ২ঘণ্টা কাম কৰে। বদ্ৰী আৰু কুলদীপেও তাৰ প্ৰশিক্ষণৰ বাবে পৰিশোধ কৰিবলৈ তাক আৰ্থিকভাৱে সহায় কৰে।",
              "gu": "તે થોડોક સમય માટે કામ કરે છે, સવારે 2 કલાક અને સાંજે 2 કલાક. બદ્રી અને કુલદીપ પણ તેના કોચિંગનો ખર્ચ ઉઠાવવા માટે તેને આર્થિક મદદ કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_1_3",
              "hi": "A5_1_3",
              "ka": "A5_1_3",
              "te": "A5_1_3",
              "or": "A5_1_3",
              "as": "A5_1_3",
              "gu": "A5_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The teacher at the coaching centre encourages the students to write articles for newspapers and digital magazines to practice their writing skills. Madhav enjoys that a lot.",
              "hi": "कोचिंग सेंटर में शिक्षक छात्रों को अपने लेखन कौशल का अभ्यास करने के लिए समाचार पत्रों और डिजिटल पत्रिकाओं के लिए लेख लिखने के लिए प्रोत्साहित करते हैं। माधव को यह बहुत पसंद है।",
              "ka": "ಕೋಚಿಂಗ್ ಸೆಂಟರ್‌ನಲ್ಲಿರುವ ಶಿಕ್ಷಕರು ತಮ್ಮ ಬರವಣಿಗೆಯ ಕೌಶಲ್ಯವನ್ನು ಅಭ್ಯಾಸ ಮಾಡಲು ಪತ್ರಿಕೆಗಳು ಮತ್ತು ಡಿಜಿಟಲ್ ನಿಯತಕಾಲಿಕೆಗಳಿಗೆ ಲೇಖನಗಳನ್ನು ಬರೆಯಲು ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಪ್ರೋತ್ಸಾಹಿಸುತ್ತಾರೆ. ಮಾಧವ್ ಅದನ್ನು ತುಂಬಾ ಆನಂದಿಸುತ್ತಾನೆ.",
              "te": "కోచింగ్ సెంటర్‌లోని ఉపాధ్యాయులు విద్యార్థులను వారి రైటింగ్ స్కిల్స్ పెంచుకోవడానికి వార్తాపత్రికలు మరియు డిజిటల్ మ్యాగజైన్‌లకు కథనాలు రాయమని ప్రోత్సహిస్తారు. మాధవ్ దానిని చాలా ఆనందిస్తాడు.",
              "or": "କୋଚିଂ ସେଣ୍ଟରର ଶିକ୍ଷକ ଛାତ୍ରମାନଙ୍କୁ ସେମାନଙ୍କର ଲେଖା ଦକ୍ଷତା ଅଭ୍ୟାସ\n  କରିବା ପାଇଁ ଖବରକାଗଜ ଏବଂ ଡିଜିଟାଲ ପତ୍ରିକା ପାଇଁ ପ୍ରବନ୍ଧ ଲେଖିବାକୁ ଉତ୍ସାହିତ କରନ୍ତି | ମାଧବ ଏହାକୁ ବହୁତ ଉପଭୋଗ କରନ୍ତି |",
              "as": "প্ৰশিক্ষণ কেন্দ্ৰৰ শিক্ষকজনে শিক্ষাৰ্থীসকলক তেওঁলোকৰ লিখাৰ দক্ষতা অনুশীলন কৰিবলৈ বাতৰি কাকত আৰু ডিজিটেল আলোচনীসমূহৰ বাবে প্ৰবন্ধ লিখিবলৈ উৎসাহিত কৰে। মাধৱে সেইটো বহুত উপভোগ কৰে।",
              "gu": "કોચિંગ સેન્ટરના શિક્ષક વિદ્યાર્થીઓને તેમની લેખન કૌશલ્યનો અભ્યાસ કરવા માટે અખબારો અને ડિજિટલ સામયિકો માટે લેખો લખવા પ્રોત્સાહિત કરે છે. માધવને એનો ઘણો આનંદ થાય છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_2_1",
              "hi": "A5_2_1",
              "ka": "A5_2_1",
              "te": "A5_2_1",
              "or": "A5_2_1",
              "as": "A5_2_1",
              "gu": "A5_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "With writing as a part-time job, he can now send money to his father. Even though it is not the amount his father expects, he doesn’t bother Madhav anymore.",
              "hi": "अंशकालिक नौकरी के रूप में लिखने के साथ, अब वह अपने पिता को पैसे भेज सकता है। भले ही राशि उसके पिता की आशा के अनुरूप नहीं है, फिर भी वह माधव को परेशान नहीं करते हैं।",
              "ka": "ಬರೆಯುವುದನ್ನು ಆರೆಕಾಲಿಕ ಉದ್ಯೋಗವಾಗಿ ಹೊಂದಿರುವುದರಿಂದ , ಅವನು ಈಗ ತನ್ನ ತಂದೆಗೆ ಹಣವನ್ನು ಕಳುಹಿಸಬಹುದು. ಇದು ಅವನ ತಂದೆ ನಿರೀಕ್ಷಿಸುವ ಮೊತ್ತವಲ್ಲದಿದ್ದರೂ, ಅವರು ಇನ್ನು ಮುಂದೆ ಮಾಧವ್‌ಗೆ ತೊಂದರೆ ಕೊಡುವುದಿಲ್ಲ.",
              "te": "పార్ట్ టైమ్ జాబ్‌గా రాయడం ద్వారా, అతను ఇప్పుడు తన తండ్రికి డబ్బు పంపగలడు. తన తండ్రి ఆశించిన మొత్తం కానప్పటికీ, అతను మాధవ్‌ను ఇబ్బంది పెట్టడు.",
              "or": "ପାର୍ଟ ଟାଇମ୍ ଚାକିରି ଭାବରେ ଲେଖିବା ସହିତ ସେ ବର୍ତ୍ତମାନ ତାଙ୍କ ପିତାଙ୍କ \n ନିକଟକୁ ଟଙ୍କା ପଠାଇ ପାରିବେ | ଯଦିଓ ଏହା ତାଙ୍କ ପିତା ଆଶା କରୁଥିବା ପରିମାଣ ନୁହେଁ, ସେ ଆଉ ମାଧବଙ୍କୁ ବ୍ୟଥିତ କରନ୍ତି ନାହିଁ |",
              "as": "আংশিক সময়ৰ চাকৰি হিচাপে লিখাৰ সৈতে, তেওঁ এতিয়া তেওঁৰ দেউতাকলৈ টকাও প্ৰেৰণ কৰিব পাৰে। যদিও এইটো তেওঁৰ দেউতাকে আশা কৰা পৰিমাণ নহয়, তেওঁ মাধৱক আৰু আমনি নকৰে।",
              "gu": "અડધો સમય નોકરી તરીકે લેખન સાથે, તે હવે તેના પિતાને પૈસા મોકલી શકે છે. તેના પિતાની અપેક્ષા મુજબ તે રકમ ન હોવા છતાં, તેના પિતા હવે માધવને પરેશાન કરતા નથી."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_2_2",
              "hi": "A5_2_2",
              "ka": "A5_2_2",
              "te": "A5_2_2",
              "or": "A5_2_2",
              "as": "A5_2_2",
              "gu": "A5_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After preparing well for a year, Madhav is confident that he will clear the preliminary exam.",
              "hi": "एक साल की अच्छी तैयारी के बाद, माधव आश्वस्त है कि वह प्रारंभिक परीक्षा को उत्तीर्ण कर लेगा।",
              "ka": "ಒಂದು ವರ್ಷ ಚೆನ್ನಾಗಿ ತಯಾರಿ ನಡೆಸಿದ್ದು, ಪ್ರಿಲಿಮಿನರಿ ಪರೀಕ್ಷೆಯಲ್ಲಿ ತೇರ್ಗಡೆಯಾಗುವ ವಿಶ್ವಾಸದಲ್ಲಿದ್ದಾನೆ ಮಾಧವ್.",
              "te": "ఒక సంవత్సరం పాటు బాగా ప్రిపేర్ అయ్యాక, ప్రిలిమినరీ పరీక్ష క్లియర్ చేస్తాడని మాధవ్ నమ్మకంగా ఉన్నాడు.",
              "or": "ଏକ ବର୍ଷ ପାଇଁ ଭଲ ପ୍ରସ୍ତୁତି ପରେ ମାଧବ ନିଶ୍ଚିତ ଯେ ସେ ପ୍ରାଥମିକ ପରୀକ୍ଷା\n  ସଫା କରିବେ |",
              "as": "এবছৰ ভালদৰে প্ৰস্তুতি কৰাৰ পিছত, মাধৱ নিশ্চিত যে তেওঁ প্ৰাথমিক পৰীক্ষাত উত্তীৰ্ণ হ'ব।",
              "gu": "એક વર્ષ સુધી સારી તૈયારી કર્યા પછી, માધવને વિશ્વાસ છે કે તે પ્રિલિમિનરી પરીક્ષા પાસ કરશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_3_1",
              "hi": "A5_3_1",
              "ka": "A5_3_1",
              "te": "A5_3_1",
              "or": "A5_3_1",
              "as": "A5_3_1",
              "gu": "A5_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav appears for the preliminary exam for the third time. When he finishes the paper, he is hopeful that he will succeed.",
              "hi": "माधव तीसरी बार प्रारंभिक परीक्षा के लिए उपस्थित होता है। जब वह पेपर खत्म करता है, तो वह आशा करता है कि वह सफल होगा।",
              "ka": "ಮಾಧವ್ ಮೂರನೇ ಬಾರಿಗೆ ಪೂರ್ವಭಾವಿ ಪರೀಕ್ಷೆಗೆ ಹಾಜರಾಗಿದ್ದಾನೆ. ಪತ್ರಿಕೆಯನ್ನು ಮುಗಿಸಿದಾಗ, ತಾನು ಯಶಸ್ವಿಯಾಗುತ್ತೇನೆ ಅನ್ನುವ ಭರವಸೆ ಇದೆ.",
              "te": "మాధవ్ మూడోసారి ప్రిలిమినరీ పరీక్షకు హాజరయ్యాడు. పేపర్ పూర్తయ్యాక అతను ఈసారి సాధిస్తాడని ఆశాభావం వ్యక్తం చేశారు.",
              "or": "ମାଧବ ତୃତୀୟ ଥର ପାଇଁ ପ୍ରାଥମିକ ପରୀକ୍ଷା ପାଇଁ ହାଜର ହୁଅନ୍ତି | ଯେତେବେଳେ\n  ସେ କାଗଜ ସମାପ୍ତ କରନ୍ତି, ସେ ଆଶା କରନ୍ତି ଯେ ସେ ସଫଳ ହେବେ |",
              "as": "মাধৱে তৃতীয়বাৰৰ বাবে প্ৰাথমিক পৰীক্ষাত অৱতীৰ্ণ হয়। যেতিয়া তেওঁ কাকতখন শেষ কৰে, তেওঁ আশাবাদী যে তেওঁ সফল হ'ব।",
              "gu": "માધવ ત્રીજી વખત પ્રિલિમિનરી એક્ઝામ માટે હાજર થયો. જ્યારે તે પેપર પૂરું કરે છે, ત્યારે તેને આશા છે કે તે સફળ થશે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_3_2",
              "hi": "A5_3_2",
              "ka": "A5_3_2",
              "te": "A5_3_2",
              "or": "A5_3_2",
              "as": "A5_3_2",
              "gu": "A5_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "On the day of the prelims result, Kuldeep and Badri stand next to Madhav while he opens the result.",
              "hi": "प्रारम्भिक परीक्षा के परिणाम के दिन, कुलदीप और बद्री माधव के साथ खड़े रहते हैं जब वह परिणाम खोलता है।",
              "ka": "ಪ್ರಿಲಿಮ್ಸ್ ಫಲಿತಾಂಶದ ದಿನ, ಕುಲದೀಪ್ ಮತ್ತು ಬದ್ರಿ ಫಲಿತಾಂಶವನ್ನು ತೆರೆಯುವಾಗ ಮಾಧವ್ ಅವರ ಪಕ್ಕದಲ್ಲಿ ನಿಲ್ಲುತ್ತಾನೆ.",
              "te": "ప్రిలిమ్స్ రిజల్ట్ వచ్చిన రోజు, మాధవ్ రిజల్ట్ ఓపెన్ చేస్తున్నప్పుడు కుల్దీప్ మరియు బద్రి పక్కన నిలబడి ఉన్నారు.",
              "or": "ପ୍ରିଲିମ୍ ଫଳାଫଳ ଦିନ କୁଲଦୀପ ଏବଂ ବଦ୍ରି ମାଧବଙ୍କ ପାଖରେ ଛିଡା \n ହୋଇଥିବାବେଳେ ସେ ଫଳାଫଳ ଖୋଲନ୍ତି |",
              "as": "প্ৰাৰম্ভিক পৰীক্ষাৰ ফলাফলৰ দিনা, কুলদীপ আৰু বদ্ৰী মাধৱৰ কাষত থিয় হয় যেতিয়া তেওঁ ফলাফলটো খোলে।",
              "gu": "પ્રિલિમ્સના પરિણામના દિવસે, કુલદીપ અને બદ્રી માધવની બાજુમાં ઉભા છે જ્યારે તે પરિણામ ખોલે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_3_3//",
              "hi": "A5_3_3 //",
              "ka": "A5_3_3//",
              "te": "A5_3_3//",
              "or": "A5_3_3//",
              "as": "A5_3_3//",
              "gu": "A5_3_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୁଳଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "You both don’t look!",
              "hi": "तुम दोनों नहीं देखो!",
              "ka": "ನೀವಿಬ್ಬರೂ ಇದನ್ನು ನೋಡಬೇಡಿ!",
              "te": "మీరిద్దరూ చూడకండి!",
              "or": "ତୁମେ ଦୁହେଁ ଦେଖିବ ନାହିଁ!",
              "as": "তোমালোক দুয়োজনে নেচাবা!",
              "gu": "તમે બંને દેખાતા નથી!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "We are not leaving you alone, Madhav. You open the result!",
              "hi": "हम तुमको अकेले नहीं छोड़ सकते हैं, माधव। तुम परिणाम खोलो!",
              "ka": "ನಾವು ನಿನ್ನನ್ನು ಒಬ್ಬನನ್ನೇ ಬಿಡುವುದಿಲ್ಲ ಮಾಧವ್. ನೀನು ಫಲಿತಾಂಶವನ್ನು ತೆಗಿ!",
              "te": "నిన్ను ఒంటరిగా వదలం మాధవ్. నువ్వు రిజల్ట్ ఓపెన్ చేయి!",
              "or": "ଆମେ ତୁମକୁ ଏକୁଟିଆ ଛାଡୁ ନାହୁଁ, ମାଧବ | ଆପଣ ଫଳାଫଳ ଖୋଲନ୍ତୁ!",
              "as": "আমি তোমাক অকলে এৰি নাযাওঁ, মাধৱ। তুমি ফলাফলটো খোলা!",
              "gu": "માધવ, અમે તને એકલો નથી છોડીવના. તું પરિણામ ખોલ!"
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_3_4(reveal)",
              "hi": "A5_3_4 (रिवील)",
              "ka": "A5_3_4(reveal)",
              "te": "A5_3_4(రివీల్)",
              "or": "A5_3_4 (ପ୍ରକାଶ)",
              "as": "A5_3_4(প্ৰকাশ)",
              "gu": "A5_3_4(જાહેર કરો)"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to see Madhav's rank and the cut off rank.",
              "hi": "माधव की रैंक और कट ऑफ रैंक देखने के लिए क्लिक करें।",
              "ka": "ಮಾಧವನ ಶ್ರೇಣಿ ಮತ್ತು ಕಟ್ ಆಫ್ ಶ್ರೇಣಿಯನ್ನು ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "మాధవ్ ర్యాంక్ మరియు కట్ ఆఫ్ ర్యాంక్ చూడటానికి క్లిక్ చేయండి.",
              "or": "ମାଧବଙ୍କ ର୍ୟାଙ୍କ ଏବଂ କଟ୍ ଅଫ୍ ର୍ୟାଙ୍କ ଦେଖିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "মাধৱৰ ৰেংক আৰু কাট অফ ৰেংক চাবলৈ ক্লিক কৰা।",
              "gu": "માધવનો ક્રમ અને કપાત ગુણ જોવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Madhav's rank",
              "hi": "माधव की रैंक",
              "ka": "ಮಾಧವಣ ಶ್ರೇನಿ",
              "te": "మాధవ్ ర్యాంక్",
              "or": "ମାଧବଙ୍କ ପଦବୀ |",
              "as": "মাধৱৰ ৰেংক",
              "gu": "માધવનો ક્રમ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Cut off rank",
              "hi": "कट ऑफ रैंक",
              "ka": "ಕಟ್ ಆಫ್ ಶ್ರೇಣಿ",
              "te": "కట్ ఆఫ్ ర్యాంక్",
              "or": "ର୍ୟାଙ୍କ୍ କାଟ |",
              "as": "কাটঅফ ৰেংক",
              "gu": "કપાત ગુણ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "9,897",
              "hi": "9,897",
              "ka": "9,897",
              "te": "9,897",
              "or": "୯,୮୯୭",
              "as": "9,897",
              "gu": "9,897"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "11, 560",
              "hi": "11, 560",
              "ka": "11, 560",
              "te": "11, 560",
              "or": "୧୧,୫୬୦",
              "as": "11, 560",
              "gu": "11, 560 છે"
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_4_2////",
              "hi": "A5_4_2 /////",
              "ka": "A5_4_2////",
              "te": "A5_4_2////",
              "or": "A5_4_2////",
              "as": "A5_4_2////",
              "gu": "A5_4_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Kuldeep and Badri start dancing in joy.",
              "hi": "कुलदीप और बद्री खुशी से नाचने लगते हैं।",
              "ka": "ಕುಲ್ದೀಪ್ ಮತ್ತು ಬದ್ರಿ ಸಂತೋಷದಿಂದ ನೃತ್ಯ ಮಾಡಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ.",
              "te": "కుల్దీప్ మరియు బద్రీ ఆనందంతో డ్యాన్స్ చేయడం ప్రారంభిస్తారు.",
              "or": "କୁଲଦୀପ ଏବଂ ବଦ୍ରି ଆନନ୍ଦରେ ନାଚିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "কুলদীপ আৰু বদ্ৰীয়ে আনন্দত নাচিবলৈ লাগি গ’ল।",
              "gu": "કુલદીપ અને બદ્રી આનંદમાં નાચવા લાગ્યા."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "The first step is done Madhav!! The first step is done!",
              "hi": "पहला कदम पार हो गया माधव !! पहला कदम पार हो गया!",
              "ka": "ಮೊದಲ ಹೆಜ್ಜೆ ಮುಗಿದಿದೆ ಮಾಧವ್!! ಮೊದಲ ಹೆಜ್ಜೆ ಮುಗಿದಿದೆ!",
              "te": "మొదటి అడుగు పడింది మాధవ్!! మొదటి అడుగు పూర్తయింది!",
              "or": "ପ୍ରଥମ ପଦକ୍ଷେପ ହେଉଛି ମାଧବ !! ପ୍ରଥମ ସୋପାନ ଶେଷ ହୋଇଛି!",
              "as": "প্ৰথম পদক্ষেপটো সম্পন্ন হ’ল মাধৱ!! প্ৰথম পদক্ষেপটো সম্পন্ন হ’ল!",
              "gu": "પહેલું પગલું પૂરું થયું માધવ!! પ્રથમ પગલું પૂર્ણ થઈ ગયું છે!"
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_4_3",
              "hi": "A5_4_3",
              "ka": "A5_4_3",
              "te": "A5_4_3",
              "or": "A5_4_3",
              "as": "A5_4_3",
              "gu": "A5_4_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They go back to their room and celebrate. Kuldeep orders food from their favourite restaurant, and Badri gets ice cream from the dairy.",
              "hi": "वे अपने कमरे में वापस जाते हैं और खुशियाँ मनाते हैं। कुलदीप ने अपने पसंदीदा रेस्तरां से भोजन का आदेश दिया, और बद्री डेयरी से आइसक्रीम लाता है।",
              "ka": "ಅವರು ತಮ್ಮ ಕೋಣೆಗೆ ಹಿಂತಿರುಗಿ ಸಂಭ್ರಮಿಸುತ್ತಾರೆ. ಕುಲದೀಪ್ ತಮ್ಮ ನೆಚ್ಚಿನ ರೆಸ್ಟೋರೆಂಟ್‌ನಿಂದ ಆಹಾರವನ್ನು ಆರ್ಡರ್ ಮಾಡುತ್ತಾನೆ ಮತ್ತು ಬದರಿಯು ಡೈರಿಯಿಂದ ಐಸ್‌ಕ್ರೀಮ್ ತರಿಸುತ್ತಾನೆ",
              "te": "వారు తమ గదికి తిరిగి వెళ్లి సెలెబ్రేట్ చేసుకుంటారు. కుల్దీప్ వారికి ఇష్టమైన రెస్టారెంట్ నుండి ఆహారాన్ని ఆర్డర్ చేస్తాడు మరియు బద్రి డెయిరీ నుండి ఐస్ క్రీం తీసుకువస్తాడు.",
              "or": "ସେମାନେ ନିଜ କୋଠରୀକୁ ଫେରି ଉତ୍ସବ ପାଳନ କରନ୍ତି | କୁଲଦୀପ\n ସେମାନଙ୍କ ପ୍ରିୟ ରେଷ୍ଟୁରାଣ୍ଟରୁ ଖାଦ୍ୟ ଅର୍ଡର କରନ୍ତି, ଏବଂ ବଦ୍ରି ଦୁଗ୍ଧରୁ ଆଇସକ୍ରିମ୍ ପାଆନ୍ତି |",
              "as": "তেওঁলোকে তেওঁলোকৰ কোঠালৈ উভতি যায় আৰু উদযাপন কৰে। কুলদীপে তেওঁলোকৰ প্ৰিয় ৰেষ্টুৰেণ্টৰ পৰা খাদ্য অৰ্ডাৰ কৰে, আৰু বদ্ৰীয়ে ডেইৰিৰ পৰা আইচক্ৰীম আনে।",
              "gu": "તેઓ તેમના રૂમમાં પાછા જાય છે અને ઉજવણી કરે છે. કુલદીપ તેમની મનપસંદ રેસ્ટોરન્ટમાંથી ભોજન મંગાવે છે અને બદ્રીને ડેરીમાંથી આઈસ્ક્રીમ લાવે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_5_1",
              "hi": "A5_5_1",
              "ka": "A5_5_1",
              "te": "A5_5_1",
              "or": "A5_5_1",
              "as": "A5_5_1",
              "gu": "A5_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to call his father and inform him.",
              "hi": "माधव ने अपने पिता को फोन करने और उन्हें सूचित करने का निश्चय किया।",
              "ka": "ಮಾಧವ್ ತನ್ನ ತಂದೆಗೆ ಕರೆ ಮಾಡಿ ತಿಳಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్ తన తండ్రికి ఫోన్ చేసి విషయం చెప్పాలని నిర్ణయించుకున్నాడు.",
              "or": "ମାଧବ ତାଙ୍କ ପିତାଙ୍କୁ ଡାକି ତାଙ୍କୁ ଜଣାଇବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି।",
              "as": "মাধৱে তাৰ দেউতাকক ফোন কৰি জনোৱাৰ সিদ্ধান্ত লয়।",
              "gu": "માધવે તેના પિતાને ફોન કરીને જાણ કરવાનું નક્કી કર્યું."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_5_2//",
              "hi": "A5_5_2 //",
              "ka": "A5_5_2//",
              "te": "A5_5_2//",
              "or": "A5_5_2//",
              "as": "A5_5_2//",
              "gu": "A5_5_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Father",
              "hi": "पिता",
              "ka": "ತಂದೆ",
              "te": "తండ్రి",
              "or": "ବାପା",
              "as": "দেউতাক",
              "gu": "પિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Papa, I cleared the first round of my Civil Service exam. I have the next exam in two months.",
              "hi": "पिताजी, मैंने अपनी सिविल सेवा परीक्षा के पहले स्तर को पार कर लिया। मेरी अगली परीक्षा दो महीने में  है।",
              "ka": "ಅಪ್ಪಾ, ನಾನು ನನ್ನ ಸಿವಿಲ್ ಸರ್ವೀಸ್ ಪರೀಕ್ಷೆಯ ಮೊದಲ ಸುತ್ತಿನಲ್ಲಿ ತೇರ್ಗಡೆಯಾಗಿದ್ದೇನೆ. ನನಗೆ ಎರಡು ತಿಂಗಳಲ್ಲಿ ಮುಂದಿನ ಪರೀಕ್ಷೆ ಇದೆ.",
              "te": "పాపా, నేను నా సివిల్ సర్వీస్ పరీక్షలో మొదటి రౌండ్ క్లియర్ చేశాను. నాకు రెండు నెలల్లో తదుపరి పరీక్ష ఉంది.",
              "or": "ପାପା, ମୁଁ ମୋର ସିଭିଲ ସର୍ଭିସ ପରୀକ୍ଷାର ପ୍ରଥମ ରାଉଣ୍ଡ ପାସ କଲି |\n  ମୋର ଦୁଇ ମାସ ମଧ୍ୟରେ ପରବର୍ତ୍ତୀ ପରୀକ୍ଷା ଅଛି |",
              "as": "পাপা, মই মোৰ চিভিল চাৰ্ভিচ পৰীক্ষাৰ প্ৰথম ৰাউণ্ডত উত্তীৰ্ণ হ’লো। মোৰ দুমাহৰ ভিতৰত পৰৱৰ্তী পৰীক্ষা আছে।",
              "gu": "પપ્પા, મેં મારી સિવિલ સર્વિસની પરીક્ષાનો પહેલો રાઉન્ડ પૂર્ણ કર્યો છે. મારી આગામી પરીક્ષા બે મહિનામાં છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "So now you will have a government job and government salary?",
              "hi": "तो अब तुम्हारे पास सरकारी नौकरी और सरकारी वेतन होगा?",
              "ka": "ಹಾಗಾದರೆ ಈಗ ನಿನಗೆ ಸರ್ಕಾರಿ ಕೆಲಸ ಮತ್ತು ಸರ್ಕಾರಿ ಸಂಬಳವಿದೆಯೇ?",
              "te": "అంటే ఇప్పుడు నీకు ప్రభుత్వ ఉద్యోగం మరియు ప్రభుత్వ జీతం వస్తుందా?",
              "or": "ତେବେ ବର୍ତ୍ତମାନ ତୁମର ସରକାରୀ ଚାକିରି ଏବଂ ସରକାରୀ ଦରମା ପାଇବ କି?",
              "as": "গতিকে এতিয়া তোমাৰ চৰকাৰী চাকৰি আৰু চৰকাৰী দৰমহা থাকিব?",
              "gu": "તો હવે તારી પાસે સરકારી નોકરી અને સરકારી પગાર હશે?"
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_5_3////",
              "hi": "A5_5_3 /////",
              "ka": "A5_5_3////",
              "te": "A5_5_3////",
              "or": "A5_5_3////",
              "as": "A5_5_3////",
              "gu": "A5_5_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Hopefully, in some time.",
              "hi": "आशा यही है, कुछ समय में।",
              "ka": "ಆಶಾದಾಯಕವಾಗಿ, ಸ್ವಲ್ಪ ಸಮಯದಲ್ಲಿ.",
              "te": "దానికి కొంత సమయం పట్టవచ్చు",
              "or": "ଆଶାକରେ, କିଛି ସମୟ ମଧ୍ୟରେ |",
              "as": "আশা কৰোঁ, কিছু সময়ৰ ভিতৰত।",
              "gu": "આશા છે કે, થોડા સમયમાં."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "What do you mean, hopefully? After three years of wasting your time on this exam you are still not sure?",
              "hi": "तुम्हारा क्या मतलब है, आशा है? इस परीक्षा में अपना समय बर्बाद करने के तीन साल बाद तुम अभी भी निश्चित नहीं हो?",
              "ka": "ಆಶಾದಾಯಕವಾಗಿ ಅಂದರೆ ನಿನ್ನ ಅರ್ಥವೇನು? ಈ ಪರೀಕ್ಷೆಯಲ್ಲಿ ನಿನ್ನ ಸಮಯವನ್ನು ವ್ಯರ್ಥ ಮಾಡಿದ ಮೂರು ವರ್ಷಗಳ ನಂತರ ನಿನಗೆ ಇನ್ನೂ ಖಚಿತವಾಗಿಲ್ಲವೇ?",
              "te": "నువ్వు ఏం అంటున్నావు? ఈ పరీక్ష కోసం మూడేళ్ల సమయాన్ని వృధా చేసిన తర్వాత కూడా నీకు ఇంకా ఖచ్చితంగా తెలియదా?",
              "or": "ଆଶାକରେ ତୁମେ କ’ଣ କହିବାକୁ ଚାହୁଁଛ? ଏହି ପରୀକ୍ଷାରେ ତୁମର ସମୟ \n ନଷ୍ଟ କରିବାର ତିନି ବର୍ଷ ପରେ ତୁମେ ନିଶ୍ଚିତ ନୁହଁ କି?",
              "as": "আশা কৰোঁ বুলি তুমি কি বুজাইছা? এই পৰীক্ষাত তোমাৰ তিনি বছৰ সময় অপচয় কৰাৰ পিছত তুমি এতিয়াও নিশ্চিত নহয়?",
              "gu": "તું શું કહેવા માંગો છે, આશા છે? આ પરીક્ષામાં તારો સમય બગાડ્યાના ત્રણ વર્ષ પછી પણ તને ખાતરી નથી?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Madhav remains silent",
              "hi": "माधव चुप रहता  है",
              "ka": "ಮಾಧವ್ ಮೌನವಾಗಿದ್ದಾನೆ",
              "te": "మాధవ్ మౌనంగా ఉన్నాడు",
              "or": "ମାଧବ ଚୁପ୍ ରହିଲେ |",
              "as": "মাধৱ নীৰৱ হৈ থাকিল",
              "gu": "માધવ મૌન રહે છે"
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_5_4",
              "hi": "A5_5_4",
              "ka": "A5_5_4",
              "te": "A5_5_4",
              "or": "A5_5_4",
              "as": "A5_5_4",
              "gu": "A5_5_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is disturbed after the conversation with his father, but he tries to focus on his upcoming main exams.",
              "hi": "माधव अपने पिता के साथ बातचीत के बाद परेशान है, लेकिन वह अपनी आगामी मुख्य परीक्षाओं पर ध्यान केंद्रित करने का प्रयत्न करता है।",
              "ka": "ಮಾಧವ್ ತನ್ನ ತಂದೆಯೊಂದಿಗಿನ ಸಂಭಾಷಣೆಯ ನಂತರ ವಿಚಲಿತನಾಗುತ್ತಾನೆ, ಆದರೆ ಅವನು ಮುಂಬರುವ ಮುಖ್ಯ ಪರೀಕ್ಷೆಗಳ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಲು ಪ್ರಯತ್ನಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్ తన తండ్రితో సంభాషణ తర్వాత కలవరపడ్డాడు, కానీ అతను తన రాబోయే మెయిన్ పరీక్షలపై దృష్టి పెట్టడానికి ప్రయత్నిస్తాడు.",
              "or": "ପିତାଙ୍କ ସହ ବାର୍ତ୍ତାଳାପ ପରେ ମାଧବ ବିଚଳିତ, କିନ୍ତୁ ସେ ତାଙ୍କର ଆଗାମୀ\n  ମୁଖ୍ୟ ପରୀକ୍ଷା ଉପରେ ଧ୍ୟାନ ଦେବାକୁ ଚେଷ୍ଟା କରନ୍ତି |",
              "as": "দেউতাকৰ সৈতে হোৱা কথোপকথনৰ পিছত মাধৱ বিচলিত হয়, কিন্তু তেওঁ তেওঁৰ আহিবলগীয়া মুখ্য পৰীক্ষাৰ বাবে মনোনিবেশ কৰিবলৈ চেষ্টা কৰে।",
              "gu": "માધવ તેના પિતા સાથેની વાતચીત પછી પરેશાન છે, પરંતુ તે તેની આગામી મુખ્ય પરીક્ષાઓ પર ધ્યાન કેન્દ્રિત કરવાનો પ્રયાસ કરે છે."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "A5_6_1",
              "hi": "A5_6_1",
              "ka": "A5_6_1",
              "te": "A5_6_1",
              "or": "A5_6_1",
              "as": "A5_6_1",
              "gu": "A5_6_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He feels that the main exams will be harder than the prelims, as he must prepare for five papers. He works extremely hard and goes to write the exam.",
              "hi": "वह अनुभव करता है कि मुख्य परीक्षा प्रारंभिक परीक्षा की तुलना में कठिन होगी, क्योंकि उसे पांच पेपर के लिए तैयारी करनी होगी। वह कठिन परिश्रम करता है और परीक्षा देने जाता है।",
              "ka": "ಪರೀಕ್ಷೆಗಳು ಪ್ರಿಲಿಮ್ಸ್‌ಗಿಂತ ಕಠಿಣವಾಗಿರುತ್ತದೆ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ. ಅವನು ತುಂಬಾ ಕಷ್ಟಪಟ್ಟು ಓದಿದ ನಂತರ ಪರೀಕ್ಷೆ ಬರೆಯಲು ಹೋಗುತ್ತಾನೆ",
              "te": "ఐదు పేపర్లకు ప్రిపేర్ కావాల్సి ఉండడంతో ప్రిలిమ్స్ కంటే మెయిన్ పరీక్షలు కష్టమవుతాయని అతను భావిస్తున్నాడు. అతను చాలా కష్టపడి పరీక్ష రాయడానికి వెళ్తాడు.",
              "or": "ସେ ଅନୁଭବ କରନ୍ତି ଯେ ମୁଖ୍ୟ ପରୀକ୍ଷାଗୁଡ଼ିକ ପ୍ରିଲିମ୍ ଅପେକ୍ଷା କଠିନ\n  ହେବ, କାରଣ ସେ ପାଞ୍ଚଟି କାଗଜପତ୍ର ପାଇଁ ପ୍ରସ୍ତୁତ ହେବା ଆବଶ୍ୟକ | ସେ ଅତ୍ୟନ୍ତ କଠିନ ପରିଶ୍ରମ କରନ୍ତି ଏବଂ ପରୀକ୍ଷା ଲେଖିବାକୁ ଯାଆନ୍ତି |",
              "as": "সি অনুভৱ কৰে যে মুখ্য পৰীক্ষাবোৰ প্ৰাৰম্ভিক পৰীক্ষাতকৈ কঠিন হ'ব, কিয়নো সি পাঁচখন কাকতৰ বাবে প্ৰস্তুতি চলাব লাগিব। তেওঁ কঠোৰ পৰিশ্ৰম কৰে আৰু পৰীক্ষাত লিখিবলৈ যায়।",
              "gu": "તેને લાગે છે કે મુખ્ય પરીક્ષા પ્રિલિમ કરતાં અઘરી હશે, કારણ કે તેણે પાંચ પેપરની તૈયારી કરવી પડશે. તે ખૂબ જ મહેનત કરે છે અને પરીક્ષા લખવા જાય છે."
            }
          }
        ]
      },
      "scene15": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_1_1",
              "hi": "A6_1_1",
              "ka": "A6_1_1",
              "te": "A6_1_1",
              "or": "A6_1_1",
              "as": "A6_1_1",
              "gu": "A6_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav goes back to the centre to find out his rank on the result day.",
              "hi": "परिणाम  के दिन अपनी रैंक देखने के लिए माधव वापस केंद्र में जाता है।",
              "ka": "ಫಲಿತಾಂಶದ ದಿನದಂದು ಮಾಧವ್ ತನ್ನ ಶ್ರೇಣಿಯನ್ನು ತಿಳಿದುಕೊಳ್ಳಲು ಕೇಂದ್ರಕ್ಕೆ ಹಿಂತಿರುಗುತ್ತಾನೆ.",
              "te": "రిజల్ట్ రోజున తన ర్యాంక్ తెలుసుకోవడానికి మాధవ్ తిరిగి సెంటర్‌కి వెళ్తాడు.",
              "or": "ଫଳାଫଳ ଦିନ ତାଙ୍କ ପଦବୀ ଜାଣିବା ପାଇଁ ମାଧବ କେନ୍ଦ୍ରକୁ ଫେରିଥାନ୍ତି |",
              "as": "ফলাফলৰ দিনা মাধৱে নিজৰ ৰেংক জানিবলৈ কেন্দ্ৰলৈ যায়।",
              "gu": "માધવ પરિણામના દિવસે તેનો ક્રમ જાણવા સેંટરમાં પાછો જાય છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_1_2(reveal)",
              "hi": "A6_1_2 (प्रकट)",
              "ka": "A6_1_2(reveal)",
              "te": "A6_1_2(రివీల్)",
              "or": "A6_1_2 (ପ୍ରକାଶ)",
              "as": "A6_1_2(প্ৰকাশ)",
              "gu": "A6_1_2(જાહેર કરો)"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to see Madhav's rank and the cut off rank.",
              "hi": "माधव की रैंक और कट ऑफ रैंक देखने के लिए क्लिक करें।",
              "ka": "ಮಾಧವನ ಶ್ರೇಣಿ ಮತ್ತು ಕಟ್ ಆಫ್ ಶ್ರೇಣಿಯನ್ನು ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ",
              "te": "మాధవ్ ర్యాంక్ మరియు కట్ ఆఫ్ ర్యాంక్ చూడటానికి క్లిక్ చేయండి.",
              "or": "ମାଧବଙ୍କ ର୍ୟାଙ୍କ ଏବଂ କଟ୍ ଅଫ୍ ର୍ୟାଙ୍କ ଦେଖିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "মাধৱৰ ৰেংক আৰু কাট অফ ৰেংক চাবলৈ ক্লিক কৰা।",
              "gu": "માધવનો ક્રમ અને કપાત ગુણ જોવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Madhav's rank",
              "hi": "माधव की रैंक",
              "ka": "ಮಾಧವಣ ಶ್ರೇಣಿ",
              "te": "మాధవ్ ర్యాంక్",
              "or": "ମାଧବଙ୍କ ପଦବୀ |",
              "as": "মাধৱৰ ৰেংক",
              "gu": "માધવનો ક્રમ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Cut off rank",
              "hi": "कट ऑफ रैंक",
              "ka": "ಕಟ್ ಆಫ್ ಶ್ರೇಣಿ",
              "te": "కట్ ఆఫ్ ర్యాంక్",
              "or": "ର୍ୟାଙ୍କ୍ କାଟ |",
              "as": "কাটঅফ ৰেংক",
              "gu": "કપાત ગુણ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "5,807",
              "hi": "5,807",
              "ka": "5,807",
              "te": "5,807",
              "or": "୫,୮୦୭",
              "as": "5,807",
              "gu": "5,807"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "2,560",
              "hi": "2,560",
              "ka": "2,560",
              "te": "2,560",
              "or": "୨,୫୬୦",
              "as": "2,560",
              "gu": "2,560"
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_1_3",
              "hi": "A6_1_3",
              "ka": "A6_1_3",
              "te": "A6_1_3",
              "or": "A6_1_3",
              "as": "A6_1_3",
              "gu": "A6_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is heartbroken. He has not cleared the main exam.",
              "hi": "माधव का दिल टूट गया है। वह मुख्य परीक्षा उत्तीर्ण नहीं कर पाया है।",
              "ka": "ಮಾಧವ್ ಬಹಳ ಎದೆಗುಂದುತ್ತಾನೆ. ಅವನು ಮುಖ್ಯ ಪರೀಕ್ಷೆಯಲ್ಲಿ ತೇರ್ಗಡೆಯಾಗಿಲ್ಲ.",
              "te": "మాధవ్ గుండె పగిలింది. అతను మెయిన్ పరీక్షలో ఉత్తీర్ణత సాధించలేదు.",
              "or": "ମାଧବ ଦୁଃଖୀ | ସେ ମୁଖ୍ୟ ପରୀକ୍ଷାରେ ପାସ କରିନାହାଁନ୍ତି।",
              "as": "মাধৱৰ হৃদয় ভাঙি যায়। তেওঁ মূল পৰীক্ষাত উত্তীৰ্ণ নহ’ল।",
              "gu": "માધવનું દિલ તૂટી ગયું. તેણે મુખ્ય પરીક્ષા પાસ કરી નથી."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_2_1",
              "hi": "A6_2_1",
              "ka": "A6_2_1",
              "te": "A6_2_1",
              "or": "A6_2_1",
              "as": "A6_2_1",
              "gu": "A6_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He is lost and confused. He has spent the last three years preparing for his exams, and he only has two more attempts left.",
              "hi": "वह निराशा में डूब गया है और उलझन में है। उसने पिछले तीन वर्षों को अपनी परीक्षाओं की तैयारी में बिता दिया है, और अब उसके पास केवल दो और प्रयास शेष हैं।",
              "ka": "ಅವನು ತಾನು ಕಳೆದುಹೋಗಿದ್ದೇನೆ ಅನ್ನುವ ಭಾವನೆಗೆ ಒಳಗಾಗುತ್ತಾನೆ  ಮತ್ತು ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದಾನೆ. ಅವನು ಕಳೆದ ಮೂರು ವರ್ಷಗಳಿಂದ ತನ್ನ ಪರೀಕ್ಷೆಗಳಿಗೆ ತಯಾರಿ ನಡೆಸುತ್ತಿದ್ದಾನೆ ಮತ್ತು ಅವನಿಗೆ ಇನ್ನು ಎರಡು ಪ್ರಯತ್ನಗಳು ಮಾತ್ರ ಉಳಿದಿವೆ.",
              "te": "అతను అయోమయంలో పడ్డాడు. అతను గత మూడు సంవత్సరాలుగా తన పరీక్షలకు ప్రిపేర్ అవుతున్నాడు మరియు అతనికి మరో రెండు ప్రయత్నాలు మాత్రమే మిగిలి ఉన్నాయి.",
              "or": "ସେ ହଜିଯାଇଛନ୍ତି ଏବଂ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛନ୍ତି | ସେ ତାଙ୍କର ପରୀକ୍ଷା ପାଇଁ \n ପ୍ରସ୍ତୁତି ପାଇଁ ଗତ ତିନିବର୍ଷ ଅତିବାହିତ କରିଛନ୍ତି, ଏବଂ ତାଙ୍କର ଆଉ ଦୁଇଟି ଚେଷ୍ଟା ବାକି ଅଛି |",
              "as": "তেওঁ দিকবিদিক হেৰুৱাইছে আৰু বিভ্ৰান্ত হৈছে। তেওঁ যোৱা তিনি বছৰ পৰীক্ষাৰ বাবে প্ৰস্তুতি চলাইছে, আৰু তেওঁৰ মাত্ৰ দুটা প্ৰচেষ্টা বাকী আছে।",
              "gu": "તે હારી ગયો છે અને મૂંઝવણમાં છે. તેણે છેલ્લાં ત્રણ વર્ષથી તેની પરીક્ષાઓની તૈયારીમાં વિતાવ્યા છે, અને તેની પાસે માત્ર બે વધુ પ્રયાસો બાકી છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_2_2",
              "hi": "A6_2_2",
              "ka": "A6_2_2",
              "te": "A6_2_2",
              "or": "A6_2_2",
              "as": "A6_2_2",
              "gu": "A6_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He is not able to talk to anyone. He spends the next few days in his room by himself. Badri and Kuldeep try cheering him up, but he barely speaks to them.",
              "hi": "वह किसी से बात नहीं कर पा रहा है। वह अगले कुछ दिन अपने कमरे में अकेले ही रहता है। बद्री और कुलदीप ने उसे खुश करने की कोशिश की, लेकिन वह मुश्किल से उनसे बात करता है।",
              "ka": "ಯಾರೊಂದಿಗೂ ಮಾತನಾಡಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ಮುಂದಿನ ದಿನಗಳನ್ನು ಅವನು ತನ್ನ ಕೋಣೆಯಲ್ಲಿ ಒಬ್ಬನೇ ಕಳೆಯುತ್ತಾನೆ. ಬದ್ರಿ ಮತ್ತು ಕುಲದೀಪ್ ಅವನನ್ನು ಹುರಿದುಂಬಿಸಲು ಪ್ರಯತ್ನಿಸುತ್ತಾರೆ, ಆದರೆ ಅವನು ಅವರೊಂದಿಗೆ ಮಾತನಾಡುವುದಿಲ್ಲ.",
              "te": "అతను ఎవరితోనూ మాట్లాడలేకపోతున్నాడు. తర్వాత కొన్ని రోజులు తన గదిలో ఒక్కడే గడుపుతాడు. బద్రీ మరియు కుల్దీప్ అతనిని ఉత్సాహపరిచేందుకు ప్రయత్నించారు, కానీ అతను వారితో మాట్లాడలేదు.",
              "or": "ସେ କାହା ସହିତ କଥାବାର୍ତ୍ତା କରିବାକୁ ସକ୍ଷମ ନୁହଁନ୍ତି | ସେ ପରବର୍ତ୍ତୀ \n କିଛି ଦିନ ନିଜ କୋଠରୀରେ ନିଜେ ବିତାଇଲେ | ବଦ୍ରି ଏବଂ କୁଲଦୀପ ତାଙ୍କୁ ଉତ୍ସାହିତ କରିବାକୁ ଚେଷ୍ଟା କରନ୍ତି, କିନ୍ତୁ ସେ ସେମାନଙ୍କ ସହ କମ କଥାବାର୍ତ୍ତା କରନ୍ତି |",
              "as": "তেওঁ কাৰো সৈতে কথা পাতিব খোজা নাই। তেওঁ পৰৱৰ্তী কেইটামান দিন নিজৰ কোঠাত অকলশৰে কটায়। বদ্ৰী আৰু কুলদীপে তাক উৎসাহিত কৰিবলৈ চেষ্টা কৰে, কিন্তু সি তেওঁলোকৰ সৈতে কোনোমতেহে কথা পাতে।",
              "gu": "તે કોઈની સાથે વાત કરવા સક્ષમ નથી. આગામી થોડા દિવસો તે પોતાના રૂમમાં એકલા વિતાવે છે. બદ્રી અને કુલદીપ તેને ઉત્સાહિત કરવાનો પ્રયાસ કરે છે, પરંતુ તે ભાગ્યે જ તેમની સાથે બોલે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_2_3",
              "hi": "A6_2_3",
              "ka": "A6_2_3",
              "te": "A6_2_3",
              "or": "A6_2_3",
              "as": "A6_2_3",
              "gu": "A6_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is unable to deal with this failure. He blames himself for being less capable than the others.",
              "hi": "माधव इस विफलता से निपटने में असमर्थ है। वह दूसरों की तुलना में कम सक्षम होने के लिए खुद को दोषी ठहराता है।",
              "ka": "ಈ ವೈಫಲ್ಯವನ್ನು ಎದುರಿಸಲು ಮಾಧವ್‌ಗೆ ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ಅವನು ಇತರರಿಗಿಂತ ಕಡಿಮೆ ಸಾಮರ್ಥ್ಯವನ್ನು ಹೊಂದಿದ್ದಕ್ಕಾಗಿ ತನ್ನನ್ನು ತಾನೇ ದೂಷಿಸಿಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "మాధవ్ ఈ వైఫల్యాన్ని తట్టుకోలేకపోతున్నాడు. అతను ఇతరుల కంటే తక్కువ సామర్థ్యం కలిగి ఉన్నాడని తనను తాను నిందించుకుంటాడు.",
              "or": "ଏହି ବିଫଳତାକୁ ସାମ୍ନା କରିବାକୁ ମାଧବ ଅସମର୍ଥ | ସେ ଅନ୍ୟମାନଙ୍କ \n ତୁଳନାରେ କମ୍ ସାମର୍ଥ୍ୟ ପାଇଁ ନିଜକୁ ଦାୟୀ କରନ୍ତି |",
              "as": "মাধৱে এই বিফলতাৰ সৈতে মোকাবিলা কৰিবলৈ অক্ষম। তেওঁ আনতকৈ কম সক্ষম হোৱাৰ বাবে নিজকে দোষাৰোপ কৰে।",
              "gu": "માધવ આ નિષ્ફળતાનો સામનો કરવામાં અસમર્થ છે. તે અન્ય કરતા ઓછા સક્ષમ હોવા માટે પોતાને દોષ આપે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_2_4",
              "hi": "A6_2_4",
              "ka": "A6_2_4",
              "te": "A6_2_4",
              "or": "A6_2_4",
              "as": "A6_2_4",
              "gu": "A6_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He feels bad that he doesn't have a family to rely on and blames himself for wasting his friends' time and money.",
              "hi": "उसे दुःख होता है कि उसके पास निर्भर होने के लिए परिवार नहीं है और अपने मित्रों के समय और धन को बर्बाद करने के लिए खुद को दोषी ठहराता है।",
              "ka": "ತನಗೆ ಅವಲಂಬಿಸಲು ಕುಟುಂಬವಿಲ್ಲ ಎಂದು ಅವನು ದುಃಖಿತನಾಗಿರುತ್ತಾನೆ ಮತ್ತು ತನ್ನ ಸ್ನೇಹಿತರ ಸಮಯ ಮತ್ತು ಹಣವನ್ನು ವ್ಯರ್ಥ ಮಾಡಿದಕ್ಕಾಗಿ ತನ್ನನ್ನು ದೂಷಿಸಿಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "తనకు కుటుంబం లేదని బాధపడుతూ తన స్నేహితుల సమయాన్ని, డబ్బును వృధా చేస్తున్నందుకు తనను తాను నిందించుకుంటాడు.",
              "or": "ସେ ଖରାପ ଅନୁଭବ କରନ୍ତି ଯେ ତାଙ୍କ ଉପରେ ନିର୍ଭର କରିବାକୁ ପରିବାର\n  ନାହିଁ ଏବଂ ନିଜ ସାଙ୍ଗମାନଙ୍କ ସମୟ ଏବଂ ଅର୍ଥ ନଷ୍ଟ କରିବାରେ ନିଜକୁ ଦାୟୀ କରନ୍ତି |",
              "as": "তেওঁ দুখ অনুভৱ কৰে যে তাৰ নিৰ্ভৰ কৰিবলৈ কোনো পৰিয়াল নাই আৰু তাৰ বন্ধুবৰ্গৰ সময় আৰু ধন অপচয় কৰাৰ বাবে নিজকে দোষাৰোপ কৰে।",
              "gu": "તેને ખરાબ લાગે છે કે તેની પાસે વિશ્વાસ કરવા માટે કોઈ કુટુંબ નથી અને તે તેના મિત્રોનો સમય અને પૈસા બગાડવા માટે પોતાને દોષી ઠેરવે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_3_1",
              "hi": "A6_3_1",
              "ka": "A6_3_1",
              "te": "A6_3_1",
              "or": "A6_3_1",
              "as": "A6_3_1",
              "gu": "A6_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Badri and Kuldeep feel helpless. They see Madhav sinking deeper into his misery and are unable to do anything other than spend time with him through the day.",
              "hi": "बद्री और कुलदीप असहाय अनुभव करते हैं। वे माधव को दुख की गहराई में डूबते हुए देखते हैं और दिन में उसके साथ समय बिताने के अलावा कुछ भी करने में असमर्थ हैं।",
              "ka": "ಬದ್ರಿ ಮತ್ತು ಕುಲದೀಪ್ ಅಸಹಾಯಕರಾಗಿದ್ದಾರೆ. ಮಾಧವ್ ತನ್ನ ದುಃಖದಲ್ಲಿ ಆಳವಾಗಿ ಮುಳುಗುತ್ತಿರುವುದನ್ನು ಅವರು ನೋಡುತ್ತಾರೆ ಮತ್ತು ದಿನವಿಡೀ ಅವನೊಂದಿಗೆ ಸಮಯ ಕಳೆಯುವುದನ್ನು ಬಿಟ್ಟು ಬೇರೆ ಏನನ್ನೂ ಮಾಡಲು ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ",
              "te": "బద్రి మరియు కుల్దీప్ నిస్సహాయంగా ఉన్నారు. వారు మాధవ్ బాధలో లోతుగా మునిగిపోవడాన్ని చూస్తారు మరియు రోజంతా అతనితో సమయం గడపడం తప్ప వేరే ఏమీ చేయలేకపోతున్నారు.",
              "or": "ବଦ୍ରି ଏବଂ କୁଲଦୀପ ନିଜକୁ ଅସହାୟ ମନେ କରନ୍ତି | ସେମାନେ ଦେଖନ୍ତି\n  ମାଧବ ତାଙ୍କ ଦୁଃଖରେ ଗଭୀର ଭାବରେ ବୁଡ଼ି ଯାଉଛନ୍ତି ଏବଂ ଦିନସାରା ତାଙ୍କ ସହିତ ସମୟ ବିତାଇବା ବ୍ୟତୀତ ଅନ୍ୟ କିଛି କରିବାକୁ ଅସମର୍ଥ |",
              "as": "বদ্ৰী আৰু কুলদীপে অসহায় অনুভৱ কৰে। তেওঁলোকে মাধৱক তেওঁৰ দুখৰ গভীৰতালৈ ডুব যোৱা দেখিছে আৰু দিনটো তেওঁৰ সৈতে সময় কটোৱাৰ বাহিৰে আন একো কৰিব নোৱাৰে।",
              "gu": "બદ્રી અને કુલદીપ લાચારી અનુભવે છે. તેઓ માધવને તેના દુઃખમાં ઊંડે સુધી ડૂબતા જુએ છે અને દિવસભર તેની સાથે સમય પસાર કરવા સિવાય બીજું કંઈ કરી શકતા નથી."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_3_2////",
              "hi": "A6_3_2 ////////////",
              "ka": "A6_3_2////",
              "te": "A6_3_2////",
              "or": "A6_3_2////",
              "as": "A6_3_2////",
              "gu": "A6_3_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Madhav, it has been more than a \nmonth since the results, and you have barely come outside or even talked to us.",
              "hi": "माधव, एक से अधिक महीना हो गया है परिणाम के बाद से, और तुम मुश्किल से बाहर आते हो या यहां तक ​​कि हमसे बात करते हो।",
              "ka": "ಮಾಧವ್, ಈ ಫಲಿತಾಂಶದ ನಂತರ ಒಂದು ತಿಂಗಳು ಕಳೆದಿದೆ, ಮತ್ತು ನೀನು ಹೊರಗೆ ಬಂದಿಲ್ಲ ಅಥವಾ ನಮ್ಮೊಂದಿಗೆ ಮಾತನಾಡಿಲ್ಲ.",
              "te": "మాధవ్, ఫలితాలు వచ్చి నెల దాటింది, నువ్వు బయటికి రావట్లేదు మరియు మాతో సరిగ్గా మాట్లాడట్లేదు.",
              "or": "ମାଧବ, ଫଳାଫଳ ବାହାରିବାର ଏକ ମାସରୁ ଅଧିକ ହେଲାଣି , ଏବଂ ତୁମେ ବହୁତ କମ ବାହାରକୁ ଆସିଛ କିମ୍ବା ଆମ ସହିତ କଥା ହୋଇଛ |",
              "as": "মাধৱ, ফলাফল ওলোৱাৰ এমাহতকৈ বেছি হৈছে, আৰু তুমি কোনোমতে বাহিৰলৈ আহিছা বা আমাৰ সৈতে কথা পাতিছা।",
              "gu": "માધવ, પરિણામ આવ્યાને એક મહિના કરતાં વધુ સમય થઈ ગયો છે, અને તમે માંડ માંડ બહાર આવ્યા છો કે અમારી સાથે વાત પણ કરી નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Come on, Madhav, you have to talk to us. Please tell us how are you feeling?",
              "hi": "आओ, माधव, तुमको हमसे बात करनी होगी। कृपया हमें बताओ कि तुम  कैसा अनुभव कर रहे हो?",
              "ka": "ಬಾ ಮಾಧವ್ ನೀನು ನಮ್ಮ ಜೊತೆ ಮಾತಾಡಬೇಕು. ನಿನಗೆ ಹೇಗನಿಸುತ್ತಿದೆ ಎಂದು ದಯವಿಟ್ಟು ನಮಗೆ ತಿಳಿಸು?",
              "te": "రా మాధవ్, నువ్వు మాతో మాట్లాడాలి. దయచేసి నువ్వు ఎలా ఫీల్ అవుతున్నావో మాకు చెప్పు?",
              "or": "ଆସ, ମାଧବ, ତୁମେ ଆମ ସହିତ କଥା ହେବାକୁ ପଡିବ | ଦୟାକରି ଆମକୁ\n  କୁହ ତୁମେ କିପରି ଅନୁଭବ କରୁଛ?",
              "as": "আহা, মাধৱ, তুমি আমাৰ সৈতে কথা পাতিব লাগিব। আমাক কোৱা তুমি কেনে অনুভৱ কৰিছা?",
              "gu": "ચાલ, માધવ, તારે અમારી સાથે વાત કરવી પડશે. કૃપા કરીને અમને બતાવ કે તું કેવું અનુભવી રહ્યો છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Leave me alone, please.",
              "hi": "कृपया मुझे अकेला छोड़ दो।",
              "ka": "ದಯವಿಟ್ಟು ನನ್ನನ್ನು ಒಬ್ಬನೇ ಇರಲು ಬಿಟ್ಟುಬಿಡಿ",
              "te": "దయచేసి నన్ను ఒంటరిగా వదిలెయ్యండి.",
              "or": "ଦୟାକରି ମୋତେ ଏକୁଟିଆ ଛାଡିଦିଅ |",
              "as": "অনুগ্ৰহ কৰি মোক অকলে এৰি দিয়া।",
              "gu": "કૃપા કરીને મને એકલો છોડી દે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_4_1",
              "hi": "A6_4_1",
              "ka": "A6_4_1",
              "te": "A6_4_1",
              "or": "A6_4_1",
              "as": "A6_4_1",
              "gu": "A6_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Kuldeep and Badri try many things to help Madhav.",
              "hi": "कुलदीप और बद्री माधव की सहायता करने के लिए अनेक प्रयास करते हैं।",
              "ka": "ಕುಲದೀಪ್ ಮತ್ತು ಬದ್ರಿ ಮಾಧವ್‌ಗೆ ಸಹಾಯ ಮಾಡಲು ಹಲವು ಪ್ರಯತ್ನಗಳನ್ನು ಮಾಡುತ್ತಾರೆ.",
              "te": "కుల్దీప్ మరియు బద్రి మాధవ్‌కి సహాయం చేయడానికి చాలా ప్రయత్నాలు చేస్తారు.",
              "or": "କୁଲଦୀପ ଏବଂ ବଦ୍ରି ମାଧବଙ୍କୁ ସାହାଯ୍ୟ କରିବା ପାଇଁ ଅନେକ ଜିନିଷ\n  ଚେଷ୍ଟା କରନ୍ତି |",
              "as": "কুলদীপ আৰু বদ্ৰীয়ে মাধৱক সহায় কৰিবলৈ বহুতো চেষ্টা কৰে।",
              "gu": "કુલદીપ અને બદ્રી માધવને મદદ કરવા માટે ઘણી કોશિશ કરે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_4_2",
              "hi": "A6_4_2",
              "ka": "A6_4_2",
              "te": "A6_4_2",
              "or": "A6_4_2",
              "as": "A6_4_2",
              "gu": "A6_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They forcibly take him out for a movie. While watching the movie, Madhav breaks down and starts crying.",
              "hi": "वे जबरन उसे एक फिल्म  दिखाने के लिए बाहर ले जाते हैं। फिल्म देखते समय, माधव टूट जाता है और रोना शुरू कर देता है।",
              "ka": "ಬಲವಂತವಾಗಿ ಸಿನಿಮಾಕ್ಕೆ ಕರೆದುಕೊಂಡು ಹೋಗುತ್ತಾರೆ. ಸಿನಿಮಾ ನೋಡುವಾಗ ಮಾಧವ್ ತಡೆಯಲಾರದೇ ಅಳುತ್ತಾನೆ.",
              "te": "బలవంతంగా అతన్ని సినిమాకు తీసుకెళ్తారు. సినిమా చూస్తుండగా మాధవ్ ఏడవడం మొదలుపెట్టాడు.",
              "or": "ସେମାନେ ତାଙ୍କୁ ଏକ ଚଳଚ୍ଚିତ୍ର ପାଇଁ ବାଧ୍ୟ କରନ୍ତି | ଚଳଚ୍ଚିତ୍ର ଦେଖିବାବେଳେ\n  ମାଧବ ଭାଙ୍ଗି କାନ୍ଦିବାକୁ ଲାଗିଲେ |",
              "as": "তেওঁলোকে তাক জোৰকৈ চিনেমা এখন দেখুৱাবলৈ লৈ যায়। চিনেমাখন চাই থাকোতে, মাধৱ ভাগি পৰে আৰু কান্দিবলৈ আৰম্ভ কৰে।",
              "gu": "તેઓ તેને બળજબરીથી ફિલ્મ જોવા માટે બહાર લઈ જાય છે. ફિલ્મ જોતી વખતે માધવ ભાંગી પડે છે અને રડવા લાગે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_4_3",
              "hi": "A6_4_3",
              "ka": "A6_4_3",
              "te": "A6_4_3",
              "or": "A6_4_3",
              "as": "A6_4_3",
              "gu": "A6_4_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Not knowing how to help him, Badri suggests that Madhav talk to his colleague Priya Ma'am, who is a counsellor. Madhav agrees to speak to her.",
              "hi": "वे समझ नहीं पाते कि उसकी मदद कैसे करें, बद्री सुझाव देता है कि माधव की सहयोगी प्रिया मैम से बात करते हैं, जो सलाहकार है। माधव उनसे बात करने के लिए सहमत है। ",
              "ka": "ಅವನಿಗೆ ಹೇಗೆ ಸಹಾಯ ಮಾಡಬೇಕೆಂದು ತಿಳಿಯದೆ, ಮಾಧವ್ ಸಲಹೆಗಾರರಾಗಿರುವ ತನ್ನ ಸಹೋದ್ಯೋಗಿ ಪ್ರಿಯಾ ಮೇಮ್ ಅವರೊಂದಿಗೆ ಮಾತನಾಡಲು ಬದ್ರಿ ಸೂಚಿಸುತ್ತಾನೆ. ಮಾಧವ್ ಅವಳೊಂದಿಗೆ ಮಾತನಾಡಲು ಒಪ್ಪುತ್ತಾನೆ.",
              "te": "అతనికి ఎలా సహాయం చేయాలో తెలియక, బద్రి తన సహోద్యోగి మరియు కౌన్సిలర్ అయిన ప్రియా మామ్‌తో మాట్లాడమని మాధవ్ కి సూచించాడు. మాధవ్ ఆమెతో మాట్లాడటానికి అంగీకరించాడు.",
              "or": "ତାଙ୍କୁ କିପରି ସାହାଯ୍ୟ କରାଯିବ ତାହା ଜାଣି ନ ଥିବାରୁ ବଦ୍ରି ପରାମର୍ଶ \n ଦେଇଛନ୍ତି ଯେ ମାଧବ ତାଙ୍କ ସହକର୍ମୀ ପ୍ରିୟା ମା’ଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରନ୍ତି। ମାଧବ ତାଙ୍କ ସହ କଥା ହେବାକୁ ରାଜି ହୋଇଥିଲେ।",
              "as": "তাক কেনেদৰে সহায় কৰিব লাগে নাজানি বদ্ৰীয়ে মাধৱক পৰামৰ্শ দিয়ে যে সি তেওঁৰ সহকৰ্মী প্ৰিয়া মেমৰ সৈতে কথা পাতক, যি এগৰাকী পৰামৰ্শদাতা। মাধৱে তেখেতৰ সৈতে কথা পাতিবলৈ সন্মত হয়।",
              "gu": "તેને કેવી રીતે મદદ કરવી તે જાણતા ન હોવાથી, બદ્રી માધવને તેની સાથીદાર પ્રિયા મેડમ સાથે વાત કરવાનું સૂચન કરે છે, જે કાઉન્સેલર છે. માધવ તેની સાથે વાત કરવા સંમત થાય છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_5_1",
              "hi": "A6_5_1",
              "ka": "A6_5_1",
              "te": "A6_5_1",
              "or": "A6_5_1",
              "as": "A6_5_1",
              "gu": "A6_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "When Madhav meets her, Priya Ma'am asks him some questions he finds hard to answer.",
              "hi": "जब माधव उनसे मिलता है, तो प्रिया मैम उससे कुछ सवाल पूछती हैं जिनके जवाब देना उसे मुश्किल लगता है।",
              "ka": "ಮಾಧವ್ ಅವರನ್ನು ಭೇಟಿಯಾದಾಗ, ಪ್ರಿಯಾ ಮೇಡಮ್ ಅವನಿಗೆ ಉತ್ತರಿಸಲು ಕಷ್ಟವಾದ ಕೆಲವು ಪ್ರಶ್ನೆಗಳನ್ನು ಕೇಳುತ್ತಾರೆ.",
              "te": "మాధవ్ ఆమెను కలిసినప్పుడు, ప్రియా మేడమ్ అతనిని కొన్ని ప్రశ్నలు అడిగింది, అతనికి సమాధానం చెప్పడం కష్టం అయింది.",
              "or": "ଯେତେବେଳେ ମାଧବ ତାଙ୍କୁ ଭେଟିଲେ, ପ୍ରିୟା ମା ତାଙ୍କୁ କିଛି ପ୍ରଶ୍ନ ପଚାରନ୍ତି\n  ଯାହା ତାଙ୍କୁ ଉତ୍ତର ଦେବା ପାଇଁ କଷ୍ଟକର ଲାଗେ |",
              "as": "যেতিয়া মাধৱে তেখেতক লগ পায়, প্ৰিয়া মেমে তাক কিছুমান প্ৰশ্ন সোধে যাৰ উত্তৰ দিবলৈ সি কঠিন অনুভৱ কৰে।",
              "gu": "જ્યારે માધવ તેને મળે છે, ત્યારે પ્રિયા મેડમ તેને કેટલાક પ્રશ્નો પૂછે છે જેનો જવાબ આપવા માટે તેને મુશ્કેલ લાગે છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_5_2",
              "hi": "A6_5_2",
              "ka": "A6_5_2",
              "te": "A6_5_2",
              "or": "A6_5_2",
              "as": "A6_5_2",
              "gu": "A6_5_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She listens to everything Madhav has to say and, after understanding what he is going through, suggests that he take small steps like writing his feelings in a journal regularly.",
              "hi": "वह सब कुछ सुनती है जो भी माधव कहना चाहता हैऔर, यह समझने के बाद कि वह किस स्थिति से गुज़र रहा है, वह सुझाव देती हैं कि वह नियमित रूप से एक पत्रिका में अपनी भावनाओं को लिखने जैसे छोटे कदम उठाए।",
              "ka": "ಮಾಧವ್ ಹೇಳುವ ಎಲ್ಲವನ್ನೂ ಅವರು ಕೇಳುತ್ತಾರೆ ಮತ್ತು ಅವನು ಏನು ಅನುಭವಿಸುತ್ತಿದ್ದಾನೆ ಎನ್ನುವುದನ್ನು ಅರ್ಥಮಾಡಿಕೊಂಡ ನಂತರ, ನಿಯಮಿತವಾಗಿ ತನ್ನ ಭಾವನೆಗಳನ್ನು ಜರ್ನಲ್‌ನಲ್ಲಿ ಬರೆಯುವಂತಹ ಸಣ್ಣ ಹೆಜ್ಜೆಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುವಂತೆ ಸೂಚಿಸುತ್ತಾರೆ.",
              "te": "ఆమె మాధవ్ చెప్పేదంతా వింటుంది మరియు అతను ఏమి చేస్తున్నాడో అర్థం చేసుకున్న తర్వాత, క్రమం తప్పకుండా తన భావాలను పత్రికలో వ్రాయడం వంటి చిన్న చిన్న పనులు చేయమని సూచించింది.",
              "or": "ସେ ମାଧବଙ୍କ ସମସ୍ତ କଥା ଶୁଣନ୍ତି ଏବଂ ସେ ଯାହା କରୁଛନ୍ତି ତାହା ବୁଝିବା ପରେ \n ସେ ପରାମର୍ଶ ଦିଅନ୍ତି ଯେ ସେ ନିଜ ଭାବନାକୁ ନିୟମିତ ପତ୍ରିକାରେ ଲେଖିବା ପରି ଛୋଟ ପଦକ୍ଷେପ ନିଅନ୍ତି |",
              "as": "তেখেতে মাধৱে কোৱা সকলোবোৰ শুনি থাকে আৰু সি কি পৰিস্থিতিৰ মাজেৰে পাৰ হৈ আছে বুজি পোৱাৰ পিছত, পৰামৰ্শ দিয়ে যে সি নিয়মীয়াকৈ এখন জাৰ্ণেলত তাৰ অনুভূতিবোৰ লিখাৰ দৰে সৰু পদক্ষেপ লওক।",
              "gu": "માધવ જે કહે છે તે બધું તે સાંભળે છે અને, તે શું પસાર કરી રહ્યો છે તે સમજ્યા પછી, તે નિયમિતપણે જર્નલમાં તેની લાગણીઓ લખવા જેવા નાના પગલાં લેવાનું સૂચન કરે છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_6_1",
              "hi": "A6_6_1",
              "ka": "A6_6_1",
              "te": "A6_6_1",
              "or": "A6_6_1",
              "as": "A6_6_1",
              "gu": "A6_6_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav goes back to his room that day, feeling unsure how counselling would help him, but he decides to try writing his thoughts anyway.",
              "hi": "माधव अपने कमरे में वापस चला जाता है, वह समझ नहीं पा रहा है कि परामर्श कितनी मदद करेगा, लेकिन वह वैसे भी अपने विचार लिखने का प्रयास करने का फैसला करता है।",
              "ka": "ಆ ದಿನ ಮಾಧವ್ ತನ್ನ ಕೋಣೆಗೆ ಹಿಂತಿರುಗುತ್ತಾನೆ, ಸಮಾಲೋಚನೆಯು ತನಗೆ ಹೇಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ ಎನ್ನುವುದು ಅವನಿಗೆ ಖಚಿತವಾಗಿಲ್ಲ, ಆದರೆ ಹೇಗಾದರೂ ಆಗಲಿ ತನ್ನ ಆಲೋಚನೆಗಳನ್ನು ಬರೆಯಲು ಪ್ರಯತ್ನಿಸಲು ಅವನು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్ ఆ రోజు తన గదికి తిరిగి వెళతాడు, కౌన్సెలింగ్ తనకు ఎలా సహాయపడుతుందో తెలియక, ఎలాగైనా తన ఆలోచనలను వ్రాయాలని నిర్ణయించుకున్నాడు.",
              "or": "କାଉନସେଲିଂ ତାଙ୍କୁ କିପରି ସାହାଯ୍ୟ କରିବ ସେ ବିଷୟରେ ଅନିଶ୍ଚିତତା\n  ଅନୁଭବ କରି ମାଧବ ସେଦିନ ନିଜ କୋଠରୀକୁ ଫେରିଗଲେ, କିନ୍ତୁ ସେ ଯେକୌଣସି ପ୍ରକାରେ ତାଙ୍କ ଚିନ୍ତାଧାରା ଲେଖିବାକୁ ଚେଷ୍ଟା କରିବାକୁ ସ୍ଥିର କଲେ |",
              "as": "মাধৱ সেইদিনা তাৰ কোঠালৈ ঘূৰি যায়, পৰামৰ্শবোৰে তাক কেনেদৰে সহায় কৰিব সেয়া নিশ্চিত নহয়, কিন্তু সি যিকোনো ধৰণে তাৰ চিন্তাবোৰ লিখিবলৈ চেষ্টা কৰাৰ সিদ্ধান্ত লয়।",
              "gu": "માધવ તે દિવસે તેના રૂમમાં પાછો જાય છે, કાઉન્સેલિંગ તેને કેવી રીતે મદદ કરશે તે અંગે અચોક્કસ લાગણી અનુભવે છે, પરંતુ તેણે કોઈપણ રીતે તેના વિચારો લખવાનો પ્રયાસ કરવાનું નક્કી કર્યું."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_6_2_reveal",
              "hi": "A6_6_2_रिवील ",
              "ka": "A6_6_2_reveal",
              "te": "A6_6_2_రివీల్",
              "or": "A6_6_2_ ପ୍ରକାଶ |",
              "as": "A6_6_2_প্ৰকাশ",
              "gu": "A6_6_2_બતાવો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click here to see the thought's Madhav has written in the journal.",
              "hi": "माधव ने पत्रिका में  जो विचार  लिखा है  उसे देखने के लिए यहां क्लिक करें।",
              "ka": "ಮಾಧವ್ ತನ್ನ ಚಿಂತನೆಯನ್ನು ಪತ್ರಿಕೆಯಲ್ಲಿ ಬರೆದಿರುವುದನ್ನು ನೋಡಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "మాధవ్ పత్రికలో వ్రాసిన ఆలోచనలను చూడటానికి ఇక్కడ క్లిక్ చేయండి.",
              "or": "ମାଧବ ପତ୍ରିକାରେ ଲେଖିଥିବା ନିଜ ର ଭାବନା କୁ ଦେଖିବାକୁ ଏଠାରେ \n କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "জাৰ্ণেলখনত মাধৱে লিখা চিন্তাবোৰ চাবলৈ ইয়াত ক্লিক কৰা।",
              "gu": "માધવે જર્નલમાં લખેલા વિચારો જોવા માટે અહીં ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Thought 1",
              "hi": "विचार 1",
              "ka": "ಯೋಚನೆ 1",
              "te": "ఆలోచన 1",
              "or": "ଭାବନା ୧",
              "as": "চিন্তা 1",
              "gu": "વિચાર 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Thought 2",
              "hi": "विचार 2 ",
              "ka": "ಯೋಚನೆ 2",
              "te": "ఆలోచన 2",
              "or": "ଭାବନା ୨",
              "as": "চিন্তা 2",
              "gu": "વિચાર 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Thought 3",
              "hi": "विचार 3",
              "ka": "ಯೋಚನೆ 3",
              "te": "ఆలోచన 3",
              "or": "ଭାବନା ୩",
              "as": "চিন্তা 3",
              "gu": "વિચાર 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "I feel like I am a failure. I have shamelessly lived off my friends for the last two years and still haven't achieved what I have been working towards.",
              "hi": "मुझे लगता है कि मैं सदैव  विफल होता हूँ। मैं पिछले दो सालों से अपने मित्रों के साथ बेशर्मी से रहता हूं और अभी भी वो लक्ष्य हासिल नहीं किया है जिसके लिए मैं काम कर रहा हूं।",
              "ka": "ನಾನೊಬ್ಬ ಸೋಲಿಗಾಗಿಯೇ ಹುಟ್ಟಿರುವವನು ಎಂದು ಅನಿಸುತ್ತಿದೆ. ನಾನು ಕಳೆದ ಎರಡು ವರ್ಷಗಳಿಂದ ನನ್ನ ಸ್ನೇಹಿತರ ಸಹಯೋಗದಿಂದ ನಾಚಿಕೆಯಿಲ್ಲದೆ ಬದುಕಿದ್ದೇನೆ ಮತ್ತು ನಾನು ಅಂದುಕೊಂಡಿರುವುದನ್ನು ಇನ್ನೂ ಸಾಧಿರುವುದಿಲ್ಲ.",
              "te": "నేను ఫెయిల్యూర్‌గా భావిస్తున్నాను. నేను గత రెండు సంవత్సరాలుగా సిగ్గులేకుండా నా స్నేహితులపై ఆధారపడి బ్రతికాను మరియు ఇప్పటికీ నేను అనుకున్నది సాధించలేకపోయాను.",
              "or": "ମୋତେ ଲାଗୁଛି ଯେ ମୁଁ ଏକ ବିଫଲ | ମୁଁ ଗତ ଦୁଇ ବର୍ଷ ଧରି ଲଜ୍ଜାଜନକ \n ଭାବରେ ମୋର ବନ୍ଧୁମାନଙ୍କଠାରୁ ବଞ୍ଚିଛି ଏବଂ ମୁଁ ଯାହା ଦିଗରେ କାର୍ଯ୍ୟ କରୁଛି ତାହା ଏପର୍ଯ୍ୟନ୍ତ ହାସଲ କରି ନାହିଁ |",
              "as": "মই নিজকে বিফল বুলি ভাবোঁ। মই যোৱা দুবছৰ ধৰি নিৰ্লজ্জভাৱে মোৰ বন্ধুবৰ্গৰ ওপৰত জীয়াই আছোঁ আৰু এতিয়াও মই যি কাম কৰি আছো সেয়া লাভ কৰিব পাৰা নাই।",
              "gu": "મને લાગે છે કે હું નિષ્ફળ છું. હું છેલ્લા બે વર્ષથી મારા મિત્રોથી બેશરમ રીતે જીવી રહ્યો છું અને હજુ પણ હું જે કામ કરી રહ્યો છું તે મેળવી શક્યો નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "I hate that I don't have parents who can support me. I hate myself for not being capable enough to clear the UPSC exam.",
              "hi": "मुझे नफरत है कि मेरे  माता-पिता नहीं हैं जो मेरा समर्थन करते। मैं यूपीएससी परीक्षा उत्तीर्ण करने के लिए सक्षम नहीं होने से खुद से नफरत करता हूं।",
              "ka": "ನನ್ನನ್ನು ಬೆಂಬಲಿಸುವ ಪೋಷಕರು ನನಗೆ ಇಲ್ಲ ಎನ್ನುವುದನ್ನು ನಾನು ದ್ವೇಷಿಸುತ್ತೇನೆ. UPSC ಪರೀಕ್ಷೆಯಲ್ಲಿ ತೇರ್ಗಡೆಯಾಗುವಷ್ಟು ಸಾಮರ್ಥ್ಯವನ್ನು ಹೊಂದಿಲ್ಲದಿದ್ದಕ್ಕಾಗಿ ನಾನು ನನ್ನನ್ನು ದ್ವೇಷಿಸಿಕೊಳ್ಳುತ್ತೇನೆ.",
              "te": "నాకు సపోర్ట్ చేయడానికి తల్లిదండ్రులు లేరని బాధ పడుతున్నాను. UPSC పరీక్షలో ఉత్తీర్ణత సాధించేంత సామర్థ్యం లేనందుకు నన్ను నేను ద్వేషిస్తున్నాను.",
              "or": "ମୁଁ ଘୃଣା କରେ ଯେ ମୋର ପିତାମାତା ନାହାଁନ୍ତି ଯେଉଁମାନେ ମୋତେ \n ସମର୍ଥନ କରିପାରିବେ | UPSC ପରୀକ୍ଷା ପାସ କରିବାକୁ ଯଥେଷ୍ଟ ସକ୍ଷମ ନ ଥିବାରୁ ମୁଁ ନିଜକୁ ଘୃଣା କରେ |",
              "as": "মই বেয়া পাওঁ যে মোৰ মা-দেউতা কোনো নাই যিয়ে মোক সমৰ্থন কৰিব পাৰে। UPSC পৰীক্ষাত উত্তীৰ্ণ হ'বলৈ পৰ্যাপ্ত সক্ষম নোহোৱাৰ বাবে মই নিজকে ঘৃণা কৰোঁ।",
              "gu": "મને નફરત છે કે મારી પાસે એવા માતાપિતા નથી જે મને ટેકો આપી શકે. UPSC પરીક્ષા પાસ કરવા માટે પૂરતી સક્ષમ ન હોવા માટે હું મારી જાતને ધિક્કારું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "But maybe I should feel good that I repeatedly tried to achieve my goal. I am afraid that I will become like my father, dependent on other people for the rest of my life.",
              "hi": "लेकिन शायद मुझे अच्छा लगना चाहिए कि मैंने बार-बार अपना लक्ष्य हासिल करने की कोशिश की। मुझे डर है कि मैं अपने पिता की तरह बन जाऊंगा, जो  अपने शेष  जीवन के लिए अन्य लोगों पर निर्भर हैं।",
              "ka": "ಆದರೆ ಬಹುಶಃ ನಾನು ನನ್ನ ಗುರಿಯನ್ನು ಸಾಧಿಸಲು ಪದೇ ಪದೇ ಪ್ರಯತ್ನಿಸಿದೆ ಎನ್ನುವುದರ ಬಗ್ಗೆ ಸಂತೋಷಪಡಬೇಕು ಎಂದು ಭಾವಿಸುತ್ತೇನೆ. ನಾನು ನನ್ನ ತಂದೆಯಂತೆ, ನನ್ನ ಜೀವನದುದ್ದಕ್ಕೂ ಇತರ ಜನರ ಮೇಲೆ ಅವಲಂಬಿತನಾಗುತ್ತೇನೆಯೋ ಏನೋ ಎಂದು ನಾನು ಹೆದರುತ್ತೇನೆ.",
              "te": "కానీ నేను నా లక్ష్యాన్ని సాధించడానికి పదేపదే ప్రయత్నించడం మంచిది అనుకుంటున్నాను. నేను నా తండ్రిలా అవుతానని, నా జీవితాంతం ఇతరులపై ఆధారపడవలసి వస్తుందని నేను భయపడుతున్నాను.",
              "or": "କିନ୍ତୁ ବୋଧହୁଏ ମୁଁ ଭଲ ଅନୁଭବ କରିବା ଉଚିତ୍ ଯେ ମୁଁ ବାରମ୍ବାର ମୋ \n ଲକ୍ଷ୍ୟ ହାସଲ କରିବାକୁ ଚେଷ୍ଟା କଲି | ମୁଁ ଭୟ କରୁଛି ଯେ ମୁଁ ମୋ ବାପାଙ୍କ ପରି ହେବି, ସାରା ଜୀବନ ଅନ୍ୟ ଲୋକଙ୍କ ଉପରେ ନିର୍ଭରଶୀଳ |",
              "as": "কিন্তু হয়তো মই ভাল অনুভৱ কৰা উচিত যে মই বাৰে বাৰে মোৰ লক্ষ্য প্ৰাপ্ত কৰিবলৈ চেষ্টা কৰিছিলোঁ। মই আশংকা কৰোঁ যে মই মোৰ দেউতাৰ দৰেই হ'ম, গোটেই জীৱন আনৰ ওপৰত নিৰ্ভৰশীল হ’ম।",
              "gu": "પરંતુ કદાચ મને સારું લાગવું જોઈએ કે મેં વારંવાર મારા લક્ષ્યને પ્રાપ્ત કરવાનો પ્રયાસ કર્યો. મને ડર છે કે હું મારા પિતા જેવો બની જઈશ, જીવનભર અન્ય લોકો પર નિર્ભર રહીશ."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_5_3//",
              "hi": "A6_5_3 //",
              "ka": "A6_5_3//",
              "te": "A6_5_3//",
              "or": "A6_5_3//",
              "as": "A6_5_3//",
              "gu": "A6_5_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "The following week, Madhav goes to meet Priya Ma'am again.",
              "hi": "अगले सप्ताह, माधव प्रिया मैम से मिलने के लिए फिर चला जाता है।",
              "ka": "ಮುಂದಿನ ವಾರದಲ್ಲಿ, ಮಾಧವ್ ಮತ್ತೆ ಪ್ರಿಯಾ ಮೇಡಮ್ ಅನ್ನು ಭೇಟಿಯಾಗಲು ಹೋಗುತ್ತಾನೆ.",
              "te": "మరుసటి వారం, మాధవ్ మళ్ళీ ప్రియా మేడమ్‌ని కలవడానికి వెళ్తాడు.",
              "or": "ପରବର୍ତ୍ତୀ ସପ୍ତାହରେ, ମାଧବ ପୁନର୍ବାର ପ୍ରିୟା ମା’ଙ୍କୁ ଭେଟିବାକୁ ଯାଆନ୍ତି |",
              "as": "পৰৱৰ্তী সপ্তাহত, মাধৱে পুনৰ প্ৰিয়া মেমক লগ কৰিবলৈ যায়।",
              "gu": "પછીના અઠવાડિયે, માધવ ફરીથી પ્રિયા મેડમને મળવા જાય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Priya",
              "hi": "प्रिया",
              "ka": "ಪ್ರಿಯ",
              "te": "ప్రియా",
              "or": "ପ୍ରିୟା",
              "as": "প্ৰিয়া",
              "gu": "પ્રિયા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Priya",
              "hi": "प्रिया",
              "ka": "ಪ್ರಿಯ",
              "te": "ప్రియా",
              "or": "ପ୍ରିୟା",
              "as": "প্ৰিয়া",
              "gu": "પ્રિયા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Tell me, Madhav, how have you been?",
              "hi": "मुझे बताओ, माधव, तुम कैसे हो?",
              "ka": "ಹೇಳು ಮಾಧವ್, ನೀನು ಹೇಗಿದ್ದೀಯ?",
              "te": "చెప్పు మాధవ్, ఎలా ఉన్నావు?",
              "or": "ମୋତେ କୁହ, ମାଧବ, ତୁମେ କେମିତି ଅଛ?",
              "as": "মোক কোৱা, মাধৱ, তুমি কেনে আছা?",
              "gu": "મને કહો, માધવ, તું કેમ છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I tried writing all my thoughts and feelings out over the last week, and I feel a lot more at ease after getting these thoughts out on paper.",
              "hi": "मैंने पिछले सप्ताह अपने सभी विचारों और भावनाओं को लिखने का प्रयास किया, और इन विचारों को कागज पर व्यक्त करके  मुझे बहुत अधिक सहज लगता है।",
              "ka": "ಕಳೆದ ವಾರದಲ್ಲಿ ನಾನು ನನ್ನ ಎಲ್ಲಾ ಆಲೋಚನೆಗಳು ಮತ್ತು ಭಾವನೆಗಳನ್ನು ಬರೆಯಲು ಪ್ರಯತ್ನಿಸಿದ್ದೇನೆ ಮತ್ತು ಈ ಆಲೋಚನೆಗಳನ್ನು ಕಾಗದದ ಮೇಲೆ ಬರೆದ ನಂತರ ನಾನು ಹೆಚ್ಚು ನಿರಾಳವಾಗಿದ್ದೇನೆ.",
              "te": "నేను గత వారంలో నా ఆలోచనలు మరియు భావాలన్నింటినీ వ్రాయడానికి ప్రయత్నించాను మరియు కాగితంపై ఈ ఆలోచనలు రాసిన తర్వాత నాకు చాలా హాయిగా అనిపిస్తుంది.",
              "or": "ମୁଁ ଗତ ସପ୍ତାହରେ ମୋର ସମସ୍ତ ଚିନ୍ତାଧାରା ଏବଂ ଭାବନା ଲେଖିବାକୁ \n ଚେଷ୍ଟା କଲି, ଏବଂ ଏହି ଚିନ୍ତାଧାରାକୁ କାଗଜରେ ବାହାର କରିବା ପରେ ମୁଁ ବହୁତ ଆରାମରେ ଅନୁଭବ କରେ |",
              "as": "মই যোৱা সপ্তাহত মোৰ সকলো চিন্তা আৰু অনুভূতি লিখিবলৈ চেষ্টা কৰিছিলোঁ, আৰু কাগজত এই চিন্তাবোৰ উলিয়াই দিয়াৰ পিছত মই বহুত বেছি স্বাচ্ছন্দ্য অনুভৱ কৰিছোঁ।",
              "gu": "મેં છેલ્લા અઠવાડિયે મારા બધા વિચારો અને લાગણીઓ લખવાનો પ્રયાસ કર્યો, અને આ વિચારોને કાગળ પર બહાર કાઢ્યા પછી હું વધુ શાંતિ અનુભવું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "I am glad you tried this method out. Most of the people I have met have told me it helped them. Do you want to talk about some of the points you wrote?",
              "hi": "मैं प्रसन्न हूँ कि आपने इस विधि को आजमाया। अधिकाँश लोगों ने मुझे बताया है कि इस विधि ने उनकी सहायता की है। क्या तुम अपने लिखे हुए  कुछ बिंदुओं के बारे में बात करना चाहते हो ? ",
              "ka": "ನೀವು ಈ ವಿಧಾನವನ್ನು ಪ್ರಯತ್ನಿಸಿದ್ದಕ್ಕೆ ನನಗೆ ಖುಷಿಯಾಗಿದೆ. ನಾನು ಭೇಟಿಯಾದ ಹೆಚ್ಚಿನ ಜನರು ನನಗೆ ಸಹಾಯವಾಗಿದೆ ಎಂದು ಹೇಳಿದ್ದಾರೆ. ನೀವು ಬರೆದ ಕೆಲವು ಅಂಶಗಳ ಬಗ್ಗೆ ಮಾತನಾಡಲು ಬಯಸುವಿರಾ?",
              "te": "నువ్వు ఈ పద్ధతిని ప్రయత్నించినందుకు నేను సంతోషిస్తున్నాను. నేను కలిసిన చాలా మంది ఇది తమకు సహాయపడిందని నాకు చెప్పారు. నువ్వు వ్రాసిన కొన్ని అంశాల గురించి మాట్లాడాలనుకుంటున్నావా?",
              "or": "ମୁଁ ଖୁସି ଯେ ତୁମେ ଏହି ପଦ୍ଧତିକୁ ଚେଷ୍ଟା କରିଛ | ମୁଁ ସାକ୍ଷାତ କରିଥିବା\n  ଅଧିକାଂଶ ଲୋକ ମୋତେ ଏହି ପଦ୍ଧତି ସାହାଯ୍ୟ କରିଛି ବୋଲି କହିଛନ୍ତି | ଆପଣ ଲେଖିଥିବା କିଛି ବିଷୟରେ କହିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
              "as": "মই সুখী যে তুমি এই পদ্ধতিটো চেষ্টা কৰিছা। মই লগ পোৱা বেছিভাগ মানুহে মোক কৈছিল যে ই তেওঁলোকক সহায় কৰিছিল। তুমি লিখা কিছুমান কথাৰ বিষয়ে কথা পাতিব বিচাৰা নেকি?",
              "gu": "મને આનંદ છે કે તે આ પદ્ધતિનો પ્રયાસ કર્યો. હું જે લોકોને મળ્યો છું તેમાંથી મોટાભાગના લોકોએ મને કહ્યું છે કે તેનાથી તેને મદદ મળી છે. તમે લખેલા કેટલાક મુદ્દાઓ વિશે વાત કરવા માંગો છો?"
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_5_4",
              "hi": "A6_5_4",
              "ka": "A6_5_4",
              "te": "A6_5_4",
              "or": "A6_5_4",
              "as": "A6_5_4",
              "gu": "A6_5_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav talks about his feelings of worthlessness, fears of depending on his friends, and mainly his lack of motivation to do anything after his recent failure.",
              "hi": "माधव अपनी बेकार की भावनाओं, अपने दोस्तों पर निर्भर होने के डर और मुख्य रूप से अपनी वर्तमान विफलता के बाद कुछ भी करने की प्रेरणा की कमी के बारे में बताता है।",
              "ka": "ಮಾಧವ್ ತನ್ನ ನಿಷ್ಪ್ರಯೋಜಕತೆಯ ಭಾವನೆಗಳು, ತನ್ನ ಸ್ನೇಹಿತರನ್ನು ಅವಲಂಬಿಸಿರುವ ಭಯ ಮತ್ತು ಮುಖ್ಯವಾಗಿ ಅವನ ಇತ್ತೀಚಿನ ವೈಫಲ್ಯದ ನಂತರ ಏನನ್ನೂ ಮಾಡಲು ಪ್ರೇರಣೆಯ ಕೊರತೆಯ ಬಗ್ಗೆ ಮಾತನಾಡುತ್ತಾನೆ.",
              "te": "మాధవ్ తనకు విలువలేదనిపించే ఫీలింగ్స్, తన స్నేహితులపై ఆధారపడాలని భయం మరియు ప్రధానంగా తన ఇటీవలి వైఫల్యం తర్వాత ఏదైనా చేయటానికి అతనిలో ప్రేరణ లేకపోవడం గురించి మాట్లాడాడు.",
              "or": "ମାଧବ ତାଙ୍କର ମୂଲ୍ୟହୀନ ଭାବନା, ସାଙ୍ଗମାନଙ୍କ ଉପରେ ନିର୍ଭର କରିବାର\n  ଭୟ ଏବଂ ମୁଖ୍ୟତଃ ତାଙ୍କର ବିଫଳତା ପରେ କିଛି କରିବାକୁ ଉତ୍ସାହର ଅଭାବ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି |",
              "as": "মাধৱে তাৰ মূল্যহীনতাৰ অনুভূতি, তাৰ বন্ধুবৰ্গৰ ওপৰত নিৰ্ভৰ কৰাৰ ভয়, আৰু মুখ্যতঃ তাৰ শেহতীয়া বিফলতাৰ পিছত যিকোনো কাম কৰিবলৈ তাৰ অনুপ্ৰেৰণাৰ অভাৱৰ বিষয়ে কয়।",
              "gu": "માધવ તેની નકામી લાગણીઓ, તેના મિત્રો પર નિર્ભર રહેવાના ડર અને મુખ્યત્વે તેની તાજેતરની નિષ્ફળતા પછી કંઈપણ કરવાની પ્રેરણાના અભાવ વિશે વાત કરે છે."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_5_5////",
              "hi": "A6_5_5 ////",
              "ka": "A6_5_5////",
              "te": "A6_5_5////",
              "or": "A6_5_5////",
              "as": "A6_5_5////",
              "gu": "A6_5_5////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Madhav, you are going through a lot, and anyone in your shoes would have felt this way. It is entirely normal to feel unmotivated after such a loss.",
              "hi": "माधव, आप बहुत कुछ सह रहे हैं, और आपके स्थान पर कोई भी होता उसने भी ऐसा ही महसूस किया होता। इस तरह की असफलता के बाद प्रेरणा की कमी महसूस करना पूरी तरह से सामान्य है।",
              "ka": "ಮಾಧವ್, ನೀವು ಬಹಳಷ್ಟು ಅನುಭವಿಸುತ್ತಿದ್ದೀರಿ, ಮತ್ತು ನಿಮ್ಮ ಜಾಗದಲ್ಲಿ ಯಾರಿದ್ದರೂ ಈ ರೀತಿ ಅನಿಸುತ್ತದೆ. ಅಂತಹ ಸೋಲಿನ ನಂತರ ಪ್ರಚೋದನೆಯಿಲ್ಲದ ಭಾವನೆ ಇರುವುದು ಸಂಪೂರ್ಣವಾಗಿ ಸಾಮಾನ್ಯವಾಗಿರುತ್ತದೆ..",
              "te": "మాధవ్, మీరు చాలా బాధపడుతున్నారు మరియు మీ షూస్‌లో ఉన్న ఎవరికైనా ఈ విధంగా అనిపించవచ్చు. అటువంటి నష్టం తర్వాత ప్రేరణ లేకుండా అనుభూతి చెందడం పూర్తిగా సాధారణం.",
              "or": "ମାଧବ, ତୁମେ ବହୁତ ଗତି କରୁଛ, ଏବଂ ତୁମର ଜୋତା ପିନ୍ଧିଥିବା ବ୍ୟକ୍ତି \n ଏହିପରି ଅନୁଭବ କରିଥାନ୍ତେ | ଏହିପରି କ୍ଷତି ପରେ ଅବିଭକ୍ତ ଅନୁଭବ କରିବା ସଂପୂର୍ଣ୍ଣ ସ୍ୱାଭାବିକ |",
              "as": "মাধৱ, তুমি বহুত বেয়া সময়ৰ মাজেৰে পাৰ হৈ আছা, আৰু তোমাৰ জাগাত বেলেগ কোনোবা থাকিলেও একে অনুভৱ কৰিলেহেঁতেন। এনে ক্ষতিৰ পিছত অনুপ্ৰেৰণাহীন অনুভৱ কৰাটো সম্পূৰ্ণ স্বাভাৱিক।",
              "gu": "માધવ, તું ઘણું બધું સહન કરી રહ્યો છે, અને તારી જગ્યાએ કોઈ પણ હોઈ ને પણ આવુ લાગ્યું હશે. આવી ખોટ પછી નિરાશા અનુભવવી એ સાવ સામાન્ય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I don't want to feel this way. I don't want to become dependent on people.",
              "hi": "मैं इस तरह महसूस नहीं करना चाहता। मैं लोगों पर निर्भर नहीं बनना चाहता।",
              "ka": "ನಾನು ಈ ರೀತಿ ಅನುಭವಿಸಲು ಬಯಸುವುದಿಲ್ಲ. ನಾನು ಜನರ ಮೇಲೆ ಅವಲಂಬಿತನಾಗಲು ಬಯಸುವುದಿಲ್ಲ.",
              "te": "నాకు ఈ విధంగా ఉండడం ఇష్టం లేదు. నేను ఇతరులపై ఆధారపడటం ఇష్టం లేదు.",
              "or": "ମୁଁ ଏହିପରି ଅନୁଭବ କରିବାକୁ ଚାହେଁ ନାହିଁ | ମୁଁ ଲୋକଙ୍କ ଉପରେ \n ନିର୍ଭରଶୀଳ ହେବାକୁ ଚାହେଁ ନାହିଁ |",
              "as": "মই এনেদৰে অনুভৱ কৰিব নিবিচাৰোঁ। মই মানুহৰ ওপৰত নিৰ্ভৰশীল হ'ব নিবিচাৰোঁ।",
              "gu": "હું આ રીતે અનુભવવા માંગતો નથી. હું લોકો પર નિર્ભર બનવા માંગતો નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Why do you not want to depend on people? It seems like they trust you enough and want to help you.",
              "hi": "आप लोगों पर निर्भर रहना क्यों नहीं चाहते हैं? ऐसा लगता है कि वे आप पर भरोसा करते हैं और आपकी सहायता करना चाहते हैं।",
              "ka": "ನೀವು ಜನರನ್ನು ಏಕೆ ಅವಲಂಬಿಸಲು ಬಯಸುವುದಿಲ್ಲ? ಅವರು ನಿಮ್ಮನ್ನು ಸಾಕಷ್ಟು ನಂಬುತ್ತಾರೆ ಮತ್ತು ನಿಮಗೆ ಸಹಾಯ ಮಾಡಲು ಬಯಸುತ್ತಾರೆ ಎಂದು ತೋರುತ್ತದೆ.",
              "te": "నువ్వు ఇతరులపై ఎందుకు ఆధారపడకూడదు? వారు నిన్ను నమ్మి సహాయం చేయాలనుకుంటున్నట్లు కనిపిస్తోంది.",
              "or": "ଆପଣ କାହିଁକି ଲୋକଙ୍କ ଉପରେ ନିର୍ଭର କରିବାକୁ ଚାହୁଁନାହାଁନ୍ତି? ଲାଗୁଛି\n  ଯେ ସେମାନେ ତୁମକୁ ଯଥେଷ୍ଟ ବିଶ୍ୱାସ କରନ୍ତି ଏବଂ ତୁମକୁ ସାହାଯ୍ୟ କରିବାକୁ ଚାହାଁନ୍ତି |",
              "as": "তুমি কিয় মানুহৰ ওপৰত নিৰ্ভৰশীল হ’ব নিবিচাৰা? এনেকুৱা লাগিছে যেন তেওঁলোকে তোমাক যথেষ্ট বিশ্বাস কৰে আৰু তোমাক সহায় কৰিব বিচাৰে।",
              "gu": "શા માટે તું લોકો પર નિર્ભર રહેવા માંગતા નથી? એવું લાગે છે કે તેઓ તારા પર પૂરતો વિશ્વાસ કરે છે અને તને મદદ કરવા માંગે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "It makes me feel small. I have seen my father constantly depending on people, and I don't like that.",
              "hi": "यह भावना मुझे छोटा महसूस कराती है। मैंने अपने पिता को हमेशा लोगों पर निर्भर ही देखा है, और मुझे यह पसंद नहीं है।",
              "ka": "ಇದು ನನ್ನನ್ನು ಕುಬ್ಜನನ್ನಾಗಿಸುತ್ತದೆ. ನನ್ನ ತಂದೆ ನಿರಂತರವಾಗಿ ಜನರನ್ನು ಅವಲಂಬಿಸಿರುವುದನ್ನು ನಾನು ನೋಡಿದ್ದೇನೆ ಮತ್ತು ಅದು ನನಗೆ ಇಷ್ಟವಿಲ್ಲ",
              "te": "ఇది నాకు అవుమానంగా అనిపిస్తుంది. నేను మా నాన్న నిరంతరం ఇతరులపై ఆధారపడటాన్ని చూశాను మరియు అది నాకు ఇష్టం లేదు.",
              "or": "ଏହା ମୋତେ ଛୋଟ ଅନୁଭବ କରେ | ଲୋକଙ୍କ ଉପରେ ନିର୍ଭର କରି \n ମୁଁ ମୋ ବାପାଙ୍କୁ କ୍ରମାଗତ ଭାବରେ ଦେଖିଛି, ଏବଂ ମୁଁ ତାହା ପସନ୍ଦ କରେ ନାହିଁ |",
              "as": "ই মোক সৰু অনুভৱ কৰায়। মই মোৰ দেউতাক নিৰন্তৰ ভাৱে মানুহৰ ওপৰত নিৰ্ভৰ কৰি আহা দেখিছোঁ, আৰু মই সেইটো ভাল নাপাওঁ।",
              "gu": "તે મને નાનો છું એવું અનુભવે છે. મેં મારા પિતાને સતત લોકો પર આધાર રાખતા જોયા છે અને મને તે પસંદ નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Can you talk more about this?",
              "hi": "क्या आप इसके बारे में और बात कर सकते हैं?",
              "ka": "ನೀವು ಇದರ ಬಗ್ಗೆ ಇನ್ನೂ ಹೆಚ್ಚು ಮಾತನಾಡಬಹುದೇ?",
              "te": "నువ్వు దీని గురించి మరింత మాట్లాడగలవా?",
              "or": "ଆପଣ ଏ ବିଷୟରେ ଅଧିକ କଥାବାର୍ତ୍ତା କରିପାରିବେ କି?",
              "as": "তুমি এই বিষয়ে অধিক ক’ব পাৰিবানে?",
              "gu": "શું તું આ વિશે વધુ વાત કરી શકે  છો?"
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_5_7//",
              "hi": "A6_5_7 //",
              "ka": "A6_5_7//",
              "te": "A6_5_7//",
              "or": "A6_5_7//",
              "as": "A6_5_7//",
              "gu": "A6_5_7//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Madhav thinks for a minute before responding.",
              "hi": "माधव ने जवाब देने से पहले एक मिनट के लिए सोचा।",
              "ka": "ಮಾಧವ್ ಪ್ರತಿಕ್ರಿಯಿಸುವ ಮೊದಲು ಒಂದು ನಿಮಿಷ ಯೋಚಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్ స్పందించే ముందు ఒక్క నిమిషం ఆలోచించాడు.",
              "or": "ଜବାବ ଦେବା ପୂର୍ବରୁ ମାଧବ ଏକ ମିନିଟ୍ ପାଇଁ ଭାବନ୍ତି |",
              "as": "মাধৱে সঁহাৰি দিয়াৰ আগতে এক মিনিটৰ বাবে চিন্তা কৰে।",
              "gu": "માધવ જવાબ આપતા પહેલા એક મિનિટ માટે વિચારે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Priya",
              "hi": "प्रिया",
              "ka": "ಪ್ರಿಯ ",
              "te": "ప్రియా",
              "or": "ପ୍ରିୟା",
              "as": "প্ৰিয়া",
              "gu": "પ્રિયા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "My father has never been involved in my life, nor does he understand what I want. He might have done the right thing to send me to a boarding school, but he never cared much.",
              "hi": "मेरे पिता मेरे जीवन में कभी भी  शामिल नहीं हुए हैं, न ही वह समझते हैं कि मैं क्या चाहता हूं। उन्होंने मुझे एक बोर्डिंग स्कूल में भेजने का सही काम किया होगा, लेकिन उन्होंने कभी भी ज्यादा परवाह नहीं की।",
              "ka": "ನನ್ನ ತಂದೆ ನನ್ನ ಜೀವನದಲ್ಲಿ ಎಂದಿಗೂ ತೊಡಗಿಸಿಕೊಂಡಿಲ್ಲ, ಅಥವಾ ನನಗೆ ಬೇಕಾದುದನ್ನು ಅವರು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದಿಲ್ಲ. ಅವರು ನನ್ನನ್ನು ಬೋರ್ಡಿಂಗ್ ಶಾಲೆಗೆ ಕಳುಹಿಸಿ ಸರಿಯಾದ ಕೆಲಸವನ್ನು ಮಾಡಿರಬಹುದು, ಆದರೆ ಅವರು ಎಂದಿಗೂ ನನ್ನ ಬಗ್ಗೆ ಹೆಚ್ಚು ಕಾಳಜಿ ವಹಿಸಲಿಲ್ಲ.",
              "te": "నా జీవితంలో నా తండ్రి ఎప్పుడూ పాల్గొనలేదు, నాకు ఏమి కావాలో అర్థం చేసుకోలేదు. అతను నన్ను బోర్డింగ్ స్కూల్‌కి పంపి సరైన పని చేసి ఉండవచ్చు, కానీ అతను ఎప్పుడూ నన్ను పెద్దగా పట్టించుకోలేదు.",
              "or": "ମୋ ବାପା ମୋ ଜୀବନରେ କେବେବି ଜଡିତ ହୋଇ ନାହାଁନ୍ତି କିମ୍ବା ମୁଁ \n ଯାହା ଚାହୁଁଛି ସେ ବୁଝନ୍ତି ନାହିଁ | ମୋତେ ଏକ ବୋର୍ଡିଂ ସ୍କୁଲକୁ ପଠାଇବା ପାଇଁ ସେ ହୁଏତ ଠିକ୍ କାର୍ଯ୍ୟ କରିଥାଇ ପାରନ୍ତି, କିନ୍ତୁ ସେ କେବେବି ଧ୍ୟାନ ଦେଇନଥିଲେ |",
              "as": "মোৰ দেউতা কেতিয়াও মোৰ জীৱনৰ সৈতে জড়িত হোৱা নাই, বা মই কি বিচাৰোঁ বুজি পোৱা নাই। তেওঁ হয়তো মোক এখন বৰ্ডিং স্কুললৈ পঠিয়াই সঠিক কাম কৰিছিলে, কিন্তু তেওঁ কেতিয়াও মোৰ বাবে চিন্তা কৰা নাছিল।",
              "gu": "મારા પિતા મારા જીવનમાં ક્યારેય સામેલ થયા નથી, ન તો તેઓ સમજી શક્યા છે કે મારે શું જોઈએ છે. તેણે મને બોર્ડિંગ સ્કૂલમાં મોકલવાનું યોગ્ય લાગ્યું હશે, પરંતુ તેઓને ક્યારેય મારી કાળજી લીધી ન હતી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Do you feel the lack of care in your life?",
              "hi": "क्या आप अपने जीवन में देखभाल की कमी अनुभव करते हैं?",
              "ka": "ನಿಮ್ಮ ಜೀವನದಲ್ಲಿ ಕಾಳಜಿಯ ಕೊರತೆಯನ್ನು ನೀವು ಅನುಭವಿಸುತ್ತಿದ್ದೀರಾ?",
              "te": "నీ జీవితంలో సంరక్షణ లేనట్టు నీకు అనిపిస్తుందా?",
              "or": "ତୁମ ଜୀବନରେ ଯତ୍ନର ଅଭାବ ଅନୁଭବ କରୁଛ କି?",
              "as": "তুমি তোমাৰ জীৱনত যত্নৰ অভাৱ অনুভৱ কৰা নেকি?",
              "gu": "શું તું તારા જીવનમાં કાળજીનો અભાવ અનુભવો છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Actually, no, I have Badri and Kuldeep with me. They care for me immensely. But I feel I don't reciprocate enough.",
              "hi": "वास्तव में,  नहीं, बद्री और कुलदीप मेरे साथ हैं। वे मेरा बहुत ख़याल रखते हैं। लेकिन मुझे लगता है कि मैं इसके बदले में कुछ नहीं कर पाता हूं।",
              "ka": "ವಾಸ್ತವವಾಗಿ, ಇಲ್ಲ, ನನ್ನೊಂದಿಗೆ ಬದ್ರಿ ಮತ್ತು ಕುಲದೀಪ್ ಇದ್ದಾರೆ. ಅವರು ನನ್ನ ಬಗ್ಗೆ ಅಪಾರ ಕಾಳಜಿ ವಹಿಸುತ್ತಾರೆ. ಆದರೆ ನಾನು ಸಾಕಷ್ಟು ಸ್ಪಂದಿಸುತ್ತಿಲ್ಲ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "నిజానికి, కాదు, నాతో బద్రి మరియు కుల్దీప్ ఉన్నారు. వాళ్లు నాపట్ల ఎంతో శ్రద్ధ చూపిస్తారు. కానీ నేను మాత్రం వాళ్ళకి అంత ఇవ్వలేకపోతున్న అనిపిస్తుంది.",
              "or": "ବାସ୍ତବରେ, ନା, ମୋ ସହିତ ବଦ୍ରି ଏବଂ କୁଲଦୀପ ଅଛନ୍ତି | ସେମାନେ ମୋ\n  ପାଇଁ ବହୁତ ଯତ୍ନ ନିଅନ୍ତି | କିନ୍ତୁ ମୁଁ ଅନୁଭବ କରୁଛି ଯେ ମୁଁ ଯଥେଷ୍ଟ ପ୍ରତିକ୍ରିୟା କରୁନାହିଁ |",
              "as": "আচলতে, নহয়, মোৰ লগত বদ্ৰী আৰু কুলদীপ আছে। তেওঁলোকে মোৰ যথেষ্ট যত্ন লয়। কিন্তু মই অনুভৱ কৰোঁ যে মই পৰ্যাপ্ত প্ৰতিদান নিদিওঁ।",
              "gu": "ખરેખર, ના, મારી સાથે બદ્રી અને કુલદીપ છે. તેઓ મારી ખૂબ કાળજી રાખે છે. પરંતુ મને લાગે છે કે હું પૂરતું બદલામાં પાછો આપતો નથી."
            }
          }
        ],
        "page21": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_7_1",
              "hi": "A6_7_1",
              "ka": "A6_7_1",
              "te": "A6_7_1",
              "or": "A6_7_1",
              "as": "A6_7_1",
              "gu": "A6_7_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav feels a lot better after this session. When he returns to his room that day, he hugs Badri and Kuldeep.",
              "hi": "माधव इस सत्र के बाद बहुत अच्छा अनुभव करता है। जब वह उस दिन अपने कमरे में लौटता है, तो वह बद्री और कुलदीप को गले लगाता है।",
              "ka": "ಈ ಮಾತುಕತೆಯ ನಂತರ ಮಾಧವ್‌ಗೆ ತುಂಬಾ ಉತ್ತಮವೆನಿಸುತ್ತದೆ.ಆ ದಿನ ಅವನು ತನ್ನ ಕೋಣೆಗೆ ಹಿಂತಿರುಗಿದಾಗ, ಅವನು ಬದ್ರಿ ಮತ್ತು ಕುಲದೀಪ್ ಅನ್ನು ತಬ್ಬಿಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "ఈ సెషన్ తర్వాత మాధవ్ చాలా బాగున్నాడు. ఆ రోజు అతను తన గదికి తిరిగి వచ్చినప్పుడు, అతను బద్రి మరియు కుల్దీప్‌లను కౌగిలించుకుంటాడు.",
              "or": "ଏହି ଅଧିବେଶନ ପରେ ମାଧବ ବହୁତ ଭଲ ଅନୁଭବ କରନ୍ତି | ସେଦିନ\n  ଯେତେବେଳେ ସେ ନିଜ କୋଠରୀକୁ ଫେରି ଆସନ୍ତି, ସେତେବେଳେ ସେ ବଦ୍ରି ଏବଂ କୁଲଦୀପଙ୍କୁ ଆଲିଙ୍ଗନ କରନ୍ତି |",
              "as": "এই কথা-বতৰা খিনিৰ পিছত মাধৱে বহুত ভাল অনুভৱ কৰে। যেতিয়া তেওঁ সেইদিনা তেওঁৰ কোঠালৈ উভতি আহে, তেওঁ বদ্ৰী আৰু কুলদীপক সাৱটি ধৰে।",
              "gu": "માધવને આ સત્ર પછી ઘણું સારું લાગે છે. તે દિવસે જ્યારે તે તેના રૂમમાં પાછો ફરે છે, ત્યારે તેણે બદ્રી અને કુલદીપને ગળે લગાવે છે."
            }
          }
        ],
        "page22": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_7_2",
              "hi": "A6_7_2",
              "ka": "A6_7_2",
              "te": "A6_7_2",
              "or": "A6_7_2",
              "as": "A6_7_2",
              "gu": "A6_7_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav continues attending Priya Ma'am's counselling session once a week. After a few months, he begins feeling a lot happier and more satisfied.",
              "hi": "माधव सप्ताह में एक बार प्रिया मैम के परामर्श सत्र में भाग लेता है। कुछ महीनों के बाद, वह बहुत खुश और अधिक संतुष्ट महसूस करता है।",
              "ka": "ಮಾಧವ್ ವಾರಕ್ಕೊಮ್ಮೆ ಪ್ರಿಯಾ ಮೇಮ್ ಅವರ ಕೌನ್ಸಿಲಿಂಗ್ ಸೆಷನ್‌ಗೆ ಹಾಜರಾಗುವುದನ್ನು ಮುಂದುವರಿಸುತ್ತಾರೆ. ಕೆಲವು ತಿಂಗಳುಗಳ ನಂತರ, ಅವನು ಹೆಚ್ಚು ಸಂತೋಷ ಮತ್ತು ಹೆಚ್ಚು ತೃಪ್ತಿ ಹೊಂದಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్ వారానికి ఒకసారి ప్రియా మేడమ్ కౌన్సెలింగ్ సెషన్‌కు హాజరవుతూనే ఉన్నాడు. కొన్ని నెలల తర్వాత, అతను చాలా సంతోషంగా మరియు ఎక్కువ సంతృప్తిగా ఉన్నట్టు కనిపిస్తాడు.",
              "or": "ମାଧବ ସପ୍ତାହରେ ଥରେ ପ୍ରିୟା ମା’ଙ୍କ ପରାମର୍ଶ ଅଧିବେଶନରେ ଯୋଗଦେବା \n ଜାରି ରଖିଛନ୍ତି | କିଛି ମାସ ପରେ, ସେ ବହୁତ ଖୁସି ଏବଂ ଅଧିକ ସନ୍ତୁଷ୍ଟ ଅନୁଭବ କରିବାକୁ ଲାଗିଲେ |",
              "as": "মাধৱে সপ্তাহত এবাৰকৈ প্ৰিয়া মেমৰ পৰামৰ্শ অধিবেশনত উপস্থিত থাকে। কেইমাহমানৰ পিছত, তেওঁ যথেষ্ট সুখী আৰু অধিক সন্তুষ্ট যেন অনুভৱ কৰিবলৈ আৰম্ভ কৰে।",
              "gu": "માધવ અઠવાડિયામાં એકવાર પ્રિયા મેડમના કાઉન્સેલિંગ સેશનમાં હાજરી આપવાનું ચાલુ રાખે છે. થોડા મહિનાઓ પછી, તે ઘણો ખુશ અને વધુ સંતુષ્ટ અનુભવવા લાગે છે."
            }
          }
        ],
        "page23": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_7_3",
              "hi": "A6_7_3",
              "ka": "A6_7_3",
              "te": "A6_7_3",
              "or": "A6_7_3",
              "as": "A6_7_3",
              "gu": "A6_7_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He continues journalling and begins to consider all the things he is grateful for, including his friends and teachers.",
              "hi": "वह जर्नल में लिखना जारी रखता है और उन सभी चीजों पर विचार करना शुरू करता है जिनके लिए वह अपने मित्रों और शिक्षकों सबका आभारी हैं।",
              "ka": "ಅವನು ಜರ್ನಲಿಂಗ್ ಅನ್ನು ಮುಂದುವರೆಸುತ್ತಾರೆ ಮತ್ತು ಅವನ ಸ್ನೇಹಿತರು ಮತ್ತು ಶಿಕ್ಷಕರು ಸೇರಿದಂತೆ ತಾನು ಕೃತಜ್ಞನಾಗಬೇಕಾಗಿರುವ ಎಲ್ಲಾ ವಿಷಯಗಳನ್ನು ಪರಿಗಣಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ.",
              "te": "అతను జర్నలింగ్‌ను కొనసాగిస్తాడు మరియు అతని స్నేహితులు మరియు ఉపాధ్యాయులతో సహా అతనికి ఉన్న అన్ని విషయాల పట్ల కృతజ్ఞతగా ఉండడం ప్రారంభించాడు.",
              "or": "ସେ ଜର୍ଣ୍ଣାଲିଂ ଜାରି ରଖିଛନ୍ତି ଏବଂ ତାଙ୍କ ବନ୍ଧୁ ଏବଂ ଶିକ୍ଷକମାନଙ୍କ ସମେତ \n ସେ କୃତଜ୍ଞ ଥିବା ସମସ୍ତ ବିଷୟ ଉପରେ ବିଚାର କରିବାକୁ ଲାଗିଲେ |",
              "as": "সি জাৰ্নেলিং অব্যাহত ৰাখে আৰু তাৰ বন্ধু আৰু শিক্ষকসকলকে ধৰি সি কৃতজ্ঞ হোৱা সকলো বোৰ কথা বিবেচনা কৰিবলৈ আৰম্ভ কৰে।",
              "gu": "તે જર્નલ લખવાનું ચાલુ રાખે છે અને તેના મિત્રો અને શિક્ષકો સહિત તે તમામ બાબતોને ધ્યાનમાં લેવાનું શરૂ કરે છે જેના માટે તે આભારી છે."
            }
          }
        ],
        "page24": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_7_4",
              "hi": "A6_7_4",
              "ka": "A6_7_4",
              "te": "A6_7_4",
              "or": "A6_7_4",
              "as": "A6_7_4",
              "gu": "A6_7_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He feels like many of the things that weighed him down are now things he can think about comfortably.",
              "hi": "उसे ऐसा लगता है कि जो चीजेँ उन्हें भारी लगती थीं, वे अब ऐसी चीजें हैं जिनके बारे में वह आराम से सोच सकता है।",
              "ka": "ತನಗೆ ಭಾರವಾಗಿದ್ದ ಅನೇಕ ಸಂಗತಿಗಳು ಈಗ ತಾನು ಆರಾಮವಾಗಿ ಯೋಚಿಸಬಹುದಾದ ವಿಷಯಗಳೆಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "తనని ఇబ్బంది పెట్టిన అనేక విషయాలు ఇప్పుడు హాయిగా ఆలోచించగల అంశాలుగా భావిస్తున్నాడు.",
              "or": "ସେ ଅନୁଭବ କରନ୍ତି ଯେ ଅନେକ ବିଷୟ ଯାହା ତାଙ୍କୁ ଭାରି ଭଳି ଅନୁଭବ କରାଏ \n ତାହାକୁ ବର୍ତ୍ତମାନ ସେ ଆରାମରେ ଚିନ୍ତା କରିପାରନ୍ତି |",
              "as": "সি অনুভৱ কৰে যে তাক ভাৰাক্ৰান্ত কৰা বহুতো বস্তুৰ কথা এতিয়া সি আৰামেৰে ভাবিব পাৰে।",
              "gu": "તેને લાગે છે કે ઘણી બધી વસ્તુઓ જે તેના પર ભાર મૂકે છે તે હવે એવી વસ્તુઓ છે જેના વિશે તે આરામથી વિચારી શકે છે."
            }
          }
        ],
        "page25": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_7_5",
              "hi": "A6_7_5",
              "ka": "A6_7_5",
              "te": "A6_7_5",
              "or": "A6_7_5",
              "as": "A6_7_5",
              "gu": "A6_7_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "But he has to decide soon what he wants to do after this. Madhav knows he has two more attempts to clear his UPSC, but he also knows how difficult the preparation is.",
              "hi": "लेकिन उसे जल्द ही तय करना होगा कि वह इसके बाद क्या करना चाहता है। माधव जानता है कि उसके पास यूपीएससी को उत्तीर्ण करने के दो और प्रयास हैं, लेकिन वह यह भी जानता है कि तैयारी कितनी कठिन है।",
              "ka": "ಆದರೆ ಇದರ ನಂತರ ಏನು ಮಾಡಬೇಕೆಂದು ಅವನು ಶೀಘ್ರದಲ್ಲೇ ನಿರ್ಧರಿಸಬೇಕು. ಮಾಧವ್ ತನ್ನ UPSC ನಲ್ಲಿ ತೇರ್ಗಡೆಗೊಳ್ಳಲು ಇನ್ನೂ ಎರಡು ಪ್ರಯತ್ನಗಳನ್ನು ಹೊಂದಿರುವುದನ್ನು ತಿಳಿದಿದ್ದಾನೆ, ಆದರೆ ತಯಾರಿ ಎಷ್ಟು ಕಷ್ಟಕರವಾಗಿದೆ ಎನ್ನುವುದು ಅವನಿಗೆ ತಿಳಿದಿದೆ.",
              "te": "అయితే దీని తర్వాత ఏం చేయాలనేది త్వరలోనే నిర్ణయించుకోవాలి. మాధవ్‌కి తన UPSC క్లియర్ చేయడానికి మరో రెండు ప్రయత్నాలు ఉన్నాయని తెలుసు, కానీ ప్రిపరేషన్ ఎంత కష్టమో కూడా అతనికి తెలుసు.",
              "or": "କିନ୍ତୁ ଏହା ପରେ ସେ କଣ କରିବାକୁ ଚାହୁଁଛନ୍ତି ଶୀଘ୍ର ନିଷ୍ପତ୍ତି ନେବାକୁ ପଡିବ | \n ମାଧବ ଜାଣନ୍ତି ଯେ ତାଙ୍କର UPSC ରେ ପାସ କରିବାକୁ ତାଙ୍କର ଆଉ ଦୁଇଟି ପ୍ରୟାସ ଅଛି, କିନ୍ତୁ ପ୍ରସ୍ତୁତି କେତେ କଷ୍ଟକର ତାହା ମଧ୍ୟ ସେ ଜାଣନ୍ତି |",
              "as": "কিন্তু সি সোনকালেই সিদ্ধান্ত ল'ব লাগিব যে ইয়াৰ পিছত সি কি কৰিব বিচাৰে। মাধৱে জানে যে তাৰ UPSC সম্পন্ন কৰিবলৈ আৰু দুটা প্ৰয়াস আছে, কিন্তু সি লগতে এইটোও জানে যে প্ৰস্তুতি কিমান কঠিন।",
              "gu": "પરંતુ તેણે જલ્દી જ નક્કી કરવાનું છે કે તે આ પછી શું કરવા માંગે છે. માધવ જાણે છે કે તેની પાસે UPSC પાસ કરવાના વધુ બે પ્રયાસ છે, પરંતુ તે એ પણ જાણે છે કે તૈયારી કેટલી મુશ્કેલ છે."
            }
          }
        ],
        "page26": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_7_6",
              "hi": "A6_7_6",
              "ka": "A6_7_6",
              "te": "A6_7_6",
              "or": "A6_7_6",
              "as": "A6_7_6",
              "gu": "A6_7_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He feels he can find another career path, which might help him get a job soon.",
              "hi": "उसे लगता है कि वह एक और करियर पथ ढूंढ सकता है, जो शीघ्र नौकरी पाने में सहायक हो सकता है।",
              "ka": "ಅವನು ಮತ್ತೊಂದು ವೃತ್ತಿ ಮಾರ್ಗವನ್ನು ಕಂಡುಕೊಳ್ಳಬಹುದು ಎಂದು ಭಾವಿಸುತ್ತಾನೆ, ಇದು ಶೀಘ್ರದಲ್ಲೇ ಕೆಲಸ ಪಡೆಯಲು ಸಹಾಯ ಮಾಡಬಹುದು.",
              "te": "అతను త్వరగా ఉద్యోగం పొందడానికి వేరొక కెరీర్ మార్గాన్ని కనుగొనగలనని అతను భావిస్తున్నాడు.",
              "or": "ସେ ଅନୁଭବ କରନ୍ତି ଯେ ସେ ଅନ୍ୟ ଏକ କ୍ୟାରିଅର୍ ପଥ ପାଇପାରିବେ,\n  ଯାହା ତାଙ୍କୁ ଶୀଘ୍ର ଚାକିରି କରିବାରେ ସାହାଯ୍ୟ କରିପାରେ |",
              "as": "সি অনুভৱ কৰে যে সি আন এটা জীৱিকাৰ পথ বিচাৰি পাব, যি তাক সোনকালে চাকৰি পোৱাত সহায় কৰিব।",
              "gu": "તેને લાગે છે કે તે અન્ય કારકિર્દીનો માર્ગ શોધી શકે છે, જે તેને ટૂંક સમયમાં નોકરી મેળવવામાં મદદ કરી શકે છે."
            }
          }
        ],
        "page27": [
          {
            "valuePath": "display",
            "value": {
              "en": "A6_7_7_D",
              "hi": "A6_7_7_D",
              "ka": "A6_7_7_D",
              "te": "A6_7_7_D",
              "or": "A6_7_7_D",
              "as": "A6_7_7_D",
              "gu": "A6_7_7_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Madhav do?",
              "hi": "माधव को क्या करना चाहिए?",
              "ka": "ಮಾಧವ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "మాధవ్ ఏం చేయాలి?",
              "or": "ମାଧବ କ’ଣ କରିବା ଉଚିତ୍?",
              "as": "মাধৱে কি কৰা উচিত?",
              "gu": "માધવે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Continue his preparations",
              "hi": "अपनी तैयारी जारी रखे",
              "ka": "ಅವನ ಸಿದ್ಧತೆಗಳನ್ನು ಮುಂದುವರಿಸುವುದೇ",
              "te": "అతని ప్రిపరేషన్ కొనసాగించాలి",
              "or": "ତାଙ୍କର ପ୍ରସ୍ତୁତି ଜାରି ରଖ |",
              "as": "তাৰ প্ৰস্তুতি অব্যাহত ৰাখিব",
              "gu": "તેની તૈયારીઓ ચાલુ રાખો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Find another career path",
              "hi": "एक और करियर रास्ता खोजे",
              "ka": "ಮತ್ತೊಂದು ವೃತ್ತಿ ಮಾರ್ಗವನ್ನು ಕಂಡುಕೊಳ್ಳುವುದೇ",
              "te": "మరొక కెరీర్ మార్గాన్ని కనుగొనాలి",
              "or": "ଅନ୍ୟ ଏକ କ୍ୟାରିଅର୍ ର ପଥ ଖୋଜ |",
              "as": "জীৱিকাৰ আন এটা পথ বিচাৰিব",
              "gu": "કારકિર્દીનો બીજો રસ્તો શોધો"
            }
          }
        ]
      },
      "scene16": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Continue preparing  for UPSC",
              "hi": "यूपीएससी के लिए तैयारी जारी रखे",
              "ka": "UPSC ಗಾಗಿ ತಯಾರಿಯನ್ನು ಮುಂದುವರಿಸುವುದು",
              "te": "UPSC ప్రిపరేషన్ కొనసాగించాలి",
              "or": "UPSC ପାଇଁ ପ୍ରସ୍ତୁତି ଜାରି ରଖ |",
              "as": "UPSCৰ বাবে প্ৰস্তুতি অব্যাহত ৰাখিব",
              "gu": "UPSC ની તૈયારી ચાલુ રાખો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to continue his preparation. Joining the Civil Service has been his dream, and he does not want to let go of it.",
              "hi": "माधव ने अपनी तैयारी जारी रखने का फैसला किया। सिविल सेवा में होना उसका सपना रहा है, और वह इसे जाने नहीं देना चाहता।",
              "ka": "ಮಾಧವ್ ತನ್ನ ತಯಾರಿಯನ್ನು ಮುಂದುವರಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ನಾಗರಿಕ ಸೇವೆಗೆ ಸೇರುವುದು ಅವನ ಕನಸಾಗಿದ್ದು, ಅದನ್ನು ಬಿಡಲು ಬಯಸುವುದಿಲ್ಲ.",
              "te": "మాధవ్ తన ప్రిపరేషన్ కొనసాగించాలని నిర్ణయించుకున్నాడు. సివిల్‌ సర్వీస్‌లో చేరాలనేది తన కల, దాన్ని వదులుకోవడం అతనికి ఇష్టం లేదు.",
              "or": "ମାଧବ ତାଙ୍କର ପ୍ରସ୍ତୁତି ଜାରି ରଖିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ସିଭିଲ ସର୍ଭିସରେ \n ଯୋଗଦେବା ତାଙ୍କର ସ୍ୱପ୍ନ ଥିଲା, ଏବଂ ସେ ଏହାକୁ ଛାଡିବାକୁ ଚାହୁଁନାହାଁନ୍ତି |",
              "as": "মাধৱে তাৰ প্ৰস্তুতি অব্যাহত ৰখাৰ সিদ্ধান্ত লয়। অসামৰিক সেৱাত যোগদান কৰাটো তাৰ সপোন আছিল, আৰু সি ইয়াক এৰি দিব নিবিচাৰে।",
              "gu": "માધવ તેની તૈયારી ચાલુ રાખવાનું નક્કી કરે છે. સિવિલ સર્વિસમાં જોડાવાનું તેનું સપનું હતું અને તે તેને છોડવા માંગતો નથી."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_1_2",
              "hi": "A7_1_2",
              "ka": "A7_1_2",
              "te": "A7_1_2",
              "or": "A7_1_2",
              "as": "A7_1_2",
              "gu": "A7_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "A year later, he gives the preliminary exam and confidently clears it. He appears for the main exam as well.",
              "hi": "एक साल बाद, वह प्रारंभिक परीक्षा देता है और आत्मविश्वास के साथ इसे उत्तीर्ण करता है। वह मुख्य परीक्षा के लिए भी उपस्थित होता है।",
              "ka": "ಒಂದು ವರ್ಷದ ನಂತರ, ಅವನು ಪ್ರಾಥಮಿಕ ಪರೀಕ್ಷೆಯನ್ನು ನೀಡುತ್ತಾನೆ ಮತ್ತು ಆತ್ಮವಿಶ್ವಾಸದಿಂದ ಅದರಲ್ಲಿ ತೇರ್ಗಡೆ ಹೊಂದುತ್ತಾನೆ.  ಅವನು ಮುಖ್ಯ ಪರೀಕ್ಷೆಗೂ ಹಾಜರಾಗುತ್ತಾನೆ.",
              "te": "ఒక సంవత్సరం తరువాత, అతను ప్రిలిమినరీ పరీక్ష రాస్తాడు మరియు దానిని నమ్మకంగా క్లియర్ చేస్తాడు. అతను మెయిన్ పరీక్షకు కూడా హాజరు అవుతాడు.",
              "or": "ଗୋଟିଏ ବର୍ଷ ପରେ, ସେ ପ୍ରାଥମିକ ପରୀକ୍ଷା ଦିଅନ୍ତି ଏବଂ ଆତ୍ମବିଶ୍ୱାସରେ \n ଏହାକୁ ପାସ କରନ୍ତି | ସେ ମଧ୍ୟ ମୁଖ୍ୟ ପରୀକ୍ଷା ପାଇଁ ହାଜର ହୁଅନ୍ତି |",
              "as": "এবছৰ পিছত, তেওঁ প্ৰাথমিক পৰীক্ষা দিয়ে আৰু আত্মবিশ্বাসেৰে উত্তীৰ্ণ হয়। তেওঁ মূল পৰীক্ষাতো অৱতীৰ্ণ হয়।",
              "gu": "એક વર્ષ પછી, તે પ્રારંભિક પરીક્ષા આપે છે અને આત્મવિશ્વાસપૂર્વક તેને પાસ કરે છે. તે મુખ્ય પરીક્ષા માટે પણ હાજરી આપે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_1_3",
              "hi": "A7_1_3",
              "ka": "A7_1_3",
              "te": "A7_1_3",
              "or": "A7_1_3",
              "as": "A7_1_3",
              "gu": "A7_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Finally, the time has come to check the results. Madhav, Kuldeep and Badri are glued to the computer screen.",
              "hi": "अंत में, परिणाम की जांच करने का समय आ गया। माधव, कुलदीप और बद्री पूरे समय कंप्यूटर स्क्रीन पर लगे हैं।",
              "ka": "ಅಂತಿಮವಾಗಿ, ಫಲಿತಾಂಶಗಳನ್ನು ಪರಿಶೀಲಿಸುವ ಸಮಯ ಬಂದಿದೆ. ಮಾಧವ್, ಕುಲದೀಪ್ ಮತ್ತು ಬದ್ರಿ ಕಂಪ್ಯೂಟರ್ ಪರದೆಗೆ ಅಂಟಿಕೊಂಡಿರುತ್ತಾರೆ.",
              "te": "చివరగా, ఫలితాలను చూడడానికి సమయం ఆసన్నమైంది. మాధవ్, కుల్దీప్ మరియు బద్రి కంప్యూటర్ స్క్రీన్‌కి అతుక్కుపోయారు.",
              "or": "ଶେଷରେ, ଫଳାଫଳ ଯାଞ୍ଚ କରିବାର ସମୟ ଆସିଛି | ମାଧବ, କୁଲଦୀପ\n  ଏବଂ ବଦ୍ରି କମ୍ପ୍ୟୁଟର ସ୍କ୍ରିନରେ ଲାଗିଛନ୍ତି |",
              "as": "অৱশেষত, ফলাফলবোৰ পৰীক্ষা কৰাৰ সময় আহি পৰে। মাধৱ, কুলদীপ আৰু বদ্ৰীয়ে কম্পিউটাৰ স্ক্ৰীণত পৰীক্ষা কৰি আছে।",
              "gu": "છેવટે, પરિણામો તપાસવાનો સમય આવી ગયો છે. માધવ, કુલદીપ અને બદ્રી કોમ્પ્યુટર સ્ક્રીન પર ચોંટેલા છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_2_1",
              "hi": "A7_2_1",
              "ka": "A7_2_1",
              "te": "A7_2_1",
              "or": "A7_2_1",
              "as": "A7_2_1",
              "gu": "A7_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Madhav is too nervous to check out his result and has asked Badri and Kuldeep to do it for him. Suddenly both of them start screaming...",
              "hi": "माधव अपने नतीजे की जांच स्वयं करने में बहुत घबड़ा रहा है और उसने बद्री और कुलदीप से उसके लिए ऐसा करने के लिए कहा है। अचानक दोनों चीखने लगते हैं ...",
              "ka": "ಮಾಧವ್ ತನ್ನ ಫಲಿತಾಂಶವನ್ನು ಪರಿಶೀಲಿಸಲು ತುಂಬಾ ನರ್ವಸ್ ಆಗಿದ್ದಾನೆ ಮತ್ತು ಬದ್ರಿ ಮತ್ತು ಕುಲದೀಪ್ ತನಗಾಗಿ ಅದನ್ನು ಮಾಡುವಂತೆ ಕೇಳಿಕೊಂಡಿದ್ದಾನೆ. ಇದ್ದಕ್ಕಿದ್ದಂತೆ ಇಬ್ಬರೂ ಕಿರುಚಲು ಪ್ರಾರಂಭಿಸಿದರು ",
              "te": "మాధవ్ తన రిజల్ట్ ని చూసుకోవడానికి చాలా భయపడి బద్రి మరియు కుల్దీప్‌ని తన కోసం చూడమని అడిగాడు. ఒక్కసారిగా ఇద్దరూ అరవడం మొదలుపెట్టారు...",
              "or": "ତାଙ୍କ ଫଳାଫଳ ଯାଞ୍ଚ କରିବାକୁ ମାଧବ ଅତ୍ୟଧିକ ନର୍ଭସ ଏବଂ ବଦ୍ରି ଏବଂ \n କୁଲଦୀପଙ୍କୁ ତାଙ୍କ ପାଇଁ ଏହା କରିବାକୁ କହିଛନ୍ତି। ହଠାତ୍ ଦୁହେଁ ଚିତ୍କାର କରିବାକୁ ଲାଗିଲେ ...",
              "as": "মাধৱে তাৰ ফলাফল পৰীক্ষা কৰিবলৈ বহুত বিব্ৰত হৈ আছে আৰু বদ্ৰী আৰু কুলদীপক তাৰ বাবে এইটো পৰীক্ষা কৰিবলৈ কৈছে। হঠাতে তেওঁলোক দুয়ো চিঞৰিবলৈ ধৰিলে...",
              "gu": "માધવ તેનું પરિણામ જોવા માટે ખૂબ જ નિરાશ છે અને તેણે બદ્રી અને કુલદીપને તેના માટે તે કરવા કહ્યું છે. અચાનક બંને ચીસો પાડવા લાગ્યા..."
            }
          }
        ],
        "page22": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_2_1b",
              "hi": "A7_2_1B",
              "ka": "A7_2_1b",
              "te": "A7_2_1b",
              "or": "A7_2_1b",
              "as": "A7_2_1b",
              "gu": "A7_2_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav has cleared the Civil Services exam!",
              "hi": "माधव ने सिविल सेवा परीक्षा उत्तीर्ण कर ली है!",
              "ka": "ಮಾಧವ್ ಸಿವಿಲ್ ಸರ್ವೀಸಸ್ ಪರೀಕ್ಷೆಯಲ್ಲಿ ತೇರ್ಗಡೆ ಹೊಂದಿದ್ದಾನೆ!",
              "te": "మాధవ్ సివిల్ సర్వీసెస్ పరీక్షలో ఉత్తీర్ణత సాధించాడు!",
              "or": "ମାଧବ ସିଭିଲ ସର୍ଭିସ ପରୀକ୍ଷାକୁ ପାସ କରିଛନ୍ତି!",
              "as": "মাধৱে অসামৰিক সেৱাৰ পৰীক্ষাত উত্তীৰ্ণ হৈছে!",
              "gu": "માધવે સિવિલ સર્વિસની પરીક્ષા પાસ કરી છે!"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_2_2",
              "hi": "A7_2_2",
              "ka": "A7_2_2",
              "te": "A7_2_2",
              "or": "A7_2_2",
              "as": "A7_2_2",
              "gu": "A7_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Kuldeep and Badri are incredibly proud of Madhav. They have seen him overcome many challenges over the years and are happy that his efforts have finally paid off.",
              "hi": "कुलदीप और बद्री  अविश्वसनीय रूप से माधव पर गर्व करते हैं। उन्होंने उसे इतने वर्षों में कई चुनौतियों का सामना करते देखा है और खुश हैं कि उसके प्रयासों ने अंततः सुखद परिणाम दिया है।",
              "ka": "ಕುಲದೀಪ್ ಮತ್ತು ಬದ್ರಿ ಮಾಧವ್ ಬಗ್ಗೆ ನಂಬಲಾಗದಷ್ಟು ಹೆಮ್ಮೆಪಡುತ್ತಾರೆ. ಅವನು ಹಿಂದಿನ ವರ್ಷಗಳಲ್ಲಿ ಅನೇಕ ಸವಾಲುಗಳನ್ನು ಜಯಿಸಿರುವುದನ್ನು ಅವರು ನೋಡಿದ್ದಾರೆ ಮತ್ತು ಅವನ ಪ್ರಯತ್ನಗಳು ಅಂತಿಮವಾಗಿ ಫಲ ನೀಡಿವೆ ಎಂದು ಸಂತೋಷಪಡುತ್ತಾರೆ.",
              "te": "కుల్దీప్ మరియు బద్రీ మాధవ్ గురించి చాలా గర్వంగా ఉన్నారు. సంవత్సరాలుగా అతను అనేక సవాళ్లను అధిగమించడాన్ని వారు చూశారు మరియు అతని ప్రయత్నాలు ఎట్టకేలకు ఫలించాయని సంతోషిస్తున్నారు.",
              "or": "କୁଲଦୀପ ଏବଂ ବଦ୍ରି ମାଧବଙ୍କ ପାଇଁ ଅତ୍ୟନ୍ତ ଗର୍ବିତ | ସେମାନେ ତାଙ୍କୁ\n  ଅନେକ ବର୍ଷ ମଧ୍ୟରେ ଅନେକ କଠିନତା କୁ ଅତିକ୍ରମ କରିଥିବାର ଦେଖିଛନ୍ତି ଏବଂ ଖୁସି ଯେ ତାଙ୍କ ଉଦ୍ୟମ ଶେଷରେ ସଫଳ ହୋଇଛି |",
              "as": "কুলদীপ আৰু বদ্ৰী মাধৱক লৈ অবিশ্বাস্যভাৱে গৌৰৱান্বিত। তেওঁলোকে তাক বহুবছৰ ধৰি বহুতো প্ৰত্যাহ্বান অতিক্ৰম কৰা দেখিছে আৰু সুখী যে তাৰ প্ৰচেষ্টাত অৱশেষত সফল হৈছে।",
              "gu": "કુલદીપ અને બદ્રીને માધવ પર ગર્વ છે. તેઓએ તેમને વર્ષોથી ઘણા પડકારોમાંથી બહાર નીકળતા જોયા છે અને તેઓ ખુશ છે કે તેમના પ્રયત્નો આખરે ફળ્યા છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_2_3",
              "hi": "A7_2_3",
              "ka": "A7_2_3",
              "te": "A7_2_3",
              "or": "A7_2_3",
              "as": "A7_2_3",
              "gu": "A7_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is too emotional to say anything. After everything he has been through, this moment feels extra special.",
              "hi": "माधव कुछ भी कहने के लिए बहुत भावुक हो रहा है। इतना सब कुछ हो जाने के बाद, यह पल उसको कुछ अधिक ही विशेष लगता है।",
              "ka": "ಮಾಧವ್ ಏನನ್ನೂ ಹೇಳಲಾಗದಷ್ಟು ಭಾವುಕನಾಗಿದ್ದಾನೆ. ಅವನು ಅನುಭವಿಸಿರುವ ಎಲ್ಲದರ ನಂತರ, ಈ ಕ್ಷಣವು ವಿಶೇಷವಾಗಿದೆ.",
              "te": "మాధవ్ ఏమీ మాట్లాడలేనంత ఎమోషనల్ అయ్యాడు. అతను చాలా ఇబ్బందులు అనుభవించాడు కాబట్టి ఈ క్షణం చాలా ప్రత్యేకంగా అనిపిస్తుంది.",
              "or": "ମାଧବ କିଛି କହିବାକୁ ଅତ୍ୟନ୍ତ ଭାବପ୍ରବଣ | ସେ ସବୁକିଛି ଅତିକ୍ରମ କରିବା \n ପରେ, ଏହି ମୁହୂର୍ତ୍ତଟି ଅତିରିକ୍ତ ସ୍ୱତନ୍ତ୍ର ଅନୁଭବ କରେ |",
              "as": "মাধৱে একো কব নোৱাৰাকৈ আৱেগিক হৈ পৰে। সি পাৰ হৈ আহা সকলোবোৰ ঘটনাৰ পিছত, এই মুহূৰ্তটো তাৰ বিশেষ যেন অনুভৱ হয়।",
              "gu": "માધવ કંઈપણ કહેવા માટે ખૂબ જ લાગણીશીલ છે. તે બધું જ પસાર કર્યા પછી, આ ક્ષણ વધારે વિશેષ લાગે છે."
            }
          }
        ],
        "page23": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_2_3b",
              "hi": "A7_2_3B",
              "ka": "A7_2_3b",
              "te": "A7_2_3b",
              "or": "A7_2_3b",
              "as": "A7_2_3b",
              "gu": "A7_2_3b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Soon after the celebrations, Badri brings both of them to a halt.",
              "hi": "उत्सव के तुरंत बाद, बद्री उन दोनों को रोक देता है।",
              "ka": "ಆಚರಣೆಯ ನಂತರ, ಬದ್ರಿ ಇಬ್ಬರನ್ನೂ ನಿಲ್ಲಿಸುತ್ತಾನೆ",
              "te": "వేడుకలు ముగిసిన వెంటనే, బద్రి వారిద్దరినీ ఆపుతాడు.",
              "or": "ଉତ୍ସବ ପରେ ଖୁବ୍ ଶୀଘ୍ର ବଦ୍ରି ଦୁହିଁଙ୍କୁ ଅଟକାଇଥାଏ |",
              "as": "উদযাপনৰ ঠিক পিছতে, বদ্ৰীয়ে তেওঁলোক দুয়োকে ৰ’বলৈ কয়।",
              "gu": "ઉજવણીઓ પછી તરત જ, બદ્રી તે બંનેને અટકાવે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_2_4//",
              "hi": "A7_2_4 //",
              "ka": "A7_2_4//",
              "te": "A7_2_4//",
              "or": "A7_2_4//",
              "as": "A7_2_4//",
              "gu": "A7_2_4//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Badri",
              "hi": "बद्री",
              "ka": "ಬದ್ರಿ",
              "te": "బద్రి",
              "or": "ବଦ୍ରି",
              "as": "বদ্ৰী",
              "gu": "બદ્રી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୁଳଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Ok, enough celebrating, we need to pull ourselves together now.",
              "hi": "ठीक है, उत्सव बहुत  हो गया, अब हमें सारे प्रयत्नों को एक साथ समेटने की जरूरत है",
              "ka": "ಸರಿ, ಸಾಕಷ್ಟು ಆಚರಿಶಿದ್ದೇವೆ, ನಾವು ಈಗ  ಒಟ್ಟಿಗೆ ಯೋಚಿಸಬೇಕಾಗಿದೆ.",
              "te": "సరే, సంబరాలు చేసుకున్నది చాలు, ఇప్పుడు మనం కలిసికట్టుగా ఉండాలి.",
              "or": "ଠିକ ଅଛି, ଯଥେଷ୍ଟ ଉତ୍ସବ ପାଳନ, ଆମକୁ ବର୍ତ୍ତମାନ ନିଜକୁ ଅଟକାଇବା \n  ଆବଶ୍ୟକ |",
              "as": "ঠিক আছে, যথেষ্ট উদযাপন হ’ল, আমি এতিয়া নিজকে একেলগে আগবঢ়াই নিব লাগিব।",
              "gu": "ઠીક છે, પૂરતી ઉજવણી કરવા માટે, આપણે હવે આપણી જાતને એકસાથે બહાર લવવાની જરૂર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Uff Badri, this is not the time to get all serious!",
              "hi": "उफ्फ बद्री, यह सब गंभीर होने का समय नहीं है!",
              "ka": "ಉಫ್ ಬದ್ರಿ, ಇದು ತುಂಬಾ ಗಂಭೀರವಾಗಿರುವ ಸಮಯವಲ್ಲ!",
              "te": "ఉఫ్ బద్రి, ఇది సీరియస్‌గా ఉండవలసిన సమయం కాదు!",
              "or": "ଆରେ ବଦ୍ରି, ସବୁ ଗମ୍ଭୀର ହେବାର ସମୟ ନୁହେଁ!",
              "as": "উফ বদ্ৰী, এইটো আমি গহীন হোৱাৰ সময় নহয়!",
              "gu": "અરે બદ્રી, આ બધા ગંભીર થવાનો સમય નથી!"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_2_5////",
              "hi": "A7_2_5 ////",
              "ka": "A7_2_5////",
              "te": "A7_2_5////",
              "or": "A7_2_5////",
              "as": "A7_2_5////",
              "gu": "A7_2_5////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "No, Kuldeep, Badri is right. There is still the personality test that I need to clear to get into Civil Services.",
              "hi": "नहीं, कुलदीप, बद्री सही है। अभी भी मुझे सिविल सेवाओं में शामिल होने के लिए व्यक्तित्व परीक्षण उत्तीर्ण करने की आवश्यकता है।",
              "ka": "ಇಲ್ಲ, ಕುಲದೀಪ್, ಬದ್ರಿ ಹೇಳಿದ್ದು ಸರಿ. ಸಿವಿಲ್ ಸರ್ವೀಸ್‌ಗೆ ಸೇರಲು ನಾನು ಇನ್ನೂ ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಯಲ್ಲಿ ತೇರ್ಗಡೆಗೊಳ್ಳಬೇಕಾಗಿದೆ.",
              "te": "లేదు కుల్దీప్, బద్రి చెప్పింది నిజమే. సివిల్ సర్వీసెస్‌లో ప్రవేశించడానికి నేను క్లియర్ చేయాల్సిన పర్సనాలిటీ టెస్ట్ ఇంకా ఉంది.",
              "or": "ନା, କୁଲଦୀପ, ବଦ୍ରି ଠିକ୍ କହିଛନ୍ତି | ସିଭିଲ ସର୍ଭିସରେ ପ୍ରବେଶ କରିବାକୁ\n  ମୋତେ ସଫା କରିବାକୁ ଆବଶ୍ୟକ କରୁଥିବା ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷା ଅଛି |",
              "as": "নহয়, কুলদীপ, বদ্ৰীয়ে ঠিকেই কৈছে। এতিয়াও ব্যক্তিত্ব পৰীক্ষা বাকী আছে যিটো মই অসামৰিক সেৱাত প্ৰৱেশ কৰিবলৈ সম্পন্ন কৰিব লাগিব।",
              "gu": "ના, કુલદીપ, બદ્રી સાચું કહે છે. હજુ પણ વ્યક્તિત્વ કસોટી બાકી છે જે મારે સિવિલ સર્વિસમાં પ્રવેશ મેળવવા માટે પાસ કરવી જરૂરી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "That is true. We have to prepare till you are ready for it.",
              "hi": "यह सच है। हमें तब तक तैयारी करनी होगी जब तक आप इसके लिए तैयार हो न जाएँ।",
              "ka": "ಅದು ನಿಜ. ನೀನು ಅದಕ್ಕೆ ಅಣಿಯಾಗುವಂತೆ ಮಾಡಲು ನಾವು ಸಿದ್ಧರಿರಬೇಕು",
              "te": "అది నిజం. నువ్వు రెడీ అయ్యేవరకు మనం బాగా ప్రిపేర్ అవ్వాలి.",
              "or": "ତାହା ସତ୍ୟ ଅଟେ | ତୁମେ ଏହା ପାଇଁ ପ୍ରସ୍ତୁତ ନହେବା ପର୍ଯ୍ୟନ୍ତ ଆମକୁ\n  ପ୍ରସ୍ତୁତ ହେବାକୁ ପଡିବ |",
              "as": "সেইটো সঁচা। তুমি ইয়াৰ বাবে সাজু নোহোৱালৈকে আমি প্ৰস্তুতি চলাব লাগিব।",
              "gu": "તે સાચી વાત છે. તારે તેના માટે તૈયારી ન થાય ત્યાં સુધી આપણે તૈયારી કરવી પડશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "You know what, let’s do a series of mock interviews so that Madhav can practice! I think you need to be more authentic with your answers.",
              "hi": "तुम जानते हो क्या, आओ बहुत सारे  मॉक/ नकली साक्षात्कार करते हैं ताकि माधव अभ्यास कर सके! मुझे लगता है कि तुम्हारे उत्तर और अधिक प्रामाणिक होने चाहिए ",
              "ka": "ನಿನಗೆ ಗೊತ್ತಾ, ಮಾಧವ್ ಅಭ್ಯಾಸ ಮಾಡಲು ಅಣಕು ಸಂದರ್ಶನಗಳ ಸರಣಿಯನ್ನು ಮಾಡೋಣ! ನಿನ್ನ ಉತ್ತರಗಳೊಂದಿಗೆ ನೀನು ಹೆಚ್ಚು ಅಧಿಕೃತವಾಗಿರಬೇಕು ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "మాధవ్ ప్రాక్టీస్ చేయడానికి కొన్ని మాక్ ఇంటర్వ్యూలు చేద్దాం! నువ్వు వాస్తవాల ప్రకారం నీ సమాధానాలు చెప్పాలని నేను భావిస్తున్నాను.",
              "or": "ଆପଣ ଜାଣନ୍ତି କ’ଣ, ଆସନ୍ତୁ ଏକ ମକ୍ ଇଣ୍ଟରଭ୍ୟୁର ଏକ ସିରିଜ୍ କରିବା ଯାହା ଦ୍ଵା\n ରା ମାଧବ ଅଭ୍ୟାସ କରିପାରିବେ! ମୁଁ ଭାବୁଛି ତୁମର ଉତ୍ତର ସହିତ ତୁମେ ଅଧିକ ପ୍ରାମାଣିକ ହେବା ଆବଶ୍ୟକ |",
              "as": "তুমি জানা, মাধৱে যাতে অনুশীলন কৰিব পাৰে তাৰ বাবে কেইবাটাও মক্-সাক্ষাৎকাৰ লওঁ আহা! মই ভাবো তুমি তোমাৰ উত্তৰৰ সৈতে অধিক বাস্তৱবাদী হ'ব লাগিব।",
              "gu": "તમે જાણો છો, ચાલો બનાવટી ઇન્ટરવ્યુની શ્રેણી કરીએ જેથી માધવ પ્રેક્ટિસ કરી શકે! મને લાગે છે કે તારે તારા જવાબો સાથે વધુ પ્રમાણિક બનવાની જરૂર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Why? I think you need to be smart with your answers.",
              "hi": "क्यों? मुझे लगता है कि तुम्हें अपने उत्तर देने में और स्मार्ट होना चाहिए।",
              "ka": "ಏಕೆ? ನಿನ್ನ ಉತ್ತರಗಳನ್ನು ನೀಡುವಲ್ಲಿ ನೀನು ಬುದ್ಧಿವಂತನಾಗಿರಬೇಕು ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "ఎందుకు? నువ్వు సమాధానాలు తెలివిగా చెప్పాలని నేను భావిస్తున్నాను.",
              "or": "କାହିଁକି? ମୁଁ ଭାବୁଛି ତୁମର ଉତ୍ତର ସହିତ ତୁମେ ସ୍ମାର୍ଟ ହେବା ଆବଶ୍ୟକ |",
              "as": "কিয়? মই ভাবো তোমাৰ উত্তৰবোৰ চতুৰ হ'ব লাগিব।",
              "gu": "શા માટે? મને લાગે છે કે તારે તારા જવાબો સાથે સ્માર્ટ બનવાની જરૂર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Oh God, stop! I will practice on my own! I need to go over some mock interview videos and decide for myself.",
              "hi": "हे भगवान, रुको! मैं अपने आप अभ्यास करूंगा! मुझे कुछ नकली साक्षात्कार के वीडियो देखने और खुद के लिए निर्णय लेने की आवश्यकता है।",
              "ka": "ಓ ದೇವರೇ, ನಿಲ್ಲಿಸಿ! ನಾನು ಸ್ವಂತವಾಗಿ ಅಭ್ಯಾಸ ಮಾಡುತ್ತೇನೆ! ನಾನು ಕೆಲವು ಅಣಕು ಸಂದರ್ಶನದ ವೀಡಿಯೊಗಳನ್ನು ನೋಡಬೇಕು ಮತ್ತು ನಾನೇ ನಿರ್ಧರಿಸಬೇಕು.",
              "te": "ఓ దేవుడా, ఆపండి! నేను సొంతంగా ప్రాక్టీస్ చేస్తాను! నేను కొన్ని మాక్ ఇంటర్వ్యూ వీడియోలను చూసి నేనే నిర్ణయించుకుంటాను.",
              "or": "ହେ ଭଗବାନ, ବନ୍ଦ କର! ମୁଁ ନିଜେ ଅଭ୍ୟାସ କରିବି! ମୋତେ କିଛି ମକ୍\n  ଇଣ୍ଟରଭ୍ୟୁ ଭିଡିଓ ଉପରେ ଯାଇ ନିଜ ପାଇଁ ନିଷ୍ପତ୍ତି ନେବା ଆବଶ୍ୟକ |",
              "as": "হে ভগৱান, বন্ধ কৰা! মই নিজে অনুশীলন কৰিম! মই কিছুমান মক্-সাক্ষাৎকাৰৰ ভিডিঅ' চাব লাগিব আৰু নিজাকৈ সিদ্ধান্ত ল'ব লাগিব।",
              "gu": "હે ભગવાન, ઊભા રહો! હું મારી જાતે પ્રેક્ટિસ કરીશ! મારે કેટલાક બનાવટી ઇન્ટરવ્યુ વિડિયોઝ જોવાની અને મારા માટે નિર્ણય લેવાની જરૂર છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_3_1",
              "hi": "A7_3_1",
              "ka": "A7_3_1",
              "te": "A7_3_1",
              "or": "A7_3_1",
              "as": "A7_3_1",
              "gu": "A7_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to watch videos on the internet. He finds out that to perform well in the interview, one needs to be open-minded and consider the bigger picture when answering questions.",
              "hi": "माधव ने इंटरनेट पर वीडियो देखने का निश्चय किया। उसे पता चलता है कि साक्षात्कार में अच्छा प्रदर्शन करने के लिए, किसी को खुले विचारों वाला होना चाहिए और प्रश्नों का उत्तर देते समय बड़ी तस्वीर पर विचार करना चाहिए।",
              "ka": "ಮಾಧವ್ ಇಂಟರ್ನೆಟ್‌ನಲ್ಲಿ ವೀಡಿಯೊಗಳನ್ನು ವೀಕ್ಷಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಸಂದರ್ಶನದಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಮಾಡಲು, ಒಬ್ಬರು ಮುಕ್ತ ಮನಸ್ಸಿನವರಾಗಿರಬೇಕು ಮತ್ತು ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸುವಾಗ ದೊಡ್ಡ ಚಿತ್ರವನ್ನು ಪರಿಗಣಿಸಬೇಕು ಎಂದು ಅವನು ಕಂಡುಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "మాధవ్ ఇంటర్నెట్‌లో వీడియోలు చూడాలని నిర్ణయించుకున్నాడు. ఇంటర్వ్యూలో బాగా రాణించాలంటే ఓపెన్ మైండెడ్‌గా ఉండాలని మరియు ప్రశ్నలకు సమాధానమిచ్చేటప్పుడు అన్ని విషయాలు పరిగణించాలని అతను తెలుసుకున్నాడు.",
              "or": "ଇଣ୍ଟରନେଟରେ ଭିଡିଓ ଦେଖିବା ପାଇଁ ମାଧବ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ସେ ଜାଣିବାକୁ \n ପାଇଲେ ଯେ ସାକ୍ଷାତକାରରେ ଭଲ ପ୍ରଦର୍ଶନ କରିବା ପାଇଁ ଜଣେ ଖୋଲା ମନୋଭାବ ରଖିବା ଆବଶ୍ୟକ ଏବଂ ପ୍ରଶ୍ନର ଉତ୍ତର ଦେବାବେଳେ ବଡ଼ ଚିତ୍ର ବିଷୟରେ ବିଚାର କରିବା ଆବଶ୍ୟକ |",
              "as": "মাধৱে ইণ্টাৰনেটত ভিডিঅ' চোৱাৰ সিদ্ধান্ত লয়। সি জানিব পাৰিলে যে সাক্ষাৎকাৰটোত ভাল প্ৰদৰ্শন কৰিবলৈ হ’লে, প্ৰশ্নৰ উত্তৰ দিওঁতে মুক্ত মনৰ হ'ব লাগিব আৰু ডাঙৰ ছবিখনৰ কথা বিবেচনা কৰিব লাগিব।",
              "gu": "માધવ ઈન્ટરનેટ પર વીડિયો જોવાનું નક્કી કરે છે. તે શોધે છે કે ઇન્ટરવ્યુમાં સારું પ્રદર્શન કરવા માટે, વ્યક્તિએ ખુલ્લા મનની જરૂર હોય છે અને પ્રશ્નોના જવાબ આપતી વખતે મોટા ચિત્રને ધ્યાનમાં લેવું જોઈએ."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_3_2",
              "hi": "A7_3_2",
              "ka": "A7_3_2",
              "te": "A7_3_2",
              "or": "A7_3_2",
              "as": "A7_3_2",
              "gu": "A7_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Badri prepares difficult mock tests for Madhav. The personality test is very important to get the role of preference.",
              "hi": "बद्री माधव के लिए कठिन मॉक/ नकली परीक्षण तैयार करता है। पसंदीदा पद पाने के लिए व्यक्तित्व परीक्षण बहुत महत्वपूर्ण है।",
              "ka": "ಬದ್ರಿ ಮಾಧವ್‌ಗೆ ಕಷ್ಟಕರವಾದ ಅಣಕು ಪರೀಕ್ಷೆಗಳನ್ನು ಸಿದ್ಧಪಡಿಸುತ್ತಾನೆ. ಆದ್ಯತೆಯ ಪಾತ್ರವನ್ನು ಪಡೆಯಲು ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಯು ಬಹಳ ಮುಖ್ಯವಾಗಿದೆ.",
              "te": "బద్రి మాధవ్ కోసం కష్టమైన మాక్ టెస్ట్ లను సిద్ధం చేస్తాడు. ప్రాధాన్యతను పొందడానికి పర్సనాలిటీ టెస్ట్ చాలా ముఖ్యం.",
              "or": "ବଦ୍ରି ମାଧବଙ୍କ ପାଇଁ କଠିନ ମକ୍ ପରୀକ୍ଷା ପ୍ରସ୍ତୁତ କରନ୍ତି | ପସନ୍ଦର ଭୂମିକା\n  ପାଇବା ପାଇଁ ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷା ଅତ୍ୟନ୍ତ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ |",
              "as": "বদ্ৰীয়ে মাধৱৰ বাবে কঠিন মক্-পৰীক্ষা প্ৰস্তুত কৰে। পছন্দৰ ভূমিকা পাবলৈ ব্যক্তিত্ব পৰীক্ষা অতি গুৰুত্বপূৰ্ণ।",
              "gu": "બદ્રી માધવ માટે મુશ્કેલ બનાવટી ટેસ્ટ તૈયાર કરે છે. પસંદગીની ભૂમિકા મેળવવા માટે વ્યક્તિત્વ પરીક્ષણ ખૂબ જ મહત્વપૂર્ણ છે."
            }
          }
        ],
        "page24": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_3_2b",
              "hi": "A7_3_2B",
              "ka": "A7_3_2b",
              "te": "A7_3_2b",
              "or": "A7_3_2b",
              "as": "A7_3_2b",
              "gu": "A7_3_2b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The answers Madhav gives will decide whether he will get selected as a Civil Servant. It will also determine if he gets IAS, IPS, IFS, IRS, or some other position.",
              "hi": "माधव के उत्तर यह तय करेंगे कि क्या वह एक सिविल सेवक के रूप में चुना जाएगा। यह भी निर्धारित करेगा कि क्या वह आईएएस, आईपीएस, आईएफएस, आईआरएस, या कुछ अन्य पद प्राप्त करता है।",
              "ka": "ಮಾಧವ್ ನೀಡುವ ಉತ್ತರಗಳು ಅವನು ಸಿವಿಲ್ ಸರ್ವೆಂಟ್ ಆಗಿ ಆಯ್ಕೆಯಾಗುತ್ತಾನೆಯೇ ಎನ್ನುವುದನ್ನು ನಿರ್ಧರಿಸುತ್ತದೆ. ಅವನು IAS, IPS, IFS, IRS ಅಥವಾ ಇನ್ನಾವುದೇ ಹುದ್ದೆಯನ್ನು ಪಡೆಯುತ್ತಾನೆಯೇ ಎನ್ನುವುದನ್ನು ಸಹ ಇದು ನಿರ್ಧರಿಸುತ್ತದೆ.",
              "te": "మాధవ్ చెప్పే సమాధానాలను బట్టి అతను సివిల్ సర్వెంట్‌గా ఎంపిక అవుతాడో లేదో నిర్ణయించబడుతుంది. అతను IAS, IPS, IFS, IRS లేదా మరేదైనా పదవిని పొందగలడో లేదో కూడా ఇది నిర్ణయిస్తుంది.",
              "or": "ମାଧବ ଦେଇଥିବା ଉତ୍ତର ସ୍ଥିର କରିବ ଯେ ସେ ଜଣେ ସରକାରୀ କର୍ମଚାରୀ \n ଭାବରେ ମନୋନୀତ ହେବେ କି ନାହିଁ | ସେ ଏହା ମଧ୍ୟ ସ୍ଥିର କରିବେ ଯେ ସେ IAS, IPS, IFS, IRS, କିମ୍ବା ଅନ୍ୟାନ୍ୟ ପଦବୀ ପାଇବେ |",
              "as": "মাধৱে দিয়া উত্তৰবোৰে সিদ্ধান্ত ল'ব যে তেওঁ এজন অসামৰিক কৰ্মচাৰী হিচাপে নিৰ্বাচিত হ'ব নে নহয়। ই লগতে নিৰ্ধাৰণ কৰিব যে তেওঁ IAS, IPS, IFS, IRS, বা আন কোনো পদ লাভ কৰে নে নাই।",
              "gu": "માધવ જે જવાબ આપે છે તે નક્કી કરશે કે તેને સિવિલ સર્વન્ટ તરીકે પસંદ કરવામાં આવશે કે નહીં. તે એ પણ નક્કી કરશે કે તેને IAS, IPS, IFS, IRS અથવા અન્ય કોઈ પદ મળે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_4_1",
              "hi": "A7_4_1",
              "ka": "A7_4_1",
              "te": "A7_4_1",
              "or": "A7_4_1",
              "as": "A7_4_1",
              "gu": "A7_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The day finally comes when Madhav has to appear for his personality test. His heart is racing because it is a big day for him.",
              "hi": "अंततः वह दिन आता है जब माधव को अपने व्यक्तित्व परीक्षण के लिए उपस्थित होना पड़ता है। उसकी धड़कनें बढ़ गई हैं क्योंकि यह उसके लिए एक बड़ा दिन है।",
              "ka": "ಕೊನೆಗೂ ಮಾಧವ್ ತನ್ನ ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಗೆ ಹಾಜರಾಗಬೇಕಾದ ದಿನ ಬರುತ್ತದೆ. ಇದು ಅವನಿಗೆ ದೊಡ್ಡ ದಿನವಾದ್ದರಿಂದ ಅವನ ಹೃದಯವು ಜೋರಾಗಿ ಹೊಡೆದುಕೊಳ್ಳುತ್ತಿದೆ.",
              "te": "చివరకు మాధవ్ తన పర్సనాలిటీ టెస్ట్ కు హాజరు కావాల్సిన రోజు వస్తుంది. ఇది అతనికి పెద్ద రోజు కాబట్టి అతని గుండె దడదడలాడుతోంది.",
              "or": "ଶେଷରେ ଦିନ ଆସେ ଯେତେବେଳେ ମାଧବଙ୍କୁ ତାଙ୍କ ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷା \n ପାଇଁ ହାଜର ହେବାକୁ ପଡିବ | ସେ ଅସ୍ଥିର ଜଣାପଡୁଛନ୍ତି କାରଣ ଏହା ତାଙ୍କ ପାଇଁ ଏକ ବଡ ଦିନ |",
              "as": "অৱশেষত সেই দিনটো আহিল যেতিয়া মাধৱে তাৰ ব্যক্তিত্ব পৰীক্ষাত অৱতীৰ্ণ হ'ব। তাৰ বুকুখন ধপধপাই আছে কাৰণ এইটো তাৰ বাবে এটা ডাঙৰ দিন।",
              "gu": "આખરે તે દિવસ આવે છે જ્યારે માધવને તેના વ્યક્તિત્વની કસોટી માટે હાજર થવાનું છે. તેનું હૃદય દોડી રહ્યું છે કારણ કે તે તેના માટે મોટો દિવસ છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_4_2",
              "hi": "A7_4_2",
              "ka": "A7_4_2",
              "te": "A7_4_2",
              "or": "A7_4_2",
              "as": "A7_4_2",
              "gu": "A7_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He enters the interview room. A panel of five officials is waiting.",
              "hi": "वह साक्षात्कार कक्ष में प्रवेश करता है। पांच अधिकारियों का एक पैनल इंतजार कर रहा है।",
              "ka": "ಅವನು ಸಂದರ್ಶನದ ಕೋಣೆಗೆ ಪ್ರವೇಶಿಸುತ್ತಾನೆ. ಐವರು ಅಧಿಕಾರಿಗಳ ಸಮಿತಿಯು ಕಾಯುತ್ತಿದೆ.",
              "te": "అతను ఇంటర్వ్యూ గదిలోకి ప్రవేశిస్తాడు. ఐదుగురు అధికారుల బృందం అక్కడ వేచి ఉంది.",
              "or": "ସେ ସାକ୍ଷାତକାର କକ୍ଷରେ ପ୍ରବେଶ କରନ୍ତି | ପାଞ୍ଚଜଣ ଅଧିକାରୀଙ୍କ ଏକ\n  ପ୍ୟାନେଲ ଅପେକ୍ଷା କରିଛି।",
              "as": "তেওঁ সাক্ষাৎকাৰ কোঠাত প্ৰৱেশ কৰে। পাঁচজন বিষয়াৰ এটা পেনেলে অপেক্ষা কৰি আছে।",
              "gu": "તે ઇન્ટરવ્યુ રૂમમાં પ્રવેશે છે. પાંચ અધિકારીઓની પેનલ રાહ જોઈ રહી છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_4_3//",
              "hi": "A7_4_3 //",
              "ka": "A7_4_3//",
              "te": "A7_4_3//",
              "or": "A7_4_3//",
              "as": "A7_4_3//",
              "gu": "A7_4_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Interviewer 1",
              "hi": "साक्षात्कारकर्ता 1",
              "ka": "ಸಂದರ್ಶನಕಾರ 1",
              "te": "ఇంటర్వ్యూయర్ 1",
              "or": "ସାକ୍ଷାତକାରୀ 1",
              "as": "সাক্ষাৎকাৰ লওতা ১",
              "gu": "ઇન્ટરવ્યુઅર 1"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Interviewer 2",
              "hi": "साक्षात्कारकर्ता 2",
              "ka": "ಸಂದರ್ಶನಕಾರ 2",
              "te": "ఇంటర్వ్యూయర్ 2",
              "or": "ସାକ୍ଷାତକାରୀ ୨",
              "as": "সাক্ষাৎকাৰ লওতা ২",
              "gu": "ઇન્ટરવ્યુઅર 2"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Congratulations on coming this far, Madhav. Can you briefly tell us about yourself before we start with the personality test?",
              "hi": "इतनी दूर तक आने के लिए बधाई, माधव। व्यक्तित्व परीक्षण शुरू होने से पहले क्या आप संक्षेप में हमें अपने बारे में बता सकते हैं?",
              "ka": "ಇಲ್ಲಿಯವರೆಗೆ ಬಂದಿದ್ದಕ್ಕೆ ಅಭಿನಂದನೆಗಳು ಮಾಧವ್. ನಾವು ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಯನ್ನು ಪ್ರಾರಂಭಿಸುವ ಮೊದಲು ನಿಮ್ಮ ಬಗ್ಗೆ ಸಂಕ್ಷಿಪ್ತವಾಗಿ ಹೇಳಬಲ್ಲಿರಾ?",
              "te": "ఇంత దూరం వచ్చినందుకు అభినందనలు మాధవ్. మేము పర్సనాలిటీ టెస్ట్ ను ప్రారంభించే ముందు మీ గురించి క్లుప్తంగా చెప్పగలరా?",
              "or": "ମାଧବ ଏ ପର୍ଯ୍ୟନ୍ତ ଆସିଥିବାରୁ ଅଭିନନ୍ଦନ | ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷା ଆରମ୍ଭ \n କରିବା ପୂର୍ବରୁ ଆପଣ ନିଜ ବିଷୟରେ ସଂକ୍ଷେପରେ ଆମକୁ କହିପାରିବେ କି?",
              "as": "ইমান দূৰলৈ অহাৰ বাবে অভিনন্দন, মাধৱ। আমি ব্যক্তিত্ব পৰীক্ষা আৰম্ভ কৰাৰ আগতে তুমি চমুকৈ তোমাৰ নিজৰ বিষয়ে আমাক ক'ব পাৰিবানে?",
              "gu": "આટલા દૂર આવવા બદલ અભિનંદન, માધવ. વ્યક્તિત્વ કસોટી શરૂ કરતા પહેલા શું તમે અમને તમારા વિશે સંક્ષિપ્તમાં કહી શકશો?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Sure, sir. My name is Madhav, and I am from Kanpur. I am an enthusiastic and determined person. I have a BA degree from Lucknow.",
              "hi": "जरूर सर । मेरा नाम माधव है, और मैं कानपुर से हूं। मैं एक उत्साही और दृढ़ व्यक्ति हूं। मैंने लखनऊ विश्विद्यालय से बीए की डिग्री प्राप्त की है।",
              "ka": "ಖಂಡಿತ, ಸರ್. ನನ್ನ ಹೆಸರು ಮಾಧವ್, ಮತ್ತು ನಾನು ಕಾನ್ಪುರದವನು. ನಾನು ಉತ್ಸಾಹಿ ಮತ್ತು ದೃಢನಿಶ್ಚಯದ ವ್ಯಕ್ತಿ. ನಾನು ಲಕ್ನೋದಿಂದ ಬಿಎ ಪದವಿ ಪಡೆದಿದ್ದೇನೆ",
              "te": "తప్పకుండా సార్. నా పేరు మాధవ్, నేను కాన్పూర్ నుండి వచ్చాను. నేను ఉత్సాహమైన మరియు పట్టుదల కల వ్యక్తిని. నేను లక్నో నుండి BA డిగ్రీ పూర్తి చేశాను.",
              "or": "ନିଶ୍ଚିତ, ସାର୍ | ମୋର ନାମ ମାଧବ, ଏବଂ ମୁଁ କାନପୁରର | ମୁଁ ଜଣେ\n  ଉତ୍ସାହୀ ଏବଂ ସଂକଳ୍ପବଦ୍ଧ ବ୍ୟକ୍ତି | ଲକ୍ଷ୍ନୋ ରୁ ମୋର ବିଏ ଡିଗ୍ରୀ ଅଛି |",
              "as": "নিশ্চয়, ছাৰ। মোৰ নাম মাধৱ, আৰু মই কানপুৰৰ। মই এজন উৎসাহী আৰু দৃঢ়প্ৰতিজ্ঞ ব্যক্তি। মই লক্ষ্ণৌৰ পৰা BA ডিগ্ৰী লৈছোঁ।",
              "gu": "ચોક્કસ, સર. મારું નામ માધવ છે, અને હું કાનપુરનો છું. હું એક ઉત્સાહી અને નિર્ધારિત વ્યક્તિ છું. મારી પાસે લખનૌથી BAની ડિગ્રી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Why do you want to join the Civil Services?",
              "hi": "आप सिविल सेवाओं में शामिल क्यों होना चाहते हैं?",
              "ka": "ನೀವು ನಾಗರಿಕ ಸೇವೆಗಳಿಗೆ ಏಕೆ ಸೇರಲು ಬಯಸುತ್ತೀರಿ?",
              "te": "మీరు సివిల్ సర్వీసెస్‌లో ఎందుకు చేరాలనుకుంటున్నారు?",
              "or": "ଆପଣ ସିଭିଲ ସର୍ଭିସରେ ଯୋଗଦେବାକୁ କାହିଁକି ଚାହୁଁଛନ୍ତି?",
              "as": "তুমি অসামৰিক সেৱাত কিয় যোগদান কৰিব বিচাৰা?",
              "gu": "તમે સિવિલ સર્વિસીસમાં શા માટે જોડાવા માંગો છો?"
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_4_4_singleSelect",
              "hi": "A7_4_4_सिंगलसेलेक्ट ",
              "ka": "A7_4_4_singleSelect",
              "te": "A7_4_4_singleSelect",
              "or": "A7_4_4_singleSelect",
              "as": "A7_4_4_এককনিৰ্বাচিত",
              "gu": "A7_4_4_એક પસંદગી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Help Madhav select an answer:",
              "hi": "माधव को एक उत्तर का चयन करने में सहायता करें:",
              "ka": "ಉತ್ತರವನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಮಾಧವ್ ಅವರಿಗೆ ಸಹಾಯ ಮಾಡಿ:",
              "te": "సమాధానం ఎంచుకోవడంలో మాధవ్‌కి సహాయం చేయండి:",
              "or": "ମାଧବଙ୍କୁ ଏକ ଉତ୍ତର ବାଛିବାରେ ସାହାଯ୍ୟ କରନ୍ତୁ:",
              "as": "মাধৱক উত্তৰ এটা বাছনি কৰাত সহায় কৰা:",
              "gu": "માધવને જવાબ પસંદ કરવામાં મદદ કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.0.display",
            "value": {
              "en": "Sir, I believe that it is a job that involves many challenges, and I feel that it will allow me to utilise all my skills and capabilities fully.",
              "hi": "महोदय, मेरा मानना ​​है कि यह एक ऐसा काम है जिसमें कई चुनौतियां हैं, और मुझे लगता है कि यह  काम मुझे अपने सभी कौशल और क्षमताओं का पूरी तरह से उपयोग करने का अवसर देगा।",
              "ka": "ಸರ್, ಇದು ಅನೇಕ ಸವಾಲುಗಳನ್ನು ಒಳಗೊಂಡಿರುವ ಕೆಲಸ ಎಂದು ನಾನು ನಂಬುತ್ತೇನೆ ಮತ್ತು ಇದು ನನ್ನ ಎಲ್ಲಾ ಕೌಶಲ್ಯ ಮತ್ತು ಸಾಮರ್ಥ್ಯಗಳನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಬಳಸಿಕೊಳ್ಳಲು ಅನುವು ಮಾಡಿಕೊಡುತ್ತದೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "సర్, ఇది చాలా సవాళ్లతో కూడిన ఉద్యోగం అని నేను నమ్ముతున్నాను మరియు ఇది నా నైపుణ్యాలు మరియు సామర్థ్యాలన్నింటినీ పూర్తిగా ఉపయోగించుకునేలా చేస్తుందని నేను భావిస్తున్నాను.",
              "or": "ସାର୍, ମୁଁ ବିଶ୍ଵାସ କରେ ଯେ ଏହା ଏକ କାମ ଯେଉଁଥିରେ ଅନେ\n କ ଚ୍ୟାଲେଞ୍ଜ ଅନ୍ତର୍ଭୁକ୍ତ, ଏବଂ ମୁଁ ଅନୁଭବ କରେ ଯେ ଏହା ମୋର ସମସ୍ତ କୌଶଳ ଏବଂ ସାମର୍ଥ୍ୟକୁ ପୂର୍ଣ୍ଣ ଭାବରେ ବ୍ୟବହାର କରିବାକୁ ଅନୁମତି ଦେବ |",
              "as": "ছাৰ, মই বিশ্বাস কৰোঁ যে এইটো এনেকুৱা এটা কাম য'ত বহুতো প্ৰত্যাহ্বান জড়িত আছে, আৰু মই অনুভৱ কৰোঁ যে ই মোক মোৰ সকলো দক্ষতা আৰু সামৰ্থ্য সম্পূৰ্ণৰূপে ব্যৱহাৰ কৰিবলৈ অনুমতি দিব।",
              "gu": "સર, હું માનું છું કે આ એક એવી નોકરી છે જેમાં ઘણા પડકારો શામેલ છે, અને મને લાગે છે કે તે મને મારી તમામ કુશળતા અને ક્ષમતાઓનો સંપૂર્ણ ઉપયોગ કરવાની પરવાનગી આપે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.1.display",
            "value": {
              "en": "Sir, it is a job that will allow me to work towards other people’s lives, dreams, and aspirations apart from my own.",
              "hi": "महोदय, यह एक ऐसा काम है जो मुझे अपने आप से अलग-अलग लोगों के जीवन, सपनों और आकांक्षाओं की ओर काम करने का अवसर देगा।",
              "ka": "ಸರ್, ಇದು ನನ್ನ ಸ್ವಂತದ್ದು ಹೊರತಾಗಿ ಇತರ ಜನರ ಜೀವನ, ಕನಸುಗಳು ಮತ್ತು ಆಕಾಂಕ್ಷೆಗಳ ಕಡೆಗೆ ಕೆಲಸ ಮಾಡಲು ಅನುವು ಮಾಡಿಕೊಡುವ ಕೆಲಸ.",
              "te": "సర్, ఇది నా స్వంత జీవితం కాకుండా ఇతరుల జీవితాలు, కలలు మరియు ఆకాంక్షల కోసం పని చేయడానికి నాకు వీలు కల్పించే ఉద్యోగం.",
              "or": "ସାର୍, ଏହା ଏକ କାମ ଯାହା ମୋତେ ଅନ୍ୟମାନଙ୍କ ବ୍ୟତୀତ ଅନ୍ୟମାନଙ୍କ\n  ଜୀବନ, ସ୍ୱପ୍ନ ଏବଂ ଆକାଂକ୍ଷା ଦିଗରେ କାର୍ଯ୍ୟ କରିବାକୁ ଅନୁମତି ଦେବ |",
              "as": "ছাৰ, এইটো এনেকুৱা এটা কাম যিয়ে মোক মোৰ নিজৰ বাহিৰেও আনৰ জীৱন, সপোন আৰু আকাংক্ষাৰ দিশত কাম কৰিবলৈ অনুমতি দিব।",
              "gu": "સર, આ એક એવી નોકરી છે જે મને મારા પોતાના સિવાય અન્ય લોકોના જીવન, સપના અને આકાંક્ષાઓ માટે કામ કરવાની મંજૂરી આપશે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_4_5//",
              "hi": "A7_4_5 //",
              "ka": "A7_4_5//",
              "te": "A7_4_5//",
              "or": "A7_4_5//",
              "as": "A7_4_5//",
              "gu": "A7_4_5//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Interviewer 2",
              "hi": "साक्षात्कारकर्ता 2",
              "ka": "ಸಂದರ್ಶನಕಾರ 2",
              "te": "ఇంటర్వ్యూయర్ 2",
              "or": "ସାକ୍ଷାତକାର 2",
              "as": "সাক্ষাৎকাৰ লওতা ২",
              "gu": "ઇન્ટરવ્યુઅર 2"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Hmm, that’s a powerful reason. What will be your priority if you become an IAS officer?",
              "hi": "हम्म, यह एक शक्तिशाली कारण है। यदि आप आईएएस अधिकारी बन जाते हैं तो आपकी प्राथमिकता क्या होगी?",
              "ka": "ಹ್ಮ, ಅದೊಂದು ಪ್ರಬಲ ಕಾರಣ. ನೀವು ಐಎಎಸ್ ಅಧಿಕಾರಿಯಾದರೆ ನಿಮ್ಮ ಆದ್ಯತೆ ಏನು?",
              "te": "అది ఒక శక్తివంతమైన కారణం. మీరు IAS అధికారి అయితే మీ ప్రాధాన్యత ఏమిటి?",
              "or": "ହମ୍, ତାହା ଏକ ଶକ୍ତିଶାଳୀ କାରଣ | ଯଦି ଆପଣ ଜଣେ IAS ଅଧିକାରୀ \n ହୁଅନ୍ତି ତେବେ ଆପଣଙ୍କର ପ୍ରାଥମିକତା କ’ଣ ହେବ?",
              "as": "হুমম, এইটো এটা শক্তিশালী কাৰণ। যদি তুমি এজন IAS বিষয়া হোৱা তেন্তে তোমাৰ প্ৰাথমিকতা কি হ'ব?",
              "gu": "હમ્મ, તે એક જોરદાર કારણ છે. જો તમે IAS ઓફિસર બનશો તો તમારી પ્રાથમિકતા શું હશે?"
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_4_5_singleSelect",
              "hi": "A7_4_5_सिंगलसेलेक्ट ",
              "ka": "A7_4_5_singleSelect",
              "te": "A7_4_5_singleSelect",
              "or": "A7_4_5_singleSelect",
              "as": "A7_4_5_এককনিৰ্বাচিত",
              "gu": "A7_4_5_એક પસંદગી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Help Madhav choose his answer:",
              "hi": "माधव को उसका उत्तर चुनने में सहायता करें:",
              "ka": "ಮಾಧವ್ ನೀಡಬೇಕಾದ ಉತ್ತರವನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಸಹಾಯ ಮಾಡಿ:",
              "te": "మాధవ్ తన సమాధానాన్ని ఎంచుకోవడానికి సహాయం చేయండి:",
              "or": "ମାଧବଙ୍କୁ ତାଙ୍କର ଉତ୍ତର ବାଛିବାରେ ସାହାଯ୍ୟ କରନ୍ତୁ:",
              "as": "মাধৱক তাৰ উত্তৰ বাছনি কৰাত সহায় কৰা:",
              "gu": "માધવને તેનો જવાબ પસંદ કરવામાં મદદ કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.0.display",
            "value": {
              "en": "Sir, I want to improve the public education system of the country.",
              "hi": "महोदय, मैं देश की सार्वजनिक शिक्षा प्रणाली में सुधार करना चाहता हूं।",
              "ka": "ಸರ್, ನಾನು ದೇಶದ ಸಾರ್ವಜನಿಕ ಶಿಕ್ಷಣ ವ್ಯವಸ್ಥೆಯನ್ನು ಸುಧಾರಿಸಲು ಬಯಸುತ್ತೇನೆ.",
              "te": "సార్, నేను దేశంలోని ప్రభుత్వ విద్యా వ్యవస్థను మెరుగుపరచాలనుకుంటున్నాను.",
              "or": "ସାର୍, ମୁଁ ଦେଶର ସର୍ବଶିକ୍ଷା ବ୍ୟବସ୍ଥାରେ ଉନ୍ନତି ଆଣିବାକୁ ଚାହୁଁଛି |",
              "as": "ছাৰ, মই দেশৰ ৰাজহুৱা শিক্ষা ব্যৱস্থা উন্নত কৰিব বিচাৰোঁ।",
              "gu": "સર, હું દેશની જાહેર શિક્ષણ પ્રણાલીમાં સુધારો કરવા માંગુ છું."
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.1.display",
            "value": {
              "en": "Sir, I want to work on improving the economy of the country.",
              "hi": "महोदय, मैं देश की अर्थव्यवस्था में सुधार करने के लिए काम करना चाहता हूं।",
              "ka": "ಸರ್, ನಾನು ದೇಶದ ಆರ್ಥಿಕತೆಯನ್ನು ಸುಧಾರಿಸಲು ಕೆಲಸ ಮಾಡಲು ಬಯಸುತ್ತೇನೆ.",
              "te": "సర్, నేను దేశ ఆర్థిక వ్యవస్థను మెరుగుపరిచేందుకు కృషి చేయాలనుకుంటున్నాను.",
              "or": "ସାର୍, ମୁଁ ଦେଶର ଅର୍ଥନୀତିର ଉନ୍ନତି ପାଇଁ କାର୍ଯ୍ୟ କରିବାକୁ ଚାହୁଁଛି |",
              "as": "ছাৰ, মই দেশৰ অৰ্থনীতিৰ বিকাশৰ বাবে কাম কৰিব বিচাৰোঁ।",
              "gu": "સર, હું દેશની અર્થવ્યવસ્થા સુધારવા માટે કામ કરવા માંગુ છું."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_4_6//",
              "hi": "A7_4_6 //",
              "ka": "A7_4_6//",
              "te": "A7_4_6//",
              "or": "A7_4_6//",
              "as": "A7_4_6//",
              "gu": "A7_4_6//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Interviewer 1",
              "hi": "साक्षात्कारकर्ता 1",
              "ka": "ಸಂದರ್ಶನಕಾರ 1",
              "te": "ఇంటర్వ్యూయర్ 1",
              "or": "ସାକ୍ଷାତକାର 1",
              "as": "সাক্ষাৎকাৰ লওতা ১",
              "gu": "ઇન્ટરવ્યુઅર 1"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Madhav, you seem to be a sensible candidate. But another quality which we look for in our candidates is the presence of mind. So here's a question.\nYou're trying to escape from danger and have two rooms to run into. The first room is full of raging fires, and the second room is full of lions that haven't eaten in 3 years. Which room is safest for you?",
              "hi": "माधव, आप एक समझदार उम्मीदवार प्रतीत होते हैं। लेकिन एक और गुणवत्ता जो हम अपने उम्मीदवारों में देखते हैं वह बुद्धि तत्परता है। तो यहाँ एक सवाल है।\nआप खतरे से बचने की कोशिश कर रहे हैं और आपके पास दो कमरे हैं बचने के लिए। पहले कमरे में प्रचंड आग है, और दूसरा कमरा शेरों से भरा है जिन्होंने 3 साल से कुछ नहीं खाया  है। कौन सा कमरा आपके लिए सबसे सुरक्षित है?",
              "ka": "ಮಾಧವ್, ನೀವು ಸಂವೇದನಾಶೀಲ ಅಭ್ಯರ್ಥಿ ಎಂದು ತೋರುತ್ತಿದೆ. ಆದರೆ ನಮ್ಮ ಅಭ್ಯರ್ಥಿಗಳಲ್ಲಿ ನಾವು ಹುಡುಕುವ ಇನ್ನೊಂದು ಗುಣವೆಂದರೆ ಮನಸ್ಸಿನ ಉಪಸ್ಥಿತಿ. ಹಾಗಾದರೆ ಇಲ್ಲಿ ಒಂದು ಪ್ರಶ್ನೆ ಇದೆ.                                                                                                                                                 ನೀವು ಅಪಾಯದಿಂದ ಪಾರಾಗಲು ಪ್ರಯತ್ನಿಸುತ್ತಿದ್ದೀರಿ ಮತ್ತು ಓಡಲು ಎರಡು ಕೊಠಡಿಗಳನ್ನು ಹೊಂದಿದ್ದೀರಿ. ಮೊದಲ ಕೊಠಡಿಯು ಉರಿಯುತ್ತಿರುವ ಬೆಂಕಿಯಿಂದ ತುಂಬಿದೆ, ಮತ್ತು ಎರಡನೇ ಕೋಣೆಯಲ್ಲಿ 3 ವರ್ಷಗಳಿಂದ ತಿನ್ನದ ಸಿಂಹಗಳು ತುಂಬಿವೆ. ಯಾವ ಕೋಣೆ ನಿಮಗೆ ಸುರಕ್ಷಿತವಾಗಿದೆ?\"",
              "te": "మాధవ్, మీరు తెలివైన అభ్యర్ధిగా కనిపిస్తున్నారు. కానీ మేము అభ్యర్థులలో చూసే మరో గుణం సమయస్పూర్తి. కాబట్టి ఒక ప్రశ్నఅడుగుతాను.\n \n మీరు ప్రమాదం నుండి తప్పించుకోవడానికి ప్రయత్నిస్తున్నారు మరియు పరిగెత్తడానికి రెండు గదులు ఉన్నాయి. మొదటి గది నిండా మంటలు, రెండవ గది నిండా 3 సంవత్సరాలుగా తినని సింహాలు ఉన్నాయి. మీకు ఏ గది సురక్షితమైనది?",
              "or": "ମାଧବ, ତୁମେ ଜଣେ ସମ୍ବେଦନଶୀଳ ପ୍ରାର୍ଥୀ ପରି ମନେ ହେଉଛ। କିନ୍ତୁ ଅନ୍ୟ ଏକ ଗୁଣ ଯାହା ଆମେ ଆମର ପ୍ରାର୍ଥୀମାନଙ୍କ ପାଇଁ ଖୋଜୁଛୁ ତାହା ହେଉଛି ମନର ଉପସ୍ଥିତି। ତେଣୁ ଏଠାରେ ଏକ ପ୍ରଶ୍ନ।\n \n ଆପଣ ବିପଦରୁ ରକ୍ଷା ପାଇବାକୁ ଚେଷ୍ଟା କରୁଛନ୍ତି ଏବଂ ଭିତରକୁ ଯିବାକୁ ଦୁଇଟି କୋଠରୀ ଅଛି | ପ୍ରଥମ କୋଠରୀଟି ପ୍ରଚଣ୍ଡ ଅଗ୍ନିକାଣ୍ଡରେ ପରିପୂର୍ଣ୍ଣ, ଏବଂ ଦ୍ୱିତୀୟ କୋଠରୀଟି ସିଂହରେ ପରିପୂର୍ଣ୍ଣ ଯାହା 3 ବର୍ଷ ମଧ୍ୟରେ ଖାଇ ନାହିଁ | କେଉଁ କୋଠରୀ ଆପଣଙ୍କ ପାଇଁ ସୁରକ୍ଷିତ?",
              "as": "মাধৱ, তোমাক এজন বুদ্ধিমান প্ৰাৰ্থী যেন লাগিছে। কিন্তু আন এটা গুণ যিটো আমি আমাৰ প্ৰাৰ্থীসকলৰ মাজত বিচাৰোঁ সেয়া হ'ল উপস্থিত বুদ্ধি। গতিকে এটা প্ৰশ্ন। তুমি বিপদৰ পৰা পলাবলৈ চেষ্টা কৰি আছা আৰু পলাই যাবলৈ দুটা কোঠা আছে। প্ৰথম কোঠাটো প্ৰচণ্ড জুইৰে ভৰি আছে, আৰু দ্বিতীয় কোঠাটো ৩বছৰে নোখোৱাকৈ থকা সিংহৰে ভৰি আছে। কোনটো কোঠা তোমাৰ বাবে বেছি সুৰক্ষিত?",
              "gu": "માધવ, તું સમજદાર ઉમેદવાર લાગે છે. પરંતુ બીજી ગુણવત્તા જે આપણે આપણા ઉમેદવારોમાં જોઈએ છીએ તે છે મનની હાજરી. તો અહીં એક પ્રશ્ન છે. તમે ભયમાંથી નીકળવાનો પ્રયાસ કરી રહ્યાં છો અને તમારી પાસે દોડવા માટે બે રૂમ છે. પહેલો ઓરડો ભીષણ આગથી ભરેલો છે અને બીજો ઓરડો સિંહોથી ભરેલો છે જેમણે 3 વર્ષમાં ખાધું નથી. તમારા માટે કયો ઓરડો સૌથી સલામત છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Lions that haven't eaten in three years are dead. So that's the safest room.",
              "hi": "तीन साल से जिन्होंने नहीं खाया वे शेर मर चुके हैं। तो यह सबसे सुरक्षित कमरा है।",
              "ka": "ಮೂರು ವರ್ಷಗಳಿಂದ ತಿನ್ನದ ಸಿಂಹಗಳು ಸತ್ತಿವೆ. ಆದ್ದರಿಂದ ಇದು ಸುರಕ್ಷಿತ ಕೋಣೆಯಾಗಿದೆ.",
              "te": "మూడేళ్లుగా తినని సింహాలు చనిపోయి ఉంటాయి. కాబట్టి అది సురక్షితమైన గది.",
              "or": "ତିନି ବର୍ଷ ମଧ୍ୟରେ ସିଂହମାନେ ଖାଇ ନାହାଁନ୍ତି | ତେଣୁ ତାହା ହେଉଛି \n ସବୁଠାରୁ ସୁରକ୍ଷିତ କୋଠରୀ |",
              "as": "তিনি বছৰে নোখোৱাকৈ থকা সিংহবোৰ মৰি গৈছে। গতিকে সেইটো আটাইতকৈ সুৰক্ষিত কোঠা।",
              "gu": "ત્રણ વર્ષથી ખાધું ન હોય તેવા સિંહો મરી ગયા છે. તેથી તે સૌથી સુરક્ષિત ઓરડો છે."
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_4_7//",
              "hi": "A7_4_7 //",
              "ka": "A7_4_7//",
              "te": "A7_4_7//",
              "or": "A7_4_7//",
              "as": "A7_4_7//",
              "gu": "A7_4_7//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Interviewer 3",
              "hi": "साक्षात्कारकर्ता 3",
              "ka": "ಸಂದರ್ಶನಕಾರ 3",
              "te": "ఇంటర్వ్యూయర్ 3",
              "or": "ସାକ୍ଷାତକାର 3",
              "as": "সাক্ষাৎকাৰ লওতা ৩",
              "gu": "ઇન્ટરવ્યુઅર 3"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Interviewer 3",
              "hi": "साक्षात्कारकर्ता 3",
              "ka": "ಸಂದರ್ಶಂಕಾರ 3",
              "te": "ఇంటర్వ్యూయర్ 3",
              "or": "ସାକ୍ଷାତକାର 3",
              "as": "সাক্ষাৎকাৰ লওতা ৩",
              "gu": "ઇન્ટરવ્યુઅર 3"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Haha. Well said. Now for the final question. Listen carefully.",
              "hi": "हाहा। ख़ूब कहा है। अब अंतिम प्रश्न के लिए। ध्यान से सुनो।",
              "ka": "ಹಾಹಾ. ಚೆನ್ನಾಗಿ ಹೇಳಿದಿರಿ. ಈಗ ಅಂತಿಮ ಪ್ರಶ್ನೆಗೆ. ಗಮನವಿಟ್ಟು ಕೇಳಿ.",
              "te": "హాహా. బాగా చెప్పారు. ఇప్పుడు చివరి ప్రశ్న. శ్రద్ధగా వినండి.",
              "or": "ହାହା ଭଲ କହିଲେ। ବର୍ତ୍ତମାନ ଅନ୍ତିମ ପ୍ରଶ୍ନ ପାଇଁ | ଧ୍ୟାନର ସହ ଶୁଣ |",
              "as": "হাহা। ভাল কৈছা। এতিয়া চূড়ান্ত প্ৰশ্ন। মনোযোগেৰে শুনিবা।",
              "gu": "હાહા. સારી રીતે જણાવ્યું હતું કે. હવે અંતિમ પ્રશ્ન માટે. સાવચેતી થી સાંભળો."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Yes Sir.",
              "hi": "जी श्रीमान।",
              "ka": "ಆಗಲಿ ಸರ್",
              "te": "అలాగే సర్.",
              "or": "ଠିକ ଅଛି ଆଜ୍ଞା।",
              "as": "হয় ছাৰ ৷",
              "gu": "હા સાહેબ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "We have launched many satellites into space in the past few decades, and a lot of money is being invested in research in this area by the government. Do you think it makes sense to go with such heavy investment in space technology while we have so much poverty in the country?",
              "hi": "हमने पिछले कुछ दशकों में कई उपग्रहों को अंतरिक्ष में लॉन्च किया है, और सरकार द्वारा इस क्षेत्र में अनुसंधान में बहुत पैसा लगाया जा रहा है। क्या आपको सरकार द्वारा अंतरिक्ष प्रौद्योगिकी में इतना भारी निवेश करना समझ में आता है, जबकि हमारे देश में बहुत अधिक गरीबी है?",
              "ka": "ಕಳೆದ ಕೆಲವು ದಶಕಗಳಲ್ಲಿ ನಾವು ಅನೇಕ ಉಪಗ್ರಹಗಳನ್ನು ಬಾಹ್ಯಾಕಾಶಕ್ಕೆ ಉಡಾಯಿಸಿದ್ದೇವೆ ಮತ್ತು ಸರ್ಕಾರವು ಈ ಪ್ರದೇಶದಲ್ಲಿ ಸಂಶೋಧನೆಗಾಗಿ ಸಾಕಷ್ಟು ಹಣವನ್ನು ಹೂಡಿಕೆ ಮಾಡುತ್ತಿದೆ. ನಾವು ದೇಶದಲ್ಲಿ ತುಂಬಾ ಬಡತನವನ್ನು ಹೊಂದಿರುವಾಗ ಬಾಹ್ಯಾಕಾಶ ತಂತ್ರಜ್ಞಾನದಲ್ಲಿ ಇಂತಹ ಭಾರೀ ಹೂಡಿಕೆಯನ್ನು ಮಾಡುವುದರಲ್ಲಿ ಅರ್ಥವಿದೆ ಎಂದು ನೀವು ಭಾವಿಸುತ್ತೀರಾ?",
              "te": "గత కొన్ని దశాబ్దాలుగా మనము అనేక ఉపగ్రహాలను అంతరిక్షంలోకి ప్రవేశపెట్టాము మరియు ప్రభుత్వం ఈ విషయంలో రీసర్చ్ కోసం చాలా డబ్బు పెట్టుబడి పెట్టారు. దేశంలో ఇంత పేదరికం ఉండగా అంతరిక్ష సాంకేతికతపై ఇంత భారీ పెట్టుబడి పెట్టడం సమంజసమని మీరు అనుకుంటున్నారా?",
              "or": "ଗତ କିଛି ଦଶନ୍ଧି ମଧ୍ୟରେ ଆମେ ମହାକାଶକୁ ଅନେକ ଉପଗ୍ରହ ଉତକ୍ଷେପଣ\n  କରିଛୁ ଏବଂ ସରକାର ଏହି କ୍ଷେତ୍ରରେ ଅନୁସନ୍ଧାନ ପାଇଁ ବହୁ ଅର୍ଥ ବିନିଯୋଗ କରୁଛନ୍ତି। ଦେଶରେ ଏତେ ଦାରିଦ୍ର୍ୟ ଥିବାବେଳେ ମହାକାଶ ପ୍ରଯୁକ୍ତିବିଦ୍ୟାରେ ଏତେ ପରିମାଣର ବିନିଯୋଗ ସହିତ ଯିବା ଅର୍ଥପୂର୍ଣ୍ଣ କି?",
              "as": "আমি যোৱা কেইটামান দশকত মহাকাশলৈ বহুতো উপগ্ৰহ উৎক্ষেপণ কৰিছোঁ, আৰু চৰকাৰে এই ক্ষেত্ৰৰ গৱেষণাত যথেষ্ট ধন বিনিয়োগ কৰি আছে। তুমি ভাবানে যে মহাকাশ প্ৰযুক্তিত ইমান গধুৰ বিনিয়োগ অৰ্থপূৰ্ণ য’ত আমাৰ দেশত ইমান দৰিদ্ৰতা আছে?",
              "gu": "આપણે છેલ્લા કેટલાક દાયકાઓમાં અવકાશમાં ઘણા ઉપગ્રહો છોડયા છે, અને સરકાર દ્વારા આ ક્ષેત્રમાં સંશોધનમાં ઘણાં નાણાંનું રોકાણ કરવામાં આવી રહ્યું છે. શું તમને લાગે છે કે સ્પેસ ટેક્નોલોજીમાં આટલું મોટું રોકાણ કરવું અર્થપૂર્ણ છે જ્યારે આપણા દેશમાં આટલી ગરીબી છે?"
            }
          }
        ],
        "page21": [
          {
            "valuePath": "display",
            "value": {
              "en": "A7_4_7_D",
              "hi": "A7_4_7_D",
              "ka": "A7_4_7_D",
              "te": "A7_4_7_D",
              "or": "A7_4_7_D",
              "as": "A7_4_7_D",
              "gu": "A7_4_7_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Madhav say?",
              "hi": "माधव को क्या कहना चाहिए?",
              "ka": "ಮಾಧವ್ ಏನು ಹೇಳಬೇಕು?",
              "te": "మాధవ్ ఏం చెప్పాలి?",
              "or": "ମାଧବ କ’ଣ କହିବା ଉଚିତ୍?",
              "as": "মাধৱে কি কোৱা উচিত?",
              "gu": "માધવે શું કહેવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Satellites are helpful for people in disaster management, weather forecasting, and communication. Hence, our spending on space missions indirectly benefits the poor man.",
              "hi": "आपदा प्रबंधन, मौसम पूर्वानुमान, और संचार में उपग्रह लोगों के लिए सहायक होते हैं। इसलिए, अंतरिक्ष मिशन पर हमारा खर्च अप्रत्यक्ष रूप से गरीब आदमी को लाभान्वित करता है।",
              "ka": "ಉಪಗ್ರಹಗಳು ವಿಪತ್ತು ನಿರ್ವಹಣೆ, ಹವಾಮಾನ ಮುನ್ಸೂಚನೆ ಮತ್ತು ಸಂವಹನದಲ್ಲಿ ಜನರಿಗೆ ಸಹಾಯಕವಾಗಿವೆ. ಆದ್ದರಿಂದ, ಬಾಹ್ಯಾಕಾಶ ಕಾರ್ಯಾಚರಣೆಗಳಿಗೆ ನಾವು ಖರ್ಚು ಮಾಡುವುದರಿಂದ ಬಡವರಿಗೆ ಪರೋಕ್ಷವಾಗಿ ಪ್ರಯೋಜನವಾಗುತ್ತದೆ.",
              "te": "ఉపగ్రహాలు విపత్తు నిర్వహణ, వాతావరణ అంచనా మరియు కమ్యూనికేషన్‌లో ప్రజలకు సహాయపడతాయి. అందువల్ల, అంతరిక్ష యాత్రలపై మనం చేసే ఖర్చు పేదవాడికి పరోక్షంగా ఉపయోగపడుతుంది.",
              "or": "ବିପର୍ଯ୍ୟୟ ପରିଚାଳନା, ପାଣିପାଗ ପୂର୍ବାନୁମାନ ଏବଂ ଯୋଗାଯୋଗରେ \n ସାଟେଲାଇଟ୍ ସାହାଯ୍ୟକାରୀ | ତେଣୁ, ସ୍ପେସ୍ ମିଶନରେ ଆମର ଖର୍ଚ୍ଚ ପରୋକ୍ଷରେ ଗରିବ ଲୋକ ପାଇଁ ଲାଭଦାୟକ |",
              "as": "উপগ্ৰহবোৰে দুৰ্যোগ ব্যৱস্থাপনা, বতৰৰ পূৰ্বানুমান আৰু যোগাযোগৰ ক্ষেত্ৰত লোকসকলক সহায় কৰে। সেয়েহে, মহাকাশ অভিযানত আমাৰ ব্যয়ে পৰোক্ষভাৱে দৰিদ্ৰ লোকসকলক লাভান্বিত কৰে।",
              "gu": "ઉપગ્રહો લોકો માટે આપત્તિ વ્યવસ્થાપન, હવામાનની આગાહી અને સંદેશાવ્યવહારમાં મદદરૂપ થાય છે. આથી અવકાશ મિશન પરનો આપણો ખર્ચ આડકતરી રીતે ગરીબ માણસને ફાયદો કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Space technology is important for our country’s pride. A country’s progress is not just about the poverty index but the strides it takes in science and technology.",
              "hi": "हमारे देश के गर्व के लिए अंतरिक्ष प्रौद्योगिकी महत्वपूर्ण है। एक देश की प्रगति सिर्फ गरीबी सूचकांक के बारे में नहीं है बल्कि विज्ञान और प्रौद्योगिकी में फैला हुआ है।",
              "ka": "ನಮ್ಮ ದೇಶದ ಹೆಮ್ಮೆಗೆ ಬಾಹ್ಯಾಕಾಶ ತಂತ್ರಜ್ಞಾನ ಮುಖ್ಯವಾಗಿದೆ. ದೇಶದ ಪ್ರಗತಿಯು ಕೇವಲ ಬಡತನ ಸೂಚ್ಯಂಕವಲ್ಲ ಆದರೆ ಅದು ವಿಜ್ಞಾನ ಮತ್ತು ತಂತ್ರಜ್ಞಾನದಲ್ಲಿ ತೆಗೆದುಕೊಳ್ಳುವ ದಾಪುಗಾಲು ಕೂಡ.",
              "te": "అంతరిక్ష సాంకేతికత మన దేశానికి గర్వకారణం. దేశం యొక్క పురోగతి కేవలం పేదరిక సూచీకి సంబంధించినది మాత్రమే కాదు, అది సైన్స్ మరియు టెక్నాలజీలో సాధించిన ప్రగతి కూడా.",
              "or": "ଆମ ଦେଶର ଗର୍ବ ପାଇଁ ସ୍ପେସ୍ ଟେକ୍ନୋଲୋଜି ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ | ଏକ ଦେଶର ଅଗ୍ରଗତି କେବଳ ଦାରିଦ୍ର୍ୟ ସୂଚକାଙ୍କ ଉପରେ ନିର୍ଭର କରେ ନାହିଁ | ଏହା ବିଜ୍ଞାନ ଏବଂ ପ୍ରଯୁକ୍ତିବିଦ୍ୟାରେ ଅଗ୍ରଗତି ଉପରେ ମଧ୍ୟ ନିର୍ଭର କରେ |",
              "as": "মহাকাশ প্ৰযুক্তি আমাৰ দেশৰ গৌৰৱৰ বাবে গুৰুত্বপূৰ্ণ। এখন দেশৰ প্ৰগতি কেৱল দৰিদ্ৰতা সূচকৰ বিষয়েই নহয় বিজ্ঞান আৰু প্ৰযুক্তিৰ ক্ষেত্ৰত লোৱা পদক্ষেপৰ বিষয়ে।",
              "gu": "આપણા દેશના ગૌરવ માટે અવકાશ ટેકનોલોજી મહત્વપૂર્ણ છે. દેશની પ્રગતિ માત્ર ગરીબી અનુક્રમણિકા પર જ નથી પરંતુ તે વિજ્ઞાન અને ટેક્નોલોજીમાં જે આગળ વધવું તે છે."
            }
          }
        ]
      },
      "scene17": {
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "Explore career alternatives",
              "hi": "कैरियर विकल्प का अन्वेषण करे",
              "ka": "ವೃತ್ತಿ ಪರ್ಯಾಯಗಳನ್ನು ಅನ್ವೇಷಿಸಿ",
              "te": "కెరీర్ ప్రత్యామ్నాయాలను అన్వేషించండి",
              "or": "କ୍ୟାରିୟର ବିକଳ୍ପଗୁଡିକ ଏକ୍ସପ୍ଲୋର୍ କରନ୍ତୁ |",
              "as": "জীৱিকাৰ বিকল্পবোৰ অন্বেষণ কৰা",
              "gu": "કારકિર્દીના વિકલ્પોનું અન્વેષણ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to think of alternate career options as he does not want to deal with the uncertainty of Civil Services exams again.",
              "hi": "माधव वैकल्पिक करियर विकल्पों के बारे में सोचने का निश्चय करता है क्योंकि वह फिर से सिविल सेवा परीक्षाओं की अनिश्चितता से निपटना नहीं चाहता  है।",
              "ka": "ಮಾಧವ್  ಸಿವಿಲ್ ಸರ್ವೀಸಸ್ ಪರೀಕ್ಷೆಗಳ ಅನಿಶ್ಚಿತತೆಯನ್ನು ಮತ್ತೊಮ್ಮೆ ಎದುರಿಸಲು ಬಯಸದ ಕಾರಣ ಪರ್ಯಾಯ ವೃತ್ತಿ ಆಯ್ಕೆಗಳ ಬಗ್ಗೆ ಯೋಚಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "సివిల్ సర్వీసెస్ పరీక్షల అనిశ్చితిని మళ్లీ ఎదుర్కోవడం ఇష్టం లేని మాధవ్ ప్రత్యామ్నాయ కెరీర్ ఎంపికల గురించి ఆలోచించాలని నిర్ణయించుకున్నాడు.",
              "or": "ପୁନର୍ବାର ସିଭିଲ ସର୍ଭିସେସ୍ ପରୀକ୍ଷାର ଅନିଶ୍ଚିତତାକୁ ସାମ୍ନା କରିବାକୁ ଚାହୁଁ \n ନ ଥିବାରୁ ବିକଳ୍ପ କ୍ୟାରିୟର ବିକଳ୍ପ ବିଷୟରେ ଚିନ୍ତା କରିବାକୁ ମାଧବ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "মাধৱে বিকল্প জীৱিকাৰ কথা ভাবিবলৈ সিদ্ধান্ত লয় কিয়নো সি পুনৰ অসামৰিক সেৱাৰ পৰীক্ষাত অনিশ্চয়তাৰ সৈতে মোকাবিলা কৰিব নিবিচাৰে।",
              "gu": "માધવ વૈકલ્પિક કારકિર્દી વિકલ્પો વિશે વિચારવાનું નક્કી કરે છે કારણ કે તે ફરીથી સિવિલ સર્વિસ પરીક્ષાઓની અનિશ્ચિતતાનો સામનો કરવા માંગતા નથી."
            }
          }
        ],
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4_1_2",
              "hi": "A4_1_2",
              "ka": "A4_1_2",
              "te": "A4_1_2",
              "or": "A4_1_2",
              "as": "A4_1_2",
              "gu": "A4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Badri suggests a 2 step process to Madhav to help him select an alternate career path. Click to reveal the steps:",
              "hi": "बद्री ने माधव को एक वैकल्पिक कैरियर पथ का चयन करने में सहायता करने के लिए 2 चरण प्रक्रिया का सुझाव दिया। चरणों को प्रकट करने के लिए क्लिक करें:",
              "ka": "ಬದರಿ  ಮಾಧವನಿಗೆ ಪರ್ಯಾಯ ವೃತ್ತಿ ಮಾರ್ಗವನ್ನು ಆಯ್ಕೆ ಮಾಡಲು 2 ಹಂತದ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಸೂಚಿಸುತ್ತಾನೆ. ಹಂತಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "ప్రత్యామ్నాయ కెరీర్ మార్గాన్ని ఎంచుకోవడంలో సహాయపడటానికి బద్రి మాధవ్‌కి 2 దశల ప్రక్రియను సూచించాడు. దశలను రివీల్ చేయడానికి క్లిక్ చేయండి:",
              "or": "ବଦ୍ରି ତାଙ୍କୁ ଏକ ବିକଳ୍ପ କ୍ୟାରିଅର୍ ପଥ ବାଛିବାରେ ସାହାଯ୍ୟ କରିବାକୁ\n  ମାଧବଙ୍କୁ 2 ଷ୍ଟେପ୍ ପ୍ରକ୍ରିୟା ପରାମର୍ଶ ଦେଇଛନ୍ତି | ପଦାଙ୍କଗୁଡିକ ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "বদ্ৰীয়ে মাধৱক বৈকল্পিক জীৱিকাৰ পথ বাছনি কৰাত সহায় কৰিবলৈ এটা 2 টা পদক্ষেপ প্ৰক্ৰিয়াৰ পৰামৰ্শ দিয়ে। পদক্ষেপবোৰ প্ৰকাশ কৰিবলৈ ক্লিক কৰা:",
              "gu": "બદ્રી માધવને વૈકલ્પિક કારકિર્દીનો માર્ગ પસંદ કરવામાં મદદ કરવા માટે 2-રીતોની પ્રક્રિયા સૂચવે છે. રીતો જાહેર કરવા માટે ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Step 1",
              "hi": "चरण 1",
              "ka": "ಹಂತ 1: ",
              "te": "దశ 1",
              "or": "ପଦାଙ୍କ 1",
              "as": "পদক্ষেপ ১",
              "gu": "પગલું 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Step 2",
              "hi": "चरण 2 ",
              "ka": "ಹಂತ 2:",
              "te": "దశ 2",
              "or": "ପଦାଙ୍କ 2",
              "as": "পদক্ষেপ 2",
              "gu": "પગલું 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Take a career test",
              "hi": "करियर परीक्षण करे",
              "ka": "ವೃತ್ತಿ ಪರೀಕ್ಷೆಯನ್ನು ತೆಗೆದುಕೊಳ್ಳುವುದು",
              "te": "కెరీర్ టెస్ట్ తీసుకోండి",
              "or": "କ୍ୟାରିୟର ପରୀକ୍ଷା ନିଅ |",
              "as": "জীৱিকাৰ পৰীক্ষা লোৱা",
              "gu": "કારકિર્દીની પરીક્ષા લો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Perform your own research",
              "hi": "अपना स्वयं की शोध करे",
              "ka": "ನಿಮ್ಮ ಸ್ವಂತ ಸಂಶೋಧನೆ ಮಾಡುವುದು",
              "te": "మీ స్వంత రీసర్చ్ చేయండి",
              "or": "ନିଜର ଅନୁସନ୍ଧାନ କର |",
              "as": "তোমাৰ নিজৰ গৱেষণা কৰা",
              "gu": "તમારું પોતાનું સંશોધન કરો"
            }
          }
        ]
      },
      "scene18": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(i)_1_1",
              "hi": "A4 (i) _1_1",
              "ka": "A4(i)_1_1",
              "te": "A4(i)_1_1",
              "or": "A4(i)_1_1",
              "as": "A4(i)_1_1",
              "gu": "A4(i)_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav takes the career test.",
              "hi": "माधव कैरियर परीक्षण लेता है।",
              "ka": "ಮಾಧವ್ ವೃತ್ತಿ ಪರ್ರೆಕ್ಷೆಯನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾನೆ",
              "te": "మాధవ్ కెరీర్ టెస్ట్ రాస్తాడు.",
              "or": "ମାଧବ କ୍ୟାରିୟର ପରୀକ୍ଷା ଦିଅନ୍ତି |",
              "as": "মাধৱে জীৱিকাৰ পৰীক্ষা ল’লে।",
              "gu": "માધવ કારકિર્દીની પરીક્ષા લે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(i)_1_2",
              "hi": "A4 (i) _1_2",
              "ka": "A4(i)_1_2",
              "te": "A4(i)_1_2",
              "or": "A4(i)_1_2",
              "as": "A4(i)_1_2",
              "gu": "A4(i)_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After finishing the section about himself and his preferences, Madhav starts answering questions related to the jobs and tasks that match his interest.",
              "hi": "अपने और अपनी प्राथमिकताओं के अनुभाग समाप्त करने के बाद, माधव नौकरियों और कार्यों से संबंधित उन सवालों के जवाब देना शुरू कर देता है जो उसकी रुचि से मेल खाते हैं।",
              "ka": "ತನ್ನ ಮತ್ತು ಅವನ ಆದ್ಯತೆಗಳ ವಿಭಾಗವನ್ನು ಮುಗಿಸಿದ ನಂತರ, ಮಾಧವ್ ತನ್ನ ಆಸಕ್ತಿಗೆ ಹೊಂದಿಕೆಯಾಗುವ ಉದ್ಯೋಗಗಳು ಮತ್ತು ಕಾರ್ಯಗಳಿಗೆ ಸಂಬಂಧಿಸಿದ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ.",
              "te": "తన గురించి మరియు అతని ప్రాధాన్యతల గురించిన విభాగాన్ని పూర్తి చేసిన తర్వాత, మాధవ్ తన ఆసక్తికి సరిపోయే ఉద్యోగాలు మరియు పనులకు సంబంధించిన ప్రశ్నలకు సమాధానం ఇవ్వడం ప్రారంభిస్తాడు.",
              "or": "ନିଜ ବିଷୟରେ ଏବଂ ତାଙ୍କ ପସନ୍ଦ ବିଷୟରେ ବିଭାଗ ଶେଷ କରିବା ପରେ,\n  ମାଧବ ଚାକିରି ଏବଂ କାର୍ଯ୍ୟଗୁଡ଼ିକ ସମ୍ବନ୍ଧୀୟ ପ୍ରଶ୍ନର ଉତ୍ତର ଦେବା ଆରମ୍ଭ କରନ୍ତି ଯାହା ତାଙ୍କ ଆଗ୍ରହ ସହିତ ମେଳ ଖାଏ |",
              "as": "নিজৰ আৰু তাৰ পছন্দৰ বিষয়ৰ শাখাটো শেষ কৰাৰ পিছত, মাধৱে তাৰ আগ্ৰহৰ সৈতে মিলা চাকৰি আৰু কামৰ সৈতে সম্পৰ্কিত প্ৰশ্নৰ উত্তৰ দিবলৈ আৰম্ভ কৰে।",
              "gu": "પોતાના અને તેની પસંદગીઓ વિશેનો વિભાગ પૂરો કર્યા પછી, માધવ તેની રુચિ સાથે મેળ ખાતા નોકરીઓ અને કાર્યોને લગતા પ્રશ્નોના જવાબ આપવાનું શરૂ કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(i)_1_3_reveal",
              "hi": "A4 (i) _1_3_रिवील ",
              "ka": "A4(i)_1_3_reveal",
              "te": "A4(i)_1_3_రివీల్",
              "or": "A4(i)_1_3_reveal",
              "as": "A4(i)_1_3_(প্ৰকাশ)",
              "gu": "A4(i)_1_3_બતાવો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal his answers.",
              "hi": "उसके उत्तरों को प्रकट करने के लिए क्लिक करें।",
              "ka": "ಅವನ ಉತ್ತರಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "అతని సమాధానాలను రివీల్ చేయడానికి క్లిక్ చేయండి.",
              "or": "ତାଙ୍କର ଉତ୍ତର ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "তেওঁৰ উত্তৰবোৰ প্ৰকাশ কৰিবলৈ ক্লিক কৰা।",
              "gu": "તેના જવાબો જણાવવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "What kind of jobs do you like?",
              "hi": "आपको किस तरह की नौकरियां पसंद हैं?",
              "ka": "ನೀವು ಯಾವ ರೀತಿಯ ಉದ್ಯೋಗಗಳನ್ನು ಇಷ್ಟಪಡುತ್ತೀರಿ?",
              "te": "మీకు ఎలాంటి ఉద్యోగాలు ఇష్టం?",
              "or": "ଆପଣ କେଉଁ ପ୍ରକାରର କାମ ପସନ୍ଦ କରନ୍ତି?",
              "as": "তুমি কেনে ধৰণৰ চাকৰি ভাল পোৱা?",
              "gu": "તમને કેવા પ્રકારની નોકરીઓ ગમે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "I see myself as someone",
              "hi": "मैं खुद को किसी के रूप में देखता हूं",
              "ka": "ನಾನು ನನ್ನನ್ನು ಒಬ್ಬ ವ್ಯಕ್ತಿಯಂತೆ ನೋಡುತ್ತಾನೆ",
              "te": "నేను ఇలా ఉండాలి అనుకుంటున్నాను",
              "or": "ମୁଁ ନିଜକୁ କେହି ଜଣେ ଦେଖେ |",
              "as": "মই নিজকে কাৰোবাৰ ৰূপত চাওঁ",
              "gu": "હું મારી જાતને કોઈક તરીકે જોવા માગું છું"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "The tasks I enjoy doing are",
              "hi": "मुझे जो काम करने में मज़ा आता है",
              "ka": "ನಾನು ಮಾಡುವ ಹಾಗೂ  ಆನಂದಿಸುವ ಕಾರ್ಯಗಳು",
              "te": "నేను ఇష్టపడి చేసే పనులు",
              "or": "ମୁଁ କରୁଥିବା କାର୍ଯ୍ୟଗୁଡ଼ିକ ହେଉଛି",
              "as": "মই ভালপোৱা কামবোৰ হৈছে",
              "gu": "મને જે કાર્યો કરવામાં આનંદ આવે છે તે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "My skills are",
              "hi": "मेरे कौशल हैं",
              "ka": "ನನ್ನ ಕೌಶಲ್ಯಗಳು",
              "te": "నా నైపుణ్యాలు",
              "or": "ମୋର କୌ ଶଳ ହେଉଛି |",
              "as": "মোৰ দক্ষতাবোৰ হৈছে",
              "gu": "મારી આવડત છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "I like going to the office, research job, working with young people",
              "hi": "मुझे कार्यालय, शोध की नौकरी, युवा लोगों के साथ काम करना पसंद है",
              "ka": "ನಾನು ಕಚೇರಿಗೆ ಹೋಗುವುದು, ಸಂಶೋಧನಾ ಕೆಲಸ, ಯುವಕರೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಇಷ್ಟ ಪಡುತ್ತೇನೆ.",
              "te": "ఆఫీస్‌కి వెళ్లడం, రీసర్చ్ జాబ్ చేయడం, యువతతో కలిసి పనిచేయడం ఇష్టం",
              "or": "ମୁଁ ଅଫିସକୁ ଯିବାକୁ, ଗବେଷଣା କାର୍ଯ୍ୟ, ଯୁବକମାନଙ୍କ ସହିତ କାମ କରିବାକୁ\n  ପସନ୍ଦ କରେ |",
              "as": "মই কাৰ্যালয়লৈ যাবলৈ, গৱেষণাৰ চাকৰি, যুৱ লোকসকলৰ সৈতে কাম কৰি ভাল পাওঁ",
              "gu": "મને ઓફિસ જવાનું, સાંસોધાનની નોકરી, યુવાનો સાથે કામ કરવું ગમે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Fighting for justice, empowering students, writing about critical issues",
              "hi": "न्याय के लिए लड़ना, छात्रों को सशक्त बनाना, महत्वपूर्ण मुद्दों के बारे में लिखना",
              "ka": "ನ್ಯಾಯಕ್ಕಾಗಿ ಹೋರಾಡುವುದು, ವಿದ್ಯಾರ್ಥಿಗಳ ಸಬಲೀಕರಣ, ನಿರ್ಣಾಯಕ ವಿಷಯಗಳ ಬಗ್ಗೆ ಬರೆಯುವುದು",
              "te": "న్యాయం కోసం పోరాడడం, విద్యార్థులను ఎంపవర్ చేయడం, క్లిష్టమైన సమస్యల గురించి రాయడం",
              "or": "ନ୍ୟାୟ ପାଇଁ ସଂଗ୍ରାମ, ଛାତ୍ରମାନଙ୍କୁ ସଶକ୍ତ କରିବା, ଜଟିଳ ବିଷୟଗୁଡ଼ିକ\n  ବିଷୟରେ ଲେଖିବା |",
              "as": "ন্যায়ৰ বাবে যুঁজ দিয়া, শিক্ষাৰ্থীসকলক শক্তিশালী কৰা, গুৰুত্বপূৰ্ণ বিষয়বোৰৰ বিষয়ে লিখা",
              "gu": "ન્યાય માટે લડવું, વિદ્યાર્થીઓને સશક્તિકરણ કરવું, જટિલ મુદ્દાઓ વિશે લખવું"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "Planning, talking to people, reading and researching",
              "hi": "योजना बनाना, लोगों से बात करना, पढ़ना और शोध करना",
              "ka": "ಯೋಜಿಸುವುದು, ಜನರೊಂದಿಗೆ ಮಾತನಾಡುವುದು, ಓದುವುದು ಮತ್ತು ಸಂಶೋಧನೆ ಮಾಡುವುದು",
              "te": "ప్లానింగ్, ప్రజలతో మాట్లాడటం, చదవడం మరియు రీసర్చ్ చేయడం",
              "or": "ଯୋଜନା, ଲୋକଙ୍କ ସହ କଥାବାର୍ତ୍ତା, ପଢିବା ଏବଂ ଅନୁସନ୍ଧାନ |",
              "as": "পৰিকল্পনা কৰা, মানুহৰ সৈতে কথা পতা, পঢ়া আৰু গৱেষণা কৰা",
              "gu": "આયોજન કરવું, લોકો સાથે વાત કરવી, વાંચવું અને સંશોધન કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "Logical reasoning, teaching and explaining, inquiring and writing",
              "hi": "तार्किक तर्क, शिक्षण और व्याख्या, पूछताछ और लेखन",
              "ka": "ತಾರ್ಕಿಕ ಪ್ರತಿಪಾದನೆ, ಬೋಧನೆ ಮತ್ತು ವಿವರಿಸುವುದು, ವಿಚಾರಿಸುವುದು ಮತ್ತು ಬರೆಯುವುದು",
              "te": "లాజికల్ రీజనింగ్, బోధించడం మరియు వివరించడం, విచారించడం మరియు వ్రాయడం",
              "or": "ଯୁକ୍ତିଯୁକ୍ତ ଯୁକ୍ତି, ଶିକ୍ଷାଦାନ ଏବଂ ବ୍ୟାଖ୍ୟା, ଅନୁସନ୍ଧାନ ଏବଂ ଲେଖା |",
              "as": "যৌক্তিক যুক্তি, শিকোৱা আৰু ব্যাখ্যা কৰা, অনুসন্ধান কৰা আৰু লিখা",
              "gu": "તાર્કિક તર્ક, શિક્ષણ અને સમજાવવું, પૂછપરછ અને લેખન"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(i)1_4",
              "hi": "A4 (i) 1_4",
              "ka": "A4(i)1_4",
              "te": "A4(i)1_4",
              "or": "A4(i)1_4",
              "as": "A4(i)1_4",
              "gu": "A4(i)1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Based on the test, he gets a result about his strengths. Madhav is quite happy to see these results. He gets a list of suggestions for his career.",
              "hi": "परीक्षण के आधार पर, वह अपनी क्षमता के बारे में परिणाम प्राप्त करता है। माधव इन परिणामों को देखकर काफी खुश है। उसे अपने करियर के लिए सुझावों की एक सूची मिलती है।",
              "ka": "ಪರೀಕ್ಷೆಯ ಆಧಾರದ ಮೇಲೆ, ಅವನು ತನ್ನ ಸಾಮರ್ಥ್ಯದ ಬಗ್ಗೆ ಫಲಿತಾಂಶವನ್ನು ಪಡೆಯುತ್ತಾನೆ. ಈ ಫಲಿತಾಂಶಗಳನ್ನು ನೋಡಿ ಮಾಧವ್ ತುಂಬಾ ಖುಷಿಯಾಗಿದ್ದಾರೆ. ಅವನು ತಮ್ಮ ವೃತ್ತಿಜೀವನಕ್ಕೆ ಸಲಹೆಗಳ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯುತ್ತಾರೆ.",
              "te": "పరీక్ష ఆధారంగా, అతను తన బలాల గురించి ఫలితాలను పొందుతాడు. ఈ ఫలితాలు చూసి మాధవ్ చాలా సంతోషంగా ఉన్నాడు. అతను తన కెరీర్ గురించి సూచనలు కల లిస్ట్ పొందుతాడు.",
              "or": "ପରୀକ୍ଷା ଉପରେ ଆଧାର କରି, ସେ ନିଜର ଶକ୍ତି ବିଷୟରେ ଏକ ଫଳାଫଳ\n  ପାଆନ୍ତି | ଏହି ଫଳାଫଳ ଦେଖି ମାଧବ ବହୁତ ଖୁସି | ସେ ତାଙ୍କ କ୍ୟାରିୟର ପାଇଁ ପରାମର୍ଶର ଏକ ତାଲିକା ପାଆନ୍ତି |",
              "as": "পৰীক্ষাৰ ওপৰত আধাৰ কৰি, সি তাৰ শক্তিৰ বিষয়ে ফলাফল লাভ কৰে। মাধৱে এই ফলাফলবোৰ দেখি যথেষ্ট সুখী। সি তাৰ জীৱিকাৰ বাবে পৰামৰ্শৰ এখন তালিকা লাভ কৰে।",
              "gu": "પરીક્ષણના આધારે, તેને તેની તાકાત વિશે પરિણામ મળે છે. માધવ આ પરિણામો જોઈને ખૂબ ખુશ છે. તેને તેની કારકિર્દી માટે સૂચનોની યાદી મળે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Career option 1",
              "hi": "करियर विकल्प 1",
              "ka": "ವೃತ್ತಿ ಆಯ್ಕೆ 1",
              "te": "కెరీర్ ఎంపిక 1",
              "or": "ବୃତ୍ତି ବିକଳ୍ପ 1",
              "as": "জীৱিকাৰ বিকল্প ১",
              "gu": "કારકિર્દી વિકલ્પ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Career option 2",
              "hi": "करियर विकल्प 2",
              "ka": "ವೃತ್ತಿ ಆಯ್ಕೆ 2",
              "te": "కెరీర్ ఎంపిక 2",
              "or": "ବୃତ୍ତି ବିକଳ୍ପ 2",
              "as": "জীৱিকাৰ বিকল্প ২",
              "gu": "કારકિર્દી વિકલ્પ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Career option 3",
              "hi": "करियर विकल्प 3",
              "ka": "ವೃತ್ತಿ ಆಯ್ಕೆ 3",
              "te": "కెరీర్ ఎంపిక 3",
              "or": "ବୃତ୍ତି ବିକଳ୍ପ 3",
              "as": "জীৱিকাৰ বিকল্প ৩",
              "gu": "કારકિર્દી વિકલ્પ 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Journalist",
              "hi": "पत्रकार",
              "ka": "ಜರ್ನಲಿಸ್ಟ್",
              "te": "జర్నలిస్ట్",
              "or": "ସାମ୍ବାଦିକ",
              "as": "সাংবাদিক",
              "gu": "પત્રકાર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Lawyer",
              "hi": "वकील",
              "ka": "ಲಾಯರ್",
              "te": "లాయర్",
              "or": "ଓକିଲ",
              "as": "উকীল",
              "gu": "વકીલ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "Teacher",
              "hi": "अध्यापक",
              "ka": "ಟೀಚರ್",
              "te": "టీచర్",
              "or": "ଶିକ୍ଷକ",
              "as": "শিক্ষক",
              "gu": "શિક્ષક"
            }
          }
        ]
      },
      "scene19": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(ii)_1_1",
              "hi": "A4 (II) _1_1",
              "ka": "A4(ii)_1_1",
              "te": "A4(ii)_1_1",
              "or": "A4(ii)_1_1",
              "as": "A4(ii)_1_1",
              "gu": "A4(ii)_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to go to the library to research each of the subjects.",
              "hi": "माधव ने प्रत्येक विषय पर शोध करने के लिए पुस्तकालय जाने का फैसला किया।",
              "ka": "ಮಾಧವ್ ಪ್ರತಿಯೊಂದು ವಿಷಯವನ್ನು ಸಂಶೋಧಿಸಲು ಗ್ರಂಥಾಲಯಕ್ಕೆ ಹೋಗಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్ లైబ్రరీకి వెళ్లి ఒక్కో సబ్జెక్ట్ ని రీసర్చ్ చేయాలని నిర్ణయించుకున్నాడు.",
              "or": "ପ୍ରତ୍ୟେକ ବିଷୟର ଯାଞ୍ଚ କରିବା ପାଇଁ ମାଧବ ପାଠାଗାର କୁ ଯିବାକୁ\n  ନିଷ୍ପତ୍ତି ନିଏ।",
              "as": "মাধৱে প্ৰতিটো বিষয়ৰ গৱেষণা কৰিবলৈ পুথিভঁৰাললৈ যোৱাৰ সিদ্ধান্ত লয়।",
              "gu": "માધવ દરેક વિષય પર સંશોધન કરવા લાઈબ્રેરીમાં જવાનું નક્કી કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(ii)_1_2",
              "hi": "A4 (II) _1_2",
              "ka": "A4(ii)_1_2",
              "te": "A4(ii)_1_2",
              "or": "A4(ii)_1_2",
              "as": "A4(ii)_1_2",
              "gu": "A4(ii)_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is browsing through the different sections to find books about famous individuals in various fields and their work.",
              "hi": "माधव विभिन्न क्षेत्रों और उनके काम में प्रसिद्ध व्यक्तियों के बारे में किताबें खोजने के लिए विभिन्न वर्गों के माध्यम से ब्राउज़ कर रहा है।",
              "ka": "ಮಾಧವ್ ವಿವಿಧ ಕ್ಷೇತ್ರಗಳಲ್ಲಿನ ಪ್ರಸಿದ್ಧ ವ್ಯಕ್ತಿಗಳು ಮತ್ತು ಅವರ ಕೆಲಸದ ಬಗ್ಗೆ ಪುಸ್ತಕಗಳನ್ನು ಹುಡುಕಲು ವಿವಿಧ ವಿಭಾಗಗಳ ಮೂಲಕ ಬ್ರೌಸ್ ಮಾಡುತ್ತಿದ್ದಾನೆ.",
              "te": "మాధవ్ వివిధ రంగాలలో ప్రసిద్ధ వ్యక్తుల గురించి మరియు వారి పని గురించి వివరాలు కల పుస్తకాల కోసం వివిధ విభాగాలను బ్రౌజ్ చేస్తున్నారు.",
              "or": "ବିଭିନ୍ନ କ୍ଷେତ୍ରରେ ପ୍ରସିଦ୍ଧ ବ୍ୟକ୍ତିବିଶେଷ ଏବଂ ସେମାନଙ୍କ କାର୍ଯ୍ୟ ବିଷୟରେ\n  ପୁସ୍ତକ ଖୋଜିବା ପାଇଁ ମାଧବ ବିଭିନ୍ନ ବିଭାଗରେ ବ୍ରାଉଜ୍ କରୁଛନ୍ତି |",
              "as": "মাধৱে বিভিন্ন ক্ষেত্ৰত বিখ্যাত ব্যক্তি আৰু তেওঁলোকৰ কামৰ বিষয়ে কিতাপ বিচাৰি বিভিন্ন শাখাৰ মাজেৰে বিচাৰ-খোচাৰ কৰি আছে।",
              "gu": "માધવ વિવિધ ક્ષેત્રોમાં પ્રખ્યાત વ્યક્તિઓ અને તેમના કાર્ય વિશે પુસ્તકો શોધવા માટે વિવિધ વિભાગો દ્વારા બ્રાઉઝ કરી રહ્યો છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(ii)_1_3_checklist",
              "hi": "A4 (II) _1_3_चेकलिस्ट ",
              "ka": "A4(ii)_1_3_checklist",
              "te": "A4(ii)_1_3_చెక్‌లిస్ట్",
              "or": "A4(ii)_1_3_checklist",
              "as": "A4(ii)_1_3_চেকলিষ্ট",
              "gu": "A4(ii)_1_3_ચકાસણીની યાદી"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click on the names below to know what Madhav finds out.",
              "hi": "यह जानने के लिए नीचे दिए गए नामों पर क्लिक करें कि माधव को क्या पता चलता है।",
              "ka": "ಮಾಧವ್ ಏನನ್ನು ಕಂಡುಕೊಂಡಿದ್ದಾನೆ ಎನ್ನುವುದನ್ನು ತಿಳಿಯಲು ಕೆಳಗಿನ ಹೆಸರುಗಳ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "మాధవ్ ఏమి కనుగొన్నాడో తెలుసుకోవడానికి క్రింది పేర్లపై క్లిక్ చేయండి.",
              "or": "ମାଧବ କ’ଣ ଜାଣନ୍ତି ଜାଣିବା ପାଇଁ ନିମ୍ନରେ ଥିବା ନାମଗୁଡ଼ିକ ଉପରେ\n  କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "মাধৱে কি বিচাৰি পালে জানিবলৈ তলৰ নামবোৰত ক্লিক কৰা।",
              "gu": "માધવને શું જાણવા મળ્યું તે જાણવા માટે નીચેના નામો પર ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Flavia Agnes, Lawyer",
              "hi": "फ्लैविया एग्नेस, वकील",
              "ka": "ಫ್ಲಾವಿಯ ಆಗ್ನೇಸ್, ಲಾಯರ್",
              "te": "ఫ్లావియా ఆగ్నెస్, లాయర్",
              "or": "ଫ୍ଲାଭିଆ ଆଗ୍ନେସ୍, ଓକିଲ |",
              "as": "ফ্লেভিয়া এগ্নেছ, উকীল",
              "gu": "ફ્લાવિયા એગ્નેસ, વકીલ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "P. Sainath, Journalist",
              "hi": "पी साईनाथ, पत्रकार",
              "ka": "ಪಿ. ಸಾಯಿನಾಥ್, ಜರ್ನಲಿಸ್ಟ್",
              "te": "పి. సాయినాథ్, జర్నలిస్ట్",
              "or": "ପି ସାଇନାଥ, ସାମ୍ବାଦିକ |",
              "as": "পি চাইনাথ, সাংবাদিক",
              "gu": "પી. સાઈનાથ, પત્રકાર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Ranjitsinh Disale, Teacher",
              "hi": "रणजीत सिंह डिसेल, टीचर",
              "ka": "ರಂಜಿತ್ ಸಿನ್ಹ್ ದಿಸಾಲೆ, ಟೀಚರ್",
              "te": "రంజిత్‌సిన్హ్ దిసాలే, ఉపాధ్యాయుడు",
              "or": "ରଣଜିତିନ ଡିସଲେ, ଶିକ୍ଷକ |",
              "as": "ৰঞ্জিতসিংহ দিচালে, শিক্ষক",
              "gu": "રણજીતસિંહ ડિસાલે, શિક્ષક"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "Success stories",
              "hi": "सफलता की कहानियां",
              "ka": "ಯಶೋಗಾಥೆಗಳು",
              "te": "విజయ గాథలు",
              "or": "ସଫଳତାର କାହାଣୀ |",
              "as": "সফলতাৰ কাহিনী",
              "gu": "સફળતાની વાર્તાઓ"
            }
          }
        ]
      },
      "scene41": {
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(ii)a_1_1",
              "hi": "A4 (II) A_1_1",
              "ka": "A4(ii)a_1_1",
              "te": "A4(ii)a_1_1",
              "or": "A4(ii)a_1_1",
              "as": "A4(ii)a_1_1",
              "gu": "A4(ii)a_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Flavia Agnes is an Indian women's rights lawyer. Her area of expertise is matrimonial rights and property law.",
              "hi": "फ्लैविया एग्नेस एक भारतीय महिला अधिकार की वकील है। उनका  विशेषज्ञता का  क्षेत्र वैवाहिक अधिकार और संपत्ति कानून है।",
              "ka": "ಫ್ಲಾವಿಯಾ ಆಗ್ನೆಸ್ ಭಾರತೀಯ ಮಹಿಳಾ ಹಕ್ಕುಗಳ ವಕೀಲೆ. ವೈವಾಹಿಕ ಹಕ್ಕುಗಳು ಮತ್ತು ಆಸ್ತಿ ಕಾನೂನು ಆಕೆಯ ಪರಿಣತಿಯ ಕ್ಷೇತ್ರವಾಗಿದೆ.",
              "te": "ఫ్లావియా ఆగ్నెస్ భారతీయ మహిళా హక్కుల లాయర్. ఆమె నైపుణ్యం ఉన్న విషయం వివాహ హక్కులు మరియు ఆస్తి చట్టం.",
              "or": "ଫ୍ଲାଭିଆ ଆଗ୍ନେସ୍ ଜଣେ ଭାରତୀୟ ମହିଳା ଅଧିକାର ଓକିଲ। ତାଙ୍କର \n ପାରଦର୍ଶିତା କ୍ଷେତ୍ର ହେଉଛି ବଇବାହିକ ଅଧିକାର ଏବଂ ସମ୍ପତ୍ତି ଆଇନ |",
              "as": "ফ্লেভিয়া এগ্নেছ এগৰাকী ভাৰতীয় মহিলা অধিকাৰ অধিবক্তা। তেখেতৰ দক্ষতাৰ ক্ষেত্ৰ হৈছে বৈবাহিক অধিকাৰ আৰু সম্পত্তি আইন।",
              "gu": "ફ્લાવિયા એગ્નેસ એક ભારતીય મહિલા અધિકાર વકીલ છે. તેણીની કુશળતાનો વિસ્તાર વૈવાહિક અધિકારો અને મિલકત કાયદો છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(ii)a_1_2_notebook",
              "hi": "A4 (II) A_1_2_नोटबुक ",
              "ka": "A4(ii)a_1_2_notebook",
              "te": "A4(ii)a_1_2_నోట్‌బుక్",
              "or": "A4(ii)a_1_2_notebook",
              "as": "A4(ii)a_1_2_টোকাবহী",
              "gu": "A4(ii)a_1_2_નોટબુક"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to know more about Flavia Agnes.",
              "hi": " फ्लैविया एग्नेस के बारे में और जानने के लिए क्लिक करें।",
              "ka": "ಫ್ಲಾವಿಯಾ ಆಗ್ನೆಸ್ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿಯಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "ఫ్లావియా ఆగ్నెస్ గురించి మరింత తెలుసుకోవడానికి క్లిక్ చేయండి.",
              "or": "ଫ୍ଲାଭିଆ ଆଗ୍ନେସ ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "ফ্লেভিয়া এগ্নেছৰ বিষয়ে অধিক জানিবলৈ ক্লিক কৰা।",
              "gu": "ફ્લાવિયા એગ્નેસ વિશે વધુ જાણવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "About",
              "hi": "विषय में ",
              "ka": "ಬಗ್ಗೆ",
              "te": "గురించి",
              "or": "ବିଷୟରେ",
              "as": "বিষয়ে",
              "gu": "વિશે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Work",
              "hi": "काम",
              "ka": "ಕೆಲಸ",
              "te": "పని",
              "or": "କାମ",
              "as": "কাম",
              "gu": "કામ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Quote",
              "hi": "उद्धरण",
              "ka": "ಉಲ್ಲೇಖ",
              "te": "కోట్",
              "or": "ଉଦ୍ଧୃତ",
              "as": "উদ্ধৃতি",
              "gu": "ભાવ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "Reflection",
              "hi": "प्रतिबिंब",
              "ka": "ಪರಿಣಾಮ",
              "te": "ప్రతిబింబం",
              "or": "ପ୍ରତିଫଳନ",
              "as": "প্ৰতিফলন",
              "gu": "પ્રતિબિંબ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "She has been working in women's rights since the 1980's. She has always been interested in women's economic rights, and her goal was to solve women's inequality within the Indian economic structure.",
              "hi": "वह 1980 के दशक से महिलाओं के अधिकारों पर  काम कर रही है। उन्हें हमेशा महिलाओं के आर्थिक अधिकारों में रुचि रही है, और उनका लक्ष्य भारतीय आर्थिक संरचना के भीतर महिलाओं की असमानता को हल करना था।",
              "ka": "ಅವರು 1980 ರ ದಶಕದಿಂದಲೂ ಮಹಿಳಾ ಹಕ್ಕುಗಳಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾರೆ. ಅವರು ಯಾವಾಗಲೂ ಮಹಿಳೆಯರ ಆರ್ಥಿಕ ಹಕ್ಕುಗಳಲ್ಲಿ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದಾರೆ ಮತ್ತು ಭಾರತೀಯ ಆರ್ಥಿಕ ರಚನೆಯೊಳಗೆ ಮಹಿಳಾ ಅಸಮಾನತೆಯನ್ನು ಪರಿಹರಿಸುವುದು ಅವರ ಗುರಿಯಾಗಿದೆ.",
              "te": "ఆమె 1980ల నుండి మహిళల హక్కుల కోసం పనిచేస్తున్నారు. ఆమె ఎల్లప్పుడూ మహిళల ఆర్థిక హక్కులపై ఆసక్తిని కలిగి ఉండేది మరియు భారత ఆర్థిక వ్యవస్థలో మహిళల అసమానతలను పరిష్కరించడం ఆమె లక్ష్యం.",
              "or": "ସେ ୧୯୮୦ଦଶକରୁ ମହିଳା ଅଧିକାରରେ କାର୍ଯ୍ୟ କରିଆସୁଛନ୍ତି। ସେ\n  ମହିଳାମାନଙ୍କର ଅର୍ଥନଇତିକ ଅଧିକାର ପ୍ରତି ସର୍ବଦା ଆଗ୍ରହୀ ଥିଲେ ଏବଂ ଭାରତୀୟ ଅର୍ଥନଇତିକ ଢାଞ୍ଚା ମହିଳାଙ୍କ ଅସମାନତାକୁ ସମାଧାନ କରିବା ତାଙ୍କର ଲକ୍ଷ୍ୟ ଥିଲା।",
              "as": "তেওঁ ১৯৮০ ৰ দশকৰ পৰা মহিলাৰ অধিকাৰৰ হকে কাম কৰি আছে। তেওঁ সদায় মহিলাৰ অৰ্থনৈতিক অধিকাৰৰ প্ৰতি আগ্ৰহী আছিল, আৰু তেওঁৰ লক্ষ্য আছিল ভাৰতীয় অৰ্থনৈতিক গাঁথনিৰ ভিতৰত মহিলাৰ বৈষম্য সমাধান কৰা।",
              "gu": "તે 1980ના દાયકાથી મહિલા અધિકારો માટે કામ કરી રહી છે. તેણી હંમેશા મહિલાઓના આર્થિક અધિકારોમાં રસ ધરાવે છે, અને તેમનો ધ્યેય ભારતીય આર્થિક માળખામાં મહિલાઓની અસમાનતાને હલ કરવાનો હતો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Flavia Agnes is part of the Global Feminism Project. She also advises the government on law implementation and currently advises the Ministry of Women and Child Development in Maharashtra. \nShe is the co-founder of the organisation MAJLIS, a legal and cultural resource centre that provides legal representation for women issues and rights.",
              "hi": "फ्लैविया एग्नेस वैश्विक नारीवाद प्रोजेक्ट का हिस्सा है। वह सरकार को कानून कार्यान्वयन पर भी सलाह देती है और वर्तमान में महाराष्ट्र में महिला और बाल विकास मंत्रालय को सलाह देती है।\nवह एक कानूनी और सांस्कृतिक संसाधन केंद्र संगठन मजलिस के सह-संस्थापक हैं जो महिलाओं के मुद्दों और अधिकारों के लिए कानूनी प्रतिनिधित्व प्रदान करती हैं।",
              "ka": "ಫ್ಲಾವಿಯಾ ಆಗ್ನೆಸ್ ಗ್ಲೋಬಲ್ ಫೆಮಿನಿಸಂ ಯೋಜನೆಯ ಭಾಗವಾಗಿದೆ. ಅವರು ಕಾನೂನು ಅನುಷ್ಠಾನದ ಬಗ್ಗೆ ಸರ್ಕಾರಕ್ಕೆ ಸಲಹೆ ನೀಡುತ್ತಾರೆ ಮತ್ತು ಪ್ರಸ್ತುತ ಮಹಾರಾಷ್ಟ್ರದ ಮಹಿಳಾ ಮತ್ತು ಮಕ್ಕಳ ಅಭಿವೃದ್ಧಿ ಸಚಿವಾಲಯಕ್ಕೆ ಸಲಹೆ ನೀಡುತ್ತಾರೆ.                                                                                         ಅವರು ಮಹಿಳಾ ಸಮಸ್ಯೆಗಳು ಮತ್ತು ಹಕ್ಕುಗಳಿಗೆ ಕಾನೂನು ಪ್ರಾತಿನಿಧ್ಯವನ್ನು ಒದಗಿಸುವ ಕಾನೂನು ಮತ್ತು ಸಾಂಸ್ಕೃತಿಕ ಸಂಪನ್ಮೂಲ ಕೇಂದ್ರವಾದ MAJLIS ಸಂಸ್ಥೆಯ ಸಹ-ಸಂಸ್ಥಾಪಕರಾಗಿದ್ದಾರೆ.",
              "te": "ఫ్లావియా ఆగ్నెస్ గ్లోబల్ ఫెమినిజం ప్రాజెక్ట్ లో భాగం. ఆమె చట్టాల అమలు విషయంలో ప్రభుత్వానికి సలహాలు ఇస్తుంది మరియు ప్రస్తుతం మహారాష్ట్రలోని మహిళా మరియు శిశు అభివృద్ధి మంత్రిత్వ శాఖకు సలహాలు ఇస్తుంది.\n \n ఆమె MAJLIS అనే సంస్థకు సహ వ్యవస్థాపకురాలు, ఇది మహిళల సమస్యలు మరియు హక్కుల కోసం చట్టపరమైన మద్దతును అందించే చట్టపరమైన మరియు సాంస్కృతిక వనరుల కేంద్రం.",
              "or": "ଫ୍ଲାଭିଆ ଆଗ୍ନେସ୍ ଗ୍ଲୋବାଲ୍ ଫେମିନିଜିମ୍ ପ୍ରକଳ୍ପର ଏକ ଅଂଶ। ସେ ଆଇନ ପ୍ରଣୟନ ଉପରେ ସରକାରଙ୍କୁ ପରାମର୍ଶ ଦେଇଛନ୍ତି ଏବଂ ବର୍ତ୍ତମାନ ମହାରାଷ୍ଟ୍ରରେ ମହିଳା ଏବଂ ଶିଶୁ ବିକାଶ ମନ୍ତ୍ରଣାଳୟକୁ ପରାମର୍ଶ ଦେଇଛନ୍ତି।\n \n ସେ MAJLIS ସଂଗଠନର ସହ-ପ୍ରତିଷ୍ଠାତା, ଏକ ଆଇନଗତ ତଥା ସାଂସ୍କୃତିକ ସମ୍ବଳ କେନ୍ଦ୍ର ଯାହା ମହିଳା ସମସ୍ୟା ଏବଂ ଅଧିକାର ପାଇଁ ଆଇନଗତ ପ୍ରତିନିଧିତ୍ୱ ପ୍ରଦାନ କରିଥାଏ |",
              "as": "ফ্লেভিয়া এগ্নেছ গোলকীয় নাৰীবাদ প্ৰকল্পৰ অংশ। তেওঁ চৰকাৰক আইন ৰূপায়ণৰ বিষয়েও পৰামৰ্শ দিয়ে আৰু বৰ্তমান মহাৰাষ্ট্ৰৰ মহিলা আৰু শিশু বিকাশ মন্ত্ৰালয়ক পৰামৰ্শ দিয়ে। তেওঁ MAJLIS নামৰ সংগঠনটোৰ সহ-প্ৰতিষ্ঠাপক, এক আইনী আৰু সাংস্কৃতিক সম্পদ কেন্দ্ৰ যি মহিলাৰ সমস্যা আৰু অধিকাৰৰ বাবে আইনী প্ৰতিনিধিত্ব প্ৰদান কৰে।",
              "gu": "ફ્લાવિયા એગ્નેસ ગ્લોબલ ફેમિનિઝમ પ્રોજેક્ટનો એક ભાગ છે. તે સરકારને કાયદાના અમલીકરણ અંગે પણ સલાહ આપે છે અને હાલમાં મહારાષ્ટ્રમાં મહિલા અને બાળ વિકાસ મંત્રાલયને સલાહ આપે છે. તે MAJLIS સંસ્થાના સહ-સ્થાપક છે, જે એક કાનૂની અને સાંસ્કૃતિક સંસાધન કેન્દ્ર છે જે મહિલાઓના મુદ્દાઓ અને અધિકારો માટે કાનૂની પ્રતિનિધિત્વ પૂરું પાડે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "*There are laws against bonded labour and against abuse, but these are poor, low-caste workers who are afraid of higher-caste owners; they have to go back to the same fields to work. They have no option.\"*\"",
              "hi": "* बंधुआ श्रम और दुर्व्यवहार के खिलाफ कानून हैं, लेकिन ये गरीब, निचली जाति के कर्मचारी हैं जो उच्च जाति के मालिकों से डरते हैं; उन्हें काम करने के लिए वही खेतों में वापस जाना होगा। उनके पास कोई विकल्प नहीं है। \"*\"",
              "ka": "*ಬಂಧಿತ ಕಾರ್ಮಿಕರ ವಿರುದ್ಧ ಮತ್ತು ದುರುಪಯೋಗದ ವಿರುದ್ಧ ಕಾನೂನುಗಳಿವೆ, ಆದರೆ ಇವರು ಬಡವರು, ಕೆಳಜಾತಿಯ ಕಾರ್ಮಿಕರು, ಅವರು ಉನ್ನತ ಜಾತಿಯ ಮಾಲೀಕರಿಗೆ ಹೆದರುತ್ತಾರೆ; ಅವರು ಕೆಲಸ ಮಾಡಲು ಅದೇ ಹೊಲಗಳಿಗೆ ಹಿಂತಿರುಗಬೇಕು. ಅವರಿಗೆ ಯಾವುದೇ ಆಯ್ಕೆಗಳಿಲ್ಲ.\"*\"",
              "te": "*బాండెడ్ లేబర్‌కి వ్యతిరేకంగా మరియు దుర్వినియోగానికి వ్యతిరేకంగా చట్టాలు ఉన్నాయి, కానీ వీరు పేద, తక్కువ కులాల కార్మికులు, ఉన్నత కులాల యజమానులకు భయపడతారు; వారు పని చేయడానికి మళ్ళీ అదే చోటుకు తిరిగి వెళ్లాలి. వారికి వేరే అవకాశం లేదు.\"*\"",
              "or": "* ବନ୍ଧିତ ଶ୍ରମ ବିରୁଦ୍ଧରେ ଏବଂ ଅପବ୍ୟବହାର ବିରୁଦ୍ଧରେ ନିୟମ ଅଛି, \n କିନ୍ତୁ ଏମାନେ ଗରିବ, ନିମ୍ନ ଜାତିର ଶ୍ରମିକ ଯେଉଁମାନେ ଉଚ୍ଚ ଜାତିର ମାଲିକମାନଙ୍କୁ ଭୟ କରନ୍ତି; ସେମାନଙ୍କୁ କାମ କରିବାକୁ ସମାନ କ୍ଷେତ୍ରକୁ ଫେରିବାକୁ ପଡିବ | ସେମାନଙ୍କର କୌଣସି ବିକଳ୍ପ ନାହିଁ। \"*\"",
              "as": "*বন্ধকী শ্ৰমিকৰ বিৰুদ্ধে আৰু উৎপীড়নৰ বিৰুদ্ধে আইন আছে, কিন্তু এইবোৰ হৈছে দৰিদ্ৰ, নিম্ন বৰ্ণৰ শ্ৰমিক যি উচ্চ বৰ্ণৰ মালিকসকলক ভয় কৰে; তেওঁলোকে কাম কৰিবলৈ একেখন ক্ষেত্ৰলৈ ঘূৰি যাব লাগিব। তেওঁলোকৰ ওচৰত কোনো বিকল্প নাই।\" *\"",
              "gu": "*બંધણી-મજૂર વર્ગ વિરુદ્ધ અને દુરુપયોગ વિરુદ્ધ કાયદાઓ છે, પરંતુ આ ગરીબ, નીચી જાતિના કામદારો છે જેઓ ઉચ્ચ જાતિના માલિકોથી ડરતા હોય છે; તેઓએ કામ કરવા માટે એ જ ક્ષેત્રોમાં પાછા જવું પડશે. તેમની પાસે કોઈ વિકલ્પ નથી.\"*\""
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Madhav feels very inspired by Flavia Agnes's approach in fighting for workers' rights. \nThis also helps him understand his father's struggles throughout his life. He feels there are many things he doesn't know about his father. \nHe feels motivated by Flavia for all she has done, but he feels law would require a lot of dedicated studying, just like UPSC.",
              "hi": "श्रमिकों के अधिकारों के लिए लड़ने में फ्लाविया एग्नेस के दृष्टिकोण से माधव बहुत प्रेरित महसूस करते हैं। इससे उन्हें जीवन भर अपने पिता के संघर्षों को समझने में भी मदद मिलती है। उसे लगता है कि वह अपने पिता के बारे में बहुत सी बातें नहीं जानता है। उसने जो कुछ किया है उसके लिए वह फ्लाविया से प्रेरित महसूस करता है, लेकिन उसे लगता है कि यूपीएससी की तरह कानून को बहुत समर्पित अध्ययन की आवश्यकता होगी।",
              "ka": "ಕಾರ್ಮಿಕರ ಹಕ್ಕುಗಳಿಗಾಗಿ ಹೋರಾಡುವ ಫ್ಲಾವಿಯಾ ಆಗ್ನೆಸ್ ಅವರ ವಿಧಾನದಿಂದ ಮಾಧವ್ ತುಂಬಾ ಸ್ಫೂರ್ತಿ ಪಡೆದಿದ್ದಾನೆ.ಇದು ಅವನ ಜೀವನದುದ್ದಕ್ಕೂ ತನ್ನ ತಂದೆಯ ಹೋರಾಟಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ. ತನ್ನ ತಂದೆಯ ಬಗ್ಗೆ ತನಗೆ ಗೊತ್ತಿಲ್ಲದ ಅನೇಕ ವಿಷಯಗಳಿವೆ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ. ಫ್ಲಾವಿಯಾ ಅವರು ಮಾಡಿದ ಎಲ್ಲದರಿಂದಲೂ ಅವನು ಪ್ರೇರೇಪಿತನಾಗಿದ್ದಾನೆ, ಆದರೆ ಕಾನೂನಿಗೆ ಯುಪಿಎಸ್‌ಸಿಯಂತೆಯೇ ಸಾಕಷ್ಟು ಸಮರ್ಪಕ ಅಧ್ಯಯನದ ಅಗತ್ಯವಿದೆ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "కార్మికుల హక్కుల కోసం పోరాడటంలో ఫ్లావియా ఆగ్నెస్ యొక్క విధానం నుండి మాధవ్ చాలా ప్రేరణ పొందాడు.\n \n ఇది అతని జీవితాంతం తన తండ్రి యొక్క కష్టాలను అర్థం చేసుకోవడానికి కూడా సహాయపడుతుంది. తన తండ్రి గురించి తనకు తెలియని విషయాలు చాలా ఉన్నాయని అతను భావిస్తాడు.\n \n ఫ్లావియా చేసిన పనులను చూసి అతను మోటివేట్ అవుతాడు. మరియు UPSC మాదిరిగానే లా కి కూడా చాలా అంకితభావంతో చదువుకోవాల్సిన అవసరం ఉందని అతను భావిస్తాడు.",
              "or": "ଶ୍ରମିକଙ୍କ ଅଧିକାର ପାଇଁ ଲ fighting ଼ିବାରେ ଫ୍ଲାଭିଆ ଆଗ୍ନେସଙ୍କ ଆଭିମୁଖ୍ୟରେ ମାଧବ ଅତ୍ୟନ୍ତ ଅନୁପ୍ରାଣିତ ଅନୁଭବ କରୁଛନ୍ତି।",
              "as": "মাধৱে শ্ৰমিকসকলৰ অধিকাৰৰ বাবে যুঁজ দিয়া ফ্লেভিয়া এগ্নেছৰ দৃষ্টিভংগীৰ দ্বাৰা বহুত অনুপ্ৰাণিত হয়। এইটোৱে তাক তাৰ দেউতাকৰ গোটেই জীৱনটোৰ সংগ্ৰাম বুজাত সহায় কৰে। সি অনুভৱ কৰে যে তাৰ দেউতাকৰ বিষয়ে সি নজনা বহুতো কথা আছে। সি ফ্লেভিয়াই কৰা সকলোবোৰ কামৰদ্বাৰা অনুপ্ৰাণিত হয়, কিন্তু সি অনুভৱ কৰে যে আইনৰ বাবে UPSCৰ দৰে যথেষ্ট সমৰ্পিত অধ্যয়নৰ প্ৰয়োজন হ'ব।",
              "gu": "માધવને કામદારોના અધિકારો માટે લડવામાં ફ્લાવિયા એગ્નેસના અભિગમથી ખૂબ જ પ્રેરણા મળે છે. આનાથી તેને તેના પિતાના જીવનભરના સંઘર્ષને સમજવામાં પણ મદદ મળે છે. તેને લાગે છે કે ઘણી એવી વસ્તુઓ છે જે તે તેના પિતા વિશે જાણતો નથી. તેણીએ જે કર્યું છે તેના માટે તે ફ્લાવિયા દ્વારા પ્રેરિત અનુભવે છે, પરંતુ તેને લાગે છે કે UPSC ની જેમ કાયદામાં પણ ઘણો સમર્પિત અભ્યાસ જરૂરી છે."
            }
          }
        ]
      },
      "scene42": {
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(ii)b_1_1",
              "hi": "A4 (II) B_1_1",
              "ka": "A4(ii)b_1_1",
              "te": "A4(ii)b_1_1",
              "or": "A4(ii)b_1_1",
              "as": "A4(ii)b_1_1",
              "gu": "A4(ii)b_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Palagummi Sainath is an Indian journalist and author.",
              "hi": "पलागुम्मी साईनाथ एक भारतीय पत्रकार और लेखक हैं।",
              "ka": "ಪಲಗುಮ್ಮಿ ಸಾಯಿನಾಥ್ ಒಬ್ಬ ಭಾರತೀಯ ಪತ್ರಕರ್ತ ಮತ್ತು ಲೇಖಕ.",
              "te": "పాలగుమ్మి సాయినాథ్ ఒక భారతీయ జర్నలిస్ట్ మరియు రచయిత.",
              "or": "ପାଲାଗୁମ୍ମୀ ସାଇନାଥ ଜଣେ ଭାରତୀୟ ସାମ୍ବାଦିକ ତଥା ଲେଖକ।",
              "as": "পালাগুম্মী চাইনাথ এগৰাকী ভাৰতীয় সাংবাদিক আৰু লেখক।",
              "gu": "પલાગુમ્મી સાઈનાથ એક ભારતીય પત્રકાર અને લેખક છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(ii)b_1_2_notebook",
              "hi": "A4 (II) B_1_2_नोटबुक ",
              "ka": "A4(ii)b_1_2_notebook",
              "te": "A4(ii)b_1_2_నోట్‌బుక్",
              "or": "A4 (ii) b_1_2_ ନୋଟବୁକ୍ |",
              "as": "A4(ii)b_1_2_টোকাবহী",
              "gu": "A4(ii)b_1_2_નોટબુક"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to know more about P. Sainath.",
              "hi": "पी. साईनाथ के बारे में और जानने के लिए क्लिक करें।",
              "ka": "ಪಿ. ಸಾಯಿನಾಥ್ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿಯಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "పి. సాయినాథ్ గురించి మరింత తెలుసుకోవడానికి క్లిక్ చేయండి.",
              "or": "P. Sainath ବିଷୟରେ ଅଧିକ ଜାଣିବା ପାଇଁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "পি. চাইনাথৰ বিষয়ে অধিক জানিবলৈ ক্লিক কৰা।",
              "gu": "પી. સાઈનાથ વિશે વધુ જાણવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "About",
              "hi": "विषय में",
              "ka": "ಬಗ್ಗೆ",
              "te": "గురించి",
              "or": "ବିଷୟରେ",
              "as": "বিষয়ে",
              "gu": "વિશે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Work",
              "hi": "काम",
              "ka": "ಕೆಲಸ",
              "te": "పని",
              "or": "କାମ",
              "as": "কাম",
              "gu": "કામ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Quote",
              "hi": "उद्धरण",
              "ka": "ಉಲ್ಲೇಖ",
              "te": "కోట్",
              "or": "ଉଦ୍ଧୃତ",
              "as": "উদ্ধৃতি",
              "gu": "ઉદ્ધત કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "Reflection",
              "hi": "प्रतिबिंब",
              "ka": "ಪರಿಣಾಮ",
              "te": "ప్రతిబింబం",
              "or": "ପ୍ରତିଫଳନ",
              "as": "প্ৰতিফলন",
              "gu": "પ્રતિબિંબ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "P. Sainath started his journalism journey by writing about foreign affairs and then moved to write about the rural affairs of India. He has received several awards in journalism both within and outside the country.",
              "hi": "पी. साईनाथ ने विदेश मामलों के बारे में लिखकर अपनी पत्रकारिता यात्रा शुरू की और फिर भारत के ग्रामीण मामलों के बारे में लिखने के लिए प्रेरित किया। उन्हें देश के भीतर और बाहर पत्रकारिता में कई पुरस्कार प्राप्त हुए हैं।",
              "ka": "ಪಿ.ಸಾಯಿನಾಥ್ ಅವರು ವಿದೇಶಿ ವ್ಯವಹಾರಗಳ ಬಗ್ಗೆ ಬರೆಯುವ ಮೂಲಕ ತಮ್ಮ ಪತ್ರಿಕೋದ್ಯಮ ಪ್ರಯಾಣವನ್ನು ಪ್ರಾರಂಭಿಸಿದರು ಮತ್ತು ನಂತರ ಭಾರತದ ಗ್ರಾಮೀಣ ವ್ಯವಹಾರಗಳ ಬಗ್ಗೆ ಬರೆಯಲು ಮುಂದಾದರು. ಅವರು ದೇಶದ ಒಳಗೆ ಮತ್ತು ಹೊರಗೆ ಪತ್ರಿಕೋದ್ಯಮದಲ್ಲಿ ಹಲವಾರು ಪ್ರಶಸ್ತಿಗಳನ್ನು ಪಡೆದಿದ್ದಾರೆ.",
              "te": "పి.సాయినాథ్ విదేశీ వ్యవహారాల గురించి రాయడం ద్వారా తన జర్నలిజం ప్రయాణాన్ని ప్రారంభించి, ఆపై భారతదేశ గ్రామీణ వ్యవహారాల గురించి రాయడం మొదలుపెట్టాడు. అతను దేశంలో మరియు దేశం బయట జర్నలిజంలో అనేక అవార్డులను అందుకున్నాడు.",
              "or": "ପି ସାଇନାଥ ବଇଦେଶିକ ବ୍ୟାପାର ବିଷୟରେ ଲେଖି ନିଜର ସାମ୍ବାଦିକତା\n  ଯାତ୍ରା ଆରମ୍ଭ କରିଥିଲେ ଏବଂ ତା’ପରେ ଭାରତର ଗ୍ରାମାଞ୍ଚଳ ବିଷୟରେ ଲେଖିବାକୁ ଯାଇଥିଲେ। ସେ ଦେଶ ତଥା ବାହାରେ ସାମ୍ବାଦିକତା କ୍ଷେତ୍ରରେ ଅନେକ ପୁରସ୍କାର ଗ୍ରହଣ କରିଛନ୍ତି |",
              "as": "পি চাইনাথে বৈদেশিক বিষয়ৰ বিষয়ে লিখি সাংবাদিকতাৰ যাত্ৰা আৰম্ভ কৰিছিল আৰু তাৰ পিছত ভাৰতৰ গ্ৰাম্য বিষয়ৰ বিষয়ে লিখিবলৈ আগবাঢ়িছিল। তেওঁ দেশৰ ভিতৰত আৰু বাহিৰত সাংবাদিকতাৰ কেইবাটাও বঁটা লাভ কৰিছে।",
              "gu": "પી. સાઈનાથે તેમની પત્રકારત્વની સફર વિદેશી બાબતો વિશે લખીને શરૂ કરી અને પછી ભારતની ગ્રામીણ બાબતો વિશે લખવા તરફ પ્રયાણ કર્યું. તેમણે દેશની અંદર અને બહાર પત્રકારત્વમાં અનેક પુરસ્કારો મેળવ્યા છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "He founded the People's Archive of Rural India (PARI) in 2014, an online platform that focuses on social and economic inequality, rural affairs, poverty and the aftermath of globalisation in India. He is well known for reporting stories from remote villages of India.",
              "hi": "उन्होंने 2014 में ग्रामीण भारत के लोगों का संग्रह (पार) की स्थापना की, जो की एक ऑनलाइन प्लेटफार्म है जो सामाजिक और आर्थिक असमानता, ग्रामीण मामलों, गरीबी और भारत में वैश्वीकरण का असर जैसे विषयों पर केंद्रित है। वह भारत के दूरदराज के गांवों से कहानियों की रिपोर्टिंग के लिए जाने जाते हैं।",
              "ka": "ಅವರು 2014 ರಲ್ಲಿ ಪೀಪಲ್ಸ್ ಆರ್ಕೈವ್ ಆಫ್ ರೂರಲ್ ಇಂಡಿಯಾ (PARI) ಅನ್ನು ಸ್ಥಾಪಿಸಿದರು, ಇದು ಸಾಮಾಜಿಕ ಮತ್ತು ಆರ್ಥಿಕ ಅಸಮಾನತೆ, ಗ್ರಾಮೀಣ ವ್ಯವಹಾರಗಳು, ಬಡತನ ಮತ್ತು ಭಾರತದಲ್ಲಿ ಜಾಗತೀಕರಣದ ನಂತರದ ಪರಿಣಾಮಗಳ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸುವ ಆನ್‌ಲೈನ್ ವೇದಿಕೆಯಾಗಿದೆ. ಅವರು ಭಾರತದ ದೂರದ ಹಳ್ಳಿಗಳ ಬಗ್ಗೆ ಕಥೆಗಳನ್ನು ವರದಿ ಮಾಡುವುದರಲ್ಲಿ ಹೆಸರುವಾಸಿಯಾಗಿದ್ದಾರೆ.",
              "te": "అతను 2014లో పీపుల్స్ ఆర్చీవ్ ఆఫ్ రూరల్ ఇండియా (PARI)ని స్థాపించాడు, ఇది సామాజిక మరియు ఆర్థిక అసమానతలు, గ్రామీణ వ్యవహారాలు, పేదరికం మరియు భారతదేశంలో ప్రపంచీకరణ తర్వాత పరిణామాలపై దృష్టి సారించే ఆన్‌లైన్ ప్లాట్‌ఫారమ్. అతను భారతదేశంలోని మారుమూల గ్రామాల నుండి కథలను సేకరించడంలో ప్రసిద్ది చెందాడు.",
              "or": "ସେ ୨୦୧୪ ରେ ପିପୁଲ୍ସ ଆର୍କାଇଭ୍ ଅଫ୍ ଗ୍ରାମୀଣ ଇଣ୍ଡିଆ (PARI) \n ପ୍ରତିଷ୍ଠା କରିଥିଲେ, ଯାହା ଏକ ଅନଲାଇନ୍ ପ୍ଲାଟଫର୍ମ ଯାହା ସାମାଜିକ ତଥା ଅର୍ଥନଇତିକ ଅସମାନତା, ଗ୍ରାମୀଣ ବ୍ୟାପାର, ଦାରିଦ୍ର୍ୟ ଏବଂ ଭାରତରେ ଜଗତୀକରଣର ପରବର୍ତ୍ତୀ ଘଟଣା ଉପରେ ଧ୍ୟାନ ଦେଇଥାଏ | ସେ ଭାରତର ଦୁର୍ଗମ ଗାଁରୁ କାହାଣୀ ରିପୋର୍ଟ କରିବା ପାଇଁ ବେଶ୍ ଜଣାଶୁଣା |",
              "as": "তেওঁ ২০১৪ চনত পিপলছ আৰ্কাইভ অৱ ৰুৰেল ইণ্ডিয়া (PARI) প্ৰতিষ্ঠা কৰিছিল, যি হৈছে এক অনলাইন মঞ্চ যিয়ে ভাৰতত সামাজিক আৰু অৰ্থনৈতিক বৈষম্য, গ্ৰাম্য পৰিক্ৰমা, দৰিদ্ৰতা আৰু বিশ্বায়নৰ পাৰিপাৰ্শ্বিকত গুৰুত্ব দিয়ে। তেওঁ ভাৰতৰ দূৰৱৰ্তী গাওঁবোৰৰ কাহিনী প্ৰতিবেদন কৰাৰ বাবে সুপৰিচিত।",
              "gu": "તેમણે 2014 માં પીપલ્સ આર્કાઈવ ઓફ રૂરલ ઈન્ડિયા (PARI) ની સ્થાપના કરી, જે એક ઓનલાઈન પ્લેટફોર્મ છે જે સામાજિક અને આર્થિક અસમાનતા, ગ્રામીણ બાબતો, ગરીબી અને ભારતમાં વૈશ્વિકરણ પછીના પરિણામો પર ધ્યાન કેન્દ્રિત કરે છે. તેઓ ભારતના અંતરિયાળ ગામડાઓની વાર્તાઓનું રિપોર્ટિંગ કરવા માટે જાણીતા છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "*How agonised we are by how people die. How unconcerned we are by how they live.\"*\"",
              "hi": "*लोग कैसे मरते हैं, इससे हम कितने व्यथित हैं। वे कैसे रहते हैं, इससे हम कितने बेपरवाह हैं।\"*\"",
              "ka": "*ಜನರು ಹೇಗೆ ಸಾಯುತ್ತಾರೆ ಎಂದು ನಾವು ಎಷ್ಟು ಸಂಕಟಪಡುತ್ತೇವೆ. ಅವರು ಹೇಗೆ ಬದುಕುತ್ತಾರೆ ಎಂಬುದರ ಬಗ್ಗೆ ನಮಗೆ ಎಳ್ಳಷ್ಟೂ ಕಾಳಜಿಯಿಲ್ಲ.\"*\"",
              "te": "*మనుష్యులు ఎలా చనిపోయారు అని ఎంత వేదనకు గురవుతున్నాము. వారు ఎలా జీవించారు అని మనం ఎంత ఆందోళన చెందాము.\"*\"",
              "or": "* ଲୋକମାନେ କିପରି ମରନ୍ତି ଆମେ କେତେ ଯନ୍ତ୍ରଣା ଅନୁଭବ କରୁ |\n  ସେମାନେ କିପରି ଜୀବନଯାପନ କରୁଛନ୍ତି ତାହା ଦ୍ଵାରା ଆମେ କେତେ ଅବହେଳିତ। \"*\"",
              "as": "*মানুহে কেনেকৈ মৃত্যুবৰণ কৰে তাক লৈ আমি কিমান চিন্তিত। তেওঁলোকে কেনেকৈ জীয়াই আছে তাক লৈ আমি কিমান অসচেতন।\" *\"",
              "gu": "*લોકો કેવી રીતે મૃત્યુ પામે છે તેનાથી આપણે કેટલા વ્યથિત છીએ. તેઓ કેવી રીતે જીવે છે તેનાથી આપણે કેટલા બેફિકર છીએ.\"*\""
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Madhav is surprised by how closely journalists understand society, and it is something he has never considered before. \nTo be a journalist seems like a job of immense responsibility and empathy. Madhav likes the idea of becoming a journalist.",
              "hi": "माधव इस बात से हैरान है कि पत्रकार समाज को कितनी करीब से समझते हैं, और यह ऐसी चीज है जिस पर उसने पहले कभी विचार नहीं किया। एक पत्रकार होना एक बहुत बड़ी जिम्मेदारी और सहानुभूति का काम लगता है। माधव को पत्रकार बनने का विचार पसंद है।",
              "ka": "ಪತ್ರಕರ್ತರು ಸಮಾಜವನ್ನು ಎಷ್ಟು ಹತ್ತಿರದಿಂದ ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ ಎಂದು ಮಾಧವ್ ಆಶ್ಚರ್ಯ ಪಡುತ್ತಾನೆ ಮತ್ತು ಅದು ಅವನು ಹಿಂದೆಂದೂ ಪರಿಗಣಿಸದ ವಿಷಯವಾಗಿದೆ.                                                                                                                                                                       ಪತ್ರಕರ್ತನಾಗುವುದು ಅಪಾರ ಜವಾಬ್ದಾರಿ ಮತ್ತು ಸಹಾನುಭೂತಿಯ ಕೆಲಸದಂತೆ ತೋರುತ್ತದೆ. ಮಾಧವ್ ಪತ್ರಕರ್ತನಾಗುವ ಆಲೋಚನೆಯನ್ನು ಇಷ್ಟಪಡುತ್ತಾನೆ.",
              "te": "“జర్నలిస్టులు సమాజాన్ని ఎంత దగ్గరగా చూస్తారో అని మాధవ్ ఆశ్చర్యపోయాడు మరియు ఇది అతను ఇంతకు ముందెన్నడూ ఆలోచించని విషయం. జర్నలిస్టుగా ఉండటమనేది అపారమైన బాధ్యత మరియు సానుభూతితో కూడిన ఉద్యోగంలా కనిపిస్తుంది. జర్నలిస్టు కావాలనే ఆలోచన మాధవ్‌కి నచ్చింది.\"",
              "or": "ସାମ୍ବାଦିକମାନେ ସମାଜକୁ କେତେ ଘନିଷ୍ଠ ଭାବରେ ବୁଝନ୍ତି ସେଥିପାଇଁ ମାଧବ ଆଶ୍ଚର୍ଯ୍ୟ ହୋଇଯାଇଛନ୍ତି ଏବଂ ଏହା ଏପରି ଏକ ବିଷୟ ଯାହା ସେ ପୂର୍ବରୁ ଭାବି ନଥିଲେ।ସାମ୍ବାଦିକ ହେବା ଅତ୍ୟନ୍ତ ଦାୟିତ୍ and ଏବଂ ସହାନୁଭୂତିର କାମ ପରି ମନେହୁଏ | ସାମ୍ବାଦିକ ହେବାର କଳ୍ପନାକୁ ମାଧବ ପସନ୍ଦ କରନ୍ତି। \"",
              "as": "মাধৱ আচৰিত হ’ল যে সাংবাদিকসকলে সমাজক কিমান নিবিড়ভাৱে বুজি পায়, আৰু এইটো সি আগতে কেতিয়াও বিবেচনা কৰা নাছিল। সাংবাদিক হোৱাটো অপৰিসীম দায়িত্ব আৰু সহানুভূতিৰ কাম যেন লাগে। মাধৱে সাংবাদিক হোৱাৰ ধাৰণাটো ভাল পায়।",
              "gu": "માધવને આશ્ચર્ય થાય છે કે પત્રકારો સમાજને કેટલી નજીકથી સમજે છે, અને તે એવી વસ્તુ છે જે તેણે પહેલાં ક્યારેય વિચાર્યું ન હતું. પત્રકાર બનવું એ અપાર જવાબદારી અને સહાનુભૂતિનું કામ લાગે છે. માધવને પત્રકાર બનવાનો વિચાર ગમે છે."
            }
          }
        ]
      },
      "scene43": {
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(ii)c_1_1",
              "hi": "A4 (II) C_1_1",
              "ka": "A4(ii)c_1_1",
              "te": "A4(ii)c_1_1",
              "or": "A4(ii)c_1_1",
              "as": "A4(ii)c_1_1",
              "gu": "A4(ii)c_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Ranjitsinh Disale is a government school teacher in Maharashtra.",
              "hi": "रणजीत सिंह डिसाले महाराष्ट्र के एक सरकारी स्कूल में शिक्षक हैं।",
              "ka": "ರಂಜಿತ್‌ಸಿನ್ಹ ದಿಸಾಲೆ ಮಹಾರಾಷ್ಟ್ರದ ಸರ್ಕಾರಿ ಶಾಲೆಯ ಶಿಕ್ಷಕ.",
              "te": "రంజిత్‌సిన్హ్ దిసాలే మహారాష్ట్రలోని ప్రభుత్వ పాఠశాల ఉపాధ్యాయుడు.",
              "or": "ରଣଜିତିନ ଡିସଲେ ମହାରାଷ୍ଟ୍ରର ଏକ ସରକାରୀ ବିଦ୍ୟାଳୟର ଶିକ୍ଷକ।",
              "as": "ৰঞ্জিতসিংহ দিচালে মহাৰাষ্ট্ৰৰ এজন চৰকাৰী বিদ্যালয়ৰ শিক্ষক।",
              "gu": "રણજીતસિંહ ડિસાલે મહારાષ્ટ્રમાં સરકારી શાળાના શિક્ષક છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(ii)c_1_1_notebook",
              "hi": "A4 (ii) c_1_1_नोटबुक ",
              "ka": "A4(ii)c_1_1_notebook",
              "te": "A4(ii)c_1_1_నోట్‌బుక్",
              "or": "A4 (ii) c_1_1_ ନୋଟବୁକ୍ |",
              "as": "A4(ii)c_1_1_টোকাবহী",
              "gu": "A4(ii)c_1_1_નોટબુક"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to know more about Ranjitsinh Disale.",
              "hi": "रणजीतसिंह डिसाले के बारे में और जानने के लिए क्लिक करें।",
              "ka": "ರಂಜಿತ್‌ಸಿನ್ಹ್ ದಿಸಾಲೆ ಕುರಿತು ಇನ್ನಷ್ಟು ತಿಳಿಯಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "రంజిత్‌సిన్హ్ దిసాలే గురించి మరింత తెలుసుకోవడానికి క్లిక్ చేయండి.",
              "or": "ରଣଜିତିନ ଡିସଲେ ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "ৰঞ্জিতসিংহ দিচালেৰ বিষয়ে অধিক জানিবলৈ ক্লিক কৰা।",
              "gu": "રણજીતસિંહ ડિસાલે વિશે વધુ જાણવા ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "About",
              "hi": "विषय में",
              "ka": "ಬಗ್ಗೆ",
              "te": "గురించి",
              "or": "ବିଷୟରେ",
              "as": "বিষয়ে",
              "gu": "વિશે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Work",
              "hi": "काम",
              "ka": "ಕೆಲಸ",
              "te": "పని",
              "or": "କାମ କର |",
              "as": "কাম",
              "gu": "કામ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Quote",
              "hi": "उद्धरण",
              "ka": "ಉಲ್ಲೇಖ",
              "te": "కోట్",
              "or": "ଉଦ୍ଧୃତ",
              "as": "উদ্ধৃতি",
              "gu": "ઉદ્ધત કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "Reflections",
              "hi": "कुछ विचार",
              "ka": "ಪರಿಣಾಮ",
              "te": "ప్రతిబింబాలు",
              "or": "ପ୍ରତିଫଳନ",
              "as": "প্ৰতিফলন",
              "gu": "પ્રતિબિંબ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Ranjitsinh Disale is a teacher in a school in the outskirts of Maharashtra. Most of the students in his school are from a tribal community who speak Kannada. \nTo make the textbooks accessible to them, he learned Kannada and translated the 1st to 4th standard textbooks.",
              "hi": "रणजीत सिंह डिसाले महाराष्ट्र के बाहरी इलाके में स्थित एक स्कूल में शिक्षक हैं। उनके स्कूल के अधिकांश छात्र एक जनजातीय समुदाय से हैं जो कन्नड़ बोलते हैं।\nपाठ्यपुस्तकों को उनके लिए सुलभ बनाने के लिए, उन्होंने कन्नड़ भाषा सीखी और 1 से 4थी कक्षा की पाठ्यपुस्तकों का अनुवाद किया।",
              "ka": "“ರಂಜಿತ್‌ಸಿನ್ಹ ದಿಸಾಲೆ ಅವರು ಮಹಾರಾಷ್ಟ್ರದ ಹೊರವಲಯದಲ್ಲಿರುವ ಶಾಲೆಯೊಂದರಲ್ಲಿ ಶಿಕ್ಷಕರಾಗಿದ್ದು, ಅವರ ಶಾಲೆಯ ಬಹುತೇಕ ವಿದ್ಯಾರ್ಥಿಗಳು ಕನ್ನಡ ಮಾತನಾಡುವ ಬುಡಕಟ್ಟು ಸಮುದಾಯದವರಾಗಿದ್ದಾರೆ.                                                                                                                                            ಪಠ್ಯಪುಸ್ತಕಗಳು ಅವರಿಗೆ ತಲುಪುವಂತೆ ಮಾಡಲು, ಅವರು ಕನ್ನಡವನ್ನು ಕಲಿತರು ಮತ್ತು 1 ರಿಂದ 4 ನೇ ತರಗತಿಯ ಪಠ್ಯಪುಸ್ತಕಗಳನ್ನು ಅನುವಾದಿಸಿದರು.",
              "te": "రంజిత్‌సిన్హ్ దిసాలే మహారాష్ట్ర శివార్లలోని ఒక పాఠశాలలో ఉపాధ్యాయుడు. అతని పాఠశాలలో చాలా మంది విద్యార్థులు కన్నడ మాట్లాడే గిరిజన సమాజానికి చెందినవారు.\n \n వారికి పాఠ్యపుస్తకాలు అందుబాటులో ఉండేలా కన్నడ నేర్చుకుని 1 నుంచి 4వ తరగతి పాఠ్యపుస్తకాలను అనువదించారు.",
              "or": "ରଣଜିତିନ ଡିସଲେ ମହାରାଷ୍ଟ୍ର ଉପକଣ୍ଠରେ ଥିବା ଏକ ବିଦ୍ୟାଳୟରେ ଶିକ୍ଷକ ଅଟନ୍ତି। ତାଙ୍କ ବିଦ୍ୟାଳୟର ଅଧିକାଂଶ ଛାତ୍ର କନ୍ନଡ ଭାଷା କହୁଥିବା ଏକ ଆଦିବାସୀ ସମ୍ପ୍ରଦାୟର।\n \n ପାଠ୍ୟ ପୁସ୍ତକଗୁଡ଼ିକ ସେମାନଙ୍କ ପାଇଁ ଉପଲବ୍ଧ କରାଇବା ପାଇଁ ସେ କନ୍ନଡ ଶିକ୍ଷା କରିଥିଲେ ଏବଂ ପ୍ରଥମରୁ 4th ର୍ଥ ମାନକ ପାଠ୍ୟ ପୁସ୍ତକ ଅନୁବାଦ କରିଥିଲେ।",
              "as": "ৰঞ্জিতসিংহ দিচালে মহাৰাষ্ট্ৰৰ উপকণ্ঠৰ এখন বিদ্যালয়ৰ শিক্ষক। তেওঁৰ বিদ্যালয়ৰ বেছিভাগ শিক্ষাৰ্থী জনজাতীয় সম্প্ৰদায়ৰ যিয়ে কানাড়া ভাষা কয়। পাঠ্যপুথিবোৰ তেওঁলোকৰ বাবে উপলব্ধ কৰিবলৈ, তেওঁ কানাড়া শিকিছিল আৰু ১মৰ পৰা ৪ৰ্থ শ্ৰেণীৰ পাঠ্যপুথি অনুবাদ কৰিছিল।",
              "gu": "રણજીતસિંહ ડિસલે મહારાષ્ટ્રની બહારની એક શાળામાં શિક્ષક છે. તેમની શાળાના મોટાભાગના વિદ્યાર્થીઓ કન્નડ બોલતા આદિવાસી સમુદાયના છે. પાઠ્યપુસ્તકો તેમના માટે સુલભ બનાવવા માટે, તેમણે કન્નડ શીખ્યા અને 1 થી 4 ધોરણના પાઠ્યપુસ્તકોનો અનુવાદ કર્યો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "He used QR codes and embedded them with audio poems, stories, and video lectures in Kannada. The process of using QR code was then replicated to all the textbooks in Maharashtra. He won the Global Teacher Prize 2020.",
              "hi": "उन्होंने क्यूआर कोड का इस्तेमाल किया और उन्हें कन्नड़ में ऑडियो कविताओं, कहानियों और वीडियो व्याख्यान के साथ अन्तर्निहित किया। क्यूआर कोड का उपयोग करने की प्रक्रिया को महाराष्ट्र में सभी पाठ्यपुस्तकों में दोहराया गया था। उन्होंने वैश्विक शिक्षक पुरस्कार 2020 जीता।",
              "ka": "ಅವನು QR ಕೋಡ್‌ಗಳನ್ನು ಬಳಸಿದನು ಮತ್ತು ಅವುಗಳನ್ನು ಕನ್ನಡದಲ್ಲಿ ಆಡಿಯೊ ಕವಿತೆಗಳು, ಕಥೆಗಳು ಮತ್ತು ವೀಡಿಯೊ ಉಪನ್ಯಾಸಗಳೊಂದಿಗೆ ಎಂಬೆಡ್ ಮಾಡಿದರು. QR ಕೋಡ್ ಬಳಸುವ ಪ್ರಕ್ರಿಯೆಯನ್ನು ನಂತರ ಮಹಾರಾಷ್ಟ್ರದ ಎಲ್ಲಾ ಪಠ್ಯಪುಸ್ತಕಗಳಿಗೆ ಪುನರಾವರ್ತಿಸಲಾಯಿತು. ಅವರು 2020 ರ ಜಾಗತಿಕ ಶಿಕ್ಷಕರ ಪ್ರಶಸ್ತಿಯನ್ನು ಗೆದ್ದಿದ್ದಾರೆ.",
              "te": "అతను QR కోడ్‌లను ఉపయోగించి వాటిని కన్నడలో ఆడియో పద్యాలు, కథలు మరియు వీడియో ఉపన్యాసాలతో పొందుపరిచాడు. QR కోడ్‌ని ఉపయోగించే ఈ ప్రక్రియ ఆ తర్వాత మహారాష్ట్రలోని అన్ని పాఠ్యపుస్తకాలలో చేయబడింది. అతను 2020 గ్లోబల్ టీచర్ ప్రైజ్ గెలుచుకున్నాడు.",
              "or": "ସେ QR କୋଡ୍ ବ୍ୟବହାର କରି କନ୍ନଡରେ ଅଡିଓ କବିତା, କାହାଣୀ,\n  ଏବଂ ଭିଡିଓ ଲେକ୍ଚର୍ ସହିତ ଏମ୍ବେଡ୍ କରିଥିଲେ | QR କୋଡ୍ ବ୍ୟବହାର କରିବାର ପ୍ରକ୍ରିୟା ପରେ ମହାରାଷ୍ଟ୍ରର ସମସ୍ତ ପାଠ୍ୟପୁସ୍ତକରେ ନକଲ କରାଯାଇଥିଲା | ସେ ଗ୍ଲୋବାଲ୍ ଶିକ୍ଷକ ପୁରସ୍କାର ୨୦୨୦ ଜିତିଥିଲେ |",
              "as": "তেওঁ QR কোড ব্যৱহাৰ কৰিছিল আৰু সেইবোৰক কানাড়াত অডিঅ' কবিতা, কাহিনী আৰু ভিডিঅ' বক্তব্যৰ সৈতে অন্তৰ্ভুক্ত কৰিছিল। QR কোড ব্যৱহাৰ কৰাৰ প্ৰক্ৰিয়াটো তেতিয়া মহাৰাষ্ট্ৰৰ সকলো পাঠ্যপুথিত পুনৰাবৃত্তি কৰা হৈছিল। তেওঁ গ্লোবেল শিক্ষকৰ বঁটা 2020 লাভ কৰিছিল ।",
              "gu": "તેણે QR કોડનો ઉપયોગ કર્યો અને તેને કન્નડમાં ઓડિયો કવિતાઓ, વાર્તાઓ અને વિડિયો પ્રવચનો સાથે એમ્બેડ કર્યું. QR કોડનો ઉપયોગ કરવાની પ્રક્રિયા પછી મહારાષ્ટ્રના તમામ પાઠ્યપુસ્તકોમાં નકલ કરવામાં આવી હતી. તેણે ગ્લોબલ ટીચર પ્રાઈઝ 2020 જીત્યું."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "*Teachers are the real change-makers who are changing the lives of their students with a mixture of chalk and challenges.\"*\"",
              "hi": "* शिक्षक असली परिवर्तन निर्माता हैं जो अपने छात्रों के जीवन को चॉक और चुनौतियों के मिश्रण के साथ बदल रहे हैं। \"*\"",
              "ka": "*ಶಿಕ್ಷಕರು ತಮ್ಮ ವಿದ್ಯಾರ್ಥಿಗಳ ಜೀವನವನ್ನು ಸೀಮೆಸುಣ್ಣ ಮತ್ತು ಸವಾಲುಗಳ ಮಿಶ್ರಣದಿಂದ ಬದಲಾಯಿಸುವ ನಿಜವಾದ ಬದಲಾವಣೆ ಮಾಡುವವರು.\"*\"",
              "te": "*ఉపాధ్యాయులు చాక్ మరియు సవాళ్లతో తమ విద్యార్థుల జీవితాలను మార్చే నిజమైన మార్పు-నిర్మాతలు.*\"",
              "or": "* ଶିକ୍ଷକମାନେ ହେଉଛନ୍ତି ପ୍ରକୃତ ପରିବର୍ତ୍ତନକାରୀ, ଯେଉଁମାନେ ଚକ \n ଏବଂ ଆହ୍ଵାନର ମିଶ୍ରଣ ସହିତ ସେମାନଙ୍କ ଛାତ୍ରମାନଙ୍କ ଜୀବନକୁ ପରିବର୍ତ୍ତନ କରୁଛନ୍ତି | \"*\"",
              "as": "*শিক্ষকসকল হৈছে প্ৰকৃত পৰিৱৰ্তনকাৰী যিয়ে তেওঁলোকৰ শিক্ষাৰ্থীসকলৰ জীৱন চক আৰু প্ৰত্যাহ্বানৰ মিশ্ৰণেৰে সলনি কৰি আছে।\" *\"",
              "gu": "*શિક્ષકો વાસ્તવિક પરિવર્તન લાવનારા છે જેઓ તેમના વિદ્યાર્થીઓના જીવનને ચાક અને પડકારોના મિશ્રણથી બદલી રહ્યા છે.\"*\""
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Madhav is impressed with how  Ranjitsinh ensured students could read the textbooks. He is inspired to see a teacher impact the lives of many students.\nHe realises a teacher's job is full of challenges and responsibilities but in the end, is very fulfilling.",
              "hi": "माधव इस बात से प्रभावित है कि रणजीतसिंह ने कैसे सुनिश्चित किया कि छात्र पाठ्यपुस्तकों को पढ़ सकें। वह एक शिक्षक को कई छात्रों के जीवन को प्रभावित करते हुए देखने के लिए प्रेरित होता है।\nवह महसूस करता है कि एक शिक्षक का काम चुनौतियों और जिम्मेदारियों से भरा होता है लेकिन अंत में, यह बहुत संतोषजनक होता है।",
              "ka": "ವಿದ್ಯಾರ್ಥಿಗಳು ಪಠ್ಯಪುಸ್ತಕಗಳನ್ನು ಓದುವುದನ್ನು ರಂಜಿತ್‌ಸಿನ್ಹ್ ಹೇಗೆ ಖಾತ್ರಿಪಡಿಸಿದರು ಎಂಬುದರ ಬಗ್ಗೆ ಮಾಧವ್ ಪ್ರಭಾವಿತನಾಗಿದ್ದಾನೆ. ಶಿಕ್ಷಕರು ಅನೇಕ ವಿದ್ಯಾರ್ಥಿಗಳ ಜೀವನದ ಮೇಲೆ ಪ್ರಭಾವ ಬೀರುವುದನ್ನು ನೋಡಲು ಅವರು ಸ್ಫೂರ್ತಿ ಪಡೆದಿದ್ದಾರೆ.                                                                                              ಶಿಕ್ಷಕನ ಕೆಲಸವು ಸವಾಲುಗಳು ಮತ್ತು ಜವಾಬ್ದಾರಿಗಳಿಂದ ತುಂಬಿದೆ ಎಂದು ಅವರು ಅರಿತುಕೊಂಡರು ಆದರೆ ಕೊನೆಯಲ್ಲಿ ಅದು ತುಂಬಾ ಸಂತಸವನ್ನು ನೀಡುತ್ತದೆ.",
              "te": "విద్యార్థులు పాఠ్యపుస్తకాలను చదవడానికి రంజిత్‌సిన్హ్ చేసిన పనులు చూసి మాధవ్ ముగ్ధుడయ్యాడు. చాలా మంది విద్యార్థుల జీవితాలపై ఉపాధ్యాయుడు ప్రభావం చూపడాన్ని చూసి అతను ప్రేరణ పొందాడు.\n \n ఉపాధ్యాయుని ఉద్యోగం సవాళ్లు మరియు బాధ్యతలతో నిండి ఉందని అతను గ్రహించాడు, కానీ చివరికి అది చాలా సంతృప్తి పరుస్తుంది.",
              "or": "ରଣଜିତ୍ସିନ୍ କିପରି ଛାତ୍ରଛାତ୍ରୀମାନେ ପାଠ୍ୟ ପୁସ୍ତକ ପ read ିପାରିବେ ସେଥିପାଇଁ ମାଧବ ପ୍ରଭାବିତ ହୋଇଛନ୍ତି। ଜଣେ ଶିକ୍ଷକ ଅନେକ ଛାତ୍ରଙ୍କ ଜୀବନରେ ପ୍ରଭାବ ପକାଇବା ଦେଖି ସେ ଅନୁପ୍ରାଣିତ ଅଟନ୍ତି।\n \n ସେ ହୃଦୟଙ୍ଗମ କରିଛନ୍ତି ଯେ ଜଣେ ଶିକ୍ଷକଙ୍କ କାର୍ଯ୍ୟ ଚ୍ୟାଲେଞ୍ଜ ଏବଂ ଦାୟିତ୍ଵ ରେ ପରିପୂର୍ଣ୍ଣ କିନ୍ତୁ ଶେଷରେ ଏହା ଅତ୍ୟନ୍ତ ପୂର୍ଣ୍ଣ ଅଟେ।",
              "as": "ৰঞ্জিতসিংহই শিক্ষাৰ্থীসকলে পাঠ্যপুথি পঢ়িব পৰাটো নিশ্চিত কৰা ব্যৱস্থাটোৱে মাধৱক প্ৰভাৱিত কৰে। তেওঁ এজন শিক্ষকে বহুতো শিক্ষাৰ্থীৰ জীৱনত প্ৰভাৱ পেলোৱা দেখি অনুপ্ৰাণিত হয়। তেওঁ উপলব্ধি কৰে যে এজন শিক্ষকৰ চাকৰি প্ৰত্যাহ্বান আৰু দায়িত্বৰে ভৰপূৰ কিন্তু শেষত, অতি পৰিপূৰ্ণ।",
              "gu": "માધવ પ્રભાવિત છે કે કેવી રીતે રણજીતસિંહે વિદ્યાર્થીઓ પાઠ્યપુસ્તકો વાંચી શકે તેની ખાતરી કરી. તે શિક્ષકને ઘણા વિદ્યાર્થીઓના જીવન પર અસર કરે છે તે જોઈને પ્રેરિત થાય છે. તે સમજે છે કે શિક્ષકનું કામ પડકારો અને જવાબદારીઓથી ભરેલું છે પરંતુ અંતે તે ખૂબ જ પરિપૂર્ણ છે."
            }
          }
        ]
      },
      "scene20": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(iii)_1_1",
              "hi": "A4 (III) _1_1",
              "ka": "A4(iii)_1_1",
              "te": "A4(iii)_1_1",
              "or": "A4(iii)_1_1",
              "as": "A4(iii)_1_1",
              "gu": "A4(iii)_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav goes back to his room feeling incredibly inspired and motivated. He sits that evening and thinks of what he has learnt.",
              "hi": "माधव अपने कमरे में अविश्वसनीय रूप से उत्साहित और प्रेरित अनुभव कर रहा है। वह उस शाम बैठकर सोचता है कि उसने क्या सीखा है।",
              "ka": "ಮಾಧವ್ ನಂಬಲಾಗದಷ್ಟು ಸ್ಫೂರ್ತಿ ಮತ್ತು ಪ್ರೇರಣೆಯ ಭಾವನೆಯಿಂದ ತನ್ನ ಕೋಣೆಗೆ ಹಿಂತಿರುಗುತ್ತಾನೆ. ಆ ಸಂಜೆ ಕುಳಿತು ತಾನು ಕಲಿತದ್ದನ್ನು ಯೋಚಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్ గొప్ప ప్రేరణతో తన గదికి తిరిగి వెళ్ళాడు. ఆ సాయంత్రం కూర్చుని తాను నేర్చుకున్నదాని గురించి ఆలోచిస్తాడు.",
              "or": "ଅବିଶ୍ୱାସନୀୟ ଅନୁପ୍ରାଣିତ ଏବଂ ଉତ୍ସାହିତ ଅନୁଭବ କରି ମାଧବ ନିଜ କୋଠରୀକୁ\n  ଫେରିଯାଆନ୍ତି | ସେ ସନ୍ଧ୍ୟାରେ ବସି ସେ ଯାହା ଶିଖିଛନ୍ତି ସେ ବିଷୟରେ ଚିନ୍ତା କରନ୍ତି |",
              "as": "মাধৱে অবিশ্বাস্যভাৱে অনুপ্ৰাণিত অনুভৱ কৰি নিজৰ কোঠালৈ উভতি যায়। সি সেই সন্ধিয়া বহি কি কি শিকিলে তাৰ কথা ভাবে।",
              "gu": "માધવ અવિશ્વસનીય રીતે પ્રેરિત અને પ્રેણના લઈને તેના રૂમમાં પાછો જાય છે. તે સાંજે બેસીને વિચારે છે કે તે શું શીખ્યો છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(iii)_1_2",
              "hi": "A4 (III) _1_2",
              "ka": "A4(iii)_1_2",
              "te": "A4(iii)_1_2",
              "or": "A4(iii)_1_2",
              "as": "A4(iii)_1_2",
              "gu": "A4(iii)_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He opens his notebook to list his thoughts down",
              "hi": "वह अपने विचारों को सूचीबद्ध करने के लिए अपनी नोटबुक खोलता है",
              "ka": "ಅವನು ತನ್ನ ಆಲೋಚನೆಗಳನ್ನು ಪಟ್ಟಿ ಮಾಡಲು ತನ್ನ ನೋಟ್ಬುಕ್ ಅನ್ನು ತೆರೆಯುತ್ತಾನೆ",
              "te": "అతను తన ఆలోచనలను లిస్ట్ గా రాయడానికి తన నోట్‌బుక్‌ని తెరుస్తాడు",
              "or": "ତାଙ୍କର ଚିନ୍ତାଧାରାକୁ ତାଲିକାଭୁକ୍ତ କରିବା ପାଇଁ ସେ ତାଙ୍କର ନୋଟବୁକ୍\n  ଖୋଲନ୍ତି |",
              "as": "সি তাৰ চিন্তাবোৰ তালিকাভুক্ত কৰিবলৈ তাৰ টোকাবহীখন খোলে",
              "gu": "તે તેના વિચારોને સૂચિબદ્ધ કરવા માટે તેની નોટબુક ખોલે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page 1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "ପୃଷ୍ଠା୧",
              "as": "পৃষ্ঠা ১",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page 2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ 2",
              "or": "ପୃଷ୍ଠା୨",
              "as": "পৃষ্ঠা ২",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Page 3",
              "hi": "पृष्ठ 3",
              "ka": "ಪುಟ 3",
              "te": "పేజీ 3",
              "or": "ପୃଷ୍ଠା୩",
              "as": "পৃষ্ঠা ৩",
              "gu": "પેજ 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "A career in law is exciting, but I don't see myself becoming a lawyer. It needs dedicated studying and would be the same as UPSC.",
              "hi": "कानून में कैरियर रोमांचक है, लेकिन मैं खुद को वकील बनता नहीं देखता हूं। इसे समर्पित अध्ययन की आवश्यकता है और यूपीएससी के समान ही होगा।",
              "ka": "ಕಾನೂನಿನ ವೃತ್ತಿಯು ಉತ್ತೇಜಕವಾಗಿದೆ, ಆದರೆ ನಾನು ವಕೀಲನಾಗುವುದನ್ನು ನಾನು ಬಯಸುತ್ತಿಲ್ಲ. ಇದಕ್ಕೆ ಸಮರ್ಪಕ ಅಧ್ಯಯನದ ಅಗತ್ಯವಿದೆ ಮತ್ತು UPSC ಯಂತೆಯೇ ಇರುತ್ತದೆ.",
              "te": "న్యాయవాద వృత్తి ఉత్తేజకరమైనది, కానీ నేను లాయర్ గా ఉండలేను. దీనికి చాలా నేర్చుకోవాలసి ఉంటుంది మరియు UPSC లాగానే ఉంటుంది.",
              "or": "ଆଇନରେ କ୍ୟାରିୟର ଉତ୍ସାହଜନକ, କିନ୍ତୁ ମୁଁ ନିଜକୁ ଜଣେ ଓକିଲ ହେବାକୁ\n  ଦେଖୁ ନାହିଁ | ଏହା ଉତ୍ସର୍ଗୀକୃତ ଅଧ୍ୟୟନର ଆବଶ୍ୟକତା ଏବଂ UPSC ସହିତ ସମାନ ହେବ |",
              "as": "আইনৰ জীৱিকা ৰোমাঞ্চকৰ, কিন্তু মই নিজকে উকীল হিচাপে দেখা নাপাওঁ। ইয়াত সমৰ্পিত অধ্যয়নৰ প্ৰয়োজন যি UPSCৰ দৰে একে হ'ব।",
              "gu": "કાયદામાં કારકિર્દી રોમાંચક છે, પરંતુ હું મારી જાતને વકીલ બનતો જોતો નથી. તેને સમર્પિત અભ્યાસની જરૂર છે અને તે UPSC જેવું જ હશે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "A degree in journalism is helpful, but I can start my journey as a journalist and upskill on the job.",
              "hi": "पत्रकारिता में डिग्री सहायक है, लेकिन मैं एक पत्रकार  के रूप में अपनी यात्रा शुरू कर सकता हूं और नौकरी पर अपस्किल कर सकता हूँ।",
              "ka": "ಪತ್ರಿಕೋದ್ಯಮದಲ್ಲಿ ಪದವಿ ಸಹಾಯಕವಾಗಿರುತ್ತದೆ, ಆದರೆ ನಾನು ಪತ್ರಕರ್ತನಾಗಿ ನನ್ನ ಪ್ರಯಾಣವನ್ನು ಪ್ರಾರಂಭಿಸಬಹುದು ಮತ್ತು ಕೆಲಸದ ಮೇಲಿನ ಕೌಶಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸಬಹುದು.",
              "te": "జర్నలిజంలో డిగ్రీ ఉపయోగకరంగా ఉంటుంది, కానీ నేను జర్నలిస్ట్ గా నా ప్రయాణాన్ని ప్రారంభించి ఉద్యోగంలో నైపుణ్యాన్ని పెంచుకోగలను.",
              "or": "ସାମ୍ବାଦିକତାର ଏକ ଡିଗ୍ରୀ ସାହାଯ୍ୟକାରୀ, କିନ୍ତୁ ମୁଁ ସାମ୍ବାଦିକ ଭାବରେ \n ମୋର ଯାତ୍ରା ଆରମ୍ଭ କରିପାରିବି ଏବଂ ଚାକିରିରେ ଅପକମିଂ କରିପାରିବି |",
              "as": "সাংবাদিকতাৰ এটা ডিগ্ৰী সহায়ক হয়, কিন্তু মই সাংবাদিক হিচাপে মোৰ যাত্ৰা আৰম্ভ কৰিব পাৰোঁ আৰু কামটোত আগবাঢ়ি যাব পাৰোঁ।",
              "gu": "પત્રકારત્વની ડિગ્રી મદદરૂપ છે, પરંતુ હું પત્રકાર તરીકેની મારી સફર શરૂ કરી શકું છું અને નોકરીમાં ઉચ્ચ કૌશલ્ય મેળવી શકું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "I can see myself as a teacher in school than teaching in a college. The best part is that Badri is a teacher too, and we can help each other become better at teaching.",
              "hi": "मैं स्वयं को एक कॉलेज शिक्षण की तुलना में स्कूल में एक शिक्षक के रूप में देख सकता हूं। सबसे अच्छी बात यह है कि बद्री एक शिक्षक भी है, और हम एक-दूसरे को शिक्षण में बेहतर बनने में सहायता कर सकते हैं।",
              "ka": "ನಾನು ಕಾಲೇಜಿನಲ್ಲಿ ಕಲಿಸುವುದಕ್ಕಿಂತ ಶಾಲೆಯಲ್ಲಿ ಶಿಕ್ಷಕನಾಗಿ ನನ್ನನ್ನು ನೋಡಬಲ್ಲೆ. ಉತ್ತಮ ಭಾಗವೆಂದರೆ ಬದ್ರಿ ಕೂಡ ಒಬ್ಬ ಶಿಕ್ಷಕ, ಮತ್ತು ನಾವು ಬೋಧನೆಯಲ್ಲಿ ಉತ್ತಮವಾಗಲು ಪರಸ್ಪರ ಸಹಾಯ ಮಾಡಬಹುದು.",
              "te": "కాలేజీలో బోధించడం కంటే స్కూల్‌లో టీచర్‌గా నేను ఉండగలను. ఉత్తమమైన విషయం ఏమిటంటే, బద్రి కూడా ఉపాధ్యాయుడే, మరియు మేము బోధించడంలో మెరుగ్గా మారడానికి ఒకరికొకరం సహాయపడవచ్చు.",
              "or": "ଏକ କଲେଜରେ ଶିକ୍ଷାଦାନ ଅପେକ୍ଷା ମୁଁ ନିଜକୁ ବିଦ୍ୟାଳୟରେ ଶିକ୍ଷକ\n  ଭାବରେ ଦେଖିପାରେ | ସର୍ବୋତ୍ତମ ଅଂଶ ହେଉଛି ବଦ୍ରି ମଧ୍ୟ ଜଣେ ଶିକ୍ଷକ, ଏବଂ ଆମେ ପରସ୍ପରକୁ ଶିକ୍ଷାଦାନରେ ଉନ୍ନତ ହେବାରେ ସାହାଯ୍ୟ କରିପାରିବା |",
              "as": "মই নিজকে মহাবিদ্যালয়ত শিক্ষকতা কৰাতকৈ বিদ্যালয়ৰ শিক্ষক হিচাপে চাব পাৰোঁ। আটাইতকৈ ভাল কথাটো হ'ল বদ্ৰীও এজন শিক্ষক, আৰু আমি ইজনে সিজনক উন্নত শিক্ষাদানৰ ক্ষেত্ৰত সহায় কৰিব পাৰোঁ।",
              "gu": "હું મારી જાતને કોલેજમાં ભણાવવા કરતાં શાળામાં શિક્ષક તરીકે જોઈ શકું છું. સૌથી સારી વાત એ છે કે બદ્રી પણ એક શિક્ષક છે, અને અમે એકબીજાને શીખવવામાં વધુ સારી રીતે મદદ કરી શકીએ છીએ."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "Career alternative",
              "hi": "करियर वैकल्पिक",
              "ka": "ವೃತ್ತಿ ಪರ್ಯಾಯಗಳು",
              "te": "కెరీర్ ప్రత్యామ్నాయం",
              "or": "ବୃତ୍ତି ବିକଳ୍ପ |",
              "as": "জীৱিকাৰ বিকল্প",
              "gu": "કારકિર્દી વિકલ્પ"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "ಎಮ್‌ಸಿ‌ಕ್ಯೂ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "On failing his third attempt at passing the IAS exam, Madhav considers his career alternatives. What is true about career alternatives?",
              "hi": "आईएएस परीक्षा उत्तीर्ण करने के अपने तीसरे प्रयास में विफल होने पर, माधव अपने करियर के विकल्पों के बारे में विचार करता है। कैरियर विकल्पों के बारे में क्या सच है?",
              "ka": "IAS ಪರೀಕ್ಷೆಯಲ್ಲಿ ಉತ್ತೀರ್ಣರಾಗಲು ತನ್ನ ಮೂರನೇ ಪ್ರಯತ್ನದಲ್ಲಿ ವಿಫಲವಾದಾಗ, ಮಾಧವ್ ತನ್ನ ವೃತ್ತಿಜೀವನದ ಪರ್ಯಾಯಗಳನ್ನು ಪರಿಗಣಿಸುತ್ತಾನೆ. ವೃತ್ತಿ ಪರ್ಯಾಯಗಳ ಬಗ್ಗೆ ನಿಜವೇನು?",
              "te": "IAS పరీక్షలో ఉత్తీర్ణత సాధించడానికి తన మూడవ ప్రయత్నంలో విఫలమైనప్పుడు, మాధవ్ తన కెరీర్‌కు ప్రత్యామ్నాయాలను పరిశీలిస్తాడు. కెరీర్ ప్రత్యామ్నాయాల గురించి నిజం ఏమిటి?",
              "or": "IAS ପରୀକ୍ଷାରେ ଉତ୍ତୀର୍ଣ୍ଣ ହେବାରେ ତାଙ୍କର ତୃତୀୟ ପ୍ରୟାସରେ ବିଫଳ \n ହେବାପରେ, ମାଧବ ତାଙ୍କ କ୍ୟାରିୟରର ବିକଳ୍ପ ଭାବରେ ବିବେଚନା କରନ୍ତି | କ୍ୟାରିୟର ବିକଳ୍ପ ବିଷୟରେ କ’ଣ ସତ?",
              "as": "IAS পৰীক্ষাত উত্তীৰ্ণ হোৱাৰ তৃতীয় প্ৰয়াসত বিফল হোৱাৰ পিছত, মাধৱে তাৰ জীৱিকাৰ বিকল্পবোৰ বিবেচনা কৰে। জীৱিকাৰ বিকল্পৰ বিষয়ে কি সঁচা?",
              "gu": "IAS પરીક્ષા પાસ કરવાના ત્રીજા પ્રયાસમાં નિષ્ફળ જવા પર, માધવ તેની કારકિર્દીના વિકલ્પોને ધ્યાનમાં લે છે. કારકિર્દીના વિકલ્પો વિશે શું સાચું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Career alternatives are a list of ten different career options for an individual.",
              "hi": "करियर विकल्प एक व्यक्ति के लिए दस अलग-अलग करियर विकल्पों की एक सूची है।",
              "ka": "ವೃತ್ತಿ ಪರ್ಯಾಯಗಳು ಒಬ್ಬ ವ್ಯಕ್ತಿಗೆ ಹತ್ತು ವಿಭಿನ್ನ ವೃತ್ತಿ ಆಯ್ಕೆಗಳನ್ನು ನೀಡುವ ಪಟ್ಟಿಯಾಗಿದೆ.",
              "te": "కెరీర్ ప్రత్యామ్నాయాలు అనగా ఒక వ్యక్తికి ఉన్న పది విభిన్న కెరీర్ ఆప్షన్స్ యొక్క లిస్ట్.",
              "or": "କ୍ୟାରିୟର ବିକଳ୍ପଗୁଡ଼ିକ ହେଉଛି ଜଣେ ବ୍ୟକ୍ତିଙ୍କ ପାଇଁ ଦଶଟି ଭିନ୍ନ\n  କ୍ୟାରିୟର ବିକଳ୍ପର ଏକ ତାଲିକା |",
              "as": "জীৱিকাৰ বিকল্পবোৰ হৈছে এজন ব্যক্তিৰ বাবে দহটা পৃথক জীৱিকাৰ বিকল্পৰ তালিকা।",
              "gu": "કારકિર્દીના વિકલ્પો એ વ્યક્તિ માટેના દસ વિવિધ કારકિર્દી વિકલ્પોની સૂચિ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Career alternatives are useful when you need to change your career due to personal or professional reasons",
              "hi": "करियर विकल्प उपयोगी होते हैं जब आपको व्यक्तिगत या व्यावसायिक कारणों से अपने करियर को बदलने की आवश्यकता होती है",
              "ka": "ವೈಯಕ್ತಿಕ ಅಥವಾ ವೃತ್ತಿಪರ ಕಾರಣಗಳಿಂದಾಗಿ ನಿಮ್ಮ ವೃತ್ತಿಯನ್ನು ಬದಲಾಯಿಸಬೇಕಾದಾಗ ವೃತ್ತಿ ಪರ್ಯಾಯಗಳು ಉಪಯುಕ್ತವಾಗುತ್ತವೆ",
              "te": "వ్యక్తిగత లేదా వృత్తిపరమైన కారణాల వల్ల మీరు మీ కెరీర్‌ని మార్చుకోవాల్సిన అవసరం వచ్చినప్పుడు కెరీర్ ప్రత్యామ్నాయాలు ఉపయోగపడతాయి",
              "or": "ଯେତେବେଳେ ଆପଣ ବ୍ୟକ୍ତିଗତ କିମ୍ବା ବୃତ୍ତିଗତ କାରଣରୁ ତୁମର କ୍ୟାରିୟର\n  ବଦଳାଇବାକୁ ଆବଶ୍ୟକ କରନ୍ତି, ସେତେବେଳେ କ୍ୟାରିୟର ବିକଳ୍ପଗୁଡ଼ିକ ଉପଯୋଗୀ |",
              "as": "ব্যক্তিগত বা পেছাদাৰী কাৰণত তুমি তোমাৰ জীৱিকা সলনি কৰাৰ প্ৰয়োজন হ'লে জীৱিকাৰ বিকল্পবোৰ উপযোগী হয়",
              "gu": "જ્યારે તમારે વ્યક્તિગત અથવા વ્યાવસાયિક કારણોસર તમારી કારકિર્દી બદલવાની જરૂર હોય ત્યારે કારકિર્દીના વિકલ્પો ઉપયોગી છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Your current skills have no connection to career alternatives.",
              "hi": "आपके वर्तमान कौशल में करियर विकल्पों के लिए कोई संबंध नहीं है।",
              "ka": "ನಿಮ್ಮ ಪ್ರಸ್ತುತ ಕೌಶಲ್ಯಗಳು ವೃತ್ತಿ ಪರ್ಯಾಯಗಳಿಗೆ ಯಾವುದೇ ಸಂಪರ್ಕವನ್ನು ಹೊಂದಿರುವುದಿಲ್ಲ.",
              "te": "మీ ప్రస్తుత నైపుణ్యాలకు కెరీర్ ప్రత్యామ్నాయాలకు ఎలాంటి సంబంధం లేదు.",
              "or": "ତୁମର ସାମ୍ପ୍ରତିକ କୌଶଳର କ୍ୟାରିୟର ବିକଳ୍ପ ସହିତ କୌଣସି ସଂଯୋଗ ନାହିଁ |",
              "as": "তোমাৰ বৰ্তমানৰ দক্ষতাৰ জীৱিকাৰ বিকল্পৰ সৈতে কোনো সম্পৰ্ক নাই।",
              "gu": "તમારી વર્તમાન કુશળતાને કારકિર્દીના વિકલ્પો સાથે કોઈ જોડાણ નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "These alternatives are important only if you can’t pass an entrance exam.",
              "hi": "ये विकल्प केवल तभी महत्वपूर्ण हैं जब आप प्रवेश परीक्षा पास नहीं कर सकते।",
              "ka": "ನೀವು ಪ್ರವೇಶ ಪರೀಕ್ಷೆಯಲ್ಲಿ ಉತ್ತೀರ್ಣರಾಗಲು ಸಾಧ್ಯವಾಗದಿದ್ದರೆ ಮಾತ್ರ ಈ ಪರ್ಯಾಯಗಳು ಮುಖ್ಯ.",
              "te": "మీరు ప్రవేశ పరీక్షలో ఉత్తీర్ణత సాధించలేకపోతే మాత్రమే ఈ ప్రత్యామ్నాయాలు ముఖ్యమైనవి.",
              "or": "ଯଦି ଆପଣ ଏକ ପ୍ରବେଶିକା ପରୀକ୍ଷାରେ ଉତ୍ତୀର୍ଣ୍ଣ ହୋଇପାରିବେ ନାହିଁ \n ତେବେ ଏହି ବିକଳ୍ପଗୁଡ଼ିକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ |",
              "as": "এই বিকল্পবোৰ কেৱল তেতিয়াহে গুৰুত্বপূৰ্ণ যেতিয়া তুমি কোনো প্ৰৱেশ পৰীক্ষাত উত্তীৰ্ণ হ'ব নোৱাৰা।",
              "gu": "જો તમે પ્રવેશ પરીક્ષા પાસ ન કરી શકો તો જ આ વિકલ્પો મહત્વપૂર્ણ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Think again! Working towards ten goals will be difficult, 2-3 options are a good number.\nit’s good to have a plan B available if your plan A does not work out.",
              "hi": "फिर से विचार करना! दस लक्ष्यों की ओर काम करना कठिन होगा, 2-3 विकल्प एक अच्छी संख्या है।\nयदि आपकी योजना ए काम नहीं करती है तो एक योजना बी उपलब्ध है।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಯೋಚಿಸಿ! ಹತ್ತು ಗುರಿಗಳ ಕಡೆಗೆ ಕೆಲಸ ಮಾಡುವುದು ಕಷ್ಟಕರವಾಗಿರುತ್ತದೆ, 2-3 ಆಯ್ಕೆಗಳು ಉತ್ತಮ ಸಂಖ್ಯೆ. ನಿಮ್ಮ ಪ್ಲಾನ್ ಎ ವರ್ಕ್ ಔಟ್ ಆಗದಿದ್ದರೆ ಪ್ಲಾನ್ ಬಿ ಲಭ್ಯವಿರುವುದು ಒಳ್ಳೆಯದು.",
              "te": "మళ్లీ ఆలోచించండి! పది లక్ష్యాల కోసం పని చేయడం కష్టం, 2-3 ఎంపికలు మంచి సంఖ్య.\n \n మీ ప్లాన్ A వర్కవుట్ కాకపోతే ప్లాన్ B అందుబాటులో ఉండడం మంచిది.",
              "or": "ପୁନର୍ବାର ଚିନ୍ତା କର! ଦଶଟି ଲକ୍ଷ୍ୟ ଦିଗରେ କାର୍ଯ୍ୟ କରିବା କଷ୍ଟକର ହେବ, ୨-୩ଟି ବିକଳ୍ପ ଏକ ଭଲ ସଂଖ୍ୟା |\n \n ଯଦି ଆପଣଙ୍କର ଯୋଜନା A କାର୍ଯ୍ୟକାରୀ ନହୁଏ ତେବେ ଏକ ପ୍ଲାନ୍ B ଉପଲବ୍ଧ ହେବା ଭଲ |",
              "as": "আকৌ চিন্তা কৰা! দহটা লক্ষ্যৰ বাবে কাম কৰাটো কঠিন হ'ব,২-৩টা বিকল্প হৈছে এক ভাল সংখ্যা। যদি তোমাৰ পৰিকল্পনা A-য়ে কাম নকৰে তেন্তে পৰিকল্পনা B উপলব্ধ থকাটো ভাল।",
              "gu": "ફરીથી વિચારો! દસ લક્ષ્યો તરફ કામ કરવું મુશ્કેલ હશે, 2-3 વિકલ્પો સારી સંખ્યા છે. જો તમારો પ્લાન A કામ ન કરે તો પ્લાન B ઉપલબ્ધ હોવું સારું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಉತ್ತಮ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ବହୁତ ଭଲ କାମ!",
              "as": "ভাল কৰিছা!",
              "gu": "સરસ કામ!"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Think again! It is helpful to use your current skills as a foundation for alternatives",
              "hi": "फिर से विचार करो! अपने वर्तमान कौशल का विकल्पों के आधार के रूप में उपयोग करना सहायक होता है",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಯೋಚಿಸಿ! ನಿಮ್ಮ ಪ್ರಸ್ತುತ ಕೌಶಲ್ಯಗಳನ್ನು ಪರ್ಯಾಯಗಳಿಗೆ ಅಡಿಪಾಯವಾಗಿ ಬಳಸಲು ಇದು ಸಹಾಯಕವಾಗುತ್ತದೆ.",
              "te": "మళ్లీ ఆలోచించండి! ప్రత్యామ్నాయాలకు పునాదిగా మీ ప్రస్తుత నైపుణ్యాలను ఉపయోగించడం ఉపయోగకరంగా ఉంటుంది",
              "or": "ପୁଣି ଥରେ ଭାବ! ତୁମର ସାମ୍ପ୍ରତିକ କୌଶଳକୁ ବିକଳ୍ପ ପାଇଁ ଏକ ମୂଳଦୁଆ\n  ଭାବରେ ବ୍ୟବହାର କରିବା ସାହାଯ୍ୟକାରୀ |",
              "as": "আকৌ চিন্তা কৰা! বিকল্পৰ ভেটি হিচাপে তোমাৰ বৰ্তমানৰ দক্ষতা ব্যৱহাৰ কৰাটো সহায়ক হ’ব",
              "gu": "ફરીથી વિચારો! તમારા વર્તમાન કૌશલ્યોનો ઉપયોગ વિકલ્પોના પાયા તરીકે કરવામાં મદદરૂપ થાય છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Think again! They are useful throughout your life if you need to change path due to personal or professional reasons",
              "hi": "फिर से विचार करो! यदि तुम्हारे व्यक्तिगत या व्यावसायिक कारणों से पथ बदलने की आवश्यकता है तो वे तुम्हारे जीवन भर में उपयोगी हैं",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಯೋಚಿಸಿ! ವೈಯಕ್ತಿಕ ಅಥವಾ ವೃತ್ತಿಪರ ಕಾರಣಗಳಿಂದ ನೀವು ಮಾರ್ಗವನ್ನು ಬದಲಾಯಿಸಬೇಕಾದರೆ ಅವು ನಿಮ್ಮ ಜೀವನದುದ್ದಕ್ಕೂ ಉಪಯುಕ್ತವಾಗಿವೆ.",
              "te": "మళ్లీ ఆలోచించండి! వ్యక్తిగత లేదా వృత్తిపరమైన కారణాల వల్ల మీరు మార్గాన్ని మార్చుకోవాల్సి వస్తే అవి మీ జీవితాంతం ఉపయోగపడతాయి",
              "or": "ପୁଣି ଥରେ ଭାବ! ବ୍ୟକ୍ତିଗତ କିମ୍ବା ବୃତ୍ତିଗତ କାରଣରୁ ଯଦି ଆପଣ ପଥ \n ପରିବର୍ତ୍ତନ କରିବାକୁ ଆବଶ୍ୟକ କରନ୍ତି ତେବେ ସେଗୁଡ଼ିକ ଆପଣଙ୍କ ଜୀବନସାରା ଉପଯୋଗୀ |",
              "as": "আকৌ চিন্তা কৰা! ব্যক্তিগত বা পেছাদাৰী কাৰণত যদি তুমি পথ সলনি কৰিব লগা হয় তেন্তে সেইবোৰ তোমাৰ গোটেই জীৱনৰ বাবে উপযোগী হ’ব",
              "gu": "ફરીથી વિચાર! જો તમારે વ્યક્તિગત અથવા વ્યાવસાયિક કારણોસર માર્ગ બદલવાની જરૂર હોય તો તે તમારા જીવનભર ઉપયોગી છે"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A4(iii)_1_3_D",
              "hi": "A4 (iii) _1_3_d",
              "ka": "A4(iii)_1_3_D",
              "te": "A4(iii)_1_3_D",
              "or": "A4(iii)_1_3_D",
              "as": "A4(iii)_1_3_D",
              "gu": "A4(iii)_1_3_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "Madhav has to choose between 2 careers. Help him decide what career to choose.",
              "hi": "माधव को 2 करियर के बीच चयन करना है। उसे यह तय करने में सहायता करें कि किस करियर को चुनना है।",
              "ka": "ಮಾಧವ್ 2 ವೃತ್ತಿಗಳ ನಡುವೆ ಆಯ್ಕೆ ಮಾಡಬೇಕು. ಯಾವ ವೃತ್ತಿಯನ್ನು ಆರಿಸಬೇಕೆಂದು ನಿರ್ಧರಿಸಲು ಅವನಿಗೆ ಸಹಾಯ ಮಾಡಿ.",
              "te": "మాధవ్ 2 కెరీర్‌లలో ఒకటి ఎంచుకోవాలి. ఏ వృత్తిని ఎంచుకోవాలో అతనికి సహాయం చేయండి.",
              "or": "ମାଧବଙ୍କୁ 2 ଟି କ୍ୟାରିଅର୍ ମଧ୍ୟରେ ବାଛିବାକୁ ପଡିବ | କେଉଁ କ୍ୟାରିୟର\n  ବାଛିବେ ତାହା ସ୍ଥିର କରିବାରେ ତାଙ୍କୁ ସାହାଯ୍ୟ କରନ୍ତୁ |",
              "as": "মাধৱে ২ টা জীৱিকাৰ মাজত বাছনি কৰিব লাগিব। কি জীৱিকা বাছনি কৰিব লাগে তাক নিৰ্ণয় কৰাত সহায় কৰা।",
              "gu": "માધવે 2 કારકિર્દીમાંથી પસંદગી કરવાની છે. તેને કઈ કારકિર્દી પસંદ કરવી તે નક્કી કરવામાં મદદ કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Take up Journalism as a career option",
              "hi": "एक कैरियर विकल्प के रूप में पत्रकारिता ले ले ",
              "ka": "ಪತ್ರಿಕೋದ್ಯಮವನ್ನು ವೃತ್ತಿಯ ಆಯ್ಕೆಯಾಗಿ ತೆಗೆದುಕೊಳ್ಳಿ",
              "te": "జర్నలిజాన్ని కెరీర్ ఆప్షన్‌గా తీసుకోవాలి",
              "or": "ସାମ୍ବାଦିକତାକୁ କ୍ୟାରିୟର ବିକଳ୍ପ ଭାବରେ ଗ୍ରହଣ କରନ୍ତୁ |",
              "as": "সাংবাদিকতাক জীৱিকাৰ বিকল্প হিচাপে ল’ব",
              "gu": "પત્રકારત્વને કારકિર્દીના વિકલ્પ તરીકે લો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Take up Teaching as a career option",
              "hi": "एक कैरियर विकल्प के रूप में पढ़ाना",
              "ka": "ಬೋಧನೆಯನ್ನು ವೃತ್ತಿಯ ಆಯ್ಕೆಯಾಗಿ ತೆಗೆದುಕೊಳ್ಳುವುದು",
              "te": "టీచింగ్‌ని కెరీర్ ఆప్షన్‌గా తీసుకోవాలి",
              "or": "ଶିକ୍ଷାଦାନକୁ କ୍ୟାରିୟର ବିକଳ୍ପ ଭାବରେ ଗ୍ରହଣ କର |",
              "as": "শিক্ষকতাক জীৱিকাৰ বিকল্প হিচাপে ল’ব",
              "gu": "શિક્ષણને કારકિર્દીના વિકલ્પ તરીકે લો"
            }
          }
        ]
      },
      "scene21": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A career in teaching",
              "hi": "शिक्षण में एक कैरियर",
              "ka": "ಶಿಕ್ಷಕ ವೃತ್ತಿ",
              "te": "ఉపాధ్యాయ వృత్తి",
              "or": "ଶିକ୍ଷାଦାନରେ ଏକ ବୃତ୍ତି |",
              "as": "শিক্ষাদানৰ এটা জীৱিকা",
              "gu": "શિક્ષણમાં કારકિર્દી"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is happy with his decision of becoming a teacher. Seeing Badri's journey has also been an inspiration for him.",
              "hi": "माधव एक शिक्षक बनने के अपने निर्णय से खुश है। बद्री की यात्रा को देखना उसके लिए एक प्रेरणा रही है।",
              "ka": "ಶಿಕ್ಷಕನಾಗುವ ನಿರ್ಧಾರದಿಂದ ಮಾಧವ್ ಸಂತಸಗೊಂಡಿದ್ದಾನೆ. ಬದ್ರಿಯವರ ಪಯಣ ನೋಡಿ ಅವನಿಗೂ ಸ್ಪೂರ್ತಿಯಾಗಿದೆ.",
              "te": "ఉపాధ్యాయుడు కావాలనే తన నిర్ణయం పట్ల మాధవ్ సంతోషంగా ఉన్నాడు. బద్రి ప్రయాణాన్ని చూడటం కూడా అతనికి ప్రేరణగా మారింది.",
              "or": "ଶିକ୍ଷକ ହେବାର ନିଷ୍ପତ୍ତିରେ ମାଧବ ଖୁସି ଅଛନ୍ତି। ବଦ୍ରିଙ୍କ ଯାତ୍ରା ଦେଖିବା\n  ମଧ୍ୟ ତାଙ୍କ ପାଇଁ ପ୍ରେରଣା ପାଲଟିଛି |",
              "as": "মাধৱ তাৰ শিক্ষক হোৱাৰ সিদ্ধান্তত সুখী। বদ্ৰীৰ যাত্ৰা দেখা পাইয়ো সি অনুপ্ৰেৰণা পাইছে।",
              "gu": "માધવ શિક્ષક બનવાના નિર્ણયથી ખુશ છે. બદ્રીની સફર જોવી એ પણ તેના માટે પ્રેરણારૂપ છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_1_2",
              "hi": "B1_1_2",
              "ka": "B1_1_2",
              "te": "B1_1_2",
              "or": "B1_1_2",
              "as": "B1_1_2",
              "gu": "B1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "But he doesn't feel he can afford to do full-time B.Ed for two years like Badri. He decides to find out more options from Badri.",
              "hi": "लेकिन उसे नहीं लगता  कि वह बद्री की तरह दो साल के लिए पूर्णकालिक बीएड कर सकता है। वह बद्री से अन्य विकल्प खोजने का निर्णय करता है।",
              "ka": "ಆದರೆ ಬದರಿಯಂತೆ ಎರಡು ವರ್ಷ ಪೂರ್ಣಾವಧಿ ಬಿ.ಎಡ್ ಮಾಡಲು ಶಕ್ತನಾಗಿಲ್ಲ ಎನ್ನುವುದು ಅವನ ಅನಿಸಿಕೆ. ಬದ್ರಿಯಿಂದ ಹೆಚ್ಚಿನ ಆಯ್ಕೆಗಳನ್ನು ಕಂಡುಕೊಳ್ಳಲು ಅವನು ನಿರ್ಧರಿಸುತ್ತಾನೆ",
              "te": "కానీ బద్రి లాగా రెండేళ్లు ఫుల్ టైం B.Ed చేసే స్థోమత తనకు లేదు. అతను బద్రి నుండి మరిన్ని ఎంపికలను తెలుసుకోవాలని నిర్ణయించుకున్నాడు.",
              "or": "କିନ୍ତୁ ସେ ଅନୁଭବ କରନ୍ତି ନାହିଁ ଯେ ସେ ବଦ୍ରିଙ୍କ ପରି ଦୁଇ ବର୍ଷ ର ସଂପୂର୍ଣ୍ଣ \n ସମୟ B. ED ପାଇଁ ଦେଇପାରିବେ। ସେ ବଦ୍ରିଙ୍କଠାରୁ ଅଧିକ ବିକଳ୍ପ ଖୋଜିବାକୁ ସ୍ଥିର କରନ୍ତି |",
              "as": "কিন্তু সি নাভাবে যে সি বদ্ৰীৰ দৰে দুবছৰৰ বাবে পূৰ্ণ-সময়ৰ B.Ed কৰিব পাৰিব। সি বদ্ৰীৰ পৰা অধিক বিকল্প বিচাৰি উলিয়াবলৈ সিদ্ধান্ত লয়৷",
              "gu": "પરંતુ તેને નથી લાગતું કે તે બદ્રીની જેમ બે વર્ષ પૂર્ણ-સમયનું B.Ed કરી શકશે. તેણે બદ્રી પાસેથી વધુ વિકલ્પો શોધવાનું નક્કી કર્યું."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_1_3//",
              "hi": "B1_1_3 //",
              "ka": "B1_1_3//",
              "te": "B1_1_3//",
              "or": "B1_1_3//",
              "as": "B1_1_3//",
              "gu": "B1_1_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Badri",
              "hi": "बद्री",
              "ka": "ಬದ್ರಿ",
              "te": "బద్రి",
              "or": "ବଦ୍ରି",
              "as": "বদ্ৰী",
              "gu": "બદ્રી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I think I should do my B.Ed through Distance Education. Classes will be only on the weekend. This will make it possible for me to work somewhere through the rest of the week.",
              "hi": "मुझे लगता है कि मुझे दूरस्थ शिक्षा के माध्यम से बीएड करना चाहिए। कक्षाएं केवल सप्ताहांत पर होंगी। इससे मेरे लिए बाकी सप्ताह में कहीं भी काम करना संभव हो जाएगा।",
              "ka": "ನಾನು ದೂರ ಶಿಕ್ಷಣದ ಮೂಲಕ ನನ್ನ ಬಿ.ಎಡ್ ಮಾಡಬೇಕು ಎಂದು  ಭಾವಿಸುತ್ತೇನೆ. ತರಗತಿಗಳು ವಾರಾಂತ್ಯದಲ್ಲಿ ಮಾತ್ರ ಇರುತ್ತವೆ. ಇದರಿಂದ ವಾರದ ಉಳಿದ ದಿನಗಳಲ್ಲಿ ಎಲ್ಲೋ ಒಂದು ಕಡೆ ಕೆಲಸ ಮಾಡಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ.",
              "te": "నేను దూరవిద్య ద్వారా నా B.Ed చేయాలని అనుకుంటున్నాను. తరగతులు వారాంతంలో మాత్రమే ఉంటాయి. దీనివల్ల మిగిలిన వారంలో ఎక్కడో ఒకచోట పని చేయడం సాధ్యమవుతుంది.",
              "or": "ମୁଁ ଭାବୁଛି ଦୂରତା ଶିକ୍ଷା ମାଧ୍ୟମରେ ମୋର B.Ed କରିବା ଉଚିତ୍ | \n କ୍ଲାସ୍ କେବଳ ସପ୍ତାହ ଶେଷରେ ହେବ | ଏହା ମୋ ପାଇଁ ସପ୍ତାହର ବାକି ସମୟ ମଧ୍ୟରେ କ ewhere ଣସି ସ୍ଥାନରେ କାମ କରିବା ସମ୍ଭବ କରିବ |",
              "as": "মই ভাবো মই দূৰ শিক্ষাৰ জৰিয়তে মোৰ B.Ed কৰা উচিত। শ্ৰেণীসমূহ কেৱল সপ্তাহান্ততহে হ'ব। এইটোৱে মোৰ বাবে সপ্তাহটোৰ বাকী সময়ছোৱাত কৰবাত কাম কৰাটো সম্ভৱ কৰি তুলিব।",
              "gu": "મને લાગે છે કે મારે ડિસ્ટન્સ એજ્યુકેશન દ્વારા બી.એડ કરવું જોઈએ. વર્ગો ફક્ત સપ્તાહના અંતે જ રહેશે. આ મારા માટે અઠવાડિયાના બાકીના દિવસોમાં ક્યાંક કામ કરવાનું શક્ય બનાવશે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "You can talk to a few teachers in my school. Some have done their training through Distance Education. They might be able to tell you if it is worth it.",
              "hi": "तुम मेरे स्कूल में कुछ शिक्षकों से बात कर सकते हो। कुछ ने दूरस्थ शिक्षा के माध्यम से अपना प्रशिक्षण किया है। वे तुमको यह बताने में सक्षम हो सकते हैं कि यह करने लायक है या नहीं।",
              "ka": "ನೀನು ನನ್ನ ಶಾಲೆಯ ಕೆಲವು ಶಿಕ್ಷಕರೊಂದಿಗೆ ಮಾತನಾಡಬಹುದು. ಕೆಲವರು ದೂರ ಶಿಕ್ಷಣದ ಮೂಲಕ ತರಬೇತಿ ಪಡೆದಿದ್ದಾರೆ. ಅದು ಯೋಗ್ಯವಾಗಿದೆಯೇ ಎಂದು ಅವರು ನಿಮಗೆ ಹೇಳಬಹುದು",
              "te": "నువ్వు నా పాఠశాలలో కొంతమంది ఉపాధ్యాయులతో మాట్లాడవచ్చు. కొందరు దూరవిద్య ద్వారా శిక్షణ పొందారు. అది విలువైనదేనా అని వారు నీకు చెప్పగలరు.",
              "or": "ତୁମେ ମୋ ସ୍କୁଲର କିଛି ଶିକ୍ଷକଙ୍କ ସହିତ କଥା ହୋଇପାରିବ | କେତେକ\n  ଦୂରତା ଶିକ୍ଷା ମାଧ୍ୟମରେ ସେମାନଙ୍କର ପ୍ରଶିକ୍ଷଣ କରିଛନ୍ତି | ଏହାର ମୂଲ୍ୟ ଅଛି କି ନାହିଁ ସେମାନେ ଆପଣଙ୍କୁ କହିବାକୁ ସମର୍ଥ ହୋଇପାରନ୍ତି |",
              "as": "তুমি মোৰ বিদ্যালয়ৰ কেইজনমান শিক্ষকৰ সৈতে কথা পাতিব পাৰা। কিছুমানে দূৰ শিক্ষাৰ জৰিয়তে তেওঁলোকৰ প্ৰশিক্ষণ লৈছে। তেওঁলোকে তোমাক ক'ব পাৰিব যে ইয়াৰ মূল্য আছে নে নাই।",
              "gu": "તારે મારી શાળાના થોડા શિક્ષકો સાથે વાત કરીવી જોઈએ. કેટલાકે તેમની તાલીમ ડિસ્ટન્સ એજ્યુકેશન દ્વારા કરી છે. તેઓ તને કહી શકશે કે શું તે મૂલ્યવાન છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_2_1",
              "hi": "B1_2_1",
              "ka": "B1_2_1",
              "te": "B1_2_1",
              "or": "B1_2_1",
              "as": "B1_2_1",
              "gu": "B1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav talks to teachers from Badri's school about their Distance Education experience.",
              "hi": "माधव ने बद्री के स्कूल के शिक्षकों से उनकी दूरस्थ शिक्षा अनुभव के बारे में बात की।",
              "ka": "ಮಾಧವ್ ಬದ್ರಿ ಶಾಲೆಯ ಶಿಕ್ಷಕರೊಂದಿಗೆ ತನ್ನ ದೂರಶಿಕ್ಷಣದ ಅನುಭವದ ಬಗ್ಗೆ ಮಾತನಾಡುತ್ತಾನೆ.",
              "te": "మాధవ్ బద్రి పాఠశాల ఉపాధ్యాయులతో వారి దూరవిద్య అనుభవం గురించి మాట్లాడాడు.",
              "or": "ମାଧବ ବଦ୍ରି ବିଦ୍ୟାଳୟର ଶିକ୍ଷକମାନଙ୍କ ସହିତ ସେମାନଙ୍କର ଦୂରତା\n  ଶିକ୍ଷା ଅଭିଜ୍ଞତା ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି |",
              "as": "মাধৱে বদ্ৰীৰ বিদ্যালয়ৰ শিক্ষকসকলৰ সৈতে তেওঁলোকৰ দূৰ শিক্ষাৰ অভিজ্ঞতাৰ বিষয়ে কথা পাতে।",
              "gu": "માધવ બદ્રીની શાળાના શિક્ષકો સાથે તેમના ડિસ્ટન્સ એજ્યુકેશનના અનુભવ વિશે વાત કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_2_2",
              "hi": "B1_2_2",
              "ka": "B1_2_2",
              "te": "B1_2_2",
              "or": "B1_2_2",
              "as": "B1_2_2",
              "gu": "B1_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He finds out that doing the B.Ed through Distance Education was a good decision for most of them. It helped them balance their studies and a full-time job. The quality of training was also quite good.",
              "hi": "उसे पता चलता है कि दूरस्थ शिक्षा के माध्यम से बीएड करना उनमें से अधिकतर के लिए एक अच्छा निर्णय था। इससे उन्हें अपने अध्ययन और पूर्णकालिक नौकरी को संतुलित करने में मदद मिली। प्रशिक्षण की गुणवत्ता भी काफी अच्छी थी।",
              "ka": "ದೂರಶಿಕ್ಷಣದ ಮೂಲಕ ಬಿಎಡ್ ಮಾಡುವುದು ಅವರಲ್ಲಿ ಹೆಚ್ಚಿನವರಿಗೆ ಉತ್ತಮ ನಿರ್ಧಾರ ಎಂದು ಅವನು ಕಂಡುಕೊಳ್ಳುತ್ತಾನೆ. ಇದು ಅವನ ಅಧ್ಯಯನ ಮತ್ತು ಪೂರ್ಣ ಸಮಯದ ಕೆಲಸವನ್ನು ಸಮತೋಲನಗೊಳಿಸಲು ಸಹಾಯ ಮಾಡಿತು. ತರಬೇತಿಯ ಗುಣಮಟ್ಟವೂ ಸಾಕಷ್ಟು ಉತ್ತಮವಾಗಿತ್ತು.",
              "te": "దూరవిద్య ద్వారా B.Ed చేయడం చాలా మందికి మంచి నిర్ణయం అని అతను తెలుసుకుంటాడు. ఇది వారి చదువులు మరియు పూర్తి సమయం ఉద్యోగాన్ని బ్యాలెన్స్ చేయడానికి వారికి సహాయపడింది. శిక్షణ నాణ్యత కూడా బాగానే ఉంది.",
              "or": "ସେ ଜାଣିବାକୁ ପାଇଲେ ଯେ ଦୂରତା ଶିକ୍ଷା ମାଧ୍ୟମରେ B.Ed କରିବା \n ସେମାନଙ୍କ ମଧ୍ୟରୁ ଅଧିକାଂଶଙ୍କ ପାଇଁ ଏକ ଭଲ ନିଷ୍ପତ୍ତି ଥିଲା | ଏହା ସେମାନଙ୍କୁ ସେମାନଙ୍କର ଅଧ୍ୟୟନ ଏବଂ ଏକ ପୂର୍ଣ୍ଣକାଳୀନ କାର୍ଯ୍ୟକୁ ସନ୍ତୁଳିତ କରିବାରେ ସାହାଯ୍ୟ କଲା | ପ୍ରଶିକ୍ଷଣର ଗୁଣ ମଧ୍ୟ ବହୁତ ଭଲ ଥିଲା |",
              "as": "সি গম পালে যে দূৰ শিক্ষাৰ জৰিয়তে B.Ed কৰাটো তেওঁলোকৰ বেছিভাগৰ বাবে এটা ভাল সিদ্ধান্ত আছিল। ই তেওঁলোকক তেওঁলোকৰ অধ্যয়ন আৰু পূৰ্ণ-সময়ৰ চাকৰিৰ মাজত ভাৰসাম্য বজাই ৰখাত সহায় কৰিছিল। প্ৰশিক্ষণৰ মানদণ্ডও যথেষ্ট ভাল আছিল।",
              "gu": "તેને ખબર પડી કે ડિસ્ટન્સ એજ્યુકેશન દ્વારા B.Ed કરવું તેમાંથી મોટાભાગના લોકો માટે સારો નિર્ણય હતો. તે તેમને તેમના અભ્યાસ અને પૂર્ણ-સમયની નોકરીને સંતુલિત કરવામાં મદદ કરી. તાલીમની ગુણવત્તા પણ ઘણી સારી હતી."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_2_3",
              "hi": "B1_2_3",
              "ka": "B1_2_3",
              "te": "B1_2_3",
              "or": "B1_2_3",
              "as": "B1_2_3",
              "gu": "B1_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to continue working at the coaching centre full time while studying over the weekends. He wants to finish his studies and start working full time soon.",
              "hi": "माधव सप्ताहांत पर अध्ययन करते समय कोचिंग सेंटर में पूर्णकालिक काम करते रहने का निश्चय करता है। वह अपनी पढ़ाई खत्म करना चाहता है और जल्द ही पूर्णकालिक काम करना चाहता है।",
              "ka": "ಮಾಧವ್ ವಾರಾಂತ್ಯದಲ್ಲಿ ಅಧ್ಯಯನ ಮಾಡುವಾಗ ಕೋಚಿಂಗ್ ಸೆಂಟರ್‌ನಲ್ಲಿ ಪೂರ್ಣ ಸಮಯವನ್ನು ಮುಂದುವರಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಅವನು ತನ್ನ ಅಧ್ಯಯನವನ್ನು ಮುಗಿಸಲು ಮತ್ತು ಶೀಘ್ರದಲ್ಲೇ ಪೂರ್ಣ ಸಮಯವನ್ನು ಕೆಲಸ ಮಾಡಲು ಬಯಸುತ್ತಾನೆ.",
              "te": "మాధవ్ వారాంతాల్లో చదువుకుంటూనే కోచింగ్ సెంటర్‌లో పూర్తి సమయం పనిచేయాలని నిర్ణయించుకున్నాడు. త్వరలో చదువు పూర్తి చేసి ఫుల్ టైం వర్క్ చేయాలనుకుంటున్నాడు.",
              "or": "ସପ୍ତାହ ଶେଷରେ ଅଧ୍ୟୟନ କରୁଥିବା ସମୟରେ ମାଧବ କୋଚିଂ ସେଣ୍ଟର\n ରେ ପୂର୍ଣ୍ଣ ସମୟ କାମ ଜାରି ରଖିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ସେ ଅଧ୍ୟୟନ ସମାପ୍ତ କରିବାକୁ ଏବଂ ଶୀଘ୍ର ପୂର୍ଣ୍ଣ ସମୟ କାମ କରିବାକୁ ଚାହୁଁଛନ୍ତି |",
              "as": "মাধৱে সপ্তাহান্তত অধ্যয়ন কৰি থাকোঁতে সম্পূৰ্ণ সময়ৰ বাবে প্ৰশিক্ষণ কেন্দ্ৰত কাম কৰি থকাৰ সিদ্ধান্ত লয়। তেওঁ পঢ়া শেষ কৰিব বিচাৰে আৰু সোনকালেই সম্পূৰ্ণ সময়ৰ কাম আৰম্ভ কৰিব বিচাৰে।",
              "gu": "માધવ સપ્તાહના અંતે અભ્યાસ કરતી વખતે કોચિંગ સેન્ટરમાં સંપૂર્ણ સમય કામ કરવાનું ચાલુ રાખવાનું નક્કી કરે છે. તે પોતાનો અભ્યાસ પૂરો કરીને જલ્દી પૂર્ણ સમય કામ કરવાનું શરૂ કરવા માંગે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_2_4",
              "hi": "B1_2_4",
              "ka": "B1_2_4",
              "te": "B1_2_4",
              "or": "B1_2_4",
              "as": "B1_2_4",
              "gu": "B1_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He also sends money home to his father regularly.",
              "hi": "वह नियमित रूप से अपने पिता को पैसे भी भेजता है।",
              "ka": "ಅವನು ತನ್ನ ತಂದೆಗೆ ನಿಯಮಿತವಾಗಿ ಹಣವನ್ನು ಕಳುಹಿಸುತ್ತಾನೆ.",
              "te": "అతను తన తండ్రికి కూడా క్రమం తప్పకుండా డబ్బు పంపేవాడు.",
              "or": "ସେ ନିୟମିତ ଭାବେ ବାପାଙ୍କ ନିକଟକୁ ଟଙ୍କା ପଠାନ୍ତି |",
              "as": "তেওঁ নিয়মীয়াকৈ ঘৰৰ দেউতাকলৈ টকাও প্ৰেৰণ কৰে।",
              "gu": "તે તેના પિતાને ઘરે પૈસા પણ નિયમિત મોકલે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_1",
              "hi": "B1_3_1",
              "ka": "B1_3_1",
              "te": "B1_3_1",
              "or": "B1_3_1",
              "as": "B1_3_1",
              "gu": "B1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He is looking forward to starting his career as a teacher. Madhav truly believes that he can bring change by teaching young students.",
              "hi": "वह एक शिक्षक के रूप में अपना करियर शुरू करने की आशा कर रहां है। माधव वास्तव में मानता है कि वह युवा छात्रों को पढ़ाने से परिवर्तन ला सकता है।",
              "ka": "ಅವನು ಶಿಕ್ಷಕರಾಗಿ ತಮ್ಮ ವೃತ್ತಿಜೀವನವನ್ನು ಪ್ರಾರಂಭಿಸಲು ಎದುರು ನೋಡುತ್ತಿದ್ದಾನೆ. ಯುವ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಕಲಿಸುವ ಮೂಲಕ ಬದಲಾವಣೆಯನ್ನು ತರಬಹುದು ಎಂದು ಮಾಧವ್ ನಿಜವಾಗಿಯೂ ನಂಬುತ್ತಾರೆ.",
              "te": "అతడు అధ్యాపకుడిగా కెరీర్ ప్రారంభించాలని ఎదురు చూస్తున్నాడు. యువ విద్యార్థులకు బోధించడం ద్వారా మార్పు తీసుకురాగలనని మాధవ్ నమ్ముతున్నాడు.",
              "or": "ସେ ଜଣେ ଶିକ୍ଷକ ଭାବରେ କ୍ୟାରିଅର୍ ଆରମ୍ଭ କରିବାକୁ ଅପେକ୍ଷା କରିଛନ୍ତି |\n  ମାଧବ ପ୍ରକୃତରେ ବିଶ୍ୱାସ କରନ୍ତି ଯେ ସେ ଯୁବକ ଛାତ୍ରମାନଙ୍କୁ ଶିକ୍ଷା ଦେଇ ପରିବର୍ତ୍ତନ ଆଣିପାରିବେ |",
              "as": "তেওঁ এজন শিক্ষক হিচাপে নিজৰ জীৱিকা আৰম্ভ কৰিবলৈ বাট চাই আছে। মাধৱে সঁচাকৈয়ে বিশ্বাস কৰে যে তেওঁ যুৱ শিক্ষাৰ্থীসকলক শিকাই পৰিৱৰ্তন আনিব পাৰিব।",
              "gu": "તે શિક્ષક તરીકે તેની કારકિર્દી શરૂ કરવા માટે ઉત્સુક છે. માધવ સાચે જ માને છે કે તે યુવા વિદ્યાર્થીઓને ભણાવીને પરિવર્તન લાવી શકે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_2",
              "hi": "B1_3_2",
              "ka": "B1_3_2",
              "te": "B1_3_2",
              "or": "B1_3_2",
              "as": "B1_3_2",
              "gu": "B1_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After two years of his studies, he appears for the B.Ed examinations and clears them with good marks. All three friends are delighted to see the results.",
              "hi": "अपने अध्ययन के दो साल बाद, वह बीएड परीक्षाओं के लिए प्रकट होता है और उन्हें अच्छे अंकों के साथ उत्तीर्ण करता है। परिणाम देखकर तीनों मित्र खुश हैं ।",
              "ka": "ಎರಡು ವರ್ಷಗಳ ಅಧ್ಯಯನದ ನಂತರ, ಅವನು B.Ed ಪರೀಕ್ಷೆಗಳಿಗೆ ಹಾಜರಾಗುತ್ತಾನೆ ಮತ್ತು ಉತ್ತಮ ಅಂಕಗಳೊಂದಿಗೆ ತೇರ್ಗಡೆಯಾಗುತ್ತಾನೆ. ಎಲ್ಲಾ ಮೂವರು ಸ್ನೇಹಿತರು ಫಲಿತಾಂಶಗಳನ್ನು ನೋಡಿ ಸಂತೋಷಪಟ್ಟಿದ್ದಾರೆ.",
              "te": "రెండేళ్లు చదివిన తర్వాత B.Ed పరీక్షలకు హాజరై మంచి మార్కులతో క్లియర్ చేస్తాడు. ముగ్గురు స్నేహితులూ ఫలితాలను చూసి సంతోషిస్తున్నారు.",
              "or": "ଦୁଇ ବର୍ଷର ଅଧ୍ୟୟନ ପରେ, ସେ B.Ed ପରୀକ୍ଷା ପାଇଁ ଉପସ୍ଥିତ ହୁଅନ୍ତି\n  ଏବଂ ସେମାନଙ୍କୁ ଭଲ ମାର୍କ ରାଖୀ ପାସ କରନ୍ତି | ସମସ୍ତ ତିନିଜଣ ସାଙ୍ଗ ଫଳାଫଳ ଦେଖି ଆନନ୍ଦିତ ହୁଅନ୍ତି |",
              "as": "দুবছৰ পঢ়াৰ পিছত, তেওঁ B.Ed পৰীক্ষাত অৱতীৰ্ণ হয় আৰু ভাল নম্বৰৰ সৈতে উত্তীৰ্ণ হয়। তিনিওজন বন্ধুৱে ফলাফল দেখি আনন্দিত হয়।",
              "gu": "તેના બે વર્ષના અભ્યાસ પછી, તે B.Ed ની પરીક્ષા આપે છે અને સારા ગુણ સાથે પાસ કરે છે. ત્રણેય મિત્રો પરિણામ જોઈને ખુશ છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_3",
              "hi": "B1_3_3",
              "ka": "B1_3_3",
              "te": "B1_3_3",
              "or": "B1_3_3",
              "as": "B1_3_3",
              "gu": "B1_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He can now start applying to different institutions for a position. He is confused about whether he should apply for government jobs or private schools.",
              "hi": "वह अब नौकरी के लिए विभिन्न संस्थानों में आवेदन करना शुरू कर सकता है। वह इस बात से उलझन में है कि क्या उसे  सरकारी नौकरियों या निजी स्कूलों के लिए आवेदन करना चाहिए।",
              "ka": "ಅವನು ಈಗ ಸ್ಥಾನಕ್ಕಾಗಿ ವಿವಿಧ ಸಂಸ್ಥೆಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಪ್ರಾರಂಭಿಸಬಹುದು. ಸರ್ಕಾರಿ ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಹಾಕಬೇಕೋ ಅಥವಾ ಖಾಸಗಿ ಶಾಲೆಗಳಿಗೆ ಅರ್ಜಿ ಹಾಕಬೇಕೋ ಎನ್ನುವ ಗೊಂದಲದಲ್ಲಿದ್ದಾನೆ.",
              "te": "అతను ఇప్పుడు ఉద్యోగం కోసం వివిధ సంస్థలకు అప్లై చేయడం ప్రారంభించవచ్చు. అతను ప్రభుత్వ ఉద్యోగాలకు అప్లై చేయాలా.. లేక ప్రైవేట్ పాఠశాలలకు అప్లై చేయాలా అనే విషయంలో అయోమయంలో ఉన్నాడు.",
              "or": "ସେ ବର୍ତ୍ତମାନ ଏକ ପଦବୀ ପାଇଁ ବିଭିନ୍ନ ଅନୁଷ୍ଠାନରେ ଆବେଦନ କରିବା\n  ଆରମ୍ଭ କରିପାରିବେ | ସେ ସରକାରୀ ଚାକିରୀ କିମ୍ବା ଘରୋଇ ବିଦ୍ୟାଳୟ ପାଇଁ ଆବେଦନ କରିବା ଉଚିତ କି ନାହିଁ ସେ ନେଇ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛନ୍ତି।",
              "as": "তেওঁ এতিয়া এটা পদৰ বাবে বিভিন্ন প্ৰতিষ্ঠানত আবেদন কৰিব পাৰিব। তেওঁ চৰকাৰী বিদ্যালয় নে ব্যক্তিগত বিদ্যালয়ৰ বাবে আবেদন কৰিব তাক লৈ বিভ্ৰান্ত।",
              "gu": "હવે તે પદ માટે વિવિધ સંસ્થાઓમાં અરજી કરવાનું શરૂ કરી શકે છે. તે મૂંઝવણમાં છે કે તેણે સરકારી નોકરી માટે અરજી કરવી જોઈએ કે ખાનગી શાળાઓમાં."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_4",
              "hi": "B1_3_4",
              "ka": "B1_3_4",
              "te": "B1_3_4",
              "or": "B1_3_4",
              "as": "B1_3_4",
              "gu": "B1_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav plans to apply to the government for the Uttar Pradesh Teacher Eligibility Test (UPTET). He feels he can create a more significant impact if he works in a government school.",
              "hi": "माधव उत्तर प्रदेश शिक्षक पात्रता परीक्षण (यूपीटीईटी) के लिए सरकारी स्कूल में आवेदन करने की योजना बनाता है। उसे लगता है कि अगर वह सरकारी स्कूल में काम करता है तो वह अधिक महत्वपूर्ण प्रभाव डाल सकता है।",
              "ka": "ಮಾಧವ್ ಉತ್ತರ ಪ್ರದೇಶದ ಶಿಕ್ಷಕರ ಅರ್ಹತಾ ಪರೀಕ್ಷೆಗೆ (UPTET) ಸರ್ಕಾರಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಯೋಜಿಸಿದ್ದಾನೆ. ಅವನು ಸರ್ಕಾರಿ ಶಾಲೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಿದರೆ ಹೆಚ್ಚು ಮಹತ್ವದ ಪರಿಣಾಮವನ್ನು ಸೃಷ್ಟಿಸಬಹುದು ಎಂದು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "ఉత్తరప్రదేశ్ ఉపాధ్యాయ అర్హత పరీక్ష (UPTET) కోసం ప్రభుత్వానికి అప్లై చేయాలని మాధవ్ ప్లాన్ చేస్తున్నాడు. అతను ప్రభుత్వ పాఠశాలలో పనిచేస్తే ఇంకా ఎక్కువ ప్రభావాన్ని సృష్టించగలనని అతను భావిస్తున్నాడు.",
              "or": "ଉତ୍ତରପ୍ରଦେଶ ଶିକ୍ଷକ ଯୋଗ୍ୟତା ପରୀକ୍ଷା (UPTET) ପାଇଁ ମାଧବ\n  ସରକାରଙ୍କୁ ଆବେଦନ କରିବାକୁ ଯୋଜନା କରିଛନ୍ତି। ଯଦି ସେ ଏକ ସରକାରୀ ବିଦ୍ୟାଳୟରେ କାର୍ଯ୍ୟ କରନ୍ତି ତେବେ ସେ ଅଧିକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ପ୍ରଭାବ ସୃଷ୍ଟି କରିପାରନ୍ତି ବୋଲି ସେ ଅନୁଭବ କରୁଛନ୍ତି |",
              "as": "মাধৱে উত্তৰ প্ৰদেশ শিক্ষক যোগ্যতা পৰীক্ষাৰ (UPTET) বাবে চৰকাৰৰ ওচৰত আবেদন কৰাৰ পৰিকল্পনা কৰে। তেওঁ অনুভৱ কৰে যে যদি তেওঁ চৰকাৰী বিদ্যালয়ত কাম কৰে তেন্তে তেওঁ অধিক গুৰুত্বপূৰ্ণ প্ৰভাৱ পেলাব পাৰিব।",
              "gu": "માધવ ઉત્તર પ્રદેશ ટીચર એલિજિબિલિટી ટેસ્ટ (UPTET) માટે સરકારને અરજી કરવાની યોજના ધરાવે છે. તેને લાગે છે કે જો તે સરકારી શાળામાં કામ કરે તો તે વધુ નોંધપાત્ર અસર ઊભી કરી શકે છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_4b",
              "hi": "B1_3_4B",
              "ka": "B1_3_4b",
              "te": "B1_3_4b",
              "or": "B1_3_4b",
              "as": "B1_3_4b",
              "gu": "B1_3_4b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The process for applying to a government school is time taking. While, for a private school, he can start working as soon as there is a job opening.",
              "hi": "एक सरकारी स्कूल में आवेदन करने की प्रक्रिया समय ले रही है। जबकि, एक निजी स्कूल में, जैसे ही नौकरी का अवसर होगा उसके तुरंत बाद वह काम करना शुरू कर सकता है।",
              "ka": "ಸರ್ಕಾರಿ ಶಾಲೆಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವ ಪ್ರಕ್ರಿಯೆಯು ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ. ಆದರೆ, ಖಾಸಗಿ ಶಾಲೆಗೆ, ಅವನು ಉದ್ಯೋಗಾವಕಾಶದ ತಕ್ಷಣ ಕೆಲಸ ಪ್ರಾರಂಭಿಸಬಹುದು.",
              "te": "ప్రభుత్వ పాఠశాలకు అప్లై చేయడానికి చాలా సమయం పడుతుంది. కానీ, ఒక ప్రైవేట్ పాఠశాలలో అయితే, అతను ఉద్యోగం ప్రారంభమైన వెంటనే పని ప్రారంభించవచ్చు.",
              "or": "ଏକ ସରକାରୀ ବିଦ୍ୟାଳୟରେ ଆବେଦନ କରିବାର ପ୍ରକ୍ରିୟା ସମୟ ନେଉଛି |\n  ଏକ ଘରୋଇ ବିଦ୍ୟାଳୟ ପାଇଁ, ଚାକିରି ଖୋଲିବା ମାତ୍ରେ ସେ କାମ ଆରମ୍ଭ କରିପାରନ୍ତି |",
              "as": "চৰকাৰী বিদ্যালয়ত আবেদন কৰাৰ প্ৰক্ৰিয়াটোত সময় লাগিব। আনহাতে, এখন ব্যক্তিগত বিদ্যালয়ৰ বাবে, চাকৰি খোলাৰ লগে লগে তেওঁ কাম আৰম্ভ কৰিব পাৰিব।",
              "gu": "સરકારી શાળામાં અરજી કરવાની પ્રક્રિયામાં સમય લાગે છે. જ્યારે, ખાનગી શાળા માટે, નોકરી શરૂ થતાંની સાથે જ તે કામ કરવાનું શરૂ કરી શકે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_5//",
              "hi": "B1_3_5 //",
              "ka": "B1_3_5//",
              "te": "B1_3_5//",
              "or": "B1_3_5//",
              "as": "B1_3_5//",
              "gu": "B1_3_5//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୁଲଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I sent you a message about the opening at the private school in Kanpur. Did you apply there?",
              "hi": "मैंने तुमको कानपुर में निजी स्कूल में नौकरी की रिक्तियों के बारे में संदेश भेजा था। क्या तुमने वहां आवेदन किया?",
              "ka": "ಕಾನ್ಪುರದ ಖಾಸಗಿ ಶಾಲೆಯು ತೆರೆಯುವ ಬಗ್ಗೆ ನಾನು ನಿನಗೆ ಸಂದೇಶ ಕಳುಹಿಸಿದ್ದೇನೆ. ನೀನು ಅಲ್ಲಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ್ದೀಯಾ?",
              "te": "కాన్పూర్‌లోని ప్రైవేట్ పాఠశాలలో ఉన్న ఉద్యోగం గురించి నేను నీకు మెసేజ్ పంపాను. దానికి అప్లై చేశావా?",
              "or": "କାନପୁରର ଘରୋଇ ବିଦ୍ୟାଳୟରେ ଖୋଲିବା ବିଷୟରେ ମୁଁ ଆପଣଙ୍କୁ\n  ଏକ ବାର୍ତ୍ତା ପଠାଇଲି | ଆପଣ ସେଠାରେ ଆବେଦନ କରିଛନ୍ତି କି?",
              "as": "মই তোমালৈ কানপুৰৰ ব্যক্তিগত বিদ্যালয়ত খালী পদৰ বিষয়ে এটা বাৰ্তা প্ৰেৰণ কৰিছিলোঁ। তুমি তাত আবেদন কৰিছিলানে?",
              "gu": "મેં તને કાનપુરની ખાનગી શાળામાં શરૂ થવા વિશે સંદેશ મોકલ્યો હતો. શું તે ત્યાં અરજી કરી હતી?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "No, not yet.",
              "hi": "नहीं अभी तक नहीं।",
              "ka": "ಇಲ್ಲ, ಇದುವರೆಗೂ ಇಲ್ಲ.",
              "te": "లేదు ఇంకా చేయలేదు.",
              "or": "ନା ଏ ପର୍ଯନ୍ତ ନୁହଁ।",
              "as": "নাই, এতিয়ালৈকে কৰা নাই।",
              "gu": "ના હમણાં નહિ."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_6////",
              "hi": "B1_3_6 /////////",
              "ka": "B1_3_6////",
              "te": "B1_3_6////",
              "or": "B1_3_6////",
              "as": "B1_3_6////",
              "gu": "B1_3_6////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Madhav, how long will you wait for the UPTET exam dates to be announced? I know you want to work in a government school, but the process takes a very long time.",
              "hi": "माधव, तुम यूपीटीईटी परीक्षा की तारीखों की घोषणा की प्रतीक्षा कब तक करोगे? मुझे पता है कि तुम एक सरकारी स्कूल में काम करना चाहते हो, लेकिन प्रक्रिया में बहुत लंबा समय लगता है।",
              "ka": "ಮಾಧವ್, UPTET ಪರೀಕ್ಷೆಯ ದಿನಾಂಕಗಳನ್ನು ಪ್ರಕಟಿಸುವ ಬಗ್ಗೆ ಎಷ್ಟು ದಿನ ಕಾಯುತ್ತೀಯಾ? ನೀನು ಸರ್ಕಾರಿ ಶಾಲೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಬಯಸುತ್ತೀಯಾ ಎಂದು ನನಗೆ ತಿಳಿದಿದೆ, ಆದರೆ ಆ ಪ್ರಕ್ರಿಯೆಯು ಬಹಳ ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ.",
              "te": "మాధవ్, UPTET పరీక్ష తేదీల ప్రకటన కోసం నువ్వు ఎంతకాలం వేచి ఉంటావు? నువ్వు ప్రభుత్వ పాఠశాలలో పని చేయాలనుకుంటున్నావని నాకు తెలుసు, కానీ ఆ ప్రక్రియ చాలా సమయం పడుతుంది.",
              "or": "ମାଧବ, UPTET ପରୀକ୍ଷା ତାରିଖ ଘୋଷଣା ହେବା ପର୍ଯ୍ୟନ୍ତ ଆପଣ\n  କେତେ ସମୟ ଅପେକ୍ଷା କରିବେ? ମୁଁ ଜାଣେ ଆପଣ ଏକ ସରକାରୀ ବିଦ୍ୟାଳୟରେ କାମ କରିବାକୁ ଚାହୁଁଛନ୍ତି, କିନ୍ତୁ ପ୍ରକ୍ରିୟା ବହୁତ ସମୟ ନେଇଥାଏ |",
              "as": "মাধৱ, তুমি UPTET পৰীক্ষাৰ তাৰিখ ঘোষণা হোৱালৈ কিমান দিন অপেক্ষা কৰিবা? মই জানো তুমি এখন চৰকাৰী বিদ্যালয়ত কাম কৰিব বিচাৰা, কিন্তু প্ৰক্ৰিয়াটোত যথেষ্ট দীঘলীয়া সময় লাগিব।",
              "gu": "માધવ, UPTET પરીક્ષાની તારીખો જાહેર થવાની તું ક્યાં સુધી રાહ જોઈશ? હું જાણું છું કે તું સરકારી શાળામાં કામ કરવા માંગો છે, પરંતુ પ્રક્રિયામાં ઘણો સમય લાગે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Private schools have good facilities for teachers, Madhav. With your experience, you can apply to the best international schools in the state.",
              "hi": "निजी स्कूलों में,  शिक्षकों के लिए अच्छी सुविधाएं हैं माधव। अपने अनुभव के साथ, आप राज्य के सर्वश्रेष्ठ अंतरराष्ट्रीय स्कूलों पर आवेदन कर सकते हैं।",
              "ka": "ಖಾಸಗಿ ಶಾಲೆಗಳಲ್ಲಿ ಶಿಕ್ಷಕರಿಗೆ ಉತ್ತಮ ಸೌಲಭ್ಯಗಳಿವೆ, ಮಾಧವ್. ನಿನ್ನ ಅನುಭವದೊಂದಿಗೆ, ನೀನು ರಾಜ್ಯದ ಅತ್ಯುತ್ತಮ ಅಂತರರಾಷ್ಟ್ರೀಯ ಶಾಲೆಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಬಹುದು.",
              "te": "ప్రైవేట్ పాఠశాలల్లో ఉపాధ్యాయులకు మంచి సౌకర్యాలు ఉన్నాయి మాధవ్. నీకు ఉన్న అనుభవంతో, నువ్వు రాష్ట్రంలోని ఉత్తమ అంతర్జాతీయ పాఠశాలలకు అప్లై చేసుకోవచ్చు.",
              "or": "ବେସରକାରୀ ବିଦ୍ୟାଳୟଗୁଡ଼ିକରେ ଶିକ୍ଷକମାନଙ୍କ ପାଇଁ ଭଲ ସୁବିଧା ଅଛି\n , ମାଧବ | ଆପଣଙ୍କ ଅଭିଜ୍ଞତା ସହିତ ଆପଣ ରାଜ୍ୟର ସର୍ବୋତ୍ତମ ଆନ୍ତର୍ଜାତୀୟ ବିଦ୍ୟାଳୟରେ ଆବେଦନ କରିପାରିବେ |",
              "as": "বেচৰকাৰী বিদ্যালয়সমূহত শিক্ষকৰ বাবে ভাল সুবিধা আছে, মাধৱ। তোমাৰ অভিজ্ঞতাৰ সৈতে, তুমি ৰাজ্যখনৰ সৰ্বশ্ৰেষ্ঠ আন্তঃৰাষ্ট্ৰীয় বিদ্যালয়ত আবেদন কৰিব পাৰা।",
              "gu": "ખાનગી શાળાઓમાં શિક્ષકો માટે સારી સુવિધા છે, માધવ. તારા અનુભવ સાથે, તું રાજ્યની શ્રેષ્ઠ આંતરરાષ્ટ્રીય શાળાઓમાં અરજી કરી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Hmm, I know, and I do need the money. Besides, if I apply to a government school and get posted in some village far away, I wouldn't be able to meet you both.",
              "hi": "हम्म, मुझे पता है, और मुझे पैसे की जरूरत है। इसके अलावा, अगर मैं सरकारी स्कूल में आवेदन करता हूं और दूर किसी गांव में भेजा जाता हूं, तो मैं तुम दोनों से नहीं मिल पाऊंगा।",
              "ka": "ಹುಂ , ನನಗೆ ಗೊತ್ತು, ಮತ್ತು ನನಗೆ ಹಣದ ಅಗತ್ಯತೆ ಇದೆ. ಅದಲ್ಲದೆ, ನಾನು ಸರ್ಕಾರಿ ಶಾಲೆಗೆ ಅರ್ಜಿ ಹಾಕಿ ದೂರದ ಯಾವುದೋ ಹಳ್ಳಿಗೆ ಹಾಕ್ಕಿದಲ್ಲಿ, ನಿಮ್ಮಿಬ್ಬರನ್ನು ಭೇಟಿ ಮಾಡಲು ನನಗೆ ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ.",
              "te": "హా, నాకు తెలుసు మరియు నాకు డబ్బు కూడా కావాలి. అదీకాక, నేను గవర్నమెంటు స్కూల్లో అప్లై చేసి, దూరంగా ఏదో ఒక ఊరిలో పోస్టింగ్ పడితే, మీ ఇద్దరినీ కలవడం కుదరదు.",
              "or": "ହଁ , ମୁଁ ଜାଣେ, ଏବଂ ମୋର ଟଙ୍କା ଦରକାର | ଏହା ବ୍ୟତୀତ, ଯଦି ମୁଁ ଏକ \n ସରକାରୀ ବିଦ୍ୟାଳୟରେ ଆବେଦନ କରେ ଏବଂ କିଛି ଗାଁରେ ପୋଷ୍ଟ ହୁଏ, ତେବେ ମୁଁ ତୁମ ଦୁହିଁଙ୍କୁ ଭେଟିବାକୁ ସମର୍ଥ ହେବି ନାହିଁ |",
              "as": "হুমম, মই জানো, আৰু মোক টকাৰ প্ৰয়োজন। ইয়াৰ উপৰিও, যদি মই এখন চৰকাৰী বিদ্যালয়ত আবেদন কৰোঁ আৰু দূৰৈৰ কোনো গাঁৱত নিযুক্তি পাওঁ, মই তোমালোক দুয়োকে লগ কৰিব নোৱাৰা হ’ম।",
              "gu": "હમ્મ, હું જાણું છું અને મને પૈસાની જરૂર છે. આ ઉપરાંત, જો હું સરકારી શાળામાં અરજી કરું અને દૂરના ગામમાં પોસ્ટિંગ કરું, તો હું તમને બંનેને મળી શકીશ નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "See Madhav, don't worry about that. You decided to become a teacher for a reason. So it's up to you, whether you want to teach in a government school or private school. At the end of the day, you have to like what you do.",
              "hi": "माधव देखो, इसके बारे में चिंता मत करो। तुमने एक कारण से शिक्षक बनने का फैसला किया। तो यह तुम पर निर्भर है, तुम एक सरकारी स्कूल में पढ़ाना चाहते हो या निजी स्कूल में। अंत में, तुमको जो करना है उसे पसंद करना होगा।",
              "ka": "ನೋಡು ಮಾಧವ್, ಅದರ ಬಗ್ಗೆ ಚಿಂತಿಸಬೇಡ. ನೀನು ಒಂದು ಕಾರಣಕ್ಕಾಗಿ ಶಿಕ್ಷಕರಾಗಲು ನಿರ್ಧರಿಸಿದ್ದೀಯಾ. ಆದ್ದರಿಂದ ನೀನು ಸರ್ಕಾರಿ ಶಾಲೆಯಲ್ಲಿ ಅಥವಾ ಖಾಸಗಿ ಶಾಲೆಯಲ್ಲಿ ಕಲಿಸಬೇಕೆ ಎನ್ನುವುದು ನಿಮಗೆ ಬಿಟ್ಟದ್ದು. ದಿನದ ಕೊನೆಯಲ್ಲಿ, ನೀನು ಮಾಡುವ ಕೆಲಸವನ್ನು ನೀನು ಇಷ್ಟಪಡಬೇಕು.",
              "te": "చూడు మాధవ్, దాని గురించి చింతించకు. నువ్వు ఒక కారణం కోసం టీచర్ కావాలని నిర్ణయించుకున్నావు. కాబట్టి నువ్వు ప్రభుత్వ పాఠశాలలో లేదా ప్రైవేట్ పాఠశాలలో బోధించాలనుకుంటున్నావా అనేది నీ ఇష్టం. చివరికి మాత్రం, నువ్వు చేసే పనిని ఇష్టపడాలి అంతే.",
              "or": "ମାଧବ ଦେଖନ୍ତୁ, ସେ ବିଷୟରେ ଚିନ୍ତା କର ନାହିଁ | ଆପଣକୌଣସି \n କାରଣରୁ ଶିକ୍ଷକ ହେବାକୁ ସ୍ଥିର କଲେ | ତେଣୁ ଆପଣ ଏକ ସରକାରୀ ବିଦ୍ୟାଳୟରେ କିମ୍ବା ଘରୋଇ ବିଦ୍ୟାଳୟରେ ଶିକ୍ଷାଦାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ନାହିଁ ତାହା ଆପଣଙ୍କ ଉପରେ ନିର୍ଭର କରେ | ଦିନର ଶେଷରେ, ତୁମେ ଯାହା କରୁଛ ତାହା ତୁମକୁ ପସନ୍ଦ କରିବାକୁ ପଡିବ |",
              "as": "চোৱা মাধৱ, সেই বিষয়ে চিন্তা নকৰিবা। তুমি কাৰণএটাতহে শিক্ষক হোৱাৰ সিদ্ধান্ত লৈছা। গতিকে এইটো তোমাৰ ওপৰত নিৰ্ভৰ কৰিব, তুমি চৰকাৰী বিদ্যালয় নে ব্যক্তিগত বিদ্যালয়ত শিক্ষকতা কৰিব বিচাৰা। দিনটোৰ শেষত, তুমি যি কৰা তুমি ভাল পাব লাগিব।",
              "gu": "જો માધવ, એની ચિંતા ના કર. તું એક કારણસર શિક્ષક બનવાનું નક્કી કર્યું. તેથી તે તારા પર નિર્ભર છે, તું સરકારી શાળામાં ભણાવવા માંગે છે કે ખાનગી શાળામાં. દિવસના અંતે, તું જે કરે છે તે તને ગમાડવું પડશે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_6_D",
              "hi": "B1_3_6_d",
              "ka": "B1_3_6_D",
              "te": "B1_3_6_D",
              "or": "B1_3_6_D",
              "as": "B1_3_6_D",
              "gu": "B1_3_6_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "Where should Madhav apply?",
              "hi": "माधव को कहां आवेदन करना चाहिए?",
              "ka": "ಮಾಧವ್ ಎಲ್ಲಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಬೇಕು?",
              "te": "మాధవ్ ఎక్కడ అప్లై చేయాలి?",
              "or": "ମାଧବ କେଉଁଠାରେ ଆବେଦନ କରିବେ?",
              "as": "মাধৱে ক'ত আবেদন কৰা উচিত?",
              "gu": "માધવે ક્યાં અરજી કરવી જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Apply at a Government School",
              "hi": "एक सरकारी स्कूल में आवेदन करे",
              "ka": "ಸರ್ಕಾರಿ ಶಾಲೆಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದು",
              "te": "ప్రభుత్వ పాఠశాలలో అప్లై చేసుకోవాలి",
              "or": "ଏକ ସରକାରୀ ବିଦ୍ୟାଳୟରେ ଆବେଦନ କରନ୍ତୁ |",
              "as": "চৰকাৰী বিদ্যালয় এখনত আবেদন কৰিব",
              "gu": "સરકારી શાળામાં અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Apply at a Private School",
              "hi": "एक निजी स्कूल में आवेदन करे",
              "ka": "ಖಾಸಗಿ ಶಾಲೆಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದು",
              "te": "ప్రైవేట్ స్కూల్లో అప్లై చేసుకోవాలి",
              "or": "ଏକ ଘରୋଇ ବିଦ୍ୟାଳୟରେ ଆବେଦନ କରନ୍ତୁ |",
              "as": "ব্যক্তিগত বিদ্যালয় এখনত আবেদন কৰিব",
              "gu": "ખાનગી શાળામાં અરજી કરો"
            }
          }
        ]
      },
      "scene22": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Apply at a Government School",
              "hi": "एक सरकारी स्कूल में आवेदन करे",
              "ka": "ಸರ್ಕಾರಿ ಶಾಲೆಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದು",
              "te": "ప్రభుత్వ పాఠశాలలో అప్లై చేసుకోవాలి",
              "or": "ଏକ ସରକାରୀ ବିଦ୍ୟାଳୟରେ ଆବେଦନ କରନ୍ତୁ |",
              "as": "চৰকাৰী বিদ্যালয় এখনত আবেদন",
              "gu": "સરકારી શાળામાં અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav applies for the UPTET exam. It is a state-level entrance exam for the posts of teachers at various schools in Uttar Pradesh.",
              "hi": "माधव यूपीटीईटी  परीक्षा के लिए आवेदन करता है। यह उत्तर प्रदेश के विभिन्न स्कूलों में शिक्षकों के पदों के लिए एक राज्य स्तरीय प्रवेश परीक्षा है।",
              "ka": "ಮಾಧವ್ UPTET ಪರೀಕ್ಷೆಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ್ದಾನೆ. ಇದು ಉತ್ತರ ಪ್ರದೇಶದ ವಿವಿಧ ಶಾಲೆಗಳಲ್ಲಿ ಶಿಕ್ಷಕರ ಹುದ್ದೆಗಳಿಗೆ ರಾಜ್ಯ ಮಟ್ಟದ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಯಾಗಿದೆ.",
              "te": "మాధవ్ UPTET పరీక్షకు అప్లై చేసుకున్నాడు. ఇది ఉత్తరప్రదేశ్‌లోని వివిధ పాఠశాలల్లో ఉపాధ్యాయుల పోస్టుల కోసం రాష్ట్ర స్థాయి ప్రవేశ పరీక్ష.",
              "or": "UPTET ପରୀକ୍ଷା ପାଇଁ ମାଧବ ଆବେଦନ କରନ୍ତି | ଉତ୍ତରପ୍ରଦେଶର \n ବିଭିନ୍ନ ବିଦ୍ୟାଳୟରେ ଶିକ୍ଷକ ପଦବୀ ପାଇଁ ଏହା ଏକ ରାଜ୍ୟ ସ୍ତରୀୟ ପ୍ରବେଶିକା ପରୀକ୍ଷା।",
              "as": "মাধৱে UPTET পৰীক্ষাৰ বাবে আবেদন কৰে। উত্তৰ প্ৰদেশৰ বিভিন্ন বিদ্যালয়ত শিক্ষকৰ পদৰ বাবে ই এক ৰাজ্যিক পৰ্যায়ৰ প্ৰৱেশ পৰীক্ষা।",
              "gu": "માધવ UPTET પરીક્ષા માટે અરજી કરે છે. તે ઉત્તર પ્રદેશની વિવિધ શાળાઓમાં શિક્ષકોની જગ્યાઓ માટે રાજ્ય-સ્તરની પ્રવેશ પરીક્ષા છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_2",
              "hi": "B2_1_2",
              "ka": "B2_1_2",
              "te": "B2_1_2",
              "or": "B2_1_2",
              "as": "B2_1_2",
              "gu": "B2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The UPSC preparations help Madhav clear the UPTET exams with a good ranking.",
              "hi": "यूपीएससी की तैयारी माधव को एक अच्छी रैंकिंग के साथ यूपीटीईटी परीक्षा को उत्तीर्ण करने में सहायता करती है।",
              "ka": "UPSC ಸಿದ್ಧತೆಗಳು ಮಾಧವ್ UPTET ಪರೀಕ್ಷೆಗಳನ್ನು ಉತ್ತಮ ಶ್ರೇಯಾಂಕದೊಂದಿಗೆ ತೇರ್ಗಡೆಗೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತವೆ.",
              "te": "UPSC సన్నాహాలు మాధవ్‌కి UPTET పరీక్షలను మంచి ర్యాంకింగ్‌తో క్లియర్ చేయడంలో సహాయపడతాయి.",
              "or": "UPSC ପ୍ରସ୍ତୁତି ମାଧବଙ୍କୁ ଏକ ଭଲ ର୍ୟାଙ୍କିଙ୍ଗ୍ ସହିତ CTET ପରୀକ୍ଷା \n ପାସ କରିବାରେ ସାହାଯ୍ୟ କରେ |",
              "as": "UPSCৰ বাবে কৰা প্ৰস্তুতিয়ে মাধৱক ভাল ৰেংকিঙৰ সৈতে UPTET পৰীক্ষাত উত্তীৰ্ণ হোৱাত সহায় কৰে।",
              "gu": "UPSC ની તૈયારીઓ માધવને UPTET પરીક્ષામાં સારા ગુણ સાથે પાસ કરવામાં મદદ કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_3",
              "hi": "B2_1_3",
              "ka": "B2_1_3",
              "te": "B2_1_3",
              "or": "B2_1_3",
              "as": "B2_1_3",
              "gu": "B2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is excited and looking forward to his posting. During the counselling, he informs the Department of Education that he wants to work in a school in the rural districts of UP.",
              "hi": "माधव उत्साहित है और अपनी पोस्टिंग की प्रतीक्षा कर रहा है। परामर्श के दौरान, वह शिक्षा विभाग को सूचित करता है कि वह ग्रामीण जिलों में एक स्कूल में काम करना चाहता है।",
              "ka": "ಮಾಧವ್ ಉತ್ಸುಕನಾಗಿದ್ದಾನೆ ಮತ್ತು ಅವರ ಪೋಸ್ಟ್‌ಗಾಗಿ ಎದುರು ನೋಡುತ್ತಿದ್ದಾನೆ. ಕೌನ್ಸೆಲಿಂಗ್ ಸಮಯದಲ್ಲಿ, ಅವನು ಉತ್ತರ ಪ್ರದೇಶದ ಗ್ರಾಮಾಂತರ ಜಿಲ್ಲೆಗಳಲ್ಲಿನ ಶಾಲೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಬಯಸುತ್ತಾನೆ ಎಂದು ಶಿಕ್ಷಣ ಇಲಾಖೆಗೆ ತಿಳಿಸುತ್ತಾನೆ",
              "te": "మాధవ్ ఉత్సాహంగా అతని పోస్టింగ్ కోసం ఎదురు చూస్తున్నాడు. కౌన్సెలింగ్ సమయంలో, అతను UP గ్రామీణ జిల్లాల్లోని పాఠశాలలో పనిచేయాలనుకుంటున్నట్లు విద్యాశాఖకు తెలియజేస్తాడు.",
              "or": "ମାଧବ ଉତ୍ସାହିତ ଅଛନ୍ତି ଏବଂ ତାଙ୍କ ପୋଷ୍ଟିଂକୁ ଅପେକ୍ଷା କରିଛନ୍ତି | କାଉନସେଲିଂ \n ସମୟରେ ସେ ଶିକ୍ଷା ବିଭାଗକୁ ସୂଚନା ଦେଇଛନ୍ତି ଯେ ସେ ୟୁପିର ଗ୍ରାମାଞ୍ଚଳର ଏକ ବିଦ୍ୟାଳୟରେ କାମ କରିବାକୁ ଚାହୁଁଛନ୍ତି।",
              "as": "মাধৱ উত্তেজিত আৰু তাৰ নিয়োগৰ বাবে অপেক্ষা কৰি আছে। পৰামৰ্শৰ সময়ত, তেওঁ শিক্ষা বিভাগক জনায় যে তেওঁ উত্তৰ প্ৰদেশৰ গ্ৰাম্য জিলাৰ এখন বিদ্যালয়ত কাম কৰিব বিচাৰে।",
              "gu": "માધવ ઉત્સાહિત છે અને તેની પોસ્ટની રાહ જોઈ રહ્યો છે. કાઉન્સેલિંગ દરમિયાન, તે શિક્ષણ વિભાગને જાણ કરે છે કે તે યુપીના ગ્રામીણ જિલ્લાઓમાં એક શાળામાં કામ કરવા માંગે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_2_1",
              "hi": "B2_2_1",
              "ka": "B2_2_1",
              "te": "B2_2_1",
              "or": "B2_2_1",
              "as": "B2_2_1",
              "gu": "B2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Soon, Madhav gets posted to a high school in the Shamili district as a history teacher. The Headteacher there is very motivated to make it the best school in the district.",
              "hi": "जल्द ही, माधव को एक इतिहास शिक्षक के रूप में शामली जिले के एक हाई स्कूल में पोस्ट किया जाता है। प्रधानाध्यापक इसे जिले में सबसे अच्छा स्कूल बनाने के लिए बहुत प्रेरित है।",
              "ka": "ಶೀಘ್ರದಲ್ಲೇ, ಮಾಧವ್ ಶಾಮಿಲಿ ಜಿಲ್ಲೆಯ ಒಂದು ಪ್ರೌಢಶಾಲೆಗೆ ಇತಿಹಾಸ ಶಿಕ್ಷಕನಾಗಿ ನೇಮಕಗೊಳ್ಳುತ್ತಾನೆ. ಅಲ್ಲಿನ ಮುಖ್ಯೋಪಾಧ್ಯಾಯರು ಅದು ಜಿಲ್ಲೆಯ ಅತ್ಯುತ್ತಮ ಶಾಲೆಯಾಗಲು ಬಹಳ ಪ್ರೇರಣೆ ನೀಡಿದ್ದಾರೆ.",
              "te": "త్వరలో, మాధవ్‌ షామిలి జిల్లాలోని ఒక ఉన్నత పాఠశాలలో హిస్టరీ టీచర్‌గా నియమించబడతాడు. అక్కడి ప్రధానోపాధ్యాయుడు దానిని జిల్లాలోనే అత్యుత్తమ పాఠశాలగా తీర్చిదిద్దేందుకు పట్టుదలతో ఉన్నాడు.",
              "or": "ଖୁବ୍ ଶୀଘ୍ର, ମାଧବ ଶାମିଲି ଜିଲ୍ଲାର ଏକ ଉଚ୍ଚ ବିଦ୍ୟାଳୟରେ ଇତିହାସ\n  ଶିକ୍ଷକ ଭାବରେ ପୋଷ୍ଟ ହୁଅନ୍ତି | ସେଠାରେ ଥିବା ପ୍ରଧାନଶିକ୍ଷକ ଏହାକୁ ଜିଲ୍ଲାର ସର୍ବୋତ୍ତମ ବିଦ୍ୟାଳୟ କରିବା ପାଇଁ ବହୁତ ଉତ୍ସାହିତ |",
              "as": "অতি সোনকালেই, মাধৱে ইতিহাসৰ শিক্ষক হিচাপে ছামিলি জিলাৰ এখন উচ্চ বিদ্যালয়ত নিযুক্তি লাভ কৰে। তাৰ প্ৰধান শিক্ষকজনে ইয়াক জিলাখনৰ এখন সৰ্বশ্ৰেষ্ঠ বিদ্যালয় হিচাপে গঢ়ি তুলিবলৈ বহুত অনুপ্ৰাণিত।",
              "gu": "ટૂંક સમયમાં, માધવને શામીલી જિલ્લાની એક હાઈસ્કૂલમાં ઈતિહાસ શિક્ષક તરીકે પદ મળે છે. ત્યાંના મુખ્ય શિક્ષક તેને જિલ્લાની શ્રેષ્ઠ શાળા બનાવવા માટે ખૂબ જ પ્રેરિત છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_2_2///",
              "hi": "B2_2_2 ////",
              "ka": "B2_2_2///",
              "te": "B2_2_2///",
              "or": "B2_2_2///",
              "as": "B2_2_2///",
              "gu": "B2_2_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Within a few months, Madhav builds a good rapport with his students. They like him because he is sensitive to their needs and is always willing to help them out.",
              "hi": "कुछ महीनों के भीतर, माधव अपने छात्रों के साथ एक अच्छा संबंध बनाता है। वे उसे पसंद करते हैं क्योंकि वह उनकी जरूरतों के प्रति संवेदनशील है और हमेशा उनकी सहायता करने के लिए तैयार है।",
              "ka": "ಕೆಲವೇ ತಿಂಗಳುಗಳಲ್ಲಿ, ಮಾಧವ್ ತನ್ನ ವಿದ್ಯಾರ್ಥಿಗಳೊಂದಿಗೆ ಉತ್ತಮ ಬಾಂಧವ್ಯವನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳುತ್ತಾನೆ. ಅವರು ಅವನನ್ನು ಇಷ್ಟಪಡುತ್ತಾರೆ ಏಕೆಂದರೆ ಅವನು ತಮ್ಮ ಅಗತ್ಯತೆಗಳಿಗೆ ಸಂವೇದಿಸುತ್ತಾನೆ  ಮತ್ತು ಅವರಿಗೆ ಸಹಾಯ ಮಾಡಲು ಯಾವಾಗಲೂ ಸಿದ್ಧವಾಗಿರುತ್ತಾನೆ",
              "te": "కొన్ని నెలల్లోనే, మాధవ్ తన విద్యార్థులతో మంచి అనుబంధాన్ని ఏర్పరచుకున్నాడు. అతను వారి అవసరాలు తీర్చడం మరియు వారికి సహాయం చేయడానికి ఎల్లప్పుడూ సిద్ధంగా ఉండడం వల్ల అందరూ అతన్ని ఇష్టపడతారు.",
              "or": "କିଛି ମାସ ମଧ୍ୟରେ, ମାଧବ ତାଙ୍କ ଛାତ୍ରମାନଙ୍କ ସହିତ ଏକ ଭଲ ସମ୍ପର୍କ\n  ସ୍ଥାପନ କରନ୍ତି | ସେମାନେ ତାଙ୍କୁ ପସନ୍ଦ କରନ୍ତି କାରଣ ସେ ସେମାନଙ୍କର ଆବଶ୍ୟକତା ପ୍ରତି ସମ୍ବେଦନଶୀଳ ଏବଂ ସେମାନଙ୍କୁ ସାହାଯ୍ୟ କରିବାକୁ ସର୍ବଦା ପ୍ରସ୍ତୁତ |",
              "as": "কেইমাহমানৰ ভিতৰতে, মাধৱে তেওঁৰ শিক্ষাৰ্থীসকলৰ সৈতে এক ভাল সম্পৰ্ক গঢ়ি তোলে। সিহঁতে তেওঁক ভাল পায় কাৰণ তেওঁ সিহঁতৰ প্ৰয়োজনীয়তাৰ প্ৰতি সংবেদনশীল আৰু তেওঁলোকক সহায় কৰিবলৈ সদায় ইচ্ছুক।",
              "gu": "થોડા મહિનામાં, માધવ તેના વિદ્યાર્થીઓ સાથે સારો તાલમેલ બનાવી લે છે. તે તેને પસંદ કરે છે કારણ કે તે તેની જરૂરિયાતો પ્રત્યે સંવેદનશીલ છે અને હંમેશા તેને મદદ કરવા તૈયાર છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_2_3",
              "hi": "B2_2_3",
              "ka": "B2_2_3",
              "te": "B2_2_3",
              "or": "B2_2_3",
              "as": "B2_2_3",
              "gu": "B2_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is looking forward to the quarterly meeting with the Headteacher. Madhav has been working with other teachers to come up with points to discuss in this meeting.",
              "hi": "माधव प्रधानाध्यापक के साथ तिमाही बैठक की प्रतीक्षा कर रहे हैं। माधव इस बैठक में चर्चा करने के लिए अन्य शिक्षकों के साथ काम कर रहे हैं।",
              "ka": "ಮಾಧವ್ ಮುಖ್ಯಶಿಕ್ಷಕರೊಂದಿಗಿನ ತ್ರೈಮಾಸಿಕ ಸಭೆಯನ್ನು ಎದುರು ನೋಡುತ್ತಿದ್ದಾನೆ. ಈ ಸಭೆಯಲ್ಲಿ ಚರ್ಚಿಸಲು ಅಂಶಗಳನ್ನು ಮಂಡಿಸಲು ಮಾಧವ್ ಇತರ ಶಿಕ್ಷಕರೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾನೆ",
              "te": "హెడ్‌టీచర్‌తో త్రైమాసిక సమావేశం కోసం మాధవ్ ఎదురు చూస్తున్నాడు. ఈ సమావేశంలో చర్చించాల్సిన పాయింట్‌లను రూపొందించడానికి మాధవ్ ఇతర ఉపాధ్యాయులతో కలిసి పని చేస్తున్నారు.",
              "or": "ପ୍ରଧାନ ଶିକ୍ଷକଙ୍କ ସହ ତ୍ରୟୀମାସିକ ବାଇଠକକୁ ମାଧବ\n  ଅପେକ୍ଷା କରିଛନ୍ତି | ଏହି ସଭାରେ ଆଲୋଚନା ପାଇଁ ପଏଣ୍ଟ ଆଣିବା ପାଇଁ ମାଧବ ଅନ୍ୟ ଶିକ୍ଷକମାନଙ୍କ ସହ କାର୍ଯ୍ୟ କରୁଛନ୍ତି |",
              "as": "মাধৱে প্ৰধান শিক্ষকৰ সৈতে তিনিমহীয়া বৈঠকৰ বাবে অপেক্ষা কৰি আছে। মাধৱে এই সভাত আলোচনা কৰিবলৈ আন শিক্ষকসকলৰ সৈতে কাম কৰি কিছুমান পইণ্ট সাজু কৰিছে।",
              "gu": "માધવ મુખ્ય શિક્ષક સાથે ત્રિમાસિક બેઠકની રાહ જોઈ રહ્યો છે. માધવ આ મીટિંગમાં ચર્ચા કરવા માટેના મુદ્દાઓ સાથે આવવા માટે અન્ય શિક્ષકો સાથે કામ કરી રહ્યો છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_3_1",
              "hi": "B2_3_1",
              "ka": "B2_3_1",
              "te": "B2_3_1",
              "or": "B2_3_1",
              "as": "B2_3_1",
              "gu": "B2_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They all agree that the school has to improve on the reading skills and also in co-curricular activities.",
              "hi": "वे सभी इस बात से सहमत हैं कि स्कूल को पढ़ने के कौशल और सह-पाठ्यचर्या गतिविधियों में भी सुधारना है।",
              "ka": "ಶಾಲೆಯು ಓದುವ ಕೌಶಲ್ಯ ಮತ್ತು ಸಹಪಠ್ಯ ಚಟುವಟಿಕೆಗಳಲ್ಲಿ ಸುಧಾರಿಸಬೇಕೆಂದು ಅವರೆಲ್ಲರೂ ಒಪ್ಪುತ್ತಾರೆ.",
              "te": "పాఠశాల రీడింగ్ స్కిల్స్ మరియు కో-కరిక్యులర్ యాక్టివిటీస్ లో కూడా మెరుగుపడాలని వారు అందరూ అంగీకరిస్తారు.",
              "or": "ସେମାନେ ସମସ୍ତେ ସହମତ ଅଟନ୍ତି ଯେ ବିଦ୍ୟାଳୟରେ ପଢିବା କୌଶଳ\n  ଏବଂ ସହ-ପାଠ୍ୟକ୍ରମରେ ଉନ୍ନତି ଆଣିବାକୁ ପଡିବ |",
              "as": "তেওঁলোক সকলোৱে সন্মত হয় যে বিদ্যালয়খনে পঢ়াৰ দক্ষতা আৰু সহ-পাঠ্যক্ৰমৰ কাৰ্যকলাপবোৰ উন্নত কৰিব লাগিব।",
              "gu": "તેઓ બધા સંમત છે કે શાળાએ વાંચન કૌશલ્ય અને સહ-અભ્યાસિક પ્રવૃત્તિઓમાં પણ સુધારો કરવો પડશે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_3_2",
              "hi": "B2_3_2",
              "ka": "B2_3_2",
              "te": "B2_3_2",
              "or": "B2_3_2",
              "as": "B2_3_2",
              "gu": "B2_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He is happy to be working in a school where all the teachers support each other, and all of them are working towards the common goal of good education for children.",
              "hi": "वह स्कूल में काम करने में प्रसन्न है जहां सभी शिक्षक एक-दूसरे का सहयोग करते हैं, और वे सभी बच्चों के लिए अच्छी शिक्षा के सामान्य लक्ष्य की दिशा में काम कर रहे हैं।",
              "ka": "ಎಲ್ಲ ಶಿಕ್ಷಕರು ಪರಸ್ಪರ ಆಸರೆಯಾಗಿರುವ ಶಾಲೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿರುವುದಕ್ಕೆ ಸಂತಸ ವ್ಯಕ್ತಪಡಿಸಿದ್ದು, ಮಕ್ಕಳಿಗೆ ಉತ್ತಮ ಶಿಕ್ಷಣ ನೀಡಬೇಕೆಂಬ ಸಾಮಾನ್ಯ ಗುರಿಯತ್ತ ಎಲ್ಲರೂ ಶ್ರಮಿಸುತ್ತಿದ್ದಾರೆ.",
              "te": "ఉపాధ్యాయులందరూ ఒకరికొకరు తోడ్పడే పాఠశాలలో పని చేస్తున్నందుకు అతనికి ఆనందంగా ఉంది, పిల్లలందరికీ మంచి విద్య అనే లక్ష్యంతో వారందరూ పనిచేస్తున్నారు.",
              "or": "ସେ ଏକ ବିଦ୍ୟାଳୟରେ କାର୍ଯ୍ୟ କରି ଖୁସି, ଯେଉଁଠାରେ ସମସ୍ତ ଶିକ୍ଷକ\n  ପରସ୍ପରକୁ ସମର୍ଥନ କରନ୍ତି ଏବଂ ସମସ୍ତେ ପିଲାମାନଙ୍କ ପାଇଁ ଉତ୍ତମ ଶିକ୍ଷାର ସାଧାରଣ ଲକ୍ଷ୍ୟ ଦିଗରେ କାର୍ଯ୍ୟ କରୁଛନ୍ତି |",
              "as": "তেওঁ এনে এখন বিদ্যালয়ত কাম কৰি সুখী য'ত সকলো শিক্ষকে ইজনে সিজনক সমৰ্থন কৰে, আৰু তেওঁলোক সকলোৱে শিশুসকলৰ বাবে ভাল শিক্ষাৰ উমৈহতীয়া লক্ষ্যৰ দিশত কাম কৰি আছে।",
              "gu": "તે એવી શાળામાં કામ કરીને ખુશ છે જ્યાં તમામ શિક્ષકો એકબીજાને ટેકો આપે છે, અને તે બધા બાળકો માટે સારા શિક્ષણના સમાન ધ્યેય તરફ કામ કરી રહ્યા છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_3_3",
              "hi": "B2_3_3",
              "ka": "B2_3_3",
              "te": "B2_3_3",
              "or": "B2_3_3",
              "as": "B2_3_3",
              "gu": "B2_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The teachers decide that Madhav will present the points of school improvement to the Head Teacher. Madhav feels responsible for the school and the students.",
              "hi": "शिक्षक तय करते हैं कि माधव स्कूल सुधार के बिंदु प्रधानाध्यापक को प्रस्तुत करेंगे। माधव स्कूल और छात्रों के लिए जिम्मेदार अनुभव  करता है।",
              "ka": "ಶಾಲೆಯ ಸುಧಾರಣೆಯ ಅಂಶಗಳನ್ನು ಮಾಧವ್ ಮುಖ್ಯ ಶಿಕ್ಷಕರಿಗೆ ಪ್ರಸ್ತುತಪಡಿಸುತ್ತಾನೆ ಎಂದು ಶಿಕ್ಷಕರು ನಿರ್ಧರಿಸುತ್ತಾರೆ. ಮಾಧವ್ ಶಾಲೆ ಮತ್ತು ವಿದ್ಯಾರ್ಥಿಗಳ ಜವಾಬ್ದಾರಿಯನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "పాఠశాల మెరుగుదల పాయింట్లను మాధవ్ ప్రధాన ఉపాధ్యాయుడికి అందజేయాలని ఉపాధ్యాయులు నిర్ణయించారు. మాధవ్ పాఠశాల మరియు విద్యార్థుల పట్ల బాధ్యతగా ఉంటాడు.",
              "or": "ଶିକ୍ଷକମାନେ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି ଯେ ମାଧବ ପ୍ରଧାନ ଶିକ୍ଷକଙ୍କୁ ବିଦ୍ୟାଳୟର \n ଉନ୍ନତିର ପଏଣ୍ଟ ଉପସ୍ଥାପନ କରିବେ | ମାଧବ ବିଦ୍ୟାଳୟ ଏବଂ ଛାତ୍ରମାନଙ୍କ ପାଇଁ ଦାୟୀ ବୋଲି ଅନୁଭବ କରନ୍ତି |",
              "as": "শিক্ষকসকলে সিদ্ধান্ত লয় যে মাধৱে বিদ্যালয়ৰ উন্নতিৰ বিষয়বোৰ প্ৰধান শিক্ষকৰ ওচৰত উপস্থাপন কৰিব। মাধৱে বিদ্যালয় আৰু শিক্ষাৰ্থীসকলৰ বাবে দায়বদ্ধ অনুভৱ কৰে।",
              "gu": "શિક્ષકો નક્કી કરે છે કે માધવ શાળાના સુધારણાના મુદ્દાઓ મુખ્ય શિક્ષકને રજૂ કરશે. માધવ શાળા અને વિદ્યાર્થીઓ પ્રત્યે જવાબદારી અનુભવે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_3_4//",
              "hi": "B2_3_4 //",
              "ka": "B2_3_4//",
              "te": "B2_3_4//",
              "or": "B2_3_4//",
              "as": "B2_3_4//",
              "gu": "B2_3_4//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "During the meeting, Madhav presents the points on behalf of the other teachers.",
              "hi": "बैठक के दौरान, माधव अन्य शिक्षकों की ओर से बिंदु प्रस्तुत करता है।",
              "ka": "ಸಭೆಯಲ್ಲಿ, ಮಾಧವ್ ಇತರ ಶಿಕ್ಷಕರ ಪರವಾಗಿ ಅಂಶಗಳನ್ನು ಮಂಡಿಸುತ್ತಾನೆ.",
              "te": "సమావేశంలో, ఇతర ఉపాధ్యాయుల తరపున మాధవ్ పాయింట్లను సమర్పించారు.",
              "or": "ବଇଠକ ସମୟରେ ମାଧବ ଅନ୍ୟ ଶିକ୍ଷକମାନଙ୍କ ତରଫରୁ ପଏଣ୍ଟ\n  ଉପସ୍ଥାପନ କରନ୍ତି |",
              "as": "সভাৰ সময়ত, মাধৱে আন শিক্ষকসকলৰ হৈ বিষয়বোৰ উপস্থাপন কৰে।",
              "gu": "મીટિંગ દરમિયાન, માધવ અન્ય શિક્ષકો વતી મુદ્દાઓ રજૂ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Our school is doing very well in terms of students' academic performance in higher classes. However, we see some of the problems that we should address.",
              "hi": "हमारा स्कूल उच्च कक्षाओं में छात्रों के अकादमिक प्रदर्शन के मामले में बहुत अच्छा प्रदर्शन कर रहा है। हालाँकि, हम कुछ ऐसी समस्याओं को देखते हैं जिनका हमें समाधान करना चाहिए।",
              "ka": "ಉನ್ನತ ತರಗತಿಗಳಲ್ಲಿ ವಿದ್ಯಾರ್ಥಿಗಳ ಶೈಕ್ಷಣಿಕ ಸಾಧನೆಯ ದೃಷ್ಟಿಯಿಂದ ನಮ್ಮ ಶಾಲೆಯು ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಿದೆ. ಆದಾಗ್ಯೂ,  ಪರಿಹರಿಸಬೇಕಾದ ಕೆಲವು ಸಮಸ್ಯೆಗಳನ್ನು ನಾವು ವೀಕ್ಷಿಸಿದ್ದೇವೆ.",
              "te": "ఉన్నత తరగతుల విద్యార్థుల చదువు విషయంలో మన పాఠశాల బాగా రాణిస్తోంది. కానీ, మనం పరిష్కరించాల్సిన సమస్యలు కొన్ని ఉన్నాయి.",
              "or": "ଉଚ୍ଚ ବିଦ୍ୟାଳୟରେ ଛାତ୍ରମାନଙ୍କ ଏକାଡେମିକ୍ ପ୍ରଦର୍ଶନ ଦୃଷ୍ଟିରୁ ଆମ ବିଦ୍ୟାଳୟ \n ବହୁତ ଭଲ ପ୍ରଦର୍ଶନ କରୁଛି | ତଥାପି, ଆମେ କିଛି ସମସ୍ୟା ଦେଖୁ ଯାହାକୁ ଆମେ ସମାଧାନ କରିବା ଉଚିତ୍ |",
              "as": "উচ্চ শ্ৰেণীত শিক্ষাৰ্থীৰ শৈক্ষিক প্ৰদৰ্শনৰ ক্ষেত্ৰত আমাৰ বিদ্যালয়ে যথেষ্ট ভাল প্ৰদৰ্শন কৰি আছে। অৱশ্যে, আমি কিছুমান সমস্যা দেখিছোঁ যিবোৰ আমি সমাধান কৰা উচিত।",
              "gu": "અમારી શાળા ઉચ્ચ વર્ગોમાં વિદ્યાર્થીઓના શૈક્ષણિક પ્રદર્શનની દ્રષ્ટિએ ખૂબ જ સારું પ્રદર્શન કરી રહી છે. જો કે, આપણે કેટલીક સમસ્યાઓ જોઈએ છીએ જેને આપણે સંબોધિત કરવી જોઈએ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I would like to hear about them.",
              "hi": "मैं उनके बारे में सुनना चाहूंगा।",
              "ka": "ನಾನು ಅದರ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಬೇಕು",
              "te": "నేను వాటి గురించి వినాలనుకుంటున్నాను.",
              "or": "ମୁଁ ସେମାନଙ୍କ ବିଷୟରେ ଶୁଣିବାକୁ ଚାହେଁ |",
              "as": "মই সেইবোৰৰ বিষয়ে শুনিব বিচাৰোঁ।",
              "gu": "હું તેમના વિશે સાંભળવા માંગુ છું."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_3_5////",
              "hi": "B2_3_5 /////////////////////",
              "ka": "B2_3_5////",
              "te": "B2_3_5////",
              "or": "B2_3_5////",
              "as": "B2_3_5////",
              "gu": "B2_3_5////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Firstly, we realised that the students don't read a lot beyond their academic books. If we set up a library in the school, it will encourage students to spend time there and develop their reading habits. If we keep this library open as a space to read and learn, they might access it before and after school or even during breaks.",
              "hi": "सबसे पहले, हमने अनुभव किया कि छात्र अपनी अकादमिक किताबों से बहुत कुछ नहीं पढ़ते हैं। अगर हम स्कूल में एक पुस्तकालय स्थापित करते हैं, तो यह छात्रों को वहां समय बिताने और अपनी पढ़ाई की आदतों को विकसित करने के लिए प्रोत्साहित करेगा। यदि हम इस पुस्तकालय को पढ़ने और सीखने के लिए एक स्थान के रूप में खुला रखते हैं, तो वे स्कूल के पहले और बाद में ब्रेक के समय भी जा सकते हैं।",
              "ka": "ಮೊದಲನೆಯದಾಗಿ, ವಿದ್ಯಾರ್ಥಿಗಳು ತಮ್ಮ ಶೈಕ್ಷಣಿಕ ಪುಸ್ತಕಗಳನ್ನು ಮೀರಿ ಹೆಚ್ಚು ಓದುವುದಿಲ್ಲ ಎಂದು ನಾವು ಅರಿತುಕೊಂಡಿದ್ದೇವೆ. ನಾವು ಶಾಲೆಯಲ್ಲಿ ಗ್ರಂಥಾಲಯವನ್ನು ಸ್ಥಾಪಿಸಿದಲ್ಲಿ, ಅದು ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಅಲ್ಲಿ ಸಮಯ ಕಳೆಯಲು ಮತ್ತು ಅವರ ಓದುವ ಹವ್ಯಾಸವನ್ನು ಬೆಳೆಸಲು ಪ್ರೋತ್ಸಾಹಿಸುತ್ತದೆ. ನಾವು ಈ ಲೈಬ್ರರಿಯನ್ನು ಓದಲು ಮತ್ತು ಕಲಿಯಲು ಒಂದು ಸ್ಥಳವಾಗಿ ತೆರೆದಿದ್ದಲ್ಲಿ, ಅವರು ಶಾಲೆಯ ಮೊದಲು ಮತ್ತು ನಂತರ ಅಥವಾ ವಿರಾಮದ ಸಮಯದಲ್ಲಿ ಅದನ್ನು ಉಪಯೋಗಿಸಬಹುದು.",
              "te": "ముందుగా, విద్యార్థులు తమ అకడమిక్ పుస్తకాలను మించి ఎక్కువ చదవరని మేము గ్రహించాము. పాఠశాలలో లైబ్రరీని ఏర్పాటు చేస్తే విద్యార్థులు అక్కడ సమయాన్ని వెచ్చించి రీడింగ్ అలవాట్లను పెంపొందించుకునే అవకాశం ఉంటుంది. మనము ఈ లైబ్రరీని చదవడానికి మరియు నేర్చుకోవడానికి తెరిచి ఉంచినట్లయితే, వారు పాఠశాలకు ముందు మరియు తర్వాత లేదా విరామ సమయంలో కూడా దీన్ని యాక్సెస్ చేయవచ్చు.",
              "or": "ପ୍ରଥମତ ,, ଆମେ ହୃଦୟଙ୍ଗମ କରିଛୁ ଯେ ଛାତ୍ରମାନେ ସେମାନଙ୍କର ଏକାଡେମିକ୍\n  ବହିଠାରୁ ଅଧିକ ପ read ନ୍ତି ନାହିଁ | ଯଦି ଆମେ ବିଦ୍ୟାଳୟରେ ଏକ ଲାଇବ୍ରେରୀ ସ୍ଥାପନ କରୁ, ଏହା ଛାତ୍ରମାନଙ୍କୁ ସେଠାରେ ସମୟ ଅତିବାହିତ କରିବାକୁ ଏବଂ ସେମାନଙ୍କର ପ reading ଼ିବା ଅଭ୍ୟାସକୁ ଉତ୍ସାହିତ କରିବାକୁ ଉତ୍ସାହିତ କରିବ | ଯଦି ଆମେ ଏହି ଲାଇବ୍ରେରୀକୁ ପ read ିବା ଏବଂ ଶିଖିବା ପାଇଁ ଏକ ସ୍ଥାନ ଭାବରେ ଖୋଲା ରଖୁ, ସେମାନେ ହୁଏତ ବିଦ୍ୟାଳୟ ପୂର୍ବରୁ ଏବଂ ପରେ କିମ୍ବା ବିରତି ସମୟରେ ଏହାକୁ ପ୍ରବେଶ କରିପାରିବେ |",
              "as": "প্ৰথমতে, আমি অনুভৱ কৰিছিলোঁ যে শিক্ষাৰ্থীসকলে তেওঁলোকৰ শৈক্ষিক কিতাপবোৰৰ বাহিৰত বেছি পঢ়া-শুনা কৰা নাই। যদি আমি বিদ্যালয়ত এটা পুথিভঁৰাল স্থাপন কৰোঁ, ই শিক্ষাৰ্থীসকলক তাত সময় কটাবলৈ আৰু তেওঁলোকৰ পঢ়াৰ অভ্যাস বিকশিত কৰিবলৈ উৎসাহিত কৰিব। যদি আমি এই পুথিভঁৰালটো পঢ়া আৰু শিকাৰ স্থান হিচাপে খোলা ৰাখোঁ, তেওঁলোকে স্কুলৰ আগত আৰু পিছত বা বিৰতিৰ সময়তো ইয়াত প্ৰৱেশ কৰিব পাৰিব।",
              "gu": "સૌપ્રથમ, આપણને સમજાયું કે વિદ્યાર્થીઓ તેમના શૈક્ષણિક પુસ્તકોથી વધુ વાંચતા નથી. જો આપણે શાળામાં પુસ્તકાલય સ્થાપીશું, તો તે વિદ્યાર્થીઓને ત્યાં સમય પસાર કરવા અને તેમની વાંચનની ટેવ વિકસાવવા પ્રોત્સાહિત કરશે. જો આપણે આ પુસ્તકાલયને વાંચવા અને શીખવાની જગ્યા તરીકે ખુલ્લું રાખીએ, તો તેઓ તેને શાળા પહેલાં અને પછી અથવા વિરામ દરમિયાન પણ જોઈ શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "That sounds like a lovely idea, Madhav. I think this has come up before, but nobody was willing to oversee the organisation and maintenance of books and operation of the library.",
              "hi": "यह एक अच्छा विचार लगता है, माधव। मुझे लगता है कि यह पहले भी सामने आया है, लेकिन कोई भी संगठन और पुस्तकों के रखरखाव और पुस्तकालय के संचालन की देखरेख करने को तैयार नहीं था।",
              "ka": "ಅದೊಂದು ಸುಂದರ ಉಪಾಯ ಅನ್ನಿಸುತ್ತಿದೆ ಮಾಧವ್. ಇದು ಮೊದಲು ಕೂಡ ಬಂದಿತ್ತು ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ, ಆದರೆ ಪುಸ್ತಕಗಳ ಸಂಘಟನೆ ಮತ್ತು ನಿರ್ವಹಣೆ ಮತ್ತು ಗ್ರಂಥಾಲಯದ ಕಾರ್ಯಾಚರಣೆಯನ್ನು ಮೇಲ್ವಿಚಾರಣೆ ಮಾಡಲು ಯಾರೂ ಸಿದ್ಧರಿರಲಿಲ್ಲ.",
              "te": "అది మంచి ఆలోచనలా ఉంది మాధవ్. ఇది ఇంతకు ముందు కూడా వచ్చిందని నేను అనుకుంటున్నాను, కాని పుస్తకాల నిర్వహణ మరియు లైబ్రరీ నిర్వహణను పర్యవేక్షించడానికి ఎవరూ ఇష్టపడలేదు.",
              "or": "ଏହା ଏକ ସୁନ୍ଦର ଧାରଣା ପରି ଶୁଣାଯାଏ, ମାଧବ | ମୁଁ ଭାବୁଛି ଏହା ପୂର୍ବରୁ\n  ଆସିଛି, କିନ୍ତୁ ବହିର ସଂଗଠନର ପରିଚାଳନା ତଥା ରକ୍ଷଣାବେକ୍ଷଣ ତଥା ପାଠାଗାରର କାର୍ଯ୍ୟ ତଦାରଖ କରିବାକୁ କେହି ଇଚ୍ଛୁକ ନୁହଁନ୍ତି |",
              "as": "এইটো এটা সুন্দৰ ধাৰণা যেন লাগিছে, মাধৱ। মই ভাবো এইটো আগতেও ভবা হৈছে, কিন্তু কোনেও পুথিভঁৰালৰ সংগঠন আৰু কিতাপ ৰক্ষণাবেক্ষণ আৰু পৰিচালনাৰ তদাৰক কৰিবলৈ ইচ্ছুক নাছিল।",
              "gu": "તે એક સુંદર વિચાર જેવું લાગે છે, માધવ. મને લાગે છે કે આ પહેલા પણ આવી ચૂક્યું છે, પરંતુ પુસ્તકોની સંસ્થા અને જાળવણી અને પુસ્તકાલયની કામગીરીની દેખરેખ રાખવા કોઈ તૈયાર નહોતું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I will look into that personally if we go ahead with this plan. But the other point is equally important. There should be some activities in school that they can participate in. We have a ground for sports, but many students have expressed their interest in co-curricular activities like music, dance, drama and painting.",
              "hi": "अगर हम इस योजना के साथ आगे बढ़ते हैं तो मैं उसे व्यक्तिगत रूप से देखूंगा। लेकिन दूसरा बिंदु समान रूप से महत्वपूर्ण है। स्कूल में कुछ गतिविधियां होनी चाहिए  जिसमें कि वे भाग ले सकते हैं। हमारे पास खेल के लिए एक जमीन है, लेकिन कई छात्रों ने संगीत, नृत्य, नाटक और चित्रकला जैसी सह-पाठ्यचर्या गतिविधियों में अपनी रुचि व्यक्त की है।",
              "ka": "ನಾವು ಈ ಯೋಜನೆಯನ್ನು ಮುಂದುವರೆಸಿದಲ್ಲಿ  ನಾನು ಅದನ್ನು ವೈಯಕ್ತಿಕವಾಗಿ ಪರಿಶೀಲಿಸುತ್ತೇನೆ. ಆದರೆ ಇನ್ನೊಂದು ಅಂಶವೂ ಅಷ್ಟೇ ಮುಖ್ಯ. ಶಾಲೆಯಲ್ಲಿ ಅವರು ಭಾಗವಹಿಸಬಹುದಾದ ಕೆಲವು ಚಟುವಟಿಕೆಗಳಿರಬೇಕು.ನಮ್ಮಲ್ಲಿ ಕ್ರೀಡೆಗಳಿಗೆ ಮೈದಾನವಿದೆ, ಆದರೆ ಅನೇಕ ವಿದ್ಯಾರ್ಥಿಗಳು ಸಂಗೀತ, ನೃತ್ಯ, ನಾಟಕ ಮತ್ತು ಚಿತ್ರಕಲೆ ಮುಂತಾದ ಸಹಪಠ್ಯ ಚಟುವಟಿಕೆಗಳಲ್ಲಿ ತಮ್ಮ ಆಸಕ್ತಿಯನ್ನು ವ್ಯಕ್ತಪಡಿಸಿದ್ದಾರೆ.",
              "te": "మనము ఈ ప్లాన్ అమలు చేస్తే నేను దానిని వ్యక్తిగతంగా పరిశీలిస్తాను. కానీ ఇంకొక పాయింట్ కూడా అంతే ముఖ్యమైనది. పాఠశాలలో వారు పాల్గొనడానికి కొన్ని యాక్టివిటీస్ ఉండాలి. మనకు క్రీడల కోసం మైదానం ఉంది, కానీ చాలా మంది విద్యార్థులు సంగీతం, నృత్యం, నాటకం మరియు పెయింటింగ్ వంటి కో-కరిక్యులర్ యాక్టివిటీస్ పై తమ ఆసక్తిని వ్యక్తం చేశారు.",
              "or": "ଯଦି ଆମେ ଏହି ଯୋଜନା ସହିତ ଆଗକୁ ବଢିବା ତେବେ ମୁଁ ବ୍ୟକ୍ତିଗତ ଭାବରେ\n  ତାହା ଦେଖିବି | କିନ୍ତୁ ଅନ୍ୟ ବିଷୟଟି ମଧ୍ୟ ସମାନ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ | ବିଦ୍ୟାଳୟରେ କିଛି କାର୍ଯ୍ୟକଳାପ ରହିବା ଉଚିତ ଯେଉଁଥିରେ ସେମାନେ ଅଂଶଗ୍ରହଣ କରିପାରିବେ | ଆମର କ୍ରୀଡା ପାଇଁ ଏକ ଭୂମି ଅଛି, କିନ୍ତୁ ସଂଗୀତ, ନୃତ୍ୟ, ନାଟକ ଏବଂ ଚିତ୍ରକଳା ଭଳି ସହ-ପାଠ୍ୟକ୍ରମରେ ଅନେକ ଛାତ୍ର ଆଗ୍ରହ ପ୍ରକାଶ କରିଛନ୍ତି |",
              "as": "যদি আমি এই পৰিকল্পনাৰ সৈতে আগবাঢ়ি যাওঁ তেন্তে মই ব্যক্তিগতভাৱে সেইটো চোৱাচিতা কৰিম। কিন্তু আনটো বিষয়ো সমানে গুৰুত্বপূৰ্ণ। বিদ্যালয়ত কিছুমান কাৰ্যকলাপ থাকিব লাগে য'ত তেওঁলোকে অংশগ্ৰহণ কৰিব পাৰে। আমাৰ ক্ৰীড়াৰ বাবে এক খেলপথাৰ আছে, কিন্তু বহুতো শিক্ষাৰ্থীয়ে সংগীত, নৃত্য, নাটক আৰু চিত্ৰকলাৰ দৰে সহ-পাঠ্যক্ৰমৰ কাৰ্যকলাপত তেওঁলোকৰ আগ্ৰহ প্ৰকাশ কৰিছে।",
              "gu": "જો આપણે આ યોજના સાથે આગળ વધીએ તો હું વ્યક્તિગત રીતે તેની તપાસ કરીશ. પણ બીજો મુદ્દો પણ એટલો જ મહત્વનો છે. શાળામાં એવી કેટલીક પ્રવૃત્તિઓ હોવી જોઈએ જેમાં તેઓ ભાગ લઈ શકે. આપણી પાસે રમતગમત માટેનું મેદાન છે, પરંતુ ઘણા વિદ્યાર્થીઓએ સંગીત, નૃત્ય, નાટક અને ચિત્રકામ જેવી સહ-અભ્યાસિક પ્રવૃત્તિઓમાં રસ દર્શાવ્યો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Hmm, that might not be easy. We haven't considered this before. But I see why you find this to be important. Unfortunately, we have limited funds. We can only move forward with one of these ideas.",
              "hi": "हम्म, यह सरल नहीं हो सकता है। हमने पहले इस पर विचार नहीं किया है। लेकिन मैं देखता हूं कि आपको यह महत्वपूर्ण क्यों लगता है। दुर्भाग्य से, हमारे पास सीमित धन है। हम इनमें से किसी एक विचार के साथ ही आगे बढ़ सकते हैं।",
              "ka": "ಹುಂ , ಅದು ಸುಲಭವಲ್ಲ. ನಾವು ಇದನ್ನು ಮೊದಲು ಪರಿಗಣಿಸಿಲ್ಲ. ಆದರೆ ನೀವು ಇದನ್ನು ಏಕೆ ಮುಖ್ಯವೆಂದು ಕಂಡುಕೊಂಡಿದ್ದೀರಿ ಎನ್ನುವುದನ್ನು ನಾನು ತಿಳಿದುಕೊಂಡಿದ್ದೇನೆ. ದುರದೃಷ್ಟವಶಾತ್, ನಾವು ಸೀಮಿತ ಹಣವನ್ನು ಹೊಂದಿದ್ದೇವೆ. ಇವುಗಳಲ್ಲಿ ಒಂದನ್ನು ಮಾತ್ರ ನಾವು ಮುಂದುವರಿಸಬಹುದು.",
              "te": "హా, అది అంత సులభం కాకపోవచ్చు. మేము దీనిని ఇంతకు ముందు పరిగణించలేదు. కానీ మీరు దీన్ని ఎందుకు ముఖ్యమైనదిగా భావిస్తున్నారో నేను అర్థం చేసుకోగలను. దురదృష్టవశాత్తు, మాకు నిధులు తక్కువ ఉన్నాయి. ఈ ఆలోచనలలో ఒకదాన్ని మాత్రమే మనం చేయగలము.",
              "or": "ହମ୍, ତାହା ସହଜ ହୋଇନପାରେ | ଆମେ ଏହା ପୂର୍ବରୁ ବିଚାର କରିନାହୁଁ\n  | କିନ୍ତୁ ମୁଁ ଦେଖୁଛି ତୁମେ ଏହାକୁ କାହିଁକି ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ମନେ କରୁଛ | ଦୁର୍ଭାଗ୍ୟବଶତ।, ଆମର ସୀମିତ ପାଣ୍ଠି ଅଛି | ଆମେ କେବଳ ଏହି ଧାରଣାଗୁଡ଼ିକ ମଧ୍ୟରୁ ଗୋଟିଏ ସହିତ ଆଗକୁ ବ can ିପାରିବା |",
              "as": "হুমম, সেয়া সহজ নহ'বও পাৰে। আমি এইটো আগতে বিবেচনা কৰা নাই। কিন্তু মই চাওঁ তুমি এইটো কিয় গুৰুত্বপূৰ্ণ বুলি বিচাৰিছা। দুৰ্ভাগ্যবশতঃ, আমাৰ পুঁজি সীমিত। আমি কেৱল এই ধাৰণাবোৰৰ যিকোনো এটাৰ সৈতেহে আগবাঢ়িব পাৰিম।",
              "gu": "હમ્મ, તે સરળ ન હોઈ શકે. અમે આ પહેલા વિચાર્યું નથી. પરંતુ હું જોઉં છું કે શા માટે તમને આ મહત્વપૂર્ણ લાગે છે. કમનસીબે, આપણી પાસે મર્યાદિત ભંડોળ છે. આમાંથી એક વિચાર સાથે જ આપણે આગળ વધી શકીએ છીએ."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_3_6_D",
              "hi": "B2_3_6_d",
              "ka": "B2_3_6_D",
              "te": "B2_3_6_D",
              "or": "B2_3_6_D",
              "as": "B2_3_6_D",
              "gu": "B2_3_6_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "All the teachers look at Madhav. What should Madhav say?",
              "hi": "सभी शिक्षक माधव को देखते हैं। माधव को क्या कहना चाहिए?",
              "ka": "ಎಲ್ಲಾ ಶಿಕ್ಷಕರೂ ಮಾಧವನತ್ತ ನೋಡುತ್ತಾರೆ. ಮಾಧವ್ ಏನು ಹೇಳಬೇಕು?",
              "te": "టీచర్లంతా మాధవ్ వైపు చూస్తున్నారు. మాధవ్ ఏం చెప్పాలి?",
              "or": "ସମସ୍ତ ଶିକ୍ଷକ ମାଧବଙ୍କୁ ଦେଖନ୍ତି | ମାଧବ କ’ଣ କହିବା ଉଚିତ୍?",
              "as": "সকলো শিক্ষকে মাধৱৰ ফালে চালে। মাধৱে কি কোৱা উচিত?",
              "gu": "બધા શિક્ષકો માધવ તરફ જુએ છે. માધવે શું કહેવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "We can start with setting up the library.",
              "hi": "हम पुस्तकालय स्थापित करने के साथ आरम्भ कर सकते हैं।",
              "ka": "ನಾವು ಗ್ರಂಥಾಲಯವನ್ನು ಸ್ಥಾಪಿಸುವುದರೊಂದಿಗೆ ಪ್ರಾರಂಭಿಸಬಹುದು",
              "te": "మనము లైబ్రరీని ఏర్పాటు చేయడం ప్రారంభించవచ్చు.",
              "or": "ଆମେ ଲାଇବ୍ରେରୀ ସେଟ୍ ଅପ୍ ସହିତ ଆରମ୍ଭ କରିପାରିବା |",
              "as": "আমি পুথিভঁৰালটো স্থাপন কৰি আৰম্ভ কৰিব পাৰোঁ।",
              "gu": "આપણે પુસ્તકાલય સુયોજિત સાથે શરૂ કરી શકો છો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "We can start with the co-curricular activities.",
              "hi": "हम सह-पाठ्यचर्या गतिविधियों के साथ आरम्भ कर सकते हैं।",
              "ka": "ನಾವು ಸಹಪಠ್ಯ ಚಟುವಟಿಕೆಗಳೊಂದಿಗೆ ಪ್ರಾರಂಭಿಸಬಹುದು.",
              "te": "మనము కో-కరిక్యులర్ యాక్టివిటీస్ ప్రారంభించవచ్చు.",
              "or": "ଆମେ ସହ-ପାଠ୍ୟକ୍ରମ କାର୍ଯ୍ୟକଳାପରୁ ଆରମ୍ଭ କରିପାରିବା |",
              "as": "আমি সহ-পাঠ্যক্ৰমৰ কাৰ্যকলাপৰ সৈতে আৰম্ভ কৰিব পাৰোঁ।",
              "gu": "આપણે સહ-અભ્યાસિક પ્રવૃત્તિઓથી શરૂઆત કરી શકીએ છીએ."
            }
          }
        ]
      },
      "scene23": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Set up a library",
              "hi": "एक पुस्तकालय स्थापित करे",
              "ka": "ಗ್ರಂಥಾಲಯವನ್ನು ಸ್ಥಾಪಿಸುವುದು",
              "te": "లైబ్రరీని ఏర్పాటు చేయాలి",
              "or": "ଏକ ଲାଇବ୍ରେରୀ ସେଟ୍ ଅପ୍ କରନ୍ତୁ |",
              "as": "এটা লাইব্ৰেৰী স্থাপন",
              "gu": "એક પુસ્તકાલય ચાલુ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav starts a library and a reading club for students. To start with, he sets the library inside a small room.",
              "hi": "माधव ने छात्रों के लिए एक पुस्तकालय और एक पठन क्लब आरम्भ किया। आरम्भ करने के लिए, वह पुस्तकालय को एक छोटे से कमरे के अंदर स्थापित करता है।",
              "ka": "ಮಾಧವ್ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆಂದು ಗ್ರಂಥಾಲಯ ಮತ್ತು ರೀಡಿಂಗ್ ಕ್ಲಬ್ ಅನ್ನು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ. ಮೊದಲಿಗೆ, ಅವರು ಗ್ರಂಥಾಲಯವನ್ನು ಸಣ್ಣ ಕೋಣೆಯೊಳಗೆ ಹೊಂದಿಸುತ್ತಾರೆ.",
              "te": "మాధవ్ విద్యార్థుల కోసం ఒక లైబ్రరీ మరియు రీడింగ్ క్లబ్‌ను ప్రారంభిస్తాడు. మొదట, అతను ఒక చిన్న గదిలో లైబ్రరీని అమర్చాడు.",
              "or": "ମାଧବ ଛାତ୍ରମାନଙ୍କ ପାଇଁ ଏକ ଲାଇବ୍ରେରୀ ଏବଂ ଏକ ପଠନ କ୍ଲବ୍ \n ଆରମ୍ଭ କରେ | ଆରମ୍ଭ କରିବା ପାଇଁ, ସେ ଏକ ଛୋଟ କୋଠରୀ ଭିତରେ ଲାଇବ୍ରେରୀ ସେଟ୍ କରନ୍ତି |",
              "as": "মাধৱে শিক্ষাৰ্থীসকলৰ বাবে এটা পুথিভঁৰাল আৰু পঢ়া ক্লাব আৰম্ভ কৰে। আৰম্ভণিতে, তেওঁ পুথিভঁৰালটো এটা সৰু কোঠাৰ ভিতৰত স্থাপন কৰে।",
              "gu": "માધવ વિદ્યાર્થીઓ માટે પુસ્તકાલય અને વાંચન કક્ષા શરૂ કરે છે. શરૂ કરવા માટે, તે એક નાનકડા રૂમની અંદર લાઇબ્રેરી બનાવે છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2i_1_1b",
              "hi": "B2i_1_1b",
              "ka": "B2i_1_1b",
              "te": "B2i_1_1b",
              "or": "B2i_1_1b",
              "as": "B2i_1_1b",
              "gu": "B2i_1_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He involves the students in setting up the library room. The students are eager to help, so they come an hour before school begins to help paint the walls and clean the room.",
              "hi": " पुस्तकालय कक्ष स्थापित करने में छात्रों को शामिल किया गया है। छात्र सहायता करने के लिए उत्सुक हैं, इसलिए स्कूल की दीवारों को पेंट करने और कमरे को साफ करने में  सहायता करने के लिए स्कूल आरम्भ होने से एक घंटे पहले वे आते हैं।",
              "ka": "ಅವನು ಗ್ರಂಥಾಲಯ ಕೊಠಡಿಯನ್ನು ಸ್ಥಾಪಿಸುವಲ್ಲಿ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ತೊಡಗಿಸಿಕೊಂಡಿದ್ದಾನೆ. ವಿದ್ಯಾರ್ಥಿಗಳು ಸಹಾಯ ಮಾಡಲು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ, ಆದ್ದರಿಂದ ಅವರು ಶಾಲೆ ಪ್ರಾರಂಭವಾಗುವ ಒಂದು ಗಂಟೆ ಮೊದಲು ಬಂದು ಗೋಡೆಗಳಿಗೆ ಬಣ್ಣ ಬಳಿಯಲು ಮತ್ತು ಕೊಠಡಿಯನ್ನು ಸ್ವಚ್ಛಗೊಳಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತಾರೆ.",
              "te": "అతను లైబ్రరీ గదిని ఏర్పాటు చేయడంలో విద్యార్థులను ఇన్వాల్వ్ చేస్తాడు. విద్యార్థులు సహాయం చేయడానికి ఉత్సాహంగా ఉన్నారు, కాబట్టి వారు పాఠశాల మొదలయ్యే ఒక గంట ముందే వచ్చి గోడలకు పెయింట్ చేయడం మరియు గదిని శుభ్రం చేయడంలో సహాయపడతారు.",
              "or": "ସେ ଲାଇବ୍ରେରୀ ରୁମ୍ ସ୍ଥାପନ କରିବାରେ ଛାତ୍ରମାନଙ୍କୁ ଜଡିତ କରନ୍ତି | \n ଛାତ୍ରମାନେ ସାହାଯ୍ୟ କରିବାକୁ ଆଗ୍ରହୀ ଅଟନ୍ତି, ତେଣୁ ବିଦ୍ୟାଳୟ କାନ୍ଥକୁ ରଙ୍ଗ କରିବା ଏବଂ କୋଠରୀ ସଫା କରିବାରେ ବିଦ୍ୟାଳୟ ଆରମ୍ଭ ହେବାର ଏକ ଘଣ୍ଟା ପୂର୍ବରୁ ସେମାନେ ଆସନ୍ତି |",
              "as": "তেওঁ শিক্ষাৰ্থীসকলক পুথিভঁৰালৰ কোঠাটো স্থাপন কৰাত অন্তৰ্ভুক্ত কৰে। শিক্ষাৰ্থীসকলে সহায় কৰিবলৈ আগ্ৰহী, সেয়েহে তেওঁলোকে বিদ্যালয় আৰম্ভ হোৱাৰ এঘণ্টা আগতে দেৱালবোৰ ৰং কৰা আৰু কোঠাটো পৰিষ্কাৰ কৰাত সহায় কৰিবলৈ আহে।",
              "gu": "તે લાઇબ્રેરી રૂમની સ્થાપનામાં વિદ્યાર્થીઓને સામેલ કરે છે. વિદ્યાર્થીઓ મદદ કરવા આતુર હોય છે, તેથી તેઓ શાળા શરૂ થાય તેના એક કલાક પહેલાં દિવાલોને રંગવામાં અને રૂમ સાફ કરવામાં મદદ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2i_1_2",
              "hi": "B2i_1_2",
              "ka": "B2i_1_2",
              "te": "B2i_1_2",
              "or": "B2i_1_2",
              "as": "B2i_1_2",
              "gu": "B2i_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "A few parents are upset that the students are working in school instead of helping at home. They stop sending their children to school. The Headteacher asks Madhav to fix the problem.",
              "hi": "कुछ माता-पिता परेशान हैं कि छात्र घर पर सहायता करने के बजाय स्कूल में काम कर रहे हैं। वे अपने बच्चों को स्कूल भेजना बंद कर देते हैं। प्रधानाध्यापक माधव से समस्या का समाधान करने के लिए कहते हैं।",
              "ka": "ವಿದ್ಯಾರ್ಥಿಗಳು ಮನೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಬದಲು ಶಾಲೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾರೆ ಎಂದು ಕೆಲವು ಪೋಷಕರು ಅಸಮಾಧಾನ ವ್ಯಕ್ತಪಡಿಸಿದ್ದಾರೆ. ಅವರು ತಮ್ಮ ಮಕ್ಕಳನ್ನು ಶಾಲೆಗೆ ಕಳುಹಿಸುವುದನ್ನು ನಿಲ್ಲಿಸುತ್ತಾರೆ. ಮುಖ್ಯೋಪಾಧ್ಯಾಯರು ಮಾಧವ್ ಅವರಿಗೆ ಸಮಸ್ಯೆ ಬಗೆಹರಿಸುವಂತೆ ಕೇಳಿಕೊಂಡರು.",
              "te": "విద్యార్థులు ఇంట్లో సాయం చేయకుండా పాఠశాలలో పనిచేస్తున్నారని కొందరు తల్లిదండ్రులు బాధ పడ్డారు. తమ పిల్లలను బడికి పంపడం మానేస్తారు. సమస్యను పరిష్కరించమని ప్రధానోపాధ్యాయుడు మాధవ్‌ను కోరాడు.",
              "or": "ଛାତ୍ରଛାତ୍ରୀମାନେ ଘରେ ସାହାଯ୍ୟ କରିବା ପରିବର୍ତ୍ତେ ସ୍କୁଲରେ କାମ କରୁଛନ୍ତି\n  ବୋଲି କିଛି ଅଭିଭାବକ ବିରକ୍ତ ହୁଅନ୍ତି | ସେମାନେ ନିଜ ପିଲାମାନଙ୍କୁ ବିଦ୍ୟାଳୟକୁ ପଠାଇବା ବନ୍ଦ କରନ୍ତି | ପ୍ରଧାନ ଶିକ୍ଷକ ମାଧବଙ୍କୁ ସମସ୍ୟାର ସମାଧାନ କରିବାକୁ କୁହନ୍ତି |",
              "as": "কেইজনমান অভিভাৱক বিচলিত হয় যে শিক্ষাৰ্থীসকলে ঘৰত সহায় কৰাৰ পৰিৱৰ্তে বিদ্যালয়ত কাম কৰি আছে। তেওঁলোকে তেওঁলোকৰ ল'ৰা-ছোৱালীক বিদ্যালয়লৈ পঠোৱা বন্ধ কৰে। প্ৰধান শিক্ষকে মাধৱক সমস্যাটো সমাধান কৰিবলৈ কয়।",
              "gu": "કેટલાક વાલીઓ નારાજ છે કે વિદ્યાર્થીઓ ઘરે મદદ કરવાને બદલે શાળામાં કામ કરી રહ્યા છે. તેઓ તેમના બાળકોને શાળાએ મોકલવાનું બંધ કરે છે. મુખ્ય શિક્ષક માધવને સમસ્યાનું સમાધાન કરવા કહે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2i_2_1",
              "hi": "B2i_2_1",
              "ka": "B2i_2_1",
              "te": "B2i_2_1",
              "or": "B2i_2_1",
              "as": "B2i_2_1",
              "gu": "B2i_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is unsure how to handle the situation and calls Badri for advice. Badri is more experienced in teaching, and Madhav feels like he is the right person to guide him.",
              "hi": "माधव अनिश्चित है कि स्थिति को कैसे संभाला जाए और वह सलाह के लिए बद्री को  कॉल करता है। बद्दी शिक्षण में अधिक अनुभवी है, और माधव को लगता है कि वह उसका मार्गदर्शन करने के लिए सही व्यक्ति है।",
              "ka": "ಪರಿಸ್ಥಿತಿಯನ್ನು ಹೇಗೆ ನಿಭಾಯಿಸಬೇಕೆಂದು ಮಾಧವ್‌ಗೆ ಅಸ್ಪಷ್ಟತೆ ಇದೆ ಮತ್ತು ಸಲಹೆಗಾಗಿ ಬದರಿಗೆ ಕರೆ ಮಾಡುತ್ತಾನೆ. ಬದ್ರಿ ಬೋಧನೆಯಲ್ಲಿ ಹೆಚ್ಚು ಅನುಭವಿ, ಮತ್ತು ಮಾಧವ್ ತನಗೆ ಮಾರ್ಗದರ್ಶನ ನೀಡಲು ಸರಿಯಾದ ವ್ಯಕ್ತಿ ಎಂದು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్ పరిస్థితిని ఎలా సెట్ చేయాలో తెలియక బద్రిని సలహా కోసం పిలుస్తాడు. బద్రికి బోధనలో ఎక్కువ అనుభవం ఉంది మరియు మాధవ్‌కు గైడెన్స్ ఇవ్వడానికి అతను సరైన వ్యక్తి అని భావించాడు.",
              "or": "ପରିସ୍ଥିତିକୁ କିପରି ସମ୍ଭାଳିବେ ମାଧବ ନିଶ୍ଚିତ ନୁହଁନ୍ତି ଏବଂ ପରାମର୍ଶ ପାଇଁ\n  ବଦ୍ରିଙ୍କୁ ଡାକନ୍ତି | ବଦ୍ରି ଶିକ୍ଷାଦାନରେ ଅଧିକ ଅଭିଜ୍ଞ, ଏବଂ ମାଧବ ତାଙ୍କୁ ମାର୍ଗଦର୍ଶନ କରିବା ପାଇଁ ଉପଯୁକ୍ତ ବ୍ୟକ୍ତି ପରି ଅନୁଭବ କରନ୍ତି |",
              "as": "মাধৱে পৰিস্থিতিটো কেনেদৰে মোকাবিলা কৰিব লাগিব সেইবিষয়ে নিশ্চিত নহয় আৰু পৰামৰ্শৰ বাবে বদ্ৰীক মাতে। বদ্ৰী শিক্ষাদানত অধিক অভিজ্ঞ, আৰু মাধৱে অনুভৱ কৰে যে তাক পথ প্ৰদৰ্শন কৰাৰ বাবে তেওঁ সঠিক ব্যক্তি।",
              "gu": "માધવ પરિસ્થિતિને કેવી રીતે સંભાળવી તે અંગે અચોક્કસ છે અને બદ્રીને સલાહ માટે બોલાવે છે. બદ્રી ભણવામાં વધુ અનુભવી છે, અને માધવને લાગે છે કે તે તેને માર્ગદર્શન આપવા માટે યોગ્ય વ્યક્તિ છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2i_2_2////",
              "hi": "B2i_2_2 /////",
              "ka": "B2i_2_2////",
              "te": "B2i_2_2////",
              "or": "B2i_2_2////",
              "as": "B2i_2_2////",
              "gu": "B2i_2_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Madhav, this keeps happening. Sometimes it feels like you're questioned about a thousand things for every step you take.",
              "hi": "माधव, ऐसा होता रहता है। कभी-कभी ऐसा लगता है कि तुम्हारे द्वारा लिए  गए  हर कदम के लिए तुमको एक हजार चीजों के बारे में पूछताछ की जाती है।",
              "ka": "ಮಾಧವ್, ಇದು ನಡೆಯುತ್ತಲೇ ಇರುತ್ತದೆ. ನೀನು ಇಡುವ ಪ್ರತಿ ಹೆಜ್ಜೆಗೂ ಸಾವಿರ ವಿಷಯಗಳ ಬಗ್ಗೆ ನಿನ್ನನ್ನು ಪ್ರಶ್ನಿಸುವಂತೆ ಕೆಲವೊಮ್ಮೆ ಅನಿಸುತ್ತದೆ.",
              "te": "మాధవ్, ఇలాంటివి జరుగుతూనే ఉంటాయి. కొన్నిసార్లు మీరు వేసే ప్రతి అడుగుకు వెయ్యి విషయాల గురించి ప్రశ్నించినట్లు అనిపిస్తుంది.",
              "or": "ମାଧବ, ଏହା ଘଟୁଛି | ବେଳେବେଳେ ଏହା ଅନୁଭବ ହୁଏ ଯେ ଆପଣ\n  ଗ୍ରହଣ କରୁଥିବା ପ୍ରତ୍ୟେକ ପଦକ୍ଷେପ ପାଇଁ ଆପଣଙ୍କୁ ପ୍ରାୟ ଏକ ହଜାର ଜିନିଷ ପ୍ରଶ୍ନ କରାଯାଇଛି |",
              "as": "মাধৱ, এনেকুৱা হৈ থাকে। কেতিয়াবা এনে অনুভৱ হয় যেন তুমি লোৱা প্ৰতিটো পদক্ষেপৰ বাবে তোমাক এহেজাৰ বস্তুৰ বিষয়ে প্ৰশ্ন কৰা হয়।",
              "gu": "માધવ, આવું થતું રહે છે. કેટલીકવાર એવું લાગે છે કે તમે લીધેલા દરેક પગલા માટે તમને હજાર વસ્તુઓ વિશે પૂછવામાં આવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, Badri, but what do I do? How did you manage when you added something new to your school?",
              "hi": "हाँ, बद्री, लेकिन मैं क्या करूँ? जब तुमने अपने स्कूल में कुछ नया जोड़ा तो तुमने कैसे व्यवस्थित किया?",
              "ka": "ಹೌದು, ಬದ್ರಿ, ಆದರೆ ನಾನೇನು ಮಾಡಲಿ? ನಿಮ್ಮ ಶಾಲೆಗೆ ಹೊಸದನ್ನು ಏನಾದರೂ ಸೇರಿಸಿದಾಗ ನೀನುಹೇಗೆ ನಿರ್ವಹಿಸಿದ್ದೀ?",
              "te": "అవును, బద్రి, అయితే నేను ఏమి చేయాలి? నువ్వు మీ స్కూల్ లో ఏదైనా కొత్తది చేసినప్పుడు దాన్ని ఎలా మేనేజ్ చేశావు?",
              "or": "ହଁ, ବଦ୍ରି, କିନ୍ତୁ ମୁଁ କ’ଣ କରିବି? ଯେତେବେଳେ ତୁମେ ତୁମର ବିଦ୍ୟାଳୟରେ \n କିଛି ନୂଆ ଯୋଡିଛ ତୁମେ କିପରି ପରିଚାଳନା କଲ?",
              "as": "হয়, বদ্ৰী, কিন্তু মই কি কৰিম? যেতিয়া তুমি তোমাৰ বিদ্যালয়ত কিবা নতুন সংযোগ কৰিছিলা তেতিয়া তুমি কেনেদৰে পৰিচালনা কৰিছিলা?",
              "gu": "હા, બદ્રી, પણ હું શું કરું? જ્યારે તે તારી શાળામાં કંઈક નવું ઉમેર્યું ત્યારે તે કેવી રીતે સંચાલન કર્યું?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "The thing that works best is 'trust'. It would be best if you won the parents' trust by talking to them about what you are doing and how it will add value.",
              "hi": "सबसे अच्छा काम करने वाली चीज 'विश्वास' है। यह सबसे अच्छा होगा यदि तुमने माता-पिता का विश्वास  उनसे बातचीत करके जीता है  कि तुम क्या कर रहे हो तो यह मूल्य कैसे जोड़ देगा।",
              "ka": "ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುವ ವಿಷಯವೆಂದರೆ 'ನಂಬಿಕೆ'. ನೀವು ಏನು ಮಾಡುತ್ತಿದ್ದೀರಿ ಮತ್ತು ಅದು ಹೇಗೆ ಮೌಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸುತ್ತದೆ ಎನ್ನುವುದರ ಕುರಿತು ಪೋಷಕರೊಂದಿಗೆ ಮಾತನಾಡುವ ಮೂಲಕ ನೀನು ಅವರ ವಿಶ್ವಾಸವನ್ನು ಗಳಿಸಿದರೆ ಅದು ಉತ್ತಮವಾಗಿರುತ್ತದೆ.",
              "te": "నమ్మకం' అనేది ఉత్తమంగా పనిచేస్తుంది. మీరు ఏమి చేస్తున్నారో మరియు అది ఎలా విలువను పెంచుతుంది అనే దాని గురించి తల్లిదండ్రులతో మాట్లాడటం ద్వారా మీరు వారి నమ్మకాన్ని గెలుచుకుంటే మంచిది.",
              "or": "ସର୍ବୋତ୍ତମ କାର୍ଯ୍ୟ କରୁଥିବା ଜିନିଷ ହେଉଛି 'ବିଶ୍ୱାସ' | ତୁମେ କ’ଣ କରୁଛ \n ଏବଂ ଏହା କିପରି ମୂଲ୍ୟ ଯୋଗ କରିବ ସେ ବିଷୟରେ ସେମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରି ପିତାମାତାଙ୍କ ବିଶ୍ୱାସ ଜିତିଲେ ଏହା ସର୍ବୋତ୍ତମ ହେବ |",
              "as": "সৰ্বশ্ৰেষ্ঠ কাম কৰা বস্তুটো হ'ল 'বিশ্বাস'। তুমি কি কৰি আছা আৰু ই কেনেদৰে মূল্য বৃদ্ধি কৰিব সেই বিষয়ে তেওঁলোকৰ সৈতে কথা পাতি অভিভাৱকৰ বিশ্বাস জিকিলে ভাল হ'ব।",
              "gu": "જે વસ્તુ શ્રેષ્ઠ કામ કરે છે તે છે 'વિશ્વાસ'. તું શું કરી રહ્યા છો અને તે કેવી રીતે મૂલ્ય વધારશે તે વિશે વાત કરીને તું માતાપિતાનો વિશ્વાસ જીતી લો તો શ્રેષ્ઠ રહેશે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2i_3_1",
              "hi": "B2i_3_1",
              "ka": "B2i_3_1",
              "te": "B2i_3_1",
              "or": "B2i_3_1",
              "as": "B2i_3_1",
              "gu": "B2i_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to hold a parent-teacher meeting and hear the parents' concerns.",
              "hi": "माधव ने माता-पिता-शिक्षक की बैठक आयोजित करने और माता-पिता की चिंताओं को सुनने का निश्चय किया।",
              "ka": "ಮಾಧವ್ ಪೋಷಕ-ಶಿಕ್ಷಕರ ಸಭೆಯನ್ನು ನಡೆಸಲು ಮತ್ತು ಪೋಷಕರ ಸಮಸ್ಯೆಗಳನ್ನು ಆಲಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "పేరెంట్స్-టీచర్ మీటింగ్ నిర్వహించి, తల్లిదండ్రుల సమస్యలను వినాలని మాధవ్ నిర్ణయించుకున్నాడు.",
              "or": "ମାଧବ ଏକ ପିତାମାତା-ଶିକ୍ଷକ ସଭା କରିବା ଏବଂ ପିତାମାତାଙ୍କ ଚିନ୍ତାଧାରା \n ଶୁଣିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "মাধৱে অভিভাৱক-শিক্ষকৰ এখন সভা অনুষ্ঠিত কৰি অভিভাৱকৰ অভিযোগ শুনিবলৈ সিদ্ধান্ত লয়।",
              "gu": "માધવ માતાપિતા-શિક્ષક મીટિંગ યોજવાનું અને માતાપિતાની ચિંતાઓ સાંભળવાનું નક્કી કરે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2i_3_2",
              "hi": "B2i_3_2",
              "ka": "B2i_3_2",
              "te": "B2i_3_2",
              "or": "B2i_3_2",
              "as": "B2i_3_2",
              "gu": "B2i_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Many parents come, and Madhav is happy to see that they are interested in discussing the problem. Madhav asks them to voice their concerns.",
              "hi": "कई माता-पिता आते हैं, और माधव यह देखकर खुश होता है कि वे समस्या पर चर्चा करने में रुचि रखते हैं। माधव ने उन्हें अपनी चिंताओं को व्यक्त करने के लिए कहा।",
              "ka": "ಅನೇಕ ಪೋಷಕರು ಬರುತ್ತಾರೆ, ಮತ್ತು ಮಾಧವ್ ಅವರು ಸಮಸ್ಯೆಯನ್ನು ಚರ್ಚಿಸಲು ಆಸಕ್ತಿ ತೋರಿಸುವುದನ್ನು ನೋಡಿ ಸಂತೋಷಪಡುತ್ತಾನೆ. ಮಾಧವ್ ಅವರು ತಮ್ಮ ಕಳವಳಗಳನ್ನು ವ್ಯಕ್ತಪಡಿಸುವಂತೆ ಕೇಳಿಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "చాలా మంది పేరెంట్స్ వస్తారు, వాళ్ళు సమస్య గురించి చర్చించడానికి ఆసక్తి చూపడం చూసి మాధవ్ సంతోషించాడు. తమ ఆందోళనలను వినిపించాలని మాధవ్ కోరారు.",
              "or": "ଅନେକ ପିତାମାତା ଆସନ୍ତି, ଏବଂ ମାଧବ ଦେଖି ଖୁସି ହୁଅନ୍ତି ଯେ ସେମାନେ \n ଏହି ସମସ୍ୟା ବିଷୟରେ ଆଲୋଚନା କରିବାକୁ ଆଗ୍ରହୀ ଅଟନ୍ତି | ମାଧବ ସେମାନଙ୍କୁ ସେମାନଙ୍କ ଚିନ୍ତାଧାରା ସ୍ୱର ଦେବାକୁ କୁହନ୍ତି |",
              "as": "বহুতো অভিভাৱক আহে, আৰু সমস্যাটোৰ বিষয়ে আলোচনা কৰিবলৈ তেওঁলোকক আগ্ৰহী হোৱা দেখি মাধৱ সুখী হয়। মাধৱে তেওঁলোকক তেওঁলোকৰ উদ্বেগৰ বিষয়ে ক'বলৈ কয়।",
              "gu": "ઘણા માતા-પિતા આવે છે, અને માધવ એ જોઈને ખુશ થાય છે કે તેઓ સમસ્યાની ચર્ચા કરવામાં રસ ધરાવે છે. માધવ તેમને તેમની ચિંતા વ્યક્ત કરવા કહે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2i_3_3//",
              "hi": "B2i_3_3 //",
              "ka": "B2i_3_3//",
              "te": "B2i_3_3//",
              "or": "B2i_3_3//",
              "as": "B2i_3_3//",
              "gu": "B2i_3_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Parent",
              "hi": "माता-पिता",
              "ka": "ಪೋಷಕರು",
              "te": "తల్లిదండ్రులు",
              "or": "ପିତାମାତା |",
              "as": "অভিভাৱক",
              "gu": "માતાપિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ |",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Sir, we need our children to help us on the farm, and we can't manage without their support. We send them to school with the intention for them to get educated like everyone else.",
              "hi": "महोदय, हमें अपने बच्चों की आवश्यकता हमको खेत पर हमारी सहायता करने के लिए है, और हम उनके सहयोग के बिना व्यवस्था नहीं कर सकते हैं। हम उन्हें हर किसी की तरह शिक्षित करने के इरादे से स्कूल भेजते हैं।",
              "ka": "ಸರ್, ಜಮೀನಿನಲ್ಲಿ ನಮಗೆ ಸಹಾಯ ಮಾಡಲು ನಮ್ಮ ಮಕ್ಕಳು ಬೇಕು ಮತ್ತು ಅವರ ಬೆಂಬಲವಿಲ್ಲದೆ ನಾವು ನಿರ್ವಹಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ. ಅವರು ಎಲ್ಲರಂತೆ ವಿದ್ಯಾವಂತರಾಗಲಿ ಎಂಬ ಉದ್ದೇಶದಿಂದ ಅವರನ್ನು ಶಾಲೆಗೆ ಕಳುಹಿಸುತ್ತೇವೆ.",
              "te": "సర్, పొలంలో మాకు సహాయం చేయడానికి మా పిల్లలు కావాలి మరియు వారి మద్దతు లేకుండా మేము మేనేజ్ చేయలేము. అందరిలాగే చదువుకోవాలనే ఉద్దేశ్యంతో వాళ్ళను పాఠశాలకు పంపుతున్నాం.",
              "or": "ସାର୍, ଚାଷରେ ଆମକୁ ସାହାଯ୍ୟ କରିବା ପାଇଁ ଆମର ପିଲାମାନେ ଆବଶ୍ୟକ\n  କରନ୍ତି, ଏବଂ ଆମେ ସେମାନଙ୍କ ସମର୍ଥନ ବିନା ପରିଚାଳନା କରିପାରିବୁ ନାହିଁ | ଅନ୍ୟମାନଙ୍କ ପରି ଶିକ୍ଷିତ ହେବା ଉଦ୍ଦେଶ୍ୟରେ ଆମେ ସେମାନଙ୍କୁ ବିଦ୍ୟାଳୟକୁ ପଠାଉ |",
              "as": "ছাৰ, আমাৰ ল'ৰা-ছোৱালীয়ে আমাক খেতিপথাৰত সহায় কৰাটো বিচাৰোঁ, কাৰণ আমি তেওঁলোকৰ সহায় অবিহনে পৰিচালনা কৰিব নোৱাৰোঁ। আমি তেওঁলোকক সকলোৰে দৰে শিক্ষিত হোৱাৰ উদ্দেশ্যৰে বিদ্যালয়লৈ পঠিয়াইছোঁ।",
              "gu": "સાહેબ, ખેતરમાં અમને મદદ કરવા માટે અમારે અમારા બાળકોની જરૂર છે, અને અમે તેના સાથ વિના સાંભાળી શકતા નથી. તેઓ બીજા બધાની જેમ શિક્ષિત થાય તે હેતુથી અમે તેમને શાળાએ મોકલીએ છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I'm sorry for not communicating my intention to you before. I wanted the students to help, so they feel connected to the space. Why don't we see the library the students helped create.",
              "hi": " अपने इरादे को पहले से न बताने के लिए मुझे खेद है। मैं चाहता था कि छात्र सहायता करें, इसलिए वे इस स्थान से जुड़ा  हुआ अनुभव करते हैं। हम उन पुस्तकालय को क्यों नहीं देखते हैं जिसे छात्रों ने बनाने में सहायता की है।",
              "ka": "ನನ್ನ ಉದ್ದೇಶವನ್ನು ಮೊದಲು ನಿಮಗೆ ತಿಳಿಸದಿದ್ದಕ್ಕಾಗಿ ಕ್ಷಮಿಸಿ. ವಿದ್ಯಾರ್ಥಿಗಳು ಸಹಾಯ ಮಾಡಬೇಕೆಂದು ನಾನು ಬಯಸುತ್ತೇನೆ, ಆದ್ದರಿಂದ ಅವರು ಬಾಹ್ಯಾಕಾಶಕ್ಕೆ ಸಂಪರ್ಕ ಹೊಂದಿದ್ದಾರೆಂದು ಭಾವಿಸುತ್ತಾರೆ. ವಿದ್ಯಾರ್ಥಿಗಳು ರಚಿಸಲು ಸಹಾಯ ಮಾಡಿದ ಗ್ರಂಥಾಲಯವನ್ನು ನಾವು ಏಕೆ ನೋಡಬಾರದು.",
              "te": "నా ఉద్దేశాన్ని ఇంతకు ముందు మీకు తెలియజేయనందుకు క్షమించండి. వారు ఈ స్థలంతో ఎక్కువ కనెక్ట్ అవ్వడానికి విద్యార్థులు సహాయం చేయాలని నేను అనుకున్నాను. విద్యార్థులు సహాయపడి సృష్టించిన లైబ్రరీని మనం ఎందుకు చూడకూడదు.",
              "or": "ମୋର ଉଦ୍ଦେଶ୍ୟ ତୁମକୁ ପୂର୍ବରୁ ଜଣାଇ ନ ଥିବାରୁ ମୁଁ ଦୁଃଖିତ | ମୁଁ ଚାହୁଁଥିଲି\n  ଛାତ୍ରମାନେ ସାହାଯ୍ୟ କରନ୍ତୁ, ତେଣୁ ସେମାନେ ସ୍ପେସ୍ ସହିତ ସଂଯୁକ୍ତ ଅନୁଭବ କରନ୍ତି | ଛାତ୍ରମାନେ ସୃଷ୍ଟି କରିବାରେ ସାହାଯ୍ୟ କରିଥିବା ଲାଇବ୍ରେରୀକୁ ଆମେ କାହିଁକି ଦେଖୁନାହୁଁ |",
              "as": "মোৰ অভিপ্ৰায়ৰ বিষয়ে আপোনালোকক আগতিয়াকৈ নজনোৱাৰ বাবে মই দুঃখিত। মই শিক্ষাৰ্থীসকলে সহায় কৰাটো বিচাৰিছিলোঁ, যাতে তেওঁলোকে ঠাইটুকুৰাৰ সৈতে সংযুক্ত অনুভৱ কৰে। শিক্ষাৰ্থীসকলে সৃষ্টি কৰাত সহায় কৰা পুথিভঁৰালটো আমি চাবলৈ নাযাওঁ কিয়।",
              "gu": "મને મારા ઈરાદાની પહેલાં તમને જાણ ન કરવા બદલ માફ કરશો. હું ઇચ્છું છું કે વિદ્યાર્થીઓ મદદ કરે, જેથી તેઓ વાતાવરણ સાથે જોડાન અનુભવે. વિદ્યાર્થીઓએ જે લાયબ્રેરી બનાવવામાં મદદ કરી હતી તે આપણે કેમ જોતા નથી."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2i_4_1",
              "hi": "B2i_4_1",
              "ka": "B2i_4_1",
              "te": "B2i_4_1",
              "or": "B2i_4_1",
              "as": "B2i_4_1",
              "gu": "B2i_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav shows them the library and all the books the students can read. He also shares that all students can spend time reading and issuing books every alternate day.",
              "hi": "माधव उन्हें पुस्तकालय और छात्रों को पढ़ने वाली सभी किताबें दिखाता है। वह यह भी साझा करता है कि सभी छात्र दो दिन में एक बार पढ़ने और किताबों को जारी करने में समय व्यतीत कर सकते हैं।",
              "ka": "ಮಾಧವ್ ಅವರಿಗೆ ಗ್ರಂಥಾಲಯ ಮತ್ತು ವಿದ್ಯಾರ್ಥಿಗಳು ಓದಬಹುದಾದ ಎಲ್ಲಾ ಪುಸ್ತಕಗಳನ್ನು ತೋರಿಸಿದರು. ಎಲ್ಲಾ ವಿದ್ಯಾರ್ಥಿಗಳು ಪ್ರತಿ ಪರ್ಯಾಯ ದಿನ ಪುಸ್ತಕಗಳನ್ನು ಓದಲು ಮತ್ತು ವಿತರಿಸಲು ಸಮಯವನ್ನು ಕಳೆಯಬಹುದು ಎಂದು ಅವರು ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "మాధవ్ వారికి లైబ్రరీ మరియు విద్యార్థులు చదవగలిగే అన్ని పుస్తకాలను చూపించాడు. విద్యార్థులందరూ రోజు తప్పి రోజు పుస్తకాలను చదవి ఇవ్వడానికి సమయాన్ని వెచ్చించవచ్చని కూడా అతను చెప్తాడు.",
              "or": "ମାଧବ ସେମାନଙ୍କୁ ଲାଇବ୍ରେରୀ ଏବଂ ଛାତ୍ରମାନେ ପଢିଥିବା ସମସ୍ତ\n  ପୁସ୍ତକ ଦେଖାଏ | ସେ ଆହୁରି ମଧ୍ୟ ଅଂଶୀଦାର କରିଛନ୍ତି ଯେ ସମସ୍ତ ଛାତ୍ରମାନେ ପ୍ରତ୍ୟେକ ବିକଳ୍ପ ଦିନରେ ବହି ପଢିବା ଏବଂ ପ୍ରଦାନ କରିବାରେ ସମୟ ଦେଇପାରିବେ |",
              "as": "মাধৱে তেওঁলোকক পুথিভঁৰালটো আৰু শিক্ষাৰ্থীসকলে পঢ়িব পৰা সকলোবোৰ কিতাপ দেখুৱায়। তেওঁ এইটোও কয় যে সকলো শিক্ষাৰ্থীয়ে প্ৰতিটো বৈকল্পিক দিনত কিতাপ পঢ়া আৰু জাৰী কৰাত সময় অতিবাহিত কৰিব পাৰিব।",
              "gu": "માધવ તેમને પુસ્તકાલય અને વિદ્યાર્થીઓ વાંચી શકે તેવા તમામ પુસ્તકો બતાવે છે. તે એ પણ બતાવે છે કે બધા વિદ્યાર્થીઓ દરેક વૈકલ્પિક દિવસે પુસ્તકો વાંચવા લઈ જઈ શકે છે અને સમય પસાર કરી શકે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2i_4_2",
              "hi": "B2i_4_2",
              "ka": "B2i_4_2",
              "te": "B2i_4_2",
              "or": "B2i_4_2",
              "as": "B2i_4_2",
              "gu": "B2i_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The parents are pleased to see the library that the students help make.",
              "hi": "माता-पिता पुस्तकालय को देखकर प्रसन्न हैं कि छात्र सहायता करते हैं।",
              "ka": "ವಿದ್ಯಾರ್ಥಿಗಳು ಸಹಾಯ ಮಾಡಿ ರಚಿಸಿದ ಗ್ರಂಥಾಲಯವನ್ನು ನೋಡಿ ಪೋಷಕರು ಸಂತಸಗೊಂಡಿದ್ದಾರೆ.",
              "te": "విద్యార్థులు తయారు చేసిన లైబ్రరీని చూసి తల్లిదండ్రులు సంతోషం వ్యక్తం చేస్తారు.",
              "or": "ଅଭିଭାବକମାନେ ଲାଇବ୍ରେରୀ ଦେଖିବାରେ ଆନନ୍ଦିତ ହୁଅନ୍ତି ଯାହା ଛାତ୍ରମାନେ \n ତିଆରି କରିବାରେ ସାହାଯ୍ୟ କରନ୍ତି |",
              "as": "শিক্ষাৰ্থীসকলে বনোৱাত সহায় কৰা পুথিভঁৰালটো দেখি অভিভাৱকসকল আনন্দিত হয়।",
              "gu": "વિદ્યાર્થીઓ મદદ મળે તે પુસ્તકાલય જોઈને વાલીઓ ખુશ થાય છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2i_4_2b",
              "hi": "B2i_4_2b",
              "ka": "B2i_4_2b",
              "te": "B2i_4_2b",
              "or": "B2i_4_2b",
              "as": "B2i_4_2b",
              "gu": "B2i_4_2b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They see some paintings that students made on the walls, the blackboard with a list of books the students want and a collection box for any old books that the students or teachers want to donate.",
              "hi": "वे कुछ पेंटिंग देखते हैं जो छात्रों ने दीवारों पर बनाई हैं, छात्रों को किताबों की सूची के साथ ब्लैकबोर्ड और किसी भी पुरानी किताबों के लिए एक संग्रह बॉक्स जिसे छात्र या शिक्षक दान करना चाहते हैं।",
              "ka": "ವಿದ್ಯಾರ್ಥಿಗಳು ಗೋಡೆಗಳ ಮೇಲೆ ಬಿಡಿಸಿದ ಕೆಲವು ವರ್ಣಚಿತ್ರಗಳು, ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಬೇಕಾದ ಪುಸ್ತಕಗಳ ಪಟ್ಟಿಯನ್ನು ಹೊಂದಿರುವ ಕಪ್ಪು ಹಲಗೆ ಮತ್ತು ವಿದ್ಯಾರ್ಥಿಗಳು ಅಥವಾ ಶಿಕ್ಷಕರು ದಾನ ಮಾಡಲು ಬಯಸುವ ಯಾವುದೇ ಹಳೆಯ ಪುಸ್ತಕಗಳ ಸಂಗ್ರಹ ಪೆಟ್ಟಿಗೆಯನ್ನು ಅವರು ನೋಡುತ್ತಾರೆ.",
              "te": "విద్యార్థులు గోడలపై వేసిన కొన్ని పెయింటింగ్‌లు, విద్యార్థులు కోరుకునే పుస్తకాల జాబితాతో కూడిన బ్లాక్‌బోర్డ్ మరియు విద్యార్థులు లేదా ఉపాధ్యాయులు విరాళంగా ఇవ్వాలనుకుంటున్న పాత పుస్తకాల సేకరణ పెట్టెను వారు చూస్తారు.",
              "or": "ସେମାନେ କିଛି ଚିତ୍ର ଦେଖନ୍ତି ଯାହା ଛାତ୍ରମାନେ କାନ୍ଥରେ ତିଆରି କରିଥିଲେ,\n  ଛାତ୍ରମାନେ ଚାହୁଁଥିବା ବହିର ତାଲିକା ସହିତ ବ୍ଲାକବୋର୍ଡ ଏବଂ ଛାତ୍ର କିମ୍ବା ଶିକ୍ଷକମାନେ ଦାନ କରିବାକୁ ଚାହୁଁଥିବା କ old ଣସି ପୁରୁଣା ପୁସ୍ତକ ପାଇଁ ଏକ ସଂଗ୍ରହ ବାକ୍ସ ଦେଖନ୍ତି |",
              "as": "তেওঁলোকে দেৱালত শিক্ষাৰ্থীসকলে তৈয়াৰ কৰা কিছুমান চিত্ৰ, শিক্ষাৰ্থীসকলে বিচৰা কিতাপবোৰৰ তালিকা থকা ব্লেকবোৰ্ড আৰু শিক্ষাৰ্থী বা শিক্ষকসকলে দান কৰিব বিচৰা যিকোনো পুৰণি কিতাপৰ বাবে এটা সংগ্ৰহ বাকচ দেখা পায়।",
              "gu": "તેઓ દિવાલો પર વિદ્યાર્થીઓએ બનાવેલા કેટલાક ચિત્રો, વિદ્યાર્થીઓને જોઈતા પુસ્તકોની યાદી સાથેનું કાળા પાટિયામાં અને વિદ્યાર્થીઓ કે શિક્ષકો દાન કરવા માંગતા હોય તેવા કોઈપણ જૂના પુસ્તકો માટે એક સંગ્રહ બોક્સ જુએ છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2i_4_3",
              "hi": "B2i_4_3",
              "ka": "B2i_4_3",
              "te": "B2i_4_3",
              "or": "B2i_4_3",
              "as": "B2i_4_3",
              "gu": "B2i_4_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav feels happy and encouraged to see the parents' response. He decides to conduct a 'read aloud' class in the library with the 5th and 6th standard students for half an hour every day.",
              "hi": " माता-पिता की प्रतिक्रिया को देख कर माधव खुश और प्रोत्साहित होता है। वह लाइब्रेरी में 5वीं और 6वीं कक्षा के छात्रों के साथ हर दिन आधे घंटे के लिए पुस्तकालय में एक 'पढ़ाए गए' वर्ग का संचालन करने का निर्णय लेता है।",
              "ka": "ಪೋಷಕರ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ನೋಡಿ ಮಾಧವ್‌ಗೆ ಸಂತೋಷ ಮತ್ತು ಪ್ರೋತ್ಸಾಹ ಸಿಕ್ಕಿತು. 5 ಮತ್ತು 6 ನೇ ತರಗತಿಯ ವಿದ್ಯಾರ್ಥಿಗಳೊಂದಿಗೆ ಪ್ರತಿದಿನ ಅರ್ಧ ಘಂಟೆಯವರೆಗೆ ಗ್ರಂಥಾಲಯದಲ್ಲಿ 'ಜೋರಾಗಿ ಓದು' ತರಗತಿಯನ್ನು ನಡೆಸಲು ಅವನು ನಿರ್ಧರಿಸುತ್ತಾನೆ",
              "te": "తల్లితండ్రుల స్పందన చూసి మాధవ్ సంతోషంగా ఉన్నాడు మరియు ప్రోత్సహించబడ్డాడు. లైబ్రరీలో 5వ మరియు 6వ తరగతి విద్యార్థులతో రోజూ అరగంట పాటు 'రీడ్ అలౌడ్' తరగతి నిర్వహించాలని నిర్ణయించుకున్నాడు.",
              "or": "ପିତାମାତାଙ୍କ ପ୍ରତିକ୍ରିୟା ଦେଖି ମାଧବ ଖୁସି ଅନୁଭବ କରନ୍ତି ଏବଂ ଉତ୍ସାହିତ\n  ହୁଅନ୍ତି | ସେ ପ୍ରତିଦିନ ଅଧ ଘଣ୍ଟା ପାଇଁ ୫ମ ଏବଂ ୬ଷ୍ଠ ମାନକ ଛାତ୍ରମାନଙ୍କ ସହିତ ଲାଇବ୍ରେରୀରେ ଏକ 'ଉଚ୍ଚ ସ୍ୱରରେ ପଢିବା' ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "মাধৱে অভিভাৱকৰ সঁহাৰি দেখি সুখী আৰু উৎসাহিত অনুভৱ কৰে। তেওঁ প্ৰতিদিনে আধা ঘণ্টাৰ বাবে ৫ম আৰু ৬ষ্ঠ শ্ৰেণীৰ শিক্ষাৰ্থীৰ সৈতে পুথিভঁৰালত 'ডাঙৰকৈ পঢ়া' শ্ৰেণী এটা অনুষ্ঠিত কৰাৰ সিদ্ধান্ত লয়।",
              "gu": "માતા-પિતાનો પ્રતિભાવ જોઈને માધવ ખુશ અને પ્રોત્સાહિત અનુભવે છે. તે પુસ્તકાલયમાં 5મા અને 6ઠ્ઠા ધોરણના વિદ્યાર્થીઓ સાથે દરરોજ અડધો કલાક માટે 'મોટેથી વાંચવાનું' વર્ગ ચલાવવાનું નક્કી કરે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2i_1_3",
              "hi": "B2i_1_3",
              "ka": "B2i_1_3",
              "te": "B2i_1_3",
              "or": "B2i_1_3",
              "as": "B2i_1_3",
              "gu": "B2i_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "With regular 'read aloud' classes, he creates an interest in the young children to read books and discuss them.",
              "hi": "नियमित रूप से 'जोर से पढ़ें' कक्षाओं के साथ, वह युवा बच्चों में किताबें पढ़ने और उन पर चर्चा करने में रुचि पैदा करता है।",
              "ka": "ನಿಯಮಿತವಾದ 'ಗಟ್ಟಿಯಾಗಿ ಓದು' ತರಗತಿಗಳೊಂದಿಗೆ, ಅವನು ಚಿಕ್ಕ ಮಕ್ಕಳಲ್ಲಿ ಪುಸ್ತಕಗಳನ್ನು ಓದಲು ಮತ್ತು ಚರ್ಚಿಸಲು ಆಸಕ್ತಿಯನ್ನು ಉಂಟುಮಾಡುತ್ತಾನೆ.",
              "te": "రెగ్యులర్‌గా 'రీడ్ అలౌడ్' తరగతులతో, అతను చిన్న పిల్లలలో పుస్తకాలు చదవడానికి మరియు వాటి గురించి చర్చించడానికి ఆసక్తిని సృష్టిస్తాడు.",
              "or": "ନିୟମିତ 'ଉଚ୍ଚ ସ୍ୱରରେ ପଢିବା' ସହିତ, ସେ ଛୋଟ ପିଲାମାନଙ୍କ ପାଇଁ ପୁସ୍ତକ\n  ପଢିବା ଏବଂ ଆଲୋଚନା କରିବା ପାଇଁ ଏକ ଆଗ୍ରହ ସୃଷ୍ଟି କରନ୍ତି |",
              "as": "নিয়মীয়াকৈ 'ডাঙৰকৈ পঢ়া' শ্ৰেণীৰ সৈতে, তেওঁ সৰু ল'ৰা-ছোৱালীবোৰৰ কিতাপ পঢ়িবলৈ আৰু সেইবোৰৰ আলোচনা কৰিবলৈ আগ্ৰহৰ সৃষ্টি কৰে।",
              "gu": "નિયમિત 'મોટેથી વાંચવાનો' વર્ગો સાથે, તે નાના બાળકોમાં પુસ્તકો વાંચવા અને તેમની ચર્ચા કરવા માટે રસ પેદા કરે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2i_1_4",
              "hi": "B2i_1_4",
              "ka": "B2i_1_4",
              "te": "B2i_1_4",
              "or": "B2i_1_4",
              "as": "B2i_1_4",
              "gu": "B2i_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The school gets a bigger library in a few years, and the teachers collect funds to add more books to the library.",
              "hi": "स्कूल को कुछ सालों में एक बड़ा पुस्तकालय मिलता है, और शिक्षक पुस्तकालय में और किताबें जोड़ने के लिए धन इकट्ठा करते हैं।",
              "ka": "ಕೆಲವು ವರ್ಷಗಳಲ್ಲಿ ಶಾಲೆಯು ದೊಡ್ಡ ಗ್ರಂಥಾಲಯವನ್ನು ಪಡೆಯುತ್ತದೆ ಮತ್ತು ಗ್ರಂಥಾಲಯಕ್ಕೆ ಹೆಚ್ಚಿನ ಪುಸ್ತಕಗಳನ್ನು ಸೇರಿಸಲು ಶಿಕ್ಷಕರು ಹಣವನ್ನು ಸಂಗ್ರಹಿಸುತ್ತಾರೆ.",
              "te": "పాఠశాలకు కొన్ని సంవత్సరాలలో పెద్ద లైబ్రరీ వస్తుంది మరియు లైబ్రరీకి మరిన్ని పుస్తకాలను జోడించడానికి ఉపాధ్యాయులు నిధులు సేకరిస్తారు.",
              "or": "ବିଦ୍ୟାଳୟଟି କିଛି ବର୍ଷ ମଧ୍ୟରେ ଏକ ବଡ଼ ଲାଇବ୍ରେରୀ ପାଇଥାଏ, ଏବଂ\n  ଶିକ୍ଷକମାନେ ଲାଇବ୍ରେରୀରେ ଅଧିକ ପୁସ୍ତକ ଯୋଡିବା ପାଇଁ ପାଣ୍ଠି ସଂଗ୍ରହ କରନ୍ତି |",
              "as": "বিদ্যালয়খনে কেইবছৰমানৰ ভিতৰতে এটা ডাঙৰ পুথিভঁৰাল লাভ কৰে, আৰু শিক্ষকসকলে পুথিভঁৰালত অধিক কিতাপ যোগ দিবলৈ পুঁজি সংগ্ৰহ কৰে।",
              "gu": "શાળાને થોડા વર્ષોમાં એક મોટી પુસ્તકાલય મળે છે, અને શિક્ષકો પુસ્તકાલયમાં વધુ પુસ્તકો ઉમેરવા માટે ભંડોળ એકત્ર કરે છે."
            }
          }
        ]
      },
      "scene24": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Set up activity clubs",
              "hi": "गतिविधि क्लब सेट अप करे",
              "ka": "ಚಟುವಟಿಕೆ ಕ್ಲಬ್‌ಗಳನ್ನು ಸ್ಥಾಪಿಸುವುದು",
              "te": "యాక్టివిటీ క్లబ్‌లను ఏర్పాటు చేయడం",
              "or": "କାର୍ଯ୍ୟକଳାପ କ୍ଲବ୍ ସେଟ୍ ଅପ୍ କରନ୍ତୁ |",
              "as": "কাৰ্যকলাপ ক্লাব স্থাপন",
              "gu": "પ્રવૃત્તિ ક્લબ બનાવો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to set up a room for co-curricular activities. He sets this up inside a small space, and he involves the students in painting and cleaning the walls.",
              "hi": "माधव ने सह-पाठ्यचर्या गतिविधियों के लिए एक कमरा स्थापित करने का निर्णय किया। वह इसे एक छोटी सी जगह के अंदर स्थापित करता है, और इसमें छात्रों को चित्रों और दीवारों की सफाई में शामिल किया गया है।",
              "ka": "ಮಾಧವ್ ಸಹಪಠ್ಯ ಚಟುವಟಿಕೆಗಳಿಗಾಗಿ ಒಂದು ಕೊಠಡಿಯನ್ನು ಸ್ಥಾಪಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಅವನು ಇದನ್ನು ಸಣ್ಣ ಜಾಗದಲ್ಲಿ ಹೊಂದಿಸುತ್ತಾನೆ ಮತ್ತು ಅವನು ಗೋಡೆಗಳನ್ನು ಚಿತ್ರಿಸಲು ಮತ್ತು ಸ್ವಚ್ಛಗೊಳಿಸುವಲ್ಲಿ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ತೊಡಗಿಸಿಕೊಳ್ಳುತ್ತಾನೆ",
              "te": "కో-కరిక్యులర్ యాక్టివిటీస్ కోసం ఒక గదిని ఏర్పాటు చేయాలని మాధవ్ నిర్ణయించుకున్నాడు. అతను దీన్ని ఒక చిన్న స్థలంలో అమర్చి విద్యార్థులను పెయింటింగ్ మరియు గోడలను శుభ్రపరచడంలో పాల్గొనేలా చేస్తాడు.",
              "or": "ସହ-ପାଠ୍ୟକ୍ରମ କାର୍ଯ୍ୟକଳାପ ପାଇଁ ଏକ କୋଠରୀ ସ୍ଥାପନ କରିବାକୁ\n  ମାଧବ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ସେ ଏହାକୁ ଏକ ଛୋଟ ଜାଗା ଭିତରେ ସେଟ୍ କରନ୍ତି, ଏବଂ ସେ ଛାତ୍ରମାନଙ୍କୁ କାନ୍ଥ ଚିତ୍ର କରିବା ଏବଂ ସଫା କରିବାରେ ଜଡିତ କରନ୍ତି |",
              "as": "মাধৱে সহ-পাঠ্যক্ৰমৰ কাৰ্যকলাপৰ বাবে এটা কোঠা স্থাপন কৰাৰ সিদ্ধান্ত লয়। তেওঁ এইটো এটা সৰু ঠাইৰ ভিতৰত স্থাপন কৰে, আৰু তেওঁ শিক্ষাৰ্থীসকলক দেৱালবোৰ ৰং কৰা আৰু পৰিষ্কাৰ কৰাত অন্তৰ্ভুক্ত কৰে।",
              "gu": "માધવ સહ-અભ્યાસિક પ્રવૃત્તિઓ માટે એક રૂમ બનાવવાનું નક્કી કરે છે. તે તેને એક નાની જગ્યામાં ગોઠવે છે, અને તે વિદ્યાર્થીઓને ચિત્રકામ અને દિવાલોની સફાઈમાં સામેલ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2ii_1_2",
              "hi": "B2II_1_2",
              "ka": "B2ii_1_2",
              "te": "B2ii_1_2",
              "or": "B2ii_1_2",
              "as": "B2ii_1_2",
              "gu": "B2ii_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "A few parents are upset that the students work in school instead of helping at home, and they don't allow the students to participate in any extracurricular activities.",
              "hi": "कुछ माता-पिता परेशान हैं कि छात्र घर पर सहायता करने के बजाय स्कूल में काम करते हैं, और वे छात्रों को किसी भी सह-पाठ्यचर्या गतिविधियों में भाग लेने की अनुमति नहीं देते हैं।",
              "ka": "ವಿದ್ಯಾರ್ಥಿಗಳು ಮನೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಬದಲು ಶಾಲೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾರೆ ಎಂದು ಕೆಲವು ಪೋಷಕರು ಅಸಮಾಧಾನ ವ್ಯಕ್ತಪಡಿಸಿದ್ದಾರೆ. ಅವರು ತಮ್ಮ ಮಕ್ಕಳನ್ನು ಶಾಲೆಗೆ ಕಳುಹಿಸುವುದನ್ನು ನಿಲ್ಲಿಸುತ್ತಾರೆ. ಮುಖ್ಯೋಪಾಧ್ಯಾಯರು ಮಾಧವ್ ಅವರಿಗೆ ಸಮಸ್ಯೆ ಬಗೆಹರಿಸುವಂತೆ ಕೇಳಿಕೊಂಡರು.",
              "te": "కొంతమంది తల్లిదండ్రులు విద్యార్థులు ఇంట్లో సహాయం చేయడానికి బదులుగా పాఠశాలలో పని చేస్తున్నారని అప్సెట్ అయి విద్యార్థులు ఎటువంటి పాఠ్యేతర కార్యకలాపాలలో పాల్గొనడానికి అనుమతించడం లేదు.",
              "or": "କିଛି ଅଭିଭାବକ ବିରକ୍ତ ହୁଅନ୍ତି ଯେ ଛାତ୍ରଛାତ୍ରୀମାନେ ଘରେ ସାହାଯ୍ୟ\n  କରିବା ପରିବର୍ତ୍ତେ ସ୍କୁଲରେ କାମ କରନ୍ତି, ଏବଂ ସେମାନେ ଛାତ୍ରମାନଙ୍କୁ କ extra ଣସି ବହିର୍ଭୂତ କାର୍ଯ୍ୟରେ ଅଂଶଗ୍ରହଣ କରିବାକୁ ଅନୁମତି ଦିଅନ୍ତି ନାହିଁ |",
              "as": "কেইজনমান অভিভাৱক বিৰক্ত হৈছে যে শিক্ষাৰ্থীসকলে ঘৰত সহায় কৰাৰ পৰিৱৰ্তে বিদ্যালয়ত কাম কৰে, আৰু তেওঁলোকে শিক্ষাৰ্থীসকলক কোনো পাঠ্যক্ৰমৰ বাহিৰৰ কাৰ্যকলাপত অংশগ্ৰহণ কৰিবলৈ অনুমতি নিদিয়ে।",
              "gu": "કેટલાક વાલીઓ નારાજ છે કે વિદ્યાર્થીઓ ઘરે મદદ કરવાને બદલે શાળામાં કામ કરે છે, અને તેઓ વિદ્યાર્થીઓને કોઈપણ અભ્યાસેતર પ્રવૃત્તિઓમાં ભાગ લેવા દેતા નથી."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2ii_1_2b",
              "hi": "B2II_1_2B",
              "ka": "B2ii_1_2b",
              "te": "B2ii_1_2b",
              "or": "B2ii_1_2b",
              "as": "B2ii_1_2b",
              "gu": "B2ii_1_2b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The Headteacher asks Madhav to fix the problem.",
              "hi": "प्रधानाध्यापक माधव से समस्या का समाधान करने के लिए कहते हैं।",
              "ka": " ಮುಖ್ಯೋಪಾಧ್ಯಾಯರು ಮಾಧವ್ ಅವರಿಗೆ ಸಮಸ್ಯೆ ಬಗೆಹರಿಸುವಂತೆ ಕೇಳಿಕೊಂಡರು.",
              "te": "సమస్యను పరిష్కరించమని ప్రధానోపాధ్యాయుడు మాధవ్‌ను కోరాడు.",
              "or": "ପ୍ରଧାନ ଶିକ୍ଷକ ମାଧବଙ୍କୁ ସମସ୍ୟାର ସମାଧାନ କରିବାକୁ କୁହନ୍ତି |",
              "as": "প্ৰধান শিক্ষকে মাধৱক সমস্যাটো সমাধান কৰিবলৈ কয়।",
              "gu": "મુખ્ય શિક્ષક માધવને સમસ્યાનું સમાધાન કરવા કહે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2ii_2_1",
              "hi": "B2II_2_1",
              "ka": "B2ii_2_1",
              "te": "B2ii_2_1",
              "or": "B2ii_2_1",
              "as": "B2ii_2_1",
              "gu": "B2ii_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is unsure how to handle the situation and calls Badri for advice.",
              "hi": "माधव अनिश्चित है कि स्थिति को संभालने और सलाह के लिए बद्री को कैसे कॉल किया जाए।",
              "ka": "ಪರಿಸ್ಥಿತಿಯನ್ನು ಹೇಗೆ ನಿಭಾಯಿಸಬೇಕೆಂದು ಮಾಧವ್‌ಗೆ ಅಸ್ಪಷ್ಟವಾಗಿದೆ ಮತ್ತು ಸಲಹೆಗಾಗಿ ಬದರಿಗೆ ಕರೆ ಮಾಡುತ್ತಾನೆ.",
              "te": "మాధవ్ పరిస్థితిని ఎలా మేనేజ్ చేయాలో తెలియక బద్రిని సలహా కోసం పిలుస్తాడు.",
              "or": "ପରିସ୍ଥିତିକୁ କିପରି ସମ୍ଭାଳିବେ ମାଧବ ନିଶ୍ଚିତ ନୁହଁନ୍ତି ଏବଂ ପରାମର୍ଶ ପାଇଁ\n  ବଦ୍ରିଙ୍କୁ ଡାକନ୍ତି |",
              "as": "মাধৱে পৰিস্থিতিটো কেনেদৰে মোকাবিলা কৰিব লাগিব সেইবিষয়ে নিশ্চিত নহয় আৰু পৰামৰ্শৰ বাবে বদ্ৰীক মাতে।",
              "gu": "માધવ પરિસ્થિતિને કેવી રીતે સંભાળવી તે અંગે અચોક્કસ છે અને બદ્રીને સલાહ માટે બોલાવે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2ii_2_2///",
              "hi": "B2II_2_2 ///",
              "ka": "B2ii_2_2///",
              "te": "B2ii_2_2///",
              "or": "B2ii_2_2///",
              "as": "B2ii_2_2///",
              "gu": "B2ii_2_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Madhav, this keeps happening. Sometimes it feels like you're questioned about a thousand things for every step you take.",
              "hi": "माधव, ऐसा होता रहता है। कभी-कभी ऐसा लगता है कि तुम्हारे द्वारा लिए गए  हर कदम के लिए तुमको एक हजार चीजों के बारे में पूछताछ की जाती है।",
              "ka": "ಮಾಧವ್, ಇದು ನಡೆಯುತ್ತಲೇ ಇರುತ್ತದೆ. ನೀನು ಇಡುವ ಪ್ರತಿ ಹೆಜ್ಜೆಗೂ ಸಾವಿರ ವಿಷಯಗಳ ಬಗ್ಗೆ ನಿನ್ನನ್ನು ಪ್ರಶ್ನಿಸುವಂತೆ ಕೆಲವೊಮ್ಮೆ ಅನಿಸುತ್ತದೆ.",
              "te": "మాధవ్, ఇలాంటివి జరుగుతూనే ఉంటాయి. కొన్నిసార్లు మీరు వేసే ప్రతి అడుగుకు వెయ్యి విషయాల గురించి ప్రశ్నించినట్లు అనిపిస్తుంది.",
              "or": "ମାଧବ, ଏହା ଘଟୁଛି | ବେଳେବେଳେ ଏହା ଅନୁଭବ ହୁଏ ଯେ ଆପଣ\n  ଗ୍ରହଣ କରୁଥିବା ପ୍ରତ୍ୟେକ ପଦକ୍ଷେପ ପାଇଁ ଆପଣଙ୍କୁ ପ୍ରାୟ ଏକ ହଜାର ପ୍ରଶ୍ନ କରାଯାଇଛି |",
              "as": "মাধৱ, এনে হৈয়েই থাকে। কেতিয়াবা এনে অনুভৱ হয় যেন তুমি লোৱা প্ৰতিটো পদক্ষেপৰ বাবে তোমাক এহেজাৰ বস্তুৰ বিষয়ে প্ৰশ্ন কৰা হয়।",
              "gu": "માધવ, આવું થતું રહે છે. કેટલીકવાર એવું લાગે છે કે તમે લીધેલા દરેક પગલા માટે તમને હજાર વસ્તુઓ વિશે પૂછવામાં આવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, Badri, but what do I do? How did you manage when you added something new to your school?",
              "hi": "हाँ, बद्री, लेकिन मैं क्या करूँ? जब तुमने अपने स्कूल में कुछ नया जोड़ा तो तुमने कैसे व्यवस्थित किया?",
              "ka": "ಹೌದು, ಬದ್ರಿ, ಆದರೆ ನಾನೇನು ಮಾಡಲಿ? ನಿಮ್ಮ ಶಾಲೆಗೆ ಹೊಸದನ್ನು ಏನಾದರೂ ಸೇರಿಸಿದಾಗ ನೀನುಹೇಗೆ ನಿರ್ವಹಿಸಿದ್ದೀ?",
              "te": "అవును, బద్రీ, కానీ నేను ఏమి చేయాలి? నువ్వు మీ పాఠశాలలో ఏదైనా కొత్తది చేసినప్పుడు నువ్వు ఎలా మేనేజ్ చేశావు?",
              "or": "ହଁ, ବଦ୍ରି, କିନ୍ତୁ ମୁଁ କ’ଣ କରିବି? ଯେତେବେଳେ ତୁମେ ତୁମର ବିଦ୍ୟାଳୟରେ \n କିଛି ନୂଆ ଯୋଡିଛ ତୁମେ କିପରି ପରିଚାଳନା କଲ?",
              "as": "হয়, বদ্ৰী, কিন্তু মই কি কৰিম? যেতিয়া তুমি তোমাৰ বিদ্যালয়ত কিবা নতুন সংযোগ কৰিছিলা তেতিয়া তুমি কেনেদৰে পৰিচালনা কৰিছিলা?",
              "gu": "હા, બદ્રી, પણ હું શું કરું? જ્યારે તે તારી શાળામાં કંઈક નવું ઉમેર્યું ત્યારે તે કેવી રીતે સંચાલન કર્યું?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "The thing that works best is ‘trust’. It would be best if you won the parents’ trust by talking to them about what you are doing and how it will add value.",
              "hi": "सबसे अच्छा काम करने वाली चीज 'विश्वास' है। यह सबसे अच्छा होगा यदि तुमने माता-पिता का विश्वास  उनसे बातचीत करके जीता है  कि तुम क्या कर रहे हो  और यह मूल्य कैसे जोड़ देगा।",
              "ka": "ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುವ ವಿಷಯವೆಂದರೆ 'ನಂಬಿಕೆ'. ನೀವು ಏನು ಮಾಡುತ್ತಿದ್ದೀರಿ ಮತ್ತು ಅದು ಹೇಗೆ ಮೌಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸುತ್ತದೆ ಎನ್ನುವುದರ ಕುರಿತು ಪೋಷಕರೊಂದಿಗೆ ಮಾತನಾಡುವ ಮೂಲಕ ನೀನು ಅವರ ವಿಶ್ವಾಸವನ್ನು ಗಳಿಸಿದರೆ ಅದು ಉತ್ತಮವಾಗಿರುತ್ತದೆ.",
              "te": "నమ్మకం' అనేది ఉత్తమంగా పనిచేసే విషయం. మీరు ఏమి చేస్తున్నారో మరియు అది ఎలా విలువను పెంచుతుంది అనే దాని గురించి తల్లిదండ్రులతో మాట్లాడటం ద్వారా మీరు వారి నమ్మకాన్ని గెలుచుకుంటే మంచిది.",
              "or": "ସର୍ବୋତ୍ତମ କାର୍ଯ୍ୟ କରୁଥିବା ଜିନିଷ ହେଉଛି ‘ବିଶ୍ୱାସ’ | ତୁମେ କ’ଣ କରୁଛ\n  ଏବଂ ଏହା କିପରି ମୂଲ୍ୟ ଯୋଗ କରିବ ସେ ବିଷୟରେ ସେମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରି ପିତାମାତାଙ୍କ ବିଶ୍ୱାସ ଜିତିଲେ ଏହା ସର୍ବୋତ୍ତମ ହେବ |",
              "as": "সৰ্বশ্ৰেষ্ঠ কাম কৰা বস্তুটো হ'ল 'বিশ্বাস'। তুমি কি কৰি আছা আৰু ই কেনেদৰে মূল্য বৃদ্ধি কৰিব সেই বিষয়ে তেওঁলোকৰ সৈতে কথা পাতি অভিভাৱকৰ বিশ্বাস জিকিলে ভাল হ'ব।",
              "gu": "જે વસ્તુ શ્રેષ્ઠ કામ કરે છે તે છે 'વિશ્વાસ'. તું શું કરી રહ્યા છો અને તે કેવી રીતે મૂલ્ય વધારશે તે વિશે વાત કરીને તું માતાપિતાનો વિશ્વાસ જીતી લો તો શ્રેષ્ઠ રહેશે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2ii_3_1",
              "hi": "B2II_3_1",
              "ka": "B2ii_3_1",
              "te": "B2ii_3_1",
              "or": "B2ii_3_1",
              "as": "B2ii_3_1",
              "gu": "B2i_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to hold a parent-teacher meeting and hear the parents’ concerns.",
              "hi": "माधव ने माता-पिता-शिक्षक की बैठक आयोजित करने और माता-पिता की चिंताओं को सुनने का निर्णय किया।",
              "ka": "ಮಾಧವ್ ಪೋಷಕ-ಶಿಕ್ಷಕರ ಸಭೆಯನ್ನು ನಡೆಸಲು ಮತ್ತು ಪೋಷಕರ ಸಮಸ್ಯೆಗಳನ್ನು ಆಲಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "పేరెంట్స్-టీచర్ మీటింగ్ నిర్వహించి, తల్లిదండ్రుల సమస్యలను వినాలని మాధవ్ నిర్ణయించుకున్నాడు.",
              "or": "ମାଧବ ଏକ ପିତାମାତା-ଶିକ୍ଷକ ସଭା କରିବା ଏବଂ ପିତାମାତାଙ୍କ ଚିନ୍ତାଧାରା \n ଶୁଣିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "মাধৱে অভিভাৱক-শিক্ষকৰ এখন সভা অনুষ্ঠিত কৰি অভিভাৱকৰ অভিযোগ শুনিবলৈ সিদ্ধান্ত লয়।",
              "gu": "માધવ માતાપિતા-શિક્ષક મીટિંગ યોજવાનું અને માતાપિતાની ચિંતાઓ સાંભળવાનું નક્કી કરે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2ii_3_1b",
              "hi": "B2II_3_1B",
              "ka": "B2ii_3_1b",
              "te": "B2ii_3_1b",
              "or": "B2ii_3_1b",
              "as": "B2ii_3_1b",
              "gu": "B2i_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Many parents come, and Madhav is happy to see that they are interested in discussing the problem. Madhav asks them to voice their concerns.",
              "hi": "कई माता-पिता आते हैं, और माधव यह देखकर खुश होता है कि वे समस्या पर चर्चा करने में रुचि रखते हैं। माधव ने उन्हें अपनी चिंताओं को व्यक्त करने के लिए कहा।",
              "ka": "ಅನೇಕ ಪೋಷಕರು ಬರುತ್ತಾರೆ, ಮತ್ತು ಮಾಧವ್ ಅವರು ಸಮಸ್ಯೆಯನ್ನು ಚರ್ಚಿಸಲು ಆಸಕ್ತಿ ತೋರಿಸುವುದನ್ನು ನೋಡಿ ಸಂತೋಷಪಡುತ್ತಾನೆ. ಮಾಧವ್ ಅವರು ತಮ್ಮ ಕಳವಳಗಳನ್ನು ವ್ಯಕ್ತಪಡಿಸುವಂತೆ ಕೇಳಿಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "చాలా మంది పేరెంట్స్ వస్తారు, వాళ్ళు సమస్య గురించి చర్చించడానికి ఆసక్తి చూపడం చూసి మాధవ్ సంతోషించాడు. తమ ఆందోళనలను వినిపించాలని మాధవ్ కోరారు.",
              "or": "ଅନେକ ପିତାମାତା ଆସନ୍ତି, ଏବଂ ମାଧବ ଦେଖି ଖୁସି ହୁଅନ୍ତି ଯେ ସେମାନେ\n  ଏହି ସମସ୍ୟା ବିଷୟରେ ଆଲୋଚନା କରିବାକୁ ଆଗ୍ରହୀ ଅଟନ୍ତି | ମାଧବ ସେମାନଙ୍କୁ ସେମାନଙ୍କ ଚିନ୍ତାଧାରା ସ୍ୱର ଦେବାକୁ କୁହନ୍ତି |",
              "as": "বহুতো অভিভাৱক আহে, আৰু সমস্যাটোৰ বিষয়ে আলোচনা কৰিবলৈ তেওঁলোকক আগ্ৰহী হোৱা দেখি মাধৱ সুখী হয়। মাধৱে তেওঁলোকক তেওঁলোকৰ উদ্বেগৰ বিষয়ে ক'বলৈ কয়।",
              "gu": "ઘણા માતા-પિતા આવે છે, અને માધવ એ જોઈને ખુશ થાય છે કે તેઓ સમસ્યાની ચર્ચા કરવામાં રસ ધરાવે છે. માધવ તેમને તેમની ચિંતા વ્યક્ત કરવા કહે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2ii_3_2",
              "hi": "B2II_3_2",
              "ka": "B2ii_3_2",
              "te": "B2ii_3_2",
              "or": "B2ii_3_2",
              "as": "B2ii_3_2",
              "gu": "B2ii_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav shows them the work of their children during the activities. He also shares how it can help them through their life.",
              "hi": "माधव उन्हें उनके बच्चों द्वारा गतिविधियों के समय किया गया काम दिखाता है। वह यह भी साझा करता है कि यह उनके जीवन में उनकी सहायता कैसे कर सकता है।",
              "ka": "ಮಾಧವ್ ಅವರು ಚಟುವಟಿಕೆಗಳ ಸಮಯದಲ್ಲಿ ಮಾಡಿರುವ ಮಕ್ಕಳ ಕೆಲಸವನ್ನು ತೋರಿಸುತ್ತಾರೆ. ಇದು ಅವರ ಜೀವನದಲ್ಲಿ ಹೇಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ ಎಂಬುದನ್ನು ಸಹ ಅವನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾನೆ",
              "te": "మాధవ్ యాక్టివిటీస్ సమయంలో వారి పిల్లలు చేసిన పనిని వారికి చూపిస్తాడు. ఇది వారి జీవితంలో వారికి ఎలా సహాయపడుతుందో కూడా అతను చెప్తాడు.",
              "or": "ମାଧବ ସେମାନଙ୍କୁ କାର୍ଯ୍ୟକଳାପ ସମୟରେ ସେମାନଙ୍କ ପିଲାମାନଙ୍କର\n  କାର୍ଯ୍ୟ ଦେଖାଏ | ଏହା ମଧ୍ୟ ସେମାନଙ୍କ ଜୀବନ ମାଧ୍ୟମରେ କିପରି ସାହାଯ୍ୟ କରିପାରିବ ସେ ଅଂଶୀଦାର କରେ |",
              "as": "মাধৱে তেওঁলোকক কামৰ সময়ত তেওঁলোকৰ সন্তানৰ কামবোৰ দেখুৱায়। তেওঁ এইটোও কয় যে ই তেওঁলোকক তেওঁলোকৰ জীৱনত কেনেদৰে সহায় কৰিব পাৰে।",
              "gu": "પ્રવૃત્તિઓ દરમિયાન માધવ તેમને તેમના બાળકોનું કામ બતાવે છે. તે તેમના જીવનમાં કેવી રીતે મદદ કરી શકે તે પણ બતાવે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2ii_3_3",
              "hi": "B2II_3_3",
              "ka": "B2ii_3_3",
              "te": "B2ii_3_3",
              "or": "B2ii_3_3",
              "as": "B2ii_3_3",
              "gu": "B2ii_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The parents take some time to understand but feel very happy with this change in the school.",
              "hi": "माता-पिता को समझने में कुछ समय लगता है लेकिन स्कूल में इस बदलाव से बहुत प्रसन्न हैं।",
              "ka": "ಪೋಷಕರು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಸ್ವಲ್ಪ ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತಾರೆ ಆದರೆ ಶಾಲೆಯ ಈ ಬದಲಾವಣೆಯಿಂದ ತುಂಬಾ ಸಂತೋಷಪಡುತ್ತಾರೆ.",
              "te": "తల్లిదండ్రులు అర్థం చేసుకోవడానికి కొంత సమయం తీసుకుంటారు కానీ పాఠశాలలో ఈ మార్పుతో చాలా సంతోషంగా ఉన్నారు.",
              "or": "ପିତାମାତାମାନେ ବୁଝିବା ପାଇଁ କିଛି ସମୟ ନିଅନ୍ତି କିନ୍ତୁ ବିଦ୍ୟାଳୟର\n  ଏହି ପରିବର୍ତ୍ତନକୁ ନେଇ ବହୁତ ଖୁସି ଅନୁଭବ କରନ୍ତି |",
              "as": "অভিভাৱকসকলে বুজিবলৈ কিছু সময় লয় কিন্তু বিদ্যালয়ৰ এই পৰিৱৰ্তনত খুব সুখী অনুভৱ কৰে।",
              "gu": "વાલીઓ સમજવામાં થોડો સમય લે છે પરંતુ શાળામાં આ બદલાવથી ખૂબ જ આનંદ અનુભવે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2ii_3_4",
              "hi": "B2II_3_4",
              "ka": "B2ii_3_4",
              "te": "B2ii_3_4",
              "or": "B2ii_3_4",
              "as": "B2ii_3_4",
              "gu": "B2ii_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Soon Madhav and other teachers arrange money to buy necessary things like musical instruments and art kits.",
              "hi": "जल्द ही माधव और अन्य शिक्षक संगीत वाद्ययंत्र और कला संबंधी आवश्यक चीजें खरीदने के लिए धन की व्यवस्था करते हैं।",
              "ka": "ಶೀಘ್ರದಲ್ಲೇ ಮಾಧವ್ ಮತ್ತು ಇತರ ಶಿಕ್ಷಕರು ಸಂಗೀತ ಉಪಕರಣಗಳು ಮತ್ತು ಆರ್ಟ್ ಕಿಟ್‌ಗಳಂತಹ ಅಗತ್ಯ ವಸ್ತುಗಳನ್ನು ಖರೀದಿಸಲು ಹಣವನ್ನು ವ್ಯವಸ್ಥೆ ಮಾಡುತ್ತಾರೆ.",
              "te": "వెంటనే మాధవ్ మరియు ఇతర ఉపాధ్యాయులు సంగీత వాయిద్యాలు మరియు ఆర్ట్ కిట్‌ల వంటి అవసరమైన వస్తువులను కొనుగోలు చేయడానికి డబ్బును ఏర్పాటు చేస్తారు.",
              "or": "ଖୁବ୍ ଶୀଘ୍ର ମାଧବ ଏବଂ ଅନ୍ୟ ଶିକ୍ଷକମାନେ ବାଦ୍ୟଯନ୍ତ୍ର ଏବଂ ଚିତ୍ର ସାମଗ୍ରୀ\n  ଭଳି ଆବଶ୍ୟକୀୟ ଜିନିଷ କିଣିବା ପାଇଁ ଅର୍ଥ ବ୍ୟବସ୍ଥା କରନ୍ତି |",
              "as": "সোনকালেই মাধৱ আৰু অন্যান্য শিক্ষকসকলে বাদ্যযন্ত্ৰ আৰু কলা সামগ্ৰীৰ দৰে প্ৰয়োজনীয় বস্তু ক্ৰয় কৰিবলৈ ধনৰ ব্যৱস্থা কৰে।",
              "gu": "ટૂંક સમયમાં જ માધવ અને અન્ય શિક્ષકો સંગીતનાં સાધનો અને આર્ટ કીટ જેવી જરૂરી વસ્તુઓ ખરીદવા પૈસાની વ્યવસ્થા કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2ii_1_3",
              "hi": "B2II_1_3",
              "ka": "B2ii_1_3",
              "te": "B2ii_1_3",
              "or": "B2ii_1_3",
              "as": "B2ii_1_3",
              "gu": "B2ii_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The students start having regular co-curricular activities, and they enjoy coming to the school even more.",
              "hi": "छात्रों की नियमित सह-पाठ्यचर्या गतिविधियां आरम्भ होती हैं, और वे स्कूल में और भी अधिक आनंद लेते हैं।",
              "ka": "ವಿದ್ಯಾರ್ಥಿಗಳು ನಿಯಮಿತ ಸಹ-ಪಠ್ಯ ಚಟುವಟಿಕೆಗಳನ್ನು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ ಮತ್ತು ಅವರು ಶಾಲೆಗೆ ಬರುವುದನ್ನು ಇನ್ನಷ್ಟು ಆನಂದಿಸುತ್ತಾರೆ.",
              "te": "విద్యార్థులు రెగ్యులర్ గా కో-కరిక్యులర్ యాక్టివిటీస్ చేయడం ప్రారంభిస్తారు మరియు వారు పాఠశాలకు రావడాన్ని మరింత ఆనందిస్తారు.",
              "or": "ଛାତ୍ରମାନେ ନିୟମିତ ସହ-ପାଠ୍ୟକ୍ରମ କାର୍ଯ୍ୟକଳାପ ଆରମ୍ଭ କରନ୍ତି,\n  ଏବଂ ସେମାନେ ବିଦ୍ୟାଳୟକୁ ଆହୁରି ଅଧିକ ଉପଭୋଗ କରନ୍ତି |",
              "as": "শিক্ষাৰ্থীসকলে নিয়মীয়াকৈ সহ-পাঠ্যক্ৰম ৰখা আৰম্ভ কৰে, আৰু তেওঁলোকে বিদ্যালয়লৈ অহাটো আৰু অধিক উপভোগ কৰে।",
              "gu": "વિદ્યાર્થીઓ નિયમિત સહ-અભ્યાસિક પ્રવૃતિઓ કરવાનું શરૂ કરે છે, અને તેઓ શાળામાં આવવાનો વધુ આનંદ માણે છે."
            }
          }
        ]
      },
      "scene25": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_1_1",
              "hi": "B3_1_1",
              "ka": "B3_1_1",
              "te": "B3_1_1",
              "or": "B3_1_1",
              "as": "B3_1_1",
              "gu": "B3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav enjoys working as a teacher. It is a tough job, but he feels his work is making a difference to the students.",
              "hi": "माधव एक शिक्षक के रूप में काम करने का आनंद लेता है। यह एक कठिन काम है, लेकिन वहअनुभव करता है कि उसका काम छात्रों में एक अंतर बना रहा है।",
              "ka": "ಮಾಧವ್ ಶಿಕ್ಷಕರಾಗಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಆನಂದಿಸುತ್ತಾನೆ. ಇದು ಕಠಿಣ ಕೆಲಸ, ಆದರೆ ಆತನ ಕೆಲಸವು ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ವ್ಯತ್ಯಾಸವನ್ನುಂಟುಮಾಡುತ್ತಿದೆ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్‌కి టీచర్‌గా పని చేయడం చాలా ఇష్టం. ఇది చాలా కష్టమైన పని, కానీ తన పని విద్యార్థులలో మార్పు తెస్తుందని అతను భావిస్తున్నాడు.",
              "or": "ମାଧବ ଜଣେ ଶିକ୍ଷକ ଭାବରେ କାମ କରିବାକୁ ଉପଭୋଗ କରନ୍ତି | ଏହା\n  ଏକ କଠିନ କାର୍ଯ୍ୟ, କିନ୍ତୁ ସେ ଅନୁଭବ କରନ୍ତି ଯେ ତାଙ୍କର କାର୍ଯ୍ୟ ଛାତ୍ରମାନଙ୍କ ପାଇଁ ଏକ ପରିବର୍ତ୍ତନ ଆଣୁଛି |",
              "as": "মাধৱে শিক্ষক হিচাপে কাম কৰি ভাল পাইছে। এইটো এটা কঠিন কাম, কিন্তু সি অনুভৱ কৰে যে তাৰ কামে শিক্ষাৰ্থীসকলৰ বাবে এক পাৰ্থক্য আনিছে।",
              "gu": "માધવને શિક્ષક તરીકે કામ કરવાની મજા આવે છે. આ એક અઘરું કામ છે, પરંતુ તેને લાગે છે કે તેનું કામ વિદ્યાર્થીઓમાં ફરક લાવી રહ્યું છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_1_2",
              "hi": "B3_1_2",
              "ka": "B3_1_2",
              "te": "B3_1_2",
              "or": "B3_1_2",
              "as": "B3_1_2",
              "gu": "B3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The teachers are friendly and support each other at work. Madhav is thankful that the parents also support the different initiatives taken at the school.",
              "hi": "शिक्षक मित्रवत हैं और काम पर एक-दूसरे का सहयोग करते हैं। माधव आभारी है कि माता-पिता स्कूल में ली गई विभिन्न पहलों का भी समर्थन करते हैं।",
              "ka": "ಶಿಕ್ಷಕರು ಸ್ನೇಹಪರರಾಗಿದ್ದಾರೆ ಮತ್ತು ಕೆಲಸದಲ್ಲಿ ಪರಸ್ಪರ ಬೆಂಬಲಿಸುತ್ತಾರೆ. ಶಾಲೆಯಲ್ಲಿ ಕೈಗೊಂಡ ವಿಭಿನ್ನ ಉಪಕ್ರಮಗಳಿಗೆ ಪೋಷಕರೂ ಬೆಂಬಲ ನೀಡುತ್ತಿರುವುದಕ್ಕೆ ಮಾಧವ್ ಧನ್ಯವಾದ ಅರ್ಪಿಸಿದ್ದಾರೆ.",
              "te": "ఉపాధ్యాయులు స్నేహపూర్వకంగా ఉంటారు మరియు పనిలో ఒకరికొకరు మద్దతు ఇస్తారు. పాఠశాలలో చేపట్టిన విభిన్న కార్యక్రమాలకు తల్లిదండ్రులు కూడా మద్దతు ఇస్తున్నందుకు మాధవ్ కృతజ్ఞతలు తెలిపారు.",
              "or": "ଶିକ୍ଷକମାନେ ବନ୍ଧୁତ୍ୱପୂର୍ଣ୍ଣ ଏବଂ କାର୍ଯ୍ୟରେ ପରସ୍ପରକୁ ସମର୍ଥନ କରନ୍ତି | \n ବିଦ୍ୟାଳୟରେ ନିଆଯାଇଥିବା ବିଭିନ୍ନ ପଦକ୍ଷେପକୁ ପିତାମାତା ମଧ୍ୟ ସମର୍ଥନ କରନ୍ତି ବୋଲି ମାଧବ କୃତଜ୍ଞ।",
              "as": "শিক্ষকসকল বন্ধুত্বপূৰ্ণ আৰু কামত ইজনে সিজনক সমৰ্থন কৰে। মাধৱ কৃতজ্ঞ যে অভিভাৱকসকলেও বিদ্যালয়ত লোৱা বিভিন্ন পদক্ষেপক সমৰ্থন কৰিছে।",
              "gu": "શિક્ષકો મૈત્રીપૂર્ણ છે અને કામ પર એકબીજાને ટેકો આપે છે. માધવ આભારી છે કે માતા-પિતા પણ શાળામાં લેવામાં આવેલી વિવિધ પહેલને સમર્થન આપે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_2_1",
              "hi": "B3_2_1",
              "ka": "B3_2_1",
              "te": "B3_2_1",
              "or": "B3_2_1",
              "as": "B3_2_1",
              "gu": "B3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav and a few teachers start an after school learning group for students who want extra support with their studies.",
              "hi": " माधव और कुछ शिक्षक एक लर्निंग समूह उन छात्रों के लिए शुरू करते हैं जो स्कूल के बाद अपने अध्ययन के साथ अतिरिक्त सहयोग चाहते हैं।",
              "ka": "ಮಾಧವ್ ಮತ್ತು ಕೆಲವು ಶಿಕ್ಷಕರು ತಮ್ಮ ಅಧ್ಯಯನದೊಂದಿಗೆ ಹೆಚ್ಚುವರಿ ಬೆಂಬಲವನ್ನು ಬಯಸುವ ವಿದ್ಯಾರ್ಥಿಗಳಿಗಾಗಿ ಶಾಲೆಯ ನಂತರದ ಕಲಿಕೆಯ ಗುಂಪನ್ನು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ.",
              "te": "మాధవ్ మరియు కొంతమంది ఉపాధ్యాయులు చదువులలో ఎక్కువ శ్రద్ధ కోరుకునే విద్యార్థుల కోసం పాఠశాల తర్వాత ఒక లర్నింగ్ గ్రూప్ ప్రారంభించారు.",
              "or": "ମାଧବ ଏବଂ କିଛି ଶିକ୍ଷକ ସେମାନଙ୍କ ଅଧ୍ୟୟନ ସହିତ ଅତିରିକ୍ତ ସମର୍ଥନ\n  ଚାହୁଁଥିବା ଛାତ୍ରମାନଙ୍କ ପାଇଁ ବିଦ୍ୟାଳୟ ଶିକ୍ଷା ଗୋଷ୍ଠୀ ଆରମ୍ଭ କରନ୍ତି |",
              "as": "মাধৱ আৰু কেইজনমান শিক্ষকে পঢ়াশুনাত অতিৰিক্ত সমৰ্থন বিচৰা শিক্ষাৰ্থীসকলৰ বাবে বিদ্যালয়ৰ পিছত এটা শিক্ষণ গোট আৰম্ভ কৰিছে।",
              "gu": "માધવ અને થોડા શિક્ષકો તેમના અભ્યાસમાં વધારાનો સહયોગ ઈચ્છતા વિદ્યાર્થીઓ માટે શાળા પછીનું શિક્ષણ જૂથ શરૂ કરે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_2_2",
              "hi": "B3_2_2",
              "ka": "B3_2_2",
              "te": "B3_2_2",
              "or": "B3_2_2",
              "as": "B3_2_2",
              "gu": "B3_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Though Madhav does not teach students from the 10th standard classes, he supports the teachers in preparing the students for board exams.",
              "hi": "हालांकि माधव 10वीं कक्षा के छात्रों को नहीं पढ़ाता है, लेकिन वह बोर्ड परीक्षा के लिए छात्रों की तैयारी में शिक्षकों का सहयोग करता है।",
              "ka": "ಮಾಧವ್ 10 ನೇ ತರಗತಿಯ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಪಾಠ ಮಾಡದಿದ್ದರೂ, ಬೋರ್ಡ್ ಪರೀಕ್ಷೆಗಳಿಗೆ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಸಿದ್ಧಪಡಿಸುವಲ್ಲಿ ಶಿಕ್ಷಕರಿಗೆ ಬೆಂಬಲ ನೀಡುತ್ತಾನೆ.",
              "te": "మాధవ్ 10వ తరగతి విద్యార్థులకు బోధించనప్పటికీ, అతను విద్యార్థులను బోర్డు పరీక్షలకు సిద్ధం చేయడంలో ఉపాధ్యాయులకు సహాయం చేస్తాడు.",
              "or": "ଯଦିଓ ମାଧବ ଦଶମ ମାନକ ଶ୍ରେଣୀର ଛାତ୍ରମାନଙ୍କୁ ଶିକ୍ଷା ଦିଅନ୍ତି ନାହିଁ,\n  ସେ ବୋର୍ଡ ପରୀକ୍ଷା ପାଇଁ ଛାତ୍ରମାନଙ୍କୁ ପ୍ରସ୍ତୁତ କରିବାରେ ଶିକ୍ଷକମାନଙ୍କୁ ସମର୍ଥନ କରନ୍ତି |",
              "as": "যদিও মাধৱে ১০ম শ্ৰেণীৰ শিক্ষাৰ্থীসকলক নপঢ়ায়, তেওঁ শিক্ষাৰ্থীসকলক ব'ৰ্ড পৰীক্ষাৰ বাবে প্ৰস্তুত কৰাত শিক্ষকসকলক সহায় কৰে।",
              "gu": "જોકે માધવ 10મા ધોરણના વિદ્યાર્થીઓને ભણાવતો નથી, પરંતુ તે વિદ્યાર્થીઓને બોર્ડની પરીક્ષા માટે તૈયાર કરવામાં શિક્ષકોને મદદ કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_2_3",
              "hi": "B3_2_3",
              "ka": "B3_2_3",
              "te": "B3_2_3",
              "or": "B3_2_3",
              "as": "B3_2_3",
              "gu": "B3_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav enjoys working in the village and also living there. Kuldeep and Badhri visit him often. He sends money to his father regularly.",
              "hi": "माधव गांव में काम करने और वहां रहने का आनंद लेता है। कुलदीप और बद्री अक्सर उसे मिलते हैं। वह अपने पिता को नियमित रूप से पैसे भेजता है।",
              "ka": "ಮಾಧವ್ ಹಳ್ಳಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಆನಂದಿಸುತ್ತಾನೆ ಮತ್ತು ಅಲ್ಲಿ ವಾಸಿಸುತ್ತಾನೆ. ಕುಲದೀಪ್ ಮತ್ತು ಬದ್ರಿ ಅವರನ್ನು ಆಗಾಗ್ಗೆ ಭೇಟಿ ಮಾಡುತ್ತಿರುತ್ತಾನೆ. ಅವನು ತನ್ನ ತಂದೆಗೆ ನಿಯಮಿತವಾಗಿ ಹಣವನ್ನು ಕಳುಹಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్ కు గ్రామంలో పని చేయడం ఇష్టం మరియు అక్కడే నివసిస్తూ ఉంటాడు. కుల్దీప్ మరియు బద్రి తరచుగా అతనిని సందర్శించేవారు. అతను తన తండ్రికి క్రమం తప్పకుండా డబ్బు పంపేవాడు.",
              "or": "ମାଧବ ଗାଁରେ କାମ କରିବା ଏବଂ ସେଠାରେ ରହିବାକୁ ମଧ୍ୟ ଉପଭୋଗ\n  କରନ୍ତି | କୁଲଦୀପ ଏବଂ ବଦ୍ରି ତାଙ୍କୁ ବାରମ୍ବାର ଦେଖା କରନ୍ତି | ସେ ନିୟମିତ ଭାବେ ତାଙ୍କ ପିତାଙ୍କ ନିକଟକୁ ଟଙ୍କା ପଠାନ୍ତି |",
              "as": "মাধৱে গাওঁখনত কাম কৰি আৰু তাত বাস কৰি ভাল পাইছে। কুলদীপ আৰু বদ্ৰীয়ে প্ৰায়ে তেওঁক লগ কৰে। তেওঁ নিয়মীয়াকৈ দেউতাকলৈ টকা প্ৰেৰণ কৰে।",
              "gu": "માધવને ગામમાં કામ કરવાની અને ત્યાં રહેવાની પણ મજા આવે છે. કુલદીપ અને બદ્રિ અવારનવાર તેની મુલાકાત લે છે. તે તેના પિતાને નિયમિત રીતે પૈસા મોકલે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "Teacher in government school",
              "hi": "सरकारी स्कूल में शिक्षक",
              "ka": "ಸರಕಾರೀ ಶಾಲೆಯಲ್ಲಿ ಒಬ್ಬ ಶಿಕ್ಷಕ",
              "te": "ప్రభుత్వ పాఠశాలలో ఉపాధ్యాయుడు",
              "or": "ସରକାରୀ ବିଦ୍ୟାଳୟରେ ଶିକ୍ଷକ |",
              "as": "চৰকাৰী বিদ্যালয়ৰ শিক্ষক",
              "gu": "સરકારી શાળામાં શિક્ષક"
            }
          }
        ]
      },
      "scene26": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Apply at a Private School",
              "hi": "एक निजी स्कूल में आवेदन करे",
              "ka": "ಖಾಸಗಿ ಶಾಲೆಗೆ ಅರ್ಜಿ ಹಾಕುವುದು",
              "te": "ప్రైవేట్ స్కూల్లో అప్లై చేయడం",
              "or": "ଏକ ଘରୋଇ ବିଦ୍ୟାଳୟରେ ଆବେଦନ କରନ୍ତୁ |",
              "as": "ব্যক্তিগত বিদ্যালয়ত আবেদন",
              "gu": "ખાનગી શાળામાં અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After thinking about the kind of school he wants to work in, Madhav decides to apply to a private school, Kings Public School Lucknow.",
              "hi": "यह सोचने के बाद कि वह किस तरह के स्कूल में काम करना चाहता है, माधव एक निजी स्कूल, किंग्स पब्लिक स्कूल लखनऊ में आवेदन करने का निर्णय करता है।",
              "ka": "ತಾನು ಯಾವ ರೀತಿಯ ಶಾಲೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಬೇಕೆಂದು ಯೋಚಿಸಿದ ನಂತರ, ಮಾಧವ್ ಖಾಸಗಿ ಶಾಲೆಯಾದ ಕಿಂಗ್ಸ್ ಪಬ್ಲಿಕ್ ಸ್ಕೂಲ್ ಲಕ್ನೋಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "అతను ఏ రకమైన పాఠశాలలో పని చేయాలనుకుంటున్నాడో ఆలోచించిన తర్వాత, మాధవ్ లక్నో లోని ప్రైవేట్ పాఠశాల అయిన కింగ్స్ పబ్లిక్ స్కూల్ కి అప్లై చేయాలని నిర్ణయించుకున్నాడు.",
              "or": "ସେ କେଉଁ ପ୍ରକାର ବିଦ୍ୟାଳୟରେ କାମ କରିବାକୁ ଚାହୁଁଛନ୍ତି ସେ ବିଷୟରେ\n  ଚିନ୍ତା କରିବା ପରେ, ମାଧବ ଏକ ଘରୋଇ ବିଦ୍ୟାଳୟ, କିଙ୍ଗସ୍ ପବ୍ଲିକ୍ ସ୍କୁଲ୍ ଲକ୍ଷ୍ନୋରେ ଆବେଦନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "তেওঁ কেনে ধৰণৰ বিদ্যালয়ত কাম কৰিব বিচাৰে সেই বিষয়ে চিন্তা কৰাৰ পিছত, মাধৱে এখন ব্যক্তিগত বিদ্যালয়, কিংছ পাব্লিক স্কুল লক্ষ্ণৌত আবেদন কৰাৰ সিদ্ধান্ত লয়।",
              "gu": "તે કેવા પ્રકારની શાળામાં કામ કરવા માંગે છે તે વિશે વિચાર્યા પછી, માધવ એક ખાનગી શાળા, કિંગ્સ પબ્લિક સ્કૂલ લખનૌમાં અરજી કરવાનું નક્કી કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_1_2",
              "hi": "B4_1_2",
              "ka": "B4_1_2",
              "te": "B4_1_2",
              "or": "B4_1_2",
              "as": "B4_1_2",
              "gu": "B4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He joins the school as a History teacher for the 6th to 8th standard. He makes history interesting for the students by taking them for learning walks in the city to understand the city's history.",
              "hi": "वह 6वीं से 8वीं कक्षा के लिए इतिहास शिक्षक के रूप में स्कूल में नौकरी करता है। वह छात्रों को शहर के इतिहास को समझने के लिए शहर में घूमने के लिए ले जाकर इतिहास को रोचक बनाता है।",
              "ka": "ಅವನು 6 ರಿಂದ 8 ನೇ ತರಗತಿಗೆ ಇತಿಹಾಸ ಶಿಕ್ಷಕನಾಗಿ ಶಾಲೆಗೆ ಸೇರುತ್ತಾನೆ. ಅವನು ನಗರದ ಇತಿಹಾಸವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ನಗರದಲ್ಲಿ ಕಲಿಯಲು ನಡಿಗೆಯಲ್ಲಿ ಕರೆದೊಯ್ಯುವ ಮೂಲಕ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಇತಿಹಾಸವನ್ನು ಆಸಕ್ತಿದಾಯಕವಾಗಿಸುತ್ತಾನೆ.",
              "te": "పాఠశాలలో 6 నుంచి 8వ తరగతి వరకు హిస్టరీ టీచర్‌గా చేరాడు. నగర చరిత్రను అర్థం చేసుకునేందుకు విద్యార్థులను నగరంలో నడకలకు తీసుకెళ్లి చరిత్రను వారికి ఆసక్తికరంగా తీర్చిదిద్దాడు.",
              "or": "ସେ ଷଷ୍ଠରୁ ଅଷ୍ଟମ ଷ୍ଟାଣ୍ଡାର୍ଡ ପାଇଁ ଇତିହାସ ଶିକ୍ଷକ ଭାବରେ ବିଦ୍ୟାଳୟରେ\n  ଯୋଗ ଦିଅନ୍ତି | ସେ ସହରର ଇତିହାସକୁ ବୁଝିବା ପାଇଁ ସହରରେ ବୁଲିବା ପାଇଁ ନେଇ ଛାତ୍ରମାନଙ୍କ ପାଇଁ ଇତିହାସକୁ ଆକର୍ଷଣୀୟ କରିଥାଏ |",
              "as": "তেওঁ ৬ৰ পৰা ৮ম শ্ৰেণীৰ বাবে ইতিহাসৰ শিক্ষক হিচাপে বিদ্যালয়ত যোগদান কৰে। তেওঁ চহৰখনৰ ইতিহাস বুজি পাবলৈ শিক্ষাৰ্থীসকলক চহৰখনত খোজ কাঢ়িবলৈ লৈ যায় যিয়ে ইতিহাসক আকৰ্ষণীয় কৰি তোলে।",
              "gu": "તે 6ઠ્ઠા થી 8મા ધોરણ માટે ઈતિહાસ શિક્ષક તરીકે શાળામાં જોડાય છે. તે વિદ્યાર્થીઓને શહેરના ઈતિહાસને સમજવા માટે શહેરમાં શીખવા માટે લઈ જઈને ઈતિહાસને રસપ્રદ બનાવે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_2_1",
              "hi": "B4_2_1",
              "ka": "B4_2_1",
              "te": "B4_2_1",
              "or": "B4_2_1",
              "as": "B4_2_1",
              "gu": "B4_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The academic coordinator is supportive of Madhav’s new idea of historical walks. Students love these walks. Slowly he settles well in the school.",
              "hi": "अकादमिक कोऑर्डिनेटर माधव के ऐतिहासिक दौर के नए विचार के समर्थक हैं। छात्रों को ये सैर बहुत पसंद है। धीरे-धीरे वह स्कूल में अच्छी तरह से बस जाता है।",
              "ka": "ಮಾಧವ್ ಅವರ ಐತಿಹಾಸಿಕ ನಡೆಗಳ ಹೊಸ ಕಲ್ಪನೆಗೆ ಶೈಕ್ಷಣಿಕ ಸಂಯೋಜಕರು ಬೆಂಬಲ ನೀಡಿದ್ದಾರೆ. ವಿದ್ಯಾರ್ಥಿಗಳು ಈ ನಡಿಗೆಗಳನ್ನು ಇಷ್ಟಪಡುತ್ತಾರೆ. ನಿಧಾನವಾಗಿ ಶಾಲೆಯಲ್ಲಿ ಚೆನ್ನಾಗಿ ನೆಲೆಸುತ್ತಾನೆ.",
              "te": "అకడమిక్ కోఆర్డినేటర్ కు చారిత్రక నడకల గురించి మాధవ్ యొక్క కొత్త ఆలోచన బాగా నచ్చుతుంది. విద్యార్థులు ఈ నడకలను ఇష్టపడతారు. మెల్లగా అతను స్కూల్లో బాగా సెటిల్ అయ్యాడు.",
              "or": "ଐତିହାସିକ ପଦଯାତ୍ରାର ମାଧବଙ୍କ ନୂତନ ଧାରଣାକୁ ଏକାଡେମିକ୍ \n ସଂଯୋଜକ ସମର୍ଥନ କରନ୍ତି | ଛାତ୍ରମାନେ ଏହି ପଦଯାତ୍ରାକୁ ଭଲ ପାଆନ୍ତି | ଧିରେ ଧିରେ ସେ ବିଦ୍ୟାଳୟରେ ଭଲ ଭାବରେ ସ୍ଥିର ହୁଏ |",
              "as": "শৈক্ষিক সমন্বয়কাৰীজনে মাধৱৰ ঐতিহাসিক পদযাত্ৰাৰ নতুন ধাৰণাটো সমৰ্থন কৰে। শিক্ষাৰ্থীসকলে খোজকাঢ়ি ভাল পায়। লাহে লাহে তেওঁ বিদ্যালয়ত ভালদৰে থিতাপি লয়।",
              "gu": "શૈક્ષણિક સંયોજક ઐતિહાસિક પદયાત્રાના માધવના નવા વિચારને સમર્થન આપે છે. વિદ્યાર્થીઓને આ ચાલવાનું ગમે છે. ધીમે ધીમે તે શાળામાં સારી રીતે સ્થાયી થાય છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_2_2",
              "hi": "B4_2_2",
              "ka": "B4_2_2",
              "te": "B4_2_2",
              "or": "B4_2_2",
              "as": "B4_2_2",
              "gu": "B4_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "With a steady job, he sends money to his father. Though he does not have the best relationship with his father, he visits him once in a while.",
              "hi": "स्थिर नौकरी के साथ, वह अपने पिता को पैसे भेजता है। हालाँकि उसके अपने पिता के साथ सबसे अच्छे संबंध नहीं हैं, लेकिन वह कभी-कभार उनसे मिलने आता है।",
              "ka": "ಸ್ಥಿರವಾದ ಉದ್ಯೋಗದೊಂದಿಗೆ, ಅವನು ತನ್ನ ತಂದೆಗೆ ಹಣವನ್ನು ಕಳುಹಿಸುತ್ತಾನೆ. ತನ್ನ ತಂದೆಯೊಂದಿಗೆ ಉತ್ತಮ ಸಂಬಂಧವನ್ನು ಹೊಂದಿಲ್ಲದಿದ್ದರೂ, ಅವನು ಒಮ್ಮೆ ಅವನನ್ನು ಭೇಟಿಯಾಗುತ್ತಾನೆ.",
              "te": "స్థిరమైన ఉద్యోగంతో, అతను తన తండ్రికి డబ్బు పంపుతాడు. తన తండ్రితో అతనికి మంచి అనుబంధం లేకపోయినా, అతను అప్పుడప్పుడు అతనిని సందర్శించేవాడు.",
              "or": "ଏକ ସ୍ଥିର ଚାକିରି ସହିତ ସେ ନିଜ ପିତାଙ୍କୁ ଟଙ୍କା ପଠାନ୍ତି | ଯଦିଓ ତାଙ୍କ \n ପିତାଙ୍କ ସହିତ ତାଙ୍କର ସର୍ବୋତ୍ତମ ସମ୍ପର୍କ ନାହିଁ, ସେ ଥରେ ତାଙ୍କୁ ଦେଖା କରନ୍ତି |",
              "as": "স্থিৰ চাকৰিৰ সৈতে, সি তাৰ দেউতাকলৈ টকা প্ৰেৰণ কৰে। যদিও তাৰ দেউতাকৰ সৈতে ঘনিষ্ঠ সম্পৰ্ক নাই, সি মাজে মাজে তেওঁক সাক্ষাৎ কৰে।",
              "gu": "સ્થિર નોકરી સાથે, તે તેના પિતાને પૈસા મોકલે છે. તેના પિતા સાથે તેના શ્રેષ્ઠ સંબંધ ન હોવા છતાં, તે સમયાંતરે તેની મુલાકાત લે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_3_1",
              "hi": "B4_3_1",
              "ka": "B4_3_1",
              "te": "B4_3_1",
              "or": "B4_3_1",
              "as": "B4_3_1",
              "gu": "B4_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "A few years into teaching, he feels like something is missing. He talks to the academic coordinator to discuss his growth in the school.",
              "hi": " शिक्षण में कुछ साल बाद, वह अनुभव करता है कि कुछ कमी है। वह स्कूल में अपनी तरक्की  पर चर्चा करने के लिए अकादमिक कोऑर्डिनेटर/ समन्वयक से बात करता है।",
              "ka": "ಕೆಲವು ವರ್ಷಗಳ ಕಾಲ ಕಲಿಸುವಾಗ, ಅವನಿಗೆ ಏನೋ ಕಳೆದುಕೊಂಡಂತೆ ಅನಿಸುತ್ತದೆ. ಶಾಲೆಯಲ್ಲಿ ತನ್ನ ಬೆಳವಣಿಗೆಯನ್ನು ಚರ್ಚಿಸಲು ಅವನು ಶೈಕ್ಷಣಿಕ ಸಂಯೋಜಕರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾನೆ.",
              "te": "టీచింగ్‌లో కొన్ని సంవత్సరాల తర్వాత, అతను ఏదో కోల్పోయినట్లు అనిపిస్తుంది. అతను పాఠశాలలో తన ఎదుగుదల గురించి చర్చించడానికి అకడమిక్ కోఆర్డినేటర్‌తో మాట్లాడతాడు.",
              "or": "ଶିକ୍ଷାଦାନରେ କିଛି ବର୍ଷ, ସେ ଅନୁଭବ କରନ୍ତି ଯେ କିଛି ଅଭାବ ଅଛି |\n  ସେ ବିଦ୍ୟାଳୟରେ ତାଙ୍କର ଅଭିବୃଦ୍ଧି ବିଷୟରେ ଆଲୋଚନା କରିବା ପାଇଁ ଏକାଡେମିକ୍ ସଂଯୋଜକଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରନ୍ତି |",
              "as": "শিক্ষাদানৰ কেইবছৰমানৰ ভিতৰতে তেওঁ অনুভৱ কৰে যেন কিবা এটাৰ অভাৱ আছে। তেওঁ বিদ্যালয়ত তেওঁৰ বিকাশৰ বিষয়ে আলোচনা কৰিবলৈ শৈক্ষিক সমন্বয়কাৰীৰ সৈতে কথা পাতে।",
              "gu": "શિક્ષણના થોડા વર્ષો પછી, તેને લાગે છે કે કંઈક ખૂટે છે. તે શાળામાં તેની વૃદ્ધિ અંગે ચર્ચા કરવા શૈક્ષણિક સંયોજક સાથે વાત કરે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_3_2_growthChart",
              "hi": "B4_3_2_ग्रोथचार्ट ",
              "ka": "B4_3_2_growthChart",
              "te": "B4_3_2_గ్రోత్ చార్ట్",
              "or": "B4_3_2_ ଗ୍ରୋଥ୍ ଚାର୍ଟ |",
              "as": "B4_3_2_বিকাশতালিকা",
              "gu": "B4_3_2_વૃદ્ધિ ચાર્ટ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "They sit together to make his career growth chart.",
              "hi": "वे अपने करियर के विकास को चार्ट बनाने के लिए एक साथ बैठते हैं।",
              "ka": "ಅವನ ವೃತ್ತಿಜೀವನದ ಬೆಳವಣಿಗೆಯ ಚಾರ್ಟ್ ಮಾಡಲು ಅವರು ಒಟ್ಟಿಗೆ ಕುಳಿತುಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "అతని కెరీర్ గ్రోత్ చార్ట్ ను రూపొందించడానికి వారు కలిసి కూర్చున్నారు.",
              "or": "ତାଙ୍କ କ୍ୟାରିୟର ଅଭିବୃଦ୍ଧି ଚାର୍ଟ ପ୍ରସ୍ତୁତ କରିବାକୁ ସେମାନେ ଏକାଠି ବସନ୍ତି |",
              "as": "তেওঁলোকে তেওঁৰ জীৱিকাৰ বিকাশৰ তালিকা তৈয়াৰ কৰিবলৈ একেলগে বহে।",
              "gu": "તેઓ તેની કારકિર્દી વૃદ્ધિ ચાર્ટ બનાવવા માટે સાથે બેસીને."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Assistant Teacher",
              "hi": "सह अध्यापक",
              "ka": "ಸಹಾಯಕ ಶಿಕ್ಷಕ",
              "te": "అసిస్టెంట్ టీచర్",
              "or": "ସହକାରୀ ଶିକ୍ଷକ",
              "as": "সহকাৰী শিক্ষক",
              "gu": "મદદનીશ શિક્ષક"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Senior Teacher",
              "hi": "वरिष्ठ शिक्षक",
              "ka": "ಹಿರಿಯ ಶಿಕ್ಷಕ",
              "te": "సీనియర్ టీచర్",
              "or": "ବରିଷ୍ଠ ଶିକ୍ଷକ",
              "as": "জ্যেষ্ঠ শিক্ষক",
              "gu": "વરિષ્ઠ શિક્ષક"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Academic Coordinator",
              "hi": "अकादमिक कोऑर्डिनेटर/ समन्वयक",
              "ka": "ಶೈಕ್ಷಣಿಕ ಸಂಯೋಜಕ",
              "te": "అకడమిక్ కోఆర్డినేటర్",
              "or": "ଏକାଡେମିକ୍ ସଂଯୋଜକ |",
              "as": "একাডেমিক সমন্বয়ক",
              "gu": "શૈક્ષણિક સંયોજક"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Vice Principal",
              "hi": "वाइस प्रिंसिपल",
              "ka": "ಉಪ ಪ್ರಾದ್ಯಾಪಕರು",
              "te": "వైస్ ప్రిన్సిపాల్",
              "or": "ଉପାଧ୍ୟକ୍ଷ",
              "as": "ভাইচ প্ৰিন্সিপাল",
              "gu": "ઉપ આચાર્ય"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Principal",
              "hi": "प्रधान अध्यापक",
              "ka": "ಪ್ರಾದ್ಯಾಪಕರು",
              "te": "ప్రిన్సిపాల్",
              "or": "ପ୍ରିନ୍ସିପାଲ୍",
              "as": "প্ৰিন্সিপাল",
              "gu": "આચાર્યશ્રી"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "CareerPlan",
              "hi": "कैरियर योजना",
              "ka": "ವೃತ್ತಿ ಯೋಜನೆ",
              "te": "కెరీర్‌ప్లాన్",
              "or": "କ୍ୟାରିଅର୍ ପ୍ଲାନ୍ |",
              "as": "জীৱিকাপৰিকল্পনা",
              "gu": "કારકિર્દી યોજના"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_3_3",
              "hi": "B4_3_3",
              "ka": "B4_3_3",
              "te": "B4_3_3",
              "or": "B4_3_3",
              "as": "B4_3_3",
              "gu": "B4_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After making the career plan, the academic coordinator shares with Madhav that people with aptitude like his are given preference for senior roles, and he should work towards it.",
              "hi": "करियर योजना बनाने के बाद, अकादमिक  कोऑर्डिनेटर/ समन्वयक माधव के साथ साझा करते हैं कि उनके जैसे योग्यता वाले लोगों को वरिष्ठ भूमिकाओं के लिए प्राथमिकता दी जाती है, और उन्हें इसके लिए काम करना चाहिए।",
              "ka": "ವೃತ್ತಿಜೀವನದ ಯೋಜನೆಯನ್ನು ರೂಪಿಸಿದ ನಂತರ, ಶೈಕ್ಷಣಿಕ ಸಂಯೋಜಕರು ಮಾಧವನೊಂದಿಗೆ ನಿಮ್ಮಂತಹ ಯೋಗ್ಯತೆ ಹೊಂದಿರುವವರಿಗೆ ಹಿರಿಯ ಹುದ್ದೆಗಳಿಗೆ ಆದ್ಯತೆ ನೀಡಲಾಗುತ್ತದೆ ಮತ್ತು ನೀವು ಆ ನಿಟ್ಟಿನಲ್ಲಿ ಕೆಲಸ ಮಾಡಬೇಕು ಎಂದು ಅಭಿಪ್ರಾಯವನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "కెరీర్ ప్లాన్‌ను రూపొందించిన తర్వాత, అకడమిక్ కోఆర్డినేటర్ మాధవ్‌తో తనలాంటి తపన ఉన్న వ్యక్తులకు సీనియర్ పాత్రలకు ప్రాధాన్యత ఇస్తారని మరియు అతను దాని కోసం కృషి చేయాలని తెలిపాడు.",
              "or": "କ୍ୟାରିୟର ଯୋଜନା ପ୍ରସ୍ତୁତ କରିବା ପରେ, ଏକାଡେମିକ୍ ସଂଯୋଜକ ମାଧବଙ୍କ\n  ସହ ଅଂଶୀଦାର କରନ୍ତି ଯେ ତାଙ୍କ ଭଳି ଦକ୍ଷତା ଥିବା ବ୍ୟକ୍ତିଙ୍କୁ ବରିଷ୍ଠ ଭୂମିକା ପାଇଁ ଅଗ୍ରାଧିକାର ଦିଆଯାଏ, ଏବଂ ସେ ଏହା ଦିଗରେ କାର୍ଯ୍ୟ କରିବା ଉଚିତ୍ |",
              "as": "জীৱিকা পৰিকল্পনা প্ৰস্তুত কৰাৰ পিছত, একাডেমিক সমন্বয়কাৰীয়ে মাধৱৰ সৈতে কথা পাতে যে তেওঁৰ দৰে দক্ষতা থকা লোকসকলক জ্যেষ্ঠ ভূমিকাৰ বাবে অগ্ৰাধিকাৰ দিয়া হয়, আৰু তেওঁ ইয়াৰ বাবে কাম কৰা উচিত।",
              "gu": "કારકિર્દીની યોજના બનાવ્યા પછી, શૈક્ષણિક સંયોજક માધવ સાથે બતાવે છે કે તેના જેવા યોગ્યતા ધરાવતા લોકોને વરિષ્ઠ ભૂમિકાઓ માટે પ્રાધાન્ય આપવામાં આવે છે, અને તેણે તે તરફ કામ કરવું જોઈએ."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_3_4",
              "hi": "B4_3_4",
              "ka": "B4_3_4",
              "te": "B4_3_4",
              "or": "B4_3_4",
              "as": "B4_3_4",
              "gu": "B4_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav knows that he has the aptitude to grow and reach the level of the school principal. However, he feels disconnected from students and the school's culture.",
              "hi": "माधव जानता है कि उसमें बढ़ने की योग्यता है और स्कूल के प्रिंसिपल के स्तर तक पहुंचने की योग्यता है। हालांकि, वह छात्रों और स्कूल की संस्कृति से जुड़ा हुआ नहीं अनुभव करता है।",
              "ka": "ತಾನು ಬೆಳೆದು ಶಾಲೆಯ ಪ್ರಾಂಶುಪಾಲರ ಮಟ್ಟಕ್ಕೇರುವ ತಾಕತ್ತು ತನಗಿದೆ ಎಂದು ಮಾಧವ್ ಗೆ ಗೊತ್ತು. ಆದಾಗ್ಯೂ, ಆತನು ವಿದ್ಯಾರ್ಥಿಗಳು ಮತ್ತು ಶಾಲೆಯ ಸಂಸ್ಕೃತಿಯಿಂದ ಸಂಪರ್ಕ ಕಡಿತಗೊಂಡಿದ್ದೇನೆಂದು ಭಾವಿಸುತ್ತಾನೆ",
              "te": "స్కూల్ ప్రిన్సిపాల్ స్థాయికి ఎదగాలనే తపన తనకు ఉందని మాధవ్‌కి తెలుసు. అయినప్పటికీ, అతను విద్యార్థులు మరియు పాఠశాల సంస్కృతి నుండి డిస్‌కనెక్ట్ అయినట్లు భావిస్తాడు.",
              "or": "ମାଧବ ଜାଣନ୍ତି ଯେ ବ grow ିବା ଏବଂ ବିଦ୍ୟାଳୟର ପ୍ରଧାନ ଶିକ୍ଷକଙ୍କ\n  ସ୍ତରରେ ପହଞ୍ଚିବା ପାଇଁ ତାଙ୍କର ଦକ୍ଷତା ଅଛି | ତଥାପି, ସେ ଛାତ୍ର ଏବଂ ବିଦ୍ୟାଳୟର ସଂସ୍କୃତିଠାରୁ ବିଚ୍ଛିନ୍ନ ଅନୁଭବ କରନ୍ତି |",
              "as": "মাধৱে জানে যে তেওঁৰ বিকাশ আৰু বিদ্যালয়ৰ অধ্যক্ষৰ স্তৰলৈকে উপনীত হোৱাৰ দক্ষতা আছে। অৱশ্যে, তেওঁ শিক্ষাৰ্থী আৰু বিদ্যালয়ৰ সংস্কৃতিৰ পৰা বিচ্ছিন্ন হোৱা যেন অনুভৱ কৰে।",
              "gu": "માધવ જાણે છે કે તેની પાસે વિકાસ કરવાની અને શાળાના આચાર્યના સ્તર સુધી પહોંચવાની ક્ષમતા છે. જો કે, તે વિદ્યાર્થીઓ અને શાળાની સંસ્કૃતિથી અલગ હોવાનું અનુભવે છે."
            }
          }
        ]
      },
      "scene27": {
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_2///",
              "hi": "B5_1_2 ///",
              "ka": "B5_1_2///",
              "te": "B5_1_2///",
              "or": "B5_1_2///",
              "as": "B5_1_2///",
              "gu": "B5_1_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He is not used to seeing children having such luxuries, especially at a young age. This makes him uncomfortable. Sometimes he feels he spends more time fitting into the school than preparing for his class.",
              "hi": "वह बच्चों को इस तरह की विलासिता में देखने का आदी नहीं है, खासकर कम उम्र में। इससे वह असहज हो जाता है। कभी-कभी उसे लगता है कि वह अपनी कक्षा की तैयारी करने से ज्यादा समय स्कूल में ठीक लगने में बिताता है।",
              "ka": "ಅದರಲ್ಲೂ ಚಿಕ್ಕವಯಸ್ಸಿನಲ್ಲಿ ಇಂತಹ ಐಷಾರಾಮಿಗಳನ್ನು ಹೊಂದಿರುವ ಮಕ್ಕಳನ್ನು ನೋಡಿ ಅವನಿಗೆ ಅಭ್ಯಾಸವಿಲ್ಲ. ಇದರಿಂದ ಆತನಿಗೆ ಕಸಿವಿಸಿಯಾಗುತ್ತದೆ. ಕೆಲವೊಮ್ಮೆ ಅವನು ತನ್ನ ತರಗತಿಗೆ ತಯಾರಿ ಮಾಡಿಕೊಳ್ಳುವುದಕ್ಕಿಂತ ಹೆಚ್ಚಿನ ಸಮಯವನ್ನು ಶಾಲೆಗೆ ಹೊಂದಿಕೊಳ್ಳಲು ಕಳೆಯುತ್ತಿದ್ದೇನೆ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "ముఖ్యంగా చిన్నవయసులో ఇలాంటి లగ్జరీలు ఉన్న పిల్లలను అతను ముందు చూడలేదు. ఇది అతనికి అసౌకర్యాన్ని కలిగిస్తుంది. కొన్నిసార్లు అతను తన క్లాస్ కి ప్రిపేర్ అవడం కంటే పాఠశాలకు సరిపోయేలా మారడానికి సమయాన్ని వెచ్చిస్తున్నట్లు అతను భావిస్తాడు.",
              "or": "ବିଶେଷ କରି ଅଳ୍ପ ବୟସରେ ପିଲାମାନଙ୍କର ଏପରି ବିଳାସପୂର୍ଣ୍ଣତା ଦେଖିବାରେ\n  ସେ ଅଭ୍ୟସ୍ତ ନୁହଁନ୍ତି | ଏହା ତାଙ୍କୁ ଅସହଜ କରିଥାଏ | ବେଳେବେଳେ ସେ ଅନୁଭବ କରନ୍ତି ଯେ ସେ ନିଜ ଶ୍ରେଣୀ ପାଇଁ ପ୍ରସ୍ତୁତି ଅପେକ୍ଷା ବିଦ୍ୟାଳୟରେ ଫିଟ୍ ହେବା ପାଇଁ ଅଧିକ ସମୟ ବିତାଉଛନ୍ତି |",
              "as": "তেওঁ শিশুসকলক এনে ধৰণৰ বিলাসিতা কৰা দেখিবলৈ পোৱাত অভ্যস্ত নহয়, বিশেষকৈ ইমান কম বয়সতে। এইটোৱে তেওঁক অস্বস্তিত পেলায়। কেতিয়াবা তেওঁ অনুভৱ কৰে যে তেওঁ নিজৰ শ্ৰেণীৰ বাবে প্ৰস্তুতি কৰাতকৈ বিদ্যালয়ত খাপ খোৱাত অধিক সময় অতিবাহিত কৰে।",
              "gu": "ખાસ કરીને નાની ઉંમરે બાળકોને આવી વૈભવી જીવીન ધરાવતા જોવાની તેને આદત નથી. આ તેને અસ્વસ્થ બનાવે છે. કેટલીકવાર તેને લાગે છે કે તે તેના વર્ગની તૈયારી કરતાં શાળામાં ફિટ કરવામાં વધુ સમય વિતાવે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_2_1",
              "hi": "B5_2_1",
              "ka": "B5_2_1",
              "te": "B5_2_1",
              "or": "B5_2_1",
              "as": "B5_2_1",
              "gu": "B5_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He talks to his friends about this and they are also worried that even after a few years in the school Madhav feels this way. They encourage him to involve himself in activities he likes after school hours.",
              "hi": "वह इस बारे में अपने मित्रों से बात करता है और वे भी चिंतित हैं कि स्कूल में कुछ वर्ष बिताने के बाद भी माधव इस तरह से अनुभव करता है। वे उसे स्कूल के बाद अपने पसंद की गतिविधियों में शामिल होने के लिए प्रोत्साहित करते हैं।",
              "ka": "ಅವನು ತನ್ನ ಸ್ನೇಹಿತರೊಂದಿಗೆ ಈ ಬಗ್ಗೆ ಮಾತನಾಡುತ್ತಾನೆ ಮತ್ತು ಶಾಲೆಯಲ್ಲಿ ಕೆಲವು ವರ್ಷಗಳ ನಂತರವೂ ಮಾಧವ್ ಈ ರೀತಿ ಭಾವಿಸುತ್ತಾನೆ ಎಂದು ಅವರು ಆತಂಕಗೊಂಡಿದ್ದಾರೆ. ಶಾಲೆಯ ಸಮಯದ ನಂತರ ಅವನು ಇಷ್ಟಪಡುವ ಚಟುವಟಿಕೆಗಳಲ್ಲಿ ತನ್ನನ್ನು ತೊಡಗಿಸಿಕೊಳ್ಳಲು ಅವರು ಪ್ರೋತ್ಸಾಹಿಸುತ್ತಾರೆ.",
              "te": "అతను ఈ విషయం గురించి తన స్నేహితులతో మాట్లాడతాడు మరియు పాఠశాలలో కొన్నేళ్ల తర్వాత కూడా మాధవ్ ఇలాగే భావిస్తున్నాడని వారు ఆందోళన చెందుతారు. పాఠశాల సమయం తర్వాత అతను ఇష్టపడే కార్యకలాపాలలో పాల్గొనమని వారు అతన్ని ప్రోత్సహిస్తారు.",
              "or": "ସେ ତାଙ୍କ ସାଙ୍ଗମାନଙ୍କ ସହିତ ଏ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି ଏବଂ ସେମାନେ\n  ମଧ୍ୟ ଚିନ୍ତିତ ଅଛନ୍ତି ଯେ ବିଦ୍ୟାଳୟରେ କିଛି ବର୍ଷ ପରେ ମଧ୍ୟ ମାଧବ ଏହିପରି ଅନୁଭବ କରନ୍ତି | ସେମାନେ ତାଙ୍କୁ ବିଦ୍ୟାଳୟ ସମୟ ପରେ ପସନ୍ଦ କରୁଥିବା କାର୍ଯ୍ୟକଳାପରେ ଜଡିତ ହେବାକୁ ଉତ୍ସାହିତ କରନ୍ତି |",
              "as": "তেওঁ তেওঁৰ বন্ধুবৰ্গৰ সৈতে এই বিষয়ে কথা পাতে আৰু তেওঁলোকো চিন্তিত হয় যে বিদ্যালয়ত কেইবছৰমান কাম কৰাৰ পাছতো মাধৱে এনেকুৱা অনুভৱ কৰিছে। তেওঁলোকে তাক স্কুলৰ সময়ৰ পিছত তেওঁ পছন্দ কৰা কাৰ্যকলাপত নিজকে অন্তৰ্ভুক্ত কৰিবলৈ উৎসাহিত কৰে।",
              "gu": "તે તેના મિત્રો સાથે આ વિશે વાત કરે છે અને તેઓ પણ ચિંતિત છે કે શાળામાં થોડા વર્ષો પછી પણ માધવ આ રીતે અનુભવે છે. તેઓ તેને શાળાના કલાકો પછી તેને ગમતી પ્રવૃત્તિઓમાં સામેલ થવા પ્રોત્સાહિત કરે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_3_1",
              "hi": "B5_3_1",
              "ka": "B5_3_1",
              "te": "B5_3_1",
              "or": "B5_3_1",
              "as": "B5_3_1",
              "gu": "B5_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav starts spending an hour every day after school to teach children in his neighbourhood who go to the nearby government school.",
              "hi": "माधव अपने पड़ोस के सरकारी स्कूल में जाने वाले बच्चों को पढ़ाने के लिए स्कूल के बाद हर दिन एक घंटा बिताना शुरू कर देता है।",
              "ka": "ಮಾಧವ್ ಪ್ರತಿದಿನ ಶಾಲೆಯ ನಂತರ, ಹತ್ತಿರದ ಸರ್ಕಾರಿ ಶಾಲೆಗೆ ಹೋಗುವ ತನ್ನ ನೆರೆಹೊರೆಯ ಮಕ್ಕಳಿಗೆ ಕಲಿಸಲು.ಒಂದು ಗಂಟೆಯನ್ನು ಕಳೆಯಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ",
              "te": "మాధవ్ ప్రతిరోజూ పాఠశాల తర్వాత సమీపంలోని ప్రభుత్వ పాఠశాలకు వెళ్లే తన ఇరుగుపొరుగు పిల్లలకు బోధించడానికి ఒక గంట గడపడం ప్రారంభిస్తాడు.",
              "or": "ମାଧବ ସ୍କୁଲ ପରେ ପ୍ରତିଦିନ ଏକ ଘଣ୍ଟା ବିତାଇବା ଆରମ୍ଭ କରନ୍ତି ଯାହାକି\n  ତାଙ୍କ ପଡ଼ୋଶୀ ପିଲାମାନଙ୍କୁ ନିକଟସ୍ଥ ସରକାରୀ ବିଦ୍ୟାଳୟକୁ ଯାଆନ୍ତି |",
              "as": "মাধৱে স্কুলৰ পিছত প্ৰতিদিনে এঘণ্টা তেওঁৰ চুবুৰীয়াৰ ল'ৰা ছোৱালীক শিকাবলৈ আৰম্ভ কৰে যিসকলে ওচৰৰ চৰকাৰী বিদ্যালয়লৈ যায়।",
              "gu": "માધવ તેની પડોશીના બાળકોને ભણાવવા માટે શાળા પછી દરરોજ એક કલાક પસાર કરવાનું શરૂ કરે છે જેઓ નજીકની સરકારી શાળામાં જાય છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_3_2",
              "hi": "B5_3_2",
              "ka": "B5_3_2",
              "te": "B5_3_2",
              "or": "B5_3_2",
              "as": "B5_3_2",
              "gu": "B5_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He feels happy teaching students after school and wants to open an activity centre with facilities like a library and computer lab for them once he saves more money.",
              "hi": "वह स्कूल के बाद छात्रों को पढ़ाने में प्रसन्नता महसूस करता है और अधिक पैसे बचाने के बाद उनके लिए एक पुस्तकालय और कंप्यूटर प्रयोगशाला जैसी सुविधाओं के साथ एक गतिविधि केंद्र खोलना चाहता है।",
              "ka": "ಅವನು ಶಾಲೆಯ ನಂತರ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಕಲಿಸಲು ಸಂತೋಷಪಡುತ್ತಾನೆ ಮತ್ತು ಹೆಚ್ಚಿನ ಹಣವನ್ನು ಉಳಿಸಿದ ನಂತರ ಅವರಿಗಾಗಿ ಗ್ರಂಥಾಲಯ ಮತ್ತು ಕಂಪ್ಯೂಟರ್ ಲ್ಯಾಬ್‌ನಂತಹ ಸೌಲಭ್ಯಗಳೊಂದಿಗೆ ಚಟುವಟಿಕೆ ಕೇಂದ್ರವನ್ನು ತೆರೆಯಲು ಬಯಸುತ್ತಾನೆ.",
              "te": "పాఠశాల తర్వాత విద్యార్థులకు బోధించడం అతనికి సంతోషంగా ఉంది మరియు అతను ఎక్కువ డబ్బు ఆదా చేసిన తర్వాత వారి కోసం లైబ్రరీ మరియు కంప్యూటర్ ల్యాబ్ వంటి సౌకర్యాలతో యాక్టివిటీ సెంటర్ తెరవాలనుకుంటున్నాడు.",
              "or": "ସେ ବିଦ୍ୟାଳୟ ପରେ ଛାତ୍ରମାନଙ୍କୁ ଶିକ୍ଷାଦାନ କରିବାରେ ଖୁସି ଅନୁଭବ\n \n  କରନ୍ତି ଏବଂ ଅଧିକ ଅର୍ଥ ସଞ୍ଚୟ କରିବା ପରେ ସେମାନଙ୍କ ପାଇଁ ଲାଇବ୍ରେରୀ ଏବଂ କମ୍ପ୍ୟୁଟର ଲ୍ୟାବ ଭଳି ସୁବିଧା ସହିତ ଏକ କାର୍ଯ୍ୟକଳାପ କେନ୍ଦ୍ର ଖୋଲିବାକୁ ଚାହାଁନ୍ତି |",
              "as": "তেওঁ বিদ্যালয়ৰ পিছত শিক্ষাৰ্থীসকলক শিকাবলৈ পাই সুখী অনুভৱ কৰে আৰু অধিক ধন ৰাহি কৰাৰ পিছত তেওঁলোকৰ বাবে পুথিভঁৰাল আৰু কম্পিউটাৰ পৰীক্ষাগাৰৰ দৰে সুবিধা থকা এটা কাৰ্যকলাপ কেন্দ্ৰ খুলিব বিচাৰে।",
              "gu": "તે શાળા પછી વિદ્યાર્થીઓને ભણાવવામાં આનંદ અનુભવે છે અને એકવાર તે વધુ પૈસા બચાવે પછી તેમના માટે પુસ્તકાલય અને કોમ્પ્યુટર લેબ જેવી સુવિધાઓ સાથે એક પ્રવૃત્તિ સેંટર ખોલવા માંગે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "Teacher in private school",
              "hi": "निजी स्कूल में शिक्षक",
              "ka": "ಖಾಸಗಿ ಶಾಲೆಯಲ್ಲಿ ಒಬ್ಬ ಶಿಕ್ಷಕ",
              "te": "ప్రైవేట్ స్కూల్లో టీచర్",
              "or": "ଘରୋଇ ବିଦ୍ୟାଳୟରେ ଶିକ୍ଷକ |",
              "as": "ব্যক্তিগত বিদ্যালয়ৰ শিক্ষক",
              "gu": "ખાનગી શાળામાં શિક્ષક"
            }
          }
        ]
      },
      "scene28": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A career in journalism",
              "hi": "पत्रकारिता में एक कैरियर",
              "ka": "ಪತ್ರಿಕೋದ್ಯಮ ವೃತ್ತಿ",
              "te": "జర్నలిజంలో కెరీర్",
              "or": "ସାମ୍ବାଦିକତା କ୍ଷେତ୍ରରେ ଏକ ବୃତ୍ତି |",
              "as": "সাংবাদিকতাৰ জীৱিকা",
              "gu": "પત્રકારત્વમાં કારકિર્દી"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to pursue a career in Journalism as he thinks that it would fuel his passion for bringing change.",
              "hi": "माधव ने पत्रकारिता में अपना करियर बनाने का निर्णय किया क्योंकि उसे लगता है कि यह बदलाव उसके जुनून को बढ़ावा देगा।",
              "ka": "ಮಾಧವ್ ತಾನು ಪತ್ರಿಕೋದ್ಯಮದಲ್ಲಿ ವೃತ್ತಿಜೀವನವನ್ನು ಮುಂದುವರಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ ಏಕೆಂದರೆ ಅದು ಬದಲಾವಣೆಯನ್ನು ತರುವ ಅವನ ಉತ್ಸಾಹವನ್ನು ಹೆಚ್ಚಿಸುತ್ತದೆ ಎಂದು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "మార్పు తీసుకురావాలనే తన అభిరుచిని పెంచుతుందని భావించిన మాధవ్ జర్నలిజంలో వృత్తిని కొనసాగించాలని నిర్ణయించుకున్నాడు.",
              "or": "ମାଧବ ସାମ୍ବାଦିକତାରେ କ୍ୟାରିୟର କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି କାରଣ ସେ\n  ଭାବନ୍ତି ଯେ ଏହା ପରିବର୍ତ୍ତନ ଆଣିବା ପାଇଁ ତାଙ୍କର ଉତ୍ସାହ କୁ ଇନ୍ଧନ ଯୋଗାଇବ |",
              "as": "মাধৱে সাংবাদিকতাত জীৱিকা গঢ়িবলৈ সিদ্ধান্ত লয় কিয়নো তেওঁ ভাবে যে ই পৰিৱৰ্তন অনাৰ প্ৰতি তেওঁৰ যি আগ্ৰহ তাত ইন্ধন যোগাব।",
              "gu": "માધવ પત્રકારત્વમાં કારકિર્દી બનાવવાનું નક્કી કરે છે કારણ કે તે વિચારે છે કે તે પરિવર્તન લાવવાના તેના જુસ્સાને ઉત્તેજન આપશે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_1_2",
              "hi": "C1_1_2",
              "ka": "C1_1_2",
              "te": "C1_1_2",
              "or": "C1_1_2",
              "as": "C1_1_2",
              "gu": "C1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "From his research, he finds out that even if a degree in journalism is helpful, it is not an absolute necessity.",
              "hi": "अपने शोध से, उसे पता चलता है कि पत्रकारिता में डिग्री भले ही सहायक हो, लेकिन यह एक मुख्य आवश्यकता नहीं है।",
              "ka": "ತನ್ನ ಸಂಶೋಧನೆಯಿಂದ, ಪತ್ರಿಕೋದ್ಯಮಕ್ಕೆ ಪದವಿಯು ಸಹಾಯಕವಾಗಿದ್ದರೂ ಸಹ, ಅದು ಸಂಪೂರ್ಣ ಅಗತ್ಯವಲ್ಲ ಎನ್ನುವುದನ್ನು ಅವನು ಕಂಡುಕೊಂಡಿದ್ದಾನೆ.",
              "te": "తన రీసర్చ్ నుండి, జర్నలిజంలో డిగ్రీ ఉపయోగకరంగా ఉన్నప్పటికీ, అది ఖచ్చితమైన అవసరం కాదని అతను కనుగొన్నాడు.",
              "or": "ତାଙ୍କର ଅନୁସନ୍ଧାନରୁ ସେ ଜାଣିବାକୁ ପାଇଲେ ଯେ ସାମ୍ବାଦିକତାର ଡିଗ୍ରୀ\n  ଯଦିଓ ସହାୟକ ହୁଏ, ଏହା ଏକ ସମ୍ପୂର୍ଣ୍ଣ ଆବଶ୍ୟକତା ନୁହେଁ |",
              "as": "তেওঁৰ গৱেষণাৰ পৰা তেওঁ গম পালে যে সাংবাদিকতাৰ ডিগ্ৰী সহায়ক হ'লেও, ই এক চৰম প্ৰয়োজনীয়তা নহয়।",
              "gu": "તેના સંશોધનમાંથી, તેઓ શોધી કાઢે છે કે જો પત્રકારત્વની ડિગ્રી મદદરૂપ હોય તો પણ, તે સંપૂર્ણ આવશ્યકતા નથી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_1_3",
              "hi": "C1_1_3",
              "ka": "C1_1_3",
              "te": "C1_1_3",
              "or": "C1_1_3",
              "as": "C1_1_3",
              "gu": "C1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "His preparation for UPSC has taught him a lot already. The articles his teacher had asked him to write while studying also gave him the confidence to apply for a job.",
              "hi": "यूपीएससी के लिए उसकी तैयारी ने उसे पहले से बहुत कुछ सिखाया है। उसके शिक्षक ने अध्ययन के समय उससे जो लेख  लिखने के लिए कहा था उन लेखों ने भी उसे नौकरी के लिए आवेदन करने का विश्वास दिलाया।",
              "ka": "UPSC ಗಾಗಿ ಅವನ ತಯಾರಿ ಈಗಾಗಲೇ ಅವನಿಗೆ ಸಾಕಷ್ಟು ಕಲಿಸಿದೆ. ಓದುತ್ತಿರುವಾಗ ಅವನ ಶಿಕ್ಷಕರು ಬರೆಯಲು ಹೇಳಿದ್ದ ಲೇಖನಗಳು ಅವನಿಗೆ ಉದ್ಯೋಗಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವ ಆತ್ಮವಿಶ್ವಾಸವನ್ನೂ ನೀಡಿದೆ.",
              "te": "UPSC కోసం అతని ప్రిపరేషన్ అతనికి ఇప్పటికే చాలా నేర్పింది. చదువుకునేటప్పుడు తన టీచర్ రాయమని అడిగిన ఆర్టికల్స్ కూడా ఉద్యోగం కోసం అప్లై చేయాలనే నమ్మకాన్ని కలిగించాయి.",
              "or": "UPSC ପାଇଁ ତାଙ୍କର ପ୍ରସ୍ତୁତି ତାଙ୍କୁ ବହୁତ କିଛି ଶିଖାଇଛି | ଅଧ୍ୟୟନ\n  ସମୟରେ ତାଙ୍କ ଶିକ୍ଷକ ତାଙ୍କୁ ଲେଖିବାକୁ କହିଥିବା ପ୍ରବନ୍ଧଗୁଡ଼ିକ ମଧ୍ୟ ତାଙ୍କୁ ଚାକିରି ପାଇଁ ଆବେଦନ କରିବାର ଆତ୍ମବିଶ୍ୱାସ ଦେଇଥିଲା |",
              "as": "UPSCৰ বাবে তেওঁৰ প্ৰস্তুতিয়ে তেওঁক ইতিমধ্যে বহুত শিকাইছে। পঢ়ি থাকোঁতে তেওঁৰ শিক্ষকে তেওঁক লিখিবলৈ কোৱা প্ৰবন্ধবোৰেও তেওঁক চাকৰিৰ বাবে আবেদন কৰাৰ ক্ষেত্ৰত আত্মবিশ্বাস দিছিল।",
              "gu": "UPSC માટેની તેની તૈયારીએ તેને ઘણું શીખવ્યું છે. તેના શિક્ષકે તેને ભણતી વખતે જે લેખ લખવાનું કહ્યું હતું તે લેખોએ પણ તેને નોકરી માટે અરજી કરવાનો વિશ્વાસ અપાવ્યો હતો."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_1_4",
              "hi": "C1_1_4",
              "ka": "C1_1_4",
              "te": "C1_1_4",
              "or": "C1_1_4",
              "as": "C1_1_4",
              "gu": "C1_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He approaches some newspaper firms. He first decides to speak to employees working there, and one of the people he meets is Suraj.",
              "hi": "वह कुछ समाचार पत्र फर्मों में जाता है। वह पहले वहां काम करने वाले कर्मचारियों से बात करने का निश्चय करता है, और वह उन लोगों में से एक सूरज नाम के व्यक्ति से  मिलता है।",
              "ka": "ಅವನು ಕೆಲವು ಪತ್ರಿಕೆ ಸಂಸ್ಥೆಗಳನ್ನು ಸಂಪರ್ಕಿಸುತ್ತಾನೆ. ಅವನು ಮೊದಲು ಅಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಉದ್ಯೋಗಿಗಳೊಂದಿಗೆ ಮಾತನಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ ಮತ್ತು ಅವನು ಭೇಟಿಯಾದ ವ್ಯಕ್ತಿಗಳಲ್ಲಿ ಒಬ್ಬರು ಸೂರಜ್.",
              "te": "అతను కొన్ని వార్తాపత్రికల సంస్థలను సంప్రదించాడు. అతను మొదట అక్కడ పనిచేస్తున్న ఉద్యోగులతో మాట్లాడాలని నిర్ణయించుకుంటాడు మరియు అతను కలిసే వ్యక్తులలో ఒకరు సూరజ్.",
              "or": "ସେ କିଛି ଖବରକାଗଜ ଫାର୍ମ ନିକଟକୁ ଆସନ୍ତି | ସେ ପ୍ରଥମେ ସେଠାରେ\n  କାର୍ଯ୍ୟ କରୁଥିବା କର୍ମଚାରୀଙ୍କ ସହ କଥା ହେବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି ଏବଂ ସେ ସାକ୍ଷାତ କରୁଥିବା ଲୋକମାନଙ୍କ ମଧ୍ୟରୁ ଜଣେ ହେଉଛନ୍ତି ସୁରଜ |",
              "as": "তেওঁ কিছুমান বাতৰি কাকত প্ৰতিষ্ঠানৰ কাষ চাপে। তেওঁ প্ৰথমে তাত কাম কৰা কৰ্মচাৰীসকলৰ সৈতে কথা পাতিবলৈ সিদ্ধান্ত লয়, আৰু তেওঁ লগ পোৱা লোকসকলৰ ভিতৰত এজন হৈছে সুৰজ।",
              "gu": "તે કેટલીક સમાચારના છાપા કંપનીઓનો સંપર્ક કરે છે. તે પહેલા ત્યાં કામ કરતા કર્મચારીઓ સાથે વાત કરવાનું નક્કી કરે છે અને તે જે લોકોને મળે છે તેમાંથી એક સૂરજ છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_1",
              "hi": "C1_2_1",
              "ka": "C1_2_1",
              "te": "C1_2_1",
              "or": "C1_2_1",
              "as": "C1_2_1",
              "gu": "C1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Suraj is a senior journalist working at a regional newspaper. He covers local news and writes about politics.",
              "hi": "सूरज एक वरिष्ठ पत्रकार है जो एक क्षेत्रीय समाचार पत्र में काम कर रहा है। वह स्थानीय समाचारों को कवर करता है और राजनीति के बारे में लिखता है।",
              "ka": "ಸೂರಜ್ ಪ್ರಾದೇಶಿಕ ಪತ್ರಿಕೆಯೊಂದರಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿರುವ ಹಿರಿಯ ಪತ್ರಕರ್ತ.ಅವರು ಸ್ಥಳೀಯ ಸುದ್ದಿಗಳನ್ನು ಕವರ್ ಮಾಡುತ್ತಾರೆ ಮತ್ತು ರಾಜಕೀಯದ ಬಗ್ಗೆ ಬರೆಯುತ್ತಾರೆ.",
              "te": "సూరజ్ ఒక ప్రాంతీయ వార్తాపత్రికలో పనిచేస్తున్న సీనియర్ జర్నలిస్టు. అతను స్థానిక వార్తలను కవర్ చేస్తాడు మరియు రాజకీయాల గురించి వ్రాస్తాడు.",
              "or": "ସୁରଜ ଜଣେ ଆଞ୍ଚଳିକ ଖବରକାଗଜରେ କାର୍ଯ୍ୟ କରୁଥିବା ଜଣେ ବରିଷ୍ଠ \n ସାମ୍ବାଦିକ | ସେ ସ୍ଥାନୀୟ ସମ୍ବାଦକୁ ଆବୃତ୍ତି କରନ୍ତି ଏବଂ ରାଜନୀତି ବିଷୟରେ ଲେଖନ୍ତି |",
              "as": "সুৰজ এজন জ্যেষ্ঠ সাংবাদিক যি এখন আঞ্চলিক বাতৰি কাকতত কাম কৰি আছে। তেওঁ স্থানীয় বাতৰি প্ৰতিবেদন কৰে আৰু ৰাজনীতিৰ বিষয়ে লিখে।",
              "gu": "સૂરજ એક પ્રાદેશિક સમાચારના છાપા કંપનીમાં કામ કરતો વરિષ્ઠ પત્રકાર છે. તે સ્થાનિક સમાચારોને આવરી લે છે અને રાજકારણ વિશે લખે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_2",
              "hi": "C1_2_2",
              "ka": "C1_2_2",
              "te": "C1_2_2",
              "or": "C1_2_2",
              "as": "C1_2_2",
              "gu": "C1_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav’s energy and eagerness to prove himself instantly resonate with him. He guides Madhav on how to proceed with applying to different newspapers.",
              "hi": "माधव का उत्साह और खुद को तुरंत साबित करने की उत्सुकता उसके साथ प्रतिध्वनित होती है। वह माधव को विभिन्न अखबारों में आवेदन करने के तरीके के बारे में बताता है।",
              "ka": "ಮಾಧವ್‌ನ ಶಕ್ತಿ ಮತ್ತು ತನ್ನನ್ನು ತಾನು ಸಾಬೀತುಪಡಿಸುವ ಉತ್ಸಾಹವು ತಕ್ಷಣವೇ ಆತನೊಂದಿಗೆ ಬೆಸೆಯುವಂತೆ ಮಾಡುತ್ತದೆ. ವಿವಿಧ ಪತ್ರಿಕೆಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದನ್ನು ಹೇಗೆ ಮುಂದುವರಿಸಬೇಕು ಎನ್ನುವುದರ ಕುರಿತು ಅವರು ಮಾಧವನಿಗೆ ಮಾರ್ಗದರ್ಶನ ನೀಡುತ್ತಾರೆ.",
              "te": "మాధవ్ శక్తి మరియు తనను తాను నిరూపించుకోవాలనే ఆత్రుత అతనికి నచ్చాయి. అతను వివిధ వార్తాపత్రికలకు ఎలా అప్లై చేయాలో మాధవ్‌కు గైడ్ చేస్తాడు.",
              "or": "ନିଜକୁ ତୁରନ୍ତ ପ୍ରମାଣ କରିବାକୁ ମାଧବଙ୍କ ଶକ୍ତି ଏବଂ ଉତ୍ସାହ ତାଙ୍କ ସହିତ\n  ତୁରନ୍ତ ପୁନଃ ପ୍ରତିରୂପିତ | ବିଭିନ୍ନ ଖବରକାଗଜରେ ଆବେଦନ କରିବା ସହିତ କିପରି ଅଗ୍ରଗତି କରିବେ ସେ ବିଷୟରେ ସେ ମାଧବଙ୍କୁ ମାର୍ଗଦର୍ଶନ କରନ୍ତି |",
              "as": "মাধৱৰ ক্ষমতা আৰু নিজকে প্ৰমাণ কৰিবলৈ তাৰ যি আগ্ৰহ তেওঁৰ সৈতে তাৎক্ষণিকভাৱে খাপ খাই পৰে। তেওঁ মাধৱক বিভিন্ন বাতৰি কাকতত আবেদন কৰাৰ বাবে কেনেদৰে আগবাঢ়িব লাগিব তাৰ নিৰ্দেশনা দিয়ে।",
              "gu": "માધવની ઉર્જા અને પોતાને સાબિત કરવાની આતુરતા તરત જ તેની સાથે પડઘો પાડે છે. તે માધવને અલગ-અલગ સમાચારના છાપા કંપનીમાં અરજી કરવાની સાથે કેવી રીતે આગળ વધવું તેનું માર્ગદર્શન આપે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_3//",
              "hi": "C1_2_3 //",
              "ka": "C1_2_3//",
              "te": "C1_2_3//",
              "or": "C1_2_3//",
              "as": "C1_2_3//",
              "gu": "C1_2_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Suraj",
              "hi": "सूरज",
              "ka": "ಸೂರಜ್",
              "te": "సూరజ్",
              "or": "ସୂରଜ",
              "as": "সুৰজ",
              "gu": "સુરજ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I plan to send in some of the articles I have written to a few newspapers and digital news portals.",
              "hi": "मेरी योजना कुछ समाचार पत्रों और डिजिटल समाचार पोर्टलों को मेरे द्वारा लिखे गए कुछ लेख भेजने की है।",
              "ka": "ನಾನು ಬರೆದ ಕೆಲವು ಲೇಖನಗಳನ್ನು ಕೆಲವು ಪತ್ರಿಕೆಗಳು ಮತ್ತು ಡಿಜಿಟಲ್ ನ್ಯೂಸ್ ಪೋರ್ಟಲ್‌ಗಳಿಗೆ ಕಳುಹಿಸಲು ನಾನು ಯೋಚಿಸುತ್ತಿದ್ದೇನೆ.",
              "te": "నేను వ్రాసిన కొన్ని కథనాలను కొన్ని వార్తాపత్రికలు మరియు డిజిటల్ న్యూస్ పోర్టల్‌లకు పంపాలనుకుంటున్నాను.",
              "or": "ମୁଁ କିଛି ଖବରକାଗଜ ଏବଂ ଡିଜିଟାଲ୍ ନ୍ୟୁଜ୍ ପୋର୍ଟାଲକୁ ଲେଖିଥିବା କିଛି\n  ଆର୍ଟିକିଲ୍ ପଠାଇବାକୁ ଯୋଜନା କରିଛି |",
              "as": "মই লিখা কিছুমান প্ৰবন্ধ কেইখনমান বাতৰি কাকত আৰু ডিজিটেল সংবাদ পৰ্টেললৈ প্ৰেৰণ কৰাৰ পৰিকল্পনা কৰিছোঁ।",
              "gu": "મેં કેટલાક છાપાઓ અને ડિજિટલ સમાચાર લખેલા કેટલાક લેખો પોર્ટલ પર મોકલવાની મારી યોજના છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "That’s good, but all your articles cover a wide range of topics. My advice would be to start with any newspaper and work in one of the different beats of that newspapers.",
              "hi": "यह अच्छा है, लेकिन आपके सभी लेख विषयों की एक विस्तृत श्रृंखला को कवर करते हैं। मेरा सुझाव है कि किसी भी समाचार पत्र से आरम्भ करें और उस समाचार पत्र के अलग-अलग बीट्स में से एक में काम करें।",
              "ka": "ಅದು ಒಳ್ಳೆಯದು, ಆದರೆ ನಿಮ್ಮ ಎಲ್ಲಾ ಲೇಖನಗಳು ವ್ಯಾಪಕ ಶ್ರೇಣಿಯ ವಿಷಯಗಳನ್ನು ಒಳಗೊಂಡಿರುತ್ತವೆ. ಯಾವುದೇ ಪತ್ರಿಕೆಯಿಂದ ಪ್ರಾರಂಭಿಸಿ ಮತ್ತು ಆ ಪತ್ರಿಕೆಗಳ ವಿಭಿನ್ನಬೀಟ್ಗಳಲ್ಲಿ ಒಂದರಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದು ನನ್ನ ಸಲಹೆಯಾಗಿದೆ.",
              "te": "అది మంచిది, కానీ నీ కథనాలు అన్నీ చాలా అంశాలను కవర్ చేస్తాయి కదా. ఏదైనా ఒక వార్తాపత్రికతో ప్రారంభించి, ఆ వార్తాపత్రికల యొక్క విభిన్న బీట్‌లలో ఒకదానిలో పని చేయమని నేను సలహా ఇస్తాను.",
              "or": "ତାହା ଭଲ, କିନ୍ତୁ ଆପଣଙ୍କର ସମସ୍ତ ଆର୍ଟିକିଲ୍ ବିଭିନ୍ନ ପ୍ରସଙ୍ଗକୁ ଆବୃତ କରେ | ମୋର ପରାମର୍ଶ ଯେକୌ\n ଣସି ଖବରକାଗଜରୁ ଆରମ୍ଭ କରିବା ଏବଂ ସେହି ଖବରକାଗଜର ଭିନ୍ନ ଭିନ୍ନ ଏକ କାମରେ କାମ କରିବା |",
              "as": "সেইটো ভাল, কিন্তু তোমাৰ সকলোবোৰ প্ৰবন্ধই বহুত বিষয় সামৰি লয়। মোৰ পৰামৰ্শ হ'ল যিকোনো বাতৰি কাকতৰ সৈতে আৰম্ভ কৰা আৰু সেই বাতৰি কাকতবোৰৰ বিভিন্ন বিটৰ এটাত কাম কৰা।",
              "gu": "તે સારું છે, પરંતુ તમારા બધા લેખો વિષયોની વિશાળ શ્રેણીને આવરી લે છે. મારી સલાહ એ છે કે કોઈપણ છાપાથી શરૂઆત કરો અને તે છાપાઓના અલગ બીટમાંનું એકમાં કામ કરો."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_4///",
              "hi": "C1_2_4 ////",
              "ka": "C1_2_4///",
              "te": "C1_2_4///",
              "or": "C1_2_4///",
              "as": "C1_2_4///",
              "gu": "C1_2_4///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "What do you mean by beat?",
              "hi": "बीट से आपका क्या मतलब है?",
              "ka": "ಬೀಟ್ ಎಂದರೆ ನಿಮ್ಮ ಅರ್ಥವೇನು?",
              "te": "బీట్ అంటే ఏమిటి?",
              "or": "ବିଟ ଅର୍ଥ କ’ଣ?",
              "as": "আপুনি বিটৰ দ্বাৰা কি বুজাইছে?",
              "gu": "બીટથી તમારો મતલબ શું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Ah, I keep forgetting that it isn’t a commonly used term. A beat is a theme that people specialise in. When you select or get assigned to a beat, they will expect you to follow that theme closely and make informed commentary on the trends you see.",
              "hi": "आह, मैं भूलता रहता हूं कि यह आमतौर पर इस्तेमाल किया जाने वाला शब्द नहीं है। बीट एक ऐसा विषय है जिसमें लोग विशेषज्ञ होते हैं। जब आप किसी बीट का चयन करते हैं या उससे सम्बद्ध होते हैं , तो वे आपसे उस विषय का बारीकी से पालन करने और आपके द्वारा देखे जाने वाले रुझानों पर सूचित टिप्पणी करने की अपेक्षा करेंगे।",
              "ka": "ಆಹ್, ಇದು ಸಾಮಾನ್ಯವಾಗಿ ಬಳಸುವ ಪದವಲ್ಲ ಎಂದು ನಾನು ಮರೆಯುತ್ತಿದ್ದೇನೆ. ಬೀಟ್ ಎನ್ನುವುದು ಜನರು ಪರಿಣತಿ ಹೊಂದಿರುವ ವಿಷಯವಾಗಿದೆ. ನೀವು ಬೀಟ್ ಅನ್ನು ಆಯ್ಕೆಮಾಡಿದಾಗ ಅಥವಾ ನಿಯೋಜಿಸಿದಾಗ, ನೀವು ಆ ವಿಷಯವನ್ನು ನಿಕಟವಾಗಿ ಅನುಸರಿಸಬೇಕು ಮತ್ತು ನೀವು ನೋಡುವ ಪ್ರವೃತ್ತಿಗಳ ಕುರಿತು ತಿಳುವಳಿಕೆಯುಳ್ಳ ವ್ಯಾಖ್ಯಾನವನ್ನು ಮಾಡಬೇಕೆಂದು ಅವರು ನಿರೀಕ್ಷಿಸುತ್ತಾರೆ.",
              "te": "ఆ, ఇది సాధారణంగా ఉపయోగించే పదం కాదని నేను మర్చిపోయాను. బీట్ అనేది వ్యక్తులు ప్రత్యేకత కలిగిన థీమ్. మీరు బీట్‌ని ఎంచుకున్నప్పుడు లేదా దానికి కేటాయించబడినప్పుడు, మీరు ఆ థీమ్‌ను దగ్గరగా పరిశీలించి అందులో మీరు చూసే ట్రెండ్‌లపై వివరణాత్మక వ్యాఖ్యానం రాయాలని వారు ఆశిస్తారు.",
              "or": "ଆହା, ମୁଁ ଭୁଲିଯାଉଛି ଯେ ଏହା ଏକ ସାଧାରଣ ବ୍ୟବହୃତ ଶବ୍ଦ ନୁହେଁ | \n ଏକ ବିଟ୍ ହେଉଛି ଏକ ଥିମ୍ ଯାହାକୁ ଲୋକମାନେ ବିଶେଷ କରିଥାନ୍ତି |",
              "as": "অহ, মই পাহৰি যাওঁ যে এইটো সচৰাচৰ ব্যৱহৃত শব্দ নহয়। এটা বীট হৈছে এটা বিষয়বস্তু য'ত মানুহ বিশেষজ্ঞ হয়। যেতিয়া তুমি এটা বীট বাছনি কৰা বা আৱণ্টন পোৱা, তেওঁলোকে আশা কৰিব যে তুমি সেই বিষয়টো নিবিড়ভাৱে অনুসৰণ কৰিবা আৰু তুমি দেখা প্ৰৱণতাবোৰৰ ওপৰত মন্তব্য কৰিবা।",
              "gu": "આહ, હું ભૂલી જાઉં છું કે તે સામાન્ય રીતે ઉપયોગમાં લેવાતો શબ્દ નથી. બીટ એ એક થીમ છે જેમાં લોકો વિશેષતા ધરાવે છે. જ્યારે તમે બીટ પસંદ કરો છો અથવા તેને સોંપવામાં આવે છે, ત્યારે તેઓ અપેક્ષા રાખશે કે તું તે થીમને નજીકથી અનુસર અને તું જે વલણો જુઓ છે તેના પર માહિતગાર ટિપ્પણી કર."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I wasn’t aware of that, but I see why it makes sense. Would you have any opportunities open for me? I’m a quick learner, so I could take up any beat at the moment.",
              "hi": "मुझे इसके बारे में पता नहीं था, लेकिन मैं देख पा रहा हूं कि यह क्यों उचित है। क्या आपके पास मेरे लिए कोई अवसर खुला होगा? मैं जल्दी सीख जाता हूं, इसलिए मैं इस समय कोई भी बीट ले सकता हूं।",
              "ka": "ನನಗೆ ಅದರ ಬಗ್ಗೆ ತಿಳಿದಿರಲಿಲ್ಲ, ಆದರೆ ಅದು ಏಕೆ ಅರ್ಥಪೂರ್ಣವಾಗಿದೆ ಎಂದು ನನಗೆ ತಿಳಿಯುತ್ತಿದೆ. ನೀವು ನನಗೆ ಯಾವುದೇ ಅವಕಾಶಗಳನ್ನು ಕೊಡಿಸಬಹುದೇ? ನಾನು ತ್ವರಿತವಾಗಿ ಕಲಿಯುವವನಾಗಿದ್ದೇನೆ, ಆದ್ದರಿಂದ ನಾನು ಈ ಸಮಯದಲ್ಲಿ ಯಾವುದೇ ಬೀಟ್ ಅನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು",
              "te": "నాకు దాని గురించి తెలియదు, కానీ అది ఎందుకు అని నేను అర్థం చేసుకోగలను. మీ దగ్గర నాకోసం ఏవైనా అవకాశాలు ఉన్నాయా? నేను త్వరగా నేర్చుకుంటాను, కాబట్టి నేను ప్రస్తుతానికి ఏ బీట్ అయినా తీసుకోగలను.",
              "or": "ମୁଁ ଏହା ବିଷୟରେ ଅବଗତ ନ ଥିଲି, କିନ୍ତୁ ଏହାର ଅର୍ଥ କାହିଁକି ଦେଖେ |\n  ତୁମେ ମୋ ପାଇଁ କୌଣସି ସୁଯୋଗ ଖୋଲିବ କି? ମୁଁ ଶୀଘ୍ର ଶିକ୍ଷାର୍ଥୀ, ତେଣୁ ମୁଁ ଏହି ସମୟରେ ଯେକୌଣସି ବିଟ୍ ନେଇପାରେ |",
              "as": "মই সেই বিষয়ে অৱগত নাছিলোঁ, কিন্তু মই চাওঁ ইয়াৰ অৰ্থ কি। আপোনাৰ ওচৰত মোৰ বাবে কোনো সুযোগ খালী আছে নেকি? মই এজন দ্ৰুত শিকাৰু, গতিকে মই এই মুহূৰ্তত যিকোনো বিট ল'ব পাৰোঁ।",
              "gu": "હું તે વિશે જાણતો ન હતો, પરંતુ હું જોઉં છું કે તે શા માટે અર્થપૂર્ણ છે. શું તારી પાસે મારા માટે કોઈ તકો ખુલ્લી હશે? હું ઝડપી શીખનાર છું, તેથી હું આ ક્ષણે કોઈપણ બીટ લઈ શકું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "We don’t have an opening in our newspaper but e-mail me your CV and some of your articles. I will forward it to my friends in the industry.",
              "hi": "हमारे पास हमारे समाचार पत्र में कोई पद रिक्त नहीं है, लेकिन मुझे अपने सीवी और अपने कुछ लेख ई-मेल करें। मैं इसे इंडस्ट्री में अपने मित्रों को भेजूंगा।",
              "ka": "ನಮ್ಮ ದಿನಪತ್ರಿಕೆಯಲ್ಲಿ ಸಧ್ಯಕ್ಕೆ ನಿಮಗೆ ಹೊಂದಿಕೆಯಾಗುವುದು ಯಾವುದೂ ಇಲ್ಲ. ಆದರೆ ನಿಮ್ಮ CV ಮತ್ತು ನಿಮ್ಮ ಕೆಲವು ಲೇಖನಗಳನ್ನು ನನಗೆ ಇಮೇಲ್ ಮಾಡಿ. ನಾನು ಅದನ್ನು ಉದ್ಯಮದಲ್ಲಿರುವ ನನ್ನ ಸ್ನೇಹಿತರಿಗೆ ರವಾನಿಸುತ್ತೇನೆ.",
              "te": "మా వార్తాపత్రికలో అయితే ఓపెనింగ్ లేదు కానీ మీ CV మరియు మీ కొన్ని కథనాలను నాకు ఇమెయిల్ చేయండి. ఇండస్ట్రీలోని నా స్నేహితులకు ఫార్వార్డ్ చేస్తాను.",
              "or": "ଆମର ଖବରକାଗଜରେ ଆମର କୌଣସି ଖାଲି ସ୍ଥାନ ନାହିଁ\n  କିନ୍ତୁ ତୁମର CV ଏବଂ ତୁମର କିଛି ଆର୍ଟିକିଲ୍ ମୋତେ ଇ-ମେଲ୍ କର | ମୁଁ ଏହାକୁ ଇଣ୍ଡଷ୍ଟ୍ରିରେ ଥିବା ମୋର ବନ୍ଧୁମାନଙ୍କ ନିକଟକୁ ପଠାଇବି |",
              "as": "আমাৰ বাতৰি কাকতত কোনো খালী নাই কিন্তু মোক তোমাৰ CV আৰু কিছুমান প্ৰবন্ধ ই-মেইল কৰিবা। মই এইটো উদ্যোগতে থকা মোৰ বন্ধুবৰ্গলৈ প্ৰেৰণ কৰিম।",
              "gu": "અમારી પાસે અમારા છાપાના કારખાનામાં કોઈ નવી ભરતી નથી પરંતુ મને તમારો CV અને તમારા કેટલાક લેખો ઈ-મેલ કરો. હું તેને કંપનીમાં મારા મિત્રોને આગળ મોકલીશ."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_5",
              "hi": "C1_2_5",
              "ka": "C1_2_5",
              "te": "C1_2_5",
              "or": "C1_2_5",
              "as": "C1_2_5",
              "gu": "C1_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav sends his CV to Suraj Sir, and with his help, he gets a job at a leading Hindi newspaper from Uttar Pradesh called Dainik Samachar.",
              "hi": "माधव अपने सीवी को सूरज महोदय को भेजता है, और उनकी मदद से, उन्हें दैनिक समचर नामक उत्तर प्रदेश से एक प्रमुख हिंदी समाचार पत्र में नौकरी मिलती है।",
              "ka": "ಮಾಧವ್ ತನ್ನ CV ಅನ್ನು ಸೂರಜ್ ಸರ್‌ಗೆ ಕಳುಹಿಸುತ್ತಾನೆ ಮತ್ತು ಆತನ ಸಹಾಯದಿಂದ ಉತ್ತರ ಪ್ರದೇಶದ ದೈನಿಕ್ ಸಮಾಚಾರ್ ಎಂಬ ಪ್ರಮುಖ ಹಿಂದಿ ಪತ್ರಿಕೆಯಲ್ಲಿ ಕೆಲಸ ಪಡೆಯುತ್ತಾನೆ.",
              "te": "మాధవ్ తన CVని సూరజ్ సర్‌కి పంపాడు మరియు అతని సహాయంతో ఉత్తరప్రదేశ్‌లోని దైనిక్ సమాచార్ అనే ప్రముఖ హిందీ వార్తాపత్రికలో ఉద్యోగం పొందాడు.",
              "or": "ମାଧବ ସୁରଜ ସାର୍ ଙ୍କ ନିକଟକୁ ନିଜର ସିଭି ପଠାନ୍ତି ଏବଂ ତାଙ୍କ ସାହାଯ୍ୟରେ\n  ସେ ଉତ୍ତରପ୍ରଦେଶର ଏକ ଅଗ୍ରଣୀ ହିନ୍ଦୀ ଦାଇନିକ ସମାଚାର ନାମକ ଏକ ଖବର କାଗଜ ରେ ଚାକିରି ପାଇଲେ।",
              "as": "মাধৱে তেওঁৰ CV সুৰজ ছাৰলৈ প্ৰেৰণ কৰে, আৰু তেওঁৰ সহায়ত, তেওঁ উত্তৰ প্ৰদেশৰ দৈনিক সংবাদ নামৰ এখন আগশাৰীৰ হিন্দী বাতৰি কাকতত চাকৰি লাভ কৰে।",
              "gu": "માધવ પોતાનો CV સૂરજ સરને મોકલે છે, અને તેની મદદથી, તેને ઉત્તર પ્રદેશના દૈનિક સમાચાર નામના અગ્રણી હિન્દી છાપાના કારખાનામાં નોકરી મળે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_5b",
              "hi": "C1_2_5B",
              "ka": "C1_2_5b",
              "te": "C1_2_5b",
              "or": "C1_2_5b",
              "as": "C1_2_5b",
              "gu": "C1_2_5b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The pay is not much, but he is excited to start his journey as a journalist. He manages to send money to his father and manage his expenses well.",
              "hi": "वेतन ज्यादा नहीं है, लेकिन वह एक पत्रकार के रूप में अपनी यात्रा शुरू करने के लिए उत्साहित है। वह अपने पिता को पैसे भेजने और अपने खर्चों की व्यवस्था करने का प्रबंधन करता है।",
              "ka": "ಸಂಭಾವನೆ ಹೆಚ್ಚು ಇಲ್ಲ, ಆದರೆ ಪತ್ರಕರ್ತನಾಗಿ ತನ್ನ ಪ್ರಯಾಣವನ್ನು ಪ್ರಾರಂಭಿಸಲು ಉತ್ಸುಕನಾಗಿದ್ದಾನೆ. ಅವನು ತನ್ನ ತಂದೆಗೆ ಹಣವನ್ನು ಕಳುಹಿಸುತ್ತಾನೆ ಮತ್ತು ಅವನ ಖರ್ಚುಗಳನ್ನು ಚೆನ್ನಾಗಿ ನಿರ್ವಹಿಸುತ್ತಾನೆ.",
              "te": "జీతం ఎక్కువ కాదు, కానీ అతను జర్నలిస్ట్ గా తన ప్రయాణాన్ని ప్రారంభించాలని ఉత్సాహంగా ఉన్నాడు. అతను తన తండ్రికి డబ్బు పంపుతాడు మరియు అతని ఖర్చులను కూడా బాగా మేనేజ్ చేస్తాడు.",
              "or": "ଦରମା ଅଧିକ ନୁହେଁ, କିନ୍ତୁ ସାମ୍ବାଦିକ ଭାବରେ ତାଙ୍କର\n  ଯାତ୍ରା ଆରମ୍ଭ କରିବାକୁ ସେ ଉତ୍ସାହିତ ଅଛନ୍ତି | ସେ ତାଙ୍କ ପିତାଙ୍କ ନିକଟକୁ ଟଙ୍କା ପଠାଇବା ଏବଂ ତାଙ୍କ ଖର୍ଚ୍ଚକୁ ଭଲ ଭାବରେ ପରିଚାଳନା କରିବାରେ ସଫଳ ହୁଅନ୍ତି |",
              "as": "দৰমহা বেছি নহয়, কিন্তু তেওঁ এজন সাংবাদিক হিচাপে যাত্ৰা আৰম্ভ কৰিবলৈ উৎসাহিত। তেওঁ তেওঁৰ দেউতাকলৈ টকা পঠিয়াবলৈ আৰু তেওঁৰ খৰচবোৰ ভালদৰে পৰিচালনা কৰিবলৈ সক্ষম হয়।",
              "gu": "પગાર વધારે નથી, પરંતુ તે પત્રકાર તરીકેની તેની સફર શરૂ કરવા માટે ઉત્સાહિત છે. તે તેના પિતાને પૈસા મોકલવા અને તેના ખર્ચાઓનું સંચાલન સારી રીતે કરે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "Talk to expert and networking",
              "hi": "विशेषज्ञ और नेटवर्किंग से बात करे",
              "ka": "ತಜ್ಞರು ಮತ್ತು ನೆಟ್‌ವರ್ಕಿಂಗ್‌ ನಲ್ಲಿರುವವರ ಜೊತೆಗೆ ಮಾತನಾಡಿ",
              "te": "నిపుణులతో మాట్లాడడం మరియు నెట్‌వర్కింగ్‌",
              "or": "ବିଶେଷଜ୍ଞ ଏବଂ ନେଟୱାର୍କିଂ ସହିତ କଥା ହୁଅନ୍ତୁ |",
              "as": "বিশেষজ্ঞ আৰু নেটৱৰ্কিংৰ সৈতে কথা পাতা",
              "gu": "નિષ્ણાત અને નેટવર્કિંગ સાથે વાત કરો"
            }
          }
        ]
      },
      "scene29": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_1",
              "hi": "C2_1_1",
              "ka": "C2_1_1",
              "te": "C2_1_1",
              "or": "C2_1_1",
              "as": "C2_1_1",
              "gu": "C2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He works as a local beat reporter in the first month. His job is to cover the different things happening in Lucknow.",
              "hi": "वह पहले महीने में एक स्थानीय बीट रिपोर्टर के रूप में काम करता है। उनका काम लखनऊ में होने वाली विभिन्न चीजों के बारे में समाचार एकत्र करना है।",
              "ka": "ಅವನು ಮೊದಲ ಕೆಲವು ತಿಂಗಳಲ್ಲಿ ಸ್ಥಳೀಯ ಬೀಟ್ ವರದಿಗಾರನಾಗಿ ಕೆಲಸ ಮಾಡುತ್ತಾನೆ. ಲಕ್ನೋದಲ್ಲಿ ನಡೆಯುವ ವಿವಿಧ ವಿಷಯಗಳನ್ನು ಕವರ್ ಮಾಡುವುದು ಅವನ ಕೆಲಸ.",
              "te": "అతడు మొదటి నెలలో స్థానిక బీట్ రిపోర్టర్‌గా పనిచేస్తాడు. లక్నోలో జరిగే వివిధ విషయాలను కవర్ చేయడం అతని పని.",
              "or": "ସେ ପ୍ରଥମ ମାସରେ ସ୍ଥାନୀୟ ବିଟ୍ ସାମ୍ବାଦିକ ଭାବରେ କାର୍ଯ୍ୟ କରନ୍ତି | ଲକ୍ଷ୍ନୋ\n  ରେ ଘଟୁଥିବା ବିଭିନ୍ନ ଘଟଣାକୁ କଭର କରିବା ତାଙ୍କର କାମ |",
              "as": "তেওঁ প্ৰথম মাহত স্থানীয় বীট প্ৰতিবেদক হিচাপে কাম কৰে। তেওঁৰ কাম হৈছে লক্ষ্ণৌত ঘটি থকা বিভিন্ন ঘটনাবোৰ সামৰি লোৱা।",
              "gu": "તે પહેલા મહિનામાં સ્થાનિક બીટ રિપોર્ટર તરીકે કામ કરે છે. તેમનું કામ લખનૌમાં થઈ રહેલી વિવિધ બાબતોને આવરી લેવાનું છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_2",
              "hi": "C2_1_2",
              "ka": "C2_1_2",
              "te": "C2_1_2",
              "or": "C2_1_2",
              "as": "C2_1_2",
              "gu": "C2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As part of the job, he attends press conferences of politicians and important meetings that the senior reporter selects.",
              "hi": "  नौकरी के एक दायित्व में, वह राजनेताओं की प्रेस कॉन्फ्रेंस और सीनियर रिपोर्टर द्वारा चुनी गई महत्वपूर्ण बैठकों में भाग लेते हैं।",
              "ka": "ಕೆಲಸದ ಭಾಗವಾಗಿ, ಅವನು ರಾಜಕಾರಣಿಗಳ ಪತ್ರಿಕಾಗೋಷ್ಠಿಗಳು ಮತ್ತು ಹಿರಿಯ ವರದಿಗಾರರು ಆಯ್ಕೆ ಮಾಡುವ ಪ್ರಮುಖ ಸಭೆಗಳಿಗೆ ಹಾಜರಾಗುತ್ತಾನೆ.",
              "te": "ఉద్యోగంలో భాగంగా, అతను సీనియర్ రిపోర్టర్ సెలెక్ట్ చేసిన రాజకీయ నాయకుల ప్రెస్ కాన్ఫరెన్స్‌లు మరియు ముఖ్యమైన సమావేశాలకు హాజరవుతాడు.",
              "or": "ଚାକିରିର ଏକ ଅଂଶ ଭାବରେ ସେ ରାଜନେତାଙ୍କ ସାମ୍ବାଦିକ ସମ୍ମିଳନୀ\n  ଏବଂ ବରିଷ୍ଠ ସାମ୍ବାଦିକ ଚୟନ କରୁଥିବା ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ବଇଠକରେ ଯୋଗ ଦିଅନ୍ତି |",
              "as": "চাকৰিৰ অংশ হিচাপে, তেওঁ ৰাজনীতিবিদসকলৰ সংবাদমেল আৰু জ্যেষ্ঠ প্ৰতিবেদকে বাছনি কৰা গুৰুত্বপূৰ্ণ সভাত উপস্থিত থাকে।",
              "gu": "નોકરીના ભાગ રૂપે, તે રાજકારણીઓની પ્રેસ કોન્ફરન્સ અને વરિષ્ઠ રિપોર્ટર પસંદ કરે છે તે મહત્વપૂર્ણ મીટિંગ્સમાં હાજરી આપે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_3",
              "hi": "C2_1_3",
              "ka": "C2_1_3",
              "te": "C2_1_3",
              "or": "C2_1_3",
              "as": "C2_1_3",
              "gu": "C2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He has to spend a lot of time on the field covering the event and coming back to the office, and writing the reports.",
              "hi": "उसे क्षेत्र में घटना को कवर करने और कार्यालय में वापस आकर रिपोर्ट लिखने में बहुत समय बिताना होगा।",
              "ka": "ಅವನು ಈವೆಂಟ್ಗಳನ್ನು  ಕವರ್ ಮಾಡುತ್ತಾ ಮತ್ತೆ ಕಛೇರಿಗೆ ಬರುತ್ತಾ, ವರದಿಗಳನ್ನು ಬರೆಯುತ್ತಾ ಕ್ಷೇತ್ರದಲ್ಲಿ ಸಾಕಷ್ಟು ಸಮಯವನ್ನು ಕಳೆಯಬೇಕಾಗುತ್ತದೆ.",
              "te": "అతను ఈవెంట్‌ను కవర్ చేయడానికి మరియు కార్యాలయానికి తిరిగి వచ్చి రిపోర్ట్ రాయడానికి చాలా సమయం ఫీల్డ్ లో గడపవలసి ఉంటుంది.",
              "or": "ତାଙ୍କୁ ଇଭେଣ୍ଟକୁ ଆବୃତ୍ତି କରିବା ଏବଂ ଅଫିସକୁ ଫେରିବା ଏବଂ ରିପୋର୍ଟ\n  ଲେଖିବା ପାଇଁ ତାଙ୍କୁ ଅନେକ ସମୟ ଅତିବାହିତ କରିବାକୁ ପଡିବ |",
              "as": "তেওঁ অনুষ্ঠানবোৰ কভাৰ কৰি আৰু কাৰ্যালয়লৈ ঘূৰি আহি, প্ৰতিবেদনবোৰ লিখিবলৈ যথেষ্ট সময় অতিবাহিত কৰিবলগীয়া হয়।",
              "gu": "તેણે ઘટનાને આવરી લેવા અને ઓફિસમાં પાછા આવવા અને રિપોર્ટ્સ લખવામાં ઘણો સમય વિતાવવો પડે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_2_1",
              "hi": "C2_2_1",
              "ka": "C2_2_1",
              "te": "C2_2_1",
              "or": "C2_2_1",
              "as": "C2_2_1",
              "gu": "C2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Kuldeep decides to visit his family and asks Madhav to come along as his family would be happy to meet Madhav.",
              "hi": "कुलदीप ने अपने परिवार से मिलने का निर्णय किया और माधव को अपने साथ आने के लिए कहा कि उसका परिवार माधव से मिलकर खुश होगा।",
              "ka": "ಕುಲದೀಪ್ ತನ್ನ ಕುಟುಂಬವನ್ನು ಭೇಟಿ ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ ಮತ್ತು ಮಾಧವನನ್ನು ಭೇಟಿಯಾಗಲು ಅವರ ಕುಟುಂಬವು ಸಂತೋಷಪಡುವ ಕಾರಣ ಮಾಧವನನ್ನು ಬರಲು ಕೇಳಿಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "కుల్దీప్ అతని కుటుంబం దగ్గరికి వెళ్లాలనుకుంటాడు మరియు మాధవ్‌ని కలవడానికి అతని కుటుంబం సంతోషిస్తుంది కాబట్టి మాధవ్‌ను కూడా రమ్మని పిలుస్తాడు.",
              "or": "କୁଲଦୀପ ତାଙ୍କ ପରିବାରକୁ ଦେଖା କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି ଏବଂ ମାଧବଙ୍କୁ\n  ସାକ୍ଷାତ କରିବାକୁ ତାଙ୍କ ପରିବାର ଖୁସି ଥିବାରୁ ମାଧବଙ୍କୁ ଆସିବାକୁ କହିଛନ୍ତି।",
              "as": "কুলদীপে তেওঁৰ পৰিয়ালক লগ কৰাৰ সিদ্ধান্ত লয় আৰু মাধৱক লগত আহিবলৈ কয় কিয়নো তেওঁৰ পৰিয়ালে মাধৱক লগ পাই ভাল পাব।",
              "gu": "કુલદીપ તેના પરિવારને મળવાનું નક્કી કરે છે અને માધવને સાથે આવવા કહે છે કારણ કે તેનો પરિવાર માધવને મળીને ખુશ થશે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_2_2//",
              "hi": "C2_2_2 //",
              "ka": "C2_2_2//",
              "te": "C2_2_2//",
              "or": "C2_2_2//",
              "as": "C2_2_2//",
              "gu": "C2_2_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୁଲଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Kuldeep",
              "hi": "कुलदीप",
              "ka": "ಕುಲ್ದೀಪ್",
              "te": "కుల్దీప్",
              "or": "କୁଲଦୀପ",
              "as": "কুলদীপ",
              "gu": "કુલદીપ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Have you packed?",
              "hi": "क्या तुमने सामान रख लिया है?",
              "ka": "ನೀನು ಪ್ಯಾಕ್ ಮಾಡಿದ್ದು ಆಯಿತಾ?",
              "te": "నువ్వు ప్యాక్ చేసుకున్నావా?",
              "or": "ଆପଣ ପ୍ୟାକ୍ କରିଛନ୍ତି କି?",
              "as": "তুমি কাপোৰ-কানি বান্ধিলানে?",
              "gu": "તે પેક કર્યું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "No, not yet.",
              "hi": "नहीं अभी तक नहीं।",
              "ka": "ಇಲ್ಲ, ಇನ್ನೂ ಇಲ್ಲ",
              "te": "లేదు ఇంకా కాలేదు.",
              "or": "ନା ଏ ପର୍ଯନ୍ତ ନୁହଁ।",
              "as": "নাই, এতিয়ালৈকে বান্ধা নাই।",
              "gu": "ના હમણાં નહિ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Madhav, the bus leaves in 3 hours! Just hurry. You know how excited Ma and Baba are to meet you. Ma has been preparing food all day.",
              "hi": "माधव, बस 3 घंटे में चली जाएगी! जल्दी करो। तुम जानते हो कि माँ और बाबा तुमसे मिलने के लिए कितना उत्साहित हैं। माँ पूरे दिन से भोजन तैयार कर रही हैं।",
              "ka": "ಮಾಧವ್, ಬಸ್ಸು 3 ಗಂಟೆಯಲ್ಲಿ ಹೊರಡುತ್ತದೆ!ಬೇಗ ಹೊರಡು. ಮಾ ಮತ್ತು ಬಾಬಾ ನಿನ್ನನ್ನು ಭೇಟಿಯಾಗಲು ಎಷ್ಟು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆಂದು ನಿನಗೆ ತಿಳಿದಿದೆ. ಅಮ್ಮ ದಿನವಿಡೀ ಆಹಾರ ತಯಾರಿಯಲ್ಲಿದ್ದಾಳೆ.",
              "te": "3 గంటల్లో బస్సు బయలుదేరుతుంది మాధవ్! త్వరగా కానివ్వు. అమ్మ మరియు బాబా నిన్ను కలవడానికి ఎంత ఉత్సాహంగా ఉన్నారో నీకు తెలుసు కదా. అమ్మ రోజంతా ఆహారం తయారు చేస్తుంది.",
              "or": "ମାଧବ, ବସ୍ hours ଘଣ୍ଟା ମଧ୍ୟରେ ଚାଲିଯାଏ! ଶୀଘ୍ର ଶୀଘ୍ର ଯାଅ |\n  ଆପଣ ଜାଣନ୍ତି ମା ଏବଂ ବାବା ଆପଣଙ୍କୁ ଭେଟିବା ପାଇଁ କେତେ ଉତ୍ସାହିତ | ମା ଦିନସାରା ଖାଦ୍ୟ ପ୍ରସ୍ତୁତ କରୁଛନ୍ତି |",
              "as": "মাধৱ, বাছখন ৩ঘণ্টাৰ ভিতৰত ওলাই যায়! সোনকাল কৰা। তুমি জানা মা আৰু বাবা তোমাক লগ পাবলৈ কিমান উৎসাহিত। মায়ে গোটেই দিনটো খাদ্য প্ৰস্তুত কৰি আছে।",
              "gu": "માધવ, બસ 3 કલાકમાં નીકળે છે! જરા ઉતાવળ કર. તું જાણે છે કે મા અને પપ્પા તને મળવા માટે કેટલા ઉત્સાહિત છે. મા આખો દિવસ ભોજન બનાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "Yaar, I can't wait to eat aunty's poori aloo.",
              "hi": "यार, मैं चाची की बनाई पूरी आलू खाने के लिए और इंतजार नहीं कर सकता।",
              "ka": "ಯಾರ್, ನಾನು ಆಂಟಿಯ ಪೂರಿ ಆಲೂ ತಿನ್ನಲು ಕಾಯುತ್ತಿದ್ದೇನೆ",
              "te": "యార్, నేను ఆంటీ చేసే పూరీ ఆలూ తినడానికి ఎదురు చూస్తున్నాను.",
              "or": "ହାଁ ରେ, ମୁଁ ମାଉସୀଙ୍କ ପୁରୀ ଆଲୁ ଖାଇବାକୁ ଅପେକ୍ଷା କରିପାରିବି ନାହିଁ |",
              "as": "বন্ধু, মই খুড়ীয়ে বনোৱা পুৰি-আলু খাবলৈ ৰ’ব নোৱাৰা হৈ আছোঁ।",
              "gu": "યાર, હું કાકીનું પૂરી અને બટાકાનું શાક ખાવા માટે રાહ જોઈ શકતો નથી."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_3_1",
              "hi": "C2_3_1",
              "ka": "C2_3_1",
              "te": "C2_3_1",
              "or": "C2_3_1",
              "as": "C2_3_1",
              "gu": "C2_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They reach Kudeep's village, and his family is delighted to see Madhav. After helping them settle in, they meet in the courtyard for chai.",
              "hi": "वे कुलदीप के गांव तक पहुंचते हैं, और उसका परिवार माधव को देखकर प्रसन्न होता है। उसके आराम का प्रबंध करने के बाद, वे चाय के लिए आंगन में मिलते हैं।",
              "ka": "ಅವರು ಕುಲದೀಪ್‌ನ ಹಳ್ಳಿಯನ್ನು ತಲುಪುತ್ತಾರೆ ಮತ್ತು ಅವರ ಕುಟುಂಬವು ಮಾಧವ್‌ನನ್ನು ನೋಡಿ ಸಂತೋಷಪಡುತ್ತದೆ. ಅವರು ಸುಧಾರಿಸಲು ಸಹಾಯ ಮಾಡಿದ ನಂತರ, ಅವರು ಚಾಯ್ಗಾಗಿ ಅಂಗಳದಲ್ಲಿ ಭೇಟಿಯಾಗುತ್ತಾರೆ.",
              "te": "వారు కుల్దీప్ గ్రామానికి చేరుకున్నారు, మరియు అతని కుటుంబం మాధవ్‌ని చూసి సంతోషిస్తుంది. వారు వచ్చి ఫ్రెష్ అయ్యాక, వారు చాయ్ కోసం కోర్ట్ యార్డ్ లో కలుసుకుంటారు.",
              "or": "ସେମାନେ କୁଦୀପଙ୍କ ଗାଁରେ ପହଞ୍ଚନ୍ତି, ଏବଂ ତାଙ୍କ ପରିବାର ମାଧବଙ୍କୁ \n ଦେଖି ବହୁତ ଖୁସି | ସେମାନଙ୍କୁ ବସିବାରେ ସାହାଯ୍ୟ କରିବା ପରେ, ସେମାନେ ଚା ’ପାଇଁ ପ୍ରାଙ୍ଗଣରେ ଭେଟନ୍ତି |",
              "as": "তেওঁলোকে কুলদীপৰ গাঁৱত উপস্থিত হয়, আৰু তেওঁৰ পৰিয়ালে মাধৱক দেখি আনন্দিত হয়। তেওঁলোকক থিতাপি লোৱাত সহায় কৰাৰ পিছত, তেওঁলোকে চাহৰ বাবে চোতালত লগ হয়।",
              "gu": "તેઓ કુદીપના ગામ પહોંચે છે, અને માધવને જોઈને તેનો પરિવાર ખુશ થાય છે. તેમને સ્થાયી થવામાં મદદ કર્યા પછી, તેઓ ચા પીવા માટે આંગણામાં મળે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_3_2//",
              "hi": "C2_3_2 //",
              "ka": "C2_3_2//",
              "te": "C2_3_2//",
              "or": "C2_3_2//",
              "as": "C2_3_2//",
              "gu": "C2_3_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Kuldeep's Mother",
              "hi": "कुलदीप की माँ",
              "ka": "ಕುಲದೀಪನ ಅಮ್ಮ",
              "te": "కుల్దీప్ తల్లి",
              "or": "କୁଲଦୀପଙ୍କ ମାତା |",
              "as": "কুলদীপৰ মাক",
              "gu": "કુલદીપની માતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ |",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Kuldeep's Father",
              "hi": "कुलदीप के पिता",
              "ka": "ಕುಲದೀಪನ ಅಪ್ಪ",
              "te": "కుల్దీప్ తండ్రి",
              "or": "କୁଲଦୀପଙ୍କ ପିତା",
              "as": "কুলদীপৰ দেউতাক",
              "gu": "કુલદીપના પિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "So Madhav, how is work? We heard how difficult it was for you in the past few years. We were all so worried for you.",
              "hi": "तो माधव, काम कैसा चल रहा है? हमने सुना है कि पिछले कुछ वर्षों में तुम्हारे लिए यह कितना मुश्किल था। हम सब तुम्हारे लिए बहुत चिंतित थे।",
              "ka": "ಹಾಗಾದರೆ ಮಾಧವ್, ಕೆಲಸ ಹೇಗಿದೆ? ಕಳೆದ ಕೆಲವು ವರ್ಷಗಳಲ್ಲಿ ನಿನಗೆ ಎಷ್ಟು ಕಷ್ಟವಾಯಿತು ಎಂದು ನಾವು ಕೇಳಿದ್ದೇವೆ. ನಾವೆಲ್ಲರೂ ನಿನಗಾಗಿ ತುಂಬಾ ಚಿಂತಿತರಾಗಿದ್ದೆವು.",
              "te": "మాధవ్, పని ఎలా ఉంది? గత కొన్ని సంవత్సరాలుగా నువ్వు ఎంత కష్టపడ్డావో మేము విన్నాము. నీ గురించి మేమంతా చాలా బాధపడ్డాం.",
              "or": "ତେବେ ମାଧବ, କାମ କିପରି ଅଛି? ଗତ କିଛି ବର୍ଷ ମଧ୍ୟରେ ଆପଣଙ୍କ\n  ପାଇଁ ଏହା କେତେ କଷ୍ଟକର ତାହା ଆମେ ଶୁଣିଛୁ | ଆମେ ସମସ୍ତେ ଆପଣଙ୍କ ପାଇଁ ଏତେ ଚିନ୍ତିତ ଥିଲୁ |",
              "as": "তেন্তে মাধৱ, কাম কেনেকুৱা চলিছে? আমি শুনিছিলোঁ যে যোৱা কেইবছৰমান তোমাৰ বাবে বহুত কঠিন আছিল। আমি সকলোৱে তোমাৰ বাবে ইমান চিন্তিত আছিলোঁ।",
              "gu": "તો માધવ, કામ કેવું છે? અમે સાંભળ્યું છે કે છેલ્લા કેટલાક વર્ષોમાં તારા માટે તે કેટલું મુશ્કેલ હતું. અમે બધા તમારા માટે ખૂબ ચિંતિત હતા."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I know, aunty. But Kuldeep and Badri have been there for me throughout. There is a lot I still need to learn, but yes, I am happy with my job.",
              "hi": "मुझे पता है, चाची। लेकिन कुलदीप और बद्री ने मेरा पूरा साथ दिया है। मुझे अभी भी सीखने की ज़रूरत है, लेकिन हाँ, मैं अपनी नौकरी से खुश हूं।",
              "ka": "ನನಗೆ ಗೊತ್ತು ಆಂಟಿ. ಆದರೆ ಕುಲದೀಪ್ ಮತ್ತು ಬದ್ರಿ ನನಗೆ ಆ ಸಮಯದ ಉದ್ದಕ್ಕೂ ಜೊತೆಗೆ ಇದ್ದರು. ನಾನು ಇನ್ನೂ ಕಲಿಯಬೇಕಾದದ್ದು ಬಹಳಷ್ಟಿದೆ, ಆದರೆ ಹೌದು, ನನ್ನ ಕೆಲಸದಲ್ಲಿ ನಾನು ಸಂತೋಷವಾಗಿದ್ದೇನೆ.",
              "te": "నాకు తెలుసు ఆంటీ. కానీ కుల్దీప్‌, బద్రి మాత్రం నాకు ఎప్పుడూ అండగా నిలిచారు. నేను ఇంకా నేర్చుకోవలసినవి చాలా ఉన్నాయి, కానీ నేను నా ఉద్యోగంతో సంతోషంగానే ఉన్నాను.",
              "or": "ମୁଁ ଜାଣେ, ମାଉସୀ | କିନ୍ତୁ କୁଲଦୀପ ଏବଂ ବଦ୍ରି ମୋ ପାଇଁ ସେଠାରେ \n ଅଛନ୍ତି | ମୋତେ ବହୁତ କିଛି ଶିଖିବାକୁ ପଡିବ, କିନ୍ତୁ ହଁ, ମୁଁ ମୋର ଚାକିରିରେ ଖୁସି |",
              "as": "মই জানো, খুড়ী। কিন্তু কুলদীপ আৰু বদ্ৰী গোটেই সময়ছোৱাত মোৰ সৈতে আছিল। মই এতিয়াও বহুতো শিকিবলগীয়া আছে, কিন্তু হয়, মই মোৰ কামত সুখী।",
              "gu": "હું જાણું છું, કાકી. પરંતુ કુલદીપ અને બદ્રી બધી રીતે મારી સાથે હતા. મારે હજી ઘણું શીખવાની જરૂર છે, પણ હા, હું મારી નોકરીથી ખુશ છું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Kuldeep told us about how you've become a big journalist?",
              "hi": "कुलदीप ने हमें बताया कि तुम एक बड़े पत्रकार कैसे बने?",
              "ka": "ನೀನು ಹೇಗೆ ಒಬ್ಬ ದೊಡ್ಡ ಪತ್ರಕರ್ತನಾದೆ ಎನ್ನುವ ಬಗ್ಗೆ ಕುಲದೀಪ್ ನಮಗೆ ತಿಳಿಸಿದ್ದಾನೆ?",
              "te": "నువ్వు పెద్ద జర్నలిస్ట్ గా ఎలా మారావో అని కుల్దీప్ మాకు చెప్పాడు?",
              "or": "ତୁମେ କିପରି ଜଣେ ବଡ ସାମ୍ବାଦିକ ହୋଇଛ ସେ ବିଷୟରେ କୁଲଦୀପ\n  ଆମକୁ କହିଥିଲେ?",
              "as": "কুলদীপে আমাক কৈছিল তুমি কেনেদৰে এজন ডাঙৰ সাংবাদিক হৈছা?",
              "gu": "કુલદીપે અમને કહ્યું કે તું મોટો પત્રકાર બની ગયો છે?"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_3_3////",
              "hi": "C2_3_3 ////////",
              "ka": "C2_3_3////",
              "te": "C2_3_3////",
              "or": "C2_3_3////",
              "as": "C2_3_3////",
              "gu": "C2_3_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Uncle, Kuldeep says anything. I just wrote my fourth article. I have been enjoying journalism quite a lot. Since it's mostly the local news that I cover, it isn't very hectic either.",
              "hi": "चाचा, कुलदीप कुछ भी कहता है। मैंने अभी अपना चौथा लेख लिखा है। मैं पत्रकारिता का आनंद ले रहा हूं। चूंकि यह ज्यादातर स्थानीय समाचार है जो मैं लिखता हूं, इसलिए इसमें बहुत व्यस्तता नहीं है।",
              "ka": "ಅಂಕಲ್, ಕುಲದೀಪ್ ಏನು ಬೇಕಾದರೂ ಹೇಳುತ್ತಾನೆ. ನಾನು ನನ್ನ ನಾಲ್ಕನೇ ಲೇಖನವನ್ನು ಬರೆದಿದ್ದೇನೆ. ನಾನು ಪತ್ರಿಕೋದ್ಯಮವನ್ನು ಸಾಕಷ್ಟು ಆನಂದಿಸುತ್ತಿದ್ದೇನೆ. ಇದು ಹೆಚ್ಚಾಗಿ ನಾನು ಆವರಿಸುವ ಸ್ಥಳೀಯ ಸುದ್ದಿಯಾಗಿರುವುದರಿಂದ, ಇದು ತುಂಬಾ ಬಿಡುವಿಲ್ಲದ ಕೆಲಸವೇನಲ್ಲ.",
              "te": "అంకుల్, కుల్దీప్ ఏమైనా చెప్తాడు. నేను నా నాల్గవ ఆర్టికల్ రాశాను అంతే. నేను జర్నలిజంను చాలా ఎంజాయ్ చేస్తున్నాను. నేను ఎక్కువగా స్థానిక వార్తలే కవర్ చేస్తాను కాబట్టి, ఇది కూడా ఎక్కువ ఇబ్బందిగా ఏం లేదు.",
              "or": "ମାମୁଁ, କୁଲଦୀପ କିଛି କୁହନ୍ତି | ମୁଁ କେବଳ ମୋର ଚତୁର୍ଥ ଲେଖା ଲେଖିଲି\n  | ମୁଁ ସାମ୍ବାଦିକତାକୁ ବହୁତ ଉପଭୋଗ କରୁଛି | ଯେହେତୁ ଏହା ପ୍ରାୟତ the ସ୍ଥାନୀୟ ସମ୍ବାଦ ଯାହା ମୁଁ କଭର୍ କରେ, ଏହା ମଧ୍ୟ ଅତ୍ୟଧିକ ବ୍ୟସ୍ତ ନୁହେଁ |",
              "as": "খুড়া, কুলদীপে যিকোনো কথা কয়। মই মাত্ৰ মোৰ চতুৰ্থ প্ৰবন্ধটো লিখিছোঁ। মই সাংবাদিকতা যথেষ্ট উপভোগ কৰিছোঁ। যিহেতু ইয়াত বেছিভাগ মই স্থানীয় বাতৰি কভাৰ কৰোঁ, মই খুব ব্যস্তও নাথাকো।",
              "gu": "કાકા, કુલદીપ કંઈ પણ કહે. મેં હમણાં જ મારો ચોથો લેખ લખ્યો. હું પત્રકારત્વનો ઘણો આનંદ માણી રહ્યો છું. કારણ કે તે મોટાભાગે સ્થાનિક સમાચાર છે જેને હું આવરી લઉં છું, તે ખૂબ જ વ્યસ્ત પણ નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "You know, a few kilometres from here a chemical factory will be built. We were wondering if any journalists would come, but nobody did.",
              "hi": "तुम जानते हो, यहां से कुछ किलोमीटर एक रासायनिक कारखाना बनाया जाएगा। हम सोच रहे थे कि क्या कोई पत्रकार आएगा, लेकिन कोई नहीं आया।",
              "ka": "ನಿನಗೆ ಗೊತ್ತಾ, ಇಲ್ಲಿಂದ ಕೆಲವು ಕಿಲೋಮೀಟರ್ ದೂರದಲ್ಲಿ ಕೆಮಿಕಲ್ ಫ್ಯಾಕ್ಟರಿ ನಿರ್ಮಾಣವಾಗುತ್ತದೆ. ಯಾರಾದರೂ ಪತ್ರಕರ್ತರು ಬರುತ್ತಾರೆಯೇ ಎಂದು ನಾವು ಯೋಚಿಸುತ್ತಿದ್ದೆವು, ಆದರೆ ಯಾರೂ ಬರಲಿಲ್ಲ.",
              "te": "మీకు తెలుసా, ఇక్కడ నుండి కొన్ని కిలోమీటర్ల దూరంలో ఒక రసాయన కర్మాగారం నిర్మించబడుతుంది. జర్నలిస్టులెవరైనా వస్తారా అని అనుకున్నాం కానీ ఎవరూ రాలేదు.",
              "or": "ଆପଣ ଜାଣନ୍ତି, ଏଠାରୁ କିଛି କିଲୋମିଟର ଦୂରରେ ଏକ ରାସାୟନିକ କା\n ରଖାନା ନିର୍ମାଣ ହେବ | ଆମେ ଭାବୁଥିଲୁ କୌଣସି ସାମ୍ବାଦିକ ଆସିବେ କି ନାହିଁ, କିନ୍ତୁ କେହି ଆସିଲେ ନାହିଁ |",
              "as": "তুমি জানানে, ইয়াৰ পৰা কেইকিলোমিটাৰমান দূৰত এটা ৰাসায়নিক কাৰখানা নিৰ্মাণ কৰা হ'ব। আমি ভাবি আছিলোঁ যে কোনোবা সাংবাদিক আহিব, কিন্তু কোনো নাহিলে।",
              "gu": "તને ખબર છે કે અહીંથી થોડાક કિલોમીટર દૂર કેમિકલ ફેક્ટરી બનશે. અમે વિચારતા હતા કે કોઈ પત્રકાર આવશે કે કેમ, પરંતુ કોઈએ ન કર્યું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I haven't heard about this, aunty. How did you find out?",
              "hi": "मैंने इस बारे में नहीं सुना है, चाची। आपको कैसे पता चला?",
              "ka": "ನಾನು ಈ ಬಗ್ಗೆ ಕೇಳಿಲ್ಲ ಆಂಟಿ. ನೀವು ಹೇಗೆ ಅದನ್ನು ಕಂಡುಹಿಡಿದ್ದೀರಿ?",
              "te": "దీని గురించి నేను వినలేదు ఆంటీ. మీకు ఎలా తెలిసింది?",
              "or": "ମାଉସୀ, ମୁଁ ଏ ବିଷୟରେ ଶୁଣି ନାହିଁ | ଆପଣ କିପରି ଜାଣିଲେ?",
              "as": "মই এই বিষয়ে শুনা নাই, খুড়ী। আপুনি কেনেকৈ গম পালে?",
              "gu": "મેં આ વિશે સાંભળ્યું નથી, આંટી. તમને કેવી રીતે ખબર પડી?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "We have not heard from official sources, but people from the private company have been making the rounds talking to the Adivasis and farmers in that area. That's all I know. If you are here for some time, you should find out what is going on. It doesn't feel right.",
              "hi": "हमने आधिकारिक स्रोतों से नहीं सुना है, लेकिन निजी कंपनी के लोग उस क्षेत्र के आदिवासियों और किसानों से बात कर रहे हैं, वहां दौरा कर रहे हैं। मुझे बस इतना पता है। यदि तुम यहां कुछ समय के लिए हो, तो तुमको पता लगाना चाहिए कि क्या हो रहा है। यह सही नहीं लगता है।",
              "ka": "ಅಧಿಕೃತ ಮೂಲಗಳಿಂದ ನಾವು ಕೇಳಿಲ್ಲ, ಆದರೆ ಖಾಸಗಿ ಕಂಪನಿಯ ಜನರು ಆ ಪ್ರದೇಶದ ಆದಿವಾಸಿಗಳು ಮತ್ತು ರೈತರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಿದ್ದಾರೆ. ನನಗೆ ಗೊತ್ತು ಅಷ್ಟೆ. ನೀನು ಸ್ವಲ್ಪ ಸಮಯ ಇಲ್ಲಿದ್ದು, ಏನಾಗುತ್ತಿದೆ ಎಂದು ಕಂಡುಹಿಡಿಯಬೇಕು. ಇದು ಸರಿ ಅನಿಸುತ್ತಿಲ್ಲ.",
              "te": "అధికారిక వర్గాల నుండి మేము వినలేదు, కాని ప్రైవేట్ కంపెనీకి చెందిన వ్యక్తులు ఆ ప్రాంతంలోని ఆదివాసీలు మరియు రైతులతో మాట్లాడుతున్నారు. నాకు అంతే తెలుసు. నువ్వు ఇక్కడ కొంతకాలం ఉంటే, అక్కడ ఏమి జరుగుతుందో నువ్వు తెలుసుకోవాలి. ఏదీ సరిగ్గా అనిపించట్లేదు.",
              "or": "ଆମେ ସରକାରୀ ସୂତ୍ରରୁ ଶୁଣିନାହୁଁ, କିନ୍ତୁ ବେସରକାରୀ କମ୍ପାନୀର ଲୋକମାନେ ଆଦିବାସୀ \n ଏବଂ ସେହି ଅଞ୍ଚଳର ଚାଷୀଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରୁଛନ୍ତି। ତାହା ମୁଁ ଜାଣେ ଯଦି ଆପଣ କିଛି ସମୟ ପାଇଁ ଏଠାରେ ଅଛନ୍ତି, ତେବେ କଣ ହେଉଛି ତାହା ଜାଣିବା ଉଚିତ୍ | ଏହା ଠିକ୍ ଅନୁଭବ କରେ ନାହିଁ |",
              "as": "আমি চৰকাৰী সূত্ৰৰ পৰা শুনা নাই, কিন্তু ব্যক্তিগত কোম্পানীৰ লোকসকলে সেই অঞ্চলৰ আদিবাসী আৰু কৃষকসকলৰ সৈতে কথা পাতি ফুৰিছে। মই সেইখিনিয়ে জানো। যদি তুমি কিছু সময়ৰ বাবে ইয়াত আছা, তুমি কি হৈ আছে বিচাৰি উলিয়াব পাৰা। এইটো সঠিক যেন লাগা নাই।",
              "gu": "અમે સત્તાવાર સૂત્રો પાસેથી સાંભળ્યું નથી, પરંતુ ખાનગી કંપનીના લોકો તે વિસ્તારના આદિવાસીઓ અને ખેડૂતો સાથે વાત કરી રહ્યા છે. હું એટલું જ જાણું છું. જો તું થોડા સમય માટે અહીં છે, તો તારે ત્યાં શું થઈ રહ્યું છે તે શોધવું જોઈએ. તે યોગ્ય નથી લાગતું."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_4_1",
              "hi": "C2_4_1",
              "ka": "C2_4_1",
              "te": "C2_4_1",
              "or": "C2_4_1",
              "as": "C2_4_1",
              "gu": "C2_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "During one of the evening walks, he sees a group of people near the bridge walking towards the village with large banners and placards. He decides to approach them.",
              "hi": "शाम को एक सैर के समय, वह गांव के पुल की ओर  बड़े बैनर और प्लेकार्ड लेकर जाते हुए लोगों के एक समूह को देखता है। वह उनसे संपर्क करने का निश्चचय करता है।",
              "ka": "ಸಂಜೆಯ ವಾಕಿಂಗ್‌ನಲ್ಲಿ ಒಂದು ಬಾರಿ, ಸೇತುವೆಯ ಬಳಿ ಜನರ ಗುಂಪೊಂದು ದೊಡ್ಡ ಬ್ಯಾನರ್‌ಗಳು ಮತ್ತು ಫಲಕಗಳೊಂದಿಗೆ ಹಳ್ಳಿಯ ಕಡೆಗೆ ನಡೆದುಕೊಂಡು ಹೋಗುವುದನ್ನು ಅವನು ನೋಡುತ್ತಾನೆ. ಅವನು ಅವರನ್ನು ಸಂಪರ್ಕಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "ఒక సాయంత్రం నడక సమయంలో, అతను వంతెన దగ్గర పెద్ద పెద్ద బ్యానర్లు మరియు ప్లకార్డులతో గ్రామం వైపు నడుచుకుంటూ వెళ్తున్న ఒక గ్రూప్ ని చూస్తాడు. అతను వారి దగ్గరికి వెళ్లాలని నిర్ణయించుకుంటాడు.",
              "or": "ସନ୍ଧ୍ୟା ଚାଲିବା ସମୟରେ ସେ ବ୍ରିଜ୍ ନିକଟରେ ଏକ ଗୋଷ୍ଠୀକୁ ବଡ଼ \n ବ୍ୟାନର ଏବଂ ପ୍ଲାକାର୍ଡ ଧରି ଗାଁ ଆଡକୁ ଯାଉଥିବାର ଦେଖନ୍ତି | ସେ ସେମାନଙ୍କ ନିକଟକୁ ଯିବାକୁ ସ୍ଥିର କରନ୍ତି |",
              "as": "এদিন সন্ধিয়া খোজ কঢ়াৰ সময়ত, তেওঁ দলঙৰ ওচৰৰ এদল লোকক ডাঙৰ বেনাৰ আৰু প্লেকাৰ্ড লৈ গাওঁখনৰ ফালে খোজ কাঢ়ি গৈ থকা দেখিলে। তেওঁ তেওঁলোকৰ কাষ চাপিবলৈ সিদ্ধান্ত লয়।",
              "gu": "એક સાંજે ચાલવા જતાં દરમિયાન, તે પુલ પાસે લોકોનું એક જૂથ મોટા બેનરો અને પ્લેકાર્ડ્સ સાથે ગામ તરફ ચાલતા જુએ છે. તે તેમની પાસે જવાનું નક્કી કરે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_4_2",
              "hi": "C2_4_2",
              "ka": "C2_4_2",
              "te": "C2_4_2",
              "or": "C2_4_2",
              "as": "C2_4_2",
              "gu": "C2_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The people he meets are pleased to hear that he is a reporter. They tell him that they are Adivasi families that live a few kilometres from here, in the area assigned to the chemical factory.",
              "hi": "वह जिन लोगों से मिलता है, वे सुनकर प्रसन्न हैं कि वह एक संवाददाता है। वे उसे बताते हैं कि वे आदिवासी परिवार हैं, कुछ किलोमीटर दूर उस क्षेत्र में रहते हैं जो  रासायनिक कारखाने को सौंपा गया है।",
              "ka": "ಅವನು ವರದಿಗಾರನೆಂದು ತಿಳಿದು ಅವನು ಭೇಟಿಯಾದ ಜನರು ಸಂತೋಷಪಡುತ್ತಾರೆ. ಅವರು ಇಲ್ಲಿಂದ ಕೆಲವು ಕಿಲೋಮೀಟರ್ ದೂರದಲ್ಲಿ ರಾಸಾಯನಿಕ ಕಾರ್ಖಾನೆಗೆ ನಿಯೋಜಿಸಲಾದ ಪ್ರದೇಶದಲ್ಲಿ ವಾಸಿಸುವ ಆದಿವಾಸಿ ಕುಟುಂಬಗಳು ಎಂದು ಅವರು ಅವನಿಗೆ ತಿಳಿಸುತ್ತಾರೆ.",
              "te": "అతను రిపోర్టర్ అని తెలిసి అతను కలుసుకున్న వ్యక్తులు సంతోషించారు. ఇక్కడి నుంచి కొన్ని కిలోమీటర్ల దూరంలో కెమికల్‌ ఫ్యాక్టరీకి కేటాయించిన ప్రాంతంలో నివాసం ఉంటున్న ఆదివాసీ కుటుంబాలు తమవని చెప్పారు.",
              "or": "ସେ ସାକ୍ଷାତ କରୁଥିବା ଲୋକମାନେ ଶୁଣି ଖୁସି ହୁଅନ୍ତି ଯେ ସେ ଜଣେ ସାମ୍ବାଦିକ\n  | ସେମାନେ ତାଙ୍କୁ କୁହନ୍ତି ଯେ ସେମାନେ ଆଡିଭାସି ପରିବାର ଯାହାକି ରାସାୟନିକ କାରଖାନାକୁ ଦିଆଯାଇଥିବା ଅଞ୍ଚଳରେ ଏଠାରୁ କିଛି କିଲୋମିଟର ଦୂରରେ ବାସ କରନ୍ତି |",
              "as": "তেওঁ লগ পোৱা লোকসকলে শুনি আনন্দিত হ’ল যে তেওঁ এজন প্ৰতিবেদক। তেওঁলোকে তাক কয় যে তেওঁলোক আদিবাসী পৰিয়াল যি ইয়াৰ পৰা কেই কিলোমিটাৰমান দূৰত বাস কৰে, ৰাসায়নিক কাৰখানা আৱণ্টিত এলেকাত।",
              "gu": "તે જે લોકોને મળે છે તે સાંભળીને ખુશ થાય છે કે તે એક રિપોર્ટર છે. તેઓ તેને કહે છે કે તેઓ આદિવાસી પરિવારો છે જે અહીંથી થોડા કિલોમીટર દૂર એક કેમિકલ ફેક્ટરીને આ વિસ્તારમાં બનાવાની છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_4_3//",
              "hi": "C2_4_3 //",
              "ka": "C2_4_3//",
              "te": "C2_4_3//",
              "or": "C2_4_3//",
              "as": "C2_4_3//",
              "gu": "C2_4_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Protestor",
              "hi": "प्रदर्शनकारी",
              "ka": "ಪ್ರತಿಭಟನಾಕಾರ",
              "te": "ప్రొటెస్టర్",
              "or": "ବିରୋଧକାରୀ |",
              "as": "প্ৰতিবাদকাৰী",
              "gu": "વિરોધ કરનાર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ |",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "We have been trying to bring attention to this, but the authorities have requested the local news agencies to keep quiet.",
              "hi": "हम इस पर ध्यान दिलाने का प्रयास कर रहे हैं, लेकिन अधिकारियों ने स्थानीय समाचार एजेंसियों से चुप रहने का अनुरोध किया है।",
              "ka": "ನಾವು ಈ ಬಗ್ಗೆ ಗಮನ ಸೆಳೆಯಲು ಬಹಳ ಪ್ರಯತ್ನಿಸುತ್ತಿದ್ದೇವೆ, ಆದರೆ ಅಧಿಕಾರಿಗಳು ಮೌನವಾಗಿರುವಂತೆ ಸ್ಥಳೀಯ ಸುದ್ದಿ ಸಂಸ್ಥೆಗಳಿಗೆ ಮನವಿ ಮಾಡಿದ್ದಾರೆ.",
              "te": "మేము దీనిపై అందరి దృష్టి తీసుకురావడానికి ప్రయత్నిస్తున్నాము, కానీ అధికారులు స్థానిక వార్తా సంస్థలను నిశ్శబ్దంగా ఉండాలని అభ్యర్థించారు.",
              "or": "ଆମେ ଏଥିପ୍ରତି ଦୃଷ୍ଟି ଦେବାକୁ ଚେଷ୍ଟା କରୁଛୁ, କିନ୍ତୁ କର୍ତ୍ତୃପକ୍ଷ ଚୁପ୍ ରହିବାକୁ\n  ସ୍ଥାନୀୟ ସମ୍ବାଦ ଏଜେନ୍ସିଗୁଡ଼ିକୁ ଅନୁରୋଧ କରିଛନ୍ତି।",
              "as": "আমি ইয়াৰ প্ৰতি মনোযোগ আকৰ্ষণ কৰিবলৈ চেষ্টা কৰি আছোঁ, কিন্তু কৰ্তৃপক্ষই স্থানীয় সংবাদ সংস্থাসমূহক চুপ থাকিবলৈ অনুৰোধ জনাইছে।",
              "gu": "અમે આ તરફ ધ્યાન દોરવાનો પ્રયાસ કરી રહ્યા છીએ, પરંતુ અધિકારીઓએ સ્થાનિક સમાચાર એજન્સીઓને ચૂપ રહેવા વિનંતી કરી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Why has that happened? Wouldn't they have to announce it to the public at some point?",
              "hi": "ऐसा क्यों हुआ? क्या उन्हें किसी समय पर जनता को इसके विषय में बताना नहीं पड़ेगा?",
              "ka": "ಯಾಕೆ ಹೀಗಾಯಿತು? ಒಂದು ಹಂತದಲ್ಲಿ ಅವರು ಅದನ್ನು ಸಾರ್ವಜನಿಕರಿಗೆ ಘೋಷಿಸಬೇಕಲ್ಲವೇ?",
              "te": "అలా ఎందుకు చేశారు? వారు ఏదో ఒక సమయంలో ప్రజలకు ప్రకటించాల్సిన అవసరం ఉంది కదా?",
              "or": "ଏହା କାହିଁକି ଘଟିଲା? ସେମାନଙ୍କୁ କିଛି ସମୟରେ ଜନସାଧାରଣଙ୍କ \n ନିକଟରେ ଘୋଷଣା କରିବାକୁ ପଡିବ ନାହିଁ କି?",
              "as": "সেইটো কিয় ঘটিছে? তেওঁলোকে কোনোবা এটা সময়ত এইটো ৰাইজৰ আগত ঘোষণা কৰিব নালাগিব নেকি?",
              "gu": "એવું કેમ થયું છે? શું તેઓએ કોઈક સમયે જાહેર જનતા સમક્ષ તેની જાહેરાત કરવાની જરૂર નથી?"
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_4_4////",
              "hi": "C2_4_4 ////",
              "ka": "C2_4_4////",
              "te": "C2_4_4////",
              "or": "C2_4_4////",
              "as": "C2_4_4////",
              "gu": "C2_4_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Sir, they want to show that the area was completely uninhabited. That's why they are clearing us out of our homes.",
              "hi": "महोदय, वे यह दिखाना चाहते हैं कि क्षेत्र पूरी तरह से निर्जन था। यही कारण है कि वे हमें अपने घरों से बाहर निकाल रहे हैं।",
              "ka": "ಸರ್, ಅವರು ಆ ಪ್ರದೇಶವು ಸಂಪೂರ್ಣವಾಗಿ ಜನವಸತಿಯನ್ನು ಹೊಂದಿಲ್ಲ ಎಂದು ತೋರಿಸಲು ಬಯಸುತ್ತಾರೆ. ಅದಕ್ಕಾಗಿಯೇ ಅವರು ನಮ್ಮನ್ನು ನಮ್ಮ ಮನೆಗಳಿಂದ ತೆರವುಗೊಳಿಸುತ್ತಿದ್ದಾರೆ.",
              "te": "వారు ఆ ప్రాంతం పూర్తిగా నిర్మానుష్యంగా ఉందని చూపించాలన్నారు సార్. అందుకే మమ్మల్ని మా ఇళ్ల నుంచి బయటకు పంపిస్తున్నారు.",
              "or": "ସାର୍, ସେମାନେ ଦର୍ଶାଇବାକୁ ଚାହାଁନ୍ତି ଯେ ଏହି ଅଞ୍ଚଳଟି ସମ୍ପୂର୍ଣ୍ଣ \n ଜନବହୁଳ ଥିଲା | ସେଥିପାଇଁ ସେମାନେ ଆମ ଘରୁ ବାହାର କରୁଛନ୍ତି |",
              "as": "ছাৰ, তেওঁলোকে দেখুৱাব বিচাৰে যে অঞ্চলটো সম্পূৰ্ণৰূপে জনবসতিহীন আছিল। সেইকাৰণে তেওঁলোকে আমাক আমাৰ ঘৰৰ পৰা আঁতৰাই দিছে।",
              "gu": "સાહેબ, તેઓ બતાવવા માંગે છે કે વિસ્તાર સાવ નિર્જન હતો. તેથી જ તેઓ અમને અમારા ઘરની બહાર કાઢી રહ્યા છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Everything we own is on that land. We can't even ask for rehabilitation if they remove us like this.",
              "hi": "हमारा जो कुछ भी है वह भूमि है। यदि वे हमें इस तरह हटाते हैं तो हम पुनर्वास के लिए पूछ भी नहीं सकते हैं।",
              "ka": "ನಮ್ಮ ಆಸ್ತಿಯೆಲ್ಲವೂ ಆ ಭೂಮಿಯೇ ಆಗಿದೆ. ನಮ್ಮನ್ನು ಈ ರೀತಿ ಹೊರಗೆ ಹಾಕಿದರೆ ಪುನರ್ವಸತಿ ಕೇಳಲೂ ಸಾಧ್ಯವಿಲ್ಲ.",
              "te": "మాకు ఉన్నదంతా ఆ భూమిపైనే. మమ్మల్ని ఇలా పంపిస్తే పునరావాసం కూడా అడగలేం.",
              "or": "ଆମର ସମସ୍ତ ଜିନିଷ ସେହି ଜମିରେ ଅଛି | ଯଦି ସେମାନେ ଆମକୁ\n  ଏହିପରି ଅପସାରଣ କରନ୍ତି ତେବେ ଆମେ ପୁନର୍ବାସ ପାଇଁ ମଧ୍ୟ ପଚାରି ପାରିବୁ ନାହିଁ |",
              "as": "আমাৰ সকলোবোৰ সেই ভূমিতে আছে। যদি তেওঁলোকে আমাক এনেদৰে আঁতৰ কৰে তেন্তে আমি আনকি পুনৰ্সংস্থাপনৰ বাবেও সুধিব নোৱাৰোঁ।",
              "gu": "અમારી માલિકીનું બધું જ તે જમીન પર છે. જો તેઓ અમને આ રીતે દૂર કરેશે તો અમે પુન:સ્થાપન માટે પણ કહી શકીએ નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "And nobody wants a chemical factory in their backyard. We know it is dangerous for the health of our families.",
              "hi": "और कोई भी अपने घर के पीछे एक रासायनिक कारखाना नहीं चाहता है। हम जानते हैं कि यह हमारे परिवारों के स्वास्थ्य के लिए खतरनाक है।",
              "ka": "ಮತ್ತು ಯಾರೂ ತಮ್ಮ ಹಿತ್ತಲಿನಲ್ಲಿ ರಾಸಾಯನಿಕ ಕಾರ್ಖಾನೆಯನ್ನು ಬಯಸುವುದಿಲ್ಲ. ಇದು ನಮ್ಮ ಕುಟುಂಬದ ಆರೋಗ್ಯಕ್ಕೆ ಅಪಾಯಕಾರಿ ಎಂದು ನಮಗೆ ತಿಳಿದಿದೆ.",
              "te": "మరియు ఎవరూ తమ పెరట్లో కెమికల్ ఫ్యాక్టరీ ఉండాలని కోరుకోరు. ఇది మా కుటుంబాల ఆరోగ్యానికి ప్రమాదకరమని మాకు తెలుసు.",
              "or": "ଏବଂ ସେମାନଙ୍କ ପଛପଟରେ ଏକ ରାସାୟନିକ କାରଖାନା କେହି ଚାହାଁନ୍ତି\n  ନାହିଁ | ଆମେ ଜାଣୁ ଏହା ଆମ ପରିବାରର ସ୍ୱାସ୍ଥ୍ୟ ପାଇଁ ବିପଦଜନକ ଅଟେ |",
              "as": "আৰু কোনেও তেওঁলোকৰ পিছফালে এটা ৰাসায়নিক কাৰখানা নিবিচাৰে। আমি জানো যে এইটো আমাৰ পৰিয়ালৰ স্বাস্থ্যৰ বাবে বিপদজনক।",
              "gu": "અને અમારે કોઈને પણ પાછલા રસ્તે કેમિકલ ફેક્ટરી જોઈતી નથી. અમે જાણીએ છીએ કે તે અમારા પરિવારના સ્વાસ્થ્ય માટે જોખમી છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_4_5",
              "hi": "C2_4_5",
              "ka": "C2_4_5",
              "te": "C2_4_5",
              "or": "C2_4_5",
              "as": "C2_4_5",
              "gu": "C2_4_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav documents the story by taking photographs and making notes to help him report the story.",
              "hi": "माधव कहानी को रिपोर्ट करने में सहायता करने के लिए तस्वीरें लेकर और नोट्स बनाकर कहानी का दस्तावेजीकरण करता है।",
              "ka": "ಮಾಧವ್ ತನ್ನ ಕಥೆಯನ್ನು ವರದಿ ಮಾಡಲು ಸಹಾಯ ಆಗುವ ಛಾಯಾಚಿತ್ರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುವ ಮತ್ತು ಟಿಪ್ಪಣಿಗಳನ್ನು ಮಾಡುವ ಮೂಲಕ ಕಥೆಯನ್ನು ದಾಖಲಿಸುತ್ತಾರೆ.",
              "te": "మాధవ్ కథను రిపోర్ట్ చేయడానికి ఫోటోగ్రాఫ్‌లు తీసుకొని మరియు నోట్స్ తయారు చేసుకొని కథను డాక్యుమెంట్ చేశాడు.",
              "or": "ତାଙ୍କୁ କାହାଣୀ ରିପୋର୍ଟ କରିବାରେ ସାହାଯ୍ୟ କରିବା ପାଇଁ ଫଟୋଗ୍ରାଫ୍\n  ଏବଂ ନୋଟ୍ ପ୍ରସ୍ତୁତ କରି ମାଧବ କାହାଣୀକୁ ଡକ୍ୟୁମେଣ୍ଟ୍ କରନ୍ତି |",
              "as": "মাধৱে ফটো তুলি আৰু কাহিনীটো প্ৰতিবেদন কৰাত সহায় কৰিবলৈ টোকা প্ৰস্তুত কৰি কাহিনীটো নথিভুক্ত কৰে।",
              "gu": "માધવ તે વાર્તાની સરખી લખવા માટે ફોટાઓ લઈને અને નોંધો બનાવીને વાર્તાનું દસ્તાવેજીકરણ કરે છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_5_1",
              "hi": "C2_5_1",
              "ka": "C2_5_1",
              "te": "C2_5_1",
              "or": "C2_5_1",
              "as": "C2_5_1",
              "gu": "C2_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Over the next few days, he makes a draft of the article. Before sharing this story with his editor, he sends the story to Suraj to get his feedback.",
              "hi": "अगले कुछ दिनों में, वह लेख का एक ड्राफ्ट तैयार करता है। अपने संपादक के साथ इस कहानी को साझा करने से पहले, वह उसकी प्रतिक्रिया प्राप्त करने के लिए सूरज को कहानी भेजता है।",
              "ka": "ಮುಂದಿನ ಕೆಲವು ದಿನಗಳಲ್ಲಿ, ಅವನು ಲೇಖನದ ಕರಡನ್ನು ಸಿದ್ಧಪಡಿಸುತ್ತಾನೆ.. ಈ ಕಥೆಯನ್ನು ತನ್ನ ಸಂಪಾದಕರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳುವ ಮೊದಲು,  ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಪಡೆಯಲು ಅವನು ಕಥೆಯನ್ನು ಸೂರಜ್‌ಗೆ ಕಳುಹಿಸುತ್ತಾನೆ.",
              "te": "తర్వాత కొన్ని రోజుల్లో, అతను ఈ ఆర్టికల్ యొక్క డ్రాఫ్ట్ తయారు చేస్తాడు. ఈ కథనాన్ని తన ఎడిటర్‌ కు చూపించే ముందు, అతను తను ఫీడ్బాక్ కోసం కథను సూరజ్‌కి పంపాడు.",
              "or": "ପରବର୍ତ୍ତୀ କିଛି ଦିନ ମଧ୍ୟରେ, ସେ ପ୍ରବନ୍ଧର ଏକ ଡ୍ରାଫ୍ଟ ପ୍ରସ୍ତୁତ କରନ୍ତି | \n ଏହି ସମ୍ପାଦକ ସହିତ ଏହି କାହାଣୀ ବାଣ୍ଟିବା ପୂର୍ବରୁ, ସେ ତାଙ୍କ ମତାମତ ପାଇବାକୁ ସୁରଜଙ୍କ ନିକଟକୁ କାହାଣୀ ପଠାନ୍ତି |",
              "as": "পৰৱৰ্তী কেইদিনমানৰ ভিতৰত, তেওঁ প্ৰবন্ধটোৰ এখন খচৰা প্ৰস্তুত কৰে। এই কাহিনীটো তেওঁৰ সম্পাদকক দিয়াৰ আগতে, তেওঁ মতামতৰ বাবে সুৰজলৈ কাহিনীটো প্ৰেৰণ কৰে।",
              "gu": "આગામી થોડા દિવસોમાં, તે લેખનો ડ્રાફ્ટ બનાવે છે. આ વાર્તા તેના સંપાદકને બતાવતા પહેલા, તે તેનો પ્રતિસાદ મેળવવા માટે વાર્તા સૂરજને મોકલે છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_5_2(message_block)",
              "hi": "C2_5_2 (MESSAGE_BLOCK)",
              "ka": "C2_5_2(message_block)",
              "te": "C2_5_2(మెసేజ్_బ్లాక్)",
              "or": "C2_5_2(message_block)",
              "as": "C2_5_2(বাৰ্তা_ব্ল’ক)",
              "gu": "C2_5_2(સંદેશ_બ્લોક)"
            }
          },
          {
            "valuePath": "blocks.0.props.messageSentTo",
            "value": {
              "en": "Suraj",
              "hi": "सूरज",
              "ka": "ಸೂರಜ್",
              "te": "సూరజ్",
              "or": "ସୂରଜ",
              "as": "সুৰজ",
              "gu": "સુરજ"
            }
          },
          {
            "valuePath": "blocks.0.props.allMessages.0.messageText",
            "value": {
              "en": "Hi Suraj, I've sent an article to you by email. I think it's an urgent topic that needs to be brought to light. I would love to hear your feedback.",
              "hi": "हाय सूरज, मैंने आपको ईमेल द्वारा एक लेख भेजा है। मुझे लगता है कि यह एक जरूरी विषय है जिसे प्रकाश में लाया जाना चाहिए। मैं आपकी प्रतिक्रिया जानना चाहूंगा।",
              "ka": "ಹಾಯ್ ಸೂರಜ್, ನಾನು ನಿಮಗೆ ಇಮೇಲ್ ಮೂಲಕ ಲೇಖನವನ್ನು ಕಳುಹಿಸಿದ್ದೇನೆ. ಇದು ಬೆಳಕಿಗೆ ತರಬೇಕಾದ ತುರ್ತು ವಿಷಯ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಅರಿಯಲು ನಾನು ಇಷ್ಟಪಡುತ್ತೇನೆ.",
              "te": "హాయ్ సూరజ్, నేను నీకు ఇమెయిల్ ద్వారా ఒక కథనాన్ని పంపాను. ఇది అత్యవసరంగా వెలుగులోకి తీసుకురావాల్సిన అంశం అని నేను భావిస్తున్నాను. నేను మీ అభిప్రాయాన్ని వినాలి అనుకుంటున్నాను.",
              "or": "ନମସ୍କାର ସୁରଜ, ମୁଁ ଆପଣଙ୍କୁ ଇମେଲ ମାଧ୍ୟମରେ ଏକ ଆର୍ଟିକିଲ\n  ପଠାଇଛି | ମୁଁ ଭାବୁଛି ଏହା ଏକ ଜରୁରୀ ବିଷୟ ଯାହାକୁ ସାମ୍ନାକୁ ଆଣିବା ଆବଶ୍ୟକ | ମୁଁ ତୁମର ମତାମତ ଶୁଣିବାକୁ ପସନ୍ଦ କରିବି |",
              "as": "হাই সুৰজ, মই ই-মেইলযোগে আপোনালৈ এটা প্ৰবন্ধ প্ৰেৰণ কৰিছোঁ। মই ভাবো এইটো এটা জৰুৰী বিষয় যাক পোহৰলৈ অনাটো প্ৰয়োজন। মই আপোনাৰ মতামত শুনিবলৈ ভাল পাম।",
              "gu": "હાય સૂરજ, મેં તમને ઈમેલ દ્વારા એક લેખ મોકલ્યો છે. મને લાગે છે કે તે એક તાકીદનો વિષય છે જેને બહાર લાવવાની જરૂર છે. મને તારો પ્રતિસાદ સાંભળવો ગમશે."
            }
          },
          {
            "valuePath": "blocks.0.props.allMessages.1.messageText",
            "value": {
              "en": "Madhav, I read through the article. It is very bold and impressive! It would be best if you got it published in your newspaper. Writing just one story might not be enough. You have to be consistent and follow up with the situation. Reach out to the right people for opinions.",
              "hi": "माधव, मैंने आपका लेख पढ़ा। यह बहुत बोल्ड और प्रभावशाली है! यदि आप इसे अपने समाचार पत्र में प्रकाशित करते हैं तो यह सबसे अच्छा होगा। केवल एक कहानी लिखना पर्याप्त नहीं है। आपको लगातार इस पर काम करते रहना होगा और स्थिति की जांच करनी होगी। इस विषय पर राय जानने के लिए सही लोगों तक पहुंचें।",
              "ka": "ಮಾಧವ್, ನಾನು ಲೇಖನವನ್ನು ಓದಿದ್ದೇನೆ. ಇದು ತುಂಬಾ  ದಿಟ್ಟವಾಗಿದೆ ಹಾಗೂ ಪ್ರಭಾವಶಾಲಿಯಾಗಿದೆ! ಅದನ್ನು ನಿಮ್ಮ ಪತ್ರಿಕೆಯಲ್ಲಿ ಪ್ರಕಟಿಸಿದರೆ ಉತ್ತಮ. ಕೇವಲ ಒಂದು ಕಥೆಯನ್ನು ಬರೆಯುವುದು ಸಾಕಾಗುವುದಿಲ್ಲ. ನೀವು ಸ್ಥಿರವಾಗಿರಬೇಕು ಮತ್ತು ಪರಿಸ್ಥಿತಿಯನ್ನು ಅನುಸರಿಸಬೇಕು. ಅಭಿಪ್ರಾಯಗಳಿಗಾಗಿ ಸರಿಯಾದ ಜನರನ್ನು ತಲುಪಬೇಕು.",
              "te": "మాధవ్, నేను ఆర్టికల్ చదివాను. ఇది చాలా బోల్డ్ మరియు ఆకట్టుకునేలా ఉంది! నువ్వు మీ వార్తాపత్రికలో ప్రచురించడానికి ఇది ఉత్తమమైనది. ఒక్క కథ రాస్తే సరిపోకపోవచ్చు. నువ్వు స్థిరంగా ఉండి పరిస్థితిని అనుసరించాలి. అభిప్రాయాల కోసం సరైన వ్యక్తులను కలవాలి.",
              "or": "ମାଧବ, ମୁଁ ପ୍ରବନ୍ଧ ମାଧ୍ୟମରେ ପଢିଛି | ଏହା ଅତ୍ୟନ୍ତ ବୋଲ୍ଡ ଏବଂ\n  ପ୍ରଭାବଶାଳୀ! ଯଦି ତୁମେ ଏହାକୁ ତୁମର ଖବରକାଗଜରେ ପ୍ରକାଶ କର, ଏହା ସର୍ବୋତ୍ତମ ହେବ | କେବଳ ଗୋଟିଏ କାହାଣୀ ଲେଖିବା ଯଥେଷ୍ଟ ହୋଇନପାରେ | ତୁମକୁ ସ୍ଥିର ହେବାକୁ ପଡିବ ଏବଂ ପରିସ୍ଥିତିକୁ ଅନୁସରଣ କରିବାକୁ ପଡିବ | ମତାମତ ପାଇଁ ସଠିକ୍ ଲୋକଙ୍କ ନିକଟରେ ପହଞ୍ଚନ୍ତୁ |",
              "as": "মাধৱ, মই প্ৰবন্ধটো পঢ়িলোঁ। এইটো অতি সাহসী আৰু চিত্তাকৰ্ষক হৈছে! যদি তুমি ইয়াক বাতৰি কাকতত প্ৰকাশ কৰোৱা তেন্তে সেইটো সৰ্বশ্ৰেষ্ঠ হ'ব। মাত্ৰ এটা কাহিনী লিখাটো পৰ্যাপ্ত নহ'বও পাৰে। তুমি নিয়মীয়া হ'ব লাগিব আৰু পৰিস্থিতিটো অনুসৰণ কৰিব লাগিব। মতামতৰ বাবে সঠিক লোকৰ ওচৰলৈ যোৱা।",
              "gu": "માધવ, મેં લેખ વાંચ્યો. તે ખૂબ જ સાહસિક અને પ્રભાવશાળી છે! જો તું તેને તારા છાપામાં પ્રકાશિત કરે તો તે શ્રેષ્ઠ રહેશે. માત્ર એક વાર્તા લખવાથી પૂરતું નથી. તમારે સુસંગત રહેવું પડશે અને પરિસ્થિતિને અનુસરવું પડશે. મંતવ્યો માટે યોગ્ય લોકો સુધી પહોંચ."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_6_1",
              "hi": "C2_6_1",
              "ka": "C2_6_1",
              "te": "C2_6_1",
              "or": "C2_6_1",
              "as": "C2_6_1",
              "gu": "C2_6_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav sends the article to his editor. The editor is happy with the way Madhav has reported the story and the story gets published in the newspaper the next day.",
              "hi": "माधव लेख को अपने संपादक को भेजता है। माधव के द्वारा प्रस्तुत की गई कहानी को देखकर सम्पादक प्रसन्न है और कहानी अगले दिन समाचार पत्र में प्रकाशित होती है।",
              "ka": "ಮಾಧವ್ ತಮ್ಮ ಸಂಪಾದಕರಿಗೆ ಲೇಖನವನ್ನು ಕಳುಹಿಸುತ್ತಾನೆ. ಮಾಧವ್  ಕಥೆಯನ್ನು ವರದಿ ಮಾಡಿರುವ ರೀತಿಗೆ ಸಂಪಾದಕರು ಸಂತೋಷಪಟ್ಟಿದ್ದಾರೆ ಮತ್ತು ಮರುದಿನ ಪತ್ರಿಕೆಯಲ್ಲಿ ಕಥೆ ಪ್ರಕಟವಾಗುತ್ತದೆ.",
              "te": "మాధవ్ తన ఎడిటర్ కి ఆర్టికల్ ని పంపాడు. మాధవ్ కథనాన్ని నివేదించిన తీరుతో ఎడిటర్ సంతోషించాడు మరియు మరుసటి రోజు వార్తాపత్రికలో కథ ప్రచురించబడింది.",
              "or": "ମାଧବ ପ୍ରବନ୍ଧକୁ ତାଙ୍କ ସମ୍ପାଦକଙ୍କ ନିକଟକୁ ପଠାନ୍ତି | ମାଧବ ଯେପରି \n କାହାଣୀ ରିପୋର୍ଟ କରିଛନ୍ତି ଏବଂ ପରଦିନ ଖବରକାଗଜରେ ପ୍ରକାଶିତ ହେବାରେ ସମ୍ପାଦକ ଖୁସି ଅଛନ୍ତି |",
              "as": "মাধৱে প্ৰবন্ধটো তেওঁৰ সম্পাদকলৈ প্ৰেৰণ কৰে। মাধৱে কাহিনীটো যিধৰণে প্ৰতিবেদন কৰিলে তাক লৈ সম্পাদক সুখী হয় আৰু কাহিনীটো পিছদিনা বাতৰি কাকতত প্ৰকাশিত হয়।",
              "gu": "માધવ લેખ તેના તંત્રીને મોકલે છે. માધવે જે રીતે વાર્તાની જાણ કરે છે તેનાથી સંપાદક ખુશ છે અને બીજા દિવસે છાપામાં વાર્તા પ્રકાશિત થાય છે."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_6_2",
              "hi": "C2_6_2",
              "ka": "C2_6_2",
              "te": "C2_6_2",
              "or": "C2_6_2",
              "as": "C2_6_2",
              "gu": "C2_6_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "However, Madhav knows that one article would not change the situation. He is keen to follow up on the developments of the chemical factory, but his editor gives him a lot of desk work to do.",
              "hi": "हालांकि, माधव को पता है कि एक लेख स्थिति को नहीं बदलेगा। वह रासायनिक कारखाने के काम की स्थिति पर जांच करने की इच्छुक है, लेकिन उसके संपादक उसे बहुत सारे डेस्क के काम करने के लिए देते हैं।",
              "ka": "ಆದರೆ, ಒಂದು ಲೇಖನವು ಪರಿಸ್ಥಿತಿಯನ್ನು ಬದಲಾಯಿಸುವುದಿಲ್ಲ ಎಂದು ಮಾಧವ್‌ಗೆ ತಿಳಿದಿದೆ. ರಾಸಾಯನಿಕ ಕಾರ್ಖಾನೆಯ ಬೆಳವಣಿಗೆಗಳನ್ನು ಅನುಸರಿಸಲು ಅವನು ಉತ್ಸುಕರಾಗಿದ್ದಾನೆ, ಆದರೆ ಅವನ ಸಂಪಾದಕರು ಅವನಿಗೆ ಮಾಡಲು ಸಾಕಷ್ಟು ಡೆಸ್ಕ್ ಕೆಲಸವನ್ನು ನೀಡುತ್ತಾರೆ",
              "te": "అయితే, ఒక్క కథనం పరిస్థితిని మార్చదని మాధవ్‌కు తెలుసు. అతను కెమికల్ ఫ్యాక్టరీ యొక్క అభివృద్ధిని అనుసరించాలి అని అనుకున్నాడు, కానీ అతని ఎడిటర్ అతనికి చాలా డెస్క్ వర్క్ ఇస్తాడు.",
              "or": "ତଥାପି, ମାଧବ ଜାଣନ୍ତି ଯେ ଗୋଟିଏ ଲେଖା ପରିସ୍ଥିତିକୁ ପରିବର୍ତ୍ତନ \n କରିବ ନାହିଁ | ସେ ରାସାୟନିକ କାରଖାନାର ବିକାଶ ଉପରେ ଅନୁସନ୍ଧାନ କରିବାକୁ ଆଗ୍ରହୀ, କିନ୍ତୁ ତାଙ୍କ ସମ୍ପାଦକ ତାଙ୍କୁ ଅନେକ ଡେସ୍କ କାମ କରିବାକୁ ଦିଅନ୍ତି |",
              "as": "অৱশ্যে, মাধৱে জানিছিল যে এটা প্ৰবন্ধই পৰিস্থিতিটো সলনি নকৰে। তেওঁ ৰাসায়নিক কাৰখানাৰ প্ৰগতিৰ বিষয়ে অনুসৰণ কৰিবলৈ আগ্ৰহী, কিন্তু তেওঁৰ সম্পাদকে তেওঁক বহুতো ডেস্কৰ কাম কৰিবলৈ দিয়ে।",
              "gu": "જોકે, માધવ જાણે છે કે એક લેખથી પરિસ્થિતિ બદલાશે નહીં. તે કેમિકલ ફેક્ટરીના વિકાસને અનુસરવા માટે આતુર છે, પરંતુ તેના તંત્રી તેને ડેસ્ક માટે ઘણું કામ આપે છે."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_5",
              "hi": "C2_1_5",
              "ka": "C2_1_5",
              "te": "C2_1_5",
              "or": "C2_1_5",
              "as": "C2_1_5",
              "gu": "C2_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav still finds ways to leave the office to go to the field. He covers many other stories related to the chemical factory, including public health, safety and sanitation.",
              "hi": "माधव अभी भी ऑफिस से निकल कर फील्ड में जाने के रास्ते ढूंढता है। वह सार्वजनिक स्वास्थ्य, सुरक्षा और स्वच्छता सहित रासायनिक कारखाने से संबंधित कई अन्य कहानियों को शामिल करता है।",
              "ka": "ಮಾಧವ್ ಆಫೀಸಿನಿಂದ ಕ್ಷೇತ್ರಕ್ಕೆ ಹೋಗಲು ದಾರಿಯನ್ನು ಹುಡುಕುತ್ತಾನೆ. ಸಾರ್ವಜನಿಕ ಆರೋಗ್ಯ, ಸುರಕ್ಷತೆ ಮತ್ತು ನೈರ್ಮಲ್ಯ ಸೇರಿದಂತೆ ರಾಸಾಯನಿಕ ಕಾರ್ಖಾನೆಗೆ ಸಂಬಂಧಿಸಿದ ಅನೇಕ ಇತರೆ ಕಥೆಗಳನ್ನು ಅವನು ಬರೆಯುತ್ತಾನೆ.",
              "te": "మాధవ్ ఇప్పటికీ ఆఫీసు నుండి ఫీల్డ్ కి వెళ్లడానికి మార్గాలు వెతుకుతున్నాడు. అతను ప్రజారోగ్యం, భద్రత మరియు పారిశుద్ధ్యంతో సహా కెమికల్ ఫ్యాక్టరీకి సంబంధించిన అనేక ఇతర కథనాలను కవర్ చేశాడు.",
              "or": "ମାଧବ ତଥାପି ଅଫିସରୁ କ୍ଷେତକୁ ଯିବା ପାଇଁ ଉପାୟ ଖୋଜନ୍ତି | ଜନସ୍ୱାସ୍ଥ୍ୟ,\n  ନିରାପତ୍ତା ଏବଂ ପରିମଳ ପରି ରାସାୟନିକ କାରଖାନା ସହିତ ଜଡିତ ଅନ୍ୟାନ୍ୟ କାହାଣୀକୁ ସେ କଭର୍ କରିଛନ୍ତି |",
              "as": "মাধৱে তেতিয়াও ফিল্ড’লৈ যাবলৈ কাৰ্যালয় এৰি যোৱাৰ উপায় বিচাৰি উলিয়াইছে। তেওঁ ৰাসায়নিক কাৰখানাৰ সৈতে সম্পৰ্কিত আন বহুতো কাহিনী সামৰি লয়, যাৰ ভিতৰত আছে ৰাজহুৱা স্বাস্থ্য, সুৰক্ষা আৰু অনাময়।",
              "gu": "માધવ હજી પણ ઓફિસ છોડીને બહાર કામ કરવા જવાના રસ્તા શોધે છે. તેણે જાહેર આરોગ્ય, સલામતી અને સ્વચ્છતા સહિત રાસાયણિક ફેક્ટરી સંબંધિત અન્ય ઘણી વાર્તાઓ આવરી લીધી છે."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_6",
              "hi": "C2_1_6",
              "ka": "C2_1_6",
              "te": "C2_1_6",
              "or": "C2_1_6",
              "as": "C2_1_6",
              "gu": "C2_1_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He also speaks to experts to find out what they think about the chemical factory. He writes small case studies to analyse the effects of other chemical factories on people living nearby.",
              "hi": "वह यह जानने के लिए विशेषज्ञों से भी बात करता है कि रासायनिक कारखाने के बारे में उनके क्या विचार हैं। वह पास के रहने वाले लोगों पर अन्य रासायनिक कारखानों के प्रभावों का विश्लेषण करने के लिए छोटे केस स्टडीज लिखता है।",
              "ka": "ರಾಸಾಯನಿಕ ಕಾರ್ಖಾನೆಯ ಬಗ್ಗೆ ಅವರು ಏನು ಯೋಚಿಸುತ್ತಾರೆ ಎನ್ನುವುದನ್ನು ತಿಳಿದುಕೊಳ್ಳಲು ಆತನು ತಜ್ಞರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾನೆ. ಸಮೀಪದಲ್ಲಿ ವಾಸಿಸುವ ಜನರ ಮೇಲೆ ಇತರ ರಾಸಾಯನಿಕ ಕಾರ್ಖಾನೆಗಳ ಪರಿಣಾಮಗಳನ್ನು ವಿಶ್ಲೇಷಿಸಲು ಅವನು ಸಣ್ಣ ಪ್ರಕರಣ ಅಧ್ಯಯನಗಳನ್ನು ಬರೆಯುತ್ತಾನೆ.",
              "te": "కెమికల్ ఫ్యాక్టరీ గురించి వారు ఏమనుకుంటున్నారో తెలుసుకోవడానికి అతను నిపుణులతో కూడా మాట్లాడతాడు. అతను ఇతర ప్రదేశాలలో కెమికల్ ఫ్యాక్టరీల వద్ద నివసించే ప్రజలపై దాని ప్రభావాలను విశ్లేషించడానికి చిన్న కేస్ స్టడీస్ వ్రాస్తాడు.",
              "or": "ରାସାୟନିକ କାରଖାନା ବିଷୟରେ ସେମାନେ କ’ଣ ଭାବୁଛନ୍ତି ତାହା ଜାଣିବା \n ପାଇଁ ସେ ବିଶେଷଜ୍ଞମାନଙ୍କ ସହ ମଧ୍ୟ କଥା ହୁଅନ୍ତି | ନିକଟସ୍ଥ ରହୁଥିବା ଲୋକଙ୍କ ଉପରେ ଅନ୍ୟ ରାସାୟନିକ କାରଖାନାର ପ୍ରଭାବ ବିଶ୍ଳେଷଣ କରିବାକୁ ସେ ଛୋଟ କେସ୍ ଷ୍ଟଡିଜ୍ ଲେଖନ୍ତି |",
              "as": "তেওঁ ৰাসায়নিক কাৰখানাটোৰ বিষয়ে বিশেষজ্ঞসকলৰ সৈতেও কথা পাতি তেওঁলোকে কি ভাবে জানিব বিচাৰিছিল। তেওঁ কাৰখানাৰ ওচৰত বসবাস কৰা লোকসকলৰ ওপৰত অন্যান্য ৰাসায়নিক কাৰখানাৰ প্ৰভাৱ বিশ্লেষণ কৰিবলৈ সৰু সৰু কেছ ষ্টাডি লিখিছে।",
              "gu": "તે કેમિકલ ફેક્ટરી વિશે શું વિચારે છે તે જાણવા માટે નિષ્ણાતો સાથે પણ વાત કરે છે. તે નજીકમાં રહેતા લોકો પર અન્ય કેમિકલ ફેક્ટરીઓની અસરોનું વિશ્લેષણ કરવા માટે નાના કેસ સ્ટડીઝ લખે છે."
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_6_3",
              "hi": "C2_6_3",
              "ka": "C2_6_3",
              "te": "C2_6_3",
              "or": "C2_6_3",
              "as": "C2_6_3",
              "gu": "C2_6_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The editor is upset that Madhav has been doing the work given to him, and he doesn't want him to follow up on the story.",
              "hi": "संपादक परेशान है कि माधव उसे दिया गया काम नहीं कर रहा है, और वह नहीं चाहता कि वह कहानी की आगे कोई जांच करे।",
              "ka": "ಮಾಧವ್ ತನಗೆ ಕೊಟ್ಟ ಕೆಲಸವನ್ನು ಮಾಡುತ್ತಿಲ್ಲ ಎಂದು ಸಂಪಾದಕರು ಅಸಮಾಧಾನಗೊಂಡಿದ್ದಾರೆ ಮತ್ತು ಅವರು ಕಥೆಯನ್ನು ಅನುಸರಿಸಲು ಬಯಸುವುದಿಲ್ಲ.",
              "te": "ఎడిటర్ మాధవ్ తనకు ఇచ్చిన పనిని చేస్తున్నాడని బాధపడతాడు మరియు కథను అనుసరించడం అతనికి ఇష్టం లేదు.",
              "or": "ସମ୍ପାଦକ ବିରକ୍ତ ହୁଅନ୍ତି ଯେ ମାଧବ ତାଙ୍କୁ ଦିଆଯାଇଥିବା କାର୍ଯ୍ୟ କରୁଛନ୍ତି\n , ଏବଂ ସେ ଚାହାଁନ୍ତି ନାହିଁ ଯେ ସେ କାହାଣୀକୁ ଅନୁସରଣ କରନ୍ତୁ |",
              "as": "মাধৱে তেওঁক দিয়া কামটো কৰি থকাৰ বাবে সম্পাদকজন বিচলিত হৈছে, আৰু তেওঁ নিবিচাৰে যে তেওঁ কাহিনীটো অনুসৰণ কৰক।",
              "gu": "સંપાદક નારાજ છે કે માધવ તેને આપવામાં આવેલ કામ કરી રહ્યો છે, અને તે નથી ઈચ્છતો કે તે વાર્તાને અનુસરે."
            }
          }
        ],
        "page24": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_6_3 b",
              "hi": "C2_6_3 b",
              "ka": "C2_6_3 b",
              "te": "C2_6_3 బి",
              "or": "C2_6_3 b",
              "as": "C2_6_3 b",
              "gu": "C2_6_3 b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "When Madhav returns to the editor with the newest developments, he seems disinterested.",
              "hi": "जब माधव नवीनतम बदलावों के साथ संपादक को कहानी देता है, तो वह सम्पादक अनिच्छुक लगता है।",
              "ka": "ಮಾಧವ್ ಹೊಸ ಬೆಳವಣಿಗೆಗಳೊಂದಿಗೆ ಸಂಪಾದಕರ ಬಳಿಗೆ ಹಿಂತಿರುಗಿದಾಗ, ಅವರು ನಿರಾಸಕ್ತಿ ತೋರುತ್ತಾರೆ.",
              "te": "కొత్త డెవలప్మెంట్స్ తో మాధవ్ ఎడిటర్ వద్దకు తిరిగి వచ్చినప్పుడు, అతను ఆసక్తి చూపలేదు.",
              "or": "ଯେତେବେଳେ ମାଧବ ନୂତନ ବିକାଶ ସହିତ ସମ୍ପାଦକକୁ ଫେରି ଆସନ୍ତି,\n  ସେତେବେଳେ ସେ ଅସନ୍ତୋଷ ପ୍ରକାଶ କରନ୍ତି |",
              "as": "যেতিয়া মাধৱে নতুন প্ৰগতিৰ সৈতে সম্পাদকৰ ওচৰলৈ ঘূৰি আহে, তেওঁক নিৰুৎসাহী যেন লাগে।",
              "gu": "જ્યારે માધવ નવી નવી ઘટનાઓ સાથે સંપાદક પાસે પાછો ફરે છે, ત્યારે તેને અણગમો લાગે છે."
            }
          }
        ],
        "page21": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_6_4",
              "hi": "C2_6_4",
              "ka": "C2_6_4",
              "te": "C2_6_4",
              "or": "C2_6_4",
              "as": "C2_6_4",
              "gu": "C2_6_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav realises that this newspaper will not allow his article to reach the people. He decides to send the report another news agency that would value it more.",
              "hi": "माधव को पता चलता है कि यह समाचार पत्र उसके लेख को लोगों तक पहुंचने की अनुमति नहीं देगा। वह रिपोर्ट को एक और समाचार एजेंसी में भेजने का निश्चय करता है जो इसे और अधिक महत्व दे।",
              "ka": "ಈ ಪತ್ರಿಕೆಯು ತನ್ನ ಲೇಖನವನ್ನು ಜನರಿಗೆ ತಲುಪಲು ಬಿಡುವುದಿಲ್ಲ ಎಂದು ಮಾಧವ್ ಅರಿತುಕೊಳ್ಳುತ್ತಾನೆ. ಅವನು ವರದಿಯನ್ನು ಹೆಚ್ಚು ಮೌಲ್ಯಯುತವಾದ ಮತ್ತೊಂದು ಸುದ್ದಿ ಸಂಸ್ಥೆಗೆ ಕಳುಹಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "ఈ వార్తాపత్రిక తన ఆర్టికల్ ని ప్రజలకు చేరవేయడానికి అనుమతించదని మాధవ్ గ్రహించాడు. అతని రిపోర్ట్ కు విలువనిచ్చే మరొక వార్తా సంస్థకు పంపాలని నిర్ణయించుకున్నాడు.",
              "or": "ମାଧବ ହୃଦୟଙ୍ଗମ କରିଛନ୍ତି ଯେ ଏହି ଖବରକାଗଜ ତାଙ୍କ ଆର୍ଟିକିଲକୁ\n  ଲୋକଙ୍କ ନିକଟରେ ପହଞ୍ଚିବାକୁ ଦେବ ନାହିଁ। ସେ ରିପୋର୍ଟକୁ ଅନ୍ୟ ଏକ ନ୍ୟୁଜ୍ ଏଜେନ୍ସି ପଠାଇବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି ଯାହା ଏହାର ମୂଲ୍ୟ ଅଧିକ ହେବ |",
              "as": "মাধৱে উপলব্ধি কৰে যে এই বাতৰি কাকতখনে তেওঁৰ প্ৰবন্ধটো মানুহৰ ওচৰলৈ যাব নিদিয়ে। তেওঁ প্ৰতিবেদনখন আন এটা সংবাদ সংস্থালৈ প্ৰেৰণ কৰাৰ সিদ্ধান্ত লয় যিয়ে ইয়াক অধিক মূল্য দিব।",
              "gu": "માધવને ખ્યાલ છે કે આ સમાચાર છાપું તેના લેખને લોકો સુધી પહોંચવા દેશે નહીં. તે અહેવાલને અન્ય સમાચાર એજન્સી મોકલવાનું નક્કી કરે છે જે તેને વધુ મહત્વ આપશે."
            }
          }
        ],
        "page22": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_5_3",
              "hi": "C2_5_3",
              "ka": "C2_5_3",
              "te": "C2_5_3",
              "or": "C2_5_3",
              "as": "C2_5_3",
              "gu": "C2_5_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He sends the article about the chemical factory to a digital news organisation called The Sprint.",
              "hi": "वह स्प्रिंट नामक एक डिजिटल समाचार संगठन को रासायनिक कारखाने के बारे में लेख भेजता है।",
              "ka": "ಅವನು ರಾಸಾಯನಿಕ ಫ್ಯಾಕ್ಟರಿಯ ಬಗ್ಗೆ ಲೇಖನವನ್ನು ದಿ ಸ್ಪ್ರಿಂಟ್ ಎಂಬ ಡಿಜಿಟಲ್ ಸುದ್ದಿ ಸಂಸ್ಥೆಗೆ ಕಳುಹಿಸುತ್ತಾನೆ.",
              "te": "అతను కెమికల్ ఫ్యాక్టరీ గురించిన ఆర్టికల్ ని ది స్ప్రింట్ అనే డిజిటల్ వార్తా సంస్థకు పంపాడు.",
              "or": "ସେ ରାସାୟନିକ କାରଖାନା ବିଷୟରେ ପ୍ରବନ୍ଧକୁ ଦି ସ୍ପ୍ରିଣ୍ଟ ନାମକ ଏକ\n  ଡିଜିଟାଲ୍ ନ୍ୟୁଜ୍ ସଂସ୍ଥାକୁ ପଠାନ୍ତି |",
              "as": "তেওঁ ৰাসায়নিক কাৰখানাৰ বিষয়ে প্ৰবন্ধটো দ্য স্প্ৰিণ্ট নামৰ এটা ডিজিটেল সংবাদ সংস্থালৈ প্ৰেৰণ কৰে।",
              "gu": "તે રાસાયણિક ફેક્ટરી વિશેનો લેખ ધ સ્પ્રિન્ટ નામની ડિજિટલ સમાચાર સંસ્થાને મોકલે છે."
            }
          }
        ],
        "page23": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_5_4",
              "hi": "C2_5_4",
              "ka": "C2_5_4",
              "te": "C2_5_4",
              "or": "C2_5_4",
              "as": "C2_5_4",
              "gu": "C2_5_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "A week later, it gets published in The Sprint. Many news agencies pick up the article, and it gets discussed in the local news. Many journalists start making their way to the proposed site to get more information.",
              "hi": "एक सप्ताह बाद, यह स्प्रिंट में प्रकाशित हो जाता है। कई समाचार एजेंसियां ​​लेख चुनती हैं, और इसकी स्थानीय समाचारों में चर्चा की जाती है। कई पत्रकार अधिक जानकारी प्राप्त करने के लिए प्रस्तावित साइट पर जाना शुरू करते हैं।",
              "ka": "ಒಂದು ವಾರದ ನಂತರ, ಇದು ಸ್ಪ್ರಿಂಟ್‌ನಲ್ಲಿ ಪ್ರಕಟವಾಗುತ್ತದೆ. ಅನೇಕ ಸುದ್ದಿ ಸಂಸ್ಥೆಗಳು ಲೇಖನವನ್ನು ಎತ್ತಿಕೊಳ್ಳುತ್ತವೆ ಮತ್ತು ಸ್ಥಳೀಯ ಸುದ್ದಿಗಳಲ್ಲಿ ಇದನ್ನು ಚರ್ಚಿಸಲಾಗುತ್ತದೆ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಯನ್ನು ಪಡೆಯಲು ಅನೇಕ ಪತ್ರಕರ್ತರು ಉದ್ದೇಶಿತ ಸೈಟ್‌ಗೆ ತಮ್ಮ ಭೇಟಿಯನ್ನು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ.",
              "te": "ఒక వారం తర్వాత, ఇది ది స్ప్రింట్‌లో ప్రచురించబడుతుంది. అనేక వార్తా సంస్థలు కథనాన్ని తీసుకుంటాయి మరియు అది స్థానిక వార్తలలో చర్చించబడుతుంది. చాలా మంది జర్నలిస్టులు మరింత సమాచారాన్ని పొందడానికి ఆ సైట్‌కి వెళ్లడం ప్రారంభిస్తారు.",
              "or": "ଗୋଟିଏ ସପ୍ତାହ ପରେ, ଏହା ଦି ସ୍ପ୍ରିଣ୍ଟରେ ପ୍ରକାଶିତ ହୁଏ | ଅନେକ ନ୍ୟୁଜ୍\n  ଏଜେନ୍ସି ଆର୍ଟିକିଲ୍ ଉଠାନ୍ତି, ଏବଂ ଏହା ସ୍ଥାନୀୟ ସମ୍ବାଦରେ ଆଲୋଚନା ହୁଏ | ଅନେକ ସାମ୍ବାଦିକ ଅଧିକ ସୂଚନା ପାଇବା ପାଇଁ ପ୍ରସ୍ତାବିତ ସାଇଟକୁ ଯିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "এসপ্তাহ পিছত, ই দ্য স্প্ৰিণ্টত প্ৰকাশিত হয়। বহুতো সংবাদ সংস্থাই প্ৰবন্ধটো তুলি লয়, আৰু ইয়াক স্থানীয় বাতৰিত আলোচনা কৰা হয়। বহুতো সাংবাদিকে অধিক তথ্য পাবলৈ প্ৰস্তাৱিত স্থানলৈ যাবলৈ আৰম্ভ কৰে।",
              "gu": "એક અઠવાડિયા પછી, તે ધ સ્પ્રિન્ટમાં પ્રકાશિત થાય છે. ઘણી સમાચાર એજન્સીઓ લેખ પસંદ કરે છે, અને સ્થાનિક સમાચારોમાં તેની ચર્ચા થાય છે. ઘણા પત્રકારો વધુ માહિતી મેળવવા માટે સૂચિત જગ્યા પર જવાની શરૂઆત કરે છે."
            }
          }
        ]
      },
      "scene30": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_1////",
              "hi": "C3_1_1 /////",
              "ka": "C3_1_1////",
              "te": "C3_1_1////",
              "or": "C3_1_1////",
              "as": "C3_1_1////",
              "gu": "C3_1_1////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Madhav! I’m so happy you wrote that article! I read it out loud to everyone here. Ma and Baba are so proud of you for covering it.",
              "hi": "माधव! मैं बहुत खुश हूं कि तुमने उस लेख को लिखा है! मैंने इसे यहां हर किसी के लिए जोर से पढ़ा। मां और बाबा यह स्टोरी कवर करने के लिए तुम पर बहुत गर्व करते हैं।",
              "ka": "ಮಾಧವ್! ನೀನು ಆ ಲೇಖನವನ್ನು ಬರೆದಿದ್ದಕ್ಕೆ ನನಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿದೆ! ನಾನು ಅದನ್ನು ಇಲ್ಲಿ ಎಲ್ಲರಿಗೂ ಜೋರಾಗಿ ಓದಿದೆ. ಅದನ್ನು ಜನರ ಮುಂದೆ ಬಿಚ್ಚಿಟ್ಟಿದ್ದಕ್ಕಾಗಿ ಮಾ ಮತ್ತು ಬಾಬಾ ನಿನ್ನ ಬಗ್ಗೆ ತುಂಬಾ ಹೆಮ್ಮೆಪಡುತ್ತಾರೆ.",
              "te": "మాధవ్! నువ్వు ఆ ఆర్టికల్ రాసినందుకు నేను చాలా సంతోషిస్తున్నాను! నేను ఇక్కడ అందరికీ చదివి వినిపించాను. దానిని కవర్ చేసినందుకు మా మరియు బాబా నీ గురించి చాలా గర్వంగా ఉన్నారు.",
              "or": "ମାଧବ! ତୁମେ ସେହି ଲେଖା ଲେଖିଥିବାରୁ ମୁଁ ବହୁତ ଖୁସି! ମୁଁ ଏହାକୁ ସମସ୍ତଙ୍କ ସାମ୍ନାରେ\n  ଉଚ୍ଚ ସ୍ୱରରେ ପଢିଛି | ଏହାକୁ ଆଚ୍ଛାଦନ କରିଥିବାରୁ ମା ଏବଂ ବାବା ତୁମ ପାଇଁ ବହୁତ ଗର୍ବିତ |",
              "as": "মাধৱ! মই বহুত সুখী যে তুমি সেই প্ৰবন্ধটো লিখিলা! মই ইয়াত সকলোকে ডাঙৰকৈ পঢ়ি শুনাইছিলোঁ। মা আৰু বাবা এইটো কভাৰ কৰা বাবে তোমাক লৈ বহুত গৌৰৱান্বিত।",
              "gu": "માધવ! હું ખૂબ ખુશ છું કે તે જે લેખ લખ્યો! હું તેને અહીં દરેકને મોટેથી વાંચું છું. તેને આવરી લેવા માટે મા અને પપ્પા તમારા પર ગર્વ અનુભવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Thanks, Kuldeep! It’s all thanks to them that I got this story and documented it.",
              "hi": "धन्यवाद, कुलदीप! इस बात के लिए धन्यवाद है कि मुझे यह कहानी मिली और मैंने इसे दस्तावेज किया।",
              "ka": "ಧನ್ಯವಾದಗಳು, ಕುಲದೀಪ್! ನಾನು ಈ ಕಥೆಯ ಬಗ್ಗೆ ಮಾಹಿತಿಯನ್ನು ಪಡೆದಿದ್ದು ಮತ್ತು ಅದನ್ನು ದಾಖಲಿಸಿದ್ದಕ್ಕಾಗಿ ಅವರಿಗೆ ಧನ್ಯವಾದಗಳು.",
              "te": "ధన్యవాదాలు, కుల్దీప్! నేను ఈ కథను డాక్యుమెంట్ చేయడానికి సహాయం చేసిన వారికి ధన్యవాదాలు.",
              "or": "ଧନ୍ୟବାଦ, କୁଲଦୀପ! ଏହା ସମସ୍ତଙ୍କୁ ଧନ୍ୟବାଦ ଯେ ମୁଁ ଏହି କାହାଣୀ \n ପାଇଛି ଏବଂ ଏହାକୁ ଡକ୍ୟୁମେଣ୍ଟ୍ କରିଛି |",
              "as": "ধন্যবাদ, কুলদীপ! তেওঁলোককো ধন্যবাদ যে মই এই কাহিনীটো পালোঁ আৰু ইয়াক নথিভুক্ত কৰিলোঁ।",
              "gu": "આભાર, કુલદીપ! તે બધાનો આભાર છે કે મને આ વાર્તા મળી અને તેનું દસ્તાવેજીકરણ કર્યું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Everyone loved it. You have fans in our village now, be ready to meet them the next time you are here.",
              "hi": "हर किसी ने इसको पसंद किया। अब हमारे गांव में भी प्रशंसक हैं, अगली बार जब आप यहां आएं तो उनसे मिलने के लिए तैयार रहें।",
              "ka": "ಎಲ್ಲರಿಗೂ ಇಷ್ಟವಾಯಿತು. ನಮ್ಮ ಗ್ರಾಮದಲ್ಲಿ ಈಗ ನಿನಗೆ ಅನೇಕ ಅಭಿಮಾನಿಗಳಿದ್ದಾರೆ, ಮುಂದಿನ ಬಾರಿ ನೀನು ಇಲ್ಲಿಗೆ ಬಂದಾಗ ಅವರನ್ನು ಭೇಟಿ ಮಾಡಲು ಸಿದ್ಧನಾಗಿರು.",
              "te": "అందరికీ అది నచ్చింది. మా గ్రామంలో ఇప్పుడు నీకు అభిమానులు ఉన్నారు, నువ్వు వచ్చేసారి ఇక్కడకు వచ్చినప్పుడు వారిని కలవడానికి సిద్ధంగా ఉండు.",
              "or": "ସମସ୍ତେ ଏହାକୁ ଭଲ ପାଉଥିଲେ | ଆମ ଗାଁରେ ତୁମର ପ୍ରଶଂସକ ଅଛନ୍ତି,\n  ପରବର୍ତ୍ତୀ ସମୟରେ ତୁମେ ଏଠାକୁ ଆସିବା ପରେ ସେମାନଙ୍କୁ ଭେଟିବାକୁ ପ୍ରସ୍ତୁତ ରୁହ |",
              "as": "সকলোৱে ইয়াক ভাল পাইছিল। এতিয়া আমাৰ গাঁৱত তোমাৰ অনুৰাগী আছে, পৰৱৰ্তী বাৰ তুমি ইয়াত থাকিলে তেওঁলোকক লগ কৰিবলৈ সাজু হ’বা।",
              "gu": "દરેકને તે ગમ્યું. અમારા ગામમાં હવે તમારા ચાહકો છે, આગલી વખતે તું અહીં હોવ ત્યારે તેમને મળવા માટે તૈયાર રહેજે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_2_1",
              "hi": "C3_2_1",
              "ka": "C3_2_1",
              "te": "C3_2_1",
              "or": "C3_2_1",
              "as": "C3_2_1",
              "gu": "C3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The response to his article gives Madhav the courage to change his job and apply to other digital news media companies that work on ground reporting.",
              "hi": "अपने लेख की प्रतिक्रिया माधव को अपनी नौकरी बदलने का साहस देती है और वह एक जमीनी रिपोर्टिंग का  काम करने वाली अन्य डिजिटल समाचार मीडिया कंपनियों में आवेदन करता है।",
              "ka": "ಅವನ ಲೇಖನದ ಬಗ್ಗೆ ಸಿಕ್ಕಿದ ಪ್ರತಿಕ್ರಿಯೆಯು ಮಾಧವ್‌ಗೆ ತನ್ನ ಕೆಲಸವನ್ನು ಬದಲಾಯಿಸಲು ಮತ್ತು ಗ್ರೌಂಡ್ ರಿಪೋರ್ಟಿಂಗ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಇತರ ಡಿಜಿಟಲ್ ಸುದ್ದಿ ಮಾಧ್ಯಮ ಕಂಪನಿಗಳಿಗೆ ಅರ್ಜಿ ಹಾಕಲು ಧೈರ್ಯವನ್ನು ನೀಡುತ್ತದೆ.",
              "te": "అతని ఆర్టికల్ కి వచ్చిన స్పందన మాధవ్‌కు తన ఉద్యోగాన్ని మార్చుకోవడానికి మరియు గ్రౌండ్ రిపోర్టింగ్‌పై పనిచేసే ఇతర డిజిటల్ న్యూస్ మీడియా కంపెనీలకు అప్లై చేసుకునే ధైర్యాన్ని ఇచ్చింది.",
              "or": "ତାଙ୍କ ଆର୍ଟିକିଲର ପ୍ରତିକ୍ରିୟା ମାଧବଙ୍କୁ ଚାକିରି ବଦଳାଇବାକୁ ଏବଂ ଗ୍ରାଉଣ୍ଡ\n  ରିପୋର୍ଟିଂରେ କାର୍ଯ୍ୟ କରୁଥିବା ଅନ୍ୟ ଡିଜିଟାଲ୍ ନ୍ୟୁଜ୍ ମିଡିଆ କମ୍ପାନୀଗୁଡିକରେ ପ୍ରୟୋଗ କରିବାକୁ ସାହସ ଦେଇଥାଏ |",
              "as": "তেওঁৰ প্ৰবন্ধটোৰ সঁহাৰিটোৱে মাধৱক তেওঁৰ চাকৰি সলনি কৰিবলৈ আৰু গ্ৰাউণ্ড ৰিপ'ৰ্টিঙত কাম কৰা আন ডিজিটেল সংবাদ মাধ্যম কোম্পানীবোৰত আবেদন কৰিবলৈ সাহস দিয়ে।",
              "gu": "તેમના લેખનો પ્રતિસાદ માધવને તેમની નોકરી બદલવા અને ગ્રાઉન્ડ રિપોર્ટિંગ પર કામ કરતી અન્ય ડિજિટલ ન્યૂઝ મીડિયા કંપનીઓને અરજી કરવાની હિંમત આપે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_2_2",
              "hi": "C3_2_2",
              "ka": "C3_2_2",
              "te": "C3_2_2",
              "or": "C3_2_2",
              "as": "C3_2_2",
              "gu": "C3_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He applies to a few news organisations and gets selected for a digital news organisation called RNA. They are famous for fearless journalism and publishing compelling stories about social issues.",
              "hi": "वह कुछ समाचार संगठनों में आवेदन करता है और आरएनए नामक एक डिजिटल समाचार संगठन के लिए चुना जाता है। वे निडर पत्रकारिता और सामाजिक मुद्दों के बारे में आकर्षक कहानियों को प्रकाशित करने के लिए प्रसिद्ध हैं।",
              "ka": "ಅವನು ಕೆಲವು ಸುದ್ದಿ ಸಂಸ್ಥೆಗಳಿಗೆ ಅರ್ಜಿ ಹಾಕುತ್ತಾನೆ ಮತ್ತು ಆರ್ ಎನ್ ಎ ಎಂಬ ಡಿಜಿಟಲ್ ಸುದ್ದಿ ಸಂಸ್ಥೆಗೆ ಆಯ್ಕೆಯಾಗುತ್ತಾನೆ. ಅವನು ನಿರ್ಭೀತ ಪತ್ರಿಕೋದ್ಯಮಕ್ಕೆ ಮತ್ತು ಸಾಮಾಜಿಕ ಸಮಸ್ಯೆಗಳ ಬಗ್ಗೆ ಬಲವಾದ ಕಥೆಗಳನ್ನು ಪ್ರಕಟಿಸಲು ಪ್ರಸಿದ್ಧನಾಗಿದ್ದಾನೆ.",
              "te": "అతను కొన్ని వార్తా సంస్థలకు అప్లై చేస్తాడు మరియు RNA అనే ​​డిజిటల్ వార్తా సంస్థకు ఎంపికవుతాడు. వారు భయపడకుండా సామాజిక సమస్యల గురించి సమగ్ర కథనాలను ప్రచురించడంలో ప్రసిద్ధి చెందారు.",
              "or": "ସେ କିଛି ସମ୍ବାଦ ସଂଗଠନ ପାଇଁ ଆବେଦନ କରନ୍ତି ଏବଂ RNA ନାମକ\n  ଏକ ଡିଜିଟାଲ୍ ନ୍ୟୁଜ୍ ସଂସ୍ଥା ପାଇଁ ମନୋନୀତ ହୁଅନ୍ତି | ସେମାନେ ନିର୍ଭୀକ ସାମ୍ବାଦିକତା ଏବଂ ସାମାଜିକ ପ୍ରସଙ୍ଗଗୁଡ଼ିକ ବିଷୟରେ ଜବରଦସ୍ତ କାହାଣୀ ପ୍ରକାଶନ ପାଇଁ ପ୍ରସିଦ୍ଧ |",
              "as": "তেওঁ কেইটামান সংবাদ সংস্থাৰ বাবে আবেদন কৰে আৰু RNA নামৰ ডিজিটেল সংবাদ সংস্থাৰ বাবে নিৰ্বাচিত হয়। তেওঁলোক নিৰ্ভীক সাংবাদিকতা আৰু সামাজিক সমস্যাৰ বিষয়ে আকৰ্ষণীয় কাহিনী প্ৰকাশ কৰাৰ বাবে বিখ্যাত।",
              "gu": "તે કેટલીક સમાચાર સંસ્થાઓને લાગુ પડે છે અને RNA નામની ડિજિટલ સમાચાર સંસ્થા માટે પસંદગી પામે છે. તેઓ નીડર પત્રકારત્વ અને સામાજિક મુદ્દાઓ વિશે આકર્ષક વાર્તાઓ પ્રકાશિત કરવા માટે પ્રખ્યાત છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_3_1",
              "hi": "C3_3_1",
              "ka": "C3_3_1",
              "te": "C3_3_1",
              "or": "C3_3_1",
              "as": "C3_3_1",
              "gu": "C3_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "His job involves writing about on-ground reality. Considering his previous experience, they put him in charge of the development beat. He reports about the latest policies in industrial development.",
              "hi": "उसके काम में जमीनी असलियत के बारे में लिखना शामिल है। उसके पिछले अनुभव को ध्यान में रखते हुए उन्होंने उसे  डेवलपमेंट बीट का प्रभारी बनाया। वह औद्योगिक विकास में नवीनतम नीतियों के बारे में रिपोर्ट करता है।",
              "ka": "ಅವನ ಕೆಲಸವು ಆನ್-ಗ್ರೌಂಡ್ ರಿಯಾಲಿಟಿ ಬಗ್ಗೆ ಬರೆಯುವುದನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ. ಅವನ ಹಿಂದಿನ ಅನುಭವವನ್ನು ಪರಿಗಣಿಸಿ, ಅವರು ಅಭಿವೃದ್ಧಿ ಬೀಟ್‌ನ ಉಸ್ತುವಾರಿ ವಹಿಸಿದ್ದಾರೆ. ಅವನು ಕೈಗಾರಿಕಾ ಅಭಿವೃದ್ಧಿಯಲ್ಲಿ ಇತ್ತೀಚಿನ ನೀತಿಗಳ ಬಗ್ಗೆ ವರದಿ ಮಾಡುತ್ತಾನೆ.",
              "te": "అతని ఉద్యోగం ఆన్-గ్రౌండ్ రియాలిటీ గురించి రాయడం. అతని గత అనుభవాన్ని దృష్టిలో ఉంచుకుని అతన్ని అభివృద్ధి బీట్‌ ఇంచార్జ్ గా బాధ్యతలు ఇచ్చారు. పారిశ్రామిక అభివృద్ధిలో తాజా విధానాల గురించి అతను రిపోర్ట్స్ రాస్తాడు.",
              "or": "ତାଙ୍କର ଚାକିରି ଅନ୍-ଗ୍ରାଉଣ୍ଡ୍ ବାସ୍ତବତା ବିଷୟରେ ଲେଖିବା ଅନ୍ତର୍ଭୁକ୍ତ |\n  ତାଙ୍କର ପୂର୍ବ ଅଭିଜ୍ଞତାକୁ ବିଚାରକୁ ନେଇ ସେମାନେ ତାଙ୍କୁ ବିକାଶର ଦାୟିତ୍ .ରେ ରଖିଲେ | ଶିଳ୍ପ ବିକାଶର ଅତ୍ୟାଧୁନିକ ନୀତି ବିଷୟରେ ସେ ରିପୋର୍ଟ କରିଛନ୍ତି।",
              "as": "তেওঁৰ কামত সত্যনিষ্ঠ বাস্তৱতাৰ বিষয়ে লিখা অন্তৰ্ভুক্ত আছে। তেওঁৰ পূৰ্বৰ অভিজ্ঞতাৰ কথা বিবেচনা কৰি, তেওঁলোকে তেওঁক বিকাশৰ বীটৰ দায়িত্ব দিয়ে। তেওঁ ঔদ্যোগিক বিকাশৰ শেহতীয়া নীতিৰ বিষয়ে প্ৰতিবেদন দিয়ে।",
              "gu": "તેમના કામમાં જમીની વાસ્તવિકતા વિશે લખવાનું સામેલ છે. તેના અગાઉના અનુભવને ધ્યાનમાં રાખીને, તેઓએ તેને વિકાસ બીટનો હવાલો સોંપ્યો. તે ઔદ્યોગિક વિકાસમાં નવીનતમ નીતિઓ વિશે અહેવાલ આપે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_3_2",
              "hi": "C3_3_2",
              "ka": "C3_3_2",
              "te": "C3_3_2",
              "or": "C3_3_2",
              "as": "C3_3_2",
              "gu": "C3_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As part of the job, Madhav gets to travel a lot. He and two of his colleagues travel to various sites of industrial projects and cover stories of people’s expectations and experiences.",
              "hi": "नौकरी के दायित्व में, माधव को बहुत यात्रा करनी पड़ती है। वह और  उसके दो सहयोगी औद्योगिक परियोजनाओं की विभिन्न साइटों की यात्रा करते हैं और लोगों की अपेक्षाओं और अनुभवों की कहानियों को संकलित करते हैं।",
              "ka": "ಕೆಲಸದ ಭಾಗವಾಗಿ, ಮಾಧವ್ ಸಾಕಷ್ಟು ಪ್ರಯಾಣಿಸುತ್ತಾನೆ. ಅವನು ಮತ್ತು ಅವನ ಇಬ್ಬರು ಸಹೋದ್ಯೋಗಿಗಳು ಕೈಗಾರಿಕಾ ಯೋಜನೆಗಳ ವಿವಿಧ ಸೈಟ್‌ಗಳಿಗೆ ಪ್ರಯಾಣಿಸುತ್ತಾರೆ ಮತ್ತು ಜನರ ನಿರೀಕ್ಷೆಗಳು ಮತ್ತು ಅನುಭವಗಳ ಕಥೆಗಳನ್ನು ಕವರ್ ಮಾಡುತ್ತಾರೆ.",
              "te": "ఉద్యోగంలో భాగంగా మాధవ్ చాలా ట్రావెల్ చేయాల్సి ఉంటుంది. అతను మరియు అతని ఇద్దరు సహచరులు ఇండస్ట్రియల్ ప్రాజెక్టుల యొక్క వివిధ సైట్‌లకు ప్రయాణిస్తూ ప్రజల అంచనాలు మరియు అనుభవాలను కవర్ చేస్తారు.",
              "or": "ଚାକିରିର ଏକ ଅଂଶ ଭାବରେ, ମାଧବ ବହୁତ ଭ୍ରମଣ କରିବାକୁ ପାଆନ୍ତି |\n  ସେ ଏବଂ ତାଙ୍କର ଦୁଇ ସହକର୍ମୀ ଶିଳ୍ପ ପ୍ରକଳ୍ପର ବିଭିନ୍ନ ସାଇଟକୁ ଯାତ୍ରା କରନ୍ତି ଏବଂ ଲୋକଙ୍କ ଆଶା ଏବଂ ଅଭିଜ୍ଞତାର କାହାଣୀ କଭର୍ କରନ୍ତି |",
              "as": "চাকৰিৰ অংশ হিচাপে, মাধৱে যথেষ্ট ভ্ৰমণ কৰিবলগীয়া হয়। তেওঁ আৰু তেওঁৰ দুজন সহকৰ্মীয়ে ঔদ্যোগিক প্ৰকল্পৰ বিভিন্ন স্থানলৈ ভ্ৰমণ কৰে আৰু মানুহৰ প্ৰত্যাশা আৰু অভিজ্ঞতাৰ কাহিনী সামৰি লয়।",
              "gu": "નોકરીના ભાગરૂપે માધવને ઘણી મુસાફરી કરવી પડે છે. તે અને તેના બે સાથીદારો ઔદ્યોગિક પ્રોજેક્ટ્સની વિવિધ જગ્યાની મુસાફરી કરે છે અને લોકોની અપેક્ષાઓ અને અનુભવોની વાર્તાઓ આવરી લે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_3_3",
              "hi": "C3_3_3",
              "ka": "C3_3_3",
              "te": "C3_3_3",
              "or": "C3_3_3",
              "as": "C3_3_3",
              "gu": "C3_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They also meet and interview different politicians and bureaucrats, including IAS officers.",
              "hi": "वे आईएएस अधिकारियों सहित विभिन्न राजनेताओं और नौकरशाहों से भी मिलते हैं और साक्षात्कार करते हैं।",
              "ka": "ಅವರು ಐಎಎಸ್ ಅಧಿಕಾರಿಗಳು ಸೇರಿದಂತೆ ವಿವಿಧ ರಾಜಕಾರಣಿಗಳು ಮತ್ತು ಅಧಿಕಾರಿಗಳನ್ನು ಭೇಟಿ ಮಾಡುತ್ತಾರೆ ಮತ್ತು ಸಂದರ್ಶನ ಮಾಡುತ್ತಾರೆ.",
              "te": "వారు IAS అధికారులతో సహా వివిధ రాజకీయ నాయకులు మరియు బ్యూరోక్రాట్‌లను కూడా కలిసి ఇంటర్వ్యూ చేస్తారు.",
              "or": "ସେମାନେ ଆଇଏଏସ୍ ଅଧିକାରୀଙ୍କ ସମେତ ବିଭିନ୍ନ ରାଜନେତା ଏବଂ\n  ଅମଲାତନ୍ତ୍ରଙ୍କୁ ଭେଟି ସାକ୍ଷାତକାର ମଧ୍ୟ କରନ୍ତି |",
              "as": "তেওঁলোকে IAS বিষয়াসহ বিভিন্ন ৰাজনীতিবিদ আৰু আমোলাসকলক লগ কৰে আৰু সাক্ষাৎকাৰ লয়।",
              "gu": "તેઓ IAS અધિકારીઓ સહિત વિવિધ રાજકારણીઓ અને અમલદારોને પણ મળે છે અને ઇન્ટરવ્યુ લે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_4_1",
              "hi": "C3_4_1",
              "ka": "C3_4_1",
              "te": "C3_4_1",
              "or": "C3_4_1",
              "as": "C3_4_1",
              "gu": "C3_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Badri and Kuldeep warn him about the risk of reporting such kind of stories.",
              "hi": "बद्री और कुलदीप ने उसे इस तरह की कहानियों की रिपोर्ट करने के जोखिम के बारे में चेतावनी दी।",
              "ka": "ಅಂತಹ ಕಥೆಗಳನ್ನು ವರದಿ ಮಾಡುವ ಅಪಾಯದ ಬಗ್ಗೆ ಬದ್ರಿ ಮತ್ತು ಕುಲದೀಪ್ ಅವನನ್ನು ಎಚ್ಚರಿಸಿದ್ದಾರೆ.",
              "te": "బద్రి మరియు కుల్దీప్ అలాంటి కథనాలను రిపోర్ట్ చేయడం వల్ల కలిగే ప్రమాదం గురించి అతన్ని హెచ్చరిస్తారు.",
              "or": "ବଦ୍ରି ଏବଂ କୁଲଦୀପ ତାଙ୍କୁ ଏଭଳି କାହାଣୀ ରିପୋର୍ଟ କରିବାର ବିପଦ\n  ବିଷୟରେ ଚେତାବନୀ ଦେଇଛନ୍ତି |",
              "as": "বদ্ৰী আৰু কুলদীপে তেওঁক এনে ধৰণৰ কাহিনী প্ৰতিবেদন কৰাৰ বিপদৰ বিষয়ে সতৰ্ক কৰি দিয়ে।",
              "gu": "બદ્રી અને કુલદીપ તેને આ પ્રકારની વાર્તાઓના રિપોર્ટિંગના જોખમ વિશે ચેતવણી આપે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_4_2",
              "hi": "C3_4_2",
              "ka": "C3_4_2",
              "te": "C3_4_2",
              "or": "C3_4_2",
              "as": "C3_4_2",
              "gu": "C3_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav understands their concerns but with his team backing him up, he feels he can write unbiased reports from the ground that reaches the public.",
              "hi": "माधव उनकी चिंता को समझता  है लेकिन अपनी टीम के समर्थन के साथ, उसे लगता है कि वह जनता तक पहुंच कर जमीन से निष्पक्ष रिपोर्ट लिख सकता है।",
              "ka": "ಮಾಧವ್ ಅವರ ಕಾಳಜಿಯನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾನೆ ಆದರೆ ಅವನ ತಂಡವು ಅವನನ್ನು ಬೆಂಬಲಿಸುತ್ತಿರುವುದರಿಂದ, ತಾನು ಸಾರ್ವಜನಿಕರನ್ನು ತಲುಪುವ ಸ್ತಹಲದಲ್ಲಿ ನಡೆಯುವ ಪಕ್ಷಪಾತವಿಲ್ಲದ ವರದಿಗಳನ್ನು ಬರೆಯಬಹುದು ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్ వారి ఆందోళనలను అర్థం చేసుకున్నాడు కానీ అతని బృందం అతనికి మద్దతు ఇవ్వడంతో, అతను ప్రజలకు చేరే విధంగా నిష్పాక్షికమైన రిపోర్ట్లు వ్రాయగలనని అతను భావిస్తున్నాడు.",
              "or": "ମାଧବ ସେମାନଙ୍କ ଚିନ୍ତାଧାରାକୁ ବୁଝ\n ନ୍ତି କିନ୍ତୁ ତାଙ୍କ ଦଳ ତାଙ୍କୁ ସମର୍ଥନ କରି ସେ ଅନୁଭବ କରନ୍ତି ଯେ ସେ ଭୂମିରୁ ନିରପେକ୍ଷ ରିପୋର୍ଟ ଲେଖିପାରିବେ ଯାହା ଜନସାଧାରଣଙ୍କ ନିକଟରେ ପହଞ୍ଚିବ |",
              "as": "মাধৱে তেওঁলোকৰ উদ্বেগবোৰ বুজি পায় কিন্তু তেওঁৰ দলটোৰ সমৰ্থনৰ সৈতে, তেওঁ অনুভৱ কৰে যে তেওঁ মাটিৰ পৰা পক্ষপাতহীন প্ৰতিবেদন লিখিব পাৰে যি জনসাধাৰণৰ ওচৰলৈ যায়।",
              "gu": "માધવ તેમની ચિંતાઓ સમજે છે પરંતુ તેમની ટીમ તેમને સમર્થન આપે છે, તેમને લાગે છે કે તે લોકો સુધી પહોંચતા જમીન પરથી નિષ્પક્ષ અહેવાલો લખી શકે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "Journalist",
              "hi": "पत्रकार",
              "ka": "ಪತ್ರಕರ್ತ ",
              "te": "జర్నలిస్ట్",
              "or": "ସାମ୍ବାଦିକ",
              "as": "সাংবাদিক",
              "gu": "પત્રકાર"
            }
          }
        ]
      },
      "scene40": {
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "IRS Probationer",
              "hi": "आईआरएस प्रोबेशनर",
              "ka": "IRS ಪ್ರೊಬೇಷನರ್",
              "te": "IRS ప్రొబేషనర్",
              "or": "IRS ପ୍ରୋବେସନର୍ |",
              "as": "IRS প্ৰবেচনাৰ",
              "gu": "IRS પ્રોબેશનર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Interviewer",
              "hi": "साक्षात्कारकर्ता",
              "ka": "ಸಂದರ್ಶನಕಾರ",
              "te": "ఇంటర్వ్యూయర్",
              "or": "ସାକ୍ଷାତକାର",
              "as": "সাক্ষাৎকাৰ লওতা",
              "gu": "ઇન્ટરવ્યુઅર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Thank you for your time Madhav. That is all we wanted to ask. You may leave now.",
              "hi": "आपके समय के लिए धन्यवाद माधव। हम सब बस इतना ही पूछना चाहते थे। अब आप जा सकते हैं।",
              "ka": "ನಿಮ್ಮ ಸಮಯಕ್ಕೆ ಧನ್ಯವಾದಗಳು ಮಾಧವ್. ನಾವು ಕೇಳಲು ಬಯಸಿದ್ದು ಇಷ್ಟೇ. ನೀವು ಇನ್ನು ಹೊರಡಬಹುದು.",
              "te": "మీ సమయానికి ధన్యవాదాలు మాధవ్. మేం అడగాలనుకున్నది అది ఒక్కటే. మీరు ఇప్పుడు వెళ్లవచ్చు.",
              "or": "ଆପଣଙ୍କ ସମୟ ମାଧବ ପାଇଁ ଧନ୍ୟବାଦ | ତାହା ହିଁ ଆମେ ପଚାରିବାକୁ \n ଚାହୁଁଥିଲୁ | ଆପଣ ବର୍ତ୍ତମାନ ଛାଡିପାରନ୍ତି |",
              "as": "তোমাৰ সময়ৰ বাবে ধন্যবাদ মাধৱ। আমি এইখিনিয়ে সুধিব বিচাৰিছিলোঁ। তুমি এতিয়া যাব পাৰা।",
              "gu": "તમારા સમય બદલ આભાર માધવ. આટલું જ અમે પૂછવા માંગતા હતા. તું હવે છોડી શકે છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "Interview",
              "hi": "साक्षात्कार",
              "ka": "ಸಂದರ್ಶನ",
              "te": "ఇంటర్వ్యూ",
              "or": "ସାକ୍ଷାତକାର |",
              "as": "সাক্ষাৎকাৰ",
              "gu": "ઈન્ટરવ્યુ"
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "ಎಮ್‌ಸಿ‌ಕ್ಯೂ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Madhav has to take an interview as the last round of the UPSC selection process. Which of the following statements is TRUE about an interview?",
              "hi": "माधव को यूपीएससी चयन प्रक्रिया के अंतिम दौर के रूप में एक साक्षात्कार लेना है। एक साक्षात्कार के बारे में निम्नलिखित में से कौन सा कथन सत्य है?",
              "ka": "ಮಾಧವ್ ತನ್ನ UPSC ಆಯ್ಕೆ ಪ್ರಕ್ರಿಯೆಯ ಕೊನೆಯ ಸುತ್ತಿನ ಸಂದರ್ಶನವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕಾಗಿದೆ. ಸಂದರ್ಶನದ ಕುರಿತು ಈ ಕೆಳಗಿನ ಯಾವ ಹೇಳಿಕೆಗಳು ನಿಜವಾಗಿದೆ?",
              "te": "UPSC సెలక్షన్ ప్రాసెస్‌లో చివరి రౌండ్‌గా మాధవ్ ఇంటర్వ్యూ తీసుకోవాలి. ఇంటర్వ్యూ గురించి కింది స్టేట్‌మెంట్‌లలో ఏది నిజం?",
              "or": "UPSC ଚୟନ ପ୍ରକ୍ରିୟାର ଶେଷ ରାଉଣ୍ଡ ଭାବରେ ମାଧବଙ୍କୁ ଏକ ସାକ୍ଷାତକାର\n  ନେବାକୁ ପଡିବ | ଏକ ସାକ୍ଷାତକାର ବିଷୟରେ ନିମ୍ନଲିଖିତ ମଧ୍ୟରୁ କେଉଁଟି ସତ୍ୟ ଅଟେ?",
              "as": "মাধৱে UPSC বাছনি প্ৰক্ৰিয়াৰ অন্তিম পৰ্যায় হিচাপে এটা সাক্ষাৎকাৰ ল'ব লাগিব। সাক্ষাৎকাৰ এটাৰ বিষয়ে নিম্নলিখিত কোনটো মন্তব্য সঁচা?",
              "gu": "માધવને UPSC પસંદગી પ્રક્રિયાના છેલ્લા રાઉન્ડ તરીકે ઇન્ટરવ્યુ લેવાનો છે. ઇન્ટરવ્યુ વિશે નીચેનામાંથી કયું વિધાન સાચું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "In an interview, present yourself as the best candidate even if you need to lie.",
              "hi": "एक साक्षात्कार में, खुद को सर्वश्रेष्ठ उम्मीदवार के रूप में प्रस्तुत करना, यदि आपको झूठ बोलने की ज़रूरत पड़े तो भी ।",
              "ka": "ಸಂದರ್ಶನದಲ್ಲಿ, ನೀವು ಸುಳ್ಳು ಹೇಳಬೇಕಾದರೂ ಸಹ ನಿಮ್ಮನ್ನು ಅತ್ಯುತ್ತಮ ಅಭ್ಯರ್ಥಿ ಎಂದು ಪ್ರಸ್ತುತಪಡಿಸಿಕೊಳ್ಳಿ",
              "te": "ఒక ఇంటర్వ్యూలో, మీరు అబద్ధం చెప్పినా కూడా, మిమ్మల్ని మీరు ఉత్తమ అభ్యర్థిగా చూపించాలి.",
              "or": "ଏକ ସାକ୍ଷାତକାରରେ, ଯଦି ଆପଣ ରହିବା ଆବଶ୍ୟକ କରନ୍ତି ତେବେ \n ନିଜକୁ ସର୍ବୋତ୍ତମ ପ୍ରାର୍ଥୀ ଭାବରେ ଉପସ୍ଥାପନ କରନ୍ତୁ |",
              "as": "সাক্ষাৎকাৰ এটাত, তুমি মিছা কোৱাৰ প্ৰয়োজন হ'লেও নিজকে সৰ্বশ্ৰেষ্ঠ প্ৰাৰ্থী হিচাপে উপস্থাপন কৰা।",
              "gu": "ઇન્ટરવ્યુમાં, જો તમારે જૂઠું બોલવાની જરૂર હોય તો પણ તમારી જાતને શ્રેષ્ઠ ઉમેદવાર તરીકે રજૂ કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "After you get your first job you will never need to do another interview.",
              "hi": "अपनी पहली नौकरी मिलने के बाद आपको कभी भी दूसरा साक्षात्कार करने की आवश्यकता नहीं होगी।",
              "ka": "ನಿಮ್ಮ ಮೊದಲ ಕೆಲಸವನ್ನು ನೀವು ಪಡೆದ ನಂತರ ನೀವು ಇನ್ನೊಂದು ಸಂದರ್ಶನವನ್ನು ಮಾಡಬೇಕಾಗಿಲ್ಲ.",
              "te": "మీరు మీ మొదటి ఉద్యోగం పొందిన తర్వాత మీరు మరొక ఇంటర్వ్యూ చేయవలసిన అవసరం లేదు.",
              "or": "ତୁମେ ତୁମର ପ୍ରଥମ ଚାକିରି ପାଇବା ପରେ ତୁମକୁ ଆଉ ଏକ ସାକ୍ଷାତକାର\n  କରିବା ଆବଶ୍ୟକ ନାହିଁ |",
              "as": "তুমি তোমাৰ প্ৰথম চাকৰি পোৱাৰ পিছত কেতিয়াও আন এটা সাক্ষাৎকাৰ দিয়াৰ প্ৰয়োজন নহ'ব।",
              "gu": "તારે તારી પ્રથમ નોકરી મેળવ્યા પછી તારે ક્યારેય બીજુ ઇન્ટરવ્યુ આપવાની જરૂર રહેશે નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "An interview is an informal conversation between your future supervisor and you.",
              "hi": "एक साक्षात्कार आपके भविष्य के सुपरवाइजर/ पर्यवेक्षक और आपके बीच एक अनौपचारिक बातचीत है।",
              "ka": "ಸಂದರ್ಶನವು ನಿಮ್ಮ ಭವಿಷ್ಯದ ಮೇಲ್ವಿಚಾರಕರು ಮತ್ತು ನಿಮ್ಮ ನಡುವಿನ ಅನೌಪಚಾರಿಕ ಸಂಭಾಷಣೆಯಾಗಿದೆ.",
              "te": "ఇంటర్వ్యూ అనేది మీ భవిష్యత్ సూపర్‌వైజర్ మరియు మీకు మధ్య జరిగే అనధికారిక సంభాషణ.",
              "or": "ଏକ ସାକ୍ଷାତକାର ହେଉଛି ତୁମର ଭବିଷ୍ୟତର ସୁପରଭାଇଜର ଏବଂ\n  ତୁମ ମଧ୍ୟରେ ଏକ ଅନ al ପଚାରିକ ବାର୍ତ୍ତାଳାପ |",
              "as": "সাক্ষাৎকাৰ হৈছে তোমাৰ ভৱিষ্যত চুপাৰভাইজাৰ আৰু তোমাৰ মাজত হোৱা এক অনানুষ্ঠানিক কথোপকথন।",
              "gu": "ઇન્ટરવ્યુ એ તમારા ભાવિ સુપરવાઇઝર અને તમારી વચ્ચેની અનૌપચારિક વાતચીત છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "It is a formal meeting for both, the interviewer & interviewee to check if the job role  and the candidate is a good fit.",
              "hi": "यह औपचारिक बैठक है जिसमें साक्षात्कारकर्ता और साक्षात्कारदाता, दोनों को यह जांचने का अवसर मिलता है कि क्या नौकरी की भूमिका और उम्मीदवार सर्वथा उपयुक्त है।",
              "ka": "ಸಂದರ್ಶನಕಾರರು ಮತ್ತು ಸಂದರ್ಶಕರು ಇಬ್ಬರಿಗೂ ಇದು ಔಪಚಾರಿಕ ಸಭೆಯಾಗಿದ್ದು, ಉದ್ಯೋಗದ ಪಾತ್ರ ಮತ್ತು ಅಭ್ಯರ್ಥಿಯು ಅದಕ್ಕೆ ಉತ್ತಮ ಆಯ್ಕೆ ಆಗಿದ್ದಾರೆಯೇ ಎಂದು ಪರಿಶೀಲಿಸಲು.",
              "te": "ఇది ఉద్యోగ పాత్ర మరియు అభ్యర్థి సరిగ్గా సరిపోతారో లేదో తెలుసుకోవడానికి ఇంటర్వ్యూయర్ & ఇంటర్వ్యూ చేసే ఇద్దరికీ ఇది ఒక అధికారిక సమావేశం.",
              "or": "ଚାକିରିର ଭୂମିକା ଏବଂ ପ୍ରାର୍ଥୀ ଏକ ଭଲ ଫିଟ୍ କି ନାହିଁ ତାହା ଯାଞ୍ଚ କରିବା\n  ପାଇଁ ଉଭୟ ସାକ୍ଷାତକାରକାରୀ ଏବଂ ସାକ୍ଷାତକାର ପାଇଁ ଏହା ଏକ ଆନୁଷ୍ଠାନିକ ବଇଠକ |",
              "as": "চাকৰিৰ ভূমিকা আৰু প্ৰাৰ্থী জন উপযুক্ত হয় নে নাই পৰীক্ষা কৰিবলৈ সাক্ষাৎকাৰ লওতা আৰু সাক্ষাৎকাৰ গ্ৰহণকাৰী দুয়োৰে বাবে ই এক আনুষ্ঠানিক সভা।",
              "gu": "નોકરીની ભૂમિકા અને ઉમેદવાર યોગ્ય છે કે કેમ તે તપાસવા માટે ઇન્ટરવ્યુ આપનાર અને ઇન્ટરવ્યુ લેનાર બંને માટે આ ઔપચારિક મીટિંગ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Think again! If you lie in your interview, it may have consequences later.",
              "hi": "फिर से विचार करो! यदि आप अपने साक्षात्कार में झूठ बोलते हैं, तो इसका परिणाम बाद में कुछ भी हो सकता है।",
              "ka": "ಮತ್ತೊಮ್ಮೆ  ಆಲೋಚಿಸಿ! ನಿಮ್ಮ ಸಂದರ್ಶನದಲ್ಲಿ ನೀವು ಸುಳ್ಳು ಹೇಳಿದರೆ, ಅದು ನಂತರ ಪರಿಣಾಮಗಳನ್ನು ಉಂಟುಮಾಡಬಹುದು.",
              "te": "మళ్లీ ఆలోచించండి! మీరు మీ ఇంటర్వ్యూలో అబద్ధం చెబితే, తర్వాత ఇబ్బంది కలగవచ్చు.",
              "or": "ପୁଣି ଥରେ ଭାବ! ଯଦି ତୁମେ ତୁମର ସାକ୍ଷାତକାରରେ ମିଛ କହୁଛ,\n  ଏହାର ପରିଣାମ ପରେ ହୋଇପାରେ |",
              "as": "আকৌ চিন্তা কৰা! যদি তুমি তোমাৰ সাক্ষাৎকাৰত মিছা কোৱা, ইয়াৰ পিছত কিবা পৰিণাম হ'ব পাৰে।",
              "gu": "ફરીથી વિચારો! જો તમે તમારા ઇન્ટરવ્યુમાં જૂઠું બોલો છો, તો તેના પરિણામો પછીથી આવી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Think again! Interviews are part of the world of work.",
              "hi": "फिर से विचार करो! साक्षात्कार काम की दुनिया का हिस्सा है।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಸಂದರ್ಶನಗಳು ಕೆಲಸದ ಪ್ರಪಂಚದಲ್ಲಿನ ಒಂದು ಭಾಗವಾಗಿದೆ.",
              "te": "మళ్లీ ఆలోచించండి! పని కోసం ఇంటర్వ్యూలు ఒక భాగం.",
              "or": "ପୁଣି ଥରେ ଭାବ! ସାକ୍ଷାତକାରଗୁଡ଼ିକ କାର୍ଯ୍ୟ ଜଗତର ଏକ ଅଂଶ |",
              "as": "আকৌ চিন্তা কৰা! সাক্ষাৎকাৰবোৰ কামৰ পৃথিৱীৰ অংশ।",
              "gu": "ફરીથી વિચારો! ઇન્ટરવ્યુ એ કામની દુનિયાનો એક ભાગ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Think again! An interview is a formal conversation to get a job.",
              "hi": "फिर से विचार करो! साक्षात्कार नौकरी पाने के लिए एक औपचारिक बातचीत है।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಸಂದರ್ಶನವು ಉದ್ಯೋಗವನ್ನು ಪಡೆಯಲು ಔಪಚಾರಿಕ ಸಂಭಾಷಣೆಯಾಗಿದೆ.",
              "te": "మళ్లీ ఆలోచించండి! ఇంటర్వ్యూ అనేది ఉద్యోగం పొందడానికి అధికారిక సంభాషణ.",
              "or": "ପୁଣି ଥରେ ଭାବ! ଚାକିରି ପାଇବା ପାଇଁ ଏକ ସାକ୍ଷାତକାର ହେଉଛି \n ଏକ ଆନୁଷ୍ଠାନିକ ବାର୍ତ୍ତାଳାପ |",
              "as": "আকৌ চিন্তা কৰা! এটা সাক্ষাৎকাৰ হৈছে চাকৰি এটা পাবলৈ এটা আনুষ্ঠানিক কথোপকথন।",
              "gu": "ફરીથી વિચારો! ઇન્ટરવ્યુ એ નોકરી મેળવવા માટે ઔપચારિક વાતચીત છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಉತ್ತಮ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ବହୁତ ଭଲ କାମ!",
              "as": "ভাল কৰিছা!",
              "gu": "સરસ કામ!"
            }
          }
        ],
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_1_1",
              "hi": "D1_1_1",
              "ka": "D1_1_1",
              "te": "D1_1_1",
              "or": "D1_1_1",
              "as": "D1_1_1",
              "gu": "D1_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "There’s an announcement in the news that the UPSC selection committee is ready with the results, and it will be sent to the candidates soon.",
              "hi": "समाचारों में बताया गया है कि यूपीएससी चयन समिति परिणामों के साथ तैयार है, और इसे जल्द ही उम्मीदवारों को भेजा जाएगा।",
              "ka": "UPSC ಆಯ್ಕೆ ಸಮಿತಿಯು ಫಲಿತಾಂಶಗಳೊಂದಿಗೆ ಸಿದ್ಧವಾಗಿದೆ ಮತ್ತು ಅದನ್ನು ಶೀಘ್ರದಲ್ಲೇ ಅಭ್ಯರ್ಥಿಗಳಿಗೆ ಕಳುಹಿಸಲಾಗುವುದು ಎಂದು ಸುದ್ದಿಯಲ್ಲಿ ಪ್ರಕಟಣೆ ಬರುತ್ತದೆ.",
              "te": "UPSC సెలక్షన్ కమిటీ ఫలితాలతో సిద్ధంగా ఉందని మరియు అవి త్వరలో అభ్యర్థులకు పంపబడుతాయని వార్తలలో ఒక ప్రకటన వచ్చింది.",
              "or": "ସମ୍ବାଦରେ ଏକ ଘୋଷଣା ଅଛି ଯେ ଫଳାଫଳ ସହିତ UPSC ଚୟନ\n  କମିଟି ପ୍ରସ୍ତୁତ, ଏବଂ ଏହା ଶୀଘ୍ର ପ୍ରାର୍ଥୀଙ୍କ ନିକଟକୁ ପଠାଯିବ |",
              "as": "বাতৰিত এটা ঘোষণা কৰা হৈছে যে UPSC বাছনি সমিতি ফলাফলৰ সৈতে সাজু হৈছে, আৰু ইয়াক শীঘ্ৰেই প্ৰাৰ্থীসকললৈ প্ৰেৰণ কৰা হ'ব।",
              "gu": "સમાચારમાં એક જાહેરાત છે કે UPSC પસંદગી સમિતિ પરિણામો સાથે તૈયાર છે, અને તે ટૂંક સમયમાં ઉમેદવારોને મોકલવામાં આવશે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_1_2",
              "hi": "D1_1_2",
              "ka": "D1_1_2",
              "te": "D1_1_2",
              "or": "D1_1_2",
              "as": "D1_1_2",
              "gu": "D1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The reporter shares that 2000 candidates appeared for the interview this year, which is the highest in the past few years.",
              "hi": "रिपोर्टर साझा करता है कि 2000 उम्मीदवार इस साल साक्षात्कार के लिए उपस्थित हुए, जो पिछले कुछ वर्षों में सबसे ज्यादा है।",
              "ka": "ಈ ವರ್ಷ 2000 ಅಭ್ಯರ್ಥಿಗಳು ಸಂದರ್ಶನಕ್ಕೆ ಹಾಜರಾಗಿದ್ದರು, ಇದು ಕಳೆದ ಕೆಲವು ವರ್ಷಗಳಲ್ಲಿ ಅತಿ ಹೆಚ್ಚು ಎಂದು ವರದಿಗಾರರು ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಂಡಿದ್ದಾರೆ.",
              "te": "ఈ ఏడాది 2000 మంది అభ్యర్థులు ఇంటర్వ్యూకు హాజరయ్యారని, ఇది గత కొన్నేళ్లలో అత్యధికమని రిపోర్టర్ తెలిపారు.",
              "or": "ସାମ୍ବାଦିକ ସେୟାର କରିଛନ୍ତି ଯେ ଏହି ବର୍ଷ ସାକ୍ଷାତକାର ପାଇଁ ୨୦୦୦\n  ପ୍ରାର୍ଥୀ ଉପସ୍ଥିତ ଥିଲେ, ଯାହା ଗତ କିଛି ବର୍ଷ ମଧ୍ୟରେ ସର୍ବାଧିକ |",
              "as": "প্ৰতিবেদকে কয় যে এই বছৰ 2000 জন প্ৰাৰ্থীয়ে সাক্ষাৎকাৰৰ বাবে অৱতীৰ্ণ হৈছিল, যি যোৱা কেইবছৰমানত সৰ্বাধিক।",
              "gu": "રિપોર્ટર બતાવે છે કે આ વર્ષે 2000 ઉમેદવારો ઇન્ટરવ્યુ માટે હાજર થયા હતા, જે છેલ્લા કેટલાક વર્ષોમાં સૌથી વધુ છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_1_3",
              "hi": "D1_1_3",
              "ka": "D1_1_3",
              "te": "D1_1_3",
              "or": "D1_1_3",
              "as": "D1_1_3",
              "gu": "D1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is nervous. He has been thinking of everything he could have said better in his personality test.",
              "hi": "माधव घबराया हुआ है। वह सब कुछ ऐसा सोच रहा है जो वह अपने व्यक्तित्व परीक्षण में बेहतर कह सकता था।",
              "ka": "ಮಾಧವ್ ಆತಂಕಗೊಂಡಿದ್ದಾನೆ. ಅವನು ತನ್ನ ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಯಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಹೇಳಬಹುದಾದ ಎಲ್ಲದರಾ ಬಗ್ಗೆಯೂ ಯೋಚಿಸುತ್ತಿದ್ದಾನೆ",
              "te": "మాధవ్ కంగారుగా ఉన్నాడు. అతను తన పర్సనాలిటీ టెస్ట్ లో ఇంకా బాగా ఎలా చెప్పగలిగేవాడని ప్రతిదాని గురించి ఆలోచిస్తున్నాడు.",
              "or": "ମାଧବ ନର୍ଭସ ଅଛନ୍ତି | ସେ ତାଙ୍କ ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷାରେ ଭଲ କହିଥାଇ\n  ପାରେ ବୋଲି ସବୁକିଛି ଚିନ୍ତା କରୁଛନ୍ତି |",
              "as": "মাধৱ উদ্বিগ্ন হৈ আছে। তেওঁ তেওঁৰ ব্যক্তিত্ব পৰীক্ষাত ভালদৰে ক'ব পৰা সকলোবোৰ কথা ভাবি আছে।",
              "gu": "માધવ નિરાશ છે. તે તેના વ્યક્તિત્વની કસોટીમાં વધુ સારી રીતે બતાવી શકે તે બધું જ વિચારી રહ્યો છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_1_4",
              "hi": "D1_1_4",
              "ka": "D1_1_4",
              "te": "D1_1_4",
              "or": "D1_1_4",
              "as": "D1_1_4",
              "gu": "D1_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He is scared that he might not have made a good impression during the personality test, and his score will affect his chance to become a Civil Servant.",
              "hi": "वह डरता है कि उसने व्यक्तित्व परीक्षण के दौरान एक अच्छा प्रभाव नहीं डाला होगा, और उसका स्कोर सिविल सेवा करने के अवसर को प्रभावित करेगा।",
              "ka": "ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಯ ಸಮಯದಲ್ಲಿ ತಾನು ಉತ್ತಮ ಪ್ರಭಾವ ಬೀರಲಿಲ್ಲ ಎಂದು ಅವನು ಭಯಪಡುತ್ತಿದ್ದಾನೆ ಮತ್ತು ಅವನ ಅಂಕವು ನಾಗರಿಕ ಸೇವಕನಾಗುವ ಅವಕಾಶದ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುತ್ತದೆ.",
              "te": "పర్సనాలిటీ టెస్ట్ సమయంలో అతను మంచి ఇంప్రెషన్ ఇవ్వలేకపోయానని మరియు అతని స్కోర్ సివిల్ సర్వెంట్ అయ్యే అవకాశాన్ని ప్రభావితం చేస్తుందని అతను భయపడ్డాడు.",
              "or": "ସେ ଭୟଭୀତ ଅଛନ୍ତି ଯେ ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷା ସମୟରେ ସେ ହୁଏତ ଭଲ \n ପ୍ରଭାବ ପକାଇ ନଥିବେ, ଏବଂ ତାଙ୍କର ସ୍କୋର ସିଭିଲ୍ ସେବକ ହେବାର ସୁଯୋଗକୁ ପ୍ରଭାବିତ କରିବ |",
              "as": "তেওঁ ভয় খাইছে যে ব্যক্তিত্ব পৰীক্ষাৰ সময়ত তেওঁ ভাল প্ৰভাৱ পেলাব নোৱাৰিব পাৰে, আৰু তেওঁৰ নম্বৰে তেওঁৰ অসামৰিক সেৱক হোৱাৰ সুযোগক প্ৰভাৱিত কৰিব।",
              "gu": "તેને ભય છે કે વ્યક્તિત્વ કસોટી દરમિયાન તેણે કદાચ સારી છાપ ન ઉભી કરી હોય, અને તેનો ક્રમ તેની સિવિલ સર્વન્ટ બનવાની તકને અસર કરશે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_2_1",
              "hi": "D1_2_1",
              "ka": "D1_2_1",
              "te": "D1_2_1",
              "or": "D1_2_1",
              "as": "D1_2_1",
              "gu": "D1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He goes out for a walk to clear his mind and have a cup of tea. He overhears two people talking about the UPSC results at the tea stall.",
              "hi": "वह अपने दिमाग को शांत करने और एक कप चाय पीने के लिए बाहर जाता है। वह चाय की दुकान पर यूपीएससी परिणामों के बारे में बात करते हुए दो लोगों को सुनता है।",
              "ka": "ಅವನು ತನ್ನ ಮನಸ್ಸನ್ನು ತಿಳಿಗೊಳಿಸಲು ಮತ್ತು ಒಂದು ಕಪ್ ಚಹಾವನ್ನು ಕುಡಿಯಲು ವಾಕಿಂಗ್‌ಗೆ ಹೋಗುತ್ತಾನೆ. ಟೀ ಸ್ಟಾಲ್‌ನಲ್ಲಿ UPSC ಫಲಿತಾಂಶಗಳ ಬಗ್ಗೆ ಇಬ್ಬರು ಮಾತನಾಡುವುದನ್ನು ಅವನು ಕೇಳುತ್ತಾನೆ.",
              "te": "అతను తన మైండ్ ని క్లియర్ చేసుకొని టీ తాగడానికి నడుస్తూ బయలుదేరాడు. టీ స్టాల్‌లో ఇద్దరు వ్యక్తులు UPSC ఫలితాల గురించి మాట్లాడుకోవడం అతను విన్నాడు.",
              "or": "ସେ ନିଜ ମନକୁ ସଫା କରିବାକୁ ଏବଂ ଏକ କପ୍ ଚା ପିଇବାକୁ ବୁଲିବାକୁ\n  ଯାଆନ୍ତି | ସେ ଚା ’ଷ୍ଟଲରେ UPSC ଫଳାଫଳ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରୁଥିବା ଦୁଇ ଜଣଙ୍କୁ ଶୁଣନ୍ତି |",
              "as": "তেওঁ মনটো পৰিষ্কাৰ কৰিবলৈ খোজ কাঢ়িবলৈ ওলাই যায় আৰু একাপ চাহ খায়। তেওঁ চাহৰ দোকানত দুজন লোকক UPSCৰ ফলাফলৰ বিষয়ে কথা পাতি থকা শুনিছিল।",
              "gu": "તે તેના મગજને સ્વસ્થ કરવા અને ચાનો કપ લેવા માટે બહાર જાય છે. તે ચાની દુકાન પર બે લોકોને UPSC પરિણામો વિશે વાત કરતા સાંભળે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_2_2////",
              "hi": "D1_2_2 ////////////",
              "ka": "D1_2_2////",
              "te": "D1_2_2////",
              "or": "D1_2_2////",
              "as": "D1_2_2////",
              "gu": "D1_2_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "You heard the news? The UPSC results are coming out.",
              "hi": "आपने सुना ? यूपीएससी परिणाम आने वाले हैं।",
              "ka": "ನೀವು ಸುದ್ದಿ ಕೇಳಿದ್ದೀರಾ? UPSC ಫಲಿತಾಂಶ ಹೊರಬೀಳುತ್ತಿದೆ.",
              "te": "నువ్వు వార్త విన్నావా? UPSC ఫలితాలు వెలువడుతున్నాయి.",
              "or": "ଆପଣ ଖବର ଶୁଣିଛନ୍ତି କି? UPSC ଫଳାଫଳ ଆସୁଛି |",
              "as": "তুমি বাতৰিটো শুনিছানে? UPSCৰ ফলাফল ওলাব হৈছে।",
              "gu": "તમે સમાચાર સાંભળ્યા? UPSCનું પરિણામ આવી રહ્યું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "So what? They come out every year, and new people come into the Civil Services every year, yet this country is still the same.",
              "hi": "तो क्या? वे हर साल आते हैं, और नए लोग हर साल सिविल सेवाओं में आते हैं, फिर भी यह देश अभी भी वैसा ही है।",
              "ka": "ಏನೀಗ? ಅದು ಪ್ರತಿ ವರ್ಷ ಹೊರಬರುತ್ತದೆ, ಮತ್ತು ಪ್ರತಿ ವರ್ಷ ಹೊಸ ಜನರು ನಾಗರಿಕ ಸೇವೆಗಳಿಗೆ ಬರುತ್ತಾರೆ, ಆದರೂ ಈ ದೇಶವು ಇನ್ನೂ ಹಾಗೆಯೇ ಇರುತ್ತದೆ.",
              "te": "అయితే ఏంటి? అవి ప్రతి సంవత్సరం వస్తాయి, మరియు ప్రతి సంవత్సరం కొత్త వ్యక్తులు సివిల్ సర్వీసెస్‌లోకి వస్తారు, కానీ ఈ దేశం ఇప్పటికీ అలాగే ఉంది.",
              "or": "ତ କଣ? ସେମାନେ ପ୍ରତିବର୍ଷ ବାହାରକୁ ଆସନ୍ତି, ଏବଂ ନୂତନ ଲୋକ\n  ପ୍ରତିବର୍ଷ ସିଭିଲ ସର୍ଭିସରେ ଆସନ୍ତି, ତଥାପି ଏହି ଦେଶ ସମାନ |",
              "as": "তাতে কি হ’ল? সেইবোৰ প্ৰতি বছৰে ওলাই থাকে, আৰু প্ৰতি বছৰে নতুন লোক অসামৰিক সেৱালৈ আহে, তথাপিও এই দেশ এতিয়াও একেই আছে।",
              "gu": "તો શું? તેઓ દર વર્ષે બહાર આવે છે, અને દર વર્ષે નવા લોકો સિવિલ સર્વિસમાં આવે છે, તેમ છતાં આ દેશ હજુ પણ એવો જ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "That’s true. One would think that people who clear such a difficult selection process must be capable enough to run the country.",
              "hi": "यह सच है। कोई सोचेगा कि जो लोग ऐसी मुश्किल चयन प्रक्रिया को उत्तीर्ण करते हैं, उन्हें देश को चलाने के लिए पर्याप्त सक्षम होना चाहिए।",
              "ka": "ಅದು ಸತ್ಯ. ಅಂತಹ ಕಠಿಣ ಆಯ್ಕೆ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ತೇರ್ಗಡೆಗೊಳ್ಳುವ  ಜನರು ದೇಶವನ್ನು ನಡೆಸಲು ಸಾಕಷ್ಟು ಸಮರ್ಥರಾಗಿರಬೇಕು ಎಂದು ಎಲ್ಲರೂ ಭಾವಿಸುತ್ತಾರೆ.",
              "te": "అది నిజం. అటువంటి క్లిష్ట ఎంపిక ప్రక్రియను క్లియర్ చేసే వ్యక్తులు దేశాన్ని నడపగల సామర్థ్యం కలిగి ఉండాలని ఎవరైనా అనుకుంటారు.",
              "or": "ତାହା ସତ୍ୟ ଅଟେ | ଜଣେ ଭାବିବେ ଯେ ଯେଉଁମାନେ ଏପରି କଠିନ \n ଚୟନ ପ୍ରକ୍ରିୟାକୁ ସଫା କରନ୍ତି ସେମାନେ ଦେଶ ଚଳାଇବା ପାଇଁ ଯଥେଷ୍ଟ ସକ୍ଷମ ହେବା ଆବଶ୍ୟକ |",
              "as": "সেইটো সঁচা। কোনোবাই ভাবিব পাৰে যে এনে কঠিন বাছনি প্ৰক্ৰিয়া সম্পন্ন কৰা লোকসকল দেশ খন চলাব পৰাকৈ যথেষ্ট সক্ষম হ'ব লাগিব।",
              "gu": "તે સાચું છે. કોઈ એવું વિચારશે કે જે લોકો આવી મુશ્કેલ પસંદગી પ્રક્રિયાને પાસ કરે છે તેઓ દેશ ચલાવવા માટે પૂરતા સક્ષમ હોવા જોઈએ."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_2_3",
              "hi": "D1_2_3",
              "ka": "D1_2_3",
              "te": "D1_2_3",
              "or": "D1_2_3",
              "as": "D1_2_3",
              "gu": "D1_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After overhearing this conversation, Madhav is even more stressed. He has been so busy with his studies for the past four years that he didn't think about the responsibility of being a Civil Servant properly.",
              "hi": "इस वार्तालाप को सुनने के बाद, माधव और भी तनावग्रस्त है। वह पिछले चार वर्षों से अपने अध्ययन में इतना व्यस्त है कि उसने सिविल सेवा करने की ज़िम्मेदारी के बारे में ठीक से नहीं सोचा था।",
              "ka": "ಈ ಸಂಭಾಷಣೆಯನ್ನು ಕೇಳಿದ ನಂತರ, ಮಾಧವ್ ಇನ್ನಷ್ಟು ಒತ್ತಡಕ್ಕೊಳಗಾಗುತ್ತಾನೆ. ಕಳೆದ ನಾಲ್ಕು ವರ್ಷಗಳಿಂದ ತನ್ನ ವಿದ್ಯಾಭ್ಯಾಸದಲ್ಲಿ ಬ್ಯುಸಿಯಾಗಿದ್ದ ಆತ ಪೌರಕಾರ್ಮಿಕನ ಜವಾಬ್ದಾರಿಯ ಬಗ್ಗೆ ಸರಿಯಾಗಿ ಯೋಚಿಸಿರಲಿಲ್ಲ.",
              "te": "ఈ సంభాషణ విన్న తర్వాత మాధవ్ మరింత ఒత్తిడికి లోనయ్యాడు. నాలుగేళ్లుగా చదువుతో బిజీబిజీగా గడిపిన అతడు సివిల్‌ సర్వెంట్‌ బాధ్యతల గురించి సరిగా ఆలోచించలేదు.",
              "or": "ଏହି ବାର୍ତ୍ତାଳାପକୁ ଶୁଣିବା ପରେ, ମାଧବ ଆହୁରି ଚାପଗ୍ରସ୍ତ | ସେ ଗତ\n  ଚାରି ବର୍ଷ ଧରି ଅଧ୍ୟୟନରେ ଏତେ ବ୍ୟସ୍ତ ଥିଲେ ଯେ ସେ ସିଭିଲ୍ ସେବକ ହେବାର ଦାୟିତ୍ about ବିଷୟରେ ସଠିକ୍ ଭାବରେ ଚିନ୍ତା କରିନଥିଲେ |",
              "as": "কাণত পৰা এই কথোপকথনটো শুনি মাধৱ আৰু অধিক চাপত পৰে। তেওঁ যোৱা চাৰি বছৰ ধৰি পঢ়া-শুনাত ইমানেই ব্যস্ত আছিল যে তেওঁ এজন অসামৰিক কৰ্মচাৰী হোৱাৰ দায়িত্বৰ বিষয়ে সঠিকভাৱে ভবাই নাছিল।",
              "gu": "આ વાતચીત સાંભળ્યા પછી માધવ વધુ તણાવમાં છે. છેલ્લા ચાર વર્ષથી તે પોતાના અભ્યાસમાં એટલો વ્યસ્ત હતો કે તેણે સિવિલ સર્વન્ટ તરીકેની જવાબદારી વિશે યોગ્ય રીતે વિચાર્યું ન હતું."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_3_1",
              "hi": "D1_3_1",
              "ka": "D1_3_1",
              "te": "D1_3_1",
              "or": "D1_3_1",
              "as": "D1_3_1",
              "gu": "D1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The pressure gets to him as he waits for the results to arrive.",
              "hi": "जब वह परिणाम की प्रतीक्षा करता है तो उस पर दबाव बढ़ जाता है।",
              "ka": "ಫಲಿತಾಂಶಗಳು ಬರಲು ಕಾಯುತ್ತಿರುವಾಗ ಬಹಳ ಒತ್ತಡವು ಅವನ ಮೇಲೆ ಬೀಳುತ್ತದೆ.",
              "te": "ఫలితాల కోసం ఎదురుచూడడంతో అతనిపై ఇంకా ఒత్తిడి పెరుగుతుంది.",
              "or": "ଫଳାଫଳ ଆସିବା ପର୍ଯ୍ୟନ୍ତ ଅପେକ୍ଷା କଲାବେଳେ ଚାପ ତାଙ୍କ ପାଖକୁ ଆସେ |",
              "as": "তেওঁ ফলাফল অহাৰ বাবে অপেক্ষা কৰি থকাৰ সময়ত চাপ বাঢ়ি গৈ থাকে।",
              "gu": "તેના પર દબાણ આવે છે કારણ કે તે પરિણામો આવવાની રાહ જુએ છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_3_2",
              "hi": "D1_3_2",
              "ka": "D1_3_2",
              "te": "D1_3_2",
              "or": "D1_3_2",
              "as": "D1_3_2",
              "gu": "D1_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After a long wait, Madhav finally gets an email. His hands are trembling.",
              "hi": "एक लंबे इंतजार के बाद, माधव को अंततः एक ईमेल मिलता है। उसके हाथ कांप रहे हैं।",
              "ka": "ಸುದೀರ್ಘ ಕಾಯುವಿಕೆಯ ನಂತರ, ಮಾಧವ್‌ಗೆ ಅಂತಿಮವಾಗಿ ಇಮೇಲ್ ಬರುತ್ತದೆ. ಅವನ ಕೈಗಳು ನಡುಗುತ್ತಿವೆ.",
              "te": "చాలా సేపటి తర్వాత, మాధవ్‌కి ఎట్టకేలకు ఒక ఇమెయిల్ వచ్చింది. అతని చేతులు వణుకుతున్నాయి.",
              "or": "ବହୁତ ସମୟ ଅପେକ୍ଷା ପରେ, ମାଧବ ଶେଷରେ ଏକ ଇମେଲ୍ ପାଇଲେ\n  | ତାଙ୍କ ହାତ ଥରି ଉଠୁଛି।",
              "as": "দীঘলীয়া অপেক্ষাৰ পিছত, মাধৱে অৱশেষত এটা ই-মেইল পালে। তেওঁৰ হাত কঁপিব ধৰে।",
              "gu": "લાંબી રાહ જોયા બાદ આખરે માધવને ઈમેલ મળે છે. તેના હાથ ધ્રૂજી રહ્યા છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_3_2 (email)",
              "hi": "D1_3_2 (ईमेल)",
              "ka": "D1_3_2 (email)",
              "te": "D1_3_2 (ఇమెయిల్)",
              "or": "D1_3_2 (ଇମେଲ୍)",
              "as": "D1_3_2 (ই-মেইল)",
              "gu": "D1_3_2 (ઇમેઇલ)"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailTo",
            "value": {
              "en": "selection-upsc.gov.in",
              "hi": "चयन-upsc.gov.in",
              "ka": "selection-upsc.gov.in",
              "te": "selection-upsc.gov.in",
              "or": "selection-upsc.gov.in",
              "as": "selection-upsc.gov.in",
              "gu": "selection-upsc.gov.in"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailSubject",
            "value": {
              "en": "Appointment to the Indian Revenue Service (IRS) on the basis of the results of the Civil Services Examination",
              "hi": "सिविल सेवा परीक्षा के परिणामों के आधार पर भारतीय राजस्व सेवा (आईआरएस) की नियुक्ति",
              "ka": "ನಾಗರಿಕ ಸೇವೆಗಳ ಪರೀಕ್ಷೆಯ ಫಲಿತಾಂಶಗಳ ಆಧಾರದ ಮೇಲೆ ಭಾರತೀಯ ಕಂದಾಯ ಸೇವೆಗೆ (IRS) ನೇಮಕಾತಿ",
              "te": "సివిల్ సర్వీసెస్ పరీక్ష ఫలితాల ఆధారంగా ఇండియన్ రెవెన్యూ సర్వీస్ (IRS)కి నియామకం",
              "or": "ସିଭିଲ ସର୍ଭିସ ପରୀକ୍ଷାର ଫଳାଫଳ ଆଧାରରେ ଭାରତୀୟ ରାଜସ୍ୱ\n  ସେବା (ଆଇଆରଏସ) ରେ ନିଯୁକ୍ତି |",
              "as": "অসামৰিক সেৱা পৰীক্ষাৰ ফলাফলৰ আধাৰত ভাৰতীয় ৰাজহ সেৱাত (IRS) নিযুক্তি",
              "gu": "સિવિલ સર્વિસ પરીક્ષાના પરિણામોના આધારે ભારતીય મહેસૂલ સેવા (IRS) માં નિમણૂક"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailMsg",
            "value": {
              "en": "Dear Applicant,\nThe Government of India is pleased to offer you the appointment to the Indian Revenue Service (IRS) on the basis of your qualifying Civil Services Examination conducted by the Union Public Service Commission.\nThe ‘Offer of Appointment’ is provisional subject to verification of your character and antecedents.",
              "hi": "प्रिय आवेदक,\nभारत सरकार को संघ लोक सेवा आयोग द्वारा आयोजित योग्यता सिविल सेवा परीक्षा के आधार पर आपको भारतीय राजस्व सेवा (आईआरएस) में नियुक्ति प्रदान करने में प्रसन्नता हो रही है।\n'नियुक्ति का प्रस्ताव' अस्थायी है और आपके चरित्र और पूर्ववर्ती के सत्यापन पर आधारित है।",
              "ka": "ಆತ್ಮೀಯ ಅರ್ಜಿದಾರರೇ,                                                                                                                                                             \"ಆತ್ಮೀಯ ಅರ್ಜಿದಾರರೇ,\nಯೂನಿಯನ್ ಪಬ್ಲಿಕ್ ಸರ್ವಿಸ್ ಕಮಿಷನ್ ನಡೆಸಿದ ನಿಮ್ಮ ಅರ್ಹತಾ ನಾಗರಿಕ ಸೇವೆಗಳ ಪರೀಕ್ಷೆಯ ಆಧಾರದ ಮೇಲೆ ಭಾರತೀಯ ಕಂದಾಯ ಸೇವೆಗೆ (IRS) ನೇಮಕಾತಿಯನ್ನು ನೀಡಲು ಭಾರತ ಸರ್ಕಾರವು ಸಂತೋಷ ಪಡುತ್ತದೆ. 'ಆಫರ್ ಆಫ್ ಅಪಾಯಿಂಟ್‌ಮೆಂಟ್' ತಾತ್ಕಾಲಿಕವಾಗಿ ನಿಮ್ಮ ಚಾರಿತ್ರ್ಯಮತ್ತು ಪೂರ್ವಾಪರಗಳ ಪರಿಶೀಲನೆಗೆ  ಒಳಪಟ್ಟಿರುತ್ತದೆ.\"",
              "te": "ప్రియమైన అప్లికెంట్,\n \n యూనియన్ పబ్లిక్ సర్వీస్ కమీషన్ నిర్వహించిన సివిల్ సర్వీసెస్ పరీక్షలో మీ అర్హత ఆధారంగా మీకు ఇండియన్ రెవెన్యూ సర్వీస్ (IRS)కి అపాయింట్‌మెంట్ అందించడానికి భారత ప్రభుత్వం సంతోషిస్తోంది.\n \n ‘అపాయింట్‌మెంట్ ఆఫర్’ తాత్కాలికమైనది మరియు ఇది మీ స్వభావం మరియు పూర్వాపరాల ధృవీకరణపై ఆధారపడి ఉంటుంది.",
              "or": "ପ୍ରିୟ ଆବେଦନକାରୀ,\n \n ୟୁନିୟନ ପବ୍ଲିକ ସର୍ଭିସ କମିଶନଙ୍କ ଦ୍ conducted ାରା ପରିଚାଳିତ ଯୋଗ୍ୟତା ସିଭିଲ ସର୍ଭିସେସ୍ ପରୀକ୍ଷା ଆଧାରରେ ଆପଣଙ୍କୁ ଭାରତୀୟ ରାଜସ୍ୱ ସେବା (ଆଇଆରଏସ) ରେ ନିଯୁକ୍ତି ପ୍ରଦାନ କରିବାକୁ ଭାରତ ସରକାର ଖୁସି।\n \n ତୁମର ନିଯୁକ୍ତି ଏବଂ ପ୍ରାଚୀନତା ଯାଞ୍ଚ ପାଇଁ ‘ନିଯୁକ୍ତି ଅଫର’ ସାମୟିକ ବିଷୟ ଅଟେ।",
              "as": "মৰমৰ আবেদনকাৰী, কেন্দ্ৰীয় লোক সেৱা আয়োগে অনুষ্ঠিত কৰা অসামৰিক সেৱা পৰীক্ষাত আপোনাৰ যোগ্যতাৰ আধাৰত ভাৰত চৰকাৰে আপোনাক ভাৰতীয় ৰাজহ সেৱা (IRS)ত নিযুক্তি প্ৰদান কৰি আনন্দিত হৈছে। 'নিযুক্তিৰ প্ৰস্তাৱ' আপোনাৰ চৰিত্ৰ আৰু পূৰ্ববৰ্তী সত্যাসত্যৰ নিৰূপণ সাপেক্ষে অস্থায়ী।",
              "gu": "પ્રિય અરજદાર, ભારત સરકાર યુનિયન પબ્લિક સર્વિસ કમિશન દ્વારા આયોજિત તમારી લાયકાત ધરાવતી સિવિલ સર્વિસીસ પરીક્ષાના આધારે તમને ભારતીય મહેસૂલ સેવા (IRS)માં નિમણૂકની અર્પણ કરતાં આનંદ અનુભવે છે. 'ઓફર ઑફ એપોઇન્ટમેન્ટ' તમારા પાત્ર અને પૂર્વ-ઈતિહાસની ચકાસણીને આધારે કામચલાઉ વિષય છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_3_3",
              "hi": "D1_3_3",
              "ka": "D1_3_3",
              "te": "D1_3_3",
              "or": "D1_3_3",
              "as": "D1_3_3",
              "gu": "D1_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav cannot believe what he is reading. He has cleared all the steps in the Civil Services selection process.",
              "hi": "माधव को विश्वास नहीं हो रहा है कि वह क्या पढ़ रहा है। उसने सिविल सेवा चयन प्रक्रिया में सभी चरणों को उत्तीर्ण कर लिया है।",
              "ka": "ಮಾಧವನಿಗೆ ತಾನು ಓದುತ್ತಿರುವುದನ್ನು ನಂಬಲಾಗುತ್ತಿಲ್ಲ. ಅವನು ನಾಗರಿಕ ಸೇವೆಗಳ ಆಯ್ಕೆ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ಎಲ್ಲಾ ಹಂತಗಳನ್ನು ಮುಗಿಸಿದ್ದಾನೆ.",
              "te": "మాధవ్ చదివేది నమ్మలేకపోతున్నాడు. సివిల్ సర్వీసెస్ ఎంపిక ప్రక్రియలో అతను అన్ని దశలను క్లియర్ చేశాడు.",
              "or": "ସେ ଯାହା ପଢୁଛନ୍ତି, ତାହା ମଧ୍ୟ ବିଶ୍ୱାସ କରିପାରିବ ନାହିଁ | ସିଭିଲ ସର୍ଭିସେସ୍\n  ଚୟନ ପ୍ରକ୍ରିୟାରେ ସେ ସମସ୍ତ ପଦକ୍ଷେପକୁ ସଫା କରିଛନ୍ତି |",
              "as": "মাধৱে বিশ্বাস কৰিব পৰা নাই সি কি পঢ়ি আছে। তেওঁ অসামৰিক সেৱা বাছনি প্ৰক্ৰিয়াৰ সকলো পদক্ষেপ সম্পূৰ্ণ কৰিছে।",
              "gu": "માધવ જે વાંચી રહ્યો છે તે માની શકતો નથી. તેમણે સિવિલ સર્વિસીસ પસંદગી પ્રક્રિયાના તમામ પગલાઓ પાસ કર્યા છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_3_4",
              "hi": "D1_3_4",
              "ka": "D1_3_4",
              "te": "D1_3_4",
              "or": "D1_3_4",
              "as": "D1_3_4",
              "gu": "D1_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "But he is unsure how to feel about the selection. He had imagined it would be a very proud and joyous moment for him if he got selected, but he’s feeling disappointed instead.",
              "hi": "लेकिन वह अनिश्चित है कि चयन के बारे में कैसा अनुभव किया जाए। उसने कल्पना की थी कि अगर उसे चुना गया तो उसके लिए यह एक बहुत गर्व और आनंदमय क्षण होगा, लेकिन वह इसके बजाय निराशा का अनुभव कर रहा है।",
              "ka": "ಆದರೆ ಆಯ್ಕೆಯ ಬಗ್ಗೆ ಹೇಗೆ ಭಾವಿಸಬೇಕೆಂದು ಅವನಿಗೆ ಇನ್ನೂ ಖಚಿತವಾಗಿಲ್ಲ. ತಾನು ಆಯ್ಕೆಯಾದಲ್ಲಿ ಅದು ಅವನಿಗೆ ತುಂಬಾ ಹೆಮ್ಮೆ ಮತ್ತು ಸಂತೋಷದಾಯಕ ಕ್ಷಣ ಎಂದು ಊಹಿಸಿದ್ದನು, ಆದರೆ ಬದಲಿಗೆ ಅವನು ನಿರಾಶೆ ಅನುಭವಿಸುತ್ತಿದ್ದಾನೆ.",
              "te": "కానీ ఎంపిక గురించి ఎలా ఫీల్ అవ్వాలో అతనికి తెలియదు. అతను ఎంపిక చేయబడితే అది అతనికి చాలా గర్వంగా మరియు సంతోషకరమైన క్షణం అని అతను ఊహించాడు, కానీ అతను నిరాశకు గురయ్యాడు.",
              "or": "କିନ୍ତୁ ଚୟନ ବିଷୟରେ କିପରି ଅନୁଭବ କରିବେ ସେ ନିଶ୍ଚିତ ନୁହଁନ୍ତି |\n  ଯଦି ସେ ମନୋନୀତ ହୁଅନ୍ତି ତେବେ ଏହା ତାଙ୍କ ପାଇଁ ଅତ୍ୟନ୍ତ ଗର୍ବିତ ଏବଂ ଆନନ୍ଦଦାୟକ ମୁହୂର୍ତ୍ତ ହେବ ବୋଲି ସେ କଳ୍ପନା କରିଥିଲେ, କିନ୍ତୁ ସେ ଏହା ବଦଳରେ ନିରାଶ ଅନୁଭବ କରୁଛନ୍ତି |",
              "as": "কিন্তু তেওঁ নিশ্চিত নহয় যে বাছনিৰ বিষয়ে কেনে অনুভৱ কৰিব লাগে। তেওঁ কল্পনা কৰিছিল যে যদি তেওঁ নিৰ্বাচিত হয় তেন্তে এইটো তেওঁৰ বাবে এক গৌৰৱান্বিত আৰু আনন্দৰ মুহূৰ্ত হ'ব, কিন্তু তাৰ সলনি তেওঁ হতাশহে অনুভৱ কৰিছে।",
              "gu": "પરંતુ તે અચોક્કસ છે કે પસંદગી વિશે કેવું લાગે છે. તેણે કલ્પના કરી હતી કે જો તે પસંદ થાય તો તે તેના માટે ખૂબ જ ગર્વ અને આનંદની ક્ષણ હશે, પરંતુ તેના બદલે તે નિરાશ થઈ રહ્યો છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_3_5",
              "hi": "D1_3_5",
              "ka": "D1_3_5",
              "te": "D1_3_5",
              "or": "D1_3_5",
              "as": "D1_3_5",
              "gu": "D1_3_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav always aimed to be an IAS officer in his preparations for Civil Services. He did not think how he would feel about being an IRS officer.",
              "hi": "माधव ने हमेशा सिविल सेवाओं की तैयारी में आईएएस अधिकारी बनना लक्षित किया था। उसने नहीं सोचा कि वह आईआरएस अधिकारी होने के बारे में कैसा अनुभव करेगा।",
              "ka": "ಮಾಧವ್ ತಾನು ಸಿವಿಲ್ ಸರ್ವೀಸ್ ತಯಾರಿಯಲ್ಲಿ ಯಾವಾಗಲೂ ಐಎಎಸ್ ಅಧಿಕಾರಿಯಾಗಬೇಕೆಂಬ ಗುರಿಯನ್ನು ಹೊಂದಿದ್ದನು. ಐಆರ್‌ಎಸ್ ಅಧಿಕಾರಿಯಾಗಿರುವ ಬಗ್ಗೆ ತಾನು ಯಾವ ರೀತಿ ಭಾವಿಸುತ್ತೇನೆ ಎಂದು ಯೋಚಿಸಿರಲಿಲ್ಲ.",
              "te": "సివిల్ సర్వీసెస్‌కు ప్రిపరేషన్‌లో మాధవ్ ఎప్పుడూ IAS అధికారి కావాలని లక్ష్యంగా పెట్టుకున్నాడు. IRS అధికారి అయినందుకు తనకెలా ఫీల్ అవ్వాలో తెలియట్లేదు.",
              "or": "ସିଭିଲ୍ ସର୍ଭିସେସ୍ ପାଇଁ ତାଙ୍କ ପ୍ରସ୍ତୁତିରେ ମାଧବ ସର୍ବଦା IAS ଅଧିକାରୀ\n  ହେବାକୁ ଲକ୍ଷ୍ୟ ରଖିଥିଲେ | ସେ ଭାବିନଥିଲେ ଯେ ଜଣେ ଆଇଆରଏସ ଅଧିକାରୀ ହେବା ବିଷୟରେ ସେ କିପରି ଅନୁଭବ କରିବେ |",
              "as": "মাধৱে সদায় অসামৰিক সেৱাৰ বাবে তেওঁৰ প্ৰস্তুতিত এজন IAS বিষয়া হোৱাৰ লক্ষ্য ৰাখিছিল। তেওঁ ভবা নাছিল যে এজন IRS বিষয়া হোৱাৰ বিষয়ে তেওঁ কেনে অনুভৱ কৰিব।",
              "gu": "સિવિલ સર્વિસીસની તૈયારીઓમાં માધવ હંમેશા IAS અધિકારી બનવાનું લક્ષ્ય રાખતા હતા. તેણે વિચાર્યું ન હતું કે IRS ઓફિસર બનીને તેને કેવું લાગશે."
            }
          }
        ]
      },
      "scene32": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_1_1",
              "hi": "D2_1_1",
              "ka": "D2_1_1",
              "te": "D2_1_1",
              "or": "D2_1_1",
              "as": "D2_1_1",
              "gu": "D2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Although Madhav is disappointed that he didn’t get his preferred post, Kuldeep and Badri are thrilled. They dance around the room, almost as though they have won something.",
              "hi": "हालांकि माधव निराश है कि उसे अपना पसंदीदा पद नहीं मिला, कुलदीप और बद्री रोमांचित हैं। वे कमरे में चारों ओर नृत्य करते हैं, मानो उन्होंने कुछ जीता है।",
              "ka": "ಮಾಧವ್ ತನ್ನ ಆದ್ಯತೆಯ ಹುದ್ದೆಯನ್ನು ಪಡೆಯದಿದ್ದಕ್ಕಾಗಿ ನಿರಾಶೆಗೊಂಡಿದ್ದರೂ ಕೂಡ, ಕುಲದೀಪ್ ಮತ್ತು ಬದ್ರಿ ರೋಮಾಂಚನಗೊಂಡಿದ್ದಾರೆ. ಅವರು ಕೋಣೆಯ ಸುತ್ತಲೂ ನೃತ್ಯ ಮಾಡುತ್ತಾರೆ, ಅವರೇ ಏನನ್ನೋ ಗೆದ್ದಂತೆ.",
              "te": "మాధవ్ తనకు ఇష్టమైన పదవిని పొందలేదని నిరాశ చెందినప్పటికీ, కుల్దీప్ మరియు బద్రి థ్రిల్‌గా ఉన్నారు. వారు దాదాపు ఏదో గెలిచినట్లుగా గది మొత్తం డ్యాన్స్ చేస్తారు.",
              "or": "ଯଦିଓ ମାଧବ ନିରାଶ ହୋଇଛନ୍ତି ଯେ ସେ ତାଙ୍କର ପସନ୍ଦିତ ପଦବୀ \n ପାଇନାହାଁନ୍ତି, କୁଲଦୀପ ଏବଂ ବଦ୍ରି ବହୁତ ଖୁସି ଅଛନ୍ତି | ସେମାନେ କୋଠରୀରେ ନାଚନ୍ତି, ଯେପରି ସେମାନେ କିଛି ଜିତିଛନ୍ତି |",
              "as": "যদিও মাধৱে তেওঁৰ পছন্দৰ পদ নোপোৱাৰ বাবে হতাশ হৈছে, কুলদীপ আৰু বদ্ৰী ৰোমাঞ্চিত হৈছে। তেওঁলোকে কোঠাটোৰ চাৰিওফালে নাচি থাকে, যেন তেওঁলোকে কিবা জিকিছে।",
              "gu": "જોકે માધવ નિરાશ છે કે તેને તેની પસંદગીની પોસ્ટ મળી નથી, કુલદીપ અને બદ્રી રોમાંચિત છે. તેઓ રૂમની આસપાસ નૃત્ય કરે છે, લગભગ જાણે કે તેઓએ કંઈક જીત્યું હોય."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_1_2",
              "hi": "D2_1_2",
              "ka": "D2_1_2",
              "te": "D2_1_2",
              "or": "D2_1_2",
              "as": "D2_1_2",
              "gu": "D2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Kuldeep and Badri remind Madhav that out of the 10 lakh people who appeared for the Civil Services exams, only 900 got appointed into Civil Services, and he is one of them.",
              "hi": "कुलदीप और बद्री ने माधव को याद दिलाया कि 10 लाख लोगों में से जो सिविल सेवा परीक्षाओं के लिए उपस्थित हुए, केवल 900 को सिविल सेवाओं में नियुक्त किया गया, और वह उनमें से एक है।",
              "ka": "ಕುಲದೀಪ್ ಮತ್ತು ಬದ್ರಿ ಮಾಧವ್‌ಗೆ ತಿಳಿಸುತ್ತಾರೆ, ಸಿವಿಲ್ ಸರ್ವೀಸ್ ಪರೀಕ್ಷೆಗಳಿಗೆ ಹಾಜರಾದ 10 ಲಕ್ಷ ಜನರಲ್ಲಿ ಕೇವಲ 900 ಜನರು ಮಾತ್ರ ಸಿವಿಲ್ ಸೇವೆಗಳಿಗೆ ನೇಮಕಗೊಂದಿರುವುದು ಹಾಗೂ ಅವರಲ್ಲಿ ನೀನೂ ಒಬ್ಬ ಎಂದು.",
              "te": "సివిల్ సర్వీసెస్ పరీక్షలకు హాజరైన 10 లక్షల మందిలో కేవలం 900 మంది మాత్రమే సివిల్ సర్వీసెస్‌లో నియమితులయ్యారని, వారిలో తానూ ఒకడని కుల్దీప్ మరియు బద్రి మాధవ్‌కు గుర్తు చేశారు.",
              "or": "କୁଲଦୀପ ଏବଂ ବଦ୍ରି ମାଧବଙ୍କୁ ମନେ ପକାଇ ଦେଇଛନ୍ତି ଯେ ସିଭିଲ\n  ସର୍ଭିସ ପରୀକ୍ଷାରେ ଭାଗ ନେଇଥିବା 10 ଲକ୍ଷ ଲୋକଙ୍କ ମଧ୍ୟରୁ କେବଳ 900 ଜଣ ସିଭିଲ ସର୍ଭିସରେ ନିଯୁକ୍ତ ହୋଇଛନ୍ତି ଏବଂ ସେ ସେମାନଙ୍କ ମଧ୍ୟରୁ ଜଣେ।",
              "as": "কুলদীপ আৰু বদ্ৰীয়ে মাধৱক সোঁৱৰাই দিয়ে যে অসামৰিক সেৱাৰ পৰীক্ষাত অৱতীৰ্ণ হোৱা 10 লাখ লোকৰ ভিতৰত মাত্ৰ 900 জনে অসামৰিক সেৱাত নিযুক্তি লাভ কৰিছে, আৰু তেওঁ তেওঁলোকৰ মাজৰ এজন।",
              "gu": "કુલદીપ અને બદ્રી માધવને યાદ કરાવે છે કે સિવિલ સર્વિસની પરીક્ષા આપનારા 10 લાખ લોકોમાંથી માત્ર 900 લોકોને સિવિલ સર્વિસમાં નિમણૂક મળી હતી અને તે તેમાંથી એક છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_1_3",
              "hi": "D2_1_3",
              "ka": "D2_1_3",
              "te": "D2_1_3",
              "or": "D2_1_3",
              "as": "D2_1_3",
              "gu": "D2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav realises that not many people can work at such an important post. Now that he has got it, he should focus on bringing about the change he has always wanted.",
              "hi": "माधव को पता चलता है कि इतने महत्वपूर्ण पद पर बहुत से लोग काम नहीं कर सकते। अब जब उसे यह मिल गया है, तो उसे उस बदलाव को लाने पर ध्यान केंद्रित करना चाहिए जो वह हमेशा से चाहता था।",
              "ka": "ಅಂತಹ ಮಹತ್ವದ ಹುದ್ದೆಯಲ್ಲಿ ಹೆಚ್ಚಿನವರು ಕೆಲಸ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ ಎಂದು ಮಾಧವ್ ಅರಿತುಕೊಂಡನು. ಈಗ ಅದು ತನಗೆ ಸಿಕ್ಕಿದ್ದು, ಸದಾ ಬಯಸುತ್ತಿರುವ ಬದಲಾವಣೆಯನ್ನು ತರುವತ್ತ ಗಮನ ಹರಿಸಬೇಕು.",
              "te": "ఇంత ముఖ్యమైన పోస్ట్ లో ఎక్కువ మంది పనిచేయలేరని మాధవ్‌కు గుర్తు చేసుకుంటాడు. ఇప్పుడు అది వచ్చినందున, అతను ఎప్పుడూ కోరుకుంటున్న మార్పు తీసుకురావడంపై దృష్టి పెట్టాలి.",
              "or": "ମାଧବ ହୃଦୟଙ୍ଗମ କରିଛନ୍ତି ଯେ ଏତେ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ପୋଷ୍ଟରେ ଅନେକ\n  ଲୋକ କାମ କରିପାରିବେ ନାହିଁ | ବର୍ତ୍ତମାନ ଯେହେତୁ ସେ ଏହା ପାଇଛନ୍ତି, ସେ ସର୍ବଦା ଚାହୁଁଥିବା ପରିବର୍ତ୍ତନ ଆଣିବା ଉପରେ ଧ୍ୟାନ ଦେବା ଉଚିତ୍ |",
              "as": "মাধৱে উপলব্ধি কৰিছে যে ইমান গুৰুত্বপূৰ্ণ পদত সকলোৱে কাম কৰিব নোৱাৰে। এতিয়া যেতিয়া তেওঁ এইটো পাইছে, তেওঁ সদায় বিচৰা পৰিৱৰ্তনটো অনাৰ ওপৰত গুৰুত্ব দিব লাগে।",
              "gu": "માધવને ખ્યાલ આવે છે કે આટલી મહત્વની પોસ્ટ પર ઘણા લોકો કામ કરી શકતા નથી. હવે જ્યારે તેને તે મળી ગયું છે, તેણે તે બદલાવ લાવવા પર ધ્યાન કેન્દ્રિત કરવું જોઈએ જે તે હંમેશા ઇચ્છતો હતો."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_1",
              "hi": "D2_2_1",
              "ka": "D2_2_1",
              "te": "D2_2_1",
              "or": "D2_2_1",
              "as": "D2_2_1",
              "gu": "D2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Soon, he leaves for Nagpur for his IRS training at the National Academy of Direct Taxes ( NADT). It is the first time he is staying so far away from his friends. He also gets a small salary during the training period that secures Madhav’s expenses.",
              "hi": "जल्द ही, वह नेशनल एकेडमी ऑफ डायरेक्ट टैक्स (एनएडीटी) में अपने आईआरएस प्रशिक्षण के लिए नागपुर जाता है। यह पहली बार है जब वह अपने दोस्तों से इतनी दूर रह रहा है। उसे प्रशिक्षण अवधि के दौरान भी थोड़ा वेतन मिलता है जिससे माधव अपने खर्चे उठाता है।",
              "ka": "ಶೀಘ್ರದಲ್ಲೇ, ಅವನು ನ್ಯಾಷನಲ್ ಅಕಾಡೆಮಿ ಆಫ್ ಡೈರೆಕ್ಟ್ ಟ್ಯಾಕ್ಸ್ (NADT) ನಲ್ಲಿ IRS ತರಬೇತಿಗಾಗಿ ನಾಗ್ಪುರಕ್ಕೆ ತೆರಳುತ್ತಾನೆ. ಅವನು ತನ್ನ ಸ್ನೇಹಿತರಿಂದ ದೂರ ಉಳಿದಿರುವುದು ಇದೇ ಮೊದಲು. ತರಬೇತಿ ಅವಧಿಯಲ್ಲಿ ಮಾಧವನ ಖರ್ಚುಗಳನ್ನು ಭದ್ರಪಡಿಸುವ ಸಣ್ಣ ಸಂಬಳವನ್ನೂ ಪಡೆಯುತ್ತಾನೆ.",
              "te": "త్వరలో, అతను నేషనల్ అకాడమీ ఆఫ్ డైరెక్ట్ టాక్సెస్ (NADT)లో IRS శిక్షణ కోసం నాగ్‌పూర్‌కు వెళ్తాడు. అతను తన స్నేహితులకు దూరంగా ఉండటం అదే మొదటిసారి. అతని శిక్షణ కాలంలో మాధవ్ ఖర్చులకు సరిపడా కొంచెం జీతం కూడా పొందుతాడు.",
              "or": "ଖୁବ୍ ଶୀଘ୍ର, ସେ ନ୍ୟାସନାଲ୍ ଏକାଡେମୀ ଅଫ୍ ଡାଇରେକ୍ଟ ଟ୍ୟାକ୍ସ (\n NADT) ରେ IRS ତାଲିମ ପାଇଁ ନାଗପୁର ଅଭିମୁଖେ ଯାତ୍ରା କରନ୍ତି | ସେ ପ୍ରଥମ ଥର ପାଇଁ ନିଜ ସାଙ୍ଗମାନଙ୍କଠାରୁ ଏତେ ଦୂରରେ ରହିଛନ୍ତି | ତାଲିମ ଅବଧିରେ ସେ ଅଳ୍ପ ଦରମା ମଧ୍ୟ ପାଇଥା’ନ୍ତି ଯାହାକି ମାଧବଙ୍କ ଖର୍ଚ୍ଚକୁ ସୁରକ୍ଷିତ କରିଥାଏ |",
              "as": "সোনকালেই, তেওঁ ৰাষ্ট্ৰীয় প্ৰত্যক্ষ কৰ একাডেমীত (NADT) IRS প্ৰশিক্ষণৰ বাবে নাগপুৰলৈ ৰাওনা হয়। এইটো প্ৰথম বাৰৰ বাবে তেওঁ তেওঁৰ বন্ধুবৰ্গৰ পৰা ইমান দূৰত আছে। তেওঁ প্ৰশিক্ষণৰ সময়ছোৱাত এটা সৰু দৰমহাও লাভ কৰে যিয়ে মাধৱৰ খৰচ সুৰক্ষিত কৰে।",
              "gu": "ટૂંક સમયમાં, તે નેશનલ એકેડેમી ઓફ ડાયરેક્ટ ટેક્સીસ (NADT) માં તેની IRS તાલીમ માટે નાગપુર જવા રવાના થાય છે. તે પ્રથમ વખત છે જ્યારે તે તેના મિત્રોથી આટલો દૂર રહે છે. તેને તાલીમના સમયગાળા દરમિયાન થોડો પગાર પણ મળે છે જે માધવના ખર્ચાઓને સુરક્ષિત કરે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_2(notebook)",
              "hi": "D2_2_2 (नोटबुक)",
              "ka": "D2_2_2(notebook)",
              "te": "D2_2_2(నోట్‌బుక్)",
              "or": "D2_2_2(notebook)",
              "as": "D2_2_2(টোকাবহী)",
              "gu": "D2_2_2(નોટબુક)"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He maintains his journal to document all his activities. Click on the pages to read his journal:",
              "hi": "वह अपनी सभी गतिविधियों का दस्तावेजीकरण करने के लिए जर्नल रखता है। उसका जर्नल पढ़ने के लिए पृष्ठों पर क्लिक करें:",
              "ka": "ಅವನು ತನ್ನ ಎಲ್ಲಾ ಚಟುವಟಿಕೆಗಳನ್ನು ದಾಖಲಿಸಲು ತನ್ನ ಜರ್ನಲ್ ಅನ್ನು ಉಪಯೋಗಿಸುತ್ತಾನೆ. ಅವನ ಜರ್ನಲ್ ಅನ್ನು ಓದಲು ಪುಟಗಳ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "అతను తన యాక్టివిటీస్ అన్నింటినీ డాక్యుమెంట్ చేయడానికి తన జర్నల్ మేంటేన్ చేస్తాడు. అతని జర్నల్ చదవడానికి పేజీలపై క్లిక్ చేయండి:",
              "or": "ତାଙ୍କର ସମସ୍ତ କାର୍ଯ୍ୟକଳାପକୁ ଦଲିଲ କରିବା ପାଇଁ ସେ ତାଙ୍କର ପତ୍ରିକା\n  ପରିଚାଳନା କରନ୍ତି | ତାଙ୍କ ପତ୍ରିକା ପଢିବା ପାଇଁ ପୃଷ୍ଠାଗୁଡ଼ିକରେ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "তেওঁ তেওঁৰ সকলো কাৰ্যকলাপ নথিভুক্ত কৰিবলৈ তেওঁৰ পত্ৰিকাখন বজাই ৰাখে। তেওঁৰ পত্ৰিকাখন পঢ়িবলৈ পৃষ্ঠাবোৰত ক্লিক কৰা:",
              "gu": "તેઓ તેમની તમામ પ્રવૃત્તિઓના દસ્તાવેજીકરણ માટે તેમની જર્નલ જાળવી રાખે છે. તેમની જર્નલ વાંચવા માટે પૃષ્ઠો પર ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page 1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "ପୃଷ୍ଠା ୧",
              "as": "পৃষ্ঠা ১",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page 2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ 2",
              "or": "ପୃଷ୍ଠା ୨",
              "as": "পৃষ্ঠা ২",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Page 3",
              "hi": "पृष्ठ 3",
              "ka": "ಪುಟ 3",
              "te": "పేజీ 3",
              "or": "ପୃଷ୍ଠା ୩",
              "as": "পৃষ্ঠা ৩",
              "gu": "પેજ 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Classes every day on subjects like Accountancy, Direct Tax Laws, Business Laws, and International Taxation. Conducted by senior IRS officers from the field and experts from various universities.",
              "hi": "क्षेत्र के वरिष्ठ आईआरएस अधिकारियों और विभिन्न विश्वविद्यालयों के विशेषज्ञों द्वारा संचालित एकाउंटेंसी/ लेखा, प्रत्यक्ष कर कानून, व्यापार कानून और अंतर्राष्ट्रीय कराधान जैसे विषयों पर हर दिन कक्षाएं हैं। ",
              "ka": "ಲೆಕ್ಕಪತ್ರ ನಿರ್ವಹಣೆ, ನೇರ ತೆರಿಗೆ ಕಾನೂನುಗಳು, ವ್ಯಾಪಾರ ಕಾನೂನುಗಳು ಮತ್ತು ಅಂತರರಾಷ್ಟ್ರೀಯ ತೆರಿಗೆಗಳಂತಹ ವಿಷಯಗಳ ಕುರಿತು ಕ್ಷೇತ್ರದ ಹಿರಿಯ IRS ಅಧಿಕಾರಿಗಳು ಮತ್ತು ವಿವಿಧ ವಿಶ್ವವಿದ್ಯಾಲಯಗಳ ತಜ್ಞರು ಪ್ರತಿದಿನ ತರಗತಿಗಳನ್ನು ನಡೆಸುತ್ತಾರೆ.",
              "te": "అకౌంటెన్సీ, డైరెక్ట్ టాక్స్ లాస్, బిజినెస్ లాస్ మరియు ఇంటర్నేషనల్ టాక్సేషన్ వంటి సబ్జెక్టులపై ప్రతిరోజూ క్లాసులు. ఈ రంగంలోని సీనియర్ IRS అధికారులు మరియు వివిధ యూనివర్సిటీల నిపుణులచే నిర్వహించబడతాయి.",
              "or": "ଆକାଉଣ୍ଟାଣ୍ଟି, ସିଧାସଳଖ ଟିକସ ନିୟମ, ବ୍ୟବସାୟ ନିୟମ ଏବଂ \n ଆନ୍ତର୍ଜାତୀୟ ଟିକସ ପରି ବିଷୟ ଉପରେ ପ୍ରତିଦିନ କ୍ଲାସ୍ | କ୍ଷେତ୍ରର ବରିଷ୍ଠ ଆଇଆରଏସ ଅଧିକାରୀ ଏବଂ ବିଭିନ୍ନ ବିଶ୍ୱବିଦ୍ୟାଳୟର ବିଶେଷଜ୍ଞଙ୍କ ଦ୍ୱାରା ପରିଚାଳିତ |",
              "as": "একাউণ্টেন্সি, প্ৰত্যক্ষ কৰ আইন, ব্যৱসায় আইন, আৰু আন্তৰ্জাতিক কৰ আদি বিষয়ত প্ৰতিদিনে শ্ৰেণী থাকে। ক্ষেত্ৰখনৰ জ্যেষ্ঠ IRS বিষয়া আৰু বিভিন্ন বিশ্ববিদ্যালয়ৰ বিশেষজ্ঞৰ দ্বাৰা পৰিচালিত কৰা হয়।",
              "gu": "એકાઉન્ટન્સી, ડાયરેક્ટ ટેક્સ કાયદા, વ્યવસાય કાયદા અને આંતરરાષ્ટ્રીય કરવેરા જેવા વિષયો પર દરરોજ વર્ગો. ક્ષેત્રના વરિષ્ઠ IRS અધિકારીઓ અને વિવિધ યુનિવર્સિટીઓના નિષ્ણાતો દ્વારા હાથ ધરવામાં આવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Participate in sports and clubs like drama, music, and dance.",
              "hi": "खेल और क्लबों जैसे नाटक, संगीत और नृत्य में भाग ले।",
              "ka": "ನಾಟಕ, ಸಂಗೀತ ಮತ್ತು ನೃತ್ಯದಂತಹ ಕ್ರೀಡೆಗಳು ಮತ್ತು ಕ್ಲಬ್‌ಗಳಲ್ಲಿ ಭಾಗವಹಿಸುವುದು",
              "te": "నాటకం, సంగీతం మరియు డ్యాన్స్ వంటి క్రీడలు మరియు క్లబ్‌లలో పాల్గొనడం.",
              "or": "ନାଟକ, ସଙ୍ଗୀତ ଏବଂ ନୃତ୍ୟ ପରି କ୍ରୀଡା ଏବଂ କ୍ଲବରେ ଅଂଶଗ୍ରହଣ କରନ୍ତୁ |",
              "as": "নাটক, সংগীত আৰু নৃত্যৰ দৰে ক্ৰীড়া আৰু ক্লাবত অংশগ্ৰহণ কৰা।",
              "gu": "નાટક, સંગીત અને નૃત્ય જેવી રમતો અને ક્લબમાં ભાગ લો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Visiting National Intelligence Agency for training in areas of Intelligence like Secret Enquiry, Cover and Alibi",
              "hi": "गुप्त पूछताछ, कवर और एलीबाई जैसे खुफिया क्षेत्रों में प्रशिक्षण के लिए राष्ट्रीय खुफिया एजेंसी का दौरा करना",
              "ka": "ರಹಸ್ಯ ವಿಚಾರಣೆ, ಕವರ್ ಮತ್ತು ಅಲಿಬಿಯಂತಹ ಗುಪ್ತಚರ ಕ್ಷೇತ್ರಗಳಲ್ಲಿ ತರಬೇತಿಗಾಗಿ ರಾಷ್ಟ್ರೀಯ ಗುಪ್ತಚರ ಸಂಸ್ಥೆಗೆ ಭೇಟಿ ನೀಡುವುದು",
              "te": "సీక్రెట్ ఎంక్వైరీ, కవర్ మరియు అలీబి వంటి ఇంటెలిజెన్స్ విభాగాలలో శిక్షణ కోసం నేషనల్ ఇంటెలిజెన్స్ ఏజెన్సీని సందర్శించడం",
              "or": "ଗୁପ୍ତ ଅନୁସନ୍ଧାନ, କଭର ଏବଂ ଆଲିବୀ ପରି ଇଣ୍ଟେଲିଜେନ୍ସ କ୍ଷେତ୍ରରେ\n  ତାଲିମ ପାଇଁ ନ୍ୟାସନାଲ ଇଣ୍ଟେଲିଜେନ୍ସ ଏଜେନ୍ସି ପରିଦର୍ଶନ |",
              "as": "গোপন অনুসন্ধান, আৱৰণ আৰু এলিবিৰ দৰে চোৰাংচোৱা ক্ষেত্ৰত প্ৰশিক্ষণৰ বাবে ৰাষ্ট্ৰীয় চোৰাংচোৱা সংস্থালৈ যোৱা",
              "gu": "ગુપ્ત તપાસ, કવર અને અલીબી જેવા ગુપ્તચર ક્ષેત્રોમાં તાલીમ માટે રાષ્ટ્રીય ગુપ્તચર એજન્સીની મુલાકાત લેવી"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_3",
              "hi": "D2_2_3",
              "ka": "D2_2_3",
              "te": "D2_2_3",
              "or": "D2_2_3",
              "as": "D2_2_3",
              "gu": "D2_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "As a part of their training, they also take a trip to the USA for ten days to meet different experts in accountancy and taxation, who demonstrate the newest approaches and technologies to make their work more efficient.",
              "hi": "अपने प्रशिक्षण के एक भाग में, वे अकाउंटेंसी और कराधान में विभिन्न विशेषज्ञों से मिलने के लिए दस दिनों के लिए यूएसए की यात्रा भी करता है, जो अपने काम को और अधिक कुशल बनाने के लिए नवीनतम दृष्टिकोण और तकनीकों का प्रदर्शन करते हैं।",
              "ka": "ಅವರ ತರಬೇತಿಯ ಭಾಗವಾಗಿ, ಅವರು ತಮ್ಮ ಕೆಲಸವನ್ನು ಹೆಚ್ಚು ಪರಿಣಾಮಕಾರಿಯಾಗಿ ಮಾಡಲು ಹೊಸ ವಿಧಾನಗಳು ಮತ್ತು ತಂತ್ರಜ್ಞಾನಗಳನ್ನು ಪ್ರದರ್ಶಿಸುವ ಅಕೌಂಟೆನ್ಸಿ ಮತ್ತು ತೆರಿಗೆಯಲ್ಲಿ ವಿವಿಧ ತಜ್ಞರನ್ನು ಭೇಟಿ ಮಾಡಲು ಹತ್ತು ದಿನಗಳ ಕಾಲ USA ಗೆ ಪ್ರವಾಸ ಕೈಗೊಳ್ಳುತ್ತಾರೆ.",
              "te": "వారి శిక్షణలో భాగంగా, అకౌంటెన్సీ మరియు టాక్సేషన్‌ లో నిపుణులను కలిసి వారు తమ పనిని మరింత సమర్థవంతంగా చేయడానికి సరికొత్త విధానాలు మరియు సాంకేతికతల గురించి నేర్చుకోవడానికి పది రోజుల పాటు USA ట్రిప్ కి వెళ్తారు.",
              "or": "ସେମାନଙ୍କ ପ୍ରଶିକ୍ଷଣର ଏକ ଅଂଶ ଭାବରେ, ସେମାନେ ଆକାଉଣ୍ଟାଣ୍ଟି \n ଏବଂ ଟ୍ୟାକ୍ସରେ ବିଭିନ୍ନ ବିଶେଷଜ୍ଞଙ୍କୁ ଭେଟିବା ପାଇଁ ଦଶ ଦିନ ପାଇଁ ଆମେରିକା ଯାତ୍ରା ମଧ୍ୟ କରନ୍ତି, ଯେଉଁମାନେ ସେମାନଙ୍କର କାର୍ଯ୍ୟକୁ ଅଧିକ କ୍ରିୟାଶୀଳ କରିବା ପାଇଁ ନୂତନ ଆଭିମୁଖ୍ୟ ଏବଂ ପ୍ରଯୁକ୍ତିବିଦ୍ୟା ପ୍ରଦର୍ଶନ କରନ୍ତି |",
              "as": "তেওঁলোকৰ প্ৰশিক্ষণৰ অংশ হিচাপে, তেওঁলোকে একাউণ্টেন্সি আৰু কৰৰ বিভিন্ন বিশেষজ্ঞক লগ কৰিবলৈ দহ দিনৰ বাবে আমেৰিকা যুক্তৰাজ্য ভ্ৰমণ কৰে, যিয়ে তেওঁলোকৰ কাম অধিক দক্ষ কৰাৰ বাবে নতুন পদ্ধতি আৰু প্ৰযুক্তি প্ৰদৰ্শন কৰে।",
              "gu": "તેમની તાલીમના ભાગ રૂપે, તેઓ એકાઉન્ટન્સી અને ટેક્સેશનના વિવિધ નિષ્ણાતોને મળવા માટે દસ દિવસ માટે USA ની સફર પણ કરે છે, જેઓ તેમના કાર્યને વધુ કાર્યક્ષમ બનાવવા માટે નવા અભિગમો અને તકનીકોનું પ્રદર્શન કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_4",
              "hi": "D2_2_4",
              "ka": "D2_2_4",
              "te": "D2_2_4",
              "or": "D2_2_4",
              "as": "D2_2_4",
              "gu": "D2_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Through the training, they also meet different officers. One of the Police officers, Shamsher Singh, tells them about how they have worked with many talented IRS officers to stop black money and illegal sources of income.",
              "hi": "प्रशिक्षण के माध्यम से, वे विभिन्न अधिकारियों से भी मिलते हैं। पुलिस अधिकारियों में से एक, शमशेर सिंह ने उन्हें बताया कि कैसे उन्होंने काले धन और आय के अवैध स्रोतों को रोकने के लिए कई प्रतिभाशाली आईआरएस अधिकारियों के साथ  काम किया है।",
              "ka": "ತರಬೇತಿಯ ಮೂಲಕ ಅವರು ವಿವಿಧ ಅಧಿಕಾರಿಗಳನ್ನು ಭೇಟಿ ಮಾಡುತ್ತಾರೆ. ಪೊಲೀಸ್ ಅಧಿಕಾರಿಗಳಲ್ಲಿ ಒಬ್ಬರಾದ ಶಂಶೇರ್ ಸಿಂಗ್ ಅವರು ಕಪ್ಪು ಹಣ ಮತ್ತು ಅಕ್ರಮ ಆದಾಯದ ಮೂಲಗಳನ್ನು ತಡೆಯಲು ಅನೇಕ ಪ್ರತಿಭಾವಂತ ಐಆರ್‌ಎಸ್ ಅಧಿಕಾರಿಗಳೊಂದಿಗೆ ಹೇಗೆ ಕೆಲಸ ಮಾಡಿದ್ದಾರೆ ಎಂಬುದರ ಕುರಿತು ತಿಳಿಸುತ್ತಾರೆ.",
              "te": "శిక్షణ మొత్తంలో, వారు వివిధ అధికారులను కూడా కలుస్తారు. పోలీసు అధికారులలో ఒకరైన షంషేర్ సింగ్, నల్లధనం మరియు అక్రమ ఆదాయ వనరులను అరికట్టడానికి చాలా మంది ప్రతిభావంతులైన IRS అధికారులతో కలిసి ఎలా పనిచేశారో వారికి చెబుతాడు.",
              "or": "ତାଲିମ ମାଧ୍ୟମରେ ସେମାନେ ବିଭିନ୍ନ ଅଧିକାରୀଙ୍କୁ ମଧ୍ୟ ଭେଟିଥାନ୍ତି | \n କଳା ଅଧିକାରୀ ତଥା ବେଆଇନ ରୋଜଗାରର ଉତ୍ସକୁ ରୋକିବା ପାଇଁ ସେମାନେ ବହୁ ପ୍ରତିଭାବାନ ଆଇଆରଏସ ଅଧିକାରୀଙ୍କ ସହ କିପରି କାର୍ଯ୍ୟ କରିଛନ୍ତି ସେ ବିଷୟରେ ଜଣେ ପୋଲିସ ଅଧିକାରୀ ଶ୍ୟାମଶର ସିଂ ସେମାନଙ୍କୁ କୁହନ୍ତି।",
              "as": "প্ৰশিক্ষণৰ সময়তে, তেওঁলোকে বিভিন্ন বিষয়াকো লগ কৰে। এজন আৰক্ষী বিষয়া, চামছেৰ সিঙে তেওঁলোকক কয় যে তেওঁলোকে কেনেদৰে বহুতো প্ৰতিভাশালী IRS বিষয়াৰ সৈতে ক'লা ধন আৰু উপাৰ্জনৰ অবৈধ উৎস বন্ধ কৰিবলৈ কাম কৰিছে।",
              "gu": "તાલીમ દ્વારા તેઓ જુદા જુદા અધિકારીઓને પણ મળે છે. એક પોલીસ અધિકારી, શમશેર સિંહ, તેમને જણાવે છે કે કેવી રીતે તેઓએ કાળા નાણાં અને આવકના ગેરકાયદેસર સ્ત્રોતોને રોકવા માટે ઘણા પ્રતિભાશાળી IRS અધિકારીઓ સાથે કામ કર્યું છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_4b",
              "hi": "D2_2_4B",
              "ka": "D2_2_4b",
              "te": "D2_2_4b",
              "or": "D2_2_4b",
              "as": "D2_2_4b",
              "gu": "D2_2_4b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav wants to know more about Shamsher Singh's experiences and talk to him after the session.",
              "hi": "माधव शमशेर सिंह के अनुभवों के बारे में और जानना चाहते हैं और सत्र के बाद उससे बात करते हैं।",
              "ka": "ಮಾಧವ್ ಶಂಶೇರ್ ಸಿಂಗ್ ಅವರ ಅನುಭವಗಳ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಬಯಸುತ್ತಾನೆ ಮತ್ತು ಅಧಿವೇಶನದ ನಂತರ ಅವರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾನೆ.",
              "te": "షంషేర్ సింగ్ అనుభవాల గురించి మరింత తెలుసుకోవాలని మరియు సెషన్ తర్వాత అతనితో మాట్లాడాలని మాధవ్ అనుకుంటాడు.",
              "or": "ମାଧବ ସମସେର ସିଂଙ୍କ ଅନୁଭୂତି ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ଏବଂ\n  ଅଧିବେଶନ ପରେ ତାଙ୍କ ସହ କଥା ହେବାକୁ ଚାହାଁନ୍ତି |",
              "as": "মাধৱে চামছেৰ সিঙৰ অভিজ্ঞতাৰ বিষয়ে অধিক জানিব বিচাৰে আৰু অধিৱেশনৰ পিছত তেওঁৰ সৈতে কথা পাতিব বিচাৰে।",
              "gu": "માધવ શમશેર સિંહના અનુભવો વિશે વધુ જાણવા માંગે છે અને સત્ર પછી તેમની સાથે વાત કરવા માંગે છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_5",
              "hi": "D2_2_5",
              "ka": "D2_2_5",
              "te": "D2_2_5",
              "or": "D2_2_5",
              "as": "D2_2_5",
              "gu": "D2_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shamsher Singh",
              "hi": "शमशेर सिंह",
              "ka": "ಶಂಶೇರ್ ಸಿಂಗ್",
              "te": "షంషేర్ సింగ్",
              "or": "ସମସର ସିଂ",
              "as": "চামছেৰ সিং",
              "gu": "શમશેર સિંહ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Shamsher Singh",
              "hi": "शमशेर सिंह",
              "ka": "ಶಂಶೇರ್ ಸಿಂಗ್",
              "te": "షంషేర్ సింగ్",
              "or": "ସମସେର ସିଂ",
              "as": "চামছেৰ সিং",
              "gu": "શમશેર સિંહ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.4.label",
            "value": {
              "en": "Madhav",
              "hi": "माधव",
              "ka": "ಮಾಧವ್",
              "te": "మాధవ్",
              "or": "ମାଧବ",
              "as": "মাধৱ",
              "gu": "માધવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Sir, I had read about the raid you supported back in 2013. That sounded like a risky operation.",
              "hi": "महोदय, मैंने 2013 में आपके द्वारा समर्थित छापे के बारे में पढ़ा था। यह एक जोखिम भरे संचालन की तरह लग रहा था।",
              "ka": "ಸರ್, ನೀವು 2013 ರಲ್ಲಿ ಬೆಂಬಲಿಸಿದ ದಾಳಿಯ ಬಗ್ಗೆ ನಾನು ಓದಿದ್ದೆ. ಅದು ಅಪಾಯಕಾರಿ ಕಾರ್ಯಾಚರಣೆಯಂತೆ ತೋರುತ್ತಿದೆ.",
              "te": "సర్, 2013లో మీరు సపోర్ట్ చేసిన రైడ్ గురించి నేను చదివాను. అది రిస్క్ తో కూడిన ఆపరేషన్ లా అనిపించింది.",
              "or": "ସାର୍, ମୁଁ ୨୦୧୩ ରେ ଆପଣ ସମର୍ଥନ କରିଥିବା ଆକ୍ରମଣ ବିଷୟରେ \n ପଢିଥିଲି | ଏହା ଏକ ବିପଦପୂର୍ଣ୍ଣ ଅପରେସନ୍ ପରି ଶୁଣାଗଲା |",
              "as": "ছাৰ, মই ২০১৩ চনত আপুনি সমৰ্থন কৰা অভিযানৰ বিষয়ে পঢ়িছিলোঁ। এইটো এটা বিপদজনক অপাৰেচনৰ দৰে লাগিছিল।",
              "gu": "સાહેબ, મેં 2013 માં આપેલા દરોડા વિશે વાંચ્યું હતું. તે જોખમી ઓપરેશન જેવું લાગ્યું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Oh! The 2013 case! Yes! It was a tough case to solve. But the officers working on it were so accurate with their work that we managed.",
              "hi": "ओह! 2013 का मामला! हां! मामला सुलझाना मुश्किल था। लेकिन इस पर काम करने वाले अधिकारी अपने काम में इतने सटीक थे कि हम कामयाब हो गए।",
              "ka": "ಓಹ್! 2013ರ ಪ್ರಕರಣ! ಹೌದು! ಇದು ಪರಿಹರಿಸಲು ಕಠಿಣ ಪ್ರಕರಣವಾಗಿತ್ತು. ಆದರೆ ಅದರಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಅಧಿಕಾರಿಗಳು ತಮ್ಮ ಕೆಲಸದಲ್ಲಿ ಎಷ್ಟು ನಿಖರವಾಗಿದ್ದರೆಂದರೆ ಅದನ್ನು ನಾವು ನಿರ್ವಹಿಸಾಲು ಸಾಧ್ಯವಾಯಿತು.",
              "te": "ఓ! 2013 కేసు! అవును! ఇది పరిష్కరించడానికి కఠినమైన కేసు. కానీ దానిపై పని చేసే అధికారులు వారి పని అక్యురేట్ గా చేశారు, కాబట్టి మేము దాన్ని చేయగలిగాము.",
              "or": "ଓଃ !୨୦୧୩ ମାମଲା! ହଁ! ଏହାର ସମାଧାନ କରିବା ଏକ କଠିନ ମାମଲା\n  ଥିଲା | କିନ୍ତୁ ଏଥିରେ କାର୍ଯ୍ୟ କରୁଥିବା ଅଧିକାରୀମାନେ ସେମାନଙ୍କ କାର୍ଯ୍ୟ ସହିତ ଏତେ ସଠିକ୍ ଥିଲେ ଯେ ଆମେ ପରିଚାଳନା କରିଥିଲୁ |",
              "as": "অহ! ২০১৩ৰ ঘটনা! হয়! সমাধান কৰাটো এটা কঠিন ঘটনা আছিল। কিন্তু ইয়াৰ ওপৰত কাম কৰা বিষয়াসকল তেওঁলোকৰ কামৰ সৈতে ইমান সঠিক আছিল যে আমি পৰিচালনা কৰিছিলোঁ।",
              "gu": "ઓહ! 2013નો કેસ! હા! તે ઉકેલવા માટે એક અઘરો કેસ હતો. પરંતુ તેના પર કામ કરતા અધિકારીઓ તેમના કામ સાથે એટલા સચોટ હતા કે અમે તેનું સંચાલન કર્યું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "That's amazing. We read about this case study to understand the nuances of a tax evasion case. I believe that the collaboration between the IRS and the police department played a critical role in the raid's success.",
              "hi": "वह आश्चर्यजनक है। हमने कर चोरी के मामले की बारीकियों को समझने के लिए इस केस स्टडी को पढ़ा। मेरा मानना ​​है कि आईआरएस और पुलिस विभाग के बीच सहयोग ने छापे की सफलता में महत्वपूर्ण भूमिका निभाई।",
              "ka": "ಅದು ಆಶ್ಚರ್ಯಕರ. ತೆರಿಗೆ ವಂಚನೆ ಪ್ರಕರಣದ ಸೂಕ್ಷ್ಮ ವ್ಯತ್ಯಾಸಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ನಾವು ಈ ಪ್ರಕರಣದ ಅಧ್ಯಯನದ ಬಗ್ಗೆ ಓದುತ್ತೇವೆ. IRS ಮತ್ತು ಪೊಲೀಸ್ ಇಲಾಖೆಯ ನಡುವಿನ ಸಹಯೋಗವು ದಾಳಿಯ ಯಶಸ್ಸಿನಲ್ಲಿ ನಿರ್ಣಾಯಕ ಪಾತ್ರವನ್ನು ವಹಿಸಿದೆ ಎಂದು ನಾನು ನಂಬುತ್ತೇನೆ.",
              "te": "ఆశ్చర్యంగా ఉంది. పన్ను ఎగవేత కేసు యొక్క సూక్ష్మ వివరాలను అర్థం చేసుకోవడానికి మేము ఈ కేస్ స్టడీ గురించి చదువుతాము. IRS మరియు పోలీసు శాఖ మధ్య సహకారం రైడ్ విజయంలో కీలక పాత్ర పోషింస్తుందని నేను నమ్ముతున్నాను.",
              "or": "ତାହା ଆଶ୍ଚର୍ଯ୍ୟଜନକ | ଟିକସ ଫାଙ୍କିବା ମାମଲାର ନ୍ୟୁଆନ୍ସ ବୁଝିବା ପାଇଁ\n  ଆମେ ଏହି କେସ୍ ଷ୍ଟଡି ବିଷୟରେ ପଢୁ| ମୋର ବିଶ୍ୱାସ ଯେ ଏହି ଆକ୍ରମଣରେ ଆଇଆରଏସ ଏବଂ ପୋଲିସ ବିଭାଗ ମଧ୍ୟରେ ସହଯୋଗ ଏକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ଭୂମିକା ଗ୍ରହଣ କରିଛି।",
              "as": "সেইটো আকৰ্ষণীয়। আমি কৰ ফাঁকিৰ গোচৰৰ সূক্ষ্মতা বুজিবলৈ এই কেছ অধ্যয়নটোৰ বিষয়ে পঢ়িছিলোঁ। মই বিশ্বাস কৰোঁ যে IRS আৰু আৰক্ষী বিভাগৰ মাজত সহযোগিতাই অভিযানটোৰ সফলতাত গুৰুত্বপূৰ্ণ ভূমিকা পালন কৰিছিল।",
              "gu": "તે અદ્ભુત છે. કરચોરીના કેસની ઘોંઘાટ સમજવા માટે અમે આ કેસ સ્ટડી વિશે વાંચીએ છીએ. હું માનું છું કે IRS અને પોલીસ વિભાગ વચ્ચેના સહયોગે દરોડાની સફળતામાં મહત્ત્વપૂર્ણ ભૂમિકા ભજવી હતી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "I'm glad you shared that, Madhav. Only when we all work together can we win the fight against corruption.",
              "hi": "मुझे खुशी है कि आपने इसे साझा किया है, माधव। जब हम सभी एक साथ काम करते हैं केवल तभी हम भ्रष्टाचार के खिलाफ लड़ाई जीत सकते हैं।",
              "ka": "ನೀವು ಅದನ್ನು ಹಂಚಿಕೊಂಡಿದ್ದಕ್ಕೆ ನನಗೆ ಸಂತೋಷವಾಗಿದೆ, ಮಾಧವ್. ಎಲ್ಲರೂ ಒಗ್ಗಟ್ಟಾಗಿ ಕೆಲಸ ಮಾಡಿದಾಗ ಮಾತ್ರ ಭ್ರಷ್ಟಾಚಾರದ ವಿರುದ್ಧದ ಹೋರಾಟದಲ್ಲಿ ಜಯಗಳಿಸಲು ಸಾಧ್ಯ.",
              "te": "మీరు అది తెలిపినందుకు నేను సంతోషిస్తున్నాను, మాధవ్. అందరం కలిసికట్టుగా పనిచేసినప్పుడే అవినీతిపై పోరాటంలో విజయం సాధించగలుగుతాం.",
              "or": "ମୁଁ ଖୁସି ଯେ ତୁମେ ସେୟାର୍ କରିଛ, ମାଧବ | ଯେତେବେଳେ ଆମେ\n  ସମସ୍ତେ ଏକାଠି କାମ କରିବା ସେତେବେଳେ ଆମେ ଦୁର୍ନୀତି ବିରୋଧରେ ଲଢେଇ କରିପାରିବା |",
              "as": "মই আনন্দিত যে তুমি সেইটো ভাগ-বতৰা কৰিছা, মাধৱ। কেৱল যেতিয়া আমি সকলোৱে একেলগে কাম কৰোঁ তেতিয়াহে আমি দুৰ্নীতিৰ বিৰুদ্ধে যুঁজত জয়ী হ'ব পাৰোঁ।",
              "gu": "મને આનંદ છે કે તમે તે બતાવ્યુ, માધવ. આપણે બધા સાથે મળીને કામ કરીએ તો જ આપણે ભ્રષ્ટાચાર સામેની લડાઈ જીતી શકીશું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.4.text",
            "value": {
              "en": "Sir, I'd like to learn a lot more from you. Can I get in touch later?",
              "hi": "महोदय, मैं आपसे बहुत कुछ सीखना चाहता हूं। क्या मैं बाद में संपर्क कर सकता हूं?",
              "ka": "ಸರ್, ನಾನು ನಿಮ್ಮಿಂದ ಇನ್ನೂ ಹೆಚ್ಚಿನದನ್ನು ಕಲಿಯಲು ಬಯಸುತ್ತೇನೆ. ನಾನು ನಂತರ ನಿಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಬಹುದೇ?",
              "te": "సర్, నేను మీ నుండి ఇంకా చాలా నేర్చుకోవాలనుకుంటున్నాను. నేను తర్వాత మిమ్మల్ని కలవచ్చా?",
              "or": "ସାର୍, ମୁଁ ଆପଣଙ୍କଠାରୁ ବହୁତ କିଛି ଶିଖିବାକୁ ଚାହେଁ | ମୁଁ ପରେ \n ଯୋଗାଯୋଗ କରିପାରିବି କି?",
              "as": "ছাৰ, মই আপোনাৰ পৰা আৰু বহুত শিকিব বিচাৰোঁ। মই পিছত যোগাযোগ কৰিব পাৰিম নেকি?",
              "gu": "સાહેબ, હું તમારી પાસેથી ઘણું શીખવા માંગુ છું. શું હું પછીથી સંપર્ક કરી શકું?"
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_6",
              "hi": "D2_2_6",
              "ka": "D2_2_6",
              "te": "D2_2_6",
              "or": "D2_2_6",
              "as": "D2_2_6",
              "gu": "D2_2_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The IRS training provides a lot of opportunities to make connections and networks. They meet many officers and seniors who guide them through their training.",
              "hi": "आईआरएस प्रशिक्षण संपर्क और नेटवर्क बनाने के लिए अनेक अवसर देता है। वह अनेक अधिकारियों और वरिष्ठ नागरिकों से मिलता है जो उसे प्रशिक्षण देकर मार्गदर्शन करते हैं।",
              "ka": "IRS ತರಬೇತಿಯು ಸಂಪರ್ಕಗಳು ಮತ್ತು ನೆಟ್‌ವರ್ಕ್‌ಗಳನ್ನು ಹೊಂದಲು ಸಾಕಷ್ಟು ಅವಕಾಶಗಳನ್ನು ಒದಗಿಸುತ್ತದೆ. ಅವನು ತಮ್ಮ ತರಬೇತಿಯ ಮೂಲಕ ಮಾರ್ಗದರ್ಶನ ನೀಡುವ ಅನೇಕ ಅಧಿಕಾರಿಗಳು ಮತ್ತು ಹಿರಿಯರನ್ನು ಭೇಟಿಯಾಗುತ್ತಾನೆ.",
              "te": "IRS శిక్షణ కనెక్షన్‌లు మరియు నెట్‌వర్క్‌లను చేయడానికి చాలా అవకాశాలను అందిస్తుంది. వారి శిక్షణలో వారికి గైడ్ చేయడానికి అనేక మంది అధికారులు మరియు సీనియర్లను వారు కలుస్తారు.",
              "or": "ଆଇଆରଏସ ଟ୍ରେନିଂ ସଂଯୋଗ ଏବଂ ନେଟୱାର୍କ କରିବା ପାଇଁ ଅନେକ\n  ସୁଯୋଗ ପ୍ରଦାନ କରେ | ସେମାନେ ଅନେକ ଅଧିକାରୀ ଏବଂ ସିନିୟରଙ୍କୁ ଭେଟନ୍ତି, ଯେଉଁମାନେ ସେମାନଙ୍କୁ ତାଲିମ ମାଧ୍ୟମରେ ମାର୍ଗଦର୍ଶନ କରନ୍ତି |",
              "as": "IRS প্ৰশিক্ষণে সংযোগ আৰু নেটৱৰ্ক বনোৱাৰ যথেষ্ট সুযোগ প্ৰদান কৰে। তেওঁলোকে বহুতো বিষয়া আৰু জ্যেষ্ঠক লগ পায় যিয়ে তেওঁলোকক তেওঁলোকৰ প্ৰশিক্ষণৰ জৰিয়তে পথ প্ৰদৰ্শন কৰে।",
              "gu": "IRS તાલીમ જોડાણો અને નેટવર્ક્સ બનાવવા માટે ઘણી તકો પૂરી પાડે છે. તેઓ ઘણા અધિકારીઓ અને વરિષ્ઠોને મળે છે જેઓ તેમની તાલીમ દ્વારા તેમને માર્ગદર્શન આપે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "Training",
              "hi": "प्रशिक्षण",
              "ka": "ತರಬೇತಿ",
              "te": "శిక్షణ",
              "or": "ତାଲିମ",
              "as": "প্ৰশিক্ষণ",
              "gu": "તાલીમ"
            }
          }
        ]
      },
      "scene33": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_1",
              "hi": "D3_1_1",
              "ka": "D3_1_1",
              "te": "D3_1_1",
              "or": "D3_1_1",
              "as": "D3_1_1",
              "gu": "D3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After completing his training, Madhav is posted as an Assistant Commissioner of Income Tax in Pune. It is a fast-developing city where many businesses and companies set up their operations.",
              "hi": "प्रशिक्षण पूरा करने के बाद माधव पुणे में सहायक आयकर आयुक्त के पद पर तैनात है। यह एक तेजी से विकसित होने वाला शहर है जहां अनैक व्यवसाय और कंपनियां अपना संचालन स्थापित करती हैं।",
              "ka": "ತನ್ನ ತರಬೇತಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ ನಂತರ, ಮಾಧವ್ ಪುಣೆಯಲ್ಲಿ ಆದಾಯ ತೆರಿಗೆ ಸಹಾಯಕ ಕಮಿಷನರ್ ಆಗಿ ನೇಮಕಗೊಂಡಿದ್ದಾನೆ. ಇದು ವೇಗವಾಗಿ ಅಭಿವೃದ್ಧಿ ಹೊಂದುತ್ತಿರುವ ನಗರವಾಗಿದ್ದು, ಅನೇಕ ವ್ಯವಹಾರಗಳು ಮತ್ತು ಕಂಪನಿಗಳು ತಮ್ಮ ಕಾರ್ಯಾಚರಣೆಗಳನ್ನು ಸ್ಥಾಪಿಸುತ್ತಿವೆ.",
              "te": "శిక్షణ పూర్తయిన తర్వాత, మాధవ్ పూణేలో ఆదాయపు పన్ను శాఖ అసిస్టెంట్ కమిషనర్‌గా నియమితులయ్యారు. ఇది వేగంగా అభివృద్ధి చెందుతున్న నగరం, ఇక్కడ అనేక వ్యాపారాలు మరియు కంపెనీలు తమ కార్యకలాపాలను కొనసాగిస్తాయి.",
              "or": "ତା’ର ପ୍ରଶିକ୍ଷଣ ସମାପ୍ତ କରିବା ପରେ ମାଧବ ପୁଣେରେ ଆୟକର ସହକାରୀ\n  କମିଶନର ଭାବରେ ପୋଷ୍ଟ କରାଯାଇଛି। ଏହା ଏକ ଦ୍ରୁତ ବିକାଶଶୀଳ ସହର ଯେଉଁଠାରେ ଅନେକ ବ୍ୟବସାୟ ଏବଂ କମ୍ପାନୀ ସେମାନଙ୍କର କାର୍ଯ୍ୟକଳାପ ସ୍ଥାପନ କରନ୍ତି |",
              "as": "প্ৰশিক্ষণ সম্পূৰ্ণ কৰাৰ পিছত, মাধৱক পুনেত আয়কৰ সহকাৰী আয়ুক্ত হিচাপে নিযুক্তি দিয়া হয়। ই হৈছে এক দ্ৰুত বিকশিত চহৰ য'ত বহুতো ব্যৱসায় আৰু কোম্পানীয়ে তেওঁলোকৰ কাৰ্যকলাপ স্থাপন কৰিছে।",
              "gu": "તેની તાલીમ પૂર્ણ કર્યા પછી, માધવ પૂણેમાં આવકવેરા વિભાગના આસિસ્ટન્ટ કમિશનર તરીકે પદ મળે છે. તે એક ઝડપી વિકાસશીલ શહેર છે જ્યાં ઘણા વ્યવસાયો અને કંપનીઓ તેમની કામગીરી સેટ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_2",
              "hi": "D3_1_2",
              "ka": "D3_1_2",
              "te": "D3_1_2",
              "or": "D3_1_2",
              "as": "D3_1_2",
              "gu": "D3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Because there are so many taxable activities happening in the city, IRS officers like Madhav are responsible for handling the large amount of work involved in revenue collection.",
              "hi": "चूंकि शहर में इतनी सारी कर योग्य गतिविधियां हो रही हैं, इसलिए माधव जैसे आईआरएस अधिकारी राजस्व संग्रह में शामिल बड़ी मात्रा में काम को संभालने के लिए उत्तरदायी हैं।",
              "ka": "ನಗರದಲ್ಲಿ ಹಲವಾರು ತೆರಿಗೆ ವಿಧಿಸಬಹುದಾದ ಚಟುವಟಿಕೆಗಳು ನಡೆಯುತ್ತಿರುವುದರಿಂದ, ಆದಾಯ ಸಂಗ್ರಹಣೆಯಲ್ಲಿ ತೊಡಗಿರುವ ದೊಡ್ಡ ಪ್ರಮಾಣದ ಕೆಲಸವನ್ನು ನಿರ್ವಹಿಸುವ ಜವಾಬ್ದಾರಿಯನ್ನು ಮಾಧವ್ ಅವರಂತಹ IRS ಅಧಿಕಾರಿಗಳು ಹೊಂದಿದ್ದಾರೆ.",
              "te": "నగరంలో పన్ను విధించాల్సిన కార్యకలాపాలు చాలా జరుగుతున్నందున, ఆదాయ సేకరణలో ఉన్న ఎక్కువ పనిని హాండిల్ చేయడానికి మాధవ్ వంటి IRS అధికారులు బాధ్యత వహిస్తారు.",
              "or": "କାରଣ ସହରରେ ଅନେକ ଟିକସଯୋଗ୍ୟ କାର୍ଯ୍ୟକଳାପ ଘଟୁଛି, ରାଜସ୍ୱ\n  ସଂଗ୍ରହରେ ଜଡିତ ବହୁ ପରିମାଣର କାର୍ଯ୍ୟ ପରିଚାଳନା ପାଇଁ ମାଧବଙ୍କ ପରି ଆଇଆରଏସ ଅଧିକାରୀମାନେ ଦାୟୀ |",
              "as": "যিহেতু চহৰখনত বহুতো কৰযোগ্য কাৰ্যকলাপ হৈ আছে, মাধৱৰ দৰে IRS বিষয়াসকল ৰাজহ সংগ্ৰহৰ সৈতে জড়িত বৃহৎ পৰিমাণৰ কাম চম্ভালিবলৈ দায়বদ্ধ।",
              "gu": "કારણ કે શહેરમાં ઘણી બધી કરપાત્ર પ્રવૃત્તિઓ થઈ રહી છે, માધવ જેવા IRS અધિકારીઓ મહેસૂલ વસૂલાતમાં સંકળાયેલા મોટા પ્રમાણમાં કામ સંભાળવા માટે જવાબદાર છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_4",
              "hi": "D3_1_4",
              "ka": "D3_1_4",
              "te": "D3_1_4",
              "or": "D3_1_4",
              "as": "D3_1_4",
              "gu": "D3_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav, along with his team, is looking into the income tax returns of businesses that reported huge profits last year. His responsibilities involve ensuring that the big industries follow all income tax rules.",
              "hi": "माधव, अपनी टीम के साथ, उन व्यवसायों के आयकर रिटर्न की तलाश में है जिन्होंने पिछले साल भारी मुनाफा कमाया है। उसकी जिम्मेदारियों में यह सुनिश्चित करना शामिल है कि बड़े उद्योग सभी आयकर नियमों का पालन करते हैं।",
              "ka": "ಮಾಧವ್ ತನ್ನ ತಂಡದೊಂದಿಗೆ ಕಳೆದ ವರ್ಷ ಭಾರೀ ಲಾಭ ಗಳಿಸಿದ ವ್ಯವಹಾರಗಳ ಆದಾಯ ತೆರಿಗೆ ರಿಟರ್ನ್ಸ್‌ಗಳನ್ನು ಪರಿಶೀಲಿಸುತ್ತಿದ್ದಾನೆ. ದೊಡ್ಡ ಕೈಗಾರಿಕೆಗಳು ಎಲ್ಲಾ ಆದಾಯ ತೆರಿಗೆ ನಿಯಮಗಳನ್ನು ಅನುಸರಿಸುವುದನ್ನು ಖಾತ್ರಿಪಡಿಸುವುದು ಅವರ ಜವಾಬ್ದಾರಿಗಳಲ್ಲಿ ಒಂದಾಗಿರುತ್ತದೆ.",
              "te": "మాధవ్ తన బృందంతో కలిసి గతేడాది భారీ లాభాలను ఆర్జించిన వ్యాపారాల ఆదాయపు పన్ను రిటర్న్‌లను పరిశీలిస్తున్నారు. పెద్ద పరిశ్రమలు అన్ని ఆదాయపు పన్ను నియమాలను అనుసరిస్తున్నాయని నిర్ధారించుకోవడం అతని బాధ్యతలలో ఒకటి.",
              "or": "ମାଧବ ତାଙ୍କ ଦଳ ସହିତ ବ୍ୟବସାୟର ଆୟକର ରିଟର୍ଣ୍ଣ ଉପରେ ଦୃଷ୍ଟି \n ରଖିଛନ୍ତି ଯାହା ଗତ ବର୍ଷ ବିପୁଳ ଲାଭ ବିଷୟରେ ଜଣାଇଥିଲା | ତାଙ୍କର ଦାୟିତ୍ଵ ନିଶ୍ଚିତ କରେ ଯେ ବଡ ଶିଳ୍ପଗୁଡିକ ସମସ୍ତ ଆୟକର ନିୟମ ପାଳନ କରନ୍ତି |",
              "as": "মাধৱে, তেওঁৰ দলৰ সৈতে, ব্যৱসায়ৰ আয়কৰ ৰিটাৰ্ণৰ ওপৰত দৃষ্টি ৰাখিছে যিয়ে যোৱা বছৰ বৃহৎ লাভৰ প্ৰতিবেদন দিছিল। তেওঁৰ দায়িত্ববোৰত ডাঙৰ উদ্যোগবোৰে যাতে সকলো আয়কৰ নিয়ম অনুসৰণ কৰে সেইটো নিশ্চিত কৰাটো অন্তৰ্ভুক্ত আছে।",
              "gu": "માધવ, તેમની ટીમ સાથે, ગયા વર્ષે જંગી નફો નોંધાવનાર વ્યવસાયોના આવકવેરા રિટર્નની તપાસ કરી રહ્યા છે. તેમની જવાબદારીઓમાં એ સુનિશ્ચિત કરવાનો સમાવેશ થાય છે કે મોટા ઉદ્યોગો આવકવેરાના તમામ નિયમોનું પાલન કરે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_4",
              "hi": "D3_1_4",
              "ka": "D3_1_4",
              "te": "D3_1_4",
              "or": "D3_1_4",
              "as": "D3_1_4",
              "gu": "D3_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "If there are cases where businesses or industries don't follow the rules, then Madhav and his team solve those cases by calling them and taking statements, examining the statements and coming to judgements.",
              "hi": "यदि ऐसे मामले हैं जहां व्यवसाय या उद्योग नियमों का पालन नहीं करते हैं, तो माधव और उसकी टीम उनको बुलाकर बयान लेती है, और बयानों की जांच करके निर्णय लेकर उन मामलों को हल करती है।",
              "ka": "ಉದ್ಯಮಗಳು ಅಥವಾ ವಹಿವಾಟುಗಳು ನಿಯಮಗಳನ್ನು ಅನುಸರಿಸದ ಪ್ರಕರಣಗಳಿದ್ದರೆ, ಮಾಧವ್ ಮತ್ತು ಅವರ ತಂಡವು ಅವರನ್ನು ಕರೆದು ಹೇಳಿಕೆಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುವ ಮೂಲಕ, ಹೇಳಿಕೆಗಳನ್ನು ಪರಿಶೀಲಿಸಿ ಮತ್ತು ತೀರ್ಪು ನೀಡುವ ಮೂಲಕ ಆ ಪ್ರಕರಣಗಳನ್ನು ಪರಿಹರಿಸುತ್ತದೆ.",
              "te": "వ్యాపారాలు లేదా పరిశ్రమలు నిబంధనలను పాటించని కేసులు ఉంటే, మాధవ్ మరియు అతని బృందం వారిని పిలిచి స్టేట్‌మెంట్‌లు తీసుకోవడం, స్టేట్‌మెంట్‌లను పరిశీలించడం మరియు తీర్పు ఇవ్వడం ద్వారా ఆ కేసులను పరిష్కరిస్తుంది.",
              "or": "ଯଦି ଏପରି କିଛି ମାମଲା ଅଛି ଯେଉଁଠାରେ ବ୍ୟବସାୟ କିମ୍ବା ଶିଳ୍ପଗୁଡିକ\n  ନିୟମ ପାଳନ କରୁନାହାଁନ୍ତି, ତେବେ ମାଧବ ଏବଂ ତାଙ୍କ ଦଳ ସେମାନଙ୍କୁ ଡାକିବା ଏବଂ ବିବୃତ୍ତି ଗ୍ରହଣ କରିବା, ବିବୃତ୍ତି ଯାଞ୍ଚ କରିବା ଏବଂ ବିଚାରକୁ ଆସିବା ଦ୍ୱାରା ସମାଧାନ କରନ୍ତି |",
              "as": "যদি এনে কিছুমান ঘটনা ঘটে য'ত ব্যৱসায় বা উদ্যোগবোৰে নিয়ম অনুসৰণ নকৰে, তেনেহ'লে মাধৱ আৰু তেওঁৰ দলে সেই গোচৰবোৰ ফোন কৰি আৰু বিবৃতি লৈ, বিবৃতিবোৰ পৰীক্ষা কৰি সমাধান কৰে।",
              "gu": "જો એવા કિસ્સાઓ છે કે જ્યાં વ્યવસાયો અથવા ઉદ્યોગો નિયમોનું પાલન કરતા નથી, તો માધવ અને તેમની ટીમ તેમને કૉલ કરીને અને નિવેદનો લઈને, નિવેદનોની તપાસ કરીને અને ચુકાદાઓ પર આવીને તે કેસોનો ઉકેલ લાવે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_2_1",
              "hi": "D3_2_1",
              "ka": "D3_2_1",
              "te": "D3_2_1",
              "or": "D3_2_1",
              "as": "D3_2_1",
              "gu": "D3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "In his first year of work, Madhav's department, with the help of the police department, recovers a lot of black money and diverts it back to the government.",
              "hi": "अपने पहले वर्ष के काम में, माधव के विभाग ने पुलिस विभाग की सहायता से, बहुत सारे काले धन की वसूली की और इसे वापस सरकार को दिया।",
              "ka": "ಕೆಲಸ ಮಾಡಿದ ಮೊದಲ ವರ್ಷದಲ್ಲಿಯೇ ಮಾಧವ್ ಅವರ ಇಲಾಖೆಯು ಪೊಲೀಸ್ ಇಲಾಖೆಯ ನೆರವಿನಿಂದ ಸಾಕಷ್ಟು ಕಪ್ಪುಹಣವನ್ನು ಮರಳಿ ಸರ್ಕಾರಕ್ಕೆ ವರ್ಗಾಯಿಸುತ್ತದೆ.",
              "te": "పని చేసిన మొదటి సంవత్సరంలోనే, మాధవ్ డిపార్ట్‌మెంట్, పోలీసు డిపార్ట్‌మెంట్ సహాయంతో, చాలా నల్లధనాన్ని వెలికితీసి, దానిని తిరిగి ప్రభుత్వానికి తిరిగి ఇస్తుంది.",
              "or": "ତାଙ୍କର ପ୍ରଥମ ବର୍ଷର କାମରେ, ମାଧବ ବିଭାଗ, ପୋଲିସ ବିଭାଗର ସହାୟତାରେ,\n  ବହୁ କଳା ଟଙ୍କା ପୁନରୁଦ୍ଧାର କରି ଏହାକୁ ସରକାରଙ୍କ ନିକଟକୁ ଫେରାଇ ଆଣିଥାଏ |",
              "as": "তেওঁৰ প্ৰথম বছৰৰ কামত, মাধৱৰ বিভাগে আৰক্ষী বিভাগৰ সহায়ত যথেষ্ট ক'লা ধন উদ্ধাৰ কৰে আৰু ইয়াক পুনৰ চৰকাৰৰ ওচৰলৈ ঘূৰাই দিয়ে।",
              "gu": "તેમના કામના પ્રથમ વર્ષમાં, માધવનો વિભાગ, પોલીસ વિભાગની મદદથી, ઘણું કાળું નાણું મેળવે છે અને તેને સરકારમાં પાછું આપે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_2_2",
              "hi": "D3_2_2",
              "ka": "D3_2_2",
              "te": "D3_2_2",
              "or": "D3_2_2",
              "as": "D3_2_2",
              "gu": "D3_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The revenue they collect is used for the development of the country.",
              "hi": "उनके द्वारा एकत्र किए गए राजस्व का उपयोग देश के विकास के लिए किया जाता है।",
              "ka": "ಅವರು ಸಂಗ್ರಹಿಸುವ ಆದಾಯವನ್ನು ದೇಶದ ಅಭಿವೃದ್ಧಿಗೆ ಬಳಸಲಾಗುತ್ತದೆ.",
              "te": "వారు కలెక్ట్ చేసే ఆదాయాన్ని దేశాభివృద్ధికి వినియోగిస్తారు.",
              "or": "ସେମାନେ ସଂଗ୍ରହ କରୁଥିବା ରାଜସ୍ୱ ଦେଶର ବିକାଶ ପାଇଁ ବ୍ୟବହୃତ ହୁଏ |",
              "as": "তেওঁলোকে সংগ্ৰহ কৰা ৰাজহ দেশৰ উন্নয়নৰ বাবে ব্যৱহাৰ কৰা হয়।",
              "gu": "તેઓ જે આવક એકત્રિત કરે છે તેનો ઉપયોગ દેશના વિકાસ માટે થાય છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_2_3",
              "hi": "D3_2_3",
              "ka": "D3_2_3",
              "te": "D3_2_3",
              "or": "D3_2_3",
              "as": "D3_2_3",
              "gu": "D3_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is happy that he can contribute to society as an IRS officer. His team also contributes by making smart tax policies and applying efficient economic laws, thus ensuring the country's economic stability.",
              "hi": "माधव खुश है कि वह एक आईआरएस अधिकारी के रूप में समाज में योगदान दे सकता है। उसकी टीम स्मार्ट कर नीतियों को और कुशल आर्थिक कानूनों को लागू करके योगदान देती है, इस प्रकार देश की आर्थिक स्थिरता सुनिश्चित करती है।",
              "ka": "ಐಆರ್‌ಎಸ್ ಅಧಿಕಾರಿಯಾಗಿ ಸಮಾಜಕ್ಕೆ ಕೊಡುಗೆ ನೀಡಬಹುದು ಎಂದು ಮಾಧವ್ ಸಂತಸಗೊಂಡಿದ್ದಾನೆ. ಅವರ ತಂಡವು ಸ್ಮಾರ್ಟ್ ತೆರಿಗೆ ನೀತಿಗಳನ್ನು ಮಾಡುವ ಮೂಲಕ ಮತ್ತು ಸಮರ್ಥ ಆರ್ಥಿಕ ಕಾನೂನುಗಳನ್ನು ಅನ್ವಯಿಸುವ ಮೂಲಕ ಕೊಡುಗೆ ನೀಡುತ್ತದೆ, ಹೀಗಾಗಿ ದೇಶದ ಆರ್ಥಿಕ ಸ್ಥಿರತೆಯನ್ನು ಖಾತ್ರಿಪಡಿಸುತ್ತದೆ.",
              "te": "IRS అధికారిగా సమాజానికి సహాయపడగలనని మాధవ్ సంతోషిస్తున్నాడు. అతని బృందం స్మార్ట్ పన్ను విధానాలను రూపొందించడం మరియు సమర్థవంతమైన ఆర్థిక చట్టాలను వర్తింపజేయడం ద్వారా కూడా సహకరిస్తుంది, తద్వారా దేశం ఆర్థికంగా స్థిరంగా ఉండేలా చూస్తుంది.",
              "or": "ମାଧବ ଖୁସି ଯେ ସେ ଜଣେ ଆଇଆରଏସ ଅଧିକାରୀ ଭାବରେ ସମାଜରେ \n ଯୋଗଦାନ କରିପାରିବେ। ସ୍ମାର୍ଟ ଟ୍ୟାକ୍ସ ନୀତି ପ୍ରସ୍ତୁତ କରି ଦକ୍ଷ ଅର୍ଥନଇତିକ ନିୟମ ପ୍ରୟୋଗ କରି ତାଙ୍କ ଦଳ ମଧ୍ୟ ସହଯୋଗ କରିଥାଏ, ଯାହାଦ୍ୱାରା ଦେଶର ଆର୍ଥିକ ସ୍ଥିରତା ସୁନିଶ୍ଚିତ ହୁଏ |",
              "as": "মাধৱ সুখী যে তেওঁ এজন IRS বিষয়া হিচাপে সমাজলৈ অৱদান আগবঢ়াব পাৰিছে। তেওঁৰ দলটোৱে স্মাৰ্ট কৰ নীতি প্ৰস্তুত কৰি আৰু দক্ষ অৰ্থনৈতিক আইন প্ৰয়োগ কৰি, এনেদৰে দেশৰ অৰ্থনৈতিক স্থিৰতা নিশ্চিত কৰি অৱদান আগবঢ়ায়।",
              "gu": "માધવ ખુશ છે કે તે IRS ઓફિસર તરીકે સમાજમાં યોગદાન આપી શકે છે. તેમની ટીમ સ્માર્ટ ટેક્સ નીતિઓ બનાવીને અને કાર્યક્ષમ આર્થિક કાયદાઓ લાગુ કરીને પણ યોગદાન આપે છે, આમ દેશની આર્થિક સ્થિરતા સુનિશ્ચિત કરે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "IRS Officer",
              "hi": "आईआरएस अधिकारी",
              "ka": "IRS ಆಫೀಸರ್",
              "te": "IRS అధికారి",
              "or": "ଆଇଆରଏସ ଅଧିକାରୀ",
              "as": "IRS বিষয়া",
              "gu": "IRS અધિકારી"
            }
          }
        ]
      },
      "scene34": {
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "IAS Probationer",
              "hi": "आईएएस प्रोबेशनर",
              "ka": "IAS ಪ್ರೊಬೇಷನರ್",
              "te": "IAS ప్రొబేషనర్",
              "or": "IAS ପ୍ରୋବେସନର୍ |",
              "as": "IAS প্ৰবেচনাৰ",
              "gu": "IAS પ્રોબેશનર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Interviewer",
              "hi": "साक्षात्कारकर्ता",
              "ka": "ಸಂದರ್ಶನಕಾರ",
              "te": "ఇంటర్వ్యూయర్",
              "or": "ସାକ୍ଷାତକାର",
              "as": "সাক্ষাৎকাৰ লওতা",
              "gu": "ઈન્ટરવ્યુ લેનાર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Thank you for your time Madhav. That is all we wanted to ask. You may leave now.",
              "hi": "आपके समय के लिए धन्यवाद माधव। हम सब बस इतना ही पूछना चाहते थे। अब आप जा सकते हैं।",
              "ka": "ನಿಮ್ಮ ಸಮಯಕ್ಕೆ ಧನ್ಯವಾದಗಳು ಮಾಧವ್. ನಾವು ಕೇಳಲು ಬಯಸಿದ್ದು ಇಷ್ಟೇ. ನೀವು ಈಗ ಹೊರಡಬಹುದು.",
              "te": "మీ సమయానికి ధన్యవాదాలు మాధవ్. మేం అడగాలనుకున్నది అంతే. మీరు ఇప్పుడు వెళ్లిపోవచ్చు.",
              "or": "ମାଧବ, ଆପଣଙ୍କ ସମୟ ପାଇଁ ଧନ୍ୟବାଦ | ତାହା ହିଁ ଆମେ ପଚାରିବାକୁ\n  ଚାହୁଁଥିଲୁ | ଆପଣ ବର୍ତ୍ତମାନ ଛାଡିପାରନ୍ତି |",
              "as": "তোমাৰ সময়ৰ বাবে ধন্যবাদ মাধৱ। আমি এইখিনিয়ে সুধিব বিচাৰিছিলোঁ। তুমি এতিয়া যাব পাৰা।",
              "gu": "તમારા સમય બદલ આભાર માધવ. આટલું જ અમે પૂછવા માંગતા હતા. તમે હવે જઈ શકો છો."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "Interview",
              "hi": "साक्षात्कार",
              "ka": "ಸಂದರ್ಶನ",
              "te": "ఇంటర్వ్యూ",
              "or": "ସାକ୍ଷାତକାର",
              "as": "সাক্ষাৎকাৰ",
              "gu": "ઈન્ટરવ્યુ"
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "ಎಮ್‌ಸಿ‌ಕ್ಯೂ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Madhav has to take an interview as the last round of the UPSC selection process. Which of the following statements is TRUE about an interview?",
              "hi": "माधव को यूपीएससी चयन प्रक्रिया के अंतिम दौर के रूप में एक साक्षात्कार देना है। एक साक्षात्कार के बारे में निम्नलिखित में से कौन सा कथन सत्य है?",
              "ka": "ಮಾಧವ್ ಅವರು UPSC ಆಯ್ಕೆ ಪ್ರಕ್ರಿಯೆಯ ಕೊನೆಯ ಸುತ್ತಿನ ಸಂದರ್ಶನವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕಾಗಿದೆ. ಸಂದರ್ಶನದ ಕುರಿತು ಈ ಕೆಳಗಿನ ಯಾವ ಹೇಳಿಕೆಗಳು ನಿಜವಾಗಿದೆ?",
              "te": "UPSC సెలక్షన్ ప్రాసెస్‌లో చివరి రౌండ్‌గా మాధవ్ ఇంటర్వ్యూ తీసుకోవాలి. ఇంటర్వ్యూ గురించి కింది స్టేట్‌మెంట్‌లలో ఏది నిజం?",
              "or": "UPSC ଚୟନ ପ୍ରକ୍ରିୟାର ଶେଷ ରାଉଣ୍ଡ ଭାବରେ ମାଧବଙ୍କୁ ଏକ ସାକ୍ଷାତକାର\n  ନେବାକୁ ପଡିବ | ଏକ ସାକ୍ଷାତକାର ବିଷୟରେ ନିମ୍ନଲିଖିତ କଥାଗୁଡ଼ିକ ମଧ୍ୟରୁ କେଉଁଟି ସତ୍ୟ?",
              "as": "মাধৱে UPSC বাছনি প্ৰক্ৰিয়াৰ অন্তিম পৰ্যায় হিচাপে এটা সাক্ষাৎকাৰ ল'ব লাগিব। সাক্ষাৎকাৰ এটাৰ বিষয়ে নিম্নলিখিত কোনটো মন্তব্য সঁচা?",
              "gu": "માધવને UPSC પસંદગી પ્રક્રિયાના છેલ્લા રાઉન્ડ તરીકે ઇન્ટરવ્યુ લેવાનો છે. ઇન્ટરવ્યુ વિશે નીચેનામાંથી કયું વિધાન સાચું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "In an interview, present yourself as the best candidate even if you need to lie.",
              "hi": "एक साक्षात्कार में, यदि आपको झूठ बोलने की ज़रूरत है तो भी खुद को सर्वश्रेष्ठ उम्मीदवार के रूप में प्रस्तुत करे।",
              "ka": "ಸಂದರ್ಶನದಲ್ಲಿ, ನೀವು ಸುಳ್ಳು ಹೇಳಬೇಕಾದರೂ ಸಹ ನಿಮ್ಮನ್ನು ಅತ್ಯುತ್ತಮ ಅಭ್ಯರ್ಥಿ ಎಂದು ಪ್ರಸ್ತುತಪಡಿಸಿ.",
              "te": "ఒక ఇంటర్వ్యూలో, మీరు అబద్ధం చెప్పినా కూడా, మిమ్మల్ని మీరు ఉత్తమ అభ్యర్థిగా చూపించాలి.",
              "or": "ଏକ ସାକ୍ଷାତକାରରେ, ଯଦି ଆପଣ ମିଛ କହିବା ଆବଶ୍ୟକ କରନ୍ତି ତେବେ\n  ନିଜକୁ ସର୍ବୋତ୍ତମ ପ୍ରାର୍ଥୀ ଭାବରେ ଉପସ୍ଥାପନ କରନ୍ତୁ |",
              "as": "সাক্ষাৎকাৰ এটাত, তুমি মিছা কোৱাৰ প্ৰয়োজন হ'লেও নিজকে সৰ্বশ্ৰেষ্ঠ প্ৰাৰ্থী হিচাপে উপস্থাপন কৰা।",
              "gu": "ઇન્ટરવ્યુમાં, જો તમારે જૂઠું બોલવાની જરૂર હોય તો પણ તમારી જાતને શ્રેષ્ઠ ઉમેદવાર તરીકે રજૂ કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "After you get your first job you will never need to do another interview.",
              "hi": "अपनी पहली नौकरी पाने के बाद आपको कभी भी एक और साक्षात्कार करने की आवश्यकता नहीं होगी।",
              "ka": "ಸಂದರ್ಶನವು ನಿಮ್ಮ ಭವಿಷ್ಯದ ಮೇಲ್ವಿಚಾರಕರು ಮತ್ತು ನಿಮ್ಮ ನಡುವಿನ ಅನೌಪಚಾರಿಕ ಸಂಭಾಷಣೆಯಾಗಿದೆ.",
              "te": "మీరు మీ మొదటి ఉద్యోగం పొందిన తర్వాత మీరు మరొక ఇంటర్వ్యూ చేయవలసిన అవసరం లేదు.",
              "or": "ତୁମେ ତୁମର ପ୍ରଥମ ଚାକିରି ପାଇବା ପରେ ତୁମକୁ ଆଉ ଏକ ସାକ୍ଷାତକାର\n  କରିବା ଆବଶ୍ୟକ ନାହିଁ |",
              "as": "তুমি তোমাৰ প্ৰথম চাকৰি পোৱাৰ পিছত কেতিয়াও আন এটা সাক্ষাৎকাৰ দিয়াৰ প্ৰয়োজন নহ'ব।",
              "gu": "તમે તમારી પ્રથમ નોકરી મેળવ્યા પછી તમારે ક્યારેય બીજો ઇન્ટરવ્યુ આપવાની જરૂર રહેશે નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "An interview is an informal conversation between your future supervisor and you.",
              "hi": " साक्षात्कार आपके भविष्य के सुपरवाइजर / पर्यवेक्षक और आपके बीच एक अनौपचारिक बातचीत है।",
              "ka": "ಸಂದರ್ಶನಕಾರರು ಮತ್ತು ಸಂದರ್ಶಕರು ಇಬ್ಬರಿಗೂ ಇದು ಔಪಚಾರಿಕ ಸಭೆಯಾಗಿದ್ದು, ಉದ್ಯೋಗದ ಪಾತ್ರ ಮತ್ತು ಅಭ್ಯರ್ಥಿಯು ಅದಕ್ಕೆ ಉತ್ತಮ ಆಯ್ಕೆ ಆಗಿದ್ದಾರೆಯೇ ಎಂದು ಪರಿಶೀಲಿಸಲು.",
              "te": "ఇంటర్వ్యూ అనేది మీ భవిష్యత్ సూపర్‌వైజర్ మరియు మీకు మధ్య జరిగే అనధికారిక సంభాషణ.",
              "or": "ଏକ ସାକ୍ଷାତକାର ହେଉଛି ତୁମର ଭବିଷ୍ୟତର ସୁପରଭାଇଜର ଏବଂ\n  ତୁମ ମଧ୍ୟରେ ଏକ ଅନଔପଚାରିକ ବାର୍ତ୍ତାଳାପ |",
              "as": "সাক্ষাৎকাৰ হৈছে তোমাৰ ভৱিষ্যত চুপাৰভাইজাৰ আৰু তোমাৰ মাজত হোৱা এক অনানুষ্ঠানিক কথোপকথন।",
              "gu": "ઇન્ટરવ્યુ એ તમારા ભાવિ સુપરવાઇઝર અને તમારી વચ્ચેની અનૌપચારિક વાતચીત છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "It is a formal meeting for both, the interviewer & interviewee to check if the job role  and the candidate is a good fit.",
              "hi": "यह औपचारिक बैठक है जिसमें साक्षात्कारकर्ता और साक्षात्कारदाता,  दोनों को जांचने के लिए कि क्या नौकरी की भूमिका और उम्मीदवार सर्वथा उपयुक्त हैं।",
              "ka": "ಮತ್ತೊಮ್ಮೆ  ಆಲೋಚಿಸಿ! ನಿಮ್ಮ ಸಂದರ್ಶನದಲ್ಲಿ ನೀವು ಸುಳ್ಳು ಹೇಳಿದರೆ, ಅದು ನಂತರ ಪರಿಣಾಮಗಳನ್ನು ಉಂಟುಮಾಡಬಹುದು.",
              "te": "ఇది ఉద్యోగ పాత్ర మరియు అభ్యర్థి సరిగ్గా సరిపోతారో లేదో తెలుసుకోవడానికి ఇంటర్వ్యూయర్ & ఇంటర్వ్యూ చేసే ఇద్దరికీ ఇది అధికారిక సమావేశం.",
              "or": "ଚାକିରିର ଭୂମିକା ଏବଂ ପ୍ରାର୍ଥୀ ଏକ ଭଲ ଫିଟ୍ କି ନାହିଁ ତାହା ଯାଞ୍ଚ କରିବା\n  ପାଇଁ ଉଭୟ ସାକ୍ଷାତକାରକାରୀ ଏବଂ ସାକ୍ଷାତକାର ପାଇଁ ଏହା ଏକ ଆନୁଷ୍ଠାନିକ ବଇଠକ |",
              "as": "চাকৰিৰ ভূমিকা আৰু প্ৰাৰ্থী জন উপযুক্ত হয় নে নাই পৰীক্ষা কৰিবলৈ সাক্ষাৎকাৰ লওতা আৰু সাক্ষাৎকাৰ গ্ৰহণকাৰী দুয়োৰে বাবে ই এক আনুষ্ঠানিক সভা।",
              "gu": "નોકરીની ભૂમિકા અને ઉમેદવાર યોગ્ય છે કે કેમ તે તપાસવા માટે ઇન્ટરવ્યુ આપનાર અને ઇન્ટરવ્યુ લેનાર બંને માટે આ ઔપચારિક મીટિંગ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Think again! If you lie in your interview, it may have consequences later.",
              "hi": "फिर से विचार करो! यदि आप अपने साक्षात्कार में झूठ बोलते हैं, तो इसका परिणाम बाद में कुछ भी हो सकता है।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಸಂದರ್ಶನಗಳು ಕೆಲಸದ ಪ್ರಪಂಚದಲ್ಲಿನ ಒಂದು ಭಾಗವಾಗಿದೆ.",
              "te": "మళ్లీ ఆలోచించండి! మీరు మీ ఇంటర్వ్యూలో అబద్ధం చెబితే, అది తర్వాత ఇబ్బంది కలిగించవచ్చు.",
              "or": "ପୁଣି ଥରେ ଭାବ! ଯଦି ତୁମେ ତୁମର ସାକ୍ଷାତକାରରେ ମିଛ କହୁଛ,\n  ଏହାର ପରିଣାମ ପରେ ହୋଇପାରେ |",
              "as": "আকৌ চিন্তা কৰা! যদি তুমি তোমাৰ সাক্ষাৎকাৰত মিছা কোৱা, ইয়াৰ পিছত কিবা পৰিণাম হ'ব পাৰে।",
              "gu": "ફરીથી વિચારો! જો તમે તમારા ઇન્ટરવ્યુમાં જૂઠું બોલો છો, તો તેના પરિણામો પછીથી આવી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Think again! Interviews are part of the world of work.",
              "hi": "फिर से विचार करो! साक्षात्कार काम की दुनिया का हिस्सा है।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಸಂದರ್ಶನವು ಉದ್ಯೋಗವನ್ನು ಪಡೆಯಲು ಔಪಚಾರಿಕ ಸಂಭಾಷಣೆಯಾಗಿದೆ.",
              "te": "మళ్లీ ఆలోచించండి! పని కోసం ఇంటర్వ్యూలు అనేది ఒక భాగం.",
              "or": "ପୁଣି ଥରେ ଭାବ! ସାକ୍ଷାତକାରଗୁଡ଼ିକ କାର୍ଯ୍ୟ ଜଗତର ଏକ ଅଂଶ |",
              "as": "আকৌ চিন্তা কৰা! সাক্ষাৎকাৰবোৰ কামৰ পৃথিৱীৰ অংশ।",
              "gu": "ફરીથી વિચારો! ઇન્ટરવ્યુ એ કામની દુનિયાનો એક ભાગ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Think again! An interview is a formal conversation to get a job.",
              "hi": "फिर से विचार करो! साक्षात्कार नौकरी पाने के लिए एक औपचारिक बातचीत है।",
              "ka": "ನಿಮ್ಮ ಮೊದಲ ಕೆಲಸವನ್ನು ನೀವು ಪಡೆದ ನಂತರ ನೀವು ಇನ್ನೊಂದು ಸಂದರ್ಶನವನ್ನು ಮಾಡಬೇಕಾಗಿಲ್ಲ.",
              "te": "మళ్లీ ఆలోచించండి! ఇంటర్వ్యూ అనేది ఉద్యోగం పొందడానికి అధికారిక సంభాషణ.",
              "or": "ପୁଣି ଥରେ ଭାବ! ଚାକିରି ପାଇବା ପାଇଁ ଏକ ସାକ୍ଷାତକାର ହେଉଛି ଏକ \n ଆନୁଷ୍ଠାନିକ ବାର୍ତ୍ତାଳାପ |",
              "as": "আকৌ চিন্তা কৰা! এটা সাক্ষাৎকাৰ হৈছে চাকৰি এটা পাবলৈ এটা আনুষ্ঠানিক কথোপকথন।",
              "gu": "ફરીથી વિચારો! ઇન્ટરવ્યુ એ નોકરી મેળવવા માટે ઔપચારિક વાતચીત છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಉತ್ತಮ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ବହୁତ ଭଲ କାମ!",
              "as": "ভাল কৰিছা!",
              "gu": "સરસ કામ!"
            }
          }
        ],
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_1_1",
              "hi": "E1_1_1",
              "ka": "E1_1_1",
              "te": "E1_1_1",
              "or": "E1_1_1",
              "as": "E1_1_1",
              "gu": "E1_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "There’s an announcement in the news that the UPSC selection committee is ready with the results, and it will be sent to the candidates soon.",
              "hi": "समाचारों में बताया गया है कि यूपीएससी चयन समिति परिणामों के साथ तैयार है, और इसे जल्द ही उम्मीदवारों को भेजा जाएगा।",
              "ka": "UPSC ಆಯ್ಕೆ ಸಮಿತಿಯು ಫಲಿತಾಂಶಗಳೊಂದಿಗೆ ಸಿದ್ಧವಾಗಿದೆ ಮತ್ತು ಅದನ್ನು ಶೀಘ್ರದಲ್ಲೇ ಅಭ್ಯರ್ಥಿಗಳಿಗೆ ಕಳುಹಿಸಲಾಗುವುದು ಎಂದು ಸುದ್ದಿಯಲ್ಲಿ ಪ್ರಕಟಣೆ ಇದೆ.",
              "te": "UPSC సెలక్షన్ కమిటీ ఫలితాలతో సిద్ధంగా ఉందని, అవి త్వరలో అభ్యర్థులకు పంపబడుతాయని వార్తలలో ఒక ప్రకటన వచ్చింది.",
              "or": "ସମ୍ବାଦରେ ଏକ ଘୋଷଣା ଅଛି ଯେ ଫଳାଫଳ ସହିତ UPSC ଚୟନ \n କମିଟି ପ୍ରସ୍ତୁତ, ଏବଂ ଏହା ଶୀଘ୍ର ପ୍ରାର୍ଥୀଙ୍କ ନିକଟକୁ ପଠାଯିବ |",
              "as": "বাতৰিত এটা ঘোষণা কৰা হৈছে যে UPSC বাছনি সমিতি ফলাফলৰ সৈতে সাজু হৈছে, আৰু ইয়াক শীঘ্ৰেই প্ৰাৰ্থীসকললৈ প্ৰেৰণ কৰা হ'ব।",
              "gu": "સમાચારમાં એક જાહેરાત છે કે UPSC પસંદગી સમિતિ પરિણામો સાથે તૈયાર છે, અને તે ટૂંક સમયમાં ઉમેદવારોને મોકલવામાં આવશે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_1_2",
              "hi": "E1_1_2",
              "ka": "E1_1_2",
              "te": "E1_1_2",
              "or": "E1_1_2",
              "as": "E1_1_2",
              "gu": "E1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The reporter shares that 2000 candidates appeared for the interview this year, which is the highest in the past few years.",
              "hi": "रिपोर्टर साझा करता है कि 2000 उम्मीदवार इस साल साक्षात्कार के लिए उपस्थित हुए, जो पिछले कुछ वर्षों में सबसे ज्यादा है।",
              "ka": "ಈ ವರ್ಷ 2000 ಅಭ್ಯರ್ಥಿಗಳು ಸಂದರ್ಶನಕ್ಕೆ ಹಾಜರಾಗಿದ್ದರು, ಇದು ಕಳೆದ ಕೆಲವು ವರ್ಷಗಳಲ್ಲಿ ಅತಿ ಹೆಚ್ಚು ಎಂದು ವರದಿಗಾರರು ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಂಡಿದ್ದಾರೆ.",
              "te": "ఈ ఏడాది 2000 మంది అభ్యర్థులు ఇంటర్వ్యూకు హాజరయ్యారని, ఇది గత కొన్నేళ్లలో అత్యధికమని రిపోర్టర్ తెలిపారు.",
              "or": "ସାମ୍ବାଦିକ ସେୟାର କରିଛନ୍ତି ଯେ ଏହି ବର୍ଷ ସାକ୍ଷାତକାର ପାଇଁ ୨୦୦୦\n ପ୍ରାର୍ଥୀ ଉପସ୍ଥିତ ଥିଲେ, ଯାହା ଗତ କିଛି ବର୍ଷ ମଧ୍ୟରେ ସର୍ବାଧିକ |",
              "as": "প্ৰতিবেদকে কয় যে এই বছৰ 2000 জন প্ৰাৰ্থীয়ে সাক্ষাৎকাৰৰ বাবে অৱতীৰ্ণ হৈছিল, যি যোৱা কেইবছৰমানত সৰ্বাধিক।",
              "gu": "રિપોર્ટર બતાવે છે કે આ વર્ષે 2000 ઉમેદવારો ઇન્ટરવ્યુ માટે હાજર થયા હતા, જે છેલ્લા કેટલાક વર્ષોમાં સૌથી વધુ છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_1_3",
              "hi": "E1_1_3",
              "ka": "E1_1_3",
              "te": "E1_1_3",
              "or": "E1_1_3",
              "as": "E1_1_3",
              "gu": "E1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav is nervous. He has been thinking of everything he could have said better in his personality test.",
              "hi": "माधव घबराया हुआ है। वह सब कुछ ऐसा सोच रहा है जो वह अपने व्यक्तित्व परीक्षण में बेहतर कह सकता था।",
              "ka": "ಮಾಧವ್ ಆತಂಕಗೊಂಡಿದ್ದಾನೆ. ಅವನು ತನ್ನ ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಯಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಹೇಳಬಹುದಾದ ಎಲ್ಲದರ ಬಗ್ಗೆಯೂ ಯೋಚಿಸುತ್ತಿದ್ದಾನೆ",
              "te": "మాధవ్ కంగారుగా ఉన్నాడు. అతను తన పర్సనాలిటీ టెస్ట్ లో ఇంకా బాగా ఎలా చెప్పగలిగేవాడు అని ప్రతిదాని గురించి ఆలోచిస్తున్నాడు.",
              "or": "ମାଧବ ନର୍ଭସ ଅଛନ୍ତି | ସେ ତାଙ୍କ ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷାରେ ଭଲ କହିଥାଇ \n ପାରେ ବୋଲି ସବୁକିଛି ଚିନ୍ତା କରୁଛନ୍ତି |",
              "as": "মাধৱ উদ্বিগ্ন হৈ আছে। তেওঁ তেওঁৰ ব্যক্তিত্ব পৰীক্ষাত ভালদৰে ক'ব পৰা সকলোবোৰ কথা ভাবি আছে।",
              "gu": "માધવ નિરાશ છે. તે તેના વ્યક્તિત્વની કસોટીમાં વધુ સારી રીતે કહી શકે તે બધું જ વિચારી રહ્યો છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_1_4",
              "hi": "E1_1_4",
              "ka": "E1_1_4",
              "te": "E1_1_4",
              "or": "E1_1_4",
              "as": "E1_1_4",
              "gu": "E1_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He is scared that he might not have made a good impression during the personality test, and his score will affect his chance to become a Civil Servant.",
              "hi": "वह डरता है कि उसने व्यक्तित्व परीक्षण के दौरान एक अच्छा प्रभाव नहीं डाला होगा, और उसका स्कोर सिविल सेवा करने के अवसर को प्रभावित करेगा।",
              "ka": "ವ್ಯಕ್ತಿತ್ವ ಪರೀಕ್ಷೆಯ ಸಮಯದಲ್ಲಿ ತಾನು ಉತ್ತಮ ಪ್ರಭಾವ ಬೀರಲಿಲ್ಲ ಎಂದು ಅವನು ಭಯಪಡುತ್ತಿದ್ದಾನೆ ಮತ್ತು ಅವನ ಅಂಕವು ನಾಗರಿಕ ಸೇವಕನಾಗುವ ಅವಕಾಶದ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುತ್ತದೆ.",
              "te": "పర్సనాలిటీ టెస్ట్ సమయంలో అతను మంచి ఇంప్రెషన్ ఇవ్వలేకపోయాడని మరియు అతని స్కోర్ సివిల్ సర్వెంట్ అయ్యే అవకాశాన్ని ప్రభావితం చేస్తుందని అతను భయపడ్డాడు.",
              "or": "ସେ ଭୟଭୀତ ଅଛନ୍ତି ଯେ ବ୍ୟକ୍ତିତ୍ୱ ପରୀକ୍ଷା ସମୟରେ ସେ ହୁଏତ ଭଲ\n  ପ୍ରଭାବ ପକାଇ ନଥିବେ, ଏବଂ ତାଙ୍କର ସ୍କୋର ସିଭିଲ୍ ସେବକ ହେବାର ସୁଯୋଗକୁ ପ୍ରଭାବିତ କରିବ |",
              "as": "তেওঁ ভয় খাইছে যে ব্যক্তিত্ব পৰীক্ষাৰ সময়ত তেওঁ ভাল প্ৰভাৱ পেলাব নোৱাৰিব পাৰে, আৰু তেওঁৰ নম্বৰে তেওঁৰ অসামৰিক সেৱক হোৱাৰ সুযোগক প্ৰভাৱিত কৰিব।",
              "gu": "તેને ડર છે કે વ્યક્તિત્વ કસોટી દરમિયાન તેણે કદાચ સારી છાપ ન ઉભી કરી હોય, અને તેનો ક્રમ તેની સિવિલ સર્વન્ટ બનવાની તકને અસર કરશે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_2_1",
              "hi": "E1_2_1",
              "ka": "E1_2_1",
              "te": "E1_2_1",
              "or": "E1_2_1",
              "as": "E1_2_1",
              "gu": "E1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He goes out for a walk to clear his mind and have a cup of tea. He overhears two people talking about the UPSC results at the tea stall.",
              "hi": "वह अपने दिमाग को शांत करने और एक कप चाय पीने के लिए बाहर जाता है। वह चाय की दुकान पर यूपीएससी परिणामों के बारे में बात करते हुए दो लोगों को सुनता है।",
              "ka": "ಅವನು ತನ್ನ ಮನಸ್ಸನ್ನು ತಿಳಿಗೊಳಿಸಲು ಮತ್ತು ಒಂದು ಕಪ್ ಚಹಾವನ್ನು ಕುಡಿಯಲು ವಾಕಿಂಗ್‌ಗೆ ಹೋಗುತ್ತಾನೆ. ಟೀ ಸ್ಟಾಲ್‌ನಲ್ಲಿ UPSC ಫಲಿತಾಂಶಗಳ ಬಗ್ಗೆ ಇಬ್ಬರು ಮಾತನಾಡುವುದನ್ನು ಅವನು ಕೇಳುತ್ತಾನೆ.",
              "te": "అతను తన మైండ్ ను క్లియర్ చేసుకొని టీ తాగడానికి నడుస్తూ వెళ్తాడు. టీ స్టాల్‌లో ఇద్దరు వ్యక్తులు UPSC ఫలితాల గురించి మాట్లాడుకోవడం అతను విన్నాడు.",
              "or": "ସେ ନିଜ ମନକୁ ସଫା କରିବାକୁ ଏବଂ ଏକ କପ୍ ଚା ପିଇବାକୁ ବୁଲିବାକୁ\n  ଯାଆନ୍ତି | ସେ ଚା ’ଷ୍ଟଲରେ UPSC ଫଳାଫଳ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରୁଥିବା ଦୁଇ ଜଣଙ୍କୁ ଶୁଣନ୍ତି |",
              "as": "তেওঁ মনটো পৰিষ্কাৰ কৰিবলৈ খোজ কাঢ়িবলৈ ওলাই যায় আৰু একাপ চাহ খায়। তেওঁ চাহৰ দোকানত দুজন লোকক UPSCৰ ফলাফলৰ বিষয়ে কথা পাতি থকা শুনিছিল।",
              "gu": "તે તેના મગજને સ્વસ્થ કરવા અને ચા પીવા માટે બહાર જાય છે. તે ચાની દુકાન પર બે લોકોને UPSC પરિણામો વિશે વાત કરતા સાંભળે છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_2_2////",
              "hi": "E1_2_2 ////",
              "ka": "E1_2_2////",
              "te": "E1_2_2////",
              "or": "E1_2_2////",
              "as": "E1_2_2////",
              "gu": "E1_2_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Did you hear the news? The UPSC results are coming out.",
              "hi": "आपने सुना ? यूपीएससी परिणाम आने वाले हैं।",
              "ka": "ನೀವು ಸುದ್ದಿ ಕೇಳಿದ್ದೀರಾ? UPSC ಫಲಿತಾಂಶ ಹೊರಬೀಳುತ್ತಿದೆ.",
              "te": "నువ్వు వార్త విన్నావా? UPSC ఫలితాలు వెలువడుతున్నాయి.",
              "or": "ଆପଣ ଏହି ଖବର ଶୁଣିଛନ୍ତି କି? UPSC ଫଳାଫଳ ବାହାରକୁ ଆସୁଛି |",
              "as": "তুমি বাতৰিটো শুনিছানে? UPSCৰ ফলাফল ওলাব হৈছে।",
              "gu": "તમે સમાચાર સાંભળ્યા? યુપીએસસીનું પરિણામ આવી રહ્યું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "So what? They come out every year, and new people come into the Civil Services every year, yet this country is still the same.",
              "hi": "तो क्या? वे हर साल आते हैं, और नए लोग हर साल सिविल सेवाओं में आते हैं, फिर भी यह देश अभी भी वैसा ही है।",
              "ka": "ಏನೀಗ? ಅದು ಪ್ರತಿ ವರ್ಷ ಹೊರಬರುತ್ತದೆ, ಮತ್ತು ಪ್ರತಿ ವರ್ಷ ಹೊಸ ಜನರು ನಾಗರಿಕ ಸೇವೆಗಳಿಗೆ ಬರುತ್ತಾರೆ, ಆದರೂ ಈ ದೇಶವು ಇನ್ನೂ ಹಾಗೆಯೇ ಇರುತ್ತದೆ.",
              "te": "అయితే ఏంటి? అవి ప్రతి సంవత్సరం వస్తాయి, మరియు ప్రతి సంవత్సరం కొత్త వ్యక్తులు సివిల్ సర్వీసెస్‌లోకి వస్తారు, కానీ ఈ దేశం ఇప్పటికీ అలాగే ఉంది.",
              "or": "ତ କଣ? ସେମାନେ ପ୍ରତିବର୍ଷ ବାହାରକୁ ଆସନ୍ତି, ଏବଂ ନୂତନ ଲୋକ \n ପ୍ରତିବର୍ଷ ସିଭିଲ ସର୍ଭିସରେ ଆସନ୍ତି, ତଥାପି ଏହି ଦେଶ ସମାନ |",
              "as": "তাতে কি হ’ল? সেইবোৰ প্ৰতি বছৰে ওলাই থাকে, আৰু প্ৰতি বছৰে নতুন লোক অসামৰিক সেৱালৈ আহে, তথাপিও এই দেশ এতিয়াও একেই আছে।",
              "gu": "તો શું? તેઓ દર વર્ષે બહાર આવે છે, અને દર વર્ષે નવા લોકો સિવિલ સર્વિસમાં આવે છે, તેમ છતાં આ દેશ હજુ પણ એવો જ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "That’s true. One would think that people who clear such a difficult selection process must be capable enough to run the country.",
              "hi": "यह सच है। कोई सोचेगा कि जो लोग ऐसी मुश्किल चयन प्रक्रिया को उत्तीर्ण करते हैं, उन्हें देश को चलाने के लिए पर्याप्त सक्षम होना चाहिए।",
              "ka": "ಅದು ಸತ್ಯ. ಅಂತಹ ಕಠಿಣ ಆಯ್ಕೆ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ತೇರ್ಗಡೆಗೊಳ್ಳುವ  ಜನರು ದೇಶವನ್ನು ನಡೆಸಲು ಸಾಕಷ್ಟು ಸಮರ್ಥರಾಗಿರಬೇಕು ಎಂದು ಎಲ್ಲರೂ ಭಾವಿಸುತ್ತಾರೆ.",
              "te": "అది నిజం. అటువంటి క్లిష్ట ఎంపిక ప్రక్రియను క్లియర్ చేసే వ్యక్తులు దేశాన్ని నడపగల సామర్థ్యం కలిగి ఉండాలని ఎవరైనా అనుకుంటారు.",
              "or": "ତାହା ସତ୍ୟ ଅଟେ | ଜଣେ ଭାବିବେ ଯେ ଯେଉଁମାନେ ଏପରି କଠିନ \n ଚୟନ ପ୍ରକ୍ରିୟାକୁ ସଫା କରନ୍ତି ସେମାନେ ଦେଶ ଚଳାଇବା ପାଇଁ ଯଥେଷ୍ଟ ସକ୍ଷମ ହେବା ଆବଶ୍ୟକ |",
              "as": "সেইটো সঁচা। কোনোবাই ভাবিব পাৰে যে এনে কঠিন বাছনি প্ৰক্ৰিয়া সম্পন্ন কৰা লোকসকল দেশখন চলাব পৰাকৈ যথেষ্ট সক্ষম হ'ব লাগিব।",
              "gu": "તે સાચું છે. કોઈ એવું વિચારશે કે જે લોકો આવી મુશ્કેલ પસંદગી પ્રક્રિયાને પાસ કરે છે તેઓ દેશ ચલાવવા માટે પૂરતા સક્ષમ હોવા જોઈએ."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_2_3",
              "hi": "E1_2_3",
              "ka": "E1_2_3",
              "te": "E1_2_3",
              "or": "E1_2_3",
              "as": "E1_2_3",
              "gu": "E1_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After overhearing this conversation, Madhav is even more stressed. He has been so busy with his studies for the past four years that he didn’t think about the responsibility of being a Civil Servant properly.",
              "hi": "इस वार्तालाप को सुनने के बाद, माधव और भी तनावग्रस्त है। वह पिछले चार वर्षों से अपने अध्ययन में इतना व्यस्त है कि उसने सिविल सेवा करने की ज़िम्मेदारी के बारे में ठीक से नहीं सोचा था।",
              "ka": "ಈ ಸಂಭಾಷಣೆಯನ್ನು ಕೇಳಿದ ನಂತರ, ಮಾಧವ್ ಇನ್ನಷ್ಟು ಒತ್ತಡಕ್ಕೊಳಗಾಗುತ್ತಾನೆ. ಕಳೆದ ನಾಲ್ಕು ವರ್ಷಗಳಿಂದ ತನ್ನ ವಿದ್ಯಾಭ್ಯಾಸದಲ್ಲಿ ಬ್ಯುಸಿಯಾಗಿದ್ದ ಆತ ಪೌರಕಾರ್ಮಿಕನ ಜವಾಬ್ದಾರಿಯ ಬಗ್ಗೆ ಸರಿಯಾಗಿ ಯೋಚಿಸಿರಲಿಲ್ಲ.",
              "te": "ఈ సంభాషణ విన్న తర్వాత మాధవ్ మరింత ఒత్తిడికి లోనయ్యాడు. నాలుగేళ్లుగా చదువుతో బిజీబిజీగా గడుపుతున్న అతడు సివిల్‌ సర్వెంట్‌ బాధ్యతల గురించి ఆలోచించలేదు.",
              "or": "ଏହି ବାର୍ତ୍ତାଳାପକୁ ଶୁଣିବା ପରେ, ମାଧବ ଆହୁରି ଚାପଗ୍ରସ୍ତ | ସେ ଗତ\n  ଚାରି ବର୍ଷ ଧରି ଅଧ୍ୟୟନରେ ଏତେ ବ୍ୟସ୍ତ ଥିଲେ ଯେ ସିଭିଲ୍ ସେବକ ହେବାର ଦାୟିତ୍ about ବିଷୟରେ ସେ ଭାବିନଥିଲେ।",
              "as": "কাণত পৰা এই কথোপকথনটো শুনি মাধৱ আৰু অধিক চাপত পৰে। তেওঁ যোৱা চাৰি বছৰ ধৰি পঢ়া-শুনাত ইমানেই ব্যস্ত আছিল যে তেওঁ এজন অসামৰিক কৰ্মচাৰী হোৱাৰ দায়িত্বৰ বিষয়ে সঠিকভাৱে ভবাই নাছিল।",
              "gu": "આ વાતચીત સાંભળ્યા પછી માધવ વધુ તણાવમાં છે. છેલ્લા ચાર વર્ષથી તે પોતાના અભ્યાસમાં એટલો વ્યસ્ત હતો કે તેણે સિવિલ સર્વન્ટ તરીકેની જવાબદારી વિશે યોગ્ય રીતે વિચાર્યું ન હતું."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_2_4",
              "hi": "E1_2_4",
              "ka": "E1_2_4",
              "te": "E1_2_4",
              "or": "E1_2_4",
              "as": "E1_2_4",
              "gu": "E1_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The pressure gets to him as he waits for the results to arrive.\nAfter a long wait, Madhav finally gets an email. His hands are trembling.",
              "hi": "जब वह परिणाम की प्रतीक्षा करता है तो उस पर दबाव बढ़ जाता है।\nएक लंबी प्रतीक्षा के बाद, माधव को अंततः एक ईमेल मिलता है। उसके हाथ कांप रहे हैं।",
              "ka": "ಫಲಿತಾಂಶಗಳು ಬರಲು ಕಾಯುತ್ತಿರುವಾಗ ಬಹಳ ಒತ್ತಡವು ಅವನ ಮೇಲೆ ಬೀಳುತ್ತದೆ. ಸುದೀರ್ಘ ಕಾಯುವಿಕೆಯ ನಂತರ, ಮಾಧವ್‌ಗೆ ಅಂತಿಮವಾಗಿ ಇಮೇಲ್ ಬರುತ್ತದೆ. ಅವನ ಕೈಗಳು ನಡುಗುತ್ತಿವೆ.",
              "te": "ఫలితాలు వచ్చే వరకు అతను ఎదురుచూస్తున్నప్పుడు అతనిపై ఒత్తిడి పెరిగింది.\n \n చాలా సేపటి తర్వాత, మాధవ్‌కి ఎట్టకేలకు ఒక ఇమెయిల్ వచ్చింది. అతని చేతులు వణుకుతున్నాయి.",
              "or": "ଫଳାଫଳ ଆସିବା ପର୍ଯ୍ୟନ୍ତ ସେ ଅପେକ୍ଷାକୃତ ବେସୀ ଚାପ ଗ୍ରସ୍ତ\n ବହୁତ ସମୟ ଅପେକ୍ଷା ପରେ, ମାଧବ ଶେଷରେ ଏକ ଇମେଲ୍ ପାଇଲେ | ତାଙ୍କ ହାତ ଥରି ଉଠୁଛି। \"",
              "as": "তেওঁ ফলাফল অহাৰ বাবে অপেক্ষা কৰি থকাৰ সময়ত চাপ বাঢ়ি গৈ থাকে। দীঘলীয়া অপেক্ষাৰ পিছত, মাধৱে অৱশেষত এটা ই-মেইল পালে। তেওঁৰ হাত কঁপিব ধৰে।",
              "gu": "તેના પર દબાણ આવે છે કારણ કે તે પરિણામો આવવાની રાહ જુએ છે. લાંબી રાહ જોયા બાદ આખરે માધવને ઈમેલ મળે છે. તેના હાથ ધ્રૂજી રહ્યા છે."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_1_6 _email",
              "hi": "E1_1_6 _EMAIL",
              "ka": "E1_1_6 _email",
              "te": "E1_1_6 _ఇమెయిల్",
              "or": "E1_1_6 _email",
              "as": "E1_1_6 _ই-মেইল",
              "gu": "E1_1_6 _ઈમેઈલ"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailTo",
            "value": {
              "en": "selection-upsc.gov.in",
              "hi": "चयन-upsc.gov.in",
              "ka": "selection-upsc.gove.in",
              "te": "selection-upsc.gov.in",
              "or": "selection-upsc.gov.in",
              "as": "selection-upsc.gov.in",
              "gu": "selection-upsc.gov.in"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailSubject",
            "value": {
              "en": "Appointment to the Indian Administrative Service (IAS) on the basis of the results of the Civil Services Examination",
              "hi": "सिविल सेवा परीक्षा के परिणामों के आधार पर भारतीय प्रशासनिक सेवा (आईएएस) के लिए नियुक्ति",
              "ka": "UPSC ಆಯ್ಕೆ ಸಮಿತಿಯು ಫಲಿತಾಂಶಗಳೊಂದಿಗೆ ಸಿದ್ಧವಾಗಿದೆ ಮತ್ತು ಅದನ್ನು ಶೀಘ್ರದಲ್ಲೇ ಅಭ್ಯರ್ಥಿಗಳಿಗೆ ಕಳುಹಿಸಲಾಗುವುದು ಎಂದು ಸುದ್ದಿಯಲ್ಲಿ ಪ್ರಕಟಣೆ ಇದೆ.",
              "te": "సివిల్ సర్వీసెస్ పరీక్ష ఫలితాల ఆధారంగా ఇండియన్ అడ్మినిస్ట్రేటివ్ సర్వీస్ (IAS)కి నియామకం",
              "or": "ସିଭିଲ ସର୍ଭିସେସ୍ ପରୀକ୍ଷାର ଫଳାଫଳ ଆଧାରରେ ଭାରତୀୟ ପ୍ରଶାସନିକ\n  ସେବା (IAS) ରେ ନିଯୁକ୍ତି |",
              "as": "অসামৰিক সেৱা পৰীক্ষাৰ ফলাফলৰ আধাৰত ভাৰতীয় প্ৰশাসনিক সেৱাত (IAS) নিযুক্তি",
              "gu": "સિવિલ સર્વિસ પરીક્ષાના પરિણામોના આધારે ભારતીય વહીવટી સેવા (IAS) માં નિમણૂક"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailMsg",
            "value": {
              "en": "Dear Applicant,\nThe Union Public Service Commission is pleased to offer you the appointment to the Indian Administrative Service (IAS) based on your qualifying Civil Services Examination conducted by the Union Public Service Commission.\nThe ‘Offer of Appointment’ is provisional subject to verification of your character and antecedents.",
              "hi": "प्रिय आवेदक,\nसंघ लोक सेवा आयोग को संघ लोक सेवा आयोग द्वारा आयोजित योग्यता सिविल सेवा परीक्षा के आधार पर आपको भारतीय प्रशासनिक सेवा (आईएएस) के लिए नियुक्ति प्रदान करने में प्रसन्नता हो रही है।\n'नियुक्ति का प्रस्ताव' अस्थायी है और आपके चरित्र और पूर्ववर्ती के सत्यापन पर आधारित है।",
              "ka": "ಆತ್ಮೀಯ ಅರ್ಜಿದಾರರೇ,                                                                                                                                                             \"ಆತ್ಮೀಯ ಅರ್ಜಿದಾರರೇ,\nಯೂನಿಯನ್ ಪಬ್ಲಿಕ್ ಸರ್ವಿಸ್ ಕಮಿಷನ್ ನಡೆಸಿದ ನಿಮ್ಮ ಅರ್ಹತಾ ನಾಗರಿಕ ಸೇವೆಗಳ ಪರೀಕ್ಷೆಯ ಆಧಾರದ ಮೇಲೆ ಭಾರತೀಯ ಕಂದಾಯ ಸೇವೆಗೆ (IRS) ನೇಮಕಾತಿಯನ್ನು ನೀಡಲು ಭಾರತ ಸರ್ಕಾರವು ಸಂತೋಷ ಪಡುತ್ತದೆ. 'ಆಫರ್ ಆಫ್ ಅಪಾಯಿಂಟ್‌ಮೆಂಟ್' ತಾತ್ಕಾಲಿಕವಾಗಿ ನಿಮ್ಮ ಚಾರಿತ್ರ್ಯಮತ್ತು ಪೂರ್ವಾಪರಗಳ ಪರಿಶೀಲನೆಗೆ  ಒಳಪಟ್ಟಿರುತ್ತದೆ.\"",
              "te": "ప్రియమైన అప్లికెంట్,\n \n యూనియన్ పబ్లిక్ సర్వీస్ కమిషన్ నిర్వహించిన సివిల్ సర్వీసెస్ పరీక్షలో మీ అర్హత ఆధారంగా ఇండియన్ అడ్మినిస్ట్రేటివ్ సర్వీస్ (IAS)కి మీకు అపాయింట్‌మెంట్‌ను అందించడానికి యూనియన్ పబ్లిక్ సర్వీస్ కమిషన్ సంతోషిస్తోంది.\n \n ‘అపాయింట్‌మెంట్ ఆఫర్’ తాత్కాలికమైనది మరియు ఇది మీ స్వభావం మరియు పూర్వాపరాల ధృవీకరణ పై ఆధారపడి ఉంటుంది.",
              "or": "ପ୍ରିୟ ଆବେଦନକାରୀ,\n \n ୟୁନିୟନ ପବ୍ଲିକ ସର୍ଭିସ କମିଶନଙ୍କ ଦ୍ qual ାରା ପରିଚାଳିତ ଯୋଗ୍ୟତା ସିଭିଲ ସର୍ଭିସେସ୍ ପରୀକ୍ଷା ଉପରେ ଆଧାର କରି ଆପଣଙ୍କୁ ଭାରତୀୟ ପ୍ରଶାସନିକ ସେବା (IAS) ରେ ନିଯୁକ୍ତି ପ୍ରଦାନ କରିବାକୁ ୟୁନିଅନ୍ ଜନସେବା ଆୟୋଗ ଆନନ୍ଦିତ |\n \n ତୁମର ନିଯୁକ୍ତି ଏବଂ ପ୍ରାଚୀନତା ଯାଞ୍ଚ ପାଇଁ ‘ନିଯୁକ୍ତି ଅଫର’ ସାମୟିକ ବିଷୟ ଅଟେ।",
              "as": "মৰমৰ আবেদনকাৰী, কেন্দ্ৰীয় লোক সেৱা আয়োগে অনুষ্ঠিত কৰা অসামৰিক সেৱা পৰীক্ষাত আপোনাৰ যোগ্যতাৰ আধাৰত ভাৰত চৰকাৰে আপোনাক ভাৰতীয় প্ৰশাসনিক সেৱা (IAS)ত নিযুক্তি প্ৰদান কৰি আনন্দিত হৈছে। 'নিযুক্তিৰ প্ৰস্তাৱ' আপোনাৰ চৰিত্ৰ আৰু পূৰ্ববৰ্তী সত্যাসত্যৰ নিৰূপণ সাপেক্ষে অস্থায়ী।",
              "gu": "પ્રિય અરજદાર, યુનિયન પબ્લિક સર્વિસ કમિશન તમને યુનિયન પબ્લિક સર્વિસ કમિશન દ્વારા આયોજિત તમારી લાયકાત ધરાવતી સિવિલ સર્વિસીસ પરીક્ષાના આધારે ભારતીય વહીવટી સેવા (IAS) માં નિમણૂકની અર્પણ કરતાં આનંદ અનુભવે છે. 'ઓફર ઑફ એપોઇન્ટમેન્ટ' તમારા પાત્ર અને પૂર્વજોની ચકાસણીને આધારે કામચલાઉ વિષય છે."
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_1_7",
              "hi": "E1_1_7",
              "ka": "E1_1_7",
              "te": "E1_1_7",
              "or": "E1_1_7",
              "as": "E1_1_7",
              "gu": "E1_1_7"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "With tears in his eyes, he calls Kuldeep and Badri to share this news with them. Both are lost for words, and tears of joy roll down their eyes.",
              "hi": "अपनी आंखों में आँसू लिए, वह कुलदीप और बद्री के साथ यह समाचार साझा करने के लिए उनको कॉल करता है। दोनों कुछ भी बोल नहीं पा रहे हैं, और खुशी के आँसू उनकी आंखों से बहने लगते हैं।",
              "ka": "ಈ ಸುದ್ದಿಯನ್ನು ಅವರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಲು ಅವನು ಕಣ್ಣೀರು ಹಾಕುತ್ತಾ ಕುಲದೀಪ್ ಮತ್ತು ಬೆದರಿಕೆ ಕರೆ ಮಾಡಿದರು. ಇಬ್ಬರೂ ಪದಗಳಿಗಾಗಿ ಕಳೆದುಹೋಗಿದ್ದಾರೆ ಮತ್ತು ಅವರ ಕಣ್ಣುಗಳಲ್ಲಿ ಸಂತೋಷದ ಕಣ್ಣೀರು ಹರಿಯುತ್ತದೆ.",
              "te": "కన్నీళ్లతో, ఈ వార్తను పంచుకోవడానికి అతను కుల్దీప్ మరియు బద్రిని పిలిచాడు. ఇద్దరూ మాటలు కోల్పోయారు, మరియు వారి కళ్లలో ఆనందభాష్పాలు తిరుగుతాయి.",
              "or": "ଆଖିରେ ଲୁହ ଆସି ସେ କୁଲଦୀପ ଏବଂ ବଦ୍ରିଙ୍କୁ ସେମାନଙ୍କ ସହ ଏହି ଖବର\n  ବାଣ୍ଟିବାକୁ ଡାକନ୍ତି | ଦୁହେଁ ଶବ୍ଦ ପାଇଁ ହଜିଯାଇଛନ୍ତି, ଏବଂ ଆଖିରୁ ଆନନ୍ଦର ଲୁହ ଝରିଯାଏ |",
              "as": "চকুত পানীৰে সৈতে তেওঁ কুলদীপ আৰু বদ্ৰীক ফোন কৰি এই খবৰটো তেওঁলোকৰ সৈতে ভাগ-বতৰা কৰে। দুয়োৰে মুখৰ মাত হেৰাই গৈছে, আৰু তেওঁলোকৰ চকুৰে আনন্দৰ চকুপানী ওলাই আহিছে।",
              "gu": "તેની આંખોમાં આંસુ સાથે, તે કુલદીપ અને બદ્રીને આ સમાચાર બતાવે માટે બોલાવે છે. બંને બોલવા માટે ખોવાઈ જાય છે, અને તેમની આંખોમાંથી આનંદના આંસુ વહી જાય છે."
            }
          }
        ]
      },
      "scene35": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_1",
              "hi": "E2_1_1",
              "ka": "E2_1_1",
              "te": "E2_1_1",
              "or": "E2_1_1",
              "as": "E2_1_1",
              "gu": "E2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav soon leaves for his training at the Lal Bahadur Shastri National Academy of Administration (LBSNAA) in Mussoorie.",
              "hi": "माधव जल्द ही मसूरी में लाल बहादुर शास्त्री नेशरी एकेडमीऑफ एडमिनिस्ट्रेशन (एलबीएसएनएए) में अपने प्रशिक्षण के लिए चला जाता है।",
              "ka": "ಮಾಧವ್ ಶೀಘ್ರದಲ್ಲೇ ಮಸ್ಸೂರಿಯಲ್ಲಿರುವ ಲಾಲ್ ಬಹದ್ದೂರ್ ಶಾಸ್ತ್ರಿ ನ್ಯಾಷನಲ್ ಅಕಾಡೆಮಿ ಆಫ್ ಅಡ್ಮಿನಿಸ್ಟ್ರೇಷನ್ (LBSNAA) ನಲ್ಲಿ ತರಬೇತಿಗಾಗಿ ಹೊರಡುತ್ತಾನೆ.",
              "te": "మాధవ్ త్వరలో ముస్సోరీలోని లాల్ బహదూర్ శాస్త్రి నేషనల్ అకాడమీ ఆఫ్ అడ్మినిస్ట్రేషన్ (LBSNAA)లో శిక్షణ కోసం బయలుదేరాడు.",
              "or": "ମାଧବ ଖୁବ୍ ଶୀଘ୍ର ମୁସୁରୀର ଲାଲ ବାହାଦୂର ଶାସ୍ତ୍ରୀ ନ୍ୟାସନାଲ୍ ଏକାଡେମୀ \n ଅଫ୍ ଆଡମିନିଷ୍ଟ୍ରେସନ୍ (LBSNAA) ରେ ତାଲିମ ପାଇଁ ବିଦାୟ ନେଇଛନ୍ତି |",
              "as": "মাধৱে সোনকালেই মুচৌৰীৰ লাল বাহাদুৰ শাস্ত্ৰী ৰাষ্ট্ৰীয় প্ৰশাসন একাডেমীত (LBSNAA) প্ৰশিক্ষণৰ বাবে ৰাওনা হয়।",
              "gu": "માધવ ટૂંક સમયમાં મસૂરીમાં લાલ બહાદુર શાસ્ત્રી નેશનલ એકેડેમી ઓફ એડમિનિસ્ટ્રેશન (LBSNAA)માં તેની તાલીમ માટે રવાના થાય છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_1b",
              "hi": "E2_1_1B",
              "ka": "E2_1_1b",
              "te": "E2_1_1b",
              "or": "E2_1_1b",
              "as": "E2_1_1b",
              "gu": "E2_1_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "It is the first time he is staying so far away from his friends. He also gets a small salary during the training period that secures Madhav’s expenses.",
              "hi": "यह पहली बार है जब वह अपने दोस्तों से इतनी दूर रह रहा है। उसे प्रशिक्षण अवधि के दौरान भी थोड़ा वेतन मिलता है जिससे माधव अपने खर्चे उठाता है।",
              "ka": "ಅವನು ತನ್ನ ಸ್ನೇಹಿತರಿಂದ ದೂರ ಉಳಿದಿರುವುದು ಇದೇ ಮೊದಲು. ತರಬೇತಿ ಅವಧಿಯಲ್ಲಿ ಮಾಧವ್‌ನ ಖರ್ಚುಗಳನ್ನು ಭದ್ರಪಡಿಸುವ ಸಣ್ಣ ಸಂಬಳವನ್ನೂ ಪಡೆಯುತ್ತಾನೆ.",
              "te": "అతను తన స్నేహితులకు దూరంగా ఉండటం అదే మొదటిసారి. అతని శిక్షణ కాలంలో మాధవ్ ఖర్చులకు సరిపోయేలా కొంచెం జీతం కూడా పొందుతాడు.",
              "or": "ପ୍ରଥମ ଥର ପାଇଁ ସେ ନିଜ ସାଙ୍ଗମାନଙ୍କଠାରୁ ଏତେ ଦୂରରେ ରୁହନ୍ତି |\n  ତାଲିମ ଅବଧିରେ ସେ ଅଳ୍ପ ଦରମା ମଧ୍ୟ ପାଇଥା’ନ୍ତି ଯାହାକି ମାଧବଙ୍କ ଖର୍ଚ୍ଚକୁ ସୁରକ୍ଷିତ କରିଥାଏ |",
              "as": "প্ৰথম বাৰৰ বাবে তেওঁ তেওঁৰ বন্ধুবৰ্গৰ পৰা ইমান দূৰত আছে। তেওঁ প্ৰশিক্ষণৰ সময়ছোৱাত এটা সৰু দৰমহাও লাভ কৰে যিয়ে মাধৱৰ খৰচ সুৰক্ষিত কৰে।",
              "gu": "તે પ્રથમ વખતે તેના મિત્રોથી આટલો દૂર રહે છે. તેને તાલીમના સમયગાળા દરમિયાન થોડો પગાર પણ મળે છે જે માધવના ખર્ચાઓને કાઢવા માટે ચાલે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_2",
              "hi": "E2_1_2",
              "ka": "E2_1_2",
              "te": "E2_1_2",
              "or": "E2_1_2",
              "as": "E2_1_2",
              "gu": "E2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He has law, management, accounting, history, politics, and economics classes by senior IAS officers and experts from various universities.",
              "hi": "उसके पास वरिष्ठ आईएएस अधिकारियों और विभिन्न विश्वविद्यालयों के विशेषज्ञों द्वारा कानून, प्रबंधन,  अकाउंटिंग/ लेखा, इतिहास, राजनीति और अर्थशास्त्र वर्ग हैं।",
              "ka": "ಅವನು ಹಿರಿಯ IAS ಅಧಿಕಾರಿಗಳು ಮತ್ತು ವಿವಿಧ ವಿಶ್ವವಿದ್ಯಾನಿಲಯಗಳ ತಜ್ಞರಿಂದ ಕಾನೂನು, ನಿರ್ವಹಣೆ, ಲೆಕ್ಕಪತ್ರ ನಿರ್ವಹಣೆ, ಇತಿಹಾಸ, ರಾಜಕೀಯ ಮತ್ತು ಅರ್ಥಶಾಸ್ತ್ರ ತರಗತಿಗಳನ್ನು ಹೊಂದಿದ್ದಾರೆ.",
              "te": "అతనికి వివిధ యూనివర్సిటీల నుండి సీనియర్ IAS అధికారులు మరియు నిపుణులచే లా, మేనేజ్మెంట్, అకౌంటింగ్, హిస్టరీ, పాలిటిక్స్ మరియు ఎకనామిక్స్ తరగతులు ఉన్నాయి.",
              "or": "ତାଙ୍କର ବରିଷ୍ଠ ଆଇଏଏସ୍ ଅଧିକାରୀ ଏବଂ ବିଭିନ୍ନ ବିଶ୍ୱବିଦ୍ୟାଳୟର ବିଶେଷଜ୍ଞଙ୍କ\n  ଦ୍ଵାରା ତାଙ୍କର ଆଇନ, ପରିଚାଳନା, ଆକାଉଣ୍ଟିଂ, ଇତିହାସ, ରାଜନୀତି ଏବଂ ଅର୍ଥନୀତି ଶ୍ରେଣୀ ରହିଛି।",
              "as": "তেওঁৰ জ্যেষ্ঠ IAS বিষয়া আৰু বিভিন্ন বিশ্ববিদ্যালয়ৰ বিশেষজ্ঞসকলৰ দ্বাৰা আইন, ব্যৱস্থাপনা, একাউণ্টিং, ইতিহাস, ৰাজনীতি আৰু অৰ্থনীতিৰ শ্ৰেণী আছে।",
              "gu": "તેની પાસે વિવિધ યુનિવર્સિટીઓના સિનિયર  IAS અધિકારીઓ અને નિષ્ણાતો દ્વારા કાયદો, વ્યવસ્થાપન, એકાઉન્ટિંગ, ઇતિહાસ, રાજકારણ અને અર્થશાસ્ત્રના વર્ગો છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_3",
              "hi": "E2_1_3",
              "ka": "E2_1_3",
              "te": "E2_1_3",
              "or": "E2_1_3",
              "as": "E2_1_3",
              "gu": "E2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "For their overall development, the officer trainees are also encouraged to participate in sports and clubs like drama, music, dance etc.",
              "hi": "अपने समग्र विकास के लिए, अधिकारी ट्रेनी को खेल और क्लब जैसे नाटक, संगीत, नृत्य आदि में भाग लेने के लिए भी प्रोत्साहित किया जाता है।",
              "ka": "ಅವರ ಒಟ್ಟಾರೆ ಅಭಿವೃದ್ಧಿಗಾಗಿ, ಅಧಿಕಾರಿ ತರಬೇತಿ ಪಡೆದವರು ನಾಟಕ, ಸಂಗೀತ, ನೃತ್ಯ ಮುಂತಾದ ಕ್ರೀಡೆಗಳು ಮತ್ತು ಕ್ಲಬ್‌ಗಳಲ್ಲಿ ಭಾಗವಹಿಸಲು ಪ್ರೋತ್ಸಾಹಿಸಲಾಗುತ್ತದೆ.",
              "te": "వారి మొత్తం అభివృద్ధి కోసం, ఆఫీసర్ ట్రైనీలు నాటకం, సంగీతం, నృత్యం మొదలైన క్రీడలు మరియు క్లబ్‌లలో కూడా పాల్గొనేలా ప్రోత్సహిస్తారు.",
              "or": "ସେମାନଙ୍କର ସାମଗ୍ରିକ ବିକାଶ ପାଇଁ, ଅଧିକାରୀ ତାଲିମ ପ୍ରାପ୍ତ ବ୍ୟକ୍ତିମାନେ\n  ନାଟକ, ସଙ୍ଗୀତ, ନୃତ୍ୟ ଇତ୍ୟାଦି କ୍ରୀଡା ଏବଂ କ୍ଲବରେ ଅଂଶଗ୍ରହଣ କରିବାକୁ ଉତ୍ସାହିତ ହୁଅନ୍ତି |",
              "as": "তেওঁলোকৰ সামগ্ৰিক বিকাশৰ বাবে, বিষয়া প্ৰশিক্ষাৰ্থীসকলক নাটক, সংগীত, নৃত্য আদিৰ দৰে ক্ৰীড়া আৰু ক্লাবত অংশগ্ৰহণ কৰিবলৈ উৎসাহিত কৰা হয়।",
              "gu": "તેના સર્વાંગી વિકાસ માટે, અધિકારી તાલીમાર્થીઓને નાટક, સંગીત, નૃત્ય વગેરે જેવી રમતો અને ક્લબમાં ભાગ લેવા માટે પણ પ્રોત્સાહિત કરવામાં આવે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_4",
              "hi": "E2_1_4",
              "ka": "E2_1_4",
              "te": "E2_1_4",
              "or": "E2_1_4",
              "as": "E2_1_4",
              "gu": "E2_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "As part of their physical endurance training, they go for a trek to the Himalayas. Madhav’s group goes to Narkanda.",
              "hi": "उनके शारीरिक सहनशक्ति प्रशिक्षण के हिस्से के रूप में, वे हिमालय पर एक ट्रेक के लिए जाते हैं। माधव का समूह नारकंद जाता है।",
              "ka": "ತಮ್ಮ ದೈಹಿಕ ಸಹಿಷ್ಣುತೆಯ ತರಬೇತಿಯ ಭಾಗವಾಗಿ, ಅವರು ಹಿಮಾಲಯಕ್ಕೆ ಚಾರಣಕ್ಕೆ ಹೋಗುತ್ತಾರೆ. ಮಾಧವನ ಗುಂಪು ನರಕಂದಕ್ಕೆ ಹೋಗುತ್ತದೆ.",
              "te": "వారి శారీరక దారుఢ్య శిక్షణలో భాగంగా, వారు హిమాలయాలకు ట్రెక్కింగ్ కోసం వెళతారు. మాధవ్ బృందం నార్కందకు వెళుతుంది.",
              "or": "ସେମାନଙ୍କର ଶାରୀରିକ ଧର୍ଯ୍ୟ ତାଲିମର ଏକ ଅଂଶ ଭାବରେ, ସେମାନେ \n ହିମାଳୟକୁ ଏକ ଟ୍ରେକ୍ ପାଇଁ ଯାଆନ୍ତି | ମାଧବଙ୍କ ଗୋଷ୍ଠୀ ନରକଣ୍ଡାକୁ ଯାଆନ୍ତି |",
              "as": "তেওঁলোকৰ শাৰীৰিক সহনশীলতাৰ প্ৰশিক্ষণৰ অংশ হিচাপে, তেওঁলোকে হিমালয়লৈ যাত্ৰা কৰিবলৈ যায়। মাধৱৰ দলটো নৰকন্দালৈ যায়।",
              "gu": "તેની શારીરિક સહનશક્તિની તાલીમના ભાગરૂપે, તેઓ હિમાલયના પ્રવાસ માટે જાય છે. માધવનું જૂથ નારકંડા જાય છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_4b///",
              "hi": "E2_1_4b ///////",
              "ka": "E2_1_4b///",
              "te": "E2_1_4b///",
              "or": "E2_1_4b///",
              "as": "E2_1_4b///",
              "gu": "E2_1_4b///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "They climb about 100kms in one week, sleep in tents, cook food in the open, and stay for three days at the height of 4500 m.",
              "hi": "वे एक सप्ताह में लगभग 100 किमी चढ़ाई करते हैं, तंबू में सोते हैं, खुले में खाना बनाते हैं, और 4500 मीटर की ऊंचाई पर तीन दिनों तक रहते हैं।",
              "ka": "ಅವರು ಒಂದು ವಾರದಲ್ಲಿ ಸುಮಾರು 100 ಕಿಲೋಮೀಟರ್‌ಗಳನ್ನು ಏರುತ್ತಾರೆ, ಟೆಂಟ್‌ಗಳಲ್ಲಿ ಮಲಗುತ್ತಾರೆ, ತೆರೆದ ಆಹಾರವನ್ನು ಬೇಯಿಸುತ್ತಾರೆ ಮತ್ತು 4500 ಮೀಟರ್ ಎತ್ತರದಲ್ಲಿ ಮೂರು ದಿನಗಳವರೆಗೆ ಇರುತ್ತಾರೆ.",
              "te": "వారు ఒక వారంలో 100 కిలోమీటర్లు అధిరోహిస్తారు, గుడారాలలో నిద్రిస్తారు, బహిరంగ ప్రదేశంలో ఆహారం వండుతారు మరియు 4500 మీటర్ల ఎత్తులో మూడు రోజులు ఉంటారు.",
              "or": "ସେମାନେ ଗୋଟିଏ ସପ୍ତାହରେ ପ୍ରାୟ ୧୦୦ କିଲୋମିଟର ଆରୋହଣ \n କରନ୍ତି, ତମ୍ବୁରେ ଶୋଇଥା’ନ୍ତି, ଖୋଲା ସ୍ଥାନରେ ଖାଦ୍ୟ ରାନ୍ଧନ୍ତି ଏବଂ ୪୫୦୦ ମିଟର ଉଚ୍ଚତାରେ ତିନି ଦିନ ରହନ୍ତି |",
              "as": "তেওঁলোকে এসপ্তাহত প্ৰায় ১০০কিলোমিটাৰ বগায়, তম্বুত শুৱে, মুকলিকৈ খাদ্য ৰান্ধে, আৰু ৪৫০০ মিটাৰ উচ্চতাত তিনি দিন থাকে।",
              "gu": "તેઓ એક અઠવાડિયામાં લગભગ 100 કિમી ચઢે છે, તંબુઓમાં સૂવે છે, ખુલ્લામાં ખોરાક રાંધે છે અને 4500 મીટરની ઊંચાઈ પર ત્રણ દિવસ રહે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_2_1",
              "hi": "E2_2_1",
              "ka": "E2_2_1",
              "te": "E2_2_1",
              "or": "E2_2_1",
              "as": "E2_2_1",
              "gu": "E2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As a part of the training, Madhav’s group visits a village in Jharkhand, named Kadirpur. They stay there and see real-life practical problems the villagers face daily.",
              "hi": "प्रशिक्षण के एक हिस्से के रूप में, माधव का समूह झारखंड के एक गांव कदीरपुर का दौरा करता है। वे वहां रहते हैं और वास्तविक जीवन की व्यावहारिक समस्याओं को दैनिक रूप से देखते हैं।",
              "ka": "ತರಬೇತಿಯ ಭಾಗವಾಗಿ, ಮಾಧವ್ ಅವರ ಗುಂಪು ಜಾರ್ಖಂಡ್‌ನ ಕದಿರ್‌ಪುರ ಎಂಬ ಹಳ್ಳಿಗೆ ಭೇಟಿ ನೀಡುತ್ತಾರೆ. ಅವರು ಅಲ್ಲಿಯೇ ಇರುತ್ತಾರೆ ಮತ್ತು ಹಳ್ಳಿಗರು ಪ್ರತಿದಿನ ಎದುರಿಸುತ್ತಿರುವ ನೈಜ-ಜೀವನದ ಪ್ರಾಯೋಗಿಕ ಸಮಸ್ಯೆಗಳನ್ನು ನೋಡುತ್ತಾರೆ.",
              "te": "శిక్షణలో భాగంగా, మాధవ్ బృందం జార్ఖండ్‌లోని కదిర్‌పూర్ అనే గ్రామాన్ని సందర్శించింది. వారు అక్కడే ఉండి, గ్రామస్తులు ప్రతిరోజూ ఎదుర్కొనే వాస్తవ జీవిత ఆచరణాత్మక సమస్యలను చూస్తారు.",
              "or": "ପ୍ରଶିକ୍ଷଣର ଏକ ଅଂଶ ଭାବରେ, ମାଧବଙ୍କ ଗୋଷ୍ଠୀ କାଦୀରପୁର ନାମକ \n ଝାଡ଼ଖଣ୍ଡର ଏକ ଗ୍ରାମ ପରିଦର୍ଶନ କରନ୍ତି | ସେମାନେ ସେଠାରେ ରୁହନ୍ତି ଏବଂ ଗ୍ରାମବାସୀମାନେ ପ୍ରତିଦିନ ସାମ୍ନା କରୁଥିବା ବାସ୍ତବ ଜୀବନର ବ୍ୟବହାରିକ ସମସ୍ୟା ଦେଖନ୍ତି |",
              "as": "প্ৰশিক্ষণৰ অংশ হিচাপে, মাধৱৰ গোটে কাদিৰপুৰ নামৰ ঝাৰখণ্ডৰ এখন গাওঁ পৰিদৰ্শন কৰে। তেওঁলোকে তাত থাকে আৰু গাওঁবাসীয়ে দৈনিক সন্মুখীন হোৱা বাস্তৱ জীৱনৰ ব্যৱহাৰিক সমস্যাবোৰ দেখে।",
              "gu": "તાલીમના ભાગરૂપે, માધવનું જૂથ ઝારખંડના કાદિરપુર નામના ગામની મુલાકાત લે છે. તેઓ ત્યાં રહે છે અને ગ્રામવાસીઓ દરરોજ સામનો કરતી વાસ્તવિક જીવન વ્યવહારિક સમસ્યાઓ જુએ છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_2_2",
              "hi": "E2_2_2",
              "ka": "E2_2_2",
              "te": "E2_2_2",
              "or": "E2_2_2",
              "as": "E2_2_2",
              "gu": "E2_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They also visit and understand the functioning of Primary Healthcare Centres (PHCs), village schools, and panchayats.",
              "hi": "वे प्राथमिक स्वास्थ्य देखभाल केंद्रों (पीएचसीएस), गांव के स्कूलों और पंचायतों पर भी जाते हैं और कामकाज समझते हैं।",
              "ka": "ಅವರು ಪ್ರಾಥಮಿಕ ಆರೋಗ್ಯ ಕೇಂದ್ರಗಳು (PHCಗಳು), ಹಳ್ಳಿ ಶಾಲೆಗಳು ಮತ್ತು ಪಂಚಾಯತ್‌ಗಳ ಕಾರ್ಯನಿರ್ವಹಣೆಯನ್ನು ಸಹ ಭೇಟಿ ಮಾಡುತ್ತಾರೆ ಮತ್ತು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "వారు ప్రాథమిక ఆరోగ్య సంరక్షణ కేంద్రాలు (PHCలు), గ్రామ పాఠశాలలు మరియు పంచాయతీలను కూడా సందర్శించి వారి పనితీరును అర్థం చేసుకుంటారు.",
              "or": "ସେମାନେ ପ୍ରାଥମିକ ସ୍ୱାସ୍ଥ୍ୟସେବା କେନ୍ଦ୍ର (PHCs), ଗ୍ରାମ ବିଦ୍ୟାଳୟ\n , ଏବଂ ପଞ୍ଚାୟତଗୁଡିକର କାର୍ଯ୍ୟକଳାପ ପରିଦର୍ଶନ କରନ୍ତି ଏବଂ ବୁ understand ନ୍ତି |",
              "as": "তেওঁলোকে প্ৰাথমিক স্বাস্থ্যসেৱা কেন্দ্ৰ (PHCs), গাঁৱৰ বিদ্যালয় আৰু পঞ্চায়তৰ কাম-কাজো পৰিদৰ্শন কৰে আৰু বুজ লয়।",
              "gu": "તેઓ પ્રાથમિક આરોગ્યસંભાળ કેન્દ્રો (PHC), ગામની શાળાઓ અને પંચાયતોની કામગીરીની પણ મુલાકાત લે છે અને સમજે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "Training",
              "hi": "प्रशिक्षण",
              "ka": "ತರಬೇತಿ",
              "te": "శిక్షణ",
              "or": "ପ୍ରଶିକ୍ଷଣ",
              "as": "প্ৰশিক্ষণ",
              "gu": "તાલીમ"
            }
          }
        ]
      },
      "scene36": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_1_1",
              "hi": "E3_1_1",
              "ka": "E3_1_1",
              "te": "E3_1_1",
              "or": "E3_1_1",
              "as": "E3_1_1",
              "gu": "E3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After completing his training, Madhav gets posted in the Banka district in Bihar as a sub-divisional magistrate.",
              "hi": "ट्रेनिंग पूरी करने के बाद माधव बिहार के बांका जिले में सब डिविजनल मजिस्ट्रेट के पद पर तैनात हो जाता है।",
              "ka": "ತನ್ನ ತರಬೇತಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ ನಂತರ, ಮಾಧವ್ ಬಿಹಾರದ ಬಂಕಾ ಜಿಲ್ಲೆಯಲ್ಲಿ ಉಪವಿಭಾಗೀಯ ಮ್ಯಾಜಿಸ್ಟ್ರೇಟ್ ಆಗಿ ನೇಮಕಗೊಳ್ಳುತ್ತಾನೆ.",
              "te": "శిక్షణ పూర్తయిన తర్వాత, మాధవ్ బీహార్‌లోని బంకా జిల్లాలో సబ్-డివిజనల్ మేజిస్ట్రేట్‌గా నియమితుడయ్యాడు.",
              "or": "ତାଲିମ ସମାପ୍ତ କରିବା ପରେ, ମାଧବ ବିହାରର ବଙ୍କା ଜିଲ୍ଲାରେ ସବ-ଡିଭିଜନାଲ\n  ମାଜିଷ୍ଟ୍ରେଟ ଭାବରେ ପୋଷ୍ଟ ହୋଇଥିଲେ।",
              "as": "প্ৰশিক্ষণ সম্পূৰ্ণ কৰাৰ পিছত, মাধৱে বিহাৰৰ বাংকা জিলাত উপ-বিভাগীয় দণ্ডাধীশ হিচাপে নিযুক্তি লাভ কৰে।",
              "gu": "તેની તાલીમ પૂર્ણ કર્યા પછી, માધવ બિહારના બાંકા જિલ્લામાં સબ-ડિવિઝનલ મેજિસ્ટ્રેટ તરીકે નિયુક્ત થાય છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_1_2",
              "hi": "E3_1_2",
              "ka": "E3_1_2",
              "te": "E3_1_2",
              "or": "E3_1_2",
              "as": "E3_1_2",
              "gu": "E3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As an IAS officer, Madhav is responsible for working on many different issues like health and hygiene, roads and infrastructure, youth employment, agriculture, education, etc.",
              "hi": "एक आईएएस अधिकारी के रूप में, माधव स्वास्थ्य और स्वच्छता, सड़कों और बुनियादी ढांचे, युवा रोजगार, कृषि, शिक्षा इत्यादि जैसे कई अलग-अलग मुद्दों पर काम करने के लिए उत्तरदायी है।",
              "ka": "ಐಎಎಸ್ ಅಧಿಕಾರಿಯಾಗಿ, ಮಾಧವ್ ಅವರು ಆರೋಗ್ಯ ಮತ್ತು ನೈರ್ಮಲ್ಯ, ರಸ್ತೆಗಳು ಮತ್ತು ಮೂಲಸೌಕರ್ಯ, ಯುವಕರ ಉದ್ಯೋಗ, ಕೃಷಿ, ಶಿಕ್ಷಣ ಇತ್ಯಾದಿಗಳಂತಹ ವಿವಿಧ ವಿಷಯಗಳಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಜವಾಬ್ದಾರಿಯನ್ನು ಹೊಂದಿದ್ದಾರೆ.",
              "te": "IAS అధికారిగా, మాధవ్ ఆరోగ్యం మరియు పరిశుభ్రత, రోడ్లు మరియు మౌలిక సదుపాయాలు, యువత ఉపాధి, వ్యవసాయం, విద్య మొదలైన అనేక విభిన్న సమస్యలపై పని చేయడానికి బాధ్యత వహిస్తారు.",
              "or": "ଜଣେ ଆଇଏଏସ୍ ଅଧିକାରୀ ଭାବରେ ସ୍ୱାସ୍ଥ୍ୟ ଏବଂ ସ୍ୱଚ୍ଛତା, ସଡକ ଏବଂ\n  ଭିତ୍ତିଭୂମି, ଯୁବକମାନଙ୍କ ନିଯୁକ୍ତି, କୃଷି, ଶିକ୍ଷା ଇତ୍ୟାଦି ବିଭିନ୍ନ ପ୍ରସଙ୍ଗରେ କାର୍ଯ୍ୟ କରିବା ପାଇଁ ମାଧବ ଦାୟୀ |",
              "as": "এজন IAS বিষয়া হিচাপে, মাধৱ স্বাস্থ্য আৰু পৰিচ্ছন্নতা, পথ আৰু আন্তঃগাঁথনি, যুৱ নিযুক্তি, কৃষি, শিক্ষা আদিৰ দৰে বিভিন্ন বিষয়ত কাম কৰাৰ বাবে দায়বদ্ধ।",
              "gu": "આઈએએસ અધિકારી તરીકે માધવ સ્વાસ્થ્ય અને સ્વચ્છતા, રસ્તાઓ અને ઈન્ફ્રાસ્ટ્રક્ચર, યુવા રોજગાર, કૃષિ, શિક્ષણ વગેરે જેવા વિવિધ મુદ્દાઓ પર કામ કરવા માટે જવાબદાર છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_1_3",
              "hi": "E3_1_3",
              "ka": "E3_1_3",
              "te": "E3_1_3",
              "or": "E3_1_3",
              "as": "E3_1_3",
              "gu": "E3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav wants to focus on improving the quality of education first because that is one of the most significant issues in the district.",
              "hi": "माधव शिक्षा की गुणवत्ता में सुधार करने पर ध्यान केंद्रित करना चाहता है क्योंकि यह जिले के सबसे महत्वपूर्ण मुद्दों में से एक है।",
              "ka": "ಮಾಧವ್ ಮೊದಲು ಶಿಕ್ಷಣದ ಗುಣಮಟ್ಟವನ್ನು ಸುಧಾರಿಸುವತ್ತ ಗಮನ ಹರಿಸಲು ಬಯಸುತ್ತಾನೆ. ಏಕೆಂದರೆ ಅದು ಜಿಲ್ಲೆಯ ಅತ್ಯಂತ ಮಹತ್ವದ ಸಮಸ್ಯೆಯಾಗಿದೆ.",
              "te": "జిల్లాలోని అత్యంత ముఖ్యమైన సమస్యలలో విద్య నాణ్యత ఒకటి కాబట్టి మొదట అది మెరుగుపరచడంపై దృష్టి పెట్టాలని మాధవ్ అనుకుంటాడు.",
              "or": "ମାଧବ ପ୍ରଥମେ ଶିକ୍ଷାର ଗୁଣାତ୍ମକ ମାନ ଉପରେ ଧ୍ୟାନ ଦେବାକୁ ଚାହୁଁଛନ୍ତି\n  କାରଣ ଏହା ଜିଲ୍ଲାର ଅନ୍ୟତମ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ପ୍ରସଙ୍ଗ ଅଟେ |",
              "as": "মাধৱে প্ৰথমে শিক্ষাৰ মানদণ্ড উন্নত কৰাৰ ওপৰত গুৰুত্ব দিব বিচাৰে কাৰণ সেইটো জিলাখনৰ আটাইতকৈ গুৰুত্বপূৰ্ণ বিষয়বোৰৰ ভিতৰত এটা।",
              "gu": "માધવ પ્રથમ શિક્ષણની ગુણવત્તા સુધારવા પર ધ્યાન કેન્દ્રિત કરવા માંગે છે કારણ કે તે જિલ્લામાં સૌથી મહત્વપૂર્ણ મુદ્દાઓ પૈકી એક છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_1_4",
              "hi": "E3_1_4",
              "ka": "E3_1_4",
              "te": "E3_1_4",
              "or": "E3_1_4",
              "as": "E3_1_4",
              "gu": "E3_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Schools in this district face many challenges such as poor infrastructure, lack of teachers and high dropout rates.",
              "hi": "इस जिले के स्कूलों को कई चुनौतियों का सामना करना पड़ता है जैसे खराब बुनियादी ढांचा, शिक्षकों की कमी और उच्च ड्रॉपआउट दर।",
              "ka": "ಈ ಜಿಲ್ಲೆಯ ಶಾಲೆಗಳು ಕಳಪೆ ಮೂಲಸೌಕರ್ಯ, ಶಿಕ್ಷಕರ ಕೊರತೆ ಮತ್ತು ಹೆಚ್ಚಿನ ಡ್ರಾಪ್‌ಔಟ್ ದರಗಳಂತಹ ಅನೇಕ ಸವಾಲುಗಳನ್ನು ಎದುರಿಸುತ್ತಿವೆ.",
              "te": "ఈ జిల్లాలోని పాఠశాలలు పేలవమైన మౌలిక సదుపాయాలు, ఉపాధ్యాయుల కొరత మరియు అధిక డ్రాపౌట్ రేట్లు వంటి అనేక సవాళ్లను ఎదుర్కొంటున్నాయి.",
              "or": "ଏହି ଜିଲ୍ଲାର ବିଦ୍ୟାଳୟଗୁଡ଼ିକ ଭିତ୍ତିଭୂମି ଖରାପ, ଶିକ୍ଷକଙ୍କ ଅଭାବ ଏବଂ \n ଉଚ୍ଚ ଡ୍ରପଆଉଟ୍ ହାର ଭଳି ଅନେକ ଆହ୍ face ାନର ସମ୍ମୁଖୀନ ହେଉଛନ୍ତି |",
              "as": "এই জিলাৰ বিদ্যালয়সমূহে বহুতো প্ৰত্যাহ্বানৰ সন্মুখীন হৈছে যেনে দুৰ্বল আন্তঃগাঁথনি, শিক্ষকৰ অভাৱ আৰু উচ্চ ড্ৰপ-আউটৰ হাৰ।",
              "gu": "આ જિલ્લાની શાળાઓ નબળું બાંધકામ, શિક્ષકોની અછત અને અધવચ્ચે બહાર નીકળી જનાર વિદ્યાર્થી ઊંચા દર જેવા ઘણા પડકારોનો સામનો કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_1",
              "hi": "E3_2_1",
              "ka": "E3_2_1",
              "te": "E3_2_1",
              "or": "E3_2_1",
              "as": "E3_2_1",
              "gu": "E3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He calls a meeting of all the principals and headteachers of the schools in the district to understand how he can support them to tackle the challenges they are facing.",
              "hi": "वह जिले के स्कूलों के सभी प्रधानाध्यापकों और मुख्य अध्यापकों की एक बैठक बुलाता है ताकि यह समझा जा सके कि वे उन चुनौतियों से निपटने के लिए उस्की सहायता कैसे कर सकते हैं जिनका वे सामना कर रहे हैं।",
              "ka": "ಅವರು ಎದುರಿಸುತ್ತಿರುವ ಸವಾಲುಗಳನ್ನು ನಿಭಾಯಿಸಲು ಅವರನ್ನು ಹೇಗೆ ಬೆಂಬಲಿಸಬಹುದು ಎನ್ನುವುದನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಅವನು ಜಿಲ್ಲೆಯ ಎಲ್ಲಾ ಶಾಲೆಗಳ ಮುಖ್ಯಶಿಕ್ಷಕರು ಮತ್ತು ಮುಖ್ಯ ಶಿಕ್ಷಕರ ಸಭೆಯನ್ನು ಕರೆಯುತ್ತಾನೆ.",
              "te": "వారు ఎదుర్కొంటున్న సవాళ్లను పరిష్కరించడానికి అతను ఎలా సహాయం చేయగలడో అర్థం చేసుకోవడానికి జిల్లాలోని అన్ని పాఠశాలల ప్రిన్సిపాల్స్ మరియు ప్రధానోపాధ్యాయులను పిలిచి సమావేశం ఏర్పాటు చేస్తాడు.",
              "or": "ଜିଲ୍ଲାର ସମସ୍ତ ପ୍ରଧାନଶିକ୍ଷକ ଏବଂ ପ୍ରଧାନଶିକ୍ଷକଙ୍କ ଏକ ବଇଠକ ସେ ଆହବାନ\n  କରୁଛନ୍ତି ଯେ ସେମାନେ ସମ୍ମୁଖୀନ ହେଉଥିବା ଆହ୍ଵାନର ମୁକାବିଲା ପାଇଁ ସେ କିପରି ସହାୟତା କରିପାରିବେ।",
              "as": "তেওঁ জিলাখনৰ বিদ্যালয়সমূহৰ সকলো অধ্যক্ষ আৰু প্ৰধান শিক্ষকৰ এখন সভা আহ্বান কৰে যাতে তেওঁলোকে সন্মুখীন হোৱা প্ৰত্যাহ্বানসমূহৰ মোকাবিলা কৰিবলৈ তেওঁ কেনেদৰে তেওঁলোকক সমৰ্থন কৰিব পাৰে বুজি পায়।",
              "gu": "તે જે પડકારોનો સામનો કરી રહ્યા છે તેનો સામનો કરવા માટે તે તેને કેવી રીતે ટેકો આપી શકે તે સમજવા માટે તે જિલ્લાની તમામ શાળાઓના આચાર્યો અને મુખ્ય શિક્ષકોની બેઠક બોલાવે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_2",
              "hi": "E3_2_2",
              "ka": "E3_2_2",
              "te": "E3_2_2",
              "or": "E3_2_2",
              "as": "E3_2_2",
              "gu": "E3_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav senses tension in the meeting. The headteachers are scared to share their thoughts, and Madhav feels it is essential to gain their trust.",
              "hi": "माधव ने बैठक में तनाव महसूस किया। मुख्य अध्यापक अपने विचार साझा करने से डरते हैं, और माधव का मानना ​​है कि उनका विश्वास हासिल करना आवश्यक है।",
              "ka": "ಮಾಧವ್ ಸಭೆಯಲ್ಲಿ ಉದ್ವಿಗ್ನತೆ ಇರುವುದನ್ನು ಗಮನಿಸಿದನು. ಮುಖ್ಯಶಿಕ್ಷಕರು ತಮ್ಮ ಆಲೋಚನೆಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ಹೆದರುತ್ತಾರೆ ಮತ್ತು ಅವರ ನಂಬಿಕೆಯನ್ನು ಗಳಿಸುವುದು ಅತ್ಯಗತ್ಯ ಎಂದು ಮಾಧವ್ ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "మాధవ్ మీటింగ్ లో టెన్షన్ పడ్డాడు. ప్రధానోపాధ్యాయులు తమ ఆలోచనలను పంచుకోవడానికి భయపడుతున్నారు మరియు వారి నమ్మకాన్ని పొందడం చాలా అవసరమని మాధవ్ భావిస్తున్నాడు.",
              "or": "ମାଧବ ସଭାରେ ଉତ୍ତେଜନା ଅନୁଭବ କରନ୍ତି | ପ୍ରଧାନ ଶିକ୍ଷକମାନେ \n ସେମାନଙ୍କର ଭାବନା ବାଣ୍ଟିବାକୁ ଡରୁଛନ୍ତି, ଏବଂ ମାଧବ ସେମାନଙ୍କ ବିଶ୍ୱାସ ହାସଲ କରିବା ଜରୁରୀ ବୋଲି ଅନୁଭବ କରୁଛନ୍ତି |",
              "as": "মাধৱে সভাত উত্তেজনা অনুভৱ কৰে। প্ৰধান শিক্ষকসকলে তেওঁলোকৰ চিন্তা-ভাবনা ভাগ-বতৰা কৰিবলৈ ভয় কৰে, আৰু মাধৱে অনুভৱ কৰে যে তেওঁলোকৰ বিশ্বাস অৰ্জন কৰাটো অত্যাৱশ্যকীয়।",
              "gu": "માધવને મીટિંગમાં તણાવનો અનુભવ થાય છે. મુખ્ય શિક્ષકો તેમના વિચારો જણાવતા ડરે છે, અને માધવને લાગે છે કે તેમનો વિશ્વાસ મેળવવો જરૂરી છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_3",
              "hi": "E3_2_3",
              "ka": "E3_2_3",
              "te": "E3_2_3",
              "or": "E3_2_3",
              "as": "E3_2_3",
              "gu": "E3_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "With Madhav’s assurance and conviction, slowly, the headteachers open up more and start sharing the challenges they’re facing.",
              "hi": "माधव के आश्वासन और दृढ़ विश्वास के साथ, धीरे-धीरे, प्रधानाध्यापक अपने मन की बात कहते हैं और उन चुनौतियों को साझा करना शुरू करते हैं, जिनका वे सामना कर रहे हैं।",
              "ka": "ಮಾಧವ್ ತನ್ನ ಭರವಸೆ ಮತ್ತು ಕನ್ವಿಕ್ಷನ್‌ನೊಂದಿಗೆ, ನಿಧಾನವಾಗಿ, ಮುಖ್ಯ ಶಿಕ್ಷಕರು ತಮ್ಮನ್ನು ತಾವು ಹೆಚ್ಚು ತೆರೆದುಕೊಳ್ಳುತ್ತಾರೆ ಮತ್ತು ಅವರು ಎದುರಿಸುತ್ತಿರುವ ಸವಾಲುಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ.",
              "te": "మాధవ్ యొక్క హామీ మరియు నమ్మకంతో, మెల్లగా, ప్రధానోపాధ్యాయులు స్వేచ్చగా వారు ఎదుర్కొంటున్న సవాళ్లను తెలపడం ప్రారంభించారు.",
              "or": "ମାଧବଙ୍କ ନିଶ୍ଚିତତା ଏବଂ ଦୃଧତା ସହିତ, ଧୀରେ ଧୀରେ, ପ୍ରଧାନ ଶିକ୍ଷକମାନେ\n  ଅଧିକ ସ୍ଥିର ଏବଂ ସେମାନେ ସମ୍ମୁଖୀନ ହେଉଥିବା ଆହ୍ଵାନଗୁଡିକ ବାଣ୍ଟିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "মাধৱৰ আশ্বাস আৰু দৃঢ়বিশ্বাসৰ সৈতে, লাহে লাহে, প্ৰধান শিক্ষকসকল অধিক মুকলি হৈ পৰে আৰু তেওঁলোকে সন্মুখীন হৈ থকা প্ৰত্যাহ্বানবোৰ ভাগ-বতৰা কৰা আৰম্ভ কৰে।",
              "gu": "માધવની ખાતરી અને ખાતરી સાથે, ધીમે ધીમે, મુખ્ય શિક્ષકો વધુ ખુલે છે અને તેઓ જે પડકારોનો સામનો કરી રહ્યાં છે તે શેર કરવાનું શરૂ કરે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_4////",
              "hi": "E3_2_4 /////",
              "ka": "E3_2_4////",
              "te": "E3_2_4////",
              "or": "E3_2_4////",
              "as": "E3_2_4////",
              "gu": "E3_2_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I think the communities here haven’t understood the power of education fully. They don’t know that if the children focus on their studies, they can get good and stable jobs.",
              "hi": "मुझे लगता है कि यहां के समुदाय ने शिक्षा की शक्ति को पूरी तरह से नहीं समझा है। वे नहीं जानते कि यदि बच्चे अपने अध्ययन पर ध्यान केंद्रित करते हैं, तो वे अच्छी और स्थिर नौकरियां प्राप्त कर सकते हैं।",
              "ka": "ಇಲ್ಲಿನ ಸಮುದಾಯಗಳು ಶಿಕ್ಷಣದ ಶಕ್ತಿಯನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಅರ್ಥಮಾಡಿಕೊಂಡಿಲ್ಲ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ಮಕ್ಕಳು ತಮ್ಮ ಅಧ್ಯಯನದತ್ತ ಗಮನ ಹರಿಸಿದರೆ, ಅವರು ಉತ್ತಮ ಮತ್ತು ಸ್ಥಿರವಾದ ಉದ್ಯೋಗವನ್ನು ಪಡೆಯಬಹುದು ಎಂದು ಅವರಿಗೆ ತಿಳಿದಿಲ್ಲ.",
              "te": "ఇక్కడి సంఘాలు విద్య యొక్క గొప్పతనాన్ని పూర్తిగా అర్థం చేసుకోలేదని నేను భావిస్తున్నాను. పిల్లలు చదువుపై దృష్టి పెడితే మంచి, స్థిరమైన ఉద్యోగాలు పొందవచ్చని వారికి తెలియదు.",
              "or": "ମୁଁ ଭାବୁଛି ଏଠାରେ ଥିବା ସମ୍ପ୍ରଦାୟଗୁଡ଼ିକ ଶିକ୍ଷାର ଶକ୍ତିକୁ ସମ୍ପୂର୍ଣ୍ଣ ରୂପେ\n  ବୁଝିନାହାଁନ୍ତି | ସେମାନେ ଜାଣନ୍ତି ନାହିଁ ଯେ ଯଦି ପିଲାମାନେ ସେମାନଙ୍କର ଅଧ୍ୟୟନ ଉପରେ ଧ୍ୟାନ ଦିଅନ୍ତି, ତେବେ ସେମାନେ ଭଲ ଏବଂ ସ୍ଥିର ଚାକିରି ପାଇପାରିବେ |",
              "as": "মই ভাবোঁ ইয়াত থকা সম্প্ৰদায়বোৰে শিক্ষাৰ শক্তি সম্পূৰ্ণৰূপে বুজি পোৱা নাই। তেওঁলোকে নাজানে যে যদি শিশুসকলে তেওঁলোকৰ অধ্যয়নত মনোনিৱেশ কৰে, তেওঁলোকে ভাল আৰু স্থিৰ চাকৰি পাব পাৰে।",
              "gu": "મને લાગે છે કે અહીંના સમુદાયો શિક્ષણની શક્તિને સંપૂર્ણ રીતે સમજી શક્યા નથી. તેઓ જાણતા નથી કે જો બાળકો તેમના અભ્યાસ પર ધ્યાન કેન્દ્રિત કરે તો તેઓ સારી અને સ્થિર નોકરી મેળવી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "It’s not just that; children who come to schools regularly cannot focus on studies. There are no toilets in the school, the classrooms are so dark and dirty, and the benches are also broken. How can one study in such an environment?",
              "hi": "इतना ही नहीं; जो बच्चे स्कूल आते हैं वे नियमित रूप से अध्ययन पर ध्यान केंद्रित नहीं कर सकते हैं। स्कूल में कोई शौचालय नहीं हैं, कक्षाएं इतनी अंधेरी और गंदी हैं, और बेंच भी टूटी हैं। इस तरह के वातावरण में कोई अध्ययन कैसे कर सकता है?",
              "ka": "ಇದು ಅಷ್ಟೇ ಅಲ್ಲ; ನಿತ್ಯ ಶಾಲೆಗೆ ಬರುವ ಮಕ್ಕಳು ಓದಿನತ್ತ ಗಮನ ಹರಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ಶಾಲೆಯಲ್ಲಿ ಶೌಚಾಲಯಗಳಿಲ್ಲ, ತರಗತಿ ಕೊಠಡಿಗಳು ಕತ್ತಲೆ ಮತ್ತು ಕೊಳಕು, ಬೆಂಚುಗಳು ಸಹ ಮುರಿದುಹೋಗಿವೆ. ಅಂತಹ ವಾತಾವರಣದಲ್ಲಿ ಒಬ್ಬರು ಹೇಗೆ ಅಧ್ಯಯನ ಮಾಡಬಹುದು?",
              "te": "ఇది మాత్రమే కాదు; నిత్యం పాఠశాలలకు వచ్చే పిల్లలు చదువుపై దృష్టి సారించలేకపోతున్నారు. పాఠశాలలో మరుగుదొడ్లు లేవు, తరగతి గదులు చాలా చీకటిగా మరియు మురికిగా ఉన్నాయి, మరియు బెంచీలు కూడా విరిగిపోయాయి. అలాంటి వాతావరణంలో ఎలా చదువుతారు?",
              "or": "ଏହା କେବଳ ନୁହେଁ; ନିୟମିତ ବିଦ୍ୟାଳୟକୁ ଆସୁଥିବା ପିଲାମାନେ ଅଧ୍ୟୟନ\n  ଉପରେ ଧ୍ୟାନ ଦେଇ ପାରିବେ ନାହିଁ | ବିଦ୍ୟାଳୟରେ କୌଣସି ଶୌଚାଳୟ ନାହିଁ, ଶ୍ରେଣୀଗୃହଗୁଡ଼ିକ ଏତେ ଅନ୍ଧକାର ଏବଂ ମଇଳା, ଏବଂ ବେଞ୍ଚଗୁଡିକ ମଧ୍ୟ ଭାଙ୍ଗିଯାଇଛି | ଏପରି ପରିବେଶରେ ଜଣେ କିପରି ଅଧ୍ୟୟନ କରିପାରିବ?",
              "as": "এয়া কেৱল সেয়াই নহয়; নিয়মীয়াকৈ বিদ্যালয়লৈ অহা শিশুৱে অধ্যয়নত মনোযোগ দিব নোৱাৰে। বিদ্যালয়ত কোনো শৌচালয় নাই, শ্ৰেণীকোঠাবোৰ ইমান অন্ধকাৰ আৰু লেতেৰা, আৰু বেঞ্চবোৰো ভাঙি গৈছে। এনে পৰিৱেশত কেনেকৈ অধ্যয়ন কৰিব পাৰে?",
              "gu": "તે માત્ર એટલું જ નથી; જે બાળકો શાળામાં નિયમિત આવે છે તે અભ્યાસ પર ધ્યાન કેન્દ્રિત કરી શકતા નથી. શાળામાં શૌચાલય નથી, વર્ગખંડો અંધારા અને ગંદા છે અને બેન્ચો પણ તૂટેલી છે. આવા વાતાવરણમાં વ્યક્તિ કેવી રીતે અભ્યાસ કરી શકે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "These are all complex problems to solve. But if we work together and take one step at a time, we can make progress.",
              "hi": "हल करने के लिए ये सभी जटिल समस्याएं हैं। लेकिन अगर हम एक साथ काम करते हैं और एक एक करके कदम उठाते हैं, तो हम प्रगति कर सकते हैं।",
              "ka": "ಇವೆಲ್ಲವೂ ಪರಿಹರಿಸಬೇಕಾದ ಸಂಕೀರ್ಣ ಸಮಸ್ಯೆಗಳು. ಆದರೆ ನಾವು ಒಟ್ಟಾಗಿ ಕೆಲಸ ಮಾಡಿದರೆ ಮತ್ತು ಒಂದೊಂದಾಗಿ ಹೆಜ್ಜೆ ಹಾಕಿದರೆ, ನಾವು ಪ್ರಗತಿ ಸಾಧಿಸಬಹುದು.",
              "te": "ఇవన్నీ పరిష్కరించాల్సిన సంక్లిష్ట సమస్యలు. కానీ మనం కలిసికట్టుగా పనిచేసి ఒక్కో అడుగు ముందుకు వేస్తే ప్రగతి సాధించవచ్చు.",
              "or": "ଏଗୁଡ଼ିକ ସମାଧାନ କରିବା ପାଇଁ ସମସ୍ତ ଜଟିଳ ସମସ୍ୟା | କିନ୍ତୁ ଯଦି \n ଆମେ ଏକାଠି କାମ କରିବା ଏବଂ ଗୋଟିଏ ଥରରେ ଗୋଟିଏ ପଦକ୍ଷେପ ନେବା, ଆମେ ଅଗ୍ରଗତି କରିପାରିବା |",
              "as": "এই সকলোবোৰ সমাধান কৰিবলৈ জটিল সমস্যা। কিন্তু যদি আমি একেলগে কাম কৰোঁ আৰু এবাৰত এটা পদক্ষেপ লওঁ, আমি উন্নতি কৰিব পাৰোঁ।",
              "gu": "આ બધી જટિલ સમસ્યાઓ હલ કરવાની છે. પરંતુ જો આપણે સાથે મળીને કામ કરીએ અને એક સમયે એક પગલું ભરીએ તો આપણે પ્રગતિ કરી શકીએ છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Where do we start?",
              "hi": "हम कहाँ से शुरू करें?",
              "ka": "ನಾವು ಎಲ್ಲಿಂದ ಪ್ರಾರಂಭಿಸುವುದು?",
              "te": "మనం ఎక్కడ ప్రారంభించాలి?",
              "or": "ଆମେ କେଉଁଠାରୁ ଆରମ୍ଭ କରିବା?",
              "as": "আমি ক'ৰ পৰা আৰম্ভ কৰিম?",
              "gu": "આપણે ક્યાંથી શરૂઆત કરીએ?"
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_5_D",
              "hi": "E3_2_5_D",
              "ka": "E3_2_5_D",
              "te": "E3_2_5_D",
              "or": "E3_2_5_D",
              "as": "E3_2_5_D",
              "gu": "E3_2_5_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What issue should Madhav start with?",
              "hi": "माधव को किस मुद्दे से शुरूआत करना चाहिए?",
              "ka": "ಮಾಧವ್ ಯಾವ ಸಮಸ್ಯೆಯ ಬಗ್ಗೆ ಮೊದಲು ಪ್ರಾರಂಭಿಸಬೇಕು?",
              "te": "మాధవ్ ఏ సమస్యతో ప్రారంభించాలి?",
              "or": "ମାଧବ କେଉଁ ପ୍ରସଙ୍ଗରୁ ଆରମ୍ଭ କରିବା ଉଚିତ୍?",
              "as": "মাধৱে কি সমস্যাৰে আৰম্ভ কৰা উচিত?",
              "gu": "માધવે કયા મુદ્દાથી શરૂઆત કરવી જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Improving school infrastructure",
              "hi": "स्कूल बुनियादी ढांचे में सुधार",
              "ka": "ಶಾಲೆಯ ಮೂಲಸೌಕರ್ಯವನ್ನು ಸುಧಾರಿಸುವುದು",
              "te": "పాఠశాల మౌలిక సదుపాయాలను మెరుగుపరచడం",
              "or": "ବିଦ୍ୟାଳୟର ଭିତ୍ତିଭୂମିରେ ଉନ୍ନତି ଆଣିବା |",
              "as": "বিদ্যালয়ৰ আন্তঃগাঁথনি উন্নত কৰা",
              "gu": "શાળાના માળખામાં સુધારો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Increasing awareness about education",
              "hi": "शिक्षा के बारे में जागरूकता बढ़ाना",
              "ka": "ಶಿಕ್ಷಣದ ಬಗ್ಗೆ ಜಾಗೃತಿಯನ್ನು ಹೆಚ್ಚಿಸುವುದು",
              "te": "విద్యపై అవగాహన పెంచడం",
              "or": "ଶିକ୍ଷା ବିଷୟରେ ସଚେତନତା ବୃଦ୍ଧି",
              "as": "শিক্ষাৰ বিষয়ে সজাগতা বৃদ্ধি কৰা",
              "gu": "શિક્ષણ પ્રત્યે જાગૃતિ વધારવી"
            }
          }
        ]
      },
      "scene37": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Improving school infrastructure",
              "hi": "स्कूल बुनियादी ढांचे में सुधार",
              "ka": "ಶಾಲೆಯ ಮೂಲಸೌಕರ್ಯವನ್ನು ಸುಧಾರಿಸುವುದು",
              "te": "పాఠశాల మౌలిక సదుపాయాలను మెరుగుపరచడం",
              "or": "ବିଦ୍ୟାଳୟର ଭିତ୍ତିଭୂମିରେ ଉନ୍ନତି ଆଣିବା |",
              "as": "বিদ্যালয়ৰ আন্তঃগাঁথনি উন্নত কৰা",
              "gu": "શાળાના માળખામાં સુધારો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to work on the infrastructure of the schools in the district. He feels that a school can become an enjoyable place for students with proper facilities.",
              "hi": "माधव जिले के स्कूलों के बुनियादी ढांचे पर काम करने का फैसला करता है। उसे लगता है कि उचित सुविधाओं वाला एक स्कूल  छात्रों के लिए एक सुखद जगह बन सकता है।",
              "ka": "ಮಾಧವ್ ಜಿಲ್ಲೆಯ ಶಾಲೆಗಳ ಮೂಲಸೌಕರ್ಯಗಳ ಮೇಲೆ ಕೆಲಸ ಮಾಡಲು ನಿರ್ಧರಿಸಿದ್ದಾನೆ. ಸರಿಯಾದ ಸೌಲಭ್ಯಗಳೊಂದಿಗೆ ಶಾಲೆಯು ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಆನಂದದಾಯಕ ಸ್ಥಳವಾಗಬಹುದು ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "జిల్లాలోని పాఠశాలల్లో మౌలిక సదుపాయాలపై దృష్టి పెట్టాలని మాధవ్ నిర్ణయించారు. సరైన సౌకర్యాలు ఉంటే పాఠశాల విద్యార్థులకు ఆనందదాయకమైన ప్రదేశంగా మారుతుందని ఆయన అభిప్రాయపడ్డారు.",
              "or": "ମାଧବ ଜିଲ୍ଲାର ବିଦ୍ୟାଳୟଗୁଡ଼ିକର ଭିତ୍ତିଭୂମି ଉପରେ କାର୍ଯ୍ୟ କରିବାକୁ\n  ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ସେ ଅନୁଭବ କରନ୍ତି ଯେ ଉପଯୁକ୍ତ ସୁବିଧା ଥିବା ଛାତ୍ରମାନଙ୍କ ପାଇଁ ଏକ ବିଦ୍ୟାଳୟ ଏକ ଉପଭୋଗ୍ୟ ସ୍ଥାନ ହୋଇପାରେ |",
              "as": "মাধৱে জিলাখনৰ বিদ্যালয়সমূহৰ আন্তঃগাঁথনিৰ ওপৰত কাম কৰাৰ সিদ্ধান্ত লয়। তেওঁ অনুভৱ কৰে যে সঠিক সুবিধা থকা এখন বিদ্যালয় শিক্ষাৰ্থীসকলৰ বাবে এক উপভোগ্য স্থান হ'ব পাৰে।",
              "gu": "માધવ જિલ્લાની શાળાઓના બાંધકમ પર કામ કરવાનું નક્કી કરે છે. તેમને લાગે છે કે શાળા યોગ્ય સુવિધાઓ સાથે વિદ્યાર્થીઓ માટે આનંદપ્રદ સ્થળ બની શકે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3(i)_1_2",
              "hi": "E3 (i) _1_2",
              "ka": "E3(i)_1_2",
              "te": "E3(i)_1_2",
              "or": "E3(i)_1_2",
              "as": "E3(i)_1_2",
              "gu": "E3(i)_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He works with other officials in the district to plan how they’re going to improve the facilities in the school. They also come up with a budget required for this plan.",
              "hi": "वह जिले के अन्य अधिकारियों के साथ मिलकर योजना बनाता है कि वे स्कूल में सुविधाओं को कैसे सुधारेंगे। वे इस योजना के लिए आवश्यक बजट भी बनाते हैं।",
              "ka": "ಶಾಲೆಯಲ್ಲಿನ ಸೌಲಭ್ಯಗಳನ್ನು ಹೇಗೆ ಸುಧಾರಿಸಬಹುದು  ಎಂಬುದನ್ನು ಯೋಜಿಸಲು ಅವನು ಜಿಲ್ಲೆಯ ಇತರ ಅಧಿಕಾರಿಗಳೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುತ್ತಾನೆ. ಅವನು ಈ ಯೋಜನೆಗೆ ಅಗತ್ಯವಿರುವ ಬಜೆಟ್‌ನೊಂದಿಗೆ ಬರುತ್ತಾನೆ.",
              "te": "అతను జిల్లాలోని ఇతర అధికారులతో కలిసి పాఠశాలలో సౌకర్యాలను ఎలా మెరుగుపరచబోతున్నారో ప్లాన్ చేయడానికి పని చేస్తాడు. ఈ ప్లాన్‌కు అవసరమైన బడ్జెట్‌ను కూడా వారు రూపొందిస్తారు.",
              "or": "ସେମାନେ ଜିଲ୍ଲାର ଅନ୍ୟ ଅଧିକାରୀଙ୍କ ସହ ମିଶି ବିଦ୍ୟାଳୟର ସୁବିଧାଗୁଡ଼ିକର\n  ଉନ୍ନତି ପାଇଁ ଯୋଜନା କରୁଛନ୍ତି। ସେମାନେ ମଧ୍ୟ ଏହି ଯୋଜନା ପାଇଁ ଆବଶ୍ୟକ ବଜେଟ୍ ଆଣିଛନ୍ତି |",
              "as": "তেওঁ জিলাখনৰ আন বিষয়াসকলৰ সৈতে তেওঁলোকে বিদ্যালয়ৰ সুবিধাবোৰ কেনেদৰে উন্নত কৰিব তাৰ পৰিকল্পনা কৰিবলৈ কাম কৰে। তেওঁলোকে এই পৰিকল্পনাৰ বাবে প্ৰয়োজনীয় বাজেটো লৈ আহে।",
              "gu": "તેઓ શાળામાં સુવિધાઓ કેવી રીતે સુધારવા જઈ રહ્યાં છે તેની યોજના બનાવવા માટે તેઓ જિલ્લાના અન્ય અધિકારીઓ સાથે કામ કરે છે. તેઓ આ યોજના માટે જરૂરી બજેટ સાથે પણ આવે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3(i)_1_3",
              "hi": "E3 (i) _1_3",
              "ka": "E3(i)_1_3",
              "te": "E3(i)_1_3",
              "or": "E3(i)_1_3",
              "as": "E3(i)_1_3",
              "gu": "E3(i)_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Their plan includes building proper toilets in schools and providing safe drinking water. They also hope to make playgrounds for students.",
              "hi": "उनकी योजना में स्कूलों में उचित शौचालय बनाना और सुरक्षित पेयजल उपलब्ध कराना शामिल है। वे छात्रों के लिए खेल के मैदान बनाने की भी उम्मीद करते हैं।",
              "ka": "ಆತನ ಯೋಜನೆಯು ಶಾಲೆಗಳಲ್ಲಿ ಸರಿಯಾದ ಶೌಚಾಲಯಗಳನ್ನು ನಿರ್ಮಿಸುವುದು ಮತ್ತು ಸುರಕ್ಷಿತ ಕುಡಿಯುವ ನೀರನ್ನು ಒದಗಿಸುವುದನ್ನು ಒಳಗೊಂಡಿದೆ. ಆತನು ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಆಟದ ಮೈದಾನಗಳನ್ನು ನೀಡಲು ಆಶಿಸಿದ್ದಾರೆ.",
              "te": "పాఠశాలల్లో సరైన మరుగుదొడ్లు నిర్మించడం, రక్షిత మంచినీరు అందించడం వంటివి తమ ప్లాన్ లో ఉన్నాయి. విద్యార్థులకు ఆట స్థలాలు కూడా ఏర్పాటు చేయాలని వారు భావిస్తున్నారు.",
              "or": "ସେମାନଙ୍କ ଯୋଜନାରେ ବିଦ୍ୟାଳୟରେ ଉପଯୁକ୍ତ ଶୌଚାଳୟ ନିର୍ମାଣ ଏବଂ\n  ନିରାପଦ ପାନୀୟ ଜଳ ଯୋଗାଇବା ଅନ୍ତର୍ଭୁକ୍ତ | ସେମାନେ ମଧ୍ୟ ଛାତ୍ରମାନଙ୍କ ପାଇଁ ଖେଳ ପଡିଆ ତିଆରି କରିବାକୁ ଆଶା କରନ୍ତି |",
              "as": "তেওঁলোকৰ পৰিকল্পনাত অন্তৰ্ভুক্ত আছে বিদ্যালয়ত সঠিক শৌচালয় নিৰ্মাণ কৰা আৰু সুৰক্ষিত খোৱা পানী প্ৰদান কৰা। তেওঁলোকে শিক্ষাৰ্থীসকলৰ বাবে খেলপথাৰ নিৰ্মাণ কৰাৰো আশা কৰে।",
              "gu": "તેમની યોજનામાં શાળાઓમાં યોગ્ય શૌચાલય બનાવવા અને પીવાનું સલામત પાણી પૂરું પાડવાનો સમાવેશ થાય છે. તેઓ વિદ્યાર્થીઓ માટે રમતનું મેદાન બનાવવાની પણ આશા રાખે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3(i)_1_4",
              "hi": "E3 (i) _1_4",
              "ka": "E3(i)_1_4",
              "te": "E3(i)_1_4",
              "or": "E3(i)_1_4",
              "as": "E3(i)_1_4",
              "gu": "E3(i)_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav has some trouble getting the budget approved by the higher authorities, but because of his persistence, they eventually approve.",
              "hi": "माधव को उच्च अधिकारियों द्वारा अनुमोदित बजट प्राप्त करने में कुछ समस्या होती है, लेकिन उसकी दृढ़ता के कारण, वे अंततः स्वीकृति देते हैं।",
              "ka": "ಮಾಧವ್ ತನ್ನ ಬಜೆಟ್ ಅನ್ನು ಉನ್ನತ ಅಧಿಕಾರಿಗಳಿಂದ ಅನುಮೋದಿಸಲು ಕೆಲವು ತೊಂದರೆಗಳನ್ನು ಎದುರಿಸುತ್ತಿದ್ದಾನೆ, ಆದರೆ ಅವನ ಹಠದ ಕಾರಣ, ಅವರು ಅಂತಿಮವಾಗಿ ಅನುಮೋದಿಸುತ್ತಾರೆ.",
              "te": "బడ్జెట్‌ను ఉన్నతాధికారులు ఆమోదించడంలో మాధవ్‌కు కొంత ఇబ్బంది ఉంది, కానీ అతని పట్టుదల కారణంగా, చివరికి వారు ఆమోదించారు.",
              "or": "ଉଚ୍ଚ କର୍ତ୍ତୃପକ୍ଷଙ୍କ ଦ୍ଵାରା ବଜେଟ୍ ଅନୁମୋଦନ ପାଇବାରେ\n  ମାଧବଙ୍କର କିଛି ଅସୁବିଧା ଅଛି, କିନ୍ତୁ ତାଙ୍କର ସ୍ଥିରତା ହେତୁ ସେମାନେ ଶେଷରେ ଅନୁମୋଦନ କଲେ |",
              "as": "মাধৱে উচ্চ কৰ্তৃপক্ষৰ দ্বাৰা বাজেট অনুমোদিত কৰাত কিছু সমস্যা হয়, কিন্তু তেওঁৰ অধ্যৱসায়ৰ বাবে, তেওঁলোকে অৱশেষত অনুমোদন জনায়।",
              "gu": "માધવને ઉચ્ચ અધિકારીઓ દ્વારા બજેટ મંજૂર કરવામાં થોડી મુશ્કેલી પડે છે, પરંતુ તેની જીદને કારણે તેઓ આખરે મંજૂર કરે છે."
            }
          }
        ]
      },
      "scene38": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Increasing awareness about education",
              "hi": "शिक्षा के बारे में जागरूकता बढ़ाना",
              "ka": "ಶಿಕ್ಷಣದ ಬಗ್ಗೆ ಜಾಗೃತಿಯನ್ನು ಹೆಚ್ಚಿಸುವುದು",
              "te": "విద్యపై అవగాహన పెంచడం",
              "or": "ଶିକ୍ଷା ବିଷୟରେ ସଚେତନତା ବୃଦ୍ଧି",
              "as": "শিক্ষাৰ বিষয়ে সজাগতা বৃদ্ধি কৰা",
              "gu": "શિક્ષણ પ્રત્યે જાગૃતિ વધારવી"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav decides to spread awareness about education among the people in the district. He feels that if everyone realises the importance of an issue, they will support him in his work.",
              "hi": "माधव जिले के लोगों के बीच शिक्षा के बारे में जागरूकता फैलाने का फैसला करता है। वह महसूस करता है कि अगर हर कोई किसी मुद्दे के महत्व को महसूस करता है, तो वे उसके काम में अपना समर्थन करेंगे।",
              "ka": "ಜಿಲ್ಲೆಯ ಜನರಲ್ಲಿ ಶಿಕ್ಷಣದ ಬಗ್ಗೆ ಜಾಗೃತಿ ಮೂಡಿಸಲು ಮಾಧವ್ ನಿರ್ಧರಿಸಿದ್ದಾನೆ. ಪ್ರತಿಯೊಬ್ಬರೂ ಸಮಸ್ಯೆಯ ಮಹತ್ವವನ್ನು ಅರಿತುಕೊಂಡರೆ, ತನ್ನ ಕೆಲಸಕ್ಕೆ ಅವರ ಬೆಂಬಲ ಸಿಗುತ್ತದೆ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "జిల్లాలోని ప్రజలకు విద్యపై అవగాహన కల్పించాలని మాధవ్ నిర్ణయించుకున్నారు. ప్రతి ఒక్కరూ ఒక సమస్య యొక్క ప్రాముఖ్యతను గుర్తిస్తే, వారు తన పనిలో తనకు మద్దతు ఇస్తారని అతను భావిస్తున్నాడు.",
              "or": "ଜିଲ୍ଲାର ଲୋକଙ୍କ ମଧ୍ୟରେ ଶିକ୍ଷା ବିଷୟରେ ସଚେତନତା ସୃଷ୍ଟି କରିବାକୁ\n  ମାଧବ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି। ସେ ଅନୁଭବ କରନ୍ତି ଯେ ଯଦି ସମସ୍ତେ ଏକ ପ୍ରସଙ୍ଗର ମହତ୍ତ୍ୱ ବୁ izes ନ୍ତି, ତେବେ ସେମାନେ ତାଙ୍କ କାର୍ଯ୍ୟରେ ତାଙ୍କୁ ସମର୍ଥନ କରିବେ |",
              "as": "মাধৱে জিলাখনৰ লোকসকলৰ মাজত শিক্ষাৰ বিষয়ে সজাগতা বিয়পাবলৈ সিদ্ধান্ত লয়। তেওঁ অনুভৱ কৰে যে যদি সকলোৱে এটা সমস্যাৰ গুৰুত্ব উপলব্ধি কৰে, তেওঁলোকে তেওঁৰ কামক সমৰ্থন কৰিব।",
              "gu": "માધવ જિલ્લામાં લોકોમાં શિક્ષણ પ્રત્યે જાગૃતિ ફેલાવવાનું નક્કી કરે છે. તેને લાગે છે કે જો દરેકને કોઈ મુદ્દાનું મહત્વ સમજાશે તો તેઓ તેને તેના કામમાં સાથ આપશે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3(ii)_1_2",
              "hi": "E3 (ii) _1_2",
              "ka": "E3(ii)_1_2",
              "te": "E3(ii)_1_2",
              "or": "E3(ii)_1_2",
              "as": "E3(ii)_1_2",
              "gu": "E3(ii)_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He writes to influential people in the district about the problems schools face and appeals to them to come forward and help in any way they can.",
              "hi": "वह जिले के प्रभावशाली लोगों को समस्याओं के बारे में लिखता है जिनका स्कूलों को सामना करना पड़ता है और उन्हें आगे आने और किसी भी तरह से मदद करने के लिए अपील करता है।",
              "ka": "ಶಾಲೆಗಳು ಎದುರಿಸುತ್ತಿರುವ ಸಮಸ್ಯೆಗಳ ಬಗ್ಗೆ ಜಿಲ್ಲೆಯ ಪ್ರಭಾವಿ ವ್ಯಕ್ತಿಗಳಿಗೆ ಪತ್ರ ಬರೆದು ಮುಂದೆ ಬಂದು ತಮಗೆ ಸಾಧ್ಯವಿರುವ ರೀತಿಯಲ್ಲಿ ಸಹಾಯ ಮಾಡುವಂತೆ ಮನವಿ ಮಾಡುತ್ತಾನೆ.",
              "te": "పాఠశాలలు ఎదుర్కొంటున్న సమస్యల గురించి జిల్లాలోని ప్రభావవంతమైన వ్యక్తులకు లేఖ వ్రాసి, వారు ముందుకు వచ్చి వారికి ఏ విధంగానైనా సహాయం చేయాలని విజ్ఞప్తి చేశారు.",
              "or": "ବିଦ୍ୟାଳୟଗୁଡ଼ିକର ସମ୍ମୁଖୀନ ହେଉଥିବା ସମସ୍ୟା ବିଷୟରେ ସେ ଜିଲ୍ଲାର\n  ପ୍ରଭାବଶାଳୀ ଲୋକଙ୍କୁ ଲେଖିଛନ୍ତି ଏବଂ ସେମାନଙ୍କୁ ଯେକୌଣସି ପ୍ରକାରେ ସାହାଯ୍ୟ କରିବାକୁ ସେମାନଙ୍କୁ ନିବେଦନ କରିଛନ୍ତି |",
              "as": "তেওঁ জিলাখনৰ প্ৰভাৱশালী লোকসকলৈ বিদ্যালয়সমূহে সন্মুখীন হোৱা সমস্যাবোৰৰ বিষয়ে লিখিছে আৰু তেওঁলোকক আগবাঢ়ি আহি যিকোনো ধৰণে সহায় কৰিবলৈ আহ্বান জনাইছে।",
              "gu": "તે જિલ્લાના પ્રભાવશાળી લોકોને શાળાઓને જે સમસ્યાઓનો સામનો કરવો પડે છે તેના વિશે પત્ર લખે છે અને તેઓને આગળ આવવા અને કોઈપણ રીતે મદદ કરવા અપીલ કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3(ii)_1_3",
              "hi": "E3 (ii) _1_3",
              "ka": "E3(ii)_1_3",
              "te": "E3(ii)_1_3",
              "or": "E3(ii)_1_3",
              "as": "E3(ii)_1_3",
              "gu": "E3(ii)_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He holds meetings with village heads and ward members who help him spread his message.",
              "hi": "वह गांव के प्रमुखों और वार्ड सदस्यों के साथ बैठकें रखता है जो उसे अपना संदेश फैलाने में मदद करते हैं।",
              "ka": "ಅವನು ತನ್ನ ಸಂದೇಶವನ್ನು ಹರಡಲು ಸಹಾಯ ಮಾಡುವ ಗ್ರಾಮದ ಮುಖಂಡರು ಮತ್ತು ವಾರ್ಡ್ ಸದಸ್ಯರೊಂದಿಗೆ ಸಭೆಗಳನ್ನು ನಡೆಸುತ್ತಾನೆ.",
              "te": "అతను తన సందేశాన్ని వ్యాప్తి చేయడంలో సహాయపడడానికి గ్రామ పెద్దలు మరియు వార్డు సభ్యులతో సమావేశాలు నిర్వహిస్తాడు.",
              "or": "ସେ ଗାଁ ମୁଖିଆ ଏବଂ ୱାର୍ଡ ସଦସ୍ୟଙ୍କ ସହ ବଇଠକ କରନ୍ତି, ଯେଉଁମାନେ\n  ତାଙ୍କୁ ବାର୍ତ୍ତା ବିସ୍ତାର କରିବାରେ ସାହାଯ୍ୟ କରନ୍ତି |",
              "as": "তেওঁ গাঁৱৰ মুৰব্বী আৰু ৱাৰ্ডৰ সদস্যসকলৰ সৈতে বৈঠক কৰে যিয়ে তেওঁৰ বাৰ্তা প্ৰচাৰ কৰাত সহায় কৰে।",
              "gu": "તે ગામના વડાઓ અને વોર્ડ સભ્યો સાથે બેઠકો કરે છે જેઓ તેમને તેમનો સંદેશ ફેલાવવામાં મદદ કરે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3(ii)_2_1",
              "hi": "E3 (II) _2_1",
              "ka": "E3(ii)_2_1",
              "te": "E3(ii)_2_1",
              "or": "E3(ii)_2_1",
              "as": "E3(ii)_2_1",
              "gu": "E3(ii)_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav learns that many children and parents have no idea about different careers after studies. Madhav decides to initiate a counselling programme.",
              "hi": "माधव देखता है कि अनेक बच्चों और माता-पिता को अध्ययन के बाद  भिन्न - भिन्न करियर के बारे में कोई जानकारी नहीं है। माधव ने एक परामर्श कार्यक्रम शुरू करने का निश्चय किया।",
              "ka": "ಅನೇಕ ಮಕ್ಕಳು ಮತ್ತು ಪೋಷಕರಿಗೆ ಅಧ್ಯಯನದ ನಂತರ ವಿವಿಧ ವೃತ್ತಿಗಳ ಬಗ್ಗೆ ತಿಳಿದಿಲ್ಲ ಎನ್ನುವುದನ್ನು ಮಾಧವ್ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆ. ಮಾಧವ್ ಕೌನ್ಸೆಲಿಂಗ್ ಕಾರ್ಯಕ್ರಮವನ್ನು ಪ್ರಾರಂಭಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "చాలా మంది పిల్లలు మరియు తల్లిదండ్రులకు చదువు తర్వాత వేరే కెరీర్‌ల గురించి తెలియదు అని మాధవ్ తెలుసుకుంటాడు. మాధవ్ కౌన్సెలింగ్ కార్యక్రమాన్ని ప్రారంభించాలని నిర్ణయించుకున్నాడు.",
              "or": "ମାଧବ ଜାଣନ୍ତି ଯେ ଅଧ୍ୟୟନ ପରେ ବିଭିନ୍ନ ପିଲା ଏବଂ ପିତାମାତାଙ୍କର\n  ବିଭିନ୍ନ ବୃତ୍ତି ବିଷୟରେ କ idea ଣସି ଧାରଣା ନାହିଁ | ଏକ ପରାମର୍ଶ କାର୍ଯ୍ୟକ୍ରମ ଆରମ୍ଭ କରିବାକୁ ମାଧବ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "মাধৱে জানিব পাৰিলে যে পঢ়াৰ পিছত বহুতো শিশু আৰু অভিভাৱকৰ বিভিন্ন জীৱিকাৰ বিষয়ে কোনো ধাৰণা নাই। মাধৱে এটা পৰামৰ্শ কাৰ্যসূচী আৰম্ভ কৰাৰ সিদ্ধান্ত লয়।",
              "gu": "માધવને ખબર પડે છે કે ઘણા બાળકો અને માતા-પિતાને અભ્યાસ પછી અલગ-અલગ કારકિર્દી વિશે કોઈ જાણ નથી. માધવ સલાહ આપવાના કાર્યક્રમો શરૂ કરવાનું નક્કી કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3(ii)_2_2",
              "hi": "E3 (ii) _2_2",
              "ka": "E3(ii)_2_2",
              "te": "E3(ii)_2_2",
              "or": "E3(ii)_2_2",
              "as": "E3(ii)_2_2",
              "gu": "E3(ii)_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The counselling programme intends to provide study material to the students to give an insight into every profession.",
              "hi": "परामर्श कार्यक्रम छात्रों को हर प्रोफेशन में अंतर्दृष्टि/ सूक्ष्म दृष्टि देने के लिए अध्ययन सामग्री उपलब्ध कराता है।",
              "ka": "ಕೌನ್ಸೆಲಿಂಗ್ ಕಾರ್ಯಕ್ರಮವು ಪ್ರತಿ ವೃತ್ತಿಯ ಬಗ್ಗೆ ಒಳನೋಟವನ್ನು ನೀಡಲು ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಅಧ್ಯಯನ ಸಾಮಗ್ರಿಗಳನ್ನು ಒದಗಿಸುವ ಉದ್ದೇಶವನ್ನು ಹೊಂದಿದೆ.",
              "te": "కౌన్సెలింగ్ కార్యక్రమం విద్యార్థులకు ప్రతి వృత్తిపై అంతర్దృష్టిని అందించడానికి స్టడీ మెటీరియల్‌ను అందించడానికి ఏర్పాటు చేయబడింది.",
              "or": "ପ୍ରତ୍ୟେକ ବୃତ୍ତି ବିଷୟରେ ଏକ ଜ୍ଞାନ ଦେବା ପାଇଁ ଛାତ୍ରମାନଙ୍କୁ ଅଧ୍ୟୟନ\n  ସାମଗ୍ରୀ ପ୍ରଦାନ କରିବାକୁ ପରାମର୍ଶ କାର୍ଯ୍ୟକ୍ରମ |",
              "as": "পৰামৰ্শ কাৰ্যসূচীয়ে শিক্ষাৰ্থীসকলক প্ৰতিটো বৃত্তিৰ বিষয়ে অন্তৰ্দৃষ্টি প্ৰদান কৰিবলৈ অধ্যয়ন সামগ্ৰী প্ৰদান কৰিব বিচাৰে।",
              "gu": "સલાહ આપવાના કાર્યક્રમો વિદ્યાર્થીઓને દરેક વ્યવસાયની સમજ આપવા માટે અભ્યાસ સામગ્રી પ્રદાન કરવાનો ઇરાદો ધરાવે છે."
            }
          }
        ]
      },
      "scene39": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_1_1",
              "hi": "E4_1_1",
              "ka": "E4_1_1",
              "te": "E4_1_1",
              "or": "E4_1_1",
              "as": "E4_1_1",
              "gu": "E4_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After a year of work, Madhav can see some change slowly happening in the schools.",
              "hi": "एक वर्ष के काम के बाद, माधव स्कूलों में धीरे-धीरे कुछ बदलाव देखता है।",
              "ka": "ಒಂದು ವರ್ಷದ ಕೆಲಸದ ನಂತರ, ಮಾಧವ್ ಶಾಲೆಗಳಲ್ಲಿ ನಿಧಾನವಾಗಿ ಕೆಲವು ಬದಲಾವಣೆಗಳನ್ನು ನೋಡಲು ಆರಂಭಿಸುತ್ತಾನೆ.",
              "te": "ఒక సంవత్సరం పని చేసిన తరువాత, మాధవ్ పాఠశాలల్లో నెమ్మదిగా కొంత మార్పును చూడగలిగాడు.",
              "or": "ଏକ ବର୍ଷ କାମ କରିବା ପରେ, ମାଧବ ବିଦ୍ୟାଳୟରେ ଧୀରେ ଧୀରେ \n କିଛି ପରିବର୍ତ୍ତନ ଘଟୁଥିବା ଦେଖିପାରେ |",
              "as": "এবছৰ কাম কৰাৰ পিছত, মাধৱে বিদ্যালয়বোৰত লাহে লাহে কিছু পৰিৱৰ্তন হোৱা দেখিবলৈ পায়।",
              "gu": "એક વર્ષ કામ કર્યા પછી, માધવ શાળાઓમાં ધીમે ધીમે થોડો ફેરફાર થતો જોઈ શકે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_1_2",
              "hi": "E4_1_2",
              "ka": "E4_1_2",
              "te": "E4_1_2",
              "or": "E4_1_2",
              "as": "E4_1_2",
              "gu": "E4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The number of dropout students decreases, and the number of students passing 10th increases compared to the previous year.",
              "hi": "स्कूल छोड़ने वाले छात्रों की संख्या कम हो जाती है, और पिछले वर्ष की तुलना में  इस वर्ष 10वीं कक्षा उत्तीर्ण करने वाले छात्रों की संख्या बढ़ जाती है।",
              "ka": "ಡ್ರಾಪ್ಔಟ್ ವಿದ್ಯಾರ್ಥಿಗಳ ಸಂಖ್ಯೆಯು ಕಡಿಮೆಯಾಗುತ್ತದೆ ಮತ್ತು ಹಿಂದಿನ ವರ್ಷಕ್ಕೆ ಹೋಲಿಸಿದರೆ 10 ನೇ ತರಗತಿಯಲ್ಲಿ ಉತ್ತೀರ್ಣರಾಗುವ ವಿದ್ಯಾರ್ಥಿಗಳ ಸಂಖ್ಯೆ ಹೆಚ್ಚಾಗುತ್ತದೆ.",
              "te": "డ్రాపౌట్ విద్యార్థుల సంఖ్య తగ్గుతుంది మరియు గత సంవత్సరంతో పోలిస్తే 10వ తరగతి ఉత్తీర్ణత సాధించిన విద్యార్థుల సంఖ్య పెరుగుతుంది.",
              "or": "ଡ୍ରପଆଉଟ୍ ଛାତ୍ରଛାତ୍ରୀଙ୍କ ସଂଖ୍ୟା ହ୍ରାସ ହୁଏ ଏବଂ ପୂର୍ବ ବର୍ଷ ତୁଳନାରେ \n ଦଶମ ପାସ୍ କରୁଥିବା ଛାତ୍ର ସଂଖ୍ୟା ବୃଦ୍ଧି ପାଇଥାଏ |",
              "as": "ড্ৰপ-আউট শিক্ষাৰ্থীৰ সংখ্যা হ্ৰাস হয়, আৰু আগৰ বছৰৰ তুলনাত ১০ম উত্তীৰ্ণ হোৱা শিক্ষাৰ্থীৰ সংখ্যা বৃদ্ধি হয়।",
              "gu": "અધૂરું ભણવાનું છોડી જનાર વિદ્યાર્થીઓની સંખ્યામાં ઘટાડો થાય છે, અને 10મું પાસ કરનારા વિદ્યાર્થીઓની સંખ્યામાં પાછલા વર્ષની સરખામણીમાં વધારો થાય છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_1_3",
              "hi": "E4_1_3",
              "ka": "E4_1_3",
              "te": "E4_1_3",
              "or": "E4_1_3",
              "as": "E4_1_3",
              "gu": "E4_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The villagers are also noticing the change. They know that there’s a lot of work still to be done for improving the education system in Banka. But they are pleased that the work has started.",
              "hi": "ग्रामीण भी इस परिवर्तन को देखते हैं। वे जानते हैं कि बांका  में शिक्षा प्रणाली में सुधार के लिए अभी भी बहुत काम किया जाना है। लेकिन वे प्रसन्न हैं कि काम शुरू हो गया है।",
              "ka": "ಈ ಬದಲಾವಣೆಯನ್ನು ಗ್ರಾಮಸ್ಥರೂ ಗಮನಿಸುತ್ತಿದ್ದಾರೆ. ಬಂಕಾದಲ್ಲಿ ಶಿಕ್ಷಣ ವ್ಯವಸ್ಥೆಯನ್ನು ಸುಧಾರಿಸಲು ಇನ್ನೂ ಸಾಕಷ್ಟು ಕೆಲಸಗಳಿವೆ ಎಂದು ಅವರಿಗೆ ತಿಳಿದಿದೆ. ಆದರೆ ಅದು ಆರಂಭಗೊಂಡಿರುವುದು ಸಂತಸ ತಂದಿದೆ.",
              "te": "గ్రామస్తులు కూడా ఈ మార్పును గమనిస్తున్నారు. బంకాలో విద్యావ్యవస్థను మెరుగుపరచడానికి ఇంకా చాలా కృషి చేయాల్సి ఉందని వారికి తెలుసు. అయితే పనులు ప్రారంభం కావడంతో సంతోషం వ్యక్తం చేస్తున్నారు.",
              "or": "ଗ୍ରାମବାସୀ ମଧ୍ୟ ଏହି ପରିବର୍ତ୍ତନକୁ ଲକ୍ଷ୍ୟ କରୁଛନ୍ତି। ସେମାନେ ଜାଣନ୍ତି\n  ଯେ ବାଙ୍କାରେ ଶିକ୍ଷା ବ୍ୟବସ୍ଥାର ଉନ୍ନତି ପାଇଁ ଅନେକ କାର୍ଯ୍ୟ କରିବାକୁ ବାକି ଅଛି। କିନ୍ତୁ କାମ ଆରମ୍ଭ ହୋଇଥିବାରୁ ସେମାନେ ଖୁସି ଅଛନ୍ତି।",
              "as": "গাওঁবাসীয়েও পৰিৱৰ্তনবোৰ লক্ষ্য কৰিছে। তেওঁলোকে জানে যে বাংকাত শিক্ষা ব্যৱস্থা উন্নত কৰাৰ বাবে এতিয়াও বহুতো কাম কৰিবলগীয়া আছে। কিন্তু তেওঁলোক সুখী যে কামটো আৰম্ভ হৈছে।",
              "gu": "ગ્રામજનો પણ આ બદલાવની નોંધ લઈ રહ્યા છે. તેઓ જાણે છે કે બાંકામાં શિક્ષણ પ્રણાલીને સુધારવા માટે હજુ ઘણું કામ કરવાનું બાકી છે. પરંતુ તેઓ ખુશ છે કે કામ શરૂ થયું છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_2_1",
              "hi": "E4_2_1",
              "ka": "E4_2_1",
              "te": "E4_2_1",
              "or": "E4_2_1",
              "as": "E4_2_1",
              "gu": "E4_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Madhav has been invited to the annual day at the government school in the Banka district.",
              "hi": "माधव को बांका जिले के सरकारी स्कूल के वार्षिक दिवस कार्यक्रम में आमंत्रित किया गया है।",
              "ka": "ಬಂಕಾ ಜಿಲ್ಲೆಯ ಸರ್ಕಾರಿ ಶಾಲೆಯಲ್ಲಿ ವಾರ್ಷಿಕ ದಿನಾಚರಣೆಗೆ ಮಾಧವ್ ಅವರನ್ನು ಆಹ್ವಾನಿಸಲಾಗಿದೆ.",
              "te": "బంకా జిల్లాలోని ప్రభుత్వ పాఠశాలలో వార్షిక దినోత్సవానికి మాధవ్‌ను ఆహ్వానించారు.",
              "or": "ବାଙ୍କା ଜିଲ୍ଲାର ସରକାରୀ ବିଦ୍ୟାଳୟରେ ମାଧବଙ୍କୁ ବାର୍ଷିକ ଦିବସକୁ\n  ନିମନ୍ତ୍ରଣ କରାଯାଇଛି |",
              "as": "মাধৱক বাংকা জিলাৰ চৰকাৰী বিদ্যালয়ৰ বাৰ্ষিক দিনটোলৈ আমন্ত্ৰণ জনোৱা হৈছে।",
              "gu": "માધવને બાંકા જિલ્લાની સરકારી શાળામાં વાર્ષિક દિવસ માટે આમંત્રણ આપવામાં આવ્યું છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_2_1b",
              "hi": "E4_2_1B",
              "ka": "E4_2_1b",
              "te": "E4_2_1b",
              "or": "E4_2_1b",
              "as": "E4_2_1b",
              "gu": "E4_2_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Headteachers from other schools nearby have also come to the event to felicitate Madhav for all his help and support in improving the education system in the district.",
              "hi": "आस-पास के अन्य स्कूलों के प्रधानाध्यापक भी जिले की शिक्षा प्रणाली सुधारने में  माधव के द्वारा की गई सहायता और सहयोग के लिए उन्हें सम्मानित करने इस कार्यक्रम में आए हैं।",
              "ka": "ಜಿಲ್ಲೆಯ ಶಿಕ್ಷಣ ವ್ಯವಸ್ಥೆಯನ್ನು ಸುಧಾರಿಸಲು ಎಲ್ಲಾ ಸಹಾಯ ಮತ್ತು ಬೆಂಬಲಕ್ಕಾಗಿ ಮಾಧವ್ ಅವರನ್ನು ಅಭಿನಂದಿಸಲು ಹತ್ತಿರದ ಇತರ ಶಾಲೆಗಳ ಮುಖ್ಯ ಶಿಕ್ಷಕರು ಸಹ ಕಾರ್ಯಕ್ರಮಕ್ಕೆ ಬಂದಿದ್ದಾರೆ.",
              "te": "జిల్లాలోని విద్యావ్యవస్థను మెరుగుపరచడంలో మాధవ్ చేసిన అన్ని సహాయాల కారణంగా అతన్ని సత్కరించడానికి సమీపంలోని ఇతర పాఠశాలల నుండి ప్రధాన ఉపాధ్యాయులు కూడా ఈ కార్యక్రమానికి వచ్చారు.",
              "or": "ଜିଲ୍ଲାର ଶିକ୍ଷା ବ୍ୟବସ୍ଥାରେ ଉନ୍ନତି ଆଣିବା ପାଇଁ ମାଧବଙ୍କୁ ତାଙ୍କର ସମସ୍ତ \n ସାହାଯ୍ୟ ଏବଂ ସହଯୋଗ ପାଇଁ ନିକଟସ୍ଥ ଅନ୍ୟ ବିଦ୍ୟାଳୟର ପ୍ରଧାନଶିକ୍ଷକମାନେ ମଧ୍ୟ ଏହି କାର୍ଯ୍ୟକ୍ରମକୁ ଆସିଛନ୍ତି।",
              "as": "জিলাখনৰ শিক্ষা ব্যৱস্থা উন্নত কৰাৰ ক্ষেত্ৰত মাধৱৰ সকলো সহায় আৰু সমৰ্থনৰ বাবে ওচৰৰ আন বিদ্যালয়ৰ প্ৰধান শিক্ষকসকলেও মাধৱক সম্বৰ্ধনা জনাবলৈ অনুষ্ঠানটোলৈ আহিছে।",
              "gu": "નજીકની અન્ય શાળાઓના મુખ્ય શિક્ષકો પણ માધવને જિલ્લામાં શિક્ષણ પ્રણાલીને સુધારવામાં તેમની તમામ મદદ અને સમર્થન બદલ સન્માન કરવા માટે આ કાર્યક્રમમાં આવ્યા હતા."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_2_2////",
              "hi": "E4_2_2 ////",
              "ka": "E4_2_2////",
              "te": "E4_2_2////",
              "or": "E4_2_2////",
              "as": "E4_2_2////",
              "gu": "E4_2_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Madhav gives a speech to address the gathering of students and parents who have come there to listen to him.",
              "hi": "माधव छात्रों और माता-पिता की सभा को संबोधित करने के लिए एक भाषण देता है जो उसको सुनने के लिए वहां आए हैं।",
              "ka": "ಮಾಧವ್ ತನ್ನ ಮಾತು ಕೇಳಲು ಅಲ್ಲಿಗೆ ಬಂದ ವಿದ್ಯಾರ್ಥಿಗಳು ಮತ್ತು ಪೋಷಕರ ಸಭೆಯನ್ನು ಉದ್ದೇಶಿಸಿ ಭಾಷಣ ಮಾಡುತ್ತಾನೆ.",
              "te": "మాధవ్ తన మాటలు వినడానికి అక్కడికి వచ్చిన విద్యార్థులను మరియు తల్లిదండ్రులను ఉద్దేశించి ప్రసంగించాడు.",
              "or": "ତାଙ୍କ କଥା ଶୁଣିବାକୁ ସେଠାକୁ ଆସିଥିବା ଛାତ୍ର ଓ ଅଭିଭାବକମାନଙ୍କ\n  ସମାବେଶକୁ ସମ୍ବୋଧିତ କରିବା ପାଇଁ ମାଧବ ଏକ ଭାଷଣ ଦିଅନ୍ତି |",
              "as": "মাধৱে তেওঁৰ কথা শুনিবলৈ তালৈ অহা শিক্ষাৰ্থী আৰু অভিভাৱকসকলৰ সমাৱেশক সম্বোধন কৰিবলৈ এটা ভাষণ দিয়ে।",
              "gu": "માધવ તેમને સાંભળવા ત્યાં આવેલા વિદ્યાર્થીઓ અને વાલીઓના મેળાવડાને સંબોધવા માટે ભાષણ આપે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I believe that all of us have the power to bring change around us. The headteachers and teachers of this district proved it by the hard work they’ve done. But this is only the start. If we want to progress, all the people in this district will need to come together and work towards change.",
              "hi": "मेरा मानना ​​है कि हम सभी के पास हमारे चारों ओर परिवर्तन लाने की शक्ति है। इस जिले के प्रधानाध्यापक और शिक्षकों ने कड़ी मेहनत से इसे साबित कर दिया है। लेकिन यह केवल शुरुआत है। अगर हम प्रगति करना चाहते हैं, तो इस जिले के सभी लोगों को एक साथ आने और परिवर्तन की दिशा में काम करने की आवश्यकता होगी।",
              "ka": "ನಮ್ಮ ಸುತ್ತಲೂ ಬದಲಾವಣೆಯನ್ನು ತರಲು ನಮ್ಮೆಲ್ಲರಿಗೂ ಶಕ್ತಿ ಇದೆ ಎಂದು ನಾನು ನಂಬುತ್ತೇನೆ. ಈ ಜಿಲ್ಲೆಯ ಮುಖ್ಯ ಶಿಕ್ಷಕರು ಮತ್ತು ಶಿಕ್ಷಕರು ತಾವು ಮಾಡಿದ ಕಠಿಣ ಪರಿಶ್ರಮದಿಂದ ಅದನ್ನು ಸಾಬೀತುಪಡಿಸಿದ್ದಾರೆ. ಆದರೆ ಇದು ಪ್ರಾರಂಭ ಮಾತ್ರ. ನಾವು ಪ್ರಗತಿ ಹೊಂದಬೇಕಾದರೆ ಈ ಜಿಲ್ಲೆಯ ಎಲ್ಲಾ ಜನರು ಒಗ್ಗೂಡಿ ಬದಲಾವಣೆಗೆ ಶ್ರಮಿಸಬೇಕು.",
              "te": "మన చుట్టూ మార్పు తీసుకురాగల శక్తి మనందరికీ ఉందని నేను నమ్ముతున్నాను. ఈ జిల్లాలోని ప్రధానోపాధ్యాయులు మరియు ఉపాధ్యాయులు తాము చేసిన కృషితో దానిని నిరూపించారు. అయితే ఇది ప్రారంభం మాత్రమే. మనం పురోగమించాలంటే ఈ జిల్లా ప్రజలంతా ఏకతాటిపైకి వచ్చి మార్పు కోసం కృషి చేయాలి.",
              "or": "ମୁଁ ବିଶ୍ୱାସ କରେ ଯେ ଆମ ସମସ୍ତଙ୍କର ଚାରିପାଖରେ ପରିବର୍ତ୍ତନ ଆଣିବାର\n  ଶକ୍ତି ଅଛି | ଏହି ଜିଲ୍ଲାର ପ୍ରଧାନ ଶିକ୍ଷକ ଏବଂ ଶିକ୍ଷକମାନେ ସେମାନେ କରିଥିବା କଠିନ ପରିଶ୍ରମ ଦ୍ୱାରା ଏହାକୁ ପ୍ରମାଣ କରିଥିଲେ | କିନ୍ତୁ ଏହା କେବଳ ଆରମ୍ଭ | ଯଦି ଆମେ ଅଗ୍ରଗତି କରିବାକୁ ଚାହୁଁ, ତେବେ ଏହି ଜିଲ୍ଲାର ସମସ୍ତ ଲୋକଙ୍କୁ ଏକାଠି ହୋଇ ପରିବର୍ତ୍ତନ ଦିଗରେ କାର୍ଯ୍ୟ କରିବାକୁ ପଡିବ |",
              "as": "মই বিশ্বাস কৰোঁ যে আমাৰ চাৰিওফালে পৰিৱৰ্তন অনাৰ ক্ষমতা আমাৰ সকলোৰে আছে। এই জিলাৰ প্ৰধান শিক্ষক আৰু শিক্ষকসকলে তেওঁলোকে কৰা কঠোৰ পৰিশ্ৰমৰ দ্বাৰা এইটো প্ৰমাণ কৰিছিল। কিন্তু এইটো কেৱল আৰম্ভণি। যদি আমি উন্নতি কৰিব বিচাৰোঁ, এই জিলাৰ সকলো লোকে একত্ৰিত হৈ পৰিৱৰ্তনৰ দিশত কাম কৰিব লাগিব।",
              "gu": "હું માનું છું કે આપણા બધામાં આપણી આસપાસ પરિવર્તન લાવવાની સાહસ છે. આ જિલ્લાના મુખ્ય શિક્ષકો અને શિક્ષકોએ તેઓએ કરેલી મહેનત દ્વારા તે સાબિત કર્યું છે. પરંતુ આ માત્ર શરૂઆત છે. જો આપણે પ્રગતિ કરવી હોય તો આ જિલ્લાના તમામ લોકોએ સાથે આવીને પરિવર્તન તરફ કામ કરવું પડશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_2_3",
              "hi": "E4_2_3",
              "ka": "E4_2_3",
              "te": "E4_2_3",
              "or": "E4_2_3",
              "as": "E4_2_3",
              "gu": "E4_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The people of Banka are very inspired. Never have they seen a sincere and honest government officer like Madhav. They feel that their hopes of a better future are coming true.",
              "hi": "बांका के लोग बहुत प्रेरित हैं। उन्होंने कभी माधव जैसे सच्चे और ईमानदार सरकारी अधिकारी को नहीं देखा। उन्हें लगता है कि एक बेहतर भविष्य की उनकी आशाएं  सच हो रही हैं।",
              "ka": "ಬಂಕಾದ ಜನರು ತುಂಬಾ ಸ್ಫೂರ್ತಿ ಪಡೆದಿದ್ದಾರೆ. ಮಾಧವ್ ಅವರಂತಹ ನಿಷ್ಠಾವಂತ ಮತ್ತು ಪ್ರಾಮಾಣಿಕ ಸರ್ಕಾರಿ ಅಧಿಕಾರಿಯನ್ನು ಅವರು ಎಂದಿಗೂ ನೋಡಿಲ್ಲ. ಉತ್ತಮ ಭವಿಷ್ಯದ ತಮ್ಮ ಭರವಸೆಗಳು ನಿಜವಾಗುವುದರಲ್ಲಿದೆ ಎಂದು ಅವರು ಭಾವಿಸುತ್ತಾರೆ.",
              "te": "బంకా ప్రజలు ఎంతో స్ఫూర్తిని పొందారు. మాధవ్ లాంటి నిజాయితీపరుడు, నిజాయితీ గల ప్రభుత్వ అధికారిని చూడలేదు. మంచి భవిష్యత్తుపై తమ ఆశలు ఫలిస్తున్నాయని వారు భావిస్తున్నారు.",
              "or": "ବାଙ୍କାର ଲୋକମାନେ ବହୁତ ଅନୁପ୍ରାଣିତ | ସେମାନେ କେବେ ମଧ୍ୟ ମାଧବଙ୍କ\n  ପରି ଜଣେ ସଚ୍ଚୋଟ ଏବଂ ସଚ୍ଚୋଟ ସରକାରୀ ଅଧିକାରୀଙ୍କୁ ଦେଖି ନାହାଁନ୍ତି | ସେମାନେ ଅନୁଭବ କରନ୍ତି ଯେ ଏକ ଭଲ ଭବିଷ୍ୟତର ଆଶା ପୂରଣ ହେଉଛି |",
              "as": "বাংকাৰ লোকসকল বহুত অনুপ্ৰাণিত হয়। তেওঁলোকে কেতিয়াও মাধৱৰ দৰে এজন আন্তৰিক আৰু সৎ চৰকাৰী বিষয়াক দেখা নাই। তেওঁলোকে অনুভৱ কৰে যে তেওঁলোকৰ উন্নত ভৱিষ্যতৰ আশা সঁচা হৈ আহিছে।",
              "gu": "બાંકાના લોકો ખૂબ જ પ્રેરિત છે. તેઓએ ક્યારેય માધવ જેવો નિષ્ઠાવાન અને પ્રામાણિક સરકારી અધિકારી જોયો નથી. તેઓને લાગે છે કે સારા ભવિષ્યની તેમની આશાઓ સાચી થઈ રહી છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "IAS Officer",
              "hi": "आईएएस अधिकारी",
              "ka": "IAS ಆಫೀಸರ್",
              "te": "IAS అధికారి",
              "or": "IAS ଅଧିକାରୀ |",
              "as": "IAS বিষয়া",
              "gu": "IAS અધિકારી"
            }
          }
        ]
      }
    }
  