import { Fragment, useContext, useEffect, useState, useMemo } from "react";
import {useLocation, useRouteMatch, useHistory } from 'react-router-dom'
import NetworkGraph from "../../components/NetworkGraph";
import PageContainer from "../../components/PageContainer";
import { TCIContext } from "../../contexts/TCIContext";
import {NavContext} from '../../contexts/NavContext';
import {SoundContext} from '../../contexts/SoundContext';
import LocalStorage from "../../utils/LocalStorage";
import { StyledProgressMapCharImgWrapper, StyledProgressMapCharNameWrapper, StyledProgressMapContainer, StyledProgressMapGraphics } from "./style";
import * as CharImages from '../../components/CharacterSelect/CharacterImages';
import { __LOC } from "../../utils/locale";
import { getQueryParam } from "../../utils/general";
import { CoachMark } from "../../components/CoachMark";
import { Button } from "../../components/Button";
import swipe from '../../assets/swipe.svg';
import unlockedDP from '../../assets/unlockedDP.svg';
import lockedDP from '../../assets/lockedDP.svg';
import { coachMark_progressMap_horScroll, coachMark_progressMap_nodeStates } from "../../data/coachMarkConfigs";
import { colors } from "../../Theme/general";
import { transform } from "lodash";
const {white} = colors;

const data = {
  nodes: [
    {
      id: 1,
      name: "A",
    },
    {
      id: 2,
      name: "B",
    }
  ],
  links: [
    {
      source: 1,
      target: 2,
    }
  ],
};

const ProgressMap = ({
  
    saveChanges,
    storyProgressData_editMode,

    isMapPreview

}) => {

  const {
    data : tciContextData,
    setData,
  } = useContext(TCIContext);

  const location = useLocation()
  const history = useHistory()
  const {setNav} = useContext(NavContext)
  const {params} = useRouteMatch() 
  const thisStorySavedData = storyProgressData_editMode /**if in edit mode */ || LocalStorage.getJSONItem(`savedData_${params?.storyId}`); /**storyId will be used here */

  const { useControlUISounds } = useContext(SoundContext);
  useControlUISounds('Map')


  useEffect(() => {


    
    let startRoute = decodeURIComponent(getQueryParam(location.search, 'startRoute'))

    

    if (startRoute) {
      let config = {
        left: [],
        right: [{ icon: "X", to: startRoute }]
      };

      setNav((prev) => ({
        ...prev,
        ...config,
      }));
    }

    

    return () => !isMapPreview && setNav((prev) => ({ ...prev, left: [], right: [] }));
  }, []);


  useEffect(() => {
    if(location.state?.animateAndReturnTo){
      console.log('animating and returning...')
      setTimeout(() => history.push(location.state.animateAndReturnTo),3000)
    }
  },[])
  
  // console.log({storyProgressData_editMode : JSON.parse(JSON.stringify(storyProgressData_editMode))})
  
  const convertStoryProgressData_to_NetworkGraphData = () => {
    
    
    let dataAry = Object.values(thisStorySavedData.data).map((d,i) => ({ ...d, index: i}));
    // console.log({dataAry});

    const isImportantChoicePage = (pageData) => {
      return (
        ['Choice', 'Choice2'].indexOf(pageData.comp) !== -1  // is 'Choice type' of component
        && !pageData.linkedTo.every(dd => dd.id === pageData.linkedTo[0].id) // and the Choice links lead to different pages and not the same.
        && pageData.omitFromMap !== true
        // && pageData.showOnMap !== false // and the Choice hasn't been explicitly defined as 'Dont Show' by the TCI authors
      )
    }
    
    /**
     * add these lines if you want only the choice and the 
     * immediate page after them to create the graph
     */
    let importantChoicePagesAry = [];
    JSON.parse(JSON.stringify(dataAry)).forEach((d,i) => {
      // choice pages and the pages immediately after choice pages
      if( 
        isImportantChoicePage(d)
      ){
        importantChoicePagesAry.push({...d, isImportantChoice : true})
      }
    })


    

    // console.log({importantChoicePagesAry})

    let pgsAfterImpChoiceAry = [];
    importantChoicePagesAry.forEach((d,i) => {
      d.linkedTo.forEach(dd => {
        if(
          !pgsAfterImpChoiceAry.some(pg => pg.id === dd.id ) &&
          d.endThread !== true // dont find the 'next page' after THREAD END pages ( if thread end page is a importantChoicePage, which is unlikely )
        ){
          pgsAfterImpChoiceAry.push(
            thisStorySavedData.data[dd.id]
          )
        }
      })
    })

    /**
     * we catch all thread end pages,
     * and if they arent already in pages after imp choice array, then we 
     * add them to the final node list, cuz we need to show these final
     * stops on the map.
     * 
     * also because,
     * for each pgAfterImpChoice, we recusrsively find the next
     * choice page, and set the 'linkedTo' of this 'pgAfter..' to this
     * next choice page. 
     * 
     * if a next choice page doesnt show up, and instead a 
     * thread end page shows up, we set the 'linkedTo' of 'pgAfter...'
     * to this threadEnd page. so we need to have these threadEnd pages available as nodes
     *  */ 
     let threadEndPages = [];
     JSON.parse(JSON.stringify(dataAry)).forEach((d,i) => {
       if(d.endThread === true && !pgsAfterImpChoiceAry.some(dd => dd.id === d.id)){
        threadEndPages.push(d);
       }
     })

    
    // console.log('PROGPROG',JSON.parse(JSON.stringify(pgsAfterImpChoiceAry)));
    
    pgsAfterImpChoiceAry.forEach((d,i) => {

      const getNextChoice = (nextPage) => {
        
        // only one 'to' page can exist for 'nextPage'
        let toPage = thisStorySavedData.data[nextPage.linkedTo[0].id];
        // console.log({toPage : toPage.id})
        
        //if the next linked page after pgAftrImpChoice is nont included inn the imp choice ary, 
        // AND this toPage ISNT a thread end
        // then conntinue recursionn till you finnd an imp choice 
        if(
          importantChoicePagesAry.every(pg => pg.id !== toPage.id)
          && toPage.endThread !== true
        ){
          return getNextChoice(toPage);
        }else{ // sp basically, if the toPage is a thread end, then simply return it.
          return toPage
        }
      }

      let nextChoice = getNextChoice(d);
      // if(d.display === "A3(i)_1_3@ whatsapp pending"){
      //   console.log({nextChoice})
      // } 
      
      // this is not the 'actual' linked to,
      // but rather a link to the nnext important choice, which is useful only for the progress map.
      //outside of it, this is faulty data
      d.linkedTo = [{
        id: nextChoice.id,
        visited: nextChoice.visited
      }]
      
    })
    
    dataAry = [...importantChoicePagesAry, ...pgsAfterImpChoiceAry, ...threadEndPages]
    
    /**
     * END ADD THESE LINES
     */

    

    let links = []
    dataAry.forEach((d,i) => {
      //skip the last page cuz this is the end. 
      //linkedTo should not exist on this page, if it does. it wonnt be used anyway
      // if(d.display === "A3(i)_1_3@ whatsapp pending") console.log('OUTSIDE!', {
      //   lw: d.display.toLowerCase(),
      //   inc: d.display.toLowerCase().indexOf('thread') === -1 && d.display.toLowerCase().indexOf('end') === -1
      // })
      // if it doesnt contain either thread or end then it means it doesnt contain the full word thread end
      if(d.endThread !== true){ // dont create links data for THREAD END pages. Because this is the end. it doesn't need to link out to anything
      
        d.linkedTo.forEach(dd => {
          links.push({
            source: d.id,
            target: dd.id
          })
        })
        
      }
      
    })

    

    return ({
      nodes : dataAry,
      links
    })
  }

  let networkGraphData = convertStoryProgressData_to_NetworkGraphData()

  const unlockedNodesAreClickable = useMemo(() => {
    return networkGraphData.nodes
      .filter((node) => node.endThread === true)
      .some((node) => node.visited === true)
      ? true
      : false;
  }, []);
  

  const [hideGraphics, setHideGraphics ] = useState(false)

  const genCharImage = () => {
    let {storyName} = thisStorySavedData;
    let img = CharImages[storyName.toLowerCase()];
    return <img src={img} alt={storyName}/>
  }

  const [coachMarkOneDone, setCoachMarkOneDone] = useState(false)

  const CoachMarkCustomComp = ({onDone}) => {
    const style = {
      
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      height: '100%'
    }
    return (
      <PageContainer style={{position: 'absolute',
      zIndex: 10, left: '50%', transform: 'translateX(-50%)'}}>
        <div style={style}>
        <h3 className='h3' style={{color: white, paddingBottom: '2rem'}}>{__LOC(coachMark_progressMap_horScroll.title)}</h3>
        <img src={swipe}/>
        <h3 className='p' style={{color: white, padding: '2rem 0'}}>{__LOC(coachMark_progressMap_horScroll.subtitle)}</h3>
        <Button height={30} onClick={() => {
          onDone();
          setCoachMarkOneDone(true)
        }}>{__LOC('Done')}</Button>
        </div>
      </PageContainer>
    )
  }

  const NodeStatesCoachMark = ({onDone}) => {
    const style = {
      
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      height: '100%'
    }
    return (
      <PageContainer style={{position: 'absolute',
      zIndex: 10, left: '50%', transform: 'translateX(-50%)'}}>
        <div style={style}>
        <h3 className='h3' style={{color: white, paddingBottom: '2rem'}}>{__LOC(coachMark_progressMap_nodeStates.title)}</h3>
        <div style={{display: 'flex', gap: '2rem'}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img src={lockedDP}/>
            <h3 className='p' style={{color: white, padding: '2rem 0'}}>{__LOC(coachMark_progressMap_nodeStates.lockedDP)}</h3>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img src={unlockedDP}/>
          <h3 className='p' style={{color: white, padding: '2rem 0'}}>{__LOC(coachMark_progressMap_nodeStates.unlockedDP)}</h3>
          </div>
        </div>
        
        
        <Button height={30} onClick={onDone}>{__LOC('Done')}</Button>
        </div>
      </PageContainer>
    )
  }

  return (
    <Fragment>
     { location.pathname.includes('/progressMap/') &&
      <CoachMark
          // bubbleConfig={bubbleConfig_charSelect_leftRightNav}
          // onShowCoachMarkChange={toggleZIndexes_charSelect_leftRightNav}
          coachmarkItemName={"progressMap_horScroll"}
          CustomComp={CoachMarkCustomComp}
        /> }

{ location.pathname.includes('/progressMap/') && coachMarkOneDone &&
      <CoachMark
          // bubbleConfig={bubbleConfig_charSelect_leftRightNav}
          // onShowCoachMarkChange={toggleZIndexes_charSelect_leftRightNav}
          coachmarkItemName={"progressMap_nodeStates"}
          CustomComp={NodeStatesCoachMark}
        /> }
    
    <StyledProgressMapContainer>
      <StyledProgressMapGraphics hideGraphics={hideGraphics}>
      <StyledProgressMapCharNameWrapper hideGraphics={hideGraphics}>
        <h1 className='h1 bold'>{__LOC(thisStorySavedData.storyName)}</h1>
      </StyledProgressMapCharNameWrapper>
      <StyledProgressMapCharImgWrapper hideGraphics={hideGraphics}>{genCharImage()}</StyledProgressMapCharImgWrapper>
      </StyledProgressMapGraphics>
        <NetworkGraph
          data={networkGraphData} 
          unlockedNodesAreClickable={unlockedNodesAreClickable}
          nodePosConfig={(() => {
            let toReturn = {}
            networkGraphData.nodes.forEach(d => {
              if(d.nodePosConfig){
                toReturn[d.id] = d.nodePosConfig;
              }
            })
            return toReturn;
          })()}
          //props for edit mode
          isEditMode = {!!saveChanges}
          saveChanges = {(nodePosConfig) => {
            if(!saveChanges) return undefined;
            let newData = [...tciContextData];
            Object.keys(nodePosConfig).forEach(key => {
              let config = nodePosConfig[key];
              let splitLink = key.split('/').filter(Boolean);
              let sceneId = splitLink[2]
              let pageId = splitLink[3]
              
              let scene = newData.find(d => d.id === sceneId);
              let page = scene.pages.find(d => d.id === pageId);
              page.nodePosConfig = config;
            })
            
            setData(newData)
            saveChanges(newData)
          }}
          handleVizCanvasScroll={(e) => {
            let box = document.querySelector('#vizCanvas').getBoundingClientRect();
            if(box.x < -10 && hideGraphics === false){
              setHideGraphics(true)
            }else if ( box.x > -10 && hideGraphics === true){
              setHideGraphics(false)
            }
          }}
          />
        
    </StyledProgressMapContainer>
    </Fragment>
  )
};

export default ProgressMap;
