export default {
  "home": {
    "en": "Home",
    "hi": "घर",
    "te": "హోమ్",
    "ka": "ಮನೆ",
    "or": "ହୋମ୍‌",
    "as": "ঘৰ",
    "gu": "હોમ"
  },
  "about": {
    "en": "About",
    "hi": "बारे में",
    "te": "గేమ్ గురించి",
    "ka": "ಬಗ್ಗೆ",
    "or": "ଖେଳ ବିଷୟରେ",
    "as": "বিষয়ে",
    "gu": "ગેમ વિશે"
  },
  "help": {
    "en": "Help",
    "hi": "मदद",
    "te": "సహాయం",
    "ka": "ಸಹಾಯ",
    "or": "ସାହାର୍ଯ୍ୟ",
    "as": "সহায়",
    "gu": "મદદ"
  },
  "language": {
    "en": "Language",
    "hi": "भाषा",
    "te": "భాష",
    "ka": "ಭಾಷೆ",
    "or": "ଭାଷା",
    "as": "ভাষা",
    "gu": "ભાષા"
  },
  "mute": {
    "en": "Mute",
    "hi": "मूक",
    "te": "మ్యూట్ చేయండి",
    "ka": "ಮ್ಯೂಟ್",
    "or": "ମ୍ୟୁଟ୍‌",
    "as": "মিউট",
    "gu": "મ્યુટ"
  },
  "unmute": {
    "en": "Unmute",
    "hi": "अनम्यूट",
    "te": "అన్‌మ్యూట్ చేయండి",
    "ka": "ಅನ್‌ಮ್ಯೂಟ್",
    "or": "ଅନ୍‌ମ୍ୟୁଟ୍‌",
    "as": "আনমিউট",
    "gu": "અનમ્યૂટ"
  },
  "razia": {
    "en": "Razia",
    "hi": "रज़िया",
    "te": "రజియా",
    "ka": "ರಜಿಯಾ",
    "or": "ରଜିୟା",
    "as": "ৰাজিয়া",
    "gu": "રઝીયા"
  },
  "razia is an ambitious girl from the sundarbans in west bengal she wants to study science and help her family financially": {
    "en": "Razia is an ambitious girl from the Sundarbans in West Bengal. She wants to study science and help her family financially.",
    "hi": "रज़िया पश्चिम बंगाल में सुंदरबन से एक महत्वाकांक्षी लड़की है। वह विज्ञान का अध्ययन करना चाहती है और अपने परिवार को आर्थिक रूप से मदद करती है।",
    "te": "రజియా పశ్చిమ బెంగాల్‌లోని సుందర్‌బన్స్‌కు చెందిన ఉన్నత స్థితిని కోరుకునే అమ్మాయి. సైన్స్ చదివి తన కుటుంబానికి ఆర్థికంగా సహాయం చేయాలని ఆమె కోరిక.",
    "ka": "ರಜಿಯಾ ಪಶ್ಚಿಮ ಬಂಗಾಳದ ಸುಂದರಬನ್‌ನ ಮಹತ್ವಾಕಾಂಕ್ಷೆಯನ್ನು ಹೊಂದಿರುವ ಹುಡುಗಿ. ಅವಳು ವಿಜ್ಞಾನವನ್ನು  ಅಧ್ಯಯನ ಮಾಡಲು ಮತ್ತು ತನ್ನ ಕುಟುಂಬದವರಿಗೆ ಆರ್ಥಿಕವಾಗಿ ಸಹಾಯ ಮಾಡಲು ಬಯಸುತ್ತಾಳೆ.",
    "or": "ପଶ୍ଚିମବଙ୍ଗର ସୁନ୍ଦରବନର ରଜିୟା ଜଣେ ଉଚ୍ଚାଭିଳାଷୀ ଝିଅ ଅଟେ | ସେ ବିଜ୍ଞାନ ପଢିବାକୁ ଚାହୁଁଛି ଏବଂ ନିଜ ପରିବାରକୁ ଆର୍ଥିକ ସାହାର୍ଯ୍ୟ କରିବାକୁ ଚାହୁଁଛି |",
    "as": "ৰাজিয়া পশ্চিম বংগৰ সুন্দৰবনৰ এগৰাকী উচ্চাকাংক্ষী ছোৱালী। তাই বিজ্ঞান অধ্যয়ন কৰিব বিচাৰে আৰু তাইৰ পৰিয়ালক আৰ্থিকভাৱে সহায় কৰিব বিচাৰে।",
    "gu": "રઝીયા પશ્ચિમ બંગાળના સુંદરવનની એક મહત્વકાંક્ષી છોકરી છે. તે વિજ્ઞાનનો અભ્યાસ કરવા ઈચ્છે છે અને પોતાના પરિવારને આર્થિક મદદ કરવા ઈચ્છે છે"
  },
  "naveen": {
    "en": "Naveen",
    "hi": "नवीन",
    "te": "నవీన్",
    "ka": "ನವೀನ್",
    "or": "ନବୀନ",
    "as": "নবীন",
    "gu": "નવિન"
  },
  "naveen is an outgoing and friendly boy from bangalore he is passionate about dance but wants to support his mother by having a successful career": {
    "en": "Naveen is an outgoing and friendly boy from Bangalore. He is passionate about dance but wants to support his mother by having a successful career.",
    "hi": "नवीन बैंगलोर से एक निवर्तमान और दोस्ताना लड़का है। वह नृत्य के बारे में भावुक है लेकिन एक सफल कैरियर होने के द्वारा अपनी माँ का समर्थन करना चाहता है।",
    "te": "నవీన్ బెంగళూరుకు చెందిన అవుట్‌గోయింగ్ మరియు స్నేహపూర్వక అబ్బాయి. అతనికి డ్యాన్స్ అంటే ఇష్టం కానీ మంచి కెరీర్‌ ద్వారా తన తల్లికి మద్దతు ఇవ్వాలనుకుంటున్నాడు.",
    "ka": "ನವೀನ್ ಬೆಂಗಳೂರಿನಿಂದ ಹೊರಹೋಗುವ ಮತ್ತು ಸ್ನೇಹಪರ ಹುಡುಗ. ಅವನು ನೃತ್ಯದ ಬಗ್ಗೆ ಒಲವು  ಹೊಂದಿದ್ದಾನೆ ಆದರೆ ಯಶಸ್ವಿ ವೃತ್ತಿಜೀವನವನ್ನು ಹೊಂದುವ ಮೂಲಕ ತನ್ನ ತಾಯಿಯನ್ನು  ಬೆಂಬಲಿಸಲು ಬಯಸುತ್ತಾನೆ.",
    "or": "ବାଙ୍ଗାଲୋରର ବାହାରେ କାମ କରୁଥିବା ନବୀନ ଜଣେ ବନ୍ଧୁତ୍ୱପୂର୍ଣ୍ଣ ପୁଅ ଅଟେ | ତାଙ୍କୁ ନୃତ୍ୟ କରିବାକୁ ପସନ୍ଦ କିନ୍ତୁ ଗୋଟିଏ ସଫଳ କ୍ୟାରିୟର୍‌ ଗଢି ନିଜ ମା’ଙ୍କୁ ସମର୍ଥନ କରିବାକୁ ଚାହୁଁଛି |",
    "as": "নবীন বাংগালোৰৰ এজন বহিৰ্গামী আৰু বন্ধুত্বপূৰ্ণ ল’ৰা। তেওঁ নৃত্যৰ প্ৰতি উৎসাহী কিন্তু সফল জীৱিকাৰ জৰিয়তে মাকক সমৰ্থন কৰিব বিচাৰে।",
    "gu": "નવીન બેંગ્લુરુના એક સારો તથા મિલનસાર છોકરો છે. તેને ડાંસનો શોખ છે પણ એક સફળ કરિયર બનાવવા માટે તે પોતાની માતાનો સાથ આપવા ઈચ્છે છે."
  },
  "you have completed __placeholder1__% of __placeholder2__ story do you wish to continue?": {
    "en": "You have completed __PLACEHOLDER1__% of __PLACEHOLDER2__ story. Do you wish to continue?",
    "hi": "आपने __PLACEHOLDER2__ कहानी का __PLACEHOLDER1__% पूरा कर लिया है। क्या आप जारी रखना चाहते हैं?",
    "te": "మీరు __ప్లేస్ హోల్డర్__ కథలో __ప్లేస్ హోల్డర్__% పూర్తి చేసారు. మీరు కొనసాగించాలనుకుంటున్నారా?",
    "ka": "ನೀವು __PLACEHOLDER1__% ರಲ್ಲಿ __PLACEHOLDER2__ ಕಥೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದೀರಿ. ನೀವು ಮುಂದುವರಿಸಲು ಬಯಸುವಿರಾ?",
    "or": "ଆପଣ __PLACEHOLDER2__ କାହାଣୀର __PLACEHOLDER1__% ସମାପ୍ତ କରିଛନ୍ତି | ଆପଣ ଜାରି ରଖିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    "as": "তুমি সম্পূৰ্ণ কৰিছা __PLACEHOLDER1__% __PLACEHOLDER2__ কাহিনী। তুমি অব্যাহত ৰাখিব বিচাৰা নে?",
    "gu": "તમારા __PLACEHOLDER2__ ની કહાનીનો __PLACEHOLDER1__ ટકા પૂરા કરવા માટે. શું તમે ઈશ્યું કરવા માગો છો?"
  },
  "restart": {
    "en": "Restart",
    "hi": "पुनर्प्रारंभ करें",
    "te": "పునఃప్రారంభించండి",
    "ka": "ಪುನರಾರಂಭ ಮಾಡಿ",
    "or": "ପୁନଃ ଆରମ୍ଭ କରନ୍ତୁ",
    "as": "পুনৰ আৰম্ভ কৰা",
    "gu": "ફરીથી પ્રારંભ કરો"
  },
  "continue": {
    "en": "Continue",
    "hi": "जारी रखना",
    "te": "కొనసాగించండి",
    "ka": "ಮುಂದುವರಿಸಿ",
    "or": "ଜାରି ରଖନ୍ତୁ",
    "as": "অব্যাহত ৰাখা",
    "gu": "આગળ જાળવી રાખો"
  },
  "explore": {
    "en": "Explore",
    "hi": "अन्वेषण करना",
    "te": "అన్వేషించండి",
    "ka": "ಅನ್ವೇಷಿಸಿ",
    "or": "ଅନ୍ୱେଷଣ କରନ୍ତୁ",
    "as": "অন্বেষণ কৰা",
    "gu": "શરૂ કરો"
  },
  "experience cards": {
    "en": "Experience Cards",
    "hi": "अनुभव कार्ड",
    "te": "అనుభవ కార్డులు",
    "ka": "ಅನುಭವ ಕಾರ್ಡ್‌ಗಳು",
    "or": "ଅନୁଭବ କାର୍ଡ",
    "as": "অভিজ্ঞতা কাৰ্ডসমূহ",
    "gu": "અનુભવ કાર્ડ"
  },
  "select a language": {
    "en": "Select A Language",
    "hi": "भाषा चुनें",
    "te": "ఒక భాషను ఎంచుకోండి",
    "ka": "ಒಂದು ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    "or": "ଭାଷା ଚୟନ କରନ୍ତୁ",
    "as": "এটা ভাষা বাছনি কৰা",
    "gu": "ભાષા પસંદ કરો"
  },
  "hindi": {
    "en": "Hindi",
    "hi": "हिन्दी",
    "te": "హిందీ",
    "ka": "ಹಿಂದಿ",
    "or": "ଓଡ଼ିଆ",
    "as": "হিন্দী",
    "gu": "હિન્દી"
  },
  "kannada": {
    "en": "Kannada",
    "hi": "कन्नड़",
    "te": "కన్నడ",
    "ka": "ಕನ್ನಡ",
    "or": "କନ୍ନଡ |",
    "as": "কানাড়া",
    "gu": "કન્નડ"
  },
  "about the career quest": {
    "en": "About the Career Quest",
    "hi": "कैरियर क्वेस्ट के बारे में",
    "te": "కెరీర్ క్వెస్ట్ గురించి",
    "ka": "ಕೆರಿಯರ್ ಕ್ವೆಸ್ಟ್ ಬಗ್ಗೆ",
    "or": "କ୍ୟାରିଅର୍ କ୍ୱେଷ୍ଟ୍ ବିଷୟରେ",
    "as": "জীৱিকা সন্ধানৰ বিষয়ে",
    "gu": "રમત વિશે"
  },
  "career quest invites you on a journey taken by eight imaginary characters of your age their life situations may even seem similar to yours help these characters make choices that impact their wellbeing, career and life in the process, pick up knowledge, skills and mindsets required for successful career decision making": {
    "en": "Career Quest invites you on a journey taken by eight imaginary characters of your age. Their life situations may even seem similar to yours. Help these characters make choices that impact their wellbeing, career and life. In the process, pick up knowledge, skills and mindsets required for successful career decision making.",
    "hi": "करियर क्वेस्ट आपको अपनी उम्र के आठ काल्पनिक पात्रों द्वारा की गई यात्रा पर आमंत्रित करता है। उनकी जीवन की स्थिति आपके समान भी प्रतीत हो सकती है। इन पात्रों को ऐसे विकल्प चुनने में मदद करें जो उनकी भलाई, करियर और जीवन को प्रभावित करें। इस प्रक्रिया में, सफल करियर निर्णय लेने के लिए आवश्यक ज्ञान, कौशल और मानसिकता को चुनें।",
    "te": "కెరీర్ క్వెస్ట్ మీ వయస్సు కల ఎనిమిది ఊహాజనిత పాత్రలు చేసిన ప్రయాణం తెలుసుకోవడానికి మిమ్మల్ని ఆహ్వానిస్తుంది. వారి జీవిత పరిస్థితులు కూడా మీలాగే అనిపించవచ్చు. ఈ పాత్రలు వారి శ్రేయస్సు, కెరీర్ మరియు జీవితాన్ని ప్రభావితం చేసే ఎంపికలను చేయడంలో సహాయపడండి. ఈ ప్రక్రియలో, విజయవంతమైన కెరీర్ నిర్ణయం తీసుకోవడానికి అవసరమైన జ్ఞానం, నైపుణ్యాలు మరియు భావాలను పెంచుకోండి.",
    "ka": "ಕೆರಿಯರ್ ಕ್ವೆಸ್ಟ್ ನಿಮ್ಮ ವಯಸ್ಸಿನ ಎಂಟು ಕಾಲ್ಪನಿಕ ಪಾತ್ರಗಳ ಪ್ರಯಾಣಕ್ಕೆ ನಿಮ್ಮನ್ನು ಆಹ್ವಾನಿಸುತ್ತದೆ. ಅವರ ಜೀವನ ಸನ್ನಿವೇಶಗಳು ನಿಮ್ಮಂತೆಯೇ ಕಾಣಿಸಬಹುದು. ಈ ಪಾತ್ರಗಳು ಅವರ ಯೋಗಕ್ಷೇಮ, ವೃತ್ತಿ ಮತ್ತು ಜೀವನದ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುವ ಆಯ್ಕೆಗಳನ್ನು ಮಾಡಲು ಸಹಾಯ ಮಾಡಿ. ಈ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ, ವೃತ್ತಿಜೀವನದ ಯಶಸ್ವಿ ನಿರ್ಧಾರಕ್ಕೆ ಅಗತ್ಯವಾದ ಜ್ಞಾನ, ಕೌಶಲ್ಯ ಮತ್ತು ಮನಸ್ಥಿತಿಗಳನ್ನು ಪಡೆದುಕೊಳ್ಳಿ.",
    "or": "ଆପଣଙ୍କୁ କ୍ୟାରିଅର୍ କ୍ୱେଷ୍ଟ୍ ଖେଳରେ ନିଜ ବୟସର ଆଠଜଣ କାଳ୍ପନିକ ପାତ୍ରମାନଙ୍କ ଦ୍ୱାରା କରାଯାଇଥିବା ଯାତ୍ରା ଉପରେ ଆମନ୍ତ୍ରଣ କରନ୍ତି | ସେମାନଙ୍କ ଜୀବନ ସ୍ଥିତି ବି ଆପଣଙ୍କ ଭଳି ଲାଗିପାରେ | ଏହି ପାତ୍ରମାନଙ୍କୁ ଏପରି ବିକଳ୍ପ ଚୟନରେ ସାହାର୍ଯ୍ୟ କରନ୍ତୁ ଯାହା ତାଙ୍କର ଭଲ,କ୍ୟାରିୟର୍‌ ଏବଂ ଜୀବନକୁ ପ୍ରଭାବିତ କରନ୍ତୁ | ଏହି ପ୍ରକ୍ରିୟାରେ, ସଫଳ କ୍ୟାରିୟର୍‍ ନିର୍ଣ୍ଣୟ ନେବା ପାଇଁ ଆବଶ୍ୟକ ଜ୍ଞାନ, କୌଶଳ ଏବଂ ମାନସିକତାକୁ ଚୟନ କରନ୍ତୁ |",
    "as": "জীৱিকা সন্ধানে তোমাক তোমাৰ বয়সৰ আঠটা কাল্পনিক চৰিত্ৰৰ দ্বাৰা লোৱা যাত্ৰালৈ আমন্ত্ৰণ জনাইছে। তেওঁলোকৰ জীৱনৰ পৰিস্থিতি আনকি তোমাৰ নিজৰ নিচিনা যেনো লাগিব পাৰে। এই চৰিত্ৰবোৰক তেওঁলোকৰ সুস্থতা, জীৱিকা আৰু জীৱনত প্ৰভাৱ পেলাব পৰা বিষয়বোৰ বাছনি কৰাত সহায় কৰা। এই প্ৰক্ৰিয়াটোত, সফল জীৱিকাৰ সিদ্ধান্ত লোৱাৰ বাবে প্ৰয়োজনীয় জ্ঞান, দক্ষতা আৰু মানসিকতা গ্ৰহণ কৰা।",
    "gu": "કરિયર ક્વેસ્ટ ગેમ વિકલ્પના સ્વરૂપમાં તેમાંથી કેટલાક નિર્ણયોની એક ઝલક રજૂ કરે છે અને એ દર્શાવે છે કે નિર્ણય કેવી રીતે કરિયર પથને અસર કરે છે. વિવિધ કરિયર-સંબંધિત ટૂલ અને યાત્રાના માધ્યમથી, ગેમ તમને પરિસ્થિતિઓનું વિષ્લેષણ કરવાની ક્ષમતા આપે છે."
  },
  "career quest introduces you to a range of tools that are essential for good career decision-making  use some of these for your characters and see how they impact their economic and social status": {
    "en": "Career Quest introduces you to a range of tools that are essential for good career decision-making.  Use some of these for your characters and see how they impact their economic and social status.",
    "hi": "करियर क्वेस्ट आपको कई प्रकार के औजारों का परिचय देता है जो अच्छे करियर निर्णय लेने के लिए आवश्यक हैं। इनमें से कुछ को अपने पात्रों के लिए उपयोग करें और देखें कि वे अपनी आर्थिक और सामाजिक स्थिति को कैसे प्रभावित करते हैं।",
    "te": "కెరీర్ క్వెస్ట్ మీకు మంచి కెరీర్ డెసిషన్ మేకింగ్ కోసం అవసరమైన అనేక రకాల సాధనాలను మీకు పరిచయం చేస్తుంది. మీ పాత్రల కోసం వీటిలో కొన్నింటిని ఉపయోగించండి మరియు అవి వారి ఆర్థిక మరియు సామాజిక స్థితిని ఎలా ప్రభావితం చేస్తాయో చూడండి.",
    "ka": "ಕೆರಿಯರ್ ಕ್ವೆಸ್ಟ್ ನಿಮಗೆ ಉತ್ತಮ ವೃತ್ತಿ ನಿರ್ಧಾರ ಕೈಗೊಳ್ಳಲು ಅಗತ್ಯವಾದ ಪರಿಕರಗಳ ಶ್ರೇಣಿಯನ್ನು ಪರಿಚಯಿಸುತ್ತದೆ. ಇವುಗಳಲ್ಲಿ ಕೆಲವನ್ನು ನಿಮ್ಮ ಪಾತ್ರಗಳಿಗಾಗಿ ಬಳಸಿ ಮತ್ತು ಅದು ಅವರ ಆರ್ಥಿಕ ಮತ್ತು ಸಾಮಾಜಿಕ ಸ್ಥಿತಿಯನ್ನು ಹೇಗೆ ಪ್ರಭಾವಿಸುತ್ತದೆ ಎಂಬುದನ್ನು ನೋಡಿ.",
    "or": "ଆପଣଙ୍କୁ କ୍ୟାରିଅର୍ କ୍ୱେଷ୍ଟ୍ ଖେଳରେ ଏହିପରି କେତେ ଉପକରଣ ସହ ପରିଚିତ କରାଇଥାଏ ଯାହା ଭଲ କ୍ୟାରିୟର୍‌ ନିର୍ଣ୍ଣୟରେ ଆବଶ୍ଯକ ଅଟେ |ସେଥିରୁ କିଛି ନିଜ ପାତ୍ରମାନଙ୍କ ପାଇଁ ଉପଯୋଗ କରନ୍ତୁ ଏବଂ ଦେଖନ୍ତୁ ଯେ ତାହା କିପରି ତାଙ୍କର ସାମାଜିକ ଓ ଆର୍ଥିକ ସ୍ଥିତିକୁ ପ୍ରଭାବିତ କରୁଛି |",
    "as": "জীৱিকা সন্ধানে তোমাক বিভিন্ন সঁজুলিৰ সৈতে পৰিচয় কৰাই দিয়ে যিবোৰ ভাল জীৱিকাৰ সিদ্ধান্ত লোৱাৰ বাবে অত্যাৱশ্যকীয়। এইবোৰৰ কিছুমান তোমাৰ চৰিত্ৰৰ বাবে ব্যৱহাৰ কৰা আৰু সেইবোৰে তেওঁলোকৰ অৰ্থনৈতিক আৰু সামাজিক স্থিতিত কেনেদৰে প্ৰভাৱ পেলায় লক্ষ্য কৰা।",
    "gu": "રમતની વિવિધ કહાનીઓના માધ્યમથી વાસ્તવિક દુનિયાની સ્થિતિનું અનુકરણ કરે છે. તમારા દ્વારા પસંદ કરવામાં આવેલા વિકલ્પની કહાનીઓના કરિયર પથને અસર કરશે, જે તે યોગ્યતા ધરાવતા સામાજીક અને આર્થિક સ્થિતિને આકાર આપશે."
  },
  "career points": {
    "en": "Career Points",
    "hi": "करियर पॉइंट",
    "te": "కెరీర్ పాయింట్లు",
    "ka": "ವೃತ್ತಿಜೀವನದ ಅಂಕಗಳು",
    "or": "କ୍ୟାରିଅର୍ ପଏଣ୍ଟସ୍",
    "as": "জীৱিকাৰ পইণ্ট",
    "gu": "કરિયર પોઇન્ટ્સ"
  },
  "with each choice you make for the character, you will gain or lose points these are called 'career points' there are 3 types of career points - money, satisfaction, and social acceptance": {
    "en": "With each choice you make for the character, you will gain or lose points. These are called 'Career Points'. There are 3 types of Career points - Money, Satisfaction, and Social Acceptance.",
    "hi": "चरित्र के लिए आपके द्वारा चुने गए प्रत्येक विकल्प के साथ, आप अंक प्राप्त करेंगे या खो देंगे। इन्हें 'करियर पॉइंट' कहा जाता है। करियर पॉइंट 3 प्रकार के होते हैं - पैसा, संतुष्टि और सामाजिक स्वीकृति।",
    "te": "పాత్ర కోసం మీరు చేసే ప్రతి ఎంపికతో, మీరు పాయింట్లను పొందుతారు లేదా కోల్పోతారు. వీటిని 'కెరీర్ పాయింట్స్' అంటారు. 3 రకాల కెరీర్ పాయింట్లు ఉన్నాయి - డబ్బు, సంతృప్తి మరియు సామాజిక అంగీకారం.",
    "ka": "ಪಾತ್ರಕ್ಕಾಗಿ ನೀವು ಮಾಡುವ ಪ್ರತಿಯೊಂದು ಆಯ್ಕೆಯೊಂದಿಗೆ, ನೀವು ಅಂಕಗಳನ್ನು ಗಳಿಸುತ್ತೀರಿ ಅಥವಾ ಕಳೆದುಕೊಳ್ಳುತ್ತೀರಿ. ಇವುಗಳನ್ನು 'ಕೆರಿಯರ್ ಪಾಯಿಂಟ್ಸ್' ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ. ವೃತ್ತಿಜೀವನದಲ್ಲಿ 3 ವಿಧಗಳಿವೆ - ಹಣ, ತೃಪ್ತಿ ಮತ್ತು ಸಾಮಾಜಿಕ ಸ್ವೀಕಾರ.",
    "or": "ଚରିତ୍ର ପାଇଁ ଆପଣଙ୍କ ଦ୍ୱାରା ଚୟନ ହୋଇଥିବା ପ୍ରତ୍ୟେକ ବିକଳ୍ପ ସହିତ, ଆପଣ ଅଙ୍କ ପ୍ରାପ୍ତ କରିବେ ବା ହରାଇବେ | ଏହାକୁ କ୍ୟାରିୟର୍‌ ପଏଣ୍ଟସ୍‌ କୁହନ୍ତି | କ୍ୟାରିୟର୍‌ ପଏଣ୍ଟସ୍‌ ତିନି ପ୍ରକାରର ହୋଇଥାଏ- ଅର୍ଥ, ସନ୍ତୁଷ୍ଟି ଏବଂ ସାମାଜିକ ସ୍ୱୀକୃତି |",
    "as": "তুমি চৰিত্ৰটোৰ বাবে কৰা প্ৰতিটো পচন্দৰ সৈতে, তুমি পইণ্ট লাভ কৰিবা বা হেৰুৱাবা। এইবোৰক 'জীৱিকাৰ পইণ্ট' বুলি কোৱা হয়। 3 প্ৰকাৰৰ জীৱিকাৰ পইণ্ট আছে - ধন, সন্তুষ্টি, আৰু সামাজিক গ্ৰহণযোগ্যতা।",
    "gu": "પાત્રો માટે તમારા દ્વારા પસંદ કરવામાં આવેલા દરેક વિકલ્પ સાથે તમારે અંક પ્રાપ્ત કરશો અથવા ગુમાવશો. તેને 'કરિયર પોઇન્ટ' કહેવામાં આવે છે. કરિયર પોઇન્ટ 3 પ્રકારના છે- પૈસા, સંતોષ અને સામાજીક સ્વીકૃતિક."
  },
  "the decisions taken affect the character's relationship with money which may be for the better or not": {
    "en": "The decisions taken affect the character's relationship with money which may be for the better or not.",
    "hi": "लिए गए निर्णय चरित्र के पैसे के साथ संबंध को प्रभावित करते हैं जो बेहतर के लिए हो सकता है या नहीं।",
    "te": "మంచి అయినా కాకపోయినా కూడా, తీసుకున్న నిర్ణయాలు డబ్బుతో పాత్ర యొక్క సంబంధాన్ని ప్రభావితం చేస్తాయి.",
    "ka": "ತೆಗೆದುಕೊಂಡ ನಿರ್ಧಾರಗಳು ಹಣದೊಂದಿಗಿನ ಪಾತ್ರದ ಸಂಬಂಧದ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುತ್ತವೆ, ಅದು ಉತ್ತಮ ಅಥವಾ ಇಲ್ಲದಿರಬಹುದು.",
    "or": "ନିଆଯାଇଥିବା ନିର୍ଣ୍ଣୟ ଚରିତ୍ରର ଅର୍ଥ ସହିତ ସମ୍ପର୍କକୁ ପ୍ରଭାବିତ କରିଥାଏ ଯାହା ଭଲ ହୋଇଥାଏ ବା ନୁହେଁ |",
    "as": "লোৱা সিদ্ধান্তবোৰে ধনৰ সৈতে চৰিত্ৰটোৰ সম্পৰ্কক প্ৰভাৱিত কৰে যি ভালৰ বাবে হ'ব পাৰে বা নহ'বও পাৰে।",
    "gu": "પૈસાઃ નિર્ણયો અંગે પૈસા સાથે પાત્રોનો સંબંધ"
  },
  "the decisions prompted by personal satisfaction affect the character’s dreams and aspirations and how successfully they achieve them": {
    "en": "The decisions prompted by personal satisfaction affect the character’s dreams and aspirations and how successfully they achieve them.",
    "hi": "व्यक्तिगत संतुष्टि से प्रेरित निर्णय चरित्र के सपनों और आकांक्षाओं को प्रभावित करते हैं और वे उन्हें कितनी सफलतापूर्वक प्राप्त करते हैं।",
    "te": "వ్యక్తిగత సంతృప్తి ద్వారా తీసుకున్న నిర్ణయాలు పాత్ర యొక్క కలలు మరియు ఆకాంక్షలను మరియు వారు వాటిని ఎంత విజయవంతంగా సాధిస్తారు అనే అంశాలను ప్రభావితం చేస్తాయి.",
    "ka": "ವೈಯಕ್ತಿಕ ತೃಪ್ತಿಯಿಂದ ಪ್ರೇರೇಪಿಸಲ್ಪಟ್ಟ ನಿರ್ಧಾರಗಳು ಪಾತ್ರದ ಕನಸುಗಳು ಮತ್ತು ಆಕಾಂಕ್ಷೆಗಳು ಮತ್ತು ಅವರು ಅವುಗಳನ್ನು ಎಷ್ಟು ಯಶಸ್ವಿಯಾಗಿ ಸಾಧಿಸುತ್ತಾರೆ ಎಂಬುದರ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುತ್ತವೆ.",
    "or": "ବ୍ୟକ୍ତିଗତ ସନ୍ତୁଷ୍ଟିରୁ ପ୍ରେରିତ ହୋଇଥିବା ନିର୍ଣ୍ଣୟ ଚରିତ୍ରର ସ୍ୱପ୍ନ ଓ ଆକାଂକ୍ଷାଗୁଡିକୁ ପ୍ରଭାବିତ କରିଥାଏ ଏବଂ ସେମାନେ ତାଙ୍କୁ କେତେ ସଫଳତାପୂର୍ବକ ପ୍ରାପ୍ତ କରିଥାନ୍ତି |",
    "as": "ব্যক্তিগত সন্তুষ্টিৰ দ্বাৰা প্ৰেৰিত সিদ্ধান্তবোৰে চৰিত্ৰটোৰ সপোন আৰু আকাংক্ষা আৰু সেইবোৰ কিমান সফলতাৰে প্ৰাপ্ত কৰে তাক প্ৰভাৱিত কৰে।",
    "gu": "સંતોષઃ નિર્ણયો બાદ પાત્રોના સપના તથા આકાંક્ષાનો સંબંધ"
  },
  "the family and the community play an important role in the success of individuals, see how acceptance from them impacts the decisions of the characters": {
    "en": "The family and the community play an important role in the success of individuals, see how acceptance from them impacts the decisions of the characters.",
    "hi": "परिवार और समुदाय व्यक्तियों की सफलता में महत्वपूर्ण भूमिका निभाते हैं, देखें कि उनकी स्वीकृति पात्रों के निर्णयों को कैसे प्रभावित करती है।",
    "te": "వ్యక్తుల విజయంలో కుటుంబం మరియు సంఘం ముఖ్యమైన పాత్ర పోషిస్తాయి, వారి అంగీకారం పాత్రల నిర్ణయాలను ఎలా ప్రభావితం చేస్తుందో చూడండి.",
    "ka": "ವ್ಯಕ್ತಿಗಳ ಯಶಸ್ಸಿನಲ್ಲಿ ಕುಟುಂಬ ಮತ್ತು ಸಮುದಾಯವು ಪ್ರಮುಖ ಪಾತ್ರವನ್ನು ವಹಿಸುತ್ತದೆ, ಅವರಿಂದ ಸ್ವೀಕಾರವು ಪಾತ್ರಗಳ ನಿರ್ಧಾರಗಳ ಮೇಲೆ ಹೇಗೆ ಪರಿಣಾಮ ಬೀರುತ್ತದೆ ಎಂಬುದನ್ನು ನೋಡಿ.",
    "or": "ପରିବାର ଏବଂ ସମୁଦାୟ ବ୍ଯକ୍ତିମାନଙ୍କର ସଫଳତାରେ ମହତ୍ତ୍ୱପୂର୍ଣ୍ଣ ଭୂମିକା ନିର୍ବାହ କରନ୍ତି, ସେମାନଙ୍କ ସ୍ୱୀକୃତି ଚରିତ୍ରମାନଙ୍କ ପାଇଁ ନିର୍ଣ୍ଣୟକୁ କିପରି ପ୍ରଭାବିତ କରୁଛି ଦେଖନ୍ତୁ |",
    "as": "ব্যক্তিসকলৰ সফলতাত পৰিয়াল আৰু সমাজে এক গুৰুত্বপূৰ্ণ ভূমিকা পালন কৰে, তেওঁলোকৰ গ্ৰহণযোগ্যতাই চৰিত্ৰবোৰৰ সিদ্ধান্তবোৰক কেনেদৰে প্ৰভাৱিত কৰে চোৱা।",
    "gu": "સ્વીકૃત્તિ"
  },
  "multiple ends": {
    "en": "Multiple ends",
    "hi": "एकाधिक छोर",
    "te": "బహుళ ముగింపులు",
    "ka": "ಬಹು ತುದಿಗಳು",
    "or": "ଏକାଧିକ ସମାପ୍ତ",
    "as": "একাধিক সমাপ্তি",
    "gu": "એકાધિક અંત"
  },
  "each choice impacts the career points and leads the character to different endings at times monetary goals are met while the character’s aspirations take a hit, other times, aspirations are met while social acceptance is harder to negotiate the game allows you to return to decision points to explore alternate endings the journeys you take are accessible through a map at all times": {
    "en": "Each choice impacts the career points and leads the character to different endings. At times monetary goals are met while the character’s aspirations take a hit, other times, aspirations are met while social acceptance is harder to negotiate. The game allows you to return to decision points to explore alternate endings. The journeys you take are accessible through a map at all times.",
    "hi": "प्रत्येक विकल्प कैरियर पॉइंट को प्रभावित करता है और चरित्र को अलग-अलग अंत तक ले जाता है। कभी-कभी मौद्रिक लक्ष्यों को पूरा किया जाता है जबकि चरित्र की आकांक्षाएं हिट होती हैं, और कयी बार, आकांक्षाएं पूरी होती हैं जबकि सामाजिक स्वीकृति पर बातचीत करना कठिन होता है। गेम आपको वैकल्पिक अंत का पता लगाने के लिए निर्णय बिंदुओं पर लौटने की अनुमति देता है। आपके द्वारा की जाने वाली यात्राएं हर समय मानचित्र के माध्यम से पहुंच योग्य होती हैं।",
    "te": "ప్రతి ఎంపిక కెరీర్ పాయింట్లను ప్రభావితం చేస్తుంది మరియు పాత్రకు విభిన్న ముగింపులను అందిస్తుంది. కొన్నిసార్లు ఆర్థిక ఇబ్బందులు తీరుతాయి, కానీ పాత్ర యొక్క కోరికలు నెరవేరవు. మరికొన్ని సార్లు కోరికలు నెరవేరుతాయి, కానీ సామాజిక అంగీకారం కోసం చర్చలు చేయడం కష్టం. ప్రత్యామ్నాయ ముగింపులను తెలుసుకోవడానికి గేమ్ లో డెసిషన్ పాయింట్‌లకు తిరిగి రావచ్చు. మీరు చేసే ప్రయాణాలు అన్ని సమయాల్లో మ్యాప్ ద్వారా అందుబాటులో ఉంటాయి.",
    "ka": "ಪ್ರತಿಯೊಂದು ಆಯ್ಕೆಯು ವೃತ್ತಿಜೀವನದ ಬಿಂದುಗಳ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುತ್ತದೆ ಮತ್ತು ಪಾತ್ರವನ್ನು ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿಗೆ ಕೊಂಡೊಯ್ಯುತ್ತದೆ. ಕೆಲವೊಮ್ಮೆ ವಿತ್ತೀಯ ಗುರಿಗಳನ್ನು ಸಾಧಿಸಲಾಗುತ್ತದೆ ಆದರೆ ಪಾತ್ರದ ಆಕಾಂಕ್ಷೆಗಳು ಹಿಟ್ ಆಗುತ್ತವೆ, ಇತರ ಸಮಯಗಳಲ್ಲಿ, ಸಾಮಾಜಿಕ ಸ್ವೀಕಾರವನ್ನು ಮಾತುಕತೆ ಮಾಡಲು ಕಷ್ಟವಾದಾಗ ಆಕಾಂಕ್ಷೆಗಳನ್ನು ಪೂರೈಸಲಾಗುತ್ತದೆ. ಪರ್ಯಾಯ ಅಂತ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ನಿರ್ಧಾರ ಬಿಂದುಗಳಿಗೆ ಹಿಂತಿರುಗಲು ಆಟವು ನಿಮಗೆ ಅನುಮತಿಸುತ್ತದೆ. ನೀವು ತೆಗೆದುಕೊಳ್ಳುವ ಪ್ರಯಾಣಗಳನ್ನು ಎಲ್ಲಾ ಸಮಯದಲ್ಲೂ ನಕ್ಷೆಯ ಮೂಲಕ ಪ್ರವೇಶಿಸಬಹುದು.",
    "or": "ପ୍ରତ୍ୟେକ ବିକଳ୍ପ କ୍ୟାରିୟର ପଏଣ୍ଟକୁ ପ୍ରଭାବିତ କରିଥାଏ ଏବଂ ଚରିତ୍ରକୁ ଶେଷର ବିଭିନ୍ନ ସ୍ଥାନକୁ ନେଇଥାଏ | ବେଳେବେଳେ ଆର୍ଥିକ ଲକ୍ଷ୍ୟ ପୂରଣ ହୁଏ ଯେତେବେଳେ କି ଚରିତ୍ରର ଆକାଂକ୍ଷା ଆଘାତ ପ୍ରାପ୍ତ ହୁଏ, ଅନ୍ୟ ସମୟରେ, ଆକାଂକ୍ଷା ପୂରଣ ହୁଏ ଯେତେବେଳେ କି ସାମାଜିକ ସ୍ୱୀକୃତିକୁ ବୁଝିବା କଷ୍ଟକର ହୋଇଥାଏ | ଖେଳ ଆପଣଙ୍କୁ ବିକଳ୍ପ ସମାପ୍ତିକୁ ଅନୁସନ୍ଧାନ କରିବା ପାଇଁ ନିର୍ଣ୍ଣୟ ପଏଣ୍ଟକୁ ଫେରିବାକୁ ଅନୁମତି ଦେଇଥାଏ | ଆପଣ ନଉଥିବା ଯାତ୍ରା ସବୁବେଳେ ମ୍ୟାପ୍‌ ମାଧ୍ୟମରେ ଉପଲବ୍ଧ ହୋଇଥାଏ |",
    "as": "প্ৰতিটো পচন্দে জীৱিকাৰ পইণ্টবোৰ প্ৰভাৱিত কৰে আৰু চৰিত্ৰটোক বিভিন্ন সমাপ্তিলৈ লৈ যায়। কেতিয়াবা আৰ্থিক লক্ষ্য পূৰণ কৰা হয় য’ত চৰিত্ৰটোৰ আকাংক্ষা আঘাত প্ৰাপ্ত হয়, আন সময়ত, আকাংক্ষাবোৰ পূৰণ কৰা হয় য’ত সামাজিক গ্ৰহণযোগ্যতা মীমাংসা কৰা কঠিন হয়। খেলটোৱে তোমাক বৈকল্পিক সমাপ্তি অন্বেষণ কৰিবলৈ সিদ্ধান্ত বিন্দুলৈ ঘূৰি আহিবলৈ অনুমতি দিয়ে। তুমি লোৱা যাত্ৰাবোৰ সকলো সময়তে মানচিত্ৰৰ জৰিয়তে উপলব্ধ।",
    "gu": "દરેક પસંદ કરિયર પોઇન્ટ્સને અસર કરે છે, અને પાત્રોને અલગ-અલગ અંત સુધી લઈ જાય છે. રમતને તમે વૈકલ્પિક અંતની શોધ કરવા માટે તમારા નિર્ણય પર પરત ફરવા મંજૂરી આપે છે."
  },
  "you will learn many career-related tools and concepts as you progress through the stories every time you come across a tool in your character’s story, you unlock an ’experience card’": {
    "en": "You will learn many career-related tools and concepts as you progress through the stories. Every time you come across a tool in your character’s story, you unlock an ’Experience Card’.",
    "hi": "जैसे-जैसे आप कहानियों के माध्यम से आगे बढ़ेंगे, आप करियर से संबंधित कई उपकरण और अवधारणाएँ सीखेंगे। हर बार जब आप अपने चरित्र की कहानी में एक टूल देखते हैं, तो आप एक 'एक्सपीरियंस कार्ड' को अनलॉक करते हैं।",
    "te": "మీరు కథలు చూసే కొద్దీ మీరు అనేక కెరీర్-సంబంధిత సాధనాలు మరియు భావనలను నేర్చుకుంటారు. మీరు మీ పాత్ర కథలో ఏదైనా ఒక సాధనాన్ని చూసిన ప్రతిసారీ, మీకు ఒక 'అనుభవ కార్డ్' అన్‌లాక్ అవుతుంది.",
    "ka": "ನೀವು ಕಥೆಗಳ ಮೂಲಕ ಪ್ರಗತಿಯಲ್ಲಿರುವಾಗ ನೀವು ಅನೇಕ ವೃತ್ತಿ-ಸಂಬಂಧಿತ ಪರಿಕರಗಳು ಮತ್ತು ಪರಿಕಲ್ಪನೆಗಳನ್ನು ಕಲಿಯುವಿರಿ. ನಿಮ್ಮ ಪಾತ್ರದ ಕಥೆಯಲ್ಲಿ ನೀವು ಪ್ರತಿ ಬಾರಿ ಉಪಕರಣವನ್ನು ನೋಡಿದಾಗ, ನೀವು 'ಅನುಭವ ಕಾರ್ಡ್' ಅನ್ನು ಅನ್ಲಾಕ್ ಮಾಡುತ್ತೀರಿ.",
    "or": "ଯେପରି ଆପଣ କାହାଣୀ ମାଧ୍ୟମରେ ଆଗକୁ ବଢିବେ, ଆପଣ ଅନେକ କ୍ୟାରିୟର ସମ୍ବନ୍ଧୀୟ ଉପକରଣ ଏବଂ ଧାରଣା ଶିଖିବେ | ପ୍ରତ୍ୟେକ ଥର ଯେତେବେଳେ ଆପଣ ନିଜ ଚରିତ୍ରର କାହାଣୀରେ ଏକ ଉପକରଣ ଦେଖିବେ, ଆପଣ ଏକ 'ଅଭିଜ୍ଞତା କାର୍ଡ' ଅନଲକ୍ କରିବେ |",
    "as": "তুমি কাহিনীবোৰৰ মাজেৰে আগবাঢ়ি যোৱাৰ লগে লগে জীৱিকা সম্পৰ্কীয় বহুতো সঁজুলি আৰু ধাৰণা শিকিব পাৰিবা। যেতিয়াই তুমি তোমাৰ চৰিত্ৰৰ কাহিনীত এটা সঁজুলি গ্ৰহন কৰিবা, তুমি এখন 'অভিজ্ঞতা কাৰ্ড' খুলিবা।",
    "gu": "જેમ-જેમ તમે કહાનીઓના માધ્યમથી આગળ વધશો, તમે કરિયરને લગતી અનેક ટૂલ્સ અને વિષય શીખશું. દરેક વખતે તમે કોઈ ટૂલ અંગે શિખશો તો તમે એક 'એક્સપિરીયન્સ કાર્ડ'ને અનલોક કરશો. આ કાર્ડ તમને ટૂલને વધારે યોગ્ય રીતે સમજાવવા અને તમારી કરિયર યાત્રામાં તેનો ઉપયોગ કરવામાં મદદ કરશે."
  },
  "this card will help you understand the tool better and use it in your career journeyunlock experience cards as you explore the different endings of each character": {
    "en": "This card will help you understand the tool better and use it in your career journey.Unlock Experience Cards as you explore the different endings of each character.",
    "hi": "यह कार्ड आपको टूल को बेहतर ढंग से समझने और अपनी करियर यात्रा में इसका उपयोग करने में मदद करेगा।अनुभव कार्ड अनलॉक करें क्योंकि आप प्रत्येक चरित्र के अलग-अलग अंत का पता लगाते हैं।",
    "te": "ఈ కార్డ్ మీకు సాధనాన్ని బాగా అర్థం చేసుకోవడానికి మరియు మీ కెరీర్ ప్రయాణంలో దాన్ని ఉపయోగించడానికి సహాయపడుతుంది.  మీరు ప్రతి పాత్ర యొక్క విభిన్న ముగింపులను అన్వేషిస్తున్నప్పుడు అనుభవ కార్డ్‌లను అన్‌లాక్ చేయవచ్చు.",
    "ka": "ಈ ಕಾರ್ಡ್ ನಿಮಗೆ ಉಪಕರಣವನ್ನು ಚೆನ್ನಾಗಿ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಮತ್ತು ನಿಮ್ಮ ವೃತ್ತಿಜೀವನದ ಪ್ರಯಾಣದಲ್ಲಿ ಬಳಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನೀವು ಪ್ರತಿ ಪಾತ್ರದ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸುವಾಗ ಅನುಭವ ಕಾರ್ಡ್‌ಗಳನ್ನು ಅನ್‌ಲಾಕ್ ಮಾಡಿ.",
    "or": "ଏହି କାର୍ଡ ଆପଣଙ୍କ ଉପକରଣକୁ ଭଲ ଭାବରେ ବୁଝିବାକୁ ସାହାଯ୍ୟ କରିବ ଏବଂ ଏହାକୁ ଆପଣଙ୍କ କ୍ୟାରିୟର ଯାତ୍ରାରେ ବ୍ୟବହାର କରିପାରିବେ | ଅନୁଭବ କାର୍ଡକୁ ଅନଲକ୍ କରନ୍ତୁ କାରଣ ଆପଣ ପ୍ରତ୍ୟେକ ଚରିତ୍ରର ଭିନ୍ନ ସମାପ୍ତିକୁ ଅନୁସନ୍ଧାନ କରନ୍ତି |",
    "as": "এই কাৰ্ডখনে তোমাক সঁজুলিটো ভালদৰে বুজাত আৰু ইয়াক তোমাৰ জীৱিকাৰ যাত্ৰাত ব্যৱহাৰ কৰাত সহায় কৰিব। তুমি প্ৰতিটো চৰিত্ৰৰ বিভিন্ন সমাপ্তি অন্বেষণ কৰাৰ লগে লগে অভিজ্ঞতা কাৰ্ডবোৰ খুলি যাবা।",
    "gu": "એક્સપિરીયન્સ કાર્ડ અનલોક કરવાથી તમને દરેક ચરિત્રના અલગ-અલગ અંત અંગે માહિતી મળી શકશે."
  },
  "social acceptance": {
    "en": "Social Acceptance",
    "hi": "सामाजिक स्वीकृति",
    "te": "సామాజిక అంగీకారం",
    "ka": "ಸಾಮಾಜಿಕ ಸ್ವೀಕಾರ",
    "or": "ସାମାଜିକ ସ୍ୱୀକୃତି",
    "as": "সামাজিক গ্ৰহণযোগ্যতা",
    "gu": "સામાજીક સ્વીકૃતિ"
  },
  "money": {
    "en": "Money",
    "hi": "धन",
    "te": "డబ్బు",
    "ka": "ಹಣ",
    "or": "ଅର୍ଥ",
    "as": "ধন",
    "gu": "નાણાં"
  },
  "satisfaction": {
    "en": "Satisfaction",
    "hi": "संतुष्टि",
    "te": "సంతృప్తి",
    "ka": "ತೃಪ್ತಿ",
    "or": "ସନ୍ତୁଷ୍ଟି",
    "as": "সন্তুষ্টি",
    "gu": "સંતોષ"
  },
  "read more": {
    "en": "Read More",
    "hi": "अधिक पढ़ें",
    "te": "ఇంకా చదవండి",
    "ka": "ಮತ್ತಷ್ಟು ಓದು",
    "or": "ଅଧିକ ପଢ଼ନ୍ତୁ",
    "as": "অধিক পঢ়া",
    "gu": "વધુ વાંચો"
  },
  "apply": {
    "en": "Apply",
    "hi": "लागू करना",
    "te": "దరఖాస్తు చేసుకోండి",
    "ka": "ಅನ್ವಯಿಸು",
    "or": "ପ୍ରଯୋଗ କରନ୍ତୁ",
    "as": "প্ৰয়োগ কৰা",
    "gu": "લાગુ"
  },
  "tap here to read more": {
    "en": "Tap here to read more",
    "hi": "अधिक पढ़ने के लिए यहां टैप करें",
    "te": "మరింత చదవడానికి ఇక్కడ నొక్కండి",
    "ka": "ಇನ್ನಷ್ಟು ಓದಲು ಇಲ್ಲಿ ಟ್ಯಾಪ್ ಮಾಡಿ",
    "or": "ଅଧିକ ପଢ଼ିବା ପାଇଁ ଏଠାରେ ଟ୍ଯାପ୍‌ କରନ୍ତୁ",
    "as": "অধিক পঢ়িবলৈ ইয়াত টেপ কৰা",
    "gu": "વધુ વાંચવા માટે અહીં ટેપ કરો"
  },
  "search": {
    "en": "Search",
    "hi": "खोज",
    "te": "వెతకండి",
    "ka": "ಹುಡುಕಿ",
    "or": "ଖୋଜନ୍ତୁ",
    "as": "অনুসন্ধান",
    "gu": "શોધો"
  },
  "to": {
    "en": "To",
    "hi": "प्रति",
    "te": "ప్రియమైన",
    "ka": "ಗೆ",
    "or": "ପ୍ରତି",
    "as": "প্ৰতি",
    "gu": "પ્રતિ"
  },
  "subject": {
    "en": "Subject",
    "hi": "विषय",
    "te": "సబ్జెక్ట్",
    "ka": "ವಿಷಯ",
    "or": "ବିଷୟ",
    "as": "বিষয়",
    "gu": "વિષય"
  },
  "new message": {
    "en": "New Message",
    "hi": "नया संदेश",
    "te": "కొత్త మెసేజ్",
    "ka": "ಹೊಸ ಸಂದೇಶ",
    "or": "ନୂତନ ବାର୍ତ୍ତା",
    "as": "নতুন বাৰ্তা",
    "gu": "નવો સંદેશ"
  },
  "send email": {
    "en": "Send Email",
    "hi": "ईमेल भेजें",
    "te": "ఈ మెయిల్ పంపించండి",
    "ka": "ಇಮೇಲ್ ಕಳುಹಿಸಿ",
    "or": "ଇମେଲ୍‌ ପଠାନ୍ତୁ",
    "as": "ইমেইল প্ৰেৰণ কৰা",
    "gu": "ઈમેલ મોકલો"
  },
  "message": {
    "en": "Message",
    "hi": "संदेश",
    "te": "మెసేజ్",
    "ka": "ಸಂದೇಶ",
    "or": "ବାର୍ତ୍ତା",
    "as": "বাৰ্তা",
    "gu": "સંદેશ"
  },
  "take a look at the map": {
    "en": "Take a look at the Map",
    "hi": "नक़्शे पर एक नज़र डालें",
    "te": "మ్యాప్‌ని ఒకసారి చూడండి",
    "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
    "or": "ମ୍ୟାପ୍‌କୁ ଦେଖନ୍ତୁ",
    "as": "মানচিত্ৰখন চোৱা",
    "gu": "નકશા ઉપર એક નજર કરો"
  },
  "end of one journey": {
    "en": "End of one journey",
    "hi": "एक यात्रा का अंत",
    "te": "ఒక ప్రయాణం ముగింపు",
    "ka": "ಒಂದು ಪ್ರಯಾಣದ ಅಂತ್ಯ",
    "or": "ଗୋଟିଏ ଯାତ୍ରାର ଅନ୍ତ",
    "as": "এটা যাত্ৰাৰ সমাপ্তি",
    "gu": "એક યાત્રાનો અંત"
  },
  "congratulations!": {
    "en": "Congratulations!",
    "hi": "बधाई हो!",
    "te": "అభినందనలు!",
    "ka": "ಅಭಿನಂದನೆಗಳು!",
    "or": "ଅଭିନନ୍ଦନ",
    "as": "অভিনন্দন!",
    "gu": "અભિનંદન"
  },
  "you have finished one journey! you have made choices for __placeholder__ that has led to this possible ending there are different endings based on the choices you make do you want to explore other journeys?": {
    "en": "You have finished one journey! You have made choices for __PLACEHOLDER__ that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other journeys?",
    "hi": "आपने एक यात्रा पूरी कर ली है! आपने __PLACEHOLDER__ के लिए विकल्प बनाए हैं जिसके कारण यह संभावित अंत हुआ है। आपके द्वारा चुने गए विकल्पों के आधार पर अलग-अलग अंत होते हैं। क्या आप अन्य यात्राओं का पता लगाना चाहते हैं?",
    "te": "మీరు ఒక ప్రయాణాన్ని ముగించారు! మీరు __PLACEHOLDER__ కోసం ఎంపికలు చేసి ఈ ముగింపును పొందారు. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర ప్రయాణాలను చూడాలనుకుంటున్నారా?",
    "ka": "ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ! ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾದ __PLACEHOLDER__ ಗಾಗಿ ನೀವು ಆಯ್ಕೆಗಳನ್ನು ಮಾಡಿದ್ದೀರಿ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ಪ್ರಯಾಣಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
    "or": "ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି! ଆପଣ __PLACEHOLDER__ ପାଇଁ ବିକଳ୍ପ କରିଛନ୍ତି ଯାହା କାରଣରୁ ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତିକୁ ହୋଇଛି | ଆପଣଙ୍କ ଚୟନିତ ବିକଳ୍ପର ଆଧାର କରି ଭିନ୍ନ ଭିନ୍ନ ସମାପ୍ତି ହୋଇଛି | ଆପଣ ଅନ୍ୟ ଯାତ୍ରାର ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    "as": "তুমি এটা যাত্ৰা সমাপ্ত কৰিছা! তুমি বাছনি কৰিছা __PLACEHOLDER__ যাৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত আধাৰ কৰি বিভিন্ন পৰিসমাপ্তি আছে। তুমি আন যাত্ৰাবোৰ অন্বেষণ কৰিব বিচাৰা নেকি?",
    "gu": "તમે એક યાત્રા પૂરી કરી લીધી છે. તમે __PLACEHOLDER__ માટે પસંદ કરવામાં આવ્યા, જેને લીધે તેનો અંત શક્ય છે. તમારા દ્વારા પસંદ કરવામાં આવેલા વિકલ્પોના આધારે અલગ-અલગ અંત હોય છે. શું તમે અન્ય યાત્રા અંગે તપાસ કરી શકો છો?"
  },
  "you have recieved new mail": {
    "en": "You have recieved new mail",
    "hi": "आपने नया मेल प्राप्त किया है",
    "te": "మీకు కొత్త మెయిల్‌ వచ్చింది",
    "ka": "ನೀವು ಹೊಸ ಮೇಲ್ ಅನ್ನು ಸ್ವೀಕರಿಸಿದ್ದೀರಿ",
    "or": "ଆପଣ ଏକ ନୂତନ ମେଲ୍‌ ପ୍ରାପ୍ତ କରିଛନ୍ତି",
    "as": "তুমি নতুন মেইল পাইছা",
    "gu": "તમને નવો સંદેશ પ્રાપ્ત થયો છે"
  },
  "new message recieved": {
    "en": "New Message Recieved",
    "hi": "नया संदेश प्राप्त हुआ",
    "te": "కొత్త మెసేజ్ వచ్చింది",
    "ka": "ಹೊಸ ಸಂದೇಶವನ್ನು ಸ್ವೀಕರಿಸಲಾಗಿದೆ",
    "or": "ନୂତନ ବାର୍ତ୍ତା ପ୍ରାପ୍ତ ହେଲା",
    "as": "নতুন বাৰ্তা আহিছে",
    "gu": "નવો સંદેશ પ્રાપ્ત થયો"
  },
  "enter email": {
    "en": "Enter Email",
    "hi": "ईमेल दर्ज करें",
    "te": "ఇమెయిల్ ఎంటర్ చేయండి",
    "ka": "ಇಮೇಲ್ ನಮೂದಿಸಿ",
    "or": "ଇମେଲ୍‌ ଏଣ୍ଟର୍‌ କରନ୍ତୁ",
    "as": "ইমেইল লিখা",
    "gu": "ઈમેલ લખો"
  },
  "enter subject": {
    "en": "Enter Subject",
    "hi": "विषय दर्ज करें",
    "te": "సబ్జెక్ట్ ఎంటర్ చేయండి",
    "ka": "ವಿಷಯವನ್ನು ನಮೂದಿಸಿ",
    "or": "ବିଷୟ ଏଣ୍ଟର୍‌ କରନ୍ତୁ",
    "as": "বিষয় লিখা",
    "gu": "વિષય લખો"
  },
  "enter message": {
    "en": "Enter Message",
    "hi": "संदेश दर्ज करें",
    "te": "మెసేజ్ ఎంటర్ చేయండి",
    "ka": "ಸಂದೇಶವನ್ನು ನಮೂದಿಸಿ",
    "or": "ବାର୍ତ୍ତା ଏଣ୍ଟର୍‌ କରନ୍ତୁ",
    "as": "বাৰ্তা লিখা",
    "gu": "સંદેશ લખો"
  },
  "are you sure you want to go back to this decision point?": {
    "en": "Are you sure you want to go back to this decision point?",
    "hi": "क्या आप वाकई इस निर्णय पॉइंट पर वापस जाना चाहते हैं?",
    "te": "మీరు ఖచ్చితంగా ఈ డెసిషన్ పాయింట్ కి తిరిగి వెళ్లాలనుకుంటున్నారా?",
    "ka": "ಈ ನಿರ್ಧಾರದ ಹಂತಕ್ಕೆ ಹಿಂತಿರುಗಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
    "or": "ଆପଣ ଏହି ନିର୍ଣ୍ଣୟ ପଏଣ୍ଟକୁ ଫେରିବାକୁ ସୁନିଶ୍ଚିତ ଅଛନ୍ତି କି?",
    "as": "তুমি নিশ্চিতনে যে তুমি এই সিদ্ধান্ত বিন্দুলৈ ঘূৰি যাব বিচাৰা?",
    "gu": "પરત ફરો અને નિર્ણય બિંદુ સાથે રમવાનું શરૂ કરો"
  },
  "play!": {
    "en": "Play!",
    "hi": "खेलें",
    "te": "ఆడండి",
    "ka": "ಪ್ಲೇ ಮಾಡಿ",
    "or": "ଖେଳ",
    "as": "খেলা",
    "gu": "રમો"
  },
  "start": {
    "en": "Start",
    "hi": "शुरू",
    "te": "ప్రారంభించండి",
    "ka": "ಪ್ರಾರಂಭಿಸಿ",
    "or": "ଆରମ୍ଭ",
    "as": "আৰম্ভ কৰা",
    "gu": "પ્રારંભ"
  },
  "scroll down": {
    "en": "Scroll Down",
    "hi": "नीचे स्क्रॉल करें",
    "te": "కిందకి స్క్రోల్ చేయండి",
    "ka": "ಕೆಳಗೆ ಸ್ಕ್ರಾಲ್ ಮಾಡಿ",
    "or": "ତଳକୁ ସ୍କ୍ରୋଲ୍‌ କରନ୍ତୁ",
    "as": "তললৈ স্ক্ৰল কৰা",
    "gu": "નીચે સ્ક્રોલ કરો"
  },
  "scroll up": {
    "en": "Scroll Up",
    "hi": "ऊपर स्क्रॉल करें",
    "te": "పైకి స్క్రోల్ చేయండి",
    "ka": "ಮೇಲಕ್ಕೆ ಸ್ಕ್ರಾಲ್ ಮಾಡಿ",
    "or": "ଉପରକୁ ସ୍କ୍ରୋଲ୍‌ କରନ୍ତୁ",
    "as": "ওপৰলৈ স্ক্ৰল কৰা",
    "gu": "ઉપર સ્ક્રોલ કરો"
  },
  "scroll right": {
    "en": "Scroll Right",
    "hi": "दाएं स्क्रॉल करें",
    "te": "కుడివైపు స్క్రోల్ చేయండి",
    "ka": "ಬಲಕ್ಕೆ ಸ್ಕ್ರಾಲ್ ಮಾಡಿ",
    "or": "ଡାହାଣକୁ ସ୍କ୍ରୋଲ୍‌ କରନ୍ତୁ",
    "as": "সোঁফালে স্ক্ৰল কৰা",
    "gu": "જમણી બાજુ સ્ક્રોલ કરો"
  },
  "scroll left": {
    "en": "Scroll Left",
    "hi": "बाएं स्क्रॉल करें",
    "te": "ఎడమవైపు స్క్రోల్ చేయండి",
    "ka": "ಎಡಕ್ಕೆ ಸ್ಕ್ರಾಲ್ ಮಾಡಿ",
    "or": "ବାମକୁ ସ୍କ୍ରୋଲ୍‌ କରନ୍ତୁ",
    "as": "বাওঁফালে স্ক্ৰল কৰা",
    "gu": "ડાબી બાજુ સ્ક્રોલ કરો"
  },
  "next": {
    "en": "Next",
    "hi": "अगला",
    "te": "తరువాత",
    "ka": "ಮುಂದೆ",
    "or": "ପରବର୍ତ୍ତୀ",
    "as": "পৰৱৰ্তী",
    "gu": "હવે પછી"
  },
  "click here to see the menu": {
    "en": "Click here to see the menu",
    "hi": "मेनू देखने के लिए यहाँ क्लिक करें",
    "te": "మెనుని చూడటానికి ఇక్కడ క్లిక్ చేయండి",
    "ka": "ಮೆನು ನೋಡಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
    "or": "ମେନ୍ୟୁ ଦେଖିବାକୁ ଏଠାରେ କ୍ଲିକ୍‌ କରନ୍ତୁ",
    "as": "মেনু চাবলৈ ইয়াত ক্লিক কৰা",
    "gu": "મેનૂ જોવા માટે અહીં ક્લિક કરો"
  },
  "change characters using arrows": {
    "en": "Change characters using arrows",
    "hi": "दिशा कुंजियों का उपयोग करके वर्ण बदलें",
    "te": "యారోలను ఉపయోగించి పాత్రలను మార్చండి",
    "ka": "ಬಾಣಗಳನ್ನು ಬಳಸಿ ಅಕ್ಷರಗಳನ್ನು ಬದಲಾಯಿಸಿ",
    "or": "ଚରିତ୍ର ବଦଳିବା ପାଇଁ ଆରୋକୁ ବ୍ୟବହାର କରନ୍ତୁ",
    "as": "কাড় ব্যৱহাৰ কৰি চৰিত্ৰবোৰ সলনি কৰা",
    "gu": "નિશાનનો ઉપયોગ કરીને પાત્રો બદલો"
  },
  "to start a characters journey, click here": {
    "en": "To start a characters journey, click here.",
    "hi": "चरित्र यात्रा शुरू करने के लिए, यहाँ क्लिक करें।",
    "te": "ఒక పాత్ర యొక్క ప్రయాణాన్ని ప్రారంభించడానికి, ఇక్కడ క్లిక్ చేయండి.",
    "ka": "ಅಕ್ಷರಗಳ ಪ್ರಯಾಣವನ್ನು ಪ್ರಾರಂಭಿಸಲು, ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ.",
    "or": "ଚରିତ୍ରର ଯାତ୍ରା ଆରମ୍ଭ ନିମନ୍ତେ ଏଠାରେ କ୍ଲିକ୍‌ କରନ୍ତୁ",
    "as": "এটা চৰিত্ৰৰ যাত্ৰা আৰম্ভ কৰিবলৈ, ইয়াত ক্লিক কৰা।",
    "gu": "પાત્રની યાત્રા શરૂ કરવા માટે, અહીં ક્લિક કરો"
  },
  "experience you have gained during the player’s journey will be saved here": {
    "en": "Experience you have gained during the player’s journey will be saved here",
    "hi": "खिलाड़ी की यात्रा के दौरान आपने जो अनुभव प्राप्त किया है, वह यहां सहेजा जाएगा",
    "te": "ప్లేయర్ ప్రయాణంలో మీరు పొందిన అనుభవం ఇక్కడ సేవ్ చేయబడుతుంది",
    "ka": "ಆಟಗಾರನ ಪ್ರಯಾಣದ ಸಮಯದಲ್ಲಿ ನೀವು ಗಳಿಸಿದ ಅನುಭವವನ್ನು ಇಲ್ಲಿ ಉಳಿಸಲಾಗುತ್ತದೆ",
    "or": "ଖେଳାଳିଙ୍କ ଯାତ୍ରା ସମୟରେ ଆପଣ ପାଇଥିବା ଅନୁଭବ ଏଠାରେ ସଞ୍ଚୟ ହେବ",
    "as": "খেলুৱৈজনৰ যাত্ৰাৰ সময়ত তুমি লাভ কৰা অভিজ্ঞতা ইয়াত সংৰক্ষণ কৰা হ'ব",
    "gu": "પાત્રની યાત્રા સમયે તમને જે અનુભવ થયો છે તે તમને અહીં મળશે"
  },
  "the points you gain in the characters journey can be seen here": {
    "en": "The points you gain in the characters journey can be seen here",
    "hi": "पात्रों की यात्रा में आपके द्वारा प्राप्त पॉइंट यहां देखे जा सकते हैं",
    "te": "పాత్రల ప్రయాణంలో మీరు పొందే పాయింట్లను ఇక్కడ చూడవచ్చు",
    "ka": "ಪಾತ್ರಗಳ ಪ್ರಯಾಣದಲ್ಲಿ ನೀವು ಗಳಿಸುವ ಅಂಕಗಳನ್ನು ಇಲ್ಲಿ ನೋಡಬಹುದು",
    "or": "ଚରିତ୍ରଙ୍କ ଯାତ୍ରାରେ ଆପଣ ପାଇଥିବା ପଏଣ୍ଟଗୁଡିକ ଏଠାରେ ଦେଖିପାରିବେ",
    "as": "চৰিত্ৰৰ যাত্ৰাত তুমি লাভ কৰা পইণ্টবোৰ ইয়াত দেখা যাব",
    "gu": "પાત્રની યાત્રા સમયે તમને જે પોઇન્ટ મળ્યા છે તેને તમે અહીં જોઈ શકો છો"
  },
  "map": {
    "en": "Map",
    "hi": "नक्शा",
    "te": "మ్యాప్",
    "ka": "ನಕ್ಷೆ",
    "or": "ମ୍ୟାପ୍‌",
    "as": "মানচিত্ৰ",
    "gu": "નકશો"
  },
  "the characters journey so far can be viewed here": {
    "en": "The characters journey so far can be viewed here",
    "hi": "अब तक के पात्रों की यात्रा यहां देखी जा सकती है",
    "te": "ఇప్పటివరకు జరిగిన పాత్రల ప్రయాణాన్ని ఇక్కడ చూడవచ్చు",
    "ka": "ಇಲ್ಲಿಯವರೆಗಿನ ಪಾತ್ರಗಳ ಪಯಣವನ್ನು ಇಲ್ಲಿ ವೀಕ್ಷಿಸಬಹುದು",
    "or": "ଚରିତ୍ରର ଏବେ ଯାଏଁ ହେଇଥିବା ଯାତ୍ରା ଏଠାରେ ଦେଖିପାରିବେ",
    "as": "এতিয়ালৈকে হোৱা চৰিত্ৰবোৰৰ যাত্ৰা ইয়াত চাব পাৰি",
    "gu": "પાત્રની અત્યાર સુધીની યાત્રાને અહીં જોઈ શકાય છે"
  },
  "yes": {
    "en": "Yes",
    "hi": "हाँ",
    "te": "అవును",
    "ka": "ಹೌದು",
    "or": "ହଁ",
    "as": "হয়",
    "gu": "હા"
  },
  "enable": {
    "en": "Enable",
    "hi": "सक्षम",
    "te": "ఎనాబుల్ చేయండి",
    "ka": "ಸಕ್ರಿಯಗೊಳಿಸಿ",
    "or": "ଜାରି ରଖନ୍ତୁ",
    "as": "সক্ষম কৰা",
    "gu": "ચાલુ કરો"
  },
  "enable game sound for ideal experience": {
    "en": "Enable game sound for ideal experience",
    "hi": "आदर्श अनुभव के लिए खेल ध्वनि सक्षम करें",
    "te": "మంచి అనుభవం కోసం గేమ్ సౌండ్‌ని ఎనాబుల్ చేయండి",
    "ka": "ಆದರ್ಶ ಅನುಭವಕ್ಕಾಗಿ ಆಟದ ಧ್ವನಿಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
    "or": "ଆଦର୍ଶ ଅନୁଭବ ପାଇଁ ଖେଳର ଶଦ୍ଦ ସକ୍ଷମ କରନ୍ତୁ",
    "as": "আদৰ্শ অভিজ্ঞতাৰ বাবে খেলৰ ধ্বনি সক্ষম কৰা",
    "gu": "આદર્શ અનુભવ માટે ગેમ સાઉન્ડ ચાલું કરો"
  },
  "swipe to view characters": {
    "en": "Swipe to view characters",
    "hi": "पात्रों को देखने के लिए स्वाइप करें",
    "te": "పాత్రలను చూడడానికి స్వైప్ చేయండి",
    "ka": "ಅಕ್ಷರಗಳನ್ನು ವೀಕ್ಷಿಸಲು ಸ್ವೈಪ್ ಮಾಡಿ",
    "or": "ଚରିତ୍ରଗୁଡିକ ଦେଖିବା ପାଇଁ ସ୍ୱାଇପ୍ କରନ୍ତୁ",
    "as": "চৰিত্ৰসমূহ দৰ্শন কৰিবলৈ চোৱাইপ কৰা",
    "gu": "વધારે પાત્રોને જોવા માટે સ્વાઈપ કરો"
  },
  "got it": {
    "en": "Got it",
    "hi": "समझ लिया",
    "te": "దొరికింది",
    "ka": "ಅರ್ಥವಾಯಿತು",
    "or": "ବୁଝିପାରିଲି",
    "as": "বুজি পাইছা",
    "gu": "સમજમાં આવ્યું"
  },
  "new card added!": {
    "en": "New Card Added!",
    "hi": "नया कार्ड जोड़ा गया",
    "te": "కొత్త కార్డ్ జోడించబడింది",
    "ka": "ಹೊಸ ಕಾರ್ಡ್ ಸೇರಿಸಲಾಗಿದೆ",
    "or": "ନୂତନ କାର୍ଡ ଯୋଡି ହେଲା",
    "as": "নতুন কাৰ্ড যোগ কৰা হৈছে",
    "gu": "નવું કાર્ડ ઉમેરવામાં આવ્યું છે"
  },
  "career points updated!": {
    "en": "Career points updated!",
    "hi": "करियर पॉइंट अद्यतन",
    "te": "కెరీర్ పాయింట్లు అప్‌డేట్ చేయబడ్డాయి",
    "ka": "ವೃತ್ತಿಜೀವನದ ಅಂಕಗಳನ್ನು ನವೀಕರಿಸಲಾಗಿದೆ",
    "or": "କ୍ୟାରିୟର୍‌ ପଏଣ୍ଟ୍‌ ଯୋଡି ହେଲା",
    "as": "জীৱিকাৰ পইণ্ট আপডেট কৰা হৈছে",
    "gu": "કરિયર પોઇન્ટ્સ અપડેટ કરેલ"
  },
  "journey started": {
    "en": "Journey Started",
    "hi": "यात्रा शुरू हुई",
    "te": "ప్రయాణం మొదలైంది",
    "ka": "ಪ್ರಯಾಣ ಪ್ರಾರಂಭವಾಯಿತು",
    "or": "ଯାତ୍ରା ଆରମ୍ଭ",
    "as": "যাত্ৰা আৰম্ভ হৈছে",
    "gu": "યાત્રા શરૂ"
  },
  "characters": {
    "en": "Characters",
    "hi": "पात्र",
    "te": "పాత్రలు",
    "ka": "ಪಾತ್ರಗಳು",
    "or": "ଚରିତ୍ର",
    "as": "চৰিত্ৰসমূহ",
    "gu": "પાત્ર"
  },
  "jhanvi": {
    "en": "Jhanvi",
    "hi": "जाह्नवी",
    "te": "జాన్వి",
    "ka": "ಜಾನ್ವಿ",
    "or": "ଜାନବୀ",
    "as": "জাহ্নৱী",
    "gu": "જાહન્વી"
  },
  "mira": {
    "en": "Mira",
    "hi": "मीरा",
    "te": "మీరా",
    "ka": "ಮೀರಾ",
    "or": "ମୀରା",
    "as": "মীৰা",
    "gu": "મીરા"
  },
  "shikhar": {
    "en": "Shikhar",
    "hi": "शिखर",
    "te": "శిఖర్",
    "ka": "ಶಿಖರ್",
    "or": "ଶିଖର",
    "as": "শিখৰ",
    "gu": "શિખર"
  },
  "revathy": {
    "en": "Revathy",
    "hi": "रेवथी",
    "te": "రేవతి",
    "ka": "ರೇವತಿ",
    "or": "ରେବତୀ",
    "as": "ৰেৱথী",
    "gu": "રેવતી"
  },
  "madhav": {
    "en": "Madhav",
    "hi": "माधव",
    "te": "మాధవ్",
    "ka": "ಮಾಧವ್",
    "or": "ମାଧବ",
    "as": "মাধৱ",
    "gu": "માધવ"
  },
  "asmita": {
    "en": "Asmita",
    "hi": "अस्मिता",
    "te": "అస్మిత",
    "ka": "ಅಸ್ಮಿತಾ",
    "or": "ଅସ୍ମିତା",
    "as": "অস্মিতা",
    "gu": "અસ્મિતા"
  },
  "loading stories": {
    "en": "Loading Stories",
    "hi": "कहानियां लोड हो रही हैं",
    "te": "కథలు లోడ్ చేయబడుతున్నాయి",
    "ka": "ಕಥೆಗಳನ್ನು ಲೋಡ್ ಮಾಡಲಾಗುತ್ತಿದೆ",
    "or": "କାହାଣୀ ଲୋଡ୍ ହେଉଛି",
    "as": "কাহিনী সমূহ লোড কৰা হৈছে",
    "gu": "વાર્તા લોડીંગ થઈ રહી છે"
  },
  "choose a character": {
    "en": "Choose a Character",
    "hi": "एक चरित्र चुनें",
    "te": "ఒక పాత్రను ఎంచుకోండి",
    "ka": "ಒಂದು ಪಾತ್ರವನ್ನು ಆರಿಸಿ",
    "or": "ଚରିତ୍ର ବାଛନ୍ତୁ",
    "as": "এটা চৰিত্ৰ বাছনি কৰা",
    "gu": "પાત્રની પસંદગી કરો"
  },
  "jhanvi is a confident and independent-minded girl who lives in mumbai she wants to start a business and be a successful businesswoman": {
    "en": "Jhanvi is a confident and independent-minded girl who lives in Mumbai. She wants to start a business and be a successful businesswoman.",
    "hi": "जाह्नवी एक आत्मविश्वासी और स्वतंत्र सोच वाली लड़की है जो मुंबई में रहती है। वह एक व्यवसाय शुरू करना चाहती है और एक सफल व्यवसायी बनना चाहती है।",
    "te": "జాన్వి ముంబైలో నివసిస్తుంది, ఆమె ఆత్మవిశ్వాసం మరియు స్వతంత్ర ఆలోచన కలిగిన అమ్మాయి. ఆమె వ్యాపారం ప్రారంభించి విజయవంతమైన వ్యాపారవేత్తగా ఎదగాలని కోరుకుంటుంది.",
    "ka": "ಜಾನ್ವಿ ಮುಂಬೈನಲ್ಲಿ ವಾಸಿಸುವ ಆತ್ಮವಿಶ್ವಾಸ ಹೊಂದಿರುವ ಮತ್ತು ಸ್ವತಂತ್ರ ಮನಸ್ಸಿನ ಹುಡುಗಿ. ಅವಳು ವ್ಯಾಪಾರವನ್ನು ಪ್ರಾರಂಭಿಸಲು ಮತ್ತು ಯಶಸ್ವಿ ಉದ್ಯಮಿಯಾಗಲು ಬಯಸುತ್ತಾಳೆ.",
    "or": "ଜାନଭୀ ଜଣେ ଆତ୍ମବିଶ୍ୱାସୀ ଏବଂ ସ୍ୱାଧୀନ ଚିନ୍ତାଧାରା ବିଶିଷ୍ଟ ଝିଅ ଯିଏକି ମୁମ୍ବାଇରେ ରହୁଛନ୍ତି | ସେ ଏକ ବ୍ୟବସାୟ ଆରମ୍ଭ କରିବାକୁ ଏବଂ ଜଣେ ସଫଳ ବ୍ୟବସାୟୀ ହେବାକୁ ଚାହୁଁଛନ୍ତି |",
    "as": "জাহ্নৱী মুম্বাইত বাস কৰা এগৰাকী আত্মবিশ্বাসী আৰু স্বতন্ত্ৰ মনৰ ছোৱালী। তাই এটা ব্যৱসায় আৰম্ভ কৰিব বিচাৰে আৰু এগৰাকী সফল মহিলা ব্যৱসায়ী হ'ব বিচাৰে।",
    "gu": "જાહન્વી એક આત્મવિશ્વાસી અને મુક્ત વિચારો ધરાવતી છોકરી છે,જે મુંબઈમાં રહે છે. તે એક વ્યવસાય શરૂ કરવા ઈચ્છે છે અને એક સફળ વ્યવસાયીક બનવા માગે છે."
  },
  "shikhar is a sensitive and thoughtful boy from a village in madhya pradesh his father wants him to be an engineer, but he wants to craft his own career journey": {
    "en": "Shikhar is a sensitive and thoughtful boy from a village in Madhya Pradesh. His father wants him to be an engineer, but he wants to craft his own career journey.",
    "hi": "शिखर मध्य प्रदेश के एक गाँव का एक संवेदनशील और विचारशील लड़का है। उसके पिता चाहते हैं कि वह एक इंजीनियर बने, लेकिन वह अपना करियर खुद बनाना चाहता है।",
    "te": "శిఖర్ మధ్యప్రదేశ్‌లోని ఒక గ్రామానికి చెందిన సున్నితమైన మరియు ఆలోచనాత్మకమైన కుర్రాడు. అతని తండ్రి అతను ఇంజనీర్ కావాలని అనుకుంటున్నాడు, కానీ అతను తన కెరీర్ జర్నీని రూపొందించుకోవాలని అనుకుంటున్నాడు.",
    "ka": "ಶಿಖರ್ ಮಧ್ಯಪ್ರದೇಶದ ಹಳ್ಳಿಯೊಂದರ ಸೂಕ್ಷ್ಮ ಮತ್ತು ಚಿಂತನಶೀಲ ಹುಡುಗ. ಅವನ ತಂದೆ ಅವನು ಎಂಜಿನಿಯರ್ ಆಗಬೇಕೆಂದು ಬಯಸುತ್ತಾರೆ ಆದರೆ ಅವನು ತನ್ನ ವೃತ್ತಿಜೀವನದ ಪ್ರಯಾಣವನ್ನು ರೂಪಿಸಲು ಬಯಸುತ್ತಾನೆ.",
    "or": "ଶିଖର ମଧ୍ୟପ୍ରଦେଶର ଏକ ଗାଁର ଜଣେ ସମ୍ବେଦନଶୀଳ ଏବଂ ଚିନ୍ତିତ ପୁଅ| ତାଙ୍କ ପିତା ଚାହୁଁଛନ୍ତି ଯେ ସେ ଜଣେ ଇଞ୍ଜିନିୟର ହୁଅନ୍ତୁ, କିନ୍ତୁ ସେ ନିଜର କ୍ୟାରିଅର୍ ଯାତ୍ରା କରିବାକୁ ଚାହୁଁଛନ୍ତି |",
    "as": "শিখৰ মধ্য প্ৰদেশৰ এখন গাঁৱৰ এজন সংবেদনশীল আৰু চিন্তাশীল লৰা। তেওঁৰ দেউতাকে তেওঁক এজন অভিযন্তা হোৱাটো বিচাৰে, কিন্তু তেওঁ নিজৰ জীৱিকাৰ যাত্ৰা নিজে তৈয়াৰ কৰিব বিচাৰে।",
    "gu": "શિખર મધ્ય પ્રદેશના એક ગામના એક સંવેદનશીલ તથા વિચારશીલ છોકરો છે. તેના પિતા ઈચ્છે છે કે તે એક એન્જીનિયર બને, પણ તે પોતાની કરિયર જાતે જ બનાવવા ઈચ્છે છે."
  },
  "mira is a determined and hardworking girl from assam who likes to plan everything she wants to explore a career in science": {
    "en": "Mira is a determined and hardworking girl from Assam who likes to plan everything. She wants to explore a career in science.",
    "hi": "मीरा असम की एक दृढ़ निश्चयी और मेहनती लड़की है जो हर चीज की योजना बनाना पसंद करती है। वह विज्ञान के क्षेत्र में अपना करियर बनाना चाहती है।",
    "te": "మీరా అస్సాంకు చెందింది, ఆమె దృఢ నిశ్చయం కల మరియు కష్టపడి పనిచేసే అమ్మాయి మరియు ప్రతిదీ ప్లాన్ చేయడానికి ఇష్టపడుతుంది. ఆమె సైన్స్‌లో కెరీర్ సాధించాలనుకుంటుంది.",
    "ka": "ಮೀರಾ ಅಸ್ಸಾಂನ ದೃಢನಿಶ್ಚಯ ಮತ್ತು ಕಠಿಣ ಪರಿಶ್ರಮದ ಹುಡುಗಿ, ಅವಳು ಎಲ್ಲವನ್ನೂ ಯೋಜಿಸಲು ಇಷ್ಟಪಡುತ್ತಾಳೆ. ಅವಳು ವಿಜ್ಞಾನದಲ್ಲಿ ವೃತ್ತಿಯನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುತ್ತಾಳೆ.",
    "or": "ଆସାମର ମୀରା ଜଣେ ନିଷ୍ଠାପର ଏବଂ ପରିଶ୍ରମୀ ଝିଅ ଯିଏ କି ସବୁକିଛି ଯୋଜନା କରିବାକୁ ପସନ୍ଦ କରନ୍ତି | ସେ ବିଜ୍ଞାନରେ କ୍ୟାରିୟର ଅନ୍ୱେଷଣ କରିବାକୁ ଚାହୁଁଛନ୍ତି |",
    "as": "মীৰা অসমৰ এগৰাকী দৃঢ় প্ৰতিজ্ঞ আৰু পৰিশ্ৰমী ছোৱালী যি সকলোবোৰ পৰিকল্পনা নিজে কৰিবলৈ ভাল পায়। তাই বিজ্ঞানত জীৱিকাৰ অন্বেষণ কৰিব বিচাৰে।",
    "gu": "મીરા આસામની એક દ્રઢ નિશ્ચયી અને મહેનતું છોકરી છે,જે દરેક બાબતમાં યોજના તૈયાર કરવાનું પસંદ કરે છે. તે સાયન્સમાં કરિયર બનાવવા ઈચ્છે છે."
  },
  "revathy is observant and analytical she is from a small town in tamil nadu she likes to play kabaddi and wants to pursue a career in sports": {
    "en": "Revathy is observant and analytical. She is from a small town in Tamil Nadu. She likes to play Kabaddi and wants to pursue a career in sports.",
    "hi": "रेवैथी पर्यवेक्षक और विश्लेषणात्मक है। वह तमिलनाडु के एक छोटे से शहर से है। वह कबड्डी खेलना पसंद करती है और खेल में करियर को आगे बढ़ाना चाहती है।",
    "te": "రేవతి పరిశీలనాత్మక మరియు విశ్లేషణాత్మకమైనది. ఆమెది తమిళనాడులోని ఒక చిన్న పట్టణం. ఆమెకు కబడ్డీ ఆడడం అంటే ఇష్టం మరియు క్రీడల్లోనే కెరీర్‌ను కొనసాగించాలనుకుంటోంది.",
    "ka": "ರೇವತಿ ಗಮನಿಸುವ ಮತ್ತು ವಿಶ್ಲೇಷಣಾತ್ಮಕದವಳು. ಈಕೆ ತಮಿಳುನಾಡಿನ ಚಿಕ್ಕ ಊರಿನವಳು. ಅವಳು ಕಬಡ್ಡಿ ಆಡಲು ಇಷ್ಟಪಡುತ್ತಾಳೆ ಮತ್ತು ಕ್ರೀಡೆಯಲ್ಲಿ ವೃತ್ತಿಜೀವನವನ್ನು ಮುಂದುವರಿಸಲು ಬಯಸುತ್ತಾಳೆ.",
    "or": "ରେବତୀ ଜଣେ ପର୍ଯ୍ୟବେକ୍ଷକ ଏବଂ ବିଶ୍ଳେଷଣାତ୍ମକ ଅଟନ୍ତି | ସେ ତାମିଲନାଡୁର ଏକ ଛୋଟ ସହରରୁ ଆସିଛନ୍ତି | ସେ କବାଡି ଖେଳିବାକୁ ପସନ୍ଦ କରନ୍ତି ଏବଂ କ୍ରୀଡ଼ାରେ କ୍ୟାରିୟର କରିବାକୁ ଚାହାଁନ୍ତି |",
    "as": "ৰেৱথী পৰ্যবেক্ষণকাৰী আৰু বিশ্লেষণাত্মক। তাই তামিলনাডুৰ এখন সৰু চহৰৰ পৰা আহিছে। তাই কাবাডী খেলিবলৈ ভাল পায় আৰু ক্ৰীড়াত জীৱিকা গঢ়িব বিচাৰে।",
    "gu": "રેવતી નિરીક્ષક અને વિશ્લેષક છે. તે તમિલનાડુના એક નાના શહેરની રહેવાસી છે. તેણીનીને કબ્બડી રમવી પસંદ છે અને રમત ગમત ક્ષેત્રમાં પોતાની કરિયર બનાવવા ઈચ્છે છે."
  },
  "madhav is a friendly and sincere boy from uttar pradesh he is inspired by movie characters and wants to create positive change in society": {
    "en": "Madhav is a friendly and sincere boy from Uttar Pradesh. He is inspired by movie characters and wants to create positive change in society.",
    "hi": "माधव उत्तर प्रदेश का एक मिलनसार और ईमानदार लड़का है। वह फिल्म के पात्रों से प्रेरित है और समाज में सकारात्मक बदलाव लाना चाहता है।",
    "te": "మాధవ్ ఉత్తరప్రదేశ్‌కు చెందిన స్నేహపూర్వక మరియు నిజాయితీగల కుర్రాడు. అతను సినిమా పాత్రల నుండి ప్రేరణ పొందాడు మరియు సమాజంలో సానుకూల మార్పును సృష్టించాలనుకుంటున్నాడు.",
    "ka": "ಮಾಧವ್ ಉತ್ತರ ಪ್ರದೇಶದ ಸ್ನೇಹಪರ ಮತ್ತು ಪ್ರಾಮಾಣಿಕ ಹುಡುಗ. ಅವನು ಚಲನಚಿತ್ರ ಪಾತ್ರಗಳಿಂದ ಸ್ಫೂರ್ತಿ ಪಡೆದಿದ್ದಾನೆ ಮತ್ತು ಸಮಾಜದಲ್ಲಿ ಸಕಾರಾತ್ಮಕ ಬದಲಾವಣೆಯನ್ನು ಸೃಷ್ಟಿಸಲು ಬಯಸುತ್ತಾನೆ.",
    "or": "ମାଧବ ଉତ୍ତରପ୍ରଦେଶର ଜଣେ ବନ୍ଧୁପୂର୍ଣ୍ଣ ଏବଂ ସଚ୍ଚୋଟ ପୁଅ | ସେ ଚଳଚ୍ଚିତ୍ରର ଚରିତ୍ରମାନଙ୍କ ଦ୍ୱାରା ଅନୁପ୍ରାଣିତ ଏବଂ ସମାଜରେ ସକରାତ୍ମକ ପରିବର୍ତ୍ତନ ଆଣିବାକୁ ଚାହାଁନ୍ତି |",
    "as": "মাধৱ উত্তৰ প্ৰদেশৰ এজন বন্ধুত্বপূৰ্ণ আৰু আন্তৰিক লৰা। তেওঁ চলচ্চিত্ৰ চৰিত্ৰৰ দ্বাৰা অনুপ্ৰাণিত আৰু সমাজত ইতিবাচক পৰিৱৰ্তন সৃষ্টি কৰিব বিচাৰে।",
    "gu": "માધવ ઉત્તર પ્રદેશનો એક મિલનસાર અને ઈમાનદાર છોકરો છે. તે ફિલ્મના પાત્રોથી પ્રેરિત છે અને સમાજમાં સકારાત્મક પરિવર્તન લાવવા ઈચ્છે છે."
  },
  "asmita (born as arvind) is an ambitious transgender woman from a small town near hyderabad she wants to challenge gender stereotypes and build a successful career for herself": {
    "en": "Asmita (born as Arvind) is an ambitious transgender woman from a small town near Hyderabad. She wants to challenge gender stereotypes and build a successful career for herself.",
    "hi": "अस्मिता (अरविंद के रूप में पैदा हुए) हैदराबाद के पास एक छोटे से शहर से एक महत्वाकांक्षी ट्रांसजेंडर महिला है। वह लिंग रूढ़िवादों को चुनौती देना और खुद के लिए एक सफल कैरियर बनाना चाहती है।",
    "te": "అస్మిత (అరవింద్‌గా జన్మించారు) హైదరాబాద్ సమీపంలోని ఒక చిన్న పట్టణానికి చెందిన ఉన్నత స్థితిని కోరుకునే ట్రాన్స్జెండర్ మహిళ. ఆమె లింగపరమైన ఇబ్బందులను సవాలు చేయాలని మరియు తన కోసం విజయవంతమైన కెరీర్ ని నిర్మించుకోవాలనుకుంటోంది.",
    "ka": "ಅಸ್ಮಿತಾ (ಅರವಿಂದ್ ಎಂದು ಜನಿಸಿದನು) ಹೈದರಾಬಾದ್ ಸಮೀಪದ ಸಣ್ಣ ಪಟ್ಟಣದ ಮಹತ್ವಾಕಾಂಕ್ಷೆಯ ಟ್ರಾನ್ಸ್ಜೆಂಡರ್ ಮಹಿಳೆ. ಅವಳು ಲಿಂಗ ಸ್ಟೀರಿಯೊಟೈಪ್‌ಗಳನ್ನು ಸವಾಲು ಮಾಡಲು ಮತ್ತು ತನಗಾಗಿ ಯಶಸ್ವಿ ವೃತ್ತಿಜೀವನವನ್ನು ನಿರ್ಮಿಸಲು ಬಯಸುತ್ತಾಳೆ.",
    "or": "ଅସ୍ମିତା (ଅରବିନ୍ଦ ଭାବରେ ଜନ୍ମ) ହାଇଦ୍ରାବାଦ ନିକଟ ଏକ ଛୋଟ ସହରର ଜଣେ ଉଚ୍ଚାଭିଳାଷୀ ଟ୍ରାନ୍ସଜେଣ୍ଡର ମହିଳା | ସେ ଲିଙ୍ଗଗତ ଷ୍ଟେରିଓଟାଇପ୍କୁ ଚ୍ୟାଲେଞ୍ଜ କରିବାକୁ ଏବଂ ନିଜ ପାଇଁ ଏକ ସଫଳ କ୍ୟାରିଅର୍ ଗଠନ କରିବାକୁ ଚାହୁଁଛନ୍ତି",
    "as": "অস্মিতা (অৰবিন্দ হিচাপে জন্ম) হায়দৰাবাদৰ ওচৰৰ এখন সৰু চহৰৰ এগৰাকী উচ্চাকাংক্ষী ট্ৰেন্সজেণ্ডাৰ মহিলা। তাই লিংগ গতানুগতিকতাক প্ৰত্যাহ্বান জনাব বিচাৰে আৰু নিজৰ বাবে এটা সফল জীৱিকা গঢ়ি তুলিব বিচাৰে।",
    "gu": "અસ્મિતા (અરવિંદના સ્વરૂપમાં જન્મ લીધો છે) હૈદરાબાદ નજીક એક નાના શહેરનો એક મહત્વકાંક્ષી ટ્રાન્સજેન્ડર મહિલા છે. તે લૈગિંક પરંપરાને પડકારવા ઈચ્છે છે અને પોતાના માટે એક સફળ કરિયર બનાવવા ઈચ્છે છે."
  },
  "done": {
    "en": "Done",
    "hi": "पूर्ण",
    "te": "పూర్తయింది",
    "ka": "ಮುಗಿದಿದೆ",
    "or": "ଶେଷ ହୋଇଛି",
    "as": "সমাপ্ত হৈছে",
    "gu": "થઈ ગયું"
  },
  "menu": {
    "en": "Menu",
    "hi": "मेनू",
    "te": "మెను",
    "ka": "ಮೆನು",
    "or": "ମେନୁ",
    "as": "মেনু",
    "gu": "મેનુ"
  },
  "use this to access options like, character select, about the game and many more": {
    "en": "Use this to access options like, character select, about the game and many more",
    "hi": "चरित्र चयन, खेल के बारे में और कई अन्य विकल्पों तक पहुंचने के लिए इसका उपयोग करें!",
    "te": "క్యారెక్టర్ సెలెక్ట్, గేమ్ గురించి మరియు మరెన్నో ఎంపికలను యాక్సెస్ చేయడానికి దీన్ని ఉపయోగించండి!",
    "ka": "ಅಕ್ಷರ ಆಯ್ಕೆ, ಆಟದ ಬಗ್ಗೆ ಮತ್ತು ಇನ್ನೂ ಹೆಚ್ಚಿನ ಆಯ್ಕೆಗಳನ್ನು ಪ್ರವೇಶಿಸಲು ಇದನ್ನು ಬಳಸಿ!",
    "or": "ଏହି ପ୍ରବେଶ ବିକଳ୍ପଗୁଡିକୁ ଯେପରିକି: ଖେଳ ବିଷୟରେ, ଚରିତ୍ର ଚୟନରେ ଏବଂ ଅଧିକ ଜାଣିବାରେ ବ୍ୟବହାର କରନ୍ତୁ",
    "as": "ইয়াক খেলৰ বিষয়ে, চৰিত্ৰ বাছনি, আৰু বহুতো বিকল্প আদিত ব্যৱহাৰ কৰিবলৈ ব্যৱহাৰ কৰা!",
    "gu": "પાત્રોની પસંદગી, રમત અંગે તથા અન્ય ઘણા વિકલ્પો સુધી પહોંચવા માટે તેનો ઉપયોગ કરો."
  },
  "use this to see the various experience cards you collect throughout the game": {
    "en": "Use this to see the various experience cards you collect throughout the game",
    "hi": "पूरे गेम में आपके द्वारा एकत्र किए गए विभिन्न अनुभव कार्ड देखने के लिए इसका उपयोग करें",
    "te": "గేమ్ అంతటా మీరు సేకరించే వివిధ అనుభవ కార్డ్‌లను చూడటానికి దీన్ని ఉపయోగించండి",
    "ka": "ಆಟದ ಉದ್ದಕ್ಕೂ ನೀವು ಸಂಗ್ರಹಿಸುವ ವಿವಿಧ ಅನುಭವ ಕಾರ್ಡ್‌ಗಳನ್ನು ನೋಡಲು ಇದನ್ನು ಬಳಸಿ",
    "or": "ଆପଣ ସମଗ୍ର ଖେଳରେ ସଂଗ୍ରହ କରୁଥିବା ବିଭିନ୍ନ ଅନୁଭବ କାର୍ଡ ଦେଖିବାକୁ ଏହାକୁ ବ୍ୟବହାର କରିପାରିବେ",
    "as": "সমগ্ৰ খেলত তুমি সংগ্ৰহ কৰা বিভিন্ন অভিজ্ঞতা কাৰ্ড চাবলৈ ইয়াক ব্যৱহাৰ কৰা",
    "gu": "સંપૂર્ણ ગેમમાં તમારા દ્વારા એકત્રિત કરવામાં આવેલ વિવિધ અનુભવ કાર્ડ જોવા માટે તેનો ઉપયોગ કરો"
  },
  "use this to see the career points you have collected in a story": {
    "en": "Use this to see the career points you have collected in a story",
    "hi": "एक कहानी में आपके द्वारा एकत्र किए गए करियर पॉइंट देखने के लिए इसका उपयोग करें",
    "te": "మీరు కథలో పొందిన కెరీర్ పాయింట్‌లను చూడటానికి దీన్ని ఉపయోగించండి",
    "ka": "ಕಥೆಯಲ್ಲಿ ನೀವು ಸಂಗ್ರಹಿಸಿದ ವೃತ್ತಿಜೀವನದ ಅಂಶಗಳನ್ನು ನೋಡಲು ಇದನ್ನು ಬಳಸಿ",
    "or": "ଆପଣ ଏକ କାହାଣୀରେ ସଂଗ୍ରହ କରିଥିବା କ୍ୟାରିୟର ପଏଣ୍ଟ ଦେଖିବାକୁ ଏହାକୁ ବ୍ୟବହାର କରିପାରିବେ",
    "as": "কাহিনী এটাত তুমি সংগ্ৰহ কৰা জীৱিকাৰ পইণ্টবোৰ চাবলৈ ইয়াক ব্যৱহাৰ কৰা",
    "gu": "એક કહાનીમાં તમારા દ્વારા એકત્રિત કરવામાં આવેલ કરિયર મુદ્દાને જોવા માટે તેનો ઉપયોગ કરો."
  },
  "use this to see the progress you’ve made in a story": {
    "en": "Use this to see the progress you’ve made in a story",
    "hi": "एक कहानी में आपने जो प्रगति की है, उसे देखने के लिए इसका इस्तेमाल करें",
    "te": "కథలో మీరు సాధించిన పురోగతిని చూడటానికి దీన్ని ఉపయోగించండి",
    "ka": "ಕಥೆಯಲ್ಲಿ ನೀವು ಮಾಡಿರುವ ಪ್ರಗತಿಯನ್ನು ನೋಡಲು ಇದನ್ನು ಬಳಸಿ",
    "or": "ଏକ କାହାଣୀରେ ଆଗକୁ ଦେଖିବା ପାଇଁ ଆପଣ ଏହାକୁ ବ୍ୟବହାର କରନ୍ତୁ",
    "as": "কাহিনী এটাত তুমি কৰা প্ৰগতি চাবলৈ ইয়াক ব্যৱহাৰ কৰা",
    "gu": "વાર્તામાં તમે કરેલી પ્રગતિને જોવા માટે આનો ઉપયોગ કરો"
  },
  "change characters using these arrows": {
    "en": "Change Characters using these arrows",
    "hi": "इन दिशा कुंजियों का उपयोग करके वर्ण बदलें",
    "te": "ఈ యారోలను ఉపయోగించి పాత్రలను మార్చండి",
    "ka": "ಈ ಬಾಣಗಳನ್ನು ಬಳಸಿಕೊಂಡು ಅಕ್ಷರಗಳನ್ನು ಬದಲಾಯಿಸಿ",
    "or": "ଏହି ଆରୋକୁ ବ୍ୟବହାର କରି ଚରିତ୍ର ପରିବର୍ତ୍ତନ କରନ୍ତୁ",
    "as": "এই কাড়বোৰ ব্যৱহাৰ কৰি চৰিত্ৰসমূহ সলনি কৰা",
    "gu": "આ નિશાનોનો ઉપયોગ કરીને પાત્રોને બદલો"
  },
  "explore the journey and decisions you’ve made through this map": {
    "en": "Explore the journey and decisions you’ve made through this map.",
    "hi": "इस नक्शे के माध्यम से आपके द्वारा किए गए यात्रा और निर्णयों का अन्वेषण करें।",
    "te": "ఈ మ్యాప్ ద్వారా మీరు చేసిన ప్రయాణం మరియు నిర్ణయాలను చూసుకోండి.",
    "ka": "ಈ ನಕ್ಷೆಯ ಮೂಲಕ ನೀವು ಮಾಡಿದ ಪ್ರಯಾಣ ಮತ್ತು ನಿರ್ಧಾರಗಳನ್ನು ಅನ್ವೇಷಿಸಿ.",
    "or": "ଏହି ମ୍ୟାପ୍‌ ମାଧ୍ୟମରେ ଆପଣ କରିଥିବା ଯାତ୍ରା ଏବଂ ନିଷ୍ପତ୍ତିଗୁଡିକ ଅନୁସନ୍ଧାନ କରନ୍ତୁ",
    "as": "এই মানচিত্ৰৰ জৰিয়তে তুমি লোৱা যাত্ৰা আৰু সিদ্ধান্তবোৰ অন্বেষণ কৰা।",
    "gu": "આ નકશાના માધ્યમથી તમારા દ્વારા કરવામાં આવેલી યાત્રા અને નિર્ણયોને શોધો"
  },
  "swipe left and right to explore the map": {
    "en": "Swipe left and right to explore the map",
    "hi": "नक़्शे का पता लगाने के लिए बाएं और दाएं स्वाइप करें",
    "te": "మ్యాప్‌ను అన్వేషించడానికి ఎడమ మరియు కుడి వైపుకు స్వైప్ చేయండి",
    "ka": "ನಕ್ಷೆಯನ್ನು ಅನ್ವೇಷಿಸಲು ಎಡ ಮತ್ತು ಬಲಕ್ಕೆ ಸ್ವೈಪ್ ಮಾಡಿ",
    "or": "ମ୍ୟାପ୍‌କୁ ଅନୁସନ୍ଧାନ କରିବା ପାଇଁ ବାମ ଏବଂ ଡାହାଣକୁ ସ୍ୱାଇପ୍ କରନ୍ତୁ",
    "as": "মানচিত্ৰ অন্বেষণ কৰিবলৈ বাওঁ আৰু সোঁফালে চোৱাইপ কৰা",
    "gu": "નકશો શોધવા માટે ડાબા તથા જમણી બાજુ સ્વીપ કરો"
  },
  "career game": {
    "en": "Career Game",
    "hi": "करियर खेल",
    "te": "కెరీర్ గేమ్",
    "ka": "ವೃತ್ತಿ ಆಟ",
    "or": "କ୍ୟାରିୟର ଖେଳ",
    "as": "জীৱিকাৰ খেল",
    "gu": "કરિયર ગેમ"
  },
  "unlocked decision point": {
    "en": "Unlocked decision point",
    "hi": "अनलॉक्ड निर्णय बिंदु",
    "te": "అన్‌లాక్ చేయబడిన డెసిషన్ పాయింట్",
    "ka": "ತೆರೆದುಕೊಳ್ಳಲಾದ ನಿರ್ಧಾರದ ಪಾಯಿಂಟ್",
    "or": "ଅନ୍‌ଲକ୍‌ ନିର୍ଣ୍ଣୟ ବିନ୍ଦୁ",
    "as": "আনলক কৰা সিদ্ধান্ত বিন্দু",
    "gu": "અનલોક કરેલ નિર્ણય પોઇન્ટ"
  },
  "marks a decision that has already been made": {
    "en": "Marks a decision that has already been made",
    "hi": "पहले से किए गए निर्णय को चिह्नित करता है",
    "te": "ఇప్పటికే తీసుకున్న నిర్ణయాన్ని సూచిస్తుంది",
    "ka": "ಈಗಾಗಲೇ ತೆಗೆದುಕೊಂಡ ನಿರ್ಧಾರವನ್ನು ಗುರುತಿಸುತ್ತದೆ",
    "or": "ଏକ ନିର୍ଣ୍ଣୟ ଚିହ୍ନିତ କରନ୍ତୁ ଯାହା ପୂର୍ବରୁ ହୋଇସାରିଛି",
    "as": "ইতিমধ্যে লোৱা এটা সিদ্ধান্ত চিহ্নিত কৰা",
    "gu": "જે અગાઉથી જ ઉપલબ્ધ છે તે નિર્ણયને નિશાન કરો"
  },
  "current decision point": {
    "en": "Current Decision Point",
    "hi": "वर्तमान निर्णय पॉइंट",
    "te": "ప్రస్తుత డెసిషన్ పాయింట్",
    "ka": "ಪ್ರಸ್ತುತ ನಿರ್ಧಾರದ ಅಂಶ",
    "or": "ବର୍ତ୍ତମାନ ନିର୍ଣ୍ଣୟ ପଏଣ୍ଟ",
    "as": "বৰ্তমান সিদ্ধান্ত পইণ্ট",
    "gu": "વર્તમાન નિર્ણય પોઇન્ટ"
  },
  "marks the most recent decision made": {
    "en": "Marks the most recent decision made",
    "hi": "सबसे हालिया निर्णय को चिह्नित करता है",
    "te": "ఇటీవల తీసుకున్న నిర్ణయాన్ని సూచిస్తుంది",
    "ka": "ತೀರಾ ಇತ್ತೀಚಿನ ನಿರ್ಧಾರವನ್ನು ಗುರುತಿಸುತ್ತದೆ",
    "or": "ବର୍ତ୍ତମାନ ନିର୍ଣ୍ଣୟକୁ ଚିହ୍ନିତ କରନ୍ତୁ",
    "as": "আটাইতকৈ শেহতীয়াকৈ লোৱা সিদ্ধান্তটো চিহ্নিত কৰা",
    "gu": "સૌથી તાજેતરમાં નિર્ણય કરવામાં આવેલ હોય તેને નિશાન કરો"
  },
  "tap to close the map": {
    "en": "Tap to close the map",
    "hi": "नक्शा बंद करने के लिए टैप करें",
    "te": "మ్యాప్‌ను మూసివేయడానికి నొక్కండి",
    "ka": "ನಕ್ಷೆಯನ್ನು ಮುಚ್ಚಲು ಟ್ಯಾಪ್ ಮಾಡಿ",
    "or": "ମ୍ୟାପ୍‌ ବନ୍ଦ କରିବାକୁ ଟ୍ୟାପ୍ କରନ୍ତୁ",
    "as": "মানচিত্ৰখন বন্ধ কৰিবলৈ টেপ কৰা",
    "gu": "નકશો બંધ કરવા ટેપ કરો"
  },
  "once you complete one journey of the story, your decision points will get unlocked click on the unlocked decision point to go back to a decision in the story": {
    "en": "Once you complete one journey of the story, your decision points will get unlocked. Click on the unlocked decision point to go back to a decision in the story.",
    "hi": "एक बार जब आप कहानी की यात्रा पूरी कर लेते हैं, तो आपके निर्णय बिंदु अनलॉक हो जाएंगे। कहानी में किसी निर्णय पर वापस जाने के लिए अनलॉक किए गए निर्णय पर क्लिक करें।",
    "te": "కథ యొక్క ఒక ప్రయాణాన్ని పూర్తి చేసిన తర్వాత, మీ నిర్ణయ పాయింట్లు అన్‌లాక్ చేయబడతాయి. కథలోని నిర్ణయానికి తిరిగి వెళ్లడానికి అన్‌లాక్ చేయబడిన డెసిషన్ పాయింట్‌పై క్లిక్ చేయండి.",
    "ka": "ಒಮ್ಮೆ ನೀವು ಕಥೆಯಲ್ಲಿನ ಒಂದು ಪ್ರಯಾಣವನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದಲ್ಲಿ, ನಿಮ್ಮ ನಿರ್ಧಾರದ ಅಂಶಗಳು ತೆರೆದುಕೊಳ್ಳುತ್ತವೆ. ಮತ್ತೊಮ್ಮೆ ಕಥೆಯಲ್ಲಿನ ನಿರ್ಧಾರಕ್ಕೆ ಹಿಂತಿರುಗಲು ತೆರೆದುಕೊಳ್ಳಲಾದ ನಿರ್ಧಾರದ ಪಾಯಿಂಟ್ ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿ.",
    "or": "ଯେବେ ଥରେ ଆପଣ କାହାଣୀର ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରନ୍ତି, ତେବେ ଆପଣଙ୍କ ନିର୍ଣ୍ଣୟ ବିନ୍ଦୁଗୁଡିକ ଅନ୍‌ଲକ୍‌ ହୋଇଯିବ | କାହାଣୀରେ କୌଣସି ନିର୍ଣ୍ଣୟକୁ ଫେରିବା ପାଇଁ ଅନ୍‍ଲକ୍‌ ହୋଇଥିବା ନିର୍ଣ୍ଣୟ ବିନ୍ଦୁ ଉପରେ କ୍ଲିକ୍‌ କରନ୍ତୁ |",
    "as": "আপুনি কাহিনীটোৰ এটা যাত্ৰা সম্পূৰ্ণ কৰাৰ পিছত, আপোনাৰ সিদ্ধান্ত ৰখা বোৰ আনলক হ'ব। কাহিনীটোৰ এটা সিদ্ধান্তলৈ ঘূৰি যাবলৈ আনলক কৰা সিদ্ধান্ত বিন্দুত ক্লিক কৰক।",
    "gu": "એકવાર તમે વાર્તાની એક સફર પૂર્ણ કરી લો, પછી તમારા નિર્ણયના મુદ્દાઓ અનલોક થઈ જશે. વાર્તામાં નિર્ણય પર પાછા જવા માટે અનલોક કરેલા નિર્ણય પોઇન્ટ પર ક્લિક કરો."
  },
  "locked decision point": {
    "en": "Locked decision point",
    "hi": "लॉक्ड निर्णय बिंदु",
    "te": "లాక్ చేయబడిన డెసిషన్ పాయింట్",
    "ka": "ತೆರೆದುಕೊಳ್ಳಲಾಗದ ನಿರ್ಧಾರದ ಪಾಯಿಂಟ್",
    "or": "ଲକ୍‌ ନିର୍ଣ୍ଣୟ ବିନ୍ଦୁ",
    "as": "লক কৰা সিদ্ধান্ত বিন্দু",
    "gu": "લોક કરેલ નિર્ણય પોઇન્ટ"
  },
  "a project by": {
    "en": "A Project By",
    "hi": "जिसने यह प्रोजेक्ट किया है",
    "te": "ఈ ప్రాజెక్ట్ చేసిన వ్యక్తి",
    "ka": "ಈ ಯೋಜನೆಯನ್ನು ರೂಪಿಸಿದ ವ್ಯಕ್ತಿ",
    "or": "ଯିଏ ଏହି ପ୍ରୋଜେକ୍ଟ କରିଛନ୍ତି |",
    "as": "যিজনে এই প্ৰকল্পটো কৰিছে",
    "gu": "જેણે આ પ્રોજેક્ટ કર્યો છે"
  },
  "funded by": {
    "en": "Funded By",
    "hi": "जिसने प्रायोजित किया है",
    "te": "స్పాన్సర్ చేసిన వ్యక్తి",
    "ka": "ಅದನ್ನು ಪ್ರಾಯೋಜಿಸಿದ ವ್ಯಕ್ತಿ",
    "or": "ଯିଏ ପ୍ରାୟୋଜିତ କରିଛନ୍ତି",
    "as": "যিজনে পৃষ্ঠপোষকতা কৰিছে",
    "gu": "જેણે સ્પોન્સર કર્યું છે"
  },
  "designed & developed by": {
    "en": "Designed & Developed By",
    "hi": "जिसने डिजाइन और विकसित किया है",
    "te": "రూపకల్పన చేసి అభివృద్ధి చేసిన వ్యక్తి",
    "ka": "ವಿನ್ಯಾಸಗೊಳಿಸಿದ ಮತ್ತು ಅಭಿವೃದ್ಧಿಪಡಿಸಿದ ಒಬ್ಬ",
    "or": "ଯିଏ ଡିଜାଇନ୍ ଏବଂ ବିକଶିତ କରିଛି",
    "as": "যিয়ে ডিজাইন আৰু বিকাশ কৰিছে",
    "gu": "જેણે ડિઝાઇન અને વિકાસ કર્યો છે"
  },
  "sound design by": {
    "en": "Sound Design By",
    "hi": "जिसने ध्वनि तैयार की है",
    "te": "ధ్వనిని రూపొందించిన వ్యక్తి",
    "ka": "ಧ್ವನಿಯನ್ನು ವಿನ್ಯಾಸಗೊಳಿಸಿದವನು",
    "or": "ଯିଏ ଶବ୍ଦର ପରିକଳ୍ପନା କରିଛନ୍ତି",
    "as": "যিজনে শব্দৰ ডিজাইন কৰিছে",
    "gu": "જેણે અવાજની રચના કરી છે"
  },
  "career": {
    "en": "Career",
    "hi": "कैरियर",
    "te": "కెరీర్",
    "ka": "ಕೆರಿಯರ್",
    "or": "କ୍ୟାରିଅର୍",
    "as": "কেৰিয়াৰ",
    "gu": "કરિયાર"
  },
  "quest": {
    "en": "Quest",
    "hi": "क्वेस्ट",
    "te": "క్వెస్ట్",
    "ka": "ಕ್ವೆಸ್ಟ್",
    "or": "କ୍ୱେଷ୍ଟ୍",
    "as": "কোয়েষ্ট",
    "gu": "કોએસ્ટ"
  },
  "look at the career points when you take the decision": {
    "en": "Look at the career points when you take the decision",
    "hi": "निर्णय लेते समय करियर के पहलुओं पर विचार करें",
    "te": "నిర్ణయం తీసుకునేటప్పుడు కెరీర్ అంశాలను పరిగణించండి",
    "ka": "ನಿರ್ಧಾರ ತೆಗೆದುಕೊಳ್ಳುವಾಗ ವೃತ್ತಿಯ ಅಂಶಗಳನ್ನು ಪರಿಗಣಿಸಿ",
    "or": "ନିଷ୍ପତ୍ତି ନେବାବେଳେ କ୍ୟାରିୟର ଦିଗଗୁଡିକ ବିଷୟରେ ବିଚାର କରନ୍ତୁ",
    "as": "সিদ্ধান্ত লোৱাৰ সময়ত কেৰিয়াৰৰ পইণ্টবোৰ বিবেচনা কৰক",
    "gu": "નિર્ણય લેતી વખતે કારકિર્દીના મુદ્દાઓ ધ્યાનમાં લો"
  },
  "watch the tutorial video to know how the game works:": {
    "en": "Watch the tutorial video to know how the game works:",
    "hi": "गेम कैसे खेलें, यह जानने के लिए ट्यूटोरियल वीडियो देखें:",
    "te": "గేమ్ ఎలా ఆడాలో తెలుసుకోవడానికి ట్యుటోరియల్ వీడియోని చూడండి:",
    "ka": "ಆಟವನ್ನು ಹೇಗೆ ಆಡಬೇಕೆಂದು ತಿಳಿಯಲು ಟ್ಯುಟೋರಿಯಲ್ ವೀಡಿಯೊವನ್ನು ವೀಕ್ಷಿಸಿ:",
    "or": "ଖେଳ କିପରି ଖେଳାଯିବ ଜାଣିବା ପାଇଁ ଟ୍ୟୁଟୋରିଆଲ୍ ଭିଡିଓ ଦେଖନ୍ତୁ:",
    "as": "গেমটো কেনেদৰে খেলিব লাগে জানিবলৈ টিউটোৰিয়েল ভিডিঅ'টো চাওক:",
    "gu": "Gēma kēvī rītē ramavī tē jāṇavā māṭē ṭyuṭōrīyala viḍi'ō ju'ō:"
  },
  "new email": {
    "en": "New Email",
    "hi": "नई ईमेल",
    "te": "కొత్త ఇమెయిల్",
    "ka": "ಹೊಸ ಇಮೇಲ್",
    "or": "ନୂତନ ଇମେଲ୍",
    "as": "নতুন ইমেইল",
    "gu": "નવો ઈમેઈલ"
  }
}

