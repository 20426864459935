import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useReducer,
  useRef
} from "react";
import { useLocation } from "react-router";
import { Player, ToneAudioBuffer } from "tone";
import * as musicAssets from "../../assets/sound/music";
import * as ambienceAssets from "../../assets/sound/ambience";
import * as soundEffectsAssets from "../../assets/sound/soundEffects";
import UISoundConfig from "../../data/UISoundConfig";
import { initPlayers, initSourcesRef, musicPlayersConfig as musicPlayersConfigDef  } from "./config";
import { extractAndPopulateRest, handleApplyMute, handleFadeVolumeChange } from "./functions";

export const SoundContext = React.createContext();

export const SoundContextProvider = ({ children }) => {
  //   const [url, setSource] = useState(undefined);
  const location = useLocation();
  const musicPlayersConfig = useRef(musicPlayersConfigDef);
  const [hardMute, setHardMute] = useState(false);

  const sources = useRef(initSourcesRef())

  const players = useMemo(initPlayers,[]);

  /**
   * this function helps us tweak multiple player controls
   * that are provided by tone js.
   * ( have been defined in initialControls )
   */
  const handleControlsChange = (playerName, controls = {}) => {
    let thisPlayer = players[playerName]; // === 'music' ? musicPlayer : ambiencePlayer
    
    Object.keys(controls).forEach(key => {
      let value = controls[key];
      
      switch(key){
        // case "fadeIn":
        // thisPlayer.fadeIn = value
        case "fadeOut":
        thisPlayer.fadeOut = value
        return;

        case "mute":
        hardMute === false && handleApplyMute(playerName, thisPlayer, value, musicPlayersConfig.current)
        return;

        case "hardMute":
        thisPlayer.mute = value;
        console.log('SETTING HARD MUTE', playerName, controls)
        setHardMute(value);
        return;

        case "instantMute":
          console.log('setting INSTANT MUTE', value)
        thisPlayer.mute = value;
        return;

        case "volume":  
        thisPlayer.set({volume: value})
        // thisPlayer.volume.value = value
        // handleFadeVolumeChange(
        //   playerName,
        //   thisPlayer,
        //   musicPlayersConfig.current,
        //   value === 1 ? -15 : 1,
        //   value
        // )
        musicPlayersConfig.current.find(d => d.id === playerName).controls.volume = value
        return;
 
      }
    })
    
  };

  const handleAllControlsChange = (playersGroup, controls = {}) => {
    if(playersGroup === 'music'){
      musicPlayersConfig.current.forEach(m => {
        handleControlsChange(m.id, controls)
        console.log(
          'AFTER MUTE ATTEMPT',
          {
            muteVal : players[m.id].mute,
            name: m.id
          }
        )
      })
    }
  }

  const handleSrcStop = (playerName, controls={}) => {
    let thisPlayer = players[playerName];
    /**
     * stop the player if it is currently 
     * playing something.
     */
    console.log('TO STOP', {playerName, state: thisPlayer.state})
    // if(thisPlayer.state === 'started'){
      console.log('stopping', playerName, controls ,hardMute)

    

      thisPlayer.stop(`+${controls?.delayStop || 0}`)
    // } 
    
  }

  const handleSrcStart = (playerName, source, controls = {}) => {


    
    let thisPlayer = players[playerName]; // === 'music' ? musicPlayer : ambiencePlayer
    let prevSource = sources.current[playerName];
    let newSource = playerName === 'ambience' 
      ? ambienceAssets[source] 
      : playerName === 'soundEffects'
        ? soundEffectsAssets[source]
        : musicAssets[source];
    
    /**
     * if prev source and new source are the same do nothing,
     * let the prev source just continue playing
     */
    console.log({prevSource, newSource})
    
    if(prevSource === newSource){
      
      if(thisPlayer.state === 'stopped'){
        console.log('SAME SOURCE STARTING STOPPED PLAYER', playerName)
        thisPlayer.start(`+${controls?.delayStart || 0}`);
        hardMute === true && handleControlsChange(playerName, {instantMute: true})
      }
      if(thisPlayer.mute === true && hardMute === false){
        console.log({hardMute : hardMute, delayStart: controls.delayStart})
        let interval = controls?.delayStart ? controls?.delayStart * 1000 :  0;
        console.log(`--------------------${interval}-------------`)
        setTimeout(() => {
          console.log('----------------THIS RAN ----------------')
          return handleControlsChange(playerName, { mute: false })
        }, interval)
      }
    }
    
    else if(prevSource !== newSource){
      console.log('NEW SOURCE STARTING PLAYER', playerName)
      const prevPathname = location.pathname;
      
      new ToneAudioBuffer(newSource, (buffer) => {
        
        const nowPathname = location.pathname
        console.log({prev: prevPathname+'-'+playerName, now : nowPathname+'-'+playerName });
        if(prevPathname === nowPathname){
          thisPlayer.buffer = buffer;
          console.log({MUTESTATE: thisPlayer.mute, playerName})
          thisPlayer.start(`+${controls?.delayStart || 0}`);
          hardMute === true && handleControlsChange(playerName, {instantMute: true})
        }

        //set prevSource to the newSource
      sources.current[playerName] = newSource;

      });
      
    }

    

  }


  const handleSoundChange = (playerName, source, controls = {}) => {

    /**
     * modify player controls ( if they have been defined )
     */
     handleControlsChange(playerName, controls )
    
     //if a source is not defined at all, then stop this function 
     //after handling controls change.
     if(!source) return;

    //if source is set to 'stop', stop it ( if its currently playing )
    if(source === 'stop'){
      handleSrcStop(playerName, controls);
    }else{
      handleSrcStart(playerName, source, controls)
    }
    
    
    
  };

  const triggerSoundEffect = source => {
    handleSoundChange('soundEffects', source)
  }
  
  const useControlStoryAmbience = (pageSound, sceneIdpageId) => {
    useEffect(() => {
      let ambience = pageSound?.ambience;

      /**
       * if no source is defined, do nothing.
       * meaning whatever was playing earlier, will continue playing
       */
      
      // ambience
      ambience?.filename && handleSoundChange('ambience', ambience.filename, ambience.controls)
      if(ambience?.filename){
        hardMute === false && musicPlayersConfig.current.forEach(player => {
          if(['story', 'choice'].indexOf(player.id) !== -1){
            handleSoundChange(player.id, undefined, { volume : ambience.filename === 'stop' ? 1 : -15 })
            // handleSoundChange(player.id, undefined, { fadeVolume : ambience.filename === 'stop' ? {fromVol: -15, toVol: 1} : {fromVol: 1, toVol: -15} })
          }
        })
      }
      
      

    },[sceneIdpageId])
  }

  const controlUISounds = Comp => {
    if(!UISoundConfig[Comp]) return;

    let {music, ambience} = UISoundConfig[Comp];

      let musicConfigToLoop = extractAndPopulateRest(music, musicPlayersConfig.current);

      
      
      // music
      musicConfigToLoop.forEach(m => {
        console.log({
          muteValue: players[m.playerName].mute,
          playerName: m.playerName,
          source: m.source,
          controls: m.controls
        })
        handleSoundChange(m.playerName, m.source, m.controls)
      })

      //extract startDelays from the musicConfigLoop,
      //cuz we need to apply those on the music volume change triggered by ambience change
      let startDelays = musicConfigToLoop.map(m => {
          return m.controls?.delayStart
        }).filter(Boolean)
      
      
      
      // ambience --> this is always a stop action
      ambience?.source && handleSoundChange('ambience', ambience.source, ambience.controls)
      if(ambience?.source){
        hardMute === false && musicPlayersConfig.current.forEach(player => {
          console.log('ambience messing with music volume')
          handleSoundChange(player.id, undefined, { mute : ambience.source === 'stop' ? false : true })
        })
        // setTimeout(() => {
        //   hardMute === false && musicPlayersConfig.current.forEach(player => {
        //     console.log('ambience messing with music volume')
        //     handleSoundChange(player.id, undefined, { volume : ambience.source === 'stop' ? 1 : -15 })
        //   })  
        // }, startDelays.length > 0 ? Math.max(...startDelays) * 1000 : 0)
      }
  }

  const useControlUISounds = (Comp, options = {}) => {
    useEffect(() => {
      let condition = options.condition !== undefined ? options.condition : true;
      if(condition){
        controlUISounds(Comp)
      }
    },options.condition !== undefined ? [options.condition] : [])
  }



  return (
    <SoundContext.Provider
      value={{ 
        players, 
        handleControlsChange, 
        handleSoundChange, 
        handleSrcStop,
        useControlUISounds,
        useControlStoryAmbience,
        handleAllControlsChange,
        controlUISounds,
        triggerSoundEffect,
        hardMute : hardMute
      }}
    >
      {children}
    </SoundContext.Provider>
  );
};



  {/* <button
        onClick={() =>
          handleControlsChange("music", "mute", !players.music.get().mute)
        }
      >{`Mute Music`}</button>
      <button
        onClick={() =>
          handleControlsChange("ambience", "mute", !players.ambience.get().mute)
        }
      >{`Mute Ambience`}</button>
      <button
        onClick={() => handleSoundChange("music", 'themeMain')}
      >{`Start Music`}</button>
      <button
        onClick={() => handleSoundChange("ambience", 'themeVar1')}
      >{`Start Ambience`}</button> */}
