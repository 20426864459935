import styled, { css } from "styled-components/macro";

export const StyledProgressMapContainer = styled.div`
position: relative;
    background-color: ${({theme}) => theme.colors.lightYellow};
    
`

export const StyledProgressMapCharImgWrapper = styled.div`
    position: absolute;
    bottom: 0;
    ${({ theme }) => theme.transition(["left" ])}
    left: ${({hideGraphics}) => hideGraphics ? '-5rem' : '-3rem'};
    width: 26rem;

    img{
        width: 100%;
        height: auto;
    }
`

export const StyledProgressMapCharNameWrapper = styled.div`
position: absolute;
top: 4rem;
${({ theme }) => theme.transition(["left" ])}
left: ${({hideGraphics}) => hideGraphics ? 0 : '2rem'};
text-transform: uppercase;
font-size: 16px;
`

export const StyledProgressMapGraphics = styled.div`
    ${({ theme }) => theme.transition(["opacity" ])}
    opacity : ${({hideGraphics}) => hideGraphics ? 0 : 1};
    
    
`