import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { Link } from "react-router-dom";
import gsap, { Power3, Elastic } from "gsap";

import { icons } from "../../importGroups/icons";
// import IndicatorsContext from "../../contexts/IndicatorsContext";
import "./_style.scss";
import {
  StyledButton,
  StyledButtonLink,
  StyledIconButton,
  StyledIconButtonLink,
  StyledNavIconLink,
  StyledNavIconButton,
} from "./style";
import {
  white,
  darkGrey,
  black,
  yellow,
  green,
} from "../../sass/vars.module.scss";
import { GameItemsContext } from "../../contexts/GameItemsContext";
import { setToLink } from "../../utils/general";
import { theme } from "../../Theme";
import { CirclesAnimation, AlertAnimation } from "../Animations";
import { SoundContext } from "../../contexts/SoundContext";
import { __LOC } from "../../utils/locale";
const {
  colors: { ORANGE, YELLOW2, YELLOW },
} = theme;

export const Button = ({
  display,
  children,
  onClick,
  style,
  active, // neutral | success | danger
  orientation,
  height,
  disabled,
  styled,
  selectable,
  secondary,
}) => (
  <StyledButton
    selectable={selectable}
    disabled={disabled}
    onClick={onClick}
    styled={styled}
    style={style}
    active={active}
    orientation={orientation}
    height={height}
    secondary={secondary}
  >
    {display || children}
  </StyledButton>
);

export const ButtonLink = ({ to, display, children, styled, style, secondary }) => (
  <StyledButtonLink to={to} styled={styled} style={style} secondary={secondary}>
    <span>{display || children}</span>
  </StyledButtonLink>
);

export const IconButton = ({
  onClick,
  icon,
  disabled,
  iconSize,
  iconColor,
  nav,
  hamburger,
  to,
  style,
  small,
  active,
  forceActive,
  activeCoach
}) => {
  const Icon = icons[icon];
  
  const Comp = to ? StyledIconButtonLink : StyledIconButton;

  return (
    <Comp
      forceActive={forceActive}
      active={active}
      nav={nav}
      hamburger={hamburger}
      small={small}
      onClick={!disabled ? onClick : undefined}
      to={to ? setToLink(to) : undefined}
      disabled={disabled}
      style={{zIndex: activeCoach === 'mapButton' ? 100 : 'unset'}}
    >
      <Icon
        color={disabled
          ? theme.colors.MID_GREY
          : (iconColor || theme.colors.BLACK) }
        size={iconSize || (small ? 14 : 20)}
        weight="duotone"
      />
    </Comp>
  );
};

export const InventoryButton = (props) => {
  let { inventory } = useContext(GameItemsContext);
  const {triggerSoundEffect} = useContext(SoundContext)
  let [triggerCircles, setTriggerCircles] = useState(-1);
  let unlockedCardsLength = inventory.filter((d) => d.unlocked === true).length;

  useEffect(() => {
    setTriggerCircles((p) => p + 1);
  }, [unlockedCardsLength]);

  const [alert, setAlert ] = useState('')

  const localizedCardAddedText = __LOC('New Card Added!')

  useEffect(() => {
    if(triggerCircles > 0){
      triggerSoundEffect('greenCircles')
      setAlert(localizedCardAddedText)
    }
  },[triggerCircles])

  return (
    <div style={{ position: "relative" }}>
      <CirclesAnimation
        size={500}
        trigger={triggerCircles > 0 && triggerCircles}
        circlesData={[
          { color: YELLOW2, delay: 0 },
          { color: ORANGE, delay: 0.05 },
          { color: YELLOW2, delay: 0.15 },
          { color: ORANGE, delay: 0.3 },
          { color: YELLOW2, delay: 0.5 },
        ]}
      />
      {alert && <AlertAnimation alert={alert} removeAlert={() => setAlert('')} color={'orange'} />}
      <div style={{ position: "relative" }}>
        <IconButton {...props} />
      </div>
    </div>
  );
};

const DummyButton = ({
  value,
  active,
  onClick,
  icon,
  iconSize,
  bgColor,
  color,
  borderColor,
  width,
}) => {
  const Icon = icons[icon];
  return (
    <StyledButton
      bgColor={active ? bgColor : "white"}
      // cursor={active}
      width={width}
      color={!active && (color || "textColor_disabled")}
      borderColor={!active && (borderColor || "textColor_disabled")}
      onClick={onClick}
    >
      {icon && Icon && (
        <Icon
          size={iconSize || 20}
          color={black}
          style={{ marginRight: "1rem" }}
        />
      )}
      {value}
    </StyledButton>
  );
};

export { DummyButton };
