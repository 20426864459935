

export const CONFIG = {
  _AppUrl : 'https://qag.netlify.app',
  _GetImagesUrl : 'https://stage-dot-kp-vanilla.el.r.appspot.com/api/mediaUpload/getFiles?bucketName=qag_media&filterPrefix=',
  _SaveStoryConfigsUrl : 'https://stage-dot-kp-vanilla.el.r.appspot.com/api/mediaUpload/writeFile?bucketName=qag_media',
  _GetStoryConfigsUrl : 'https://stage-dot-kp-vanilla.el.r.appspot.com/api/mediaUpload/getFiles?bucketName=qag_media&filterPrefix=storyConfig_',
  _DataSrc : 'local', //'local' will fetch from local static files '/data/*,  'server' will fetch from server
  _TCIUrl : '/tci/0ccc7b67fa37c162f8b8e9e964f7ead6a3c34862',
  _Env : 'prod',
  _GATag : 'G-QTCYHYT8HB',
  _EnableMCQEventTracker: true
}


// export const CONFIG = {
//   _AppUrl : 'http://localhost:3001',
//   _GetImagesUrl : 'http://192.168.0.101:8080/api/mediaUpload/getFiles?bucketName=qag_media&filterPrefix=',
//   _SaveStoryConfigsUrl : 'http://192.168.0.101:8080/api/mediaUpload/writeFile?bucketName=qag_media',
//   _GetStoryConfigsUrl : 'http://192.168.0.101:8080/api/mediaUpload/getFiles?bucketName=qag_media&filterPrefix=storyConfig_',
  
// }





