

import styled, {css} from "styled-components/macro";

export const InteractiveCompWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InteractiveCompWrapper__noBotMargin = styled.div`
  width: 100%;
  height: ${({theme}) => `calc(100vh - ${theme.layout.pageMarginBottom}px)` };
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DummyInput = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  min-height: 35px;
  flex-direction: column;
  background-color: ${({ bgColor, theme }) =>
    theme.colors[bgColor] || theme.colors["white"]};
  padding: .5rem 1.5rem;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.colors["white"]};
`;

export const MaxCountAlert = styled.div`
  display: flex;
  justify-content: center;
  > span {
    background-color: ${({theme}) => theme.colors.danger};
    padding: 2px 8px;
    border-radius: 2px;
    color: ${({theme}) => theme.colors.white};
  }
`
export const StyledTag = styled.div`
  background-color: ${({theme}) => theme.colors.black};
  color: ${({theme}) => theme.colors.white};
  padding: 3px 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 10px;
  ${({theme}) => theme.typo.h5}
  ${(props) => props.styled }
`;

export const StyleOverlayBlackScreenWrapper = styled.div`
  ${({theme}) => theme.transition("height", { duration: '0s'})}
  width: 100vw;
  height: ${({show}) => show ? '100vh' : 0};
  transition-delay: ${({show}) => show ? 0 : '0.3s'};
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`
export const StyleOverlayBlackScreen = styled.div`
  background-color: ${({theme}) => theme.colors.black};
  opacity: ${({show}) => show ? 0.8 : 0};
  
  width: 100%;
  height: 100%;
  
  ${({theme}) => theme.transition(["opacity"])}
  
  
`