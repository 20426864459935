
import { useContext } from 'react';
import { EnvContext } from '../contexts/EnvContext';
import * as translationExports from '../data/translationExports';
import UITranslations from '../data/translations/final/UITranslations'


export const __getNetworkGraphLocale = (storyId, sceneId, pageId, activeLangValue ) => {
    
    if(activeLangValue === 'en') return undefined // dont translate if lang is en. just use the text being rendered in html
    let pageData = translationExports[`${storyId}_translations`][sceneId][pageId];
    if(!pageData) return undefined;
    return pageData.find(d => d.valuePath === 'display')?.value[activeLangValue] || undefined
}

export const convertEnIntoLocaleKey = text => text.toLowerCase().replace(/[\.\n\"]/g, '').trim();


export const __LOC = (text, ph1, ph2) => {
    
    let {activeLang} = useContext(EnvContext)
    if(activeLang.value === 'en') return text;
    let sanitizedText = convertEnIntoLocaleKey(text);

    const checkMatchAgainst__PLACEHOLDER__translations = (sanitizedText) => {

        if(sanitizedText.includes('you have completed')){
            
            let transKey = Object.keys(UITranslations).find(k => k.includes('you have completed __placeholder1__'));
            let trans = UITranslations[transKey][activeLang.value]
            trans = trans.replace('__PLACEHOLDER1__', ph1)
            trans = trans.replace('__PLACEHOLDER2__', ph2)
            return trans;
        }else if(sanitizedText.includes('you have finished one journey')){
            let transKey = Object.keys(UITranslations).find(k => k.includes('you have finished one journey'));
            let trans = UITranslations[transKey][activeLang.value]
            trans = trans.replace('__PLACEHOLDER__', ph1)
            return trans;
        }else{
            return text
        }
    } 

    // console.log({sanitizedText})
    return UITranslations[sanitizedText] 
        ? (UITranslations[sanitizedText][activeLang.value] || text)
        : checkMatchAgainst__PLACEHOLDER__translations(sanitizedText)
}
