import React, { useContext, Fragment } from "react";
import { GameItemsContext } from "../../contexts/GameItemsContext";
import "./_style.scss";

import AnimatedNumber from "animated-number-react";
import { icons } from "../../importGroups/icons";
import { __LOC } from "../../utils/locale";

export const Metrics = () => {
  const { metrics } = useContext(GameItemsContext);
  const sortFn = (a,b) => a.display.localeCompare(b.display) //alphabetical
  return (
    <Fragment>
    <div className="Metrics">
      {Object.values(metrics).sort(sortFn).map((metric) => (
        
          <MetricBlock score={metric.score}/>        
      ))}
    </div>
    <div className="Metrics">
      {Object.values(metrics).sort(sortFn).map((metric) => (
        
          <MetricBlock icon={metric.icon} style={{justifyContent: 'center'}}/>
        
      ))}
    </div>
    <div className="Metrics">
      {Object.values(metrics).sort(sortFn).map((metric) => (
        
          <MetricBlock  display={metric.display}/>
        
      ))}
    </div>
    </Fragment>
  );

};


const MetricBlock = ({ icon, score, display, style }) => {
  

  const genIcon = (icon) => {
    let Icon = icons[icon];
    return (
      <div style={{ maxWidth: '10rem'}}>
        <Icon viewBox={"0 0 20 20"} width={"100%"} height={undefined} />
      </div>
    );
  };
  
  const formatValue = (value) => value.toFixed(0);
  return (
    <div className="MetricBlock" style={style}>
      <h1 className="h1 bold">
        {score &&  <AnimatedNumber value={score} formatValue={formatValue} />}
      </h1>
      {icon && genIcon(icon)}
      {display && <h5 className='p' style={{textAlign: 'center'}}>{__LOC(display)}</h5>}
    </div>
  );
};



  




