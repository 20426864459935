import React, { forwardRef } from "react";
import "./_style.scss";

export const FixedGrid = ({ children, className, id, style, bgImgPage }) => {
  
  return (
    <div id={id} className={`qagGrid--fixed grid ${className}`} style={style}>
      {children}
    </div>
  );
};

export const AutoGrid = ({ children, className, id, style }) => {
  return (
    <div id={id} className={`qagGrid--auto grid ${className}`} style={style}>
      {children}
    </div>
  );
};

export const FlexibleGrid = ({ children, className, id }) => {

  const minimumHeight = window.innerHeight - 120;
  const totalGapHeight = 10 * 20;
  const rowHeight = (minimumHeight - totalGapHeight) / 20;

  return (
    <div
      id={id}
      className={`qagGrid--flexible grid ${className}`}
      style={{
        gridTemplateRows: `repeat(14, minmax(${rowHeight}px, auto))`,
      }}>
      {children}
    </div>
  );
};


export const ColGrid = forwardRef(({children, className, style}, ref) => {
  return (<div ref={ref} className={`colGrid ${className}`} style={style}>{children}</div>)
})
