
  export default {
    "scene0": {
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "X_1",
            "hi": "X_1",
            "ka": "X_1",
            "te": "X_1",
            "or": "X_1",
            "as": "X_1",
            "gu": "X_1"
          }
        },
        {
          "valuePath": "blocks.0.props.desc",
          "value": {
            "en": "Razia lives in the Sunderbans with her parents and her younger sister, Shazia. Her older brother, Imran, works as a paramedic in a hospital in the city. He is trained to give medical help in the emergency room.",
            "hi": "रजिया अपने माता-पिता और अपनी छोटी बहन शाजिया के साथ सुंदरबन में रहती है। उसका बड़ा भाई, इमरान शहर के अस्पताल में पैरामेडिक है। उसने एमरजेंसी में मेडिकल हेल्प करने की ट्रेनिंग ली है।",
            "ka": "ರಝಿಯಾ ತನ್ನ ಹೆತ್ತವರು ಹಾಗು ಕಿರಿಯ ಸಹೋದರಿ ಶಾಝಿಯಾ ಅವರೊಂದಿಗೆ ಸುಂದರ್‌ಬನ್ಸ್‌ನಲ್ಲಿ ವಾಸಿಸುತ್ತಿದ್ದಾಳೆ.ಆಕೆಯ ಅಣ್ಣ ಇಮ್ರಾನ್ ನಗರದ ಆಸ್ಪತ್ರೆಯೊಂದರಲ್ಲಿ ವೈದ್ಯಕೀಯ ಸಿಬ್ಬಂದಿಯಾಗಿ ಕೆಲಸ ನಿರ್ವಹಿಸುತ್ತಿದ್ದಾರೆ. ಅವರು ಎಮರ್ಜೆನ್ಸಿ ರೂಮಿನಲ್ಲಿ ವೈದ್ಯಕೀಯ ಸಹಾಯವನ್ನು ನೀಡಲು ತರಬೇತಿ ಪಡೆದಿದ್ದಾರೆ.",
            "te": "రజియా తన తల్లిదండ్రులు మరియు చెల్లెలు షాజియాతో కలిసి సుందర్‌బన్స్ లో నివసిస్తుంది. ఆమె అన్న ఇమ్రాన్ నగరంలోని ఓ ఆసుపత్రిలో వైద్య సహాయకుడిగా పనిచేస్తున్నాడు. అతను అత్యవసర గదిలో వైద్య సహాయం అందించడానికి శిక్షణ తీసుకున్నాడు.",
            "or": "ରଜିଆ ତାଙ୍କ ପିତାମାତା ଏବଂ ସାନ ଭଉଣୀ ଶାଜିଆଙ୍କ ସହ ସୁନ୍ଦରବନରେ ରୁହନ୍ତି । ତାଙ୍କ ବଡ ଭାଇ ଇମ୍ରାନ ସହରର ଏକ ଡାକ୍ତରଖାନାରେ ପାରାମେଡିକ୍ ଭାବରେ କାର୍ଯ୍ୟ କରନ୍ତି । ଜରୁରୀକାଳୀନ କୋଠରୀରେ ଡାକ୍ତରୀ ସହାୟତା ଦେବା ପାଇଁ ସେ ତାଲିମପ୍ରାପ୍ତ ।",
            "as": "ৰাজিয়াই তাইৰ মাক-দেউতাক আৰু তাইৰ সৰু ভনীয়েক শ্বাজিয়াৰ সৈতে সুন্দৰবনত বাস কৰে। তাইৰ ডাঙৰ ভায়েক ইমৰানে চহৰখনৰ এখন চিকিৎসালয়ত পেৰামেডিক হিচাপে কাম কৰে। তেওঁক জৰুৰীকালীন কোঠাত চিকিৎসামূলক সহায় কৰিবলৈ প্ৰশিক্ষণ দিয়া হৈছে।",
            "gu": "રઝિયા સુંદરવનમાં તેના માતા-પિતા અને તેની નાની બહેન શાઝિયા સાથે રહે છે. તેનો મોટો ભાઈ ઈમરાન, પેરામેડિક તરીકે શહેરની એક દવાખાનામાં કામ કરે છે. તેણે ઇમરજન્સી રૂમમાં તબીબી મદદ આપવા માટેની તાલીમ મેળવેલ છે। "
          }
        },
        {
          "valuePath": "blocks.0.props.descTexts.0.text",
          "value": {
            "en": "Razia lives in the Sunderbans with her parents and her younger sister, Shazia. Her older brother, Imran, works as a paramedic in a hospital in the city. He is trained to give medical help in the emergency room.",
            "hi": "रजिया अपने माता-पिता और अपनी छोटी बहन शाजिया के साथ सुंदरबन में रहती है। उसका बड़ा भाई, इमरान शहर के अस्पताल में पैरामेडिक है। उसने एमरजेंसी में मेडिकल हेल्प करने की ट्रेनिंग ली है।",
            "ka": "ರಝಿಯಾ ತನ್ನ ಹೆತ್ತವರು ಹಾಗು ಕಿರಿಯ ಸಹೋದರಿ ಶಾಝಿಯಾ ಅವರೊಂದಿಗೆ ಸುಂದರ್‌ಬನ್ಸ್‌ನಲ್ಲಿ ವಾಸಿಸುತ್ತಿದ್ದಾಳೆ.ಆಕೆಯ ಅಣ್ಣ ಇಮ್ರಾನ್ ನಗರದ ಆಸ್ಪತ್ರೆಯೊಂದರಲ್ಲಿ ವೈದ್ಯಾಧಿಕಾರಿಯಾಗಿ ಕೆಲಸ ನಿರ್ವಹಿಸುತ್ತಿದ್ದಾರೆ. ಅವರು ಎಮರ್ಜೆನ್ಸಿ ರೂಮಿನಲ್ಲಿ ವೈದ್ಯಕೀಯ ಸಹಾಯವನ್ನು ನೀಡಲು ತರಬೇತಿ ಪಡೆದಿದ್ದಾರೆ.",
            "te": "రజియా తన తల్లిదండ్రులు మరియు చెల్లెలు షాజియాతో కలిసి సుందర్‌బన్స్ లో నివసిస్తుంది. ఆమె అన్న ఇమ్రాన్ నగరంలోని ఓ ఆసుపత్రిలో వైద్య సహాయకుడిగా పనిచేస్తున్నాడు. అతను అత్యవసర గదిలో వైద్య సహాయం అందించడానికి శిక్షణ తీసుకున్నాడు.",
            "or": "ରଜିଆ ତାଙ୍କ ପିତାମାତା ଏବଂ ସାନ ଭଉଣୀ ଶାଜିଆଙ୍କ ସହ ସୁନ୍ଦରବନରେ ରୁହନ୍ତି । ତାଙ୍କ ବଡ ଭାଇ ଇମ୍ରାନ ସହରର ଏକ ଡାକ୍ତରଖାନାରେ ପାରାମେଡିକ୍ ଭାବରେ କାର୍ଯ୍ୟ କରନ୍ତି । ଜରୁରୀକାଳୀନ କୋଠରୀରେ ଡାକ୍ତରୀ ସହାୟତା ଦେବା ପାଇଁ ସେ ତାଲିମପ୍ରାପ୍ତ ।",
            "as": "ৰাজিয়াই তাইৰ মাক-দেউতাক আৰু তাইৰ সৰু ভনীয়েক শ্বাজিয়াৰ সৈতে সুন্দৰবনত বাস কৰে। তাইৰ ডাঙৰ ভায়েক ইমৰানে চহৰখনৰ এখন চিকিৎসালয়ত পেৰামেডিক হিচাপে কাম কৰে। তেওঁক জৰুৰীকালীন কোঠাত চিকিৎসামূলক সহায় কৰিবলৈ প্ৰশিক্ষণ দিয়া হৈছে।",
            "gu": "રઝિયા સુંદરવનમાં તેના માતા-પિતા અને તેની નાની બહેન શાઝિયા સાથે રહે છે. તેનો મોટો ભાઈ ઈમરાન, પેરામેડિક તરીકે શહેરની એક દવાખાનામાંમાં કામ કરે છે. તેણે ઇમરજન્સી રૂમમાં તબીબી મદદ આપવા માટેની તાલીમ મેળવેલ છે"
          }
        },
        {
          "valuePath": "blocks.0.props.descTexts.1.text",
          "value": {
            "en": "Living in the Sundarbans, Razia feels a deep connection to the place. She is part of the eco-warrior club, an NGO program at her school.",
            "hi": "सुंदरबन में रहने की वजह से रजिया को उस जगह से गहरा लगाव था। उसने अपने स्कूल के एनजीओ प्रोग्राम, इको-वारियर क्लब में हिस्सा लिया है।",
            "ka": "ಸುಂದರಬನ್ಸ್‌ನಲ್ಲಿ ವಾಸಿಸುತ್ತಿರುವ ರಝಿಯಾ ಈ ಸ್ಥಳದೊಂದಿಗೆ ಆಳವಾದ ಸಂಬಂಧವನ್ನು ಹೊಂದಿದ್ದಾಳೆ. ಅವಳು ತನ್ನ ಶಾಲೆಯ ಎನ್‌ಜಿಒ ಕಾರ್ಯಕ್ರಮವಾದ ಇಕೋ-ವಾರಿಯರ್ ಕ್ಲಬ್‌ನ ಭಾಗವಾಗಿದ್ದಾಳೆ.",
            "te": "సుందర్‌బన్స్ లో నివసిస్తున్నందున, రజియాకు ఆ ప్రదేశం అంటే చాలా ఇష్టం. ఆమె తన స్కూల్‌లోని NGO కార్యక్రమం అయిన ఎకో-వారియర్ క్లబ్‌లో సభ్యురాలు.",
            "or": "ସୁନ୍ଦରବନରେ ରହୁଥିବା ରଜିଆ ସେହି ସ୍ଥାନ ସହିତ ଏକ ଗଭୀର ସଂଯୋଗ ଅନୁଭବ କରନ୍ତି । ସେ ଇକୋ-ୱାରିଅର୍ କ୍ଲବ, ଯାହାକି ତାଙ୍କ ବିଦ୍ୟାଳୟର ଏକ NGO କାର୍ଯ୍ୟକ୍ରମ ତାହାର ଏକ ଅଂଶ ।",
            "as": "সুন্দৰবনত বাস কৰি, ৰাজিয়াই ঠাইখনৰ সৈতে এক গভীৰ সম্পৰ্ক অনুভৱ কৰে। তাই পৰিৱেশৰ কাৰণে কাম কৰা ক্লাবৰ অংশ, যি হৈছে তাইৰ বিদ্যালয়ৰ এক NGO কাৰ্যসূচী।",
            "gu": "સુંદરવનમાં રહેવામાં, રઝિયા આ સ્થળ સાથે નજીકનો જોડાણ અનુભવે છે. તે ઇકો-વોરિયર ક્લબનો એક ભાગ છે, જે એક સામાજિક સંસ્થા છે, જે તેની સ્કૂલમાં કાર્યરત છે "
          }
        },
        {
          "valuePath": "blocks.0.props.descTexts.2.text",
          "value": {
            "en": "The Director didi at the NGO is her inspiration and she dreams of becoming like her.",
            "hi": "उस एनजीओ की डायरेक्टर दीदी उसके लिए प्रेरणा थी और वह आगे चलकर दीदी के जैसे ही बनना चाहती है।",
            "ka": "ಅವಳ ಸ್ಫೂರ್ತಿಯು ಎನ್‌ಜಿಒ ನಲ್ಲಿರುವ ನಿರ್ದೇಶಕಿ ದೀದಿಯಾಗಿದ್ದಾರೆ ಮತ್ತು ಅವಳು ಅವರಂತೆ ಆಗಬೇಕೆಂದು ಕನಸು ಕಾಣುತ್ತಾಳೆ.",
            "te": "NGO లోని డైరెక్టర్ అక్క తనకు స్ఫూర్తి మరియు తను కూడా ఆమె లాగానే కావాలనుకుంటుంది.",
            "or": "NGOର ନିର୍ଦ୍ଦେଶକ ଦିଦି ତାଙ୍କର ପ୍ରେରଣା ଏବଂ ସେ ତାଙ୍କ ପରି ହେବାର ସ୍ୱପ୍ନ ଦେଖନ୍ତି ।",
            "as": "NGO ৰ পৰিচালিকা বাইদেউ তাইৰ অনুপ্ৰেৰণা আৰু তাই তেওঁৰ দৰে হোৱাৰ সপোন দেখে।",
            "gu": "સામાજિક સંસ્થાના નિયામક બહેન તેની પ્રેરણા છે, અને તે તેમના જેવી બનવાનું સપનું ધરાવે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.descTexts.3.text",
          "value": {
            "en": "Currently in her 10th standard, Razia is an above average student with dreams of doing well in life. Her mother worries about her, as Razia is independent and does not do things that are expected of her as a girl.",
            "hi": "10वी कक्षा में पढ़ रही रजिया पढ़ाई में अच्छी है और वह जिंदगी में कुछ हासिल करना चाहती है। रजिया की माँ रजिया की फिक्र करती रहती है क्योंकि रजिया आजाद ख्यालों वाली लडकी है और वह लड़कियों की तरह काम नहीं करती जैसे कि उससे उम्मीद की जाती है।",
            "ka": "ಪ್ರಸ್ತುತ ತನ್ನ10ನೇ ತರಗತಿಯಲ್ಲಿ, ರಝಿಯಾ ಜೀವನದಲ್ಲಿ ಉತ್ತಮ ಸಾಧನೆ ಮಾಡುವ ಕನಸುಗಳೊಂದಿಗೆ ಸರಾಸರಿಗಿಂತ ಹೆಚ್ಚಿನ ವಿದ್ಯಾರ್ಥಿನಿ. ರಝಿಯಾ ಸ್ವತಂತ್ರಳಾಗಿದ್ದಾಳೆ ಮತ್ತು ಹುಡುಗಿಯಾಗಿ ಅವಳಿಂದ ನಿರೀಕ್ಷಿತ ಕೆಲಸಗಳನ್ನು ಮಾಡಲಾಗದ ಕಾರಣ ಅವಳ ತಾಯಿ ಅವಳ ಬಗ್ಗೆ ಚಿಂತಿಸುತ್ತಾರೆ.",
            "te": "ప్రస్తుతం 10వ తరగతి చదువుతున్న రజియా సగటు విద్యార్థి కంటే ఎక్కువ మరియు తన జీవితంలో బాగా రాణించాలనుకుంటుంది. రజియా స్వతంత్రురాలు మరియు ఆడపిల్లగా ఆమె ఆశించిన పనులు చేయకపోవడంతో ఆమె తల్లి ఆమె గురించి ఆందోళన చెందుతుంది.",
            "or": "ବର୍ତ୍ତମାନ ଦଶମ ଶ୍ରେଣୀରେ ପଢୁଥିବା ରଜିଆ ଜଣେ ହାରାହାରି ଛାତ୍ରୀ ଏବଂ ଜୀବନରେ କିଛି ଭଲ କରିବାର ସ୍ୱପ୍ନ ଦେଖନ୍ତି । ତାଙ୍କ ମା ତାଙ୍କ ବିଷୟରେ ଚିନ୍ତା କରନ୍ତି, କାରଣ ରଜିଆ ସ୍ୱାଧୀନ ଏବଂ ଝିଅ ଭାବରେ ତାଙ୍କଠାରୁ ଆଶା କରାଯାଉଥିବା କାର୍ଯ୍ୟ କରନ୍ତି ନାହିଁ ।",
            "as": "বৰ্তমান ৰাজিয়া দশম শ্ৰেণীৰ ছাত্ৰী হ'লেও শ্ৰেণীৰ আন ল'ৰা-ছোৱালী বোৰতকৈ অলপ বেছি জানে আৰু জীৱনত ভাল কিবা এটা কৰাৰ সপোন আছে। তাইৰ মাকে তাইৰ কাৰণে চিন্তা কৰে, কিয়নো ৰাজিয়া স্বাধীন‌ মনৰ আৰু ছোৱালী হিচাপে তাইৰ পৰা আশা কৰা কাম বোৰ তাই নকৰে।",
            "gu": "હાલમાં તે 10મા ધોરણમાં ભણે છે, રઝિયા એક મધ્યપ્રમાણથી ઉપરની વિદ્યાર્થિની છે અને જીવનમાં કંઇક સારું કરવાના સપનાઓ ધરાવે છે. તેની માતા તેના વિશે ચિંતા કરે છે, કારણ કે રઝિયા સ્વતંત્ર છે અને તે એવું કરતી નથી જે એક છોકરી તરીકે તેની પાસેથી અપેક્ષા રાખવામાં આવે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.descTexts.4.text",
          "value": {
            "en": "In this story, we look at how Razia explores different career options by choosing between Science or Vocational Training Diploma.",
            "hi": "इस स्टोरी में हम देखेंगे कि, रजिया सायन्स या वोकेशनल ट्रेनिंग डिप्लोमा में से किसी ऑप्शन को चुनकर कैसे अलग करियर ऑप्शन्स ढूंढती है।",
            "ka": "ಈ ಕಥೆಯಲ್ಲಿ, ವಿಜ್ಞಾನ ಅಥವಾ ವೊಕೇಶನಲ್ ಟ್ರೈನಿಂಗ್ ಡಿಪ್ಲೊಮಾ ನಡುವೆ ಆಯ್ಕೆ ಮಾಡುವ ಮೂಲಕ ರಝಿಯಾ ವಿವಿಧ ವೃತ್ತಿ ಆಯ್ಕೆಗಳನ್ನು ಹೇಗೆ ಅನ್ವೇಷಿಸುತ್ತಾರೆ ಎಂಬುದನ್ನು ನಾವು ನೋಡುತ್ತೇವೆ.",
            "te": "ఈ కథలో,సైన్స్ లేదా వొకేషనల్ ట్రైనింగ్ డిప్లొమాలో వివిధ కెరీర్ ఆప్షన్‌లను ఎంచుకోవడాన్ని రజియా ఎలా అన్వేషిస్తుందో మనం చూద్దాం",
            "or": "ଏହି କାହାଣୀରେ, ଆମେ ଦେଖିବା ଯେ ରଜିଆ କିପରି ବିଜ୍ଞାନ କିମ୍ବା ଧନ୍ଦାମୂଳକ ତାଲିମ ଡିପ୍ଲୋମା ମଧ୍ୟରେ ବାଛି ବିଭିନ୍ନ କ୍ୟାରିୟର ବିକଳ୍ପ ଅନୁସନ୍ଧାନ କରନ୍ତି ।",
            "as": "এই কাহিনীটোত, আমি বিজ্ঞান বা বৃত্তিমূলক প্ৰশিক্ষণ ডিপ্লোমাৰ মাজত বাছনি কৰি ৰাজিয়াই কেনেদৰে বিভিন্ন কেৰিয়াৰৰ বিকল্প বোৰ বিচাৰ কৰে চাওঁ।",
            "gu": "આ વાર્તાની અંદર, આપણે જોઈએ કે કેવી રીતે રઝિયા વિજ્ઞાન અથવા ડિપ્લોમા વ્યવસાયિક તાલીમ  વચ્ચે પસંદગી કરીને કારકિર્દીના અલગ અલગ વિકલ્પોની શોધ કરે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.metricText.metricCoin",
          "value": {
            "en": "Improve her family’s financial situation",
            "hi": "अपने फॅमिली की फायनांशियल कंडिशन सुधारना",
            "ka": "ಆಕೆಯ ಕುಟುಂಬದ ಆರ್ಥಿಕ ಪರಿಸ್ಥಿತಿಯನ್ನು ಸುಧಾರಿಸಿ",
            "te": "తన కుటుంబ ఆర్థిక పరిస్థితిని మెరుగుపరచడం",
            "or": "ତାଙ୍କ ପରିବାରର ଆର୍ଥିକ ସ୍ଥିତିରେ ଉନ୍ନତି ଆଣିବା",
            "as": "তাইৰ পৰিয়ালৰ বিত্তীয় পৰিস্থিতি উন্নত কৰা",
            "gu": "તે તેના પરિવારની આર્થિક સ્થિતિમાં સુધારો કરે"
          }
        },
        {
          "valuePath": "blocks.0.props.metricText.metricPersonal",
          "value": {
            "en": "Study and work on topics she likes",
            "hi": "अपने पंसदीदा टॉपिक के बारे में पढ़ना और उस पर काम करना",
            "ka": "ಅವಳು ಇಷ್ಟಪಡುವ ವಿಷಯಗಳ ಬಗ್ಗೆ ಅಧ್ಯಯನ ಮಾಡಿ ಮತ್ತು ಕೆಲಸ ಮಾಡಿ",
            "te": "తనకు ఇష్టమైన టాపిక్స్ చదవడం మరియు వాటి సంబంధిత పని చేయడం",
            "or": "ସେ ପସନ୍ଦ କରୁଥିବା ବିଷୟଗୁଡ଼ିକ ଉପରେ ଅଧ୍ୟୟନ ଏବଂ କାର୍ଯ୍ୟ କରିବା",
            "as": "তাই ভালপোৱা বিষয়বোৰ অধ্যয়ন কৰা আৰু তাৰ ওপৰত কাম কৰা",
            "gu": "તે પોતાને ગમતા વિષયો પર અભ્યાસ અને કામ કરવું ગમે છે "
          }
        },
        {
          "valuePath": "blocks.0.props.metricText.metricSocial",
          "value": {
            "en": "Parents have strong opinions on gender roles for girls",
            "hi": "माता-पिता लड़कियों के तौर-तरीकों के बारे में सख्त राय रखते हैं",
            "ka": "ಹುಡುಗಿಯರಿಗೆ ಲಿಂಗ ಪಾತ್ರಗಳ ಬಗ್ಗೆ ಪೋಷಕರು ಬಲವಾದ ಅಭಿಪ್ರಾಯಗಳನ್ನು ಹೊಂದಿದ್ದಾರೆ",
            "te": "ఆడపిల్లల లింగ పాత్రలపై తల్లిదండ్రులకు బలమైన అభిప్రాయాలు ఉంటాయి",
            "or": "ଲିଙ୍ଗର ଭୂମିକା ଉପରେ ପିତାମାତାଙ୍କ ଝିଅମାନଙ୍କ ପାଇଁ ଦୃଢ ମତାମତ ଅଛି",
            "as": "ছোৱালীৰ বাবে লিংগমূলক ভূমিকাৰ ওপৰত অভিভাৱকৰ দৃঢ় মতামত আছে",
            "gu": "માતપિતા એવું દ્રઢપણે માને છે છોકરીઓ માટે જાતિ આધારિત ભૂમિકા નક્કી થવી જોઈએ  "
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "X_3_1",
            "hi": "X_3_1",
            "ka": "X_3_1",
            "te": "X_3_1",
            "or": "X_3_1",
            "as": "X_3_1",
            "gu": "X_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia and her friend Sania are on a boat. They see many plastic bottles in the river.",
            "hi": "रजिया और उसकी दोस्त सानिया एक बोट पर हैं। उन्हें नदी में कई प्लास्टिक की बोतलें दिखाई देती हैं।",
            "ka": "ರಝಿಯಾ ಮತ್ತು ಆಕೆಯ ಸ್ನೇಹಿತೆ ಸಾನಿಯಾ ದೋಣಿಯಲ್ಲಿದ್ದಾರೆ. ಅವರು ನದಿಯಲ್ಲಿ ಅನೇಕ ಪ್ಲಾಸ್ಟಿಕ್ ಬಾಟಲಿಗಳನ್ನು ನೋಡುತ್ತಾರೆ.",
            "te": "రజియా మరియు ఆమె స్నేహితురాలు సానియా పడవలో ఉన్నారు. వారు నదిలో చాలా ప్లాస్టిక్ బాటిళ్లను చూస్తారు",
            "or": "ରଜିଆ ଏବଂ ତାଙ୍କ ବନ୍ଧୁ ସାନିଆ ଏକ ଡଙ୍ଗାରେ ଅଛନ୍ତି । ସେମାନେ ନଦୀରେ ଅନେକ ପ୍ଲାଷ୍ଟିକ୍ ବୋତଲ ଦେଖନ୍ତି ।",
            "as": "নদী",
            "gu": "રઝિયા અને તેની મિત્ર સાનિયા હોડી માં છે. તેઓ ઘણી પ્લાસ્ટિકની બોટલો નદીમાં જુએ છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "X_3_2//",
            "hi": "X_3_2//",
            "ka": "X_3_2//",
            "te": "X_3_2//",
            "or": "X_3_2//",
            "as": "X_3_2//",
            "gu": "X_3_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Sania",
            "hi": "सानिया",
            "ka": "ಸಾನಿಯಾ",
            "te": "సానియా",
            "or": "ସାନିଆ",
            "as": "ছানিয়া",
            "gu": "સાનિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Chee, look at this mess the tourists make. They are so irresponsible.",
            "hi": "छी, देखो टूरिस्टों ने कितनी गंदगी फैलाई है। कितने लापरवाह है ये लोग।",
            "ka": "ಛೀ, ಪ್ರವಾಸಿಗರು ಮಾಡುವ ಈ ಅವ್ಯವಸ್ಥೆ ನೋಡಿ. ಅವರು ತುಂಬಾ ಬೇಜವಾಬ್ದಾರರಾಗಿದ್ದಾರೆ.",
            "te": "ఛీ, పర్యాటకులు చేసే ఈ గందరగోళాన్ని చూడండి. వారు చాలా బాధ్యతారాహిత్యంగా ఉన్నారు.",
            "or": "ଛି, ପର୍ଯ୍ୟଟକମାନେ କରୁଥିବା ଏହି ବିଶୃଙ୍ଖଳାକୁ ଦେଖ । ସେମାନେ କେତେ ଦାୟିତ୍ୱହୀନ ।",
            "as": "ছিঃ, পৰ্যটকসকলে কৰা এই লেতেৰাবোৰ চাওক। তেওঁলোক বহুত দায়িত্বহীন।",
            "gu": "જો, પ્રવાસીઓ કેટલું ખરાબ વર્તન કરે છે. તેઓ કેટલા  બેજવાબદાર છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Hmm, I’m sure this is what they do at home also. If we had to go to another town, we would never throw trash into their water! People from the city are different.",
            "hi": "हम्म, मुझे यकीन है कि वे अपने घर पर भी ऐसे ही करते होंगे। अगर हमें किसी दुसरे शहर में जाना पड़ें तो, हम कभी भी उनके पानी में कचरा नहीं फेकेंगे! शहर के लोग कितनी अलग सोच रखते हैं।",
            "ka": "ಹಾಂ, ಅವರು ಮನೆಯಲ್ಲಿಯೂ ಇದನ್ನೇ ಮಾಡುತ್ತಾರೆ ಎಂದು ನನಗೆ ಖಾತ್ರಿಯಿದೆ. ನಾವು ಬೇರೆ ಊರಿಗೆ ಹೋಗಬೇಕಾದರೆ ಅವರ ನೀರಿಗೆ ಕಸ ಹಾಕುವುದಿಲ್ಲ! ನಗರದ ಜನರು ವಿಭಿನ್ನರಾಗಿದ್ದಾರೆ.",
            "te": "హా, వాళ్ళు ఇంట్లో కూడా ఇలానే చేస్తారని నేను ఖచ్చితంగా అనుకుంటున్నాను.మనం వేరే ఊరికి వెళ్ళవలసి వస్తే,మనం ఎప్పుడూ చెత్తను వారి నీటిలో వేయము.నగర ప్రజలు భిన్నంగా ఉంటారు.",
            "or": "ହଁ, ମୁଁ ନିଶ୍ଚିତ ଯେ ସେମାନେ ଘରେ ମଧ୍ୟ ଏହା କରୁଥିବେ । ଯଦି ଆମକୁ ଅନ୍ୟ ସହରକୁ ଯିବାକୁ ପଡିବ, ତେବେ ଆମେ ସେମାନଙ୍କ ପାଣିରେ ଆବର୍ଜନା ଫିଙ୍ଗିବୁ ନାହିଁ! ସହରର ଲୋକମାନେ ଭିନ୍ନ ଅଟନ୍ତି ।",
            "as": "হুম, মই নিশ্চিত যে তেওঁলোকে চাগে ঘৰতো এয়াই কৰে। যদি আমি আন এখন চহৰলৈ যাব লগা হয়, তেন্তে আমি কেতিয়াও তেওঁলোকৰ পানীত আৱৰ্জনা নেপেলাও! চহৰৰ মানুহবোৰ বেলেগ।",
            "gu": "હા, મને લાગે છે કે તેઓ ઘરે પણ આ પ્રમાણે જ કરતાં હશે. જો આપણે બીજા શહેરમાં જઈએ તો આપણે  ક્યારેય તેમના પાણીમાં કચરો ફેંકીશું નહીં! શહેરના લોકો અલગ હોય છે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "X_3_3////",
            "hi": "X_3_3////",
            "ka": "X_3_3////",
            "te": "X_3_3////",
            "or": "X_3_3////",
            "as": "X_3_3////",
            "gu": "X_3_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "But Sania, they still should be held responsible. \nAll this pollution affects the environment around us, and then that affects us.",
            "hi": "लेकिन सानिया, उन्हें इस बात के लिए जिम्मेदार ठहराया जाना चाहिए। \n यह पोल्यूशन हमारे आसपास के पर्यावरण को दूषित कर रहा है और इसका असर हम पर भी होता है।",
            "ka": "ಆದರೆ ಸಾನಿಯಾ, ಅವರು ಇನ್ನೂ ಜವಾಬ್ದಾರರಾಗಿರಬೇಕು.\n ಈ ಎಲ್ಲಾ ಮಾಲಿನ್ಯವು ನಮ್ಮ ಸುತ್ತಲಿನ ಪರಿಸರದ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುತ್ತದೆ ಮತ್ತು ನಂತರ ಅದು ನಮ್ಮ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುತ್ತದೆ.",
            "te": "కానీ సానియా..ఇప్పటికైనా వారు బాధ్యత వహించాలి.\n ఈ కాలుష్యం అంతా మన చుట్టూ ఉన్న పర్యావరణాన్ని ప్రభావితం చేస్తుంది, ఆపై అది మనల్ని ప్రభావితం చేస్తుంది.\"",
            "or": "କିନ୍ତୁ ସାନିଆ, ତଥାପି ସେମାନଙ୍କୁ ଦାୟୀ କରାଯିବା ଉଚିତ୍ । ଏହି ସମସ୍ତ ପ୍ରଦୂଷଣ ଆମ ଚାରିପାଖରେ ଥିବା ପରିବେଶକୁ ପ୍ରଭାବିତ କରେ, ଏବଂ ତା'ପରେ ଏହା ଆମକୁ ପ୍ରଭାବିତ କରେ ।",
            "as": "কিন্তু ছানিয়া, তেওঁলোক এতিয়াও দায়িত্বশীল হোৱা উচিত। \n এই সকলো প্ৰদূষণে আমাৰ চাৰিওফালৰ পৰিৱেশ নষ্ট কৰে, আৰু তাৰ পিছত ই আমাকো নষ্ট কৰে।",
            "gu": "પરંતુ સાનિયા, તેમને હજુ પણ જવાબદાર માનવા જોઈએ.\nઆ તમામ પ્રદૂષણ આપણી આજુબાજુના વાતાવરણને અસર કરે છે અને પછી તે આપણને અસર કરે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "But Razia, tourists are also a source of earning money for many people.\nMy Baba is a tour operator, my brothers are both working in hotels and hence they are able to afford my education.",
            "hi": "लेकिन रजिया, ये टूरिस्ट कई लोगों के लिए पैसे कमाने का जरिया हैं। \n मेरे बाबा टूर ऑपरेटर है और मेरे दोनों भाई होटल में काम करते हैं और इसलिए वे मेरे पढ़ाई का खर्च उठा सकते हैं।",
            "ka": "ಆದರೆ ರಝಿಯಾ, ಪ್ರವಾಸಿಗರು ಅನೇಕ ಜನರಿಗೆ ಹಣ ಸಂಪಾದಿಸುವ ಮೂಲವಾಗಿದ್ದಾರೆ.\n ನನ್ನ ಬಾಬಾ ಟೂರ್ ಆಪರೇಟರ್, ನನ್ನ ಸಹೋದರರು ಇಬ್ಬರೂ ಹೋಟೆಲ್‌ಗಳಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾರೆ ಮತ್ತು ಆದ್ದರಿಂದ ಅವರು ನನ್ನ ಶಿಕ್ಷಣಕ್ಕೆ ಸಹಾಯ ಮಾಡಲು ಸಮರ್ಥರಾಗಿದ್ದಾರೆ.",
            "te": "కానీ రజియా, టూరిస్ట్ ల (పర్యాటకులు) వల్ల చాలామంది డబ్బులు కూడా సంపాదిస్తారు కదా. మా నాన్న ఒక టూర్ ఆపరేటర్ (పర్యాటక కార్యకర్త) మరియు మా అన్నలు ఇద్దరూ హోటళ్లలో పని చేస్తున్నారు మరియు దానివల్లనే వాళ్ళు నన్ను చదివించగలుగుతున్నారు.",
            "or": "କିନ୍ତୁ ରଜିଆ, ପର୍ଯ୍ୟଟକମାନେ ମଧ୍ୟ ଅନେକ ଲୋକଙ୍କ ପାଇଁ ଟଙ୍କା ରୋଜଗାର କରିବାର ଉତ୍ସ । ମୋ ବାବା ଜଣେ ଟୁର୍ ଅପରେଟର, ମୋ ଭାଇମାନେ ଉଭୟ ହୋଟେଲରେ କାମ କରୁଛନ୍ତି ଏବଂ ସେଥିପାଇଁ ସେମାନେ ମୋର ଶିକ୍ଷା ଖର୍ଚ୍ଚ କରିବାକୁ ସକ୍ଷମ ଅଟନ୍ତି ।",
            "as": "কিন্তু ৰাজিয়া, পৰ্যটকসকল বহু লোকৰ বাবে ধন উপাৰ্জনৰ উৎস। মোৰ বাবা এজন ট্যুৰ অপাৰেটৰ, মোৰ দুয়োজন ভাইটিয়ে হোটেলত কাম কৰি আছে আৰু সেয়েহে তেওঁলোকে মোৰ পঢ়াৰ খৰচ বহন কৰিব পাৰিছে।",
            "gu": "પરંતુ રઝિયા, પ્રવાસીઓ ઘણા લોકો માટે કમાણીનો સ્ત્રોત પણ છે.\nમારા બાબા ટૂર ઓપરેટર છે, મારા બંને ભાઈઓ હોટલમાં કામ કરે છે અને તેથી તેના લીધે મારું ભણતર શક્ય બને છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "X_3_4",
            "hi": "X_3_4",
            "ka": "X_3_4",
            "te": "X_3_4",
            "or": "X_3_4",
            "as": "X_3_4",
            "gu": "X_3_4"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Sania",
            "hi": "सानिया",
            "ka": "ಸಾನಿಯಾ",
            "te": "సానియా",
            "or": "ସାନିଆ",
            "as": "ছানিয়া",
            "gu": "સાનિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Sania",
            "hi": "सानिया",
            "ka": "ಸಾನಿಯಾ",
            "te": "సానియా",
            "or": "ସାନିଆ",
            "as": "ছানিয়া",
            "gu": "સાનિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Okay leave this, have you filled the form with your choice of subjects for 11th and 12th?",
            "hi": "चलो छोड़ो ये बातें, क्या तुमने 11 वीं और 12 वीं के फॉर्म में अपने पसंदीदा विषय चुने है?",
            "ka": "ಸರಿ ಇದನ್ನು ಬಿಡು, ನೀನು 11 ಮತ್ತು 12ನೇ ತರಗತಿಗಳಿಗೆ ನಿನ್ನ ಆಯ್ಕೆಯ ವಿಷಯಗಳೊಂದಿಗೆ ಫಾರ್ಮ್ ಅನ್ನು ಭರ್ತಿ ಮಾಡಿದ್ದೀಯಾ?",
            "te": "సరేలే ఇది వదిలేయ్, 11వ మరియు 12వ తరగతికి సంబంధించిన సబ్జెక్ట్ల ఎంపిక కోసం ఫారమ్‌ని నింపావా?",
            "or": "ଠିକ ଅଛି ଛାଡ, ତୁମେ ଏକାଦଶ ଏବଂ ଦ୍ୱାଦଶ ପାଇଁ ତୁମ ପସନ୍ଦର ବିଷୟରେ ଫର୍ମ ପୂରଣ କରିଛ କି?",
            "as": "ঠিক আছে এইটো বাদ দিয়া, তুমি 11 আৰু 12 শ্ৰেণীৰ বাবে তুমি ভালপোৱা বিষয়ৰ সৈতে ফৰ্মখন ফিলাপ কৰিছা নে?",
            "gu": "ઠીક છે તે વાત રહેવા દે, શું તે 11મા અને 12મા ધોરણ માટે તારા પસંદગીના વિષયોનું ફોર્મ ભર્યું છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "I haven’t really decided, I need to talk to Imran dada. He is coming back today.",
            "hi": "मैंने अभी तक तय नहीं किया है, मुझे इमरान दादा से बात करनी होगी, वह आज आनेवाला है।",
            "ka": "ನಾನು ನಿಜವಾಗಿಯೂ ನಿರ್ಧರಿಸಿಲ್ಲ, ನಾನು ಇಮ್ರಾನ್ ದಾದಾ ಜೊತೆ ಮಾತನಾಡಬೇಕು. ಅವರು ಇಂದು ಹಿಂತಿರುಗುತ್ತಿದ್ದಾರೆ.",
            "te": "నేను ఇంకా నిర్ణయించుకోలేదు, నేను ఇమ్రాన్ అన్నయ్యతో మాట్లాడాలి. అతను ఈరోజు తిరిగి వస్తున్నాడు.",
            "or": "ମୁଁ ପ୍ରକୃତରେ ନିଷ୍ପତ୍ତି ନେଇନାହିଁ, ମୋତେ ଇମ୍ରାନ ଭାଇଙ୍କ ସହ କଥା ହେବାକୁ ପଡିବ । ସେ ଆଜି ଫେରୁଛନ୍ତି ।",
            "as": "মই সঁচাকৈয়ে সিদ্ধান্ত লোৱা নাই, মই ইমৰান দাদাৰ সৈতে কথা পাতিব লাগিব। তেওঁ আজি ঘূৰি আহিব।",
            "gu": "મેં ખરેખર નક્કી નથી કર્યું, મારે ઈમરાન દાદા સાથે વાત કરવી પડશે. તેઓ આજે પાછા આવી રહ્યા છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "I’m so sure you are going to take up science, you’re anyway so interested in it.",
            "hi": "मुझे यकीन है कि तुम सायन्स ही लेनेवाली हो, आखिर तुम्हें इसमें इतना इंटरेस्ट भी तो है।",
            "ka": "ನೀನು ವಿಜ್ಞಾನವನ್ನು ತೆಗೆದುಕೊಳ್ಳುವೆ ಎಂದು ನನಗೆ ಖಚಿತವಾಗಿದೆ, ನೀನು ಹೇಗಾದರೂ ಅದರಲ್ಲಿ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದೀಯ.",
            "te": "నాకు ఖచ్చితంగా తెలుసు నువ్వు సైన్స్ తీసుకుంటవ్ అని, దానిపై నీకు చాలా ఆసక్తి ఉంది కదా",
            "or": "ମୁଁ ନିଶ୍ଚିତ ଯେ ତୁମେ ବିଜ୍ଞାନ ନେବ, କାରଣ ତୁମେ ଏଥିରେ ବହୁତ ଆଗ୍ରହୀ ।",
            "as": "মই নিশ্চিত যে তুমি বিজ্ঞান বিষয়টো ল'বা, কাৰণ তুমি এই বিষয়টোৰ প্ৰতি বহুত আগ্ৰহী।",
            "gu": "મને ખાતરી છે કે તું વિજ્ઞાન વિષય  લઈશ, કેમકે તને તેમાં રસ છે"
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "X_3_5(d)",
            "hi": "X_3_5(d)",
            "ka": "X_3_5(d)",
            "te": "X_3_5(d)",
            "or": "X_3_5(ଘ)",
            "as": "X_3_5(d)",
            "gu": "X_3_5(d)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Razia say?",
            "hi": "रजिया को क्या जवाब देना चाहिए?",
            "ka": "ರಝಿಯಾ ಏನು ಹೇಳಬಹುದು?",
            "te": "రజియా ఏం చెప్పాలి?",
            "or": "ରଜିଆ କ'ଣ କହିବା ଉଚିତ୍?",
            "as": "ৰাজিয়াই কি কোৱা উচিত?",
            "gu": "રઝિયાએ શું કહેવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "I am not sure, I think I need to support my family financially.",
            "hi": "पता नहीं, मुझे लगता है कि मुझे मेरे परिवार को फायनांशियली मदद भी करनी चाहिए।",
            "ka": "ನನಗೆ ಖಚಿತವಿಲ್ಲ, ನನ್ನ ಕುಟುಂಬವನ್ನು ಆರ್ಥಿಕವಾಗಿ ಬೆಂಬಲಿಸಬೇಕು ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
            "te": "నేను ఇంకా ఏమి అనుకోలేదు, నేను నా కుటుంబానికి ఆర్థికంగా సహాయపడాలి అనుకుంటున్నాను.",
            "or": "ମୁଁ ନିଶ୍ଚିତ ନୁହେଁ, ମୁଁ ଭାବୁଛି ମୋତେ ମୋ ପରିବାରକୁ ଆର୍ଥିକ ସହାୟତା କରିବା ଆବଶ୍ୟକ ।",
            "as": "মই নিশ্চিত নহয়, মই ভাবোঁ মই মোৰ পৰিয়ালক আৰ্থিকভাৱে সহায় কৰিব লাগিব।",
            "gu": "હું હજુ ચોક્કસ નથી, મને લાગે છે કે મારે મારા પરિવારને આર્થિક રીતે મદદ કરવી જોઈએ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Yes I think you are right. I would love to study science in college.",
            "hi": "हाँ, मुझे लगता है कि तुम सही कह रही हो. मैं कॉलेज में सायन्स पढ़ना चाहती हूँ।",
            "ka": "ಹೌದು ನೀನು ಹೇಳಿದ್ದು ಸರಿ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ನಾನು ಕಾಲೇಜಿನಲ್ಲಿ ವಿಜ್ಞಾನವನ್ನು ಕಲಿಯಲು ಇಷ್ಟಪಡುತ್ತೇನೆ.",
            "te": "అవును నాకు తెలిసి నువ్వు చెప్పింది సరైనది. నేను కాలేజీలో సైన్స్ చదవడానికి ఇష్టపడతాను.",
            "or": "ହଁ, ମୁଁ ଭାବୁଛି ତୁମେ ଠିକ୍ । ମୁଁ କଲେଜରେ ବିଜ୍ଞାନ ଅଧ୍ୟୟନ କରିବାକୁ ପସନ୍ଦ କରିବି ।",
            "as": "হয়, মই ভাবোঁ তুমি ঠিক কথাই কৈছা। মই কলেজত বিজ্ঞান পঢ়িবলৈ ভাল পাম।",
            "gu": "હા, મને લાગે છે કે તું સાચું કહે છે, મને કોલેજમાં વિજ્ઞાન વિષય ભણવો ખુબજ ગમશે"
          }
        }
      ]
    },
    "scene2": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "X(i)_3_5////",
            "hi": "X(i)_3_5////",
            "ka": "X(i)_3_5////",
            "te": "X(i)_3_5////",
            "or": "X(i)_3_5////",
            "as": "X(i)_3_5////",
            "gu": "X(i)_3_5////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "I am not sure, i think i need to support my family financially",
            "hi": "पता नहीं, मुझे लगता है कि मुझे मेरे परिवार को फायनांशियली मदद भी करनी चाहिए।",
            "ka": "ನನಗೆ ಖಚಿತವಿಲ್ಲ, ನನ್ನ ಕುಟುಂಬವನ್ನು ಆರ್ಥಿಕವಾಗಿ ಬೆಂಬಲಿಸಬೇಕು ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
            "te": "నేను ఇంకా ఏమి అనుకోలేదు, నేను నా కుటుంబానికి ఆర్థికంగా సహాయపడాలి అనుకుంటున్నాను.",
            "or": "ମୁଁ ନିଶ୍ଚିତ ନୁହେଁ, ମୁଁ ଭାବୁଛି ମୋତେ ମୋ ପରିବାରକୁ ଆର୍ଥିକ ସହାୟତା କରିବା ଆବଶ୍ୟକ",
            "as": "মই নিশ্চিত নহয়, মই ভাবোঁ মই মোৰ পৰিয়ালক আৰ্থিকভাৱে সহায় কৰিব লাগিব",
            "gu": "હું હજુ ચોક્કસ નથી, મને લાગે છે કે મારે મારા પરિવારને આર્થિક રીતે મદદ કરવી જોઈએ"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "But didn’t Imran want you to study further?",
            "hi": "लेकिन क्या इमरान नहीं चाहता कि तुम आगे पढ़ो?",
            "ka": "ಆದರೆ ನೀನು ಮುಂದೆ ಕಲಿಯುವುದು ಇಮ್ರಾನ್‌ಗೆ ಇಷ್ಟವಿರಲಿಲ್ಲವೇ?",
            "te": "అయితే నువ్వు ఇంకా చదవాలని ఇమ్రాన్ అనుకోవట్లేదా?",
            "or": "କିନ୍ତୁ ଇମ୍ରାନ ଚାହୁଁନଥିଲେ କି ତୁମେ ଅଧିକ ଅଧ୍ୟୟନ କର ବୋଲି?",
            "as": "কিন্তু ইমৰানে তুমি বেছিকৈ পঢ়াটো বিচৰা নাছিল জানো?",
            "gu": "પણ શું ઈમરાન ઈચ્છતો ન હતો કે તું આગળ અભ્યાસ કરે?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Yes, I know, but I don’t know if Abbu will be able to afford it.",
            "hi": "हाँ, मुझे पता है, लेकिन पता नहीं अब्बू इसके लिए खर्च कर पाएंगे या नहीं।",
            "ka": "ಹೌದು, ನನಗೆ ಗೊತ್ತು, ಆದರೆ ಅಬ್ಬುವಿಗೆ ಅದನ್ನು ಭರಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತದೆಯೇ ಎಂದು ನನಗೆ ತಿಳಿದಿಲ್ಲ.",
            "te": "హా, నాకు తెలుసు, కానీ నాన్న అంత డబ్బు పెట్టగలడో లేదో నాకు తెలియదు.",
            "or": "ହଁ, ମୁଁ ଜାଣେ, କିନ୍ତୁ ମୁଁ ଜାଣେ ନାହିଁ ଯେ ଅବୁ ଏହା ପାଇଁ ଖର୍ଚ୍ଚ କରିବାକୁ ସକ୍ଷମ ହେବେ କି ନାହିଁ ।",
            "as": "হয়, মই জানো, কিন্তু আব্বুৱে ইয়াৰ খৰচ বহন কৰিব পাৰিব নে নাই মই নাজানো।",
            "gu": "હા, હું જાણું છું, પણ મને લાગતું નથી કે અબ્બુને તે પરવડશે કે નહીં."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "So then? What about college?",
            "hi": "तो अब? कॉलेज का क्या?",
            "ka": "ಹಾಗಾದರೆ? ಕಾಲೇಜಿನ ಬಗ್ಗೆ ಏನು?",
            "te": "అయితే మరి? కాలేజీ సంగతేంటి?",
            "or": "ତେବେ କ’ଣ? କଲେଜ ବିଷୟରେ କ'ଣ ହେବ?",
            "as": "তেতিয়াহলে কলেজৰ কথাটো কি হব?",
            "gu": "તો પછી? કોલેજ વિશે શું?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "I don't know Sania, I really don't know",
            "hi": "पता नहीं सानिया, मुझे सच में कुछ पता नहीं।",
            "ka": "ನನಗೆ ಗೊತ್ತಿಲ್ಲ ಸಾನಿಯಾ, ನಿಜವಾಗಿಯೂ ನನಗೆ ಗೊತ್ತಿಲ್ಲ",
            "te": "నాకు తెలియదు సానియా, నాకు నిజంగా తెలియదు",
            "or": "ମୁଁ ସାନିଆଙ୍କୁ ଜାଣେ ନାହିଁ, ମୁଁ ପ୍ରକୃତରେ ଜାଣେ ନାହିଁ",
            "as": "মই ছানিয়াক চিনি নাপাওঁ, মই সঁচাকৈয়ে নাজানো।",
            "gu": "મને કશી ખબર નથી પડતી સાનિયા, હું ખરેખર મુઝવણમાં છું"
          }
        }
      ]
    },
    "scene3": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "X(ii)_3_6////",
            "hi": "X(ii)_3_6////",
            "ka": "X(ii)_3_6////",
            "te": "X(ii)_3_6////",
            "or": "X(ii)_3_6////",
            "as": "X(ii)_3_6////",
            "gu": "X(ii)_3_6////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Yes I think you are right. I would love to study science in college.",
            "hi": "हाँ, मुझे लगता है कि तुम सही कह रही हो। मैं कॉलेज में सायन्स पढ़ना चाहती हूँ।",
            "ka": "ಹೌದು ನೀನು ಹೇಳಿದ್ದು ಸರಿ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ನಾನು ಕಾಲೇಜಿನಲ್ಲಿ ವಿಜ್ಞಾನವನ್ನು ಕಲಿಯಲು ಇಷ್ಟಪಡುತ್ತೇನೆ.",
            "te": "అవును నాకు తెలిసి నువ్వు చెప్పింది సరైనది. నేను కాలేజీలో సైన్స్ చదవడానికి ఇష్టపడతాను.",
            "or": "ହଁ ମୁଁ ଭାବୁଛି ତୁମେ ଠିକ୍ । ମୁଁ କଲେଜରେ ବିଜ୍ଞାନ ଅଧ୍ୟୟନ କରିବାକୁ ପସନ୍ଦ କରିବି ।",
            "as": "হয়, মই ভাবোঁ আপুনি ঠিক কথাই কৈছে। মই কলেজত বিজ্ঞান পঢ়িবলৈ ভাল পাম।",
            "gu": "હા મને તમે સાચા લાગો છો. મને કોલેજમાં વિજ્ઞાન માં અભ્યાસ પસંદ આવશે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "But… I don’t think Abbu will be able to afford it.",
            "hi": "लेकिन… मुझे लगता है कि अब्बू शायद इतना खर्च नहीं कर पाएंगे।",
            "ka": "ಆದರೆ... ಅಬ್ಬುವಿಗೆ ಅದನ್ನು ಭರಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ ಎಂದು ನಾನು ಭಾವಿಸುವುದಿಲ್ಲ.",
            "te": "కానీ... నాన్న అంత డబ్బు పెట్టగలడో లేదో నాకు తెలియదు.",
            "or": "କିନ୍ତୁ... ମୁଁ ଜାଣେ ନାହିଁ ଯେ ଅବୁ ଏହା ପାଇଁ ଖର୍ଚ୍ଚ କରିବାକୁ ସକ୍ଷମ ହେବେ କି ନାହିଁ ।",
            "as": "কিন্তু... মই নাভাবোঁ যে আব্বুৱে ইয়াৰ খৰচ বহন কৰিব পাৰিব।",
            "gu": "પણ… મને નથી લાગતું કે અબ્બુને તે પરવડશે કે કેમ?."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Razia, you have to take what you love studying. You know so much about the environment. I can imagine you becoming like Director Ma'am at the NGO (an organisation with a social mission)",
            "hi": "रजिया, तुम्हें जो पसंद है वहीं पढ़ना चाहिए। तुम्हें पर्यावरण के बारे में कितनी जानकारी है। मुझे तो लगता है कि तुम एनजीओ (सोशल मिशन पर कार्य करनेवाली संस्था) कि डायरेक्टर मैम भी बन सकती हो।",
            "ka": "ರಝಿಯಾ, ನೀನು ಕಲಿಯಲು ಇಷ್ಟಪಡುವದನ್ನು ನೀನು ತೆಗೆದುಕೊಳ್ಳಬೇಕು. ಪರಿಸರದ ಬಗ್ಗೆ ನಿನಗೆ ತುಂಬಾ ತಿಳಿದಿದೆ. ನೀನು ಎನ್‌ಜಿಒ (ಸಾಮಾಜಿಕ ಧ್ಯೇಯವನ್ನು ಹೊಂದಿರುವ ಸಂಸ್ಥೆ) ನಲ್ಲಿರುವ ಡೈರೆಕ್ಟರ್ ಮೇಡಮ್‌ನಂತೆ ಆಗುತ್ತೀಯ ಎಂದು ನಾನು ಊಹಿಸಬಲ್ಲೆ",
            "te": "రజియా, నువ్వు చదవడానికి ఇష్టపడేదాన్ని నువ్వు తీసుకోవాలి. పర్యావరణం గురించి నీకు చాలా తెలుసు నువ్వు NGO (సామాజిక లక్ష్యంతో కూడిన సంస్థ)లో డైరెక్టర్ మేడమ్ లాగా అవ్వగలవు అని నేను నిన్ను ఊహించాను",
            "or": "ରଜିଆ, ତୁମେ ଯାହା ଅଧ୍ୟୟନ କରିବାକୁ ଭଲ ପାଅ ତାହା ନେବାକୁ ପଡିବ । ତୁମେ ପରିବେଶ ବିଷୟରେ ବହୁତ କିଛି ଜାଣ । ମୁଁ କଳ୍ପନା କରିପାରୁଛି ଯେ ତୁମେ NGOର ନିର୍ଦ୍ଦେଶକ ମାଡାମଙ୍କ ପରି ହୋଇଯାଉଛ (ଏକ ସାମାଜିକ ଲକ୍ଷ୍ୟ ଥିବା ଏକ ସଂଗଠନ)",
            "as": "ৰাজিয়া, তুমি যি পঢ়ি ভাল পোৱা সেয়া ল'ব লাগিব। তুমি পৰিৱেশৰ বিষয়ে বহুত জানা। মই কল্পনা কৰিব পাৰোঁ যে তুমি NGO (সামাজিক অভিযান থকা এটা সংগঠন)ত পৰিচালিকা বাইদেউৰ দৰে হ'বা।",
            "gu": "રઝિયા, તને જે ભણવાનું પસંદ છે તારે તે વિષય ભણવામાં પસંદ કરવો જોઈએ. તને પર્યાવરણ વિશે ઘણી જાણકારી છે. હું તે જોઈ શકું છું કે  તું ડાયરેક્ટર મેડમની જેમ એક સામાજિક સંસ્થામાં (એક સંસ્થા જે સામાજિક મુદ્દાઑ ઉપર કામ કરતી હોય) કામગીરી કરતી હોય "
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "I really really want to!",
            "hi": "मैं सच में यह करना चाहती हूँ!",
            "ka": "ನಾನು ನಿಜವಾಗಿಯೂ ಬಯಸುತ್ತೇನೆ!",
            "te": "నేను నిజంగా అలా అవ్వాలని కోరుకుంటున్నాను!",
            "or": "ମୁଁ ପ୍ରକୃତରେ ଚାହୁଁଛି!",
            "as": "মই সঁচাকৈয়ে তেনে হ'বলৈ বিচাৰো!",
            "gu": "હું ખરેખર તે બનવા માંગુ છું"
          }
        }
      ]
    },
    "scene5": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "X3_1_1",
            "hi": "X3_1_1",
            "ka": "X3_1_1",
            "te": "X3_1_1",
            "or": "X3_1_1",
            "as": "X3_1_1",
            "gu": "X3_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "On the way back, Razia decides to go to the NGO to distract herself. She loves talking to Director Ma'am at the NGO, who is also very fond of her.",
            "hi": "वापस जाते समय, रजिया ने अपना दिल बहलाने के लिए एनजीओ जाने का तय किया। उसे एनजीओ के डायरेक्टर मैम से बात करना अच्छा लगता था और मैम भी उसे पसंद करती थी।",
            "ka": "ಹಿಂತಿರುಗುವ ದಾರಿಯಲ್ಲಿ, ರಝಿಯಾ ತನ್ನ ಗಮನವನ್ನು ಬೇರೆಡೆಗೆ ಸೆಳೆಯಲು ಎನ್‌ಜಿಒಗೆ ಹೋಗಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಅವಳು ಎನ್‌ಜಿಒದಲ್ಲಿ ಡೈರೆಕ್ಟರ್ ಮೇಡಮ್‌ನೊಂದಿಗೆ ಮಾತನಾಡಲು ಇಷ್ಟಪಡುತ್ತಾಳೆ, ಅವರೂ ಅವಳನ್ನು ತುಂಬಾ ಇಷ್ಟಪಡುತ್ತಾರೆ.",
            "te": "తిరిగి వెళ్ళేటప్పుడు, రజియా తన దృష్టి మార్చుకోడానికి NGOకి వెళ్లాలని అనుకుంది. NGOలో డైరెక్టర్ మేడంతో మాట్లాడటం ఆమెకు చాలా ఇష్టం, ఆమె కూడా తనని చాలా ఇష్టపడుతుంది.",
            "or": "ଫେରିବା ବାଟରେ ରଜିଆ ନିଜକୁ ବିଭ୍ରାନ୍ତ କରିବା ପାଇଁ NGO ଯିବାକୁ ନିଷ୍ପତ୍ତି ନେଲେ । ସେ NGOର ନିର୍ଦ୍ଦେଶକ ମାଡାମଙ୍କ ସହ କଥା ହେବାକୁ ଭଲ ପାଆନ୍ତି, ଯିଏ ତାଙ୍କୁ ମଧ୍ୟ ବହୁତ ଭଲ ପାଆନ୍ତି ।",
            "as": "উভতি আহোঁতে, ৰাজিয়াই মনৰ অশান্তিখিনি দূৰ কৰিবলৈ NGO লৈ যোৱাৰ সিদ্ধান্ত লয়। তাই NGOত পৰিচালিকা বাইদেউৰ সৈতে কথা পাতি ভাল পায়, যি গৰাকীয়ে তাইক খুব মৰম কৰে।",
            "gu": "પાછા આવતી વખતે, રઝિયા પોતાનું ધ્યાન વિચલિત કરવા માટે સામાજિક સંસ્થામાંમાં જવાનું નક્કી કરે છે. તેણીને સામાજિક સંસ્થામાંના નિયામક મેડમ સાથે વાત કરવાનું પસંદ છે, જે તેણીને ખૂબ પસંદ કરે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "X3_1_2",
            "hi": "X3_1_2",
            "ka": "X3_1_2",
            "te": "X3_1_2",
            "or": "X3_1_2",
            "as": "X3_1_2",
            "gu": "X3_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The director Ma'am sees Razia and is delighted. She calls for 2 cups of chai and notices Razia is a little distracted.",
            "hi": "रजिया को देखकर डायरेक्टर मैम बहुत खुश हुई। उन्होंने 2 कप चाय मंगवाई और तभी उन्होंने नोटिस किया कि रजिया खोयी-खोयी सी लग रही है।",
            "ka": "ಡೈರೆಕ್ಟರ್ ಮೇಡಂ ರಝಿಯಾಳನ್ನು ನೋಡಿ ಖುಷಿ ಪಟ್ಟಿದ್ದಾರೆ. ಅವಳು 2 ಕಪ್ ಟೀಗೆ ಹೇಳಿದರು ಮತ್ತು ರಝಿಯಾ ಸ್ವಲ್ಪ ವಿಚಲಿತಳಾಗಿರುವುದನ್ನು ಗಮನಿಸಿದರು.",
            "te": "డైరెక్టర్ మేడమ్ రజియాను చూసి ఆనందించారు. ఆమె 2 కప్పుల చాయ్ తీసుకురమ్మంది మరియు రజియా కొంచెం బాధగా ఉండటం గమనించింది.",
            "or": "ନିର୍ଦ୍ଦେଶକ ମାଡାମ୍ ରଜିଆଙ୍କୁ ଦେଖି ଖୁସି ହୁଅନ୍ତି । ସେ 2 କପ୍ ଚା ପାଇଁ କୁହନ୍ତି ଏବଂ ଲକ୍ଷ୍ୟ କରନ୍ତି ଯେ ରଜିଆ ଟିକିଏ ବିଭ୍ରାନ୍ତ ।",
            "as": "পৰিচালিকা বাইদেউয়ে ৰাজিয়াক দেখি ভাল পালে। তেওঁ ২ কাপ চাহ আনিবলৈ কয় আৰু লক্ষ্য কৰে যে ৰাজিয়া অলপ অন্যমনস্ক হৈ আছে।",
            "gu": ".નિયામક બહેન રઝિયાને જુએ છે અને ખુશ થાય છે. તેણે બે કપ ચાનો ઓર્ડર આપ્યો અને જોયું કે રઝિયા થોડી મૂંઝવણ માં હતી."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "X3_1_3//",
            "hi": "X3_1_3//",
            "ka": "X3_1_3//",
            "te": "X3_1_3//",
            "or": "X3_1_3//",
            "as": "X3_1_3//",
            "gu": "X3_1_3//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Director Ma'am",
            "hi": "डायरेक्टर मैम",
            "ka": "ಡೈರೆಕ್ಟರ್ ಮೇಡಮ್‌",
            "te": "డైరెక్టర్ మేడమ్",
            "or": "ନିର୍ଦ୍ଦେଶକ ମାଡାମ୍",
            "as": "পৰিচালিকা বাইদেউ",
            "gu": "નિયામક બહેન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Director Ma'am",
            "hi": "डायरेक्टर मैम",
            "ka": "ಡೈರೆಕ್ಟರ್ ಮೇಡಮ್‌",
            "te": "డైరెక్టర్ మేడమ్",
            "or": "ନିର୍ଦ୍ଦେଶକ ମାଡାମ୍",
            "as": "পৰিচালিকা বাইদেউ",
            "gu": "નિયામક બહેન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "What happened Razia? You seem a little lost today.",
            "hi": "क्या हुआ रजिया? तुम आज कहा खोई हुई लग रही हो।",
            "ka": "ಏನಾಯಿತು ರಝಿಯಾ? ನೀನು ಇಂದು ಸ್ವಲ್ಪ ವಿಚಲಿತಳಾದಂತೆ ತೋರುತ್ತಿದೆ.",
            "te": "ఏమైంది రజియా? నువ్వు ఈరోజు ఏదో కోల్పోయినట్లు కనిపిస్తున్నావు.",
            "or": "ରଜିଆ କ'ଣ ହେଲା? ତୁମେ ଆଜି ଟିକିଏ ଅନ୍ୟମନସ୍କ ପରି ମନେହେଉଛି ।",
            "as": "ৰাজিয়া কি হ'ল? তোমাক আজি অলপ অন্যমনস্ক হোৱা যেন লাগিছে।",
            "gu": "શું થયું રઝિયા? આજે તમે થોડા ખોવાયેલા દેખાવો  છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Ma'am, I have only a few days to decide what I should study next, and I'm so confused.",
            "hi": "मैम, मेरे पास बस कुछ ही दिन बचे हैं यह तय करने के लिए मुझे आगे क्या पढ़ना चाहिए और मैं कन्फ्यूज हूँ।",
            "ka": "ಮೇಡಂ, ನಾನು ಮುಂದೆ ಏನು ಓದಬೇಕು ಎಂದು ನಿರ್ಧರಿಸಲು ನನಗೆ ಕೆಲವೇ ದಿನಗಳಿವೆ ಮತ್ತು ನಾನು ತುಂಬಾ ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದೇನೆ.",
            "te": "మేడమ్, నేను తరువాత ఏమి చదవాలో నిర్ణయించుకోవడానికి నాకు కొన్ని రోజులే ఉన్నాయి మరియు నాకు చాలా అయోమయంగా ఉంది.",
            "or": "ମାଡାମ୍, ମୁଁ ଏହାପରେ କ'ଣ ଅଧ୍ୟୟନ କରିବା ଉଚିତ୍ ତାହା ସ୍ଥିର କରିବାକୁ ମୋ ପାଖରେ ମାତ୍ର କିଛି ଦିନ ଅଛି, ଏବଂ ମୁଁ ବହୁତ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛି ।",
            "as": "মেডাম, মই পিছত কি পঢ়িব লাগে সিদ্ধান্ত ল'বলৈ মোৰ ওচৰত মাত্ৰ কেইদিনমান আছে, আৰু মই বৰ বিমোৰত পৰিছো।",
            "gu": "બહેન , મારી પાસે માત્ર થોડા દિવસો છે, એ નક્કી કરવા કે મારે આગળ કયો વિષય ભણવો જોઈએ, અને મને કઈ પણ ખબર નથી પડતી."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "What is the confusion? You are so fond of the environment! And science is what you love studying right?",
            "hi": "कन्फ्यूजन क्या है? तुम्हें पर्यावरण से इतना लगाव है! और तुम्हें सायन्स पढ़ना अच्छा लगता है, है ना?",
            "ka": "ಏನು ಗೊಂದಲ? ನೀನು ಪರಿಸರವನ್ನು ತುಂಬಾ ಇಷ್ಟಪಡುತ್ತೀಯ! ಮತ್ತು ವಿಜ್ಞಾನವು ನೀನು ಅಧ್ಯಯನ ಮಾಡಲು ಇಷ್ಟಪಡುತ್ತೀಯ ಅಲ್ಲವೇ?",
            "te": "అయోమయం ఏమిటి? నీకు ప్రకృతి అంటే చాలా ఇష్టం కదా! మరియు నువ్వు సైన్స్ చదవడానికే ఇష్టపడతావు కదా ?",
            "or": "କ'ଣ ଦ୍ୱନ୍ଦ୍ୱ ? ତୁମେ ପରିବେଶକୁ ବହୁତ ଭଲ ପାଅ! ଏବଂ ବିଜ୍ଞାନ ହେଉଛି ଯାହା ତୁମେ ଅଧ୍ୟୟନ କରିବାକୁ ଭଲ ପାଅ, ଠିକ୍?",
            "as": "খেলিমেলিটো কি ? তুমি পৰিৱেশক বহুত বেছি ভালপোৱা ! আৰু বিজ্ঞান হৈছে তুমি পঢ়ি ভাল পোৱা বিষয়, নহয় জানো?",
            "gu": "શું મૂંઝવણ છે? તમને પર્યાવરણમાં ખૂબ રસ છે! અને વિજ્ઞાન ભણવાનું તમને ખૂબ ગમે છે બરાબર ને?"
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "X3_1_4////",
            "hi": "X3_1_4////",
            "ka": "X3_1_4////",
            "te": "X3_1_4////",
            "or": "X3_1_4/////",
            "as": "X3_1_4////",
            "gu": "X3_1_4////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Ma'am, the financial situation at home is not very good. I would also like Shazia to get a chance to study, for which I will have to earn soon.",
            "hi": "मैम, मेरे घर की फायनांशियल सिच्युएशन ज्यादा अच्छी नहीं है। और मैं चाहती हूँ कि शाजिया को भी पढ़ने का मौका मिले, जिसके लिए मुझे जल्द ही पैसे कमाना शुरू करना होगा।",
            "ka": "ಮೇಡಂ, ಮನೆಯಲ್ಲಿ ಆರ್ಥಿಕ ಪರಿಸ್ಥಿತಿ ಚೆನ್ನಾಗಿಲ್ಲ. ಶಾಝಿಯಾಗೆ ಅಧ್ಯಯನ ಮಾಡಲು ಅವಕಾಶ ಸಿಗಬೇಕೆಂದು ನಾನು ಬಯಸುತ್ತೇನೆ, ಅದಕ್ಕಾಗಿ ನಾನು ಶೀಘ್ರದಲ್ಲೇ ಕೆಲಸಮಾಡಬೇಕಾಗಿದೆ.",
            "te": "మేడం, ఇంట్లో ఆర్థిక పరిస్థితి బాలేదు. షాజియాకు కూడా చదువుకునే అవకాశం రావాలని కోరుకుంటున్నాను,, దాని కోసం నేను త్వరగా సంపాదించాలి.",
            "or": "ମାଡାମ୍, ଘରର ଆର୍ଥିକ ସ୍ଥିତି ଭଲ ନୁହେଁ । ମୁଁ ଏହା ମଧ୍ୟ ଚାହେଁ ଯେ ଶାଜିଆ ଅଧ୍ୟୟନ କରିବାର ସୁଯୋଗ ପାଉ, ଯାହା ପାଇଁ ମୋତେ ଶୀଘ୍ର ରୋଜଗାର କରିବାକୁ ପଡିବ ।",
            "as": "মেডাম, মোৰ ঘৰৰ আৰ্থিক অৱস্থা বৰ ভাল নহয়। মই শ্বাজিয়াইয়ো পঢ়াৰ সুযোগ পোৱাটো বিচাৰো, যাৰ বাবে মই সোনকালে উপাৰ্জন কৰিব লাগিব।",
            "gu": ".મેડમ, મારા ઘરની આર્થિક સ્થિતિ બહુ સારી નથી. હું એ પણ ઈચ્છું છું કે શાઝિયાને પણ ભણવાની તક મળે, જેના માટે મારે જલ્દી કમાવું પડશે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Razia, you have to see the benefits of studying as a long term investment.\nI can understand financial difficulty, but even then there are other options for you to study and not burden your family.",
            "hi": "रजिया, तुम्हें पढ़ाई से होने वाले फायदों को लॉन्ग टर्म इन्वेस्टमेंट के तौर पर सोचना होगा।\n मैं फायनांशियल कंडिशन को समझ सकती हूँ लेकिन फिर भी अपने परिवार पर बोझ न बनते हुए पढ़ाई करने के लिए तुम्हारे पास दुसरे ऑप्शन्स हैं।",
            "ka": "ರಝಿಯಾ, ನೀನು ದೀರ್ಘಾವಧಿಯ ಹೂಡಿಕೆಯಾಗಿ ಅಧ್ಯಯನದ ಪ್ರಯೋಜನಗಳನ್ನು ನೋಡಬೇಕು.\n ನಾನು ಹಣಕಾಸಿನ ತೊಂದರೆಯನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಬಲ್ಲೆ, ಆದರೆ ನಂತರವೂ ನೀನು ಅಧ್ಯಯನ ಮಾಡಲು ಮತ್ತು ನಿನ್ನ ಕುಟುಂಬಕ್ಕೆ ಹೊರೆಯಾಗದಂತೆ ಇತರ ಆಯ್ಕೆಗಳಿವೆ.",
            "te": "రజియా, చదువుకోవడం వల్ల కలిగే ప్రయోజనాలను దీర్ఘకాలిక పెట్టుబడిగా చూడాలి. ఆర్థిక ఇబ్బందులను నేను అర్థం చేసుకోగలను, అయితే నువ్వు చదువుకోవడానికి మరియు మీ కుటుంబానికి భారం కాకుండా ఉండటానికి ఇంకా అవకాశాలు ఉన్నాయి.",
            "or": "ରଜିଆ, ତୁମକୁ ଅଧ୍ୟୟନର ଲାଭକୁ ଏକ ଦୀର୍ଘକାଳୀନ ବିନିଯୋଗ ଭାବରେ ଦେଖିବାକୁ ପଡିବ । ମୁଁ ଆର୍ଥିକ ଅସୁବିଧା ବୁଝିପାରୁଛି, କିନ୍ତୁ ତା'ପରେ ମଧ୍ୟ ତୁମ ପରିବାର ଉପରେ ବୋଝ ନହୋଇ ତୁମ ଅଧ୍ୟୟନ ପାଇଁ ଅନ୍ୟାନ୍ୟ ବିକଳ୍ପ ଅଛି ।",
            "as": "ৰাজিয়া, তুমি পঢ়াৰ লাভালাভবোৰ দীঘলীয়া সময়ৰ বাবে বিনিয়োগ হিচাপে চাব লাগিব।\n মই আৰ্থিক অসুবিধা বুজি পাওঁ, কিন্তু তেতিয়াও তোমাৰ বাবে পৰিয়ালৰ ওপৰত বোজা নপৰাকৈ পঢ়াৰ বেলেগ ব্যৱস্থা আছে ।",
            "gu": "“રઝિયા, તારે લાંબાગાળાના ફાયદા વિષે વિચારવું પડશે અને તે  પ્રમાણે અભ્યાસ કરવો પડશે. હું તારી આર્થિક મુશ્કેલી સમજી શકું છું, તેમ છતાં તમારી પાસે અભ્યાસ કરવા અને તમારા પરિવાર પર ભાર ન બનાવવા માટે અન્ય વિકલ્પો છે.\""
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "What do you mean?",
            "hi": "क्या मतलब है आपका?",
            "ka": "ನಿಮ್ಮ ಮಾತಿನ ಅರ್ಥವೇನು?",
            "te": "మీరు ఏమంటున్నారు?",
            "or": "ଆପଣ କଣ କହିବାକୁ ଚାହୁଁଛନ୍ତି?",
            "as": "আপুনি কি বুজাইছে?",
            "gu": "તમે શું કહેવા માગો છો ?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "You can choose courses and colleges where scholarships are available. There is also the option to work during the day and study in an evening college.",
            "hi": "तुम ऐसे कोर्स या कॉलेज चुन सकती हो जहाँ स्कोलरशिप दी जाती हो। और दूसरा ऑप्शन यह है कि तुम दिन में काम कर सकती हो और शाम को कॉलेज पूरा कर सकती हो।",
            "ka": "ವಿದ್ಯಾರ್ಥಿವೇತನಗಳು ಲಭ್ಯವಿರುವ ಕೋರ್ಸ್‌ಗಳು ಮತ್ತು ಕಾಲೇಜುಗಳನ್ನು ನೀನು ಆಯ್ಕೆ ಮಾಡಬಹುದು. ಹಗಲಿನಲ್ಲಿ ದುಡಿಯುವ ಮತ್ತು ಸಂಜೆ ಕಾಲೇಜಿನಲ್ಲಿ ಓದುವ ಆಯ್ಕೆಯೂ ಇದೆ.",
            "te": "నువ్వు స్కాలర్‌షిప్‌లు (ఉపకార వేతనం) ఉన్న కోర్సులు మరియు కాలేజీలను ఎంచుకోవచ్చు. పగలు పని చేసి సాయంత్రం కాలేజీలో చదువుకునే అవకాశం కూడా ఉంది.",
            "or": "ତୁମେ ଏପରି ପାଠ୍ୟକ୍ରମ ଏବଂ କଲେଜ ବାଛିପାରିବ ଯେଉଁଠାରେ ଛାତ୍ରବୃତ୍ତି ଉପଲବ୍ଧ । ଦିନରେ କାମ କରିବା ଏବଂ ସନ୍ଧ୍ୟା କଲେଜରେ ଅଧ୍ୟୟନ କରିବାର ବିକଳ୍ପ ମଧ୍ୟ ଅଛି ।",
            "as": "তুমি এনে পাঠ্যক্ৰম আৰু কলেজ বাছিব পাৰা য'ত স্কলাৰশ্বিপ পোৱা যায়। দিনত কাম কৰাৰ আৰু সন্ধিয়াৰ কলেজত পঢ়াৰ সুবিধাও আছে।",
            "gu": ".તમે જ્યાં શિષ્યવૃત્તિ ઉપલબ્ધ હોય હોય તેવા કોર્સ અને કોલેજો પસંદ કરી શકો છો. તને દિવસ દરમિયાન કામ કરવાનું અને સાંજની કૉલેજમાં અભ્યાસ કરવાનો વિકલ્પ પણ મળી શકે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "Scholarships?",
            "hi": "स्कोलरशिप?",
            "ka": "ವಿದ್ಯಾರ್ಥಿವೇತನಗಳು?",
            "te": "స్కాలర్‌షిప్‌లు? (ఉపకార వేతనాలు )",
            "or": "ଛାତ୍ରବୃତ୍ତି?",
            "as": "স্কলাৰশ্বিপ ?",
            "gu": "શિષ્યવૃત્તિ?"
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "X3_1_5//",
            "hi": "X3_1_5//",
            "ka": "X3_1_5//",
            "te": "X3_1_5//",
            "or": "X3_1_5//",
            "as": "X3_1_5//",
            "gu": "X3_1_5//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Director Ma'am",
            "hi": "डायरेक्टर मैम",
            "ka": "ಡೈರೆಕ್ಟರ್ ಮೇಡಮ್‌",
            "te": "డైరెక్టర్ మేడమ్",
            "or": "ନିର୍ଦ୍ଦେଶକ ମାଡାମ୍",
            "as": "পৰিচালিকা বাইদেউ",
            "gu": "નિયામક બહેન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "There are many organisations that fund students' college fee. For that you have to work very hard and perform well in school.",
            "hi": "ऐसी कई संस्थाएं है जो स्टूडेंट्स के कॉलेज की फी के लिए फंड देती हैं। इसके लिए तुम्हें बहुत मेहनत करनी होगी और स्कूल में अच्छा परफोर्मेंस दिखाना होगा।",
            "ka": "ವಿದ್ಯಾರ್ಥಿಗಳ ಕಾಲೇಜು ಶುಲ್ಕವನ್ನು ನೀಡುವ ಅನೇಕ ಸಂಸ್ಥೆಗಳಿವೆ. ಅದಕ್ಕಾಗಿ ನೀನು ತುಂಬಾ ಕಷ್ಟಪಟ್ಟು ಕೆಲಸ ಮಾಡಬೇಕು ಮತ್ತು ಶಾಲೆಯಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸಬೇಕು.",
            "te": "విద్యార్థుల కాలేజీ ఫీజుకు డబ్బులు ఇచ్చే సంస్థలు చాలా ఉన్నాయి. దాని కోసం నువ్వు చాలా కష్టపడి స్కూల్లో మంచిగా చదవాలి.",
            "or": "ଅନେକ ସଂଗଠନ ଅଛି ଯାହା ଛାତ୍ରଛାତ୍ରୀମାନଙ୍କ କଲେଜ ଶୁଳ୍କକୁ ପାଣ୍ଠି ପ୍ରଦାନ କରେ । ଏଥିପାଇଁ ତୁମକୁ ବହୁତ କଠିନ ପରିଶ୍ରମ କରିବାକୁ ପଡିବ ଏବଂ ବିଦ୍ୟାଳୟରେ ଭଲ ପ୍ରଦର୍ଶନ କରିବାକୁ ପଡିବ ।",
            "as": "বহুতো সংগঠন আছে যিয়ে শিক্ষাৰ্থীসকলৰ কলেজৰৰ মাচুলৰ বাবে পুঁজি যোগান ধৰে। তাৰ বাবে তুমি বহুত কঠোৰ পৰিশ্ৰম কৰিব লাগিব আৰু বিদ্যালয়ত ভাল ফল দেখুৱাব লাগিব।",
            "gu": "એવી ઘણી સંસ્થાઓ છે જે વિધ્યાર્થીઓની કોલેજ ફી પૂરી પાડે છે.  તેના માટે તમારે ખૂબ જ મહેનત કરવી પડશે અને શાળામાં સારું પ્રદર્શન કરવું પડશે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "I hadn’t thought of these options at all Ma'am! I need to spend some time doing research on this.",
            "hi": "मैंने इन ऑप्शन्स के बारे में तो सोचा ही नहीं था मैम! मुझे लगता है कि मुझे इस पर थोड़ा रिसर्च करना होगा।",
            "ka": "ನಾನು ಈ ಆಯ್ಕೆಗಳ ಬಗ್ಗೆ ಯೋಚಿಸಿರಲಿಲ್ಲ ಮೇಡಂ! ನಾನು ಈ ಬಗ್ಗೆ ಸಂಶೋಧನೆ ಮಾಡಲು ಸ್ವಲ್ಪ ಸಮಯ ಕಳೆಯಬೇಕಾಗಿದೆ.",
            "te": "నేను ఈ ఎంపికల గురించి అస్సలు ఆలోచించలేదు మేడమ్! నేను దీనిపై పరిశోధన చేయడానికి కొంత సమయం వెచ్చించాలి.",
            "or": "ମୁଁ ଏହି ବିକଳ୍ପଗୁଡ଼ିକ ବିଷୟରେ ଆଦୌ ଚିନ୍ତା କରିନଥିଲି! ମତେ ଏହା ଉପରେ ଗବେଷଣା କରିବାରେ କିଛି ସମୟ ବିତାଇବା ଆବଶ୍ୟକ ।",
            "as": "মই এই সুবিধা বোৰৰ বিষয়ে একেবাৰে চিন্তা কৰা নাছিলো! মই এই বিষয়ে চিন্তা কৰিবলৈ অলপ সময় ল'ব লাগিব।",
            "gu": "મેં આ વિકલ્પો વિશે બિલકુલ વિચાર્યું નહોતું બહેન! મારે આના પર સંશોધન કરવામાં થોડો સમય પસાર કરવાની જરૂર છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "Scholarship",
            "hi": "स्कोलरशिप",
            "ka": "ವಿದ್ಯಾರ್ಥಿವೇತನ",
            "te": "స్కాలర్‌షిప్ (ఉపకార వేతనం)",
            "or": "ଛାତ୍ରବୃତ୍ତି",
            "as": "স্কলাৰশ্বিপ",
            "gu": "શિષ્યવૃત્તિ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just learnt about scholarships!",
            "hi": "आपने अभी-अभी स्कोलरशिप के बारे में जानकारी हासिल की है!",
            "ka": "ನೀನು ಕೇವಲ ವಿದ್ಯಾರ್ಥಿವೇತನಗಳ ಬಗ್ಗೆ ಕಲಿ!",
            "te": "నువ్వు ఇప్పుడే స్కాలర్‌షిప్ (ఉపకార వేతనం) గురించి తెలుసుకున్నావు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ଛାତ୍ରବୃତ୍ତି ବିଷୟରେ ଜାଣିଲେ!",
            "as": "তুমি এইমাত্ৰ স্কলাৰশ্বিপ বিষয়ে জানিলা !",
            "gu": "તમે હમણાં જ શિષ્યવૃત્તિ વિશે શીખ્યા!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Scholarship",
            "hi": "स्कोलरशिप",
            "ka": "ವಿದ್ಯಾರ್ಥಿವೇತನ",
            "te": "స్కాలర్‌షిప్ (ఉపకార వేతనం))",
            "or": "ଛାତ୍ରବୃତ୍ତି",
            "as": "স্কলাৰশ্বিপ",
            "gu": "શિષ્યવૃત્તિ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Miscellaneous",
            "hi": "मिसलेनियस",
            "ka": "ವಿವಿಧ",
            "te": "ఇతర",
            "or": "ବିବିଧତା",
            "as": "বিবিধ",
            "gu": "પરચૂરણ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "To continue her studies, Razia can apply for financial support/grants. These grants are called scholarships. They are provided to students who want to pursue higher studies but cannot afford them. Students do not have to pay back the money they get through scholarships. To apply for scholarships, you should search based on what is eligibility. Some criteria are course, marks, age limit, family incomes, special skills etc. The process includes filling up the application, submitting credentials and giving interviews before being selected for the scholarship.",
            "hi": "अपनी पढ़ाई जारी रखने के लिए, रजिया फायनांशियल सपोर्ट/ग्रांट्स के लिए अप्लाई कर सकती है. इन ग्रांट्स को स्कोलरशिप कहते हैं। ये उन स्टूडेंट्स को दी जाती हैं, जो हायर स्टडीज करना चाहते हैं लेकिन उनके पास इतने पैसे नहीं है। स्टूडेंट्स को स्कोलरशिप के तौर पर मिलनेवाले पैसे वापस करने की जरूरत नहीं होती। स्कोलरशिप के लिए अप्लाई करने के लिए, आपको पात्रता के अनुसार सर्च करना होगा। इसके कुछ मानदंड है कोर्स, मार्क्स, आयु मर्यादा, परिवार की कमाई, विशेष कौशल आदि। इस प्रक्रिया में अॅप्लिकेशन भरना, क्रेड़ेंशियल भरना और स्कोलरशिप के लिए सिलेक्ट होने से पहने इंटरव्यू देना शामिल हैं।",
            "ka": "ತನ್ನ ಅಧ್ಯಯನವನ್ನು ಮುಂದುವರಿಸಲು, ರಝಿಯಾ ಹಣಕಾಸಿನ ನೆರವು/ಅನುದಾನಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಬಹುದು. ಈ ಅನುದಾನಗಳನ್ನು ವಿದ್ಯಾರ್ಥಿವೇತನ ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ.ಉನ್ನತ ಶಿಕ್ಷಣವನ್ನು ಪಡೆಯಲು ಬಯಸುವ ಆದರೆ ಅವುಗಳನ್ನು ಭರಿಸಲಾಗದ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಅವುಗಳನ್ನು ಒದಗಿಸಲಾಗುತ್ತದೆ. ವಿದ್ಯಾರ್ಥಿಗಳು ಸ್ಕಾಲರ್‌ಶಿಪ್ ಮೂಲಕ ಪಡೆದ ಹಣವನ್ನು ಹಿಂತಿರುಗಿಸಬೇಕಾಗಿಲ್ಲ.ವಿದ್ಯಾರ್ಥಿವೇತನಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು, ನಿಮ್ಮ ಅರ್ಹತೆ ಏನೆಂಬುದನ್ನು ಆಧರಿಸಿ ಹುಡುಕಬೇಕು.ಕೆಲವು ಮಾನದಂಡಗಳು ಕೋರ್ಸ್, ಅಂಕಗಳು, ವಯಸ್ಸಿನ ಮಿತಿ, ಕುಟುಂಬದ ಆದಾಯ, ವಿಶೇಷ ಕೌಶಲ್ಯಗಳು ಇತ್ಯಾದಿ.ಈ ಪ್ರಕ್ರಿಯೆಯು ಅರ್ಜಿಯನ್ನು ಭರ್ತಿ ಮಾಡುವುದು, ರುಜುವಾತುಗಳನ್ನು ಸಲ್ಲಿಸುವುದು ಮತ್ತು ವಿದ್ಯಾರ್ಥಿವೇತನಕ್ಕೆ ಆಯ್ಕೆಯಾಗುವ ಮೊದಲು ಸಂದರ್ಶನಗಳನ್ನು ನೀಡುವುದನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.",
            "te": "తన చదువును కొనసాగించడానికి, రజియా ఆర్థిక సహాయం/మంజూరు కోసం దరఖాస్తు చేసుకోవచ్చు. ఈ నిధులను స్కాలర్‌షిప్‌లు (ఉపకార వేతనాలు) అంటారు.ఉన్నత చదువులు చదవాలనుకున్నా ఆర్థిక స్థోమత లేని విద్యార్థులకు వీటిని అందజేస్తున్నారు. విద్యార్థులు స్కాలర్‌షిప్‌ల (ఉపకార వేతనాలు) ద్వారా పొందిన డబ్బును తిరిగి చెల్లించాల్సిన అవసరం లేదు. స్కాలర్‌షిప్‌ల (ఉపకార వేతనాలు) కోసం దరఖాస్తు చేయడానికి, మీ అర్హత ఆధారంగా శోధించాలి. కొన్ని ప్రమాణాలు కోర్సు, మార్కులు, వయోపరిమితి, కుటుంబ ఆదాయాలు, ప్రత్యేక నైపుణ్యాలు మొదలైనవి. ఈ ప్రక్రియలో దరఖాస్తును పూరించడం, ఆధారాలను సమర్పించడం మరియు స్కాలర్‌షిప్‌కు (ఉపకార వేతనం) ఎంపికయ్యే ముందు ఇంటర్వ్యూలు ఇవ్వడం వంటివి ఉంటాయి.",
            "or": "ତାଙ୍କର ଅଧ୍ୟୟନ ଜାରି ରଖିବାକୁ, ରଜିଆ ଆର୍ଥିକ ସହାୟତା/ଅନୁଦାନ ପାଇଁ ଆବେଦନ କରିପାରିବେ । ଏହି ଅନୁଦାନକୁ ଛାତ୍ରବୃତ୍ତି କୁହାଯାଏ । ସେଗୁଡିକ ଛାତ୍ରଛାତ୍ରୀମାନଙ୍କୁ ପ୍ରଦାନ କରାଯାଏ ଯେଉଁମାନେ ଉଚ୍ଚ ଅଧ୍ୟୟନ କରିବାକୁ ଚାହାଁନ୍ତି କିନ୍ତୁ ତାହା ପାଇଁ ଖର୍ଚ୍ଚ କରି ପାରିବେ ନାହିଁ । ଛାତ୍ରଛାତ୍ରୀମାନଙ୍କୁ ଛାତ୍ରବୃତ୍ତି ମାଧ୍ୟମରେ ପାଇଥିବା ଟଙ୍କା ଫେରସ୍ତ କରିବାକୁ ପଡିବ ନାହିଁ । ଛାତ୍ରବୃତ୍ତି ପାଇଁ ଆବେଦନ କରିବାକୁ, ଆପଣ ଯୋଗ୍ୟତା କ'ଣ ତାହା ଉପରେ ଆଧାର କରି ସନ୍ଧାନ କରିବା ଉଚିତ୍ । କେତେକ ମାନଦଣ୍ଡ ହେଉଛି ପାଠ୍ୟକ୍ରମ, ମାର୍କ, ବୟସ ସୀମା, ପାରିବାରିକ ଆୟ, ବିଶେଷ ଦକ୍ଷତା ଇତ୍ୟାଦି । ଏହି ପ୍ରକ୍ରିୟାରେ ଆପ୍ଲିକେସନ୍ ପୂରଣ କରିବା, ପରିଚୟପତ୍ର ଦାଖଲ କରିବା ଏବଂ ଛାତ୍ରବୃତ୍ତି ପାଇଁ ମନୋନୀତ ହେବା ପୂର୍ବରୁ ସାକ୍ଷାତକାର ଦେବା ଅନ୍ତର୍ଭୁକ୍ତ ।",
            "as": "পঢ়া কামটো চলাই নিবলৈ, ৰাজিয়া আৰ্থিক সমৰ্থন/অনুদানৰ বাবে এপ্লাই কৰিব পাৰে। এই অনুদানবোৰক স্কলাৰশ্বিপ বুলি কোৱা হয়। এই স্কলাৰশ্বিপ সেই শিক্ষাৰ্থীসকলক দিয়া হয় যিসকলে উচ্চ শিক্ষা গ্ৰহণ কৰিব বিচাৰে কিন্তু তাৰ খৰচ বহন কৰিব নোৱাৰে। শিক্ষাৰ্থীসকলে স্কলাৰশ্বিপৰ জৰিয়তে লাভ কৰা ধন ঘূৰাই দিব নালাগে। স্কলাৰশ্বিপৰ বাবে এপ্লাই কৰিবলৈ, যোগ্যতা সমূহৰ ওপৰত ভিত্তি কৰি অনুসন্ধান কৰা উচিত। ইয়াৰ কিছুমান চৰ্ত হৈছে পাঠ্যক্ৰম, নম্বৰ, বয়সৰ সীমা, পাৰিবাৰিক উপাৰ্জন, বিশেষ দক্ষতা ইত্যাদি। প্ৰক্ৰিয়াটোত অন্তৰ্ভুক্ত থাকে ফৰ্মফিলাপ কৰা, প্ৰমাণপত্ৰ দাখিল কৰা আৰু স্কলাৰশ্বিপৰ বাবে নিৰ্বাচিত হোৱাৰ বাবে ইন্টাৰভিউ দিয়া।",
            "gu": "તેણીનો અભ્યાસ ચાલુ રાખવા માટે, રઝિયા નાણાકીય સહાય/શિષ્યવૃત્તિ માટે અરજી કરી શકે છે. આ અનુદાનને શિષ્યવૃત્તિ કહેવામાં આવે છે. તે એવા વિદ્યાર્થીઓ માટે છે કે જેઓ ઉચ્ચ અભ્યાસ કરવા માંગે છે પણ તે માટે તે આર્થિક રીતે સક્ષમ નથી. વિદ્યાર્થીઓએ શિષ્યવૃત્તિ દ્વારા મેળવેલા નાણાં પાછા ચૂકવવાની જરૂર નથી. શિષ્યવૃત્તિ માટે અરજી કરવા માટે, તમારે યોગ્યતા શું છે તેના આધારે શોધ કરવી જોઈએ. માપદંડોમાં ડિગ્રી, ગ્રેડ, મહત્તમ ઉંમર, કૌટુંબિક આવક, વિશેષ કૌશલ્યો વગેરેનો સમાવેશ થાય છે. આ પ્રક્રિયામાં અરજી પૂર્ણ કરવી, પ્રમાણપત્રો જમા કરવા અને શિષ્યવૃત્તિ માટે પસંદગી થાય તે પહેલાં ઇન્ટરવ્યૂની પ્રક્રિયાનો સમાવેશ થાય છે."
          }
        }
      ]
    },
    "scene1": {
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "X1_1_2(D)",
            "hi": "X1_1_2(D)",
            "ka": "X1_1_2(D)",
            "te": "X1_1_2(D)",
            "or": "X1_1_2(D)",
            "as": "X1_1_2(D)",
            "gu": "X1_1_2(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Razia is happy after talking to Director Ma'am but she is still confused about her options.\nWhat should she do?",
            "hi": "रजिया डायरेक्टर मैम से बात करने के बाद बहुत खुशी हुई लेकिन अभी भी वह अपने ऑप्शन्स को लेकर कन्फ्यूज है। \n उसे क्या करना चाहिए?",
            "ka": "ಡೈರೆಕ್ಟರ್ ಮೇಡಮ್ ಜೊತೆ ಮಾತನಾಡಿದ ನಂತರ ರಝಿಯಾ ಖುಷಿಯಾಗಿದ್ದಾಳೆ ಆದರೆ ಅವಳು ಇನ್ನೂ ತನ್ನ ಆಯ್ಕೆಗಳ ಬಗ್ಗೆ ಗೊಂದಲದಲ್ಲಿದ್ದಾಳೆ.\n ಅವಳು ಏನು ಮಾಡಬೇಕು?",
            "te": "డైరెక్టర్ మేడమ్‌తో మాట్లాడిన తర్వాత రజియా సంతోషంగా ఉంది, కానీ ఆమె తన ఎంపికల గురించి ఇప్పటికీ గందరగోళంగా ఉంది.\n ఆమె ఏమి చేయాలి?",
            "or": "ନିର୍ଦ୍ଦେଶକ ମାଡାମଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବା ପରେ ରଜିଆ ଖୁସି କିନ୍ତୁ ସେ ତଥାପି ତାଙ୍କ ବିକଳ୍ପ ବିଷୟରେ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛନ୍ତି । ସେ କ'ଣ କରିବା ଉଚିତ୍?",
            "as": "পৰিচালক মেডামৰ সৈতে কথা পতাৰ পিছত ৰাজিয়া সুখী কিন্তু তাই এতিয়াও তাই কি বাছি লব এই বিষয়ে ভাবি বিমোৰত পৰিছে।\n তাই কি কৰা উচিত?",
            "gu": "“રઝિયા નિયામક બહેન સાથે વાત કર્યા પછી ખુશ છે પરંતુ તે હજી પણ તેના વિકલ્પો વિશે મૂંઝવણમાં છે.\nતેને શું કરવું જોઈએ?\""
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Talk to Imran dada to get his suggestion",
            "hi": "इमरान दादा से बात करके उसकी राय लेनी चाहिए",
            "ka": "ಅವರ ಸಲಹೆಯನ್ನು ಪಡೆಯಲು ಇಮ್ರಾನ್ ದಾದಾ ಅವರೊಂದಿಗೆ ಮಾತನಾಡು",
            "te": "ఇమ్రాన్ అన్నయ్య సలహా పొందడం కోసం అతనితో మాట్లాడాలి",
            "or": "ଇମ୍ରାନ ଭାଇଙ୍କ ସହ ତାଙ୍କ ପରାମର୍ଶ ପାଇବା ପାଇଁ କଥା ହେଲେ",
            "as": "ইমৰান দাদাৰ সৈতে তেখেতৰ পৰামৰ্শ পাবলৈ কথা পতা",
            "gu": "ઇમરાન દાદા સાથે વાત કરીને તેમની સલાહ લો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Go back home and reflect on her options",
            "hi": "घर वापस जा कर अपने ऑप्शन्स पर विचार करना चाहिए",
            "ka": "ಮನೆಗೆ ಹಿಂತಿರುಗಿ ಮತ್ತು ಅವಳ ಆಯ್ಕೆಗಳನ್ನು ಪ್ರತಿಬಿಂಬಿಸಿ",
            "te": "ఇంటికి తిరిగి వెళ్లి ఆమె ఎంపికల గురించి ఆలోచించాలి",
            "or": "ଘରକୁ ଫେରି ତାଙ୍କ ବିକଳ୍ପ ବିଷୟରେ ଭାବିଲେ",
            "as": "ঘৰলৈ উভতি যোৱা আৰু তাই কি বাছনি কৰিব তাৰ ওপৰত চিন্তা কৰা",
            "gu": "ઘરે પાછા જાઓ અને તેના વિકલ્પો ઉપર વિચાર કરો"
          }
        }
      ]
    },
    "scene4": {
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "X2_2_1_Talk to Imran Dada",
            "hi": "इमरान दादा से बात करना",
            "ka": "X2_2_1_ಇಮ್ರಾನ್ ದಾದಾ ಜೊತೆ ಮಾತನಾಡಿ",
            "te": "X2_2_1_ఇమ్రాన్ అన్నయ్యతో మాట్లాడాలి",
            "or": "X2_2_1_ ଇମ୍ରାନ ଭାଇଙ୍କ ସହ କଥା ହୁଅନ୍ତୁ",
            "as": "X2_2_1_ইমৰান দাদাৰ সৈতে কথা পতা",
            "gu": "X2_2_1_ઇમરાન દાદા સાથે વાત કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia and Imran go to their favourite Kulfi shop in the market.",
            "hi": "रजिया और इमरान मार्केट में अपने पसंदीदा कुल्फी शॉप पर जाते हैं।",
            "ka": "ರಝಿಯಾ ಮತ್ತು ಇಮ್ರಾನ್ ಮಾರುಕಟ್ಟೆಯಲ್ಲಿ ತಮ್ಮ ನೆಚ್ಚಿನ ಕುಲ್ಫಿ ಅಂಗಡಿಗೆ ಹೋಗುತ್ತಾರೆ.",
            "te": "రజియా, ఇమ్రాన్‌లు మార్కెట్‌లో తమకు ఇష్టమైన కుల్ఫీ దుకాణం కి వెళ్తారు.",
            "or": "ରଜିଆ ଏବଂ ଇମ୍ରାନ ବଜାରରେ ସେମାନଙ୍କର ପ୍ରିୟ କୁଲଫି ଦୋକାନକୁ ଗଲେ ।",
            "as": "ৰাজিয়া আৰু ইমৰানে বজাৰত তেওঁলোকৰ প্ৰিয় কুলফি দোকানলৈ যায়।",
            "gu": "રઝિયા અને ઈમરાન બજારમાં તેમની મનપસંદ કુલ્ફીની દુકાને જાય છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "X2_2_2",
            "hi": "X2_2_2",
            "ka": "X2_2_2",
            "te": "X2_2_2",
            "or": "X2_2_2",
            "as": "X2_2_2",
            "gu": "X2_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "After settling down with a plate each, he asks her how she is and what she has been doing. She shares with him her confusion in deciding the subjects.",
            "hi": "कुल्फी की प्लेट लेकर बैठते हुए, इमरान उससे उसका हालचाल पूछता है। वह उसे विषय चुनने में हो रहे कन्फ्यूज के बारे में बताती है।",
            "ka": "ಇಬ್ಬರು ತಟ್ಟೆಯೊಂದಿಗೆ ನೆಲೆಸಿದ ನಂತರ, ಅವನು ಅವಳನ್ನು ಕೇಳುತ್ತಾನೆ, ಅವಳು ಹೇಗಿದ್ದಾಳೆ ಮತ್ತು ಅವಳು ಏನು ಮಾಡುತ್ತಿದ್ದಳು. ವಿಷಯಗಳನ್ನು ನಿರ್ಧರಿಸುವಲ್ಲಿ ತನ್ನ ಗೊಂದಲವನ್ನು ಅವಳು ಅವನೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳುತ್ತಾಳೆ.",
            "te": "ఒక్కొక్కరు ఒక ప్లేట్‌ తీసుకుని స్థిరపడిన తర్వాత,అతను ఆమె ఎలా ఉంది మరియు ఆమె ఏమి చేస్తోంది అని అడిగాడు.సబ్జెక్ట్‌లను నిర్ణయించడంలో తన గందరగోళాన్ని ఆమె అతనితో చెప్తుంది..",
            "or": "ପ୍ରତ୍ୟେକ ଗୋଟିଏ ଗୋଟିଏ ପ୍ଲେଟ୍ ଧରିବା ପରେ, ସେ ତାଙ୍କୁ ପଚାରିଲେ ଯେ ସେ କିପରି ଅଛନ୍ତି ଏବଂ ସେ କ'ଣ କରୁଛନ୍ତି । ବିଷୟଗୁଡିକ ସ୍ଥିର କରିବାରେ ସେ ତାଙ୍କ ଦ୍ୱନ୍ଦ୍ୱ କୁ ତାଙ୍କ ସହିତ ଅଂଶୀଦାର କରିଲେ ।",
            "as": "নিজৰ বাবে এখনকৈ প্লেট লোৱাৰ পিছত, তেওঁ তাইক সুধিলে তাই কেনে আছে আৰু কি কৰি আছে। বিষয়বোৰ বাছি লোৱাত তাই যে বিমোৰত পৰিছে সেই কথা তেওঁক কলে।",
            "gu": "તેઓ પોતાની પ્લેટ લઈને સાથે બેસે છે, તેઓ એને પૂછે છે કે તે કેમ છે અને તે શું કરી રહી છે. તે પોતાની વિષયો નક્કી કરવામાં પડતી મૂંઝવણ કહે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "X2_2_3//",
            "hi": "X2_2_3//",
            "ka": "X2_2_3//",
            "te": "X2_2_3//",
            "or": "X2_2_3//",
            "as": "X2_2_3//",
            "gu": "X2_2_3//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Imran",
            "hi": "इमरान",
            "ka": "ಇಮ್ರಾನ್",
            "te": "ఇమ్రాన్",
            "or": "ଇମ୍ରାନ",
            "as": "ইমৰান",
            "gu": "ઈમરાન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Dada, I am so confused about what to take up. I really want to do science but I also want to help Abbu and you. With science, I won't be able to start earning soon.",
            "hi": "दादा, मुझे समझ नहीं आ रहा कि मुझे क्या लेना चाहिए। मुझे सच में सायन्स लेना है लेकिन मुझे अब्बू और तुम्हें मदद भी करनी है। सायन्स लेने के बाद मैं जल्दी से पैसा कमाना शुरू नहीं कर सकूंगी।",
            "ka": "ದಾದಾ, ನಾನು ಏನು ತೆಗೆದುಕೊಳ್ಳಬೇಕು ಎಂಬ ಗೊಂದಲದಲ್ಲಿದ್ದೇನೆ. ನಾನು ನಿಜವಾಗಿಯೂ ವಿಜ್ಞಾನವನ್ನು ಕಲಿಯಲು ಬಯಸುತ್ತೇನೆ ಆದರೆ ನಾನು ಅಬ್ಬು ಮತ್ತು ನಿಮಗೆ ಸಹಾಯ ಮಾಡಲೂ ಬಯಸುತ್ತೇನೆ. ವಿಜ್ಞಾನದೊಂದಿಗೆ, ನಾನು ಶೀಘ್ರದಲ್ಲೇ ಗಳಿಸಲು ಪ್ರಾರಂಭಿಸಲು ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ.",
            "te": "అన్నయ్య, నేను ఏమి తీసుకోవాలో తెలియక చాలా అయోమయంలో ఉన్నాను. నేను నిజంగా సైన్స్ చేయాలనుకుంటున్నాను, కానీ నేను నాన్నకు మరియు మీకు సహాయం చేయాలనుకుంటున్నాను. సైన్స్‌తో, నేను త్వరగా సంపాదించడం ప్రారంభించలేను.",
            "or": "ଭାଇ, ମୁଁ କ'ଣ ଗ୍ରହଣ କରିବି ସେ ବିଷୟରେ ବହୁତ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛି । ମୁଁ ପ୍ରକୃତରେ ବିଜ୍ଞାନ ପଢିବାକୁ ଚାହୁଁଛି କିନ୍ତୁ ମୁଁ ଅବୁ ଏବଂ ତୁମକୁ ମଧ୍ୟ ସାହାଯ୍ୟ କରିବାକୁ ଚାହୁଁଛି । ବିଜ୍ଞାନ ନେଇ, ମୁଁ ଶୀଘ୍ର ରୋଜଗାର ଆରମ୍ଭ କରିବାକୁ ସମର୍ଥ ହେବି ନାହିଁ ।",
            "as": "দাদা, কি বিষয় ল'ব লাগে তাক লৈ মই বহুত বিমোৰত পৰিছোঁ। মই সঁচাকৈয়ে বিজ্ঞান পঢ়িব বিচাৰোঁ কিন্তু লগতে আব্বু আৰু আপোনাক সহায় কৰিব বিচাৰোঁ। বিজ্ঞান পঢ়িলে, মই সোনকালে পইচা ঘটিব নোৱাৰিম।",
            "gu": "દાદા, હું ખૂબ મૂંઝવણમાં છું કે કયો વિષય લેવો. હું ખરેખર વિજ્ઞાન વિષય ભણવા માંગુ છું પણ હું અબ્બુ અને તમારી મદદ કરવા માંગુ છું. જો હું વિજ્ઞાન લઇશ, તો, હું જલ્દી કમાણી નહીં શરૂ કરી શકુ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Why do you worry about these things Razia, you know that Abbu and I are managing.",
            "hi": "तुम इन सब चीजों के बारे में चिंता क्यों कर रही हो रजिया, तुम्हें पता है कि अब्बू और मैं मैनेज कर रहे हैं।",
            "ka": "ಈ ವಿಷಯಗಳ ಬಗ್ಗೆ ಯಾಕೆ ಚಿಂತೆ ಮಾಡ್ತೀಯಾ ರಝಿಯಾ,ಅಬ್ಬು ಮತ್ತು ನಾನು ನಿರ್ವಹಿಸುತ್ತಿದ್ದೇವೆ ಎಂದು ನಿನಗೆ ತಿಳಿದಿದೆ.",
            "te": "ఈ విషయాల గురించి ఎందుకు చింతిస్తున్నావు రజియా, నాన్న మరియు నేను నిర్వహిస్తున్నామని నీకు తెలుసు కదా.",
            "or": "ତୁମେ କାହିଁକି ଏହି ଜିନିଷଗୁଡ଼ିକ ବିଷୟରେ ଚିନ୍ତା କରୁଛ ରଜିଆ, ତୁମେ ଜାଣ ଯେ ଅବୁ ଏବଂ ମୁଁ ସବୁକିଛି ଚଳାଉଛୁ ।",
            "as": "তুমি এই কথাবোৰ কিয় চিন্তা কৰিছা ৰাজিয়া, তুমি জানা যে আব্বু আৰু মই চম্ভালি আছো।",
            "gu": "રઝિયા તું શા માટે આ વાતોની ચિંતા  કરે છે, તને ખબર છે કે અબ્બુ અને હું આ બધુ સંભાળી લઈએ છીએ."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "But I still want to help. I know how much Ammi and Abbu are struggling.",
            "hi": "लेकिन फिर भी मैं मदद करना चाहती हूँ। मुझे पता है कि अम्मी और अब्बू कितनी मुश्किलें उठाते हैं।",
            "ka": "ಆದರೆ ನಾನು ಇನ್ನೂ ಸಹಾಯ ಮಾಡಲು ಬಯಸುತ್ತೇನೆ. ಅಮ್ಮಿ ಮತ್ತು ಅಬ್ಬು ಎಷ್ಟು ಕಷ್ಟ ಪಡ್ತಾರೆ ಅಂತ ಗೊತ್ತು.",
            "te": "అయినా కూడా నేను సహాయం చేయాలనుకుంటున్నాను. అమ్మ, నాన్న ఎంత కష్టపడుతున్నారో నాకు తెలుసు.",
            "or": "କିନ୍ତୁ ମୁଁ ତଥାପି ସାହାଯ୍ୟ କରିବାକୁ ଚାହୁଁଛି । ମୁଁ ଜାଣେ ଅମ୍ମି ଏବଂ ଅବୁ କେତେ ସଂଘର୍ଷ କରୁଛନ୍ତି ।",
            "as": "কিন্তু মই এতিয়াও সহায় কৰিব বিচাৰোঁ। মই জানো আম্মী আৰু আব্বুয়ে কিমান কষ্ট কৰি আছে।",
            "gu": "પરંતુ  હું પણ મદદ કરવા માંગુ છું. હું જાણું છું કે અમ્મી અને અબ્બુ બહુજ સંઘર્ષ કરી રહ્યા છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "X2_2_4////",
            "hi": "X2_2_4////",
            "ka": "X2_2_4////",
            "te": "X2_2_4////",
            "or": "X2_2_4/////",
            "as": "X2_2_4////",
            "gu": "X2_2_4////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "I get why you are worried. I felt the same thing when I was your age. So I did my Diploma and started working quickly as a medical staff. I learnt from doctors and nurses directly.",
            "hi": "मुझे पता है कि तुम क्यों चिंता कर रही हो। जब मैं तुम्हारी उम्र का था तब मुझे भी ऐसा ही लगता था। इसलिए मैंने डिप्लोमा किया और जल्दी से मेडिकल स्टाफ के तौर पर काम करना शुरू किया। मैंने सीधे डॉक्टर्स और नर्सेस से सीखा है।",
            "ka": "ನೀನು ಯಾಕೆ ಚಿಂತೆ ಮಾಡುತ್ತಿದಿ ಎಂದು ನನಗೆ ಅರ್ಥವಾಯಿತು. ನಾನು ನಿನ್ನ ವಯಸ್ಸಿನವನಾಗಿದ್ದಾಗ ನನಗೆ ಅದೇ ಭಾವನೆ ಇತ್ತು. ಹಾಗಾಗಿ ನಾನು ನನ್ನ ಡಿಪ್ಲೊಮಾವನ್ನು ಮಾಡಿದ್ದೇನೆ ಮತ್ತು ವೈದ್ಯಕೀಯ ಸಿಬ್ಬಂದಿಯಾಗಿ ತ್ವರಿತವಾಗಿ ಕೆಲಸ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿದೆ. ನಾನು ನೇರವಾಗಿ ವೈದ್ಯರು ಮತ್ತು ದಾದಿಯರಿಂದ ಕಲಿತಿದ್ದೇನೆ.",
            "te": "నువ్వు ఎందుకు కంగారు పడుతున్నావో నాకు అర్థమైంది. నీ వయస్సులో ఉన్నప్పుడు నేను కూడా అలానే అనుకున్నాను. కాబట్టి నేను నా డిప్లొమా చేసి త్వరగా వైద్య సిబ్బందిలో పనిచేయడం ప్రారంభించాను. నేను నేరుగా వైద్యులు మరియు నర్సుల నుండి నేర్చుకున్నాను.",
            "or": "ମୁଁ ଜାଣିଲି ତୁମେ କାହିଁକି ଚିନ୍ତିତ । ଯେତେବେଳେ ମୁଁ ତୁମ ବୟସର ଥିଲି ସେତେବେଳେ ମୁଁ ସମାନ ଅନୁଭବ କରୁଥିଲି । ତେଣୁ ମୁଁ ମୋର ଡିପ୍ଲୋମା କରିଥିଲି ଏବଂ ଡାକ୍ତରୀ କର୍ମଚାରୀ ଭାବରେ ଶୀଘ୍ର କାମ କରିବା ଆରମ୍ଭ କଲି । ମୁଁ ସିଧାସଳଖ ଡାକ୍ତର ଏବଂ ନର୍ସଙ୍କଠାରୁ ଶିଖିଲି ।",
            "as": "তুমি কিয় চিন্তিত মই গম পালোঁ। মই যেতিয়া তোমাৰ বয়সৰ আছিলো তেতিয়াও মইও এনেকৈয়ে ভাবিছিলো। সেয়েহে মই মোৰ ডিপ্লোমা কৰিছিলো আৰু চিকিৎসা কৰ্মচাৰী হিচাপে দ্ৰুততাৰে কাম কৰিবলৈ আৰম্ভ কৰিছিলো। মই চিকিৎসক আৰু নাৰ্চৰ পৰা পোনপটীয়াকৈ শিকিছিলো।",
            "gu": "મને ખબર છે કે તું શા માટે ચિંતિત છે. જ્યારે હું તારી ઉંમરનો હતો ત્યારે મને પણ તારા જેવુ જ લાગ્યું હતું. તેથી મેં  ડિપ્લોમા કર્યું અને મેડિકલ સ્ટાફ તરીકે ઝડપથી કામ કરવાનું શરૂ કર્યું. મેં સીધું જ ડૉક્ટરો અને નર્સો પાસેથી શીખ્યો."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Yes Dada, but I don't want to be a paramedic like you. It doesn’t interest me.",
            "hi": "हाँ दादा, लेकिन मुझे तुम्हारी तरह पैरामेडिक नहीं बनना है। मुझे इसमें इंटरेस्ट नहीं है।",
            "ka": "ಹೌದು ದಾದಾ, ಆದರೆ ನಾನು ನಿಮ್ಮಂತೆ ವೈದ್ಯಕೀಯ ಸಿಬ್ಬಂದಿಯಾಗಲು ಬಯಸುವುದಿಲ್ಲ. ಇದು ನನಗೆ ಆಸಕ್ತಿಯಿಲ್ಲ.",
            "te": "అవును అన్నయ్య, కానీ నేను మీలాగా పారామెడిక్‌గా ఉండాలనుకోవడం లేదు. అది నాకు ఇష్టం లేదు.",
            "or": "ହଁ ଭାଇ, କିନ୍ତୁ ମୁଁ ତୁମ ପରି ପାରାମେଡିକ୍ ହେବାକୁ ଚାହେଁ ନାହିଁ । ମୁଁ ଏଥିରେ ଆଗ୍ରହୀ ନୁହେଁ ।",
            "as": "হয় দাদা, কিন্তু মই তোমাৰ দৰে পেৰামেডিক হ'ব নিবিচাৰো। এইটো মই সিমান ভাল নাপাওঁ ।",
            "gu": "હા દાદા, પણ મારે તમારા જેવા પેરામેડિકલ  નથી બનવું. મને તેમાં રસ નથી"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "No no, that’s not the only Diploma Course available. There are so many options there for you.",
            "hi": "नहीं, नहीं, बस यहीं एक डिप्लोमा कोर्स उपलब्ध नहीं है। तुम्हारे पास कई ऑप्शन्स हैं।",
            "ka": "ಇಲ್ಲ ಇಲ್ಲ, ಇದು ಲಭ್ಯವಿರುವ ಏಕೈಕ ಡಿಪ್ಲೊಮಾ ಕೋರ್ಸ್ ಅಲ್ಲ. ನಿನಗೆ ಹಲವು ಆಯ್ಕೆಗಳಿವೆ.",
            "te": "కాదు కాదు, అందుబాటులో ఉన్న డిప్లొమా కోర్సు అది మాత్రమే కాదు. మీ కోసం చాలా ఎంపికలు ఉన్నాయి..",
            "or": "ନା, ତାହା ଉପଲବ୍ଧ ଏକମାତ୍ର ଡିପ୍ଲୋମା ପାଠ୍ୟକ୍ରମ ନୁହେଁ । ତୁମ ପାଇଁ ଅନେକ ବିକଳ୍ପ ଅଛି ।",
            "as": "নহয়, অকল এইটোৱেই যে একমাত্ৰ ডিপ্লোমা পাঠ্যক্ৰম আছে তেনে নহয়। তোমাৰ বাবে অন্য বহুতো সুবিধা আছে।",
            "gu": "ના ના, એવું નથી કે એકમાત્ર ડિપ્લોમા કોર્સ ઉપલબ્ધ છે. તારા માટે ત્યાં ઘણા બધા વિકલ્પો છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Really? I didn’t know.",
            "hi": "सच में? मुझे पता नहीं था।",
            "ka": "ನಿಜವಾಗಿಯೂ? ನನಗೆ ಗೊತ್ತಿರಲಿಲ್ಲ.",
            "te": "నిజంగానా? నాకు తెలియదు.",
            "or": "ପ୍ରକୃତରେ? ମୁଁ ଜାଣି ନଥିଲି ।",
            "as": "হয় নেকি? মই জনা নাছিলো।",
            "gu": "ખરેખર? મને એ ખબર ન હતી."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "Yes! And then you can start working as soon as you are done.",
            "hi": "हाँ! और फिर जैसे तुम्हारी पढ़ाई खत्म हो जाए तुम काम करना शुरू कर सकती हो।",
            "ka": "ಹೌದು! ತದನಂತರ ನೀನು ಇದನ್ನು ಮುಗಿಸಿದ ತಕ್ಷಣ ನೀನು ಕೆಲಸವನ್ನು ಪ್ರಾರಂಭಿಸಬಹುದು.",
            "te": "అవును! అది నువ్వు పూర్తి చేసిన వెంటనే పని చేయడం ప్రారంభించవచ్చు.",
            "or": "ହଁ! ଏବଂ ତା'ପରେ ତୁମେ ସମାପ୍ତ ହେବା ମାତ୍ରେ କାମ କରିବା ଆରମ୍ଭ କରିପାରିବ ।",
            "as": "হয়! আৰু তাৰ পিছত তুমি তোমাৰ পঢ়া শেষ হোৱাৰ লগে লগে কাম আৰম্ভ কৰিব পাৰিবা।",
            "gu": "હા! અને એ પૂર્ણ થયા પછી તું તરત જ કામ કરવાનું શરૂ કરી શકો છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "X2_3_1",
            "hi": "X2_3_1",
            "ka": "X2_3_1",
            "te": "X2_3_1",
            "or": "X2_3_1",
            "as": "X2_3_1",
            "gu": "X2_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia along with Imran dada go to the vocational training institute (a place that focus on teaching skill based courses) to find out more about Diploma Courses available there.",
            "hi": "इमरान दादा के साथ रजिया वोकेशनल ट्रेनिंग इंस्टीट्यूट (ऐसी जगह जहाँ कौशल पर आधारित कोर्सेस सिखाने पर ध्यान दिया जाता है) जाती है और वहाँ कौन-कौन से डिप्लोमा कोर्सेस हैं यह पता करते हैं।",
            "ka": "ಇಮ್ರಾನ್ ದಾದಾ ಜೊತೆಗೆ ರಝಿಯಾ ಅಲ್ಲಿ ಲಭ್ಯವಿರುವ ಡಿಪ್ಲೊಮಾ ಕೋರ್ಸ್‌ಗಳ ಕುರಿತು ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ವೃತ್ತಿಪರ ತರಬೇತಿ ಸಂಸ್ಥೆಗೆ (ಕೌಶಲ್ಯ ಆಧಾರಿತ ಕೋರ್ಸ್‌ಗಳನ್ನು ಕಲಿಸುವ ಸ್ಥಳ) ಗೆ ಹೋಗುತ್ತಾಳೆ.",
            "te": "ఇమ్రాన్ అన్నయ్యతో పాటు రజియా అక్కడ అందుబాటులో ఉన్న డిప్లొమా కోర్సుల గురించి మరింత తెలుసుకోవడానికి వొకేషనల్ ట్రైనింగ్ ఇన్స్టిట్యూట్ కు (వృత్తి విద్యా శిక్షణా సంస్థ) వెళతారు.",
            "or": "ଇମ୍ରାନ ଭାଇଙ୍କ ସହ ରଜିଆ ଧନ୍ଦାମୂଳକ ତାଲିମ ପ୍ରତିଷ୍ଠାନ (ଏକ ସ୍ଥାନ ଯାହା ଦକ୍ଷତା ଆଧାରିତ ପାଠ୍ୟକ୍ରମ ଶିକ୍ଷା ଉପରେ ଧ୍ୟାନ ଦିଏ) କୁ ଯାଇ ସେଠାରେ ଉପଲବ୍ଧ ଡିପ୍ଲୋମା ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ଅଧିକ ଜାଣିବା ପାଇଁ ଯାଆନ୍ତି ।",
            "as": "ৰাজিয়াই ইমৰান দাদাৰ সৈতে বৃত্তিমূলক প্ৰশিক্ষণ প্ৰতিষ্ঠানলৈ (দক্ষতা আধাৰিত পাঠ্যক্ৰম শিকোৱাৰ ওপৰত গুৰুত্ব দিয়া স্থান) তাত থকা ডিপ্লোমা পাঠ্যক্ৰমৰ বিষয়ে ভালদৰে জানিবলৈ যায়।",
            "gu": " રઝિયા ઇમરાન દાદા(મોટાભાઈ ને દાદા કહે છે ) સાથે વ્યાવસાયિક પ્રશિક્ષણ સંસ્થામાં ઉપલબ્ધ ડિપ્લોમા અભ્યાસક્રમો વિશે વધુ જાણવા માટે જાય છે. (એક જગ્યા કે જે કૌશલ્ય આધારિત અભ્યાસક્રમો શીખવવા પર ધ્યાન કેન્દ્રિત કરે છે"
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "X2_3_2",
            "hi": "X2_3_2",
            "ka": "X2_3_2",
            "te": "X2_3_2",
            "or": "X2_3_2",
            "as": "X2_3_2",
            "gu": "X2_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia comes back with a lot of information. Now she has a lot to think about.",
            "hi": "रजिया को काफी जानकारी मिलती हैं। अब उसे बहुत से ऑप्शन्स के बारे में सोचना होगा।",
            "ka": "ರಝಿಯಾ ಸಾಕಷ್ಟು ಮಾಹಿತಿಯೊಂದಿಗೆ ಹಿಂತಿರುಗುತ್ತಾಳೆ. ಈಗ ಅವಳು ಯೋಚಿಸಲು ಬಹಳಷ್ಟು ಇದೆ.",
            "te": "రజియా చాలా సమాచారంతో తిరిగి వస్తుంది. ఇప్పుడు ఆమె చాలా ఆలోచించాల్సింది ఉంది.",
            "or": "ରଜିଆ ଅନେକ ସୂଚନା ସହିତ ଫେରି ଆସନ୍ତି । ବର୍ତ୍ତମାନ ତାଙ୍କ ପାଖରେ ଚିନ୍ତା କରିବାକୁ ବହୁତ କିଛି ଅଛି ।",
            "as": "ৰাজিয়াই বহুতো তথ্য লৈ উভতি আহে। এতিয়া তাইৰ বহুত চিন্তা কৰিবলগীয়া আছে।",
            "gu": "રઝિયા ઘણી બધી માહિતી લઈને પાછી આવે છે. હવે તેની પાસે ઘણું બધું વિચારવા માટે માહિતી છે."
          }
        }
      ]
    },
    "scene6": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "X4_1_1_Go home and reflect",
            "hi": "घर जाएं और विचार करें",
            "ka": "X4_1_1_ಮನೆಗೆ ಹೋಗಿ ಪ್ರತಿಬಿಂಬಿಸಿ",
            "te": "X4_1_1_ఇంటికి వెళ్లి ప్రతిబింబించండి",
            "or": "X4_1_1_ଘରକୁ ଯାଆନ୍ତି ଏବଂ ତର୍ଜମା କରନ୍ତି",
            "as": "X4_1_1_ঘৰলৈ যাওক আৰু বিবেচনা কৰক",
            "gu": "X4_1_1_ઘરે જાઓ અને મનન કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "When Razia reaches home that day she takes the form out of her bag and sits outside her house. Her mother is drying fish in the sun.",
            "hi": "उस दिन जब रजिया अपने घर गई, उसने अपने बैग से फॉर्म बाहर निकाला और अपने घर के बाहर जाकर बैठी। उसकी माँ धूप में मछली सुखा रही थी।",
            "ka": "ಆ ದಿನ ರಝಿಯಾ ಮನೆಗೆ ಬಂದಾಗ ಅವಳು ತನ್ನ ಚೀಲದಿಂದ ಅರ್ಜಿಯನ್ನು ತೆಗೆದುಕೊಂಡು ತನ್ನ ಮನೆಯ ಹೊರಗೆ ಕುಳಿತಳು. ಆಕೆಯ ತಾಯಿ ಬಿಸಿಲಿನಲ್ಲಿ ಮೀನು ಒಣಗಿಸುತ್ತಿದ್ದಾರೆ.",
            "te": "ఆ రోజు ఇంటికి చేరుకున్న తర్వాత రజియా తన బ్యాగ్ లోంచి ఫారమ్ తీసుకొని తన ఇంటి బయట కూర్చుంది. ఆమె తల్లి చేపలను ఎండబెడుతోంది.",
            "or": "ଯେତେବେଳେ ରଜିଆ ସେଦିନ ଘରେ ପହଞ୍ଚିଲେ ସେ ତାଙ୍କ ବ୍ୟାଗରୁ ଫର୍ମ ବାହାର କରି ତାଙ୍କ ଘର ବାହାରେ ବସିଲେ । ତାଙ୍କ ମା ଖରାରେ ମାଛ ଶୁଖାଉଥିଲେ ।",
            "as": "যেতিয়া ৰাজিয়া সেইদিনা ঘৰলৈ আহে তেতিয়া তাই নিজৰ বেগৰ পৰা ফৰ্মখন উলিয়াই লয় আৰু ঘৰৰ বাহিৰত বহে। তাইৰ মাকে ৰ'দত মাছ শুকুৱাই আছে।",
            "gu": "તે દિવસે જ્યારે રઝિયા ઘરે પહોંચે છે ત્યારે તે તેની થેલીમાંથી ફોર્મ બહાર કાઢે છે અને તેના ઘરની બહાર બેસી જાય છે. તેની માતા તડકામાં માછલી સૂકવી રહી છે"
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "X4_1_2//",
            "hi": "X4_1_2//",
            "ka": "X4_1_2//",
            "te": "X4_1_2//",
            "or": "X4_1_2//",
            "as": "X4_1_2//",
            "gu": "X4_1_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Ammi",
            "hi": "अम्मी",
            "ka": "ಅಮ್ಮಿ",
            "te": "అమ్మ",
            "or": "ଅମ୍ମି",
            "as": "আম্মী",
            "gu": "અમ્મી"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Why are you sitting there alone? What are you thinking about?",
            "hi": "तुम यहाँ अकेली क्यों बैठी हो? क्या सोच रही हो?",
            "ka": "ನೀನೇಕೆ ಅಲ್ಲಿ ಒಬ್ಬಂಟಿಯಾಗಿ ಕುಳಿತಿರುವೆ? ನೀನು ಯಾವುದರ ಬಗ್ಗೆ ಚಿಂತಿಸುತ್ತಿರುವೆ?",
            "te": "ఎందుకు ఒంటరిగా కూర్చున్నావు? నువ్వు దేని గురించి ఆలోచిస్తున్నావు?",
            "or": "ତୁମେ କାହିଁକି ସେଠାରେ ଏକୁଟିଆ ବସିଛ? କ'ଣ ଚିନ୍ତା କରୁଛ?",
            "as": "তুমি তাত অকলে কিয় বহি আছা? তুমি কি চিন্তা কৰি আছা?",
            "gu": "તું ત્યાં એકલી કેમ બેઠી છે? તું શેના વિશે વિચારી રહી છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Nothing Ammi, I need to decide what subject to take up in 11th and 12th, but I don't know what to do. I really want to do science.",
            "hi": "कुछ नहीं अम्मी, 11 वीं और 12 वीं में कौन से विषय लेने हैं इसका चुनाव करना हैं, लेकिन मुझे समझ नहीं आ रहा कि मैं क्या करूं। मुझे सच में सायन्स लेना है।",
            "ka": "ಏನಿಲ್ಲ ಅಮ್ಮಿ, ೧೧ ಮತ್ತು ೧೨ ನೇ ತರಗತಿಯಲ್ಲಿ ಯಾವ ವಿಷಯವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕೆಂದು ನಾನು ನಿರ್ಧರಿಸಬೇಕು, ಆದರೆ ಏನು ಮಾಡಬೇಕೆಂದು ನನಗೆ ತಿಳಿದಿಲ್ಲ. ನಾನು ನಿಜವಾಗಿಯೂ ವಿಜ್ಞಾನ ಕಲಿಯಲು ಬಯಸುತ್ತೇನೆ",
            "te": "ఏమీ లేదు అమ్మ, 11, 12వ లో ఏ సబ్జెక్ట్ తీసుకోవాలో నిర్ణయించుకోవాలి, కానీ నాకు ఏం చేయాలో తెలియడం లేదు. నాకు నిజానికి సైన్స్ చేయాలని ఉంది.",
            "or": "କିଛି ନୁହେଁ ଅମ୍ମି , ମୁଁ 11 ଏବଂ ଦ୍ୱାଦଶରେ କେଉଁ ବିଷୟ ଗ୍ରହଣ କରିବାକୁ ହେବ ତାହା ସ୍ଥିର କରିବା ଆବଶ୍ୟକ, କିନ୍ତୁ ମୁଁ କ'ଣ ନେବି ଜାଣେ ନାହିଁ । ମୁଁ ପ୍ରକୃତରେ ବିଜ୍ଞାନ ନେବାକୁ ଚାହୁଁଛି ।",
            "as": "একো নহয় আম্মী, মই সিদ্ধান্ত ল'ব লাগিব যে 11 আৰু 12 শ্ৰেণীত কি বিষয় গ্ৰহণ কৰিব লাগিব, কিন্তু মই কি কৰিব লাগে নাজানো। মই সঁচাকৈয়ে বিজ্ঞান পঢ়িব বিচাৰো।",
            "gu": "કાઈ નહીં અમ્મી, મારે 11 અને 12માં ધોરણમાં કયો વિષય લેવાનો છે તે નક્કી કરવા વિષે વિચારું છું, પણ મને ખબર નથી કે મારે શું કરવું. ખરેખર મારે વિજ્ઞાન વિષય ભણવો છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "x4_1_3////",
            "hi": "x4_1_3////",
            "ka": "x4_1_3////",
            "te": "x4_1_3////",
            "or": "x4_1_3////",
            "as": "x4_1_3////",
            "gu": "x4_1_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Razia, I know you have always worked hard in school, but you know the situation at home. Imran and your Abbu are working hard. There are loans to pay and money to save up for both yours and Shazia’s weddings.",
            "hi": "रजिया, मुझे पता है कि तुम पढ़ाई में बहुत मेहनत करती हो, लेकिन तुम्हें पता है घर पर क्या हालात हैं। इमरान और तुम्हारे अब्बू कितनी मेहनत करते हैं। कर्ज चुकाने हैं और फिर तुम्हारी और शाजिया की शादी के लिए पैसे बचाने हैं।",
            "ka": "ರಝಿಯಾ, ನೀನು ಶಾಲೆಯಲ್ಲಿ ಯಾವಾಗಲೂ ಕಷ್ಟಪಟ್ಟು ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೀ ಎಂದು ನನಗೆ ತಿಳಿದಿದೆ, ಆದರೆ ಮನೆಯ ಪರಿಸ್ಥಿತಿ ನಿನಗೆ ತಿಳಿದಿದೆ. ಇಮ್ರಾನ್ ಮತ್ತು ನಿನ್ನ ಅಬ್ಬು ಕಷ್ಟಪಟ್ಟು ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾರೆ.ಪಾವತಿಸಲು ಸಾಲಗಳಿವೆ ಮತ್ತು ನಿನ್ನ ಹಾಗು ಶಾಝಿಯಾಳ ಮದುವೆಗಳಿಗೆ ಹಣವನ್ನು ಉಳಿಸಬೇಕಾಗಿದೆ.",
            "te": "రజియా, నువ్వు స్కూల్లో ఎప్పుడూ కష్టపడి చదువుతావని నాకు తెలుసు, కానీ ఇంట్లో పరిస్థితి నీకు తెలుసు కదా. ఇమ్రాన్ మరియు మీ నాన్న చాలా కష్టపడుతున్నారురుణాలు చెల్లించాల్సినవి ఉన్నాయి మరియు నీది, షాజియా వివాహం కోసం కూడా డబ్బు ఆదా చేయాలి.",
            "or": "ରଜିଆ, ମୁଁ ଜାଣେ ତୁମେ ସର୍ବଦା ବିଦ୍ୟାଳୟରେ କଠିନ ପରିଶ୍ରମ କରିଛ, କିନ୍ତୁ ତୁମେ ଘରର ପରିସ୍ଥିତି ଜାଣ । ଇମ୍ରାନ ଏବଂ ତୁମର ଅବୁ କଠିନ ପରିଶ୍ରମ କରୁଛନ୍ତି । ଋଣ ଦେୟ କରିବା ପାଇଁ ଏବଂ ତୁମର ଏବଂ ଶାଜିଆଙ୍କ ବିବାହ ପାଇଁ ଟଙ୍କା ସଞ୍ଚୟ କରିବାକୁ ଅଛି ।",
            "as": "ৰাজিয়া, মই জানো তুমি সদায় স্কুলত বহুত কষ্ট কৰি পঢ়িছা, কিন্তু তুমি ঘৰৰ পৰিস্থিতি জানা। ইমৰান আৰু তোমাৰ আব্বুয়ে বহুত কষ্ট কৰি আছে। আমাৰ ঋণ পৰিশোধ কৰিবলৈ আছে আৰু তোমাৰ আৰু শ্বাজিয়াৰ বিয়াৰ বাবে টকা জমা কৰিবলৈ আছে।",
            "gu": "રઝિયા, હું જાણું છું કે તે શાળામાં હંમેશા સખત મહેનત કરી છે, પણ ઘરની પરિસ્થિતિની તને ખબર છે. ઈમરાન અને તારા અબ્બુ સખત મહેનત કરે છે. આપણે લોન પણ ચૂકવવાની છે અને તારા અને શાજીયાના લગ્ન માટે પૈસા પણ ભેગા કરવાના છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "But Ammi how are my studies and my marriage connected.",
            "hi": "लेकिन अम्मी मेरी पढ़ाई और शादी में क्या संबंध है?",
            "ka": "ಆದರೆ ಅಮ್ಮಿ ನನ್ನ ಓದು ಮತ್ತು ನನ್ನ ಮದುವೆ ಹೇಗೆ ಸಂಬಂಧಿಸಿದೆ.",
            "te": "కానీ అమ్మ నా చదువుకి, పెళ్లికి సంబంధం ఏమిటి.",
            "or": "କିନ୍ତୁ ଅମ୍ମି ମୋର ଅଧ୍ୟୟନ ଏବଂ ମୋର ବିବାହ କିପରି ସଂଯୁକ୍ତ ।",
            "as": "কিন্তু আম্মী মোৰ পঢ়া শুনা আৰু মোৰ বিয়া কেনেকৈ লগ হৈ আছে।",
            "gu": "પણ અમ્મી મારો અભ્યાસ અને મારા લગ્ન કેવી રીતે જોડાયેલા છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Leave that! Why don't you do a course like how your dada did?",
            "hi": "छोडो यह सब! तुम अपने दादा की तरह कोई कोर्स क्यों नहीं करती?",
            "ka": "ಅದನ್ನು ಬಿಡು! ನಿನ್ನ ದಾದಾ ಮಾಡಿದಂತಹ ಕೋರ್ಸ್ ಅನ್ನು ನೀನೇಕೆ ಮಾಡಬಾರದು?",
            "te": "అది వదిలెయ్! మీ అన్నయ్య చేసిన కోర్సును నువ్వు ఎందుకు చేయకూడదు?",
            "or": "ଛାଡ ତାକୁ! ତୁମର ଭାଇ କଲା ପରି ତୁମେ କାହିଁକି ଏକ ପାଠ୍ୟକ୍ରମ କରୁନାହଁ?",
            "as": "সেইটো বাদ দিয়া! তোমাৰ দাদাই কৰা পাঠ্যক্ৰমৰ দৰে এটা পাঠ্যক্ৰম তুমিও কিয় নকৰা?",
            "gu": "તેને છોડ! તું તારા દાદાની જેમ કોર્સ કેમ નથી કરતી?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "You mean a Diploma?",
            "hi": "मतलब डिप्लोमा?",
            "ka": "ನಿಮ್ಮ ಪ್ರಕಾರ ಡಿಪ್ಲೊಮಾ?",
            "te": "మీ ఉద్దేశ్యం డిప్లొమా?",
            "or": "ତୁମେ ଏକ ଡିପ୍ଲୋମା କଥା କହିବାକୁ ଚାହୁଁଛ କି?",
            "as": "আপুনি ডিপ্লোমাৰ কথা কৈছে?",
            "gu": "તમારો મતલબ ડિપ્લોમા કરવાનો?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "Yes, that way you can start working until we look for a boy for you. This would help Abbu also.",
            "hi": "हाँ, इस तरह से तुम तबै तक नौकरी कर सकती हो जब तक हम तुम्हारे लिए कोई लड़का ना ढूंढ लें। इससे तुम्हारे अब्बू को भी मदद हो जाएगी।",
            "ka": "ಹೌದು, ನಾವು ನಿನಗಾಗಿ ಹುಡುಗನನ್ನು ಹುಡುಕುವವರೆಗೂ ನೀನು ಕೆಲಸ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಬಹುದು. ಇದು ಅಬ್ಬೂಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
            "te": "అవును, మేము నీ కోసం అబ్బాయిని వెతికే వరకు నువ్వు పని చేయవచ్చు. ఇది నాన్నకు కూడా ఉపయోగపడుతుంది.",
            "or": "ହଁ, ସେହି ଉପାୟରେ ଆମେ ତୁମ ବିବାହ ପାଇଁ ଏକ ପ୍ରାର୍ଥୀ ଖୋଜିବା ପର୍ଯ୍ୟନ୍ତ ତୁମେ କାମ କରିପାରିବ । ଏହା ଅବୁଙ୍କୁ ମଧ୍ୟ ସାହାଯ୍ୟ କରିବ ।",
            "as": "হয়, আমি তোমাৰ বাবে ল'ৰা এটা বিচৰালৈকে তুমি কাম কৰিব পাৰা। এইটোৱে আব্বুকো সহায় কৰিব।",
            "gu": "હા, અમે તારા માટે છોકરો શોધીએ ત્યાં સુધી તું આ રીતે કામ કરવાનું શરૂ કરી શકે છે. આનાથી અબ્બુને પણ મદદ મળશે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.5.text",
          "value": {
            "en": "Hmmm",
            "hi": "हम्म",
            "ka": "ಹಾಂ",
            "te": "హా",
            "or": "ହଁ",
            "as": "হুমমম",
            "gu": "બરાબર"
          }
        }
      ]
    },
    "scene7": {
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "X5_1_1",
            "hi": "X5_1_1",
            "ka": "X5_1_1",
            "te": "X5_1_1",
            "or": "X5_1_1",
            "as": "X5_1_1",
            "gu": "X5_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia sits in her room that night, pulls up her diary and writes Positives and Negatives for Science and Diploma Courses.",
            "hi": "रजिया रात को अपने कमरे में बैठी थी, उसने अपनी डायरी बाहर निकाली और सायन्स और डिप्लोमा कोर्सेस के बारे में पोजिटिव और निगेटिव पॉइंट्स लिखने लगी।",
            "ka": "ಆ ರಾತ್ರಿ ರಝಿಯಾ ತನ್ನ ಕೋಣೆಯಲ್ಲಿ ಕುಳಿತು ತನ್ನ ಡೈರಿಯನ್ನು ಎಳೆಯುತ್ತಾಳೆ ಮತ್ತು ವಿಜ್ಞಾನ ಮತ್ತು ಡಿಪ್ಲೋಮಾ ಕೋರ್ಸ್‌ಗಳಿಗೆ ಧನಾತ್ಮಕ ಮತ್ತು ನಕಾರಾತ್ಮಕತೆಯನ್ನು ಬರೆಯುತ್ತಾಳೆ.",
            "te": "ఆ రాత్రి రజియా తన గదిలో కూర్చుని, తన డైరీని తీసి సైన్స్ మరియు డిప్లొమా కోర్సులకు పాజిటివ్‌లు మరియు నెగెటివ్‌లు (సానుకూల మరియు ప్రతికూలతలు) రాసింది.",
            "or": "ରଜିଆ ସେହି ରାତିରେ ତାଙ୍କ କୋଠରୀରେ ବସି ତାଙ୍କ ଡାଏରୀ ଆଣନ୍ତି ଏବଂ ବିଜ୍ଞାନ ଏବଂ ଡିପ୍ଲୋମା ପାଠ୍ୟକ୍ରମ ପାଇଁ ସକରାତ୍ମକ ଏବଂ ନକାରାତ୍ମକ ବିଷୟରେ ଲେଖନ୍ତି ।",
            "as": "ৰাজিয়াই সেই নিশা তাইৰ কোঠাত বহি, তাইৰ ডায়েৰীখন টানি আনে আৰু বিজ্ঞান আৰু ডিপ্লোমা পাঠ্যক্ৰমৰ ভাল আৰু বেয়া দিশ বোৰ লিখে।",
            "gu": "રઝિયા તે રાત્રે તેના રૂમમાં બેસીને, તેની ડાયરીમાં વિજ્ઞાન અને ડિપ્લોમા અભ્યાસક્રમ વિશેના હકારાત્મક અને નકારાત્મક પાસાઓ લખે છે."
          }
        }
      ],
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "X5_1_2_Reveal",
            "hi": "X5_1_2_दिखाएं",
            "ka": "X5_1_2_ಬಹಿರಂಗಪಡಿಸಿ",
            "te": "X5_1_2_చెప్పడం (వెల్లడి చేయడం",
            "or": "X5_1_2_ ପ୍ରକାଶିତ କରିବା",
            "as": "X5_1_2_প্ৰকাশ কৰক",
            "gu": "X5_1_2_પ્રગટ કરવું"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Click to reveal the Positives and Negatives of taking Diploma Course after 10th",
            "hi": "10 वीं के बाद डिप्लोमा कोर्स करने के पोजिटिव और निगेटिव पॉइंट्स दिखाएं",
            "ka": "೧೦ನೇ ತರಗತಿಯ ನಂತರ ಡಿಪ್ಲೊಮಾ ಕೋರ್ಸ್ ತೆಗೆದುಕೊಳ್ಳುವ ಧನಾತ್ಮಕ ಮತ್ತು ಋಣಾತ್ಮಕ ಅಂಶಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ",
            "te": "10వ తరగతి తర్వాత డిప్లొమా కోర్సు తీసుకోవడం వల్ల వచ్చే పాజిటివ్‌లు మరియు నెగెటివ్‌ (సానుకూల మరియు ప్రతికూలతలు) లను తెలుసుకోడానికి క్లిక్ చేయండి (నొక్కండి)",
            "or": "ଦଶମ ପରେ ଡିପ୍ଲୋମା ପାଠ୍ୟକ୍ରମ ନେବାର ସକରାତ୍ମକ ଏବଂ ନକାରାତ୍ମକ ପ୍ରକାଶିତ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ",
            "as": "দশম শ্ৰেণীৰ পিছত ডিপ্লোমা পাঠ্যক্ৰমৰ ভাল আৰু বেয়া দিশ বোৰ জানিবলৈ ক্নিক কৰক",
            "gu": "10માં પછી ડિપ્લોમા કોર્સ લેવાના ફાયદા અને ગેરફાયદા જાણવા માટે ક્લિક કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Positive",
            "hi": "पोजिटिव",
            "ka": "ಧನಾತ್ಮಕ",
            "te": "పాజిటివ్ (సానుకూలమైనది)",
            "or": "ସକରାତ୍ମକ",
            "as": "ভাল দিশ",
            "gu": "ફાયદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "Positive",
            "hi": "पोजिटिव",
            "ka": "ಧನಾತ್ಮಕ",
            "te": "పాజిటివ్ (సానుకూలమైనది)",
            "or": "ସକରାତ୍ମକ",
            "as": "ভাল দিশ",
            "gu": "ફાયદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.beforeReveal",
          "value": {
            "en": "Positive",
            "hi": "पोजिटिव",
            "ka": "ಧನಾತ್ಮಕ",
            "te": "పాజిటివ్ (సానుకూలమైనది)",
            "or": "ସକରାତ୍ମକ",
            "as": "ভাল দিশ",
            "gu": "ફાયદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.beforeReveal",
          "value": {
            "en": "Negative",
            "hi": "निगेटिव",
            "ka": "ನಕಾರಾತ್ಮ",
            "te": "నెగెటివ్ (ప్రతికూలమైనది)",
            "or": "ନକାରାତ୍ମକ",
            "as": "বেয়া দিশ",
            "gu": "ગેરફાયદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.beforeReveal",
          "value": {
            "en": "Negative",
            "hi": "निगेटिव",
            "ka": "ನಕಾರಾತ್ಮ",
            "te": "నెగెటివ్ (ప్రతికూలమైనది)",
            "or": "ନକାରାତ୍ମକ",
            "as": "বেয়া দিশ",
            "gu": "ગેરફાયદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.5.beforeReveal",
          "value": {
            "en": "Negative",
            "hi": "निगेटिव",
            "ka": "ನಕಾರಾತ್ಮ",
            "te": "నెగెటివ్ (ప్రతికూలమైనది)",
            "or": "ନକାରାତ୍ମକ",
            "as": "বেয়া দিশ",
            "gu": "ગેરફાયદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "Less numbers of years of studying to start earning soon",
            "hi": "कम वर्षों के लिए पढ़ाई होने के कारण जल्दी से कमाना शुरू",
            "ka": "ಶೀಘ್ರದಲ್ಲೇ ಗಳಿಸಲು ಪ್ರಾರಂಭಿಸಲು ಕಡಿಮೆ ವರ್ಷಗಳ ಅಧ್ಯಯನ",
            "te": "తక్కువ సంవత్సరాలపాటు చదివి త్వరగా సంపాదించడం ప్రారంభించవచ్చు",
            "or": "ଶୀଘ୍ର ରୋଜଗାର ଆରମ୍ଭ କରିବାକୁ କମ୍ ସଂଖ୍ୟକ ବର୍ଷରେ ଅଧ୍ୟୟନ କରିବା",
            "as": "সোনকালে উপাৰ্জন আৰম্ভ কৰিবলৈ বেছি বছৰ পঢ়িব নালাগে",
            "gu": "ટૂંક સમયમાં કમાણી શરૂ કરવા માટે ઓછા વર્ષો માટે અભ્યાસ કરવો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "Focus on skill development for a job",
            "hi": "नोकरी के लिए कौशल विकसित करने पर ध्यान देता है",
            "ka": "ಉದ್ಯೋಗಕ್ಕಾಗಿ ಕೌಶಲ್ಯ ಅಭಿವೃದ್ಧಿಗೆ ಗಮನ ಕೊಡಿ",
            "te": "ఉద్యోగం కోసం నైపుణ్యాభివృద్ధిపై దృష్టి పెట్టాలి",
            "or": "ଚାକିରି ପାଇଁ ଦକ୍ଷତା ବିକାଶ ଉପରେ ଧ୍ୟାନ ଦେବା",
            "as": "চাকৰি এটাৰ বাবে দৰকাৰ হোৱা দক্ষতা বিকাশৰ ওপৰত মন দিব পাৰি",
            "gu": "નોકરી માટે કુશળતા વિકસાવવા પર ધ્યાન આપો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.afterReveal",
          "value": {
            "en": "Lesser fee structure",
            "hi": "कम फी",
            "ka": "ಕಡಿಮೆ ಶುಲ್ಕ ರಚನೆ",
            "te": "తక్కువ ఫీజు (తక్కువ రుసుము నిర్మాణం)",
            "or": "କମ୍ ଶୁଳ୍କ",
            "as": "কম মাচুলৰ দিব লগা হয়",
            "gu": "ઓછી ફીનું માળખું"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.afterReveal",
          "value": {
            "en": "Lesser paying jobs in the beginning",
            "hi": "शुरुआत में कम सैलरी की नोकरी",
            "ka": "ಆರಂಭದಲ್ಲಿ ಕಡಿಮೆ ಸಂಬಳದ ಕೆಲಸಗಳು",
            "te": "ప్రారంభంలో తక్కువ జీతంతో కూడిన ఉద్యోగాలు",
            "or": "ଆରମ୍ଭରେ କମ୍ ଦରମା ଦେଉଥିବା ଚାକିରି",
            "as": "আৰম্ভণিতে কম দৰমহাৰ চাকৰি পায়",
            "gu": "શરૂઆતના દિવસોમાં નોકરીઓ ઓછા પગારવાળી હશે "
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.afterReveal",
          "value": {
            "en": "Not study about the environment",
            "hi": "पर्यावरण के बारे में पढ़ाई नहीं है",
            "ka": "ಪರಿಸರದ ಬಗ್ಗೆ ಅಧ್ಯಯನ ಮಾಡುವುದಿಲ್ಲ",
            "te": "పర్యావరణం గురించి అధ్యయనం చేయలేను",
            "or": "ପରିବେଶ ବିଷୟରେ ଅଧ୍ୟୟନ ନାହିଁ",
            "as": "পৰিৱেশৰ বিষয়ে পঢ়া শুনা কৰিব পৰা নহ'ব",
            "gu": "પર્યાવરણ વિશે અભ્યાસ કરાશે નહીં."
          }
        },
        {
          "valuePath": "blocks.0.props.options.5.afterReveal",
          "value": {
            "en": "Will not become like Director Ma’am",
            "hi": "डायरेक्टर मैम जैसे बन नहीं सकते",
            "ka": "ಡೈರೆಕ್ಟರ್ ಮೇಡಂ ಹಾಗೆ ಆಗುವುದಿಲ್ಲ",
            "te": "డైరెక్టర్ మేడమ్ లాగా అవ్వలేను",
            "or": "ନିର୍ଦ୍ଦେଶକ ମାଡ଼ାମ୍ ପରି ହେବେ ନାହିଁ",
            "as": "পৰিচালক মেডামৰ দৰে হ'ব পৰা নাযাব",
            "gu": "નિયામક શ્રીમતી જેવા નહીં બની શકાય "
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "X5_1_3_Reveal",
            "hi": "X5_1_3_दिखाएं",
            "ka": "X5_1_3_ಬಹಿರಂಗಪಡಿಸಿ",
            "te": "X5_1_3_చెప్పడం (వెల్లడి చేయడం )",
            "or": "X5_1_3_ ପ୍ରକାଶ କରନ୍ତୁ",
            "as": "X5_1_3_প্ৰকাশ কৰক",
            "gu": "X5_1_3_પ્રગટ કરવું"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Click to reveal the Positives and Negatives of taking Science after 10th",
            "hi": "10 वीं के बाद सायन्स लेने के पोजिटिव और निगेटिव पॉइंट्स दिखाएं",
            "ka": "೧೦ನೇ ತರಗತಿಯ ನಂತರ ವಿಜ್ಞಾನವನ್ನು ತೆಗೆದುಕೊಳ್ಳುವ ಧನಾತ್ಮಕ ಮತ್ತು ಋಣಾತ್ಮಕ ಅಂಶಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ",
            "te": "10వ తరగతి తర్వాత సైన్స్ తీసుకోవడం వల్ల వచ్చే పాజిటివ్‌లు మరియు నెగెటివ్‌లను((సానుకూల మరియు ప్రతికూలతలు) లను తెలుసుకోడానికి క్లిక్ చేయండి (నొక్కండి))",
            "or": "ଦଶମ ପରେ ବିଜ୍ଞାନ ନେବାର ସକରାତ୍ମକ ଏବଂ ନକାରାତ୍ମକ ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ",
            "as": "দশম শ্ৰেণীৰ পিছত বিজ্ঞান বিষয় লোৱাৰ ভাল দিশ আৰু বেয়া দিশ বোৰ জানিবলৈ ক্লিক কৰক",
            "gu": "10માં પછી ડિપ્લોમા કોર્સ લેવાના ફાયદા અને ગેરફાયદા જાણવા માટે ક્લિક કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Postive",
            "hi": "पोजिटिव",
            "ka": "ಧನಾತ್ಮಕ",
            "te": "పాజిటివ్ (సానుకూలమైనది)",
            "or": "ସକରାତ୍ମକ",
            "as": "ভাল দিশ",
            "gu": "ફાયદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "Positive",
            "hi": "पोजिटिव",
            "ka": "ಧನಾತ್ಮಕ",
            "te": "పాజిటివ్ (సానుకూలమైనది)",
            "or": "ସକରାତ୍ମକ",
            "as": "ভাল দিশ",
            "gu": "ફાયદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.beforeReveal",
          "value": {
            "en": "Positive",
            "hi": "पोजिटिव",
            "ka": "ಧನಾತ್ಮಕ",
            "te": "పాజిటివ్ (సానుకూలమైనది)",
            "or": "ସକରାତ୍ମକ",
            "as": "ভাল দিশ",
            "gu": "ફાયદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.beforeReveal",
          "value": {
            "en": "Negative",
            "hi": "निगेटिव",
            "ka": "ನಕಾರಾತ್ಮ",
            "te": "నెగెటివ్ (ప్రతికూలమైనది)",
            "or": "ନକାରାତ୍ମକ",
            "as": "বেয়া দিশ",
            "gu": "ગેરફાયદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.beforeReveal",
          "value": {
            "en": "Negative",
            "hi": "निगेटिव",
            "ka": "ನಕಾರಾತ್ಮ",
            "te": "నెగెటివ్ (ప్రతికూలమైనది)",
            "or": "ନକାରାତ୍ମକ",
            "as": "বেয়া দিশ",
            "gu": "ગેરફાયદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.5.beforeReveal",
          "value": {
            "en": "Negative",
            "hi": "निगेटिव",
            "ka": "ನಕಾರಾತ್ಮ",
            "te": "నెగెటివ్ (ప్రతికూలమైనది)",
            "or": "ନକାରାତ୍ମକ",
            "as": "বেয়া দিশ",
            "gu": "ગેરફાયદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "Wider experience and opportunities",
            "hi": "व्यापक अनुभव और अवसर",
            "ka": "ವಿಶಾಲ ಅನುಭವ ಮತ್ತು ಅವಕಾಶಗಳು",
            "te": "ఎక్కువ అనుభవం మరియు అవకాశాలు",
            "or": "ବ୍ୟାପକ ଅଭିଜ୍ଞତା ଏବଂ ସୁଯୋଗ",
            "as": "বেছি অভিজ্ঞতা আৰু সুযোগ পোৱা যায়",
            "gu": "ખૂબ અનુભવ અને ઘણી તકો મળે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "Better paying jobs",
            "hi": "अच्छी सैलरी की नौकरी",
            "ka": "ಉತ್ತಮ ಸಂಬಳದ ಉದ್ಯೋಗಗಳು",
            "te": "ఎక్కువ జీతం వచ్చే ఉద్యోగాలు",
            "or": "ଭଲ ଦରମା ବିଶିଷ୍ଟ ଚାକିରି",
            "as": "ভাল দৰমহাৰ চাকৰি",
            "gu": "વધુ સારા પગારવાળી નોકરીઓ મળે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.afterReveal",
          "value": {
            "en": "Study about environment",
            "hi": "पर्यावरण के बारे में पढ़ाई",
            "ka": "ಪರಿಸರದ ಬಗ್ಗೆ ಅಧ್ಯಯನ",
            "te": "పర్యావరణం గురించి నేర్చుకుంటాం",
            "or": "ପରିବେଶ ବିଷୟରେ ଅଧ୍ୟୟନ",
            "as": "পৰিৱেশৰ বিষয়ে পঢ়া শুনা কৰিব পাৰি",
            "gu": " પર્યાવરણ વિશે અભ્યાસ  કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.afterReveal",
          "value": {
            "en": "Higher fees",
            "hi": "ज्यादा फी",
            "ka": "ಹೆಚ್ಚಿನ ಶುಲ್ಕಗಳು",
            "te": "ఎక్కువ ఫీజు (రుసుము)",
            "or": "ଅଧିକ ଶୁଳ୍କ",
            "as": "অধিক মাচুল",
            "gu": "ફી વધારે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.afterReveal",
          "value": {
            "en": "Can’t support family for few years",
            "hi": "कुछ सालों के लिए परिवार को मदद नहीं कर सकती",
            "ka": "ಕೆಲವು ವರ್ಷಗಳವರೆಗೆ ಕುಟುಂಬವನ್ನು ಪೋಷಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
            "te": "కొన్ని సంవత్సరాలు కుటుంబాన్ని పోషించలేను",
            "or": "କିଛି ବର୍ଷ ପାଇଁ ପରିବାରକୁ ସମର୍ଥନ କରିପାରିବେ ନାହିଁ",
            "as": "কেইবছৰমানৰ বাবে পৰিয়ালক সহায় কৰিব নোৱাৰি",
            "gu": "થોડા વર્ષો માટે પરિવારને મદદ નથી કરી શકતા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.5.afterReveal",
          "value": {
            "en": "Against Ammi’s wishes",
            "hi": "अम्मी के इच्छा के विरुद्ध है",
            "ka": "ಅಮ್ಮಿಯ ಇಚ್ಛೆಗೆ ವಿರುದ್ಧ",
            "te": "అమ్మ ఇష్టానికి వ్యతిరేకం",
            "or": "ଅମ୍ମିଙ୍କ ଇଚ୍ଛା ବିରୁଦ୍ଧରେ",
            "as": "আম্মীৰ ইচ্ছাৰ বিপৰীতে",
            "gu": "અમ્મીની ઈચ્છા વિરુદ્ધ"
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "X5_1_6(D)",
            "hi": "X5_1_6(D)",
            "ka": "X5_1_6(D)",
            "te": "X5_1_6(D)",
            "or": "X5_1_6(D)",
            "as": "X5_1_6(D)",
            "gu": "X5_1_6(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Now that Razia has analysed the Positives and Negatives, she needs to decide. \nWhat should Razia do?",
            "hi": "अब जब रजिया ने पोजिटिव और निगेटिव पॉइंट्स पर अच्छे से विचार कर लिया है, तो उसे निर्णय लेना होगा।\n रजिया को क्या करना चाहिए?",
            "ka": "ಈಗ ರಝಿಯಾ ಧನಾತ್ಮಕ ಮತ್ತು ನಕಾರಾತ್ಮಕ ಅಂಶಗಳನ್ನು ವಿಶ್ಲೇಷಿಸಿದ್ದಾಳೆ, ಅವಳು ನಿರ್ಧರಿಸಬೇಕಾಗಿದೆ.\n ರಝಿಯಾ ಏನು ಮಾಡಬೇಕು?",
            "te": "ఇప్పుడు రజియా పాజిటివ్‌లు మరియు నెగెటివ్‌లను (సానుకూల మరియు ప్రతికూలతలు) లను)విశ్లేషించింది, ఆమె నిర్ణయించుకోవాలి. రజియా ఏం చేయాలి?",
            "or": "ବର୍ତ୍ତମାନ ରଜିଆ ସକରାତ୍ମକ ଏବଂ ନକାରାତ୍ମକ ପକ୍ଷ ଗୁଡ଼ିକୁ ବିଶ୍ଳେଷଣ କରିସାରିଛନ୍ତି, ସେ ନିଷ୍ପତ୍ତି ନେବା ଆବଶ୍ୟକ । ରଜିଆ କ'ଣ କରିବା ଉଚିତ୍?",
            "as": "এতিয়া যিহেতু ৰাজিয়াই ভাল আৰু বেয়া দিশবোৰ বিশ্লেষণ কৰিছে, তাই সিদ্ধান্ত ল'ব লাগিব। \n ৰাজিয়াই কি কৰা উচিত?",
            "gu": "હવે જ્યારે રઝિયાએ ફાઈદા અને ગેરફાઈદાનું વિશ્લેષણ કર્યું છે, તેણે નિર્ણય લેવાની જરૂર છે.\nરઝિયાને આગળ શું કરવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Choose to study Science",
            "hi": "सायन्स पढ़ने का निर्णय लेना चाहिए",
            "ka": "ವಿಜ್ಞಾನವನ್ನು ಅಧ್ಯಯನ ಮಾಡಲು ಆಯ್ಕೆ ಮಾಡಬೇಕು",
            "te": "సైన్స్ చదవాలా",
            "or": "ବିଜ୍ଞାନ ଅଧ୍ୟୟନ କରିବାକୁ ବାଛନ୍ତୁ",
            "as": "বিজ্ঞান পঢ়িবলৈ বাছনি কৰক",
            "gu": "વિજ્ઞાન વિષયમાં અભ્યાસ કરવાનું પસંદ કરવું"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Choose to do Diploma Course",
            "hi": "डिप्लोमा पढने का निर्णय लेना चाहिए",
            "ka": "ಡಿಪ್ಲೊಮಾ ಕೋರ್ಸ್ ಮಾಡಲು ಆಯ್ಕೆ ಮಾಡಬೇಕು",
            "te": "డిప్లొమా కోర్సు చేయాలా",
            "or": "ଡିପ୍ଲୋମା ପାଠ୍ୟକ୍ରମ କରିବାକୁ ବାଛନ୍ତୁ",
            "as": "ডিপ্লোমা পাঠ্যক্ৰম কৰিবলৈ বাছনি কৰক",
            "gu": "ડિપ્લોમા કોર્સ કરવાનું પસંદ કરવું"
          }
        }
      ]
    },
    "scene8": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_1_1_Study Science",
            "hi": "सायन्स पढ़ना",
            "ka": "A1_1_1_ವಿಜ್ಞಾನ ಅಧ್ಯಯನ",
            "te": "A1_1_1_సైన్స్ చదవాలి",
            "or": "A1_1_1_ବିଜ୍ଞାନ ଅଧ୍ୟୟନ କରନ୍ତୁ",
            "as": "A1_1_1_বিজ্ঞান অধ্যয়ন",
            "gu": "A1_1_1_વિજ્ઞાનનો અભ્યાસ કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia studies science in 11th and 12th. She shares with Imran dada her interest to study Environmental Science.",
            "hi": "रजिया 11 वीं और 12 वीं में सायन्स लेती हैं। वह इमरान दादा को बताती है कि उसे एनवायरमेंटल सायन्स पढने में दिलचस्पी है।",
            "ka": "ರಜಿಯಾ ೧೧ ಮತ್ತು ೧೨ ನೇ ತರಗತಿಯಲ್ಲಿ ವಿಜ್ಞಾನವನ್ನು ಅಧ್ಯಯನ ಮಾಡುತ್ತಾಳೆ. ಅವಳು ಇಮ್ರಾನ್ ದಾದಾ ಅವರೊಂದಿಗೆ ಪರಿಸರ ವಿಜ್ಞಾನವನ್ನು ಅಧ್ಯಯನ ಮಾಡುವ ಆಸಕ್ತಿಯನ್ನು ಹಂಚಿಕೊಳುತ್ತಾಳೆ.",
            "te": "రజియా 11వ మరియు 12వ తరగతిలో సైన్స్ చదువుతుంది. ఆమె పర్యావరణ శాస్త్రాన్ని చదవాలనే తన ఇష్టాన్ని ఇమ్రాన్ అన్నయ్యతో చెప్పింది.",
            "or": "ରଜିଆ ଏକାଦଶ ଏବଂ ଦ୍ୱାଦଶରେ ବିଜ୍ଞାନ ଅଧ୍ୟୟନ କରନ୍ତି । ପରିବେଶ ବିଜ୍ଞାନ ଅଧ୍ୟୟନ ପାଇଁ ସେ ଇମ୍ରାନ ଭାଇଙ୍କ ସହ ତାଙ୍କର ଆଗ୍ରହ ବିଷୟରେ କୁହନ୍ତି ।",
            "as": "ৰাজিয়াই 11 আৰু 12 শ্ৰেণীত বিজ্ঞান অধ্যয়ন কৰে। তাই পৰিৱেশ বিজ্ঞান অধ্যয়ন কৰিবলৈ ইমৰান দাদাক তাইৰ আগ্ৰহৰ কথা কয়।",
            "gu": "રઝિયા 11 અને 12માં ધોરણમાં વિજ્ઞાનનો વિષયનો અભ્યાસ કરે છે. તેને ઈમરાન દાદા સાથે પોતાના પર્યાવરણ વિજ્ઞાનના અભ્યાસ કરવાની રુચિ રજૂ કરી."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_1_2",
            "hi": "A1_1_2",
            "ka": "A1_1_2",
            "te": "A1_1_2",
            "or": "A1_1_2",
            "as": "A1_1_2",
            "gu": "A1_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Imran is very pleased to hear this, but since these specialised courses are only available in the city, he asks Razia to do some research before talking to Ammi and Abbu.",
            "hi": "इमरान को यह सुनकर बहुत खुशी होती है, लेकिन ऐसे स्पेशलाइज्ड कोर्सेस केवल शहर में उपलब्ध होते हैं, इसलिए वह अम्मी-अब्बू से बात करने से पहले रजिया को और थोडा रिसर्च करने के लिए कहता है।",
            "ka": "ಇದನ್ನು ಕೇಳಿ ಇಮ್ರಾನ್ ತುಂಬಾ ಸಂತೋಷಪಟ್ಟನು, ಆದರೆ ಈ ವಿಶೇಷ ಕೋರ್ಸ್‌ಗಳು ನಗರದಲ್ಲಿ ಮಾತ್ರ ಲಭ್ಯವಿರುವುದರಿಂದ, ಅವನು ಅಮ್ಮಿ ಮತ್ತು ಅಬ್ಬು ಅವರೊಂದಿಗೆ ಮಾತನಾಡುವ ಮೊದಲು ಸ್ವಲ್ಪ ಸಂಶೋಧನೆ ಮಾಡಲು ರಜಿಯಾ ಅವಳನ್ನು ಕೇಳುತ್ತಾನೆ.",
            "te": "ఇది విని ఇమ్రాన్ చాలా సంతోషించాడు, అయితే ఈ ప్రత్యేక కోర్సులు నగరంలో మాత్రమే అందుబాటులో ఉన్నాయి కాబట్టి, అమ్మ మరియు నాన్నతో మాట్లాడే ముందు కొంచెం పరిశోధన చేయమని రజియాకు చెప్పాడు.",
            "or": "ଇମ୍ରାନ ଏହା ଶୁଣି ବହୁତ ଖୁସି, କିନ୍ତୁ ଯେହେତୁ ଏହି ବିଶେଷ ପାଠ୍ୟକ୍ରମଗୁଡ଼ିକ କେବଳ ସହରରେ ଉପଲବ୍ଧ, ସେ ରଜିଆଙ୍କୁ ଅମ୍ମି ଏବଂ ଅବୁଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବା ପୂର୍ବରୁ କିଛି ଗବେଷଣା କରିବାକୁ କହିଥିଲେ।",
            "as": "ইমৰানে এইকথা শুনি খুব ভাল পালে কিন্তু যিহেতু এই বিশেষ পাঠ্যক্ৰমবোৰ কেৱল চহৰতহে আছে, তেওঁ ৰাজিয়াক আম্মী আৰু আব্বুৰ সৈতে কথা পতাৰ আগতে কিছু চিন্তা কৰিবলৈ কয়।",
            "gu": "આ સાંભળીને ઈમરાન દાદા ખૂબ જ ખુશ થાય છે, પરંતુ આ અલગ અભ્યાસક્રમો ફક્ત શહેરમાં જ ઉપલબ્ધ હોવાથી, તે અમ્મી અને અબ્બુ સાથે વાત કરતા પહેલા રઝિયાને થોડું શોધખોળ કરવાનું કહે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_1_3_searchBlock",
            "hi": "A1_1_3_सर्चब्लॉक",
            "ka": "A1_1_3_ಸರ್ಚ್ ಬ್ಲಾಕ್",
            "te": "A1_1_3_సర్చ్ బ్లాక్ (శోధన బ్లాక్)",
            "or": "A1_1_3_ବ୍ଲକ ସନ୍ଧାନ କରନ୍ତୁ",
            "as": "A1_1_3_সন্ধান ব্লক",
            "gu": "A1_1_3_ખાના શોધો"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "She decides to use Abbu's phone to look through the tuition fees, location, safety, food and stay that these courses have to offer to students.",
            "hi": "ये कोर्सेस स्टूडेंट्स को ट्यूशन फी, स्थान, सुरक्षा, भोजन और रहने की सुविधा प्रदान करते हैं यह जानने के लिए उसने अब्बू का फोन इस्तेमाल करने का तय किया।",
            "ka": "ಈ ಕೋರ್ಸ್‌ಗಳು ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ನೀಡಬೇಕಾದ ಬೋಧನಾ ಶುಲ್ಕ, ಸ್ಥಳ, ಸುರಕ್ಷತೆ, ಆಹಾರ ಮತ್ತು ನಿಲ್ಲಬೇಕಾದ ಸ್ಥಳದ ಬಗ್ಗೆ ನೋಡಲು ಅಬ್ಬು ಅವರ ಫೋನ್ ಅನ್ನು ಬಳಸಲು ಅವಳು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
            "te": "ఈ కోర్సులు విద్యార్థులకు అందించే ట్యూషన్ ఫీజులు, ,ప్రాంతం/స్తానం ,భద్రత,ఆహారం మరియు ఉండడం గురించి ఆమె నాన్న ఫోన్‌ లో వెతకాలనుకుంది",
            "or": "ଏହି ପାଠ୍ୟକ୍ରମଗୁଡିକ ଛାତ୍ରମାନଙ୍କୁ ପ୍ରଦାନ କରୁଥିବା ଟ୍ୟୁସନ୍ ଫି, ଅବସ୍ଥାନ, ନିରାପତ୍ତା, ଖାଦ୍ୟ ଏବଂ ରହିବା ବିଷୟରେ ଜାଣିବା ପାଇଁ ସେ ଅବୁଙ୍କ ଫୋନ୍ ବ୍ୟବହାର କରିବା ପାଇଁ ନିଷ୍ପତ୍ତି ନେଲେ ।",
            "as": "তাই পাঠ্যক্ৰমবোৰৰ খৰচ, থকাৰ ঠাই, সুৰক্ষা আৰু এই পাঠ্যক্ৰমবোৰে থকা আৰু খোৱাৰ সুবিধা দিয়ে নে নিদিয়ে আদি কথা বোৰ জানিবলৈ আব্বুৰ ফোনটো ব্যৱহাৰ কৰাৰ সিদ্ধান্ত লয়।",
            "gu": "તેણે અબ્બુના ફોનની મદદથી ટ્યુશન ફી, જગ્યા, સલામતી, જમવાનું અને આ અભ્યાસક્રમો વિદ્યાર્થીઓને શું પ્રદાન કરે છે તે જોવા માટે નક્કી કરે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.strings.0.string",
          "value": {
            "en": "Bachelor's in Environmental Science + Government Colleges +  West Bengal",
            "hi": "एनवायरमेंटल सायन्स में बैचलर डिग्री + सरकारी कॉलेज + वेस्ट बंगाल",
            "ka": "ಪರಿಸರ ವಿಜ್ಞಾನದಲ್ಲಿ ಪದವಿ + ಸರ್ಕಾರಿ ಕಾಲೇಜುಗಳು + ಪಶ್ಚಿಮ ಬಂಗಾಳ",
            "te": "పర్యావరణ శాస్త్రంలో బ్యాచిలర్స్ + ప్రభుత్వ కళాశాలలు + పశ్చిమ బెంగాల్",
            "or": "ପରିବେଶ ବିଜ୍ଞାନରେ ସ୍ନାତକ + ସରକାରୀ ମହାବିଦ୍ୟାଳୟ + ପଶ୍ଚିମବଙ୍ଗ",
            "as": "পৰিৱেশ বিজ্ঞানত স্নাতক ডিগ্ৰী + চৰকাৰী কলেজবোৰ + পশ্চিম বংগ",
            "gu": "પર્યાવરણ વિજ્ઞાનમાં સ્નાતક + સરકારી કોલેજો + પશ્ચિમ બંગાળમાં"
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_1_4",
            "hi": "A1_1_4",
            "ka": "A1_1_4",
            "te": "A1_1_4",
            "or": "A1_1_4",
            "as": "A1_1_4",
            "gu": "A1_1_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She finds out about the different government colleges offering the course she wants. She then goes to collegedunia.com to see the ranking of the colleges on academic record, tuition fee, location, safety, food and stay.",
            "hi": "उसे उसकी पसंद के कोर्स के लिए कई सरकारी कॉलेजों के बारे में जानकरी मिलती हैं। फिर वह collegedunia.com पर जाकर अकैडमिक रेकॉर्ड, ट्यूशन फी, स्थान, सुरक्षा, भोजन और निवास के लिए कॉलेज की रैंकिंग देखती है।",
            "ka": "ತನಗೆ ಬೇಕಾದ ಕೋರ್ಸ್‌ಗಳನ್ನು ನೀಡುವ ವಿವಿಧ ಸರ್ಕಾರಿ ಕಾಲೇಜುಗಳ ಬಗ್ಗೆ ಅವಳು ಕಂಡುಕೊಳ್ಳುತ್ತಾಳೆ. ನಂತರ ಅವರು ಶೈಕ್ಷಣಿಕ ದಾಖಲೆ, ಬೋಧನಾ ಶುಲ್ಕ, ಸ್ಥಳ, ಸುರಕ್ಷತೆ, ಆಹಾರ ಮತ್ತು ವಾಸ್ತವ್ಯದ ಮೇಲೆ ಕಾಲೇಜುಗಳ ಶ್ರೇಯಾಂಕವನ್ನು ನೋಡಲು collegedunia.com ಅನ್ನು ನೋಡುತ್ತಾಳೆ.",
            "te": "తనకు కావాల్సిన కోర్సును అందిస్తున్న వివిధ ప్రభుత్వ కళాశాలల గురించి ఆమె తెలుసుకుంటుంది. అకడమిక్ రికార్డ్, ట్యూషన్ ఫీజు, స్థానం, భద్రత, ఆహారం మరియు బస వంటి విషయాలలో ఆమె కళాశాలల ర్యాంకింగ్‌ లను చూడటానికి ఆమె collegedunia.com లో చూస్తుంది.",
            "or": "ସେ ଚାହୁଁଥିବା ପାଠ୍ୟକ୍ରମ ପ୍ରଦାନ କରୁଥିବା ବିଭିନ୍ନ ସରକାରୀ କଲେଜ ବିଷୟରେ ଜାଣିବାକୁ ପାଇଲେ । ତା'ପରେ ସେ ଏକାଡେମିକ୍ ରେକର୍ଡ, ଟ୍ୟୁସନ୍ ଫି, ଅବସ୍ଥାନ, ନିରାପତ୍ତା, ଖାଦ୍ୟ ଏବଂ ରହିବା ଉପରେ କଲେଜଗୁଡିକର ମାନ୍ୟତା ଦେଖିବାକୁ collegedunia.com ଗଲେ ।",
            "as": "তাই বিচৰা পাঠ্যক্ৰমটো আগবঢ়োৱা বিভিন্ন চৰকাৰী কলেজবোৰ বিচাৰি উলিয়ায়। তাৰ পিছত তাই একাডেমিক ৰেকৰ্ড, টিউচন মাচুল, অৱস্থান, সুৰক্ষা, খোৱা আৰু থকাৰ ব্যৱস্থা আদিৰ ওপৰত কলেজসমূহৰ ৰেংকিং চাবলৈ collegedunia.com লৈ যায়।",
            "gu": "તેને પસંદ કોર્ષ ભણાવતી અલગ અલગ કોલેજો તે શોધી કાઢે છે.  ત્યારપછી તે કોલેજદુનિયા.કોમ પર શૈક્ષણિક નોધ, ટ્યુશન ફી, જગ્યા, સલામતી, જમવાનું અને રહેવાનું તે કોલેજોનો નંબર જોવા માટે જાય છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_1_5_Apply button",
            "hi": "A1_1_5_अप्लाई बटन",
            "ka": "A1_1_5_ಅಪ್ಲೈ ಬಟನ್",
            "te": "A1_1_5_అప్లై బటన్ (దరఖాస్తు చేసుకోండి)",
            "or": "A1_1_5_ଆବେଦନ କରନ୍ତୁ ବଟନ୍",
            "as": "A1_1_5_এপ্লাই বাটন",
            "gu": "A1_1_5_ બટન લાગુ કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Three colleges stand out for her that also have a hostel provision.\nShe applies to all these colleges as their academic ratings are recognized nationally. Apply to all universities to proceed.",
            "hi": "उसे तीन कॉलेज अच्छे लगते हैं जहाँ हॉस्टल की सुविधा भी प्रदान की जाती है। \n वह इन तीनों कॉलेज के लिए अप्लाई करती हैं क्योंकि इनके अकैडमिक रेटिंग्ज को देशभर में मान्यता प्राप्त है। आगे बढ़ने के लिए सभी युनिवर्सिटीज में अप्लाई करें",
            "ka": "ಹಾಸ್ಟೆಲ್ ಸೌಲಭ್ಯವನ್ನು ಹೊಂದಿರುವ ಮೂರು ಕಾಲೇಜುಗಳು ಅವಳಿಗೆ ಎದ್ದು ಕಾಣುತ್ತವೆ.\n ಈ ಎಲ್ಲಾ ಕಾಲೇಜುಗಳ ಶೈಕ್ಷಣಿಕ ರೇಟಿಂಗ್‌ಗಳು ರಾಷ್ಟ್ರೀಯವಾಗಿ ಗುರುತಿಸಲ್ಪಟ್ಟಿರುವುದರಿಂದ ಈ ಎಲ್ಲಾ ಕಾಲೇಜುಗಳಿಗೆ ಅವಳು ಅಪ್ಲೈ ಮಾಡುತ್ತಾಳೆ. ಮುಂದುವರೆಯಲು ಎಲ್ಲಾ ವಿಶ್ವವಿದ್ಯಾಲಯಗಳಿಗೆ ಅಪ್ಲೈ ಮಾಡಿ.",
            "te": "హాస్టల్ సదుపాయం ఉన్న మూడు కాలేజీలను ఎంచుకుంది.\n  జాతీయ స్థాయిలో గుర్తింపు పొందిన ఈ కాలేజీల అకడమిక్ రేటింగ్‌ల ఆధారంగా ఆమె వీటన్నిటికీ దరఖాస్తు చేసింది. కొనసాగించడానికి అన్ని విశ్వవిద్యాలయాలకు దరఖాస్తు చేసుకోండి.",
            "or": "ତାଙ୍କ ପାଇଁ ତିନୋଟି କଲେଜ ଉପଯୁକ୍ତ ଯାହାର ହଷ୍ଟେଲ ବ୍ୟବସ୍ଥା ମଧ୍ୟ ଅଛି । ସେ ଏହି ସମସ୍ତ କଲେଜପାଇଁ ଅବଦାନ କରିଲେ କାରଣ ସେଗୁଡିକର ଏକାଡେମିକ୍ ରେଟିଂ ଜାତୀୟ ସ୍ତରରେ ସ୍ୱୀକୃତି ପ୍ରାପ୍ତ । ଆଗକୁ ବଢିବା ପାଇଁ ସମସ୍ତ ବିଶ୍ୱବିଦ୍ୟାଳୟରେ ଆବେଦନ କରନ୍ତୁ ।",
            "as": "তিনিখন কলেজ তাইৰ বাবে আছে য'ত হোষ্টেলৰ ব্যৱস্থাও আছে।\n তাই এই সকলো কলেজৰ বাবে এপ্লাই কৰে কিয়নো সেইকেইখনৰ শৈক্ষিক মূল্যাঙ্কন ৰাষ্ট্ৰীয়ভাৱে স্বীকৃত। আগবাঢ়িবলৈ সকলো কলেজত এপ্লাই কৰক।",
            "gu": "તેના માટે ત્રણ કોલેજો અલગ છે જેમાં હોસ્ટેલની વ્યવસ્થા પણ છે.\nતે આ તમામ કોલેજોમાં અરજી કરે છે કારણ કે તેની શૈક્ષણિક માન્યતા રાષ્ટ્રીય સ્તરે પ્રમાણિત છે. આગળ વધવા માટે તમામ યુનિવર્સિટીઓને અરજી કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "University of Jadavpur",
            "hi": "जादवपुर यूनिवर्सिटी",
            "ka": "ಯೂನಿವರ್ಸಿಟಿ ಆಫ್ ಜಾದವಪುರ್",
            "te": "జాదవ్‌పూర్ విశ్వవిద్యాలయం",
            "or": "ଯାଦବପୁର ବିଶ୍ୱବିଦ୍ୟାଳୟ",
            "as": "যাদৱপুৰ বিশ্ববিদ্যালয়",
            "gu": "જાદવપુર યુનિવર્સિટી"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "University of Calcutta",
            "hi": "कोलकता युनिवर्सिटी",
            "ka": "ಯೂನಿವರ್ಸಿಟಿ ಆಫ್ ಕಲ್ಕತ್ತಾ",
            "te": "కలకత్తా విశ్వవిద్యాలయం",
            "or": "କଲିକତା ବିଶ୍ୱବିଦ୍ୟାଳୟ",
            "as": "কলিকতা বিশ্ববিদ্যালয়",
            "gu": "કલકત્તા યુનિવર્સિટી"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Institute of Environmental studies",
            "hi": "इंस्टीट्यूट ऑफ एनवायरमेंटल स्टडीज",
            "ka": "ಇನ್ಸ್ಟಿಟ್ಯೂಟ್ ಆಫ್ ಎನ್ವಿರಾನ್ಮೆಂಟಲ್ ಸ್ಟಡೀಸ್",
            "te": "ఇన్స్టిట్యూట్ ఆఫ్ ఎన్విరాన్మెంటల్ స్టడీస్ (పర్యావరణ అధ్యయనాలు యొక్క సంస్థ )",
            "or": "ପରିବେଶ ଅଧ୍ୟୟନ ପ୍ରତିଷ୍ଠାନ",
            "as": "পৰিৱেশ অধ্যয়ন প্ৰতিষ্ঠান",
            "gu": "પર્યાવરણીય અભ્યાસ સંસ્થા"
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_1_6_Applybutton",
            "hi": "A1_1_6_अप्लाई बटन",
            "ka": "A1_1_6_ಅಪ್ಲೈಬಟನ್",
            "te": "A1_1_6_అప్లై బటన్ (దరఖాస్తు చేసుకోండి)",
            "or": "A1_1_6_ ଆବେଦନ କରନ୍ତୁ ବଟନ୍",
            "as": "A1_1_6_এপ্লাই বাটন",
            "gu": "A1_1_6બટન લાગુ કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "She also searches for scholarships which are available specifically for girls which covers both the college and hostel fee. She decides to apply for four scholarships. Apply to all to proceed.",
            "hi": "वह ऐसे स्कोलरशिप भी ढूंढती हैं जो विशेष रूप से लड़कियों के लिए उपलब्ध है और जो कॉलेज और हॉस्टल की फी दोनों कवर करते हैं। वह चार स्कोलरशिप के लिए अप्लाई करने का फैसला करती हैं। आगे बढ्ने के लिए सभी को अप्लाई करें।",
            "ka": "ಕಾಲೇಜು ಮತ್ತು ಹಾಸ್ಟೆಲ್ ಶುಲ್ಕ ಎರಡನ್ನೂ ಒಳಗೊಂಡಿರುವ ನಿರ್ದಿಷ್ಟವಾಗಿ ಹುಡುಗಿಯರಿಗೆ ಲಭ್ಯವಿರುವ ವಿದ್ಯಾರ್ಥಿವೇತನಕ್ಕಾಗಿ ಅವಳು ಹುಡುಕುತ್ತಾಳೆ. ಅವಳು ನಾಲ್ಕು ವಿದ್ಯಾರ್ಥಿವೇತನಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಮುಂದುವರೆಯಲು ಎಲ್ಲವುಗಳಿಗೆ ಅಪ್ಲೈ ಮಾಡಿ.",
            "te": "కళాశాల మరియు వసతిగృహం రెండింటికి రుసుములు చెల్లించే బాలికల కోసం ప్రత్యేకంగా అందుబాటులో ఉన్న ఉపకార వేతనా ((స్కాలర్‌షిప్ ))ల కోసం ఆమె వెతుకుతుంది.ఆమె నాలుగు ఉపకార వేతనాల ల కోసం దరఖాస్తు చేయాలని నిర్ణయించుకుంది.కొనసాగించడానికి అన్నిటికి దరఖాస్తు చేసుకుంది",
            "or": "ସେ ଛାତ୍ରବୃତ୍ତି ମଧ୍ୟ ଖୋଜିଲେ ଯାହା ବିଶେଷ ଭାବରେ ଝିଅମାନଙ୍କ ପାଇଁ ଉପଲବ୍ଧ ଯାହା ଉଭୟ କଲେଜ ଏବଂ ହଷ୍ଟେଲ ଶୁଳ୍କକୁ ଅନ୍ତର୍ଭୁକ୍ତ କରେ । ସେ ଚାରୋଟି ଛାତ୍ରବୃତ୍ତି ପାଇଁ ଆବେଦନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି। ଆଗକୁ ବଢିବା ପାଇଁ ସମସ୍ତଙ୍କ ପାଇଁ ଆବେଦନ କରନ୍ତୁ ।",
            "as": "তাই এনে বৃত্তি বিচাৰে যিবোৰ বিশেষকৈ ছোৱালীৰ বাবেহে আছে আৰু যিবোৰে কলেজ আৰু হোষ্টেল দুয়োটা মাচুল সামৰি লয়। তাই চাৰিটা বৃত্তিৰ বাবে এপ্লাই কৰাৰ সিদ্ধান্ত লয়। আগবাঢ়িবলৈ সকলোৰে বাবে এপ্লাই কৰক।",
            "gu": "તે શિષ્યવૃત્તિની પણ શોધ કરે છે જે ખાસ કરીને છોકરીઓ માટે ઉપલબ્ધ છે જે કોલેજ અને હોસ્ટેલ ફી બંનેને સમાવી શકે છે. તે ચાર શિષ્યવૃત્તિ માટે અરજી કરવાનું નક્કી કરે છે. આગળ વધવા માટે બધુ લાગુ કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Santoor Women’s scholarship",
            "hi": "संतूर विमेन्स स्कोलरशिप",
            "ka": "ಸಂತೂರ್ ಮಹಿಳಾ ವಿದ್ಯಾರ್ಥಿವೇತನ",
            "te": "సంతూర్ మహిళల ఉపకార వేతనం (స్కాలర్‌షిప్ )",
            "or": "ସନ୍ତୁର ମହିଳା ଛାତ୍ରବୃତ୍ତି",
            "as": "চেন্টুৰ মহিলা বৃত্তি",
            "gu": "સંતૂર મહિલાઓ માટે શિષ્યવૃત્તિ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Women scientist scheme",
            "hi": "महिला वैज्ञानिक योजना",
            "ka": "ಮಹಿಳಾ ವಿಜ್ಞಾನಿ ಯೋಜನೆ",
            "te": "మహిళా శాస్త్రవేత్త పథకం",
            "or": "ମହିଳା ବୈଜ୍ଞାନିକ ଯୋଜନା",
            "as": "মহিলা বিজ্ঞানী আঁচনি",
            "gu": "મહિલાઓ માટે વૈજ્ઞાનિક યોજના"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "AICTE pragati scholarship for girls",
            "hi": "लड़कियों के लिए एआईसीटीई प्रगति स्कोलरशिप",
            "ka": "ಹುಡುಗಿಯರಿಗೆ AICTE ಪ್ರಗತಿ ವಿದ್ಯಾರ್ಥಿವೇತನ",
            "te": "బాలికలకు AICTE ప్రగతి స్కాలర్‌షిప్ (ఉపకార వేతనం)",
            "or": "ଝିଅମାନଙ୍କ ପାଇଁ ଏଆଇସିଟିଇ (AICTE) ପ୍ରଗତି ଛାତ୍ରବୃତ୍ତି",
            "as": "ছোৱালীৰ বাবে এ.আই.চি.টি.ই. প্ৰগতি বৃত্তি",
            "gu": "છોકરીઓ માટે AICTE પ્રગતિ શિષ્યવૃત્તિ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.display",
          "value": {
            "en": "Begum hazrat mahal national scholarship",
            "hi": "बेगम हजरत महल नैशनल स्कोलरशिप",
            "ka": "ಬೇಗಂ ಹಜರತ್ ಮಹಲ್ ರಾಷ್ಟ್ರೀಯ ವಿದ್ಯಾರ್ಥಿವೇತನ",
            "te": "బేగం హజ్రత్ మహల్ జాతీయ స్కాలర్‌షిప్ (ఉపకార వేతనం)",
            "or": "ବେଗମ୍ ହଜରତ ମହଲ ଜାତୀୟ ଛାତ୍ରବୃତ୍ତି",
            "as": "বেগম হজৰত মহল ৰাষ্ট্ৰীয় বৃত্তি",
            "gu": "બેગમ હઝરત મહલ રાષ્ટ્રીય શિષ્યવૃત્તિ"
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "Research",
            "hi": "रिसर्च",
            "ka": "ಸಂಶೋಧನೆ",
            "te": "పరిశోధించు",
            "or": "ଅନୁସନ୍ଧାନ",
            "as": "গৱেষণা",
            "gu": "શોધખોળ કરવી"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained research experience!",
            "hi": "आपने अभी-अभी रिसर्च करने का अनुभव प्राप्त किया हैं!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಸಂಶೋಧನಾ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే పరిశోధన అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ଅନୁସନ୍ଧାନ ଅଭିଜ୍ଞତା ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ গৱেষণাৰ অভিজ্ঞতা লাভ কৰিলা!",
            "gu": "તમે હમણાં જ શોધખોળનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Research",
            "hi": "रिसर्च",
            "ka": "ಸಂಶೋಧನೆ",
            "te": "పరిశోధించు",
            "or": "ଅନୁସନ୍ଧାନ",
            "as": "গৱেষণা",
            "gu": "શોધખોળ કરવી"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर डेवलपमेंट कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "కెరీర్ అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ୟାରିୟର ବିକାଶ ଦକ୍ଷତା",
            "as": "কেৰিয়াৰ বিকাশৰ দক্ষতা",
            "gu": "કારકિર્દી વિકાસના કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Razia has searched the internet about the different colleges based on set criteria like colleges with hostel facilities. This process of finding out more information about a particular topic is called ‘Research’. You will probably come across an area or subject at various points in your career journey that you want to know about. You can get more information about that topic from the internet, friends, family, teachers, books, newspapers, and libraries.",
            "hi": "रजिया ने हॉस्टल सुविधा वाले कॉलेज जैसे तय किए हुए मानदंडों के अनुसार इंटरनेट पर अलग-अलग कॉलेजों को सर्च किया है। किसी विशेष विषय पर अधिक जानकारी प्राप्त करने की इस प्रक्रिया को ‘रिसर्च’ कहते हैं। आप अपने करियर के दौरान ऐसे कई पल आ सकते हैं जहाँ आप किसी विषय या चीज के बारे में अधिक जानकारी प्राप्त करना चाहेंगे। आप इंटरनेट, दोस्त, परिवार, शिक्षक, किताबें, अख़बार और लाइब्रेरी से इस विषय के बारे में अधिक जानकारी हासिल सकते हैं।",
            "ka": "ಹಾಸ್ಟೆಲ್ ಸೌಲಭ್ಯಗಳನ್ನು ಹೊಂದಿರುವ ಕಾಲೇಜುಗಳಂತಹ ನಿಗದಿತ ಮಾನದಂಡಗಳ ಆಧಾರದ ಮೇಲೆ ರಝಿಯಾ ವಿವಿಧ ಕಾಲೇಜುಗಳ ಬಗ್ಗೆ ಅಂತರ್ಜಾಲದಲ್ಲಿ ಹುಡುಕಿದ್ದಾಳೆ.ನಿರ್ದಿಷ್ಟ ವಿಷಯದ ಬಗ್ಗೆ ಹೆಚ್ಚಿನ ಮಾಹಿತಿಯನ್ನು ಕಂಡುಹಿಡಿಯುವ ಈ ಪ್ರಕ್ರಿಯೆಯನ್ನು 'ಸಂಶೋಧನೆ' ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ.ನಿಮ್ಮ ವೃತ್ತಿ ಪ್ರಯಾಣದ ವಿವಿಧ ಹಂತಗಳಲ್ಲಿ ನೀವು ತಿಳಿದುಕೊಳ್ಳಲು ಬಯಸುವ ಪ್ರದೇಶ ಅಥವಾ ವಿಷಯವನ್ನು ನೀವು ಬಹುಶಃ ನೋಡಬಹುದು. ನೀವು ಇಂಟರ್ನೆಟ್, ಸ್ನೇಹಿತರು, ಕುಟುಂಬ, ಶಿಕ್ಷಕರು, ಪುಸ್ತಕಗಳು, ಪತ್ರಿಕೆಗಳು ಮತ್ತು ಗ್ರಂಥಾಲಯಗಳಿಂದ ಆ ವಿಷಯದ ಕುರಿತು ಹೆಚ್ಚಿನ ಮಾಹಿತಿಯನ್ನು ಪಡೆಯಬಹುದು",
            "te": "వసతి సౌకర్యాలు ఉన్న కళాశాలల వంటి ప్రమాణాలు కలిగిఉన్న వివిధ కళాశాలల గురించి రజియా ఇంటర్నెట్‌లో వెతికింది . ఒక నిర్దిష్ట అంశం గురించి మరింత సమాచారాన్ని కనుగొనే ఈ ప్రక్రియను 'పరిశోధన' అంటారు.మీ కెరీర్ ప్రయాణంలో వివిధ అంశాలు బహుశా ఒక ప్రాంతం లేదా విషయాన్ని గురించి మీరు తెలుసుకోవాలనుకోవాల్సిన పరిస్థితి ఎదురుపడవచ్చు.మీరు ఇంటర్నెట్, స్నేహితులు, కుటుంబం, ఉపాధ్యాయులు, పుస్తకాలు, వార్తాపత్రికలు మరియు లైబ్రరీల నుండి ఆ అంశం గురించి మరింత సమాచారాన్ని పొందవచ్చు.",
            "or": "ହଷ୍ଟେଲ ସୁବିଧା ଥିବା କଲେଜ ପରି ସେଟ୍ ମାନଦଣ୍ଡ ଉପରେ ଆଧାର କରି ରଜିଆ ବିଭିନ୍ନ କଲେଜ ବିଷୟରେ ଇଣ୍ଟରନେଟରେ ଖୋଜିଲେ । ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ବିଷୟରେ ଅଧିକ ସୂଚନା ଖୋଜିବାର ଏହି ପ୍ରକ୍ରିୟାକୁ 'ଅନୁସନ୍ଧାନ' କୁହାଯାଏ । ତୁମେ ବୋଧହୁଏ ତୁମର କ୍ୟାରିୟର ଯାତ୍ରାର ବିଭିନ୍ନ ବିନ୍ଦୁରେ ଏକ କ୍ଷେତ୍ର କିମ୍ବା ବିଷୟ ସାମ୍ନାକୁ ଆସିବ ଯାହା ବିଷୟରେ ତୁମେ ଜାଣିବାକୁ ଚାହୁଁଛ । ତୁମେ ଇଣ୍ଟରନେଟ୍, ବନ୍ଧୁ, ପରିବାର, ଶିକ୍ଷକ, ପୁସ୍ତକ, ଖବରକାଗଜ ଏବଂ ଲାଇବ୍ରେରୀରୁ ସେହି ବିଷୟରେ ଅଧିକ ସୂଚନା ପାଇପାରିବ ।",
            "as": "ৰাজিয়াই ইণ্টাৰনেটত হোষ্টেলৰ সুবিধা থকা কলেজ বিচাৰে। এটা নিৰ্দিষ্ট বিষয়ৰ বিষয়ে অধিক তথ্য বিচাৰি উলিওৱাৰ এই প্ৰক্ৰিয়াটোক 'গৱেষণা' বুলি কোৱা হয়। তুমি তোমাৰ কেৰিয়াৰৰ বিভিন্ন সময়ত এনে এটা বিষয়ৰ সন্মুখীন হ'বা যাৰ বিষয়ে তুমি জানিব বিচাৰা। তুমি সেই বিষয়টোৰ বিষয়ে ইণ্টাৰনেট, বন্ধুবৰ্গ, পৰিয়াল, শিক্ষক, কিতাপ, বাতৰি কাকত আৰু পুথিভঁৰালৰ পৰা অধিক তথ্য লাভ কৰিব পাৰা।",
            "gu": "રઝિયાએ હોસ્ટેલની સુવિધા ધરાવતી કોલેજ જેવા નિર્ધારિત માપદંડોના આધારે વિવિધ કોલેજો વિશે ઇન્ટરનેટ પર શોધે છે. કોઈ ચોક્કસ વિષય વિશે વધુ માહિતી મેળવવાની આ પ્રક્રિયાને ‘સંશોધન’ કહે છે. તમે તમારા કારકિર્દીના માર્ગમાં વિવિધ બિંદુઓ પર એક ક્ષેત્ર અથવા વિષય શોધી શકો છો જેનો તમે અભ્યાસ કરવા માંગો છો. તમે ઇન્ટરનેટની મદદથી મિત્રો, કુટુંબીજનો, શિક્ષકો, પુસ્તકો, સમાચાર પત્રો અને પુસ્તકાલયો પર આ વિષય પર વધુ માહિતી મેળવી શકો છો."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_1",
            "hi": "A1_2_1",
            "ka": "A1_2_1",
            "te": "A1_2_1",
            "or": "A1_2_1",
            "as": "A1_2_1",
            "gu": "A1_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "In a few days, a letter arrives. She decides to share this with Ammi and Abbu when Imran dada is at home.",
            "hi": "कुछ दिनों बाद, एक खत आता है। वह जब इमरान दादा घर पर होगा तभी इसके बारे में अम्मी और अब्बू से बात करने का निर्णय लेती है।",
            "ka": "ಕೆಲವೇ ದಿನಗಳಲ್ಲಿ ಒಂದು ಪತ್ರ ಬರುತ್ತದೆ. ಇಮ್ರಾನ್ ದಾದಾ ಮನೆಯಲ್ಲಿದ್ದಾಗ ಇದನ್ನು ಅಮ್ಮಿ ಮತ್ತು ಅಬ್ಬು ಜೊತೆ ಹಂಚಿಕೊಳ್ಳಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
            "te": "కొద్దిరోజుల్లో ఉత్తరం వస్తుంది. ఇమ్రాన్ అన్నయ్య ఇంట్లో ఉన్నప్పుడు ఈ విషయాన్ని అమ్మ మరియు నాన్నలతో పంచుకోవాలని ఆమె నిర్ణయించుకుంది.",
            "or": "କିଛି ଦିନ ମଧ୍ୟରେ, ଏକ ଚିଠି ଆସେ । ଇମ୍ରାନ ଭାଇ ଘରେ ଥିବା ବେଳେ ସେ ଏହା ବିଷୟରେ ଅମ୍ମି ଏବଂ ଅବୁଙ୍କୁ କହିବାକୁ ନିଷ୍ପତ୍ତି ନେଲେ ।",
            "as": "কেইদিনমানৰ ভিতৰতে এখন চিঠি আহিল। ইমৰান দাদা ঘৰত থকা সময়ত তাই এই বিষয়ে আম্মী আৰু আব্বুৰ সৈতে কথা পতাৰ সিদ্ধান্ত লয়।",
            "gu": "થોડા દિવસોમાં જ પત્ર આવશે. ઈમરાન દાદા ઘરે હતા ત્યારે તેમણે મારા કાકા અને અબુ સાથે આ વાત કહેવાનું નક્કી કર્યું."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_2//",
            "hi": "A1_2_2//",
            "ka": "A1_2_2//",
            "te": "A1_2_2//",
            "or": "A1_2_2//",
            "as": "A1_2_2//",
            "gu": "A1_2_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Imran",
            "hi": "इमरान",
            "ka": "ಇಮ್ರಾನ್",
            "te": "ఇమ్రాన్",
            "or": "ଇମ୍ରାନ",
            "as": "ইমৰান",
            "gu": "ઈમરાન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "I got my admission letter! I got into the University of Jadavpur!",
            "hi": "मुझे अभी-अभी मेरा एडमिशन लेटर मिला है! जादवपुर युनिवर्सिटी में मेरा दाखला हो गया है!",
            "ka": "ನನ್ನ ಪ್ರವೇಶ ಪತ್ರ ಸಿಕ್ಕಿತು! ನಾನು ಜಾದವ್‌ಪುರ ವಿಶ್ವವಿದ್ಯಾಲಯಕ್ಕೆ ಪ್ರವೇಶಿಸಿದೆ!",
            "te": "నేను జాదవ్‌పూర్ విశ్వవిద్యాలయంలో చేరడానికి నాకు ప్రవేశ లేఖ వచ్చింది!",
            "or": "ମୁଁ ମୋର ଆଡମିଶନ ଚିଠି ପାଇଲି! ମୁଁ ଯାଦବପୁର ବିଶ୍ୱବିଦ୍ୟାଳୟରେ ଆଡମିଶନ ପାଇଲି!",
            "as": "মই মোৰ নামভৰ্তিপত্ৰ পাইছোঁ ! মই যাদৱপুৰ বিশ্ববিদ্যালয়ত পাইছোঁ!",
            "gu": "મને મારો નોંધણી પત્ર મળ્યો! હું જાદવપુર યુનિવર્સિટીમાં જોડાઈ!"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Jadavpur! Shabash! I am so proud of you.",
            "hi": "जादवपुर! शाबाश! मुझे तुम पर गर्व है।",
            "ka": "ಜಾದವಪುರ! ಶಭಾಷ್! ನಾನು ನಿನ್ನ ಬಗ್ಗೆ ತುಂಬಾ ಹೆಮ್ಮೆಪಡುತ್ತೇನೆ.",
            "te": "జాదవ్‌పూర్! శభాష్! నాకు చాలా గర్వంగా ఉంది.",
            "or": "ଯାଦବପୁର! ସାବାସ୍! ମୁଁ ତୁମ ପାଇଁ ବହୁତ ଗର୍ବିତ ।",
            "as": "যাদৱপুৰ! চাব্বাছ! মই তোমাক লৈ বহুত গৌৰৱান্বিত।",
            "gu": "જાદવપુર! જોરદાર ! મને તારા પર ગર્વ થાય છે."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_3////",
            "hi": "A1_2_3////",
            "ka": "A1_2_3////",
            "te": "A1_2_3////",
            "or": "A1_2_3/////",
            "as": "A1_2_3////",
            "gu": "A1_2_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Jadavpur? Where is that?",
            "hi": "जादवपुर? ये कहाँ है?",
            "ka": "ಜಾದವಪುರ? ಅದು ಎಲ್ಲಿದೆ?",
            "te": "జాదవ్‌పూర్? అది ఎక్కడ ఉంది?",
            "or": "ଯାଦବପୁର? ତାହା କେଉଁଠାରେ ଅଛି?",
            "as": "যাদৱপুৰ? সেইখন ক'ত আছে?",
            "gu": "જાદવપુર? તે ક્યાં છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "It’s a very famous college in Kolkata.",
            "hi": "यह कोलकाता का बहुत फेमस कॉलेज है।",
            "ka": "ಇದು ಕೋಲ್ಕತ್ತಾದ ಅತ್ಯಂತ ಪ್ರಸಿದ್ಧ ಕಾಲೇಜು.",
            "te": "ఇది కోల్‌కతాలోని చాలా ప్రసిద్ధ కళాశాల.",
            "or": "ଏହା କୋଲକାତାର ଏକ ପ୍ରସିଦ୍ଧ କଲେଜ ।",
            "as": "এইখন কলকাতাৰ এখন অতি বিখ্যাত কলেজ।",
            "gu": "તે કોલકત્તાની ખૂબ જ પ્રખ્યાત કોલેજ  છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Kolkata!? You’re surely not planning to go there right?",
            "hi": "कोलकाता? कहीं तुम सच में वहाँ पर जाने अ प्लैन तो नहीं बना रही हो?",
            "ka": "ಕೋಲ್ಕತ್ತಾ!? ನೀನು ಖಂಡಿತವಾಗಿಯೂ ಅಲ್ಲಿಗೆ ಹೋಗಲು ಯೋಜಿಸುತ್ತಿಲವಲ್ಲವೇ?",
            "te": "కోల్‌కతా!? మీరు ఖచ్చితంగా అక్కడికి వెళ్లాలని అనుకోలేదా?",
            "or": "କୋଲକାତା!? ତୁମେ ନିଶ୍ଚିତ ଭାବରେ ସେଠାକୁ ଯିବାକୁ ଯୋଜନା କରୁନ?",
            "as": "কলকাতা!? তুমি নিশ্চিতভাৱে তালৈ যোৱাৰ পৰিকল্পনা কৰা নাইতো?",
            "gu": "કોલકત્તા!?  ચોક્કસ તું ત્યાં જવાનું વિચારી રહી નથી?"
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_4(d)",
            "hi": "A1_2_4(d)",
            "ka": "A1_2_4(d)",
            "te": "A1_2_4(d)",
            "or": "A1_2_4(d)",
            "as": "A1_2_4(d)",
            "gu": "A1_2_4(d)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Razia say?",
            "hi": "रजिया को क्या कहना चाहिए?",
            "ka": "ರಝಿಯಾ ಏನು ಹೇಳಬೇಕು?",
            "te": "రజియా ఏం చెప్పాలి?",
            "or": "ରଜିଆ କ'ଣ କହିବା ଉଚିତ୍?",
            "as": "ৰাজিয়াই কি কোৱা উচিত?",
            "gu": " રઝિયા શું કહેવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Yes! Dada can take me there, he knows the city very well.",
            "hi": "हाँ! मैं दादा के साथ वहाँ पर जा सकती हूँ, वह उस शहर को अच्छे से जानता है।",
            "ka": "ಹೌದು! ದಾದಾ ನನ್ನನ್ನು ಅಲ್ಲಿಗೆ ಕರೆದೊಯ್ಯಬಹುದು, ಅವನಿಗೆ ನಗರವು ಚೆನ್ನಾಗಿ ತಿಳಿದಿದೆ.",
            "te": "అవును! అన్నయ్య నన్ను అక్కడికి తీసుకెళ్లగలడు, అతనికి నగరం గురించి బాగా తెలుసు.",
            "or": "ହଁ! ଭାଇ ମୋତେ ସେଠାକୁ ନେଇପାରିବେ, ସେ ସହରକୁ ଭଲ ଭାବରେ ଜାଣନ୍ତି ।",
            "as": "হয়! দাদাই মোক তালৈ লৈ যাব পাৰে, তেওঁ চহৰখন ভালদৰে জানে।",
            "gu": "હા! દાદા મને ત્યાં લઈ જઈ શકે છે, તેઓને તે શહેરની સારી રીતે ખબર છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "I got admission… so now I have to go there.",
            "hi": "मुझे एडमिशन मिला है... तो अब मुझे वहाँ पर जाना होगा।",
            "ka": "ನನಗೆ ಪ್ರವೇಶ ಸಿಕ್ಕಿತು... ಹಾಗಾಗಿ ಈಗ ನಾನು ಅಲ್ಲಿಗೆ ಹೋಗಬೇಕಾಗಿದೆ.",
            "te": "నాకు ప్రవేశం వచ్చింది... కాబట్టి ఇప్పుడు నేను అక్కడికి వెళ్లాలి.",
            "or": "ମୁଁ ଆଡମିଶନ ପାଇଲି... ତେଣୁ ବର୍ତ୍ତମାନ ମୋତେ ସେଠାକୁ ଯିବାକୁ ପଡିବ ।",
            "as": "মই নামভৰ্তি পাইছোঁ... গতিকে এতিয়া মই তালৈ যাব লাগিব।",
            "gu": "મને ત્યાં પ્રવેશ મળી ગયો છે… તો હવે મારે ત્યાં જવું પડશે."
          }
        }
      ],
      "page12": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_5////",
            "hi": "A1_2_5////",
            "ka": "A1_2_5////",
            "te": "A1_2_5////",
            "or": "A1_2_5/////",
            "as": "A1_2_5////",
            "gu": "A1_2_5////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Are you going to say something about this?",
            "hi": "क्या आप इसके बारे में कुछ नहीं कहेंगे?",
            "ka": "ಇದರ ಬಗ್ಗೆ ಏನಾದರೂ ಹೇಳಲು ಹೊರಟಿದ್ದೀಯಾ?",
            "te": "మీరు దీని గురించి ఏదైనా చెప్పాలనుకుంటున్నారా?",
            "or": "ତୁମେ ଏ ବିଷୟରେ କିଛି କହିବାକୁ ଯାଉଛ କି?",
            "as": "আপুনি এই বিষয়ে কিবা ক'ব নেকি?",
            "gu": "શું તમે આ વિશે કંઈક કહેવા જઈ રહ્યા છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Education in a big city… Do you think we can afford it, Razia?",
            "hi": "इतने बड़े शहर में पढ़ना… तुम्हे लगता है कि हम इतना खर्च कर पाएंगे, रजिया?",
            "ka": "ದೊಡ್ಡ ನಗರದಲ್ಲಿ ಶಿಕ್ಷಣ... ನಾವು ಅದನ್ನು ಭರಿಸಬಹುದು ಎಂದು ನೀನು ಭಾವಿಸುತ್ತೀಯಾ, ರಝಿಯಾ ?",
            "te": "పెద్ద నగరంలో చదువు... మనం దానికి అంత పెట్టగలమని నువ్వు అనుకుంటున్నావా, రజియా?",
            "or": "ଏକ ବଡ ସହରରେ ଶିକ୍ଷା... ତୁମେ ଭାବୁଛ କି ଆମେ ଏହା କରି ପାରିବା, ରଜିଆ?",
            "as": "এখন ডাঙৰ চহৰত শিক্ষা... তুমি ভাবানে যে আমি ইয়াৰ খৰচ বহন কৰিব পাৰোঁ, ৰাজিয়া?",
            "gu": "મોટા શહેરમાં શિક્ષણ.. રઝિયા, શું તને લાગે છે કે આપણે તે માટે સક્ષમ છીએ?"
          }
        }
      ],
      "page13": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_6(d)",
            "hi": "A1_2_6(d)",
            "ka": "A1_2_6(d)",
            "te": "A1_2_6(d)",
            "or": "A1_2_6(d)",
            "as": "A1_2_6(d)",
            "gu": "A1_2_6(d)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Razia say?",
            "hi": "रजिया को क्या कहना चाहिए?",
            "ka": "ರಝಿಯಾ ಏನು ಹೇಳಬೇಕು?",
            "te": "రజియా ఏం చెప్పాలి?",
            "or": "ରଜିଆ କ'ଣ କହିବା ଉଚିତ୍?",
            "as": "ৰাজিয়াই কি কোৱা উচিত?",
            "gu": "રઝિયાએ શું કહેવું જોઈએ ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "But I’ve got a full scholarship which covers my tuition fees! So that’s one less worry for us.",
            "hi": "लेकिन मुझे फूल स्कोलरशिप मिली हैं जो मेरी ट्यूशन फी कवर करेगी! तो एक चिंता तो कम हो गई है।",
            "ka": "ಆದರೆ ನನ್ನ ಬೋಧನಾ ಶುಲ್ಕವನ್ನು ಒಳಗೊಂಡಿರುವ ಪೂರ್ಣ ವಿದ್ಯಾರ್ಥಿವೇತನವನ್ನು ನಾನು ಪಡೆದುಕೊಂಡಿದ್ದೇನೆ! ಆದ್ದರಿಂದ ನಮಗೆ ಒಂದು ಕಡಿಮೆ ಚಿಂತೆ.",
            "te": "కానీ నాకు పూర్తి ఉపకార వేతనం (స్కాలర్‌షిప్) వచ్చింది,అది నా ట్యూషన్ రుసుములను అన్నిటిని చెల్లిస్తుంది ! కాబట్టి మనం తక్కువ చింతించవచ్చు .",
            "or": "କିନ୍ତୁ ମୋର ଏକ ପୂର୍ଣ୍ଣ ଛାତ୍ରବୃତ୍ତି ଅଛି ଯାହା ମୋର ଟ୍ୟୁସନ୍ ଫି କୁ ଅନ୍ତର୍ଭୁକ୍ତ କରେ! ତେଣୁ ଏହା ଆମ ପାଇଁ କମ୍ ଚିନ୍ତାଜନକ ।",
            "as": "কিন্তু মোৰ এটা সম্পূৰ্ণ বৃত্তি আছে যিয়ে মোৰ টিউচন মাচুল সামৰি লয়! গতিকে ইয়াৰ দ্বাৰা আমাৰ এটা চিন্তা কম হৈছে।",
            "gu": "પરંતુ મને સંપૂર્ણ શિષ્યવૃત્તિ મળી જેમાં મારી ટ્યુશન ફીનો સમાવેશ થાય છે! તેથી તે મારી ચિંતા ઓછી કરે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "I have such a great opportunity in my hands. Why should money be of any concern?",
            "hi": "मेरे पास इतना अच्छा मौका है। तो मुझे पैसों की फिक्र क्यों करनी चाहिए?",
            "ka": "ನನ್ನ ಕೈಯಲ್ಲಿ ಅಂತಹ ಉತ್ತಮ ಅವಕಾಶವಿದೆ. ಹಣವು ಏಕೆ ಕಾಳಜಿ ವಹಿಸಬೇಕು?",
            "te": "అలాంటి గొప్ప అవకాశం నా చేతిలో ఉంది. డబ్బు గురించి ఎందుకు ఆందోళన చెందాలి?",
            "or": "ମୋ ହାତରେ ଏତେ ଭଲ ସୁଯୋଗ ଅଛି । ଟଙ୍କା କାହିଁକି କୌଣସି ଚିନ୍ତାର ବିଷୟ ହେବ?",
            "as": "মোৰ হাতত ইমান এটা ভাল সুযোগ আছে। টকা কিয় কোনো চিন্তাৰ বিষয় হ'ব লাগে?",
            "gu": "આટલી મોટી તક મારા હાથમાં છે. પૈસાની શા માટે ચિંતા કરીએ."
          }
        }
      ],
      "page14": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_7//",
            "hi": "A1_2_7//",
            "ka": "A1_2_7//",
            "te": "A1_2_7//",
            "or": "A1_2_7//",
            "as": "A1_2_7//",
            "gu": "A1_2_7//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Ammi",
            "hi": "अम्मी",
            "ka": "ಅಮ್ಮಿ",
            "te": "అమ్మ",
            "or": "ଅମ୍ମି",
            "as": "আম্মী",
            "gu": "અમ્મી"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "You think money is our only concern? What about your safety? Where will you stay in that big city?",
            "hi": "क्या तुम्हें लगता है कि हमें बस पैसों की चिंता है? तुम्हारी सुरक्षा के बारे में क्या? इतने बड़े शहर में तुम कहाँ रहोगी?",
            "ka": "ಹಣವು ನಮ್ಮ ಏಕೈಕ ಕಾಳಜಿ ಎಂದು ನೀನು ಭಾವಿಸುತ್ತೀಯಾ? ನಿನ್ನ ಸುರಕ್ಷತೆಯ ಬಗ್ಗೆ ಏನು? ಆ ದೊಡ್ಡ ನಗರದಲ್ಲಿ ನೀನು ಎಲ್ಲಿ ಉಳಿಯುತ್ತೀಯಾ?",
            "te": "డబ్బు మాత్రమే మా ఆందోళన అని నువ్వు అనుకుంటున్నావా ? నీ భద్రత గురించి ఏమి అంటావు ? ఆ పెద్ద నగరంలో నువ్వు ఎక్కడ ఉంటావు ?",
            "or": "ତୁମେ ଭାବୁଥିବେ ଟଙ୍କା ଆମର ଏକମାତ୍ର ଚିନ୍ତା? ତୁମର ସୁରକ୍ଷା ବିଷୟରେ କ'ଣ? ତୁମେ ସେହି ବଡ ସହରରେ କେଉଁଠାରେ ରହିବ?",
            "as": "তুমি ভাবা যে অকল টকাই আমাৰ একমাত্ৰ চিন্তা? তোমাৰ সুৰক্ষাৰ বিষয়ে কি? তুমি সেই ডাঙৰ চহৰখনত ক'ত থাকিবা?",
            "gu": "તને લાગે છે કે પૈસા જ આપણી ચિંતા છે? તારી સલામતી વિશે શું? તું ક્યાં રહીશ એ મોટા શહેરમાં?"
          }
        }
      ],
      "page15": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_8(d)",
            "hi": "A1_2_8(d)",
            "ka": "A1_2_8(d)",
            "te": "A1_2_8(d)",
            "or": "A1_2_8(d)",
            "as": "A1_2_8(d)",
            "gu": "A1_2_8(d)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Razia say?",
            "hi": "रजिया को क्या जवाब देना चाहिए?",
            "ka": "ರಝಿಯಾ ಏನು ಹೇಳಬೇಕು?",
            "te": "రజియా ఏం చెప్పాలి?",
            "or": "ରଜିଆ କ'ଣ କହିବା ଉଚିତ୍?",
            "as": "ৰাজিয়াই কি কোৱা উচিত?",
            "gu": "રઝિયાએ શું કહેવું જોઈએ ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "The university has good hostel facilities for girls and the campus is also secure.",
            "hi": "युनिवर्सिटी में लड़कियों के लिए अच्छी हॉस्टल सुविधा उपलब्ध है और कैम्पस भी सुरक्षित है।",
            "ka": "ವಿಶ್ವವಿದ್ಯಾನಿಲಯವು ಹುಡುಗಿಯರಿಗೆ ಉತ್ತಮ ಹಾಸ್ಟೆಲ್ ಸೌಲಭ್ಯಗಳನ್ನು ಹೊಂದಿದೆ ಮತ್ತು ಕ್ಯಾಂಪಸ್ ಕೂಡ ಸುರಕ್ಷಿತವಾಗಿದೆ.",
            "te": "విశ్వవిద్యాలయం లో బాలికలకు మంచి వసతి సౌకర్యాలు ఉన్నాయి మరియు విశ్వవిద్యాలయ ప్రాంగణం కూడా సురక్షితంగా ఉంటుంది .",
            "or": "ବିଶ୍ୱବିଦ୍ୟାଳୟରେ ଝିଅମାନଙ୍କ ପାଇଁ ଭଲ ହଷ୍ଟେଲ ସୁବିଧା ଅଛି ଏବଂ କ୍ୟାମ୍ପସ ମଧ୍ୟ ସୁରକ୍ଷିତ ।",
            "as": "বিশ্ববিদ্যালয়খনত ছোৱালীৰ বাবে ভাল হোষ্টেলৰ সুবিধা আছে আৰু চৌহদটো সুৰক্ষিত।",
            "gu": "છોકરીઓ માટે યુનિવર્સિટીમાં હોસ્ટેલની સારી સુવિધા છે અને કેમ્પસ પણ સલામત છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Ammi I'm grown up now! I will live in the girls hostel.",
            "hi": "अम्मी, अब में बड़ी हो गई हूँ! मैं लडकियों के हॉस्टल में रह सकती हूँ।",
            "ka": "ಅಮ್ಮಿ ನಾನು ಈಗ ಬೆಳೆದಿದ್ದೇನೆ! ನಾನು ಹುಡುಗಿಯರ ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿ ವಾಸಿಸುತ್ತೇನೆ",
            "te": "అమ్మ నేను ఇప్పుడు పెద్దదానిని! నేను బాలికల వసతి గృహం లో ఉంటాను.",
            "or": "ଅମ୍ମି ମୁଁ ବର୍ତ୍ତମାନ ବଡ ହୋଇଛି! ମୁଁ ବାଳିକା ହଷ୍ଟେଲରେ ରହିବି ।",
            "as": "আম্মী মই এতিয়া ডাঙৰ হৈছো! মই ছোৱালীৰ হোষ্টেলত থাকিম।",
            "gu": "અમ્મી હવે હું મોટી થઈ ગઈ છું! હું ગર્લ્સ હોસ્ટેલમાં રહીશ."
          }
        }
      ],
      "page16": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_9//",
            "hi": "A1_2_9//",
            "ka": "A1_2_9//",
            "te": "A1_2_9//",
            "or": "A1_2_9//",
            "as": "A1_2_9//",
            "gu": "A1_2_9//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Ammi",
            "hi": "अम्मी",
            "ka": "ಅಮ್ಮಿ",
            "te": "అమ్మ",
            "or": "ଅମ୍ମି",
            "as": "আম্মী",
            "gu": "અમ્મી"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Maryam mausi was right, girls become too independent these days.",
            "hi": "मरियम मौसी सही कह रह थी, आजकल की लड़कियां काफी इंडिपेंडेंट हो गई है।",
            "ka": "ಮೇರಿಯಮ್ ಮೌಸಿ ಹೇಳಿದ್ದು ಸರಿ, ಇತ್ತೀಚಿನ ದಿನಗಳಲ್ಲಿ ಹುಡುಗಿಯರು ತುಂಬಾ ಸ್ವತಂತ್ರರಾಗುತ್ತಾರೆ.",
            "te": "మరియం అత్తయ్య చెప్పింది నిజమే, ఈ రోజుల్లో ఆడపిల్లలు చాలా స్వతంత్రులుగా మారుతున్నారు.",
            "or": "ମରିୟମ ମାଉସୀ ଠିକ୍ ଥିଲେ, ଝିଅମାନେ ଆଜିକାଲି ଅତ୍ୟଧିକ ସ୍ୱାଧୀନ ହୋଇ ଗଲେଣି ।",
            "as": "মৰিয়ম মৌচিয়ে ঠিকেই কৈছিল, ছোৱালীবোৰ আজিকালি বহুত বেছি স্বাধীন হৈ পৰিছে।",
            "gu": "મરિયમ મૌસીની વાત સાચી હતી, કે આજકાલ છોકરીઓ પોતાના રીતે ઘણી સ્વતંત્ર થઈ ગઈ છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "I don’t understand what you mean.",
            "hi": "तुम्हारे कहने का मतलब क्या है, मैं समझ नहीं।",
            "ka": "ನೀವು ಏನು ಹೇಳುತ್ತೀರಿ ಎಂದು ನನಗೆ ಅರ್ಥವಾಗುತ್ತಿಲ್ಲ.",
            "te": "మీరు ఏమంటున్నారో నాకు అర్థం కాలేదు.",
            "or": "ତୁମେ କ'ଣ କହିବାକୁ ଚାହୁଁଛ ମୁଁ ବୁଝିପାରୁ ନାହିଁ ।",
            "as": "আপুনি কি বুজাইছে মই বুজি পোৱা নাই।",
            "gu": "હું જે સમજાવવા માંગુ છું તે તું નથી સમજતી"
          }
        }
      ],
      "page17": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_10////",
            "hi": "A1_2_10////",
            "ka": "A1_2_10////",
            "te": "A1_2_10////",
            "or": "A1_2_10/////",
            "as": "A1_2_10////",
            "gu": "A1_2_10////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Unmarried girls cannot leave their families. And if you study so much, who will accept your hand in marriage?",
            "hi": "कुँवारी लड़कियां अपने परिवार से दूर नहीं रह सकती। और अगर तुम इतनी पढ़ाई करोगी तो तुमसे शादी कौन करेगा?",
            "ka": "ಅವಿವಾಹಿತ ಹುಡುಗಿಯರು ತಮ್ಮ ಕುಟುಂಬವನ್ನು ಬಿಟ್ಟು ಹೋಗುವಂತಿಲ್ಲ. ಮತ್ತು ನೀವು ತುಂಬಾ ಅಧ್ಯಯನ ಮಾಡಿದರೆ, ನಿಮ್ಮ ಮದುವೆಯನ್ನು ಯಾರು ಒಪ್ಪಿಕೊಳ್ಳುತ್ತಾರೆ?",
            "te": "పెళ్లికాని అమ్మాయిలు తమ కుటుంబాలను వదిలి వెళ్లకూడదు. మరి ఇంత చదువుకుంటే నీతో పెళ్లికి ఎవరు ఒప్పుకుంటారు?",
            "or": "ଅବିବାହିତ ଝିଅମାନେ ସେମାନଙ୍କ ପରିବାର ଛାଡି ପାରିବେ ନାହିଁ । ଆଉ ଯଦି ତୁମେ ଏତେ ଅଧ୍ୟୟନ କର, ତେବେ କିଏ ତୁମକୁ ବିବାହ କରିବ?",
            "as": "অবিবাহিত ছোৱালীবোৰে তেওঁলোকৰ পৰিয়াল এৰিব নোৱাৰে। আৰু যদি তুমি ইমান পঢ়া শুনা কৰা, তোমাক কোনে বিয়া কৰাব বিচাৰিব ?",
            "gu": "અપરિણીત છોકરીઓ તેમના કુટુંબથી દૂર નથી જઈ શકતી. અને તું વધારે ભણીશ તો તારી સાથે લગ્ન કોણ કરશે?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Why are you saying this? I thought you would be happy that I’ve got the admission.",
            "hi": "यह क्या कह रही है आप? मुझे तो लगा था कि आपको मुझे एडमिशन मिलने पर खुशी होगी।",
            "ka": "ಯಾಕೆ ಹೀಗೆ ಹೇಳುತ್ತಿರುವೆ? ನಾನು ಪ್ರವೇಶ ಪಡೆದಿರುವುದಕ್ಕೆ ನೀವು ಸಂತೋಷಪಡುತ್ತೀರಿ ಎಂದು ನಾನು ಭಾವಿಸಿದೆ.",
            "te": "ఇలా ఎందుకు చెప్తున్నావు? నాకు ప్రవేశం వచ్చినందుకు మీరు సంతోషిస్తారని నేను అనుకున్నాను.",
            "or": "ତୁମେ ଏମିତି କାହିଁକି କହୁଛ? ମୁଁ ଭାବିଲି ତୁମେ ଖୁସି ହେବ ଯେ ମୁଁ ଆଡମିଶନ ପାଇଛି ।",
            "as": "আপুনি এনেকে কিয় কৈছে? মই ভাবিছিলো যে মই নামভৰ্তি কৰাত আপুনি সুখী হ'ব।",
            "gu": "તમે આવું કેમ કહો છો? મને એમ હતું કે મને એડમિશન મળી ગયું છે તેનાથી તમે ખુશ થશો."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "You’re too young to understand these things.",
            "hi": "यह सब बातें समझने के लिए तुम अभी छोटी हो।",
            "ka": "ಈ ವಿಷಯಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ನೀನು ತುಂಬಾ ಚಿಕ್ಕವಳು.",
            "te": "మీరు ఈ విషయాలను అర్థం చేసుకునేందుకు చాలా చిన్న వయస్సులో ఉన్నారు.",
            "or": "ଏହି ଜିନିଷଗୁଡିକ ବୁଝିବା ପାଇଁ ତୁମେ ବହୁତ ଛୋଟ ।",
            "as": "তুমি এই কথাবোৰ বুজিবলৈ এতিয়াও বহুত সৰু হৈ আছা।",
            "gu": "આ બાબતો સમજવા માટે તું ઘણી નાની છે."
          }
        }
      ],
      "page18": [
        {
          "valuePath": "display",
          "value": {
            "en": "A1_2_11(D)",
            "hi": "A1_2_11(D)",
            "ka": "A1_2_11(D)",
            "te": "A1_2_11(D)",
            "or": "A1_2_11(D)",
            "as": "A1_2_11(D)",
            "gu": "A1_2_11(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Razia say?",
            "hi": "रजिया की क्या कहना चाहिए?",
            "ka": "ರಝಿಯಾ ಏನು ಹೇಳಬೇಕು?",
            "te": "రజియా ఏం చెప్పాలి?",
            "or": "ରଜିଆ କ'ଣ କହିବା ଉଚିତ୍?",
            "as": "ৰাজিয়াই কি কোৱা উচিত?",
            "gu": "રઝિયાએ શું કહેવું જોઈએ ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "But Abbu this is our chance to do better as a family!",
            "hi": "लेकिन अब्बू, यहीं तो मौका कि हमारा परिवार और बेहतर बन सकें!",
            "ka": "ಆದರೆ ಅಬ್ಬು ಕುಟುಂಬವಾಗಿ ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸಲು ಇದು ನಮ್ಮ ಅವಕಾಶ!",
            "te": "కానీ నాన్న, మన కుటుంబం మంచిగా అవ్వడానికి ఇది మంచి అవకాశం!",
            "or": "କିନ୍ତୁ ଅବୁ ଏହା ଆମର ଏକ ପରିବାର ଭାବରେ ଭଲ କରିବାର ସୁଯୋଗ!",
            "as": "কিন্তু আব্বু এটা পৰিয়াল হিচাপে এইটো আমাৰ বাবে এটা ভাল কৰাৰ সুযোগ!",
            "gu": "પણ અબ્બુ, કુટુંબનું વધુ સારું કરવાની આ મારી\n તક છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "I understand Abbu, I will stay in the village.",
            "hi": "मैं समझती हूँ, अब्बू, मैं गाँव में ही रहूंगी।",
            "ka": "ನನಗೆ ಅರ್ಥವಾಯಿತು ಅಬ್ಬು, ನಾನು ಹಳ್ಳಿಯಲ್ಲಿಯೇ ಇರುತ್ತೇನೆ.",
            "te": "నాకు అర్థమైంది నాన్న, నేను ఊర్లోనే ఉంటాను.",
            "or": "ମୁଁ ବୁଝିପାରୁଛି ଅବୁ, ମୁଁ ଗାଁରେ ରହିବି ।",
            "as": "মই বুজি পাইছো আব্বু, মই গাঁৱতে থাকিম।",
            "gu": "હું અબ્બુને સમજું છું, હું ગામમાં જ રહીશ."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.tag",
          "value": {
            "en": "Long term investment",
            "hi": "लॉन्ग टर्म इन्वेस्टमेंट",
            "ka": "ದೀರ್ಘಾವಧಿ ಹೂಡಿಕೆ",
            "te": "దీర్ఘకాలిక పెట్టుబడి",
            "or": "ଦୀର୍ଘକାଳୀନ ବିନିଯୋଗ",
            "as": "দীঘলীয়া সময়ৰ বাবে বিনিয়োগ",
            "gu": "પણ અબ્બુ, કુટુંબનું કઈ સારું કરવા માટેની આ મારી તક છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.tag",
          "value": {
            "en": "Parents approval",
            "hi": "माता-पिता की मंजूरी",
            "ka": "ಪೋಷಕರ ಅನುಮೋದನೆ",
            "te": "తల్లిదండ్రుల అనుమతి",
            "or": "ପିତାମାତାଙ୍କ ଅନୁମୋଦନ",
            "as": "অভিভাৱকৰ অনুমোদন",
            "gu": "માતાપિતાની મંજૂરી જોઈએ"
          }
        }
      ]
    },
    "scene9": {
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "Negotiation",
            "hi": "निगोशिएट करना",
            "ka": "ಮಾತುಕತೆ",
            "te": "నెగోషియేషన్ (చర్చల)",
            "or": "ବୁଝାମଣା",
            "as": "বুজাবুজি",
            "gu": "વાટાઘાટો પણ હોય"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained negotiation experience!",
            "hi": "आपने अभी-अभी निगोशिएट करने का अनुभव प्राप्त किया है!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಮಾತುಕತೆಯ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే నెగోషియేషన్ (చర్చల) అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ବୁଝାମଣା ଅଭିଜ୍ଞତା ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ বুজাবুজিৰে কেনেদৰে কাম কৰিব লাগে তাৰ অভিজ্ঞতা লাভ কৰিছা!",
            "gu": "તમે હમણાં જ વાટાઘાટોનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Negotiation",
            "hi": "निगोशिएट करना",
            "ka": "ಮಾತುಕತೆ",
            "te": "నెగోషియేషన్ (చర్చల)",
            "or": "ବୁଝାମଣା",
            "as": "বুজাবুজি",
            "gu": "વાટાઘાટો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर डेवलपमेंट कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "కెరీర్ అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ୟାରିୟର ବିକାଶ ଦକ୍ଷତା",
            "as": "কেৰিয়াৰ বিকাশৰ দক্ষতা",
            "gu": "કારકિર્દી વિકાસના  કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Razia has to negotiate with her parents about going to the city to study. Negotiation is a discussion between people to reach an agreement on a decision when you have different points of view. We need to negotiate both in our personal and professional life. It requires you to be clear about what you are looking for and have a conversation with another person on why it is crucial for you. It also requires you to be open to listening and change your mind if what the other person is saying makes sense for you.",
            "hi": "रजिया को शहर में पढने के लिए जाने के बारे में अपने माता-पिता के साथ निगोशिएट करना होगा। निगोशिएट करना मतलब अलग विचारों के लोग आपस में चर्चा करके किसी एक निर्णय पर सहमत होते हैं। हमें अपनी व्यक्तिगत जीवन और पेशे में निगोशिएट करना पड़ता हैं। इसके लिए जरुरी है कि आप को पता हो कि आप क्या चाहते हैं और फिर दुसरे व्यक्ति से इस बात पर चर्चा करो की यह आपके लिए इतना महत्वपूर्ण क्यों हैं। साथ ही आपको सामने वाले की बात भी सुननी चाहिए और अगर उसकी बातें सही लगे तो अपने विचारों को भी बदलना चाहिए।",
            "ka": "ಓದಲು ನಗರಕ್ಕೆ ಹೋಗುವ ಬಗ್ಗೆ ರಝಿಯಾ ತನ್ನ ಪೋಷಕರೊಂದಿಗೆ ಮಾತುಕತೆ ನಡೆಸಬೇಕು.ನೀವು ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳನ್ನು ಹೊಂದಿರುವಾಗ ನಿರ್ಧಾರದ ಮೇಲೆ ಒಪ್ಪಂದವನ್ನು ತಲುಪಲು ಜನರ ನಡುವಿನ ಚರ್ಚೆಯೇ ಮಾತುಕತೆಯಾಗಿದೆ.ನಮ್ಮ ವೈಯಕ್ತಿಕ ಮತ್ತು ವೃತ್ತಿಪರ ಜೀವನದಲ್ಲಿ ನಾವು ಮಾತುಕತೆ ನಡೆಸಬೇಕಾಗಿದೆ.ನೀವು ಏನನ್ನು ಹುಡುಕುತ್ತಿದ್ದೀರಿ ಎಂಬುದರ ಕುರಿತು ನೀವು ಸ್ಪಷ್ಟವಾಗಿರಬೇಕು ಮತ್ತು ಅದು ನಿಮಗೆ ಏಕೆ ನಿರ್ಣಾಯಕವಾಗಿದೆ ಎಂಬುದರ ಕುರಿತು ಇನ್ನೊಬ್ಬ ವ್ಯಕ್ತಿಯೊಂದಿಗೆ ಸಂಭಾಷಣೆ ನಡೆಸುವುದು ಅಗತ್ಯವಾಗಿದೆ.ನೀವು ಕೇಳಲು ಮುಕ್ತರಾಗಿರಲು ಮತ್ತು ಇತರ ವ್ಯಕ್ತಿಯು ಹೇಳುತ್ತಿರುವುದು ನಿಮಗೆ ಅರ್ಥವಾಗಿದ್ದರೆ ನಿಮ್ಮ ಮನಸ್ಸನ್ನು ಬದಲಾಯಿಸಲು ಸಹ ಇದು ಅಗತ್ಯವಾಗಿರುತ್ತದೆ.",
            "te": "చదువుకోవడానికి పట్టణానికి వెళ్లే విషయంలో రజియా తన తల్లిదండ్రులతో చర్చలు జరపాలి.మీకు భిన్నమైన దృక్కోణాలు ఉన్నప్పుడు ఒక నిర్ణయంపై ఒక అంగీకారానికి రావడానికి వ్యక్తుల మధ్య చర్చను (నెగోషియేషన్ )అంటారు. మన వ్యక్తిగత జీవితంలోనూ, వృత్తి జీవితంలోనూ చర్చలు జరపాలి. మీరు వెతుకుతున్న దాని గురించి మీరు స్పష్టంగా తెలుసుకోవాలి మరియు అది మీకు ఎందుకు కీలకం అనే దానిపై మరొక వ్యక్తితో సంభాషణను కలిగి ఉండాలి. మీరు వినడానికి సిద్ధంగా ఉండాలి మరియు అవతలి వ్యక్తి చెప్పేది మీకు అర్ధమైతే మీ మనసు మార్చుకోవడం కూడా దీనికి అవసరం.",
            "or": "ରଜିଆଙ୍କୁ ଅଧ୍ୟୟନ କରିବାକୁ ସହର ଯିବା ବିଷୟରେ ତାଙ୍କ ପିତାମାତାଙ୍କ ସହ ବୁଝାମଣା କରିବାକୁ ପଡିବ । ଯେତେବେଳେ ତୁମର ଦୃଷ୍ଟିକୋଣ ଭିନ୍ନ ଥାଏ ସେତେବେଳେ ନିଷ୍ପତ୍ତି ଉପରେ ଏକ ରାଜିନାମାରେ ପହଞ୍ଚିବା ପାଇଁ ଲୋକଙ୍କ ମଧ୍ୟରେ ବୁଝାମଣା ହେଉଛି ଏକ ଆଲୋଚନା । ଆମର ବ୍ୟକ୍ତିଗତ ଏବଂ ବୃତ୍ତିଗତ ଜୀବନରେ ଆମକୁ ବୁଝାମଣା କରିବା ଆବଶ୍ୟକ । ଏହା ତୁମକୁ ତୁମେ କ'ଣ ଖୋଜୁଛ ସେ ବିଷୟରେ ସ୍ପଷ୍ଟ ହେବା ପାଇଁ ଏବଂ ଏହା ତୁମ ପାଇଁ କାହିଁକି ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ସେ ବିଷୟରେ ଅନ୍ୟ ବ୍ୟକ୍ତିଙ୍କ ସହ କଥା ହେବା ପାଇଁ ଆବଶ୍ୟକ । ଯଦି ଅନ୍ୟ ବ୍ୟକ୍ତି ଯାହା କହୁଛନ୍ତି ତାହା ତୁମ ପାଇଁ ଅର୍ଥପୂର୍ଣ୍ଣ ହୁଏ ତେବେ ତୁମକୁ ଏହା ମଧ୍ୟ ଶୁଣିବା ଆବଶ୍ୟକ ଏବଂ ତୁମକୁ ନିଜ ମନ ପରିବର୍ତ୍ତନ କରିବାକୁ ପ୍ରସ୍ତୁତ ରହିବା ଆବଶ୍ୟକ ।",
            "as": "ৰাজিয়াই তাইৰ মাক দেউতাকৰ সৈতে চহৰলৈ পঢ়িবলৈ যোৱাৰ বিষয়ে বুজাবুজি কৰিব লাগিব। যেতিয়া কোনো সিদ্ধান্ত এটাৰ ওপৰত বিভিন্ন জন মানুহৰ বিভিন্ন দৃষ্টিভংগী থাকে তেতিয়া এক চুক্তিত উপনীত হ'বলৈ কৰা আলোচনাই হ'ল এক বুজাবুজি । আমি আমাৰ ব্যক্তিগত আৰু পেছাদাৰী দুয়োটা জীৱনতে বুজাবুজি কৰিব লাগিব। ইয়াৰ বাবে তুমি কি বিচাৰি আছা সেই বিষয়ে স্পষ্ট হ'ব লাগিব আৰু ই তোমাৰ বাবে কিয় গুৰুত্বপূৰ্ণ সেই বিষয়ে আন এজন ব্যক্তিৰ সৈতে আলোচনা কৰিব লাগিব। যদি আনজনে কোৱা কথাবোৰ তোমাৰ বাবে অৰ্থপূৰ্ণ হয় তেনেহ'লে তুমি তেওঁৰ কথা মানি লৈ তোমাৰ মতামত সলনি কৰাটো প্ৰয়োজন।",
            "gu": "રઝિયાને તેના માતા-પિતા સાથે શહેરમાં અભ્યાસ કરવા જવા અંગે વાટાઘાટો કરવી પડી છે. જ્યારે તમારો દૃષ્ટિકોણ અલગ હોય ત્યારે નિર્ણય પર સંમત થવા માટે લોકો વચ્ચેની ચર્ચા એ વાટાઘાટો છે. તમારે તમારા અંગત અને વ્યાવસાયિક જીવનમાં વાટાઘાટો કરવાની જરૂર પડે છે. તમે જે શોધી રહ્યા છો તેના વિશે તમારે સ્પષ્ટ હોવું જોઈએ અને તે તમારા માટે કેવી રીતે  મહત્વપૂર્ણ છે તે વિશે અન્ય વ્યક્તિ સાથે વાત કરવાની જરૂર છે. જો બીજી વ્યક્તિ જે કહે છે તે તમને સમજમાં આવે છે, તો તેનો સ્વીકાર કરવા માટે તમારે ખુલ્લા મને તમારો વિચાર બદલવાની જરૂર છે અને સામેની વ્યક્તિની વાત સ્વીકારવા માટે તૈયારી રાખવી"
          }
        }
      ],
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "A2_1_1//_Go for higher studies",
            "hi": "हायर स्टडीज करना",
            "ka": "A2_1_1//_ಉನ್ನತ ವ್ಯಾಸಂಗಕ್ಕೆ ಹೋಗಿ",
            "te": "A2_1_1//_ఉన్నత చదువుల కోసం వెళ్ళడం",
            "or": "A2_1_1//_ଉଚ୍ଚ ଅଧ୍ୟୟନ ପାଇଁ ଯାଆନ୍ତୁ",
            "as": "A2_1_1//_উচ্চ শিক্ষাৰ বাবে যোৱা",
            "gu": "A2_1_1//_ઉચ્ચ અભ્યાસ માટે આગળ વધો"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Abbu",
            "hi": "अब्बू",
            "ka": "ಅಬ್ಬು",
            "te": "నాన్న",
            "or": "ଅବୁ",
            "as": "আব্বু",
            "gu": "અબ્બુ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "But Abbu this is our chance to do better as a family!",
            "hi": "लेकिन अब्बू, यहीं तो मौका कि हमारा परिवार और बेहतर बन सकें!",
            "ka": "ಆದರೆ ಅಬ್ಬು ಕುಟುಂಬವಾಗಿ ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸಲು ಇದು ನಮ್ಮ ಅವಕಾಶ!",
            "te": "కానీ నాన్న, మన కుటుంబం మంచిగా అవ్వడానికి ఇది మంచి అవకాశం!",
            "or": "କିନ୍ତୁ ଅବୁ ଏହା ଆମେ ଏକ ପରିବାର ଭାବରେ ଭଲ କରିବାର ସୁଯୋଗ!",
            "as": "কিন্তু আব্বু এটা পৰিয়াল হিচাপে এইটো আমাৰ বাবে এটা ভাল কৰাৰ সুযোগ!",
            "gu": "પણ અબ્બુ એક કુટુંબ તરીકે કઈક સારું કરવા માટેનો મારી પાસે આ મોકો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Why can’t you look for courses nearby? That way you can stay close to us.",
            "hi": "तुम कहीं नजदीक कोर्स क्यों नहीं ढूंढती? इस तरह से तुम हमारे पास रह सकोगी।",
            "ka": "ನೀನು ಹತ್ತಿರದ ಕೋರ್ಸ್‌ಗಳನ್ನು ಏಕೆ ಹುಡುಕಬಾರದು? ಆ ಮೂಲಕ ನೀನು ನಮ್ಮ ಹತ್ತಿರ ಉಳಿಯಬಹುದು.",
            "te": "సమీపంలోని కోర్సులు చూసుకోవచ్చు కదా? ఆ విధంగా నువ్వు మాకు దగ్గరగా ఉండవచ్చు",
            "or": "ତୁମେ କାହିଁକି ନିକଟବର୍ତ୍ତୀ କୌଣସି ପାଠ୍ୟକ୍ରମ ଖୋଜି ପାରିବ ନାହିଁ? ଏହିପରି ତୁମେ ଆମ ପାଖରେ ରହିପାରିବ ।",
            "as": "তুমি ওচৰৰ পাঠ্যক্ৰম এটা কিয় বিচাৰিব নোৱাৰা? এনেদৰে তুমি আমাৰ ওচৰত থাকিব পাৰিবা।",
            "gu": "શા માટે તું નજીકમાં આવો કોર્સ શોધી શકતી નથી? આ રીતે તું અમારી નજીક રહી શકીશ"
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "A2_1_2(d)",
            "hi": "A2_1_2(d)",
            "ka": "A2_1_2(d)",
            "te": "A2_1_2(d)",
            "or": "A2_1_2(d)",
            "as": "A2_1_2(d)",
            "gu": "A2_1_2 (d)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Razia says:",
            "hi": "रजिया कहती है:",
            "ka": "ರಝಿಯಾ ಹೇಳುತ್ತಾಳೆ:",
            "te": "రజియా చెప్పింది:",
            "or": "ରଜିଆ କୁହନ୍ତି:",
            "as": "ৰাজিয়াই কয়:",
            "gu": "રઝિયા કહે છે:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Abbu, I would not go so far to study if this was available in any colleges nearby.",
            "hi": "अब्बू, अगर यह कोर्स किसी नजदीकी कॉलेज में उपलब्ध होता तो मैं इतनी दूर का कॉलेज नहीं चुनती।",
            "ka": "ಅಬ್ಬು, ಇದು ಹತ್ತಿರದ ಯಾವುದಾದರೂ ಕಾಲೇಜಿನಲ್ಲಿ ಲಭ್ಯವಿದ್ದರೆ ನಾನು ಓದಲು ಇಷ್ಟು ದೂರ ಹೋಗುವುದಿಲ್ಲ.",
            "te": "నాన్న, దగ్గరలో ఉన్న కాలేజీల్లో ఇది ఉంటే నేను అంత దూరం వెళ్ళి చదువుకోను.",
            "or": "ଅବୁ, ଯଦି ଏହା ନିକଟସ୍ଥ କୌଣସି କଲେଜରେ ଉପଲବ୍ଧ ଥା’ନ୍ତା ତେବେ ମୁଁ ଅଧ୍ୟୟନ କରିବାକୁ ଏତେ ଦୂରକୁ ଯାଇ ନ’ଥାନ୍ତି ।",
            "as": "আব্বু, ওচৰৰ কোনো কলেজত এইটো বিষয় থকা হ'লে মই পঢ়িবলৈ ইমান দূৰলৈ নাযাওঁ।",
            "gu": "અબ્બુ, જો તે અભ્યાસ નજીકની કોલેજમાં ઉપલબ્ધ હોત, તો હું આટલે દૂર ભણવા ન જાત."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Abbu I really want to go to the city!",
            "hi": "अब्बू, मैं सच में शहर में जाना चाहती हूँ!",
            "ka": "ಅಬ್ಬು ನಾನು ನಿಜವಾಗಿಯೂ ನಗರಕ್ಕೆ ಹೋಗಲು ಬಯಸುತ್ತೇನೆ!",
            "te": "నాన్న నాకు నిజంగా పట్టణానికి వెళ్లాలని ఉంది!",
            "or": "ଅବୁ ମୁଁ ପ୍ରକୃତରେ ସହରକୁ ଯିବାକୁ ଚାହୁଁଛି!",
            "as": "আব্বু মই সঁচাকৈয়ে চহৰলৈ যাব বিচাৰোঁ!",
            "gu": "અબ્બુ મારે ખરેખર તે શહેરમાં જવું છે!"
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "A2_1_3////",
            "hi": "A2_1_3////",
            "ka": "A2_1_3////",
            "te": "A2_1_3////",
            "or": "A2_1_3/////",
            "as": "A2_1_3////",
            "gu": "A2_1_3 ////"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Imran who had been patiently listening all this while decides to step in.",
            "hi": "इमरान अब तक शांति से यह सब सुन रहा था, लेकिन अब उसने अपनी बात कहने तय किया।",
            "ka": "ಇದೆಲ್ಲವನ್ನೂ ತಾಳ್ಮೆಯಿಂದ ಕೇಳುತ್ತಿದ್ದ ಇಮ್ರಾನ್ ಹೆಜ್ಜೆ ಹಾಕಲು ನಿರ್ಧರಿಸಿದ್ದಾರೆ.",
            "te": "ఇదంతా ఓపికగా వింటున్న ఇమ్రాన్ ఇంక మాట్లాడాలనుకున్నాడు.",
            "or": "ଇମ୍ରାନ ଯିଏ ଧୈର୍ଯ୍ୟର ସହିତ ଏସବୁ ଶୁଣୁଥିଲେ, ଭିତରକୁ ଯିବାକୁ ନିଷ୍ପତ୍ତି ନେଲେ ।",
            "as": "ইমান দেৰি ধৈৰ্য্যসহকাৰে এই সকলোবোৰ শুনাৰ পাছত ইমৰানে কিবা এটা কোৱাৰ সিদ্ধান্ত লয়।",
            "gu": "ઈમરાન, જે શાંતિથી આ બધું સાંભળી રહ્યો હતા તેણે અંદર આવવાનું નક્કી કર્યું."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Abbu, what if she lives with me instead of the hostel?",
            "hi": "अब्बू, अगर यह हॉस्टल की जगह मेरे साथ रहे तो कैसा रहेगा?",
            "ka": "ಅಬ್ಬು, ಅವಳು ಹಾಸ್ಟೆಲ್ ಬಿಟ್ಟು ನನ್ನ ಜೊತೆ ವಾಸವಾದರೆ?",
            "te": "నాన్న, ఆమె వసతి గృహం లో కాకుండా నాతో ఉంటే?",
            "or": "ଅବୁ, ଯଦି ସେ ହଷ୍ଟେଲ ପରିବର୍ତ୍ତେ ମୋ ସହିତ ରୁହନ୍ତି?",
            "as": "আব্বু, যদি তাই হোষ্টেলৰ সলনি মোৰ সৈতে থাকে তেন্তে কেনে হ'ব?",
            "gu": "અબ્બુ, જો તે હોસ્ટેલને બદલે મારી સાથે રહે તો?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "But dada you live 2 hours away from college. How do I travel that much every day? Plus there's so much to learn from the other girls.",
            "hi": "लेकिन दादा आप इस कॉलेज से 2 घंटे की दूरी पर रहते हैं। मैं रोज इतना ट्रेवलिंग कैसे करूंगी? और दूसरी लड़कियों के साथ रहकर मैं बहुत कुछ सीख सकती हूँ।",
            "ka": "ಆದರೆ ನೀವು ಕಾಲೇಜಿನಿಂದ 2 ಗಂಟೆಗಳ ದೂರದಲ್ಲಿ ವಾಸಿಸುತ್ತೀರಿ. ನಾನು ಪ್ರತಿದಿನ ಇಷ್ಟು ಪ್ರಯಾಣ ಮಾಡುವುದು ಹೇಗೆ? ಜೊತೆಗೆ ಇತರ ಹುಡುಗಿಯರಿಂದ ಕಲಿಯಲು ತುಂಬಾ ಇದೆ.",
            "te": "కానీ అన్నయ్య నువ్వు కాలేజీకి 2 గంటల దూరంలో ఉంటావు. నేను ప్రతిరోజూ అంత దూరం ప్రయాణం చేయడం ఎలా? అంతేకాకుండా ఇతర అమ్మాయిల నుండి నేర్చుకోవలసినవి చాలా ఉన్నాయి",
            "or": "କିନ୍ତୁ ଭାଇ ତୁମେ କଲେଜଠାରୁ 2 ଘଣ୍ଟା ଦୂରରେ ରୁହ । ମୁଁ ପ୍ରତିଦିନ ଏତେ ଯାତ୍ରା କିପରି କରିବି? ଏଥିସହ ଅନ୍ୟ ଝିଅମାନଙ୍କଠାରୁ ବହୁତ କିଛି ଶିଖିବାକୁ ଅଛି ।",
            "as": "কিন্তু দাদা আপুনি কলেজৰ পৰা ২ ঘণ্টা আঁতৰত থাকে। মই সদায় ইমান দূৰ বাট কেনেকৈ যাম? লগতে আন ছোৱালীবোৰৰ পৰা মোৰ শিকিবলৈ বহুত আছে।",
            "gu": "પણ દાદા તમે કોલેજથી 2 કલાક અંતરે રહો છો. હું દરરોજ આટલી મુસાફરી કેવી રીતે કરી શકું? ઉપરાંત અન્ય છોકરીઓ પાસેથી ઘણું શીખવાનું છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Razia, this is the least you can do for Ammi and me to be at peace.",
            "hi": "रजिया, अम्मी और मेरे मन की शांति के लिए तुम इतना तो कर सकती हो।",
            "ka": "ರಝಿಯಾ, ಅಮ್ಮಿ ಮತ್ತು ನಾನು ಶಾಂತಿಯಿಂದ ಇರಲು ನೀನು ಮಾಡಬಹುದಾದ ಕನಿಷ್ಠ ಕೆಲಸ ಇದು.",
            "te": "రజియా, అమ్మ మరియు నేను ప్రశాంతంగా ఉండాలంటే నువ్వు చేసే అతి చిన్న పని ఇదే.",
            "or": "ରଜିଆ, ଏହା ହେଉଛି ସର୍ବନିମ୍ନ ତୁମେ ଅମ୍ମି ଏବଂ ମୋ ପାଇଁ ଶାନ୍ତିରେ ରହିବା ପାଇଁ କରିପାରିବ ।",
            "as": "ৰাজিয়া, আম্মী আৰু মই শান্তিত থকাৰ বাবে তুমি অন্ততঃ এইটো কৰিব পাৰা।",
            "gu": "રઝિયા, અમ્મી અને મને શાંતિ મળે તે માટે  તારે આટલું તો  કરવું જ રહયું"
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "A2_1_4(D)",
            "hi": "A2_1_4(D)",
            "ka": "A2_1_4(D)",
            "te": "A2_1_4(D)",
            "or": "A2_1_4(D)",
            "as": "A2_1_4(D)",
            "gu": "A2_1_4(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Razia do?",
            "hi": "रजिया को क्या करना चाहिए?",
            "ka": "ರಝಿಯಾ ಏನು ಮಾಡಬೇಕು?",
            "te": "రజియా ఏం చేయాలి?",
            "or": "ରଜିଆ କ'ଣ କରିବା ଉଚିତ୍?",
            "as": "ৰাজিয়াই কি কৰা উচিত?",
            "gu": "રઝિયાને શું કરવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Stay at the hostel.",
            "hi": "हॉस्टल में रहना।",
            "ka": "ಹಾಸ್ಟೆಲ್ ನಲ್ಲಿ ಇರಬೇಕು.",
            "te": "వసతి గృహం లో ఉండాలి.",
            "or": "ହଷ୍ଟେଲରେ ରହିବେ ।",
            "as": "হোষ্টেলত থাকক।",
            "gu": "હોસ્ટેલમાં રહેવું જોઈએ! "
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Stay with Imran dada.",
            "hi": "इमरान दादा के साथ रहना।",
            "ka": "ಇಮ್ರಾನ್ ದಾದಾ ಜೊತೆ ಇರಬೇಕು.",
            "te": "ఇమ్రాన్ అన్నయ్యతో కలిసి ఉండాలి.",
            "or": "ଇମ୍ରାନ ଭାଇଙ୍କ ସହ ରହିବେ ।",
            "as": "ইমৰান দাদাৰ সৈতে থাকক।",
            "gu": "ઈમરાન દાદા સાથે રહેવું."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.tag",
          "value": {
            "en": "EXPOSURE",
            "hi": "एक्सपोजर",
            "ka": "ಒಡ್ಡುವಿಕೆ",
            "te": "ఎక్స్పోషర్ (అవగాహన/బహిర్గతం)",
            "or": "ଏକ୍ସପୋଜର୍",
            "as": "সদৰী কৰা, প্ৰকাশ কৰা",
            "gu": "EXPOSURE (સંપર્કમાં આવવું/બહાર નીકળવું/"
          }
        }
      ]
    },
    "scene10": {
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "Negotiation level 2_Stay at the hostel",
            "hi": "हॉस्टल में रहना",
            "ka": "ಮಾತುಕತೆ ಹಂತ 2_ಹಾಸ್ಟೆಲ್ ನಲ್ಲಿ ಇರಿ",
            "te": "నెగోషియేషన్ చర్చ స్థాయి 2_హాస్టల్‌లో ఉండాలి",
            "or": "ବୁଝାମଣା ସ୍ତର 2_ ହଷ୍ଟେଲରେ ରହିବେ",
            "as": "বুজাবুজিৰ স্তৰ 2_হোষ্টেলত থাকক",
            "gu": "વાટાઘાટોનું સ્તર 2_ હોસ્ટેલમાં રહેવું"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained negotiation experience!",
            "hi": "आपने अभी-अभी निगोशिएट करने का अनुभव प्राप्त किया है!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಮಾತುಕತೆಯ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే నెగోషియేషన్ (చర్చ ) అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ବୁଝାମଣା ଅଭିଜ୍ଞତା ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ বুজাবুজিৰে কেনেদৰে কাম কৰিব লাগে তাৰ অভিজ্ঞতা লাভ কৰিছা!",
            "gu": "વાટાઘાટોનો અનુભવ તમે હમણાં જ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Negotiation",
            "hi": "निगोशिएट करना",
            "ka": "ಮಾತುಕತೆ",
            "te": "నెగోషియేషన్ (చర్చ)",
            "or": "ବୁଝାମଣା",
            "as": "বুজাবুজি",
            "gu": "વાટાઘાટો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर डेवलपमेंट कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "కెరీర్ అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ୟାରିୟର ବିକାଶ ଦକ୍ଷତା",
            "as": "কেৰিয়াৰ বিকাশৰ দক্ষতা সমুহ",
            "gu": "કારકિર્દી વિકાસના કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Razia has to negotiate a lot with her parents as she is the first girl in her family who wants to pursue higher education in science outside their village. There are different stages in negotiation, starting with preparation, discussion and agreement. Negotiation is not an argument. It would help if you were assertive but at the same willing to listen to the other person. Make a list of why your decision is good for you, the negative consequences, and how you will deal with those.",
            "hi": "रजिया को अपने माता-पिता के साथ बहुत निगोशिएट करना पड़ा क्योंकि वह उनके परिवार की पहली लड़की थी जो अपने गाँव से बाहर सायन्स में हायर स्टडीज करना चाहती थी। निगोशिएशन के कई स्तर होते हैं, जिसकी शुरुआत होती है तैयारी करने से, फिर चर्चा और राजी होना। निगोशिएन का मतलब विवाद करना नहीं है। अगर आप अपने विचारों को सही मानते हैं तो यह अच्छी बात लेकिन आप को सामने वाले इन्सान की बात भी सुननी चाहिए। आपका निर्णय आपके लिए सही क्यों हैं, इसके नकरात्मक परिणाम क्या है और इनका सामना कैसे किया जा सकता हैं इसकी सूची बनाएं।",
            "ka": "ರಝಿಯಾ ತನ್ನ ಕುಟುಂಬದಲ್ಲಿ ತಮ್ಮ ಹಳ್ಳಿಯ ಹೊರಗೆ ವಿಜ್ಞಾನದಲ್ಲಿ ಉನ್ನತ ಶಿಕ್ಷಣವನ್ನು ಪಡೆಯಲು ಬಯಸುತ್ತಿರುವ ಮೊದಲ ಹುಡುಗಿಯಾಗಿರುವುದರಿಂದ ತನ್ನ ಹೆತ್ತವರೊಂದಿಗೆ ಸಾಕಷ್ಟು ಮಾತುಕತೆ ನಡೆಸಬೇಕಾಗಿದೆ.ಸಮಾಲೋಚನೆಯಲ್ಲಿ ವಿವಿಧ ಹಂತಗಳಿವೆ, ಸಿದ್ಧತೆ, ಚರ್ಚೆ ಮತ್ತು ಒಪ್ಪಂದದಿಂದ ಪ್ರಾರಂಭಿಸಿ.ಮಾತುಕತೆ ಒಂದು ವಾದವಲ್ಲ. ನೀವು ಸಮರ್ಥರಾಗಿದ್ದರೆ ಆದರೆ ಇತರ ವ್ಯಕ್ತಿಯ ಮಾತನ್ನು ಕೇಳಲು ಸಿದ್ಧರಿದ್ದರೆ ಅದು ಸಹಾಯ ಮಾಡುತ್ತದೆ.ನಿಮ್ಮ ನಿರ್ಧಾರವು ನಿಮಗೆ ಏಕೆ ಒಳ್ಳೆಯದು, ಋಣಾತ್ಮಕ ಪರಿಣಾಮಗಳು ಮತ್ತು ನೀವು ಅವುಗಳನ್ನು ಹೇಗೆ ಎದುರಿಸುತ್ತೀರಿ ಎಂಬುದರ ಪಟ್ಟಿಯನ್ನು ಮಾಡಿ.",
            "te": "రజియా తన కుటుంబంలో తమ గ్రామం వెలుపల సైన్స్‌లో ఉన్నత విద్యను అభ్యసించాలనుకునే మొదటి అమ్మాయి కాబట్టి రజియా తన తల్లిదండ్రులతో చాలా చర్చలు జరపవలసి ఉంటుంది.సంధిలో వివిధ దశలు ఉన్నాయి, తయారీ, చర్చ మరియు ఒప్పందంతో మొదలవుతాయి.చర్చలు ఒక వాదన కాదు. మీరు దృఢంగా ఉన్నట్లయితే, అదే సమయంలో అవతలి వ్యక్తి చెప్పేది వినడానికి సిద్ధంగా ఉంటే ఇది సహాయపడుతుంది. మీ నిర్ణయం మీకు ఎందుకు మంచిది, ప్రతికూల పరిణామాలు మరియు మీరు వాటిని ఎలా ఎదుర్కోవాలి అనే జాబితాను రూపొందించండి.",
            "or": "ରଜିଆଙ୍କୁ ତାଙ୍କ ପିତାମାତାଙ୍କ ସହ ବହୁତ ବୁଝାମଣା କରିବାକୁ ପଡିବ କାରଣ ସେ ତାଙ୍କ ପରିବାରର ପ୍ରଥମ ଝିଅ ଯିଏ ନିଜ ଗାଁ ବାହାରେ ବିଜ୍ଞାନରେ ଉଚ୍ଚ ଶିକ୍ଷା ଗ୍ରହଣ କରିବାକୁ ଚାହୁଁଛନ୍ତି । ପ୍ରସ୍ତୁତି, ଆଲୋଚନା ଏବଂ ରାଜିନାମାଠାରୁ ଆରମ୍ଭ କରି ବୁଝାମଣାରେ ବିଭିନ୍ନ ପର୍ଯ୍ୟାୟ ଅଛି । ବୁଝାମଣା ଏକ ଯୁକ୍ତି ନୁହେଁ । ଯଦି ତୁମେ ଦୃଢ କିନ୍ତୁ ତଥାପି ଅନ୍ୟ ବ୍ୟକ୍ତିଙ୍କ କଥା ଶୁଣିବାକୁ ଇଚ୍ଛୁକ ତେବେ ଏହା ତୁମକୁ ସାହାଯ୍ୟ କରିବ । ତୁମର ନିଷ୍ପତ୍ତି କାହିଁକି ତୁମ ପାଇଁ ଭଲ, ନକାରାତ୍ମକ ପରିଣାମ, ଏବଂ ତୁମେ ସେଗୁଡ଼ିକ ସହିତ କିପରି ମୁକାବିଲା କରିବ ତାହାର ଏକ ତାଲିକା ପ୍ରସ୍ତୁତ କର ।",
            "as": "ৰাজিয়াই তাইৰ মাক দেউতাকৰ সৈতে বহুত বুজাবুজি কৰিব লাগিব কিয়নো তাই তাইৰ পৰিয়ালৰ প্ৰথম ছোৱালী যি তেওঁলোকৰ গাঁৱৰ বাহিৰত বিজ্ঞানত উচ্চ শিক্ষা গ্ৰহণ কৰিব বিচাৰে। প্ৰস্তুতি, আলোচনা আৰু সন্মতিৰ পৰা আৰম্ভ কৰি বুজাবুজিৰ বিভিন্ন পৰ্যায় আছে। বুজাবুজি কোনো তৰ্ক নহয়। যদি তুমি দৃঢ় হয় কিন্তু একেসময়তে আন জনৰ কথা শুনিবলৈ ইচ্ছুক হয় তেন্তে ই তোমাক সহায় কৰিব। তোমাৰ সিদ্ধান্ত তোমাৰ বাবে কিয় ভাল, হ'ব পৰা বেয়া পৰিণাম আৰু তুমি সেইবোৰৰ সৈতে কেনেদৰে মোকাবিলা কৰিবা তাৰ এখন তালিকা প্ৰস্তুত কৰা।",
            "gu": "રઝિયાને તેના માતપિતા સાથે ઘણી વાટાઘાટો કરવી પડી કેમ કે તે તેમના કુટુંબની પહેલી છોકરી છે જે ઉચ્ચ વિજ્ઞાનઅભ્યાસ માટે તેને ગામની બહાર જવું પડશે.  વાટાઘાટોના અલગ અલગ સ્તર છે, જેમ કે તૈયારી, ચર્ચા અને સમજૂતી. વાટાઘાટા એ દલીલ જેવુ નથી. જો તમે મજબૂત છો પરંતુ તે જ સમયે તમારી સામેની વ્યક્તિને સાંભળવા માટે તૈયાર છો, તો તે મદદ કરશે. તમારો નિર્ણય તમારા માટે શા માટે સારો છે, તેના નકારાત્મક પરિણામો અને તમે તેનો સામનો કેવી રીતે કરશો તેની યાદી બનાવો."
          }
        }
      ],
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "A3_1_1",
            "hi": "A3_1_1",
            "ka": "A3_1_1",
            "te": "A3_1_1",
            "or": "A3_1_1",
            "as": "A3_1_1",
            "gu": "A3_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia is in her final year of college. She has been living in the hostel for the last 3 years, and has learnt a lot from meeting different people.",
            "hi": "रजिया अपने कॉलेज के आखरी साल में है। वह पिछले 3 सालों से हॉस्टल में रह रही है और अलग-अलग लोगों से मिलकर बहुत कुछ सीख चुकी हैं।",
            "ka": "ರಝಿಯಾ ಕಾಲೇಜಿನ ಅಂತಿಮ ವರ್ಷದಲ್ಲಿ ಓದುತ್ತಿದ್ದಾಳೆ. ಕಳೆದ 3 ವರ್ಷಗಳಿಂದ ಹಾಸ್ಟೆಲ್ ನಲ್ಲಿ ವಾಸ ಮಾಡುತ್ತಿದ್ದು, ಬೇರೆ ಬೇರೆ ವ್ಯಕ್ತಿಗಳನ್ನು ಭೇಟಿಯಾಗಿ ಸಾಕಷ್ಟು ಕಲಿತಿದ್ದಾಳೆ",
            "te": "రజియా కళాశాలలో చివరి సంవత్సరం చదువుతోంది. ఆమె గత మూడు సంవత్సరాలుగా వసతి గృహం లో నివసిస్తుంది మరియు వివిధ వ్యక్తులను కలవడం ద్వారా చాలా నేర్చుకున్నది నేర్చుకుంది.",
            "or": "ରଜିଆ କଲେଜର ଅନ୍ତିମ ବର୍ଷରେ ଅଛନ୍ତି । ସେ ଗତ 3 ବର୍ଷ ଧରି ହଷ୍ଟେଲରେ ରହୁଛନ୍ତି ଏବଂ ବିଭିନ୍ନ ଲୋକଙ୍କୁ ଭେଟିବାରୁ ବହୁତ କିଛି ଶିଖିଛନ୍ତି ।",
            "as": "ৰাজিয়া তাইৰ কলেজৰ শেষ বৰ্ষত আছে। তাই যোৱা 3 বছৰ ধৰি হোষ্টেলত বাস কৰি আছে আৰু বিভিন্ন লোকক লগ পোৱাৰ পৰা বহুতো শিকিছে।",
            "gu": "રઝિયા તેના  કોલેજના છેલ્લા વર્ષમાં છે. અને તે છેલ્લા 3 વર્ષથી હોસ્ટેલમાં રહે છે, અને તે અલગ અલગ લોકો ને મળીને ગણું બધુ શીખી છે "
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "A3_1_2",
            "hi": "A3_1_2",
            "ka": "A3_1_2",
            "te": "A3_1_2",
            "or": "A3_1_2",
            "as": "A3_1_2",
            "gu": "A3_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She has many close friends from different parts of West Bengal and  from different states across India. She now knows a lot more about different cultures.",
            "hi": "उसके कई दोस्त हैं जो पश्चिम बंगाल के अलग-अलग हिस्सों से और भारत के अलग-अलग राज्यों से हैं। अब उसे अलग-अलग संस्कृतियों के बारे में बहुत कुछ पता है।",
            "ka": "ಅವಳು ಪಶ್ಚಿಮ ಬಂಗಾಳದ ವಿವಿಧ ಭಾಗಗಳಿಂದ ಮತ್ತು ಭಾರತದಾದ್ಯಂತ ವಿವಿಧ ರಾಜ್ಯಗಳಿಂದ ಅನೇಕ ಆಪ್ತ ಸ್ನೇಹಿತರನ್ನು ಹೊಂದಿದ್ದಾಳೆ. ಅವಳು ಈಗ ವಿಭಿನ್ನ ಸಂಸ್ಕೃತಿಗಳ ಬಗ್ಗೆ ಹೆಚ್ಚು ತಿಳಿದಿದ್ದಾಳೆ.",
            "te": "ఆమెకు పశ్చిమ బెంగాల్‌లోని వివిధ ప్రాంతాల నుండి మరియు భారతదేశంలోని వివిధ రాష్ట్రాల నుండి చాలా మంది స్నేహితులు ఉన్నారు.ఆమెకు ఇప్పుడు విభిన్న సంస్కృతుల గురించి చాలా ఎక్కువ తెలుసు.",
            "or": "ପଶ୍ଚିମବଙ୍ଗର ବିଭିନ୍ନ ସ୍ଥାନ ଏବଂ ଭାରତର ବିଭିନ୍ନ ରାଜ୍ୟରୁ ତାଙ୍କର ଅନେକ ଘନିଷ୍ଠ ବନ୍ଧୁ ଅଛନ୍ତି । ସେ ବର୍ତ୍ତମାନ ବିଭିନ୍ନ ସଂସ୍କୃତି ବିଷୟରେ ବହୁତ କିଛି ଜାଣନ୍ତି ।",
            "as": "পশ্চিম বংগৰ বিভিন্ন প্ৰান্তৰ পৰা আৰু সমগ্ৰ ভাৰতৰ বিভিন্ন ৰাজ্যৰ পৰা তাইৰ বহুতো ঘনিষ্ঠ বন্ধু আছে। তাই এতিয়া বিভিন্ন সংস্কৃতিৰ বিষয়ে বহুত বেছি জানে।",
            "gu": "પશ્ચિમ બંગાળના વિવિધ ભાગોમાંથી અને સમગ્ર ભારતના વિવિધ રાજ્યોના તેને ઘણા નજીકના મિત્રો છે. તે હવે અલગ અલગ સંસ્કૃતિઓ વિશે ઘણું બધું જાણે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "A3_1_3",
            "hi": "A3_1_3",
            "ka": "A3_1_3",
            "te": "A3_1_3",
            "or": "A3_1_3",
            "as": "A3_1_3",
            "gu": "A3_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia and her friend Fatima have been considering the job options  they can apply for after college.",
            "hi": "रजिया और उसकी दोस्त फातिमा कॉलेज खत्म होने के बाद करने के लिए जॉब ढूंढ रहे हैं।",
            "ka": "ರಝಿಯಾ ಮತ್ತು ಅವಳ ಸ್ನೇಹಿತೆ ಫಾತಿಮಾ ಅವರು ಕಾಲೇಜು ನಂತರ ಅರ್ಜಿ ಸಲ್ಲಿಸಬಹುದಾದ ಉದ್ಯೋಗ ಆಯ್ಕೆಗಳನ್ನು ಪರಿಗಣಿಸುತ್ತಿದ್ದಾರೆ.",
            "te": "రజియా మరియు ఆమె స్నేహితురాలు ఫాతిమా కళాశాల తర్వాత వారు దరఖాస్తు చేసుకోగల ఉద్యోగ ఎంపికలను చూస్తున్నారు.",
            "or": "ରଜିଆ ଏବଂ ତାଙ୍କ ବନ୍ଧୁ ଫାତିମା କଲେଜ ପରେ ଆବେଦନ କରିପାରୁଥିବା ଚାକିରି ବିକଳ୍ପ ଉପରେ ବିଚାର କରୁଛନ୍ତି ।",
            "as": "ৰাজিয়া আৰু তাইৰ বন্ধু ফাতিমাই কলেজ শেষ হোৱাৰ পিছত এপ্লাই কৰিব পৰা চাকৰিবোৰৰ বিষয়ে বিবেচনা কৰি আছে।",
            "gu": " રઝિયા અને તેની મિત્ર ફાતિમા કોલેજ પછી કયા નોકરી માટે અરજી કરી શકે તે અંગે  વિચારણા કરી રહી છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "A3_1_4///",
            "hi": "A3_1_4///",
            "ka": "A3_1_4///",
            "te": "A3_1_4///",
            "or": "A3_1_4///",
            "as": "A3_1_4///",
            "gu": "A3_1_4///"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "There are various job options",
            "hi": "उनके पास कई जॉब ऑप्शन्स हैं",
            "ka": "ವಿವಿಧ ಉದ್ಯೋಗ ಆಯ್ಕೆಗಳಿವೆ",
            "te": "రకరకాల ఉద్యోగ ఎంపికలు ఉన్నాయి",
            "or": "ବିଭିନ୍ନ ଚାକିରି ବିକଳ୍ପ ଅଛି",
            "as": "বিভিন্ন ধৰণৰ চাকৰি আছে",
            "gu": "નોકરી માટે ત્યાં ગણા બધા વિકલ્પ છે "
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "They can become an ecologist and study the relation between organisms and the environment.",
            "hi": "वे इकोलॉजिस्ट बन सकती हैं और जीवों और पर्यावरण के बीच के संबंधों का अध्ययन कर सकती हैं।",
            "ka": "ಅವರು ಪರಿಸರಶಾಸ್ತ್ರಜ್ಞರಾಗಬಹುದು ಮತ್ತು ಜೀವಿಗಳು ಮತ್ತು ಪರಿಸರದ ನಡುವಿನ ಸಂಬಂಧವನ್ನು ಅಧ್ಯಯನ ಮಾಡಬಹುದು.",
            "te": "వారు పర్యావరణ శాస్త్రవేత్తగా మారవచ్చు మరియు జీవులు మరియు పర్యావరణం మధ్య సంబంధాన్ని అధ్యయనం చేయవచ్చు.",
            "or": "ସେମାନେ ଜଣେ ପରିବେଶବିତ ବା ଇକୋଲୋଜିଷ୍ଟ ହୋଇ ପାରିବେ ଏବଂ ଜୀବ ଓ ପରିବେଶ ମଧ୍ୟରେ ସମ୍ପର୍କକୁ ଅଧ୍ୟୟନ କରିପାରିବେ ।",
            "as": "তেওঁলোক এজন পৰিস্থিতিবিদ হ'ব পাৰে আৰু জীৱ আৰু পৰিৱেশৰ মাজৰ সম্পৰ্ক অধ্যয়ন কৰিব পাৰে।",
            "gu": "તેઓ ઇકોલોજીસ્ટ બની શકે છે જે સજીવો અને પર્યાવરણ વચ્ચેના સંબંધનો અભ્યાસ કરી છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "They can also become consultants to advise organisations on how they can minimise harmful impact on the environment. \nSome roles of teaching are also open for them to apply.",
            "hi": "वे सलाहकार बनकर संस्थाओं को सलाह दे सकती हैं कि उनके कारण होनेवाले पर्यावरण के दुष्प्रभावों को कैसे कम किया जाए। \n वे कुछ जगहों पर शिक्षक के जॉब के लिए भी अप्लाई कर सकती हैं।",
            "ka": "\"ಪರಿಸರದ ಮೇಲೆ ಹಾನಿಕಾರಕ ಪರಿಣಾಮವನ್ನು ಹೇಗೆ ಕಡಿಮೆ ಮಾಡಬಹುದು ಎಂಬುದರ ಕುರಿತು ಸಂಸ್ಥೆಗಳಿಗೆ ಸಲಹೆ ನೀಡಲು ಅವರು ಸಲಹೆಗಾರರಾಗಬಹುದು.\n ಬೋಧನೆಗೆ ಸಂಬಂಧಪಟ್ಟ ಕೆಲವು (ಪಾತ್ರಗಳಿಗೂ) ಕೆಲಸಗಳಿಗೂ ಅವರು ಅರ್ಜಿ ಸಲ್ಲಿಸಬಹುದಾಗಿದೆ  .\"",
            "te": "\"వారు పర్యావరణంపై హానికరమైన ప్రభావాన్ని ఎలా తగ్గించవచ్చో సంస్థలకు సలహా ఇవ్వడానికి కూడా సలహాదారులు కావచ్చు\n వారు దరఖాస్తు చేసుకోవడానికి బోధనకు సంబంధించిన కొన్ని పాత్రలు కూడా అందుబాటులో ఉన్నాయి.",
            "or": "ସେମାନେ ପରିବେଶ ଉପରେ କ୍ଷତିକାରକ ପ୍ରଭାବକୁ କିପରି କମ୍ କରିପାରିବେ ସେ ବିଷୟରେ ସଂଗଠନଗୁଡ଼ିକୁ ପରାମର୍ଶ ଦେବା ପାଇଁ ପରାମର୍ଶଦାତା ମଧ୍ୟ ହୋଇପାରିବେ । ସେମାନଙ୍କ ଆବେଦନ କରିବା ପାଇଁ ଶିକ୍ଷାଦାନର କିଛି ଭୂମିକା ମଧ୍ୟ ଉପଲବ୍ଧ ଅଛି ।",
            "as": "তেওঁলোকে পৰিৱেশৰ ওপৰত পৰা ক্ষতিকাৰক প্ৰভাৱ কেনেদৰে কম কৰিব পাৰি সেই বিষয়ে সংগঠনবোৰক পৰামৰ্শ দিবলৈ পৰামৰ্শদাতা হ'ব পাৰে। শিক্ষণৰ লগত জড়িত কিছুমান চাকৰিৰ বাবেও সিহতে এপ্লাই কৰিব পাৰে।",
            "gu": "તેઓ એવી સંસ્થાઓના સલાહકાર બની શકે છે, કે જે પર્યાવરણ પર હાનિકારક અસર કેવી રીતે ઘટાડી શકાય.\nતે માટે માર્ગદર્શન પૂરું પાડે છે. શિક્ષણક્ષેત્રના ઘણી ભૂમિકા તેમના માટે ઉપલબ્ધ છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "A3_1_5//",
            "hi": "A3_1_5//",
            "ka": "A3_1_5//",
            "te": "A3_1_5//",
            "or": "A3_1_5//",
            "as": "A3_1_5//",
            "gu": "A3_1_5//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Fatima",
            "hi": "फातिमा",
            "ka": "ಫಾತಿಮಾ",
            "te": "ఫాతిమా",
            "or": "ଫାତିମା",
            "as": "ফাতিমা",
            "gu": "ફાતિમા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Fatima",
            "hi": "फातिमा",
            "ka": "ಫಾತಿಮಾ",
            "te": "ఫాతిమా",
            "or": "ଫାତିମା",
            "as": "ফাতিমা",
            "gu": "ફાતિમા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "I sent my application for the travel ecologist role at the nature conservation foundation. I really want to travel and work.",
            "hi": "मैंने नेचर कंजरवेशन फाउंडेशन में ट्रैवल इकोलॉजिस्ट के जॉब के लिए एप्लीकेशन भेजा है। मैं सच में घूमना और काम करना चाहती हूँ।",
            "ka": "ನಾನು ಪ್ರಕೃತಿ ಸಂರಕ್ಷಣಾ ಪ್ರತಿಷ್ಠಾನದಲ್ಲಿ ಪ್ರಯಾಣ ಪರಿಸರಶಾಸ್ತ್ರಜ್ಞರ ಪಾತ್ರಕ್ಕಾಗಿ ನನ್ನ ಅರ್ಜಿಯನ್ನು ಕಳುಹಿಸಿದೆ. ನನಗೆ ನಿಜವಾಗಲೂ ಪ್ರಯಾಣ ಮತ್ತು ಕೆಲಸ ಇಷ್ಟವಾಗುತ್ತದೆ .",
            "te": "ప్రకృతి పరిరక్షణ ఫౌండేషన్ వద్ద ప్రయాణ పర్యావరణ శాస్త్రవేత్త పాత్ర కు నేను నా దరఖాస్తును పంపాను.నేను నిజంగా ప్రయాణం చేస్తూ పని చేయాలనుకుంటున్నాను.",
            "or": "ପ୍ରକୃତି ସଂରକ୍ଷଣ ଫାଉଣ୍ଡେସନରେ ଟ୍ରାଭେଲ ଇକୋଲୋଜିଷ୍ଟ ଭୂମିକା ପାଇଁ ମୁଁ ମୋର ଆବେଦନ ପଠାଇଥିଲି । ମୁଁ ପ୍ରକୃତରେ ବୁଲିବାକୁ ଏବଂ କାମ କରିବାକୁ ଚାହୁଁଛି ।",
            "as": "মই প্ৰকৃতি সংৰক্ষণ ফাউণ্ডেশ্যনত ভ্ৰমণ পৰিস্থিতিবিদ পদৰ বাবে মোৰ আবেদন পঠিয়াইছিলোঁ। মই সঁচাকৈয়ে ভ্ৰমণ আৰু কাম কৰিব বিচাৰোঁ।",
            "gu": "મેં નેચર કન્ઝર્વેશન ફાઉન્ડેશનમાં (પર્યાવરર્ણની સાચવણી કરતી સંસ્થા) \"ટ્રાવેલ ઇકોલોજિસ્ટની\" ભૂમિકા માટે અરજી કરી છે. મને મુસાફરી કરવી અને કામ કરવું ખરેખર ગમે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "It’s good you have decided what you want Fatima. I’m still a little confused.",
            "hi": "अच्छी बात है कि तुम क्या बनना चाहती हो यह तुमने पहले ही तय कर लिया है। मैं तो अभी भी कन्फ्यूज हूँ।",
            "ka": "ಫಾತಿಮಾ ನಿನಗೆ ಏನು ಬೇಕು ಎಂದು ನೀನು ನಿರ್ಧರಿಸಿರುವುದು ಒಳ್ಳೆಯದು. ನಾನು ಇನ್ನೂ ಸ್ವಲ್ಪ ಗೊಂದಲದಲ್ಲಿದ್ದೇನೆ.",
            "te": "ఫాతిమా నీవు ఏమి కావాలో నిర్ణయించుకోవడం మంచిది. నేను ఇంకా కొంచెం అయోమయంలో ఉన్నాను.",
            "or": "ଏହା ଭଲ ଯେ ତୁମେ କ'ଣ ଚାହୁଁଛନ୍ତି ତାହା ସ୍ଥିର କରିଛ ଫାତିମା । ମୁଁ ତଥାପି ଟିକିଏ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛି ।",
            "as": "ফাতিমা, এইটো বৰ ভাল কথা যে তুমি কি কৰিবা সেই সিদ্ধান্ত লৈছা। মই এতিয়াও অলপ বিমোৰত পৰি আছোঁ।",
            "gu": "ફાતિમા, તે સારું છે કે તે નક્કી કરી લીધું છે કે તારે શું જોઈએ છે,  પણ હું હજુ મૂંઝવણમાં જ છું."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "What are your options?",
            "hi": "तुम्हारे पास कौन से ऑप्शन्स हैं?",
            "ka": "ನಿಮ್ಮ ಆಯ್ಕೆಗಳು ಯಾವುವು?",
            "te": "మీ ఎంపికలు ఏమిటి?",
            "or": "ତୁମର ବିକଳ୍ପଗୁଡ଼ିକ କ'ଣ?",
            "as": "তোমাৰ বিকল্পবোৰ কি?",
            "gu": "તારા વિકલ્પો કયા છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.text",
          "value": {
            "en": "I was considering either the environmental consultant role at the eco resort or a field ecologist at the NGO. Good thing is that both are close to my home.",
            "hi": "मैं सोच रही थी कि मुझे इको रिसोर्ट में एनवायरमेंटल सलाहकार या एनजीओ में फिल्ड इकोलॉजिस्ट के लिए अप्लाई करना चाहिए। अच्छी बात यह है कि ये दोनों ही मेरे घर से नजदीक हैं।",
            "ka": "ನಾನು ಪರಿಸರ ರೆಸಾರ್ಟ್‌ನಲ್ಲಿ ಪರಿಸರ ಸಲಹೆಗಾರರ ​​ಪಾತ್ರವನ್ನು ಅಥವಾ ಎನ್‌ಜಿಒದಲ್ಲಿ ಕ್ಷೇತ್ರ ಪರಿಸರಶಾಸ್ತ್ರಜ್ಞರ ಕೆಲಸವನ್ನು ಆಯ್ಕೆ ಮಾಡಬೇಕೆಂದುಕೊಂಡಿದ್ದೇನೆ.. ಒಳ್ಳೆಯ ವಿಷಯವೆಂದರೆ ಎರಡು ನನ್ನ ಮನೆಯ ಹತ್ತಿರವೇ ಇದೆ.",
            "te": "నేను ఎకో-రిసార్ట్‌లో పర్యావరణ సలహాదారు పాత్రను \n లేదా NGO వద్ద ఒక క్షేత్ర పర్యావరణ శాస్త్రవేత్త పరిశీలిస్తున్నాను. మంచి విషయమేమిటంటే,రెండూ మా ఇంటికి దగ్గరగా ఉన్నాయి.",
            "or": "ମୁଁ ଇକୋ ରିସର୍ଟରେ ପରିବେଶ ପରାମର୍ଶଦାତା ଭୂମିକା କିମ୍ବା NGOରେ ଏକ କ୍ଷେତ୍ର ପରିବେଶବିତ (ଫିଲ୍ଡ ଇକୋଲୋଜିଷ୍ଟ) ବିଷୟରେ ବିଚାର କରୁଥିଲି । ଭଲ କଥା ହେଉଛି ଉଭୟ ମୋ ଘରର ନିକଟତର ।",
            "as": "মই হয় ইক' ৰিজৰ্টত পৰিৱেশ পৰামৰ্শদাতাৰ পদ বা NGOৰ ক্ষেত্ৰ পৰিস্থিতিবিদ হোৱাৰ কথা বিবেচনা কৰি আছিলো। ভাল কথাটো হ'ল যে দুয়োটা মোৰ ঘৰৰ ওচৰত আছে।",
            "gu": "હું ઇકો-રિસોર્ટમાં સલાહકાર તરીકે અથવા સામાજિક સંસ્થામાં ફિલ્ડ ઇકોલોજિસ્ટ (પર્યાવરણ સલાહકારની) ભૂમિકા વિશે વિચારી રહી હતી. સારી બાબત એ છે કે તે બંને મારા ઘરની નજીક છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "A3_1_6////",
            "hi": "A3_1_6////",
            "ka": "A3_1_6////",
            "te": "A3_1_6////",
            "or": "A3_1_6/////",
            "as": "A3_1_6////",
            "gu": "A3_1_6////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "NGO?",
            "hi": "एनजीओ?",
            "ka": "ಎನ್‌ಜಿಒ?",
            "te": "NGO?",
            "or": "NGO?",
            "as": "NGO?",
            "gu": "NGO? (ગેર-સરકારી સંસ્થા)"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Yes, the one I have always wanted to work at. But the job doesn’t pay as much as the other one.",
            "hi": "हाँ, जहाँ मैं हमेशा से काम करना चाहती थी। लेकिन इस जॉब मैं मुझे इतनी अच्छी सैलरी नहीं मिलेगी जितनी दूसरी जॉब में मिल रही है।",
            "ka": "ಹೌದು,ನಾನು ಯಾವಾಗಲೂ ಈ ಕ್ಷೇತ್ರದಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಬಯಸುತ್ತೇನೆ. ಆದರೆ ಈ ಕೆಲಸ ಹೆಚ್ಚು ಸಂಬಳವನ್ನು ನೀಡುವುದಿಲ್ಲ ",
            "te": "అవును,నేను ఎప్పటినుంచో పని చేయాలని కోరుకునేది.కానీ ఆ ఉద్యోగం వేరే ఉద్యోగాలకంటే ఎక్కువ జీతం ఇవ్వదు.",
            "or": "ହଁ, ମୁଁ ସର୍ବଦା କାମ କରିବାକୁ ଚାହୁଁଛି । କିନ୍ତୁ ଚାକିରି ଅନ୍ୟଟି ପରି ଦରମା ଦିଏ ନାହିଁ ।",
            "as": "হয়, মই সদায় এই কামটো কৰিব বিচাৰিছিলো। কিন্তু এই চাকৰিটোত আনবোৰতকৈ দৰমহা কম।",
            "gu": "હા, જેની પર હું હંમેશા કામ કરવા માંગતી હતી. પરંતુ એ નોકરી બીજી નોકરી જેટલા પગાર નથી મળતા."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Razia, why don’t you apply for that environmental educator role, at the international school? You’re so good at teaching!",
            "hi": "रजिया, तुम एनवायरमेंटल एज्युकेटर के लिए अप्लाई क्यों नहीं करती? तुम भुत अच्छे से पढ़ाती हो!",
            "ka": "ರಝಿಯಾ, ನೀನು ಆ ಪರಿಸರ ಶಿಕ್ಷಕರ ಪಾತ್ರಕ್ಕಾಗಿ ಅಂತರರಾಷ್ಟ್ರೀಯ ಶಾಲೆಯಲ್ಲಿ ಏಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದಿಲ್ಲ? ನಿನ್ನ  ಬೋಧನೆಯ ಕೌಶಲ್ಯ ತುಂಬಾ ಚೆನ್ನಾಗಿದೆ  ",
            "te": "రజియా, అంతర్జాతీయ పాఠశాలలో ఆ పర్యావరణ విద్యావేత్త పాత్ర కోసం మీరు ఎందుకు దరఖాస్తు చేసుకోవచ్చు కదా?నువ్వు చాలా బాగా బోధిస్తావు!",
            "or": "ରଜିଆ, ତୁମେ ଆନ୍ତର୍ଜାତୀୟ ବିଦ୍ୟାଳୟରେ ସେହି ପରିବେଶ ଶିକ୍ଷାବିତ୍ ଭୂମିକା ପାଇଁ କାହିଁକି ଆବେଦନ କରୁନ? ତୁମେ ଶିକ୍ଷାଦାନରେ ବହୁତ ଭଲ!",
            "as": "ৰাজিয়া, তুমি আন্তৰ্জাতিক বিদ্যালয়ত সেই পৰিৱেশ শিক্ষাবিদৰ পদৰ বাবে কিয় এপ্লাই নকৰা? তুমি শিকোৱাত ইমান ভাল!",
            "gu": "રઝિયા, તું આંતરરાષ્ટ્રીય શાળામાં \"પર્યાવરણીય શિક્ષકની\" ભૂમિકા માટે અરજી કેમ નથી કરતી? તું ભણાવવામાં હોશિયાર છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "That role is in the city. I will again be far from home.",
            "hi": "लेकिन ये जॉब तो शहर में हैं। मुझे फिर से घर से दूर रहना पड़ेगा।",
            "ka": "ಆ ಕೆಲಸಗಳು ನಗರದಲ್ಲಿ ಮಾತ್ರ ಇರುತ್ತದೆ ಹಾಗಾಗಿ ನಾನು ಮನೆಯಿಂದ ದೂರವಿರಬೇಕಾಗುತ್ತದೆ  ",
            "te": "ఆ ఉద్యోగం నగరంలో ఉంది.నేను మళ్ళీ ఇంటికి దూరంగా ఉండాలి.",
            "or": "ସେହି ଭୂମିକା ସହରରେ ଅଛି । ମୁଁ ପୁନର୍ବାର ଘରୁ ବହୁତ ଦୂରରେ ରହିବି ।",
            "as": "সেই পদটো চহৰতহে আছে। মই আকৌ ঘৰৰ পৰা বহু দূৰত থাকিব লাগিব।",
            "gu": "તે નોકરી શહેરમાં છે. તેથી હું ફરી ઘરથી દૂર જતી રહીશ"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "But if you’re looking for a good salary, that’s the best offer we have got this year!",
            "hi": "लेकिन अगर तुम्हें अच्छी सैलरी चाहिए, तो फिर इस साल हमारे पास यहीं सबसे अच्छा ऑफर है!",
            "ka": "ನೀನು ಉತ್ತಮ ಸಂಬಳ ದೊರೆಯುವ ಕೆಲಸವನ್ನು ಹುಡುಕುತ್ತಿದ್ದರೆ ಈ ಕೆಲಸ ವರ್ಷದಲ್ಲಿ ದೊರೆತ ಉತ್ತಮ ಅವಕಾಶವಾಗಿರುತ್ತದೆ ",
            "te": "కానీ నువ్వు మంచి జీతం కోసం చూస్తున్నట్లయితే,ఈ సంవత్సరం మనకు వచ్చిన మంచి అవకాశం ఇదే!",
            "or": "କିନ୍ତୁ ଯଦି ତୁମେ ଏକ ଭଲ ଦରମା ଚାହୁଁଛ, ତେବେ ତାହା ଚଳିତ ବର୍ଷ ଆମେ ପାଇଥିବା ସର୍ବୋତ୍ତମ ଅଫର!",
            "as": "কিন্তু যদি তুমি ভাল দৰমহা বিচাৰি আছা, সেইটো এইবছৰ আমি পোৱা সৰ্বশ্ৰেষ্ঠ প্ৰস্তাৱ!",
            "gu": "પરંતુ જો તું સારા પગારની નોકરીની શોધમાં છે, તો આ વર્ષે આપણને મળેલી તક સૌથી  ઉત્તમ છે!"
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "A3_2(D)",
            "hi": "A3_2(D)",
            "ka": "A3_2(D)",
            "te": "A3_2(D)",
            "or": "A3_2(D)",
            "as": "A3_2(D)",
            "gu": "A3_2(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "When she goes to the placement office in college they give her a form. \nWhat should Razia select?",
            "hi": "जब वह कॉलेज के प्लेसमेंट ऑफिस में जाती है, तब वे उसे एक फॉर्म देते हैं। \n रजिया को क्या चुनना चाहिए?",
            "ka": "“ಅವಳು ಕಾಲೇಜಿನಲ್ಲಿ ಪ್ಲೇಸ್‌ಮೆಂಟ್ ಆಫೀಸ್‌ಗೆ ಹೋದಾಗ ಅವಳಿಗೆ ಒಂದು ಫಾರ್ಮ್ ಕೊಡುತ್ತಾರೆ.\n ಇದರಲ್ಲಿ ರಝಿಯಾ ಯಾವುದನ್ನು ಆರಿಸಬೇಕು?\"",
            "te": "\"ఆమె కళాశాల లో ప్లేస్‌మెంట్ కార్యాలయం కు వెళ్ళినప్పుడు వారు ఆమెకు ఒక ఫారం ఇస్తారు.\n .రజియా దేనిని ఎంచుకోవాలి?",
            "or": "ଯେତେବେଳେ ସେ କଲେଜରେ ପ୍ଲେସମେଣ୍ଟ ଅଫିସକୁ ଯାଆନ୍ତି ସେମାନେ ତାଙ୍କୁ ଏକ ଫର୍ମ ଦିଅନ୍ତି । ରଜିଆ କ'ଣ ଚୟନ କରିବା ଉଚିତ୍?",
            "as": "যেতিয়া তাই কলেজৰ প্লেচমেণ্ট অফিচলৈ যায় তেওঁলোকে তাইক এখন ফৰ্ম দিয়ে। \n ৰাজিয়াই কি বাছনি কৰা উচিত?",
            "gu": "જ્યારે તે કોલેજમાં નિયુક્તિની ઓફિસમાં જાય છે ત્યારે તેમને એક ફોર્મ મળે છે ભરવા માટે છે.\nશું પસંદ કરવું જોઈએ રઝિયાને?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Trainee environmental consultant at the eco-resort.",
            "hi": "इको-रिसोर्ट में ट्रेनी एनवायरमेंटल सलाहकार।",
            "ka": "ಇಕೋ-ರೆಸಾರ್ಟ್‌ನಲ್ಲಿ ತರಬೇತಿಯಲ್ಲಿರುವ  ಪರಿಸರ ಸಲಹೆಗಾರ",
            "te": "ఎకో-రిసార్ట్‌లో శిక్షణ పొందిన పర్యావరణ సలహాదారు.",
            "or": "ଇକୋ-ରିସର୍ଟରେ ପ୍ରଶିକ୍ଷାର୍ଥୀ ପରିବେଶ ପରାମର୍ଶଦାତା ।",
            "as": "ইক'-ৰিজৰ্টত প্ৰশিক্ষাৰ্থী পৰিৱেশ পৰামৰ্শদাতা।",
            "gu": "ઇકો-રિસોર્ટ ખાતે તાલીમાર્થી પર્યાવરણ સલાહકાર "
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Junior environmental educator at the international school.",
            "hi": "इंटरनैशनल स्कूल में ज्युनियर एनवायरमेंटल एज्युकेटर।",
            "ka": "ಅಂತರರಾಷ್ಟ್ರೀಯ ಶಾಲೆಯಲ್ಲಿ ಕಿರಿಯ ಪರಿಸರ ಶಿಕ್ಷಣತಜ್ಞ.",
            "te": "అంతర్జాతీయ పాఠశాలలో జూనియర్ పర్యావరణ విద్యావేత్త.",
            "or": "ଆନ୍ତର୍ଜାତୀୟ ବିଦ୍ୟାଳୟରେ କନିଷ୍ଠ ପରିବେଶ ଶିକ୍ଷାବିତ୍ ।",
            "as": "আন্তৰ্জাতিক বিদ্যালয়ত কনিষ্ঠ পৰিৱেশ শিক্ষাবিদ।",
            "gu": "જુનિયર પર્યાવરણીય શિક્ષક આંતરરાષ્ટ્રીય શાળામાં ."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Field Ecologist at the NGO.",
            "hi": "एनजीओ में फिल्ड इकोलॉजिस्ट।",
            "ka": "ಎನ್‌ಜಿಒ ನಲ್ಲಿ ಕ್ಷೇತ್ರ ಪರಿಸರಶಾಸ್ತ್ರಜ್ಞ.",
            "te": "NGO వద్ద. క్షేత్ర పర్యావరణ శాస్త్రవేత్త",
            "or": "NGOରେ ଫିଲ୍ଡ ଇକୋଲୋଜିଷ୍ଟ ।",
            "as": "NGOৰ ফিল্ড ইকোলজিষ্ট।",
            "gu": "NGOમાં ફિલ્ડ ઇકોલોજિસ્ટ."
          }
        }
      ]
    },
    "scene11": {
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "Negotiation level 2_Stay with Imran dada",
            "hi": "इमरान दादा के साथ रहना",
            "ka": "ಮಾತುಕತೆಯ ಮಟ್ಟ 2_ಇಮ್ರಾನ್ ದಾದಾ ಜೊತೆ ",
            "te": "చర్చల స్థాయి 2_ఇమ్రాన్ అన్నయ్యతో ఉండాలి",
            "or": "ବୁଝାମଣା ସ୍ତର 2_ ଇମ୍ରାନ ଭାଇଙ୍କ ସହ ରହିବେ",
            "as": "বুজাবুজিৰ স্তৰ 2_ইমৰান দাদাৰ সৈতে থকা",
            "gu": "વાટાઘાટોનું સ્તર 2_ ઇમરાન દાદા સાથે રહો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained negotiation experience!",
            "hi": "आपने अभी-अभी निगोशिएट करने का अनुभव प्राप्त किया है!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಮಾತುಕತೆಯ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే నెగోషియేషన్ (చర్చ) అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ବୁଝାମଣା ଅଭିଜ୍ଞତା ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ বুজাবুজিৰে কেনেদৰে কাম কৰিব লাগে তাৰ অভিজ্ঞতা লাভ কৰিছা!",
            "gu": "વાટાઘાટોનો અનુભવ તમે હમણાં જ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Negotiation",
            "hi": "निगोशिएट करना",
            "ka": "ಮಾತುಕತೆ",
            "te": "నెగోషియేషన్ (చర్చ)",
            "or": "ବୁଝାମଣା",
            "as": "বুজাবুজি",
            "gu": "વાટાઘાટો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर डेवलपमेंट कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "కెరీర్ అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ୟାରିୟର ବିକାଶ ଦକ୍ଷତା",
            "as": "কেৰিয়াৰ বিকাশৰ দক্ষতা সমুহ",
            "gu": "કારકિર્દી વિકાસના કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Razia has to negotiate a lot with her parents as she is the first girl in her family who wants to pursue higher education in science outside their village. There are different stages in negotiation, starting with preparation, discussion and agreement. Negotiation is not an argument. It would help if you were assertive but at the same willing to listen to the other person. Make a list of why your decision is good for you, the negative consequences, and how you will deal with those.",
            "hi": "रजिया को अपने माता-पिता के साथ बहुत निगोशिएट करना पड़ा क्योंकि वह उनके परिवार की पहली लड़की थी जो अपने गाँव से बाहर सायन्स में हायर स्टडीज करना चाहती थी। निगोशिएशन के कई स्तर होते हैं, जिसकी शुरुआत होती है तैयारी करने से, फिर चर्चा और राजी होना। निगोशिएन का मतलब विवाद करना नहीं है। अगर आप अपने विचारों को सही मानते हैं तो यह अच्छी बात लेकिन आप को सामने वाले इन्सान की बात भी सुननी चाहिए। आपका निर्णय आपके लिए सही क्यों हैं, इसके नकरात्मक परिणाम क्या है और इनका सामना कैसे किया जा सकता हैं इसकी सूची बनाएं।",
            "ka": "ರಝಿಯಾ ತನ್ನ ಕುಟುಂಬದಲ್ಲಿ ತಮ್ಮ ಹಳ್ಳಿಯ ಹೊರಗೆ ವಿಜ್ಞಾನದಲ್ಲಿ ಉನ್ನತ ಶಿಕ್ಷಣವನ್ನು ಪಡೆಯಲು ಬಯಸುತ್ತಿರುವ ಮೊದಲ ಹುಡುಗಿಯಾಗಿರುವುದರಿಂದ ತನ್ನ ಹೆತ್ತವರೊಂದಿಗೆ ಸಾಕಷ್ಟು ಮಾತುಕತೆ ನಡೆಸಬೇಕಾಗಿದೆ.ಸಮಾಲೋಚನೆಯಲ್ಲಿ ವಿವಿಧ ಹಂತಗಳಿವೆ, ಸಿದ್ಧತೆ, ಚರ್ಚೆ ಮತ್ತು ಒಪ್ಪಂದ.  ಮಾತುಕತೆ ಎಂದರೆ ವಾದಮಾಡುವುದಲ್ಲ . \nನಿಮ್ಮನ್ನು ಸಮರ್ಥಿಸಿಕೊಳ್ಳುವುದಾದರೆ ಇತರರ ಮಾತನ್ನು ಕೇಳಿಸಿಕೊಳ್ಳುವವರಾಗಿರಬೇಕು ಮತ್ತು ನಿಮ್ಮ ನಿರ್ಧಾರ  ಏಕೆ ಉತ್ತಮವಾಗಿದೆ ಹಾಗು ಅದರಿಂದಾಗುವ ಕೆಟ್ಟ (ನಕಾರಾತ್ಮಕ ಅಂಶಗಳೇನು) ಪರಿಣಾಮಗಳು ಮತ್ತು ಅದನ್ನು ನೀವು ಹೇಗೆ ಎದುರಿಸುತ್ತೀರಿ ಎಂಬುದನ್ನು ಪಟ್ಟಿ ಮಾಡಿಕೊಳ್ಳಬೇಕಾಗುತ್ತದೆ .",
            "te": "రజియా తన కుటుంబంలో తమ గ్రామం వెలుపల సైన్స్‌లో ఉన్నత విద్యను అభ్యసించాలనుకునే మొదటి అమ్మాయి కాబట్టి రజియా తన తల్లిదండ్రులతో చాలా చర్చలు జరపవలసి ఉంటుంది.సంధిలో వివిధ దశలు ఉన్నాయి, తయారీ, చర్చ మరియు ఒప్పందంతో మొదలవుతాయి.చర్చలు ఒక వాదన కాదు. మీరు దృఢంగా ఉన్నట్లయితే, అదే సమయంలో అవతలి వ్యక్తి చెప్పేది వినడానికి సిద్ధంగా ఉంటే ఇది సహాయపడుతుంది. మీ నిర్ణయం మీకు ఎందుకు మంచిది, ప్రతికూల పరిణామాలు మరియు మీరు వాటిని ఎలా ఎదుర్కోవాలి అనే జాబితాను రూపొందించండి.",
            "or": "ରଜିଆଙ୍କୁ ତାଙ୍କ ପିତାମାତାଙ୍କ ସହ ବହୁତ ବୁଝାମଣା କରିବାକୁ ପଡିବ କାରଣ ସେ ତାଙ୍କ ପରିବାରର ପ୍ରଥମ ଝିଅ ଯିଏ ନିଜ ଗାଁ ବାହାରେ ବିଜ୍ଞାନରେ ଉଚ୍ଚ ଶିକ୍ଷା ଗ୍ରହଣ କରିବାକୁ ଚାହୁଁଛନ୍ତି । ପ୍ରସ୍ତୁତି, ଆଲୋଚନା ଏବଂ ରାଜିନାମାଠାରୁ ଆରମ୍ଭ କରି ବୁଝାମଣାରେ ବିଭିନ୍ନ ପର୍ଯ୍ୟାୟ ଅଛି । ବୁଝାମଣା ଏକ ଯୁକ୍ତି ନୁହେଁ । ଯଦି ତୁମେ ଦୃଢ କିନ୍ତୁ ତଥାପି ଅନ୍ୟ ବ୍ୟକ୍ତିଙ୍କ କଥା ଶୁଣିବାକୁ ଇଚ୍ଛୁକ ତେବେ ଏହା ତୁମକୁ ସାହାଯ୍ୟ କରିବ । ତୁମର ନିଷ୍ପତ୍ତି କାହିଁକି ତୁମ ପାଇଁ ଭଲ, ନକାରାତ୍ମକ ପରିଣାମ, ଏବଂ ତୁମେ ସେଗୁଡ଼ିକ ସହିତ କିପରି ମୁକାବିଲା କରିବ ତାହାର ଏକ ତାଲିକା ପ୍ରସ୍ତୁତ କର ।",
            "as": "ৰাজিয়াই তাইৰ মাক দেউতাকৰ সৈতে বহুত বুজাবুজি কৰিব লাগিব কিয়নো তাই তাইৰ পৰিয়ালৰ প্ৰথম ছোৱালী যি তেওঁলোকৰ গাঁৱৰ বাহিৰত বিজ্ঞানত উচ্চ শিক্ষা গ্ৰহণ কৰিব বিচাৰে। প্ৰস্তুতি, আলোচনা আৰু সন্মতিৰ পৰা আৰম্ভ কৰি বুজাবুজিৰ বিভিন্ন পৰ্যায় আছে। বুজাবুজি কোনো তৰ্ক নহয়। যদি তুমি দৃঢ় হয় কিন্তু একেসময়তে আন জনৰ কথা শুনিবলৈ ইচ্ছুক হয় তেন্তে ই তোমাক সহায় কৰিব। তোমাৰ সিদ্ধান্ত তোমাৰ বাবে কিয় ভাল, হব পৰা বেয়া পৰিণাম আৰু তুমি সেইবোৰৰ সৈতে কেনেদৰে মোকাবিলা কৰিবা তাৰ এখন তালিকা প্ৰস্তুত কৰা।",
            "gu": "રઝિયાને તેના માતપિતા સાથે ઘણી વાટાઘાટો કરવી પડી કેમ કે તે તેમના કુટુંબની પહેલી છોકરી છે જે ઉચ્ચ વિજ્ઞાનઅભ્યાસ માટે તેને ગામની બહાર જવું પડશે.  વાટાઘાટોના અલગ અલગ સ્તર છે, જેમ કે તૈયારી, ચર્ચા અને સમજૂતી. વાટાઘાટા એ દલીલ જેવુ નથી. જો તમે મજબૂત છો પરંતુ તે જ સમયે તમારી સામેની વ્યક્તિને સાંભળવા માટે તૈયાર છો, તો તે મદદ કરશે. તમારો નિર્ણય તમારા માટે શા માટે સારો છે, તેના નકારાત્મક પરિણામો અને તમે તેનો સામનો કેવી રીતે કરશો તેની યાદી બનાવો."
          }
        }
      ],
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "A4_1_1",
            "hi": "A4_1_1",
            "ka": "A4_1_1",
            "te": "A4_1_1",
            "or": "A4_1_1",
            "as": "A4_1_1",
            "gu": "A4_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia is in her final year of college. She has been living with Imran dada for the last 3 years. She has to travel 2 hours to reach college every day.",
            "hi": "रजिया अपने कॉलेज के आखरी साल में हैं। वह पिछले 3 सालों के इमरान दादा के साथ रह रही है। उसे हर दिन कॉलेज जाने के लिए 2 घंटे का सफर करना पड़ता है।",
            "ka": "ರಝಿಯಾ ಕಾಲೇಜಿನ ಅಂತಿಮ ವರ್ಷದಲ್ಲಿ ಓದುತ್ತಿದ್ದಾಳೆ. ಆಕೆ ಕಳೆದ ೩ ವರ್ಷಗಳಿಂದ ಇಮ್ರಾನ್ ದಾದಾ ಜೊತೆ ವಾಸಿಸುತ್ತಿದ್ದು ಕಾಲೇಜು ತಲುಪಲು ಪ್ರತಿದಿನ ೩ ಗಂಟೆಯ ಪ್ರಯಾಣಿಸಬೇಕಾಗಿರುತ್ತದೆ ",
            "te": "రజియా కాలేజీ లో చివరి సంవత్సరం చదువుతోంది.ఆమె గత మూడేళ్లుగా ఇమ్రాన్ అన్నయ్యతో కలిసి ఉంటుంది.రోజూ కాలేజీకి చేరుకోవడానికి 2 గంటల ప్రయాణం చేయాల్సి ఉంటుంది.",
            "or": "ରଜିଆ କଲେଜର ଅନ୍ତିମ ବର୍ଷରେ ଅଛନ୍ତି । ସେ ଗତ ୩ ବର୍ଷ ଧରି ଇମ୍ରାନ ଭାଇଙ୍କ ସହ ରହୁଛନ୍ତି । ପ୍ରତିଦିନ କଲେଜରେ ପହଞ୍ଚିବା ପାଇଁ ତାଙ୍କୁ 2 ଘଣ୍ଟା ଯାତ୍ରା କରିବାକୁ ପଡେ ।",
            "as": "ৰাজিয়া তাইৰ কলেজৰ শেষ বৰ্ষত আছে। তাই যোৱা ৩ বছৰ ধৰি ইমৰান দাদৰ সৈতে আছে। তাই প্ৰতিদিনে কলেজ পাবলৈ ২ ঘণ্টা ভ্ৰমণ কৰিব লাগে।",
            "gu": "અત્યારે રઝિયા કોલેજના અંતિમ વર્ષમાં છે. ઈમરાન દાદા સાથે તે છેલ્લા 3 વર્ષથી  રહે છે. તેને દરરોજ કોલેજ જવા માટે 2 કલાક અંતર કાપવું પડે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "A4_1_2",
            "hi": "A4_1_2",
            "ka": "A4_1_2",
            "te": "A4_1_2",
            "or": "A4_1_2",
            "as": "A4_1_2",
            "gu": "A4_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Because of this she is unable to spend enough time with her classmates. She doesn’t have many close friends.",
            "hi": "इसके कारण वह अपने क्लासमेट्स के साथ ज्यादा समय नहीं बिता पाती। उसके पास ज्यादा करीबी दोस्त नहीं हैं",
            "ka": "ಇದರಿಂದಾಗಿ ಆಕೆ ತನ್ನ ಸಹಪಾಠಿಗಳೊಂದಿಗೆ ಸಾಕಷ್ಟು ಸಮಯ ಕಳೆಯಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ.ಹಾಗೆಯೇ ಅವಳು ಹೆಚ್ಚು ಆಪ್ತ ಸ್ನೇಹಿತರನ್ನು ಹೊಂದಿಲ್ಲ.",
            "te": "ఈ కారణంగా ఆమె తన తోటి విద్యార్థులతో తగినంత సమయం గడపలేకపోతోంది.ఆమెకు ఎక్కువ క్లోజ్ ఫ్రెండ్స్ లేరు.",
            "or": "ଏହି କାରଣରୁ ସେ ତାଙ୍କ ସହପାଠୀମାନଙ୍କ ସହିତ ଯଥେଷ୍ଟ ସମୟ ବିତାଇବାରେ ଅସମର୍ଥ । ତାଙ୍କର ଅନେକ ଘନିଷ୍ଠ ବନ୍ଧୁ ନାହାଁନ୍ତି ।",
            "as": "ইয়াৰ কাৰণে তাই তাইৰ সহপাঠীসকলৰ সৈতে বেছি সময় কটাব নোৱাৰে। তাইৰ বহু বেছি নলেগলে লগা বন্ধু নাই।",
            "gu": "આના કારણે તે તેના વર્ગના મિત્રો સાથે પૂરતો સમય આપી નથી શકતી. તેના નજીકના મિત્રો પણ ઓછા છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "A4_1_3",
            "hi": "A4_1_3",
            "ka": "A4_1_3",
            "te": "A4_1_3",
            "or": "A4_1_3",
            "as": "A4_1_3",
            "gu": "A4_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She spends a lot of her time cooking meals and cleaning the house, and manages to study while she is travelling.",
            "hi": "वह अपना ज्यादातर समय खाना बनाने और घर की सफाई करने में ही बिताती है और सफर के दौरान पढ़ाई करती है।",
            "ka": "ಅವಳು ತನ್ನ ಹೆಚ್ಚಿನ ಸಮಯವನ್ನು ಅಡುಗೆ ಮಾಡುವುದರಲ್ಲಿ ಮತ್ತು ಮನೆಯನ್ನು ಸ್ವಚ್ಛಗೊಳಿಸುವುದರಲ್ಲಿ ಕಳೆಯುತ್ತಾಳೆ. ಓದುವುದೇನಿದ್ದರೂ ಪ್ರಯಾಣಡ ಸಮಯದಲ್ಲಿ ಮಾತ್ರ.  \n  ",
            "te": "ఆమె చాలా సమయం భోజనం వండడం, ఇల్లు శుభ్రం చేయడం మరియు ప్రయాణం చేస్తూ చదువుకోవడం లోనే గడుపుతుంది.",
            "or": "ସେ ଭୋଜନ ରାନ୍ଧିବା ଏବଂ ଘର ସଫା କରିବାରେ ବହୁତ ସମୟ ବିତାନ୍ତି, ଏବଂ ସେ ଯାତ୍ରା କରିବା ସମୟରେ ସଫଳତାର ସହିତ ଅଧ୍ୟୟନ କରନ୍ତି ।",
            "as": "তাই তাইৰ যথেষ্ট সময় ৰন্ধা-বঢ়া আৰু ঘৰ চাফা কৰাত খৰচ কৰে, আৰু ভ্ৰমণ কৰি থাকোতে পঢ়া শুনা কৰে ।",
            "gu": "તેનો ઘણો સમય જમવાનું બનાવમાં અને ઘરની સાફ સફાઈમાં જાય છે, અને તે મુસાફરી કરતી વખતે અભ્યાસમાં માટે સમય ફાળવે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "A4_1_4",
            "hi": "A4_1_4",
            "ka": "A4_1_4",
            "te": "A4_1_4",
            "or": "A4_1_4",
            "as": "A4_1_4",
            "gu": "A4_1_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia is considering her options towards the jobs she might be able to take up after college.",
            "hi": "रजिया कॉलेज खत्म होने के बाद करने के लिए जॉब ढूंढ रहे हैं।",
            "ka": "ರಝಿಯಾ ಕಾಲೇಜಿನ ನಂತರ ತೆಗೆದುಕೊಳ್ಳಲು ಸಾಧ್ಯವಾಗಬಹುದಾದ ಉದ್ಯೋಗಗಳ ಕಡೆಗೆ ತನ್ನ ಆಯ್ಕೆಗಳನ್ನು ಪರಿಗಣಿಸುತ್ತಿದ್ದಾಳೆ.",
            "te": "రజియా కళాశాల తర్వాత తాను చేపట్టగలిగే ఉద్యోగాల పట్ల తన ఎంపికలను పరిశీలిస్తోంది.",
            "or": "ରଜିଆ ସେହି ଚାକିରିଗୁଡିକ ପାଇଁ ତାଙ୍କର ବିକଳ୍ପ ଉପରେ ବିଚାର କରୁଛନ୍ତି ଯାହାକୁ ସେ କଲେଜ ପରେ ଗ୍ରହଣ କରିବାକୁ ସକ୍ଷମ ହୋଇପାରନ୍ତି ।",
            "as": "ৰাজিয়াই কলেজৰ পিছত ল'ব পৰা চাকৰিবোৰৰ বিষয়ে চিন্তা চৰ্চা কৰি আছে।",
            "gu": "રઝિયા કોલેજ પછી જે નોકરી લઈ શકે તે માટે તેના વિકલ્પો પર વિચાર કરે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "A4_1_5",
            "hi": "A4_1_5",
            "ka": "A4_1_5",
            "te": "A4_1_5",
            "or": "A4_1_5",
            "as": "A4_1_5",
            "gu": "A4_1_5"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "There are various job options.",
            "hi": "उसके पास कई जॉब ऑप्शन्स है",
            "ka": "ವಿವಿಧ ಉದ್ಯೋಗ ಆಯ್ಕೆಗಳಿವೆ.",
            "te": "వివిధ ఉద్యోగ ఎంపికలు ఉన్నాయి.",
            "or": "ବିଭିନ୍ନ ଚାକିରି ବିକଳ୍ପ ଅଛି ।",
            "as": "চাকৰিৰ বিভিন্ন বিকল্প আছে।",
            "gu": "નોકરી માટે ત્યાં ગણા બધા વિકલ્પ છે "
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "She can become an ecologist and study the relation between organisms and the environment.",
            "hi": "वह इकोलॉजिस्ट बन सकती हैं और जीवों और पर्यावरण के बीच के संबंधों का अध्ययन कर सकती है।",
            "ka": "ಅವಳು ಪರಿಸರ ವಿಜ್ಞಾನಿಯಾಗಬಹುದು ಮತ್ತು ಜೀವಿಗಳು ಮತ್ತು ಪರಿಸರದ ನಡುವಿನ ಸಂಬಂಧವನ್ನು ಅಧ್ಯಯನ ಮಾಡಬಹುದು.",
            "te": "ఆమె పర్యావరణ శాస్త్రవేత్త కావచ్చు మరియు జీవులు మరియు పర్యావరణం మధ్య సంబంధాన్ని అధ్యయనం చేయవచ్చు.",
            "or": "ସେ ଜଣେ ପରିବେଶବିତ ବା ଇକୋଲୋଜିଷ୍ଟ ହୋଇ ପାରିବେ ଏବଂ ଜୀବ ଏବଂ ପରିବେଶ ମଧ୍ୟରେ ସମ୍ପର୍କକୁ ଅଧ୍ୟୟନ କରିପାରିବେ ।",
            "as": "তাই এগৰাকী পৰিস্থিতিবিদ হ'ব পাৰে আৰু জীৱ আৰু পৰিৱেশৰ মাজৰ সম্পৰ্ক অধ্যয়ন কৰিব পাৰে।",
            "gu": "તેઓ ઇકોલોજીસ્ટ બની શકે છે જે સજીવો અને પર્યાવરણ વચ્ચેના સંબંધનો અભ્યાસ કરી છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "She can also become a consultant to advise organisations on how they can minimise harmful impact on the environment.",
            "hi": "वह सलाहकार बनकर संस्थाओं को सलाह दे सकती है कि उनके कारण होनेवाले पर्यावरण के दुष्प्रभावों को कैसे कम किया जाए।",
            "ka": "ಪರಿಸರದ ಮೇಲೆ ಹಾನಿಕಾರಕ ಪರಿಣಾಮವನ್ನು ಹೇಗೆ ಕಡಿಮೆ ಮಾಡಬಹುದು ಎಂಬುದರ ಕುರಿತು ಸಂಸ್ಥೆಗಳಿಗೆ ಸಲಹೆ ನೀಡಲು ಅವಳು ಸಲಹೆಗಾರರಾಗಬಹುದು.",
            "te": "పర్యావరణంపై హానికరమైన ప్రభావాన్ని ఎలా తగ్గించవచ్చో సంస్థలకు సలహా ఇవ్వడానికి ఆమె సలహాదారుగా కూడా మారవచ్చు.",
            "or": "ପରିବେଶ ଉପରେ କ୍ଷତିକାରକ ପ୍ରଭାବକୁ କିପରି କମ୍ କରିପାରିବେ ସେ ବିଷୟରେ ସଂଗଠନଗୁଡ଼ିକୁ ପରାମର୍ଶ ଦେବା ପାଇଁ ସେ ପରାମର୍ଶଦାତା ମଧ୍ୟ ହୋଇପାରିବେ ।",
            "as": "পৰিৱেশৰ ওপৰত ক্ষতিকাৰক প্ৰভাৱ বোৰ কেনেদৰে কম কৰিব পাৰি সেই বিষয়ে সংগঠনবোৰক পৰামৰ্শ দিবলৈ তাই এগৰাকী পৰামৰ্শদাতাও হ'ব পাৰে।",
            "gu": "તેઓ સંસ્થાઓના સલાહકાર બની શકે છે કે જે પર્યાવરર્ણને ઓછામાં ઓછી હાનિ કેવી રીતે પહોચાડી શકાય તે વિષે સલાહ આપી શકે"
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "A4_1_6//",
            "hi": "A4_1_6//",
            "ka": "A4_1_6//",
            "te": "A4_1_6//",
            "or": "A4_1_6//",
            "as": "A4_1_6//",
            "gu": "A4_1_6//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "One day when Imran dada comes home from work...",
            "hi": "एक दिन इमरान दादा काम खत्म करके घर लौटता है...",
            "ka": "ಒಂದು ದಿನ ಇಮ್ರಾನ್ ದಾದಾ ಕೆಲಸ ಮುಗಿಸಿ ಮನೆಗೆ ಬಂದಾಗ…",
            "te": "ఒకరోజు ఇమ్రాన్ అన్నయ్య పని నుండి ఇంటికి రాగానే...",
            "or": "ଦିନେ ଯେତେବେଳେ ଇମ୍ରାନ ଭାଇ କାମରୁ ଘରକୁ ଆସିଲେ ...",
            "as": "এদিন যেতিয়া ইমৰান দাদা কামৰ পৰা ঘৰলৈ আহে...",
            "gu": "એક દિવસ જ્યારે ઇમરાન દાદા કામ પરથી ઘરે પાછા આવે છે..."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Imran",
            "hi": "इमरान",
            "ka": "ಇಮ್ರಾನ್",
            "te": "ఇమ్రాన్",
            "or": "ଇମ୍ରାନ",
            "as": "ইমৰান",
            "gu": "ઈમરાન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Imran",
            "hi": "इमरान",
            "ka": "ಇಮ್ರಾನ್",
            "te": "ఇమ్రాన్",
            "or": "ଇମ୍ରାନ",
            "as": "ইমৰান",
            "gu": "ઈમરાન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Razia, the job interviews are going to start in your college, right? Have you applied?",
            "hi": "रजिया, तुम्हारे कॉलेज में अब जॉब इन्टरव्यू शुरू हो जाएंगे, है ना? तुमने अप्लाई किया है?",
            "ka": "ರಝಿಯಾ, ನಿನ್ನ ಕಾಲೇಜಿನಲ್ಲಿ ಉದ್ಯೋಗ ಸಂದರ್ಶನಗಳು ಪ್ರಾರಂಭವಾಗಲಿವೆ, ಸರಿ? ನೀವು ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ್ದೀಯಾ?",
            "te": "రజియా, మీ కాలేజీలో ఉద్యోగాల కోసం ఇంటర్వ్యూలు\n  ప్రారంభం కాబోతున్నాయి కదా , నువ్వు దరఖాస్తు చేసుకున్నావా ?",
            "or": "ରଜିଆ, ଚାକିରି ସାକ୍ଷାତକାର ତୁମ କଲେଜରେ ଆରମ୍ଭ ହେବାକୁ ଯାଉଛି, ଠିକ୍? ତୁମେ ଆବେଦନ କରିଛ କି?",
            "as": "ৰাজিয়া, চাকৰিৰ ইন্টাৰভিউ বোৰ তোমাৰ কলেজত আৰম্ভ হ'ব, নহয় জানো? তুমি এপ্লাই কৰিছা নে?",
            "gu": "રઝિયા, તારી કોલેજ \\માં નોકરી માટે ઇન્ટરવ્યૂ ચાલુ થઈ ગયા છે  બરાબરને? તે એમાં અરજી કરી કે?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Not yet dada, but I know there are two opportunities I'm interested in pursuing.",
            "hi": "अभी नहीं दादा लेकिन मुझे पता है कि डेरे पास दो ऑप्शन्स हैं जिनमें करियर करना मुझे अच्छा लगेगा।",
            "ka": "ಇನ್ನೂಆಗಿಲ್ಲ ದಾದಾ , ಆದರೆ ನಾನು ಅನುಸರಿಸಲು ಆಸಕ್ತಿ ಹೊಂದಿರುವ ಎರಡು ಅವಕಾಶಗಳಿವೆ ಎಂದು ನನಗೆ ತಿಳಿದಿದೆ.",
            "te": "ఇంకా లేదు అన్నయ్య కానీ నాకు ఇష్టమైనవి చేయడానికి రెండు అవకాశాలు ఉన్నాయని నాకు తెలుసు.",
            "or": "ଏପର୍ଯ୍ୟନ୍ତ ନୁହେଁ ଭାଇ, କିନ୍ତୁ ମୁଁ ଜାଣେ ଦୁଇଟି ସୁଯୋଗ ଅଛି ଯାହାକୁ ମୁଁ ଅନୁସରଣ କରିବାକୁ ଆଗ୍ରହୀ ।",
            "as": "এতিয়ালৈকে নাই কৰা দাদা, কিন্তু মই জানো যে দুটা সুযোগ আছে যি দুটা মোৰ ভাল লাগিছে।",
            "gu": "ના દાદા, હજી સુધી નથી કરી, પણ મને ખબર છે મારા માટે બે તકો છે જેમાં મારો રસ છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "Alright, what are your options?",
            "hi": "ठीक है, क्या ऑप्शन्स है तुम्हारे पास?",
            "ka": "ಸರಿ, ನಿನ್ನ ಆಯ್ಕೆಗಳು ಯಾವುವು?",
            "te": "సరే, నీ ఎంపికలు ఏమిటి?",
            "or": "ଠିକ ଅଛି, ତୁମର ବିକଳ୍ପଗୁଡ଼ିକ କ'ଣ?",
            "as": "ঠিক আছে, তোমাৰ বিকল্পবোৰ কি?",
            "gu": "ઠીક છે, તો તારા વિકલ્પો કયા છે?\t"
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "A4_1_7////",
            "hi": "A4_1_7////",
            "ka": "A4_1_7////",
            "te": "A4_1_7////",
            "or": "A4_1_7/////",
            "as": "A4_1_7////",
            "gu": "A4_1_7////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "I was considering the environmental consultant role at the eco resort or as a field ecologist at the NGO near home. Both are close to home.",
            "hi": "मैं सोच रही थी कि मुझे इको रिसोर्ट में एनवायरमेंटल सलाहकार या एनजीओ में फिल्ड इकोलॉजिस्ट के लिए अप्लाई करना चाहिए। अच्छी बात यह है कि ये दोनों ही मेरे घर से नजदीक हैं।",
            "ka": "ನಾನು ಪರಿಸರ ರೆಸಾರ್ಟ್‌ನಲ್ಲಿ ಪರಿಸರ ಸಲಹೆಗಾರರ ​​ಪಾತ್ರವನ್ನು ಅಥವಾ ಮನೆಯ ಸಮೀಪವಿರುವ ಎನ್‌ಜಿಒದಲ್ಲಿ ಕ್ಷೇತ್ರ ಪರಿಸರಶಾಸ್ತ್ರಜ್ಞನಾಗಿ ಪರಿಗಣಿಸುತ್ತಿದ್ದೆ. ಎರಡು ಮನೆಯ ಹತ್ತಿರವೇ ಇದ್ದಾರೆ.",
            "te": "నేను పర్యావరణ రిసార్ట్ వద్ద పర్యావరణ సలహాదారు పాత్రను లేదా ఇంటి దగ్గర లో ఉన్న NGO లో ఒక క్షేత్ర పర్యావరణ శాస్త్రవేత్త గా పని చేయాలని పరిశీలిస్తున్నాను.రెండు ఇంటి దగ్గరలోనే ఉన్నాయి",
            "or": "ମୁଁ ଇକୋ ରିସର୍ଟରେ କିମ୍ବା ଘର ନିକଟରେ ଥିବା NGOରେ ଜଣେ ଫିଲ୍ଡ ଇକୋଲୋଜିଷ୍ଟ ଭାବରେ ପରିବେଶ ପରାମର୍ଶଦାତା ଭୂମିକା ଉପରେ ବିଚାର କରୁଥିଲି । ଉଭୟ ଘରର ନିକଟତର ।",
            "as": "মই ইক' ৰিজৰ্টত পৰিৱেশ পৰামৰ্শদাতাৰ পদ বা ঘৰৰ ওচৰৰ NGOত ক্ষেত্ৰ পৰিস্থিতিবিদ হিচাপে কাম কৰিম বুলি ভাবি আছো। দুয়োখন ঘৰৰ ওচৰতে আছে।",
            "gu": "હું વિચારતી હતી કે ઈકો રિસોટમાં પર્યાવરણ બાબતે સલાહકાર તરીકે જોડાવું અથવા ફીલ્ડ ઈકોલોજિસ્ટ તરીકે સામાજિક સંસ્થામાં જોડાવું. બંને ઘરથી નજીક છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Oh that’s so nice, haven’t you always wanted to work at the NGO?",
            "hi": "यह तो बहुत अच्छी बात है, तुम तो हमेशा से ही एनजीओ में काम करना चाहती थी, है ना?",
            "ka": "ಓಹ್, ಅದು ತುಂಬಾ ಒಳ್ಳೆಯದು, ನೀನು ಯಾವಾಗಲೂ ಎನ್‌ಜಿಒದಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಬಯಸಲಿಲ್ಲವೇ?",
            "te": "అవునా,అది చాలా మంచిది.నువ్వు ఎప్పుడూ NGOలో పనిచేయాలని అనుకుంటావు కదా?",
            "or": "ଓଃ ଏହା ବହୁତ ସୁନ୍ଦର, ତୁମେ ସର୍ବଦା NGOରେ କାମ କରିବାକୁ ଚାହୁଁନଥିଲ କି?",
            "as": "অহ্ এইটো বহুত ভাল কথা, তুমি সদায় NGOত কাম কৰিব বিচৰা নাছিলা জানো?",
            "gu": "ખૂબ સરસ, અને તું હંમેશા સામાજિક સંસ્થામાં કામ કરવા માંગતી હતી?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Yes, but the NGO job doesn’t pay as much as the eco-resort. That’s why I'm confused.",
            "hi": "हाँ, लेकिन यह एनजीओ जे जॉब में ज्यादा सैलरी नहीं मिलेगी। इसलिए मैं कन्फ्यूज हूँ।",
            "ka": "ಹೌದು, ಆದರೆ ಎನ್‌ಜಿಒ ಕೆಲಸವು ಪರಿಸರ-ರೆಸಾರ್ಟ್‌ನಷ್ಟು ಹಣವನ್ನು ನೀಡುವುದಿಲ್ಲ. ಅದಕ್ಕಾಗಿಯೇ ನಾನು ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದೇನೆ.",
            "te": "అవును, కానీ\n NGO ఉద్యోగం పర్యావరణ రిసార్ట్‌కు ఇచ్చే అంత జీతం ఇవ్వదు.\n అందుకే నేను అయోమయంలో ఉన్నాను.",
            "or": "ହଁ, କିନ୍ତୁ NGO ଚାକିରି ଇକୋ-ରିସର୍ଟ ପରି ଦରମା ଦିଏ ନାହିଁ । ସେଥିପାଇଁ ମୁଁ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛି ।",
            "as": "হয়, কিন্তু NGOৰ চাকৰিটোৱে ইক'-ৰিজৰ্টৰ দৰে দৰমহা নিদিয়ে। সেইকাৰণে মই বিমোৰত পৰিছো।",
            "gu": "હા, પણ ઇકો-રિસોર્ટ  જેવી નોકરીમાં જે પૈસા મને મળે છે  તેના કરતાં સામાજિક સંસ્થાની નોકરીમાં પૈસા ઓછા મળે છે, તેથી મને મૂંઝવણા છું."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Your concern is valid. But don’t feel this pressure. Do what you feel is best for you.",
            "hi": "तुम्हारी चिंता सही है. लेकिन इस बारे में ज्यादा टेन्शन मत लो. तुम्हें अपने लिए जो सही लगता है वही करो.",
            "ka": "ನಿನ್ನ ಕಾಳಜಿ ಮಾನ್ಯವಾಗಿದೆ. ಆದರೆ ಈ ಒತ್ತಡವನ್ನು ಅನುಭವಿಸಬೇಡ. ನಿನಗೆ ಯಾವುದು ಉತ್ತಮ ಎಂದು ನೀನು ಭಾವಿಸುತ್ತೀಯೋ ಅದನ್ನು ಮಾಡ.",
            "te": "మీ ఆందోళన సరైనదే. కానీ ఇంత ఒత్తిడిని తీసుకోవద్దు . నీకు ఏది ఉత్తమమైనదిగా అనిపిస్తుందో అదే చేయండి.",
            "or": "ତୁମ ଚିନ୍ତା ବୈଧ ଅଟେ। କିନ୍ତୁ ତୁମେ ଚିନ୍ତା କର ନାହିଁ । ତୁମ ପାଇଁ ଯାହା ସର୍ବୋତ୍ତମ ଅନୁଭବ କରୁଛ ତୁମେ ତାହା କର ।",
            "as": "তুমি ঠিকেই কৈছা। কিন্তু তুমি চাপত নাথাকিবা। তুমি তোমাৰ বাবে যি ভাল বুলি ভাবা সেয়াই কৰা ।",
            "gu": "તારી વાત બરાબર છે, તું કોઈ દબાણના અનુભવીશ. તને જે યોગ્ય લાગે તે પ્રમાણે કર"
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "A4_2(D)",
            "hi": "A4_2(D)",
            "ka": "A4_2(D)",
            "te": "A4_2(D)",
            "or": "A4_2(D)",
            "as": "A4_2(D)",
            "gu": "A4_2(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "When she goes to the placement office in college they give her a form. \nWhat should Razia select?",
            "hi": "जब वह कॉलेज के प्लेसमेंट ऑफिस में जाती है, तब वे उसे एक फॉर्म देते हैं। \n रजिया को क्या चुनना चाहिए?",
            "ka": "“ಅವಳು ಕಾಲೇಜಿನಲ್ಲಿ ಪ್ಲೇಸ್‌ಮೆಂಟ್ ಆಫೀಸ್‌ಗೆ ಹೋದಾಗ ಅವಳಿಗೆ ಒಂದು ಫಾರ್ಮ್ ಕೊಡುತ್ತಾರೆ.\n ರಝಿಯಾ ಯಾವುದನ್ನು ಆರಿಸಬೇಕು?\"",
            "te": "ఆమె కళాశాలలో ప్లేస్‌మెంట్ కార్యాలయం కు వెళ్లినప్పుడు వారు ఆమెకు ఒక ఫారమ్ ఇస్తారు.రజియా దేనిని ఎంచుకోవాలి?",
            "or": "ଯେତେବେଳେ ସେ କଲେଜରେ ପ୍ଲେସମେଣ୍ଟ ଅଫିସକୁ ଯାଆନ୍ତି ସେମାନେ ତାଙ୍କୁ ଏକ ଫର୍ମ ଦିଅନ୍ତି । ରଜିଆ କ'ଣ ଚୟନ କରିବା ଉଚିତ୍?",
            "as": "যেতিয়া তাই কলেজৰ প্লেচমেণ্ট অফিচলৈ যায় তেওঁলোকে তাইক এখন ফৰ্ম দিয়ে। \n ৰাজিয়াই কি বাছনি কৰা উচিত?",
            "gu": "જ્યારે તે કોલેજમાં નિયુક્તિ ઓફિસમાં જાય છે ત્યારે તેને ભરવા માટે એક ફોર્મ  આપવામાં આવે છે\nરઝિયાએ શું પસંદ કરવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Trainee environmental consultant at the eco-resort.",
            "hi": "इंटरनैशनल स्कूल में ज्युनियर एनवायरमेंटल एज्युकेटर।",
            "ka": "ಇಕೋ-ರೆಸಾರ್ಟ್‌ನಲ್ಲಿ ತರಬೇತಿ ಪಡೆದ ಪರಿಸರ ಸಲಹೆಗಾರ.",
            "te": "ఎకో-రిసార్ట్‌లో శిక్షణ పొందిన పర్యావరణ సలహాదారు.",
            "or": "ଇକୋ-ରିସର୍ଟରେ ପ୍ରଶିକ୍ଷାର୍ଥୀ ପରିବେଶ ପରାମର୍ଶଦାତା ।",
            "as": "ইক'-ৰিজৰ্টত প্ৰশিক্ষাৰ্থী পৰিৱেশ পৰামৰ্শদাতা।",
            "gu": "ઇકો-રિસોર્ટ ખાતે તાલીમાર્થી પર્યાવરણ સલાહકાર"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Field ecologist at the NGO.",
            "hi": "एनजीओ में फिल्ड इकोलॉजिस्ट।",
            "ka": "ಎನ್‌ಜಿಒ ನಲ್ಲಿ ಕ್ಷೇತ್ರ ಪರಿಸರ ವಿಜ್ಞಾನಿ.",
            "te": "NGO లో. క్షేత్ర పర్యావరణ శాస్త్రవేత్త",
            "or": "NGOରେ ଫିଲ୍ଡ ଇକୋଲୋଜିଷ୍ଟ ।",
            "as": "NGOৰ ক্ষেত্ৰ পৰিস্থিতিবিদ।",
            "gu": "NGOમાં ફિલ્ડ ઇકોલોજિસ્ટ."
          }
        }
      ]
    },
    "scene12": {
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "Negotiation",
            "hi": "निगोशिएट करना",
            "ka": "ಮಾತುಕತೆ",
            "te": "చర్చలు",
            "or": "ବୁଝାମଣା",
            "as": "বুজাবুজি",
            "gu": "વાટાઘાટો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained negotiation experience!",
            "hi": "आपने अभी-अभी निगोशिएट करने का अनुभव प्राप्त किया है!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಮಾತುಕತೆಯ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే నెగోషియేషన్ (చర్చ) అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ବୁଝାମଣା ଅଭିଜ୍ଞତା ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ বুজাবুজিৰে কেনেদৰে কাম কৰিব লাগে তাৰ অভিজ্ঞতা লাভ কৰিছা!",
            "gu": "વાટાઘાટોનો અનુભવ તમે હમણાં જ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Negotiation",
            "hi": "निगोशिएट करना",
            "ka": "ಮಾತುಕತೆ",
            "te": "నెగోషియేషన్ (చర్చ)",
            "or": "ବୁଝାମଣା",
            "as": "বুজাবুজি",
            "gu": "વાટાઘાટો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर डेवलपमेंट कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "కెరీర్ అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ୟାରିୟର ବିକାଶ ଦକ୍ଷତା",
            "as": "কেৰিয়াৰ বিকাশৰ দক্ষতা",
            "gu": "કારકિર્દી વિકાસના કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Razia has to negotiate with her parents about going to the city to study. Negotiation is a discussion between people to reach an agreement on a decision when you have different points of view. We need to negotiate both in our personal and professional life. It requires you to be clear about what you are looking for and have a conversation with another person on why it is crucial for you. It also requires you to be open to listening and change your mind if what the other person is saying makes sense for you.",
            "hi": "रजिया को शहर में पढ़ने के लिए जाने के बारे में अपने माता-पिता के साथ निगोशिएट करना होगा। निगोशिएट करना मतलब अलग विचारों के लोग आपस में चर्चा करके किसी एक निर्णय पर सहमत होते हैं। हमें अपनी व्यक्तिगत जीवन और पेशे में निगोशिएट करना पड़ता हैं। इसके लिए जरुरी है कि आप को पता हो कि आप क्या चाहते हैं और फिर दुसरे व्यक्ति से इस बात पर चर्चा करो की यह आपके लिए इतना महत्वपूर्ण क्यों हैं। साथ ही आपको सामने वाले की बात भी सुननी चाहिए और अगर उसकी बातें सही लगे तो अपने विचारों को भी बदलना चाहिए।",
            "ka": "ಓದಲು ನಗರಕ್ಕೆ ಹೋಗುವ ಬಗ್ಗೆ ರಝಿಯಾ ತನ್ನ ಪೋಷಕರೊಂದಿಗೆ ಮಾತುಕತೆ ನಡೆಸಬೇಕು.ನೀವು ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳನ್ನು ಹೊಂದಿರುವಾಗ ನಿರ್ಧಾರದ ಮೇಲೆ ಒಪ್ಪಂದವನ್ನು ತಲುಪಲು ಜನರ ನಡುವಿನ ಚರ್ಚೆಯೇ ಮಾತುಕತೆಯಾಗಿದೆ.ನಮ್ಮ ವೈಯಕ್ತಿಕ ಮತ್ತು ವೃತ್ತಿಪರ ಜೀವನದಲ್ಲಿ ನಾವು ಮಾತುಕತೆ ನಡೆಸಬೇಕಾಗಿದೆ.ನೀವು ಏನನ್ನು ಹುಡುಕುತ್ತಿದ್ದೀರಿ ಎಂಬುದರ ಕುರಿತು ನೀವು ಸ್ಪಷ್ಟವಾಗಿರಬೇಕು ಮತ್ತು ಅದು ನಿಮಗೆ ಏಕೆ ನಿರ್ಣಾಯಕವಾಗಿದೆ ಎಂಬುದರ ಕುರಿತು ಇನ್ನೊಬ್ಬ ವ್ಯಕ್ತಿಯೊಂದಿಗೆ ಸಂಭಾಷಣೆ ನಡೆಸುವುದು ಅಗತ್ಯವಾಗಿದೆ.ನೀವು ಕೇಳಲು ಮುಕ್ತರಾಗಿರಲು ಮತ್ತು ಇತರ ವ್ಯಕ್ತಿಯು ಹೇಳುತ್ತಿರುವುದು ನಿಮಗೆ ಅರ್ಥವಾಗಿದ್ದರೆ ನಿಮ್ಮ ಮನಸ್ಸನ್ನು ಬದಲಾಯಿಸಲು ಸಹ ಇದು ಅಗತ್ಯವಾಗಿರುತ್ತದೆ.",
            "te": "చదువుకోవడానికి సిటీకి వెళ్లే విషయంలో రజియా తన తల్లిదండ్రులతో చర్చలు జరపాలి. మీకు భిన్నమైన అభిప్రాయాలు ఉన్నప్పుడు ఒక నిర్ణయంపై ఒక ఒప్పందాన్ని చేరుకోవడానికి వ్యక్తుల మధ్య చర్చను నెగోషియేషన్ అంటారు. మన వ్యక్తిగత జీవితంలోనూ, వృత్తి జీవితంలోనూ చర్చలు జరపాలి. మీరు వెతుకుతున్న దాని గురించి మీరు స్పష్టంగా తెలుసుకోవాలి మరియు ఇది మీకు ఎందుకు కీలకం అనే దానిపై మరొక వ్యక్తితో సంభాషణను కలిగి ఉండాలి. మీరు వినడానికి సిద్ధంగా ఉండాలి మరియు అవతలి వ్యక్తి చెప్పేది మీకు అర్ధమైతే మీ మనసు మార్చుకోవడం కూడా దీనికి అవసరం.",
            "or": "ରଜିଆଙ୍କୁ ଅଧ୍ୟୟନ କରିବାକୁ ସହର ଯିବା ବିଷୟରେ ତାଙ୍କ ପିତାମାତାଙ୍କ ସହ ବୁଝାମଣା କରିବାକୁ ପଡିବ । ଯେତେବେଳେ ତୁମର ଦୃଷ୍ଟିକୋଣ ଭିନ୍ନ ଥାଏ ସେତେବେଳେ ନିଷ୍ପତ୍ତି ଉପରେ ଏକ ରାଜିନାମାରେ ପହଞ୍ଚିବା ପାଇଁ ଲୋକଙ୍କ ମଧ୍ୟରେ ବୁଝାମଣା ହେଉଛି ଏକ ଆଲୋଚନା । ଆମର ବ୍ୟକ୍ତିଗତ ଏବଂ ବୃତ୍ତିଗତ ଜୀବନରେ ଆମକୁ ବୁଝାମଣା କରିବା ଆବଶ୍ୟକ । ଏହା ତୁମକୁ ତୁମେ କ'ଣ ଖୋଜୁଛ ସେ ବିଷୟରେ ସ୍ପଷ୍ଟ ହେବା ପାଇଁ ଏବଂ ଏହା ତୁମ ପାଇଁ କାହିଁକି ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ସେ ବିଷୟରେ ଅନ୍ୟ ବ୍ୟକ୍ତିଙ୍କ ସହ କଥା ହେବା ପାଇଁ ଆବଶ୍ୟକ । ଯଦି ଅନ୍ୟ ବ୍ୟକ୍ତି ଯାହା କହୁଛନ୍ତି ତାହା ତୁମ ପାଇଁ ଅର୍ଥପୂର୍ଣ୍ଣ ହୁଏ ତେବେ ତୁମକୁ ଏହା ମଧ୍ୟ ଶୁଣିବା ଆବଶ୍ୟକ ଏବଂ ତୁମକୁ ନିଜ ମନ ପରିବର୍ତ୍ତନ କରିବାକୁ ପ୍ରସ୍ତୁତ ରହିବା ଆବଶ୍ୟକ ।",
            "as": "ৰাজিয়াই তাইৰ মাক দেউতাকৰ সৈতে চহৰলৈ পঢ়িবলৈ যোৱাৰ বিষয়ে বুজাবুজি কৰিব লাগিব। যেতিয়া কোনো সিদ্ধান্ত এটাৰ ওপৰত বিভিন্ন জন মানুহৰ বিভিন্ন দৃষ্টিভংগী থাকে তেতিয়া এক চুক্তিত উপনীত হ'বলৈ কৰা আলোচনাই হ'ল এক বুজাবুজি । আমি আমাৰ ব্যক্তিগত আৰু পেছাদাৰী দুয়োটা জীৱনতে বুজাবুজি কৰিব লাগিব। ইয়াৰ বাবে তুমি কি বিচাৰি আছা সেই বিষয়ে স্পষ্ট হ'ব লাগিব আৰু ই তোমাৰ বাবে কিয় গুৰুত্বপূৰ্ণ সেই বিষয়ে আন এজন ব্যক্তিৰ সৈতে আলোচনা কৰিব লাগিব। যদি আনজনে কোৱা কথাবোৰ তোমাৰ বাবে অৰ্থপূৰ্ণ হয় তেনেহ'লে তুমি তেওৰ কথা মানি লৈ তোমাৰ মতামত সলনি কৰাটো প্ৰয়োজন।",
            "gu": "રઝિયાએ  તેના માતા-પિતા સાથે શહેરમાં અભ્યાસ કરવા જવા માટે વાટાઘાટો કરવી પડશે. જ્યારે તમારો મત બીજા કરતાં અલગ હોય ત્યારે વાટાઘાટો દ્વારા કોઈ એક નિર્ણય પર પહોંચવામાં મદદરૂપ થવાય છે. આપણે આપણા વ્યક્તિગત અને વ્યાવસાયિક જીવનમાં બંને રીતે બાંધછોડ કરવાની જરૂર છે. તમે જે શોધી રહ્યા છો તે માટે તમે સ્પસ્ટ હોવા જોઈએ અને તે તમારા માટે શા માટે નિર્ણાયક છે તે અંગે બીજા લોકો સાથે વાતચીત કરવી પડશે. જો બીજા લોકો જે કહે છે તે તમારા માટે કામનું હોય તમારે તે  સાંભળવા માટે તૈયાર રહેવું અને પોતાને જરૂરી લાગે તો તે પ્રમાણે તમારે તમારું મન બદલવું જોઈએ."
          }
        }
      ],
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "A5_1_1//_Stay in the village",
            "hi": "गाँव में रहना",
            "ka": "A5_1_1//_ಹಳ್ಳಿಯಲ್ಲಿ ಇರಿ",
            "te": "A5_1_1//_గ్రామంలో ఉండడం",
            "or": "A5_1_1//_ ଗାଁରେ ରୁହନ୍ତୁ",
            "as": "A5_1_1//_গাঁৱত থকা",
            "gu": "A5_1_1//_ગામમાં રહેવું"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "I understand Abbu, I will stay in the village.",
            "hi": "मैं समझती हूँ, अब्बू, मैं गाँव में ही रहूंगी।",
            "ka": "ನನಗೆ ಅರ್ಥವಾಯಿತು ಅಬ್ಬು , ನಾನು ಹಳ್ಳಿಯಲ್ಲಿಯೇ ಇರುತ್ತೇನೆ.",
            "te": "నాకు అర్థమైంది అబ్బు,నేను ఊర్లోనే ఉంటాను.",
            "or": "ମୁଁ ବୁଝିପାରୁଛି ଅବୁ, ମୁଁ ଗାଁରେ ରହିବି ।",
            "as": "মই বুজি পাইছোঁ আব্বু, মই গাঁৱতে থাকিম।",
            "gu": "અબ્બુ હું સમજુ છું, હું ગામમાં રહીશ."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "A5_2_1",
            "hi": "A5_2_1",
            "ka": "A5_2_1",
            "te": "A5_2_1",
            "or": "A5_2_1",
            "as": "A5_2_1",
            "gu": "A5_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "A year later...\nRazia stays back in her village. Ammi is happy that she listened to her. She takes up a skill training course nearby where she enrolls for a tailoring course.",
            "hi": "एक साल बाद...\n रजिया अपने गाँव में ही रहती है। अम्मी बहुत खुश है कि रजिया ने उसकी बात मान ली। वो नजदीक ही एक कौशल ट्रेनिंग कोर्स लेती है जहाँ वह टेलरिंग कोर्स सीख रही है।",
            "ka": "ಒಂದು ವರ್ಷದ ನಂತರ...\n ರಝಿಯಾ ತನ್ನ ಹಳ್ಳಿಯಲ್ಲಿಯೇ ಇರುತ್ತಾಳೆ. ಅವಳು ತನ್ನ ಮಾತು ಕೇಳಿದ್ದಕ್ಕೆ ಅಮ್ಮಿಗೆ ಖುಷಿ. ಅವಳು ಹತ್ತಿರದ ಕೌಶಲ್ಯ ತರಬೇತಿ ಕೋರ್ಸ್ ಅನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾಳೆ, ಅಲ್ಲಿ ಅವಳು ಟೈಲರಿಂಗ್ ಕೋರ್ಸ್‌ಗೆ ದಾಖಲಾಗುತ್ತಾಳೆ.",
            "te": "\"సంవత్సరం తరువాత...\n రజియా తన గ్రామంలోనే ఉంటుంది. అమ్మి తన మాట విని సంతోషించింది. ఆమె సమీపంలోని నైపుణ్య శిక్షణా కోర్సును తీసుకుంటుంది, అక్కడ ఆమె టైలరింగ్ కోర్సులో చేరింది.\"",
            "or": "ଏକ ବର୍ଷ ପରେ... ରଜିଆ ତାଙ୍କ ଗାଁରେ ରୁହନ୍ତି । ଅମ୍ମି ଖୁସି ଯେ ସେ ତାଙ୍କ କଥା ଶୁଣିଛନ୍ତି । ସେ ନିକଟରେ ଏକ ଦକ୍ଷତା ତାଲିମ ପାଠ୍ୟକ୍ରମ ଗ୍ରହଣ କରନ୍ତି ଯେଉଁଠାରେ ସେ ଏକ ଟେଲରିଂ ପାଠ୍ୟକ୍ରମ ପାଇଁ ନାମ ଲେଖାନ୍ତି ।",
            "as": "এবছৰ পাছত ...\n ৰাজিয়া তাইৰ গাঁৱত থাকিল। আম্মী সুখী যে তাই তেঁওৰ কথা শুনিছিল। তাই ওচৰতে এটা দক্ষতা প্ৰশিক্ষণ পাঠ্যক্ৰম গ্ৰহণ কৰে য'ত তাই টেইলাৰিং পাঠ্যক্ৰমৰ বাবে নাম ভৰ্তি কৰে।",
            "gu": "એક વર્ષ પછી...\nરઝિયા તેના ગામમાં પાછી રહે છે. તેની દીકરી તેની વાત સાંભળે છે તેથી તેની અમ્મી ખૂબ ખુશ છે. તેણી સીવણનો કોર્સ કરવા પ્રવેશ મેળવે છે અને તે નજીકમાં એક કૌશલ્ય પ્રશિક્ષણ માટે જાય છે. "
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "A5_2_2",
            "hi": "A5_2_2",
            "ka": "A5_2_2",
            "te": "A5_2_2",
            "or": "A5_2_2",
            "as": "A5_2_2",
            "gu": "A5_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia starts getting tailoring orders at home and is happy that she is able to earn for her family. Imran dada is proud of her for taking up this responsibility along with him.",
            "hi": "रजिया को घर पर सिलाई के ऑर्डर्स मिल रहे हैं और वह खुश है कि वह अपने परिवार के लिए पैसे कमा सकती है। इमरान दादा को उस पर गर्व है कि रजिया ने उसके साथ घर की जिम्मेदारी उठानी शुरू कर दी है।",
            "ka": "ರಝಿಯಾ ಮನೆಯಲ್ಲಿ ಟೈಲರಿಂಗ್ ಆರ್ಡರ್‌ಗಳನ್ನು ಪಡೆಯಲು ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ ಮತ್ತು ತನ್ನ ಕುಟುಂಬಕ್ಕಾಗಿ ಸಂಪಾದಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತಿದೆ ಎಂದು ಸಂತೋಷಪಡುತ್ತಾಳೆ. ಇಮ್ರಾನ್ ದಾದಾ ತನ್ನೊಂದಿಗೆ ಈ ಜವಾಬ್ದಾರಿಯನ್ನು ತೆಗೆದುಕೊಂಡಿದ್ದಕ್ಕಾಗಿ ಅವಳ ಬಗ್ಗೆ ಹೆಮ್ಮೆಪಡುತ್ತಾನೆ.",
            "te": "రజియా ఇంట్లోనే టైలరింగ్ ఆర్డర్‌లు పొందడం ప్రారంభించింది మరియు ఆమె తన కుటుంబం కోసం సంపాదించగలుగుతున్నందుకు సంతోషంగా ఉంది.ఇమ్రాన్ అన్నయ్య తనతో పాటు ఈ బాధ్యతను తీసుకున్నందుకు గర్వపడుతున్నాడు.",
            "or": "ରଜିଆ ଘରେ ଟେଲରିଂ ଅର୍ଡର ପାଇବା ଆରମ୍ଭ କରନ୍ତି ଏବଂ ଖୁସି ଯେ ସେ ତାଙ୍କ ପରିବାର ପାଇଁ ରୋଜଗାର କରିବାକୁ ସକ୍ଷମ ଅଟନ୍ତି । ଇମ୍ରାନ ଭାଇ ତାଙ୍କ ସହିତ ଏହି ଦାୟିତ୍ୱ ଗ୍ରହଣ କରିଥିବାରୁ ତାଙ୍କ ପାଇଁ ଗର୍ବିତ ।",
            "as": "ৰাজিয়াই ঘৰত টেইলাৰিং অৰ্ডাৰ পোৱা আৰম্ভ কৰে আৰু সুখী যে তাই তাইৰ পৰিয়ালৰ বাবে উপাৰ্জন কৰিবলৈ সক্ষম হৈছে। ইমৰান দাদাই তেওঁৰ সৈতে তাই এই দায়িত্ব লোৱাৰ বাবে গৌৰৱান্বিত।",
            "gu": "રઝિયાને ઘરે સીવણના ઓર્ડર મળવા લાગે છે અને તે ખુશ છે કે તે તેના કુટુંબ માટે તે કઈક કમાઈ શકે છે. ઈમરાન દાદાને ગર્વ છે કે તે તેમની સાથે આ જવાબદારી નિભાવવાની છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "A5_2_3",
            "hi": "A5_2_3",
            "ka": "A5_2_3",
            "te": "A5_2_3",
            "or": "A5_2_3",
            "as": "A5_2_3",
            "gu": "A5_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "But she misses studying and often goes to the NGO to attend talks and lectures by researchers and ecologists. She wishes that she could be like them, some day.",
            "hi": "लेकिन वह पढ़ाई को मिस करती है और अक्सर एनजीओ जाकर रिसर्चर्स और इकोलॉजिस्ट द्वारा दिए जा रहें लेक्चर्स और भाषण सुनती है। वह चाहती है कि एक दिन वह भी उनकी तरह बने।",
            "ka": "ಆದರೆ ಅವಳು ಅಧ್ಯಯನವನ್ನು ಮಿಸ್ ಮಾಡುತ್ತಾಳೆ ಮತ್ತು ಸಂಶೋಧಕರು ಮತ್ತು ಪರಿಸರಶಾಸ್ತ್ರಜ್ಞರ ಮಾತುಕತೆಗಳು ಮತ್ತು ಉಪನ್ಯಾಸಗಳಿಗೆ ಹಾಜರಾಗಲು ಆಗಾಗ್ಗೆ ಎನ್‌ಜಿಒಗೆ ಹೋಗುತ್ತಾಳೆ. ಅವಳೂ ಒಂದಲ್ಲ ಒಂದು ದಿನ ಅವರಂತೆ ಆಗಬಹುದೆಂಬ ಆಸೆ ಪಡುತ್ತಾಳೆ.",
            "te": "కానీ ఆమె చదువుకోలేదు మరియు పరిశోధకులు మరియు పర్యావరణ శాస్త్రవేత్తల చర్చలు మరియు ఉపన్యాసాలకు హాజరు కావడానికి తరచుగా NGOకి వెళుతుంది. ఆమె ఏదో ఒక రోజు వారిలా ఉండాలని కోరుకుంటుంది.",
            "or": "କିନ୍ତୁ ସେ ଅଧ୍ୟୟନରୁ ବଞ୍ଚିତ ହୁଅନ୍ତି ଏବଂ ପ୍ରାୟତଃ ଗବେଷକ ଏବଂ ଇକୋଲୋଜିଷ୍ଟଙ୍କ ଦ୍ୱାରା ଆଲୋଚନା ଏବଂ ବକ୍ତୃତାରେ ଯୋଗଦେବାକୁ NGOକୁ ଯାଆନ୍ତି । ସେ ଇଚ୍ଛା କରନ୍ତି ଯେ ସେ ଦିନେ ସେମାନଙ୍କ ପରି ହୋଇପାରିବେ ।",
            "as": "কিন্তু তাই অধ্যয়নৰ পৰা বঞ্চিত হয় আৰু প্ৰায়ে গৱেষক আৰু পৰিস্থিতিবিদ সকলৰ আলোচনা আৰু বক্তৃতাত উপস্থিত থাকিবলৈ NGO লৈ যায়। তাই আশা কৰে যে তাই কোনোবা দিনা তেওঁলোকৰ দৰে হ'ব পাৰিব ।",
            "gu": "પણ તે અભ્યાસ કરવાનું છૂટી જાય છે અને સંશોધકો અને ઇકોલોજીસ્ટના ભાષણ અને પ્રવચનોમાં હાજરી આપવા માટે NGOમાં ઘણીવાર જાય છે. તે એવું મને છે કે તે એક દિવસ તેમના જેવી બનશે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "A5_3_1",
            "hi": "A5_3_1",
            "ka": "A5_3_1",
            "te": "A5_3_1",
            "or": "A5_3_1",
            "as": "A5_3_1",
            "gu": "A5_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Her parents begin finding a groom for her as they hope to get her married within a year.",
            "hi": "उसके माता-पिता ने उसके लली लड़का देखना शुरू कर दिया है क्योंकि वह चाहते हैं कि एक साल के अंदर उसकी शादी हो जाए।",
            "ka": "ಆಕೆಯ ಪೋಷಕರು ಆಕೆಗೆ ವರನನ್ನು ಹುಡುಕಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ ಒಂದು ವರ್ಷದೊಳಗೆ ಅವಳನ್ನು ಮದುವೆ ಮಾಡಲು ಅವರು ಆಶಿಸುತ್ತಾರೆ.",
            "te": "ఆమె తల్లిదండ్రులు ఆమెకు ఒక వరుడిని వెతకడం ప్రారంభించారు,వారు ఆమెకు ఒక సంవత్సరం లోపు వివాహం చేయాలని అనుకుంటున్నారు.",
            "or": "ତାଙ୍କ ପିତାମାତା ତାଙ୍କ ପାଇଁ ବର ଖୋଜିବା ଆରମ୍ଭ କରନ୍ତି କାରଣ ସେମାନେ ତାଙ୍କୁ ଏକ ବର୍ଷ ମଧ୍ୟରେ ବିବାହ କରିବାକୁ ଆଶା କରନ୍ତି ।",
            "as": "তাইৰ মাক দেউতাকে তাইৰ বাবে এজন দৰা বিচাৰিবলৈ আৰম্ভ কৰে কিয়নো তেওঁলোকে এবছৰৰ ভিতৰত তাইক বিয়া দিয়াৰ কথা ভাবিছে।",
            "gu": "એના માતપિતા તેના માટે વર શોધવાનું ચાલુ કરે છે કારણ કે તેઓ તેના એક વર્ષમાં લગ્ન કરાવવા માંગે છે ."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "A5_3_2",
            "hi": "A5_3_2",
            "ka": "A5_3_2",
            "te": "A5_3_2",
            "or": "A5_3_2",
            "as": "A5_3_2",
            "gu": "A5_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "But Razia wonders if there could have been more to her life than this…",
            "hi": "लेकिन रजिया सोचती रहती है कि क्या वह जिंदगी में इसके अलावा और भी कुछ कर सकती है …",
            "ka": "ಆದರೆ ರಝಿಯಾ ತನ್ನ ಜೀವನದಲ್ಲಿ ಇದಕ್ಕಿಂತ ಹೆಚ್ಚು ಇರಬಹುದೇ ಎಂದು ಆಶ್ಚರ್ಯ ಪಡುತ್ತಾಳೆ…",
            "te": "అయితే రజియా తన జీవితంలో ఇంతకంటే ఇంకేమైనా ఉంటుందా అని ఆశ్చర్యపోతూంది",
            "or": "କିନ୍ତୁ ରଜିଆ ଭାବନ୍ତି ଯେ କ'ଣ ଏହା ଠାରୁ ତାଙ୍କ ଜୀବନରେ ଅଧିକ କିଛି ହୋଇପାରେ କି...",
            "as": "কিন্তু ৰাজিয়াই আচৰিত হয় যে তাইৰ জীৱনত ইয়াতকৈ বেছি থাকিব পাৰিলেহেঁতেন নেকি...",
            "gu": "પરંતુ રઝિયા વિચારે છે કે શું તેના જીવનમાં આનાથી બીજું કંઈ ના હોઈ શકે?"
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Tailoring",
            "hi": "टेलरिंग",
            "ka": "**THREAD_END**_ಟೈಲರಿಂಗ್",
            "te": "**THREAD_END**_టైలరింగ్",
            "or": "**THREAD_END**_ଟେଲରିଂ",
            "as": "**থ্ৰেড_এণ্ড**_টেইলাৰিং",
            "gu": "**THREAD_END**_ટેલરિંગ"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Razia that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने रजिया के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँची है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकती है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n \n ನೀವು ರಝಿಯಾಗಾಗಿ ಮಾಡಿದ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ.ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు రజియా కోసం ఎంపికలు చేసారు,అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది.మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి.మీరు ఇతర సన్నివేశాలను కూడా చూడాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ! \n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି! \n ଆପଣ ରଜିଆ ପାଇଁ ନିଷ୍ପତ୍ତିଗୁଡିକ ପସନ୍ଦ କରିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତିକୁ ଆଗେଇ ନେଇଛି । ଆପଣ କରୁଥିବା ପସନ୍ଦଗୁଡିକ ଉପରେ ଆଧାର କରି ବିଭିନ୍ନ ପ୍ରକାରର ଶେଷ ଅଛି । ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହାଁନ୍ତି କି?",
            "as": "অভিনন্দন!\n তুমি এটা যাত্ৰা সমাপ্ত কৰিছা!\n তুমি ৰাজিয়াৰ বাবে বাছনি কৰিছা যাৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত আধাৰিত কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ জানিব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક યાત્રા પૂરી કરી છે!\n\nતમે રઝિયા માટે પસંદગીઓ કરી છે જેના કારણે અંતે આ શક્ય બન્યું. તમે જે પસંદગી કરો છો તેના આધારે અલગ અલગ અંત આવે છે. શું તમે અન્ય દ્રશ્યો શોધવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ର ଉପରେ ନଜର ପକାନ୍ତୁ",
            "as": "মানচিত্ৰখন চোৱা",
            "gu": "ચાલો નકશા તરફ નજર નાખીએ"
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "Tailoring",
            "hi": "टेलरिंग",
            "ka": "ಟೈಲರಿಂಗ್",
            "te": "టైలరింగ్",
            "or": "ଟେଲରିଂ",
            "as": "টেইলাৰিং",
            "gu": "સિલઈકામ"
          }
        }
      ]
    },
    "scene13": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D1_1_1_Apply at the eco resort",
            "hi": "इको रिसोर्ट में अप्लाई करना",
            "ka": "D1_1_1_ಪರಿಸರ ರೆಸಾರ್ಟ್ನಲ್ಲಿ ಅನ್ವಯಿಸಿ",
            "te": "D1_1_1_ఎకో రిసార్ట్ కి అప్లై చేయడం",
            "or": "D1_1_1_ ଇକୋ ରିସର୍ଟରେ ଆବେଦନ କଲେ",
            "as": "D1_1_1_ইক' ৰিজৰ্টত এপ্লাই কৰা",
            "gu": "D1_1_1_ઈકો રિસોર્ટ માટે અરજી કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia gets a call for an interview with the eco-resort. During the interview Razia is told that the job has a mixed set of responsibilities.",
            "hi": "रजिया को इको रिसोर्ट से इन्टरव्यू के लिए कॉल आता है। इन्टरव्यू के दौरान रजिया को बताया जाता है कि इस जॉब में अलग-अलग जिम्मेदारियां हैं।",
            "ka": "ಪರಿಸರ-ರೆಸಾರ್ಟ್‌ನೊಂದಿಗೆ ಸಂದರ್ಶನಕ್ಕಾಗಿ ರಝಿಯಾಗೆ ಕರೆ ಬರುತ್ತದೆ. ಸಂದರ್ಶನದ ಸಮಯದಲ್ಲಿ ರಝಿಯಾಗೆ ಕೆಲಸವು ಮಿಶ್ರ ಜವಾಬ್ದಾರಿಗಳನ್ನು ಹೊಂದಿದೆ ಎಂದು ಹೇಳಲಾಗುತ್ತದೆ.",
            "te": "ఎకో-రిసార్ట్‌కి ఇంటర్వ్యూ కోసం రజియాకి కాల్ వచ్చింది.\n ఇంటర్వ్యూ సమయంలో రజియా కు ఉద్యోగం లో మిశ్రమ బాధ్యతలు ఉంటాయి అని చెప్పారు .",
            "or": "ରଜିଆ ଇକୋ-ରିସର୍ଟ ସହିତ ସାକ୍ଷାତକାର ପାଇଁ ଏକ କଲ୍ ପାଇଲେ । ସାକ୍ଷାତକାର ସମୟରେ ରଜିଆଙ୍କୁ କୁହାଯାଏ ଯେ ଏହି ଚାକିରିରେ ମିଶ୍ରିତ ଦାୟିତ୍ୱ ଅଛି ।",
            "as": "ৰাজিয়াই ইক'-ৰিজৰ্টৰ সৈতে ইন্টাৰভিউৰ বাবে ফোন পাইছিল। ইন্টাৰভিউৰ সময়ত ৰাজিয়াক কোৱা হৈছিল যে চাকৰিটোত এক মিশ্ৰিত দায়িত্ব আছে।",
            "gu": "રઝિયાને ઈકો-રિસોર્ટમાં ઈન્ટરવ્યુ માટે ફોન આવે છે. ઇન્ટરવ્યુ દરમિયાન રઝિયાને કહેવામાં આવ્યું કે આ ભૂમિકા માટે એક સાથે ઘણી જવાબદારીઓ છે "
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D1_1_2",
            "hi": "D1_1_2",
            "ka": "D1_1_2",
            "te": "D1_1_2",
            "or": "D1_1_2",
            "as": "D1_1_2",
            "gu": "D1_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She will work as a junior environmental consultant and a part time naturalist where she has to go on tours with the guests and share information about the environment. She will work under a senior environmentalist.",
            "hi": "वह ज्युनियर एनवायरमेंटल सलाहकार के रूप में और पार्ट-टाइम नैचरलिस्ट के तौर पर काम करेगी, जहाँ उसे मेहमानों के साथ टूर पर जाना होगा और उन्हें पर्यावरण के बारे में जानकारी देनी होगी। वह सीनियर एनवायरमेंटलिस्ट के अधिकार के नीचे काम करेगी।",
            "ka": "ಅವಳು ಜೂನಿಯರ್ ಪರಿಸರ ಸಲಹೆಗಾರರಾಗಿ ಮತ್ತು ಅರೆಕಾಲಿಕ ನೈಸರ್ಗಿಕವಾದಿಯಾಗಿ ಕೆಲಸ ಮಾಡುತ್ತಾಳೆ, ಅಲ್ಲಿ ಅವಳು ಅತಿಥಿಗಳೊಂದಿಗೆ ಪ್ರವಾಸಗಳಿಗೆ ಹೋಗಬೇಕು ಮತ್ತು ಪರಿಸರದ ಬಗ್ಗೆ ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾಳೆ. ಅವಳು ಹಿರಿಯ ಪರಿಸರವಾದಿಗಳ ಅಡಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಾಳೆ.",
            "te": "ఆమె జూనియర్ పర్యావరణ సలహాదారుగా \n మరియు పార్ట్‌టైమ్ నేచురలిస్ట్ గా పని చేయాలి , అక్కడ ఆమె అతిథులతో పర్యటనలకు వెళ్లి పర్యావరణం గురించి సమాచారాన్ని పంచుకోవాలి. ఆమె సీనియర్ పర్యావరణవేత్త ఆధ్వర్యంలో పని చేయాలి .",
            "or": "ସେ ଜଣେ କନିଷ୍ଠ ପରିବେଶ ପରାମର୍ଶଦାତା ଏବଂ ଏକ ପାର୍ଟ ଟାଇମ୍ ପ୍ରକୃତିବାଦୀ (naturalist) ଭାବରେ କାର୍ଯ୍ୟ କରିବେ ଯେଉଁଠାରେ ତାଙ୍କୁ ଅତିଥିମାନଙ୍କ ସହିତ ଭ୍ରମଣରେ ଯିବାକୁ ପଡିବ ଏବଂ ପରିବେଶ ବିଷୟରେ ସୂଚନା ବାଣ୍ଟିବାକୁ ପଡିବ । ସେ ଜଣେ ବରିଷ୍ଠ ପରିବେଶବିତଙ୍କ ଅଧୀନରେ କାମ କରିବେ ।",
            "as": "তাই এগৰাকী কনিষ্ঠ পৰিৱেশ পৰামৰ্শদাতা আৰু আংশিক সময়ৰ প্ৰকৃতিবিদ হিচাপে কাম কৰিব য'ত তাই অতিথিসকলৰ সৈতে ভ্ৰমণলৈ যাব লাগিব আৰু পৰিৱেশৰ বিষয়ে তথ্য সমূহ জনাব লাগিব। তাই এজন জ্যেষ্ঠ পৰিৱেশবিদৰ অধীনত কাম কৰিব।",
            "gu": "તેએક જુનિયર પર્યાવરણ સલાહકાર અને અંશકાલિક પ્રકૃતિવાદી તરીકે કામ કરશે જ્યાં તે મહેમાનો સાથે પ્રવાસ પર જવું પડશે અને પર્યાવરણ વિશેની માહિતી આપવી પડશે. તે એક વરીસ્ઠ પર્યાવરણવિદ હેઠળ કામ કરશે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D1_1_3",
            "hi": "D1_1_3",
            "ka": "D1_1_3",
            "te": "D1_1_3",
            "or": "D1_1_3",
            "as": "D1_1_3",
            "gu": "D1_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia gets the offer letter. \nHer job starts with a probation of 6 months.",
            "hi": "रजिया को ऑफर लेटर मिलता है। \n उसका जॉब 6 महीने के प्रोबेशन के साथ शुरू होता है।",
            "ka": "\"ರಝಿಯಾಗೆ ಆಫರ್ ಲೆಟರ್ ಸಿಕ್ಕಿದೆ.\n ಆಕೆಯ ಕೆಲಸವು ೬ ತಿಂಗಳ ಪರೀಕ್ಷೆಯೊಂದಿಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ.",
            "te": "రజియాకు ఆఫర్ లెటర్ వచ్చింది.\n ఆమె ఉద్యోగం 6 నెలల పరిశీలనతో మొదలవుతుంది.",
            "or": "ରଜିଆ ଅଫର ଲେଟର ପାଇଲେ । ତାଙ୍କ ଚାକିରି 6 ମାସର ପ୍ରୋବେସନ୍ ସହିତ ଆରମ୍ଭ ହୁଏ ।",
            "as": "ৰাজিয়াই অফাৰ লেটাৰ খন পাইছে।\n তাইৰ চাকৰিটো 6 মাহৰ পৰীক্ষণ কালৰ সৈতে আৰম্ভ হয়।",
            "gu": "રઝિયાને દરખાસ્ત પત્ર મળ્યો.\nતેણીની નોકરી 6 મહિનાના કામચલાવ ધોરણ ઉપર શરૂ થાય છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D1_2_1",
            "hi": "D1_2_1",
            "ka": "D1_2_1",
            "te": "D1_2_1",
            "or": "D1_2_1",
            "as": "D1_2_1",
            "gu": "D1_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia is confused about the role and the probation. She sends a picture of the letter to Imran dada.",
            "hi": "रजिया अपने रोल और प्रोबेशन को लेकर कन्फ्यूज है। वह इस लेटर का फोटो इमरान दादा को भेजती है।",
            "ka": "ರಝಿಯಾ ಪಾತ್ರ ಮತ್ತು ಪರೀಕ್ಷೆಯ ಬಗ್ಗೆ ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದಾರೆ. ಅವಳು ಪತ್ರದ ಚಿತ್ರವನ್ನು ಇಮ್ರಾನ್ ದಾದಾಗೆ ಕಳುಹಿಸುತ್ತಾಳೆ.",
            "te": "రజియా పాత్ర మరియు పరిశీలన గురించి గందరగోళంగా ఉంది. ఆమె లెటర్ యొక్క చిత్రాన్ని ఇమ్రాన్ అన్నయ్యకి పంపింది.",
            "or": "ରଜିଆ ଭୂମିକା ଏବଂ ପ୍ରୋବେସନ୍ ବିଷୟରେ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛନ୍ତି । ସେ ଇମ୍ରାନ ଭାଇଙ୍କୁ ଚିଠିର ଏକ ଚିତ୍ର ପଠାନ୍ତି ।",
            "as": "ৰাজিয়াই তাইৰ পদবী আৰু পৰীক্ষণ কালৰ বিষয়ে বিমোৰত পৰিছে। তাই চিঠিখনৰ এখন ফটো ইমৰান দাদালৈ পঠিয়াই দিয়ে।",
            "gu": "રઝિયાપોતાના કામ અંગેની ભૂમિકા અને કામચલાવ ધોરણની નોકરીને લઈને મૂંઝવણમાં છે. તે ઈમરાન દાદાને પત્રનો ફોટો મોકલે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D1_2_2//",
            "hi": "D1_2_2//",
            "ka": "D1_2_2//",
            "te": "D1_2_2//",
            "or": "D1_2_2//",
            "as": "D1_2_2//",
            "gu": "D1_2_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Imran (over call)",
            "hi": "इमरान (कॉल पर)",
            "ka": "ಇಮ್ರಾನ್(ಕರೆ ಮೇಲೆ)",
            "te": "ఇమ్రాన్ (కాల్ లో)",
            "or": "ଇମ୍ରାନ୍ (କଲ୍ ରେ)",
            "as": "ইমৰান (টেলিফ'নৰ জৰিয়তে)",
            "gu": "ઈમરાન (કોલ પર)"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Imran",
            "hi": "इमरान",
            "ka": "ಇಮ್ರಾನ್",
            "te": "ఇమ్రాన్",
            "or": "ଇମ୍ରାନ",
            "as": "ইমৰান",
            "gu": "ઈમરાન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.4.label",
          "value": {
            "en": "Imran",
            "hi": "इमरान",
            "ka": "ಇಮ್ರಾನ್",
            "te": "ఇమ్రాన్",
            "or": "ଇମ୍ରାନ",
            "as": "ইমৰান",
            "gu": "ઈમરાન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Hello! Razia! Congratulations!",
            "hi": "हैलो! रजिया! बधाई हो!",
            "ka": "ನಮಸ್ಕಾರ! ರಝಿಯಾ! ಅಭಿನಂದನೆಗಳು!",
            "te": "హలో! రజియా! అభినందనలు!",
            "or": "ହେଲୋ! ରଜିଆ! ଅଭିନନ୍ଦନ!",
            "as": "হেল্ল! ৰাজিয়া! অভিনন্দন!",
            "gu": "નમસ્તે! રઝિયા! અભિનંદન!"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Yes dada! But.. I am not sure about the role.",
            "hi": "हाँ, दादा! लेकिन... मुझे अपने रोल को लेकर कन्फ्यूज हूँ।",
            "ka": "ಹೌದು ಅಣ್ಣ! ಆದರೆ.. ಪಾತ್ರದ ಬಗ್ಗೆ ಖಚಿತವಾಗಿಲ್ಲ.",
            "te": "అవును అన్నయ్య! కానీ.. ఆ పాత్రపై నాకు స్పష్టత లేదు.",
            "or": "ହଁ ଭାଇ! କିନ୍ତୁ..। ମୁଁ ଭୂମିକା ବିଷୟରେ ନିଶ୍ଚିତ ନୁହେଁ ।",
            "as": "হয় দাদা! কিন্তু..। মই পদবীটোৰ বিষয়ে নিশ্চিত নহয়।",
            "gu": "હ દાદા! પણ.. મને હજી સુધી મારા કામ વિષે પૂરી ખબર નથી"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "Oh, why?",
            "hi": "अच्छा, लेकिन क्यों?",
            "ka": "ಓಹ್, ಏಕೆ?",
            "te": "అయ్యో ఎందుకు?",
            "or": "ଓଃ, କାହିଁକି?",
            "as": "অহ, কিয়?",
            "gu": "અરે, કેમ?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.3.text",
          "value": {
            "en": "It is a mix of an environmental consultant AND a naturalist. I am just confident about the environment part. How will I take guests on tours?",
            "hi": "इसमें एनवायरमेंटल सलाहकार और नैचरलिस्ट दोनों का करना होगा। एनवायरमेंट के बारे में मुझें कोई चिंता नहीं है। लेकिन मैं मेहमानों को टूर पर कैसे ले जाऊंगी?",
            "ka": "ಇದು ಪರಿಸರ ಸಲಹೆಗಾರ ಮತ್ತು ನೈಸರ್ಗಿಕವಾದಿಗಳ ಮಿಶ್ರಣವಾಗಿದೆ. ಪರಿಸರದ ಬಗ್ಗೆ ನನಗೆ ವಿಶ್ವಾಸವಿದೆ. ಪ್ರವಾಸಗಳಿಗೆ ನಾನು ಅತಿಥಿಗಳನ್ನು ಹೇಗೆ ಕರೆದುಕೊಂಡು ಹೋಗುವುದು?",
            "te": "ఇది పర్యావరణ సలహాదారు మరియు ప్రకృతి శాస్త్రవేత్తల మిశ్రమం. పర్యావరణం భాగంపై నాకు నమ్మకం ఉంది. నేను అతిథులను పర్యటనలకు ఎలా తీసుకెళ్తాను?",
            "or": "ଏହା ଜଣେ ପରିବେଶ ପରାମର୍ଶଦାତା ଏବଂ ପ୍ରକୃତିବାଦୀଙ୍କ ମିଶ୍ରଣ । ମୁଁ କେବଳ ପରିବେଶ ବିଷୟରେ ଆତ୍ମବିଶ୍ୱାସୀ । ମୁଁ ଅତିଥିମାନଙ୍କୁ ଭ୍ରମଣରେ କିପରି ନେବି?",
            "as": "এইটো এজন পৰিৱেশ পৰামৰ্শদাতা আৰু এজন প্ৰকৃতিবিদৰ মিশ্ৰণ। মই কেৱল পৰিৱেশ অংশৰ বিষয়েহে আত্মবিশ্বাসী। মই অতিথিসকলক কেনেকৈ ভ্ৰমণলৈ লৈ যাম?",
            "gu": "તે પર્યાવરણીય સલાહકાર અને પ્રકૃતિવાદી બંનેની સંયુક્તભૂમિકા છે. હું માત્ર પર્યાવરણના ભાગ વિશે તૈયાર છું. અને હું મહેમાનોને પ્રવાસ ઉપર કેવી રીતે લઈ જઈશ?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.4.text",
          "value": {
            "en": "Hmm, I see.",
            "hi": "हम्म, समझ गया।",
            "ka": "ಹಾಂ, ನಾನು ನೋಡುತ್ತೇನೆ.",
            "te": "అవునా.",
            "or": "ହଁ, ମୁଁ ବୁଝୁଛି ।",
            "as": "হুম, মই বুজিছোঁ।",
            "gu": "હમ્મ, હું જોઉં છું."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "D1_2_3////",
            "hi": "D1_2_3////",
            "ka": "D1_2_3////",
            "te": "D1_2_3////",
            "or": "D1_2_3/////",
            "as": "D1_2_3////",
            "gu": "D1_2_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Also did you read? It says probation of 6 month… What does that mean?",
            "hi": "और यह तुमने पढ़ा है क्या? इसमें लिखा है 6 महीने का प्रोबेशन... इसका क्या मतलब है?",
            "ka": "ನೀವೂ ಓದಿದ್ದೀರಾ? ಇದು ೬ ತಿಂಗಳ ಪರೀಕ್ಷೆಯನ್ನು ಹೇಳುತ್ತದೆ... ಇದರ ಅರ್ಥವೇನು?",
            "te": "అలాగే మీరు చదివారా? ఇది 6 నెలల ప్రొబేషన్ అని చెబుతుంది.. దాని అర్థం ఏమిటి?",
            "or": "ଆଉ ମଧ୍ୟ ତୁମେ ପଢିଥିଲ କି? ଏଥିରେ 6 ମାସର ପ୍ରୋବେସନ୍ କୁହାଯାଇଛି... ତାହାର ମାନେ କଣ?",
            "as": "লগতে আপুনি পঢ়িছিলনে? ইয়াত 6 মাহৰ পৰীক্ষণ বুলি কোৱা হৈছে... সেইটো মানে কি?",
            "gu": "તે પણ એ વાંચ્યું? તેમાં 6 મહિનાનું પ્રોબેશન કહે છે… તેનો અર્થ શું થાય છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Oh, that's just the starting period for your employers to test if you are suited for the role. And also for you to get time to understand if you like it.",
            "hi": "ओह, यह बस स्टार्टिंग पीरियड है जिसमें तुम्हारे बॉस को पता चल जाएगा कि तुम इस रोल के लिए सही हो या नहीं। और तुम्हें भी यह जानने के लिए समय मिलेगा कि क्या यह काम तुम्हें पसंद है या नहीं।",
            "ka": "ಓಹ್, ನಿಮ್ಮ ಉದ್ಯೋಗದಾತರು ನೀವು ಪಾತ್ರಕ್ಕೆ ಸೂಕ್ತರೇ ಎಂದು ಪರೀಕ್ಷಿಸಲು ಇದು ಪ್ರಾರಂಭದ ಅವಧಿಯಾಗಿದೆ. ಮತ್ತು ನೀವು ಅದನ್ನು ಇಷ್ಟಪಡುತ್ತೀರಾ ಎಂದು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ನಿಮಗೆ ಸಮಯ ಸಿಗುತ್ತದೆ.",
            "te": "అవునా, మీరు పాత్రకు సరిపోతారో లేదో పరీక్షించడానికి మీ యజమానులకు ఇది ప్రారంభ కాలం. మరియు మీకు నచ్చిందో లేదో అర్థం చేసుకోవడానికి మీకు సమయం ఉంటుంది.",
            "or": "ଓଃ, ତୁମେ ଏହି ଭୂମିକା ପାଇଁ ଉପଯୁକ୍ତ କି ନୁହେଁ ତାହା ପରୀକ୍ଷା କରିବା ପାଇଁ ଏହା ତୁମର ନିଯୁକ୍ତିଦାତାମାନଙ୍କ କେବଳ ଏକ ପ୍ରାରମ୍ଭିକ ଅବଧି । ଏବଂ ଯଦି ତୁମେ ଏହାକୁ ପସନ୍ଦ କର ତେବେ ତୁମର ବୁଝିବା ପାଇଁ ମଧ୍ୟ ସମୟ ଆବଶ୍ୟକ ।",
            "as": "অহ, তুমি পদবীটোৰ বাবে উপযুক্ত হয় নে নহয় পৰীক্ষা কৰাৰ বাবে তোমাৰ নিয়োগকৰ্তাৰ বাবে এইটো কেৱল আৰম্ভণিৰ ম্যাদ। আৰু লগতে তুমি বুজিবলৈ সময় পাবা যে তুমি ভাল পাইছা নে নাই।",
            "gu": "ઓહ, તે તો તું તે નોકરી માટે યોગ્ય છે કે કેમ તે તપાસવા માટે નોકરીદાતા અને તારી વચ્ચેનો શરૂઆતનો સમય છે, અને ઉપરાત તે તારા માટે તારી ભૂમિકા સમજવા માટેનો પણ સમય છે. "
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Oh, I didn't see it like that.",
            "hi": "ओह, मैंने तो ऐसा सोचा नहीं था।",
            "ka": "ಓಹ್, ನಾನು ಹಾಗೆ ನೋಡಲಿಲ್ಲ.",
            "te": "అవునా,నేను దాన్ని అలా చూడలేదు.",
            "or": "ଓଃ, ମୁଁ ସେପରି ଭାବିନଥିଲି ।",
            "as": "অহ, মই তেনেকুৱা বুলি ভবা নাছিলোঁ।",
            "gu": "ઓહ, મેં તો એવું જોયું નથી."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Yes, so in the probation period you will know if this role is good for you or not.",
            "hi": "हाँ, तो इस प्रोबेशन पीरियड में तुम्हें पता चलेगा कि यह रोल तुम्हारे लिए अच्छा है या नहीं।",
            "ka": "ಹೌದು, ಆದ್ದರಿಂದ ಈ ಪಾತ್ರವು ನಿಮಗೆ ಉತ್ತಮವಾಗಿದೆಯೇ ಅಥವಾ ಇಲ್ಲವೇ ಎಂದು ಪರೀಕ್ಷಾ ಅವಧಿಯಲ್ಲಿ ನಿಮಗೆ ತಿಳಿಯುತ್ತದೆ.",
            "te": "అవును,కాబట్టి ఈ పాత్ర నీకు మంచిదా కాదా అనేది పరిశీలన కాలంలో, నీకు తెలుస్తుంది.",
            "or": "ହଁ, ତେଣୁ ପ୍ରୋବେସନ୍ ଅବଧିରେ ତୁମେ ଜାଣିବ ଯେ ଏହି ଭୂମିକା ତୁମ ପାଇଁ ଭଲ କି ନୁହେଁ ।",
            "as": "হয়, গতিকে পৰীক্ষণ কালৰ সময়ত তুমি জানিবা যে এই পদবীটো তোমাৰ বাবে ভাল হয় নে নহয়।",
            "gu": "હા, તો પ્રોબેશન સમય દરમિયાન તને ખબર પડશે કે આ કામ તારા માટે યોગ્ય છે કે નહીં."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "Probation period",
            "hi": "प्रोबेशन पीरियड",
            "ka": "ಪರೀಕ್ಷಣಾವಧಿ",
            "te": "పరిశీలన కాలం",
            "or": "ପ୍ରୋବେସନ୍ ଅବଧି",
            "as": "পৰীক্ষণ কাল",
            "gu": "પ્રોબેશન સમયગાળો "
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained knowledge about Probation!",
            "hi": "आपने अभी-अभी प्रोबेशन के बारे में जानकारी प्राप्त की है!",
            "ka": "ನೀವು ಪರೀಕ್ಷಣಾವಧಿ ಬಗ್ಗೆ ಜ್ಞಾನವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ",
            "te": "మీరు ఇప్పుడే పరిశీలన గురించి జ్ఞానాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ପ୍ରୋବେସନ୍ ବିଷୟରେ ଜ୍ଞାନ ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ পৰীক্ষণ কালৰ বিষয়ে জ্ঞান লাভ কৰিছা!",
            "gu": "તને પ્રોબેશન વિશે જ્ઞાન મળ્યું!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Probation",
            "hi": "प्रोबेशन",
            "ka": "ಪರೀಕ್ಷಣಾವಧಿ",
            "te": "పరిశీలన",
            "or": "ପ୍ରୋବେସନ୍",
            "as": "পৰীক্ষণ কাল",
            "gu": "પ્રોબેશન"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Understanding Work Environment",
            "hi": "काम के माहौल को समझना",
            "ka": "ಕೆಲಸದ ಪರಿಸರವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು",
            "te": "పని వాతావరణాన్ని అర్థం చేసుకోవడం",
            "or": "କାର୍ଯ୍ୟ ପରିବେଶ ବୁଝିବା",
            "as": "কামৰ পৰিৱেশ বুজা",
            "gu": "કામના વાતાવરણને સમજવું"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Raiza gets the job at the resort with a six month probation period. Probation is like a “trial period” at an organisation. During probation, the employer and the employee know if they are a good fit for the organisation and the role. Once the probation period is over, the employee can get a permanent job offer, or their contract ends based on their performance. As an employee, you can also choose or leave the job at that time.",
            "hi": "रजिया को छः महीने के प्रोबेशन पीरियड के साथ रिसोर्ट में जॉब मिल जाती है। प्रोबेशन संस्था में “ट्रायल पीरियड” की तरह होता है। प्रोबेशन के दौरान, बॉस और कर्मचारी को पता चलता है कि वह इस संस्था और इस रोल के लिए सही है या नहीं। प्रोबेशन पीरियड खत्म होने के बाद, कर्मचारी को परमनेंट जॉब ऑफर मिल सकता है या उनके काम के अनुसार उनका कांट्रेक्ट खत्म हो सकता है। कर्मचारी के तौर पर, आप काम करना जारी रखने सकते हैं या जॉब छोड़ सकते हैं।",
            "ka": "ರಝಿಯಾ ಆರು ತಿಂಗಳ ಪರೀಕ್ಷಾ ಅವಧಿಯೊಂದಿಗೆ ರೆಸಾರ್ಟ್‌ನಲ್ಲಿ ಕೆಲಸ ಪಡೆಯುತ್ತಾಳೆ. ಪರೀಕ್ಷೆಯು ಸಂಸ್ಥೆಯಲ್ಲಿ \"ಪ್ರಯೋಗದ ಅವಧಿ\" ಯಂತೆ.ಪರೀಕ್ಷೆಯ ಸಮಯದಲ್ಲಿ, ಉದ್ಯೋಗದಾತ ಮತ್ತು ಉದ್ಯೋಗಿ ಅವರು ಸಂಸ್ಥೆಗೆ ಮತ್ತು ಪಾತ್ರಕ್ಕೆ ಸೂಕ್ತವಾದರೆ ಎಂದು ತಿಳಿಯುತ್ತಾರೆ.ಪರೀಕ್ಷಾ ಅವಧಿಯು ಮುಗಿದ ನಂತರ, ಉದ್ಯೋಗಿ ಶಾಶ್ವತ ಉದ್ಯೋಗ ಪ್ರಸ್ತಾಪವನ್ನು ಪಡೆಯಬಹುದು ಅಥವಾ ಅವರ ಕಾರ್ಯಕ್ಷಮತೆಯ ಆಧಾರದ ಮೇಲೆ ಅವರ ಒಪ್ಪಂದವು ಕೊನೆಗೊಳ್ಳುತ್ತದೆ. ಉದ್ಯೋಗಿಯಾಗಿ, ನೀವು ಆ ಸಮಯದಲ್ಲಿ ಕೆಲಸವನ್ನು ಆಯ್ಕೆ ಮಾಡಬಹುದು ಅಥವಾ ಬಿಡಬಹುದು.",
            "te": "ఆరు నెలల ప్రొబేషన్ పీరియడ్‌తో రజియా రిసార్ట్‌లో ఉద్యోగం పొందింది.\n పరిశీలన అనేది ఒక సంస్థలో \"ట్రయల్ పీరియడ్\" లాంటిది. పరిశీలన సమయంలో, యజమాని మరియు ఉద్యోగి వారు సంస్థకు మరియు పాత్రకు సరిగ్గా సరిపోతారో లేదో తెలుసుకుంటారు. పరిశీలన వ్యవధి ముగిసిన తర్వాత, ఉద్యోగి శాశ్వత ఉద్యోగ ప్రతిపాదనను పొందవచ్చు లేదా వారి పనితీరు ఆధారంగా వారి ఒప్పందం ముగుస్తుంది. ఉద్యోగిగా, మీరు ఆ సమయంలో ఉద్యోగాన్ని ఎంచుకోవచ్చు లేదా వదిలివేయవచ్చు.",
            "or": "ରଜିଆ ଛଅ ମାସର ପ୍ରୋବେସନ୍ ଅବଧି ସହିତ ରିସର୍ଟରେ ଚାକିରି ପାଆନ୍ତି । ପ୍ରୋବେସନ୍ ଏକ ସଂଗଠନରେ ଏକ \"ପରୀକ୍ଷଣ ଅବଧି\" ପରି । ପ୍ରୋବେସନ୍ ସମୟରେ, ନିଯୁକ୍ତିଦାତା ଏବଂ କର୍ମଚାରୀ ଜାଣନ୍ତି ଯେ ସେମାନେ ସଂଗଠନ ଏବଂ ଭୂମିକା ପାଇଁ ଭଲ ଭାବରେ ଫିଟ୍ କି ନାହିଁ । ଥରେ ପ୍ରୋବେସନ୍ ଅବଧି ସମାପ୍ତ ହେବା ପରେ, କର୍ମଚାରୀ ଏକ ସ୍ଥାୟୀ ଚାକିରି ଅଫର୍ ପାଇପାରିବେ, କିମ୍ବା ସେମାନଙ୍କର କାର୍ଯ୍ୟଦକ୍ଷତା ଉପରେ ଆଧାର କରି ସେମାନଙ୍କର ଚୁକ୍ତି ଶେଷ ହୁଏ । ଜଣେ କର୍ମଚାରୀ ଭାବରେ, ଆପଣ ସେହି ସମୟରେ ଚାକିରି ବାଛିପାରିବେ କିମ୍ବା ଛାଡିପାରିବେ ମଧ୍ୟ ।",
            "as": "ৰাজিয়াই ছয় মাহৰ পৰীক্ষণ কালৰ সৈতে ৰিজৰ্টত চাকৰিটো পায়। প্ৰবেচন এটা সংগঠনত \"পৰীক্ষণ কাল\"ৰ দৰে। পৰীক্ষণ কালৰ সময়ত, নিয়োগকৰ্তা আৰু কৰ্মচাৰীয়ে তেওঁলোকৰ সংগঠন আৰু পদৰ বাবে উপযুক্ত হয় নে নহয় সেই কথা জানে। পৰীক্ষণ কাল শেষ হোৱাৰ পিছত, কৰ্মচাৰীজনে স্থায়ী চাকৰিৰ প্ৰস্তাৱ লাভ কৰিব পাৰে, বা তেওঁলোকৰ প্ৰদৰ্শনৰ ওপৰত ভিত্তি কৰি তেওঁলোকৰ চুক্তি সমাপ্ত হ'ব পাৰে। এজন কৰ্মচাৰী হিচাপে, তুমি সেই সময়ত চাকৰিটো কৰিব বা এৰিব পাৰা।",
            "gu": "રઝિયાને છ મહિનાના પ્રોબેશન સમયગાળા સાથે રિસોર્ટમાં નોકરી મળે છે.  પ્રોબેશન એ સંસ્થામાં \"કસોટી સમય\" જેવું છે. પ્રોબેશન દરમિયાન, માલિક અને કર્મચારી બન્ને જાણે છે કે શું તેઓ સંસ્થા અને ભૂમિકા માટે યોગ્ય છે કે નહીં. એકવાર પ્રોબેશનનો સમયગાળો પૂરો થઈ જાય પછી, કર્મચારીને કાયમી નોકરીની તક મળી શકે છે, અથવા તેનો કરાર તેમની કામગીરીના આધારે પૂરો થાય છે. એક કર્મચારી તરીકે, તમે તે સમયે નોકરી કરવાનું પસંદ કરી શકો છો અથવા છોડી પણ શકો છો."
          }
        }
      ]
    },
    "scene14": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_1_1",
            "hi": "D2_1_1",
            "ka": "D2_1_2",
            "te": "D2_1_1",
            "or": "D2_1_1",
            "as": "D2_1_1",
            "gu": "D2_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia begins her 6 month probation at the eco-resort. She works under the supervision of Sushma Ma’am, the senior environmentalist.",
            "hi": "रजिया इको-रिसोर्ट में 6 महीने का प्रोबेशन शुरू करती है। वह सीनियर एनवायरमेंटलिस्ट सुषमा मै म, के सुपरविजन के नीचे काम करना शुरू करती है।",
            "ka": "ರಝಿಯಾ ತನ್ನ 6 ತಿಂಗಳ ಪರೀಕ್ಷೆಯನ್ನು ಪರಿಸರ-ರೆಸಾರ್ಟ್‌ನಲ್ಲಿ ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ. ಅವರು ಹಿರಿಯ ಪರಿಸರವಾದಿ ಸುಷ್ಮಾ ಮೇಮ್ ಅವರ ಮೇಲ್ವಿಚಾರಣೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಾರೆ.",
            "te": "రజియా తన 6 నెలల పరిశీలనను ఎకో-రిసార్ట్‌లో ప్రారంభించింది. ఆమె సీనియర్ పర్యావరణవేత్త సుష్మా మేడమ్ పర్యవేక్షణలో పని చేస్తుంది.",
            "or": "ରଜିଆ ଇକୋ-ରିସର୍ଟରେ ତାଙ୍କର 6 ମାସର ପ୍ରୋବେସନ୍ ଆରମ୍ଭ କରନ୍ତି । ସେ ବରିଷ୍ଠ ପରିବେଶବିତ୍ ସୁଷମା ମାଡ଼ାମଙ୍କ ତତ୍ତ୍ୱାବଧାନରେ କାମ କରନ୍ତି ।",
            "as": "ৰাজিয়াই ইক'-ৰিজৰ্টত তাইৰ 6 মাহৰ পৰীক্ষণ কাল আৰম্ভ কৰে। তাই জ্যেষ্ঠ পৰিৱেশবিদ সুষমা মেডামৰ তত্বাৱধানত কাম কৰে।",
            "gu": "રઝિયાએ ઈકો-રિસોર્ટમાં 6 મહિનાનું પ્રોબેશન શરૂ કરી નાખે છે. તે વરિષ્ઠ પર્યાવરણવાદી સુષ્મા શ્રીમતીની દેખરેખ હેઠળ કામ કરે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_1_2",
            "hi": "D2_1_2",
            "ka": "D2_1_2",
            "te": "D2_1_2",
            "or": "D2_1_2",
            "as": "D2_1_2",
            "gu": "D2_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Sushma Ma’am is a strict manager who is very particular about the work she does at the eco-resort. Razia is scared of Sushma Ma’am, but she learns a lot from her.",
            "hi": "सुषमा मैम काफी सख्त मैनेजर है जो इको-रिसोर्ट में अपने काम को लेकर कोई भी लापरवाही पंसद नहीं करती। रजिया को सुषमा मैम का डर लगता है लेकिन वह उनसे बहुत कुछ सीखती भी है।",
            "ka": "ಸುಷ್ಮಾ ಮೇಮ್ ಅವರು ಕಟ್ಟುನಿಟ್ಟಾದ ನಿರ್ವಾಹಕರು, ಅವರು ಪರಿಸರ-ರೆಸಾರ್ಟ್‌ನಲ್ಲಿ ಮಾಡುವ ಕೆಲಸದ ಬಗ್ಗೆ ತುಂಬಾ ನಿರ್ದಿಷ್ಟವಾಗಿ ಹೇಳುತ್ತಾರೆ. ರಝಿಯಾ ಸುಷ್ಮಾ ಮೇಡಮ್‌ಗೆ ಹೆದರುತ್ತಾಳೆ, ಆದರೆ ಅವಳು ಅವರಿಂದ ಬಹಳಷ್ಟು ಕಲಿಯುತ್ತಾಳೆ",
            "te": "సుష్మా మేడమ్ ఎకో-రిసార్ట్‌లో చేసే పని గురించి చాలా నిర్దిష్టంగా ఉండే కఠినమైన నిర్వాహకురాలు. రజియా సుష్మా మేడమ్‌కి భయపడింది, కానీ ఆమె నుండి రజియా చాలా నేర్చుకుంటుంది.",
            "or": "ସୁଷମା ମାଡ଼ାମ ଜଣେ କଠୋର ପରିଚାଳକ ଯିଏ ଇକୋ-ରିସର୍ଟରେ କରୁଥିବା କାର୍ଯ୍ୟ ବିଷୟରେ ବହୁତ ନିର୍ଦ୍ଦିଷ୍ଟ । ରଜିଆ ସୁଷମା ମାଡ଼ାମଙ୍କୁ ଭୟ କରନ୍ତି, କିନ୍ତୁ ସେ ତାଙ୍କଠାରୁ ବହୁତ କିଛି ଶିଖନ୍ତି ।",
            "as": "সুষমা মেডাম এগৰাকী কঠোৰ পৰিচালক আৰু তেওঁ ইক'-ৰিজৰ্টত কৰা কামৰ বিষয়ে খুব সচেতন। ৰাজিয়াই সুষমা মেডামক ভয় কৰে, কিন্তু তাই তেওঁৰ পৰা বহুত শিকিছে।",
            "gu": "સુષ્મા મેમ એક કડક મેનેજર છે અને તે ઇકો રિસોર્ટમાં જે કામ કરી રહી છે તેના વિશે ખૂબ જ ચોક્કસ છે. રઝિયા સુષ્મા મેડમથી ડરે છે, પરંતુ તે એમની પાસેથી ખૂબ શીખે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_1_3",
            "hi": "D2_1_3",
            "ka": "D2_1_3",
            "te": "D2_1_3",
            "or": "D2_1_3",
            "as": "D2_1_3",
            "gu": "D2_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Then one day, in the third month of her probation…",
            "hi": "फिर एक दिन, उसके प्रोबेशन के तीसरे महीने में …",
            "ka": "ನಂತರ ಒಂದು ದಿನ, ಅವಳ ಪರೀಕ್ಷೆಯ ಮೂರನೇ ತಿಂಗಳಲ್ಲಿ …",
            "te": "తర్వాత ఒకరోజు,ఆమె పరిశీలన యొక్క మూడవ నెలలో...",
            "or": "ତା'ପରେ ଦିନେ, ତାଙ୍କ ପ୍ରୋବେସନ୍ ର ତୃତୀୟ ମାସରେ ...",
            "as": "তাৰ পিছত এদিন, তাইৰ পৰীক্ষণ কালৰ তৃতীয় মাহত...",
            "gu": "પછી એક દિવસ, તેના પ્રોબેશનના ત્રીજા મહિનામાં ..."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_1_4////",
            "hi": "D2_1_4////",
            "ka": "D2_1_4////",
            "te": "D2_1_4////",
            "or": "D2_1_4////",
            "as": "D2_1_4////",
            "gu": "D2_1_4////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Razia, why don’t you take over the tour tomorrow with the guests?",
            "hi": "रजिया, कल तुम मेहमानों के साथ टूर पर क्यों नहीं जाती?",
            "ka": "ರಝಿಯಾ, ನೀನು ಅತಿಥಿಗಳೊಂದಿಗೆ ನಾಳೆ ಪ್ರವಾಸವನ್ನು ಏಕೆ ತೆಗೆದುಕೊಳ್ಳಬಾರದು?",
            "te": "రజియా,నువ్వు అతిథులతో కలిసి రేపటి పర్యటనకు వెళ్ళగలవా?",
            "or": "ରଜିଆ, ତୁମେ ଆସନ୍ତାକାଲି ଅତିଥିମାନଙ୍କ ସହିତ କାହିଁକି ଭ୍ରମଣରେ ଯାଉନ?",
            "as": "ৰাজিয়া, তুমি কাইলৈ অতিথিসকলৰ ভ্ৰমণৰ দায়িত্ব কিয় নোলোৱা?",
            "gu": "રઝિયા, તું આવતીકાલે મહેમાનો સાથે પ્રવાસે કેમ સંભાળતી નથી?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Oh no ma’am, I'm not sure if I'm ready.",
            "hi": "ओह नहीं, मैम, पता नहीं मैं इसके लिए तैयार हूँ या नहीं।",
            "ka": "ಅಯ್ಯೋ ಇಲ್ಲ ಮೇಡಂ, ನಾನು ಸಿದ್ಧನಿದ್ದೇನೆಯೇ ಎಂದು ನನಗೆ ಖಚಿತವಿಲ್ಲ.",
            "te": "అయ్యో మేడమ్,నేను సిద్ధంగా ఉన్నానో లేదో నాకు ఖచ్చితంగా తెలియదు.",
            "or": "ଓଃ ନା ମାଡ଼ାମ, ମୁଁ ନିଶ୍ଚିତ ନୁହେଁ ଯେ ମୁଁ ସେଥିପାଇଁ ପ୍ରସ୍ତୁତ କି ନାହିଁ ।",
            "as": "অহ্ মেডাম, মই সাজু হয় নে নহয় নিশ্চিত নহয় ।",
            "gu": "ના મેડમ, મને થોડી ઓછી ખાતરી છે કે હું તૈયાર છું કે નહીં."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "What is there to be ready about?   How will you work independently in the future? I will not always be there to guide you.",
            "hi": "इसमें तैयार होने की क्या बात है? तुम भविष्य में अकेली कैसे काम करोगी? तुम्हें मार्गदर्शन करने के लिए मैं हमेशा तुम्हारे साथ तो नहीं रह सकती।",
            "ka": "ಸಿದ್ಧವಾಗಿರಲು ಏನು ಇದೆ? ಭವಿಷ್ಯದಲ್ಲಿ ನೀನು ಸ್ವತಂತ್ರವಾಗಿ ಹೇಗೆ ಕೆಲಸ ಮಾಡುತ್ತೀಯ? ನಿನಗೆ ಮಾರ್ಗದರ್ಶನ ನೀಡಲು ನಾನು ಯಾವಾಗಲೂ ಇರುವುದಿಲ್ಲ",
            "te": "దేని గురించి సిద్ధంగా ఉండాలి? భవిష్యత్తులో నువ్వు స్వతంత్రంగా ఎలా పని చేస్తావు? ప్రతీసారి నీకు చెప్పడానికి నేను ఉండను.",
            "or": "ପ୍ରସ୍ତୁତ ହେବାର କ'ଣ ଅଛି? ଭବିଷ୍ୟତରେ ତୁମେ କିପରି ସ୍ୱାଧୀନ ଭାବରେ କାର୍ଯ୍ୟ କରିବ? ମୁଁ ସବୁବେଳେ ତୁମକୁ ମାର୍ଗଦର୍ଶନ କରିବାକୁ ରହିବି ନାହିଁ ।",
            "as": "কিহৰ বাবে সাজু থাকিব লাগে? ভৱিষ্যতে তুমি কেনেদৰে স্বাধীন ভাৱে কাম কৰিবা? মই সদায় তোমাক বাট দেখুৱাবলৈ নাথাকিম।",
            "gu": "એમાં શું તૈયાર રહેવાનું છે? તું ભવિષ્યમાં સ્વતંત્ર રીતે કેવી રીતે કામ કરીશ? હું તને  માર્ગદર્શન આપવા માટે હંમેશા ત્યાં રહીશ નહીં."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "But ma’am….",
            "hi": "लेकिन मैम ….",
            "ka": "ಆದರೆ ಮೇಮ್..",
            "te": "కాని మేడమ్....",
            "or": "କିନ୍ତୁ ମାଡ଼ାମ...",
            "as": "কিন্তু মেডাম ...।",
            "gu": "પણ મેડમ..."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_2_1",
            "hi": "D2_2_1",
            "ka": "D2_2_1",
            "te": "D2_2_1",
            "or": "D2_2_1",
            "as": "D2_2_1",
            "gu": "D2_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia very hesitantly takes the tour. Being observed by Sushma Ma’am makes her nervous and she makes many mistakes.",
            "hi": "रजिया कतराते हुए टूर के लिए तैयार हो गई। सुषमा मैम उस पर नजर रखे हुए थी इसलिए वह घबरा रही थी और कई गलतियां कर रही थी।",
            "ka": "ರಝಿಯಾ ತುಂಬಾ ಹಿಂಜರಿಕೆಯಿಂದ ಪ್ರವಾಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾಳೆ. ಸುಷ್ಮಾ ಮೇಡಮ್ ಅವರನ್ನು ಗಮನಿಸುವುದರಿಂದ ಅವಳು ಉದ್ವೇಗಗೊಳ್ಳುತ್ತಾಳೆ ಮತ್ತು ಅವಳು ಅನೇಕ ತಪ್ಪುಗಳನ್ನು ಮಾಡುತ್ತಾಳೆ.",
            "te": "రజియా చాలా సంకోచంగా పర్యటనకు వెళుతుంది.సుష్మా మేడమ్‌ తనను గమనించడం వల్ల ఆమె భయపడి చాలా తప్పులు చేస్తుంది.",
            "or": "ରଜିଆ ବହୁତ ଦ୍ୱିଧାରେ ଭ୍ରମଣରେ ଯାଆନ୍ତି । ସୁଷମା ମାଡ଼ାମଙ୍କ ଦ୍ୱାରା ପାଳନ କରାଯିବା ତାଙ୍କୁ ନର୍ଭସ କରିଥାଏ ଏବଂ ସେ ଅନେକ ଭୁଲ କରନ୍ତି ।",
            "as": "ৰাজিয়াই অতি সংকোচেৰে ভ্ৰমণৰ দায়িত্ব লয়। সুষমা মেডামৰ দ্বাৰা পৰ্যবেক্ষণ কৰাটোৱে তাইক উদ্বিগ্ন কৰি তোলে আৰু তাই বহুতো ভুল কৰে।",
            "gu": "રઝિયા ખૂબ જ અચકતા પ્રવાસ પર નીકળે છે. સુષ્મા મેડમ દ્વારા નિહાળવાથી તે ઉદાસ થઈ જાય છે અને તે ઘણી ભૂલો પણ કરે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "D2_2_2",
            "hi": "D2_2_2",
            "ka": "D2_2_2",
            "te": "D2_2_2",
            "or": "D2_2_2",
            "as": "D2_2_2",
            "gu": "D2_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Sushma Ma’am is not impressed and tells Razia if she doesn’t improve, they will have to end her probation.",
            "hi": "सुषमा मैम इससे प्रभावित नहीं हुई और उसने रजिया को बताया कि अगर रजिया ने अपने कार्य को नहीं सुधारा तो उन्हें उसका प्रोबेशन खत्म करना होगा।",
            "ka": "ಸುಷ್ಮಾ ಮೇಡಮ್ ಪ್ರಭಾವಿತರಾಗಲಿಲ್ಲ ಮತ್ತು ರಝಿಯಾ ಸುಧಾರಿಸದಿದ್ದರೆ, ಅವರು ತಮ್ಮ ಪರೀಕ್ಷೆಯನ್ನು ಕೊನೆಗೊಳಿಸಬೇಕಾಗುತ್ತದೆ ಎಂದು ಹೇಳುತ್ತಾರೆ.",
            "te": "సుష్మా మేడమ్ కి ఇది నచ్చలేదు మరియు రజియా మెరుగుపడకపోతే,వారు తన పరిశీలన ను ముగించవలసి ఉంటుంది అని చెప్పింది.",
            "or": "ସୁଷମା ମାଡ଼ାମ ଏହା ଦ୍ଵାରା ପ୍ରଭାବିତ ହୁଅନ୍ତି ନାହିଁ ଏବଂ ରଜିଆଙ୍କୁ କୁହନ୍ତି ଯେ ଯଦି ସେ ଉନ୍ନତି ନ କରନ୍ତି, ତେବେ ସେମାନଙ୍କୁ ତାଙ୍କ ପ୍ରୋବେସନ୍ ଶେଷ କରିବାକୁ ପଡିବ ।",
            "as": "সুষমা মেডামে ভাল নাপায় আৰু ৰাজিয়াক কয় যে যদি তাই উন্নতি নকৰে, তেওঁলোকে তাইৰ পৰীক্ষণ কাল শেষ কৰিব লাগিব।",
            "gu": "સુષ્મા મેડમ પ્રભાવિત  નથી થતાં અને રઝિયાને કહે છે કે જો તે પોતાનામાં સુધારો નહીં લાવે, તો તેણે તેનું પ્રોબેશન સમય સમાપ્ત કરવો પડશે."
          }
        }
      ]
    },
    "scene15": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D3_1_1",
            "hi": "D3_1_1",
            "ka": "D3_1_1",
            "te": "D3_1_1",
            "or": "D3_1_1",
            "as": "D3_1_1",
            "gu": "D3_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "On the weekend, Razia and her family visit the city to meet Imran dada.",
            "hi": "सप्ताह की छुट्टी के दिन रजिया और उसके माता-पिता इमरान दादा से मिलने शहर जाते हैं।",
            "ka": "ವಾರಾಂತ್ಯದಲ್ಲಿ, ರಝಿಯಾ ಮತ್ತು ಅವರ ಕುಟುಂಬ ಇಮ್ರಾನ್ ದಾದಾ ಅವರನ್ನು ಭೇಟಿ ಮಾಡಲು ನಗರಕ್ಕೆ ಭೇಟಿ ನೀಡುತ್ತಾರೆ.",
            "te": "వారాంతంలో,రజియా మరియు ఆమె కుటుంబం ఇమ్రాన్ అన్నయ్యను కలవడానికి నగరానికి వెళ్తారు.",
            "or": "ସପ୍ତାହ ଶେଷରେ, ରଜିଆ ଏବଂ ତାଙ୍କ ପରିବାର ଇମ୍ରାନ ଭାଇଙ୍କୁ ଭେଟିବା ପାଇଁ ସହର ପରିଦର୍ଶନ କରନ୍ତି ।",
            "as": "সপ্তাহটোৰ শেষত ৰাজিয়া আৰু তেওঁৰ পৰিয়ালে চহৰখনলৈ ইমৰান দাদাক লগ কৰিবলৈ আহে।",
            "gu": "અઠવાડીયાના અંતે, રઝિયા અને તેના કુટુંબ વાળા ઇમરાન દાદાને મળવા શહેર જાય છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D3_1_2",
            "hi": "D3_1_2",
            "ka": "D3_1_2",
            "te": "D3_1_2",
            "or": "D3_1_2",
            "as": "D3_1_2",
            "gu": "D3_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia decides to go to a bookstore to buy a book on public speaking that can help her prepare better for the tour.",
            "hi": "रजिया पब्लिक स्पीकिंग पर किताब खरीदने के लिए बुकस्टोर जाने का निर्णय लेती है ताकि टूर पर उसका कार्य प्रदर्शन अच्छा हो सकें।",
            "ka": "ಸಾರ್ವಜನಿಕ ಭಾಷಣದ ಪುಸ್ತಕವನ್ನು ಖರೀದಿಸಲು ರಝಿಯಾ ಪುಸ್ತಕದಂಗಡಿಗೆ ಹೋಗಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ, ಅದು ಪ್ರವಾಸಕ್ಕೆ ಉತ್ತಮವಾಗಿ ತಯಾರಿ ಮಾಡಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
            "te": "రజియా బహిరంగ ప్రసంగం (పబ్లిక్ స్పీకింగ్) గురించి పుస్తకాన్ని కొనుగోలు చేయడానికి పుస్తక దుకాణానికి వెళ్లాలని నిర్ణయించుకుంది, అది పర్యటనకు మరింత మెరుగ్గా సిద్ధం కావడానికి సహాయపడుతుంది.",
            "or": "ରଜିଆ ସାର୍ବଜନୀନ ଭାଷଣ ଉପରେ ଏକ ପୁସ୍ତକ କିଣିବା ପାଇଁ ଏକ ପୁସ୍ତକ ଦୋକାନକୁ ଯିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି ଯାହା ତାଙ୍କୁ ଭ୍ରମଣ ପାଇଁ ଭଲ ଭାବରେ ପ୍ରସ୍ତୁତ କରିବାରେ ସାହାଯ୍ୟ କରିପାରିବ ।",
            "as": "ৰাজিয়াই ৰাজহুৱাভাৱে কথা কোৱাৰ ওপৰত লিখা কিতাপ এখন কিনিবলৈ এখন কিতাপৰ দোকানলৈ যোৱাৰ কথা ভাবে যিয়ে তাইক ভ্ৰমণৰ বাবে ভালদৰে প্ৰস্তুত কৰাত সহায় কৰিব পাৰে।",
            "gu": "રઝિયા પુસ્તક ખરીદવા બુકસ્ટોર પર જવાનું નક્કી કરે છે જે લોકો સાથે વાર્તાલાપ પર હોય જેથી તેને પ્રવાસ માટે વધુ સારી રીતે તૈયાર કરવામાં મદદ મળી શકે"
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D3_1_3",
            "hi": "D3_1_3",
            "ka": "D3_1_3",
            "te": "D3_1_3",
            "or": "D3_1_3",
            "as": "D3_1_3",
            "gu": "D3_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She browses through the entire bookstore and is left confused. She sees two racks -   \n1. Communication and public speaking  \n2. Career growth and planning",
            "hi": "वह पूरा बुकस्टोर देख लेती है और कन्फ्यूज हो जाती है। वहाँ पर दो रैक दिखाई देते हैं - \n 1. संभाषण और पब्लिक स्पीकिंग \n 2. करियर वृद्धि और प्लानिंग",
            "ka": "\"ಅವಳು ಇಡೀ ಪುಸ್ತಕದಂಗಡಿಯಲ್ಲಿ ಬ್ರೌಸ್ ಮಾಡುತ್ತಾಳೆ ಮತ್ತು ಗೊಂದಲಕ್ಕೊಳಗಾಗುತ್ತಾಳೆ. ಅವಳು ಎರಡು ಚರಣಿಗೆಗಳನ್ನು ನೋಡುತ್ತಾಳೆ -\n 1. ಸಂವಹನ ಮತ್ತು ಸಾರ್ವಜನಿಕ ಭಾಷಣ\n 2. ವೃತ್ತಿ ಬೆಳವಣಿಗೆ ಮತ್ತು ಯೋಜನೆ\"",
            "te": "ఆమె మొత్తం పుస్తకాల దుకాణాన్ని చూసి అయోమయంలో పడింది.ఆమె రెండు అరలను చూసింది -\n 1. కమ్యూనికేషన్ మరియు బహిరంగ ప్రసంగం\n 2. కెరీర్ పెరుగుదల మరియు ప్రణాళిక \"",
            "or": "ସେ ସମସ୍ତ ପୁସ୍ତକ ଦୋକାନ ଖୋଜନ୍ତି ଏବଂ ଦ୍ୱନ୍ଦ୍ୱରେ ରହନ୍ତି । ସେ ଦୁଇଟି ଥାକ ଦେଖନ୍ତି – \n 1. ଯୋଗାଯୋଗ ଏବଂ ସାର୍ବଜନୀନ ଭାଷଣ\n 2. କ୍ୟାରିୟର ଅଭିବୃଦ୍ଧି ଏବଂ ଯୋଜନା",
            "as": "তাই গোটেই কিতাপৰ দোকানখন বিচাৰি চলাথ কৰে আৰু বিমোৰত পৰে। তাই দুটা ৰেক দেখিছে - \n 1. যোগাযোগ আৰু ৰাজহুৱা স্থানত কথা কোৱা \n 2. কেৰিয়াৰৰ বিকাশ আৰু পৰিকল্পনা",
            "gu": "તેણે આખી પુસ્તકોની દુકાન જોઈ લીધી અને અંતે તે મુઝાઇ ગયી. તે બે ખાના જુએ છે -\n1. વાર્તાલાપ અને જાહેરમાં બોલવું\n2. કારકિર્દી વૃદ્ધિ અને આયોજન"
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D3_1_4",
            "hi": "D3_1_4",
            "ka": "D3_1_4",
            "te": "D3_1_4",
            "or": "D3_1_4",
            "as": "D3_1_4",
            "gu": "D3_1_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She has the money to buy one book from the store. She thinks to herself, what if I learn public speaking and still don’t get the confidence? What if I am in the wrong job?",
            "hi": "उसके पास स्टोर से सिर्फ एक ही किताब खरीदने के लिए पैसे हैं। वह सोचती है कि अगर मैंने पब्लिक स्पीकिंग की किताब खरीदी लेकिन मेरे पास आत्मविश्वास नहीं होगा तो क्या मैं करूंगी? अगर मैंने गलत जॉब चुना होगा तो क्या होगा?",
            "ka": "ಅಂಗಡಿಯಿಂದ ಒಂದು ಪುಸ್ತಕವನ್ನು ಖರೀದಿಸಲು ಅವಳ ಬಳಿ ಹಣವಿದೆ. ಅವಳು ತಾನೇ ಯೋಚಿಸುತ್ತಾಳೆ, ನಾನು ಸಾರ್ವಜನಿಕ ಭಾಷಣವನ್ನು ಕಲಿತರೆ ಮತ್ತು ಇನ್ನೂ ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಪಡೆಯದಿದ್ದರೆ ಏನು? ನಾನು ತಪ್ಪು ಕೆಲಸದಲ್ಲಿದ್ದರೆ ಏನು?",
            "te": "దుకాణం నుండి ఒక పుస్తకం కొనుక్కోవడానికి ఆమె వద్ద డబ్బు ఉంది. నేను పబ్లిక్ స్పీకింగ్ నేర్చుకుని ఇంకా ఆత్మవిశ్వాసం పొందలేకపోతే ఎలా అని ఆమె తనలో తాను అనుకుంటుంది? నేను తప్పు ఉద్యోగంలో ఉంటే ఏమి చేయాలి?",
            "or": "ଦୋକାନରୁ ଗୋଟିଏ ପୁସ୍ତକ କିଣିବା ପାଇଁ ତାଙ୍କ ପାଖରେ ଟଙ୍କା ଅଛି । ସେ ନିଜକୁ ନିଜେ କୁହନ୍ତି, ଯଦି ମୁଁ ସାର୍ବଜନୀନ ଭାଷଣ ବିଷୟରେ ଶିଖେ ଏବଂ ତଥାପି ଆତ୍ମବିଶ୍ୱାସ ପାଏ ନାହିଁ? ଯଦି ମୁଁ ଭୁଲ୍ ଚାକିରିରେ ଅଛି ତେବେ କ'ଣ ହେବ?",
            "as": "দোকানৰ পৰা এখন কিতাপ কিনিবলৈ তাইৰ ওচৰত টকা আছে। তাই নিজে ভাবে, যদি মই ৰাজহুৱাভাৱে কথা কোৱা শিকিম আৰু তথাপিও আত্মবিশ্বাস নাপাওঁ তেন্তে কি হ'ব? যদি মই ভুল চাকৰিত আছোঁ তেন্তে কি হ'ব?",
            "gu": "તેના જોડે એક પુસ્તક ખરીદવા માટે પૈસા છે. તે પોતાને પૂછે છે કે જો લોકો જોડે વાતો કેમ કરવી તે શિખું અને તેમ છતાં મને વિશ્વાસ ના આવે તો? તો શું ખોટી નોકરીમાં છું?"
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "D3_1_5(D)",
            "hi": "D3_1_5(D)",
            "ka": "D3_1_5(D)",
            "te": "D3_1_5(D)",
            "or": "D3_1_5(D)",
            "as": "D3_1_5(D)",
            "gu": "D3_1_5(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Razia do?",
            "hi": "रजिया को क्या करना चाहिए?",
            "ka": "ರಝಿಯಾ ಏನು ಮಾಡಬೇಕು?",
            "te": "రజియా ఏం చేయాలి?",
            "or": "ରଜିଆ କ'ଣ କରିବା ଉଚିତ୍?",
            "as": "ৰাজিয়াই কি কৰা উচিত?",
            "gu": "રઝિયાએ શું કરવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Pick a book on communication and public speaking to upskill herself",
            "hi": "खुद को अपस्किल करने के लिए संभाषण और पब्लिक स्पीकिंग की किताब खरीदनी चाहिए",
            "ka": "ತನ್ನ ಕೌಶಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸಲು ಸಂವಹನ ಮತ್ತು ಸಾರ್ವಜನಿಕ ಭಾಷಣದ ಪುಸ್ತಕವನ್ನು ಆರಿಸಿ",
            "te": "తన నైపుణ్యాన్ని పెంచుకోవడానికి బహిరంగంగా మాట్లాడటం మరియు కమ్యూనికేషన్ పుస్తకాన్ని ఎంచుకోవడం",
            "or": "ତାଙ୍କୁ ନିଜର ଉନ୍ନତି କରିବା ପାଇଁ ଯୋଗାଯୋଗ ଏବଂ ସାର୍ବଜନୀନ ଭାଷଣ ଉପରେ ଏକ ପୁସ୍ତକ ବାଛନ୍ତୁ",
            "as": "নিজৰ উন্নতিৰ বাবে তাই যোগাযোগ আৰু ৰাজহুৱা স্থানত কথা কোৱাৰ বিষয়ে এখন কিতাপ বাছনি কৰক",
            "gu": "પોતાનામાં  ઉચ્ચ કૌશલ્ય વિકસાવવા માટે વાર્તાલાપ અને પબ્લિક સ્પીકિંગ પર એક પુસ્તક પસંદ કરવું જોઈએ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Pick a book career growth and planning to reconsider her career path",
            "hi": "अपने करियर के बारे में फिर से विचार करने के लिए करियर वृद्धि और प्लानिंग की किताब खरीदनी चाहिए",
            "ka": "ಪುಸ್ತಕ ವೃತ್ತಿಜೀವನದ ಬೆಳವಣಿಗೆಯನ್ನು ಆರಿಸಿ ಮತ್ತು ಅವಳ ವೃತ್ತಿ ಮಾರ್ಗವನ್ನು ಮರುಪರಿಶೀಲಿಸಲು ಯೋಜಿಸಿ",
            "te": "కెరీర్ వృద్ధి కోసం ఒక పుస్తకాన్ని ఎంచుకోవడం మరియు ఆమె కెరీర్ మార్గాన్ని పునఃపరిశీలించడానికి ప్లాన్ చేయడం",
            "or": "କ୍ୟାରିୟର ଅଭିବୃଦ୍ଧି ଏବଂ ତାଙ୍କ କ୍ୟାରିୟର ପଥର ପୁନର୍ବିଚାର କରିବାକ ପାଇଁ ଯୋଜନା ଉପରେ ଏକ ପୁସ୍ତକ ବାଛନ୍ତୁ",
            "as": "তাইৰ কেৰিয়াৰৰ পথ পুনৰ বিবেচনা কৰিবলৈ কেৰিয়াৰৰ বিকাশ আৰু পৰিকল্পনা থকা কিতাপ এখন বাছনি কৰক",
            "gu": "એક પુસ્તક લો ,કારકિર્દી વૃદ્ધિ અને આયોજન ઉપર અને તેના કારકિર્દી ઘડતર પર પુનર્વિચાર કરો"
          }
        }
      ]
    },
    "scene16": {
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "Upskilling_Upskill on public speaking",
            "hi": "पब्लिक स्पीकिंग के कौशल को सुधारना",
            "ka": "ಉನ್ನತ ಕೌಶಲ್ಯ_ಸಾರ್ವಜನಿಕ ಭಾಷಣದಲ್ಲಿ ಕೌಶಲ್ಯ",
            "te": "నైపుణ్యం పెంచడం _ బహిరంగ ప్రసంగంపై _అధిక నైపుణ్యం",
            "or": "ଅପସ୍କିଲିଂ_ସାର୍ବଜନୀନ ଭାଷଣରେ ଉନ୍ନତି",
            "as": "আপস্কিলিং_ৰাজহুৱা ভাৱে কথা কোৱাৰ দক্ষতাৰ উন্নতি কৰা",
            "gu": "જાહેરમાં બોલવા પર ઉચ્ચ કૌશલ્યવર્ધન "
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained upskilling experience!",
            "hi": "आपने अभी-अभी अपस्किल करने का अनुभव प्राप्त किया है!",
            "ka": "ನೀವು ಕೌಶಲ್ಯದ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే నైపుణ్యం పెంచుకునే అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ଅପସ୍କିଲିଂ ଅଭିଜ୍ଞତା ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ আপস্কিলিংৰ অভিজ্ঞতা লাভ কৰিছা!",
            "gu": "તમે અત્યારે જ ઉચ્ચ કૌશલ્યનો અનુભવ લીધો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Upskilling",
            "hi": "अपस्किल करना",
            "ka": "ಉನ್ನತ ಕೌಶಲ್ಯ",
            "te": "నైపుణ్యం పెంచడం",
            "or": "ଅପସ୍କିଲିଂ",
            "as": "আপস্কিলিং",
            "gu": "ઉચ્ચ કૌશલ્ય"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर डेवलपमेंट कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "కెరీర్ అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ୟାରିୟର ବିକାଶ ଦକ୍ଷତା",
            "as": "কেৰিয়াৰ বিকাশৰ দক্ষতা",
            "gu": "કારકિર્દી વિકાસ કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "To continue working in the eco-resort, Razia has to upskill her public speaking skills or lose her job. Upskilling means keeping yourself updated about new developments in your field of work. It is learning new skills from time to time. It means that even after you finish studying in school or college, you continue to learn - either through short courses, certification, workshops. Upskilling can open more growth possibilities for you in your career. You can upskill either by doing an online course, attending training, or workshops.",
            "hi": "इको-रिसोर्ट में काम करते रहने के लिए, रजिया को अपना पब्लिक स्पीकिंग कौशल अपस्किल करना होगा या अपने जॉब को अलविदा कहना होगा। अपस्किल करना मतलब अपने कार्यक्षेत्र में होनेवाले नए विकासों के बारे में खुद को अपडेट रखना। इसका मतलब है समय-समय पर नई बातें सीखना। यानी स्कूल और कॉलेज में अपनी पढ़ाई पूरी करने के बाद भी, छोटे से कोर्स, प्रमाणपत्र या वर्कशॉप द्वारा आप कुछ ना कुछ सीखते रहते हैं। अपस्किल करने से आपके करियर में वृद्धि के कई मार्ग खुल जाते हैं। आप ऑनलाइन कोर्स करके, ट्रेनिंग या वर्कशॉप जॉइन करके खुद को अपस्किल कर सकते हैं",
            "ka": "ಪರಿಸರ-ರೆಸಾರ್ಟ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಮುಂದುವರಿಸಲು, ರಝಿಯಾ ತನ್ನ ಸಾರ್ವಜನಿಕ ಮಾತನಾಡುವ ಕೌಶಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸಿಕೊಳ್ಳಬೇಕು ಅಥವಾ ತನ್ನ ಕೆಲಸವನ್ನು ಕಳೆದುಕೊಳ್ಳಬೇಕು.ಅಪ್‌ಸ್ಕಿಲ್ಲಿಂಗ್ ಎಂದರೆ ನಿಮ್ಮ ಕೆಲಸದ ಕ್ಷೇತ್ರದಲ್ಲಿನ ಹೊಸ ಬೆಳವಣಿಗೆಗಳ ಬಗ್ಗೆ ನಿಮ್ಮನ್ನು ನವೀಕರಿಸಿಕೊಳ್ಳುವುದು. ಕಾಲಕಾಲಕ್ಕೆ ಹೊಸ ಕೌಶಲ್ಯಗಳನ್ನು ಕಲಿಯುವುದು ಎಂದರ್ಥ.ಇದರರ್ಥ ನೀವು ಶಾಲೆ ಅಥವಾ ಕಾಲೇಜಿನಲ್ಲಿ ಓದುವುದನ್ನು ಮುಗಿಸಿದ ನಂತರವೂ ನೀವು ಕಲಿಯುವುದನ್ನು ಮುಂದುವರಿಸುತ್ತೀರಿ - ಚಿಕ್ಕ ಕೋರ್ಸ್‌ಗಳು, ಪ್ರಮಾಣೀಕರಣ, ಕಾರ್ಯಾಗಾರಗಳ ಮೂಲಕ.ಉನ್ನತ ಕೌಶಲ್ಯವು ನಿಮ್ಮ ವೃತ್ತಿಜೀವನದಲ್ಲಿ ನಿಮಗೆ ಹೆಚ್ಚಿನ ಬೆಳವಣಿಗೆಯ ಸಾಧ್ಯತೆಗಳನ್ನು ತೆರೆಯುತ್ತದೆ. ಆನ್‌ಲೈನ್ ಕೋರ್ಸ್ ಮಾಡುವ ಮೂಲಕ, ತರಬೇತಿಗೆ ಹಾಜರಾಗುವ ಮೂಲಕ ಅಥವಾ ಕಾರ್ಯಾಗಾರಗಳ ಮೂಲಕ ನೀವು ಕೌಶಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸಬಹುದು.",
            "te": "ఎకో-రిసార్ట్‌లో పని చేయడం కొనసాగించడానికి, రజియా బహిరంగంగా మాట్లాడే నైపుణ్యాలను పెంచుకోవాల్సి ఉంది లేదా ఆమె ఉద్యోగాన్ని కోల్పోతుంది.నైపుణ్యలను పెంచుకోవడం అంటే మీ పని రంగంలో కొత్త పరిణామాల గురించి మిమ్మల్ని మీరు నవీకరణ లో ఉంచుకోవడం. ఎప్పటికప్పుడు కొత్త నైపుణ్యాలను నేర్చుకోవడం. అంటే మీరు పాఠశాలలో లేదా కళాశాలలో చదవడం పూర్తయిన తర్వాత కూడా, మీరు చిన్న కోర్సులు, సర్టిఫికేషన్, వర్క్‌షాప్‌ల ద్వారా నేర్చుకుంటూనే ఉంటారు.నైపుణ్యం పెంచుకోవడం, మీకు కెరీర్‌లో మరింత వృద్ధి అవకాశాలను మీకు అందిస్తుంది . మీరు ఆన్‌లైన్ కోర్సు చేయడం, శిక్షణకు హాజరు కావడం లేదా వర్క్‌షాప్‌లు చేయడం ద్వారా నైపుణ్యాన్ని పెంచుకోవచ్చు.",
            "or": "ଇକୋ-ରିସର୍ଟରେ କାମ ଜାରି ରଖିବାକୁ, ରଜିଆଙ୍କୁ ତାଙ୍କର ସାର୍ବଜନୀନ ଭାଷଣ କୌଶଳକୁ ବୃଦ୍ଧି କରିବାକୁ ପଡିବ କିମ୍ବା ଚାକିରି ହରାଇବାକୁ ପଡିବ । ଅପସ୍କିଲିଂର ଅର୍ଥ ହେଉଛି ତୁମର କାର୍ଯ୍ୟ କ୍ଷେତ୍ରରେ ନୂତନ ବିକାଶ ବିଷୟରେ ନିଜକୁ ଅପଡେଟ୍ ରଖିବା । ଏହା ହେଉଛି ସମୟ ସମୟରେ ନୂତନ କୌଶଳ ଶିଖିବା । ଏହାର ଅର୍ଥ ହେଉଛି ଯେ ତୁମେ ବିଦ୍ୟାଳୟ କିମ୍ବା କଲେଜରେ ଅଧ୍ୟୟନ ଶେଷ କରିବା ପରେ ମଧ୍ୟ, ଶିଖିବା ଜାରି ରଖିଛ - ହୁଏତ ସଂକ୍ଷିପ୍ତ ପାଠ୍ୟକ୍ରମ, ପ୍ରମାଣିକରଣ (ସାର୍ଟିଫିକେଶନ), କର୍ମଶାଳା ମାଧ୍ୟମରେ । ଅପସ୍କିଲିଂ ତୁମ କ୍ୟାରିୟରରେ ତୁମ ପାଇଁ ଅଧିକ ଅଭିବୃଦ୍ଧି ସମ୍ଭାବନା ଖୋଲିପାରେ । ତୁମେ ଏକ ଅନଲାଇନ୍ ପାଠ୍ୟକ୍ରମ କରି, ତାଲିମରେ ଯୋଗ ଦେଇ, କିମ୍ୱା କର୍ମଶାଳାଗୁଡିକ ଦ୍ଵାରା ଅପସ୍କିଲ୍ କରିପାରିବ।",
            "as": "ইক'-ৰিজৰ্টত কাম কৰি থাকিবলৈ, ৰাজিয়াই তেওঁৰ ৰাজহুৱা কথা কোৱাৰ দক্ষতা বৃদ্ধি কৰিব লাগিব নহলে চাকৰি হেৰুৱাব লাগিব। আপস্কিলিং মানে হৈছে তোমাৰ কামৰ ক্ষেত্ৰত নতুন বিকাশৰ বিষয়ে নিজকে আপডেট কৰি ৰখা। ই হৈছে সময়ে সময়ে নতুন দক্ষতা আহৰণ কৰি থকা ব্যৱস্থা । ইয়াৰ অৰ্থ হৈছে যে তুমি স্কুল বা কলেজত পঢ়া শেষ কৰাৰ পিছতো চমু পাঠ্যক্ৰম বা কৰ্মশালাৰ জৰিয়তে শিকি থাকিব লাগিব। আপস্কিলিঙে তোমাৰ কেৰিয়াৰত তোমাৰ বাবে অধিক বিকাশৰ সম্ভাৱনা মুকলি কৰিব পাৰে। তুমি হয় অনলাইন পাঠ্যক্ৰম কৰি, প্ৰশিক্ষণত অংশগ্ৰহণ কৰি বা কৰ্মশালাত অংশগ্ৰহণ কৰি দক্ষতা বৃদ্ধি কৰিব পাৰা।",
            "gu": "રઝિયાએ જો ઇકો-રિસોર્ટમાં આગળ કામ કરવું હોય તો, જાહેરમાં બોલવાની કુશળતા વધારવી પડશે અથવા તો નોકરી છોડવી પડશે. ઉચ્ચ કૌશલ્યનો અર્થ છે તમારા કાર્યક્ષેત્રમાં થતાં નવા બદલાવ/પરિવર્તન વિશે તમારી જાતને બદલવું રહેવું. એટલે કે સમયાંતરે નવી કુશળતા શીખવી પડશે. તેનો અર્થ એ છે કે તમે શાળા અથવા કૉલેજમાં અભ્યાસ પૂર્ણ કર્યા પછી પણ, તમે ટૂંકા અભ્યાસક્રમો, પ્રમાણપત્રો, વર્કશોપ દ્વારા શીખવાનું ચાલુ રાખો છો. ઉચ્ચ કૌશલ્ય તમારા માટે તમારી કારકિર્દીમાં વૃદ્ધિની વધુ શક્યતાઓ બતાવે શકે છે. તમે ઓનલાઈન કોર્સ કરીને, તાલીમમાં અથવા વર્કશોપમાં હાજરી આપીને ઉચ્ચ કૌશલ્ય પણ કરી શકો છો."
          }
        }
      ],
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D4_1_1",
            "hi": "D4_1_1",
            "ka": "D4_1_1",
            "te": "D4_1_1",
            "or": "D4_1_1",
            "as": "D4_1_1",
            "gu": "D4_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She gets a book called 'How do you develop self-confidence and improve public speaking'.",
            "hi": "वह एक किताब लेती है जिसका नाम है ‘आत्मविश्वास कैसे विकसित करें और पब्लिक स्पीकिंग कैसे सुधारें’।",
            "ka": "ಆಕೆ 'ನೀವು ಹೇಗೆ ಆತ್ಮ ವಿಶ್ವಾಸವನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳುತ್ತೀರಿ ಮತ್ತು ಸಾರ್ವಜನಿಕ ಭಾಷಣವನ್ನು ಸುಧಾರಿಸುತ್ತೀರಿ' ಎಂಬ ಪುಸ್ತಕವನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
            "te": "ఆమె 'మీరు ఆత్మవిశ్వాసాన్ని ఎలా అభివృద్ధి చేసుకోవడం \n మరియు బహిరంగ ప్రసంగాన్ని మెరుగుపరచడం అనే పుస్తకం కొన్నది",
            "or": "ସେ 'ତୁମେ କିପରି ଆତ୍ମବିଶ୍ୱାସ ବିକଶିତ କରିବ ଏବଂ ସାର୍ବଜନୀନ ଭାଷଣରେ ଉନ୍ନତି ଆଣିବ' ନାମକ ଏକ ପୁସ୍ତକ ପାଇଲେ ।",
            "as": "তাই 'আপুনি কেনেদৰে আত্মবিশ্বাস বিকাশ কৰিব আৰু ৰাজহুৱা বক্তৃতা উন্নত কৰিব' নামৰ এখন কিতাপ পায়।",
            "gu": "તેણીને 'હાઉ ડુ યુ ડેવલપ સેલ્ફ કોન્ફિડન્સ એન્ડ ઇમ્પ્રુવ પબ્લિક સ્પીકિંગ' નામનું પુસ્તક મળે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D4_1_2",
            "hi": "D4_1_2",
            "ka": "D4_1_2",
            "te": "D4_1_2",
            "or": "D4_1_2",
            "as": "D4_1_2",
            "gu": "D4_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Among many learnings, one that stood out for her is - \n'To fight the fear of public speaking, practice with your friends.'",
            "hi": "इसमें दी हुई कई बातों में से उसे एक बात बहुत ज्यादा पसंद आयी, वह है - \n 'पब्लिक स्पीकिंग के डर से लड़ने के लिए, अपने दोस्तों के साथ अभ्यास करें।’",
            "ka": "\"ಅನೇಕ ಕಲಿಕೆಗಳಲ್ಲಿ, ಅವಳಿಗೆ ಎದ್ದುಕಾಣುವ ಒಂದು -\n \"ಸಾರ್ವಜನಿಕವಾಗಿ ಮಾತನಾಡುವ ಭಯದ ವಿರುದ್ಧ ಹೋರಾಡಲು, ನಿಮ್ಮ ಸ್ನೇಹಿತರೊಂದಿಗೆ ಅಭ್ಯಾಸ ಮಾಡಿ.\"",
            "te": "\"అనేక అభ్యాసాలలో, ఆమెకు ప్రత్యేకంగా నిలిచేది ఏమిటంటే -\n 'బహిరంగ ప్రసంగం యొక్క భయంతో పోరాడటానికి, మీ స్నేహితులతో సాధన చేయండి.'",
            "or": "ଅନେକ ଶିକ୍ଷା ମଧ୍ୟରେ, ଯାହା ତାଙ୍କ ପାଇଁ ବିଶିଷ୍ଟ ଥିଲା ତାହା ହେଉଛି - 'ସାର୍ବଜନୀନ ଭାଷଣର ଭୟ ସହିତ ଲଢିବା ପାଇଁ, ତୁମର ସାଙ୍ଗମାନଙ୍କ ସହିତ ଅଭ୍ୟାସ କର। '",
            "as": "বহুতো শিক্ষাৰ ভিতৰত, যিটো তাইৰ লগত মিলিছিল সেয়া হ'ল - \n 'ৰাজহুৱা স্থানত কথা কোৱাৰ ভয়ৰ সৈতে যুঁজিবলৈ, আপোনাৰ বন্ধুবৰ্গৰ সৈতে অনুশীলন কৰক।'",
            "gu": "તેણીની ઘણું શીખી, જેમથી એક મહત્વની શીખ હતી કે \"જાહેરમાં બોલવાના ડર સામે કેવી રીતે લડવું\" તમે પણ તમારા મિત્રો સાથે અભ્યાસ કરો "
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D4_1_3",
            "hi": "D4_1_3",
            "ka": "D4_1_3",
            "te": "D4_1_3",
            "or": "D4_1_3",
            "as": "D4_1_3",
            "gu": "D4_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Inspired by the suggestion, she calls a few of her friends and decides to do a practice session with them. They give her feedback and Razia corrects herself.",
            "hi": "इस सुझाव से प्रभावित होकर उसने अपने कुछ दोस्तों कॉल किया और उनके साथ अभ्यास करने का तय किया। उन्होंने उसे प्रतिक्रियाएं दी और उनके अनुसार रजिया ने अपनी गलतियां सुधारी।",
            "ka": "ಸಲಹೆಯಿಂದ ಪ್ರೇರಿತಳಾದ ಆಕೆ ತನ್ನ ಕೆಲ ಸ್ನೇಹಿತರನ್ನು ಕರೆದು ಅವರೊಂದಿಗೆ ಅಭ್ಯಾಸ ನಡೆಸಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಅವರು ಅವಳ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ನೀಡುತ್ತಾರೆ ಮತ್ತು ರಝಿಯಾ ತನ್ನನ್ನು ತಾನೇ ಸರಿಪಡಿಸಿಕೊಳ್ಳುತ್ತಾಳೆ.",
            "te": "సూచన ద్వారా ప్రేరణ పొంది, ఆమె తన స్నేహితుల్లో కొందరిని పిలిచి, వారితో ఒక సాధన సెషన్ చేయాలని నిర్ణయించుకుంది. వారు తమ అభిప్రాయాన్ని ఆమెకు అందించారు మరియు రజియా తనను తాను సరిదిద్దుకుంది.",
            "or": "ଏହି ପରାମର୍ଶ ଦ୍ୱାରା ଅନୁପ୍ରାଣିତ ହୋଇ ସେ ତାଙ୍କର କିଛି ବନ୍ଧୁଙ୍କୁ ଡାକନ୍ତି ଏବଂ ସେମାନଙ୍କ ସହିତ ଏକ ଅଭ୍ୟାସ ଅଧିବେଶନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି । ସେମାନେ ତାଙ୍କୁ ମତାମତ ଦିଅନ୍ତି ଏବଂ ରଜିଆ ନିଜକୁ ସଂଶୋଧନ କରନ୍ତି ।",
            "as": "পৰামৰ্শটোৰ দ্বাৰা অনুপ্ৰাণিত হৈ, তাই তাইৰ কেইজনমান বন্ধুক মাতিছিল আৰু তেওঁলোকৰ সৈতে এটা অনুশীলন কৰাৰ সিদ্ধান্ত লৈছিল। তেওঁলোকে তাইক মতামত দিয়ে আৰু ৰাজিয়াই নিজকে শুদ্ধ কৰে।",
            "gu": "આ સૂચનાથી પ્રેરિત થઈને, તે તેના કેટલાક મિત્રોને બોલાવે છે અને તેમની સાથે જાહેરમાં બોલવાનો અનુભવ કરવાનું નક્કી કરે છે. તેઓ રઝિયાને  પ્રતિભાવ આપે છે અને રઝિયા પોતાને સુધારે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D4_2_1",
            "hi": "D4_2_1",
            "ka": "D4_2_1",
            "te": "D4_2_1",
            "or": "D4_2_1",
            "as": "D4_2_1",
            "gu": "D4_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She again does a session with the guests and Sushma Ma’am observes quietly. She notices Razia adding a lot of stories from her childhood to keep the guest engaged.",
            "hi": "उसने फिर से मेहमानों के साथ एक सेशन किया और इस बार भी सुषमा मैम उसका निरिक्षण कर रही थी। उन्होंने देखा कि मेहमानों की दिलचस्पी बनाए रखने के लिए रजिया अपने बचपन के कई किस्से सूना रही है।",
            "ka": "ಅವಳು ಮತ್ತೆ ಅತಿಥಿಗಳೊಂದಿಗೆ ಸೆಷನ್ ಮಾಡುತ್ತಾಳೆ ಮತ್ತು ಸುಷ್ಮಾ ಮೇಡಮ್ ಸದ್ದಿಲ್ಲದೆ ಗಮನಿಸುತ್ತಾರೆ. ಅತಿಥಿಯನ್ನು ತೊಡಗಿಸಿಕೊಳ್ಳಲು ರಝಿಯಾ ತನ್ನ ಬಾಲ್ಯದ ಕಥೆಗಳನ್ನು ಸೇರಿಸುವುದನ್ನು ಅವರು ಗಮನಿಸುತ್ತಾರೆ.",
            "te": "ఆమె మళ్ళీ అతిథులతో సెషన్ చేస్తుంది మరియు సుష్మా మేడమ్ నిశ్శబ్దంగా గమనిస్తోంది.అతిథిని ఎంగేజ్ చేయడానికి రజియా తన చిన్ననాటి నుండి చాలా కథలను చెప్పడాన్ని ఆమె గమనించింది.",
            "or": "ସେ ପୁନର୍ବାର ଅତିଥିମାନଙ୍କ ସହିତ ଏକ ଅଧିବେଶନ କରନ୍ତି ଏବଂ ସୁଷମା ମାଡ଼ାମ୍ ଚୁପ୍ ଚାପ୍ ଦେଖନ୍ତି । ଅତିଥିଙ୍କୁ ନିୟୋଜିତ ରଖିବା ପାଇଁ ରଜିଆ ତାଙ୍କ ପିଲାଦିନରୁ ଅନେକ କାହାଣୀ ଯୋଡିଥିବା ସେ ଲକ୍ଷ୍ୟ କରନ୍ତି ।",
            "as": "তাই আকৌ অতিথিসকলৰ সৈতে এটা অধিৱেশন কৰে আৰু সুষমা বাইদেৱে শান্তভাৱে লক্ষ্য কৰে। তেওঁ লক্ষ্য কৰিছে যে অতিথিসকলক ব্যস্ত ৰাখিবলৈ ৰাজিয়াই তাইৰ শৈশৱৰ বহুতো কাহিনী শুনাইছিল।",
            "gu": "તે ફરીથી મહેમાનો સાથે બેઠક કરે છે અને સુષ્મા મેડમ શાંતિથી નિહાળે છે. મેડમે નોંધ્યું કે રઝિયા મહેમાનને વ્યસ્ત રાખવા માટે તેના બાળપણની ઘણી વાર્તાઓ કહે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D4_2_2",
            "hi": "D4_2_2",
            "ka": "D4_2_2",
            "te": "D4_2_2",
            "or": "D4_2_2",
            "as": "D4_2_2",
            "gu": "D4_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Sushma Ma’am is impressed with Razia's improvement and new found confidence. She offers Razia a permanent job.",
            "hi": "रजिया के कार्य में हुए सुधार और आत्मविश्वास से सुषमा मैम काफी प्रभवित हुई। उन्होंने रजिया को परमनेंट जॉब की ऑफर दी।",
            "ka": "ಸುಷ್ಮಾ ಮೇಮ್ ರಝಿಯಾ ಅವಳ ಸುಧಾರಣೆ ಮತ್ತು ಹೊಸ ಆತ್ಮವಿಶ್ವಾಸದಿಂದ ಪ್ರಭಾವಿತರಾಗಿದ್ದಾರೆ. ಅವರು ರಝಿಯಾಗೆ ಖಾಯಂ ಉದ್ಯೋಗವನ್ನು ನೀಡುತ್ತಾರೆ.",
            "te": "రజియా యొక్క మెరుగుదల మరియు కొత్తగా కనుగొన్న ఆత్మవిశ్వాసానికి సుష్మా మేడమ్ ముగ్దులయ్యారు . ఆమె రజియాకు శాశ్వత ఉద్యోగాన్ని అందిస్తుంది",
            "or": "ରଜିଆର ଉନ୍ନତି ଏବଂ ନୂତନ ଆତ୍ମବିଶ୍ୱାସରେ ସୁଷମା ମାଡ଼ାମ୍ ପ୍ରଭାବିତ ହୁଅନ୍ତି । ସେ ରଜିଆଙ୍କୁ ଏକ ସ୍ଥାୟୀ ଚାକିରି ପ୍ରଦାନ କରନ୍ତି ।",
            "as": "সুষমা বাইদেৱে ৰাজিয়াৰ উন্নতি আৰু নতুন আত্মবিশ্বাসত প্ৰভাৱিত হৈছে। তেঁও ৰাজিয়াক এটা স্থায়ী চাকৰি দিয়ে।",
            "gu": "સુષ્મા મેડમ રઝિયામાં આવેલા પરિવર્તન અને આત્મવિશ્વાસના સંચારથી અભિભૂત થાય છે અને તેને કાયમી નોકરી આપે છે"
          }
        }
      ]
    },
    "scene17": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D5_1_1",
            "hi": "D5_1_1",
            "ka": "D5_1_1",
            "te": "D5_1_1",
            "or": "D5_1_1",
            "as": "D5_1_1",
            "gu": "D5_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Seeing her do well with the tours, Sushma Ma’am starts doing fewer tours with the guests. Razia meets tourists from different parts of the world.",
            "hi": "उसे अच्छे से टूर करते हुए देखकर, सुषमा मैम ने मेहमानों के साथ कम टूर करने लगी। रजिया दुनियाभर के अलग-अलग हिस्सों से आए हुए टूरिस्टों से मिलती थी।",
            "ka": "ಅವಳು ಪ್ರವಾಸಗಳನ್ನು ಉತ್ತಮವಾಗಿ ಮಾಡುವುದನ್ನು ನೋಡಿದ ಸುಷ್ಮಾ ಮೇಮ್ ಅತಿಥಿಗಳೊಂದಿಗೆ ಕಡಿಮೆ ಪ್ರವಾಸಗಳನ್ನು ಮಾಡಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ. ರಝಿಯಾ ಪ್ರಪಂಚದ ವಿವಿಧ ಭಾಗಗಳಿಂದ ಪ್ರವಾಸಿಗರನ್ನು ಭೇಟಿಯಾಗುತ್ತಾಳೆ.",
            "te": "ఆమె పర్యటనలు బాగా చేయడం చూసి,సుష్మా మేడమ్ అతిథులతో కలిసి పర్యటనలు చేయడం తగ్గించింది.రజియా ప్రపంచంలోని వివిధ ప్రాంతాల నుండి వచ్చే పర్యాటకులను కలుస్తుంది.",
            "or": "ତାଙ୍କୁ ଭ୍ରମଣରେ ଭଲ ପ୍ରଦର୍ଶନ କରୁଥିବା ଦେଖି ସୁଷମା ମାଡ଼ାମ୍ ଅତିଥିମାନଙ୍କ ସହିତ କମ୍ ଭ୍ରମଣ କରିବା ଆରମ୍ଭ କରନ୍ତି । ରଜିଆ ଦୁନିଆର ବିଭିନ୍ନ ସ୍ଥାନରୁ ପର୍ଯ୍ୟଟକଙ୍କୁ ଭେଟନ୍ତି ।",
            "as": "তাইক ভ্ৰমণবোৰ সুন্দৰকৈ কৰা দেখি, সুষমা বাইদেৱে অতিথিসকলৰ সৈতে কমকৈ ভ্ৰমণ কৰিবলৈ লয়। ৰাজিয়াই পৃথিৱীৰ বিভিন্ন প্ৰান্তৰ পৰ্যটকক লগ কৰে।",
            "gu": "પ્રવાસમાં તેને સારું કરતા જોઈને, સુષ્મા મેડમ મહેમાનો સાથે ઓછા પ્રવાસ કરવાનું શરૂ કરે છે. રઝિયા વિશ્વના અલગ અલગ ભાગોમાંથી પ્રવાસીઓને મળે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D5_1_2",
            "hi": "D5_1_2",
            "ka": "D5_1_2",
            "te": "D5_1_2",
            "or": "D5_1_2",
            "as": "D5_1_2",
            "gu": "D5_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Apart from conducting the tours, her job includes talking to the guests about mangrove forest, conducting workshops with the locals on protecting the environment, and helping create monthly reports.",
            "hi": "टूर आयोजित करने के अलावा, उसका काम है मेहमानों को मैनग्रोव के जंगलों के बारे में बताना, पर्यावरण की रक्षा करने के लिए स्थानिक लोगों के साथ वर्कशॉप आयोजित करना और मासिक रिपोर्ट तैयार करने में मदद करना।",
            "ka": "ಪ್ರವಾಸಗಳನ್ನು ನಡೆಸುವುದರ ಹೊರತಾಗಿ, ಮ್ಯಾಂಗ್ರೋವ್ ಕಾಡಿನ ಬಗ್ಗೆ ಅತಿಥಿಗಳೊಂದಿಗೆ ಮಾತನಾಡುವುದು, ಪರಿಸರವನ್ನು ರಕ್ಷಿಸುವ ಕುರಿತು ಸ್ಥಳೀಯರೊಂದಿಗೆ ಕಾರ್ಯಾಗಾರಗಳನ್ನು ನಡೆಸುವುದು ಮತ್ತು ಮಾಸಿಕ ವರದಿಗಳನ್ನು ರಚಿಸಲು ಸಹಾಯ ಮಾಡುವುದು ಅವಳ ಕೆಲಸ.",
            "te": "పర్యటనలు నిర్వహించడమే కాకుండా, మాంగ్రూవ్ అడవుల గురించి అతిథులతో మాట్లాడటం,పర్యావరణాన్ని పరిరక్షించడంపై స్థానికులతో వర్క్ షాప్‌లు నిర్వహించడం మరియు నెలవారీ నివేదికలను రూపొందించడంలో సహాయపడటం వీటిఅన్నింటిని ఆమె ఉద్యోగం లో చేర్చారు",
            "or": "ଭ୍ରମଣ କରିବା ବ୍ୟତୀତ, ତାଙ୍କ ଚାକିରିରେ ଅତିଥିମାନଙ୍କ ସହିତ ମାନଗ୍ରୁଭ୍ ଜଙ୍ଗଲ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରିବା, ପରିବେଶ ସୁରକ୍ଷା ଉପରେ ସ୍ଥାନୀୟ ଲୋକଙ୍କ ସହ କର୍ମଶାଳା ପରିଚାଳନା କରିବା ଏବଂ ମାସିକ ରିପୋର୍ଟ ପ୍ରସ୍ତୁତ କରିବାରେ ସାହାଯ୍ୟ କରିବା ଅନ୍ତର୍ଭୁକ୍ତ ।",
            "as": "ভ্ৰমণবোৰ পৰিচালনা কৰাৰ উপৰিও, তাইৰ কামৰ ভিতৰত আছে অতিথিসকলৰ সৈতে মেংগ্ৰোভ অৰণ্যৰ বিষয়ে কথা পতা, পৰিৱেশ সুৰক্ষিত কৰাৰ ওপৰত স্থানীয় লোকসকলৰ সৈতে কৰ্মশালা অনুষ্ঠিত কৰা, আৰু মাহিলী প্ৰতিবেদন সৃষ্টি কৰাত সহায় কৰা।",
            "gu": "પ્રવાસોના આયોજન કરવા ઉપરાંત, તે કામમાં મેન્ગ્રોવ જંગલ વિશે મહેમાનો સાથે વાત કરવી, પર્યાવરણની સુરક્ષા પર સ્થાનિકો સાથે વર્કશોપ યોજવી અને માસિક અહેવાલો બનાવવામાં મદદ કરવાનો સમાવેશ થાય છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D5_1_3",
            "hi": "D5_1_3",
            "ka": "D5_1_3",
            "te": "D5_1_3",
            "or": "D5_1_3",
            "as": "D5_1_3",
            "gu": "D5_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Happy with her knowledge and interest, some of the guests suggest that she should apply in eco-resorts outside of West Bengal but Razia is happy staying with her family and doing well at her job.",
            "hi": "उसकी ज्ञान और दिलचस्पी को देखकर खुश होते हुए कुछ मेहमानों ने रजिया को सुझाव दिया कि उसे पश्चिम बंगाल के बाहर के इको-रिसोर्ट में अप्लाई करना चाहिए लेकिन रजिया अपने परिवार के साथ रहकर खुश है और उसका जॉब भी अच्छा चल रहा है।",
            "ka": "ಆಕೆಯ ಜ್ಞಾನ ಮತ್ತು ಆಸಕ್ತಿಯಿಂದ ಸಂತೋಷವಾಗಿರುವ ಕೆಲವು ಅತಿಥಿಗಳು ಅವಳು ಪಶ್ಚಿಮ ಬಂಗಾಳದ ಹೊರಗಿನ ಪರಿಸರ-ರೆಸಾರ್ಟ್‌ಗಳಲ್ಲಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಬೇಕೆಂದು ಸಲಹೆ ನೀಡುತ್ತಾರೆ ಆದರೆ ರಝಿಯಾ ತನ್ನ ಕುಟುಂಬದೊಂದಿಗೆ ಸಂತೋಷದಿಂದ ಇದ್ದು ತನ್ನ ಕೆಲಸದಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಾಳೆ.",
            "te": "ఆమె జ్ఞానం మరియు ఆసక్తి చూసి సంతోషించి, కొంతమంది అతిథులు ఆమె పశ్చిమ బెంగాల్ వెలుపల ఉన్న ఎకో-రిసార్ట్ లలో దరఖాస్తు చేసుకోవాలని సూచిస్తున్నారు, అయితే రజియా తన కుటుంబంతో సంతోషంగా ఉంటూ తన ఉద్యోగంలో బాగా పనిచేస్తోంది.",
            "or": "ତାଙ୍କ ଜ୍ଞାନ ଏବଂ ଆଗ୍ରହରେ ଖୁସି, କିଛି ଅତିଥି ପରାମର୍ଶ ଦିଅନ୍ତି ଯେ ସେ ପଶ୍ଚିମବଙ୍ଗ ବାହାରେ ଇକୋ-ରିସର୍ଟରେ ଆବେଦନ କରିବା ଉଚିତ୍ କିନ୍ତୁ ରଜିଆ ତାଙ୍କ ପରିବାର ସହିତ ରହି ତାଙ୍କ ଚାକିରିରେ ଭଲ ପ୍ରଦର୍ଶନ କରି ଖୁସି ଅଛନ୍ତି ।",
            "as": "তাইৰ জ্ঞান আৰু আগ্ৰহেৰে সুখী, কিছুমান অতিথিয়ে পৰামৰ্শ দিয়ে যে তাই পশ্চিম বংগৰ বাহিৰৰ ইক'-ৰিজৰ্টত এপ্লাই কৰিব লাগে কিন্তু ৰাজিয়া তাইৰ পৰিয়ালৰ সৈতে থাকি আৰু তাইৰ কাম ভালকৈ কৰি সুখী।",
            "gu": "તેના જ્ઞાન અને રસથી ખુશ થઈને કેટલાક મહેમાનો સૂચવે છે કે તેને પશ્ચિમ બંગાળની બહાર ઇકો-રિસોર્ટમાં અરજી કરવી જોઈએ પરંતુ રઝિયા તેના કુટુંબ સાથે રહીને અને તેની નોકરી સારી રીતે કરી રહી છે તેમાં તે ખુશ છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ1",
            "hi": "MCQ1",
            "ka": "MCQ1",
            "te": "MCQ1",
            "or": "MCQ1",
            "as": "MCQ1",
            "gu": "MCQ1"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia does internet research to find and gain a scholarship. Internet research helps us gain basic information about careers and industries. It also helps us know about new opportunities. Select the statement which is TRUE:",
            "hi": "रजिया इंटरनेट पर रिसर्च करके स्कोलरशिप के बारे में जानकारी प्राप्त करती है। इंटरनेट रिसर्च हमें करियर और उद्योगों के बारे में मुलभुत जानकारी प्राप्त करने में मदद करता है। यह हमें नए अवसरों के बारे में जानकारी प्राप्त करने में भी मदद करता है। सही विधान को चुनें:",
            "ka": "ವಿದ್ಯಾರ್ಥಿವೇತನವನ್ನು ಹುಡುಕಲು ಮತ್ತು ಪಡೆಯಲು ರಝಿಯಾ ಇಂಟರ್ನೆಟ್ ಸಂಶೋಧನೆ ಮಾಡುತ್ತಾಳೆ. ಇಂಟರ್ನೆಟ್ ಸಂಶೋಧನೆಯು ವೃತ್ತಿ ಮತ್ತು ಉದ್ಯಮಗಳ ಬಗ್ಗೆ ಮೂಲಭೂತ ಮಾಹಿತಿಯನ್ನು ಪಡೆಯಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಹೊಸ ಅವಕಾಶಗಳ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಲು ಇದು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನಿಜವಾಗಿರುವ ಹೇಳಿಕೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ:",
            "te": "రజియా స్కాలర్‌షిప్‌ (ఉపకార వేతనం) గురించి కనుగొని దానిని పొందేందుకు ఇంటర్నెట్ పరిశోధన చేస్తుంది.కెరీర్‌లు మరియు పరిశ్రమల గురించి ప్రాథమిక సమాచారాన్ని పొందడంలో ఇంటర్నెట్ పరిశోధన మాకు సహాయపడుతుంది. ఇది కొత్త అవకాశాల గురించి తెలుసుకోవడానికి కూడా మాకు సహాయపడుతుంది:ఏది నిజమైన స్టేట్ మెంటో దానిని ఎంచుకోండి",
            "or": "ରଜିଆ ଏକ ଛାତ୍ରବୃତ୍ତି ଖୋଜିବା ଏବଂ ହାସଲ କରିବା ପାଇଁ ଇଣ୍ଟରନେଟ୍ ରେ ଅନୁସନ୍ଧାନ କରନ୍ତି । ଇଣ୍ଟରନେଟ୍ ରେ ଅନୁସନ୍ଧାନ ଆମକୁ କ୍ୟାରିୟର ଏବଂ ଶିଳ୍ପ ବିଷୟରେ ମୌଳିକ ସୂଚନା ହାସଲ କରିବାରେ ସାହାଯ୍ୟ କରେ । ଏହା ଆମକୁ ନୂତନ ସୁଯୋଗ ବିଷୟରେ ଜାଣିବାରେ ମଧ୍ୟ ସାହାଯ୍ୟ କରେ । ଠିକ ଥିବା ଉକ୍ତିଟି ଚୟନ କରନ୍ତୁ:",
            "as": "ৰাজিয়াই স্কলাৰশ্বিপ বিচাৰিবলৈ আৰু লাভ কৰিবলৈ ইণ্টাৰনেট ব্যৱহাৰ কৰে। ইণ্টাৰনেটৰ ব্যৱহাৰে আমাক কেৰিয়াৰ আৰু উদ্যোগৰ বিষয়ে মৌলিক তথ্য আহৰণ কৰাত সহায় কৰে। ই আমাক নতুন সুযোগৰ বিষয়ে জনাত সহায় কৰে। সঁচা উক্তিটো বাছনি কৰা:",
            "gu": "શિષ્યવૃત્તિની શોધમાં અને મેળવવા માટે રઝિયા ઇન્ટરનેટ જોવે છે. ઈન્ટરનેટના લીધે અમને કારકિર્દી અને ઉદ્યોગો વિશે મૂળભૂત માહિતી મેળવવામાં મદદ કરે છે. તે અમને નવી તકો વિશે જાણવામાં પણ મદદ કરે છે. જે સાચું વાક્ય છે તે પસંદ કરો:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "The internet is the only way to do research and find information.",
            "hi": "रिसर्च करने और जानकारी प्राप्त करने के लिए केवल इंटरनेट ही एकमात्र मार्ग है",
            "ka": "ಸಂಶೋಧನೆ ಮಾಡಲು ಮತ್ತು ಮಾಹಿತಿಯನ್ನು ಹುಡುಕಲು ಇಂಟರ್ನೆಟ್ ಏಕೈಕ ಮಾರ್ಗವಾಗಿದೆ.",
            "te": "పరిశోధనలు చేయడానికి మరియు సమాచారాన్ని కనుగొనడానికి ఇంటర్నెట్ మాత్రమే ఏకైక మార్గం.",
            "or": "ଅନୁସନ୍ଧାନ କରିବା ଏବଂ ସୂଚନା ଖୋଜିବା ପାଇଁ ଇଣ୍ଟରନେଟ୍ ହେଉଛି ଏକମାତ୍ର ଉପାୟ ।",
            "as": "গৱেষণা আৰু তথ্য বিচাৰি উলিওৱাৰ একমাত্ৰ উপায় হৈছে ইণ্টাৰনেট।",
            "gu": "ઈન્ટરનેટ એ કંઈક શોધવા અને માહિતી શોધવાનો એક સારો વિકલ છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "To do research we can talk to people and read books and newspapers",
            "hi": "रिसर्च करने के लिए हम लोगों से बात कर सकते हैं और किताबें और अख़बार पढ़ सकते हैं",
            "ka": "ಸಂಶೋಧನೆ ಮಾಡಲು ನಾವು ಜನರೊಂದಿಗೆ ಮಾತನಾಡಬಹುದು ಮತ್ತು ಪುಸ್ತಕಗಳು ಮತ್ತು ಪತ್ರಿಕೆಗಳನ್ನು ಓದಬಹುದು",
            "te": "పరిశోధన చేయడానికి మనం ప్రజలతో మాట్లాడవచ్చు మరియు పుస్తకాలు మరియు వార్తాపత్రికలు చదవవచ్చు",
            "or": "ଅନୁସନ୍ଧାନ କରିବା ପାଇଁ ଆମେ ଲୋକଙ୍କ ସହ କଥା ହୋଇପାରିବା ଏବଂ ପୁସ୍ତକ ଏବଂ ଖବରକାଗଜ ପଢିପାରିବା ।",
            "as": "গৱেষণা কৰিবলৈ আমি মানুহৰ সৈতে কথা পাতিব পাৰোঁ আৰু কিতাপ আৰু বাতৰি কাকত পঢ়িব পাৰোঁ",
            "gu": "શોધખોળ કરવા માટે આપણે લોકો સાથે વાત કરી શકીએ છીએ અને પુસ્તકો અને સમાચાર પત્રો પણ વાંચી શકીએ છીએ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Research is required only when one needs to find a scholarship to study",
            "hi": "रिसर्च केवल तभी किया जाता है जब किसी को पढ़ाई के लिए स्कोलरशिप के बारे में जानकारी चाहिए होती है।",
            "ka": "ಅಧ್ಯಯನ ಮಾಡಲು ವಿದ್ಯಾರ್ಥಿವೇತನವನ್ನು ಹುಡುಕಬೇಕಾದಾಗ ಮಾತ್ರ ಸಂಶೋಧನೆಯ ಅಗತ್ಯವಿದೆ",
            "te": "చదువుకోవడానికి స్కాలర్‌షిప్‌ (ఉపకార వేతనం )ను కనుగొనవలసి వచ్చినప్పుడు మాత్రమే పరిశోధన అవసరం",
            "or": "ଅନୁସନ୍ଧାନ କେବଳ ସେତେବେଳେ ଆବଶ୍ୟକ ହୁଏ ଯେତେବେଳେ ଜଣେ ଅଧ୍ୟୟନ ପାଇଁ ଛାତ୍ରବୃତ୍ତି ଖୋଜିବା ଆବଶ୍ୟକ କରନ୍ତି ।",
            "as": "কেৱল স্কলাৰশ্বিপ বিচাৰিবলৈহে গৱেষণাৰ প্ৰয়োজন",
            "gu": "જ્યારે કોઈને અભ્યાસ માટે શિષ્યવૃત્તિ શોધવાની જરૂર હોય  ત્યારે સંશોધન કરવું જરૂરી છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Think again! To do research we can talk to people and read books and newspapers.",
            "hi": "फिर से विचार करें! रिसर्च करने के लिए हम लोगो के साथ बात कर सकते हैं और और किताबें और अख़बार पढ़ सकते हैं।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಸಂಶೋಧನೆ ಮಾಡಲು ನಾವು ಜನರೊಂದಿಗೆ ಮಾತನಾಡಬಹುದು ಮತ್ತು ಪುಸ್ತಕಗಳು ಮತ್ತು ಪತ್ರಿಕೆಗಳನ್ನು ಓದಬಹುದು.",
            "te": "మళ్లీ ఆలోచించండి! పరిశోధన చేయడానికి మనం ప్రజలతో మాట్లాడవచ్చు మరియు పుస్తకాలు మరియు వార్తాపత్రికలు చదవవచ్చు.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ଅନୁସନ୍ଧାନ କରିବା ପାଇଁ ଆମେ ଲୋକଙ୍କ ସହ କଥା ହୋଇପାରିବା ଏବଂ ପୁସ୍ତକ ଏବଂ ଖବରକାଗଜ ପଢିପାରିବା ।",
            "as": "আকৌ চিন্তা কৰা! গৱেষণা কৰিবলৈ আমি মানুহৰ সৈতে কথা পাতিব পাৰোঁ আৰু কিতাপ আৰু বাতৰি কাকত পঢ়িব পাৰোঁ।",
            "gu": " ફરીથી વિચારો!  સંશોધન કરવા માટે આપણે લોકો સાથે વાત કરી શકીએ છીએ અને પુસ્તકો અને સમાચાર પત્રો પણ વાંચી શકીએ છીએ."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "शाबाश!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "గొప్ప పని!",
            "or": "ବହୁତ ଭଲ!",
            "as": "ভাল কাম!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! Research can help us be aware of opportunities throughout our careers.",
            "hi": "फिर से विचार करें! रिसर्च हमें अपने करियर के अवसरों के बारे में जागरूक होने के लिए मदद करता है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ನಮ್ಮ ವೃತ್ತಿಜೀವನದುದ್ದಕ್ಕೂ ಅವಕಾಶಗಳ ಬಗ್ಗೆ ಅರಿವು ಮೂಡಿಸಲು ಸಂಶೋಧನೆ ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ",
            "te": "మళ్లీ ఆలోచించండి! మన కెరీర్‌లో అవకాశాల గురించి తెలుసుకోవడంలో పరిశోధన సహాయపడుతుంది.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ଗବେଷଣା ଆମକୁ ଆମର କ୍ୟାରିୟରରେ ସୁଯୋଗ ବିଷୟରେ ସଚେତନ ହେବାରେ ସାହାଯ୍ୟ କରିପାରିବ ।",
            "as": "আকৌ চিন্তা কৰা! গৱেষণাই আমাক আমাৰ সমগ্ৰ কেৰিয়াৰত সুযোগৰ বিষয়ে সজাগ হোৱাত সহায় কৰিব পাৰে।",
            "gu": " ફરીથી વિચારો! સંશોધન અમારી સમગ્ર કારકિર્દી દરમિયાન તકોથી જાણકર રહેવામાં મદદ કરી શકે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ4",
            "hi": "MCQ4",
            "ka": "MCQ4",
            "te": "MCQ4",
            "or": "MCQ4",
            "as": "MCQ4",
            "gu": "MCQ4"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia negotiates with her family to allow her to study in the city. Negotiation helps people with differing points of view to reach an agreement to finalise a decision. In your life, where do you think negotiation plays a role?",
            "hi": "रजिया शहर में पढ़ने के लिए जाने के लिए अपने माता-पिता के साथ निगोशिएट करती है। निगोशिएट करने से अलग विचारों के लोगों को किसी एक निर्णय पर सहमत होने में मदद होती है। आपकी जिंदगी में, निगोशिएट करना कहां काम आता है ऐसा आपको लगता हैं?",
            "ka": "ರಝಿಯಾ ತನ್ನ ಕುಟುಂಬದೊಂದಿಗೆ ನಗರದಲ್ಲಿ ಓದಲು ಅವಕಾಶ ನೀಡಲು ಮಾತುಕತೆ ನಡೆಸುತಾಳೆ. ನಿರ್ಧಾರವನ್ನು ಅಂತಿಮಗೊಳಿಸಲು ಒಪ್ಪಂದವನ್ನು ತಲುಪಲು ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳನ್ನು ಹೊಂದಿರುವ ಜನರಿಗೆ ಮಾತುಕತೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನಿಮ್ಮ ಜೀವನದಲ್ಲಿ, ಮಾತುಕತೆಯು ಎಲ್ಲಿ ಒಂದು ಪಾತ್ರವನ್ನು ವಹಿಸುತ್ತದೆ ಎಂದು ನೀವು ಭಾವಿಸುತ್ತೀರಿ?",
            "te": "రజియా నగరంలో చదువుకోవడానికి అనుమతించమని తన కుటుంబంతో చర్చలు జరిపింది .భిన్నమైన అభిప్రాయాలు ఉన్న వ్యక్తులు ఒక నిర్ణయాన్ని ఖరారు చేయడానికి ఒక ఒప్పందాన్ని చేరుకోవడానికి చర్చలు సహాయపడతాయి. మీ జీవితంలో, నెగోషియేషన్ ( చర్చ) ఎక్కడ పాత్ర పోషిస్తాయని మీరు అనుకుంటున్నారు?",
            "or": "ରଜିଆ ତାଙ୍କୁ ସହରରେ ଅଧ୍ୟୟନ କରିବାକୁ ଅନୁମତି ଦେବା ପାଇଁ ତାଙ୍କ ପରିବାର ସହିତ ବୁଝାମଣା କରନ୍ତି । ବୁଝାମଣା ଭିନ୍ନ ଦୃଷ୍ଟିକୋଣଥିବା ଲୋକଙ୍କୁ ଏକ ନିଷ୍ପତ୍ତି ଚୂଡ଼ାନ୍ତ କରିବା ପାଇଁ ଏକ ରାଜିନାମାରେ ପହଞ୍ଚିବାରେ ସାହାଯ୍ୟ କରେ । ଆପଣଙ୍କ ଜୀବନରେ, ବୁଝାମଣା କେଉଁଠାରେ ଏକ ଭୂମିକା ଗ୍ରହଣ କରେ ବୋଲି ଆପଣ ଭାବନ୍ତି?",
            "as": "ৰাজিয়াই তাইক চহৰখনত পঢ়িবলৈ অনুমতি দিবলৈ তাইৰ পৰিয়ালৰ সৈতে বুজাবুজি কৰে। বুজাবুজিয়ে ভিন্ন দৃষ্টিভংগী থকা লোকসকলক সিদ্ধান্ত এটা চূড়ান্ত কৰিবলৈ এক চুক্তিত উপনীত হোৱাত সহায় কৰে। তোমাৰ জীৱনত, তুমি ক'ত বুজাবুজিয়ে ভূমিকা পালন কৰে বুলি ভাবা?",
            "gu": "રઝિયા તેને શહેરમાં અભ્યાસ કરવાની પરવાનગી માટે તેના પરિવાર સાથે વાટાઘાટો કરે છે. વાટાઘાટો અલગ અલગ વિચારણા ધરાવતા લોકોને નિર્ણયને અંતિમ સ્વરૂપ આપવા માટે કરાર સુધી પહોંચવામાં મદદ કરે છે. તમારા જીવનમાં, તમે શું વિચારો છો કે વાટાઘાટો ભૂમિકા ભજવે છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "To change your significant others (parents, friends etc) beliefs about your career decisions",
            "hi": "आपके करियर के निर्णय को लेकर अपने खास लोगों के (माता-पिता, दोस्त आदि) विचार बदलने के लिए",
            "ka": "ನಿಮ್ಮ ವೃತ್ತಿ ನಿರ್ಧಾರಗಳ ಬಗ್ಗೆ ನಿಮ್ಮ ಪ್ರಮುಖ ಇತರರ (ಪೋಷಕರು, ಸ್ನೇಹಿತರು ಇತ್ಯಾದಿ) ನಂಬಿಕೆಗಳನ್ನು ಬದಲಾಯಿಸಲು",
            "te": "మీ కెరీర్ నిర్ణయాల గురించి మీ ముఖ్యమైన ఇతరుల (తల్లిదండ్రులు, స్నేహితులు మొదలైనవారు) నమ్మకాలను మార్చడానికి",
            "or": "ଆପଣଙ୍କ କ୍ୟାରିୟର ନିଷ୍ପତ୍ତି ବିଷୟରେ ଆପଣଙ୍କର ଆତ୍ମୀୟମାନଙ୍କ (ପିତାମାତା, ବନ୍ଧୁ ଇତ୍ୟାଦି) ବିଶ୍ୱାସ ପରିବର୍ତ୍ତନ କରିବାକୁ",
            "as": "তোমাৰ কেৰিয়াৰৰ সিদ্ধান্তৰ বিষয়ে তোমাৰ গুৰুত্বপূৰ্ণ আনসকলৰ (পিতৃ-মাতৃ, বন্ধু আদি) বিশ্বাস সলনি কৰিবলৈ",
            "gu": "તમારી કારકિર્દીના નિર્ણયો વિશે તમારા નોંધપાત્ર અન્ય (માતાપિતા, મિત્રો વગેરે)ની માન્યતાઓને બદલવા માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "To gain a better salary when joining a new workplace",
            "hi": "नई जॉब में बेहतर सैलरी प्राप्त करने के लिए",
            "ka": "ಹೊಸ ಕೆಲಸದ ಸ್ಥಳಕ್ಕೆ ಸೇರಿದಾಗ ಉತ್ತಮ ಸಂಬಳವನ್ನು ಪಡೆಯಲು",
            "te": "కొత్త కార్యాలయంలో చేరినప్పుడు మెరుగైన జీతం పొందేందుకు",
            "or": "ଏକ ନୂତନ କର୍ମକ୍ଷେତ୍ରରେ ଯୋଗଦେବା ସମୟରେ ଏକ ଉତ୍ତମ ଦରମା ପାଇବା ପାଇଁ",
            "as": "নতুন কৰ্মস্থানত যোগদান কৰোঁতে ভাল দৰমহা প্ৰাপ্ত কৰিবলৈ",
            "gu": "વધુ સારો પગાર મેળવવા માટે નવા કાર્યસ્થળે જોડાતી વખતે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "To get information about a career and the jobs within the career",
            "hi": "करियर के बारे में और करियर में उपलब्ध जॉब के बारे में जानकारी प्राप्त करने के लिए",
            "ka": "ವೃತ್ತಿ ಮತ್ತು ವೃತ್ತಿಯೊಳಗಿನ ಉದ್ಯೋಗಗಳ ಬಗ್ಗೆ ಮಾಹಿತಿ ಪಡೆಯಲು",
            "te": "కెరీర్ మరియు కెరీర్‌లోని ఉద్యోగాల గురించి సమాచారాన్ని పొందడానికి",
            "or": "ଏକ କ୍ୟାରିୟର ଏବଂ ସେହି କ୍ୟାରିୟର ମଧ୍ୟରେ ଚାକିରି ବିଷୟରେ ସୂଚନା ପାଇବା ପାଇଁ",
            "as": "এটা কেৰিয়াৰৰ বিষয়ে আৰু কেৰিয়াৰটোৰ ভিতৰত থকা চাকৰিৰ বিষয়ে তথ্য প্ৰাপ্ত কৰিবলৈ",
            "gu": "કારકિર્દી અને નોકરીમાં તેની અંદરની નોકરીઓ વિશે માહિતી મેળવવા માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "When making an important decision, negotiation is the process by which people who disagree on how things should be done come to an agreement that works for everyone involved.",
            "hi": "महत्वपूर्ण निर्णय लेते समय, निगोशिएट करना एक प्रक्रिया है जिसके जरिए जो लोग किसी काम को किस तरह से किया जाना चाहिए इस बात पर सहमत नहीं होते वे ऐसे किसी समझौते पर पहुँचते हैं जो इस प्रक्रिया में शामिल सबको मंजूर हो।",
            "ka": "ಪ್ರಮುಖ ನಿರ್ಧಾರವನ್ನು ಮಾಡುವಾಗ, ಸಮಾಲೋಚನೆಯು ಪ್ರಕ್ರಿಯೆಯಾಗಿದ್ದು, ವಿಷಯಗಳನ್ನು ಹೇಗೆ ಮಾಡಬೇಕು ಎಂಬುದರ ಕುರಿತು ಭಿನ್ನಾಭಿಪ್ರಾಯ ಹೊಂದಿರುವ ಜನರು ಒಳಗೊಂಡಿರುವ ಪ್ರತಿಯೊಬ್ಬರಿಗೂ ಕೆಲಸ ಮಾಡುವ ಒಪ್ಪಂದಕ್ಕೆ ಬರುತ್ತಾರೆ.",
            "te": "ఒక ముఖ్యమైన నిర్ణయం తీసుకునేటప్పుడు, నెగోషియేషన్ ( చర్చ) అంటే ఏదైనా ఎలా జరగాలి అనే దానిపై విభేదించే వ్యక్తులు పాల్గొన్న ప్రతి ఒక్కరికీ నచ్చే విధంగా ఒక ఒప్పందానికి వచ్చే ప్రక్రియ.",
            "or": "ଏକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ନିଷ୍ପତ୍ତି ନେବା ବେଳେ, ବୁଝାମଣା ହେଉଛି ସେହି ପ୍ରକ୍ରିୟା ଯାହା ଦ୍ୱାରା ସେ ବିଷୟରେ ଅସହମତ ଥିବା ଲୋକମାନେ ଜିନିଷଗୁଡିକ କିପରି କରାଯିବା ଉଚିତ୍ ଏକ ରାଜିନାମାରେ ଆସନ୍ତି ଯାହା ସମ୍ପୃକ୍ତ ସମସ୍ତଙ୍କ ପାଇଁ କାର୍ଯ୍ୟ କରେ ।",
            "as": "গুৰুত্বপূৰ্ণ সিদ্ধান্ত লওঁতে, বুজাবুজি হৈছে এনে এক প্ৰক্ৰিয়া যাৰ দ্বাৰা কামবোৰ কেনেদৰে কৰিব লাগে সেই বিষয়ে অসন্মত হোৱা লোকসকলে এক চুক্তিত উপনীত হয় যি জড়িত সকলোৰে বাবে কামত আহে।",
            "gu": "જ્યારે મહત્વપૂર્ણ વાતચીતથી નિર્ણય લેતી વખતે, વાટાઘાટો એ એવી પ્રક્રિયા છે કે જેના દ્વારા કેવી રીતે તે કરવું જોઈએ તે અંગે અસંમત થતાં  લોકો એક કરાર પર આવે છે જે જેમાં બધા હાજર હોય તે માટે કાર્ય કરે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job! Negotiation allows space for the people involved in a decision to see various choices possible and move forward in a manner that is equal for everyone involved.",
            "hi": "शाबाश! निगोशिएशन के कारण निर्णय लेने की प्रक्रिया में शामिल सभी लोगों को भिन्न संभाव्य विकल्पों के बारे में जानकारी मिलती हैं और वे इस दिशा में आगे बढ़ते जो सबके लिए समान हो।",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ! ಸಮಾಲೋಚನೆಯು ನಿರ್ಧಾರದಲ್ಲಿ ತೊಡಗಿರುವ ಜನರಿಗೆ ವಿವಿಧ ಆಯ್ಕೆಗಳನ್ನು ಸಾಧ್ಯವೆಂದು ನೋಡಲು ಮತ್ತು ಒಳಗೊಂಡಿರುವ ಎಲ್ಲರಿಗೂ ಸಮಾನವಾದ ರೀತಿಯಲ್ಲಿ ಮುಂದುವರಿಯಲು ಜಾಗವನ್ನು ನೀಡುತ್ತದೆ.",
            "te": "గొప్ప పని! నెగోషియేషన్ ( చర్చ) వివిధ ఎంపికలను సాధ్యమయ్యేలా చూడడానికి మరియు పాల్గొన్న ప్రతి ఒక్కరికీ సమానమైన పద్ధతిలో ముందుకు సాగడానికి నిర్ణయంలో పాల్గొన్న వ్యక్తులకు అనుమతిస్తుంది.",
            "or": "ବହୁତ ଭଲ! ବୁଝାମଣା ନିଷ୍ପତ୍ତିରେ ଜଡିତ ଲୋକଙ୍କ ପାଇଁ ବିଭିନ୍ନ ପସନ୍ଦକୁ ସମ୍ଭବ ଦେଖିବା ଏବଂ ଏହିପରି ଭାବରେ ଆଗକୁ ବଢିବା ସ୍ଥାନ ଅନୁମତି ଦେଇଥାଏ ଯାହା ଏଥିରେ ସମ୍ପୃକ୍ତ ସମସ୍ତଙ୍କ ପାଇଁ ସମାନ ।",
            "as": "ভাল কাম! বুজাবুজিয়ে সিদ্ধান্তত জড়িত লোকসকলৰ বাবে বিভিন্ন বিকল্প পথ বাছনি কৰিবলৈ আৰু জড়িত সকলোৰে বাবে সমান ধৰণে আগবাঢ়িবলৈ সহায় কৰে।",
            "gu": "સરસ કામ! વાટાઘાટો નિર્ણયમાં સામેલ લોકોને વિવિધ પસંદગીઓ શક્ય જોવા અને સામેલ દરેક વ્યક્તિ માટે સમાન હોય તેવી રીતે આગળ વધવાની જગ્યા આપે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! To get information we do research and networking. To reach an agreement when starting out with differing points of view, it’s important to negotiate.",
            "hi": "फिर से विचार करें! जानकारी प्राप्त करने के लिए हम रिसर्च और नेटवर्किंग करते हैं। जब हम भिन्न विचारों के साथ शुरुआत करते हैं और किसी निर्णय पर पहुंचना चाहते हैं तब निगोशिएट करना महत्वपूर्ण है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಮಾಹಿತಿಯನ್ನು ಪಡೆಯಲು ನಾವು ಸಂಶೋಧನೆ ಮತ್ತು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮಾಡುತ್ತೇವೆ. ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳೊಂದಿಗೆ ಪ್ರಾರಂಭಿಸುವಾಗ ಒಪ್ಪಂದವನ್ನು ತಲುಪಲು, ಮಾತುಕತೆ ನಡೆಸುವುದು ಮುಖ್ಯವಾಗಿದೆ.",
            "te": "మళ్లీ ఆలోచించు! సమాచారాన్ని పొందడానికి మేము పరిశోధన మరియు నెట్‌వర్కింగ్ చేస్తాము.భిన్నమైన అభిప్రాయాలు, ప్రారంభించేటప్పుడు ఒక ఒప్పందాన్ని చేరుకోవడానికి చర్చలు జరపడం ముఖ్యం.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ସୂଚନା ପାଇବା ପାଇଁ ଆମେ ଅନୁସନ୍ଧାନ ଏବଂ ନେଟୱାର୍କିଂ କରୁ । ଭିନ୍ନ ଭିନ୍ନ ଦୃଷ୍ଟିକୋଣରୁ ଆରମ୍ଭ କରିବା ସମୟରେ ଏକ ରାଜିନାମାରେ ପହଞ୍ଚିବା ପାଇଁ, ବୁଝାମଣା କରିବା ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ।",
            "as": "আকৌ চিন্তা কৰা! তথ্য লাভ কৰিবলৈ আমি গৱেষণা আৰু নেটৱৰ্কিং কৰোঁ। ভিন্ন দৃষ্টিভংগীৰে আৰম্ভ কৰোঁতে এক চুক্তিত উপনীত হ'বলৈ, বুজাবুজি কৰাটো জৰুৰী।",
            "gu": " ફરીથી વિચારો ! સંશોધન અને નેટવર્કિંગની મદદથી માહિતી મેળવીએ છીએ. જુદા જુદા વિચારોથી શરૂઆત કરતી વખતે સમજૂતી સુધી પહોંચવા માટે, વાટાઘાટો કરવી એ મહત્વ હોય છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Junior Environmental Consultant",
            "hi": "ज्युनियर एनवायरमेंटल सलाहकार",
            "ka": "**THREAD_END**_ಕಿರಿಯ ಪರಿಸರ ಸಲಹೆಗಾರ",
            "te": "**THREAD_END**_జూనియర్ పర్యావరణ సలహాదారు",
            "or": "**THREAD_END** _କନିଷ୍ଠ ପରିବେଶ ପରାମର୍ଶଦାତା",
            "as": "**থ্ৰেড_এণ্ড**_কনিষ্ঠ পৰিৱেশ পৰামৰ্শদাতা",
            "gu": "**THREAD_END**_જુનિયર પર્યાવરણ સલાહકાર"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations! \nYou have finished one journey!\n\nYou have made choices for Razia that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने रजिया के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँची है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकती है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n \n ನೀವು ರಝಿಯಾಗಾಗಿ ಮಾಡಿದ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ.ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు రజియా కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర దృశ్యాలను అన్వేషించాలనుకుంటున్నారా?\"",
            "or": "ଅଭିନନ୍ଦନ! \n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି! \n ଆପଣ ରଜିଆ ପାଇଁ ନିଷ୍ପତ୍ତିଗୁଡିକ ପସନ୍ଦ କରିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତିକୁ ଆଗେଇ ନେଇଛି । ଆପଣ କରୁଥିବା ପସନ୍ଦଗୁଡିକ ଉପରେ ଆଧାର କରି ବିଭିନ୍ନ ପ୍ରକାରର ଶେଷ ଅଛି । ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହାଁନ୍ତି କି?",
            "as": "অভিনন্দন!\n তুমি এটা যাত্ৰা সমাপ্ত কৰিলা!\n \n তুমি ৰাজিয়াৰ বাবে বাছনি কৰিছা যাৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত আধাৰিত কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ জানিব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક સફર પૂરી કરી છે!\n\nતમે રઝિયા માટે જે પસંદગીઓ કરી છે જેના કારણે આ સંભવિત અંત આવ્યો છે. તમે જે પસંદગી કરો છો તેના આધારે વિવિધ અંત છે. શું તમે બીજા દ્રશ્યો શોધવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ର ଉପରେ ନଜର ପକାନ୍ତୁ",
            "as": "মানচিত্ৰখন চোৱা",
            "gu": "ચાલો નકશા તરફ નજર નાખીએ"
          }
        }
      ]
    },
    "scene18": {
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "Upskilling_Reconsider career path",
            "hi": "करियर के बारे में फिर से सोचना",
            "ka": "ಉನ್ನತ ಕೌಶಲ್ಯ_ವೃತ್ತಿ ಮಾರ್ಗವನ್ನು ಮರುಪರಿಶೀಲಿಸಿ",
            "te": "నైపుణ్యం పెంచుకోవడం _ కెరీర్ మార్గాన్ని పునరాలోచించండి",
            "or": "ଅପସ୍କିଲିଂ_କ୍ୟାରିୟର ପଥ ପୁନର୍ବିଚାର କରନ୍ତୁ",
            "as": "আপস্কিলিং_বেলেগ কেৰিয়াৰৰ পথৰ বিষয়ে আকৌ ভাবা",
            "gu": "ઉચ્ચ કૌશલ્ય_કારકિર્દી રસ્તા પર પુનર્વિચાર કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained upskilling experience!",
            "hi": "आपने अभी-अभी अपस्किल करने का अनुभव प्राप्त किया है!",
            "ka": "ನೀವು ಕೇವಲ ಕೌಶಲ್ಯದ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే నైపుణ్యం పెంచుకునే అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ଅପସ୍କିଲିଂ ଅଭିଜ୍ଞତା ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ উত্থানৰ অভিজ্ঞতা লাভ কৰিছা!",
            "gu": "તે હમણાં જ ઉચ્ચ કૌશલ્યનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Upskilling",
            "hi": "अपस्किल करना",
            "ka": "ಉನ್ನತ ಕೌಶಲ್ಯ",
            "te": "నైపుణ్యం పెంచడం",
            "or": "ଅପସ୍କିଲିଂ",
            "as": "আপস্কিলিং",
            "gu": "ઉચ્ચ કૌશલ્ય"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर डेवलपमेंट कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "కెరీర్ అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ୟାରିୟର ବିକାଶ ଦକ୍ଷତା",
            "as": "কেৰিয়াৰ বিকাশৰ দক্ষতা",
            "gu": "કારકિર્દી વિકાસ કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "To continue working in the eco-resort, Razia has to upskill her public speaking skills or lose her job. Upskilling means keeping yourself updated about new developments in your field of work. It is learning new skills from time to time. It means that even after you finish studying in school or college, you continue to learn - either through short courses, certification, workshops. Upskilling can open more growth possibilities for you in your career. You can upskill either by doing an online course, attending training, or workshops.",
            "hi": "अपनी पढ़ाई जारी रखने के लिए, रजिया फायनांशियल सपोर्ट/ग्रांट्स के लिए अप्लाई कर सकती है. इन ग्रांट्स को स्कोलरशिप कहते हैं। ये उन स्टूडेंट्स को दी जाती हैं, जो हायर स्टडीज करना चाहते हैं लेकिन उनके पास इतने पैसे नहीं है। स्टूडेंट्स को स्कोलरशिप के तौर पर मिलनेवाले पैसे वापस करने की जरूरत नहीं होती। स्कोलरशिप के लिए अप्लाई करने के लिए, आपको पात्रता के अनुसार सर्च करना होगा। इसके कुछ मानदंड है कोर्स, मार्क्स, आयु मर्यादा, परिवार की कमाई, विशेष कौशल आदि। इस प्रक्रिया में अॅप्लिकेशन भरना, क्रेड़ेंशियल भरना और स्कोलरशिप के लिए सिलेक्ट होने से पहने इंटरव्यू देना शामिल हैं।",
            "ka": "ಪರಿಸರ-ರೆಸಾರ್ಟ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಮುಂದುವರಿಸಲು, ರಝಿಯಾ ತನ್ನ ಸಾರ್ವಜನಿಕ ಮಾತನಾಡುವ ಕೌಶಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸಿಕೊಳ್ಳಬೇಕು ಅಥವಾ ತನ್ನ ಕೆಲಸವನ್ನು ಕಳೆದುಕೊಳ್ಳಬೇಕು.ಅಪ್‌ಸ್ಕಿಲ್ಲಿಂಗ್ ಎಂದರೆ ನಿಮ್ಮ ಕೆಲಸದ ಕ್ಷೇತ್ರದಲ್ಲಿನ ಹೊಸ ಬೆಳವಣಿಗೆಗಳ ಬಗ್ಗೆ ನಿಮ್ಮನ್ನು ನವೀಕರಿಸಿಕೊಳ್ಳುವುದು. ಕಾಲಕಾಲಕ್ಕೆ ಹೊಸ ಕೌಶಲ್ಯಗಳನ್ನು ಕಲಿಯುವುದು ಎಂದರ್ಥ.ಇದರರ್ಥ ನೀವು ಶಾಲೆ ಅಥವಾ ಕಾಲೇಜಿನಲ್ಲಿ ಓದುವುದನ್ನು ಮುಗಿಸಿದ ನಂತರವೂ ನೀವು ಕಲಿಯುವುದನ್ನು ಮುಂದುವರಿಸುತ್ತೀರಿ - ಚಿಕ್ಕ ಕೋರ್ಸ್‌ಗಳು, ಪ್ರಮಾಣೀಕರಣ, ಕಾರ್ಯಾಗಾರಗಳ ಮೂಲಕ.ಉನ್ನತ ಕೌಶಲ್ಯವು ನಿಮ್ಮ ವೃತ್ತಿಜೀವನದಲ್ಲಿ ನಿಮಗೆ ಹೆಚ್ಚಿನ ಬೆಳವಣಿಗೆಯ ಸಾಧ್ಯತೆಗಳನ್ನು ತೆರೆಯುತ್ತದೆ. ಆನ್‌ಲೈನ್ ಕೋರ್ಸ್ ಮಾಡುವ ಮೂಲಕ, ತರಬೇತಿಗೆ ಹಾಜರಾಗುವ ಮೂಲಕ ಅಥವಾ ಕಾರ್ಯಾಗಾರಗಳ ಮೂಲಕ ನೀವು ಕೌಶಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸಬಹುದು.",
            "te": "ఎకో-రిసార్ట్‌లో పని చేయడం కొనసాగించడానికి, రజియా బహిరంగంగా మాట్లాడే నైపుణ్యాలను పెంచుకోవాల్సి ఉంది లేదా ఆమె ఉద్యోగాన్ని కోల్పోతుంది.నైపుణ్యలను పెంచుకోవడం అంటే మీ పని రంగంలో కొత్త పరిణామాల గురించి మిమ్మల్ని మీరు నవీకరణ లో ఉంచుకోవడం. ఎప్పటికప్పుడు కొత్త నైపుణ్యాలను నేర్చుకోవడం. అంటే మీరు పాఠశాలలో లేదా కళాశాలలో చదవడం పూర్తయిన తర్వాత కూడా, మీరు చిన్న కోర్సులు, సర్టిఫికేషన్, వర్క్‌షాప్‌ల ద్వారా నేర్చుకుంటూనే ఉంటారు.నైపుణ్యం పెంచుకోవడం, మీకు కెరీర్‌లో మరింత వృద్ధి అవకాశాలను మీకు అందిస్తుంది . మీరు ఆన్‌లైన్ కోర్సు చేయడం, శిక్షణకు హాజరు కావడం లేదా వర్క్‌షాప్‌లు చేయడం ద్వారా నైపుణ్యాన్ని పెంచుకోవచ్చు.",
            "or": "ଇକୋ-ରିସର୍ଟରେ କାମ ଜାରି ରଖିବାକୁ, ରଜିଆଙ୍କୁ ତାଙ୍କର ସାର୍ବଜନୀନ ଭାଷଣ କୌଶଳକୁ ବୃଦ୍ଧି କରିବାକୁ ପଡିବ କିମ୍ବା ଚାକିରି ହରାଇବାକୁ ପଡିବ । ଅପସ୍କିଲିଂର ଅର୍ଥ ହେଉଛି ତୁମର କାର୍ଯ୍ୟ କ୍ଷେତ୍ରରେ ନୂତନ ବିକାଶ ବିଷୟରେ ନିଜକୁ ଅପଡେଟ୍ ରଖିବା । ଏହା ହେଉଛି ସମୟ ସମୟରେ ନୂତନ କୌଶଳ ଶିଖିବା । ଏହାର ଅର୍ଥ ହେଉଛି ଯେ ତୁମେ ବିଦ୍ୟାଳୟ କିମ୍ବା କଲେଜରେ ଅଧ୍ୟୟନ ଶେଷ କରିବା ପରେ ମଧ୍ୟ, ଶିଖିବା ଜାରି ରଖିଛ - ହୁଏତ ସଂକ୍ଷିପ୍ତ ପାଠ୍ୟକ୍ରମ, ପ୍ରମାଣିକରଣ (ସାର୍ଟିଫିକେଶନ), କର୍ମଶାଳା ମାଧ୍ୟମରେ । ଅପସ୍କିଲିଂ ତୁମ କ୍ୟାରିୟରରେ ତୁମ ପାଇଁ ଅଧିକ ଅଭିବୃଦ୍ଧି ସମ୍ଭାବନା ଖୋଲିପାରେ । ତୁମେ ଏକ ଅନଲାଇନ୍ ପାଠ୍ୟକ୍ରମ କରି, ତାଲିମରେ ଯୋଗ ଦେଇ, କିମ୍ୱା କର୍ମଶାଳାଗୁଡିକ ଦ୍ଵାରା ଅପସ୍କିଲ୍ କରିପାରିବ।",
            "as": "ইক'-ৰিজৰ্টত কাম কৰি থাকিবলৈ, ৰাজিয়াই তাইৰ ৰাজহুৱাভাবে কথা কোৱাৰ দক্ষতা বৃদ্ধি কৰিব লাগিব নহলে চাকৰি হেৰুৱাব লাগিব। আপস্কিলিং মানে হৈছে তোমাৰ কামৰ ক্ষেত্ৰত নতুন বিকাশৰ বিষয়ে নিজকে আপডেট কৰি ৰখা। ই হৈছে সময়ে সময়ে নতুন দক্ষতা আহৰণ কৰি থকা ব্যৱস্থা । ইয়াৰ অৰ্থ হৈছে যে তুমি বিদ্যালয় বা কলেজত অধ্যয়ন সমাপ্ত কৰাৰ পিছতো চমু পাঠ্যক্ৰম বা কৰ্মশালাৰ জৰিয়তে শিক্ষা অব্যাহত ৰাখিব লাগিব। আপস্কিলিঙে তোমাৰ কেৰিয়াৰত তোমাৰ বাবে অধিক বিকাশৰ সম্ভাৱনা মুকলি কৰিব পাৰে। তুমি হয় অনলাইন পাঠ্যক্ৰম কৰি, প্ৰশিক্ষণত অংশগ্ৰহণ কৰি বা কৰ্মশালাত অংশগ্ৰহণ কৰি দক্ষতা বৃদ্ধি কৰিব পাৰা।",
            "gu": "ઇકો-રિસોર્ટમાં આગળ કામ કરવું હોય તો, રઝિયાને જાહેરમાં બોલવાની કુશળતામાં વધારવી પડશે અથવા તો નોકરી છોડવી પડશે. ઉચ્ચ કૌશલ્યનો અર્થ છે તમારા કાર્યક્ષેત્રમાં નવા વિકાસ વિશે તમારી જાતને બદલવું પડશે. તે સમયાંતરે નવી કુશળતા શીખી પડશે. તેનો અર્થ એ છે કે તમે શાળા અથવા કૉલેજમાં અભ્યાસ પૂર્ણ કર્યા પછી પણ, તમે ટૂંકા અભ્યાસક્રમો, પ્રમાણપત્રો, વર્કશોપ દ્વારા શીખવાનું ચાલુ રાખો છો. ઉચ્ચ કૌશલ્ય તમારા માટે તમારી કારકિર્દીમાં વૃદ્ધિની વધુ શક્યતાઓ બતાવે શકે છે. તમે ઓનલાઈન કોર્સ કરીને, તાલીમમાં અથવા વર્કશોપમાં હાજરી આપીને ઉચ્ચ કૌશલ્ય પણ કરી શકો છો."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_1_1",
            "hi": "D6_1_1",
            "ka": "D6_1_1",
            "te": "D6_1_1",
            "or": "D6_1_1",
            "as": "D6_1_1",
            "gu": "D6_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She gets the book on career growth and planning.",
            "hi": "वह करियर वृद्धि और प्लानिंग की किताब खरीदती है।",
            "ka": "ಅವಳು ವೃತ್ತಿಜೀವನದ ಬೆಳವಣಿಗೆ ಮತ್ತು ಯೋಜನೆ ಕುರಿತು ಪುಸ್ತಕವನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
            "te": "ఆమె కెరీర్ వృద్ధి మరియు ప్రణాళికపై అనే పుస్తకం కొన్నది",
            "or": "ସେ କ୍ୟାରିୟର ଅଭିବୃଦ୍ଧି ଏବଂ ଯୋଜନା ଉପରେ ପୁସ୍ତକ ପାଆନ୍ତି ।",
            "as": "তাই কেৰিয়াৰৰ বিকাশ আৰু পৰিকল্পনাৰ ওপৰত লিখা কিতাপখন লয়।",
            "gu": "તેને કારકિર્દીમાં પ્રગતિ અને આયોજન કરવા  પર પુસ્તક મળે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_1_2",
            "hi": "D6_1_2",
            "ka": "D6_1_2",
            "te": "D6_1_2",
            "or": "D6_1_2",
            "as": "D6_1_2",
            "gu": "D6_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She opens the book and finds a form that helps determine the right career path for oneself. She decides to fill the form.",
            "hi": "वह जब किताब खोलती हैं तब उसे एक फॉर्म मिलता है जो उसे सही करियर मार्ग चुनने में मदद करता है। वह इस फॉर्म को भरने का निर्णय लेती है।",
            "ka": "ಅವಳು ಪುಸ್ತಕವನ್ನು ತೆರೆಯುತ್ತಾಳೆ ಮತ್ತು ತನಗಾಗಿ ಸರಿಯಾದ ವೃತ್ತಿ ಮಾರ್ಗವನ್ನು ನಿರ್ಧರಿಸಲು ಸಹಾಯ ಮಾಡುವ ಫಾರ್ಮ್ ಅನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾಳೆ. ಅವಳು ಫಾರ್ಮ್ ಅನ್ನು ತುಂಬಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
            "te": "ఆమె పుస్తకాన్ని తెరిచి, తనకు తానుగా సరైన కెరీర్ మార్గాన్ని నిర్ణయించడంలో సహాయపడే ఫారమ్‌ను కనుగొంటుంది. ఆమె ఫారమ్ నింపాలని నిర్ణయించుకుంది.",
            "or": "ସେ ସେହି ପୁସ୍ତକ ଖୋଲି ଏକ ଫର୍ମ ପାଆନ୍ତି ଯାହା ନିଜ ପାଇଁ ସଠିକ୍ କ୍ୟାରିୟର ପଥ ନିର୍ଦ୍ଧାରଣ କରିବାରେ ସାହାଯ୍ୟ କରେ । ସେ ଫର୍ମ ପୂରଣ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି ।",
            "as": "তাই কিতাপখন খোলে আৰু এখন ফৰ্ম বিচাৰি পায় যিখনে সঠিক কেৰিয়াৰৰ পথ বাছি লোৱাত সহায় কৰে। তাই ফৰ্মখন পূৰণ কৰিম বুলি ভাবে।",
            "gu": "તે પુસ્તક ખોલે છે અને તેને એક ફોર્મ મળે છે જેમાં પોતાને માટે યોગ્ય કારકિર્દી રસ્તો નક્કી કરવામાં મદદ કરે છે. તે ફોર્મ ભરવાનું નક્કી કરે છે."
          }
        }
      ],
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_1_3",
            "hi": "D6_1_3",
            "ka": "D6_1_3",
            "te": "D6_1_3",
            "or": "D6_1_3",
            "as": "D6_1_3",
            "gu": "D6_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The form reads: Choose at least 2 career paths to compare. She writes...\n1. Environmental consultant and naturalist at the resort \n2. Field ecologist role at the NGO.",
            "hi": "फॉर्म में लिखा है: तुलना करने के लिए कम से कम दो करियर मार्ग चुनें। वह लिखती है...\n 1. एनवायरमेंटल सलाहकार या रिसोर्ट में नैचरलिस्ट \n 2. एनजीओ में फिल्ड इकोलॉजिस्ट का रोल।",
            "ka": "\"ಫಾರ್ಮ್ ಓದುತ್ತದೆ: ಹೋಲಿಸಲು ಕನಿಷ್ಠ 2 ವೃತ್ತಿ ಮಾರ್ಗಗಳನ್ನು ಆರಿಸಿ. ಅವಳು ಬರೆಯುತ್ತಾಳೆ...\n 1. ರೆಸಾರ್ಟ್‌ನಲ್ಲಿ ಪರಿಸರ ಸಲಹೆಗಾರ ಮತ್ತು ನೈಸರ್ಗಿಕವಾದಿ\n 2. ಎನ್‌ಜಿಒ ನಲ್ಲಿ ಕ್ಷೇತ್ರ ಪರಿಸರ ವಿಜ್ಞಾನಿ ಪಾತ್ರ.\"",
            "te": "\"ఫారమ్ ఇలా ఉంది: పోల్చడానికి కనీసం 2 కెరీర్ మార్గాలను ఎంచుకోండి. ఆమె ఇలా వ్రాస్తుంది...\n 1. రిసార్ట్‌లో పర్యావరణ సలహాదారు మరియు ప్రకృతి శాస్త్రవేత్త\n 2. NGOలో క్షేత్ర పర్యావరణ శాస్త్రవేత్త పాత్ర.\"",
            "or": "ଫର୍ମରେ ଲେଖାଯାଇଛି: ତୁଳନା କରିବାକୁ ଅତି କମରେ 2 ଟି କ୍ୟାରିୟର ପଥ ବାଛନ୍ତୁ । ସେ ଲେଖନ୍ତି... \n 1. ରିସର୍ଟରେ ପରିବେଶ ପରାମର୍ଶଦାତା ଏବଂ ପ୍ରକୃତିବାଦୀ \n 2. NGOରେ ଫିଲ୍ଡ ଇକୋଲୋଜିଷ୍ଟ ଭୂମିକା ।",
            "as": "ফৰ্মখনত এইদৰে লিখা হৈছে: তুলনা কৰিবলৈ কমেও 2 টা কেৰিয়াৰ পথ বাছনি কৰক। তাই লিখিছে...\n 1. ৰিজৰ্টত পৰিৱেশ পৰামৰ্শদাতা আৰু প্ৰকৃতিবিদ \n 2. NGOত ক্ষেত্ৰ পৰিস্থিতিবিদৰ পদ।",
            "gu": "ફોર્મ વાંચે છે: સરખામણી કરવા માટે ઓછામાં ઓછા 2 કારકિર્દી રસ્તા પસંદ કરો. તે લખે છે...\n1. રિસોર્ટમાં પર્યાવરણ સલાહકાર અને પ્રકૃતિવાદી\n2. NGOમાં ક્ષેત્ર ઇકોલોજિસ્ટની ભૂમિકા."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_1_4_revealBlock",
            "hi": "D6_1_4_ब्लॉक दिखाएं",
            "ka": "D6_1_4_ರಿವೀಲ್ ಬ್ಲಾಕ್",
            "te": "D6_1_4_రివీల్ బ్లాక్",
            "or": "D6_1_4_ପ୍ରକାଶ କରନ୍ତୁ ବ୍ଲକ",
            "as": "D6_1_4_উন্মোচন ব্লক",
            "gu": "D6_1_4_ ખાનું જાહેર કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "The question is - 'What knowledge is needed for both the roles?' \nShe writes...",
            "hi": "प्रश्न है – ‘इन दोनों रोल के लिए किस विषय का ज्ञान होना चाहिए?’ \n वह लिखती है...",
            "ka": "\"ಪ್ರಶ್ನೆ - 'ಎರಡೂ ಪಾತ್ರಗಳಿಗೆ ಏನು ಜ್ಞಾನ ಬೇಕು?'\n ಅವಳು ಬರೆಯುತ್ತಾಳೆ...\"",
            "te": "\"ప్రశ్న ఏమిటంటే - 'రెండు పాత్రలకు ఏ జ్ఞానం అవసరం?'\n ఆమె ఇలా రాసింది.",
            "or": "ପ୍ରଶ୍ନ ହେଉଛି - 'ଉଭୟ ଭୂମିକା ପାଇଁ କେଉଁ ଜ୍ଞାନ ଆବଶ୍ୟକ?' ସେ ଲେଖନ୍ତି...",
            "as": "প্ৰশ্নটো হ'ল- 'দুয়োটা পদৰ বাবে কি কি জ্ঞান লাগিব?' \n তাই লিখিছে...",
            "gu": "પ્રશ્ન એ છે કે - 'કયા જ્ઞાનની જરૂર છે બંને ભૂમિકાઓ માટે?'\nતે લખે છે ..."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Knowledge for Eco resort",
            "hi": "इको रिसोर्ट के लिए ज्ञान",
            "ka": "ಪರಿಸರ ರೆಸಾರ್ಟ್‌ಗಾಗಿ ಜ್ಞಾನ",
            "te": "పర్యావరణ రిసార్ట్ కోసం జ్ఞానం",
            "or": "ଇକୋ ରିସର୍ଟ ପାଇଁ ଜ୍ଞାନ",
            "as": "ইক' ৰিজৰ্টৰ বাবে জ্ঞান",
            "gu": "ઇકો રિસોર્ટ  માટે જ્ઞાન"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "Knowledge for Eco resort",
            "hi": "इको रिसोर्ट के लिए ज्ञान",
            "ka": "ಪರಿಸರ ರೆಸಾರ್ಟ್‌ಗಾಗಿ ಜ್ಞಾನ",
            "te": "పర్యావరణ రిసార్ట్ కోసం జ్ఞానం",
            "or": "ଇକୋ ରିସର୍ଟ ପାଇଁ ଜ୍ଞାନ",
            "as": "ইক' ৰিজৰ্টৰ বাবে জ্ঞান",
            "gu": "ઇકો રિસોર્ટ  માટે જ્ઞાન"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.beforeReveal",
          "value": {
            "en": "Knowledge for NGO",
            "hi": "एनजीओ के लिए ज्ञान",
            "ka": "ಎನ್‌ಜಿಒಗೆ ಜ್ಞಾನ",
            "te": "NGO కోసం జ్ఞానం",
            "or": "NGO ପାଇଁ ଜ୍ଞାନ",
            "as": "NGOৰ বাবে জ্ঞান",
            "gu": "NGO  માટે જ્ઞાન"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.beforeReveal",
          "value": {
            "en": "Knowledge for NGO",
            "hi": "एनजीओ के लिए ज्ञान",
            "ka": "ಎನ್‌ಜಿಒಗೆ ಜ್ಞಾನ",
            "te": "NGO కోసం జ్ఞానం",
            "or": "NGO ପାଇଁ ଜ୍ଞାନ",
            "as": "NGOৰ বাবে জ্ঞান",
            "gu": "NGO  માટે જ્ઞાન"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "A degree in Environmental science",
            "hi": "एनवायरमेंटल सायन्स में डिग्री",
            "ka": "ಪರಿಸರ ವಿಜ್ಞಾನದಲ್ಲಿ ಪದವಿ",
            "te": "పర్యావరణ శాస్త్రంలో డిగ్రీ",
            "or": "ପରିବେଶ ବିଜ୍ଞାନରେ ଡିଗ୍ରୀ",
            "as": "পৰিৱেশ বিজ্ঞানৰ এটা ডিগ্ৰী",
            "gu": "પર્યાવરણ વિજ્ઞાનમાં ડિગ્રી"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "Interest in the environment and ability to work outdoor",
            "hi": "पर्यावरण में दिलचस्पी और ऑफिस से बाहर काम करने की क्षमता",
            "ka": "ಪರಿಸರದಲ್ಲಿ ಆಸಕ್ತಿ ಮತ್ತು ಹೊರಾಂಗಣದಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಸಾಮರ್ಥ್ಯ",
            "te": "పర్యావరణ శాస్త్రం పై ఆసక్తి మరియు బయట పని చేయగల సామర్థ్యం",
            "or": "ପରିବେଶ ପ୍ରତି ଆଗ୍ରହ ଏବଂ ବାହ୍ୟ କାର୍ଯ୍ୟ କରିବାର କ୍ଷମତା",
            "as": "পৰিৱেশৰ প্ৰতি আগ্ৰহ আৰু বাহিৰত কাম কৰাৰ সামৰ্থ্য",
            "gu": "પર્યાવરણમાં રસ અને બહારના કામ કરવાની ક્ષમતા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.afterReveal",
          "value": {
            "en": "A degree in Environmental science",
            "hi": "एनवायरमेंटल सायन्स में डिग्री",
            "ka": "ಪರಿಸರ ವಿಜ್ಞಾನದಲ್ಲಿ ಪದವಿ",
            "te": "పర్యావరణ శాస్త్రం లో డిగ్రీ",
            "or": "ପରିବେଶ ବିଜ୍ଞାନରେ ଡିଗ୍ରୀ",
            "as": "পৰিৱেশ বিজ্ঞানৰ এটা ডিগ্ৰী",
            "gu": "પર્યાવરણ વિજ્ઞાનમાં ડિગ્રી"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.afterReveal",
          "value": {
            "en": "Knowledge of environment related rules and regulations",
            "hi": "पर्यावरण संबंधी नियम और विनियमों का ज्ञान",
            "ka": "ಪರಿಸರ ಸಂಬಂಧಿತ ನಿಯಮಗಳು ಮತ್ತು ನಿಬಂಧನೆಗಳ ಜ್ಞಾನ",
            "te": "పర్యావరణానికి సంబంధించిన నియమాలు మరియు నిబంధనలపై అవగాహన",
            "or": "ପରିବେଶ ସମ୍ବନ୍ଧୀୟ ନୀତି ଏବଂ ନିୟମର ଜ୍ଞାନ",
            "as": "পৰিৱেশ সম্পৰ্কীয় নিয়ম আৰু নিয়মাৱলীৰ জ্ঞান",
            "gu": "પર્યાવરણ સંબંધિત નીતિઓ અને નિયમોનું જ્ઞાન"
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_1_5",
            "hi": "D6_1_5",
            "ka": "D6_1_5",
            "te": "D6_1_5",
            "or": "D6_1_5",
            "as": "D6_1_5",
            "gu": "D6_1_5"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "The next question says -  \nWhat skills do you need to learn for the roles?",
            "hi": "अगला प्रश्न है - \n इस रोल के लिए आपको कौन से कौशल सीखने होंगे?",
            "ka": "\"ಮುಂದಿನ ಪ್ರಶ್ನೆ ಹೇಳುತ್ತದೆ -\n ಪಾತ್ರಗಳಿಗಾಗಿ ನೀವು ಯಾವ ಕೌಶಲ್ಯಗಳನ್ನು ಕಲಿಯಬೇಕು?\"",
            "te": "\"తదుపరి ప్రశ్న ఇలా చెప్పింది -\n పాత్రల కోసం మీరు ఏ నైపుణ్యాలను నేర్చుకోవాలి?\"",
            "or": "ପରବର୍ତ୍ତୀ ପ୍ରଶ୍ନ ରେ କୁହାଯାଇଛି – ଭୂମିକାଟି ପାଇଁ ଆପଣଙ୍କୁ କେଉଁ ଦକ୍ଷତା ଶିଖିବା ଆବଶ୍ୟକ?",
            "as": "পাছৰ প্ৰশ্নটোত কোৱা হৈছে - \n পদবোৰৰ বাবে তুমি কি দক্ষতা শিকিব লাগিব?",
            "gu": "આગળનો પ્રશ્ન એ કહે છે -\nકામગીરીઓ માટે તમારે કઈ કુશળતા શીખવાની જરૂર છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Skills for Eco resort",
            "hi": "इको रिसोर्ट के लिए कौशल",
            "ka": "ಪರಿಸರ ರೆಸಾರ್ಟ್ಗಾಗಿ ಕೌಶಲ್ಯಗಳು",
            "te": "పర్యావరణ రిసార్ట్ కోసం నైపుణ్యాలు",
            "or": "ଇକୋ ରିସର୍ଟ ପାଇଁ ଦକ୍ଷତା",
            "as": "ইক' ৰিজৰ্টৰ বাবে দক্ষতা",
            "gu": "ઇકો રિસોર્ટ માટે કુશળતા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "Skills for Eco resort",
            "hi": "इको रिसोर्ट के लिए कौशल",
            "ka": "ಪರಿಸರ ರೆಸಾರ್ಟ್ಗಾಗಿ ಕೌಶಲ್ಯಗಳು",
            "te": "పర్యావరణ రిసార్ట్ కోసం నైపుణ్యాలు",
            "or": "ଇକୋ ରିସର୍ଟ ପାଇଁ ଦକ୍ଷତା",
            "as": "ইক' ৰিজৰ্টৰ বাবে দক্ষতা",
            "gu": "ઇકો રિસોર્ટ માટે કુશળતા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.beforeReveal",
          "value": {
            "en": "Skills for NGO",
            "hi": "एनजीओ के लिए कौशल",
            "ka": "ಎನ್‌ಜಿಒ ಗಾಗಿ ಕೌಶಲ್ಯಗಳು",
            "te": "NGO కోసం నైపుణ్యాలు",
            "or": "NGO ପାଇଁ ଦକ୍ଷତା",
            "as": "NGOৰ বাবে দক্ষতা",
            "gu": "NGOઓ માટે કુશળતા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.beforeReveal",
          "value": {
            "en": "Skills for NGO",
            "hi": "एनजीओ के लिए कौशल",
            "ka": "ಎನ್‌ಜಿಒ ಗಾಗಿ ಕೌಶಲ್ಯಗಳು",
            "te": "NGO కోసం నైపుణ్యాలు",
            "or": "NGO ପାଇଁ ଦକ୍ଷତା",
            "as": "NGOৰ বাবে দক্ষতা",
            "gu": "NGOઓ માટે કુશળતા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "Interacting with new people",
            "hi": "नए लोगों के साथ बातचीत करना",
            "ka": "ಹೊಸ ಜನರೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸುವುದು",
            "te": "కొత్త వ్యక్తులతో పరస్పరంగా అవ్వడం",
            "or": "ନୂତନ ଲୋକଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବା",
            "as": "নতুন মানুহৰ সৈতে কথা পতা",
            "gu": "નવા લોકો સાથે વાતચીત થાય"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "Problem solving",
            "hi": "समस्या का समाधान ढूँढना",
            "ka": "ಸಮಸ್ಯೆ ಪರಿಹರಿಸುವ",
            "te": "సమస్య పరిష్కారం",
            "or": "ସମସ୍ୟାର ସମାଧାନ",
            "as": "সমস্যা সমাধান",
            "gu": "સમસ્યા કેવી રીતે ઉકેલવાની"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.afterReveal",
          "value": {
            "en": "Writing and documenting skills",
            "hi": "लिखना और दस्तावेज बनाने का कौशल",
            "ka": "ಬರೆಯುವ ಮತ್ತು ದಾಖಲಿಸುವ ಕೌಶಲ್ಯ",
            "te": "రాయడం మరియు డాక్యుమెంట్ చేయడంలో నైపుణ్యాలు",
            "or": "ଲେଖିବା ଏବଂ ଡକ୍ୟୁମେଣ୍ଟ କରିବା ଦକ୍ଷତା",
            "as": "লিখা আৰু নথিভুক্ত কৰাৰ দক্ষতা",
            "gu": "લખવાનું અને દસ્તાવેજીકરણની આવડત"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.afterReveal",
          "value": {
            "en": "Working independently",
            "hi": "स्वतंत्र रूप से काम करना",
            "ka": "ಸ್ವತಂತ್ರವಾಗಿ ಕೆಲಸ ಮಾಡುವುದು",
            "te": "స్వతంత్రంగా పని చేయడం",
            "or": "ସ୍ୱାଧୀନ ଭାବରେ କାର୍ଯ୍ୟ କରିବା",
            "as": "স্বাধীনভাৱে কাম কৰা",
            "gu": "સ્વતંત્ર રીતે કામ કરવાનું"
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_1_6(D)",
            "hi": "D6_1_6(D)",
            "ka": "D6_1_6(D)",
            "te": "D6_1_6(D)",
            "or": "D6_1_6(D)",
            "as": "D6_1_6(D)",
            "gu": "D6_1_6(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Next is for her to select what skills from the above match her aspirations? \n\nHelp Razia decide her top aspiration:",
            "hi": "इसके आगे उसे चुनना है कि उपर दिए हुए कौशल में से कौन से कौशल उसकी महत्वाकांक्षाओं से मेल खाते है? \n \n रजिया को अपने सबसे श्रेष्ठ महत्वाकांक्षा तय करने में मदद कीजिए:",
            "ka": "\"ಮುಂದೆ ಅವಳು ತನ್ನ ಆಕಾಂಕ್ಷೆಗಳಿಗೆ ಹೊಂದಿಕೆಯಾಗುವ ಮೇಲಿನ ಯಾವ ಕೌಶಲ್ಯಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿಕೊಳ್ಳಬೇಕು?\n \n ರಝಿಯಾ ತನ್ನ ಉನ್ನತ ಆಕಾಂಕ್ಷೆಯನ್ನು ನಿರ್ಧರಿಸಲು ಸಹಾಯ ಮಾಡಿ:\"",
            "te": "తర్వాత ఆమె తన ఆకాంక్షలకు సరిపోయే పై వాటిలోని ఏ నైపుణ్యాలను ఎంచుకోవాలి?\n \n రజియా తన ప్రధాన ఆకాంక్షను నిర్ణయించడంలో సహాయపడండి:",
            "or": "ଟଙ୍କା ପାଇଁ ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପ ହେଉଛି ଉପରୋକ୍ତ ମଧ୍ୟରୁ କେଉଁ ଦକ୍ଷତା ତାଙ୍କ ଆକାଂକ୍ଷା ସହିତ ମେଳ ଖାଉଛି ତାହା ଚୟନ କରିବା? \n ରଜିଆଙ୍କୁ ତାଙ୍କର ଶୀର୍ଷ ଆକାଂକ୍ଷା ସ୍ଥିର କରିବାରେ ସାହାଯ୍ୟ କରନ୍ତୁ:",
            "as": "ইয়াৰ পিছত ওপৰৰ ভাগ বোৰৰ পৰা তাই বাছনি কৰিব যে তাইৰ ইচ্ছাৰ সৈতে কি দক্ষতা মিলে? \n ৰাজিয়াক তাইৰ শীৰ্ষ আকাংক্ষা নিৰ্ণয় কৰাত সহায় কৰা:",
            "gu": "આગળ તેને એ પસંદ કરવાનું છે કે ઉપરમાંથી કઈ આવડત તેની આકાંક્ષાઓ સાથે મેળ ખાય છે?\n\nરઝિયાને તેની ઉચ્ચ આકાંક્ષા નક્કી કરવામાં મદદ કરો:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Interacting with new people",
            "hi": "नए लोगों के साथ बातचीत करना",
            "ka": "ಹೊಸ ಜನರೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸುವುದು",
            "te": "కొత్త వ్యక్తులతో పరస్పరంగా అవ్వడం",
            "or": "ନୂତନ ଲୋକଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବା",
            "as": "নতুন মানুহৰ সৈতে কথা পতা",
            "gu": "નવા લોકો સાથે વાર્તાલાપ કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Working independently",
            "hi": "स्वत्रंत रूप से काम करना",
            "ka": "ಸ್ವತಂತ್ರವಾಗಿ ಕೆಲಸ ಮಾಡುವುದು",
            "te": "స్వతంత్రంగా పని చేయడం",
            "or": "ସ୍ୱାଧୀନ ଭାବରେ କାର୍ଯ୍ୟ କରିବା",
            "as": "স্বাধীনভাৱে কাম কৰা",
            "gu": "સ્વતંત્ર રીતે કામ કરવાનું"
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_1_7_Interacting with new people",
            "hi": "नए लोगों के साथ बातचीत करना",
            "ka": "D6_1_7_ಹೊಸ ಜನರೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸುವುದು",
            "te": "D6_1_7_కొత్త వ్యక్తులతో పరస్పరంగా అవ్వడం",
            "or": "D6_1_7_ନୂତନ ଲୋକଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବା",
            "as": "D6_1_7_নতুন মানুহৰ সৈতে কথা পতা",
            "gu": "D6_1_7_નવા લોકો સાથે વાર્તાલાપ કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "The last question is - \n'Summarise who you are and what is your passion?'",
            "hi": "आखरी प्रश्न है - \n 'आप कौन है और आपको किस बात का जूनून है यह संक्षेप में बताएं?’",
            "ka": "\"ಕೊನೆಯ ಪ್ರಶ್ನೆ -\n \"ಸಾರಾಂಶಗೊಳಿಸಿ ನೀವು ಯಾರು ಮತ್ತು ನಿಮ್ಮ ಉತ್ಸಾಹ ಏನು?\"",
            "te": "చివరి ప్రశ్న -\n 'మీరు ఎవరో మరియు మీ అభిరుచి ఏమిటో సారాంశం చెప్పండి?",
            "or": "ଶେଷ ପ୍ରଶ୍ନ ହେଉଛି – \n 'ସଂକ୍ଷେପରେ କୁହ ଯେ ତୁମେ କିଏ ଏବଂ ତୁମର ଅଭିଳାଷା କ'ଣ?'",
            "as": "শেষৰ প্ৰশ্নটো হ'ল - \n 'তুমি কোন আৰু তোমাৰ কিহত ৰাপ আছে ?'",
            "gu": "છેલ્લો પ્રશ્ન એ છે -\n'ટૂંકમાં કહો કે તમે કોણ છો અને તમને શેમાં શોખ છે?'"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Based on her aspirations, Razia summarises -",
            "hi": "अपनी महत्वाकांक्षा के आधार पर रजिया ने उत्तर दिया -",
            "ka": "ತನ್ನ ಆಕಾಂಕ್ಷೆಗಳ ಆಧಾರದ ಮೇಲೆ, ರಝಿಯಾ ಸಂಕ್ಷಿಪ್ತವಾಗಿ ಹೇಳುತ್ತಾಳೆ -",
            "te": "తన ఆకాంక్షల ఆధారంగా, రజియా క్లుప్తంగా చెప్పింది -",
            "or": "ତାଙ୍କ ଆକାଂକ୍ଷା ଉପରେ ଆଧାର କରି ରଜିଆ ସଂକ୍ଷେପରେ କୁହନ୍ତି -",
            "as": "নিজৰ ইচ্ছাৰ ওপৰত আধাৰিত কৰি, ৰাজিয়াই সাৰাংশ দিয়ে -",
            "gu": "તે એની આકાંક્ષાઓના આધારથી, રઝિયા ટૂંકમાં કહે છે-"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "I like interacting with people. It has been my dream to create awareness about protecting the Sunderabans. I also wish to take care of my family.",
            "hi": "मुझे लोगों के साथ बातचीत करना अच्छा लगता है। सुंदरबन के संरक्षण के बारे में लोगों को जागरूक करना हमेशा से मेरा सपना रहा है। और साथ ही मैं मेरे परिवार की देखभाल भी करना चाहती हूँ।",
            "ka": "ನಾನು ಜನರೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸಲು ಇಷ್ಟಪಡುತ್ತೇನೆ. ಸುಂದರಬನರನ್ನು ರಕ್ಷಿಸುವ ಬಗ್ಗೆ ಜಾಗೃತಿ ಮೂಡಿಸುವುದು ನನ್ನ ಕನಸಾಗಿದೆ. ನನ್ನ ಕುಟುಂಬವನ್ನು ನೋಡಿಕೊಳ್ಳಲು ನಾನು ಸಹ ಬಯಸುತ್ತೇನೆ.",
            "te": "వ్యక్తులతో సంభాషించడం నాకు ఇష్టం. సుందరబన్‌లను రక్షించడం గురించి అవగాహన కల్పించడం నా కల. నేను కూడా నా కుటుంబాన్ని జాగ్రత్తగా చూసుకోవాలనుకుంటున్నాను.",
            "or": "ମୁଁ ଲୋକଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବାକୁ ପସନ୍ଦ କରେ । ସୁନ୍ଦରବନର ସୁରକ୍ଷା ବିଷୟରେ ସଚେତନତା ସୃଷ୍ଟି କରିବା ମୋର ସ୍ୱପ୍ନ । ମୁଁ ମଧ୍ୟ ମୋ ପରିବାରର ଯତ୍ନ ନେବାକୁ ଚାହୁଁଛି ।",
            "as": "মই মানুহৰ সৈতে কথা পাতি ভাল পাওঁ। সুন্দৰবনৰ সুৰক্ষাৰ বিষয়ে সজাগতা সৃষ্টি কৰাটো মোৰ এটা সপোন । মই মোৰ পৰিয়ালৰ যত্ন লবলৈও বিচাৰো।",
            "gu": "લોકો સાથે વાતચીત કરવી મને ગમે છે. સુંદરવનની રક્ષા માટે જાગૃતિ લાવવાનું મારું સપનું છે. અને હું પણ મારા કુટુંબનું ધ્યાન રાખવા ઈચ્છું છું."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "D6_1_8_Working independently",
            "hi": "स्वत्रंत रूप से काम करना",
            "ka": "D6_1_8_ಸ್ವತಂತ್ರವಾಗಿ ಕೆಲಸ ಮಾಡುವುದು",
            "te": "D6_1_8_స్వతంత్రంగా పని చేయడం",
            "or": "D6_1_8_ସ୍ୱାଧୀନ ଭାବରେ କାର୍ଯ୍ୟ କରୁଛନ୍ତି",
            "as": "D6_1_8_ স্বাধীনভাৱে কাম কৰা",
            "gu": "D6_1_8_સ્વતંત્ર રીતે કામ કરવાનું"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "The last question is - \n'Summarise who you are and what is your passion?'",
            "hi": "आखरी प्रश्न है - \n 'आप कौन है और आपको किस बात का जूनून है यह संक्षेप में बताएं?’",
            "ka": "\"ಕೊನೆಯ ಪ್ರಶ್ನೆ -\n \"ಸಾರಾಂಶಗೊಳಿಸಿ ನೀವು ಯಾರು ಮತ್ತು ನಿಮ್ಮ ಉತ್ಸಾಹ ಏನು?\"",
            "te": "చివరి ప్రశ్న -\n 'మీరు ఎవరో మరియు మీ అభిరుచి ఏమిటో సారాంశం చెప్పండి?",
            "or": "ଶେଷ ପ୍ରଶ୍ନ ହେଉଛି – \n 'ସଂକ୍ଷେପରେ ନିଜ ବିଷୟରେ କୁହ ଏବଂ ତୁମର ଅଭିଳାଷା କ'ଣ?'",
            "as": "শেষৰ প্ৰশ্নটো হ'ল - \n 'তুমি কোন আৰু তোমাৰ কিহত ৰাপ আছে?'",
            "gu": "છેલ્લો પ્રશ્ન એ છે -\n'ટૂંકમાં કહો કે તમે કોણ છો અને તમને શેમાં શોખ છે?'"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Based on her aspirations, Razia summarises -",
            "hi": "अपनी महत्वाकांक्षा के आधार पर रजिया ने उत्तर दिया -",
            "ka": "ತನ್ನ ಆಕಾಂಕ್ಷೆಗಳ ಆಧಾರದ ಮೇಲೆ, ರಝಿಯಾ ಸಂಕ್ಷಿಪ್ತವಾಗಿ ಹೇಳುತ್ತಾಳೆ -",
            "te": "తన ఆకాంక్షల ఆధారంగా, రజియా క్లుప్తంగా చెప్పింది -",
            "or": "ତାଙ୍କ ଆକାଂକ୍ଷା ଉପରେ ଆଧାର କରି ରଜିଆ ସଂକ୍ଷେପରେ କୁହନ୍ତି -",
            "as": "নিজৰ আকাংক্ষাৰ ওপৰত আধাৰিত কৰি, ৰাজিয়াই সাৰাংশ দিয়ে -",
            "gu": "તે એની આકાંક્ષાઓના આધારથી, રઝિયા ટૂંકમાં કહે છે-"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "I like working independently and wish to work on the environment through research. I also wish to take care of my family.",
            "hi": "मुझे स्वतंत्र रूप से काम करना अच्छा लगता है और मैं पर्यावरण पर रिसर्च करना चाहती हूँ। और साथ ही मैं मेरे परिवार की देखभाल भी करना चाहती हूँ।",
            "ka": "ನಾನು ಸ್ವತಂತ್ರವಾಗಿ ಕೆಲಸ ಮಾಡಲು ಇಷ್ಟಪಡುತ್ತೇನೆ ಮತ್ತು ಸಂಶೋಧನೆಯ ಮೂಲಕ ಪರಿಸರದ ಮೇಲೆ ಕೆಲಸ ಮಾಡಲು ಬಯಸುತ್ತೇನೆ. ನನ್ನ ಕುಟುಂಬವನ್ನು ನೋಡಿಕೊಳ್ಳಲು ನಾನು ಸಹ ಬಯಸುತ್ತೇನೆ.",
            "te": "నేను స్వతంత్రంగా పనిచేయడం ఇష్టం మరియు పరిశోధన ద్వారా పర్యావరణంపై పని చేయాలనుకుంటున్నాను. నేను కూడా నా కుటుంబాన్ని జాగ్రత్తగా చూసుకోవాలనుకుంటున్నాను.",
            "or": "ମୁଁ ସ୍ୱାଧୀନ ଭାବରେ କାମ କରିବାକୁ ପସନ୍ଦ କରେ ଏବଂ ଗବେଷଣା ମାଧ୍ୟମରେ ପରିବେଶ ଉପରେ କାମ କରିବାକୁ ଚାହୁଁଛି । ମୁଁ ମଧ୍ୟ ମୋ ପରିବାରର ଯତ୍ନ ନେବାକୁ ଚାହୁଁଛି ।",
            "as": "মই স্বাধীনভাৱে কাম কৰিবলৈ ভাল পাওঁ আৰু গৱেষণাৰ জৰিয়তে পৰিৱেশৰ ওপৰত কাম কৰিব বিচাৰোঁ। মই মোৰ পৰিয়ালৰ যত্ন লবলৈও বিচাৰোঁ।",
            "gu": "હું મારી જાતે અને સંશોધન દ્વારા પર્યાવરણ પર કામ કરવાનું પસંદ કરું છું. અને હું પણ મારા પરિવારનું ધ્યાન રાખવા ઈચ્છું છું."
          }
        }
      ]
    },
    "scene19": {
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "Understanding self",
            "hi": "खुद को समझना",
            "ka": "ಸ್ವಯಂ ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು",
            "te": "తనను తాను అర్థం చేసుకోవడం",
            "or": "ନିଜକୁ ବୁଝିବା",
            "as": "নিজকে বুজা",
            "gu": "પોતાની જાતને સમજવું"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Understanding Self experience!",
            "hi": "आपने अभी-अभी खुद को समझने के बारे में ज्ञान प्राप्त किया है!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಸ್ವಯಂ ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವ ಬಗ್ಗೆ ಅನುಭವಗಳಿಸಿದ್ದೀರಿ",
            "te": "మీరు ఇప్పుడే మిమల్ని మీరు అర్థం చేసుకునే అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ଆତ୍ମ ଅଭିଜ୍ଞତାକୁ ବୁଝିବା ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ নিজকে বুজাৰ অভিজ্ঞতা লাভ কৰিছা!",
            "gu": "તમે હમણાં જ પોતાની જાતને સમજવાનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Understanding Self",
            "hi": "खुद को समझना",
            "ka": "ಸ್ವಯಂ ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು",
            "te": "తనను తాను అర్థం చేసుకోవడం",
            "or": "ନିଜକୁ ବୁଝିବା",
            "as": "নিজকে বুজা",
            "gu": "પોતાની જાતને સમજવું"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Self Development",
            "hi": "स्वयं विकास",
            "ka": "ಸ್ವಯಂ ಅಭಿವೃದ್ಧಿ",
            "te": "స్వయం అభివృద్ధి",
            "or": "ନିଜର ବିକାଶ",
            "as": "আত্ম বিকাশ",
            "gu": "આત્મવિકાસ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Razia uses the career planning tool to understand herself and apply for the right job. When thinking about college courses and career paths, an important aspect is to know about yourself. That is learning about your capabilities, your interests, what you are good at and your values. Before researching career options, it is helpful to spend time understanding yourself. Doing that can help you to plan and make career decisions that are right for you.",
            "hi": "रजिया खुद को समझने के लिए करियर प्लानिंग टूल का इस्तेमाल करती है और सही जॉब के लिए अप्लाई करती है। कॉलेज के कोर्सेस और करियर के मार्गों के बारे में सोचते हुए सबसे महत्वपूर्ण पहलू है खुद को समझना। इसका मतलब है कि अपनी क्षमता, अपनी दिलचस्पी, आप किस चीज में अच्छे हैं और आपके जीवन मूल्यों के बारे में जानना। करियर ऑप्शन्स के बारे में रिसर्च करने से पहले, खुद को समझने के लिए समय बिताना मददगार साबित होता है। ऐसा करने से आपको प्लान बनाने में और अपने लिए सही करियर चुनने में मदद होती है।",
            "ka": "ರಝಿಯಾ ತನ್ನನ್ನು ತಾನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಮತ್ತು ಸರಿಯಾದ ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ವೃತ್ತಿ ಯೋಜನೆ ಸಾಧನವನ್ನು ಬಳಸುತ್ತಾಳೆ. ಕಾಲೇಜು ಕೋರ್ಸ್‌ಗಳು ಮತ್ತು ವೃತ್ತಿ ಮಾರ್ಗಗಳ ಬಗ್ಗೆ ಯೋಚಿಸುವಾಗ, ನಿಮ್ಮ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳುವುದು ಒಂದು ಪ್ರಮುಖ ಅಂಶವಾಗಿದೆ.ಅದು ನಿಮ್ಮ ಸಾಮರ್ಥ್ಯಗಳು, ನಿಮ್ಮ ಆಸಕ್ತಿಗಳು, ನೀವು ಯಾವುದರಲ್ಲಿ ಉತ್ತಮರು ಮತ್ತು ನಿಮ್ಮ ಮೌಲ್ಯಗಳ ಬಗ್ಗೆ ಕಲಿಯುವುದು. ವೃತ್ತಿಯ ಆಯ್ಕೆಗಳನ್ನು ಸಂಶೋಧಿಸುವ ಮೊದಲು, ನಿಮ್ಮನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಸಮಯವನ್ನು ಕಳೆಯಲು ಸಹಾಯವಾಗುತ್ತದೆ.ಹಾಗೆ ಮಾಡುವುದರಿಂದ ನಿಮಗೆ ಸೂಕ್ತವಾದ ವೃತ್ತಿ ನಿರ್ಧಾರಗಳನ್ನು ಯೋಜಿಸಲು ಮತ್ತು ಮಾಡಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡಬಹುದು.",
            "te": "రజియా తనను తాను అర్థం చేసుకోవడానికి మరియు సరైన ఉద్యోగం కోసం దరఖాస్తు చేసుకోవడానికి కెరీర్ ప్రణాళిక సాధనాన్ని ఉపయోగిస్తుంది. కళాశాల కోర్సులు మరియు కెరీర్ మార్గాల గురించి ఆలోచిస్తున్నప్పుడు, మీ గురించి తెలుసుకోవడం ఒక ముఖ్యమైన అంశం. అంటే మీ సామర్థ్యాలు, మీ ఆసక్తులు, మీరు దేనిలో మంచివారు మరియు మీ విలువల గురించి నేర్చుకోవడం. కెరీర్ ఎంపికలను పరిశోధించే ముందు, మిమ్మల్ని మీరు అర్థం చేసుకోవడానికి సమయాన్ని వెచ్చించడం ఉపయోగకరంగా ఉంటుంది. అలా చేయడం వల్ల మీకు సరైన కెరీర్ నిర్ణయాలను ప్రణాళిక చేసుకోవడానికి మరియు తీసుకోవడానికి మీకు సహాయపడుతుంది.",
            "or": "ରଜିଆ ନିଜକୁ ବୁଝିବା ଏବଂ ସଠିକ୍ ଚାକିରି ପାଇଁ ଆବେଦନ କରିବା ପାଇଁ କ୍ୟାରିୟର ଯୋଜନା ଉପକରଣ ବ୍ୟବହାର କରନ୍ତି । କଲେଜ ପାଠ୍ୟକ୍ରମ ଏବଂ କ୍ୟାରିୟର ପଥ ବିଷୟରେ ଚିନ୍ତା କରିବା ସମୟରେ, ଏକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ଦିଗ ହେଉଛି ନିଜ ବିଷୟରେ ଜାଣିବା । ତାହା ହେଉଛି ତୁମର ସାମର୍ଥ୍ୟ, ତୁମର ସ୍ୱାର୍ଥ, ତୁମେ କେଉଁଥିରେ ଭଲ ଏବଂ ତୁମର ମୂଲ୍ୟବୋଧ ବିଷୟରେ ଶିଖିବା । କ୍ୟାରିୟର ବିକଳ୍ପ ଉପରେ ଅନୁସନ୍ଧାନ କରିବା ପୂର୍ବରୁ, ନିଜକୁ ବୁଝିବାରେ ସମୟ ବିତାଇବା ସହାୟକ ହୋଇଥାଏ । ଏହା କରିବା ତୁମକୁ ଯୋଜନା କରିବାରେ ଏବଂ କ୍ୟାରିୟର ନିଷ୍ପତ୍ତି ନେବାରେ ସାହାଯ୍ୟ କରିପାରିବ ଯାହା ତୁମ ପାଇଁ ଠିକ୍ ଅଟେ ।",
            "as": "ৰাজিয়াই নিজকে বুজিবলৈ আৰু সঠিক কামৰ বাবে এপ্লাই কৰিবলৈ কেৰিয়াৰ পৰিকল্পনাৰ সঁজুলি ব্যৱহাৰ কৰে। কলেজৰ পাঠ্যক্ৰম আৰু কেৰিয়াৰৰ পথৰ বিষয়ে চিন্তা কৰোঁতে, এটা গুৰুত্বপূৰ্ণ দিশ হৈছে নিজৰ বিষয়ে জনা। সেয়া হৈছে নিজস্ব সামৰ্থ্য, আগ্ৰহ, কিহত ভাল আৰু নিজস্ব মূল্যবোধৰ বিষয়ে শিকা। কেৰিয়াৰৰ বিকল্পবোৰ আলোচনা কৰাৰ আগতে, নিজকে বুজাত সময় অতিবাহিত কৰাটো প্ৰয়োজনীয়। এনে কৰিলে ই তোমাক সঠিক পৰিকল্পনাৰে কেৰিয়াৰৰ বাছনি কৰাত সহায় কৰিব পাৰে।",
            "gu": "રઝિયા પોતાની જાતને સમજવા અને યોગ્ય નોકરીમાં અરજી કરવા માટે કારકિર્દી આયોજનના  સાધનનો ઉપયોગ કરે છે. કોલેજના અભ્યાસક્રમો અને કારકિર્દીના માર્ગો વિશે વિચારતી વખતે, અને તમારા વિશે જાણવું એ એક મહત્વપૂર્ણ છે. તે તમારી આવડતો, તમારી રુચિઓ, તમે શેમાં સારા છો અને તમારા મૂલ્યો વિશે શીખવા વિશે છે. કારકિર્દીના વિકલ્પો શોધતા પહેલા, તમારે તમારી જાતને સમજવામાં થોડો સમય પસાર કરવો જોઈએ. આ તમને તમારા માટે યોગ્ય કારકિર્દીના નિર્ણયો  લેવામાં મદદ કરી શકે છે."
          }
        }
      ],
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_1_1",
            "hi": "D7_1_1",
            "ka": "D7_1_1",
            "te": "D7_1_1",
            "or": "D7_1_1",
            "as": "D7_1_1",
            "gu": "D7_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "After filling the form, Razia gets her answer. She decides to continue with her job.",
            "hi": "फॉर्म भरने के बाद, रजिया को उसका जवाब मिल जाता है। वह अपना जॉब जारी रखने का निर्णय लेती है।",
            "ka": "ಫಾರ್ಮ್ ಅನ್ನು ಭರ್ತಿ ಮಾಡಿದ ನಂತರ, ರಝಿಯಾ ತನ್ನ ಉತ್ತರವನ್ನು ಪಡೆಯುತ್ತಾಳೆ. ಅವಳು ತನ್ನ ಕೆಲಸವನ್ನು ಮುಂದುವರಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
            "te": "ఫారమ్‌ను పూరించిన తర్వాత, రజియా తన సమాధానం పొందింది. ఆమె తన ఉద్యోగాన్ని కొనసాగించాలని నిర్ణయించుకుంది.",
            "or": "ଫର୍ମ ପୂରଣ କରିବା ପରେ ରଜିଆ ତାଙ୍କ ଉତ୍ତର ପାଇଲେ । ସେ ତାଙ୍କ ଚାକିରି ଜାରି ରଖିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି।",
            "as": "ফৰ্মখন পূৰণ কৰাৰ পিছত, ৰাজিয়াই তাইৰ উত্তৰ পালে। তাই তাইৰ চাকৰিটো কৰি থকাৰ সিদ্ধান্ত লয়।",
            "gu": "ફોર્મ ભર્યા પછી, રઝિયાને જવાબ મળે છે. અને તે નોકરી ચાલુ રાખવાનું નક્કી કરે છે. "
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_1_2",
            "hi": "D7_1_2",
            "ka": "D7_1_2",
            "te": "D7_1_2",
            "or": "D7_1_2",
            "as": "D7_1_2",
            "gu": "D7_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "But she needs to find a solution to her fear.",
            "hi": "लेकिन उसे अपने डर की समस्या का समाधान ढूँढना होगा।",
            "ka": "ಆದರೆ ಅವಳ ಭಯಕ್ಕೆ ಪರಿಹಾರ ಕಂಡುಕೊಳ್ಳಬೇಕು.",
            "te": "అయితే ఆమె భయానికి పరిష్కారం కనుగొనవలసి ఉంది",
            "or": "କିନ୍ତୁ ତାଙ୍କୁ ତାଙ୍କ ଭୟର ସମାଧାନ ଖୋଜିବା ଆବଶ୍ୟକ ।",
            "as": "কিন্তু তাই তাইৰ ভয়ৰ সমাধান বিচাৰি উলিয়াব লাগিব।",
            "gu": "પરંતુ તેણીએ તેના ડરનો ઉકેલ શોધવાની જરૂર છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_1_3",
            "hi": "D7_1_3",
            "ka": "D7_1_3",
            "te": "D7_1_3",
            "or": "D7_1_3",
            "as": "D7_1_3",
            "gu": "D7_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She calls a few of her friends and decides to do a practice session with them. \nThey give her feedback and Razia corrects herself.",
            "hi": "उसने अपने कुछ दोस्तों कॉल किया और उनके साथ अभ्यास करने का तय किया। \n उन्होंने उसे प्रतिक्रियाएं दी और उनके अनुसार रजिया ने अपनी गलतियां सुधारी।",
            "ka": "\"ಅವಳು ತನ್ನ ಕೆಲವು ಸ್ನೇಹಿತರನ್ನು ಕರೆಸುತ್ತಾಳೆ ಮತ್ತು ಅವರೊಂದಿಗೆ ಅಭ್ಯಾಸ ಸೆಷನ್ ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.\n ಅವರು ಅವಳ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ನೀಡುತ್ತಾರೆ ಮತ್ತು ರಝಿಯಾ ತನ್ನನ್ನು ತಾನೇ ಸರಿಪಡಿಸಿಕೊಳ್ಳುತ್ತಾಳೆ.",
            "te": "ఆమె తన స్నేహితుల్లో కొందరిని పిలిచి, వారితో ఒక సాధన సెషన్ చేయాలని నిర్ణయించుకుంది. వారు తమ అభిప్రాయాన్ని ఆమెకు అందించారు మరియు రజియా తనను తాను సరిదిద్దుకుంది.",
            "or": "ସେ ତାଙ୍କର କିଛି ବନ୍ଧୁଙ୍କୁ ଡାକନ୍ତି ଏବଂ ସେମାନଙ୍କ ସହିତ ଏକ ଅଭ୍ୟାସ ଅଧିବେଶନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି । ସେମାନେ ତାଙ୍କୁ ମତାମତ ଦିଅନ୍ତି ଏବଂ ରଜିଆ ନିଜକୁ ସଂଶୋଧନ କରନ୍ତି ।",
            "as": "তাই তাইৰ কেইজনমান বন্ধুক ফোন কৰে আৰু তেওঁলোকৰ সৈতে অনুশীলন কৰাৰ সিদ্ধান্ত লয়। তেওঁলোকে তাইক মতামত দিয়ে আৰু ৰাজিয়াই নিজকে শুদ্ধ কৰে।",
            "gu": "તે તેના થોડાક મિત્રોને બોલાવે છે અને તેમની સાથે અનુભવ કરવાનું નક્કી કરે છે.\nતે મિત્રો તેને પ્રતિસાદ આપે છે અને રઝિયા પોતાને સુધારે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_2_1",
            "hi": "D7_2_1",
            "ka": "D7_2_1",
            "te": "D7_2_1",
            "or": "D7_2_1",
            "as": "D7_2_1",
            "gu": "D7_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She again does a session with the guests and Sushma Ma’am observes quietly.",
            "hi": "उसने फिर से मेहमानों के साथ एक सेशन किया और इस बार भी सुषमा मैम उसका निरिक्षण कर रही थी।",
            "ka": "ಅವಳು ಮತ್ತೆ ಅತಿಥಿಗಳೊಂದಿಗೆ ಸೆಷನ್ ಮಾಡುತ್ತಾರೆ ಮತ್ತು ಸುಷ್ಮಾ ಮೇಡಮ್ ಸದ್ದಿಲ್ಲದೆ ಗಮನಿಸುತ್ತಾರೆ.",
            "te": "ఆమె మళ్ళీ అతిథులతో సెషన్ చేస్తుంది మరియు సుష్మా మేడమ్ నిశ్శబ్దంగా గమనిస్తోంది",
            "or": "ସେ ପୁନର୍ବାର ଅତିଥିମାନଙ୍କ ସହିତ ଏକ ଅଧିବେଶନ କରନ୍ତି ଏବଂ ସୁଷମା ମାଡ଼ାମ୍ ଚୁପ୍ ଚାପ୍ ଦେଖନ୍ତି ।",
            "as": "তাই আকৌ অতিথিসকলৰ সৈতে এটা অধিৱেশন কৰে আৰু সুষমা মেমে শান্তভাৱে পৰ্যবেক্ষণ কৰে।",
            "gu": "તે ફરીથી મહેમાનો સાથે પ્રતિક્રિયા કરે છે અને સુષ્મા મેડમ શાંતિથી અવલોકન કરે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_2_2",
            "hi": "D7_2_2",
            "ka": "D7_2_2",
            "te": "D7_2_2",
            "or": "D7_2_2",
            "as": "D7_2_2",
            "gu": "D7_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Sushma Ma'am notices Razia adding a lot of stories from her childhood to keep the guests engaged.",
            "hi": "उन्होंने देखा कि मेहमानों की दिलचस्पी बनाए रखने के लिए रजिया अपने बचपन के कई किस्से सूना रही है।",
            "ka": "ಅತಿಥಿಗಳನ್ನು ತೊಡಗಿಸಿಕೊಳ್ಳಲು ರಝಿಯಾ ತನ್ನ ಬಾಲ್ಯದ ಕಥೆಗಳನ್ನು ಸೇರಿಸುವುದನ್ನು ಸುಷ್ಮಾ ಮೇಮ್ ಗಮನಿಸುತ್ತಾರೆ",
            "te": "అతిథిని ఎంగేజ్ చేయడానికి రజియా తన చిన్ననాటి నుండి చాలా కథలను చెప్పడాన్ని ఆమె గమనించింది.",
            "or": "ସୁଷମା ମାଡ଼ାମ୍ ଲକ୍ଷ୍ୟ କରିଛନ୍ତି ଯେ ରଜିଆ ଅତିଥିମାନଙ୍କୁ ନିୟୋଜିତ ରଖିବା ପାଇଁ ତାଙ୍କ ପିଲାଦିନରୁ ଅନେକ କାହାଣୀ ଯୋଡିଛନ୍ତି ।",
            "as": "সুষমা বাইদেৱে লক্ষ্য কৰিছে যে ৰাজিয়াই অতিথিসকলক ব্যস্ত ৰাখিবলৈ তাইৰ শৈশৱৰ বহুতো কাহিনী যোগ দিছে।",
            "gu": "સુષ્મા મેડમ નોધે છે કે રઝિયા મહેમાનને વ્યસ્ત રાખવા માટે તેના બાળપણની ઘણી વાર્તાઓ કહે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "D7_2_3",
            "hi": "D7_2_3",
            "ka": "D7_2_3",
            "te": "D7_2_3",
            "or": "D7_2_3",
            "as": "D7_2_3",
            "gu": "D7_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Sushma Ma’am is impressed with Razia's improvement and new found confidence. She offers Razia a permanent job.",
            "hi": "रजिया के कार्य में हुए सुधार और आत्मविश्वास से सुषमा मैम काफी प्रभवित हुई। उन्होंने रजिया को परमनेंट जॉब की ऑफर दी।",
            "ka": "ಸುಷ್ಮಾ ಮೇಮ್ ರಝಿಯಾ ಅವಳೆ ಸುಧಾರಣೆ ಮತ್ತು ಹೊಸ ಆತ್ಮವಿಶ್ವಾಸದಿಂದ ಪ್ರಭಾವಿತರಾಗಿದ್ದಾರೆ. ಅವರು ರಝಿಯಾಗೆ ಖಾಯಂ ಉದ್ಯೋಗವನ್ನು ನೀಡುತ್ತಾರೆ.",
            "te": "రజియా యొక్క మెరుగుదల మరియు కొత్తగా కనుగొన్న ఆత్మవిశ్వాసానికి సుష్మా మేడమ్ ముగ్దులయ్యారు . ఆమె రజియాకు శాశ్వత ఉద్యోగాన్ని అందిస్తుంది",
            "or": "ରଜିଆର ଉନ୍ନତି ଏବଂ ନୂତନ ଆତ୍ମବିଶ୍ୱାସରେ ସୁଷମା ମାଡ଼ାମ୍ ପ୍ରଭାବିତ ହୁଅନ୍ତି । ସେ ରଜିଆଙ୍କୁ ଏକ ସ୍ଥାୟୀ ଚାକିରି ପ୍ରଦାନ କରନ୍ତି ।",
            "as": "সুষমা মেডাম ৰাজিয়াৰ উন্নতি আৰু নতুন আত্মবিশ্বাসত প্ৰভাৱিত হৈছে। তেওঁ ৰাজিয়াক এটা স্থায়ী চাকৰি প্ৰদান কৰে।",
            "gu": "સુષ્મા મેડમ રઝિયામાં આવેલા પરિવર્તન અને આત્મવિશ્વાસના સંચારથી અભિભૂત થાય છે અને તેને કાયમી નોકરી આપે છે"
          }
        }
      ]
    },
    "scene20": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D8_1_1",
            "hi": "D8_1_1",
            "ka": "D8_1_1",
            "te": "D8_1_1",
            "or": "D8_1_1",
            "as": "D8_1_1",
            "gu": "D8_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Seeing her do well with the tours, Sushma Ma’am starts doing fewer tours with the guests. Razia meets tourists from different parts of the world.",
            "hi": "उसे अच्छे से टूर करते हुए देखकर, सुषमा मैम ने मेहमानों के साथ कम टूर करने लगी। रजिया दुनियाभर के अलग-अलग हिस्सों से आए हुए टूरिस्टों से मिलती थी।",
            "ka": "ಅವಳು ಪ್ರವಾಸಗಳನ್ನು ಉತ್ತಮವಾಗಿ ಮಾಡುವುದನ್ನು ನೋಡಿದ ಸುಷ್ಮಾ ಮೇಮ್ ಅತಿಥಿಗಳೊಂದಿಗೆ ಕಡಿಮೆ ಪ್ರವಾಸಗಳನ್ನು ಮಾಡಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ. ರಝಿಯಾ ಪ್ರಪಂಚದ ವಿವಿಧ ಭಾಗಗಳಿಂದ ಪ್ರವಾಸಿಗರನ್ನು ಭೇಟಿಯಾಗುತ್ತಾಳೆ.",
            "te": "ఆమె పర్యటనలు బాగా చేయడం చూసి,సుష్మా మేడమ్ అతిథులతో కలిసి పర్యటనలు చేయడం తగ్గించింది.రజియా ప్రపంచంలోని వివిధ ప్రాంతాల నుండి వచ్చే పర్యాటకులను కలుస్తుంది.",
            "or": "ତାଙ୍କୁ ଭ୍ରମଣରେ ଭଲ ପ୍ରଦର୍ଶନ କରୁଥିବା ଦେଖି ସୁଷମା ମାଡ଼ାମ୍ ଅତିଥିମାନଙ୍କ ସହିତ କମ୍ ଭ୍ରମଣ କରିବା ଆରମ୍ଭ କରନ୍ତି । ରଜିଆ ଦୁନିଆର ବିଭିନ୍ନ ସ୍ଥାନରୁ ପର୍ଯ୍ୟଟକଙ୍କୁ ଭେଟନ୍ତି ।",
            "as": "তাইক ভ্ৰমণবোৰ সুন্দৰকৈ কৰা দেখি, সুষমা বাইদেৱে অতিথিসকলৰ সৈতে কম ভ্ৰমণ কৰা আৰম্ভ কৰে। ৰাজিয়াই পৃথিৱীৰ বিভিন্ন প্ৰান্তৰ পৰ্যটকক লগ কৰে।",
            "gu": "પ્રવાસમાં તે સારું કરતા જોઈને, સુષ્મા મેડમ મહેમાનો સાથે ઓછા પ્રવાસ કરવાનું શરૂ કરે છે. રઝિયા વિશ્વના અલગ અલગ ભાગોમાંથી પ્રવાસીઓને મળે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D8_1_2",
            "hi": "D8_1_2",
            "ka": "D8_1_2",
            "te": "D8_1_2",
            "or": "D8_1_2",
            "as": "D8_1_2",
            "gu": "D8_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Apart from conducting the tours, her job includes talking to the guests about mangrove forest, conducting workshops with the locals on protecting the environment, and helping create monthly reports.",
            "hi": "टूर आयोजित करने के अलावा, उसका काम है मेहमानों को मैनग्रोव के जंगलों के बारे में बताना, पर्यावरण की रक्षा करने के लिए स्थानिक लोगों के साथ वर्कशॉप आयोजित करना और मासिक रिपोर्ट तैयार करने में मदद करना।",
            "ka": "ಪ್ರವಾಸಗಳನ್ನು ನಡೆಸುವುದರ ಹೊರತಾಗಿ, ಮ್ಯಾಂಗ್ರೋವ್ ಕಾಡಿನ ಬಗ್ಗೆ ಅತಿಥಿಗಳೊಂದಿಗೆ ಮಾತನಾಡುವುದು, ಪರಿಸರವನ್ನು ರಕ್ಷಿಸುವ ಕುರಿತು ಸ್ಥಳೀಯರೊಂದಿಗೆ ಕಾರ್ಯಾಗಾರಗಳನ್ನು ನಡೆಸುವುದು ಮತ್ತು ಮಾಸಿಕ ವರದಿಗಳನ್ನು ರಚಿಸಲು ಸಹಾಯ ಮಾಡುವುದು ಅವಳ ಕೆಲಸ.",
            "te": "పర్యటనలు నిర్వహించడమే కాకుండా, మాంగ్రూవ్ అడవుల గురించి అతిథులతో మాట్లాడటం,పర్యావరణాన్ని పరిరక్షించడంపై స్థానికులతో వర్క్ షాప్‌లు నిర్వహించడం మరియు నెలవారీ నివేదికలను రూపొందించడంలో సహాయపడటం వీటిఅన్నింటిని ఆమె ఉద్యోగం లో చేర్చారు",
            "or": "ଭ୍ରମଣ କରିବା ବ୍ୟତୀତ, ତାଙ୍କ ଚାକିରିରେ ଅତିଥିମାନଙ୍କ ସହିତ ମାନଗ୍ରୁଭ୍ ଜଙ୍ଗଲ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରିବା, ପରିବେଶ ସୁରକ୍ଷା ଉପରେ ସ୍ଥାନୀୟ ଲୋକଙ୍କ ସହ କର୍ମଶାଳା ପରିଚାଳନା କରିବା ଏବଂ ମାସିକ ରିପୋର୍ଟ ପ୍ରସ୍ତୁତ କରିବାରେ ସାହାଯ୍ୟ କରିବା ଅନ୍ତର୍ଭୁକ୍ତ ।",
            "as": "ভ্ৰমণবোৰ পৰিচালনা কৰাৰ উপৰিও, তাইৰ কামৰ ভিতৰত আছে অতিথিসকলৰ সৈতে মেংগ্ৰোভ অৰণ্যৰ বিষয়ে কথা পতা, পৰিৱেশ সুৰক্ষিত কৰাৰ ওপৰত স্থানীয় লোকসকলৰ সৈতে কৰ্মশালা অনুষ্ঠিত কৰা, আৰু মাহিলী প্ৰতিবেদন সৃষ্টি কৰাত সহায় কৰা।",
            "gu": "પ્રવાસોના આયોજન કરવા ઉપરાંત, તે કામમાં મેન્ગ્રોવ જંગલ વિશે મહેમાનો સાથે વાત કરવી, પર્યાવરણની સુરક્ષા પર સ્થાનિકો સાથે વર્કશોપ યોજવી અને માસિક અહેવાલો બનાવવામાં મદદ કરવાનો સમાવેશ થાય છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D8_1_3",
            "hi": "D8_1_3",
            "ka": "D8_1_3",
            "te": "D8_1_3",
            "or": "D8_1_3",
            "as": "D8_1_3",
            "gu": "D8_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Happy with her knowledge and interest, some of the guests suggest that she should apply in eco-resorts outside of West Bengal but Razia is happy staying with her family and doing well at her job.",
            "hi": "उसकी ज्ञान और दिलचस्पी को देखकर खुश होते हुए कुछ मेहमानों ने रजिया को सुझाव दिया कि उसे पश्चिम बंगाल के बाहर के इको-रिसोर्ट में अप्लाई करना चाहिए लेकिन रजिया अपने परिवार के साथ रहकर खुश है और उसका जॉब भी अच्छा चल रहा है।",
            "ka": "ಆಕೆಯ ಜ್ಞಾನ ಮತ್ತು ಆಸಕ್ತಿಯಿಂದ ಸಂತೋಷವಾಗಿರುವ ಕೆಲವು ಅತಿಥಿಗಳು ಅವಳು ಪಶ್ಚಿಮ ಬಂಗಾಳದ ಹೊರಗಿನ ಪರಿಸರ-ರೆಸಾರ್ಟ್‌ಗಳಲ್ಲಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಬೇಕೆಂದು ಸಲಹೆ ನೀಡುತ್ತಾರೆ ಆದರೆ ರಝಿಯಾ ತನ್ನ ಕುಟುಂಬದೊಂದಿಗೆ ಸಂತೋಷದಿಂದ ಇದ್ದು ತನ್ನ ಕೆಲಸದಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಾಳೆ.",
            "te": "ఆమె జ్ఞానం మరియు ఆసక్తి చూసి సంతోషించి, కొంతమంది అతిథులు ఆమె పశ్చిమ బెంగాల్ వెలుపల ఉన్న ఎకో-రిసార్ట్ లలో దరఖాస్తు చేసుకోవాలని సూచిస్తున్నారు, అయితే రజియా తన కుటుంబంతో సంతోషంగా ఉంటూ తన ఉద్యోగంలో బాగా పనిచేస్తోంది.",
            "or": "ତାଙ୍କ ଜ୍ଞାନ ଏବଂ ଆଗ୍ରହରେ ଖୁସି, କିଛି ଅତିଥି ପରାମର୍ଶ ଦିଅନ୍ତି ଯେ ସେ ପଶ୍ଚିମବଙ୍ଗ ବାହାରେ ଇକୋ-ରିସର୍ଟରେ ଆବେଦନ କରିବା ଉଚିତ୍ କିନ୍ତୁ ରଜିଆ ତାଙ୍କ ପରିବାର ସହିତ ରହି ତାଙ୍କ ଚାକିରିରେ ଭଲ ପ୍ରଦର୍ଶନ କରି ଖୁସି ଅଛନ୍ତି ।",
            "as": "তাইৰ জ্ঞান আৰু আগ্ৰহেৰে সুখী, কিছুমান অতিথিয়ে পৰামৰ্শ দিয়ে যে তাই পশ্চিম বংগৰ বাহিৰৰ ইকো-ৰিজৰ্টত এপ্লাই কৰিব লাগে কিন্তু ৰাজিয়া তাইৰ পৰিয়ালৰ সৈতে থাকি আৰু তাইৰ কাম ভালকৈ কৰি সুখী।",
            "gu": "તેના જ્ઞાન અને રસથી ખુશ થઈને કેટલાક મહેમાનો સૂચવે છે કે તે પશ્ચિમ બંગાળની બહાર ઇકો-રિસોર્ટમાં અરજી કરવી જોઈએ પરંતુ રઝિયા તેના કુટુંબ સાથે રહીને અને તેની નોકરી સારી રીતે કરી રહી છે તેમાં તે ખુશ છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Junior Environmental Consultant",
            "hi": "ज्युनियर एनवायरमेंटल सलाहकार",
            "ka": "**THREAD_END**_ಕಿರಿಯ ಪರಿಸರ ಸಲಹೆಗಾರ",
            "te": "**THREAD_END**_జూనియర్ పర్యావరణ సలహాదారు",
            "or": "**THREAD_END** _କନିଷ୍ଠ ପରିବେଶ ପରାମର୍ଶଦାତା",
            "as": "**থ্ৰেড_এণ্ড**_কনিষ্ঠ পৰিৱেশ পৰামৰ্শদাতা",
            "gu": "**THREAD_END**_જુનિયર પર્યાવરણ સલાહકાર"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Razia that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने रजिया के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँची है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकती है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n \n ನೀವು ರಝಿಯಾಗಾಗಿ ಮಾಡಿದ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ.ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు రజియా కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర దృశ్యాలను అన్వేషించాలనుకుంటున్నారా?\"",
            "or": "ଅଭିନନ୍ଦନ! \n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି! \n ଆପଣ ରଜିଆ ପାଇଁ ନିଷ୍ପତ୍ତିଗୁଡିକ ପସନ୍ଦ କରିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତିକୁ ଆଗେଇ ନେଇଛି । ଆପଣ କରୁଥିବା ପସନ୍ଦଗୁଡିକ ଉପରେ ଆଧାର କରି ବିଭିନ୍ନ ପ୍ରକାରର ଶେଷ ଅଛି । ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହାଁନ୍ତି କି?",
            "as": "অভিনন্দন!\n তুমি এটা যাত্ৰা সমাপ্ত কৰিলা!\n \n তুমি ৰাজিয়াৰ বাবে বাছনি কৰিছা যাৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত আধাৰিত কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ জানিব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક સફર પૂરી કરી છે!\n\nતમે રઝિયા માટે જે પસંદગીઓ કરી છે જેના કારણે આ સંભવિત અંત આવ્યો છે. તમે જે પસંદગી કરો છો તેના આધારે વિવિધ અંત છે. શું તમે બીજા દ્રશ્યો શોધવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ର ଉପରେ ନଜର ପକାନ୍ତୁ",
            "as": "মানচিত্ৰখন চোৱা",
            "gu": "ચાલો નકશા તરફ નજર નાખીએ"
          }
        }
      ]
    },
    "scene21": {
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "Understanding self",
            "hi": "खुद को समझना",
            "ka": "ಸ್ವಯಂ ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು",
            "te": "తనను తాను అర్థం చేసుకోవడం",
            "or": "ନିଜକୁ ବୁଝିବା",
            "as": "নিজকে বুজা",
            "gu": "પોતાની જાતને સમજવું"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Understanding Self experience!",
            "hi": "आपने अभी-अभी खुद को समझने के बारे में ज्ञान प्राप्त किया है!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಸ್ವಯಂ ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವ ಬಗ್ಗೆ ಅನುಭವಗಳಿಸಿದ್ದೀರಿ",
            "te": "మీరు ఇప్పుడే మిమల్ని మీరు అర్థం చేసుకునే అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ଆତ୍ମ ଅଭିଜ୍ଞତାକୁ ବୁଝିବା ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ আত্ম অভিজ্ঞতাৰ বিষয়ে জ্ঞান লাভ কৰিছা!",
            "gu": "તમે હમણાં જ પોતાની જાતને સમજવાનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Understanding Self",
            "hi": "खुद को समझना",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "తనను తాను అర్థం చేసుకోవడం",
            "or": "ନିଜକୁ ବୁଝିବା",
            "as": "নিজকে বুজা",
            "gu": "પોતાની જાતને સમજવું"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Self Development",
            "hi": "स्वयं विकास",
            "ka": "ಸ್ವಯಂ ಅಭಿವೃದ್ಧಿ",
            "te": "స్వయం అభివృద్ధి",
            "or": "ଆତ୍ମ ବିକାଶ",
            "as": "আত্ম বিকাশ",
            "gu": "આત્મવિકાસ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Razia uses the career planning tool to understand herself and apply for the right job. When thinking about college courses and career paths, an important aspect is to know about yourself. That is learning about your capabilities, your interests, what you are good at and your values. Before researching career options, it is helpful to spend time understanding yourself. Doing that can help you to plan and make career decisions that are right for you.",
            "hi": "रजिया खुद को समझने के लिए करियर प्लानिंग टूल का इस्तेमाल करती है और सही जॉब के लिए अप्लाई करती है। कॉलेज के कोर्सेस और करियर के मार्गों के बारे में सोचते हुए सबसे महत्वपूर्ण पहलू है खुद को समझना। इसका मतलब है कि अपनी क्षमता, अपनी दिलचस्पी, आप किस चीज में अच्छे हैं और आपके जीवन मूल्यों के बारे में जानना। करियर ऑप्शन्स के बारे में रिसर्च करने से पहले, खुद को समझने के लिए समय बिताना मददगार साबित होता है। ऐसा करने से आपको प्लान बनाने में और अपने लिए सही करियर चुनने में मदद होती है।",
            "ka": "ರಝಿಯಾ ತನ್ನನ್ನು ತಾನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಮತ್ತು ಸರಿಯಾದ ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ವೃತ್ತಿ ಯೋಜನೆ ಸಾಧನವನ್ನು ಬಳಸುತ್ತಾಳೆ. ಕಾಲೇಜು ಕೋರ್ಸ್‌ಗಳು ಮತ್ತು ವೃತ್ತಿ ಮಾರ್ಗಗಳ ಬಗ್ಗೆ ಯೋಚಿಸುವಾಗ, ನಿಮ್ಮ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳುವುದು ಒಂದು ಪ್ರಮುಖ ಅಂಶವಾಗಿದೆ.ಅದು ನಿಮ್ಮ ಸಾಮರ್ಥ್ಯಗಳು, ನಿಮ್ಮ ಆಸಕ್ತಿಗಳು, ನೀವು ಯಾವುದರಲ್ಲಿ ಉತ್ತಮರು ಮತ್ತು ನಿಮ್ಮ ಮೌಲ್ಯಗಳ ಬಗ್ಗೆ ಕಲಿಯುವುದು. ವೃತ್ತಿಯ ಆಯ್ಕೆಗಳನ್ನು ಸಂಶೋಧಿಸುವ ಮೊದಲು, ನಿಮ್ಮನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಸಮಯವನ್ನು ಕಳೆಯಲು ಸಹಾಯವಾಗುತ್ತದೆ.ಹಾಗೆ ಮಾಡುವುದರಿಂದ ನಿಮಗೆ ಸೂಕ್ತವಾದ ವೃತ್ತಿ ನಿರ್ಧಾರಗಳನ್ನು ಯೋಜಿಸಲು ಮತ್ತು ಮಾಡಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡಬಹುದು.",
            "te": "రజియా తనను తాను అర్థం చేసుకోవడానికి మరియు సరైన ఉద్యోగం కోసం దరఖాస్తు చేసుకోవడానికి కెరీర్ ప్రణాళిక సాధనాన్ని ఉపయోగిస్తుంది. కళాశాల కోర్సులు మరియు కెరీర్ మార్గాల గురించి ఆలోచిస్తున్నప్పుడు, మీ గురించి తెలుసుకోవడం ఒక ముఖ్యమైన అంశం. అంటే మీ సామర్థ్యాలు, మీ ఆసక్తులు, మీరు దేనిలో మంచివారు మరియు మీ విలువల గురించి నేర్చుకోవడం. కెరీర్ ఎంపికలను పరిశోధించే ముందు, మిమ్మల్ని మీరు అర్థం చేసుకోవడానికి సమయాన్ని వెచ్చించడం ఉపయోగకరంగా ఉంటుంది. అలా చేయడం వల్ల మీకు సరైన కెరీర్ నిర్ణయాలను ప్రణాళిక చేసుకోవడానికి మరియు తీసుకోవడానికి మీకు సహాయపడుతుంది.",
            "or": "ରଜିଆ ନିଜକୁ ବୁଝିବା ଏବଂ ସଠିକ୍ ଚାକିରି ପାଇଁ ଆବେଦନ କରିବା ପାଇଁ କ୍ୟାରିୟର ଯୋଜନା ଉପକରଣ ବ୍ୟବହାର କରନ୍ତି । କଲେଜ ପାଠ୍ୟକ୍ରମ ଏବଂ କ୍ୟାରିୟର ପଥ ବିଷୟରେ ଚିନ୍ତା କରିବା ସମୟରେ, ଏକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ଦିଗ ହେଉଛି ନିଜ ବିଷୟରେ ଜାଣିବା । ତାହା ହେଉଛି ତୁମର ସାମର୍ଥ୍ୟ, ତୁମର ସ୍ୱାର୍ଥ, ତୁମେ କେଉଁଥିରେ ଭଲ ଏବଂ ତୁମର ମୂଲ୍ୟବୋଧ ବିଷୟରେ ଶିଖିବା । କ୍ୟାରିୟର ବିକଳ୍ପ ଉପରେ ଅନୁସନ୍ଧାନ କରିବା ପୂର୍ବରୁ, ନିଜକୁ ବୁଝିବାରେ ସମୟ ବିତାଇବା ସହାୟକ ହୋଇଥାଏ । ଏହା କରିବା ତୁମକୁ ଯୋଜନା କରିବାରେ ଏବଂ କ୍ୟାରିୟର ନିଷ୍ପତ୍ତି ନେବାରେ ସାହାଯ୍ୟ କରିପାରିବ ଯାହା ତୁମ ପାଇଁ ଠିକ୍ ଅଟେ ।",
            "as": "ৰাজিয়াই নিজকে বুজিবলৈ আৰু সঠিক কামৰ বাবে এপ্লাই কৰিবলৈ কেৰিয়াৰ পৰিকল্পনাৰ সঁজুলি ব্যৱহাৰ কৰে। কলেজৰ পাঠ্যক্ৰম আৰু কেৰিয়াৰৰ পথৰ বিষয়ে চিন্তা কৰোঁতে, এটা গুৰুত্বপূৰ্ণ দিশ হৈছে নিজৰ বিষয়ে জনা। সেয়া হৈছে নিজস্ব সামৰ্থ্য, আগ্ৰহ, কিহত ভাল আৰু নিজস্ব মূল্যবোধৰ বিষয়ে শিকা। কেৰিয়াৰৰ বিকল্পবোৰ আলোচনা কৰাৰ আগতে, নিজকে বুজাত সময় অতিবাহিত কৰাটো প্ৰয়োজনীয়। এনে কৰিলে ই তোমাক সঠিক পৰিকল্পনাৰে কেৰিয়াৰৰ বাছনি কৰাত সহায় কৰিব পাৰে।",
            "gu": "રઝિયા પોતાની જાતને સમજવા અને યોગ્ય નોકરીમાં અરજી કરવા માટે કારકિર્દી આયોજનના સાધનનો ઉપયોગ કરે છે. કોલેજના અભ્યાસક્રમો અને કારકિર્દીના માર્ગો વિશે વિચારતી વખતે, અને તમારા વિશે જાણવું એ એક મહત્વપૂર્ણ છે. તે તમારી આવડતો, તમારી રુચિઓ, તમે શેમાં સારા છો અને તમારા મૂલ્યો વિશે શીખવા વીશે છે. કારકિર્દીના વિકલ્પો શોધતા પહેલા, તમારે તમારી જાતને સમજવામાં થોડો સમય પસાર કરવો જોઈએ. આ તમને તમારા માટે યોગ્ય કારકિર્દીના નિર્ણયો  લેવામાં મદદ કરી શકે છે."
          }
        }
      ],
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "D9_1_1",
            "hi": "D9_1_1",
            "ka": "D9_1_1",
            "te": "D9_1_1",
            "or": "D9_1_1",
            "as": "D9_1_1",
            "gu": "D9_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Based on the result of the test, she is now sure that the role at the eco-resort is not a good fit for her.",
            "hi": "इस परीक्षा के परिणामों से अब उसे यकीन हो जाता है कि इको-रिसोर्ट में उसका रोल उसके सही नहीं है।",
            "ka": "ಪರೀಕ್ಷೆಯ ಫಲಿತಾಂಶದ ಆಧಾರದ ಮೇಲೆ, ಪರಿಸರ-ರೆಸಾರ್ಟ್‌ನಲ್ಲಿನ ಪಾತ್ರವು ತನಗೆ ಸರಿಹೊಂದುವುದಿಲ್ಲ ಎಂದು ಅವಳು ಈಗ ಖಚಿತವಾಗಿ ತಿಳಿದಿದ್ದಾಳೆ.",
            "te": "పరీక్ష ఫలితం ఆధారంగా, పర్యావరణ-రిసార్ట్‌లోని పాత్ర తనకు సరిపోదని ఆమె ఇప్పుడు నిశ్చయించుకుంది.",
            "or": "ପରୀକ୍ଷାର ଫଳାଫଳ ଆଧାରରେ, ସେ ବର୍ତ୍ତମାନ ନିଶ୍ଚିତ ଯେ ଇକୋ-ରିସର୍ଟରେ ଭୂମିକା ତାଙ୍କ ପାଇଁ ଭଲ ଭାବରେ ଫିଟ୍ ନୁହେଁ ।",
            "as": "পৰীক্ষাটোৰ ফলাফলৰ ওপৰত ভিত্তি কৰি, তাই এতিয়া নিশ্চিত যে ইক'-ৰিজৰ্টৰ পদটো তাইৰ বাবে উপযুক্ত নহয়।",
            "gu": "પરીક્ષણના પરિણામના આધારે, તેને હવે ખાતરી છે કે ઇકો-રિસોર્ટની ભૂમિકા  તેના માટે યોગ્ય નથી."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "D9_1_2",
            "hi": "D9_1_2",
            "ka": "D9_1_2",
            "te": "D9_1_2",
            "or": "D9_1_2",
            "as": "D9_1_2",
            "gu": "D9_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She feels the NGO role is the right path for her. She looks online if the NGO role is still open and she gets lucky!",
            "hi": "उसे लगता है कि एनजीओ का जॉब उसके लिए सही मार्ग होगा। वह ऑनलाइन चेक करती है कि एनजीओ का जॉब अभी भी उपलब्ध है या नहीं और वह भाग्यशाली है!",
            "ka": "ಎನ್‌ಜಿಒ ಪಾತ್ರವು ತನಗೆ ಸರಿಯಾದ ಮಾರ್ಗವೆಂದು ಅವಳು ಭಾವಿಸುತ್ತಾಳೆ.ಎನ್‌ಜಿಒ ಪಾತ್ರ ಇನ್ನೂ ತೆರೆದಿದ್ದರೆ ಅವಳು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ನೋಡುತ್ತಾಳೆ ಮತ್ತು ಅವಳು ಅದೃಷ್ಟಶಾಲಿಯಾಗುತ್ತಾಳೆ!",
            "te": "NGO పాత్ర తనకు సరైన మార్గమని ఆమె భావిస్తుంది. NGO పాత్ర ఇంకా తెరిచి ఉంటే ఆమె ఆన్‌లైన్‌లో చూస్తుంది మరియు ఆమెకు అదృష్టం కలిసి వస్తుంది!",
            "or": "ସେ ଅନୁଭବ କରନ୍ତି ଯେ NGO ଭୂମିକା ତାଙ୍କ ପାଇଁ ସଠିକ୍ ପଥ । ସେ ଅନଲାଇନରେ ଦେଖନ୍ତି ଯେ NGO ଭୂମିକାଟି ଏପର୍ଯ୍ୟନ୍ତ ଖୋଲା ଅଛି କି ନାହିଁ ଏବଂ ସେ ଭାଗ୍ୟବାନ!",
            "as": "তাই NGO'ৰ পদটো তাইৰ বাবে সঠিক পথ বুলি ভাবে। তাই অনলাইনত NGOৰ পদটো এতিয়াও খোলা আছে নে নাই চালে আৰু তাইৰ ভাগ্য ভাল আছিল, পদটো খোলা আছে!",
            "gu": "તેને લાગે છે કે NGOની ભૂમિકા તેના માટે સાચો રસ્તો છે. તે ઓનલાઇન જોવે છે NGOની ભૂમિકા હજુ પણ ખુલ્લી હોય અને તે નસીબદાર હોય તો!"
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "D9_1_3",
            "hi": "D9_1_3",
            "ka": "D9_1_3",
            "te": "D9_1_3",
            "or": "D9_1_3",
            "as": "D9_1_3",
            "gu": "D9_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She decides to apply for the role.",
            "hi": "वह इस रोल के लिए अप्लाई करने का निर्णय लेती है।",
            "ka": "ಅವಳು ಪಾತ್ರಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
            "te": "ఆమె పాత్ర కోసం దరఖాస్తు చేయాలని నిర్ణయించుకుంది.",
            "or": "ସେ ଏହି ଭୂମିକା ପାଇଁ ଆବେଦନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି।",
            "as": "তাই পদটোৰ বাবে এপ্লাই কৰাৰ সিদ্ধান্ত লয়।",
            "gu": "તેણી ભૂમિકા માટે અરજી કરવાનું નક્કી કરે છે."
          }
        }
      ]
    },
    "scene23": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_1_1_Apply at the school",
            "hi": "स्कूल में अप्लाई करना",
            "ka": "C1_1_1_ಶಾಲೆಯಲ್ಲಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ",
            "te": "C1_1_1_పాఠశాలలో దరఖాస్తు చేసుకోండి",
            "or": "C1_1_1_ବିଦ୍ୟାଳୟରେ ଆବେଦନ କରନ୍ତୁ",
            "as": "C1_1_1_বিদ্যালয়ত এপ্লাই",
            "gu": "C1_1_1_શાળામાં અરજી કર"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia applies for the role of Environmental Educator at the Greenspring School in Kolkata.",
            "hi": "रजिया कोलकाता के ग्रीनस्प्रिंग स्कूल में एनवायरमेंटल एज्युकेटर के जॉब के लिए अप्लाई करती है।",
            "ka": "ರಝಿಯಾ ಕೋಲ್ಕತ್ತಾದ ಗ್ರೀನ್‌ಸ್ಪ್ರಿಂಗ್ ಸ್ಕೂಲ್‌ನಲ್ಲಿ ಪರಿಸರ ಶಿಕ್ಷಕರ ಪಾತ್ರಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ್ದಾಳೆ.",
            "te": "రజియా కోల్‌కతాలోని గ్రీన్‌స్ప్రింగ్ స్కూల్‌లో పర్యావరణ విద్యావేత్త దరఖాస్తు పాత్ర కోసం చేసుకుంది",
            "or": "ରଜିଆ କୋଲକାତାର ଗ୍ରୀନସ୍ପ୍ରିଙ୍ଗ ସ୍କୁଲରେ ପରିବେଶ ଶିକ୍ଷାବିତ୍ ଭୂମିକା ପାଇଁ ଆବେଦନ କରନ୍ତି ।",
            "as": "ৰাজিয়াই কলকাতাৰ গ্ৰীণস্প্ৰিং স্কুলত পৰিৱেশ শিক্ষাবিদৰ পদৰ বাবে এপ্লাই কৰে।",
            "gu": "રઝિયા પર્યાવરણ શિક્ષકની ભૂમિકા માટે કોલકાતાની ગ્રીનસ્પ્રિંગ સ્કૂલમાં અરજી કરે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_1_2",
            "hi": "C1_1_2",
            "ka": "C1_1_2",
            "te": "C1_1_2",
            "or": "C1_1_2",
            "as": "C1_1_2",
            "gu": "C1_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Once she submits her application, she gets a call from the school informing her about the selection process.",
            "hi": "एप्लीकेशन सबमिट करने के बाद, उसे स्कूल में कॉल आता है जिससे उसे चुनाव प्रक्रिया की जानकारी मिलती है।",
            "ka": "ಅವಳು ತನ್ನ ಅರ್ಜಿಯನ್ನು ಸಲ್ಲಿಸಿದ ನಂತರ, ಆಕೆಗೆ ಆಯ್ಕೆ ಪ್ರಕ್ರಿಯೆಯ ಬಗ್ಗೆ ತಿಳಿಸುವುದಕ್ಕೆ ಶಾಲೆಯಿಂದ ಕರೆ ಬರುತ್ತದೆ.",
            "te": "ఆమె తన దరఖాస్తును సమర్పించిన తర్వాత, ఆమెకు ఎంపిక ప్రక్రియ గురించి తెలియజేస్తూ పాఠశాల నుండి కాల్ వస్తుంది.",
            "or": "ସେ ତାଙ୍କ ଆବେଦନ ଦାଖଲ କରିବା ପରେ, ବିଦ୍ୟାଳୟରୁ ଏକ କଲ୍ ପାଇଲେ ଯେଉଥିରେ ଚୟନ ପ୍ରକ୍ରିୟା ବିଷୟରେ ତାଙ୍କୁ ସୂଚନା ଦିଆଗଲା।",
            "as": "তাই এপ্লাই কৰাৰ পিছত, তাই বিদ্যালয়ৰ পৰা ফোন এটা পায় আৰু তাইক বাছনি প্ৰক্ৰিয়াৰ বিষয়ে কয়।",
            "gu": "એકવાર તે પોતાની અરજી મોકલે છે પછી, તેને શાળામાંથી એક ફોન આવે છે જે તે પસંદગી પ્રક્રિયા વિશે જાણ કરે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_1_3///",
            "hi": "C1_1_3///",
            "ka": "C1_1_3///",
            "te": "C1_1_3///",
            "or": "C1_1_3///",
            "as": "C1_1_3///",
            "gu": "C1_1_3///"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "She has to write a 500 word essay explaining why she wants to be an environmental educator. She also has to submit a lesson plan and prepare for a demonstration class.",
            "hi": "उसे 500 शब्दों का निबंध लिखकर यह बताना है कि वह एनवायरमेंटल एज्युकेटर क्यों बनना चाहती है। उसे लेसन प्लान भी सबमिट करना है और डेमोंस्ट्रेशन क्लास के लिए तैयारी करनी है।",
            "ka": "ಅವಳು ಪರಿಸರ ಶಿಕ್ಷಣತಜ್ಞರಾಗಲು ಏಕೆ ಬಯಸುತ್ತಾಳೆ ಎಂಬುದನ್ನು ವಿವರಿಸುವ ೫೦೦ ಪದಗಳ ಪ್ರಬಂಧವನ್ನು ಬರೆಯಬೇಕು. ಅವಳು ಪಾಠ ಯೋಜನೆಯನ್ನು ಸಲ್ಲಿಸಬೇಕು ಮತ್ತು ಪ್ರಾತ್ಯಕ್ಷಿಕೆ ತರಗತಿಗೆ ತಯಾರಿ ನಡೆಸಬೇಕು.",
            "te": "ఆమె పర్యావరణ అధ్యాపకురాలిగా ఎందుకు ఉండాలనుకుంటున్నారో వివరిస్తూ 500 పదాల వ్యాసాన్ని రాయాలి. ఆమె పాఠ్య ప్రణాళికను సమర్పించి,తరగతి లో చెప్పడం కోసం సిద్ధం కావాలి",
            "or": "ସେ କାହିଁକି ପରିବେଶ ଶିକ୍ଷାବିତ୍ ହେବାକୁ ଚାହୁଁଛନ୍ତି ତାହା ବର୍ଣ୍ଣନା କରି ତାଙ୍କୁ ଏକ 500 ଶବ୍ଦ ପ୍ରବନ୍ଧ ଲେଖିବାକୁ ପଡିବ । ତାଙ୍କୁ ଏକ ପାଠ୍ୟ ଯୋଜନା ମଧ୍ୟ ଦାଖଲ କରିବାକୁ ପଡିବ ଏବଂ ଏକ ପ୍ରଦର୍ଶନ ଶ୍ରେଣୀ ପାଇଁ ପ୍ରସ୍ତୁତ ହେବାକୁ ପଡିବ ।",
            "as": "তাই কিয় এজন পৰিৱেশ শিক্ষাবিদ হ'ব বিচাৰে বৰ্ণনা কৰি 500 শব্দৰ প্ৰবন্ধ লিখিব লাগিব। তাই এটা পাঠ পৰিকল্পনা দাখিল কৰিব লাগিব আৰু এটা প্ৰদৰ্শন শ্ৰেণীৰ বাবে প্ৰস্তুত হ'ব লাগিব।",
            "gu": "તે ને પર્યાવરણીય શિક્ષક કેમ બનવું છે તે માટે  તેને 500 શબ્દોનો નિબંધ લખવો પડશે. તેણે પાઠ આયોજન મોકલવું પડશે અને નિદર્શન વર્ગ માટે તૈયારી કરવી પડશે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_2_1",
            "hi": "C1_2_1",
            "ka": "C1_2_1",
            "te": "C1_2_1",
            "or": "C1_2_1",
            "as": "C1_2_1",
            "gu": "C1_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia gets selected for the next round. In the second round, Razia is asked to teach on a topic for 15 minutes. Razia walks into a class of 20 students.  She feels unprepared and nervous.",
            "hi": "रजिया का अगले राउंड के लिए चुनाव होता है। दुसरे राउंड में, रजिया को एक विषय पर 15 मिनट तक पढ़ाने के लिए कहा जाता है। रजिया 20 विद्यार्थियों की कक्षा में प्रवेश करती है। वह इसके लिए पूरी तरह से तैयार नहीं है और इसलिए वह घबरा जाती है।",
            "ka": "ರಝಿಯಾ ಮುಂದಿನ ಸುತ್ತಿಗೆ ಆಯ್ಕೆಯಾಗುತ್ತಾಳೆ. ಎರಡನೇ ಸುತ್ತಿನಲ್ಲಿ, ರಝಿಯಾಗೆ ೧೫ ನಿಮಿಷಗಳ ಕಾಲ ಒಂದು ವಿಷಯದ ಬಗ್ಗೆ ಕಲಿಸಲು ಕೇಳಲಾಗುತ್ತದೆ. ರಝಿಯಾ ೨೦ ವಿದ್ಯಾರ್ಥಿಗಳ ತರಗತಿಗೆ ಹೋಗುತ್ತಾಳೆ. ಅವಳು ಸಿದ್ಧವಿಲ್ಲದ ಮತ್ತು ಉದ್ವೇಗ ಭಾವನೆಯನ್ನು ಅನುಭವಿಸುತ್ತಾಳೆ.",
            "te": "రజియా తదుపరి రౌండ్‌కు ఎంపికైంది. రెండవ రౌండ్‌లో, రజియాను 15 నిమిషాల పాటు ఒక అంశంపై బోధించమని అడుగుతారు. రజియా 20 మంది విద్యార్థులతో కూడిన తరగతిలోకి వెళుతుంది.ఆమె సిద్ధంగా లేనట్లు మరియు భయం గా అనిపిస్తుంది",
            "or": "ରଜିଆ ପରବର୍ତ୍ତୀ ରାଉଣ୍ଡ ପାଇଁ ମନୋନୀତ ହୁଅନ୍ତି । ଦ୍ୱିତୀୟ ରାଉଣ୍ଡରେ, ରଜିଆଙ୍କୁ 15 ମିନିଟ୍ ପାଇଁ ଏକ ବିଷୟ ଉପରେ ଶିକ୍ଷା ଦେବାକୁ କୁହାଯାଏ । ରଜିଆ 20 ଜଣ ଛାତ୍ରଙ୍କ ଶ୍ରେଣୀକୁ ଯାଆନ୍ତି । ସେ ଅପ୍ରସ୍ତୁତ ଏବଂ ନର୍ଭସ ଅନୁଭବ କରନ୍ତି ।",
            "as": "ৰাজিয়া পৰৱৰ্তী ৰাউণ্ডৰ বাবে নিৰ্বাচিত হয়। দ্বিতীয় ৰাউণ্ডত, ৰাজিয়াক 15 মিনিটৰ বাবে এটা বিষয়ত শিকাবলৈ কোৱা হয়। ৰাজিয়াই ২০ জন শিক্ষাৰ্থীৰ এটা শ্ৰেণীলৈ যায়। তাই অলপ অপ্ৰস্তুত হয় আৰু ভয় খায়।",
            "gu": "રઝિયા આગલા રાઉન્ડ માટે પસંદ થઈ જાય છે. બીજા રાઉન્ડમાં, રઝિયાને 15 મિનિટ માટે એક વિષય પર ભણાવવાનું કહેવામાં આવે છે. રઝિયા 20 વિદ્યાર્થીઓના વર્ગમાં જાય છે. તે તૈયારી વિનાની અને ઉદાસીનતા અનુભવે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_2_2////",
            "hi": "C1_2_2////",
            "ka": "C1_2_2////",
            "te": "C1_2_2////",
            "or": "C1_2_2/////",
            "as": "C1_2_2////",
            "gu": "C1_2_2////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "She hesitantly starts the class. The class does not start well. She forgets to introduce herself.",
            "hi": "वह झिझकते हुए पढ़ाना शुरू करती है, लेकिन वह ये काम अच्छे नहीं कर पाती। वह खुदके बारे में जानकारी देना भूल जाती है।",
            "ka": "ಅವಳು ಹಿಂಜರಿಕೆಯಿಂದ ತರಗತಿಯನ್ನು ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ. ತರಗತಿ ಸರಿಯಾಗಿ ಪ್ರಾರಂಭವಾಗುವುದಿಲ್ಲ. ಅವಳು ತನ್ನನ್ನು ಪರಿಚಯಿಸಿಕೊಳ್ಳಲು ಮರೆಯುತ್ತಾಳೆ.",
            "te": "ఆమె తడబడుతూ క్లాసు మొదలు పెట్టింది. క్లాస్ సరిగ్గా ప్రారంభం కాలేదు. ఆమె తనను తాను పరిచయం చేసుకోవడం మర్చిపోయింది.",
            "or": "ସେ ଦ୍ୱିଧାରେ ଶ୍ରେଣୀ ଆରମ୍ଭ କରନ୍ତି । ଶ୍ରେଣୀ ଭଲରେ ଆରମ୍ଭ ହୁଏ ନାହିଁ । ସେ ନିଜକୁ ପରିଚିତ କରାଇବାକୁ ଭୁଲିଯାଆନ୍ତି ।",
            "as": "তাই সংকোচেৰে শ্ৰেণীটো আৰম্ভ কৰে। শ্ৰেণীটো ভালদৰে আৰম্ভ নহয়। তাই নিজৰ পৰিচয় দিবলৈ পাহৰি যায়।",
            "gu": "તેણે અચકાતા અચકતા વર્ગ શરૂ કર્યો. વર્ગ સારી રીતે શરૂ થતો નથી. તે પોતાનો પરિચય આપવાનું ભૂલી જાય છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "The students seem distracted.",
            "hi": "विद्यार्थियों का पढ़ाई में ध्यान नहीं हैं।",
            "ka": "ವಿದ್ಯಾರ್ಥಿಗಳು ವಿಚಲಿತರಾದಂತಿದೆ.",
            "te": "విద్యార్థులు పరధ్యానంలో ఉన్నట్లు తెలుస్తోంది.",
            "or": "ଛାତ୍ରମାନେ ବିଭ୍ରାନ୍ତ ମନେହୁଅନ୍ତି ।",
            "as": "শিক্ষাৰ্থীসকল অন্যমনস্ক যেন লাগিছে।",
            "gu": "વિદ્યાર્થીઓ વિચલિત થઈ જાય છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "To get their attention she plays a quiz with them.",
            "hi": "उनका ध्यान आकर्षित करने के लिए वह उनके साथ क्विज खेलती है।",
            "ka": "ಅವರ ಗಮನ ಸೆಳೆಯಲು ಅವಳು ಅವರೊಂದಿಗೆ ರಸಪ್ರಶ್ನೆ ಆಡುತ್ತಾಳೆ.",
            "te": "వారి దృష్టిని ఆకర్షించేందుకు ఆమె వారితో క్విజ్ ఆడుతుంది",
            "or": "ସେମାନଙ୍କର ଧ୍ୟାନ ପାଇବା ପାଇଁ ସେ ସେମାନଙ୍କ ସହିତ ଏକ କୁଇଜ୍ ଖେଳନ୍ତି ।",
            "as": "সিহতৰ মনোযোগ আকৰ্ষণ কৰিবলৈ তাই সিহঁতৰ সৈতে এটা কুইজ খেলে।",
            "gu": "તેમનું ધ્યાન ખેંચવા માટે તે તેમની સાથે પ્રશ્નોતરીની રમત રમે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "The students get excited over the quiz but it leads to chaos in the class.",
            "hi": "क्विज के नाम से विद्यार्थी ज्यादा ही उत्साहित हो जाते हैं और कक्षा में शोर मच जाता है।",
            "ka": "ವಿದ್ಯಾರ್ಥಿಗಳು ರಸಪ್ರಶ್ನೆಯಲ್ಲಿ ಉತ್ಸುಕರಾಗುತ್ತಾರೆ ಆದರೆ ಇದು ತರಗತಿಯಲ್ಲಿ ಗೊಂದಲಕ್ಕೆ ಕಾರಣವಾಗುತ್ತದೆ.",
            "te": "విద్యార్థులు క్విజ్‌పై ఉత్సాహంగా ఉంటారు కానీ అది తరగతిలో గందరగోళానికి దారి తీస్తుంది.",
            "or": "ଛାତ୍ରମାନେ କୁଇଜ୍ ଉପରେ ଉତ୍ସାହିତ ହୁଅନ୍ତି କିନ୍ତୁ ଏହା ଶ୍ରେଣୀରେ ବିଶୃଙ୍ଖଳା ସୃଷ୍ଟି କରେ ।",
            "as": "শিক্ষাৰ্থীসকল কুইজটোক লৈ উত্তেজিত হৈ পৰে কিন্তু ই শ্ৰেণীত বিশৃংখলতাৰ সৃষ্টি কৰে।",
            "gu": "વિદ્યાર્થીઓ પ્રશ્નોતરીમાં મજા આવે છે પરંતુ તે વર્ગમાં અવ્યવસ્ચા તરફ દોરી જાય છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_2_3",
            "hi": "C1_2_3",
            "ka": "C1_2_3",
            "te": "C1_2_3",
            "or": "C1_2_3",
            "as": "C1_2_3",
            "gu": "C1_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia gets the help of the coordinator observing her class to calm the students.",
            "hi": "रजिया को उसकी कक्षा पर नजर रखने के लिए आए हुए कोऑर्डिनेटर की मदद से विद्यार्थियों को शांत करती है।",
            "ka": "ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಶಾಂತಗೊಳಿಸಲು ರಝಿಯಾ ತನ್ನ ತರಗತಿಯನ್ನು ಗಮನಿಸಿದ ಸಂಯೋಜಕರ ಸಹಾಯವನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
            "te": "విద్యార్థులను శాంతింపజేయడానికి రజియా తన తరగతిని గమనిస్తున్న సమన్వయకర్త సహాయాన్ని తీసుకుంటుంది.",
            "or": "ଛାତ୍ରମାନଙ୍କୁ ଶାନ୍ତ କରିବା ପାଇଁ ରଜିଆ ତାଙ୍କ ଶ୍ରେଣୀର ନିରୀକ୍ଷଣ କରୁଥିବା ସଂଯୋଜକଙ୍କ ସାହାଯ୍ୟ ପାଆନ୍ତି ।",
            "as": "ৰাজিয়াই শিক্ষাৰ্থীসকলক শান্ত কৰিবলৈ তাইৰ শ্ৰেণীটো পৰ্যবেক্ষণ কৰা সমন্বয়কাৰীৰ সহায় লয়।",
            "gu": "વિદ્યાર્થીઓને શાંત કરવા માટે રઝિયાને તેના વર્ગનું નિરીક્ષણ કરતા સંયોજકની મદદ મળે છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "C1_2_4",
            "hi": "C1_2_4",
            "ka": "C1_2_4",
            "te": "C1_2_4",
            "or": "C1_2_4",
            "as": "C1_2_4",
            "gu": "C1_2_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The coordinator, observing her throughout, is disappointed and gives a low score.",
            "hi": "उस पर नजर रखने के लिए आए हुए कोऑर्डिनेटर निराश हो जाते है और उसे कम गुण देते हैं।",
            "ka": "ಸಂಯೋಜಕರು, ಅವಳನ್ನು ಉದ್ದಕ್ಕೂ ಗಮನಿಸುತ್ತಾ, ನಿರಾಶೆಗೊಂಡರು ಮತ್ತು ಕಡಿಮೆ ಅಂಕಗಳನ್ನು ನೀಡುತ್ತಾರೆ.",
            "te": "సమన్వయకర్త,ఆమెను అంతటా గమనించి, నిరాశ చెందాడు మరియు తక్కువ స్కోర్‌ని ఇచ్చాడు.",
            "or": "ସଂଯୋଜକ, ତାଙ୍କୁ ସମଗ୍ର ସମୟରେ ନିରୀକ୍ଷଣ କରି ନିରାଶ ହୋଇ କମ୍ ସ୍କୋର ଦିଅନ୍ତି ।",
            "as": "সমন্বয়কাৰীজনে তাইক সমগ্ৰ সময়ত লক্ষ্য কৰি হতাশ হয় আৰু কম নম্বৰ দিয়ে।",
            "gu": "સંયોજક, તેનું સમગ્ર વસ્તુ જોવે છે અને નિરાશ થાય છે અને ઓછો સ્કોર આપે છે."
          }
        }
      ]
    },
    "scene24": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_1_1",
            "hi": "C2_1_1",
            "ka": "C2_1_1",
            "te": "C2_1_1",
            "or": "C2_1_1",
            "as": "C2_1_1",
            "gu": "C2_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "After taking the demo class, Raiza meets the principal.",
            "hi": "डेमो क्लास लेने के बाद, रजिया प्रिंसिपल से मिलती है",
            "ka": "ಡೆಮೊ ಕ್ಲಾಸ್ ತೆಗೆದುಕೊಂಡ ನಂತರ ರಝಿಯಾ ಪ್ರಾಂಶುಪಾಲರನ್ನು ಭೇಟಿಯಾಗುತ್ತಾಳೆ.",
            "te": "డెమో క్లాస్ తీసుకున్న తర్వాత, రజియా ప్రిన్సిపాల్‌ని కలుస్తుంది.",
            "or": "ଡେମୋ କ୍ଲାସ୍ ନେବା ପରେ ରଜିଆ ପ୍ରିନ୍ସିପାଲଙ୍କୁ ଭେଟିଲେ।",
            "as": "ডেমো শ্ৰেণী লোৱাৰ পিছত, ৰাজিয়াই অধ্যক্ষক লগ কৰে।",
            "gu": "ડેમોનો ક્લાસ લીધા પછી, રઝિયા આચાર્યશ્રીને મળે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Principal",
            "hi": "प्रिंसिपल",
            "ka": "ಪ್ರಿನ್ಸಿಪಾಲ್",
            "te": "ప్రిన్సిపాల్",
            "or": "ପ୍ରିନ୍ସିପାଲ୍",
            "as": "অধ্যক্ষ",
            "gu": "આચાર્યશ્રી"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "May I know your motivation to apply?",
            "hi": "क्या मैं जान सकता हूँ कि तुम्हे अप्लाई करने की प्रेरणा कहाँ से मिली।",
            "ka": "ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿಮ್ಮ ಪ್ರೇರಣೆಯನ್ನು ನಾನು ತಿಳಿಯಬಹುದೇ?",
            "te": "దరఖాస్తు చేయడానికి మీ ప్రేరణ గురించి నేను తెలుసుకోవచ్చా?",
            "or": "ଆବେଦନ କରିବାକୁ ଆପଣଙ୍କର ପ୍ରେରଣାକୁ ମୁଁ ଜାଣିପାରେ କି?",
            "as": "মই আপুনি এপ্লাই কৰিবলৈ কিয় অনুপ্ৰেৰণা পালে জানিব পাৰোনে?",
            "gu": "શું હું જાણી શકું અરજી કરવાની તમારી પ્રેરણા ને?"
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_1_2_OrderComponent",
            "hi": "C2_1_2_ऑर्डरकम्पोनेंट",
            "ka": "C2_1_2_ಆರ್ಡರ್ ಕಾಂಪೊನೆಂಟ್",
            "te": "C2_1_2_ఆర్డర్ కాంపొనెంట్",
            "or": "C2_1_2_ଉପାଦାନ ଅର୍ଡର କରନ୍ତୁ",
            "as": "C2_1_2_উপাদানৰ আদেশ",
            "gu": "2_1_2 ઓર્ડર ઘટક"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Help Razia select the right answer -",
            "hi": "रजिया को सही उत्तर चुनने में मदद करें -",
            "ka": "ಸರಿಯಾದ ಉತ್ತರವನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ರಝಿಯಾಗೆ ಸಹಾಯ ಮಾಡಿ -",
            "te": "సరైన సమాధానం ఎంచుకోవడంలో రజియాకు సహాయం చేయండి -",
            "or": "ରଜିଆଙ୍କୁ ସଠିକ୍ ଉତ୍ତର ଚୟନ କରିବାରେ ସାହାଯ୍ୟ କରନ୍ତୁ -",
            "as": "ৰাজিয়াক সঠিক উত্তৰ বাছনি কৰাত সহায় কৰা -",
            "gu": "રઝિયા ને સાચો જવાબ શોધવામાં મદદ કરો -"
          }
        },
        {
          "valuePath": "blocks.0.props.optionGroups.0.options.0.display",
          "value": {
            "en": "I have good subject matter knowledge and expertise",
            "hi": "मेरे पास इस विषय का अच्छा ज्ञान और विशेषता है",
            "ka": "ನನಗೆ ಉತ್ತಮ ವಿಷಯ ಜ್ಞಾನ ಮತ್ತು ಪರಿಣತಿ ಇದೆ",
            "te": "నాకు మంచి సబ్జెక్ట్ పరిజ్ఞానం మరియు నైపుణ్యం ఉంది",
            "or": "ମୋର ଭଲ ବିଷୟ ବସ୍ତୁ ଜ୍ଞାନ ଏବଂ ପାରଦର୍ଶିତା ଅଛି",
            "as": "মোৰ বিষয়বস্তুৰ ওপৰত ভাল জ্ঞান আৰু দক্ষতা আছে",
            "gu": "મારી પાસે તે વિષયનું સારું જ્ઞાન અને કુશળતા છે"
          }
        },
        {
          "valuePath": "blocks.0.props.optionGroups.0.options.1.display",
          "value": {
            "en": "I have always wanted to live in the city",
            "hi": "मैं हमेशा से ही शहर में रहना चाहती थी",
            "ka": "ನಾನು ಯಾವಾಗಲೂ ನಗರದಲ್ಲಿ ವಾಸಿಸಲು ಬಯಸುತಿದ್ಧೆ",
            "te": "ఈ ఉద్యోగం మిగతా వాటి కంటే బాగా జీతం చెల్లిస్తుంది",
            "or": "ମୁଁ ସର୍ବଦା ସହରରେ ରହିବାକୁ ଚାହୁଁଛି",
            "as": "মই সদায় চহৰখনত থাকিব বিচাৰিছিলো",
            "gu": "મારે હમેશા શહેરમાં રહેવાની ઈચ્છા હતી"
          }
        },
        {
          "valuePath": "blocks.0.props.optionGroups.1.options.0.display",
          "value": {
            "en": "This job pays well than the others",
            "hi": "दुसरे जॉब की तुलना में इस जॉब में अच्छी सैलरी मिलती है",
            "ka": "ಈ ಕೆಲಸವು ಇತರರಿಗಿಂತ ಉತ್ತಮವಾಗಿ ಪಾವತಿಸುತ್ತದೆ\n",
            "te": "వేరే వాటి కన్నా ఈ ఉద్యోగం లో జీతం ఎక్కువ",
            "or": "ଏହି ଚାକିରି ଅନ୍ୟମାନଙ୍କ ତୁଳନାରେ ଭଲ ଦେୟ ଦେଇଥାଏ",
            "as": "এই চাকৰিটোৱে আনবোৰতকৈ ভাল দৰমহা দিয়ে",
            "gu": "આ નોકરી સારા પૈસા આપે છે બીજા નોકરી કરતાં"
          }
        },
        {
          "valuePath": "blocks.0.props.optionGroups.1.options.1.display",
          "value": {
            "en": "I want to empower children to protect the environment",
            "hi": "मैं बच्चों को पर्यावरण की रक्षा करने के लिए सक्षम बनाना चाहती हूँ",
            "ka": "ಪರಿಸರವನ್ನು ರಕ್ಷಿಸಲು ಮಕ್ಕಳನ್ನು ಸಬಲೀಕರಣಗೊಳಿಸಲು ನಾನು ಬಯಸುತ್ತೇನೆ",
            "te": "పర్యావరణాన్ని పరిరక్షించే లా పిల్లలను శక్తివంతం చేయాలనుకుంటున్నాను",
            "or": "ପରିବେଶର ସୁରକ୍ଷା ପାଇଁ ମୁଁ ପିଲାମାନଙ୍କୁ ସଶକ୍ତ କରିବାକୁ ଚାହୁଁଛି",
            "as": "মই শিশুসকলক পৰিৱেশ সুৰক্ষিত কৰিবলৈ উসাহিত কৰিব বিচাৰোঁ",
            "gu": "હું બાળકોને પર્યાવરણની સુરક્ષા માટે સશક્ત બનાવવા માંગુ છું "
          }
        },
        {
          "valuePath": "blocks.0.props.optionGroups.2.options.0.display",
          "value": {
            "en": "I am new to this field but I am fast learner.",
            "hi": "यह क्षेत्र मेरे लिए नया है लेकिन मैं बहुत जल्दी सीखती हूँ",
            "ka": "ನಾನು ಈ ಕ್ಷೇತ್ರಕ್ಕೆ ಹೊಸಬ ಆದರೆ ನಾನು ವೇಗವಾಗಿ ಕಲಿಯುವವನು\n",
            "te": "నేను ఈ రంగానికి కొత్త కానీ నేను వేగంగా నేర్చుకోగలను.",
            "or": "ମୁଁ ଏହି କ୍ଷେତ୍ରରେ ନୂତନ କିନ୍ତୁ ମୁଁ ବହୁତ ଶୀଘ୍ର ଶିଖେ ।",
            "as": "মই এই ক্ষেত্ৰত নতুন যদিও মই বেগী শিক্ষাৰ্থী",
            "gu": "હું આ ક્ષેત્રમાં નવી છું પણ હું જલ્દી શિખનાર છું."
          }
        },
        {
          "valuePath": "blocks.0.props.optionGroups.2.options.1.display",
          "value": {
            "en": "My friends say I’m good at teaching",
            "hi": "मेरे दोस्त कहते हैं कि मैं अच्छे से पढ़ाती हूँ",
            "ka": "ನಾನು ಚೆನ್ನಾಗಿ ಕಲಿಸುತ್ತೆನೆ ಎಂದು ನನ್ನ ಸ್ನೇಹಿತರು ಹೇಳುತ್ತಾರೆ",
            "te": "నేను టీచింగ్‌లో బాగా రాణిస్తానని నా స్నేహితులు అంటున్నారు",
            "or": "ମୋର ସାଙ୍ଗମାନେ କୁହନ୍ତି ମୁଁ ଶିକ୍ଷାଦାନରେ ଭଲ",
            "as": "মোৰ বন্ধুসকলে কয় যে মই শিকোৱাত ভাল",
            "gu": "મારા મિત્રો કહે છે કે ભણાવામાં સારી છું"
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_1_3////",
            "hi": "C2_1_3////",
            "ka": "C2_1_3////",
            "te": "C2_1_3////",
            "or": "C2_1_3////",
            "as": "C2_1_3////",
            "gu": "C2_1_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Principal is impressed by her clarity of thought.",
            "hi": "प्रिंसिपल उसके स्पष्ट विचारों से प्रभावित हैं।",
            "ka": "ಆಕೆಯ ಆಲೋಚನೆಯ ಸ್ಪಷ್ಟತೆಯಿಂದ ಪ್ರಿನ್ಸಿಪಾಲ್ ಪ್ರಭಾವಿತರಾಗಿದ್ದಾರೆ.",
            "te": "ప్రిన్సిపాల్ ఆమె ఆలోచనల స్పష్టతకు ముగ్ధులయ్యారు.",
            "or": "ପ୍ରିନ୍ସିପାଲ୍ ତାଙ୍କ ଚିନ୍ତାଧାରାର ସ୍ପଷ୍ଟତା ଦ୍ୱାରା ପ୍ରଭାବିତ ହେଲେ ।",
            "as": "অধ্যক্ষই তাইৰ চিন্তাৰ স্পষ্টতা দেখি ভাল পায়।",
            "gu": "આચાર્યશ્રી તેના વિચારોની સ્પષ્ટતાથી પ્રભાવિત થાય છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Razia you seem to be an intelligent girl, but it seems like you have no experience in teaching. This can be a big problem.\nAs an option, we can give you the first 6 months training on the job but you will have to sign a contract with us for a minimum of 3 years.",
            "hi": "रजिया तुम बहुत होशियार लड़की हो लेकिन लगता है कि तुम्हारे पास पढ़ाने का कोई अनुभव नहीं है। यह बड़ी समस्या हो सकती है। \n इस समस्या के उपाय के तौर पर हम तुम्हें 6 महीने जॉब की ट्रेनिंग दे सकते हैं लेकिन तुम्हें हमारे साथ कम से कम 3 साल का कांट्रेक्ट साइन करना होगा।",
            "ka": "“ರಝಿಯಾ ನೀನು ಬುದ್ದಿವಂತ ಹುಡುಗಿಯಂತೆ ಕಾಣುತ್ತೀಯ, ಆದರೆ ನಿನಗೆ ಕಲಿಸುವುದರಲ್ಲಿ ಅನುಭವವೇ ಇಲ್ಲದಂತಿದೆ, ಇದು ದೊಡ್ಡ ಸಮಸ್ಯೆಯಾಗಬಹುದು.\n ಒಂದು ಆಯ್ಕೆಯಾಗಿ, ನಾವು ನಿಮಗೆ ಮೊದಲ ೬ ತಿಂಗಳ ಕೆಲಸದ ತರಬೇತಿಯನ್ನು ನೀಡಬಹುದು ಆದರೆ ನೀವು ಕನಿಷ್ಟ 3 ವರ್ಷಗಳವರೆಗೆ ನಮ್ಮೊಂದಿಗೆ ಒಪ್ಪಂದಕ್ಕೆ ಸಹಿ ಹಾಕಬೇಕಾಗುತ್ತದೆ.",
            "te": "“రజియా నువ్వు తెలివైన అమ్మాయిలా ఉన్నావు, కానీ నీకు టీచింగ్‌లో అనుభవం లేనట్లుంది.. ఇది పెద్ద సమస్య కావచ్చు.\n ఒక ఎంపికగా, మేము మీకు ఉద్యోగంలో మొదటి 6 నెలల శిక్షణ ఇవ్వగలము, అయితే మీరు కనీసం 3 సంవత్సరాల పాటు మాతో ఒప్పందంపై సంతకం చేయాలి.\"",
            "or": "ରଜିଆ ତୁମେ ଜଣେ ବୁଦ୍ଧିମାନ ଝିଅ ପରି ମନେହୁଏ, କିନ୍ତୁ ଲାଗୁଛି ଯେ ଶିକ୍ଷାଦାନରେ ତୁମର କୌଣସି ଅଭିଜ୍ଞତା ନାହିଁ । ଏହା ଏକ ବଡ ସମସ୍ୟା ହୋଇପାରେ । ଏକ ବିକଳ୍ପ ଭାବରେ, ଆମେ ଆପଣଙ୍କୁ ଚାକିରିରେ ପ୍ରଥମ 6 ମାସର ତାଲିମ ଦେଇପାରିବା କିନ୍ତୁ ଆପଣଙ୍କୁ ସର୍ବନିମ୍ନ 3 ବର୍ଷ ପାଇଁ ଆମ ସହିତ ଏକ ଚୁକ୍ତିନାମା ସ୍ୱାକ୍ଷର କରିବାକୁ ପଡିବ ।",
            "as": "ৰাজিয়া আপুনি এজনী বুদ্ধিমান ছোৱালী যেন লাগিছে, কিন্তু এনে লাগিছে যেন আপোনাৰ শিক্ষাদানৰ কোনো অভিজ্ঞতা নাই। এইটো এটা ডাঙৰ সমস্যা হ'ব পাৰে। ইয়াৰ\n বিকল্প হিচাপে, আমি আপোনাক চাকৰিটোৰ প্ৰথম 6 মাহৰ প্ৰশিক্ষণ দিব পাৰোঁ কিন্তু আপুনি অতিকমেও 3 বছৰৰ বাবে আমাৰ সৈতে চুক্তি কৰিব লাগিব।",
            "gu": "રઝિયા, એવું લાગે છે કે તું બુદ્ધિશાળી છોકરી છે, પણ તને ભણાવવાનો કોઈ અનુભવ નથી. આ એક મોટી સમસ્યા હોઈ શકે છે.\nએક વિકલ્પ તરીકે, અમે તમને નોકરી પર પ્રથમ 6 મહિનાની તાલીમ આપી શકીએ છીએ પરંતુ તારે અમારી સાથે ઓછામાં ઓછા 3 વર્ષ માટે કરાર પર હસ્તાક્ષર કરવા પડશે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_1_4",
            "hi": "C2_1_4",
            "ka": "C2_1_4",
            "te": "C2_1_4",
            "or": "C2_1_4",
            "as": "C2_1_4",
            "gu": "C2_1_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia is confused, she is excited to learn but there are too many questions coming to her mind.",
            "hi": "रजिया कन्फ्यूज हो जाती है, वह सीखने के लिए उत्साहित है लेकिन उसके मन बहुत से सवाल उठ रहे हैं।",
            "ka": "ರಝಿಯಾ ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದಾಳೆ, ಅವಳು ಕಲಿಯಲು ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ ಆದರೆ ಅವಳ ಮನಸ್ಸಿನಲ್ಲಿ ಹಲವಾರು ಪ್ರಶ್ನೆಗಳು ಬರುತ್ತಿವೆ.",
            "te": "రజియా అయోమయంగా ఉంది, ఆమె నేర్చుకోవడానికి సంతోషంగా ఉంది, కానీ ఆమె మనసులో చాలా ప్రశ్నలు వస్తున్నాయి.",
            "or": "ରଜିଆ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛନ୍ତି, ସେ ଶିଖିବାକୁ ଉତ୍ସାହିତ କିନ୍ତୁ ତାଙ୍କ ମନକୁ ବହୁତ ପ୍ରଶ୍ନ ଆସୁଛି ।",
            "as": "ৰাজিয়া বিমোৰত পৰিছে, তাই শিকিবলৈ উৎসাহিত হৈ আছে কিন্তু তাইৰ মনলৈ বহুতো প্ৰশ্ন আহি আছে।",
            "gu": "રઝિયા મૂંઝવણમાં મુકાઇ જાય છે, તે શીખવા માટે ઉત્સાહિત છે પરંતુ તેના મનમાં ઘણા બધા પ્રશ્નો ઊભા થાય છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_1_5",
            "hi": "C2_1_5",
            "ka": "C2_1_5",
            "te": "C2_1_5",
            "or": "C2_1_5",
            "as": "C2_1_5",
            "gu": "C2_1_5"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She thinks about her mother, the family’s reaction, the pressure to get married and if she will even like teaching.",
            "hi": "वह अपनी माँ और परिवार इस पर कैसे प्रतिक्रिया करेंगे, शादी करने के दबाव और अगर उसे सिखाना पसंद नहीं आया तो क्या होगा ऐसे कई मुद्दों के बारे में सोचती हैं।",
            "ka": "ಅವಳು ತನ್ನ ತಾಯಿ, ಕುಟುಂಬದ ಪ್ರತಿಕ್ರಿಯೆ, ಮದುವೆಯಾಗಲು ಒತ್ತಡ ಮತ್ತು ಅವಳು ಬೋಧನೆಯನ್ನು ಇಷ್ಟಪಡುವ ಬಗ್ಗೆ ಯೋಚಿಸುತ್ತಾಳೆ.",
            "te": "ఆమె తన తల్లి గురించి, కుటుంబం యొక్క ప్రతిస్పందన గురించి, పెళ్లి చేసుకోవాలనే ఒత్తిడి గురించి మరియు ఆమె బోధనను ఇష్టపడుతుందా అనే దాని గురించి ఆలోచిస్తుంది.",
            "or": "ସେ ତାଙ୍କ ମା, ପରିବାରର ପ୍ରତିକ୍ରିୟା, ବିବାହ କରିବାର ଚାପ ଏବଂ ଯଦି ସେ ଶିକ୍ଷାଦାନ ପସନ୍ଦ କରିବେ ସେ ବିଷୟରେ ଚିନ୍ତା କରନ୍ତି ।",
            "as": "যদিও তাই শিকাই ভাল পায়, তাই তাইৰ মাকৰ বিষয়ে, পৰিয়ালৰ প্ৰতিক্ৰিয়া, বিয়া হোৱাৰ চাপ এইবোৰৰ বিষয়ে চিন্তা কৰে।",
            "gu": "તે વિચારે છે કે તે તેની માતા, પરિવારની પ્રતિક્રિયા, લગ્ન કરવા માટેના દબાણ અને તેને ભણાવવાનું પણ ગમે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "C2_1_6(D)",
            "hi": "C2_1_6(D)",
            "ka": "C2_1_6(D)",
            "te": "C2_1_6(D)",
            "or": "C2_1_6(D)",
            "as": "C2_1_6(D)",
            "gu": "C2_1_6(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Razia wonders if the other job options were better. She needs to decide if she should accept this job or apply to the other roles. \n\n What should she do?",
            "hi": "रजिया सोचती है कि क्या अन्य जॉब ऑप्शन्स इससे बेहतर हो सकते हैं। उसे यह तय करना होगा कि यह जॉब स्वीकार करनी चाहिए या किसी अन्य जॉब के लिए अप्लाई करना चाहिए। \n \n  उसे क्या करना चाहिए?",
            "ka": "\"ಇತರ ಉದ್ಯೋಗ ಆಯ್ಕೆಗಳು ಉತ್ತಮವಾಗಿದ್ದವೇ ಎಂದು ರಝಿಯಾ ಆಶ್ಚರ್ಯ ಪಡುತ್ತಾಳೆ. ಅವಳು ಈ ಕೆಲಸವನ್ನು ಒಪ್ಪಿಕೊಳ್ಳಬೇಕೇ ಅಥವಾ ಇತರ ಪಾತ್ರಗಳಿಗೆ ಅನ್ವಯಿಸಬೇಕೇ ಎಂದು ಅವಳು ನಿರ್ಧರಿಸಬೇಕು.\n \n  ಅವಳು ಏನು ಮಾಡಬೇಕು?\"",
            "te": "\"ఇతర ఉద్యోగ ఎంపికలు బాగున్నాయా అని రజియా ఆశ్చర్యపోతోంది. ఆమె ఈ ఉద్యోగాన్ని అంగీకరించాలా లేదా ఇతర పాత్రలకు దరఖాస్తు చేయాలా అనేది ఆమె నిర్ణయించుకోవాలి.\n \n  ఆమె ఏమి చేయాలి?\"",
            "or": "ରଜିଆ ଭାବନ୍ତି ଯେ ଅନ୍ୟ ଚାକିରି ବିକଳ୍ପଗୁଡ଼ିକ ଭଲ କି? ସେ ଏହି ଚାକିରି ଗ୍ରହଣ କରିବା ଉଚିତ କି ଅନ୍ୟ ଭୂମିକାରେ ଆବେଦନ କରିବା ଉଚିତ୍ ତାହା ସ୍ଥିର କରିବା ଆବଶ୍ୟକ । ସେ କ'ଣ କରିବା ଉଚିତ୍?",
            "as": "ৰাজিয়াই আচৰিত হৈ ভাবে যে চাকৰিৰ আন বিকল্পবোৰ ভাল আছিল নেকি। তাই সিদ্ধান্ত ল'ব লাগিব যে তাই এই চাকৰিটো গ্ৰহণ কৰিব লাগে নে আন পদত এপ্লাই কৰিব লাগে। \n তাই কি কৰা উচিত?",
            "gu": "રઝિયા આશ્ચર્ય પામી રહી છે કે શું અન્ય નોકરીના વિકલ્પો વધુ સારા હતા. તેણીએ નક્કી કરવાની જરૂર છે કે તેણીએ આ નોકરી સ્વીકારવી જોઈએ કે અન્ય ભૂમિકાઓ માટે અરજી કરવી જોઈએ.\n\n તેણીએ શું કરવું જોઈએ?\""
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Continue with the decision and take the job and training",
            "hi": "निर्णय पर आगे बढ़कर यह जॉब स्वीकार करना चाहिए और ट्रेनिंग प्राप्त करनी चाहिए",
            "ka": "ನಿರ್ಧಾರವನ್ನು ಮುಂದುವರಿಸಿ ಮತ್ತು ಕೆಲಸ ಮತ್ತು ತರಬೇತಿಯನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ",
            "te": "నిర్ణయాన్ని కొనసాగించండి మరియు ఉద్యోగం మరియు శిక్షణ తీసుకోండి",
            "or": "ନିଷ୍ପତ୍ତି ଜାରି ରଖନ୍ତୁ ଏବଂ ଚାକିରି ଏବଂ ତାଲିମ ନିଅନ୍ତୁ",
            "as": "সিদ্ধান্তটো অব্যাহত ৰাখা আৰু চাকৰি আৰু প্ৰশিক্ষণ লোৱা",
            "gu": "નિર્ણય સાથે ચાલુ રાખો અને નોકરી અને તાલીમ લો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Leave and apply for the eco-resort role",
            "hi": "मना करना चाहिए और इको-रिसोर्ट रोल के लिए अप्लाई करना चाहिए",
            "ka": "ಇದನ್ನು ಬಿಟ್ಟು , ಪರಿಸರ-ರೆಸಾರ್ಟ್ ಪಾತ್ರಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ",
            "te": "వదిలేసి , పర్యావరణ రిసార్ట్ పాత్ర కు దరఖాస్తు చేయాలి",
            "or": "ଛାଡନ୍ତୁ ଏବଂ ଇକୋ-ରିସର୍ଟ ଭୂମିକା ପାଇଁ ଆବେଦନ କରନ୍ତୁ",
            "as": "এৰি যোৱা আৰু ইক'-ৰিজৰ্ট পদৰ বাবে এপ্লাই কৰা",
            "gu": "છોડો અને અરજી કરો ઇકો-રિસોર્ટની ભૂમિકા માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Leave and apply for the NGO role",
            "hi": "मना करना चाहिए और एनजीओ के रोल के लिए अप्लाई करना चाहिए",
            "ka": "ಬಿಟ್ಟು ಎನ್‌ಜಿಒ ಪಾತ್ರಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ",
            "te": "వదిలేసి NGO పాత్ర కోసం దరఖాస్తు చేయాలి",
            "or": "ଛାଡନ୍ତୁ ଏବଂ NGO ଭୂମିକା ପାଇଁ ଆବେଦନ କରନ୍ତୁ",
            "as": "এৰি দিয়া আৰু এনজিঅ'ৰ পদৰ বাবে এপ্লাই কৰা",
            "gu": "છોડો અને અરજી કરો NGOની ભૂમિકા માટે"
          }
        }
      ]
    },
    "scene26": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "C3_1_1_Take up the teaching job",
            "hi": "पढ़ाने का जॉब स्वीकार करना चाहिए।",
            "ka": "C3_1_1_ಬೋಧನಾ ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ",
            "te": "C3_1_1_టీచింగ్ ఉద్యోగంలో చేరండి",
            "or": "C3_1_1_ଶିକ୍ଷାଦାନ ଚାକିରି ଗ୍ରହଣ କରନ୍ତୁ",
            "as": "C3_1_1_শিক্ষকতাৰ চাকৰি লোৱা",
            "gu": "C3_1_1_શિક્ષકની નોકરી સ્વીકારો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia discusses with her family and takes up teaching job. The first year is very tough for Razia.",
            "hi": "रजिया अपने परिवार के साथ चर्चा करती है और पढ़ाने के जॉब को स्वीकार करती हैं। पहला साल रजिया के लिए काफी कठिन था।",
            "ka": "ರಝಿಯಾ ತನ್ನ ಕುಟುಂಬದೊಂದಿಗೆ ಚರ್ಚಿಸುತ್ತಾಳೆ ಮತ್ತು ಶಿಕ್ಷಕಿಯ ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾಳೆ. ರಝಿಯಾಗೆ ಮೊದಲ ವರ್ಷ ತುಂಬಾ ಕಠಿಣವಾಗಿದೆ.",
            "te": "రజియా తన కుటుంబంతో చర్చించి టీచింగ్ ఉద్యోగం తీసుకుంటుంది. రజియాకి మొదటి సంవత్సరం చాలా కష్టం.",
            "or": "ରଜିଆ ତାଙ୍କ ପରିବାର ସହିତ ଆଲୋଚନା କରନ୍ତି ଏବଂ ଶିକ୍ଷାଦାନ ଚାକିରି କରନ୍ତି । ରଜିଆଙ୍କ ପାଇଁ ପ୍ରଥମ ବର୍ଷ ବହୁତ କଠିନ ।",
            "as": "ৰাজিয়াই তাইৰ পৰিয়ালৰ সৈতে আলোচনা কৰে আৰু শিক্ষকতাৰ চাকৰি লয়। প্ৰথম বছৰটো ৰাজিয়াৰ বাবে বহুত কঠিন আছিল।",
            "gu": "રઝિયા તેના કુટુંબ સાથે ચર્ચા કરે છે અને શિક્ષકની નોકરી કરે છે. રઝિયા માટે પહેલું વર્ષ ઘણું અઘરું છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "C3_1_2",
            "hi": "C3_1_2",
            "ka": "C3_1_2",
            "te": "C3_1_2",
            "or": "C3_1_2",
            "as": "C3_1_2",
            "gu": "C3_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Some teachers are helpful and some give her more work as she is new. But she enjoys teaching and her students start liking the class.",
            "hi": "कुछ शिक्षक उसकी मदद करते तो कुछ उसे ज्यादा काम देते क्योंकि वह यहाँ नई थी। लेकिन उसे पढ़ाना अच्छा लगता है और विद्यार्थियों को भी उसकी कक्षा को पसंद करते हैं।",
            "ka": "ಕೆಲವು ಶಿಕ್ಷಕರು ಸಹಾಯ ಮಾಡುತ್ತಾರೆ ಮತ್ತು ಕೆಲವರು ಅವಳು ಹೊಸಬಳು ಎಂದು ಹೆಚ್ಚಿನ ಕೆಲಸವನ್ನು ನೀಡುತ್ತಾರೆ. ಆದರೆ ಅವಳು ಬೋಧನೆಯನ್ನು ಆನಂದಿಸುತ್ತಾಳೆ ಮತ್ತು ಅವಳ ವಿದ್ಯಾರ್ಥಿಗಳು ತರಗತಿಯನ್ನು ಇಷ್ಟಪಡುತ್ತಾರೆ.",
            "te": "కొంతమంది ఉపాధ్యాయులు సహాయపడతారు మరియు కొందరు ఆమె కొత్తది కాబట్టి ఆమెకు ఎక్కువ పనిని ఇస్తారు. కానీ ఆమె బోధనను ఆనందిస్తుంది మరియు ఆమె విద్యార్థులు తరగతిని ఇష్టపడటం ప్రారంభిస్తారు.",
            "or": "କିଛି ଶିକ୍ଷକ ସାହାଯ୍ୟକାରୀ ଏବଂ କେତେକ ତାଙ୍କୁ ନୂତନ ହୋଇଥିବାରୁ ଅଧିକ କାମ ଦିଅନ୍ତି । କିନ୍ତୁ ସେ ଶିକ୍ଷାଦାନକୁ ଉପଭୋଗ କରନ୍ତି ଏବଂ ତାଙ୍କ ଛାତ୍ରମାନେ ଶ୍ରେଣୀକୁ ପସନ୍ଦ କରିବା ଆରମ୍ଭ କରନ୍ତି ।",
            "as": "কিছুমান শিক্ষকে তাইক সহায় কৰে আৰু কিছুমানে তাইক অধিক কাম দিয়ে কিয়নো তাই নতুন। কিন্তু তাই শিকাই ভাল পাইছে আৰু তাইৰ শিক্ষাৰ্থীসকলে শ্ৰেণীটো ভাল পাবলৈ আৰম্ভ কৰিছে।",
            "gu": "કેટલાક શિક્ષકો મદદરૂપ બને છે અને કેટલાક તેને નવી સમજીને વધુ કામ આપે છે. પરંતુ તેને શીખવામાં આનંદ મળે છે અને તેના વિદ્યાર્થીઓ વર્ગને પસંદ કરવાનું શરૂ કરે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "C3_2_1",
            "hi": "C3_2_1",
            "ka": "C3_2_1",
            "te": "C3_2_1",
            "or": "C3_2_1",
            "as": "C3_2_1",
            "gu": "C3_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She gets permission from the coordinator and the principal to take the high school students on nature writing walks and it becomes popular with the students and the parents.",
            "hi": "उसे कोऑर्डिनेटर और प्रिंसिपल से हाई स्कूल के विद्यार्थियों को नैचर राइटिंग वॉक के लिए अनुमति मिलती हैं और वह विद्यार्थी और उनके माता-पिता के बीच लोकप्रिय हो जाती है।",
            "ka": "ಪ್ರೌಢಶಾಲಾ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಪ್ರಕೃತಿ ಬರವಣಿಗೆಗೆ ಕರೆದೊಯ್ಯಲು ಸಂಯೋಜಕರು ಮತ್ತು ಪ್ರಾಂಶುಪಾಲರಿಂದ ಅನುಮತಿ ಪಡೆಯುತ್ತಾಳೆ ಮತ್ತು ಅದು ವಿದ್ಯಾರ್ಥಿಗಳು ಮತ್ತು ಪೋಷಕರಲ್ಲಿ ಜನಪ್ರಿಯವಾಗುತ್ತದೆ.",
            "te": "ఆమె హైస్కూల్ విద్యార్థులను ప్రకృతి రచన నడకలకు తీసుకెళ్లడానికి సమన్వయకర్త మరియు ప్రిన్సిపాల్ నుండి అనుమతి పొందుతుంది మరియు ఇది విద్యార్థులు మరియు తల్లిదండ్రులలో ప్రాచుర్యం పొందింది.",
            "or": "ସେ ସଂଯୋଜକ ଏବଂ ପ୍ରଧାନ ଶିକ୍ଷକଙ୍କ ଠାରୁ ପ୍ରକୃତି ଲେଖା ପଦଯାତ୍ରାରେ ହାଇସ୍କୁଲ ଛାତ୍ରମାନଙ୍କୁ ନେବାକୁ ଅନୁମତି ପାଆନ୍ତି ଏବଂ ଏହା ଦ୍ଵାରା ଛାତ୍ରଛାତ୍ରୀ ଏବଂ ପିତାମାତାଙ୍କ ମଧ୍ୟରେ ଲୋକପ୍ରିୟ ହୋଇଯାଆନ୍ତି ।",
            "as": "তাই সমন্বয়ক আৰু অধ্যক্ষৰ পৰা উচ্চ বিদ্যালয়ৰ শিক্ষাৰ্থীসকলক প্ৰকৃতিৰ ওপৰত লিখিবলৈ খোজকঢ়াই লৈ যোৱাৰ অনুমতি লাভ কৰে আৰু এইটো শিক্ষাৰ্থী আৰু অভিভাৱকসকলৰ মাজত জনপ্ৰিয় হৈ পৰে।",
            "gu": "તેને પરવાનગી મળે છે સંયોજક અને આચાર્ય પાસેથી હાઇસ્કૂલના વિદ્યાર્થીઓને પ્રકૃતિ પર લઈ જવાની, લેખન કરવાની અને તે વિદ્યાર્થીઓ અને વાલીઓ વચ્ચે લોકપ્રિય બને છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "C3_2_2",
            "hi": "C3_2_2",
            "ka": "C3_2_2",
            "te": "C3_2_2",
            "or": "C3_2_2",
            "as": "C3_2_2",
            "gu": "C3_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She tells a lot of stories about the Sundarbans and many students are planning to visit the place with their parents.",
            "hi": "वह सुंदरबन के बारे में कई कहानियां सुनाती है और कई बच्चे अपने माता-पिता के साथ उस जगह को भेट देने कि सोचते हैं।",
            "ka": "ಅವರು ಸುಂದರಬನ್ಸ್ ಬಗ್ಗೆ ಸಾಕಷ್ಟು ಕಥೆಗಳನ್ನು ಹೇಳುತ್ತಾಳೆ ಮತ್ತು ಅನೇಕ ವಿದ್ಯಾರ್ಥಿಗಳು ತಮ್ಮ ಪೋಷಕರೊಂದಿಗೆ ಸ್ಥಳಕ್ಕೆ ಭೇಟಿ ನೀಡಲು ಯೋಜಿಸುತ್ತಿದ್ದಾರೆ.",
            "te": "ఆమె సుందర్బన్స్ గురించి చాలా కథలు చెబుతుంది.మరియు చాలా మంది విద్యార్థులు వారి తల్లిదండ్రులతో కలిసి ఆ స్థలాన్ని సందర్శించేందుకు ప్లాన్ చేస్తున్నారు.",
            "or": "ସେ ସୁନ୍ଦରବନ ବିଷୟରେ ଅନେକ କାହାଣୀ କୁହନ୍ତି ଏବଂ ଅନେକ ଛାତ୍ର ସେମାନଙ୍କ ପିତାମାତାଙ୍କ ସହ ଏହି ସ୍ଥାନ ପରିଦର୍ଶନ କରିବାକୁ ଯୋଜନା କରନ୍ତି ।",
            "as": "তাই সুন্দৰবনৰ বিষয়ে বহুতো কাহিনী কয় আৰু বহুতো শিক্ষাৰ্থীয়ে তেওঁলোকৰ অভিভাৱকৰ সৈতে ঠাইখন ভ্ৰমণ কৰাৰ পৰিকল্পনা কৰে।",
            "gu": "તે સુંદરવન વિશે ઘણી બધી વાર્તાઓ કહે છે અને ઘણા વિદ્યાર્થીઓ તેમના માતાપિતા સાથે તે સ્થળ પર જવાનું આયોજન કરે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ1",
            "hi": "MCQ1",
            "ka": "MCQ1",
            "te": "MCQ1",
            "or": "MCQ1",
            "as": "MCQ1",
            "gu": "MCQ1"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia does internet research to find and gain a scholarship. Internet research helps us gain basic information about careers and industries. It also helps us know about new opportunities. Select the statement which is TRUE:",
            "hi": "रजिया इंटरनेट पर रिसर्च करके स्कोलरशिप के बारे में जानकारी प्राप्त करती है। इंटरनेट रिसर्च हमें करियर और उद्योगों के बारे में मुलभुत जानकारी प्राप्त करने में मदद करता है। यह हमें नए अवसरों के बारे में जानकारी प्राप्त करने में भी मदद करता है। सही विधान को चुनें:",
            "ka": "ವಿದ್ಯಾರ್ಥಿವೇತನವನ್ನು ಹುಡುಕಲು ಮತ್ತು ಪಡೆಯಲು ರಝಿಯಾ ಇಂಟರ್ನೆಟ್ ಸಂಶೋಧನೆ ಮಾಡುತ್ತಾಳೆ. ಇಂಟರ್ನೆಟ್ ಸಂಶೋಧನೆಯು ವೃತ್ತಿ ಮತ್ತು ಉದ್ಯಮಗಳ ಬಗ್ಗೆ ಮೂಲಭೂತ ಮಾಹಿತಿಯನ್ನು ಪಡೆಯಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಹೊಸ ಅವಕಾಶಗಳ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಲು ಇದು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನಿಜವಾಗಿರುವ ಹೇಳಿಕೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ:",
            "te": "రజియా స్కాలర్‌షిప్‌ (ఉపకార వేతనం) గురించి కనుగొని దానిని పొందేందుకు ఇంటర్నెట్ పరిశోధన చేస్తుంది.కెరీర్‌లు మరియు పరిశ్రమల గురించి ప్రాథమిక సమాచారాన్ని పొందడంలో ఇంటర్నెట్ పరిశోధన మాకు సహాయపడుతుంది. ఇది కొత్త అవకాశాల గురించి తెలుసుకోవడానికి కూడా మాకు సహాయపడుతుంది:ఏది నిజమైన స్టేట్ మెంటో దానిని ఎంచుకోండి",
            "or": "ରଜିଆ ଏକ ଛାତ୍ରବୃତ୍ତି ଖୋଜିବା ଏବଂ ହାସଲ କରିବା ପାଇଁ ଇଣ୍ଟରନେଟ୍ ରେ ଅନୁସନ୍ଧାନ କରନ୍ତି । ଇଣ୍ଟରନେଟ୍ ରେ ଅନୁସନ୍ଧାନ ଆମକୁ କ୍ୟାରିୟର ଏବଂ ଶିଳ୍ପ ବିଷୟରେ ମୌଳିକ ସୂଚନା ହାସଲ କରିବାରେ ସାହାଯ୍ୟ କରେ । ଏହା ଆମକୁ ନୂତନ ସୁଯୋଗ ବିଷୟରେ ଜାଣିବାରେ ମଧ୍ୟ ସାହାଯ୍ୟ କରେ । ଠିକ ଥିବା ଉକ୍ତିଟି ଚୟନ କରନ୍ତୁ:",
            "as": "ৰাজিয়াই বৃত্তি বিচাৰিবলৈ আৰু লাভ কৰিবলৈ ইণ্টাৰনেট ব্যৱহাৰ কৰে। ইণ্টাৰনেটৰ ব্যৱহাৰে আমাক কেৰিয়াৰ আৰু উদ্যোগৰ বিষয়ে মৌলিক তথ্য আহৰণ কৰাত সহায় কৰে। ই আমাক নতুন সুযোগৰ বিষয়ে জনাত সহায় কৰে। সঁচা উক্তিটো বাছনি কৰা:",
            "gu": "શિષ્યવૃત્તિની  મેળવવા માટે રઝિયા ઇન્ટરનેટ જોવે છે. ઈન્ટરનેટના લીધે અમને કારકિર્દી અને ઉદ્યોગો વિશે મૂળભૂત માહિતી મેળવવામાં મદદ કરે છે. તે અમને નવી તકો વિશે જાણવામાં પણ મદદ કરે છે. જે સાચું વાક્ય છે તે પસંદ કરો:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "The internet is the only way to do research and find information.",
            "hi": "रिसर्च करने और जानकारी प्राप्त करने के लिए केवल इंटरनेट ही एकमात्र मार्ग है",
            "ka": "ಸಂಶೋಧನೆ ಮಾಡಲು ಮತ್ತು ಮಾಹಿತಿಯನ್ನು ಹುಡುಕಲು ಇಂಟರ್ನೆಟ್ ಏಕೈಕ ಮಾರ್ಗವಾಗಿದೆ.",
            "te": "పరిశోధనలు చేయడానికి మరియు సమాచారాన్ని కనుగొనడానికి ఇంటర్నెట్ మాత్రమే ఏకైక మార్గం.",
            "or": "ଅନୁସନ୍ଧାନ କରିବା ଏବଂ ସୂଚନା ଖୋଜିବା ପାଇଁ ଇଣ୍ଟରନେଟ୍ ହେଉଛି ଏକମାତ୍ର ଉପାୟ ।",
            "as": "গৱেষণা আৰু তথ্য বিচাৰি উলিওৱাৰ একমাত্ৰ উপায় হৈছে ইণ্টাৰনেট।",
            "gu": "ઈન્ટરનેટ એ કંઈક શોધવા અને માહિતી મેળવવાનો એક સારો વિકલ્પ છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "To do research we can talk to people and read books and newspapers",
            "hi": "रिसर्च करने के लिए हम लोगों से बात कर सकते हैं और किताबें और अख़बार पढ़ सकते हैं",
            "ka": "ಸಂಶೋಧನೆ ಮಾಡಲು ನಾವು ಜನರೊಂದಿಗೆ ಮಾತನಾಡಬಹುದು ಮತ್ತು ಪುಸ್ತಕಗಳು ಮತ್ತು ಪತ್ರಿಕೆಗಳನ್ನು ಓದಬಹುದು",
            "te": "పరిశోధన చేయడానికి మనం ప్రజలతో మాట్లాడవచ్చు మరియు పుస్తకాలు మరియు వార్తాపత్రికలు చదవవచ్చు",
            "or": "ଅନୁସନ୍ଧାନ କରିବା ପାଇଁ ଆମେ ଲୋକଙ୍କ ସହ କଥା ହୋଇପାରିବା ଏବଂ ପୁସ୍ତକ ଏବଂ ଖବରକାଗଜ ପଢିପାରିବା ।",
            "as": "গৱেষণা কৰিবলৈ আমি মানুহৰ সৈতে কথা পাতিব পাৰোঁ আৰু কিতাপ আৰু বাতৰি কাকত পঢ়িব পাৰোঁ",
            "gu": "શોધખોળ કરવા માટે આપણે લોકો સાથે વાત કરી શકીએ છીએ અને પુસ્તકો અને સમાચાર પત્રો પણ વાંચી શકીએ છીએ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Research is required only when one needs to find a scholarship to study",
            "hi": "रिसर्च केवल तभी किया जाता है जब किसी को पढ़ाई के लिए स्कोलरशिप के बारे में जानकारी चाहिए होती है।",
            "ka": "ಅಧ್ಯಯನ ಮಾಡಲು ಸ್ಕೋಲರ್ಷಿಪ್ಸ್  ಹುಡುಕಬೇಕಾದಾಗ ಮಾತ್ರ ಸಂಶೋಧನೆಯ ಅಗತ್ಯವಿದೆ",
            "te": "చదువుకోవడానికి స్కాలర్‌షిప్‌ (ఉపకార వేతనం )ను కనుగొనవలసి వచ్చినప్పుడు మాత్రమే పరిశోధన అవసరం",
            "or": "ଅନୁସନ୍ଧାନ କେବଳ ସେତେବେଳେ ଆବଶ୍ୟକ ହୁଏ ଯେତେବେଳେ ଜଣେ ଅଧ୍ୟୟନ ପାଇଁ ଛାତ୍ରବୃତ୍ତି ଖୋଜନ୍ତି ।",
            "as": "অধ্যয়নৰ বাবে বৃত্তি বিচাৰিবৰ বাবেহে গৱেষণাৰ প্ৰয়োজন",
            "gu": "જ્યારે કોઈને અભ્યાસ માટે શિષ્યવૃત્તિ શોધવાની જરૂર હોય ત્યારે સંશોધન કરવું જરૂરી છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Think again! To do research we can talk to people and read books and newspapers.",
            "hi": "फिर से विचार करें! रिसर्च करने के लिए हम लोगो के साथ बात कर सकते हैं और और किताबें और अख़बार पढ़ सकते हैं।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಸಂಶೋಧನೆ ಮಾಡಲು ನಾವು ಜನರೊಂದಿಗೆ ಮಾತನಾಡಬಹುದು ಮತ್ತು ಪುಸ್ತಕಗಳು ಮತ್ತು ಪತ್ರಿಕೆಗಳನ್ನು ಓದಬಹುದು.",
            "te": "మళ్లీ ఆలోచించండి! పరిశోధన చేయడానికి మనం ప్రజలతో మాట్లాడవచ్చు మరియు పుస్తకాలు మరియు వార్తాపత్రికలు చదవవచ్చు.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ଗବେଷଣା କରିବା ପାଇଁ ଆମେ ଲୋକଙ୍କ ସହ କଥା ହୋଇପାରିବା ଏବଂ ପୁସ୍ତକ ଏବଂ ଖବରକାଗଜ ପଢିପାରିବା ।",
            "as": "আকৌ চিন্তা কৰা! গৱেষণা কৰিবলৈ আমি মানুহৰ সৈতে কথা পাতিব পাৰোঁ আৰু কিতাপ আৰু বাতৰি কাকত পঢ়িব পাৰোঁ।",
            "gu": "ફરીથી વિચારો ! સંશોધન કરવા માટે આપણે લોકો સાથે વાત કરી શકીએ છીએ અને પુસ્તકો અને સમાચાર પત્રો પણ વાંચી શકીએ છીએ."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "शाबाश!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "గొప్ప పని!",
            "or": "ବହୁତ ଭଲ!",
            "as": "ভাল কাম!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! Research can help us be aware of opportunities throughout our careers.",
            "hi": "फिर से विचार करें! रिसर्च हमें अपने करियर के अवसरों के बारे में जागरूक होने के लिए मदद करता है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ನಮ್ಮ ವೃತ್ತಿಜೀವನದುದ್ದಕ್ಕೂ ಅವಕಾಶಗಳ ಬಗ್ಗೆ ಅರಿವು ಮೂಡಿಸಲು ಸಂಶೋಧನೆ ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
            "te": "మళ్లీ ఆలోచించండి! మన కెరీర్‌లో అవకాశాల గురించి తెలుసుకోవడంలో పరిశోధన సహాయపడుతుంది.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ଅନୁସନ୍ଧାନ ଆମକୁ ଆମର କ୍ୟାରିୟରରେ ସୁଯୋଗ ବିଷୟରେ ସଚେତନ ହେବାରେ ସାହାଯ୍ୟ କରିପାରିବ ।",
            "as": "আকৌ চিন্তা কৰা! গৱেষণাই আমাক আমাৰ সমগ্ৰ কেৰিয়াৰত সুযোগৰ বিষয়ে সজাগ হোৱাত সহায় কৰিব পাৰে।",
            "gu": "ફરીથી વિચારો ! સંશોધન અમારી સમગ્ર કારકિર્દી દરમિયાન તકોથી જાણકાર રહેવામાં મદદ કરી શકે છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ4",
            "hi": "MCQ4",
            "ka": "MCQ4",
            "te": "MCQ4",
            "or": "MCQ4",
            "as": "MCQ4",
            "gu": "MCQ4"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia negotiates with her family to allow her to study in the city. Negotiation helps people with differing points of view to reach an agreement to finalise a decision. In your life, where do you think negotiation plays a role?",
            "hi": "रजिया शहर में पढ़ने के लिए जाने के लिए अपने माता-पिता के साथ निगोशिएट करती है। निगोशिएट करने से अलग विचारों के लोगों को किसी एक निर्णय पर सहमत होने में मदद होती है। आपकी जिंदगी में, निगोशिएट करना कहां काम आता है ऐसा आपको लगता हैं?",
            "ka": "ರಝಿಯಾ ತನ್ನ ಕುಟುಂಬದೊಂದಿಗೆ ನಗರದಲ್ಲಿ ಓದಲು ಅವಕಾಶ ನೀಡಲು ಮಾತುಕತೆ ನಡೆಸುತಾಳೆ. ನಿರ್ಧಾರವನ್ನು ಅಂತಿಮಗೊಳಿಸಲು ಒಪ್ಪಂದವನ್ನು ತಲುಪಲು ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳನ್ನು ಹೊಂದಿರುವ ಜನರಿಗೆ ಮಾತುಕತೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನಿಮ್ಮ ಜೀವನದಲ್ಲಿ, ಮಾತುಕತೆಯು ಎಲ್ಲಿ ಒಂದು ಪಾತ್ರವನ್ನು ವಹಿಸುತ್ತದೆ ಎಂದು ನೀವು ಭಾವಿಸುತ್ತೀರಿ?",
            "te": "రజియా నగరంలో చదువుకోవడానికి అనుమతించమని తన కుటుంబంతో చర్చలు జరిపింది .భిన్నమైన అభిప్రాయాలు ఉన్న వ్యక్తులు ఒక నిర్ణయాన్ని ఖరారు చేయడానికి ఒక ఒప్పందాన్ని చేరుకోవడానికి చర్చలు సహాయపడతాయి. మీ జీవితంలో, నెగోషియేషన్ ( చర్చ) ఎక్కడ పాత్ర పోషిస్తాయని మీరు అనుకుంటున్నారు?",
            "or": "ରଜିଆ ତାଙ୍କୁ ସହରରେ ଅଧ୍ୟୟନ କରିବାକୁ ଅନୁମତି ଦେବା ପାଇଁ ତାଙ୍କ ପରିବାର ସହିତ ବୁଝାମଣା କରନ୍ତି । ବୁଝାମଣା ଭିନ୍ନ ଦୃଷ୍ଟିକୋଣ ଥିବା ଲୋକଙ୍କୁ ଏକ ନିଷ୍ପତ୍ତି ଚୂଡ଼ାନ୍ତ କରିବା ପାଇଁ ଏକ ରାଜିନାମାରେ ପହଞ୍ଚିବାରେ ସାହାଯ୍ୟ କରେ । ଆପଣଙ୍କ ଜୀବନରେ, ବୁଝାମଣା କେଉଁଠାରେ ଏକ ଭୂମିକା ଗ୍ରହଣ କରେ ବୋଲି ଆପଣ ଭାବନ୍ତି?",
            "as": "ৰাজিয়াই তাইক চহৰখনত পঢ়িবলৈ অনুমতি দিবলৈ তাইৰ পৰিয়ালৰ সৈতে বুজাবুজি কৰে। বুজাবুজিয়ে ভিন্ন দৃষ্টিভংগী থকা লোকসকলক সিদ্ধান্ত এটা চূড়ান্ত কৰিবলৈ এক চুক্তিত উপনীত হোৱাত সহায় কৰে। তোমাৰ জীৱনত, তুমি ক'ত বুজাবুজিয়ে ভূমিকা পালন কৰে বুলি ভাবা?",
            "gu": "રઝિયા તેને શહેરમાં અભ્યાસ કરવાની પરવાનગી માટે તેના પરિવાર સાથે વાટાઘાટો કરે છે. વાટાઘાટો અલગ અલગ વિચારણા ધરાવતા લોકોને નિર્ણયને અંતિમ સ્વરૂપ આપવા માટે કરાર સુધી પહોંચવામાં મદદ કરે છે. તમારા જીવનમાં, તમે શું વિચારો છો કે વાટાઘાટો ભૂમિકા ભજવે છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "To change your significant others (parents, friends etc) beliefs about your career decisions",
            "hi": "आपके करियर के निर्णय को लेकर अपने खास लोगों के (माता-पिता, दोस्त आदि) विचार बदलने के लिए",
            "ka": "ನಿಮ್ಮ ವೃತ್ತಿ ನಿರ್ಧಾರಗಳ ಬಗ್ಗೆ ನಿಮ್ಮ ಪ್ರಮುಖ ಇತರರ (ಪೋಷಕರು, ಸ್ನೇಹಿತರು ಇತ್ಯಾದಿ) ನಂಬಿಕೆಗಳನ್ನು ಬದಲಾಯಿಸಲು",
            "te": "మీ కెరీర్ నిర్ణయాల గురించి మీ ముఖ్యమైన ఇతరుల (తల్లిదండ్రులు, స్నేహితులు మొదలైనవారు) నమ్మకాలను మార్చడానికి",
            "or": "ଆପଣଙ୍କ କ୍ୟାରିୟର ନିଷ୍ପତ୍ତି ବିଷୟରେ ଆପଣଙ୍କର ଆତ୍ମୀୟମାନଙ୍କ (ପିତାମାତା, ବନ୍ଧୁ ଇତ୍ୟାଦି) ବିଶ୍ୱାସ ପରିବର୍ତ୍ତନ କରିବାକୁ",
            "as": "তুমি তোমাৰ কেৰিয়াৰৰ ওপৰত লোৱা সিদ্ধান্তৰ বিষয়ে তোমাৰ বাবে গুৰুত্বপূৰ্ণ আনসকল লোকৰ (পিতৃ-মাতৃ, বন্ধু আদি) মতামত সলনি কৰিবলৈ",
            "gu": "તમારી કારકિર્દીના નિર્ણયો વિશે તમારા નોંધપાત્ર અન્ય (માતાપિતા, મિત્રો વગેરે)ની માન્યતાઓને બદલવા માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "To gain a better salary when joining a new workplace",
            "hi": "नई जॉब में बेहतर सैलरी प्राप्त करने के लिए",
            "ka": "ಹೊಸ ಕೆಲಸದ ಸ್ಥಳಕ್ಕೆ ಸೇರಿದಾಗ ಉತ್ತಮ ಸಂಬಳವನ್ನು ಪಡೆಯಲು",
            "te": "కొత్త కార్యాలయంలో చేరినప్పుడు మెరుగైన జీతం పొందేందుకు",
            "or": "ଏକ ନୂତନ କର୍ମକ୍ଷେତ୍ରରେ ଯୋଗଦେବା ସମୟରେ ଏକ ଉତ୍ତମ ଦରମା ପାଇବାକୁ",
            "as": "নতুন কৰ্মস্থানত যোগদান কৰোঁতে ভাল দৰমহা পোৱা",
            "gu": "વધુ સારો પગાર મેળવવા માટે નવા કાર્યસ્થળે જોડાતી વખતે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "To get information about a career and the jobs within the career",
            "hi": "करियर के बारे में और करियर में उपलब्ध जॉब के बारे में जानकारी प्राप्त करने के लिए",
            "ka": "ವೃತ್ತಿ ಮತ್ತು ವೃತ್ತಿಯೊಳಗಿನ ಉದ್ಯೋಗಗಳ ಬಗ್ಗೆ ಮಾಹಿತಿ ಪಡೆಯಲು",
            "te": "కెరీర్ మరియు కెరీర్‌లోని ఉద్యోగాల గురించి సమాచారాన్ని పొందడానికి",
            "or": "ଏକ କ୍ୟାରିୟର ଏବଂ ସେହି କ୍ୟାରିୟର ମଧ୍ୟରେ ଚାକିରି ବିଷୟରେ ସୂଚନା ପାଇବା ପାଇଁ",
            "as": "কেৰিয়াৰ আৰু কেৰিয়াৰৰ ভিতৰত থকা চাকৰিৰ বিষয়ে তথ্য বিচাৰি পোৱা",
            "gu": "કારકિર્દી અને નોકરીમાં તેની અંદરની નોકરીઓ વિશે માહિતી મેળવવા માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "When making an important decision, negotiation is the process by which people who disagree on how things should be done come to an agreement that works for everyone involved.",
            "hi": "महत्वपूर्ण निर्णय लेते समय, निगोशिएट करना एक प्रक्रिया है जिसके जरिए जो लोग किसी काम को किस तरह से किया जाना चाहिए इस बात पर सहमत नहीं होते वे ऐसे किसी समझौते पर पहुँचते हैं जो इस प्रक्रिया में शामिल सबको मंजूर हो।",
            "ka": "ಪ್ರಮುಖ ನಿರ್ಧಾರವನ್ನು ಮಾಡುವಾಗ, ಸಮಾಲೋಚನೆಯು ಪ್ರಕ್ರಿಯೆಯಾಗಿದ್ದು, ವಿಷಯಗಳನ್ನು ಹೇಗೆ ಮಾಡಬೇಕು ಎಂಬುದರ ಕುರಿತು ಭಿನ್ನಾಭಿಪ್ರಾಯ ಹೊಂದಿರುವ ಜನರು ಒಳಗೊಂಡಿರುವ ಪ್ರತಿಯೊಬ್ಬರಿಗೂ ಕೆಲಸ ಮಾಡುವ ಒಪ್ಪಂದಕ್ಕೆ ಬರುತ್ತಾರೆ.",
            "te": "ఒక ముఖ్యమైన నిర్ణయం తీసుకునేటప్పుడు, నెగోషియేషన్ ( చర్చ) అంటే ఏదైనా ఎలా జరగాలి అనే దానిపై విభేదించే వ్యక్తులు పాల్గొన్న ప్రతి ఒక్కరికీ నచ్చే విధంగా ఒక ఒప్పందానికి వచ్చే ప్రక్రియ.",
            "or": "ଏକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ନିଷ୍ପତ୍ତି ନେବା ବେଳେ, ବୁଝାମଣା ହେଉଛି ସେହି ପ୍ରକ୍ରିୟା ଯାହା ଦ୍ୱାରା ସେ ବିଷୟରେ ଅସହମତ ଥିବା ଲୋକମାନେ ଜିନିଷଗୁଡିକ କିପରି କରାଯିବା ଉଚିତ୍ ତାହାକୁ ନେଇ ରାଜି ହୁଅନ୍ତି ଯାହା ଏଥିରେ ସମ୍ପୃକ୍ତ ସମସ୍ତଙ୍କ ପାଇଁ କାର୍ଯ୍ୟ କରେ ।",
            "as": "এটা গুৰুত্বপূৰ্ণ সিদ্ধান্ত লওঁতে, বুজাবুজি হৈছে এনে এক প্ৰক্ৰিয়া যাৰ দ্বাৰা কামবোৰ কেনেদৰে কৰিব লাগে সেই বিষয়ে অসন্মত হোৱা লোকসকলে এক চুক্তিত উপনীত হয় আৰু এই চুক্তিয়ে সিদ্ধান্ত লোৱা গোটেই কেইজনকে সহায় কৰে।",
            "gu": "મહત્વપૂર્ણ નિર્ણય લેતી વખતે, વાટાઘાટો એ એવી પ્રક્રિયા છે કે જેના દ્વારા વસ્તુઓ કેવી રીતે કરવી જોઈએ તે અંગે અસંમત હોય તેવા લોકો એક કરાર પર આવે છે જે સામેલ દરેક માટે કાર્ય કરે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job! Negotiation allows space for the people involved in a decision to see various choices possible and move forward in a manner that is equal for everyone involved.",
            "hi": "शाबाश! निगोशिएशन के कारण निर्णय लेने की प्रक्रिया में शामिल सभी लोगों को भिन्न संभाव्य विकल्पों के बारे में जानकारी मिलती हैं और वे इस दिशा में आगे बढ़ते जो सबके लिए समान हो।",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ! ಸಮಾಲೋಚನೆಯು ನಿರ್ಧಾರದಲ್ಲಿ ತೊಡಗಿರುವ ಜನರಿಗೆ ವಿವಿಧ ಆಯ್ಕೆಗಳನ್ನು ಸಾಧ್ಯವೆಂದು ನೋಡಲು ಮತ್ತು ಒಳಗೊಂಡಿರುವ ಎಲ್ಲರಿಗೂ ಸಮಾನವಾದ ರೀತಿಯಲ್ಲಿ ಮುಂದುವರಿಯಲು ಜಾಗವನ್ನು ನೀಡುತ್ತದೆ.",
            "te": "గొప్ప పని! నెగోషియేషన్ ( చర్చ) వివిధ ఎంపికలను సాధ్యమయ్యేలా చూడడానికి మరియు పాల్గొన్న ప్రతి ఒక్కరికీ సమానమైన పద్ధతిలో ముందుకు సాగడానికి నిర్ణయంలో పాల్గొన్న వ్యక్తులకు అనుమతిస్తుంది.",
            "or": "ବହୁତ ଭଲ! ବୁଝାମଣା ନିଷ୍ପତ୍ତିରେ ଜଡିତ ଲୋକଙ୍କ ପାଇଁ ବିଭିନ୍ନ ପସନ୍ଦକୁ ସମ୍ଭବ ବୋଲି ଜାଣିବା ଏବଂ ଏହିପରି ଭାବରେ ଆଗକୁ ବଢିବାକୁ ଅନୁମତି ଦେଇଥାଏ ଯାହା ଏଥିରେ ସମ୍ପୃକ୍ତ ସମସ୍ତଙ୍କ ପାଇଁ ସମାନ ହୋଇଥାଏ ।",
            "as": "ভাল কাম! বুজাবুজিয়ে সিদ্ধান্তত জড়িত সকলোকে তেওঁলোকৰ হাতত থকা বিভিন্ন বিকল্প বোৰ চাবলৈ সুযোগ দিয়ে আৰু সিদ্ধান্তটোত জড়িত সকলোৱে একেলগে আগবাঢ়ি যাব পাৰে।",
            "gu": "સરસ કામ! વાટાઘાટો નિર્ણયમાં સામેલ લોકોને વિવિધ પસંદગીઓ શક્ય જોવા અને સામેલ દરેક માટે સમાન હોય તેવી રીતે આગળ વધવાની જગ્યા આપે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! To get information we do research and networking. To reach an agreement when starting out with differing points of view, it’s important to negotiate.",
            "hi": "फिर से विचार करें! जानकारी प्राप्त करने के लिए हम रिसर्च और नेटवर्किंग करते हैं। जब हम भिन्न विचारों के साथ शुरुआत करते हैं और किसी निर्णय पर पहुंचना चाहते हैं तब निगोशिएट करना महत्वपूर्ण है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಮಾಹಿತಿಯನ್ನು ಪಡೆಯಲು ನಾವು ಸಂಶೋಧನೆ ಮತ್ತು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮಾಡುತ್ತೇವೆ. ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳೊಂದಿಗೆ ಪ್ರಾರಂಭಿಸುವಾಗ ಒಪ್ಪಂದವನ್ನು ತಲುಪಲು, ಮಾತುಕತೆ ನಡೆಸುವುದು ಮುಖ್ಯವಾಗಿದೆ.",
            "te": "మళ్లీ ఆలోచించు! సమాచారాన్ని పొందడానికి మేము పరిశోధన మరియు నెట్‌వర్కింగ్ చేస్తాము.భిన్నమైన అభిప్రాయాలు, ప్రారంభించేటప్పుడు ఒక ఒప్పందాన్ని చేరుకోవడానికి చర్చలు జరపడం ముఖ్యం.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ସୂଚନା ପାଇବା ପାଇଁ ଆମେ ଅନୁସନ୍ଧାନ ଏବଂ ନେଟୱାର୍କିଂ କରୁ । ଭିନ୍ନ ଭିନ୍ନ ଦୃଷ୍ଟିକୋଣରୁ ଆରମ୍ଭ କରିବା ସମୟରେ ଏକ ରାଜିନାମାରେ ପହଞ୍ଚିବା ପାଇଁ, ବୁଝାମଣା କରିବା ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ।",
            "as": "আকৌ চিন্তা কৰা! তথ্য পাবলৈ আমি গৱেষণা আৰু নেটৱৰ্কিং কৰোঁ। ভিন্ন দৃষ্টিভংগীৰে আৰম্ভ কৰোঁতে এক চুক্তিত উপনীত হ'বলৈ, বুজাবুজি কৰাটো গুৰুত্বপূৰ্ণ।",
            "gu": "ફરીથી વિચાર! માહિતી મેળવવા માટે અમે સંશોધન અને નેટવર્કિંગ કરીએ છીએ. જુદા જુદા દૃષ્ટિકોણથી શરૂઆત કરતી વખતે કરાર સુધી પહોંચવા માટે, વાટાઘાટો કરવી મહત્વપૂર્ણ છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Junior Environmental Educator",
            "hi": "ज्युनियर एनवायरमेंटल एज्युकेटर",
            "ka": "**THREAD_END**_ಕಿರಿಯ ಪರಿಸರ ಸಲಹೆಗಾರ",
            "te": "**THREAD_END**_జూనియర్ పర్యావరణ సలహాదారు",
            "or": "**THREAD_END**_କନିଷ୍ଠ ପରିବେଶ ଶିକ୍ଷାବିତ୍",
            "as": "**থ্ৰেড_এণ্ড**_কনিষ্ঠ পৰিৱেশ শিক্ষাবিদ",
            "gu": "**THREAD_END**_જુનિયર પર્યાવરણીય શિક્ષક"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Razia that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने रजिया के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँची है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकती है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n \n ನೀವು ರಝಿಯಾಗಾಗಿ ಮಾಡಿದ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ.ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు రజియా కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర దృశ్యాలను అన్వేషించాలనుకుంటున్నారా?\"",
            "or": "ଅଭିନନ୍ଦନ! \n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି! \n ଆପଣ ରଜିଆ ପାଇଁ ନିଷ୍ପତ୍ତିଗୁଡିକ ପସନ୍ଦ କରିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତିକୁ ଆଗେଇ ନେଇଛି । ଆପଣ କରୁଥିବା ପସନ୍ଦଗୁଡିକ ଉପରେ ଆଧାର କରି ବିଭିନ୍ନ ପ୍ରକାରର ଶେଷ ଅଛି । ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହାଁନ୍ତି କି?",
            "as": "অভিনন্দন!\n তুমি এটা যাত্ৰা সমাপ্ত কৰিলা!\n \n তুমি ৰাজিয়াৰ বাবে বাছনি কৰিছা যাৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত আধাৰিত কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ জানিব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક સફર પૂરી કરી છે!\n\nતમે રઝિયા માટે જે પસંદગીઓ કરી છે જેના કારણે આ સંભવિત અંત આવ્યો છે. તમે જે પસંદગી કરો છો તેના આધારે વિવિધ અંત છે. શું તમે બીજા દ્રશ્યો શોધવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ର ଉପରେ ନଜର ପକାନ୍ତୁ",
            "as": "মানচিত্ৰখন চোৱা",
            "gu": "ચાલો નકશા તરફ નજર નાખીએ"
          }
        }
      ]
    },
    "scene25": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "E1_1_1_Apply at the NGO",
            "hi": "एनजीओ में अप्लाई करना",
            "ka": "E1_1_1_ಎನ್‌ಜಿಒ ನಲ್ಲಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ",
            "te": "E1_1_1_NGO వద్ద దరఖాస్తు చేసుకోండి",
            "or": "E1_1_1_NGO ରେ ଆବେଦନ କରନ୍ତୁ",
            "as": "E1_1_1_ NGOত এপ্লাই",
            "gu": "E1_1_1_NGO પર અરજી કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia gets a call from the NGO for an interview. She walks into the office where three interviewers are waiting for her.",
            "hi": "रजिया को एनजीओ से इन्टरव्यू के लिए कॉल आता है। वह ऑफिस में जाती है, तो वहाँ तीन लोग उसका इन्टरव्यू लेने के बैठे होते हैं।",
            "ka": "ರಝಿಯಾಗೆ ಎನ್‌ಜಿಒ ದಿಂದ ಸಂದರ್ಶನಕ್ಕೆ ಕರೆ ಬರುತ್ತದೆ. ಮೂರು ಸಂದರ್ಶಕರು ತನಗಾಗಿ ಕಾಯುತ್ತಿರುವ ಕಛೇರಿಗೆ ಅವಳು ನಡೆಯುತ್ತಾಳೆ.",
            "te": "రజియాకు NGO నుండి ఇంటర్వ్యూ కోసం కాల్ వచ్చింది. ఆమె ఆఫీసులోకి వెళ్తుంది, అక్కడ ముగ్గురు ఇంటర్వ్యూయర్లు ఆమె కోసం ఎదురు చూస్తున్నారు",
            "or": "ରଜିଆ ଏକ ସାକ୍ଷାତକାର ପାଇଁ NGOରୁ ଏକ କଲ୍ ପାଇଲେ । ସେ ଅଫିସକୁ ଯାଆନ୍ତି ଯେଉଁଠାରେ ତିନି ଜଣ ସାକ୍ଷାତକାରକାରୀ ତାଙ୍କୁ ଅପେକ୍ଷା କରିଛନ୍ତି ।",
            "as": "ৰাজিয়াই এটা ইন্টাৰভিউৰ বাবে NGOৰ পৰা ফোন পাইছিল। তাই অফিচলৈ যায় য'ত তিনিজন ইন্টাৰভিউ লওতাই তাইৰ বাবে অপেক্ষা কৰি আছে।",
            "gu": "રઝિયાને NGOથી  ઇન્ટરવ્યૂ માટે કોલ આવે છે. તે ઓફિસ તરફ જાય છે જ્યાં ત્રણ લોકો ઇન્ટરવ્યૂ માટે રાહ જોતાં હોય છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "E1_1_2",
            "hi": "E1_1_2",
            "ka": "E1_1_2",
            "te": "E1_1_2",
            "or": "E1_1_2",
            "as": "E1_1_2",
            "gu": "E1_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "They smile at her as they welcome her in. Razia introduces herself, and they begin the interview.",
            "hi": "वे मुस्कुराकर उसका स्वागत करते हैं। रजिया खुद का परिचय देती है और वे इन्टरव्यू लेना शुरू करते हैं।",
            "ka": "ಅವರು ಅವಳನ್ನು ಸ್ವಾಗತಿಸುವಾಗ ಅವಳನ್ನು ನೋಡಿ ನಗುತ್ತಾರೆ. ರಝಿಯಾ ತನ್ನನ್ನು ಪರಿಚಯಿಸಿಕೊಂಡಳು ಮತ್ತು ಅವರು ಸಂದರ್ಶನವನ್ನು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ.",
            "te": "వారు ఆమెను స్వాగతిస్తున్నప్పుడు ఆమెను చూసి నవ్వారు. రజియా తనను తాను పరిచయం చేసుకుంటుంది మరియు వారు ఇంటర్వ్యూని ప్రారంభించారు.",
            "or": "ସେମାନେ ତାଙ୍କୁ ହସର ସହିତ ସ୍ୱାଗତ କରନ୍ତି । ରଜିଆ ନିଜକୁ ପରିଚିତ କରାନ୍ତି, ଏବଂ ସେମାନେ ସାକ୍ଷାତକାର ଆରମ୍ଭ କରନ୍ତି ।",
            "as": "তেঁওলোকে তাইলৈ চাই হাঁহিৰে তাইক স্বাগতম জনালে । ৰাজিয়াই নিজৰ পৰিচয় দিয়ে, আৰু তেওঁলোকে ইন্টাৰভিউটো আৰম্ভ কৰে।",
            "gu": "તેઓ રઝિયા તરફ સ્મિત કરે છે અને તેનું સ્વાગત કરે છે. રઝિયા પોતાનો પરિચય આપે છે, અને તેઓ ઇન્ટરવ્યુ શરૂ કરે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "E1_1_3_Single select",
            "hi": "E1_1_3_एक ही सिलेक्ट करें",
            "ka": "E1_1_3_ಏಕ ಆಯ್ಕೆ",
            "te": "E1_1_3_సింగిల్ సెలెక్ట్",
            "or": "E1_1_3_ଗୋଟିଏ ଚୟନ କରନ୍ତୁ",
            "as": "E1_1_3_ যিকোনো এটা নিৰ্বাচন কৰা",
            "gu": "E1_1_3_સિંગલ સિલેક્ટ"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Alright Razia, we are impressed with your academic performance. You  have done well… But tell us, why do you want to work with us? \nHelp Razia pick her answer",
            "hi": "अच्छा रजिया, हम तुम्हारे अकैडमिक कार्य प्रदर्शन से प्रभावित हैं। तुम्हे काफी अच्छा किया है … लेकिन हमें बताओ की तुम हमारे साथ काम क्यों करना चाहती हो? \n जवाब चुनने में रजिया की मदद करें",
            "ka": "\"ಸರಿ ರಝಿಯಾ, ನಿಮ್ಮ ಶೈಕ್ಷಣಿಕ ಸಾಧನೆಯಿಂದ ನಾವು ಪ್ರಭಾವಿತರಾಗಿದ್ದೇವೆ. ನೀವು ಚೆನ್ನಾಗಿ ಮಾಡಿದ್ದೀರಿ... ಆದರೆ ನಮಗೆ ಹೇಳಿ, ನೀವು ನಮ್ಮೊಂದಿಗೆ ಏಕೆ ಕೆಲಸ ಮಾಡಲು ಬಯಸುತ್ತೀರಿ?\n ರಝಿಯಾ ಅವಳ ಉತ್ತರವನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಸಹಾಯ ಮಾಡಿ\"",
            "te": "\"అలాగే రజియా, మీ విద్యా పనితీరుతో మేము ఆకట్టుకున్నాము. మీరు బాగా చేసారు... అయితే మాకు చెప్పండి, మీరు మాతో ఎందుకు పని చేయాలనుకుంటున్నారు?\n రజియా సమాధానాన్ని ఎంచుకునేందుకు సహాయం చేయండి\"",
            "or": "ଠିକ ଅଛି ରଜିଆ, ଆମେ ତୁମର ଏକାଡେମିକ୍ ପ୍ରଦର୍ଶନରେ ପ୍ରଭାବିତ । ଆପଣ ଭଲ କରିଛନ୍ତି... କିନ୍ତୁ ଆମକୁ କୁହନ୍ତୁ, ଆପଣ ଆମ ସହିତ କାହିଁକି କାମ କରିବାକୁ ଚାହୁଁଛନ୍ତି? ରଜିଆଙ୍କୁ ତାଙ୍କ ଉତ୍ତର ବାଛିବାରେ ସାହାଯ୍ୟ କରନ୍ତୁ",
            "as": "ঠিক আছে ৰাজিয়া, আমি আপোনাৰ শৈক্ষিক প্ৰদৰ্শনত প্ৰভাৱিত হৈছোঁ। আপুনি ভাল কৰিছে... কিন্তু আমাক কওক, আপুনি আমাৰ সৈতে কিয় কাম কৰিব বিচাৰে? \n ৰাজিয়াক তাইৰ উত্তৰ বাছনি কৰাত সহায় কৰক",
            "gu": "ઠીક છે રઝિયા, અમે તમારા શૈક્ષણિક પ્રદર્શનથી પ્રભાવિત છીએ. તમે સારું કર્યું છે... પણ તમે અમારી સાથે કેમ કામ કરવા માંગો છો તે અમને કહો?\nરઝિયાને તેનો જવાબ મેળવવામાં મદદ કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.optionGroups.0.options.0.display",
          "value": {
            "en": "Because it has been my dream to work towards the environment…",
            "hi": "क्योंकि पर्यावरण के लिए काम करना हमेशा से ही मेरा सपना रहा है...",
            "ka": "ಏಕೆಂದರೆ ಪರಿಸರಕ್ಕಾಗಿ ಕೆಲಸ ಮಾಡುವುದು ನನ್ನ ಕನಸಾಗಿದೆ.",
            "te": "ఎందుకంటే పర్యావరణం కోసం పని చేయాలనేది నా కల.",
            "or": "କାରଣ ପରିବେଶ ପାଇଁ କାମ କରିବା ମୋର ସ୍ୱପ୍ନ ରହି ଆସିଛି ...",
            "as": "কাৰণ পৰিৱেশৰ ওপৰত কাম কৰাটো মোৰ এটা সপোন...",
            "gu": "કારણ કે મારું સપનું હતું કે હું પર્યાવરણ માટે કામ કરું..."
          }
        },
        {
          "valuePath": "blocks.0.props.optionGroups.0.options.1.display",
          "value": {
            "en": "I always wanted to learn from Director Ma'am and people like you and work towards improving Sundarbans...",
            "hi": "मैं हमेशा से ही डायरेक्टर मैम और आप जैसे लोगों से सीखना चाहती थी और सुंदरबन के हालात सुधारने के लिए काम करना चाहती थी...",
            "ka": "ನಾನು ಯಾವಾಗಲೂ ಡೈರೆಕ್ಟರ್ ಮೇಡಂ ಮತ್ತು ನಿಮ್ಮಂತಹ ಜನರಿಂದ ಕಲಿಯಲು ಬಯಸಿದ್ದೆ ಮತ್ತು ಸುಂದರಬನವನ್ನು ಸುಧಾರಿಸಲು ಕೆಲಸ ಮಾಡಲು ಬಯಸಿದ್ದೆ.",
            "te": "నేను డైరెక్టర్ మేడమ్ మరియు మీలాంటి వారి నుండి నేర్చుకోవాలని మరియు సుందర్‌బన్స్‌ను మెరుగుపరచడానికి కృషి చేయాలని ఎప్పుడూ కోరుకుంటున్నాను...",
            "or": "ମୁଁ ସର୍ବଦା ନିର୍ଦ୍ଦେଶକ ମାଡ଼ାମ୍ ଏବଂ ଆପଣଙ୍କ ପରି ଲୋକଙ୍କଠାରୁ ଶିଖିବାକୁ ଚାହୁଁଥିଲି ଏବଂ ସୁନ୍ଦରବନର ଉନ୍ନତି ଦିଗରେ କାର୍ଯ୍ୟ କରିବାକୁ ଚାହୁଁଥିଲି ...",
            "as": "মই সদায় পৰিচালিকা বাইদেউ আৰু আপোনাৰ দৰে লোকৰ পৰা শিকিব বিচাৰিছিলো আৰু সুন্দৰবনৰ উন্নতিৰ দিশত কাম কৰিব বিচাৰিছিলো...",
            "gu": "હું હંમેશા નિયામક મેડમ અને તમારા જેવા લોકો પાસેથી કઈક શીખવા માંગુ છું અને એવું કામ કરવા માગું છું કે સુંદરવન સુધરે..."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "E1_1_4_Single select",
            "hi": "E1_1_4_एक ही सिलेक्ट करें",
            "ka": "E1_1_4_ಏಕ ಆಯ್ಕೆ",
            "te": "E1_1_4_సింగిల్ సెలెక్ట్",
            "or": "E1_1_4_ ଗୋଟିଏ ଚୟନ କରନ୍ତୁ",
            "as": "E1_1_4_ যিকোনো এটা নিৰ্বাচন কৰা",
            "gu": "E1_1_4_ એક પસંદ કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "That’s wonderful. But can you tell us your skill sets that will help with the current role?  \nHelp Razia pick her answer",
            "hi": "अच्छी बात है। क्या तुम हमें बता सकती हो कि तुम्हारे पास ऐसे कौन से कौशल हैं जो इस जॉब में तुम्हारी मदद कर सकते हैं? \n जवाब चुनने में रजिया की मदद करें",
            "ka": "\"ಅದು ಅದ್ಭುತವಾಗಿದೆ. ಆದರೆ ಪ್ರಸ್ತುತ ಪಾತ್ರಕ್ಕೆ ಸಹಾಯ ಮಾಡುವ ನಿಮ್ಮ ಕೌಶಲ್ಯ ಸೆಟ್‌ಗಳನ್ನು ನೀವು ನಮಗೆ ಹೇಳಬಲ್ಲಿರಾ?\n ರಝಿಯಾ ಅವರ ಉತ್ತರವನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಸಹಾಯ ಮಾಡಿ\"",
            "te": "\"అద్భుతం. అయితే ప్రస్తుత పాత్రకు సహాయపడే మీ నైపుణ్యాల సెట్‌లను మీరు మాకు చెప్పగలరా?\n రజియా సమాధానాన్ని ఎంచుకునేందుకు సహాయం చేయండి\"",
            "or": "ବହୁତ ଭଲ । କିନ୍ତୁ ଆପଣ ଆମକୁ ଆପଣଙ୍କର ଦକ୍ଷତା ସେଟ୍ କହିପାରିବେ କି ଯାହା ବର୍ତ୍ତମାନର ଭୂମିକାରେ ସାହାଯ୍ୟ କରିବ? ରଜିଆଙ୍କୁ ତାଙ୍କ ଉତ୍ତର ବାଛିବାରେ ସାହାଯ୍ୟ କରନ୍ତୁ",
            "as": "এয়া বৰ ভাল কথা। কিন্তু আপুনি আমাক আপোনাৰ দক্ষতাৰ সংহতিবোৰ ক'ব পাৰিবনে যি বৰ্তমানৰ পদটো পোৱাত সহায় কৰিব? \n ৰাজিয়াক তাইৰ উত্তৰ বাছনি কৰাত সহায় কৰক",
            "gu": "તે ખૂબ સરસ છે. પરંતુ શું તમે અમને તમારા કૌશલ્ય વીશે કહી શકો છો જે અત્યારની ભૂમિકામાં મદદ કરશે?\nરઝિયાને તેનો જવાબ મેળવવામાં મદદ કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.optionGroups.0.options.0.display",
          "value": {
            "en": "Field research, conducting training and awareness.",
            "hi": "फिल्ड रिसर्च, ट्रेनिंग और जागरूकता अभियान आयोजित करना।",
            "ka": "ಕ್ಷೇತ್ರ ಸಂಶೋಧನೆ, ತರಬೇತಿ ಮತ್ತು ಜಾಗೃತಿ ನಡೆಸುವುದು.",
            "te": "క్షేత్ర పరిశోధన, శిక్షణ మరియు అవగాహన కల్పించడం.",
            "or": "କ୍ଷେତ୍ର ଅନୁସନ୍ଧାନ, ତାଲିମ ଏବଂ ସଚେତନତା ପରିଚାଳନା ।",
            "as": "ক্ষেত্ৰ গৱেষণা, প্ৰশিক্ষণ আৰু সজাগতা পৰিচালনা কৰা।",
            "gu": "ક્ષેત્ર સંશોધન, તાલીમ રાખે છે અને જાગૃતિ લાવે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.optionGroups.0.options.1.display",
          "value": {
            "en": "Since I am a local, I connect with both the environment and the people.",
            "hi": "चूँकि मैं स्थानिक निवासी हूँ, मैं पर्यावरण और लोगों के साथ संपर्क बना सकती हूँ।",
            "ka": "ನಾನು ಸ್ಥಳೀಯನಾಗಿರುವುದರಿಂದ ಪರಿಸರ ಮತ್ತು ಜನರೊಂದಿಗೆ ಸಂಪರ್ಕ ಸಾಧಿಸುತ್ತೇನೆ.",
            "te": "నేను స్థానికుడిని కాబట్టి, నేను పర్యావరణం మరియు ప్రజలు రెండింటితో కలవగలను",
            "or": "ଯେହେତୁ ମୁଁ ଜଣେ ସ୍ଥାନୀୟ, ମୁଁ ଉଭୟ ପରିବେଶ ଏବଂ ଲୋକଙ୍କ ସହିତ ଜଡ଼ିତ ହୁଏ ।",
            "as": "যিহেতু মই এগৰাকী স্থানীয় বাসিন্দা, মই পৰিৱেশ আৰু মানুহ দুয়োৰে সৈতে সংযোগ স্থাপন কৰোঁ।",
            "gu": "હું અહીની સ્થાનિક હોવાથી હું પર્યાવરણ અને લોકો બંને સાથે જોડાઈ શકું છું."
          }
        },
        {
          "valuePath": "blocks.0.props.optionGroups.0.options.2.display",
          "value": {
            "en": "I am a very positive team player and I am a curious learner.",
            "hi": "मैं सकारात्मक टीम प्लेअर हूँ और सीखने में जिज्ञासु हूँ।",
            "ka": "ನಾನು ತುಂಬಾ ಧನಾತ್ಮಕ ತಂಡದ ಆಟಗಾರ ಮತ್ತು ನಾನು ಕುತೂಹಲಕಾರಿ ಕಲಿಯುವವಳು.",
            "te": "నేను చాలా సానుకూల టీమ్ ప్లేయర్‌ని మరియు నేను ఆసక్తిగా నేర్చుకుంటాను.",
            "or": "ମୁଁ ଦଳର ଜଣେ ସକରାତ୍ମକ ସଦସ୍ୟ ଏବଂ ମୁଁ ଜଣେ ଜିଂଜ୍ଞାସୁ ଶିକ୍ଷାର୍ଥୀ ।",
            "as": "মই এগৰাকী অতি ভাল দলীয়ভাৱে কাম কৰিব পৰা লোক আৰু মই এজন কৌতূহলী শিক্ষাৰ্থী।",
            "gu": "હું ખૂબ જ સકારાત્મક ટીમની ખેલાડી છું અને હું એક જિજ્ઞાસુ શીખવવાળી છું."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "E1_1_5_Single select",
            "hi": "E1_1_5_एक ही सिलेक्ट करें",
            "ka": "E1_1_5_ಏಕ ಆಯ್ಕೆ",
            "te": "E1_1_5_సింగిల్ సెలెక్ట్",
            "or": "E1_1_5_ ଗୋଟିଏ ଚୟନ କରନ୍ତୁ",
            "as": "E1_1_5_ যিকোনো এটা নিৰ্বাচন কৰা",
            "gu": "E1_1_4_ એક પસંદ કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "That’s exactly who we want on our team! We have one last thing to ask you Razia. Where do you see yourself in 5 years?\nHelp Razia pick her options.",
            "hi": "हमें अपनी टीम पर ऐसा ही सदस्य चाहिए! अब हमारा आखरी सवाल यह है, रजिया। तुम अगले 5 सालों में अपने आप को कहाँ देखती हो? \n रजिया को अपने ऑप्शन्स चुनने में मदद करें।",
            "ka": "\"ನಮ್ಮ ತಂಡದಲ್ಲಿ ನಮಗೆ ಬೇಕಾಗಿರುವುದು ಅವರೇ! ರಝಿಯಾ ನಿಮಗೆ ಕೊನೆಯದಾಗಿ ಒಂದು ವಿಚಾರವನ್ನು ಕೇಳಬೇಕಾಗಿದೆ. ೫ ವರ್ಷಗಳಲ್ಲಿ ನಿಮ್ಮನ್ನು ಎಲ್ಲಿ ನೋಡುತ್ತೀರಿ?\n ರಝಿಯಾ ಅವಳ ಆಯ್ಕೆಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಸಹಾಯ ಮಾಡಿ.\"",
            "te": "మా టీమ్‌లో మాకు సరిగ్గా ఇలాంటివాళ్లే కావాలి! రజియా, మిమ్మల్ని చివరిగా ఒకటి అడగాలి. 5 సంవత్సరాలలో మిమ్మల్ని మీరు ఎక్కడ చూడాలనుకుంటున్నారు?\n రజియా తన ఎంపికలను ఎంచుకునేందుకు సహాయం చేయండి.\"",
            "or": "ଆମେ ଆମ ଦଳରେ ଠିକ୍ ତାହା ଚାହୁଁ! ରଜିଆଙ୍କୁ ପଚାରିବା ପାଇଁ ଆମର ଗୋଟିଏ ଶେଷ ଜିନିଷ ଅଛି । 5 ବର୍ଷ ମଧ୍ୟରେ ଆପଣ ନିଜକୁ କେଉଁଠାରେ ଦେଖୁଛନ୍ତି? ରଜିଆଙ୍କୁ ତାଙ୍କ ବିକଳ୍ପ ବାଛିବାରେ ସାହାଯ୍ୟ କରନ୍ତୁ ।",
            "as": "আমি আমাৰ দলত ঠিক এনেধৰণৰেই বিচাৰো! আমাৰ আপোনাক সুধিবলৈ এটা শেষ কথা আছে ৰাজিয়া। অহা 5 বছৰত আপুনি নিজকে কি অৱস্থাত দেখিছে?\n ৰাজিয়াক তাইৰ বিকল্পবোৰ বাছনি কৰাত সহায় কৰা।",
            "gu": "બસ અમને આ અમારી ટીમમાં જોઈએ છે! અમે તને એક છેલ્લી વાત પૂછવાની છે રઝિયા. 5 વર્ષની અંદર તું તારી જાતને ક્યાં જોશે?\nરઝિયાને તેના વિકલ્પો સોધવામાં કરવામાં મદદ કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.optionGroups.0.options.0.display",
          "value": {
            "en": "As a director of an NGO.",
            "hi": "एनजीओ के डायरेक्टर के तौर पर।",
            "ka": "ಎನ್‌ಜಿಒ ವೊಂದರ ನಿರ್ದೇಶಕರಾಗಿ.",
            "te": "ఒక NGO డైరెక్టర్‌గా.",
            "or": "ଏକ NGOର ନିର୍ଦ୍ଦେଶକ ଭାବରେ ।",
            "as": "এটা NGOৰ সঞ্চালক হিচাপে।",
            "gu": "NGOના નિયામક તરીકે."
          }
        },
        {
          "valuePath": "blocks.0.props.optionGroups.0.options.1.display",
          "value": {
            "en": "An environmental researcher working in the Sunderbans.",
            "hi": "सुंदरबन में काम करनेवाले एनवायरमेंटल रिसर्चर के तौर पर।",
            "ka": "ಸುಂದರ್‌ಬನ್ಸ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿರುವ ಪರಿಸರ ಸಂಶೋಧಕ.",
            "te": "సుందర్‌బన్స్‌లో పనిచేస్తున్న పర్యావరణ పరిశోధకుడు గా..",
            "or": "ସୁନ୍ଦରବନରେ କାର୍ଯ୍ୟ କରୁଥିବା ଜଣେ ପରିବେଶ ଗବେଷକ ।",
            "as": "সুন্দৰবনত কাম কৰা এজন পৰিৱেশ গৱেষক।",
            "gu": "પર્યાવરણ સંશોધક સુંદરવનમાં કામ કરવા માટે ."
          }
        },
        {
          "valuePath": "blocks.0.props.optionGroups.0.options.2.display",
          "value": {
            "en": "Being happy and fulfilled by the work I do.",
            "hi": "मैं जो काम कर रही हूँ उसमें खुश और संतुष्ट।",
            "ka": "ನಾನು ಮಾಡುವ ಕೆಲಸದಿಂದ ಸಂತೋಷ ಮತ್ತು ತೃಪ್ತಿ.",
            "te": "నేను చేసే పనితో సంతోషంగా మరియు సంతృప్తిగా ఉండడం.",
            "or": "ମୁଁ କରୁଥିବା କାର୍ଯ୍ୟ ଦ୍ୱାରା ଖୁସି ଏବଂ ପରିପୂର୍ଣ୍ଣ ।",
            "as": "মই কৰা কামৰ দ্বাৰা সুখী আৰু পৰিপূৰ্ণ হোৱা।",
            "gu": "હું ખુશ છું અને પરિપૂર્ણ છું જે કામ કરું છું તેનાથી ."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "E1_1_6",
            "hi": "E1_1_6",
            "ka": "E1_1_6",
            "te": "E1_1_6",
            "or": "E1_1_6",
            "as": "E1_1_6",
            "gu": "E1_1_6"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Fabulous! We are extremely happy with your response Razia and it gives me great pleasure to invite you to join our team.",
            "hi": "बढ़िया! तुम्हारे जवाब से हम बहुत खुश हैं रजिया और तुम्हे हमारी टीम में शामिल होने के लिए आमंत्रित करते हुए हमें बहुत खुशी हो रही हैं।",
            "ka": "ಅದ್ಭುತ! ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಯಿಂದ ನಮಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿದೆ ರಝಿಯಾ ಮತ್ತು ನಮ್ಮ ತಂಡಕ್ಕೆ ಸೇರಲು ನಿಮ್ಮನ್ನು ಆಹ್ವಾನಿಸಲು ನನಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿದೆ.",
            "te": "అద్భుతమైన! మీ ప్రతిస్పందనతో మేము చాలా సంతోషిస్తున్నాము రజియా మరియు మిమ్మల్ని మా బృందంలో చేరమని ఆహ్వానించడం నాకు చాలా ఆనందాన్ని ఇస్తుంది",
            "or": "ଚମତ୍କାର! ଆମେ ଆପଣଙ୍କ ପ୍ରତିକ୍ରିୟାରେ ଅତ୍ୟନ୍ତ ଖୁସି ରଜିଆ ଏବଂ ଆମ ଦଳରେ ଯୋଗଦେବାକୁ ଆପଣଙ୍କୁ ଆମନ୍ତ୍ରଣ କରି ମୁଁ ବହୁତ ଆନନ୍ଦିତ ।",
            "as": "বৰ ভাল কথা! আপোনাৰ সঁহাৰিত আমি অত্যন্ত সুখী আৰু আপোনাক আমাৰ দলত যোগদান কৰিবলৈ আমন্ত্ৰণ জনাই আমি অত্যন্ত আনন্দিত।",
            "gu": "કલ્પિત! રઝિયાના તમારા પ્રતિભાવથી અમે ખૂબ જ ખુશ છીએ અને તમને અમારી ટીમમાં જોડાવા માટે આમંત્રિત કરતાં મને ઘણો આનંદ થાય છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "E1_1_7",
            "hi": "E1_1_7",
            "ka": "E1_1_7",
            "te": "E1_1_7",
            "or": "E1_1_7",
            "as": "E1_1_7",
            "gu": "E1_1_7"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia leaves the interview with an offer letter. She can’t wait to start her new job at the NGO!",
            "hi": "रजिया ऑफर लेटर लेकर इन्टरव्यू देकर बाहर निकलती हैं। वह एनजीओ में काम शुरू करने के लिए इंतजार नहीं कर सकती!",
            "ka": "ರಝಿಯಾ ಸಂದರ್ಶನದಿಂದ ಆಫರ್ ಲೆಟರ್‌ನೊಂದಿಗೆ ಹೊರಡುತ್ತಾಳೆ. ಎನ್‌ಜಿಒ ನಲ್ಲಿ ತನ್ನ ಹೊಸ ಕೆಲಸವನ್ನು ಪ್ರಾರಂಭಿಸಲು ಅವಳು ಕಾಯಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ!",
            "te": "రజియా ఆఫర్ లెటర్‌తో ఇంటర్వ్యూ నుండి బయలుదేరింది. NGOలో తన కొత్త ఉద్యోగాన్ని ప్రారంభించడానికి ఆమె వేచి ఉండలేకపోతోంది!",
            "or": "ରଜିଆ ଏକ ଅଫର ଚିଠି ସହିତ ସାକ୍ଷାତକାରରୁ ଫେରିଲେ । ସେ NGOରେ ତାଙ୍କର ନୂତନ ଚାକିରି ଆରମ୍ଭ କରିବାକୁ ଅପେକ୍ଷା କରିପାରୁ ନାହାନ୍ତି !",
            "as": "ৰাজিয়াই এখন অফাৰ লেটাৰৰ সৈতে ইন্টাৰভিউটো এৰিলে । তাই NGOত তাইৰ নতুন চাকৰি আৰম্ভ কৰিবলৈ অপেক্ষা কৰিব নোৱাৰে!",
            "gu": "રઝિયા ઑફર લેટર સાથે ઇન્ટરવ્યુ પૂરું કરે છે. તેને NGOમાં નવી નોકરી શરૂ કરવા માટે રાહ જોઈ શકતી નથી!"
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "Interview",
            "hi": "इन्टरव्यू देना",
            "ka": "ಸಂದರ್ಶನ",
            "te": "ఇంటర్వ్యూ (ముఖా ముఖి సమావేశం)",
            "or": "ସାକ୍ଷାତକାର",
            "as": "ইন্টাৰভিউ",
            "gu": "ઈન્ટરવ્યુ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained interview experience!",
            "hi": "आपने अभी-अभी इन्टरव्यू देने का अनुभव प्राप्त किया हैं!",
            "ka": "ನೀವು ಸಂದರ್ಶನದ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే ఇంటర్వ్యూ (ముఖా ముఖి సమావేశం)అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ସାକ୍ଷାତକାର ଅଭିଜ୍ଞତା ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ ইন্টাৰভিউৰ অভিজ্ঞতা লাভ কৰিছা!",
            "gu": "તમે બસ ઈન્ટરવ્યુનો અનુભવ મેળવ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Interview",
            "hi": "इन्टरव्यू देना",
            "ka": "ಸಂದರ್ಶನ",
            "te": "ఇంటర్వ్యూ (ముఖా ముఖి సమావేశం)",
            "or": "ସାକ୍ଷାତକାର",
            "as": "ইন্টাৰভিউ",
            "gu": "ઈન્ટરવ્યુ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Suitability Assessment Tools",
            "hi": "उचित करियर के लिए मूल्यांकन टूल",
            "ka": "ವೃತ್ತಿ ಸೂಕ್ತತೆಯ ಮೌಲ್ಯಮಾಪನ ಪರಿಕರಗಳು",
            "te": "కెరీర్ అనుకూలత అంచనా సాధనాలు",
            "or": "କ୍ୟାରିଅର୍ ଉପଯୁକ୍ତତା ମୂଲ୍ୟାଙ୍କନ ଉପକରଣଗୁଡିକ",
            "as": "কেৰিয়াৰ উপযুক্ততা মূল্যায়ন সঁজুলি",
            "gu": "કારકિર્દી યોગ્યતા મૂલ્યાંકનના સાધનો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Razia appears in an interview for the NGO role and does well. An interview is a way for you to tell your employers or the other person about yourself and why you will be a good fit for the job. An interview means you share your learnings, aspirations, strengths, and why you are interested in the specific job. Being prepared for an interview is essential to help you be more confident in your answers about yourself and answer technical questions about the work.",
            "hi": "रजिया एनजीओ एक जॉब के लिए इन्टरव्यू देती है और उसका इन्टरव्यू बहुत अच्छा होता है। इन्टरव्यू एक ऐसा मार्ग है जिससे आप अपने बॉस या दुसरे व्यक्ति को अपने बारे में बता सकते हैं और उन्हें यकीन दिला सकते हैं कि आप इस जॉब के लिए सही उम्मीदवार क्यों हैं। इन्टरव्यू का मतलब है अपनी शिक्षा, महत्वाकांक्षा, खूबियाँ और आप इस विशेष जॉब में दिलचस्प क्यों हैं यह बताना। इन्टरव्यू के लिए पहले से तैयारी करने से आप अपने बारे में और काम के बारे तकनिकी पश्नों का उत्तर आत्मविश्वास से दे सकते हैं।",
            "ka": "ಎನ್‌ಜಿಒ ಪಾತ್ರಕ್ಕಾಗಿ ರಝಿಯಾ ಸಂದರ್ಶನವೊಂದರಲ್ಲಿ ಕಾಣಿಸಿಕೊಂಡರು ಮತ್ತು ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಾಳೆ. ಸಂದರ್ಶನವು ನಿಮ್ಮ ಉದ್ಯೋಗದಾತರಿಗೆ ಅಥವಾ ಇತರ ವ್ಯಕ್ತಿಗೆ ನಿಮ್ಮ ಬಗ್ಗೆ ಹೇಳಲು ಒಂದು ಮಾರ್ಗವಾಗಿದೆ ಮತ್ತು ನೀವು ಕೆಲಸಕ್ಕೆ ಏಕೆ ಸೂಕ್ತರಾಗಿರುತ್ತೀರಿ. ಸಂದರ್ಶನ ಎಂದರೆ ನಿಮ್ಮ ಕಲಿಕೆಗಳು, ಆಕಾಂಕ್ಷೆಗಳು, ಸಾಮರ್ಥ್ಯಗಳು ಮತ್ತು ನಿರ್ದಿಷ್ಟ ಉದ್ಯೋಗದಲ್ಲಿ ನೀವು ಏಕೆ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದೀರಿ ಎಂಬುದನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತೀರಿ. ನಿಮ್ಮ ಬಗ್ಗೆ ನಿಮ್ಮ ಉತ್ತರಗಳಲ್ಲಿ ಹೆಚ್ಚು ವಿಶ್ವಾಸ ಹೊಂದಲು ಮತ್ತು ಕೆಲಸದ ಕುರಿತು ತಾಂತ್ರಿಕ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಲು ಸಂದರ್ಶನಕ್ಕೆ ಸಿದ್ಧರಾಗಿರುವುದು ಅತ್ಯಗತ್ಯ.",
            "te": "NGO పాత్ర కోసం రజియా ఒక ఇంటర్వ్యూకి వెళ్ళింది మరియు బాగా చేస్తుంది. ఇంటర్వ్యూ అనేది మీ యజమానులకు లేదా ఇతర వ్యక్తికి మీ గురించి చెప్పడానికి మరియు మీరు ఉద్యోగానికి ఎందుకు సరిపోతారని చెప్పడానికి ఒక మార్గం. ఇంటర్వ్యూ అంటే మీరు మీ అభ్యాసాలు, ఆకాంక్షలు, బలాలు మరియు మీరు నిర్దిష్ట ఉద్యోగంపై ఎందుకు ఆసక్తిని కలిగి ఉన్నారో పంచుకుంటారు. మీ గురించిన మీ సమాధానాలపై మరింత నమ్మకంగా ఉండటానికి మరియు పనికి సంబంధించిన సాంకేతిక ప్రశ్నలకు సమాధానమివ్వడానికి ఇంటర్వ్యూ కోసం సిద్ధం కావడం చాలా అవసరం",
            "or": "ରଜିଆ NGO ଭୂମିକା ପାଇଁ ଏକ ସାକ୍ଷାତକାରକୁ ଯାଆନ୍ତି ଏବଂ ଭଲ କରନ୍ତି । ଏକ ସାକ୍ଷାତକାର ହେଉଛି ଆପଣଙ୍କ ନିଯୁକ୍ତିଦାତା କିମ୍ବା ଅନ୍ୟ ବ୍ୟକ୍ତିଙ୍କୁ ନିଜ ବିଷୟରେ କହିବାର ଏବଂ ଆପଣ କାହିଁକି ଚାକିରି ପାଇଁ ଭଲ ଭାବରେ ଫିଟ୍ ହେବେ ତାହା କହିବାର ଏକ ଉପାୟ । ଏକ ସାକ୍ଷାତକାରର ଅର୍ଥ ହେଉଛି ଆପଣ ଆପଣଙ୍କର ଶିକ୍ଷା, ଆକାଂକ୍ଷା, ଶକ୍ତି, ଏବଂ ଆପଣ କାହିଁକି ନିର୍ଦ୍ଦିଷ୍ଟ ଚାକିରିରେ ଆଗ୍ରହୀ ଅଟନ୍ତି ତାହା ଅଂଶୀଦାର କରନ୍ତି । ଆପଣଙ୍କୁ ନିଜ ବିଷୟରେ ଆପଣଙ୍କ ଉତ୍ତରରେ ଅଧିକ ଆତ୍ମବିଶ୍ୱାସୀ ହେବାରେ ଏବଂ କାର୍ଯ୍ୟ ବିଷୟରେ ବୈଷୟିକ ପ୍ରଶ୍ନର ଉତ୍ତର ଦେବାରେ ସାହାଯ୍ୟ କରିବା ପାଇଁ ଏକ ସାକ୍ଷାତକାର ପାଇଁ ପ୍ରସ୍ତୁତ ରହିବା ଜରୁରୀ ।",
            "as": "ৰাজিয়া NGOৰ পদৰ বাবে এটা ইন্টাৰভিউ দিবলৈ গৈছিল আৰু ভাল প্ৰদৰ্শন কৰিছিল। ইন্টাৰভিউ হৈছে তোমাৰ নিয়োগকৰ্তা বা আন ব্যক্তিক তোমাৰ বিষয়ে কোৱাৰ এক উপায় আৰু তুমি কিয় চাকৰিটোৰ বাবে উপযুক্ত সেই বিষয়ে কোৱাৰ এটা পথ। ইন্টাৰভিউ এটাৰ অৰ্থ হৈছে তুমি তোমাৰ শিক্ষা, আকাংক্ষা, শক্তি, আৰু তুমি নিৰ্দিষ্ট কামটোত কিয় আগ্ৰহী সেয়া প্ৰকাশ কৰা। তোমাৰ নিজৰ বিষয়ে তোমাৰ উত্তৰত অধিক আত্মবিশ্বাসী হ'বলৈ আৰু কামটোৰ বিষয়ে কাৰিকৰী প্ৰশ্নৰ শুদ্ধ উত্তৰ দিবলৈ ইন্টাৰভিউৰ বাবে প্ৰস্তুত হোৱাটো দৰকাৰ।",
            "gu": "રઝિયા NGOના રોલ માટે એક ઇન્ટરવ્યુમાં આવે છે અને સારૂ દેખાડે છે. ઇન્ટરવ્યુ એ તમારા માટે તમારા માલિક અથવા બીજા લોકોને તમારા વિશે અને તમે નોકરી માટે યોગ્ય છો તે જણાવવાનો એક રસ્તો છે. ઇન્ટરવ્યૂનો અર્થ થાય છે કે તમે તમારા શિક્ષણ, આકાંક્ષાઓ, શક્તિઓ અને તમને ચોક્કસ એ કામમાં કેમ રસ છે તે કહો છો. તમારા વિશેના તમારા જવાબોમાં વધુ પોતાના પર વિશ્વાસ રાખવો અને કામ વિશેના ટેકનિકલ પ્રશ્નોને જવાબ આપવા માટે ઇન્ટરવ્યૂ માટે તૈયાર થવું જરૂરી છે."
          }
        }
      ]
    },
    "scene27": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "E2_1_1",
            "hi": "E2_1_1",
            "ka": "E2_1_1",
            "te": "E2_1_1",
            "or": "E2_1_1",
            "as": "E2_1_1",
            "gu": "E2_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Two years later:\n\nRazia has been working as an ecologist at the NGO. She works very closely in the mangrove forests.",
            "hi": "दो साल बाद:\n \n रजिया एक एनजीओ में इकोलॉजजिस्ट के तौर पर काम कर रही है। वह मैनग्रोव के जंगलों पर काम करती है।",
            "ka": "ಎರಡು ವರ್ಷಗಳ ನಂತರ: ರಝಿಯಾ ಎನ್‌ಜಿಒದಲ್ಲಿ ಪರಿಸರ ವಿಜ್ಞಾನಿಯಾಗಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾಳೆ. ಅವಳು ಮ್ಯಾಂಗ್ರೋವ್ ಕಾಡುಗಳಲ್ಲಿ ಬಹಳ ನಿಕಟವಾಗಿ ಕೆಲಸ ಮಾಡುತ್ತಾಳೆ.",
            "te": "రెండు సంవత్సరాల తరువాత:\n \n రజియా ఎన్జీవోలో పర్యావరణ శాస్త్రవేత్తగా పనిచేస్తున్నారు. ఆమె మాంగ్రూవ్ అడవులలో చాలా దగ్గరగా పనిచేస్తుంది.",
            "or": "ଦୁଇ ବର୍ଷ ପରେ: \n ରଜିଆ NGOରେ ଇକୋଲୋଜିଷ୍ଟ ଭାବରେ କାର୍ଯ୍ୟ କରୁଛନ୍ତି । ସେ ମାନଗ୍ରୁଭ୍ ଜଙ୍ଗଲରେ ଅତି ନିକଟରୁ କାମ କରନ୍ତି ।",
            "as": "দুবছৰ পিছত:\n \n ৰাজিয়াই NGO'ত পৰিস্থিতিবিদ হিচাপে কাম কৰি আছে। তাই মেংগ্ৰোভ অৰণ্যত মনেপ্ৰাণে কাম কৰি আছে।",
            "gu": "બે વર્ષ પછી:\n\nરઝિયા NGOમાં ઇકોલોજીસ્ટ તરીકે કામ કરી રહી છે. તે ખૂબ જ નજીકથી કામ કરે છે મેન્ગ્રોવના જંગલોમાં ."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "E2_1_2",
            "hi": "E2_1_2",
            "ka": "E2_1_2",
            "te": "E2_1_2",
            "or": "E2_1_2",
            "as": "E2_1_2",
            "gu": "E2_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She recently published her experiences on a website, which received a lot of attention due to her knowledge of Sunderbans.",
            "hi": "उसने हाल ही में अपना अनुभव वैबसाइट पर प्रकाशित किया है। सुंदरबन के बारे में उसने दी जानकारी के कारण इसने बहुत से लोगों का ध्यान आकर्षित किया है।",
            "ka": "ಅವಳು ಇತ್ತೀಚೆಗೆ ತಮ್ಮ ಅನುಭವಗಳನ್ನು ವೆಬ್‌ಸೈಟ್‌ನಲ್ಲಿ ಪ್ರಕಟಿಸಿದರು, ಇದು ಅವಳ ಸುಂದರ್‌ಬನ್ಸ್‌ನ ಜ್ಞಾನದಿಂದಾಗಿ ಹೆಚ್ಚು ಗಮನ ಸೆಳೆಯಿತು.",
            "te": "ఆమె ఇటీవల తన అనుభవాలను వెబ్‌సైట్‌లో ప్రచురించింది, ఇది సుందర్‌బన్స్‌పై ఆమెకున్న పరిజ్ఞానం కారణంగా చాలా మంది దృష్టిని ఆకర్షించింది.",
            "or": "ସେ ନିକଟରେ ଏକ ୱେବସାଇଟରେ ନିଜର ଅନୁଭୂତି ପ୍ରକାଶ କରିଥିଲେ, ଯାହା ସୁନ୍ଦରବନ ବିଷୟରେ ତାଙ୍କର ଜ୍ଞାନ ହେତୁ ବହୁତ ଲୋକଙ୍କ ଧ୍ୟାନ ଆକର୍ଷିତ କରିଥିଲା ।",
            "as": "তেওঁ অলপতে এটা ৱেবছাইটত তেওঁৰ অভিজ্ঞতা প্ৰকাশ কৰিছিল, যিটোৱে সুন্দৰবনৰ বিষয়ে থকা তেওঁৰ জ্ঞানৰ বাবে যথেষ্ট মনোযোগ লাভ কৰিছিল।",
            "gu": "તેને હમણાં એક વેબસાઈટ પર તેના અનુભવો બતાવ્યા, જે સુંદરબન વિશેના તેના જ્ઞાનને કારણે ખૂબ ધ્યાન મેળવ્યું."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "E2_2_1",
            "hi": "E2_2_1",
            "ka": "E2_2_1",
            "te": "E2_2_1",
            "or": "E2_2_1",
            "as": "E2_2_1",
            "gu": "E2_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Director Ma'am has been noticing Razia's interest in writing and asks her to meet in the office.",
            "hi": "डायरेक्टर मैम ने नोटिस किया कि रजिया को लिखने में दिलचस्पी है और वह उसे ऑफिस में मिलने के लिए कहती है।",
            "ka": "ಡೈರೆಕ್ಟರ್ ಮೇಡಮ್ ರಝಿಯಾಳ ಬರವಣಿಗೆಯ ಆಸಕ್ತಿಯನ್ನು ಗಮನಿಸಿ ಅವಳನ್ನು ಕಛೇರಿಯಲ್ಲಿ ಭೇಟಿಯಾಗುವಂತೆ ಕೇಳುತ್ತಾರೆ.",
            "te": "డైరక్టర్ మేమ్ రజియాకి రాయడం పట్ల ఉన్న ఆసక్తిని గమనించి ఆమెను ఆఫీసులో కలవమని అడిగాడు.",
            "or": "ନିର୍ଦ୍ଦେଶକ ମାଡ଼ାମ୍ ରଜିଆଙ୍କ ଲେଖାଲେଖିରେ ଥିବା ଆଗ୍ରହକୁ ଲକ୍ଷ୍ୟ କରି ତାଙ୍କୁ କାର୍ଯ୍ୟାଳୟରେ ଭେଟିବାକୁ କୁହନ୍ତି ।",
            "as": "পৰিচালিকা বাইদেউয়ে ৰাজিয়াৰ লিখাৰ আগ্ৰহ লক্ষ্য কৰি আছে আৰু তাইক কাৰ্যালয়ত লগ কৰিবলৈ মাতিছে।",
            "gu": "નિયામક મેડમે રઝિયાની લેખનમાં રસ જોઈ રહ્યા છે અને તેને ઑફિસમાં મળવાનું કહે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "E2_2_2//",
            "hi": "E2_2_2//",
            "ka": "E2_2_2//",
            "te": "E2_2_2//",
            "or": "E2_2_2//",
            "as": "E2_2_2//",
            "gu": "E2_2_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "As they discuss her ideas for the next few papers, Director Ma'am interrupts Razia…",
            "hi": "जब वे अगले कुछ पेपर्स के लिए कल्पनाओं के बारे में चर्चा कर रहे थे तभी डायरेक्टर मैम ने रजिया को बीच में टोका ...",
            "ka": "ಮುಂದಿನ ಕೆಲವು ಪತ್ರಿಕೆಗಳಿಗಾಗಿ ಅವರು ತಮ್ಮ ಆಲೋಚನೆಗಳನ್ನು ಚರ್ಚಿಸುತ್ತಿರುವಾಗ, ನಿರ್ದೇಶಕಿ ಮೇಡಮ್ ರಝಿಯಾ ಅವಳನ್ನು ಅಡ್ಡಿಪಡಿಸಿದರು…",
            "te": "వారు తదుపరి కొన్ని పేపర్ల కోసం ఆమె ఆలోచనలను చర్చిస్తున్నప్పుడు, డైరెక్టర్ మామ్ రజియాను ఆపారు",
            "or": "ଯେତେବେଳେ ସେମାନେ କିଛି ପରବର୍ତ୍ତୀ କାମ ପାଇଁ ତାଙ୍କ ଧାରଣା ବିଷୟରେ ଆଲୋଚନା କରୁଥାନ୍ତି, ନିର୍ଦ୍ଦେଶକ ମାଡ଼ାମ୍ ରଜିଆଙ୍କୁ ବାଧା ଦିଅନ୍ତି ...",
            "as": "তেওঁলোকে পৰৱৰ্তী কেইখনমান কাকতৰ বাবে তাইৰ ধাৰণাবোৰ আলোচনা কৰি থাকোঁতে পৰিচালক মেডামে ৰাজিয়াক কথাৰ মাজতে বাধা দিয়ে...",
            "gu": "ત્યાં તેઓ આવનારા કેટલાક કાગળો માટે તેના વિચારોની ચર્ચા કરે છે, ત્યારે નિયામક મેડમ રઝિયાને અટકાવે છે..."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Ma'am",
            "hi": "मैम",
            "ka": "ಮೇಡಂ",
            "te": "మేడమ్",
            "or": "ମାଡ଼ାମ୍",
            "as": "মেডাম",
            "gu": "મેડમ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Ma'am",
            "hi": "मैम",
            "ka": "ಮೇಡಂ",
            "te": "మేడమ్",
            "or": "ମାଡ଼ାମ୍",
            "as": "মেডাম",
            "gu": "મેડમ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Razia, there is something important I was hoping to discuss with you.",
            "hi": "रजिया, मैं तुमसे एक जरुरी बात पर चर्चा करना चाहती हूँ।",
            "ka": "ರಝಿಯಾ, ನಾನು ನಿನ್ನೊಂದಿಗೆ ಒಂದು ಪ್ರಮುಖ ವಿಷಯವನ್ನು ಚರ್ಚಿಸಲು ಆಶಿಸುತ್ತಿದ್ದೆ.",
            "te": "రజియా, నేను మీతో ఒక ముఖ్యమైన విషయం గురించి చర్చించాలని ఆశిస్తున్నాను.",
            "or": "ରଜିଆ, କିଛି ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ କଥା ଅଛି ଯାହା ମୁଁ ତୁମ ସହିତ ଆଲୋଚନା କରିବାକୁ ଆଶା କରୁଥିଲି ।",
            "as": "ৰাজিয়া, তোমাৰ লগত মোৰ এটা দৰকাৰী কথা আলোচনা কৰিবলগীয়া আছে।",
            "gu": "રઝિયા, કઈક મહત્વનુ છે જે હું તારામાંથી ઇચ્છતી હતી અને ચર્ચા કરવા માગું છું. "
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Yes Director Ma'am?",
            "hi": "जी, डायरेक्टर मैम?",
            "ka": "ಹೌದು ಡೈರೆಕ್ಟರ್ ಮೇಡಂ?",
            "te": "చెప్పండి డైరెక్టర్ మేడమ్?",
            "or": "ହଁ ନିର୍ଦ୍ଦେଶକ ମାଡ଼ାମ୍?",
            "as": "হয় পৰিচালিকা বাইদেউ?",
            "gu": "હા નિયામક મેડમ?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "I have been extremely happy with the work you have been doing lately.",
            "hi": "तुम्हारे आजकल के काम को देखकर मुझे बहुत खुशी हो रही है।",
            "ka": "ನೀನು ಇತ್ತೀಚೆಗೆ ಮಾಡುತ್ತಿರುವ ಕೆಲಸದಿಂದ ನನಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿದೆ.",
            "te": "మీరు ఈ మధ్య చేస్తున్న పనికి నేను చాలా సంతోషిస్తున్నాను",
            "or": "ତୁମେ ବର୍ତ୍ତମାନ କରୁଥିବା କାର୍ଯ୍ୟରେ ମୁଁ ଅତ୍ୟନ୍ତ ଖୁସି ।",
            "as": "তুমি শেহতীয়াকৈ কৰি থকা কামত মই অত্যন্ত সুখী।",
            "gu": "તમે તાજેતરમાં જે કામ કરી રહ્યા છો તેનાથી હું ખૂબ જ ખુશ છું."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "E2_2_3////",
            "hi": "E2_2_3////",
            "ka": "E2_2_3////",
            "te": "E2_2_3////",
            "or": "E2_2_3/////",
            "as": "E2_2_3////",
            "gu": "E2_2_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Would you like this to be your full time role? I would be happy to place a few projects entirely in your hands.",
            "hi": "क्या तुम्हें यह काम फूल टाइम करना अच्छा लगेगा? कुछ प्रोजेक्ट्स का काम पूरी तरह से तुम्हारे हाथों में सौंपने मुझे खुशी होगी।",
            "ka": "ಇದು ನಿನ್ನ ಪೂರ್ಣ ಸಮಯದ ಪಾತ್ರವಾಗಲು ನೀನು ಬಯಸುವಿಯಾ? ಕೆಲವು ಯೋಜನೆಗಳನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ನಿನ್ನ ಕೈಯಲ್ಲಿ ಇರಿಸಲು ನಾನು ಸಂತೋಷಪಡುತ್ತೇನೆ.",
            "te": "ఇది మీ పూర్తి స్థాయి పాత్ర కావాలని మీరు అనుకుంటున్నారా? కొన్ని ప్రాజెక్ట్‌లను పూర్తిగా మీ చేతుల్లో ఉంచడానికి నేను సంతోషిస్తాను.",
            "or": "ତୁମେ ଚାହୁଁଛ କି ଏହା ତୁମର ପୂର୍ଣ୍ଣକାଳୀନ ଭୂମିକା ହେଉ ବୋଲି? ମୁଁ କିଛି ପ୍ରକଳ୍ପକୁ ସମ୍ପୂର୍ଣ୍ଣ ଭାବରେ ତୁମ ହାତରେ ଦେଇ ଖୁସି ହେବି ।",
            "as": "তুমি এইটো তোমাৰ স্থায়ী পদ হোৱাটো বিচাৰা নেকি? মই কেইটামান প্ৰজেক্ট সম্পূৰ্ণৰূপে তোমাক দিবলৈ পালে ভাল পাম।",
            "gu": "શું તું આ તારા પૂરા સમયની ભૂમિકા રીતે કામ કરવા માંગે છે? કેટલાક પ્રોજેક્ટ સંપૂર્ણપણે હું તને સોપવા માગું છું અને મને આનંદ થશે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Oh that would be wonderful Ma'am. I would love to have the opportunity.",
            "hi": "ओह, यह तो बहुत ही खुशी की बात है मैम। ये अवसर पाकर मैं खुद को भाग्यशाली समझूंगी।",
            "ka": "ಓಹ್ ಅದು ಅದ್ಭುತವಾಗಿರುತ್ತದೆ ಮೇಡಮ್. ನಾನು ಅವಕಾಶವನ್ನು ಹೊಂದಲು ಇಷ್ಟಪಡುತ್ತೇನೆ.",
            "te": "అది అద్భుతంగా ఉంది మేడమ్. నేను ఈ అవకాశాన్ని తీసుకోవాలనుకుంటున్నాను.",
            "or": "ଓଃ ତାହା ଚମତ୍କାର ହେବ ମାଡ଼ାମ୍ । ମୁଁ ସେ ସୁଯୋଗ ପାଇବାକୁ ପସନ୍ଦ କରିବି ।",
            "as": "অহ্ সেইটো বহুত ভাল কথা হ'ব মেডাম। মই এইটো সুযোগ পালে বৰ ভাল পাম।",
            "gu": "ઓહ તે બહુજ સરસ હશે મેડમ. મને આ તક મેળવવાનું ગમશે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "But there’s a condition Razia, everyone who gets to lead projects has to study research writing. If you are keen on this role, you have to study for the next couple of months.",
            "hi": "लेकिन इसके लिए एक शर्त है रजिया, जिस किसी को भी प्रोजेक्ट लीड करने का अवसर मिलता हैं उसे रिसर्च रायटिंग सीखनी जरुरी है। अगर तुम यह रोल चाहती हो तो तुम्हें अगले कुछ महीनों तक पढ़ाई करनी होगी।",
            "ka": "ಆದರೆ ರಝಿಯಾ ಒಂದು ಷರತ್ತು ಇದೆ, ಯೋಜನೆಗಳನ್ನು ಮುನ್ನಡೆಸುವ ಪ್ರತಿಯೊಬ್ಬರೂ ಸಂಶೋಧನಾ ಬರವಣಿಗೆಯನ್ನು ಅಧ್ಯಯನ ಮಾಡಬೇಕು. ನೀನು ಈ ಪಾತ್ರದಲ್ಲಿ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದರೆ, ನೀನು ಮುಂದಿನ ಒಂದೆರಡು ತಿಂಗಳು ಅಧ್ಯಯನ ಮಾಡಬೇಕು.",
            "te": "కానీ ఒక షరతు ఉంది రజియా, ప్రాజెక్ట్‌లకు నాయకత్వం వహించే ప్రతి ఒక్కరూ పరిశోధనా రచనను అధ్యయనం చేయాలి. మీరు ఈ పాత్రపై ఆసక్తి కలిగి ఉంటే, మీరు రాబోయే రెండు నెలలు చదవాలి.",
            "or": "କିନ୍ତୁ ଗୋଟିଏ ସର୍ତ୍ତ ଅଛି ରଜିଆ, ପ୍ରକଳ୍ପର ନେତୃତ୍ୱ ନେଉଥିବା ବ୍ୟକ୍ତିଙ୍କୁ ସମସ୍ତ ଗବେଷଣା ଲେଖା ଅଧ୍ୟୟନ କରିବାକୁ ପଡିବ । ଯଦି ତୁମେ ଏହି ଭୂମିକା ପାଇଁ ଆଗ୍ରହୀ, ତେବେ ତୁମକୁ ଆସନ୍ତା ଦୁଇ ମାସ ପାଇଁ ଅଧ୍ୟୟନ କରିବାକୁ ପଡିବ ।",
            "as": "কিন্তু এটা চৰ্ত আছে ৰাজিয়া, প্ৰজেক্টৰ নেতৃত্ব দিব পৰা সকলে গৱেষণাৰ লিখনি পঢ়িব লাগিব। যদি তুমি এই পদটোৰ বাবে আগ্ৰহী, তুমি পৰৱৰ্তী কেইমাহমান পঢ়াশুনা কৰিব লাগিব।",
            "gu": "પરંતુ રઝિયા એક શરત છે, જે વ્યક્તિ આ પ્રોજેક્ટ્સનું નેતૃત્વ કરે છે તેણે સંશોધન લેખનનો અભ્યાસ કરવો પડશે. જો તું આ ભૂમિકા માટે ઉત્સુક છે, તો તારે આવતા બે મહિના સુધી અભ્યાસ કરવો પડશે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "But Ma'am, I hardly get any time between the work and home. Ammi is old now, she is not able to manage the house work by herself and Shazia needs to focus on her studies.",
            "hi": "लेकिन मैम, मुझे काम और घर संभालने के बाद बहुत ही कम समय मिलता है। अम्मी की उम्र हो गई है इसलिए वह खुद से घर सारे काम नहीं कर सकती और शाजिया को अपनी पढ़ाई पर ध्यान जरुरी है।",
            "ka": "ಆದರೆ ಮೇಡಂ, ಕೆಲಸ ಮತ್ತು ಮನೆಯ ನಡುವೆ ನನಗೆ ಸಮಯ ಸಿಗುವುದಿಲ್ಲ. ಅಮ್ಮಿಗೆ ಈಗ ವಯಸ್ಸಾಗಿದೆ, ಮನೆ ಕೆಲಸವನ್ನು ತಾನೇ ನಿಭಾಯಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ ಮತ್ತು ಶಾಜಿಯಾ ತನ್ನ ಅಧ್ಯಯನದತ್ತ ಗಮನ ಹರಿಸಬೇಕಾಗಿದೆ.",
            "te": "కానీ మేడమ్, నాకు పని మరియు ఇంటి మధ్య సమయం దొరకదు. అమ్మకి ఇప్పుడు వయసొచ్చింది, ఇంటి పనులు తనంతట తానుగా చేసుకోలేకపోతుంది మరియు షాజియా తన చదువుపై దృష్టి పెట్టాలి.",
            "or": "କିନ୍ତୁ ମାଡ଼ାମ୍, ମୁଁ କାମ ଏବଂ ଘର ମଧ୍ୟରେ କ୍ୱଚିତ୍ ସମୟ ପାଏ । ଅମ୍ମି ବର୍ତ୍ତମାନ ବୃଦ୍ଧ, ସେ ନିଜେ ଘର କାମ ପରିଚାଳନା କରିବାକୁ ସକ୍ଷମ ନୁହଁନ୍ତି ଏବଂ ଶାଜିଆ ତାଙ୍କ ଅଧ୍ୟୟନ ଉପରେ ଧ୍ୟାନ ଦେବା ଆବଶ୍ୟକ ।",
            "as": "কিন্তু মেডাম, মই ঘৰৰ কামৰ কাৰণে বাকী কাম কৰিবলৈ সময় নাপাওঁ। আম্মীৰ এতিয়া বয়স হৈছে, তেঁও নিজে ঘৰৰ কাম কৰিব নোৱাৰে আৰু শ্বাজিয়াই তাইৰ পঢ়াশুনাত মন দিব লাগিব।",
            "gu": "પરંતુ મેડમ, મને કામ અને ઘર સિવાય ભાગ્યે જ સમય મળે છે. અમ્મી હવે ગરડી થઈ ગઈ છે, તે ઘરનું કામ જાતે કરી શકતી નથી અને શાઝિયાને તેના અભ્યાસ પર ધ્યાન આપવાની જરૂર છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "I understand Razia but these opportunities don’t come around very often. Take some time and think about it.",
            "hi": "मैं समझती हूँ रजिया लेकिन ऐसे मौके बार-बार नहीं मिलते। इसके बारे में सोचने के लिए थोड़ा समय लो।",
            "ka": "ನನಗೆ ಅರ್ಥವಾಗುತ್ತದೆ ರಝಿಯಾ ಆದರೆ ಈ ಅವಕಾಶಗಳು ಹೆಚ್ಚಾಗಿ ಬರುವುದಿಲ್ಲ. ಸ್ವಲ್ಪ ಸಮಯ ತೆಗೆದುಕೊಂಡು ಮತ್ತು ಅದರ ಬಗ್ಗೆ ಯೋಚಿಸು.",
            "te": "నేను అర్థం చేసుకున్నాను రజియా కానీ ఈ అవకాశాలు ఎప్పటికీ రావు. కొంచెం సమయం తీసుకుని దాని గురించి ఆలోచించు.",
            "or": "ମୁଁ ବୁଝିପାରୁଛି ରଜିଆ କିନ୍ତୁ ଏହି ସୁଯୋଗଗୁଡ଼ିକ ପ୍ରାୟତଃ ଆସେ ନାହିଁ । କିଛି ସମୟ ନିଅ ଏବଂ ଏହା ବିଷୟରେ ଚିନ୍ତା କର ।",
            "as": "মই বুজি পাইছোঁ ৰাজিয়া, কিন্তু এই সুযোগবোৰ সঘনাই নাহে। অলপ সময় লোৱা আৰু ইয়াৰ বিষয়ে চিন্তা কৰা।",
            "gu": "હું સમજું છું રઝિયા, પણ આ તકો ઓછી વાર મળતી હોય છે. થોડો સમય લે અને તેના વિશે વિચારો."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "E2_2_4//",
            "hi": "E2_2_4//",
            "ka": "E2_2_4//",
            "te": "E2_2_4//",
            "or": "E2_2_4//",
            "as": "E2_2_4//",
            "gu": "E2_2_4//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "On the way back home from the NGO, she thinks to herself.",
            "hi": "एनजीओ से घर लौटते समय रजिया विचार करती है।",
            "ka": "ಎನ್‌ಜಿಒದಿಂದ ಮನೆಗೆ ಹಿಂದಿರುಗುವಾಗ, ಅವಳು ತನ್ನಷ್ಟಕ್ಕೆ ತಾನೇ ಯೋಚಿಸುತ್ತಾಳೆ.",
            "te": "NGO నుండి ఇంటికి తిరిగి వస్తున్నప్పుడు, ఆమె తనలో తాను ఆలోచిస్తుంది.",
            "or": "NGOରୁ ଘରକୁ ଫେରିବା ବାଟରେ ସେ ମନେ ମନେ ଭାବନ୍ତି ।",
            "as": "NGO ৰ পৰা ঘৰলৈ উভতি অহাৰ পথত তাই নিজৰ লগতে কথা পাতি ভাবিব ধৰে।",
            "gu": "જ્યારે તે ઘરે પાછી આવતી હતી NGO થી તે તેના વિષે જાતે વિચારે છે. "
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "This course will help my career grow. It is a great job opportunity as well.",
            "hi": "यह कोर्स मेरे करियर में वृद्धि के लिए अच्छा है। साथ ही आय अच्छा अवसर भी है।",
            "ka": "ಈ ಕೋರ್ಸ್ ನನ್ನ ವೃತ್ತಿಜೀವನದ ಬೆಳವಣಿಗೆಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಇದೊಂದು ಉತ್ತಮ ಉದ್ಯೋಗಾವಕಾಶವೂ ಹೌದು.",
            "te": "ఈ కోర్సు నా కెరీర్‌ ఎదుగుదలకు ఉపయోగపడుతుంది. ఇది కూడా ఒక గొప్ప ఉద్యోగ అవకాశం",
            "or": "ଏହି ପାଠ୍ୟକ୍ରମ ମୋ କ୍ୟାରିୟରକୁ ବୃଦ୍ଧି କରିବାରେ ସାହାଯ୍ୟ କରିବ । ଏହା ମଧ୍ୟ ଏକ ଉତ୍ତମ ଚାକିରିର ସୁଯୋଗ ।",
            "as": "এই পাঠ্যক্ৰমে মোৰ কেৰিয়াৰ ভাল কৰাত সহায় কৰিব। এইটো এটা অতি ভাল সুযোগ।",
            "gu": "આ કોર્સ મારી કારકિર્દીને આગળ વધારવામાં મદદ કરશે. તેમાં નોકરીની પણ સારી તકો છે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "E2_2_5//",
            "hi": "E2_2_5//",
            "ka": "E2_2_5//",
            "te": "E2_2_5//",
            "or": "E2_2_5//",
            "as": "E2_2_5//",
            "gu": "E2_2_5//"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "But even though the course is for a few months, I will still have to help out at home. What if I continue with the current role itself? I am already getting opportunities to submit my work online.",
            "hi": "यह कोर्स कुछ ही महीनों के लिए है फिर भी, मुझे घर पर मदद तो करनी ही होगी। अगर मैं इसी रोल में काम करना जारी रखु तो क्या होगा? मुझे पहले से ही अपना काम ऑनलाइन सबमिट करने के अवसर मिल रहे हैं।",
            "ka": "ಆದರೆ ಕೋರ್ಸ್ ಕೆಲವು ತಿಂಗಳುಗಳಿದ್ದರೂ, ನಾನು ಇನ್ನೂ ಮನೆಯಲ್ಲಿ ಸಹಾಯ ಮಾಡಬೇಕಾಗಿದೆ. ನಾನು ಪ್ರಸ್ತುತ ಪಾತ್ರದಲ್ಲಿಯೇ ಮುಂದುವರಿದರೆ ಏನು? ನನ್ನ ಕೆಲಸವನ್ನು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಸಲ್ಲಿಸಲು ನಾನು ಈಗಾಗಲೇ ಅವಕಾಶಗಳನ್ನು ಪಡೆಯುತ್ತಿದ್ದೇನೆ.",
            "te": "అయితే కోర్సు కొన్ని నెలలు అయినప్పటికీ, నేను ఇంకా ఇంట్లో సహాయం చేయాల్సి ఉంటుంది. నేను ఇప్పుడున్న పాత్రనే కొనసాగిస్తే? నేను ఇప్పటికే నా పనిని ఆన్‌లైన్‌లో సమర్పించే అవకాశాలను పొందుతున్నాను.",
            "or": "କିନ୍ତୁ ଯଦିଓ ପାଠ୍ୟକ୍ରମ କିଛି ମାସ ପାଇଁ ଅଛି, ତଥାପି ମୋତେ ଘରେ ସାହାଯ୍ୟ କରିବାକୁ ପଡିବ । ଯଦି ମୁଁ ବର୍ତ୍ତମାନର ଭୂମିକା ଜାରି ରଖେ ତେବେ କ'ଣ ହେବ? ମୁଁ ପୂର୍ବରୁ ମୋର କାର୍ଯ୍ୟ ଅନଲାଇନରେ ଦାଖଲ କରିବାର ସୁଯୋଗ ପାଉଛି ।",
            "as": "কিন্তু যদিও এই পাঠ্যক্ৰমটো কেইমাহমানৰ বাবেহে, তথাপিও মই ঘৰৰ কামত সহায় কৰিব লাগিব। যদি মই বৰ্তমানৰ কামটোৱেই কৰি থাকো তেতিয়া কি হ'ব? মই ইতিমধ্যে মোৰ কামবোৰ অনলাইনত জমা দিয়াৰ সুযোগ পাইছোঁ।",
            "gu": "પણ આ કોર્સ થોડા મહિનાનો છે છતાં, પણ મારે ઘરમાં મદદ કરવી પડશે. જો હું વર્તમાન સ્તિથિ સાથે જ ચાલુ રાખું તો કેવું? મને પહેલેથી જ મારું કામ ઓનલાઈન મોકલવાની તકો મળી રહી છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "E2_2_6(D)",
            "hi": "E2_2_6(D)",
            "ka": "E2_2_6(D)",
            "te": "E2_2_6(D)",
            "or": "E2_2_6(D)",
            "as": "E2_2_6(D)",
            "gu": "E2_2_6(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "After a few days Razia goes back to Director Ma'am. \n\nWhat should Razia do?",
            "hi": "कुछ दिनों बाद रजिया डायरेक्टर मैम पास वापस जाती है। \n \n रजिया को क्या करना चाहिए?",
            "ka": "\"ಕೆಲವು ದಿನಗಳ ನಂತರ ರಝಿಯಾ ಮತ್ತೆ ಡೈರೆಕ್ಟರ್ ಮೇಡಮ್ ಬಳಿಗೆ ಹೋಗುತ್ತಾಳೆ.\n \n ರಝಿಯಾ ಏನು ಮಾಡಬೇಕು?\"",
            "te": "“కొన్ని రోజుల తర్వాత రజియా మళ్లీ డైరెక్టర్ మేడమ్ దగ్గరకు వెళుతుంది.రజియా ఏం చేయాలి?",
            "or": "କିଛି ଦିନ ପରେ ରଜିଆ ନିର୍ଦ୍ଦେଶକ ମାଡ଼ାମଙ୍କ ନିକଟକୁ ଫେରିଯାଆନ୍ତି । \n \n ରଜିଆ କ'ଣ କରିବା ଉଚିତ୍?",
            "as": "কেইদিনমানৰ পিছত ৰাজিয়া পৰিচালক মেডামৰ ওচৰলৈ উভতি যায়। \n \n \n ৰাজিয়াই কি কৰা উচিত?",
            "gu": "થોડા દિવસ પછી રઝિયા નિયામક મેડમ જોડે જાય છે. રઝિયાને શું કરવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take up the online course on research writing.",
            "hi": "ऑनलाइन रिसर्च रायटिंग का कोर्स करना चाहिए।",
            "ka": "ಸಂಶೋಧನಾ ಬರವಣಿಗೆಯ ಆನ್‌ಲೈನ್ ಕೋರ್ಸ್ ಅನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ.",
            "te": "పరిశోధన రచనపై ఆన్‌లైన్ కోర్సును తీసుకోవడం.",
            "or": "ଗବେଷଣା ଲେଖା ଉପରେ ଅନଲାଇନ୍ ପାଠ୍ୟକ୍ରମ ନିଅନ୍ତୁ ।",
            "as": "গৱেষণা লিখনিৰ ওপৰত অনলাইন পাঠ্যক্ৰম লওক।",
            "gu": "ઓનલાઇન કોર્સ કરવો લખાણના સંશોધન માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Continue working as a field ecologist.",
            "hi": "फिल्ड इकोलॉजिस्ट के तौर पर काम करना जारी रखना चाहिए।",
            "ka": "ಕ್ಷೇತ್ರ ಪರಿಸರ ವಿಜ್ಞಾನಿಯಾಗಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಮುಂದುವರಿಸಿ.",
            "te": "క్షేత్ర పర్యావరణ శాస్త్రవేత్త.గా పని చేయడం పని కొనసాగించడం",
            "or": "ଏକ ଫିଲ୍ଡ ଇକୋଲୋଜିଷ୍ଟ ଭାବରେ କାର୍ଯ୍ୟ ଜାରି ରଖନ୍ତୁ ।",
            "as": "ক্ষেত্ৰ পৰিস্থিতিবিদ হিচাপে কাম কৰি থাকক।",
            "gu": "કામ કરવાનું ચાલુ રાખો ફિલ્ડ ઇકોલોજિસ્ટ તરીકે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.tag",
          "value": {
            "en": "Career Growth",
            "hi": "करियर वृद्धि",
            "ka": "ವೃತ್ತಿ ಬೆಳವಣಿಗೆ",
            "te": "కెరీర్ పెరుగుదల",
            "or": "କ୍ୟାରିୟର ଅଭିବୃଦ୍ଧି",
            "as": "কেৰিয়াৰৰ ভাল কৰক।",
            "gu": "કારકિર્દી વૃદ્ધિ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.tag",
          "value": {
            "en": "Career Stability",
            "hi": "करियर में स्थिरता",
            "ka": "ವೃತ್ತಿ ಸ್ಥಿರತೆ",
            "te": "కెరీర్ స్థిరత్వం",
            "or": "କ୍ୟାରିୟର ସ୍ଥିରତା",
            "as": "কেৰিয়াৰৰ স্থিৰ কৰক।",
            "gu": "કારકિર્દી સ્થિરતા"
          }
        }
      ]
    },
    "scene28": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "E3_1_1_Take up course on Research Writing",
            "hi": "रिसर्च रायटिंग का कोर्स करना चाहिए",
            "ka": "E3_1_1_ಸಂಶೋಧನಾ ಬರವಣಿಗೆಯ ಕೋರ್ಸ್ ಅನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ",
            "te": "E3_1_1_పరిశోధన రచనపై కోర్సు తీసుకోవడం",
            "or": "E3_1_1_ଗବେଷଣା ଲେଖା ଉପରେ ପାଠ୍ୟକ୍ରମ ପ୍ରସ୍ତୁତ କରନ୍ତୁ",
            "as": "E3_1_1_গৱেষণা লিখনিৰ ওপৰত পাঠ্যক্ৰম লওক",
            "gu": "E3_1_1_સંશોધન લેખનનો અભ્યાસક્રમ માટે કોર્સ લો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia has taken up the course in research writing.\nIntially, she finds it difficult to cope with all her responsibilities. Seeing her struggle, Shazia shares some of her responsibility at home.",
            "hi": "रजिया ने रिसर्च रायटिंग का कोर्स लिया है। \n शुरू में, उसे सारी जिम्मेदारियां संभालने में मुश्किल होती थी। उसके तकलीफ को देखकर उसकी बहन शाजिया ने घर के काम में उसका हाथ बटाने का तय किया।",
            "ka": "\"ರಝಿಯಾ ಸಂಶೋಧನಾ ಬರವಣಿಗೆ ಕೋರ್ಸ್ ತೆಗೆದುಕೊಂಡಿದ್ದಾಳೆ. ಆರಂಭದಲ್ಲಿ, ಅವಳು ತನ್ನ ಎಲ್ಲಾ ಜವಾಬ್ದಾರಿಗಳನ್ನು ನಿಭಾಯಿಸಲು ಕಷ್ಟಪಡುತ್ತಾಳೆ. ಅವಳ ಹೋರಾಟವನ್ನು ನೋಡಿದ ಶಾಝಿಯಾ ತನ್ನ ಕೆಲವು ಜವಾಬ್ದಾರಿಯನ್ನು ಮನೆಯಲ್ಲಿ ಹಂಚಿಕೊಳ್ಳುತ್ತಾಳೆ.",
            "te": "‘‘రజియా పరిశోధన రచనలో కోర్సును అభ్యసించింది.\n మొదట్లో, ఆమె తన బాధ్యతలన్నింటినీ భరించడం కష్టంగా ఉంది. ఆమె కష్టాన్ని చూసిన షాజియా తన ఇంటి బాధ్యతలో కొంత భాగాన్ని పంచుకుంటుంది",
            "or": "ରଜିଆ ଗବେଷଣା ଲେଖାରେ ପାଠ୍ୟକ୍ରମ ଗ୍ରହଣ କରିଛନ୍ତି । \n ଆରମ୍ଭରେ, ସେ ତାଙ୍କର ସମସ୍ତ ଦାୟିତ୍ୱର ମୁକାବିଲା କରିବା କଷ୍ଟକର ମାନେ କରନ୍ତି । ତାଙ୍କ ସଂଘର୍ଷ ଦେଖି ଶାଜିଆ ଘରେ ତାଙ୍କର କିଛି ଦାୟିତ୍ୱ ବାଣ୍ଟି ନିଅନ୍ତି ।",
            "as": "ৰাজিয়াই গৱেষণাৰ লিখনীৰ পাঠ্যক্ৰমটো লৈছে। \n প্ৰথমতে তাই তাইৰ সকলো দায়িত্ব কৰি যাবলৈ অসুবিধা পাইছিল। তাইৰ সংগ্ৰাম দেখি শ্বাজিয়াই ঘৰত তাইৰ কিছু কামত সহায় কৰি দিছিল।",
            "gu": "રઝિયાએ સંશોધન લેખનનો કોર્સ કરે છે.\nશરૂઆતમાં, તેને તેની બધી જવાબદારીઓનો સામનો કરવો મુશ્કેલ લાગે છે. તેના સંઘર્ષને જોઈને, શાઝિયા ઘરની તેની કેટલીક જવાબદારીઓ વહેંચે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "E3_1_2",
            "hi": "E3_1_2",
            "ka": "E3_1_2",
            "te": "E3_1_2",
            "or": "E3_1_2",
            "as": "E2_2_6(D)",
            "gu": "E3_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She is not able to access the internet at home and so has to go to the office to take the course.",
            "hi": "उसके घर पर इंटरनेट की सुविधा नहीं है और इसलिए कोर्स पूरा करने के लिए उसे ऑफिस जाना पड़ता है।",
            "ka": "ಅವಳು ಮನೆಯಲ್ಲಿ ಇಂಟರ್ನೆಟ್ ಅನ್ನು ಪ್ರವೇಶಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ ಮತ್ತು ಕೋರ್ಸ್ ತೆಗೆದುಕೊಳ್ಳಲು ಕಚೇರಿಗೆ ಹೋಗಬೇಕಾಗುತ್ತದೆ.",
            "te": "ఆమె ఇంట్లో ఇంటర్నెట్‌ని యాక్సెస్ చేయలేకపోయింది మరియు కోర్సు తీసుకోవడానికి ఆఫీసుకు వెళ్లాల్సి వస్తుంది",
            "or": "ସେ ଘରେ ଇଣ୍ଟରନେଟ୍ ଆକସେସ୍ କରିବାକୁ ସକ୍ଷମ ନୁହଁନ୍ତି ଏବଂ ତେଣୁ ପାଠ୍ୟକ୍ରମ ନେବାକୁ କାର୍ଯ୍ୟାଳୟକୁ ଯିବାକୁ ପଡିବ ।",
            "as": "তাই ঘৰত ইণ্টাৰনেট ব্যৱহাৰ কৰিব পৰা নাছিল আৰু সেই কাৰণে পাঠ্যক্ৰমটো কৰিবলৈ অফিচলৈ যাব লাগিছিল।",
            "gu": "તે ઘરેથી ઈન્ટરનેટનો ઉપયોગ કરી શકતી નથી અને તેથી તેને કોર્સ કરવા માટે ઓફિસ જવું પડે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "E3_1_3",
            "hi": "E3_1_3",
            "ka": "E3_1_3",
            "te": "E3_1_3",
            "or": "E3_1_3",
            "as": "E3_1_3",
            "gu": "E3_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "After the first few weeks she makes a schedule for herself. She decides to do one lesson per day.",
            "hi": "पहले कुछ हफ़्तों के बाद वह खुद के लिए एक शेड्यूल तैयार करती है। वह हर दिन एक लेसन पूरा करने का निर्णय लेती है।",
            "ka": "ಮೊದಲ ಕೆಲವು ವಾರಗಳ ನಂತರ ಅವಳು ತನಗಾಗಿ ವೇಳಾಪಟ್ಟಿಯನ್ನು ಮಾಡಿಕೊಳ್ಳುತ್ತಾಳೆ. ಅವಳು ದಿನಕ್ಕೆ ಒಂದು ಪಾಠವನ್ನು ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
            "te": "మొదటి కొన్ని వారాల తర్వాత, ఆమె తన కోసం ఒక షెడ్యూల్‌ను రూపొందించుకుంటుంది. రోజుకు ఒక పాఠం చేయాలని ఆమె నిర్ణయించుకుంది.",
            "or": "ପ୍ରଥମ କିଛି ସପ୍ତାହ ପରେ ସେ ନିଜ ପାଇଁ ଏକ କାର୍ଯ୍ୟସୂଚୀ ତିଆରି କରନ୍ତି । ସେ ପ୍ରତିଦିନ ଗୋଟିଏ ପାଠ ଶିକ୍ଷା କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି ।",
            "as": "প্ৰথম কেইসপ্তাহমানৰ পিছত তাই নিজৰ বাবে এটা সময়সূচী বনাই লৈছিল। তাই প্ৰতিদিনে এটা পাঠ শেষ কৰাৰ সিদ্ধান্ত লয়।",
            "gu": "પ્રથમ થોડા અઠવાડિયા પછી તે પોતાના માટે સમય પત્રક બનાવે છે. તે દરરોજ એક પાઠ કરવાનું નક્કી કરે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "E3_2_1//",
            "hi": "E3_2_1//",
            "ka": "E3_2_1//",
            "te": "E3_2_1//",
            "or": "E3_2_1//",
            "as": "E3_2_1//",
            "gu": "E3_2_1//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "A few days after completing the course she gets the certificate of completion. She goes to Director Ma'am’s office to show it to her.",
            "hi": "यह कोर्स पूरा करने के कुछ दिनों बाद उसे कोर्स पूरा करने का प्रमाणपत्र मिलता है। वह डायरेक्टर मैम के ऑफिस में प्रमाणपत्र दिखाने जाती है।",
            "ka": "ಕೋರ್ಸ್ ಮುಗಿದ ಕೆಲವು ದಿನಗಳ ನಂತರ ಅವಳು ಪೂರ್ಣಗೊಳಿಸಿದ ಪ್ರಮಾಣಪತ್ರವನ್ನು ಪಡೆಯುತ್ತಾಳೆ. ಅವಳು ಅದನ್ನು ತೋರಿಸಲು ಡೈರೆಕ್ಟರ್ ಮೇಡಮ್ ಅವರ ಕಚೇರಿಗೆ ಹೋಗುತ್ತಾಳೆ.",
            "te": "కోర్సు పూర్తయిన కొన్ని రోజుల తర్వాత ఆమె పూర్తి చేసిన సర్టిఫికేట్‌ను పొందుతుంది. ఆమె దానిని చూపించడానికి డైరెక్టర్ మేడమ్ ఆఫీసుకి వెళుతుంది.",
            "or": "ପାଠ୍ୟକ୍ରମ ସମାପ୍ତ କରିବାର କିଛି ଦିନ ପରେ ସେ ସମାପ୍ତ ହେବାର ପ୍ରମାଣପତ୍ର ପାଆନ୍ତି । ସେ ତାହା ଦେଖାଇବା ପାଇଁ ନିର୍ଦ୍ଦେଶକ ମାଡ଼ାମଙ୍କ କାର୍ଯ୍ୟାଳୟକୁ ଯାଆନ୍ତି ।",
            "as": "পাঠ্যক্ৰমটো শেষ কৰাৰ কেইদিনমান পিছত তাই সম্পূৰ্ণ হোৱাৰ প্ৰমাণপত্ৰ লাভ কৰে। তাই প্ৰমাণপত্ৰখন দেখুৱাবলৈ পৰিচালিকা বাইদেউৰ কাৰ্যালয়লৈ যায়।",
            "gu": "કોર્સ પૂરો કર્યાના થોડા દિવસો પછી તે પૂરૂ કર્યાનું પ્રમાણપત્ર મળે છે. તે તેને બતાવવા માટે નિયામક મેડમની ઓફિસ જાય છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Ma'am",
            "hi": "मैम",
            "ka": "ಮೇಡಂ",
            "te": "మేడమ్",
            "or": "ମାଡ଼ାମ୍",
            "as": "মেডাম",
            "gu": "મેડમ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Well done Razia! How do you feel after coompleting the course?",
            "hi": "शाबाश रजिया! कोर्स पूरा करने के बाद तुम्हे कैसा लग रहा है?",
            "ka": "ಚೆನ್ನಾಗಿದೆ ರಝಿಯಾ! ಕೋರ್ಸ್ ಮುಗಿದ ನಂತರ ನಿನಗೆ ಏನನಿಸುತ್ತದೆ?",
            "te": "బాగా చేసావ్ రజియా! కోర్సు పూర్తి చేసిన తర్వాత మీకు ఎలా అనిపిస్తోంది?",
            "or": "ଭଲ ହୋଇଛି ରଜିଆ! ପାଠ୍ୟକ୍ରମକୁ ସମ୍ପୂର୍ଣ୍ଣ କରିବା ପରେ ତୁମେ କିପରି ଅନୁଭବ କରୁଛ?",
            "as": "চাব্বাছ ৰাজিয়া! পাঠ্যক্ৰমটো শেষ কৰাৰ পিছত তোমাৰ কেনেকুৱা লাগিছে?",
            "gu": "શાબાશ રઝિયા! કોર્સ પૂરો કર્યા પછી હવે તને કેવું લાગે છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "I’m feeling so much more confident about writing! I think balancing my lessons and the work at home helped me to manage my time better.",
            "hi": "मुझे लिखने के बारे में अभी ज्यादा आत्मविश्वास महसूस हो रहा है! मुझे लगता है कि मेरे लेसन और घर के काम को बैलेन्स करने के बाद अभी मैं समय का बेहतर नियोजन कर सकती हूँ।",
            "ka": "ಬರವಣಿಗೆಯ ಬಗ್ಗೆ ನನಗೆ ಹೆಚ್ಚು ವಿಶ್ವಾಸವಿದೆ! ನನ್ನ ಪಾಠಗಳನ್ನು ಮತ್ತು ಮನೆಯಲ್ಲಿನ ಕೆಲಸವು ಸಮತೋಲನಗೊಳಿಸುವುದು ನನ್ನ ಸಮಯವನ್ನು ಉತ್ತಮವಾಗಿ ನಿರ್ವಹಿಸಲು ನನಗೆ ಸಹಾಯ ಮಾಡಿದೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
            "te": "నేను రాయడం పట్ల చాలా నమ్మకంగా ఉన్నాను! నా పాఠాలు మరియు ఇంట్లో పనిని బ్యాలెన్స్ చేయడం వల్ల నా సమయాన్ని మెరుగ్గా నిర్వహించడంలో నాకు సహాయపడిందని నేను భావిస్తున్నాను.",
            "or": "ମୁଁ ଲେଖାଲେଖି କରିବା ବିଷୟରେ ବହୁତ ଆତ୍ମବିଶ୍ୱାସୀ ଅନୁଭବ କରୁଛି! ମୁଁ ଭାବୁଛି ମୋର ଶିକ୍ଷା ଏବଂ ଘରେ କାର୍ଯ୍ୟ ସନ୍ତୁଳନ ମୋତେ ମୋର ସମୟକୁ ଭଲ ଭାବରେ ପରିଚାଳନା କରିବାରେ ସାହାଯ୍ୟ କଲା ।",
            "as": "মোৰ লেখাৰ ক্ষেত্ৰত বহুত বেছি আত্মবিশ্বাসী যেন লাগিছে! মই ভাবো মোৰ পঢ়া আৰু ঘৰৰ কামৰ মাজত ভাৰসাম্য ৰখাটোৱে মোক মোৰ সময় ভালদৰে মিলাই লোৱাত সহায় কৰিছিল।",
            "gu": "હું મને લખવા વિશે વધારે વિશ્વાસ છે! મને લાગે છે કે મારા પાઠ અને ઘરના કામને સંતુલિત કરવાથી મને મારા સમયને વધુ સારી રીતે ગોઠવામાં મદદ મળી."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "E3_2_2////",
            "hi": "E3_2_2////",
            "ka": "E3_2_2////",
            "te": "E3_2_2////",
            "or": "E3_2_2/////",
            "as": "E3_2_2////",
            "gu": "E3_2_2////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Of course! And I am aware of how much effort that might have required.",
            "hi": "सही कहा! और मुझे पता है कि तुम्हें इसके लिए बहुत मेहनत करनी पड़ी होगी।",
            "ka": "ಖಂಡಿತವಾಗಿ! ಮತ್ತು ಅದಕ್ಕೆ ಎಷ್ಟು ಪ್ರಯತ್ನ ಬೇಕಾಗಬಹುದು ಎಂದು ನನಗೆ ತಿಳಿದಿದೆ.",
            "te": "అవును! మరియు దానికి ఎంత ప్రయత్నం అవసరమో నాకు తెలుసు.",
            "or": "ନିଶ୍ଚିତ! ମୁଁ ଜାଣିଛି ଯେ ଏହା କେତେ ପ୍ରୟାସ ଆବଶ୍ୟକ କରିଥାଇପାରେ ।",
            "as": "নিশ্চয়! আৰু মই জানো সেই খিনি কৰিবলৈ কিমান পৰিশ্ৰম কৰিব লগা হৈছে।",
            "gu": "ચોકસ! અને હા મને ખબર છે કે તેના માટે કેટલા પ્રયત્નોની જરૂર પડી શકે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "No no Ma'am, I understand the impact the course will have on my work.",
            "hi": "नहीं नहीं मैम, मुझे पट है कि इस कोर्स का मेरे काम पर कितना प्रभाव होगा।",
            "ka": "ಇಲ್ಲ ಮೇಡಂ, ಕೋರ್ಸ್ ನನ್ನ ಕೆಲಸದ ಮೇಲೆ ಬೀರುವ ಪ್ರಭಾವವನ್ನು ನಾನು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೇನೆ.",
            "te": "కాదు కాదు మేడమ్,కోర్సు నా పనిపై ఎలాంటి ప్రభావం చూపుతుందో నాకు అర్థమైంది.",
            "or": "ନା ମାଡ଼ାମ୍, ମୁଁ ବୁଝିପାରୁଛି ପାଠ୍ୟକ୍ରମ ମୋ କାର୍ଯ୍ୟ ଉପରେ ପ୍ରଭାବ ପକାଇବ ।",
            "as": "নহয় মেডাম, মই বুজি পাইছোঁ যে পাঠ্যক্ৰমটোৱে মোৰ কামত কি প্ৰভাৱ পেলাব।",
            "gu": "ના ના મેડમ, હું સમજું છું કે મારા કામ પર શું અસર પડશે કોર્સથી."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "I was also waiting to give you the new offer letter. Razia, would you like to lead our mangrove research unit?",
            "hi": "मैं तुम्हें नया ऑफर लेटर देने के लिए इंतजार कर रही थी। रजिया, क्या तुम अपने मैनग्रोग रिसर्च युनिट लीडर बनना पसंद करोगी?",
            "ka": "ನಾನೂ ನಿನಗೆ ಹೊಸ ಆಫರ್ ಲೆಟರ್ ಕೊಡಲು ಕಾಯುತ್ತಿದ್ದೆ. ರಝಿಯಾ, ನೀನು ನಮ್ಮ ಮ್ಯಾಂಗ್ರೋವ್ ಸಂಶೋಧನಾ ಘಟಕವನ್ನು ಮುನ್ನಡೆಸಲು ಬಯಸುತ್ತೀಯಾ?",
            "te": "నేను కూడా మీకు కొత్త ఆఫర్ లెటర్ ఇవ్వాలని ఎదురు చూస్తున్నాను. రజియా, నువ్వు మన మాంగ్రూవ్ పరిశోధన విభాగానికి నాయకత్వం వహించాలనుకుంటున్నారా?",
            "or": "ମୁଁ ମଧ୍ୟ ତୁମକୁ ନୂତନ ଅଫର ଚିଠି ଦେବାକୁ ଅପେକ୍ଷା କରିଥିଲି । ରଜିଆ, ତୁମେ ଆମର ମାନଗ୍ରୁଭ୍ ଗବେଷଣା ୟୁନିଟ୍ ର ନେତୃତ୍ୱ ନେବାକୁ ଚାହୁଁଛ କି?",
            "as": "মইও তোমাক নতুন অফাৰ লেটাৰখন দিবলৈ ৰৈ আছিলো। ৰাজিয়া, তুমি আমাৰ মেংগ্ৰোভ গৱেষণা গোটক নেতৃত্ব দিব বিচাৰা নেকি?",
            "gu": "હું પણ તમને નવો ઑફર લેટર આપવાની રાહ જોઈ રહી હતી. રઝિયા, શું તને અમારા મેન્ગ્રોવ રિસર્ચ યુનિટનું નેતૃત્વ કરવા માંગો છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Yes Director Ma'am, I would love to!",
            "hi": "हाँ, डायरेक्टर मैम, मुझे बहुत खुशी होगी!",
            "ka": "ಹೌದು ಡೈರೆಕ್ಟರ ಮೇಡಂ, ನಾನು ಇಷ್ಟಪಡುತ್ತೇನೆ!",
            "te": "అవును డైరెక్టర్ మేడమ్, నాకు ఇష్టం కూడా!",
            "or": "ହଁ ନିର୍ଦ୍ଦେଶକ ମାଡ଼ାମ୍, ମୁଁ ପସନ୍ଦ କରିବି!",
            "as": "হয় পৰিচালক মেডাম, মই ভাল পাম!",
            "gu": "હા નિયામક મેડમ, મને ગમશે!"
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "E3_3_1",
            "hi": "E3_3_1",
            "ka": "E3_3_1",
            "te": "E3_3_1",
            "or": "E3_3_1",
            "as": "E3_3_1",
            "gu": "E3_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia continues working at the NGO and goes ahead to publish many papers about mangrove ecosystems.",
            "hi": "रजिया एनजीओ में काम करना शुरू रखती है और आगे जाकर मैनग्रोव इकोसिस्टम पर कई पेपर्स प्रकाशित करती है।",
            "ka": "ರಝಿಯಾ ಎನ್‌ಜಿಒದಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಮುಂದುವರೆಸಿದ್ದಾಳೆ ಮತ್ತು ಮ್ಯಾಂಗ್ರೋವ್ ಪರಿಸರ ವ್ಯವಸ್ಥೆಗಳ ಕುರಿತು ಅನೇಕ ಪತ್ರಿಕೆಗಳನ್ನು ಪ್ರಕಟಿಸಲು ಮುಂದಾಗುತ್ತಾಳೆ.",
            "te": "రజియా NGOలో పని చేస్తూనే ఉంది మరియు మాంగ్రూవ్ అడవుల పర్యావరణ వ్యవస్థల గురించి అనేక పత్రాలను ప్రచురించడానికి ముందుకు సాగింది.",
            "or": "ରଜିଆ NGOରେ କାର୍ଯ୍ୟ ଜାରି ରଖିଛନ୍ତି ଏବଂ ମାନଗ୍ରୁଭ୍ ଇକୋସିଷ୍ଟମ ବିଷୟରେ ଅନେକ ଲେଖା ପ୍ରକାଶ କରନ୍ତି ।",
            "as": "ৰাজিয়াই NGO'ত কাম কৰি আছে আৰু মেংগ্ৰোভ পৰিস্থিতিতন্ত্ৰৰ বিষয়ে বহুতো লেখা প্ৰকাশ কৰিবলৈ আগবাঢ়িছে।",
            "gu": "રઝિયા NGOમાં કામ કરવાનું ચાલુ રાખે છે અને મેન્ગ્રોવ ઇકોસિસ્ટમ વિશે ઘણા પેપર પ્રકાશિત કરવામાં આગળ વધે છે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "E3_3_2",
            "hi": "E3_3_2",
            "ka": "E3_3_2",
            "te": "E3_3_2",
            "or": "E3_3_2",
            "as": "E3_3_2",
            "gu": "E3_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Her work becomes very famous, and she travels across the country giving lectures and attending seminars on coastal ecology.",
            "hi": "उसका काम बहुत लोकप्रिय होता है और वह पुरे देश में घूम कर लेक्चर्स देती है और कोस्टल इकोलॉजी पर सेमिनार अटेंड करती है।",
            "ka": "ಅವಳ ಕೆಲಸವು ಬಹಳ ಪ್ರಸಿದ್ಧವಾಗಿದೆ, ಮತ್ತು ಅವಳು ದೇಶಾದ್ಯಂತ ಪ್ರವಾಸ ಮಾಡಿ ಉಪನ್ಯಾಸಗಳನ್ನು ನೀಡುತ್ತಾಳೆ ಮತ್ತು ಕರಾವಳಿ ಪರಿಸರ ವಿಜ್ಞಾನದ ಕುರಿತು ಸೆಮಿನಾರ್‌ಗಳಿಗೆ ಹಾಜರಾಗುತ್ತಾಳೆ.",
            "te": "ఆమె పని చాలా ప్రసిద్ధి చెందింది మరియు ఆమె దేశమంతటా తిరుగుతూ ఉపన్యాసాలు ఇస్తూ మరియు తీరప్రాంత జీవావరణ శాస్త్రంపై సెమినార్‌లకు హాజరవుతుంది.",
            "or": "ତାଙ୍କର କାର୍ଯ୍ୟ ବହୁତ ପ୍ରସିଦ୍ଧ ହୋଇଯାଏ, ଏବଂ ସେ ବକ୍ତୃତା ଦେବା ଏବଂ ଉପକୂଳ ପରିବେଶ ଉପରେ ସେମିନାରରେ ଯୋଗ ଦେବା ପାଇଁ ସାରା ଦେଶ ଭ୍ରମଣ କରନ୍ତି ।",
            "as": "তাইৰ কাম অতি বিখ্যাত হৈ পৰে, আৰু তাই সমগ্ৰ দেশভ্ৰমণ কৰি বক্তৃতা দিয়ে আৰু উপকূলীয় পৰিস্থিতি বিজ্ঞানৰ ওপৰত আলোচনাচক্ৰত অংশগ্ৰহণ কৰে।",
            "gu": "તેણીનું કાર્ય ખૂબ જ પ્રખ્યાત બને છે, અને તે દરિયાકાંઠાના ઇકોલોજી પર પ્રવચનો આપવા અને સેમિનારોમાં હાજરી આપવા માટે સમગ્ર દેશમાં પ્રવાસ કરે છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "E3_3_3",
            "hi": "E3_3_3",
            "ka": "E3_3_3",
            "te": "E3_3_3",
            "or": "E3_3_3",
            "as": "E3_3_3",
            "gu": "E3_3_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Her family is very proud of her. Ammi encourages her to go work, while she and abbu now manage the house.",
            "hi": "उसके परिवार को उस पर गर्व है। अम्मी उसे काम करने के लिए प्रेरणा देती है और वह अब्बू के साथ घर चलाती है।",
            "ka": "ಅವಳ ಕುಟುಂಬವು ಅವಳ ಬಗ್ಗೆ ತುಂಬಾ ಹೆಮ್ಮೆಪಡುತ್ತದೆ. ಅಮ್ಮಿ ಅವಳನ್ನು ಕೆಲಸಕ್ಕೆ ಹೋಗಲು ಪ್ರೋತ್ಸಾಹಿಸುತ್ತಾಳೆ, ಆದರೆ ಅವಳು ಮತ್ತು ಅಬ್ಬು ಈಗ ಮನೆಯನ್ನು ನಿರ್ವಹಿಸುತ್ತಾರೆ.",
            "te": "ఆమె కుటుంబం ఆమె గురించి చాలా గర్వంగా ఉంది. అమ్మి ఆమెను పనికి వెళ్ళమని ప్రోత్సహిస్తుంది, ఆమె మరియు అబ్బు ఇప్పుడు ఇంటిని నిర్వహిస్తున్నారు.",
            "or": "ତାଙ୍କ ପରିବାର ତାଙ୍କ ପାଇଁ ବହୁତ ଗର୍ବିତ । ଅମ୍ମି ତାଙ୍କୁ କାମକୁ ଯିବାକୁ ଉତ୍ସାହିତ କରୁଥିବାବେଳେ ସେ ଏବଂ ଅବୁ ବର୍ତ୍ତମାନ ଘର ପରିଚାଳନା କରନ୍ତି ।",
            "as": "তাইৰ পৰিয়ালে তাইক লৈ বহুত গৌৰৱান্বিত। আম্মীয়ে তাইক কামলৈ যাবলৈ উৎসাহিত কৰে, আনহাতে তাই আৰু আব্বুৱে এতিয়া ঘৰটো পৰিচালনা কৰে।",
            "gu": "તેનો પરિવારને તેના પર ખૂબ ગર્વ છે. અમ્મી તેને કામ પર જવા માટે પ્રોત્સાહિત કરે છે, જ્યારે તે અને અબ્બુ હવે ઘર સંભાળે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "Upskilling",
            "hi": "अपस्किल करना",
            "ka": "ಉನ್ನತ ಕೌಶಲ್ಯ",
            "te": "నైపుణ్యం పెంచడం",
            "or": "ଅପସ୍କିଲିଂ",
            "as": "আপস্কিলিং",
            "gu": "ઉચ્ચ કૌશલ્ય"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained upskilling experience!",
            "hi": "आपने अभी-अभी अपस्किल करने का अनुभव प्राप्त किया है!",
            "ka": "ನೀವು ಕೇವಲ ಕೌಶಲ್ಯದ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే నైపుణ్యం పెంచుకునే అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ଅପସ୍କିଲିଂ ଅଭିଜ୍ଞତା ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ উত্থানৰ অভিজ্ঞতা লাভ কৰিছা!",
            "gu": "તમે હમણાં જ ઉચ્ચ કૌશલ્યનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Upskilling in career",
            "hi": "करियर में अपस्किल करना",
            "ka": "ವೃತ್ತಿಯಲ್ಲಿ ಉನ್ನತ ಕೌಶಲ್ಯ",
            "te": "కెరీర్‌లో నైపుణ్యాన్ని పెంచుకోవడం",
            "or": "କ୍ୟାରିୟରରେ ଅପସ୍କିଲିଂ",
            "as": "কেৰিয়াৰত আপস্কিলিং",
            "gu": "કારકિર્દીમાં ઉચ્ચ કૌશલ્ય"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Development Skills",
            "hi": "करियर डेवलपमेंट कौशल",
            "ka": "ವೃತ್ತಿ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು",
            "te": "కెరీర్ అభివృద్ధి నైపుణ్యాలు",
            "or": "କ୍ୟାରିୟର ବିକାଶ ଦକ୍ଷତା",
            "as": "কেৰিয়াৰ বিকাশৰ দক্ষতা",
            "gu": "કારકિર્દી વિકાસ કૌશલ્યો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "To learn how to conduct research and write about it, Razia takes up a research and writing course online. She had to do this to be eligible for the new role. To learn a new skill is called upskilling. Upskilling is expanding your knowledge and enhancing your skills for a specific position or a new job. Sometimes organizations conduct training for all the employees, and sometimes you see an opportunity that you can apply for and upskill.",
            "hi": "रिसर्च करना और इसके बारे में लिखना सीखने के लिए, रजिया ऑनलाइन रिसर्च और रायटिंग कोर्स करती है। नए रोल के लायक बनने के लिए उसे यह कोर्स करना पड़ता है। कोई भी कौशल सीखना मतलब अपस्किल करना।अपस्किल करना मतलब किसी विशेष नए जॉब के लिए अपने ज्ञान को बढ़ाना और अपने कौशल अधिक अच्छा करना। कभी-कभी संस्थाएं अपने कर्मचारियों के लिए ट्रेनिंग आयोजित करती है और कभी-कभी आपको ऐसा मौका मिलता है कि जिसके लिए आप अप्लाई कर सकते हैं और अपस्किल कर सकते हैं।",
            "ka": "ಸಂಶೋಧನೆ ನಡೆಸುವುದು ಮತ್ತು ಅದರ ಬಗ್ಗೆ ಬರೆಯುವುದು ಹೇಗೆ ಎಂದು ತಿಳಿಯಲು, ರಝಿಯಾ ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಸಂಶೋಧನೆ ಮತ್ತು ಬರವಣಿಗೆ ಕೋರ್ಸ್ ಅನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾಳೆ. ಹೊಸ ಪಾತ್ರಕ್ಕೆ ಅರ್ಹರಾಗಲು ಅವಳು ಇದನ್ನು ಮಾಡಬೇಕಾಗಿತ್ತು. ಹೊಸ ಕೌಶಲ್ಯವನ್ನು ಕಲಿಯಲು ಉನ್ನತ ಕೌಶಲ್ಯ ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ. ಉನ್ನತ ಕೌಶಲ್ಯವು ನಿಮ್ಮ ಜ್ಞಾನವನ್ನು ವಿಸ್ತರಿಸುವುದು ಮತ್ತು ನಿರ್ದಿಷ್ಟ ಸ್ಥಾನ ಅಥವಾ ಹೊಸ ಉದ್ಯೋಗಕ್ಕಾಗಿ ನಿಮ್ಮ ಕೌಶಲ್ಯಗಳನ್ನು ಹೆಚ್ಚಿಸುವುದು. ಕೆಲವೊಮ್ಮೆ ಸಂಸ್ಥೆಗಳು ಎಲ್ಲಾ ಉದ್ಯೋಗಿಗಳಿಗೆ ತರಬೇತಿಯನ್ನು ನಡೆಸುತ್ತವೆ ಮತ್ತು ಕೆಲವೊಮ್ಮೆ ನೀವು ಅರ್ಜಿ ಸಲ್ಲಿಸುವ ಮತ್ತು ಕೌಶಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸುವ ಅವಕಾಶವನ್ನು ನೋಡುತ್ತೀರಿ.",
            "te": "పరిశోధన మరియు దాని గురించి వ్రాయడం ఎలాగో తెలుసుకోవడానికి, రజియా ఆన్‌లైన్‌లో పరిశోధన మరియు రచన కోర్సును తీసుకుంటుంది. కొత్త పాత్రకు అర్హత సాధించేందుకు ఆమె ఇలా చేయాల్సి వచ్చింది. కొత్త నైపుణ్యాన్ని నేర్చుకోవడాన్ని అప్‌స్కిల్లింగ్ అంటారు. నైపుణ్యం అనేది మీ జ్ఞానాన్ని విస్తరించడం మరియు నిర్దిష్ట స్థానం లేదా కొత్త ఉద్యోగం కోసం మీ నైపుణ్యాలను మెరుగుపరచడం. కొన్నిసార్లు సంస్థలు ఉద్యోగులందరికీ శిక్షణను నిర్వహిస్తాయి మరియు కొన్నిసార్లు మీరు దరఖాస్తు చేసుకునే మరియు నైపుణ్యం పెంచుకునే అవకాశాన్ని చూస్తారు.",
            "or": "ଗବେଷଣା କିପରି କରିବେ ଏବଂ ଏହା ବିଷୟରେ ଲେଖିବେ ଶିଖିବା ପାଇଁ, ରଜିଆ ଅନଲାଇନରେ ଏକ ଗବେଷଣା ଏବଂ ଲେଖାଲେଖି କରିବା ପାଠ୍ୟକ୍ରମ ଗ୍ରହଣ କରନ୍ତି । ନୂତନ ଭୂମିକା ପାଇଁ ଯୋଗ୍ୟ ହେବା ପାଇଁ ତାଙ୍କୁ ଏହା କରିବାକୁ ପଡିଲା । ଏକ ନୂତନ କୌଶଳ ଶିଖିବାକୁ ଅପସ୍କିଲିଂ କୁହାଯାଏ । ଅପସ୍କିଲିଂ ଆପଣଙ୍କ ଜ୍ଞାନକୁ ବିସ୍ତାର କରେ ଏବଂ ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ପଦବୀ କିମ୍ବା ଏକ ନୂତନ ଚାକିରି ପାଇଁ ଆପଣଙ୍କର ଦକ୍ଷତା ବୃଦ୍ଧି କରେ । ବେଳେବେଳେ ସଂଗଠନଗୁଡିକ ସମସ୍ତ କର୍ମଚାରୀଙ୍କ ପାଇଁ ତାଲିମ ପରିଚାଳନା କରନ୍ତି, ଏବଂ ବେଳେବେଳେ ଆପଣ ଏକ ସୁଯୋଗ ପାଆନ୍ତି ଯାହା ପାଇଁ ଆପଣ ଆବେଦନ କରିପାରିବେ ଏବଂ ଅପସ୍କିଲ୍ କରିପାରିବେ ।",
            "as": "গৱেষণা কেনেদৰে কৰিব লাগে আৰু ইয়াৰ বিষয়ে লিখিবলৈ শিকিবলৈ, ৰাজিয়াই অনলাইনত এটা গৱেষণা আৰু লিখাৰ পাঠ্যক্ৰম লয়। নতুন কামটোৰ বাবে যোগ্য হ'বলৈ তাই এইটো কৰিব লগা হৈছিল। নতুন কাম এটাত দক্ষ হোৱাটোকে আপস্কিলিং বুলি কোৱা হয়। আপস্কিলিং হৈছে তোমাৰ জ্ঞান সম্প্ৰসাৰিত কৰা আৰু এটা নিৰ্দিষ্ট স্থিতি বা নতুন চাকৰিৰ বাবে তোমাৰ দক্ষতা বৃদ্ধি কৰা। কেতিয়াবা সংগঠনবোৰে সকলো কৰ্মচাৰীৰ বাবে প্ৰশিক্ষণ পৰিচালনা কৰে, আৰু কেতিয়াবা তুমি এনে এটা সুযোগ পাব পাৰা য'ত তুমি আবেদন কৰিব পাৰা আৰু দক্ষতা বৃদ্ধি কৰিব পাৰা।",
            "gu": "સંશોધન કેવી રીતે કરવું અને તેના વિશે લખવું તે જાણવા માટે, રઝિયા ઑનલાઇન સંશોધન અને લેખન અભ્યાસક્રમો લે છે. નવા રાઉન્ડ માટે લાયક થવા માટે તેણે આ કરવું પડ્યું. નવા કૌશલ્યો શીખવાને કૌશલ્ય વિકાસ કહેવાય છે. અપસ્કિલિંગ એ ચોક્કસ નોકરી અથવા નવી નોકરી માટે તમારા જ્ઞાન અને કૌશલ્યોને વધારવા વિશે છે. સંસ્થાઓ કેટલીકવાર બધા કર્મચારીઓ માટે તાલીમનું આયોજન કરે છે, અને કેટલીકવાર તેઓ એવી તકો જુએ છે કે જેના માટે તમે અરજી કરી શકો અને ઉચ્ચ કૌશલ્યો મેળવી શકો."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ1",
            "hi": "MCQ1",
            "ka": "MCQ1",
            "te": "MCQ1",
            "or": "MCQ1",
            "as": "MCQ1",
            "gu": "MCQ1"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia does internet research to find and gain a scholarship. Internet research helps us gain basic information about careers and industries. It also helps us know about new opportunities. Select the statement which is TRUE:",
            "hi": "रजिया इंटरनेट पर रिसर्च करके स्कोलरशिप के बारे में जानकारी प्राप्त करती है। इंटरनेट रिसर्च हमें करियर और उद्योगों के बारे में मुलभुत जानकारी प्राप्त करने में मदद करता है। यह हमें नए अवसरों के बारे में जानकारी प्राप्त करने में भी मदद करता है। सही विधान को चुनें:",
            "ka": "ವಿದ್ಯಾರ್ಥಿವೇತನವನ್ನು ಹುಡುಕಲು ಮತ್ತು ಪಡೆಯಲು ರಝಿಯಾ ಇಂಟರ್ನೆಟ್ ಸಂಶೋಧನೆ ಮಾಡುತ್ತಾಳೆ. ಇಂಟರ್ನೆಟ್ ಸಂಶೋಧನೆಯು ವೃತ್ತಿ ಮತ್ತು ಉದ್ಯಮಗಳ ಬಗ್ಗೆ ಮೂಲಭೂತ ಮಾಹಿತಿಯನ್ನು ಪಡೆಯಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಹೊಸ ಅವಕಾಶಗಳ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಲು ಇದು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನಿಜವಾಗಿರುವ ಹೇಳಿಕೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ:",
            "te": "రజియా స్కాలర్‌షిప్‌ (ఉపకార వేతనం) గురించి కనుగొని దానిని పొందేందుకు ఇంటర్నెట్ పరిశోధన చేస్తుంది.కెరీర్‌లు మరియు పరిశ్రమల గురించి ప్రాథమిక సమాచారాన్ని పొందడంలో ఇంటర్నెట్ పరిశోధన మాకు సహాయపడుతుంది. ఇది కొత్త అవకాశాల గురించి తెలుసుకోవడానికి కూడా మాకు సహాయపడుతుంది:ఏది నిజమైన స్టేట్ మెంటో దానిని ఎంచుకోండి",
            "or": "ରଜିଆ ଏକ ଛାତ୍ରବୃତ୍ତି ଖୋଜିବା ଏବଂ ହାସଲ କରିବା ପାଇଁ ଇଣ୍ଟରନେଟ୍ ରେ ଅନୁସନ୍ଧାନ କରନ୍ତି । ଇଣ୍ଟରନେଟ୍ ରେ ଅନୁସନ୍ଧାନ ଆମକୁ କ୍ୟାରିୟର ଏବଂ ଶିଳ୍ପ ବିଷୟରେ ମୌଳିକ ସୂଚନା ହାସଲ କରିବାରେ ସାହାଯ୍ୟ କରେ । ଏହା ଆମକୁ ନୂତନ ସୁଯୋଗ ବିଷୟରେ ଜାଣିବାରେ ମଧ୍ୟ ସାହାଯ୍ୟ କରେ । ଠିକ ଥିବା ଉକ୍ତିଟି ଚୟନ କରନ୍ତୁ:",
            "as": "ৰাজিয়াই স্কলাৰশ্বিপ বিচাৰিবলৈ আৰু লাভ কৰিবলৈ ইণ্টাৰনেট ব্যৱহাৰ কৰে। ইণ্টাৰনেটৰ ব্যৱহাৰে আমাক কেৰিয়াৰ আৰু উদ্যোগৰ বিষয়ে মৌলিক তথ্য আহৰণ কৰাত সহায় কৰে। ই আমাক নতুন সুযোগৰ বিষয়ে জনাত সহায় কৰে।সঁচা উক্তিটো বাছনি কৰা:",
            "gu": "રઝિયા શિષ્યવૃત્તિ શોધવા અને મેળવવા માટે ઇન્ટરનેટ પર જોવે છે. ઈન્ટરનેટ સંશોધન અમને વ્યવસાયો અને ઉદ્યોગો વિશે મૂળભૂત માહિતી મેળવવામાં મદદ કરે છે. તે અમને નવી તકો વિશે જાણવામાં મદદ કરે છે. સાચું નિવેદન પસંદ કરો:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "The internet is the only way to do research and find information.",
            "hi": "रिसर्च करने और जानकारी प्राप्त करने के लिए केवल इंटरनेट ही एकमात्र मार्ग है",
            "ka": "ಸಂಶೋಧನೆ ಮಾಡಲು ಮತ್ತು ಮಾಹಿತಿಯನ್ನು ಹುಡುಕಲು ಇಂಟರ್ನೆಟ್ ಏಕೈಕ ಮಾರ್ಗವಾಗಿದೆ.",
            "te": "పరిశోధనలు చేయడానికి మరియు సమాచారాన్ని కనుగొనడానికి ఇంటర్నెట్ మాత్రమే ఏకైక మార్గం.",
            "or": "ଗବେଷଣା କରିବା ଏବଂ ସୂଚନା ଖୋଜିବା ପାଇଁ ଇଣ୍ଟରନେଟ୍ ହେଉଛି ଏକମାତ୍ର ଉପାୟ ।",
            "as": "গৱেষণা আৰু তথ্য বিচাৰি উলিওৱাৰ একমাত্ৰ উপায় হৈছে ইণ্টাৰনেট।",
            "gu": "ઈન્ટરનેટ એકમાત્ર રસ્તો છે જે સંશોધન કરવા અને માહિતી શોધવામાં મદદ કરે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "To do research we can talk to people and read books and newspapers",
            "hi": "रिसर्च करने के लिए हम लोगों से बात कर सकते हैं और किताबें और अख़बार पढ़ सकते हैं",
            "ka": "ಸಂಶೋಧನೆ ಮಾಡಲು ನಾವು ಜನರೊಂದಿಗೆ ಮಾತನಾಡಬಹುದು ಮತ್ತು ಪುಸ್ತಕಗಳು ಮತ್ತು ಪತ್ರಿಕೆಗಳನ್ನು ಓದಬಹುದು",
            "te": "పరిశోధన చేయడానికి మనం ప్రజలతో మాట్లాడవచ్చు మరియు పుస్తకాలు మరియు వార్తాపత్రికలు చదవవచ్చు",
            "or": "ଗବେଷଣା କରିବା ପାଇଁ ଆମେ ଲୋକଙ୍କ ସହ କଥା ହୋଇପାରିବା ଏବଂ ପୁସ୍ତକ ଏବଂ ଖବରକାଗଜ ପଢିପାରିବା ।",
            "as": "গৱেষণা কৰিবলৈ আমি মানুহৰ সৈতে কথা পাতিব পাৰোঁ আৰু কিতাপ আৰু বাতৰি কাকত পঢ়িব পাৰোঁ",
            "gu": "સંશોધન કરવા માટે આપણે લોકો સાથે વાત કરી શકીએ છીયે અને પુસ્તકો અને સમાચાર પત્રો વાંચી શકીએ છીએ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Research is required only when one needs to find a scholarship to study",
            "hi": "रिसर्च केवल तभी किया जाता है जब किसी को पढ़ाई के लिए स्कोलरशिप के बारे में जानकारी चाहिए होती है।",
            "ka": "ಅಧ್ಯಯನ ಮಾಡಲು ವಿದ್ಯಾರ್ಥಿವೇತನವನ್ನು ಹುಡುಕಬೇಕಾದಾಗ ಮಾತ್ರ ಸಂಶೋಧನೆಯ ಅಗತ್ಯವಿದೆ",
            "te": "చదువుకోవడానికి స్కాలర్‌షిప్‌ (ఉపకార వేతనం )ను కనుగొనవలసి వచ్చినప్పుడు మాత్రమే పరిశోధన అవసరం",
            "or": "ଗବେଷଣା କେବଳ ସେତେବେଳେ ଆବଶ୍ୟକ ହୁଏ ଯେତେବେଳେ ଜଣେ ଅଧ୍ୟୟନ ପାଇଁ ଛାତ୍ରବୃତ୍ତି ଖୋଜନ୍ତି ।",
            "as": "অধ্যয়নৰ বাবে স্কলাৰশ্বিপ বিচৰাৰ বাবেহে গৱেষণাৰ প্ৰয়োজন",
            "gu": "ત્યારેજ શોધખોળ જરૂરી છે જ્યારે કોઈએ અભ્યાસ માટે શિષ્યવૃત્તિ શોધવી હોય "
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Think again! To do research we can talk to people and read books and newspapers.",
            "hi": "फिर से विचार करें! रिसर्च करने के लिए हम लोगो के साथ बात कर सकते हैं और और किताबें और अख़बार पढ़ सकते हैं।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಸಂಶೋಧನೆ ಮಾಡಲು ನಾವು ಜನರೊಂದಿಗೆ ಮಾತನಾಡಬಹುದು ಮತ್ತು ಪುಸ್ತಕಗಳು ಮತ್ತು ಪತ್ರಿಕೆಗಳನ್ನು ಓದಬಹುದು.",
            "te": "మళ్లీ ఆలోచించండి! పరిశోధన చేయడానికి మనం ప్రజలతో మాట్లాడవచ్చు మరియు పుస్తకాలు మరియు వార్తాపత్రికలు చదవవచ్చు.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ଗବେଷଣା କରିବା ପାଇଁ ଆମେ ଲୋକଙ୍କ ସହ କଥା ହୋଇପାରିବା ଏବଂ ପୁସ୍ତକ ଏବଂ ଖବରକାଗଜ ପଢିପାରିବା ।",
            "as": "আকৌ চিন্তা কৰা! গৱেষণা কৰিবলৈ আমি মানুহৰ সৈতে কথা পাতিব পাৰোঁ আৰু কিতাপ আৰু বাতৰি কাকত পঢ়িব পাৰোঁ।",
            "gu": "ફરીથી વિચારો ! સંશોધન કરવા માટે આપણે લોકો સાથે વાત કરી શકીએ છીએ અને પુસ્તકો અને સમાચાર પત્રો પણ વાંચી શકીએ છીએ."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "शाबाश!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "గొప్ప పని!",
            "or": "ବହୁତ ଭଲ!",
            "as": "ভাল কাম!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! Research can help us be aware of opportunities throughout our careers.",
            "hi": "फिर से विचार करें! रिसर्च हमें अपने करियर के अवसरों के बारे में जागरूक होने के लिए मदद करता है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ನಮ್ಮ ವೃತ್ತಿಜೀವನದುದ್ದಕ್ಕೂ ಅವಕಾಶಗಳ ಬಗ್ಗೆ ಅರಿವು ಮೂಡಿಸಲು ಸಂಶೋಧನೆ ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
            "te": "మళ్లీ ఆలోచించండి! మన కెరీర్‌లో అవకాశాల గురించి తెలుసుకోవడంలో పరిశోధన సహాయపడుతుంది.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ଗବେଷଣା ଆମକୁ ଆମର କ୍ୟାରିୟରରେ ସୁଯୋଗ ବିଷୟରେ ସଚେତନ ହେବାରେ ସାହାଯ୍ୟ କରିପାରିବ ।",
            "as": "আকৌ চিন্তা কৰা! গৱেষণাই আমাক আমাৰ সমগ্ৰ কেৰিয়াৰত সুযোগৰ বিষয়ে সজাগ হোৱাত সহায় কৰিব পাৰে।",
            "gu": "ફરીથી વિચારો ! સંશોધન અમારી સમગ્ર કારકિર્દી દરમિયાન તકોથી જાણકાર રહેવામાં મદદ કરી શકે છે."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ4",
            "hi": "MCQ4",
            "ka": "MCQ4",
            "te": "MCQ4",
            "or": "ଏମସିକ୍ୟୁ4",
            "as": "MCQ4",
            "gu": "MCQ4"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia negotiates with her family to allow her to study in the city. Negotiation helps people with differing points of view to reach an agreement to finalise a decision. In your life, where do you think negotiation plays a role?",
            "hi": "रजिया शहर में पढ़ने के लिए जाने के लिए अपने माता-पिता के साथ निगोशिएट करती है। निगोशिएट करने से अलग विचारों के लोगों को किसी एक निर्णय पर सहमत होने में मदद होती है। आपकी जिंदगी में, निगोशिएट करना कहां काम आता है ऐसा आपको लगता हैं?",
            "ka": "ರಝಿಯಾ ತನ್ನ ಕುಟುಂಬದೊಂದಿಗೆ ನಗರದಲ್ಲಿ ಓದಲು ಅವಕಾಶ ನೀಡಲು ಮಾತುಕತೆ ನಡೆಸುತಾಳೆ. ನಿರ್ಧಾರವನ್ನು ಅಂತಿಮಗೊಳಿಸಲು ಒಪ್ಪಂದವನ್ನು ತಲುಪಲು ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳನ್ನು ಹೊಂದಿರುವ ಜನರಿಗೆ ಮಾತುಕತೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನಿಮ್ಮ ಜೀವನದಲ್ಲಿ, ಮಾತುಕತೆಯು ಎಲ್ಲಿ ಒಂದು ಪಾತ್ರವನ್ನು ವಹಿಸುತ್ತದೆ ಎಂದು ನೀವು ಭಾವಿಸುತ್ತೀರಿ?",
            "te": "రజియా నగరంలో చదువుకోవడానికి అనుమతించమని తన కుటుంబంతో చర్చలు జరిపింది .భిన్నమైన అభిప్రాయాలు ఉన్న వ్యక్తులు ఒక నిర్ణయాన్ని ఖరారు చేయడానికి ఒక ఒప్పందాన్ని చేరుకోవడానికి చర్చలు సహాయపడతాయి. మీ జీవితంలో, నెగోషియేషన్ ( చర్చ) ఎక్కడ పాత్ర పోషిస్తాయని మీరు అనుకుంటున్నారు?",
            "or": "ରଜିଆ ତାଙ୍କୁ ସହରରେ ଅଧ୍ୟୟନ କରିବାକୁ ଅନୁମତି ଦେବା ପାଇଁ ତାଙ୍କ ପରିବାର ସହିତ ବୁଝାମଣା କରନ୍ତି । ବୁଝାମଣା ଭିନ୍ନ ଦୃଷ୍ଟିକୋଣଥିବା ଲୋକଙ୍କୁ ଏକ ନିଷ୍ପତ୍ତି ଚୂଡ଼ାନ୍ତ କରିବା ପାଇଁ ଏକ ରାଜିନାମାରେ ପହଞ୍ଚିବାରେ ସାହାଯ୍ୟ କରେ । ଆପଣଙ୍କ ଜୀବନରେ, ବୁଝାମଣା କେଉଁଠାରେ ଏକ ଭୂମିକା ଗ୍ରହଣ କରେ ବୋଲି ଆପଣ ଭାବନ୍ତି?",
            "as": "ৰাজিয়াই তাইক চহৰখনত পঢ়িবলৈ অনুমতি দিবলৈ তাইৰ পৰিয়ালৰ সৈতে বুজাবুজি কৰে। বুজাবুজিয়ে ভিন্ন দৃষ্টিভংগী থকা লোকসকলক সিদ্ধান্ত এটা চূড়ান্ত কৰিবলৈ এক চুক্তিত উপনীত হোৱাত সহায় কৰে। তোমাৰ জীৱনত, তুমি ক'ত বুজাবুজিয়ে ভূমিকা পালন কৰে বুলি ভাবা?",
            "gu": "રઝિયા તેને શહેરમાં અભ્યાસ કરવાની પરવાનગી માટે તેના પરિવાર સાથે વાટાઘાટો કરે છે. વાટાઘાટો અલગ અલગ વિચારણા ધરાવતા લોકોને નિર્ણયને અંતિમ સ્વરૂપ આપવા માટે કરાર સુધી પહોંચવામાં મદદ કરે છે. તમારા જીવનમાં, તમે શું વિચારો છો કે વાટાઘાટો ભૂમિકા ભજવે છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "To change your significant others (parents, friends etc) beliefs about your career decisions",
            "hi": "आपके करियर के निर्णय को लेकर अपने खास लोगों के (माता-पिता, दोस्त आदि) विचार बदलने के लिए",
            "ka": "ನಿಮ್ಮ ವೃತ್ತಿ ನಿರ್ಧಾರಗಳ ಬಗ್ಗೆ ನಿಮ್ಮ ಪ್ರಮುಖ ಇತರರ (ಪೋಷಕರು, ಸ್ನೇಹಿತರು ಇತ್ಯಾದಿ) ನಂಬಿಕೆಗಳನ್ನು ಬದಲಾಯಿಸಲು",
            "te": "మీ కెరీర్ నిర్ణయాల గురించి మీ ముఖ్యమైన ఇతరుల (తల్లిదండ్రులు, స్నేహితులు మొదలైనవారు) నమ్మకాలను మార్చడానికి",
            "or": "ଆପଣଙ୍କ କ୍ୟାରିୟର ନିଷ୍ପତ୍ତି ବିଷୟରେ ଆପଣଙ୍କର ଆତ୍ମୀୟମାନଙ୍କ (ପିତାମାତା, ବନ୍ଧୁ ଇତ୍ୟାଦି) ବିଶ୍ୱାସ ପରିବର୍ତ୍ତନ କରିବାକୁ",
            "as": "তুমি তোমাৰ কেৰিয়াৰৰ ওপৰত লোৱা সিদ্ধান্তৰ বিষয়ে তোমাৰ বাবে গুৰুত্বপূৰ্ণ আনসকল লোকৰ (পিতৃ-মাতৃ, বন্ধু আদি) মতামত সলনি কৰিবলৈ",
            "gu": "તમારી કારકિર્દીના નિર્ણયો વિશે તમારા નોંધપાત્ર અન્ય (માતાપિતા, મિત્રો વગેરે)ની માન્યતાઓને બદલવા માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "To gain a better salary when joining a new workplace",
            "hi": "नई जॉब में बेहतर सैलरी प्राप्त करने के लिए",
            "ka": "ಹೊಸ ಕೆಲಸದ ಸ್ಥಳಕ್ಕೆ ಸೇರಿದಾಗ ಉತ್ತಮ ಸಂಬಳವನ್ನು ಪಡೆಯಲು",
            "te": "కొత్త కార్యాలయంలో చేరినప్పుడు మెరుగైన జీతం పొందేందుకు",
            "or": "ଏକ ନୂତନ କର୍ମକ୍ଷେତ୍ରରେ ଯୋଗଦେବା ସମୟରେ ଏକ ଉତ୍ତମ ଦରମା ପାଇବାକୁ",
            "as": "নতুন কৰ্মস্থানত যোগদান কৰোঁতে ভাল দৰমহা পোৱা",
            "gu": "વધુ સારો પગાર મેળવવા માટે નવા કાર્યસ્થળે જોડાતી વખતે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "To get information about a career and the jobs within the career",
            "hi": "करियर के बारे में और करियर में उपलब्ध जॉब के बारे में जानकारी प्राप्त करने के लिए",
            "ka": "ವೃತ್ತಿ ಮತ್ತು ವೃತ್ತಿಯೊಳಗಿನ ಉದ್ಯೋಗಗಳ ಬಗ್ಗೆ ಮಾಹಿತಿ ಪಡೆಯಲು",
            "te": "కెరీర్ మరియు కెరీర్‌లోని ఉద్యోగాల గురించి సమాచారాన్ని పొందడానికి",
            "or": "ଏକ କ୍ୟାରିୟର ଏବଂ ସେହି କ୍ୟାରିୟର ମଧ୍ୟରେ ଚାକିରି ବିଷୟରେ ସୂଚନା ପାଇବା ପାଇଁ",
            "as": "কেৰিয়াৰ আৰু কেৰিয়াৰৰ ভিতৰত থকা চাকৰিৰ বিষয়ে তথ্য লাভ কৰা",
            "gu": "કારકિર્દી અને નોકરીમાં તેની અંદરની નોકરીઓ વિશે માહિતી મેળવવા માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "When making an important decision, negotiation is the process by which people who disagree on how things should be done come to an agreement that works for everyone involved.",
            "hi": "महत्वपूर्ण निर्णय लेते समय, निगोशिएट करना एक प्रक्रिया है जिसके जरिए जो लोग किसी काम को किस तरह से किया जाना चाहिए इस बात पर सहमत नहीं होते वे ऐसे किसी समझौते पर पहुँचते हैं जो इस प्रक्रिया में शामिल सबको मंजूर हो।",
            "ka": "ಪ್ರಮುಖ ನಿರ್ಧಾರವನ್ನು ಮಾಡುವಾಗ, ಸಮಾಲೋಚನೆಯು ಪ್ರಕ್ರಿಯೆಯಾಗಿದ್ದು, ವಿಷಯಗಳನ್ನು ಹೇಗೆ ಮಾಡಬೇಕು ಎಂಬುದರ ಕುರಿತು ಭಿನ್ನಾಭಿಪ್ರಾಯ ಹೊಂದಿರುವ ಜನರು ಒಳಗೊಂಡಿರುವ ಪ್ರತಿಯೊಬ್ಬರಿಗೂ ಕೆಲಸ ಮಾಡುವ ಒಪ್ಪಂದಕ್ಕೆ ಬರುತ್ತಾರೆ.",
            "te": "ఒక ముఖ్యమైన నిర్ణయం తీసుకునేటప్పుడు, నెగోషియేషన్ ( చర్చ) అంటే ఏదైనా ఎలా జరగాలి అనే దానిపై విభేదించే వ్యక్తులు పాల్గొన్న ప్రతి ఒక్కరికీ నచ్చే విధంగా ఒక ఒప్పందానికి వచ్చే ప్రక్రియ.",
            "or": "ଏକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ନିଷ୍ପତ୍ତି ନେବା ବେଳେ, ବୁଝାମଣା ହେଉଛି ସେହି ପ୍ରକ୍ରିୟା ଯାହା ଦ୍ୱାରା ସେ ବିଷୟରେ ଅସହମତ ଥିବା ଲୋକମାନେ ଜିନିଷଗୁଡିକ କିପରି କରାଯିବା ଉଚିତ୍ ତାହାକୁ ନେଇ ରାଜି ହୁଅନ୍ତି ଯାହା ଏଥିରେ ସମ୍ପୃକ୍ତ ସମସ୍ତଙ୍କ ପାଇଁ କାର୍ଯ୍ୟ କରେ ।",
            "as": "এটা গুৰুত্বপূৰ্ণ সিদ্ধান্ত লওতে, বুজাবুজি হৈছে এনে এক প্ৰক্ৰিয়া যাৰ দ্বাৰা কামবোৰ কেনেদৰে কৰিব লাগে সেই বিষয়ে অসন্মত হোৱা লোকসকলে এক চুক্তিত উপনীত হয় আৰু এই চুক্তিয়ে সিদ্ধান্ত লোৱা গোটেই কেইজনকে সহায় কৰে।",
            "gu": "જ્યારે મહત્વપૂર્ણ વાતચીતથી નિર્ણય લેતી વખતે, વાટાઘાટો એ એવી પ્રક્રિયા છે કે જેના દ્વારા કેવી રીતે તે કરવું જોઈએ તે અંગે અસંમત થતાં  લોકો એક કરાર પર આવે છે જે જેમાં બધા હાજર હોય તે માટે કાર્ય કરે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job! Negotiation allows space for the people involved in a decision to see various choices possible and move forward in a manner that is equal for everyone involved.",
            "hi": "शाबाश! निगोशिएशन के कारण निर्णय लेने की प्रक्रिया में शामिल सभी लोगों को भिन्न संभाव्य विकल्पों के बारे में जानकारी मिलती हैं और वे इस दिशा में आगे बढ़ते जो सबके लिए समान हो।",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ! ಸಮಾಲೋಚನೆಯು ನಿರ್ಧಾರದಲ್ಲಿ ತೊಡಗಿರುವ ಜನರಿಗೆ ವಿವಿಧ ಆಯ್ಕೆಗಳನ್ನು ಸಾಧ್ಯವೆಂದು ನೋಡಲು ಮತ್ತು ಒಳಗೊಂಡಿರುವ ಎಲ್ಲರಿಗೂ ಸಮಾನವಾದ ರೀತಿಯಲ್ಲಿ ಮುಂದುವರಿಯಲು ಜಾಗವನ್ನು ನೀಡುತ್ತದೆ.",
            "te": "గొప్ప పని! నెగోషియేషన్ ( చర్చ) వివిధ ఎంపికలను సాధ్యమయ్యేలా చూడడానికి మరియు పాల్గొన్న ప్రతి ఒక్కరికీ సమానమైన పద్ధతిలో ముందుకు సాగడానికి నిర్ణయంలో పాల్గొన్న వ్యక్తులకు అనుమతిస్తుంది.",
            "or": "ବହୁତ ଭଲ! ବୁଝାମଣା ନିଷ୍ପତ୍ତିରେ ଜଡିତ ଲୋକଙ୍କ ପାଇଁ ବିଭିନ୍ନ ପସନ୍ଦକୁ ସମ୍ଭବ ବୋଲି ଜାଣିବା ଏବଂ ଏହିପରି ଭାବରେ ଆଗକୁ ବଢିବାକୁ ଅନୁମତି ଦେଇଥାଏ ଯାହା ଏଥିରେ ସମ୍ପୃକ୍ତ ସମସ୍ତଙ୍କ ପାଇଁ ସମାନ ହୋଇଥାଏ ।",
            "as": "ভাল কাম! বুজাবুজিয়ে সিদ্ধান্তত জড়িত সকলোকে তেওঁলোকৰ হাতত থকা বিভিন্ন বিকল্পবোৰ চাবলৈ সুযোগ দিয়ে আৰু সিদ্ধান্তটোত জড়িত সকলোৱে একেলগে আগবাঢ়ি যাব পাৰে।",
            "gu": "સરસ કામ! વાટાઘાટો નિર્ણયમાં સામેલ લોકોને વિવિધ પસંદગીઓ શક્ય જોવા અને સામેલ દરેક વ્યક્તિ માટે સમાન હોય તેવી રીતે આગળ વધવાની જગ્યા આપે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! To get information we do research and networking. To reach an agreement when starting out with differing points of view, it’s important to negotiate.",
            "hi": "फिर से विचार करें! जानकारी प्राप्त करने के लिए हम रिसर्च और नेटवर्किंग करते हैं। जब हम भिन्न विचारों के साथ शुरुआत करते हैं और किसी निर्णय पर पहुंचना चाहते हैं तब निगोशिएट करना महत्वपूर्ण है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಮಾಹಿತಿಯನ್ನು ಪಡೆಯಲು ನಾವು ಸಂಶೋಧನೆ ಮತ್ತು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮಾಡುತ್ತೇವೆ. ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳೊಂದಿಗೆ ಪ್ರಾರಂಭಿಸುವಾಗ ಒಪ್ಪಂದವನ್ನು ತಲುಪಲು, ಮಾತುಕತೆ ನಡೆಸುವುದು ಮುಖ್ಯವಾಗಿದೆ.",
            "te": "మళ్లీ ఆలోచించు! సమాచారాన్ని పొందడానికి మేము పరిశోధన మరియు నెట్‌వర్కింగ్ చేస్తాము.భిన్నమైన అభిప్రాయాలు, ప్రారంభించేటప్పుడు ఒక ఒప్పందాన్ని చేరుకోవడానికి చర్చలు జరపడం ముఖ్యం.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ସୂଚନା ପାଇବା ପାଇଁ ଆମେ ଅନୁସନ୍ଧାନ ଏବଂ ନେଟୱାର୍କିଂ କରୁ । ଭିନ୍ନ ଭିନ୍ନ ଦୃଷ୍ଟିକୋଣରୁ ଆରମ୍ଭ କରିବା ସମୟରେ ଏକ ରାଜିନାମାରେ ପହଞ୍ଚିବା ପାଇଁ, ବୁଝାମଣା କରିବା ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ।",
            "as": "আকৌ চিন্তা কৰা! তথ্য প্ৰাপ্ত কৰিবলৈ আমি গৱেষণা আৰু নেটৱৰ্কিং কৰোঁ। ভিন্ন দৃষ্টিভংগীৰে আৰম্ভ কৰোঁতে এক চুক্তিত উপনীত হ'বলৈ, বুজাবুজি কৰাটো গুৰুত্বপূৰ্ণ।",
            "gu": "ફરીથી વિચારો ! સંશોધન અને નેટવર્કિંગની મદદથી માહિતી મેળવીએ છીએ. જુદા જુદા વિચારોથી શરૂઆત કરતી વખતે સમજૂતી સુધી પહોંચવા માટે, વાટાઘાટો કરવી એ મહત્વ હોય છે."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Research Lead",
            "hi": "रिसर्च लीड",
            "ka": "**THREAD_END**_ರೀಸರ್ಚ್ ಲೀಡ್",
            "te": "**THREAD_END**_జూనియర్ పర్యావరణ సలహాదారు",
            "or": "**THREAD_END**_ଅନୁସନ୍ଧାନ ଲିଡ୍",
            "as": "**থ্ৰেড_এণ্ড**_গৱেষণাত আগভাগ লোৱা",
            "gu": "**THREAD_END**_સંશોધનને દોરવું"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Razia that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने रजिया के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँची है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकती है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n \n ನೀವು ರಝಿಯಾಗಾಗಿ ಮಾಡಿದ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ.ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు రజియా కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర దృశ్యాలను అన్వేషించాలనుకుంటున్నారా?\"",
            "or": "ଅଭିନନ୍ଦନ! \n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି! \n ଆପଣ ରଜିଆ ପାଇଁ ନିଷ୍ପତ୍ତିଗୁଡିକ ପସନ୍ଦ କରିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତିକୁ ଆଗେଇ ନେଇଛି । ଆପଣ କରୁଥିବା ପସନ୍ଦଗୁଡିକ ଉପରେ ଆଧାର କରି ବିଭିନ୍ନ ପ୍ରକାରର ଶେଷ ଅଛି । ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହାଁନ୍ତି କି?",
            "as": "অভিনন্দন!\n তুমি এটা যাত্ৰা সমাপ্ত কৰিলা!\n \n তুমি ৰাজিয়াৰ বাবে বাছনি কৰিছা যাৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত আধাৰিত কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ জানিব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક સફર પૂરી કરી છે!\n\nતમે રઝિયા માટે જે પસંદગીઓ કરી છે જેના કારણે આ સંભવિત અંત આવ્યો છે. તમે જે પસંદગી કરો છો તેના આધારે વિવિધ અંત છે. શું તમે બીજા દ્રશ્યો શોધવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ର ଉପରେ ନଜର ପକାନ୍ତୁ",
            "as": "মানচিত্ৰখন চোৱা",
            "gu": "ચાલો નકશા તરફ નજર નાખીએ"
          }
        }
      ]
    },
    "scene29": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "E4_1_1_Continue as a field ecologist",
            "hi": "फिल्ड इकोलॉजिस्ट के तौर पर काम करना जारी रखना",
            "ka": "E4_1_1_ಕ್ಷೇತ್ರ ಪರಿಸರ ವಿಜ್ಞಾನಿಯಾಗಿ ಮುಂದುವರಿಯಿರಿ",
            "te": "E4_1_1_క్షేత్ర పర్యావరణ శాస్త్రవేత్త.గా పని చేయడం పని కొనసాగించడం",
            "or": "E4_1_1_ଏକ ଫିଲ୍ଡ ଇକୋଲୋଜିଷ୍ଟ ଭାବରେ ଜାରି ରଖନ୍ତୁ",
            "as": "E4_1_1_ক্ষেত্ৰ পৰিস্থিতিবিদ হিচাপে কাম কৰি থাকক",
            "gu": "E4_1_1_ફીલ્ડ ઇકોલોજિસ્ટ તરીકે ચાલુ રાખ"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia chooses not to take up the course on research writing. She decides to focus on her family and  posting articles online.",
            "hi": "रजिया रिसर्च रायटिंग का कोर्स न करने का निर्णय लेती है। वह बस अपने परिवार और लेख ऑनलाइन पोस्ट करने पर ध्यान केंद्रित करने का तय करती है।",
            "ka": "ರಝಿಯಾ ಸಂಶೋಧನಾ ಬರವಣಿಗೆಯ ಕೋರ್ಸ್ ಅನ್ನು ತೆಗೆದುಕೊಳ್ಳದಿರಲು ನಿರ್ಧರಿಸಿದ್ದಾಳೆ. ಅವಳು ತನ್ನ ಕುಟುಂಬದ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಲು ಮತ್ತು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಲೇಖನಗಳನ್ನು ಪೋಸ್ಟ್ ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
            "te": "రజియా పరిశోధన రచన కోర్సును తీసుకోకూడదని ఎంచుకుంది. \n ఆమె తన కుటుంబంపై దృష్టి పెట్టాలని మరియు కథనాలను ఆన్‌లైన్‌లో పోస్ట్ చేయాలని నిర్ణయించుకుంది",
            "or": "ରଜିଆ ଗବେଷଣା ଲେଖା ଉପରେ ପାଠ୍ୟକ୍ରମ ନ ନେବାକୁ ବାଛିଛନ୍ତି । ସେ ତାଙ୍କ ପରିବାର ଉପରେ ଧ୍ୟାନ ଦେବାକୁ ଏବଂ ଅନଲାଇନରେ ପ୍ରବନ୍ଧ ପୋଷ୍ଟ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି ।",
            "as": "ৰাজিয়াই গৱেষণা লিখনিৰ পাঠ্যক্ৰম নকৰো বুলি ভাবিছে। তাই তাইৰ পৰিয়ালক গুৰুত্ব দিয়াৰ সিদ্ধান্ত লয় আৰু অনলাইনত প্ৰবন্ধ পোষ্ট কৰে।",
            "gu": "રઝિયા સંશોધન લેખનનો કોર્સ ન લેવાનું પસંદ કરે છે. તેણી તેના કુટુંબ પર ધ્યાન રખવાનું નક્કી કરે છે અને લેખો ઑનલાઇન પોસ્ટ કરે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "E4_1_2",
            "hi": "E4_1_2",
            "ka": "E4_1_2",
            "te": "E4_1_2",
            "or": "E4_1_2",
            "as": "E4_1_2",
            "gu": "E4_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Director Ma'am is disappointed but she understands why Razia made that decision.",
            "hi": "डायरेक्टर मैम को यह बात अच्छी नहीं लगती लेकिन उन्हें पता हैं कि रजिया ने यह निर्णय क्यों लिया।",
            "ka": "ಡೈರೆಕ್ಟರ್ ಮೇಡಮ್ ನಿರಾಶೆಗೊಂಡರು ಆದರೆ ರಝಿಯಾ ಏಕೆ ಆ ನಿರ್ಧಾರ ತೆಗೆದುಕೊಂಡಿದ್ದಾಳೆಂದು ಆಕೆಗೆ ಅರ್ಥವಾಗಿದೆ.",
            "te": "డైరెక్టర్ మేడం నిరాశ చెందారు , కానీ రజియా ఎందుకు ఆ నిర్ణయం తీసుకుందో ఆమెకు అర్థమైంది.",
            "or": "ନିର୍ଦ୍ଦେଶକ ମାଡ଼ାମ୍ ନିରାଶ ହୋଇଥିଲେ କିନ୍ତୁ ସେ ବୁଝନ୍ତି ଯେ ରଜିଆ କାହିଁକି ସେହି ନିଷ୍ପତ୍ତି ନେଇଥିଲେ।",
            "as": "পৰিচালক মেডাম হতাশ হয় কিন্তু তেঁও বুজি পাইছে যে ৰাজিয়াই কিয় সেই সিদ্ধান্ত লৈছিল।",
            "gu": "નિયામક મેડમ નારાજ છે પરંતુ તે સમજે છે કે રઝિયાએ આ નિર્ણય કેમ લીધો."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "E4_1_3//",
            "hi": "E4_1_3//",
            "ka": "E4_1_3//",
            "te": "E4_1_3//",
            "or": "E4_1_3//",
            "as": "E4_1_3//",
            "gu": "E4_1_3//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "When she returns home that day, she tells Shazia about her decision.",
            "hi": "उस दिन घर लौटने पर शाजिया को अपने निर्णय के बारे में बताती है।",
            "ka": "ಅವಳು ಆ ದಿನ ಮನೆಗೆ ಹಿಂದಿರುಗಿದಾಗ, ಅವಳು ತನ್ನ ನಿರ್ಧಾರವನ್ನು ಶಾಝಿಯಾಗೆ ತಿಳಿಸುತ್ತಾಳೆ.",
            "te": "ఆ రోజు ఇంటికి తిరిగి రాగానే షాజియాకి తన నిర్ణయం గురించి చెప్పింది.",
            "or": "ଯେତେବେଳେ ସେ ସେଦିନ ଘରକୁ ଫେରିଲେ, ସେ ଶାଜିଆଙ୍କୁ ତାଙ୍କ ନିଷ୍ପତ୍ତି ବିଷୟରେ କୁହନ୍ତି ।",
            "as": "তাই যেতিয়া সেইদিনা ঘৰলৈ উভতি আহে, তাই শ্বাজিয়াক তাইৰ সিদ্ধান্তৰ বিষয়ে কয়।",
            "gu": "તે દિવસે જ્યારે તે ઘરે પછી આવે છે, ત્યારે તે શાઝિયાને તેના નિર્ણય વિશે જણાવે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Shazia",
            "hi": "शाजिया",
            "ka": "ಶಾಝಿಯಾ",
            "te": "షాజియా",
            "or": "ଶାଜିଆ",
            "as": "শ্বাজিয়া",
            "gu": "શાઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Didi it was such a good opportunity! Why would you not take it?",
            "hi": "दीदी, यह कितना अच्छा मौका था! आप ने हाँ क्यों नहीं कहा?",
            "ka": "ದೀದಿ ಇದು ಒಳ್ಳೆಯ ಅವಕಾಶ! ನೀವು ಅದನ್ನು ಏಕೆ ತೆಗೆದುಕೊಳ್ಳುವುದಿಲ್ಲ?",
            "te": "దీదీ ఇది చాలా మంచి అవకాశం! నువ్వు ఇది తీసుకోవచ్చు కదా?",
            "or": "ଦିଦି ଏହା ଏତେ ଭଲ ସୁଯୋଗ ଥିଲା! ଆପଣ ଏହାକୁ କାହିଁକି ନେବେ ନାହିଁ?",
            "as": "দিদি এইটো ইমান ভাল সুযোগ আছিল! আপুনি কিয় নকৰে?",
            "gu": "દીદી આટલી સારી તક હતી! તમે તેને કેમ ના લીધી ?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Shazia, Ammi is growing old, and I have to be around to make sure she is looked after. Anyway I'm happy with the role I play at the NGO. You concentrate on your studies and do what you love.",
            "hi": "शाजिया, अम्मी की उम्र होती जा रही है और उसकी देखभाल करने के लिए मुझे घर पर रहना पड़ेगा। वैसे भी, एनजीओ में मेरा जो काम हैं वह मुझे बहुत अच्छा लगता है। तुम अपनी पढ़ाई पर ध्यान दो और जो अच्छा लगता है वह करो।",
            "ka": "ಶಾಝಿಯಾ, ಅಮ್ಮಿ ವಯಸ್ಸಾಗುತ್ತಿದ್ದಾರೆ, ಮತ್ತು ಅವಳನ್ನು ನೋಡಿಕೊಳ್ಳುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ನಾನು ಸುತ್ತಲೂ ಇರಬೇಕು. ಅದೇನೇ ಇರಲಿ ಎನ್‌ಜಿಒದಲ್ಲಿ ನಾನು ನಿರ್ವಹಿಸುತ್ತಿರುವ ಪಾತ್ರ ನನಗೆ ಖುಷಿ ತಂದಿದೆ. ನೀನು ನಿನ್ನ ಅಧ್ಯಯನದ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸು ಮತ್ತು ನೀನು ಇಷ್ಟಪಡುವದನ್ನು ಮಾಡು.",
            "te": "షాజియా, అమ్మ వయసు మీద పడుతున్నది , నేను ఆమెను జాగ్రత్త గా చూసుకునేలా అందుబాటులో ఉండాలి . ఏది ఏమైనా ఎన్జీవోలో నేను పోషించిన పాత్ర పట్ల నేను సంతోషంగా ఉన్నాను. నువ్వు నీ \n  చదువుపై దృష్టి పెట్టు మరియు మీకు నచ్చినది చెయ్యి .",
            "or": "ଶାଜିଆ, ଅମ୍ମି ବୃଦ୍ଧ ହେଉଛନ୍ତି, ଏବଂ ତାଙ୍କୁ ଯତ୍ନ ନିଆଯାଉଛି ବୋଲି ନିଶ୍ଚିତ କରିବାକୁ ମୋତେ ଆଖପାଖରେ ରହିବାକୁ ପଡିବ । ଯାହା ହେଉ ମୁଁ NGOରେ ଅଭିନୟ କରୁଥିବା ଭୂମିକାରେ ଖୁସି । ତୁମେ ତୁମର ଅଧ୍ୟୟନଉପରେ ଧ୍ୟାନ ଦେଇଛ ଏବଂ ତୁମେ ଯାହା ଭଲ ପାଅ ତାହା କର ।",
            "as": "শ্বাজিয়া, আম্মীৰ বয়স বাঢ়ি আহিছে আৰু তেঁওৰ যত্ন লবলৈ মই তেঁওৰ ওচৰত থাকিব লাগিব। যি কি নহওক মই NGOত পালন কৰা ভূমিকাৰ বাবে সুখী। তুমি ভালদৰে পঢ়াশুনা কৰা আৰু যি ভাল পোৱা কৰা।",
            "gu": "શાઝિયા, અમ્મી ગરડી થઈ રહી છે, અને તેની સંભાળ રાખવામાં આવે તેની ખાતરી કરવા મારે આસપાસ એક ને રહેવું પડશે. કોઈપણ રીતે હું NGOમાં જે ભૂમિકા ભજવું છું તેનાથી હું ખુશ છું. તમે તમારા અભ્યાસ પર ધ્યાન કેન્દ્રિત કરો અને તમને જે ગમે તે કરો."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "E4_1_4////",
            "hi": "E4_1_4////",
            "ka": "E4_1_4////",
            "te": "E4_1_4////",
            "or": "E4_1_4////",
            "as": "E4_1_4////",
            "gu": "E4_1_4////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "But what about the job? Hasn’t this been your dream?",
            "hi": "लेकिन जॉब के बारे में क्या? यह तो आपका सपना हैं, है ना?",
            "ka": "ಆದರೆ ಕೆಲಸದ ಬಗ್ಗೆ ಏನು? ಇದು ನಿಮ್ಮ ಕನಸಾಗಿರಲಿಲ್ಲವೇ?",
            "te": "కానీ ఉద్యోగం గురించి ఏమిటి? ఇది నీ కల కాదా?",
            "or": "କିନ୍ତୁ ଚାକିରି ବିଷୟରେ କ'ଣ? ଏହା ଆପଣଙ୍କର ସ୍ୱପ୍ନ ନୁହେଁ କି?",
            "as": "কিন্তু চাকৰিটোৰ কি হ'ব? এইটো আপোনাৰ সপোন নাছিল জানো?",
            "gu": "પણ નોકરી વિષે શું? શું એ તારું સપનું નો હતું"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "These opportunities keep coming, your studies are more important now. I can always apply for the job later.",
            "hi": "ऐसे मौके तो मिलते रहेंगे, फिलहाल तुम्हारी पढ़ाई ज्यादा मायने रखती है। मैं बाद में भी जॉब के लिए अप्लाई कर सकती हूँ।",
            "ka": "ಈ ಅವಕಾಶಗಳು ಬರುತ್ತಲೇ ಇರುತ್ತವೆ, ನಿನ್ನ ಅಧ್ಯಯನಗಳು ಈಗ ಹೆಚ್ಚು ಮುಖ್ಯವಾಗಿವೆ. ನಾನು ಯಾವಾಗಲೂ ನಂತರ ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಬಹುದು.",
            "te": "ఈ అవకాశాలు వస్తూనే ఉంటాయి,ఇప్పుడు నీ చదువు ముఖ్యం. నేను తర్వాత ఎప్పుడైనా ఉద్యోగం కోసం దరఖాస్తు చేసుకోవచ్చు.",
            "or": "ଏହି ସୁଯୋଗଗୁଡ଼ିକ ଆସୁଛି, ଆପଣଙ୍କ ଅଧ୍ୟୟନ ବର୍ତ୍ତମାନ ଅଧିକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ । ମୁଁ ସର୍ବଦା ପରେ ଚାକିରି ପାଇଁ ଆବେଦନ କରିପାରିବି ।",
            "as": "এই সুযোগবোৰ আহি থাকে, এতিয়া তোমাৰ পঢ়াটো অধিক দৰকাৰী কাম। মই সদায় পিছত চাকৰিটোৰ বাবে এপ্লাই কৰিব পাৰিম।\n .",
            "gu": "આ તકો આવતી રહેશે, તારો અભ્યાસ હવે વધુ મહત્વપૂર્ણ છે. હું નોકરી માટે પછી પણ અરજી કરી શકું છું."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Didi, I really appreciate what you do for us.",
            "hi": "दीदी, आप हमारे लिए जो कर रही हैं उसके लिए मैं आभारी हूँ।",
            "ka": "ದೀದಿ, ನೀವು ನಮಗಾಗಿ ಮಾಡುತ್ತಿರುವುದನ್ನು ನಾನು ನಿಜವಾಗಿಯೂ ಪ್ರಶಂಸಿಸುತ್ತೇನೆ.",
            "te": "దీదీ, మీరు మా కోసం చేస్తున్న దానికి నేను నిజంగా అభినందిస్తున్నాను.",
            "or": "ଦିଦି, ତୁମେ ଆମ ପାଇଁ ଯାହା କର ମୁଁ ପ୍ରକୃତରେ ପ୍ରଶଂସା କରେ ।",
            "as": "দিদি, আপুনি আমাৰ বাবে যি কৰিছে মই সঁচাকৈয়ে প্ৰশংসা কৰোঁ।",
            "gu": "દીદી, હું ખરેખર પ્રશંસા કરું છું તમે અમારા માટે જે કરો છો તેની."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Yes yes of course. But you don’t bother about this, just focus on your studies.",
            "hi": "अरे ऐसा क्यों कह रही हो। लेकिन तुम इस बात की परवाह मत करो, बस अपनी पढ़ाई पर ध्यान दो।",
            "ka": "ಹೌದು ಹೌದು ಖಂಡಿತ. ಆದರೆ ನೀನು ಈ ಬಗ್ಗೆ ತಲೆ ಕೆಡಿಸಿಕೊಳ್ಳಬೇಡ, ನಿನ್ನ ಅಧ್ಯಯನದತ್ತ ಗಮನ ಹರಿಸು.",
            "te": "అవును అవును. కానీ నువ్వు దీని గురించి బాధపడకు, నీ చదువుపై దృష్టి పెట్టు",
            "or": "ହଁ ଅବଶ୍ୟ ହଁ । କିନ୍ତୁ ତୁମେ ଏ ବିଷୟରେ ଚିନ୍ତା କର ନାହିଁ, କେବଳ ତୁମର ଅଧ୍ୟୟନ ଉପରେ ଧ୍ୟାନ ଦିଅ ।",
            "as": "অৱশ্যেই হয় বাৰু। কিন্তু তুমি এই বিষয়ে চিন্তা নকৰিবা, কেৱল তোমাৰ পঢ়াশুনাত মন দিয়া।",
            "gu": "હા હા ચોક્કસ. પરંતુ તું આના વિશે ચિંતા ના કર, ફક્ત તારા અભ્યાસ પર ધ્યાન કેન્દ્રિત કર."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "E4_2_1",
            "hi": "E4_2_1",
            "ka": "E4_2_1",
            "te": "E4_2_1",
            "or": "E4_2_1",
            "as": "E4_2_1",
            "gu": "E4_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia continues to work at the NGO. She is able to spend more time at home to look after her mother.",
            "hi": "रजिया एनजीओ में काम करना जारी रखती है। वह घर पर ज्यादा समय दे सकती है और माँ का ध्यान रख सकती है।",
            "ka": "ರಝಿಯಾ ಎನ್‌ಜಿಒದಲ್ಲಿ ಕೆಲಸ ಮುಂದುವರೆಸಿದ್ದಾಳೆ. ಅವಳು ತನ್ನ ತಾಯಿಯನ್ನು ನೋಡಿಕೊಳ್ಳಲು ಮನೆಯಲ್ಲಿ ಹೆಚ್ಚು ಸಮಯ ಕಳೆಯಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ.",
            "te": "రజియా NGO లో పని చేస్తూనే ఉంది. ఆమె తన తల్లిని చూసుకోవడానికి ఇంట్లో ఎక్కువ సమయం ఉండగలుగుతుంది.",
            "or": "ରଜିଆ NGOରେ କାର୍ଯ୍ୟ ଜାରି ରଖିଛନ୍ତି । ସେ ତାଙ୍କ ମା'ଙ୍କ ଯତ୍ନ ନେବା ପାଇଁ ଘରେ ଅଧିକ ସମୟ ବିତାଇବାକୁ ସକ୍ଷମ ଅଟନ୍ତି ।",
            "as": "ৰাজিয়াই NGOত কাম কৰি আছে। তাই তাইৰ মাকৰ চোৱা-চিতা কৰিবলৈ ঘৰত অধিক সময় থাকিব পাৰিছে।",
            "gu": "રઝિયા NGOમાં કામ કરવાનું ચાલુ રાખે છે. તે તેની માતાની સંભાળ રાખવા માટે ઘરે વધુ સમય પસાર કરવા સક્ષમ છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "E4_2_2",
            "hi": "E4_2_2",
            "ka": "E4_2_2",
            "te": "E4_2_2",
            "or": "E4_2_2",
            "as": "E4_2_2",
            "gu": "E4_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia’s work continues getting published in many prestigious magazines and on several websites.",
            "hi": "रजिया के लेख कई प्रसिद्ध मैगज़ीन में और कई वैबसाइट्स पर प्रकाशित होते रहते हैं।",
            "ka": "ರಝಿಯಾಳ ಕೆಲಸವು ಅನೇಕ ಪ್ರತಿಷ್ಠಿತ ನಿಯತಕಾಲಿಕೆಗಳಲ್ಲಿ ಮತ್ತು ಹಲವಾರು ವೆಬ್‌ಸೈಟ್‌ಗಳಲ್ಲಿ ಪ್ರಕಟವಾಗುತ್ತಲೇ ಇದೆ.",
            "te": "రజియా రచనలు అనేక ప్రతిష్టాత్మక పత్రికలలో మరియు అనేక వెబ్‌సైట్లలోప్రచురింపబడుతూనే ఉన్నాయి",
            "or": "ରଜିଆର କାର୍ଯ୍ୟ ଅନେକ ସମ୍ମାନଜନକ ପତ୍ରିକା ଏବଂ ଅନେକ ୱେବସାଇଟରେ ପ୍ରକାଶିତ ହେବାରେ ଲାଗିଛି ।",
            "as": "ৰাজিয়াৰ কাম বহুতো ডাঙৰ আলোচনী আৰু কেইবাটাও ৱেবছাইটত প্ৰকাশ হৈ আছে।",
            "gu": "રઝિયાનું કાર્ય ઘણી વેબસાઇટ્સ અને પ્રતિષ્ઠિત સામયિકોમાં પર પ્રકાશિત થતું રહે છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "E4_2_3",
            "hi": "E4_2_3",
            "ka": "E4_2_3",
            "te": "E4_2_3",
            "or": "E4_2_3",
            "as": "E4_2_3",
            "gu": "E4_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She looks forward to supporting Shazia to finish her education until she finds a good job. \nRazia has made several plans for herself after Shazia graduates.",
            "hi": "वह चाहती है कि शाजिया की पढ़ाई पूरी होने के बाद जब तक उसे जॉब नहीं मिलता तब तक वह उसकी मदद करना चाहती है। \n शाजिया के ग्रेजुएट होने के बाद रजिया ने खुद के लिए भी कई प्लान बनाए हैं।",
            "ka": "\"ಒಳ್ಳೆಯ ಕೆಲಸವನ್ನು ಕಂಡುಕೊಳ್ಳುವವರೆಗೆ ತನ್ನ ಶಿಕ್ಷಣವನ್ನು ಮುಗಿಸಲು ಶಾಝಿಯಾಗೆ ಬೆಂಬಲ ನೀಡಲು ಅವಳು ಎದುರು ನೋಡುತ್ತಾಳೆ. ಶಾಝಿಯಾ ಪದವೀಧರರಾದ ನಂತರ ರಝಿಯಾ ತನಗಾಗಿ ಹಲವಾರು ಯೋಜನೆಗಳನ್ನು ಮಾಡಿಕೊಂಡಿದ್ದಾಳೆ.",
            "te": "\"షాజియాకు మంచి ఉద్యోగం దొరికే వరకు తన చదువును పూర్తి చేయడానికి మద్దతు ఇవ్వాలని ఆమె ఎదురు చూస్తోంది. \n షాజియా గ్రాడ్యుయేట్ అయిన తర్వాత రజియా తన కోసం అనేక ప్రణాళికలు వేసుకుంది.\"",
            "or": "ସେ ଏକ ଭଲ ଚାକିରି ନପାଇବା ପର୍ଯ୍ୟନ୍ତ ଶାଜିଆଙ୍କୁ ତାଙ୍କ ଶିକ୍ଷା ସମାପ୍ତ କରିବାକୁ ସମର୍ଥନ କରିବାକୁ ଅପେକ୍ଷା କରିଛନ୍ତି । ଶାଜିଆ ସ୍ନାତକ ପରେ ରଜିଆ ନିଜ ପାଇଁ ଅନେକ ଯୋଜନା କରିଛନ୍ତି ।",
            "as": "তাই শ্বাজিয়াক এটা ভাল চাকৰি নোপোৱালৈকে পঢ়া শেষ হোৱাত সহায় কৰি দিম বুলি ভাবিছে।\n শ্বাজিয়া স্নাতক হোৱাৰ পিছত ৰাজিয়াই নিজৰ বাবে কেইবাটাও পৰিকল্পনা কৰিছে।",
            "gu": "તે શાઝિયાને મદદ કરે જ્યાં સુધી એને સારી નોકરી ન મળે ત્યાં સુધી અને તેનું શિક્ષણ પૂરું કરવા માટે તે આતુર છે.\nશાઝિયા તેનું શિક્ષણ પૂરું કર્યા પછી રઝિયાએ પોતાના માટે ખૂબ યોજનાઓ બનાવી છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ1",
            "hi": "MCQ1",
            "ka": "MCQ1",
            "te": "MCQ1",
            "or": "ଏମସିକ୍ୟୁ1",
            "as": "MCQ1",
            "gu": "MCQ1"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia does internet research to find and gain a scholarship. Internet research helps us gain basic information about careers and industries. It also helps us know about new opportunities. Select the statement which is TRUE:",
            "hi": "रजिया इंटरनेट पर रिसर्च करके स्कोलरशिप के बारे में जानकारी प्राप्त करती है। इंटरनेट रिसर्च हमें करियर और उद्योगों के बारे में मुलभुत जानकारी प्राप्त करने में मदद करता है। यह हमें नए अवसरों के बारे में जानकारी प्राप्त करने में भी मदद करता है। सही विधान को चुनें:",
            "ka": "ವಿದ್ಯಾರ್ಥಿವೇತನವನ್ನು ಹುಡುಕಲು ಮತ್ತು ಪಡೆಯಲು ರಝಿಯಾ ಇಂಟರ್ನೆಟ್ ಸಂಶೋಧನೆ ಮಾಡುತ್ತಾಳೆ. ಇಂಟರ್ನೆಟ್ ಸಂಶೋಧನೆಯು ವೃತ್ತಿ ಮತ್ತು ಉದ್ಯಮಗಳ ಬಗ್ಗೆ ಮೂಲಭೂತ ಮಾಹಿತಿಯನ್ನು ಪಡೆಯಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಹೊಸ ಅವಕಾಶಗಳ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಲು ಇದು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನಿಜವಾಗಿರುವ ಹೇಳಿಕೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ:",
            "te": "రజియా స్కాలర్‌షిప్‌ (ఉపకార వేతనం) గురించి కనుగొని దానిని పొందేందుకు ఇంటర్నెట్ పరిశోధన చేస్తుంది.కెరీర్‌లు మరియు పరిశ్రమల గురించి ప్రాథమిక సమాచారాన్ని పొందడంలో ఇంటర్నెట్ పరిశోధన మాకు సహాయపడుతుంది. ఇది కొత్త అవకాశాల గురించి తెలుసుకోవడానికి కూడా మాకు సహాయపడుతుంది:ఏది నిజమైన స్టేట్ మెంటో దానిని ఎంచుకోండి",
            "or": "ରଜିଆ ଏକ ଛାତ୍ରବୃତ୍ତି ଖୋଜିବା ଏବଂ ହାସଲ କରିବା ପାଇଁ ଇଣ୍ଟରନେଟ୍ ରେ ଅନୁସନ୍ଧାନ କରନ୍ତି । ଇଣ୍ଟରନେଟ୍ ରେ ଅନୁସନ୍ଧାନ ଆମକୁ କ୍ୟାରିୟର ଏବଂ ଶିଳ୍ପ ବିଷୟରେ ମୌଳିକ ସୂଚନା ହାସଲ କରିବାରେ ସାହାଯ୍ୟ କରେ । ଏହା ଆମକୁ ନୂତନ ସୁଯୋଗ ବିଷୟରେ ଜାଣିବାରେ ମଧ୍ୟ ସାହାଯ୍ୟ କରେ । ଠିକ ଥିବା ଉକ୍ତିଟି ଚୟନ କରନ୍ତୁ:",
            "as": "ৰাজিয়াই স্কলাৰশ্বিপ বিচাৰিবলৈ আৰু লাভ কৰিবলৈ ইণ্টাৰনেট ব্যৱহাৰ কৰে। ইণ্টাৰনেটৰ ব্যৱহাৰে আমাক কেৰিয়াৰ আৰু উদ্যোগৰ বিষয়ে মৌলিক তথ্য লাভ কৰাত সহায় কৰে। ই আমাক নতুন সুযোগৰ বিষয়ে জনাত সহায় কৰে। সচাঁ উক্তিটো বাছনি কৰা",
            "gu": "રઝિયા ઇન્ટરનેટમાં સંશોધન કરે કે તેને શિષ્યવૃત્તિ મળે. ઈન્ટરનેટમાં સોધવાથી આપણને કારકિર્દી અને ઉદ્યોગો વિશે મૂળભૂત માહિતી મેળવવામાં મદદ કરે છે. તે અમને નવી તકો વિશે જાણવામાં પણ મદદ કરે છે. પસંદ કરો નિવેદન જે સાચું છે:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "The internet is the only way to do research and find information.",
            "hi": "रिसर्च करने और जानकारी प्राप्त करने के लिए केवल इंटरनेट ही एकमात्र मार्ग है",
            "ka": "ಸಂಶೋಧನೆ ಮಾಡಲು ಮತ್ತು ಮಾಹಿತಿಯನ್ನು ಹುಡುಕಲು ಇಂಟರ್ನೆಟ್ ಏಕೈಕ ಮಾರ್ಗವಾಗಿದೆ.",
            "te": "పరిశోధనలు చేయడానికి మరియు సమాచారాన్ని కనుగొనడానికి ఇంటర్నెట్ మాత్రమే ఏకైక మార్గం.",
            "or": "ଗବେଷଣା କରିବା ଏବଂ ସୂଚନା ଖୋଜିବା ପାଇଁ ଇଣ୍ଟରନେଟ୍ ହେଉଛି ଏକମାତ୍ର ଉପାୟ ।",
            "as": "গৱেষণা আৰু তথ্য বিচাৰি উলিওৱাৰ একমাত্ৰ উপায় হৈছে ইণ্টাৰনেট।",
            "gu": "ઈન્ટરનેટ એ એકમાત્ર રસ્તો છે સંશોધન કરવા અને માહિતી શોધવાનો  માટે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "To do research we can talk to people and read books and newspapers",
            "hi": "रिसर्च करने के लिए हम लोगों से बात कर सकते हैं और किताबें और अख़बार पढ़ सकते हैं",
            "ka": "ಸಂಶೋಧನೆ ಮಾಡಲು ನಾವು ಜನರೊಂದಿಗೆ ಮಾತನಾಡಬಹುದು ಮತ್ತು ಪುಸ್ತಕಗಳು ಮತ್ತು ಪತ್ರಿಕೆಗಳನ್ನು ಓದಬಹುದು",
            "te": "పరిశోధన చేయడానికి మనం ప్రజలతో మాట్లాడవచ్చు మరియు పుస్తకాలు మరియు వార్తాపత్రికలు చదవవచ్చు",
            "or": "ଗବେଷଣା କରିବା ପାଇଁ ଆମେ ଲୋକଙ୍କ ସହ କଥା ହୋଇପାରିବା ଏବଂ ପୁସ୍ତକ ଏବଂ ଖବରକାଗଜ ପଢିପାରିବା ।",
            "as": "গৱেষণা কৰিবলৈ আমি মানুহৰ সৈতে কথা পাতিব পাৰোঁ আৰু কিতাপ আৰু বাতৰি কাকত পঢ়িব পাৰোঁ",
            "gu": "શોધખોળ કરવા માટે આપણે લોકો સાથે વાત કરી શકીએ છીએ અને પુસ્તકો અને સમાચાર પત્રો પણ વાંચી શકીએ છીએ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Research is required only when one needs to find a scholarship to study",
            "hi": "रिसर्च केवल तभी किया जाता है जब किसी को पढ़ाई के लिए स्कोलरशिप के बारे में जानकारी चाहिए होती है।",
            "ka": "ಅಧ್ಯಯನ ಮಾಡಲು ವಿದ್ಯಾರ್ಥಿವೇತನವನ್ನು ಹುಡುಕಬೇಕಾದಾಗ ಮಾತ್ರ ಸಂಶೋಧನೆಯ ಅಗತ್ಯವಿದೆ",
            "te": "చదువుకోవడానికి స్కాలర్‌షిప్‌ (ఉపకార వేతనం )ను కనుగొనవలసి వచ్చినప్పుడు మాత్రమే పరిశోధన అవసరం",
            "or": "ଗବେଷଣା କେବଳ ସେତେବେଳେ ଆବଶ୍ୟକ ହୁଏ ଯେତେବେଳେ ଜଣେ ଅଧ୍ୟୟନ ପାଇଁ ଛାତ୍ରବୃତ୍ତି ଖୋଜନ୍ତି ।",
            "as": "পঢ়া-শুনা কৰিবলৈ স্কলাৰশ্বিপ বিচৰাৰ বাবেহে গৱেষণাৰ প্ৰয়োজন",
            "gu": "અભ્યાસ માટે શિષ્યવૃત્તિ શોધવાની જરૂરી હોય જ્યારે કોઈને ત્યારે સંશોધન કરવું જરૂરી છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Think again! To do research we can talk to people and read books and newspapers.",
            "hi": "फिर से विचार करें! रिसर्च करने के लिए हम लोगो के साथ बात कर सकते हैं और और किताबें और अख़बार पढ़ सकते हैं।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಸಂಶೋಧನೆ ಮಾಡಲು ನಾವು ಜನರೊಂದಿಗೆ ಮಾತನಾಡಬಹುದು ಮತ್ತು ಪುಸ್ತಕಗಳು ಮತ್ತು ಪತ್ರಿಕೆಗಳನ್ನು ಓದಬಹುದು.",
            "te": "మళ్లీ ఆలోచించండి! పరిశోధన చేయడానికి మనం ప్రజలతో మాట్లాడవచ్చు మరియు పుస్తకాలు మరియు వార్తాపత్రికలు చదవవచ్చు.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ଗବେଷଣା କରିବା ପାଇଁ ଆମେ ଲୋକଙ୍କ ସହ କଥା ହୋଇପାରିବା ଏବଂ ପୁସ୍ତକ ଏବଂ ଖବରକାଗଜ ପଢିପାରିବା ।",
            "as": "আকৌ চিন্তা কৰা! গৱেষণা কৰিবলৈ আমি মানুহৰ সৈতে কথা পাতিব পাৰোঁ আৰু কিতাপ আৰু বাতৰি কাকত পঢ়িব পাৰোঁ।",
            "gu": "વિચારો ફરીથી! સંશોધન કરવા માટે આપણે લોકો સાથે વાત કરી શકીએ છીએ અને પુસ્તકો અને સમાચાર પત્રો પણ વાંચી શકીએ છીએ."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "शाबाश!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "గొప్ప పని!",
            "or": "ବହୁତ ଭଲ!",
            "as": "ভাল কাম!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! Research can help us be aware of opportunities throughout our careers.",
            "hi": "फिर से विचार करें! रिसर्च हमें अपने करियर के अवसरों के बारे में जागरूक होने के लिए मदद करता है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ನಮ್ಮ ವೃತ್ತಿಜೀವನದುದ್ದಕ್ಕೂ ಅವಕಾಶಗಳ ಬಗ್ಗೆ ಅರಿವು ಮೂಡಿಸಲು ಸಂಶೋಧನೆ ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
            "te": "మళ్లీ ఆలోచించండి! మన కెరీర్‌లో అవకాశాల గురించి తెలుసుకోవడంలో పరిశోధన సహాయపడుతుంది.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ଗବେଷଣା ଆମକୁ ଆମର କ୍ୟାରିୟରରେ ସୁଯୋଗ ବିଷୟରେ ସଚେତନ ହେବାରେ ସାହାଯ୍ୟ କରିପାରିବ ।",
            "as": "আকৌ চিন্তা কৰা! গৱেষণাই আমাক আমাৰ সমগ্ৰ কেৰিয়াৰত সুযোগৰ বিষয়ে সজাগ হোৱাত সহায় কৰিব পাৰে।",
            "gu": "વિચારો ફરીથી! સંશોધન અમારી સમગ્ર કારકિર્દી દરમિયાન તકોથી જ્ઞાન રહેવામાં મદદ કરી શકે છે."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ4",
            "hi": "MCQ4",
            "ka": "MCQ4",
            "te": "MCQ4",
            "or": "MCQ4",
            "as": "MCQ4",
            "gu": "MCQ4"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia negotiates with her family to allow her to study in the city. Negotiation helps people with differing points of view to reach an agreement to finalise a decision. In your life, where do you think negotiation plays a role?",
            "hi": "रजिया शहर में पढ़ने के लिए जाने के लिए अपने माता-पिता के साथ निगोशिएट करती है। निगोशिएट करने से अलग विचारों के लोगों को किसी एक निर्णय पर सहमत होने में मदद होती है। आपकी जिंदगी में, निगोशिएट करना कहां काम आता है ऐसा आपको लगता हैं?",
            "ka": "ರಝಿಯಾ ತನ್ನ ಕುಟುಂಬದೊಂದಿಗೆ ನಗರದಲ್ಲಿ ಓದಲು ಅವಕಾಶ ನೀಡಲು ಮಾತುಕತೆ ನಡೆಸುತಾಳೆ. ನಿರ್ಧಾರವನ್ನು ಅಂತಿಮಗೊಳಿಸಲು ಒಪ್ಪಂದವನ್ನು ತಲುಪಲು ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳನ್ನು ಹೊಂದಿರುವ ಜನರಿಗೆ ಮಾತುಕತೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನಿಮ್ಮ ಜೀವನದಲ್ಲಿ, ಮಾತುಕತೆಯು ಎಲ್ಲಿ ಒಂದು ಪಾತ್ರವನ್ನು ವಹಿಸುತ್ತದೆ ಎಂದು ನೀವು ಭಾವಿಸುತ್ತೀರಿ?",
            "te": "రజియా నగరంలో చదువుకోవడానికి అనుమతించమని తన కుటుంబంతో చర్చలు జరిపింది .భిన్నమైన అభిప్రాయాలు ఉన్న వ్యక్తులు ఒక నిర్ణయాన్ని ఖరారు చేయడానికి ఒక ఒప్పందాన్ని చేరుకోవడానికి చర్చలు సహాయపడతాయి. మీ జీవితంలో, నెగోషియేషన్ ( చర్చ) ఎక్కడ పాత్ర పోషిస్తాయని మీరు అనుకుంటున్నారు?",
            "or": "ରଜିଆ ତାଙ୍କୁ ସହରରେ ଅଧ୍ୟୟନ କରିବାକୁ ଅନୁମତି ଦେବା ପାଇଁ ତାଙ୍କ ପରିବାର ସହିତ ବୁଝାମଣା କରନ୍ତି । ବୁଝାମଣା ଭିନ୍ନ ଦୃଷ୍ଟିକୋଣଥିବା ଲୋକଙ୍କୁ ଏକ ନିଷ୍ପତ୍ତି ଚୂଡ଼ାନ୍ତ କରିବା ପାଇଁ ଏକ ରାଜିନାମାରେ ପହଞ୍ଚିବାରେ ସାହାଯ୍ୟ କରେ । ଆପଣଙ୍କ ଜୀବନରେ, ବୁଝାମଣା କେଉଁଠାରେ ଏକ ଭୂମିକା ଗ୍ରହଣ କରେ ବୋଲି ଆପଣ ଭାବନ୍ତି?",
            "as": "ৰাজিয়াই তাইক চহৰখনত পঢ়িবলৈ অনুমতি দিবলৈ তাইৰ পৰিয়ালৰ সৈতে বুজাবুজি কৰে। বুজাবুজিয়ে ভিন্ন দৃষ্টিভংগী থকা লোকসকলক সিদ্ধান্ত এটা চূড়ান্ত কৰিবলৈ এক চুক্তিত উপনীত হোৱাত সহায় কৰে। তোমাৰ জীৱনত, তুমি ক'ত বুজাবুজিয়ে ভূমিকা পালন কৰে বুলি ভাবা?",
            "gu": "રઝિયા તેને શહેરમાં અભ્યાસ કરવાની પરવાનગી માટે તેના પરિવાર સાથે વાટાઘાટો કરે છે. વાટાઘાટો અલગ અલગ વિચારણા ધરાવતા લોકોને નિર્ણયને અંતિમ સ્વરૂપ આપવા માટે કરાર સુધી પહોંચવામાં મદદ કરે છે. તમારા જીવનમાં, તમે શું વિચારો છો કે વાટાઘાટો ભૂમિકા ભજવે છે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "To change your significant others (parents, friends etc) beliefs about your career decisions",
            "hi": "आपके करियर के निर्णय को लेकर अपने खास लोगों के (माता-पिता, दोस्त आदि) विचार बदलने के लिए",
            "ka": "ನಿಮ್ಮ ವೃತ್ತಿ ನಿರ್ಧಾರಗಳ ಬಗ್ಗೆ ನಿಮ್ಮ ಪ್ರಮುಖ ಇತರರ (ಪೋಷಕರು, ಸ್ನೇಹಿತರು ಇತ್ಯಾದಿ) ನಂಬಿಕೆಗಳನ್ನು ಬದಲಾಯಿಸಲು",
            "te": "మీ కెరీర్ నిర్ణయాల గురించి మీ ముఖ్యమైన ఇతరుల (తల్లిదండ్రులు, స్నేహితులు మొదలైనవారు) నమ్మకాలను మార్చడానికి",
            "or": "ଆପଣଙ୍କ କ୍ୟାରିୟର ନିଷ୍ପତ୍ତି ବିଷୟରେ ଆପଣଙ୍କର ଆତ୍ମୀୟମାନଙ୍କ (ପିତାମାତା, ବନ୍ଧୁ ଇତ୍ୟାଦି) ବିଶ୍ୱାସ ପରିବର୍ତ୍ତନ କରିବାକୁ",
            "as": "তুমি তোমাৰ কেৰিয়াৰৰ ওপৰত লোৱা সিদ্ধান্তৰ বিষয়ে তোমাৰ বাবে গুৰুত্বপূৰ্ণ আনসকল লোকৰ (পিতৃ-মাতৃ, বন্ধু আদি) মতামত সলনি কৰিবলৈ",
            "gu": "તમારી કારકિર્દીના નિર્ણયો વિશે તમારા નોંધપાત્ર અન્ય (માતાપિતા, મિત્રો વગેરે)ની માન્યતાઓને બદલવા માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "To gain a better salary when joining a new workplace",
            "hi": "नई जॉब में बेहतर सैलरी प्राप्त करने के लिए",
            "ka": "ಹೊಸ ಕೆಲಸದ ಸ್ಥಳಕ್ಕೆ ಸೇರಿದಾಗ ಉತ್ತಮ ಸಂಬಳವನ್ನು ಪಡೆಯಲು",
            "te": "కొత్త కార్యాలయంలో చేరినప్పుడు మెరుగైన జీతం పొందేందుకు",
            "or": "ଏକ ନୂତନ କର୍ମକ୍ଷେତ୍ରରେ ଯୋଗଦେବା ସମୟରେ ଏକ ଉତ୍ତମ ଦରମା ପାଇବାକୁ",
            "as": "নতুন কৰ্মস্থানত যোগদান কৰোঁতে ভাল দৰমহা পাবলৈ",
            "gu": "વધુ સારો પગાર મેળવવા માટે નવા કાર્યસ્થળે જોડાતી વખતે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "To get information about a career and the jobs within the career",
            "hi": "करियर के बारे में और करियर में उपलब्ध जॉब के बारे में जानकारी प्राप्त करने के लिए",
            "ka": "ವೃತ್ತಿ ಮತ್ತು ವೃತ್ತಿಯೊಳಗಿನ ಉದ್ಯೋಗಗಳ ಬಗ್ಗೆ ಮಾಹಿತಿ ಪಡೆಯಲು",
            "te": "కెరీర్ మరియు కెరీర్‌లోని ఉద్యోగాల గురించి సమాచారాన్ని పొందడానికి",
            "or": "ଏକ କ୍ୟାରିୟର ଏବଂ ସେହି କ୍ୟାରିୟର ମଧ୍ୟରେ ଚାକିରି ବିଷୟରେ ସୂଚନା ପାଇବା ପାଇଁ",
            "as": "কেৰিয়াৰ আৰু কেৰিয়াৰৰ ভিতৰত থকা চাকৰিৰ বিষয়ে তথ্য লাভ কৰিবলৈ",
            "gu": "કારકિર્દી અને નોકરીમાં તેની અંદરની નોકરીઓ વિશે માહિતી મેળવવા માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "When making an important decision, negotiation is the process by which people who disagree on how things should be done come to an agreement that works for everyone involved.",
            "hi": "महत्वपूर्ण निर्णय लेते समय, निगोशिएट करना एक प्रक्रिया है जिसके जरिए जो लोग किसी काम को किस तरह से किया जाना चाहिए इस बात पर सहमत नहीं होते वे ऐसे किसी समझौते पर पहुँचते हैं जो इस प्रक्रिया में शामिल सबको मंजूर हो।",
            "ka": "ಪ್ರಮುಖ ನಿರ್ಧಾರವನ್ನು ಮಾಡುವಾಗ, ಸಮಾಲೋಚನೆಯು ಪ್ರಕ್ರಿಯೆಯಾಗಿದ್ದು, ವಿಷಯಗಳನ್ನು ಹೇಗೆ ಮಾಡಬೇಕು ಎಂಬುದರ ಕುರಿತು ಭಿನ್ನಾಭಿಪ್ರಾಯ ಹೊಂದಿರುವ ಜನರು ಒಳಗೊಂಡಿರುವ ಪ್ರತಿಯೊಬ್ಬರಿಗೂ ಕೆಲಸ ಮಾಡುವ ಒಪ್ಪಂದಕ್ಕೆ ಬರುತ್ತಾರೆ.",
            "te": "ఒక ముఖ్యమైన నిర్ణయం తీసుకునేటప్పుడు, నెగోషియేషన్ ( చర్చ) అంటే ఏదైనా ఎలా జరగాలి అనే దానిపై విభేదించే వ్యక్తులు పాల్గొన్న ప్రతి ఒక్కరికీ నచ్చే విధంగా ఒక ఒప్పందానికి వచ్చే ప్రక్రియ.",
            "or": "ଏକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ନିଷ୍ପତ୍ତି ନେବା ବେଳେ, ବୁଝାମଣା ହେଉଛି ସେହି ପ୍ରକ୍ରିୟା ଯାହା ଦ୍ୱାରା ସେ ବିଷୟରେ ଅସହମତ ଥିବା ଲୋକମାନେ ଜିନିଷଗୁଡିକ କିପରି କରାଯିବା ଉଚିତ୍ ତାହାକୁ ନେଇ ରାଜି ହୁଅନ୍ତି ଯାହା ଏଥିରେ ସମ୍ପୃକ୍ତ ସମସ୍ତଙ୍କ ପାଇଁ କାର୍ଯ୍ୟ କରେ ।",
            "as": "এটা গুৰুত্বপূৰ্ণ সিদ্ধান্ত লওতে, বুজাবুজি হৈছে এনে এক প্ৰক্ৰিয়া যাৰ দ্বাৰা কামবোৰ কেনেদৰে কৰিব লাগে সেই বিষয়ে অসন্মত হোৱা লোকসকলে এক চুক্তিত উপনীত হয় আৰু এই চুক্তিয়ে সিদ্ধান্ত লোৱা গোটেই কেইজনকে সহায় কৰে।",
            "gu": "જ્યારે મહત્વપૂર્ણ વાતચીતથી નિર્ણય લેતી વખતે, વાટાઘાટો એ એવી પ્રક્રિયા છે કે જેના દ્વારા કેવી રીતે તે કરવું જોઈએ તે અંગે અસંમત થતાં  લોકો એક કરાર પર આવે છે જે જેમાં બધા હાજર હોય તે માટે કાર્ય કરે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job! Negotiation allows space for the people involved in a decision to see various choices possible and move forward in a manner that is equal for everyone involved.",
            "hi": "शाबाश! निगोशिएशन के कारण निर्णय लेने की प्रक्रिया में शामिल सभी लोगों को भिन्न संभाव्य विकल्पों के बारे में जानकारी मिलती हैं और वे इस दिशा में आगे बढ़ते जो सबके लिए समान हो।",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ! ಸಮಾಲೋಚನೆಯು ನಿರ್ಧಾರದಲ್ಲಿ ತೊಡಗಿರುವ ಜನರಿಗೆ ವಿವಿಧ ಆಯ್ಕೆಗಳನ್ನು ಸಾಧ್ಯವೆಂದು ನೋಡಲು ಮತ್ತು ಒಳಗೊಂಡಿರುವ ಎಲ್ಲರಿಗೂ ಸಮಾನವಾದ ರೀತಿಯಲ್ಲಿ ಮುಂದುವರಿಯಲು ಜಾಗವನ್ನು ನೀಡುತ್ತದೆ.",
            "te": "గొప్ప పని! నెగోషియేషన్ ( చర్చ) వివిధ ఎంపికలను సాధ్యమయ్యేలా చూడడానికి మరియు పాల్గొన్న ప్రతి ఒక్కరికీ సమానమైన పద్ధతిలో ముందుకు సాగడానికి నిర్ణయంలో పాల్గొన్న వ్యక్తులకు అనుమతిస్తుంది.",
            "or": "ବହୁତ ଭଲ! ବୁଝାମଣା ନିଷ୍ପତ୍ତିରେ ଜଡିତ ଲୋକଙ୍କ ପାଇଁ ବିଭିନ୍ନ ପସନ୍ଦକୁ ସମ୍ଭବ ବୋଲି ଜାଣିବା ଏବଂ ଏହିପରି ଭାବରେ ଆଗକୁ ବଢିବାକୁ ଅନୁମତି ଦେଇଥାଏ ଯାହା ଏଥିରେ ସମ୍ପୃକ୍ତ ସମସ୍ତଙ୍କ ପାଇଁ ସମାନ ହୋଇଥାଏ ।",
            "as": "ভাল কাম! বুজাবুজিয়ে সিদ্ধান্তত জড়িত সকলোকে তেওঁলোকৰ হাতত থকা বিভিন্ন বিকল্প বোৰ চাবলৈ সুযোগ দিয়ে আৰু সিদ্ধান্তটোত জড়িত সকলোৱে একেলগে আগবাঢ়ি যাব পাৰে।।",
            "gu": "સરસ કામ! વાર્તાલાભથી નિર્ણયમાં ભેગા થયેલા લોકોને અલગ અલગ  પસંદગીઓ શક્ય જોવા અને દરેક વ્યક્તિ માટે સમાન હોય તેવી રીતે આગળ જવાની રસ્તો આપે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! To get information we do research and networking. To reach an agreement when starting out with differing points of view, it’s important to negotiate.",
            "hi": "फिर से विचार करें! जानकारी प्राप्त करने के लिए हम रिसर्च और नेटवर्किंग करते हैं। जब हम भिन्न विचारों के साथ शुरुआत करते हैं और किसी निर्णय पर पहुंचना चाहते हैं तब निगोशिएट करना महत्वपूर्ण है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಮಾಹಿತಿಯನ್ನು ಪಡೆಯಲು ನಾವು ಸಂಶೋಧನೆ ಮತ್ತು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮಾಡುತ್ತೇವೆ. ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳೊಂದಿಗೆ ಪ್ರಾರಂಭಿಸುವಾಗ ಒಪ್ಪಂದವನ್ನು ತಲುಪಲು, ಮಾತುಕತೆ ನಡೆಸುವುದು ಮುಖ್ಯವಾಗಿದೆ.",
            "te": "మళ్లీ ఆలోచించు! సమాచారాన్ని పొందడానికి మేము పరిశోధన మరియు నెట్‌వర్కింగ్ చేస్తాము.భిన్నమైన అభిప్రాయాలు, ప్రారంభించేటప్పుడు ఒక ఒప్పందాన్ని చేరుకోవడానికి చర్చలు జరపడం ముఖ్యం.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ସୂଚନା ପାଇବା ପାଇଁ ଆମେ ଅନୁସନ୍ଧାନ ଏବଂ ନେଟୱାର୍କିଂ କରୁ । ଭିନ୍ନ ଭିନ୍ନ ଦୃଷ୍ଟିକୋଣରୁ ଆରମ୍ଭ କରିବା ସମୟରେ ଏକ ରାଜିନାମାରେ ପହଞ୍ଚିବା ପାଇଁ, ବୁଝାମଣା କରିବା ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ।",
            "as": "আকৌ চিন্তা কৰা! তথ্য প্ৰাপ্ত কৰিবলৈ আমি গৱেষণা আৰু নেটৱৰ্কিং কৰোঁ। ভিন্ন দৃষ্টিভংগীৰে আৰম্ভ কৰোঁতে এক চুক্তিত উপনীত হ'বলৈ, বুজাবুজি কৰাটো গুৰুত্বপূৰ্ণ।",
            "gu": "વિચારો ફરીથી! સંશોધન અને નેટવર્કિંગની મદદથી માહિતી મેળવીએ છીએ. જુદા જુદા વિચારોથી શરૂઆત કરતી વખતે સમજૂતી સુધી પહોંચવા માટે, વાટાઘાટો કરવી એ મહત્વ હોય છે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Field Ecologist",
            "hi": "फिल्ड इकोलॉजिस्ट",
            "ka": "**THREAD_END**_ಕ್ಷೇತ್ರ ಪರಿಸರಶಾಸ್ತ್ರಜ್ಞ",
            "te": "**THREAD_END**_జూనియర్ పర్యావరణ సలహాదారు",
            "or": "**THREAD_END** _ଫିଲ୍ଡ ଇକୋଲୋଜିଷ୍ଟ",
            "as": "**থ্ৰেড_এণ্ড**_ক্ষেত্ৰ পৰিস্থিতিবিদ",
            "gu": "**THREAD_END**_ફિલ્ડ ઇકોલોજિસ્ટ"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Razia that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने रजिया के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँची है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकती है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n \n ನೀವು ರಝಿಯಾಗಾಗಿ ಮಾಡಿದ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ.ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు రజియా కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర దృశ్యాలను అన్వేషించాలనుకుంటున్నారా?\"",
            "or": "ଅଭିନନ୍ଦନ! \n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି! \n ଆପଣ ରଜିଆ ପାଇଁ ନିଷ୍ପତ୍ତିଗୁଡିକ ପସନ୍ଦ କରିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତିକୁ ଆଗେଇ ନେଇଛି । ଆପଣ କରୁଥିବା ପସନ୍ଦଗୁଡିକ ଉପରେ ଆଧାର କରି ବିଭିନ୍ନ ପ୍ରକାରର ଶେଷ ଅଛି । ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହାଁନ୍ତି କି?",
            "as": "অভিনন্দন!\n তুমি এটা যাত্ৰা সমাপ্ত কৰিলা!\n \n তুমি ৰাজিয়াৰ বাবে বাছনি কৰিছা যাৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত আধাৰিত কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ জানিব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક સફર પૂરી કરી છે!\n\nતમે રઝિયા માટે જે પસંદગીઓ કરી છે જેના કારણે આ સંભવિત અંત આવ્યો છે. તમે જે પસંદગી કરો છો તેના આધારે વિવિધ અંત છે. શું તમે બીજા દ્રશ્યો શોધવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ର ଉପରେ ନଜର ପକାନ୍ତୁ",
            "as": "মানচিত্ৰখন চোৱা",
            "gu": "ચાલો નકશા તરફ નજર નાખીએ"
          }
        }
      ]
    },
    "scene22": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_1_1_ Do a Diploma course",
            "hi": "डिप्लोमा कोर्स करना",
            "ka": "B1_1_1_ ಡಿಪ್ಲೊಮಾ ಕೋರ್ಸ್ ಮಾಡಿ",
            "te": "B1_1_1_ డిప్లొమా కోర్సు చేయండి",
            "or": "B1_1_1_ଏକ ଡିପ୍ଲୋମା ପାଠ୍ୟକ୍ରମ କରନ୍ତୁ",
            "as": "B1_1_1_ ডিপ্লোমা পাঠ্যক্ৰম এটা কৰক",
            "gu": "B1_1_1_ ડિપ્લોમા કોર્સ કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia decides to do a diploma.\n\nShe goes to meet the academic coordinator of the Vocational Training Institute with Imran dada to ask about the admission.",
            "hi": "रजिया डिप्लोमा करने का निर्णय लेती है।\n \n वह इमरान दादा के साथ वोकेशनल ट्रेनिंग इंस्टीट्यूट के अकैडमिक कोऑर्डिनेटर से मिलती है और एडमिशन के बारे में पूछती है।",
            "ka": "\"ರಝಿಯಾ ಡಿಪ್ಲೊಮಾ ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಅವಳು ಪ್ರವೇಶದ ಬಗ್ಗೆ ಕೇಳಲು ಇಮ್ರಾನ್ ದಾದಾ ಅವರೊಂದಿಗೆ ವೃತ್ತಿಪರ ತರಬೇತಿ ಸಂಸ್ಥೆಯ ಶೈಕ್ಷಣಿಕ ಸಂಯೋಜಕರನ್ನು ಭೇಟಿಯಾಗಲು ಹೋಗುತ್ತಾಳೆ.",
            "te": "\"రజియా డిప్లొమా చేయాలని నిర్ణయించుకుంది.\n \n ఆమె అడ్మిషన్ గురించి అడగడానికి ఇమ్రాన్ అన్నయ్యతో కలిసి వృత్తి శిక్షణా సంస్థ యొక్క అకడమిక్ కోఆర్డినేటర్‌ని కలవడానికి వెళుతుంది.\"",
            "or": "ରଜିଆ ଡିପ୍ଲୋମା କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି। ସେ ଇମ୍ରାନ ଭାଇଙ୍କ ସହ ଧନ୍ଦାମୂଳକ ତାଲିମ ପ୍ରତିଷ୍ଠାନର ଏକାଡେମିକ୍ ସଂଯୋଜକଙ୍କୁ ଭେଟି ଆଡମିଶନ ବିଷୟରେ ପଚାରିବାକୁ ଯାଆନ୍ତି ।",
            "as": "ৰাজিয়াই এটা ডিপ্লোমা কৰাৰ কথা ভাবিছে।\n \n \n তাই নামভৰ্তিৰ বিষয়ে সুধিবলৈ ইমৰান দাদাৰ সৈতে বৃত্তিমূলক প্ৰশিক্ষণ প্ৰতিষ্ঠানৰ একাডেমিক সমন্বয়কক লগ কৰিবলৈ যায়।",
            "gu": "રઝિયાએ ડિપ્લોમા કરવાનું નક્કી કરે છે.\n\nતે એડમિશન વિશે પૂછવા ઇમરાન દાદા સાથે વોકેશનલ ટ્રેનિંગ ઇન્સ્ટિટ્યૂટના શૈક્ષણિક સંયોજકને મળવા જાય છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_1_2////",
            "hi": "B1_1_2////",
            "ka": "B1_1_2////",
            "te": "B1_1_2////",
            "or": "B1_1_2/////",
            "as": "B1_1_2////",
            "gu": "B1_1_2////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "This is the college brochure. We have 3 courses, you can read about them.",
            "hi": "यह कॉलेज का ब्रोशर है। हमारे पास 3 कोर्सेस हैं, आप उनके बारे में पढ़ सकते हैं।",
            "ka": "ಇದು ಕಾಲೇಜಿನ ಕರಪತ್ರ. ನಮ್ಮಲ್ಲಿ ೩ ಕೋರ್ಸ್‌ಗಳಿವೆ, ನೀವು ಅವುಗಳ ಬಗ್ಗೆ ಓದಬಹುದು.",
            "te": "ఇది కళాశాల బ్రోచర్. మాకు 3 కోర్సులు ఉన్నాయి, మీరు వాటి గురించి చదువుకోవచ్చు.",
            "or": "ଏହା ହେଉଛି କଲେଜ ବ୍ରୋସର୍ । ଆମର 3ଟି ପାଠ୍ୟକ୍ରମ ଅଛି, ଆପଣ ସେଗୁଡ଼ିକ ବିଷୟରେ ପଢିପାରିବେ ।",
            "as": "এইখন কলেজৰ প্ৰচাৰ পত্ৰিকা। আমাৰ 3 টা পাঠ্যক্ৰম আছে, আপুনি সেইবোৰৰ বিষয়ে পঢ়িব পাৰে।",
            "gu": "આ કોલેજની પુસ્તિકા છે જેમાં બધી માહિતી છે. અમારી પાસે 3 અભ્યાસક્રમો છે, તમે તેના વિશે વાંચી શકો છો."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "But how am I supposed to choose which course I want to take up?",
            "hi": "लेकिन मुझे कौन सा कोर्स करना चाहिए यह मैं कैसे चुनुंगी?",
            "ka": "ಆದರೆ ನಾನು ಯಾವ ಕೋರ್ಸ್ ಅನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕೆಂದು ನಾನು ಹೇಗೆ ಆಯ್ಕೆ ಮಾಡಿಕೊಳ್ಳಬೇಕು?",
            "te": "అయితే నేను ఏ కోర్సును ఎంచుకోవాలి?",
            "or": "କିନ୍ତୁ ମୁଁ କେଉଁ ପାଠ୍ୟକ୍ରମ ଗ୍ରହଣ କରିବାକୁ ଚାହୁଁଛି ତାହା ମୁଁ କିପରି ବାଛିବି?",
            "as": "কিন্তু মই কোনটো পাঠ্যক্ৰম লম সেইটো মই কেনেকৈ বাছিব লাগিব?",
            "gu": "પણ કયો કોર્સ હું કરવા માંગુ છું તે હું કેવી રીતે પસંદ કરી શકું?"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Go through each course and the job opportunities available after the course. We have also done a market study to help you pick a course.",
            "hi": "हर एक कोर्स के बारे में और कोर्स पूरा होने के बाद उपलब्ध जॉब के अवसरों के बारे में पढ़ो। आपको कोर्स चुनने में मदद हो इसलिए हमने मार्केट का अध्ययन भी किया हैं।",
            "ka": "ಪ್ರತಿ ಕೋರ್ಸ್ ಮತ್ತು ಕೋರ್ಸ್ ನಂತರ ಲಭ್ಯವಿರುವ ಉದ್ಯೋಗಾವಕಾಶಗಳ ಮೂಲಕ ಹೋಗಿ. ಕೋರ್ಸ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡಲು ನಾವು ಮಾರುಕಟ್ಟೆ ಅಧ್ಯಯನವನ್ನು ಸಹ ಮಾಡಿದ್ದೇವೆ.",
            "te": "ప్రతి కోర్సు మరియు కోర్సు తర్వాత అందుబాటులో ఉన్న ఉద్యోగ అవకాశాలను పరిశీలించండి. మీరు కోర్సును ఎంచుకోవడంలో సహాయపడటానికి మేము మార్కెట్ అధ్యయనం కూడా చేసాము.",
            "or": "ପାଠ୍ୟକ୍ରମ ପରେ ଉପଲବ୍ଧ ପ୍ରତ୍ୟେକ ପାଠ୍ୟକ୍ରମ ଏବଂ ଚାକିରି ସୁଯୋଗ ବିଷୟରେ ଭଲରେ ପଢନ୍ତୁ । ଆପଣଙ୍କୁ ଏକ ପାଠ୍ୟକ୍ରମ ବାଛିବାରେ ସାହାଯ୍ୟ କରିବା ପାଇଁ ଆମେ ଏକ ବଜାର ଅଧ୍ୟୟନ ମଧ୍ୟ କରିଛୁ ।",
            "as": "পাঠ্যক্ৰমৰ পিছত পাব পৰা প্ৰতিটো চাকৰিৰ সুযোগবোৰৰ বিষয়ে চাওক। আমি আপোনাক এটা পাঠ্যক্ৰম বাছনি কৰাত সহায় কৰিবলৈ এটা বজাৰ অধ্যয়নও কৰিছোঁ।",
            "gu": "દરેક કોર્સ જોવો અને કોર્સ પછી ઉપલબ્ધ નોકરીની તકો પર જુઓ. તમને કોર્સ પસંદ કરવામાં અને મદદ કરવા અને અભ્યાસ માટે અમે બહાર નજર કરેલી છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_1_3interaction",
            "hi": "B1_1_3बातचीत करना",
            "ka": "B1_1_3 ಪರಸ್ಪರ ಕ್ರಿಯೆ",
            "te": "B1_1_3 ఇంటరాక్షన్",
            "or": "B1_1_3 ଭାବର ଆଦାନ ପ୍ରଦାନ",
            "as": "B1_1_3 ভাবৰ আদান প্ৰদান",
            "gu": "B1_1_3 વાર્તાલાભ"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "The coordinator gives Razia the brochure that has information about the courses. Razia opens and reads... \nClick on each page to find out.",
            "hi": "कोऑर्डिनेटर रजिया को ब्रोशर देता है जिसमें इन कोर्सेस के बारे में जानकारी दी हुई है। रजिया यह खोलती है और पढ़ना शुरू करती है... \n जानकारी के लिए हर एक पेज पर क्लिक करें।",
            "ka": "\"ಕೋರ್ಡಿನೇಟರ್ ರಝಿಯಾ ಅವರಿಗೆ ಕೋರ್ಸ್‌ಗಳ ಬಗ್ಗೆ ಮಾಹಿತಿ ಇರುವ ಬ್ರೋಷರ್ ಅನ್ನು ನೀಡುತ್ತಾರೆ. ರಝಿಯಾ ತೆರೆದು ಓದುತ್ತಾಳೆ… ಕಂಡುಹಿಡಿಯಲು ಪ್ರತಿ ಪುಟದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ.\"",
            "te": "కోఆర్డినేటర్ రజియాకు కోర్సుల గురించిన సమాచారం ఉన్న బ్రోచర్ ఇచ్చారు. రజియా తెరిచి చదువుతుంది...\n తెలుసుకోవడానికి ప్రతి పేజీపై క్లిక్ చేయండి.",
            "or": "ସଂଯୋଜକ ରଜିଆଙ୍କୁ ବ୍ରୋସର ଦିଅନ୍ତି ଯେଉଁଥିରେ ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ସୂଚନା ଅଛି । ରଜିଆ ଖୋଲି ପଢନ୍ତି... ଜାଣିବା ପାଇଁ ପ୍ରତ୍ୟେକ ପୃଷ୍ଠାରେ କ୍ଲିକ୍ କରନ୍ତୁ।",
            "as": "সমন্বয়কাৰীয়ে ৰাজিয়াক পাঠ্যক্ৰমৰ বিষয়ে তথ্য থকা কিতাপখন দিয়ে। ৰাজিয়াই খুলি পঢ়ে... \n জানিবলৈ প্ৰতিটো পৃষ্ঠাত ক্লিক কৰা।",
            "gu": "સલાહકાર રઝિયાને એક પુસ્તિકા આપે છે જેમાં કોર્સ વિશેની માહિતી હોય છે. રઝિયા ખોલે છે અને વાંચે છે...\nતે શોધવા માટે દરેક પેજ પર ક્લિક કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Page 1",
            "hi": "पेज 1",
            "ka": "ಪುಟ 1",
            "te": "పేజీ 1",
            "or": "ପୃଷ୍ଠା 1",
            "as": "Page 1",
            "gu": "પેજ 1"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Page 2",
            "hi": "पेज 2",
            "ka": "ಪುಟ 2",
            "te": "పేజీ 2",
            "or": "ପୃଷ୍ଠା 2",
            "as": "Page 2",
            "gu": "પેજ 2"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "Page 3",
            "hi": "पेज 3",
            "ka": "ಪುಟ 3",
            "te": "పేజీ 3",
            "or": "ପୃଷ୍ଠା 3",
            "as": "Page 3",
            "gu": "પેજ 3"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Course offered: Beautician\nCourse details - Beauty Therapy, Hair Dressing, Skin and Hair Treatments, Corrective Makeup, Portfolio Shoot         \nDuration: 1 year\n                                         Eligibility: 10th pass\nJobs opportunities and market: \nMost women after studying this course start their own beauty studio. It has high demand if one wants to work in the city.",
            "hi": "ऑफर किए जानेवाले कोर्स: ब्यूटीशियन \n कोर्स के तपशील – ब्यूटी थेरपी, हेअर ड्रेसिंग, त्वचा और बालों के ट्रीटमेंट, करेक्टिव मेकअप, पोर्टफोलियो शूट \n कालावधी: 1 वर्ष \n  पात्रता: 10 वीं पास \n जॉब के अवसर और मार्केट: \n इस कोर्स को पढ़ने के बाद ज्यादातर महिलाएं खुद का ब्यूटी स्टूडियो शुरू करती हैं। अगर किसी को शहर में काम करना हो यह काफी अच्छा ऑप्शन है।",
            "ka": "\"ಕೋರ್ಸ್ ನೀಡಲಾಗುತ್ತದೆ: ಬ್ಯೂಟಿಷಿಯನ್\n ಕೋರ್ಸ್ ವಿವರಗಳು - ಬ್ಯೂಟಿ ಥೆರಪಿ, ಹೇರ್ ಡ್ರೆಸ್ಸಿಂಗ್, ಚರ್ಮ ಮತ್ತು ಕೂದಲು ಚಿಕಿತ್ಸೆಗಳು, ಸರಿಪಡಿಸುವ ಮೇಕಪ್, ಪೋರ್ಟ್ಫೋಲಿಯೊ ಶೂಟ್\n ಅವಧಿ: 1 ವರ್ಷ\n  ಅರ್ಹತೆ: ೧೦ ನೇ ತೇರ್ಗಡೆ\n ಉದ್ಯೋಗ ಅವಕಾಶಗಳು ಮತ್ತು ಮಾರುಕಟ್ಟೆ:\n ಈ ಕೋರ್ಸ್ ಓದಿದ ನಂತರ ಹೆಚ್ಚಿನ ಮಹಿಳೆಯರು ತಮ್ಮದೇ ಆದ ಬ್ಯೂಟಿ ಸ್ಟುಡಿಯೊವನ್ನು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ. ನಗರದಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಬಯಸಿದರೆ ಇದಕ್ಕೆ ಹೆಚ್ಚಿನ ಬೇಡಿಕೆಯಿದೆ.",
            "te": "ఆఫర్ చేయబడే కోర్సు: బ్యూటీషియన్\n కోర్సు వివరాలు - బ్యూటీ థెరపీ, హెయిర్ డ్రెస్సింగ్, స్కిన్ మరియు హెయిర్ ట్రీట్‌మెంట్స్, కరెక్టివ్ మేకప్, పోర్ట్ ఫోలియో షూట్\n వ్యవధి: 1 సంవత్సరం \n అర్హత: 10వ తరగతి పాస్\n ఉద్యోగ అవకాశాలు మరియు మార్కెట్: ఈ కోర్సు చదివిన తర్వాత చాలా మంది మహిళలు తమ సొంత బ్యూటీ స్టూడియోను ప్రారంభిస్తారు. ఎవరైనా నగరంలో పని చేయాలనుకుంటే దీనికి అధిక డిమాండ్ ఉంది.",
            "or": "ପ୍ରଦାନ କରାଯାଇଥିବା ପାଠ୍ୟକ୍ରମ: ବିୟୁଟିସିଆନ୍ \n କୋର୍ସ ବିବରଣୀ - ସୌନ୍ଦର୍ଯ୍ୟ ଥେରାପି, ହେୟାର ଡ୍ରେସିଂ, ଚର୍ମ ଏବଂ କେଶ ଚିକିତ୍ସା, ସଂଶୋଧନ ମେକଅପ୍, ପୋର୍ଟଫୋଲିଓ ସୁଟ୍ \n ଅବଧି: 1 ବର୍ଷ \n ଯୋଗ୍ୟତା: ଦଶମ ପାସ୍ \n ନିଯୁକ୍ତି ସୁଯୋଗ ଏବଂ ବଜାର: ଏହି ପାଠ୍ୟକ୍ରମ ଅଧ୍ୟୟନ କରିବା ପରେ ଅଧିକାଂଶ ମହିଳା ସେମାନଙ୍କର ନିଜସ୍ୱ ସୌନ୍ଦର୍ଯ୍ୟ ଷ୍ଟୁଡିଓ ଆରମ୍ଭ କରନ୍ତି । ଯଦି ଜଣେ ସହରରେ କାମ କରିବାକୁ ଚାହେଁ ତେବେ ଏହାର ଅଧିକ ଚାହିଦା ଅଛି ।",
            "as": "পাঠ্যক্ৰম আগবঢ়োৱা হৈছে: বিউটিচিয়ান\n পাঠ্যক্ৰমৰ বিৱৰণ - বিউটি থেৰাপী, হেয়াৰ ড্ৰেছিং, ছাল আৰু চুলিৰ চিকিৎসা, সংশোধনমূলক মেকআপ, পৰ্টফলিঅ শ্বুট \n ম্যাদ: 1 বছৰ\n  যোগ্যতা: দশম উত্তীৰ্ণ\n চাকৰিৰ সুযোগ আৰু বজাৰ: \n এই পাঠ্যক্ৰমটো অধ্যয়ন কৰাৰ পিছত বেছিভাগ মহিলাই তেওঁলোকৰ নিজৰ বিউটিপাৰ্লাৰ খোলে। যদি কোনোবাই চহৰখনত কাম কৰিব বিচাৰে তেন্তে ইয়াৰ বহুক বেছি চাহিদা আছে।",
            "gu": "કોર્સ પ્રદાન કરે છે: સુંદરતાનો\nઅભ્યાસક્રમની વિગતો - બ્યુટી થેરાપી, હેર ડ્રેસિંગ, ત્વચા અને વાળની સારવાર, સુધારાત્મક મેકઅપ, પોર્ટફોલિયો શૂટ\nસમયગાળો: 1 વર્ષ\n                                          લાયકાત: 10મું પાસ\nનોકરીની તકો અને બજારમાં એની માંગ:\nમોટાભાગની મહિલાઓ આ કોર્સનો અભ્યાસ કર્યા પછી પોતાનો બ્યુટી સ્ટુડિયો શરૂ કરે છે. તેની ખૂબ માંગ છે જો કોઈ શહેરમાં કામ કરવા માંગે છે તો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Course offered: Tailoring\nCourse details -  Sewing Terminology, Body Measurement Methods, Hand & Machine Stitches    \nDuration: 2 years\n                                       Eligibility: 10th pass\nJobs opportunities and market: \nTailoring skills are in high demand near the jute mills in Kolkata as there are many stitching units there.",
            "hi": "ऑफर किए जानेवाले कोर्स: टेलरिंग \n कोर्स के तपशील – सिलाई टर्मिनोलॉजी, शरीर के माप लेने के तरीके, हाथ और मशीन से सिलाई \n कालावधी: 2 वर्ष \n  पात्रता: 10 वीं पास \n जॉब के अवसर और मार्केट: \n टेलरिंग कौशल को कोलकाता के ज्यूट मिल्स में काफी मांग है क्योंकि वहाँ पर बहुत से सिलाई यूनिट्स है।",
            "ka": "\"ಕೊಡುವ ಕೋರ್ಸ್: ಟೈಲರಿಂಗ್\n ಕೋರ್ಸ್ ವಿವರಗಳು - ಹೊಲಿಗೆ ಪರಿಭಾಷೆ, ದೇಹ ಮಾಪನ ವಿಧಾನಗಳು, ಕೈ ಮತ್ತು ಯಂತ್ರದ ಹೊಲಿಗೆಗಳು\n ಅವಧಿ: 2 ವರ್ಷಗಳು\n  ಅರ್ಹತೆ: 10 ನೇ ತೇರ್ಗಡೆ\n ಉದ್ಯೋಗ ಅವಕಾಶಗಳು ಮತ್ತು ಮಾರುಕಟ್ಟೆ:\n ಕೋಲ್ಕತ್ತಾದ ಸೆಣಬಿನ ಗಿರಣಿಗಳಲ್ಲಿ ಅನೇಕ ಹೊಲಿಗೆ ಘಟಕಗಳು ಇರುವುದರಿಂದ ಟೈಲರಿಂಗ್ ಕೌಶಲ್ಯಗಳಿಗೆ ಹೆಚ್ಚಿನ ಬೇಡಿಕೆಯಿದೆ.",
            "te": "ఆఫర్ చేయబడే కోర్సు: టైలరింగ్\n కోర్సు వివరాలు - కుట్టు పదజాలం, శరీర కొలత పద్ధతులు, చేతి & మెషిన్ కుట్లు\n వ్యవధి: 2 సంవత్సరాలు \n అర్హత: 10వ తరగతి పాస్\n ఉద్యోగ అవకాశాలు మరియు మార్కెట్: కోల్‌కతాలోని జ్యూట్ మిల్లుల దగ్గర టైలరింగ్ నైపుణ్యాలకు చాలా డిమాండ్ ఉంది, ఎందుకంటే అక్కడ చాలా కుట్టు యూనిట్లు ఉన్నాయి.",
            "or": "ପ୍ରଦାନ କରାଯାଇଥିବା ପାଠ୍ୟକ୍ରମ: ଟେଲରିଂ\n କୋର୍ସ ବିବରଣୀ - ସିଲେଇ ଶବ୍ଦ, ଶରୀର ପରିମାପ ପଦ୍ଧତି, ହ୍ୟାଣ୍ଡ ଏବଂ ମେସିନ୍ ଷ୍ଟିଚ୍ \n ଅବଧି: 2 ବର୍ଷ \n ଯୋଗ୍ୟତା: ଦଶମ ପାସ୍ \n ନିଯୁକ୍ତି ସୁଯୋଗ ଏବଂ ବଜାର: କୋଲକାତାର ଝୋଟ ମିଲ୍ ନିକଟରେ ଟେଲରିଂ ଦକ୍ଷତାର ଅଧିକ ଚାହିଦା ଅଛି କାରଣ ସେଠାରେ ଅନେକ ସିଲେଇ ୟୁନିଟ୍ ଅଛି ।",
            "as": "পাঠ্যক্ৰম আগবঢ়োৱা হৈছে: টেইলাৰিং\n পাঠ্যক্ৰমৰ বিৱৰণ - চিলাই পৰিভাষা, শৰীৰৰ জোখ মাপ পদ্ধতি, হাত আৰু যন্ত্ৰৰ সহায়ত চিলাই \n ম্যাদ: 2 বছৰ\n  যোগ্যতা: দশম উত্তীৰ্ণ\n চাকৰিৰ সুযোগ আৰু বজাৰ: \n কলকাতাৰ মৰাপাট কলবোৰৰ ওচৰত টেইলাৰিং দক্ষতাৰ বহুত বেছি চাহিদা আছে কাৰণ তাত বহুতো চিলাই গোট আছে।",
            "gu": "કોર્સ પ્રદાન કરે છે: શિલાઈ કામ\nઅભ્યાસક્રમની વિગતો - સીવણ પરિભાષા, શરીર માપવાની પદ્ધતિઓ, હાથ અને મશીનના ટાંકા\nસમયગાળો: 2 વર્ષ\n                                        લાયકાત: 10મું પાસ\nનોકરીની તકો અને બજારમાં એની માંગ:\nકોલકાતામાં જ્યુટ મિલોની નજીક શિલાઈ કામ કૌશલ્યોની ખૂબ માંગ છે કારણ કે ત્યાં ઘણા શિલાઈ કામ એકમો છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Course offered: Front Office and Administration\nCourse details - Reservation, Reception, Cash Billing, Business Communication, Guest and Customer Care \nDuration: 2 years\nEligibility: 10th pass\nJobs opportunities and market: \nThere is a high demand for trained professionals in and around Sunderbans as tourism is rising and more resorts are opening up.",
            "hi": "ऑफर किए जानेवाले कोर्स: फ्रंट ऑफिस और एडमिनिस्ट्रेशन \n कोर्स के तपशील – रिजर्वेशन, रिसेप्शन, कैश बिलिंग, बिजनेस कम्युनिकेशन, मेहमान और ग्राहक सेवा \n कालावधी: 2 वर्ष \n पात्रता: 10 वीं पास \n जॉब के अवसर और मार्केट: \n टूरिजम बढ़ने के कारण और कई रिसोर्ट खुलने के कारण सुंदरबन के आसपास के प्रदेश में प्रशिक्षित प्रोफेशनल्स के लिए काफी मांग है।",
            "ka": "\"ಕೋರ್ಸ್ ನೀಡಲಾಗುತ್ತದೆ: ಫ್ರಂಟ್ ಆಫೀಸ್ ಮತ್ತು ಅಡ್ಮಿನಿಸ್ಟ್ರೇಷನ್\n ಕೋರ್ಸ್ ವಿವರಗಳು - ಮೀಸಲಾತಿ, ಸ್ವಾಗತ, ನಗದು ಬಿಲ್ಲಿಂಗ್, ವ್ಯಾಪಾರ ಸಂವಹನ, ಅತಿಥಿ ಮತ್ತು ಗ್ರಾಹಕ ಆರೈಕೆ\n ಅವಧಿ: ೨ ವರ್ಷಗಳು\n ಅರ್ಹತೆ: ೧೦ ನೇ ತೇರ್ಗಡೆ\n ಉದ್ಯೋಗ ಅವಕಾಶಗಳು ಮತ್ತು ಮಾರುಕಟ್ಟೆ:\n ಪ್ರವಾಸೋದ್ಯಮ ಹೆಚ್ಚುತ್ತಿರುವ ಕಾರಣ ಮತ್ತು ಹೆಚ್ಚಿನ ರೆಸಾರ್ಟ್‌ಗಳು ತೆರೆಯುತ್ತಿರುವುದರಿಂದ ಸುಂದರ್‌ಬನ್ಸ್ ಮತ್ತು ಸುತ್ತಮುತ್ತಲಿನ ತರಬೇತಿ ಪಡೆದ ವೃತ್ತಿಪರರಿಗೆ ಹೆಚ್ಚಿನ ಬೇಡಿಕೆಯಿದೆ.",
            "te": "ఆఫర్ చేయబడే కోర్సు: ఫ్రంట్ ఆఫీస్ మరియు అడ్మినిస్ట్రేషన్\n కోర్సు వివరాలు - రిజర్వేషన్, రిసెప్షన్, క్యాష్ బిల్లింగ్, బిజినెస్ కమ్యూనికేషన్, గెస్ట్ మరియు కస్టమర్ కేర్\n వ్యవధి: 2 సంవత్సరాలు\n అర్హత: 10వ తరగతి పాస్\n ఉద్యోగ అవకాశాలు మరియు మార్కెట్:టూరిజం పెరుగుతున్నందున మరియు మరిన్ని రిసార్ట్‌లు తెరవబడుతున్నందున సుందర్‌బన్స్ మరియు చుట్టుపక్కల శిక్షణ పొందిన నిపుణులకు అధిక డిమాండ్ ఉంది.\"",
            "or": "ପ୍ରଦାନ କରାଯାଇଥିବା ପାଠ୍ୟକ୍ରମ: ଫ୍ରଣ୍ଟ ଅଫିସ୍ ଏବଂ ଆଡମିନିଷ୍ଟ୍ରେସନ୍ \n କୋର୍ସ ବିବରଣୀ - ସଂରକ୍ଷଣ, ଅଭ୍ୟର୍ଥନା, ନଗଦ ବିଲିଂ, ବ୍ୟବସାୟ ଯୋଗାଯୋଗ, ଅତିଥି ଏବଂ ଗ୍ରାହକ ସେବା \n ଅବଧି: 2 ବର୍ଷ \n ଯୋଗ୍ୟତା: ଦଶମ ପାସ୍ \n ନିଯୁକ୍ତି ସୁଯୋଗ ଏବଂ ବଜାର: ପର୍ଯ୍ୟଟନ ବୃଦ୍ଧି ପାଉଥିବାରୁ ଏବଂ ଅଧିକ ରିସର୍ଟ ଖୋଲିବା କାରଣରୁ ସୁନ୍ଦରବନ ଏବଂ ଏହାର ଆଖପାଖରେ ତାଲିମପ୍ରାପ୍ତ ବୃତ୍ତିଗତମାନଙ୍କର ଅଧିକ ଚାହିଦା ଅଛି ।",
            "as": "পাঠ্যক্ৰম আগবঢ়োৱা হৈছে: অফিচৰ কাম-কাজ আৰু প্ৰশাসন\n পাঠ্যক্ৰমৰ বিৱৰণ - সংৰক্ষণ, অভ্যৰ্থনা, নগদ বিলিং, ব্যৱসায় লেন-দেন,জাতীয় যোগাযোগ, অতিথি আৰু গ্ৰাহক যত্ন \n ম্যাদ: 2 বছৰ\n যোগ্যতা: দশম উত্তীৰ্ণ\n চাকৰিৰ সুযোগ আৰু বজাৰ: \n সুন্দৰবন আৰু ইয়াৰ আশে-পাশে প্ৰশিক্ষিত পেছাদাৰীসকলৰ বহুত বেছি চাহিদা আছে কাৰণ পৰ্যটন বৃদ্ধি পাইছে আৰু অধিক ৰিজৰ্ট মুকলি হৈছে।",
            "gu": "કોર્સ પ્રદાન કરે છે: ફ્રન્ટ ઓફિસ અને એડમિનિસ્ટ્રેશન\nઅભ્યાસક્રમની વિગતો - રિઝર્વેશન, રિસેપ્શન, કેશ બિલિંગ, બિઝનેસ કોમ્યુનિકેશન, ગેસ્ટ અને કસ્ટમર કેર\nસમયગાળો: 2 વર્ષ\nલાયકાત: 10મું પાસ\nનોકરીની તકો અને બજારમાં એની માંગ:\nસુંદરબન અને તેના નજદીકમાં પ્રશિક્ષિત વ્યાવસાયિકોની ખૂબ જરૂરયાત છે કારણ કે પ્રવાસન વધી રહ્યું છે અને વધુ રિસોર્ટ ખુલી રહ્યા છે."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_1_7",
            "hi": "B1_1_7",
            "ka": "B1_1_7",
            "te": "B1_1_7",
            "or": "B1_1_7",
            "as": "B1_1_7",
            "gu": "B1_1_7"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia thinks about her options. She is not keen on taking up beautician and tailoring courses as they don't interest her. Front office and administration feels like the one that suits her the best.",
            "hi": "रजिया अपने ऑप्शन्स के बारे में सोचती है। वुसे ब्यूटीशियन और टेलरिंग के कोर्सेस में कोई दिलचस्पी नहीं है, इसलिए वह ये कोर्स नहीं करना चाहती। उसे लगता है कि उसके लिए फ्रंट ऑफिस और एडमिनिस्ट्रेशन सही रहेगा।",
            "ka": "ರಝಿಯಾ ತನ್ನ ಆಯ್ಕೆಗಳ ಬಗ್ಗೆ ಯೋಚಿಸುತ್ತಾಳೆ. ಬ್ಯೂಟಿಷಿಯನ್ ಮತ್ತು ಟೈಲರಿಂಗ್ ಕೋರ್ಸ್‌ಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಅವಳು ಆಸಕ್ತಿ ಹೊಂದಿಲ್ಲ ಏಕೆಂದರೆ ಅದು ಅವಳಿಗೆ ಆಸಕ್ತಿಯಿಲ್ಲ. ಫ್ರಂಟ್ ಆಫೀಸ್ ಮತ್ತು ಅಡ್ಮಿನಿಸ್ಟ್ರೇಷನ್ ಅವಳಿಗೆ ಸೂಕ್ತವಾದದ್ದು ಎಂದು ಭಾವಿಸುತ್ತದೆ.",
            "te": "రజియా తన ఎంపికల గురించి ఆలోచిస్తోంది. ఆమెకు బ్యూటీషియన్ మరియు టైలరింగ్ కోర్సులు ఆసక్తి లేకపోవడంతో ఆమె ఆసక్తి చూపడం లేదు. ఫ్రంట్ ఆఫీస్ మరియు అడ్మినిస్ట్రేషన్ ఆమెకు బాగా సరిపోతాయని అనిపిస్తుంది.",
            "or": "ରଜିଆ ତାଙ୍କ ବିକଳ୍ପ ବିଷୟରେ ଚିନ୍ତା କରନ୍ତି । ସେ ବିୟୁଟିସିଆନ୍ ଏବଂ ଟେଲରିଂ ପାଠ୍ୟକ୍ରମ ଗ୍ରହଣ କରିବାକୁ ଆଗ୍ରହୀ ନୁହଁନ୍ତି କାରଣ ସେଗୁଡିକରେ ତାଙ୍କର ଆଗ୍ରହ ନାହିଁ । ଫ୍ରଣ୍ଟ ଅଫିସ ଏବଂ ପ୍ରଶାସନ ତାଙ୍କୁ ସର୍ବୋତ୍ତମ ଉପଯୁକ୍ତ ଅନୁଭବ ହୁଏ ।",
            "as": "ৰাজিয়াই তাইৰ বিকল্পবোৰৰ বিষয়ে ভাবে। তাইৰ বিউটিচিয়ান আৰু টেইলাৰিং পাঠ্যক্ৰম লবলৈ মন নাই কিয়নো এইবোৰে তাইক আগ্ৰহী নকৰে। সন্মুখৰ কাৰ্যালয় আৰু প্ৰশাসনেই তাইৰ বাবে ভাল হ'ব বুলি ভাবে।",
            "gu": "રઝિયા તે વિકલ્પો વિશે વિચારે છે. તે બ્યુટીશીયન અને ટેલરીંગ કોર્સ લેવા માટે ઉત્સુક નથી કારણ કે તેમાં તે કોઈ રસ નથી. ફ્રન્ટ ઓફિસ અને એડમિનિસ્ટ્રેશન કરવા માગે છે કારણકે તે વધુ યોગ્ય લાગે છે."
          }
        }
      ],
      "page12": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_1_8",
            "hi": "B1_1_8",
            "ka": "B1_1_8",
            "te": "B1_1_8",
            "or": "B1_1_8",
            "as": "B1_1_8",
            "gu": "B1_1_8"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She decides to do a diploma course in front office and administration as there are many jobs available in her village. Ammi is quite happy about her decision.",
            "hi": "वह फ्रंट ऑफिस और एडमिनिस्ट्रेशन का डिप्लोमा कोर्स करने का निर्णय लेती है क्योंकि उसके गाँव के आसपास इस क्षेत्र के कई जॉब उपलब्ध हैं। अम्मी उसके निर्णय से बहुत खुश है।",
            "ka": "ತನ್ನ ಹಳ್ಳಿಯಲ್ಲಿ ಅನೇಕ ಉದ್ಯೋಗಗಳು ಲಭ್ಯವಿರುವುದರಿಂದ ಫ್ರಂಟ್ ಆಫೀಸ್ ಮತ್ತು ಆಡಳಿತದಲ್ಲಿ ಡಿಪ್ಲೊಮಾ ಕೋರ್ಸ್ ಮಾಡಲು ಅವಳು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಅಮ್ಮಿ ತನ್ನ ನಿರ್ಧಾರದ ಬಗ್ಗೆ ಸಾಕಷ್ಟು ಸಂತೋಷಪಟ್ಟಿದ್ದಾಳೆ.",
            "te": "తన గ్రామంలో చాలా ఉద్యోగాలు అందుబాటులో ఉన్నందున ఆమె ఫ్రంట్ ఆఫీస్ మరియు అడ్మినిస్ట్రేషన్‌లో డిప్లొమా కోర్సు చేయాలని నిర్ణయించుకుంది. అమ్మి తన నిర్ణయం పట్ల చాలా సంతోషంగా ఉంది.",
            "or": "ସେ ଫ୍ରଣ୍ଟ ଅଫିସ ଏବଂ ପ୍ରଶାସନରେ ଏକ ଡିପ୍ଲୋମା ପାଠ୍ୟକ୍ରମ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି କାରଣ ତାଙ୍କ ଗାଁରେ ଅନେକ ଚାକିରି ଉପଲବ୍ଧ ଅଛି । ଅମ୍ମି ତାଙ୍କ ନିଷ୍ପତ୍ତିକୁ ନେଇ ବହୁତ ଖୁସି ଅଛନ୍ତି ।",
            "as": "তাই অফিচৰ কাম কাজ আৰু প্ৰশাসনত ডিপ্লোমা কৰাৰ সিদ্ধান্ত লয় কিয়নো তাইৰ গাঁৱত বহুতো চাকৰি আছে । আম্মী তাইৰ সিদ্ধান্তক লৈ যথেষ্ট সুখী।",
            "gu": "તે ફ્રન્ટ ઓફિસ અને એડમિનિસ્ટ્રેશનમાં ડિપ્લોમા કોર્સ કરવાનું નક્કી કર્યું કારણ કે ઘણી નોકરીઓ ઉપલબ્ધ છે તેના ગામમાં. અમ્મી તેના નિર્ણયથી પણ ખૂબ રાજી છે."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "Market Study and trends",
            "hi": "मार्केट का अध्ययन और रुझान",
            "ka": "ಮಾರುಕಟ್ಟೆ ಅಧ್ಯಯನ ಮತ್ತು ಪ್ರವೃತ್ತಿಗಳು",
            "te": "మార్కెట్ అధ్యయనం మరియు పోకడలు (ట్రెండ్స్ )",
            "or": "ବଜାର ଅଧ୍ୟୟନ ଏବଂ ଟ୍ରେଣ୍ଡଗୁଡିକ",
            "as": "বজাৰ অধ্যয়ন আৰু ট্ৰেণ্ড",
            "gu": "બજાર અભ્યાસ અને વલણો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained market study and trends experience!",
            "hi": "आपने अभी-अभी मार्केट का अध्ययन और रुझान का अनुभव प्राप्त किया हैं!",
            "ka": "ನೀವು ಇದೀಗ ಮಾರುಕಟ್ಟೆ ಅಧ್ಯಯನ ಮತ್ತು ಪ್ರವೃತ್ತಿಗಳ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే మార్కెట్ అధ్యయనం మరియు పోకడల (ట్రెండ్‌ల) అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ବଜାର ଅଧ୍ୟୟନ ଏବଂ ଟ୍ରେଣ୍ଡ୍ ଅଭିଜ୍ଞତା ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ বজাৰ অধ্যয়ন আৰু ট্ৰেণ্ডৰ অভিজ্ঞতা লাভ কৰিছা!",
            "gu": "તમે હમણાં જ તમે બજાર વિષે અભ્યાસ અને વલણોનો વિષે અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Market Study and Trends",
            "hi": "मार्केट का अध्ययन और रुझान",
            "ka": "ಮಾರುಕಟ್ಟೆ ಅಧ್ಯಯನ ಮತ್ತು ಪ್ರವೃತ್ತಿಗಳು",
            "te": "మార్కెట్ అధ్యయనం మరియు పోకడలు (ట్రెండ్స్ )",
            "or": "ବଜାର ଅଧ୍ୟୟନ ଏବଂ ଟ୍ରେଣ୍ଡ୍",
            "as": "বজাৰ অধ্যয়ন আৰু ট্ৰেণ্ড",
            "gu": "બજાર અભ્યાસ અને વલણો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Understanding Work Environment",
            "hi": "कार्यस्थल के पर्यावरण को समझना",
            "ka": "ಕೆಲಸದ ಪರಿಸರವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು",
            "te": "పని వాతావరణాన్ని అర్థం చేసుకోవడం",
            "or": "କାର୍ଯ୍ୟ ପରିବେଶ ବୁଝିବା",
            "as": "কামৰ পৰিৱেশ বুজা",
            "gu": "કામના વાતાવરણને સમજવું"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Razia studies the college brochure to find out the courses that have the most jobs. Researching the market and trends before selecting a particular course is to know which industry is doing well or not. A trend is a general direction in which something is developing or changing based on what is popular. You can know the market trends by reading newspapers, articles, talking to people to understand the opportunities available after completing the course, and understanding if the trend is only there for a short time.",
            "hi": "रजिया कॉलेज के ब्रोशर को पढ़ती है और पता करती है कि कौन सा कोर्स करने ज्यादा जॉब अवसर उपलब्ध हैं। किसी विशेष कोर्स को चुनने से पहले मार्केट और रुझान के बारे में रिसर्च करना मतलब कौन सा उद्योग अच्छा प्रदर्शन कर रहा है यह जानना। रुझान का मतलब है किसी विशेष दिशा में विकास होना या लोकप्रियता के अनुसार बदलाव करना। आप अख़बार, लेख पढ़कर, मार्केट के रुझान के बारे जान सकते हैं, लोगो से बातचीत करने के कोर्स के पूरा होने के बाद कौन से अवसर प्राप्त हो सकते हैं इसकी जानकारी लेते हैं और यह समझ सकते हैं कि कोई भी रुझान कितने समय तक चलेगा।",
            "ka": "ಹೆಚ್ಚಿನ ಉದ್ಯೋಗಗಳನ್ನು ಹೊಂದಿರುವ ಕೋರ್ಸ್‌ಗಳನ್ನು ಕಂಡುಹಿಡಿಯಲು ರಝಿಯಾ ಕಾಲೇಜು ಕರಪತ್ರವನ್ನು ಅಧ್ಯಯನ ಮಾಡುತ್ತಾಳೆ. ನಿರ್ದಿಷ್ಟ ಕೋರ್ಸ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡುವ ಮೊದಲು ಮಾರುಕಟ್ಟೆ ಮತ್ತು ಟ್ರೆಂಡ್‌ಗಳನ್ನು ಸಂಶೋಧಿಸುವುದು ಯಾವ ಉದ್ಯಮವು ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಿದೆ ಅಥವಾ ಇಲ್ಲ ಎಂದು ತಿಳಿಯುವುದು. ಟ್ರೆಂಡ್ ಎನ್ನುವುದು ಒಂದು ಸಾಮಾನ್ಯ ನಿರ್ದೇಶನವಾಗಿದ್ದು, ಅದರಲ್ಲಿ ಯಾವುದೋ ಜನಪ್ರಿಯವಾಗಿರುವ ಆಧಾರದ ಮೇಲೆ ಏನಾದರೂ ಅಭಿವೃದ್ಧಿ ಹೊಂದುತ್ತಿದೆ ಅಥವಾ ಬದಲಾಗುತ್ತಿದೆ. ದಿನಪತ್ರಿಕೆಗಳು, ಲೇಖನಗಳನ್ನು ಓದುವುದು, ಕೋರ್ಸ್ ಮುಗಿದ ನಂತರ ಲಭ್ಯವಿರುವ ಅವಕಾಶಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಜನರೊಂದಿಗೆ ಮಾತನಾಡುವುದು ಮತ್ತು ಸ್ವಲ್ಪ ಸಮಯದವರೆಗೆ ಟ್ರೆಂಡ್ ಇದೆಯೇ ಎಂದು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವ ಮೂಲಕ ಮಾರುಕಟ್ಟೆಯ ಪ್ರವೃತ್ತಿಯನ್ನು ನೀವು ತಿಳಿದುಕೊಳ್ಳಬಹುದು.",
            "te": "ఎక్కువ ఉద్యోగాలు ఉన్న కోర్సులను తెలుసుకోవడానికి రజియా కళాశాల బ్రోచర్‌ను అధ్యయనం చేస్తుంది. ఫలానా కోర్సును ఎంచుకునే ముందు మార్కెట్ మరియు ట్రెండ్‌లను పరిశోధించడం అంటే ఏ పరిశ్రమ బాగా పని చేస్తుందో లేదా అని తెలుసుకోవడం. ట్రెండ్ అనేది జనాదరణ పొందిన వాటి ఆధారంగా ఏదైనా అభివృద్ధి చెందడం లేదా మారుతున్న సాధారణ దిశ. వార్తాపత్రికలు, కథనాలు చదవడం, కోర్సు పూర్తయిన తర్వాత ఉన్న అవకాశాలను అర్థం చేసుకునేందుకు ప్రజలతో మాట్లాడడం, ట్రెండ్ కొద్దికాలం మాత్రమే ఉంటే అర్థం చేసుకోవడం ద్వారా మార్కెట్ ట్రెండ్‌లను తెలుసుకోవచ్చు",
            "or": "ସର୍ବାଧିକ ନିଯୁକ୍ତି ସୁଯୋଗ ଥିବା ପାଠ୍ୟକ୍ରମ ଜାଣିବା ପାଇଁ ରଜିଆ କଲେଜ ବ୍ରୋସର ଅଧ୍ୟୟନ କରନ୍ତି । ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ପାଠ୍ୟକ୍ରମ ଚୟନ କରିବା ପୂର୍ବରୁ ବଜାର ଏବଂ ଟ୍ରେଣ୍ଡ ଉପରେ ଗବେଷଣା କରିବା ହେଉଛି କେଉଁ ଶିଳ୍ପ ଭଲ କରୁଛି କି ନାହିଁ ଜାଣିବା । ଏକ ଟ୍ରେଣ୍ଡ ହେଉଛି ଏକ ସାଧାରଣ ଦିଗ ଯେଉଁଥିରେ ଲୋକପ୍ରିୟ ଉପରେ ଆଧାର କରି କିଛି ବିକାଶ କିମ୍ବା ପରିବର୍ତ୍ତନ ହେଉଛି । ଆପଣ ଖବରକାଗଜ, ପ୍ରବନ୍ଧ ପଢି, ପାଠ୍ୟକ୍ରମ ସମାପ୍ତ କରିବା ପରେ ଉପଲବ୍ଧ ସୁଯୋଗ ବୁଝିବା ପାଇଁ ଲୋକଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରି ପାରିବେ ଏବଂ ବୁଝିପାରିବେ ଯେ ଏହି ଟ୍ରେଣ୍ଡ କେବଳ ଅଳ୍ପ ସମୟ ପାଇଁ ଅଛି କି ନାହିଁ ।",
            "as": "ৰাজিয়াই আটাইতকৈ বেছি চাকৰি থকা পাঠ্যক্ৰমবিলাক বিচাৰিবলৈ কলেজৰ কিতাপখন পঢ়ে। এটা নিৰ্দিষ্ট পাঠ্যক্ৰম বাছনি কৰাৰ আগতে বজাৰ আৰু ট্ৰেণ্ডৰ ওপৰত গৱেষণা কৰাটো হ'ল কোনটো উদ্যোগে ভাল কাম কৰি আছে বা নাই কৰা তাক জনা। এটা ট্ৰেণ্ড হৈছে এক সাধাৰণ দিশ য'ত জনপ্ৰিয় বস্তুৰ ওপৰত ভিত্তি কৰি কিবা বিকশিত বা সলনি হৈছে নেকি তাক জনা। তুমি বাতৰি কাকত, প্ৰবন্ধ পঢ়ি, বজাৰৰ ট্ৰেণ্ড জানিব পাৰা আৰু মানুহৰ সৈতে কথা পাতি, পাঠ্যক্ৰম শেষ কৰাৰ পিছত পোৱা সুযোগবোৰ বুজিবলৈ আৰু এই ট্ৰেণ্ড কেৱল কম সময়ৰ বাবেহে আছে নেকি তাক জানিব পাৰা ।",
            "gu": "સૌથી વધુ નોકરીઓ સાથે કોર્સ શોધવા માટે રઝિયા કૉલેજ બ્રોશરનો અભ્યાસ કરે છે. કોઈ ચોક્કસ અભ્યાસક્રમ પસંદ કરતા પહેલા બજાર અને વલણો પર સંશોધન કરવાનો અર્થ એ છે કે કયો ઉદ્યોગ સારો દેખાવ કરી રહ્યો છે. વલણ એ એક સામાન્ય વલણ છે જેમાં લોકપ્રિય શું છે તેના આધારે કંઈક વિકસિત થાય છે અથવા બદલાય છે. તમે અખબારો અને લેખો વાંચીને અને કોર્સ પૂરો કર્યા પછી ઉપલબ્ધ તકોને સમજવા અને ટ્રેન્ડ થોડા સમય માટે જ છે કે કેમ તે સમજવા માટે તમે લોકો સાથે વાત કરીને બજારના વલણો વિશે જાણી શકો છો."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_2_1",
            "hi": "B1_2_1",
            "ka": "B1_2_1",
            "te": "B1_2_1",
            "or": "B1_2_1",
            "as": "B1_2_1",
            "gu": "B1_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia studies hard and performs extremely well at the vocational institute.",
            "hi": "रजिया बहुत पढ़ाई करती है और अपने वोकेशनल इंस्टीट्यूट में अच्छा प्रदर्शन करती है।",
            "ka": "ರಝಿಯಾ ಕಷ್ಟಪಟ್ಟು ಅಧ್ಯಯನ ಮಾಡುತ್ತಾರೆ ಮತ್ತು ವೃತ್ತಿಪರ ಸಂಸ್ಥೆಯಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಾಳೆ.",
            "te": "రజియా కష్టపడి చదువుతుంది మరియు వృత్తి విద్యా సంస్థలో అత్యుత్తమ పనితీరు కనబరుస్తుంది.",
            "or": "ରଜିଆ କଠିନ ଅଧ୍ୟୟନ କରନ୍ତି ଏବଂ ଧନ୍ଦାମୂଳକ ଅନୁଷ୍ଠାନରେ ଅତ୍ୟନ୍ତ ଭଲ ପ୍ରଦର୍ଶନ କରନ୍ତି ।",
            "as": "ৰাজিয়াই কঠোৰ অধ্যয়ন কৰে আৰু বৃত্তিমূলক প্ৰতিষ্ঠানত অত্যন্ত ভাল প্ৰদৰ্শন কৰে।",
            "gu": "રઝિયા સખત મહેનત કરે છે અભ્યાસમાં અને વ્યાવસાયિક સંસ્થામાં ખૂબ સારું પ્રદર્શન કરે છે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_2_2",
            "hi": "B1_2_2",
            "ka": "B1_2_2",
            "te": "B1_2_2",
            "or": "B1_2_2",
            "as": "B1_2_2",
            "gu": "B1_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Her teachers are happy with her dedication. The training focuses on practical knowledge.",
            "hi": "उसके शिक्षक उसकी लग्न को देखकर खुश हैं। ट्रेनिंग के व्यावहारिक ज्ञान पर ध्यान केंद्रित किया जाता है।",
            "ka": "ಅವಳ ಸಮರ್ಪಣೆಯಿಂದ ಅವಳ ಶಿಕ್ಷಕರು ಸಂತೋಷಪಟ್ಟಿದ್ದಾರೆ. ತರಬೇತಿಯು ಪ್ರಾಯೋಗಿಕ ಜ್ಞಾನವನ್ನು ಕೇಂದ್ರೀಕರಿಸುತ್ತದೆ.",
            "te": "ఆమె అంకితభావంతో ఆమె ఉపాధ్యాయులు సంతోషంగా ఉన్నారు. శిక్షణ ఆచరణాత్మక పరిజ్ఞానంపై దృష్టి పెడుతుంది",
            "or": "ତାଙ୍କ ଶିକ୍ଷକମାନେ ତାଙ୍କ ନିଷ୍ଠା ରେ ଖୁସି ଅଛନ୍ତି । ତାଲିମ ବ୍ୟବହାରିକ ଜ୍ଞାନ ଉପରେ ଧ୍ୟାନ ଦେଇଥାଏ ।",
            "as": "তাইৰ শিক্ষকসকল তাইৰ কামত সুখী। প্ৰশিক্ষণটোৱে ব্যৱহাৰিক জ্ঞানৰ ওপৰত গুৰুত্ব দিয়ে।",
            "gu": "તેના શિક્ષકો તેની  મહેનતથી ખુશ છે. તાલીમ વ્યવહારિક જ્ઞાન ઉપર ધ્યાન કેન્દ્રિત કરે છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_2_3",
            "hi": "B1_2_3",
            "ka": "B1_2_3",
            "te": "B1_2_3",
            "or": "B1_2_3",
            "as": "B1_2_3",
            "gu": "B1_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She has a small group of friends in her class, all from the nearby villages. They are keen on starting their careers soon.",
            "hi": "उसकी कक्षा में उसके कुछ दोस्त भी हैं, जो पास ही गाँव से हैं। वे भी जल्द ही अपना करियर शुरू करना चाहते हैं।",
            "ka": "ಅವಳು ತನ್ನ ತರಗತಿಯಲ್ಲಿ ಹತ್ತಿರದ ಹಳ್ಳಿಗಳಿಂದ ಬಂದ ಸ್ನೇಹಿತರ ಸಣ್ಣ ಗುಂಪನ್ನು ಹೊಂದಿದ್ದಾಳೆ. ಅವರು ಶೀಘ್ರದಲ್ಲೇ ತಮ್ಮ ವೃತ್ತಿಜೀವನವನ್ನು ಪ್ರಾರಂಭಿಸಲು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ.",
            "te": "ఆమె తరగతిలో చిన్న స్నేహితుల సమూహం ఉంది, అందరూ సమీపంలోని గ్రామాల నుండి వచ్చిన వారు . త్వరలో తమ కెరీర్‌ను ప్రారంభించాలనే ఆసక్తితో ఉన్నారు.",
            "or": "ତାଙ୍କ ଶ୍ରେଣୀରେ ବନ୍ଧୁମାନଙ୍କର ଏକ ଛୋଟ ଗୋଷ୍ଠୀ ଅଛି, ସମସ୍ତେ ନିକଟସ୍ଥ ଗ୍ରାମର । ସେମାନେ ଶୀଘ୍ର କ୍ୟାରିୟର ଆରମ୍ଭ କରିବାକୁ ଆଗ୍ରହୀ ।",
            "as": "তাইৰ শ্ৰেণীত তাইৰ বন্ধুৰ সৰু গোট এটা আছে,সকলোবোৰ ওচৰৰ গাওঁবোৰৰ পৰা অহা। তেওঁলোকে সোনকালেই তেওঁলোকৰ কেৰিয়াৰ আৰম্ভ কৰিবলৈ আগ্ৰহী।",
            "gu": "તેના વર્ગમાં એક નાનું મિત્રોનું જૂથ છે , જે  બધા નજીકના ગામડાના છે. તેઓ ટૂંક સમયમાં તેમની કારકિર્દી શરૂ કરવા માટે આતુર છે."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1_2_4",
            "hi": "B1_2_4",
            "ka": "B1_2_4",
            "te": "B1_2_4",
            "or": "B1_2_4",
            "as": "B1_2_4",
            "gu": "B1_2_4"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "They support each other and learn how to apply for different jobs and internships.",
            "hi": "वे एकदूसरे की मदद करते हैं और अलग-अलग जॉब या इन्टर्नशिप के लिए कैसे अप्लाई करना सीखते हैं।",
            "ka": "ಅವರು ಪರಸ್ಪರ ಬೆಂಬಲಿಸುತ್ತಾರೆ ಮತ್ತು ವಿವಿಧ ಉದ್ಯೋಗಗಳು ಮತ್ತು ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳಿಗೆ ಹೇಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಬೇಕೆಂದು ಕಲಿಯುತ್ತಾರೆ.",
            "te": "వారు ఒకరికొకరు మద్దతు ఇస్తారు మరియు వివిధ ఉద్యోగాలు మరియు ఇంటర్న్‌షిప్‌ల కోసం ఎలా దరఖాస్తు చేసుకోవాలో నేర్చుకుంటారు.",
            "or": "ସେମାନେ ପରସ୍ପରକୁ ସମର୍ଥନ କରନ୍ତି ଏବଂ ବିଭିନ୍ନ ଚାକିରି ଏବଂ ଇଣ୍ଟର୍ନସିପ୍ ପାଇଁ କିପରି ଆବେଦନ କରିବେ ଶିଖନ୍ତି ।",
            "as": "তেওঁলোকে ইজনে সিজনক সমৰ্থন কৰে আৰু বিভিন্ন চাকৰি আৰু ইন্টাৰ্ণশ্বিপৰ বাবে কেনেদৰে এপ্লাই কৰিব লাগে শিকে।",
            "gu": "તેઓ એકબીજાને મદદ કરે છે અને એવું શીખે છે કે વિવિધ નોકરીઓ અને ઇન્ટર્નશીપ માટે કેવી રીતે અરજી કરવી."
          }
        }
      ]
    },
    "scene37": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1(i)_1_1",
            "hi": "B1(i)_1_1",
            "ka": "B1(i)_1_1",
            "te": "B1(i)_1_1",
            "or": "B1(i)_1_1",
            "as": "B1(i)_1_1",
            "gu": "B1(i)_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia has completed her first year of vocational training. She wishes to get some practical experience during the summer break.",
            "hi": "रजिया ने अपने वोकेशनल ट्रेनिंग का पहला वर्ष पूरा कर लिया है। वह गर्मी की छुट्टियों में थोड़ा अनुभव प्राप्त करना चाहती हैं।",
            "ka": "ರಝಿಯಾ ತನ್ನ ಮೊದಲ ವರ್ಷದ ವೃತ್ತಿಪರ ತರಬೇತಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದಾಳೆ. ಬೇಸಿಗೆಯ ವಿರಾಮದ ಸಮಯದಲ್ಲಿ ಕೆಲವು ಪ್ರಾಯೋಗಿಕ ಅನುಭವವನ್ನು ಪಡೆಯಲು ಅವಳು ಬಯಸುತ್ತಾಳೆ.",
            "te": "రజియా వృత్తి విద్యా శిక్షణ మొదటి సంవత్సరం పూర్తి చేసింది. వేసవి విరామంలో కొంత ఆచరణాత్మక అనుభవాన్ని పొందాలని ఆమె కోరుకుంటుంది.",
            "or": "ରଜିଆ ଧନ୍ଦାମୂଳକ ତାଲିମର ପ୍ରଥମ ବର୍ଷ ସମାପ୍ତ କରିଛନ୍ତି । ଗ୍ରୀଷ୍ମ ଅବକାଶ ସମୟରେ ସେ କିଛି ବ୍ୟବହାରିକ ଅଭିଜ୍ଞତା ପାଇବାକୁ ଚାହୁଁଛନ୍ତି ।",
            "as": "ৰাজিয়াই তাইৰ বৃত্তিমূলক প্ৰশিক্ষণৰ প্ৰথম বছৰ সম্পূৰ্ণ কৰিছে। তাই গৰম বন্ধৰ সময়ত কিছু ব্যৱহাৰিক অভিজ্ঞতা লাভ কৰিব বিচাৰে।",
            "gu": "રઝિયાએ તેનું પ્રથમ વર્ષ પૂર્ણ કરે છે જે વ્યાવસાયિક તાલીમનું છે. તે ઉનાળાનામાં થોડો વ્યવહારુ અનુભવ મેળવવા છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1(i)_1_2",
            "hi": "B1(i)_1_2",
            "ka": "B1(i)_1_2",
            "te": "B1(i)_1_2",
            "or": "B1(i)_1_2",
            "as": "B1(i)_1_2",
            "gu": "B1(i)_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "On the college notice board, she sees an internship offer. It's in a eco-resort near home. They have provided an email address where interested students can apply.",
            "hi": "कॉलेज के नोटिस बोर्ड पर वह इन्टर्नशिप के लिए ऑफर देखती है। यह उसके घर के पास के इको-रिसोर्ट के लिए है। उन्होंने ईमेल एड्रेस दिया हैं ताकि इच्छुक स्टूडेंट्स अप्लाई कर सकें।",
            "ka": "ಕಾಲೇಜಿನ ನೋಟಿಸ್ ಬೋರ್ಡ್‌ನಲ್ಲಿ, ಅವಳು ಇಂಟರ್ನ್‌ಶಿಪ್ ಪ್ರಸ್ತಾಪವನ್ನು ನೋಡುತ್ತಾಳೆ. ಇದು ಮನೆಯ ಸಮೀಪವಿರುವ ಪರಿಸರ-ರೆಸಾರ್ಟ್‌ನಲ್ಲಿದೆ. ಆಸಕ್ತ ವಿದ್ಯಾರ್ಥಿಗಳು ಅರ್ಜಿ ಸಲ್ಲಿಸಬಹುದಾದ ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ಅವರು ಒದಗಿಸಿದ್ದಾರೆ.",
            "te": "కాలేజీ నోటీసు బోర్డులో, ఆమెకు ఇంటర్న్‌షిప్ ఆఫర్ కనిపించింది. ఇది ఇంటికి సమీపంలోని పర్యావరణ రిసార్ట్‌లో ఉంది. ఆసక్తిగల విద్యార్థులు దరఖాస్తు చేసుకోగల ఇమెయిల్ చిరునామాను వారు అందించారు.",
            "or": "କଲେଜ ବିଜ୍ଞପ୍ତି ବୋର୍ଡରେ, ସେ ଏକ ଇଣ୍ଟର୍ନସିପ୍ ଅଫର୍ ଦେଖନ୍ତି । ଏହା ଘର ନିକଟରେ ଥିବା ଏକ ଇକୋ-ରିସର୍ଟରେ ଅଛି । ସେମାନେ ଏକ ଇମେଲ୍ ଠିକଣା ପ୍ରଦାନ କରିଛନ୍ତି ଯେଉଁଠାରେ ଆଗ୍ରହୀ ଛାତ୍ରମାନେ ଆବେଦନ କରିପାରିବେ ।",
            "as": "কলেজৰ জাননী বোৰ্ডত, তাই এটা ইন্টাৰ্ণশ্বিপ প্ৰস্তাৱ দেখিছে। এইটো ঘৰৰ ওচৰৰ এটা ইক'-ৰিজৰ্টত আছে। তেওঁলোকে এটা ইমেইল ঠিকনা প্ৰদান কৰিছে য'ত আগ্ৰহী শিক্ষাৰ্থীসকলে এপ্লাই কৰিব পাৰে।",
            "gu": "કોલેજના નોટિસ બોર્ડ ઉપર, તેને ઇન્ટર્નશિપની માંગ દેખાય છે. તે ઘરની નજીકના ઇકો-રિસોર્ટમાં છે. તેમાં એક ઇમેઇલ સરનામું આપેલું છે જેમાં જે વિદ્યાર્થીઓ રસ ધરાવતા હોય તે અરજી કરી શકે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1(i)_2_1",
            "hi": "B1(i)_2_1",
            "ka": "B1(i)_2_1",
            "te": "B1(i)_2_1",
            "or": "B1(i)_2_1",
            "as": "B1(i)_2_1",
            "gu": "B1(i)_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia decides to apply for the internship",
            "hi": "रजिया इन्टर्नशिप के लिए अप्लाई करने का निर्णय लेती है",
            "ka": "ರಝಿಯಾ ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ",
            "te": "రజియా ఇంటర్న్‌షిప్ కోసం దరఖాస్తు చేయాలనుకుంది",
            "or": "ରଜିଆ ଇଣ୍ଟର୍ନସିପ୍ ପାଇଁ ଆବେଦନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି",
            "as": "ৰাজিয়াই ইণ্টাৰ্নশ্বিপৰ বাবে এপ্লাই কৰাৰ সিদ্ধান্ত লয়",
            "gu": "રઝિયા ઇન્ટર્નશિપમાં અરજી કરવાનું નક્કી કરે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1(i)_2_2",
            "hi": "B1(i)_2_2",
            "ka": "B1(i)_2_2",
            "te": "B1(i)_2_2",
            "or": "B1(i)_2_2",
            "as": "B1(i)_2_2",
            "gu": "B1(i)_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.sendMailTo",
          "value": {
            "en": "internwithus@sundarretreat.in",
            "hi": "internwithus@sundarretreat.in",
            "ka": "internwithus@sundarretreat.in",
            "te": "internwithus@sundarretreat.in",
            "or": "internwithus@sundarretreat.in",
            "as": "internwithus@sundarretreat.in",
            "gu": "internwithus@sundarretreat.in"
          }
        },
        {
          "valuePath": "blocks.0.props.sendMailSubject",
          "value": {
            "en": "Application for summer internship",
            "hi": "गर्मी की छुट्टियों में इन्टर्नशिप के लिए एप्लीकेशन",
            "ka": "ಬೇಸಿಗೆ ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಾಗಿ ಅರ್ಜಿ",
            "te": "వేసవి ఇంటర్న్‌షిప్ కోసం దరఖాస్తు",
            "or": "ଗ୍ରୀଷ୍ମ ଇଣ୍ଟର୍ନସିପ୍ ପାଇଁ ଆବେଦନ",
            "as": "গ্ৰীষ্মকালীন ইন্টাৰ্ণশ্বিপৰ বাবে আবেদন",
            "gu": "ઉનાળાની ઇન્ટર્નશિપમાં અરજી કરવી"
          }
        },
        {
          "valuePath": "blocks.0.props.sendMailMsg",
          "value": {
            "en": "Dear Ma’am/Sir,\nI’m a first year student of front office and administration. \nI have been trained in attending guests and overseeing front office operations. \nAt school, I was a part of the eco warrior club where I learnt a lot about the Sunderban deltas. \nI believe I would make a good intern for your team.\nLooking forward to hearing back from you.\n\nRegards,\nRazia",
            "hi": "आदरणीय मैम/सर, \n मैं फ्रंट ऑफिस और एडमिनिस्ट्रेशन की प्रथम वर्ष में पढ़नेवाली छात्रा हूँ। \n मैंने मेहमानों की मदद करने और फ्रंट ऑफिस के काम करने के लिए प्रशिक्षण लिया है। \n स्कूल में, मैं इको वॉरियर क्लब का हिस्सा थी जहाँ पर मैंने सुंदरबन डेल्टा के बारे में बहुत जानकारी हासिल की है। \n मुझे विश्वास है कि मैं आपकी टीम में एक अच्छी इन्टर्न साबित हो सकती हूँ। \n मैं आप के जवाब का इंतजार करूंगी। \n \n शुभकामनाएं,\n रजिया",
            "ka": "ಆತ್ಮೀಯ ಮೇಡಂ/ಸರ್, ನಾನು ಫ್ರಂಟ್ ಆಫೀಸ್ ಮತ್ತು ಆಡಳಿತದ ಮೊದಲ ವರ್ಷದ ವಿದ್ಯಾರ್ಥಿಯಾಗಿದ್ದೇನೆ.\n ಅತಿಥಿಗಳಿಗೆ ಹಾಜರಾಗಲು ಮತ್ತು ಮುಂಭಾಗದ ಕಛೇರಿಯ ಕಾರ್ಯಾಚರಣೆಗಳ ಮೇಲ್ವಿಚಾರಣೆಯಲ್ಲಿ ನಾನು ತರಬೇತಿ ಪಡೆದಿದ್ದೇನೆ. ಶಾಲೆಯಲ್ಲಿ, ನಾನು ಪರಿಸರ ಯೋಧ ಕ್ಲಬ್‌ನ ಭಾಗವಾಗಿದ್ದೆ, ಅಲ್ಲಿ ನಾನು ಸುಂದರ್‌ಬನ್ ಡೆಲ್ಟಾಗಳ ಬಗ್ಗೆ ಸಾಕಷ್ಟು ಕಲಿತಿದ್ದೇನೆ.\n ನಾನು ನಿಮ್ಮ ತಂಡಕ್ಕೆ ಉತ್ತಮ ಇಂಟರ್ನ್ ಮಾಡುತ್ತೇನೆ ಎಂದು ನಾನು ನಂಬುತ್ತೇನೆ.\n ನಿಮ್ಮಿಂದ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಕೇಳಲು ಎದುರು ನೋಡುತ್ತಿದ್ದೇನೆ.\n \n ವಂದನೆಗಳು,\n ರಝಿಯಾ\"",
            "te": "\"ప్రియమైన మేడమ్/సర్,\n నేను ఫ్రంట్ ఆఫీస్ మరియు అడ్మినిస్ట్రేషన్‌లో మొదటి సంవత్సరం విద్యార్థిని.\n నేను అతిథులకు హాజరు కావడం మరియు ఫ్రంట్ ఆఫీస్ కార్యకలాపాలను పర్యవేక్షించడంలో శిక్షణ పొందాను.\n పాఠశాలలో, నేను ఎకో వారియర్ క్లబ్‌లో భాగమయ్యాను, అక్కడ నేను సుందర్‌బన్ డెల్టాల గురించి చాలా నేర్చుకున్నాను.\n నేను మీ బృందానికి మంచి ఇంటర్న్‌ని అవుతానని నమ్ముతున్నాను.\n మీ నుండి తిరిగి వినడానికి ఎదురు చూస్తున్నాను.\n \n గౌరవంతో,\n రజియా\"",
            "or": "ପ୍ରିୟ ମାଡ଼ାମ୍ /ସାର୍, \n ମୁଁ ଫ୍ରଣ୍ଟ ଅଫିସ ଏବଂ ପ୍ରଶାସନର ପ୍ରଥମ ବର୍ଷର ଛାତ୍ରୀ । \n ମୋତେ ଅତିଥିମାନଙ୍କ ସହିତ ଯୋଗଦେବା ଏବଂ ଫ୍ରଣ୍ଟ ଅଫିସ କାର୍ଯ୍ୟର ତଦାରଖ କରିବାରେ ତାଲିମ ଦିଆଯାଇଛି । \n ବିଦ୍ୟାଳୟରେ, ମୁଁ ଇକୋ ୱାରିୟର କ୍ଲବର ଏକ ଅଂଶ ଥିଲି ଯେଉଁଠାରେ ମୁଁ ସୁନ୍ଦରବନ ତ୍ରିକୋଣଭୂମି ବିଷୟରେ ବହୁତ କିଛି ଶିଖିଥିଲି । \n ମୁଁ ବିଶ୍ୱାସ କରେ ମୁଁ ଆପଣଙ୍କ ଦଳ ପାଇଁ ଏକ ଭଲ ଇଣ୍ଟର୍ନ ହେବି । ଆପଣଙ୍କଠାରୁ ଉତ୍ତର ଶୁଣିବାକୁ ଅପେକ୍ଷା କରିଛି। \n \n ସମ୍ମାନର ସହିତ, \n ରଜିଆ",
            "as": "শ্ৰদ্ধেয় মেডাম/ছাৰ,\n মই অফিচৰ কাম-কাজ আৰু প্ৰশাসন পাঠ্যক্ৰমৰ প্ৰথম বৰ্ষৰ ছাত্ৰী। \n মোক অতিথিসকলক চোৱা-চিতা কৰা আৰু সন্মুখ অফিচৰ কাম-কাজ তদাৰক কৰাৰ প্ৰশিক্ষণ দিয়া হৈছে। \n স্কুলত, মই পৰিৱেশ যোদ্ধা ক্লাবৰ এটা অংশ আছিলোঁ য'ত মই সুন্দৰবান বদ্বীপৰ বিষয়ে বহুত শিকিছিলো। \n মই বিশ্বাস কৰোঁ যে মই আপোনাৰ দলৰ বাবে এজন ভাল ইণ্টাৰ্ন হ'ব পাৰিম।\n আপোনাৰ পৰা কিবা উত্তৰ পাবলৈ বাট চাই আছো।\n \n সম্মানেৰে,\n ৰাজিয়া",
            "gu": "પ્રિય મેડમ/સર,\nહું ફ્રન્ટ ઓફિસ અને એડમિનિસ્ટ્રેશનમાં પ્રથમ વર્ષની વિદ્યાર્થીની છું.\nહું મહેમાનોની હાજરી આપવા અને ફ્રન્ટ ઓફિસના કાર્યપ્રવાહની દેખરેખ રાખવાની તાલીમ મેળવેલી છે.\nશાળામાં, હું ઇકો વોરિયર ક્લબનો ભાગ હતી જ્યાં મેં સુંદરવન ડેલ્ટા વિશે ઘણું શીખ્યું.\nમને લાગે છે કે હું તમારી ટીમ માટે શ્રેષ્ઠ કોચ બનીશ.\nહું ફરીથી તમારી પાસેથી સાંભળવા માટે આતુર છું.\n\nસાદર,\nરઝિયા\""
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1(i)_3_1",
            "hi": "B1(i)_3_1",
            "ka": "B1(i)_3_1",
            "te": "B1(i)_3_1",
            "or": "B1(i)_3_1",
            "as": "B1(i)_3_1",
            "gu": "B1(i)_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "A few days later, Razia receives a response. She has been invited to take up the internship!",
            "hi": "कुछ दिनों बाद, रजिया को जवाब मिल जाता है. उसे इन्टर्नशिप के लिए आमंत्रित किया गया है!",
            "ka": "ಕೆಲವು ದಿನಗಳ ನಂತರ, ರಝಿಯಾ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಪಡೆಯುತ್ತಾಳೆ. ಇಂಟರ್ನ್‌ಶಿಪ್ ತೆಗೆದುಕೊಳ್ಳಲು ಅವಳನ್ನು ಆಹ್ವಾನಿಸಲಾಗಿದೆ!",
            "te": "కొన్ని రోజుల తర్వాత, రజియా ప్రతిస్పందనను అందుకుంటుంది. ఆమె ఇంటర్న్‌షిప్ తీసుకోవడానికి ఆహ్వానించబడింది!",
            "or": "କିଛି ଦିନ ପରେ, ରଜିଆ ଏକ ପ୍ରତିକ୍ରିୟା ପାଆନ୍ତି । ତାଙ୍କୁ ଇଣ୍ଟର୍ନସିପ୍ ନେବାକୁ ଆମନ୍ତ୍ରଣ କରାଯାଇଛି!",
            "as": "কেইদিনমানৰ পিছত, ৰাজিয়াই এটা সঁহাৰি লাভ কৰে। তাইক ইণ্টাৰ্নশ্বিপ গ্ৰহণ কৰিবলৈ আমন্ত্ৰণ জনোৱা হৈছে!",
            "gu": "થોડા દિવસો પછી, રઝિયાને જવાબ મળે છે. આમંત્રણ આપવામાં આવ્યું છે જેમાં તે ઇન્ટર્નશીપ લઈ સકે છે!"
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1(i)_3_2//",
            "hi": "B1(i)_3_2//",
            "ka": "B1(i)_3_2//",
            "te": "B1(i)_3_2//",
            "or": "B1(i)_3_2//",
            "as": "B1(i)_3_2//",
            "gu": "B1(i)_3_2//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "She immediately calls Imran",
            "hi": "वह तुरंत इमरान को फोन करती है",
            "ka": "ತಕ್ಷಣ ಇಮ್ರಾನ್ ಗೆ ಕರೆ ಮಾಡುತಾಳೆ",
            "te": "ఆమె వెంటనే ఇమ్రాన్‌కి ఫోన్ చేసింది",
            "or": "ସେ ତୁରନ୍ତ ଇମ୍ରାନଙ୍କୁ ଡାକନ୍ତି",
            "as": "তাই লগে লগে ইমৰানক ফোন কৰে",
            "gu": "તે તરત જ ઇમરાનને કોલ કરે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Imran",
            "hi": "इमरान",
            "ka": "ಇಮ್ರಾನ್",
            "te": "ఇమ్రాన్",
            "or": "ଇମ୍ରାନ",
            "as": "ইমৰান",
            "gu": "ઇમરાન"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Dada, I got the eco-resort internship!",
            "hi": "दादा, मुझे इको-रिसोर्ट में इन्टर्नशिप मिली है!",
            "ka": "ದಾದಾ, ನನಗೆ ಇಕೋ-ರೆಸಾರ್ಟ್ ಇಂಟರ್ನ್‌ಶಿಪ್ ಸಿಕ್ಕಿದೆ!",
            "te": "అన్నయ్య, నాకు పర్యావరణ రిసార్ట్ లో ఇంటర్న్‌షిప్ వచ్చింది!",
            "or": "ଭାଇ, ମୁଁ ଇକୋ-ରିସର୍ଟ ଇଣ୍ଟର୍ନସିପ୍ ପାଇଲି!",
            "as": "দাদা, মই ইক'-ৰিজৰ্ট ইন্টাৰ্ণশ্বিপ পাইছো!",
            "gu": "દાદા, મને ઇકો-રિસોર્ટ ઇન્ટર્નશિપ મળી છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "Internship?",
            "hi": "इन्टर्नशिप?",
            "ka": "ಇಂಟರ್ನ್ಶಿಪ್?",
            "te": "ఇంటర్న్‌షిప్?",
            "or": "ଇଣ୍ଟର୍ନସିପ୍?",
            "as": "ইন্টাৰ্ণশ্বিপ?",
            "gu": "ઇન્ટર્નશિપ?"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "Yes dada, you remember the resort near our home?  They take interns from our college to help them with the tourists that come during summer.",
            "hi": "हाँ दादा, क्या आपको हमारे घर के नजदीक का रिसोर्ट याद है ? वे गर्मी के मौसम में टूरिस्टों के मामले में मदद करने के लिए हमारे कॉलेज से इन्टर्न लेते हैं।",
            "ka": "ಹೌದು ದಾದಾ, ನಿಮಗೆ ನಮ್ಮ ಮನೆಯ ಸಮೀಪವಿರುವ ರೆಸಾರ್ಟ್ ನೆನಪಿದೆಯೇ? ಬೇಸಿಗೆಯಲ್ಲಿ ಬರುವ ಪ್ರವಾಸಿಗರಿಗೆ ಸಹಾಯ ಮಾಡಲು ಅವರು ನಮ್ಮ ಕಾಲೇಜಿನಿಂದ ಇಂಟರ್ನ್‌ಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾರೆ.",
            "te": "అవును అన్నయ్య, మన ఇంటికి దగ్గర్లో ఉన్న రిసార్ట్ గుర్తుందా? వేసవి లో వచ్చే టూరిస్ట్ లకు సహాయం చేయడానికి మా కాలేజీ నుండి ఇంటర్న్‌లను తీసుకుంటారు.",
            "or": "ହଁ ଭାଇ, ତୁମେ ଆମ ଘର ନିକଟରେ ଥିବା ରିସର୍ଟକୁ ମନେ ରଖିଛ କି? ଗ୍ରୀଷ୍ମ ସମୟରେ ଆସୁଥିବା ପର୍ଯ୍ୟଟକମାନଙ୍କ ସହିତ ସେମାନଙ୍କୁ ସାହାଯ୍ୟ କରିବା ପାଇଁ ସେମାନେ ଆମ କଲେଜରୁ ଇଣ୍ଟର୍ନମାନଙ୍କୁ ନେଇଯାଆନ୍ତି ।",
            "as": "হয় দাদা, আপোনাৰ আমাৰ ঘৰৰ ওচৰৰ ৰিজৰ্টটো মনত আছেনে? তেওঁলোকে গৰম দিনত অহা পৰ্যটকসকলৰ সৈতে তেওঁলোকক সহায় কৰিবলৈ আমাৰ কলেজৰ পৰা ইণ্টাৰ্ন লয়।",
            "gu": "હા દાદા, તમને યાદ છે આપણાં ઘરની પાસે એક રિસોર્ટ છે? તેઓ ઉનાળા દરમિયાન આવતા પ્રવાસીઓ સાથે મદદ કરવા અમારી કોલેજમાંથી ઈન્ટર્ન લે છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1(i)_3_3////",
            "hi": "B1(i)_3_3////",
            "ka": "B1(i)_3_3////",
            "te": "B1(i)_3_3////",
            "or": "B1(i)_3_3////",
            "as": "B1(i)_3_3////",
            "gu": "B1(i)_3_3////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Oh that’s such a good opportunity Razia! You’ll get to see their business in peak season!",
            "hi": "ओह, यह तो सच में अच्छा अवसर है रजिया! तुम्हे पीक सीजन में उनके बिजनेस को देखने का मौका मिलेगा!",
            "ka": "ಓ ಅದೊಂದು ಒಳ್ಳೆಯ ಅವಕಾಶ ರಝಿಯಾ! ಪೀಕ್ ಸೀಸನ್‌ನಲ್ಲಿ ನೀನು ಅವರ ವ್ಯಾಪಾರವನ್ನು ನೋಡುತ್ತೀಯಾ!",
            "te": "అవునా, అది మంచి అవకాశం రజియా! మీరు పీక్ సీజన్‌లో వారి బిజినెస్ చూడవచ్చు!",
            "or": "ଓଃ ଏହା ଏକ ଭଲ ସୁଯୋଗ ରଜିଆ! ତୁମେ ପିକ୍ ସୀଜନରେ ସେମାନଙ୍କର ବ୍ୟବସାୟ ଦେଖିବାକୁ ପାଇବ!",
            "as": "অহ্ এইটো ইমান ভাল সুযোগ ৰাজিয়া! তুমি ব্যৱসায়ৰ অতি ভাল সময়খিনিত তেওঁলোকৰ ব্যৱসায় চাবলৈ পাবা!",
            "gu": "ઓહ આ તો કેટલો સરસ તક છે રઝિયા! તમને સારી સીઝનમાં તેમનો વ્યવસાય જોવા મળશે!"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Yes, and you know what, I will also get stipend (a small pay given to interns) for the job!",
            "hi": "हाँ, और आपको पता है, मुझे इस जॉब के लिए स्टाइपेंड (इन्टर्न को दिया जानेवाला थोड़ा सा वेतन) भी मिलेगा!",
            "ka": "ಹೌದು, ಮತ್ತು ನಿಮಗೆ ಗೊತ್ತಾ, ನನ್ನ ಕೆಲಸಕ್ಕಾಗಿ ಸ್ಟೈಫಂಡ್ (ಇಂಟರ್ನ್‌ಗಳಿಗೆ ನೀಡಲಾಗುವ ಸಣ್ಣ ವೇತನ) ಸಹ ಪಡೆಯುತ್ತೇನೆ!",
            "te": "అవును, మరియు నీకు తెలుసా, నాకు ఉద్యోగం నుండి స్టైపెండ్ (ఇంటర్న్‌లకు ఇచ్చే చిన్న వేతనం) కూడా వస్తుంది!",
            "or": "ହଁ, ଏବଂ ତୁମେ ଜାଣିଛ କି, ମୁଁ ଚାକିରି ପାଇଁ ଷ୍ଟାଇପେଣ୍ଡ (ଇଣ୍ଟର୍ନମାନଙ୍କୁ ଦିଆଯାଇଥିବା ଏକ ଛୋଟ ଦରମା) ମଧ୍ୟ ପାଇବି!",
            "as": "হয়, আৰু আপুনি জানেনে, মই চাকৰিটোৰ বাবে ষ্টাইপেণ্ড (ইণ্টাৰ্নসকলক দিয়া সামান্য দৰমহা)ও পাম!",
            "gu": "હા, અને તમે જાણો છો, મને નોકરી માટે વૃત્તિકા ​(ઇન્ટર્ન્સને આપવામાં આવતો નાનો પગાર) પણ મળશે"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "That’s really good, but Razia, did you tell Ammi? She called me yesterday saying they are going to look for wedding proposals for you during that time.",
            "hi": "यह तो बहुत अच्छी बात है, लेकिन रजिया, तुमने यह बात अम्मी को बताई है क्या? उन्होंने कल मुझे कॉल किया था और कहा वे अब तुम्हारी शादी के लिए लड़का ढूंढने वाले हैं।",
            "ka": "ಅದು ನಿಜವಾಗಿಯೂ ಚೆನ್ನಾಗಿದೆ, ಆದರೆ ರಝಿಯಾ, ಅಮ್ಮಿಗೆ ಹೇಳಿದ್ದೀಯಾ? ಆ ಸಮಯದಲ್ಲಿ ಅವರು ನಿನಗಾಗಿ ಮದುವೆಯ ಪ್ರಸ್ತಾಪಗಳನ್ನು ನೋಡಲಿದ್ದಾರೆ ಎಂದು ಅವರು ನಿನ್ನೆ ನನಗೆ ಕರೆ ಮಾಡಿದರು.",
            "te": "అది చాలా బాగుంది, కానీ రజియా, అమ్మకి చెప్పావా?\n  ఆ సమయంలో మీ కోసం పెళ్లి ప్రతిపాదనలు వారు వెతకబోతున్నారని ఆమె నిన్న నాకు ఫోన్ చేసింది",
            "or": "ଏହା ପ୍ରକୃତରେ ଭଲ, କିନ୍ତୁ ରଜିଆ, ତୁମେ ଆମ୍ମିଙ୍କୁ କହିଥିଲ କି? ସେ ଗତକାଲି ମୋତେ ଡାକି କହିଲେ ଯେ ସେମାନେ ସେହି ସମୟରେ ତୁମ ପାଇଁ ବିବାହ ପ୍ରସ୍ତାବ ଖୋଜିବାକୁ ଯାଉଛନ୍ତି।",
            "as": "এইটো সঁচাকৈয়ে ভাল, কিন্তু ৰাজিয়া, তুমি আম্মীক কৈছানে? তেঁও মোক কালি ফোন কৰি কৈছিল যে তেওঁলোকে সেই সময়ছোৱাত তোমাৰ বিয়াৰ প্ৰস্তাৱ বোৰৰ বিষয়ে ভাবিব।",
            "gu": "ખરેખર તે સારું છે, પણ રઝિયા, તેં અમ્મીને જણાવ્યુ? તેને ગઈકાલે મને ફોન કર્યો કે તેઓ તે સમય દરમિયાન તમારા માટે લગ્નનો પ્રસ્તાવો શોધી રહ્યા છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Yes, she’s been telling me this for some time now. I’m not sure what to do!",
            "hi": "हाँ, वह बहुत दिनों से मुझे यह कह रही हैं। लेकिन मुझे पता नहीं कि मुझे क्या करना चाहिए!",
            "ka": "ಹೌದು, ಅವಳು ಕೆಲವು ಸಮಯದಿಂದ ಇದನ್ನು ನನಗೆ ಹೇಳುತ್ತಿದ್ದಳು. ಏನು ಮಾಡಬೇಕೆಂದು ನನಗೆ ಖಚಿತವಿಲ್ಲ!",
            "te": "అవును, ఆమె చాలా రోజులుగా ఈ విషయం నాకు చెబుతోంది. నాకు ఏమి చేయాలో తెలియట్లేదు!",
            "or": "ହଁ, ସେ ମୋତେ କିଛି ସମୟ ଧରି ଏହା କହୁଛନ୍ତି । ମୁଁ ନିଶ୍ଚିତ ନୁହେଁ କ'ଣ କରିବି!",
            "as": "হয়, তেঁও মোক কেইদিনমানৰ পৰা এইটো কৈ আছে। মই ভাবিয়ে পোৱা নাই কি কৰিব লাগে!",
            "gu": "હા, તે છેલ્લા કેટલાક સમયથી મને આ વાત કહી રહી છે. મને ખાતરી નથી કે શું કરવું!"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.4.text",
          "value": {
            "en": "Think carefully before you decide. You know how all this affects Ammi's health. We don’t want to add to the problem.",
            "hi": "कोई भी निर्णय लेने से पहले अच्छी तरह से सोचना। तुम्हें पता है ना कि इस सब का अम्मी के तबीयत पर कैसे असर हो सकता है। हमें और मुसीबत खड़ी नहीं करनी।",
            "ka": "ನೀವು ನಿರ್ಧರಿಸುವ ಮೊದಲು ಎಚ್ಚರಿಕೆಯಿಂದ ಯೋಚಿಸಿ. ಇದೆಲ್ಲಾ ಅಮ್ಮಿಯ ಆರೋಗ್ಯದ ಮೇಲೆ ಹೇಗೆ ಪರಿಣಾಮ ಬೀರುತ್ತೆ ಗೊತ್ತಾ. ನಾವು ಸಮಸ್ಯೆಯನ್ನು ಸೇರಿಸಲು ಬಯಸುವುದಿಲ್ಲ.",
            "te": "మీరు నిర్ణయించుకునే ముందు జాగ్రత్తగా ఆలోచించండి. ఇదంతా అమ్మి ఆరోగ్యంపై ఎలాంటి ప్రభావం చూపుతుందో తెలుసా. మేము సమస్యను జోడించదలచుకోలేదు",
            "or": "ତୁମେ ନିଷ୍ପତ୍ତି ନେବା ପୂର୍ବରୁ ଯତ୍ନର ସହ ଚିନ୍ତା କର । ତୁମେ ଜାଣିଛ ଏସବୁ ଅମ୍ମିଙ୍କ ସ୍ୱାସ୍ଥ୍ୟ ଉପରେ କିପରି ପ୍ରଭାବ ପକାଇଥାଏ । ଆମେ ସମସ୍ୟାକୁ ବଢାଇବାକୁ ଚାହୁଁ ନାହୁଁ ।",
            "as": "কিবা সিদ্ধান্ত লোৱাৰ আগতে সাৱধানে চিন্তা কৰিবা। তুমি জানা যে এই সকলোবোৰে আম্মীৰ স্বাস্থ্যত কেনেদৰে প্ৰভাৱ পেলায়। আমি আৰু সমস্যা বঢ়াব নিবিচাৰোঁ।",
            "gu": "તમે નિર્ણય લેતા પહેલાં કાળજીપૂર્વક વિચારો. તમે જાણો છો કે આ બધું અમ્મીના સ્વાસ્થ્ય પર કેવી અસર કરે છે. આપણે સમસ્યામાં વધારો નથી કરવા માંગતા."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "B1(i)_3_4(D)",
            "hi": "B1(i)_3_4(D)",
            "ka": "B1(i)_3_4(D)",
            "te": "B1(i)_3_4(D)",
            "or": "B1(i)_3_4(D)",
            "as": "B1(i)_3_4(D)",
            "gu": "B1(i)_3_4(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Razia do?",
            "hi": "रजिया को क्या करना चाहिए?",
            "ka": "ರಝಿಯಾ ಏನು ಮಾಡಬೇಕು?",
            "te": "రజియా ఏం చేయాలి?",
            "or": "ରଜିଆ କ'ଣ କରିବା ଉଚିତ୍?",
            "as": "ৰাজিয়াই কি কৰা উচিত?",
            "gu": "રઝિયાને શું કરવું જોઇયે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Go against Ammi's wishes and get the internship.",
            "hi": "अम्मी की इच्छा के खिलाफ जाकर इन्टर्नशिप लेनी चाहिए।",
            "ka": "ಅಮ್ಮಿಯ ಇಚ್ಛೆಗೆ ವಿರುದ್ಧವಾಗಿ ಹೋಗಿ ಇಂಟರ್ನ್‌ಶಿಪ್ ಪಡೆಯಬೇಕು.",
            "te": "అమ్మ ఇష్టానికి వ్యతిరేకంగా వెళ్లి ఇంటర్న్‌షిప్ చేయాలా.",
            "or": "ଆମ୍ମିଙ୍କ ଇଚ୍ଛା ବିରୁଦ୍ଧରେ ଯାଆନ୍ତୁ ଏବଂ ଇଣ୍ଟର୍ନସିପ୍ ପାଆନ୍ତୁ ।",
            "as": "আম্মীৰ ইচ্ছাৰ বিপৰীতে যাওক আৰু ইণ্টাৰ্নশ্বিপ লাভ কৰক।",
            "gu": "અમ્મીની ઈચ્છાની વિરુદ્ધ જઈને ઈન્ટર્નશિપ મેળવો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Listen to Ammi. Internships can come again.",
            "hi": "अम्मी की बात माननी चाहिए। इन्टर्नशिप बाद में भी मिल सकती है।",
            "ka": "ಅಮ್ಮಿ ಮಾತು ಕೇಳು. ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳು ಮತ್ತೆ ಬರಬಹುದು.",
            "te": "అమ్మ మాట వినాలి. ఇంటర్న్‌షిప్‌లు మళ్లీ రావచ్చు.",
            "or": "ଆମ୍ମି ଶୁଣନ୍ତୁ । ଇଣ୍ଟର୍ନସିପ୍ ପୁନର୍ବାର ଆସିପାରେ ।",
            "as": "আম্মীৰ কথা শুনিব। ইণ্টাৰ্নশ্বিপ আকৌ আহিব পাৰে।",
            "gu": "અમ્મીની વાત સાંભળ. ઇન્ટર્નશિપ ફરીથી આવી શકે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.tag",
          "value": {
            "en": "career growth",
            "hi": "करियर वृद्धि",
            "ka": "ವೃತ್ತಿ ಬೆಳವಣಿಗೆ",
            "te": "కెరీర్ వృద్ధి",
            "or": "କ୍ୟାରିୟର ଅଭିବୃଦ୍ଧି",
            "as": "কেৰিয়াৰৰ বিকাশ",
            "gu": "કારકિર્દી વૃદ્ધિ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.tag",
          "value": {
            "en": "selflessness",
            "hi": "निस्वार्थता",
            "ka": "ನಿಸ್ವಾರ್ಥತೆ",
            "te": "నిస్వార్థం",
            "or": "ନିଃସ୍ୱାର୍ଥପରତା",
            "as": "নিঃস্বাৰ্থতা",
            "gu": "નિઃસ્વાર્થતા"
          }
        }
      ]
    },
    "scene30": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_1_1_Give up the internship",
            "hi": "इन्टर्नशिप छोड़ देना",
            "ka": "B2_1_1_ಇಂಟರ್ನ್‌ಶಿಪ್ ಅನ್ನು ಬಿಟ್ಟುಬಿಡಿ",
            "te": "B2_1_1_ఇంటర్న్‌షిప్‌ను వదులుకోవడం",
            "or": "B2_1_1_ଇଣ୍ଟର୍ନସିପ୍ ଛାଡି ଦିଅନ୍ତୁ",
            "as": "B2_1_1_ইন্টাৰ্ণশ্বিপ এৰি দিয়ক",
            "gu": "B2_1_1_ઇન્ટર્નશિપ રહેવાદો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Soon after her completion of college, Razia gets married. Her parents choose a family who own a small safari tour business in Sundarbans.",
            "hi": "कॉलेज पूरा करने के बाद जल्दी ही रजिया की शादी हो गई। उसके माता-पिता ने उसके लिए ऐसा परिवार चुना था जो सुंदरबन में छोटा सा सफारी टूर का व्यवसाय करता था।",
            "ka": "ಕಾಲೇಜು ಮುಗಿದ ಕೂಡಲೇ ರಝಿಯಾ ಮದುವೆಯಾಗುತ್ತಾಳೆ. ಆಕೆಯ ಪೋಷಕರು ಸುಂದರಬನ್ಸ್‌ನಲ್ಲಿ ಸಣ್ಣ ಸಫಾರಿ ಪ್ರವಾಸ ವ್ಯಾಪಾರವನ್ನು ಹೊಂದಿರುವ ಕುಟುಂಬವನ್ನು ಆಯ್ಕೆ ಮಾಡುತ್ತಾರೆ.",
            "te": "కాలేజ్ పూర్తయిన వెంటనే, రజియా పెళ్లి చేసుకుంటుంది. ఆమె తల్లిదండ్రులు సుందర్‌బన్స్‌లో చిన్న సఫారీ పర్యటన వ్యాపారాన్ని కలిగి ఉన్న కుటుంబాన్ని ఎంచుకున్నారు.",
            "or": "କଲେଜ ସମାପ୍ତ ହେବାର କିଛି ସମୟ ପରେ, ରଜିଆ ବିବାହ କରନ୍ତି । ତାଙ୍କ ପିତାମାତା ଏକ ପରିବାର ବାଛିଛନ୍ତି ଯିଏ ସୁନ୍ଦରବନରେ ଏକ ଛୋଟ ସଫାରୀ ଭ୍ରମଣ ବ୍ୟବସାୟର ମାଲିକ ।",
            "as": "কলেজ সম্পূৰ্ণ হোৱাৰ লগে লগে ৰাজিয়াই বিবাহ পাশত আৱদ্ধ হয়। তাইৰ মাক দেউতাকে সুন্দৰবনত এটা সৰু চাফাৰী ভ্ৰমণৰ ব্যৱসায় কৰা পৰিয়াল এটা বাছনি কৰে।",
            "gu": "રઝિયાના લગ્ન થઈ જાય છે કોલેજ પૂરી થયા પછી તરત જ. તેના માતા-પિતા એક નાનો સફારી ટુર બિઝનેસ ધરાવતા પરિવારને પસંદ કરે છે જે સુંદરવનમાં ."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_1_2",
            "hi": "B2_1_2",
            "ka": "B2_1_2",
            "te": "B2_1_2",
            "or": "B2_1_2",
            "as": "B2_1_2",
            "gu": "B2_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She settles in well with her new family and handles her responsibilities as a homemaker. Over time she begins helping with the tourism business as well.",
            "hi": "वह अपने नए परिवार में घुल-मिल गई और गृहिणी के तौर पर घर की जिम्मेदारियां संभालने लगी। कुछ समय बाद वह अपने परिवार के टूरिजम व्यवसाय में भी मदद करने लगी।",
            "ka": "ಅವಳು ತನ್ನ ಹೊಸ ಕುಟುಂಬದೊಂದಿಗೆ ಚೆನ್ನಾಗಿ ನೆಲೆಸುತ್ತಾಳೆ ಮತ್ತು ಗೃಹಿಣಿಯಾಗಿ ತನ್ನ ಜವಾಬ್ದಾರಿಗಳನ್ನು ನಿಭಾಯಿಸುತ್ತಾಳೆ. ಕಾಲಾನಂತರದಲ್ಲಿ ಅವರು ಪ್ರವಾಸೋದ್ಯಮ ವ್ಯವಹಾರದಲ್ಲಿ ಸಹಾಯ ಮಾಡಲು ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ.",
            "te": "ఆమె తన కొత్త కుటుంబంతో బాగా స్థిరపడింది మరియు గృహిణిగా తన బాధ్యతలను నిర్వహిస్తుంది. కాలక్రమేణా ఆమె పర్యాటక వ్యాపారంలో కూడా సహాయం చేయడం ప్రారంభిస్తుంది.",
            "or": "ସେ ତାଙ୍କ ନୂତନ ପରିବାର ସହିତ ଭଲ ଭାବରେ ବସବାସ କରନ୍ତି ଏବଂ ଜଣେ ଗୃହିଣୀ ଭାବରେ ତାଙ୍କର ଦାୟିତ୍ୱ ପରିଚାଳନା କରନ୍ତି । ସମୟ ସହିତ ସେ ପର୍ଯ୍ୟଟନ ବ୍ୟବସାୟରେ ମଧ୍ୟ ସାହାଯ୍ୟ କରିବା ଆରମ୍ଭ କରନ୍ତି ।",
            "as": "তাই তাইৰ নতুন পৰিয়ালৰ সৈতে ভালদৰে থিতাপি লয় আৰু গৃহিণী হিচাপে তাইৰ দায়িত্ববোৰ চম্ভালে। সময়ৰ লগে লগে তাই পৰ্যটন ব্যৱসায়তো সহায় কৰিবলৈ আৰম্ভ কৰে।",
            "gu": "તે પોતાના નવા પરિવાર સાથે સારી રીતે સ્થાયી થાય છે અને ગૃહિણી તરીકેની તે બધી જવાબદારીઓ સંભાળે છે. જેમ સમય પ્રસાર થાય છે તેમ પ્રવાસન વ્યવસાયમાં પણ મદદ કરવાનું શરૂ કરે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_2_1",
            "hi": "B2_2_1",
            "ka": "B2_2_1",
            "te": "B2_2_1",
            "or": "B2_2_1",
            "as": "B2_2_1",
            "gu": "B2_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She suggests her husband to start an eco-tour section. Her idea is liked by everyone in the family and they decide to try it out.",
            "hi": "उसने अपने पति को इको-टूर सेक्शन शुरू करने का सुझाव दिया। घर में सबको उसकी कल्पना अच्छी लगी और उन्होंने ऐसा करने का तय किया।",
            "ka": "ಅವಳು ತನ್ನ ಪತಿಗೆ ಪರಿಸರ ಪ್ರವಾಸ ವಿಭಾಗವನ್ನು ಪ್ರಾರಂಭಿಸಲು ಸೂಚಿಸುತ್ತಾಳೆ. ಆಕೆಯ ಕಲ್ಪನೆಯನ್ನು ಕುಟುಂಬದ ಪ್ರತಿಯೊಬ್ಬರೂ ಇಷ್ಟಪಡುತ್ತಾರೆ ಮತ್ತು ಅವರು ಅದನ್ನು ಪ್ರಯತ್ನಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾರೆ.",
            "te": "ఆమె తన భర్తకు పర్యావరణ పర్యటన విభాగాన్ని ప్రారంభించమని సూచించింది. ఆమె ఆలోచన కుటుంబంలోని ప్రతి ఒక్కరికీ నచ్చింది మరియు వారు దానిని ప్రయత్నించాలని నిర్ణయించుకున్నారు.",
            "or": "ସେ ତାଙ୍କ ସ୍ୱାମୀଙ୍କୁ ଏକ ଇକୋ-ଟୁର୍ ବିଭାଗ ଆରମ୍ଭ କରିବାକୁ ପରାମର୍ଶ ଦିଅନ୍ତି । ତାଙ୍କ ଧାରଣା ପରିବାରର ସମସ୍ତେ ପସନ୍ଦ କରନ୍ତି ଏବଂ ସେମାନେ ଏହାକୁ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି ।",
            "as": "তেওঁ তেওঁৰ স্বামীক এক পৰিৱেশ-ভ্ৰমণ শাখা আৰম্ভ কৰিবলৈ পৰামৰ্শ দিয়ে। তাইৰ ধাৰণাটো পৰিয়ালৰ সকলোৱে ভাল পায় আৰু তেওঁলোকে কৰিবলৈ চেষ্টা কৰাৰ সিদ্ধান্ত লয়।",
            "gu": "તે તેના પતિને ઇકો-ટૂર વિભાગ શરૂ કરવાનું સૂચન કરે છે. તેનો વિચાર કુટુંબના દરેકને ગમ્યો અને તેઓએ તેને અજમાવવાનું નક્કી કર્યું."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_2_2",
            "hi": "B2_2_2",
            "ka": "B2_2_2",
            "te": "B2_2_2",
            "or": "B2_2_2",
            "as": "B2_2_2",
            "gu": "B2_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The idea suggested by Razia becomes a success with the tourists and their business becomes popular in the area.",
            "hi": "रजिया ने दिया हुआ सुझाव टूरिस्टों को अच्छा लगा था और उनका व्यवसाय उस क्षेत्र में काफी लोकप्रिय हो गया।",
            "ka": "ರಝಿಯಾ ಸೂಚಿಸಿದ ಕಲ್ಪನೆಯು ಪ್ರವಾಸಿಗರಲ್ಲಿ ಯಶಸ್ವಿಯಾಗುತ್ತದೆ ಮತ್ತು ಅವರ ವ್ಯಾಪಾರವು ಕ್ಷೇತ್ರದಲ್ಲಿ ಜನಪ್ರಿಯವಾಗುತ್ತದೆ.",
            "te": "రజియా సూచించిన ఆలోచన పర్యాటకులలో విజయవంతమవుతుంది మరియు వారి వ్యాపారం ప్రాంతంలో ప్రసిద్ధి చెందింది.",
            "or": "ରଜିଆଙ୍କ ଦ୍ୱାରା ପରାମର୍ଶ ଦିଆଯାଇଥିବା ଧାରଣା ପର୍ଯ୍ୟଟକମାନଙ୍କ ପାଇଁ ସଫଳ ହୋଇଯାଏ ଏବଂ ସେମାନଙ୍କର ବ୍ୟବସାୟ ଏହି ଅଞ୍ଚଳରେ ଲୋକପ୍ରିୟ ହୋଇଯାଏ ।",
            "as": "ৰাজিয়াই পৰামৰ্শ দিয়া ধাৰণাটো পৰ্যটকসকলৰ ক্ষেত্ৰত সফল হয় আৰু তেওঁলোকৰ ব্যৱসায় অঞ্চলটোত জনপ্ৰিয় হৈ পৰে।",
            "gu": "રઝિયાએ કહેલો વિચાર પ્રવાસીઓમાં સફળ થાય છે અને તેમનો વ્યવસાય આ વિસ્તારમાં જાણીતા બને છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B2_2_3",
            "hi": "B2_2_3",
            "ka": "B2_2_3",
            "te": "B2_2_3",
            "or": "B2_2_3",
            "as": "B2_2_3",
            "gu": "B2_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia’s new family is not used to having women work but because of her useful suggestions, Razia starts to get involved gradually. Over time she is able to contribute more in the business.",
            "hi": "रजिया के नए परिवार को महिलाओं का काम करना अच्छा नहीं लगता था, लेकिन उसके अच्छे सुझाव के करण रजिया ने धीरे-धीरे व्यवसाय में हाथ बटाना शुरू किया। समय के साथ-साथ वह व्यवसाय में और भी ज्यादा काम करने लगी।",
            "ka": "ರಝಿಯಾ ಅವರ ಹೊಸ ಕುಟುಂಬವು ಮಹಿಳೆಯರು ಕೆಲಸ ಮಾಡುವ ಅಭ್ಯಾಸವನ್ನು ಹೊಂದಿಲ್ಲ ಆದರೆ ಅವಳ ಉಪಯುಕ್ತ ಸಲಹೆಗಳಿಂದಾಗಿ, ರಝಿಯಾ ಕ್ರಮೇಣ ತೊಡಗಿಸಿಕೊಳ್ಳಲು ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ. ಕಾಲಾನಂತರದಲ್ಲಿ ಅವಳು ವ್ಯವಹಾರದಲ್ಲಿ ಹೆಚ್ಚಿನ ಕೊಡುಗೆ ನೀಡಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ.",
            "te": "రజియా యొక్క కొత్త కుటుంబానికి మహిళలు పని చేయడం అలవాటు లేదు కానీ ఆమె ఉపయోగకరమైన సూచనల కారణంగా, రజియా క్రమంగా పాలుపంచుకోవడం ప్రారంభిస్తుంది. కాలక్రమేణా ఆమె వ్యాపారంలో మరింత సహకారం అందించగలదు.",
            "or": "ରଜିଆଙ୍କ ନୂତନ ପରିବାର ମହିଳାମାନଙ୍କୁ କାମ କରିବାରେ ଅଭ୍ୟସ୍ତ ନୁହେଁ କିନ୍ତୁ ତାଙ୍କର ଉପଯୋଗୀ ପରାମର୍ଶ ହେତୁ ରଜିଆ ଧୀରେ ଧୀରେ ଜଡିତ ହେବାକୁ ଲାଗିଲେ । ସମୟ ସହିତ ସେ ବ୍ୟବସାୟରେ ଅଧିକ ଯୋଗଦାନ କରିବାକୁ ସକ୍ଷମ ହୁଅନ୍ତି ।",
            "as": "ৰাজিয়াৰ নতুন পৰিয়ালটোৰ মহিলাসকল কাম কৰাত অভ্যস্ত নহয় যদিও তাইৰ উপযোগী পৰামৰ্শৰ বাবে, ৰাজিয়া ক্ৰমান্বয়ে জড়িত হ'বলৈ ধৰিলে। সময়ৰ লগে লগে তাই ব্যৱসায়ত অধিক অৱদান আগবঢ়াব পাৰিছে।",
            "gu": "રઝિયાના નવા કુટુંબમાં મહિલાઓને કામ કરવાની આદત નથી પરંતુ તેના ઉપયોગી કહેવાથી રઝિયા ધીમે ધીમે તેમાં સામેલ થવા લાગે છે. સમય જતાં તે ધંધામાં વધુ યોગદાન આપવા સક્ષમ બને છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ2",
            "hi": "MCQ2",
            "ka": "MCQ2",
            "te": "MCQ2",
            "or": "MCQ2",
            "as": "MCQ2",
            "gu": "MCQ2"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia does an internship at a company that later offers her a job. Internships help us understand the everyday details of jobs and industries. Select which of the following statements about internships are true.",
            "hi": "रजिया ने कंपनी में इन्टर्नशिप की जिसने आगे चलकर उसे जॉब का ऑफर दिया। इन्टर्नशिप की मदद से हम जॉब और उद्योग के हर दिन के काम को विस्तार से समझ सकते हैं। इन्टर्नशिप के बारे में निम्नलिखित में से कौन सा विधान सही है वह चुनें।",
            "ka": "ರಝಿಯಾ ಕಂಪನಿಯಲ್ಲಿ ಇಂಟರ್ನ್‌ಶಿಪ್ ಮಾಡುತ್ತಾಳೆ, ಅದು ನಂತರ ಅವಳಿಗೆ ಕೆಲಸ ನೀಡುತ್ತದೆ. ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳು ಉದ್ಯೋಗಗಳು ಮತ್ತು ಕೈಗಾರಿಕೆಗಳ ದೈನಂದಿನ ವಿವರಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳ ಕುರಿತು ಈ ಕೆಳಗಿನ ಯಾವ ಹೇಳಿಕೆಗಳು ನಿಜವೆಂದು ಆಯ್ಕೆಮಾಡಿ.",
            "te": "రజియా ఒక కంపెనీలో ఇంటర్న్‌షిప్ చేస్తుంది, అది ఆమెకు ఉద్యోగం ఇచ్చింది. ఉద్యోగాలు మరియు పరిశ్రమల రోజువారీ వివరాలను అర్థం చేసుకోవడానికి ఇంటర్న్‌షిప్‌లు మాకు సహాయపడతాయి. ఇంటర్న్‌షిప్‌ల గురించిన కింది స్టేట్‌మెంట్‌లలో ఏది నిజమో ఎంచుకోండి.",
            "or": "ରଜିଆ ଏକ କମ୍ପାନୀରେ ଏକ ଇଣ୍ଟର୍ନସିପ୍ କରନ୍ତି ଯାହା ପରେ ତାଙ୍କୁ ନିଯୁକ୍ତି ପ୍ରଦାନ କରେ । ଇଣ୍ଟର୍ନସିପ୍ ଆମକୁ ଚାକିରି ଏବଂ ଶିଳ୍ପର ଦୈନନ୍ଦିନ ବିବରଣୀ ବୁଝିବାରେ ସାହାଯ୍ୟ କରେ । ଇଣ୍ଟର୍ନସିପ୍ ବିଷୟରେ ନିମ୍ନଲିଖିତ ମଧ୍ୟରୁ କେଉଁ ଉକ୍ତି ଠିକ ତାହା ଚୟନ କରନ୍ତୁ ।",
            "as": "ৰাজিয়াই এটা কোম্পানীত ইন্টাৰ্ণশ্বিপ কৰে যিটোৱে পিছত তাইক এটা চাকৰি দিয়ে। ইন্টাৰ্ণশ্বিপে আমাক চাকৰি আৰু উদ্যোগৰ দৈনন্দিন বিৱৰণ বুজাত সহায় কৰে। ইণ্টাৰ্নশ্বিপৰ বিষয়ে নিম্নলিখিত কোনটো মন্তব্য সঁচা বাছনি কৰক।",
            "gu": "રઝિયા એક કંપનીમાં ઇન્ટર્નશિપ કરે છે જે તેને નોકરીની આપવાનું નક્કી કરે છે. ઇન્ટર્નશીપ અમને નોકરીઓ અને ઉદ્યોગોની રોજિંદી વિગતો સમજવામાં મદદ કરે છે. ઇન્ટર્નશીપ વિશે નીચેનામાંથી કયું સાચું છે તે પસંદ કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Internships and apprenticeships are the same.",
            "hi": "इन्टर्नशिप और अप्रेंटिसशिप समान हैं।",
            "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಮತ್ತು ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್ ಒಂದೇ.",
            "te": "ఇంటర్న్‌షిప్‌లు మరియు అప్రెంటిస్‌షిప్‌లు ఒకే విధంగా ఉంటాయి.",
            "or": "ଇଣ୍ଟର୍ନସିପ୍ ଏବଂ ଆପ୍ରେଣ୍ଟିସିପ୍ ସମାନ ।",
            "as": "ইণ্টাৰ্নশ্বিপ আৰু এপ্ৰেণ্টিছশ্বিপ একে।",
            "gu": "ઇન્ટર્નશિપ અને એપ્રેન્ટિસશિપ એક સરખું છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "An internship can help you know more about the working environment of an industry",
            "hi": "इन्टर्नशिप आपको उद्योग के काम करने के माहौल को अच्छे से समझने में मदद करती है।",
            "ka": "ಉದ್ಯಮದ ಕೆಲಸದ ವಾತಾವರಣದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಇಂಟರ್ನ್‌ಶಿಪ್ ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ",
            "te": "పరిశ్రమ యొక్క పని వాతావరణం గురించి మరింత తెలుసుకోవడానికి ఇంటర్న్‌షిప్ మీకు సహాయపడుతుంది",
            "or": "ଏକ ଇଣ୍ଟର୍ନସିପ୍ ଆପଣଙ୍କୁ ଏକ ଶିଳ୍ପର କାର୍ଯ୍ୟ ପରିବେଶ ବିଷୟରେ ଅଧିକ ଜାଣିବାରେ ସାହାଯ୍ୟ କରିପାରେ",
            "as": "এটা ইন্টাৰ্ণশ্বিপে আপোনাক উদ্যোগ এটাৰ কামৰ পৰিৱেশৰ বিষয়ে অধিক জনাত সহায় কৰিব পাৰে",
            "gu": "ઇન્ટર્નશિપ વધુ જાણવામાં મદદ કરી શકે છે તમને ઉદ્યોગના કાર્યકારી વાતાવરણ વિશે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "An internship is a one-year long job that helps you learn more about an industry",
            "hi": "इन्टर्नशिप एक-वर्ष का जॉब होता है जो आपको उद्योग के बारे में अधिक जानकारी प्रापर करने में मदद करता है।",
            "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಒಂದು ವರ್ಷದ ದೀರ್ಘಾವಧಿಯ ಕೆಲಸವಾಗಿದ್ದು ಅದು ಉದ್ಯಮದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ",
            "te": "ఇంటర్న్‌షిప్ అనేది ఒక సంవత్సరం సుదీర్ఘ ఉద్యోగం, ఇది పరిశ్రమ గురించి మరింత తెలుసుకోవడానికి మీకు సహాయపడుతుంది",
            "or": "ଏକ ଇଣ୍ଟର୍ନସିପ୍ ହେଉଛି ଏକ ବର୍ଷର ଲମ୍ବା ଚାକିରି ଯାହା ଆପଣଙ୍କୁ ଏକ ଶିଳ୍ପ ବିଷୟରେ ଅଧିକ ଜାଣିବାରେ ସାହାଯ୍ୟ କରେ ।",
            "as": "ইণ্টাৰ্নশ্বিপ হৈছে এক বছৰৰ দীঘলীয়া কাম যি আপোনাক উদ্যোগ এটাৰ বিষয়ে অধিক শিকাত সহায় কৰে",
            "gu": "ઇન્ટર્નશિપમાં એ એક વર્ષ સુધી નોકરી કરવી પડે છે જે તમને ઉદ્યોગ વિશે વધુ જાણવામાં મદદ કરે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Think again! They are similar but not the same. Unlike internships, they can extend from a few months to a couple of years.",
            "hi": "फिर से विचार करें! वे एक जैसे तो हैं लेकिन समान नहीं हैं। इन्टर्नशिप के विपरीत, इसे कुछ महीनों से लेकर कुछ सालों तक बढ़ाया जा सकता है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಅವು ಹೋಲುತ್ತವೆ ಆದರೆ ಒಂದೇ ಅಲ್ಲ. ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳಿಗಿಂತ ಭಿನ್ನವಾಗಿ, ಅವರು ಕೆಲವು ತಿಂಗಳುಗಳಿಂದ ಒಂದೆರಡು ವರ್ಷಗಳವರೆಗೆ ವಿಸ್ತರಿಸಬಹುದು.",
            "te": "మరలా ఆలోచించు! అవి ఒకేలా ఉంటాయి కానీ ఒకేలా ఉండవు. ఇంటర్న్‌షిప్‌ల మాదిరిగా కాకుండా, అవి కొన్ని నెలల నుండి కొన్ని సంవత్సరాల వరకు పొడిగించవచ్చు.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ସେଗୁଡିକ ସମାନ ପରି କିନ୍ତୁ ସମାନ ନୁହଁନ୍ତି । ଇଣ୍ଟର୍ନସିପ୍ ପରି, ସେଗୁଡିକ କିଛି ମାସରୁ ଦୁଇ ବର୍ଷ ପର୍ଯ୍ୟନ୍ତ ହୋଇପାରେ ।",
            "as": "আকৌ চিন্তা কৰা! সেইবোৰ একে ধৰণৰ কিন্তু সম্পূৰ্ণ একে নহয়। ইণ্টাৰ্নশ্বিপৰ বিপৰীতে, সেইবোৰ কেইমাহমানৰ পৰা কেইবছৰমানলৈ কেতিয়াবা প্ৰসাৰিত হ'ব পাৰে।",
            "gu": "વિચાર ફરીથી! તે એક સરખા જેવુ છે પરંતુ થોડું અલગગ છે. ઇન્ટર્નશીપથી ઊલટું, તેમાં થોડા મહિનાઓથી બે વર્ષ સુધી લાંબુ પણ કરી શકે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "शाबाश!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "గొప్ప పని!",
            "or": "ବହୁତ ଭଲ!",
            "as": "ভাল কাম!",
            "gu": "સરસ કામ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! Internships are short term work opportunities usually for a few weeks or months.",
            "hi": "फिर से विचार करें! इन्टर्नशिप अल्पअवधी के लिए काम करने के अवसर होते हैं जो कुछ हफ्तों से लेकर कुछ महीनों के होते हैं।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳು ಸಾಮಾನ್ಯವಾಗಿ ಕೆಲವು ವಾರಗಳು ಅಥವಾ ತಿಂಗಳುಗಳವರೆಗೆ ಅಲ್ಪಾವಧಿಯ ಕೆಲಸದ ಅವಕಾಶಗಳಾಗಿವೆ.",
            "te": "మరలా ఆలోచించు! ఇంటర్న్‌షిప్‌లు సాధారణంగా కొన్ని వారాలు లేదా నెలలపాటు స్వల్పకాలిక ఉద్యోగ అవకాశాలు.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ଇଣ୍ଟର୍ନସିପ୍ ସାଧାରଣତଃ କିଛି ସପ୍ତାହ କିମ୍ବା ମାସ ପାଇଁ ସ୍ୱଳ୍ପ କାଳୀନ କାର୍ଯ୍ୟ ସୁଯୋଗ ।",
            "as": "আকৌ চিন্তা কৰা! ইণ্টাৰ্নশ্বিপ হৈছে সাধাৰণতে কেইসপ্তাহ বা মাহৰ বাবে কম সময়ৰ কামৰ সুযোগ।",
            "gu": "ફરીથી વિચારો! ઇન્ટર્નશીપ એ થોડા સમય માટેની એક તક છે કદાચ થોડા અઠવાડીયા અથવા મહિના માટે"
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ3",
            "hi": "MCQ3",
            "ka": "MCQ3",
            "te": "MCQ3",
            "or": "MCQ3",
            "as": "MCQ3",
            "gu": "MCQ3"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia studies the college brochure to identify which courses have the most jobs available post-training. Knowing trends helps you plan your career and decide what to learn to be up to date. Select which of the \nfollowing statements about trends is true:",
            "hi": "रजिया कॉलेज के ब्रोशर को पढ़ती है और पता करती है कि कौन सा कोर्स करने ज्यादा जॉब अवसर उपलब्ध हैं।रुझान के बारे में जानने से आपको अपने करियर के बारे में प्लान करने में और अपडेट रहने के लिए क्या पढ़ना चाहिए यह जानने में मदद मिलती है।\n रुझान के बारे में निम्नलिखित में से कौन सा विधान सही है वह चुनें:",
            "ka": "ತರಬೇತಿಯ ನಂತರ ಯಾವ ಕೋರ್ಸ್‌ಗಳಲ್ಲಿ ಹೆಚ್ಚು ಉದ್ಯೋಗಗಳು ಲಭ್ಯವಿವೆ ಎಂಬುದನ್ನು ಗುರುತಿಸಲು ರಝಿಯಾ ಕಾಲೇಜು ಕರಪತ್ರವನ್ನು ಅಧ್ಯಯನ ಮಾಡುತ್ತಾಳೆ. ಟ್ರೆಂಡ್‌ಗಳನ್ನು ತಿಳಿದುಕೊಳ್ಳುವುದು ನಿಮ್ಮ ವೃತ್ತಿಯನ್ನು ಯೋಜಿಸಲು ಮತ್ತು ನವೀಕೃತವಾಗಿರಲು ಏನನ್ನು ಕಲಿಯಬೇಕೆಂದು ನಿರ್ಧರಿಸಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.ಈ ಕೆಳಗಿನ ಪ್ರವೃತ್ತಿಗಳ ಬಗ್ಗೆ ಯಾವ ಹೇಳಿಕೆಗಳು ನಿಜ ಎಂದು ಆಯ್ಕೆ ಮಾಡಿ\n  :",
            "te": "\"శిక్షణా తరువాత ఏయే కోర్సుల్లో అత్యధిక ఉద్యోగాలు అందుబాటులో ఉన్నాయో గుర్తించడానికి రజియా కళాశాల బ్రోచర్‌ను అధ్యయనం చేస్తుంది. ట్రెండ్‌లను తెలుసుకోవడం మీ కెరీర్‌ప్రణాళిక చేసుకోవడంలో మరియు తాజాగా ఏమి నేర్చుకోవాలో నిర్ణయించుకోవడంలో మీకు సహాయపడుతుంది. వాటిలో దేనిని ఎంచుకోండి\n ట్రెండ్‌ల గురించి కింది ప్రకటనలు నిజం:\"",
            "or": "କେଉଁ ପାଠ୍ୟକ୍ରମରେ ତାଲିମ ପରେ ସର୍ବାଧିକ ଚାକିରି ଉପଲବ୍ଧ ତାହା ଚିହ୍ନଟ କରିବା ପାଇଁ ରଜିଆ କଲେଜ ବ୍ରୋସର ଅଧ୍ୟୟନ କରନ୍ତି । ଟ୍ରେଣ୍ଡ ଜାଣିବା ଆପଣଙ୍କୁ ଆପଣଙ୍କ କ୍ୟାରିୟର ଯୋଜନା କରିବାରେ ଏବଂ ଅପ୍ ଟୁ ଡେଟ୍ ହେବା ଶିଖିବା ରେ ସାହାଯ୍ୟ କରେ । ଟ୍ରେଣ୍ଡଗୁଡିକ ବିଷୟରେ ନିମ୍ନଲିଖିତ ଉକ୍ତିଗୁଡିକ ମଧ୍ୟରୁ କେଉଁଟି ଠିକ ଚୟନ କରନ୍ତୁ:",
            "as": "প্ৰশিক্ষণৰ পিছত কোনবোৰ পাঠ্যক্ৰমত আটাইতকৈ বেছি চাকৰি পাব পাৰি তাক জানিবলৈ ৰাজিয়াই কলেজৰ প্ৰচাৰ প্ৰত্ৰিকাখন পঢ়ে। ট্ৰেণ্ড জনাটোৱে তোমাক তোমাৰ কেৰিয়াৰৰ পৰিকল্পনা কৰাত সহায় কৰে আৰু আপ টু ডেট হ'বলৈ কি শিকিব লাগে সেইটো জনাত সহায় কৰে। \n ট্ৰেণ্ডৰ বিষয়ে নিম্নলিখিত মন্তব্যবোৰৰ কোনটো সঁচা বাছনি কৰা:",
            "gu": "રઝિયા કોલેજનું પુસ્તક ને નજર કરે છે જેમાં કે કયા કોર્સમાં નોકરી વધારે છે તાલીમ પછી. આ જાણવાથી તમને તમારી કારકિર્દીની યોજના બનાવવામાં અને અદ્યતન બનવા માટે શું ભણવું તે નક્કી કરવામાં મદદ મળે છે. તેમાંથી એક વલણ પસંદ કરો જે વિશે નીચેના નિવેદનો સાચા છે:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Some trends can be short term and create only a temporary increase in jobs or skills required.",
            "hi": "कुछ रुझान अल्पकालिक होते हैं और बस कुछ सी समय के लिए जॉब निर्माण करते हैं या इस कौशल की आवश्यकता होती है।",
            "ka": "ಕೆಲವು ಪ್ರವೃತ್ತಿಗಳು ಅಲ್ಪಾವಧಿಯದ್ದಾಗಿರಬಹುದು ಮತ್ತು ಅಗತ್ಯವಿರುವ ಉದ್ಯೋಗಗಳು ಅಥವಾ ಕೌಶಲ್ಯಗಳಲ್ಲಿ ತಾತ್ಕಾಲಿಕ ಹೆಚ್ಚಳವನ್ನು ಮಾತ್ರ ರಚಿಸಬಹುದು.",
            "te": "కొన్ని ట్రెండ్‌లు స్వల్పకాలికంగా ఉంటాయి మరియు అవసరమైన ఉద్యోగాలు లేదా నైపుణ్యాలలో తాత్కాలిక పెరుగుదలను మాత్రమే సృష్టించవచ్చు.",
            "or": "କିଛି ଟ୍ରେଣ୍ଡ ସ୍ୱଳ୍ପ କାଳୀନ ହୋଇପାରେ ଏବଂ ଆବଶ୍ୟକ ଚାକିରି କିମ୍ବା ଦକ୍ଷତାରେ କେବଳ ଅସ୍ଥାୟୀ ବୃଦ୍ଧି ସୃଷ୍ଟି କରିପାରେ ।",
            "as": "কিছুমান ট্ৰেণ্ড কম সময়ৰ বাবে হ'ব পাৰে আৰু কেৱল অস্থায়ী সময়ৰ বাবে চাকৰি বা দক্ষতাৰ সৃষ্টি কৰিব পাৰে।",
            "gu": "કેટલાક વલણો ટૂંકા સમયના હોઈ શકે છે અને નોકરીઓ અથવા આવશ્યક કૌશલ્યોમાં માત્ર થોડા સમય માટે વધારો કરી શકે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "To know the trend about an industry or area of work you need to do research & networking.",
            "hi": "किसी उद्योग या कार्यक्षेत्र से संबंधित रुझान के बारे में जानने के लिए आपको रिसर्च और नेटवर्किंग करने की आवश्यकता हैं।",
            "ka": "ಉದ್ಯಮ ಅಥವಾ ಕೆಲಸದ ಕ್ಷೇತ್ರದ ಪ್ರವೃತ್ತಿಯನ್ನು ತಿಳಿಯಲು ನೀವು ಸಂಶೋಧನೆ ಮತ್ತು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮಾಡಬೇಕಾಗಿದೆ.",
            "te": "పరిశ్రమ లేదా పని ప్రాంతం గురించిన ట్రెండ్‌ని తెలుసుకోవడానికి మీరు పరిశోధన & నెట్‌వర్కింగ్ చేయాలి.",
            "or": "ଏକ ଶିଳ୍ପ କିମ୍ବା କାର୍ଯ୍ୟକ୍ଷେତ୍ର ବିଷୟରେ ଟ୍ରେଣ୍ଡ ଜାଣିବା ପାଇଁ ଆପଣଙ୍କୁ ଅନୁସନ୍ଧାନ ଏବଂ ନେଟୱାର୍କିଂ କରିବା ଆବଶ୍ୟକ ।",
            "as": "উদ্যোগ বা কামৰ ক্ষেত্ৰৰ বিষয়ে ট্ৰেণ্ড জানিবলৈ আপুনি গৱেষণা আৰু নেটৱৰ্কিং কৰিব লাগিব।",
            "gu": "સંશોધન અને નેટવર્કિંગ કરવી જરૂર છે ઉદ્યોગ અથવા કાર્ય ક્ષેત્ર વિશેના વલણને જાણવા માટે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "It is always a good decision to follow the trend when choosing a course to study",
            "hi": "अध्ययन के लिए कोर्स का चुनाव करते समय रुझान को फ़ॉलो करना हमेशा सही निर्णय होता है।",
            "ka": "ಅಧ್ಯಯನ ಮಾಡಲು ಕೋರ್ಸ್ ಅನ್ನು ಆಯ್ಕೆಮಾಡುವಾಗ ಯಾವಾಗಲೂ ಪ್ರವೃತ್ತಿಯನ್ನು ಅನುಸರಿಸುವುದು ಉತ್ತಮ ನಿರ್ಧಾರ",
            "te": "చదువుకోవడానికి కోర్సును ఎంచుకున్నప్పుడు ట్రెండ్‌ను అనుసరించడం ఎల్లప్పుడూ మంచి నిర్ణయం",
            "or": "ଅଧ୍ୟୟନ ପାଇଁ ଏକ ପାଠ୍ୟକ୍ରମ ବାଛିବା ସମୟରେ ଟ୍ରେଣ୍ଡ ଅନୁସରଣ କରିବା ସର୍ବଦା ଏକ ଭଲ ନିଷ୍ପତ୍ତି ।",
            "as": "অধ্যয়নৰ বাবে পাঠ্যক্ৰম এটা বাছনি কৰোঁতে ট্ৰেণ্ড অনুসৰণ কৰাটো সদায়ে এটা ভাল সিদ্ধান্ত",
            "gu": "એ સારો નિર્ણય છે કે જો અભ્યાસ માટે અભ્યાસક્રમ પસંદ કરતી વખતે વલણને અનુસરવું જોઇયે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Correct.",
            "hi": "सही.",
            "ka": "ಸರಿ.",
            "te": "సరైనది.",
            "or": "ଠିକ୍‌।",
            "as": "শুদ্ধ।",
            "gu": "સાચું"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Correct.",
            "hi": "सही.",
            "ka": "ಸರಿ.",
            "te": "సరైనది.",
            "or": "ଠିକ୍‌।",
            "as": "শুদ্ধ।",
            "gu": "સાચું"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Oops! It is also important to review your decision based on your abilities & interests.",
            "hi": "ओहो! अपनी क्षमता और दिलचस्पी के अनुसार अपने निर्णय को परखना महत्वपूर्ण है।",
            "ka": "ಅಯ್ಯೋ! ನಿಮ್ಮ ಸಾಮರ್ಥ್ಯಗಳು ಮತ್ತು ಆಸಕ್ತಿಗಳ ಆಧಾರದ ಮೇಲೆ ನಿಮ್ಮ ನಿರ್ಧಾರವನ್ನು ಪರಿಶೀಲಿಸುವುದು ಸಹ ಮುಖ್ಯವಾಗಿದೆ.",
            "te": "అయ్యో! మీ సామర్థ్యాలు మరియు ఆసక్తుల ఆధారంగా మీ నిర్ణయాన్ని సమీక్షించడం కూడా ముఖ్యం.",
            "or": "ଓଃ! ତୁମର ଦକ୍ଷତା ଏବଂ ରୁଚି ଉପରେ ଆଧାର କରି ତୁମର ନିଷ୍ପତ୍ତିର ସମୀକ୍ଷା କରିବା ମଧ୍ୟ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ।",
            "as": "উফ! তোমাৰ সামৰ্থ্য আৰু স্বাৰ্থৰ ওপৰত ভিত্তি কৰি তোমাৰ সিদ্ধান্ত আকৌ পৰীক্ষা কৰাটোও জৰুৰী।",
            "gu": "અરે! એ પણ જોવું જોઈએ કે તમારી ક્ષમતાઓ અને રુચિઓના આધારે તમારા નિર્ણયને નિહાળવું મહત્વપૂર્ણ છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Contribute in Family Business",
            "hi": "परिवार के व्यवसाय में योगदान",
            "ka": "**THREAD_END**_ ಕುಟುಂಬ ವ್ಯವಹಾರದಲ್ಲಿ ಕೊಡುಗೆ",
            "te": "**THREAD_END**_కుటుంబ వ్యాపారాల్లో సహకారం చేయడం",
            "or": "**THREAD_END**_ପାରିବାରିକ ବ୍ୟବସାୟରେ ଅବଦାନ",
            "as": "**থ্ৰেড এণ্ড**_ পাৰিবাৰিক ব্যৱসায়ত বৰঙণি আগবঢ়োৱা",
            "gu": "**THREAD_END**_કૌટુંબિક વ્યવસાયમાં યોગદાન આપો"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Razia that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने रजिया के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँची है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकती है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n \n ನೀವು ರಝಿಯಾಗಾಗಿ ಮಾಡಿದ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు రజియా కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ! \n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି! \n ଆପଣ ରଜିଆ ପାଇଁ ନିଷ୍ପତ୍ତିଗୁଡିକ ପସନ୍ଦ କରିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତିକୁ ଆଗେଇ ନେଇଛି । ଆପଣ କରୁଥିବା ପସନ୍ଦଗୁଡିକ ଉପରେ ଆଧାର କରି ବିଭିନ୍ନ ପ୍ରକାରର ଶେଷ ଅଛି । ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହାଁନ୍ତି କି?",
            "as": "অভিনন্দন!\n তুমি এটা যাত্ৰা সমাপ্ত কৰিলা!\n \n তুমি ৰাজিয়াৰ বাবে বাছনি কৰিছা যাৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত আধাৰিত কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ জানিব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક સફર પૂરી કરી છે!\n\nતમે રઝિયા માટે જે પસંદગીઓ કરી છે જેના કારણે આ સંભવિત અંત આવ્યો છે. તમે જે પસંદગી કરો છો તેના આધારે વિવિધ અંત છે. શું તમે બીજા દ્રશ્યો શોધવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ର ଉପରେ ନଜର ପକାନ୍ତୁ",
            "as": "মানচিত্ৰখন চোৱা",
            "gu": "ચાલો નકશા તરફ નજર નાખીએ"
          }
        }
      ]
    },
    "scene31": {
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_1_1_Do the internship",
            "hi": "इन्टर्नशिप करना",
            "ka": "B3_1_1_ಇಂಟರ್ನ್‌ಶಿಪ್ ಮಾಡಿ",
            "te": "B3_1_1_ఇంటర్న్‌షిప్ చేయడం",
            "or": "B3_1_1_ଇଣ୍ଟର୍ନସିପ୍ କରନ୍ତୁ",
            "as": "B3_1_1_ইন্টাৰ্ণশ্বিপ কৰা",
            "gu": "B3_1_1_ઇન્ટર્નશિપ કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "During the internship, she is assigned to the front office team.",
            "hi": "इन्टर्नशिप करने के दौरान उसे फ्रंट ऑफिस टीम में शामिल किया गया।",
            "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಸಮಯದಲ್ಲಿ, ಅವಳನ್ನು ಫ್ರಂಟ್ ಆಫೀಸ್ ತಂಡಕ್ಕೆ ನಿಯೋಜಿಸಲಾಗಿದೆ.",
            "te": "ఇంటర్న్‌షిప్ సమయంలో, ఆమె ఫ్రంట్ ఆఫీస్ బృందానికి కేటాయించబడుతుంది",
            "or": "ଇଣ୍ଟର୍ନସିପ୍ ସମୟରେ, ତାଙ୍କୁ ଫ୍ରଣ୍ଟ ଅଫିସ ଦଳକୁ ନ୍ୟସ୍ତ କରାଯାଇଛି ।",
            "as": "ইণ্টাৰ্নশ্বিপৰ সময়ত, তাইক সন্মুখৰ কাৰ্যালয়ৰ বাবে বাছনি কৰা দলত নিযুক্ত কৰা হয়।",
            "gu": "ઇન્ટર્નશીપના સમય વખતે, તેને ફ્રન્ટ ઓફિસ ટીમ સોંપવામાં આવે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_1_2",
            "hi": "B3_1_2",
            "ka": "B3_1_2",
            "te": "B3_1_2",
            "or": "B3_1_2",
            "as": "B3_1_2",
            "gu": "B3_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She learns from the staff how to manage registers, entry of guests, and assign guest rooms on the computer.",
            "hi": "उसने स्टाफ से रजिस्टर रखना, मेहमानों की एंट्री करना और कम्प्यूटर पर मेहमानों के लिए रूम असाइन करना सिखा।",
            "ka": "ರಿಜಿಸ್ಟರ್‌ಗಳನ್ನು ನಿರ್ವಹಿಸುವುದು, ಅತಿಥಿಗಳ ಪ್ರವೇಶ ಮತ್ತು ಕಂಪ್ಯೂಟರ್‌ನಲ್ಲಿ ಅತಿಥಿ ಕೊಠಡಿಗಳನ್ನು ನಿಯೋಜಿಸುವುದು ಹೇಗೆ ಎಂದು ಸಿಬ್ಬಂದಿಯಿಂದ ಅವಳು ಕಲಿಯುತ್ತಾಳೆ.",
            "te": "కంప్యూటర్‌లో రిజిస్టర్లను ఎలా నిర్వహించాలో అతిథుల ప్రవేశం మరియు అతిథి గదులను కేటాయించడం ఆమె సిబ్బంది నుండి నేర్చుకుంటుంది,",
            "or": "ସେ କର୍ମଚାରୀଙ୍କଠାରୁ ଶିଖନ୍ତି ଯେ କିପରି ରେଜିଷ୍ଟର ପରିଚାଳନା କରିବେ, ଅତିଥିଙ୍କ ପ୍ରବେଶ କରିବେ ଏବଂ କମ୍ପ୍ୟୁଟରରେ ଅତିଥି କୋଠରୀ ନ୍ୟସ୍ତ କରିବେ ।",
            "as": "তেওঁ কৰ্মচাৰীৰ পৰা পঞ্জীয়ন, অতিথিৰ প্ৰৱেশ, আৰু কম্পিউটাৰত অতিথি কোঠা কেনেদৰে ঠিক কৰিব লাগে শিকে।",
            "gu": "તે સ્ટાફ પાસેથી શીખવા મળે છે કે કેવી રીતે રજિસ્ટરને ગોઠવુંવ, કમ્પ્યુટર પર મહેમાનોની હાજરી અને આવનાર માટે રૂમ કેવી રીતે સોપવો."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_1_3",
            "hi": "B3_1_3",
            "ka": "B3_1_3",
            "te": "B3_1_3",
            "or": "B3_1_3",
            "as": "B3_1_3",
            "gu": "B3_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "In a week's time, she starts managing registers and guests' records independently.",
            "hi": "एक हफ्ते में उसने स्वतंत्र रूप से रजिस्टर लिखना और मेहमानों का रेकॉर्ड रखना शुरू किया।",
            "ka": "ಒಂದು ವಾರದ ಸಮಯದಲ್ಲಿ, ಅವರು ಸ್ವತಂತ್ರವಾಗಿ ರೆಜಿಸ್ಟರ್‌ಗಳು ಮತ್ತು ಅತಿಥಿಗಳ ದಾಖಲೆಗಳನ್ನು ನಿರ್ವಹಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ.",
            "te": "ఒక వారం వ్యవధిలో, ఆమె రిజిస్టర్లు మరియు అతిథుల రికార్డులను స్వతంత్రంగా నిర్వహించడం ప్రారంభిస్తుంది.",
            "or": "ଏକ ସପ୍ତାହ ମଧ୍ୟରେ, ସେ ସ୍ୱାଧୀନ ଭାବରେ ରେଜିଷ୍ଟର ଏବଂ ଅତିଥିଙ୍କ ରେକର୍ଡ ପରିଚାଳନା ଆରମ୍ଭ କରନ୍ତି ।",
            "as": "এসপ্তাহৰ ভিতৰত, তাই স্বাধীনভাৱে পঞ্জীয়ন আৰু অতিথিৰ ৰেকৰ্ড পৰিচালনা কৰা আৰম্ভ কৰে।",
            "gu": "એક અઠવાડિયાના સમયમાં, કેવી રીતે રજિસ્ટર અને મહેમાનોના રેકોર્ડનું સંચાલન કરવાનું શરૂ કરવું તે પોતાના રીતે બધુ કરે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_2_1",
            "hi": "B3_2_1",
            "ka": "B3_2_1",
            "te": "B3_2_1",
            "or": "B3_2_1",
            "as": "B3_2_1",
            "gu": "B3_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The 2 months of the internship are very useful for her to understand how work takes place in an office. She gains confidence to manage herself better and take ownership of work.",
            "hi": "2 महीने की इन्टर्नशिप उसके लिए काफी उपयुक्त साबित हुई, जिसने उसे ऑफिस में कैसे काम होता है यह समझने में मदद की। उसे खुद को अच्छे से संभालने का और काम की जिम्मेदारी लेने का आत्मविश्वास मिला।",
            "ka": "ಕಛೇರಿಯಲ್ಲಿ ಕೆಲಸ ಹೇಗೆ ನಡೆಯುತ್ತದೆ ಎಂಬುದನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ೨ ತಿಂಗಳ ಇಂಟರ್ನ್‌ಶಿಪ್ ತುಂಬಾ ಉಪಯುಕ್ತವಾಗಿದೆ. ಅವಳು ತನ್ನನ್ನು ತಾನು ಉತ್ತಮವಾಗಿ ನಿರ್ವಹಿಸಿಕೊಳ್ಳುವ ಮತ್ತು ಕೆಲಸದ ಮಾಲೀಕತ್ವವನ್ನು ತೆಗೆದುಕೊಳ್ಳುವ ವಿಶ್ವಾಸವನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
            "te": "2 నెలల ఇంటర్న్‌షిప్ ఆమెకు ఆఫీసులో పని ఎలా జరుగుతుందో అర్థం చేసుకోవడానికి చాలా ఉపయోగకరంగా ఉంటుంది. ఆమె తనను తాను మెరుగ్గా నిర్వహించుకోగలదనే విశ్వాసాన్ని పొందుతుంది మరియు పని యొక్క యాజమాన్యాన్ని తీసుకుంటుంది.",
            "or": "ଏକ କାର୍ଯ୍ୟାଳୟରେ କିପରି କାର୍ଯ୍ୟ ହୁଏ ତାହା ବୁଝିବା ପାଇଁ ଇଣ୍ଟର୍ନସିପ୍ ର 2 ମାସ ତାଙ୍କ ପାଇଁ ଅତ୍ୟନ୍ତ ଉପଯୋଗୀ । ସେ ନିଜକୁ ଭଲ ଭାବରେ ପରିଚାଳନା କରିବା ଏବଂ କାର୍ଯ୍ୟର ମାଲିକାନା ନେବା ପାଇଁ ଆତ୍ମବିଶ୍ୱାସ ହାସଲ କରନ୍ତି ।",
            "as": "এটা অফিচত কেনেদৰে কামবোৰ হয় বুজিবলৈ ইণ্টাৰ্নশ্বিপৰ ২ মাহ তাইৰ বাবে অতি উপযোগী। তাই নিজকে ভালদৰে পৰিচালনা কৰিবলৈ আৰু কামৰ মালিকীস্বত্ব ল'বলৈ আত্মবিশ্বাস লাভ কৰে।",
            "gu": "2 મહિનાની ઇન્ટર્નશિપ બહુજ મહત્વની હોય છે જેમાં કામ કેવી રીતે કરવું ઓફિસમાં. તેને આત્મવિશ્વાસ વધારે મળે છે બધુ કેવી રીતે સંચાલન કરવું અને પોતાના પર જવાબદારી કામની મૂકે છે. "
          }
        }
      ],
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "Internship",
            "hi": "इन्टर्नशिप",
            "ka": "ಇಂಟರ್ನ್ಶಿಪ್",
            "te": "ఇంటర్న్‌షిప్",
            "or": "ଇଣ୍ଟର୍ନସିପ୍",
            "as": "ইন্টাৰ্ণশ্বিপ",
            "gu": "ઇન્ટર્નશિપ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained internship experience!",
            "hi": "आपने अभी-अभी इन्टर्नशिप का अनुभव प्राप्त किया है!",
            "ka": "ನೀವು ಈಗಷ್ಟೇ ಇಂಟರ್ನ್‌ಶಿಪ್ ಅನುಭವವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే ఇంటర్న్‌షిప్ అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ଇଣ୍ଟର୍ନସିପ୍ ଅଭିଜ୍ଞତା ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ ইণ্টাৰ্নশ্বিপৰ অভিজ্ঞতা লাভ কৰিছা!",
            "gu": "તમે ઇન્ટર્નશિપનો અનુભવ મળવ્યો હમણાં"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Internship",
            "hi": "इन्टर्नशिप",
            "ka": "ಇಂಟರ್ನ್ಶಿಪ್",
            "te": "ఇంటర్న్‌షిప్",
            "or": "ଇଣ୍ଟର୍ନସିପ୍",
            "as": "ইন্টাৰ্ণশ্বিপ",
            "gu": "ઇન્ટર્નશિપ"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Career Suitability Assessment Tools",
            "hi": "उचित करियर के लिए मूल्यांकन टूल",
            "ka": "ವೃತ್ತಿ ಸೂಕ್ತತೆಯ ಮೌಲ್ಯಮಾಪನ ಪರಿಕರಗಳು",
            "te": "కెరీర్ అనుకూలత అంచనా సాధనాలు",
            "or": "କ୍ୟାରିଅର୍ ଉପଯୁକ୍ତତା ମୂଲ୍ୟାଙ୍କନ ଉପକରଣଗୁଡିକ",
            "as": "কেৰিয়াৰ উপযুক্ততা মূল্যায়ন সঁজুলি",
            "gu": "કારકિર્દી અનુરૂપતા મૂલ્યાંકન સાધનો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "Razia applies for an internship at an eco-resort while studying at the vocational training institute. An internship is a short-term work experience offered by companies, usually to students. An internship is a good opportunity to get exposure to the working environment related to your field of study, often within a specific industry. Each industry works differently. The best way to find an internship is by looking for someone who already works in that industry.",
            "hi": "रजिया वोकेशनल ट्रेनिंग इंस्टीट्यूट में पढ़ाई करते समय इको-रिसोर्ट में इन्टर्नशिप के लिए अप्लाई करती है। इन्टर्नशिप कंपनियों द्वारा दिया जानेवाला काम का अल्पकालीन अनुभव होता है। इन्टर्नशिप आपके पढ़ाई के क्षेत्र से संबंधित, अक्सर किसी विशेष उद्योग में, काम के पर्यावरण का अनुभव प्राप्त करने का अच्छा अवसर होता है। प्रत्येक उद्योग का काम करने का अलग तारिका होता है। इन्टर्नशिप प्राप्त करने का सबसे अच्छा तारिका यह है कि इस उद्योग में काम करनेवाली व्यक्ति को ढूँढना।",
            "ka": "ರಝಿಯಾ ವೃತ್ತಿಪರ ತರಬೇತಿ ಸಂಸ್ಥೆಯಲ್ಲಿ ಅಧ್ಯಯನ ಮಾಡುವಾಗ ಪರಿಸರ-ರೆಸಾರ್ಟ್‌ನಲ್ಲಿ ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಾಳೆ. ಇಂಟರ್ನ್‌ಶಿಪ್ ಎನ್ನುವುದು ಕಂಪನಿಗಳು ಸಾಮಾನ್ಯವಾಗಿ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ನೀಡುವ ಅಲ್ಪಾವಧಿಯ ಕೆಲಸದ ಅನುಭವವಾಗಿದೆ. ಇಂಟರ್ನ್‌ಶಿಪ್ ನಿಮ್ಮ ಅಧ್ಯಯನದ ಕ್ಷೇತ್ರಕ್ಕೆ ಸಂಬಂಧಿಸಿದ ಕೆಲಸದ ವಾತಾವರಣಕ್ಕೆ ಒಡ್ಡಿಕೊಳ್ಳಲು ಉತ್ತಮ ಅವಕಾಶವಾಗಿದೆ, ಸಾಮಾನ್ಯವಾಗಿ ನಿರ್ದಿಷ್ಟ ಉದ್ಯಮದಲ್ಲಿ. ಪ್ರತಿಯೊಂದು ಉದ್ಯಮವು ವಿಭಿನ್ನವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ. ಆ ಉದ್ಯಮದಲ್ಲಿ ಈಗಾಗಲೇ ಕೆಲಸ ಮಾಡುವ ಯಾರನ್ನಾದರೂ ಹುಡುಕುವ ಮೂಲಕ ಇಂಟರ್ನ್‌ಶಿಪ್ ಅನ್ನು ಹುಡುಕುವ ಅತ್ಯುತ್ತಮ ಮಾರ್ಗವಾಗಿದೆ.",
            "te": "రజియా వృత్తి విద్యా శిక్షణా సంస్థలో చదువుతున్నప్పుడు పర్యావరణ రిసార్ట్‌లో ఇంటర్న్‌షిప్ కోసం దరఖాస్తు చేసింది. ఇంటర్న్‌షిప్ అనేది కంపెనీలు సాధారణంగా విద్యార్థులకు అందించే స్వల్పకాలిక పని అనుభవం. ఇంటర్న్‌షిప్ అనేది మీ అధ్యయన రంగానికి సంబంధించిన పని వాతావరణాన్ని బహిర్గతం చేయడానికి, తరచుగా ఒక నిర్దిష్ట పరిశ్రమలో ఉండే మంచి అవకాశం. ఒక్కో పరిశ్రమ ఒక్కో విధంగా పనిచేస్తుంది. ఇంటర్న్‌షిప్‌ను కనుగొనడానికి ఉత్తమ మార్గం ఆ పరిశ్రమలో ఇప్పటికే పని చేస్తున్న వారిని వెతకడం.",
            "or": "ଧନ୍ଦାମୂଳକ ତାଲିମ ପ୍ରତିଷ୍ଠାନରେ ଅଧ୍ୟୟନ କରୁଥିବା ବେଳେ ରଜିଆ ଏକ ଇକୋ-ରିସର୍ଟରେ ଇଣ୍ଟର୍ନସିପ୍ ପାଇଁ ଆବେଦନ କରନ୍ତି । ଏକ ଇଣ୍ଟର୍ନସିପ୍ ହେଉଛି କମ୍ପାନୀଗୁଡିକ ଦ୍ୱାରା ପ୍ରଦାନ କରାଯାଉଥିବା ଏକ ସ୍ୱଳ୍ପକାଳୀନ କାର୍ଯ୍ୟ ଅଭିଜ୍ଞତା, ସାଧାରଣତଃ ଛାତ୍ରମାନଙ୍କୁ । ଏକ ଇଣ୍ଟର୍ନସିପ୍ ହେଉଛି ଆପଣଙ୍କ ଅଧ୍ୟୟନ କ୍ଷେତ୍ର ସହିତ ଜଡିତ କାର୍ଯ୍ୟ ପରିବେଶର ସଂସ୍ପର୍ଶରେ ଆସିବାର ଏକ ଭଲ ସୁଯୋଗ, ପ୍ରାୟତଃ ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ଶିଳ୍ପ ମଧ୍ୟରେ । ପ୍ରତ୍ୟେକ ଶିଳ୍ପ ଭିନ୍ନ ଭାବରେ କାର୍ଯ୍ୟ କରେ । ଏକ ଇଣ୍ଟର୍ନସିପ୍ ଖୋଜିବାର ସର୍ବୋତ୍ତମ ଉପାୟ ହେଉଛି ସେହି ଶିଳ୍ପରେ ପୂର୍ବରୁ କାମ କରୁଥିବା ବ୍ୟକ୍ତିଙ୍କୁ ଖୋଜିବା ।",
            "as": "বৃত্তিমূলক প্ৰশিক্ষণ প্ৰতিষ্ঠানত অধ্যয়ন কৰি থাকোঁতে ৰাজিয়াই ইকো-ৰিজৰ্টত ইণ্টাৰ্নশ্বিপৰ বাবে এপ্লাই কৰে। ইণ্টাৰ্নশ্বিপ হৈছে কোম্পানীবোৰে সাধাৰণতে শিক্ষাৰ্থীসকলক আগবঢ়োৱা এক কম সময়ৰ কামৰ অভিজ্ঞতা । এক নিৰ্দিষ্ট উদ্যোগৰ ভিতৰত তোমাৰ অধ্যয়ন ক্ষেত্ৰৰ সৈতে সম্পৰ্কিত কামৰ পৰিৱেশৰ সংস্পৰ্শলৈ অহাৰ বাবে ইণ্টাৰ্নশ্বিপ হৈছে এক ভাল সুযোগ । প্ৰতিটো উদ্যোগে বেলেগ বেলেগ ধৰণে কাম কৰে। ইণ্টাৰ্নশ্বিপ বিচাৰি উলিওৱাৰ সকলোতকে ভাল উপায় হ'ল সেই উদ্যোগত ইতিমধ্যে কাম কৰা কাৰোবাক বিচাৰি উলিওৱা।",
            "gu": "રઝિયા ઇકો-રિસોર્ટમાં ઇન્ટર્નશિપ માટે અરજી કરે છે જે વ્યાવસાયિક તાલીમ સંસ્થામાં અભ્યાસ કરતી વખતે થાય છે. તે થોડા સમયનો  કામનો અનુભવ છે જે ઇન્ટર્નશિપ વખતે કંપનીઓ દ્વારા આપવામાં આવતો હોય છે અને સામાન્ય રીતે વિદ્યાર્થીઓને. ઇન્ટર્નશીપ એ સારી તક છે જેમાં તમારા અભ્યાસના ક્ષેત્ર સાથે સંબંધિત કાર્યકારી વાતાવરણમાં વધુ જાણવાની, ઘણીવાર નક્કી વાળા ઉદ્યોગમાં. દરેક ઉદ્યોગ અલગ રીતે કામ કરે છે. શ્રેષ્ઠ માર્ગ એ છે કે જો તે ઉદ્યોગમાં પહેલેથી કોઈ કામ કરતી વ્યક્તિની શોધ કરવી તેથી ઇન્ટર્નશીપ શોધવાની સરળ થઈ જાય."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_2_2",
            "hi": "B3_2_2",
            "ka": "B3_2_2",
            "te": "B3_2_2",
            "or": "B3_2_2",
            "as": "B3_2_2",
            "gu": "B3_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She is back to her college after the internship. For the next 2 semesters, her learning from the internship adds more value to her courses.",
            "hi": "इन्टर्नशिप पूरी करने के बाद वह फिर से अपने कॉलेज में आती है। अगले 2 सेमिस्टर में इन्टर्नशिप में प्राप्त की शिक्षा के कारण उसका पाठ्यक्रम अधिक अच्छा होता है।",
            "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಮುಗಿಸಿ ಮತ್ತೆ ಕಾಲೇಜಿಗೆ ಮರಳಿದ್ದಾಳೆ. ಮುಂದಿನ ೨ ಸೆಮಿಸ್ಟರ್‌ಗಳಿಗೆ, ಇಂಟರ್ನ್‌ಶಿಪ್‌ನಿಂದ ಅವಳ ಕಲಿಕೆಯು ಅವಳ ಕೋರ್ಸ್‌ಗಳಿಗೆ ಹೆಚ್ಚಿನ ಮೌಲ್ಯವನ್ನು ನೀಡುತ್ತದೆ.",
            "te": "ఇంటర్న్‌షిప్ తర్వాత ఆమె తన కాలేజీకి తిరిగి వచ్చింది. తదుపరి 2 సెమిస్టర్‌ల కోసం, ఆమె ఇంటర్న్‌షిప్ నుండి నేర్చుకున్న విషయాలు ఆమె కోర్సులకు మరింత విలువను జోడిస్తుంది.",
            "or": "ଇଣ୍ଟର୍ନସିପ୍ ପରେ ସେ ତାଙ୍କ କଲେଜକୁ ଫେରିଛନ୍ତି । ପରବର୍ତ୍ତୀ 2ଟି ସେମିଷ୍ଟାର ପାଇଁ, ଇଣ୍ଟର୍ନସିପ୍ ରୁ ତାଙ୍କର ଶିକ୍ଷା ତାଙ୍କ ପାଠ୍ୟକ୍ରମରେ ଅଧିକ ମୂଲ୍ୟ ଯୋଗ କରେ ।",
            "as": "ইন্টাৰ্ণশ্বিপৰ পিছত তাই তাইৰ কলেজলৈ উভতি আহিছে। ইণ্টাৰ্নশ্বিপৰ পৰা তাই শিকাখিনিয়ে তাইৰ পৰৱৰ্তী 2 টা ছেমিষ্টাৰৰ পাঠ্যক্ৰম অধিক মূল্যবান কৰি তুলিছে।",
            "gu": "ઈન્ટર્નશીપ પત્યા બાદ તે પોતાની કોલેજમાં પાછી આવે છે. આવનારા 2 સેમેસ્ટર માટે, ઇન્ટર્નશિપમાંથી તેનું શિક્ષણ તેના અભ્યાસક્રમોમાં વધુ મહત્વ બની જાય છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_3_1",
            "hi": "B3_3_1",
            "ka": "B3_3_1",
            "te": "B3_3_1",
            "or": "B3_3_1",
            "as": "B3_3_1",
            "gu": "B3_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "On finishing her course, she visits the eco-resort to look for a full-time opportunity and finds an opening at the front desk.",
            "hi": "अपना कोर्स पूरा करने के बाद, वह इको-रिसोर्ट में फूल टाइम का जॉब ढूंढने के लिए जाती है और उसे पता चलता है कि वहाँ पर फ्रंट डेस्क का जॉब उपलब्ध है।",
            "ka": "ತನ್ನ ಕೋರ್ಸ್ ಮುಗಿಸಿದ ನಂತರ, ಅವಳು ಪೂರ್ಣ ಸಮಯದ ಅವಕಾಶವನ್ನು ಹುಡುಕಲು ಪರಿಸರ-ರೆಸಾರ್ಟ್‌ಗೆ ಭೇಟಿ ನೀಡುತ್ತಾಳೆ ಮತ್ತು ಮುಂಭಾಗದ ಮೇಜಿನ ಬಳಿ ತೆರೆಯುವಿಕೆಯನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾಳೆ.",
            "te": "తన కోర్సును ముగించిన తర్వాత, ఆమె పూర్తి-సమయ అవకాశం కోసం పర్యావరణ-రిసార్ట్‌ని సందర్శించింది మరియు ముందు డెస్క్‌లో ఓపెనింగ్‌ను కనుగొంటుంది.",
            "or": "ତାଙ୍କ ପାଠ୍ୟକ୍ରମ ସମାପ୍ତ କରିବା ପରେ, ସେ ଏକ ପୂର୍ଣ୍ଣକାଳୀନ ସୁଯୋଗ ଖୋଜିବା ପାଇଁ ଇକୋ-ରିସର୍ଟ ପରିଦର୍ଶନ କରନ୍ତି ଏବଂ ଫ୍ରଣ୍ଟ ଡେସ୍କରେ ଏକ ସୁଯୋଗ ପାଆନ୍ତି ।",
            "as": "তাইৰ পাঠ্যক্ৰম সমাপ্ত কৰাৰ পিছত, তাই পূৰ্ণ-সময়ৰ সুযোগ বিচাৰি ইক'-ৰিজৰ্টলৈ যায় আৰু ফ্ৰন্ট ডেস্কত এটা সুযোগ বিচাৰি পায়।",
            "gu": "તેનો અભ્યાસક્રમ પૂરો કર્યા પછી, તે ઇકો-રિસોર્ટની મુલાકાત લે છે પૂર્ણ-સમયની તક શોધવા માટે અને ફ્રન્ટ ડેસ્ક પર એક નવી શરૂઆત કરે છે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_3_2",
            "hi": "B3_3_2",
            "ka": "B3_3_2",
            "te": "B3_3_2",
            "or": "B3_3_2",
            "as": "B3_3_2",
            "gu": "B3_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "The interviewers assess her growth in the last one year through a demo interaction with a guest.",
            "hi": "इन्टरव्यू लेने वाले मेहमान के साथ डेमो संवाद के जरिए उसने पिछले साल में किए हुए विकास का मूल्यांकन करते हैं।",
            "ka": "ಸಂದರ್ಶಕರು ಅತಿಥಿಯೊಂದಿಗೆ ಡೆಮೊ ಸಂವಾದದ ಮೂಲಕ ಕಳೆದ ಒಂದು ವರ್ಷದಲ್ಲಿ ಆಕೆಯ ಬೆಳವಣಿಗೆಯನ್ನು ನಿರ್ಣಯಿಸುತ್ತಾರೆ.",
            "te": "ఇంటర్వ్యూయర్‌లు అతిథితో డెమో ఇంటరాక్షన్ ద్వారా గత సంవత్సరంలో ఆమె పెరుగుదల ను అంచనా వేస్తారు",
            "or": "ସାକ୍ଷାତକାରକାରୀମାନେ ଅତିଥିଙ୍କ ସହ ଏକ ଡେମୋ କଥାବାର୍ତ୍ତା ମାଧ୍ୟମରେ ଗତ ଏକ ବର୍ଷ ମଧ୍ୟରେ ତାଙ୍କର ଅଭିବୃଦ୍ଧି ଆକଳନ କରନ୍ତି ।",
            "as": "ইন্টাৰভিউ লওতাসকলে অতিথিৰ সৈতে এক প্ৰদৰ্শনী কথা-বতৰাৰ জৰিয়তে যোৱা এবছৰত তাইৰ বিকাশৰ মূল্যায়ন কৰে।",
            "gu": "ઇન્ટરવ્યુ લેનારાઓ મહેમાન સાથેના ડેમો ક્રિયાપ્રતિક્રિયાથી છેલ્લા એક વર્ષમાં તેની બઢતીનું મહત્વ બતાવે છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "B3_3_3",
            "hi": "B3_3_3",
            "ka": "B3_3_3",
            "te": "B3_3_3",
            "or": "B3_3_3",
            "as": "B3_3_3",
            "gu": "B3_3_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "They’re impressed with her confidence and offer her a job.",
            "hi": "वे उसके आत्मविश्वास से प्रभावित होते हैं और उसे जॉब ऑफर करते हैं।",
            "ka": "ಅವರು ಅವಳ ಆತ್ಮವಿಶ್ವಾಸದಿಂದ ಪ್ರಭಾವಿತರಾಗಿದ್ದಾರೆ ಮತ್ತು ಆಕೆಗೆ ಉದ್ಯೋಗವನ್ನು ನೀಡುತ್ತಾರೆ.",
            "te": "వారు ఆమె విశ్వాసానికి ముగ్ధులై ఆమెకు ఉద్యోగాన్ని అందిస్తారు.",
            "or": "ସେମାନେ ତାଙ୍କ ଆତ୍ମବିଶ୍ୱାସରେ ପ୍ରଭାବିତ ହୁଅନ୍ତି ଏବଂ ତାଙ୍କୁ ଚାକିରି ପ୍ରଦାନ କରନ୍ତି ।",
            "as": "তেওঁলোকে তাইৰ আত্মবিশ্বাসত প্ৰভাৱিত হয় আৰু তাইক এটা চাকৰি প্ৰদান কৰিছে।",
            "gu": "તેઓ તેના પ્રભાવિત થાય છે આત્મવિશ્વાસથી અને તેને નોકરીની આપે છે."
          }
        }
      ]
    },
    "scene32": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_1_1",
            "hi": "B4_1_1",
            "ka": "B4_1_1",
            "te": "B4_1_1",
            "or": "B4_1_1",
            "as": "B4_1_1",
            "gu": "B4_1_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia has been working for more than 8 months and has been liking the work at the front desk.",
            "hi": "रजिया 8 महीने से वहाँ पर काम कर रही है और उसे फ्रंट डेस्क का काम अच्छा लगने लगा है।",
            "ka": "ರಝಿಯಾ ೮ ತಿಂಗಳಿಗಿಂತ ಹೆಚ್ಚು ಕಾಲ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾಳೆ ಮತ್ತು ಮುಂಭಾಗದ ಮೇಜಿನ ಕೆಲಸವನ್ನು ಇಷ್ಟಪಟ್ಟಿದ್ದಾಳೆ.",
            "te": "రజియా 8 నెలలకు పైగా పని చేస్తోంది మరియు ఫ్రంట్ డెస్క్‌లో పనిని ఇష్టపడుతోంది.",
            "or": "ରଜିଆ 8 ମାସରୁ ଅଧିକ ସମୟ ଧରି କାମ କରୁଛନ୍ତି ଏବଂ ଫ୍ରଣ୍ଟ ଡେସ୍କରେ କାମକୁ ପସନ୍ଦ କରୁଛନ୍ତି ।",
            "as": "ৰাজিয়াই ৮ মাহৰো অধিক সময় ধৰি কাম কৰি আছে আৰু ফ্ৰন্ট ডেস্কৰ কামটো কৰি ভাল পাইছে।",
            "gu": "રઝિયા 8 મહિનાથી વધુ સમયથી કામ કરી રહી છે અને ફ્રન્ટ ડેસ્ક પર કામ કરવામાં તેને મજા આવે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_1_2",
            "hi": "B4_1_2",
            "ka": "B4_1_2",
            "te": "B4_1_2",
            "or": "B4_1_2",
            "as": "B4_1_2",
            "gu": "B4_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She interacts with the guests with confidence. Her manager is very happy with her communication skills.",
            "hi": "वह मेहमानों के साथ आत्मविश्वास से बातचीत करती है। उसके संवाद कौशल को देखकर उसकी मैनेजर बहुत खुश है।",
            "ka": "ಅತಿಥಿಗಳೊಂದಿಗೆ ಆತ್ಮವಿಶ್ವಾಸದಿಂದ ಸಂವಹನ ನಡೆಸುತ್ತಾಳೆ. ಆಕೆಯ ಸಂವಹನ ಕೌಶಲ್ಯದಿಂದ ಆಕೆಯ ಮ್ಯಾನೇಜರ್ ತುಂಬಾ ಸಂತೋಷಪಟ್ಟಿದ್ದಾರೆ.",
            "te": "ఆమె ఆత్మవిశ్వాసంతో అతిథులతో సంభాషిస్తుంది. ఆమె సంభాషణ నైపుణ్యాల పట్ల ఆమె మేనేజర్ చాలా సంతోషంగా ఉన్నారు.",
            "or": "ସେ ଅତିଥିମାନଙ୍କ ସହିତ ଆତ୍ମବିଶ୍ୱାସ ସହିତ କଥାବାର୍ତ୍ତା କରନ୍ତି । ତାଙ୍କ ମ୍ୟାନେଜର ତାଙ୍କ ଯୋଗାଯୋଗ ଦକ୍ଷତାରେ ବହୁତ ଖୁସି ।",
            "as": "তাই অতিথিসকলৰ সৈতে আত্মবিশ্বাসেৰে কথা পাতে। তাইৰ মেনেজাৰ তাইৰ যোগাযোগ দক্ষতাত বহুত সুখী।",
            "gu": "તે આત્મવિશ્વાસ સાથે મહેમાનો સાથે વાતચીત કરે છે. તેના મેનેજર તેના વાર્તાલાભના કૌશલ્યથી ખૂબ જ ખુશ છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_2_1",
            "hi": "B4_2_1",
            "ka": "B4_2_1",
            "te": "B4_2_1",
            "or": "B4_2_1",
            "as": "B4_2_1",
            "gu": "B4_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "One day, during the tourist season, there is a staff shortage at the resort for a few days. To manage that, Razia is asked to help 2 hours a day in the tour section.",
            "hi": "एक दिन, टूरिजम के सीजन के दौरान, कुछ दिनों के लिए स्टाफ की कमी हो रही थी। इससे निपटने के लिए, रजिया को टूर सेक्शन में हर दिन 2 घंटे के लिए मदद करने के लिए कहा गया।",
            "ka": "ಪ್ರವಾಸಿ ಋತುವಿನಲ್ಲಿ ಒಂದು ದಿನ, ಕೆಲವು ದಿನಗಳವರೆಗೆ ರೆಸಾರ್ಟ್‌ನಲ್ಲಿ ಸಿಬ್ಬಂದಿ ಕೊರತೆ ಇದೆ. ಅದನ್ನು ನಿರ್ವಹಿಸಲು, ಪ್ರವಾಸ ವಿಭಾಗದಲ್ಲಿ ದಿನಕ್ಕೆ ೨ ಗಂಟೆಗಳ ಕಾಲ ಸಹಾಯ ಮಾಡಲು ರಝಿಯಾಳನ್ನು ಕೇಳಲಾಗುತ್ತದೆ.",
            "te": "రిసార్ట్‌లో కొన్ని రోజులుగా పర్యాటక సీజన్ లో సిబ్బంది కొరత ఉంది.ఒక రోజు, దాన్ని నిర్వహించడానికి, టూర్ విభాగంలో రోజుకు 2 గంటలు సహాయం చేయమని రజియాని అడిగారు.",
            "or": "ଦିନେ, ପର୍ଯ୍ୟଟନ ଋତୁରେ, କିଛି ଦିନ ପାଇଁ ରିସର୍ଟରେ କର୍ମଚାରୀ ଅଭାବ ରହିଥାଏ । ଏହାକୁ ପରିଚାଳନା କରିବା ପାଇଁ, ରଜିଆଙ୍କୁ ଭ୍ରମଣ ବିଭାଗରେ ଦିନକୁ 2 ଘଣ୍ଟା ସାହାଯ୍ୟ କରିବାକୁ କୁହାଯାଏ ।",
            "as": "এদিন, পৰ্যটনৰ সময়ত, কিছু দিনৰ বাবে ৰিজৰ্টত কৰ্মচাৰীৰ অভাৱ হয়। সেইটো নিয়ন্ত্ৰণ কৰিবলৈ ৰাজিয়াক ভ্ৰমণ শাখাত দিনে 2 ঘণ্টা সহায় কৰিবলৈ কোৱা হয়।",
            "gu": "એક દિવસ ટુરિસ્ટ સીઝનમાં, રિસોર્ટમાં થોડા દિવસો માટે સ્ટાફ ઓછો હોય છે. તેને સાંભળવા માટે, રઝિયાને પ્રવાસ વિભાગમાં 2 કલાક દિવસમાં મદદ કરવાનું કહેવામાં આવે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_2_2",
            "hi": "B4_2_2",
            "ka": "B4_2_2",
            "te": "B4_2_2",
            "or": "B4_2_2",
            "as": "B4_2_2",
            "gu": "B4_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia’s main responsibility is assisting Sushma Ma’am - a senior environmentalist who conducts tours with the guests.",
            "hi": "रजिया की प्रमुख जिम्मेदारी है सुषमा मैम को मदद करना – जो मेहमानों के साथ टूर आयोजित करनेवाली सीनियर एनवायरमेंटलिस्ट हैं।",
            "ka": "ಅತಿಥಿಗಳೊಂದಿಗೆ ಪ್ರವಾಸಗಳನ್ನು ನಡೆಸುವ ಹಿರಿಯ ಪರಿಸರವಾದಿ ಸುಷ್ಮಾ ಮೇಮ್‌ಗೆ ಸಹಾಯ ಮಾಡುವುದು ರಝಿಯಾಳ ಮುಖ್ಯ ಜವಾಬ್ದಾರಿಯಾಗಿದೆ.",
            "te": "అతిథులతో పర్యటనలు నిర్వహించే సీనియర్ పర్యావరణవేత్త అయిన సుష్మా మామ్‌కు సహాయం చేయడం రజియా ప్రధాన బాధ్యత.",
            "or": "ରଜିଆଙ୍କ ମୁଖ୍ୟ ଦାୟିତ୍ୱ ହେଉଛି ସୁଷମା ମାଡ଼ାମ – ଯିଏ ଜଣେ ବରିଷ୍ଠ ପରିବେଶବିତ୍ ଯିଏ ଅତିଥିମାନଙ୍କ ସହିତ ଭ୍ରମଣ କରନ୍ତି, ତାଙ୍କୁ ସାହାଯ୍ୟ କରିବା ।",
            "as": "ৰাজিয়াৰ মুখ্য দায়িত্ব হৈছে সুষমা মেডামক সহায় কৰা - এগৰাকী জ্যেষ্ঠ পৰিৱেশবিদ যি অতিথিসকলৰ সৈতে ভ্ৰমণ পৰিচালনা কৰে।",
            "gu": "રઝિયાની મહત્વની જવાબદારી સુષ્મા મેડમને મદદ કરવાની છે - એક વરિષ્ઠ પર્યાવરણવાદી જે મહેમાનો સાથે પ્રવાસનું સંચાલન કરે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_2_3",
            "hi": "B4_2_3",
            "ka": "B4_2_3",
            "te": "B4_2_3",
            "or": "B4_2_3",
            "as": "B4_2_3",
            "gu": "B4_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia enjoys interacting with the guests and does her work sincerely.",
            "hi": "रजिया को मेहमानों के साथ बातचीत करना अच्छा लगता है और वह अपना काम पूरी निष्ठा से करती है।",
            "ka": "ರಝಿಯಾ ಅತಿಥಿಗಳೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸುವುದನ್ನು ಆನಂದಿಸುತ್ತಾಳೆ ಮತ್ತು ತನ್ನ ಕೆಲಸವನ್ನು ಪ್ರಾಮಾಣಿಕವಾಗಿ ಮಾಡುತ್ತಾಳೆ.",
            "te": "రజియా అతిథులతో సంభాషించడాన్ని ఆస్వాదిస్తుంది మరియు తన పనిని నిజాయితీగా చేస్తుంది.",
            "or": "ରଜିଆ ଅତିଥିମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରିବାକୁ ଉପଭୋଗ କରନ୍ତି ଏବଂ ଆନ୍ତରିକତାର ସହିତ ତାଙ୍କ କାର୍ଯ୍ୟ କରନ୍ତି ।",
            "as": "ৰাজিয়াই অতিথিসকলৰ সৈতে কথা পাতি ফুৰ্তি পায় আৰু তাইৰ কাম মনোযোগেৰে কৰে।",
            "gu": "રઝિયાને મહેમાનો સાથે વાર્તાલાભ કરવામાં મજા આવે છે અને તેનું કામ આદરપૂર્વક કરે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_2_4////",
            "hi": "B4_2_4////",
            "ka": "B4_2_4////",
            "te": "B4_2_4////",
            "or": "B4_2_4/////",
            "as": "B4_2_4////",
            "gu": "B4_2_4////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "You know there is a village called “widow village” in  Sunderbans. It is because so many women have lost their husbands to tiger attacks there.",
            "hi": "क्या आपको पता हैं कि सुंदरबन में “विधवा गाँव” के नाम का एक गाँव है। क्योंकि यहाँ की बहुत सी महिलाओं के पती शेर के हमले में मारे गए हैं।",
            "ka": "ಸುಂದರ್‌ಬನ್ಸ್‌ನಲ್ಲಿ \"ವಿಧವೆ ಗ್ರಾಮ\" ಎಂಬ ಗ್ರಾಮವಿದೆ ಎಂದು ನಿಮಗೆ ತಿಳಿದಿದೆ. ಏಕೆಂದರೆ ಅಲ್ಲಿ ಹುಲಿ ದಾಳಿಗೆ ಅನೇಕ ಮಹಿಳೆಯರು ತಮ್ಮ ಗಂಡನನ್ನು ಕಳೆದುಕೊಂಡಿದ್ದಾರೆ.",
            "te": "సుందర్‌బన్స్‌లో \"వితంతువు గ్రామం\" అనే గ్రామం ఉందని మీకు తెలుసు. ఎందుకంటే అక్కడ పులుల దాడిలో చాలా మంది మహిళలు తమ భర్తలను కోల్పోయారు",
            "or": "ଆପଣ ଜାଣନ୍ତି ସୁନ୍ଦରବନରେ \"ବିଧବା ଗ୍ରାମ\" ନାମକ ଏକ ଗ୍ରାମ ଅଛି । ଏହାର କାରଣ ହେଉଛି ସେଠାରେ ବାଘ ଆକ୍ରମଣରେ ଅନେକ ମହିଳା ସ୍ୱାମୀଙ୍କୁ ହରାଇଛନ୍ତି।",
            "as": "আপুনি জানে যে সুন্দৰবনত \"বিধৱা গাওঁ\" নামৰ এখন গাওঁ আছে। ইয়াৰ কাৰণ হৈছে বহুতো মহিলাই তাত বাঘৰ আক্ৰমণত তেওঁলোকৰ মানুহকেইজনক হেৰুৱাইছে।",
            "gu": "તને ખબર છે કે સુંદરવનમાં એક ગામ છે તેનું નામ “વિધવા ગામ”. કારણ ઘણી સ્ત્રીઓએ તેમના પતિ ગુમાવ્યા છે વાઘના હુમલામાં ."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "Wow! That’s really interesting. The other Safari guides didn’t tell us all these details!",
            "hi": "वॉव! यह काफी दिलचस्प है। अन्य सफारी गाइड्स ने हमें इस बारे में कुछ भी नहीं बताया था!",
            "ka": "ಅದ್ಭುತ! ಅದು ನಿಜವಾಗಿಯೂ ಆಸಕ್ತಿದಾಯಕವಾಗಿದೆ. ಇತರ ಸಫಾರಿ ಮಾರ್ಗದರ್ಶಿಗಳು ಈ ಎಲ್ಲಾ ವಿವರಗಳನ್ನು ನಮಗೆ ತಿಳಿಸಲಿಲ್ಲ!",
            "te": "వావ్! అది నిజంగా ఆసక్తికరమైనది. ఇతర సఫారీ మార్గదర్శకులు\n  ఈ వివరాలన్నీ మాకు చెప్పలేదు!",
            "or": "ଅଦ୍ଭୁତ! ତାହା ପ୍ରକୃତରେ ଆକର୍ଷଣୀୟ । ଅନ୍ୟ ସଫାରୀ ଗାଇଡ୍ ଆମକୁ ଏହି ସମସ୍ତ ବିବରଣୀ କହିନାହାଁନ୍ତି!",
            "as": "বাঃ! এইটো সঁচাকৈয়ে আকৰ্ষণীয়। আন চাফাৰী গাইডসকলে আমাক এই সকলোবোৰ কোৱা নাছিল!",
            "gu": "વાહ! તે ખૂબ રસપ્રદ વાત છે. બીજા વન્ય માર્ગદર્શિકાઓએ અમને આ બધી વિગતો જણાવી ન હતી!"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Haha I've grown up hearing these stories!",
            "hi": "हाहा मैं ऐसी कहानियां सुनते हुए बड़ी हुई हूँ!",
            "ka": "ಹಹಾ ನಾನು ಈ ಕಥೆಗಳನ್ನು ಕೇಳಿ ಬೆಳೆದಿದ್ದೇನೆ!",
            "te": "హహ నేను ఈ కథలు వింటూ పెరిగాను!",
            "or": "ହାହା ମୁଁ ଏହି କାହାଣୀଗୁଡିକ ଶୁଣି ବଡ ହୋଇଛି!",
            "as": "হা:হা: মই এই কাহিনীবোৰ শুনিয়ে ডাঙৰ হৈছো!",
            "gu": "હાહા હું આ વાર્તાઓથી જ મોટો થયો છું બાળપણમાં મે સાંભળેલી!"
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_3_1//",
            "hi": "B4_3_1//",
            "ka": "B4_3_1//",
            "te": "B4_3_1//",
            "or": "B4_3_1//",
            "as": "B4_3_1//",
            "gu": "B4_3_1//"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Sushma Ma'am approaches her after the tour.",
            "hi": "टूर के बाद में सुषमा मैम उसके पास आती हैं।",
            "ka": "ಪ್ರವಾಸದ ನಂತರ ಸುಷ್ಮಾ ಮೇಡಮ್ ಅವರನ್ನು ಸಂಪರ್ಕಿಸುತ್ತಾರೆ.",
            "te": "టూర్ అయిపోయిన తర్వాత సుష్మా మేడమ్ ఆమె దగ్గరకు వచ్చింది.",
            "or": "ଗସ୍ତ ପରେ ସୁଷମା ମାଡ଼ାମ୍ ତାଙ୍କ ନିକଟକୁ ଆସନ୍ତି ।",
            "as": "ভ্ৰমণৰ পিছত সুষমা মেম তাইৰ ওচৰলৈ আহিছিল।",
            "gu": "સુષ્મા મેડમ તેનો સંપર્ક કરે છે પ્રવાસ પછી."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.label",
          "value": {
            "en": "Sushma Ma'am",
            "hi": "सुषमा मैम",
            "ka": "ಸುಷ್ಮಾ ಮೇಡಂ",
            "te": "సుష్మా మేడమ్",
            "or": "ସୁଷମା ମାଡ଼ାମ୍",
            "as": "সুষমা মেম",
            "gu": "સુષ્મા મેડમ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.label",
          "value": {
            "en": "Razia",
            "hi": "रजिया",
            "ka": "ರಝಿಯಾ",
            "te": "రజియా",
            "or": "ରଜିଆ",
            "as": "ৰাজিয়া",
            "gu": "રઝિયા"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.label",
          "value": {
            "en": "Sushma Ma'am",
            "hi": "सुषमा मैम",
            "ka": "ಸುಷ್ಮಾ ಮೇಡಂ",
            "te": "సుష్మా మేడమ్",
            "or": "ସୁଷମା ମାଡ଼ାମ୍",
            "as": "সুষমা মেম",
            "gu": "સુષ્મા મેડમ"
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.0.text",
          "value": {
            "en": "Thank you for helping me out. There were just too many people today. I couldn't have managed alone.",
            "hi": "मेरी मदद करने के लिए धन्यवाद। आज बहुत ज्यादा लोग थे। मैं अकेली इन्हें नहीं संभाल सकती थी।",
            "ka": "ನನಗೆ ಸಹಾಯ ಮಾಡಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ಇಂದು ತುಂಬಾ ಜನರಿದ್ದರು. ನಾನು ಒಬ್ಬಂಟಿಯಾಗಿ ನಿಭಾಯಿಸಲು ಸಾಧ್ಯವಾಗಲಿಲ್ಲ.",
            "te": "నాకు సహాయం చేసినందుకు ధన్యవాదాలు. ఈ రోజు చాలా మంది వ్యక్తులు ఉన్నారు. నేను ఒంటరిగా నిర్వహించలేకపోయేదాన్ని.",
            "or": "ମୋତେ ସାହାଯ୍ୟ କରିଥିବାରୁ ଧନ୍ୟବାଦ । ଆଜି ବହୁତ ଲୋକ ଥିଲେ । ମୁଁ ଏକୁଟିଆ ପରିଚାଳନା କରିପାରିନଥାନ୍ତି ।",
            "as": "মোক সহায় কৰাৰ বাবে ধন্যবাদ। আজি বহুত মানুহ আছিল। মই অকলে পৰিচালনা কৰিব নোৱাৰিলোহেঁতেন।",
            "gu": "મને મદદ કરવા બદલ તારો આભાર. ઘણા બધા લોકો હતા આજે ત્યાં. હું એકલી સાંભળી ના સકત."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.1.text",
          "value": {
            "en": "No problem ma’am, I really enjoyed it.",
            "hi": "कोई बात नहीं मैम, मुझे यह सच में अच्छा लगा।",
            "ka": "ಪರವಾಗಿಲ್ಲ ಮೇಡಂ, ನಾನು ಅದನ್ನು ನಿಜವಾಗಿಯೂ ಆನಂದಿಸಿದೆ.",
            "te": "పర్వాలేదు మేడమ్, నేను నిజంగా ఆనందించాను.",
            "or": "କୌଣସି ଅସୁବିଧା ନାହିଁ, ମୁଁ ପ୍ରକୃତରେ ଏହାକୁ ଉପଭୋଗ କଲି ।",
            "as": "কোনো সমস্যা নাই মেডাম, মই সঁচাকৈয়ে উপভোগ কৰিছিলো।",
            "gu": "કોઈ વાંધો નહીં મેમ, મને ખરેખર મજા આવી."
          }
        },
        {
          "valuePath": "blocks.0.props.convBlocks.2.text",
          "value": {
            "en": "Ofcourse! I could see that. Even the guests praised you a lot! Good Job Razia!",
            "hi": "पता है! मुझे दिखाई दे रहा था। मेहमानों ने भी तुम्हारी बहुत तारीफ की! शाबाश रजिया!",
            "ka": "ಖಂಡಿತವಾಗಿ! ನಾನು ಅದನ್ನು ನೋಡಬಲ್ಲೆ. ಅತಿಥಿಗಳು ಸಹ ನಿನ್ನನು ತುಂಬಾ ಹೊಗಳಿದರು! ಒಳ್ಳೆಯ ಕೆಲಸ ರಝಿಯಾ!",
            "te": "అనువు! నేను చూసాను. అతిథులు కూడా నిన్ను చాలా పొగిడారు! బాగా చేసావు రజియా!",
            "or": "ଅବଶ୍ୟ! ମୁଁ ତାହା ଦେଖିପାରୁଥିଲି । ଏପରିକି ଅତିଥିମାନେ ମଧ୍ୟ ଆପଣଙ୍କୁ ବହୁତ ପ୍ରଶଂସା କରିଥିଲେ! ତୁମେ ଭଲ କମ କରିଛ ରଜିଆ!",
            "as": "অৱশ্যে! মই সেইটো দেখিবলৈ পালোঁ। আনকি অতিথিসকলেও তোমাক বহুত প্ৰশংসা কৰিছিল! গুড জব ৰাজিয়া!",
            "gu": "ચોક્કસ! હું તે જોઈ શકુ. મહેમાનોએ પણ તમારી ખૂબ વાહ વાહ કરી! સરસ કામ રઝિયા!"
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_3_2////",
            "hi": "B4_3_2////",
            "ka": "B4_3_2////",
            "te": "B4_3_2////",
            "or": "B4_3_2/////",
            "as": "B4_3_2////",
            "gu": "B4_3_2////"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.0.text",
          "value": {
            "en": "Thank you ma'am! I really like being out in the open and talking to the guests about our Sunderbans.",
            "hi": "धन्यवाद मैम! मुझे बाहर घूमना और मेहमानों को सुंदरबन के बारे में बताना अच्छा लगता है।",
            "ka": "ಧನ್ಯವಾದಗಳು ಮೇಡಂ! ನಮ್ಮ ಸುಂದರ್‌ಬನ್‌ಗಳ ಬಗ್ಗೆ ಅತಿಥಿಗಳೊಂದಿಗೆ ಮಾತನಾಡಲು ನಾನು ತುಂಬಾ ಇಷ್ಟಪಡುತ್ತೇನೆ.",
            "te": "ధన్యవాదాలు మేడమ్! మా సుందర్‌బన్‌ల గురించి బహిరంగంగా ఉండటం మరియు అతిథులతో మాట్లాడటం నాకు చాలా ఇష్టం.",
            "or": "ଧନ୍ୟବାଦ ମାଡ଼ାମ୍! ମୁଁ ପ୍ରକୃତରେ ଖୋଲାରେ ରହିବାକୁ ଏବଂ ଅତିଥିମାନଙ୍କ ସହିତ ଆମର ସୁନ୍ଦରବନ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରିବାକୁ ପସନ୍ଦ କରେ ।",
            "as": "ধন্যবাদ মেডাম! মই সঁচাকৈয়ে মুকলিকৈ বাহিৰত থাকি আমাৰ সুন্দৰবনৰ বিষয়ে অতিথিসকলৰ সৈতে কথা পাতি ভাল পাওঁ।",
            "gu": "ધન્યવાદ મેડમ! મને બહાર જવાનું અને મહેમાનો સાથે સુંદરવન વિશે વાત કરવાનું ખરેખર મજા છે."
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.1.text",
          "value": {
            "en": "We are planning to hire a person permanently for this role. I was thinking, why don’t you apply for this? There can be an internal transfer.",
            "hi": "हम सोच रहे हैं कि किसी को इस काम के लिए परमानेंट जॉब पर रखें। मैं सोच रही हूँ कि तुम इस जॉब के लिए अप्लाई क्यों नहीं करती? इंटर्नल ट्रांसफर भी किया जा सकता है।",
            "ka": "ಈ ಪಾತ್ರಕ್ಕೆ ಖಾಯಂ ಆಗಿ ಒಬ್ಬ ವ್ಯಕ್ತಿಯನ್ನು ನೇಮಿಸಿಕೊಳ್ಳಲು ಯೋಜಿಸುತ್ತಿದ್ದೇವೆ. ನಾನು ಯೋಚಿಸುತ್ತಿದ್ದೆ, ನೀನು ಇದಕ್ಕೆ ಏಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಬಾರದು? ಆಂತರಿಕ ವರ್ಗಾವಣೆ ಆಗಬಹುದು.",
            "te": "మేము ఒక వ్యక్తిని ఈ పాత్ర కోసం శాశ్వతంగా నియమించాలని ప్రణాళిక చేస్తున్నాము . నేను ఆలోచిస్తున్నాను, మీరు దీని కోసం ఎందుకు దరఖాస్తు చేయకూడదు? అంతర్గత బదిలీ ఉండవచ్చు.",
            "or": "ଏହି ଭୂମିକା ପାଇଁ ଆମେ ସ୍ଥାୟୀ ଭାବରେ ଜଣେ ବ୍ୟକ୍ତିଙ୍କୁ ନିଯୁକ୍ତି ଦେବାକୁ ଯୋଜନା କରୁଛୁ । ମୁଁ ଭାବୁଥିଲି, ତୁମେ ଏଥିପାଇଁ କାହିଁକି ଆବେଦନ କରୁନ? ଏକ ଆଭ୍ୟନ୍ତରୀଣ ସ୍ଥାନାନ୍ତର ହୋଇପାରେ।",
            "as": "আমি এই চাকৰিটোৰ বাবে স্থায়ীভাৱে এজন ব্যক্তিক নিয়োগ কৰাৰ পৰিকল্পনা কৰি আছো। মই ভাবি আছিলো, তুমি ইয়াৰ বাবে কিয় এপ্লাই নকৰা? এটা আভ্যন্তৰীণ স্থানান্তৰ হ'ব পাৰে।",
            "gu": "અમે કાયમી એક વયક્તિ માટે વિચારી રહ્યા છીયે. હું એવું વિચારું છું કે તું કેમ આના માટે અરજી નથી કરતી? અહી અંદર અંદર બદલી થઈ સકે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.2.text",
          "value": {
            "en": "Oh! Really? But Ma’am do you think I am qualified for this position?",
            "hi": "ओह! सच में? लेकिन मैम क्या आपको लगता हैं कि मैं इस जॉब के काबिल हूँ?",
            "ka": "ಓಹ್! ನಿಜವಾಗಿಯೂ? ಆದರೆ ಮೇಡಂ ನಾನು ಈ ಸ್ಥಾನಕ್ಕೆ ಅರ್ಹನೆಂದು ನೀವು ಭಾವಿಸುತ್ತೀರಾ?",
            "te": "అవునా! నిజంగానా? కానీ మేడమ్, ఈ స్థానానికి నేను సరిపోతానని మీరు అనుకుంటున్నారా?",
            "or": "ଓଃ! ପ୍ରକୃତରେ? କିନ୍ତୁ ମାଡ଼ାମ୍ ଆପଣ ଭାବୁଛନ୍ତି କି ମୁଁ ଏହି ପଦବୀ ପାଇଁ ଯୋଗ୍ୟ?",
            "as": "অহ! সঁচাকৈয়ে? কিন্তু মেডাম আপুনি ভাবেনে যে মই এই পদৰ বাবে যোগ্য?",
            "gu": "ઓહ! ખરેખર? પણ મેડમ તમને લાગે છે કે હું જગ્યા માટે યોગ્ય છું? "
          }
        },
        {
          "valuePath": "blocks.0.props.blockData.3.text",
          "value": {
            "en": "Well, the interview process is not that easy. So if you’re interested, you will have to study hard.",
            "hi": "सच कहूँ तो, इन्टरव्यू की प्रक्रिया आसान नहीं होगी। तो अगर तुम्हें दिलचस्पी है तो तुम्हें बहुत पढ़ाई करनी होगी।",
            "ka": "ಅಲ್ಲದೆ, ಸಂದರ್ಶನ ಪ್ರಕ್ರಿಯೆ ಅಷ್ಟು ಸುಲಭವಲ್ಲ. ಆದ್ದರಿಂದ ನೀನು ಆಸಕ್ತಿ ಹೊಂದಿದ್ದರೆ, ನೀನು ಕಷ್ಟಪಟ್ಟು ಅಧ್ಯಯನ ಮಾಡಬೇಕಾಗುತ್ತದೆ.",
            "te": "అయితే, ఇంటర్వ్యూ ప్రక్రియ అంత సులభం కాదు. కాబట్టి మీకు ఆసక్తి ఉంటే, మీరు కష్టపడి చదువుకోవాలి.",
            "or": "ହଁ, ସାକ୍ଷାତକାର ପ୍ରକ୍ରିୟା ଏତେ ସହଜ ନୁହେଁ । ତେଣୁ ଯଦି ତୁମେ ଆଗ୍ରହୀ, ତେବେ ତୁମକୁ କଠିନ ଅଧ୍ୟୟନ କରିବାକୁ ପଡିବ ।",
            "as": "ঠিক আছে, ইন্টাৰভিউ প্ৰক্ৰিয়াটো ইমান সহজ নহয়। গতিকে যদি তুমি ইচ্ছা কৰা, তুমি ভালদৰে পঢ়াশুনা কৰিব লাগিব।",
            "gu": "ઇન્ટરવ્યૂની ક્રિયા એટલી સરળ નથી. પણ જો તું રાજી હોય તો તારે વધારે મહેનત કરવી પડશે ભણવા માટે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_3_3_RevealComponent",
            "hi": "कम्पोनेंट दिखाएं",
            "ka": "B4_3_4_ ಕಾಂಪೊನೆಂಟ್ ಅನ್ನು ಬಹಿರಂಗಪಡಿಸಿ",
            "te": "B4_3_3_కాంపోనెంట్‌ను బహిర్గతం చేయండి",
            "or": "B4_3_3_ଉପାଦାନ ପ୍ରକାଶ କରନ୍ତୁ",
            "as": "B4_3_3_উপাদান প্ৰকাশ কৰক",
            "gu": "B4_3_3_ભાગ વ્યક્ત કરવું"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia is very excited about this offer. She takes some time to reflect on the Positives and Negatives of both the jobs at hand. Click to reveal Positives and Negatives of Front desk.",
            "hi": "रजिया इस ऑफर को लेकर काफी उत्साहित है। वह इन दोनों जॉब्स के पोजिटिव और निगेटिव बातों के बारे में विचार करती है। फ्रंट डेस्क के पोजिटिव और निगेटिव दिखाने के लिए क्लिक करें।",
            "ka": "ಈ ಆಫರ್ ಬಗ್ಗೆ ರಝಿಯಾ ತುಂಬಾ ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ. ಕೈಯಲ್ಲಿರುವ ಎರಡೂ ಉದ್ಯೋಗಗಳ ಧನಾತ್ಮಕ ಮತ್ತು ಋಣಾತ್ಮಕತೆಯನ್ನು ಪ್ರತಿಬಿಂಬಿಸಲು ಅವಳು ಸ್ವಲ್ಪ ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತಾಳೆ. ಮುಂಭಾಗದ ಮೇಜಿನ ಧನಾತ್ಮಕ ಮತ್ತು ನಕಾರಾತ್ಮಕತೆಯನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "te": "ఈ ఆఫర్ పట్ల రజియా చాలా ఉత్సాహంగా ఉంది. ఆమె చేతిలో ఉన్న రెండు ఉద్యోగాల సానుకూలతలు మరియు ప్రతికూలతల ను ప్రతిబింబించడానికి కొంత సమయం పడుతుంది. ఫ్రంట్ డెస్క్ యొక్క సానుకూలతలు మరియు ప్రతికూలతలు బహిర్గతం చేయడానికి క్లిక్ చేయండి",
            "or": "ରଜିଆ ଏହି ଅଫରକୁ ନେଇ ବହୁତ ଉତ୍ସାହିତ ଅଛନ୍ତି । ସେ ହାତରେ ଥିବା ଉଭୟ ଚାକିରିର ସକରାତ୍ମକ ଏବଂ ନକାରାତ୍ମକ ଉପରେ ତର୍ଜମା କରିବାକୁ କିଛି ସମୟ ନିଅନ୍ତି । ଫ୍ରଣ୍ଟ ଡେସ୍କର ସକରାତ୍ମକ ଏବଂ ନକାରାତ୍ମକ ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ ।",
            "as": "ৰাজিয়া এই প্ৰস্তাৱটোক লৈ অতি উৎসাহিত। তাই হাতত থকা দুয়োটা চাকৰিৰ ভাল আৰু বেয়া কথাবোৰ প্ৰতিফলিত কৰিবলৈ কিছু সময় লয়। ফ্ৰণ্ট ডেস্কৰ ভাল আৰু বেয়া দিশবোৰ প্ৰকাশ কৰিবলৈ ক্লিক কৰক।",
            "gu": "રઝિયા ઉત્સાહિત છે આ તક માટે. તે થોડો સમય લે છે ફાઇદા અને ગેરફાઈદા આ બંને નોકરી બતાવા માટે. ફાઇદા અને ગેરફાઈદા ને બતાવા ક્લિક કરો ફ્રંટ ડેસ્ક પર"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Positive of Front desk",
            "hi": "फ्रंट डेस्क जॉब के पोजिटिव",
            "ka": "ಮುಂಭಾಗದ ಮೇಜಿನ ಧನಾತ್ಮಕ",
            "te": "ఫ్రంట్ డెస్క్ యొక్క సానుకూలత",
            "or": "ଫ୍ରଣ୍ଟ ଡେସ୍କର ସକରାତ୍ମକ",
            "as": "ফ্ৰণ্ট ডেস্কৰ ভাল দিশ",
            "gu": "ફ્રન્ટ ડેસ્કના ફાઈદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "Positive of Front desk",
            "hi": "फ्रंट डेस्क जॉब के पोजिटिव",
            "ka": "ಮುಂಭಾಗದ ಮೇಜಿನ ಧನಾತ್ಮಕ",
            "te": "ఫ్రంట్ డెస్క్ యొక్క సానుకూలత",
            "or": "ଫ୍ରଣ୍ଟ ଡେସ୍କର ସକରାତ୍ମକ",
            "as": "ফ্ৰণ্ট ডেস্কৰ ভাল দিশ",
            "gu": "ફ્રન્ટ ડેસ્કના ફાઈદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.beforeReveal",
          "value": {
            "en": "Negative of Front desk",
            "hi": "फ्रंट डेस्क जॉब के निगेटिव",
            "ka": "ಮುಂಭಾಗದ ಮೇಜಿನ ಋಣಾತ್ಮಕ",
            "te": "ఫ్రంట్ డెస్క్ యొక్క ప్రతికూలత",
            "or": "ଫ୍ରଣ୍ଟ ଡେସ୍କର ନକାରାତ୍ମକ",
            "as": "ফ্ৰণ্ট ডেস্কৰ বেয়া দিশ",
            "gu": "ફ્રન્ટ ડેસ્કના ગેરફાઈદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.beforeReveal",
          "value": {
            "en": "Negative of Front desk",
            "hi": "फ्रंट डेस्क जॉब के निगेटिव",
            "ka": "ಮುಂಭಾಗದ ಮೇಜಿನ ಋಣಾತ್ಮಕ",
            "te": "ఫ్రంట్ డెస్క్ యొక్క ప్రతికూలత",
            "or": "ଫ୍ରଣ୍ଟ ଡେସ୍କର ନକାରାତ୍ମକ",
            "as": "ফ্ৰণ্ট ডেস্কৰ বেয়া দিশ",
            "gu": "ફ્રન્ટ ડેસ્કના ગેરફાઈદા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "Already knows the job",
            "hi": "पहले से ही जॉब के बारे में पता है",
            "ka": "ಆಗಲೇ ಕೆಲಸ ಗೊತ್ತಿದೆ",
            "te": "అప్పటికే ఉద్యోగం గురించి తెలుసు",
            "or": "ପୂର୍ବରୁ କାମ କଣ ଜାଣିଛନ୍ତି",
            "as": "চাকৰিটো কথা আগতিয়াকৈ জানে",
            "gu": "પહેલેથી જ એ કામ વિષે ખબર છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "Can get a job in any other industry",
            "hi": "किसी भी अन्य उद्योग में जॉब मिल सकता है।",
            "ka": "ಬೇರೆ ಯಾವುದೇ ಉದ್ಯಮದಲ್ಲಿ ಕೆಲಸ ಪಡೆಯಬಹುದು",
            "te": "మరే ఇతర పరిశ్రమలోనైనా ఉద్యోగం పొందవచ్చు",
            "or": "ଅନ୍ୟ କୌଣସି ଶିଳ୍ପରେ ଚାକିରି ପାଇପାରିବେ",
            "as": "আন যিকোনো উদ্যোগত চাকৰি এটা পাব পাৰে",
            "gu": "કોઈ પણ કંપનીમાં નોકરી આસાનીથી મળી સકે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.afterReveal",
          "value": {
            "en": "Not a dream job",
            "hi": "मनचाहा जॉब नहीं है",
            "ka": "ಕನಸಿನ ಕೆಲಸವಲ್ಲ",
            "te": "కలగన్న ఉద్యోగం కాదు",
            "or": "ଏକ ସ୍ୱପ୍ନର କାମ ନୁହେଁ",
            "as": "সপোনৰ কামটো নহয়",
            "gu": "સપનાની નોકરી નહીં"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.afterReveal",
          "value": {
            "en": "The growth is very slow",
            "hi": "ग्रोथ धीरे-धीरे होगी",
            "ka": "ಬೆಳವಣಿಗೆ ತುಂಬಾ ನಿಧಾನವಾಗಿದೆ",
            "te": "పెరుగుదల చాలా నెమ్మదిగా ఉంటుంది",
            "or": "ଅଭିବୃଦ୍ଧି ବହୁତ ମନ୍ଥର ଅଟେ",
            "as": "বিকাশ অতি লেহেমীয়া",
            "gu": "બઢતી ખૂબ જ ધીમી છે"
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_3_4_RevealComponent",
            "hi": "B4_3_4_कम्पोनेंट दिखाएं",
            "ka": "B4_3_4_ ರಿವೀಲ್ ಕಾಂಪೊನೆಂಟ್",
            "te": "B4_3_4_కాంపోనెంట్‌ను బహిర్గతం చేయండి",
            "or": "B4_3_4_ଉପାଦାନ ପ୍ରକାଶ କରନ୍ତୁ",
            "as": "B4_3_4_উপাদান প্ৰকাশ কৰক",
            "gu": "B4_3_3_ભાગ વ્યક્ત કરવું"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Click to reveal Positives and Negatives of Assistant Environmentalist.",
            "hi": "असिस्टेंट एनवायरमेंटलिस्ट के पोजिटिव और निगेटिव दिखाने के लिए क्लिक करें।",
            "ka": "ಸಹಾಯಕ ಪರಿಸರವಾದಿಯ ಧನಾತ್ಮಕ ಮತ್ತು ಋಣಾತ್ಮಕ ಅಂಶಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "te": "పర్యావరణ నిపుణుడి సహాయకుడు యొక్క సానుకూల మరియు ప్రతికూల అంశాలను బహిర్గతం చేయడానికి క్లిక్ చేయండి",
            "or": "ସହାୟକ ପରିବେଶବିତ୍ ଙ୍କ ସକରାତ୍ମକ ଏବଂ ନକାରାତ୍ମକ ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ ।",
            "as": "সহকাৰী পৰিৱেশবিদৰ ভাল আৰু বেয়া দিশ প্ৰকাশ কৰিবলৈ ক্লিক কৰক।",
            "gu": "પર્યાવરણ સહાયકના ફાયદા અને ગેરફાયદા જણાવવા માટે ક્લિક કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "Positive of Assistant Environmentalist",
            "hi": "असिस्टेंट एनवायरमेंटलिस्ट के पोजिटिव",
            "ka": "ಸಹಾಯಕ ಪರಿಸರವಾದಿಯ ಧನಾತ್ಮಕ",
            "te": "పర్యావరణ నిపుణుడి సహాయకుడు యొక్క సానుకూలత",
            "or": "ସହାୟକ ପରିବେଶବିତ୍ ଙ୍କ ସକରାତ୍ମକ",
            "as": "সহকাৰী পৰিৱেশবিদৰ ভাল দিশ",
            "gu": "મદદનીશ પર્યાવરણવાદીની ફાઇદાકારક"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "Positive of Assistant Environmentalist",
            "hi": "असिस्टेंट एनवायरमेंटलिस्ट के पोजिटिव",
            "ka": "ಸಹಾಯಕ ಪರಿಸರವಾದಿಯ ಧನಾತ್ಮಕ",
            "te": "పర్యావరణ నిపుణుడి సహాయకుడు యొక్క సానుకూలత",
            "or": "ସହାୟକ ପରିବେଶବିତ୍ ଙ୍କ ସକରାତ୍ମକ",
            "as": "সহকাৰী পৰিৱেশবিদৰ ভাল দিশ",
            "gu": "મદદનીશ પર્યાવરણવાદીની ફાઇદાકારક"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.beforeReveal",
          "value": {
            "en": "Negative of Assistant Environmentalist",
            "hi": "असिस्टेंट एनवायरमेंटलिस्ट के निगेटिव",
            "ka": "ಸಹಾಯಕ ಪರಿಸರವಾದಿಯ ಋಣಾತ್ಮಕ",
            "te": "పర్యావరణ నిపుణుడి సహాయకుడు యొక్క ప్రతికూలత",
            "or": "ସହାୟକ ପରିବେଶବିତ୍ ଙ୍କ ନକାରାତ୍ମକ",
            "as": "সহকাৰী পৰিৱেশবিদৰ বেয়া দিশ",
            "gu": "મદદનીશ પર્યાવરણવાદીની ગેરફાઇદાકારક"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.beforeReveal",
          "value": {
            "en": "Negative of Assistant Environmentalist",
            "hi": "असिस्टेंट एनवायरमेंटलिस्ट के निगेटिव",
            "ka": "ಸಹಾಯಕ ಪರಿಸರವಾದಿಯ ಋಣಾತ್ಮಕ",
            "te": "పర్యావరణ నిపుణుడి సహాయకుడుయొక్క ప్రతికూలత",
            "or": "ସହାୟକ ପରିବେଶବିତ୍ ଙ୍କ ନକାରାତ୍ମକ",
            "as": "সহকাৰী পৰিৱেশবিদৰ বেয়া দিশ",
            "gu": "મદદનીશ પર્યાવરણવાદીની ગેરફાઇદાકારક"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "Dream role to work in environment",
            "hi": "पर्यावरण पर काम करने का मनचाहा रोल",
            "ka": "ಪರಿಸರದಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದು ಕನಸಿನ ಪಾತ್ರ",
            "te": "పర్యావరణంలో పనిచేయడం కలల పాత్ర",
            "or": "ପରିବେଶରେ କାମ କରିବା ସ୍ୱପ୍ନର ଭୂମିକା",
            "as": "পৰিৱেশত কাম কৰিবলৈ সপোনৰ ভূমিকা",
            "gu": "પરિયાવરણ સાથે કામ કરવું એ મારૂ સપનું છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "Will eventually get better pay",
            "hi": "आगे चलकर अच्छी सैलरी मिलेगी",
            "ka": "ಅಂತಿಮವಾಗಿ ಉತ್ತಮ ವೇತನ ಸಿಗುತ್ತದೆ",
            "te": "తర్వాత మంచి జీతం వస్తుంది",
            "or": "ଶେଷରେ ଉତ୍ତମ ଦରମା ପାଇବେ",
            "as": "অৱশেষত ভাল দৰমহা পাব",
            "gu": "વધુ સારો પગાર મળશે આખરે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.afterReveal",
          "value": {
            "en": "Will have to start as a fresher",
            "hi": "फ्रेशर के तौर पर काम की शुरुआत करनी होगी",
            "ka": "ಫ್ರೆಶರ್ ಆಗಿ ಪ್ರಾರಂಭಿಸಬೇಕು",
            "te": "ఫ్రెషర్‌గా ప్రారంభించాల్సి ఉంటుంది",
            "or": "ନୂତନ ଭାବରେ ଆରମ୍ଭ କରିବାକୁ ପଡିବ",
            "as": "এজন নতুন হিচাপে আৰম্ভ কৰিব লাগিব",
            "gu": "શિખાઉ તરીકે નવી શરૂઆત કરવી પડશે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.afterReveal",
          "value": {
            "en": "Can work only in Eco-tourism",
            "hi": "केवल इको-टूरिजम में ही काम किया जा सकता है",
            "ka": "ಪರಿಸರ ಪ್ರವಾಸೋದ್ಯಮದಲ್ಲಿ ಮಾತ್ರ ಕೆಲಸ ಮಾಡಬಹುದು",
            "te": "పర్యావరణ పర్యాటకం లో మాత్రమే పని చేయవచ్చు",
            "or": "କେବଳ ଇକୋ-ଟୁରିଜିମ୍ ରେ କାମ କରିପାରିବ",
            "as": "কেৱল পৰিৱেশ-পৰ্যটনতহে কাম কৰিব পাৰে",
            "gu": "ઈકો ટુરીઝમમાં જ માત્ર કામ કરી શકે છે"
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "B4_3_5(D)",
            "hi": "B4_3_5(D)",
            "ka": "B4_3_5(D)",
            "te": "B4_3_5(D)",
            "or": "B4_3_5(D)",
            "as": "B4_3_5(D)",
            "gu": "B4_3_5(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Razia do?",
            "hi": "रजिया को क्या करना चाहिए?",
            "ka": "ರಝಿಯಾ ಏನು ಮಾಡಬೇಕು?",
            "te": "రజియా ఏం చేయాలి?",
            "or": "ରଜିଆ କ'ଣ କରିବା ଉଚିତ୍?",
            "as": "ৰাজিয়াই কি কৰা উচিত?",
            "gu": "રઝિયા ને શું કરવું જોઈએ?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Go back to her front desk role.",
            "hi": "अपने फ्रंट डेस्क के काम पर वापस जाना चाहिए।",
            "ka": "ಅವಳ ಮುಂಭಾಗದ ಮೇಜಿನ ಪಾತ್ರಕ್ಕೆ ಹಿಂತಿರುಗಿ.",
            "te": "ఆమె ఫ్రంట్ డెస్క్ పాత్రకి తిరిగి వెళ్లాలా.",
            "or": "ତାଙ୍କ ଫ୍ରଣ୍ଟ ଡେସ୍କ ଭୂମିକାକୁ ଫେରି ଯାଆନ୍ତୁ ।",
            "as": "তাইৰ ফ্ৰণ্ট ডেস্কৰ কামলৈ ঘূৰি যাওক।",
            "gu": "તેના ફ્રંટ ડેસ્ક પર પાછા જાઓ"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Start over again and apply for the new role.",
            "hi": "फिर से शुरुआत करनी चाहिए और नए रोल के लिए अप्लाई करना चाहिए।",
            "ka": "ಮತ್ತೆ ಪ್ರಾರಂಭಿಸಿ ಮತ್ತು ಹೊಸ ಪಾತ್ರಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ.",
            "te": "మళ్లీ ప్రారంభించి, కొత్త పాత్ర కోసం దరఖాస్తు చేసుకోవాలా.",
            "or": "ପୁନର୍ବାର ଆରମ୍ଭ କରନ୍ତୁ ଏବଂ ନୂତନ ଭୂମିକା ପାଇଁ ଆବେଦନ କରନ୍ତୁ ।",
            "as": "পুনৰ আৰম্ভ কৰক আৰু নতুন কামৰ বাবে এপ্লাই কৰক।",
            "gu": "ફરીથી શરૂઆત કરો અને નવા કામ માટે અરજી કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.tag",
          "value": {
            "en": "stability",
            "hi": "स्थिरता",
            "ka": "ಸ್ಥಿರತೆ",
            "te": "స్థిరత్వం",
            "or": "ସ୍ଥିରତା",
            "as": "স্থিৰতা",
            "gu": "સ્થિરતા"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.tag",
          "value": {
            "en": "Uncertainty",
            "hi": "अनिश्चितता",
            "ka": "ಅನಿಶ್ಚಿತತೆ",
            "te": "అనిశ్చితత్వం",
            "or": "ଅନିଶ୍ଚିତତା",
            "as": "অনিশ্চয়তা",
            "gu": "અનિશ્ચિતતા"
          }
        }
      ]
    },
    "scene33": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_1_1_Keep the front desk job",
            "hi": "फ्रंट डेस्क का जॉब जारी रखना",
            "ka": "B5_1_1_ಮುಂಭಾಗದ ಮೇಜಿನ ಕೆಲಸವನ್ನು ಇರಿಸಿ",
            "te": "B5_1_1_ఫ్రంట్ డెస్క్ ఉద్యోగం లో ఉండడం",
            "or": "B5_1_1_ ଫ୍ରଣ୍ଟ ଡେସ୍କ ଚାକିରି ଜାରି ରଖନ୍ତୁ",
            "as": "B5_1_1_ফ্ৰণ্ট ডেস্কৰ কাম কৰা",
            "gu": "B5_1_1_ફ્રન્ટ ડેસ્ક  નોકરી રાખો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Raiza returns to her front desk job. She liked the break and being able to try something new. \nBut she prefers her job at the front desk.",
            "hi": "रजिया फिर से पाने फ्रंट डेस्क के काम पर वापस आती है। उसे यह ब्रेक और कुछ नया ट्राय करके अच्छा लगा। \n लेकिन उसे अपना फ्रंट डेस्क का जॉब ही पसंद है।",
            "ka": "\"ರಝಿಯಾ ತನ್ನ ಮುಂಭಾಗದ ಮೇಜಿನ ಕೆಲಸಕ್ಕೆ ಮರಳುತ್ತಾಳೆ. ಅವಳು ವಿರಾಮವನ್ನು ಇಷ್ಟಪಟ್ಟಳು ಮತ್ತು ಹೊಸದನ್ನು ಪ್ರಯತ್ನಿಸಲು ಸಾಧ್ಯವಾಯಿತು. ಆದರೆ ಅವಳು ಫ್ರಂಟ್ ಡೆಸ್ಕ್‌ನಲ್ಲಿ ತನ್ನ ಕೆಲಸವನ್ನು ಆದ್ಯತೆ ನೀಡುತ್ತಾಳೆ.",
            "te": "రైజా తన ఫ్రంట్ డెస్క్ ఉద్యోగానికి తిరిగి వచ్చింది. ఆమెకు విరామం మరియు కొత్తదాన్ని ప్రయత్నించడం నచ్చింది.\n కానీ ఆమె ముందు డెస్క్‌లో తన ఉద్యోగాన్ని ఇష్టపడుతుంది.",
            "or": "ରଜିଆ ତାଙ୍କ ଫ୍ରଣ୍ଟ ଡେସ୍କ ଚାକିରିକୁ ଫେରିଆସନ୍ତି । ସେ ଅବକାଶକୁ ପସନ୍ଦ କଲେ ଏବଂ କିଛି ନୂଆ ଚେଷ୍ଟା କରିବାକୁ ସକ୍ଷମ ହୋଇଥିଲେ । \n କିନ୍ତୁ ସେ ଫ୍ରଣ୍ଟ ଡେସ୍କରେ ଚାକିରି ପସନ୍ଦ କରନ୍ତି ।",
            "as": "ৰাজিয়া তাইৰ ফ্ৰণ্ট ডেস্কৰ কামলৈ ঘূৰি আহিছে। তাই বিৰতিটো ভাল পাইছিল আৰু কিবা নতুন চেষ্টা কৰিবলৈ সক্ষম হৈছিল। \n \n কিন্তু তাই ফ্ৰণ্ট ডেস্কত তাইৰ কামটো পছন্দ কৰে।",
            "gu": "રાયઝાને તેનું ફ્રન્ટ ડેસ્ક કામ પર પાછી આવે છે. તેને વિરામ ગમ્યો અને કંઈક નવું કરવાનો પ્રયાસ કર્યો.\nપણ તે ફ્રન્ટ ડેસ્ક માટે તેની નોકરી પસંદ કરે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_1_2",
            "hi": "B5_1_2",
            "ka": "B5_1_2",
            "te": "B5_1_2",
            "or": "B5_1_2",
            "as": "B5_1_2",
            "gu": "B5_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "At the front desk she learns a lot of different things like interacting with the guests, basic accounting and coordination.",
            "hi": "फ्रंट डेस्क पर उसने कई बातें सीखी जैसे कि मेहमानों के साथ बातचीत करना, मुलभुत अकाउंटिंग और कोऑर्डिनेशन।",
            "ka": "ಮುಂಭಾಗದ ಮೇಜಿನ ಬಳಿ ಅವಳು ಅತಿಥಿಗಳೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸುವುದು, ಮೂಲ ಲೆಕ್ಕಪತ್ರ ನಿರ್ವಹಣೆ ಮತ್ತು ಸಮನ್ವಯದಂತಹ ವಿವಿಧ ವಿಷಯಗಳನ್ನು ಕಲಿಯುತ್ತಾಳೆ.",
            "te": "ఫ్రంట్ డెస్క్ వద్ద ఆమె అతిథులతో సంభాషించడం,ప్రాథమిక అకౌంటింగ్ మరియు సమన్వయం వంటి చాలా విభిన్న విషయాలను నేర్చుకుంటుంది",
            "or": "ଫ୍ରଣ୍ଟ ଡେସ୍କରେ ସେ ଅତିଥିମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରିବା, ମୌଳିକ ଆକାଉଣ୍ଟିଂ ଏବଂ ସମନ୍ୱୟ ପରି ଅନେକ ଭିନ୍ନ ଜିନିଷ ଶିଖନ୍ତି ।",
            "as": "ফ্ৰণ্ট ডেস্কত তাই অতিথিৰ সৈতে কথা পাতে, সাধাৰণ একাউণ্টিং আৰু সমন্বয়ৰ দৰে বহুতো বিভিন্ন ধৰণৰ কথা শিকে।",
            "gu": "ફ્રન્ટ ડેસ્ક પર તે મહેમાનો સાથે વાત કેવી રીતે કરવી, મૂળભૂત હિસાબ અને સંકલન જેવી ઘણી અલગ અલગ વસ્તુઓ શીખે છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_2_1",
            "hi": "B5_2_1",
            "ka": "B5_2_1",
            "te": "B5_2_1",
            "or": "ଫ୍ରଣ୍ଟ ଡେସ୍କରେ ସେ ଅତିଥିମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରିବା, ମୌଳିକ ଆକାଉଣ୍ଟିଂ ଏବଂ ସମନ୍ୱୟ ପରି ଅନେକ ଭିନ୍ନ ଜିନିଷ ଶିଖନ୍ତି ।",
            "as": "B5_2_1",
            "gu": "B5_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia feels this experience is helpful as she can look for jobs outside the hospitality industry as well.",
            "hi": "रजिया को लगता है कि यह अनुभव बहुत मददगार है क्योंकि वह होस्पिटेलीटी उद्योग से बाहर कोई अन्य जॉब भी ढूंढ सकती है।",
            "ka": "ರಝಿಯಾ ಅವಳು ಆತಿಥ್ಯ ಉದ್ಯಮದ ಹೊರಗಿನ ಉದ್ಯೋಗಗಳನ್ನು ಹುಡುಕಬಹುದಾದ್ದರಿಂದ ಈ ಅನುಭವವು ಸಹಾಯಕವಾಗಿದೆಯೆಂದು ಭಾವಿಸುತ್ತಾಳೆ.",
            "te": "తాను ఆతిథ్య పరిశ్రమ బయట కూడా ఉద్యోగాల కోసం వెతకవచ్చు కాబట్టి ఈ అనుభవం సహాయకరంగా ఉందని రజియా భావించింది",
            "or": "ରଜିଆ ଅନୁଭବ କରନ୍ତି ଯେ ଏହି ଅଭିଜ୍ଞତା ସହାୟକ ଅଟେ କାରଣ ସେ ଆତିଥ୍ୟ ଶିଳ୍ପ ବାହାରେ ମଧ୍ୟ ଚାକିରି ଖୋଜିପାରିବେ ।",
            "as": "ৰাজিয়াই অনুভৱ কৰে যে এই অভিজ্ঞতাবোৰে তাইক আতিথ্য উদ্যোগৰ বাহিৰত চাকৰি বিচাৰাত সহায় কৰিব ।",
            "gu": "રઝિયાને લાગે છે કે આ અનુભવ તેને કામમાં આવશે કારણ કે તે મહેમાનગીરી ઉદ્યોગની બહાર પણ નોકરીઓ શોધી શકે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B5_2_2",
            "hi": "B5_2_2",
            "ka": "B5_2_2",
            "te": "B5_2_2",
            "or": "B5_2_2",
            "as": "B5_2_2",
            "gu": "B5_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She continues working in the resort for a few years and gets promoted to a lobby manager. She feels very proud of herself for sticking to a career and making the most of it.",
            "hi": "वह कुछ और साल रिसोर्ट में काम करना जारी रखती है और फिर लॉबी मैनेजर के पद पर उसका प्रमोशन होता है। उसे खुद पर गर्व है कि उसने करियर करने का रास्ता चुना और इसके लिए मेहनत की।",
            "ka": "ಅವಳು ಕೆಲವು ವರ್ಷಗಳು ರೆಸಾರ್ಟ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಮುಂದುವರೆಸುತ್ತಾಳೆ ಮತ್ತು ಲಾಬಿ ಮ್ಯಾನೇಜರ್ ಆಗಿ ಬಡ್ತಿ ಪಡೆಯುತ್ತಾಳೆ. ವೃತ್ತಿಜೀವನಕ್ಕೆ ಅಂಟಿಕೊಂಡಿರುವುದಕ್ಕೆ ಮತ್ತು ಅದರ ಸದುಪಯೋಗಕ್ಕಾಗಿ ಅವಳು ತನ್ನ ಬಗ್ಗೆ ತುಂಬಾ ಹೆಮ್ಮೆಪಡುತ್ತಾಳೆ.",
            "te": "ఆమె కొన్నేళ్లుగా రిసార్ట్‌లో పని చేస్తూ లాబీ నిర్వాహకురాలిగా పదోన్నతి పొందింది. కెరీర్‌కు కట్టుబడి ఉన్నందుకు మరియు దానిని సద్వినియోగం చేసుకున్నందుకు ఆమె చాలా గర్వంగా ఉంది.",
            "or": "ସେ କିଛି ବର୍ଷ ଧରି ରିସର୍ଟରେ କାମ ଜାରି ରଖିଛନ୍ତି ଏବଂ ଏକ ଲବି ମ୍ୟାନେଜରକୁ ପଦୋନ୍ନତି ପାଇଛନ୍ତି । କ୍ୟାରିୟରରେ ଲାଗି ରହିବା ଏବଂ ଏହାର ଅଧିକ ଉପଯୋଗ କରିଥିବାରୁ ସେ ନିଜ ପାଇଁ ବହୁତ ଗର୍ବିତ ଅନୁଭବ କରନ୍ତି ।",
            "as": "তাই কেইবছৰমানৰ পৰা ৰিজৰ্টত কাম কৰি আছে আৰু লবি মেনেজাৰলৈ পদোন্নতি লাভ কৰিছে। তাই কেৰিয়াৰত লাগি থকা আৰু ইয়াৰ সৰ্বাধিক ব্যৱহাৰ কৰাৰ বাবে নিজকে লৈ বহুত গৌৰৱান্বিত অনুভৱ কৰে।",
            "gu": "તે રિસોર્ટમાં થોડા વધુ વર્ષો સુધી કામ કરે છે અને તેને લોબી મેનેજર તરીકે બઢતી આપવામાં આવે છે. તેણી પોતાની કારકિર્દીને વળગી રહેવા અને તેનો પૂરો લાભ ઉઠાવવા બદલ પોતાના ઉપર ખૂબ ગર્વ અનુભવે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ2",
            "hi": "MCQ2",
            "ka": "MCQ2",
            "te": "MCQ2",
            "or": "MCQ2",
            "as": "MCQ2",
            "gu": "MCQ2"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia does an internship at a company that later offers her a job. Internships help us understand the everyday details of jobs and industries. Select which of the following statements about internships are true.",
            "hi": "रजिया ने कंपनी में इन्टर्नशिप की जिसने आगे चलकर उसे जॉब का ऑफर दिया। इन्टर्नशिप की मदद से हम जॉब और उद्योग के हर दिन के काम को विस्तार से समझ सकते हैं। इन्टर्नशिप के बारे में निम्नलिखित में से कौन सा विधान सही है वह चुनें।",
            "ka": "ರಝಿಯಾ ಕಂಪನಿಯಲ್ಲಿ ಇಂಟರ್ನ್‌ಶಿಪ್ ಮಾಡುತ್ತಾಳೆ, ಅದು ನಂತರ ಅವಳಿಗೆ ಕೆಲಸ ನೀಡುತ್ತದೆ. ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳು ಉದ್ಯೋಗಗಳು ಮತ್ತು ಕೈಗಾರಿಕೆಗಳ ದೈನಂದಿನ ವಿವರಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳ ಕುರಿತು ಈ ಕೆಳಗಿನ ಯಾವ ಹೇಳಿಕೆಗಳು ನಿಜವೆಂದು ಆಯ್ಕೆಮಾಡಿ.",
            "te": "రజియా ఒక కంపెనీలో ఇంటర్న్‌షిప్ చేస్తుంది, అది ఆమెకు ఉద్యోగం ఇచ్చింది. ఉద్యోగాలు మరియు పరిశ్రమల రోజువారీ వివరాలను అర్థం చేసుకోవడానికి ఇంటర్న్‌షిప్‌లు మాకు సహాయపడతాయి. ఇంటర్న్‌షిప్‌ల గురించిన కింది స్టేట్‌మెంట్‌లలో ఏది నిజమో ఎంచుకోండి.",
            "or": "ରଜିଆ ଏକ କମ୍ପାନୀରେ ଏକ ଇଣ୍ଟର୍ନସିପ୍ କରନ୍ତି ଯାହା ପରେ ତାଙ୍କୁ ନିଯୁକ୍ତି ପ୍ରଦାନ କରେ । ଇଣ୍ଟର୍ନସିପ୍ ଆମକୁ ଚାକିରି ଏବଂ ଶିଳ୍ପର ଦୈନନ୍ଦିନ ବିବରଣୀ ବୁଝିବାରେ ସାହାଯ୍ୟ କରେ । ଇଣ୍ଟର୍ନସିପ୍ ବିଷୟରେ ନିମ୍ନଲିଖିତ ମଧ୍ୟରୁ କେଉଁ ଉକ୍ତି ଠିକ ତାହା ଚୟନ କରନ୍ତୁ ।",
            "as": "ৰাজিয়াই এটা কোম্পানীত ইন্টাৰ্ণশ্বিপ কৰে যিটোৱে পিছত তাইক এটা চাকৰি প্ৰদান কৰে। ইন্টাৰ্ণশ্বিপে আমাক চাকৰি আৰু উদ্যোগৰ দৈনন্দিন বিৱৰণ বুজাত সহায় কৰে। ইণ্টাৰ্নশ্বিপৰ বিষয়ে নিম্নলিখিত কোনটো মন্তব্য সঁচা বাছনি কৰা।",
            "gu": "રઝિયા એક કંપનીમાં ઇન્ટર્નશિપ કરે છે એજ કંપની તેને નોકરી માટે આવકારે છે. ઇન્ટર્નશીપના લીધે અમને નોકરીઓ અને ઉદ્યોગોના લીધે રોજની વિગતો સમજવામાં મદદ મળે છે. નીચેનામાંથી કયું નિવેદન સાચું છે ઇન્ટર્નશીપ વિશે તે પસંદ કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Internships and apprenticeships are the same.",
            "hi": "इन्टर्नशिप और अप्रेंटिसशिप समान हैं।",
            "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಮತ್ತು ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್ ಒಂದೇ.",
            "te": "ఇంటర్న్‌షిప్‌లు మరియు అప్రెంటిస్‌షిప్‌లు ఒకే విధంగా ఉంటాయి.",
            "or": "ଇଣ୍ଟର୍ନସିପ୍ ଏବଂ ଆପ୍ରେଣ୍ଟିସିପ୍ ସମାନ ।",
            "as": "ইণ্টাৰ্নশ্বিপ আৰু এপ্ৰেণ্টিছশ্বিপ একে।",
            "gu": "ઇન્ટર્નશિપ અને એપ્રેન્ટિસશિપ બંને એક સરખા છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "An internship can help you know more about the working environment of an industry",
            "hi": "इन्टर्नशिप आपको उद्योग के काम करने के माहौल को अच्छे से समझने में मदद करती है।",
            "ka": "ಉದ್ಯಮದ ಕೆಲಸದ ವಾತಾವರಣದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಇಂಟರ್ನ್‌ಶಿಪ್ ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ",
            "te": "పరిశ్రమ యొక్క పని వాతావరణం గురించి మరింత తెలుసుకోవడానికి ఇంటర్న్‌షిప్ మీకు సహాయపడుతుంది",
            "or": "ଏକ ଇଣ୍ଟର୍ନସିପ୍ ଆପଣଙ୍କୁ ଏକ ଶିଳ୍ପର କାର୍ଯ୍ୟ ପରିବେଶ ବିଷୟରେ ଅଧିକ ଜାଣିବାରେ ସାହାଯ୍ୟ କରିପାରେ",
            "as": "এটা ইন্টাৰ্ণশ্বিপে তোমাক উদ্যোগ এটাৰ কামৰ পৰিৱেশৰ বিষয়ে অধিক জনাত সহায় কৰিব পাৰে",
            "gu": "ઇન્ટર્નશિપના લીધે તમને ઉદ્યોગના કાર્યકારી વાતાવરણ વિશે વધુ જાણવામાં મદદ મળી શકે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "An internship is a one-year long job that helps you learn more about an industry",
            "hi": "इन्टर्नशिप एक-वर्ष का जॉब होता है जो आपको उद्योग के बारे में अधिक जानकारी प्रापर करने में मदद करता है।",
            "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಒಂದು ವರ್ಷದ ದೀರ್ಘಾವಧಿಯ ಕೆಲಸವಾಗಿದ್ದು ಅದು ಉದ್ಯಮದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ",
            "te": "ఇంటర్న్‌షిప్ అనేది ఒక సంవత్సరం సుదీర్ఘ ఉద్యోగం, ఇది పరిశ్రమ గురించి మరింత తెలుసుకోవడానికి మీకు సహాయపడుతుంది",
            "or": "ଏକ ଇଣ୍ଟର୍ନସିପ୍ ହେଉଛି ଏକ ବର୍ଷର ଲମ୍ବା ଚାକିରି ଯାହା ଆପଣଙ୍କୁ ଏକ ଶିଳ୍ପ ବିଷୟରେ ଅଧିକ ଜାଣିବାରେ ସାହାଯ୍ୟ କରେ ।",
            "as": "ইণ্টাৰ্নশ্বিপ হৈছে এক বছৰৰ দীঘলীয়া কাম যি তোমাক উদ্যোগ এটাৰ বিষয়ে অধিক শিকাত সহায় কৰে",
            "gu": "ઇન્ટર્નશિપ એ એક વર્ષની લાંબી નોકરી છે જે તમને મદદ કરશે જે ઉદ્યોગ વિશે વધુ જાણવામાં કામ આવશે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Think again! They are similar but not the same. Unlike internships, they can extend from a few months to a couple of years.",
            "hi": "फिर से विचार करें! वे एक जैसे तो हैं लेकिन समान नहीं हैं। इन्टर्नशिप के विपरीत, इसे कुछ महीनों से लेकर कुछ सालों तक बढ़ाया जा सकता है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಅವು ಹೋಲುತ್ತವೆ ಆದರೆ ಒಂದೇ ಅಲ್ಲ. ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳಿಗಿಂತ ಭಿನ್ನವಾಗಿ, ಅವರು ಕೆಲವು ತಿಂಗಳುಗಳಿಂದ ಒಂದೆರಡು ವರ್ಷಗಳವರೆಗೆ ವಿಸ್ತರಿಸಬಹುದು.",
            "te": "మరలా ఆలోచించు! అవి ఒకేలా ఉంటాయి కానీ ఒకేలా ఉండవు. ఇంటర్న్‌షిప్‌ల మాదిరిగా కాకుండా, అవి కొన్ని నెలల నుండి కొన్ని సంవత్సరాల వరకు పొడిగించవచ్చు.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ସେଗୁଡିକ ସମାନ ପରି କିନ୍ତୁ ସମାନ ନୁହଁନ୍ତି । ଇଣ୍ଟର୍ନସିପ୍ ପରି, ସେଗୁଡିକ କିଛି ମାସରୁ ଦୁଇ ବର୍ଷ ପର୍ଯ୍ୟନ୍ତ ହୋଇପାରେ ।",
            "as": "আকৌ চিন্তা কৰা! সেইবোৰ একে ধৰণৰ কিন্তু সম্পূৰ্ণ একে নহয়। ইণ্টাৰ্নশ্বিপৰ বিপৰীতে, সেইবোৰ কেইমাহমানৰ পৰা কেইবছৰমানলৈ কেতিয়াবা প্ৰসাৰিত হ'ব পাৰে।",
            "gu": "વિચારો ફરીથી! તેઓ એક સરખા છે પરંતુ એક જેવા નથી. ઇન્ટર્નશીપથી વિપરીત, તેઓ થોડા મહિનાથી લઈને બે વર્ષ સુધી લંબાવી હોય સકે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "शाबाश!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "గొప్ప పని!",
            "or": "ବହୁତ ଭଲ!",
            "as": "ভাল কাম!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! Internships are short term work opportunities usually for a few weeks or months.",
            "hi": "फिर से विचार करें! इन्टर्नशिप कुछ समय के लिए काम करने के अवसर होते हैं जो कुछ हफ्तों से लेकर कुछ महीनों के होते हैं।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳು ಸಾಮಾನ್ಯವಾಗಿ ಕೆಲವು ವಾರಗಳು ಅಥವಾ ತಿಂಗಳುಗಳವರೆಗೆ ಅಲ್ಪಾವಧಿಯ ಕೆಲಸದ ಅವಕಾಶಗಳಾಗಿವೆ.",
            "te": "మరలా ఆలోచించు! ఇంటర్న్‌షిప్‌లు సాధారణంగా కొన్ని వారాలు లేదా నెలలపాటు స్వల్పకాలిక ఉద్యోగ అవకాశాలు.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ଇଣ୍ଟର୍ନସିପ୍ ସାଧାରଣତଃ କିଛି ସପ୍ତାହ କିମ୍ବା ମାସ ପାଇଁ ସ୍ୱଳ୍ପ କାଳୀନ କାର୍ଯ୍ୟ ସୁଯୋଗ ।",
            "as": "আকৌ চিন্তা কৰা! ইণ্টাৰ্নশ্বিপ হৈছে সাধাৰণতে কেইসপ্তাহ বা মাহৰ বাবে কম সময়ৰ বাবে কামৰ সুযোগ।",
            "gu": "વિચારો ફરીથી! તેઓ એક સરખા છે પરંતુ એક જેવા નથી. ઇન્ટર્નશીપથી અલગ છે, તેઓ થોડા મહિનાથી લઈને બે વર્ષ સુધી વધારે હોય સકે છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ3",
            "hi": "MCQ3",
            "ka": "MCQ3",
            "te": "MCQ3",
            "or": "MCQ3",
            "as": "MCQ3",
            "gu": "MCQ3"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia studies the college brochure to identify which courses have the most jobs available post-training. Knowing trends helps you plan your career and decide what to learn to be up to date. Select which of the \nfollowing statements about trends is true:",
            "hi": "रजिया कॉलेज के ब्रोशर को पढ़ती है और पता करती है कि कौन सा कोर्स करने ज्यादा जॉब अवसर उपलब्ध हैं।रुझान के बारे में जानने से आपको अपने करियर के बारे में प्लान करने में और अपडेट रहने के लिए क्या पढ़ना चाहिए यह जानने में मदद मिलती है। \n रुझान के बारे में निम्नलिखित में से कौन सा विधान सही है वह चुनें:",
            "ka": "ತರಬೇತಿಯ ನಂತರ ಯಾವ ಕೋರ್ಸ್‌ಗಳಲ್ಲಿ ಹೆಚ್ಚು ಉದ್ಯೋಗಗಳು ಲಭ್ಯವಿವೆ ಎಂಬುದನ್ನು ಗುರುತಿಸಲು ರಝಿಯಾ ಕಾಲೇಜು ಕರಪತ್ರವನ್ನು ಅಧ್ಯಯನ ಮಾಡುತ್ತಾಳೆ. ಟ್ರೆಂಡ್‌ಗಳನ್ನು ತಿಳಿದುಕೊಳ್ಳುವುದು ನಿಮ್ಮ ವೃತ್ತಿಯನ್ನು ಯೋಜಿಸಲು ಮತ್ತು ನವೀಕೃತವಾಗಿರಲು ಏನನ್ನು ಕಲಿಯಬೇಕೆಂದು ನಿರ್ಧರಿಸಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಈ ಕೆಳಗಿನ ಪ್ರವೃತ್ತಿಗಳ ಬಗ್ಗೆ ಯಾವ ಹೇಳಿಕೆಗಳು ನಿಜ ಎಂದು ಆಯ್ಕೆ ಮಾಡಿ\n  :",
            "te": "\"శిక్షణా తరువాత ఏయే కోర్సుల్లో అత్యధిక ఉద్యోగాలు అందుబాటులో ఉన్నాయో గుర్తించడానికి రజియా కళాశాల బ్రోచర్‌ను అధ్యయనం చేస్తుంది. ట్రెండ్‌లను తెలుసుకోవడం మీ కెరీర్‌ప్రణాళిక చేసుకోవడంలో మరియు తాజాగా ఏమి నేర్చుకోవాలో నిర్ణయించుకోవడంలో మీకు సహాయపడుతుంది. వాటిలో దేనిని ఎంచుకోండి\n ట్రెండ్‌ల గురించి కింది ప్రకటనలు నిజం:\"",
            "or": "କେଉଁ ପାଠ୍ୟକ୍ରମରେ ତାଲିମ ପରେ ସର୍ବାଧିକ ଚାକିରି ଉପଲବ୍ଧ ତାହା ଚିହ୍ନଟ କରିବା ପାଇଁ ରଜିଆ କଲେଜ ବ୍ରୋସର ଅଧ୍ୟୟନ କରନ୍ତି । ଟ୍ରେଣ୍ଡ ଜାଣିବା ଆପଣଙ୍କୁ ଆପଣଙ୍କ କ୍ୟାରିୟର ଯୋଜନା କରିବାରେ ଏବଂ ଅପ୍ ଟୁ ଡେଟ୍ ହେବା ଶିଖିବାରେ ସାହାଯ୍ୟ କରେ । ଟ୍ରେଣ୍ଡଗୁଡିକ ବିଷୟରେ ନିମ୍ନଲିଖିତ ଉକ୍ତିଗୁଡିକ ମଧ୍ୟରୁ କେଉଁଟି ଠିକ ଚୟନ କରନ୍ତୁ:",
            "as": "প্ৰশিক্ষণৰ পিছত কোনবোৰ পাঠ্যক্ৰমত আটাইতকৈ বেছি চাকৰি পাব পাৰি তাক জানিবলৈ ৰাজিয়াই কলেজৰ প্ৰচাৰ প্ৰত্ৰিকাখন পঢ়ে। ট্ৰেণ্ড জনাটোৱে তোমাক তোমাৰ কেৰিয়াৰৰ পৰিকল্পনা কৰাত সহায় কৰে আৰু আপ টু ডেট হ'বলৈ কি শিকিব লাগে সেইটো জনাত সহায় কৰে। \n ট্ৰেণ্ডৰ বিষয়ে নিম্নলিখিত মন্তব্যবোৰৰ কোনটো সঁচা বাছনি কৰা:",
            "gu": "તાલીમ પછી કયા અભ્યાસક્રમોમાં સૌથી વધુ નોકરીઓ ઉપલબ્ધ છે તે ઓળખવા માટે રઝિયા કોલેજ બ્રોશરનો અભ્યાસ કરે છે.  વલણો જાણવાથી તમને તમારી કારકિર્દીની યોજના બનાવવામાં અને અદ્યતન બનવા માટે શું શીખવું તે નક્કી કરવામાં મદદ મળે છે.  વલણો વિશે નીચેનામાંથી કયું નિવેદન સાચું છે તે પસંદ કરો:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Some trends can be short term and create only a temporary increase in jobs or skills required.",
            "hi": "कुछ रुझान अल्पकालिक होते हैं और बस कुछ सी समय के लिए जॉब निर्माण करते हैं या इस कौशल की आवश्यकता होती है।",
            "ka": "ಕೆಲವು ಪ್ರವೃತ್ತಿಗಳು ಅಲ್ಪಾವಧಿಯದ್ದಾಗಿರಬಹುದು ಮತ್ತು ಅಗತ್ಯವಿರುವ ಉದ್ಯೋಗಗಳು ಅಥವಾ ಕೌಶಲ್ಯಗಳಲ್ಲಿ ತಾತ್ಕಾಲಿಕ ಹೆಚ್ಚಳವನ್ನು ಮಾತ್ರ ರಚಿಸಬಹುದು.",
            "te": "కొన్ని ట్రెండ్‌లు స్వల్పకాలికంగా ఉంటాయి మరియు అవసరమైన ఉద్యోగాలు లేదా నైపుణ్యాలలో తాత్కాలిక పెరుగుదలను మాత్రమే సృష్టించవచ్చు.",
            "or": "କିଛି ଟ୍ରେଣ୍ଡ ସ୍ୱଳ୍ପ କାଳୀନ ହୋଇପାରେ ଏବଂ ଆବଶ୍ୟକ ଚାକିରି କିମ୍ବା ଦକ୍ଷତାରେ କେବଳ ଅସ୍ଥାୟୀ ବୃଦ୍ଧି ସୃଷ୍ଟି କରିପାରେ ।",
            "as": "কিছুমান ট্ৰেণ্ড কম সময়ৰ বাবে হ'ব পাৰে আৰু কেৱল অস্থায়ী সময়ৰ বাবে চাকৰি বা দক্ষতাৰ সৃষ্টি কৰিব পাৰে।",
            "gu": "કેટલાક વલણો ટૂંકા સમયના હોઈ શકે છે અને નોકરીઓ અથવા આવશ્યક કૌશલ્યોમાં માત્ર થોડા સમય માટે વધારો કરી શકે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "To know the trend about an industry or area of work you need to do research & networking.",
            "hi": "किसी उद्योग या कार्यक्षेत्र से संबंधित रुझान के बारे में जानने के लिए आपको रिसर्च और नेटवर्किंग करने की आवश्यकता हैं।",
            "ka": "ಉದ್ಯಮ ಅಥವಾ ಕೆಲಸದ ಕ್ಷೇತ್ರದ ಪ್ರವೃತ್ತಿಯನ್ನು ತಿಳಿಯಲು ನೀವು ಸಂಶೋಧನೆ ಮತ್ತು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮಾಡಬೇಕಾಗಿದೆ.",
            "te": "పరిశ్రమ లేదా పని ప్రాంతం గురించిన ట్రెండ్‌ని తెలుసుకోవడానికి మీరు పరిశోధన & నెట్‌వర్కింగ్ చేయాలి.",
            "or": "ଏକ ଶିଳ୍ପ କିମ୍ବା କାର୍ଯ୍ୟକ୍ଷେତ୍ର ବିଷୟରେ ଟ୍ରେଣ୍ଡ ଜାଣିବା ପାଇଁ ଆପଣଙ୍କୁ ଅନୁସନ୍ଧାନ ଏବଂ ନେଟୱାର୍କିଂ କରିବା ଆବଶ୍ୟକ ।",
            "as": "উদ্যোগ বা কামৰ ক্ষেত্ৰৰ বিষয়ে ট্ৰেণ্ড জানিবলৈ তুমি গৱেষণা আৰু নেটৱৰ্কিং কৰিব লাগিব।",
            "gu": "ઉદ્યોગ અથવા કાર્ય ક્ષેત્ર વિશેના વલણને જાણવા માટે તમારે સંશોધન અને નેટવર્કિંગ કરવાની જરૂર છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "It is always a good decision to follow the trend when choosing a course to study",
            "hi": "अध्ययन के लिए कोर्स का चुनाव करते समय रुझान को फ़ॉलो करना हमेशा सही निर्णय होता है।",
            "ka": "ಅಧ್ಯಯನ ಮಾಡಲು ಕೋರ್ಸ್ ಅನ್ನು ಆಯ್ಕೆಮಾಡುವಾಗ ಯಾವಾಗಲೂ ಪ್ರವೃತ್ತಿಯನ್ನು ಅನುಸರಿಸುವುದು ಉತ್ತಮ ನಿರ್ಧಾರ",
            "te": "చదువుకోవడానికి కోర్సును ఎంచుకున్నప్పుడు ట్రెండ్‌ను అనుసరించడం ఎల్లప్పుడూ మంచి నిర్ణయం",
            "or": "ଅଧ୍ୟୟନ ପାଇଁ ଏକ ପାଠ୍ୟକ୍ରମ ବାଛିବା ସମୟରେ ଟ୍ରେଣ୍ଡ ଅନୁସରଣ କରିବା ସର୍ବଦା ଏକ ଭଲ ନିଷ୍ପତ୍ତି ।",
            "as": "অধ্যয়নৰ বাবে পাঠ্যক্ৰম এটা বাছনি কৰোঁতে ট্ৰেণ্ড অনুসৰণ কৰাটো সদায়ে এটা ভাল সিদ্ধান্ত",
            "gu": "એ સારો નિર્ણય છે કે જો અભ્યાસ માટે અભ્યાસક્રમ પસંદ કરતી વખતે વલણને અનુસરવું જોઇયે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Correct.",
            "hi": "सही.",
            "ka": "ಸರಿ.",
            "te": "సరైనది.",
            "or": "ଠିକ୍‌।",
            "as": "শুদ্ধ।",
            "gu": "સાચું"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Correct.",
            "hi": "सही.",
            "ka": "ಸರಿ.",
            "te": "సరైనది.",
            "or": "ଠିକ୍‌।",
            "as": "শুদ্ধ",
            "gu": "સાચું"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Oops! It is also important to review your decision based on your abilities & interests.",
            "hi": "ओहो! अपनी क्षमता और दिलचस्पी के अनुसार अपने निर्णय को परखना महत्वपूर्ण है।",
            "ka": "ಅಯ್ಯೋ! ನಿಮ್ಮ ಸಾಮರ್ಥ್ಯಗಳು ಮತ್ತು ಆಸಕ್ತಿಗಳ ಆಧಾರದ ಮೇಲೆ ನಿಮ್ಮ ನಿರ್ಧಾರವನ್ನು ಪರಿಶೀಲಿಸುವುದು ಸಹ ಮುಖ್ಯವಾಗಿದೆ.",
            "te": "అయ్యో! మీ సామర్థ్యాలు మరియు ఆసక్తుల ఆధారంగా మీ నిర్ణయాన్ని సమీక్షించడం కూడా ముఖ్యం.",
            "or": "ଓଃ! ତୁମର ଦକ୍ଷତା ଏବଂ ରୁଚି ଉପରେ ଆଧାର କରି ତୁମର ନିଷ୍ପତ୍ତିର ସମୀକ୍ଷା କରିବା ମଧ୍ୟ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ।",
            "as": "উফ! আপোনাৰ সামৰ্থ্য আৰু স্বাৰ্থৰ ওপৰত ভিত্তি কৰি আপোনাৰ সিদ্ধান্ত আকৌ পৰীক্ষা কৰাটোও জৰুৰী।",
            "gu": "અરે! એ પણ જોવું જોઈએ કે તમારી ક્ષમતાઓ અને રુચિઓના આધારે તમારા નિર્ણયને નિહાળવું મહત્વપૂર્ણ છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Lobby Manager",
            "hi": "लॉबी मैनेजर",
            "ka": "**THREAD_END**_ ಲಾಬಿ ಮ್ಯಾನೇಜರ್",
            "te": "**THREAD_END**_లాబీ నిర్వాహకుడు",
            "or": "**THREAD_END**_ଲବି ମ୍ୟାନେଜର",
            "as": "**থ্ৰেড এণ্ড**_লবি মেনেজাৰ",
            "gu": "**THREAD_END**_લોબી મેનેજર"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Razia that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने रजिया के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँची है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकती है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n \n ನೀವು ರಝಿಯಾಗಾಗಿ ಮಾಡಿದ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు రజియా కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ! \n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି! \n ଆପଣ ରଜିଆ ପାଇଁ ନିଷ୍ପତ୍ତିଗୁଡିକ ପସନ୍ଦ କରିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତିକୁ ଆଗେଇ ନେଇଛି । ଆପଣ କରୁଥିବା ପସନ୍ଦଗୁଡିକ ଉପରେ ଆଧାର କରି ବିଭିନ୍ନ ପ୍ରକାରର ଶେଷ ଅଛି । ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହାଁନ୍ତି କି?",
            "as": "অভিনন্দন!\n তুমি এটা যাত্ৰা সমাপ্ত কৰিলা!\n \n তুমি ৰাজিয়াৰ বাবে বাছনি কৰিছা যাৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত আধাৰিত কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ জানিব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે એક સફર પૂરી કરી છે!\n\nતમે રઝિયા માટે જે પસંદગીઓ કરી છે જેના કારણે આ સંભવિત અંત આવ્યો છે. તમે જે પસંદગી કરો છો તેના આધારે વિવિધ અંત છે. શું તમે બીજા દ્રશ્યો શોધવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ର ଉପରେ ନଜର ପକାନ୍ତୁ",
            "as": "মানচিত্ৰখন চোৱা",
            "gu": "ચાલો નકશા તરફ નજર નાખીએ"
          }
        }
      ]
    },
    "scene34": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_1_1_Apply for the new role",
            "hi": "नए रोल के लिए अप्लाई करना",
            "ka": "B6_1_1_ಹೊಸ ಪಾತ್ರಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ",
            "te": "B6_1_1_కొత్త పాత్ర కోసం దరఖాస్తు చేసుకోండి",
            "or": "B6_1_1_ ନୂତନ ଭୂମିକା ପାଇଁ ଅବଦାନ କରନ୍ତୁ",
            "as": "B6_1_1_নতুন চৰিত্ৰটোৰ বাবে এপ্লাই",
            "gu": "B6_1_1_નવી ભૂમિકા માટે અરજી કરો"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia is thrilled about the opportunity. She has 2 weeks to prepare for the interview. She needs to study but is not sure where to start.",
            "hi": "रजिया इस अवसर को लेकर उत्साहित है। उसके पास इन्टरव्यू की तैयारी करने के लिए 2 हफ्ते है। उसे पढ़ाई करनी होगी लेकिन उसे समझ नहीं आ रहा कि कहाँ से शुरू किया जाएं",
            "ka": "ಈ ಅವಕಾಶದ ಬಗ್ಗೆ ರಝಿಯಾ ಥ್ರಿಲ್ ಆಗಿದ್ದಾಳೆ. ಸಂದರ್ಶನಕ್ಕೆ ತಯಾರಾಗಲು ಆಕೆಗೆ ೨ ವಾರಗಳಿವೆ. ಅವಳು ಅಧ್ಯಯನ ಮಾಡಬೇಕಾಗಿದೆ ಆದರೆ ಎಲ್ಲಿಂದ ಪ್ರಾರಂಭಿಸಬೇಕು ಎಂದು ಖಚಿತವಾಗಿಲ್ಲ.",
            "te": "ఈ అవకాశం వచ్చినందుకు రజియా పులకించిపోయింది . ఆమె ఇంటర్వ్యూకి సిద్ధం కావడానికి 2 వారాల సమయం ఉంది. ఆమె చదువుకోవాలి కానీ ఎక్కడ ప్రారంభించాలో ఖచ్చితంగా తెలియదు.",
            "or": "ରଜିଆ ଏହି ସୁଯୋଗକୁ ନେଇ ଆନନ୍ଦିତ । ସାକ୍ଷାତକାର ପାଇଁ ପ୍ରସ୍ତୁତ ହେବାକୁ ତାଙ୍କ ପାଖରେ 2 ସପ୍ତାହ ଅଛି । ସେ ଅଧ୍ୟୟନ କରିବା ଆବଶ୍ୟକ କରନ୍ତି କିନ୍ତୁ କେଉଁଠାରୁ ଆରମ୍ଭ କରିବେ ତାହା ନିଶ୍ଚିତ ନୁହଁନ୍ତି ।",
            "as": "ৰাজিয়া সুযোগটোৰ বিষয়ে ৰোমাঞ্চিত। ইন্টাৰভিউৰ বাবে প্ৰস্তুতি কৰিবলৈ তাইৰ ওচৰত 2 সপ্তাহ আছে। তাই পঢ়িব লাগিব কিন্তু ক'ৰ পৰা আৰম্ভ কৰিব তাই ভাবি পোৱা নাই।",
            "gu": "રઝિયા આ તક વિશે ગભરાઈ જાય છે. તેની જોડે 2 અઠવાડિયા છે ઇન્ટરવ્યુની તૈયારી કરવા માટે. તેને ભણવાની જરૂર છે પરંતુ કઈ જગ્યાએથી ચાલુ કરવું તેની ખબર નથી."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_1_2",
            "hi": "B6_1_2",
            "ka": "B6_1_2",
            "te": "B6_1_2",
            "or": "B6_1_2",
            "as": "B6_1_2",
            "gu": "B6_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She reads the Job Description carefully and finds 2 things very important for the role: \nGood communication skills and\nTechnical environmental knowledge about Sundarbans.",
            "hi": "वह सावधानी से जॉब का विवरण पढ़ती है और उसे पता चलता हैं कि इस रोल के लिए 2 बातें महत्वपूर्ण हैं: \n अच्छी कम्युनिकेशन स्किल और \n सुंदरबन के बारे में टेक्निकल एनवायरमेंटल ज्ञान।",
            "ka": "ಅವರು ಜಾಬ್ ವಿವರಣೆಯನ್ನು ಎಚ್ಚರಿಕೆಯಿಂದ ಓದುತ್ತಾರೆ ಮತ್ತು ಪಾತ್ರಕ್ಕೆ ೨ ಮುಖ್ಯವಾದ ವಿಷಯಗಳನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾಳೆ: ಉತ್ತಮ ಸಂವಹನ ಕೌಶಲ್ಯ ಮತ್ತು\n ಸುಂದರಬನ್ಸ್ ಬಗ್ಗೆ ತಾಂತ್ರಿಕ ಪರಿಸರ ಜ್ಞಾನ.",
            "te": "\"ఆమె ఉద్యోగ వివరణను జాగ్రత్తగా చదువుతుంది\n  మరియు పాత్ర కోసం చాలా ముఖ్యమైన 2 విషయాలను కనుగొంటుంది:\n మంచి కమ్యూనికేషన్ నైపుణ్యాలు మరియు\n సుందర్బన్స్ గురించి సాంకేతిక పర్యావరణ పరిజ్ఞానం.\"",
            "or": "ସେ ଚାକିରିର ବିବରଣୀକୁ ଯତ୍ନର ସହିତ ପଢନ୍ତି ଏବଂ ଭୂମିକା ପାଇଁ 2ଟି ଜିନିଷ ଅତ୍ୟନ୍ତ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ମନେ କରନ୍ତି: ସୁନ୍ଦରବନ ବିଷୟରେ ଉତ୍ତମ ଯୋଗାଯୋଗ ଦକ୍ଷତା ଏବଂ ବୈଷୟିକ ପରିବେଶ ଜ୍ଞାନ ।",
            "as": "তাই চাকৰিৰ বিৱৰণ সাৱধানে পঢ়ে আৰু কামটোৰ বাবে 2 টা বস্তু অতি দৰকাৰী বুলি ভাবে: \n ভাল যোগাযোগ দক্ষতা আৰু\n সুন্দৰবনৰ বিষয়ে কাৰিকৰী পাৰিপাৰ্শ্বিক জ্ঞান।",
            "gu": "તે નોકરી વર્ણન ધ્યાનથી વાંચે છે અને તેના માટે 2 વસ્તુઓ ખૂબ જ મહત્વપૂર્ણ શોધે છે:\nસારું વાર્તાલાભ આવડત અને\nસુંદરવન વિશે ટેકનિકલ પર્યાવરણીય જ્ઞાન."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_1_3_RevealComponent",
            "hi": "B6_1_3_कम्पोनेंट दिखाएं",
            "ka": "B6_1_3",
            "te": "B6_1_3_కాంపోనెంట్‌ను బహిర్గతం చేయండి",
            "or": "B6_1_3_ଉପାଦାନ ପ୍ରକାଶ କରନ୍ତୁ",
            "as": "B6_1_3_উপাদান প্ৰকাশ কৰক",
            "gu": "B4_3_3_ભાગ વ્યક્ત કરવું"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "She decides to map her strengths and areas of improvement to know how to prioritise preparation.",
            "hi": "वह अपने बलस्थान की और किन बातों में उसे सुधार करने की जरूरत हैं उसकी सूची बनाने का निर्णय लेती हैं ताकि तैयारी की प्राथमिकता तय की जा सकें।",
            "ka": "ತಯಾರಿಗೆ ಹೇಗೆ ಆದ್ಯತೆ ನೀಡಬೇಕೆಂದು ತಿಳಿಯಲು ತನ್ನ ಸಾಮರ್ಥ್ಯ ಮತ್ತು ಸುಧಾರಣೆಯ ಕ್ಷೇತ್ರಗಳನ್ನು ನಕ್ಷೆ ಮಾಡಲು ಅವಳು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
            "te": "ప్రిపరేషన్‌కు ఎలా ప్రాధాన్యత ఇవ్వాలో తెలుసుకోవడానికి ఆమె తన బలాలు మరియు అభివృద్ధి రంగాలను మ్యాప్ చేయాలని నిర్ణయించుకుంది.",
            "or": "ପ୍ରସ୍ତୁତିକୁ କିପରି ପ୍ରାଥମିକତା ଦିଆଯିବ ଜାଣିବା ପାଇଁ ସେ ତାଙ୍କ ଶକ୍ତି ଏବଂ ଉନ୍ନତିକ୍ଷେତ୍ରକୁ ମାନଚିତ୍ର କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି ।",
            "as": "প্ৰস্তুতিক কেনেদৰে আগস্থান দিব লাগে জানিবলৈ তাই তাইৰ শক্তি আৰু উন্নতিৰ ক্ষেত্ৰসমূহ বিছাৰি উলিয়াবলৈ সিদ্ধান্ত লয়।",
            "gu": "શક્તિઓ અને સુધારણાના ક્ષેત્રોને ગોઠવવાનું નક્કી કરે છે જે તૈયારીને પ્રાથમિકતા કેવી રીતે આપવી તે જાણવું જરૂરી છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.beforeReveal",
          "value": {
            "en": "My strengths",
            "hi": "मेरे बलस्थान",
            "ka": "ನನ್ನ ಸಾಮರ್ಥ್ಯಗಳು",
            "te": "నా బలాలు",
            "or": "ମୋର ଶକ୍ତି",
            "as": "মোৰ শক্তি",
            "gu": "મારી તાકાત"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.beforeReveal",
          "value": {
            "en": "My strengths",
            "hi": "मेरे बलस्थान",
            "ka": "ನನ್ನ ಸಾಮರ್ಥ್ಯಗಳು",
            "te": "నా బలాలు",
            "or": "ମୋର ଶକ୍ତି",
            "as": "মোৰ শক্তি",
            "gu": "મારી તાકાત"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.beforeReveal",
          "value": {
            "en": "My strengths",
            "hi": "मेरे बलस्थान",
            "ka": "ನನ್ನ ಸಾಮರ್ಥ್ಯಗಳು",
            "te": "నా బలాలు",
            "or": "ମୋର ଶକ୍ତି",
            "as": "মোৰ শক্তি",
            "gu": "મારી તાકાત"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.beforeReveal",
          "value": {
            "en": "My areas of improvement",
            "hi": "जहाँ मुझे सुधार करने की जरूरत हैं",
            "ka": "ನನ್ನ ಸುಧಾರಣೆಯ ಕ್ಷೇತ್ರಗಳು",
            "te": "నా మెరుగుదల ప్రాంతాలు",
            "or": "ମୋର ଉନ୍ନତିର କ୍ଷେତ୍ରଗୁଡିକ",
            "as": "মোৰ উন্নতিৰ ক্ষেত্ৰসমূহ",
            "gu": "મારા સુધારવવાના વિસ્તારો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.beforeReveal",
          "value": {
            "en": "My areas of improvement",
            "hi": "जहाँ मुझे सुधार करने की जरूरत हैं",
            "ka": "ನನ್ನ ಸುಧಾರಣೆಯ ಕ್ಷೇತ್ರಗಳು",
            "te": "నా మెరుగుదల ప్రాంతాలు",
            "or": "ମୋର ଉନ୍ନତିର କ୍ଷେତ୍ରଗୁଡିକ",
            "as": "মোৰ উন্নতিৰ ক্ষেত্ৰসমূহ",
            "gu": "મારા સુધારવવાના વિસ્તારો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.5.beforeReveal",
          "value": {
            "en": "My areas of improvement",
            "hi": "जहाँ मुझे सुधार करने की जरूरत हैं",
            "ka": "ನನ್ನ ಸುಧಾರಣೆಯ ಕ್ಷೇತ್ರಗಳು",
            "te": "నా మెరుగుదల ప్రాంతాలు",
            "or": "ମୋର ଉନ୍ନତିର କ୍ଷେତ୍ରଗୁଡିକ",
            "as": "মোৰ উন্নতিৰ ক্ষেত্ৰসমূহ",
            "gu": "મારા સુધારવવાના વિસ્તારો"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.afterReveal",
          "value": {
            "en": "Good at front desk and talking to guests",
            "hi": "फ्रंट डेस्क के काम में और मेहमानों से बातचीत करने में अच्छी हूँ",
            "ka": "ಮುಂಭಾಗದ ಮೇಜಿನ ಬಳಿ ಮತ್ತು ಅತಿಥಿಗಳೊಂದಿಗೆ ಮಾತನಾಡುವುದು ಒಳ್ಳೆಯದು",
            "te": "ముందు డెస్క్ వద్ద మరియు అతిథులతో మాట్లాడటం మంచిది",
            "or": "ଫ୍ରଣ୍ଟ ଡେସ୍କରେ ଭଲ ଏବଂ ଅତିଥିମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା",
            "as": "ফ্ৰন্ট ডেস্কত থকা আৰু অতিথিৰ সৈতে কথা পতাত ভাল",
            "gu": "તે ફ્રન્ટ ડેસ્ક પર સારી છે અને મહેમાનો સાથે વાત કરવા માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.afterReveal",
          "value": {
            "en": "Knows a lot of stories about Sunderban",
            "hi": "सुंदरबन के बारे में कई कहानियां जानती हूँ",
            "ka": "ಸುಂದರ್‌ಬನ್ ಬಗ್ಗೆ ಸಾಕಷ್ಟು ಕಥೆಗಳನ್ನು ತಿಳಿದಿದ್ದಾರೆ",
            "te": "సుందర్‌బన్ గురించి చాలా కథలు తెలుసు",
            "or": "ସୁନ୍ଦରବନ ବିଷୟରେ ଅନେକ କାହାଣୀ ଜାଣିଛି",
            "as": "সুন্দৰবনৰ বিষয়ে বহুতো কাহিনী জানে",
            "gu": "તેને ગણી બધી સુંદરબન વિષે ખ્યાલ છે "
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.afterReveal",
          "value": {
            "en": "Learning on the job",
            "hi": "काम के बारे में सीखना",
            "ka": "ಕೆಲಸದ ಮೇಲೆ ಕಲಿಯುವುದು",
            "te": "ఉద్యోగంలో నేర్చుకున్నవి",
            "or": "ଚାକିରିରେ ଶିଖିବା",
            "as": "কাম কৰি শিকা",
            "gu": "નોકરી પર શીખવું"
          }
        },
        {
          "valuePath": "blocks.0.props.options.3.afterReveal",
          "value": {
            "en": "To know more about Flora and Fauna",
            "hi": "वनस्पतियों और जीवों के बारे में अधिक जानना",
            "ka": "ಸಸ್ಯ ಮತ್ತು ಪ್ರಾಣಿಗಳ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು",
            "te": "వృక్షజాలం మరియు జంతుజాలం గురించి మరింత తెలుసుకోవడానికి",
            "or": "ଉଦ୍ଭିଦ ଏବଂ ପ୍ରଣିମାନଙ୍କ ବିଷୟରେ ଅଧିକ ଜାଣିବା",
            "as": "উদ্ভিদ আৰু প্ৰাণীৰ বিষয়ে অধিক জনা",
            "gu": "વનસ્પતિ અને પ્રાણીસૃષ્ટિ વિશે વધુ જાણકારી મેળવવા માટે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.4.afterReveal",
          "value": {
            "en": "How to communicate while conducting tours",
            "hi": "टूर आयोजित करते समय कैसे बात करते यह सीखना",
            "ka": "ಪ್ರವಾಸಗಳನ್ನು ನಡೆಸುವಾಗ ಹೇಗೆ ಸಂವಹನ ಮಾಡುವುದು",
            "te": "ఎలా సంభాషించాలి పర్యటనలు నిర్వహిస్తున్నప్పుడు",
            "or": "ଭ୍ରମଣ ପରିଚାଳନା କରିବା ସମୟରେ କିପରି ଯୋଗାଯୋଗ କରିବେ",
            "as": "ভ্ৰমণ পৰিচালনা কৰোঁতে কেনেদৰে যোগাযোগ কৰিব লাগে",
            "gu": "વાતચીત કેવી રીતે કરવી જયરે પ્રવાશીઓ સમજાવતા હોય"
          }
        },
        {
          "valuePath": "blocks.0.props.options.5.afterReveal",
          "value": {
            "en": "Time management",
            "hi": "समय का प्रबंधन",
            "ka": "ಸಮಯ ನಿರ್ವಹಣೆ",
            "te": "సమయ నిర్వహణ",
            "or": "ସମୟ ପରିଚାଳନା",
            "as": "সময় ব্যৱস্থাপনা",
            "gu": "સમયને કેવી રીતે ગોઠવવો"
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "Understanding of the self",
            "hi": "खुद को समझना",
            "ka": "ಸ್ವಯಂ ತಿಳುವಳಿಕೆ",
            "te": "తనను తాను అర్థం చేసుకోవడం",
            "or": "ଆତ୍ମବିଷୟରେ ବୁଝିବା",
            "as": "নিজকে বুজা",
            "gu": "પોતાને ઓળખવો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.title",
          "value": {
            "en": "You just gained Understanding Self experience!",
            "hi": "आपने अभी-अभी खुद को समझने का अनुभव प्राप्त किया हैं!",
            "ka": "ನೀವು ಸ್ವಯಂ ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು ಅನುಭವವನ್ನು ಪಡೆದಿದ್ದೀರಿ!",
            "te": "మీరు ఇప్పుడే మిమల్ని మీరు అర్థం చేసుకునే అనుభవాన్ని పొందారు!",
            "or": "ଆପଣ ବର୍ତ୍ତମାନ ନିଜକୁ ବୁଝିବା ଅଭିଜ୍ଞତା ହାସଲ କରିଲେ!",
            "as": "তুমি এইমাত্ৰ নিজকে বুজাৰ অভিজ্ঞতা লাভ কৰিছা!",
            "gu": "તમે હમણાં જ પોતાને સમજવાનો અનુભવ મેળવ્યો છે!"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.title",
          "value": {
            "en": "Understanding Self",
            "hi": "खुद को समझना",
            "ka": "ಸ್ವಯಂ ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು",
            "te": "తనను తాను అర్థం చేసుకోవడం",
            "or": "ନିଜକୁ ବୁଝିବା",
            "as": "নিজকে বুজা",
            "gu": "પોતાને ઓળખો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.category",
          "value": {
            "en": "Self Development",
            "hi": "स्वयं विकास",
            "ka": "ಸ್ವಯಂ ಅಭಿವೃದ್ಧಿ",
            "te": "స్వయం అభివృద్ధి",
            "or": "ଆତ୍ମ ବିକାଶ",
            "as": "আত্ম বিকাশ",
            "gu": "પોતાને વિકાસ કરવો"
          }
        },
        {
          "valuePath": "blocks.0.props.data.inventoryCardProps.desc",
          "value": {
            "en": "To prepare better for the interview, Razia lists her strengths and areas of improvement. It is an important aspect to know the places where she needs to work on for the interview. Before researching career options or preparing for interviews, it is helpful to understand yourself, know your strengths and work on your weaknesses. Doing that can help you to perform better.",
            "hi": "इन्टरव्यू के लिए अच्छे से तैयारी करने के लिए, रजिया अपने बलस्थान और जहाँ उसे सुधार करने की जरूरत हैं। यह बहुत महत्वपूर्ण पहलू है जिस पर उसे काम करने की जरूरत है। करियर ऑप्शन्स के बारे में रिसर्च करने से पहले या इन्टरव्यू के लिए तैयारी करने से पहले, खुद के बारे में जानना, अपने बलस्थान जानना और अपनी कमजोरियां सुधारना मददगार साबित हो सकता है। ऐसा करने से अच्छा कम करने में मदद मिलती है।",
            "ka": "ಸಂದರ್ಶನಕ್ಕೆ ಉತ್ತಮವಾಗಿ ತಯಾರಾಗಲು, ರಝಿಯಾ ತನ್ನ ಸಾಮರ್ಥ್ಯ ಮತ್ತು ಸುಧಾರಣೆಯ ಕ್ಷೇತ್ರಗಳನ್ನು ಪಟ್ಟಿಮಾಡುತ್ತಾಳೆ. ಸಂದರ್ಶನಕ್ಕಾಗಿ ಅವಳು ಕೆಲಸ ಮಾಡಬೇಕಾದ ಸ್ಥಳಗಳನ್ನು ತಿಳಿದುಕೊಳ್ಳುವುದು ಒಂದು ಪ್ರಮುಖ ಅಂಶವಾಗಿದೆ. ವೃತ್ತಿಯ ಆಯ್ಕೆಗಳನ್ನು ಸಂಶೋಧಿಸುವ ಮೊದಲು ಅಥವಾ ಸಂದರ್ಶನಗಳಿಗೆ ತಯಾರಿ ಮಾಡುವ ಮೊದಲು, ನಿಮ್ಮನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು, ನಿಮ್ಮ ಸಾಮರ್ಥ್ಯಗಳನ್ನು ತಿಳಿದುಕೊಳ್ಳಲು ಮತ್ತು ನಿಮ್ಮ ದೌರ್ಬಲ್ಯಗಳ ಮೇಲೆ ಕೆಲಸ ಮಾಡಲು ಸಹಾಯವಾಗುತ್ತದೆ. ಹಾಗೆ ಮಾಡುವುದರಿಂದ ನೀವು ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸಲು ಸಹಾಯ ಮಾಡಬಹುದು.",
            "te": "ఇంటర్వ్యూ కోసం మెరుగ్గా సిద్ధం కావడానికి, రజియా తన బలాలు మరియు మెరుగుదల ఉన్న ప్రాంతాలను జాబితా చేసింది. ఇంటర్వ్యూ కోసం ఆమె పని చేయాల్సిన ప్రదేశాలను తెలుసుకోవడం ఒక ముఖ్యమైన అంశం. కెరీర్ ఎంపికలను పరిశోధించే ముందు లేదా ఇంటర్వ్యూలకు సిద్ధమయ్యే ముందు, మిమ్మల్ని మీరు అర్థం చేసుకోవడం, మీ బలాలు తెలుసుకోవడం మరియు మీ బలహీనతలపై పని చేయడం ఉపయోగకరంగా ఉంటుంది. అలా చేయడం వల్ల మీరు మెరుగైన పనితీరు కనబరుస్తారు.",
            "or": "ସାକ୍ଷାତକାର ପାଇଁ ଭଲ ପ୍ରସ୍ତୁତ ହେବା ପାଇଁ, ରଜିଆ ତାଙ୍କର ଶକ୍ତି ଏବଂ ଉନ୍ନତିକ୍ଷେତ୍ର ତାଲିକାଭୁକ୍ତ କରନ୍ତି । ସାକ୍ଷାତକାର ପାଇଁ ସେ ଯେଉଁ ଠାରେ କାମ କରିବା ଆବଶ୍ୟକ କରନ୍ତି ତାହା ଜାଣିବା ଏକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ଦିଗ । କ୍ୟାରିୟର ବିକଳ୍ପ ଉପରେ ଗବେଷଣା କରିବା କିମ୍ବା ସାକ୍ଷାତକାର ପାଇଁ ପ୍ରସ୍ତୁତ ହେବା ପୂର୍ବରୁ, ନିଜକୁ ବୁଝିବା, ଆପଣଙ୍କର ଶକ୍ତି ଜାଣିବା ଏବଂ ଆପଣଙ୍କର ଦୁର୍ବଳତା ଉପରେ କାର୍ଯ୍ୟ କରିବା ସହାୟକ ହୋଇଥାଏ । ଏହା କରିବା ଆପଣଙ୍କୁ ଭଲ ପ୍ରଦର୍ଶନ କରିବାରେ ସାହାଯ୍ୟ କରିପାରେ ।",
            "as": "ইন্টাৰভিউটোৰ বাবে ভালদৰে প্ৰস্তুত হ'বলৈ, ৰাজিয়াই তাইৰ শক্তি আৰু উন্নতিৰ ক্ষেত্ৰসমূহৰ তালিকা বনাই লয়। ইন্টাৰভিউটোৰ বাবে তাই কি ঠাইত কাম কৰিব লাগিব সেয়া জনাটো এটা প্ৰয়োজনীয় দিশ। কেৰিয়াৰৰ আন ভাগবোৰৰ চিন্তা কৰাৰ আগতে বা ইন্টাৰভিউটোৰ বাবে প্ৰস্তুতি কৰাৰ আগতে, নিজকে বুজি পোৱা, নিজৰ শক্তি জনা আৰু নিজৰ দুৰ্বলতাৰ ওপৰত কাম কৰাটোৱে সহায় কৰিব পাৰে। এইটোৱে তোমাক ভাল প্ৰদৰ্শন কৰাত সহায় কৰিব পাৰে।",
            "gu": "ઇન્ટરવ્યુ માટે વધુ સારી તૈયારી કરવી, રઝિયા તેની શક્તિઓ અને સુધારણાના ક્ષેત્રોની યાદી બનાવે છે. ઇન્ટરવ્યુ માટે તેને શું ત્યાર કરવાની છે તે સમજવું એક મહત્વપૂર્ણ પાસું છે. કારકિર્દીના વિકલ્પો પર સોધવું પહેલા અથવા ઇન્ટરવ્યુની તૈયારી કરતા પહેલા, તમારે પોતાને સમજવું, તમારી શક્તિઓ જાણવી અને તમારી ભૂલો પર કામ કરવું તેમાં મદદ મળે છે. આમ કરવાથી તમે વધુ સારું પ્રદર્શન કરવામાં મદદ મળી શકે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B6_1_4(D)",
            "hi": "B6_1_4(D)",
            "ka": "B6_1_4(D)",
            "te": "B6_1_4(D)",
            "or": "B6_1_4(D)",
            "as": "B6_1_4(D)",
            "gu": "B6_1_4(D)"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "What should Razia do?",
            "hi": "रजिया को क्या करना चाहिए?",
            "ka": "ರಝಿಯಾ ಏನು ಮಾಡಬೇಕು?",
            "te": "రజియా ఏం చేయాలి?",
            "or": "ରଜିଆ କ'ଣ କରିବା ଉଚିତ୍?",
            "as": "ৰাজিয়াই কি কৰা উচিত?",
            "gu": "રઝિયાને શું કરવું જોઇયે?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Upskill on environmental knowledge around Sunderbans",
            "hi": "सुंदरबन के बारे में एनवायरमेंटल ज्ञान को अपस्किल करना",
            "ka": "ಸುಂದರ್‌ಬನ್ಸ್ ಸುತ್ತಮುತ್ತಲಿನ ಪರಿಸರ ಜ್ಞಾನದ ಮೇಲಿನ ಕೌಶಲ್ಯ",
            "te": "సుందర్‌బన్స్ చుట్టూ ఉన్న పర్యావరణ పరిజ్ఞానంపై అధిక నైపుణ్యం పెంచుకోవడం",
            "or": "ସୁନ୍ଦରବନ ଚାରିପାଖରେ ପରିବେଶ ଜ୍ଞାନ ଉପରେ ଅପସ୍କିଲ୍",
            "as": "সুন্দৰবনৰ চাৰিওফালে পৰিৱেশজ্ঞানৰ ওপৰত আপস্কিল",
            "gu": "ઉચ્ચ કૌશલ્ય મેળવવું સુંદરવનની આસપાસના પર્યાવરણીય જ્ઞાન ઉપર"
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "Upskill on communication skills to talk to guests better",
            "hi": "मेहमानों के साथ अच्छे से बात करने के लिए संवाद कौशल अपस्किल करना",
            "ka": "ಅತಿಥಿಗಳೊಂದಿಗೆ ಉತ್ತಮವಾಗಿ ಮಾತನಾಡಲು ಸಂವಹನ ಕೌಶಲ್ಯಗಳನ್ನು ಹೆಚ್ಚಿಸಿ",
            "te": "అతిథులతో మెరుగ్గా మాట్లాడేందుకు సమాచార నెపుణ్యాలపై అధిక నైపుణ్యాన్ని పెంచుకోవడం",
            "or": "ଅତିଥିମାନଙ୍କ ସହିତ ଭଲ କଥାବାର୍ତ୍ତା କରିବାକୁ ଯୋଗାଯୋଗ ଦକ୍ଷତା ଉପରେ ଅପସ୍କିଲ୍",
            "as": "অতিথিৰ সৈতে ভালদৰে কথা পাতিবলৈ যোগাযোগ দক্ষতাৰ ওপৰত আপস্কিল",
            "gu": "મહેમાનો સાથે વધુ સારી રીતે વાત કરવા માટે વાર્તાલાભ કૌશલ્ય પર કૌશલ્ય વધારો"
          }
        }
      ]
    },
    "scene35": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_1_1_Upskill on environmental knowledge",
            "hi": "एनवायरमेंटल ज्ञान को अपस्किल करना",
            "ka": "B7_1_1_ಪರಿಸರ ಜ್ಞಾನದ ಮೇಲಿನ ಕೌಶಲ್ಯ",
            "te": "B7_1_1_ పర్యావరణ పరిజ్ఞానంపై అధిక నైపుణ్యం",
            "or": "B7_1_1_ ପରିବେଶ ଜ୍ଞାନ ଉପରେ ଅପସ୍କିଲ୍",
            "as": "B7_1_1_পৰিৱেশজ্ঞানৰ ওপৰত আপস্কিল",
            "gu": "B7_1_1_પર્યાવરણ જ્ઞાન પર ઉચ્ચ કૌશલ્ય મેળવવું"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She starts off by going to the resort library and finds a book to build her technical environmental knowledge about the Sundarbans.",
            "hi": "वह रिसोर्ट की लायब्रेरी में जाने लगी और सुंदरबन के बारे में टेक्निकल एनवायरमेंटल ज्ञान बढ़ाने के लिए किताबें पढ़ना शुरू किया।",
            "ka": "ಅವಳು ರೆಸಾರ್ಟ್ ಲೈಬ್ರರಿಗೆ ಹೋಗುವ ಮೂಲಕ ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ ಮತ್ತು ಸುಂದರಬನ್ಸ್ ಬಗ್ಗೆ ತನ್ನ ತಾಂತ್ರಿಕ ಪರಿಸರ ಜ್ಞಾನವನ್ನು ನಿರ್ಮಿಸಲು ಪುಸ್ತಕವನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾಳೆ.",
            "te": "ఆమె రిసార్ట్ లైబ్రరీకి వెళ్లడం ప్రారంభిస్తుంది మరియు సుందర్బన్స్ గురించి తన సాంకేతిక పర్యావరణ పరిజ్ఞానాన్ని పెంపొందించడానికి ఒక పుస్తకాన్ని కనుగొంటుంది.",
            "or": "ସେ ରିସର୍ଟ ଲାଇବ୍ରେରୀକୁ ଯାଇ ଆରମ୍ଭ କରନ୍ତି ଏବଂ ସୁନ୍ଦରବନ ବିଷୟରେ ତାଙ୍କର ବୈଷୟିକ ପରିବେଶ ଜ୍ଞାନ ନିର୍ମାଣ ପାଇଁ ଏକ ପୁସ୍ତକ ଖୋଜନ୍ତି ।",
            "as": "তাই ৰিজৰ্ট লাইব্ৰেৰীলৈ যায় আৰু সুন্দৰবনৰ বিষয়ে কাৰিকৰী পাৰিপাৰ্শ্বিক জ্ঞান নিৰ্মাণ কৰিবলৈ এখন কিতাপ বিচাৰি পায়।",
            "gu": "તે સૌપ્રથમ રિસોર્ટની લાઇબ્રેરીમાં જાય છે અને એક પુસ્તક શોધે છે જે તેણીને સુંદરવન વિશેના તેના ટેકનિકલ ઇકોલોજીકલ જ્ઞાનને વિસ્તારવા દે છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_1_2",
            "hi": "B7_1_2",
            "ka": "B7_1_2",
            "te": "B7_1_2",
            "or": "B7_1_2",
            "as": "B7_1_2",
            "gu": "B7_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She studies well and makes sure she is thorough with all the facts about the area.",
            "hi": "उसने अच्छे से पढ़ाई की और उस प्रदेश के बारे में उसने पूरी जानकारी प्राप्त की।",
            "ka": "ಅವಳು ಚೆನ್ನಾಗಿ ಅಧ್ಯಯನ ಮಾಡುತ್ತಾಳೆ ಮತ್ತು ಪ್ರದೇಶದ ಬಗ್ಗೆ ಎಲ್ಲಾ ಸಂಗತಿಗಳೊಂದಿಗೆ ಅವಳು ಸಂಪೂರ್ಣವಾಗಿದ್ದಾಳೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುತ್ತಾಳೆ.",
            "te": "ఆమె బాగా చదువుతుంది మరియు ఆ ప్రాంతం గురించిన అన్ని వాస్తవాలతో ఆమె క్షుణ్ణంగా ఉండేలా చూసుకుంటుంది.",
            "or": "ସେ ଭଲ ଭାବରେ ଅଧ୍ୟୟନ କରନ୍ତି ଏବଂ ନିଶ୍ଚିତ କରନ୍ତି ଯେ ସେ ଏହି ଅଞ୍ଚଳ ବିଷୟରେ ସମସ୍ତ ତଥ୍ୟ ପୁଙ୍ଖାନୁପୁଙ୍ଖ ଭାବରେ ଜାଣିଛନ୍ତି ।",
            "as": "তাই ভালদৰে পঢ়ে আৰু নিশ্চিত হয় যে তাইৰ লগত অঞ্চলটোৰ বিষয়ে সকলো তথ্য সম্পূৰ্ণৰূপে আছে।",
            "gu": "તે એક સારી શીખવા વાળી છે અને ખાતરી કરે છે કે તે આ વિસ્તાર વિશેના તમામ હકીકતો સંપૂર્ણ છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_1_3///",
            "hi": "B7_1_3///",
            "ka": "B7_1_3///",
            "te": "B7_1_3///",
            "or": "B7_1_3///",
            "as": "B7_1_3///",
            "gu": "B7_1_3///"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "She talks to the local people in and around the resort to know more about the flora and fauna in that area.",
            "hi": "उसने स्थानिक लोगों से और रिसोर्ट के लोगों से बातचीत करके उस प्रदेश के वनस्पतियों और जीवों के बारे मेंजानकारी हासिल की।",
            "ka": "ಆ ಪ್ರದೇಶದಲ್ಲಿನ ಸಸ್ಯ ಮತ್ತು ಪ್ರಾಣಿಗಳ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ರೆಸಾರ್ಟ್‌ನಲ್ಲಿರುವ ಮತ್ತು ಸುತ್ತಮುತ್ತಲಿನ ಸ್ಥಳೀಯ ಜನರೊಂದಿಗೆ ಅವಳು ಮಾತನಾಡುತ್ತಾಳೆ.",
            "te": "ఆ ప్రాంతంలోని వృక్షజాలం మరియు జంతుజాలం గురించి మరింత తెలుసుకోవడానికి ఆమె రిసార్ట్‌లో మరియు చుట్టుపక్కల ఉన్న స్థానిక ప్రజలతో మాట్లాడుతుంది.",
            "or": "ସେ ସେହି ଅଞ୍ଚଳର ଉଦ୍ଭିଦ ଏବଂ ଜୀବଜନ୍ତୁ ବିଷୟରେ ଅଧିକ ଜାଣିବା ପାଇଁ ରିସୋର୍ଟ ଏବଂ ଏହାର ଆଖପାଖରେ ଥିବା ସ୍ଥାନୀୟ ଲୋକଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରନ୍ତି ।",
            "as": "তাই ৰিজৰ্টৰ আশে-পাশে থকা স্থানীয় লোকসকলৰ সৈতে সেই অঞ্চলৰ উদ্ভিদ আৰু জীৱজন্তুৰ বিষয়ে অধিক জানিবলৈ কথা পাতিছিল।",
            "gu": "તે આ વિસ્તારની વનસ્પતિ અને પ્રાણીસૃષ્ટિ વિશે વધુ જાણવા માટે રિસોર્ટમાં અને તેની આસપાસના લોકો સાથે વાત કરે છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_2_1",
            "hi": "B7_2_1",
            "ka": "B7_2_1",
            "te": "B7_2_1",
            "or": "B7_2_1",
            "as": "B7_2_1",
            "gu": "B7_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "After 2 weeks, she goes to the interview confidently.",
            "hi": "2 हफ्ते बाद, वह पुरे विश्वास के साथ इन्टरव्यू के लिए गई।",
            "ka": "೨ ವಾರಗಳ ನಂತರ, ಅವಳು ಆತ್ಮವಿಶ್ವಾಸದಿಂದ ಸಂದರ್ಶನಕ್ಕೆ ಹೋಗುತ್ತಾಳೆ.",
            "te": "2 వారాల తర్వాత, ఆమె నమ్మకంగా ఇంటర్వ్యూకి వెళుతుంది.",
            "or": "2 ସପ୍ତାହ ପରେ, ସେ ଆତ୍ମବିଶ୍ୱାସର ସହିତ ସାକ୍ଷାତକାରକୁ ଯାଆନ୍ତି ।",
            "as": "2 সপ্তাহৰ পিছত, তাই আত্মবিশ্বাসেৰে ইন্টাৰভিউ দিবলৈ যায়।",
            "gu": "2 અઠવાડિયા પછી, તે પૂરા વિશ્વાસ સાથે ઇન્ટરવ્યુમાં જાય છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_2_2",
            "hi": "B7_2_2",
            "ka": "B7_2_2",
            "te": "B7_2_2",
            "or": "B7_2_2",
            "as": "B7_2_2",
            "gu": "B7_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Sushma Ma’am is able to see the effort Razia has put to learn about the environment. She feels Razia will fit the role well and offers her the job.",
            "hi": "सुषमा मैम को साफ दिखाई दे रहा था कि रजिया ने पर्यावरण के बारे में सीखने के लिए काफी मेहनत की है। उन्हें लगा कि रजिया इस रोल के लिए सही हैं और उन्होंने उसे जॉब के लिए ऑफर दी।",
            "ka": "ಪರಿಸರದ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಲು ರಝಿಯಾ ಮಾಡಿರುವ ಶ್ರಮವನ್ನು ಸುಷ್ಮಾ ಮೇಮ್ ನೋಡಲು ಸಾಧ್ಯವಾಗಿದೆ. ರಝಿಯಾ ಪಾತ್ರಕ್ಕೆ ಚೆನ್ನಾಗಿ ಹೊಂದಿಕೊಳ್ಳುತ್ತಾಳೆ ಎಂದು ಅವರು ಭಾವಿಸುತ್ತಾರೆ ಮತ್ತು ಅವಳಿಗೆ ಕೆಲಸವನ್ನು ನೀಡುತ್ತಾರೆ.",
            "te": "పర్యావరణం గురించి తెలుసుకోవడానికి రజియా చేసిన కృషిని సుష్మా మేడమ్ చూడగలుగుతున్నారు. ఆ పాత్రకు రజియా బాగా సరిపోతుందని భావించి ఆమెకు ఉద్యోగం ఇచ్చింది.",
            "or": "ରଜିଆ ପରିବେଶ ବିଷୟରେ ଜାଣିବାକୁ କରିଥିବା ପ୍ରୟାସକୁ ସୁଷମା ମାଡ଼ାମ୍ ଦେଖନ୍ତି । ସେ ଅନୁଭବ କରନ୍ତି ଯେ ରଜିଆ ଏହି ଭୂମିକାକୁ ଭଲ ଭାବରେ ଫିଟ୍ ଏବଂ ତାଙ୍କୁ ଚାକିରି ପ୍ରଦାନ କରନ୍ତି ।",
            "as": "ৰাজিয়াই পৰিৱেশৰ বিষয়ে শিকিবলৈ কৰা প্ৰচেষ্টা সুষমা মেমে জানিবলৈ সক্ষম হৈছে। তেঁও ভাবে যে ৰাজিয়া কামটোৰ বাবে ভালদৰে উপযুক্ত হব আৰু তাইক চাকৰিটো দিয়ে।",
            "gu": "સુષ્મા મેડમે જોયું કે રઝિયાએ પર્યાવરણ વિશે શીખવા માટે કેટલી મહેનત કરી છે. તેને લાગે છે કે રઝિયા આ ભૂમિકાને સારી રીતે કરે છે અને તેને નોકરીની આવકાર મળે છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_2_3",
            "hi": "B7_2_3",
            "ka": "B7_2_3",
            "te": "B7_2_3",
            "or": "B7_2_3",
            "as": "B7_2_3",
            "gu": "B7_2_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia accepts the role as a junior naturalist.",
            "hi": "रजिया ने ज्युनियर नैचरलिस्ट के रोल को स्वीकार किया।",
            "ka": "ಜೂನಿಯರ್ ನ್ಯಾಚುರಲಿಸ್ಟ್ ಪಾತ್ರವನ್ನು ರಝಿಯಾ ಒಪ್ಪಿಕೊಂಡಿದ್ದಾಳೆ.",
            "te": "జూనియర్ ప్రకృతి శాస్త్రవేత్త. పాత్రను రజియా అంగీకరించింది",
            "or": "ରଜିଆ ଜଣେ କନିଷ୍ଠ ପ୍ରକୃତିବାଦୀ ଭାବରେ ଭୂମିକା ଗ୍ରହଣ କରନ୍ତି ।",
            "as": "ৰাজিয়াই এগৰাকী কনিষ্ঠ প্ৰকৃতিবিদ হিচাপে কামটো গ্ৰহণ কৰে।",
            "gu": "રઝિયા જુનિયર નેચરલિસ્ટની ભૂમિકા નિભાવે છે."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_3_1",
            "hi": "B7_3_1",
            "ka": "B7_3_1",
            "te": "B7_3_1",
            "or": "B7_3_1",
            "as": "B7_3_1",
            "gu": "B7_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Her work now involves going on guided tours, coordinating nature walks, and organising film shows and talks for the guests and help Sushma Ma’am with her work.",
            "hi": "अब उसका काम है गाइड टूर करना, नैचर वॉक आयोजित करना और मेहमानों के लिए फिल्म शो और भाषण आयोजित करना और सुषमा मैम के काम उन्हें मदद करना।",
            "ka": "ಅವಳ ಕೆಲಸವು ಈಗ ಮಾರ್ಗದರ್ಶಿ ಪ್ರವಾಸಗಳಿಗೆ ಹೋಗುವುದು, ಪ್ರಕೃತಿಯ ನಡಿಗೆಗಳನ್ನು ಸಂಯೋಜಿಸುವುದು ಮತ್ತು ಅತಿಥಿಗಳಿಗಾಗಿ ಚಲನಚಿತ್ರ ಪ್ರದರ್ಶನಗಳು ಮತ್ತು ಮಾತುಕತೆಗಳನ್ನು ಆಯೋಜಿಸುವುದು ಮತ್ತು ಸುಷ್ಮಾ ಮೇಮ್ ಅವರ ಕೆಲಸದಲ್ಲಿ ಸಹಾಯ ಮಾಡುವುದು.",
            "te": "ఆమె పనిలో ఇప్పుడు గైడెడ్ టూర్‌లకు వెళ్లడం, ప్రకృతి నడకలను సమన్వయం చేయడం మరియు అతిథుల కోసం ఫిల్మ్ షోలు మరియు చర్చలు నిర్వహించడం మరియు ఆమె పనిలో సుష్మా మేడం కు సహాయం చేయడం వంటివి ఉన్నాయి.",
            "or": "ତାଙ୍କ କାର୍ଯ୍ୟରେ ବର୍ତ୍ତମାନ ଗାଇଡେଡ୍ ଟୁର୍ ରେ ଯିବା, ପ୍ରକୃତି ସହ ଚାଲିବା (ନେଚର ୱାକ୍ସ) ସମନ୍ୱୟ କରିବା ଏବଂ ଅତିଥିମାନଙ୍କ ପାଇଁ ଚଳଚ୍ଚିତ୍ର ଶୋ ଏବଂ କଥାବାର୍ତ୍ତା ଆୟୋଜନ କରିବା ଏବଂ ସୁଷମା ମାଡ଼ାମଙ୍କୁ ତାଙ୍କ କାର୍ଯ୍ୟରେ ସାହାଯ୍ୟ କରିବା ଅନ୍ତର୍ଭୁକ୍ତ ।",
            "as": "তাইৰ কামৰ ভিতৰত এতিয়া নেতৃত্ব দি ভ্ৰমণ আগুৱাই নিয়া, প্ৰকৃতি ৰাজ্যত খোজকাঢ়োতে সমন্বয় কৰা, অতিথিসকলৰ বাবে চলচ্চিত্ৰ অনুষ্ঠান কৰা আৰু অতিথি সকলৰ কাৰণে কথা পতা আৰু সুষমা মেমক তেওঁৰ কামত সহায় কৰা অন্তৰ্ভুক্ত হৈ আছে।",
            "gu": "તેના કાર્યમાં હવે માર્ગદર્શન, પ્રકૃતિની ચાલનું સંકલન, મૂવી સ્ક્રીનીંગ અને મહેમાનો માટે વાર્તાલાપનું આયોજન અને સુષ્મા મેડમને કામમાં મદદ કરવાનો સમાવેશ થાય છે."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "B7_3_2",
            "hi": "B7_3_2",
            "ka": "B7_3_2",
            "te": "B7_3_2",
            "or": "B7_3_2",
            "as": "B7_3_2",
            "gu": "B7_3_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She can’t believe she has gotten a job related to Sunderbans and conducting ecotours. She is very happy with her career choices.",
            "hi": "रजिया को यकीन नहीं हो रहा कि उसे सुंदरबन से संबंधित और इकोटूर आयोजित करने का जॉब मिला है। वह अपने करियर के चुनाव से खुश है।",
            "ka": "ಅವಳು ಸುಂದರ್‌ಬನ್ಸ್‌ಗೆ ಸಂಬಂಧಿಸಿದ ಮತ್ತು ಇಕೋಟೂರ್‌ಗಳನ್ನು ನಡೆಸುವ ಕೆಲಸವನ್ನು ಪಡೆದಿದ್ದಾಳೆಂದು ಅವಳು ನಂಬಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ. ತನ್ನ ವೃತ್ತಿಯ ಆಯ್ಕೆಗಳಿಂದ ಅವಳು ತುಂಬಾ ಸಂತೋಷವಾಗಿದ್ದಾಳೆ.",
            "te": "ఆమె సుందర్‌బన్స్‌కి సంబంధించిన ఉద్యోగం సంపాదించిందని మరియు పర్యావరణ పర్యటనలను నిర్వహించడాన్ని ఆమె మ్మలేకపోతుంది. ఆమె తన కెరీర్ ఎంపికలతో చాలా సంతోషంగా ఉంది.",
            "or": "ସେ ବିଶ୍ୱାସ କରିପାରନ୍ତି ନାହିଁ ଯେ ସେ ସୁନ୍ଦରବନ ଏବଂ ଇକୋଟୁର ପରିଚାଳନା ସହିତ ଜଡିତ ଚାକିରି ପାଇଛନ୍ତି । ସେ ତାଙ୍କ କ୍ୟାରିୟର ପସନ୍ଦରେ ବହୁତ ଖୁସି ।",
            "as": "তাইৰ বিশ্বাসেই নহয় যে তাই সুন্দৰবনৰ সৈতে সম্পৰ্ক থকা চাকৰি পাইছে আৰু ইক' টুৰ সমূহ পৰিচালনা কৰিছে। তাই তাইৰ কেৰিয়াৰৰ পছন্দবোৰত বহুত সুখী।",
            "gu": "તે માનતી નથી કે તેને સુંદરવન અને ઇકોટુરના સંચાલન સાથે સંબંધિત નોકરી મળી છે. તે તેની કારકિર્દીની પસંદગીથી ખૂબ જ ખુશ છે."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ2",
            "hi": "MCQ2",
            "ka": "MCQ2",
            "te": "MCQ2",
            "or": "MCQ2",
            "as": "MCQ2",
            "gu": "MCQ2"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia does an internship at a company that later offers her a job. Internships help us understand the everyday details of jobs and industries. Select which of the following statements about internships are true.",
            "hi": "रजिया ने कंपनी में इन्टर्नशिप की जिसने आगे चलकर उसे जॉब का ऑफर दिया। इन्टर्नशिप की मदद से हम जॉब और उद्योग के हर दिन के काम को विस्तार से समझ सकते हैं। इन्टर्नशिप के बारे में निम्नलिखित में से कौन सा विधान सही है वह चुनें।",
            "ka": "ರಝಿಯಾ ಕಂಪನಿಯಲ್ಲಿ ಇಂಟರ್ನ್‌ಶಿಪ್ ಮಾಡುತ್ತಾಳೆ, ಅದು ನಂತರ ಅವಳಿಗೆ ಕೆಲಸ ನೀಡುತ್ತದೆ. ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳು ಉದ್ಯೋಗಗಳು ಮತ್ತು ಕೈಗಾರಿಕೆಗಳ ದೈನಂದಿನ ವಿವರಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳ ಕುರಿತು ಈ ಕೆಳಗಿನ ಯಾವ ಹೇಳಿಕೆಗಳು ನಿಜವೆಂದು ಆಯ್ಕೆಮಾಡಿ.",
            "te": "రజియా ఒక కంపెనీలో ఇంటర్న్‌షిప్ చేస్తుంది, అది ఆమెకు ఉద్యోగం ఇచ్చింది. ఉద్యోగాలు మరియు పరిశ్రమల రోజువారీ వివరాలను అర్థం చేసుకోవడానికి ఇంటర్న్‌షిప్‌లు మాకు సహాయపడతాయి. ఇంటర్న్‌షిప్‌ల గురించిన కింది స్టేట్‌మెంట్‌లలో ఏది నిజమో ఎంచుకోండి.",
            "or": "ରଜିଆ ଏକ କମ୍ପାନୀରେ ଏକ ଇଣ୍ଟର୍ନସିପ୍ କରନ୍ତି ଯାହା ପରେ ତାଙ୍କୁ ନିଯୁକ୍ତି ପ୍ରଦାନ କରେ । ଇଣ୍ଟର୍ନସିପ୍ ଆମକୁ ଚାକିରି ଏବଂ ଶିଳ୍ପର ଦୈନନ୍ଦିନ ବିବରଣୀ ବୁଝିବାରେ ସାହାଯ୍ୟ କରେ । ଇଣ୍ଟର୍ନସିପ୍ ବିଷୟରେ ନିମ୍ନଲିଖିତ ମଧ୍ୟରୁ କେଉଁ ଉକ୍ତି ଠିକ ତାହା ଚୟନ କରନ୍ତୁ ।",
            "as": "ৰাজিয়াই এটা কোম্পানীত ইন্টাৰ্ণশ্বিপ কৰে যিয়ে পিছত তাইক এটা চাকৰি প্ৰদান কৰে। ইন্টাৰ্ণশ্বিপে আমাক চাকৰি আৰু উদ্যোগৰ দৈনন্দিন বিৱৰণ বুজাত সহায় কৰে। ইণ্টাৰ্নশ্বিপৰ বিষয়ে নিম্নলিখিত কোনটো মন্তব্য সঁচা বাছনি কৰা।",
            "gu": "રઝિયા એક કંપનીમાં ઇન્ટર્નશિપ કરી રહી છે જે પછીથી તેને નોકરીની ઓફર કરશે. ઇન્ટર્નશિપ અમને નોકરીઓ અને ઉદ્યોગોની દૈનિક વિગતો સમજવામાં મદદ કરે છે. ઇન્ટર્નશીપ વિશે નીચેનામાંથી કયું નિવેદન સાચું છે તે પસંદ કરો."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Internships and apprenticeships are the same.",
            "hi": "इन्टर्नशिप और अप्रेंटिसशिप समान हैं।",
            "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಮತ್ತು ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್ ಒಂದೇ ಆಗಿದೆ.",
            "te": "ఇంటర్న్‌షిప్‌లు మరియు అప్రెంటిస్‌షిప్‌లు ఒకే విధంగా ఉంటాయి.",
            "or": "ଇଣ୍ଟର୍ନସିପ୍ ଏବଂ ଆପ୍ରେଣ୍ଟିସିପ୍ ସମାନ ।",
            "as": "ইণ্টাৰ্নশ্বিপ আৰু এপ্ৰেণ্টিছশ্বিপ একে।",
            "gu": "ઇન્ટર્નશિપ અને એપ્રેન્ટિસશિપ સમાન છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "An internship can help you know more about the working environment of an industry",
            "hi": "इन्टर्नशिप आपको उद्योग के काम करने के माहौल को अच्छे से समझने में मदद करती है।",
            "ka": "ಉದ್ಯಮದ ಕೆಲಸದ ವಾತಾವರಣದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಇಂಟರ್ನ್‌ಶಿಪ್ ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ",
            "te": "పరిశ్రమ యొక్క పని వాతావరణం గురించి మరింత తెలుసుకోవడానికి ఇంటర్న్‌షిప్ మీకు సహాయపడుతుంది",
            "or": "ଏକ ଇଣ୍ଟର୍ନସିପ୍ ଆପଣଙ୍କୁ ଏକ ଶିଳ୍ପର କାର୍ଯ୍ୟ ପରିବେଶ ବିଷୟରେ ଅଧିକ ଜାଣିବାରେ ସାହାଯ୍ୟ କରିପାରେ",
            "as": "এটা ইন্টাৰ্ণশ্বিপে তোমাক উদ্যোগ এটাৰ কামৰ পৰিৱেশৰ বিষয়ে অধিক জনাত সহায় কৰিব পাৰে",
            "gu": "ઇન્ટર્નશિપ તમને ઉદ્યોગના કામના વાતાવરણ વિશે વધુ જાણકારી મેળવવામાં મદદ કરશે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "An internship is a one-year long job that helps you learn more about an industry",
            "hi": "इन्टर्नशिप एक-वर्ष का जॉब होता है जो आपको उद्योग के बारे में अधिक जानकारी प्रापर करने में मदद करता है।",
            "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಒಂದು ವರ್ಷದ ದೀರ್ಘಾವಧಿಯ ಕೆಲಸವಾಗಿದ್ದು ಅದು ಉದ್ಯಮದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ",
            "te": "ఇంటర్న్‌షిప్ అనేది ఒక సంవత్సరం సుదీర్ఘ ఉద్యోగం, ఇది పరిశ్రమ గురించి మరింత తెలుసుకోవడానికి మీకు సహాయపడుతుంది",
            "or": "ଏକ ଇଣ୍ଟର୍ନସିପ୍ ହେଉଛି ଏକ ବର୍ଷର ଲମ୍ବା ଚାକିରି ଯାହା ଆପଣଙ୍କୁ ଏକ ଶିଳ୍ପ ବିଷୟରେ ଅଧିକ ଜାଣିବାରେ ସାହାଯ୍ୟ କରେ ।",
            "as": "ইণ্টাৰ্নশ্বিপ হৈছে এক বছৰৰ দীঘলীয়া কাম যি তোমাক উদ্যোগ এটাৰ বিষয়ে অধিক শিকাত সহায় কৰে",
            "gu": "ઇન્ટર્નશિપ એ એક વર્ષની નોકરી છે જે તમને ઉદ્યોગ વિશે વધુ જાણવામાં મદદ કરે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Think again! They are similar but not the same. Unlike internships, they can extend from a few months to a couple of years.",
            "hi": "फिर से विचार करें! वे एक जैसे तो हैं लेकिन समान नहीं हैं। इन्टर्नशिप के विपरीत, इसे कुछ महीनों से लेकर कुछ सालों तक बढ़ाया जा सकता है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಅವು ಹೋಲುತ್ತವೆ ಆದರೆ ಒಂದೇ ಅಲ್ಲ. ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳಿಗಿಂತ ಭಿನ್ನವಾಗಿ, ಅವು ಕೆಲವು ತಿಂಗಳುಗಳಿಂದ ಒಂದೆರಡು ವರ್ಷಗಳವರೆಗೆ ವಿಸ್ತರಿಸಬಹುದು.",
            "te": "మరలా ఆలోచించు! అవి ఒకేలా ఉంటాయి కానీ ఒకేలా ఉండవు. ఇంటర్న్‌షిప్‌ల మాదిరిగా కాకుండా, అవి కొన్ని నెలల నుండి కొన్ని సంవత్సరాల వరకు పొడిగించవచ్చు.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ସେଗୁଡିକ ସମାନ ପରି କିନ୍ତୁ ସମାନ ନୁହଁନ୍ତି । ଇଣ୍ଟର୍ନସିପ୍ ପରି, ସେଗୁଡିକ କିଛି ମାସରୁ ଦୁଇ ବର୍ଷ ପର୍ଯ୍ୟନ୍ତ ହୋଇପାରେ ।",
            "as": "আকৌ চিন্তা কৰা! সেইবোৰ একে ধৰণৰ কিন্তু সম্পূৰ্ণ একে নহয়। ইণ্টাৰ্নশ্বিপৰ বিপৰীতে, সেইবোৰ কেইমাহমানৰ পৰা কেইবছৰমানলৈ কেতিয়াবা প্ৰসাৰিত হ'ব পাৰে।",
            "gu": "ફરીથી વિચાર! તેઓ એક સમાન છે પરંતુ સરખા નથી. ઇન્ટર્નશીપ્સથી અલગ, તે થોડા મહિનાથી બે વર્ષ સુધીની હોઈ શકે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "शाबाश!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "గొప్ప పని!",
            "or": "ବହୁତ ଭଲ!",
            "as": "ভাল কাম!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! Internships are short term work opportunities usually for a few weeks or months.",
            "hi": "फिर से विचार करें! इन्टर्नशिप अल्पअवधी के लिए काम करने के अवसर होते हैं जो कुछ हफ्तों से लेकर कुछ महीनों के होते हैं।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳು ಸಾಮಾನ್ಯವಾಗಿ ಕೆಲವು ವಾರಗಳು ಅಥವಾ ತಿಂಗಳುಗಳವರೆಗೆ ಅಲ್ಪಾವಧಿಯ ಕೆಲಸದ ಅವಕಾಶಗಳಾಗಿವೆ.",
            "te": "మరలా ఆలోచించు! ఇంటర్న్‌షిప్‌లు సాధారణంగా కొన్ని వారాలు లేదా నెలలపాటు స్వల్పకాలిక ఉద్యోగ అవకాశాలు.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ଇଣ୍ଟର୍ନସିପ୍ ସାଧାରଣତଃ କିଛି ସପ୍ତାହ କିମ୍ବା ମାସ ପାଇଁ ସ୍ୱଳ୍ପ କାଳୀନ କାର୍ଯ୍ୟ ସୁଯୋଗ ।",
            "as": "আকৌ চিন্তা কৰা! ইণ্টাৰ্নশ্বিপ হৈছে সাধাৰণতে কেইসপ্তাহ বা মাহৰ বাবে কম সময়ৰ বাবে কাম কৰাৰ সুযোগ।",
            "gu": "ફરીથી વિચાર! ઇન્ટર્નશીપ સામાન્ય રીતે થોડા અઠવાડિયા કે મહિનાઓ માટે ઓછા ગાળાની નોકરીની તકો હોય છે."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ3",
            "hi": "",
            "ka": "MCQ3",
            "te": "MCQ3",
            "or": "MCQ3",
            "as": "MCQ3",
            "gu": "MCQ3"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia studies the college brochure to identify which courses have the most jobs available post-training. Knowing trends helps you plan your career and decide what to learn to be up to date. Select which of the \nfollowing statements about trends is true:",
            "hi": "रजिया कॉलेज के ब्रोशर को पढ़ती है और पता करती है कि कौन सा कोर्स करने ज्यादा जॉब अवसर उपलब्ध हैं।रुझान के बारे में जानने से आपको अपने करियर के बारे में प्लान करने में और अपडेट रहने के लिए क्या पढ़ना चाहिए यह जानने में मदद मिलती है। \n रुझान के बारे में निम्नलिखित में से कौन सा विधान सही है वह चुनें:",
            "ka": "ತರಬೇತಿಯ ನಂತರ ಯಾವ ಕೋರ್ಸ್‌ಗಳಲ್ಲಿ ಹೆಚ್ಚು ಉದ್ಯೋಗಗಳು ಲಭ್ಯವಿವೆ ಎಂಬುದನ್ನು ಗುರುತಿಸಲು ರಝಿಯಾ ಕಾಲೇಜು ಕರಪತ್ರವನ್ನು ಅಧ್ಯಯನ ಮಾಡುತ್ತಾಳೆ. ಟ್ರೆಂಡ್‌ಗಳನ್ನು ತಿಳಿದುಕೊಳ್ಳುವುದು ನಿಮ್ಮ ವೃತ್ತಿಯನ್ನು ಯೋಜಿಸಲು ಮತ್ತು ನವೀಕೃತವಾಗಿರಲು ಏನನ್ನು ಕಲಿಯಬೇಕೆಂದು ನಿರ್ಧರಿಸಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.ಈ ಕೆಳಗಿನ ಪ್ರವೃತ್ತಿಗಳ ಬಗ್ಗೆ ಯಾವ ಹೇಳಿಕೆಗಳು ನಿಜ ಎಂದು ಆಯ್ಕೆ ಮಾಡಿ:",
            "te": "\"శిక్షణా తరువాత ఏయే కోర్సుల్లో అత్యధిక ఉద్యోగాలు అందుబాటులో ఉన్నాయో గుర్తించడానికి రజియా కళాశాల బ్రోచర్‌ను అధ్యయనం చేస్తుంది. ట్రెండ్‌లను తెలుసుకోవడం మీ కెరీర్‌ప్రణాళిక చేసుకోవడంలో మరియు తాజాగా ఏమి నేర్చుకోవాలో నిర్ణయించుకోవడంలో మీకు సహాయపడుతుంది. వాటిలో దేనిని ఎంచుకోండి\n ట్రెండ్‌ల గురించి కింది ప్రకటనలు నిజం:\"",
            "or": "କେଉଁ ପାଠ୍ୟକ୍ରମରେ ତାଲିମ ପରେ ସର୍ବାଧିକ ଚାକିରି ଉପଲବ୍ଧ ତାହା ଚିହ୍ନଟ କରିବା ପାଇଁ ରଜିଆ କଲେଜ ବ୍ରୋସର ଅଧ୍ୟୟନ କରନ୍ତି । ଟ୍ରେଣ୍ଡ ଜାଣିବା ଆପଣଙ୍କୁ ଆପଣଙ୍କ କ୍ୟାରିୟର ଯୋଜନା କରିବାରେ ଏବଂ ଅପ୍ ଟୁ ଡେଟ୍ ହେବା ଶିଖିବା ରେ ସାହାଯ୍ୟ କରେ । ଟ୍ରେଣ୍ଡଗୁଡିକ ବିଷୟରେ ନିମ୍ନଲିଖିତ ଉକ୍ତିଗୁଡିକ ମଧ୍ୟରୁ କେଉଁଟି ସତ୍ୟ ଚୟନ କରନ୍ତୁ:",
            "as": "প্ৰশিক্ষণৰ পিছত কোনবোৰ পাঠ্যক্ৰমত আটাইতকৈ বেছি চাকৰি পাব পাৰি তাক জানিবলৈ ৰাজিয়াই কলেজৰ প্ৰচাৰ প্ৰত্ৰিকাখন পঢ়ে। ট্ৰেণ্ড জনাটোৱে তোমাক তোমাৰ কেৰিয়াৰৰ পৰিকল্পনা কৰাত সহায় কৰে আৰু আপ টু ডেট হ'বলৈ কি শিকিব লাগে সেইটো জনাত সহায় কৰে। \n ট্ৰেণ্ডৰ বিষয়ে নিম্নলিখিত মন্তব্যবোৰৰ কোনটো সঁচা বাছনি কৰা:",
            "gu": "રઝિયા યુનિવર્સિટીના પુસ્તકમાં તપાસ કરે છે કે તાલીમ પછી કયા અભ્યાસક્રમોમાં સૌથી વધુ નોકરીઓ છે. વલણો જાણવાથી તમને તમારી કારકિર્દીની યોજના બનાવવામાં અને અપ ટુ ડેટ રહેવા માટે તમારે શું શીખવાની જરૂર છે તે નક્કી કરવામાં મદદ મળશે. નીચેનામાંથી કયું વિકાસ નિવેદન સાચું છે તે પસંદ કરો:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Some trends can be short term and create only a temporary increase in jobs or skills required.",
            "hi": "कुछ रुझान अल्पकालिक होते हैं और बस कुछ सी समय के लिए जॉब निर्माण करते हैं या इस कौशल की आवश्यकता होती है।",
            "ka": "ಕೆಲವು ಪ್ರವೃತ್ತಿಗಳು ಅಲ್ಪಾವಧಿಯದ್ದಾಗಿರಬಹುದು ಮತ್ತು ಅಗತ್ಯವಿರುವ ಉದ್ಯೋಗಗಳು ಅಥವಾ ಕೌಶಲ್ಯಗಳಲ್ಲಿ ತಾತ್ಕಾಲಿಕ ಹೆಚ್ಚಳವನ್ನು ಮಾತ್ರ ರಚಿಸಬಹುದು.",
            "te": "కొన్ని ట్రెండ్‌లు స్వల్పకాలికంగా ఉంటాయి మరియు అవసరమైన ఉద్యోగాలు లేదా నైపుణ్యాలలో తాత్కాలిక పెరుగుదలను మాత్రమే సృష్టించవచ్చు.",
            "or": "କିଛି ଟ୍ରେଣ୍ଡ ସ୍ୱଳ୍ପ କାଳୀନ ହୋଇପାରେ ଏବଂ ଆବଶ୍ୟକ ଚାକିରି କିମ୍ବା ଦକ୍ଷତାରେ କେବଳ ଅସ୍ଥାୟୀ ବୃଦ୍ଧି ସୃଷ୍ଟି କରିପାରେ ।",
            "as": "কিছুমান ট্ৰেণ্ড কম সময়ৰ বাবে হ'ব পাৰে আৰু কেৱল অস্থায়ী সময়ৰ বাবে চাকৰি বা দক্ষতাৰ সৃষ্টি কৰিব পাৰে।",
            "gu": "કેટલીક પ્રક્રિયાઓ ટૂંકી હોઈ શકે છે અને અસ્થાયી નોકરીઓ અથવા આવશ્યક કુશળતાની સંખ્યામાં વધારો કરી શકે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "To know the trend about an industry or area of work you need to do research & networking.",
            "hi": "किसी उद्योग या कार्यक्षेत्र से संबंधित रुझान के बारे में जानने के लिए आपको रिसर्च और नेटवर्किंग करने की आवश्यकता हैं।",
            "ka": "ಉದ್ಯಮ ಅಥವಾ ಕೆಲಸದ ಕ್ಷೇತ್ರದ ಪ್ರವೃತ್ತಿಯನ್ನು ತಿಳಿಯಲು ನೀವು ಸಂಶೋಧನೆ ಮತ್ತು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮಾಡಬೇಕಾಗಿದೆ.",
            "te": "పరిశ్రమ లేదా పని ప్రాంతం గురించిన ట్రెండ్‌ని తెలుసుకోవడానికి మీరు పరిశోధన & నెట్‌వర్కింగ్ చేయాలి.",
            "or": "ଏକ ଶିଳ୍ପ କିମ୍ବା କାର୍ଯ୍ୟକ୍ଷେତ୍ର ବିଷୟରେ ଟ୍ରେଣ୍ଡ ଜାଣିବା ପାଇଁ ଆପଣଙ୍କୁ ଅନୁସନ୍ଧାନ ଏବଂ ନେଟୱାର୍କିଂ କରିବା ଆବଶ୍ୟକ ।",
            "as": "উদ্যোগ বা কামৰ ক্ষেত্ৰৰ বিষয়ে ট্ৰেণ্ড জানিবলৈ তুমি গৱেষণা আৰু নেটৱৰ্কিং কৰিব লাগিব।",
            "gu": "તમારી કંપની અથવા તમારા કાર્ય ક્ષેત્ર વિશે જાણવા માટે, તમારે સંશોધન અને નેટવર્કિંગ કરવાની જરૂર છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "It is always a good decision to follow the trend when choosing a course to study",
            "hi": "अध्ययन के लिए कोर्स का चुनाव करते समय रुझान को फ़ॉलो करना हमेशा सही निर्णय होता है।",
            "ka": "ಅಧ್ಯಯನ ಮಾಡಲು ಕೋರ್ಸ್ ಅನ್ನು ಆಯ್ಕೆಮಾಡುವಾಗ ಯಾವಾಗಲೂ ಪ್ರವೃತ್ತಿಯನ್ನು ಅನುಸರಿಸುವುದು ಉತ್ತಮ ನಿರ್ಧಾರ",
            "te": "చదువుకోవడానికి కోర్సును ఎంచుకున్నప్పుడు ట్రెండ్‌ను అనుసరించడం ఎల్లప్పుడూ మంచి నిర్ణయం",
            "or": "ଅଧ୍ୟୟନ ପାଇଁ ଏକ ପାଠ୍ୟକ୍ରମ ବାଛିବା ସମୟରେ ଟ୍ରେଣ୍ଡ ଅନୁସରଣ କରିବା ସର୍ବଦା ଏକ ଭଲ ନିଷ୍ପତ୍ତି ।",
            "as": "অধ্যয়নৰ বাবে পাঠ্যক্ৰম এটা বাছনি কৰোঁতে ট্ৰেণ্ড অনুসৰণ কৰাটো সদায়ে এটা ভাল সিদ্ধান্ত",
            "gu": "અભ્યાસ માટે વિષયો પસંદ કરતી વખતે વલણોને અનુસરવાનું હંમેશા સારો વિચાર છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Correct.",
            "hi": "सही.",
            "ka": "ಸರಿ",
            "te": "సరైనది.",
            "or": "ଠିକ୍‌।",
            "as": "শুদ্ধ।",
            "gu": "સાચું."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Correct.",
            "hi": "सही.",
            "ka": "ಸರಿ",
            "te": "సరైనది.",
            "or": "ଠିକ୍‌।",
            "as": "শুদ্ধ।",
            "gu": "સાચું."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Oops! It is also important to review your decision based on your abilities & interests.",
            "hi": "ओहो! अपनी क्षमता और दिलचस्पी के अनुसार अपने निर्णय को परखना महत्वपूर्ण है।",
            "ka": "ಅಯ್ಯೋ! ನಿಮ್ಮ ಸಾಮರ್ಥ್ಯಗಳು ಮತ್ತು ಆಸಕ್ತಿಗಳ ಆಧಾರದ ಮೇಲೆ ನಿಮ್ಮ ನಿರ್ಧಾರವನ್ನು ಪರಿಶೀಲಿಸುವುದು ಸಹ ಮುಖ್ಯವಾಗಿದೆ.",
            "te": "అయ్యో! మీ సామర్థ్యాలు మరియు ఆసక్తుల ఆధారంగా మీ నిర్ణయాన్ని సమీక్షించడం కూడా ముఖ్యం.",
            "or": "ଓଃ! ତୁମର ଦକ୍ଷତା ଏବଂ ରୁଚି ଉପରେ ଆଧାର କରି ତୁମର ନିଷ୍ପତ୍ତିର ସମୀକ୍ଷା କରିବା ମଧ୍ୟ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ।",
            "as": "উফ! তোমাৰ সামৰ্থ্য আৰু স্বাৰ্থৰ ওপৰত আধাৰিত কৰি তোমাৰ সিদ্ধান্ত পুনৰ পৰীক্ষা কৰাটোও জৰুৰী।",
            "gu": "અરે! તમારી તાકાત અને રસના આધારે તમારા નિર્ણયોનું મૂલ્યાંકન કરવું પણ મહત્વપૂર્ણ છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Junior Naturalist",
            "hi": "जूनियर प्रकृतिवादी",
            "ka": "**THREAD_END ** _ಜೂನಿಯರ್ ನ್ಯಾಚುರಲಿಸ್ಟ್",
            "te": "**THREAD_END**_జూనియర్ ప్రకృతి శాస్త్రవేత్త",
            "or": "**THREAD_END** _କନିଷ୍ଠ ପ୍ରକୃତିବାଦୀ",
            "as": "**থ্ৰেড_এণ্ড**_কনিষ্ঠ প্ৰকৃতিবিদ",
            "gu": "**THREAD_END**_જુનિયર પ્રકૃતિવાદી"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Razia that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने रजिया के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँची है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकती है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n \n ನೀವು ರಝಿಯಾಗಾಗಿ ಮಾಡಿದ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ.ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు రజియా కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ! \n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି! \n ଆପଣ ରଜିଆ ପାଇଁ ନିଷ୍ପତ୍ତିଗୁଡିକ ପସନ୍ଦ କରିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତିକୁ ଆଗେଇ ନେଇଛି । ଆପଣ କରୁଥିବା ପସନ୍ଦଗୁଡିକ ଉପରେ ଆଧାର କରି ବିଭିନ୍ନ ପ୍ରକାରର ଶେଷ ଅଛି । ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହାଁନ୍ତି କି?",
            "as": "অভিনন্দন!\n তুমি এটা যাত্ৰা সমাপ্ত কৰিলা!\n \n তুমি ৰাজিয়াৰ বাবে বাছনি কৰিছা যাৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত আধাৰিত কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ জানিব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે પ્રવાસ પૂર્ણ કર્યો છે!\n\nતમે આ સંભવિત અંત તરફ દોરી જવા માટે રઝિયાની પસંદગી કરી. તમે જે પસંદ કરો છો તેના આધારે વિવિધ અંત છે. શું તમે અન્ય વિસ્તારોની શોધખોળ કરવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ର ଉପରେ ନଜର ପକାନ୍ତୁ",
            "as": "মানচিত্ৰখন চোৱা",
            "gu": "ચાલો નકશા તરફ નજર નાખીએ"
          }
        }
      ]
    },
    "scene36": {
      "page0": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_1_1_Upskill on communication skills",
            "hi": "संवाद कौशल को अपस्किल करना",
            "ka": "B8_1_1_ಸಂವಹನ ಕೌಶಲ್ಯಗಳ ಮೇಲಿನ ಕೌಶಲ್ಯ",
            "te": "B8_1_1_ సంభాషణ నైపుణ్యాలు మీద అధిక నైపుణ్యం",
            "or": "B8_1_1_ଯୋଗାଯୋଗ ଦକ୍ଷତାରେ ଅପସ୍କିଲ",
            "as": "B8_1_1_যোগাযোগ দক্ষতাৰ ওপৰত আপস্কিল",
            "gu": "B8_1_1_વાર્તાલાભ કૌશલ્ય પર આવડત"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia feels she needs to build confidence in her communication skills.",
            "hi": "रजिया को लगता है कि उसे अपने संवाद कौशल सुधारने के लिए विश्वास बढ़ाने की जरूरत है।",
            "ka": "ರಝಿಯಾ ತನ್ನ ಸಂವಹನ ಕೌಶಲ್ಯದಲ್ಲಿ ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳಬೇಕು ಎಂದು ಭಾವಿಸುತ್ತಾಳೆ.",
            "te": "రజియా తన సంభాషణ నైపుణ్యాలు పై నమ్మకాన్ని పెంచుకోవాలని భావిస్తోంది.",
            "or": "ରଜିଆ ଅନୁଭବ କରନ୍ତି ଯେ ତାଙ୍କୁ ତାଙ୍କ ଯୋଗାଯୋଗ ଦକ୍ଷତା ଉପରେ ଆତ୍ମବିଶ୍ୱାସ ସୃଷ୍ଟି କରିବା ଆବଶ୍ୟକ ।",
            "as": "ৰাজিয়াই ভাবে যে তাই তাইৰ যোগাযোগ দক্ষতাৰ ওপৰত আত্মবিশ্বাস গঢ়ি তুলিব লাগিব।",
            "gu": "રઝિયાને લાગે છે કે તેને તેની વાર્તાલાભ કૌશલ્યમાં આત્મવિશ્વાસ વધારવાની જરૂર છે."
          }
        }
      ],
      "page1": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_1_2",
            "hi": "B8_1_2",
            "ka": "B8_1_2",
            "te": "B8_1_2",
            "or": "B8_1_2",
            "as": "B8_1_2",
            "gu": "B8_1_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She gets a book called 'How do you develop self-confidence and improve public speaking' from the resort library.",
            "hi": "वह एक किताब लेती है जिसका नाम है ‘आत्मविश्वास कैसे विकसित करें और पब्लिक स्पीकिंग कैसे सुधारें’।",
            "ka": "ರೆಸಾರ್ಟ್ ಲೈಬ್ರರಿಯಿಂದ ಅವಳು 'ನೀವು ಆತ್ಮ ವಿಶ್ವಾಸವನ್ನು ಹೇಗೆ ಬೆಳೆಸುತ್ತೀರಿ ಮತ್ತು ಸಾರ್ವಜನಿಕ ಭಾಷಣವನ್ನು ಸುಧಾರಿಸುತ್ತೀರಿ' ಎಂಬ ಪುಸ್ತಕವನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
            "te": "ఆమె రిసార్ట్ లైబ్రరీ నుండి 'మీరు ఆత్మవిశ్వాసాన్ని ఎలా పెంచుకుంటారు మరియు బహిరంగ ప్రసంగంని మెరుగుపరచుకోవాలి ' అనే పుస్తకాన్ని పొందారు.",
            "or": "ସେ ରିସର୍ଟ ଲାଇବ୍ରେରୀରୁ 'ତୁମେ କିପରି ଆତ୍ମବିଶ୍ୱାସ ବିକଶିତ କରିବ ଏବଂ ସାର୍ବଜନୀନ ଭାଷଣରେ ଉନ୍ନତି ଆଣିବ' ନାମକ ଏକ ପୁସ୍ତକ ପାଆନ୍ତି ।",
            "as": "তাই ৰিজৰ্ট লাইব্ৰেৰীৰ পৰা 'আপুনি কেনেদৰে আত্মবিশ্বাস বিকাশ কৰিব আৰু ৰাজহুৱা স্থানত কথা কোৱা উন্নত কৰিব' নামৰ এখন কিতাপ লাভ কৰে।",
            "gu": "તેને રિસોર્ટ લાઇબ્રેરીમાંથી એક પુસ્તક મળ્યું જેમાં આત્મવિશ્વાસ વિકસાવશો અને જાહેરમાં બોલવામાં સુધારો કરશો કેવી રીતે કરવું છે."
          }
        }
      ],
      "page2": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_1_3",
            "hi": "B8_1_3",
            "ka": "B8_1_3",
            "te": "B8_1_3",
            "or": "B8_1_3",
            "as": "B8_1_3",
            "gu": "B8_1_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Among the many takeaways from the book, one thing that stands out for her - ``One of the tricks to public speaking and fighting the fear of the public is to practice with your friends.”",
            "hi": "इसमें दी हुई कई बातों में से उसे एक बात बहुत ज्यादा पसंद आयी, वह है – “पब्लिक स्पीकिंग सुधारने के लिए और लोगों के डर से लड़ने के लिए, अपने दोस्तों के साथ अभ्यास करें।“",
            "ka": "ಪುಸ್ತಕದ ಅನೇಕ ಟೇಕ್‌ಅವೇಗಳಲ್ಲಿ, ಅವಳಿಗೆ ಎದ್ದು ಕಾಣುವ ಒಂದು ವಿಷಯ - ``ಸಾರ್ವಜನಿಕವಾಗಿ ಮಾತನಾಡುವ ಮತ್ತು ಸಾರ್ವಜನಿಕರ ಭಯವನ್ನು ಹೋರಾಡುವ ತಂತ್ರಗಳಲ್ಲಿ ಒಂದು ನಿಮ್ಮ ಸ್ನೇಹಿತರೊಂದಿಗೆ ಅಭ್ಯಾಸ ಮಾಡುವುದು.",
            "te": "పుస్తకం నుండి అనేకమైన తీసుకెళ్లే వాటిలో ఆమెకు ప్రత్యేకంగా నిలిచే ఒక విషయం ఏమిటంటే - `` బహిరంగ ప్రసంగం మరియు ప్రజల భయంతో పోరాడే ఉపాయాలలో ఒకటి మీ స్నేహితులతో సాధన చేయడం.\"",
            "or": "ପୁସ୍ତକର ଅନେକ ଗ୍ରହଣୀଯ ଉପଦେଶ ମଧ୍ୟରୁ, ଗୋଟିଏ ଜିନିଷ ଯାହା ତାଙ୍କ ପାଇଁ ଉପଯୁକ୍ତ - '' ସାର୍ବଜନୀନ ଭାଷଣ ଏବଂ ଲୋକମାନଙ୍କ ଭୟ ସହିତ ଲଢିବାର ଅନ୍ୟତମ କୌଶଳ ହେଉଛି ତୁମର ସାଙ୍ଗମାନଙ୍କ ସହିତ ଅଭ୍ୟାସ କରିବା ।",
            "as": "কিতাপখনৰ পৰা পোৱা বহুতো উপলব্ধিৰ ভিতৰত, তাইৰ বাবে এটা কথা আছে - \"ৰাজহুৱাভাৱে কথা কোৱা আৰু ৰাইজলৈ লগা ভয়ৰ সৈতে যুঁজ দিয়াৰ এটা কৌশল হ'ল আপোনাৰ বন্ধুবৰ্গৰ সৈতে অনুশীলন কৰা।\"",
            "gu": "પુસ્તકમાંથી ઘણી બાબતોમાં સિખવા જેવી છે એમની એક વસ્તુ તેના માટે અલગ છે - જાહેરમાં બોલવાની અને પ્રેક્ષકોની ચિંતા સામે લડવાની એક યુક્તિ તમારા મિત્રો સાથે પ્રેક્ટિસ કરવી છે."
          }
        }
      ],
      "page3": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_2_1",
            "hi": "B8_2_1",
            "ka": "B8_2_1",
            "te": "B8_2_1",
            "or": "B8_2_1",
            "as": "B8_2_1",
            "gu": "B8_2_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She finds her colleagues to  practice tour sessions with them.",
            "hi": "वह अपने सहकर्मियों की मदद लेकर उनके साथ टूर सेशन का अभ्यास करती है।",
            "ka": "ಅವರೊಂದಿಗೆ ಪ್ರವಾಸದ ಅವಧಿಗಳನ್ನು ಅಭ್ಯಾಸ ಮಾಡಲು ತನ್ನ ಸಹೋದ್ಯೋಗಿಗಳನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾಳೆ.",
            "te": "ఆమె తన సహోద్యోగులలో టూర్ సెషన్‌లను సాధన చేయడానికి కనుగొంటుంది.",
            "or": "ସେ ତାଙ୍କ ସହକର୍ମୀମାନଙ୍କୁ ସେମାନଙ୍କ ସହିତ ଭ୍ରମଣ ଅଧିବେଶନ ଅଭ୍ୟାସ କରିଲେ ।",
            "as": "তাই তাইৰ সহকৰ্মীসকলক তেওঁলোকৰ সৈতে ভ্ৰমণ অধিৱেশনৰ অনুশীলন কৰিবলৈ বিচাৰি লয়।",
            "gu": "તે તેના સાથે કામ કરવાવાળાને તેમની સાથે પ્રવાસ સત્રોની તૈયારી કરવા માટે શોધે છે."
          }
        }
      ],
      "page4": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_2_2",
            "hi": "B8_2_2",
            "ka": "B8_2_2",
            "te": "B8_2_2",
            "or": "B8_2_2",
            "as": "B8_2_2",
            "gu": "B8_2_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia’s colleagues feel she is already good in communication as she has been working at the front desk for some time now.",
            "hi": "रजिया के सहकर्मियों को लगता हैं कि वह उसका संवाद कौशल पहले से अच्छा है क्योंकि वह बहुत समय से फ्रंट डेस्क पर काम कर रही है।",
            "ka": "ರಝಿಯಾಳ ಸಹೋದ್ಯೋಗಿಗಳು ಅವಳು ಕೆಲವು ಸಮಯದಿಂದ ಫ್ರಂಟ್ ಡೆಸ್ಕ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿರುವುದರಿಂದ ಅವಳು ಈಗಾಗಲೇ ಸಂವಹನದಲ್ಲಿ ಉತ್ತಮವಾಗಿದ್ದಾಳೆ ಎಂದು ಭಾವಿಸುತ್ತಾರೆ.",
            "te": "రజియా కొంత కాలంగా ఫ్రంట్ డెస్క్‌లో పని చేస్తున్నందున ఆమె సంభాషించడం లో ఇప్పటికే మంచిదని ఆమె సహోద్యోగులు భావిస్తున్నారు.",
            "or": "ରଜିଆଙ୍କ ସହକର୍ମୀମାନେ ଅନୁଭବ କରନ୍ତି ଯେ ସେ ଯୋଗାଯୋଗରେ ଭଲ ଅଛନ୍ତି କାରଣ ସେ କିଛି ସମୟ ଧରି ଫ୍ରଣ୍ଟ ଡେସ୍କରେ କାମ କରୁଛନ୍ତି ।",
            "as": "ৰাজিয়াৰ সহকৰ্মীসকলে অনুভৱ কৰে যে তাই এনেও যোগাযোগত ভাল কিয়নো তাই কিছু দিনৰ পৰা ফ্ৰন্ট ডেস্কত কাম কৰি আছে।",
            "gu": "રઝિયાના છેલ્લા કેટલાક સમયથી ફ્રન્ટ ડેસ્ક પર કામ કરી રહી છે તેથી સાથીદારોને લાગે છે કે તે વાતચીત કરવામાં ખૂબ સારી છે."
          }
        }
      ],
      "page5": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_3_1",
            "hi": "B8_3_1",
            "ka": "B8_3_1",
            "te": "B8_3_1",
            "or": "B8_3_1",
            "as": "B8_3_1",
            "gu": "B8_3_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia is confident about her preparation. After 2 weeks, she goes for the interview.",
            "hi": "रजिया अपने तैयारी को लेकर खुश है। 2 हफ्ते बाद, वह इन्टरव्यू के लिए जाती है।",
            "ka": "ರಝಿಯಾ ತನ್ನ ತಯಾರಿ ಬಗ್ಗೆ ವಿಶ್ವಾಸ ವ್ಯಕ್ತಪಡಿಸಿದ್ದಾಳೆ. ೨ ವಾರಗಳ ನಂತರ, ಅವಳು ಸಂದರ್ಶನಕ್ಕೆ ಹೋಗುತ್ತಾಳೆ.",
            "te": "రజియా తన సన్నద్ధత పట్ల నమ్మకంగా ఉంది. 2 వారాల తర్వాత, ఆమె ఇంటర్వ్యూకి వెళుతుంది.",
            "or": "ରଜିଆ ତାଙ୍କ ପ୍ରସ୍ତୁତି ବିଷୟରେ ଆତ୍ମବିଶ୍ୱାସୀ । 2 ସପ୍ତାହ ପରେ, ସେ ସାକ୍ଷାତକାର ପାଇଁ ଯାଆନ୍ତି ।",
            "as": "ৰাজিয়া তাইৰ প্ৰস্তুতিৰ বিষয়ে আত্মবিশ্বাসী। 2 সপ্তাহৰ পিছত, তাই ইন্টাৰভিউ দিবলৈ যায়।",
            "gu": "રઝિયાને વિશ્વાસ છે કે તેની તૈયારી સારી છે. 2 અઠવાડિયા પછી, તે ઇન્ટરવ્યુ માટે જાય છે."
          }
        }
      ],
      "page6": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_3_2///",
            "hi": "B8_3_2///",
            "ka": "B8_3_2///",
            "te": "B8_3_2///",
            "or": "B8_3_2///",
            "as": "B8_3_2///",
            "gu": "B8_3_2///"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "But to her surprise, at the interview, Razia is asked many technical questions about the flora and fauna of the Sundarbans. She is unable to answer most of them.",
            "hi": "लेकिन इन्टरव्यू में उसे सुंदरबन के वनस्पतियों और जीवों के बारे में कई टेक्निकल प्रश्न पूछे जाते हैं जिसके कारण वह चकित हो जाती है और कई प्रश्नों का ठीक से जवाब नहीं दे पाती।",
            "ka": "ಆದರೆ ಅವಳ ಆಶ್ಚರ್ಯಕ್ಕೆ, ಸಂದರ್ಶನದಲ್ಲಿ, ರಝಿಯಾಗೆ ಸುಂದರಬನ್ಸ್‌ನ ಸಸ್ಯ ಮತ್ತು ಪ್ರಾಣಿಗಳ ಬಗ್ಗೆ ಅನೇಕ ತಾಂತ್ರಿಕ ಪ್ರಶ್ನೆಗಳನ್ನು ಕೇಳಲಾಯಿತು. ಹೆಚ್ಚಿನವರಿಗೆ ಉತ್ತರಿಸಲು ಆಕೆಗೆ ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ.",
            "te": "కానీ ఆమెను ఆశ్చర్యపరిచే విధంగా, ఇంటర్వ్యూలో, రజియాను సుందర్బన్స్ యొక్క వృక్షజాలం మరియు జంతుజాలం గురించి అనేక సాంకేతిక ప్రశ్నలు అడిగారు. చాలా వాటికి ఆమె సమాధానం చెప్పలేకపోతోంది.",
            "or": "କିନ୍ତୁ ଆଶ୍ଚର୍ଯ୍ୟର କଥା, ସାକ୍ଷାତକାରରେ ରଜିଆଙ୍କୁ ସୁନ୍ଦରବନର ଉଦ୍ଭିଦ ଏବଂ ଜୀବଜନ୍ତୁଙ୍କ ବିଷୟରେ ଅନେକ ବୈଷୟିକ ପ୍ରଶ୍ନ ପଚରାଯାଏ । ସେ ସେମାନଙ୍କ ମଧ୍ୟରୁ ଅଧିକାଂଶର ଉତ୍ତର ଦେବାକୁ ଅସମର୍ଥ ।",
            "as": "কিন্তু তাইক আচৰিত কৰি, ইন্টাৰভিউটোত, ৰাজিয়াক সুন্দৰবনৰ উদ্ভিদ আৰু প্ৰাণীৰ বিষয়ে বহুতো কাৰিকৰী প্ৰশ্ন সোধা হয়। তাই তেওঁলোকৰ বেছিভাগ প্ৰশ্নৰ উত্তৰ দিব নোৱাৰিলে।",
            "gu": "પરંતુ તેના આશ્ચર્ય લાગે છે ઇન્ટરવ્યુમાં, કારણ કે રઝિયાને સુંદરવનની વનસ્પતિ અને પ્રાણીસૃષ્ટિ વિશે વધારે તકનીકી પ્રશ્નો પૂછવામાં આવ્યા હતા. તેના ઘણા બધા જવાબો આપવા કઠિન હતા."
          }
        }
      ],
      "page7": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_3_3",
            "hi": "B8_3_3",
            "ka": "B8_3_3",
            "te": "B8_3_3",
            "or": "B8_3_3",
            "as": "B8_3_3",
            "gu": "B8_3_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "The Interviewers feel Razia is not suitable for the role. She leaves the interview disappointed.",
            "hi": "इन्टरव्यू लेने वालों को लगता है कि रजिया इस रोल के लिए सही नहीं है। वह उदास हो कर इन्टरव्यू से बाहर निकलती है।",
            "ka": "ಸಂದರ್ಶಕರು ಈ ಪಾತ್ರಕ್ಕೆ ರಝಿಯಾ ಸೂಕ್ತವಲ್ಲ ಎಂದು ಭಾವಿಸುತ್ತಾರೆ. ಅವಳು ನಿರಾಶೆಯಿಂದ ಸಂದರ್ಶನವನ್ನು ಬಿಡುತ್ತಾಳೆ.",
            "te": "ఈ పాత్రకు రజియా సరిపోదని ఇంటర్వ్యూ చేసేవారు భావిస్తున్నారు. ఆమె నిరాశతో ఇంటర్వ్యూ నుండి నిష్క్రమించింది.",
            "or": "ସାକ୍ଷାତକର୍ତ୍ତାମାନେ ଅନୁଭବ କରନ୍ତି ଯେ ରଜିଆ ଏହି ଭୂମିକା ପାଇଁ ଉପଯୁକ୍ତ ନୁହଁନ୍ତି । ସେ ସାକ୍ଷାତକାରରୁ ନିରାଶ ହୋଇ ଫେରିଲେ ।",
            "as": "ইন্টাৰভিউ লওতাসকলে অনুভৱ কৰে যে ৰাজিয়া কামটোৰ বাবে উপযুক্ত নহয়। তাই হতাশ হৈ ইন্টাৰভিউটো এৰিছিল।",
            "gu": "રઝિયા આ રોલ માટે યોગ્ય નથી તેવું ઇન્ટરવ્યુ લેનાર ને લાગે છે. તે નિરાશ થઈને ઈન્ટરવ્યુ છોડી દે છે."
          }
        }
      ],
      "page8": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_4_1",
            "hi": "B8_4_1",
            "ka": "B8_4_1",
            "te": "B8_4_1",
            "or": "B8_4_1",
            "as": "B8_4_1",
            "gu": "B8_4_1"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "Razia continues her job at the front desk role where she handles guest registrations, complaints and requests.",
            "hi": "रजिया फ्रंट डेस्क पर अपना जॉब जारी रखती हैं जहाँ वह मेहमानों के रजिस्ट्रेशन, शिकायतें और रिक्वेस्ट को संभालती है।",
            "ka": "ರಝಿಯಾಳು ಅತಿಥಿ ನೋಂದಣಿಗಳು, ದೂರುಗಳು ಮತ್ತು ವಿನಂತಿಗಳನ್ನು ನಿರ್ವಹಿಸುವ ಮುಂಭಾಗದ ಮೇಜಿನ ಪಾತ್ರದಲ್ಲಿ ತಮ್ಮ ಕೆಲಸವನ್ನು ಮುಂದುವರೆಸುತ್ತಾಳೆ.",
            "te": "గెస్ట్ రిజిస్ట్రేషన్, ఫిర్యాదులు మరియు అభ్యర్థనలను నిర్వహించే ఫ్రంట్ డెస్క్ పాత్రలో రజియా తన ఉద్యోగాన్ని కొనసాగిస్తుంది.",
            "or": "ରଜିଆ ଫ୍ରଣ୍ଟ ଡେସ୍କ ଭୂମିକାରେ ଚାକିରି ଜାରି ରଖିଛନ୍ତି ଯେଉଁଠାରେ ସେ ଅତିଥି ପଞ୍ଜିକରଣ, ଅଭିଯୋଗ ଏବଂ ଅନୁରୋଧ ପରିଚାଳନା କରନ୍ତି ।",
            "as": "ৰাজিয়াই সন্মুখৰ ডেস্কত কৰা কাম অব্যাহত ৰাখে য'ত তেওঁ অতিথিৰ পঞ্জীয়ন, অভিযোগ আৰু অনুৰোধ চম্ভালে।",
            "gu": "રઝિયા ફ્રન્ટ ડેસ્ક પર કામ કરવાનું ચાલુ રાખે છે, જ્યાં તે મહેમાનોની નોંધણી, ફરિયાદો અને વિનંતીઓનું સંચાલન કરે છે."
          }
        }
      ],
      "page9": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_4_2",
            "hi": "B8_4_2",
            "ka": "B8_4_2",
            "te": "B8_4_2",
            "or": "B8_4_2",
            "as": "B8_4_2",
            "gu": "B8_4_2"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She is also given some additional responsibility of handling balancing cash accounts.",
            "hi": "उसे और एक जिम्मेदारी भी सौंपी गई है, जो है कैश अकाउंट संभालना।",
            "ka": "ಬ್ಯಾಲೆನ್ಸಿಂಗ್ ನಗದು ಖಾತೆಗಳನ್ನು ನಿರ್ವಹಿಸುವ ಕೆಲವು ಹೆಚ್ಚುವರಿ ಜವಾಬ್ದಾರಿಯನ್ನು ಆಕೆಗೆ ನೀಡಲಾಗಿದೆ.",
            "te": "ఆమెకు బ్యాలెన్సింగ్ నగదు ఖాతాలను నిర్వహించే అదనపు బాధ్యత కూడా ఇవ్వబడింది.",
            "or": "ନଗଦ ଆକାଉଣ୍ଟକୁ ସନ୍ତୁଳିତ କରିବା ପାଇଁ ତାଙ୍କୁ କିଛି ଅତିରିକ୍ତ ଦାୟିତ୍ୱ ମଧ୍ୟ ଦିଆଯାଇଛି ।",
            "as": "তাইক নগদ টকাৰ একাউণ্টৰ হিচাব ৰখাৰ কিছু অতিৰিক্ত দায়িত্বও দিয়া হৈছে।",
            "gu": "તેને કેશ સંચાલન એકાઉન્ટ્સ સંભાળવાની કેટલીક વધારે જવાબદારી પણ આપવામાં આવી છે."
          }
        }
      ],
      "page10": [
        {
          "valuePath": "display",
          "value": {
            "en": "B8_4_3",
            "hi": "B8_4_3",
            "ka": "B8_4_3",
            "te": "B8_4_3",
            "or": "B8_4_3",
            "as": "B8_4_3",
            "gu": "B8_4_3"
          }
        },
        {
          "valuePath": "blocks.0.props.text",
          "value": {
            "en": "She gets to interact with the guests and gets good feedback from them. But she can't help but wonder, how it would be for her if she had got the naturalist job.",
            "hi": "उसे मेहमानों के साथ बातचीत करने का और उनसे अभिप्राय प्राप्त करने का मौका मिलता है। लेकिन वह सोचती रहती है कि अगर उसे नैचरलिस्ट का जॉब मिला होता तो क्या होता।",
            "ka": "ಅವಳು ಅತಿಥಿಗಳೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸುತ್ತಾಳೆ ಮತ್ತು ಅವರಿಂದ ಉತ್ತಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಪಡೆಯುತ್ತಾಳೆ. ಆದರೆ ಅವಳಿಗೆ ನಿಸರ್ಗವಾದಿ ಕೆಲಸ ಸಿಕ್ಕಿದ್ದರೆ ಹೇಗಿರುತ್ತಿತ್ತು ಎಂದು ಆಶ್ಚರ್ಯಪಡದೆ ಇರಲಾರಳು.",
            "te": "ఆమె అతిథులతో సంభాషిస్తుంది మరియు వారి నుండి మంచి అభిప్రాయాన్ని పొందుతుంది. అయితే ఆమెకు ప్రకృతి శాస్త్రవేత్త ఉద్యోగం వస్తే ఎలా ఉంటుందో ఆలోచించకుండా ఉండలేకపోతోంది.",
            "or": "ସେ ଅତିଥିମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରିବାକୁ ପାଆନ୍ତି ଏବଂ ସେମାନଙ୍କଠାରୁ ଭଲ ମତାମତ ପାଆନ୍ତି । କିନ୍ତୁ ସେ ଆଶ୍ଚର୍ଯ୍ୟ ହେବା ବ୍ୟତୀତ ଅନ୍ୟ କୌଣସି ସାହାଯ୍ୟ କରିପାରିବେ ନାହିଁ, ଯଦି ସେ ପ୍ରକୃତିବାଦୀ ଚାକିରି ପାଇଥାନ୍ତେ ତେବେ ଏହା ତାଙ୍କ ପାଇଁ କିପରି ହୋଇଥାନ୍ତା ।",
            "as": "তাই অতিথিসকলৰ সৈতে কথা পাতিবলৈ পায় আৰু তেওঁলোকৰ পৰা ভাল মতামত লাভ কৰে। কিন্তু তাই আচৰিত হোৱাৰ বাহিৰে একো কৰিব নোৱাৰে, যদি তাই প্ৰকৃতিবিদ চাকৰিটো পোৱাহ'লে কিমান যে ভাল লাগিলহেঁতেন।",
            "gu": "તે મહેમાનો સાથે વાતચીત કરવાની અને તેમની પાસેથી સારો પ્રતિસાદ મેળવવાની જરૂર છે. પરંતુ તે પામી શકતી નથી પણ આશ્ચર્ય છે કારણ કે જો તેને પ્રકૃતિવાદીની નોકરી આપવામાં આવી હોત તો કરત."
          }
        }
      ],
      "page12": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ2",
            "hi": "MCQ2",
            "ka": "MCQ2",
            "te": "MCQ2",
            "or": "MCQ2",
            "as": "MCQ2",
            "gu": "MCQ2"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia does an internship at a company that later offers her a job. Internships help us understand the everyday details of jobs and industries. Select which of the following statements about internships are true.",
            "hi": "रजिया ने कंपनी में इन्टर्नशिप की जिसने आगे चलकर उसे जॉब का ऑफर दिया। इन्टर्नशिप की मदद से हम जॉब और उद्योग के हर दिन के काम को विस्तार से समझ सकते हैं। इन्टर्नशिप के बारे में निम्नलिखित में से कौन सा विधान सही है वह चुनें।",
            "ka": "ರಝಿಯಾ ಕಂಪನಿಯಲ್ಲಿ ಇಂಟರ್ನ್‌ಶಿಪ್ ಮಾಡುತ್ತಾಳೆ, ಅದು ನಂತರ ಅವಳಿಗೆ ಕೆಲಸ ನೀಡುತ್ತದೆ. ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳು ಉದ್ಯೋಗಗಳು ಮತ್ತು ಕೈಗಾರಿಕೆಗಳ ದೈನಂದಿನ ವಿವರಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳ ಕುರಿತು ಈ ಕೆಳಗಿನ ಯಾವ ಹೇಳಿಕೆಗಳು ನಿಜವೆಂದು ಆಯ್ಕೆಮಾಡಿ.",
            "te": "రజియా ఒక కంపెనీలో ఇంటర్న్‌షిప్ చేస్తుంది, అది ఆమెకు ఉద్యోగం ఇచ్చింది. ఉద్యోగాలు మరియు పరిశ్రమల రోజువారీ వివరాలను అర్థం చేసుకోవడానికి ఇంటర్న్‌షిప్‌లు మాకు సహాయపడతాయి. ఇంటర్న్‌షిప్‌ల గురించిన కింది స్టేట్‌మెంట్‌లలో ఏది నిజమో ఎంచుకోండి.",
            "or": "ରଜିଆ ଏକ କମ୍ପାନୀରେ ଏକ ଇଣ୍ଟର୍ନସିପ୍ କରନ୍ତି ଯାହା ପରେ ତାଙ୍କୁ ନିଯୁକ୍ତି ପ୍ରଦାନ କରେ । ଇଣ୍ଟର୍ନସିପ୍ ଆମକୁ ଚାକିରି ଏବଂ ଶିଳ୍ପର ଦୈନନ୍ଦିନ ବିବରଣୀ ବୁଝିବାରେ ସାହାଯ୍ୟ କରେ । ଇଣ୍ଟର୍ନସିପ୍ ବିଷୟରେ ନିମ୍ନଲିଖିତ ମଧ୍ୟରୁ କେଉଁ ଉକ୍ତି ଠିକ ତାହା ଚୟନ କରନ୍ତୁ ।",
            "as": "ৰাজিয়াই এটা কোম্পানীত ইন্টাৰ্ণশ্বিপ কৰে যিয়ে পিছত তাইক এটা চাকৰি দিয়ে। ইন্টাৰ্ণশ্বিপে আমাক চাকৰি আৰু উদ্যোগৰ দৈনন্দিন বিৱৰণ বুজাত সহায় কৰে। ইণ্টাৰ্নশ্বিপৰ বিষয়ে নিম্নলিখিত কোনটো মন্তব্য সঁচা বাছনি কৰক।",
            "gu": "રઝિયા એક એવી કંપનીમાં ટ્રેનિંગ કરે છે જે તેને નોકરીની તક મળે છે. તેના લીધે એને નોકરીઓ અને કંપનીઓ વિશેની રોજબરોજની માહિતીને સમજવામાં મદદ મળે છે. અને એના લીધે પ્રવૃત્તિઓ માટે આમાંથી કઈ માહિતી યોગ્ય છે તે પસંદ કરે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Internships and apprenticeships are the same.",
            "hi": "इन्टर्नशिप और अप्रेंटिसशिप समान हैं।",
            "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಮತ್ತು ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್ ಒಂದೇ ಆಗಿದೆ.",
            "te": "ఇంటర్న్‌షిప్‌లు మరియు అప్రెంటిస్‌షిప్‌లు ఒకే విధంగా ఉంటాయి.",
            "or": "ଇଣ୍ଟର୍ନସିପ୍ ଏବଂ ଆପ୍ରେଣ୍ଟିସିପ୍ ସମାନ ।",
            "as": "ইণ্টাৰ্নশ্বিপ আৰু এপ্ৰেণ্টিছশ্বিপ একে।",
            "gu": "ઇન્ટર્નશિપ અને એપ્રેન્ટિસશિપ એક સરખા છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "An internship can help you know more about the working environment of an industry",
            "hi": "इन्टर्नशिप आपको उद्योग के काम करने के माहौल को अच्छे से समझने में मदद करती है।",
            "ka": "ಉದ್ಯಮದ ಕೆಲಸದ ವಾತಾವರಣದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಇಂಟರ್ನ್‌ಶಿಪ್ ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ",
            "te": "పరిశ్రమ యొక్క పని వాతావరణం గురించి మరింత తెలుసుకోవడానికి ఇంటర్న్‌షిప్ మీకు సహాయపడుతుంది",
            "or": "ଏକ ଇଣ୍ଟର୍ନସିପ୍ ଆପଣଙ୍କୁ ଏକ ଶିଳ୍ପର କାର୍ଯ୍ୟ ପରିବେଶ ବିଷୟରେ ଅଧିକ ଜାଣିବାରେ ସାହାଯ୍ୟ କରିପାରେ",
            "as": "এটা ইন্টাৰ্ণশ্বিপে তোমাৰ উদ্যোগ এটাৰ কামৰ পৰিৱেশৰ বিষয়ে অধিক জনাত সহায় কৰিব পাৰে",
            "gu": "ઇન્ટર્નશિપ તમને ઉદ્યોગના કામના વાતાવરણ વિશે વધુ જાણકારી મેળવવામાં મદદ કરશે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "An internship is a one-year long job that helps you learn more about an industry",
            "hi": "इन्टर्नशिप एक-वर्ष का जॉब होता है जो आपको उद्योग के बारे में अधिक जानकारी प्रापर करने में मदद करता है।",
            "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಒಂದು ವರ್ಷದ ದೀರ್ಘಾವಧಿಯ ಕೆಲಸವಾಗಿದ್ದು ಅದು ಉದ್ಯಮದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ",
            "te": "ఇంటర్న్‌షిప్ అనేది ఒక సంవత్సరం సుదీర్ఘ ఉద్యోగం, ఇది పరిశ్రమ గురించి మరింత తెలుసుకోవడానికి మీకు సహాయపడుతుంది",
            "or": "ଏକ ଇଣ୍ଟର୍ନସିପ୍ ହେଉଛି ଏକ ବର୍ଷର ଲମ୍ବା ଚାକିରି ଯାହା ଆପଣଙ୍କୁ ଏକ ଶିଳ୍ପ ବିଷୟରେ ଅଧିକ ଜାଣିବାରେ ସାହାଯ୍ୟ କରେ ।",
            "as": "ইণ্টাৰ্নশ্বিপ হৈছে এক বছৰৰ দীঘলীয়া কাম যি তোমাৰ উদ্যোগ এটাৰ বিষয়ে অধিক শিকাত সহায় কৰে",
            "gu": "ઇન્ટર્નશિપ એ એક વર્ષની નોકરી છે જે તમને ઉદ્યોગ વિશે વધુ જાણવામાં મદદ કરે છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Think again! They are similar but not the same. Unlike internships, they can extend from a few months to a couple of years.",
            "hi": "फिर से विचार करें! वे एक जैसे तो हैं लेकिन समान नहीं हैं। इन्टर्नशिप के विपरीत, इसे कुछ महीनों से लेकर कुछ सालों तक बढ़ाया जा सकता है।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಅವು ಹೋಲುತ್ತವೆ ಆದರೆ ಒಂದೇ ಅಲ್ಲ. ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳಿಗಿಂತ ಭಿನ್ನವಾಗಿ, ಅವು ಕೆಲವು ತಿಂಗಳುಗಳಿಂದ ಒಂದೆರಡು ವರ್ಷಗಳವರೆಗೆ ವಿಸ್ತರಿಸಬಹುದು.",
            "te": "మరలా ఆలోచించు! అవి ఒకేలా ఉంటాయి కానీ ఒకేలా ఉండవు. ఇంటర్న్‌షిప్‌ల మాదిరిగా కాకుండా, అవి కొన్ని నెలల నుండి కొన్ని సంవత్సరాల వరకు పొడిగించవచ్చు.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ସେଗୁଡିକ ସମାନ ପରି କିନ୍ତୁ ସମାନ ନୁହଁନ୍ତି । ଇଣ୍ଟର୍ନସିପ୍ ପରି, ସେଗୁଡିକ କିଛି ମାସରୁ ଦୁଇ ବର୍ଷ ପର୍ଯ୍ୟନ୍ତ ହୋଇପାରେ ।",
            "as": "আকৌ চিন্তা কৰা! সেইবোৰ একে ধৰণৰ কিন্তু সম্পূৰ্ণ একে নহয়। ইণ্টাৰ্নশ্বিপৰ বিপৰীতে, সেইবোৰ কেইমাহমানৰ পৰা কেইবছৰমানলৈহে কেতিয়াবা কামৰ সময়টো বঢ়াই দিব পাৰে।",
            "gu": "ફરીથી વિચાર! તેઓ એક સમાન છે પરંતુ સરખા નથી. ઇન્ટર્નશીપ્સથી અલગ, તે થોડા મહિનાથી બે વર્ષ સુધીની હોઈ શકે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Great job!",
            "hi": "शाबाश!",
            "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ!",
            "te": "గొప్ప పని!",
            "or": "ବହୁତ ଭଲ!",
            "as": "ভাল কাম!",
            "gu": "સરસ કામ!"
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Think again! Internships are short term work opportunities usually for a few weeks or months.",
            "hi": "फिर से विचार करें! इन्टर्नशिप अल्पअवधी के लिए काम करने के अवसर होते हैं जो कुछ हफ्तों से लेकर कुछ महीनों के होते हैं।",
            "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳು ಸಾಮಾನ್ಯವಾಗಿ ಕೆಲವು ವಾರಗಳು ಅಥವಾ ತಿಂಗಳುಗಳವರೆಗೆ ಅಲ್ಪಾವಧಿಯ ಕೆಲಸದ ಅವಕಾಶಗಳಾಗಿವೆ.",
            "te": "మరలా ఆలోచించు! ఇంటర్న్‌షిప్‌లు సాధారణంగా కొన్ని వారాలు లేదా నెలలపాటు స్వల్పకాలిక ఉద్యోగ అవకాశాలు.",
            "or": "ପୁନର୍ବାର ଚିନ୍ତା କରନ୍ତୁ! ଇଣ୍ଟର୍ନସିପ୍ ସାଧାରଣତଃ କିଛି ସପ୍ତାହ କିମ୍ବା ମାସ ପାଇଁ ସ୍ୱଳ୍ପ କାଳୀନ କାର୍ଯ୍ୟ ସୁଯୋଗ ।",
            "as": "আকৌ চিন্তা কৰা! ইণ্টাৰ্নশ্বিপ হৈছে কম সময়ৰ বাবে সাধাৰণতে কেইসপ্তাহমান বা মাহৰ বাবে কাম কৰাৰ সুযোগ।",
            "gu": "ફરીથી વિચાર! ઇન્ટર્નશીપમાં સામાન્ય રીતે થોડા અઠવાડિયા કે મહિનાઓ માટે ઓછા ગાળાની નોકરીની તકો હોય છે."
          }
        }
      ],
      "page13": [
        {
          "valuePath": "display",
          "value": {
            "en": "MCQ3",
            "hi": "MCQ3",
            "ka": "MCQ3",
            "te": "MCQ3",
            "or": "MCQ3",
            "as": "MCQ3",
            "gu": "MCQ3"
          }
        },
        {
          "valuePath": "blocks.0.props.contextText",
          "value": {
            "en": "Razia studies the college brochure to identify which courses have the most jobs available post-training. Knowing trends helps you plan your career and decide what to learn to be up to date. Select which of the \nfollowing statements about trends is true:",
            "hi": "रजिया कॉलेज के ब्रोशर को पढ़ती है और पता करती है कि कौन सा कोर्स करने ज्यादा जॉब अवसर उपलब्ध हैं।रुझान के बारे में जानने से आपको अपने करियर के बारे में प्लान करने में और अपडेट रहने के लिए क्या पढ़ना चाहिए यह जानने में मदद मिलती है। \n रुझान के बारे में निम्नलिखित में से कौन सा विधान सही है वह चुनें:",
            "ka": "ತರಬೇತಿಯ ನಂತರ ಯಾವ ಕೋರ್ಸ್‌ಗಳಲ್ಲಿ ಹೆಚ್ಚು ಉದ್ಯೋಗಗಳು ಲಭ್ಯವಿವೆ ಎಂಬುದನ್ನು ಗುರುತಿಸಲು ರಝಿಯಾ ಕಾಲೇಜು ಕರಪತ್ರವನ್ನು ಅಧ್ಯಯನ ಮಾಡುತ್ತಾಳೆ. ಟ್ರೆಂಡ್‌ಗಳನ್ನು ತಿಳಿದುಕೊಳ್ಳುವುದು ನಿಮ್ಮ ವೃತ್ತಿಯನ್ನು ಯೋಜಿಸಲು ಮತ್ತು ನವೀಕೃತವಾಗಿರಲು ಏನನ್ನು ಕಲಿಯಬೇಕೆಂದು ನಿರ್ಧರಿಸಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.ಈ ಕೆಳಗಿನ ಪ್ರವೃತ್ತಿಗಳ ಬಗ್ಗೆ ಯಾವ ಹೇಳಿಕೆಗಳು ನಿಜ ಎಂದು ಆಯ್ಕೆ ಮಾಡಿ:",
            "te": "\"శిక్షణా తరువాత ఏయే కోర్సుల్లో అత్యధిక ఉద్యోగాలు అందుబాటులో ఉన్నాయో గుర్తించడానికి రజియా కళాశాల బ్రోచర్‌ను అధ్యయనం చేస్తుంది. ట్రెండ్‌లను తెలుసుకోవడం మీ కెరీర్‌ప్రణాళిక చేసుకోవడంలో మరియు తాజాగా ఏమి నేర్చుకోవాలో నిర్ణయించుకోవడంలో మీకు సహాయపడుతుంది. వాటిలో దేనిని ఎంచుకోండి\n ట్రెండ్‌ల గురించి కింది ప్రకటనలు నిజం:\"",
            "or": "କେଉଁ ପାଠ୍ୟକ୍ରମରେ ତାଲିମ ପରେ ସର୍ବାଧିକ ଚାକିରି ଉପଲବ୍ଧ ତାହା ଚିହ୍ନଟ କରିବା ପାଇଁ ରଜିଆ କଲେଜ ବ୍ରୋସର ଅଧ୍ୟୟନ କରନ୍ତି । ଟ୍ରେଣ୍ଡ ଜାଣିବା ଆପଣଙ୍କୁ ଆପଣଙ୍କ କ୍ୟାରିୟର ଯୋଜନା କରିବାରେ ଏବଂ ଅପ୍ ଟୁ ଡେଟ୍ ହେବା ଶିଖିବା ରେ ସାହାଯ୍ୟ କରେ । ଟ୍ରେଣ୍ଡଗୁଡିକ ବିଷୟରେ ନିମ୍ନଲିଖିତ ଉକ୍ତିଗୁଡିକ ମଧ୍ୟରୁ କେଉଁଟି ଠିକ ଚୟନ କରନ୍ତୁ:",
            "as": "প্ৰশিক্ষণৰ পিছত কোনবোৰ পাঠ্যক্ৰমত আটাইতকৈ বেছি চাকৰি পাব পাৰি তাক জানিবলৈ ৰাজিয়াই কলেজৰ প্ৰচাৰ প্ৰত্ৰিকাখন পঢ়ে। ট্ৰেণ্ড জনাটোৱে তোমাক তোমাৰ কেৰিয়াৰৰ পৰিকল্পনা কৰাত সহায় কৰে আৰু আপ টু ডেট হ'বলৈ কি শিকিব লাগে সেইটো জনাত সহায় কৰে। \n ট্ৰেণ্ডৰ বিষয়ে নিম্নলিখিত মন্তব্যবোৰৰ কোনটো সঁচা বাছনি কৰা:",
            "gu": "રઝિયા યુનિવર્સિટીના પુસ્તકમાં તપાસ કરે છે કે તાલીમ પછી કયા અભ્યાસક્રમોમાં સૌથી વધુ નોકરીઓ છે. વલણો જાણવાથી તમને તમારી કારકિર્દીની યોજના બનાવવામાં અને અપ ટુ ડેટ રહેવા માટે તમારે શું શીખવાની જરૂર છે તે નક્કી કરવામાં મદદ મળશે. નીચેનામાંથી કયું વિકાસ નિવેદન સાચું છે તે પસંદ કરો:"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Some trends can be short term and create only a temporary increase in jobs or skills required.",
            "hi": "कुछ रुझान अल्पकालिक होते हैं और बस कुछ सी समय के लिए जॉब निर्माण करते हैं या इस कौशल की आवश्यकता होती है।",
            "ka": "ಕೆಲವು ಪ್ರವೃತ್ತಿಗಳು ಅಲ್ಪಾವಧಿಯದ್ದಾಗಿರಬಹುದು ಮತ್ತು ಅಗತ್ಯವಿರುವ ಉದ್ಯೋಗಗಳು ಅಥವಾ ಕೌಶಲ್ಯಗಳಲ್ಲಿ ತಾತ್ಕಾಲಿಕ ಹೆಚ್ಚಳವನ್ನು ಮಾತ್ರ ರಚಿಸಬಹುದು.",
            "te": "కొన్ని ట్రెండ్‌లు స్వల్పకాలికంగా ఉంటాయి మరియు అవసరమైన ఉద్యోగాలు లేదా నైపుణ్యాలలో తాత్కాలిక పెరుగుదలను మాత్రమే సృష్టించవచ్చు.",
            "or": "କିଛି ଟ୍ରେଣ୍ଡ ସ୍ୱଳ୍ପ କାଳୀନ ହୋଇପାରେ ଏବଂ ଆବଶ୍ୟକ ଚାକିରି କିମ୍ବା ଦକ୍ଷତାରେ କେବଳ ଅସ୍ଥାୟୀ ବୃଦ୍ଧି ସୃଷ୍ଟି କରିପାରେ ।",
            "as": "কিছুমান ট্ৰেণ্ড কম সময়ৰ বাবে হ'ব পাৰে আৰু প্ৰয়োজনীয় চাকৰি বা দক্ষতাত কেৱল অস্থায়ী বৃদ্ধি সৃষ্টি কৰিব পাৰে।",
            "gu": "કેટલીક પ્રક્રિયાઓ ટૂંકી હોઈ શકે છે અને અસ્થાયી નોકરીઓ અથવા આવશ્યક કુશળતાની સંખ્યામાં વધારો કરી શકે છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.display",
          "value": {
            "en": "To know the trend about an industry or area of work you need to do research & networking.",
            "hi": "किसी उद्योग या कार्यक्षेत्र से संबंधित रुझान के बारे में जानने के लिए आपको रिसर्च और नेटवर्किंग करने की आवश्यकता हैं।",
            "ka": "ಉದ್ಯಮ ಅಥವಾ ಕೆಲಸದ ಕ್ಷೇತ್ರದ ಪ್ರವೃತ್ತಿಯನ್ನು ತಿಳಿಯಲು ನೀವು ಸಂಶೋಧನೆ ಮತ್ತು ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮಾಡಬೇಕಾಗಿದೆ.",
            "te": "పరిశ్రమ లేదా పని ప్రాంతం గురించిన ట్రెండ్‌ని తెలుసుకోవడానికి మీరు పరిశోధన & నెట్‌వర్కింగ్ చేయాలి.",
            "or": "ଏକ ଶିଳ୍ପ କିମ୍ବା କାର୍ଯ୍ୟକ୍ଷେତ୍ର ବିଷୟରେ ଟ୍ରେଣ୍ଡ ଜାଣିବା ପାଇଁ ଆପଣଙ୍କୁ ଅନୁସନ୍ଧାନ ଏବଂ ନେଟୱାର୍କିଂ କରିବା ଆବଶ୍ୟକ ।",
            "as": "উদ্যোগ বা কামৰ ক্ষেত্ৰৰ বিষয়ে ট্ৰেণ্ড জানিবলৈ তুমি গৱেষণা আৰু নেটৱৰ্কিং কৰিব লাগিব।",
            "gu": "તમારી કંપની અથવા તમારા કાર્ય ક્ષેત્ર વિશે જાણવા માટે, તમારે સંશોધન અને નેટવર્કિંગ કરવાની જરૂર છે."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.display",
          "value": {
            "en": "It is always a good decision to follow the trend when choosing a course to study",
            "hi": "अध्ययन के लिए कोर्स का चुनाव करते समय रुझान को फ़ॉलो करना हमेशा सही निर्णय होता है।",
            "ka": "ಅಧ್ಯಯನ ಮಾಡಲು ಕೋರ್ಸ್ ಅನ್ನು ಆಯ್ಕೆಮಾಡುವಾಗ ಯಾವಾಗಲೂ ಪ್ರವೃತ್ತಿಯನ್ನು ಅನುಸರಿಸುವುದು ಉತ್ತಮ ನಿರ್ಧಾರ",
            "te": "చదువుకోవడానికి కోర్సును ఎంచుకున్నప్పుడు ట్రెండ్‌ను అనుసరించడం ఎల్లప్పుడూ మంచి నిర్ణయం",
            "or": "ଅଧ୍ୟୟନ ପାଇଁ ଏକ ପାଠ୍ୟକ୍ରମ ବାଛିବା ସମୟରେ ଟ୍ରେଣ୍ଡ ଅନୁସରଣ କରିବା ସର୍ବଦା ଏକ ଭଲ ନିଷ୍ପତ୍ତି ।",
            "as": "অধ্যয়নৰ বাবে পাঠ্যক্ৰম এটা বাছনি কৰোঁতে ট্ৰেণ্ড অনুসৰণ কৰাটো সদায়ে এটা ভাল সিদ্ধান্ত",
            "gu": "અભ્યાસ માટે વિષયો પસંદ કરતી વખતે વલણોને અનુસરવાનું હંમેશા સારો વિચાર છે"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.popUpText",
          "value": {
            "en": "Correct.",
            "hi": "सही.",
            "ka": "ಸರಿ.",
            "te": "సరైనది.",
            "or": "ଠିକ୍‌।",
            "as": "শুদ্ধ।",
            "gu": "સાચું."
          }
        },
        {
          "valuePath": "blocks.0.props.options.1.popUpText",
          "value": {
            "en": "Correct.",
            "hi": "सही.",
            "ka": "ಸರಿ.",
            "te": "సరైనది.",
            "or": "ଠିକ୍‌।",
            "as": "শুদ্ধ।",
            "gu": "સાચું."
          }
        },
        {
          "valuePath": "blocks.0.props.options.2.popUpText",
          "value": {
            "en": "Oops! It is also important to review your decision based on your abilities & interests.",
            "hi": "ओहो! अपनी क्षमता और दिलचस्पी के अनुसार अपने निर्णय को परखना महत्वपूर्ण है।",
            "ka": "ಅಯ್ಯೋ! ನಿಮ್ಮ ಸಾಮರ್ಥ್ಯಗಳು ಮತ್ತು ಆಸಕ್ತಿಗಳ ಆಧಾರದ ಮೇಲೆ ನಿಮ್ಮ ನಿರ್ಧಾರವನ್ನು ಪರಿಶೀಲಿಸುವುದು ಸಹ ಮುಖ್ಯವಾಗಿದೆ.",
            "te": "అయ్యో! మీ సామర్థ్యాలు మరియు ఆసక్తుల ఆధారంగా మీ నిర్ణయాన్ని సమీక్షించడం కూడా ముఖ్యం.",
            "or": "ଓଃ! ତୁମର ଦକ୍ଷତା ଏବଂ ରୁଚି ଉପରେ ଆଧାର କରି ତୁମର ନିଷ୍ପତ୍ତିର ସମୀକ୍ଷା କରିବା ମଧ୍ୟ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ।",
            "as": "উফ! তোমাৰ সামৰ্থ্য আৰু স্বাৰ্থৰ ওপৰত আধাৰিত কৰি তোমাৰ সিদ্ধান্ত পুনৰপৰীক্ষা কৰাটোও জৰুৰী।",
            "gu": "અરે! તમારી તાકાત અને રસના આધારે તમારા નિર્ણયોનું મૂલ્યાંકન કરવું પણ મહત્વપૂર્ણ છે."
          }
        }
      ],
      "page11": [
        {
          "valuePath": "display",
          "value": {
            "en": "**THREAD_END**_Front Desk Worker",
            "hi": "फ्रंट डेस्क कामगार",
            "ka": "**THREAD_END **_ಫ್ರಂಟ್ ಡೆಸ್ಕ್ ವರ್ಕರ್",
            "te": "**THREAD_END**_ఫ్రంట్ డెస్క్ వర్కర్",
            "or": "**THREAD_END**_ସାମ୍ନା ଡେସ୍କ ୱାର୍କର୍",
            "as": "**থ্ৰেড এণ্ড**_ফ্ৰন্ট ডেস্ক কৰ্মী",
            "gu": "**THREAD_END**_ફ્રન્ટ ડેસ્ક વર્કર"
          }
        },
        {
          "valuePath": "blocks.0.props.question",
          "value": {
            "en": "Congratulations!\nYou have finished one journey!\n\nYou have made choices for Razia that has led to this possible ending. There are different endings based on the choices you make. Do you want to explore other scenes?",
            "hi": "बधाई हो!\n आपने एक सफर पूरा कर लिया हैं! \n \n आपने रजिया के लिए जो ऑप्शन्स चुने हैं उसके आधार पर वह जिंदगी में इस स्थान पर आ पहुँची है। आपने चुने हुए विकल्पों के आधार पर वह अलग-अलग स्थान पर हो सकती है। क्या आप इन अन्य स्थानों के बारे में जानना चाहते हैं?",
            "ka": "ಅಭಿನಂದನೆಗಳು!\n ನೀವು ಒಂದು ಪ್ರಯಾಣವನ್ನು ಮುಗಿಸಿದ್ದೀರಿ!\n \n ನೀವು ರಝಿಯಾಗಾಗಿ ಮಾಡಿದ ಆಯ್ಕೆಗಳು ಈ ಸಂಭವನೀಯ ಅಂತ್ಯಕ್ಕೆ ಕಾರಣವಾಗಿದೆ. ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳ ಆಧಾರದ ಮೇಲೆ ವಿಭಿನ್ನ ಅಂತ್ಯಗಳಿವೆ. ನೀವು ಇತರ ದೃಶ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುವಿರಾ?",
            "te": "అభినందనలు!\n మీరు ఒక ప్రయాణాన్ని ముగించారు!\n \n మీరు రజియా కోసం ఎంపికలు చేసారు, అది ఈ సాధ్యమైన ముగింపుకు దారితీసింది. మీరు చేసే ఎంపికల ఆధారంగా వేర్వేరు ముగింపులు ఉన్నాయి. మీరు ఇతర సన్నివేశాలను అన్వేషించాలనుకుంటున్నారా?",
            "or": "ଅଭିନନ୍ଦନ! \n ଆପଣ ଗୋଟିଏ ଯାତ୍ରା ସମାପ୍ତ କରିଛନ୍ତି! \n ଆପଣ ରଜିଆ ପାଇଁ ନିଷ୍ପତ୍ତିଗୁଡିକ ପସନ୍ଦ କରିଛନ୍ତି ଯାହା ଏହି ସମ୍ଭାବ୍ୟ ସମାପ୍ତିକୁ ଆଗେଇ ନେଇଛି । ଆପଣ କରୁଥିବା ପସନ୍ଦଗୁଡିକ ଉପରେ ଆଧାର କରି ବିଭିନ୍ନ ପ୍ରକାରର ଶେଷ ଅଛି । ଆପଣ ଅନ୍ୟ ଦୃଶ୍ୟଗୁଡିକ ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହାଁନ୍ତି କି?",
            "as": "অভিনন্দন!\n তুমি এটা যাত্ৰা সমাপ্ত কৰিলা!\n \n তুমি ৰাজিয়াৰ বাবে বাছনি কৰিছা যাৰ ফলত এই সম্ভাৱ্য সমাপ্তি ঘটিছে। তুমি কৰা বাছনিৰ ওপৰত আধাৰিত কৰি বিভিন্ন সমাপ্তি আছে। তুমি আন দৃশ্যবোৰ জানিব বিচাৰা নেকি?",
            "gu": "અભિનંદન!\nતમે પ્રવાસ પૂર્ણ કર્યો છે!\n\nતમે આ સંભવિત અંત તરફ દોરી જવા માટે રઝિયાની પસંદગી કરી. તમે જે પસંદ કરો છો તેના આધારે વિવિધ અંત છે. શું તમે અન્ય વિસ્તારોની શોધખોળ કરવા માંગો છો?"
          }
        },
        {
          "valuePath": "blocks.0.props.options.0.display",
          "value": {
            "en": "Take a look at the Map",
            "hi": "इस नक्शे को देखें",
            "ka": "ನಕ್ಷೆಯನ್ನು ನೋಡೋಣ",
            "te": "మ్యాప్‌ని ఒకసారి చూడండి",
            "or": "ମାନଚିତ୍ର ଉପରେ ନଜର ପକାନ୍ତୁ",
            "as": "মানচিত্ৰখন চোৱা",
            "gu": "ચાલો નકશા તરફ નજર નાખીએ"
          }
        }
      ]
    }
  }
