import React, {useRef, useEffect} from "react";
import "./_style.scss";
import {ColGrid} from "../../Grid";
import gsap, { Power3 } from "gsap";
import { insertLineBreaks } from "../../../utils/general";
import { __LOC } from "../../../utils/locale";

export function PageTitle({
  children,
  style,
  className
}) {

  const defaultStyle = {
    textAlign: 'center', 
    paddingBottom: '2rem',
    gridColumn: '1/ 11'
  }

  

  return (
    <ColGrid>
      <div
        className={`h2 ${className}`}
        style={{
          ...defaultStyle,
          ...(style ? style : {})
        }}>
        {children}
      </div>
    </ColGrid>
  );
}


export function PageSubtitle({
  children,
  style,
  className
}) {

  const defaultStyle = {
    textAlign: 'center', 
    paddingBottom: '2rem',
    gridColumn: '1/ 11'
  }


  return (
    <div
      className={`h3 ${className}`}
      style={{
        ...defaultStyle,
        ...(style ? style : {})
      }}>
      {__LOC(children)}
    </div>
  );
}




export function ContextText({
  id : idProp,
  children,
  className,
  text : textProp,
  style,
  textClass
}) {

  const id = idProp || 'contextText';

  const ref = {
    [id] : useRef(null)
  }

  useEffect(() => {
    gsap.from(ref[id].current, {
      transform: 'translateY(30px)',
      opacity: 0,
      duration: 0.6,
      ease: Power3.easeOut,
    });
  }, []);

  // const text = textProp || children
  let text = textProp || children; 
  // if(!Array.isArray(text)) text = text.split('\n')

  const defaultStyle={
    textAlign: 'center',
    gridColumn: '2 / span 8',
    paddingBottom: '1rem',
    ...style
  }


  // const genText = text => {text}</h4>;

  return (
    <ColGrid
      ref={ref[id]}
      className={`contextText-gridContainer ${className}`}
      >
      <div className='contextText-wrapper' style={defaultStyle}>
        <h4 className={`${textClass || 'h4'}`}>{ insertLineBreaks(text) }</h4>
      </div>
    </ColGrid>
  );
}