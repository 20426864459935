import React, { useState, Fragment } from "react";
import {useLocation} from 'react-router-dom'
import { StyleOverlayBlackScreen, StyleOverlayBlackScreenWrapper } from "../../Theme/globalStyledComps";
import { StyledH6 } from "../blocks/Typo/style";
import { IconButton } from "../Button";
import { StyledListItemWrapper, StyledMenuListWrapper } from "./style";
import { theme} from '../../Theme'
import { OverlayBlackScreen } from "../OverlayBlackScreen";
import { __LOC } from "../../utils/locale";

export const MainMenu = (props) => {
  const [showLinks, setShowLinks] = useState(false);

  const location = useLocation();

  const handleClick = (d) => {
    d.onClick && d.onClick()
    setShowLinks(false);
  }

  return (
    <Fragment>
      <OverlayBlackScreen onClick={() => setShowLinks(false)} show={showLinks}/>
      
    <div style={{width: '40px', position: 'relative' , zIndex: showLinks ? 100 : 'unset'}}>
      
      <IconButton
        hamburger
        icon={"List"}
        iconSize={26}
        onClick={() => setShowLinks((prev) => !prev)}
        active={showLinks && true}
      />
      <StyledMenuListWrapper>
        {showLinks &&
          props.links?.map((d) => (
            <StyledListItemWrapper>
              <IconButton nav={true} icon={d.icon} to={d.to} onClick={() => handleClick(d)} active={location.pathname === d.to} />
              <StyledH6 styled={`text-align: center; padding-top: 0.5rem; color: ${theme.colors.WHITE}`}>{__LOC(d.display)}</StyledH6>
            </StyledListItemWrapper>
          ))}
      </StyledMenuListWrapper>
    </div>
    </Fragment>
  );
};
