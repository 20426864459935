import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M16.725 14.327L5.937 21.615l5.695 1.603.367 5.905 9.396-9.011-2.286-2.83.001-.002-2.385-2.953z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#222"
        d="M5.937 21.615l-.28-.414-.893.604 1.038.292.135-.482zm10.788-7.288l.389-.314-.289-.357-.38.256.28.415zm-5.093 8.89l.499-.03-.022-.355-.342-.096-.135.482zm.367 5.906l-.499.031.067 1.076.779-.746-.347-.36zm9.396-9.011l.347.36.33-.317-.287-.357-.39.314zm-2.286-2.83l-.314-.39-.389.314.315.39.388-.315zm.001-.002l.314.39.39-.315-.315-.389-.389.314zM6.217 22.03l10.788-7.289-.56-.829-10.788 7.29.56.828zm5.55.706l-5.694-1.602-.271.963 5.694 1.602.271-.963zm-.634.513l.367 5.905.998-.062-.367-5.906-.998.063zm1.213 6.235l9.396-9.011-.693-.722-9.396 9.011.693.722zm9.439-9.686l-2.287-2.831-.777.628 2.285 2.83.779-.627zm-2.989-2.906l.628.778-.628-.779zm-2.46-2.251l2.385 2.954.778-.629-2.385-2.953-.778.628z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M23.582 14.327l10.788 7.288-5.695 1.603-.368 5.905-9.396-9.011 2.286-2.831 2.385-2.954z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#222"
        d="M34.37 21.615l.28-.414.893.604-1.038.292-.135-.482zm-10.788-7.288l-.389-.314.289-.357.38.256-.28.415zm5.093 8.89l-.5-.03.023-.355.341-.096.136.482zm-.368 5.906l.499.031-.067 1.076-.778-.746.346-.36zm-9.396-9.011l-.346.36-.332-.317.289-.357.389.314zm2.286-2.831l.314-.39.39.315-.315.389-.389-.314zm0 0l-.314.389-.39-.315.315-.389.389.314zM34.09 22.03L23.302 14.74l.56-.829 10.788 7.29-.56.828zm-5.55.706l5.694-1.602.271.963-5.695 1.602-.27-.963zm.634.513l-.368 5.905-.998-.062.368-5.905.998.062zm-1.213 6.235l-9.396-9.011.692-.722 9.396 9.011-.692.722zm-9.439-9.686l2.286-2.831.778.628-2.286 2.83-.778-.627zm2.989-2.906l-.628.778.628-.779zm2.46-2.251l-2.385 2.954-.778-.629 2.385-2.953.778.628z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M16.586 20.452l-1.108 12.972 4.826-3.425 4.826 3.425-1.107-12.972h-7.437z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#222"
        d="M15.478 33.424l-.498-.043-.092 1.075.88-.624-.29-.408zm1.108-12.972v-.5h-.46l-.039.458.499.042zM20.304 30l.29-.407-.29-.206-.29.206.29.407zm4.826 3.425l-.29.408.88.624-.092-1.075-.498.043zm-1.107-12.972l.498-.042-.04-.458h-.458v.5zm-3.64 0h-.5v.5h.5v-.5zm0 0h.5v-.5h-.5v.5zm-4.406 13.015l1.107-12.972-.997-.085L14.98 33.38l.996.086zm4.038-3.875l-4.826 3.424.579.816 4.825-3.425-.578-.816zm0 .815l4.826 3.425.578-.816-4.826-3.424-.578.815zm5.613 2.975L24.521 20.41l-.997.085 1.108 12.972.996-.085zm-1.605-13.43h-3.64v1h3.64v-1zm-4.14.5h1-1zm-3.297.5h3.796v-1h-3.796v1z"
      ></path>
      <circle
        cx="20.467"
        cy="14.557"
        r="8.116"
        fill="#fff"
        stroke="#222"
      ></circle>
      <path
        fill="#fff"
        stroke="#222"
        d="M20.907 10.978l.677 1.25a1.5 1.5 0 001.041.76l1.401.264a.5.5 0 01.272.834l-.988 1.053a1.5 1.5 0 00-.394 1.218l.184 1.429a.5.5 0 01-.711.515l-1.274-.61a1.5 1.5 0 00-1.295 0l-1.273.61a.5.5 0 01-.712-.515l.184-1.43a1.5 1.5 0 00-.394-1.218l-.988-1.052a.5.5 0 01.272-.834l1.401-.264a1.5 1.5 0 001.041-.76l.677-1.25a.5.5 0 01.88 0z"
      ></path>
    </svg>
  );
}

export default Icon;