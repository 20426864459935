import React, { useState } from "react";


export const TCIContext = React.createContext();

export const TCIProvider = ({children}) => {
    const [data, setData] = useState(undefined);
    const [thisPageId, setThisPageId] = useState(undefined);
    const [thisSceneId, setThisSceneId] = useState(undefined);

    return (
        <TCIContext.Provider
            value={{
                data, setData,
                thisPageId, setThisPageId,
                thisSceneId, setThisSceneId
            }}
            >
            {children}
        </TCIContext.Provider>
    )
}