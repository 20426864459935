
    export default {
      "scene0": {
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X_1_1",
              "hi": "X_1_1",
              "ka": "X_1_1",
              "te": "X_1_1",
              "or": "x_1_1",
              "as": "X_1_1",
              "gu": "X_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.desc",
            "value": {
              "en": "placeholder desc",
              "hi": "प्लेसहोल्डर विवरण  ",
              "ka": "placeholder desc",
              "te": "placeholder desc",
              "or": "placeholder desc",
              "as": "placeholder desc",
              "gu": "placeholder desc"
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.0.text",
            "value": {
              "en": "Shikhar lives with his family in a village near Ujjain in Madhya Pradesh. He has an elder brother, Arun, who works with their father on the farm.",
              "hi": "शिखर मध्य प्रदेश में उज्जैन के पास एक गांव में अपने परिवार के साथ रहता है। उसका एक बड़ा भाई है अरुण, जो अपने पिता के साथ खेत में काम करता है।",
              "ka": "ಶಿಖರ್ ತನ್ನ ಕುಟುಂಬದೊಂದಿಗೆ ಮಧ್ಯಪ್ರದೇಶದ ಉಜ್ಜಯಿನಿ ಬಳಿಯ ಹಳ್ಳಿಯಲ್ಲಿ ವಾಸಿಸುತ್ತಿದ್ದಾನೆ. ಅವನಿಗೆ ಅರುಣ್ ಎಂಬ ಅಣ್ಣನಿದ್ದಾನೆ, ಅವರು ತಮ್ಮ ತಂದೆಯೊಂದಿಗೆ ಜಮೀನಿನಲ್ಲಿ ಕೆಲಸವನ್ನು ಮಾಡುತ್ತಾರೆ.",
              "te": "మధ్యప్రదేశ్‌లోని ఉజ్జయిని సమీపంలోని ఒక గ్రామంలో శిఖర్ తన కుటుంబంతో నివసిస్తున్నాడు. అతనికి ఒక అన్నయ్య అరుణ్ ఉన్నాడు, అతను వాళ్ళ తండ్రితో కలిసి పొలంలో పనిచేస్తున్నాడు.",
              "or": "ଶିଖର ତା ପରିବାର ସହ ମଧ୍ୟପ୍ରଦେଶ ର ଉଜ୍ଜୟିନୀ ନିକଟସ୍ଥ ଏକ ଗ୍ରାମ ରେ ରହେ| ଅରୁଣ ନାମରେ ତାର ଗୋଟେ ସାନ ଭାଇ ଅଛି, ଯିଏ କି ସେମାନଙ୍କର ବାପାଙ୍କ ସହିତ ଫାର୍ମ ରେ କାମ କରେ|",
              "as": "শিখৰে তাৰ পৰিয়ালৰ সৈতে মধ্য প্ৰদেশৰ উজ্জয়িনীৰ ওচৰৰ এখন গাঁৱত বাস কৰে। তাৰ এজন ককায়েক আছে,অৰুণ, যি তেওঁলোকৰ দেউতাকৰ সৈতে খেতি পথাৰত কাম কৰে।",
              "gu": "શિખર તેના પરિવાર સાથે મધ્યપ્રદેશના ઉજ્જૈન પાસેના એક ગામમાં રહે છે. તેનો એક મોટો ભાઈ અરુણ છે, જે ખેતરમાં તેના પિતા સાથે કામ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.1.text",
            "value": {
              "en": "Shikhar is currently in the 9th standard. He is a quiet and sensitive boy. He is very observant by nature. He is always ready to help people around him.",
              "hi": "शिखर नौवीं कक्षा में है। वह एक शांत और संवेदनशील लड़का है। वह स्वभाव से पैनी नज़र रखने वाला है। वह हमेशा अपने आस-पास के लोगों की मदद करने के लिए तैयार रहता है।",
              "ka": "ಶಿಖರ್ ಸದ್ಯದಲ್ಲಿ 9ನೇ ತರಗತಿಯಲ್ಲಿ ಓದುತ್ತಿದ್ದಾನೆ. ಅವನು ಶಾಂತವಾದ ಮತ್ತು ಸಂವೇದನಾಶೀಲ ಹುಡುಗ. ಅವನು ಸ್ವಭಾವತಃ ಎಲ್ಲವನ್ನೂ ಬಹಳ ಗಮನಿಸುವವನು. ಅವನು ಯಾವಾಗಲೂ ತನ್ನ ಸುತ್ತಲಿನ ಜನರಿಗೆ ಸಹಾಯ ಮಾಡಲು ಸಿದ್ಧನಾಗಿರುತ್ತಾನೆ",
              "te": "శిఖర్ ప్రస్తుతం 9వ తరగతి చదువుతున్నాడు. అతను నెమ్మదైన మరియు సున్నితమైన అబ్బాయి. అతను స్వతహాగా చాలా గమనించేవాడు. అతను తన చుట్టూ ఉన్న వ్యక్తులకు సహాయం చేయడానికి ఎల్లప్పుడూ సిద్ధంగా ఉంటాడు.",
              "or": "ଶିଖର ବର୍ତ୍ତମାନ ନବମ ଶ୍ରେଣୀରେ ପାଠ ପଢୁଛି | ସେ ଗୋଟିଏ ଶାନ୍ତ ଶିଷ୍ଟ ପିଲା| \n ପ୍ରକୃତିଗତ ଭାବେ ସେ ଗୋଟିଏ ନିରୀକ୍ଷଣକାରୀ ଭଳି ଲାଗେ| ନିଜ ଚାରିପଟେ ଥିବା ଲୋକଙ୍କୁ ସାହାଯ୍ୟ କରିବା ପାଇଁ ସେ ସର୍ବଦା ତତ୍ପର ରହେ|",
              "as": "শিখৰ বৰ্তমান নৱম শ্ৰেণীত আছে। সি এজন শান্ত আৰু সচেতন ল'ৰা। সি স্বভাৱত অতি মনোযোগী। সি সদায় তাৰ চাৰিওফালৰ লোকসকলক সহায় কৰিবলৈ সাজু।",
              "gu": "શિખર હાલ 9મા ધોરણમાં છે. તે શાંત અને સંવેદનશીલ છોકરો છે. તે સ્વભાવે ખૂબ જ સચેત છે. તે હંમેશા તેની આસપાસના લોકોને મદદ કરવા તૈયાર રહે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.2.text",
            "value": {
              "en": "People at home and in the village often ask him for help to understand how to use apps on their mobile phones as he is good at learning new things.",
              "hi": "वह नई चीजों को सीखने में अच्छा है इसलिए घर और गांव में अक्सर लोग उससे अपने मोबाइल फोन पर ऐप्स के उपयोग करने के तरीके समझना चाहते हैं।",
              "ka": "ಅವನು ಹೊಸ ವಿಷಯಗಳನ್ನು ಕಲಿಯುವುದರಲ್ಲಿ ನಿಪುಣನಾಗಿರುವುದರಿಂದ ತಮ್ಮ ಮೊಬೈಲ್ ಫೋನ್‌ಗಳಲ್ಲಿ ಅಪ್ಲಿಕೇಶನ್‌ಗಳನ್ನು ಹೇಗೆ ಬಳಸುವುದು ಎಂಬುದನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಮನೆಯಲ್ಲಿ ಮತ್ತು ಹಳ್ಳಿಯ ಜನರು ಆಗಾಗ್ಗೆ ಆತನ ಸಹಾಯವನ್ನು ಕೇಳುತ್ತಾರೆ.",
              "te": "అతను కొత్త విషయాలను నేర్చుకోవడంలో నైపుణ్యం ఉన్నందున వారి మొబైల్ ఫోన్‌లలో యాప్‌లను ఎలా ఉపయోగించాలో అర్థం చేసుకోవడానికి ఇంట్లో మరియు గ్రామంలోని వ్యక్తులు తరచుగా అతనిని సహాయం కోసం అడుగుతారు.",
              "or": "ଯେହେତୁ ସେ ନୂଆ ନୂଆ ଜିନିଷ ଶିଖିବାରେ ଆଗ୍ରହୀ, ତେଣୁ ଘରେ ଏବଂ ଗାଁ ରେ ଥିବା ଲୋକ ମାନେ ବହୁତ ସମୟରେ ତାକୁ ମୋବାଇଲ ଫୋନ ରେ ଆପ ଗୁଡିକ କିପରି ବ୍ୟବହାର କରାଯାଏ, ସେ ବିଷୟରେ ପଚାରନ୍ତି |",
              "as": "ঘৰৰ আৰু গাঁৱৰ মানুহে প্ৰায়ে তেওঁলোকৰ ম'বাইল ফোনত এপবোৰ কেনেদৰে ব্যৱহাৰ কৰিব লাগে বুজিবলৈ তাৰ সহায় বিচাৰে কিয়নো সি নতুন বস্তু শিকাত ভাল।",
              "gu": "ઘરે અને ગામડાના લોકો તેના મોબાઈલ ફોન પર એપ્સનો ઉપયોગ કેવી રીતે કરવો તે સમજવા માટે વારંવાર તેની પાસે મદદ માટે પૂછે છે કારણ કે તે નવી વસ્તુઓ શીખવામાં સારો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.3.text",
            "value": {
              "en": "He loves to write his thoughts and feelings in his diary to reflect on the day. He started it in his fifth after his teacher asked all students to maintain a daily journal.",
              "hi": "वह दिन भर के अपने विचारों और भावनाओं पर चिंतन करने के लिए उन्हें अपनी डायरी में  लिखना पसंद करता है। पांचवीं कक्षा में जब उसके शिक्षक ने अपने छात्रों से दैनिक डायरी लेखन के लिए कहा तबसे उसने यह शुरु किया ।",
              "ka": "ದಿನವನ್ನು ಹೇಗೆ ಕಳೆದೆ ಎನ್ನುವುದನ್ನು ತಿಳಿಯಲು  ದಿನಚರಿಯಲ್ಲಿ ತನ್ನ ಆಲೋಚನೆಗಳು ಮತ್ತು ಭಾವನೆಗಳನ್ನು ಬರೆಯಲು ಅವನು ಇಷ್ಟಪಡುತ್ತಾನೆ. ದಿನನಿತ್ಯದ ಜರ್ನಲ್ ಅನ್ನು ನಿರ್ವಹಿಸಲು ಎಲ್ಲಾ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೂ ಅವರ ಶಿಕ್ಷಕರು ಹೇಳಿದ ನಂತರ ಅವನು ತನ್ನ ಐದನೇ ವಯಸ್ಸಿನಲ್ಲಿ ಅದನ್ನು ಪ್ರಾರಂಭಿಸಿದನು.",
              "te": "అతను రోజూ తన డైరీలో తన ఆలోచనలు మరియు భావాలను వ్రాయడానికి ఇష్టపడతాడు. ఐదవ తరగతిలో తన ఉపాధ్యాయుడు విద్యార్థులందరినీ రోజువారీ దినచర్యను నమోదు (జర్నల్) నిర్వహించమని చెప్పిన తర్వాత అతను దానిని ప్రారంభించాడు.",
              "or": "ଦିନକୁ ଆହୁରି ସୁନ୍ଦର କରିବା ପାଇଁ ସେ ନିଜର ଭାବନା ଏବଂ ଅନୁଭୂତି କୁ ଡାଏରୀ ରେ ସବୁଦିନ ଲେଖିବାକୁ ଭଲ ପାଏ | ପଞ୍ଚମ ଶ୍ରେଣୀ ରେ ପଢୁଥିବା ସମୟରେ ଦିନେ ଶିକ୍ଷକ ସମସ୍ତଙ୍କୁ ନୀତିଦିନିଆ ଦୈନନ୍ଦିନୀ ଲେଖିବାକୁ କହିବା ପରେ ସେ ଏମିତି ଡାଏରୀ ଲେଖିବା ଆରମ୍ଭ କରେ।",
              "as": "সি দিনটোৰ খতিয়ান ৰখাৰ বাবে তাৰ ডায়েৰীত তাৰ চিন্তা আৰু অনুভূতি লিখিবলৈ ভাল পায়। তাৰ শিক্ষকে সকলো শিক্ষাৰ্থীক দিনপঞ্জী এখন বজাই ৰাখিবলৈ কোৱাত সি এই অভ্যাসটো পঞ্চম শ্ৰেণীত থকাৰ পৰাই আৰম্ভ কৰিছিল।",
              "gu": "તે પૂરા દિવસનું યાદ બધુ યાદ રાખવા માટે તેની ડાયરીમાં તેના વિચારો અને લાગણીઓ લખવાનું પસંદ કરે છે. તેના પાંચમામાં ધોરણમાં શિક્ષકે તમામ વિદ્યાર્થીઓને દૈનિક નોધ જાળવવાનું કહ્યું હતું ત્યાર બાદ તેને આ શરૂઆત કરી."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.4.text",
            "value": {
              "en": "He likes playing on his brother’s mobile phone. He is also very interested in food and learning how to cook from his mother. His father doesn’t appreciate him doing that.",
              "hi": "वह अपने भाई के मोबाइल फोन पर खेलना पसंद करता है। वह भोजन में और अपनी माँ से भोजन पकाने की विधि सीखने में भी बहुत रुचि रखता है। उसके पिता इस बात से खुश नहीं हैं।",
              "ka": "ಅವನು ತನ್ನ ಸಹೋದರನ ಮೊಬೈಲ್ ಫೋನ್‌ನಲ್ಲಿ ಆಟವಾಡಲು ಇಷ್ಟಪಡುತ್ತಾನೆ. ಅವನು ಆಹಾರದಲ್ಲಿ ತುಂಬಾ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದಾನೆ ಹಾಗೂ ತನ್ನ ತಾಯಿಯಿಂದ ಅಡುಗೆ ಮಾಡುವುದು ಹೇಗೆ ಎಂದು ಕಲಿಯುತ್ತಾನೆ. ಅವನು ಹಾಗೆ ಮಾಡುವುದನ್ನು ಅವನ ತಂದೆ ಮೆಚ್ಚುವುದಿಲ್ಲ",
              "te": "అతను తన సోదరుడి మొబైల్ ఫోన్‌లో ఆడటం ఇష్టపడతాడు. అతను తన తల్లి నుండి ఆహారం మరియు వంట ఎలా నేర్చుకోవాలో తెలుసుకోవడానికి కూడా చాలా ఆసక్తిని కలిగి ఉన్నాడు. అలా చేయడం అతని తండ్రికి నచ్చదు.",
              "or": "ସେ ନିଜ ଭାଇ ର ମୋବାଇଲ ଫୋନ ରେ ଖେଳିବାକୁ ଭଲ ପାଏ| ଆହୁରି ମଧ୍ୟ\n  ସେ ନିଜ ମା ଙ୍କ ଠାରୁ ବିଭିର୍ଣ୍ଣ ପ୍ରକାର ଖାଦ୍ୟ ତିଆରି କରିବାର କୌଶଳ କୁ ଶିଖିବା ରେ ବହୁତ ଆଗ୍ରହୀ| ତାର ବାପା ତାର ଏପରି କାର୍ଯ୍ୟ କୁ ମହତ୍ଵ ଦିଅନ୍ତି ନହିଁ |",
              "as": "সি তাৰ ককায়েকৰ ম'বাইল ফোনত খেলি ভাল পায়। সি খাদ্যৰ প্ৰতি আৰু কেনেদৰে ৰান্ধিব লাগে মাকৰ পৰা শিকিবলৈও যথেষ্ট আগ্ৰহী। তাৰ দেউতাকে সি কৰা এই কামবোৰৰ প্ৰশংসা নকৰে।",
              "gu": "તેને તેના ભાઈના મોબાઈલ ફોન પર ગેમ રમવાનું ગમે છે. તેને ખોરાક બનાવવામાં પણ ખૂબ જ રસ છે અને તેની માતા પાસેથી રસોઇ કેવી રીતે બનાવી તે શીખે છે. તેના પિતા તેને આવું કરવા માટે પ્રશંસા કરતા નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.5.text",
            "value": {
              "en": "Engineers are highly respected within their community. His father wants Shikhar to study engineering, just like his cousin, Sujay.",
              "hi": "इंजीनियरों को उनके समाज में अत्यधिक सम्मानित किया जाता है। उसके पिता चाहते हैं कि शिखरअपने चचेरे भाई सुजय की तरह इंजीनियरिंग का अध्ययन करे।",
              "ka": "ಇಂಜಿನಿಯರ್‌ಗಳು ಅವರ ಸಮುದಾಯದಲ್ಲಿ ಹೆಚ್ಚು ಗೌರವಾನ್ವಿತರಾಗಿದ್ದಾರೆ. ಶಿಖರ್ ತನ್ನ ಸೋದರಸಂಬಂಧಿ ಸುಜಯ್‌ನಂತೆ ಇಂಜಿನಿಯರಿಂಗ್ ಓದಬೇಕೆಂದು ಅವನ ತಂದೆಯ ಬಯಕೆಯಾಗಿದೆ",
              "te": "వారి సంఘంలో ఇంజనీర్లు చాలా గౌరవించబడతారు. శిఖర్‌ తన బంధువైన సుజయ్‌ లాగే ఇంజినీరింగ్‌ చదవాలని అతని తండ్రి కోరిక.",
              "or": "ସେମାନଙ୍କ ସମୁଦାୟରେ ଇଞ୍ଜିନିୟର ମାନଙ୍କୁ ବହୁତ ମାନ୍ୟତା ଦିଆଯାଏ | \n ଶିଖର ନିଜ ଭାଇ ସୁଜୟ ଭଳି ଇଞ୍ଜିନିୟରିଂ ପଢୁ ବୋଲି ତା ବାପଙ୍କର ଇଛା |",
              "as": "অভিযন্তাসকলক তেওঁলোকৰ সম্প্ৰদায়ৰ ভিতৰত অত্যন্ত সন্মান কৰা হয়। তাৰ দেউতাকে শিখৰক তাৰ সম্পৰ্কীয় ককায়েক সুজয়ৰ দৰে ইঞ্জিনিয়াৰিং পঢ়াটো বিচাৰে।",
              "gu": "ઇજનેરોને તેના સમુદાયમાં ખૂબ સન્માન આપવામાં આવે છે. તેના પિતા ઈચ્છે છે કે શિખર તેના પિતરાઈ ભાઈ સુજયની જેમ જ એન્જિનિયરિંગનો અભ્યાસ કરે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.6.text",
            "value": {
              "en": "In this story, Shikhar will explore many different career paths beyond engineering.",
              "hi": "इस कहानी में, शिखर इंजीनियरिंग के अतिरिक्त कई अलग-अलग करियर पथ का पता लगाएगा। ",
              "ka": "ಈ ಕಥೆಯಲ್ಲಿ, ಶಿಖರ್ ಎಂಜಿನಿಯರಿಂಗ್‌ನ ಹೊರಗೆ ಹಲವು ವಿಭಿನ್ನ ವೃತ್ತಿ ಮಾರ್ಗಗಳನ್ನು ಅನ್ವೇಷಿಸಲಿದ್ದಾನೆ.",
              "te": "ఈ కథలో, శిఖర్ ఇంజనీరింగ్‌ కాకుండా అనేక విభిన్న కెరీర్ మార్గాలను అన్వేషించనున్నాడు.",
              "or": "ଏହି କଥାଟିରେ,ଶିଖର ଇଞ୍ଜିନିୟରିଂ ବ୍ଯତୀତ ଅନ୍ୟାନ୍ୟ ବହୁତ ଗୁଡିଏ ରୋଜଗାର ପନ୍ଥା ବିଷୟରେ ବୁଝାଇବ|",
              "as": "এই কাহিনীটোত, শিখৰে ইঞ্জিনিয়াৰিঙৰ বাহিৰেও কেৰিয়াৰৰ বিভিন্ন পথ বিচাৰিব।",
              "gu": "આ વાર્તામાં, શિખર એન્જિનિયરિંગ ઉપરાંત કારકિર્દીના વિવિધ રસ્તાઓ શોધશે."
            }
          }
        ]
      },
      "scene1": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_1",
              "hi": "X1_1_1",
              "ka": "X1_1_1",
              "te": "X1_1_1",
              "or": "X1_1_1",
              "as": "X1_1_1",
              "gu": "X1_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "It is Shikhar’s favourite time of the year, the mustard flowering season. The fields look vibrant and beautiful, and Shikhar is thrilled. He loves this season for the food it offers.",
              "hi": " शिखर को साल का यह समय पसंद है, सरसों के फूल का मौसम। खेत जीवंत और सुंदर दिखते हैं, और शिखर रोमांचित है। वह इस मौसम में मिलने वाले भोजन के लिए इसको पसंद करता है।",
              "ka": "ಇದು ಶಿಖರ್ ಗೆ ವರ್ಷದ ನೆಚ್ಚಿನ ಸಮಯ, ಸಾಸಿವೆ ಹೂಬಿಡುವ ಋತು. ಜಮೀನುಗಳು ರೋಮಾಂಚಕ ಮತ್ತು ಸುಂದರವಾಗಿ ಕಾಣುತ್ತವೆ ಮತ್ತು ಶಿಖರ್ ರೋಮಾಂಚನಗೊಂಡಿದ್ದಾನೆ. ಇದು ನೀಡುವ ಆಹಾರಕ್ಕಾಗಿ ಅವರು ಈ ಋತುವನ್ನು ಪ್ರೀತಿಸುತ್ತಾರೆ.",
              "te": "ఇది సంవత్సరంలో శిఖర్‌కి ఇష్టమైన సమయం, ఆవాలు పుష్పించే కాలం. పొలాలు ఉత్సాహంగా మరియు అందంగా కనిపిస్తాయి మరియు శిఖర్ పులకరిస్తాడు. అతను ఈ సీజన్‌లో దొరికే ఆహారం వల్ల అతను దీనిని ఇష్టపడతాడు.",
              "or": "ଏହା ହେଉଛି ଶିଖରର ମନପସନ୍ଦ ଋତୁ, ସୋରିଷ ଫୁଲ ର ଋତୁ| ସୋରିଷ କ୍ଷେତ ଜୀବନ୍ତ ଏବଂ ସୁନ୍ଦର ଦେଖାଯାଏ, ଏବଂ ଶିଖର ନିଜକୁ ରୋମାଞ୍ଚିତ ଅନୁଭବ କରେ|ଏହି ଋତୁ ରେ ମିଳୁଥିବା ଖାଦ୍ୟ ପାଇଁ ସେ ଏହି ଋତୁଟିକୁ ବହୁତ ଭଲ ପାଏ|",
              "as": "এয়া শিখৰৰ বছৰটোৰ প্ৰিয় সময়, সৰিয়হ ফুলাৰ বতৰ। পথাৰবোৰ জীৱন্ত আৰু ধুনীয়া দেখা যায়, আৰু শিখৰ ৰোমাঞ্চিত হয়। সি এই ঋতুত লাভ কৰা খাদ্যৰ বাবে ঋতুটোক ভাল পায়।",
              "gu": "તે શિખરનો પ્રિય સમય છે વર્ષ માટે, જ્યારે સરસવના ફૂલોની મોસમ હોય. ખેતરો ગતિશીલ અને સુંદર લાગે છે અને શિખર રોમાંચિત છે. તે આ મોસમમાં જે ખોરાક આપે છે તે તેને પસંદ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_2",
              "hi": "X1_1_2",
              "ka": "X1_1_2",
              "te": "X1_1_2",
              "or": "X1_1_2",
              "as": "X1_1_2",
              "gu": "X1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "His diary has thoughts on the beautiful fields, the different moods and the meals the season brings.",
              "hi": "उसकी डायरी में खूबसूरत खेतों, मन की विभिन्न दशाओं और इस मौसम में उपलब्ध भोजन पर विचार लिखे गये हैं ।",
              "ka": "ಆತನ ದಿನಚರಿಯು ಸುಂದರವಾದ ಹೊಲಗಳು, ವಿಭಿನ್ನ ಮನಸ್ಥಿತಿಗಳು ಮತ್ತು ಋತುವಿನ ಊಟವನ್ನು ಹೊಂದಿದೆ.",
              "te": "అతని డైరీలో అందమైన పొలాలు, విభిన్న మనోభావాలు మరియు కాలంలో వచ్చే భోజనాలపై తన గురించి ఆలోచనలు ఉన్నాయి.",
              "or": "ତା ଡାଏରୀ ରେ ଏହି ସୁନ୍ଦର କ୍ଷେତ, ଅଲଗା ଅଲଗା ମନୋଦଶା ଏବଂ ଏହି ଋତୁରେ ମିଳୁଥିବା ବିଭିର୍ଣ୍ଣ ଖାଦ୍ୟ ବିଷୟରେ ଲେଖା ମାନ ଅଛି|",
              "as": "তাৰ ডায়েৰীখনত সুন্দৰ পথাৰ, বিভিন্ন মনোভাৱ আৰু ঋতুটোৱে কঢ়িয়াই অনা আহাৰৰ বিষয়ে অনুভৱ আছে।",
              "gu": "તેની ડાયરીમાં સુંદર ખેતરો, અલગ-અલગ મન અને મોસમ દ્વારા ઊગતા ખાધ વિશેના વિચારો છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_2_1",
              "hi": "X1_2_1",
              "ka": "X1_2_1",
              "te": "X1_2_1",
              "or": "X1_2_1",
              "as": "X1_2_1",
              "gu": "X1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "While Shikhar is writing in his diary, he hears his friend Devansh calling out to him. Devansh and his sister, Aarti, want to make an account to share recipe videos like their cousin from the city.",
              "hi": "जब शिखर अपनी डायरी लिख रहा है, उसे अपने मित्र देवांश की आवाज़ सुनाई देती है। देवांश और उसकी बहन आरती, शहर में रहने वाले अपने चचेरे भाई की तरह खाना पकाने की विधि के वीडियो साझा करने के लिए एक अकाउंट/ खाता बनाना चाहते हैं।",
              "ka": "ಶಿಖರ್ ತನ್ನ ದಿನಚರಿಯಲ್ಲಿ ಬರೆಯುತ್ತಿರುವಾಗ, ಅವನ ಸ್ನೇಹಿತ ದೇವಾಂಶ್ ತನ್ನನ್ನು ಕರೆಯುವುದನ್ನು ಅವನು ಕೇಳುತ್ತಾನೆ. ದೇವಾಂಶ್ ಮತ್ತು ಅವನ ಸಹೋದರಿ ಆರತಿ ಅವರು ನಗರದಲ್ಲಿರುವ ತಮ್ಮ ಸೋದರಸಂಬಂಧಿಯಂತೆ ಪಾಕವಿಧಾನ ವೀಡಿಯೊಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ಖಾತೆಯನ್ನು ಮಾಡಲು ಬಯಸುತ್ತಾರೆ.",
              "te": "శిఖర్ తన డైరీలో రాసుకుంటున్నప్పుడు, అతని స్నేహితుడు దేవాన్ష్ తనను పిలవడం వింటాడు. దేవాన్ష్ మరియు అతని సోదరి, ఆర్తి, నగరంలోని వారి బంధువు లాగానే వంటకాల వీడియోలను భాగస్వామ్యం చేయడానికి ఒక ఖాతాను తయారు చేయాలనుకుంటున్నారు.",
              "or": "ଯେତେବେଳେ ସେ ନିଜ ଡାଏରୀ ଲେଖେ , ତା ସାଙ୍ଗ ଦେବନ୍ସ ତାକୁ ଡାକୁଥିବାର\n  ସେ ଶୁନେ | ଦେବାନ୍ସ ଏବଂ ତା ଭଉଣୀ, ଆରତୀ, ସହରରେ ରହୁଥିବା ତାଙ୍କ \n ଭଏ ଭଉଣୀ ମାନେ ଯେମିତି ପାକବିଧି ଭିଡିଓକୁ କୁ ବାଣ୍ଟିବା ଆକାଉଣ୍ଟ ବନେଇଛନ୍ତି,\n ସେମିତି ଗୋଟିଏ ଆକାଉଣ୍ଟ ବନେଇବା ପାଇଁ ଚାହୁଛନ୍ତି|",
              "as": "শিখৰে তাৰ ডায়েৰীত লিখি থাকোঁতে, সি তাৰ বন্ধু দেৱাংশে তাক মাতি থকা শুনিছে। দেৱাংশ আৰু তাৰ ভগ্নী আৰতিয়ে চহৰখনৰ পৰা তেওঁলোকৰ সম্পৰ্কীয় ককায়েকৰ দৰে ৰেচিপি ভিডিঅ' শ্বেয়াৰ কৰিবলৈ এটা একাউণ্ট বনাব বিচাৰে।",
              "gu": "જ્યારે શિખર તેની ડાયરીમાં લખી રહ્યો હતો, ત્યારે તે તેના મિત્ર દેવાંશને તેને બોલતા સાંભળે છે. દેવાંશ અને તેની બહેન આરતી, શહેરમાંથી તેમના પિતરાઈ ભાઈની જેમ રસોઈના વીડિયો શેર કરવા માટે એક એકાઉન્ટ બનાવવા માંગે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Devansh",
              "hi": "देवांश ",
              "ka": "ದೇವಾಂಶ್",
              "te": "దేవాన్ష్",
              "or": "ଦେଭାଂଶ",
              "as": "দেৱাংশ",
              "gu": "દેવાંશ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "So, we need to set a password.",
              "hi": "तो, हमें एक पासवर्ड बनाने की आवश्यकता है।",
              "ka": "ಅಂದರೆ, ನಾವು ಒಂದು ಪಾಸ್ವರ್ಡ್ ಅನ್ನು ಸೆಟ್ ಅಪ್ ಮಾಡಬೇಕು",
              "te": "కాబట్టి, మనము పాస్‌వర్డ్‌ని పెట్టాలి.",
              "or": "ତାହେଲେ, ଆମେ ଗୋଟିଏ ପାସୱାର୍ଡ ଦରକାର କରୁଛୁ|",
              "as": "গতিকে, আমি এটা পাছৱৰ্ড ছেট কৰিব লাগিব।",
              "gu": "તેથી, આપણે પાસવર્ડ બનાવવાની જરૂર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Yes, of course! you can’t open an account otherwise.",
              "hi": "हां बिल्कुल! अन्यथा आप अकाउंट/ खाता नहीं खोल सकते।",
              "ka": "ಹೌದು,ಆದರೆ ನೀನು ಖಾತೆಯನ್ನು ತೆಗೆಯಲು ಆಗುವುದಿಲ್ಲ.",
              "te": "అవును, కచ్చితంగా! లేకపోతే మీరు అకౌంట్ తెరవలేరు.",
              "or": "ହଁ, ନହେଲେ ଆମେ ଆକାଉଣ୍ଟ ଖୋଲି ପାରିବା ନହିଁ|",
              "as": "হয় নিশ্চয়! অন্যথা তুমি একাউণ্ট এটা খুলিব নোৱাৰা।",
              "gu": "હા ચોક્ક્સ! કેમ કે એના સીવાય એકાઉન્ટ ખોલી શકતા નથી."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_2_2",
              "hi": "X1_2_2",
              "ka": "X1_2_2",
              "te": "X1_2_2",
              "or": "X1_2_2",
              "as": "X1_2_2",
              "gu": "X1_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Put something I can remember easily, please.",
              "hi": " कृपया, ऐसा कुछ बनाओ जिसे मैं सरलता से याद रख सकूं। ",
              "ka": "ದಯವಿಟ್ಟು ನಾನು ನೆನಪಿನಲ್ಲಿ ಇಟ್ಟುಕೊಳ್ಳುವಂತಹದನ್ನು ಹಾಕು.",
              "te": "దయచేసి నేను సులభంగా గుర్తుపట్టగలిగేదాన్ని ఉంచండి.",
              "or": "ଦୟାକରି ଏମିତି ପାସୱାର୍ଡ ରଖ ଯାହାକୁ ମୁଁ ସହଜ ରେ ମାନେ ରଖିପାରିବି|",
              "as": "মই সহজে মনত ৰাখিব পৰা কিবা এটা ৰাখা।",
              "gu": "કૃપા કરીને, હું સરળતાથી યાદ રાખી શકું એવું કંઈક રાખો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I put my name and birth date as the password. It’s easy to remember.",
              "hi": "मैं अपना नाम और जन्म तिथि पासवर्ड के रूप में रखता हूँ। इसे याद रखना आसान है।",
              "ka": "ನಾನು ನನ್ನ ಹೆಸರು ಹಾಗೂ ಹುಟ್ಟಿದ ದಿನಾಂಕವನ್ನು ಪಾಸ್ವರ್ಡ್ ಮಾಡಿಕೊಂಡಿದ್ದೇನೆ. ಅದು ನೆನಪಿಟ್ಟುಕೊಳ್ಳಲು ಸುಲಭ.",
              "te": "నేను నా పేరు మరియు పుట్టిన తేదీని పాస్‌వర్డ్ గా ఉంచాను. ఇది గుర్తుంచుకోవడం సులభం.",
              "or": "ମୁଁ ମୋ ନାଁ ଏବଂ ଜନ୍ମ ତାରିଖ କୁ ପାସୱାର୍ଡ ଭାବେ ରଖୁଛି| ଏହା କୁ ମନେରଖିବା \n ସହଜ ହେବ|",
              "as": "মই মোৰ নাম আৰু জন্ম তাৰিখ পাছৱৰ্ড হিচাপে ৰাখিছো। এইটো মনত ৰখা সহজ।",
              "gu": "મેં પાસવર્ડ તરીકે મારું નામ અને જન્મ તારીખ મૂકી છે. તે યાદ રાખવું સરળ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "But tell me, do you both know how to make these videos?",
              "hi": "लेकिन मुझे बताओ, क्या आप दोनों जानते हैं कि इन वीडियो को कैसे बनाया जाए?",
              "ka": "ಆದರೆ ನನಗೆ ತಿಳಿಸು, ನಿಮಗಿಬ್ಬರಿಗ್ಗೂ ಈ ವೀಡಿಯೋ ಮಾಡುವುದು ಹೇಗೆ ಎಂದು ತಿಳಿದಿದೆಯೇ?",
              "te": "కానీ ఇది చెప్పండి, మీ ఇద్దరికీ ఈ వీడియోలు ఎలా చేయాలో తెలుసా?",
              "or": "କିନ୍ତୁ ମତେ କୁହ କି ତୁମେ ଦୁହେଁ ଭିଡିଓ ତିଆରି କରିବା ଜାଣିଛ ତ?",
              "as": "কিন্তু মোক কোৱা, তোমালোক দুয়োজনে এই ভিডিঅ'বোৰ কেনেদৰে বনাব লাগে জানানে?",
              "gu": "પણ મને કહો, શું તમે બંને જાણો છો કે આ વીડિયો કેવી રીતે બનાવવો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Umm... No...",
              "hi": "उम्म ... नहीं ...",
              "ka": "ಹ್ಮ... ಇಲ್ಲ...",
              "te": "ఉమ్... తెలియదు ..",
              "or": "ନାଁ|",
              "as": "উম... নাজানো…",
              "gu": "અમ્મ... ના..."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Shikhar looks confused.",
              "hi": "शिखरअनिश्चय की स्थिति में दिखता है।",
              "ka": "ಶಿಖರ್ ಸ್ವಲ್ಪ ಗೊಂದಲಕ್ಕೆ ಒಳಗಾಗುತ್ತಾನೆ",
              "te": "శిఖర్ అయోమయంగా కనిపిస్తాడు.",
              "or": "ଶିଖର ଭ୍ରମିତ ଭଳି ଜଣାପଡୁଛି|",
              "as": "শিখৰক চিন্তিত দেখা গৈছে।",
              "gu": "શિખર મૂંઝાયેલો દેખાય છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_3_1",
              "hi": "X1_3_1",
              "ka": "X1_3_1",
              "te": "X1_3_1",
              "or": "X1_3_1",
              "as": "X1_3_1",
              "gu": "X1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar learns a lot more about shooting and editing videos by watching video-making tutorials online.",
              "hi": "शिखर वीडियो बनाने के ऑनलाइन ट्यूटोरियल देखकर वीडियो को शूट करना और उसे संपादित करने के बारे में बहुत कुछ सीखता है।",
              "ka": "ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ವೀಡಿಯೊ-ಮೇಕಿಂಗ್ ಟ್ಯುಟೋರಿಯಲ್‌ಗಳನ್ನು ನೋಡುವ ಮೂಲಕ ಶಿಖರ್ ವೀಡಿಯೊಗಳನ್ನು ಚಿತ್ರೀಕರಿಸುವ ಮತ್ತು ಸಂಪಾದಿಸುವ ಕುರಿತು ಹೆಚ್ಚಿನದನ್ನು ಕಲಿಯುತ್ತಾನೆ.",
              "te": "ఆన్‌లైన్‌లో వీడియో తయారు చేసే ట్యుటోరియల్‌లను చూడటం ద్వారా శిఖర్ వీడియోలను చిత్రీకరించడం మరియు సవరించడం గురించి చాలా నేర్చుకుంటాడు.",
              "or": "ଶିଖର ଭିଡିଓ ପରିଚାଳନା ଏବଂ ସମ୍ପାଦନା ବିଷୟରେ ଅନଲାଇନ ଦେଖି ଦେଖି ବହୁତ କିଛି ଶିଖିଛି|",
              "as": "শিখৰে অনলাইনত ভিডিঅ' বনোৱাৰ টিউটৰিয়েল চাই ভিডিঅ'ৰ শ্বুটিং আৰু এডিটৰ বিষয়ে বহুত শিকে।",
              "gu": "શિખર ઑનલાઇન વીડિયો-બનાવવના ટ્યુટોરિયલ્સ જોઈને વીડિયો બનાવે છે અને એડિટિંગ વિશે ઘણું બધું શીખે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_3_2",
              "hi": "X1_3_2",
              "ka": "X1_3_2",
              "te": "X1_3_2",
              "or": "X1_3_2",
              "as": "X1_3_2",
              "gu": "X1_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He helps Aarti make cooking videos and upload them. Shikhar is enjoying this kind of learning through video tutorials at his own pace and improving through practice.",
              "hi": "वह आरती को खाना पकाने के वीडियो बनाने और उन्हें अपलोड करने में मदद करता है। शिखर वीडियो ट्यूटोरियल के माध्यम से इस तरह अपने हिसाब से  सीखने में आनंद ले रहा है और अभ्यास के द्वारा सुधार कर रहा है।",
              "ka": "ಆತನು ಆರತಿಗೆ ಅಡುಗೆ ವೀಡಿಯೊಗಳನ್ನು ಮಾಡಲು ಮತ್ತು ಅವುಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಸಹಾಯ ಮಾಡುತ್ತಾನೆ. ಶಿಖರ್ ತನ್ನ ಸ್ವಂತ ವೇಗದಲ್ಲಿ ವೀಡಿಯೊ ಟ್ಯುಟೋರಿಯಲ್‌ಗಳ ಮೂಲಕ ಈ ರೀತಿಯ ಕಲಿಕೆಯನ್ನು ಆನಂದಿಸುತ್ತಿದ್ದಾನೆ ಮತ್ತು ಅಭ್ಯಾಸದ ಮೂಲಕ ಸುಧಾರಿಸಿಕೊಳ್ಳುತ್ತಿದ್ದಾನೆ.",
              "te": "అతను ఆర్తికి వంట వీడియోలను తయారు చేయడంలో మరియు వాటిని అప్‌లోడ్ చేయడంలో సహాయం చేస్తాడు. శిఖర్ తన తెలివితో వీడియో ట్యుటోరియల్స్ ద్వారా ఇలా నేర్చుకోవడం ఆనందిస్తున్నాడు మరియు సాధన చేస్తూ మెరుగవుతున్నాడు.",
              "or": "ସେ ଆରତୀ କୁ ରୋଷେଇ ଭିଡିଓ ତିଆରି କରିବା ଏବଂ ତାକୁ ଅପଲୋଡ କରିବାରେ ସାହାଯ୍ୟ କରେ| ଶିଖର ଏହି ଭଳି ଭିଡିଓ ଟ୍ଯୁଟୋରିଆଲ କୁ ନିଜସ୍ଵ ଚେଷ୍ଟା ରେ ଦେଖିବା ଓ ଶିଖିବାରେ ବହୁତ ଆନନ୍ଦ ପାଏ|",
              "as": "সি আৰতিক ৰন্ধন ভিডিঅ' বনোৱাত আৰু সেইবোৰ আপলোড কৰাত সহায় কৰে। শিখৰে নিজৰ মতে ভিডিঅ' টিউটৰিয়েলৰ জৰিয়তে এইবোৰ শিকাত আনন্দ পাইছে আৰু অনুশীলনৰ জৰিয়তে উন্নতি কৰিছে।",
              "gu": "તે આરતીને રસોઈના વીડિયો બનાવવામાં અને અપલોડ કરવામાં મદદ કરે છે. શિખર પોતાની ગતિએ વિડિયો ટ્યુટોરિયલ્સ દ્વારા આ પ્રકારના શીખવાનો આનંદ માણી રહ્યો છે અને પ્રેક્ટિસ દ્વારા સુધારી રહ્યો છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "Experimentation",
              "hi": "प्रयोग",
              "ka": "ಪ್ರಯೋಗ ಮಾಡು",
              "te": "ప్రయోగీకరణ",
              "or": "ପରୀକ୍ଷଣ |",
              "as": "পৰীক্ষা-নিৰীক্ষা",
              "gu": "પ્રયોગ"
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "ಎಮ್‌ಸಿ‌ಕ್ಯೂ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar is very good at learning new ideas about cooking since he keeps experimenting i.e. trying new activities like new recipes and watching videos on his phone. Select the sentence which is TRUE about experimenting for career development:",
              "hi": "शिखर खाना पकाने के बारे में नए विचारों को सीखने में बहुत कुशल है क्योंकि वह प्रयोग करता रहता है जैसे कि नई गतिविधियों  को करने की कोशिश करना,  व्यंजन बनाने की नई विधियों के ट्यूटोरियल वीडियो अपने फोन पर  देखना। करियर विकास के लिए प्रयोग करने के बारे में ऐसे वाक्य का चयन करें जो सत्य है:",
              "ka": "ಶಿಖರ್  ಅಡುಗೆಯ ಬಗ್ಗೆ ಹೊಸ ವಿಚಾರಗಳನ್ನು ಕಲಿಯುವುದರಲ್ಲಿ ತುಂಬಾ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದಾನೆ.  ಏಕೆಂದರೆ ಅವನು ಪ್ರಯೋಗಗಳನ್ನು ಮಾಡುತ್ತಲೇ ಇರುತ್ತಾನೆ. ಅಂದರೆ ಹೊಸ ಪಾಕವಿಧಾನಗಳಂತಹ ಹೊಸ ಚಟುವಟಿಕೆಗಳನ್ನು ಪ್ರಯತ್ನಿಸುತ್ತಾನೆ ಮತ್ತು ಅವನ ಫೋನ್‌ನಲ್ಲಿ ವೀಡಿಯೊಗಳನ್ನು ವೀಕ್ಷಿಸುತ್ತಾನೆ. ವೃತ್ತಿಜೀವನದ ಅಭಿವೃದ್ಧಿಗಾಗಿ ಪ್ರಯೋಗದ ಬಗ್ಗೆ ನಿಜವಾಗಿರುವ ವಾಕ್ಯವನ್ನು ಆಯ್ಕೆಮಾಡಿ:",
              "te": "శిఖర్ తన ఫోన్‌లో కొత్త వంటకాలు మరియు వీడియోలను చూడటం వంటి ప్రయోగాలు చేస్తూనే ఉన్నందున వంట గురించి కొత్త ఆలోచనలను నేర్చుకోవడంలో చాలా మంచివాడు. కెరీర్ అభివృద్ధి కోసం ప్రయోగాలు చేయడం గురించి నిజం అయిన వాక్యాన్ని ఎంచుకోండి:",
              "or": "ଶିଖର ନୂଆ ନୂଆ ରୋଷେଇ ବିଧିକୁ ଶିଖିବା ଏବଂ ତାକୁ ପରୀକ୍ଷା କରିବାରେ \n ବହୁତ ଆଗ୍ରହୀ| ଯେମିତିକି . ନୂଆ ରୋଷେଇ ବିଧି କୁ ନିଜ ଫୋନ ରେ ଭିଡିଓ \n  ମାଧ୍ୟମ ରେ ଦେଖିବା ଏବଂ ଶିଖିବା| ନିଜ କ୍ୟାରିଆର କୁ ବିକଶିତ କରିବା ପାଇଁ\n ଉପଯୁକ୍ତ ବାକ୍ୟ କୁ ସତ ବୋଲି ଚିହ୍ନିତ କରନ୍ତୁ|",
              "as": "শিখৰ ৰন্ধনৰ বিষয়ে নতুন আইডিয়া শিকাত বহুত ভাল কিয়নো সি পৰীক্ষা-নিৰীক্ষা কৰি থাকে অৰ্থাৎ নতুন ৰেচিপিৰ দৰে নতুন কাম চেষ্টা কৰা আৰু তাৰ ফোনত ভিডিঅ' চোৱা। কেৰিয়াৰ বিকাশৰ বাবে পৰীক্ষা-নিৰীক্ষা কৰাৰ বিষয়ে সঁচা বাক্যটো বাছনি কৰা:",
              "gu": "શિખર રસોઈ વિશેના નવા વિચારો શીખવામાં ખૂબ જ સારો છે કારણ કે તે સતત પ્રયોગો કરતો રહે છે એટલે કે નવી રસોઈ બાનાવવી જેવી નવી પ્રવૃત્તિઓ અજમાવવા અને તેના ફોન પર વીડિયો જોવાનો. કારકિર્દી વિકાસ માટે પ્રયોગ કરવા વિશે સાચું હોય તે વાક્ય પસંદ કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "To experiment means to use technology to learn new skills",
              "hi": "प्रयोग करने हेतु नए कौशल सीखने के लिए तकनीक का उपयोग करना ",
              "ka": "ಪ್ರಯೋಗ ಮಾಡುವುದು ಎಂದರೆ ಹೊಸ ಕೌಶಲ್ಯಗಳನ್ನು ಕಲಿಯಲು ತಂತ್ರಜ್ಞಾನವನ್ನು ಬಳಸುವುದು ಎಂದರ್ಥ.",
              "te": "ప్రయోగాలు చేయడం అంటే కొత్త నైపుణ్యాలను నేర్చుకోవడానికి సాంకేతికతని ఉపయోగించడం",
              "or": "ପରୀକ୍ଷା କରିବା ଅର୍ଥାତ ନୂତନ ଜ୍ଞାନ କୌଶଳ କୁ ଶିଖିବାକୁ ବିଭିର୍ଣ୍ଣ ପ୍ରକାରର ଜ୍ଞାନକୌଶଳ କୁ ବ୍ୟବହାର କରିବା|",
              "as": "পৰীক্ষা কৰাৰ অৰ্থ হৈছে নতুন দক্ষতা শিকিবলৈ প্ৰযুক্তিৰ ব্যৱহাৰ কৰা",
              "gu": "પ્રયોગ કરવાનો અર્થ છે નવી કુશળતા શીખવા માટે ટેકનોલોજીનો ઉપયોગ કરવો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Internships are good ways to experiment with professional interests",
              "hi": "इंटर्नशिप व्यावसायिक हितों के साथ प्रयोग करने के अच्छे तरीके हैं",
              "ka": "ವೃತ್ತಿಪರ ಆಸಕ್ತಿಗಳನ್ನು ಪ್ರಯೋಗಿಸಲು ಇಂಟರ್ನ್‌ಶಿಪ್ ಉತ್ತಮ ಮಾರ್ಗವಾಗಿರುತ್ತದೆ.",
              "te": "వృత్తిపరమైన ఆసక్తులతో ప్రయోగాలు చేయడానికి ఇంటర్న్‌షిప్‌లు మంచి మార్గాలు",
              "or": "ବୃତ୍ତିଗତ ଆଗ୍ରହ କିମ୍ବା ଉପଯୋଗୀତା ସହିତ ପରୀକ୍ଷଣ କରିବା ପାଇଁ ଇଣ୍ଟେର୍ନ୍ଷିପ \n ହେଉଛି ଉତ୍ତମ ମାଧ୍ୟମ|",
              "as": "পেছাদাৰী আগ্ৰহৰ সৈতে পৰীক্ষা কৰাৰ বাবে ইণ্টাৰ্নশ্বিপ হৈছে ভাল উপায়",
              "gu": "ઇન્ટર્નશીપ એ વ્યાવસાયિક રુચિઓ સાથે પ્રયોગ કરવાની સારી રીત છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "To experiment means that your CV will show that you are not serious",
              "hi": "प्रयोग करने का मतलब है कि आपका सीवी दिखाएगा कि आप गंभीर नहीं हैं",
              "ka": "ಪ್ರಯೋಗ ಮಾಡುವುದು ಎಂದರೆ ನೀವು ಜವಾಬ್ದಾರಿ ಉಳ್ಳವರು ಅಲ್ಲ ಎಂದು ನಿಮ್ಮ CV ತೋರಿಸುತ್ತದೆ",
              "te": "ప్రయోగం చేయడం అంటే మీరు సీరియస్‌గా లేరని మీ CV చూపిస్తుంది",
              "or": "ପରୀକ୍ଷଣ କରିବା ଅର୍ଥାତ ତୁମେ ଗମ୍ଭୀର ନୁହେନ୍ନ ବୋଲି ତୁମର ସିଭି ଦର୍ଶାଉଥିବ|",
              "as": "পৰীক্ষা কৰাৰ অৰ্থ হৈছে যে তোমাৰ CV-য়ে দেখুৱাব যে তুমি মনোযোগী নহয়",
              "gu": "પ્રયોગ કરવાનો અર્થ એ છે કે તમારું CV બતાવશે કે તમે ગંભીર નથી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Think again! You can experiment in many ways. Using technology is only one example.",
              "hi": "फिर से विचार करो! आप अनेक तरीकों से प्रयोग कर सकते हैं। तकनीक का उपयोग केवल एक उदाहरण है।",
              "ka": "ಮತ್ತೂಮೆ ಆಲೋಚಿಸಿ! ನೀವು ಹಲವು ವಿಧಗಳಲ್ಲಿ ಪ್ರಯೋಗವನ್ನು  ಮಾಡಬಹುದು. ತಂತ್ರಜ್ಞಾನವನ್ನು ಬಳಸುವುದು ಒಂದೇ ಒಂದು ಉದಾಹರಣೆಯಾಗಿದೆ.",
              "te": "మళ్లీ ఆలోచించండి! మీరు అనేక విధాలుగా ప్రయోగాలు చేయవచ్చు. సాంకేతికతని ఉపయోగించడం ఒక ఉదాహరణ మాత్రమే.",
              "or": "ଆଉଥରେ ଭାବ! ତୁମେ ଅନ୍ୟ ବହୁତ କିଛି ଉପାୟରେ ପରୀକ୍ଷଣ କରିପାରିବ| \n ଟେକନୋଲଜିର ବ୍ୟବହାର କେବଳ ଗୋଟିଏ ଉଦାହରଣ|",
              "as": "আকৌ চিন্তা কৰা! তুমি বিভিন্ন ধৰণে পৰীক্ষা কৰিব পাৰা। প্ৰযুক্তি ব্যৱহাৰ কৰাটো কেৱল এটা উদাহৰণ।",
              "gu": "ફરીથી વિચારો! તમે ઘણી રીતે પ્રયોગ કરી શકો છો. ટેકનોલોજીનો ઉપયોગ એ માત્ર એક ઉદાહરણ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಉತ್ತಮ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ବହୁତ ବଢିଆ |",
              "as": "ভাল কাম!",
              "gu": "સરસ કામ!"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Think again! While creating your CV and giving job interviews it is important to communicate how your experiments helped your goals.",
              "hi": "फिर से विचार करो! अपने सीवी बनाने और नौकरी साक्षात्कार देने के दौरान यह बताना महत्वपूर्ण है कि आपके प्रयोगों ने आपके लक्ष्यों को कैसे मदद की।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ನಿಮ್ಮ CV ರಚಿಸುವಾಗ ಮತ್ತು ಉದ್ಯೋಗ ಸಂದರ್ಶನಗಳನ್ನು ನೀಡುವಾಗ ನಿಮ್ಮ ಪ್ರಯೋಗಗಳು ನಿಮ್ಮ ಗುರಿಗಳಿಗೆ ಹೇಗೆ ಸಹಾಯ ಮಾಡಿದೆ ಎನ್ನುವುದನ್ನು ತಿಳಿಸುವುದು ಮುಖ್ಯವಾಗಿರುತ್ತದೆ.",
              "te": "మళ్లీ ఆలోచించండి! మీ CVని సృష్టిస్తున్నప్పుడు మరియు ఉద్యోగ ఇంటర్వ్యూలు ఇస్తున్నప్పుడు మీ ప్రయోగాలు మీ లక్ష్యాలకు ఎలా సహాయపడ్డాయో తెలియజేయడం ముఖ్యం.",
              "or": "ଆଉଥରେ ଚିନ୍ତାକର! ଯେତେବେଳେ ନିଜର ସିଭି ତିଆରି କରି ଏବଂ କାମ ପାଇଁ \n ଇନ୍ଟାରଭିଉ ଦେବାକୁ ଯାଉଛେ, ସେତେବେଳେ କିଭଳି ନିଜର ପରୀକ୍ଷଣ ତୁମକୁ ନିଜ\n  ଲକ୍ଷ ସ୍ଥଳ ରେ ପହଞ୍ଚାଇ ପାରିଛି, ସେ ବିଷୟରେ ଜଣାଇବା ମଧ୍ୟ ଆବଶ୍ୟକ|",
              "as": "আকৌ চিন্তা কৰা! তোমাৰ CV তৈয়াৰ কৰোঁতে আৰু চাকৰিৰ সাক্ষাৎকাৰ দিয়াৰ সময়ত তোমাৰ পৰীক্ষাবোৰে তোমাৰ লক্ষ্যত কেনেদৰে সহায় কৰিছিল সেয়া জনোৱাটো গুৰুত্বপূৰ্ণ।",
              "gu": "ફરીથી વિચારો! તમારું CV બનાવતી વખતે અને નોકરીનું ઇન્ટરવ્યુ આપતી વખતે તમારા પ્રયોગો તમારા લક્ષ્યોને કેવી રીતે મદદ કરે છે તે જણાવવું મહત્વપૂર્ણ છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_4_1",
              "hi": "X1_4_1",
              "ka": "X1_4_1",
              "te": "X1_4_1",
              "or": "X1_4_1",
              "as": "X1_4_1",
              "gu": "X1_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "While making the videos for her, he learns a lot about cooking and spends more time with his mother in the kitchen.",
              "hi": "उसके लिए वीडियो बनाते समय, वह खाना पकाने के बारे में बहुत कुछ सीखता है और रसोईघर में अपनी मां के साथ अधिक समय बिताता है।",
              "ka": "ಅವಳಿಗಾಗಿ ವೀಡಿಯೊಗಳನ್ನು ಮಾಡುವಾಗ, ಅವನು ಅಡುಗೆಯ ಬಗ್ಗೆ ಸಾಕಷ್ಟು ಕಲಿಯುತ್ತಾನೆ ಮತ್ತು ಅಡುಗೆಮನೆಯಲ್ಲಿ ತನ್ನ ತಾಯಿಯೊಂದಿಗೆ ಹೆಚ್ಚು ಸಮಯ ಕಳೆಯುತ್ತಾನೆ.",
              "te": "ఆమె కోసం వీడియోలు చేస్తున్నప్పుడు, అతను వంట గురించి చాలా నేర్చుకుంటాడు మరియు వంటగదిలో తన తల్లితో ఎక్కువ సమయం గడుపుతాడు.",
              "or": "ଯେତେବେଳେ ସେ ତା ପାଇଁ ଭିଡିଓ ତିଆରି କରେ ,ସେ ରୋଷେଇ ସମ୍ବନ୍ଧିତ \n ବହୁତ କିଛି ଶିଖେ ଏବଂ ନିଜ ମା ଙ୍କ ସହିତ ରୋଷେଇ ଘରେ \n  ବହୁତ ସମୟ ଅତିବାହିତ କରେ।",
              "as": "তাইৰ বাবে ভিডিঅ' বনোৱাৰ সময়ত, সি ৰন্ধনৰ বিষয়ে বহুত শিকে আৰু মাকৰ সৈতে পাকঘৰত অধিক সময় কটায়।",
              "gu": "તેના માટે વીડિયો બનાવતી વખતે, તે રસોઈ વિશે ઘણું શીખે છે અને તેની માતા સાથે રસોડામાં વધુ સમય પ્રસાર કરે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_4_2",
              "hi": "X1_4_2",
              "ka": "X1_4_2",
              "te": "X1_4_2",
              "or": "X1_4_2",
              "as": "X1_4_2",
              "gu": "X1_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "But his father is not happy to see this.",
              "hi": "लेकिन उसके पिता यह देखकर खुश नहीं हैं।",
              "ka": "ಆದರೆ ಅವನ ತಂದೆ ಇದನ್ನು ನೋಡಿ ಸಂತೋಷಪಡುವುದಿಲ್ಲ.",
              "te": "అయితే ఇది చూసి అతని తండ్రి సంతోషించడు.",
              "or": "କିନ୍ତୁ ତାର ବାପା ଏସବୁ ଦେଖି ଖୁସି ନୁହଁନ୍ତି |",
              "as": "কিন্তু তাৰ দেউতাক এয়া দেখি সুখী নহয়।",
              "gu": "પરંતુ તેના પિતા આ જોઈને ખુશ નથી."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_4_3",
              "hi": "X1_4_3",
              "ka": "X1_4_3",
              "te": "X1_4_3",
              "or": "X1_4_3",
              "as": "X1_4_3",
              "gu": "X1_4_3"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "One day in the courtyard.",
              "hi": "एक दिन आंगन में।",
              "ka": "ಒಂದು ದಿನ ಅಂಗಳದಲ್ಲಿ.",
              "te": "ఒక రోజు ప్రాంగణంలో.",
              "or": "ପ୍ରାଙ୍ଗଣ ରେ ଗୋଟିଏ ଦିନ|",
              "as": "এদিন চোতালত।",
              "gu": "એક દિવસ આંગણામાં."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Maaji",
              "hi": "माँजी",
              "ka": "ಮಾಜಿ",
              "te": "అమ్మగారు",
              "or": "ମାଜୀ",
              "as": "মা",
              "gu": "માજી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Maaji, let us make some baingan ki sabzi today. I have learnt a new way to make it.",
              "hi": "माँजी, आइए आज बैंगन की सब्जी बनाएं। मैंने इसे बनाने का एक नया तरीका सीखा है।",
              "ka": "ಮಾಜಿ, ನಾವು ಇಂದು ಬೈಂಗನ್ ಕಿ ಸಬ್ಜಿಯನ್ನು ಮಾಡೋಣ. ನಾನು ಅದನ್ನು ಮಾಡಲು ಹೊಸ ಮಾರ್ಗವನ್ನು ಕಲಿತಿದ್ದೇನೆ.",
              "te": "అమ్మగారు, ఈరోజు మనం వంకాయ కూరని తయారు చేద్దాం. నేను దానిని తయారు చేయడానికి కొత్త విధానాన్ని నేర్చుకున్నాను.",
              "or": "ମା,ଚାଲନ୍ତୁ ଆଜି ବାଇଗଣ ର କିଛି ତରକାରୀ ତିଆରି କରିବା| ମୁଁ ଏହାକୁ ତିଆରି କରିବାର ଏକ ନୂଆ କୌଶଳ ଶିଖିଛି|",
              "as": "মা, আজি আমি অলপ বেঙেনাৰ চবজি বনাওঁ আহক। মই ইয়াক বনাবলৈ এটা নতুন উপায় শিকিছোঁ।",
              "gu": "માજી, ચાલો આજે રીંગણનું શાક બનાવીએ. મેં તેને બનાવવાની નવી રીત શીખી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Your last dish of bhindi was too good, Shikhar! Where are you learning these things?",
              "hi": "तुम्हारे द्वारा पिछ्ली बार बनाई भिंडी का पकवान बहुत अच्छा था, शिखर! तुम इन चीजों को कहां से सीख रहे हो?",
              "ka": "ನಿನ್ನ ಕಳೆದ ಬಾರಿ ಊಟಕ್ಕೆ ಮಾಡಿದ ಭಿಂಡಿ ತುಂಬಾ ಚೆನ್ನಾಗಿತ್ತು, ಶಿಖರ್! ನೀನು ಈ ವಿಷಯಗಳನ್ನು ಎಲ್ಲಿ ಕಲಿಯುತ್ತಿದ್ದೀಯಾ?",
              "te": "నీ చివరి వంటకం బెండకాయ కూర చాలా బాగుంది, శిఖర్! నువ్వు ఈ విషయాలు ఎక్కడ నేర్చుకుంటున్నావు?",
              "or": "ଶିଖର, ପୂର୍ବରୁ ତୁମେ କରିଥିବା ଭେଣ୍ଡି ର ସବ୍ଜୀଟି ବହୁତ ସୁଆଦିଆ ଥିଲା! କେଉଁଠାରୁ \n ତୁମେ ଏସବୁ ଶିଖୁଛ?",
              "as": "শিখৰ, তোমাৰ ভেণ্ডীৰ শেষৰ ব্য়ঞ্জনখন বহুত ভাল আছিল! তুমি এই বস্তুবোৰ ক'ত শিকি আছা?",
              "gu": "તારી ભીંડાની છેલ્લી વાનગી બહુ સારી હતી, શિખર! તું આ ક્યાંથી શીખો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "It is available online, Maaji. And now that I'm helping Aarti, I learn from her as well.",
              "hi": "यह ऑनलाइन उपलब्ध है, माँजी। और अब जब मैं आरती की मदद कर रहा हूं, तो मैं उससे भी सीखता हूं।",
              "ka": "ಇದು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಲಭ್ಯವಿದೆ, ಮಾಜಿ. ಮತ್ತು ಈಗ ನಾನು ಆರತಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತಿದ್ದೇನೆ, ನಾನು ಅವಳಿಂದಲೂ ಕಲಿಯುತ್ತಿದ್ದೇನೆ.",
              "te": "ఇది ఆన్‌లైన్‌లో అందుబాటులో ఉంది అమ్మగారు. ఇప్పుడు నేను ఆర్తికి సహాయం చేస్తున్నాను, నేను ఆమె నుండి కూడా నేర్చుకుంటున్నాను.",
              "or": "ମା, ଏସବୁ ଅନଲାଇନ ରେ ଉପଲବ୍ଧ ଅଛି| ଏବଂ ବର୍ତ୍ତମାନ ମୁଁ ଆରତୀ କୁ ସାହାଯ୍ୟ କରୁଛି, ଏବଂ ତା ଠାରୁ ସିଖୁଛି ମଧ୍ୟ|",
              "as": "এইবোৰ অনলাইনত শিকিছোঁ, মা। আৰু এতিয়া যেতিয়া মই আৰতিক সহায় কৰি আছিলো, মই তাইৰ পৰাও শিকিছোঁ।",
              "gu": "તે ઓનલાઈન ઉપલબ્ધ છે, માજી. અને હવે હું આરતીને મદદ કરી રહ્યો છું, હું તેની પાસેથી પણ શીખું છું."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_4_4",
              "hi": "X1_4_4",
              "ka": "X1_4_4",
              "te": "X1_4_4",
              "or": "X1_4_4",
              "as": "X1_4_4",
              "gu": "X1_4_4"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "So nice. More boys should come to the kitchen to cook. How else will you survive if you ever go to the city to work?",
              "hi": "बहुत अच्छा। अधिक लड़कों को रसोई में खाना बनाना चाहिए। वरना यदि तुम कभी भी काम करने के लिए शहर जाते हो तो कैसे जीवन जियोगे?",
              "ka": "ಬಹಳ ಒಳ್ಳೆಯದು. ಹೆಚ್ಚು ಹುಡುಗರು ಅಡುಗೆ ಮಾಡಲು ಬರಬೇಕು. ನೀನು ಎಂದಾದರೂ ಕೆಲಸ ಮಾಡಲು ನಗರಕ್ಕೆ ಹೋದರೆ ಹೇಗೆ ಬದುಕುತ್ತೀಯಾ?",
              "te": "చాలా బాగుంది. వంట చేయడానికి ఎక్కువ మంది అబ్బాయిలు వంటగదికి రావాలి. మీరు ఎప్పుడైనా నగరానికి పనికి వెళితే మీరు ఎలా బతుకుతారు?",
              "or": "ବହୁତ ବଢିଆ| ପୁଅମାନେ ଅଧିକ ମାତ୍ରାରେ ରୋଷେଇ ଘରକୁ ରାନ୍ଧିବା ପାଇଁ ଆସିବା ଉଚିତ|\n ନହେଲେ ଚାକିରି କରିବାକୁ ସହର କୁ ଗଲେ ତୁମେ କେମିତି ନିଜକୁ ବଞ୍ଚେଇ ରଖିବା?",
              "as": "বৰ ভাল কথা। ৰান্ধিবলৈ আন ল'ৰাবোৰো পাকঘৰলৈ আহিব লাগে। যদি তুমি কেতিয়াবা কাম কৰিবলৈ চহৰলৈ যোৱা তেন্তে তুমি আৰু কেনেকৈ জীয়াই থাকিবা?",
              "gu": "ખૂબ સરસ. છોકરાઓને રસોડામાં વધુ આવવું જોઈએ. જો તમે ક્યારેય કામ કરવા શહેરમાં જશો તો તમે કેવી રીતે ટકી શકશો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "What nonsense are you teaching him? If he studies now and works hard, he won’t need to step into the kitchen. There are more critical things men need to do.",
              "hi": "आप उसे क्या व्यर्थ की बातें सिखा रही हैं? यदि वह अब पढ़ता है और कड़ी मेहनत करता है, तो उसे रसोईघर में कदम रखने की आवश्यकता नहीं होगी। पुरुषों को अधिक महत्वपूर्ण चीजें करने की ज़रूरत  हैं।",
              "ka": "ನೀವು ಅವನಿಗೆ ಯಾವ ಅಸಂಬದ್ಧತೆಯನ್ನು ಕಲಿಸುತ್ತಿದ್ದೀರಿ? ಅವನು ಈಗ ಅಧ್ಯಯನ ಮಾಡಿದರೆ ಮತ್ತು ಕಷ್ಟಪಟ್ಟು ಕೆಲಸ ಮಾಡಿದರೆ, ಅವನು ಅಡುಗೆಮನೆಗೆ ಕಾಲಿಡುವ ಅಗತ್ಯವಿಲ್ಲ. ಪುರುಷರು ಮಾಡಬೇಕಾದದ್ದು ಇನ್ನೂ ಹೆಚ್ಚು ನಿರ್ಣಾಯಕ ವಿಷಯಗಳಿವೆ.",
              "te": "నువ్వు అసలు అతనికి ఏం నేర్పిస్తున్నావు? ఇప్పుడు చదువుకుని కష్టపడితే వంటగదిలోకి అడుగు పెట్టాల్సిన అవసరం ఉండదు. పురుషులు చేయవలసిన క్లిష్టమైన పనులు చాలా ఉన్నాయి.",
              "or": "କଣ ଅବୁ ବେକାର କଥା ତୁମେ ତାକୁ ଶିଖାଉଛ?ଯଦି ସେ କଠିନ ପରିଶ୍ରମ କରି ପଥ ପଢିବ ତାହେଲେ ତାକୁ ରୋଷେଇ ଘରକୁ ଯିବା ଦରକାର ପଡିବନି|\n ରୋଷେଇ ଛଡା ଅଲଗା ବହୁତ କିଛି କଷ୍ଟସାଧ୍ୟ କାମ ପୁଅଙ୍କ ପାଇଁ ଅଛି|",
              "as": "তুমি তাক কি বাজে কথা শিকাই আছা? যদি সি এতিয়া পঢ়ে আৰু কঠোৰ পৰিশ্ৰম কৰে, তেন্তে সি পাকঘৰত ভৰি দিয়াৰ প্ৰয়োজন নহ'ব। মতা মানুহে কৰিব লগা আৰু অধিক গুৰুত্বপূৰ্ণ কাম আছে।",
              "gu": "તમે તેને શું બકવાસ શીખવો છો? જો તે અત્યારે અભ્યાસ કરશે અને સખત મહેનત કરશે તો તેને રસોડામાં પગ મૂકવાની જરૂર નહીં પડે. પુરૂષોને વધુ જટિલ બાબતો કરવાની જરૂર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "But Babuji, I like cooking. What’s the problem with me helping Maaji out?",
              "hi": "लेकिन बाबूजी, मुझे खाना बनाना पसंद है। मेरे द्वारा माँजी की मदद करने में क्या समस्या है?",
              "ka": "ಆದರೆ ಬಾಬೂಜಿ, ನನಗೆ ಅಡುಗೆ ಮಾಡುವುದು ಇಷ್ಟ. ಮಾಜಿಗೆ ನಾನು ಸಹಾಯ ಮಾಡಿದಲ್ಲಿ ಏನು ಸಮಸ್ಯೆ ಇದೆ?",
              "te": "కానీ నాన్నగారు, నాకు వంట చేయడం ఇష్టం. నేను అమ్మగారికి సహాయం చేయడంలో సమస్య ఏమిటి?",
              "or": "କିନ୍ତୁ ବାବା, ମତେ ରୋଏଇ କରିବାକୁ ଭଲ ଲାଗେ| ଯଦି ମୁଁ ମାଙ୍କୁ ଏଥିରେ\n ସାହାଯ୍ୟ କରୁଛି ତେବେ ଅସୁବିଧା କଣ?",
              "as": "কিন্তু দেউতা, মই ৰান্ধি ভাল পাওঁ। মাক সহায় কৰাত মোৰ কি সমস্যা আছে?",
              "gu": "પણ બાબુજી, મને રસોઈ કરવી ગમે છે. માજીને મદદ કરવામાં મને શું વાંધો છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Don’t make stupid excuses to avoid studying. If you still have spare time, come and work in the fields with us! Foolish boy!",
              "hi": "अध्ययन से बचने के लिए बेवकूफी भरे बहाने मत बनाओ। यदि तुम्हारे पास अभी खाली समय है, तो आओ और हमारे साथ खेतों में काम करो! मूर्ख लड़का!",
              "ka": "ಅಧ್ಯಯನ ಮಾಡುವುದನ್ನು ತಪ್ಪಿಸಲು ಮೂರ್ಖ ಕಾರಣಗಳನ್ನು ನೀಡಬೇಡ. ನಿನಗೆ ಇನ್ನೂ ಬಿಡುವು ಇದ್ದಲ್ಲಿ, ನಮ್ಮೊಂದಿಗೆ ಹೊಲಗಳಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಬಾ! ಮೂರ್ಖ ಹುಡುಗ!",
              "te": "చదువుకోకుండా ఉండేందుకు తెలివితక్కువ సాకులు చెప్పకు. నీకు అంత ఖాళీ సమయం ఉంటే, మాతో కలిసి పొలాల్లో పని చేయి! వెర్రి అబ్బాయి!",
              "or": "ପାଠପଢାକୁ ଏଡାଇବା ପାଇଁ ବେକାର ଯୁକ୍ତି କରନି| ବୋକା ପିଲା, ଯଦି ତୋ\n ପାଖେ ଏବେ ବି ଖାଲି ସମୟ ରହୁଛି ତେବେ ମୋ ସହ କ୍ଷେତ ରେ କାମ କରିବୁ ଆସେ |",
              "as": "পঢ়াৰ পৰা আঁতৰি থাকিবলৈ এনেধৰণৰ অজুহাত নেদেখুৱাবা। যদি তোমাৰ এতিয়াও খালী সময় আছে, আমাৰ সৈতে পথাৰত আহি কাম কৰা! মূৰ্খ ল'ৰা!",
              "gu": "અભ્યાસ ટાળવા માટે ખોટા બહાના ન બનાય. જો તરી પાસે હજુ પણ ફાજલ સમય હોય, તો આવ અને મારી સાથે ખેતરોમાં કામ કર! નાદાન છોકરો!"
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_3_notebook",
              "hi": "X1_1_3_नोटबुक ",
              "ka": "X1_1_3_notebook",
              "te": "X1_1_3_పుస్తకం",
              "or": "X1_1_3_notebook",
              "as": "X1_1_3_notebook",
              "gu": "X1_1_3_નોટબુક"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar is sad and opens his diary to write his thoughts.",
              "hi": "शिखर दुखी है और अपने विचार लिखने के लिए अपनी डायरी खोलता है।",
              "ka": "ಶಿಖರ್ ದುಃಖಿತನಾಗಿ ತನ್ನ ಆಲೋಚನೆಗಳನ್ನು ಬರೆಯಲು ತನ್ನ ಡೈರಿಯನ್ನು ತೆರೆಯುತ್ತಾನೆ.",
              "te": "శిఖర్ విచారంగా ఉన్నాడు మరియు తన ఆలోచనలను వ్రాయడానికి తన డైరీని తెరిచాడు.",
              "or": "ଶିଖର ମନ ଦୁଖରେ ନିଜ ଡାଏରି କୁ କାଢି ନିଜ ଭାବନା କୁ ଲେଖୁଛି|",
              "as": "শিখৰ দুখী আৰু তাৰ অনুভৱ লিখিবলৈ তাৰ ডায়েৰীখন খোলে।",
              "gu": "શિખર ઉદાસ છે અને તેના વિચારો લખવા માટે તેની ડાયરી ખોલે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page 1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "ପୃଷ୍ଠା ୧",
              "as": "পৃষ্ঠা 1",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page 2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ 2",
              "or": "ପୃଷ୍ଠା ୨",
              "as": "পৃষ্ঠা 2",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Page 3",
              "hi": "पृष्ठ3",
              "ka": "ಪುಟ 3",
              "te": "పేజీ 3",
              "or": "ପୃଷ୍ଠା ୩",
              "as": "পৃষ্ঠা 3",
              "gu": "પેજ 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Why does BabuJi think cooking is not for boys? I saw so many videos online where men also cook great food.",
              "hi": "बाबूजी क्यों सोचते हैं कि खाना पकाने का कार्य लड़कों के लिए नहीं है? मैंने इतने सारे वीडियो ऑनलाइन देखे जिसमें  पुरुष भी बहुत अच्छा खाना पकाते हैं।",
              "ka": "ಬಾಬುಜಿ ಅಡುಗೆ ಮಾಡುವುದು ಹುಡುಗರಿಗೆ ಸರಿ ಅಲ್ಲ ಎಂದು ಏಕೆ ಭಾವಿಸುತ್ತಾರೆ? ನಾನು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಹಲವಾರು ವೀಡಿಯೊಗಳನ್ನು ನೋಡಿದ್ದೇನೆ, ಅಲ್ಲಿ ಪುರುಷರು ಸಹ ಉತ್ತಮವಾದ ಆಹಾರವನ್ನು ಬೇಯಿಸುತ್ತಾರೆ.",
              "te": "నాన్నగారు వంట చేయడం అబ్బాయిలకి కాదు అని ఎందుకు అనుకుంటున్నారు? నేను ఆన్‌లైన్‌లో చాలా వీడియోలను చూశాను, ఇందులో పురుషులు కూడా మంచి ఆహారాన్ని వండుతారు.",
              "or": "ବାପା କାହିଁକି ଭାବୁଛନ୍ତି ଯେ ପୁଅ ମାନେ ରୋଷେଇ କରିବା ଉଚିତ ନୁହେଁ? ମୁଁ \n ଅନଲାଇନ ରେ ବହୁତ ପୁଅ ମାନଙ୍କୁ ଭଲ ଭଲ ରୋଷେଇ କରିବାର ଦେଖିଛି|",
              "as": "দেউতাই কিয় ভাবে যে ৰন্ধন ল'ৰাৰ বাবে নহয়? মই অনলাইনত বহুতো ভিডিঅ' দেখিছিলো য'ত ল'ৰাবোৰেও ভাল খাদ্য ৰান্ধে।",
              "gu": "બાબુજીને કેમ લાગે છે કે રસોઈ છોકરાઓ માટે નથી? મેં ઘણા બધા વિડીયો ઓનલાઈન જોયા છે જેમાં પુરૂષો પણ ઉત્તમ ભોજન બનાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "I like learning and trying new things. I learnt so much from editing videos and uploading them. So why not cook and create new recipes too?",
              "hi": "मुझे सीखना और नई चीजें बनाने की कोशिश करना पसंद है। मैंने वीडियो संपादित करने और उन्हें अपलोड करने से बहुत कुछ सीखा। तो क्यों न खाना पकाएं और नई विधियां भी बनाएं ?",
              "ka": "ನಾನು ಹೊಸದನ್ನು ಕಲಿಯಲು ಮತ್ತು ಪ್ರಯತ್ನಿಸಲು ಇಷ್ಟಪಡುತ್ತೇನೆ. ವೀಡಿಯೊಗಳನ್ನು ತಿದ್ದುಪಡಿ ಮಾಡುವುದರಿಂದ ಮತ್ತು ಅವುಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡುವುದರಿಂದ ನಾನು ತುಂಬಾ ಕಲಿತಿದ್ದೇನೆ. ಹಾಗಾದರೆ ಹೊಸ ಪಾಕವಿಧಾನಗಳನ್ನು ಏಕೆ ಮಾಡಬಾರದು ಮತ್ತು ರಚಿಸಬಾರದು?",
              "te": "నాకు కొత్త విషయాలను నేర్చుకోవడం మరియు ప్రయత్నించడం ఇష్టం. వీడియోలను సవరించడం మరియు వాటిని అప్‌లోడ్ చేయడం ద్వారా నేను చాలా నేర్చుకున్నాను. కాబట్టి కొత్త వంటకాలను కూడా ఎందుకు వండకూడదు?",
              "or": "ମୁଁ ନୂଆ ନୂଆ କଥା ଶିଖିବାକୁ ଏବଂ କାର୍ଯ୍ୟକାରୀ କରିବାକୁ ପସନ୍ଦ କରେ|ମୁଁ ଭିଡିଓ କୁ\n ସମ୍ପାଦନ କରିବା ଏବଂ ତାକୁ ଅନଲାଇନ ରେ ଅପଲୋଡ କରିବା ବିଷୟରେ ମଧ୍ୟ ଶିଖିଛି|\n ତେବେ ରୋଷେଇ ଶିଖିବା ଏବଂ ନୂଆ ନୂଆ ପକ୍ଵାନ ତିଆରି କରିବା କାହିଁକି ନୁହେଁ?",
              "as": "মই নতুন বস্তু শিকিবলৈ আৰু চেষ্টা কৰিবলৈ ভাল পাওঁ। মই ভিডিঅ' এডিট আৰু সেইবোৰ আপলোড কৰাৰ পৰা বহুত শিকিছিলোঁ। তেন্তে কিয় ৰন্ধা আৰু নতুন ৰেচিপি নবনাম?",
              "gu": "મને નવું શીખવું અને અજમાવવું ગમે છે. હું વિડિયો એડિટ કરીને અને અપલોડ કરવાથી ઘણું શીખ્યો છું. તો શા માટે રસોઇ ન કરૂ અને નવી વાનગીઓ પણ બનાવું?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "I have to do engineering because our cousin, Sujay Bhaiya, is doing engineering.",
              "hi": "मुझे इंजीनियरिंग करना है क्योंकि हमारे चचेरे भाई सुजय भाई, इंजीनियरिंग कर रहे हैं।",
              "ka": "ನಮ್ಮ ಸೋದರ ಸಂಬಂಧಿ ಸುಜಯ್ ಭಯ್ಯಾ ಇಂಜಿನಿಯರಿಂಗ್ ಮಾಡುತ್ತಿರುವುದರಿಂದ ನಾನು ಇಂಜಿನಿಯರಿಂಗ್ ಮಾಡಬೇಕಾಗಿದೆ.",
              "te": "మా బంధువు సుజయ్ అన్నయ్య ఇంజినీరింగ్ చేస్తున్నందున నేను ఇంజినీరింగ్ చేయాల్సి వచ్చింది.",
              "or": "ମତେ ବି ଇଞ୍ଜିନିୟରିଂ କରିବାକୁ ପଡିବ କାରଣ ମୋ ସୁଜୟ ଭାଇ ଇଞ୍ଜିନିୟରିଂ \n କରୁଛନ୍ତି|",
              "as": "মই ইঞ্জিনিয়াৰিং কৰিব লাগিব কিয়নো আমাৰ সম্পৰ্কীয় দাদা সুজয় দাদাই ইঞ্জিনিয়াৰিং কৰি আছে।",
              "gu": "મારે એન્જિનિયરિંગ કરવું છે કારણ કે અમારા પિતરાઈ ભાઈ, સુજય ભાઈ એન્જિનિયરિંગ કરે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "Reflection",
              "hi": "प्रतिबिंब",
              "ka": "ಚಿಂತನೆ",
              "te": "ప్రతిబింబించడం",
              "or": "ପ୍ରତିଫଳନ|",
              "as": "প্ৰতিফলন",
              "gu": "પ્રતિભાવ"
            }
          }
        ]
      },
      "scene2": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_1_1",
              "hi": "X2_1_1",
              "ka": "X2_1_1",
              "te": "X2_1_1",
              "or": "X2_1_1",
              "as": "X2_1_1",
              "gu": "X2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar is in 10th standard, and he is still helping people in the village understand all the apps and features on their phones.",
              "hi": "शिखर दसवीं कक्षा में है, और वह अभी भी गांव में लोगों को अपने फोन पर सभी ऐप्स और सुविधाओं को समझने में सहायता कर रहा है।",
              "ka": "ಶಿಖರ್ 10 ನೇ ತರಗತಿಯಲ್ಲಿ ಓದುತ್ತಿದ್ದಾನೆ, ಮತ್ತು ಅವನು ಇನ್ನೂ ಹಳ್ಳಿಯ ಜನರಿಗೆ ತಮ್ಮ ಫೋನ್‌ಗಳಲ್ಲಿನ ಎಲ್ಲಾ ಅಪ್ಲಿಕೇಶನ್‌ಗಳು ಮತ್ತು ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತಿದ್ದಾನೆ.",
              "te": "శిఖర్ 10వ తరగతి చదువుతున్నాడు మరియు ఇప్పటికీ గ్రామంలోని ప్రజలు వారి ఫోన్లలోని అన్ని యాప్‌ లు మరియు లక్షణాలను అర్థం చేసుకోవడంలో సహాయం చేస్తున్నాడు.",
              "or": "ଶିଖର ଦଶମ ଶ୍ରେଣୀ ରେ ପଢେ, ଏବଂ ସେ ଗାଁର ଲୋକ ମାନଙ୍କୁ ସବୁ ପ୍ରକାର ଆପ ଗୁଡିକର ବ୍ୟବହାର ଏବଂ ଉପାଦେୟତାକୁ ବୁଝିବାରେ ସାହାଯ୍ୟ କରେ|",
              "as": "শিখৰ দশম শ্ৰেণীত আছে, আৰু সি এতিয়াও গাওঁখনৰ লোকসকলক তেওঁলোকৰ ফোনৰ সকলো এপ আৰু সুবিধা বুজাত সহায় কৰি আছে।",
              "gu": "શિખર 10મા ધોરણમાં છે, અને તે હજુ પણ ગામના લોકોને તેમના ફોન પરની તમામ એપ્સ અને સુવિધાઓ સમજવામાં મદદ કરી રહ્યો છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_1_2",
              "hi": "X2_1_2",
              "ka": "X2_1_2",
              "te": "X2_1_2",
              "or": "X2_1_2",
              "as": "X2_1_2",
              "gu": "X2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He also starts helping on the farm whenever his brother and father need extra help.",
              "hi": "जब भी उसके भाई और पिता को अतिरिक्त सहयोग की आवश्यकता होती है तो वह खेत में सहायता करना शुरु कर देता है।",
              "ka": "ತನ್ನ ಸಹೋದರ ಮತ್ತು ತಂದೆಗೆ ಹೆಚ್ಚುವರಿ ಸಹಾಯ ಬೇಕಾದಾಗ ಅವನು ಜಮೀನಿನಲ್ಲಿ ಸಹಾಯ ಮಾಡಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ.",
              "te": "తన సోదరుడు మరియు తండ్రికి అదనపు సహాయం అవసరమైనప్పుడు అతను కూడా పొలంలో సహాయం చేయడం ప్రారంభిస్తాడు.",
              "or": "ଯେତେବେଳେ କ୍ଷେତ ରେ ତାର ବାପା ଓ ଭାଇ ତାର ସାହାଯ୍ୟ ଦରକାର କରନ୍ତି,\n ସେ ସାହାଯ୍ୟ କରେ|",
              "as": "যেতিয়াই তাৰ ককায়েক আৰু দেউতাকক অধিক সহায়ৰ প্ৰয়োজন হয় তেতিয়াই সি খেতিপথাৰত সহায় কৰিবলৈ আৰম্ভ কৰে।",
              "gu": "જ્યારે પણ તેના ભાઈ અને પિતાને વધારાની મદદની જરૂર હોય ત્યારે તે ખેતરમાં મદદ કરવાનું પણ શરૂ કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_2_1//",
              "hi": "X2_2_1 //",
              "ka": "X2_2_1//",
              "te": "X2_2_1//",
              "or": "X2_2_1//",
              "as": "X2_2_1//",
              "gu": "X2_2_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "One day, while Shikhar is on his brother's phone, Arun Bhaiya takes the phone away from him.",
              "hi": "एक दिन, जब शिखर अपने भाई के फोन पर है, अरुण भैया उससे फोन ले लेते हैं।",
              "ka": "ಒಂದು ದಿನ, ಶಿಖರ್ ತನ್ನ ಸಹೋದರನ ಫೋನ್‌ನಲ್ಲಿದ್ದಾಗ, ಅರುಣ್ ಭಯ್ಯಾ ಅವನಿಂದ ಫೋನ್ ತೆಗೆದುಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "ఒకరోజు, శిఖర్ తన సోదరుడి ఫోన్లో చూస్తుంటే, అరుణ్ అన్నయ్య అతని నుండి ఫోన్ తీసుకుంటాడు.",
              "or": "ଦିନେ ଶିଖର ତା ଭୈରା ଫୋନରେ ଥିବାବେଳେ,ଅରୁଣ ଭାଇ ତା ହାତରୁ ଫୋନ ଟିକୁ \n ଛଡାଇ ନିଅନ୍ତି|",
              "as": "এদিন, শিখৰে তাৰ ককায়েকৰ ফোন চাই থকাৰ সময়ত, অৰুণ দাদাই ফোনটো তাৰ পৰা আঁতৰাই লৈ যায়।",
              "gu": "એક દિવસ, જ્યારે શિખર તેના ભાઈના ફોન પર હતો, ત્યારે અરુણ ભાઈ તેની પાસેથી ફોન છીનવી લે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Arun Bhaiya",
              "hi": "अरुण भैया ",
              "ka": "ಅರುಣ್ ಭೈಯ್ಯ",
              "te": "అరుణ్ అన్నయ్య",
              "or": "ଅରୁଣ ଭାଇ",
              "as": "অৰুণ দাদা",
              "gu": "અરુણ ભાઈ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Arun Bhaiya",
              "hi": "अरुण भैया ",
              "ka": "ಅರುಣ್ ಭೈಯ್ಯ",
              "te": "అరుణ్ అన్నయ్య",
              "or": "ଅରୁଣ ଭାଇ",
              "as": "অৰুণ দাদা",
              "gu": "અરુણ ભાઈ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Are you watching those cooking shows again?",
              "hi": "क्या तुम उन खाना पकाने के शो को फिर से देख रहे हो?",
              "ka": "ನೀನು ಮತ್ತೆ ಆ ಅಡುಗೆ ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ನೋಡುತ್ತಿದ್ದೀಯಾ?",
              "te": "నువ్వు మళ్లీ ఆ వంటల ప్రదర్శనలను చూస్తున్నావా?",
              "or": "ତୁ ପୁଣି ସେହି ରୋଷେଇ ଭିଡିଓ କୁ ଦେଖୁଚୁ?",
              "as": "তুমি সেই ৰন্ধা শ্ব'বোৰ আকৌ চাই আছা নেকি?",
              "gu": "શું તું તે રસોઈ શો ફરીથી જોઈ રહ્યો છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Bhaiya, see how many likes Devansh’s last video got! We made this video using a new method.",
              "hi": "भैया, देखिए कि देवांश के पिछले वीडियो को कितने लाइक्स मिले हैं! हमने इस वीडियो को एक नई विधि का उपयोग करके बनाया था।",
              "ka": "ಭೈಯ್ಯ, ದೇವಾಂಶ್ ಅವರ ಹಿಂದಿನ ವೀಡಿಯೊಗೆ ಎಷ್ಟು ಲೈಕ್‌ಗಳು ಸಿಕ್ಕಿವೆ ನೋಡು! ನಾವು ಹೊಸ ವಿಧಾನವನ್ನು ಬಳಸಿಕೊಂಡು ಈ ವೀಡಿಯೊವನ್ನು ಮಾಡಿದ್ದೇವೆ.",
              "te": "అన్నయ్య, దేవాన్ష్ చివరి వీడియోకి ఎన్ని లైక్లు వచ్చాయో చూడండి! మేము ఈ వీడియోను కొత్త పద్ధతిని ఉపయోగించి చేసాము.",
              "or": "ଭାଇ, ଦେଖା ଦେବାଂଶର ପୂର୍ବ ଭିଡିଓ କେତେ ସାରା ଲାଇକ ପାଇଛି!ଆମେ ଏକ ନୂଆ \n ପ୍ରଣାଳୀ ରେ ଏହି ଭିଡିଓକୁ କରିଥିଲୁ|",
              "as": "দাদা, দেৱাংশৰ শেষ ভিডিঅ'টোৱে কিমান লাইক পাইছে চোৱা! আমি এটা নতুন পদ্ধতি ব্যৱহাৰ কৰি এই ভিডিঅ'টো তৈয়াৰ কৰিছোঁ।",
              "gu": "ભાઈ, જુઓ દેવાંશના છેલ્લા વીડિયોને કેટલી લાઈક્સ મળી! અમે એક નવી પદ્ધતિનો ઉપયોગ કરીને આ વિડિયો બનાવ્યો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Stop wasting your time Shikhar. We need help in the field starting tomorrow. Come to the farm directly after school.",
              "hi": " शिखर अपने समय को बर्बाद करना बंद करो। हमें खेत में कल से मदद की ज़रूरत है। स्कूल के बाद सीधे खेत में आ जाना।",
              "ka": "ನಿನ್ನ ಸಮಯವನ್ನು ವ್ಯರ್ಥ ಮಾಡುವುದನ್ನು ನಿಲ್ಲಿಸು ಶಿಖರ್. ನಾಳೆಯಿಂದ ಪ್ರಾರಂಭವಾಗುವ ಜಮೀನಿನ ಕೆಲಸದಲ್ಲಿ ನಮಗೆ ಸಹಾಯ ಬೇಕು. ಶಾಲೆ ಮುಗಿಸಿ ನೇರವಾಗಿ ಜಮೀನಿಗೆ ಬಾ.",
              "te": "నీ సమయాన్ని వృథా చేయడం ఆపు శిఖర్. రేపటి నుండి పొలంలో మాకు సహాయం కావాలి. పాఠశాల అయ్యాక నేరుగా పొలానికి రా.",
              "or": "ନିଜର ସମୟକୁ ବର୍ବାଦ କରିବା ବନ୍ଦ କର ଶିଖର |ଆମେ କାଲିଠାରୁ କ୍ଷେତରେ \n ସାହାଯ୍ୟ ଦରକାର କରୁଛୁ| ସ୍କୁଲ ସମୟ ସାରିବା ପରେ ସିଧା କ୍ଷେତ କୁ ଆସିବୁ|",
              "as": "তোমাৰ সময় নষ্ট কৰা বন্ধ কৰা শিখৰ। কাইলৈৰ পৰা পথাৰৰ কামত আমাক সহায় লাগিব। স্কুলৰ পিছত চিধা ফাৰ্মলৈ আহিবা।",
              "gu": "તારો સમય બગાડવાનું બંધ કર શિખર. અમને આવતીકાલથી ખેતરમાં મદદની શરૂઆત કર જે જરૂર છે. શાળા પછી સીધો ખેતરમાં આવજે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "Bhaiya, I’ve been called to the panchayat office tomorrow.",
              "hi": "भैया, मुझे कल पंचायत कार्यालय में बुलाया गया है।",
              "ka": "ಭೈಯ್ಯ, ನಾಳೆ ನನ್ನನ್ನು ಪಂಚಾಯತ್ ಕಚೇರಿಗೆ ಬರಲು ಕರೆದಿದ್ದಾರೆ",
              "te": "అన్నయ్య, రేపు నన్ను పంచాయితీ ఆఫీసుకి పిలిచారు.",
              "or": "ଭାଇ, ମତେ ଆସନ୍ତା କାଲୀ ପାଇଁ ପଞ୍ଚାଯତ ଅଫିସରୁ ଡାକରା ଆସିଛି|",
              "as": "দাদা, কাইলৈ মোক পঞ্চায়ত অফিচলৈ মাতিছে।",
              "gu": "ભાઈ, મને કાલે પંચાયત ઓફિસે બોલાવવામાં આવ્યો છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_2_2////",
              "hi": "X2_2_2 /////",
              "ka": "X2_2_2////",
              "te": "X2_2_2////",
              "or": "X2_2_2////",
              "as": "X2_2_2////",
              "gu": "X2_2_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Okay, finish your work and come.",
              "hi": "ठीक है, अपना काम खत्म कर के आ जाना।",
              "ka": "ಸರಿ ನಿನ್ನ ಕೆಲಸವನ್ನು ಮುಗಿಸಿಕೊಂಡು  ಬಾ.",
              "te": "సరే నీ పని ముగించుకుని రా.",
              "or": "ଠିକ ଅଛି, ନିଜ କାମ ସାରି ଆସିବୁ|",
              "as": "ঠিক আছে, তোমাৰ কাম শেষ কৰা আৰু আহা।",
              "gu": "ઠીક છે, તારું કામ પૂરું કરીને આવજે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "No Bhaiya, I am going there as a volunteer for the next week. The Panchayat is doing seed bank data collection on the app.",
              "hi": "नहीं भैया, मैं वहां अगले सप्ताह के लिए स्वेच्छा से जा रहा हूं। पंचायत ऐप पर बीज बैंक डेटा संग्रह कर रहा है।",
              "ka": "ಇಲ್ಲ ಭೈಯ್ಯ, ನಾನು ಮುಂದಿನ ವಾರ ಸ್ವಯಂಸೇವಕನಾಗಿ ಅಲ್ಲಿಗೆ ಹೋಗುತ್ತಿದ್ದೇನೆ. ಪಂಚಾಯತ್ ಆ್ಯಪ್‌ನಲ್ಲಿ ಬೀಜ ಬ್ಯಾಂಕ್ ಮಾಹಿತಿ ಸಂಗ್ರಹಣೆ ಮಾಡುತ್ತಿದೆ.",
              "te": "లేదు అన్నయ్య, నేను వచ్చే వారం వాలంటీర్‌ గా అక్కడికి వెళ్తున్నాను. పంచాయతీ యాప్‌ లో విత్తన బ్యాంక్ డేటా సేకరణ చేస్తోంది.",
              "or": "ନା ଭାଇ, ଆସନ୍ତା ସପ୍ତାହ ପାଇଁ ମୁ ଭଲାନ୍ଟିୟର ଭାବେ ଯାଉଛି| ପଞ୍ଚାଯତ ଆପ ମାଧ୍ୟମରେ ବ୍ୟାଙ୍କ ବିବରଣ ସଂଗ୍ରହ ର କାମ କରିବାକୁ ଯାଉଛି|",
              "as": "দাদা নহয়, মই অহা এসপ্তাহৰ বাবে স্বেচ্ছাসেৱক হিচাপে তালৈ যাম। পঞ্চায়তে এপটোত বীজ বেংকৰ তথ্য সংগ্ৰহ কৰি আছে।",
              "gu": "ના ભાઈ, હું આવતા અઠવાડિયે સ્વયંસેવક તરીકે ત્યાં જઈ રહ્યો છું. પંચાયત એપ પર બીજ બેંક ડેટા ભેગા કરી રહી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "What will you do there?",
              "hi": "तुम वहां क्या करोगे?",
              "ka": "ನೀನು ಅಲ್ಲಿ ಏನು ಮಾಡುತ್ತೀಯಾ/",
              "te": "అక్కడ ఏం చేస్తావు?",
              "or": "ତୁମେ କଣ କରିବା ସେଠି?",
              "as": "তুমি তাত কি কৰিবা?",
              "gu": "તું ત્યાં શું કરશો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "I helped the Panchayat president with the video app sometime back, so he asked me to volunteer for this new thing they are trying.",
              "hi": "मैंने पंचायत अध्यक्ष की वीडियो ऐप बनाने में कुछ दिन पहले सहायता की थी, इसलिए उन्होंने मुझे इस नए काम में अपनी सेवाएं देने के लिए कहा है।",
              "ka": "ನಾನು ಸ್ವಲ್ಪ ಸಮಯದ ಹಿಂದೆ ವೀಡಿಯೊ ಅಪ್ಲಿಕೇಶನ್‌ನೊಂದಿಗೆ ಪಂಚಾಯತ್ ಅಧ್ಯಕ್ಷರಿಗೆ ಸಹಾಯ ಮಾಡಿದ್ದೇನೆ, ಆದ್ದರಿಂದ ಅವರು ಪ್ರಯತ್ನಿಸುತ್ತಿರುವ ಈ ಹೊಸ ವಿಷಯಕ್ಕಾಗಿ ಸ್ವಯಂಸೇವಕನಾಗಿ ಬರಲು ನನ್ನನ್ನು ಕೇಳಿದರು",
              "te": "నేను కొంత కాలం క్రితం వీడియో యాప్‌ గురించి పంచాయతీ ప్రెసిడెంట్‌కి సహాయం చేసాను, కాబట్టి వారు ప్రయత్నిస్తున్న ఈ కొత్త పనికి వాలంటీర్ గా రావాలని ఆయన నన్ను కోరారు.",
              "or": "କିଛି ସମୟ ପୂର୍ବରୁ ମୁଁ ପଞ୍ଚାଯତ ସଭାପତିଙ୍କୁ ଭିଡିଓ ଆପ ସମ୍ବନ୍ଧିତ ସାହାଯ୍ୟ \n କରିଥିଲି , ତେଣୁ ସେମାନେ ଚେଷ୍ଟା କରୁଥିବା ନୂତନ କୌଶଳ ପାଇଁ ମତେ ସହଯୋଗୀ ଭାବେ ଡାକିଛନ୍ତି|",
              "as": "মই কিছুসময় আগতে পঞ্চায়তৰ সভাপতিক ভিডিঅ' এপটোত সহায় কৰিছিলো, সেয়েহে তেওঁ মোক তেওঁলোকে চেষ্টা কৰি থকা এই নতুন কামটোৰ বাবে স্বেচ্ছাসেৱী হ'বলৈ কৈছিল।",
              "gu": "મેં થોડા સમય પહેલા પંચાયત પ્રમુખને વીડિયો એપ દ્વારા મદદ કરી હતી, તેથી તેમણે મને આ નવી વસ્તુ માટે સ્વયંસેવક બનવા કહ્યું જે તેઓ પ્રયાસ કરી રહ્યા છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Shikhar! You give all your time to help other people out! Here your own family is asking you for help, and you can’t take some time out for us?",
              "hi": "शिखर! तुम अपना सभी समय अन्य लोगों की सहायता करने के लिए देते हो! यहां तुम्हारा परिवार तुम्हारी सहायता मांग रहा है, और तुम हमारे लिए कुछ समय नहीं निकाल सकते?",
              "ka": "ಶಿಖರ್! ಇತರ ಜನರಿಗೆ ಸಹಾಯ ಮಾಡಲು ನೀನು ನಿನ್ನ ಎಲ್ಲಾ ಸಮಯವನ್ನು ನೀಡುತ್ತೀಯಾ! ಇಲ್ಲಿ ನಿನ್ನ ಸ್ವಂತ ಕುಟುಂಬವು ನಿನ್ನನ್ನು ಸಹಾಯಕ್ಕಾಗಿ ಕೇಳುತ್ತಿದೆ ಮತ್ತು ನಮಗಾಗಿ ನೀನು ಸ್ವಲ್ಪ ಸಮಯವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಸಾಧ್ಯವಿಲ್ಲವೇ?",
              "te": "శిఖర్! ఇతర వ్యక్తులకు సహాయం చేయడానికి నువ్వు నీ సమయాన్ని పెడతావు! ఇక్కడ నీ స్వంత కుటుంబం నిన్ను సహాయం కోసం అడుగుతోంది. కానీ నువ్వు మా కోసం కొంత సమయం కేటాయించలేవా?",
              "or": "ଶିଖର ତୁମେ ସବୁବେଳେ ନିଜର ସମୟକୁ ଅନ୍ୟକୁ ସାହାଜ୍ୟ କରିବା ପାଇଁ ଲଗାଉଛ \n ,କିନ୍ତୁ ଏଠି ତୁମ ନିଜ ପରିବାର ତୁମାଠାରୁ ସମୟ ମାଗୁଛି, ଏବଂ ତୁମେ ଆମ ପାଇଁ ସମୟ \n ଦେଇପାରିବ କି?",
              "as": "শিখৰ! তুমি আন লোকক সহায় কৰিবলৈ তোমাৰ সকলো সময় দিয়া! ইয়াত তোমাৰ নিজৰ পৰিয়ালে তোমাক সহায় বিচাৰিছে, আৰু তুমি আমাৰ বাবে অলপ সময় উলিয়াব নোৱাৰা?",
              "gu": "શિખર! તમે અન્ય લોકોને મદદ કરવા માટે તારો બધો સમય આપ છે! અહીં તારું પોતાનું કુટુંબ તારી પાસે મદદ માટે પૂછે છે, અને તું અમારા માટે થોડો સમય નથી કાઢી શકતો?"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_2_3",
              "hi": "X2_2_3",
              "ka": "X2_2_3",
              "te": "X2_2_3",
              "or": "X2_2_3",
              "as": "X2_2_3",
              "gu": "X2_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar is confused about what he should do. He wants to help his brother but feels that the opportunity to help the Panchayat would be good for him.",
              "hi": "शिखर उलझन में है कि उसे क्या करना चाहिए। वह अपने भाई की सहायता करना चाहता है लेकिन महसूस करता है कि पंचायत की सहायता करने का अवसर उसके लिए अच्छा होगा।",
              "ka": "ಶಿಖರ್ ಏನು ಮಾಡಬೇಕು ಎನ್ನುವ ಗೊಂದಲದಲ್ಲಿದ್ದಾನೆ. ಅವನು ತನ್ನ ಸಹೋದರನಿಗೆ ಸಹಾಯ ಮಾಡಲು ಬಯಸುತ್ತಾನೆ ಆದರೆ ಪಂಚಾಯತ್‌ಗೆ ಸಹಾಯ ಮಾಡುವ ಅವಕಾಶವು ತನಗೆ ಒಳ್ಳೆಯದು ಎಂದು ಭಾವಿಸುತ್ತಾನೆ",
              "te": "శిఖర్ ఏం చేయాలో తెలియక అయోమయంలో ఉన్నాడు. అతను తన సోదరుడికి సహాయం చేయాలనుకుంటున్నాడు, కానీ పంచాయతీకి సహాయం చేసే అవకాశం అతనికి మంచిదని భావిస్తాడు.",
              "or": "ଶିଖର କଣ କରିବ ନ କରିବ ସେ ବାବଦରେ ଦ୍ଵନ୍ଦ୍ଵ ରେ ପଡିଯାଇଛି|ସେ ନିଜ ଭାଇକୁ\n ସାହାଯ୍ୟ କରିବାକୁ ଚାହୁଛି କିନ୍ତୁ ପଞ୍ଚାଯତ କୁ ସାହାଯ୍ୟ କରିବାର ଅବସର ତା ପାଇଁ\n ଭଲ ବୋଲି ମଧ୍ୟ ଅନୁଭବ କରୁଛି|",
              "as": "শিখৰে কি কৰিব লাগে তাক লৈ চিন্তিত। সি তাৰ ককায়েকক সহায় কৰিব বিচাৰে কিন্তু ভাবে যে পঞ্চায়তক সহায় কৰাৰ সুযোগ তাৰ বাবে ভাল হ'ব।",
              "gu": "શિખર મૂંઝવણમાં છે કે તેણે શું કરવું જોઈએ. તે તેના ભાઈને મદદ કરવા માંગે છે પરંતુ તેને લાગે છે કે પંચાયતને મદદ કરવાની તક તેના માટે સારી રહેશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_2_4_D",
              "hi": "X2_2_4_d",
              "ka": "X2_2_4_D",
              "te": "X2_2_4_D",
              "or": "X2_2_4_D",
              "as": "X2_2_4_D",
              "gu": "X2_2_4_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Shikhar do?",
              "hi": "शिखर को क्या करना चाहिए?",
              "ka": "ಶಿಖರ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "శిఖర్ ఏం చేయాలి?",
              "or": "ଶିଖର କଣ କରିବା ଉଚିତ?",
              "as": "শিখৰে কি কৰিব লাগে?",
              "gu": "શિખરે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Help on the farm",
              "hi": "खेत में मदद",
              "ka": "ಜಮೀನಿನಲ್ಲಿ ಸಹಾಯ ಮಾಡುವುದು",
              "te": "పొలంలో సహాయం",
              "or": "କ୍ଷେତ ରେ ସାହାଯ୍ୟ",
              "as": "ফাৰ্মত সহায় কৰা",
              "gu": "ખેતરમાં મદદ કરવી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Volunteer at the Panchayat",
              "hi": "पंचायत में स्वयंसेवी ",
              "ka": "ಪಂಚಾಯತ್ ನಲ್ಲಿ ಸ್ವಯಂಸೇವಕನಾಗುವುದು",
              "te": "పంచాయతీ వద్ద వాలంటీర్",
              "or": "ପଞ୍ଚାଯତ ରେ ସହଯୋଗିତା",
              "as": "পঞ্চায়তত স্বেচ্ছাসেৱক",
              "gu": "પંચાયતમાં સ્વયંસેવક"
            }
          }
        ]
      },
      "scene3": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Help on the farm",
              "hi": "खेत में मदद",
              "ka": "ಜಮೀನಿನಲ್ಲಿ ಸಹಾಯವನ್ನು ಮಾಡುವುದು",
              "te": "పొలంలో సహాయం",
              "or": "କ୍ଷେତ ରେ ସାହାଯ୍ୟ",
              "as": "ফাৰ্মত সহায় কৰা",
              "gu": "ખેતરમાં મદદ કરવી"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar starts working on the farm after school. It is a lot of work, but he likes it. Every time he works on the farm, he gets a lot of ideas.",
              "hi": "शिखर स्कूल के बाद खेत पर काम करना शुरु कर देता है। बहुत काम है, लेकिन वह इसे पसंद करता है। हर बार जब वह खेत पर काम करता है, तो उसे बहुत सारे विचार मिलते हैं।",
              "ka": "ಶಿಖರ್ ಶಾಲೆಯ ನಂತರ ಜಮೀನಿನಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ. ಇದು ಬಹಳಷ್ಟು ಕೆಲಸವಾಗುತ್ತದೆ, ಆದರೆ ಅವನು ಅದನ್ನು ಇಷ್ಟಪಡುತ್ತಾನೆ. ಪ್ರತಿಬಾರಿ ಜಮೀನಿನಲ್ಲಿ ಕೆಲಸ ಮಾಡುವಾಗಲೂ ಹಲವು ವಿಚಾರಗಳು ಬರುತ್ತವೆ.",
              "te": "శిఖర్ స్కూల్ అయిపోయిన తర్వాత పొలంలో పనిచేయడం మొదలుపెడతాడు. ఇది చాలా ఎక్కువ పని, కానీ అతను దానిని ఇష్టపడతాడు. పొలంలో పనిచేసినప్పుడల్లా అతనికి ఎన్నో ఆలోచనలు వస్తాయి.",
              "or": "ସ୍କୁଲ ସମୟ ପରେ ଶିଖର କ୍ଷେତ ରେ କାମ କରେ| ଏହା ବହୁତ ଗୁଡିଏ କାମ \n କିନ୍ତୁ ଶିଖର ଏହାକୁ ଭଲ ପାଏ| ଯେତିକି ସମୟ ସେ କ୍ଷେତ ରେ କାମ କରେ ସେ ବହୁତ ଗୁଡିଏ ଯୋଜନା ପାଏ|",
              "as": "শিখৰে স্কুলৰ পিছত ফাৰ্মত কাম কৰা আৰম্ভ কৰে। ইয়াত বহুত কাম কৰিব লাগে, কিন্তু সি এইটো ভাল পায়। যেতিয়াই সি ফাৰ্মত কাম কৰে, সি বহুতো আইডিয়া পায়।",
              "gu": "શિખર શાળા પછી ખેતરમાં કામ કરવાનું શરૂ કરે છે. ત્યાં ઘણું કામ છે, પરંતુ તેને તે ગમે છે. જ્યારે પણ તે ખેતરમાં કામ કરે છે, ત્યારે તેને ઘણા વિચારો આવે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2i_1_2",
              "hi": "X2i_1_2",
              "ka": "X2i_1_2",
              "te": "X2i_1_2",
              "or": "X2i_1_2",
              "as": "X2i_1_2",
              "gu": "X2i_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He learns a lot about harvesting, and he is looking forward to going to the mandi to sell their harvest with his brother.",
              "hi": "वह फसल की कटाई के बारे में बहुत कुछ सीखता है, और अपने भाई के साथ अपनी फसल बेचने के लिए मंडी जाने की उम्मीद कर रहा है।",
              "ka": "ಆತನು ಕಟಾವು ಬಗ್ಗೆ ಸಾಕಷ್ಟು ಕಲಿಯುತ್ತಾನೆ, ಹಾಗೂ  ಅವನು ತಮ್ಮ ಕಟಾವನ್ನು ತನ್ನ ಸಹೋದರನೊಂದಿಗೆ ಮಾರಲು ಮಂಡಿಗೆ ಹೋಗಲು ಎದುರು ನೋಡುತ್ತಿದ್ದಾನೆ.",
              "te": "అతను కోత గురించి చాలా నేర్చుకుంటాడు మరియు అతను తన సోదరుడితో కలిసి తమ పంటను అమ్మడానికి మండి (మార్కెట్ )కి వెళ్లాలని ఎదురు చూస్తున్నాడు.",
              "or": "ଫସଲ ଅମଳ ବିଷୟରେ ସେ ବହୁତ କିଛି ଶିଖେ, ଏବଂ ନିଜ ଭାଇ ସହିତ ମଣ୍ଡୀ କୁ ଯାଇ ଅମଳ ହୋଇଥିବା ଫସଲ କୁ ବିକିବା ପାଇଁ ଅପେକ୍ଷା କରେ|",
              "as": "সি শস্য চপোৱাৰ বিষয়ে বহুত শিকে, আৰু সি তাৰ ককায়েকৰ সৈতে তেওঁলোকৰ শস্য বিক্ৰী কৰিবলৈ মাণ্ডিলৈ যাবলৈ বাট চাই আছে।",
              "gu": "તે લણવા વિશે ઘણું શીખે છે, અને તે તેના ભાઈ સાથે તેમની લણેલું વેચવા માટે મંડીમાં જવાની રાહ જોઈ રહ્યો છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2i_1_3//",
              "hi": "X2i_1_3 //",
              "ka": "X2i_1_3//",
              "te": "X2i_1_3//",
              "or": "X2i_1_3//",
              "as": "X2i_1_3//",
              "gu": "X2i_1_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "His interest in the kitchen and watching recipe videos online, helped him learn more about vegetables. He suggests some new ideas to his brother.",
              "hi": "रसोई में उसकी रुचि और व्यंजन बनाने की विधि के वीडियो ऑनलाइन देखकर, उसे सब्जियों के बारे में और अधिक जानने में मदद मिली। वह अपने भाई को कुछ नए विचारों का सुझाव देता है।",
              "ka": "ಅಡುಗೆಮನೆಯಲ್ಲಿನ ಅವನ ಆಸಕ್ತಿ ಮತ್ತು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ರೆಸಿಪಿ ವೀಡಿಯೊಗಳನ್ನು ವೀಕ್ಷಿಸುವುದು, ತರಕಾರಿಗಳ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಅವನಿಗೆ ಸಹಾಯ ಮಾಡಿತು. ಅವನು ತನ್ನ ಸಹೋದರನಿಗೆ ಕೆಲವು ಹೊಸ ಆಲೋಚನೆಗಳನ್ನು ಸೂಚಿಸುತ್ತಾನೆ.",
              "te": "వంటగదిలో అతని ఆసక్తి మరియు ఆన్‌లైన్‌ లో వంటల వీడియోలను చూడటం, కూరగాయల గురించి మరింత తెలుసుకోవడానికి అతనికి సహాయపడింది. అతను తన సోదరుడికి కొన్ని కొత్త ఆలోచనలను సూచిస్తాడు.",
              "or": "ଅନଲାଇନ ପକ୍ଵାନ ବିଧି ଦେଖିବା ଓ ରୋଷେଇ ରେ ଥିବା ତାର ରୁଚି ତାକୁ \n ପରିବା ବିଷୟରେ ଜାଣିବାରେ ବହୁତ ସାହାଯ୍ୟ କଲା | ସେ ନିଜ ଭାଇ କୁ ନୂଆ \n  ନୂଆ ଯୋଜନା ର ପ୍ରସ୍ତାବ ଦେଲା|",
              "as": "পাকঘৰৰ প্ৰতি তাৰ আগ্ৰহ আৰু অনলাইন ৰেচিপি ভিডিঅ' চোৱাটোৱে তাক পাচলিৰ বিষয়ে অধিক শিকাত সহায় কৰিছিল। সি তাৰ ককায়েকক কিছুমান নতুন পৰামৰ্শ দিয়ে।",
              "gu": "રસોડામાં તેમની રુચિ અને વાનગીના વિડિયો ઓનલાઈન જોવાથી, તેને શાકભાજી વિશે વધુ જાણવામાં મદદ મળી. તે તેના ભાઈને કેટલાક નવા વિચારો સૂચવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arun Bhaiya",
              "hi": "अरुण भैया ",
              "ka": "ಅರುಣ್ ಭೈಯ್ಯ",
              "te": "అరుణ్ అన్నయ్య",
              "or": "ଅରୁଣ ଭାଇ",
              "as": "অৰুণ দাদা",
              "gu": "અરુણ ભાઈ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Bhaiya, why don’t we grow Broccoli?",
              "hi": "भैया, हम ब्रोकोली क्यों नहीं उगाते?",
              "ka": "ಭೈಯ್ಯ, ನಾವು ಬ್ರೊಕೊಲಿಯನ್ನು ಏಕೆ ಬೆಳೆಯಬಾರದು?",
              "te": "అన్నయ్య, మనం బ్రొకోలీని ఎందుకు పెంచకూడదు?",
              "or": "ଭାଇ, ଆମେ ବ୍ରୋକୋଲି ଚାଷ କଲେ କେମିତି ହବ?",
              "as": "দাদা, আমি ব্ৰ'কলিৰ খেতি কিয় নকৰোঁ?",
              "gu": "ભાઈ, આપણે બ્રોકોલી કેમ નથી ઉગાડતા?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "What is that?",
              "hi": "वो क्या है?",
              "ka": "ಹಾಗಂದರೆ ಏನು?",
              "te": "అది ఏమిటి?",
              "or": "ସେଇଟା କଣ?",
              "as": "সেইটো কি?",
              "gu": "એ શું છે?"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2i_1_4////",
              "hi": "X2i_1_4 /////",
              "ka": "X2i_1_4////",
              "te": "X2i_1_4////",
              "or": "X2i_1_4////",
              "as": "X2i_1_4////",
              "gu": "X2i_1_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Bhaiya, Broccoli is a type of vegetable.",
              "hi": "भैया, ब्रोकोली एक प्रकार की सब्जी है।",
              "ka": "ಭೈಯ್ಯ, ಅದು ಒಂದು ರೀತಿಯ ತರಕಾರಿ",
              "te": "అన్నయ్య, బ్రొకోలీ ఒక రకమైన కూరగాయ.",
              "or": "ଭାଇ, ବ୍ରୋକୋଲି ହେଉଛି ଏକ ପ୍ରକାର ପରିବା |",
              "as": "দাদা, ব্ৰ'কলি হৈছে এবিধ পাচলি।",
              "gu": "ભાઈ, બ્રોકોલી એક પ્રકારનું શાક છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Who eats Broccoli? I haven’t heard of anyone eating anything like this. Besides, nobody would buy it.",
              "hi": "ब्रोकोली कौन खाता है? मैंने ऐसे किसी भी खाने के बारे में नहीं सुना है। इसके अलावा, कोई भी इसे नहीं खरीदेगा।",
              "ka": "ಬ್ರೊಕೊಲಿಯನ್ನು ಯಾರು ತಿನ್ನುತ್ತಾರೆ? ಯಾರೂ ಅದನ್ನು ತಿನ್ನುವುದನ್ನು ನಾನು ಕೇಳಿಲ್ಲ. ಇದಲ್ಲದೆ, ಯಾರೂ ಅದನ್ನು ಖರೀದಿಸುವುದೂ ಇಲ್ಲ.",
              "te": "బ్రొకోలీని ఎవరు తింటారు? ఎవరైనా ఇలాంటివి తినడం గురించి నేను ఎప్పుడూ వినలేదు. అంతేకాకుండా, ఎవరూ దానిని కొనరు.",
              "or": "ବ୍ରୋକୋଲି କିଏ ଖାଏ?",
              "as": "ব্ৰ'কলি কোনে খায়? মই কোনোবাই এনে একো খোৱাৰ কথা শুনা নাই। ইয়াৰ উপৰিও, কোনেও ইয়াক নিকিনিব।",
              "gu": "બ્રોકોલી કોણ ખાય છે? મેં કોઈને આવું કંઈ ખાતા સાંભળ્યા નથી. આ ઉપરાંત, કોઈ તેને ખરીદશે નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Trust me, Bhaiya, it is high in demand in the cities. It looks like a green cauliflower, but it sells for much more.",
              "hi": "मेरा विश्वास करो, भैया, शहरों में इसकी बहुत मांग है। यह एक हरे रंग की फूलगोभी की तरह दिखती है, लेकिन यह उससे कहीं अधिक कीमत पर बेचा जाता है।",
              "ka": "ನನ್ನನ್ನು ನಂಬಿ ಭೈಯ್ಯಾ, ನಗರಗಳಲ್ಲಿ ಇದಕ್ಕೆ ಹೆಚ್ಚು ಬೇಡಿಕೆಯಿದೆ. ಇದು ಹಸಿರು ಹೂಕೋಸು ರೀತಿಯೇ ತೋರುತ್ತಾದೆ, ಆದರೆ ಇದು ಹೆಚ್ಚು ಮಾರಾಟವಾಗುತ್ತದೆ.",
              "te": "నన్ను నమ్మండి అన్నయ్య, నగరాల్లో దీనికి గిరాకీ ఎక్కువ. ఇది ఆకుపచ్చ కాలీఫ్లవర్ లాగా కనిపిస్తుంది, కానీ ఇది చాలా ఎక్కువ అమ్ముడవుతోంది.",
              "or": "ବିଶ୍ଵାସ କର ଭାଇ, ସହର ମାନଙ୍କରେ ଏହାର ଚାହିଦା ବହୁତ ବେଶୀ| ଏହା ନୀଳ \n ରଙ୍ଗର ଫୁଲକୋବି ଭଳି|କିନ୍ତୁ ଏହାର ଦାମ ବହୁତ ବେଶୀ|",
              "as": "বিশ্বাস কৰা, দাদা, চহৰবোৰত ইয়াৰ চাহিদা অধিক। ই দেখাত সেউজীয়া ফুলকবিৰ দৰে, কিন্তু ই বহুত বেছি বিক্ৰী হয়।",
              "gu": "મારા પર વિશ્વાસ કરો, ભાઈ, શહેરોમાં તેની માંગ વધારે છે. તે લીલા ફુલાવર જેવું લાગે છે, પરંતુ તે વધુ વેચાય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Shikhar, I'm tired of you and your internet knowledge. My friends were saying that everything you read or see on the internet is not always correct. There is a lot of wrong information too. Hurry up, It’s getting dark, and we need to finish sowing today.",
              "hi": "शिखर, मैं तुमसे और तुम्हारे इंटरनेट ज्ञान से थक गया हूं। मेरे दोस्त कह रहे थे कि इंटरनेट पर जो कुछ भी तुम  पढ़ते या देखते हो वह हमेशा सही नहीं होता है। बहुत सारी गलत जानकारी भी है। जल्दी करो, अंधेरा हो रहा है, और हमें आज बुवाई खत्म करनी है।",
              "ka": "ಶಿಖರ್, ನಾನು ನಿನ್ನ ಮತ್ತು ನಿನ್ನ ಇಂಟರ್ನೆಟ್ ಜ್ಞಾನದಿಂದ ಬೇಸತ್ತಿದ್ದೇನೆ. ನೀನು ಅಂತರ್ಜಾಲದಲ್ಲಿ ಓದುವ ಅಥವಾ ನೋಡುವ ಎಲ್ಲವೂ ಯಾವಾಗಲೂ ಸರಿಯಾಗಿಲ್ಲ ಎಂದು ನನ್ನ ಸ್ನೇಹಿತರು ಹೇಳುತ್ತಿದ್ದರು. ಸಾಕಷ್ಟು ತಪ್ಪು ಮಾಹಿತಿಯೂ ಇದೆ.ಕತ್ತಲಾಗುತ್ತಿದೆ, ಬೇಗನೆ ಮಾಡು, ಮತ್ತು ನಾವು ಇಂದು ಬಿತ್ತನೆಯನ್ನು ಮುಗಿಸಬೇಕಾಗಿದೆ.",
              "te": "శిఖర్, నేను నీతో మరియు నీ ఇంటర్నెట్ జ్ఞానంతో విసిగిపోయాను. నువ్వు ఇంటర్నెట్‌ లో చదివినవి లేదా చూసేవన్నీ ప్రతీసారీ సరైనవి కావు అని నా స్నేహితులు చెప్పేవారు. చాలా తప్పుడు సమాచారం కూడా ఉంటుంది. త్వరగా చేయి, చీకటి పడుతోంది, ఈ రోజు మనం విత్తనాలు నాటడం పూర్తి చేయాలి.",
              "or": "ଶିଖର, ମୁ ତୋର ଇଣ୍ଟେରନେଟ ଜ୍ଞାନ ଏବଂ ତୋ ପାଇଁ ବିରକ୍ତ ହୋଇଗଲିଣି|\n ମୋ ସାଙ୍ଗ ମାନେ କୁହନ୍ତି ଯାହା ସବୁ ଇଣ୍ଟେରନେଟ ରେ ଆମେ ଦେଖୁ କିମ୍ବା \n ପଢୁ ତାହା ସବୁବେଳେ ସଠିକ ନୁହେଁ| ଏଠି ବହୁତ ସାରା ଭୁଲ ତଥ୍ୟ ମଧ୍ୟ \n ଅଛି| ଶୀଘ୍ର କର, ଅନ୍ଧାର ହେଲାଣି ଆଉ ଆମକୁ ବୀଜ ରୋପଣ କାମ ଆଜି ହି ସାରିବାକୁ ପଡିବ|",
              "as": "শিখৰ, মই তোমাৰ আৰু তোমাৰ ইণ্টাৰনেট জ্ঞানৰ পৰা ভাগৰি পৰিছোঁ। মোৰ বন্ধুসকলে কৈ আছিল যে তুমি ইণ্টাৰনেটত পঢ়া বা দেখা সকলোবোৰ সদায় শুদ্ধ নহয়। তাত বহুতো ভুল তথ্যও আছে। সোনকালে, আন্ধাৰ হৈ আহিছে, আৰু আমি আজি সিঁচি শেষ কৰিব লাগিব।",
              "gu": "શિખર, હું તારા અને તારા ઇન્ટરનેટ જ્ઞાનથી કંટાળી ગયો છું. મારા મિત્રો કહેતા હતા કે તું ઈન્ટરનેટ પર જે વાંચે છે કે જુઓ છે તે બધું સાચું હોતું નથી. ઘણી બધી ખોટી માહિતી પણ છે. ઉતાવળ કર, અંધારું થઈ રહ્યું છે, અને આપણે આજે વાવણી પૂરી કરવાની જરૂર છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2i_2_1_DiaryComponent",
              "hi": "X2i_2_1_डायरीकॉम्पोनेंट ",
              "ka": "X2i_2_1_DiaryComponent",
              "te": "X2i_2_1_డైరీ భాగం",
              "or": "X2i_2_1_DiaryComponent",
              "as": "X2i_2_1_DiaryComponent",
              "gu": "X2i_2_1_ડાઈરી અંશ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar is disappointed with his brother’s response. He goes back home and reflects in his diary.",
              "hi": "शिखर अपने भाई की प्रतिक्रिया से निराश है। वह घर वापस जाकर इसे अपनी डायरी में लिखता है।",
              "ka": "ಶಿಖರ್ ತನ್ನ ಸಹೋದರನ ಪ್ರತಿಕ್ರಿಯೆಯಿಂದ ನಿರಾಶೆಗೊಂಡಿದ್ದಾನೆ. ಅವನು ಮನೆಗೆ ಹಿಂತಿರುಗಿ ತನ್ನ ದಿನಚರಿಯಲ್ಲಿ ಅದನ್ನು ಬರೆಯುತ್ತಾನೆ",
              "te": "తన సోదరుడి ప్రతిస్పందనతో శిఖర్ నిరాశ చెందాడు. అతను ఇంటికి తిరిగి వెళ్లి తన డైరీలో ప్రతిబింబిస్తాడు.",
              "or": "ଶିଖର ନିଜ ଭାଇର ପ୍ରତିକ୍ରିୟାରେ ଆହତ ହେଲା| ନିଜ ଘରକୁ ଫେରି ସେ ନିଜ ଡାଏରୀ ରେ ମ୍ଯାଗନା ହେଲା|",
              "as": "শিখৰে তাৰ ককায়েকৰ সঁহাৰিত হতাশ হৈছে। সি ঘৰলৈ উভতি যায় আৰু তাৰ ডায়েৰী লিখে।",
              "gu": "શિખર તેના ભાઈના જવાબથી નિરાશ છે. તે ઘરે પાછો જાય છે અને તેની ડાયરીમાં બધુ લખે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page 1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "ପ୍ରୁଷ୍ଠା୧",
              "as": "পৃষ্ঠা 1",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page 2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ 2",
              "or": "ପ୍ରୁଷ୍ଠା୨",
              "as": "পৃষ্ঠা 2",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "I feel happy I decided to help my father and brother. I have learnt so much from them. I think there is so much more that I can do to help them. I’m sure I can know more on youtube, but I need to find suitable sources as there is a lot of wrong information and I must be careful.",
              "hi": "मुझे खुशी है कि मैंने अपने पिता और भाई की मदद करने का फैसला किया। मैंने उनसे बहुत कुछ सीखा है। मुझे लगता है कि अभी और भी बहुत कुछ है जिसमें मैं उनकी सहायता कर सकता हूं। मुझे विश्वास है कि मैं यूट्यूब पर और जानकारी ले सकता हूं, लेकिन मुझे उपयुक्त सूत्रों की आवश्यकता है क्योंकि बहुत सारी गलत जानकारी है और मुझे सावधान रहना चाहिए।",
              "ka": "ನನ್ನ ತಂದೆ ಮತ್ತು ಸಹೋದರನಿಗೆ ಸಹಾಯ ಮಾಡಲು ನಾನು ನಿರ್ಧರಿಸಿದ್ದೇನೆ ಎಂದು ನನಗೆ ಸಂತೋಷವಾಗಿದೆ. ಅವರಿಂದ ನಾನು ತುಂಬಾ ಕಲಿತಿದ್ದೇನೆ. ಅವರಿಗೆ ಸಹಾಯ ಮಾಡಲು ನಾನು ಇನ್ನೂ ಹೆಚ್ಚಿನದನ್ನು ಮಾಡಬಹುದು ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ನಾನು ಯೂಟ್ಯೂಬ್‌ನಲ್ಲಿ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಬಹುದು ಎಂದು ನನಗೆ ಖಾತ್ರಿಯಿದೆ, ಆದರೆ ಸಾಕಷ್ಟು ತಪ್ಪು ಮಾಹಿತಿ ಇರುವುದರಿಂದ ನಾನು ಸೂಕ್ತ ಮೂಲಗಳನ್ನು ಹುಡುಕಬೇಕಾಗಿದೆ ಮತ್ತು ನಾನು ಜಾಗರೂಕನಾಗಿರಬೇಕು.",
              "te": "నేను నా తండ్రి మరియు సోదరుడికి సహాయం చేయాలని నిర్ణయించుకున్నందుకు సంతోషంగా ఉన్నాను. వారి నుంచి నేను చాలా నేర్చుకున్నాను. వారికి సహాయం చేయడానికి నేను చేయగలిగేవి ఇంకా చాలా ఉన్నాయని నేను భావిస్తున్నాను. నేను యూట్యూబ్‌ లో మరింత తెలుసుకోగలనని ఖచ్చితంగా అనుకుంటున్నాను, కానీ చాలా తప్పుడు సమాచారం ఉన్నందున నేను సరైన వనరులను కనుగొనాలి మరియు నేను జాగ్రత్తగా ఉండాలి.",
              "or": "ନିଜ ଭାଇ ଓ ବାପାଙ୍କୁ ସାହାଯ୍ୟ କରିବାର ନିଜର ନିଷ୍ପତ୍ତି ପାଇଁ ମୁ ଖୁସି ଅନୁଭବ କରୁଛି| ମୁଁ ସେମାନଙ୍କ ଠାରୁ ବହୁତ କିଛି ଶିଖିଛି | ମୁଁ ଭାବୁଛି ଆହୁରି ବହୁତ କିଛି ଅଛି ଯେଉଁଥିରେ ସେମାନଙ୍କୁ ମୁ ସାହାଯ୍ୟ କରିପାରିବି| ମୁଁ ନିଶ୍ଚିତ ଯେ ମୁଁ ୟୁଟୁବ ରୁ ଆହୁରି ବେଶୀ ଜାଣିପାରିବି, କିନ୍ତୁ ମତେ ଉପଯୁକ୍ତ ସ୍ରୋତ ବାଛିବାକୁ ପଡିବ କାରଣ ଏଠାରେ ବହୁତ ଭୁଲ ତଥ୍ୟ ଦିଆଯାଇଛି ଏବଂ ମତେ ସତର୍କ ରହିବାକୁ ପଡିବ|",
              "as": "মই সুখী অনুভৱ কৰোঁ মই মোৰ দেউতা আৰু দাদাক সহায় কৰাৰ সিদ্ধান্ত লৈছিলোঁ। মই তেওঁলোকৰ পৰা বহুত শিকিছোঁ। মই ভাবো তেওঁলোকক সহায় কৰিবলৈ মই আৰু বহুতো কৰিব পাৰোঁ। মই নিশ্চিত যে মই ইউটিউবত অধিক জানিব পাৰোঁ , কিন্তু মই উপযুক্ত উৎস বিচাৰিব লাগিব কিয়নো বহুতো ভুল তথ্য আছে আৰু মই সাৱধান হ'ব লাগিব।",
              "gu": "મને ખુશી છે કે મેં મારા પિતા અને ભાઈને મદદ કરવાનું નક્કી કર્યું. હું તેમની પાસેથી ઘણું શીખ્યો છું. મને લાગે છે કે હું તેમને મદદ કરવા માટે ઘણું બધું કરી શકું છું. મને ખાતરી છે કે હું યુટ્યુબ પર વધુ જાણી શકું છું, પરંતુ મારે યોગ્ય સ્ત્રોતો શોધવાની જરૂર છે કારણ કે ત્યાં ઘણી બધી ખોટી માહિતી છે અને મારે સાવચેત રહેવું જોઈએ."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "I have many ideas on what we should grow and sell in the mandi at a reasonable rate. But they think I am too young, so they don’t take me seriously. When I get older, they will take me seriously.",
              "hi": "मेरे पास अनेक विचार हैं कि हमें क्या उगाना चाहिए और मंडी में एक उचित कीमत पर बेचना चाहिए। लेकिन उन्हें लगता है कि मैं बहुत छोटा हूं, इसलिए वे मुझे गंभीरता से नहीं लेते हैं। जब मैं बड़ा हो जाऊंगा, तो वे मुझे गंभीरता से लेंगे।",
              "ka": "ನಾವು  ಏನನ್ನು ಬೆಳೆದು ಮಂಡಿಯಲ್ಲಿ ಸಮಂಜಸವಾದ ದರದಲ್ಲಿ ಮಾರಾಟ ಮಾಡಬೇಕೆಂಬುದರ ಬಗ್ಗೆ ನನಗೆ ಹಲವು ವಿಚಾರಗಳಿವೆ. ಆದರೆ ನಾನು ತುಂಬಾ ಚಿಕ್ಕವನು ಎಂದು ಅವರು ಭಾವಿಸುತ್ತಾರೆ, ಆದ್ದರಿಂದ ಅವರು ನನ್ನನ್ನು ಗಂಭೀರವಾಗಿ ಪರಿಗಣಿಸುವುದಿಲ್ಲ. ನಾನು ವಯಸ್ಕನಾದಾಗ, ಅವರು ನನ್ನನ್ನು ಗಂಭೀರವಾಗಿ ಪರಿಗಣಿಸುತ್ತಾರೆ.",
              "te": "మనం ఏమి పెంచాలి మరియు మండి (మార్కెట్)లో మంచి ధరకు దేనికి వస్తుంది అనే దానిపై నాకు చాలా ఆలోచనలు ఉన్నాయి. కానీ నేను చాలా చిన్నవాడినని వారు అనుకుంటున్నారు, కాబట్టి వారు నన్ను సీరియస్‌ గా తీసుకోరు. నేను పెద్దయ్యాక నన్ను సీరియస్‌ గా తీసుకుంటారు.",
              "or": "ଏକ ଉଚିତ ମୂଲ୍ୟ ରେ ଯେଉଁ ସାମଗ୍ରୀ କୁ ମଣ୍ଡୀ ରେ ବିକ୍ରି କରାଯାଇପାରିବ ,\n ସେହି ସବୁ ସାମଗ୍ରୀର ଚାଷ ବାବଦରେ ମୋର ବହୁତ ସାରା ଧାରଣା ଅଛି| କିନ୍ତୁ ଅନ୍ୟମାନେ ଭାବୁଛନ୍ତି ଏସବୁ ପାଇଁ ମୁଁ ବହୁତ ଛୋଟ| ତେଣୁ ସେମାନେ ମତେ ଗମ୍ଭୀରତା ର ସହ ନେଉନାହାନ୍ତି| ଯେତେବେଳେ ମୁଁ ବଡ ହୋଇଯିବୀ, ସେମାନେ ମତେ ଗମ୍ଭୀରଟା ର ସହ ନେବେ|",
              "as": "আমি মাণ্ডিত যুক্তিসঙ্গত হাৰত কি বৃদ্ধি আৰু বিক্ৰী কৰিব লাগে তাৰ ওপৰত মোৰ বহুতো আইডিয়া আছে। কিন্তু তেওঁলোকে ভাবে যে মই বহুত সৰু, সেয়েহে তেওঁলোকে মোক গুৰুত্ব সহকাৰে নলয়। যেতিয়া মই ডাঙৰ হ'ম, তেওঁলোকে মোক গুৰুত্বসহকাৰে ল'ব।",
              "gu": "શું ઉગાડવું અને વાજબી ભાવે શું વેચવું જોઈએ આપણી મંડીમાં તેના પર મારી પાસે ઘણા વિચારો છે. પરંતુ તેઓ વિચારે છે કે હું ખૂબ નાનો છું, તેથી તેઓ મને ગંભીરતાથી સમાજતા નથી. જ્યારે હું મોટો થઈશ, ત્યારે તેઓ મને ગંભીરતાથી સમજી શકશે "
            }
          }
        ]
      },
      "scene4": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Volunteer at the panchayat",
              "hi": "पंचायत में स्वयंसेवी ",
              "ka": "ಪಂಚಾಯತ್ ನಲ್ಲಿ ಸ್ವಯಂಸೇವಕ",
              "te": "పంచాయతీ వద్ద వాలంటీర్",
              "or": "ପଞ୍ଚାଯତ ରେ ସ୍ଵୟଂସେବକ",
              "as": "পঞ্চায়তত স্বেচ্ছাসেৱক",
              "gu": "પંચાયતમાં સ્વયંસેવક"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar's job as a volunteer is to take photos of the seeds in the seed bank and upload them in the app, along with the details of the sources.",
              "hi": "एक स्वयंसेवी के रूप में शिखर का काम बीज बैंक में बीजों की तस्वीरें लेना और सूत्रों के विवरण के साथ उन्हें ऐप में अपलोड करना है।",
              "ka": "ಸ್ವಯಂಸೇವಕನಾಗಿ ಶಿಖರ್ ಮಾಡಬೇಕಾದ ಕೆಲಸವೆಂದರೆ ಬೀಜ ಬ್ಯಾಂಕ್‌ನಲ್ಲಿರುವ ಬೀಜಗಳ ಫೋಟೋಗಳನ್ನು ತೆಗೆದುಕೊಂಡು ಅವುಗಳನ್ನು ಮೂಲಗಳ ವಿವರಗಳೊಂದಿಗೆ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ಅಪ್‌ಲೋಡ್ ಮಾಡುವುದು.",
              "te": "విత్తన బ్యాంక్ లో ఉన్న విత్తనాలను ఫోటోలు తీసి వాటిని వివరాలతో సహా యాప్‌ లో అప్‌లోడ్ చేయడం వాలంటీర్‌గా శిఖర్ చేసే పని.",
              "or": "ସ୍ଵୟଂସେବକ ଭାବେ ଶିଖରର କାମ ଥିଲା ବୀଜ ବ୍ୟାଙ୍କରେ ଥିବା ବୀଜ ଗୁଡିକର ଫୋଟୋ \n ଉଠାଇ ତାର ସଠିକ ବିବରଣୀ ସହ ତାକୁ ଆପ ରେ ଅପଲୋଡ କରିବା|",
              "as": "এজন স্বেচ্ছাসেৱক হিচাপে শিখৰৰ কাম হৈছে বীজ বেঙ্কত বীজবোৰৰ ফটো তোলা আৰু সেইবোৰ উৎসবোৰৰ বিৱৰণৰ সৈতে এপটোত আপলোড কৰা।",
              "gu": "સ્વયંસેવક તરીકે શિખરનું કામ બીજના ફોટા લેવાનું છે બીજ બેન્કમાંથી અને તેને એપમાં અપલોડ કરવાનું છે, તેની સાથે સ્ત્રોતોની વિગતો પણ લખવાની છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2ii_1_2",
              "hi": "X2II_1_2",
              "ka": "X2ii_1_2",
              "te": "X2ii_1_2",
              "or": "X2ii_1_2",
              "as": "X2ii_1_2",
              "gu": "X2ii_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He meets Abhilash, a researcher at the agriculture college nearby, who trains them on using the app. He is enjoying the work and finishes the job in one week.",
              "hi": "वह पास के कृषि कॉलेज के एक शोधकर्ता अभिलाष से मिलता है, जो उन्हें ऐप का उपयोग करने के लिए प्रशिक्षित करते हैं। वह काम में आनंद ले रहा है और एक सप्ताह में उसे खत्म करता है।",
              "ka": "ಅವನು ಹತ್ತಿರದ ಕೃಷಿ ಕಾಲೇಜಿನ ಸಂಶೋಧಕ ಅಭಿಲಾಷ್ ಅವರನ್ನು ಭೇಟಿಯಾಗುತ್ತಾನೆ, ಅವರು ಅಪ್ಲಿಕೇಶನ್ ಬಳಸುವ ಬಗ್ಗೆ ಆತನಿಗೆ ತರಬೇತಿ ನೀಡುತ್ತಾರೆ. ಅವನು ಕೆಲಸವನ್ನು ಆನಂದಿಸುತ್ತಿದ್ದಾನೆ ಮತ್ತು ಒಂದೇ ವಾರದಲ್ಲಿ ಕೆಲಸವನ್ನು ಮುಗಿಸುತ್ತಾನೆ.",
              "te": "అతను దగ్గరలోని వ్యవసాయ కళాశాలలో పరిశోధకుడైన అభిలాష్‌ ని కలుసుకున్నాడు, అతను యాప్‌ ని ఉపయోగించడం పై వారికి శిక్షణ ఇస్తాడు. అతనికి పని నచ్చింది మరియు ఒక వారంలో పనిని పూర్తి చేస్తాడు.",
              "or": "ସେ ଅଭିଳାଷ କୁ ସାକ୍ଷାତ କରେ, ଯିଏ କି ନିକଟସ୍ଥ କୃଷି ମହାବିଦ୍ୟାଳୟ ରେ \n ଅନୁସନ୍ଧାନକର୍ତ୍ତା ଭାବେ କମ କରେ, ଯିଏ କି ସେମାନଙ୍କୁ ଆପ ର ବ୍ୟବହାର \n ବିଷୟରେ ଶିକ୍ଷା ଦିଏ| ସେ ନିଜ କାମର ଆନନ୍ଦ ନିଏ ଓ କାମକୁ ଗୋଟିଏ ସପ୍ତାହ\n  ମଧ୍ୟରେ ଶେଷ କରିଦିଏ|",
              "as": "সি ওচৰৰ কৃষি কলেজৰ গৱেষক অভিলাষক লগ পায়, যিয়ে তেওঁলোকক এপটো ব্যৱহাৰ কৰি প্ৰশিক্ষণ দিয়ে। সি কামটোত আনন্দ পাইছে আৰু এসপ্তাহৰ ভিতৰত কামটো শেষ কৰিছে।",
              "gu": "તે નજીકની કૃષિ કોલેજના સંશોધક અભિલાષને મળે છે, જે તેમને એપનો ઉપયોગ કરવાની તાલીમ આપે છે. તે કામનો આનંદ માણી રહ્યો છે અને એક અઠવાડિયામાં કામ પૂરું કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2ii_1_3//",
              "hi": "X2II_1_3 //",
              "ka": "X2ii_1_3//",
              "te": "X2ii_1_3//",
              "or": "X2ii_1_3//",
              "as": "X2ii_1_3//",
              "gu": "X2ii_1_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Abhilash",
              "hi": "अभिलाष ",
              "ka": "ಅಭಿಲಾಷ್",
              "te": "అభిలాష్",
              "or": "ଅଭିଳାଷ",
              "as": "অভিলাষ",
              "gu": "અભિલાષ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Bhaiya, how will this help you?",
              "hi": "भैया, यह आपकी मदद कैसे करेगा?",
              "ka": "ಭೈಯ್ಯ, ಇದು ನಿಮಗೆ ಯಾವ ರೀತಿಯಲ್ಲಿ ಸಹಾಯ ಮಾಡುತ್ತದೆ",
              "te": "అన్నయ్య, ఇది మీకు ఎలా సహాయం చేస్తుంది?",
              "or": "ଭାଇ, ଏହା ଆପଣଙ୍କୁ କିପରି ସାହାଯ୍ୟ କରିବା?",
              "as": "দাদা, ই তোমাক কেনেদৰে সহায় কৰিব?",
              "gu": "ભાઈ, આ તને કેવી રીતે મદદ કરશે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "The seed bank will help us know and preserve the variety of crops in this village.",
              "hi": "बीज बैंक हमें इस गांव में फसलों की विविधता को जानने और संरक्षित करने में हमारी मदद करेगा।",
              "ka": "ಈ ಗ್ರಾಮದ ವಿವಿಧ ಬೆಳೆಗಳನ್ನು ತಿಳಿಯಲು ಮತ್ತು ಸಂರಕ್ಷಿಸಲು ಬೀಜ ಬ್ಯಾಂಕ್ ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ",
              "te": "విత్తన బ్యాంకు ఈ గ్రామంలోని వివిధ రకాల పంటలను తెలుసుకుని వాటిని సంరక్షిస్తుంది.",
              "or": "ବୀଜ ବ୍ୟାଙ୍କ ଆମକୁ ଗାଁରେ କିପରି ବିଭିର୍ଣ୍ଣ ପ୍ରକାର ଶସ୍ୟ କୁ ସୁରକ୍ଷିତ ରଖାଯାଇପାରିବା \n ସେ ବିଷୟରେ ଜାଣିବାରେ ସାହାଯ୍ୟ କରିବା|",
              "as": "বীজ বেংকে আমাক এই গাওঁখনৰ বিভিন্ন শস্য জনা আৰু সংৰক্ষণ কৰাত সহায় কৰিব।",
              "gu": "બીજ બેંક અમને આ ગામમાં પાકની વિવિધતા જાણવા અને સાચવવામાં મદદ કરશે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "So you collect and store them here?",
              "hi": "तो आप उन्हें यहाँ इकट्ठा करते हैं और उनका भंडारण करते हैं? ",
              "ka": "ಆದ್ದರಿಂದ ನೀವು ಅವುಗಳನ್ನು ಇಲ್ಲಿ ಸಂಗ್ರಹಿಸಿ ಶೇಖರಿಸುತ್ತೀರಾ?",
              "te": "అంటే నువ్వు వాటిని సేకరించి ఇక్కడ నిల్వ చేస్తావా?",
              "or": "ତାହେଲେ ତୁମେ ସେସବୁ କୁ ଏହିଠାରୁ ସଂଗ୍ରହ କରି ଏହିଠାରେ ସଂରକ୍ଷିତ ରଖିବ?",
              "as": "গতিকে তুমি সেইবোৰ ইয়াত সংগ্ৰহ আৰু সংৰক্ষণ কৰা?",
              "gu": "તો તમે તેમને અહીં એકત્રિત કરીને સંગ્રહિત કરશો?"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2ii_1_4////",
              "hi": "X2II_1_4 ////",
              "ka": "X2ii_1_4////",
              "te": "X2ii_1_4////",
              "or": "X2ii_1_4////",
              "as": "X2ii_1_4////",
              "gu": "X2ii_1_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Yes, of course. We keep bringing in different local varieties that are easy to grow. If the seeds get spoiled due to some disease or drought, we can still use the seeds that are in the bank.",
              "hi": "हां बिल्कुल। हम विभिन्न स्थानीय किस्मों के बीज लाते हैं जो उगाने में सरल हैं। यदि कुछ बीमारी या सूखे के कारण बीज खराब हो जाते हैं, तो हम अभी भी उन बीजों का उपयोग कर सकते हैं जो बैंक में हैं।",
              "ka": "ಹೌದು ಖಚಿತವಾಗಿ. ನಾವು ಬೆಳೆಯಲು ಸುಲಭವಾದ ವಿವಿಧ ಸ್ಥಳೀಯ ತಳಿಗಳನ್ನು ತರುತ್ತಲೇ ಇರುತ್ತೇವೆ. ಯಾವುದೇ ರೋಗ ಅಥವಾ ಬರಗಾಲದಿಂದ ಬೀಜಗಳು ಕೆಟ್ಟರೆ, ನಾವು ಬ್ಯಾಂಕ್‌ನಲ್ಲಿರುವ ಬೀಜಗಳನ್ನು ಇನ್ನೂ ಬಳಸಬಹುದು.",
              "te": "అవును. సులువుగా పెరిగే వివిధ రకాల స్థానిక రకాలను తెస్తూనే ఉన్నాం. ఏదైనా రోగాల వల్ల లేదా ఎండవేడి వల్ల విత్తనాలు చెడిపోతే, మనం బ్యాంకులో ఉన్న విత్తనాలను ఉపయోగించవచ్చు.",
              "or": "ହଁ, ନିଶ୍ଚୟ| ଆମେ ବିଭିର୍ଣ୍ଣ ପ୍ରକାର ସ୍ଥାନୀୟ ମଞ୍ଜି ଯାହା କି ସହଜରେ ଚାଷ କରାଯାଇପାରିବ\n ତାକୁ ଆଣି ସଂରକ୍ଷିତ କରୁ| ଯଦି ମଞ୍ଜି ଗୁଡିକ ଅନାବୃଷ୍ଟି କିମ୍ବା କୌଣସି ରୋଗ କାରଣରୁ \n ଖରାପ ହୋଇଯାଏ, ତେବେ ବ୍ୟାଙ୍କ ରେ ଗଚ୍ଛିତ ଥିବା ମଞ୍ଜିକୁ ଆମେ ବ୍ୟବହାର କରିପାରିବୁ|",
              "as": "হয় নিশ্চয়। আমি বিভিন্ন স্থানীয় সঁচ আনিছো যিবোৰ খেতি কৰিবলৈ সহজ। যদি কোনো ৰোগ বা খৰাঙৰ ফলত বীজবোৰ নষ্ট হয়, তেন্তে আমি এতিয়াও বেংকত থকা বীজ ব্যৱহাৰ কৰিব পাৰোঁ।",
              "gu": "હા ચોક્ક્સ.  અમે વિવિધ સ્થાનિક જાતો લાવતા રહીએ છીએ જે ઉગાડવામાં સરળ છે.  જો કોઈ રોગ અથવા દુષ્કાળને કારણે બીજ બગડી જાય, તો પણ આપણે બેંકમાં રહેલા બીજનો ઉપયોગ કરી શકીએ છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "That great! Also, based on what vegetable is more in demand, farmers can always be ready!",
              "hi": "बहुत अच्छा! इसके अलावा, कौन सी सब्जी मांग में अधिक है इसके आधार पर किसान हमेशा तैयार हो सकते हैं!",
              "ka": "ಅದು ಒಳ್ಳೆಯದು! ಅಲ್ಲದೆ, ಯಾವ ತರಕಾರಿಗೆ ಹೆಚ್ಚು ಬೇಡಿಕೆಯಿದೆ ಎನ್ನುವುದರ ಆಧಾರದ ಮೇಲೆ, ರೈತರು ಯಾವಾಗಲೂ ಸಿದ್ಧರಾಗಿರಬೇಕು!",
              "te": "అది మంచిది! అలాగే, ఏ కూరగాయకు ఎక్కువ గిరాకీ ఉందో దాని ఆధారంగా రైతులు ఎల్లప్పుడూ సిద్ధంగా ఉండగలరు!",
              "or": "ତାହେଲେ ତ ବଢିଆ! ପରିବାର ଚାହିଦା ଅନୁଯାୟୀ ଚାଷୀ ମାନେ ସବୁବେଳେ \n ତତ୍ପର ରହିବେ!",
              "as": "ইমান ডাঙৰ! লগতে, কি পাচলিৰ চাহিদা অধিক তাৰ ওপৰত আধাৰিত কৰি, কৃষকসকল সদায়ে সাজু থাকিব পাৰে!",
              "gu": "તે મહાન! ઉપરાંત, કયા શાકભાજીની વધુ માંગ છે તેના આધારે, ખેડૂતો હંમેશા તૈયાર રહી શકે છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "That's correct! And the most important thing is this will help in having a range of crops.",
              "hi": "यह सही है! और सबसे महत्वपूर्ण बात यह है कि यह फसलों की विविधता रखने में मदद करेगा।",
              "ka": "ಅದು ಸರಿ! ಮತ್ತು ಅತ್ಯಂತ ಮುಖ್ಯವಾದ ವಿಷಯವೆಂದರೆ ಇದು ವಿವಿಧ ಬೆಳೆಗಳನ್ನು ಹೊಂದಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "సరిగ్గా చెప్పావు! మరియు చాలా ముఖ్యమైన విషయం ఏమిటంటే ఇది చాలా పంటలు కలిగి ఉండటానికి సహాయపడుతుంది.",
              "or": "ହଁ ଠିକ! ଏବଂ ସବୁଠୁ ଜରୁରୀ ହେଲା ଏହା ଆମକୁ ବିଭିର୍ଣ୍ଣ ପ୍ରକାରର ଶସ୍ୟ କୁ \n ରଖିବାରେ ସାହାଯ୍ୟ କରେ|",
              "as": "সেইটো শুদ্ধ! আৰু আটাইতকৈ গুৰুত্বপূৰ্ণ কথাটো হ'ল এইটোৱে বিভিন্ন শস্য ৰখাত সহায় কৰিব।",
              "gu": "તે સાચુ છે! અને સૌથી મહત્વની બાબત એ છે કે આ પાકની શ્રેણીમાં મદદ કરશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "I didn’t know this work would be so helpful for us farmers. How do you know so much, Bhaiya?",
              "hi": "मुझे नहीं पता था कि यह काम हम किसानों के लिए इतना उपयोगी होगा। आप इतना कैसे जानते हैं, भैया?",
              "ka": "ಈ ಕೆಲಸವು ನಮ್ಮ ರೈತರಿಗೆ ತುಂಬಾ ಸಹಾಯಕವಾಗುತ್ತದೆ ಎಂದು ನನಗೆ ತಿಳಿದಿರಲಿಲ್ಲ. ನಿನಗೆ ಇಷ್ಟು ಹೇಗೆ ಗೊತ್ತು ಭೈಯ್ಯಾ?",
              "te": "ఈ పని రైతులకు ఎంతగానో ఉపయోగపడుతుందని నాకు తెలియదు. ఇంతకీ ఇవన్నీ నీకెలా తెలుసు అన్నయ్య?",
              "or": "ଆମ ଭଳି ଚାଷିମାନଙ୍କ ପାଇଁ ଏହା ଏତେ ଲାଭଦାୟକ ବୋଲି ମୁଁ ଜାଣି ନଥିଲି\n ଭାଇ| ତୁମେ ଏତେ ସବୁ କେମିତି ଜାଣିଲ ଭାଇ?",
              "as": "মই নাজানিছিলো যে এই কামটো আমাৰ কৃষকসকলৰ বাবে ইমান সহায়ক হ'ব। দাদা, তুমি ইমানখিনি কেনেকৈ জানা?",
              "gu": "મને ખબર નહોતી કે આ કામ અમારા ખેડૂતો માટે આટલું ઉપયોગી થશે. તને આટલું બધું કેવી રીતે ખબર પડે છે, ભાઈ?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "This is what we learn in agricultural college.",
              "hi": "यह हम कृषि कॉलेज में सीखते हैं।",
              "ka": "ಇದನ್ನೇ ನಾವು ಕೃಷಿ ಕಾಲೇಜಿನಲ್ಲಿ ಕಲಿಯುತ್ತೇವೆ.",
              "te": "వ్యవసాయ కళాశాలలో నేర్చుకునేది ఇదే.",
              "or": "ଏହିସବୁ ବିଷୟରେ ଆମେ ମହାବିଦ୍ୟାଳୟରେ ପଢୁ ଏବଂ ସିଖୁ|",
              "as": "কৃষি কলেজত আমি এইটোৱেই শিকিছোঁ।",
              "gu": "આ અમે કૃષિ કોલેજમાં શીખીએ છીએ."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2ii_1_5_DiaryComponent",
              "hi": "X2ii_1_5_डायरीकॉम्पोनेंट",
              "ka": "X2ii_1_5_DiaryComponen",
              "te": "X2ii_1_5_డైరీ భాగం",
              "or": "X2ii_1_5_DiaryComponent",
              "as": "X2ii_1_5_DiaryComponent",
              "gu": "X2ii_1_5_ડાયરીનો અંશ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar goes home and writes his learnings in his diary.",
              "hi": "शिखर घर जाता है और अपनी डायरी में इस सीख को लिखता है।",
              "ka": "ಶಿಖರ್ ಮನೆಗೆ ಹೋಗಿ ತನ್ನ ಕಲಿಕೆಯನ್ನು ತನ್ನ ಡೈರಿಯಲ್ಲಿ ಬರೆಯುತ್ತಾನೆ.",
              "te": "శిఖర్ ఇంటికి వెళ్లి తను నేర్చుకున్న విషయాలను తన డైరీలో రాసుకున్నాడు.",
              "or": "ଶିଖର ଶିଖିଥିବା ନୂଆ ତଥ୍ୟକୁ ନିଜ ଡାଏରୀରେ ଲେଖିଲ|",
              "as": "শিখৰ ঘৰলৈ যায় আৰু সি শিকাবোৰ তাৰ ডায়েৰীত লিখিছে।",
              "gu": "શિખર ઘરે જાય છે અને તેની ડાયરીમાં તેની શીખવેલી રીત લખે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page 1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "ପୃଷ୍ଠା ୧",
              "as": "পৃষ্ঠা 1",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page 2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ 2",
              "or": "ପୃଷ୍ଠା ୨",
              "as": "পৃষ্ঠা 2",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "There is so much to learn from everyone. We only hear about engineering at school and home. Abhilash Bhaiya’s work is so interesting.",
              "hi": "हर किसी से सीखने के लिए बहुत कुछ है। हम केवल स्कूल और घर में इंजीनियरिंग के बारे में सुनते हैं। अभिलाष भैया का काम बहुत रुचिपूर्ण है।",
              "ka": "ಪ್ರತಿಯೊಬ್ಬರಿಂದ ಕಲಿಯಲು ತುಂಬಾ ಇದೆ. ಇಂಜಿನಿಯರಿಂಗ್ ಬಗ್ಗೆ ನಾವು ಶಾಲೆಯಲ್ಲಿ ಮತ್ತು ಮನೆಯಲ್ಲಿ ಮಾತ್ರ ಕೇಳುತ್ತೇವೆ. ಅಭಿಲಾಷ್ ಭಯ್ಯಾ ಅವರ ಕೆಲಸ ತುಂಬಾ ಆಸಕ್ತಿದಾಯಕವಾಗಿದೆ.",
              "te": "ప్రతి ఒక్కరి నుంచి నేర్చుకోవాల్సింది చాలా ఉంది. స్కూల్లో, ఇంట్లో ఇంజినీరింగ్ గురించి మాత్రమే వింటుంటాం. అభిలాష్ అన్నయ్య పని చాలా ఆసక్తికరంగా ఉంది.",
              "or": "ଏଠାରେ ପ୍ରତ୍ୟେକ ଙ୍କ ଠାରୁ ଶିଖିବା ପାଇଁ ବହୁତ କିଛି ଅଛି| ଆମେ ଘରେ ଏବଂ ବିଦ୍ୟାଳୟ ରେ\n କେବଳ ଇଞ୍ଜିନିୟରିଂ ବିଷୟରେ ହି ଶୁଣୁ | ଅଭିଳାଷ ଭାଇଙ୍କର କାମ ବହୁତ ମଜାଦାର |",
              "as": "সকলোৰে পৰা শিকিবলৈ বহুত আছে। আমি কেৱল বিদ্যালয় আৰু ঘৰত ইঞ্জিনিয়াৰিঙৰ বিষয়ে শুনিছোঁ। অভিলাষ দাদাৰ কামটো ইমান আকৰ্ষণীয়।",
              "gu": "દરેક પાસેથી શીખવા જેવું ઘણું છે. અમે ફક્ત શાળા અને ઘરે જ એન્જિનિયરિંગ વિશે સાંભળીએ છીએ. અભિલાષ ભાઈનું કામ ઘણું રસપ્રદ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "I wonder if going to the agricultural college and doing the course will help Arun Bhaiya also. Our farm could benefit so much from this knowledge.",
              "hi": "मेरे विचार से कृषि कॉलेज में जाने और अध्ययन करने से अरुण भैया को भी मदद मिलेगी। इस ज्ञान से हमारे खेत को बहुत लाभ हो सकता है।",
              "ka": "ಕೃಷಿ ಕಾಲೇಜಿಗೆ ಹೋಗಿ ಕೋರ್ಸ್ ಮಾಡುವುದರಿಂದ ಅರುಣ್ ಭಯ್ಯಾ ಅವರಿಗೂ ಸಹಾಯವಾಗುತ್ತದೆಯೇ ಎಂದು ನಾನು ಆಶ್ಚರ್ಯ ಪಡುತ್ತೇನೆ. ಈ ಜ್ಞಾನದಿಂದ ನಮ್ಮ ಜಮೀನು ತುಂಬಾ ಪ್ರಯೋಜನ ಪಡೆಯಬಹುದು.",
              "te": "వ్యవసాయ కళాశాలకు వెళ్లి కోర్స్ చేస్తే అరుణ్ అన్నయ్యకి కూడా సహాయం అవుతుందేమో. ఈ జ్ఞానం నుండి మా పొలం చాలా ప్రయోజనం పొందవచ్చు.",
              "or": "ମୁଁ ଭାବୁଛି ଯଦି ମୁଁ କୃଷି ମହାବିଦ୍ୟାଳୟ କୁ ଜୀବି ଏବଂ ଏହି ପାଠ୍ୟକ୍ରମ କୁ ପଢିବି\n  ତେବେ ତେବେ ଏହା ଅରୁଣ ଭାଇଙ୍କୁ ମଧ୍ୟ ସାହାଜ୍ଯ କରିବା| ଆମ କ୍ଷେତକୁ ବହୁତ\n  ସାରା ଲାଭ ମିଳିବ|",
              "as": "মই ভাবো যে কৃষি কলেজলৈ গৈ পাঠ্যক্ৰমটো কৰিলে অৰুণ দাদাৰো লাভ হ'ব। আমাৰ ফাৰ্মে এই জ্ঞানৰ পৰা যথেষ্ট লাভান্বিত হ'ব পাৰে।",
              "gu": "મને આશ્ચર્ય થાય છે કે શું કૃષિ કોલેજમાં જઈને કોર્સ કરવાથી અરુણ ભાઈને પણ મદદ મળશે. અમારા ખેતરને આ જ્ઞાનથી ઘણો ફાયદો થઈ શકે છે."
            }
          }
        ]
      },
      "scene5": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_1",
              "hi": "X3_1_1",
              "ka": "X3_1_1",
              "te": "X3_1_1",
              "or": "X3_1_1",
              "as": "X3_1_1",
              "gu": "X3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar’s 10th standard is over, and he has scored well in his exams. It is time for Shikhar to select a stream for his 11th and 12th standards.",
              "hi": "शिखर ने दसवीं कक्षा उत्तीर्ण कर ली है, और उसने अपनी परीक्षा में अच्छे अंक प्राप्त किए हैं। यह शिखर के लिए अपने ग्यारहवीं और बारहवीं कक्षा के लिए एक स्ट्रीम/ शाखा चयन करने का समय है। ",
              "ka": "ಶಿಖರ್ ತನ್ನ 10 ನೇ ತರಗತಿ ಮುಗಿಸುತ್ತಾನೆ ಮತ್ತು ಅವನು ತನ್ನ ಪರೀಕ್ಷೆಗಳಲ್ಲಿ ಉತ್ತಮ ಅಂಕಗಳನ್ನು ಗಳಿಸಿದ್ದಾನೆ. ಶಿಖರ್ ತನ್ನ 11 ಮತ್ತು 12 ನೇ ತರಗತಿಗಳಿಗೆ ಸ್ಟ್ರೀಮ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡುವ ಸಮಯ ಬಂದಿದೆ..",
              "te": "శిఖర్ 10వ తరగతి పూర్తయింది మరియు అతను తన పరీక్షలలో మంచి స్కోర్ సాధించాడు. శిఖర్ తన 11వ మరియు 12వ తరగతులకు ఒక స్ట్రీమ్‌ ని ఎంచుకోవడానికి ఇది సమయం.",
              "or": "ଶିଖରର ଦଶମ ପାଠ ଶେଷ ହୋଇଛି ଏବଂ ସେ ପରୀକ୍ଷା ରେ ଭଲ ନମ୍ବର ରଖିଛି|\n ଏବେ ତାର ୧୧ ଓ ୧୨ ଶ୍ରେଣୀ ପାଇଁ କୌଣସି ଏକ ସ୍ରୋତକୁ ନିରୂପଣ \n କରିବାର ଅଛି|",
              "as": "শিখৰৰ দশম শ্ৰেণী শেষ হৈছে, আৰু তেওঁ পৰীক্ষাত ভাল নম্বৰ পাইছে। শিখৰে তেওঁৰ একাদশ আৰু দ্বাদশ শ্ৰেণীৰ বাবে এটা শাখা বাছনি কৰাৰ সময় আহিছে।",
              "gu": "શિખરનું 10મું ધોરણ પૂરું થઈ ગયું છે, અને તેણે તેની પરીક્ષામાં સારો ગુણ મેળવેલા છે. શિખર તેના 11મા અને 12મા ધોરણ માટે પ્રવાહ પસંદ કરવાનો સમય આવી ગયો છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_1b",
              "hi": "X3_1_1b",
              "ka": "X3_1_1b",
              "te": "X3_1_1b",
              "or": "X3_1_1b",
              "as": "X3_1_1b",
              "gu": "X3_1_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He has to choose between Arts, Commerce and Science. Shikhar is confused because he doesn’t like or hate any specific subject.",
              "hi": "उसे कला, वाणिज्य और विज्ञान के बीच चयन करना है। शिखर उलझन में है क्योंकि वह किसी भी विशिष्ट विषय को पसंद या नापसंद नहीं करता है।",
              "ka": "ಅವನು ಕಲೆ, ವಾಣಿಜ್ಯ ಮತ್ತು ವಿಜ್ಞಾನದಲ್ಲಿ ಒಂದನ್ನು ಆಯ್ಕೆ ಮಾಡಬೇಕು. ಶಿಖರ್ ತಾನು ಯಾವುದೇ ನಿರ್ದಿಷ್ಟ ವಿಷಯವನ್ನು ಇಷ್ಟಪಡದ ಅಥವಾ ದ್ವೇಷಿಸದ ಕಾರಣದಿಂದಾಗಿ ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದಾನೆ.",
              "te": "అతను కళలు (ఆర్ట్స్,), వాణిజ్యం (కామర్స్ ) మరియు సైన్స్‌లో ఏదో ఒకదానిని ఎంచుకోవాలి. శిఖర్ ఇష్టం అయిన లేక ఇష్టం లేని సబ్జెక్ట్ ఏదీ లేనందున అయోమయంలో ఉన్నాడు.",
              "or": "ତାକୁ କଳା, ବାଣିଜ୍ୟ, ଏବଂ ବିଜ୍ଞାନରୁ ଗୋଟିଏ କୁ ବାଛିବାକୁ ପଡିବ| ଶିଖର ଦ୍ଵନ୍ଦ ରେ \n ପଡିଯାଏଛି କାରଣ ସେ ଏକ ନିର୍ଦ୍ଧିଷ୍ଟ ବିଷୟ କୁ ଘୃଣା କିମ୍ବା ଭଲ ପାଏ ନହିଁ|",
              "as": "তেওঁ কলা, বাণিজ্য আৰু বিজ্ঞানৰ মাজত বাছনি কৰিব লাগিব। শিখৰ চিন্তিত হৈছে কাৰণ সি কোনো নিৰ্দিষ্ট বিষয় ভাল বা বেয়া নাপায়।",
              "gu": "તેણે આર્ટસ, કોમર્સ અને સાયન્સમાંથી પસંદગી કરવાની હોય છે. શિખર મૂંઝવણમાં છે કારણ કે તેને કોઈ ચોક્કસ વિષય પસંદ નથી કે નકારતો નથી."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_2_reveal",
              "hi": "X3_1_2_रिवील",
              "ka": "X3_1_2_reveal",
              "te": "X3_1_2_రివీల్ (బహిర్గతం)",
              "or": "X3_1_2_reveal",
              "as": "X3_1_2_reveal",
              "gu": "X3_1_2_દેખાડો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He thinks of all the suggestions that people in his life have given him regarding his career.\nClick to see what they’re saying:",
              "hi": "वह उन सभी सुझावों के बारे में सोचता है जो उसके जीवन से जुड़े लोगों ने करियर के बारे में बताए हैं।\nयह देखने के लिए क्लिक करें कि वे क्या कह रहे हैं:",
              "ka": "ಅವನ ಜೀವನದಲ್ಲಿ ಜನರು ತಮ್ಮ ವೃತ್ತಿಜೀವನದ ಕುರಿತು ನೀಡಿದ ಎಲ್ಲಾ ಸಲಹೆಗಳ ಬಗ್ಗೆ ಅವನು ಯೋಚಿಸುತ್ತಾನೆ. ಅವರು ಏನು ಹೇಳುತ್ತಿದ್ದಾರೆಂದು ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "తన జీవితంలోని వ్యక్తులు తన కెరీర్‌ కు సంబంధించి ఇచ్చిన అన్ని సూచనల గురించి అతను ఆలోచిస్తాడు.\n వారు ఏమి చెబుతున్నారో చూడటానికి క్లిక్ చేయండి (నొక్కండి):",
              "or": "ତାର ଜୀବନ କାଳରେ ତାର ଜୀବିକା କୁ ନେଇ ଅନ୍ୟମାନେ ଦେଇଥିବା ପ୍ରସ୍ତାବକୁ \n ସେ ଭାବିବାକୁ ଲାଗିଲା| ସେମାନେ କଣ ସବୁ କହିଥିଲେ ଦେଖିବା ପାଇଁ କ୍ଲିକ କରନ୍ତୁ|",
              "as": "তাক তাৰ চৌপাশৰ মানুহে তাৰ কেৰিয়াৰ সম্পৰ্কে দিয়া সকলো পৰামৰ্শৰ বিষয়ে চিন্তা কৰে।\n \n তেওঁলোকে কি কৈছে চাবলৈ ক্লিক কৰক:",
              "gu": "તે બધાના સૂચનો વિશે વિચારે છે જે તેના જીવનમાં લોકોએ તેને તેની કારકિર્દી અંગે આપ્યા છે. તેઓ શું કહે છે તે જોવા માટે ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Friends",
              "hi": "मित्र ",
              "ka": "ಸ್ನೇಹಿತರು",
              "te": "స్నేహితులు",
              "or": "ସାଙ୍ଗମାନେ|",
              "as": "বন্ধুসকল",
              "gu": "મિત્રો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Babuji",
              "hi": "बाबूजी",
              "ka": "ಬಾಬುಜಿ",
              "te": "నాన్నగారు",
              "or": "ବାବୁଜୀ",
              "as": "দেউতা",
              "gu": "બાબુજી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Arun Bhaiya",
              "hi": "अरुण भैया ",
              "ka": "ಅರುಣ್ ಭೈಯ್ಯ",
              "te": "అరుణ్ అన్నయ్య",
              "or": "ଅରୁଣ ଭାଇ",
              "as": "অৰুণ দাদা",
              "gu": "અરુણ ભાઈ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "Teacher",
              "hi": "अध्यापक",
              "ka": "ಶಿಕ್ಷಕರು",
              "te": "టీచర్",
              "or": "ଶିକ୍ଷକ",
              "as": "শিক্ষক",
              "gu": "શિક્ષક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Friends: You want to study further?! Then you take up commerce. It's less work, and you can spend more time with us.",
              "hi": "मित्र: क्या तुम आगे अध्ययन करना चाहते हो?! फिर तुम कॉमर्स/ वाणिज्य ले लो। कम काम है, और तुम  हमारे साथ अधिक समय व्यतीत कर सकते हो।",
              "ka": "ಸ್ನೇಹಿತರೇ: ನೀವು ಮುಂದೆ ಓದಲು ಬಯಸುವಿರಾ?! ಮುಂದೆ ನೀವು ವಾಣಿಜ್ಯವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು. ಇದು ಕಡಿಮೆ ಕೆಲಸವನ್ನು ಹೊಂದಿರುತ್ತದೆ, ಮತ್ತು ನೀವು ನಮ್ಮೊಂದಿಗೆ ಹೆಚ್ಚು ಸಮಯ ಕಳೆಯಬಹುದು.",
              "te": "స్నేహితులు: నువ్వు ఇంకా చదవాలనుకుంటున్నారా?! అయితే కామర్స్ తీసుకో. ఇందులో తక్కువ పని ఉంటుంది, మరియు నువ్వు మాతో ఎక్కువ సమయం గడపవచ్చు.",
              "or": "ସାଙ୍ଗମାନେ: ତୁମେ ଆଗକୁ ପଢିବାକୁ ଚାହୁଛ? ତାହେଲେ ତୁମେ ବାଣିଜ୍ୟ ନିଅ |\n ଏଥିରେ ବହୁତ କମ କାମ, ଏବଂ ତୁମେ ଆମମାନଙ୍କ ସହ ବେଶୀ ସମୟ ବିତାଇ ପାରିବ|",
              "as": "বন্ধুসকল: তুমি অধিক পঢ়িব বিচাৰা?! তেন্তে তুমি বাণিজ্য শাখা গ্ৰহণ কৰা। এইটো কম কাম, আৰু তুমি আমাৰ সৈতে অধিক সময় থাকিব পাৰা।",
              "gu": "મિત્રો: તમારે આગળ ભણવું છે?! પછી તમે વાણિજ્ય અપનાવો. તે ઓછું કામ છે, અને તમે અમારી સાથે વધુ સમય વિતાવી શકો છો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Babuji: Your brother is working on the farm so you can study. You have got good marks. You should become an engineer like Sujay.",
              "hi": "बाबूजी: तुम्हारा भाई खेत पर काम कर रहा है ताकि तुम अध्ययन कर सको। तुमको अच्छे अंक मिले हैं। तुम्हें सुजय की तरह इंजीनियर बनना चाहिए।",
              "ka": "ಬಾಬೂಜಿ: ನಿನ್ನ ಅಣ್ಣ ಹೊಲದಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾನೆ ನೀನು ಓದು. ನೀನು ಉತ್ತಮ ಅಂಕಗಳನ್ನು ಪಡೆದಿದ್ದೀಯಾ. ನೀನು ಸುಜಯ್ ತರಹ ಇಂಜಿನಿಯರ್ ಆಗಬೇಕು.",
              "te": "నాన్నగారు: మీ అన్న పొలం పని చేస్తున్నాడు కాబట్టి నువ్వు చదువుకో. నీకు మంచి మార్కులు వచ్చాయి. నువ్వు సుజయ్ లాగా ఇంజనీర్ అవ్వాలి.",
              "or": "ବାପା: ତୁମ ଭାଇ କ୍ଷେତ ରେ କାମ କରୁଛି ତେଣୁ ତୁମେ ଆଗକୁ ପଢି ପାରିବ|ତୁମେ \n ଭଲ ନମ୍ବର ପାଇଛ | ତୁମେ ସୁଜୟ ଭଳି ଇଞ୍ଜିନିୟର ହୋଇପାରିବ|",
              "as": "দেউতাঃ তোমাৰ ককায়েৰে খেতিপথাৰত কাম কৰি আছে যাতে তুমি পঢ়িব পাৰা। তুমি ভাল নম্বৰ পাইছা। তুমি সুজয়ৰ দৰে এজন অভিযন্তা হ'ব লাগে।",
              "gu": "બાબુજી: તારો ભાઈ ખેતરમાં કામ કરે છે એટલે તું ભણી શકે. તમને સારા ગુણ આવ્યા છે. તારે સુજયની જેમ ઇજનેર બનવું જોઈએ."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "Bhaiya: I don’t understand these things much. Babuji wants you to study. I know you don’t Can you do anything else that is helpful?",
              "hi": "भैया: मैं इन चीजों को ज्यादा नहीं समझता हूं। बाबूजी चाहते हैं कि तुम अध्ययन करो। मुझे पता है कि तुम नहीं चाहते, क्या तुम ऐसा कुछ और कर सकते हो जो उपयोगी हो?",
              "ka": "ಭೈಯ್ಯಾ: ನನಗೆ ಈ ವಿಷಯಗಳು ಹೆಚ್ಚು ಅರ್ಥವಾಗುವುದಿಲ್ಲ. ಬಾಬೂಜಿ ನೀನು ಓದಬೇಕೆಂದು ಬಯಸುತ್ತಾನೆ. ನನಗೆ ಗೊತ್ತು ನಿನಗೆ ಬೇಡ ಎಂದು. ನಿನಗೆ ಸಹಾಯಕಾರಿಯಾಗಾಬಹುದಾದ ಬೇರೇನಾದರೂ ಮಾಡಬಹುದೇ?",
              "te": "అన్నయ్య: నాకు ఈ విషయాలు పెద్దగా అర్థం కావు. నాన్నగారు నువ్వు చదువుకోవాలనుకుంటున్నారు. నువ్వు అది చేయవని నాకు తెలుసు, సహాయంగా ఉండేలా ఇంకేమైనా చేయగలవా?",
              "or": "ଭାଇ: ମୁଁ ଏହି ସବୁ ଜିନିଷକୁ ଭଲ ଭାବେ ବୁଝୁପରେ ନହିଁ | ବାପା ଚହୁଁଛନ୍ତି ତୁମେ ପଢ|\n ମୁଁ ଜାଣିଛି ତୁମେ ପାରିବନି| ତୁମେ ଏମିତି କିଛି କରିପାରିବ କି ଯାହା ସାହାଜ୍ଯପୂର୍ଣ୍ଣ ହେବ?",
              "as": "দাদাঃ মই এইবোৰ বেছি বুজি নাপাওঁ। দেউতাই তুমি পঢ়াটো বিচাৰে। মই জানো তুমি নোৱাৰা, তুমি আমাক সহায় কৰিব পৰা আন কিবা কৰিব নোৱাৰা নে?",
              "gu": "ભાઈ: મને આ વાતો બહુ સમજાતી નથી. બાબુજી ઈચ્છે છે કે તું ભણે. હું જાણું છું કે તમે નથી કરી શકતા શું તમે બીજું કંઈ કરી શકો જે મદદરૂપ થાય?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "Teacher: If you are unsure right now, you can take science i.e. Physics, Chemistry, Biology and Maths. That way you will be open to do whatever you want after school.",
              "hi": "शिक्षक: यदि तुम अभी निश्चय नहीं कर पा रहे हो, तो तुम विज्ञान यानी भौतिकी, रसायन विज्ञान, जीवविज्ञान और गणित ले सकते हो। इस तरह तुम स्कूल के बाद जो भी  करना चाहते हो उसे करने के लिए मार्ग खुले रहेंगे।",
              "ka": "ಶಿಕ್ಷಕರು: ಇದೀಗ ನಿನಗೆ ಖಚಿತವಿಲ್ಲದಿದ್ದಲ್ಲಿ, ನೀನು ವಿಜ್ಞಾನವನ್ನು ಅಂದರೆ ಭೌತಶಾಸ್ತ್ರ, ರಸಾಯನಶಾಸ್ತ್ರ, ಜೀವಶಾಸ್ತ್ರ ಮತ್ತು ಗಣಿತವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು. ಅದರಿಂದಾಗಿ ಶಾಲೆಯ ನಂತರ ನಿನಗೆ ಬೇಕಾದುದನ್ನು ಮಾಡಲು ನೀನು ಸ್ವತಂತ್ರನಾಗಿರುತ್ತೀಯಾ.",
              "te": "టీచర్: మీకు ప్రస్తుతం ఖచ్చితంగా తెలియకుంటే, నువ్వు సైన్స్ అంటే ఫిజిక్స్, కెమిస్ట్రీ, బయాలజీ మరియు మ్యాథ్స్ తీసుకోవచ్చు. ఆ విధంగా మీరు పాఠశాల తర్వాత మీకు కావలసినది చేయడానికి సిద్ధంగా ఉంటావు.",
              "or": "ଯଦି ତୁମେ ଏବେ ନିଶ୍ଚିତ ତେବେ ତୁମେ ବିଜ୍ଞାନ ପଢିପାରିବା, ଯେମିତିକି:ଭୌତିକ ବିଜ୍ଞାନ,ରସାୟନ ଶସ୍ତ୍ର, ଜୀବବିଜ୍ଞାନ ଓ ଗଣିତ | ଯାହାକି ତୁମକୁ ବିଦ୍ୟାଳୟ ଅଧ୍ୟୟନ ପରେ ତୁମେ ଏଚ କରୁଥିବା କାମ କରିବାରେ ସହାୟକ ହେବ|",
              "as": "শিক্ষক: যদি তুমি এই মুহূৰ্তত নিশ্চিত নোহোৱা, তুমি বিজ্ঞান অৰ্থাৎ পদাৰ্থ বিজ্ঞান, ৰসায়ন বিজ্ঞান, জীৱবিজ্ঞান আৰু গণিত ল'ব পাৰা। তেতিয়া তুমি স্কুলৰ পাছত যি মন যায় তাকে কৰিব পাৰিবা।",
              "gu": "શિક્ષક: જો તમને અત્યારે ખાતરી ન હોય તો તમે વિજ્ઞાન એટલે કે ભૌતિકશાસ્ત્ર, રસાયણશાસ્ત્ર, જીવવિજ્ઞાન અને ગણિત લઈ શકો છો. આ રીતે તમે શાળા પછી જે ઈચ્છો તે કરવા માટે તમે ખુલ્લા હશો."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_3",
              "hi": "X3_1_3",
              "ka": "X3_1_3",
              "te": "X3_1_3",
              "or": "X3_1_3",
              "as": "X3_1_3",
              "gu": "X3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar realises that choosing a stream now will decide what work he can do after his studies.",
              "hi": "शिखर को पता चलता है कि अब एक स्ट्रीम/ धारा का चुनाव तय करेगा कि वह अपने अध्ययन के बाद क्या काम कर सकता है।",
              "ka": "ಶಿಖರ್ ಈಗ ಸ್ಟ್ರೀಮ್ ಅನ್ನು ಆರಿಸಿಕೊಳ್ಳುವುದು ತನ್ನ ಅಧ್ಯಯನದ ನಂತರ ತಾನು ಯಾವ ಕೆಲಸವನ್ನು ಮಾಡಬಹುದು ಎನ್ನುವುದನ್ನು ನಿರ್ಧರಿಸುತ್ತದೆ ಎಂದು ಅರಿತುಕೊಂಡನು.",
              "te": "ఇప్పుడు ఒక స్ట్రీమ్‌ ని ఎంచుకుంటే తన చదువు తర్వాత తాను ఏ పని చేయవచ్చో నిర్ణయిస్తుందని శిఖర్ గ్రహించాడు.",
              "or": "ଶିଖର ବର୍ତ୍ତମାନ ନିଶ୍ଚିତ ହେଲା କି ବର୍ତ୍ତମାନ କୌଣସି ଏକ ସ୍ରୋତ ର ଚୟନ ତାର \n ପାଠ ପଢା ଶେଷ ହେବା ପରେ ତାର କାମକୁ ନିର୍ଧାରଣ କରିବା|",
              "as": "শিখৰে ভাবিছে যে এতিয়া এটা শাখা বাছনি কৰিলে তাৰ পঢ়াৰ পিছত কি কাম কৰিব পাৰে সেইটো ঠিক কৰিব।",
              "gu": "શિખરને ખ્યાલ છે કે હવે પ્રવાહ પસંદ કરવાથી તે નક્કી કરશે કે તે તેના અભ્યાસ પછી શું કામ કરી શકે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_4",
              "hi": "X3_1_4",
              "ka": "X3_1_4",
              "te": "X3_1_4",
              "or": "X3_1_4",
              "as": "X3_1_4",
              "gu": "X3_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Since he likes exploring new things, he decides to follow his teacher’s advice to take Physics, Chemistry, Maths, and Biology as it will open many options.",
              "hi": "चूंकि उसे नई चीजों की खोज करना पसंद है, इसलिए वह भौतिकी, रसायन शास्त्र, गणित और जीवविज्ञान लेने के लिए अपने शिक्षक की सलाह का पालन करने का निर्णय लेता है क्योंकि इससे कई विकल्प खुल जाएंगे।",
              "ka": "ಅವನು ಹೊಸ ವಿಷಯಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಇಷ್ಟಪಡುವ ಕಾರಣ, ಭೌತಶಾಸ್ತ್ರ, ರಸಾಯನಶಾಸ್ತ್ರ, ಗಣಿತ ಮತ್ತು ಜೀವಶಾಸ್ತ್ರವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ತನ್ನ ಶಿಕ್ಷಕರ ಸಲಹೆಯನ್ನು ಅನುಸರಿಸಲು ಅವನು ನಿರ್ಧರಿಸುತ್ತಾನೆ ಏಕೆಂದರೆ ಅದು ಅನೇಕ ಆಯ್ಕೆಗಳನ್ನು ತೆರೆಯುತ್ತದೆ.",
              "te": "అతను కొత్త విషయాలను అన్వేషించడం ఇష్టపడతాడు కాబట్టి, అతను ఫిజిక్స్, కెమిస్ట్రీ, మ్యాథ్స్ మరియు బయాలజీని తీసుకోవాలని తన ఉపాధ్యాయుని సలహాను అనుసరించాలని నిర్ణయించుకున్నాడు, ఎందుకంటే దీని వల్ల చాలా ఎంపికలు ఉంటాయి.",
              "or": "ଯେହେତୁ ସେ ନୂଆ ଜିନିଷ ଶିଖିବାରେ ଆଗ୍ରହୀ ତେଣୁ ଶିକ୍ଷକ ଙ୍କ କହିବା ଅନୁସାରେ ଭୌତିକ ବିଜ୍ଞାନ,ରସାୟନ ଶସ୍ତ୍ର, ଜୀବବିଜ୍ଞାନ ଓ ଗଣିତ ନେଇ ପଢିବା ବୋଲି ସ୍ଥିର କଳା| ଯାହାକି ତାକୁ ଆଗକୁ ବହୁତ ସାରା ରାସ୍ତା ଦେଖାଇବାରେ ସାହାଯ୍ୟ କରିବା|",
              "as": "যিহেতু সি নতুন বস্তু অন্বেষণ কৰিবলৈ ভাল পায়, সি পদাৰ্থ বিজ্ঞান, ৰসায়ন বিজ্ঞান, গণিত আৰু জীৱবিজ্ঞান ল'বলৈ তাৰ শিক্ষকৰ পৰামৰ্শ অনুসৰণ কৰাৰ সিদ্ধান্ত লয় কিয়নো ই বহুতো বিকল্প খুলিব।",
              "gu": "તેને નવી વસ્તુઓનું નિરીક્ષા કરવાનું ગમતું હોવાથી, તેણે ભૌતિકશાસ્ત્ર, રસાયણશાસ્ત્ર, ગણિત અને જીવવિજ્ઞાન લેવાની તેના શિક્ષકની સલાહને અનુસરવાનું નક્કી કર્યું કારણ કે તે ઘણા વિકલ્પો ખોલશે."
            }
          }
        ]
      },
      "scene6": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_1",
              "hi": "X4_1_1",
              "ka": "X4_1_1",
              "te": "X4_1_1",
              "or": "X4_1_1",
              "as": "X4_1_1",
              "gu": "X4_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar has passed his  11th standard examination. His cousin Sujay is visiting them for his summer holiday. Sujay is studying engineering at a college in the city close to the village.",
              "hi": "शिखर ने अपनी ग्यारहवीं कक्षा की परीक्षा उत्तीर्ण की है। उसके चचेरे भाई सुजय गर्मी की छुट्टी के लिए उसके पास आ रहे हैं। सुजय गांव के पास ही शहर के एक कॉलेज में इंजीनियरिंग का अध्ययन कर रहा है।",
              "ka": "ಶಿಖರ್ 11ನೇ ತರಗತಿಯಲ್ಲಿ ತೇರ್ಗಡೆಯಾಗಿದ್ದಾನೆ. ಅವನ ಸೋದರ ಸಂಬಂಧಿ ಸುಜಯ್  ಅವನ ಬೇಸಿಗೆ ರಜೆಯಲ್ಲಿ ಅವನನ್ನು ಭೇಟಿಯಾಗುತ್ತಿದ್ದಾನೆ. ಸುಜಯ್ ಹಳ್ಳಿಗೆ ಸಮೀಪವಿರುವ ನಗರದ ಕಾಲೇಜೊಂದರಲ್ಲಿ ಇಂಜಿನಿಯರಿಂಗ್ ಓದುತ್ತಿದ್ದಾನೆ.",
              "te": "శిఖర్ తన 11వ తరగతి పరీక్షలో ఉత్తీర్ణుడయ్యాడు. అతని బంధువు సుజయ్ తన వేసవి సెలవుల కోసం వారి వద్దకు వస్తున్నాడు. సుజయ్ గ్రామానికి సమీపంలోని నగరంలోని ఓ కళాశాలలో ఇంజనీరింగ్ చదువుతున్నాడు.",
              "or": "ଶିଖର ନିଜର ୧୧ କକ୍ଷର ପରୀକ୍ଷା ଶେଷ କଲା| ଗ୍ରୀଷ୍ମ ଛୁଟି ପାଇଁ ତା ଭାଇ ସୁଜୟ \n ତାଙ୍କ ଘରକୁ ବୁଲିବାକୁ ଆସୁଛି|ଗାଁ ପାଖରେ ଥିବା ମହାବିଦ୍ୟାଳୟ ରେ ସୁଜୟ ଇଞ୍ଜିନିଅରିଂ \n ପଢୁଛି|",
              "as": "শিখৰে তাৰ একাদশ শ্ৰেণীৰ পৰীক্ষাত পাছ হৈছে। তাৰ সম্পৰ্কীয় দাদা সুজয়ে তাৰ গৰম বন্ধৰ বাবে তেওঁলোকক দেখা দিবলৈ আহিছে। সুজয়ে গাওঁখনৰ ওচৰৰ চহৰখনৰ এখন কলেজত ইঞ্জিনিয়াৰিং পঢ়ি আছে।",
              "gu": "શિખરે 11મા ધોરણની પરીક્ષા પાસ કરી છે. તેનો પિતરાઈ ભાઈ સુજય તેની ઉનાળાની રજાઓ માટે તેની મુલાકાતે છે. સુજય ગામની નજીક આવેલી શહેરની કોલેજમાં એન્જિનિયરિંગનો અભ્યાસ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_2//",
              "hi": "X4_1_2 //",
              "ka": "X4_1_2//",
              "te": "X4_1_2//",
              "or": "X4_1_2//",
              "as": "X4_1_2//",
              "gu": "X4_1_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar likes spending time with Sujay.",
              "hi": "शिखर को सुजय के साथ समय बिताना पसंद है।",
              "ka": "ಶಿಖರ್ ಸುಜಯ್ ಜೊತೆ ಸಮಯ ಕಳೆಯಲು ಇಷ್ಟಪಡುತ್ತಾನೆ.",
              "te": "సుజయ్‌ తో సమయం గడపడం శిఖర్‌కి ఇష్టం.",
              "or": "ଶିଖର ସୁଜୟ ସହିତ ସମୟ ବିତାଇବା ପାଇଁ ପସନ୍ଦ କରେ|",
              "as": "শিখৰে সুজয়ৰ সৈতে সময় কটাবলৈ ভাল পায়।",
              "gu": "શિખરને સુજય સાથે સમય પસાર કરવો ગમે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Sujay",
              "hi": "सुजय",
              "ka": "ಸುಜಯ್",
              "te": "సుజయ్",
              "or": "ସୁଜୟ",
              "as": "সুজয়",
              "gu": "સુજય"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Shikhar, Are you planning to prepare for the engineering entrance exam this year?",
              "hi": "शिखर, क्या तुम इस साल इंजीनियरिंग प्रवेश परीक्षा के लिए तैयारी करने की योजना बना रहे हो?",
              "ka": "ಶಿಖರ್, ನೀನು ಈ ವರ್ಷ ಇಂಜಿನಿಯರಿಂಗ್ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಗೆ ತಯಾರಿ ನಡೆಸಲು ಯೋಚಿಸುತ್ತಿದ್ದೀಯಾ?",
              "te": "శిఖర్, నువ్వు ఈ సంవత్సరం ఇంజనీరింగ్ ప్రవేశ పరీక్షకు సిద్ధమవుతున్నావా?",
              "or": "ଶିଖର, ତୁମେ ଏହିବର୍ଷ ଇଞ୍ଜିନିଅରିଂ ର ପ୍ରବେଶିକା ପରୀକ୍ଷା ଦେବା ପାଇଁ ନିଜକୁ ପ୍ରସ୍ତୁତ \n କରୁଛ କି?",
              "as": "শিখৰ, তুমি এই বছৰ ইঞ্জিনিয়াৰিং প্ৰৱেশ পৰীক্ষাৰ বাবে প্ৰস্তুতি কৰাৰ কথা ভাবিছা নেকি?",
              "gu": "શિખર, શું તું આ વર્ષે એન્જિનિયરિંગની પ્રવેશ પરીક્ષાની તૈયારી કરવાનું વિચારી રહ્યો છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Bhaiya, I’m not sure if I want to do engineering, but everyone at home expects me to do it.",
              "hi": "भैया, मैं निश्चय नहीं कर पा रहा हूँ कि मैं इंजीनियरिंग करना चाहता हूं, लेकिन घर पर हर कोई मुझसे ऐसा करने की उम्मीद करता है।",
              "ka": "ಭೈಯ್ಯಾ, ನಾನು ಇಂಜಿನಿಯರಿಂಗ್ ಮಾಡಬೇಕೆಂದು ನನಗೆ ಖಚಿತವಿಲ್ಲ, ಆದರೆ ಮನೆಯಲ್ಲಿ ಎಲ್ಲರೂ ನಾನು ಅದನ್ನು ಮಾಡಬೇಕೆಂದು ನಿರೀಕ್ಷಿಸುತ್ತಾರೆ.",
              "te": "అన్నయ్య, నేను ఇంజినీరింగ్ చేయాలనుకుంటున్నానో లేదో నాకు ఖచ్చితంగా తెలియదు, కానీ ఇంట్లో అందరూ నేను అదే చేయాలని ఆశిస్తున్నారు.",
              "or": "ଭାଇ, ମୁ ନିଶ୍ଚିତ ନୁହେଁ , କିନ୍ତୁ ଘରେ ସମସ୍ତେ ସେୟା ଚହୁଁଛନ୍ତି|",
              "as": "দাদা, মই নিশ্চিত নহয় যে মই ইঞ্জিনিয়াৰিং কৰিব বিচাৰো নে নাই, কিন্তু ঘৰৰ সকলোৱে মই এইটো কৰাটো আশা কৰে।",
              "gu": "ભાઈ, મને ખાતરી નથી કે મારે એન્જીનીયરીંગ કરવું છે કે કેમ, પણ ઘરના દરેક જણ મારી પાસેથી તે કરવાની આશા રાખે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_3////",
              "hi": "X4_1_3 ////",
              "ka": "X4_1_3////",
              "te": "X4_1_3////",
              "or": "X4_1_3////",
              "as": "X4_1_3////",
              "gu": "X4_1_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "So you don’t like engineering? Or do you have another career in mind?",
              "hi": "तो तुमको इंजीनियरिंग पसंद नहीं है? या तुम्हारे मन में किसी और करियर का विचार है?",
              "ka": "ಹಾಗಾದರೆ ನಿನಗೆ ಎಂಜಿನಿಯರಿಂಗ್ ಇಷ್ಟವಿಲ್ಲವೇ? ಅಥವಾ ನಿನ್ನ ಮನಸ್ಸಿನಲ್ಲಿ ಬೇರೆ ಯಾವುದಾದರೂ ವೃತ್ತಿ ಇದೆಯೇ?",
              "te": "అంటే నీకు ఇంజనీరింగ్ ఇష్టం లేదా? లేక మరో కెరీర్‌ ఏదైనా అనుకున్నావా?",
              "or": "ତାହେଲେ ତୁମେ କଣ ଇଞ୍ଜିନିଅରିଂ କୁ ପସନ୍ଦ କରନି ? କିମ୍ବା ତୁମେ ଅଲଗା କିଛି ରେ ନିଜ \n ଜୀବିକା କରିବା ବୋଲି ଭବିଛ?",
              "as": "গতিকে তুমি ইঞ্জিনিয়াৰিং ভাল নোপোৱা নেকি? নে তোমাৰ মনত আন এটা কেৰিয়াৰ আছে?",
              "gu": "તો તને એન્જિનિયરિંગ નથી ગમતું? અથવા તારા મનમાં બીજી કારકિર્દી છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "It’s not that. I don't want to take up engineering just because it is the only option available to me.",
              "hi": "ऐसा नहीं है। मैं सिर्फ इसलिए इंजीनियरिंग नहीं लेना चाहता क्योंकि यह मेरे लिए एकमात्र उपलब्ध विकल्प   है।",
              "ka": "ಅದು ಅಲ್ಲ. ಇಂಜಿನಿಯರಿಂಗ್ ಅನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ನಾನು ಬಯಸುವುದಿಲ್ಲ ಏಕೆಂದರೆ ಅದು ನನಗೆ ಲಭ್ಯವಿರುವ ಏಕೈಕ ಆಯ್ಕೆಯಾಗಿದೆ ಎನ್ನುವುದರಿಂದ ",
              "te": "అది కాదు. నాకు అందుబాటులో ఉన్న ఏకైక ఎంపిక అది మాత్రమే కాబట్టి నేను ఇంజనీరింగ్‌ తీసుకోవాలనుకోవట్లేదు.",
              "or": "ସେମିତି କିଛି ନୁହେଁ| ଏଇ ଗୋଟିଏ ଚୟନ ମୋ ପାଇଁ ଉଦ୍ଧିଷ୍ଠ ବୋଲି ମୁଁ ଇଞ୍ଜିନିଅରିଂ \n କୁ ବାଛିବାକୁ ଚାହୁଁ ନାହି|",
              "as": "সেইটো নহয়। মই কেৱল ইঞ্জিনিয়াৰিং ল'ব নিবিচাৰো কাৰণ এইটো মোৰ বাবে থকা একমাত্ৰ বিকল্প।",
              "gu": "એવું નથી. હું એન્જીનીયરીંગ લેવા માંગતો નથી કારણ કે તે મારા માટે એકમાત્ર વિકલ્પ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Hmmm. Why don’t you come with me to the city and visit my college? If you still feel the same way, then don’t go for the coaching classes.",
              "hi": "हम्म। तुम मेरे साथ शहर आकर मेरा कॉलेज क्यों नहीं देखते? यदि तुम तब भी वैसा ही महसूस करते हो, तो कोचिंग क्लासेस के लिए मत जाओ।",
              "ka": "ಹ್ಮ್ಮ. ನೀನು ನನ್ನೊಂದಿಗೆ ನಗರಕ್ಕೆ ಏಕೆ ಬರಬಾರದು ಮತ್ತು ನನ್ನ ಕಾಲೇಜಿಗೆ ಭೇಟಿ ನೀಡಬಾರದು? ನೀನು ಆಗಲೂ  ಅದೇ ರೀತಿ ಭಾವಿಸಿದಲ್ಲಿ, ನಂತರ ಕೋಚಿಂಗ್ ತರಗತಿಗಳಿಗೆ ಹೋಗಬೇಡ.",
              "te": "అయ్యో. మీరు నాతో నగరానికి వచ్చి నా కళాశాలను ఎందుకు సందర్శించకూడదు? మీకు ఇంకా అలాగే అనిపిస్తే, కోచింగ్ క్లాసులకు వెళ్లకం",
              "or": "ତୁମେ ତାହେଲେ କାହିଁକି ମୋ ସହ ସହର କୁ ଆଇ ମୋ କଲେଜ ବୁଲିକି ଯାଉନ? ତାପରେ ବି ଯଦି ତୁମେ ସେଇ ଏକ କଥା ଅନୁଭବ କରିବ ତେବେ ତୁମେ କୋଚିଂ କ୍ଳାସ ପାଇଁ ଜୀବନାହି|",
              "as": "হুম। তুমি মোৰ সৈতে চহৰলৈ আহি মোৰ কলেজলৈ কিয় নাহা? যদি তেতিয়াও একে ভাৱ হয়, তেন্তে প্ৰশিক্ষণ ক্লাছলৈ নাযাবা।",
              "gu": "બરાબર. તું મારી સાથે શહેરમાં આવીને મારી કોલેજની મુલાકાત કેમ નથી લેતા? જો તું હજી પણ એવું જ અનુભવે છે, તો કોચિંગ ક્લાસમાં ન જઈશ."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_2_1",
              "hi": "X4_2_1",
              "ka": "X4_2_1",
              "te": "X4_2_1",
              "or": "X4_2_1",
              "as": "X4_2_1",
              "gu": "X4_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar feels it'll be good for him and plans to discuss it with Babuji and Bhaiya.",
              "hi": "शिखर को लगता है कि यह उसके लिए अच्छा रहेगा और बाबूजी तथा भैया के साथ इस विषय पर चर्चा करने की योजना बनाता है।",
              "ka": "ಶಿಖರ್ ಇದು ಒಳ್ಳೆಯದು ಎಂದು ಭಾವಿಸುತ್ತಾನೆ ಮತ್ತು ಬಾಬೂಜಿ ಹಾಗೂ ಭಯ್ಯಾ ಅವರೊಂದಿಗೆ ಚರ್ಚಿಸಲು ಯೋಚಿಸುತ್ತಾನೆ.",
              "te": "శిఖర్ అది తనకు మంచిదని భావించి, నాన్నగారు మరియు అన్నయ్యతో చర్చించాలని ఆలోచిస్తున్నాడు.",
              "or": "ଶିଖର ଏହାକୁ ଉଚ୍ଚିତ ବୋଲି ଭବିଲ ଏବଂ ଭାଇ ଓ ବାପାଙ୍କ ସହ ଆଲୋଚନା କରିବାକୁ \n ଭବିଲା|",
              "as": "শিখৰে ভাবে যে এইটো তাৰ বাবে ভাল হ'ব আৰু দেউতাক আৰু ককায়েকৰ সৈতে আলোচনা কৰাৰ কথা ভাবিছে।",
              "gu": "શિખરને લાગે છે કે તે તેના માટે સારું રહેશે અને બાબુજી અને ભાઈ સાથે તેની ચર્ચા કરવાની યોજના ધરાવે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_2_2//",
              "hi": "X4_2_2 //",
              "ka": "X4_2_2//",
              "te": "X4_2_2//",
              "or": "X4_2_2//",
              "as": "X4_2_2//",
              "gu": "X4_2_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "As he is about to approach them, he overhears their discussion around money problems.",
              "hi": "वह उनसे जैसे ही संपर्क करने जाता है,  उसे उनकी धन संबंधी समस्या की भनक लगती है।",
              "ka": "ಅವನು ಅವರನ್ನು ಸಮೀಪಿಸುತ್ತಿರುವಾಗ, ಹಣದ ಸಮಸ್ಯೆಗಳ ಬಗ್ಗೆ ಅವರ ಚರ್ಚೆಯನ್ನು ಅವನು ಕೇಳುತ್ತಾನೆ.",
              "te": "అతను వారి దగ్గరికి వెళుతున్నప్పుడు, డబ్బు సమస్యల గురించి వారి చర్చను అతను విన్నాడు.",
              "or": "ବାପା ଓ ଭାଇଙ୍କ ସହ ଆଲୋଚନା ପାଇଁ ଯିବା ସମୟରେ ସେ ଟଙ୍କା ପଇସା \n ସମ୍ବନ୍ଧିତ ସେମାନଙ୍କର ଆଲୋଚନାକୁ ଶୁଣିଲା|",
              "as": "যেতিয়া সি তেওঁলোকৰ ওচৰলৈ যাবলৈ ওলায়, সি পইছাৰ অসুবিধাৰ বিষয়ে তেওঁলোকৰ আলোচনা শুনে।",
              "gu": "જ્યારે તે તેમનો સંપર્ક કરવા જઈ રહ્યો છે, ત્યારે તે પૈસાની સમસ્યાઓ વિશેની તેની ચર્ચા સાંભળે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Babuji",
              "hi": "बाबूजी",
              "ka": "ಬಾಬುಜಿ",
              "te": "నాన్నగారు",
              "or": "ବାବୁଜୀ",
              "as": "দেউতা",
              "gu": "બાબુજી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arun",
              "hi": "अरुण",
              "ka": "ಅರುಣ್ ",
              "te": "అరుణ్",
              "or": "ଅରୁଣ ଭାଇ",
              "as": "অৰুণ",
              "gu": "અરુણ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Arun, what is the profit looking like? Do we have enough to buy next year’s supply of fertilisers comfortably?",
              "hi": "अरुण, कितना लाभ दिख रहा है? क्या हमारे पास अगले वर्ष की फर्टिलाइज़र्स/ उर्वरकों की आपूर्ति को सहज रूप से खरीदने के लिए पर्याप्त धन है?",
              "ka": "ಅರುಣ್, ಲಾಭ ಹೇಗಿದೆ? ಮುಂದಿನ ವರ್ಷದ ರಸಗೊಬ್ಬರಗಳನ್ನು ಆರಾಮವಾಗಿ ಖರೀದಿಸಲು ನಮಗೆ ಸಾಕಷ್ಟು ಇದೆಯೇ?",
              "te": "అరుణ్, లాభమేంటి అనిపించిందా? వచ్చే సంవత్సరం ఎరువులను కొనడానికి మనకు సరిపడా ఉందా?",
              "or": "ଅରୁଣ, ଲାଭ କେତେ ହେଲା ଭଳି ଲାଗୁଛି? ଆସନ୍ତା ବର୍ଷ ପାଇଁ ସାର କିଣିପାରିବା ଭଳି \n ଟଙ୍କା ଆମ ପାଖରେ ଅଛି ତ?",
              "as": "অৰুণ, লাভ কেনেকুৱা দেখা গৈছে? আমাৰ ওচৰত অহা বছৰৰ বাবে সাৰ কিনিবলৈ পৰ্যাপ্ত টকা আছে নে?",
              "gu": "અરુણ, ફાઇદો કેવો દેખાય છે? શું આપણી પાસે આવતા વર્ષનો ખાતરનો પુરવઠો ખરીદવા માટે પૂરતો છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "It is going to be tough. We also need more people to work on the farm to make a profit.",
              "hi": "यह कठिन समय है। लाभ पाने के लिए खेत पर काम करने हेतु हमें और भी लोगों की आवश्यकता है।",
              "ka": "ಇದು ಕಠಿಣವಾಗಲಿದೆ. ಲಾಭ ಗಳಿಸಲು ಜಮೀನಿನಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಹೆಚ್ಚು ಜನರು ಬೇಕು.",
              "te": "ఇది కొంచెం కష్టమే. లాభం రావాలంటే పొలంలో పని చేయడానికి ఎక్కువ మంది కూడా కావాలి.",
              "or": "ବହୁତ କାଷ୍ଠସାଧ୍ୟ ଜଣାପଡୁଛି। ଆମେ ଆମ କ୍ଷେତ ରେ ଲାଭ ପାଇଁ ଆହୁରି ବେଶୀ ଲୋକ ଦରକାର \n କରୁଚେ।",
              "as": "এইটো কঠিন হ'ব। লাভৰ বাবে আমাক খেতিপথাৰত কাম কৰিবলৈ অধিক লোকৰ প্ৰয়োজন।",
              "gu": "તે અઘરું થવાનું છે. આપણને નફો મેળવવા માટે ખેતરમાં કામ કરવા માટે વધુ લોકોની પણ જરૂર છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_2_3////",
              "hi": "X4_2_3 ////////",
              "ka": "X4_2_3////",
              "te": "X4_2_3////",
              "or": "X4_2_3////",
              "as": "X4_2_3////",
              "gu": "X4_2_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "That will cost us a lot. We can do it only if we know we will get a reasonable price for our produce. Or we will suffer losses like the last two seasons.",
              "hi": "इसमें बहुत खर्च आएगा। हम ऐसा केवल तभी कर सकते हैं जब हमें पता हो कि हमें अपने उत्पाद के लिए उचित मूल्य मिलेगा। या हम पिछले दो सत्रों की तरह घाटे का सामना करेंगे।",
              "ka": "ಅದು ನಮಗೆ ಸಾಕಷ್ಟು ವೆಚ್ಚವಾಗುತ್ತದೆ. ನಮ್ಮ ಉತ್ಪನ್ನಕ್ಕೆ ಸಮಂಜಸವಾದ ಬೆಲೆ ಸಿಗುತ್ತದೆ ಎಂದು ತಿಳಿದರೆ ಮಾತ್ರ ನಾವು ಅದನ್ನು ಮಾಡಬಹುದು. ಇಲ್ಲವೇ ಕಳೆದ ಎರಡು ಸೀಸನ್ ನಂತೆ ನಷ್ಟ ಅನುಭವಿಸಬೇಕಾಗುತ್ತದೆ.",
              "te": "దానివల్ల మనకు చాలా ఖర్చు అవుతుంది. మనం పండించిన పంటకు గిట్టుబాటు ధర లభిస్తుందని తెలిస్తేనే మనం చేయగలం. లేదంటే గత రెండు సీజన్‌ ల మాదిరిగా నష్టపోతాం.",
              "or": "ତାହା ଆମ ପାଇଁ ବ୍ୟୟବହୁଳ ହେବ। ଆମେ ସେତେବେଳେ କରିପାରିବା \n ଯେତେବେଳେ ଆମେ ଜାଣିବା ଯେ ଆମ ଉତ୍ପାଦନ ର ସଠିକ ମୂଲ୍ୟ ଆମେ ପାଇବା| \n ନହେଲେ ଆମେ ପୂର୍ବ ଦୁଇ ବର୍ଷ ଭଳି ଖ୍ଯାତି ସହିବା।",
              "as": "ইয়াৰ বাবে বহুত খৰচ লাগিব। আমি এইটো তেতিয়াহে কৰিব পাৰোঁ যদি আমি জানো যে আমি আমাৰ উৎপাদিত সামগ্ৰীৰ বাবে যুক্তিসঙ্গত মূল্য পাম। নতুবা আমি যোৱা দুটা ঋতুৰ দৰে লোকচানৰ সন্মুখীন হ'ম।",
              "gu": "તે આપણને વધારે ખર્ચ કરશે. આપણે તે ત્યારે જ કરી શકીએ જો આપણને ખબર હોય કે આમા ઉત્પાદન માટે વાજબી ભાવ મળશે. અથવા તો છેલ્લી બે રૂતુની જેમ નુકસાન ભોગવવું પડશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "We can take a loan from Jabbu. He said he is giving a loan at 8% this year.",
              "hi": "हम जब्बू से ऋण ले सकते हैं। उसने बताया कि वह इस साल 8% पर ऋण दे रहा है।",
              "ka": "ನಾವು ಜಬ್ಬುವಿನಿಂದ ಸಾಲ ತೆಗೆದುಕೊಳ್ಳಬಹುದು. ಈ ವರ್ಷ ಶೇ.8 ರ ಬಡ್ಡಿಯಲ್ಲಿ ಸಾಲ ನೀಡುತ್ತೇನೆ ಎನ್ನುತ್ತಿದ್ದರು.",
              "te": "జబ్బు దగ్గర అప్పు తీసుకోవచ్చు. ఈ ఏడాది 8% కి అప్పు ఇస్తున్నట్లు చెప్పాడు.",
              "or": "ଆମେ ଜାବୁ ଠାରୁ ରୁଣ ନେଇପାରିବା। ସେ କହିଛି ଆମକୁ ୮% ରେ ରୁଣ ଦବ",
              "as": "আমি জব্বুৰ পৰা ঋণ ল'ব পাৰোঁ। তেওঁ কৈছিল যে তেওঁ এই বছৰ 8% হাৰত ঋণ দি আছে।",
              "gu": "આપણે જબ્બુ પાસેથી લોન લઈ શકીએ છીએ. તે આ વર્ષે 8%ના દરે લોન આપી રહ્યો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Okay, we will talk about it later when we get there. Shikhar and your Maaji will hear us and get worried.",
              "hi": "ठीक है,  हम इसके बारे में बाद में बात करेंगे, जब हम वहां पहुंचेंगे। शिखर और तुम्हारी माँजी हमें सुनेंगे और चिंतित हो जाएंगे।",
              "ka": "ಸರಿ, ನಾವು ಆ ಸಮಯಕ್ಕೆ ಬಂದ ಬಳಿಕ ಅದರ ಬಗ್ಗೆ ಮಾತನಾಡೋಣ ಶಿಖರ್ ಮತ್ತು ನಿಮ್ಮ ಮಾಜಿ ನಮ್ಮ ಮಾತನ್ನು ಕೇಲಿಸಿಕೊಳ್ಳುತ್ತಾರೆ ಹಾಗೂ  ಚಿಂತಿತರಾಗುತ್ತಾರೆ.",
              "te": "సరే, అక్కడికి వెళ్ళిన తర్వాత దాని గురించి మాట్లాడుకుందాం. శిఖర్ మరియు మీ అమ్మగారు మన మాటలు వింటే ఆందోళన చెందుతారు.",
              "or": "ଠିକ ଅଛି, ଆମେ ଏହି ବିଷୟରେ ଶେଠୀ ପହଞ୍ଚିବା ପରେ କଥା ହେବା। ଶିଖର ଆଉ ମା ଆମ କଥା ଶୁଣି ଚିନ୍ତିତ ହୋଇପଡିବେ।",
              "as": "ঠিক আছে, আমি পিছত তালৈ গ'লে ইয়াৰ বিষয়ে কথা পাতিম। শিখৰ আৰু তোমাৰ মায়ে আমাৰ কথা শুনিলে আকৌ চিন্তাত পৰিব।",
              "gu": "ઠીક છે, જ્યારે આપણે ત્યાં પહોંચીશું ત્યારે અમે તેના વિશે પછીથી વાત કરીશું. શિખર અને તારા માજી અમને સાંભળશે અને ચિંતા કરશે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_2_4_reveal",
              "hi": "X4_2_4_रिवील ",
              "ka": "X4_2_4_reveal",
              "te": "X4_2_4_reveal",
              "or": "X4_2_4_reveal",
              "as": "X4_2_4_reveal",
              "gu": "X4_2_4_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar is left confused. He has so many thoughts in his mind. Click on the buttons to reveal his thoughts.",
              "hi": "शिखर उलझन में है। उसके मन में बहुत सारे विचार हैं। उसके विचारों को प्रकट करने के लिए बटन पर क्लिक करें।",
              "ka": "ಶಿಖರ್ ಗೊಂದಲದಲ್ಲಿದ್ದಾನೆ. ಆತನ ಮನಸ್ಸಿನಲ್ಲಿ ಹಲವಾರು ಆಲೋಚನೆಗಳಿವೆ. ಅವನ ಆಲೋಚನೆಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಗುಂಡಿಗಳ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "శిఖర్ అయోమయంలో పడ్డాడు. అతని మనసులో చాలా ఆలోచనలు ఉన్నాయి. అతని ఆలోచనలను బహిర్గతం చేయడానికి బటన్లపై క్లిక్ చేయండి (నొక్కండి).",
              "or": "ଶିଖର ଦ୍ଵନ୍ଦ ମଧ୍ୟରେ ବାହାରିଗଲା। ତା ମୁଣ୍ଡରେ ବର୍ତ୍ତମାନ ବହୁତ ଗୁଡିଏ ବିଚାର।\n ତାର ବିଚାର ଗୁଡିକୁ ଜାଣିବା ପାଇଁ ବଟନ ଉପରେ କ୍ଲିକ କରନ୍ତୁ।",
              "as": "শিখৰ চিন্তিত হৈ পৰিছে। তাৰ মনত বহুতো চিন্তা আছে। তাৰ চিন্তাবোৰ প্ৰকাশ কৰিবলৈ বুটামবোৰত ক্লিক কৰা।",
              "gu": "શિખર મૂંઝવણમાં મુકાઈ ગયો. તેના મનમાં ઘણા બધા વિચારો છે. તેના વિચારો જણાવવા માટે બટન પર ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Thought 1",
              "hi": "विचार 1",
              "ka": "ಆಲೋಚನೆ 1",
              "te": "ఆలోచన 1",
              "or": "ବିଚାର୧",
              "as": "চিন্তা 1",
              "gu": "વિચાર 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Thought 2",
              "hi": "विचार 2 ",
              "ka": "ಆಲೋಚನೆ 2",
              "te": "ఆలోచన 2",
              "or": "ବିଚାର୨",
              "as": "চিন্তা 2",
              "gu": "વિચાર 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Thought 3",
              "hi": "विचार 3",
              "ka": "ಆಲೋಚನೆ 3",
              "te": "ఆలోచన 3",
              "or": "ବିଚାର୩",
              "as": "চিন্তা 3",
              "gu": "વિચાર 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "Thought 4",
              "hi": "विचार 4",
              "ka": "ಆಲೋಚನೆ 4",
              "te": "ఆలోచన 4",
              "or": "ବିଚାର୪",
              "as": "চিন্তা 4",
              "gu": "વિચાર 4"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "“Why am I thinking of further studies when my family is going through a bad time.”",
              "hi": "\"जब मेरा परिवार बुरे समय से गुजर रहा है तो मैं आगे के अध्ययन के बारे में क्यों सोच रहा हूं।\"",
              "ka": "ನನ್ನ ಕುಟುಂಬವು ಕೆಟ್ಟ ಸಮಯವನ್ನು ಎದುರಿಸುತ್ತಿರುವಾಗ ನಾನು ಹೆಚ್ಚಿನ ಓದಿನ ಬಗ್ಗೆ ಏತಕ್ಕಾಗಿ ಯೋಚಿಸುತ್ತಿದ್ದೇನೆ.",
              "te": "\"నా కుటుంబం పరిస్థితి బాగా లేనప్పుడు నేను తదుపరి చదువుల గురించి ఎందుకు ఆలోచిస్తున్నాను.\"",
              "or": "\"ମୁଁ କାହିଁକି ଆଗକୁ ପଢିବାକୁ ଚାହୁଛି ଯେତେବେଳେ କି ମୋ ପରିବାର ଖରାପ \n ସମୟ ମଧ୍ୟ ଦେଇ ଗତି କରୁଛି।\"",
              "as": "যেতিয়া মোৰ পৰিয়ালে বেয়া সময় পাৰ কৰি আছে তেতিয়া মই কিয় অধিক পঢ়াৰ কথা ভাবি আছোঁ।",
              "gu": "\"મારો પરિવાર ખરાબ સમયમાંથી પસાર થઈ રહ્યો છે ત્યારે હું શા માટે આગળ અભ્યાસ કરવાનું વિચારી રહ્યો છું.\""
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "“I don’t think I want to study engineering anyway. Why can't I be the extra help that Babuji needs on the farm? ”",
              "hi": "\"वैसे भी मुझे नहीं लगता कि मैं इंजीनियरिंग का अध्ययन करना चाहता हूं। बाबूजी को खेत पर ज़रुरत है, मैं उन्हें अतिरिक्त सहायता क्यों नहीं कर सकता ? \"",
              "ka": "“ಹೇಗಿದ್ದರೂ ಇಂಜಿನಿಯರಿಂಗ್ ಓದಬೇಕೆಂದು ನನಗೆ ಅನಿಸುತ್ತಿಲ್ಲ. ಜಮೀನಿನಲ್ಲಿ ಬಾಬೂಜಿಗೆ ಅಗತ್ಯವಿರುವ ಹೆಚ್ಚುವರಿ ಸಹಾಯಕ ನಾನೇಕೆ ಆಗಬಾರದು? ”",
              "te": "“నేను ఎలాగైనా ఇంజినీరింగ్ చదవాలని అనుకోవడం లేదు. పొలంలో నాన్నగారికి అవసరమైన అదనపు సహాయం నేనెందుకు చేయలేను? ”",
              "or": "ମୁଁ ଭାବୁନି କି ମୁଁ ଇଞ୍ଜିନିଅରିଂ କରିବାକୁ ଚାହୁଛି। କାହିଁକି ମୁ ମୋ ବାପାଙ୍କୁ କ୍ଷେତରେ \n  ଦରକାର \n କରୁଥିବା ବଳକା ସାହାଯ୍ୟ କରିପାରିବିନି?\"",
              "as": "মই নাভাবো যে মই কেৱল ইঞ্জিনিয়াৰিং পঢ়িব বিচাৰোঁ। দেউতাক খেতিপথাৰত প্ৰয়োজন হোৱা অতিৰিক্ত সহায় মই কিয় হ'ব নোৱাৰোঁ?",
              "gu": "“મને નથી લાગતું કે મારે કોઈપણ રીતે એન્જિનિયરિંગનો અભ્યાસ કરવો છે. બાબુજીને ખેતરમાં જોઈતી વધારાનો મદદગાર હું કેમ ન બની શકું? \""
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "“If I study enough and get a good job, I might be able to help them better.”",
              "hi": "\"अगर मैं पर्याप्त अध्ययन करता हूं और एक अच्छी नौकरी प्राप्त करता हूं, तो मैं उनकी सहायता करने में बेहतर साबित हो सकता हूं।\"",
              "ka": "ನಾನು ಸಾಕಷ್ಟು ಓದಿದರೆ ಮತ್ತು ಒಳ್ಳೆಯ ಕೆಲಸವನ್ನು ಪಡೆದರೆ, ನಾನು ಅವರಿಗೆ ಉತ್ತಮ ರೀತಿಯಲ್ಲಿ ಸಹಾಯ ಮಾಡಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ.",
              "te": "నేను తగినంతగా చదివి మంచి ఉద్యోగం సంపాదించినట్లయితే, నేను వారికి బాగా సహాయం చేయగలను.",
              "or": "\" ଯଦି ମୁଁ ଯଥେଷ୍ଟ ପାଠ ପଢି ଗୋଟିଏ ଭଲ ଚାକିରି ପାଇବି। ବୋଧ ହୁଏ ମୁ ସେମାନଙ୍କୁ \n ଭଲ ଭାବେ ସାହାଯ୍ୟ କରିପାରିବି।\"",
              "as": "যদি মই অধিক পঢ়ো আৰু ভাল চাকৰি পাওঁ, তেন্তে মই তেওঁলোকক অধিক ভালদৰে সহায় কৰিব পাৰিম।",
              "gu": "\"જો હું પૂરતો અભ્યાસ કરીશ અને સારી નોકરી મેળવીશ, તો હું તેમને વધુ સારી રીતે મદદ કરી શકીશ.\""
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "“Babuji will get very angry if I don’t study further. But like Abhilash from the seed bank, I can also study something where I can use the knowledge to help them.”",
              "hi": "यदि मैं आगे पढ़ाई नहीं करता तो बाबूजी को बहुत गुस्सा आएगा। लेकिन बीज बैंक से अभिलाष की तरह, मैं कुछ भी अध्ययन कर सकता हूं जहां मैं उनकी सहायता करने के लिए जानकारी का उपयोग कर सकता हूं। \"",
              "ka": "“ನಾನು ಮುಂದೆ ಓದದಿದ್ದರೆ ಬಾಬೂಜಿ ತುಂಬಾ ಕೋಪಗೊಳ್ಳುತ್ತಾರೆ. ಆದರೆ ಬೀಜ ಬ್ಯಾಂಕ್‌ನ ಅಭಿಲಾಷ್‌ನಂತೆ, ನಾನು ಅವರಿಗೆ ಸಹಾಯ ಮಾಡಲು ಬುದ್ಧಿಯನ್ನು ಬಳಸಬಹುದಾದ ಏನನ್ನಾದರೂ ಓದಬಹುದು.\"",
              "te": "“నేను ఇంకా చదువుకోకపోతే నాన్నగారికి చాలా కోపం వస్తుంది. కానీ విత్తన బ్యాంక్ లో అభిలాష్ లాగా, నేను కూడా వారికి సహాయం చేయడానికి ఉపయోగపడేలా ఏదైనా నేర్చుకోవచ్చు.",
              "or": "\"ବାପା ବହୁତ ରାଗିବେ ଯଦି ମୁଁ ଆଗକୁ ପାଠ ନପଢିବି। କିନ୍ତୁ ମଞ୍ଜି ବ୍ୟାଙ୍କର \n  ଅଭିଲାଷ ଭଳି, ମୁଁ ଏମିତି କିଛି ପଢିପାରିବି ଯାହାଦ୍ୱାରା ମୁଁ ମୋ ଜ୍ଞାନ ବଳରେ \n ଏମାନଙ୍କୁ କିଛି ସାହାଯ୍ୟ କରି ପାରିବି।\"",
              "as": "যদি মই আৰু নপঢ়ো তেন্তে দেউতাৰ খুব খং উঠিব। কিন্তু বীজ বেংকৰ অভিলাষৰ দৰে, মইও কিবা এটা পঢ়িব পাৰোঁ য'ত মই তেওঁলোকক সহায় কৰিবলৈ জ্ঞান ব্যৱহাৰ কৰিব পাৰোঁ।",
              "gu": "“જો હું આગળ નહીં ભણું તો બાબુજીને વધારે ગુસ્સો આવશે. પરંતુ બીજ બેંકના અભિલાષની જેમ, હું પણ કંઈક અભ્યાસ કરી શકું છું જ્યાં હું તેમને મદદ કરવા માટે જ્ઞાનનો ઉપયોગ કરી શકું.\""
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_2_5_D",
              "hi": "X4_2_5_d",
              "ka": "X4_2_5_D",
              "te": "X4_2_5_D",
              "or": "X4_2_5_D",
              "as": "X4_2_5_D",
              "gu": "X4_2_5_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Shikhar decide?",
              "hi": "शिखर को क्या निर्णय लेना चाहिए?",
              "ka": "ಶಿಖರ್ ಏನು ನಿರ್ಧಾರ ತೆಗೆದುಕೊಳ್ಳಬೇಕು?",
              "te": "శిఖర్ ఏం నిర్ణయం తీసుకోవాలి?",
              "or": "ଶିଖର କଣ ନିଷ୍ପତ୍ତି ନେବା?",
              "as": "শিখৰে কি সিদ্ধান্ত ল'ব লাগে?",
              "gu": "શિખરે શું નક્કી કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Discontinue studies after 12th and help the family. He might find something later",
              "hi": "बारहवीं के बाद अध्ययन बंद करे और परिवार की सहायता करे। उसे बाद में कुछ मिल सकता है",
              "ka": "12 ನೇ ತರಗತಿಯ ನಂತರ ಓದುವುದನ್ನು ನಿಲ್ಲಿಸುವುದು ಮತ್ತು ಕುಟುಂಬಕ್ಕೆ ಸಹಾಯವನ್ನು ಮಾಡುವುದು. ನಂತರ ಆತನು ಏನನ್ನಾದರೂ ಕಂಡುಕೊಳ್ಳಬಹುದು.",
              "te": "12వ తరగతి తర్వాత చదువుకు స్వస్తి చెప్పి కుటుంబానికి సహాయం చేయాలి. అతను తర్వాత ఏదైనా కనుగొనవచ్చు",
              "or": "୧୨କକ୍ଷର ପରୀକ୍ଷା ପରେ ସେ ନିଜ ପରିବାରକୁ ସାହାଯ୍ୟ କରିବାକୁ ପାଠପଢା\n ରୋକିଦେବ |କିଛି ସମୟ ପରେ ଯଦି କିଛି କାମ ସେ ପାଇଯିବ।",
              "as": "দ্বাদশৰ পিছত পঢ়া বন্ধ কৰা আৰু পৰিয়ালটোক সহায় কৰা। সি পিছত কিবা বিচাৰি পাব পাৰে",
              "gu": "12મા પછી ભણવાનું બંધ કરો અને પરિવારને મદદ કરવી. તે પછીથી કંઈક મેળવી શકે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Go to Sujay to know more about further studies",
              "hi": "आगे के अध्ययन के बारे में अधिक जानने के लिए सुजय के पास जाए",
              "ka": "ಮುಂದಿನ ಅಧ್ಯಯನದ ಕುರಿತು ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಸುಜಯ್ ಬಳಿ ಹೋಗುವುದು",
              "te": "తదుపరి చదువుల గురించి మరింత తెలుసుకోవడానికి సుజయ్ వద్దకు వెళ్ళడం",
              "or": "ଆଗକୁ ପାଠ ପଢା ପାଇଁ ସୁଜୟ ନିକଟକୁ ଯାଅ|",
              "as": "আৰু পঢ়াৰ বিষয়ে অধিক জানিবলৈ সুজয়ৰ ওচৰলৈ যোৱা",
              "gu": "આગળના અભ્યાસ વિશે વધુ જાણવા માટે સુજય પાસે જાઓ"
            }
          }
        ]
      },
      "scene12": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Learn about further studies",
              "hi": "आगे के अध्ययन के बारे में जाने",
              "ka": "ಮುಂದಿನ ಓದುಗಳ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳುವುದು",
              "te": "తదుపరి చదువు గురించి తెలుసుకోవడం",
              "or": "ଭବିଷ୍ୟତ ର ପାଠପଢା ବାବଦରେ ଜାଣିବା",
              "as": "অধিক পঢ়াৰ বিষয়ে শিকা",
              "gu": "વધુ અભ્યાસ વિશે જાણો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar visits the college with his cousin Sujay. College feels very different from school. It is a huge campus with various departments.",
              "hi": "शिखर अपने चचेरे भाई सुजय के साथ कॉलेज का दौरा करता है। कॉलेज स्कूल से बहुत अलग लगता है। यह विभिन्न विभागों से युक्त एक विशाल परिसर है।",
              "ka": "ಶಿಖರ್ ತನ್ನ ಸೋದರ ಸಂಬಂಧಿ ಸುಜಯ್ ಜೊತೆ ಕಾಲೇಜಿಗೆ ಭೇಟಿ ನೀಡುತ್ತಾನೆ. ಕಾಲೇಜು ಶಾಲೆಗಿಂತ ತುಂಬಾ ಭಿನ್ನವಾಗಿದೆ. ಇದು ವಿವಿಧ ವಿಭಾಗಗಳನ್ನು ಹೊಂದಿರುವ ಬೃಹತ್ ಕ್ಯಾಂಪಸ್ ಆಗಿದೆ.",
              "te": "శిఖర్ తన బంధువు సుజయ్‌ తో కలిసి కళాశాలకి వెళ్తాడు. కళాశాల పాఠశాల కంటే చాలా భిన్నంగా అనిపిస్తుంది. ఇది వివిధ విభాగాలతో కూడిన భారీ ప్రాంగణం.",
              "or": "ଶିଖର ନିଜ ଭାଇ ସୁଜୟ ସହିତ କଲେଜ ବୁଲିବା ପାଇଁ ଗଲା। ବିଦ୍ୟାଳୟ ଠାରୁ\n ମହାବିଦ୍ୟାଳୟ ବହୁତ ଅଲଗା ଥିଲା। ଏଇଠି ବହୁତ ପ୍ରକାରର ବିଭାଗ ସହିତ \n ଏକ ବଡ ପରିସର ଥିଲା।",
              "as": "শিখৰে তাৰ সম্পৰ্কীয় ককায়েক সুজয়ৰ সৈতে কলেজলৈ যায়। কলেজখন স্কুলতকৈ বহুত বেলেগ অনুভৱ কৰে। এইটো বিভিন্ন বিভাগৰ সৈতে এটা বিশাল চৌহদ।",
              "gu": "શિખર તેના પિતરાઈ ભાઈ સુજય સાથે કૉલેજની મુલાકાત લે છે. કૉલેજ શાળા કરતાં ખૂબ જ અલગ લાગે છે. તે વિવિધ વિભાગો સાથેનું વિશાળ કેમ્પસ છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_1_2",
              "hi": "A1_1_2",
              "ka": "A1_1_2",
              "te": "A1_1_2",
              "or": "A1_1_2",
              "as": "A1_1_2",
              "gu": "A1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Engineering has more departments than he knew, like bioengineering, automobile engineering, and chemical engineering.",
              "hi": " जितना वह जानता था उससे कहीं अधिक इंजीनियरिंग के  विभाग हैं, जैव इंजीनियरिंग, ऑटोमोबाइल इंजीनियरिंग, और केमिकल इंजीनियरिंग आदि।",
              "ka": "ಇಂಜಿನಿಯರಿಂಗ್ ಅವನು ತಿಳಿದಿರುವುದಕ್ಕಿಂತ ಹೆಚ್ಚು ಅಂದರೆ ಬಯೋ ಇಂಜಿನಿಯರಿಂಗ್, ಆಟೋಮೊಬೈಲ್ ಇಂಜಿನಿಯರಿಂಗ್ ಮತ್ತು ಕೆಮಿಕಲ್ ಇಂಜಿನಿಯರಿಂಗ್ ನಂತಹ ಹೆಚ್ಚು ವಿಭಾಗಗಳನ್ನು ಹೊಂದಿದೆ.",
              "te": "ఇంజనీరింగ్‌ లో బయో ఇంజినీరింగ్, ఆటోమొబైల్ ఇంజనీరింగ్ మరియు కెమికల్ ఇంజనీరింగ్ వంటి అతనికి తెలిసిన దానికంటే ఎక్కువ విభాగాలు ఉన్నాయి.",
              "or": "ସେ ଜାଣିଥିବା ଠାରୁ ବହୁତ ଅଧିକ ବିଭାଗ ଇଞ୍ଜିନିଅରିଂ ରେ ଥିଲା, ଯେମିତିକି ବୟୋ\n ଇଂଜିନିଯରିଂ,ଅଟୋମୋବଇଲ ଇଞ୍ଜିନିଅରିଂ,ଏବଂ କେମିକାଲ ଇଞ୍ଜିନିଅରିଂ।",
              "as": "ইঞ্জিনিয়াৰিঙত সি জনাতকৈ অধিক বিভাগ আছে, যেনে জৈৱ ইঞ্জিনিয়াৰিং, অটোমোবাইল ইঞ্জিনিয়াৰিং, আৰু ৰাসায়নিক ইঞ্জিনিয়াৰিং।",
              "gu": "એન્જિનિયરિંગમાં બાયોએન્જિનિયરિંગ, ઓટોમોબાઈલ એન્જિનિયરિંગ અને કેમિકલ એન્જિનિયરિંગ જેવા તેના કરતાં વધુ વિભાગો છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_1_3",
              "hi": "A1_1_3",
              "ka": "A1_1_3",
              "te": "A1_1_3",
              "or": "A1_1_3",
              "as": "A1_1_3",
              "gu": "A1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Sujay is studying mechanical engineering. Each department has a laboratory.",
              "hi": "सुजय मैकेनिकल इंजीनियरिंग का अध्ययन कर रहा है। प्रत्येक विभाग में एक प्रयोगशाला होती है।",
              "ka": "ಸುಜಯ್ ಮೆಕ್ಯಾನಿಕಲ್ ಇಂಜಿನಿಯರಿಂಗ್ ಓದುತ್ತಿದ್ದಾನೆ. ಪ್ರತಿ ಇಲಾಖೆಗೂ ಒಂದು ಪ್ರಯೋಗಾಲಯವಿದೆ",
              "te": "సుజయ్ మెకానికల్ ఇంజినీరింగ్ చదువుతున్నాడు. ప్రతి విభాగానికి ఒక ప్రయోగశాల ఉంది.",
              "or": "ସୁଜୟ ମେକାନିକାଲ ଇଞ୍ଜିନିଅରିଂ ପଢେ। ସବୁ ବିଭାଗ ରେ ଗୋଟିଏ ଲେଖା \n ପରୀକ୍ଷାଗାର ଅଛି।",
              "as": "সুজয়ে মেকানিকেল ইঞ্জিনিয়াৰিং পঢ়ি আছে। প্ৰতিটো বিভাগৰ এটা পৰীক্ষাগাৰ আছে।",
              "gu": "સુજય મિકેનિકલ એન્જિનિયરિંગનો અભ્યાસ કરે છે. દરેક વિભાગમાં લેબોરેટરી છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_1_4",
              "hi": "A1_1_4",
              "ka": "A1_1_4",
              "te": "A1_1_4",
              "or": "A1_1_4",
              "as": "A1_1_4",
              "gu": "A1_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Sujay takes him through the curriculum, but there is nothing in it that excites Shikhar.",
              "hi": "सुजय उसे पाठ्यक्रम के विषय में बताता है, लेकिन इसमें कुछ भी ऐसा नहीं है जो शिखर को उत्तेजित करता है।",
              "ka": "ಸುಜಯ್ ಅವನಿಗೆ ಪಠ್ಯಕ್ರಮದ ಬಗ್ಗೆ ತಿಳಿಸುತ್ತಾನೆ, ಆದರೆ ಅದರಲ್ಲಿ ಶಿಖರಾಣಿಗೆ ಆಸಕ್ತಿ ಹುಟ್ಟಿಸುವುದು ಯಾವುದೂ ಇರುವುದಿಲ್ಲ.",
              "te": "సుజయ్ అతనికి పాఠ్యాంశాలను చూపిస్తాడు, కానీ శిఖర్‌ కి అవేవీ నచ్చవు.",
              "or": "ସୁଜୟ ଶିଖର କୁ ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ବୁଝାଇଲା କିନ୍ତୁ ତାହା ଶିଖର କୁ ସେମିତି \n କିଛି ପ୍ରଭାବ ପକାଇଲା ନହିଁ ।",
              "as": "সুজয়ে তাক পাঠ্যক্ৰমৰ মাজলৈ লৈ যায়, কিন্তু ইয়াত শিখৰক আগ্ৰহী কৰা একো নাই।",
              "gu": "સુજય તેને અભ્યાસક્રમ દ્વારા લઈ જાય છે, પરંતુ તેમાં એવું કંઈ નથી જે શિખરને ઉત્તેજિત કરે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_2_1",
              "hi": "A1_2_1",
              "ka": "A1_2_1",
              "te": "A1_2_1",
              "or": "A1_2_1",
              "as": "A1_2_1",
              "gu": "A1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Sujay also takes him to his coaching centre. There Shikhar sees classrooms filled with students and is shocked.",
              "hi": "सुजय उसे अपने कोचिंग केंद्र में  भी ले जाता है। वहां शिखर छात्रों से भरी कक्षाओं को देखकर हैरान है।",
              "ka": "ಸುಜಯ್  ಅವನನ್ನು ತನ್ನ ಕೋಚಿಂಗ್ ಸೆಂಟರ್‌ಗೆ ಕೂಡಾ ಕರೆದುಕೊಂಡು ಹೋಗುತ್ತಾನೆ. ಅಲ್ಲಿ ಶಿಖರ್ ವಿದ್ಯಾರ್ಥಿಗಳಿಂದ ತುಂಬಿರುವ ತರಗತಿಗಳನ್ನು ನೋಡಿ ಆಘಾತಕ್ಕೊಳಗಾಗುತ್ತಾನೆ.",
              "te": "సుజయ్ అతనని తన కోచింగ్ సెంటర్‌ కి కూడా తీసుకెళ్తాడు. అక్కడ విద్యార్థులతో నిండిన తరగతి గదులను శిఖర్ చూసి షాక్ అయ్యాడు.",
              "or": "କୋଚିଂ ସେଣ୍ଟର କୁ ମଧ୍ୟ ସୁଜୟ ଶିଖର କୁ ନେଇଗଲା। ସେଠାରେ ଶିଖର ଶ୍ରେଣୀ\n କକ୍ଷରେ ପିଲାମାନେ ଭରପୁର ଥିଲେ ଏବଂ ସେ ଏହାକୁ ଦେଖି ଆଶ୍ଚର୍ଯ୍ୟ ହୋଇଗଲା।",
              "as": "সুজয়ে তাক তাৰ প্ৰশিক্ষণ কেন্দ্ৰলৈও লৈ যায়। তাত শিখৰে শিক্ষাৰ্থীৰে ভৰ্তি শ্ৰেণীকোঠাবোৰ দেখিছিল আৰু হতবাক হৈছিল।",
              "gu": "સુજય તેને તેના કોચિંગ સેન્ટરમાં પણ લઈ જાય છે. ત્યાં શિખર વિદ્યાર્થીઓથી ભરેલા વર્ગખંડો જુએ છે અને ચોંકી જાય છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_2_2//",
              "hi": "A1_2_2 //",
              "ka": "A1_2_2//",
              "te": "A1_2_2//",
              "or": "A1_2_2//",
              "as": "A1_2_2//",
              "gu": "A1_2_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Sujay",
              "hi": "सुजय ",
              "ka": "ಸುಜಯ್",
              "te": "సుజయ్",
              "or": "ସୁଜୟ",
              "as": "সুজয়",
              "gu": "સુજય"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "There are so many students here!",
              "hi": "यहां बहुत सारे छात्र हैं!",
              "ka": "ಇಲ್ಲಿ ಎಷ್ಟೆಲ್ಲಾ ವಿದ್ಯಾರ್ಥಿಗಳಿದ್ದಾರೆ!",
              "te": "ఇక్కడ చాలా మంది విద్యార్థులు ఉన్నారు!",
              "or": "ଏଠାରେ କେତେ ସାରା ଛାତ୍ରଛାତ୍ରୀ !",
              "as": "ইয়াত বহুতো শিক্ষাৰ্থী আছে!",
              "gu": "અહીં ઘણા વિદ્યાર્થીઓ છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Yes, this year, it seems more students are joining after they have gotten excellent results in the last few years.",
              "hi": "हां, इस साल, ऐसा लगता है कि पिछले कुछ वर्षों में उत्कृष्ट परिणाम प्राप्त करने के बाद अधिक छात्र अध्ययन के लिए आ रहे हैं।",
              "ka": "ಹೌದು, ಈ ವರ್ಷ, ಕಳೆದ ಕೆಲವು ವರ್ಷಗಳಲ್ಲಿ ಅತ್ಯುತ್ತಮ ಫಲಿತಾಂಶಗಳನ್ನು ಪಡೆದ ನಂತರ ಹೆಚ್ಚಿನ ವಿದ್ಯಾರ್ಥಿಗಳು ಸೇರುತ್ತಿದ್ದಾರೆಂದು ತೋರುತ್ತದೆ.",
              "te": "అవును, ఈ సంవత్సరం, గత కొన్ని సంవత్సరాలలో అద్భుతమైన ఫలితాలు సాధించిన తర్వాత ఎక్కువ మంది విద్యార్థులు చేరినట్లు తెలుస్తోంది.",
              "or": "ହଁ, ଏହି ବର୍ଷ ,ପୂର୍ବ କିଛି ବର୍ଷରେ ଭଲ ଫଳାଫଳ ରଖି ଲାଗୁଛି ବହୁତ ସାରା\n  ଛାତ୍ରଛାତ୍ରୀ ଯୋଗଦାନ କରିଛନ୍ତି।",
              "as": "হয়, এই বছৰ, যোৱা কেইবছৰমানত উৎকৃষ্ট ফলাফল পোৱাৰ পিছত অধিক শিক্ষাৰ্থীয়ে যোগদান কৰা দেখা গৈছে।",
              "gu": "હા, આ વર્ષે, એવું લાગે છે કે છેલ્લાં કેટલાંક વર્ષોમાં ઉત્તમ પરિણામો મેળવ્યા બાદ વધુ વિદ્યાર્થીઓ જોડાઈ રહ્યાં છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_2_3////",
              "hi": "A1_2_3 ////",
              "ka": "A1_2_3////",
              "te": "A1_2_3////",
              "or": "A1_2_3////",
              "as": "A1_2_3////",
              "gu": "A1_2_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "That means more people will become engineers.",
              "hi": "इसका अर्थ है कि बहुत सारे इंजीनियर बन जाएंगे।",
              "ka": "ಅಂದರೆ ಹೆಚ್ಚು ಜನರು ಇಂಜಿನಿಯರ್ ಆಗುತ್ತಾರೆ.",
              "te": "అంటే ఎక్కువ మంది ఇంజనీర్లు అవుతారు.",
              "or": "ତା ଅର୍ଥ ବହୁତ ପିଲା ଇଞ୍ଜିନିଅର ହେବେ।",
              "as": "ইয়াৰ অৰ্থ হৈছে অধিক লোক অভিযন্তা হ'ব।",
              "gu": "એટલે કે વધુ લોકો એન્જિનિયર બનશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Haha, the seats are the same. There is more competition. That means more pressure this year for all students. So they are taking double the number of classes.",
              "hi": "हाहा, सीटें उतनी ही हैं। अधिक प्रतिस्पर्धा है। इसका अर्थ यह है कि सभी छात्रों के लिए इस साल अधिक दबाव है । इसलिए वे कक्षाओं की संख्या को दोगुना कर रहे हैं।",
              "ka": "ಹಾ ಹಾ, ಲಭ್ಯವಿರುವ ಸೀಟುಗಳು ಒಂದೇ ಆಗಿವೆ. ಹೆಚ್ಚು ಪೈಪೋಟಿ ಇದೆ. ಅಂದರೆ ಎಲ್ಲಾ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಈ ವರ್ಷ ಹೆಚ್ಚಿನ ಒತ್ತಡ ಇರುತ್ತದೆ. ಹಾಗಾಗಿ ಅವರು ಎರಡು ಪಟ್ಟು ತರಗತಿಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಿದ್ದಾರೆ.",
              "te": "హహ, సీట్లు అలాగే ఉన్నాయి. పోటీ ఎక్కువ ఉంది. అంటే విద్యార్థులందరికీ ఈ ఏడాది ఒత్తిడి ఎక్కువ. అందుకే రెట్టింపు సంఖ్యలో తరగతులు తీసుకుంటున్నారు.",
              "or": "ହାହା , ସିଟ କିନ୍ତୁ ସେତିକି। ଏଠାରେ ବହୁତ ପ୍ରତିଯୋଗୀତା। କହିବାର ଅର୍ଥ ଏହି ବର୍ଷ \n ପିଲାଙ୍କ ପାଇଁ ବହୁତ ପ୍ରେସର ରହିବା। ତେଣୁ ସେମାନେ ଦୁଇ ଥର ଲେଖାଏ \n କ୍ଲାସ ନଉଛନ୍ତି।",
              "as": "হাহা, আসনবোৰ একে। অধিক প্ৰতিযোগিতা আছে। ইয়াৰ অৰ্থ হৈছে এই বছৰ সকলো শিক্ষাৰ্থীৰ বাবে অধিক চাপ। গতিকে তেওঁলোকে দুগুণ ক্লাচ লৈছে।",
              "gu": "હાહા, બેઠકો સમાન છે. વધુ સ્પર્ધા છે. એટલે કે આ વર્ષે તમામ વિદ્યાર્થીઓ માટે વધુ દબાણ છે. તેથી તેઓ બમણા વર્ગો લઈ રહ્યા છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_2_4",
              "hi": "A1_2_4",
              "ka": "A1_2_4",
              "te": "A1_2_4",
              "or": "A1_2_4",
              "as": "A1_2_4",
              "gu": "A1_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar is shocked to see the number of students in the college and tuition centres. It makes him feel overwhelmed with pressure.",
              "hi": "कॉलेज और ट्यूशन केंद्र में छात्रों की संख्या देखकर शिखर चौंक जाता है। इस दबाव के कारण वह व्याकुलता का अनुभव करता है।",
              "ka": "ಕಾಲೇಜು ಮತ್ತು ಟ್ಯೂಷನ್ ಸೆಂಟರ್ ಗಳಲ್ಲಿ ಇರುವ ವಿದ್ಯಾರ್ಥಿಗಳ ಸಂಖ್ಯೆಯನ್ನು  ನೋಡಿ ಶಿಖರ್ ಬೆಚ್ಚಿಬಿದ್ದಿದ್ದಾನೆ. ಇದು ಅವನಿಗೆ ಸಾಕಷ್ಟು ಒತ್ತಡವನ್ನು ತುಂಬುತ್ತದೆ.",
              "te": "కళాశాల, ట్యూషన్ సెంటర్లలో విద్యార్థుల సంఖ్య చూసి శిఖర్ షాక్ అయ్యాడు. ఇది అతనికి ఒత్తిడి పెరిగేలా చేస్తుంది.",
              "or": "ଶିଖର କଲେଜ ଓ ଟିଉଶନ ସେଣ୍ଟର ରେ ଏତେ ସାରା ପିଲାଙ୍କୁ ଦେଖି ଆଶ୍ଚର୍ଯ୍ୟ \n ହୋଇଗଲା। ଏହା ତାକୁ ଅତ୍ୟଧିକ ପ୍ରେସର ରେ ଅଭୁଭୂତ କରିଦେଲା।",
              "as": "কলেজ আৰু টিউচন চেন্টাৰত শিক্ষাৰ্থীৰ সংখ্যা দেখি শিখৰ হতবাক হৈ পৰিছে। এইটোৱে তাক চিন্তিত কৰি তুলিছে।",
              "gu": "કોલેજ અને ટ્યુશન સેન્ટરમાં વિદ્યાર્થીઓની સંખ્યા જોઈને શિખર ચોંકી જાય છે. તે તેને દબાણથી વધુ પડતો અનુભવ કરાવે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_1",
              "hi": "A1_3_1",
              "ka": "A1_3_1",
              "te": "A1_3_1",
              "or": "A1_3_1",
              "as": "A1_3_1",
              "gu": "A1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar also sees some videos online to know more about the courses and topics taught in engineering colleges.",
              "hi": "शिखर इंजीनियरिंग कॉलेजों में पढ़ाए जाने वाले कोर्स और विषयों के बारे में अधिक जानने के लिए ऑनलाइन कुछ वीडियो देखता है।",
              "ka": "ಎಂಜಿನಿಯರಿಂಗ್ ಕಾಲೇಜುಗಳಲ್ಲಿ ಕಲಿಸುವ ಕೋರ್ಸ್‌ಗಳು ಮತ್ತು ವಿಷಯಗಳ ಕುರಿತು ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಶಿಖರ್ ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಕೆಲವು ವೀಡಿಯೊಗಳನ್ನು ನೋಡುತ್ತಾನೆ.",
              "te": "ఇంజినీరింగ్ కళాశాలల్లో బోధించే కోర్సులు మరియు అంశాల గురించి మరింత తెలుసుకోవడానికి శిఖర్ ఆన్‌లైన్‌ లో కొన్ని వీడియోలను కూడా చూస్తాడు.",
              "or": "ଇଞ୍ଜିନିଅରିଂ କୋଲେଜ ରେ ପଢା ଯାଉଥିବା ବିଷୟବସ୍ତୁ ବିଷୟରେ ଅଧିକ ଜାଣିବା ପାଇଁ \n ଅନଲାଇନରେ ଶିଖର କିଛି ଭିଡିଓ ଦେଖିଲା।",
              "as": "শিখৰে ইঞ্জিনিয়াৰিং কলেজসমূহত শিকোৱা পাঠ্যক্ৰম আৰু বিষয়বোৰৰ বিষয়ে অধিক জানিবলৈ কিছুমান ভিডিঅ' অনলাইনত চায়।",
              "gu": "શિખર એન્જિનિયરિંગ કોલેજોમાં ભણાવવામાં આવતા અભ્યાસક્રમો અને વિષયો વિશે વધુ જાણવા માટે ઑનલાઇન કેટલાક વીડિયો પણ જુએ છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_2//",
              "hi": "A1_3_2 //",
              "ka": "A1_3_2//",
              "te": "A1_3_2//",
              "or": "A1_3_2//",
              "as": "A1_3_2//",
              "gu": "A1_3_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Sujay",
              "hi": "सुजय",
              "ka": "ಸುಜಯ್",
              "te": "సుజయ్",
              "or": "ସୁଜୟ",
              "as": "সুজয়",
              "gu": "સુજય"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Bhaiya, I usually feel excited to learn new things, but engineering feels like much study and work. Also, there is too much competition.",
              "hi": "भैया, मैं आमतौर पर नई चीजों को सीखने के लिए उत्साहित महसूस करता हूं, लेकिन लगता है कि इंजीनियरिंग में  बहुत अधिक अध्ययन और बहुत सारा काम है । इसके अलावा, इसमें बहुत अधिक प्रतिस्पर्धा है।",
              "ka": "ಭೈಯ್ಯಾ, ನಾನು ಸಾಮಾನ್ಯವಾಗಿ ಹೊಸ ವಿಷಯಗಳನ್ನು ಕಲಿಯಲು ಉತ್ಸುಕನಾಗಿದ್ದೇನೆ, ಆದರೆ ಇಂಜಿನಿಯರಿಂಗ್ ಹೆಚ್ಚು ಓದುವುದು ಮತ್ತು ಪ್ರಯತ್ನ ಪಡಬೇಕಾದುದು ಎಂದು ಭಾಸವಾಗುತ್ತದೆ. ಅಲ್ಲದೆ, ತುಂಬಾ ಸ್ಪರ್ಧೆ ಇರುವಂತೆ ಕಾಣುತ್ತದೆ.",
              "te": "అన్నయ్య, నేను సాధారణంగా కొత్త విషయాలు నేర్చుకోవడానికి ఉత్సాహంగా ఉంటాను, కానీ ఇంజినీరింగ్ అంటే ఎక్కువ చదవాలి మరియు పెద్ద పనిలా అనిపిస్తుంది. అలాగే పోటీ కూడా ఎక్కువే.",
              "or": "ଭାଇ, ମୁ ସବୁବେଳେ ନୂଆ ଜିନିଷ ଶିଖିବାରେ ରୋମାଞ୍ଚ ଅନୁଭବ କରେ, କିନ୍ତୁ \n ଇଞ୍ଜିନିଅରିଂ ରେ ବହୁତ ପାଠ ଆଉ କାମ ମଧ୍ୟ। ଏବଂ ଏଠାରେ ବହୁତ ପ୍ରତିଯୋଗୀତା ମଧ୍ୟ।",
              "as": "দাদা, মই সাধাৰণতে নতুন বস্তু শিকিবলৈ ভালপাওঁ, কিন্তু ইঞ্জিনিয়াৰিং যথেষ্ট পঢ়াশুনা আৰু কামৰ দৰে লাগে। লগতে, অত্যাধিক প্ৰতিযোগিতা আছে।",
              "gu": "ભાઈ, હું સામાન્ય રીતે નવી વસ્તુઓ શીખવા માટે ઉત્સાહિત અનુભવું છું, પરંતુ એન્જિનિયરિંગમાં ખૂબ અભ્યાસ અને કામ જેવું લાગે છે. ઉપરાંત, ખૂબ સ્પર્ધા છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "If you think engineering is not for you, there are other courses that you can do. With your choice of subjects in 11th, you have a chance to choose courses either in science or commerce.",
              "hi": "यदि तुमको लगता है कि इंजीनियरिंग तुम्हारे लिए नहीं है, तो ऐसे अन्य कोर्स हैं जो तुम अध्ययन कर सकते हो। ग्यारहवीं  में विषयों की अपनी पसंद के साथ, तुम्हारे पास विज्ञान या वाणिज्य में कोर्स चुनने का मौका है।",
              "ka": "ಎಂಜಿನಿಯರಿಂಗ್ ನಿನಗಾಗಿ ಅಲ್ಲ ಎಂದು ನೀನು ಭಾವಿಸಿದಲ್ಲಿ, ನೀನು ಮಾಡಬಹುದಾದ ಇತರ ಕೋರ್ಸ್‌ಗಳಿವೆ. 11 ನೇ ತರಗತಿಯಲ್ಲಿ ನಿನ್ನ ವಿಷಯಗಳ ಆಯ್ಕೆಯೊಂದಿಗೆ, ವಿಜ್ಞಾನ ಅಥವಾ ವಾಣಿಜ್ಯ ಕೋರ್ಸ್‌ಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ನಿನಗೆ ಅವಕಾಶವಿದೆ.",
              "te": "ఇంజినీరింగ్ నీ కోసం కాదని నువ్వు అనుకుంటే, నువ్వు చేయగల ఇతర కోర్సులు ఉన్నాయి. 11వ తరగతిలో నువ్వు ఎంచుకున్న సబ్జెక్టులతో, నువ్వు సైన్స్ లేదా కామర్స్ లో కోర్సులను ఎంచుకునే అవకాశం ఉంది.",
              "or": "ଯଦି ତୁମେ ଭାବୁଛ ଇଞ୍ଜିନିଅରିଂ ତୁମ ପାଇଁ ନୁହେଁ , ତେବେ ଏଠାରେ ଅଲଗା ବିଷୟବସ୍ତୁ\n ମଧ୍ୟ ଅଛି ଯାହାକୁ ତୁମେ କରିପାରିବ। ୧୧ ଶ୍ରେଣୀରେ ତୁମେ ରଖିଥିବା ବିଷୟ ବସ୍ତୁ ଆନୁସରେ \n ତୁମ ପକ୍ଷେ ଏବେ ମଧ୍ୟ ବିଜ୍ଞାନ କିମ୍ବା ବାଣିଜ୍ୟ ନେବାର ସୁଯୋଗ ଅଛି।",
              "as": "যদি তুমি ভাবা যে ইঞ্জিনিয়াৰিং তোমাৰ বাবে নহয়, তুমি কৰিব পৰা আন কিছুমান পাঠ্যক্ৰম আছে। একাদশত তোমাৰ বিষয় বাছনি কৰাৰ সৈতে, তোমাৰ বিজ্ঞান বা বাণিজ্যৰ পাঠ্যক্ৰম বাছনি কৰাৰ সুযোগ আছে।",
              "gu": "જો તને લાગે કે એન્જિનિયરિંગ તમારા માટે નથી, તો અન્ય કોર્સ છે જે તું કરી શકે છો. 11માં વિષયોની તારી પસંદગી સાથે, તારી પાસે વિજ્ઞાન અથવા વાણિજ્યમાં કોર્સ પસંદ કરવાની તક છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_3////",
              "hi": "A1_3_3 /////",
              "ka": "A1_3_3////",
              "te": "A1_3_3////",
              "or": "A1_3_3////",
              "as": "A1_3_3////",
              "gu": "A1_3_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Bhaiya, but Babuji thinks that only engineering is the best. He believes that is the only way for me to get a well-paying job.",
              "hi": "भैया, लेकिन बाबूजी सोचते हैं कि केवल इंजीनियरिंग करना ही सबसे अच्छा है। उनका मानना ​​है कि यह मेरे लिए अच्छा वेतन पाने का एकमात्र रास्ता है।",
              "ka": "ಭೈಯ್ಯಾ, ಆದರೆ ಇಂಜಿನಿಯರಿಂಗ್ ಮಾತ್ರ ಉತ್ತಮ ಎಂದು ಬಾಬೂಜಿ ಭಾವಿಸುತ್ತಾರೆ. ನನಗೆ ಒಳ್ಳೆಯ ಸಂಬಳದ ಕೆಲಸ ಸಿಗಬೇಕಾದರೆ ಅದೊಂದೇ ದಾರಿ ಎಂದು ಅವರು ನಂಬಿದ್ದಾರೆ.",
              "te": "అన్నయ్య, కానీ నాన్నగారు మాత్రం ఇంజినీరింగ్ మాత్రమే మంచిది అనుకుంటాడు. నాకు మంచి జీతం వచ్చే ఉద్యోగం రావాలంటే అదొక్కటే మార్గం అని అతను నమ్ముతాడు.",
              "or": "ଭାଇ, ବାପା ଭାବୁଛନ୍ତି କେବଳ ଇଞ୍ଜିନିଅରିଂ ହି ଠିକ। ମୋ ପାଇଁ ଏକ ଭଲ ରୋଜଗାର\n ଚାକିରି ଭାବେ ସେ ଏହାକୁ ହିନ ବିଶ୍ବାସ କରନ୍ତି।",
              "as": "দাদা, কিন্তু দেউতাই ভাবে যে কেৱল ইঞ্জিনিয়াৰিঙেই সৰ্বশ্ৰেষ্ঠ। তেওঁ বিশ্বাস কৰে যে মোৰ বাবে ভাল বেতনৰ চাকৰি পোৱাৰ একমাত্ৰ উপায়।",
              "gu": "ભાઈ, પણ બાબુજીને લાગે છે કે એન્જિનિયરિંગ જ શ્રેષ્ઠ છે. તે માને છે કે મારા માટે સારા પગારવાળી નોકરી મેળવવાનો આ એકમાત્ર રસ્તો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Hmm. I can understand why he feels that. Engineering is a good field to study, but people don’t know there are other fields that are as good.",
              "hi": "हम्म। मैं समझ सकता हूं कि वह ऐसा क्यों महसूस करते हैं। अध्ययन करने के लिए इंजीनियरिंग एक अच्छा क्षेत्र है, लेकिन लोगों को नहीं पता कि ऐसे अन्य क्षेत्र हैं जो इतने ही अच्छे हैं।",
              "ka": "ಹ್ಮ್ಮ. ಅವರು ಯಾಕೆ ಹಾಗೆ ಭಾವಿಸುತ್ತಾರೆಂದು ನನಗೆ ಅರ್ಥವಾಗುತ್ತದೆ. ಇಂಜಿನಿಯರಿಂಗ್ ಓದಲು ಉತ್ತಮ ಕ್ಷೇತ್ರವಾಗಿದೆ, ಆದರೆ ಉತ್ತಮವಾದ ಇತರ ಕ್ಷೇತ್ರಗಳಿವೆ ಎಂದು ಜನರಿಗೆ ತಿಳಿದಿಲ್ಲ.",
              "te": "హ్మ్. అతను అలా ఎందుకు భావిస్తున్నాడో నేను అర్థం చేసుకోగలను. ఇంజినీరింగ్ చదవడానికి మంచి రంగం, కానీ ఇంకా మంచి రంగాలు కూడా ఉన్నాయని ప్రజలకు తెలియదు.",
              "or": "ହଁ, ମୁଁ ବୁଝିପାରୁଛି ସେ କାହିଁକି ଏମିତି ଭାବୁଛନ୍ତି। ଇଞ୍ଜିନିଅରିଂ ଏକ ଭଲ ଭିତ୍ତିଭୂମି \n କିନ୍ତୁ ଲୋକମାନେ ଏହା ଜନାନ୍ତିନୀ ଯେ ଆହୁରି ମଧ୍ୟ ଅଲଗା ଭିତ୍ତିଭୂମି ଅଛି ଯାହାକି \n ଏହା ତୁଳନାରେ ବହୁତ ଭଲ।",
              "as": "হুম। মই বুজি পাওঁ তেওঁ কিয় এনে ভাবে। ইঞ্জিনিয়াৰিং পঢ়া-শুনাৰ বাবে এক ভাল ক্ষেত্ৰ, কিন্তু মানুহে নাজানে আন কিছুমান ক্ষেত্ৰ আছে যিবোৰ সমানে ভাল।",
              "gu": "હમ. હું સમજી શકું છું કે તેને એવું કેમ લાગે છે. એન્જિનિયરિંગ એ ભણવા માટે સારું ક્ષેત્ર છે, પરંતુ લોકો જાણતા નથી કે અન્ય વિભાગો પણ એટલા સારા છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "What other fields would be as good as engineering? Anyway, I think Babuji has set his mind on engineering. I don’t know how much I can convince him of something else.",
              "hi": " अन्य दूसरे क्षेत्र कौन से हैं, जो इंजीनियरिंग के समान ही अच्छे होंगे? वैसे भी, मुझे लगता है कि बाबूजी ने इंजीनियरिंग पर अपना मन बना लिया है। मुझे नहीं पता कि मैं उन्हें कुछ और के लिए समझा सकता हूं।",
              "ka": "ಎಂಜಿನಿಯರಿಂಗ್‌ನಷ್ಟು ಉತ್ತಮವಾದ ಇತರ ಕ್ಷೇತ್ರಗಳು ಯಾವುವು? ಅದೇನೇ ಇರಲಿ, ಬಾಬೂಜಿ ಇಂಜಿನಿಯರಿಂಗ್ ಕಡೆ ಮನಸ್ಸು ಮಾಡಿದ್ದಾರೆ ಅಂತ ನನಗನ್ನಿಸುತ್ತದೆ. ಬೇರೆ ಯಾವುದನ್ನಾದರೂ ಆಗಲಿ ನಾನು ಅವರಿಗೆ ಯಾವ ರೀತಿಯಲ್ಲಿ ಮನವರಿಕೆ ಮಾಡಬಹುದೆಂದು ನನಗೆ ತಿಳಿದಿಲ್ಲ.",
              "te": "ఇంజినీరింగ్ కంటే ఏ రంగాలు బాగుంటాయి? ఏది ఏమైనా నాన్నగారు ఇంజినీరింగ్ అనే ఫిక్స్ అయ్యాడు అనుకుంటున్నాను. వేరేదాని అతనిని ఎంతవరకు ఒప్పించగలనో నాకు తెలియదు.",
              "or": "ଇଞ୍ଜିନିଅରିଂ ଭଳି ଭଲ ଆଉ କେଉଁ ଭିତ୍ତିଭୂମି ଅଛି? ଯାହାବି ହାଉ, ମୁଁ ଭାବୁଛି \n ବାପା ଇଞ୍ଜିନିଅରିଂ ପାଇଁ ମନସ୍ଥ କରିସାରିଲେଣି। ମୁଁ ଯାନୀ ନାହି କେତେ ମାତ୍ରାରେ \n ତାଙ୍କୁ ମୁଁ ଅଲଗା କୌଣସି ପାଇଁ ଭରସା ଦେଇପାରିବି।",
              "as": "ইঞ্জিনিয়াৰিঙৰ দৰে আন কোনবোৰ ক্ষেত্ৰ ভাল হ'ব? যি কি নহওক, মই ভাবো দেউতাই ইঞ্জিনিয়াৰিঙৰ ওপৰত মন স্থিৰ কৰিছে। মই নাজানো মই তেওঁক আন কিবা এটাৰ বিষয়ে কিমান সৈমান কৰিব পাৰোঁ।",
              "gu": "એન્જિનિયરિંગ જેટલું સારું બીજું કયું ક્ષેત્ર હશે? કોઈપણ રીતે, મને લાગે છે કે બાબુજીએ તેમનું મન એન્જિનિયરિંગ પર નક્કી કર્યું છે. મને ખબર નથી કે હું તેને બીજી કોઈ વસ્તુમાં કેટલું મનાવી શકીશ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "There’s an open house happening next week at my college. There you’ll get an opportunity to visit the campus and talk to professors and students to know more about the courses. Why don’t you stay a week longer here and attend it?",
              "hi": "मेरे कॉलेज में अगले सप्ताह ओपन हाउस/ खुला अधिवेशन हो रहा है। वहां तुमको परिसर में जाने और प्रोफेसरों तथा छात्रों से कोर्स के बारे में अधिक जानने का अवसर मिलेगा। तुम यहां एक सप्ताह तक रहकर इसमें भाग क्यों नहीं लेते?",
              "ka": "ನನ್ನ ಕಾಲೇಜಿನಲ್ಲಿ ಮುಂದಿನ ವಾರ ಓಪನ್ ಹೌಸ್ ಇದೆ. ಅಲ್ಲಿ ನೀನು ಕ್ಯಾಂಪಸ್‌ಗೆ ಭೇಟಿ ನೀಡಲು ಮತ್ತು ಕೋರ್ಸ್‌ಗಳ ಕುರಿತು ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಪ್ರಾಧ್ಯಾಪಕರು ಮತ್ತು ವಿದ್ಯಾರ್ಥಿಗಳೊಂದಿಗೆ ಮಾತನಾಡಲು ಅವಕಾಶವನ್ನು ಪಡೆಯುತ್ತೀಯಾ. ನೀನು ಏಕೆ ಇಲ್ಲಿ ಇನ್ನೂ ಒಂದು ವಾರ ಕಾಲ ಉಳಿಯಬಾರದು ಮತ್ತು ಅದಕ್ಕೆ ಹಾಜರಾಗಬಾರದು?",
              "te": "మా కళాశాలలో వచ్చే వారం బహిరంగ సభ జరుగుతోంది. అక్కడ నువ్వు ప్రాంగణం ని సందర్శించి, కోర్సుల గురించి మరింత తెలుసుకోవడానికి ప్రొఫెసర్‌లు మరియు విద్యార్థులతో మాట్లాడే అవకాశాన్ని పొందవచ్చు. నువ్వు ఇక్కడ ఇంకొక వారం ఉండి దానికి రావచ్చు కదా?",
              "or": "ମୋ କଲେଜରେ ଆସନ୍ତା ସପ୍ତାହ ରେ ଏକ ସାର୍ବଜନୀନ ପାଠ୍ୟଶଳା ହେବାକୁ ଯାଉଛି। ତୁମେ ସେଠାରେ ତୁମେ କ୍ୟାମ୍ପସ ବୁଲିବା ସହିତ ବିଭିର୍ଣ୍ଣ ପାଠ୍ୟକ୍ରମ ବାବଦରେ ବିଭିର୍ଣ୍ଣ ଅଧ୍ୟାପକ ଏବଂ ଛାତ୍ରଛାତ୍ରୀଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିପାରିବ। ତୁମେ ଏତିରେ ଯୋଗଦାନ କରିବା ପାଇଁ ଆଉ ଗୋଟେ ସପ୍ତାହ ଅଧିକ କାହିଁକି ରହୁନ?",
              "as": "অহা সপ্তাহত মোৰ কলেজত এখন মুকলি সভা হ'ব। তাত তুমি চৌহদলৈ যোৱাৰ আৰু পাঠ্যক্ৰমবোৰৰ বিষয়ে অধিক জানিবলৈ অধ্যাপক আৰু শিক্ষাৰ্থীসকলৰ সৈতে কথা পাতিবলৈ সুযোগ পাবা। তুমি ইয়াত আৰু এসপ্তাহ থাকি এইটোত উপস্থিত নাথাকা কিয়?",
              "gu": "મારી કોલેજમાં આવતા અઠવાડિયે ખુલ્લી ચર્ચા થવાની છે. ત્યાં તમને કેમ્પસની મુલાકાત લેવાની અને અભ્યાસક્રમો વિશે વધુ જાણવા માટે પ્રાધ્યાપકો અને વિદ્યાર્થીઓ સાથે વાત કરવાની તક મળશે. શા માટે તમે અહીં એક અઠવાડિયું રોકાઈને તેમાં હાજરી આપતા નથી?"
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_4_D",
              "hi": "A1_3_4_D",
              "ka": "A1_3_4_D",
              "te": "A1_3_4_D",
              "or": "A1_3_4_D",
              "as": "A1_3_4_D",
              "gu": "A1_3_4_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Shikhar do?",
              "hi": "शिखर को क्या करना चाहिए?",
              "ka": "ಶಿಖರ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "శిఖర్ ఏం చేయాలి?",
              "or": "ଶିଖର କଣ କରିବା ଉଚ୍ଚିତ?",
              "as": "শিখৰে কি কৰিব লাগে?",
              "gu": "શિખરે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Go back to the village and tell his family he will help on the farm as Babuji wants him to do engineering only",
              "hi": "गांव में वापस जाए और अपने परिवार को बताए कि वह खेती के काम में सहायता करेगा क्योंकि बाबूजी उसे केवल इंजीनियरिंग कराना चाहते हैं",
              "ka": "ಹಳ್ಳಿಗೆ ಹಿಂತಿರುಗುವುದು ಮತ್ತು ಬಾಬೂಜಿ ಅವರು ಕೇವಲ ಎಂಜಿನಿಯರಿಂಗ್ ಮಾಡಬೇಕೆಂದು ಬಯಸಿದ್ದರಿಂದ ಜಮೀನಿನಲ್ಲಿ ಸಹಾಯ ಮಾಡುವುದಾಗಿ ಅವರ ಕುಟುಂಬಕ್ಕೆ ತಿಳಿಸುವುದು.",
              "te": "నాన్నగారు ఇంజినీరింగ్ మాత్రమే చేయాలనుకుంటున్నందున గ్రామానికి తిరిగి వెళ్లి పొలంలో సహాయం చేస్తానని అతని కుటుంబానికి చెప్పడం",
              "or": "ଗାଁ କୁ ଫେରିଯାଇ ବାପଙ୍କୁ ତାଙ୍କ କ୍ଷେତ ରେ ସାହାଯ୍ୟ କରିବାକୁ କହିବ କି \n ଯେତେବେଳେ ସେ କେବଳ ଇଞ୍ଜିନିଅରିଂ କରିବାକୁ ବାପା ଚାହୁଛନ୍ତି।",
              "as": "গাঁৱলৈ উভতি যোৱা আৰু তাৰ পৰিয়ালক কোৱা যে সি খেতিপথাৰত সহায় কৰিব কিয়নো দেউতাকে তাক কেৱল ইঞ্জিনিয়াৰিং কৰাটো বিচাৰে",
              "gu": "ગામમાં પાછા જાઈને તેના પરિવારને કહેવું કે તે ખેતરમાં મદદ કરશે કારણ કે બાબુજી ઇચ્છે છે કે તે માત્ર એન્જિનિયરિંગ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Wait another week and attend the open house to explore other courses",
              "hi": "एक और सप्ताह प्रतीक्षा करे और अन्य कोर्स का पता लगाने के लिए ओपन हाउस में भाग ले",
              "ka": "ಇನ್ನೊಂದು ವಾರ ನಿರೀಕ್ಷಿಸುವುದು ಮತ್ತು ಇತರ ಕೋರ್ಸ್‌ಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಓಪನ್ ಹೌಸ್ ಗೆ ಹಾಜರಾಗುವುದು",
              "te": "ఇతర కోర్సులను అన్వేషించడానికి మరో వారం వేచి ఉండి బహిరంగ సభకు హాజరుకావడం",
              "or": "ଆଉ ଗୋଟିଏ ସପ୍ତାହ ଅପେକ୍ଷା କର ଏବଂ ଅଲଗା ସବୁ ପାଠ୍ୟକ୍ରମ କୁ ଜାଣିବା \n ପାଇଁ ସାର୍ବଜନୀନ ପାଠଶାଳା ଟିରେ ଯୋଗଦାନ କର।",
              "as": "আৰু এক সপ্তাহ ৰোৱা আৰু আন পাঠ্যক্ৰমৰ বিষয়ে জানিবলৈ মুকলি সভাত উপস্থিত থকা",
              "gu": "બીજા અઠવાડિયે રાહ જુઓ અને અન્ય અભ્યાસક્રમોનું અન્વેષણ કરવા માટે ઓપન હાઉસમાં હાજરી આપો"
            }
          }
        ]
      },
      "scene13": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Attend open house",
              "hi": "ओपन हाउस/ खुले अधिवेशन में भाग ले",
              "ka": "ಓಪನ್ ಹೌಸ್ ಗೆ ಹಾಜರಾಗುವುದು",
              "te": "బహిరంగ సభకు హాజరవడం",
              "or": "ସାର୍ବଜନୀନ ପାଠଶାଳା ରେ ଯୋଗଦାନ।",
              "as": "মুকলি সভাত উপস্থিত থকা",
              "gu": "ખુલ્લી ચર્ચામાં હાજરી આપો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar waits back with Sujay for another week and attends the open house at the university. He is excited to know what other courses he could take instead of engineering.",
              "hi": "शिखर एक और सप्ताह के लिए सुजय के साथ प्रतीक्षा करता है और विश्वविद्यालय में ओपन हाउस में भाग लेता है। वह यह जानने के लिए उत्साहित है कि इंजीनियरिंग के बजाय अन्य कौन सा कोर्स ले सकता है।",
              "ka": "ಶಿಖರ್ ಇನ್ನೊಂದು ವಾರ ಸುಜಯ್ ಜೊತೆ ಕಾಯುತ್ತಾನೆ ಮತ್ತು ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಓಪನ್ ಹೌಸ್ ಗೆ ಹಾಜರಾಗುತ್ತಾನೆ. ಅವನು ಇಂಜಿನಿಯರಿಂಗ್ ಬದಲಿಗೆ ಬೇರೆ ಯಾವ ಕೋರ್ಸ್‌ಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದೆಂದು ತಿಳಿಯಲು ಉತ್ಸುಕರಾಗಿದ್ದಾನೆ.",
              "te": "శిఖర్ సుజయ్‌ తో మరో వారం వేచి ఉండి విశ్వవిద్యాలయంలో జరిగే బహిరంగ సభకు హాజరయ్యాడు. అతను ఇంజినీరింగ్‌ కు బదులుగా ఏ ఇతర కోర్సులు తీసుకోవచ్చో తెలుసుకోవాలనే ఉత్సాహంతో ఉన్నాడు.",
              "or": "ଶିଖର ସୁଜୟ ସହିତ ଔ ଗୋଟିଏ ସପ୍ତାହ ଅପେକ୍ଷା କଲା ଏବଂ ମହାବିଦ୍ୟାଳୟ \n ରେ ପାଠଶାଳା ରେଯୋଗଦାନ କଲା। ଇଞ୍ଜିନିଅରିଂ ଛଡା ଅଲଗା କେଉଁ ବିଷୟ\n ସେ ନେଇପାରିବା, ସେହି ବିଷୟରେ ଜାଣିବା ପାଇଁ ସେ ଉଶ୍ଚାହିତ ଥିଲା।",
              "as": "শিখৰে সুজয়ৰ সৈতে আৰু এসপ্তাহ ৰ'ল আৰু বিশ্ববিদ্যালয়ৰ মুকলি সভাত উপস্থিত থাকিল। সি ইঞ্জিনিয়াৰিঙৰ বাহিৰে আন কি পাঠ্যক্ৰম ল'ব পাৰে জানিবলৈ আগ্ৰহী।",
              "gu": "શિખર સુજય સાથે બીજા અઠવાડિયા સુધી રાહ જુએ છે અને યુનિવર્સિટીના ખુલ્લી ચર્ચામાં જાય છે. તે એન્જિનિયરિંગને બદલે અન્ય કયા કોર્સ કરી શકે તે જાણવા માટે તે ઉત્સાહિત છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_2",
              "hi": "A2_1_2",
              "ka": "A2_1_2",
              "te": "A2_1_2",
              "or": "A2_1_2",
              "as": "A2_1_2",
              "gu": "A2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Some departments are organising talks and activities to make the event more useful. The university is full of activities as parents and school students have come to attend the open day.",
              "hi": "कुछ विभाग कार्यक्रम को और अधिक उपयोगी बनाने के लिए वार्ता और गतिविधियों का आयोजन कर रहे हैं। विश्वविद्यालय गतिविधियों से भरा है क्योंकि माता-पिता और स्कूल के छात्र ओपन दिवस में भाग लेने आए हैं।",
              "ka": "ಈವೆಂಟ್ ಅನ್ನು ಹೆಚ್ಚು ಉಪಯುಕ್ತವಾಗಿಸಲು ಕೆಲವು ಇಲಾಖೆಗಳು ಮಾತುಕತೆ ಮತ್ತು ಚಟುವಟಿಕೆಗಳನ್ನು ಆಯೋಜಿಸುತ್ತಿವೆ. ಓಪನ್ ಹೌಸಿನಲ್ಲಿ ಪಾಲ್ಗೊಳ್ಳಲು ಪೋಷಕರು ಮತ್ತು ಶಾಲಾ ವಿದ್ಯಾರ್ಥಿಗಳು ಬಂದಿದ್ದರಿಂದ ವಿಶ್ವವಿದ್ಯಾನಿಲಯವು ಚಟುವಟಿಕೆಗಳಿಂದ ತುಂಬಿದೆ.",
              "te": "కొన్ని విభాగాలు ఈవెంట్‌ను మరింత ఉపయోగకరంగా చేయడానికి చర్చలు మరియు కార్యకలాపాలను నిర్వహిస్తున్నాయి. తల్లిదండ్రులు మరియు పాఠశాల విద్యార్థులు ఓపెన్ డేకి హాజరయ్యేందుకు రావడంతో విశ్వవిద్యాలయం కార్యకలాపాలతో నిండిపోయింది.",
              "or": "କାର୍ଯ୍ୟକ୍ରମକୁ ଅଧିକ ଉପଯୋଗୀ କରିବା ପାଇଁ କିଛି ବିଭାଗ କାର୍ଯ୍ୟକଳାପ ଓ କଥୋପକଥନ \n ର ଆୟୋଜନ କରୁଥାନ୍ତି। ମହାବିଦ୍ୟାଳୟ ଏକଦୁମ ଭରପୁର ଲାଗୁଥିଲା ଯେହେତୁ ସବୁ ପିତାମାତା \n ଓ ଛାତ୍ରଛାତ୍ରୀମାନେ ଏହି ଦିନରେ ଯୋଗ ଦେବାକୁ ଆସିଥିଲେ।",
              "as": "কিছুমান বিভাগে অনুষ্ঠানটো অধিক উপযোগী কৰি তুলিবলৈ আলোচনা আৰু কাম-কাজৰ আয়োজন কৰিছে। বিশ্ববিদ্যালয়খন কামেৰে ভৰি আছে কিয়নো অভিভাৱক আৰু বিদ্যালয়ৰ শিক্ষাৰ্থীসকল মুকলি সভাত উপস্থিত আছে।",
              "gu": "કેટલાક વિભાગો ઇવેન્ટને વધુ ઉપયોગી બનાવવા માટે વાર્તાલાપ અને પ્રવૃત્તિઓનું આયોજન કરી રહ્યા છે. વાલીઓ અને શાળાના વિદ્યાર્થીઓ ઓપન ડેમાં હાજરી આપવા આવ્યા હોવાથી યુનિવર્સિટી પ્રવૃત્તિઓથી ભરેલી છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_3",
              "hi": "A2_1_3",
              "ka": "A2_1_3",
              "te": "A2_1_3",
              "or": "A2_1_3",
              "as": "A2_1_3",
              "gu": "A2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Various departments of the college have set up rooms where the students and teachers of that department meet the visitors to answer their questions.",
              "hi": "कॉलेज के विभिन्न विभागों ने कमरे निर्धारित किए हैं जहां उस विभाग के छात्र और शिक्षक आगंतुकों को उनके सवालों के जवाब देने के लिए मिलते हैं।",
              "ka": "ಕಾಲೇಜಿನ ವಿವಿಧ ವಿಭಾಗಗಳು ಸಂದರ್ಶಕರ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಲು ಆ ವಿಭಾಗದ ವಿದ್ಯಾರ್ಥಿಗಳು ಮತ್ತು ಶಿಕ್ಷಕರು ಭೇಟಿ ನೀಡುವ ಕೊಠಡಿಗಳನ್ನು ಸ್ಥಾಪಿಸಿವೆ.",
              "te": "సందర్శకుల ప్రశ్నలకు సమాధానమిచ్చేందుకు కళాశాలలోని వివిధ విభాగాలకు చెందిన విద్యార్థులు, ఉపాధ్యాయులు సమావేశమయ్యే గదులను ఏర్పాటు చేశారు.",
              "or": "କଲେଜ ର ବିଭିର୍ଣ୍ଣ ବିଭାଗ ଭିର୍ଣ୍ଣ ଭିର୍ଣ୍ଣ କୋଠରୀର ଆୟୋଜନ କରିଥିଲା, ଯେଉଁଠାରେ \n ସେହି ବିଭାଗର ଶିକ୍ଷକ ଓ ଛାତ୍ରଛାତ୍ରୀ ଆଗନ୍ତୁକଙ୍କ ସହ ସାକ୍ଷାତ କରି ସେମାନଙ୍କର \n ପ୍ରଶ୍ନର ସଠିକ ଉତ୍ତର ଦେଇପାରିବେ।",
              "as": "কলেজখনৰ বিভিন্ন বিভাগে কোঠা স্থাপন কৰিছে য'ত সেই বিভাগৰ শিক্ষাৰ্থী আৰু শিক্ষকসকলে তেওঁলোকৰ প্ৰশ্নৰ উত্তৰ দিবলৈ অতিথিসকলক লগ কৰে।",
              "gu": "કૉલેજના વિવિધ વિભાગોએ રૂમ ગોઠવ્યા છે જ્યાં તે વિભાગના વિદ્યાર્થીઓ અને શિક્ષકો મુલાકાતીઓને તેમના પ્રશ્નોના જવાબ આપવા માટે મળે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_4",
              "hi": "A2_1_4",
              "ka": "A2_1_4",
              "te": "A2_1_4",
              "or": "A2_1_4",
              "as": "A2_1_4",
              "gu": "A2_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar gets a plan from the front desk for the event. He goes through the list of rooms to know about the various departments that interest him.",
              "hi": "शिखर को कार्यक्रम के लिए फ्रंट डेस्क से एक योजना मिलती है। वह उन विभिन्न विभागों के बारे में जानने के लिए अपनी रुचि के कमरों में सूची के माध्यम से जाता है।",
              "ka": "ಈವೆಂಟ್‌ಗಾಗಿ ಶಿಖರ್ ಮುಂಭಾಗದ ಮೇಜಿನಿಂದ ದರಾ ಬಗ್ಗೆ ಮಾಹಿತಿಯನ್ನು ಪಡೆಯುತ್ತಾನೆ. ತನಗೆ ಆಸಕ್ತಿಯಿರುವ ವಿವಿಧ ಇಲಾಖೆಗಳ ಬಗ್ಗೆ ತಿಳಿಯಲು ಅವನು ಕೊಠಡಿಗಳ ಪಟ್ಟಿಯನ್ನು ವೀಕ್ಷಿಸುತ್ತಾನೆ.",
              "te": "శిఖర్ ఫ్రంట్ డెస్క్ నుండి ఈవెంట్ గురించి ఒక ప్రణాళిక పొందాడు. తనకు ఆసక్తి ఉన్న వివిధ విభాగాల గురించి తెలుసుకోవడానికి అతను గదుల జాబితాను చూస్తాడు.",
              "or": "କାର୍ଯ୍ୟ କ୍ରମ ପାଇଁ ଶିଖର ସାମ୍ନା ବେଞ୍ଚ ରେ ବସି ବସି ଏକ ଉପାୟ ପାଇଲା। କେଉଁ ବିଭାଗର କେଉଁ କୋଠରି ଏହା ଜାଣିବା ପାଇଁ ସେ କୋଠରି ର ଲିଷ୍ଟ ରୁ ସମସ୍ତ କୋଠରୀକୁ ଦେଖିଲା ଯାହାକି ତାର ରୁଚିକୁ ବଢାଇଥିଲା।",
              "as": "শিখৰে অনুষ্ঠানৰ মেজৰ পৰা এখন প্লেন লাভ কৰিলে। সি তাত তাৰ মনোযোগ আকৰ্ষণ কৰা বিভিন্ন বিভাগৰ বিষয়ে জানিবলৈ তালিকাখন পঢ়িলে।",
              "gu": "શિખરને ઇવેન્ટ માટે ફ્રન્ટ ડેસ્ક પરથી પ્લાન મળે છે. તેને રુચિ ધરાવતા વિવિધ વિભાગો વિશે જાણવા માટે તે રૂમની સૂચિમાંથી પસાર થાય છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_5_checklist",
              "hi": "A2_1_5_चेकलिस्ट ",
              "ka": "A2_1_5_checklist",
              "te": "A2_1_5_చెక్‌ లిస్ట్ (తనిఖీ జాబితా)",
              "or": "A2_1_5_checklist",
              "as": "A2_1_5_checklist",
              "gu": "A2_1_5_ચેકલિસ્ટ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He stands in the main corridor and sees the different rooms. Which room should he visit now?",
              "hi": "वह मुख्य गलियारे में खड़ा है और विभिन्न कमरे देखता है। अब उसे किस कमरे में जाना चाहिए?",
              "ka": "ಅವನು ಮುಖ್ಯ ಕಾರಿಡಾರ್‌ನಲ್ಲಿ ನಿಂತು ವಿವಿಧ ಕೋಣೆಗಳನ್ನು ನೋಡುತ್ತಾನೆ. ಅವನು ಈಗ ಯಾವ ಕೋಣೆಗೆ ಭೇಟಿ ನೀಡಬೇಕು?",
              "te": "అతను ప్రధాన కారిడార్‌ లో నిలబడి వేర్వేరు గదులను చూస్తున్నాడు. అతను ఇప్పుడు ఏ గదికి వెళ్ళాలి?",
              "or": "ସେ ବର୍ତ୍ତମାନ ମୁଖ୍ୟ ଦ୍ଵାରରେ ଛିଡା ହେଲା ଏବଂ ସମସ୍ତ କୋଠରୀକୁ ଦେଖିଲା। ବର୍ତ୍ତମାନ କେଉଁ କୋଠରି କୁ ସେ ପ୍ରଥମେ ଯିବ?",
              "as": "তেওঁ মূল কৰিডৰত থিয় হৈ বিভিন্ন কোঠা চাইছে। তেওঁ এতিয়া কোনটো কোঠালৈ যাব লাগে?",
              "gu": "તે મુખ્ય લોબીમાં ઉભો છે અને જુદા જુદા રૂમમાં જુએ છે. તેણે હવે કયા રૂમની મુલાકાત લેવી જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Commerce",
              "hi": "व्यापार",
              "ka": "ವಾಣಿಜ್ಯ",
              "te": "కామర్స్ (వాణిజ్యం)",
              "or": "ବାଣିଜ୍ୟ",
              "as": "বাণিজ্য",
              "gu": "વાણિજ્ય"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Hotel Management",
              "hi": "होटल प्रबंधन",
              "ka": "ಹೊಟೇಲ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್",
              "te": "హోటల్ మేనేజ్‌మెంట్",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ",
              "as": "হোটেল মেনেজমেণ্ট",
              "gu": "હોટલ મેનેજમેંટ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Humanities",
              "hi": "मानविकी/ ह्यूमैनिटीज ",
              "ka": "ಹ್ಯುಮಾನಿಟೀಸ್",
              "te": "హ్యుమానిటీస్ (మానవీయ శాస్త్రాలు)",
              "or": "ମାନବତତ୍ଵ",
              "as": "হিউমেনিটিজ",
              "gu": "માનવતા"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "Engineering",
              "hi": "अभियांत्रिकी/ इंजीनियरिंग ",
              "ka": "ಇಂಜಿನಿಯರಿಂಗ್",
              "te": "ఇంజనీరింగ్",
              "or": "ଇଞ୍ଜିନିଅରିଂ",
              "as": "ইঞ্জিনিয়েৰিং",
              "gu": "એન્જિનિયરિંગ"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "Research",
              "hi": "अनुसंधान/ रिसर्च ",
              "ka": "ಸಂಶೋಧನೆ",
              "te": "పరిశోధన",
              "or": "ରିସର୍ଚ",
              "as": "গৱেষণা",
              "gu": "સંશોધન"
            }
          }
        ]
      },
      "scene14": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2i_1_1",
              "hi": "A2i_1_1",
              "ka": "A2i_1_1",
              "te": "A2i_1_1",
              "or": "A2i_1_1",
              "as": "A2i_1_1",
              "gu": "A2i_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar enters the commerce department room and is surprised to see the number of parents and students present there. The course seems to be very popular.",
              "hi": "शिखर वाणिज्य विभाग के कमरे में प्रवेश करता है और वहां मौजूद माता-पिता और छात्रों की संख्या को देखकर आश्चर्यचकित है। कोर्स बहुत लोकप्रिय प्रतीत होता है।",
              "ka": "ಶಿಖರ್  ವಾಣಿಜ್ಯ ವಿಭಾಗದ ಕೊಠಡಿಯನ್ನು ಪ್ರವೇಶಿಸಿದನು ಮತ್ತು ಅಲ್ಲಿ ಹಾಜರಿದ್ದ ಪೋಷಕರು ಮತ್ತು ವಿದ್ಯಾರ್ಥಿಗಳ ಸಂಖ್ಯೆಯನ್ನು ನೋಡಿ ಆಶ್ಚರ್ಯಚಕಿತನಾದನು. ಕೋರ್ಸ್ ತುಂಬಾ ಜನಪ್ರಿಯವಾಗಿದೆ ಎಂದು ತೋರುತ್ತದೆ.",
              "te": "శిఖర్ కామర్స్ విభాగం గదిలోకి ప్రవేశించి అక్కడ ఉన్న తల్లిదండ్రులు మరియు విద్యార్థుల సంఖ్యను చూసి ఆశ్చర్యపోతాడు. కోర్సు చాలా ప్రజాదరణ పొందింది అని అనిపిస్తుంది.",
              "or": "ଶିଖର ବାଣିଜ୍ୟ ବିଭାଗର କୋଠରୀରେ ପ୍ରବେଶ କଳା ଏବଂ ସେଠାରେ ଉପସ୍ଥିତ ଥିବା\n ଅଭିଭାବକ ଓ ଛାତ୍ରଛାତ୍ରୀଙ୍କୁ ଦେଖି ଆଶ୍ଚର୍ଯ୍ୟ ହେଲା। ତେବେ ଏହି ପାଠ୍ୟକ୍ରମ ଟି \n ଲୋକପ୍ରିୟ ଭଳି ଲାଗୁଛି।",
              "as": "শিখৰে বাণিজ্য বিভাগৰ কোঠালৈ যায় আৰু তাত উপস্থিত অভিভাৱক আৰু শিক্ষাৰ্থীৰ সংখ্যা দেখি আচৰিত হয়। পাঠ্যক্ৰমটো বহুত জনপ্ৰিয় যেন লাগিছে।",
              "gu": "શિખર કોમર્સ વિભાગના રૂમમાં પ્રવેશે છે અને ત્યાં હાજર વાલીઓ અને વિદ્યાર્થીઓની સંખ્યા જોઈને આશ્ચર્યચકિત થઈ જાય છે. કોર્સ ખૂબ જ લોકપ્રિય લાગે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2i_1_2",
              "hi": "A2I_1_2",
              "ka": "A2i_1_2",
              "te": "A2i_1_2",
              "or": "A2i_1_2",
              "as": "A2i_1_2",
              "gu": "A2i_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He finds it difficult to talk to the student volunteers in front of so many people. He walks around the room, trying to find someone whom he can talk to alone.",
              "hi": "उसे इतने सारे लोगों के सामने  स्वयंसेवी/ वॉलंटीयर छात्र से बात करना मुश्किल लगता है। वह कमरे में चारों ओर घूमता है, किसी ऐसे व्यक्ति को खोजने की कोशिश करता है जिससे वह अकेले में बात कर सकता है।",
              "ka": "ಇಷ್ಟು ಜನರ ಮುಂದೆ ಹಾಗೂ ವಿದ್ಯಾರ್ಥಿ ಸ್ವಯಂಸೇವಕರೊಂದಿಗೆ ಮಾತನಾಡಲು ಅವನಿಗೆ ಕಷ್ಟವಾಗುತ್ತದೆ. ಅವನು ಕೋಣೆಯ ಸುತ್ತಲೂ ನಡೆಯುತ್ತಾನೆ, ಅವನು ಒಬ್ಬಂಟಿಯಾಗಿ ಮಾತನಾಡಬಲ್ಲ ಯಾರನ್ನಾದರೂ ಹುಡುಕಲು ಪ್ರಯತ್ನಿಸುತ್ತಾನೆ",
              "te": "చాలా మంది ముందు విద్యార్థి వాలంటీర్లతో మాట్లాడటం అతనికి కష్టంగా ఉంది. అతను ఒంటరిగా మాట్లాడగల వ్యక్తి కోసం చూస్తూ, గది చుట్టూ తిరుగుతాడు.",
              "or": "ଏତେ ଲୋକଙ୍କ ସାମ୍ନାରେ ସେ ଛାତ୍ର ସ୍ଵୟଂସେବକ ଙ୍କ ସହ କଥା ହେବାକୁ ସଙ୍କୋଚ \n ଅନୁଭବ କଲା। ସେ କୋଠରି ମଧ୍ୟରେ ବୁଲିବାକୁ ଲାଗିଲା ଓ ଏମିତି ଜଣଙ୍କୁ ଖୋଜିଲା ଯାହା ସହ ଏ ଏକାନ୍ତ ରେ କଥା ହୋଇପାରିବ।",
              "as": "সি বহুতো মানুহৰ সন্মুখত ছাত্ৰ স্বেচ্ছাসেৱকসকলৰ সৈতে কথা পাতিবলৈ অসুবিধা পাইছে। সি অকলে কথা পাতিবলৈ এজন ব্যক্তি বিচাৰি সি কোঠাটোৰ চাৰিওফালে ঘূৰি ফুৰে।",
              "gu": "આટલા બધા લોકોની સામે વિદ્યાર્થી સ્વયંસેવકો સાથે વાત કરવી તેને કઠિન લાગે છે. તે રૂમમાં ફરે છે, કોઈને શોધવાનો પ્રયાસ કરે છે જેની સાથે તે એકલો વાત કરી શકે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2i_1_3",
              "hi": "A2i_1_3",
              "ka": "A2i_1_3",
              "te": "A2i_1_3",
              "or": "A2i_1_3",
              "as": "A2i_1_3",
              "gu": "A2i_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "On one of the tables, he finds a pile of pamphlets. The students of this department have created a FAQ (Frequently Asked Questions), which has all the commonly asked questions about the course and their answers noted down.",
              "hi": " एक मेज पर, वह पैम्फलेट/ प्रचार पुस्तिकाओं का ढेर पाता है। इस विभाग के छात्रों ने एक एफएक्यू (अक्सर पूछे जाने वाले प्रश्न) बनाए हैं, जिनमें कोर्स के बारे में सभी सामान्यतः पूछे जाने वाले प्रश्न हैं और उनके उत्तर नीचे लिखे गए हैं।",
              "ka": "ಒಂದು ಮೇಜಿನ ಮೇಲೆ, ಅವನು ಕರಪತ್ರಗಳ ರಾಶಿಯನ್ನು ಕಾಣುತ್ತಾನೆ. ಈ ವಿಭಾಗದ ವಿದ್ಯಾರ್ಥಿಗಳು FAQ (ಪದೇ ಪದೇ ಕೇಳಲಾಗುವ ಪ್ರಶ್ನೆಗಳು) ರಚಿಸಿದ್ದಾರೆ, ಇದರಲ್ಲಿ ಕೋರ್ಸ್ ಬಗ್ಗೆ ಸಾಮಾನ್ಯವಾಗಿ ಕೇಳಲಾಗುವ ಎಲ್ಲಾ ಪ್ರಶ್ನೆಗಳು ಮತ್ತು ಅವುಗಳ ಉತ್ತರಗಳನ್ನು ನಮೂದಿಸಲಾಗಿದೆ.",
              "te": "ఒక టేబుల్ మీద, అతను కరపత్రాల కుప్పను కనుగొంటాడు. ఈ విభాగానికి చెందిన విద్యార్థులు FAQ (తరచుగా అడిగే ప్రశ్నలు) సృష్టించారు, ఇందులో కోర్సు గురించి సాధారణంగా అడిగే అన్ని ప్రశ్నలు మరియు వాటి సమాధానాలు నమోదు చేయబడ్డాయి.",
              "or": "ଟେବଲ ମାନଙ୍କ ମଧ୍ୟରୁ ଗୋଟିଏ ଟେବଲ ଉପରେ ସେ କେତେଗୁଡିଏ ପୁସ୍ତିକା ପାଇଲା\n ଏହି ବିଭାଗର ପିଲାମାନେ ଗୋଟିଏ FAQ ତିଆରି କରିଛନ୍ତି, ଯାହାକି ସାଧାରଣ \n ପ୍ରଶ୍ନ ଏବଂ ତାହାର ଲିଖିତ ଉତ୍ତର କୁ ବହନ କରିଥିଲା।",
              "as": "এখন টেবুলত তেওঁ পত্ৰিকাৰ দ'ম বিচাৰি পালে। এই বিভাগৰ শিক্ষাৰ্থীসকলে এটা FAQ (সঘনাই সোধা প্ৰশ্ন) বনাইছে, য'ত পাঠ্যক্ৰমটোৰ বিষয়ে সচৰাচৰ সোধা সকলো প্ৰশ্ন আছে আৰু তেওঁলোকৰ উত্তৰবোৰ লিখা হৈছে।",
              "gu": "એક ટેબલ પર તેને પત્રિકાઓનો ઢગલો જોવા મળે છે. આ વિભાગના વિદ્યાર્થીઓએ એક FAQ (વારંવાર પૂછાતા પ્રશ્નો) બનાવ્યા છે, જેમાં કોર્સ વિશે સામાન્ય રીતે પૂછાતા તમામ પ્રશ્નો અને તેમના જવાબો નોંધવામાં આવ્યા છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2i_1_4_reveal",
              "hi": "A2i_1_4_reveal",
              "ka": "A2i_1_4_reveal",
              "te": "A2i_1_4_రివీల్ (బహిర్గతం)",
              "or": "A2i_1_4_reveal",
              "as": "A2i_1_4_reveal",
              "gu": "A2i_1_4_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar is relieved to find this. He takes a look at the document.\nClick on the questions to reveal their answers:",
              "hi": "शिखर यह पाकर चिंतामुक्त है। वह दस्तावेज़ पर एक दृष्टि डालता है।\nउसके उत्तरों को दिखाने के लिए प्रश्नों पर क्लिक करें:",
              "ka": "ಇದನ್ನು ಕಂಡು ಶಿಖರ್ ನಿರಾಳನಾಗಿದ್ದಾನೆ  ಅವನು ಡಾಕ್ಯುಮೆಂಟ್ ಅನ್ನು ನೋಡುತ್ತಾನೆ. ಅವರ ಉತ್ತರಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಪ್ರಶ್ನೆಗಳ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "శిఖర్ దీన్ని కనుగొన్నందుకు ఉపశమనం పొందాడు. అతను పత్రాన్ని పరిశీలించాడు.\n సమాధానాలను చూడడానికి ప్రశ్నలపై క్లిక్ చేయండి (నొక్కండి):",
              "or": "ଶିଖର ଏହାକୁ ପାଇ ଆଶ୍ଵସ୍ତି ଅନୁଭବ କଳା। ସେ ଏହି କାଗଜ ପତ୍ରକୁ ନିରୀକ୍ଷଣ କଲା ।\n ଉତ୍ତର ପାଇବା ପାଇଁ ପ୍ରଶ୍ନ ଗୁଡିକ ଉପରେ କ୍ଲିକ କରନ୍ତୁ ।",
              "as": "শিখৰে এইটো বিচাৰি পাই সকাহ পাইছে। সি ডকুমেন্টটো চালে।\n \n প্ৰশ্নবোৰৰ উত্তৰ পাবলৈ ক্লিক কৰা:",
              "gu": "આ જાણીને શિખર રાહત અનુભવે છે. તે દસ્તાવેજ પર એક નજર નાખે છે. પ્રશ્નોના જવાબો જાણવા માટે તેના પર ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Can students from other streams join commerce?",
              "hi": "क्या अन्य स्ट्रीम/ धाराओं के छात्र वाणिज्य में शामिल हो सकते हैं?",
              "ka": "ಇತರೆ ಸ್ಟ್ರೀಮ್‌ಗಳ ವಿದ್ಯಾರ್ಥಿಗಳು ವಾಣಿಜ್ಯಕ್ಕೆ ಸೇರಬಹುದೇ?",
              "te": "ఇతర స్ట్రీమ్‌ ల విద్యార్థులు కామర్స్ లో చేరవచ్చా?",
              "or": "ଅନ୍ୟ ସ୍ରୋତ ର ଛାତ୍ରମାନେ ବାଣିଜ୍ୟ ନେଇ ପଢିପାରିବେ କି?",
              "as": "আন শাখাৰ শিক্ষাৰ্থীসকলে বাণিজ্যত যোগ হ'ব পাৰিবনে?",
              "gu": "શું અન્ય પ્રવાહના વિદ્યાર્થીઓ કોમર્સમાં જોડાઈ શકે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "What Subjects will I learn?",
              "hi": "मैं किन विषयों को सीखूंगा?",
              "ka": "ನಾನು ಯಾವ ವಿಷಯಗಳನ್ನು ಕಲಿಯುತ್ತೇನೆ?",
              "te": "నేను ఏ సబ్జెక్టులు నేర్చుకుంటాను?",
              "or": "କେଉଁ କେଉଁ ବିଷୟ ରେ ମୁଁ ଶିକ୍ଷାଲାଭ କରିପାରିବି?",
              "as": "মই কি বিষয় শিকিম?",
              "gu": "હું કયા વિષયો શીખીશ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "What are the career options after this course?",
              "hi": "इस कोर्स के बाद कैरियर विकल्प क्या हैं?",
              "ka": "ಈ ಕೋರ್ಸ್ ನಂತರ ಇರುವ ವೃತ್ತಿ ಆಯ್ಕೆಗಳು ಯಾವುವು?",
              "te": "ఈ కోర్సు తర్వాత కెరీర్ ఎంపికలు ఏమిటి?",
              "or": "ଏହି ପାଠ୍ୟକ୍ରମ ପରେ ଚାକିରି ର କଣ ଅପ୍ଶନ ରହିଛି?",
              "as": "এই পাঠ্যক্ৰমৰ পিছত কেৰিয়াৰৰ বিকল্পবোৰ কি?",
              "gu": "આ કોર્સ પછી કારકિર્દીના તક શું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "What type of companies will hire?",
              "hi": "किस प्रकार की कंपनियां नौकरी पर लेंगी?",
              "ka": "ಯಾವ ರೀತಿಯ ಕಂಪನಿಗಳು ನೇಮಕಾಟಿ ಮಾಡಿಕೊಳ್ಳುತ್ತವೆ?",
              "te": "ఏ రకమైన కంపెనీలు తీసుకుంటాయి?",
              "or": "କେଉଁ ପ୍ରକାର ର କମ୍ପାନୀ ପ୍ରଯୁଜ୍ୟ ହେବ?",
              "as": "কেনে ধৰণৰ কোম্পানীয়ে নিয়োগ কৰিব?",
              "gu": "કયા પ્રકારની કંપનીઓ ભરતી કરશે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Yes, students from any stream are eligible provided they have the minimum percentage of marks set by the college. The course starts with the basics of accounting and economics.",
              "hi": "हां, किसी भी स्ट्रीम/ धारा के छात्र योग्य  हैं बशर्ते कि उनके पास कॉलेज द्वारा निर्धारित अंकों का न्यूनतम प्रतिशत हो। कोर्स अकाउंटिंग/ लेखांकन और अर्थशास्त्र की मूल बातों के साथ शुरु होता है।",
              "ka": "ಹೌದು, ಯಾವುದೇ ಸ್ಟ್ರೀಮ್‌ನ ವಿದ್ಯಾರ್ಥಿಗಳು ಕಾಲೇಜು ನಿಗದಿಪಡಿಸಿದ ಕನಿಷ್ಠ ಶೇಕಡಾವಾರು ಅಂಕಗಳನ್ನು ಹೊಂದಿದ್ದಲ್ಲಿ ಅವರು ಅರ್ಹರಾಗಿರುತ್ತಾರೆ. ಕೋರ್ಸ್ ಲೆಕ್ಕಪತ್ರ ನಿರ್ವಹಣೆ ಮತ್ತು ಅರ್ಥಶಾಸ್ತ್ರದ ಮೂಲಗಳೊಂದಿಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ.",
              "te": "అవును, కళాశాల నిర్ణయించిన కనీస మార్కుల శాతాన్ని కలిగి ఉంటే ఏ స్ట్రీమ్‌ లోని విద్యార్థులు అయినా అర్హులే. అకౌంటింగ్ మరియు ఎకనామిక్స్ యొక్క ప్రాథమిక అంశాలతో కోర్సు ప్రారంభమవుతుంది.",
              "or": "ହଁ, କୌଣସି ସ୍ରୋତର ଛାତ୍ରଛାତ୍ରୀ କଲେଜ ଦ୍ଵାରା ପ୍ରଦତ୍ତ ଶତକଡା ନମ୍ବର ହିସାବରେ\n କୌଣସି ବି ସ୍ରୋତ ନେବାପାଇଁ ଯୋଗ୍ୟ। ଏହି ପାଠ୍ୟକ୍ରମ ଅର୍ଥନୀତି ଓ ଲେଖାଙ୍କନର \n ମୂଳଭିତ୍ତିରୁ ଆରମ୍ଭ।",
              "as": "হয়, যিকোনো শাখাৰ শিক্ষাৰ্থীসকল যোগ্য হয় যদিহে তেওঁলোকৰ কলেজে নিৰ্ধাৰণ কৰা নম্বৰৰ নিম্নতম শতাংশ থাকে। পাঠ্যক্ৰমটো একাউণ্টিং আৰু অৰ্থনীতিৰ মৌলিক বিষয়ৰ সৈতে আৰম্ভ হয়।",
              "gu": "હા, કોઈપણ પ્રવાહના વિદ્યાર્થીઓ લાયકાત ધરાવે છે જો કે તેમની પાસે કૉલેજ દ્વારા નિર્ધારિત ગુણની લઘુત્તમ ટકાવારી હોય. કોર્સ એકાઉન્ટિંગ અને અર્થશાસ્ત્રની મૂળભૂત બાબતોથી શરૂ થાય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "The subjects vary from taxation and financial accounting to economics business studies.",
              "hi": " टैक्सेशन और फ़ाइनेन्शिअल अकाउंटिंग से लेकर अर्थशास्त्र और बिजनेस स्टडीज़ तक के विषय उपलब्ध हैं।",
              "ka": "ವಿಷಯಗಳು ತೆರಿಗೆ ಮತ್ತು ಹಣಕಾಸು ಲೆಕ್ಕಪತ್ರದಿಂದ ಅರ್ಥಶಾಸ್ತ್ರ ವ್ಯವಹಾರಗಳ ಬಗ್ಗೆಯೂ ಓದುವವರೆಗೂ ಬದಲಾಗುತ್ತವೆ.",
              "te": "సబ్జెక్టులు టాక్సేషన్ మరియు ఫైనాన్షియల్ అకౌంటింగ్ నుండి ఆర్థిక శాస్త్ర వ్యాపార అధ్యయనాల వరకు మారుతూ ఉంటాయి.",
              "or": "ବିଷୟ ଗୁଡିକ କର ଓ ଅର୍ଥବ୍ୟବସ୍ଥା ରୁ ଅର୍ଥନୈତିକ ବାଣିଜ୍ୟ ପାଠ୍ୟକ୍ରମକୁ \n ବଦଳିଥାଏ।",
              "as": "বিষয়বোৰ কৰ আৰু বিত্তীয় হিচাপৰ পৰা অৰ্থনীতিৰ ব্যৱসায় অধ্যয়নলৈকে আছে।",
              "gu": "કરવેરા અને નાણાકીય એકાઉન્ટિંગથી લઈને અર્થશાસ્ત્રના ધંધા અભ્યાસ સુધીના વિષયો બદલાય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "There is an option to become a CA(Chartered Accountant), or study further courses like Master of Business Administration (MBA) or Bachelor in Education (B.Ed).",
              "hi": " सीए (चार्टर्ड एकाउंटेंट) बनने भी एक विकल्प है, या अन्य कोर्स का अध्ययन करें जैसे मास्टर ऑफ बिज़नस एडमिनिस्ट्रेशन (एमबीए) या बैचलर इन एजुकेशन (बीएड)।",
              "ka": "CA (ಚಾರ್ಟರ್ಡ್ ಅಕೌಂಟೆಂಟ್) ಆಗಲು ಅಥವಾ ಮಾಸ್ಟರ್ ಆಫ್ ಬಿಸಿನೆಸ್ ಅಡ್ಮಿನಿಸ್ಟ್ರೇಷನ್ (MBA) ಅಥವಾ ಬ್ಯಾಚುಲರ್ ಇನ್ ಎಜುಕೇಶನ್ (B.Ed) ನಂತಹ ಹೆಚ್ಚಿನ ಕೋರ್ಸ್‌ಗಳನ್ನು ಓದಲು ಒಂದು ಆಯ್ಕೆ ಇದೆ.",
              "te": "CA (చార్టర్డ్ అకౌంటెంట్) కావడానికి లేదా మాస్టర్ ఆఫ్ బిజినెస్ అడ్మినిస్ట్రేషన్ (MBA) లేదా బ్యాచిలర్ ఇన్ ఎడ్యుకేషన్ (B.Ed) వంటి తదుపరి కోర్సులను చదివే అవకాశం ఉంది.",
              "or": "ଏଠାରେ ସିଏ ର ଚୟନ ପାଇଁ ଏକ ଧାରା ଅଛି କିମ୍ବା MBA କିମ୍ବା B.ED ଭଳି ପାଠ୍ୟକ୍ରମ ଅଛି।",
              "as": "CA (চাৰ্টাৰ্ড একাউণ্টেণ্ট) হোৱাৰ, বা বিজনেছ এডমিনিষ্ট্ৰেচনৰ স্নাতকোত্তৰ (MBA) বা শিক্ষাৰ স্নাতক (B.Ed)ৰ দৰে পৰৱৰ্তী পাঠ্যক্ৰম অধ্যয়ন কৰাৰ বিকল্প আছে।",
              "gu": "ત્યાં CA (ચાર્ટર્ડ એકાઉન્ટન્ટ) બનવાનો વિકલ્પ છે અથવા માસ્ટર ઓફ બિઝનેસ એડમિનિસ્ટ્રેશન (MBA) અથવા બેચલર ઇન એજ્યુકેશન (B.Ed) જેવા વધુ અભ્યાસક્રમોનો અભ્યાસ કરવાનો વિકલ્પ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "Every organisation needs someone to handle its finances efficiently. Students can find work in private and government services.",
              "hi": "प्रत्येक संगठन को अपने वित्त/ अर्थ व्यवस्था को कुशलता से संभालने के लिए  किसी व्यक्ति की आवश्यकता होती है। छात्र निजी और सरकारी सेवाओं में नौकरी प्राप्त कर सकते हैं।",
              "ka": "ಪ್ರತಿಯೊಂದು ಸಂಸ್ಥೆಗೂ ತನ್ನ ಹಣಕಾಸು ನಿರ್ವಹಣೆಯನ್ನು ಸಮರ್ಥವಾಗಿ ನಿಭಾಯಿಸಲು ಯಾರಾದರೂ ಬೇಕು. ವಿದ್ಯಾರ್ಥಿಗಳು ಖಾಸಗಿ ಮತ್ತು ಸರ್ಕಾರಿ ಸೇವೆಗಳಲ್ಲಿ ಕೆಲಸ ಹುಡುಕಬಹುದು.",
              "te": "ప్రతి సంస్థకు దాని ఆర్థిక వ్యవహారాలను సమర్థవంతంగా నిర్వహించడానికి ఎవరైనా అవసరం. విద్యార్థులు ప్రైవేట్ మరియు ప్రభుత్వ సర్వీసెస్ లో ఉద్యోగం పొందవచ్చు.",
              "or": "ପ୍ରତ୍ୟେକ ସଂଗଠନ ଏହାର ଅର୍ଥବ୍ୟବସ୍ଥା ସମ୍ବନ୍ଧୀୟ ତଥ୍ୟକୁ କୁଶଳତା ପୂର୍ବକ ସମ୍ଭାଳିବା \n ପାଇଁ କାହାକୁ ନା କାହାକୁ ଦରକାର କରନ୍ତି। ଛାତ୍ରଛାତ୍ରୀ ମାନେ ସରକାରୀ କିମ୍ବା ବେସରକାରୀ \n ଭାବେ ଚାକିରି ପାଇପାରିବେ।",
              "as": "প্ৰতিটো সংগঠনক ইয়াৰ বিত্ত ভালকৈ চম্ভালিবলৈ কাৰোবাৰ প্ৰয়োজন। শিক্ষাৰ্থীসকলে ব্যক্তিগত আৰু চৰকাৰী সেৱাত কাম বিচাৰি পাব পাৰে।",
              "gu": "દરેક સંસ્થાને તેની નાણાકીય વ્યવસ્થાને અસરકારક રીતે સંભાળવા માટે કોઈની જરૂર હોય છે. વિદ્યાર્થીઓ ખાનગી અને સરકારી સેવાઓમાં કામ શોધી શકે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2i_2_1",
              "hi": "A2I_2_1",
              "ka": "A2i_2_1",
              "te": "A2i_2_1",
              "or": "A2i_2_1",
              "as": "A2i_2_1",
              "gu": "A2i_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar understands that this subject has a lot of scope for a bright future. But he does not have much of an idea regarding many of the subjects mentioned in the pamphlet.",
              "hi": "शिखर समझ जाता है कि इस विषय में उज्ज्वल भविष्य के लिए बहुत अधिक संभावना है। लेकिन पैम्फलेट में उल्लिखित कई विषयों के बारे में उसको बहुत जानकारी  नहीं है।",
              "ka": "ಈ ವಿಷಯವು ಉಜ್ವಲ ಭವಿಷ್ಯಕ್ಕಾಗಿ ಸಾಕಷ್ಟು ಸ್ಕೋಪ್ ಹೊಂದಿದೆ ಎಂದು ಶಿಖರ್ ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾನೆ. ಆದರೆ ಕರಪತ್ರದಲ್ಲಿ ಉಲ್ಲೇಖಿಸಿರುವ ಹಲವು ವಿಷಯಗಳ ಬಗ್ಗೆ ಅವನಿಗೆ ಹೆಚ್ಚಿನ ಕಲ್ಪನೆ ಇಲ್ಲ.",
              "te": "ఈ సబ్జెక్ట్ ఉజ్వల భవిష్యత్తుకు చాలా అవకాశం ఉందని శిఖర్ అర్థం చేసుకున్నాడు. కానీ కరపత్రంలో పేర్కొన్న అనేక విషయాల గురించి అతనికి పెద్దగా ఆలోచన లేదు.",
              "or": "ଶିଖର ବୁଝିସରିଥିଲା ଯେ ଏହି ପାଠ୍ୟକ୍ରମରେ ଉଜ୍ଜ୍ଵଳ ଭବିଷ୍ୟତ ପାଇଁ ଏକ ଭଲ \n କ୍ଷେତ୍ର ଅଛି। କିନ୍ତୁ ପୁସ୍ତିକାରେ ଲିଖିତ ବିଷୟଗୁଡିକ ବାବଦରେ ତାର ବେଶୀ ଜ୍ଞାନ ନହିଁ।",
              "as": "শিখৰে বুজি পাইছে যে এই বিষয়টোৰ এক উজ্জ্বল ভৱিষ্যতৰ বাবে বহুত সুযোগ আছে। কিন্তু পত্ৰিকাখনত থকা বহুতো বিষয়ত তেওঁৰ বিশেষ আইডিয়া নাই।",
              "gu": "શિખર સમજે છે કે આ વિષયમાં ઉજ્જવળ ભવિષ્ય માટે ઘણી તકો છે. પરંતુ પત્રિકામાં ઉલ્લેખિત ઘણા વિષયો અંગે તેમને બહુ ખ્યાલ નથી."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2i_2_2",
              "hi": "A2i_2_2",
              "ka": "A2i_2_2",
              "te": "A2i_2_2",
              "or": "A2i_2_2",
              "as": "A2i_2_2",
              "gu": "A2i_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He is happy that he will be able to explore his options for work after commerce. More importantly, Babuji might agree to let him take up a commerce degree.",
              "hi": "वह खुश है कि वह कॉमर्स/ वाणिज्य के बाद काम के लिए अपने विकल्पों का पता लगाने में सक्षम होगा। सबसे महत्वपूर्ण बात यह है कि बाबूजी उसे कॉमर्स/ वाणिज्य की डिग्री लेने के लिए सहमत हो सकते हैं।",
              "ka": "ವಾಣಿಜ್ಯದ ನಂತರ ಕೆಲಸಕ್ಕಾಗಿ ತನ್ನ ಆಯ್ಕೆಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ ಎಂದು ಅವನು ಸಂತೋಷಪಡುತ್ತಾನೆ. ಹೆಚ್ಚು ಮುಖ್ಯವಾಗಿ, ಬಾಬೂಜಿ ಅವರು ವಾಣಿಜ್ಯ ಪದವಿಯನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಒಪ್ಪಿಕೊಳ್ಳಬಹುದು.",
              "te": "కామర్స్ తర్వాత పని కోసం తన ఎంపికలను అన్వేషించగలనని అతను సంతోషిస్తున్నాడు. మరీ ముఖ్యంగా, నాన్నగారు కామర్స్ డిగ్రీని తీసుకోవడానికి అంగీకరించవచ్చు.",
              "or": "ସେ ଖୁସି ଥିଲା ଯେ ବାଣିଜ୍ୟ ପଢିବା ପରେ ସେ ନିଜର ବହୁତ ସାରା କାମକୁ ସଠିକ\n ଦିଗରେ ନେଇପାରିବ। ସବୁଠୁ ମୁଖ୍ୟ ହେଲା, ବାପା ଏହି ବାଣିଜ୍ୟ ର ଡିଗ୍ରୀ ପାଇଁ ବୋଧ \n ହୁଏ ରାଜି ହୋଇଯିବେ।",
              "as": "সি সুখী যে সি বাণিজ্যৰ পিছত কামৰ বাবে তাৰ বিকল্পবোৰ বিচাৰিব পাৰিব। আটাইতকৈ গুৰুত্বপূৰ্ণ কথাটো হ'ল, দেউতাই তাক বাণিজ্য ডিগ্ৰী ল'বলৈ দিব পাৰে।",
              "gu": "તે ખુશ છે કે તે વાણિજ્ય પછી કામ માટે તેના વિકલ્પો શોધી શકશે. વધુ મહત્ત્વની વાત એ છે કે, બાબુજી તેને આ કોમર્સની ડિગ્રી મેળવવા માટે સંમત થઈ શકે છે."
            }
          }
        ]
      },
      "scene15": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2ii_1_1",
              "hi": "A2II_1_1",
              "ka": "A2ii_1_1",
              "te": "A2ii_1_1",
              "or": "A2ii_1_1",
              "as": "A2ii_1_1",
              "gu": "A2ii_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar enters the Hotel Management department room. Shikhar has never heard of this course before. He is waiting to see what happens inside.",
              "hi": "शिखर होटल प्रबंधन विभाग के कमरे में प्रवेश करता है। शिखर ने पहले इस कोर्स के बारे में कभी नहीं सुना है। वह यह देखने का इंतजार कर रहा है कि अंदर क्या होता है।",
              "ka": "ಶಿಖರ್ ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಡಿಪಾರ್ಟ್‌ಮೆಂಟ್ ಕೋಣೆಗೆ ಪ್ರವೇಶಿಸಿದನು. ಶಿಖರ್ ಹಿಂದೆಂದೂ ಈ ಕೋರ್ಸ್ ಬಗ್ಗೆ ಕೇಳಿರಲಿಲ್ಲ. ಒಳಗೆ ಏನಾಗುತ್ತದೆ ಎಂದು ನೋಡಲು ಅವನು ಕಾಯುತ್ತಿದ್ದಾನೆ.",
              "te": "శిఖర్ హోటల్ మేనేజ్‌మెంట్ డిపార్ట్మెంట్ గదిలోకి ప్రవేశించాడు. శిఖర్ ఇంతకు ముందు ఈ కోర్సు గురించి వినలేదు. లోపల ఏం జరుగుతుందోనని ఎదురు చూస్తున్నాడు.",
              "or": "ଶିଖର ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ବିଭାଗର କୋଠରି ମଧ୍ୟକୁ ପ୍ରବେଶ କଲା। ଶିଖର \n ଏହି ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ଆଗରୁ କେବେ ଶୁଣିନଥିଲା। ଭିତରେ କଣ ସବୁ ଘଟିବ \n ତାହା ପାଇଁ ସେ ଅପେକ୍ଷା କରିଥିଲା।",
              "as": "শিখৰে হোটেল মেনেজমেণ্ট ডিপাৰ্টমেণ্টৰ কোঠালৈ যায়। শিখৰে এই পাঠ্যক্ৰমৰ বিষয়ে আগতে কেতিয়াও শুনা নাছিল। সি ভিতৰত কি হয় চাবলৈ ৰৈ আছে।",
              "gu": "શિખર હોટેલ મેનેજમેન્ટ વિભાગના રૂમમાં પ્રવેશ્યો. શિખરે આ કોર્સ વિશે પહેલાં ક્યારેય સાંભળ્યું નથી. તે અંદર શું થાય છે તે જોવાની રાહ જોઈ રહ્યો છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2ii_1_3",
              "hi": "A2II_1_3",
              "ka": "A2ii_1_3",
              "te": "A2ii_1_3",
              "or": "A2ii_1_3",
              "as": "A2ii_1_3",
              "gu": "A2ii_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He enters the room, and much to his surprise, he sees everyone wearing chef caps, gloves and doing some things with food. He’s very confused.",
              "hi": "वह कमरे में प्रवेश करता है, और वह हर किसी को शेफ कैप्स, दस्ताने पहने और भोजन के साथ कुछ करते हुए देख आश्चर्यचकित रह जाता है। वह बहुत दुविधा में है।",
              "ka": "ಅವನು ಕೋಣೆಗೆ ಪ್ರವೇಶಿಸಿದನು, ಮತ್ತು ಅವನ ಆಶ್ಚರ್ಯಕ್ಕೆ, ಎಲ್ಲರೂ ಬಾಣಸಿಗ ಕ್ಯಾಪ್‌ಗಳು, ಕೈಗವಸುಗಳನ್ನು ಧರಿಸಿ ಮತ್ತು ಆಹಾರದೊಂದಿಗೆ ಕೆಲವು ಕೆಲಸಗಳನ್ನು ಮಾಡುವುದನ್ನು ಅವನು ನೋಡುತ್ತಾನೆ. ಅವನು ತುಂಬಾ ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದಾನೆ.",
              "te": "అతను గదిలోకి ప్రవేశించాడు మరియు అతనిని ఆశ్చర్యపరిచే విధంగా, అతను ప్రతి ఒక్కరూ చెఫ్ క్యాప్లు, గ్లౌజులు ధరించి, ఆహారంతో కొన్ని పనులు చేయడం చూశాడు. అతను చాలా అయోమయంలో ఉన్నాడు.",
              "or": "ସେ କୋଠରୀ ମଧ୍ୟକୁ ପ୍ରବେଶ କଲା ଓ ତାର ବିସ୍ମଯ ଠାରୁ ଢେର ଅଧିକ ସେ ଆଶ୍ଚର୍ଯ୍ୟ \n ହେଲା। ସେ ଦେଖିଲ ସମସ୍ତେ ବାବର୍ଚି ର ଟୋପି ଓ ଦସ୍ତାନା ପିନ୍ଧି କିଛି ଖାଦ୍ଯ ପ୍ରସ୍ତୁତ କରୁଛନ୍ତି। \n ସେ ବେଶୀ ଦ୍ଵନ୍ଦ୍ଵ ରେ ପଡିଗଲା।",
              "as": "সি কোঠাটোলৈ যায়, আৰু আচৰিত হৈ সি সকলোকে ৰন্ধন টুপী, হাতমোজা পিন্ধি আৰু খাদ্যৰ সৈতে কিছুমান কাম কৰা দেখিলে। সি বৰ চিন্তিত হ'ল।",
              "gu": "તે રૂમમાં પ્રવેશે છે, અને તેના આશ્ચર્ય વચ્ચે, તે દરેક રસોઇયાની ટોપીયો, મોજા પહેરેલા અને ખોરાક સાથે કઈક કરી રહેલા જુએ છે. તે ખૂબ જ મૂંઝવણમાં છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2ii_2_1",
              "hi": "A2II_2_1",
              "ka": "A2ii_2_1",
              "te": "A2ii_2_1",
              "or": "A2ii_2_1",
              "as": "A2ii_2_1",
              "gu": "A2ii_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He then goes to one of the stalls. He is watching some students experiment with wheat flour to make healthy biscuits while others are making baked snacks.",
              "hi": "फिर वह उनमें से एक स्टॉल में जाता है। वह कुछ छात्रों को पौष्टिक बिस्कुट बनाने के लिए गेहूं के आटे के साथ प्रयोग करते देखता है जबकि अन्य बेक्ड स्नैक्स बना रहे हैं।",
              "ka": "ನಂತರ ಅವನು ಸ್ಟಾಲ್ ಒಂದಕ್ಕೆ ಹೋಗುತ್ತಾನೆ. ಕೆಲವು ವಿದ್ಯಾರ್ಥಿಗಳು ಆರೋಗ್ಯಕರ ಬಿಸ್ಕತ್ತುಗಳನ್ನು ತಯಾರಿಸಲು ಗೋಧಿ ಹಿಟ್ಟಿನಿಂದ ಪ್ರಯೋಗ ಮಾಡುತ್ತಿರುವುದನ್ನು ಅವನು ವೀಕ್ಷಿಸುತ್ತಾನೆ. ಇತರರು ಬೇಯಿಸಿದ ತಿಂಡಿಗಳನ್ನು ತಯಾರಿಸುತ್ತಿದ್ದಾರೆ.",
              "te": "తర్వాత అతను ఒక స్టాల్‌కి వెళ్తాడు. కొంతమంది విద్యార్థులు ఆరోగ్యకరమైన బిస్కెట్లను తయారు చేయడానికి గోధుమ పిండితో ప్రయోగాలు చేస్తుంటే మరికొందరు కాల్చిన చిరుతిళ్లు తయారు చేయడాన్ని అతను చూస్తున్నాడు.",
              "or": "ସେଠାରେ ସେ ଗୋଟିଏ ଦୋକାନ ମଧ୍ୟକୁ ଗଲା। ସେଠାରେ ସେ କିଛି ଛାତ୍ର ଛାତ୍ରୀଙ୍କୁ \n ମଇଦାରୁ ଭଲ ବିସ୍କୁଟ ବନେଇବାର ଦେଖିଲ ଓ ଅନ୍ୟ କିଛି ଜଣଙ୍କୁ ପୋଡା ବିସ୍କୁଟ \n ବନେଇବାର ମଧ୍ୟ ଦେଖିଲ।",
              "as": "তাৰ পিছত সি এখন ষ্টললৈ যায়। সি কিছুমান শিক্ষাৰ্থীক স্বাস্থ্যকৰ বিস্কুট বনাবলৈ ঘেঁহুৰ আটা পৰীক্ষা-নিৰীক্ষা কৰা দেখিছে আনহাতে আন কিছুমানে বেক কৰা জলপান বনাই আছে।",
              "gu": "તે પછી તે એક સ્ટોલ પર જાય છે. તે કેટલાક વિદ્યાર્થીઓને સ્વાસ્થ્યવર્ધક બિસ્કિટ બનાવવા માટે ઘઉંના લોટનો પ્રયોગ કરતા જોઈ રહ્યો છે જ્યારે અન્ય શેકેલો નાસ્તો બનાવી રહ્યા છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2ii_2_2",
              "hi": "A2II_2_2",
              "ka": "A2ii_2_2",
              "te": "A2ii_2_2",
              "or": "A2ii_2_2",
              "as": "A2ii_2_2",
              "gu": "A2ii_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He’s very confused about what is happening and what the course is. He approaches one of the students.",
              "hi": "वह बहुत दुविधा में है कि वहां क्या हो रहा है और पाठ्यक्रम क्या है। वह उनमें से एक छात्र के पास पहुंचता है।",
              "ka": "ಏನಾಗುತ್ತಿದೆ ಮತ್ತು ಕೋರ್ಸ್ ಏನು ಎನ್ನುವುದರ ಕುರಿತು ಅವನು ತುಂಬಾ ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದಾನೆ. ಅವನು ವಿದ್ಯಾರ್ಥಿಗಳಲ್ಲಿ ಒಬ್ಬನನ್ನು ಸಂಪರ್ಕಿಸುತ್ತಾನೆ.",
              "te": "అతను ఏమి జరుగుతుందో మరియు కోర్సు ఏమిటో తెలియక చాలా అయోమయంగా ఉన్నాడు. అతను విద్యార్థులలో ఒకరి దగ్గరికి వెళ్తాడు.",
              "or": "ସେ ବହୁତ ଦ୍ଵନ୍ଦ୍ଵ ରେ। ଓ କଣ ସବୁ ଘଟୁଛି ଏବଂ ଏହା କେଉଁ ପ୍ରକାରର\n  ପାଠ୍ୟକ୍ରମ ବୋଲି ଭାବେ। ସେ ଗୋଟିଏ ପିଲକୁ ପଚାରେ ।",
              "as": "কি ঘটিছে আৰু পাঠ্যক্ৰমটো কি তাক লৈ সি বহুত চিন্তিত হ'ল। সি এজন শিক্ষাৰ্থীৰ কাষ চাপিছে।",
              "gu": "શું થઈ રહ્યું છે અને કોર્સ શું છે તે વિશે તે ખૂબ જ મૂંઝવણમાં છે. તે એક વિદ્યાર્થી પાસે જાય છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2ii_2_3//",
              "hi": "A2II_2_3 //",
              "ka": "A2ii_2_3//",
              "te": "A2ii_2_3//",
              "or": "A2ii_2_3//",
              "as": "A2ii_2_3//",
              "gu": "A2ii_2_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Student",
              "hi": "छात्र ",
              "ka": "ವಿದ್ಯಾರ್ಥಿ",
              "te": "విద్యార్థి",
              "or": "ଛାତ୍ର",
              "as": "শিক্ষাৰ্থী",
              "gu": "વિદ્યાર્થી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Hi, I wanted to know what exactly you study in Hotel Management? Why is everyone making food here?",
              "hi": "हाय, मैं जानना चाहता था कि आप होटल प्रबंधन में वास्तव में क्या अध्ययन करते हैं? हर कोई यहाँ भोजन क्यों बना रहा है?",
              "ka": "ಹಾಯ್, ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್‌ನಲ್ಲಿ ನೀವು ನಿಖರವಾಗಿ ಏನು ಓದುತ್ತೀರಿ ಎಂದು ತಿಳಿಯಲು ನಾನು ಬಯಸುತ್ತೇನೆ? ಎಲ್ಲರೂ ಇಲ್ಲಿ ಆಹಾರವನ್ನು ಏಕೆ ತಯಾರಿಸುತ್ತಿದ್ದಾರೆ?",
              "te": "హాయ్, మీరు హోటల్ మేనేజ్‌మెంట్ లో సరిగ్గా ఏమి చదువుతున్నారు అని నేను తెలుసుకోవాలనుకుంటున్నాను? అందరూ ఇక్కడ భోజనం ఎందుకు తయారు చేస్తున్నారు?",
              "or": "ହାଏ, ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ କେଉଁ ପ୍ରକାରର ପାଠ୍ୟକ୍ରମ ମୁଁ ଜାଣିବାକୁ ଚାହୁଛି। \n କାହିଁକି ସମସ୍ତେ ଖାଦ୍ୟ ପ୍ରସ୍ତୁତ କରୁଛନ୍ତି?",
              "as": "হাই, মোক কোৱাচোন তুমি হোটেল মেনেজমেণ্টত আচলতে কি পঢ়া? সকলোৱে ইয়াত কিয় খাদ্য বনাই আছে?",
              "gu": "નમસ્તે, મારે જાણવું છે કે તમે હોટેલ મેનેજમેન્ટમાં ખરેખર શું અભ્યાસ કરો છો? શા માટે દરેક અહીં ખોરાક બનાવે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Because that’s one of the things we learn in Hotel Management!",
              "hi": "क्योंकि यह उन चीजों में से एक है जिसे हम होटल प्रबंधन में सीखते हैं!",
              "ka": "ಏಕೆಂದರೆ ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್‌ನಲ್ಲಿ ನಾವು ಕಲಿಯುವ ವಿಷಯಗಳಲ್ಲಿ ಅದೂ ಒಂದಾಗಿರುವುದರಿಂದ!",
              "te": "ఎందుకంటే హోటల్ మేనేజ్‌మెంట్ లో మనం నేర్చుకునే విషయాలలో ఇది ఒకటి!",
              "or": "କାରଣ ସେଇସବୁ ହି ଆମେ ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ରେ ଶିଖୁ ।",
              "as": "কাৰণ হোটেল মেনেজমেণ্টত আমি এইটো বস্তু শিকোঁ!",
              "gu": "કારણ કે હોટેલ મેનેજમેન્ટમાં આપણે જે શીખીએ છીએ તેમાંનું એક છે!"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2ii_2_4////",
              "hi": "A2II_2_4 ////",
              "ka": "A2ii_2_4////",
              "te": "A2ii_2_4////",
              "or": "A2ii_2_4////",
              "as": "A2ii_2_4////",
              "gu": "A2ii_2_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "It’s a 4-year course that includes managing a hotel or running a commercial kitchen and bakery. At the end of the course, you get a Bachelor’s degree in Hotel Management (B.HM).",
              "hi": "यह एक 4 साल का कोर्स है जिसमें एक होटल का प्रबंधन करना या व्यावसायिक रसोई और बेकरी चलाना शामिल है। कोर्स के अंत में, आपको होटल प्रबंधन (बीएचएम) में बैचलर/ स्नातक की डिग्री मिलती है।",
              "ka": "ಇದು 4 ವರ್ಷಗಳ ಕೋರ್ಸ್ ಆಗಿದ್ದು ಅದು ಹೋಟೆಲ್ ಅನ್ನು ನಿರ್ವಹಿಸುವುದು ಅಥವಾ ವಾಣಿಜ್ಯ ಅಡುಗೆಮನೆ ಮತ್ತು ಬೇಕರಿಯನ್ನು ನಡೆಸುವುದು ಒಳಗೊಂಡಿರುತ್ತದೆ. ಕೋರ್ಸ್‌ನ ಕೊನೆಯಲ್ಲಿ, ನೀವು ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್‌ನಲ್ಲಿ (B.HM) ಬ್ಯಾಚುಲರ್ ಪದವಿಯನ್ನು ಪಡೆಯುತ್ತೀರಿ.",
              "te": "ఇది హోటల్‌ను మేనేజ్ చేయడం లేదా వాణిజ్య వంటగది మరియు బేకరీని మేనేజ్ చేయడం వంటివి నేర్పించే 4-సంవత్సరాల కోర్సు. కోర్సు ముగింపులో, మీరు హోటల్ మేనేజ్‌మెంట్ (B.HM)లో బ్యాచిలర్ డిగ్రీని పొందుతారు.",
              "or": "ଏହା ଏକ ୪ ବର୍ଷୀୟ ପାଠ୍ୟକ୍ରମ ଯାହାକି ହୋଟେଲ ପରିଚାଳନା କିମ୍ବା ବାଣିଜ୍ୟ ଭିତ୍ତିକ \n ରୋଷେଇ ଘର ଏବଂ ବେକେରି ଦୋକାନ କୁ କିପରି ଚଳାଇବାକୁ ହୁଏ, ତାହାର ଅନ୍ତର୍ଭୁକ୍ତ। \n ପରିଶେଷରେ ତୁମେ ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟର ଗୋଟିଏ ଡିଗ୍ରୀ ପାଇବା। (B.HM)",
              "as": "ই হৈছে 4 বছৰীয়া পাঠ্যক্ৰম য'ত হোটেল এখন পৰিচালনা কৰা বা বাণিজ্যিক পাকঘৰ আৰু বেকাৰী চলোৱা বিষয় অন্তৰ্ভুক্ত থাকে। পাঠ্যক্ৰমৰ শেষত, তুমি হোটেল মেনেজমেণ্টত স্নাতক ডিগ্ৰী লাভ কৰা (B.HM)।",
              "gu": "તે 4-વર્ષનો કોર્સ છે જેમાં હોટલનું સંચાલન અથવા કોમર્શિયલ રસોડુ અને બેકરી ચલાવવાનો સમાવેશ થાય છે. કોર્સના અંતે, તમે હોટેલ મેનેજમેન્ટ (B.HM) માં સ્નાતકની ડિગ્રી મેળવો છો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "And what will you do after finishing this course?",
              "hi": "और इस कोर्स को खत्म करने के बाद आप क्या करेंगे?",
              "ka": "ಮತ್ತು ಈ ಕೋರ್ಸ್ ಮುಗಿದ ನಂತರ ನೀವು ಏನು ಮಾಡುತ್ತೀರಿ?",
              "te": "మరియు ఈ కోర్సు పూర్తి చేసిన తర్వాత మీరు ఏమి చేస్తారు?",
              "or": "ଏବଂ ଏହି ପାଠସାରିଲା ପରେ ତୁମେ କେଉଁ କମ କରିବ?",
              "as": "আৰু এই পাঠ্যক্ৰমটো শেষ কৰাৰ পিছত তুমি কি কৰিবা?",
              "gu": "અને આ કોર્સ પૂરો કર્યા પછી તમે શું કરશો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Well, you can become anything from a hotel manager to a chef, depending on your interest.",
              "hi": "आप अपनी रुचि के आधार पर एक होटल मैनेजर से लेकर एक शेफ तक कुछ भी बन सकते हैं।",
              "ka": "ಒಳ್ಳೆಯದು, ನಿಮ್ಮ ಆಸಕ್ತಿಗೆ ಅನುಗುಣವಾಗಿ ನೀವು ಹೋಟೆಲ್ ಮ್ಯಾನೇಜರ್‌ನಿಂದ ಬಾಣಸಿಗರವರೆಗೆ ಏನು ಬೇಕಾದರೂ ಆಗಬಹುದು.",
              "te": "మీ ఆసక్తిని బట్టి మీరు హోటల్ మేనేజర్ నుండి వంటవాడు వరకు ఏదైనా కావచ్చు.",
              "or": "ତେବେ , ତୁମେ ନିଜ ରୁଚି ଅନୁଯାୟୀ ବାବର୍ଚି କିମ୍ବା ହୋଟେଲ ମ୍ୟାନେଜର ର \n କାମ କରିପାରିବ।",
              "as": "হয়, তোমাৰ আগ্ৰহৰ ওপৰত নিৰ্ভৰ কৰি, তুমি হোটেল মেনেজাৰৰ পৰা ৰান্ধনীলৈকে যিকোনো হ'ব পাৰা।",
              "gu": "સારું, તમે તમારી રુચિના આધારે હોટેલ મેનેજરથી લઈને રસોઇયા સુધી કંઈપણ બની શકો છો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "A chef?!!",
              "hi": "एक शेफ?!!",
              "ka": "ಒಬ್ಬ ಬಾಣಸಿಗ?!!",
              "te": "ఒక వంటవాడు?!!",
              "or": "ଗୋଟେ ରୋଷେୟା ?",
              "as": "এজন ৰান্ধনী?!!",
              "gu": "રસોઇયા?!!"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2ii_2_5",
              "hi": "A2II_2_5",
              "ka": "A2ii_2_5",
              "te": "A2ii_2_5",
              "or": "A2ii_2_5",
              "as": "A2ii_2_5",
              "gu": "A2ii_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar also finds that he has to take an entrance exam called  The National Council for Hotel Management and Catering Technology Joint Entrance Exam (NCHMCT JEE).",
              "hi": "शिखर को यह भी पता चलता है कि उसे नेशनल काउंसिल ऑफ होटल मैनेजमेंट एंड कैटरिंग टेक्नोलॉजी संयुक्त प्रवेश परीक्षा (एनसीएचएमसीटी जेईई) नामक एक प्रवेश परीक्षा देनी है।",
              "ka": "ಶಿಖರ್  ನ್ಯಾಶನಲ್ ಕೌನ್ಸಿಲ್ ಫಾರ್ ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್ ಮತ್ತು ಕ್ಯಾಟರಿಂಗ್ ಟೆಕ್ನಾಲಜಿ ಜಂಟಿ ಪ್ರವೇಶ ಪರೀಕ್ಷೆ (NCHMCT JEE) ಎಂಬ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಯನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕು ಎಂದು ಕಂಡುಕೊಂಡನು..",
              "te": "అతను నేషనల్ కౌన్సిల్ ఫర్ హోటల్ మేనేజ్‌మెంట్ అండ్ క్యాటరింగ్ టెక్నాలజీ జాయింట్ ఎంట్రన్స్ ఎగ్జామ్ (NCHMCT JEE) అనే ప్రవేశ పరీక్షలో పాల్గొనవలసి ఉందని శిఖర్ కనుగొన్నాడు.",
              "or": "ଶିଖର ଜାଣିଲ ଯେ ତାକୁ The National Council for Hotel Management and Catering Technology Joint Entrance Exam (NCHMCT JEE). ନାଁରେ ଏକ ପ୍ରବେଶିକା ପରୀକ୍ଷା ଦେବାକୁ ହେବ।",
              "as": "শিখৰে লগতে গম পালে যে সি ৰাষ্ট্ৰীয় হোটেল মেনেজমেণ্ট আৰু কেটাৰিং প্ৰযুক্তি যুটীয়া প্ৰৱেশ পৰীক্ষা (NCHMCT JEE) নামৰ প্ৰৱেশ পৰীক্ষা দিব লাগিব।",
              "gu": "શિખરને એ પણ જાણવા મળ્યું કે તેણે નેશનલ કાઉન્સિલ ફોર હોટેલ મેનેજમેન્ટ એન્ડ કેટરિંગ ટેક્નોલોજી જોઈન્ટ એન્ટ્રન્સ એક્ઝામ (NCHMCT JEE) નામની પ્રવેશ પરીક્ષા આપવાની છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2ii_1_4",
              "hi": "A2II_1_4",
              "ka": "A2ii_1_4",
              "te": "A2ii_1_4",
              "or": "A2ii_1_4",
              "as": "A2ii_1_4",
              "gu": "A2ii_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar is very excited to know about hotel management. He looks around the rest of the tables, talks to some of the other students and finds out more about their course and curriculum.",
              "hi": "होटल प्रबंधन के बारे में जानने के लिए शिखर बहुत उत्साहित है। वह बाकी मेजों के चारों ओर देखता है, कुछ अन्य छात्रों से बात करता है और उनके कोर्स और  पाठ्यक्रम के बारे में अधिक जानकारी लेता है।",
              "ka": "ಶಿಖರ್ ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಲು ತುಂಬಾ ಉತ್ಸುಕನಾಗಿದ್ದಾನೆ. ಅವನು ಉಳಿದ ಟೇಬಲ್‌ಗಳ ಸುತ್ತಲೂ ನೋಡುತ್ತಾನೆ, ಇತರ ಕೆಲವು ವಿದ್ಯಾರ್ಥಿಗಳೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾನೆ ಮತ್ತು ಅವರ ಕೋರ್ಸ್ ಮತ್ತು ಪಠ್ಯಕ್ರಮದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "హోటల్ మేనేజ్‌మెంట్ గురించి తెలుసుకోవడానికి శిఖర్ చాలా ఉత్సాహంగా ఉన్నాడు. అతను మిగిలిన బల్లల చుట్టూ చూస్తాడు, మరికొందరు విద్యార్థులతో మాట్లాడతాడు మరియు వారి కోర్సు మరియు పాఠ్యాంశాల గురించి మరింత తెలుసుకుంటాడు.",
              "or": "ଶିଖର ଏହି ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ଆଗ୍ରହୀ। ସେ ଅନ୍ୟସବୁ ଟେବଲ \n ଉପରେ ନଜର ପକାଇଲା। ଅଲଗା ଛାତ୍ରଛାତ୍ରୀଙ୍କ ସହ କଥା ହୋଇ ଅନ୍ୟାନ୍ୟ ପାଠ୍ୟକ୍ରମ \n ବିଷୟରେ ବୁଝିଲା।",
              "as": "শিখৰ হোটেল মেনেজমেণ্টৰ বিষয়ে জানিবলৈ বৰ আগ্ৰহী। সি বাকী টেবুলবোৰৰ চাৰিওফালে চায়, আন কিছুমান শিক্ষাৰ্থীৰ সৈতে কথা পাতে আৰু তেওঁলোকৰ পাঠ্যক্ৰমৰ বিষয়ে অধিক জানিব পাৰে।",
              "gu": "શિખર હોટલ મેનેજમેન્ટ વિશે જાણીને ખૂબ જ ઉત્સાહિત છે. તે બાકીના ટેબલોની આસપાસ જુએ છે, અન્ય કેટલાક વિદ્યાર્થીઓ સાથે વાત કરે છે અને તેમના અભ્યાસક્રમ અને અભ્યાસક્રમ વિશે વધુ માહિતી મેળવે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2ii_1_5",
              "hi": "A2II_1_5",
              "ka": "A2ii_1_5",
              "te": "A2ii_1_5",
              "or": "A2ii_1_5",
              "as": "A2ii_1_5",
              "gu": "A2ii_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He exits the room with many thoughts in his head. He is nervous about how his father will respond to this course.",
              "hi": "वह अपने मन में कई विचारों को लेकर कमरे से बाहर निकलता है। वह इस बारे में परेशान है कि उसके पिता इस कोर्स के लिए कैसी प्रतिक्रिया देंगे।",
              "ka": "ಅವನು ತನ್ನ ತಲೆಯಲ್ಲಿ ಅನೇಕ ಆಲೋಚನೆಗಳೊಂದಿಗೆ ಕೋಣೆಯಿಂದ ನಿರ್ಗಮಿಸುತ್ತಾನೆ. ಈ ಕೋರ್ಸ್‌ಗೆ ತನ್ನ ತಂದೆ ಹೇಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುತ್ತಾರೆ ಎಂದು ಅವನು ಆತಂಕಗೊಂಡಿದ್ದಾನೆ.",
              "te": "అతను తన తలలో అనేక ఆలోచనలతో గది నుండి బయటికి వెళ్తాడు. ఈ కోర్సుపై తన తండ్రి ఎలా స్పందిస్తాడోనని అతను భయపడుతున్నాడు.",
              "or": "ନିଜ ମନ ରେ ଅନେକ ଗୁଡିଏ ଭାବନକୁ ନେଇ ସେ ପ୍ରସ୍ଥାନ କରେ । ନିଜ ବାପଙ୍କର\n ଏହି ପାଠ୍ୟକ୍ରମ ପ୍ରତି ପ୍ରତିକ୍ରିୟା କୁ ଭାବି ଭାବି ସେ ଡର ଅନୁଭବ କରେ ।",
              "as": "সি মূৰত বহুতো চিন্তা লৈ কোঠাটোৰ পৰা ওলাই গ'ল। তাৰ দেউতাকে এই পাঠ্যক্ৰমত কেনেদৰে সঁহাৰি দিব তাক লৈ সি ভয় খাইছে।",
              "gu": "તે તેના મગજમાં ઘણા વિચારો સાથે રૂમની બહાર નીકળે છે. તેના પિતા આ કોર્સ માટે કેવી પ્રતિક્રિયા આપશે તે અંગે તે નિરાશ છે."
            }
          }
        ]
      },
      "scene16": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2iii_1_1",
              "hi": "A2III_1_1",
              "ka": "A2iii_1_1",
              "te": "A2iii_1_1",
              "or": "A2iii_1_1",
              "as": "A2iii_1_1",
              "gu": "A2iii_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar enters the humanities department room.",
              "hi": "शिखर ह्यूमैनिटी/ मानविकी विभाग के कमरे में प्रवेश करता है।",
              "ka": "ಶಿಖರ್ ಹ್ಯುಮಾನಿಟೀಸ್  ವಿಭಾಗದ ಕೊಠಡಿಯನ್ನು ಪ್ರವೇಶಿಸಿದನು.",
              "te": "శిఖర్ హ్యుమానిటీస్ డిపార్ట్‌మెంట్ గదిలోకి ప్రవేశించాడు.",
              "or": "ଶିଖର ମାନବିକ ବିଭାଗରେ ପ୍ରବେଶ କରେ।",
              "as": "শিখৰে হিউমেনিটিজ বিভাগৰ কোঠালৈ যায়।",
              "gu": "શિખર માનવતા વિભાગના રૂમમાં પ્રવેશ્યો."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2iii_1_2",
              "hi": "A2III_1_2",
              "ka": "A2iii_1_2",
              "te": "A2iii_1_2",
              "or": "A2iii_1_2",
              "as": "A2iii_1_2",
              "gu": "A2iii_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He learns that they teach many interesting subjects here like history, geography, political science etc.",
              "hi": "उसे पता चलता है कि वे यहां इतिहास, भूगोल, राजनीतिक विज्ञान इत्यादि जैसे अनेक रोचक विषयों को पढ़ाते हैं।",
              "ka": "ಇಲ್ಲಿ ಇತಿಹಾಸ, ಭೌಗೋಳಿಕತೆ, ರಾಜಕೀಯ ವಿಜ್ಞಾನ ಮುಂತಾದ ಅನೇಕ ಆಸಕ್ತಿದಾಯಕ ವಿಷಯಗಳನ್ನು ಕಲಿಸುತ್ತಾರೆ ಎಂದು ಅವನು ಅರಿತುಕೊಂಡನು..",
              "te": "వారు ఇక్కడ చరిత్ర, భౌగోళిక శాస్త్రం, రాజకీయ శాస్త్రం మొదలైన అనేక ఆసక్తికరమైన విషయాలను బోధిస్తున్నారని అతను తెలుసుకున్నాడు.",
              "or": "ସେ ଜାଣିଲା ଯେ ଏଠାରେ ବହୁତ କିଛି ମନଲୋଭା ପାଠ ପଢାଯାଏ । ଯେମିତିକି,\n ଇତିହାସ, ଭୂଗୋଳ, ରାଜନୀତି ବିଜ୍ଞାନ ଇତ୍ୟାଦି।",
              "as": "সি জানিব পাৰে যে তেওঁলোকে ইয়াত ইতিহাস, ভূগোল, ৰাজনীতি বিজ্ঞান আদিৰ দৰে বহুতো আকৰ্ষণীয় বিষয় শিকায়।",
              "gu": "તે અહીં ઇતિહાસ, ભૂગોળ, રાજકીય વિજ્ઞાન વગેરે જેવા ઘણા રસપ્રદ વિષયો શીખે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2iii_1_3",
              "hi": "A2III_1_3",
              "ka": "A2iii_1_3",
              "te": "A2iii_1_3",
              "or": "A2iii_1_3",
              "as": "A2iii_1_3",
              "gu": "A2iii_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He talks to some of the students and finds out that the course is quite theoretical, with a lot of written work and research.",
              "hi": "वह कुछ छात्रों से बात करता है और जानता है कि कोर्स काफी सैद्धांतिक है, जिसमें बहुत सारे लिखित कार्य और शोध हैं।",
              "ka": "ಅವನು ಕೆಲವು ವಿದ್ಯಾರ್ಥಿಗಳೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾನೆ ಮತ್ತು ಸಾಕಷ್ಟು ಲಿಖಿತ ಕೆಲಸ ಹಾಗೂ ಸಂಶೋಧನೆಯೊಂದಿಗೆ ಕೋರ್ಸ್ ಸಾಕಷ್ಟು ಸಿದ್ದಾಂತಗಳನ್ನು ಹೊಂದಿದೆ ಎಂದು ಕಂಡುಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "అతను కొంతమంది విద్యార్థులతో మాట్లాడాడు మరియు చాలా వ్రాతపూర్వక పని మరియు పరిశోధనతో కోర్సు ఎక్కువ సిద్ధాంతపరంగా ఉందని తెలుసుకుంటాడు.",
              "or": "କିଛି ଛାତ୍ରଛାତ୍ରୀଙ୍କ ସହ କଥା ହୋଇ ସେ ଜାଣିଲା ଯେ ଏହି ପାଠ ସନ୍ଧାନ ପୂର୍ଣ୍ଣ ଏବଂ \n ଏଥିରେ ଗବେଷଣା ସମ୍ବନ୍ଧୀୟ ବହୁତ କିଛି ଲେଖା ଯାଏ।",
              "as": "সি কিছুমান শিক্ষাৰ্থীৰ সৈতে কথা পাতিছিল আৰু গম পাইছিল যে পাঠ্যক্ৰমটো বহুতো লিখিত কাম আৰু গৱেষণাৰ সৈতে, যথেষ্ট তাত্ত্বিক।",
              "gu": "તે કેટલાક વિદ્યાર્થીઓ સાથે વાત કરે છે અને શોધે છે કે અભ્યાસક્રમ તદ્દન સૈદ્ધાંતિક છે, જેમાં ઘણાં લેખિત કાર્ય અને સંશોધન છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2iii_1_4",
              "hi": "A2III_1_4",
              "ka": "A2iii_1_4",
              "te": "A2iii_1_4",
              "or": "A2iii_1_4",
              "as": "A2iii_1_4",
              "gu": "A2iii_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He finds history and politics very interesting but is not too keen on submitting so many written documents.",
              "hi": "उसे इतिहास और राजनीति बहुत दिलचस्प लगती है लेकिन इतने सारे लिखित दस्तावेज जमा करने के लिए बहुत उत्सुक नहीं हैं।",
              "ka": "ಅವನು ಇತಿಹಾಸ ಮತ್ತು ರಾಜಕೀಯವನ್ನು ಬಹಳ ಆಸಕ್ತಿದಾಯಕವಾಗಿ ಕಂಡುಕೊಳ್ಳುತ್ತಾನೆ. ಆದರೆ ಹಲವಾರು ಲಿಖಿತ ದಾಖಲೆಗಳನ್ನು ಸಲ್ಲಿಸಲು ಹೆಚ್ಚು ಉತ್ಸುಕನಾಗಿರುವುದಿಲ್ಲ.",
              "te": "అతను చరిత్ర మరియు రాజకీయాలను చాలా ఆసక్తికరంగా భావిస్తాడు, కానీ చాలా వ్రాతపూర్వక పత్రాలను సమర్పించడానికి పెద్దగా ఆసక్తి చూపలేదు.",
              "or": "ଇତିହାସ ଓ ରାଜନୀତି ବିଜ୍ଞାନ କୁ ସେ ମନଲୋଭା ପାଠ ବୋଲି ସେ ଭାବେ କିନ୍ତୁ\n ଏତେ ସାରା ଲିଖିତ ଦସ୍ତବେଜ କୁ ସେ ଉତ୍ସଆହପୂର୍ଣ୍ଣ ବୋଲି ମାନେ କରେ ନହିଁ।",
              "as": "সি ইতিহাস আৰু ৰাজনীতি বৰ ভাল পালে কিন্তু ইমান বোৰ লিখিত ডকুমেন্ট জমা দিবলৈ বৰ আগ্ৰহী নহয়।",
              "gu": "તેને ઈતિહાસ અને રાજકારણ ખૂબ જ રસપ્રદ લાગે છે પરંતુ આટલા બધા લેખિત દસ્તાવેજો જમા કરવા માટે તેઓ બહુ ઉત્સુક નથી."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2iii_1_5",
              "hi": "A2III_1_5",
              "ka": "A2iii_1_5",
              "te": "A2iii_1_5",
              "or": "A2iii_1_5",
              "as": "A2iii_1_5",
              "gu": "A2iii_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The subjects in the curriculum are very different from what he studied in school, so he decides to explore other departments.",
              "hi": "पाठ्यक्रम में विषय, उससे बहुत अलग हैं जो उसने स्कूल में अध्ययन किये थे, इसलिए वह अन्य विभागों का पता लगाने का फैसला करता है।",
              "ka": "ಪಠ್ಯಕ್ರಮದಲ್ಲಿನ ವಿಷಯಗಳು ಅವನು ಶಾಲೆಯಲ್ಲಿ ಓದಿದ ವಿಷಯಕ್ಕಿಂತ ತುಂಬಾ ಭಿನ್ನವಾಗಿರುತ್ತವೆ, ಆದ್ದರಿಂದ ಅವನು ಇತರ ವಿಭಾಗಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "పాఠ్యాంశాల్లోని సబ్జెక్టులు అతను పాఠశాలలో చదివిన వాటికి చాలా భిన్నంగా ఉన్నాయి, కాబట్టి అతను ఇతర విభాగాలను అన్వేషించాలని నిర్ణయించుకున్నాడు.",
              "or": "ସ୍କୁଲ ରେ ପଢିଥିବା ପାଠକୁ ସେ ଏଏହି ପାଠ୍ୟକ୍ରମରେ ଲେଖାଯାଇଥିବା ପାଠ ଠାରୁ \n ଢେର ଅଲଗା ବୋଲି ମନେକରେ। ତେଣୁ ଅଲଗା ବିଭାଗ ବୁଲି ଦେଖିବାକୁ ମନସ୍ଥ କରେ",
              "as": "পাঠ্যক্ৰমৰ বিষয়বোৰ সি স্কুলত পঢ়া বিষয়তকৈ বহুত বেলেগ, সেয়েহে সি আন বিভাগবোৰ চোৱাৰ কথা ভাৱে।",
              "gu": "અભ્યાસક્રમના વિષયો તેણે શાળામાં ભણેલા વિષયો કરતા ઘણા અલગ છે, તેથી તે અન્ય વિભાગોમાં શોધખોળ કરવાનું નક્કી કરે છે."
            }
          }
        ]
      },
      "scene17": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2iv_1_1",
              "hi": "A2IV_1_1",
              "ka": "A2iv_1_1",
              "te": "A2iv_1_1",
              "or": "A2iv_1_1",
              "as": "A2iv_1_1",
              "gu": "A2iv_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar walks up to the engineering department room.",
              "hi": "शिखर इंजीनियरिंग विभाग के कमरे तक चला जाता है।",
              "ka": "ಶಿಖರ್ ಇಂಜಿನಿಯರಿಂಗ್ ಡಿಪಾರ್ಟ್ಮೆಂಟ್ ರೂಮಿನತ್ತ ನಡೆದನು.",
              "te": "శిఖర్‌ ఇంజినీరింగ్‌ డిపార్ట్‌మెంట్‌ గదికి వెళ్లాడు.",
              "or": "ଶିଖର ଇଞ୍ଜିନିଅର ବିଭାଗ ମଧ୍ୟକୁ ପ୍ରବେଶ କରେ।",
              "as": "শিখৰে ইঞ্জিনিয়াৰিং বিভাগৰ কোঠালৈ যায়।",
              "gu": "શિખર એન્જિનિયરિંગ વિભાગના રૂમમાં ચાલ્યો ગયો."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2iv_1_2",
              "hi": "A2IV_1_2",
              "ka": "A2iv_1_2",
              "te": "A2iv_1_2",
              "or": "A2iv_1_2",
              "as": "A2iv_1_2",
              "gu": "A2iv_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Since Sujay had shown him around his department already, Shikhar has an idea about what they study.",
              "hi": "चूंकि सुजय ने उसे पहले से ही अपना विभाग दिखाया था, इसलिए शिखर को इस विषय में जानकारी है कि वे क्या अध्ययन करते हैं।",
              "ka": "ಸುಜಯ್ ಅವನಿಗೆ ಈಗಾಗಲೇ ತನ್ನ ವಿಭಾಗದ ಸುತ್ತಲೂ ತೋರಿಸಿದ್ದರಿಂದ, ಶಿಖರ್‌ಗೆ ಅವನು ಏನು ಓದುತ್ತಾನೆ ಎಂಬುದರ ಬಗ್ಗೆ ಒಂದು ಕಲ್ಪನೆ ಇದೆ",
              "te": "సుజయ్ అప్పటికే తన డిపార్ట్‌మెంట్‌ చుట్టూ చూపించాడు కాబట్టి, శిఖర్‌కి వాళ్ళు ఏమి చదువుతారు అని ఆలోచన ఉంది.",
              "or": "ଯେହେତୁ ସୁଜୟ ତାକୁ ଆଗରୁ ଏହି ବିଭାଗ ଦେଖାଇଥିଲା, ତେଣୁ ତାର ସେ\n  ବାବଦରେ କିଛି ଅନୁଭୂତି ଥିଲା।",
              "as": "যিহেতু সুজয়ে তাক ইতিমধ্যে তাৰ বিভাগৰ চাৰিওফালে দেখুৱাইছিল, তেওঁলোকে কি অধ্যয়ন কৰে সেই বিষয়ে শিখৰৰ এক আইডিয়া আছে।",
              "gu": "સુજયે તેને તેના વિભાગ પહેલેથી જ બાતવ્યો હોવાથી, શિખરને ખબર છે કે તેઓ શું અભ્યાસ કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2iv_1_3",
              "hi": "A2IV_1_3",
              "ka": "A2iv_1_3",
              "te": "A2iv_1_3",
              "or": "A2iv_1_3",
              "as": "A2iv_1_3",
              "gu": "A2iv_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He peeps inside and sees many parents and students standing around the tables where the faculty members are sitting.",
              "hi": "वह अंदर झांकता है और कई माता-पिता तथा छात्रों को मेज के चारों ओर खड़े देखता है जहां फैकल्टी/ संकाय सदस्य बैठे हैं।",
              "ka": "ಅವನು ಒಳಗೆ ಇಣುಕಿ ನೋಡುತ್ತಾನೆ ಮತ್ತು ಅಧ್ಯಾಪಕರು ಕುಳಿತಿರುವ ಟೇಬಲ್‌ಗಳ ಸುತ್ತಲೂ ಅನೇಕ ಪೋಷಕರು ಮತ್ತು ವಿದ್ಯಾರ್ಥಿಗಳು ನಿಂತಿರುವುದನ್ನು ನೋಡುತ್ತಾನೆ.",
              "te": "అతను లోపలికి చూసాడు మరియు అధ్యాపకులు కూర్చున్న బల్లల చుట్టూ చాలా మంది తల్లిదండ్రులు మరియు విద్యార్థులు నిలబడి ఉన్నారు.",
              "or": "ସେ ସେହି କୋଠରୀ ମଧ୍ୟକୁ ନିରୀକ୍ଷଣ କରେ ଓ ଦେଖିଲା ଯେ ବହୁତ ଅଭିଭବକ ଓ ଛାତ୍ର ଛାତ୍ରୀ ବିଭାଗୀୟ ସଭ୍ୟ ମାନେ ବସିଥିବା ଟେବଲ ଚାରିପାଖରେ ଛିଡା ହୋଇଛନ୍ତି।",
              "as": "সি ভিতৰলৈ জুমি চাই বহুতো অভিভাৱক আৰু শিক্ষাৰ্থীক টেবুলৰ চাৰিওফালে থিয় হৈ থকা দেখিলে য'ত শিক্ষকসকল বহি আছে।",
              "gu": "તે અંદર ડોકિયું કરે છે અને ઘણા વાલીઓ અને વિદ્યાર્થીઓને ટેબલની આજુબાજુ ઉભેલા જોયા છે જ્યાં શિક્ષક સભ્યો બેઠા છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2iv_1_4",
              "hi": "A2IV_1_4",
              "ka": "A2iv_1_4",
              "te": "A2iv_1_4",
              "or": "A2iv_1_4",
              "as": "A2iv_1_4",
              "gu": "A2iv_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He decides to skip this room and move ahead. He knows he can talk to Sujay if he wants to find out anything more.",
              "hi": "वह इस कमरे को छोड़कर आगे बढ़ने का फैसला करता है। उसे पता है कि अगर वह कुछ और जानना चाहता है तो वह सुजय से बात कर सकता है ।",
              "ka": "ಅವನು ಈ ಕೊಠಡಿಯನ್ನು ಬಿಟ್ಟು ಮುಂದೆ ಹೋಗಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.  ಏನಾದರೂ ಹೆಚ್ಚು ತಿಳಿದುಕೊಳ್ಳಲು ಬಯಸಿದರೆ ಸುಜಯ್‌ ಒಂದಿಗೆ ಮಾತನಾಡಬಹುದು ಎಂದು ಅವನಿಗೆ ತಿಳಿದಿದೆ.",
              "te": "అతను ఈ గదిని దాటి ముందుకు వెళ్లాలని నిర్ణయించుకున్నాడు. ఇంకేమైనా తెలుసుకోవాలంటే సుజయ్‌ తో మాట్లాడవచ్చని అతనికి తెలుసు.",
              "or": "ସେ ଏହି କୋଠରୀ କୁ ଛାଡିଯିବାକୁ ନିଷ୍ପତ୍ତି ନେଲା। କିଛି ଅଧିକ ଜାଣିବା ଦରକାର \n ହେଲେ ସେ ସୁଜୟ କୁ ପଚାରିନବ ବୋଲି ଜାଣିଥିଲା।",
              "as": "সি এই কোঠাটো এৰি আগবাঢ়ি যায়। সি জানে যে সি আৰু কিবা বিচাৰিব খুজিলে সি সুজয়ৰ সৈতে কথা পাতিব পাৰে।",
              "gu": "તે આ રૂમ છોડીને આગળ વધવાનું નક્કી કરે છે. તે જાણે છે કે જો તે વધુ કંઈ જાણવા માંગતો હોય તો તે સુજય સાથે વાત કરી શકે છે."
            }
          }
        ]
      },
      "scene18": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_1",
              "hi": "A3_1_1",
              "ka": "A3_1_1",
              "te": "A3_1_1",
              "or": "A3_1_1",
              "as": "A3_1_1",
              "gu": "A3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar is back home and is happy with his experience at the college. He is confused about what course he should take up. Sujay suggests Shikhar to map out his likes, dislikes and the courses that interest him.",
              "hi": "शिखर घर वापस आ गया है और कॉलेज में अपने अनुभव से खुश है। वह दुविधा में है कि उसे कौन सा कोर्स करना चाहिए। सुजय ने शिखर को अपनी पसंद, नापसंद और उन कोर्स का ब्यौरा तैयार करने का सुझाव दिया।",
              "ka": "ಶಿಖರ್ ಮನೆಗೆ ಮರಳಿದ್ದು ಕಾಲೇಜಿನ ಅನುಭವದಿಂದ ಸಂತಸಗೊಂಡಿದ್ದಾನೆ. ಯಾವ ಕೋರ್ಸ್ ತೆಗೆದುಕೊಳ್ಳಬೇಕು ಎಂಬ ಗೊಂದಲದಲ್ಲಿದ್ದಾನೆ. ಸುಜಯ್ ಶಿಖರ್‌ಗೆ ಅವನ ಇಷ್ಟಗಳು, ಇಷ್ಟಪಡದಿರುವಿಕೆಗಳು ಮತ್ತು ಅವನಿಗೆ ಆಸಕ್ತಿಯಿರುವ ಕೋರ್ಸ್‌ಗಳನ್ನು ನಕ್ಷೆ ಮಾಡಲು ಸೂಚಿಸುತ್ತಾನೆ.",
              "te": "శిఖర్ ఇంటికి తిరిగి వచ్చాడు మరియు కళాశాలలో తన అనుభవంతో సంతోషంగా ఉన్నాడు. ఏ కోర్సు చదవాలో తెలియక అయోమయంలో ఉన్నాడు. సుజయ్ శిఖర్‌ కి అతని ఇష్టాలు, అయిష్టాలు మరియు అతనికి ఆసక్తి ఉన్న కోర్సులను మ్యాప్ చేయమని సూచించాడు.",
              "or": "ଶିଖର ଘରକୁ ଫେରି ଆସିଲା ଓ କଲେଜରେ ନିଜ ଅନୁଭୂତି ସହ ସେ ଖୁସି ଥିଲା। \n କେଉଁ ବିଷୟରେ ସେ ପାଠ ପଢିବ ସେ ନେଇ ସେ ଦ୍ଵନ୍ଦ୍ଵ ରେ ଥିଲ। ନିଜ ପସନ୍ଦ \n ନାପସନ୍ଦକୁ ଚୟନ କରି ରୁଚି ଅନୁସାରେ ପାଠ୍ୟକ୍ରମ ନବକୁ ସୁଜୟ ପ୍ରସ୍ତାବ ଦେଲା।",
              "as": "শিখৰ ঘৰলৈ উভতি আহিছে আৰু কলেজত তাৰ অভিজ্ঞতাত সুখী। সি কি পথ ল'ব লাগে তাক লৈ সি চিন্তিত। সুজয়ে শিখৰক তাৰ পছন্দ, অপছন্দ আৰু তাক আগ্ৰহী পাঠ্যক্ৰমবোৰ বাছিবলৈ পৰামৰ্শ দিয়ে।",
              "gu": "શિખર ઘરે પાછો ફર્યો છે અને કોલેજમાંના અનુભવથી તે ખુશ છે. તેણે કયો કોર્સ કરવો તે અંગે તે મૂંઝવણમાં છે. સુજય શિખરને તેની પસંદ, નાપસંદ અને તેને રુચિ ધરાવતા કોર્સનો મેપ બનાવવાનું સૂચન કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_2_notebook",
              "hi": "A3_1_2_नोटबुक ",
              "ka": "A3_1_2_notebook",
              "te": "A3_1_2_పుస్తకం",
              "or": "A3_1_2_notebook",
              "as": "A3_1_2_notebook",
              "gu": "A3_1_2_નોટબુક"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar takes a diary and begins noting down his likes, dislikes and interests.",
              "hi": "शिखर एक डायरी लेता है और उसमें अपनी पसंद, नापसंद और रुचियों को लिखता है।",
              "ka": "ಶಿಖರ್ ಡೈರಿಯನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾನೆ ಮತ್ತು ಅವನ ಇಷ್ಟಗಳು, ಇಷ್ಟಪಡದಿರುವಿಕೆಗಳು ಮತ್ತು ಆಸಕ್ತಿಗಳನ್ನು ಬರೆಯಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ",
              "te": "శిఖర్ డైరీని తీసుకుని, అతని ఇష్టాలు, అయిష్టాలు మరియు ఆసక్తులను నమోదు చేయడం ప్రారంభించాడు.",
              "or": "ପସନ୍ଦ",
              "as": "শিখৰে এখন ডায়েৰী লয় আৰু তাৰ পছন্দ, অপছন্দ আৰু আগ্ৰহবোৰ লিখিবলৈ লয়।",
              "gu": "શિખર એક ડાયરી લે છે અને તેની પસંદ, નાપસંદ અને રુચિઓ નોંધવાનું શરૂ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Likes",
              "hi": " पसंद ",
              "ka": "ಇಷ್ಟಗಳು",
              "te": "ఇష్టాలు",
              "or": "ନାପସନ୍ଦ",
              "as": "পছন্দ",
              "gu": "પસંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Dislikes",
              "hi": "नापसंद ",
              "ka": "ಇಷ್ಟಪಡದಿರುವುದು",
              "te": "అయిష్టాలు",
              "or": "ରୁଚି",
              "as": "অপছন্দ",
              "gu": "નાપસંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Interest",
              "hi": "रुचि ",
              "ka": "ಆಸಕ್ತಿಗಳು",
              "te": "ఆసక్తి",
              "or": "ଆଗ୍ରହ",
              "as": "আগ্ৰহ",
              "gu": "વ્યાજ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "- I like cooking and experimenting in the kitchen.\n\n-I like helping people fix their problems.\n\n-I enjoy learning new things and studying.\n\n- I like trying new apps on the phone and knowing how they work.\n\n- I like to be successful and help my family",
              "hi": "- मुझे रसोई में खाना बनाना और प्रयोग करना पसंद है।\n\n-मुझे लोगों की समस्याओं को सुलझाने में सहायता करना पसंद है।\n\n-मुझे नई चीजों को सीखना और अध्ययन करना पसंद है।\n\n- मुझे फोन पर नए ऐप्स और उनकी प्रक्रिया को समझने की कोशिश करना पसंद है।\n\n- मुझे सफल होना और अपने परिवार की सहायता करना पसंद है",
              "ka": "ನಾನು ಅಡುಗೆಮನೆಯಲ್ಲಿ ಅಡುಗೆ ಮತ್ತು ಪ್ರಯೋಗ ಮಾಡುವುದನ್ನು  ಇಷ್ಟಪಡುತ್ತೇನೆ.                                                                                                                                     ಜನರು ತಮ್ಮ ಸಮಸ್ಯೆಗಳನ್ನು ಪರಿಹರಿಸಲು ಸಹಾಯ ಮಾಡಲು ನಾನು ಇಷ್ಟಪಡುತ್ತೇನೆ.                                                                                                                                 ನಾನು ಹೊಸ ವಿಷಯಗಳನ್ನು ಕಲಿಯಲು ಮತ್ತು ಅಧ್ಯಯನ ಮಾಡಲು ಇಷ್ಟಪಡುತ್ತೇನೆ                                                                                                                                ಫೋನ್‌ನಲ್ಲಿ ಹೊಸ ಆ್ಯಪ್‌ಗಳನ್ನು ಪ್ರಯತ್ನಿಸಲು ಮತ್ತು ಅವು ಹೇಗೆ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತವೆ ಎನ್ನುವುದನ್ನು ತಿಳಿದುಕೊಳ್ಳಲು ನಾನು ಇಷ್ಟಪಡುತ್ತೇನೆ.                                                              ನಾನು ಯಶಸ್ವಿಯಾಗಲು ಮತ್ತು ನನ್ನ ಕುಟುಂಬಕ್ಕೆ ಸಹಾಯ ಮಾಡಲು ಇಷ್ಟಪಡುತ್ತೇನೆ",
              "te": "- నాకు వంటగదిలో వంట చేయడం మరియు ప్రయోగాలు చేయడం ఇష్టం.\n \n -ప్రజలు తమ సమస్యలను పరిష్కరించడంలో సహాయం చేయడం నాకు ఇష్టం.\n \n -కొత్త విషయాలు నేర్చుకోవడం మరియు చదువుకోవడం నాకు చాలా ఇష్టం.\n \n - ఫోన్‌లో కొత్త యాప్‌లను ప్రయత్నించడం మరియు అవి ఎలా పని చేస్తాయో తెలుసుకోవడం నాకు చాలా ఇష్టం.\n \n - నేను విజయవంతం కావడం మరియు నా కుటుంబానికి సహాయం చేయడం ఇష్టం",
              "or": "\"- ମୁଁ ରୋଷେଇ ଘରେ ରୋଷେଇ ଏବଂ ପରୀକ୍ଷଣ କରିବାକୁ ପସନ୍ଦ କରେ |\n \n -ମୁଁ ଲୋକମାନଙ୍କୁ ସେମାନଙ୍କର ସମସ୍ୟା ସମାଧାନ କରିବାରେ ସାହାଯ୍ୟ କରିବାକୁ ପସନ୍ଦ କରେ |\n \n -ମୁଁ ନୂଆ ଜିନିଷ ଶିଖିବା ଏବଂ ଅଧ୍ୟୟନ କରିବାକୁ ଉପଭୋଗ କରେ |\n \n - ମୁଁ ଫୋନରେ ନୂଆ ଆପ୍ ଚେଷ୍ଟା କରିବାକୁ ଏବଂ ସେଗୁଡିକ କିପରି କାମ କରେ ଜାଣିବାକୁ ପସନ୍ଦ କରେ |\n \n - ମୁଁ ସଫଳ ହେବାକୁ ଏବଂ ମୋ ପରିବାରକୁ ସାହାଯ୍ୟ କରିବାକୁ ପସନ୍ଦ କରେ |",
              "as": "\"- মই পাকঘৰত ৰন্ধা আৰু পৰীক্ষা-নিৰীক্ষা ভাল পাওঁ।\n \n -মই লোকসকলক তেওঁলোকৰ সমস্যা সমাধানত সহায় কৰিবলৈ ভাল পাওঁ।\n \n -মই নতুন বস্তু শিকি আৰু পঢ়ি ভাল পাওঁ।\n \n - মই ফোনত নতুন এপ চেষ্টা কৰি ভাল পাওঁ আৰু সেইবোৰে কেনেদৰে কাম কৰে জানি ভাল পাওঁ।\n \n - মই সফল হ'বলৈ আৰু মোৰ পৰিয়ালক সহায় কৰিবলৈ ভাল পাওঁ\"",
              "gu": "મને રસોડામાં રસોઈ બનાવવી અને પ્રયોગ કરવો ગમે છે. - મને લોકોની સમસ્યાઓ ઉકેલવામાં મદદ કરવી ગમે છે. -મને નવી વસ્તુઓ શીખવામાં અને અભ્યાસ કરવામાં આનંદ આવે છે. - મને ફોન પર નવી એપ્સ અજમાવવાનું અને તે કેવી રીતે કામ કરે છે તે જાણવું ગમે છે. - મને સફળ થવું અને મારા પરિવારને મદદ કરવી ગમે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Being compared to others, especially my cousin.\nBeing forced to do engineering.",
              "hi": "दूसरों से तुलना, विशेष रूप से अपने चचेरे भाई से तुलना।\nइंजीनियरिंग करने के लिए मजबूर होना।",
              "ka": "ಇತರರೊಂದಿಗೆ ಹೋಲಿಸಲಾಗುತ್ತಿದೆ, ವಿಶೇಷವಾಗಿ ನನ್ನ ಸೋದರಸಂಬಂಧಿಯೊಂದಿಗೆ, ಇಂಜಿನಿಯರಿಂಗ್ ಮಾಡುವಂತೆ ಒತ್ತಾಯಿಸಲಾಗುತ್ತಿದೆ.",
              "te": "ఇతరులతో, ముఖ్యంగా నా బంధువు తో పోల్చి.\n ఇంజినీరింగ్ చేయమని బలవంతం చేస్తున్నారు.",
              "or": "ଅନ୍ୟ ମାନଙ୍କ ତୁଳନାରେ , ସ୍ଵତନ୍ତ୍ର ଭାବେ ମୋ ଭାଇ।\n ଇଞ୍ଜିନିଅରିଂ କରିବାକୁ ବାଧ୍ୟ କରାଯାଇଥିଲା।",
              "as": "আনৰ সৈতে তুলনা কৰিছে, বিশেষকৈ মোৰ সম্পৰ্কীয় ভাই-ভনীৰ সৈতে।\n ইঞ্জিনিয়াৰিং কৰিবলৈ বাধ্য হৈছোঁ।",
              "gu": "અન્ય લોકો સાથે સરખામણી કરવામાં આવે છે, ખાસ કરીને મારા પિતરાઈ ભાઈ. એન્જિનિયરિંગ કરવાની ફરજ પડી રહી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "After visiting the college, I really like two courses -\nBachelor in Hotel Management and Commerce (B.com).",
              "hi": "कॉलेज जाने के बाद, मुझे वास्तव में दो कोर्स पसंद हैं -\nहोटल मैनेजमेंट और कॉमर्स (बीकॉम)।",
              "ka": "ಕಾಲೇಜಿಗೆ ಭೇಟಿ ನೀಡಿದ ನಂತರ, ನಾನು ಎರಡು ಕೋರ್ಸ್‌ಗಳನ್ನು ನಿಜವಾಗಿಯೂ ಇಷ್ಟಪಡುತ್ತೇನೆ - ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಮತ್ತು ವಾಣಿಜ್ಯದಲ್ಲಿ ಬ್ಯಾಚುಲರ್ (B.com) ಆಗುವುದು.",
              "te": "\"కళాశాలని సందర్శించిన తర్వాత, నాకు రెండు కోర్సులు చాలా ఇష్టం -\n హోటల్ మేనేజ్‌మెంట్ మరియు కామర్స్ లో బ్యాచిలర్ (B.com).\"",
              "or": "ଇଞ୍ଜିନିଅରିଂ କଲେଜ ବୁଲି ଆସିଲା ପରେ , ମୋର ଦୁଇଟି ପାଠ୍ୟକ୍ରମ ପସନ୍ଦ ହୋଇଛି।\n Bachelor in Hotel Management and Commerce (B.com).",
              "as": "কলেজখনলৈ যোৱাৰ পিছত, মই সঁচাকৈয়ে দুটা পাঠ্যক্ৰম ভালপালো-\n হোটেল মেনেজমেণ্ট আৰু বাণিজ্যত স্নাতক (B.com)।",
              "gu": "કોલેજની મુલાકાત લીધા પછી, મને ખરેખર બે અભ્યાસક્રમો ગમે છે - હોટેલ મેનેજમેન્ટ અને કોમર્સમાં બેચલર (B.com)."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_5",
              "hi": "A3_1_5",
              "ka": "A3_1_5",
              "te": "A3_1_5",
              "or": "A3_1_5",
              "as": "A3_1_5",
              "gu": "A3_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar realises that though he has listed B.com, he is more interested in studying Hotel Management. He knows that his family would not be happy with him taking up such a course.",
              "hi": "शिखर को लगता है कि भले ही उसने बीकॉम सूचीबद्ध किया है, लेकिन उसे होटल प्रबंधन का अध्ययन करने में अधिक रुचि है। वह जानता है कि उसका परिवार इस तरह के कोर्स को लेने से खुश नहीं होगा।",
              "ka": "ಶಿಖರ್ ತಾನು ಬಿ.ಕಾಂ ಅನ್ನು ಪಟ್ಟಿ ಮಾಡಿದ್ದರೂ ಸಹಾ ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಓದುವುದರಲ್ಲಿ ಹೆಚ್ಚು ಆಸಕ್ತಿ ಹೊಂದಿದ್ದಾನೆ ಎಂದು ಅರಿತುಕೊಂಡನು ಅವನು ಅಂತಹ ಕೋರ್ಸ್ ತೆಗೆದುಕೊಳ್ಳುವುದರಿಂದ ಅವನ ಕುಟುಂಬವು ಸಂತೋಷವಾಗಿರುವುದಿಲ್ಲ ಎನ್ನುವುದು ಅವನಿಗೆ ತಿಳಿದಿದೆ.",
              "te": "శిఖర్ B.com లో చేరినప్పటికీ, హోటల్ మేనేజ్‌మెంట్ చదవడానికే ఎక్కువ ఆసక్తి ఉందని గ్రహించాడు. అతను అలాంటి కోర్సు తీసుకోవడంతో తన కుటుంబం సంతోషంగా ఉండదని అతనికి తెలుసు.",
              "or": "ଶିଖର ହୃଦୟଙ୍ଗମ କରେ ଯେ ଯଦିଓ ସେ ବାଣିଜ୍ୟ ରେ ନାମଙ୍କନ କରିଛି, ତେବେ \n ତାର ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ପାଇଁ ବେଶୀ ରୁଚି। ସେ ଜାଣେ ଏମିତି ଏକ ପାଠ୍ୟକ୍ରମ ପାଇଁ \n ତା ପରିବାର ଲୋକେ କେବେ ରାଜି ହେବେ ନହିଁ।",
              "as": "শিখৰে ভাবিছে যে যদিও সি B.comত নাম লগাইছে, সি হোটেল মেনেজমেণ্ট পঢ়িবলৈ বেছি আগ্ৰহী। সি জানে যে তাৰ পৰিয়ালে সি এনেধৰণৰ পাঠ্যক্ৰম লোৱাত সুখী নহ'ব।",
              "gu": "શિખરને ખ્યાલ આવે છે કે તેણે B.com નું લિસ્ટ કર્યું હોવા છતાં તેને હોટેલ મેનેજમેન્ટનો અભ્યાસ કરવામાં વધુ રસ છે. તે જાણે છે કે તેનો પરિવાર તેના આવા અભ્યાસક્રમથી ખુશ નહીં થાય."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_6",
              "hi": "A3_1_6",
              "ka": "A3_1_6",
              "te": "A3_1_6",
              "or": "A3_1_6",
              "as": "A3_1_6",
              "gu": "A3_1_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "On the other hand, Commerce is something he could convince them about because of the job opportunities and social acceptability.",
              "hi": "दूसरी तरफ, कॉमर्स/ वाणिज्य ऐसा विषय है जिसे वह नौकरी की उपलब्धता और सामाजिक स्वीकार्यता के कारण उन्हें समझा सकता है।",
              "ka": "ಮತ್ತೊಂದೆಡೆ, ಉದ್ಯೋಗಾವಕಾಶಗಳು ಮತ್ತು ಸಾಮಾಜಿಕವಾಗಿ ಸ್ವೀಕರಿಸಿರುವುದರಿದಾಗಿ ಅವನು ಅವರಿಗೆ ಮನವರಿಕೆ ಮಾಡಬಹುದಾದ ವಿಷಯವೆಂದರೆ ವಾಣಿಜ್ಯವಾಗಿದೆ.",
              "te": "మరోవైపు, కామర్స్ అనేది ఉద్యోగ అవకాశాలు మరియు ఎక్కువ మంది తీసుకుంటారు కాబట్టి అతను వారిని ఒప్పించగలడు.",
              "or": "ଅନ୍ୟପକ୍ଷରେ, ବାଣିଜ୍ୟ ହେଉଛି ଏମିତି ଏକ ପାଠ୍ୟକ୍ରମ ଯାହା ପାଇଁ ସେ ପରିବାର କୁ\n ବିଶ୍ବାସ କରେଇପାରିବା ଯେହେତୁ ଏଥିରେ ସାମାଜିକ ସ୍ତରରେ ଚାକିରି ର ସୁଯୋଗ ରହିଛି।",
              "as": "আনহাতে, চাকৰিৰ সুযোগ আৰু সমাজৰ গ্ৰহণৰ বাবে বাণিজ্য হৈছে এনে এক বিষয় যাৰ বিষয়ে সি তেওঁলোকক পতিয়ন নিয়াব পাৰে।",
              "gu": "બીજી બાજુ, વાણિજ્ય એવી વસ્તુ છે જેના વિશે તેઓ તેને નોકરીની તકો અને સામાજિક સ્વીકાર્યતાને કારણે સમજાવી શકે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_1_7",
              "hi": "A3_1_7",
              "ka": "A3_1_7",
              "te": "A3_1_7",
              "or": "A3_1_7",
              "as": "A3_1_7",
              "gu": "A3_1_7"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "But he still wants to share his interest in Hotel Management with his father.",
              "hi": "लेकिन वह अभी भी होटल प्रबंधन में अपनी रुचि को अपने पिता के साथ साझा करना चाहता है।",
              "ka": "ಆದರೂ ಕೂಡ ಅವನು ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್‌ನಲ್ಲಿ ಇರುವ ತನ್ನ ಆಸಕ್ತಿಯನ್ನು ತನ್ನ ತಂದೆಯೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಲು ಬಯಸುತ್ತಾನೆ.",
              "te": "కానీ అతను ఇప్పటికీ తన తండ్రితో హోటల్ మేనేజ్‌మెంట్ పై తన ఆసక్తి గురించి చెప్పాలనుకుంటున్నాడు.",
              "or": "କିନ୍ତୁ ସେ ତାର ବାପାଙ୍କ ସହ ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ରେ ଥିବା ତାର ରୁଚିକୁ \n ଆଲୋଚନା କରିବାକୁ ଚାହୁଛି।",
              "as": "কিন্তু সি এতিয়াও হোটেল মেনেজমেণ্টৰ প্ৰতি তাৰ আগ্ৰহ তাৰ দেউতাকৰ আগত ক'ব বিচাৰে।",
              "gu": "પરંતુ તે હજુ પણ હોટેલ મેનેજમેન્ટમાં તેની રુચિ તેના પિતા સાથે શેર કરવા માંગે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_2_1//",
              "hi": "A3_2_1 //",
              "ka": "A3_2_1//",
              "te": "A3_2_1//",
              "or": "A3_2_1//",
              "as": "A3_2_1//",
              "gu": "A3_2_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Babuji",
              "hi": "बाबूजी",
              "ka": "ಬಾಬುಜಿ",
              "te": "నాన్నగారు",
              "or": "ବାବୁଜୀ",
              "as": "দেউতা",
              "gu": "બાબુજી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Arun Bhaiya",
              "hi": "अरुण भैया ",
              "ka": "ಅರುಣ್ ಭೈಯ್ಯ",
              "te": "అరుణ్ అన్నయ్య",
              "or": "ଅରୁଣ ଭାଇ",
              "as": "অৰুণ দাদা",
              "gu": "અરુણ ભાઈ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.4.label",
            "value": {
              "en": "Babuji",
              "hi": "बाबूजी",
              "ka": "ಬಾಬುಜಿ",
              "te": "నాన్నగారు",
              "or": "ବାବୁଜୀ",
              "as": "দেউতা",
              "gu": "બાબુજી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Hotel Management? Have you started this nonsense again? I can’t believe my son wants to become a cook!",
              "hi": "होटल प्रबंधन? क्या तुमने इस बकवास को फिर से शुरु किया है? मैं विश्वास नहीं कर सकता कि मेरा बेटा रसोइया बनना चाहता है!",
              "ka": "ಹೋಟೆಲ್ ನಿರ್ವಹಣೆ? ನೀನು ಮತ್ತೆ ಈ ಅಸಂಬದ್ಧತೆಯನ್ನು ಪ್ರಾರಂಭಿಸಿದ್ದೀಯಾ? ನನ್ನ ಮಗ ಅಡುಗೆಯವನಾಗಲು ಬಯಸುತ್ತಾನೆ ಎಂದು ನನಗೆ ನಂಬಲಾಗುತ್ತಿಲ್ಲ!",
              "te": "హోటల్ మేనేజ్‌మెంట్? నువ్వు మళ్ళీ ఈ అర్ధంలేని పనిని ప్రారంభించావా? నా కొడుకు వంటవాడు కావాలంటే నేను నమ్మలేకపోతున్నాను!",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ? ପୁଣିଥରେ ତୁ ସେହି ପାଗଳାମି ଆରମ୍ଭ କଲୁ? ତୁ \n ଗୋଟେ ବାବର୍ଚି ହେବୁ ବୋଲି ମୁଁ ଭବିପାରୁନି!",
              "as": "হোটেল মেনেজমেণ্ট? তুমি এই বাজে কথাবোৰ পুনৰ আৰম্ভ কৰিছা নেকি? মই বিশ্বাস কৰিব নোৱাৰো যে মোৰ পুত্ৰই ৰান্ধনী হ'ব বিচাৰে!",
              "gu": "હોટલ મેનેજમેંટ? શું તે આ બકવાસ ફરી શરૂ કર્યો છે? હું વિશ્વાસ કરી શકતો નથી કે મારો પુત્ર રસોઈઓ બનવા માંગે છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Babuji, there were many male students in the college doing this course, I won’t be alone.",
              "hi": "बाबूजी, कॉलेज में कई पुरुष छात्र यह कोर्स कर रहे थे, मैं अकेला नहीं रहूंगा।",
              "ka": "ಬಾಬೂಜಿ, ಕಾಲೇಜಿನಲ್ಲಿ ಅನೇಕ ಪುರುಷ ವಿದ್ಯಾರ್ಥಿಗಳು ಈ ಕೋರ್ಸ್ ಮಾಡುತ್ತಿದ್ದರು, ನಾನು ಒಬ್ಬಂಟಿಯಾಗಿರುವುದಿಲ್ಲ.",
              "te": "నాన్నగారు, కళాశాలలో చాలా మంది మగ విద్యార్థులు ఈ కోర్సు చేస్తున్నారు, నేను ఒంటరిగా ఉండను.",
              "or": "ବାପା, କଲେଜରେ ଆହୁରି ବହୁତ ପୁଆପିଲା ଏହି ପାଠ୍ୟକ୍ରମ କରୁଛନ୍ତି, ମୁଁ \n ଏକା ନୁହେଁ ।",
              "as": "দেউতা, কলেজত বহুতো পুৰুষ শিক্ষাৰ্থীয়ে এই পাঠ্যক্ৰমটো কৰি আছিল, মই অকলে নাথাকিম।",
              "gu": "બાબુજી, કૉલેજમાં આ કોર્સ કરતા ઘણા પુરૂષ વિદ્યાર્થીઓ હતા, હું એકલો નહીં હોઉં."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Shikhar, we talked about this earlier; you aren’t going to take up a useless course like that.",
              "hi": "शिखर, हमने इस बारे में पहले बात की थी; तुम इस तरह का बेकार कोर्स करने के लिए नहीं जा रहे हो।",
              "ka": "ಶಿಖರ್, ನಾವು ಈ ಹಿಂದೆ ಇದರ ಬಗ್ಗೆ ಮಾತನಾಡಿದ್ದೇವೆ; ನೀನು ಅಂತಹ ಅನುಪಯುಕ್ತ ಕೋರ್ಸ್ ಅನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಹೋಗಬಾರದು.",
              "te": "శిఖర్, మనము దీని గురించి ముందుగా మాట్లాడాము; నువ్వు అలాంటి పనికిరాని కోర్సు తీసుకోకు.",
              "or": "ଶିଖର, ଆମେ ଆଗରୁ ଏସବୁ ଆଲୋଚନା କରିସାରିଛେ। ତୁ ଏପରି କିଛି ଫାଲତୁ \n  ପାଠ ପଢିବୁ ନହିଁ ।",
              "as": "শিখৰ, আমি এই বিষয়ে আগতে কথা পাতিছিলো; তুমি তেনেধৰণৰ দৰকাৰী পাঠ্যক্ৰম ল'ব নোৱাৰা।",
              "gu": "શિખર, આપણે આ વિશે અગાઉ વાત કરી હતી; તમે તેના જેવો નકામો અભ્યાસક્રમ અપનાવવાના નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "But Bhaiya, I want to do a Hotel Management course. It’s my dream! I feel if I do something I'm interested in, I will become successful in that field.",
              "hi": "लेकिन भैया, मैं एक होटल प्रबंधन कोर्स करना चाहता हूं। यह मेरा सपना है! मुझे लगता है कि अगर मैं ऐसा कुछ करता हूं जिसमें मुझे दिलचस्पी है,तो मैं उस क्षेत्र में सफल हो जाऊंगा।",
              "ka": "ಆದರೆ ಭೈಯ್ಯಾ, ನಾನು ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್ ಕೋರ್ಸ್ ಮಾಡಲು ಬಯಸುತ್ತೇನೆ. ಇದು ನನ್ನ ಕನಸು! ನನಗೆ ಆಸಕ್ತಿಯಿರುವ ಕೆಲಸ ಮಾಡಿದಲ್ಲಿ ಆ ಕ್ಷೇತ್ರದಲ್ಲಿ ನಾನು ಯಶಸ್ವಿಯಾಗುತ್ತೇನೆ ಅನ್ನಿಸುತ್ತದೆ.",
              "te": "కానీ అన్నయ్య, నేను హోటల్ మేనేజ్‌మెంట్ కోర్సు చేయాలనుకుంటున్నాను. అది నా కల! నాకు ఆసక్తి ఉన్న పని చేస్తే ఆ రంగంలో రాణిస్తానని భావిస్తున్నాను.",
              "or": "ଇନ୍ତୁ ଭାଇ, ମୁ ଏହି ପାଠ ପଢିବାକୁ ଚାହୁଛି। ଏହା ମୋର ସ୍ଵପ୍ନ! ମୁଁ ବିଶ୍ଵାସ କରେ \n ଯଦି ମୁଁ ମୋ ରୁଚି ଆନୁଯାୟି କାମ କରିବି, ତେବେ ମୁଁ ନିଶ୍ଚୟ ସଫଳ ହେବି।",
              "as": "কিন্তু দাদা, মই হোটেল মেনেজমেণ্ট পাঠ্যক্ৰম এটা কৰিব বিচাৰোঁ। এইটো মোৰ সপোন! মই ভাবো যে যদি মই আগ্ৰহী কিবা এটা কৰোঁ, মই সেই ক্ষেত্ৰত সফল হ'ম।",
              "gu": "પણ ભાઈ, મારે હોટેલ મેનેજમેન્ટનો કોર્સ કરવો છે. તે મારું સ્વપ્ન છે! મને લાગે છે કે જો હું એવું કંઈક કરું જેમાં મને રુચિ છે, તો હું તે ક્ષેત્રમાં સફળ થઈશ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.4.text",
            "value": {
              "en": "We had saved money for your engineering course for so many years. This course will not get you a job. You will struggle to make a few rupees here and there. Why do you want to shoot yourself on the foot?",
              "hi": "हमने इतने सालों से तुम्हारे इंजीनियरिंग कोर्स के लिए पैसे बचाए थे। इस कोर्स से तुमको नौकरी नहीं मिलेगी। तुम यहां - वहां कुछ रुपए कमाने के लिए संघर्ष करोगे। तुम अपने पैर पर खुद कुल्हाड़ी क्यों मारना चाहते हो?",
              "ka": "ನಿನ್ನ ಇಂಜಿನಿಯರಿಂಗ್ ಕೋರ್ಸಿಗೆ ಇಷ್ಟು ವರ್ಷ ಹಣ ಉಳಿಸಿದ್ದೆವು. ಈ ಕೋರ್ಸ್ ಮಾಡಿದರೆ ನಿನಗೆ ಕೆಲಸ ಸಿಗುವುದಿಲ್ಲ. ಅಲ್ಲಿ ಇಲ್ಲಿ ಒಂದಿಷ್ಟು ರೂಪಾಯಿ ಮಾಡಲು ಕಷ್ಟಪಡುತ್ತೀಯಾ. ನೀನೇಕೆ ನಿನ್ನದೇ ಪಾದದ ಮೇಲೆ ಗುಂಡು ಹಾರಿಸಿಕೊಳ್ಳಲು ಬಯಸುತ್ತೀಯಾ?",
              "te": "నీ ఇంజనీరింగ్ కోర్సు కోసం మేము చాలా సంవత్సరాలు డబ్బు ఆదా చేసాము. ఈ కోర్సు వల్ల నీకు ఉద్యోగం రాదు. నువ్వు అక్కడ డబ్బు సంపాదించడానికి కష్టపడతావు. నీ పాదాలపై ఎందుకు కాల్చుకోవాలనుకుంటున్నావు?",
              "or": "ଆମେମାନେ ତୁମ ଇଞ୍ଜିନିଅରିଂ ପଢା ପାଇଁ ବହୁତ ବର୍ଷ ହେଲାଣି ଟଙ୍କା ଯୋଗାଡ \n କରି ରଖିଛୁ। ଏହି ପାଠ ତୁମକୁ ଚାକିରି ଟିଏ ଦେଇପାରିବ ନହିଁ। ତୁମେ କିଛି ଟଙ୍କା \n ପାଇଁ ଏଠି ସେଠି ଘୂରିବୁଲିବୁ। ???",
              "as": "আমি ইমান বছৰ ধৰি তোমাৰ ইঞ্জিনিয়াৰিং পঢ়াৰ বাবে টকা ৰাহি কৰিছিলো। এই পাঠ্যক্ৰমে তোমাক চাকৰি নিদিব। তুমি কেইটামান টকা উপাৰ্জন কৰিবলৈ ইফালৰ পৰা সিফাললৈ সংগ্ৰাম কৰিবা। তুমি কিয় নিজকে ভৰিত গুলী মাৰিব খুজিছা?",
              "gu": "અમે તમારા એન્જિનિયરિંગ કોર્સ માટે આટલા વર્ષો સુધી પૈસા બચાવ્યા હતા. આ કોર્સ તમને નોકરી નહીં મળે. તમે અહીં અને ત્યાં થોડા રૂપિયા કમાવવા માટે સંઘર્ષ કરશો. શા માટે તમે તમારી જાતને પગ પર મારવા માંગો છો?"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_2_2////",
              "hi": "A3_2_2 /////",
              "ka": "A3_2_2////",
              "te": "A3_2_2////",
              "or": "A3_2_2////",
              "as": "A3_2_2////",
              "gu": "A3_2_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Babuji, I don’t want to do engineering, please. I won’t be able to get through 4 years of studying something I don’t like. I’d rather do commerce instead if it’s so crucial for you that I get a respectable job.",
              "hi": "बाबूजी, निवेदन है, मैं इंजीनियरिंग नहीं करना चाहता। मैं  4 वर्ष कुछ ऐसा अध्ययन करने में सफल नहीं हो सकूंगा जो मुझे पसंद नहीं है। अगर मुझे सम्मानजनक नौकरी मिलना आपके लिए इतना महत्वपूर्ण है तो मैं इसके बजाय कॉमर्स/ वाणिज्य करूँगाl ",
              "ka": "ಬಾಬೂಜಿ, ದಯವಿಟ್ಟು ನನಗೆ ಎಂಜಿನಿಯರಿಂಗ್ ಮಾಡಲು ಇಷ್ಟವಿಲ್ಲ. ನಾನು ಇಷ್ಟಪಡದ ಯಾವುದನ್ನಾದರೂ 4 ವರ್ಷಗಳ ಕಾಲ ಅಧ್ಯಯನ ಮಾಡಲು ನನಗೆ ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ. ನಾನು ಗೌರವಾನ್ವಿತ ಕೆಲಸವನ್ನು ಪಡೆಯುವುದೇ ನಿಮಗೆ ತುಂಬಾ ನಿರ್ಣಾಯಕವೆನಿಸಿದ್ದರೆ ನಾನು ವಾಣಿಜ್ಯವನ್ನು ಮಾಡಲು ಬಯಸುತ್ತೇನೆ.",
              "te": "నాన్నగారు, నాకు ఇంజినీరింగ్ చేయడం ఇష్టం లేదు. నాకు నచ్చనిది నేను 4 సంవత్సరాల పాటు చదవలేను. నేను గౌరవప్రదమైన ఉద్యోగం పొందడం మీకు చాలా కీలకమైనట్లయితే బదులుగా నేను కామర్స్ చేయాలనుకుంటున్నాను.",
              "or": "ବାବୁଜୀ, ମୁଁ ଇଞ୍ଜିନିଅରିଂ କରିବାକୁ ଚାହୁଁ ନହିଁ । ଯାଉ ପାଠକୁ ମୁଁ ଭଲ ପାଏନାହି , \n ତାପାଇଁ ମୁ ୪ ବର୍ଷ ସମୟ ଦେଇପାରିବି ନହିଁ। ଯଦି ଆପଣଙ୍କୁ ମୋର ଗୋଟେ \n ସଂମ୍ମାନ ଯୋଗ୍ୟ ଚାକିରି ପାଇବା ଟା ଚିନ୍ତାରେ ପକାଉଚି, ତେବେ ମୁ ବାଣିଜ୍ୟ ପଢିବୀ",
              "as": "দেউতা, মই ইঞ্জিনিয়াৰিং কৰিব নিবিচাৰোঁ। মই ভাল নোপোৱা কিবা এটা পঢ়ি 4 বছৰ পাৰ কৰিব নোৱাৰিম। মই তাৰ সলনি বাণিজ্য কৰিম যদি মই এটা সন্মানীয় চাকৰি পোৱাতো আপোনাৰ বাবে ইমান গুৰুত্বপূৰ্ণ।",
              "gu": "બાબુજી, મારે એન્જીનીયરીંગ નથી કરવું, પ્લીઝ. મને ગમતી ન હોય તેવી કોઈ વસ્તુનો અભ્યાસ કરીને હું 4 વર્ષ પસાર કરી શકીશ નહીં. જો તમારા માટે મને સન્માનજનક નોકરી મળે તો હું તેના બદલે વાણિજ્ય કરવાનું પસંદ કરીશ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Hmm, Commerce sounds like a good option. I’ve heard you get good jobs in the city with this degree.",
              "hi": "हम्म, कॉमर्स/ वाणिज्य एक अच्छा विकल्प लगता है। मैंने सुना है कि इस डिग्री से शहर में अच्छी नौकरी मिल जाती हैं।",
              "ka": "ಹ್ಮ್ಮ, ವಾಣಿಜ್ಯವು ಉತ್ತಮ ಆಯ್ಕೆಯಂತೆ ತೋರುತ್ತದೆ. ಈ ಪದವಿಯೊಂದಿಗೆ ನಗರದಲ್ಲಿ ಒಳ್ಳೆಯ ಕೆಲಸ ಸಿಗುತ್ತದೆ ಎಂದು ಕೇಳಿದ್ದೇನೆ.",
              "te": "కామర్స్ మంచి ఎంపికగా అనిపిస్తుంది. ఈ డిగ్రీతో నీకు నగరంలో మంచి ఉద్యోగాలు వస్తాయని విన్నాను.",
              "or": "ହଁ, ବାଣିଜ୍ୟ ଏକ ଭଲ ଚୟନ । ଏହି ଡିଗ୍ରୀ ସହିତ ସହର ରେ ତୁମକୁ ଭଲ \n ଚାକିରି ମିଳିଯିବ।",
              "as": "হুম, বাণিজ্য এটা ভাল বিকল্প যেন লাগিছে। মই শুনিছোঁ যে তুমি এই ডিগ্ৰীটোৰ সৈতে চহৰখনত ভাল চাকৰি পাবা।",
              "gu": "હમ્મ, વાણિજ્ય એક સારા વિકલ્પ જેવું લાગે છે. મેં સાંભળ્યું છે કે આ ડિગ્રી સાથે શહેરમાં સારી નોકરી મળેશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "If that is the case, then you can do Commerce Shikhar. But don’t even think about doing hotel management, unless you want us to become the joke of our village.",
              "hi": "यदि ऐसा है शिखर, तो तुम वाणिज्य कर सकते हो। लेकिन होटल प्रबंधन करने के बारे में सोचो भी मत, जब तक कि तुम अपने गांव में हमारा मजाक नहीं बनाना चाहते हो।",
              "ka": "ಹಾಗಿದ್ದಲ್ಲಿ, ನೀನು ವಾಣಿಜ್ಯವನ್ನು ಮಾಡಬಹುದು ಶಿಖರ್. ಆದರೆ ನಾವು ನಮ್ಮ ಹಳ್ಳಿಯ ಜೋಕ್ ಆಗಬೇಕಿದ್ದಲ್ಲಿ ಮಾತ್ರವೇ ನೀನು ಹೋಟೆಲ್ ನಿರ್ವಹಣೆ ಮಾಡುವ ಬಗ್ಗೆ ಯೋಚಿಸು.",
              "te": "అలా అయితే, నువ్వు కామర్స్ చేయవచ్చు శిఖర్. కానీ మన ఊర్లో జోక్‌ గా మారాలని నువ్వు కోరుకుంటే తప్ప, హోటల్ మేనేజ్‌మెంట్ గురించి కూడా ఆలోచించవద్దు.",
              "or": "ଯଦି ସେଇଟା ହି ହେବ, ତେବେ ଶିଖର ତୁମେ ବାଣିଜ୍ୟ ନେଇଇ ପଢିପାରିବ। \n କିନ୍ତୁ ଯଦି ଗାଁରେ ଆମର joke ନ କରିବାର ଅଛି ତେବେ ସେହି ହୋଟେଲ\n  ମ୍ୟାନେଜମେଣ୍ଟ କରିବା କଥା ସଂପୂର୍ଣ୍ଣ ଭୁଲିଯାଅ।",
              "as": "যদি তেনে হয়, তেন্তে তুমি বাণিজ্য কৰিব পাৰা শিখৰ। কিন্তু হোটেল মেনেজমেণ্ট কৰাৰ কথা নাভাবিবা, যদিহে তুমি গাঁৱত আমাৰ কৌতুক হোৱাটো নিবিচাৰা।",
              "gu": "જો એવું હોય તો તમે કોમર્સ કરી શકે છે. પણ હોટેલ મેનેજમેન્ટ કરવાનું વિચારીશ નહીં, સિવાય કે તું ઇચ્છે કે અમે આપણા ગામની મજાક બનીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "We are already struggling with our farm produce. We won’t support you anymore if you don’t get a job after this course.",
              "hi": "हम पहले से ही हमारी कृषि उत्पाद के लिए संघर्ष कर रहे हैं। यदि तुमको इस कोर्स के बाद नौकरी नहीं मिलती है तो हम तुमको सहयोग नहीं देंगे।",
              "ka": "ನಾವು ಈಗಾಗಲೇ ನಮ್ಮ ಕೃಷಿ ಉತ್ಪನ್ನಗಳೊಂದಿಗೆ ಹೋರಾಡುತ್ತಿದ್ದೇವೆ. ಈ ಕೋರ್ಸ್‌ನ ನಂತರ ನಿನಗೆ ಕೆಲಸ ಸಿಗದಿದ್ದರೆ ನಾವು ಇನ್ನು ಮುಂದೆ ನಿನ್ನನ್ನು ಬೆಂಬಲಿಸಾಲು ಆಗುವುದಿಲ್ಲ.",
              "te": "ఇప్పటికే వ్యవసాయ ఉత్పత్తులతో ఇబ్బందులు పడుతున్నాం. ఈ కోర్సు తర్వాత నీకు ఉద్యోగం రాకుంటే మేము ఇకపై మీకు మద్దతు ఇవ్వలేము.",
              "or": "ଆମେମାନେ ଏମିତି ବି ଆମ କ୍ଷେତ ର ଉତ୍ପାଦନ କୁ ନେଇ ସଂଘର୍ଷ କରିବାକୁ ପଡୁଛି।\n ଏହି ପାଠ୍ୟକ୍ରମ ପରେ ଯଦି ତୁମେ କିଛି ଚାକିରି ନ କରିବା ତେବେ ଆମେ ଆଉ ତୁମକୁ\n ସାହାଯ୍ୟ କରିପାରିବୁ ନହିଁ।",
              "as": "আমি ইতিমধ্যে আমাৰ খেতিৰ উৎপাদিত বস্তুৰ সৈতে যুঁজি আছোঁ। যদি তুমি এই পাঠ্যক্ৰমৰ পিছত চাকৰি নোপোৱা তেন্তে আমি তোমাক আৰু সমৰ্থন নকৰো।",
              "gu": "આપણે પહેલેથી જ આપણી ખેત પેદાશો સાથે સંઘર્ષ કરી રહ્યા છીએ. જો તને આ કોર્સ પછી નોકરી નહીં મળે તો અમે તને સમર્થન આપીશું નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Bhaiya, it’s not just about cooking. The opportunities might be less, but if I study well, I can get a good job. I can even become a manager in a big restaurant or hotel!",
              "hi": "भैया, यह सिर्फ खाना पकाने के बारे में नहीं है। अवसर कम हो सकते हैं, लेकिन अगर मैं अच्छी तरह से अध्ययन करता हूं, तो मुझे अच्छी नौकरी मिल सकती है। मैं एक बड़े रेस्तरां या होटल में एक प्रबंधक भी बन सकता हूं!",
              "ka": "ಭೈಯ್ಯ, ಇದು ಕೇವಲ ಅಡುಗೆಯ ಬಗ್ಗೆ ಅಲ್ಲ. ಅವಕಾಶಗಳು ಕಡಿಮೆಯಾಗಿರಬಹುದು, ಆದರೆ ನಾನು ಚೆನ್ನಾಗಿ ಓದಿದರೆ, ನಾನು ಉತ್ತಮ ಉದ್ಯೋಗವನ್ನು ಪಡೆಯಬಹುದು. ನಾನು ದೊಡ್ಡ ರೆಸ್ಟೋರೆಂಟ್ ಅಥವಾ ಹೋಟೆಲ್‌ನಲ್ಲಿ ಮ್ಯಾನೇಜರ್ ಆಗಬಹುದು!",
              "te": "అన్నయ్య, ఇది కేవలం వంట మాత్రమే కాదు. అవకాశాలు తక్కువగా ఉండవచ్చు, కానీ నేను బాగా చదివితే, నేను మంచి ఉద్యోగం సంపాదించగలను. నేను పెద్ద రెస్టారెంట్ లేదా హోటల్‌లో మేనేజర్‌ ని కూడా కాగలను!",
              "or": "ଭାଇ, ଏହା କେବଳ ରୋଷେଇ ର କଥା ନୁହେଁ, ସୁଯୋଗ କମ ଥାଇପାରେ, କିନ୍ତୁ \n ଯଦି ମୁଁ ଭଲ ସେ ପଢିବି, ତେବେ ମୁଁ ଗୋଟିଏ ଭଲ କାମ ପାଇପାରିବି। ମୁଁ ଏକ ହୋଟେଲ \n କିମ୍ବା ରେଷ୍ଟୋରା ରେ ମ୍ୟାନଜର ମଧ୍ୟ ହୋଇ ପାରିବି।",
              "as": "দাদা, এয়া কেৱল ৰন্ধাৰ বিষয়ে নহয়। সুযোগবোৰ কম হ'ব পাৰে, কিন্তু যদি মই ভালদৰে পঢ়ো, মই এটা ভাল চাকৰি পাব পাৰোঁ। মই আনকি এখন ডাঙৰ ৰেষ্টুৰেণ্ট বা হোটেলত মেনেজাৰ হ'ব পাৰোঁ!",
              "gu": "ભાઈ, વાત માત્ર રસોઈની નથી. તકો ઓછી હોઈ શકે છે, પરંતુ જો હું સારી રીતે અભ્યાસ કરું તો મને સારી નોકરી મળી શકે છે. હું મોટી રેસ્ટોરન્ટ કે હોટેલમાં મેનેજર પણ બની શકું છું!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.5.text",
            "value": {
              "en": "Shikhar, do what you want. You know other parents expect their children to work in the field immediately after the 12th. What am I to do if you are going to ruin your life? If you fail, remember that we had warned you.",
              "hi": "शिखर, वही करो जो तुम चाहते हो। तुम जानते हो कि अन्य माता-पिता बारहवीं के तुरंत बाद अपने बच्चों से खेत में काम करने की उम्मीद करते हैं। यदि तुम अपने जीवन को बर्बाद करने जा रहे हो तो मैं क्या करूं? यदि तुम असफल हो जाते हो, तो याद रखना  कि हमने तुमको चेतावनी दी थी।",
              "ka": "ಶಿಖರ್, ನಿನಗೆ ಬೇಕಾದುದನ್ನು ಮಾಡು. ಇತರ ಪೋಷಕರು ತಮ್ಮ ಮಕ್ಕಳು 12 ನೇ ಇಯತ್ತೆಯ ನಂತರ ತಕ್ಷಣವೇ ಕ್ಷೇತ್ರದಲ್ಲಿ ಕೆಲಸ ಮಾಡಬೇಕೆಂದು ನಿರೀಕ್ಷಿಸುತ್ತಾರೆ ಎಂದು ನಿನಗೆ ತಿಳಿದಿದೆ. ನೀನು ನಿನ್ನ ಜೀವನವನ್ನು ಹಾಳುಮಾಡಲು ಹೋದರೆ ನಾವು ಏನು ಮಾಡಬೇಕು? ನೀನು ವಿಫಲನಾದರೆ, ನಾವು ನಿಮಗೆ ಎಚ್ಚರಿಕೆ ನೀಡಿದ್ದೇವೆ ಎನ್ನುವುದನ್ನು ನೆನಪಿಟ್ಟುಕೋ.",
              "te": "శిఖర్, నీకు ఏమి కావాలో అది చేసుకో. ఇతర తల్లిదండ్రులు తమ పిల్లలు 12వ తరగతి అయిన వెంటనే పొలంలో పని చేయాలని ఆశిస్తున్నారని నీకు తెలుసు. నువ్వు నీ జీవితాన్ని నాశనం చేసుకుంటే నేనేం చేయాలి? నువ్వు ఫెయిల్ అయితే, మేము నిన్ను హెచ్చరించామని గుర్తు చేసుకో.",
              "or": "ଶିଖର, ତୁମେ ଯାହା ଚାହୁଁଚ କର। ତୁମେ ଜାଣିଛ ତ ସବୁ ବାପା ମା ଚାହାନ୍ତି ଯେ \n ତାଙ୍କ ପିଲା ଦ୍ଵାଦଶ ଶ୍ରେଣୀ ପାସ କରିବା ପରେ ହିନ ଯଥାଶୀଘ୍ର ଚାକିରି ପାଇଯାନ୍ତୁ। \n ଯଦି ତୁମେ ନିଜ ଜୀବନ କୁ ଖରାପ କରିବାକୁ ଯାଉଛ ତେବେ ମୁଁ କଣ କରିବି! ଯଦି \n ତୁମେ ଫେଲ ହେଲା ତେବେ ମନେରଖ ଯେ ଆମେ ତୁମକୁ ସତର୍କ କରିଥିଲୁ!",
              "as": "শিখৰ, তুমি যি বিচাৰা সেয়া কৰা। তুমি জানা যে আন অভিভাৱকসকলে আশা কৰে যে তেওঁলোকৰ সন্তানে দ্বাদশৰ ঠিক পিছতে পথাৰত কাম কৰিব। যদি তুমি তোমাৰ জীৱন ধ্বংস কৰিবলৈ যোৱা তেন্তে মই কি কৰিম? যদি তুমি বিফল হোৱা, মনত ৰাখিবা যে আমি তোমাক সতৰ্ক কৰি দিছিলো।",
              "gu": "શિખર, તારે જે કરવું હોય તે કર. તું જાણે છે કે અન્ય માતા-પિતા તેમના બાળકો 12માં પછી તરત જ ખેતરમાં કામ કરવાની અપેક્ષા રાખે છે. જો તું તારી જીંદગી બગાડવા માગતો હોય તો મારે શું કરવું? જો તું નિષ્ફળ થાય, તો યાદ રાખજે કે મે તને ચેતવણી આપી હતી."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "A3_2_3_D",
              "hi": "A3_2_3_D",
              "ka": "A3_2_3_D",
              "te": "A3_2_3_D",
              "or": "A3_2_3_D",
              "as": "A3_2_3_D",
              "gu": "A3_2_3_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Shikhar do?",
              "hi": "शिखर को क्या करना चाहिए?",
              "ka": "ಶಿಖರ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "శిఖర్ ఏం చేయాలి?",
              "or": "ଶିଖର କଣ କରିବ?",
              "as": "শিখৰে কি কৰিব লাগে?",
              "gu": "શિખરે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Apply for Commerce (B.com)",
              "hi": "कॉमर्स/ वाणिज्य के लिए आवेदन करे (बी.कॉम)",
              "ka": "ವಾಣಿಜ್ಯಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದು (B.com)",
              "te": "కామర్స్ (B.com) కి దరఖాస్తు చేయాలి",
              "or": "ବାଣିଜ୍ୟ ପାଇଁ ଆବେଦନ",
              "as": "বাণিজ্যৰ বাবে আবেদন (B.com)",
              "gu": "કોમર્સ માટે અરજી કરવી (B.com)"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Apply for Hotel Management",
              "hi": "होटल प्रबंधन के लिए आवेदन करे",
              "ka": "ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್ ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದು",
              "te": "హోటల్ మేనేజ్‌మెంట్ కి దరఖాస్తు చేయాలి",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ପାଇଁ ଆବେଦନ",
              "as": "হোটেল মেনেজমেন্টৰ বাবে আবেদন",
              "gu": "હોટેલ મેનેજમેન્ટ માટે અરજી કરવી"
            }
          }
        ]
      },
      "scene19": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Apply for hotel management",
              "hi": "होटल प्रबंधन के लिए आवेदन करे",
              "ka": "ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್ ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದು",
              "te": "హోటల్ మేనేజ్‌మెంట్ కి దరఖాస్తు చేయాలి",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ପାଇଁ ଆବେଦନ",
              "as": "হোটেল মেনেজমেন্টৰ বাবে আবেদন",
              "gu": "હોટેલ મેનેજમેન્ટ માટે અરજી કરવી"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Against his family's wishes, Shikhar applies for the Hotel Management course by writing the NCHMCT JEE examination. His family agrees to fund his education.",
              "hi": "अपने परिवार की इच्छाओं के विरुद्ध, शिखर एनसीएमसीटी जेईई परीक्षा लिखकर होटल प्रबंधन कोर्स के लिए आवेदन करता है। उसका परिवार उसको   शिक्षा शुल्क देने के लिए सहमत है।",
              "ka": "ಆತನ ಕುಟುಂಬದ ಇಚ್ಛೆಗೆ ವಿರುದ್ಧವಾಗಿ, ಶಿಖರ್ NCHMCT JEE ಪರೀಕ್ಷೆಯನ್ನು ಬರೆಯುವ ಮೂಲಕ ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಕೋರ್ಸ್‌ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಾನೆ. ಅವನ ಶಿಕ್ಷಣಕ್ಕೆ ಧನಸಹಾಯ ನೀಡಲು ಅವರ ಕುಟುಂಬ ಒಪ್ಪಿದೆ.",
              "te": "అతని కుటుంబం యొక్క ఇష్టానికి వ్యతిరేకంగా, శిఖర్ NCHMCT JEE పరీక్ష రాయడం ద్వారా హోటల్ మేనేజ్‌మెంట్ కోర్సుకి దరఖాస్తు చేశాడు. అతని చదువుకు డబ్బు ఇవ్వడానికి అతని కుటుంబం అంగీకరించింది.",
              "or": "ନିଜ ପରିବାରର ଇଛା ବିରୁଦ୍ଧରେ, ସେ NCHMCT JEE ପରୀକ୍ଷା ଦେଇ \n ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ପାଠ୍ୟକ୍ରମ ପାଇଁ ଆବେଦନ କଲା। ତା ପରିବବାର \n ଏହାର ଖର୍ଚ ଦେବା ପାଇଁ ରାଜି ହୋଇଗଲା।",
              "as": "তাৰ পৰিয়ালৰ ইচ্ছাৰ বিপৰীতে, শিখৰে NCHMCT JEE পৰীক্ষা লিখি হোটেল মেনেজমেণ্ট পাঠ্যক্ৰমৰ বাবে আবেদন কৰে। তাৰ পৰিয়ালে তাৰ শিক্ষাৰ বাবে পইচা দিবলৈ মান্তি হৈছে।",
              "gu": "તેના પરિવારની ઈચ્છા વિરુદ્ધ, શિખર NCHMCT JEE પરીક્ષા લખીને હોટેલ મેનેજમેન્ટ કોર્સ માટે અરજી કરે છે. તેનો પરિવાર તેના શિક્ષણ માટે ભંડોળ આપવા સંમત થાય છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_1_2",
              "hi": "C1_1_2",
              "ka": "C1_1_2",
              "te": "C1_1_2",
              "or": "C1_1_2",
              "as": "C1_1_2",
              "gu": "C1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Babuji is very upset with Shikhar's decision. He doesn't talk to him much. Arun and his mother continue talking to Shikhar and supporting him through his application process.",
              "hi": "बाबूजी शिखर के फैसले से बहुत परेशान हैं। वह उससे ज्यादा बात नहीं करते हैं। अरुण और उसकी मां शिखर से बात करना जारी रखते हैं और उसकी आवेदन प्रक्रिया के दौरान उसको सहयोग देते हैं।",
              "ka": "ಶಿಖರನ ನಿರ್ಧಾರದಿಂದ ಬಾಬೂಜಿ ತುಂಬಾ ನೊಂದುಕೊಂಡಿದ್ದಾರೆ. ಅವರು ಅವನೊಂದಿಗೆ ಹೆಚ್ಚು ಮಾತನಾಡುವುದಿಲ್ಲ. ಅರುಣ್ ಮತ್ತು ಅವನ ತಾಯಿ ಶಿಖರ್‌ನೊಂದಿಗೆ ಮಾತನಾಡುವುದನ್ನು ಮುಂದುವರಿಸುತ್ತಾರೆ ಮತ್ತು ಅವನ ಅರ್ಜಿ ಪ್ರಕ್ರಿಯೆಯ ಮೂಲಕ ಅವನನ್ನು ಬೆಂಬಲಿಸುತ್ತಾರೆ.",
              "te": "శిఖర్ నిర్ణయంతో నాన్నగారు చాలా బాధపడ్డాడు. అతనితో మాట్లాడడం తగ్గించాడు. అరుణ్ మరియు అతని తల్లి శిఖర్‌ తో మాట్లాడటం మరియు అతని దరఖాస్తు ప్రక్రియలో అతనికి మద్దతు ఇవ్వడం కొనసాగిస్తున్నారు.",
              "or": "ଶିଖରର ନିଷ୍ପତ୍ତି ପାଇଁ ଦୁଃଖିତ ଥିଲେ। ସେ ଶିଖର ସହ କଥା ହେଉନଥିଲେ। ଅରୁଣ\n ଓ ତା ମା ତାର ଦରଖାସ୍ତ ପ୍ରକ୍ରିୟା ସମୟରେ ତାକୁ ସହଯୋଗ କରୁଥିଲେ ଓ ତା ସହ\n  କଥା ହେଉଥିଲେ।",
              "as": "দেউতাক শিখৰৰ সিদ্ধান্তত বহুত অসন্তুষ্ট। তেওঁ তাৰ সৈতে বেছি কথা নাপাতে। অৰুণ আৰু তাৰ মাকে শিখৰৰ সৈতে কথা পাতি আছে আৰু তাৰ আবেদন প্ৰক্ৰিয়াত তাক সমৰ্থন কৰি আছে।",
              "gu": "શિખરના નિર્ણયથી બાપૂજી ખૂબ નારાજ છે. તે તેની સાથે બહુ વાત કરતા નથી. અરુણ અને તેની માતા શિખર સાથે વાત કરવાનું ચાલુ રાખે છે અને તેની અરજી પ્રક્રિયા દ્વારા તેને ટેકો આપે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_1",
              "hi": "C1_2_1",
              "ka": "C1_2_1",
              "te": "C1_2_1",
              "or": "C1_2_1",
              "as": "C1_2_1",
              "gu": "C1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar clears the examination and sends his application documents to a couple of Catering colleges across India. He gets a response from the JJM University in Indore.",
              "hi": "शिखर परीक्षा को उत्तीर्ण करता है और अपने आवेदन दस्तावेजों को भारत भर में कुछ केटरिंग कॉलेजों में भेजता है। उसे इंदौर में जेएसएम विश्वविद्यालय से उत्तर मिलता है।",
              "ka": "ಶಿಖರ್ ಪರೀಕ್ಷೆಯಲ್ಲಿ ತೇರ್ಗಡೆಯಾಗುತ್ತಾನೆ ಮತ್ತು ತನ್ನ ಅರ್ಜಿ ದಾಖಲೆಗಳನ್ನು ಭಾರತದಾದ್ಯಂತ ಇರುವ ಒಂದೆರಡು ಕ್ಯಾಟರಿಂಗ್ ಕಾಲೇಜುಗಳಿಗೆ ಕಳುಹಿಸುತ್ತಾನೆ. ಅವನು ಇಂದೋರ್‌ನಲ್ಲಿರುವ ಜೆಜೆಎಂ ವಿಶ್ವವಿದ್ಯಾಲಯದಿಂದ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಪಡೆಯುತ್ತಾನೆ.",
              "te": "శిఖర్ పరీక్షలో ఉత్తీర్ణత సాధించి, తన దరఖాస్తు పత్రాలను భారతదేశంలోని రెండు క్యాటరింగ్ కళాశాలలకు పంపాడు. ఇండోర్‌లోని JJM విశ్వవిద్యాలయం నుండి అతనికి జవాబు వచ్చింది.",
              "or": "ଶିଖର ନିଜ ପରୀକ୍ଷା ଶେଷ କରିଲା ଓ ନିଜ ଦରଖାସ୍ତ ଦସ୍ତାବିଜ କୁ ଭାରତର \n  କେତେକ \n ଖାଦ୍ୟପେୟ ମହାବିଦ୍ୟାଳୟକୁ ପଠାଇଲା। ଇନ୍ଦୋର ରା ଜେଜେଏମ ମହାବିଦ୍ୟାଳୟରୁ \n ସେ ପ୍ରତିକ୍ରିୟା ପାଇଲା।",
              "as": "শিখৰে পৰীক্ষাত পাছ কৰে আৰু তাৰ আবেদনৰ নথিপত্ৰসমূহ গোটেই ভাৰতৰ কেইখনমান কেটাৰিং কলেজলৈ পঠিয়ায়। সি ইন্দোৰৰ JJM বিশ্ববিদ্যালয়ৰ পৰা সঁহাৰি পায়।",
              "gu": "શિખર પરીક્ષા પાસ કરે છે અને તેની અરજીના દસ્તાવેજો ભારતભરની કેટલાક વિભાગોની કોલેજોને મોકલે છે. તેને ઈન્દોરની JJM યુનિવર્સિટી તરફથી પ્રતિસાદ મળે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_2",
              "hi": "C1_2_2",
              "ka": "C1_2_2",
              "te": "C1_2_2",
              "or": "C1_2_2",
              "as": "C1_2_2",
              "gu": "C1_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He has only two steps left to get admission into the college. He logs into the college website and looks at his progress.",
              "hi": "कॉलेज में प्रवेश पाने के लिए उसको केवल दो चरण शेष हैं। वह कॉलेज की वेबसाइट में लॉगिन करता है और अपनी प्रगति को देखता है।",
              "ka": "ಅವನು ಕಾಲೇಜಿಗೆ ಪ್ರವೇಶ ಪಡೆಯಲು ಕೇವಲ ಎರಡು ಹಂತಗಳು ಮಾತ್ರ ಉಳಿದಿವೆ. ಅವನು ಕಾಲೇಜಿನ ವೆಬ್‌ಸೈಟ್‌ಗೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ ತನ್ನ ಪ್ರಗತಿಯನ್ನು ನೋಡುತ್ತಾನೆ.",
              "te": "కళాశాలలో ప్రవేశం పొందడానికి అతనికి రెండు దశలు మాత్రమే మిగిలి ఉన్నాయి. అతను కళాశాల వెబ్‌సైట్‌ లోకి లాగిన్ అయ్యాడు మరియు అతని పురోగతిని చూస్తున్నాడు.",
              "or": "କଲେଜ ରେ ଏଡମିଶନ ପାଇଁ ତାର ଆଉ ଦୁଇଟି ପାହାଚ ବାକି ଥିଲା। ସେ କଲେଜର\n ୱେବସାଇଟ କୁ ଲଗ ଇନ କରି ସେ ନିଜର ଉରନ୍ନତି ଦେଖିବାକୁ ଲାଗିଲା।",
              "as": "কলেজত নামভৰ্তি কৰিবলৈ তাৰ মাত্ৰ দুখোজ বাকী আছে। সি কলেজৰ ৱেবছাইটত লগ ইন কৰে আৰু তাৰ প্ৰগতিৰ ফালে চায়।",
              "gu": "કોલેજમાં પ્રવેશ મેળવવા માટે તેની પાસે માત્ર બે પગલાં બાકી છે. તે કોલેજની વેબસાઈટમાં લોગઈન કરે છે અને તેની પ્રગતિ જુએ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Completed",
              "hi": "पूरा होना",
              "ka": "ಮುಗಿಯಿತು",
              "te": "పూర్తయింది",
              "or": "ପୂର୍ଣ୍ଣ ହେଲା",
              "as": "সম্পূৰ্ণ হৈছে",
              "gu": "પૂર્ણ થયું"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Completed",
              "hi": "पूरा होना",
              "ka": "ಮುಗಿಯಿತು",
              "te": "పూర్తయింది",
              "or": "ପୂର୍ଣ୍ଣ ହେଲା",
              "as": "সম্পূৰ্ণ হৈছে",
              "gu": "પૂર્ણ થયું"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Pending",
              "hi": "लंबित",
              "ka": "ಬಾಕಿಯಿದೆ",
              "te": "పెండింగ్‌ లో ఉంది",
              "or": "ଅନିର୍ଣ୍ଣିତ ରହିଲା",
              "as": "অমীমাংসিত",
              "gu": "બાકી છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "Pending",
              "hi": "लंबित",
              "ka": "ಬಾಕಿಯಿದೆ",
              "te": "పెండింగ్‌ లో ఉంది",
              "or": "ଅନିର୍ଣ୍ଣିତ ରହିଲା",
              "as": "অমীমাংসিত",
              "gu": "બાકી છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Documents submitted",
              "hi": "दस्तावेज़ प्रस्तुत किए गए",
              "ka": "ದಾಖಲೆಗಳನ್ನು ಸಲ್ಲಿಸಲಾಗಿದೆ",
              "te": "పత్రాలు సమర్పించబడ్డాయి",
              "or": "ଦସ୍ତାବିଜ ଦାଖଲ ହେଲା",
              "as": "ডকুমেন্ট জমা হৈছে",
              "gu": "દસ્તાવેજો સબમિટ કર્યા"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Documents submitted",
              "hi": "दस्तावेज़ प्रस्तुत किए गए",
              "ka": "ದಾಖಲೆಗಳನ್ನು ಸಲ್ಲಿಸಲಾಗಿದೆ",
              "te": "పత్రాలు సమర్పించబడ్డాయి",
              "or": "ଦସ୍ତାବିଜ ଦାଖଲ ହେଲା",
              "as": "ডকুমেন্ট জমা হৈছে",
              "gu": "દસ્તાવેજો સબમિટ કર્યા"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "Group discussion",
              "hi": "सामूहिक चर्चा",
              "ka": "ಗುಂಪು ಚರ್ಚೆ",
              "te": "బృంద చర్చ",
              "or": "ସଙ୍ଘଗତ ବିଚାର ବିମର୍ଷ",
              "as": "দলীয় আলোচনা",
              "gu": "જૂથ ચર્ચા"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "Personal Interview",
              "hi": "व्यक्तिगत साक्षात्कार",
              "ka": "ವೈಯಕ್ತಿಕ ಸಂದರ್ಶನ",
              "te": "వ్యక్తిగత ఇంటర్వ్యూ",
              "or": "ବ୍ୟକ୍ତିଗତ ସାକ୍ଷାତ କାର",
              "as": "ব্যক্তিগত সাক্ষাৎকাৰ",
              "gu": "અંગત મુલાકાત"
            }
          }
        ]
      },
      "scene20": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1i_1_1",
              "hi": "C1i_1_1",
              "ka": "C1i_1_1",
              "te": "C1i_1_1",
              "or": "C1i_1_1",
              "as": "C1i_1_1",
              "gu": "C1i_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar appears at the University for the group discussion round. He is scared because public speaking is not his strength.",
              "hi": "शिखर समूह चर्चा दौर के लिए विश्वविद्यालय में उपस्थित होता है। उसे डर लग रहा है क्योंकि सबके सामने बोलना उसकी ताकत नहीं है।",
              "ka": "ಗ್ರೂಪ್ ಡಿಸ್ಕಶನ್ ಸುತ್ತಿಗಾಗಿ ಶಿಖರ್ ವಿಶ್ವವಿದ್ಯಾಲಯಕ್ಕೆ ಹೊಗುತ್ತಾನೆ. ಸಾರ್ವಜನಿಕ ಭಾಷಣವು ಅವನ ಶಕ್ತಿಯಲ್ಲದ ಕಾರಣ ಅವನು ಹೆದರುತ್ತಾನೆ.",
              "te": "శిఖర్ బృంద చర్చ రౌండ్ కోసం విశ్వవిద్యాలయం కి వెళ్తాడు. పబ్లిక్ లో మాట్లాడటం తన బలం కానందున అతను భయపడుతున్నాడు.",
              "or": "ସଙ୍ଘଗତ ବିଚାର ବିମର୍ଷ ପାଇଁ ସିଖର ମହାବିଦ୍ୟାଳୟ ରେ ଉପସ୍ଥିତ ହେଲା। ସେ \n ଟିକେ ଦାରୁଥିବାର ଲାଗିଲା କାରଣ ସେ ସାର୍ବଜନିକ ଭାବେ କଥା ହେବାରେ ସିଦ୍ଧହସ୍ତ \n ନଥିଲା।",
              "as": "শিখৰ দলীয় আলোচনা ৰাউণ্ডৰ বাবে বিশ্ববিদ্যালয়লৈ যায়। সি ভয় খাইছে কাৰণ ৰাজহুৱাভাৱে কোৱাটো তাৰ শক্তি নহয়।",
              "gu": "શિખર યુનિવર્સિટીમાં જુથ વાર્તાલાભ રાઉન્ડ માટે દેખાય છે. તે ભયભીત છે કારણ કે જાહેરમાં બોલવું તેની તાકાત નથી."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1i_1_2",
              "hi": "C1i_1_2",
              "ka": "C1i_1_2",
              "te": "C1i_1_2",
              "or": "C1i_1_2",
              "as": "C1i_1_2",
              "gu": "C1i_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He remembers the advice given by Sujay to speak to the point and politely wait for his turn to speak. Taking a deep breath, he enters the room.",
              "hi": "वह सुजय द्वारा दी गई सलाह को याद करता है जिसमे बिंदु पर बात करना और विनम्रतापूर्वक अपनी बारी की प्रतीक्षा करना कहा गया है। गहरी सांस लेकर, वह कमरे में प्रवेश करता है।",
              "ka": "ವಿಷಯಕ್ಕೆ ತಕ್ಕಂತೆ ಮಾತನಾಡಲು ಸುಜಯ್ ನೀಡಿದ ಸಲಹೆಯನ್ನು ಅವನು ನೆನಪಿಸಿಕೊಳ್ಳುತ್ತಾನೆ ಮತ್ತು ಮಾತನಾಡುವ ಸರದಿಗಾಗಿ ಸಹನೆಯಿಂದ  ಕಾಯುತ್ತಾನೆ. ಧೀರ್ಘವಾದ ಉಸಿರನ್ನು ತೆಗೆದುಕೊಂಡು ಅವನು ಕೋಣೆಯ ಒಳಗಡೆ ಪ್ರವೇಶಿಸುತ್ತಾನೆ.",
              "te": "పాయింట్‌ కి తగ్గట్టుగా మాట్లాడాలని, తన వంతు వచ్చే వరకు మర్యాదగా ఎదురుచూడాలని సుజయ్‌ ఇచ్చిన సలహాను గుర్తు చేసుకున్నారు. గట్టిగా ఊపిరి పీల్చుకుంటూ గదిలోకి ప్రవేశించాడు.",
              "or": "ସେ ସୁଜୟ କହିଥିବା କଥାକୁ ମନେପକେଇଲା କି ସଠିକ ଦିଗରେ କହିବ ଓ ନିଜର \n ପାଳି ଆସିବା ଯାଏଁ ନାମର ଭାବେ ଅପେକ୍ଷା କରିବାକୁ। ଏକ ଦୀର୍ଘ ନିଶ୍ଵାସ ନେଇ ସେ\n  କୋଠରି ମଧ୍ୟକୁ ପ୍ରବେଶ କଳା।",
              "as": "সি কথা ক'বলৈ সুজয়ে দিয়া পৰামৰ্শটো মনত ৰাখে আৰু নম্ৰতাৰে তাৰ কথা কোৱাৰ পাললৈ ৰয়। দীঘলীয়া উশাহ লৈ সি কোঠাটোলৈ সোমায়।",
              "gu": "તેને સુજય દ્વારા આપેલી સલાહ યાદ આવે છે જે જે પોઈન્ટ ટુ ધ પોઈન્ટ બોલવાની અને નમ્રતાથી તેના બોલવાના વારો આવે ત્યારે રાહ જોવાની. ઊંડો શ્વાસ લઈને તે રૂમમાં પ્રવેશે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1i_1_3",
              "hi": "C1i_1_3",
              "ka": "C1i_1_3",
              "te": "C1i_1_3",
              "or": "C1i_1_3",
              "as": "C1i_1_3",
              "gu": "C1i_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Welcome everyone to the group discussion round. You have 20 mins for this discussion. Today's topic of discussion is -\nHigher Education vs Work Experience: Which one is more important?\"\"",
              "hi": "समूह चर्चा दौर में सभी का स्वागत है। इस चर्चा के लिए आपके पास 20 मिनट हैं। आज की चर्चा का विषय है -\nउच्च शिक्षा बनाम कार्य अनुभव: कौन सा अधिक महत्वपूर्ण है? \"\"",
              "ka": "ಗುಂಪು ಚರ್ಚೆಯ ಸುತ್ತಿಗೆ ಎಲ್ಲರಿಗೂ ಸ್ವಾಗತ. ಈ ಚರ್ಚೆಗೆ ನಿಮಗೆ 20 ನಿಮಿಷಗಳಿವೆ. ಇಂದಿನ ಚರ್ಚೆಯ ವಿಷಯವೆಂದರೆ - ಉನ್ನತ ಶಿಕ್ಷಣ ಮತ್ತು ಕೆಲಸದ ಅನುಭವ: ಯಾವುದು ಹೆಚ್ಚು ಮುಖ್ಯ?\"\"",
              "te": "బృంద చర్చ రౌండ్‌ కి అందరికీ స్వాగతం. ఈ చర్చకు మీకు 20 నిమిషాల సమయం ఉంది. ఈరోజు చర్చనీయాంశం -\n ఉన్నత విద్య vs పని అనుభవం: ఏది ముఖ్యమైనది?\"\"",
              "or": "ସଙ୍ଘଗତ ବିଚାର ବିମର୍ଶକୁ ସମସ୍ତଙ୍କୁ ସ୍ଵାଗତ। ଆପଣଙ୍କ ପାଖେ ବିଚାର ବିମର୍ଷ ପାଇଁ କୋଡିଏ ମିନିଟ ଲେଖାଏଁ ସମୟ ଅଛି। ଆଜିର ବିଷୟ ହେଉଛି- ଉଚ୍ଚ ଶିକ୍ଷା vs କାର୍ଯ୍ୟ ରା ଅନୁଭବ: କେଉଁଟି ବେଶୀ ଜରୁରୀ?",
              "as": "দলীয় আলোচনা পৰ্যায়লৈ সকলোকে স্বাগতম জনাইছোঁ। এই আলোচনাৰ বাবে তোমালোকৰ ওচৰত ২০ মিনিট আছে। আজিৰ আলোচনাৰ বিষয় হৈছে -\n উচ্চ শিক্ষা বনাম কৰ্ম অভিজ্ঞতা: কোনটো অধিক গুৰুত্বপূৰ্ণ?\"",
              "gu": "સમૂહ ચર્ચા રાઉન્ડમાં દરેકનું સ્વાગત છે. આ ચર્ચા માટે તમારી પાસે 20 મિનિટ છે. આજનો ચર્ચાનો વિષય છે - ઉચ્ચ શિક્ષણ વિરુદ્ધ કામનો અનુભવ: કયું વધુ મહત્વનું છે?\""
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1i_1_4//",
              "hi": "C1i_1_4 //",
              "ka": "C1i_1_4//",
              "te": "C1i_1_4//",
              "or": "C1i_1_4//",
              "as": "C1i_1_4//",
              "gu": "C1i_1_4//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Person 1",
              "hi": "व्यक्ति 1",
              "ka": "ವ್ಯಕ್ತಿ 1",
              "te": "వ్యక్తి 1",
              "or": "ପ୍ରଥମ ଲୋକ",
              "as": "ব্যক্তি 1",
              "gu": "વ્યક્તિ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Person 2",
              "hi": "व्यक्ति 2",
              "ka": "ವ್ಯಕ್ತಿ 2",
              "te": "వ్యక్తి 2",
              "or": "ଦ୍ଵିତୀୟ ଲୋକ",
              "as": "ব্যক্তি 2",
              "gu": "વ્યક્તિ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Person 3",
              "hi": "व्यक्ति 3",
              "ka": "ವ್ಯಕ್ತಿ 3",
              "te": "వ్యక్తి 3",
              "or": "ତୃତୀୟ ଲୋକ",
              "as": "ব্যক্তি 3",
              "gu": "વ્યક્તિ 3"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Education isn't just about gaining knowledge. You also meet a lot of new people in university and learn about new cultures.",
              "hi": "शिक्षा सिर्फ ज्ञान प्राप्त करने के बारे में नहीं है। आप विश्वविद्यालय में कई नए लोगों से भी मिलते हैं और नई संस्कृतियों के बारे में जानते हैं।",
              "ka": "ಶಿಕ್ಷಣ ಎಂದರೆ ಕೇವಲ ಬುದ್ದಿಯನ್ನು ಗಳಿಸುವುದಲ್ಲ. ನೀವು ವಿಶ್ವವಿದ್ಯಾನಿಲಯದಲ್ಲಿ ಬಹಳಷ್ಟು ಹೊಸ ಜನರನ್ನು ಭೇಟಿಯಾಗುತ್ತೀರಿ ಮತ್ತು ಹೊಸ ಸಂಸ್ಕೃತಿಗಳ ಬಗ್ಗೆ ಕಲಿಯುತ್ತೀರಿ.",
              "te": "విద్య అంటే కేవలం జ్ఞానాన్ని పొందడమే కాదు. మీరు విశ్వవిద్యాలయంలో చాలా మంది కొత్త వ్యక్తులను కూడా కలుస్తారు మరియు కొత్త సంస్కృతుల గురించి తెలుసుకుంటారు.",
              "or": "ଶିକ୍ଷାର ଅର୍ଥ କେବଳ ଜ୍ଞାନ ଆହରଣ କରିବା ନୁହେଁ। ତୁମେ ମହାବିଦ୍ୟାଳୟରେ \n ବହୁତ ଗୁଡିଏ ନୂଆ ଲୋକଙ୍କୁ ଦେହ କରି ସେମାନଙ୍କ ପରମ୍ପରା ବିଷୟରେ ଜାଣିପାରିବ।",
              "as": "শিক্ষা কেৱল জ্ঞান লাভৰ বাবে নহয়। তুমি বিশ্ববিদ্যালয়ত বহুতো নতুন মানুহক লগ পোৱা আৰু নতুন সংস্কৃতিৰ বিষয়ে শিকা।",
              "gu": "શિક્ષણ માત્ર જ્ઞાન મેળવવાનું નથી. તમે યુનિવર્સિટીમાં ઘણા નવા લોકોને મળો છો અને નવી સંસ્કૃતિઓ વિશે શીખો છો."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I think it also teaches you to respect different perspectives.",
              "hi": "मेरे विचार में यह आपको विभिन्न दृष्टिकोणों का सम्मान करना भी सिखाता है।",
              "ka": "ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳನ್ನು ಗೌರವಿಸಲು ಇದು ನಿಮಗೆ ಕಲಿಸುತ್ತದೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "విభిన్న అభిప్రాయాలను గౌరవించడం కూడా మీకు నేర్పుతుందని నేను భావిస్తున్నాను.",
              "or": "ବିଭିର୍ଣ୍ଣ ପ୍ରକାର ରା ପ୍ରତ୍ୟାଶିତ କୁ ସମ୍ମାନ ଦେବାକୁ ମଧ୍ୟ ଏହା ଶିଖାଇଥାଏ।",
              "as": "মই ভাবো ই তোমাক বিভিন্ন দৃষ্টিভংগীক সন্মান কৰিবলৈও শিকায়।",
              "gu": "મને લાગે છે કે તે તમને વિવિધ દ્રષ્ટિકોણનો આદર કરવાનું પણ શીખવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "But you can learn that in a job experience as well. Plus, you get practical experience which is often missing in a college education.",
              "hi": "लेकिन आप वह सब नौकरी केअनुभव से भी सीख सकते हैं । इसके अलावा, आपको व्यावहारिक अनुभव मिलता है जो अक्सर कॉलेज शिक्षा में गायब होता है।",
              "ka": "ಆದರೆ ನೀವು ಅದನ್ನು ಕೆಲಸದ ಅನುಭವದಲ್ಲಿಯೂ ಸಹಾ ಕಲಿಯಬಹುದು. ಜೊತೆಗೆ, ಕಾಲೇಜು ಶಿಕ್ಷಣದಲ್ಲಿ ಸಾಮಾನ್ಯವಾಗಿ ಕಾಣೆಯಾಗಿರುವ ಪ್ರಾಯೋಗಿಕ ಅನುಭವವನ್ನು ನೀವು ಪಡೆಯುತ್ತೀರಿ.",
              "te": "కానీ మీరు ఉద్యోగ అనుభవంలో కూడా నేర్చుకోవచ్చు. అదనంగా, మీరు కళాశాల విద్యలో తరచుగా లేని ప్రాక్టికల్ అనుభవాన్ని పొందుతారు.",
              "or": "କିନ୍ତୁ ତାହାକୁ ତୁମେ ଚାକିରି କାଳ ମାଧରେ ବି ଶିଖିପାରିବା। ତାସହିତ ତୁମେ \n ବ୍ୟବହାରିକ ଅନୁଭବ ମଧ୍ୟ ଜାଣିପାରିବ ଯାହା କି କଲେଜ ପଢା ସମୟରେ ଶିଖାଯାଇନଥାଏ।",
              "as": "কিন্তু তুমি সেইটো চাকৰিৰ অভিজ্ঞতাতো শিকিব পাৰা। লগতে, তুমি ব্যৱহাৰিক অভিজ্ঞতা লাভ কৰা যি প্ৰায়ে কলেজৰ শিক্ষাত নাথাকে।",
              "gu": "પરંતુ તમે તે નોકરીના અનુભવમાં પણ શીખી શકો છો. ઉપરાંત, તમને વ્યવહારુ અનુભવ મળે છે જે ઘણીવાર કૉલેજ શિક્ષણમાં ખૂટે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1i_1_5_d",
              "hi": "C1i_1_5_d",
              "ka": "C1i_1_5_d",
              "te": "C1i_1_5_d",
              "or": "C1i_1_5_d",
              "as": "C1i_1_5_d",
              "gu": "C1i_1_5_d"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Shikhar say?",
              "hi": "शिखर को क्या कहना चाहिए?",
              "ka": "ಶಿಖರ್ ಏನು ಹೇಳಬೇಕು?",
              "te": "శిఖర్ ఏం చెప్పాలి?",
              "or": "ଶିଖର କଣ କହିବ?",
              "as": "শিখৰে কি ক'ব লাগে?",
              "gu": "શિખરે શું કહેવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Yes, and adding to that, you can earn and learn simultaneously with working experience.",
              "hi": "हां, और उसके अतिरिक्त, आप काम के अनुभव के साथ - साथ धन कमा सकते हैं और सीख भी सकते हैं।",
              "ka": "ಹೌದು, ಮತ್ತು ಅದಕ್ಕೆ ಪೂರಕವಾಗಿ ಹೇಳುವುದಾದರೆ, ನೀವು ಕೆಲಸದ ಅನುಭವದೊಂದಿಗೆ ಅದನ್ನು ಏಕಕಾಲದಲ್ಲಿ ಗಳಿಸಬಹುದು ಮತ್ತು ಕಲಿಯಬಹುದು.",
              "te": "అవును, మరియు దానికి జోడిస్తూ, మీరు పని అనుభవంతో ఏకకాలంలో సంపాదించవచ్చు మరియు నేర్చుకోవచ్చు.",
              "or": "ହଁ, ତାସହିତ , ତୁମେ ଚାକିରିରେ ସମାନ୍ତରାଳ ଭାବେ ଶିଖିବା ଓ ପଇସା ରୋଜଗାର \n ମଧ୍ୟ କରିପାରିବ।",
              "as": "হয়, আৰু ইয়াত যোগ দি, তুমি কামৰ অভিজ্ঞতাৰ সৈতে একেলগে উপাৰ্জন আৰু শিকিব পাৰা।",
              "gu": "હા, અને તેમાં ઉમેરો કરીને, તમે કામના અનુભવ સાથે કમાણી અને શીખવા પણ મળે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "In education, you can focus on developing your skill sets, whereas you might get caught up with work and learning is ignored on the job.",
              "hi": "शिक्षा में, आप अपने स्किल सेट/ कौशल को विकसित करने पर ध्यान केंद्रित कर सकते हैं, जबकि  नौकरी में आप पर काम का बहुत दबाव होता है और सीखने को  अनदेखा किया जा सकता है।",
              "ka": "ಶಿಕ್ಷಣದಲ್ಲಿ, ನಿಮ್ಮ ಕೌಶಲ್ಯದ ಸೆಟ್‌ಗಳನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸುವುದರ ಮೇಲೆ ನೀವು ಗಮನಹರಿಸಬಹುದು, ಆದರೆ ನೀವು ಕೆಲಸದಲ್ಲಿ ಸಿಕ್ಕಿಹಾಕಿಕೊಳ್ಳಬಹುದು ಮತ್ತು ಕೆಲಸದಲ್ಲಿ ಕಲಿಕೆಯನ್ನು ನಿರ್ಲಕ್ಷಿಸಬಹುದು.",
              "te": "విద్యలో, మీరు మీ నైపుణ్యాన్ని అభివృద్ధి చేయడంపై దృష్టి పెట్టవచ్చు, అయితే మీరు పనిలో చిక్కుకుపోయి నేర్చుకోవడం కుదరకపోవచ్చు.",
              "or": "ଶିକ୍ଷା ରେ ତୁମେ ନିଜର କୌଶଳ ର ବିକାଶ ଉପରେ ଗୁରୁତ୍ଵ ଦେଇପାରିବ, ଯେଉଁଠାରେ କି,???",
              "as": "শিক্ষাৰ ক্ষেত্ৰত, তুমি তোমাৰ দক্ষতাবোৰ বিকাশ কৰাত গুৰুত্ব দিব পাৰা, আনহাতে তুমি কামত লাগি থাকিবা আৰু চাকৰিত শিকাটো অৱহেলা কৰিবা।",
              "gu": "શિક્ષણમાં, તમે તમારા કૌશલ્યની રીત વિકસાવવા પર ધ્યાન કેન્દ્રિત કરી શકો છો, જ્યારે તમે કામમાં ફસાઈ શકો છો અને નોકરીમાં શીખવાની અવગણના કરવામાં આવે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1i_1_6",
              "hi": "C1i_1_6",
              "ka": "C1i_1_6",
              "te": "C1i_1_6",
              "or": "C1i_1_6",
              "as": "C1i_1_6",
              "gu": "C1i_1_6"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Person 2",
              "hi": "व्यक्ति 2",
              "ka": "ವ್ಯಕ್ತಿ 2",
              "te": "వ్యక్తి 2",
              "or": "ଦ୍ଵିତୀୟ ଲୋକ",
              "as": "ব্যক্তি 2",
              "gu": "વ્યક્તિ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Person 3",
              "hi": "व्यक्ति 3",
              "ka": "ವ್ಯಕ್ತಿ 3",
              "te": "వ్యక్తి 3",
              "or": "ତୃତୀୟ ଲୋକ",
              "as": "ব্যক্তি 3",
              "gu": "વ્યક્તિ 3"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I would also say that experience can provide essential career skills\nlike leadership, communication and time management which may not develop in college.",
              "hi": "मैं यह भी कहूंगा कि अनुभव आवश्यक करियर स्किल/ कौशल की तरह नेतृत्व, संचार और समय प्रबंधन  प्रदान कर सकता है,\nजो कॉलेज में विकसित नहीं हो सकता है।",
              "ka": "ಅನುಭವವು ನಾಯಕತ್ವ, ಸಂವಹನ ಮತ್ತು ಸಮಯ ನಿರ್ವಹಣೆಯಂತಹ ಅಗತ್ಯ ವೃತ್ತಿ ಕೌಶಲ್ಯಗಳನ್ನು ಒದಗಿಸುತ್ತದೆ ಎಂದು ನಾನು ಹೇಳುತ್ತೇನೆ, ಅದು ಕಾಲೇಜಿನಲ್ಲಿ ಅಭಿವೃದ್ಧಿಯಾಗುವುದಿಲ್ಲ.",
              "te": "అనుభవం అవసరమైన కెరీర్ నైపుణ్యాలను అందించగలదని కూడా నేను చెబుతాను\n నాయకత్వం, కమ్యూనికేషన్ మరియు సమయ నిర్వహణ వంటివి కళాశాలలో అభివృద్ధి చెందకపోవచ్చు.",
              "or": "ମୁଁ ଏହା ମଧ୍ୟ କହିବି ଯେ ଅନୁଭବ, ନେତୃତ୍ଵ ଭଳି ଜରୁରୀ କ୍ୟାରିଆର କୌଶଳ \n , ସମୟର ସଂଚାଳନ ଓ ଯୋଗାଯୋଗ ମଧ୍ୟ ଯୋଗାଇଥାଏ ଯାହାକି କଲେଜ ରେ \n ହୋଇପାରିନଥାଏ।",
              "as": "মই এইটোও ক'ম যে অভিজ্ঞতাই কেৰিয়াৰৰ প্ৰয়োজনীয় দক্ষতা দিব পাৰে যেনে নেতৃত্ব, যোগাযোগ আৰু সময় মেনেজমেন্ট যি কলেজত বিকাশ নহ'ব পাৰে।",
              "gu": "હું એમ પણ કહીશ કે અનુભવ નેતૃત્વ, વાર્તાલાભ અને સમય વ્યવસ્થાપન જેવી આવશ્યક કારકિર્દી કૌશલ્યો પ્રદાન કરી શકે છે જે કૉલેજમાં શીખવા ના મળી શકે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "These are skills you can learn anywhere. You have proof of the training with formal education, which always helps when looking for a job.",
              "hi": "ये वे कौशल हैं जिन्हें आप कहीं भी सीख सकते हैं। आपके पास औपचारिक शिक्षा के साथ प्रशिक्षण का सबूत है, जो हमेशा नौकरी की तलाश करते समय सहायता करता है।",
              "ka": "ಇವುಗಳು ನೀವು ಎಲ್ಲಿ ಬೇಕಾದರೂ ಕಲಿಯಬಹುದಾದ ಕೌಶಲ್ಯಗಳಾಗಿವೆ. ನೀವು ಔಪಚಾರಿಕ ಶಿಕ್ಷಣದೊಂದಿಗೆ ತರಬೇತಿಯ ಪುರಾವೆಯನ್ನು ಹೊಂದಿರುತ್ತೀರಿ, ಇದು ಉದ್ಯೋಗವನ್ನು ಹುಡುಕುತ್ತಿರುವಾಗ ಯಾವಾಗಲೂ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "ఇవి మీరు ఎక్కడైనా నేర్చుకోగల నైపుణ్యాలు. మీరు అధికారిక విద్యతో శిక్షణకు సంబంధించిన రుజువును కలిగి ఉన్నారు, ఇది ఉద్యోగం కోసం వెతుకుతున్నప్పుడు ఎల్లప్పుడూ సహాయపడుతుంది.",
              "or": "ଏହି ସବୁ କୌଶଳ କୁ ତୁମେ କେଉଁଠାରେ ବି ଶିଖିପାରିବ। ତୁମ ପାଖରେ ଔପଚାରିକ \n ଶିକ୍ଷାର ପ୍ରମାଣ ଅଛି, ଯାହାକି ସବୁବେଳେ ତୁମକୁ ଚାକିରି ଖୋଜିବାରେ ସାହାଯ୍ୟ କରିବା।",
              "as": "এইবোৰ হৈছে তুমি যিকোনো ঠাইতে শিকিব পৰা দক্ষতা। তোমাৰ ওচৰত আনুষ্ঠানিক শিক্ষাৰ সৈতে প্ৰশিক্ষণৰ প্ৰমাণ আছে, যি চাকৰি বিচাৰোতে সদায়ে সহায় কৰে।",
              "gu": "આ કૌશલ્યો તમે ગમે ત્યાં શીખી શકો છો. તમારી પાસે ઔપચારિક શિક્ષણ સાથેની તાલીમનો પુરાવો છે, જે નોકરીની શોધ કરતી વખતે હંમેશા મદદ કરે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1i_1_7_d",
              "hi": "C1i_1_7_d",
              "ka": "C1i_1_7_d",
              "te": "C1i_1_7_d",
              "or": "C1i_1_7_d",
              "as": "C1i_1_7_d",
              "gu": "C1i_1_7_d"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Shikhar say?",
              "hi": "शिखर को क्या कहना चाहिए?",
              "ka": "ಶಿಖರ್ ಏನು ಹೇಳಬೇಕು?",
              "te": "శిఖర్ ఏం చెప్పాలి?",
              "or": "ଶିଖର କଣ କହିବା ଉଚ୍ଚିତ ହବ?",
              "as": "শিখৰে কি ক'ব লাগে?",
              "gu": "શિખરે શું કહેવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Though education is expensive, there are many ways to learn online, often for free.",
              "hi": "हालांकि शिक्षा महंगी है, परन्तु ऑनलाइन सीखने के भी कई तरीके हैं, अक्सर निःशुल्क।",
              "ka": "ಶಿಕ್ಷಣವು ದುಬಾರಿಯಾಗಿದ್ದರೂ, ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಕಲಿಯಲು ಹಲವು ಮಾರ್ಗಗಳಿವೆ, ಕೆಲವು ಬಾರಿ ಉಚಿತವಾಗಿ.",
              "te": "విద్య ఖరీదైనది అయినప్పటికీ, ఆన్‌లైన్‌ లో ఉచితంగా నేర్చుకోవడానికి కూడా అనేక మార్గాలు ఉన్నాయి.",
              "or": "ଯଦିଓ ଶିକ୍ଷା ବ୍ୟୟବହୁଳ, ଶିଖିବା ପାଇଁ ଅନଲାଇନ ରେ ବହୁତ ସାରା ମାଧ୍ୟମ ଅଛି\n , ଏମିତିକି ନିଃଶୁଳ୍କ ମଧ୍ୟ।",
              "as": "যদিও শিক্ষা খৰছী, অনলাইন শিকাৰ বহুতো উপায় আছে, প্ৰায়ে বিনামূলীয়াকৈ।",
              "gu": "શિક્ષણ ખર્ચાળ હોવા છતાં, ઑનલાઇન શીખવાની ઘણી રીતો છે, ઘણી વખત મફતમાં."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "True, and along with learning from great teachers, you can also get industry training through internships.",
              "hi": "सच है, और महान शिक्षकों से सीखने के साथ ही, आप इंटर्नशिप के माध्यम से  इंडस्ट्री / उद्योग प्रशिक्षण भी प्राप्त कर सकते हैं।",
              "ka": "ನಿಜ, ಮತ್ತು ಉತ್ತಮ ಶಿಕ್ಷಕರಿಂದ ಕಲಿಯುವುದರ ಜೊತೆಗೆ, ನೀವು ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳ ಮೂಲಕ ಉದ್ಯಮದ ತರಬೇತಿಯನ್ನು ಸಹ ಪಡೆಯಬಹುದು.",
              "te": "నిజమే, గొప్ప ఉపాధ్యాయుల నుండి నేర్చుకోవడంతో పాటు, మీరు ఇంటర్న్‌షిప్‌ల ద్వారా పరిశ్రమలో శిక్షణ కూడా పొందవచ్చు.",
              "or": "ସତ କଥା, ଭଲ ଶିକ୍ଷକଙ୍କ ଠାରୁ ଶିଖିବା ସହିତ, ତୁମେ ଇନ୍ଟର୍ନସିପ ମାଧ୍ୟମରେ ଉଦ୍ଯୋଗ ପ୍ରଶିକ୍ଷଣ ମଧ୍ୟ କରିପାରିବ।",
              "as": "সঁচা, আৰু ভাল শিক্ষকসকলৰ পৰা শিকাৰ লগতে, তুমি ইণ্টাৰ্নশ্বিপৰ জৰিয়তে উদ্যোগৰ প্ৰশিক্ষণো পাব পাৰা।",
              "gu": "સાચું, અને મહાન શિક્ષકો પાસેથી શીખવાની સાથે, તમે ઇન્ટર્નશિપ દ્વારા ઉદ્યોગની તાલીમ પણ મેળવી શકો છો."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1i_1_8////",
              "hi": "C1i_1_8 ////",
              "ka": "C1i_1_8////",
              "te": "C1i_1_8////",
              "or": "C1i_1_8////",
              "as": "C1i_1_8////",
              "gu": "C1i_1_8////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Ok, time's up! That was a good discussion. All of you had interesting points. Shikhar, why don't you conclude this discussion?",
              "hi": "ठीक है, समय समाप्त! यह एक अच्छी चर्चा थी। आप सभी के पास रोचक बिंदु थे। शिखर, क्यों न आप इस चर्चा का निष्कर्ष बताएं ?",
              "ka": "ಸರಿ, ಸಮಯವು ಮುಗಿದಿದೆ! ಅದು ಒಳ್ಳೆಯ ಚರ್ಚೆಯಾಗಿತ್ತು. ನಿಮ್ಮೆಲ್ಲರಿಗೂ ಆಸಕ್ತಿದಾಯಕ ಅಂಶಗಳಿವೆ. ಶಿಖರ್, ನೀವು ಈ ಚರ್ಚೆಯನ್ನು ಏಕೆ ಮುಕ್ತಾಯಗೊಳಿಸಬಾರದು?",
              "te": "సరే, సమయం ముగిసింది! అది మంచి చర్చ. మీ అందరికీ ఆసక్తికరమైన అంశాలు ఉన్నాయి. శిఖర్, మీరు ఈ చర్చను ఎందుకు ముగించకూడదు?",
              "or": "ଠିକ ଅଛି, ସମୟ ସମାପ୍ତ ହେଲା! ଏହା ଏକ ଭଲ ଆଲୋଚନା ଥିଲା। ଆପଣ ମାନଙ୍କର \n ପଏଣ୍ଟ ଗୁଡିକ ମନଲୋଭା ଥିଲା। ଶିଖର, ତୁମେ କାହିଁକି ଆଲୋଚନାର କିଛି ନିଷ୍କର୍ଷ \n ବାହାର କରିପାରିଲନି ?",
              "as": "ঠিক আছে, সময় শেষ হৈছে! এইটো এটা ভাল আলোচনা আছিল। তোমালোক সকলোৰে আকৰ্ষণীয় কথা আছিল। শিখৰ, তুমি এই আলোচনা কিয় শেষ নকৰা?",
              "gu": "ઠીક છે, સમય પૂરો થયો! તે સારી ચર્ચા હતી. તમારા બધાના રસપ્રદ મુદ્દા હતા. શિખર, તું આ ચર્ચા કેમ પૂર્ણ કરતો નથી?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I think we can conclude by saying that we should see experience and education as supplementary to each other. That way, we can get the benefits of both and successfully navigate our careers.",
              "hi": "मुझे लगता है कि हम यह कहकर निष्कर्ष निकाल सकते हैं कि हमें अनुभव और शिक्षा को एक-दूसरे के पूरक के रूप में  देखना चाहिए। इस तरह, हम दोनों के लाभ प्राप्त कर सकते हैं और सफलतापूर्वक अपने करियर को नेविगेट/  सही दिशा प्रदान कर सकते हैं।",
              "ka": "ನಾವು ಅನುಭವ ಮತ್ತು ಶಿಕ್ಷಣವನ್ನು ಪರಸ್ಪರ ಪೂರಕವಾಗಿ ನೋಡಬೇಕು ಎಂದು ಹೇಳುವ ಮೂಲಕ ನಾವು ಮುಗಿಸಬಹುದು ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ಆ ರೀತಿಯಲ್ಲಿ, ನಾವು ಎರಡರ ಪ್ರಯೋಜನಗಳನ್ನು ಪಡೆಯಬಹುದು ಮತ್ತು ನಮ್ಮ ವೃತ್ತಿಜೀವನವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನ್ಯಾವಿಗೇಟ್ ಮಾಡಬಹುದು.",
              "te": "అనుభవాన్ని, చదువును ఒకదానికొకటి అనుబంధంగా చూడాలి అని చెప్పి ముగించవచ్చు అనుకుంటున్నాను. ఆ విధంగా, మనము రెండింటి ప్రయోజనాలను పొందవచ్చు మరియు మన కెరీర్‌ ను విజయవంతంగా ఏర్పాటు చేసుకోవచ్చు.",
              "or": "ମୁଁ ଭାବୁଛି ଆମେ ଏହା କହି ନିଷ୍କର୍ଷ ବାହାର କରିବା ଯେ,ଅନୁଭବ ଓ ଶିକ୍ଷା ପରସ୍ପର ପୂରକ ଅଟନ୍ତି। ସେହି ରାସ୍ତା ରେ ଆମେ ଦୁଇଟି ଯାକ ରା ଲାଭ ପାଇପାରିବା ଏବଂ ସଫଳତାର ସହ ନିଜ କ୍ୟାରିଆର କୁ ଆଗେଇନେଇ ପାରିବା।",
              "as": "মই ভাবো যে আমি অভিজ্ঞতা আৰু শিক্ষাক ইটো আনটোৰ পৰিপূৰক হিচাপে চাব লাগে বুলি কৈ শেষ কৰিব পাৰোঁ। এনেদৰে, আমি দুয়োটাৰে লাভালাভ পাব পাৰোঁ আৰু সফলতাৰে আমাৰ কেৰিয়াৰ চলাব পাৰোঁ।",
              "gu": "મને લાગે છે કે આપણે એમ કહીને નિષ્કર્ષ પર આવી શકીએ છીએ કે આપણે અનુભવ અને શિક્ષણને એકબીજાના પૂરક તરીકે જોવું જોઈએ. આ રીતે, આપણને બંનેનો લાભ મેળવી શકીએ છીએ અને આપણી કારકિર્દીને સફળતાપૂર્વકની દીશા નક્કી કરી શકીએ છીએ."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "GroupDiscussion",
              "hi": "सामूहिक चर्चा",
              "ka": "ಗುಂಪು ಚರ್ಚೆ",
              "te": "బృంద చర్చ",
              "or": "ଗୋଷ୍ଠୀଗତ ଆଲୋଚନା",
              "as": "দলীয় আলোচনা",
              "gu": "જૂથ ચર્ચા"
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1i_1_9",
              "hi": "C1i_1_9",
              "ka": "C1i_1_9",
              "te": "C1i_1_9",
              "or": "C1i_1_9",
              "as": "C1i_1_9",
              "gu": "C1i_1_9"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The group discussion gives a lot of confidence to Shikhar, and he performs well in his interview. \nA few days later, he gets the admission letter!",
              "hi": "समूह चर्चा शिखर को बहुत आत्मविश्वास देती है, और वह अपने साक्षात्कार में अच्छा प्रदर्शन करता है।\nकुछ दिनों बाद, उसे प्रवेश पत्र मिलता है!",
              "ka": "ಗುಂಪು ಚರ್ಚೆಯು ಶಿಖರ್‌ಗೆ ಹೆಚ್ಚಿನ ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ನೀಡುತ್ತದೆ ಮತ್ತು ಅವನು ತನ್ನ ಸಂದರ್ಶನದಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಾನೆ. ಕೆಲವು ದಿನಗಳ ನಂತರ, ಅವನು ಪ್ರವೇಶ ಪತ್ರವನ್ನು ಪಡೆಯುತ್ತಾನೆ",
              "te": "బృంద చర్చ శిఖర్‌ కి చాలా ఆత్మవిశ్వాసాన్ని ఇస్తుంది మరియు అతను తన ఇంటర్వ్యూలో బాగా రాణిస్తున్నాడు.\n కొన్ని రోజుల తర్వాత, అతనికి ప్రవేశ లేఖ వచ్చింది!",
              "or": "ଅଷ୍ଠୀଗତ ଆଲୋଚନା ଶିଖର କୁ ବହୁତ ସାରା ଆତ୍ମବିଶ୍ଵାସ ଦେଲା, ଏବଂ ସେ ନିଜ\n ସାକ୍ଷାତକାର ରେ ଭଲ ପ୍ରଦର୍ଶନ କଲା। \n କିଛିଦିନ ପରେ ସେ ଏକ ପ୍ରବେଶ ପାତ୍ର ପାଇଲା।",
              "as": "দলীয় আলোচনাই শিখৰক যথেষ্ট আত্মবিশ্বাস দিয়ে, আৰু সি তাৰ সাক্ষাৎকাৰত ভাল প্ৰদৰ্শন কৰে। \n \n কেইদিনমানৰ পিছত, সি নামভৰ্তিৰ পত্ৰখন পায়!",
              "gu": "સમૂહ ચર્ચા શિખરને ઘણો આત્મવિશ્વાસ આપે છે, અને તે ઇન્ટરવ્યુમાં સારું પ્રદર્શન કરે છે. થોડા દિવસો પછી, તેને પ્રવેશ પત્ર મળે છે!"
            }
          }
        ]
      },
      "scene21": {
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_1",
              "hi": "C2_1_1",
              "ka": "C2_1_1",
              "te": "C2_1_1",
              "or": "C2_1_1",
              "as": "C2_1_1",
              "gu": "C2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar has started his Hotel Management course at the JJM university in Indore. He is excited about moving to a large city to study.",
              "hi": "शिखर ने इंदौर के जेएसएम विश्वविद्यालय में अपना होटल प्रबंधन कोर्स शुरु किया है। वह अध्ययन करने के लिए एक बड़े शहर में जाने पर उत्साहित है।",
              "ka": "ಶಿಖರ್ ಇಂದೋರ್‌ನ ಜೆಜೆಎಂ ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಕೋರ್ಸ್ ಆರಂಭಿಸಿದ್ದಾರೆ. ಅವನು ಅಧ್ಯಯನ ಮಾಡಲು ದೊಡ್ಡ ನಗರಕ್ಕೆ ತೆರಳಲು ಉತ್ಸುಕನಾಗಿದ್ದಾನೆ.",
              "te": "శిఖర్ ఇండోర్‌లోని JJM విశ్వవిద్యాలయంలో హోటల్ మేనేజ్‌మెంట్ కోర్సును ప్రారంభించాడు. అతను చదువుకోవడానికి పెద్ద నగరానికి వెళ్లడం పట్ల ఉత్సాహంగా ఉన్నాడు.",
              "or": "ଶିଖର ନିଜର ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ପାଠ୍ୟକ୍ରମକୁ ଇନ୍ଦୋର ରା JJM \n ମହାବିଦ୍ୟାଳୟରେ ଆରମ୍ଭ କଲା। ଏକ ବିରାଟ ନଗରୀକୁ ପଢିବାକୁ ଆସୁଥିବାରୁ ସେ \n ରୋମାଞ୍ଚ ଅନୁଭବ କଲା।",
              "as": "শিখৰে ইন্দোৰৰ JJM বিশ্ববিদ্যালয়ত হোটেল মেনেজমেণ্ট পাঠ্যক্ৰম আৰম্ভ কৰিছে। সি পঢ়িবলৈ এখন ডাঙৰ চহৰলৈ যোৱাৰ বাবে উৎসাহিত।",
              "gu": "શિખરે ઈન્દોરની JJM યુનિવર્સિટીમાં હોટેલ મેનેજમેન્ટનો કોર્સ શરૂ કર્યો છે. તે ભણવા માટે મોટા શહેરમાં જવા માટે ઉત્સાહિત છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_2",
              "hi": "C2_1_2",
              "ka": "C2_1_2",
              "te": "C2_1_2",
              "or": "C2_1_2",
              "as": "C2_1_2",
              "gu": "C2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "In the first year of college, Shikhar has many mandatory classes. These are all important to set the foundation for his career.",
              "hi": "कॉलेज के पहले वर्ष में  शिखर की कई अनिवार्य कक्षाएं हैं। ये उसके करियर की नींव निर्धारित करने के लिए सभी महत्वपूर्ण हैं।",
              "ka": "ಕಾಲೇಜಿನ ಮೊದಲ ವರ್ಷದಲ್ಲಿ ಶಿಖರ್‌ಗೆ ಹಲವು ಕಡ್ಡಾಯ ತರಗತಿಗಳಿವೆ. ಅವನ ವೃತ್ತಿಜೀವನಕ್ಕೆ ಅಡಿಪಾಯ ಹಾಕಲು ಇವೆಲ್ಲವೂ ಮುಖ್ಯವಾಗಿದೆ.",
              "te": "కళాశాల మొదటి సంవత్సరంలో, శిఖర్‌ కు అనేక తప్పనిసరి తరగతులు ఉన్నాయి. అతని కెరీర్‌ కు పునాది వేయడానికి ఇవన్నీ ముఖ్యమైనవి.",
              "or": "କଲେଜ ର ପ୍ରଥମ ବର୍ଷରେ, ଶିଖର ର ବହୁତ ଅନିବାର୍ଯ୍ୟ କ୍ଳାସ ରହିଲା। ଏଗୁଡିକ \n ତାର କ୍ୟାରିଆର ର ମୂଳଦୁଆ ପାଇଁ ଜରୁରୀ ଥିଲା।",
              "as": "কলেজৰ প্ৰথম বৰ্ষত, শিখৰৰ বহুতো বাধ্যতামূলক ক্লাছ আছে। এই সকলোবোৰ তাৰ কেৰিয়াৰৰ ভেটি বনাবলৈ দৰকাৰী।",
              "gu": "કૉલેજના પ્રથમ વર્ષમાં, શિખરના ઘણા ફરજિયાત વર્ગો છે. તેની કારકિર્દીનો પાયો નાખવા માટે આ તમામ મહત્વપૂર્ણ છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_3///",
              "hi": "C2_1_3 ///",
              "ka": "C2_1_3///",
              "te": "C2_1_3///",
              "or": "C2_1_3///",
              "as": "C2_1_3///",
              "gu": "C2_1_3///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar likes the classes where they learn the basics of cooking and food production. It has always been his dream to learn the techniques used in the youtube videos he used to see.",
              "hi": "शिखर कक्षाओं को पसंद करता है जहां वे खाना पकाने और खाद्य उत्पादन की मूल बातें सीखते हैं। उसके द्वारा देखे गए यूट्यूब वीडियो में उपयोग की जाने वाली तकनीकों को सीखना हमेशा से उसका सपना रहा है ।",
              "ka": "ಶಿಖರ್ ಆಡುಗೆ ಮತ್ತು ಆಹಾರ ಉತ್ಪಾದನೆಯ ಮೂಲಭೂತ ಅಂಶಗಳನ್ನು ಕಲಿಯುವ ತರಗತಿಗಳನ್ನು ಇಷ್ಟಪಡುತ್ತಾನೆ. ತಾನು ನೋಡುತ್ತಿದ್ದ ಯೂಟ್ಯೂಬ್ ವೀಡಿಯೋಗಳಲ್ಲಿ ಬಳಸುವ ತಂತ್ರಗಳನ್ನು ಕಲಿಯುವುದು ಅವನ ಕನಸಾಗಿತ್ತು",
              "te": "శిఖర్ వంట మరియు ఆహార ఉత్పత్తికి సంబంధించిన ప్రాథమిక అంశాలను నేర్చుకునే తరగతులను ఇష్టపడతారు. తను చూసే యూట్యూబ్ వీడియోలలో ఉపయోగించే టెక్నిక్‌లను నేర్చుకోవడం అతని కల.",
              "or": "ଶିଖର କୁ କ୍ଳାସ ଗୁଡିକ ଭଲ ଲାଗିଲା, ଯେଉଁଠାରେ ସେମାନେ ରୋଷେଇ ଓ ଖାଦ୍ୟ \n ଉତ୍ପାଦନର ମୁଳକଥା କୁ ଶିଖିଲେ। ୟୁ ଟ୍ୟୁବ ରେ ସେ ଦେଖୁଥିବା ଭିଡିଓରେ କରାଯାଉଥିବା କୌଶଳ ଗୁଡିକୁ ଶିଖିବା ତାର ସ୍ଵପ୍ନା ଥିଲା।",
              "as": "শিখৰে সিঁহতে ৰন্ধন আৰু খাদ্য বনোৱাৰ সাধাৰণ বিষয়বোৰ শিকা ক্লাছবোৰ ভাল পায়। সি দেখা ইউটিউব ভিডিঅ'বোৰত ব্যৱহৃত কৌশলবোৰ শিকাটো সদায় তাৰ সপোন আছিল।",
              "gu": "શિખરને તે વર્ગો ગમે છે જ્યાં તેઓ રસોઈ અને ખાદ્ય ઉત્પાદનની મૂળભૂત બાબતો શીખે છે. તે જે યુટ્યુબ વિડીયો જોતો હતો તેમાં ઉપયોગમાં લેવાતી ટેક્નિક શીખવાનું તેનું હંમેશા સપનું રહ્યું છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_4///",
              "hi": "C2_1_4 /////",
              "ka": "C2_1_4///",
              "te": "C2_1_4///",
              "or": "C2_1_4///",
              "as": "C2_1_4///",
              "gu": "C2_1_4///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "But there are also some classes that he doesn’t enjoy, like housekeeping and business communication. He likes interacting with people but gets nervous when he is assessed for it.",
              "hi": "लेकिन कुछ विषय जैसे हाउसकीपिंग और व्यापार संचार भी हैं जिनमें उसे आनंद नहीं आता। वह लोगों के साथ बातचीत करना पसंद करता है लेकिन उसको घबराहट होती है जब इसके लिए उसका मूल्यांकन किया जाता है।",
              "ka": "ಆದರೆ ಮನೆಗೆಲಸ ಮತ್ತು ವ್ಯವಹಾರ ಸಂವಹನದಂತಹ ಕೆಲವು ತರಗತಿಗಳನ್ನು ಅವನು ಆನಂದಿಸುವುದಿಲ್ಲ. ಅವನು ಜನರೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸಲು ಇಷ್ಟಪಡುತ್ತಾನೆ ಆದರೆ ಅವನನ್ನು ಅದಕ್ಕಾಗಿ ನಿರ್ಣಯಿಸುವ ಸಮಯದಲ್ಲಿ ಅವನು ಆತಂಕಕ್ಕೊಳಗಾಗುತ್ತಾನೆ.",
              "te": "కానీ అతనికి హౌస్ కీపింగ్ మరియు బిజినెస్ కమ్యూనికేషన్ వంటి కొన్ని తరగతులు కూడా నచ్చలేదు. అతను వ్యక్తులతో సంభాషించడాన్ని ఇష్టపడతాడు, కానీ దాని గురించి అంచనా వేసినప్పుడు అతను భయపడతాడు.",
              "or": "କିନ୍ତୁ ସେଠି ଏମିତି କିଛି କ୍ଳାସ ଥିଲା ଯାହାକୁ ସେ ବିଲକୁଲ ଉପଭୋଗ କରୁନଥିଲା।\n ଯେମିତିକି ଘରର ଯତ୍ନ ଓ ବ୍ୟବସାୟ ଆଦାନପ୍ରଦାନ । ସେ ଲୋକମାନଙ୍କ ସହ କଥା \n ହେବାକୁ ପସନ୍ଦ କରୁଥିଲା କିନ୍ତୁ ନିଜ ପାଳି ପଡିଲେ ସେ ଡରୁଥିଲା ।",
              "as": "কিন্তু কিছুমান ক্লাছ আছে যিবোৰ সি ভাল নাপায়, যেনে হাউচকিপিং আৰু ব্যৱসায়িক যোগাযোগ। সি মানুহৰ লগত কথা পাতি ভাল পায় কিন্তু তাক ইয়াৰ বাবে মূল্যায়ন কৰিলে সি বিচলিত হয়।",
              "gu": "પરંતુ કેટલાક વર્ગો એવા પણ છે કે જે તેને પસંદ નથી, જેમ કે હાઉસકીપિંગ અને ધંધામાટેના. તે લોકો સાથે વાતચીત કરવાનું પસંદ કરે છે પરંતુ જ્યારે તેનું મૂલ્યાંકન કરવામાં આવે છે ત્યારે તે નિરાશ થઈ જાય છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_1",
              "hi": "C2_1_1",
              "ka": "C2_1_1",
              "te": "C2_1_1",
              "or": "C2_1_1",
              "as": "C2_1_1",
              "gu": "C2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "One of his favourite subjects is nutrition because of how they apply science to understand food production and the human body's needs.",
              "hi": "पोषण उसके पसंदीदा विषयों में से एक है क्योंकि उसमें बताया जाता है कि खाद्य उत्पादन और मानव शरीर की जरूरतों को समझने के लिए विज्ञान का कैसे उपयोग करते हैं।",
              "ka": "ಆಹಾರ ಉತ್ಪಾದನೆ ಮತ್ತು ಮಾನವ ದೇಹದ ಅಗತ್ಯಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ವಿಜ್ಞಾನವನ್ನು ಹೇಗೆ ಅನ್ವಯಿಸುತ್ತಾರೆ ಎನ್ನುವ ಕಾರಣದಿಂದಾಗಿ ಅವನ ನೆಚ್ಚಿನ ವಿಷಯವೆಂದರೆ ಪೌಷ್ಟಿಕಾಂಶ.",
              "te": "ఆహారోత్పత్తి మరియు మానవ శరీర అవసరాలను అర్థం చేసుకోవడానికి వారు సైన్స్ ని ఎలా అన్వయిస్తారు అనే దాని వల్ల పోషకాహారం అతనికి ఇష్టమైన విషయాలలో ఒకటి.",
              "or": "ନ୍ୟୁଟ୍ରିସନ ତାର ପ୍ରିୟା ବିଷୟ ଥିଲା କାରଣ ଥିଲା ସେମାନେ କିପରି ବିଜ୍ଞାନ କୁ \n ଖାଦ୍ୟ ଉତ୍ପାଦନ ଓ ମଣିଷର ଶାରୀରିକ ଆବଶ୍ୟକତା ରେ ପ୍ରୟୋଗ କରୁଥିଲେ।",
              "as": "তাৰ ভাল লগা বিষয়বোৰৰ ভিতৰত এটা হৈছে পুষ্টি কাৰণ সেইবোৰে খাদ্য বনোৱা আৰু মানুহৰ শৰীৰৰ প্ৰয়োজনীয়তা বুজিবলৈ বিজ্ঞান কেনেদৰে প্ৰয়োগ কৰে।",
              "gu": "તેમના પ્રિય વિષયોમાંનો એક પોષણ છે કારણ કે તેઓ ખોરાકના ઉત્પાદન અને માનવ શરીરની જરૂરિયાતોને સમજવા માટે વિજ્ઞાનને કેવી રીતે લાગુ કરે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_2_1",
              "hi": "C2_2_1",
              "ka": "C2_2_1",
              "te": "C2_2_1",
              "or": "C2_2_1",
              "as": "C2_2_1",
              "gu": "C2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Although it is a basic introductory course, he finds out that an entire department is dedicated to understanding nutrition.",
              "hi": "हालांकि यह एक बुनियादी प्रारंभिक कोर्स है, लेकिन उसे पता चलता है कि एक संपूर्ण विभाग पोषण को समझने के लिए समर्पित है।",
              "ka": "ಇದು ಮೂಲಭೂತ ಪರಿಚಯಾತ್ಮಕ ಕೋರ್ಸ್ ಆಗಿದ್ದರೂ ಕೂಡ, ಇಡೀ ವಿಭಾಗವು ಪೌಷ್ಟಿಕಾಂಶವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಮೀಸಲಾಗಿರುತ್ತದೆ ಎಂದು ಅವನು ಕಂಡುಕೊಂಡನು.",
              "te": "ఇది ప్రాథమిక పరిచయ కోర్సు అయినప్పటికీ, పోషకాహారాన్ని అర్థం చేసుకోవడానికి ఒక విభాగం మొత్తం అంకితం చేయబడిందని అతను కనుగొన్నాడు.",
              "or": "ଯଦିଓ ଏହା ଏକ ପରିଚାୟାତ୍ମକ ପାଠ୍ୟକ୍ରମ ଥିଲା, ସେ ଦେଖିଲ ଯେ କିପରି ଗୋଟିଏ ପୂର୍ଣ୍ଣ ବିଭାଗ ପୋଷଣତା କୁ ବୁଝିବା ପାଇଁ କାମ କରୁଛି।",
              "as": "যদিও এইটো এটা সাধাৰণ পৰিচয়মূলক পাঠ্যক্ৰম, সি গম পায় যে এটা গোটেই বিভাগ পুষ্টি বুজাৰ বাবে আছে।",
              "gu": "જો કે તે એક મૂળભૂત પ્રારંભિક અભ્યાસક્રમ છે, તેમ છતાં તેને જાણવા મળ્યું કે આખો વિભાગ પોષણને સમજવા માટે સમર્પિત છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_3_1",
              "hi": "C2_3_1",
              "ka": "C2_3_1",
              "te": "C2_3_1",
              "or": "C2_3_1",
              "as": "C2_3_1",
              "gu": "C2_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He meets one of his senior, Rupali, who studies in that department. They usually meet each other in the library every other day during the breaks.",
              "hi": "वह अपनी वरिष्ठ छात्रा, रुपाली से मिलता है, जो उस विभाग में पढ़ती है। वे आमतौर पर हर दूसरे दिन ब्रेक के दौरान लाइब्रेरी में मिलते हैं।",
              "ka": "ಆ ವಿಭಾಗದಲ್ಲಿ ಓದುತ್ತಿರುವ ತನ್ನ ಹಿರಿಯರೊಬ್ಬರಾದ ರೂಪಾಲಿಯನ್ನು ಭೇಟಿಯಾಗುತ್ತಾನೆ. ಅವರು ಸಾಮಾನ್ಯವಾಗಿ ವಿರಾಮದ ಸಮಯದಲ್ಲಿ ಪ್ರತಿದಿನ ಲೈಬ್ರರಿಯಲ್ಲಿ ಪರಸ್ಪರ ಭೇಟಿಯಾಗುತ್ತಾರೆ.",
              "te": "అతను ఆ విభాగంలో చదువుతున్న తన సీనియర్లలో ఒకరైన రూపాలిని కలుస్తాడు. వారు సాధారణంగా ప్రతి రోజు విరామ సమయంలో లైబ్రరీలో ఒకరినొకరు కలుసుకుంటారు.",
              "or": "ସେ ରୂପାଲୀ ବୋଲି ନିଜର ସିନିୟର ସହିତ ପରିଚିତ ହେଲା,ଯିଏ କି ସେହି ବିଭାଗ ରେ ପାଠ ପଢୁଥିଲା। ଅବକାଶ ସମୟରେ ସେମାନେ ପାଠାଗାର ରେ ପରସ୍ପର କୁ ସାକ୍ଷାତ କରୁଥିଲେ।",
              "as": "সি সেই বিভাগত পঢ়া, তাৰ এগৰাকী জ্যেষ্ঠ, ৰূপালীক লগ পায়। বিৰতিৰ সময়ত সিঁহতে সাধাৰণতে প্ৰতিদিনে পুথিভঁৰালত ইজনে সিজনক লগ পায়।",
              "gu": "તે તેની એક ઉપરી કક્ષાની રૂપાલીને મળે છે, જે તે વિભાગમાં અભ્યાસ કરે છે. તેઓ સામાન્ય રીતે રિસેસ દરમિયાન દર બીજા દિવસે પુસ્તકાલયમાં એકબીજાને મળે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_3_2",
              "hi": "C2_3_2",
              "ka": "C2_3_2",
              "te": "C2_3_2",
              "or": "C2_3_2",
              "as": "C2_3_2",
              "gu": "C2_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He asks her if she will help him understand more about nutrition and the course she is doing. She readily agrees. They decide to meet in the canteen on the following day during the lunch break.",
              "hi": "वह रुपाली से पूछता है कि क्या वह उसे पोषण और उसके द्वारा किये जा रहे कोर्स के बारे में अधिक समझने में मदद करेगी। वह सहमत हो जाती है। वे अगले दिन लंच ब्रेक के दौरान कैंटीन में मिलने का निर्णय लेते हैं।",
              "ka": "ಪೌಷ್ಠಿಕಾಂಶ ಮತ್ತು ಅವಳು ಮಾಡುತ್ತಿರುವ ಕೋರ್ಸ್ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತೀರಾ ಎಂದು ಅವನು ಅವಳನ್ನು ಕೇಳುತ್ತಾನೆ. ಅವಳು ತಕ್ಷಣ ಒಪ್ಪುತ್ತಾಳೆ. ಮರುದಿನ ಊಟದ ವಿರಾಮದ ಸಮಯದಲ್ಲಿ ಅವರು ಕ್ಯಾಂಟೀನ್‌ನಲ್ಲಿ ಭೇಟಿಯಾಗಲು ನಿರ್ಧರಿಸುತ್ತಾರೆ.",
              "te": "పోషకాహారం మరియు ఆమె చేస్తున్న కోర్సు గురించి మరింత అర్థం చేసుకోవడంలో ఆమెకు సహాయం చేస్తారా అని అతను ఆమెను అడుగుతాడు. ఆమె వెంటనే అంగీకరిస్తుంది. మరుసటి రోజు భోజన విరామ సమయంలో క్యాంటీన్లో కలవాలని నిర్ణయించుకున్నారు.",
              "or": "ସେ ତାକୁ ନ୍ୟୁଟ୍ରିସନ ଓ ନିଜେ ପଢୁଥିବା ପାଠ ବିଷୟରେ ଅଧିକ ବୁଝାଇବାକୁ କହୁଥିଲା। ସେ ରାଜି ହେଇଯାଉଥିଲା। ଭୋଜନ ଅବକାଶ ରେ ସେମାନେ କ୍ୟାଂଟିନ ରେ ଦେଖା ହବାକୁ ନିଷ୍ପତ୍ତି ନେଲେ।",
              "as": "সি তাই তাক পুষ্টি আৰু তাই কৰি থকা পাঠ্যক্ৰমৰ বিষয়ে অধিক বুজাত সহায় কৰিব নেকি সুধিছিল। তাই সহজে মান্তি হয়। সিঁহতে পিছদিনা দুপৰীয়াৰ আহাৰৰ বিৰতিৰ সময়ত কেণ্টিনত লগ হোৱাৰ সিদ্ধান্ত লয়।",
              "gu": "તે તેણીને પૂછે છે કે શું તેણી તેને પોષણ અને તે જે અભ્યાસક્રમ કરી રહી છે તે વિશે વધુ સમજવામાં મદદ કરશે. તે સહેલાઈથી સંમત થાય છે. તેઓ બીજા દિવસે બપોરના જમવાના સમય દરમિયાન કેન્ટીનમાં મળવાનું નક્કી કરે છે."
            }
          }
        ]
      },
      "scene22": {
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_3",
              "hi": "C3_1_3",
              "ka": "C3_1_3",
              "te": "C3_1_3",
              "or": "C3_1_3",
              "as": "C3_1_3",
              "gu": "C3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "That night Shikhar goes back to his hostel and thinks about his conversation with Rupali.",
              "hi": "उस रात शिखर वापस अपने छात्रावास में जाता है और रुपाली के साथ हुई बातचीत के बारे में सोचता है।",
              "ka": "ಆ ರಾತ್ರಿ ಶಿಖರ್ ತನ್ನ ಹಾಸ್ಟೆಲ್‌ಗೆ ಹಿಂತಿರುಗಿ ರೂಪಾಲಿಯೊಂದಿಗೆ ನಡೆದ ತನ್ನ ಸಂಭಾಷಣೆಯ ಬಗ್ಗೆ ಯೋಚಿಸುತ್ತಾನೆ.",
              "te": "ఆ రాత్రి శిఖర్ తన హాస్టల్‌కి తిరిగి వెళ్లి రూపాలితో తన సంభాషణ గురించి ఆలోచిస్తాడు.",
              "or": "ସେହି ରାତି ରେ ଶିଖର ନିଜ ହଷ୍ଟେଲ କୁ ଗଲା ଓ ରୂପାଲୀ ସହ ହୋଇଥିବା କଥା କୁ ଭାବିବାକୁ ଲାଗିଲା।",
              "as": "সেই ৰাতি শিখৰ তাৰ হোষ্টেললৈ ঘূৰি যায় আৰু ৰূপালীৰ সৈতে তাৰ কথা-বতৰাৰ বিষয়ে ভাৱে।",
              "gu": "તે રાત્રે શિખર તેની હોસ્ટેલમાં પાછો જાય છે અને રૂપાલી સાથેની તેની વાતચીત વિશે વિચારે છે."
            }
          }
        ],
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_1",
              "hi": "C3_1_1",
              "ka": "C3_1_1",
              "te": "C3_1_1",
              "or": "C3_1_1",
              "as": "C3_1_1",
              "gu": "C3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar makes a list of questions he wants to ask her, but they don’t have much time because the break gets over within half an hour.",
              "hi": "शिखर उन प्रश्नों की एक सूची बनाता है जो वह उससे पूछना चाहता है, लेकिन उनके पास अधिक समय नहीं है क्योंकि ब्रेक आधे घंटे में समाप्त हो जाता है।",
              "ka": "ಶಿಖರ್ ತಾನು ಅವಳನ್ನು ಕೇಳಲು ಬಯಸುವ ಪ್ರಶ್ನೆಗಳ ಪಟ್ಟಿಯನ್ನು ಮಾಡುತ್ತಾನೆ, ಆದರೆ ಅವರಿಗೆ ಹೆಚ್ಚು ಸಮಯವಿಲ್ಲ ಏಕೆಂದರೆ ವಿರಾಮವು ಅರ್ಧ ಗಂಟೆಯೊಳಗೆ ಮುಗಿಯುತ್ತದೆ.",
              "te": "శిఖర్ ఆమెను అడగాలనుకునే ప్రశ్నల జాబితాను తయారు చేస్తాడు, కానీ వారికి ఎక్కువ సమయం లేదు ఎందుకంటే విరామం అరగంటలోనే ముగిస్తుంది.",
              "or": "ଶିଖର ପଚାରିବାକୁ ଥିବା ପ୍ରଶ୍ନ ଗୁଡିକର ଏକ ସୂଚୀ ତିଆରି କରିବାକୁ ଲାଗିଲା, କିନ୍ତୁ ସେମାନଙ୍କ ପକ୍ଷେ ବେଶୀ ସମୟ ନଥିଲା ଯେହେତୁ ଭୋଜନ ଅବକାଶ ଅଧ ଘଣ୍ଟା ମଧ୍ୟରେ ଶେଷ ହୋଇଯାଉଥିଲା।",
              "as": "শিখৰে তাইক সুধিব বিচৰা প্ৰশ্নবোৰৰ এখন তালিকা বনায় , কিন্তু সিহঁতৰ ওচৰত বেছি সময় নাই কাৰণ আধা ঘণ্টাৰ ভিতৰত বিৰতি শেষ হয়।",
              "gu": "શિખર તેણીને પૂછવા માંગે છે તેવા પ્રશ્નોની યાદી બનાવે છે, પરંતુ તેની પાસે વધુ સમય નથી કારણ કે રિસેસ અડધા કલાકમાં સમાપ્ત થઈ જાય છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_2",
              "hi": "C3_1_2",
              "ka": "C3_1_2",
              "te": "C3_1_2",
              "or": "C3_1_2",
              "as": "C3_1_2",
              "gu": "C3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Help him choose any two questions to ask.",
              "hi": "उसे पूछने के लिए किन्ही दो प्रश्नों को चुनने में सहायता करें।",
              "ka": "ಯಾವುದೇ ಎರಡು ಪ್ರಶ್ನೆಗಳನ್ನು ಕೇಳುವ ಸಲುವಾಗಿ ಆಯ್ಕೆ ಮಾಡಲು ಅವನಿಗೆ ಸಹಾಯ ಮಾಡಿ.",
              "te": "అడగడానికి ఏవైనా రెండు ప్రశ్నలను ఎంచుకోవడంలో అతనికి సహాయపడండి.",
              "or": "କୌଣସି ଦୁଇଟି ପ୍ରଶ୍ନ ପଚାରିବାରେ ସାହାଯ୍ୟ କର।",
              "as": "সুধিবলৈ যিকোনো দুটা প্ৰশ্ন বছাত তাক সহায় কৰা।",
              "gu": "પૂછવા માટે કોઈપણ બે પ્રશ્નો પસંદ કરવામાં તેને મદદ કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "What are the main job opportunities I can get after doing this course?",
              "hi": "इस कोर्स को करने के बाद मैं नौकरी के कौन से मुख्य अवसर प्राप्त कर सकता हूं?",
              "ka": "ಈ ಕೋರ್ಸ್ ಮಾಡಿದ ನಂತರ ನಾನು ಪಡೆಯಬಹುದಾದ ಮುಖ್ಯ ಉದ್ಯೋಗಾವಕಾಶಗಳು ಯಾವುವು?",
              "te": "ఈ కోర్సు చేసిన తర్వాత నాకు లభించే ప్రధాన ఉద్యోగ అవకాశాలు ఏమిటి?",
              "or": "ଏହି ପାଠ୍ୟକ୍ରମ ପରେ କେଉଁ ଭଳି ଚାକିରି ମୁଁ ପାଇପାରିବି?",
              "as": "এই পাঠ্যক্ৰমটো কৰাৰ পিছত মই কি মুখ্য চাকৰিৰ সুবিধা পাম?",
              "gu": "આ કોર્સ કર્યા પછી મને નોકરીની મુખ્ય તકો શું મળી શકે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "What are the skills necessary for this program?",
              "hi": "इस कार्यक्रम के लिए कौन सी स्किल/ कौशल आवश्यक हैं ?",
              "ka": "ಈ ಕಾರ್ಯಕ್ರಮಕ್ಕೆ ಅಗತ್ಯವಿರುವ ಕೌಶಲ್ಯಗಳು ಯಾವುವು?",
              "te": "ఈ కార్యక్రమానికి అవసరమైన నైపుణ్యాలు ఏమిటి?",
              "or": "ଏହି ପାଠ ପାଇଁ କେଉଁଭଳି କୌଶଳ ର ଅବୟକତା ରହିଛି?",
              "as": "এই কাৰ্যসূচীৰ বাবে কি দক্ষতা লাগে?",
              "gu": "આ પ્રોગ્રામ માટે જરૂરી કૌશલ્યો શું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "What are the different subjects in this specialisation?",
              "hi": "इस विशेषज्ञता में भिन्न - भिन्न विषय क्या हैं?",
              "ka": "ಈ ಪ್ರಾವೀಣ್ಯತೆಯಲ್ಲಿ ಇರುವ  ವಿವಿಧ ವಿಷಯಗಳು ಯಾವುವು?",
              "te": "ఈ స్పెషలైజేషన్లోని విభిన్న సబ్జెక్టులు ఏమిటి?",
              "or": "ଏହି ରେ କେଉଁ ପ୍ରକାର ର ବିଷୟ ରହିଛି?",
              "as": "এই বিশেষত্বত বিভিন্ন বিষয়বোৰ কি?",
              "gu": "આ વિશેષતામાં વિવિધ વિષયો શું છે?"
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "InformationalInterview",
              "hi": "इन्फोर्मेशनल इंटरव्यू",
              "ka": "ಮಾಹಿತಿ ಸಂದರ್ಶನ",
              "te": "సమాచార ఇంటర్వ్యూ",
              "or": "ସୂଚନାଯୋଗ୍ୟ ଇଣ୍ଟରଭ୍ୟୁ |",
              "as": "তথ্যমূলক সাক্ষাৎকাৰ",
              "gu": "માહિતીપ્રદ મુલાકાત"
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "ಎಮ್‌ಸಿ‌ಕ್ಯೂ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar does an informational interview with Rupali to learn more about a course he was interested to take. Which of the following is TRUE about an informational interview:",
              "hi": "शिखर अपने पसंदीदा कोर्स के बारे में अधिक जानने के लिए रुपाली के साथ एक इन्फोर्मेशनल इंटरव्यू/ सूचनात्मक साक्षात्कार करता है। निम्नलिखित में से कौन सा कथन इन्फोर्मेशनल इंटरव्यू/ सूचनात्मक साक्षात्कार के बारे में सच है:",
              "ka": "ಶಿಖರ್ ತಾನು ತೆಗೆದುಕೊಳ್ಳಲು ಆಸಕ್ತಿಯಿರುವ ಕೋರ್ಸ್ ಕುರಿತು ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ರೂಪಾಲಿಯೊಂದಿಗೆ ಮಾಹಿತಿ ಸಂದರ್ಶನವನ್ನು ಮಾಡುತ್ತಾನೆ. ಮಾಹಿತಿ ಸಂದರ್ಶನದ ಕುರಿತು ಈ ಕೆಳಗಿನವುಗಳಲ್ಲಿ ಯಾವುದು ನಿಜ:",
              "te": "శిఖర్ తనకు ఆసక్తి ఉన్న కోర్సు గురించి మరింత తెలుసుకోవడానికి రూపాలితో సమాచార ఇంటర్వ్యూ చేస్తాడు. సమాచార ఇంటర్వ్యూకి సంబంధించి కింది వాటిలో ఏది నిజం:",
              "or": "ଶିଖର ରୂପାଲୀ ସହ ନିଜର ରୁଚି ଅନୁଯାଇ ନେବାକୁ ଥିବା ବିଷୟ ବାବଦରେ ବୁଝିବାକୁ\n  ଏକ ସୂଚନାତ୍ମକ ସାକ୍ଷାତକାର କରେ ।ନିମ୍ନସ୍ଥ କେଉଁଟି ସୂଚନାତ୍ମକ ସାକ୍ଷାତକାର ପାଇଁ ପ୍ରଯୁଜ୍ୟ?",
              "as": "শিখৰে ৰূপালীৰ সৈতে এটা তথ্যমূলক সাক্ষাৎকাৰ কৰে যাতে সি ল'ব বিচৰা এটা পাঠ্যক্ৰমৰ বিষয়ে অধিক জানিব পাৰে। তথ্যমূলক সাক্ষাৎকাৰ এটাৰ বিষয়ে তলৰ কোনটো সঁচা:",
              "gu": "શિખર રૂપાલી સાથે એક માહિતીપ્રદ ઈન્ટરવ્યુ કરે છે અને તેને જે કોર્સમાં રસ હતો તેના વિશે વધુ જાણવા માટે. માહિતીપ્રદ ઇન્ટરવ્યુ વિશે નીચેનામાંથી કયું સાચું છે:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "They should be done only when you are looking for a job",
              "hi": "उन्हे केवल तब किया जाना चाहिए जब आप नौकरी ढूंढ रहे हों",
              "ka": "ನೀವು ಕೆಲಸ ಹುಡುಕುತ್ತಿರುವಾಗ ಮಾತ್ರವೇ ಅವುಗಳನ್ನು ಮಾಡಬೇಕು",
              "te": "మీరు ఉద్యోగం కోసం చూస్తున్నప్పుడు మాత్రమే వాటిని చేయాలి",
              "or": "ଏଗୁଡିକ ସେତେବେଳେ କରାଯିବ ଉଚିତ ଯେତେବେଳେ ତୁମେ ଚାକିରି ଖୋଜିବ",
              "as": "সেইবোৰ কেৱল তুমি চাকৰি বিচাৰি থকাৰ সময়তহে কৰিব লাগে",
              "gu": "જ્યારે તમે નોકરી શોધી રહ્યા હોવ ત્યારે જ તે કરવું જોઈએ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "They are formal conversations with senior professionals",
              "hi": "वे वरिष्ठ प्रोफेशनल/ व्यवसायियों के साथ औपचारिक बातचीत हैं",
              "ka": "ಅವು ಹಿರಿಯ ವೃತ್ತಿಪರರೊಂದಿಗಿನ ಔಪಚಾರಿಕ ಸಂಭಾಷಣೆಗಳಾಗಿವೆ",
              "te": "అవి సీనియర్ నిపుణులతో అధికారిక సంభాషణలు",
              "or": "ଉପରିସ୍ଥ ପେଷାଦାରଙ୍କ ସହ ଏହା ଏକ ସାଧାରଣ କଥୋପକଥନ।",
              "as": "সেইবোৰ জ্যেষ্ঠ পেছাদাৰীসকলৰ সৈতে আনুষ্ঠানিক কথা-বাৰ্তা",
              "gu": "તેઓ ઉપરી વ્યાવસાયિકો સાથે ઔપચારિક વાતચીત છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "These interviews help you make informed decisions",
              "hi": "ये साक्षात्कार आपको विचारपूर्ण निर्णय लेने में सहायता करते हैं",
              "ka": "ತಿಳುವಳಿಕೆಯುಳ್ಳ ನಿರ್ಧಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಈ ಸಂದರ್ಶನಗಳು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತವೆ",
              "te": "ఈ ఇంటర్వ్యూలు మీకు సమాచారంతో నిర్ణయాలు తీసుకోవడంలో సహాయపడతాయి",
              "or": "ସୂଚନାତ୍ମକ ନିଷ୍ପତ୍ତି ନେବାରେ ଏହା ସାହାଯ୍ୟ କରେ।",
              "as": "এই সাক্ষাৎকাৰবোৰে তোমাক জ্ঞাত সিদ্ধান্ত লোৱাত সহায় কৰে",
              "gu": "આ ઇન્ટરવ્યુ તમને માહિતગાર નિર્ણયો લેવામાં મદદ કરે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "These interviews are helpful only to choose your college",
              "hi": "ये साक्षात्कार केवल आपके कॉलेज के चुनाव में सहायक होते हैं",
              "ka": "ನಿಮ್ಮ ಕಾಲೇಜನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಮಾತ್ರವೇ ಈ ಸಂದರ್ಶನಗಳು ಸಹಾಯಕವಾಗಿರುತ್ತವೆ.",
              "te": "ఈ ఇంటర్వ్యూలు మీ కళాశాలని ఎంచుకోవడానికి మాత్రమే ఉపయోగపడతాయి",
              "or": "ନିଜର କଲେଜ ଚୟନ କରିବା ବେଳେ ଏହା ସାହାଯ୍ୟ କରେ",
              "as": "এই সাক্ষাৎকাৰবোৰে কেৱল তোমাৰ কলেজ বাছনিত সহায় কৰে",
              "gu": "આ ઇન્ટરવ્યુ ફક્ત તમારી કોલેજ પસંદ કરવા માટે મદદરૂપ છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Think again! These are helpful in many ways",
              "hi": "फिर से विचार करो! ये कई तरीकों से सहायक हैं",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಇವು ಹಲವು ರೀತಿಯಲ್ಲಿ ಸಹಾಯಕವಾಗಿರುತ್ತವೆ",
              "te": "మళ్లీ ఆలోచించండి! ఇవి చాలా రకాలుగా ఉపకరిస్తాయి",
              "or": "ପୁନର୍ବାର ଭାବ! ଏଗୁଡିକ ବହୁଳ ଭାବେ ଉପାଦେୟକାରୀ",
              "as": "আকৌ ভাবা! এইবোৰ বিভিন্ন ধৰণে সহায়ক",
              "gu": "ફરીથી વિચારો! આ ઘણી રીતે મદદરૂપ છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Think again! Anyone who has knowledge about your interest area can be",
              "hi": "फिर से विचार करो! कोई भी जिसको आपके पसंदीदा काम की जानकारी है",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ನಿಮ್ಮ ಆಸಕ್ತಿಯ ವಿಷಯದ ಬಗ್ಗೆ ಜ್ಞಾನವನ್ನು ಹೊಂದಿರುವ ಯಾರಾದರೂ ಆಗಿರಬಹುದು",
              "te": "మళ్లీ ఆలోచించండి! మీకు ఆసక్తి ఉన్న అంశం గురించి అవగాహన ఉన్న ఎవరైనా కావచ్చు",
              "or": "ପୁନର୍ବାର ଭାବ! ତୁମେ ରୁଚି ରଖୁଥିବା କ୍ଷେତ୍ର ରେ କେହି ବି ରହିପାରିବେ",
              "as": "আকৌ ভাবা ! তোমাৰ ইচ্ছাৰ ক্ষেত্ৰৰ বিষয়ে জনা যিকোনো মানুহ হ'ব পাৰে",
              "gu": "ફરીથી વિચારો! તમારી રુચિના ક્ષેત્ર વિશે જાણકારી ધરાવનાર કોઈપણ હોઈ શકે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಉತ್ತಮ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ବହୁତ ବଢିଆ!",
              "as": "ভাল কাম",
              "gu": "સરસ કામ!"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Think again! These are helpful in many ways",
              "hi": "फिर से विचार करो! ये कई तरीकों से सहायक हैं",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಇವು ಹಲವು ರೀತಿಯಲ್ಲಿ ಸಹಾಯಕವಾಗಿರುತ್ತವೆ",
              "te": "మళ్లీ ఆలోచించండి! ఇవి చాలా రకాలుగా ఉపయోగపడతాయి",
              "or": "ପୁନର୍ବାର ଭାବ! ଏଗୁଡିକ ବହୁଳ ଭାବେ ଉପାଦେୟକାରୀ",
              "as": "আকৌ ভাবা! এইবোৰ বিভিন্ন ধৰণে সহায়ক",
              "gu": "ફરીથી વિચારો! આ ઘણી રીતે મદદરૂપ છે"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_2_2////",
              "hi": "C3_2_2 //////",
              "ka": "C3_2_2////",
              "te": "C3_2_2////",
              "or": "C3_2_2////",
              "as": "C3_2_2////",
              "gu": "C3_2_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Thank you so much Rupali, nutrition sounds like an exciting subject to study. How easy is it to shift from Hotel Management to nutrition?",
              "hi": "बहुत बहुत धन्यवाद रुपाली, अध्ययन के लिए न्यूट्रीशन/ पोषण एक रोमांचक विषय लगता है। होटल प्रबंधन से पोषण में बदलाव करना कितना सहज है?",
              "ka": "ತುಂಬಾ ಧನ್ಯವಾದಗಳು ರೂಪಾಲಿ, ಪೌಷ್ಟಿಕಾಂಶವು ಅಧ್ಯಯನಕ್ಕೆ ಒಂದು ಉತ್ತೇಜಕ ವಿಷಯವಾಗಿದೆ. ಹೋಟೆಲ್ ನಿರ್ವಹಣೆಯಿಂದ ಪೌಷ್ಟಿಕಾಂಶಕ್ಕೆ ಬದಲಾಯಿಸುವುದು ಎಷ್ಟು ಸುಲಭ?",
              "te": "చాలా ధన్యవాదాలు రూపాలి, పోషకాహారం అధ్యయనం చేయడానికి ఒక ఉత్తేజకరమైన విషయంగా అనిపిస్తుంది. హోటల్ మేనేజ్‌మెంట్ నుండి పోషకాహారానికి మారడం ఎంత సులభం?",
              "or": "ବହୁତ ଧନ୍ୟବାଦ ରୂପାଲୀ, ନ୍ୟୁଟ୍ରିଶନ ଏକ ମଜାଦାର ବିଷୟ ଭଳି ମାନେ ହେଉଛି।\n  ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ରୁ ନ୍ୟୁଟ୍ରିଶନ କୁ ସ୍ଥାନାନ୍ତର କରିବା କେତେ ସହଜ?",
              "as": "তোমাক বহুত ধন্যবাদ ৰূপালী, পুষ্টি পঢ়াৰ বাবে এটা বঢিয়া বিষয় যেন লাগিছে। হোটেল মেনেজমেণ্টৰ পৰা পুষ্টিলৈ সলনি কৰাটো কিমান সহজ?",
              "gu": "તમારો ખૂબ ખૂબ આભાર રૂપાલી, પોષણ અભ્યાસ માટે એક આકર્ષક વિષય જેવું લાગે છે. હોટેલ મેનેજમેન્ટમાંથી પોષણમાં શિફ્ટ થવું કેટલું સરળ છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Ah, that won’t be difficult as long as you did all your courses in the first year, and your grades are good.",
              "hi": "आह, यह तब तक मुश्किल नहीं होगा जब तक कि आपने अपने सभी कोर्स जो पहले वर्ष में किए और उसमें आपके ग्रेड अच्छे हैं।",
              "ka": "ಆಹ್, ನೀವು ಮೊದಲ ವರ್ಷದಲ್ಲಿ ನಿಮ್ಮ ಎಲ್ಲಾ ಕೋರ್ಸ್‌ಗಳನ್ನು ಮುಗಿಸುವವರೆಗೆ ಹಾಗೂ ನಿಮ್ಮ ಗ್ರೇಡ್‌ಗಳು ಉತ್ತಮವಾಗಿರುವವರೆಗೆ ಅದು ಅಷ್ಟೇನು ಕಷ್ಟವಾಗುವುದಿಲ್ಲ.",
              "te": "ఆహ్, మీరు మొదటి సంవత్సరంలో నీ అన్ని కోర్సులు చేసినంత కాలం మరియు నీ గ్రేడ్లు బాగున్నంత వరకు అది కష్టం కాదు.",
              "or": "ଆଃ , ଯଦି ତୁମେ ପ୍ରଥମ ବର୍ଷରେ ନିଜ ପାଠ୍ୟକ୍ରମକୁ ସାରିଦେଇଛ ଓ ତୁମ ଗ୍ରେଡ ସବୁ ଭଲ ଅଛି\n ତେବେ ଏହା ବିଲକୁଲ ବି କଷ୍ଟଦାୟକ ନୁହେଁ।",
              "as": "আহ, তুমি প্ৰথম বছৰত তোমাৰ সকলো পাঠ্যক্ৰম কৰালৈ সেইটো টান নহ'ব, আৰু তোমাৰ গ্ৰেড ভাল।",
              "gu": "હા, જ્યાં સુધી તે પ્રથમ વર્ષમાં તમારા બધા અભ્યાસક્રમો કર્યા હોય અને તારા ગુણ સારા હોય ત્યાં સુધી તે મુશ્કેલ નહીં હોય."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "So I’ll be able to start with the nutrition course just like that?",
              "hi": "तो मैं बस इस तरह न्यूट्रीशन/ पोषण कोर्स करने में सक्षम हो जाऊंगा?",
              "ka": "ಹಾಗಾಗಿ ನಾನು ಪೌಷ್ಠಿಕಾಂಶ ಕೋರ್ಸ್‌ನೊಂದಿಗೆ ಪ್ರಾರಂಭಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತದೆಯೇ?",
              "te": "అంటే నేను పోషకాహార కోర్సును అలాగే ప్రారంభించగలనా?",
              "or": "ତାହେଲେ କଣ ମୁଁ ନ୍ୟୁଟ୍ରିଶନ ବିଷୟରେ ପଢିବା ଆରମ୍ଭ କରିପ[ଅରିବି?",
              "as": "গতিকে মই পুষ্টি পাঠ্যক্ৰমৰ সৈতে ঠিক তেনেকৈ আৰম্ভ কৰিব পাৰিম?",
              "gu": "તો હું પોષણનો કોર્સ તે જ રીતે શરૂ કરી શકીશ?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "You’ll have to send an application to the university office. I think you will have to take some extra classes to catch up.",
              "hi": "आपको विश्वविद्यालय कार्यालय में एक आवेदन भेजना होगा। मुझे लगता है कि आपको उसे पाने के लिए कुछ अतिरिक्त कक्षाएं लेनी होंगी।",
              "ka": "ನೀವು ವಿಶ್ವವಿದ್ಯಾಲಯದ ಕಚೇರಿಗೆ ಅರ್ಜಿಯನ್ನು ಕಳುಹಿಸಬೇಕಾಗುತ್ತದೆ. ಅದನ್ನು ಕಲಿಯಲು ನೀವು ಕೆಲವು ಹೆಚ್ಚುವರಿ ತರಗತಿಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕಾಗುತ್ತದೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ",
              "te": "నువ్వు విశ్వవిద్యాలయం కార్యాలయానికి దరఖాస్తు పంపవలసి ఉంటుంది. మీరు కొన్ని అదనపు తరగతులు తీసుకోవలసి ఉంటుందని నేను భావిస్తున్నాను.",
              "or": "ତୁମକୁ ମହାବିଦ୍ୟାଳୟର ଅଫିସ ରେ ଏକ ଦରଖାସ୍ତ ଦେବାକୁ ପଡିବ। ମୁଁ ଭାବୁଛି ଠିକ ରେ \n ଜାଣିବା ପାଇଁ ତୁମକୁ କିଛି ଅତିରିକ୍ତ କ୍ଳାସ ନେବାକୁ ପଡିବ।",
              "as": "তুমি বিশ্ববিদ্যালয়ৰ অফিচলৈ এখন আবেদন পঠিয়াব লাগিব। মই ভাবো তুমি ধৰিবলৈ কিছু অতিৰিক্ত ক্লাছ ল'ব লাগিব।",
              "gu": "તારે યુનિવર્સિટી ઑફિસમાં અરજી મોકલવી પડશે. મને લાગે છે કે તારે મળવા માટે કેટલાક વધારાના વર્ગો લેવા પડશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_4",
              "hi": "C3_1_4",
              "ka": "C3_1_4",
              "te": "C3_1_4",
              "or": "C3_1_4",
              "as": "C3_1_4",
              "gu": "C3_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He feels that the field of nutrition and dietetics has a lot of scope. This way, he could solve people’s problems and get a chance to work with food.",
              "hi": "वह अनुभव करता है कि न्यूट्रीशन/ पोषण और आहार विज्ञान के क्षेत्र में बहुत अधिक अवसर है। इस तरह, वह लोगों की समस्याओं को हल कर सकता है और भोजन बनाने के नई विधिओं पर काम करने का मौका प्राप्त कर सकता है।",
              "ka": "ಪೌಷ್ಟಿಕಾಂಶ ಮತ್ತು ಆಹಾರ ಪದ್ಧತಿಯ ಕ್ಷೇತ್ರವು ಸಾಕಷ್ಟು ವ್ಯಾಪ್ತಿಯನ್ನು ಹೊಂದಿದೆ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ. ಈ ರೀತಿಯಾಗಿ, ಅವನು ಜನರ ಸಮಸ್ಯೆಗಳನ್ನು ಪರಿಹರಿಸಬಹುದು ಮತ್ತು ಆಹಾರದೊಂದಿಗೆ ಕೆಲಸ ಮಾಡಲು ಅವಕಾಶವನ್ನು ಪಡೆಯಬಹುದು.",
              "te": "పోషకాహారం మరియు ఆహార శాస్త్రం రంగానికి చాలా అవకాశం ఉందని అతను భావిస్తున్నాడు. ఈ విధంగా, అతను ప్రజల సమస్యలను పరిష్కరించగలడు మరియు ఆహారానికి సంబంధించిన పని చేసే అవకాశాన్ని పొందగలడు.",
              "or": "ନ୍ୟୁଟ୍ରିସନ ଓ ଭୋଜନ ସ୍ରୋତରେ ଭଲ କ୍ଷେତ୍ର ରହିଛି ବୋଲି ସେ ଅନୁଭବ କରେ । \n ଏହିଭଳି ସେ ଲୋକମାନଙ୍କୁ ସାହାଯ୍ୟ କରିପାରିବ ଓ ଖାଦ୍ୟ ସମ୍ବନ୍ଧୀୟ କାର୍ଯ୍ୟ ମଧ୍ୟ କରିପାରିବ।",
              "as": "সি ভাবে যে পুষ্টি আৰু ডায়েটেটিক্সৰ ক্ষেত্ৰখনত বহুত সুযোগ আছে। এনেদৰে, সি মানুহৰ সমস্যা সমাধান কৰিব পাৰে আৰু খাদ্যৰ লগত কাম কৰাৰ সুযোগ পাব পাৰে।",
              "gu": "તેમને લાગે છે કે પોષણ અને આહારશાસ્ત્રના ક્ષેત્રમાં ઘણી તકો છે. આ રીતે, તે લોકોની સમસ્યાઓ હલ કરી શકતો હતો અને ખોરાક સાથે કામ કરવાની તક મેળવી શકતો હતો."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_5",
              "hi": "C3_1_5",
              "ka": "C3_1_5",
              "te": "C3_1_5",
              "or": "C3_1_5",
              "as": "C3_1_5",
              "gu": "C3_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He can also finish his studies in 3 years instead of 4 and can start earning sooner. However, this is a big shift from his original plans.",
              "hi": "वह 4 के बजाय 3 साल में अपनी पढ़ाई भी कर सकता है और जल्द ही कमाई शुरु कर सकता है। हालांकि, यह उसकी प्रारम्भिक योजनाओं से एक बड़ा बदलाव है।",
              "ka": "ಅವನು ತನ್ನ ಅಧ್ಯಯನವನ್ನು 4 ವರ್ಷಗಳ ಬದಲಿಗೆ 3 ವರ್ಷಗಳಲ್ಲಿ ಮುಗಿಸಬಹುದು ಮತ್ತು ಬೇಗ ಗಳಿಸಲು ಪ್ರಾರಂಭಿಸಬಹುದು. ಆದಾಗ್ಯೂ, ಇದು ಅವರ ಮೂಲ ಯೋಜನೆಗಿಂತ ಬಹಳ ದೊಡ್ಡ ಬದಲಾವಣೆಯಾಗಿದೆ.",
              "te": "అతను తన చదువును 4 సంవత్సరాలకు బదులుగా 3 సంవత్సరాలలో ముగించవచ్చు మరియు త్వరగా సంపాదించడం ప్రారంభించవచ్చు. అయితే, ఇది అతని అసలు ప్రణాళికల నుండి పెద్ద మార్పు.",
              "or": "ସେ ଚାରି ବର୍ଷ ନୁହେ ବରଂ ତିନି ବର୍ଷରେ ନିଜ ପାଠ୍ୟକ୍ରମ କୁ ସାରି ଜଲଦି ରୋଜଗାର କରିପାରିବ।\n ଯାହା ବି ହଊ, ଏହା ତାର ଯୋଜନା ରୁ ଢେର ବଡ ସାଧନ।",
              "as": "সি ৪ ৰ সলনি ৩ বছৰত পঢ়া শেষ কৰিব পাৰে আৰু সোনকালে উপাৰ্জন আৰম্ভ কৰিব পাৰে। অৱশ্যে, এইটো তাৰ মূল পৰিকল্পনাৰ পৰা এক ডাঙৰ পৰিৱৰ্তন।",
              "gu": "તે 4 ને બદલે 3 વર્ષમાં પોતાનો અભ્યાસ પણ પૂરો કરી શકે છે અને જલ્દી કમાવાનું શરૂ કરી શકે છે. જો કે, આ તેની મૂળ યોજનાઓથી મોટું પરિવર્તન છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_6",
              "hi": "C3_1_6",
              "ka": "C3_1_6",
              "te": "C3_1_6",
              "or": "C3_1_6",
              "as": "C3_1_6",
              "gu": "C3_1_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "His job options will get limited if he chooses to study nutrition. With the hotel management degree, he’ll have the freedom to do whatever he enjoys but not nutrition.",
              "hi": "यदि वह न्यूट्रीशन/ पोषण का अध्ययन करना चुनता है तो उसके नौकरी के  विकल्प सीमित हो जाएंगे। होटल प्रबंधन की डिग्री के साथ, उसे अपनी इच्छानुसार काम करने की स्वतंत्रता होगी, लेकिन न्यूट्रीशन/पोषण के साथ नहीं।",
              "ka": "ಅವನು ಪೌಷ್ಟಿಕಾಂಶವನ್ನು ಅಧ್ಯಯನ ಮಾಡಲು ಆಯ್ಕೆ ಮಾಡಿದರೆ ಅವನ ಉದ್ಯೋಗ ಆಯ್ಕೆಗಳು ಸೀಮಿತವಾಗುತ್ತವೆ. ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಪದವಿಯೊಂದಿಗೆ, ಅವನು ಇಷ್ಟಪಡುವದನ್ನು ಮಾಡುವ ಸ್ವಾತಂತ್ರ್ಯವನ್ನು ಹೊಂದಿರುತ್ತಾನೆ ಆದರೆ ಪೌಷ್ಟಿಕಾಂಶವನ್ನು ತೆಗೆದುಕೊಳ್ಳುವುದರಿಂದ ಅಲ್ಲ.",
              "te": "అతను పోషకాహారాన్ని ఎంచుకుంటే అతని ఉద్యోగ ఎంపికలు పరిమితంగా ఉంటాయి. హోటల్ మేనేజ్‌మెంట్ డిగ్రీతో, అతనికి నచ్చింది చేసే స్వేచ్ఛను కలిగి ఉంటాడు కానీ పోషకాహారం కాదు.",
              "or": "ଯଦି ସେ ନ୍ୟୁଟ୍ରିଶନକୁ ପଢିବାକୁ ଚୟନ କରିବା ତେବେ ତା ପାଇଁ ଚାକିରି ର ବିକଳ୍ପ ସୀମିତ ହୋଇଯିବ।",
              "as": "যদি সি পুষ্টি পঢ়িব বিচাৰে তেন্তে তাৰ চাকৰিৰ বিকল্প সীমিত হ'ব। হোটেল মেনেজমেণ্ট ডিগ্ৰীৰ সৈতে, সি আনন্দ পোৱাবোৰ কৰাৰ স্বাধীনতা থাকিব কিন্তু পুষ্টিত নাথাকে।",
              "gu": "જો તે પોષણનો અભ્યાસ કરવાનું પસંદ કરશે તો તેની નોકરીના વિકલ્પો મર્યાદિત થઈ જશે. હોટેલ મેનેજમેન્ટની ડિગ્રી સાથે, તેને જે ગમે તે કરવાની સ્વતંત્રતા હશે પરંતુ પોષણ નહીં."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_7_D",
              "hi": "C3_1_7_D",
              "ka": "C3_1_7_D",
              "te": "C3_1_7_D",
              "or": "C3_1_7_D",
              "as": "C3_1_7_D",
              "gu": "C3_1_7_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Shikhar do?",
              "hi": "शिखर को क्या करना चाहिए?",
              "ka": "ಶಿಖರ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "శిఖర్ ఏం చేయాలి?",
              "or": "ଶିଖର କଣ କରିବା ଉଚିତ?",
              "as": "শিখৰে কি কৰিব লাগে?",
              "gu": "શિખરે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Apply for a transfer to the nutrition department",
              "hi": "न्यूट्रीशन/ पोषण विभाग में ट्रांसफर/ स्थानांतरण के लिए आवेदन करे",
              "ka": "ಪೌಷ್ಟಿಕಾಂಶ ಇಲಾಖೆಗೆ ವರ್ಗಾವಣೆಗಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದು",
              "te": "పోషకాహార విభాగానికి బదిలీ కోసం దరఖాస్తు చేసుకోవడం",
              "or": "ନ୍ୟୁଟ୍ରିଶନ ବିଭାଗକୁ ବଦଳିକରଣ ପାଇଁ ଆବେଦନ କରିବା କି?",
              "as": "পুষ্টি বিভাগলৈ সলনিৰ বাবে আবেদন কৰা",
              "gu": "પોષણ વિભાગમાં ટ્રાન્સફર માટે અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Continue with his Hotel Management course",
              "hi": "अपने होटल प्रबंधन कोर्स को जारी रखे",
              "ka": "ಆತನ ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್ ಕೋರ್ಸ್ ಅನ್ನು ಮುಂದುವರೆಸುವುದು",
              "te": "అతని హోటల్ మేనేజ్‌మెంట్ కోర్సును కొనసాగించడం",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ପାଠ୍ୟକ୍ରମ ସହ ଜାରି ରଖିବା?",
              "as": "তাৰ হোটেল মেনেজমেণ্ট পাঠ্যক্ৰম চলাই ৰাখা।",
              "gu": "તેનો હોટેલ મેનેજમેન્ટ કોર્સ ચાલુ રાખો"
            }
          }
        ]
      },
      "scene23": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Study Nutrition",
              "hi": " न्यूट्रीशन/ पोषण अध्ययन",
              "ka": "ಪೌಷ್ಟಿಕಾಂಶದ ಬಗ್ಗೆ ಅಧ್ಯಯನವನ್ನು ಮಾದುವುದು",
              "te": "పౌష్టికాహారాన్ని అధ్యయనం చేయడం",
              "or": "ନ୍ୟୁଟ୍ରିଶନ ହି ପଢ ।",
              "as": "পুষ্টি পঢ়া",
              "gu": "ન્યુટ્રિશન નો આભાસ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar decides to apply for a transfer from hotel management to the nutrition department. He sends in his request for a transfer to the academic office at the university.",
              "hi": "शिखर होटल प्रबंधन से पोषण विभाग में ट्रांसफर/ स्थानांतरण के लिए आवेदन करने का फैसला करता है। वह विश्वविद्यालय में अकादमिक कार्यालय में ट्रांसफर/ स्थानांतरण के लिए निवेदन करता है।",
              "ka": "ಶಿಖರ್ ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್‌ನಿಂದ ಪೌಷ್ಟಿಕಾಂಶ ವಿಭಾಗಕ್ಕೆ ವರ್ಗಾವಣೆಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ವಿಶ್ವವಿದ್ಯಾನಿಲಯದಲ್ಲಿನ ಶೈಕ್ಷಣಿಕ ಕಚೇರಿಗೆ ವರ್ಗಾವಣೆಗಾಗಿ ತನ್ನ ವಿನಂತಿಯನ್ನು ಕಳುಹಿಸುತ್ತಾನೆ.",
              "te": "హోటల్ మేనేజ్‌మెంట్ నుండి పోషకాహార విభాగానికి బదిలీ కోసం దరఖాస్తు చేయాలని శిఖర్ నిర్ణయించుకున్నాడు. అతను విశ్వవిద్యాలయంలోని విద్యా కార్యాలయానికి బదిలీ కోసం తన అభ్యర్థనను పంపాడు.",
              "or": "ଶିଖର ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟରୁ ନିଉଟ୍ରିଶନ ବିଭାଗକୁ ବଦଳିକରଣ ପାଇଁ ଚିନ୍ତା କଲା। ସେ ମହାବିଦ୍ୟାଳୟ ର ସାଇକ୍ଷଣିକ କାର୍ଯ୍ୟଳୟକୁ ବଦଳି ପାଇଁ ଆବେଦନ କଳା।",
              "as": "শিখৰে হোটেল মেনেজমেণ্টৰ পৰা পুষ্টি বিভাগলৈ সলনিৰ বাবে আবেদন কৰাটো থিৰাং কৰিছে। সি বিশ্ববিদ্যালয়ৰ শৈক্ষিক অফিচলৈ সলনিৰ বাবে তাৰ অনুৰোধ পঠিয়ায় ।",
              "gu": "શિખરે હોટલ મેનેજમેન્ટમાંથી પોષણ વિભાગમાં બદલી માટે અરજી કરવાનું નક્કી કર્યું. તે યુનિવર્સિટીમાં શૈક્ષણિક કાર્યાલયમાં બદલી માટે તેમની વિનંતી મોકલે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_1_2",
              "hi": "C4_1_2",
              "ka": "C4_1_2",
              "te": "C4_1_2",
              "or": "C4_1_2",
              "as": "C4_1_2",
              "gu": "C4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The academic office checks his marks for the first year, and since he has scored well, they approve his request.",
              "hi": "अकादमिक कार्यालय उसके प्रथम वर्ष के अंकों की जांच करता है, और चूंकि उसने अच्छे अंक प्राप्त किए हैं, इसलिए वे उसके निवेदन को स्वीकार कर लेते हैं।",
              "ka": "ಶೈಕ್ಷಣಿಕ ಕಛೇರಿಯು ಮೊದಲ ವರ್ಷದಲ್ಲಿ ಅವನು ಗಳಿಸಿದ ಅಂಕಗಳನ್ನು ಪರಿಶೀಲಿಸುತ್ತದೆ, ಮತ್ತು ಅವನು ಉತ್ತಮ ಅಂಕಗಳನ್ನು ಗಳಿಸಿದ್ದರಿಂದ, ಅವರು ಅವನ ವಿನಂತಿಯನ್ನು ಅನುಮೋದಿಸುತ್ತಾರೆ.",
              "te": "అకడమిక్ కార్యాలయం మొదటి సంవత్సరం అతని మార్కులను తనిఖీ చేస్తుంది మరియు అతను బాగా స్కోర్ చేసినందున, వారు అతని అభ్యర్థనను ఆమోదించారు.",
              "or": "ଶିକ୍ଷଣିକ କାର୍ଜ୍ୟାଳୟ ପ୍ରଥମ ବର୍ଷ ପାଇଁ ତାର ମୂଲ୍ୟାଙ୍କନ କଲା, ଏବଂ ଯେହେତୁ ସେ ଭଲ \n ସ୍କୋର କରିଥିଲା ତାର ଆବେଦନ କୁ ସ୍ବୀକୃତି ଦିଆଗଲା।",
              "as": "শৈক্ষিক অফিচে প্ৰথম বছৰৰ বাবে তাৰ নম্বৰ পৰীক্ষা কৰে, আৰু যিহেতু সি ভাল নম্বৰ পাইছে, তেওঁলোকে তাৰ অনুৰোধ ৰাখে।",
              "gu": "શૈક્ષણિક કાર્યાલય પ્રથમ વર્ષ માટે તેના ગુણ તપાસે છે, અને તેણે સારા ગુણ મેળવ્યા હોવાથી, તેઓ તેની વિનંતીને મંજૂર કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_1_3",
              "hi": "C4_1_3",
              "ka": "C4_1_3",
              "te": "C4_1_3",
              "or": "C4_1_3",
              "as": "C4_1_3",
              "gu": "C4_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He enrols himself in the nutrition department, and the teachers ask Shikar to catch up with a few courses he missed in his first year.",
              "hi": "वह स्वयं को न्यूट्रीशन/ पोषण विभाग में नामांकित करता है, और शिक्षक शिखर से अपने प्रथम वर्ष में छूटे हुए कोर्स को पूरा करने के लिए कहते हैं।",
              "ka": "ಅವನು ತನ್ನನ್ನು ಪೌಷ್ಟಿಕಾಂಶ ವಿಭಾಗಕ್ಕೆ ದಾಖಲಿಸಿಕೊಳ್ಳುತ್ತಾನೆ ಮತ್ತು ಶಿಕ್ಷಕರು ಶಿಖರ್‌ಗೆ ತನ್ನ ಮೊದಲ ವರ್ಷದಲ್ಲಿ ತಪ್ಪಿಸಿಕೊಂಡ ಕೆಲವು ಕೋರ್ಸ್‌ಗಳ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಲು ಹೇಳುತ್ತಾರೆ.",
              "te": "అతను న్యూట్రిషన్ డిపార్ట్మెంట్‌ లో తనను తాను నమోదు చేసుకున్నాడు మరియు ఉపాధ్యాయులు శిఖర్ ని తన మొదటి సంవత్సరంలో మిస్ అయిన కొన్ని కోర్సులను చేయమని అడుగుతారు.",
              "or": "ନ୍ୟୁଟ୍ରିସନ ବିଭାଗରେ ସେ ନିଜର ନାମାଙ୍କନ କଲା, ଏବଂ ନିଜର ପ୍ରଥମ ବର୍ଷରେ ହରାଇଥିବା \n ବିଷୟଗୁଡ଼ିକୁ ଆଉଥରେ ପଢିବା ପାଇଁ ତା ଶିକ୍ଷକ ତାକୁ ପ୍ରସ୍ତାବ ଦେଲେ।",
              "as": "সি পুষ্টি বিভাগত নামভৰ্তি কৰে, আৰু শিক্ষকসকলে শিখৰক তাৰ প্ৰথম বছৰত বাদ পৰা কেইটামান পাঠ্যক্ৰম ৰাখিবলৈ কয়।",
              "gu": "તે પોષણ વિભાગમાં પોતાને દાખલ કરે છે, અને શિક્ષકો શિખરને તેના પ્રથમ વર્ષમાં ચૂકી ગયેલા કેટલાક અભ્યાસક્રમો શીખવા માટે કહે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_1",
              "hi": "C4_2_1",
              "ka": "C4_2_1",
              "te": "C4_2_1",
              "or": "C4_2_1",
              "as": "C4_2_1",
              "gu": "C4_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Due to this, he barely gets any time to do anything but attend classes and practicals. He doesn’t have the time to make new friends in the department or participate in extracurricular activities.",
              "hi": "इस कारण, उसके पास कक्षाओं और प्रैक्टिकल में उपस्थित होने के अलावा कुछ भी करने के लिए शायद ही कोई समय मिलता है। उसके पास विभाग में नए मित्र बनाने या कोर्स के अलावा होने वाली गतिविधियों में भाग लेने का समय नहीं है।",
              "ka": "ಈ ಕಾರಣದಿಂದಾಗಿ, ಅವನು ತರಗತಿಗಳು ಮತ್ತು ಪ್ರಾಯೋಗಿಕಗಳಿಗೆ ಹಾಜರಾಗುವುದನ್ನು ಹೊರತುಪಡಿಸಿ ಏನನ್ನೂ ಮಾಡಲು ಯಾವುದೇ ಸಮಯವನ್ನು ಪಡೆಯುವುದಿಲ್ಲ. ವಿಭಾಗದಲ್ಲಿ ಹೊಸ ಸ್ನೇಹಿತರನ್ನು ಮಾಡಿಕೊಳ್ಳಲು ಅಥವಾ ಪಠ್ಯೇತರ ಚಟುವಟಿಕೆಗಳಲ್ಲಿ ಭಾಗವಹಿಸಲು ಅವನಿಗೆ ಸಮಯ ಸಿಗುವುದಿಲ್ಲ.",
              "te": "ఈ కారణంగా, అతను తరగతులు మరియు ప్రాక్టికల్స్ కు హాజరవడం తప్ప మరేమీ చేయలేడు. డిపార్ట్మెంట్‌ లో కొత్త స్నేహితులను సంపాదించడానికి లేదా పాఠ్యేతర కార్యకలాపాలలో పాల్గొనడానికి అతనికి సమయం లేదు.",
              "or": "ଏହି କାରଣରୁ ସେ ଅଲଗା କିଛି କରିବା ପାଇଁ ସେ ସମୟ ପାଏ ନହିଁ କିନ୍ତୁ ନିଜ କ୍ଲାସ \n ଓ ପ୍ରାକ୍ଟିକାଲ ଗୁଡିକରେ ଭାଗ ନିଏ। ତା ପାଖରେ ବିଭାଗରେ ନୂଆ ସାଙ୍ଗ ବନେଇବାରେ \n ଅଥବା ପାଠ ବହିର୍ଭୂତ ଅଲଗା କିଛିରେ ଭାଗ ନେବା ପାଇଁ ସମୟ ପାଏ ନହିଁ।",
              "as": "ইয়াৰ বাবে, সি ক্লাছ আৰু ব্যৱহাৰিকত উপস্থিত থকাৰ বাহিৰে আন একো কৰিবলৈ কোনো সময় নাপায়। বিভাগটোত নতুন বন্ধু বনোৱা বা পাঠ্যক্ৰমৰ বাহিৰৰ কামত যোগ দিয়াৰ সময় তাৰ নাই।",
              "gu": "આ કારણે, તેને વર્ગો અને પ્રેક્ટિકલમાં હાજરી આપવા સિવાય બીજું કંઈપણ કરવા માટે ભાગ્યે જ સમય મળે છે. તેની પાસે વિભાગમાં નવા મિત્રો બનાવવા કે અભ્યાસેતર પ્રવૃત્તિઓમાં ભાગ લેવાનો સમય નથી."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_2",
              "hi": "C4_2_2",
              "ka": "C4_2_2",
              "te": "C4_2_2",
              "or": "C4_2_2",
              "as": "C4_2_2",
              "gu": "C4_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "His friends and family are concerned because of the amount of pressure he is dealing with in college.",
              "hi": "कॉलेज में काम की अधिकता के दबाव के कारण उसके मित्र और परिवार चिंतित हैं।",
              "ka": "ಅವನು ಕಾಲೇಜಿನಲ್ಲಿ ವ್ಯವಹರಿಸುತ್ತಿರುವ ಒತ್ತಡದ ಪ್ರಮಾಣದಿಂದಾಗಿ ಅವನ ಸ್ನೇಹಿತರು ಮತ್ತು ಕುಟುಂಬದವರು ಕಳವಳಗೊಂಡಿದ್ದಾರೆ.",
              "te": "కళాశాలలో అతను పడుతున్న ఒత్తిడి కారణంగా అతని స్నేహితులు మరియు కుటుంబ సభ్యులు ఆందోళన చెందుతున్నారు.",
              "or": "ଯେଉଁ ପାଠପଢା ର ଚାପ ଦେଇ ସେ ଗତି କରୁଥିଲା, ତା ପରିବାର ଏଏହି ବିଷୟରେ \n ଅବଗତ ଥିଲେ।",
              "as": "সি কলেজত কিমান হেঁচাৰ সৈতে মোকাবিলা কৰি আছে তাকলৈ তাৰ বন্ধুবৰ্গ আৰু পৰিয়াল চিন্তিত ।",
              "gu": "તેના મિત્રો અને કુટુંબીજનો ચિંતિત છે કારણ કે તે કોલેજમાં કેટલા દબાણનો સામનો કરી રહ્યો છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_3",
              "hi": "C4_2_3",
              "ka": "C4_2_3",
              "te": "C4_2_3",
              "or": "C4_2_3",
              "as": "C4_2_3",
              "gu": "C4_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikar is stressed about doing well in the course. He falls ill and wonders if he made a mistake moving to the nutrition department.",
              "hi": "कोर्स को अच्छी तरह से करने के लिए शिखर को बहुत तनाव है। वह अस्वस्थ हो जाता है और सोचता है कि क्या उसने न्यूट्रीशन/ पोषण विभाग में जाने की गलती की।",
              "ka": "ಶಿಖರ್ ತನ್ನ ಕೋರ್ಸ್‌ನಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುವ ಬಗ್ಗೆ ಒತ್ತು ನೀಡುತ್ತಾನೆ. ಅವನು ಅನಾರೋಗ್ಯಕ್ಕೆ ಒಳಗಾಗುತ್ತಾನೆ ಮತ್ತು ಪೌಷ್ಟಿಕಾಂಶ ವಿಭಾಗಕ್ಕೆ ಹೋಗಿದ್ದೇ ತನ್ನ ತಪ್ಪಾಯಿತೇ ಎಂದು ಅಚ್ಚರಿಗೊಳಗಾಗುತ್ತಾನೆ.",
              "te": "కోర్సులో బాగా రాణిస్తున్నందుకు షికార్ ఒత్తిడికి లోనయ్యాడు. అతను అనారోగ్యానికి గురవుతాడు మరియు అతను పోషకాహార విభాగానికి తరలించడంలో పొరపాటు పడ్డాడా అని ఆశ్చర్యపోతాడు.",
              "or": "ବିଷୟରେ ଭଲ କରିବା ପାଇଁ ଶିଖର ବହୁତ ଚପଗ୍ରସ୍ତ ଥିଲା। ତାର ଦେହ ଖରାପ ହେଲା ଓ ସେ ନ୍ୟୁଟ୍ରିସନ ବିଭାଗକୁ ଆସି କିଛି ଭୁଲ କଲା କି ବୋଲି ଭାବିବାକୁ ଲାଗିଲା।",
              "as": "শিখৰ পাঠ্যক্ৰমত ভাল কৰাৰ বাবে চাপত আছে। সি অসুস্থ হৈ পৰে আৰু সি পুষ্টি বিভাগলৈ যোৱাত ভুল কৰিছিল নেকি বুলি আচৰিত হয়।",
              "gu": "શિખરને અભ્યાસક્રમમાં સારું કરવા માટે ભાર મૂકવામાં આવે છે. તે બીમાર પડે છે અને વિચારે છે કે શું તેણે પોષણ વિભાગમાં જવાની ભૂલ કરી છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_3_1//",
              "hi": "C4_3_1 //",
              "ka": "C4_3_1//",
              "te": "C4_3_1//",
              "or": "C4_3_1//",
              "as": "C4_3_1//",
              "gu": "C4_3_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "His mother is concerned about his health and asks Arun to talk to Shikhar.",
              "hi": "उसकी मां उसके स्वास्थ्य के बारे में चिंतित है और अरुण को शिखर से बात करने के लिए कहती है।",
              "ka": "ಅವನ ತಾಯಿಯು ಆತನ ಆರೋಗ್ಯದ ಬಗ್ಗೆ ಕಾಳಜಿ ಹೊಂದಿದ್ದಾಳೆ ಮತ್ತು ಶಿಖರ್ ಜೊತೆ ಮಾತನಾಡಲು ಅರುಣನಿಗೆ ಹೇಳುತ್ತಾಳೆ.",
              "te": "అతని తల్లి అతని ఆరోగ్యం గురించి ఆందోళన చెందుతోంది మరియు శిఖర్‌ తో మాట్లాడమని అరుణ్‌ ని కోరింది.",
              "or": "ତା ମା ତା ଦେହ ବାବଦରେ ଆବଗତ ଥିଲେ ଓ ଅରୁଣ କୁ ଶିଖର ସହ କଥା ହେବ ପାଇଁ କହିଲେ।",
              "as": "তাৰ মাক তাৰ স্বাস্থ্যক লৈ চিন্তিত আৰু অৰুণক শিখৰৰ সৈতে কথা পাতিবলৈ কৈছিল।",
              "gu": "તેની માતા શિખરના સ્વાસ્થ્ય વિશે ચિંતિત છે અને અરુણને શિખર સાથે વાત કરવા કહે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Arun",
              "hi": "अरुण",
              "ka": "ಅರುಣ್",
              "te": "అరుణ్",
              "or": "ଅରୁଣ",
              "as": "অৰুণ",
              "gu": "અરુણ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "What is happening, Shikhar? I thought you would be happy doing the course but look at you.",
              "hi": "क्या हो रहा है, शिखर? मुझे लगा कि तुम खुश हो कि तुम यह कोर्स कर रहे हो लेकिन अब अपने को देखो।",
              "ka": "ಏನಾಗುತ್ತಿದೆ ಶಿಖರ್? ನೀನು ಕೋರ್ಸ್ ಮಾಡುತ್ತಿರುವುದರಿಂದ ಸಂತೋಷವಾಗಿರುತ್ತೀ ಅಂದು ಕೊಂಡಿದ್ದೆವು. ಆದರೆ ನಿನ್ನನ್ನು ನೀನು ನೋಡಿಕೋ. ",
              "te": "ఏం జరుగుతోంది శిఖర్? నువ్వు కోర్సు చేయడం సంతోషంగా ఉంటుందని నేను అనుకున్నాను, కానీ నిన్ను చూడు.",
              "or": "କଣ ହଉଛି ଶିଖର? ମୁଁ ଭାବିଲି ତୁମେ ଏହି ପାଠ୍ୟକ୍ରମ ରେ ଖୁସି ଥିବା କିନ୍ତୁ ନିଜ\n  ଆଡକୁ ଦେଖା।",
              "as": "কি হৈ আছে, শিখৰ? মই ভাবিছিলো তুমি পাঠ্যক্ৰমটো কৰি সুখী হ'বা কিন্তু তোমাৰ ফালে চোৱা।",
              "gu": "શું થઈ રહ્યું છે શિખર? મેં વિચાર્યું કે તું આ કોર્સ કરીને ખુશ થઓ હશે પણ તારી તરફ જો."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "No, Bhaiya, there is a lot of work to be done, and I don’t want to fail.",
              "hi": "नहीं, भैया, बहुत सारे काम करने हैं, और मैं असफल नहीं होना चाहता हूं।",
              "ka": "ಇಲ್ಲ, ಭೈಯ್ಯ, ಮಾಡಲು ಬಹಳಷ್ಟು ಕೆಲಸಗಳಿವೆ ಹಾಗೂ ನಾನು ಅನುತ್ತೀರ್ಣನಾಗಲು ಬಯಸುವುದಿಲ್ಲ.",
              "te": "లేదు, అన్నయ్య, చాలా పని చేయాల్సి ఉంది మరియు నేను ఫెయిల్ కాకూడదనుకుంటున్నాను.",
              "or": "ନାଁ ଭାଇ, ଏଠାରେ ବହୁତ କମ କରିବାକୁ ଅଛି, ଏବଂ ମୁ ଫେଲ ହେବାକୁ ଚାହୁନାହି।",
              "as": "নহয়, দাদা, বহুত কাম কৰিব লাগিব, আৰু মই বিফল হ'ব নোখোজোঁ।",
              "gu": "ના, ભાઈ, ઘણું કામ કરવાનું છે, અને હું નિષ્ફળ થવા માંગતો નથી."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_3_2////",
              "hi": "C4_3_2 //////////",
              "ka": "C4_3_2////",
              "te": "C4_3_2////",
              "or": "C4_3_2////",
              "as": "C4_3_2////",
              "gu": "C4_3_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "First, stop getting stressed about the courses.",
              "hi": "सबसे पहले, कोर्स के प्रति तनाव लेना बंद करो।",
              "ka": "ಮೊದಲಿಗೆ, ಕೋರ್ಸ್‌ಗಳ ಬಗ್ಗೆ ಒತ್ತಡಕ್ಕೆ ಸಿಲುಕುವುದನ್ನು ನಿಲ್ಲಿಸು",
              "te": "మొదట, కోర్సుల గురించి ఒత్తిడికి గురికాకుండా ఉండు.",
              "or": "ପ୍ରଥମତଃ ବିଷୟକୁ ନେଇ ଚିନ୍ତା କରିବା ବନ୍ଦ କର।",
              "as": "প্ৰথমে, পাঠ্যক্ৰমবোৰৰ বিষয়ে চাপ লোৱা বন্ধ কৰা।",
              "gu": "પ્રથમ, અભ્યાસક્રમો વિશે તણાવ રાખવાનું બંધ કર."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Bhaiya, do you think I made a mistake taking this course?",
              "hi": "भैया, क्या आपको लगता है कि मैंने इस कोर्स को लेकर कोई गलती की है?",
              "ka": "ಭೈಯ್ಯ, ನಾನು ಈ ಕೋರ್ಸ್ ತೆಗೆದುಕೊಂಡು ತಪ್ಪು ಮಾಡಿದೆ ಎಂದು ನೀವು ಭಾವಿಸುತ್ತೀರಾ?",
              "te": "అన్నయ్య, నేను ఈ కోర్సు తీసుకొని తప్పు చేశానని నువ్వు అనుకుంటున్నావా?",
              "or": "ଭାଇ , ତୁମେ ଭାବୁଛ କି ମୁଁ ଏହି ବିଷୟକୁ ନେଇ କିଛି ଭୁଲ କରିଛି?",
              "as": "দাদা, তুমি ভাবা নেকি যে মই এই পাঠ্যক্ৰমটো লৈ ভুল কৰিছিলোঁ?",
              "gu": "ભાઈ, શું તમને લાગે છે કે મેં આ કોર્સ કરવામાં ભૂલ કરી છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Shikar, stop overthinking and focus on what you have to do. You are doing a course you like. The more you think negatively, the less you are going to be successful in your career.",
              "hi": "शिखर, अत्यधिक सोचना बंद करो, तुमको जो करना है, उस पर ध्यान केंद्रित करो। तुम अपना मनपसंद कोर्स कर रहे हो। जितना अधिक तुम नकारात्मक सोचते रहोगे, उतना ही अपने करियर में कम सफल होगे।",
              "ka": "ಶಿಖರ್, ಅತಿಯಾಗಿ ಯೋಚಿಸುವುದನ್ನು ನಿಲ್ಲಿಸು ಮತ್ತು ನೀನು ಏನು ಮಾಡಬೇಕೆಂಬುದನ್ನು ಮನಸ್ಸಿನಲ್ಲಿಟ್ಟುಕೊ. ನೀನು ಇಷ್ಟಪಡುವ ಕೋರ್ಸ್ ಮಾಡುತ್ತಿದ್ದೀಯಾ. ನೀನು ಹೆಚ್ಚು ನಕಾರಾತ್ಮಕವಾಗಿ ಯೋಚಿಸುತ್ತೀರಿಯಾ, ನಿನ್ನ ವೃತ್ತಿಜೀವನದಲ್ಲಿ ನೀನು ಯಶಸ್ವಿಯಾಗುತ್ತೀಯಾ.",
              "te": "శిఖర్, అతిగా ఆలోచించడం మానేసి నువ్వు చేయాల్సిన పనిపై దృష్టి పెట్టు. నువ్వు నీకు నచ్చిన కోర్సు చేస్తున్నావు. నువ్వు ఎంత నెగెటివ్‌ (ప్రతికూలం) గా ఆలోచిస్తే, నీ కెరీర్లో నువ్వు అంతగా విజయం సాధించలేవు.",
              "or": "ଶିଖର , ବେଶୀ ଚିନ୍ତା କରିବା ବନ୍ଦ କର ଓ ଆଗକୁ କଣ କରିବାକୁ ଅଛି ସେଥିରେ \n ଧ୍ୟାନ ଦିଅ। ତୁମେ ଭଲ ପାଉଥିବା ବିଷୟକୁ ହି ପଢୁଛ। ଯେତେ ଅଧିକ ନାକରାତ୍ମକ \n ଚିନ୍ତାଧାରା ତୁମେ ରଖିବ ସେତେ କମ ତୁମେ ନିଜ କ୍ୟାରିଆର ରେ ସଫଳ ହେବ।",
              "as": "শিখৰ, অত্যাধিক চিন্তা কৰা বন্ধ কৰা আৰু তুমি কি কৰিব লাগিব তাৰ ওপৰত গুৰুত্ব দিয়া। তুমি তুমি বিচৰা এটা পাঠ্যক্ৰম কৰি আছা। তুমি যিমানে নেতিবাচকভাৱে চিন্তা কৰা, তুমি তোমাৰ কেৰিয়াৰত সিমানে কম সফল হ'বা ।",
              "gu": "શિખર, વધુ પડતું વિચારવાનું બંધ કર અને તારે શું કરવાનું છે તેના પર ધ્યાન કેન્દ્રિત કરો. તમે તમને ગમતો કોર્સ કરી રહ્યા છો. તમે જેટલું વધુ નકારાત્મક વિચારો છો, તેટલું ઓછું તું તારી કારકિર્દીમાં સફળ થઈશ."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_4",
              "hi": "C4_2_4",
              "ka": "C4_2_4",
              "te": "C4_2_4",
              "or": "C4_2_4",
              "as": "C4_2_4",
              "gu": "C4_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar starts organising his time a little better. He focuses on his studies and makes sure he has a healthy diet and gets good rest.",
              "hi": "शिखर अपना समय थोड़ा व्यवस्थित करना शुरु कर देता है। वह अपने अध्ययन पर ध्यान केंद्रित करता है और यह सुनिश्चित करता है कि वह पौष्टिक भोजन ले  तथा विश्राम का भी ध्यान रखे।",
              "ka": "ಶಿಖರ್ ತನ್ನ ಸಮಯವನ್ನು ಸ್ವಲ್ಪ ಉತ್ತಮವಾಗಿ ಸಂಘಟಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ. ಅವನು ತನ್ನ ಅಧ್ಯಯನದ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸುತ್ತಾನೆ ಮತ್ತು ಆರೋಗ್ಯಕರ ಆಹಾರವನ್ನು ಹೊಂದಿದ್ದಾನೆ ಮತ್ತು ಉತ್ತಮ ವಿಶ್ರಾಂತಿ ಪಡೆಯುತ್ತಿದ್ದೇನೆ ಎನ್ನುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "శిఖర్ తన సమయాన్ని కొంచెం మెరుగ్గా నిర్వహించడం ప్రారంభించాడు. అతను తన చదువుపై దృష్టి పెడతాడు మరియు అతను ఆరోగ్యకరమైన ఆహారం మరియు మంచి విశ్రాంతి పొందేలా చూసుకుంటాడు.",
              "or": "ଶିଖର ନିଜ ସମୟକୁ ଭଲ ଭାବେ ସଂଗଠିତ କରିବାକୁ ଆରମ୍ଭ କଲା। ଏ ନିଜ ପାଠ\n  ପଢା ରେ ନିଜର ଧ୍ୟାନ ଲଗେଇଲା ଓ ସ୍ୱାସ୍ଥ୍ୟବାନ ଖାଦ୍ୟ ଓ ଉତ୍ତମ ବିଶ୍ରାମ ନେବାକୁ \n ମନସ୍ଥ କଲା ।",
              "as": "শিখৰে তাৰ সময় অলপ ভালদৰে সজাবলৈ লয়। সি তাৰ পঢ়া-শুনাত গুৰুত্ব দিয়ে আৰু তাৰ স্বাস্থ্যকৰ আহাৰ আৰু ভাল আৰাম পোৱাটো নিশ্চিত কৰে।",
              "gu": "શિખર તેના સમયને થોડો સારી રીતે ગોઠવવાનું શરૂ કરે છે. તે તેના અભ્યાસ પર ધ્યાન કેન્દ્રિત કરે છે અને ખાતરી કરે છે કે તેની પાસે તંદુરસ્ત આહાર લે છે અને તેને સારો આરામ મળે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_5",
              "hi": "C4_2_5",
              "ka": "C4_2_5",
              "te": "C4_2_5",
              "or": "C4_2_5",
              "as": "C4_2_5",
              "gu": "C4_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He realises that although he doesn’t get the best grades in all his extra classes, Shikhar feels like he can pay attention in class, work hard and remain motivated towards completing his course.",
              "hi": "वह देखता है कि उसे अपने सभी अतिरिक्त कक्षाओं में सर्वश्रेष्ठ ग्रेड नहीं मिलते हैं, फिर भी शिखर को लगता है कि वह कक्षा में ध्यान दे सकता है, कड़ी मेहनत कर सकता है और अपने कोर्स को पूरा करने के लिए प्रेरित रह सकता है।",
              "ka": "ತನ್ನ ಎಲ್ಲಾ ಹೆಚ್ಚುವರಿ ತರಗತಿಗಳಲ್ಲಿ ಉತ್ತಮ ಶ್ರೇಣಿಗಳನ್ನು ಪಡೆಯದಿದ್ದರೂ, ಶಿಖರ್ ತರಗತಿಯಲ್ಲಿ ತನ್ನ ಗಮನ ಹರಿಸಬಹುದು, ಕಷ್ಟಪಟ್ಟು ಕೆಲಸ ಮಾಡಬಹುದು ಹಾಗೂ ತನ್ನ ಕೋರ್ಸ್ ಅನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ಪ್ರೇರೇಪಿಸಬಹುದೆಂದು ಅವನು ಅರಿತುಕೊಂಡನು.",
              "te": "అతను తన అదనపు తరగతులన్నింటిలో అత్యుత్తమ గ్రేడ్లను పొందనప్పటికీ, శిఖర్ తరగతిలో శ్రద్ధ వహించగలనని, కష్టపడి పని చేయగలనని మరియు తన కోర్సును పూర్తి చేయడానికి ప్రేరణగా ఉండగలనని అతను గ్రహించాడు.",
              "or": "ସେ ଅନୁଭବ କଲା ଯେ ଯଦିଓ ସେ ନିଜର ଅତିରିକ୍ତ କ୍ଲାସ ଗୁଡିକରେ ଭଲ ଗ୍ରେଡ \n ପାଇନଥିଲା, ତେବେ ଶିଖର ଅନୁଭବ କଲା ଯେ ସେ ଶ୍ରେଣୀରେ ଧ୍ୟାନ ଦେବା, କଠିନ ପରିଶ୍ରମ କରିବା \n ଏବଂ ପାଠ ଶେଷ କରିବା ପାଇଁ ପ୍ରେରିତ ହେବ।",
              "as": "সি ভাবে যে সি তাৰ সকলো অতিৰিক্ত ক্লাছত আটাইতকৈ ভাল গ্ৰেড নাপালেও, শিখৰে ভাবে যে সি ক্লাছত মনোযোগ দিব পাৰে, কষ্ট কৰিব পাৰে আৰু তাৰ পাঠ্যক্ৰম সম্পূৰ্ণ কৰাৰ বাবে প্ৰেৰণা পাব পাৰে।",
              "gu": "તે સમજે છે કે જો કે તેને તેના તમામ વધારાના વર્ગોમાં શ્રેષ્ઠ ગુણ નથી મળતા, શિખરને લાગે છે કે તે વર્ગમાં ધ્યાન આપી શકે છે, સખત મહેનત કરી શકે છે અને તેનો અભ્યાસક્રમ પૂર્ણ કરવા માટે પ્રેરિત રહી શકે છે."
            }
          }
        ]
      },
      "scene24": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_1_1",
              "hi": "C5_1_1",
              "ka": "C5_1_1",
              "te": "C5_1_1",
              "or": "C5_1_1",
              "as": "C5_1_1",
              "gu": "C5_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar has been enjoying studying nutrition. He has successfully finished his first internship at the Indore General Hospital, working as a nutritionist.",
              "hi": "शिखर न्यूट्रीशन/ पोषण का अध्ययन कर रहा है। उसने इंदौर जनरल अस्पताल में न्यूट्रीशनिस्ट/   पोषण विशेषज्ञ के रूप में अपनी पहली इंटर्नशिप को सफलतापूर्वक पूरा कर लिया है।",
              "ka": "ಶಿಖರ್ ಪೌಷ್ಟಿಕಾಂಶದ ಬಗೆಗಿನ ಓದನ್ನು ಆನಂದಿಸುತ್ತಿದ್ದಾನೆ. ಅವನು ತನ್ನ ಮೊದಲ ಇಂಟರ್ನ್‌ಶಿಪ್ ಅನ್ನು ಇಂದೋರ್ ಜನರಲ್ ಆಸ್ಪತ್ರೆಯಲ್ಲಿ ಯಶಸ್ವಿಯಾಗಿ ಮುಗಿಸಿದ್ದಾನೆ, ಪೌಷ್ಟಿಕತಜ್ಞನಾಗಿ ಕೆಲಸ ಮಾಡಿದ್ದಾನೆ.",
              "te": "శిఖర్ పోషకాహారం చదువుతూ ఆనందిస్తున్నాడు. అతను పోషకాహార నిపుణుడు గా పని చేస్తూ ఇండోర్ జనరల్ హాస్పిటల్లో తన మొదటి ఇంటర్న్‌షిప్‌ ను విజయవంతంగా పూర్తి చేశాడు.",
              "or": "ଶିଖର ନ୍ୟୁଟ୍ରିସନ ପଢିବାରେ ଉପଭୋଗ କରୁଥିଲା। ସେ ନିଜର ପ୍ରଥମ ଇଣ୍ଟେର୍ନ୍ସିପ କୁ ଇନ୍ଦୋର ର ସାଧାରଣ ଚିକିଶ୍ଚଳୟ ରେ ନ୍ୟୁଟୃସନିଷ୍ଟ ଭାବେ କାମ କରିସଫଳତା ପୂର୍ବକ ଶେଷ କରିଲା।",
              "as": "শিখৰে পুষ্টি অধ্যয়ন কৰি ভাল পাইছে। সি ইন্দোৰ জেনেৰেল হস্পিটেলত পুষ্টিবিদ হিচাপে কাম কৰি সফলতাৰে তাৰ প্ৰথম ইন্টাৰ্ণশ্বিপ শেষ কৰিছে।",
              "gu": "શિખર પોષણનો અભ્યાસ કરવામાં આનંદ માણી રહ્યો છે. પોષણશાસ્ત્રી તરીકે કામ કરીને તેણે ઇન્દોર જનરલ હોસ્પિટલમાં તેની પ્રથમ ઇન્ટર્નશિપ સફળતાપૂર્વક પૂર્ણ કરી છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_1_2",
              "hi": "C5_1_2",
              "ka": "C5_1_2",
              "te": "C5_1_2",
              "or": "C5_1_2",
              "as": "C5_1_2",
              "gu": "C5_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Throughout the internship, Shikhar has had many new experiences and learnt a lot on the job.",
              "hi": "इंटर्नशिप के दौरान, शिखर को अनेक नए अनुभव हुए हैं और उसने वहां बहुत कुछ सीखा है।",
              "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್‌ನ ಉದ್ದಕ್ಕೂ, ಶಿಖರ್ ಅನೇಕ ಹೊಸ ಅನುಭವಗಳನ್ನು ಹೊಂದಿದ್ದಾನೆ ಮತ್ತು ಉದ್ಯೋಗದಲ್ಲಿ ಬಹಳಷ್ಟು ಕಲಿತಿದ್ದಾನೆ.",
              "te": "ఇంటర్న్‌షిప్‌ మొత్తంలో, శిఖర్ చాలా కొత్త అనుభవాలను పొందాడు మరియు ఉద్యోగంలో చాలా నేర్చుకున్నాడు.",
              "or": "ଇଣ୍ଟେର୍ସିପ ସମୟରେ, ଶିଖର ର ବହୁତ କିଛି ଅନୁଭୂତି ହେଲା ଓ ଚାକିରି ରେ ନୂଆ ନୂଆ ବହୁତ କିଛି ଶିଖିଲ।",
              "as": "সমগ্ৰ ইণ্টাৰ্নশ্বিপত, শিখৰে বহুতো নতুন অভিজ্ঞতা লাভ কৰিছে আৰু কামটোত বহুতো শিকিছে।",
              "gu": "સમગ્ર ઇન્ટર્નશિપ દરમિયાન, શિખરને ઘણા નવા અનુભવો થયા છે અને નોકરી પર ઘણું શીખ્યા છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_1_3_NotebookComponent",
              "hi": "C5_1_3_नोटबुककॉम्पोनेन्ट ",
              "ka": "C5_1_3_NotebookComponent",
              "te": "C5_1_3_పుస్తకం భాగం",
              "or": "C5_1_3_NotebookComponent",
              "as": "C5_1_3_NotebookComponent",
              "gu": "C5_1_3_નોટબુક સામગ્રી"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Flip through his diary to find out about his experience of the internship.",
              "hi": "इंटर्नशिप के उसके अनुभव के बारे में जानने के लिए उसकी डायरी के पन्नों को पलटें।",
              "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್‌ನ ಆತನ ಅನುಭವದ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಲು ಅವನ ಡೈರಿಯನ್ನು ಫ್ಲಿಪ್ ಮಾಡಿ.",
              "te": "ఇంటర్న్‌ షిప్‌ అనుభవం గురించి తెలుసుకోవడానికి అతని డైరీని చూడండి.",
              "or": "ଶିଖର ର ଇଣ୍ଟେର୍ନ୍ସିପ ର ଅନୁଭବ ବିଷୟରେ ଜାଣିବା ପାଇଁ ଡାଏରୀ ମଧ୍ୟରେ ଦେଖନ୍ତୁ।",
              "as": "তাৰ ইণ্টাৰ্নশ্বিপৰ অভিজ্ঞতাৰ বিষয়ে জানিবলৈ তাৰ ডায়েৰীখন লুটিয়াওক।",
              "gu": "ઇન્ટર્નશિપના ના અનુભવ વિશે જાણવા માટે તેની ડાયરીમાં તપાસ કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page 1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "ପ୍ରୁଷ୍ଠା ୧",
              "as": "পৃষ্ঠা 1",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page 2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ 2",
              "or": "ପ୍ରୁଷ୍ଠା ୨",
              "as": "পৃষ্ঠা 2",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "**Things that I learnt**\n\n- Interacting with patients and family\n\n- Reading nutrition charts\n\n- Communicating with doctors and nurses and implementing their instructions",
              "hi": "** चीजें जो मैंने सीखीं **\n\n- रोगियों और परिवार के साथ बातचीत\n\n- पोषण चार्ट पढ़ना\n\n- डॉक्टरों और नर्सों के साथ बातचीत करना और उनके निर्देशों को लागू करना",
              "ka": "**ನಾನು ಕಲಿತ ವಿಷಯಗಳು**                                                                                                                                                                                                            ರೋಗಿಗಳು ಮತ್ತು ಕುಟುಂಬದೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸುವುದು                                                                                                                                                                      ಪೌಷ್ಟಿಕಾಂಶದ ಚಾರ್ಟ್ಗಳನ್ನು ಓದುವುದು                                                                                                                                                                                          ವೈದ್ಯರು ಮತ್ತು ದಾದಿಯರೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸುವುದು ಮತ್ತು ಅವರ ಸೂಚನೆಗಳನ್ನು ಅನುಷ್ಠಾನಗೊಳಿಸುವುದು",
              "te": "**నేను నేర్చుకున్న విషయాలు**\n - రోగులు మరియు కుటుంబ సభ్యులతో సంభాషించడం\n - పోషకాహార చార్ట్లను చదవడం\n - వైద్యులు మరియు నర్సులతో కమ్యూనికేట్ చేయడం మరియు వారి సూచనలను అమలు చేయడం",
              "or": "\"ଯାହା ସବୁ ମୁଁ ଶିଖିଲି\"\n \"ରୋଗୀ ଓ ତାଙ୍କ ପରିବାର ସହ କଥାବାର୍ତ୍ତା\"\n \"ପୋଷଣ ସୁଚିକୁ ପଢିବା\"\n \"ଡାକ୍ତର ଓ ସେବିକା ମାନଙ୍କ ସହ କଥାବାର୍ତ୍ତା ଓ ସେମାନଙ୍କର ଆଦେଶ କୁ ମାନିବା\"",
              "as": "\"**মই শিকা বস্তুবোৰ**\n \n - ৰোগী আৰু পৰিয়ালৰ সৈতে কথা পতা\n \n - পুষ্টি চাৰ্ট পঢ়া\n \n - চিকিৎসক আৰু নাৰ্ছসকলৰ সৈতে যোগাযোগ কৰা আৰু তেওঁলোকৰ নিৰ্দেশনা পালন কৰা\"",
              "gu": "**મેં શીખેલી બાબતો** - દર્દીઓ અને પરિવાર સાથે વાર્તાલાભ કરવી - પોષણ ચાર્ટ વાંચવું - ડોકટરો અને નર્સો સાથે વાતચીત કરવી અને તેમની સૂચનાઓનો અમલ કરવો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "**My reflections of the experience**\n\n- The work is difficult sometimes because a patient’s health can be affected if I make a mistake.\n\n- The smile on the face of the patients when they leave the hospital after recovery makes me happy.\n\n- I can positively change people’s lives; by doing this work, but Bhaiya and \nBabuji may not be pleased.",
              "hi": "** अनुभव के मेरे विचार **\n\n- काम कभी-कभी मुश्किल होता है क्योंकि रोगी का स्वास्थ्य प्रभावित हो सकता है अगर मैं गलती करता हूं।\n\n- जब वे ठीक होने के बाद अस्पताल छोड़ते हैं तो मरीज के चेहरे पर मुस्कान मुझे खुश करती  है।\n\n- इस काम को करके, मैं लोगों के जीवन को सकारात्मक रूप से बदल सकता हूं; लेकिन हो सकता है भैया और\nबाबूजी खुश न हों।",
              "ka": "**ನನ್ನ ಅನುಭವದ ಬಗ್ಗೆ ನನ್ನ ಅನಿಸಿಕೆ**                                                                                                                                                                                         ಕೆಲಸವು ಕೆಲವೊಮ್ಮೆ ಕಷ್ಟಕರವಾಗಿರುತ್ತದೆ ಏಕೆಂದರೆ ನಾನು ತಪ್ಪು ಮಾಡಿದರೆ ರೋಗಿಯ ಆರೋಗ್ಯದ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರಬಹುದು.                                                                    ರೋಗಿಗಳು ಗುಣಮುಖರಾಗಿ ಆಸ್ಪತ್ರೆಯಿಂದ ಹೊರಬರುವಾಗ ಅವರ ಮುಖದಲ್ಲಿನ ನಗು ನನಗೆ ಸಂತೋಷವನ್ನು ನೀಡುತ್ತದೆ.                                                                                        ನಾನು ಜನರ ಜೀವನವನ್ನು ಪಾಸಿಟಿವ್ ಆಗಿ ಬದಲಾಯಿಸಬಲ್ಲೆ; ಈ ಕೆಲಸವನ್ನು ಮಾಡುವುದರಿಂದ, ಆದರೆ ಭೈಯ್ಯಾ ಮತ್ತು ಬಾಬುಜಿಗೆ ಸಂತೋಷವಾಗದಿರಬಹುದು.",
              "te": "****అనుభవంపై నా ప్రతిబింబాలు****\n \n - నేను పొరపాటు చేస్తే రోగి ఆరోగ్యం దెబ్బతింటుంది కాబట్టి కొన్నిసార్లు పని కష్టంగా ఉంటుంది.\n \n - కోలుకుని ఆసుపత్రి నుంచి బయటకు వెళ్లేటప్పుడు పేషెంట్ల ముఖంలో చిరునవ్వు నాకు సంతోషాన్నిస్తుంది.\n \n - నేను ప్రజల జీవితాలను సానుకూలంగా మార్చగలను; ఈ పని చేయడం ద్వారా, కానీ అన్నయ్య మరియు\n నాన్నగారు సంతోషించకపోవచ్చు.",
              "or": "\"ମୋ ଅନୁଭବର ଅଭିଜ୍ଞତା \"\n -କାମ ବହୁତ କଷ୍ଟସାଧ୍ୟ ଥିଲା କାରଣ ମୋର କୌଣସି ଗୋଟିଏ ଭୁଲ ରୋଗୀର ସ୍ୱାସ୍ଥ୍ୟ ଉପରେ ପ୍ରଭାବ ପକାଇପାରେ। \n -ସୁସ୍ଥ ହେଲା ପରେ ରୋଗୀମାନେ ପ୍ରସ୍ଥାନ କରିବା ବେଳେ ତାଙ୍କ ମୁହଁ ର ହସ ମତେ ଖୁସି କରୁଥିଲା।\n -ମୁଁ ସକାରାତ୍ମକ ଭାବେ ଲୋକମାନଙ୍କର ଜୀବନକୁ ବଦଳାଇ ପାରିବି, କିନ୍ତୁ ବାପା ଓ ଭାଇ ଏଥିରେ ଖୁସି ହୋଇ ନପରାନ୍ତି।",
              "as": "**মোৰ অভিজ্ঞতাৰ বিৱৰণ**\n - কামটো কেতিয়াবা টান হয় কিয়নো মই ভুল কৰিলে ৰোগীৰ স্বাস্থ্যত প্ৰভাৱ পৰে। পাৰে।\n \n \n - আৰোগ্য হোৱাৰ পিছত চিকিৎসালয়ৰ পৰা ওলাই যাওঁতে ৰোগীসকলৰ মুখৰ হাঁহিটোৱে মোক সুখী কৰে।\n \n \n - মই মানুহৰ জীৱন ইতিবাচকভাৱে সলনি কৰিব পাৰো; এই কামটো কৰি, কিন্তু দাদা আৰু \n \n দেউতা সন্তুষ্ট নহ'বও পাৰে।",
              "gu": "**અનુભવના મારા પ્રતિબિંબ** - કામ ક્યારેક મુશ્કેલ હોય છે કારણ કે જો હું ભૂલ કરું તો દર્દીના સ્વાસ્થ્યને અસર થઈ શકે છે. - જ્યારે દર્દીઓ સાજા થયા પછી હોસ્પિટલમાંથી નીકળે છે ત્યારે તેમના ચહેરા પરનું સ્મિત મને ખુશ કરે છે. - હું લોકોના જીવનમાં હકારાત્મક ફેરફાર કરી શકું છું; આ કામ કરીને, પણ ભાઈ અને બાબુજી રાજી નહીં થાય."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "Internship",
              "hi": "इंटर्नशिप",
              "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್‌",
              "te": "ఇంటర్న్‌ షిప్‌",
              "or": "ଇଣ୍ଟେର୍ନ୍ସିପ",
              "as": "ইন্টাৰ্ণশ্বিপ",
              "gu": "ઇન્ટર્નશિપ"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_1_5",
              "hi": "C5_1_5",
              "ka": "C5_1_5",
              "te": "C5_1_5",
              "or": "C5_1_5",
              "as": "C5_1_5",
              "gu": "C5_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "On reflecting back, Shikhar feels that he is enjoying working as a nutritionist and is glad that he chose this course.",
              "hi": "पुनः विचार करने पर, शिखर को लगता है कि उसे न्यूट्रीशनिस्ट/ पोषण विशेषज्ञ के रूप में काम करने में आनंद आ रहा है और वह खुश है कि उसने इस कोर्स को चुना है।",
              "ka": "ಮತ್ತೆ ಯೋಚನೆ ಮಾಡಿದಾಗ, ಶಿಖರ್ ತಾನು ಪೌಷ್ಟಿಕತಜ್ಞನಾಗಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಆನಂದಿಸುತ್ತಿದ್ದಾನೆ ಮತ್ತು ಅವನುಈ ಕೋರ್ಸ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಿಕೊಂಡಿದ್ದಕ್ಕಾಗಿ ಸಂತೋಷಪಡುತ್ತಾನೆ.",
              "te": "తిరిగి ఆలోచించినప్పుడు, శిఖర్ తాను పోషకాహార నిపుణుడిగా పని చేయడం ఆనందిస్తున్నట్లు మరియు అతను ఈ కోర్సును ఎంచుకున్నందుకు సంతోషిస్తున్నాడు.",
              "or": "ପୁରୁଣା କଥାକୁ ମନେପକାଇ ଶିଖର ଅନୁଭବ କଲା ଯେ ସେ ନ୍ୟୁଟୃସନିଷ୍ଟ ଭାବେ \n କାମ କରିବାକୁ ସେ ଉପଭୋଗ କରୁଛି ଓ ସେ ବହୁତ ଖୁସି ଯେ ସେ ଏହି ବିଷୟକୁ\n ଚୟନ କରିଛି।",
              "as": "পিছলৈ ঘূৰি চাই , শিখৰে ভাবে যে তেওঁ পুষ্টিবিদ হিচাপে কাম কৰি উপভোগ কৰি আছে আৰু আনন্দিত যে তেওঁ এই পাঠ্যক্ৰমটো বাছনি কৰিছে।",
              "gu": "પાછો પ્રતિભાવ કરવા પર, શિખરને લાગે છે કે તે પોષણશાસ્ત્રી તરીકે કામ કરવાનો આનંદ માણી રહ્યો છે અને તે ખુશ છે કે તેણે આ કોર્સ પસંદ કર્યો."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "Reflection",
              "hi": "प्रतिबिंब",
              "ka": "ಚಿಂತನೆ",
              "te": "ప్రతిబింబించడం",
              "or": "ପ୍ରତିଫଳନ",
              "as": "প্ৰতিফলন",
              "gu": "પ્રતિભાવ"
            }
          }
        ]
      },
      "scene25": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_1_1",
              "hi": "C6_1_1",
              "ka": "C6_1_1",
              "te": "C6_1_1",
              "or": "C6_1_1",
              "as": "C6_1_1",
              "gu": "C6_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar has finished his studies and passed college. He gets his first job at a small private hospital, working as a junior clinical nutritionist.",
              "hi": "शिखर ने अपनी पढ़ाई पूरी कर ली है और कॉलेज पास कर लिया है। उसे एक छोटे निजी अस्पताल में जूनियर क्लीनिकल न्यूट्रीशनिस्ट/ नैदानिक ​​पोषण विशेषज्ञ के रूप में अपनी पहली नौकरी मिलती है।",
              "ka": "ಶಿಖರ್ ಕಾಲೇಜು ಓದು ಮುಗಿಸಿ ಪಾಸಾಗಿದ್ದಾನೆ. ಅವನು ಕಿರಿಯ ಕ್ಲಿನಿಕಲ್ ಪೌಷ್ಟಿಕತಜ್ಞನಾಗಿ ಸಣ್ಣ ಖಾಸಗಿ ಆಸ್ಪತ್ರೆಯಲ್ಲಿ ತನ್ನಮೊದಲ ಕೆಲಸವಾಗಿ ಪಡೆಯುತ್ತಾನೆ",
              "te": "శిఖర్ తన చదువు పూర్తి చేసి కళాశాలలో నుండి వెళ్ళిపోయాడు. అతను ఒక చిన్న ప్రైవేట్ ఆసుపత్రిలో తన మొదటి ఉద్యోగం పొందుతాడు, జూనియర్ క్లినికల్ జూనియర్ క్లినికల్ పోషకాహార నిపుణుడు. గా పని చేస్తాడు.",
              "or": "ଶିଖର ନିଜ ପଢା ଶେଷ କରି କଲେଜ ପାସ କଲା। ସେ ଏକ ଘରୋଇ ଦବାଖାନାରେ \n କନିଷ୍ଠ ନ୍ୟୁଟୃସନିଷ୍ଟ ଭାବେ ଚାକିରି ପାଇଲା।",
              "as": "শিখৰে পঢ়া শেষ কৰি কলেজ পাছ কৰিছে। তেওঁ এখন সৰু ব্যক্তিগত চিকিৎসালয়ত প্ৰথম চাকৰি পায়, এজন কনিষ্ঠ ক্লিনিকেল পুষ্টিবিদ হিচাপে কাম কৰে।",
              "gu": "શિખરે પોતાનો અભ્યાસ પૂરો કર્યો છે અને કોલેજ પાસ કરી છે. જુનિયર ક્લિનિકલ ન્યુટ્રિશનિસ્ટ તરીકે કામ કરીને તેને નાની ખાનગી હોસ્પિટલમાં તેની પ્રથમ નોકરી મળે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_1_2",
              "hi": "C6_1_2",
              "ka": "C6_1_2",
              "te": "C6_1_2",
              "or": "C6_1_2",
              "as": "C6_1_2",
              "gu": "C6_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Because of his internship experience, he settles into this job easily as he could understand all his duties quickly.",
              "hi": "अपने इंटर्नशिप अनुभव के कारण, वह सरलता से इस नौकरी में रम जाता है क्योंकि उसने अपने सभी कर्तव्यों को जल्दी से समझ लिया है।",
              "ka": "ಅವನ ಇಂಟರ್ನ್‌ಶಿಪ್ ಅನುಭವದ ಕಾರಣ, ಅವನು ತನ್ನ ಎಲ್ಲಾ ಕರ್ತವ್ಯಗಳನ್ನು ತ್ವರಿತವಾಗಿ ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವ ಕಾರಣ ಅವನು ಸುಲಭವಾಗಿ ಈ ಉದ್ಯೋಗದಲ್ಲಿ ನೆಲೆಸುತ್ತಾನೆ.",
              "te": "అతని ఇంటర్న్‌ షిప్‌ అనుభవం కారణంగా, అతను తన అన్ని విధులను త్వరగా అర్థం చేసుకోగలడు కాబట్టి అతను ఈ ఉద్యోగంలో సులభంగా స్థిరపడతాడు.",
              "or": "ନିଜର ଇଣ୍ଟେର୍ନ୍ସିପ ଅନୁଭବ ପାଇଁ, ସେ ସେ ଏହି କାମରେ ସହଜରେ ନିଯୋଜିତ ହୋଇଗଲା \n ଯେହେତୁ ସେ ନିଜର କର୍ତ୍ତବ୍ୟ କୁ ବହୁତ ଜଲଦି ବୁଝିପାରିଥିଲା।",
              "as": "তাৰ ইণ্টাৰ্নশ্বিপৰ অভিজ্ঞতাৰ বাবে, তেওঁ সহজে এই কামত থিতাপি লয় কিয়নো তেওঁ তাৰ সকলো কৰ্তব্য সোনকালে বুজিব পাৰে।",
              "gu": "તેના ઇન્ટર્નશિપ અનુભવને કારણે, તે આ નોકરીમાં સરળતાથી સ્થાયી થાય છે કારણ કે તે તેની બધી ફરજો ઝડપથી સમજી શકતો હતો."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_1_3",
              "hi": "C6_1_3",
              "ka": "C6_1_3",
              "te": "C6_1_3",
              "or": "C6_1_3",
              "as": "C6_1_3",
              "gu": "C6_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After a few years of hard work in the hospital, he becomes an assistant nutritionist.",
              "hi": "अस्पताल में कुछ वर्षों की कड़ी मेहनत के बाद, वह एक सहायक न्यूट्रीशनिस्ट/ पोषण विशेषज्ञ बन जाता है।",
              "ka": "ಆಸ್ಪತ್ರೆಯಲ್ಲಿ ಕೆಲವು ವರ್ಷಗಳ ಕಠಿಣ ಪರಿಶ್ರಮದ ನಂತರ, ಅವನು ಸಹಾಯಕ ಪೌಷ್ಟಿಕತಜ್ಞನಾಗುತ್ತಾನೆ.",
              "te": "ఆసుపత్రిలో కొన్ని సంవత్సరాలు కష్టపడి, అతను సహాయ పోషకాహార నిపుణుడు అవుతాడు.",
              "or": "ଦବାଖାନାରେ କଠିନ ପରିଶ୍ରମ ପରେ ସେ ସହାୟକ ନ୍ୟୁଟ୍ରିସନିଷ୍ଟ ହୋଇଗଲା।",
              "as": "চিকিৎসালয়ত কেইবছৰমান কষ্ট কৰাৰ পিছত, তেওঁ এজন সহকাৰী পুষ্টিবিদ হৈ পৰে।",
              "gu": "હોસ્પિટલમાં થોડા વર્ષોની સખત મહેનત પછી, તે સહાયક ન્યુટ્રિશનિસ્ટ બને છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_2_2",
              "hi": "C6_2_2",
              "ka": "C6_2_2",
              "te": "C6_2_2",
              "or": "C6_2_2",
              "as": "C6_2_2",
              "gu": "C6_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "In this position, he gets the opportunity to work with many senior doctors at the hospital and learns a lot about food and healthcare from them.",
              "hi": "इस स्थिति में, उसे अस्पताल में कई वरिष्ठ डॉक्टरों के साथ काम करने का अवसर मिलता है और उनसे खाद्य और स्वास्थ्य देखभाल के बारे में बहुत कुछ सीखता है।",
              "ka": "ಈ ಸ್ಥಾನದಲ್ಲಿ, ಅವನು ಆಸ್ಪತ್ರೆಯಲ್ಲಿ ಅನೇಕ ಹಿರಿಯ ವೈದ್ಯರೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುವ ಅವಕಾಶವನ್ನು ಪಡೆಯುತ್ತಾನೆ ಮತ್ತು ಅವರಿಂದ ಆಹಾರ ಮತ್ತು ಆರೋಗ್ಯದ ಬಗ್ಗೆ ಸಾಕಷ್ಟು ಕಲಿಯುತ್ತಾನೆ.",
              "te": "ఈ స్థితిలో, అతను ఆసుపత్రిలో చాలా మంది సీనియర్ వైద్యులతో కలిసి పనిచేసే అవకాశాన్ని పొందుతాడు మరియు వారి నుండి ఆహారం మరియు ఆరోగ్య సంరక్షణ గురించి చాలా నేర్చుకుంటాడు.",
              "or": "ଏହି ସ୍ଥାନରେ ସେ ବହୁତ ଗୁଡିଏ ବରିଷ୍ଠ ଡାକ୍ତର ଙ୍କ ସହ କାମ କରିବାର ସୁଯୋଗ\n ପାଇଲା ଓ ଖାଦ୍ୟ ଏବଂ ସ୍ଵାସ୍ଥ୍ୟର ଯତ୍ନ ବିଷୟରେ ଶିଖିଲା।",
              "as": "এই পদত, তেওঁ চিকিৎসালয়ৰ বহুতো জ্যেষ্ঠ চিকিৎসকৰ সৈতে কাম কৰাৰ সুযোগ পায় আৰু তেওঁলোকৰ পৰা খাদ্য আৰু স্বাস্থ্যসেৱাৰ বিষয়ে বহুতো শিকে।",
              "gu": "આ પદ પર, તેને હોસ્પિટલમાં ઘણા વરિષ્ઠ ડૉક્ટરો સાથે કામ કરવાની તક મળે છે અને તેની પાસેથી ખોરાક અને આરોગ્યસંભાળ વિશે ઘણું શીખે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_2_3",
              "hi": "C6_2_3",
              "ka": "C6_2_3",
              "te": "C6_2_3",
              "or": "C6_2_3",
              "as": "C6_2_3",
              "gu": "C6_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "His family was not very happy when he took up the course in college but are now satisfied that he is working in a hospital and helping patients.",
              "hi": "जब उसने कॉलेज में कोर्स लिया था तो उनका परिवार बहुत खुश नहीं था लेकिन अब संतुष्ट है कि वह एक अस्पताल में काम कर रहा है और रोगियों की सहायता  कर रहा है।",
              "ka": "ಅವನು ಕಾಲೇಜಿನಲ್ಲಿ ಕೋರ್ಸ್ ತೆಗೆದುಕೊಂಡಾಗ ಅವನ ಕುಟುಂಬವು ತುಂಬಾ ಸಂತೋಷವಾಗಿರಲಿಲ್ಲ ಆದರೆ ಈಗ ಅವನು ಆಸ್ಪತ್ರೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾನೆ ಮತ್ತು ರೋಗಿಗಳಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತಿದ್ದಾನೆ ಎನ್ನುವ ತೃಪ್ತಿ ಹೊಂದಿದ್ದಾನೆ.",
              "te": "అతను కళాశాలలో కోర్సు తీసుకున్నప్పుడు అతని కుటుంబం చాలా సంతోషంగా లేదు, కానీ ఇప్పుడు అతను ఆసుపత్రిలో పని చేస్తూ రోగులకు సహాయం చేస్తున్నందుకు సంతృప్తి చెందాడు.",
              "or": "ଯେତେବେଳେ ସେ କଲେଜ ରେ ଏହି ପାଠ୍ୟକ୍ରମ ଚୟନ କରିଥିଲା, ତା ପରିବାର \n ଲୋକେ ବେଶୀ ଖୁସି ନଥିଲେ। କିନ୍ତୁ ଏ ଏବେ ଦବଖାନାରେ କାମ କରୁଛି ଓ ରୋଗୀଙ୍କର \n ସେବା କରୁଥିବାରୁ ସେମାନେ ସନ୍ତୁଷ୍ଟ ଥିଲେ।",
              "as": "কলেজত পাঠ্যক্ৰম গ্ৰহণ কৰাৰ সময়ত তাৰ পৰিয়াল বৰ সুখী নাছিল কিন্তু এতিয়া সন্তুষ্ট যে তেওঁ এখন চিকিৎসালয়ত কাম কৰি আছে আৰু ৰোগীসকলক সহায় কৰি আছে।",
              "gu": "જ્યારે તે કૉલેજમાં અભ્યાસક્રમ લીધો ત્યારે તેનો પરિવાર બહુ ખુશ ન હતો પરંતુ હવે તે હૉસ્પિટલમાં કામ કરે છે અને દર્દીઓને મદદ કરે છે તેનાથી સંતુષ્ટ છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_2_4",
              "hi": "C6_2_4",
              "ka": "C6_2_4",
              "te": "C6_2_4",
              "or": "C6_2_4",
              "as": "C6_2_4",
              "gu": "C6_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar also sends some money home, and his parents are happy with his growth in his career.",
              "hi": "शिखर कुछ पैसे भी घर भेजता है, और उसके माता-पिता उसकी करियर वृद्धि से खुश हैं।",
              "ka": "ಶಿಖರ್ ಕೂಡ ಸ್ವಲ್ಪ ಹಣವನ್ನು ಮನೆಗೆ ಕಳುಹಿಸುತ್ತಾನೆ ಮತ್ತು ಅವನ ವೃತ್ತಿಜೀವನದ ಬೆಳವಣಿಗೆಯಿಂದ ಅವನ ಹೆತ್ತವರು ಸಂತೋಷಪಡುತ್ತಾರೆ",
              "te": "శిఖర్ ఇంటికి కూడా కొంత డబ్బు పంపుతాడు మరియు అతని కెరీర్లో అతని ఎదుగుదల పట్ల అతని తల్లిదండ్రులు సంతోషంగా ఉన్నారు.",
              "or": "ଶିଖର କିଛି ଟଙ୍କା ମଧ୍ୟ ଘରକୁ ପଠାଉଥିଲା , ଏବଂ ତା ପିତାମାତା ତାର କ୍ୟାରିଆର ରେ \n ଏପରି ବିକାଶରେ ଖୁସି ଥିଲେ।",
              "as": "শিখৰে ঘৰলৈ কিছু টকাও পঠিয়ায়, আৰু তেওঁৰ পিতৃ-মাতৃ তেওঁৰ কেৰিয়াৰৰ বিকাশত সুখী।",
              "gu": "શિખર કેટલાક પૈસા ઘરે પણ મોકલે છે, અને તેના માતા-પિતા તેની કારકિર્દીના વિકાસથી ખુશ છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_2_5",
              "hi": "C6_2_5",
              "ka": "C6_2_5",
              "te": "C6_2_5",
              "or": "C6_2_5",
              "as": "C6_2_5",
              "gu": "C6_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "With his knowledge about food and nutrition, Shikhar also guides his brother and father with what yields have higher demand and are considered more nutritious.",
              "hi": "खाद्य और पोषण के बारे में अपने ज्ञान के साथ, शिखर अपने भाई और पिता का भी मार्गदर्शन करता है कि किस फसल की मांग अधिक है और  किसे अधिक पौष्टिक माना जाता है।",
              "ka": "ಆಹಾರ ಮತ್ತು ಪೌಷ್ಠಿಕಾಂಶದ ಬಗ್ಗೆ ಇರುವ ಅವನ ಜ್ಞಾನದೊಂದಿಗೆ, ಶಿಖರ್ ತನ್ನ ಸಹೋದರ ಮತ್ತು ತಂದೆಗೆ ಯಾವ ಇಳುವರಿಯು ಹೆಚ್ಚಿನ ಬೇಡಿಕೆಯನ್ನು ಹೊಂದಿದೆ ಮತ್ತು ಹೆಚ್ಚು ಪೌಷ್ಟಿಕವೆಂದು ಪರಿಗಣಿಸಲಾಗಿದೆ ಎಂದು ಮಾರ್ಗದರ್ಶನ ನೀಡುತ್ತಾನೆ.",
              "te": "ఆహారం మరియు పోషకాహారం గురించి తనకున్న జ్ఞానంతో, శిఖర్ తన సోదరుడు మరియు తండ్రికి కూడా ఏ రకాల దిగుబడికి ఎక్కువ గిరాకీ ఉంది మరియు ఎక్కువ పోషకమైనదిగా పరిగణించబడుతుందని నిర్దేశిస్తాడు.",
              "or": "ଖାଦ୍ୟ ଏବଂ ନ୍ୟୁଟ୍ରିସନ ରେ ନିଜ ଜ୍ଞାନ ସହିତ, ଶିଖର କେଉଁ ପ୍ରକାର ଉପଜର ବେଶୀ ଚାହିଦା ଓ \n ଅଧିକ ପୋଷକ ଭାବେ ଦେଖାଯାଉଛି, ସେ ବାବଦରେ ତା ଭାଇ ଓ ବାପଙ୍କୁ ଅବଗତ କରାଏ।",
              "as": "খাদ্য আৰু পুষ্টিৰ বিষয়ে তেওঁৰ জ্ঞানৰ সৈতে, শিখৰে তেওঁৰ ককায়েক আৰু দেউতাককো পথ দেখুৱায় কি উৎপাদনৰ চাহিদা অধিক আৰু অধিক পুষ্টিকৰ বুলি গণ্য কৰা হয়।",
              "gu": "ખોરાક અને પોષણ વિશેના તેના જ્ઞાન સાથે, શિખર તેના ભાઈ અને પિતાને પણ માર્ગદર્શન આપે છે કે કઈ ઉપજની માંગ વધુ છે અને તે વધુ પોષક માનવામાં આવે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_3_1",
              "hi": "C6_3_1",
              "ka": "C6_3_1",
              "te": "C6_3_1",
              "or": "C6_3_1",
              "as": "C6_3_1",
              "gu": "C6_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After a couple of years more of working as an assistant, Shikhar is ready to take the next step in his career by working independently and becoming a consultant dietician.",
              "hi": "एक सहायक के रूप में काम करने के कुछ वर्षों बाद, शिखर स्वतंत्र रूप से काम करके और सलाहकार आहार विशेषज्ञ बनकर अपने करियर में आगे बढ़ने  के लिए तैयार है।",
              "ka": "ಒಂದೆರಡು ವರ್ಷಗಳು ಸಹಾಯಕರಾಗಿ ಕೆಲಸ ಮಾಡಿದ ನಂತರ, ಶಿಖರ್ ಸ್ವತಂತ್ರವಾಗಿ ಕೆಲಸ ಮಾಡುವ ಮೂಲಕ ಮತ್ತು ಸಲಹೆಗಾರ ಡಯಟಿಶಿಯನ್ ಆಗುವ ಮೂಲಕ ತನ್ನ ವೃತ್ತಿಜೀವನದಲ್ಲಿ ಮುಂದಿನ ಹೆಜ್ಜೆ ಇಡಲು ಸಿದ್ಧನಾಗಿದ್ದಾನೆ.",
              "te": "కొన్ని సంవత్సరాల పాటు సహాయకుడు, గా పనిచేసిన శిఖర్, స్వతంత్రంగా పనిచేసి, సలహాదారు డైటీషియన్. గా మారడం ద్వారా తన కెరీర్లో తదుపరి అడుగు వేయడానికి సిద్ధంగా ఉన్నాడు.",
              "or": "କିଛି ବର୍ଷ ପରେ, ସହାୟକ ଭାବେ କମ କରିଲା ପରେ, ଶିଖର ନିଜେ ସ୍ଵତନ୍ତ୍ର ଭାବେ\n ଆହାର ପରାମର୍ଶ ଦାତା ଭାବେ କାମ କରିବାର ପରବର୍ତ୍ତୀ ପାଦ ବଢାଇବା ପାଇଁ ପ୍ରସ୍ତୁତ ଥିଲା।",
              "as": "সহকাৰী হিচাপে আৰু কেইবছৰমান কাম কৰাৰ পিছত, শিখৰে স্বতন্ত্ৰভাৱে কাম কৰি আৰু কনচালটেন্ট ডায়েটিচিয়ান হৈ তেওঁৰ কেৰিয়াৰৰ পৰৱৰ্তী খোজ দিবলৈ সাজু হৈছে।",
              "gu": "આસિસ્ટન્ટ તરીકે બે વર્ષ વધુ કામ કર્યા પછી, શિખર સ્વતંત્ર રીતે કામ કરીને અને કન્સલ્ટન્ટ ડાયટિશિયન બનીને તેની કારકિર્દીમાં આગળનું પગલું ભરવા માટે તૈયાર છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_3_2",
              "hi": "C6_3_2",
              "ka": "C6_3_2",
              "te": "C6_3_2",
              "or": "C6_3_2",
              "as": "C6_3_2",
              "gu": "C6_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "For this, he has to register with the Indian Dietetic Association. However, he can only become a registered dietician if he has done a Postgraduate course in Nutrition & Dietetics.",
              "hi": "इसके लिए, उसे भारतीय डायटेटिक एसोसिएशन के साथ पंजीकरण करना होगा। हालांकि, वह एक पंजीकृत आहार विशेषज्ञ तब ही बन सकता है जब वह न्यूट्रीशन और डाइटेटिक्स/ पोषण और आहार में स्नातकोत्तर कोर्स करता है।",
              "ka": "ಇದಕ್ಕಾಗಿ ಅವನು ಇಂಡಿಯನ್ ಡಯೆಟಿಕ್ ಅಸೋಸಿಯೇಷನ್‌ನಲ್ಲಿ ನೋಂದಾಯಿಸಿಕೊಳ್ಳಬೇಕು. ಆದಾಗ್ಯೂ, ಅವನು ಪೌಷ್ಟಿಕಾಂಶದಲ್ಲಿ ಸ್ನಾತಕೋತ್ತರ ಕೋರ್ಸ್ ಮಾಡಿದರೆ ಮಾತ್ರ ಅವನು ನೋಂದಾಯಿತ ಆಹಾರ ತಜ್ಞನಾಗಬಹುದು",
              "te": "ఇందుకోసం ఇండియన్ డైటెటిక్ అసోసియేషన్ లో (భారతీయ ఆహార నియంత్రణ అసోసియేషన్.)నమోదు చేసుకోవాలి. అయితే, అతను పోషకాహారం & ఆహార శాస్త్రాల లో పోస్ట్ గ్రాడ్యుయేట్ కోర్సు చేసినట్లయితే మాత్రమే అతను నమోదిత ఆహార నిపుణుడుఅవుతాడు.",
              "or": "ଏହା ପାଇଁ ତାକୁ Indian Dietetic Association ସହିତ ପଞ୍ଜକୃତ କରିବାକୁ ହେବ। ତଥାପି ସେ ଏକ ପଞ୍ଜୀକୃତ ଡାଏଟିସିଆନ ସେତିକିବେଳେ ହୋଇପାରିବ, ଯଦି ସେ ନ୍ୟୁଟୃସନ ଓ ଡାଏଟିକ୍ସ ରେ ସ୍ନାତୋକତ୍ତର କରିଥିବା।",
              "as": "ইয়াৰ বাবে, তেওঁ ইণ্ডিয়ান ডায়েটেটিক এছ'চিয়েচনৰ সৈতে পঞ্জীয়ন কৰিব লাগিব। অৱশ্যে, তেওঁ কেৱল তেতিয়াহে পঞ্জীভুক্ত ডায়েটিচিয়ান হ'ব পাৰে যদি তেওঁ পুষ্টি আৰু ডায়েটেটিক্সত স্নাতকোত্তৰ পাঠ্যক্ৰম কৰিছে।",
              "gu": "આ માટે તેણે ઈન્ડિયન ડાયેટીક એસોસિએશનમાં રજીસ્ટ્રેશન કરાવવું પડશે. જો કે, જો તેણે પોષણ અને આહારશાસ્ત્રમાં અનુસ્નાતક અભ્યાસક્રમ કર્યો હોય તો જ તે નોંધાયેલ ડાયેટિશિયન બની શકે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_3_3",
              "hi": "C6_3_3",
              "ka": "C6_3_3",
              "te": "C6_3_3",
              "or": "C6_3_3",
              "as": "C6_3_3",
              "gu": "C6_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar is now searching for the best colleges in India, excited for this next step in his career journey.",
              "hi": "शिखर अब भारत के सर्वश्रेष्ठ कॉलेजों की तलाश कर रहा है, वह अपने करियर यात्रा में इस अगले कदम के लिए उत्साहित है।",
              "ka": "ಶಿಖರ್ ಈಗ ಭಾರತದ ಅತ್ಯುತ್ತಮ ಕಾಲೇಜುಗಳನ್ನು ಹುಡುಕುತ್ತಿದ್ದಾನೆ, ಅವನ ವೃತ್ತಿಜೀವನದ ಈ ಮುಂದಿನ ಹೆಜ್ಜೆಗಾಗಿ ಉತ್ಸುಕನಾಗಿದ್ದಾನೆ.",
              "te": "శిఖర్ ఇప్పుడు భారతదేశంలోని అత్యుత్తమ కళాశాలల కోసం వెతుకుతున్నాడు, తన కెరీర్ ప్రయాణంలో ఈ తదుపరి దశ కోసం ఉత్సాహంగా ఉన్నాడు.",
              "or": "ଶିଖର ବର୍ତ୍ତମାନ ଭାରତର ଭଲ କଲେଜକୁ ଖୋଜୁଛି, ନିଜ କ୍ୟାରିଅର ଯାତ୍ରା ର ପରବର୍ତ୍ତୀ ସୋପାନ ପାଇଁ ରୋମାଞ୍ଚିତ ଅଛି।",
              "as": "শিখৰে এতিয়া ভাৰতৰ শ্ৰেষ্ঠ কলেজবিলাক বিচাৰি আছে, তেওঁৰ কেৰিয়াৰ যাত্ৰাৰ এই পৰৱৰ্তী খোজৰ বাবে উৎসাহিত।",
              "gu": "શિખર હવે ભારતમાં શ્રેષ્ઠ કોલેજો શોધી રહ્યો છે, તેની કારકિર્દીની સફરના આ આગળના પગલા માટે ઉત્સાહિત છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "Nutritionist",
              "hi": " न्यूट्रीशनिस्ट/ पोषण विशेषज्ञ",
              "ka": "ಪೌಷ್ಟಿಕತಜ್ಞ",
              "te": "పోషకాహార నిపుణుడు",
              "or": "ନ୍ୟୁଟ୍ରିସନିଷ୍ଟ",
              "as": "পুষ্টিবিদ",
              "gu": "ન્યુટ્રિશનિસ્ટ"
            }
          }
        ]
      },
      "scene26": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Continue Hotel Management",
              "hi": "होटल प्रबंधन जारी रखे",
              "ka": "ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್ ಕೋರ್ಸ್ ಮುಂದುವರಿಸುವುದು",
              "te": "హోటల్ మేనేజ్‌మెంట్ కొనసాగించండి",
              "or": "ଅଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ କୁ ଜାରି ରଖିବ।",
              "as": "হোটেল মেনেজমেণ্ট চলাই ৰাখা",
              "gu": "હોટેલ મેનેજમેન્ટ ચાલુ રાખો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar decides to continue studying Hotel Management as it has been his dream to become a chef.",
              "hi": "शिखर होटल प्रबंधन का अध्ययन जारी रखने का निर्णय करता है क्योंकि उसका एक शेफ बनने का सपना रहा है।",
              "ka": "ಶಿಖರ್‌ಗೆ ಬಾಣಸಿಗನಾಗುವುದು ಅವನ ಕನಸಾಗಿದ್ದರಿಂದ ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಓದುವುದನ್ನು ಮುಂದುವರಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "శిఖర్ వంటవాడు కావాలనేది తన కల కాబట్టి హోటల్ మేనేజ్‌మెంట్ చదవాలని నిర్ణయించుకున్నాడు.",
              "or": "ଶିଖର ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟକୁ ଜାରି ରଖିବାକୁ ନିଷ୍ପତ୍ତି ନେଲା, ଯେହେତୁ \n ଏକ ରୋଷେୟା ହେବା ତାର ସ୍ଵପ୍ନ ଥିଲା।",
              "as": "শিখৰে হোটেল মেনেজমেণ্ট পঢ়াটো বজাই ৰখা থিৰাং কৰিছে কিয়নো এজন ৰান্ধনী হোৱাটো তেওঁৰ সপোন আছিল।",
              "gu": "શિખરે હોટેલ મેનેજમેન્ટનો અભ્યાસ ચાલુ રાખવાનું નક્કી કર્યું કારણ કે રસોયો બનવાનું તેનું સ્વપ્ન હતું."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_1_2",
              "hi": "C7_1_2",
              "ka": "C7_1_2",
              "te": "C7_1_2",
              "or": "C7_1_2",
              "as": "C7_1_2",
              "gu": "C7_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As a part of the course, he has to take up an internship to get first-hand experience in the industry.",
              "hi": "कोर्स के एक भाग के रूप में, उसे इंडस्ट्री में अनुभव पाने के लिए पहले इंटर्नशिप करनी पड़ेगी।",
              "ka": "ಕೋರ್ಸ್‌ನ ಭಾಗವಾಗಿ, ಅವನು ಉದ್ಯಮದಲ್ಲಿ ಮೊದಲ ಅನುಭವವನ್ನು ಪಡೆಯಲು ಇಂಟರ್ನ್‌ಶಿಪ್ ತೆಗೆದುಕೊಳ್ಳಬೇಕಾಗುತ್ತದೆ.",
              "te": "కోర్సులో భాగంగా, పరిశ్రమలో మొదటి అనుభవాన్ని పొందడానికి అతను ఇంటర్న్షిప్ చేయాలి.",
              "or": "ପାଠ୍ୟକ୍ରମର ଏକ ଅଂଶ ଭାବେ , ତାକୁ ଇଣ୍ଡଷ୍ଟ୍ରି ରେ ପ୍ରଥମ ଅନୁଭବ ପାଇଁ ଏକ ଇଣ୍ଟର୍ନ୍ସିପ \n ହାତକୁ ନବାର ଥିଲା।",
              "as": "পাঠ্যক্ৰমৰ অংশ হিচাপে, তেওঁ উদ্যোগটোত প্ৰত্যক্ষ অভিজ্ঞতা পাবলৈ এটা ইন্টাৰ্ণশ্বিপ ল'ব লাগিব।",
              "gu": "કોર્સના ભાગરૂપે, તેણે ઉદ્યોગમાં પ્રથમ હાથનો અનુભવ મેળવવા માટે ઇન્ટર્નશિપ લેવી પડશે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_1_3",
              "hi": "C7_1_3",
              "ka": "C7_1_3",
              "te": "C7_1_3",
              "or": "C7_1_3",
              "as": "C7_1_3",
              "gu": "C7_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He has been doing well in his studies. Because of good marks in college, he gets many options of places to intern at. He decides to do his internship at a big hotel in Indore.",
              "hi": "वह बहुत अच्छी तरह से अपना अध्ययन कर रहा है। कॉलेज में अच्छे अंकों के कारण, उसे इंटर्न करने के लिए अनेक स्थानों के विकल्प मिलते हैं। वह इंदौर में एक बड़े होटल में अपनी इंटर्नशिप करने का फैसला करता है।",
              "ka": "ಅವನು ತನ್ನ ಅಧ್ಯಯನದಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಿದ್ದಾನೆ. ಕಾಲೇಜಿನಲ್ಲಿ ಉತ್ತಮ ಅಂಕಗಳಿರುವುದರಿಂದ, ಅವನು ಇಂಟರ್ನ್ ಮಾಡಲು ಹಲವು ಸ್ಥಳಗಳ ಆಯ್ಕೆಗಳನ್ನು ಪಡೆಯುತ್ತಾನೆ. ಇಂದೋರ್‌ನ ದೊಡ್ಡ ಹೋಟೆಲ್‌ನಲ್ಲಿ ತನ್ನ ಇಂಟರ್ನ್‌ಶಿಪ್ ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "చదువులో బాగా రాణిస్తున్నాడు. కళాశాలలో మంచి మార్కుల కారణంగా, అతను ఇంటర్న్షిప్ కోసం అనేక స్థలాల ఎంపికలను పొందుతాడు. ఇండోర్‌ లోని ఒక పెద్ద హోటల్‌లో ఇంటర్న్‌ షిప్‌ చేయాలని నిర్ణయించుకున్నాడు.",
              "or": "ସେ ନିଜ ପାଠ ରେ ଭଲ କରୁଥିଲା। କଲେଜରେ ଭଲ ନମ୍ବର ରଖିଥିବାରୁ, ସେ ଇଣ୍ଟର୍ଣ \n କରିବା ପାଇଁ ବହୁତ ସାରା ଯାଗାର ବିକଳ୍ପ ପାଇଲା। ଇନ୍ଦୋର ର ଏକ ବଡ ହୋଟେଲ ରେ ସେ ନିଜର ଇଣ୍ଟର୍ନ୍ସିପ \n କରିବା ବୋଲି ମନସ୍ଥ କଲା।",
              "as": "তেওঁ পঢ়াশুনাত ভাল কৰি আছে। কলেজত ভাল নম্বৰৰ বাবে, তেওঁ ইন্টাৰ্ণ কৰিবলৈ বহুতো বিকল্প পায়। তেওঁ ইন্দোৰৰ এখন ডাঙৰ হোটেলত তেওঁৰ ইন্টাৰ্ণশ্বিপ কৰাৰ সিদ্ধান্ত লয়।",
              "gu": "તે તેના અભ્યાસમાં સારો દેખાવ કરી રહ્યો છે. કૉલેજમાં સારા ગુણને કારણે તેને ઈન્ટર્ન કરવા માટે ઘણા બધા વિકલ્પો મળે છે. તેણે ઈન્દોરની એક મોટી હોટેલમાં ઈન્ટર્નશિપ કરવાનું નક્કી કર્યું."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_2_1",
              "hi": "C7_2_1",
              "ka": "C7_2_1",
              "te": "C7_2_1",
              "or": "C7_2_1",
              "as": "C7_2_1",
              "gu": "C7_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "On the first day of the internship the hotel manager explains to all the interns about the various departments and the kinds of jobs they will be involved in.",
              "hi": "इंटर्नशिप के पहले दिन होटल मैनेजर विभिन्न विभागों और अलग -अलग तरह के काम के विषय में सभी इंटर्न को बताते हैं जो उनको करने हैं।",
              "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್‌ನ ಮೊದಲ ದಿನದಂದು ಹೋಟೆಲ್ ಮ್ಯಾನೇಜರ್ ಎಲ್ಲಾ ಇಂಟರ್ನ್‌ಗಳಿಗೆ ವಿವಿಧ ವಿಭಾಗಗಳು ಮತ್ತು ಅವರು ತೊಡಗಿಸಿಕೊಳ್ಳುವ ಕೆಲಸಗಳ ಬಗ್ಗೆ ವಿವರಿಸುತ್ತಾರೆ.",
              "te": "ఇంటర్న్‌ షిప్‌ మొదటి రోజున హోటల్ మేనేజర్ ఇంటర్న్ ల అందరికీ వివిధ విభాగాలు మరియు వారు పాల్గొనబోయే ఉద్యోగాల గురించి వివరిస్తారు.",
              "or": "ଇଣ୍ଟର୍ନ୍ସିପର ପ୍ରଥମ ଦିନରେ, ହୋଟେଲ ମ୍ୟାନେଜର ସମସ୍ତକୁ ବିଭିର୍ଣ୍ଣ ବିଭାଗ ବିଷୟରେ \n ବୁଝାଇଲେ ଓ କେଉଁ ପ୍ରକାର କାମରେ ସେମାନେ ନିୟୋଜିତ ହେବେ ସେ କଥା ମଧ୍ୟ କହିଲେ।",
              "as": "ইন্টাৰ্ণশ্বিপৰ প্ৰথম দিনটোত হোটেল মেনেজাৰে সকলো ইন্টাৰ্ণক বিভিন্ন বিভাগ আৰু তেওঁলোক কেনে ধৰণৰ চাকৰিত জড়িত থাকিব সেই বিষয়ে বুজাই দিয়ে।",
              "gu": "ઇન્ટર્નશીપના પ્રથમ દિવસે હોટેલ મેનેજર તમામ ઇન્ટર્નને વિવિધ વિભાગો અને તેઓ કેવા પ્રકારની નોકરીઓમાં સામેલ થશે તે વિશે સમજાવે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_2_2//",
              "hi": "C7_2_2 //",
              "ka": "C7_2_2//",
              "te": "C7_2_2//",
              "or": "C7_2_2//",
              "as": "C7_2_2//",
              "gu": "C7_2_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Hotel Manager",
              "hi": "होटल के प्रबंधक",
              "ka": "ಹೊಟೇಲ್ ಮ್ಯಾನೇಜರ್",
              "te": "హోటల్ మేనేజర్",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜର",
              "as": "হোটেল মেনেজাৰ",
              "gu": "હોટેલ મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I will now be handing out your job cards, and they will have information regarding where you will be working this week.",
              "hi": "मैं अब आपको जॉब कार्ड सौंप रहा हूं, और उसमें आप इस सप्ताह कहां काम करेंगे, इससे संबंधित जानकारी होगी।",
              "ka": "ನಾನು ಈಗ ನಿಮ್ಮ ಜಾಬ್ ಕಾರ್ಡ್‌ಗಳನ್ನು ಹಸ್ತಾಂತರಿಸುತ್ತೇನೆ ಮತ್ತು ಈ ವಾರ ನೀವು ಎಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತೀರಿ ಎನ್ನುವುದರ ಕುರಿತು ಅವರು ಮಾಹಿತಿಯನ್ನು ಹೊಂದಿರುತ್ತಾರೆ.",
              "te": "నేను ఇప్పుడు మీ జాబ్ కార్డ్ లను అందజేస్తాను మరియు ఈ వారం మీరు ఎక్కడ పని చేస్తారనే దాని గురించి వారికి సమాచారం ఉంటుంది.",
              "or": "ମୁଁ ବର୍ତ୍ତମାନ ତୁମର ଜବ କାର୍ଡ ଗୁଡିକୁ ବାହାର କରିବି, ଏବଂ ସେଗୁଡିକରେ ଏହି ସପ୍ତାହରେ\n ତୁମେ କେଉଁଠାରେ କାମ କରିବ ସେହି ବିଷୟରେ ସୂଚନା ଥିବା।",
              "as": "মই এতিয়া তোমাৰ চাকৰিৰ কাৰ্ড দিম, আৰু এই সপ্তাহত তুমি ক'ত কাম কৰিবা সেই সম্পৰ্কে তেওঁলোকৰ ওচৰত তথ্য থাকিব।",
              "gu": "હું હવે તારા નોકરીના કાર્ડ્સ આપીશ, અને તું આ અઠવાડિયે ક્યાં કામ કરીશ તે અંગેની માહિતી તેમની પાસે હશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_2_3_reveal",
              "hi": "C7_2_3_रिवील ",
              "ka": "C7_2_3_reveal",
              "te": "C7_2_3_reveal",
              "or": "C7_2_3_reveal",
              "as": "C7_2_3_reveal",
              "gu": "C7_2_3_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar gets his card from the hotel manager. Click to see his responsibility for the week.",
              "hi": "शिखर को होटल मैनेजर से अपना कार्ड मिलता है। सप्ताह के लिए उसकी जिम्मेदारी देखने के लिए क्लिक करें।",
              "ka": "ಶಿಖರ್ ಹೋಟೆಲ್ ಮ್ಯಾನೇಜರ್‌ನಿಂದ ಕಾರ್ಡ್ ಪಡೆಯುತ್ತಾನೆ. ವಾರದ ಅವನ ಜವಾಬ್ದಾರಿಯನ್ನು ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "హోటల్ మేనేజర్ నుండి శిఖర్ కార్డు పొందాడు. వారంలో అతని బాధ్యతను చూడటానికి క్లిక్ చేయండి.",
              "or": "ଶିଖର ମ୍ୟାନେଜର ଠାରୁ ନିଜ କାର୍ଡ ନେଲା। ଏହି ସପ୍ତାହ ରେ ତାର କାମର \n ଦାଇତ୍ଵକୁ ଦେଖିବା ପାଇଁ କ୍ଲିକ କରନ୍ତୁ।",
              "as": "শিখৰে হোটেল মেনেজাৰৰ পৰা তেওঁৰ কাৰ্ডখন পাইছিল। সপ্তাহটোৰ বাবে তেওঁৰ দায়িত্ব চাবলৈ ক্লিক কৰক।",
              "gu": "શિખરને હોટેલ મેનેજર પાસેથી તેનું કાર્ડ મળે છે. અઠવાડિયા માટે તેમની જવાબદારી જોવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Shikhar - Week 1",
              "hi": "शिखर - सप्ताह 1",
              "ka": "ಶಿಖರ್ - ವಾರ 1",
              "te": "శిఖర్ - 1వ వారం",
              "or": "ଶିଖର-ପ୍ରଥମ ସପ୍ତାହ",
              "as": "শিখৰ - সপ্তাহ 1",
              "gu": "શિખર - અઠવાડિયું 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "--- HOUSEKEEPING ---",
              "hi": "--- हाउस कीपिंग ---",
              "ka": "--- ಮನೆಗೆಲಸ ---",
              "te": "హౌస్ కీపింగ్",
              "or": "ହାଉସ କିପିଙ୍ଗ",
              "as": "--- হাউচকিপিং ---",
              "gu": "--- હાઉસકીપિંગ ---"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_3_1//",
              "hi": "C7_3_1 //",
              "ka": "C7_3_1//",
              "te": "C7_3_1//",
              "or": "C7_3_1//",
              "as": "C7_3_1//",
              "gu": "C7_3_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "A few days later, Shikhar is tidying up a guest's room, and the hotel manager is observing him.",
              "hi": "कुछ दिनों बाद, शिखर अतिथि के कमरे की साफ़ सफाई कर रहा है, और होटल प्रबंधक उसे देख रहा है।",
              "ka": "ಕೆಲವು ದಿನಗಳ ನಂತರ, ಶಿಖರ್ ಅತಿಥಿಯ ಕೋಣೆಯನ್ನು ಅಚ್ಚುಕಟ್ಟು ಮಾಡುತ್ತಿದ್ದಾನೆ ಮತ್ತು ಹೋಟೆಲ್ ವ್ಯವಸ್ಥಾಪಕರು ಅವನನ್ನು ಗಮನಿಸುತ್ತಿದ್ದಾರೆ.",
              "te": "కొన్ని రోజుల తరువాత, శిఖర్ అతిథి గదిని సర్దుతున్నాడు, హోటల్ మేనేజర్ అతనిని గమనిస్తున్నాడు.",
              "or": "କିଛି ଦିନ ପରେ, ଶିଖର ଜଣେ ଅତିଥିଙ୍କ ରୁମକୁ ସଫା କରୁଥିଲା ଓ ତା ମ୍ୟାନେଜର \n ତାକୁ ଅବଲୋକନ କରୁଥିଲା।",
              "as": "কেইদিনমান পিছত, শিখৰে আলহীৰ কোঠাটো চিজিল কৰি আছে, আৰু হোটেলৰ মেনেজাৰে তেওঁক লক্ষ্য কৰি আছে।",
              "gu": "થોડા દિવસો પછી, શિખર એક મહેમાનના રૂમને વ્યવસ્થિત કરી રહ્યો છે, અને હોટેલ મેનેજર તેનું નિરીક્ષણ કરી રહ્યો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Hotel Manager",
              "hi": "होटल प्रबंधक",
              "ka": "ಹೊಟೇಲ್ ಮ್ಯಾನೇಜರ್",
              "te": "హోటల్ మేనేజర్",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜର",
              "as": "হোটেল মেনেজাৰ",
              "gu": "હોટેલ મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Hotel Manager",
              "hi": "होटल प्रबंधक",
              "ka": "ಹೊಟೇಲ್ ಮ್ಯಾನೇಜರ್",
              "te": "హోటల్ మేనేజర్",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜର",
              "as": "হোটেল মেনেজাৰ",
              "gu": "હોટેલ મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "What are you doing? You're entirely messing up his luggage!",
              "hi": "तुम क्या कर रहे हो? तुम पूरी तरह से सामान को अस्त व्यस्त कर रहे हो!",
              "ka": "ನೀನು ಏನು ಮಾಡುತ್ತಿರುವೆ? ನೀನು ಆತನ ಸಾಮಾನುಗಳನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಅವ್ಯವಸ್ಥೆಗೊಳಿಸುತ್ತಿದ್ದೀಯಾ!",
              "te": "నువ్వు ఏమి చేస్తున్నావు? నువ్వు అతని సామాను పూర్తిగా పాడు చేస్తున్నావు!",
              "or": "ତୁମେ କଣ କରୁଛ? ତୁମେ ସଂପୂର୍ଣ୍ଣ ଭାବେ ତାର ସାମାନ କୁ ଗୋଳିଆମିଶା \n କରୀଦଉଚ!",
              "as": "তুমি কি কৰিছা? তুমি তেওঁৰ লাগেজ সম্পূৰ্ণৰূপে খেলিমেলি কৰি আছা",
              "gu": "તું શું કરે છે? તમે તેના સામાનને સંપૂર્ણપણે ગડબડ કરી રહ્યાં છો!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I think I misunderstood how you wanted me to arrange it.",
              "hi": "मुझे लगता है कि मैंने गलत समझा कि आप मुझसे किस तरह व्यवस्थित कराना चाहते थे।",
              "ka": "ನಾನು ಅದನ್ನು ಹೇಗೆ ವ್ಯವಸ್ಥೆ ಮಾಡಬೇಕೆಂದು ನೀವು ಬಯಸುತ್ತೀರಿ ಎನ್ನುವುದನ್ನು ನಾನು ತಪ್ಪಾಗಿ ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೇನೆ ಎಂದು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "నువ్వు దీన్ని ఎలా ఏర్పాటు చేయాలనుకుంటున్నావో నేను తప్పుగా అర్థం చేసుకున్నాను.",
              "or": "ମୁଁ ଭାବୁଛି, ଆପଣ ମତେ କିପରି ଏହି ସାମାନ କୁ ସଜାଡିବାକୁ କହିଲେ ସେଇଟାକୁ ମୁ \n ଠିକରେ ବୁଝିପାରିନି ।",
              "as": "মই ভাবো মই ভুল বুজিছিলো তুমি কেনেকৈ মোক এইটো সজোৱাটো বিচাৰিছিলা।",
              "gu": "મને લાગે છે કે તમે મને કેવી રીતે ગોઠવવા માંગો છો તે મને ગેરસમજ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "The guest will be very unhappy if they see this. These mistakes affect the hotel's reputation.",
              "hi": "यदि अतिथि इसे देखते हैं तो वे बहुत दुखी होंगे। ये गलतियाँ होटल की प्रतिष्ठा को प्रभावित करती हैं।",
              "ka": "ಅತಿಥಿಗಳು ಇದನ್ನು ನೋಡಿದರೆ ತುಂಬಾ ಅಸಮಾಧಾನಗೊಳ್ಳುತ್ತಾರೆ. ಈ ತಪ್ಪುಗಳು ಹೋಟೆಲ್ ಖ್ಯಾತಿಯ ಮೇಲೆ ಪರಿಣಾಮವನ್ನು ಬೀರುತ್ತವೆ.",
              "te": "ఇది చూస్తే అతిథి చాలా అసంతృప్తి చెందుతారు. ఈ తప్పులు హోటల్ ప్రతిష్టను ప్రభావితం చేస్తాయి.",
              "or": "ଅତିଥି ଏହାକୁ ଦେଖି ବେସୀ ଖୁସି ହେବେ ନହିଁ। ଏହି ଭଳି ଭୁଲ ହୋଟେଲ ର ସମ୍ମାନ ରେ ଆଞ୍ଚ ଆଣିବ।",
              "as": "অতিথিজনে এইটো দেখিলে বৰ অসন্তুষ্ট হ'ব। এই ভুলবোৰে হোটেলখনৰ সুনামত প্ৰভাৱ পেলায়।",
              "gu": "જો મહેમાન આ જોશે તો તે ખૂબ જ નાખુશ થશે. આ ભૂલો હોટલની પ્રતિષ્ઠાને અસર કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "Sorry, sir, I'll fix it right away.",
              "hi": "क्षमा करें, महोदय, मैं इसे तुरंत ठीक कर दूंगा।",
              "ka": "ಕ್ಷಮಿಸಿ, ಸರ್, ನಾನು ಅದನ್ನು ತಕ್ಷಣ ಸರಿಪಡಿಸುತ್ತೇನೆ.",
              "te": "క్షమించండి సార్, నేను వెంటనే సరి చేస్తాను.",
              "or": "ମୁଁ ଦୁଖିତ ସାର, ମୁଁ ଏବେ ହିଁ ଏହାକୁ ଠିକ କରିଦେବି ।",
              "as": "দুঃখিত, ছাৰ, মই ইয়াক লগে লগে ঠিক কৰি দিম।",
              "gu": "માફ કરશો, સાહેબ, હું તેને તરત જ ઠીક કરીશ."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_3_2",
              "hi": "C7_3_2",
              "ka": "C7_3_2",
              "te": "C7_3_2",
              "or": "C7_3_2",
              "as": "C7_3_2",
              "gu": "C7_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar quietly writes in his diary that night about how difficult it is to work in this field as it is challenging to keep customers happy all the time.",
              "hi": "शिखर चुपचाप अपनी डायरी में लिखता है कि इस क्षेत्र में काम करना कितना कठिन है क्योंकि ग्राहकों को हर समय खुश रखना चुनौतीपूर्ण है।",
              "ka": "ಗ್ರಾಹಕರನ್ನು ಸದಾ ಖುಷಿಯಾಗಿಡುವುದು ಸವಾಲಿನ ಕೆಲಸವಾಗಿರುವುದರಿಂದ ಈ ಕ್ಷೇತ್ರದಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದು ಎಷ್ಟು ಕಷ್ಟ ಎಂದು ಶಿಖರ್ ಆ ರಾತ್ರಿ ತನ್ನ ಡೈರಿಯಲ್ಲಿ ಸದ್ದಿಲ್ಲದೆ ಬರೆದುಕೊಂಡಿದ್ದಾನೆ.",
              "te": "కొనుగోలుదారులను ఎల్లవేళలా సంతోషంగా ఉంచడం సవాలుతో కూడుకున్నది ఈ రంగంలో పని చేయడం ఎంత కష్టమో శిఖర్ ఆ రాత్రి తన డైరీలో నిశ్శబ్దంగా రాసుకున్నాడు.",
              "or": "ଶିଖର ସେହି ରାତିରେ ନୀରବ ରେ ନିଜ ଡାଏରୀ ରେ ଲେଖିଲ କି ଏହି କାମ କେତେ କଷ୍ଟ\n ଯେହେତୁ ଏଠାରେ ସବୁବେଳେ ଗ୍ରାହକ ମାନଙ୍କୁ ଖୁସି କରିବାକୁ ହୁଏ।",
              "as": "শিখৰে সেই ৰাতি তেওঁৰ ডায়েৰীত মনে মনে লিখিছিল যে এই ক্ষেত্ৰত কাম কৰাটো কিমান টান কিয়নো গ্ৰাহকসকলক সকলো সময়তে সুখী ৰখাটো প্ৰত্যাহ্বানজনক।",
              "gu": "શિખર તે રાત્રે શાંતિથી તેની ડાયરીમાં લખે છે કે આ ક્ષેત્રમાં કામ કરવું કેટલું મુશ્કેલ છે કારણ કે ગ્રાહકોને હંમેશા ખુશ રાખવા પડકારરૂપ છે."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_2_4_reveal",
              "hi": "C7_2_4_रिवील ",
              "ka": "C7_2_4_reveal",
              "te": "C7_2_4_రివీల్ (బహిర్గతం)",
              "or": "C7_2_4_reveal",
              "as": "C7_2_4_reveal",
              "gu": "C7_2_4_reveal"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar gets assigned a new role the following week. Click to see.",
              "hi": "अगले सप्ताह शिखर को एक नई भूमिका सौंपी गई। देखने के लिए क्लिक करें।",
              "ka": "ಮುಂದಿನ ವಾರದಲ್ಲಿ ಶಿಖರ್‌ಗೆ ಹೊಸ ಕೆಲಸವನ್ನು ನಿಯೋಜಿಸಲಾಗಿದೆ. ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "మరుసటి వారం శిఖర్‌ కి కొత్త పాత్ర కేటాయించబడుతుంది. చూడటానికి క్లిక్ చేయండి (నొక్కండి).",
              "or": "ଏହି ସପ୍ତାହରେ ଶିଖରକୁ ଏକ ନୂଆ ଭୂମିକା ମିଳେ । ଏଠାରେ କ୍ଲିକ କରନ୍ତୁ।",
              "as": "শিখৰে পিছৰ সপ্তাহত এটা নতুন কাম পায়। চাবলৈ ক্লিক কৰা।",
              "gu": "આગલા અઠવાડિયે શિખરને નવી ભૂમિકા સોંપવામાં આવશે. જોવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Shikhar - Week 2",
              "hi": "शिखर - सप्ताह 2",
              "ka": "ಶಿಖರ್ - ವಾರ 2",
              "te": "శిఖర్ - 2వ వారం",
              "or": "ଶିଖର - ଦ୍ଵିତୀୟ ସପ୍ତାହ",
              "as": "শিখৰ - সপ্তাহ 2",
              "gu": "શિખર - અઠવાડિયું 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "----KITCHEN----",
              "hi": "----किचेन----",
              "ka": "----KITCHEN----",
              "te": "----వంటగది----",
              "or": "ରୋଷେଇ ଘର",
              "as": "----পাকঘৰ----",
              "gu": "----રસોડું----"
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_4_1//",
              "hi": "C7_4_1 //",
              "ka": "C7_4_1//",
              "te": "C7_4_1//",
              "or": "C7_4_1//",
              "as": "C7_4_1//",
              "gu": "C7_4_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Head Chef",
              "hi": "मुख्य रसोइया",
              "ka": "ಮುಖ್ಯ ಬಾಣಸಿಗ",
              "te": "హెడ్ చెఫ్",
              "or": "ମୁଖ୍ୟ ରୋଷେୟା",
              "as": "মুখ্য ৰান্ধনী",
              "gu": "મુખ્ય રસોઇયા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Head Chef",
              "hi": "मुख्य रसोइया",
              "ka": "ಮುಖ್ಯ ಬಾಣಸಿಗ",
              "te": "హెడ్ చెఫ్",
              "or": "ମୁଖ୍ୟ ରୋଷେୟା",
              "as": "মুখ্য ৰান্ধনী",
              "gu": "મુખ્ય રસોઇયા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "That's good, Shikhar! You've learnt this new technique of baking very quickly.",
              "hi": "यह अच्छा है, शिखर! आपने बहुत जल्दी बेकिंग की इस नई तकनीक को सीखा है।",
              "ka": "ಚೆನ್ನಾಗಿದೆ ಶಿಖರ್! ನೀನು ಬೇಯಿಸುವ ಈ ಹೊಸ ತಂತ್ರವನ್ನು ಬೇಗನೆ ಕಲಿತಿದ್ದೀಯಾ",
              "te": "బాగుంది శిఖర్! మీరు ఈ కొత్త బేకింగ్ టెక్నిక్‌ని చాలా త్వరగా నేర్చుకున్నారు.",
              "or": "ବହୁତ ବଢିଆ , ଶିଖର ! ତୁମେ ରାନ୍ଧିବାର ଏହି ପ୍ରକ୍ରିୟା ଟିକୁ ବହୁତ ଜଲଦି ଶିଖିଗଲ।",
              "as": "এইটো ভাল, শিখৰ! তুমি অতি সোনকালে বেকিং কৰাৰ এই নতুন কৌশলটো শিকিছা।",
              "gu": "તે સારું છે, શિખર! તું પકવવાની આ નવી રીત ખૂબ જ ઝડપથી શીખી ગયા છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Thanks! Though I would say standing for so long in front of the oven's heat isn't easy.",
              "hi": "धन्यवाद! हालांकि मैं कहूंगा कि ओवन की गर्मी के सामने इतने लंबे समय तक खड़ा रहना सरल नहीं है।",
              "ka": "ಧನ್ಯವಾದಗಳು! ಒಲೆಯ ಬಿಸಿಯ ಮುಂದೆ ತುಂಬಾ ಹೊತ್ತು ನಿಲ್ಲುವುದು ಸುಲಭವಲ್ಲ ಎನ್ನುವುದು ನನ್ನ ಅನಿಸಿಕೆ",
              "te": "ధన్యవాదాలు! ఓవెన్ వేడి ముందు చాలా సేపు నిలబడటం అంత సులభం కాదని నేను అంటాను.",
              "or": "ଧନ୍ୟବାଦ! ତେବେ ମୁଁ କହିବି ଯେ ଓଭାନ ର ତାତି ସାମ୍ନା ରେ ଏତେ ସମୟ ଛିଡା \n ହେବ ସହଜ ନୁହେଁ।",
              "as": "ধন্যবাদ! তথাপিও মই ক'ম যে অভেনৰ তাপৰ সন্মুখত ইমান দীঘলীয়া সময় থিয় হৈ থকাটো সহজ নহয়।",
              "gu": "આભાર! જો કે હું કહીશ કે પકાવવાની નાની ભઠ્ઠીની ગરમી સામે આટલા લાંબા સમય સુધી ઊભા રહેવું સરળ નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Even though I had problems early on, I eventually got used to it.",
              "hi": "भले ही मुझे शुरु में समस्याएं थीं, फिर मुझे इसकी आदत हो गई।",
              "ka": "ನಾನು ಆರಂಭದಲ್ಲಿ ಸಮಸ್ಯೆಗಳನ್ನು ಹೊಂದಿದ್ದರೂ ಸಹ, ನಾನು ಅಂತಿಮವಾಗಿ ಅದಕ್ಕೆ ಹೊಂದಿಕೊಂಡಿದ್ದೇನೆ..",
              "te": "నాకు ప్రారంభంలో సమస్యలు వచ్చినప్పటికీ, చివరికి నేను దానికి అలవాటు పడ్డాను.",
              "or": "ପ୍ରଥମରୁ ଏହା ମୋ ପାଇଁ ମଧ୍ୟ ସହଜ ନଥିଲା, କ୍ରମଶଃ ଏହା ମୋ ଅଭ୍ୟାସରେ \n ପରିଣତ ହୋଇଗଲା।",
              "as": "যদিও আৰম্ভণিতে মোৰ সমস্যা হৈছিল, শেষলৈ মই ইয়াত অভ্যস্ত হৈ পৰিছিলো।",
              "gu": "ભલે મને શરૂઆતમાં સમસ્યાઓ હતી, પણ આખરે મને તેની આદત પડી ગઈ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "How did you get used to it?",
              "hi": "आपको इसकी आदत कैसे हुई?",
              "ka": "ನೀನು ಅದಕ್ಕೆ ಹೇಗೆ ಹೊಂದಿಕೊಂಡೆ?",
              "te": "మీరు ఎలా అలవాటు పడ్డారు?",
              "or": "କେମିତି ଏହା ଆପଣଙ୍କ ଅଭ୍ୟାସ ରେ ପରିଣତ ହୁଏ?",
              "as": "তুমি ইয়াত কেনেকৈ অভ্যস্ত হ'লা?",
              "gu": "તમને તેની આદત કેવી રીતે પડી?"
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_4_2////",
              "hi": "C7_4_2 ////////",
              "ka": "C7_4_2////",
              "te": "C7_4_2////",
              "or": "C7_4_2////",
              "as": "C7_4_2////",
              "gu": "C7_4_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I had no choice. If you like it, you have to manage it in some way or another. The role requires working for 14 - 16 hours, running around taking orders, preparing the menu, managing the stock of ingredients and so on.",
              "hi": "मेरे पास कोई विकल्प नही था। यदि आपको यह पसंद है, तो आपको इसे किसी न किसी तरीके से संभालना होगा। इस काम  में 14 - 16 घंटे के लिए काम करने की आवश्यकता होती है, ऑर्डर लेने, मेनू तैयार करने, सामग्री के स्टॉक का प्रबंधन करने और ऐसे सब काम।",
              "ka": "ನನಗೆ ಬೇರೆ ಆಯ್ಕೆ ಇರಲಿಲ್ಲ. ಅದನ್ನು ಇಷ್ಟಪಟ್ಟರೆ, ನೀನು ಅದನ್ನು ಒಂದಲ್ಲಾ ಒಂದು ರೀತಿಯಲ್ಲಿ ನಿರ್ವಹಿಸಬೇಕು. ಪಾತ್ರಕ್ಕೆ 14 - 16 ಗಂಟೆಗಳ ಕಾಲ ಕೆಲಸ ಮಾಡುವ ಅಗತ್ಯವಿರುತ್ತದೆ, ಆರ್ಡರ್ಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಓದುವುದು, ಮೆನುವನ್ನು ಸಿದ್ಧಪಡಿಸುವುದು, ಪದಾರ್ಥಗಳ ಸ್ಟಾಕ್ ಅನ್ನು ನಿರ್ವಹಿಸುವುದು ಇತ್ಯಾದಿ.",
              "te": "నాకు వేరే మార్గం లేదు. నీకు నచ్చితే, నువ్వు దానిని ఏదో ఒక విధంగా నిర్వహించాలి. ఈ పాత్రకు 14 - 16 గంటల పాటు పని చేయడం, ఆర్డర్లు తీసుకోవడం, మెనూని సిద్ధం చేయడం, పదార్థాల స్టాక్‌ ను చూడడం మరియు మొదలైనవి అవసరం.",
              "or": "ମୋ ପାଖରେ ଅନ୍ୟକିଛି ବିକଳ୍ପ ନଥିଲା, ତୁମକୁ କିଛି ନା କିଛି ଉପାୟେରେ ଚଳେଇବାକୁ \n ପଡିବ। ତୁମକୁ ୧୪-୧୬ ଘଣ୍ଟା ଯାଏଁ କାମ କରିବାକୁ ପଡିବ, ଲୋକଙ୍କ ପାଖକୁ ଅର୍ଡର ଆଣିବାକୁ ଯିବାକୁ ପଡିବ, ଖାଦ୍ୟର ସୂଚୀ ଆନୁସରେ ରାନ୍ଧିବାକୁ ପଡିବ, ଓ ସାମଗ୍ରୀର ଉପସ୍ଥିତି କୁ ମଧ୍ୟ ତଦାରଖ କରିବାକୁ ପଡିବ ଓ ଆହୁରି ବହୁତ କିଛି।",
              "as": "মোৰ কোনো বিকল্প নাছিল। যদি তুমি এইটো ভাল পোৱা, তুমি ইয়াক যিকোনো ধৰণে চলাব লাগিব। কামটোত 14-16 ঘণ্টা কাম কৰা, অৰ্ডাৰ লোৱা, মেনু প্ৰস্তুত কৰা, উপাদানবোৰৰ ষ্টক চোৱা-চিতা কৰা আদিৰ প্ৰয়োজন।",
              "gu": "મારી પાસે કોઈ વિકલ્પ નહોતો. જો તને તે ગમે છે, તો તમારે તેને કોઈને કોઈ રીતે મેનેજ કરવું પડશે. ભૂમિકા માટે 14 - 16 કલાક કામ કરવું, ઓર્ડર લેવા માટે દોડવું, મેનૂ તૈયાર કરવું, ઘટકોના સ્ટોકનું સંચાલન કરવું વગેરે જરૂરી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Oh! It is not as easy as it seems!",
              "hi": "ओह! ऐसा लगता है कि यह उतना सरल नहीं है!",
              "ka": "ಓಹ್! ಇದು ಅಂದುಕೊಂಡಷ್ಟು ಸುಲಭವಲ್ಲ!",
              "te": "ఓ! ఇది కనిపించినంత సులభం కాదు!",
              "or": "ଓଃହ! ଏହା ଯେତିକି ସହଜ ଲାଗୁଛି ସେତିକି ସହଜ ନୁହେଁ!",
              "as": "ওহ! এইটো যিমান সহজ যেন লাগে সিমান সহজ নহয়",
              "gu": "ઓહ! તે લાગે છે તેટલું સરળ નથી!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Haha yes. But you're a quick learner Shikhar. \nI think you should decide on the breakfast menu for tomorrow.",
              "hi": "हा - हा हाँ। लेकिन आप जल्दी सीख लेते हैं, शिखर ।\nमुझे लगता है कि आपको कल के लिए नाश्ते का मेनू तय करना चाहिए।",
              "ka": "ಹಹ ಹೌದು. ಆದರೆ ಶಿಖರ್ ನೀನು ಬೇಗ ಕಲಿಯುವಿ. ನಾಳೆಯ ಉಪಹಾರ ಮೆನುವನ್ನು ನೀನು ನಿರ್ಧರಿಸಬೇಕು ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "హాహా అవును. అయితే నువ్వు త్వరగా నేర్చుకునేవాడివి శిఖర్.\n రేపటి బ్రేక్‌ ఫాస్ట్ మెనూని మీరు నిర్ణయించుకోవాలని నేను భావిస్తున్నాను.",
              "or": "ହାହା ହଁ, କିନ୍ତୁ ତୁମେ ବହୁତ ଜଲ୍ଦି ସିଖୁଛ ଶିଖର। ମୁଁ ଭାବୁଛି ଆସନ୍ତା କାଲି ପାଇଁ ତୁମକୁ \n ସକାଳ ଜଳଖିଆ ବିଷୟରେ ଭବିନବା ଦରକାର।",
              "as": "হাহা হয়। কিন্তু তুমি এজন তীক্ষ্ণ শিক্ষাৰ্থী শিখৰ। মই ভাবো তুমি কাইলৈৰ বাবে পুৱাৰ আহাৰৰ মেনু ঠিক কৰিব লাগে ।",
              "gu": "બરાબર. શિખર પણ તું ઝડપી શીખનાર છે. મને લાગે છે કે તારે આવતીકાલના નાસ્તાના મેનુ પર નિર્ણય લેવો જોઈએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "I'd love to!",
              "hi": "मुझे खुशी होगी!",
              "ka": "ನಾನು ಅದನ್ನು ಇಷ್ಟಪಟ್ಟು ಮಾಡುತ್ತೇನೆ!",
              "te": "నేను దీన్ని ఇష్టపడతాను!",
              "or": "ମୁଁ ନିଶ୍ଚୟ କରିବି।",
              "as": "মই ভাল পাম",
              "gu": "મને ગમશે!"
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_5_1",
              "hi": "C7_5_1",
              "ka": "C7_5_1",
              "te": "C7_5_1",
              "or": "C7_5_1",
              "as": "C7_5_1",
              "gu": "C7_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar enthusiastically writes in his diary that night about how much he enjoys working in the kitchen.",
              "hi": "शिखर उत्साहपूर्वक उस रात अपनी डायरी में लिखता है कि वह रसोई में काम करने में कितना आनंदित होता है।",
              "ka": "ಶಿಖರ್ ಉತ್ಸಾಹದಿಂದ ಆ ರಾತ್ರಿ ತನ್ನ ದಿನಚರಿಯಲ್ಲಿ ಅಡುಗೆಮನೆಯಲ್ಲಿ ಎಷ್ಟು ಆನಂದಿಸುತ್ತಿದ್ದೇನೆ ಎಂದು ಬರೆದಿದ್ದಾನೆ.",
              "te": "శిఖర్ ఆ రాత్రి తన డైరీలో కిచెన్ లో చేయడం ఎంత ఆనందాన్ని పొందుతుందో రాసుకున్నాడు.",
              "or": "ଶିଖର ବହୁତ ଉତ୍ସହ ର ସହ ସେହି ରାତିରେ ଲେଖେ କି ସେ ରୋଷେଇ ଘରେ \n କାମ କରି କେତେ ଆନନ୍ଦ ପାଏ ।",
              "as": "শিখৰে সেই ৰাতি তাৰ ডায়েৰীত ফুৰ্তিৰে লিখিছিল যে সি পাকঘৰত কাম কৰি কিমান ভাল পায়।",
              "gu": "શિખર ઉત્સાહપૂર્વક તે રાત્રે તેની ડાયરીમાં લખે છે કે તેને રસોડામાં કામ કરવામાં કેટલો આનંદ આવે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_5_2",
              "hi": "C7_5_2",
              "ka": "C7_5_2",
              "te": "C7_5_2",
              "or": "C7_5_2",
              "as": "C7_5_2",
              "gu": "C7_5_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He also feels he is good at management since the Head Chef gives him many responsibilities in the kitchen.",
              "hi": "उसे यह भी लगता है कि वह प्रबंधन में अच्छा है क्योंकि हेड शेफ उसे रसोई में अनेक जिम्मेदारियां देता है।",
              "ka": "ಮುಖ್ಯ ಬಾಣಸಿಗರು ಅವನಿಗೆ ಅಡುಗೆಮನೆಯಲ್ಲಿ ಅನೇಕ ಜವಾಬ್ದಾರಿಗಳನ್ನು ನೀಡುವುದರಿಂದ ತಾನು ನಿರ್ವಹಣೆಯಲ್ಲಿ ಉತ್ತಮ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ",
              "te": "హెడ్ ​​చెఫ్ తనకు వంటగదిలో చాలా బాధ్యతలు అప్పగిస్తున్నందున అతను మేనేజ్‌మెంట్ లో బాగా చేస్తున్నాడని కూడా అతను భావిస్తాడు.",
              "or": "ସେ ଭଲ ଅନୁଭବ କରେ ଯେବେଠାରୁ ମୁଖ୍ୟ ରୋଷେୟା ତାକୁ ରୋଷେଇ ଘରେ ତାକୁ \n ବହୁତ ଦାଇତ୍ଵ ଦିଅନ୍ତି। ।",
              "as": "সি এইটোও ভাবে যে সি মেনেজমেণ্টত ভাল কিয়নো মুখ্য ৰান্ধনীয়ে তাক পাকঘৰত বহুতো দায়িত্ব দিয়ে।",
              "gu": "તેને એવું પણ લાગે છે કે તે મેનેજમેન્ટમાં સારો છે કારણ કે મુખ્ય રસોઓ તેને રસોડામાં ઘણી જવાબદારીઓ આપે છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_2_5//",
              "hi": "C7_2_5 //",
              "ka": "C7_2_5//",
              "te": "C7_2_5//",
              "or": "C7_2_5//",
              "as": "C7_2_5//",
              "gu": "C7_2_5//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar's internship comes to an end. The hotel manager calls the interns to the lobby to congratulate them.",
              "hi": "शिखर की इंटर्नशिप समाप्ति पर आती है। होटल प्रबंधक सभी इंटर्न को बधाई देने के लिए लॉबी में बुलाता है।",
              "ka": "ಶಿಖರ್‌ನ ಇಂಟರ್ನ್‌ಶಿಪ್ ಕೊನೆಗೊಳ್ಳುತ್ತದೆ. ಹೋಟೆಲ್ ಮ್ಯಾನೇಜರ್ ಇಂಟರ್ನ್‌ಗಳನ್ನು ಅಭಿನಂದಿಸಲು ಲಾಬಿಗೆ ಕರೆಯುತ್ತಾರೆ.",
              "te": "శిఖర్ ఇంటర్న్షిప్ ముగింపు దశకు చేరుకుంది. హోటల్ మేనేజర్ ఇంటర్న్ లను అభినందించడానికి లాబీకి పిలుస్తాడు.",
              "or": "ଶିଖର ର ଇଣ୍ଟର୍ନ୍ସିପ ଶେଷ ହୁଏ । ହୋଟେଲ ମ୍ୟାନେଜର ସମସ୍ତ ଇଣ୍ଟର୍ଣ ଙ୍କୁ \n ଲବି କୁ ଡାକି ବଧେଇ ଦିଅନ୍ତି ।",
              "as": "শিখৰৰ ইন্টাৰ্ণশ্বিপ শেষ হৈছে । হোটেলৰ মেনেজাৰে ইণ্টাৰ্ণসকলক শুভেচ্ছা জনাবলৈ লবীলৈ মাতিছিল।",
              "gu": "શિખરની ઇન્ટર્નશિપ પૂરી થાય છે. હોટેલ મેનેજર ઇન્ટર્નને અભિનંદન આપવા માટે લોબીમાં બોલાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Hotel Manager",
              "hi": "होटल प्रबंधक",
              "ka": "ಹೊಟೇಲ್ ಮ್ಯಾನೇಜರ್",
              "te": "హోటల్ మేనేజర్",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜର",
              "as": "হোটেল মেনেজাৰ",
              "gu": "હોટેલ મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "So finally, your internship period is over. We are pleased with all of you and how much you have learned this past month!",
              "hi": "तो अंत में, आपकी इंटर्नशिप का समय समाप्त हुआ। हम आप सभी से और आप सबने पिछले महीने कितना सीखा है दोनों से प्रसन्न हैं!",
              "ka": "ಆದ್ದರಿಂದ ಅಂತಿಮವಾಗಿ, ನಿಮ್ಮ ಇಂಟರ್ನ್‌ಶಿಪ್ ಅವಧಿ ಮುಗಿದಿದೆ. ನಿಮ್ಮೆಲ್ಲರ ಬಗ್ಗೆ ನಮಗೆ ಸಂತೋಷವಾಗಿದೆ ಮತ್ತು ಕಳೆದ ತಿಂಗಳು ನೀವು ಎಷ್ಟು ಕಲಿತಿದ್ದೀರಿ!",
              "te": "కాబట్టి చివరకు, మీ ఇంటర్న్‌ షిప్‌ కాలం ముగిసింది. మీ అందరితో మరియు గత నెలలో మీరు ఎంత నేర్చుకున్నవాటితో మేము సంతోషిస్తున్నాము !",
              "or": "ତେବେ ଅନ୍ତରେ, ତୁମମାନଙ୍କର ଇଣ୍ଟର୍ନ୍ସିପ ଅବଧି ଶେଷ ହୋଇଛି । ଆମେ ତୁମମାନଙ୍କ କାମରେ ଏବଂ ଯାହା ସବୁ ଗତ କିଛି ମାସରେ ଶିଖିଚ ,ସେଥିରେ ଖୁସି।",
              "as": "গতিকে অৱশেষত, তোমালোকৰ ইণ্টাৰ্ণশ্বিপৰ ম্যাদ শেষ হৈছে। আমি তোমালোক সকলোৰে ওপৰত আৰু যোৱা মাহত তোমালোকে যিমান শিকিছা তাত সন্তুষ্ট।",
              "gu": "આખરે, તમારી ઇન્ટર્નશિપ અવધિ સમાપ્ત થઈ ગઈ છે. અમે તમારા બધાથી ખુશ છીએ અને તમે આ પાછલા મહિનામાં કેટલું શીખ્યા છો!"
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_2_6_internshipcertificate",
              "hi": "C7_2_6_इंटर्नशिपसर्टिफिकेट ",
              "ka": "C7_2_6_internshipcertificate",
              "te": "C7_2_6_ఇంటర్న్‌ షిప్‌ సర్టిఫికేట్",
              "or": "C7_2_6_internshipcertificate",
              "as": "C7_2_6_internshipcertificate",
              "gu": "C7_2_6_ઇન્ટર્નશિપ પ્રમાણપત્ર"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He hands them their internship certificates. Shikhar receives an envelope.",
              "hi": "वह उन्हें उनके इंटर्नशिप प्रमाण पत्र देता है। शिखर को एक लिफाफा मिलता है।",
              "ka": "ಅವರು ಇಂಟರ್ನ್‌ಶಿಪ್ ಪ್ರಮಾಣಪತ್ರಗಳನ್ನು ಹಸ್ತಾಂತರಿಸುತ್ತಾರೆ. ಶಿಖರ್ ಒಂದು ಲಕೋಟೆಯನ್ನು ಸ್ವೀಕರಿಸುತ್ತಾನೆ.",
              "te": "అతను వారి ఇంటర్న్షిప్ సర్టిఫికేట్‌లను వారికి అందజేస్తాడు. శిఖర్ ఒక కవరు అందుకున్నాడు.",
              "or": "ସେ ସମସ୍ତଙ୍କୁ ସେମାନଙ୍କର ଇଣ୍ଟର୍ନ୍ସିପ ସାର୍ଟିଫିକେଟ ଦିଅନ୍ତି । ଶିଖର ନିଜର ଏନଭଲପ \n ଗ୍ରହଣ କରେ।",
              "as": "তেওঁ সিহঁতক সিহঁতৰ ইন্টাৰ্ণশ্বিপ প্ৰমাণপত্ৰ দিয়ে। শিখৰে এটা খাম পায়।",
              "gu": "તે તેમને તેમના ઇન્ટર્નશિપ પ્રમાણપત્રો આપે છે. શિખરને એક પરબિડીયું મળે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Internship Certificate",
              "hi": "इंटर्नशिप प्रमाणपत्र",
              "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಪ್ರಮಾಣಪತ್ರ",
              "te": "ఇంటర్న్‌ షిప్‌ సర్టిఫికేట్",
              "or": "ଇଣ୍ଟର୍ନ୍ସିପ ପ୍ରମାଣ ପତ୍ର",
              "as": "ইন্টাৰ্ণশ্বিপ প্ৰমাণপত্ৰ",
              "gu": "ઇન્ટર્નશિપ પ્રમાણપત્ર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Tasks",
              "hi": "टास्क ",
              "ka": "ಕೆಲಸಗಳು",
              "te": "పనులు",
              "or": "ଟାସ୍କ",
              "as": "কাৰ্য্যসমূহ",
              "gu": "કાર્યો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "**4 Seasons Hotel\nIndore**\n\n\nJuly, 2021\n\n\n**Internship Certificate**\n\nThis is to confirm that Mr Shikhar has completed this internship program at 4 Seasons Hotel, Indore, India.\n\nWe wish to appreciate his work and wish him success in his career on behalf of the hotel.\n\n\nVikas Khanna\nSenior Hotel Manager",
              "hi": "** 4 सीज़न होटल\nइंदौर **\n\n\nजुलाई, 2021\n\n\n** इंटर्नशिप प्रमाणपत्र **\n\nयह पुष्टि करना है कि श्री शिखर ने 4 सीज़न होटल, इंदौर, भारत में इस इंटर्नशिप कार्यक्रम को पूरा कर लिया है।\n\nहम आपके काम की सराहना करते हैं और होटल की तरफ से आपके करियर में सफलता की कामना करते हैं।\n\n\nविकास खन्ना\nवरिष्ठ होटल प्रबंधक",
              "ka": "**4 ಸೀಸನ್ಸ್ ಹೋಟೆಲ್, ಇಂದೋರ್**                                                                                                                                                                                           ಜುಲೈ, 2021                                                                                                                                                                                                                    **ಇಂಟರ್ನ್‌ಶಿಪ್ ಪ್ರಮಾಣಪತ್ರ**                                                                                                                                                                                                      ಶ್ರೀ ಶಿಖರ್ ಅವರು ಇಂದೋರ್‌ನ 4 ಸೀಸನ್ಸ್ ಹೋಟೆಲ್‌ನಲ್ಲಿ ಈ ಇಂಟರ್ನ್‌ಶಿಪ್ ಕಾರ್ಯಕ್ರಮವನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದಾರೆ ಎಂದು ಇದು.ಖಚಿತಪಡಿಸುತ್ತದೆ                                                       ನಾವು ಅವರ ಕೆಲಸವನ್ನು ಪ್ರಶಂಸಿಸಲು ಬಯಸುತ್ತೇವೆ ಮತ್ತು ಹೋಟೆಲ್ ಪರವಾಗಿ ಅವರ ವೃತ್ತಿಜೀವನದಲ್ಲಿ ಯಶಸ್ಸನ್ನು ಬಯಸುತ್ತೇವೆ.                                                                             ವಿಕಾಸ್ ಖನ್ನ                                                                                                                                                                                                                      ಸೀನಿಯರ್ ಹೊಟೇಲ್ ಮ್ಯಾನೇಜರ್",
              "te": "**4 సీజన్స్ హోటల్\n ఇండోర్**\n \n \n జూలై, 2021\n \n \n **ఇంటర్న్‌షిప్ సర్టిఫికేట్**\n \n మిస్టర్ శిఖర్ భారతదేశంలోని ఇండోర్‌లోని 4 సీజన్స్ హోటల్‌లో ఈ ఇంటర్న్‌షిప్ ప్రోగ్రామ్‌ను పూర్తి చేసినట్లు నిర్ధారించబడింది.\n \n మేము అతని పనిని అభినందిస్తున్నాము మరియు హోటల్ తరపున అతని కెరీర్‌లో విజయం సాధించాలని కోరుకుంటున్నాము.\n \n \n వికాస్ ఖన్నా\n సీనియర్ హోటల్ మేనేజర్",
              "or": "** ଇନ୍ଦୋର ହୋଟେଲ ରେ ୪ ବର୍ଷ **\n \n ଜୁଲାଇ,୨୦୨୧ \n \n **ଇଣ୍ଟେର୍ନ୍ସିପ ପ୍ରମାଣ ପତ୍ର**\n ଏହା ଜଣାଇଦିଆ ଯାଉଛି ଯେ ଶିଖର ଇନ୍ଦୋର, ଭାରତ ର ହୋଟେଲ ରେ ନିଜର ୪ ବର୍ଷର \n ଇଣ୍ଟର୍ନ୍ସିପ ପାଠ୍ୟକ୍ରମକୁ ଶେଷ କରିଛନ୍ତି। \n ହୋଟେଲ ତରଫ ରୁ ଆମେ ତାଙ୍କୁ ନିଜର କାମରେ ସଫଳ ହେବା ପାଇଁ ବଧେଇ ଜଣାଉଛୁ।\n \n ବିକାଶ ଖାନ୍ନା \n ବରିଷ୍ଠ ହୋଟେଲ ମ୍ୟାନେଜର",
              "as": "\"**4 চিজনছ হোটেল\n \n ইন্দোৰ**\n \n জুলাই, ২০২১\n \n **ইন্টাৰ্ণশ্বিপ প্ৰমাণপত্ৰ**\n \n এইটো নিশ্চিত কৰা হয় যে শ্ৰীযুত শিখৰে ভাৰতৰ ইন্দোৰৰ 4 চিজনছ হোটেলত এই ইণ্টাৰ্ণশ্বিপ কাৰ্যসূচী সম্পূৰ্ণ কৰিছে।\n আমি তেওঁৰ কামৰ প্ৰশংসা কৰিব বিচাৰোঁ আৰু হোটেলৰ হৈ তেওঁৰ কেৰিয়াৰত সফলতা কামনা কৰিছোঁ।\n \n বিকাশ খান্না\n জ্যেষ্ঠ হোটেল মেনেজাৰ\"",
              "gu": "**4 સીઝન્સ હોટેલ ઈન્દોર** જુલાઈ, 2021 **ઈન્ટર્નશીપ પ્રમાણપત્ર** આ પુષ્ટિ કરવા માટે છે કે શ્રી શિખરે આ ઈન્ટર્નશીપ પ્રોગ્રામ 4 સીઝન્સ હોટેલ, ઈન્દોર, ભારત ખાતે પૂર્ણ કર્યો છે. અમે તેમના કામની પ્રશંસા કરવા ઈચ્છીએ છીએ અને હોટેલ વતી તેમને તેમની કારકિર્દીમાં સફળતાની શુભેચ્છા પાઠવીએ છીએ. વિકાસ ખન્ના સિનિયર હોટેલ મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "He has completed one month of training in the following departments - Housekeeping, Kitchen, Management and Front Desk.",
              "hi": "उसने निम्नलिखित विभागों - हाउसकीपिंग, रसोई, प्रबंधन और फ्रंट डेस्क में एक महीने का प्रशिक्षण पूरा कर लिया है।",
              "ka": "ಆತನು ಈ ಕೆಳಗಿನ ವಿಭಾಗಗಳಲ್ಲಿ ಒಂದು ತಿಂಗಳ ತರಬೇತಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದಾರೆ - ಹೌಸ್ ಕೀಪಿಂಗ್, ಕಿಚನ್, ಮ್ಯಾನೇಜ್ಮೆಂಟ್ ಮತ್ತು ಫ್ರಂಟ್ ಡೆಸ್ಕ್.",
              "te": "అతను క్రింది విభాగాలలో ఒక నెల శిక్షణను పూర్తి చేసాడు - హౌస్ కీపింగ్, కిచెన్, మేనేజ్‌మెంట్ మరియు ఫ్రంట్ డెస్క్.",
              "or": "ଏହି ସବୁ ବିଭାଗରେ ସେ ନିଜର ମାସିକିଆ ପ୍ରଶିକ୍ଷଣ ଶେଷ କଲା- ହାଉସ କିପିଙ୍ଗ,\n କିଚେନ, ମ୍ୟାନେଜମେଣ୍ଟ, ଫ୍ରଣ୍ଟ ଡେସ୍କ ।",
              "as": "সি তলৰ বিভাগবোৰত এমাহৰ প্ৰশিক্ষণ সম্পূৰ্ণ কৰিছে - হাউচকিপিং, কিচেন, মেনেজমেণ্ট আৰু ফ্ৰণ্ট ডেস্ক।",
              "gu": "તેણે નીચેના વિભાગો - હાઉસકીપિંગ, કિચન, મેનેજમેન્ટ અને ફ્રન્ટ ડેસ્કમાં એક મહિનાની તાલીમ પૂર્ણ કરી છે."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "Internship",
              "hi": "इंटर्नशिप",
              "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್‌",
              "te": "ఇంటర్న్‌షిప్",
              "or": "ଇଣ୍ଟେର୍ନ୍ସିପ",
              "as": "ইন্টাৰ্ণশ্বিপ",
              "gu": "ઇન્ટર્નશિપ"
            }
          }
        ]
      },
      "scene32": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C8_1_1",
              "hi": "C8_1_1",
              "ka": "C8_1_1",
              "te": "C8_1_1",
              "or": "C8_1_1",
              "as": "C8_1_1",
              "gu": "C8_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar had a good internship experience. It was tough, but he learnt a lot. He also got a better understanding of what he wanted to do.",
              "hi": "शिखर के पास एक अच्छा इंटर्नशिप अनुभव था। यह कठिन था, लेकिन उसने बहुत कुछ सीखा। उसे यह भी अच्छी तरह समझ आ गया कि वह क्या करना चाहता था।",
              "ka": "ಶಿಖರ್‌ಗೆ ಉತ್ತಮ ಇಂಟರ್ನ್‌ಶಿಪ್ ಅನುಭವವನ್ನು ಗಳಿಸಿದ್ದಾನೆ. ಇದು ಕಠಿಣವಾಗಿತ್ತು, ಆದರೆ ಆತನು ಬಹಳಷ್ಟು ಕಲಿತಿದ್ದಾನೆ. ಆತನು ಏನು ಮಾಡಲು ಬಯಸುತ್ತಾನೆ ಎನ್ನುವುದರ ಬಗ್ಗೆ ಉತ್ತಮ ತಿಳುವಳಿಕೆಯನ್ನು ಸಹ ಪಡೆಡಿದ್ದಾನೆ",
              "te": "శిఖర్‌కి మంచి ఇంటర్న్‌షిప్ అనుభవం ఉంది. ఇది చాలా కష్టం, కానీ అతను చాలా నేర్చుకున్నాడు. అతను ఏమి చేయాలనుకుంటున్నాడో కూడా అతనికి మంచి అవగాహన వచ్చింది.",
              "or": "ଶିଖର ର ଏକ ଭଲ ଇଣ୍ଟର୍ନ୍ସିପ ର ଅନୁଭବ ଥିଲା। ଏହା କଷ୍ଟ ସାଧ୍ୟ ଥିଲା, କିନ୍ତୁ ସେ \n ବହୁତ କିଛି ଶିଖିଲା। ନିଜେ କଣ ଚାହୁଁଛି ଏହା ବିଷୟରେ ମଧ୍ୟ ସେ ବୁଝିସରିଥିଲା।",
              "as": "শিখৰৰ এটা ভাল ইন্টাৰ্ণশ্বিপৰ অভিজ্ঞতা আছিল। এইটো টান আছিল, কিন্তু সি বহুত শিকিছিল। সি কি কৰিব বিচাৰিছিল সেই বিষয়েও ভালদৰে বুজি পাইছিল।",
              "gu": "શિખરને ઈન્ટર્નશિપનો સારો અનુભવ હતો. તે અઘરું હતું, પરંતુ તેણે ઘણું શીખ્યું. તે શું કરવા માંગે છે તેની પણ તેને સારી રીતે સમજ પડી."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C8_1_2",
              "hi": "C8_1_2",
              "ka": "C8_1_2",
              "te": "C8_1_2",
              "or": "C8_1_2",
              "as": "C8_1_2",
              "gu": "C8_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He is at the end of his third year, and he now needs to decide what to do next. \nHis course offers two options. Click to read them:",
              "hi": "वह अपने तीसरे वर्ष के अंत में है, और अब उसे यह निश्चय करने की आवश्यकता है कि आगे क्या करना है।\nउसका कोर्स दो विकल्प प्रदान करता है। उन्हें पढ़ने के लिए क्लिक करें:",
              "ka": "ಆತನು ತನ್ನ ಮೂರನೇ ವರ್ಷದ ಅಂತ್ಯದಲ್ಲಿದ್ದಾನೆ ಮತ್ತು ಮುಂದೆ ಏನು ಮಾಡಬೇಕೆಂದು ಆತನು ಈಗ ನಿರ್ಧರಿಸಬೇಕಾಗಿದೆ. ಆತನ ಕೋರ್ಸ್ ಎರಡು ಆಯ್ಕೆಗಳನ್ನು ನೀಡುತ್ತದೆ. ಅವುಗಳನ್ನು ಓದಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "అతను తన మూడవ సంవత్సరం చివరిలో ఉన్నాడు, మరియు అతను ఇప్పుడు ఏమి చేయాలో నిర్ణయించుకోవాలి.\n అతని కోర్సు రెండు ఎంపికలను అందిస్తుంది. వాటిని చదవడానికి క్లిక్ చేయండి (నొక్కండి):",
              "or": "ସେ ନିଜର ତୃତୀୟ ବର୍ଷର ଶେଷ ପର୍ଯ୍ୟାୟ ରେ ଥାଏ , ଏବଂ ଏବେ କଣ କରିବାକୁ ହେବ \n ଏହା ବିଷୟରେ ମଧ୍ୟ ନିଷ୍ପତ୍ତି ନେଲା। ତାହାର ପାଠ୍ୟକ୍ରମରେ ଦୁଇଟି ବିକଳ୍ପ ଥିଲା।\n ସେଗୁଡିକୁ ଜାଣିବା ପାଇଁ କ୍ଲିକ କରନ୍ତୁ।",
              "as": "সি তাৰ তৃতীয় বছৰৰ শেষত আছে, আৰু সি এতিয়া পৰৱৰ্তী সময়ত কি কৰিব লাগে তাৰ সিদ্ধান্ত ল'ব লাগিব। তাৰ পাঠ্যক্ৰমে দুটা বিকল্প আগবঢ়ায়। সেইবোৰ পঢ়িবলৈ ক্লিক কৰক।",
              "gu": "તે તેના ત્રીજા વર્ષના અંતમાં છે, અને તેણે હવે આગળ શું કરવું તે નક્કી કરવાની જરૂર છે. તેનો કોર્સ બે વિકલ્પો આપે છે. તેને વાંચવા માટે ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Option 1",
              "hi": "विकल्प 1",
              "ka": "ಆಯ್ಕೆ 1",
              "te": "ఎంపిక 1",
              "or": "ବିକଳ୍ପ ୧",
              "as": "বিকল্প 1",
              "gu": "વિકલ્પ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Option 2",
              "hi": "विकल्प 2",
              "ka": "ಆಯ್ಕೆ 2",
              "te": "ఎంపిక 2",
              "or": "ବିକଳ୍ପ ୨",
              "as": "বিকল্প 2",
              "gu": "વિકલ્પ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Leave the college after 3rd year and get a B.Sc in culinary science. He can start working as a chef after that.",
              "hi": "तीसरे वर्ष के बाद कॉलेज छोड़ दे और पाक विज्ञान में बीएससी करे। वह उसके बाद एक शेफ के रूप में काम करना शुरु कर सकता है।",
              "ka": "3ನೇ ವರ್ಷದ ನಂತರ ಕಾಲೇಜು ಬಿಟ್ಟು ಪಾಕ ವಿಜ್ಞಾನದಲ್ಲಿ ಬಿ.ಎಸ್ಸಿ ಮಾಡುವುದು. ಅದರ ನಂತರ ಆತನು ಬಾಣಸಿಗರಾಗಿ ಕೆಲಸ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಬಹುದು.",
              "te": "3వ సంవత్సరం తర్వాత కళాశాల వదిలి,వంట శాస్త్రం లో B.Sc చేయడం. ఆ తర్వాత చెఫ్‌ గా పనిచేయడం ప్రారంభించవచ్చు.",
              "or": "ତୃତୀୟ ବର୍ଷ ପରେ କଲେଜ ଛାଡି ପାକ ବିଜ୍ଞାନ ରେ ବି.ଏସ ସି । ଏହାପରେ \n ସେ ଏକ ବାବରଚୀ ରୂପେ କାମ କରିପାରିବ।",
              "as": "তৃতীয় বছৰৰ পিছত কলেজ এৰি ৰন্ধন বিজ্ঞানত B.Sc লওক। তাৰ পিছত সি এজন ৰান্ধনী হিচাপে কাম কৰা আৰম্ভ কৰিব পাৰে।",
              "gu": "3 વર્ષ પછી કોલેજ છોડો અને રસોઈ વિજ્ઞાનમાં B.Sc મેળવો. તે પછી તે રસોઓ તરીકે કામ કરવાનું શરૂ કરી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Continue his studies for one more year and get a degree in Hotel Management.",
              "hi": "एक और साल के लिए अपनी पढ़ाई जारी रखे और होटल प्रबंधन में डिग्री प्राप्त करे।",
              "ka": "ಇನ್ನೂ ಒಂದು ವರ್ಷ ತನ್ನ ಅಧ್ಯಯನವನ್ನು ಮುಂದುವರಿಸಿ ಮತ್ತು ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್‌ನಲ್ಲಿ ಪದವಿ ಪಡೆಯುವುದು.",
              "te": "తన చదువును ఇంకో సంవత్సరం కొనసాగించి హోటల్ మేనేజ్‌మెంట్ లో పట్టా పొందవచ్చు.",
              "or": "ଆଉ ଗୋଟିଏ ବର୍ଷ ପଢି ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ରେ ଏକ ଡିଗ୍ରୀ ହାସଲ କରିବା।",
              "as": "আৰু এবছৰ পঢ়া-শুনা চলাই ৰাখি আৰু হোটেল মেনেজমেণ্টত ডিগ্ৰী পাওক।",
              "gu": "વધુ એક વર્ષ અભ્યાસ ચાલુ રાખો અને હોટેલ મેનેજમેન્ટની ડિગ્રી મેળવે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C8_1_3_d",
              "hi": "C8_1_3_d",
              "ka": "C8_1_3_d",
              "te": "C8_1_3_d",
              "or": "C8_1_3_d",
              "as": "C8_1_3_d",
              "gu": "C8_1_3_d"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "But he feels he needs more information to make the decision.",
              "hi": "लेकिन उसे लगता है कि निर्णय लेने के लिए उसे और अधिक जानकारी चाहिए।",
              "ka": "ಆದರೆ ನಿರ್ಧಾರ ತೆಗೆದುಕೊಳ್ಳಲು ಹೆಚ್ಚಿನ ಮಾಹಿತಿಯ ಅಗತ್ಯವಿದೆ ಎಂದು ಆತನು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "కానీ నిర్ణయం తీసుకోవడానికి తనకు మరింత సమాచారం అవసరమని అతను భావిస్తున్నాడు.",
              "or": "କିନ୍ତୁ ସେ ଅନୁଭବ କରେ ଯେ କିଛି ନିଷ୍ପତ୍ତି ନାବା ପାଇଁ ସେ ଆହୁରି ତଥ୍ୟ ଆବଶ୍ୟକ \n କରୁଛି।",
              "as": "কিন্তু সি ভাবে যে সিদ্ধান্ত ল'বলৈ তাক অধিক তথ্য দৰকাৰ ।",
              "gu": "પરંતુ તેને લાગે છે કે નિર્ણય લેવા માટે તેને વધુ માહિતીની જરૂર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Should he seek more information before deciding?",
              "hi": "क्या उसे निर्णय लेने से पहले अधिक जानकारी चाहिए?",
              "ka": "ನಿರ್ಧರಿಸುವ ಮೊದಲು ಆತನು ಹೆಚ್ಚಿನ ಮಾಹಿತಿಯನ್ನು ಹುಡುಕಬೇಕೇ?",
              "te": "నిర్ణయం తీసుకునే ముందు అతను మరింత సమాచారం కోరాలా?",
              "or": "ନିଷ୍ପତ୍ତି ନବା ପାଇଁ ସେ ଆହୁରି କିଛି ବେଶୀ ତଥ୍ୟ ଆବଶ୍ୟକ କରୁଛି କି?",
              "as": "সিদ্ধান্ত লোৱাৰ আগতে সি অধিক তথ্য বিচাৰিব লাগে নেকি",
              "gu": "શું તેણે નિર્ણય લેતા પહેલા વધુ માહિતી લેવી જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Should Shikhar make a decision now without discussion?",
              "hi": "क्या अब शिखर को चर्चा के बिना एक निर्णय लेना चाहिए?",
              "ka": "ಶಿಖರ್ ಈಗಲೇ ಚರ್ಚೆ ಮಾಡದೇ ನಿರ್ಧಾರವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕೇ?",
              "te": "శిఖర్ ఇప్పుడు చర్చించకుండా నిర్ణయం తీసుకోవాలా?",
              "or": "ବିନା କିଛି ଆଲୋଚନାରେ ସେ ନିଷ୍ପତ୍ତି ନେଇଯିବ କି?",
              "as": "শিখৰে এতিয়া আলোচনা অবিহনে সিদ্ধান্ত ল'ব লাগে নে?",
              "gu": "શું શિખરે હવે ચર્ચા કર્યા વગર નિર્ણય લેવો જોઈએ?"
            }
          }
        ]
      },
      "scene27": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Seek more information",
              "hi": "अधिक जानकारी ले",
              "ka": "ಹೆಚ್ಚಿನ ಮಾಹಿತಿಯನ್ನು ಹುಡುಕುವುದು",
              "te": "మరింత సమాచారం కోసం వెతకడం",
              "or": "ଆହୁରି ଅଧିକ ସୂଚନା ଦରକାର କରୁଛି।",
              "as": "অধিক তথ্য বিচাৰা",
              "gu": "વધુ માહિતી મેળવો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He wants to explore what other career options he has after getting his hotel management degree.",
              "hi": "वह अपनी होटल प्रबंधन की डिग्री प्राप्त करने के बाद उपलब्ध अन्य कैरियर विकल्पों का पता लगाना चाहता है।",
              "ka": "ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಪದವಿಯನ್ನು ಪಡೆದ ನಂತರ ಆತನು ಇತರ ವೃತ್ತಿ ಆಯ್ಕೆಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುತ್ತಾನೆ.",
              "te": "అతను తన హోటల్ మేనేజ్‌మెంట్ డిగ్రీని పొందిన తర్వాత అతనికి ఉన్న ఇతర కెరీర్ ఎంపికలను అన్వేషించాలనుకుంటున్నాడు.",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ଡିଗ୍ରୀ ପରେ ତା ପକ୍ଷେ ଆଉ କେଉଁ ସବୁ ବିକଳ୍ପ \n ଅଛି ସେ ଜାଣିବାକୁ ଇଛା କରେ।",
              "as": "সি হোটেল মেনেজমেণ্ট ডিগ্ৰী পোৱাৰ পিছত তাৰ ওচৰত কেৰিয়াৰৰ আন কি বিকল্প আছে তাক অধিক জানিব বিচাৰে।",
              "gu": "હોટેલ મેનેજમેન્ટની ડિગ્રી મેળવ્યા પછી તેની પાસે કારકિર્દીના અન્ય વિકલ્પો શું છે તે શોધવા માંગે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C8i_1_2",
              "hi": "C8i_1_2",
              "ka": "C8i_1_2",
              "te": "C8i_1_2",
              "or": "C8i_1_2",
              "as": "C8i_1_2",
              "gu": "C8i_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He goes back to meet the hotel manager from his internship. He decides to approach him to clarify his doubts and make a decision.",
              "hi": "अपनी इंटर्नशिप से वापस वह होटल मैनेजर से मिलने के लिए गया। अपने संदेहों को दूर करने और निर्णय लेने के लिए वह उससे संपर्क करने का फैसला करता है।",
              "ka": "ಅವನು ತನ್ನ ಇಂಟರ್ನ್‌ಶಿಪ್‌ ಮಾಡಿದ ಹೋಟೆಲ್ ವ್ಯವಸ್ಥಾಪಕರನ್ನು ಭೇಟಿ ಮಾಡಲು ಹಿಂತಿರುಗುತ್ತಾನೆ. ಅವನ ಅನುಮಾನಗಳನ್ನು ಸ್ಪಷ್ಟಪಡಿಸಲು ಮತ್ತು ನಿರ್ಧಾರ ತೆಗೆದುಕೊಳ್ಳಲು ಅವನು ಅವರನ್ನು ಸಂಪರ್ಕಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ",
              "te": "అతను తన ఇంటర్న్‌షిప్ నుండి హోటల్ మేనేజర్‌ ని కలవడానికి తిరిగి వెళ్తాడు. అతను తన సందేహాలను నివృత్తి చేసుకోవడానికి మరియు నిర్ణయం తీసుకోవడానికి అతనిని సంప్రదించాలని నిర్ణయించుకున్నాడు.",
              "or": "ଇଣ୍ଟର୍ନ୍ସିପ କରୁଥିବା ହୋଟେଲ ମ୍ୟାନେଜର ପାଖକୁ ସାକ୍ଷାତ କରିବାକୁ ସେ ଫେରେ। \n ସେ ଭାବେ ଯେ ସେ ନିଜର ସନ୍ଦେହ ଦୂର କରି ତାକୁ ନିଷ୍ପତ୍ତି ନେବାରେ ସାହାଯ୍ୟ ମାଗିବ।",
              "as": "সি তাৰ ইন্টাৰ্ণশ্বিপৰ পৰা হোটেল মেনেজাৰক লগ কৰিবলৈ ঘূৰি যায়। সি তাৰ সন্দেহ দূৰ কৰিবলৈ আৰু সিদ্ধান্ত ল'বলৈ তেওঁৰ কাষ চাপিবলৈ সিদ্ধান্ত লয়।",
              "gu": "તે તેની ઇન્ટર્નશિપમાંથી હોટેલ મેનેજરને મળવા પાછો જાય છે. તે તેની શંકાઓને સ્પષ્ટ કરવા અને નિર્ણય લેવા માટે તેનો સંપર્ક કરવાનું નક્કી કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C8i_1_3//",
              "hi": "C8i_1_3 //",
              "ka": "C8i_1_3//",
              "te": "C8i_1_3//",
              "or": "C8i_1_3//",
              "as": "C8i_1_3//",
              "gu": "C8i_1_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Hotel Manager",
              "hi": "होटल प्रबंधक",
              "ka": "ಹೊಟೇಲ್ ಮ್ಯಾನೇಜರ್",
              "te": "హోటల్ మేనేజర్",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜର",
              "as": "হোটেল মেনেজাৰ",
              "gu": "હોટેલ મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Sir, I've been thinking about what I want to pursue, and I feel like I want to become a full-time chef. Maybe I could finish college now and get a three year Bachelor degree in Hospitality and Catering Studies and start working somewhere to gain more experience.",
              "hi": "महोदय, मैं इस बारे में सोच रहा हूं कि मैं क्या करना चाहता हूं, और मुझे लगता है कि मैं पूर्णकालिक शेफ बनना चाहता हूं। शायद मैं अब कॉलेज खत्म कर सकता हूं और हॉस्पिटेलिटी एंड केटरिंग स्टडीज़ में तीन साल की स्नातक की डिग्री प्राप्त कर सकता हूं और अधिक अनुभव प्राप्त करने के लिए कहीं काम करना शुरु कर सकता हूं।",
              "ka": "ಸರ್, ನಾನು ಏನನ್ನು ಮುಂದುವರಿಸಬೇಕೆಂದು ಯೋಚಿಸುತ್ತಿದ್ದೇನೆ ಮತ್ತು ನಾನು ಪೂರ್ಣ ಸಮಯದ ಬಾಣಸಿಗನಾಗಲು ಬಯಸುತ್ತೇನೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ಬಹುಶಃ ನಾನು ಈಗ ಕಾಲೇಜು ಮುಗಿಸಬಹುದು ಮತ್ತು ಹಾಸ್ಪಿಟಾಲಿಟಿ ಮತ್ತು ಕ್ಯಾಟರಿಂಗ್ ಸ್ಟಡೀಸ್‌ನಲ್ಲಿ ಮೂರು ವರ್ಷಗಳ ಬ್ಯಾಚುಲರ್ ಪದವಿಯನ್ನು ಪಡೆಯಬಹುದು ಮತ್ತು ಹೆಚ್ಚಿನ ಅನುಭವವನ್ನು ಪಡೆಯಲು ಎಲ್ಲೋ ಕೆಲಸ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಬಹುದು.",
              "te": "సర్, నేను ఏమి చేయాలి అనే దాని గురించి ఆలోచిస్తున్నాను మరియు నేను పూర్తి సమయం చెఫ్‌ గా మారాలనుకుంటున్నాను. బహుశా నేను ఇప్పుడు కళాశాల ముగించి, హాస్పిటాలిటీ మరియు క్యాటరింగ్ స్టడీస్‌ లో మూడు సంవత్సరాల బ్యాచిలర్ డిగ్రీని పొందగలను మరియు మరింత అనుభవాన్ని పొందడానికి ఎక్కడైనా పని చేయడం ప్రారంభించవచ్చు.",
              "or": "ସାର ମୁଁ କେଉଁଥିରେ ଆଗକୁ ଯିବି ସେ ବାବଦରେ ଭାବୁଛି, ଏବଂ ମୁ ଅନୁଭବ କରୁଛି \n ଯେ ମୁ ଏକ ପୂର୍ଣ୍ଣ ସମୟ ରୋଷେୟା ହେବି। ବୋଧ ହୁଏ ମୁଁ ମୋ କଲେଜ ଏବେ ସାରିପାରିବି \n ଏବଂ ହସ୍ପିଟାଲିଟି ଓ କ୍ୟାଟରିଙ୍ଗ ପାଠରେ ତିନି ବର୍ଷୀୟ ସ୍ନାତକ ଡିଗ୍ରୀ ହାସଲ କରିବି, ଏବଂ \n ଆହୁରି ଅଭିଜ୍ଞତା ପାଇଁ କେଉଁଠାରେ କାମ ଆରମ୍ଭ କରିବି।",
              "as": "চাৰ, মই কি কৰিব বিচাৰো সেই বিষয়ে ভাবি কৰি আছোঁ, আৰু মই ভাবো যে মই এজন পূৰ্ণ-সময়ৰ ৰান্ধনী হ'ব বিচাৰোঁ। হয়তো মই এতিয়া কলেজ শেষ কৰিব পাৰোঁ আৰু হস্পিটালিটি আৰু কেটাৰিং ষ্টাডিজত তিনি বছৰীয়া স্নাতক ডিগ্ৰী লাভ কৰিব পাৰোঁ আৰু অধিক অভিজ্ঞতা লাভ কৰিবলৈ কৰবাত কাম কৰা আৰম্ভ কৰিব পাৰোঁ।",
              "gu": "સાહેબ, હું શું કરવા માંગુ છું તે વિશે હું વિચારી રહ્યો છું, અને મને લાગે છે કે હું પૂરા સમય માટે રસોઈઓ બનવા માંગુ છું. કદાચ હું હવે કોલેજ પૂરી કરી શકું અને હોસ્પિટાલિટી અને કેટરિંગના અભિયાસમાં ત્રણ વર્ષની બેચલર ડિગ્રી મેળવી શકું અને વધુ અનુભવ મેળવવા માટે ક્યાંક કામ કરવાનું શરૂ કરી શકું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "That's wonderful, Shikhar! Our head chef has told me a lot about how well you did in the internship. I think it's a good idea.",
              "hi": "यह बहुत अच्छी बात है, शिखर! हमारे हेड शेफ ने मुझे इस बारे में बहुत कुछ बताया है कि आपने इंटर्नशिप में कितना अच्छा काम किया है। मुझे लगता है यह एक अच्छा विचार है।",
              "ka": "ಅದ್ಭುತವಾಗಿದೆ, ಶಿಖರ್! ನೀವು ಇಂಟರ್ನ್‌ಶಿಪ್‌ನಲ್ಲಿ ಎಷ್ಟು ಚೆನ್ನಾಗಿ ಮಾಡಿದ್ದೀರಿ ಎಂದು ನಮ್ಮ ಮುಖ್ಯ ಬಾಣಸಿಗ ನನಗೆ ಸಾಕಷ್ಟು ಹೇಳಿದ್ದಾರೆ. ಇದು ಒಳ್ಳೆಯದು ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "అద్భుతంగా ఉంది శిఖర్! మీరు ఇంటర్న్‌షిప్ లో ఎంత బాగా చేశారో మా హెడ్ చెఫ్ నాకు చాలా చెప్పారు. ఇది మంచి ఆలోచన అని నేను భావిస్తున్నాను.",
              "or": "ଅତି ଉତ୍ତମ! ଶିଖର! ଇଣ୍ଟର୍ନ୍ସିପ ରେ ତୁମେ କେତେ ଭଲ ପ୍ରଦର୍ଶନ କରିଛ ସେ ବିଷୟରେ ଆମ \n ମୁଖ୍ୟ ରୋଷେୟା କହିଛନ୍ତି। ମୁଁ ଭାବୁଛି ଏହା ଏକ ଭଲ ବିଚାର।",
              "as": "এইটো আচৰিত, শিখৰ! আমাৰ মুখ্য ৰান্ধনীজনে মোক বহুত কৈছে যে তুমি ইণ্টাৰ্ণৰ্শ্বিপত কিমান ভাল কৰিছিলা। মই ভাবো এইটো এটা ভাল আইডিয়া।",
              "gu": "તે અદ્ભુત છે, શિખર! અમારા મુખ્ય રસોઇયાએ મને ઘણું કહ્યું છે કે મે ઇન્ટર્નશિપમાં કેટલું સારું કર્યું છે. મને લાગે છે કે તે એક સારો વિચાર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "But, sir, I am confused. I could also complete one more year and get a Hotel Management degree. What should I do?",
              "hi": "लेकिन, महोदय, मैं दुविधा में हूं। मैं एक और साल भी पूरा कर सकता था और होटल प्रबंधन की डिग्री प्राप्त कर सकता था। मुझे क्या करना चाहिए?",
              "ka": "ಆದರೆ, ಸರ್, ನಾನು ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದೇನೆ. ನಾನು ಇನ್ನೂ ಒಂದು ವರ್ಷವನ್ನು ಪೂರ್ಣಗೊಳಿಸಬಹುದು ಮತ್ತು ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್ ಪದವಿಯನ್ನು ಪಡೆಯಬಹುದು. ನಾನು ಏನು ಮಾಡಲಿ?",
              "te": "కానీ, సార్, నేను అయోమయంగా ఉన్నాను. నేను మరో సంవత్సరం పూర్తి చేసి హోటల్ మేనేజ్‌మెంట్ డిగ్రీని కూడా పొందగలను. నేనేం చేయాలి?",
              "or": "କିନ୍ତୁ ସାର, ମୁଁ ଦ୍ଵନ୍ଦ ମଧ୍ୟରେ ଅଛି। ମୁଁ ଆଉ ଗୋଟିଏ ବର୍ଷ ପଢି ମୋର ହୋଟେଲ \n ମ୍ୟାନେଜମେଣ୍ଟ କୋର୍ସର ଡିଗ୍ରୀ ହାସଲ କରିପାରିବି। ତେବେ ମୁଁ କଣ କରିବି?",
              "as": "কিন্তু, ছাৰ, মই চিন্তিত। মই আৰু এবছৰ সম্পূৰ্ণ কৰি আৰু হোটেল মেনেজমেণ্ট ডিগ্ৰী লাভ কৰিব পাৰোঁ। মই কি কৰিব লাগে?",
              "gu": "પણ, સાહેબ, હું મૂંઝવણમાં છું. હું વધુ એક વર્ષ પૂર્ણ કરી શકું અને હોટેલ મેનેજમેન્ટની ડિગ્રી મેળવી શકું. મારે શું કરવું જોઈએ?"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C8i_1_4////",
              "hi": "C8i_1_4 /////",
              "ka": "C8i_1_4////",
              "te": "C8i_1_4////",
              "or": "C8i_1_4////",
              "as": "C8i_1_4////",
              "gu": "C8i_1_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "It would provide you with many more options later definitely. Why don’t you weigh both options carefully? What are the advantages of leaving the course now?",
              "hi": "यह आपको निश्चित रूप से और बहुत से विकल्प प्रदान करेगा। आप दोनों विकल्पों की तुलना क्यों नहीं करते? अब कोर्स छोड़ने के क्या फायदे हैं?",
              "ka": "ಇದು ನಿಮಗೆ ನಂತರ ಖಂಡಿತವಾಗಿಯೂ ಹಲವು ಆಯ್ಕೆಗಳನ್ನು ಒದಗಿಸುತ್ತದೆ. ನೀವು ಎರಡೂ ಆಯ್ಕೆಗಳನ್ನು ಏಕೆ ಎಚ್ಚರಿಕೆಯಿಂದ ಅಳೆಯಬಾರದು? ಈಗ ಕೋರ್ಸ್ ತೊರೆಯುವುದರಿಂದ ಏನು ಪ್ರಯೋಜನ?",
              "te": "ఇది మీకు తర్వాత ఖచ్చితంగా అనేక ఎంపికలను అందిస్తుంది. మీరు రెండు ఎంపికలను ఎందుకు జాగ్రత్తగా అంచనా వేయకూడదు? ఇప్పుడు కోర్సును విడిచిపెట్టడం వల్ల కలిగే ప్రయోజనాలు ఏమిటి?",
              "or": "ପରେ ନିଶ୍ଚିତ ରୂପେ ଏହା ତୁମକୁ ବହୁତ ସାରା ବିକଳ୍ପ ଦେବ। ତୁମେ ଦୁଇଟି ଯାକକୁ \n ଯତ୍ନ ର ସହ ତୁଲାଉନ କାହିଁକି? ବର୍ତ୍ତମାନ ପାଠ୍ୟକ୍ରମ କୁ ଛାଡିବାରେ କଣ ଫାଇଦା?",
              "as": "ই তোমাক পিছত নিশ্চিতভাৱে আৰু বহুতো বিকল্প দিব। তুমি দুয়োটা বিকল্প সাৱধানে জুখি নোচোৱা কিয়? এতিয়া পাঠ্যক্ৰম এৰি যোৱাৰ সুবিধাবোৰ কি?",
              "gu": "તે તમને પછીથી ચોક્કસપણે ઘણા વધુ વિકલ્પો પ્રદાન કરશે. શા માટે તમે બંને વિકલ્પોને કાળજીપૂર્વક સરખામણી કરતા નથી? હવે કોર્સ છોડવાનો શું ફાયદો છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I will be able to start earning immediately, which will help my family. And I will be able to focus on what I love to do. But...",
              "hi": "मैं तुरंत कमाई शुरु कर पाऊंगा, जो मेरे परिवार की सहायता करेगा। और जो मुझे करना पसंद है, मैं उस पर ध्यान केंद्रित कर पाऊंगा। परंतु...",
              "ka": "ನಾನು ತಕ್ಷಣವೇ ಗಳಿಸಲು ಪ್ರಾರಂಭಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ, ಇದು ನನ್ನ ಕುಟುಂಬಕ್ಕೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಮತ್ತು ನಾನು ಮಾಡಲು ಇಷ್ಟಪಡುವದನ್ನು ಕೇಂದ್ರೀಕರಿಸಲು ನನಗೆ ಸಾಧ್ಯವಾಗುತ್ತದೆ. ಆದರೆ...",
              "te": "నేను వెంటనే సంపాదించడం ప్రారంభించగలను, అది నా కుటుంబానికి సహాయం చేస్తుంది. మరియు నేను ఇష్టపడే పనిపై దృష్టి పెట్టగలను. కానీ...",
              "or": "ମୁଁ ବହୁତ ଜଲଦି ରୋଜଗାର କ୍ଷମ ହେବି, ଯାହାକି ମୋ ପରିବାରକୁ ସାହାଯ୍ୟ କରିବ। \n ଏବଂ ମୁଁ ଭଲ ପାଉଥିବା କାମ ଉପରେ ଧ୍ୟାନ କେନ୍ଦ୍ରିତ କରିପାରିବି, କିନ୍ତୁ...",
              "as": "মই লগে লগে উপাৰ্জন আৰম্ভ কৰিব পাৰিম, যি মোৰ পৰিয়ালক সহায় কৰিব। আৰু মই কি কৰিবলৈ ভাল পাওঁ তাৰ ওপৰত গুৰুত্ব দিব পাৰিম। কিন্তু...",
              "gu": "હું તરત જ કમાણી શરૂ કરી શકીશ, જે મારા પરિવારને મદદ કરશે. અને હું જે કરવાનું પસંદ કરું છું તેના પર ધ્યાન કેન્દ્રિત કરી શકીશ. પણ..."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Go on. But?",
              "hi": "आगे बोलो। परंतु?",
              "ka": "ಮುಂದುವರೆಸು, ಆದರೆ?",
              "te": "కొనసాగించు. కానీ?",
              "or": "ହଉ, କିନ୍ତୁ?",
              "as": "কৈ থাকা। কিন্তু?",
              "gu": "તેના ઉપર આગળ વધો. પણ?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "But I feel like I will get many more opportunities if I complete my hotel management degree. They will be happy if I take up the role of a manager somewhere.",
              "hi": "लेकिन मुझे लगता है कि अगर मैं अपनी होटल प्रबंधन की डिग्री पूरी करता हूं तो मुझे बहुत से अवसर मिलेंगे। अगर मैं कहीं प्रबंधक बन जाता हूं तो वे खुश होंगे।",
              "ka": "ಆದರೆ ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್ ಮೆಂಟ್ ಪದವಿ ಮುಗಿಸಿದರೆ ಇನ್ನೂ ಹಲವು ಅವಕಾಶಗಳು ಸಿಗುತ್ತವೆ ಅನಿಸುತ್ತಿದೆ. ನಾನು ಎಲ್ಲೋ ಮ್ಯಾನೇಜರ್ ಪಾತ್ರವನ್ನು ತೆಗೆದುಕೊಂಡರೆ ಅವರು ಸಂತೋಷಪಡುತ್ತಾರೆ.",
              "te": "కానీ నేను హోటల్ మేనేజ్‌మెంట్ డిగ్రీ పూర్తి చేస్తే మరిన్ని అవకాశాలు వస్తాయని భావిస్తున్నాను. నేను ఎక్కడైనా మేనేజర్ పాత్రను చేపడితే సంతోషిస్తారు.",
              "or": "କିନ୍ତୁ ମୁଁ ଅନୁଭବ କରୁଛି ଯଦି ମୁଁ ମୋ ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ଡିଗ୍ରୀକୁ ସଂପୂର୍ଣ୍ଣ \n କରିବି ତେବେ ମୁଁ ଆହୁରି ବେଶୀ ମଉକା ପାଇବି। ଯଦି ମୁଁ କେଉଁଠାରେ ମ୍ୟାନେଜର ଭାବେ ଚାକିରି କରିବି ତେବେ ସେମାନେ ବେଶୀ ଖୁସି ହେବେ।",
              "as": "কিন্তু মই ভাবো যে যদি মই মোৰ হোটেল মেনেজমেন্ট ডিগ্ৰী সম্পূৰ্ণ কৰোঁ তেন্তে মই আৰু বহুতো সুযোগ পাম। যদি মই কৰবাত মেনেজাৰৰ কাম কৰোঁ তেন্তে তেওঁলোক সুখী হ'ব।",
              "gu": "પરંતુ મને લાગે છે કે જો હું મારી હોટેલ મેનેજમેન્ટ ડિગ્રી પૂર્ણ કરીશ તો મને ઘણી વધુ તકો મળશે. જો હું ક્યાંક મેનેજરની ભૂમિકા નિભાવીશ તો તેઓ ખુશ થશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "And what are the disadvantages of completing the hotel management degree?",
              "hi": "और होटल प्रबंधन की डिग्री पूरी करने के नुकसान क्या हैं?",
              "ka": "ಮತ್ತು ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಪದವಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸುವಲ್ಲಿ ಇರುವ ಅನಾನುಕೂಲಗಳು ಯಾವುವು?",
              "te": "మరి హోటల్ మేనేజ్‌మెంట్ డిగ్రీ పూర్తి చేయడం వల్ల కలిగే నష్టాలు ఏమిటి?",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ପାଠକୁ ସଂପୂର୍ଣ୍ଣ କରିବାର କ୍ଷତି କଣ?",
              "as": "আৰু হোটেল মেনেজমেন্ট ডিগ্ৰী সম্পূৰ্ণ কৰাৰ অসুবিধাবোৰ কি?",
              "gu": "અને હોટેલ મેનેજમેન્ટ ડિગ્રી પૂર્ણ કરવાના ગેરફાયદા શું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.5.text",
            "value": {
              "en": "I will be spending my time in my final year studying while I could be gaining experience in the kitchen.",
              "hi": "मैं अंतिम वर्ष पूरा करने में अपना समय बिताऊंगा, जबकि मैं रसोईघर में अनुभव प्राप्त कर सकता हूं।",
              "ka": "ನಾನು ಅಡುಗೆಮನೆಯಲ್ಲಿ ಅನುಭವವನ್ನು ಪಡೆಯುತ್ತಿರುವಾಗ ನಾನು ನನ್ನ ಅಂತಿಮ ವರ್ಷದಲ್ಲಿ ನನಗೆ ಸಿಗುವ ಸಮಯದಲ್ಲಿ ಅಧ್ಯಯನ ಮಾಡುತ್ತೇನೆ",
              "te": "నేను వంటగదిలో అనుభవాన్ని పొందుతూ నా చివరి సంవత్సరం చదువుతూ నా సమయాన్ని వెచ్చిస్తాను.",
              "or": "ମୁଁ ମୋ ସମୟକୁ ମୋର ପାଠର ଅନ୍ତିମ ବର୍ଷରେ ଅତିବାହିତ କରୁଥିବି \n ଯେତେବେଳେ କି ମୁଁ ରୋଷେଇ ଘରେ କିଛି ଶିଖି ପାରିବି।",
              "as": "মই মোৰ সময় শেষৰ বছৰত পঢ়াত কটাম যেতিয়া মই পাকঘৰত অভিজ্ঞতা ল'ব পাৰোঁ।",
              "gu": "હું મારા અંતિમ વર્ષમાં મારો સમય અભ્યાસમાં વિતાવીશ જ્યારે હું રસોડામાં અનુભવ મેળવી શકીશ."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C8i_1_5//",
              "hi": "C8i_1_5 //",
              "ka": "C8i_1_5//",
              "te": "C8i_1_5//",
              "or": "C8i_1_5//",
              "as": "C8i_1_5//",
              "gu": "C8i_1_5//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Hotel Manager",
              "hi": "होटल प्रबंधक",
              "ka": "ಹೊಟೇಲ್ ಮ್ಯಾನೇಜರ್",
              "te": "హోటల్ మేనేజర్",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜର",
              "as": "হোটেল মেনেজাৰ",
              "gu": "હોટેલ મેનેજર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Great, so now that you put down all your thoughts, what do you feel more comfortable choosing?",
              "hi": "बढ़िया, तो अब जब आप अपने सभी विचारों को सामने रखते हैं, तो आपके लिए क्या चुनना अधिक सुविधाजनक है?",
              "ka": "ಅದ್ಭುತವಾಗಿದೆ, ಆದ್ದರಿಂದ ನೀವು ಈಗ ನಿಮ್ಮ ಎಲ್ಲಾ ಆಲೋಚನೆಗಳನ್ನು ಹೊರ ಹಾಕಿದ್ದೀರಿ, ನೀವು ಯಾವುದನ್ನು ಹೆಚ್ಚು ನಿಮಗೆ ಸಹಾಯವಾಗಬಹುದು ಎಂದು ಆರಿಸುತ್ತೀರಿ?",
              "te": "గ్రేట్, కాబట్టి ఇప్పుడు మీరు మీ ఆలోచనలన్నింటినీ రాసుకున్నారు, మీరు ఏది ఎంచుకుంటే బాగుంటుంది అనుకుంటున్నారు?",
              "or": "ଅଦ୍ଭୁତ, ତାହେଲେ ବର୍ତ୍ତମାନ ତୁମେ ନିଜର ସମସ୍ତ ଚିନ୍ତାକୁ ପଛରେ ଛାଡି କୁହ ଯେ \n ତୁମେ କେଉଁ ଥିରେ ସୁଖଦ ଅନୁଭବ କରୁଛ?",
              "as": "মহান, গতিকে এতিয়া যেতিয়া তুমি তোমাৰ সকলো চিন্তা কৰিছা, তুমি কি বাছনি কৰিবলৈ অতি সহজ পাবা?",
              "gu": "સરસ, તો હવે જ્યારે તે તારા બધા વિચારો રાખ્યા છે, તો તને શું પસંદ કરવામાં વધુ આરામદાયક લાગે છે?"
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C8i_1_6_D",
              "hi": "C8i_1_6_d",
              "ka": "C8i_1_6_D",
              "te": "C8i_1_6_D",
              "or": "C8i_1_6_D",
              "as": "C8i_1_6_D",
              "gu": "C8i_1_6_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "Help Shikhar make his decision.",
              "hi": "शिखर को अपना निर्णय लेने में मदद करो।",
              "ka": "ಶಿಖರ್ ಒಂದು ನಿಧಾರಕ್ಕೆ ಬರಲು ಆತನಿಗೆ ಸಹಾಯ ಮಾಡಿ",
              "te": "శిఖర్ నిర్ణయం తీసుకోవడంలో అతనికి సహాయపడండి.",
              "or": "ଶିଖର କୁ ନିଜ ନିଷ୍ପତ୍ତି ନେବାରେ ସାହାଯ୍ୟ କର।",
              "as": "শিখৰক তাৰ সিদ্ধান্ত লোৱাত সহায় কৰা।",
              "gu": "શિખરને તેનો નિર્ણય લેવામાં મદદ કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Get a Bachelor degree in Hospitality and Catering Studies and apply for a chef role.",
              "hi": "हॉस्पिटेलिटी एंड केटरिंग स्टडीज़ में स्नातक की डिग्री प्राप्त करे और शेफ के पद पर नौकरी करने के लिए आवेदन करे।",
              "ka": "ಹಾಸ್ಪಿಟಾಲಿಟಿ ಮತ್ತು ಕ್ಯಾಟರಿಂಗ್ ಸ್ಟಡೀಸ್‌ನಲ್ಲಿ ಬ್ಯಾಚುಲರ್ ಪದವಿ ಪಡೆಯುವುದು ಹಾಗೂ  ಬಾಣಸಿಗ ಪಾತ್ರಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದು",
              "te": "హాస్పిటాలిటీ మరియు క్యాటరింగ్ స్టడీస్‌ లో బ్యాచిలర్ డిగ్రీని పొంది చెఫ్ పాత్ర కోసం దరఖాస్తు చేసుకోవడం.",
              "or": "ହସ୍ପିଟାଲିଟି ଓ କ୍ୟାଟାରିଙ୍ଗ ପାଠରେ ନିଜର ସ୍ନାତକ ଡିଗ୍ରୀ ହାସଲ କର ଓ ଏକ \n ରୋଷେୟା ଚାକିରି ପାଇଁ ଆବେଦନ କର।",
              "as": "আতিথ্য আৰু কেটাৰিং অধ্যয়নত স্নাতক ডিগ্ৰী লাভ কৰা আৰু এজন ৰান্ধনীৰ কামৰ বাবে আবেদন কৰা।",
              "gu": "હોસ્પિટાલિટી અને કેટરિંગ અભિયાસમાં સ્નાતક ડિગ્રી મેળવો અને રસોઇયાની ભૂમિકા માટે અરજી કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Continue to get the Hotel management degree.",
              "hi": "होटल प्रबंधन की डिग्री प्राप्त करना जारी रखे।",
              "ka": "ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಪದವಿ ಪಡೆಯುವುದಕ್ಕಾಗಿ ಮುಂದುವರೆಸುವುದು",
              "te": "హోటల్ మేనేజ్‌మెంట్ డిగ్రీని పొందడం కొనసాగించడం.",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ଡିଗ୍ରୀ ପାଇବାକୁ ଜାରିରଖ।",
              "as": "হোটেল মেনেজমেণ্ট ডিগ্ৰী পোৱাটো চলাই ৰাখিব।",
              "gu": "હોટેલ મેનેજમેન્ટ ડિગ્રી મેળવવાનું ચાલુ રાખો."
            }
          }
        ]
      },
      "scene28": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Take the decision",
              "hi": "निर्णय लो ",
              "ka": "ಒಂದು ನಿರ್ಧಾರವನ್ನು ತೆಗೆದುಕೊಳ್ಳುವುದು",
              "te": "నిర్ణయం తీసుకోండి",
              "or": "ଏକ ନିଷ୍ପତ୍ତି ନିଅ।",
              "as": "সিদ্ধান্ত লোৱা",
              "gu": "નિર્ણય લો"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Shikhar do?",
              "hi": "शिखर को क्या करना चाहिए?",
              "ka": "ಶಿಖರ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "శిఖర్ ఏం చేయాలి?",
              "or": "ଶିଖର କଣ କରିବା ଉଚ୍ଚିତ?",
              "as": "শিখৰে কি কৰিব লাগে?",
              "gu": "શિખરે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Get a B.Sc in culinary science and apply for a chef role",
              "hi": "पाक विज्ञान में बीएससी करे और शेफ के पद पर नौकरी करने के लिए आवेदन करे",
              "ka": "ಪಾಕಶಾಸ್ತ್ರದಲ್ಲಿ B.Sc ಪಡೆಯುವುದು ಹಾಗೂ ಬಾಣಸಿಗ ಕೆಲಸಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದು",
              "te": "వంట శాస్త్రం లో B.Sc పొంది మరియు చెఫ్ పాత్ర కోసం దరఖాస్తు చేసుకోవడం",
              "or": "ପାକ ବିଜ୍ଞାନ ରେ ବି. ଏସ ସି କରି ଏକ ରୋଷେୟା ଚାକିରି ପାଇଁ ଆବେଦନ",
              "as": "ৰন্ধন বিজ্ঞানত B.Sc লাভ কৰা আৰু ৰান্ধনীৰ কামৰ বাবে আবেদন কৰা",
              "gu": "રસોઈ વિજ્ઞાનમાં B.Sc મેળવો અને રસોઇયાની કામગીરી માટે અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Continue to get the Hotel management degree",
              "hi": "होटल प्रबंधन की डिग्री प्राप्त करना जारी रखे",
              "ka": "ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಪದವಿ ಪಡೆಯುವುದಕ್ಕಾಗಿ ಮುಂದುವರೆಸುವುದು",
              "te": "హోటల్ మేనేజ్‌మెంట్ డిగ్రీని పొందడం కొనసాగించండి",
              "or": "ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ ଡିଗ୍ରୀ ପାଇବା ଜାରି ରଖ।",
              "as": "হোটেল মেনেজমেণ্ট ডিগ্ৰী পোৱাটো চলাই ৰাখিব।",
              "gu": "હોટેલ મેનેજમેન્ટ પદવી  મેળવવાનું ચાલુ રાખો"
            }
          }
        ]
      },
      "scene29": {
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "Apply for chef role",
              "hi": "शेफ के पद पर नौकरी करने के लिए आवेदन करे",
              "ka": "ಬಾಣಸಿಗ ಕೆಲಸಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದು",
              "te": "చెఫ్ పాత్ర కోసం దరఖాస్తు చేసుకోవడం",
              "or": "ରୋଷେୟା ପାଇଁ ଆବେଦନ।",
              "as": "ৰান্ধনীৰ কামৰ বাবে আবেদন কৰক",
              "gu": "રસોઇયાની ભૂમિકા માટે અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar decides to leave college after the 3rd year with a B.Sc in culinary science.",
              "hi": "शिखर ने पाक विज्ञान में बीएससी के साथ तीसरे वर्ष के बाद कॉलेज छोड़ने का निर्णय किया।",
              "ka": "ಶಿಖರ್ 3 ನೇ ವರ್ಷದ ನಂತರ ಪಾಕಶಾಸ್ತ್ರದಲ್ಲಿ ಬಿಎಸ್ಸಿಯನ್ನು ಪಡೆದ ನಂತರ  ಕಾಲೇಜು ಬಿಡಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "శిఖర్ 3వ సంవత్సరం తర్వాత వంట శాస్త్రం లో B.Scతో కళాశాలని విడిచిపెట్టాలని నిర్ణయించుకున్నాడు.",
              "or": "ପାକ ବିଜ୍ଞାନରେ ବି.ଏସ ସି ସହିତ ଶିଖର କଲେଜ ଛାଡିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି .",
              "as": "শিখৰে ৰন্ধন বিজ্ঞানৰ B.Scৰ সৈতে তৃতীয় বছৰৰ পিছত কলেজ এৰিবলৈ সিদ্ধান্ত লয়।",
              "gu": "શિખરે રસોઈ વિજ્ઞાનમાં B.Sc સાથે 3જા વર્ષ પછી કૉલેજ છોડવાનું નક્કી કર્યું."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C9_1_2",
              "hi": "C9_1_2",
              "ka": "C9_1_2",
              "te": "C9_1_2",
              "or": "C9_1_2",
              "as": "C9_1_2",
              "gu": "C9_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He joins a small restaurant as an assistant chef.",
              "hi": "वह एक सहायक शेफ के रूप में एक छोटे से रेस्टोरेंट से जुड़ जाता है।",
              "ka": "ಆತನು ಸಹಾಯಕ ಬಾಣಸಿಗನಾಗಿ ಸಣ್ಣ ರೆಸ್ಟೋರೆಂಟ್‌ ಒಂದಕ್ಕೆ ಸೇರುತ್ತಾನೆ",
              "te": "అతను ఒక చిన్న రెస్టారెంట్లో సహాయ వంటవాడు.గా చేరాడు.",
              "or": "ସେ ଏକ ଛୋଟ ରେଷ୍ଟୁରାଣ୍ଟ ରେ ସହାୟକ ରୋଷେୟା ଭାବେ କାମ କରନ୍ତି।",
              "as": "সি সহকাৰী ৰান্ধনী হিচাপে এখন সৰু ৰেষ্টুৰেণ্টত সোমায়।",
              "gu": "તે એક નાનકડા ભોજનાલયમાં સહાયક રસોઇયા તરીકે જોડાય છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C9_2_1",
              "hi": "C9_2_1",
              "ka": "C9_2_1",
              "te": "C9_2_1",
              "or": "C9_2_1",
              "as": "C9_2_1",
              "gu": "C9_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He learns a lot on the job and after 4 years he gets offered the role of a senior chef in a bigger restaurant.",
              "hi": "वह नौकरी में बहुत कुछ सीखता है और 4 साल बाद उसे एक बड़े रेस्तरां में सीनियर शेफ के पद की नौकरी का प्रस्ताव मिलता है।",
              "ka": "ಆತನು ಕೆಲಸದಲ್ಲಿ ಬಹಳಷ್ಟು ಕಲಿಯುತ್ತಾನೆ ಮತ್ತು 4 ವರ್ಷಗಳ ನಂತರ ಆತನು ದೊಡ್ಡ ರೆಸ್ಟೋರೆಂಟ್‌ ಒಂದರಲ್ಲಿ ಹಿರಿಯ ಬಾಣಸಿಗನ ಕೆಲಸವನ್ನು ಪಡೆಯುತ್ತಾನೆ",
              "te": "అతను ఉద్యోగంలో చాలా నేర్చుకుంటాడు మరియు 4 సంవత్సరాల తర్వాత అతను పెద్ద రెస్టారెంట్లో సీనియర్ వంటమనిషి పాత్రను పొందుతాడు.",
              "or": "ଏହି କାମ ରେ ସେ ବହୁତ କିଛି ଶିଖେ ଓ ଚାରି ବର୍ଷ ପରେ ଏକ ବଡ ରେଷ୍ଟୁରାଣ୍ଟ ରେ \n ସେ ବରିଷ୍ଠ ରୋଷେୟା ଭାବେ ପ୍ରସ୍ତାବ ପାଏ ।",
              "as": "সি কামটোত বহুত শিকে আৰু ৪ বছৰৰ পিছত সি এখন ডাঙৰ ৰেষ্টুৰেণ্টত এজন জ্যেষ্ঠ ৰান্ধনীৰ কামৰ প্ৰস্তাৱ পায়।",
              "gu": "તે કામ પર ઘણું શીખે છે અને 4 વર્ષ પછી તેને એક મોટી ભોજનાલયમાં વરિષ્ઠ રસોઇયાની ભૂમિકાની ઓફર મળે છે.    "
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C9_2_2",
              "hi": "C9_2_2",
              "ka": "C9_2_2",
              "te": "C9_2_2",
              "or": "C9_2_2",
              "as": "C9_2_2",
              "gu": "C9_2_2  "
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He earns good money in the senior role, however the work gets very stressful.",
              "hi": "वह वरिष्ठ पद पर अच्छा वेतन पाता है, हालांकि काम बहुत तनावपूर्ण हो जाता है।",
              "ka": "ಆತನು  ಹಿರಿಯ ಬಾಣಸಿಗನ ಕೆಲಸದಲ್ಲಿ  ಉತ್ತಮ ಹಣವನ್ನು ಗಳಿಸುತ್ತಾನೆ, ಆದರೆ ಕೆಲಸವು ತುಂಬಾ ಒತ್ತಡವನ್ನು ಹೊಂದಿರುತ್ತದೆ",
              "te": "అతను సీనియర్ పాత్రలో మంచి డబ్బు సంపాదిస్తాడు, అయినప్పటికీ పని చాలా ఒత్తిడిని కలిగిస్తుంది.",
              "or": "ସେ ଏହି ପଦବୀ ରେ ରହି ଭଲ ଦରମା ପାଏ , କିନ୍ତୁ କାମ ବହୁତ ଅଶାନ୍ତି \n ଦାୟକ।",
              "as": "সি জ্যেষ্ঠ কামটোত ভাল ধন উপাৰ্জন কৰে, অৱশ্যে কামটো অতি চাপপূৰ্ণ হৈ পৰে।",
              "gu": "તે વરિષ્ઠ ભૂમિકામાં સારી કમાણી કરે છે, જો કે કામ ખૂબ જ તણાવપૂર્ણ થઈ જાય છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C9_2_3",
              "hi": "C9_2_3",
              "ka": "C9_2_3",
              "te": "C9_2_3",
              "or": "C9_2_3",
              "as": "C9_2_3",
              "gu": "C9_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Around that time one of his fellow graduates from the university talks to Shikhar about his idea of opening a cloud kitchen.",
              "hi": "उसी समय के आसपास विश्वविद्यालय के उनके साथी स्नातकों में से एक क्लाउड रसोई खोलने के अपने विचार के बारे में शिखर से बात करता है।",
              "ka": "ಅದೇ ಸಮಯದಲ್ಲಿ ವಿಶ್ವವಿದ್ಯಾನಿಲಯದಿಂದ ಆತನ ಸಹ ಪದವೀಧರರೊಬ್ಬರು ಶಿಖರ್ ಅವರೊಂದಿಗೆ ಕ್ಲೌಡ್ ಕಿಚನ್ ತೆರೆಯುವ ಆಲೋಚನೆಯ ಬಗ್ಗೆ ಮಾತನಾಡುತ್ತಾರೆ.",
              "te": "ఆ సమయంలో విశ్వవిద్యాలయం నుండి అతని తోటి గ్రాడ్యుయేట్‌ లలో ఒకరు క్లౌడ్ కిచెన్ తెరవాలనే తన ఆలోచన గురించి శిఖర్‌తో మాట్లాడాడు.",
              "or": "ସେହି ସମୟରେ ବିଶ୍ୱବିଦ୍ୟାଳୟର ତାଙ୍କର ଜଣେ ସହପାଠୀ ଶିଖରଙ୍କ ସହ କ୍ଲା\n ଉଡ୍ ରୋଷେଇ ଘର ଖୋଲିବା ବିଷୟରେ କଥାବାର୍ତ୍ତା କରିଥିଲେ |",
              "as": "সেই সময়ত বিশ্ববিদ্যালয়ৰ পৰা তাৰ এজন সহস্নাতকে শিখৰৰ সৈতে ক্লাউড পাকঘৰ খোলাৰ আইডিয়াটোৰ বিষয়ে কথা পাতিছিল।",
              "gu": "તે સમયે યુનિવર્સિટીમાંથી તેનો એક સાથી સ્નાતક શિખર સાથે ક્લાઉડ કિચન ખોલવાના તેના વિચાર વિશે વાત કરે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C9_3_1",
              "hi": "C9_3_1",
              "ka": "C9_3_1",
              "te": "C9_3_1",
              "or": "C9_3_1",
              "as": "C9_3_1",
              "gu": "C9_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He explains that a cloud kitchen is a place where food is prepared and is delivered to people by taking orders via calls and online delivery platforms.",
              "hi": "वह बताता है कि एक क्लाउड रसोई एक ऐसी जगह है जहां भोजन तैयार किया जाता है और कॉल और ऑनलाइन डिलीवरी प्लेटफार्मों के द्वारा आदेश लेकर लोगों तक पहुंचाया जाता है।",
              "ka": "ಕ್ಲೌಡ್ ಕಿಚನ್ ಎನ್ನುವುದು ಆಹಾರವನ್ನು ತಯಾರಿಸುವ ಸ್ಥಳವಾಗಿದೆ ಮತ್ತು ಕರೆಗಳು ಮತ್ತು ಆನ್‌ಲೈನ್ ಡೆಲಿವರಿ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗಳ ಮೂಲಕ ಆರ್ಡರ್‌ಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುವ ಮೂಲಕ ಜನರಿಗೆ ತಲುಪಿಸಲಾಗುತ್ತದೆ ಎಂದು ಅವರು ವಿವರಿಸುತ್ತಾರೆ.",
              "te": "కాల్స్ మరియు ఆన్‌లైన్ డెలివరీ ప్లాట్‌ ఫారమ్ల ద్వారా ఆర్డర్లు తీసుకోవడం ద్వారా ఆహారాన్ని తయారు చేసి ప్రజలకు డెలివరీ చేసే ప్రదేశం క్లౌడ్ కిచెన్ అని ఆయన వివరించారు.",
              "or": "ସେ ବ୍ୟାଖ୍ୟା କରିଛନ୍ତି ଯେ ଏକ କ୍ଲାଉଡ୍ ରୋଷେଇ ଘର ହେଉଛି ଏକ ସ୍ଥାନ ଯେଉଁଠାରେ ଖାଦ୍ୟ ପ୍ରସ୍ତୁତ କରାଯାଏ ଏବଂ କଲ୍ ଏବଂ ଅନ ଲାଇନ୍ ବିତରଣ ପ୍ଲାଟଫର୍ମ ମାଧ୍ୟମରେ ଅର୍ଡର ନେଇ ଲୋକଙ୍କୁ ବିତରଣ କରାଯାଇଥାଏ |",
              "as": "তেওঁ বৰ্ণনা কৰে যে ক্লাউড পাকঘৰ হৈছে এনে এক ঠাই য'ত খাদ্য বনোৱা হয় আৰু ফোনকল আৰু অনলাইন ডেলিভাৰী প্লেটফৰ্মৰ জৰিয়তে অৰ্ডাৰ লৈ মানুহক দিয়া হয়।",
              "gu": "તે સમજાવે છે કે ક્લાઉડ કિચન એ એવી જગ્યા છે જ્યાં ખોરાક તૈયાર કરવામાં આવે છે અને કોલ અને ઓનલાઈન ડિલિવરી પ્લેટફોર્મ દ્વારા ઓર્ડર લઈને લોકોને પહોંચાડવામાં આવે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C9_3_2",
              "hi": "C9_3_2",
              "ka": "C9_3_2",
              "te": "C9_3_2",
              "or": "C9_3_2",
              "as": "C9_3_2",
              "gu": "C9_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar likes this idea, and they decide to start their own business.",
              "hi": "शिखर को यह विचार पसंद आता है, और वे अपना खुद का व्यवसाय शुरु करने का निश्चय करते हैं।",
              "ka": "ಶಿಖರ್ ಈ ಕಲ್ಪನೆಯನ್ನು ಇಷ್ಟಪಡುತ್ತಾನೆ ಮತ್ತು ಅವರು ತಮ್ಮ ಸ್ವಂತ ವ್ಯವಹಾರವನ್ನು ಪ್ರಾರಂಭಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾರೆ.",
              "te": "శిఖర్‌ కి ఈ ఆలోచన నచ్చి, వారు తమ స్వంత వ్యాపారాన్ని ప్రారంభించాలని నిర్ణయించుకున్నారు.",
              "or": "ଶିଖର ଏହି ଧାରଣାକୁ ପସନ୍ଦ କରନ୍ତି ଏବଂ ସେମାନେ ନିଜର ବ୍ୟବସାୟ ଆରମ୍ଭ କରିବାକୁ ସ୍ଥିର କରନ୍ତି |",
              "as": "শিখৰে এই আইডিয়াটো ভাল পায়, আৰু সিহঁতে সিহঁতৰ নিজৰ ব্যৱসায় আৰম্ভ কৰাটো ঠিক কৰে।",
              "gu": "શિખરને આ વિચાર ગમ્યો અને તેઓ પોતાનો ધંધો શરૂ કરવાનું નક્કી કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C9_3_3",
              "hi": "C9_3_3",
              "ka": "C9_3_3",
              "te": "C9_3_3",
              "or": "C9_3_3",
              "as": "C9_3_3",
              "gu": "C9_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Today Shikhar and his friend run their cloud kitchen called Indore Eats, which they started a year ago and have been making good money through the business.",
              "hi": "आज शिखर और उसका मित्र इंदौर ईट्स के नाम से अपनी क्लाउड रसोई को चला रहे हैं, जिसे उन्होंने एक साल पहले शुरु किया था और व्यापार के माध्यम से अच्छा पैसा कमा रहे हैं।",
              "ka": "ಇಂದು ಶಿಖರ್ ಮತ್ತು ಅವರ ಸ್ನೇಹಿತ ಇಂದೋರ್ ಈಟ್ಸ್ ಎಂಬ ಕ್ಲೌಡ್ ಕಿಚನ್ ಅನ್ನು ನಡೆಸುತ್ತಿದ್ದಾರೆ, ಅವರು ಒಂದು ವರ್ಷದ ಹಿಂದೆ ಪ್ರಾರಂಭಿಸಿದರು ಮತ್ತು ವ್ಯಾಪಾರದ ಮೂಲಕ ಉತ್ತಮ ಹಣವನ್ನು ಗಳಿಸುತ್ತಿದ್ದಾರೆ.",
              "te": "ఈ రోజు శిఖర్ మరియు అతని స్నేహితుడు ఇండోర్ ఈట్స్ అనే వారి క్లౌడ్ కిచెన్‌ ను నడుపుతున్నారు, వారు ఒక సంవత్సరం క్రితం ప్రారంభించారు మరియు వ్యాపారం ద్వారా బాగా డబ్బు సంపాదిస్తున్నారు.",
              "or": "ଆଜି ଶିଖର ଏବଂ ତାଙ୍କ ବନ୍ଧୁ ଇନ୍ଦୋର ଇଟ୍ସ ନାମକ କ୍ଲାଉଡ୍ ରୋଷେଇ ଘର ଚଳାନ୍ତି, ଯାହାକି ସେମାନେ ଏକ ବର୍ଷ ପୂର୍ବେ ଆରମ୍ଭ କରିଥିଲେ ଏବଂ ବ୍ୟବସାୟ ମାଧ୍ୟମରେ ଭଲ ରୋଜଗାର କରୁଥିଲେ |",
              "as": "আজি শিখৰ আৰু তাৰ বন্ধুৱে সিহঁতৰ ইন্দোৰ ইটছ নামৰ ক্লাউড কিচেন চলায়, যিটো সিহঁতে এবছৰ আগতে আৰম্ভ কৰিছিল আৰু ব্যৱসায়ৰ জৰিয়তে ভাল টকা ঘটি আছে।",
              "gu": "આજે શિખર અને તેનો મિત્ર ઈન્દોર ઈટ્સ નામનું તેમનું ક્લાઉડ રસોડુ ચલાવે છે, જે તેમણે એક વર્ષ પહેલા શરૂ કર્યું હતું અને ધંધા દ્વારા સારી કમાણી કરી રહ્યા છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "C9_3_4",
              "hi": "C9_3_4",
              "ka": "C9_3_4",
              "te": "C9_3_4",
              "or": "C9_3_4",
              "as": "C9_3_4",
              "gu": "C9_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Seeing Shikhar's success, his family's perspective on Shikhar's career path is changing. Shikhar is also able to financially support his family.",
              "hi": "शिखर की सफलता को देखते हुए, शिखर के करियर पथ पर उसके परिवार का दृष्टिकोण बदल रहा है। शिखर भी अपने परिवार को आर्थिक रूप से सहयोग  करने में सक्षम है।",
              "ka": "ಶಿಖರ್‌ನ ಯಶಸ್ಸನ್ನು ನೋಡಿ, ಶಿಖರ್ ತನ್ನ ವೃತ್ತಿಜೀವನದ ಹಾದಿಯ ಬಗ್ಗೆ ಅವರ ಕುಟುಂಬದ ದೃಷ್ಟಿಕೋನವು ಬದಲಾಗುತ್ತಿದೆ. ಶಿಖರ್ ಕೂಡ ತನ್ನ ಕುಟುಂಬವನ್ನು ಆರ್ಥಿಕವಾಗಿ ಬೆಂಬಲಿಸಲು ಸಮರ್ಥನಾಗಿದ್ದಾನೆ.",
              "te": "శిఖర్ విజయం చూసి, శిఖర్ కెరీర్ బాటలో అతని కుటుంబ దృక్పథం మారుతోంది. శిఖర్ తన కుటుంబాన్ని కూడా ఆర్థికంగా ఆదుకుంటున్నాడు.",
              "or": "ଶିଖରଙ୍କ ସଫଳତା ଦେଖି ଶିଖରଙ୍କ କ୍ୟାରିଅର୍ ପଥ ଉପରେ ତାଙ୍କ ପରିବାରର ଦୃଷ୍ଟିକୋଣ ବଦଳୁଛି | ଶିଖର ମଧ୍ୟ ତାଙ୍କ ପରିବାରକୁ ଆର୍ଥିକ ସହାୟତା କରିବାରେ ସକ୍ଷମ ଅଟନ୍ତି |",
              "as": "শিখৰৰ সফলতা দেখি, শিখৰৰ কেৰিয়াৰৰ পথৰ ওপৰত তাৰ পৰিয়ালৰ ভাব-ধাৰা সলনি হৈছে। শিখৰেও তাৰ পৰিয়ালক আৰ্থিকভাৱে সহায় কৰিবলৈ সক্ষম হৈছে।",
              "gu": "શિખરની સફળતા જોઈને, શિખરની કારકિર્દીના માર્ગ પર તેના પરિવારનો દૃષ્ટિકોણ બદલાઈ રહ્યો છે. શિખર તેના પરિવારને આર્થિક રીતે પણ મદદ કરી શકે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "Chef",
              "hi": "शेफ ",
              "ka": "ಬಾಣಸಿಗ",
              "te": "చెఫ్ పాత్ర కోసం దరఖాస్తు చేసుకోవడం",
              "or": "ରୋଷେୟା",
              "as": "ৰান্ধনী",
              "gu": "રસોઈયો"
            }
          }
        ]
      },
      "scene30": {
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "Finish hotel management",
              "hi": "होटल प्रबंधन समाप्त करे",
              "ka": "ಹೊಟೇಲ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್ ಅನ್ನು ಪೂರೈಸುವುದು",
              "te": "హోటల్ మేనేజ్‌మెంట్ ముగించడం",
              "or": "ହୋଟେଲ ପରିଚାଳନା ସମାପ୍ତ କରନ୍ତୁ |",
              "as": "হোটেল মেনেজমেন্ট শেষ কৰা",
              "gu": "હોટેલ મેનેજમેન્ટ પૂરુ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar finishes his studies and gets his degree in Hotel Management.",
              "hi": "शिखर ने अपनी पढ़ाई समाप्त की और होटल प्रबंधन में अपनी डिग्री प्राप्त की।",
              "ka": "ಶಿಖರ್ ತನ್ನ ಅಧ್ಯಯನವನ್ನು ಮುಗಿಸಿ ಹೋಟೆಲ್ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್‌ನಲ್ಲಿ ಪದವಿಯನ್ನು ಪಡೆಯುತ್ತಾನೆ.",
              "te": "శిఖర్ తన చదువు పూర్తి చేసి హోటల్ మేనేజ్‌మెంట్ లో డిగ్రీ పొందాడు.",
              "or": "ଶିଖର ଅଧ୍ୟୟନ ସମାପ୍ତ କରି ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟରେ ଡିଗ୍ରୀ ହାସଲ କରନ୍ତି |",
              "as": "শিখৰে পঢ়া শেষ কৰে আৰু হোটেল মেনেজমেণ্টত ডিগ্ৰী পায়।",
              "gu": "શિખરે પોતાનો અભ્યાસ પૂરો કર્યો અને હોટેલ મેનેજમેન્ટની પદવી મેળવી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C10_1_2",
              "hi": "C10_1_2",
              "ka": "C10_1_2",
              "te": "C10_1_2",
              "or": "C10_1_2",
              "as": "C10_1_2",
              "gu": "C10_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Through his college placement, he gets selected as a management trainee in McMillan's, a popular fast food restaurant that has many branches around the country.",
              "hi": "अपने कॉलेज के द्वारा प्राप्त नियुक्ति से, उसे मैकमिलन के एक लोकप्रिय फास्ट फूड रेस्तरां जिसकी देश भर में अनेक शाखाएं हैं, में एक प्रबंधन ट्रेनी/ प्रशिक्षु के रूप में चुना जाता है ।",
              "ka": "ಅವನ ಕಾಲೇಜು ಪ್ಲೇಸ್ಮೆಂಟ್ ಮೂಲಕ, ಅವನು ಮ್ಯಾಕ್‌ಮಿಲನ್ಸ್‌ನಲ್ಲಿ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಟ್ರೈನಿಯಾಗಿ ಆಯ್ಕೆಯಾಗುತ್ತಾನೆ, ಇದು ದೇಶದಾದ್ಯಂತ ಅನೇಕ ಶಾಖೆಗಳನ್ನು ಹೊಂದಿರುವ ಜನಪ್ರಿಯ ಫಾಸ್ಟ್ ಫುಡ್ ರೆಸ್ಟೋರೆಂಟ್ ಆಗಿದೆ.",
              "te": "అతని కళాశాల నియామకం ద్వారా, అతను దేశవ్యాప్తంగా అనేక శాఖలను కలిగి ఉన్న ప్రముఖ ఫాస్ట్ ఫుడ్ రెస్టారెంట్ అయిన మెక్‌మిలన్స్‌లో మేనేజ్‌మెంట్ ట్రైనీగా ఎంపికయ్యాడు.",
              "or": "ତାଙ୍କ କଲେଜ୍ ପ୍ଲେସମେଣ୍ଟ୍ ମାଧ୍ୟମରେ, ସେ ମ୍ୟାକମିଲାନ୍ ର ଏକ ଲୋକପ୍ରିୟ ଫାଷ୍ଟଫୁଡ୍ ରେଷ୍ଟୁରାଣ୍ଟରେ ମ୍ୟାନେଜମେଣ୍ଟ୍ ଟ୍ରେନି ଭାବରେ ମନୋନୀତ ହୋଇଥିଲେ ଯାହାର ଦେଶରେ ଅନେକ ଶାଖା ଅଛି |",
              "as": "তাৰ কলেজৰ সংস্থাপনৰ জৰিয়তে, সি মেকমিলানছত মেনেজমেণ্ট ট্ৰেইনি হিচাপে বাছনি হয়, সমগ্ৰ দেশত বহুতো শাখা থকা এখন জনপ্ৰিয় ফাষ্ট ফুড ৰেষ্টুৰেণ্ট।",
              "gu": "તેના કોલેજ નિયુક્તિ દ્વારા, તે મેકમિલન, એક લોકપ્રિય ફાસ્ટ ફૂડ રેસ્ટોરન્ટમા સંચાલન તાલીમાર્થીની તરીકે પસંદગી પામે છે, જેની સમગ્ર દેશમાં ઘણી શાખાઓ છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C10_2_1",
              "hi": "C10_2_1",
              "ka": "C10_2_1",
              "te": "C10_2_1",
              "or": "C10_2_1",
              "as": "C10_2_1",
              "gu": "C10_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar joins McMillan’s as a management trainee. Here he works on various tasks like planning, training experienced staff, and participating in the hiring process.",
              "hi": "शिखर मैकमिलन में प्रबंधन ट्रेनी/ प्रशिक्षु के रूप में नौकरी करने लगता है। यहां वह योजना, प्रशिक्षण अनुभवी कर्मचारियों, और भर्ती प्रक्रिया में भाग लेने जैसे विभिन्न कार्यों पर काम करता है।",
              "ka": "ಶಿಖರ್ ಮ್ಯಾಕ್‌ಮಿಲನ್‌ಗೆ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್ ಟ್ರೈನಿಯಾಗಿ ಸೇರುತ್ತಾನೆ. ಇಲ್ಲಿ ಅವನು ಯೋಜನೆ, ಅನುಭವಿ ಸಿಬ್ಬಂದಿಗೆ ತರಬೇತಿ ನೀಡುವುದು ಮತ್ತು ನೇಮಕಾತಿ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ಭಾಗವಹಿಸುವಂತಹ ವಿವಿಧ ಕಾರ್ಯಗಳಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಾರೆ.",
              "te": "శిఖర్ మెక్‌మిలన్‌ లో మేనేజ్‌మెంట్ ట్రైనీగా చేరాడు. ఇక్కడ అతను ప్రణాళిక, అనుభవజ్ఞులైన సిబ్బందికి శిక్షణ ఇవ్వడం మరియు నియామక ప్రక్రియలో పాల్గొనడం వంటి వివిధ పనులపై పని చేస్తాడు.",
              "or": "ଶିଖର ଏକ ପରିଚାଳନା ପ୍ରଶିକ୍ଷକ ଭାବରେ ମ୍ୟାକମିଲାନକୁ ଯୋଗଦାନ କ\n ରନ୍ତି | ଏଠାରେ ସେ ଯୋଜନା, ଅଭିଜ୍ଞ କର୍ମଚାରୀଙ୍କୁ ତାଲିମ ଦେବା ଏବଂ ନିଯୁକ୍ତି\n  ପ୍ରକ୍ରିୟାରେ ଅଂଶଗ୍ରହଣ କରିବା ପରି ବିଭିନ୍ନ କାର୍ଯ୍ୟରେ କାର୍ଯ୍ୟ କରନ୍ତି |",
              "as": "শিখৰে মেকমিলানৰ এজন মেনেজমেণ্ট প্ৰশিক্ষাৰ্থী হিচাপে যোগ দিয়ে। ইয়াত সি বিভিন্ন কাম যেনে পৰিকল্পনা, অভিজ্ঞ কৰ্মচাৰীসকলক প্ৰশিক্ষণ দিয়া, আৰু নিয়োগ প্ৰক্ৰিয়াত অংশগ্ৰহণ কৰা আদিৰ ওপৰত কাম কৰে।",
              "gu": "શિખર મેકમિલન્સમાં મેનેજમેન્ટ ટ્રેઇની તરીકે જોડાય છે. અહીં તે આયોજન, અનુભવી  કર્મચારીઓને તાલીમ આપવા અને ભરતી પ્રક્રિયામાં ભાગ લેવા જેવા વિવિધ કાર્યો પર કામ કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C10_2_2",
              "hi": "C10_2_2",
              "ka": "C10_2_2",
              "te": "C10_2_2",
              "or": "C10_2_2",
              "as": "C10_2_2",
              "gu": "C10_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Since his work involves working with multiple departments, he builds a network with people having different skills and experiences.",
              "hi": "चूंकि उसको कई विभागों के साथ मिलकर काम करना है, इसलिए वह  भिन्न भिन्न कौशल वाले और अनुभवी लोगों के साथ एक नेटवर्क बनाता है।",
              "ka": "ಅವನ ಕೆಲಸವು ಬಹು ವಿಭಾಗಗಳೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುವುದರಿಂದ, ಆತನು ವಿಭಿನ್ನ ಕೌಶಲ್ಯ ಮತ್ತು ಅನುಭವಗಳನ್ನು ಹೊಂದಿರುವ ಜನರೊಂದಿಗೆ ನೆಟ್‌ವರ್ಕ್ ಅನ್ನು ನಿರ್ಮಿಸುತ್ತಾನೆ.",
              "te": "అతని పనిలో బహుళ విభాగాలతో కలిసి పని చేయడం వలన, అతను విభిన్న నైపుణ్యాలు మరియు అనుభవాలను కలిగి ఉన్న వ్యక్తులతో ఒక నెట్‌వర్క్ ను నిర్మిస్తాడు.",
              "or": "ଯେହେତୁ ତାଙ୍କର କାର୍ଯ୍ୟ ଏକାଧିକ ବିଭାଗ ସହିତ କାର୍ଯ୍ୟ କରିବା ସହିତ ଜଡିତ\n , ସେ ଭିନ୍ନ ଭିନ୍ନ କୌଶଳ ଏବଂ ଅଭିଜ୍ଞତା ଥିବା ଲୋକଙ୍କ ସହିତ ଏକ\n  ନେଟୱାର୍କ ନିର୍ମାଣ କରନ୍ତି |",
              "as": "যিহেতু তাৰ কামত একাধিক বিভাগৰ সৈতে কাম কৰিব লাগে, সি বিভিন্ন দক্ষতা আৰু অভিজ্ঞতা থকা লোকৰ সৈতে এক নেটৱৰ্ক গঢ়ি তোলে।",
              "gu": "તેના કાર્યમાં બહુવિધ વિભાગો સાથે કામ કરવાનો સમાવેશ થતો હોવાથી, તે વિવિધ કુશળતા અને અનુભવો ધરાવતા લોકો સાથે માળખુ બનાવે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "Trainee",
              "hi": "ट्रेनी",
              "ka": "ತರಬೇತಿ ಪಡೆದವರು",
              "te": "శిక్షణ పొందిన వ్యక్తి",
              "or": "ପ୍ରଶିକ୍ଷକ",
              "as": "প্ৰশিক্ষাৰ্থী",
              "gu": "તાલીમાર્થી"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C10_2_3",
              "hi": "C10_2_3",
              "ka": "C10_2_3",
              "te": "C10_2_3",
              "or": "C10_2_3",
              "as": "C10_2_3",
              "gu": "C10_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After a year and a half, Shikhar becomes an assistant manager at McMillan’s. He is happy with his career growth but feels that he is unable to give time to his interest in cooking.",
              "hi": "डेढ़ साल बाद, शिखर मैकमिलन में एक सहायक प्रबंधक बन गया है। वह अपने करियर की तरक्की से खुश है लेकिन अनुभव करता है कि वह खाना पकाने में अपनी रुचि के लिए समय नहीं दे पा रहा है।",
              "ka": "ಒಂದೂವರೆ ವರ್ಷದ ನಂತರ, ಶಿಖರ್ ಮೆಕ್‌ಮಿಲನ್ಸ್‌ನಲ್ಲಿ ಸಹಾಯಕ ವ್ಯವಸ್ಥಾಪಕನಾಗುತ್ತಾನೆ. ಅವನು ತನ್ನ ವೃತ್ತಿಜೀವನದ ಬೆಳವಣಿಗೆಯಿಂದ ಸಂತೋಷವಾಗಿದ್ದಾನೆ. ಆದರೆ ಅಡುಗೆಯಲ್ಲಿ ಅವನ ಆಸಕ್ತಿಗೆ ಸಮಯವನ್ನು ನೀಡಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ ಎಂದು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "ఏడాదిన్నర తర్వాత, శిఖర్ మెక్‌మిలన్‌ లో అసిస్టెంట్ మేనేజర్ అవుతాడు. అతను తన కెరీర్ ఎదుగుదలతో సంతోషంగా ఉన్నాడు, కానీ అతను తన వంటపై ఆసక్తికి సమయం ఇవ్వలేనని భావిస్తున్నాడు.",
              "or": "ଦେଢ ବର୍ଷ ପରେ, ଶିଖର ମ୍ୟାକମିଲାନଙ୍କ ସହକାରୀ ପରିଚାଳକ ହୁଅନ୍ତି\n  | ସେ ନିଜ କ୍ୟାରିୟରର ଅଭିବୃଦ୍ଧିରେ ଖୁସି କିନ୍ତୁ ରାନ୍ଧିବା ପାଇଁ ତାଙ୍କର ଆଗ୍ରହକୁ ସମୟ ଦେବାରେ ଅସମର୍ଥ ବୋଲି ଅନୁଭବ କରନ୍ତି |",
              "as": "ডেৰ বছৰৰ পিছত, শিখৰ মেকমিলানৰ সহকাৰী মেনেজাৰ হয়। সি তাৰ কেৰিয়াৰৰ বিকাশত সুখী কিন্তু ভাবে যে সি ৰন্ধনৰ প্ৰতি থকা আগ্ৰহক সময় দিবলৈ অক্ষম।",
              "gu": "દોઢ વર્ષ પછી, શિખર મેકમિલન ખાતે  મદદનીશ મેનેજર બને છે. તે તેની કારકિર્દીની વૃદ્ધિથી ખુશ છે પરંતુ તેને લાગે છે કે તે રસોઈમાં તેની રુચિને સમય આપી શકતો નથી."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C10_3_1",
              "hi": "C10_3_1",
              "ka": "C10_3_1",
              "te": "C10_3_1",
              "or": "C10_3_1",
              "as": "C10_3_1",
              "gu": "C10_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He starts his own social media page on his colleagues' suggestions, where he shares images of the dishes he makes and his experiments with different recipes.",
              "hi": "वह अपने सहयोगियों के सुझावों पर स्वयं का सोशल मीडिया पेज शुरु करता है, जहां वह उन व्यंजनों के चित्र जो वह बनाता है और विभिन्न व्यंजनों के साथ अपने प्रयोग साझा करता है।",
              "ka": "ಅವನು ತಮ್ಮ ಸಹೋದ್ಯೋಗಿಗಳ ಸಲಹೆಗಳ ಮೇಲೆ ತನ್ನದೇ ಆದ ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಪುಟವನ್ನು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ, ಅಲ್ಲಿ ಅವರು ಮಾಡುವ ಭಕ್ಷ್ಯಗಳ ಚಿತ್ರಗಳನ್ನು ಮತ್ತು ವಿಭಿನ್ನ ಪಾಕವಿಧಾನಗಳೊಂದಿಗೆ ತನ್ನ ಪ್ರಯೋಗಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾನೆ",
              "te": "అతను తన సహోద్యోగుల సూచనలపై తన స్వంత సోషల్ మీడియా పేజీని ప్రారంభించాడు, అక్కడ అతను తయారుచేసే వంటకాల చిత్రాలను మరియు విభిన్న వంటకాలతో తన ప్రయోగాలను పంచుకుంటాడు.",
              "or": "ସେ ନିଜ ସହକର୍ମୀଙ୍କ ପରାମର୍ଶରେ ନିଜର ସୋସିଆଲ୍ ମିଡିଆ ପୃଷ୍ଠା ଆରମ୍ଭ କ\n ରନ୍ତି, ଯେଉଁଠାରେ ସେ ପ୍ରସ୍ତୁତ କରୁଥିବା ଖାଦ୍ୟର ଚିତ୍ର ଏବଂ ବିଭିନ୍ନ ରେସିପି\n  ସହିତ ତାଙ୍କର ପରୀକ୍ଷଣ ଅଂଶୀଦାର କରନ୍ତି |",
              "as": "সি তাৰ সহকৰ্মীসকলৰ পৰামৰ্শৰ ওপৰত নিজৰ সামাজিক মাধ্যমৰ পৃষ্ঠা আৰম্ভ কৰে, য'ত সি বনোৱা ব্যঞ্জনবোৰৰ ছবি আৰু বিভিন্ন ৰেচিপিৰ সৈতে তাৰ পৰীক্ষা-নিৰীক্ষা ভাগ-বতৰা কৰে।",
              "gu": "તે તેના સાથીદારોના સૂચનો પર પોતાનું સોશિયલ મીડિયા પેજ શરૂ કરે છે, જ્યાં તે બનાવે છે તે વાનગીઓની છબીઓ અને વિવિધ વાનગીઓ સાથેના તેના પ્રયોગો શેર કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C10_3_2",
              "hi": "C10_3_2",
              "ka": "C10_3_2",
              "te": "C10_3_2",
              "or": "C10_3_2",
              "as": "C10_3_2",
              "gu": "C10_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He also travels around Indore, documenting the different types of street food available in the city. This hobby keeps Shikhar happy whenever his work becomes stressful.",
              "hi": "वह इंदौर के आसपास यात्रा भी करता है, और शहर में विभिन्न प्रकार के उपलब्ध स्ट्रीट फूड के बारे में  लिखता जाता है। जब भी उसका काम तनावपूर्ण हो जाता है तो यह शौक उसको खुश रखता है।",
              "ka": "ಆತನು ಇಂದೋರ್‌ನಾದ್ಯಂತ ಸಂಚರಿಸುತ್ತಾನೆ, ನಗರದಲ್ಲಿ ಲಭ್ಯವಿರುವ ವಿವಿಧ ರೀತಿಯ ಬೀದಿ ಆಹಾರವನ್ನು ದಾಖಲಿಸುತ್ತಾನೆ. ಈ ಹವ್ಯಾಸವು ಶಿಖರ್‌ಗೆ ತನ್ನ ಕೆಲಸವು ಒತ್ತಡವಾದಾಗಲೆಲ್ಲಾ ಸಂತೋಷವನ್ನು ನೀಡುತ್ತದೆ.",
              "te": "అతను ఇండోర్ చుట్టూ తిరుగుతూ, నగరంలో లభించే వివిధ రకాల స్ట్రీట్ ఫుడ్‌ లను డాక్యుమెంట్ చేస్తూ ఉంటాడు. ఈ అభిరుచి శిఖర్‌ని తన పని ఒత్తిడికి గురిచేసినప్పుడల్లా సంతోషంగా ఉంచుతుంది.",
              "or": "ସେ ସହରରେ ଉପଲବ୍ଧ ବିଭିନ୍ନ ପ୍ରକାରର ଷ୍ଟ୍ରିଟ୍ ଫୁଡ୍ ଡକ୍ୟୁମେଣ୍ଟ୍ କରି ଇନ୍ଦୋରରେ ମଧ୍ୟ ଭ୍ରମଣ କରନ୍ତି | ଯେତେବେଳେ ତାଙ୍କ କାର୍ଯ୍ୟ ଚାପଗ୍ରସ୍ତ ହୁଏ ଏହି ଅଭ୍ୟାସ ଶିଖରଙ୍କୁ ଖୁସି ରଖେ |",
              "as": "লগতে সি ইন্দোৰৰ চাৰিওফালে ঘূৰে, চহৰখনত পোৱা বিভিন্ন প্ৰকাৰৰ ৰাস্তাৰ খাদ্য তালিকা বনায়। যেতিয়াই তাৰ কামৰ হেঁচা পৰে এই চখটোৱে শিখৰক সুখী ৰাখে।",
              "gu": "તે શહેરમાં ઉપલબ્ધ વિવિધ પ્રકારના શેરી ખોરાકનું દસ્તાવેજીકરણ કરીને ઈન્દોરની આસપાસ પણ ફરે છે. જ્યારે પણ તેનું કામ તણાવપૂર્ણ બને છે ત્યારે આ શોખ શિખરને ખુશ રાખે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "C10_4_1",
              "hi": "C10_4_1",
              "ka": "C10_4_1",
              "te": "C10_4_1",
              "or": "C10_4_1",
              "as": "C10_4_1",
              "gu": "C10_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar’s family is happy to see him progress in his career and become financially independent.",
              "hi": "शिखर का परिवार उसकी अपने करियर में प्रगति देखकर और उसे आर्थिक रूप से स्वतंत्र देखकर खुश है।",
              "ka": "ಶಿಖರ್ ಕುಟುಂಬವು ಆತನು  ವೃತ್ತಿಜೀವನದಲ್ಲಿ ಪ್ರಗತಿ ಸಾಧಿಸಿರುವುದು ಹಾಗೂ  ಆರ್ಥಿಕವಾಗಿ ಸ್ವತಂತ್ರನಾಗಿರುವುದನ್ನು ಕಂದು ಸಂತೋಷಗೊಂಡಿದೆ..",
              "te": "శిఖర్ తన కెరీర్లో పురోగతి సాధించడం మరియు ఆర్థికంగా స్వతంత్రంగా మారడం చూసి అతని కుటుంబం సంతోషంగా ఉంది.",
              "or": "ତାଙ୍କ କ୍ୟାରିୟରରେ ଅଗ୍ରଗତି କରି ଆର୍ଥିକ ଦୃଷ୍ଟିରୁ ସ୍ଵାଧୀନ ହୋଇଥିବାର ଦେଖି ଶିଖରଙ୍କ ପରିବାର ଖୁସି ଅଛନ୍ତି।",
              "as": "শিখৰৰ পৰিয়ালে তাক তাৰ কেৰিয়াৰত আগবঢ়া দেখি আৰু আৰ্থিকভাৱে আত্মনিৰ্ভৰ হোৱা দেখি সুখী।",
              "gu": "શિખરનો પરિવાર તેને તેની કારકિર્દીમાં પ્રગતિ કરતો અને આર્થિક રીતે સ્વતંત્ર બનતો જોઈને ખુશ છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "Restaurant Manager",
              "hi": "रेस्टोरेंट प्रबंधक ",
              "ka": "ರೆಸ್ಟೋರೆಂಟ್ ಮ್ಯಾನೇಜರ್",
              "te": "రెస్టారెంట్ మేనేజర్",
              "or": "ରେଷ୍ଟୁରାଣ୍ଟ ମ୍ୟାନେଜର |",
              "as": "ৰেষ্টুৰেণ্ট মেনেজাৰ",
              "gu": "ભોજનાલય  મેનેજર"
            }
          }
        ]
      },
      "scene7": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Apply for commerce",
              "hi": "कॉमर्स/ वाणिज्य के लिए आवेदन करो ",
              "ka": "ವಾಣಿಜ್ಯಕ್ಕೆ ಅರ್ಜಿ ಹಾಕುವುದು",
              "te": "వాణిజ్యం కోసం దరఖాస్తు చేసుకోండి",
              "or": "ବାଣିଜ୍ୟ ପାଇଁ ଆବେଦନ କରନ୍ତୁ |",
              "as": "বাণিজ্যৰ বাবে আবেদন কৰা",
              "gu": "વાણિજ્ય માટે અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar enrols in a college in Indore to do B.com. The course is very different for him. It takes him some time to get used to the course.",
              "hi": " शिखर ने इंदौर के एक कॉलेज में बीकॉम करने के लिए नामांकन किया। कोर्स उसके लिए बहुत अलग है। इस कोर्स को समझने में उसे कुछ समय लगता है।",
              "ka": "B.com ಮಾಡಲು ಶಿಖರ್ ಇಂದೋರ್‌ನ ಕಾಲೇಜಿಗೆ ದಾಖಲಾಗುತ್ತಾನೆ. ಕೋರ್ಸ್ ಅವನಿಗೆ ತುಂಬಾ ವಿಭಿನ್ನವಾಗಿದೆ. ಕೋರ್ಸ್‌ಗೆ ಒಗ್ಗಿಕೊಳ್ಳಲು ಅವನಿಗೆ ಸ್ವಲ್ಪ ಸಮಯ ಬೇಕಾಗುತ್ತದೆ.",
              "te": "శిఖర్ B.com చేయడానికి ఇండోర్లోని ఒక కళాశాలలో చేరాడు. కోర్సు అతనికి చాలా భిన్నంగా ఉంటుంది. అతను కోర్సుకు అలవాటు పడటానికి కొంత సమయం పడుతుంది.",
              "or": "ବି.କମ୍ କରିବା ପାଇଁ ଶିଖର ଇନ୍ଦୋରର ଏକ କଲେଜରେ ନାମ ଲେଖାନ୍ତି | \n ତାଙ୍କ ପାଇଁ ପାଠ୍ୟକ୍ରମ ବହୁତ ଅଲଗା | ପାଠ୍ୟକ୍ରମରେ ଅଭ୍ୟସ୍ତ ହେବା ପାଇଁ\n  ତାଙ୍କୁ କିଛି ସମୟ ଲାଗେ |",
              "as": "শিখৰে B.com কৰিবলৈ ইন্দোৰৰ এখন কলেজত নাম লগায়। পাঠ্যক্ৰমটো তাৰ বাবে বহুত বেলেগ। পাঠ্যক্ৰমত অভ্যস্ত হ'বলৈ তাক কিছু সময় লাগে।",
              "gu": "શિખર B.com કરવા માટે ઈન્દોરની કોલેજમાં પ્રવેશ મેળવે છે. તેના માટે કોર્સ ખૂબ જ અલગ છે. કોર્સની આદત પડવા માટે તેને થોડો સમય લાગે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_1_2",
              "hi": "D1_1_2",
              "ka": "D1_1_2",
              "te": "D1_1_2",
              "or": "D1_1_2",
              "as": "D1_1_2",
              "gu": "D1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Even though it is not what he thought he would study, he likes subjects like financial accounting, management, and economics.",
              "hi": "हालांकि उसने कभी सोचा नहीं था कि वह ऐसे विषयों को पढ़ेगा, लेकिन वह फ़ाइनेन्शिअल अकाउंटिंग/ वित्तीय लेखा, प्रबंधन और अर्थशास्त्र जैसे विषयों को पसंद करता है।",
              "ka": "ತಾನು ಓದುತ್ತೇನೆಂದು ಅಂದುಕೊಂಡಿರದಿದ್ದರೂ ಫೈನಾನ್ಶಿಯಲ್ ಅಕೌಂಟಿಂಗ್, ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್, ಎಕನಾಮಿಕ್ಸ್‌ನಂತಹ ಸಬ್ಜೆಕ್ಟ್‌ಗಳನ್ನು ಇಷ್ಟಪಡುತ್ತಾನೆ.",
              "te": "తను చదువుకుంటానని అనుకున్నది కాకపోయినా ఫైనాన్షియల్ అకౌంటింగ్, మేనేజ్‌మెంట్, ఎకనామిక్స్ లాంటి సబ్జెక్టులంటే అతనికి ఇష్టం.",
              "or": "ଯଦିଓ ସେ ଅଧ୍ୟୟନ କରିବେ ବୋଲି ଭାବି ନଥିଲେ ମଧ୍ୟ ସେ ଆର୍ଥିକ ଆକାଉଣ୍ଟିଂ\n , ମ୍ୟାନେଜମେଣ୍ଟ ଏବଂ ଅର୍ଥନୀତି ଭଳି ବିଷୟ ପସନ୍ଦ କରନ୍ତି |",
              "as": "যদিও সি পঢ়িব বুলি ভবা নাছিল, সি বিত্তীয় হিচাপ, ব্যৱস্থাপনা আৰু অৰ্থনীতিৰ দৰে বিষয় ভাল পায়।",
              "gu": "તેમ છતાં તેણે વિચાર્યું હતું કે તે અભ્યાસ કરશે તેવું નથી, તેને નાણાકીય એકાઉન્ટિંગ, મેનેજમેન્ટ અને અર્થશાસ્ત્ર જેવા વિષયો ગમે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_1_3",
              "hi": "D1_1_3",
              "ka": "D1_1_3",
              "te": "D1_1_3",
              "or": "D1_1_3",
              "as": "D1_1_3",
              "gu": "D1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As he explores the course, he continues to add his thoughts and learnings in his diary.",
              "hi": "जैसे जैसे वह कोर्स के बारे में जानकारी प्राप्त करता जाता है, वह अपनी डायरी में अपने विचारों और सीख को लिखता रहता है।",
              "ka": "ಅವನು ಕೋರ್ಸ್ ಅನ್ನು ಅನ್ವೇಷಿಸುವಾಗ, ಅವನು ತನ್ನ ಆಲೋಚನೆಗಳು ಮತ್ತು ಕಲಿಕೆಗಳನ್ನು ತನ್ನ ದಿನಚರಿಯಲ್ಲಿ ಸೇರಿಸುವುದನ್ನು ಮುಂದುವರಿಸುತ್ತಾನೆ.",
              "te": "అతను కోర్సును అన్వేషిస్తున్నప్పుడు, అతను తన డైరీలో తన ఆలోచనలు మరియు అభ్యాసాలను జోడించడం కొనసాగిస్తాడు.",
              "or": "ସେ ପାଠ୍ୟକ୍ରମ ଅନୁସନ୍ଧାନ କଲାବେଳେ, ସେ ନିଜ ଚିନ୍ତାଧାରା ଏବଂ ଶିକ୍ଷଣକୁ\n  ନିଜ ଡାଏରୀରେ ଲେଖିବାକୁ ଲାଗିଲେ |",
              "as": "সি পাঠ্যক্ৰমটো বিচৰাৰ লগে লগে, সি তাৰ ডায়েৰীত তাৰ চিন্তা আৰু শিকাবোৰ লিখি আছে।",
              "gu": "જેમ જેમ તે અભ્યાસક્રમની શોધખોળ કરે છે, તેમ તેમ તે પોતાની ડાયરીમાં તેના વિચારો અને શિક્ષણ ઉમેરવાનું ચાલુ રાખે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_2_1",
              "hi": "D1_2_1",
              "ka": "D1_2_1",
              "te": "D1_2_1",
              "or": "D1_2_1",
              "as": "D1_2_1",
              "gu": "D1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "His favourite teacher is Bharati Ma’am, who has written books, articles on financial inclusion in rural India.",
              "hi": "उसकी पसंदीदा शिक्षिका भारती मैम हैं, जिन्होंने ग्रामीण भारत में वित्तीय समावेशन पर किताबें और लेख लिखे हैं।",
              "ka": "ಅವರ ನೆಚ್ಚಿನ ಶಿಕ್ಷಕಿ ಭಾರತಿ ಮೇಡಮ್, ಅವರು ಗ್ರಾಮೀಣ ಭಾರತದಲ್ಲಿ ಆರ್ಥಿಕ ಒಳಗೊಳ್ಳುವಿಕೆಯ ಬಗ್ಗೆ ಪುಸ್ತಕಗಳು, ಲೇಖನಗಳನ್ನು ಬರೆದಿದ್ದಾರೆ.",
              "te": "గ్రామీణ భారతదేశంలో ఆర్థిక చేరికపై పుస్తకాలు, వ్యాసాలు రాసిన భారతి మేడం అతని అభిమాన ఉపాధ్యాయురాలు.",
              "or": "ତାଙ୍କର ପ୍ରିୟ ଶିକ୍ଷକ ହେଉଛନ୍ତି ଭାରତୀ ମ୍ୟାମ, ଯିଏ ଗ୍ରାମୀଣ ଭାରତରେ ଆର୍ଥିକ ଅନ୍ତର୍ଭୂକ୍ତିକୁ ନେଇ ପୁସ୍ତକ, ପ୍ରବନ୍ଧ ଲେଖିଛନ୍ତି।",
              "as": "তাৰ প্ৰিয় শিক্ষক হৈছে ভাৰতী মেম, যি গ্ৰাম্য ভাৰতত বিত্তীয় অন্তৰ্ভুক্তিৰ ওপৰত কিতাপ, প্ৰবন্ধ লিখিছে।",
              "gu": "તેમના પ્રિય શિક્ષક ભારતી મેડમ છે, જેમણે ગ્રામીણ ભારતમાં નાણાકીય સમાવેશ પર પુસ્તકો, લેખો લખ્યા છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_2_22(bg)",
              "hi": "D1_2_22 (बीजी)",
              "ka": "D1_2_22(bg)",
              "te": "D1_2_22(bg)",
              "or": "D1_2_22(bg)",
              "as": "D1_2_22(bg)",
              "gu": "D1_2_22(bg)"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "One day, in the Business and financial management class -",
              "hi": "एक दिन, व्यापार और वित्तीय प्रबंधन की कक्षा में -",
              "ka": "ಒಂದು ದಿನ, ವ್ಯಾಪಾರ ಮತ್ತು ಹಣಕಾಸು ನಿರ್ವಹಣೆ ತರಗತಿಯಲ್ಲಿ -",
              "te": "ఒక రోజు, వ్యాపారం మరియు ఫైనాన్షియల్ మేనేజ్‌మెంట్ తరగతిలో -",
              "or": "ଦିନେ, ବ୍ୟବସାୟ ଏବଂ ଆର୍ଥିକ ପରିଚାଳନା ଶ୍ରେଣୀରେ -",
              "as": "এদিন, ব্যৱসায় আৰু বিত্তীয় ব্যৱস্থাপনা ক্লাছত -",
              "gu": "એક દિવસ, વ્યવસાય અને નાણાકીય વ્યવસ્થાપન વર્ગમાં -"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Bharati Ma'am",
              "hi": "भारती मैम",
              "ka": "ಭಾರತಿ ಮೇಡಮ್",
              "te": "భారతి మేడమ్",
              "or": "ଭାରତୀ ମ୍ୟାମ",
              "as": "ভাৰতী মেম",
              "gu": "ભારતી મેડમ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "As we have learnt about microfinance, the purpose behind such a scheme is to help the people from economically weaker sections of society to be able to take loans and generate income.",
              "hi": "जैसा कि हमने माइक्रोफाइनेंस के बारे में सीखा है, इस तरह की योजना का उद्देश्य समाज के आर्थिक रूप से कमजोर वर्गों के लोगों को ऋण लेने और आय उत्पन्न करने में सहायता करना है।",
              "ka": "ನಾವು ಕಿರುಬಂಡವಾಳದ ಬಗ್ಗೆ ಕಲಿತಂತೆ, ಇಂತಹ ಯೋಜನೆಯ ಹಿಂದಿನ ಉದ್ದೇಶವು ಸಮಾಜದ ಆರ್ಥಿಕವಾಗಿ ದುರ್ಬಲ ವರ್ಗದ ಜನರಿಗೆ ಸಾಲವನ್ನು ಪಡೆಯಲು ಮತ್ತು ಆದಾಯವನ್ನು ಗಳಿಸಲು ಸಹಾಯ ಮಾಡುವುದು.",
              "te": "మనము మైక్రోఫైనాన్స్ గురించి తెలుసుకున్నట్లుగా, అటువంటి పథకం వెనుక ఉద్దేశ్యం ఏమిటంటే, సమాజంలోని ఆర్థికంగా బలహీన వర్గాల ప్రజలు రుణాలు తీసుకోవడానికి మరియు ఆదాయాన్ని సంపాదించడానికి సహాయం చేయడం.",
              "or": "ଯେହେତୁ ଆମେ ମାଇକ୍ରୋ ଫାଇନାନ୍ସ ବିଷୟରେ ଜାଣିଛୁ, ଏହିପରି ଏକ ଯୋଜନାର ଉଦ୍ଦେଶ୍ୟ ହେଉଛି ସମାଜର ଅର୍ଥନିତିକ ଦୁର୍ବଳ ଶ୍ରେଣୀର ଲୋକଙ୍କୁ\n ରୁଣ ନେବା ଏବଂ ଆୟ କରିବାରେ ସକ୍ଷମ ହେବା |",
              "as": "আমি মাইক্ৰ'ফাইনেন্সৰ বিষয়ে শিকিছোঁ, এনে আঁচনিৰ আঁৰৰ উদ্দেশ্য হৈছে সমাজৰ অৰ্থনৈতিকভাৱে দুৰ্বল শ্ৰেণীৰ লোকসকলক ঋণ ল'বলৈ আৰু উপাৰ্জন সৃষ্টি কৰিবলৈ সক্ষম হোৱাত সহায় কৰা।",
              "gu": "જેમ આપણે માઇક્રોફાઇનાન્સ વિશે શીખ્યા છીએ, આવી યોજના પાછળનો હેતુ સમાજના આર્થિક રીતે નબળા વર્ગના લોકોને લોન લેવા અને આવક ઉત્પાદન કરવામાં સક્ષમ બનવામાં મદદ કરવાનો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Ma’am, but then what is the difference between bank loans and microfinance.",
              "hi": "महोदया, लेकिन फिर बैंक ऋण और माइक्रोफाइनेंस के बीच क्या अंतर है।",
              "ka": "ಮೇಡಂ, ಆದರೆ ಬ್ಯಾಂಕ್ ಸಾಲಗಳು ಮತ್ತು ಮೈಕ್ರೋಫೈನಾನ್ಸ್ ನಡುವಿನ ವ್ಯತ್ಯಾಸವೇನು.",
              "te": "మేడమ్, అయితే బ్యాంకు రుణాలు మరియు మైక్రోఫైనాన్స్ మధ్య తేడా ఏమిటి.",
              "or": "ମ୍ୟାମ ’, କିନ୍ତୁ ତାହେଲେ ବ୍ୟାଙ୍କ ରୁଣ ଏବଂ ମାଇକ୍ରୋ ଫାଇନାନ୍ସ ମଧ୍ୟରେ \n ପାର୍ଥକ୍ୟ କ’ଣ?",
              "as": "মেম, কিন্তু তেতিয়া বেঙ্ক ঋণ আৰু মাইক্ৰ'ফাইনেন্সৰ মাজত পাৰ্থক্য কি।",
              "gu": "મેડમ, પરંતુ પછી બેંક લોન અને માઇક્રોફાઇનાન્સમાં શું તફાવત છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_2_3///",
              "hi": "D1_2_3 ///",
              "ka": "D1_2_3///",
              "te": "D1_2_3///",
              "or": "D1_2_3///",
              "as": "D1_2_3///",
              "gu": "D1_2_3///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "People who lack financial capital are hesitant to show security to take loans from banks or local money lenders. People can avoid borrowing money with high-interest rates by opting for microloans.",
              "hi": "जिन लोगों को वित्तीय पूंजी की कमी है, वे बैंकों या स्थानीय साहूकारों से ऋण लेने के लिए सिक्यूरिटी दिखने में संकोच करते हैं। लोग माइक्रोलोंस का चयन करके उच्च ब्याज दरों पर उधार लेने से बच सकते हैं।",
              "ka": "ಹಣಕಾಸಿನ ಬಂಡವಾಳದ ಕೊರತೆಯಿರುವ ಜನರು ಬ್ಯಾಂಕ್‌ಗಳು ಅಥವಾ ಸ್ಥಳೀಯ ಲೇವಾದೇವಿದಾರರಿಂದ ಸಾಲವನ್ನು ಪಡೆಯಲು ಭದ್ರತೆಯನ್ನು ತೋರಿಸಲು ಹಿಂಜರಿಯುತ್ತಾರೆ. ಮೈಕ್ರೊಲೋನ್‌ಗಳನ್ನು ಆರಿಸಿಕೊಳ್ಳುವ ಮೂಲಕ ಜನರು ಹೆಚ್ಚಿನ-ಬಡ್ಡಿ ದರಗಳೊಂದಿಗೆ ಹಣವನ್ನು ಎರವಲು ಪಡೆಯುವುದನ್ನು ತಪ್ಪಿಸಬಹುದು.",
              "te": "ఆర్థిక మూలధనం లేని వ్యక్తులు బ్యాంకులు లేదా స్థానిక రుణదాతల నుండి రుణాలు తీసుకోవడానికి సెక్యూరిటీని చూపించడానికి వెనుకాడతారు. మైక్రోలోన్‌లను ఎంచుకోవడం ద్వారా ప్రజలు అధిక-వడ్డీ రేట్లతో డబ్బు తీసుకోకుండా నివారించవచ్చు.",
              "or": "ଯେଉଁମାନଙ୍କର ଆର୍ଥିକ ପୁଞ୍ଜି ଅଭାବ ଥାଏ ସେମାନେ ବ୍ୟାଙ୍କ କିମ୍ବା ସ୍ଥାନୀୟ ରୁଣଦାତାଙ୍କଠାରୁ ରୁଣ ନେବାକୁ ସୁରକ୍ଷା ଦୃଷ୍ଟିରୁ ଦ୍ୱିଧା କରନ୍ତି | ଲୋକମାନେ ମାଇକ୍ରୋଲୋନ୍ ଚୟନ କରି ଉଚ୍ଚ ସୁଧ ହାର ସହିତ ଟଙ୍କା ଉଧାର ନେବାରୁ ଦୂରେଇ ରହିପାରିବେ |",
              "as": "যিসকল লোকৰ বিত্তীয় মূলধনৰ অভাৱ আছে তেওঁলোকে বেংক বা স্থানীয় ধন দাতাৰ পৰা ঋণ ল'বলৈ সুৰক্ষাবোধ নকৰে। লোকসকলে মাইক্ৰ'লোন বাছনি কৰি উচ্চ সূতত ধন ধাৰ নল'ব পাৰে।",
              "gu": "જે લોકો પાસે નાણાકીય મૂડીનો અભાવ છે તેઓ બેંકો અથવા સ્થાનિક નાણા ધિરાણકર્તાઓ પાસેથી લોન લેવા માટે સુરક્ષા બતાવવામાં અચકાય છે. લોકો માઇક્રોલોન્સનો વિકલ્પ પસંદ કરીને ઊંચા વ્યાજ દરો સાથે નાણાં ઉછીના લેવાનું ટાળી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "That is true, ma’am. I know that my own family has been exploited by money lenders who gave loans at high interests.",
              "hi": "यह सच है, महोदया। मुझे पता है कि मेरे परिवार का शोषण साहूकारों द्वारा किया गया है, जिन्होंने उच्च ब्याज दरों पर ऋण दिया था।",
              "ka": "ಅದು ನಿಜ, ಮೇಡಂ. ಹೆಚ್ಚಿನ ಬಡ್ಡಿಗೆ ಸಾಲ ನೀಡಿದ ಲೇವಾದೇವಿದಾರರಿಂದ ನನ್ನ ಸ್ವಂತ ಕುಟುಂಬ ಶೋಷಣೆಗೆ ಒಳಗಾಗಿದೆ ಎಂದು ನನಗೆ ತಿಳಿದಿದೆ.",
              "te": "అది నిజం, మేడమ్. అధిక వడ్డీలకు అప్పులు ఇచ్చిన వడ్డీ వ్యాపారుల వల్ల నా కుటుంబమే దోపిడీకి గురైందని నాకు తెలుసు.",
              "or": "ତାହା ସତ, ମ୍ୟାମ ’ ମୁଁ ଜାଣେ ଯେ ମୋ ନିଜ ପରିବାର ଟଙ୍କା ରୁଣଦାତାମାନଙ୍କ ଦ୍ୱାରା ଶୋଷିତ ହୋଇଛନ୍ତି ଯେଉଁମାନେ ଉଚ୍ଚ ସୁଧହାର ରେ ରୁଣ ଦେଇଛନ୍ତି |",
              "as": "সেয়া সঁচা, মেম। মই জানো যে মোৰ নিজৰ পৰিয়ালটোকে ধনদাতাসকলে শোষণ কৰিছে যিয়ে উচ্চ সুতৰ হাৰত ঋণ দিছিল।",
              "gu": "તે સાચું છે, મેડમ. હું જાણું છું કે મારા પોતાના પરિવારનું નાણાં શાહુકાર દ્વારા શોષણ કરવામાં આવ્યું છે જેમણે ઊંચા વ્યાજે લોન આપી હતી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Yes, microfinance has also helped bring social change by providing women in various self-help groups with small loans to set up their practice and become financially independent.",
              "hi": "हां, माइक्रोफाइनेंस ने महिलाओं को अपने व्यवसाय को चलाने और वित्तीय रूप से स्वतंत्र बनाने के लिए छोटे ऋण वाले विभिन्न स्व-सहायता समूहों में ऋण प्रदान करके सामाजिक परिवर्तन लाने में भी सहायता की है।",
              "ka": "ಹೌದು, ಮೈಕ್ರೋ ಫೈನಾನ್ಸ್ ವಿವಿಧ ಸ್ವ-ಸಹಾಯ ಗುಂಪುಗಳಲ್ಲಿ ಮಹಿಳೆಯರಿಗೆ ತಮ್ಮ ಅಭ್ಯಾಸವನ್ನು ಸ್ಥಾಪಿಸಲು ಮತ್ತು ಆರ್ಥಿಕವಾಗಿ ಸ್ವತಂತ್ರರಾಗಲು ಸಣ್ಣ ಸಾಲಗಳನ್ನು ಒದಗಿಸುವ ಮೂಲಕ ಸಾಮಾಜಿಕ ಬದಲಾವಣೆಯನ್ನು ತರಲು ಸಹಾಯ ಮಾಡಿದೆ.",
              "te": "అవును, మైక్రోఫైనాన్స్ వివిధ స్వయం-సహాయక సంఘాలలోని మహిళలకు వారి అభ్యాసాన్ని ఏర్పాటు చేసుకోవడానికి మరియు ఆర్థికంగా స్వతంత్రంగా ఉండటానికి చిన్న రుణాలను అందించడం ద్వారా సామాజిక మార్పును తీసుకురావడానికి కూడా సహాయపడింది.",
              "or": "ହଁ, ମାଇକ୍ରୋ ଫାଇନାନ୍ସ ମଧ୍ୟ ବିଭିନ୍ନ ସ୍ଵୟଂ ସହାୟକ ଗୋଷ୍ଠୀର ମହିଳାମାନଙ୍କୁ\n  ସେମାନଙ୍କର ଅଭ୍ୟାସ ପ୍ରତିଷ୍ଠା ଏବଂ ଆର୍ଥିକ ସ୍ତରରେ ସ୍ଵାଧୀନ ହେବା\n  ପାଇଁ ଛୋଟ ରୁଣ ଯୋଗାଇ ସାମାଜିକ ପରିବର୍ତ୍ତନ ଆଣିବାରେ ସାହାଯ୍ୟ କରିଛି |",
              "as": "হয়, মাইক্ৰ'ফাইনেন্সে বিভিন্ন আত্মসহায়ক গোটৰ মহিলাসকলক তেওঁলোকৰ ব্যৱসায় কৰিবলৈ আৰু আৰ্থিকভাৱে স্বাৱলম্বী হ'বলৈ সৰু ঋণ দি সামাজিক পৰিৱৰ্তন অনাত সহায় কৰিছে।",
              "gu": "હા, માઇક્રોફાઇનાન્સે વિવિધ સ્વ-સહાય જૂથોમાં મહિલાઓને તેમની પ્રેક્ટિસ સ્થાપિત કરવા અને આર્થિક રીતે સ્વતંત્ર બનવા માટે નાની લોન આપીને સામાજિક પરિવર્તન લાવવામાં પણ મદદ કરી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Oh really?",
              "hi": "क्या वास्तव में?",
              "ka": "ಓಹ್, ಹೌದೇ?",
              "te": "అవునా, నిజంగానా?",
              "or": "ଓଃ ସତରେ?",
              "as": "সঁচাকে?",
              "gu": "ખરેખર?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Yes! But like all finance schemes, one has to be careful and understand the loan amount they borrow and the rules for repaying the loan amount.",
              "hi": "हां! लेकिन सभी वित्त योजनाओं की तरह, सबको सावधान रहना चाहिए और ऋण की राशि को जो वे लेते हैं और ऋण राशि चुकाने के नियमों को अच्छी तरह समझना चाहिए।",
              "ka": "ಹೌದು! ಆದರೆ ಎಲ್ಲಾ ಹಣಕಾಸು ಯೋಜನೆಗಳಂತೆ, ಒಬ್ಬರು ಜಾಗರೂಕರಾಗಿರಬೇಕು ಮತ್ತು ಅವರು ಎರವಲು ಪಡೆಯುವ ಸಾಲದ ಮೊತ್ತ ಮತ್ತು ಸಾಲದ ಮೊತ್ತವನ್ನು ಮರುಪಾವತಿ ಮಾಡುವ ನಿಯಮಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಬೇಕು.",
              "te": "అవును! కానీ అన్ని ఫైనాన్స్ స్కీమ్‌ల మాదిరిగానే, ఒకరు జాగ్రత్తగా ఉండాలి మరియు వారు తీసుకున్న రుణ మొత్తాన్ని మరియు రుణ మొత్తాన్ని తిరిగి చెల్లించే నియమాలను అర్థం చేసుకోవాలి.",
              "or": "ହଁ! କିନ୍ତୁ ସମସ୍ତ ଫାଇନାନ୍ସ ସ୍କିମ୍ ପରି, ଜଣେ ଯତ୍ନବାନ ହେବା ଆବଶ୍ୟକ ଏବଂ\n ସେମାନେ ରୁଣ ପରିମାଣ ଏବଂ ରୁଣ ପରିଶୋଧ କରିବାର ନିୟମ ବୁଝିବା ଆବଶ୍ୟକ |",
              "as": "হয়! কিন্তু সকলো বিত্তীয় আঁচনিৰ দৰে, তেওঁলোকে ধাৰ লোৱা ঋণৰ পৰিমাণ আৰু ঋণৰ পৰিমাণ পৰিশোধ কৰাৰ নিয়মবোৰৰ ক্ষেত্ৰত সাৱধান হ'ব লাগিব আৰু বুজিব লাগিব।",
              "gu": "હા! પરંતુ તમામ ફાઇનાન્સ યોજનાની જેમ, વ્યક્તિએ સાવચેત રહેવું જોઈએ અને તેણે લીધેલી લોનની રકમ અને લોનની રકમ ચૂકવવાના નિયમોને સમજવું પડશે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_2_4(notebook)",
              "hi": "D1_2_4 (नोटबुक)",
              "ka": "D1_2_4(notebook)",
              "te": "D1_2_4(పుస్తకం)",
              "or": "D1_2_4(notebook)",
              "as": "D1_2_4(notebook)",
              "gu": "D1_2_4(નોટબુક)"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He quickly makes a note about his learnings in his diary",
              "hi": "वह जल्दी से अपनी डायरी में इस सीख के विषय में लिखता है",
              "ka": "ಅವನು ತನ್ನ ಡೈರಿಯಲ್ಲಿ ತನ್ನ ಕಲಿಕೆಯ ಬಗ್ಗೆ ತ್ವರಿತವಾಗಿ ಟಿಪ್ಪಣಿ ಮಾಡುತ್ತಾನೆ",
              "te": "అతను తన డైరీలో తను నేర్చుకున్న వాటి గురించి నోట్ చేసుకున్నాడు",
              "or": "ସେ ନିଜ ଡାଏରୀରେ ଶିଖିବା ବିଷୟରେ ଶୀଘ୍ର ଏକ ଟିପ୍ପଣୀ କରନ୍ତି |",
              "as": "সি লগে লগে তাৰ ডায়েৰীত তাৰ শিকাৰ বিষয়ে এটা টোকা বনায়",
              "gu": "તે ઝડપથી તેની ડાયરીમાં તેના શીખવાની નોંધ બનાવે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page 1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "ପ୍ରୁଷ୍ଠା ୧",
              "as": "পৃষ্ঠা 1",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page 2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ 2",
              "or": "ପ୍ରୁଷ୍ଠା ୨",
              "as": "পৃষ্ঠা 2",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Understanding finances is a powerful tool for everyone.There are so many things that people in the village don’t know about. Kaka from Ratlam did not fence his farm because he didn’t want to take a loan from Mahinder Bhaiya.",
              "hi": "वित्त को समझना प्रत्येक के लिए एक शक्तिशाली टूल/ उपकरण है। इतनी सारी चीजें हैं जिनके बारे गांव के लोगों को नहीं पता है। रतलाम के काका ने अपने खेत में बाड़ नहीं लगाई क्योंकि वह महिंदर भैया से ऋण नहीं लेना चाहते थे।",
              "ka": "ಹಣಕಾಸಿನ ತಿಳುವಳಿಕೆಯು ಪ್ರತಿಯೊಬ್ಬರಿಗೂ ಪ್ರಬಲವಾದ ಸಾಧನವಾಗಿದೆ. ಹಳ್ಳಿಯ ಜನರಿಗೆ ತಿಳಿದಿಲ್ಲದ ಹಲವಾರು ವಿಷಯಗಳಿವೆ. ರತ್ಲಾಮ್‌ನ ಕಾಕಾ ಅವರು ಮಹಿಂದರ್ ಭಯ್ಯಾ ಅವರಿಂದ ಸಾಲ ಪಡೆಯಲು ಬಯಸದ ಕಾರಣ ತಮ್ಮ ಜಮೀನಿಗೆ ಬೇಲಿ ಹಾಕಲಿಲ್ಲ.",
              "te": "ఆర్థిక విషయాలను అర్థం చేసుకోవడం ప్రతి ఒక్కరికీ శక్తివంతమైన సాధనం.గ్రామంలో ప్రజలకు తెలియని చాలా విషయాలు ఉన్నాయి. రత్లాంకు చెందిన కాకా మహీందర్ భయ్యా నుంచి అప్పు తీసుకోవడం ఇష్టంలేక తన పొలానికి కంచె వేయలేదు.",
              "or": "ଫାଇନାନ୍ସ କୁ ବୁଝିବା ସମସ୍ତଙ୍କ ପାଇଁ ଏକ ଶକ୍ତିଶାଳୀ ସାଧନ ଅଟେ | ସେଠାରେ \n ବହୁତଗୁଡ଼ିଏ ଜିନିଷ ଅଛି ଯାହା ଗାଁର ଲୋକମାନେ ଜାଣନ୍ତି ନାହିଁ | ରତ୍ଲାମର କାକା \n ତାଙ୍କ ଚାଷ ଜମିରେ ବାଡ କରି ନଥିଲେ କାରଣ ସେ ମହିନ୍ଦର ଭାଇଙ୍କ ଠାରୁ\n ରୁଣ ନେବାକୁ ଚାହୁଁନଥିଲେ।",
              "as": "বিত্ত বুজাটো সকলোৰে বাবে এক শক্তিশালী সঁজুলি। এনে বহুতো বস্তু আছে যাৰ বিষয়ে গাওঁখনৰ মানুহে নাজানে। ৰতলামৰ কাকাই তেওঁৰ খেতিপথাৰত বেৰা দিয়া নাছিল কাৰণ তেওঁ মহিন্দৰ দাদাৰ পৰা ঋণ ল'ব নিবিচাৰিছিল।",
              "gu": "દરેક વ્યક્તિ માટે નાણાંકીય બાબતોને સમજવું એ એક શક્તિશાળી સાધન છે. એવી ઘણી બધી વસ્તુઓ છે જેના વિશે ગામના લોકો જાણતા નથી. રતલામના કાકાએ તેમના ખેતરમાં વાડ નથી લગાવી કારણ કે તેઓ મહિન્દરભાઈ પાસેથી લોન લેવા માંગતા ન હતા."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "My family could have benefited from this knowledge as well.  If we knew that microloans exist, life would have been different. When I read about the career path in microfinance online, the general view is that it is very satisfying though it does not pay well initially.",
              "hi": "मेरा परिवार भी इस ज्ञान से  लाभान्वित हो सकता था। अगर हम जानते कि माइक्रोलोंस जैसी कोई सुविधा है, तो जीवन कुछ अलग ही होता। जब मैंने माइक्रोफाइनेंस में करियर पथ के बारे में ऑनलाइन पढ़ा, तो सामान्य विचार है कि यह बहुत संतोषजनक है, हालांकि यह शुरुआत में अच्छी तरह से भुगतान नहीं करता है।",
              "ka": "ಈ ಜ್ಞಾನದಿಂದ ನನ್ನ ಕುಟುಂಬವೂ ಪ್ರಯೋಜನ ಪಡೆಯಬಹುದಿತ್ತು. ಕಿರುಸಾಲಗಳಿವೆ ಎಂದು ನಮಗೆ ತಿಳಿದಿದ್ದರೆ, ಜೀವನವು ವಿಭಿನ್ನವಾಗಿರುತ್ತಿತ್ತು. ಮೈಕ್ರೋಫೈನಾನ್ಸ್‌ನಲ್ಲಿ ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ವೃತ್ತಿಜೀವನದ ಹಾದಿಯ ಬಗ್ಗೆ ನಾನು ಓದಿದಾಗ, ಆರಂಭದಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಪಾವತಿಸದಿದ್ದರೂ ಇದು ತುಂಬಾ ತೃಪ್ತಿಕರವಾಗಿದೆ ಎಂಬುದು ಸಾಮಾನ್ಯ ದೃಷ್ಟಿಕೋನವಾಗಿದೆ.",
              "te": "ఈ జ్ఞానం ఉండి ఉంటే నా కుటుంబం కూడా ప్రయోజనం పొంది ఉండవచ్చు. మైక్రోలోన్‌ లు ఉన్నాయని మనకు తెలిస్తే, జీవితం భిన్నంగా ఉండేది. నేను ఆన్‌లైన్లో మైక్రోఫైనాన్స్ లో కెరీర్ మార్గం గురించి చదివినప్పుడు, ప్రారంభంలో బాగా చెల్లించనప్పటికీ ఇది చాలా సంతృప్తికరంగా ఉందని సాధారణ అభిప్రాయం.",
              "or": "ମୋ ପରିବାର ମଧ୍ୟ ଏହି ଜ୍ଞାନରୁ ଉପକୃତ ହୋଇଥାନ୍ତେ | ଯଦି ଆମେ ଜାଣିଥାନ୍ତୁ \n  ମାଇକ୍ରୋଲୋନ ବୋଲି କିଛି ଅଛି, ଜୀବନ ଅଲଗା ହୋଇଥାନ୍ତା | ଯେତେବେଳେ ମୁଁ ଅନଲାଇନ ରେ ମାଇକ୍ରୋ ଫାଇନାନ୍ସରେ କ୍ୟାରିଅର ବିଷୟରେ ପଢେ, ସାଧାରଣ ଭାବେ ଏହା ଅତ୍ୟନ୍ତ ସନ୍ତୋଷଜନକ ଯଦିଓ ଏହା ପ୍ରାରମ୍ଭରେ ଭଲ ଦେୟ ଦିଏ ନାହିଁ |",
              "as": "মোৰ পৰিয়ালে এই জ্ঞানৰ পৰাও লাভান্বিত হ'ব পাৰিলেহেঁতেন। যদি আমি মাইক্ৰ'লোনৰ বিষয়ে জানিলোহেঁতেন, জীৱন বেলেগ হ'লহেঁতেন। যেতিয়া মই অনলাইনত মাইক্ৰ'ফাইনেন্সত কেৰিয়াৰৰ পথৰ বিষয়ে পঢ়োঁ, সাধাৰণ দৃষ্টিত ই অতি সন্তোষজনক যদিও ই আৰম্ভণিতে ভাল পৰিশোধ নকৰে।",
              "gu": "મારા પરિવારને પણ આ જ્ઞાનનો લાભ મળ્યો હોત. જો આપણે જાણતા હોત કે માઇક્રોલોન્સ અસ્તિત્વમાં છે, તો જીવન અલગ હોત. જ્યારે હું માઇક્રોફાઇનાન્સમાં કારકિર્દીના માર્ગ વિશે ઓનલાઈન વાંચું છું, ત્યારે સામાન્ય અભિપ્રાય એ છે કે તે ખૂબ જ સંતોષકારક છે જો કે તે શરૂઆતમાં સારી ચૂકવણી કરતું નથી."
            }
          }
        ]
      },
      "scene8": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_1_1",
              "hi": "D2_1_1",
              "ka": "D2_1_1",
              "te": "D2_1_1",
              "or": "D2_1_1",
              "as": "D2_1_1",
              "gu": "D2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar has completed the 5th semester and is looking forward to the last semester as there will be companies visiting the college for placements.",
              "hi": "शिखर ने पांचवां सेमेस्टर पूरा कर लिया है और अंतिम सेमेस्टर की ओर देख रहा है क्योंकि उसमें प्लेसमेंट/ नियुक्ति के लिए कंपनियां कॉलेज आएँगी।",
              "ka": "ಶಿಖರ್ 5ನೇ ಸೆಮಿಸ್ಟರ್‌ ಮುಗಿಸಿದ್ದು, ಕೊನೆಯ ಸೆಮಿಸ್ಟರ್‌ಗಾಗಿ ಎದುರು ನೋಡುತ್ತಿದ್ದಾನೆ, ಏಕೆಂದರೆ ಪ್ಲೇಸ್‌ಮೆಂಟ್‌ಗಾಗಿ ಕಾಲೇಜಿಗೆ ಕಂಪನಿಗಳು ಭೇಟಿ ನೀಡುತ್ತವೆ.",
              "te": "శిఖర్ 5వ సెమిస్టర్ పూర్తి చేసి, ప్లేస్‌మెంట్ల కోసం కళాశాలకి వచ్చే కంపెనీలు ఉండటంతో చివరి సెమిస్టర్ కోసం ఎదురు చూస్తున్నాడు.",
              "or": "ଶିଖର ୫ମ ସେମିଷ୍ଟାର ସମାପ୍ତ କରିଛନ୍ତି ଏବଂ ଶେଷ ସେମିଷ୍ଟାରକୁ ଅପେକ୍ଷା\n  କରିଛନ୍ତି କାରଣ ପ୍ଲେସମେଣ୍ଟ ପାଇଁ କଲେଜ ପରିଦର୍ଶନ କରୁଥିବା କମ୍ପାନୀଗୁଡିକ ଆସିବେ |",
              "as": "শিখৰে ৫ম ছেমিষ্টাৰ সম্পূৰ্ণ কৰিছে আৰু শেষ ছেমিষ্টাৰৰ বাবে ৰৈ আছে কিয়নো তাত কোম্পানীবোৰে সংস্থাপনৰ বাবে কলেজলৈ আহিব।",
              "gu": "શિખરે 5મું સત્ર પૂરું કર્યું છે અને છેલ્લા સત્રની રાહ જોઈ રહ્યો છે કારણ કે નિયુક્તિ માટે કોલેજની મુલાકાત લેતી કંપનીઓ હશે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_1_1(bg)",
              "hi": "D2_1_1 (बीजी)",
              "ka": "D2_1_1(bg)",
              "te": "D2_1_1(bg)",
              "or": "D2_1_1(bg)",
              "as": "D2_1_1(bg)",
              "gu": "D2_1_1(bg)"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar’s Babuji seems to be very happy that he is about to complete his course.",
              "hi": "शिखर के बाबूजी बहुत खुश हैं कि वह अपने कोर्स को पूरा करने वाला है।",
              "ka": "ಶಿಖರ್‌ನ ಬಾಬೂಜಿ ಅವನು ತನ್ನ ಕೋರ್ಸ್ ಅನ್ನು ಪೂರ್ಣಗೊಳಿಸುತ್ತಿರುವುದರಿಂದ ಬಹಳ ಸಂತೋಷಪಟ್ಟಿದ್ದಾರೆ",
              "te": "శిఖర్ నాన్నగారు తన కోర్సును పూర్తి చేయబోతున్నందుకు చాలా సంతోషంగా ఉన్నట్లు తెలుస్తోంది.",
              "or": "ଶିଖରଙ୍କ ବାବୁଜୀ ବହୁତ ଖୁସି ମନେ ହେଉଛନ୍ତି ଯେ ସେ ତାଙ୍କ ପାଠ୍ୟକ୍ରମ ଶେଷକରିବାକୁ ଯାଉଛନ୍ତି |",
              "as": "শিখৰৰ দেউতাক বহুত সুখী যেন লাগিছে যে সি তাৰ পাঠ্যক্ৰম শেষ কৰিবলৈ ওলাইছে।",
              "gu": "શિખરના બાબુજી ખૂબ જ ખુશ લાગે છે કે તેઓ તેમનો અભ્યાસક્રમ પૂરો કરવા જઈ રહ્યા છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Babuji",
              "hi": "बाबूजी",
              "ka": "ಬಾಬುಜಿ",
              "te": "నాన్నగారు",
              "or": "ବାବୁଜୀ",
              "as": "দেউতা",
              "gu": "બાબુજી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Now that you will have a job soon, it will remove some of the burdens of running the house from Arun and me.",
              "hi": "अब जब तुम जल्द ही नौकरी करोगे, यह घर चलाने में अरुण और मेरा कुछ बोझ को हल्का कर देगा।",
              "ka": "ಈಗ ನಿನಗೆ ಶೀಘ್ರದಲ್ಲೇ ಕೆಲಸ ಸಿಗಲಿದೆ, ಇದು ಅರುಣ್ ಮತ್ತು ನನ್ನಿಂದ ಮನೆ ನಡೆಸುವ ಕೆಲವು ಹೊರೆಗಳನ್ನು ತೆಗೆದುಹಾಕುತ್ತದೆ.",
              "te": "ఇప్పుడు నీకు త్వరలో ఉద్యోగం వస్తుంది, అది అరుణ్ మరియు నా నుండి ఇంటిని నడిపించే కొంత భారాన్ని తొలగిస్తుంది.",
              "or": "ବର୍ତ୍ତମାନ ତୁମର ଶୀଘ୍ର ଚାକିରି ପାଇବ, ଏହା ଅରୁଣ ଏବଂ ମୋ ଠାରୁ ଘର ଚଳାଇବାର\n  କିଛି ଭାର ଦୂର କରିବ |",
              "as": "এতিয়া যিহেতু তোমাৰ সোনকালে চাকৰি হ'ব, ই অৰুণ আৰু মোৰ পৰা ঘৰটো চলোৱাৰ কিছু বোজা আঁতৰ কৰিব।",
              "gu": "હવે તને જલદી નોકરી મળી જશે એટલે અરુણ અને મારા પર ઘર ચલાવવાનો ભાર થોડો દૂર થશે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_1_2///",
              "hi": "D2_1_2 /////",
              "ka": "D2_1_2///",
              "te": "D2_1_2///",
              "or": "D2_1_2///",
              "as": "D2_1_2///",
              "gu": "D2_1_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Yes, Babuji, I understand. I’m happy that the course is getting over so I can help you all soon.",
              "hi": "हाँ, बाबूजी, मैं समझता हूं। मुझे खुशी है कि कोर्स खत्म हो रहा है, अब मैं जल्द ही आपकी सहायता कर सकता हूं।",
              "ka": "ಹೌದು, ಬಾಬೂಜಿ, ನನಗೆ ಅರ್ಥವಾಯಿತು. ಕೋರ್ಸ್ ಮುಗಿದಿದೆ ಎಂದು ನನಗೆ ಸಂತೋಷವಾಗಿದೆ, ಹಾಗಾಗಿ ನಾನು ಶೀಘ್ರದಲ್ಲೇ ನಿಮ್ಮೆಲ್ಲರಿಗೂ ಸಹಾಯ ಮಾಡಬಹುದು.",
              "te": "అవును నాన్నగారు, నాకు అర్థమైంది. కోర్సు పూర్తవుతున్నందుకు నేను సంతోషంగా ఉన్నాను కాబట్టి నేను మీ అందరికీ త్వరలో సహాయం చేయగలను.",
              "or": "ହଁ, ବାବୁଜୀ, ମୁଁ ବୁଝିପାରୁଛି | ମୁଁ ଖୁସି ଯେ ପାଠ୍ୟକ୍ରମ ସମାପ୍ତ ହେଉଛି ତେଣୁ\n  ମୁଁ ଶୀଘ୍ର ଆପଣଙ୍କୁ ସାହାଯ୍ୟ କରିପାରିବି |",
              "as": "হয়, দেউতা, মই বুজি পাইছো। মই সুখী যে পাঠ্যক্ৰমটো শেষ হৈছে সেয়ে মই সোনকালেই আপোনালোক সকলোকে সহায় কৰিব পাৰোঁ।",
              "gu": "હા, બાબુજી, હું સમજી ગયો. હું ખુશ છું કે કોર્સ પૂરો થઈ રહ્યો છે તેથી હું તમને જલ્દી મદદ કરી શકીશ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Make sure you don’t get a job that is too far away. I hope it will be in Indore. But even if you have to go, it’s okay.",
              "hi": "ध्यान रखना कि ऐसी कोई नौकरी ना करना जो बहुत दूर हो। मुझे उम्मीद है कि यह इंदौर में होगी। लेकिन अगर तुमको जाना है, तो ठीक है।",
              "ka": "ನಿನಗೆ ತುಂಬಾ ದೂರದಲ್ಲಿರುವ ಕೆಲಸ ಸಿಗದಂತೆ ನೋಡಿಕೊಳ್ಳು. ಇದು ಇಂದೋರ್‌ನಲ್ಲಿ ಆಗಲಿದೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ಆದರೆ ನೀವು ಹೋಗಬೇಕಾದರೂ ಪರವಾಗಿಲ್ಲ.",
              "te": "నీకు చాలా దూరంలో ఉన్న ఉద్యోగం రాకుండా చూసుకో. ఇది ఇండోర్లో ఉంటే బాగుంటుంది. కానీ మీరు వెళ్ళవలసి వచ్చినా పర్లేదు.",
              "or": "ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ଏକ ଚାକିରି ପାଇବେ ନାହିଁ ଯାହା ବହୁତ ଦୂରରେ\n  ଅଛି | ମୁଁ ଆଶା କରୁଛି ଏହା ଇନ୍ଦୋରରେ ହେବ | କିନ୍ତୁ ଯଦିଓ ତୁମକୁ ଯିବାକୁ\n  ପଡିବ, ଠିକ୍ ଅଛି |",
              "as": "তুমি যাতে বহুত দূৰত চাকৰি নোপোৱা সেইটো চাবা। মই ভাবো এইটো ইন্দোৰত হ'ব। কিন্তু তুমি যাব লগা হ'লেও, ঠিক আছে।",
              "gu": "ખાતરી કર કે તને એવી નોકરી ન મળે જે ખૂબ દૂર હોય. મને આશા છે કે તે ઈન્દોરમાં હશે. પણ જો તારે જવું પડે તો પણ ઠીક છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Babuji, there’s a long way to go before I know where I will be placed. We have to sit through a placement drive in college. \nThere is a selection process for it. We have to write exams, give interviews and a lot more.",
              "hi": "बाबूजी, इससे पहले कि मैं जान पाऊँ कि मुझे कहाँ नौकरी मिलेगी, एक लंबा सफर तय करना है। हमें कॉलेज की प्लेसमेंट ड्राइव से गुजरना होगा।\nइसके लिए एक चयन प्रक्रिया है। हमें परीक्षा लिखनी हैं, साक्षात्कार देना है और बहुत कुछ करना है।",
              "ka": "ಬಾಬೂಜಿ, ನನ್ನನ್ನು ಎಲ್ಲಿ ಇರಿಸಲಾಗುವುದು ಎಂದು ತಿಳಿಯುವ ಮೊದಲು ಬಹಳ ದೂರ ಹೋಗಬೇಕಾಗಿದೆ. ನಾವು ಕಾಲೇಜಿನಲ್ಲಿ ಪ್ಲೇಸ್‌ಮೆಂಟ್ ಡ್ರೈವ್ ಮೂಲಕ ಕುಳಿತುಕೊಳ್ಳಬೇಕು. ಅದಕ್ಕೆ ಆಯ್ಕೆ ಪ್ರಕ್ರಿಯೆ ಇದೆ. ನಾವು ಪರೀಕ್ಷೆಗಳನ್ನು ಬರೆಯಬೇಕು, ಸಂದರ್ಶನಗಳನ್ನು ನೀಡಬೇಕು ಮತ್ತು ಇನ್ನೂ ಹೆಚ್ಚಿನದನ್ನು ಮಾಡಬೇಕು.",
              "te": "నాన్నగారు, నాకు ఎక్కడ వస్తుందో తెలియక ముందు ఇంకా చాలా చేయాలి. మనం కళాశాలలో ప్లేస్‌మెంట్ డ్రైవ్‌ లో కూర్చోవాలి.\n దాని కోసం ఒక ఎంపిక ప్రక్రియ ఉంది. మేము పరీక్షలు రాయాలి, ఇంటర్వ్యూలు ఇవ్వాలి మరియు ఇంకా చాలా చేయాలి.",
              "or": "ବାବୁଜୀ, ମୋତେ କେଉଁଠାରେ ରଖାଯିବ ଜାଣିବା ପାଇଁ ବହୁତ ଡେରି ଅଛି । ଆମକୁ କଲେଜରେ ଏକ ପ୍ଲେସମେଣ୍ଟ ଡ୍ରାଇଭ ମାଧ୍ୟମରେ ବସିବାକୁ ପଡିବ|ଏହା ପାଇଁ ଏକ ଚୟନ ପ୍ରକ୍ରିୟା ଅଛି | ଆମକୁ ପରୀକ୍ଷା ଲେଖିବାକୁ, ସାକ୍ଷାତକାର ଦେବା ଏବଂ ଆହୁରି ଅନେକ କିଛି କରିବାକୁ ପଡିବ।",
              "as": "দেউতা, মোক ক'ত সংস্থাপিত হ'ম সেয়া জানিবলৈ বহুত বাকী আছে। আমি কলেজত প্লেচমেণ্ট ড্ৰাইভৰ মাজেৰে বহিব লাগিব। \n \n ইয়াৰ বাবে এটা বাছনি প্ৰক্ৰিয়া আছে। আমি পৰীক্ষা লিখিব লাগিব, সাক্ষাৎকাৰ দিব লাগিব আৰু বহুতো দিব লাগিব।",
              "gu": "બાબુજી, મને ક્યાં મુકવામાં આવશે તે જાણતા પહેલા ઘણી લામું અંતર કાપવું પડશે. અમારે કોલેજમાં નિયુક્તિ ડ્રાઈવમાંથી પસાર થવું પડશે. તેના માટે પસંદગી પ્રક્રિયા છે. મારે પરીક્ષાઓ આપવી પડશે, ઇન્ટરવ્યુ આપવાનું છે અને ઘણું બધું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Yes, yes, but you will get it. You are the first person in the family who has studied so much. You will make us proud.",
              "hi": "हाँ, हाँ, लेकिन तुम्हें यह ज़रूर मिलेगी। तुम परिवार के पहले व्यक्ति हो जिसने इतनी अधिक शिक्षा प्राप्त की है। तुम हमारा नाम  रोशन करोगे।",
              "ka": "ಹೌದು, ಹೌದು, ಆದರೆ ನೀನು ಅದನ್ನು ಪಡೆಯುತ್ತೀಯಾ. ಕುಟುಂಬದಲ್ಲಿ ಇಷ್ಟು ಓದಿದ ಮೊದಲ ವ್ಯಕ್ತಿ ನೀನು. ನೀನು ನಮಗೆ ಹೆಮ್ಮೆ ತರುತ್ತೀಯಾ.",
              "te": "అవును, అవును, కానీ నీకు అది వస్తుంది. కుటుంబంలో ఇంత చదివిన మొదటి వ్యక్తివి నువ్వే. నువ్వు మమ్మల్ని గర్వపడేలా చేస్తావు.",
              "or": "ହଁ, ହଁ, କିନ୍ତୁ ତୁମେ ଏହାକୁ ପାଇବ | ତୁମେ ଆମ ପରିବାରର ପ୍ରଥମ ବ୍ୟକ୍ତି ଯିଏ \n ଏତେ ଅଧ୍ୟୟନ କରିଛନ୍ତି | ତୁମେ ଆମକୁ ଗର୍ବିତ କରିବ |",
              "as": "হয়, হয়, কিন্তু তুমি এইটো পাবা। তুমি পৰিয়ালৰ প্ৰথম ব্যক্তি যিয়ে ইমান পঢ়িছা। তুমি আমাক গৌৰৱান্বিত কৰিবা।",
              "gu": "હા, હા, પરંતુ તને તે મળશે. તું પરિવારમાં પ્રથમ વ્યક્તિ છો જેણે આટલું બધું ભણ્યો છે. તું અમને ગૌરવ અપાવશો."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_1",
              "hi": "D2_2_1",
              "ka": "D2_2_1",
              "te": "D2_2_1",
              "or": "D2_2_1",
              "as": "D2_2_1",
              "gu": "D2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar is nervous after talking to his father. He feels there are a lot of expectations from his family.",
              "hi": "अपने पिता से बात करने के बाद शिखर घबरा गया है। उसे लगता है कि परिवार को उससे बहुत सारी उम्मीदें हैं।",
              "ka": "ಶಿಖರ್ ತನ್ನ ತಂದೆಯೊಂದಿಗೆ ಮಾತನಾಡಿದ ನಂತರ ಆತಂಕಗೊಂಡಿದ್ದಾನೆ. ಅವನ ಕುಟುಂಬದಿಂದ ಸಾಕಷ್ಟು ನಿರೀಕ್ಷೆಗಳಿವೆ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "తండ్రితో మాట్లాడిన తర్వాత శిఖర్ కంగారుగా ఉన్నాడు. తన కుటుంబానికి తనపై చాలా అంచనాలు ఉన్నాయని అతను భావిస్తున్నాడు.",
              "or": "ତାଙ୍କ ପିତାଙ୍କ ସହ କଥା ହେବା ପରେ ଶିଖର ଡରି ଯାଇଛନ୍ତି। ସେ ଅନୁଭବ କରୁଛନ୍ତି\n  ଯେ ତାଙ୍କ ପରିବାରରୁ ବହୁତ ଆଶା ଅଛି |",
              "as": "শিখৰে তাৰ দেউতাকৰ সৈতে কথা পতাৰ পিছত ভয় খাইছে। সি ভাবে যে তাৰ পৰিয়ালৰ বহুত আশা আছে।",
              "gu": "પિતા સાથે વાત કરીને શિખર નિરાશ છે. તેને લાગે છે કે તેના પરિવાર પાસેથી ઘણી અપેક્ષાઓ છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_2",
              "hi": "D2_2_2",
              "ka": "D2_2_2",
              "te": "D2_2_2",
              "or": "D2_2_2",
              "as": "D2_2_2",
              "gu": "D2_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He applies to all the companies recruiting students with a B.Com degree and are offering a good salary.",
              "hi": "वह बीकॉम की डिग्री के साथ छात्रों को नियुक्ति और अच्छा वेतन देने वाली सभी कंपनियों में आवेदन करता है।",
              "ka": "ಅವನು ಬಿ.ಕಾಂ ಪದವಿ ಹೊಂದಿರುವ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ನೇಮಕ ಮಾಡಿಕೊಳ್ಳುವ ಮತ್ತು ಉತ್ತಮ ಸಂಬಳವನ್ನು ನೀಡುತ್ತಿರುವ .ಎಲ್ಲಾ ಕಂಪನಿಗಳಿಗೆ ಅನ್ವಯಿಸುತ್ತಾನೆ",
              "te": "B.com డిగ్రీ చదివిన విద్యార్థులను రిక్రూట్ చేసుకుంటూ మంచి జీతం ఇచ్చే కంపెనీలన్నింటికీ అతను దరఖాస్తు చేశాడు.",
              "or": "ସେ ବି.କମ୍ ଡିଗ୍ରୀ ହାସଲ କରୁଥିବା ସମସ୍ତ ଛାତ୍ରଛାତ୍ରୀଙ୍କ ପାଇଁ ପ୍ରଯୁଜ୍ୟ ଏବଂ ଭଲ\n  ବେତନ ପ୍ରଦାନ କରୁଥିବା କମ୍ପାନୀ ରେ ଆବେଦନ କରେ।",
              "as": "সি B.Com ডিগ্ৰী থকা শিক্ষাৰ্থীক নিয়োগ কৰা আৰু ভাল দৰমহা দিয়া সকলো কোম্পানীৰ বাবে আবেদন কৰে।",
              "gu": "તે B.Com ડિગ્રી ધરાવતા વિદ્યાર્થીઓની ભરતી કરતી તમામ કંપનીઓને લાગુ પડે છે અને સારો પગાર મળે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_3_1",
              "hi": "D2_3_1",
              "ka": "D2_3_1",
              "te": "D2_3_1",
              "or": "D2_3_1",
              "as": "D2_3_1",
              "gu": "D2_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "At the first stage of placements, all the companies come to campus to give a pre-placement presentation. Shikhar goes to attend them.",
              "hi": "प्लेसमेंट के पहले चरण में, सभी कंपनियां प्री-प्लेसमेंट प्रस्तुति देने के लिए परिसर में आती हैं। शिखर उनमें भाग लेने के लिए जाता है।",
              "ka": "ಪ್ಲೇಸ್‌ಮೆಂಟ್‌ಗಳ ಮೊದಲ ಹಂತದಲ್ಲಿ, ಪ್ರಿಪ್ಲೇಸ್‌ಮೆಂಟ್ ಪ್ರಸ್ತುತಿಯನ್ನು ನೀಡಲು ಎಲ್ಲಾ ಕಂಪನಿಗಳು ಕ್ಯಾಂಪಸ್‌ಗೆ ಬರುತ್ತವೆ. ಶಿಖರ್ ಅದರಲ್ಲಿ ಹಾಜರಾಗಲು ಹೋಗುತ್ತಾನೆ.",
              "te": "ప్లేస్‌మెంట్ల మొదటి దశలో, అన్ని కంపెనీలు ప్రీ-ప్లేస్‌మెంట్ ప్రజెంటేషన్ ఇవ్వడానికి క్యాంపస్‌ కు వస్తాయి. శిఖర్ వాటికి హాజరయ్యేందుకు వెళ్తాడు.",
              "or": "ପ୍ଲେସମେଣ୍ଟର ପ୍ରଥମ ପର୍ଯ୍ୟାୟରେ, ସମସ୍ତ କମ୍ପାନୀ କ୍ୟାମ୍ପସକୁ ଏକ ପ୍ରି-ପ୍ଲେସମେଣ୍ଟ\n  ଉପସ୍ଥାପନା ଦେବାକୁ ଆସନ୍ତି | ଶିଖର ସେଗୁଡିକୁ ଯୋଗଦେବାକୁ ଯାଆନ୍ତି |",
              "as": "সংস্থাপনৰ প্ৰথম পৰ্যায়ত, সকলো কোম্পানীয়ে প্ৰি-প্লেচমেণ্ট প্ৰেজেণ্টেচন দিবলৈ কেম্পাচলৈ আহে। শিখৰে তেওঁলোকৰ সৈতে উপস্থিত থাকে।",
              "gu": "પ્લેસમેન્ટના પ્રથમ તબક્કે, તમામ કંપનીઓ નિયુક્તિ પહેલા રજૂઆત આપવા માટે કેમ્પસમાં આવે છે. શિખર તેમની હાજરી આપવા જાય છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_3_2",
              "hi": "D2_3_2",
              "ka": "D2_3_2",
              "te": "D2_3_2",
              "or": "D2_3_2",
              "as": "D2_3_2",
              "gu": "D2_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "In these presentations, they tell the students about the open roles and the qualifications required for them.",
              "hi": "इन प्रस्तुतियों में, वे छात्रों को उपलब्ध पद और उसके लिए आवश्यक योग्यता के बारे में बताते हैं।",
              "ka": "ಈ ಪ್ರಸ್ತುತಿಗಳಲ್ಲಿ, ಅವರು ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಓಪನ್ ಪಾತ್ರಗಳು ಮತ್ತು ಅವರಿಗೆ ಬೇಕಾದ ಅರ್ಹತೆಗಳ ಬಗ್ಗೆ ಹೇಳುತ್ತಾರೆ.",
              "te": "ఈ ప్రెజెంటేషన్లలో, వారు విద్యార్థులకు బహిరంగ పాత్రలు మరియు వారికి అవసరమైన అర్హతల గురించి చెబుతారు.",
              "or": "ଏହି ଉପସ୍ଥାପନାଗୁଡିକରେ, ସେମାନେ ଛାତ୍ରମାନଙ୍କୁ ଉପଯୁକ୍ତ ଭୂମିକା ଏବଂ ସେମାନଙ୍କ\n  ପାଇଁ ଆବଶ୍ୟକ ଯୋଗ୍ୟତା ବିଷୟରେ କୁହନ୍ତି |",
              "as": "এই প্ৰেজেণ্টেচনবোৰত, তেওঁলোকে শিক্ষাৰ্থীসকলক মুকলি কাম আৰু তেওঁলোকৰ বাবে প্ৰয়োজনীয় অৰ্হতাৰ বিষয়ে কয়।",
              "gu": "આ પ્રસ્તુતિઓમાં, તેઓ વિદ્યાર્થીઓને બધીજ ભૂમિકાઓ અને તેમના માટે જરૂરી લાયકાત વિશે જણાવે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_3_3",
              "hi": "D2_3_3",
              "ka": "D2_3_3",
              "te": "D2_3_3",
              "or": "D2_3_3",
              "as": "D2_3_3",
              "gu": "D2_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The second stage of placements is the written examination. They test the students to see if they have the knowledge needed for the job. Shikhar attempts the written examination for all the companies.",
              "hi": "प्लेसमेंट का दूसरा चरण लिखित परीक्षा है। वे छात्रों के पास नौकरी के लिए आवश्यक ज्ञान है या नहीं, यह जांचने के लिए परीक्षा लेते हैं। शिखर सभी कंपनियों के लिए लिखित परीक्षा देता है।",
              "ka": "ನೇಮಕಾತಿಗಳ ಎರಡನೇ ಹಂತವು ಲಿಖಿತ ಪರೀಕ್ಷೆಯಾಗಿದೆ. ಅವರು ಕೆಲಸಕ್ಕೆ ಅಗತ್ಯವಾದ ಜ್ಞಾನವನ್ನು ಹೊಂದಿದ್ದಾರೆಯೇ ಎಂದು ನೋಡಲು ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಪರೀಕ್ಷಿಸುತ್ತಾರೆ. ಶಿಖರ್ ಅವರು ಎಲ್ಲಾ ಕಂಪನಿಗಳಿಗೆ ಲಿಖಿತ ಪರೀಕ್ಷೆಯನ್ನು ಪ್ರಯತ್ನಿಸುತ್ತಾರೆ.",
              "te": "ప్లేస్‌మెంట్ల రెండో దశ వ్రాత పరీక్ష. వారు ఉద్యోగానికి అవసరమైన జ్ఞానం ఉందో లేదో తెలుసుకోవడానికి విద్యార్థులను పరీక్షిస్తారు. శిఖర్ అన్ని కంపెనీల వ్రాత పరీక్షకు ప్రయత్నిస్తాడు.",
              "or": "ପ୍ଲେସମେଣ୍ଟର ଦ୍ୱିତୀୟ ପର୍ଯ୍ୟାୟ ହେଉଛି ଲିଖିତ ପରୀକ୍ଷା | ଚାକିରି ପାଇଁ ଆବଶ୍ୟକ\n  ଜ୍ଞାନ ଅଛି କି ନାହିଁ ତାହା ଜାଣିବା ପାଇଁ ସେମାନେ ଛାତ୍ରମାନଙ୍କୁ ପରୀକ୍ଷା କରନ୍ତି |\n  ଶିଖର ସମସ୍ତ କମ୍ପାନୀ ପାଇଁ ଲିଖିତ ପରୀକ୍ଷା କରିବାକୁ ଚେଷ୍ଟା କରନ୍ତି |",
              "as": "সংস্থাপনৰ দ্বিতীয় পৰ্যায়টো হ'ল লিখিত পৰীক্ষা। তেওঁলোকে শিক্ষাৰ্থীসকলক চাকৰিটোৰ বাবে প্ৰয়োজনীয় জ্ঞান আছে নে নাই চাবলৈ পৰীক্ষা কৰে। শিখৰে সকলো কোম্পানীৰ বাবে লিখিত পৰীক্ষাৰ চেষ্টা কৰে।",
              "gu": "પ્લેસમેન્ટનો બીજો તબક્કો લેખિત પરીક્ષા છે. તેઓ નોકરી માટે જરૂરી જ્ઞાન ધરાવે છે કે કેમ તે જોવા માટે વિદ્યાર્થીઓનું પરીક્ષણ કરે છે. શિખર તમામ કંપનીઓ માટે લેખિત પરીક્ષાનો પ્રયાસ કરે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_4_1 D",
              "hi": "D2_4_1 डी",
              "ka": "D2_4_1 D",
              "te": "D2_4_1 D",
              "or": "D2_4_1 D",
              "as": "D2_4_1 D",
              "gu": "D2_4_1 D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "A week later, he gets an email from the placement office. Shikhar gets selected for three jobs. Which job should he go for?",
              "hi": "एक सप्ताह बाद, उसे प्लेसमेंट कार्यालय से एक ईमेल मिलता है। शिखर को तीन नौकरियों के लिए चुना जाता है। उसे किस नौकरी के लिए जाना चाहिए?",
              "ka": "ಒಂದು ವಾರದ ನಂತರ, ಅವನಿಗೆ ಪ್ಲೇಸ್‌ಮೆಂಟ್ ಆಫೀಸ್‌ನಿಂದ ಇಮೇಲ್ ಬರುತ್ತದೆ. ಶಿಖರ್ ಮೂರು ಕೆಲಸಗಳಿಗೆ ಆಯ್ಕೆಯಾಗುತ್ತಾನೆ. ಅವನು ಯಾವ ಕೆಲಸಕ್ಕೆ ಹೋಗಬೇಕು?",
              "te": "ఒక వారం తర్వాత, అతనికి ప్లేస్‌మెంట్ ఆఫీస్ నుండి ఇమెయిల్ వస్తుంది. శిఖర్ మూడు ఉద్యోగాలకు ఎంపికయ్యాడు. అతను ఏ ఉద్యోగానికి వెళ్లాలి?",
              "or": "ଗୋଟିଏ ସପ୍ତାହ ପରେ, ସେ ପ୍ଲେସମେଣ୍ଟ ଅଫିସରୁ ଏକ ଇମେଲ ପାଇଲେ | \n ଶିଖର ତିନିଟି ଚାକିରି ପାଇଁ ମନୋନୀତ ହୁଅନ୍ତି | ସେ କେଉଁ କାମ ପାଇଁ ଯିବା ଉଚିତ୍?",
              "as": "এসপ্তাহ পিছত, তেওঁ নিয়োগ অফিচৰ পৰা এটা ইমেইল পায়। শিখৰ তিনিটা চাকৰিৰ বাবে নিৰ্বাচিত হয়। সি কোনটো চাকৰিৰ বাবে যাব লাগে?",
              "gu": "એક અઠવાડિયા પછી, તેને નિયુક્તિની ઓફિસ તરફથી એક ઈમેલ મળે છે. શિખરની ત્રણ નોકરીઓ માટે પસંદગી થાય છે. તેણે કઈ નોકરી માટે જવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Junior accountant at a factory",
              "hi": "एक कारखाने में जूनियर एकाउंटेंट",
              "ka": "ಕಾರ್ಖಾನೆಯೊಂದರಲ್ಲಿ ಜೂನಿಯರ್ ಅಕೌಂಟೆಂಟ್",
              "te": "ఒక ఫ్యాక్టరీలో జూనియర్ అకౌంటెంట్",
              "or": "ଏକ କାରଖାନାରେ ଜୁନିଅର ଆକାଉଣ୍ଟାଣ୍ଟ |",
              "as": "এটা কাৰখানাত কনিষ্ঠ একাউণ্টেণ্ট",
              "gu": "ફેક્ટરીમાં જુનિયર એકાઉન્ટન્ટ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Junior tax accountant at a chartered accountant office",
              "hi": "एक चार्टर्ड एकाउंटेंट कार्यालय में जूनियर टैक्स एकाउंटेंट",
              "ka": "ಚಾರ್ಟರ್ಡ್ ಅಕೌಂಟೆಂಟ್ ಕಚೇರಿಯಲ್ಲಿ ಜೂನಿಯರ್ ಟ್ಯಾಕ್ಸ್ ಅಕೌಂಟೆಂಟ್",
              "te": "చార్టర్డ్ అకౌంటెంట్ కార్యాలయంలో జూనియర్ జూనియర్ పన్ను అకౌంటెంట్",
              "or": "ଏକ ଚାର୍ଟାର୍ଡ ଆକାଉଣ୍ଟାଣ୍ଟ ଅଫିସରେ ଜୁନିଅର ଟ୍ୟାକ୍ସ ଆକାଉଣ୍ଟାଣ୍ଟ |",
              "as": "চাৰ্টাৰ্ড একাউণ্টেণ্ট অফিচত কনিষ্ঠ টেক্স একাউণ্টেণ্ট",
              "gu": "ચાર્ટર્ડ એકાઉન્ટન્ટ ઓફિસમાં જુનિયર ટેક્સ એકાઉન્ટન્ટ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Junior consultant at a investment firm",
              "hi": "एक इन्वेस्टमेंट फर्म में जूनियर सलाहकार",
              "ka": "ಒಂದು ಹೂಡಿಕೆ ಸಂಸ್ಥೆಯಲ್ಲಿ ಜೂನಿಯರ್ ಸಲಹೆಗಾರ",
              "te": "పెట్టుబడి సంస్థలో జూనియర్ సలహాదారు",
              "or": "ଏକ ନିବେଶ ଫାର୍ମରେ ଜୁନିଅର ପରାମର୍ଶଦାତା |",
              "as": "এটা বিনিয়োগ প্ৰতিষ্ঠানৰ কনিষ্ঠ পৰামৰ্শদাতা",
              "gu": "ઇન્વેસ્ટમેન્ટ ફર્મમાં જુનિયર કન્સલ્ટન્ટ"
            }
          }
        ]
      },
      "scene9": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Placement process",
              "hi": "नियुक्ति प्रक्रिया",
              "ka": "ನಿಯೋಜನೆ ಪ್ರಕ್ರಿಯೆ",
              "te": "ప్లేస్‌మెంట్ ప్రక్రియ",
              "or": "ସ୍ଥାନିତ ପ୍ରକ୍ରିୟା",
              "as": "নিয়োগ প্ৰক্ৰিয়া",
              "gu": "નિયુક્તિ પ્રક્રિયા"
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_1",
              "hi": "D3_1_1",
              "ka": "D3_1_1",
              "te": "D3_1_1",
              "or": "D3_1_1",
              "as": "D3_1_1",
              "gu": "D3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar goes forward with the role of the junior accountant at the factory position.",
              "hi": "शिखर कारखाने में जूनियर एकाउंटेंट के पद के लिए आगे बढ़ता है।",
              "ka": "ಶಿಖರ್ ಕಾರ್ಖಾನೆಯಲ್ಲಿ ಜೂನಿಯರ್ ಅಕೌಂಟೆಂಟ್ ಕೆಲಸವನ್ನು ನಿರ್ವಹಿಸಾಲು ಸೇರಿಕೊಳ್ಳುತ್ತಾನೆ",
              "te": "శిఖర్ ఫ్యాక్టరీ స్థానంలో జూనియర్ అకౌంటెంట్ పాత్ర తీసుకున్నాడు.",
              "or": "କାରଖାନାରେ ଜୁନିଅର ଆକାଉଣ୍ଟାଣ୍ଟର ଭୂମିକା ନେଇ ଶିଖର ଆଗକୁ ବଢନ୍ତି |",
              "as": "শিখৰে কাৰখানাৰ পদত কনিষ্ঠ একাউণ্টেণ্টৰ কামৰ সৈতে আগবাঢ়িছে।",
              "gu": "શિખર ફેક્ટરીમાં જુનિયર એકાઉન્ટન્ટની ભૂમિકા સાથે આગળ વધે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_2",
              "hi": "D3_1_2",
              "ka": "D3_1_2",
              "te": "D3_1_2",
              "or": "D3_1_2",
              "as": "D3_1_2",
              "gu": "D3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The next stage is the group discussion. Shikhar prepares for the group discussion and feels confident after having done well in the written examination.",
              "hi": "अगला चरण समूह चर्चा है। शिखर लिखित परीक्षा में अच्छा प्रदर्शन करने के बाद समूह चर्चा के लिए तैयारी करता है औरआत्मविश्वास का अनुभव करता है।",
              "ka": "ಮುಂದಿನ ಹಂತವು ಚರ್ಚೆಯಾಗಿದೆ. ಶಿಖರ್ ಗುಂಪು ಚರ್ಚೆಗೆ ತಯಾರಿ ನಡೆಸುತ್ತಾನೆ ಮತ್ತು ಲಿಖಿತ ಪರೀಕ್ಷೆಯಲ್ಲಿ ಉತ್ತಮ ಸಾಧನೆ ಮಾಡಿದ ನಂತರ ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಅನುಭವಿಸುತ್ತಾನೆ",
              "te": "తదుపరి దశ బృంద చర్చ. శిఖర్ బృంద చర్చ కు సిద్ధమవుతాడు మరియు వ్రాత పరీక్షలో బాగా చేసిన తర్వాత ఆత్మవిశ్వాసంతో ఉంటాడు.",
              "or": "ପରବର୍ତ୍ତୀ ପର୍ଯ୍ୟାୟ ହେଉଛି ଗୋଷ୍ଠୀ ଆଲୋଚନା | ଶିଖର ଗୋଷ୍ଠୀ ଆଲୋଚନା\n  ପାଇଁ ପ୍ରସ୍ତୁତ ହୁଅନ୍ତି ଏବଂ ଲିଖିତ ପରୀକ୍ଷାରେ ଭଲ ପ୍ରଦର୍ଶନ କରିବା ପରେ\n  ଆତ୍ମବିଶ୍ୱାସ ଅନୁଭବ କରନ୍ତି |",
              "as": "পিছৰ পৰ্যায়টো হ'ল দলীয় আলোচনা। শিখৰে দলীয় আলোচনাৰ বাবে প্ৰস্তুতি চলাইছে আৰু লিখিত পৰীক্ষাত ভাল কৰাৰ পিছত আত্মবিশ্বাসী হয়।",
              "gu": "આગળનો તબક્કો જૂથ ચર્ચા છે. શિખર જૂથ ચર્ચાની તૈયારી કરે છે અને લેખિત પરીક્ષામાં સારો દેખાવ કર્યા પછી આત્મવિશ્વાસ અનુભવે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_2_1(bg)",
              "hi": "D3_2_1 (बीजी)",
              "ka": "D3_2_1(bg)",
              "te": "D3_2_1(bg)",
              "or": "D3_2_1(bg)",
              "as": "D3_2_1(bg)",
              "gu": "D3_2_1(bg)"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "When he enters the group discussion room the next day, he overhears a conversation between 2 other candidates.",
              "hi": "जब वह अगले दिन समूह चर्चा कक्ष में प्रवेश करता है, तो वह 2 अन्य प्रतिभागियों के बीच होने वाली बातचीत को सुन लेता है।",
              "ka": "ಮರುದಿನ ಅವನು ಗುಂಪು ಚರ್ಚೆಯ ಕೋಣೆಗೆ ಪ್ರವೇಶಿಸಿದಾಗ, ಆತನು ಇತರ 2 ಅಭ್ಯರ್ಥಿಗಳ ನಡುವಿನ ಸಂಭಾಷಣೆಯನ್ನು ಕೇಳುತ್ತಾರೆ.",
              "te": "అతను మరుసటి రోజు బృంద చర్చ గదిలోకి ప్రవేశించినప్పుడు, అతను మరో ఇద్దరు అభ్యర్థుల మధ్య సంభాషణను వింటాడు.",
              "or": "ପରଦିନ ଯେତେବେଳେ ସେ ଗୋଷ୍ଠୀ ଆଲୋଚନା କକ୍ଷରେ ପ୍ରବେଶ କରନ୍ତି, \n ସେତେବେଳେ ସେ ଅନ୍ୟ ୨ ଜଣ ପ୍ରାର୍ଥୀଙ୍କ ମଧ୍ୟରେ ବାର୍ତ୍ତାଳାପ ଶୁଣନ୍ତି |",
              "as": "পিছদিনা যেতিয়া সি দলীয় আলোচনা কোঠাত সোমায়, সি আন 2 জন প্ৰাৰ্থীৰ মাজত হোৱা কথা-বতৰা শুনিছিল।",
              "gu": "જ્યારે તે બીજા દિવસે જૂથ ચર્ચા રૂમમાં પ્રવેશે છે, ત્યારે તે 2 અન્ય ઉમેદવારો વચ્ચેની વાતચીત સાંભળે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Canditate 1",
              "hi": "उम्मीदवार 1",
              "ka": "ಅಭ್ಯರ್ಥಿ 1",
              "te": "అభ్యర్థి 1",
              "or": "ପ୍ରାର୍ଥୀ ୧",
              "as": "প্ৰাৰ্থী 1",
              "gu": "ઉમેદવાર 1"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Canditate 2",
              "hi": "उम्मीदवार 2",
              "ka": "ಅಭ್ಯರ್ಥಿ 2",
              "te": "అభ్యర్థి 2",
              "or": "ପ୍ରାର୍ଥୀ ୨",
              "as": "প্ৰাৰ্থী 2",
              "gu": "ઉમેદવાર 2"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "You know, zero percent EMI schemes are offered on outstanding credit cards. But what they are actually doing is hiding the interest element and passing it on to the customer in the form of a processing fee.",
              "hi": "आप जानते हैं, क्रेडिट कार्ड की बकाया राशी पर शून्य प्रतिशत ईएमआई वाली योजनाएं प्रस्तावित की जाती हैं। लेकिन वे वास्तव में क्या कर रही हैं, ब्याज तत्व को छुपा रही हैं और इसे प्रोसेसिंग शुल्क के रूप में ग्राहक से वसूल रही हैं।",
              "ka": "ನಿಮಗೆ ಗೊತ್ತಾ, ಬಾಕಿ ಉಳಿದಿರುವ ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್‌ಗಳಲ್ಲಿ ಶೂನ್ಯ ಶೇಕಡಾ EMI ಯೋಜನೆಗಳನ್ನು ನೀಡಲಾಗುತ್ತದೆ. ಆದರೆ ಅವರು ನಿಜವಾಗಿ ಮಾಡುತ್ತಿರುವುದು ಬಡ್ಡಿಯ ಅಂಶವನ್ನು ಮರೆಮಾಡುವುದು ಮತ್ತು ಅದನ್ನು ಸಂಸ್ಕರಣಾ ಶುಲ್ಕದ ರೂಪದಲ್ಲಿ ಗ್ರಾಹಕರಿಗೆ ವರ್ಗಾಯಿಸುವುದು.",
              "te": "మీకు తెలుసా, ఔట్ స్టాండింగ్ క్రెడిట్ కార్డ్ లపై సున్నా శాతం EMI పథకాలు అందించబడతాయి. కానీ వారు వాస్తవానికి చేస్తున్నది ఏంటంటే వడ్డీ అనే అంశాన్ని దాచిపెట్టి, ప్రాసెసింగ్ రుసుము రూపంలో కస్టమర్‌ కు అది వేయడం.",
              "or": "ଆପଣ ଜାଣନ୍ତି, ବକେୟା କ୍ରେଡିଟ୍ କାର୍ଡରେ ଶୂନ ଶତକଡା EMI ସ୍କିମ୍ ପ୍ରଦାନ\n  କରାଯାଏ | କିନ୍ତୁ ସେମାନେ ପ୍ରକୃତରେ ଯାହା କରୁଛନ୍ତି ତାହା ହେଉଛି ସୁଧ \n ଉପାଦାନକୁ ଲୁଚାଇବା ଏବଂ ଏହାକୁ ପ୍ରକ୍ରିୟାକରଣ ଫି ଆକାରରେ ଗ୍ରାହକଙ୍କ\n  ନିକଟକୁ ପଠାଇବା |",
              "as": "তুমি জানানে, শূন্য শতাংশ ইএমআই আঁচনিবোৰ বকেয়া ক্ৰেডিট কাৰ্ডত আগবঢ়োৱা হয়। কিন্তু তেওঁলোকে প্ৰকৃততে যি কৰি আছে সেয়া হ'ল সুতৰ হাৰৰ উপাদানটো লুকুৱাই ৰখা আৰু ইয়াক প্ৰচেছিং মাচুলৰ ৰূপত গ্ৰাহকক দিয়ে।",
              "gu": "તમે જાણો છો, ક્રેડિટ કાર્ડ્સ પર શૂન્ય ટકા EMI સ્કીમ ઓફર કરવામાં આવે છે જે ચાલુ છે તેમાં. પરંતુ તેઓ વાસ્તવમાં જે કરી રહ્યા છે તે વ્યાજના તત્વને છુપાવી રહ્યા છે અને તેને પ્રોસેસિંગ ફીના રૂપમાં ગ્રાહકને આપી રહ્યા છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Yes, and they don't give it to all. The companies do a credit score check.",
              "hi": "हाँ, और वे इसे सभी को नहीं देते हैं। कंपनियां क्रेडिट स्कोर जांच करती हैं।",
              "ka": "ಹೌದು, ಮತ್ತು ಅವರು ಅದನ್ನು ಎಲ್ಲರಿಗೂ ನೀಡುವುದಿಲ್ಲ. ಕಂಪನಿಗಳು ಕ್ರೆಡಿಟ್ ಸ್ಕೋರ್ ಚೆಕ್ ಮಾಡುತ್ತವೆ.",
              "te": "అవును, మరియు వారు దానిని అందరికీ ఇవ్వరు. కంపెనీలు క్రెడిట్ స్కోర్ చెక్ చేస్తాయి.",
              "or": "ହଁ, ଏବଂ ସେମାନେ ଏହାକୁ ସମସ୍ତଙ୍କୁ ଦିଅନ୍ତି ନାହିଁ | କମ୍ପାନୀଗୁଡିକ ଏକ \n କ୍ରେଡିଟ୍ ସ୍କୋର ଯାଞ୍ଚ କରନ୍ତି |",
              "as": "হয়, আৰু তেওঁলোকে সকলোকে নিদিয়ে। কোম্পানীবোৰে ক্ৰেডিট স্কোৰ পৰীক্ষা কৰে।",
              "gu": "હા, અને તેઓ તે બધાને આપતા નથી. કંપનીઓ ક્રેડિટ સ્કોર ચેક કરે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_2_3",
              "hi": "D3_2_3",
              "ka": "D3_2_3",
              "te": "D3_2_3",
              "or": "D3_2_3",
              "as": "D3_2_3",
              "gu": "D3_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He realises that there are people from the Financial accounting specialisation who are more knowledgeable than him. Shikhar gets nervous.",
              "hi": "उसे लगता है कि फ़ाइनेन्शिअल अकाउंटिंग/ वित्तीय लेखांकन विशेषज्ञ वे लोग हैं जो उससे अधिक जानकार हैं। शिखर घबरा जाता है।",
              "ka": "ಫೈನಾನ್ಶಿಯಲ್ ಅಕೌಂಟಿಂಗ್ ಸ್ಪೆಷಲೈಸೇಶನ್‌ನಿಂದ ತನಗಿಂತ ಹೆಚ್ಚು ಜ್ಞಾನವುಳ್ಳ ಜನರಿದ್ದಾರೆ ಎಂದು ಅವರು ಅರಿತುಕೊಳ್ಳುತ್ತಾರೆ. ಶಿಖರ್ ಉದ್ವಿಗ್ನನಾಗುತ್ತಾನೆ.",
              "te": "ఫైనాన్షియల్ అకౌంటింగ్ స్పెషలైజేషన్ నుండి తన కంటే ఎక్కువ నాలెడ్జ్ ఉన్న వ్యక్తులు ఉన్నారని అతను గ్రహించాడు. శిఖర్ కంగారు పడ్డాడు",
              "or": "ସେ ହୃଦୟଙ୍ଗମ କରିଛନ୍ତି ଯେ ଆର୍ଥିକ ଆକାଉଣ୍ଟିଂ ବିଶେଷଜ୍ଞତାର କିଛି ଲୋକ \n ଅଛନ୍ତି ଯେଉଁମାନେ ତାଙ୍କ ଅପେକ୍ଷା ଅଧିକ ଜ୍ଞାନବାନ ଅଟନ୍ତି | ଶିଖର ନର୍ଭସ ହୋଇଯାଏ |",
              "as": "সি ভাবে যে বিত্তীয় একাউণ্টিং বিশেষত্বৰ কিছুমান লোক আছে যি তাৰ তুলনাত অধিক জ্ঞানী। শিখৰে ভয় খায়।",
              "gu": "તેને ખ્યાલ આવે છે કે ફાઇનાન્સિયલ એકાઉન્ટિંગ વિશેષતાના એવા લોકો છે જેઓ તેમના કરતાં વધુ જાણકાર છે. શિખર ગભરાઈ જાય છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_2_4",
              "hi": "D3_2_4",
              "ka": "D3_2_4",
              "te": "D3_2_4",
              "or": "D3_2_4",
              "as": "D3_2_4",
              "gu": "D3_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "During the group discussion round, he is unable to perform well. The other two candidates hold most of the discussion while he sits silently.",
              "hi": "समूह चर्चा दौर के दौरान, वह अच्छा प्रदर्शन करने में असमर्थ है। अधिकतर चर्चा अन्य दो उम्मीदवार करते हैं, जबकि वह चुपचाप बैठता है।",
              "ka": "ಗುಂಪು ಚರ್ಚೆಯ ಸುತ್ತಿನಲ್ಲಿ, ಅವನು ಉತ್ತಮ ಪ್ರದರ್ಶನ ನೀಡಲು ಸಾಧ್ಯವಾಗಲಿಲ್ಲ. ಅವನು ಮೌನವಾಗಿ ಕುಳಿತಿರುವಾಗ ಇತರ ಇಬ್ಬರು ಅಭ್ಯರ್ಥಿಗಳು ಹೆಚ್ಚಿನ ಚರ್ಚೆಯನ್ನು ನಡೆಸುತ್ತಾರೆ.",
              "te": "బృంద చర్చ రౌండ్లో అతను బాగా రాణించలేకపోయాడు. అతను నిశ్శబ్దంగా కూర్చుంటాడు మరియు మిగిలిన ఇద్దరు అభ్యర్థులు చర్చలో ఎక్కువ భాగం మాట్లాడుతారు.",
              "or": "ଗୋଷ୍ଠୀ ଆଲୋଚନା ରାଉଣ୍ଡରେ ସେ ଭଲ ପ୍ରଦର୍ଶନ କରିବାରେ ଅସମର୍ଥ | \n ସେ ଚୁପଚାପ୍ ବସିଥିବା ବେଳେ ଅନ୍ୟ ଦୁଇ ପ୍ରାର୍ଥୀ ଅଧିକାଂଶ ଆଲୋଚନା କରନ୍ତି |",
              "as": "দলীয় আলোচনা ৰাউণ্ডৰ সময়ত, সি ভাল কৰিবলৈ অক্ষম। সি মনে মনে বহি থাকোঁতে আন দুজন প্ৰাৰ্থীয়ে বেছিভাগ আলোচনা কৰে।",
              "gu": "ગ્રુપ ડિસ્કશન રાઉન્ડ દરમિયાન તે સારું પ્રદર્શન કરી શકતો નથી. અન્ય બે ઉમેદવારો મોટાભાગની ચર્ચા કરે છે જ્યારે તે ચૂપચાપ બેસે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_3_1",
              "hi": "D3_3_1",
              "ka": "D3_3_1",
              "te": "D3_3_1",
              "or": "D3_3_1",
              "as": "D3_3_1",
              "gu": "D3_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The interviewer tells him that he has not cleared the group discussion. Shikhar leaves the room disappointed.",
              "hi": "साक्षात्कारकर्ता ने उसे बताया कि उसने समूह चर्चा को उत्तीर्ण नहीं किया है। शिखर निराश होकर कमरे से चला जाता है।",
              "ka": "ಸಂದರ್ಶಕರು ಅವರು ಗುಂಪು ಚರ್ಚೆಯಲ್ಲಿ ತೇರ್ಗಡೆ ಆಗಿಲ್ಲ ಎಂದು ಹೇಳುತ್ತಾರೆ. ಶಿಖರ್ ನಿರಾಶೆಯಿಂದ ಕೋಣೆಯಿಂದ ಹೊರಟನು.",
              "te": "అతను బృంద చర్చను క్లియర్ చేయలేదని ఇంటర్వ్యూయర్ అతనికి చెప్పాడు. శిఖర్ నిరాశతో గది నుండి బయలుదేరాడు.",
              "or": "ସାକ୍ଷାତକାରକାରୀ ତାଙ୍କୁ କହିଛନ୍ତି ଯେ ସେ ଗୋଷ୍ଠୀ ଆଲୋଚନାକୁ ପାସ \n କରିନାହାଁନ୍ତି। ଶିଖର ନିରାଶ ହୋଇ ରୁମରୁ ବାହାରି ଯାଆନ୍ତି |",
              "as": "সাক্ষাৎকাৰ লওঁতাই তাক কৈছিল যে সি দলীয় আলোচনাটো সম্পূৰ্ণ কৰা নাই। শিখৰে হতাশ হৈ কোঠাৰ পৰা ওলাই গ'ল।",
              "gu": "ઇન્ટરવ્યુ લેનાર તેને કહે છે કે તેણે ગ્રુપ ડિસ્કશન ક્લિયર કર્યું નથી. શિખર નિરાશ થઈને રૂમમાંથી નીકળી જાય છે."
            }
          }
        ]
      },
      "scene10": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Placement process",
              "hi": "नियुक्ति प्रक्रिया",
              "ka": "ನಿಯೋಜನೆಯ ಪ್ರಕ್ರಿಯೆ",
              "te": "ప్లేస్‌మెంట్ ప్రక్రియ",
              "or": "ସ୍ଥାନିତ ପ୍ରକ୍ରିୟା",
              "as": "নিয়োগ প্ৰক্ৰিয়া",
              "gu": "નિયુક્તિ પ્રક્રિયા"
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_1_1",
              "hi": "D4_1_1",
              "ka": "D4_1_1",
              "te": "D4_1_1",
              "or": "D4_1_1",
              "as": "D4_1_1",
              "gu": "D4_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar decides to go forward with the role of Junior tax accountant. The next round of the placement is the group discussion.",
              "hi": "शिखर जूनियर टैक्स एकाउंटेंट के पद के लिए आगे बढ़ने का निश्चय करता है। प्लेसमेंट का अगला दौर समूह चर्चा है।",
              "ka": "ಶಿಖರ್ ಜೂನಿಯರ್ ಟ್ಯಾಕ್ಸ್ ಅಕೌಂಟೆಂಟ್ ಕೆಲಸಕ್ಕೆ ಸೇರಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ನಿಯೋಜನೆಯ ಮುಂದಿನ ಸುತ್ತು ಗುಂಪು ಚರ್ಚೆಯಾಗಿದೆ.",
              "te": "శిఖర్ జూనియర్ ట్యాక్స్ అకౌంటెంట్ పాత్రతో ముందుకు వెళ్లాలని నిర్ణయించుకున్నాడు. ప్లేస్‌మెంట్ యొక్క తదుపరి రౌండ్ బృంద చర్చ.",
              "or": "ଜୁନିଅର ଟ୍ୟାକ୍ସ ଆକାଉଣ୍ଟାଣ୍ଟର ଭୂମିକା ସହିତ ଆଗକୁ ଯିବାକୁ ଶିଖର ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ପ୍ଲେସମେଣ୍ଟର ପରବର୍ତ୍ତୀ ରାଉଣ୍ଡ ହେଉଛି ଗୋଷ୍ଠୀ ଆଲୋଚନା |",
              "as": "শিখৰে কনিষ্ঠ টেক্স একাউণ্টেণ্টৰ কামৰ সৈতে আগবাঢ়ি যোৱাৰ সিদ্ধান্ত লৈছে। সংস্থাপনৰ পিছৰ পৰ্যায়টো হ'ল দলীয় আলোচনা।",
              "gu": "શિખર જુનિયર ટેક્સ એકાઉન્ટન્ટની ભૂમિકા સાથે આગળ વધવાનું નક્કી કરે છે. નિયુક્તિનો આગળનો રાઉન્ડ સમૂહ ચર્ચા છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_1_2",
              "hi": "D4_1_2",
              "ka": "D4_1_2",
              "te": "D4_1_2",
              "or": "D4_1_2",
              "as": "D4_1_2",
              "gu": "D4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar does well in the group discussion and feels confident about the final round, which is the formal interview.",
              "hi": "शिखर समूह चर्चा को अच्छे से करता है और अंतिम पड़ाव के बारे में आत्मविश्वास का अनुभव करता है, जो औपचारिक साक्षात्कार है।",
              "ka": "ಶಿಖರ್ ಗುಂಪು ಚರ್ಚೆಯಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಾನೆ ಮತ್ತು ಅಂತಿಮ ಸುತ್ತಿನ ಬಗ್ಗೆ ವಿಶ್ವಾಸ ಹೊಂದಿದ್ದಾನೆ, ಅದು ಔಪಚಾರಿಕ ಸಂದರ್ಶನವಾಗಿದೆ.",
              "te": "శిఖర్ బృంద చర్చ లో బాగా రాణిస్తున్నాడు మరియు అధికారిక ఇంటర్వ్యూ అయిన చివరి రౌండ్ గురించి నమ్మకంగా ఉన్నాడు.",
              "or": "ଶିଖର ଗୋଷ୍ଠୀ ଆଲୋଚନାରେ ଭଲ ପ୍ରଦର୍ଶନ କରନ୍ତି ଏବଂ ଅନ୍ତିମ ରାଉଣ୍ଡ \n ବିଷୟରେ ଆତ୍ମବିଶ୍ୱାସ ଅନୁଭବ କରନ୍ତି, ଯାହାକି ଆନୁଷ୍ଠାନିକ ସାକ୍ଷାତକାର |",
              "as": "শিখৰে দলীয় আলোচনাত ভাল কৰে আৰু শেষৰ ৰাউণ্ডৰ বিষয়ে আত্মবিশ্বাসী হয়, যিটো আনুষ্ঠানিক সাক্ষাৎকাৰ।",
              "gu": "શિખર જૂથ ચર્ચામાં સારો દેખાવ કરે છે અને અંતિમ રાઉન્ડ વિશે આત્મવિશ્વાસ અનુભવે છે, જે ઔપચારિક ઇન્ટરવ્યુ છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_2_1 (bg)",
              "hi": "D4_2_1 (बीजी)",
              "ka": "D4_2_1 (bg)",
              "te": "D4_2_1 (bg)",
              "or": "D4_2_1 (bg)",
              "as": "D4_2_1 (bg)",
              "gu": "D4_2_1 (bg)"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "When he goes for the interview",
              "hi": "जब वह साक्षात्कार के लिए जाता है",
              "ka": "ಅವನು ಸಂದರ್ಶನಕ್ಕೆ ಹೋದಾಗ",
              "te": "అతను ఇంటర్వ్యూకి వెళ్ళినప్పుడు",
              "or": "ଯେତେବେଳେ ସେ ସାକ୍ଷାତକାର ପାଇଁ ଯାଆନ୍ତି |",
              "as": "যেতিয়া সি সাক্ষাৎকাৰৰ বাবে যায়",
              "gu": "જ્યારે તે ઈન્ટરવ્યુ માટે જાય છે"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Interviewer",
              "hi": "साक्षात्कारकर्ता",
              "ka": "ಸಂದರ್ಶನಕಾರ",
              "te": "ఇంటర్వ్యూయర్",
              "or": "ସାକ୍ଷାତକାର କରୁଥିବା ବ୍ୟକ୍ତି",
              "as": "সাক্ষাৎকাৰ লওঁতা",
              "gu": "ઇન્ટરવ્યુ લેનાર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Interviewer",
              "hi": "साक्षात्कारकर्ता",
              "ka": "ಸಂದರ್ಶನಕಾರ",
              "te": "ఇంటర్వ్యూయర్",
              "or": "ସାକ୍ଷାତକାର କରୁଥିବା ବ୍ୟକ୍ତି",
              "as": "সাক্ষাৎকাৰ লওঁতা",
              "gu": "ઇન્ટરવ્યુ લેનાર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "what subjects are you interested in?",
              "hi": "आप किस विषय में रुचि रखते हैं?",
              "ka": "ನೀವು ಯಾವ ವಿಷಯಗಳಲ್ಲಿ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದೀರಿ?",
              "te": "మీకు ఏ విషయాలపై ఆసక్తి ఉంది?",
              "or": "ଆପଣ କେଉଁ ବିଷୟଗୁଡିକ ପାଇଁ ଆଗ୍ରହୀ?",
              "as": "তুমি কোনবোৰ বিষয়ত আগ্ৰহী?",
              "gu": "તમને કયા વિષયોમાં રસ છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "In college, I liked financial management.",
              "hi": "कॉलेज में, मुझे फ़ाइनेन्शिअल/ वित्तीय प्रबंधन पसंद था।",
              "ka": "ಕಾಲೇಜಿನಲ್ಲಿ, ನಾನು ಹಣಕಾಸು ನಿರ್ವಹಣೆಯನ್ನು ಇಷ್ಟಪಟ್ಟಿದ್ದೆ",
              "te": "కళాశాలలో నాకు ఫైనాన్షియల్ మేనేజ్‌మెంట్ అంటే ఇష్టం.",
              "or": "କଲେଜରେ, ମୁଁ ହୋଟେଲ ମ୍ୟାନେଜମେଣ୍ଟ କୁ ପସନ୍ଦ କରେ |",
              "as": "কলেজত, মই বিত্তীয় মেনেজমেন্ট ভাল পাইছিলো।",
              "gu": "કોલેજમાં મને ફાયનાન્સિયલ મેનેજમેન્ટ ગમ્યું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Oh! And what about Taxation?",
              "hi": "ओह! और टैक्सेशन/ कराधान के विषय में ?",
              "ka": "ಓಹ್! ಮತ್ತು ತೆರಿಗೆಯ ಬಗ್ಗೆ ಏನು?",
              "te": "ఓ! మరియు పన్నుల సంగతి ఏమిటి?",
              "or": "ଓଃ ! ଏବଂ ଟିକସ ବିଷୟ?",
              "as": "ওহ! আৰু কৰৰ বিষয়ে কি?",
              "gu": "ઓહ! અને કરવેરા વિશે શું?"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_2_2///",
              "hi": "D4_2_2 ///",
              "ka": "D4_2_2///",
              "te": "D4_2_2///",
              "or": "D4_2_2///",
              "as": "D4_2_2///",
              "gu": "D4_2_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "That hasn't been my favourite subject, but I understand it well.",
              "hi": "यह मेरा पसंदीदा विषय नहीं रहा है, लेकिन मैं इसे अच्छी तरह से समझता हूं।",
              "ka": "ಅದು ನನ್ನ ನೆಚ್ಚಿನ ವಿಷಯವಾಗಿರಲಿಲ್ಲ, ಆದರೆ ನಾನು ಅದನ್ನು ಚೆನ್ನಾಗಿ ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೇನೆ.",
              "te": "అది నాకు ఇష్టమైన సబ్జెక్ట్ కాదు, కానీ నాకు బాగా అర్థమైంది.",
              "or": "ତାହା ମୋର ପ୍ରିୟ ବିଷୟ ହୋଇନାହିଁ, କିନ୍ତୁ ମୁଁ ଏହାକୁ ଭଲ ଭାବରେ ବୁଝିପାରୁଛି \n  |",
              "as": "সেইটো মোৰ প্ৰিয় বিষয় নহয়, কিন্তু মই ইয়াক ভালদৰে বুজি পাওঁ।",
              "gu": "તે મારો પ્રિય વિષય નથી, પરંતુ હું તેને સારી રીતે સમજું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "And your resume says you have not applied for CA on the side. Why is that?",
              "hi": "और आपका रेज़्यूमे कहता है कि आपने सीए के पद के लिए आवेदन नहीं किया है। ऐसा क्यों है?",
              "ka": "ಮತ್ತು ನಿಮ್ಮ ರೆಸ್ಯೂಮ್ ಬದಿಯಲ್ಲಿ ನೀವು CA ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿಲ್ಲ ಎಂದು ಹೇಳುತ್ತದೆ. ಅದು ಏಕೆ?",
              "te": "మరియు మీరు CA కోసం దరఖాస్తు చేసుకోలేదని మీ రెజ్యూమ్ చెబుతోంది. అది ఎందుకు?",
              "or": "ଏବଂ ତୁମର ରିଜ୍ୟୁମ୍ କହୁଛି ତୁମେ CA ପାଇଁ ଆବେଦନ କରିନାହଁ | ତାହା କାହିଁକି?",
              "as": "আৰু তোমাৰ ৰিজিউমিয়ে কৈছে যে তুমি কাষত চিএৰ বাবে আবেদন কৰা নাই। সেইয়া কিয়?",
              "gu": "અને તમારો બાયોડેટા કહે છે કે તમે બાજુમાં CA માટે અરજી કરી નથી. તે શા માટે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Ma'am, I wanted a general understanding of all subjects in commerce and not something specific as a CA.",
              "hi": "महोदया, मैं कॉमर्स/ वाणिज्य में सभी विषयों की सामान्य समझ चाहता था और सीए के रूप में कुछ विशिष्ट नहीं था।",
              "ka": "ಮೇಡಂ, ನಾನು ವಾಣಿಜ್ಯದಲ್ಲಿ ಎಲ್ಲಾ ವಿಷಯಗಳ ಸಾಮಾನ್ಯ ತಿಳುವಳಿಕೆಯನ್ನು ಬಯಸುತ್ತೇನೆ ಮತ್ತು CA ಆಗಿ ನಿರ್ದಿಷ್ಟವಾದದ್ದನ್ನು ಅಲ್ಲ.",
              "te": "మేడమ్, నేను కామర్స్ లోని అన్ని సబ్జెక్టులపై సాధారణ అవగాహనను కోరుకున్నాను మరియు CA లాగా నిర్దిష్టంగా ఏమీ అనుకోలేదు.",
              "or": "ମ୍ୟାମ, ମୁଁ ବାଣିଜ୍ୟରେ ସମସ୍ତ ବିଷୟର ସାଧାରଣ ବୁଝାମଣା ଚାହୁଁଥିଲି ଏବଂ CA\n  ଭଳି କିଛି ନିର୍ଦ୍ଦିଷ୍ଟ ନୁହେଁ |",
              "as": "মেম, মই বাণিজ্যৰ সকলো বিষয়ৰ সাধাৰণ বুজাবুজি বিচাৰিছিলো, চিএ হিচাপে নিৰ্দিষ্ট কিবা নহয়।",
              "gu": "મેડમ, મારે કોમર્સના તમામ વિષયોની સામાન્ય સમજ જોઈતી હતી અને CA તરીકે કોઈ ચોક્કસ બાબતની નહિ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "But you know all job applications for the Tax accountant role prefer students pursuing CA because they understand tax and accounting a lot more than B.com graduates.",
              "hi": "लेकिन आप जानते हैं कि टैक्स एकाउंटेंट के पद की नौकरी के लिए आवेदन में सभी सीए करने वाले छात्रों को पसंद करते हैं क्योंकि वे टैक्स और अकाउंटिंग को बीकॉम स्नातकों की तुलना में ज्यादा अच्छे से समझते हैं।",
              "ka": "ಆದರೆ ತೆರಿಗೆ ಅಕೌಂಟೆಂಟ್ ಕೆಲಸಕ್ಕಾಗಿ ಎಲ್ಲಾ ಉದ್ಯೋಗ ಅರ್ಜಿಗಳು CA ಅನ್ನು ಅನುಸರಿಸುವ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಆದ್ಯತೆ ನೀಡುತ್ತವೆ ಎಂದು ನಿಮಗೆ ತಿಳಿದಿದೆ ಏಕೆಂದರೆ ಅವರು B.com ಪದವೀಧರರಿಗಿಂತ ಹೆಚ್ಚು ತೆರಿಗೆ ಮತ್ತು ಲೆಕ್ಕಪತ್ರವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "కానీ టాక్స్ అకౌంటెంట్ పాత్ర కోసం అన్ని ఉద్యోగ దరఖాస్తులు CA చదివే విద్యార్థులను ఇష్టపడతాయని మీకు తెలుసు, ఎందుకంటే వారు B.com గ్రాడ్యుయేట్ల కంటే టాక్స్ మరియు అకౌంటింగ్‌ను చాలా ఎక్కువగా అర్థం చేసుకుంటారు.",
              "or": "କିନ୍ତୁ ଆପଣ ଜାଣନ୍ତି ଟ୍ୟାକ୍ସ ଆକାଉଣ୍ଟାଣ୍ଟ ଭୂମିକା ପାଇଁ ସମସ୍ତ ଚାକିରୀ ଆବେଦନଗୁଡିକ\n  CA ଅନୁସରଣ କରୁଥିବା ଛାତ୍ରମାନଙ୍କୁ ପସନ୍ଦ କରନ୍ତି କାରଣ ସେମାନେ ଟିକସ ଓ ଆକାଉଣ୍ଟିଂକୁ B.com ସ୍ନାତକମାନଙ୍କ ଅପେକ୍ଷା \n  ବହୁତ ଅଧିକ ବୁଝିପାରନ୍ତି|",
              "as": "কিন্তু তুমি জানা যে টেক্স একাউণ্টেণ্টৰ কামৰ বাবে সকলো চাকৰিৰ আৱেদনে চিএ কৰা শিক্ষাৰ্থীসকলক পছন্দ কৰে কিয়নো তেওঁলোকে টেক্স আৰু একাউণ্টিং B.com স্নাতকতকৈ বহুত বেছি বুজি পায়।",
              "gu": "પરંતુ તમે જાણો છો કે ટેક્સ એકાઉન્ટન્ટની ભૂમિકા માટે તમામ નોકરીની અરજીઓ CA કરતા વિદ્યાર્થીઓને પસંદ કરે છે કારણ કે તેઓ B.com સ્નાતકો કરતાં ટેક્સ અને એકાઉન્ટિંગને ઘણું વધારે સમજે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "...",
              "hi": "...",
              "ka": "...",
              "te": "...",
              "or": "...",
              "as": "...",
              "gu": "..."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_2_3",
              "hi": "D4_2_3",
              "ka": "D4_2_3",
              "te": "D4_2_3",
              "or": "D4_2_3",
              "as": "D4_2_3",
              "gu": "D4_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "At the end of the interview, he is informed that he won't be getting a job.",
              "hi": "साक्षात्कार के अंत में, उसे सूचित किया जाता है कि उसे नौकरी नहीं मिल पाएगी।",
              "ka": "ಸಂದರ್ಶನದ ಕೊನೆಯಲ್ಲಿ, ಅವನಿಗೆ ಕೆಲಸ ಸಿಗುವುದಿಲ್ಲ ಎನ್ನುವುದನ್ನು ತಿಳಿಸಲಾಗುತ್ತದೆ.",
              "te": "ఇంటర్వ్యూ ముగిసే సమయానికి, అతనికి ఉద్యోగం రాదని సమాచారం.",
              "or": "ସାକ୍ଷାତକାର ଶେଷରେ, ତାଙ୍କୁ ସୂଚନା ଦିଆଯାଉଛି ଯେ ସେ ଚାକିରି ପାଇବେ ନାହିଁ |",
              "as": "সাক্ষাৎকাৰৰ শেষত, তাক জনায় যে সি চাকৰি নাপাব।",
              "gu": "ઇન્ટરવ્યુના અંતે, તેને જાણ કરવામાં આવે છે કે તેને નોકરી મળશે નહીં."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_2_4",
              "hi": "D4_2_4",
              "ka": "D4_2_4",
              "te": "D4_2_4",
              "or": "D4_2_4",
              "as": "D4_2_4",
              "gu": "D4_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar is disheartened.",
              "hi": "शिखर निराश है।",
              "ka": "ಶಿಖರ್ ಹತಾಶನಾಗಿದ್ದಾನೆ.",
              "te": "శిఖర్ నిరుత్సాహపడ్డాడు.",
              "or": "ଶିଖର ହତାଶ |",
              "as": "শিখৰ হতাশ হৈছে।",
              "gu": "શિખર નિરાશ છે."
            }
          }
        ]
      },
      "scene11": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "placement process",
              "hi": "नियुक्ति प्रक्रिया",
              "ka": "ನಿಯೋಜನೆಯ ಪ್ರಕ್ರಿಯೆ",
              "te": "ప్లేస్మెంట్ ప్రక్రియ",
              "or": "ସ୍ଥାନିତ ପ୍ରକ୍ରିୟା |",
              "as": "নিয়োগ প্ৰক্ৰিয়া",
              "gu": "નિયુક્તિ પ્રક્રિયા"
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_1_1",
              "hi": "D5_1_1",
              "ka": "D5_1_1",
              "te": "D5_1_1",
              "or": "D5_1_1",
              "as": "D5_1_1",
              "gu": "D5_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar decides to go forward with the role of a junior consultant at the investment firm.",
              "hi": "शिखर इन्वेस्टमेंट फर्म में एक जूनियर सलाहकार के पद के लिए आगे बढ़ने का फैसला करता है।",
              "ka": "ಒಂದು ಹೂಡಿಕೆ ಸಂಸ್ಥೆಯಲ್ಲಿ ಜೂನಿಯರ್ ಕನ್ಸಲ್ಟೆಂಟ್‌ನ ಕಲಸವನ್ನು ಪಡೆಯಲು  ಶಿಖರ್ ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "శిఖర్ పెట్టుబడి సంస్థలో జూనియర్ కన్సల్టెంట్ పాత్రతో ముందుకు వెళ్లాలని నిర్ణయించుకున్నాడు.",
              "or": "ନିବେଶ ଫାର୍ମରେ କନିଷ୍ଠ ପରାମର୍ଶଦାତାଙ୍କ ଭୂମିକା ନେଇ ଶିଖର ଆଗକୁ ଯିବାକୁ\n  ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "শিখৰে বিনিয়োগ প্ৰতিষ্ঠানটোত এজন কনিষ্ঠ পৰামৰ্শদাতাৰ কামৰ সৈতে আগবাঢ়ি যোৱাটো ঠিক কৰিছে।",
              "gu": "શિખર રોકાણ પેઢીમાં જુનિયર સલાહકારની ભૂમિકા સાથે આગળ વધવાનું નક્કી કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_1_2",
              "hi": "D5_1_2",
              "ka": "D5_1_2",
              "te": "D5_1_2",
              "or": "D5_1_2",
              "as": "D5_1_2",
              "gu": "D5_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The company receives the maximum number of applications. The group discussion and interview rounds go well.  But Shikhar doesn’t hear back from them for a few days.",
              "hi": "कंपनी को अधिकतम संख्या में आवेदन प्राप्त होते हैं। समूह चर्चा और साक्षात्कार दौर अच्छी तरह से होते हैं। लेकिन शिखर को उनसे कुछ दिनों तक कोई समाचार नहीं मिलता।",
              "ka": "ಕಂಪನಿಯು ಗರಿಷ್ಠ ಸಂಖ್ಯೆಯ ಅರ್ಜಿಗಳನ್ನು ಸ್ವೀಕರಿಸಿರುತ್ತದೆ. ಗುಂಪು ಚರ್ಚೆ ಮತ್ತು ಸಂದರ್ಶನದ ಸುತ್ತುಗಳು ಉತ್ತಮವಾಗಿ ನಡೆಯುತ್ತವೆ. ಆದರೆ ಶಿಖರ್ ಕೆಲವು ದಿನಗಳಾದರೂ ಅವರಿಂದ ಯಾವುದೇ ಸಮಾಚಾರವನ್ನು ಸ್ವೀಕರಿಸುವುದಿಲ್ಲ.",
              "te": "కంపెనీ గరిష్ట సంఖ్యలో దరఖాస్తులను స్వీకరిస్తుంది. బృంద చర్చ మరియు ఇంటర్వ్యూ రౌండ్లు బాగా సాగుతాయి. అయితే శిఖర్ కొన్ని రోజులుగా వారి మాట వినడు.",
              "or": "କମ୍ପାନୀ ସର୍ବାଧିକ ସଂଖ୍ୟକ ଆବେଦନ ଗ୍ରହଣ କରେ | ଗୋଷ୍ଠୀ ଆଲୋଚନା \n ଏବଂ ସାକ୍ଷାତକାର ରାଉଣ୍ଡଗୁଡିକ ଭଲରେ ଚାଲିଥାଏ | କିନ୍ତୁ ଶିଖର ସେମାନଙ୍କଠାରୁ\n  କିଛି ଦିନ ପର୍ଯ୍ୟନ୍ତ ଶୁଣନ୍ତି ନାହିଁ |",
              "as": "কোম্পানীটোৱে সৰ্বাধিক সংখ্যক আবেদন পায়। দলীয় আলোচনা আৰু সাক্ষাৎকাৰৰ ৰাউণ্ডবোৰ ভালদৰে চলি থাকে। কিন্তু শিখৰে কেইদিনমানৰ বাবে তেওঁলোকৰ কোনো উত্তৰ নাপায়।",
              "gu": "કંપની મહત્તમ સંખ્યામાં અરજીઓ મેળવે છે. જૂથ ચર્ચા અને ઇન્ટરવ્યુ રાઉન્ડ સારી રીતે જાય છે. પરંતુ શિખર થોડા દિવસો સુધી તેમનો જવાબ સાંભળતો નથી."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_1_3 email",
              "hi": "D5_1_3 ईमेल",
              "ka": "D5_1_3 email",
              "te": "D5_1_3 ఇమెయిల్",
              "or": "D5_1_3 email",
              "as": "D5_1_3 email",
              "gu": "D5_1_3 ઇમેઇલ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He writes an email to know about the status of his application.",
              "hi": "वह अपने आवेदन की स्थिति के बारे में जानने के लिए एक ईमेल लिखता है।",
              "ka": "ಆತನು ತನ್ನ ಅರ್ಜಿಯ ಸ್ಥಿತಿಯನ್ನು ತಿಳಿದುಕೊಳ್ಳಲು ಇಮೇಲ್ ಬರೆಯುತ್ತಾನೆ.",
              "te": "అతను తన దరఖాస్తు స్థితి గురించి తెలుసుకోవడానికి ఒక ఇమెయిల్‌ ను వ్రాస్తాడు.",
              "or": "ସେ ତାଙ୍କ ଆବେଦନର ସ୍ଥିତି ବିଷୟରେ ଜାଣିବା ପାଇଁ ଏକ ଇମେଲ୍ ଲେଖନ୍ତି |",
              "as": "সি তাৰ আবেদনৰ স্থিতিৰ বিষয়ে জানিবলৈ এটা ইমেইল লিখিছে।",
              "gu": "તે તેની અરજીની સ્થિતિ જાણવા માટે ઈમેલ લખે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.sendMailTo",
            "value": {
              "en": "hr@zmail.com",
              "hi": "hr@zmail.com",
              "ka": "hr@zmail.com",
              "te": "hr@zmail.com",
              "or": "hr@zmail.com",
              "as": "hr@zmail.com",
              "gu": "hr@zmail.com"
            }
          },
          {
            "valuePath": "blocks.0.props.sendMailSubject",
            "value": {
              "en": "Application status",
              "hi": "आवेदन की स्थिति",
              "ka": "ಅರ್ಜಿಯ ಸ್ಥಿತಿ",
              "te": "దరఖాస్తు స్థితి",
              "or": "ଆବେଦନ ସ୍ଥିତି",
              "as": "আবেদনৰ স্থিতি",
              "gu": "એપ્લિકેશન સ્થિતિ"
            }
          },
          {
            "valuePath": "blocks.0.props.sendMailMsg",
            "value": {
              "en": "Dear HR Team, \n\nI am Shikhar, with application number Z0932L. It has been a week since I appeared for the formal interview. I was wondering if there are any updates on my application status.\n\nRegards,\nShikhar",
              "hi": "प्रिय एचआर टीम,\n\nमैं शिखर हूं, मेरी एप्लिकेशन नंबर Z0932L है। एक सप्ताह हो गया है जब मैं औपचारिक साक्षात्कार के लिए उपस्थित हुआ था। मैं सोच रहा था कि मेरे आवेदन की स्थिति पर क्या कोई जानकारी उपलब्ध है।\n\nसादर,\nशिखर",
              "ka": "ಆತ್ಮೀಯ ಮಾನವ ಸಂಪನ್ಮೂಲ ತಂಡದವರೆ,                                                                                                                                                                                    ನಾನು ಶಿಖರ್, ಅರ್ಜಿ ಸಂಖ್ಯೆ Z0932L. ನಾನು ಔಪಚಾರಿಕ ಸಂದರ್ಶನಕ್ಕೆ ಹಾಜರಾಗಿ ಒಂದು ವಾರವಾಗಿದೆ. ನನ್ನ ಅರ್ಜಿಯ ಸ್ಥಿತಿಯ ಕುರಿತು ಯಾವುದೇ ಅಪ್ ಡೇಟ್ ಇದೆಯೇ ಎಂದು ನಾನು ಕಾತುರದಿಂದ ಕಾಯುತ್ತಿದ್ದೇನೆ.                                                                                                                                                                                                             ವಂದನೆಗಳು,                                                                                                                                                                                                                                 ಶಿಖರ್",
              "te": "డియర్ HR టీమ్,\n \n నేను శిఖర్, అప్లికేషన్ నంబర్ Z0932L. నేను ఫార్మల్ ఇంటర్వ్యూకి హాజరై ఒక వారం అయ్యింది. నా దరఖాస్తు స్థితిపై ఏవైనా అప్‌డేట్‌లు ఉన్నాయా అని నేను ఆశ్చర్యపోతున్నాను.\n \n గౌరవంతో,\n శిఖర్",
              "or": "\"ପ୍ରିୟ HR ଦଳ,\n \n ମୁଁ ଶିଖର, ଆବେଦନ ନମ୍ବର Z0932L ସହିତ | ମୁଁ ଆନୁଷ୍ଠାନିକ ସାକ୍ଷାତକାର ପାଇଁ ହାଜର ହେବାର ଏକ ସପ୍ତାହ ବିତିଗଲାଣି | ମୁଁ ଭାବୁଥିଲି ମୋର ଆବେଦନ ସ୍ଥିତି ଉପରେ କୌଣସି ଅଦ୍ୟତନ ଅଛି କି?\n \n ଶୁଭେଚ୍ଛା,\n ଶିଖର \"",
              "as": "সন্মানীয় HR দল, \n \n মই শিখৰ, এপ্লিকেচন নম্বৰ Z0932L। মই আনুষ্ঠানিক সাক্ষাৎকাৰ দিয়াৰ এসপ্তাহ হ'ল। মই মোৰ আবেদনৰ স্থিতিৰ কিবা আপডেট আছে নেকি জানিব বিচাৰোঁ।\n \n ধন্যবাদেৰে,\n শিখৰ",
              "gu": "પ્રિય HR ટીમ, હું શિખર છું, એપ્લિકેશન નંબર Z0932L સાથે. મને ઔપચારિક ઇન્ટરવ્યુ માટે હાજર થયાને એક અઠવાડિયું થઈ ગયું છે. હું આશ્ચર્ય પામી રહ્યો હતો કે શું મારી અરજીની સ્થિતિ પર કોઈ અપડેટ છે. સાદર, શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailTo",
            "value": {
              "en": "shikhar@zmail.com",
              "hi": "shikhar@zmail.com",
              "ka": "shikhar@zmail.com",
              "te": "shikhar@zmail.com",
              "or": "shikhar@zmail.com",
              "as": "shikhar@zmail.com",
              "gu": "shikhar@zmail.com"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailSubject",
            "value": {
              "en": "Re: Application Status",
              "hi": "सन्दर्भ: आवेदन की स्थिति",
              "ka": "ಬಗ್ಗೆ: ಅರ್ಜಿಯ ಸ್ಥಿತಿ",
              "te": "ప్ర: దరఖాస్తు స్థితి",
              "or": "Re: Application Status",
              "as": "ৰিপ্লাই: আবেদনৰ স্থিতি",
              "gu": "Re: અરજી સ્થિતિ"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailMsg",
            "value": {
              "en": "Dear Shikhar, \n\nThank you for applying to our organization for the role of a junior consultant. After reviewing your performance in both rounds, we are sorry to inform you that you are not selected. The position requires someone who has a deeper understanding of auditing. \n\nWe wish you the best.\n\nRegards,\nHR team",
              "hi": "प्रिय शिखर,\n\nजूनियर सलाहकार के पद के लिए हमारे संगठन को आवेदन करने के लिए धन्यवाद। दोनों दौर में आपके प्रदर्शन की समीक्षा करने के बाद, हमें आपको सूचित करते हुए खेद है कि आपका चयन नहीं हुआ है। इस पद के लिए किसी ऐसे व्यक्ति की आवश्यकता होती है जिसे ऑडिटिंग की गहरी समझ होती है।\n\nहमारी तरफ से आपको शुभकामनाएं।\n\nसादर,\nएचआर टीम",
              "ka": "ಆತ್ಮೀಯ ಶಿಖರ್,                                                                                                                                                                                                                               ಕಿರಿಯ ಸಲಹೆಗಾರರ ​​ಕೆಲಸಕ್ಕಾಗಿ ನಮ್ಮ ಸಂಸ್ಥೆಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ಎರಡೂ ಸುತ್ತುಗಳಲ್ಲಿ ನಿಮ್ಮ ಕಾರ್ಯಕ್ಷಮತೆಯನ್ನು ಪರಿಶೀಲಿಸಿದ ನಂತರ, ನಿಮ್ಮನ್ನು ಆಯ್ಕೆ ಮಾಡಲಾಗಿಲ್ಲ ಎಂದು ತಿಳಿಸಲು ನಾವು ವಿಷಾದಿಸುತ್ತೇವೆ. ಈ ಸ್ಥಾನಕ್ಕೆ ಲೆಕ್ಕಪರಿಶೋಧನೆಯ ಬಗ್ಗೆ ಆಳವಾದ ತಿಳುವಳಿಕೆಯನ್ನು ಹೊಂದಿರುವ ಯಾರಾದರೂ ಅಗತ್ಯವಿದೆ.                                                                                                                                ನಾವು ನಿಮಗೆ ಶುಭ ಹಾರೈಸುತ್ತೇವೆ.                                                                                                                                                                                           ವಂದನೆಗಳು,                                                                                                                                                                                                                       ಎಚ್‌ಆರ್ ತಂಡ",
              "te": "ప్రియమైన శిఖర్,\n \n జూనియర్ కన్సల్టెంట్ పాత్ర కోసం మా సంస్థకు దరఖాస్తు చేసినందుకు ధన్యవాదాలు. రెండు రౌండ్లలో మీ పనితీరును సమీక్షించిన తర్వాత, మీరు ఎంపిక కాలేదని తెలియజేసేందుకు చింతిస్తున్నాము. ఈ స్థానానికి ఆడిటింగ్‌పై లోతైన అవగాహన ఉన్న వ్యక్తి అవసరం.\n \n మేము మీకు శుభాకాంక్షలు తెలియజేస్తున్నాము.\n \n గౌరవంతో,\n HR బృందం",
              "or": "\"ପ୍ରିୟ ଶିଖର,\n \n କନିଷ୍ଠ ପରାମର୍ଶଦାତା ଭୂମିକା ପାଇଁ ଆମ ସଂସ୍ଥାରେ ଆବେଦନ କରିଥିବାରୁ ଧନ୍ୟବାଦ | ଉଭୟ ରାଉଣ୍ଡରେ ତୁମର କାର୍ଯ୍ୟଦକ୍ଷତାକୁ ସମୀକ୍ଷା କରିବା ପରେ, ତୁମକୁ ମନୋନୀତ ନହେବା ପାଇଁ ଆମେ ଦୁଃଖିତ | ପଦବୀ ଏପରି ଜଣେ ଆବଶ୍ୟକ କରେ ଯାହାର ଅଡିଟ୍ ବିଷୟରେ ଗଭୀର ବୁଝାମଣା ଥିବ |\n \n ଆମେ ଆପଣଙ୍କୁ ଶୁଭେଚ୍ଛା ଜଣାଉଛୁ\n \n ଶୁଭେଚ୍ଛା,\n HR ଦଳ \"",
              "as": "\"মৰমৰ শিখৰ, \n \n এজন কনিষ্ঠ পৰামৰ্শদাতাৰ পদৰ বাবে আমাৰ সংগঠনত আবেদন কৰাৰ বাবে ধন্যবাদ। দুয়োটা ৰাউণ্ডত আপোনাৰ প্ৰদৰ্শন পৰ্যালোচনা কৰাৰ পিছত, আপোনাক বাছনি কৰা নাই বুলি জনাবলৈ আমি দুঃখিত। পদটোৰ বাবে এনে এজন ব্যক্তিৰ প্ৰয়োজন যাৰ অডিটিংৰ গভীৰ অভিজ্ঞতা আছে। \n \n আমি আপোনাক শুভকামনা জনাইছো।\n \n ধন্যবাদেৰে,\n HR দল\"",
              "gu": "પ્રિય શિખર, જુનિયર કન્સલ્ટન્ટની ભૂમિકા માટે અમારી સંસ્થામાં અરજી કરવા બદલ આભાર. બંને રાઉન્ડમાં તમારા પ્રદર્શનની સમીક્ષા કર્યા પછી, અમે તમને જણાવતા દિલગીર છીએ કે તમારી પસંદગી કરવામાં આવી નથી. પદ માટે એવી વ્યક્તિની જરૂર હોય છે જે ઓડિટીંગની ઊંડી સમજ ધરાવે છે. અમે તમને શુભેચ્છા પાઠવીએ છીએ. સાદર, એચઆર ટીમ"
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_1_5",
              "hi": "D5_1_5",
              "ka": "D5_1_5",
              "te": "D5_1_5",
              "or": "D5_1_5",
              "as": "D5_1_5",
              "gu": "D5_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar is disappointed. He was keen on getting this job.",
              "hi": "शिखर निराश है। वह इस काम को पाने के लिए उत्सुक था।",
              "ka": "ಶಿಖರ್‌ಗೆ ನಿರಾಸೆಯಾಗಿದೆ. ಆತನು ಈ ಕೆಲಸವನ್ನು ಪಡೆಯಲು ಉತ್ಸುಕನಾಗಿದ್ದನು.",
              "te": "శిఖర్ నిరాశ చెందాడు. అతను ఈ ఉద్యోగం పొందాలనే తపనతో ఉన్నాడు.",
              "or": "ଶିଖର ନିରାଶ ହୋଇଛନ୍ତି। ସେ ଏହି ଚାକିରି ପାଇବାକୁ ଆଗ୍ରହୀ ଥିଲେ |",
              "as": "শিখৰ হতাশ হৈছে। সি এই চাকৰিটো পাবলৈ আগ্ৰহী আছিল।",
              "gu": "શિખર નિરાશ છે. કેમ કે તે આ નોકરી મેળવવા ઉત્સુક હતો."
            }
          }
        ]
      },
      "scene33": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_1_1",
              "hi": "D6_1_1",
              "ka": "D6_1_1",
              "te": "D6_1_1",
              "or": "D6_1_1",
              "as": "D6_1_1",
              "gu": "D6_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "The placement season is over, and Shikhar is upset that he hasn’t been able to get a job.",
              "hi": "प्लेसमेंट का दौर खत्म हो गया है, और शिखर परेशान है कि वह नौकरी पाने में सफल नहीं हुआ है।",
              "ka": "ನಿಯೋಜನೆಯ ಸೀಸನ್ ಮುಗಿದಿದ್ದು, ತನಗೆ ಕೆಲಸ ಸಿಗಲಿಲ್ಲ ಎಂದು ಶಿಖರ್ ಬೇಸರಗೊಂಡಿದ್ದಾನೆ.",
              "te": "ప్లేస్‌మెంట్ సీజన్ ముగిసింది, తనకు ఉద్యోగం రాలేదని శిఖర్ కలత చెందాడు.",
              "or": "ପ୍ଲେସମେଣ୍ଟର ଅବଧି ଶେଷ ହୋଇଛି, ଏବଂ ଶିଖର ଅସନ୍ତୋଷ ପ୍ରକାଶ କରିଛନ୍ତି ଯେ ସେ ଚାକିରି ପାଇବାରେ ସକ୍ଷମ ହୋଇନାହାଁନ୍ତି |",
              "as": "সংস্থাপনৰ সময় শেষ হৈছে, আৰু শিখৰে চাকৰি নাই পোৱা বুলি বিচলিত হৈছে।",
              "gu": "નિયુક્તીનો સમયગાળો પૂરો થઈ ગયો છે, અને શિખર નારાજ છે કે તેને નોકરી મળી નથી."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_1_2",
              "hi": "D6_1_2",
              "ka": "D6_1_2",
              "te": "D6_1_2",
              "or": "D6_1_2",
              "as": "D6_1_2",
              "gu": "D6_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He doesn’t know what to tell Babuji. He expected him to get a job after the course, and he blames himself for not doing his best. But it is time to go home.",
              "hi": "वह नहीं जानता कि बाबूजी से क्या कहे। उन्हें उम्मीद थी  कि कोर्स के बाद उसे नौकरी मिल जाएगी, और वह अपना सर्वश्रेष्ठ न दे पाने के लिए खुद को दोषी ठहराता है। लेकिन अब यह घर जाने का समय है।",
              "ka": "ಬಾಬೂಜಿಗೆ ಏನು ಹೇಳಬೇಕೆಂದು ತಿಳಿಯುತ್ತಿಲ್ಲ. ಕೋರ್ಸ್ ನಂತರ ತನಗೆ ಕೆಲಸ ಸಿಗುತ್ತದೆ ಎಂದು ಅವರು ನಿರೀಕ್ಷಿಸಿದ್ದರು, ಮತ್ತು ಅವರು ತಮ್ಮ ಕೈಲಾದಷ್ಟು ಮಾಡದಿದ್ದಕ್ಕಾಗಿ ಸ್ವತಃ ದೂಷಿಸಿಕೊಳ್ಳುತ್ತಾರೆ. ಆದರೆ ಮನೆಗೆ ಹೋಗುವ ಸಮಯ ಆಗಿದೆ.",
              "te": "నాన్నగారుకి ఏం చెప్పాలో తెలియడం లేదు. కోర్సు తర్వాత తనకు ఉద్యోగం వస్తుందని ఆశించి, తను సత్తా చాటలేదని తనను తాను నిందించుకుంటున్నాడు. కానీ ఇంటికి వెళ్ళే సమయం వచ్చింది.",
              "or": "ବାବୁଜୀଙ୍କୁ କ’ଣ କହିବେ ସେ ଜାଣନ୍ତି ନାହିଁ | ସେ ଆଶା କରିଥିଲେ ଯେ ପାଠ୍ୟକ୍ରମ ପରେ ସେ ଚାକିରି ପାଇବେ, ଏବଂ ସେ ଯଥାସମ୍ଭବ କାର୍ଯ୍ୟ ନକରିବାରୁ ନିଜକୁ ଦାୟୀ କରିବେ | କିନ୍ତୁ ଘରକୁ ଯିବାର ସମୟ ଆସିଛି |",
              "as": "দেউতাকক কি ক'ব লাগে সি নাজানে। সি আশা কৰিছিল যে পাঠ্যক্ৰমৰ পিছত সি চাকৰি পাব, আৰু সি নিজৰ শ্ৰেষ্ঠ কাম নকৰাৰ বাবে নিজকে দোষাৰোপ কৰে। কিন্তু এতিয়া ঘৰলৈ যোৱাৰ সময়।",
              "gu": "તેને ખબર નથી કે બાબુજીને શું કહેવું. તેને કોર્સ પછી નોકરી મળવાની અપેક્ષા હતી, અને તે પોતાનું સર્વશ્રેષ્ઠ પ્રદર્શન ન કરવા બદલ પોતાને દોષ આપે છે. પણ ઘરે જવાનો સમય થઈ ગયો છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_2_1 bg",
              "hi": "D6_2_1 बीजी",
              "ka": "D6_2_1 bg",
              "te": "D6_2_1 bg",
              "or": "D6_2_1 bg",
              "as": "D6_2_1 bg",
              "gu": "D6_2_1 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar reaches home and shares his worry with his brother.",
              "hi": "शिखर घर पहुंचकर अपने भाई के साथ अपनी चिंता साझा करता है।",
              "ka": "ಶಿಖರ್ ಮನೆಗೆ ತಲುಪುತ್ತಾನೆ ಮತ್ತು ತನ್ನ ಸಹೋದರನೊಂದಿಗೆ ತನ್ನ ಚಿಂತೆಯನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "శిఖర్ ఇంటికి చేరుకుని తన ఆందోళనను తన సోదరుడితో పంచుకున్నాడు.",
              "or": "ଶିଖର ଘରେ ପହାଞ୍ଚି ତାଙ୍କ ଭାଇଙ୍କ ସହିତ ନିଜର ଚିନ୍ତା ବାଣ୍ଟନ୍ତି |",
              "as": "শিখৰে ঘৰলৈ যায় আৰু তাৰ চিন্তা ককায়েকৰ সৈতে ভাগ-বতৰা কৰে।",
              "gu": "શિખર ઘરે પહોંચે છે અને તેની ચિંતા તેના ભાઈને જણાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arun Bhaiya",
              "hi": "अरुण भैया ",
              "ka": "ಅರುಣ್ ಭೈಯ್ಯ",
              "te": "అరుణ్ అన్నయ్య",
              "or": "ଅରୁଣ ଭାଇ",
              "as": "অৰুণ দাদা",
              "gu": "અરુણભાઈ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Arun Bhaiya",
              "hi": "अरुण भैया ",
              "ka": "ಅರುಣ್ ಭೈಯ್ಯ",
              "te": "అరుణ్ అన్నయ్య",
              "or": "ଅରୁଣ ଭାଇ",
              "as": "অৰুণ দাদা",
              "gu": "અરુણભાઈ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Bhaiya, Babuji will feel very disappointed in me.",
              "hi": "भैया, बाबूजी मुझसे बहुत अधिक निराश होंगे।",
              "ka": "ಭೈಯ್ಯಾ, ಬಾಬೂಜಿ ನನ್ನಿಂದಾಗಿ ತುಂಬಾ ನಿರಾಶೆ ಅನುಭವಿಸುತ್ತಿದ್ದಾರೆ",
              "te": "అన్నయ్య, నాన్నగారు నా వల్ల చాలా నిరాశకు గురవుతారు.",
              "or": "ଭାଇ, ବାବୁଜୀ ମୋ ଉପରେ ବହୁତ ନିରାଶ ଅନୁଭବ କରିବେ |",
              "as": "দাদা, দেউতাই মোৰ ওপৰত বৰ হতাশ হ'ব।",
              "gu": "ભાઈ, બાબુજી મારાથી ખૂબ નિરાશ થશે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Don’t worry, Shikhar, you will get a job somewhere. You have a degree in your hand! But tell me something, did you even like the jobs you applied to?",
              "hi": "चिंता मत करो, शिखर, तुमको कहीं न कहीं  नौकरी मिल जाएगी। तुम्हारे अपने हाथ में एक डिग्री है! लेकिन मुझे कुछ बताओ, क्या तुमको भी उन नौकरियों में कोई  पसंद आई, जिनमें तुमने आवेदन किया था ?",
              "ka": "ಚಿಂತಿಸಬೇಡ, ಶಿಖರ್, ನಿನಗೆ ಎಲ್ಲಿಯೋ ಒಂದು ಕಡೆ ಕೆಲಸ ಸಿಗುತ್ತದೆ. ನಿನ್ನ ಕೈಯಲ್ಲಿ ಪದವಿ ಇದೆ! ಆದರೆ ಹೇಳು ನೀನು ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ ಉದ್ಯೋಗಗಳು ನಿನಗೆ ಇಷ್ಟವಾಯಿತೇ?",
              "te": "చింతించకు, శిఖర్, నీకు ఎక్కడో ఒకచోట ఉద్యోగం వస్తుంది. నీ చేతిలో డిగ్రీ ఉంది! అయితే ఒక విషయం చెప్పు, నువ్వు దరఖాస్తు చేసుకున్న ఉద్యోగాలు నీకు నచ్చాయా?",
              "or": "ଚିନ୍ତା କର ନାହିଁ, ଶିଖର, ତୁମେ କୌଣସି ସ୍ଥାନରେ ଚାକିରି ପାଇବ | ତୁମ ହାତରେ ଏକ ଡିଗ୍ରୀ ଅଛି! କିନ୍ତୁ ମୋତେ କିଛି କୁହନ୍ତୁ, ଆପଣ ଆବେଦନ କରିଥିବା ଚାକିରୀକୁ ପସନ୍ଦ କରନ୍ତି କି?",
              "as": "চিন্তা নকৰিবা, শিখৰ, তুমি কৰবাত চাকৰি পাবা। তোমাৰ হাতত ডিগ্ৰী আছে! কিন্তু মোক কোৱা, তুমি আবেদন কৰা চাকৰিবোৰো ভাল পাইছিলা নেকি?",
              "gu": "ચિંતા ન કર શિખર, તને ક્યાંક નોકરી મળી જશે. તારા હાથમાં ડિગ્રી છે! પણ મને કંઈક કહો, તમે જે નોકરીઓ માટે અરજી કરી હતી તે તમને પણ ગમી હતી?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "There was so much pressure to get a job, I didn’t think of it like that.",
              "hi": "नौकरी पाने के लिए इतना दबाव होगा, मैंने ऐसा नहीं सोचा था।",
              "ka": "ಕೆಲಸ ಪಡೆಯಲು ತುಂಬಾ ಒತ್ತಡವಿತ್ತು, ಹಾಗಾಗಿ ನಾನು ಅದರ ಬಗ್ಗೆ ಯೋಚಿಸಲಿಲ್ಲ.",
              "te": "ఉద్యోగం తెచ్చుకోవాలనే ఒత్తిడి చాలా ఉంది, నేను దాని గురించి ఆలోచించలేదు.",
              "or": "ଚାକିରି ପାଇବା ପାଇଁ ଏତେ ଚାପ ଥିଲା, ମୁଁ ସେପରି କିଛି ଭାବି ନ ଥିଲି |",
              "as": "চাকৰি এটা পাবলৈ ইমান চাপ আছিল, মই ভবা নাছিলোঁ।",
              "gu": "નોકરી મેળવવા માટે ખૂબ દબાણ હતું, મેં તેના જેવું વિચાર્યું ન હતું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "So maybe that could be the reason? Say, what do you like to do?",
              "hi": "तो शायद यह कारण हो सकता है? कहो, तुम क्या करना पसंद करते हो?",
              "ka": "ಹಾಗಾದರೆ ಬಹುಶಃ ಅದು ಕಾರಣವಾಗಿರಬಹುದೇ? ಹೇಳು, ನೀನು ಏನು ಮಾಡಲು ಇಷ್ಟಪಡುತ್ತೀಯಾ?",
              "te": "కాబట్టి బహుశా అది కారణం కావచ్చు? చెప్పు, నువ్వు ఏమి చేయాలనుకుంటున్నావు?",
              "or": "ତେବେ ବୋଧହୁଏ ଏହାହିଁ ଏହାର କାରଣ ହୋଇପାରେ? କୁହ, ତୁମେ କ’ଣ\n  କରିବାକୁ ପସନ୍ଦ କରୁଛ?",
              "as": "গতিকে হয়তো সেইটোৱেই কাৰণ হ'ব পাৰে? কওক, তুমি কি কৰিব বিচাৰা?",
              "gu": "તો કદાચ એ કારણ હોઈ શકે? બોલો, તને શું કરવું ગમે છે?"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_2_2///",
              "hi": "D6_2_2 ////",
              "ka": "D6_2_2///",
              "te": "D6_2_2///",
              "or": "D6_2_2///",
              "as": "D6_2_2///",
              "gu": "D6_2_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Honestly, I would like to work in Microfinance. There was a class in college that Bharati ma’am took. That course I loved. I have been reading about it since then. There is so much I can do with people in villages or smaller towns.",
              "hi": "ईमानदारी से, मैं माइक्रोफाइनेंस  में काम करना चाहूंगा। कॉलेज में एक कक्षा थी जिसे भारती मैम लिया करती थीं। वह कोर्स  मैं पसंद करता था। मैं तब से इसके बारे में पढ़ रहा हूं। मैं गांवों या छोटे शहरों में लोगों के साथ बहुत कुछ कर सकता हूं।",
              "ka": "ಪ್ರಾಮಾಣಿಕವಾಗಿ, ನಾನು ಮೈಕ್ರೋಫೈನಾನ್ಸ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಬಯಸುತ್ತೇನೆ. ಕಾಲೇಜಿನಲ್ಲಿ ಭಾರತಿ ಮೇಡಂ ತೆಗೆದುಕೊಂಡ ತರಗತಿ ಇತ್ತು. ಆ ಕೋರ್ಸ್ ನನಗೆ ಇಷ್ಟವಾಯಿತು. ಅಂದಿನಿಂದ ನಾನು ಅದರ ಬಗ್ಗೆ ಓದುತ್ತಿದ್ದೇನೆ. ಹಳ್ಳಿಗಳು ಅಥವಾ ಸಣ್ಣ ಪಟ್ಟಣಗಳ ಜನರೊಂದಿಗೆ ನಾನು ಮಾಡಲು ತುಂಬಾ ಇದೆ.",
              "te": "నిజాయితీగా, నేను మైక్రోఫైనాన్స్ లో పని చేయాలనుకుంటున్నాను. కళాశాలలో భారతి మేడమ్ తీసుకున్న క్లాస్ ఉంది. ఆ కోర్సు నాకు నచ్చింది. అప్పటి నుండి నేను దాని గురించి చదువుతున్నాను. గ్రామాలు లేదా చిన్న పట్టణాల్లోని వ్యక్తులతో నేను చేయగలిగేది చాలా ఉంది.",
              "or": "ସତ କହିବାକୁ ଗଲେ, ମୁଁ ମାଇକ୍ରୋ ଫାଇନାନ୍ସରେ କାମ କରିବାକୁ ଚାହେଁ |\n  କଲେଜରେ ଏକ ଶ୍ରେଣୀ ଥିଲା ଯାହାକୁ ଭାରତୀ ମା ’ନେଇଥିଲେ | ସେହି ପାଠ୍ୟକ୍ରମ\n  ମୁଁ ଭଲ ପାଉଥିଲି | ସେବେଠାରୁ ମୁଁ ଏହା ବିଷୟରେ ପଢି ଆସୁଛି | \n ଗାଁ କିମ୍ବା ଛୋଟ ସହରର ଲୋକଙ୍କ ସହିତ ମୁଁ ବହୁତ କିଛି କରିପାରିବି |",
              "as": "সঁচাকৈয়ে, মই মাইক্ৰ'ফাইনেন্সত কাম কৰিব বিচাৰোঁ। কলেজত এটা ক্লাছ আছিল যিটো ভাৰতী মেমে লৈছিল। সেই পাঠ্যক্ৰমটো মই ভাল পাইছিলো। মই তেতিয়াৰ পৰা ইয়াৰ বিষয়ে পঢ়ি আছোঁ। গাওঁ বা সৰু চহৰৰ মানুহৰ সৈতে মই বহুত কৰিব পাৰোঁ।",
              "gu": "પ્રામાણિકપણે, હું માઇક્રોફાઇનાન્સમાં કામ કરવા માંગુ છું. કૉલેજમાં એક ક્લાસ હતો જે ભારતી મેડમે લીધો હતો. તે કોર્સ મને ગમ્યો. ત્યારથી હું તેના વિશે વાંચું છું. ગામડાં કે નાના શહેરોના લોકો સાથે હું ઘણું બધું કરી શકું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "So did you apply to these organisations?",
              "hi": "तो क्या तुमने इन संस्थाओं में आवेदन किया?",
              "ka": "ಹಾಗಾದರೆ ನೀನು ಈ ಸಂಸ್ಥೆಗಳಿಗೆ ಅರ್ಜಿಯನ್ನು ಸಲ್ಲಿಸಿದ್ದೀಯಾ?",
              "te": "అంటే నువ్వు ఈ సంస్థలకు దరఖాస్తు చేశావా?",
              "or": "ତେବେ ଆପଣ ଏହି ସଂସ୍ଥାଗୁଡ଼ିକରେ ଆବେଦନ କରିଛନ୍ତି କି?",
              "as": "তেন্তে তুমি এই সংস্থাবোৰত আবেদন কৰিছিলানে?",
              "gu": "તો શું તમે આ સંસ્થાઓને અરજી કરી હતી?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "No Bhaiya, the starting pay is usually less, so nobody wants to do the job. From what Babuji was saying, we need a lot of money to get some farm equipment. I will not be able to afford that if I take a job in microfinance.",
              "hi": "नहीं भैया, आरंभिक वेतन सामान्यतः कम होता है, इसलिए कोई भी नौकरी नहीं करना चाहता। बाबूजी ने जो कहा था, उस वेतन से कुछ कृषि उपकरण प्राप्त करने के लिए हमें बहुत सारा पैसा चाहिए। अगर मैं माइक्रोफाइनेंस में नौकरी करता हूं तो मैं ऐसा करने में सक्षम नहीं होऊंगा।",
              "ka": "ಇಲ್ಲ ಭೈಯ್ಯಾ, ಆರಂಭಿಕ ವೇತನವು ಸಾಮಾನ್ಯವಾಗಿ ಕಡಿಮೆ ಇರುತ್ತದೆ, ಆದ್ದರಿಂದ ಯಾರೂ ಕೆಲಸವನ್ನು ಮಾಡಲು ಬಯಸುವುದಿಲ್ಲ. ಬಾಬೂಜಿ ಹೇಳುತ್ತಿದ್ದ ಮಾತುಗಳಿಂದ ನಮಗೆ ಕೃಷಿ ಉಪಕರಣಗಳನ್ನು ಪಡೆಯಲು ಸಾಕಷ್ಟು ಹಣ ಬೇಕು. ನಾನು ಮೈಕ್ರೋಫೈನಾನ್ಸ್‌ನಲ್ಲಿ ಕೆಲಸ ತೆಗೆದುಕೊಂಡರೆ ಅದನ್ನು ಭರಿಸಲು ನನಗೆ ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ.",
              "te": "లేదు అన్నయ్య, ప్రారంభ వేతనం సాధారణంగా తక్కువగా ఉంటుంది, కాబట్టి ఎవరూ ఉద్యోగం చేయడానికి ఇష్టపడరు. నాన్నగారు చెబుతున్నదాని ప్రకారం, కొన్ని వ్యవసాయ పరికరాలు కొనడానికి మనకు చాలా డబ్బు కావాలి. మైక్రోఫైనాన్స్ లో ఉద్యోగం చేస్తే నాకు ఆ స్తోమత ఉండదు.",
              "or": "ନା ଭାଇ, ପ୍ରାରମ୍ଭିକ ଦରମା ସାଧାରଣତଃ କମ୍, ତେଣୁ କେହି ଏହି କାମ କରିବାକୁ \n ଚାହାଁନ୍ତି ନାହିଁ | ବାବୁଜୀ ଯାହା କହୁଥିଲେ, ସେଥିରୁ କିଛି ଚାଷ ଉପକରଣ ପାଇବା \n ପାଇଁ ଆମକୁ ବହୁତ ଟଙ୍କା ଦରକାର | ଯଦି ମୁଁ ମାଇକ୍ରୋ ଫାଇନାନ୍ସରେ ଚାକିରି କରେ,\n  ତେବେ ମୁଁ ତାହା ଦେଇ ପାରିବି ନାହିଁ |",
              "as": "নহয় দাদা, আৰম্ভণিৰ দৰমহা সাধাৰণতে কম হয়, সেয়েহে কোনেও কামটো কৰিব নিবিচাৰে। দেউতাই যি কৈছিল তাৰ পৰা, কিছুমান খেতিৰ সঁজুলি পাবলৈ আমাক যথেষ্ট টকা লাগে। যদি মই মাইক্ৰ'ফাইনেন্সত চাকৰি লওঁ তেন্তে মই সেইটো বহন কৰিব নোৱাৰিম।",
              "gu": "ના ભાઈ, શરુઆતનો પગાર સામાન્ય રીતે ઓછો હોય છે, તેથી કોઈ કામ કરવા માંગતું નથી. બાબુજી જે કહેતા હતા તેના પરથી આપણે ખેતીના કેટલાક સાધનો ખરીદવા માટે ઘણા પૈસાની જરૂર છે. જો હું માઇક્રોફાઇનાન્સમાં નોકરી લઉં તો મને તે પરવડે નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Hmm. Money is crucial for us right now, but why are we worrying about all this today? You have just come home. We'll talk more then.",
              "hi": "हम्म। अभी हमारे लिए पैसा महत्वपूर्ण है, लेकिन आज हम इस सब के बारे में क्यों चिंता कर रहे हैं? तुम अभी घर आए हो । हम बाद में और बात करेंगे।",
              "ka": "ಹ್ಮ. ಹಣವು ನಮಗೆ ಬೇಕಾಗಿದೆ, ಆದರೆ ನಾವು ಇಂದು ಈ ಎಲ್ಲದರ ಬಗ್ಗೆ ಏಕೆ ಚಿಂತಿಸುತ್ತಿದ್ದೇವೆ? ಈಗಷ್ಟೇ ಮನೆಗೆ ಬಂದಿದ್ದೀಯ. ನಾವು ನಂತರ ಹೆಚ್ಚು ಮಾತನಾಡೋಣ.",
              "te": "హ్మ్. ప్రస్తుతం మనకు డబ్బు చాలా కీలకం, కానీ ఈ రోజే మనం దీని గురించి ఎందుకు చింతిస్తున్నాము? నువ్వు ఇప్పుడే ఇంటికి వచ్చావు. మనము తర్వాత మళ్ళీ మాట్లాడదాము.",
              "or": "ହମ୍। ବର୍ତ୍ତମାନ ଆମ ପାଇଁ ଟଙ୍କା ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ, କିନ୍ତୁ ଆଜି ଆମେ ଏସବୁ ବିଷୟ\n ରେ କାହିଁକି ଚିନ୍ତା କରୁଛୁ? ତୁମେ ବର୍ତ୍ତମାନ ଘରକୁ ଆସିଛ | ଆମେ ପରେ \n  ଅଧିକ କଥା ହେବା |",
              "as": "হুম এই মুহূৰ্তত আমাৰ বাবে টকা দৰকাৰী, কিন্তু আমি আজি এই সকলোবোৰৰ বিষয়ে কিয় চিন্তা কৰিছোঁ? তুমি এইমাত্ৰ ঘৰলৈ আহিছা। আমি তেতিয়া অধিক কথা পাতিম।",
              "gu": "હા. અત્યારે આપણા માટે પૈસા મહત્ત્વપૂર્ણ છે, પણ આજે આપણે આ બધાની ચિંતા શા માટે કરીએ છીએ? તું હમણાં જ ઘરે આવ્યો છે. પછી વધુ વાત કરીશું."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_2_3",
              "hi": "D6_2_3",
              "ka": "D6_2_3",
              "te": "D6_2_3",
              "or": "D6_2_3",
              "as": "D6_2_3",
              "gu": "D6_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The conversation with his brother leaves Shikhar very restless, and he starts thinking if he should look for jobs that he likes.",
              "hi": "अपने भाई के साथ हुई बातचीत से  शिखर और भी  बेचैन हो जाता  है, और वह सोचता है कि क्या उसे उन नौकरियों को खोजना चाहिए जिन्हें वह पसंद करता है।",
              "ka": "ಅವನ ಸಹೋದರನೊಂದಿಗಿನ ಸಂಭಾಷಣೆಯು ಶಿಖರ್‌ನನ್ನು ತುಂಬಾ ಚಂಚಲಗೊಳಿಸುತ್ತದೆ ಮತ್ತು ಅವನು ಇಷ್ಟಪಡುವ ಉದ್ಯೋಗಗಳನ್ನು ಹುಡುಕಬೇಕೇ ಎಂದು ಅವನು ಯೋಚಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ.",
              "te": "అతని సోదరుడితో సంభాషణ శిఖర్‌ ను చాలా అశాంతికి గురి చేస్తుంది మరియు అతను తనకు నచ్చిన ఉద్యోగాల కోసం వెతకాలి అని ఆలోచిస్తాడు.",
              "or": "ତାଙ୍କ ଭାଇଙ୍କ ସହ ବାର୍ତ୍ତାଳାପ ଶିଖରଙ୍କୁ ବହୁତ ଅସ୍ଥିର କରିଦିଏ, ଏବଂ ସେ \n ଭାବିବାକୁ ଲାଗିଲେ ଯେ ସେ ପସନ୍ଦ କରୁଥିବା ଚାକିରି ଖୋଜିବା ଉଚିତ୍ କି?",
              "as": "তাৰ ককায়েকৰ সৈতে হোৱা কথা-বতৰাই শিখৰক অতি অস্থিৰ কৰি তোলে, আৰু সি ভাবিবলৈ লয় যে সি ভাল লগা চাকৰি বিচাৰিব লাগে নেকি।",
              "gu": "તેના ભાઈ સાથેની વાતચીતથી શિખર ખૂબ જ ઉદાસ થઈ જાય છે, અને તે વિચારવા લાગે છે કે શું તેને ગમતી નોકરીઓ શોધવી જોઈએ."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_3_1 search",
              "hi": "D6_3_1 खोज",
              "ka": "D6_3_1 search",
              "te": "D6_3_1 శోధన",
              "or": "D6_3_1 search",
              "as": "D6_3_1 search",
              "gu": "D6_3_1 શોધ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Not overthinking, Shikhar decides to look online for some jobs in microfinance.",
              "hi": "अत्यधिक न सोचते हुए, शिखर माइक्रोफाइनेंस में नौकरियां ऑनलाइन देखने का निश्चय करता है।",
              "ka": "ಅತಿಯಾಗಿ ಯೋಚಿಸದೆ, ಶಿಖರ್ ಮೈಕ್ರೋಫೈನಾನ್ಸ್‌ನಲ್ಲಿ ಕೆಲವು ಉದ್ಯೋಗಗಳಿಗಾಗಿ ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ನೋಡಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ",
              "te": "అతిగా ఆలోచించకుండా, మైక్రోఫైనాన్స్ లో కొన్ని ఉద్యోగాల కోసం ఆన్‌లైన్లో చూడాలని శిఖర్ నిర్ణయించుకున్నాడు.",
              "or": "ଅଧିକ ଚିନ୍ତା ନକରି, ଶିଖର ମାଇକ୍ରୋ ଫାଇନାନ୍ସରେ କିଛି ଚାକିରି ପାଇଁ\n  ଅନଲାଇନ୍ ଖୋଜିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "অত্যাধিক চিন্তা নকৰি, শিখৰে মাইক্ৰ'ফাইনেন্সত কিছুমান চাকৰিৰ বাবে অনলাইন চাবলৈ ঠিক কৰে।",
              "gu": "વધુ વિચાર્યા વિના, શિખરે માઇક્રોફાઇનાન્સમાં કેટલીક નોકરીઓ માટે ઑનલાઇન જોવાનું નક્કી કર્યું."
            }
          },
          {
            "valuePath": "blocks.0.props.strings.0.string",
            "value": {
              "en": "Microfinance + jobs + Indore",
              "hi": "माइक्रोफाइनेंस + जॉब्स + इंदौर",
              "ka": "ಮೈಕ್ರೋಫೈನಾನ್ಸ್ + ಉದ್ಯೋಗಗಳು + ಇಂದೋರ್",
              "te": "మైక్రోఫైనాన్స్ + ఉద్యోగాలు + ఇండోర్",
              "or": "Microfinance + jobs + Indore",
              "as": "মাইক্ৰ'ফাইনেন্স + চাকৰি + ইন্দোৰ",
              "gu": "માઇક્રોફાઇનાન્સ + નોકરીઓ + ઇન્દોર"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_3_3",
              "hi": "D6_3_3",
              "ka": "D6_3_3",
              "te": "D6_3_3",
              "or": "D6_3_3",
              "as": "D6_3_3",
              "gu": "D6_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Result -\nInternship at Bandhan Microfinance",
              "hi": "परिणाम -\nबंधन माइक्रोफाइनेंस में इंटर्नशिप",
              "ka": "ಮೈಕ್ರೋಫೈನಾನ್ಸ್ + ಉದ್ಯೋಗಗಳು + ಇಂದೋರ್",
              "te": "ఫలితం -\n బంధన్ మైక్రోఫైనాన్స్ లో ఇంటర్న్‌షిప్",
              "or": "Result -\n Internship at Bandhan Microfinance",
              "as": "ফলাফল -\n বন্ধন মাইক্ৰ'ফাইনেন্সত ইণ্টাৰ্ণশ্বিপ",
              "gu": "પરિણામ - બંધન માઇક્રોફાઇનાન્સ ખાતે ઇન્ટર્નશિપ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Read more",
              "hi": "आगे पढ़ें",
              "ka": "ಮತ್ತಷ್ಟು ಓದಿ",
              "te": "ఇంకా చదవండి",
              "or": "ଅଧିକ ପଢ",
              "as": "অধিক পঢ়ক",
              "gu": "વધુ વાંચો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "The internship will last for three months. The intern's job is to work with the team to create awareness about microfinance for different towns and villages and help the team implement the strategy.  The bank will observe the intern over this period and offer a full-time role depending on the performance.",
              "hi": "इंटर्नशिप तीन महीने तक चलेगी। इंटर्न का काम टीम के साथ विभिन्न कस्बों और गांवों के लिए माइक्रोफाइनेंस के बारे में जागरूकता पैदा करने और रणनीति को लागू करने में सहायता करने के लिए काम करना है। बैंक इस अवधि में इंटर्न का निरीक्षण करेगा और प्रदर्शन के आधार पर पूर्णकालिक पद प्रदान करेगा।",
              "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಮೂರು ತಿಂಗಳವರೆಗೆ ಇರುತ್ತದೆ. ವಿವಿಧ ಪಟ್ಟಣಗಳು ​​ಮತ್ತು ಹಳ್ಳಿಗಳಿಗೆ ಕಿರುಬಂಡವಾಳದ ಬಗ್ಗೆ ಅರಿವು ಮೂಡಿಸಲು ತಂಡದೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುವುದು ಮತ್ತು ಕಾರ್ಯತಂತ್ರವನ್ನು ಕಾರ್ಯಗತಗೊಳಿಸಲು ತಂಡಕ್ಕೆ ಸಹಾಯ ಮಾಡುವುದು ಇಂಟರ್ನ್‌ನ ಕೆಲಸ. ಈ ಅವಧಿಯಲ್ಲಿ ಬ್ಯಾಂಕ್ ಇಂಟರ್ನ್ ಅನ್ನು ಗಮನಿಸುತ್ತದೆ ಮತ್ತು ಕಾರ್ಯಕ್ಷಮತೆಯನ್ನು ಅವಲಂಬಿಸಿ ಪೂರ್ಣ ಸಮಯದ ಪಾತ್ರವನ್ನು ನೀಡುತ್ತದೆ.",
              "te": "ఇంటర్న్‌షిప్ మూడు నెలల పాటు కొనసాగుతుంది. వివిధ పట్టణాలు మరియు గ్రామాలకు మైక్రోఫైనాన్స్ గురించి అవగాహన కల్పించడానికి బృందంతో కలిసి పని చేయడం మరియు వ్యూహాన్ని అమలు చేయడంలో బృందానికి సహాయం చేయడం ఇంటర్న్ యొక్క పని. ఈ వ్యవధిలో బ్యాంక్ ఇంటర్న్ ను గమనిస్తుంది మరియు పనితీరుపై ఆధారపడి పూర్తి-సమయం పాత్రను అందిస్తుంది.",
              "or": "ଏହି ଇଣ୍ଟର୍ନସିପ୍ ତିନିମାସ ପର୍ଯ୍ୟନ୍ତ ଚାଲିବ | ବିଭିନ୍ନ ସହର ଏବଂ ଗ୍ରାମ ପାଇଁ\n  ମାଇକ୍ରୋ ଫାଇନାନ୍ସ ବିଷୟରେ ସଚେତନତା ସୃଷ୍ଟି କରିବା ଏବଂ ଦଳକୁ କାର୍ଯ୍ୟକାରୀ କରିବାରେ ସାହାଯ୍ୟ କରିବା ପାଇଁ ଇଣ୍ଟରନେଟର କାର୍ଯ୍ୟ ହେଉଛି ଦଳ \n ସହିତ କାର୍ଯ୍ୟ କରିବା | ବ୍ୟାଙ୍କ ଏହି ଅବଧି ମଧ୍ୟରେ ଇଣ୍ଟରନ ମାନକୁ ନଜର କରିବ ଏବଂ\n  କାର୍ଯ୍ୟଦକ୍ଷତା ଉପରେ ନିର୍ଭର କରି ଏକ ପୂର୍ଣ୍ଣକାଳୀନ ଭୂମିକା ପ୍ରଦାନ କରିବ |",
              "as": "ইণ্টাৰ্ণশ্বিপটো তিনি মাহ ধৰি চলিব। ইণ্টাৰ্ণৰ কাম হৈছে বিভিন্ন চহৰ আৰু গাঁৱৰ বাবে মাইক্ৰ'ফাইনেন্সৰ বিষয়ে সজাগতা সৃষ্টি কৰিবলৈ দলটোৰ সৈতে কাম কৰা আৰু দলটোক কৌশল ৰূপায়ণ কৰাত সহায় কৰা। বেঙ্কে এই সময়ছোৱাত ইণ্টাৰ্ণটো চাব আৰু প্ৰদৰ্শনৰ ওপৰত নিৰ্ভৰ কৰি পূৰ্ণ-সময়ৰ কাম আগবঢ়াব।",
              "gu": "ઇન્ટર્નશિપ ત્રણ મહિના સુધી ચાલશે. ઇન્ટર્નનું કામ અલગ-અલગ નગરો અને ગામો માટે માઇક્રોફાઇનાન્સ વિશે જાગૃતિ લાવવા અને ટીમને વ્યૂહરચના અમલમાં લાવવામાં મદદ કરવા માટે ટીમ સાથે કામ કરવાનું છે. બેંક આ સમયગાળા દરમિયાન ઇન્ટર્નનું અવલોકન કરશે અને કામગીરીના આધારે પૂર્ણ-સમયની ભૂમિકા ઓફર કરશે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_3_4",
              "hi": "D6_3_4",
              "ka": "D6_3_4",
              "te": "D6_3_4",
              "or": "D6_3_4",
              "as": "D6_3_4",
              "gu": "D6_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar notices that this internship pays lesser than all other jobs for the first three months. The full-time salary is not disclosed.",
              "hi": "शिखर ने देखा कि यह इंटर्नशिप पहले तीन महीनों के लिए अन्य सभी नौकरियों की तुलना में कम वेतन देती है। पूर्णकालिक वेतन के बारे में साफ़ तौर पर नहीं बताया गया है।",
              "ka": "ಈ ಇಂಟರ್ನ್‌ಶಿಪ್ ಮೊದಲ ಮೂರು ತಿಂಗಳವರೆಗೆ ಎಲ್ಲಾ ಇತರ ಉದ್ಯೋಗಗಳಿಗಿಂತ ಕಡಿಮೆ ವೇತನವನ್ನು ನೀಡುತ್ತದೆ ಎಂದು ಶಿಖರ್ ಗಮನಿಸುತ್ತಾನೆ. ಪೂರ್ಣ ಸಮಯದ ಸಂಬಳವನ್ನು ಬಹಿರಂಗಪಡಿಸಲಾಗಿಲ್ಲ.",
              "te": "ఈ ఇంటర్న్‌షిప్ మొదటి మూడు నెలల పాటు అన్ని ఇతర ఉద్యోగాల కంటే తక్కువ వేతనాన్ని ఇస్తుందని శిఖర్ గమనించాడు. పూర్తి సమయం జీతం వెల్లడించలేదు.",
              "or": "ଶିଖର ଧ୍ୟାନ ଦିଅନ୍ତି ଯେ ଏହି ଇଣ୍ଟର୍ନସିପ୍ ପ୍ରଥମ ତିନି ମାସ ପାଇଁ ଅନ୍ୟ ସମସ୍ତ\n  ଚାକିରି ଅପେକ୍ଷା କମ୍ ଦରମା ଦେଇଥାଏ | ପୂର୍ଣ୍ଣକାଳୀନ ଦରମା ପ୍ରକାଶ କରାଯାଇ ନାହିଁ |",
              "as": "শিখৰে লক্ষ্য কৰিছে যে এই ইন্টাৰ্ণশ্বিপে প্ৰথম তিনি মাহৰ বাবে আন সকলো চাকৰিতকৈ কম বেতন দিয়ে। পূৰ্ণ-সময়ৰ দৰমহা প্ৰকাশ কৰা নহয়।",
              "gu": "શિખરે નોંધ્યું છે કે આ ઇન્ટર્નશિપ પ્રથમ ત્રણ મહિના માટે અન્ય તમામ નોકરીઓ કરતાં ઓછી ચૂકવણી કરે છે. પૂર્ણ-સમયનો પગાર જાહેર કરાયો નથી."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_3_5",
              "hi": "D6_3_5",
              "ka": "D6_3_5",
              "te": "D6_3_5",
              "or": "D6_3_5",
              "as": "D6_3_5",
              "gu": "D6_3_5"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He is not sure what to do and opens his diary to make some notes. He lists the advantages and disadvantages of this role in his diary.",
              "hi": "वह निश्चित नहीं  है कि क्या करना है और कुछ नोट्स बनाने के लिए अपनी डायरी खोलता है। वह अपनी डायरी में इस भूमिका के फायदे और नुकसान सूचीबद्ध करता है।",
              "ka": "ಅವನು ಏನು ಮಾಡಬೇಕೆಂದು ತೋಚದೆ ಕೆಲವು ಟಿಪ್ಪಣಿಗಳನ್ನು ಮಾಡಲು ತನ್ನ ಡೈರಿಯನ್ನು ತೆರೆದನು. ಅವನು ತನ್ನ ಡೈರಿಯಲ್ಲಿ ಈ ಕೆಲಸದ ಅನುಕೂಲಗಳು ಮತ್ತು ಅನಾನುಕೂಲಗಳನ್ನು ಪಟ್ಟಿ ಮಾಡಿದ್ದಾನೆ.",
              "te": "అతను ఏమి చేయాలో అర్థం కాక, కొన్ని నోట్స్ రాయడానికి తన డైరీని తెరిచాడు. అతను తన డైరీలో ఈ పాత్ర యొక్క ప్రయోజనాలు మరియు అప్రయోజనాలను జాబితా చేశాడు.",
              "or": "ସେ କ’ଣ କରିବେ ତାହା ନିଶ୍ଚିତ ନୁହଁନ୍ତି ଏବଂ କିଛି ଲେଖିବା ପାଇଁ ତାଙ୍କର\n  ଡାଏରୀ ଖୋଲନ୍ତି | ସେ ତାଙ୍କ ଡାଏରୀରେ ଏହି ଭୂମିକାର ସୁବିଧା ଏବଂ ଅସୁବିଧା\n  ତାଲିକାଭୁକ୍ତ କରିଛନ୍ତି |",
              "as": "সি কি কৰিব লাগে নিশ্চিত নহয় আৰু কিছুমান টোকা বনাবলৈ তাৰ ডায়েৰীখন মেলে। সি তাৰ ডায়েৰীত এই কামৰ সুবিধা আৰু অসুবিধাবোৰৰ তালিকা বনায়।",
              "gu": "શું કરવું તે સુનિશ્ચિત નથી અને કેટલીક નોંધો બનાવવા માટે તેની ડાયરી ખોલે છે. તે પોતાની ડાયરીમાં આ ભૂમિકાના ફાયદા અને ગેરફાયદાની યાદી આપે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page 1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "ପ୍ରୁଷ୍ଠା ୧",
              "as": "পৃষ্ঠা 1",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page 2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ 2",
              "or": "ପ୍ରୁଷ୍ଠା ୨",
              "as": "পৃষ্ঠা 2",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "**Advantages**\n\n- I will get an opportunity to contribute meaningfully to the community.\n\n- I must get some work in hand just so that I can support Babuji.",
              "hi": "** लाभ **\n\n- मुझे समुदाय को सार्थक रूप से योगदान करने का अवसर मिलेगा।\n\n- मुझे कुछ काम मिलना चाहिए ताकि मैं बाबूजी का सहयोग कर सकूं।",
              "ka": "**ಅನುಕೂಲಗಳು**                                                                                                                                                                                                                           ಸಮಾಜಕ್ಕೆ ಅರ್ಥಪೂರ್ಣವಾಗಿ ಕೊಡುಗೆ ನೀಡಲು ನನಗೆ ಅವಕಾಶ ಸಿಗುತ್ತದೆ.                                                                                                                                               ನಾನು ಬಾಬೂಜಿಯನ್ನು ಬೆಂಬಲಿಸಲು ನನ್ನ ಕೈಯಲ್ಲಿ ಸ್ವಲ್ಪ ಕೆಲಸವನ್ನು ಹೊಂದಿರಬೇಕು.",
              "te": "**ప్రయోజనాలు**\n \n - సమాజానికి అర్థవంతంగా సహకరించే అవకాశం నాకు లభిస్తుంది.\n \n - నేను నాన్నగారికి మద్దతివ్వాలంటే నాకు కొంత పని కావాలి.",
              "or": "** ସୁବିଧା **\n \n \n \n - ମୁଁ ସମ୍ପ୍ରଦାୟକୁ ଅର୍ଥପୂର୍ଣ୍ଣ ଭାବରେ ଯୋଗଦାନ କରିବାର ଏକ ସୁଯୋଗ ପାଇବି |\n \n \n \n - ମୁଁ ନିଶ୍ଚୟ ହାତରେ କିଛି କାମ ପାଇବି ଯାହାଦ୍ୱାରା ମୁଁ ବାବୁଜୀଙ୍କୁ ସମର୍ଥନ କରିପାରିବି |",
              "as": "**সুবিধাসমূহ**\n \n \n \n - মই সমাজলৈ অৰ্থপূৰ্ণ অৱদান আগবঢ়োৱাৰ সুযোগ পাম।\n \n \n \n - মই দেউতাক সমৰ্থন কৰিব পৰাকৈ হাতত কিছু কাম ল'ব লাগিব।",
              "gu": "**લાભ** - મને સમુદાયમાં અર્થપૂર્ણ યોગદાન કરવાની તક મળશે. - મારે હાથમાં કંઈક કામ મળવું જોઈએ જેથી હું બાબુજીને ટેકો આપી શકું."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "**Disadvantages**\n\n- The money they are offering is much lesser than the other jobs at the placements.\n\n- It is only an internship right now, with no guarantee of a full-time job.",
              "hi": "** नुकसान **\n\n- वे जितना वेतन देने का प्रस्ताव रख रहे हैं वह प्लेसमेंट में अन्य नौकरियों की तुलना में बहुत कम है।\n\n- यह अभी एक इंटर्नशिप है, पूर्णकालिक नौकरी की कोई गारंटी नहीं है।",
              "ka": "**ಅನಾನುಕೂಲಗಳು**                                                                                                                                                                                                                  ಅವರು ನೀಡುತ್ತಿರುವ ಹಣವು ಪ್ಲೇಸ್‌ಮೆಂಟ್‌ಗಳಲ್ಲಿನ ಇತರ ಉದ್ಯೋಗಗಳಿಗಿಂತ ತುಂಬಾ ಕಡಿಮೆಯಾಗಿದೆ.                                                                                                               ಇದು ಬರೀ ಇಂಟರ್ನ್‌ಶಿಪ್ ಆಗಿದೆ, ಪೂರ್ಣ ಸಮಯದ ಉದ್ಯೋಗದ ಖಾತರಿಯಿಲ್ಲ.",
              "te": "**ప్రతికూలతలు**\n \n - ప్లేస్‌మెంట్‌ లలో ఇతర ఉద్యోగాల కంటే వారు ఇచ్చే డబ్బు చాలా తక్కువ.\n \n - ఇది ప్రస్తుతం ఇంటర్న్‌షిప్ మాత్రమే, పూర్తి సమయం ఉద్యోగానికి హామీ లేదు.",
              "or": "** ଅସୁବିଧା **\n \n \n \n - ସେମାନେ ପ୍ରଦାନ କରୁଥିବା ଟଙ୍କା ପ୍ଲେସମେଣ୍ଟରେ ଥିବା ଅନ୍ୟ ଚାକିରି ତୁଳନାରେ ବହୁତ କମ୍ ଅଟେ |\n \n \n \n - ଏହା କେବଳ ଏକ ଇଣ୍ଟର୍ନସିପ୍ ଅଟେ, ଯେଉଁଥିରେ ପୂର୍ଣ୍ଣକାଳୀନ ଚାକିରି ର କୌଣସି ଗ୍ୟାରେଣ୍ଟି ନାହିଁ |",
              "as": "**অসুবিধা**\n \n \n \n - তেওঁলোকে দিয়া ধন নিয়োগৰ আন চাকৰিবোৰতকৈ যথেষ্ট কম।\n \n \n \n - এইটো এই মুহূৰ্তত কেৱল এটা ইন্টাৰ্ণশ্বিপ, পূৰ্ণ-সময়ৰ চাকৰিৰ কোনো নিশ্চয়তা নাই।",
              "gu": "**ગેરફાયદાઓ** - તેઓ જે પૈસા ઓફર કરે છે તે નિમણૂક પરની અન્ય નોકરીઓ કરતા ઘણા ઓછા છે. - અત્યારે તે માત્ર એક ઇન્ટર્નશિપ છે, જેમાં પૂર્ણ-સમયની નોકરીની કોઈ ગેરેંટી નથી."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6_3_6 (D)",
              "hi": "D6_3_6 (d)",
              "ka": "D6_3_6 (D)",
              "te": "D6_3_6 (D)",
              "or": "D6_3_6 (D)",
              "as": "D6_3_6 (D)",
              "gu": "D6_3_6 (D)"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Shikhar do?",
              "hi": "शिखर को क्या करना चाहिए?",
              "ka": "ಶಿಖರ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "శిఖర్ ఏం చేయాలి?",
              "or": "ଶିଖର କ’ଣ କରିବା ଉଚିତ୍?",
              "as": "শিখৰে কি কৰিব লাগে?",
              "gu": "શિખરે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Apply for the internship",
              "hi": "इंटर्नशिप के लिए आवेदन करे",
              "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಗೆ ಅರ್ಜಿ ಹಾಕುವುದು",
              "te": "ఇంటర్న్‌షిప్ కోసం దరఖాస్తు చేసుకోవడం",
              "or": "ଇଣ୍ଟର୍ନସିପ୍ ପାଇଁ ଆବେଦନ କରନ୍ତୁ |",
              "as": "ইন্টাৰ্ণশ্বিপৰ বাবে আবেদন কৰা",
              "gu": "ઇન્ટર્નશિપ માટે અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Wait for other opportunities",
              "hi": "अन्य अवसरों की प्रतीक्षा करे",
              "ka": "ಇತರ ಅವಕಾಶಗಳಿಗಾಗಿ ನಿರೀಕ್ಷಿಸುವುದು",
              "te": "ఇతర అవకాశాల కోసం వేచి ఉండడం",
              "or": "ଅନ୍ୟ ସୁଯୋଗ ପାଇଁ ଅପେକ୍ଷା କର |",
              "as": "আন সুযোগৰ বাবে ৰোৱা",
              "gu": "અન્ય તકોની રાહ જુઓ"
            }
          }
        ]
      },
      "scene34": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Apply for the internship",
              "hi": "इंटर्नशिप के लिए आवेदन करे",
              "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್ ಗೆ ಅರ್ಜಿ ಹಾಕುವುದು",
              "te": "ఇంటర్న్‌షిప్ కోసం దరఖాస్తు చేసుకోవడం",
              "or": "ଇଣ୍ଟର୍ନସିପ୍ ପାଇଁ ଆବେଦନ କରନ୍ତୁ |",
              "as": "ইন্টাৰ্ণশ্বিপৰ বাবে আবেদন কৰা",
              "gu": "ઇન્ટર્નશિપ માટે અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He starts his role as an intern and tries to find opportunities to learn from the seniors at the bank.",
              "hi": "वह एक इंटर्न के रूप में अपनी नौकरी शुरु करता है और बैंक में सीनियर्स से सीखने के अवसरों को खोजने की कोशिश करता है।",
              "ka": "ಅವನು ಇಂಟರ್ನ್ ಆಗಿ ತನ್ನ ಕೆಲಸವನ್ನು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ ಮತ್ತು ಬ್ಯಾಂಕಿನಲ್ಲಿ ಹಿರಿಯರಿಂದ ಕಲಿಯಲು ಅವಕಾಶಗಳನ್ನು ಹುಡುಕಲು ಪ್ರಯತ್ನಿಸುತ್ತಾನೆ.",
              "te": "అతను ఇంటర్న్ గా తన పాత్రను ప్రారంభించాడు మరియు బ్యాంక్‌లోని సీనియర్ల నుండి నేర్చుకునే అవకాశాలను కనుగొనడానికి ప్రయత్నిస్తాడు.",
              "or": "ସେ ଜଣେ ଇଣ୍ଟରଣ୍ଣ ଭାବରେ ତାଙ୍କର ଭୂମିକା ଆରମ୍ଭ କରନ୍ତି ଏବଂ ବ୍ୟାଙ୍କରେ \n ସିନିୟରମାନଙ୍କଠାରୁ ଶିଖିବାର ସୁଯୋଗ ଖୋଜିବାକୁ ଚେଷ୍ଟା କରନ୍ତି |",
              "as": "সি এজন ইণ্টাৰ্ন হিচাপে তাৰ কাম আৰম্ভ কৰে আৰু বেংকৰ জ্যেষ্ঠসকলৰ পৰা শিকাৰ সুযোগ বিচৰাৰ চেষ্টা কৰে।",
              "gu": "તે એક ઇન્ટર્ન તરીકે તેની ભૂમિકા શરૂ કરે છે અને બેંકમાં વરિષ્ઠો પાસેથી શીખવાની તકો શોધવાનો પ્રયાસ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "d7_1_1b",
              "hi": "D7_1_1B",
              "ka": "d7_1_1b",
              "te": "d7_1_1b",
              "or": "d7_1_1b",
              "as": "d7_1_1b",
              "gu": "d7_1_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "His main work is to plan awareness programs in the villages about microfinance schemes. He also has to travel to different villages and towns as part of the awareness drive.",
              "hi": "उसका मुख्य काम माइक्रोफाइनेंस स्कीम्स के विषय में गांवों में जागरूकता कार्यक्रमों की योजना बनाना है। उसे जागरूकता अभियान के हिस्से के रूप में विभिन्न गांवों और कस्बों की यात्रा भी करनी है।",
              "ka": "ಕಿರುಬಂಡವಾಳ ಯೋಜನೆಗಳ ಬಗ್ಗೆ ಹಳ್ಳಿಗಳಲ್ಲಿ ಜಾಗೃತಿ ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ಯೋಜಿಸುವುದು ಆತನ ಮುಖ್ಯ ಕೆಲಸ. ಜಾಗೃತಿ ಅಭಿಯಾನದ ಅಂಗವಾಗಿ ಆತನು ವಿವಿಧ ಗ್ರಾಮಗಳು ಮತ್ತು ಪಟ್ಟಣಗಳಿಗೆ ಪ್ರಯಾಣಿಸಬೇಕಾಗಿದೆ.",
              "te": "మైక్రోఫైనాన్స్ పథకాల గురించి గ్రామాల్లో అవగాహన కార్యక్రమాలను ప్రణాళిక చేయడం ఆయన ప్రధాన పని. అవగాహన యాత్రలో భాగంగా వివిధ గ్రామాలు, పట్టణాలకు కూడా వెళ్లాల్సి ఉంటుంది.",
              "or": "ତାଙ୍କର ମୁଖ୍ୟ କାର୍ଯ୍ୟ ହେଉଛି ମାଇକ୍ରୋ ଫାଇନାନ୍ସ ଯୋଜନା ବିଷୟରେ ଗ୍ରାମଗୁଡିକରେ\n  ସଚେତନତା କାର୍ଯ୍ୟକ୍ରମ ଯୋଜନା କରିବା | ତାଙ୍କୁ ସଚେତନତା ଡ୍ରାଇଭ୍ ର ଏକ ଅଂଶ\n  ଭାବରେ \n ବିଭିନ୍ନ ଗ୍ରାମ ଏବଂ ସହରକୁ ଯାତ୍ରା କରିବାକୁ ପଡିବ |",
              "as": "তাৰ মুখ্য কাম হৈছে মাইক্ৰ'ফাইনেন্স আঁচনিৰ বিষয়ে গাঁওবোৰত সজাগতা কাৰ্যসূচীৰ সৃষ্টি কৰা। সি সজাগতা অভিযানৰ অংশ হিচাপে বিভিন্ন গাঁও আৰু চহৰলৈও যাব লাগিব।",
              "gu": "તેમનું મુખ્ય કાર્ય ગામડાઓમાં માઈક્રોફાઈનાન્સ યોજનાઓ વિશે જાગૃતિ કાર્યક્રમોનું આયોજન કરવાનું છે. તેમણે જાગૃતિ અભિયાનના ભાગરૂપે જુદા જુદા ગામડાઓ અને નગરોની મુસાફરી પણ કરવી પડશે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_1_2 bg",
              "hi": "D7_1_2 बीजी",
              "ka": "D7_1_2 bg",
              "te": "D7_1_2 bg",
              "or": "D7_1_2 bg",
              "as": "D7_1_2 bg",
              "gu": "D7_1_2 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He takes up any task that can help or support the work at the organisation. He treats each task as a learning experience.",
              "hi": "वह ऐसा कोई भी काम करता है जो संगठन के काम में सहयोग या समर्थन कर सकता है। \nवह प्रत्येक कार्य को सीखने के अनुभव के रूप में मानता है।",
              "ka": "ಸಂಸ್ಥೆಯಲ್ಲಿನ ಕೆಲಸಕ್ಕೆ ಸಹಾಯ ಮಾಡುವ ಅಥವಾ ಬೆಂಬಲಿಸುವ ಯಾವುದೇ ಕೆಲಸವನ್ನು ಅವನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾನೆ. ಅವನು ಪ್ರತಿಯೊಂದು ಕೆಲಸವನ್ನು ಕಲಿಕೆಯ ಅನುಭವವಾಗಿ ಪರಿಗಣಿಸುತ್ತಾನೆ",
              "te": "సంస్థలో పనికి సహాయపడే లేదా మద్దతు ఇచ్చే ఏదైనా పనిని అతను తీసుకుంటాడు. అతను ప్రతి పనిని ఒక నేర్చుకునే అనుభవంగా భావిస్తాడు.",
              "or": "ସେ ଯେକୌଣସି କାର୍ଯ୍ୟ କରନ୍ତି ଯାହା ସଂଗଠନରେ କାର୍ଯ୍ୟକୁ ସାହାଯ୍ୟ କିମ୍ବା \n ସମର୍ଥନ କରିପାରିବ | ସେ ପ୍ରତ୍ୟେକ କାର୍ଯ୍ୟକୁ ଏକ ଶିକ୍ଷଣ ଅଭିଜ୍ଞତା ଭାବରେ \n ବ୍ୟବହାର କରନ୍ତି |",
              "as": "সি সংগঠনটোৰ কামত সহায় বা সমৰ্থন কৰা যিকোনো কাম লয়। সি প্ৰতিটো কামক শিকাৰ অভিজ্ঞতা হিচাপে মানি লয়।",
              "gu": "તે કોઈપણ કાર્ય હાથમાં લે છે જે સંસ્થાના કાર્યમાં મદદ અથવા સમર્થન કરી શકે છે. તે દરેક કાર્યને શીખવાના અનુભવ તરીકે ગણે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Ma'am",
              "hi": "महोदया",
              "ka": "ಮೇಡಮ್",
              "te": "మేడమ్",
              "or": "ମ୍ୟାମ",
              "as": "মেম",
              "gu": "મેડમ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Ma'am",
              "hi": "महोदया",
              "ka": "ಮೇಡಮ್",
              "te": "మేడమ్",
              "or": "ମ୍ୟାମ",
              "as": "মেম",
              "gu": "મેડમ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Shikhar, can you please log in these reports?",
              "hi": "शिखर, क्या आप इन रिपोर्टों में लॉग इन कर सकते हैं?",
              "ka": "ಶಿಖರ್, ದಯವಿಟ್ಟು ಈ ವರದಿಗಳನ್ನು ಲಾಗ್ ಇನ್ ಮಾಡಬಹುದೇ?",
              "te": "శిఖర్, దయచేసి మీరు ఈ రిపోర్ట్ లను లాగిన్ చేయగలరా?",
              "or": "ଶିଖର, ଆପଣ ଦୟାକରି ଏହି ରିପୋର୍ଟଗୁଡିକରେ ଲଗ୍ଇନ କରିପାରିବେ କି?",
              "as": "শিখৰ, তুমি এই প্ৰতিবেদনবোৰ লগ ইন কৰাত সহায় কৰিব পাৰিবানে?",
              "gu": "શિખર, શું તું આ અહેવાલોમાં લૉગ ઇન કરી શકશો?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Yes, sure",
              "hi": "हाँ, बिलकुल ",
              "ka": "ಆಗಲಿ, ಖಂಡಿತವಾಗಿಯೂ",
              "te": "అవును ఖచ్చితంగా",
              "or": "ହଁ, ନିଶ୍ଚୟ",
              "as": "হয়, হ'ব",
              "gu": "હા પાક્કુ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Here are the files.",
              "hi": "ये फाइलें हैं।",
              "ka": "ಕಡತಗಳು ಇಲ್ಲಿವೆ",
              "te": "ఇక్కడ ఫైల్స్ ఉన్నాయి.",
              "or": "ଫାଇଲ ଗୁଡିକ ଏଇଠି ଅଛି।",
              "as": "ইয়াত ফাইলবোৰ আছে।",
              "gu": "અહીં ફાઈલો છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_1_3 bg",
              "hi": "D7_1_3 बीजी",
              "ka": "D7_1_3 bg",
              "te": "D7_1_3 bg",
              "or": "D7_1_3 bg",
              "as": "D7_1_3 bg",
              "gu": "D7_1_3 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "While reading the files, Shikhar sees a lot of farmers rejecting the loans.",
              "hi": "फ़ाइलों को पढ़ते समय, शिखर देखता है कि कई किसानों ने  ऋण लेना अस्वीकार कर दिया।",
              "ka": "ಕಡತಗಳನ್ನು ಓದುತ್ತಿರುವಾಗ, ಶಿಖರ್ ಬಹಳಷ್ಟು ರೈತರು ಸಾಲವನ್ನು ತಿರಸ್ಕರಿಸುವುದನ್ನು ನೋಡುತ್ತಾನೆ.",
              "te": "ఫైళ్లు చదువుతున్నప్పుడు, చాలా మంది రైతులు రుణాలను తిరస్కరించడం శిఖర్‌ కు కనిపిస్తుంది.",
              "or": "ଫାଇଲଗୁଡିକ ପଢିବାବେଳେ ଶିଖର ଅନେକ କୃଷକ ରୁଣ କୁ ଣ ପ୍ରତ୍ୟାଖ୍ୟାନ\n  କରୁଥିବା ଦେଖନ୍ତି |",
              "as": "ফাইলবোৰ পঢ়ি থাকোঁতে, শিখৰে বহুতো কৃষকক ঋণ অস্বীকাৰ কৰা দেখিছে।",
              "gu": "ફાઈલો વાંચતી વખતે, શિખર ઘણા ખેડૂતોને લોન નકારતા જુએ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Ma'am",
              "hi": "महोदया",
              "ka": "ಮೇಡಮ್",
              "te": "మేడమ్",
              "or": "ମ୍ୟାମ",
              "as": "মেম",
              "gu": "મેડમ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Ma’am, why are there so many farmers not taking the loans? I thought microloans would be a big hit among farmers!",
              "hi": "महोदया, इतने सारे किसान ऋण क्यों नहीं ले रहे हैं? मैंने सोचा था कि किसानों के बीच माइक्रोलोन्स योजना  बहुत सफल होगी!",
              "ka": "ಮೇಡಂ, ಎಷ್ಟೋ ರೈತರು ಸಾಲವನ್ನು ಏಕೆ ತೆಗೆದುಕೊಳ್ಳುತ್ತಿಲ್ಲ? ಮೈಕ್ರೋಲೋನ್‌ಗಳು ರೈತರಲ್ಲಿ ದೊಡ್ಡ ಹಿಟ್ ಆಗುತ್ತವೆ ಎಂದು ನಾನು ಭಾವಿಸಿದ್ದೆ!",
              "te": "మేడమ్, చాలా మంది రైతులు ఎందుకు రుణాలు తీసుకోరు? మైక్రోలోన్లు రైతులలో పెద్ద హిట్ అవుతాయని నేను అనుకున్నాను!",
              "or": "ମା ’, କାହିଁକି ଏତେ ସଂଖ୍ୟକ କୃଷକ ରୁଣ ନେଉନାହାଁନ୍ତି? ମୁଁ ଭାବିଲି \n କୃଷକମାନଙ୍କ ମଧ୍ୟରେ ମାଇକ୍ରୋଲୋନ୍ ଏକ ବଡ଼ ସାହାଯ୍ୟ ହେବ!",
              "as": "মেম, ইমান কৃষকে ঋণ লোৱা নাই কিয়? মই ভাবিছিলো যে কৃষকসকলৰ মাজত মাইক্ৰ'লোন বহুত সফল হ'ব!",
              "gu": "મેડમ, આટલા બધા ખેડૂતો લોન કેમ નથી લેતા? મને લાગતું હતું કે ખેડૂતોમાં માઇક્રોલોન્સને મોટી અસર થશે!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "It will be a hit if they accept it. But right now, they don’t seem to listen or understand what we are offering.",
              "hi": "यदि वे इसे स्वीकार करते हैं तो यह सफल होगी। लेकिन अभी, वे सुनने या समझने के लिए तैयार नहीं हैं कि हम क्या प्रस्तावित कर रहे हैं।",
              "ka": "ಅವರು ಒಪ್ಪಿಕೊಂಡರೆ ಹಿಟ್ ಆಗುತ್ತದೆ. ಆದರೆ ಇದೀಗ, ಅವರು ನಾವು ನೀಡುತ್ತಿರುವುದನ್ನು ಕೇಳಲು ಅಥವಾ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಆಸಕ್ತಿ ತೋರುತ್ತಿಲ್ಲ.",
              "te": "వాళ్ళు ఒప్పుకుంటే హిట్ అవుతుంది. కానీ ప్రస్తుతం, మేము ఏమి అందిస్తున్నామో వారు వినడం లేదా అర్థం చేసుకోవడం లేదు.",
              "or": "ଯଦି ସେମାନେ ଏହାକୁ ଗ୍ରହଣ କରନ୍ତି ତେବେ ଏହା ଏକ ସାହାଯ୍ୟ ହେବ | କିନ୍ତୁ ବର୍ତ୍ତମାନ,\n  ସେମାନେ ଯାହା ଶୁଣୁଛନ୍ତି ତାହା ଶୁଣିବା କିମ୍ବା ବୁଝିବା ପରି ମନେ ହେଉନାହିଁ |",
              "as": "যদি তেওঁলোকে এইটো লয় তেন্তে এইটো সফল হ'ব। কিন্তু এই মুহূৰ্তত, তেওঁলোকে আমি কি আগবঢ়াইছো শুনিছে বা বুজি পাইছে যেন নালাগে।",
              "gu": "જો તેઓ તેને સ્વીકારે તો તે અસર થશે. પરંતુ અત્યારે, તેઓ આપણે જે આપવાનું કહી રહ્યા છીએ તે સાંભળતા કે સમજી શકતા નથી."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_1_4///",
              "hi": "D7_1_4 ///",
              "ka": "D7_1_4///",
              "te": "D7_1_4///",
              "or": "D7_1_4///",
              "as": "D7_1_4///",
              "gu": "D7_1_4///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Probably we are not able to explain?",
              "hi": "शायद हम समझाने में सक्षम नहीं हैं?",
              "ka": "ಬಹುಶಃ ನಾವು ಅವರಿಗೆ ವಿವರಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲವೇ?",
              "te": "బహుశా మనం వివరించలేకపోతున్నాం ఏమో?",
              "or": "ସମ୍ଭବତ ଆମେ ବ୍ୟାଖ୍ୟା କରିବାକୁ ସକ୍ଷମ ନୁହଁ କି?",
              "as": "সম্ভৱতঃ আমি বুজাব পৰা নাই?",
              "gu": "કદાચ આપણે સમજાવી શકતા નથી?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "What do you mean?",
              "hi": "आपका क्या मतलब है?",
              "ka": "ನೀನು ಏನು ಹೇಳುತ್ತಿದ್ದೀಯಾ?",
              "te": "మీ ఉద్దేశ్యం ఏమిటి?",
              "or": "କଣ ତୁମେ କହିବାକୁ ଚାହୁଁଚ ?",
              "as": "তুমি কি বুজাইছা?",
              "gu": "તમે શું કહેવા માગો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Maybe they don’t want to understand the point. My father has similar thoughts. He doesn’t believe anyone from the city telling him about his work. But if a friend would tell him, he would listen. \nTo convince them, you need to be one of them.",
              "hi": "शायद वे इस बात को समझना नहीं चाहते हैं। मेरे पिता के समान विचार हैं।यदि शहर का कोई व्यक्ति उन्हे उनके किसी काम के विषय में बताएगा तो वह उसका विश्वास नहीं करते हैं। लेकिन अगर कोई मित्र उन्हें बताएगा, तो वह बात मानेंगे।\nउन्हें विश्वास दिलाने के लिए, आपको उनमें से एक होना चाहिए।",
              "ka": "ಬಹುಶಃ ಅವರು ವಿಷಯವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಬಯಸುವುದಿಲ್ಲ. ನನ್ನ ತಂದೆಗೆ ಇದೇ ರೀತಿಯ ಆಲೋಚನೆಗಳಿವೆ. ನಗರದ ಯಾರೊಬ್ಬರು ತನ್ನ ಕೆಲಸದ ಬಗ್ಗೆ ಹೇಳಿದರೂ ಅವನು ನಂಬುವುದಿಲ್ಲ. ಆದರೆ ಗೆಳೆಯರು ಹೇಳಿದರೆ ಕೇಳುತ್ತಿದ್ದರು. ಅವರಿಗೆ ಮನವರಿಕೆ ಮಾಡಲು, ನೀವು ಅವರಲ್ಲಿ ಒಬ್ಬರಾಗಿರಬೇಕು.",
              "te": "బహుశా వారికి విషయం అర్థం కావడం ఇష్టం లేకపోవచ్చు. మా నాన్నకు ఇలాంటి ఆలోచనలు ఉన్నాయి. అతను తన పని గురించి నగరంలో ఎవరైనా చెబితే అతను నమ్మడు. కానీ ఒక స్నేహితుడు అతనికి చెబితే, అతను వింటాడు.\n వారిని ఒప్పించాలంటే మీరు వారిలో ఒకరిగా ఉండాలి.",
              "or": "ବୋଧହୁଏ ସେମାନେ ଏହି କଥା ବୁଝିବାକୁ ଚାହାଁନ୍ତି ନାହିଁ। ମୋର ପିତାଙ୍କର ମଧ୍ୟ ସମାନ ଚିନ୍ତାଧାରା ଅଛି। ସେ ବିଶ୍ଵାସ କରନ୍ତି ନାହିଁ ଯେ ସହରର କେହି ଜଣେ ତାଙ୍କୁ ତାଙ୍କ କାର୍ଯ୍ୟ ବିଷୟରେ କହିବେ। କିନ୍ତୁ ଯଦି ଜଣେ ବନ୍ଧୁ ତାଙ୍କୁ କହିବେ ତେବେ ସେ ଶୁଣିବେ। ସେମାନଙ୍କୁ ମନାଇବା ପାଇଁ ତୁମେ ସେମାନଙ୍କ ମଧ୍ୟରୁ ଜଣେ ହେବା ଆବଶ୍ୟକ।",
              "as": "হয়তো তেওঁলোকে কথাটো বুজিব নিবিচাৰে। মোৰ দেউতাইও একে ধৰণে ভাবে। তেওঁ চহৰৰ কাকো তেওঁৰ কামৰ বিষয়ে কোৱাটো বিশ্বাস নকৰে। কিন্তু যদি এজন বন্ধুৱে তেওঁক কয়, তেওঁ শুনিব। \n \n তেওঁলোকক বুজাবলৈ, তুমি তেওঁলোকৰ মাজৰ এজন হ'ব লাগিব।",
              "gu": "કદાચ તેઓ મુદ્દાને સમજવા માંગતા નથી. મારા પિતાના પણ આવા જ વિચારો છે. તે શહેરમાંથી કોઈ તેને તેના કામ વિશે કહે તે માનતો નથી. પણ જો કોઈ મિત્ર તેને કહેતો તો તે સાંભળતો. તેમને સમજાવવા માટે, તમારે તેમાંથી એક બનવાની જરૂર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "That’s a great insight Shikhar. Why don’t you join us for the next camp?",
              "hi": "यह अच्छा दृष्टिकोण है शिखर। आप अगले शिविर के लिए हमारे साथ क्यों नहीं जुड़ जाते?",
              "ka": "ಅದೊಂದು ಉತ್ತಮ ಒಳನೋಟ ಶಿಖರ್. ಮುಂದಿನ ಶಿಬಿರಕ್ಕೆ ನೀನು ನಮ್ಮೊಂದಿಗೆ ಏಕೆ ಸೇರಬಾರದು?",
              "te": "అది గొప్ప ఆలోచన శిఖర్. మీరు తదుపరి క్యాంప్ కి మాతో ఎందుకు చేరకూడదు?",
              "or": "ତାହା ହେଉଛି ଏକ ଉତ୍ତମ ବିଚାର ଶିଖର | ପରବର୍ତ୍ତୀ ଶିବିର ପାଇଁ ଆପଣ\n  କାହିଁକି ଆମ ସହିତ ଯୋଗଦେଉନାହାନ୍ତି?",
              "as": "এইটো এটা গভীৰ উপলব্ধি শিখৰ। তুমি পৰৱৰ্তী শিবিৰৰ বাবে আমাৰ সৈতে যোগদান নকৰা কিয়?",
              "gu": "તે એક મહાન સમજ છે શિખર. તમે આગલા શિબિરમાં કેમ જોડાતા નથી?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Sure, Ma’am!",
              "hi": "निश्चित रूप से, महोदया!",
              "ka": "ಖಂಡಿತ, ಮೇಡಮ್!",
              "te": "తప్పకుండా, మేడమ్!",
              "or": "ନିଶ୍ଚୟ ମ୍ୟାମ!",
              "as": "নিশ্চয়, মেম!",
              "gu": "ચોક્કસ, મેડમ!"
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "Internship",
              "hi": "इंटर्नशिप",
              "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್",
              "te": "ఇంటర్న్‌షిప్",
              "or": "ଇଣ୍ଟର୍ନ୍ସିପ",
              "as": "ইন্টাৰ্ণশ্বিপ",
              "gu": "ઇન્ટર્નશિપ"
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "ಎಮ್‌ಸಿ‌ಕ್ಯೂ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar does an internship at the Indore General Hospital while doing a nutrition course. \nSelect which of the following is true about internships:",
              "hi": " न्यूट्रीशन/ पोषण कोर्स करते समय शिखर इंदौर जनरल अस्पताल में इंटर्नशिप करता है।\nइंटर्नशिप के बारे में निम्नलिखित में से कौन सा कथन सत्य है चुनें:",
              "ka": "ಶಿಖರ್ ನ್ಯೂಟ್ರಿಷನ್ ಕೋರ್ಸ್ ಮಾಡುವಾಗ ಇಂದೋರ್ ಜನರಲ್ ಆಸ್ಪತ್ರೆಯಲ್ಲಿ ಇಂಟರ್ನ್‌ಶಿಪ್ ಮಾಡುತ್ತಾನೆ. ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳ ಕುರಿತು ಈ ಕೆಳಗಿನವುಗಳಲ್ಲಿ ಯಾವುದು ಸರಿ ಎಂಬುದನ್ನು ಆಯ್ಕೆಮಾಡಿ:",
              "te": "‘‘శిఖర్ న్యూట్రిషన్ కోర్సు చేస్తూనే ఇండోర్ జనరల్ హాస్పిటల్‌లో ఇంటర్న్‌షిప్ చేస్తున్నాడు.\n ఇంటర్న్‌షిప్‌లకు సంబంధించి కింది వాటిలో ఏది సరైనదో ఎంచుకోండి:\"",
              "or": "ନ୍ୟୁଟ୍ରିସନ ପାଠ୍ୟକ୍ରମ କରୁଥିବାବେଳେ ଶିଖର ଇନ୍ଦୋର ଜେନେରାଲ ହସ୍ପିଟାଲ\n ରେ ଇଣ୍ଟର୍ନସିପ୍ କରନ୍ତି। ଇଣ୍ଟର୍ନସିପ୍ ବିଷୟରେ ନିମ୍ନଲିଖିତ ମଧ୍ୟରୁ କେଉଁଟି ସତ୍ୟ ତାହା ବାଛନ୍ତୁ:",
              "as": "শিখৰে পুষ্টি পাঠ্যক্ৰম কৰি থাকোতে ইন্দোৰ জেনেৰেল হস্পিটেলত ইন্টাৰ্ণশ্বিপ কৰে। \n ইণ্টাৰ্নশ্বিপৰ বিষয়ে তলৰ কোনটো সঁচা বাছনি কৰক:",
              "gu": "શિખર ઇન્દોર જનરલ હોસ્પિટલમાં ઇન્ટર્નશિપ કરે છે જ્યારે પોષણનો કોર્સ કરે છે. ઇન્ટર્નશીપ વિશે નીચેનામાંથી કયું સાચું છે તે પસંદ કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Internships are the best method to find a job as you can show your talent on-the-job",
              "hi": "इंटर्नशिप नौकरी खोजने का सबसे अच्छा तरीका है क्योंकि आप नौकरी करते समय अपनी प्रतिभा को दिखा सकते हैं",
              "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳು ಉದ್ಯೋಗವನ್ನು ಹುಡುಕಲು ಉತ್ತಮ ವಿಧಾನವಾಗಿದೆ ಏಕೆಂದರೆ ನೀವು ಕೆಲಸದ ಮೇಲೆ ನಿಮ್ಮ ಪ್ರತಿಭೆಯನ್ನು ತೋರಿಸಬಹುದು",
              "te": "మీరు ఉద్యోగంలో మీ ప్రతిభను చూపగలిగేలా ఇంటర్నషిప్ లు ఉద్యోగాన్ని కనుగొనడానికి ఉత్తమమైన పద్ధతి",
              "or": "ଚାକିରି ଖୋଜିବା ପାଇଁ ଇଣ୍ଟର୍ନସିପ୍ ହେଉଛି ସର୍ବୋତ୍ତମ ପଦ୍ଧତି, ଯେହେତୁ ତୁମେ\n  ତୁମର ପ୍ରତିଭାକୁ ଚାକିରିରେ ଦେଖାଇ ପାରିବ |",
              "as": "চাকৰি এটা বিচাৰি উলিওৱাৰ বাবে ইণ্টাৰ্ণশ্বিপ হৈছে উৎকৃষ্ট উপায় কিয়নো তুমি কামত তোমাৰ প্ৰতিভা দেখুৱাব পাৰা।",
              "gu": "ઇન્ટર્નશીપ એ નોકરી શોધવા માટેની શ્રેષ્ઠ પદ્ધતિ છે કારણ કે તમે નોકરી પર તમારી પ્રતિભા બતાવી શકો છો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Internships are only done as part of a course and not after the course is done",
              "hi": "इंटर्नशिप केवल कोर्स के एक भाग के रूप में की जाती है और कोर्स के बाद नहीं",
              "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳನ್ನು ಕೋರ್ಸ್‌ನ ಭಾಗವಾಗಿ ಮಾತ್ರ ಮಾಡಲಾಗುತ್ತದೆ ಮತ್ತು ಕೋರ್ಸ್ ಮಾಡಿದ ನಂತರ ಅಲ್ಲ",
              "te": "ఇంటర్న్‌షిప్ కోర్సులో భాగంగా మాత్రమే చేయబడతాయి మరియు కోర్సు పూర్తయిన తర్వాత కాదు",
              "or": "ଇଣ୍ଟର୍ନସିପ୍ କେବଳ ଏକ ପାଠ୍ୟକ୍ରମର ଅଂଶ ଭାବରେ କରାଯାଇଥାଏ ଏବଂ ପାଠ୍ୟକ୍ରମ ସରିବା ପରେ ନୁହେଁ |",
              "as": "ইণ্টাৰ্ণশ্বিপ কেৱল এটা পাঠ্যক্ৰমৰ অংশ হিচাপে কৰা হয় আৰু পাঠ্যক্ৰমটো সম্পূৰ্ণ হোৱাৰ পিছত নহয়",
              "gu": "ઇન્ટર્નશીપ માત્ર કોર્સના ભાગ રૂપે કરવામાં આવે છે કોર્સ પૂર્ણ થયા પછી નહીં"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "In an internship, your main responsibility will be to bring coffee for your supervisor",
              "hi": " इंटर्नशिप में, आपकी मुख्य जिम्मेदारी अपने सुपरवाइजर/ पर्यवेक्षक को खुश रखने की होगी",
              "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್‌ನಲ್ಲಿ, ನಿಮ್ಮ ಮೇಲ್ವಿಚಾರಕರಿಗೆ ಕಾಫಿ ತರುವುದು ನಿಮ್ಮ ಮುಖ್ಯ ಜವಾಬ್ದಾರಿಯಾಗಿದೆ",
              "te": "ఇంటర్న్‌షిప్ లో, మీ సూపర్‌వైజర్ కోసం కాఫీ తీసుకురావడం మీ ప్రధాన బాధ్యత",
              "or": "ଏକ ଇଣ୍ଟର୍ନସିପ୍ ରେ, ତୁମର ମୁଖ୍ୟ ଦାୟିତ୍ୱ ତୁମର ସୁପରଭାଇଜର ପାଇଁ କଫି ଆଣିବା |",
              "as": "ইণ্টাৰ্নশ্বিপ এটাত, তোমাৰ মুখ্য দায়িত্ব হ'ব তোমাৰ চুপাৰভাইজাৰৰ বাবে কফি অনা",
              "gu": "ઇન્ટર્નશીપમાં, તમારી મુખ્ય જવાબદારી તમારા સુપરવાઇઝર માટે કોફી લાવવાની રહેશે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "Internships help you try out an industry before you decide to work in the industry",
              "hi": "इंडस्ट्री में काम करने का फैसला करने से पहले इंटर्नशिप आपको उस इंडस्ट्री का अनुभव प्राप्त करने में मदद करती है",
              "ka": "ನೀವು ಉದ್ಯಮದಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ನಿರ್ಧರಿಸುವ ಮೊದಲು ಉದ್ಯಮವನ್ನು ಪ್ರಯತ್ನಿಸಲು ಇಂಟರ್ನ್‌ಶಿಪ್ ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ",
              "te": "మీరు ఇండస్ట్రీలో పని చేయాలని నిర్ణయించుకునే ముందు ఇండస్ట్రీని ప్రయత్నించడంలో ఇంటర్న్‌షిప్ లు మీకు సహాయపడతాయి",
              "or": "ଇଣ୍ଡଷ୍ଟ୍ରିରେ କାମ କରିବାକୁ ନିଷ୍ପତ୍ତି ନେବା ପୂର୍ବରୁ ଇଣ୍ଟର୍ନସିପ୍ ଆପଣଙ୍କୁ ଏକ ଇ\n ଣ୍ଡଷ୍ଟ୍ରିରେ ଚେଷ୍ଟା କରିବାକୁ ସାହାଯ୍ୟ କରେ |",
              "as": "তুমি উদ্যোগটোত কাম কৰাটো ঠিক কৰাৰ আগতে ইণ্টাৰ্ণশ্বিপে তোমাক এটা উদ্যোগ বাছনি কৰাত সহায় কৰে",
              "gu": "ઈન્ટર્નશીપ તમને ઈન્ડસ્ટ્રીમાં કામ કરવાનું નક્કી કરતા પહેલા ઉદ્યોગ અજમાવવામાં મદદ કરે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Think again! To find a job, it is better to apply for a job. Sometimes, some organisations hire interns.",
              "hi": "फिर से विचार करो! नौकरी खोजने के लिए, नौकरी के लिए आवेदन करना ज्यादा सही है। कभी-कभी, कुछ संगठन इन्टर्न को नौकरी पर रखते हैं।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಉದ್ಯೋಗವನ್ನು ಹುಡುಕಲು, ಕೆಲಸಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದು ಉತ್ತಮ. ಕೆಲವೊಮ್ಮೆ, ಕೆಲವು ಸಂಸ್ಥೆಗಳು ಇಂಟರ್ನಿಗಳನ್ನು ನೇಮಿಸಿಕೊಳ್ಳುತ್ತವೆ.",
              "te": "మళ్లీ ఆలోచించండి! ఉద్యోగం కోసం, ఉద్యోగం కోసం దరఖాస్తు చేసుకోవడం మంచిది. కొన్నిసార్లు, కొన్ని సంస్థలు ఇంటర్న్ లను నియమించుకుంటాయి.",
              "or": "ପୁଣି ଥରେ ଭାବ! ଚାକିରି ଖୋଜିବା ପାଇଁ, ଚାକିରି ପାଇଁ ଆବେଦନ କରିବା \n ଭଲ | ବେଳେବେଳେ, କିଛି ସଂସ୍ଥା ଇଣ୍ଟରନ୍ନ ଙ୍କୁ ନିଯୁକ୍ତି କରନ୍ତି |",
              "as": "আকৌ চিন্তা কৰা! চাকৰি এটা বিচাৰিবলৈ, চাকৰি এটাৰ বাবে আবেদন কৰা ভাল। কেতিয়াবা, কিছুমান সংগঠনে ইণ্টাৰ্ণ নিয়োগ কৰে।",
              "gu": "ફરીથી વિચારો! નોકરી શોધવા માટે, નોકરી માટે અરજી કરવી વધુ સારું છે. કેટલીકવાર, કેટલીક સંસ્થાઓ ઇન્ટર્ન ભાડે રાખે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Think again! Internships happen during and after a course is done too.",
              "hi": "फिर से विचार करो! इंटर्नशिप एक कोर्स के दौरान और बाद में  भी होती है।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಕೋರ್ಸ್‌ನ ಸಮಯದಲ್ಲಿ ಮತ್ತು ನಂತರವೂ ಇಂಟರ್ನ್‌ಶಿಪ್‌ಗಳು ನಡೆಯುತ್ತವೆ",
              "te": "మళ్లీ ఆలోచించండి! ఇంటర్న్‌షిప్ లు ఒక కోర్సు సమయంలో మరియు తర్వాత కూడా జరుగుతాయి.",
              "or": "ପୁଣି ଥରେ ଭାବ! ଚାକିରି ଖୋଜିବା ପାଇଁ, ଚାକିରି ପାଇଁ ଆବେଦନ କରିବା ଭ\n ଲ | ବେଳେବେଳେ, କିଛି ସଂସ୍ଥା ଇଣ୍ଟର୍ଣ ନିଯୁକ୍ତି କରନ୍ତି |",
              "as": "আকৌ চিন্তা কৰা! পাঠ্যক্ৰম এটা শেষ হোৱাৰ সময়ত আৰু পিছত ইণ্টাৰ্ণশ্বিপ হয়।",
              "gu": "ફરીથી વિચારો! ઇન્ટર્નશીપ કોર્સ દરમિયાન અને પછી પણ થાય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Think again! Many organisations have better internship programs where your learning will be prioritised.",
              "hi": "फिर से विचार करो! कई संगठनों के पास बेहतर इंटर्नशिप कार्यक्रम होते हैं जहां आपके द्वारा सीखने को प्राथमिकता दी जाएगी।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಅನೇಕ ಸಂಸ್ಥೆಗಳು ಉತ್ತಮ ಇಂಟರ್ನ್‌ಶಿಪ್ ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ಹೊಂದಿವೆ, ಅಲ್ಲಿ ನಿಮ್ಮ ಕಲಿಕೆಗೆ ಆದ್ಯತೆ ನೀಡಲಾಗುತ್ತದೆ.",
              "te": "మరలా ఆలోచించు! అనేక సంస్థలు మీ అభ్యాసానికి ప్రాధాన్యతనిచ్చే మెరుగైన ఇంటర్న్‌షిప్ ప్రోగ్రామ్‌లను కలిగి ఉన్నాయి",
              "or": "ପୁଣି ଥରେ ଭାବ! ଅନେକ ସଂସ୍ଥାର ଉନ୍ନତ ଇଣ୍ଟର୍ନସିପ୍ ପ୍ରୋଗ୍ରାମ ଅଛି ଯେଉଁଠା\n ରେ ଆପଣଙ୍କର ଶିକ୍ଷଣକୁ ପ୍ରାଥମିକତା ଦିଆଯିବ |",
              "as": "আকৌ চিন্তা কৰা! বহুতো সংগঠনৰ উন্নত ইন্টাৰ্ণশ্বিপ কাৰ্যসূচী আছে য'ত তোমাৰ শিকাক প্ৰাথমিকতা দিয়া হ'ব।",
              "gu": "ફરીથી વિચારો! ઘણી સંસ્થાઓ પાસે સારી ઇન્ટર્નશિપ યોજનાઓ હોય છે જ્યાં તમારા શિક્ષણને પ્રાથમિકતા આપવામાં આવશે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಉತ್ತಮ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ବଢିଆ!",
              "as": "ভাল কাম!",
              "gu": "સરસ કામ!"
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_2_1",
              "hi": "D7_2_1",
              "ka": "D7_2_1",
              "te": "D7_2_1",
              "or": "D7_2_1",
              "as": "D7_2_1",
              "gu": "D7_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar attends the next camp. He gets an opportunity to prove his approach.",
              "hi": "शिखर अगले शिविर में भाग लेता है। उसे अपना दृष्टिकोण साबित करने का अवसर मिलता है।",
              "ka": "ಶಿಖರ್ ಮುಂದಿನ ಶಿಬಿರದಲ್ಲಿ ಭಾಗವಹಿಸುತ್ತಾನೆ. ಅವನು ತನ್ನ ವಿಧಾನವನ್ನು ಸಾಬೀತುಪಡಿಸಲು ಅವಕಾಶವನ್ನು ಪಡೆಯುತ್ತಾನೆ",
              "te": "శిఖర్ తదుపరి క్యాంప్ కి హాజరయ్యాడు. అతను తన విధానాన్ని నిరూపించుకునే అవకాశాన్ని పొందుతాడు.",
              "or": "ଶିଖର ପରବର୍ତ୍ତୀ ଶିବିରରେ ଯୋଗ ଦିଅନ୍ତି | ତାଙ୍କର ଆଭିମୁଖ୍ୟ ପ୍ରମାଣ କରିବାକୁ ସେ ଏକ ସୁଯୋଗ ପାଆନ୍ତି |",
              "as": "শিখৰে পিছৰ শিবিৰত ভাগ লয়। সি নিজৰ দৃষ্টিভংগী প্ৰমাণ কৰাৰ সুযোগ পায়।",
              "gu": "શિખર આગામી શિબિરમાં હાજરી આપે છે. તેને પોતાનો અભિગમ સાબિત કરવાની તક મળે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_2_2",
              "hi": "D7_2_2",
              "ka": "D7_2_2",
              "te": "D7_2_2",
              "or": "D7_2_2",
              "as": "D7_2_2",
              "gu": "D7_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He talks to farmers and is able to explain to them the benefits of microloans by sharing his experiences in the field.",
              "hi": "वह किसानों से बात करता है और उन्हें खेत में अपने अनुभव साझा करके माइक्रोलोन्स के लाभों को समझाने में सफल होता है।",
              "ka": "ಆತನು ರೈತರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾನೆ ಮತ್ತು ಜಾಮೀನಿನ ಕೆಲಸದಲ್ಲಿನ ತನ್ನ ಅನುಭವಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುವ ಮೂಲಕ ಮೈಕ್ರೋಲೋನ್‌ಗಳ ಪ್ರಯೋಜನಗಳನ್ನು ಅವರಿಗೆ ವಿವರಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ.",
              "te": "అతను రైతులతో మాట్లాడతాడు మరియు ఫీల్డ్ లో తన అనుభవాలను పంచుకోవడం ద్వారా మైక్రోలోన్ల వల్ల కలిగే ప్రయోజనాలను వారికి వివరించగలడు.",
              "or": "ସେ କୃଷକମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରନ୍ତି ଏବଂ କ୍ଷେତରେ ନିଜର ଅଭିଜ୍ଞତା \n ବାଣ୍ଟି ସେମାନଙ୍କୁ ମାଇକ୍ରୋଲୋନ ର \n  ଉପକାରିତା ବିଷୟରେ ବୁଝାଇବାକୁ ସକ୍ଷମ ହୁଅନ୍ତି |",
              "as": "সি কৃষকসকলৰ সৈতে কথা পাতিছে আৰু ক্ষেত্ৰখনত তাৰ অভিজ্ঞতা ভাগ-বতৰা কৰি তেওঁলোকক মাইক্ৰ'লোনৰ লাভালাভবোৰ বুজাবলৈ সক্ষম হৈছে।",
              "gu": "તે ખેડૂતો સાથે વાત કરે છે અને ખેતરમાં પોતાના અનુભવો કહે છે તે લોકોને માઇક્રો લોનના ફાયદા સમજાવવામાં સક્ષમ છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_2_3",
              "hi": "D7_2_3",
              "ka": "D7_2_3",
              "te": "D7_2_3",
              "or": "D7_2_3",
              "as": "D7_2_3",
              "gu": "D7_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They feel Shikhar is different from other people who usually try to sell their products without caring about the farmers.",
              "hi": "उन्हें लगता है कि शिखर अन्य लोगों से अलग है जो सामान्यतः किसानों की चिंता किए बिना अपने उत्पादों को बेचने की कोशिश करते हैं।",
              "ka": "ಸಾಮಾನ್ಯವಾಗಿ ರೈತರ ಬಗ್ಗೆ ಕಾಳಜಿ ವಹಿಸದೆ ತಮ್ಮ ಉತ್ಪನ್ನಗಳನ್ನು ಮಾರಾಟ ಮಾಡಲು ಪ್ರಯತ್ನಿಸುವ ಇತರ ಜನರಿಗಿಂತ ಶಿಖರ್ ವಿಭಿನ್ನ ಎಂದು ಅವರು ಭಾವಿಸುತ್ತಾರೆ.",
              "te": "సాధారణంగా రైతుల గురించి పట్టించుకోకుండా తమ ఉత్పత్తులను విక్రయించడానికి ప్రయత్నించే ఇతర వ్యక్తుల కంటే శిఖర్ భిన్నంగా ఉన్నాడని వారు భావిస్తున్నారు.",
              "or": "ସେମାନେ ଅନୁଭବ କରନ୍ତି ଯେ ଶିଖର ଅନ୍ୟ ଲୋକଙ୍କଠାରୁ ଭିନ୍ନ, ଯେଉଁମାନେ\n  ସାଧାରଣତ the ଚାଷୀଙ୍କ ବିଷୟରେ ଚିନ୍ତା ନକରି ସେମାନଙ୍କ ଉତ୍ପାଦ ବିକ୍ରୟ\n  କରିବାକୁ ଚେଷ୍ଟା କରନ୍ତି |",
              "as": "তেওঁলোকে ভাবে যে সাধাৰণতে কৃষকসকলৰ যত্ন নোলোৱাকৈ তেওঁলোকৰ সামগ্ৰী বিক্ৰী কৰিবলৈ চেষ্টা কৰা লোকসকলতকৈ শিখৰ বেলেগ।",
              "gu": "તેઓને લાગે છે કે શિખર અન્ય લોકો કરતા અલગ છે જેઓ સામાન્ય રીતે ખેડૂતોની પરવા કર્યા વિના તેમના ઉત્પાદનો વેચવાનો પ્રયાસ કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_2_4",
              "hi": "D7_2_4",
              "ka": "D7_2_4",
              "te": "D7_2_4",
              "or": "D7_2_4",
              "as": "D7_2_4",
              "gu": "D7_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Ten farmers in that camp apply for microloans. His team is very impressed! They see value in Shikhar’s presence. They feel he is a good mix of finance and knowledge of the field.",
              "hi": "उस शिविर में दस किसान माइक्रोलोन्स के लिए आवेदन करते हैं। उनकी टीम बहुत प्रभावित है! वे शिखर के उपस्थित होने का महत्त्व समझते हैं। उन्हें लगता है कि वह वित्त और क्षेत्र ज्ञान का एक अच्छा मिश्रण है।",
              "ka": "ಆ ಶಿಬಿರದಲ್ಲಿ ಇದ್ದ ಹತ್ತು ರೈತರು ಕಿರುಸಾಲಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಾರೆ. ಅವರ ತಂಡವು ತುಂಬಾ ಪ್ರಭಾವಿತವಾಗಿದೆ! ಅವರು ಶಿಖರ್ ಉಪಸ್ಥಿತಿಯಲ್ಲಿ ಪ್ರಯೋಜನವನ್ನು ನೋಡುತ್ತಾರೆ.ಆತನು ಹಣಕಾಸು ಮತ್ತು ಜಮೀನಿನ ಜ್ಞಾನದ ಉತ್ತಮ ಮಿಶ್ರಣ ಎಂದು ಅವರು ಭಾವಿಸುತ್ತಾರೆ.",
              "te": "ఆ శిబిరంలో పది మంది రైతులు సూక్ష్మ రుణాల కోసం దరఖాస్తు చేసుకున్నారు. అతని బృందం చాలా ఆకట్టుకుంది! శిఖర్ సమక్షంలో వారికి విలువ కనిపిస్తుంది.అతను ఆర్థిక మరియు ఫీల్డ్ యొక్క జ్ఞానం మంచి కలయిక అని వారు భావిస్తున్నారు",
              "or": "ସେହି ଶିବିରର ଦଶଜଣ କୃଷକ ମାଇକ୍ରୋଲୋନ୍ ପାଇଁ ଆବେଦନ କରନ୍ତି | \n ତାଙ୍କ ଦଳ ବହୁତ ପ୍ରଭାବିତ! ସେମାନେ ଶିଖରଙ୍କ ଉପସ୍ଥିତିରେ ମୂଲ୍ୟ ଦେଖନ୍ତି \n | ସେମାନେ ଅନୁଭବ କରନ୍ତି ଯେ ସେ ଅର୍ଥ ଏବଂ କ୍ଷେତ୍ରର ଜ୍ଞାନର ଏକ ଭଲ ମିଶ୍ରଣ |",
              "as": "সেই শিবিৰৰ দহজন কৃষকে মাইক্ৰ'লোনৰ বাবে আবেদন কৰে। তেওঁৰ দলটো বহুত মুগ্ধ হৈছে! তেওঁলোকে শিখৰৰ উপস্থিতিত মূল্য দেখিছে। তেওঁলোকে ভাবে যে সি ক্ষেত্ৰখনৰ বিত্ত আৰু জ্ঞানৰ এক ভাল মিশ্ৰণ।",
              "gu": "તે શિબિરમાં દસ ખેડૂતો માઇક્રોલોન્સ માટે અરજી કરે છે. તેની ટીમ ખૂબ જ પ્રભાવિત છે! તેઓ શિખરની હાજરીમાં મૂલ્ય જુએ છે. તેઓને લાગે છે કે તે નાણા અને આ ક્ષેત્રનું સારું એવું ભેગું જ્ઞાનનું છે."
            }
          }
        ]
      },
      "scene35": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8_1_1",
              "hi": "D8_1_1",
              "ka": "D8_1_1",
              "te": "D8_1_1",
              "or": "D8_1_1",
              "as": "D8_1_1",
              "gu": "D8_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Everyone from the team appreciates Shikhar’s work in creating awareness about the microloans. As his internship comes to an end, he gets offered a full-time job at the bank in the business development team.",
              "hi": " टीम का प्रत्येक सदस्य माइक्रोलोन्स के बारे में जागरूकता पैदा करने में शिखर के काम की सराहना करता है। चूंकि उनकी इंटर्नशिप खत्म हो जाती है, इसलिए उसे बैंक में बिजनेस डेवलपमेंट टीम में पूर्णकालिक नौकरी करने का प्रस्ताव दिया जाता है।",
              "ka": "ಕಿರುಸಾಲಗಳ ಬಗ್ಗೆ ಅರಿವು ಮೂಡಿಸುವಲ್ಲಿ ಶಿಖರ್ ಮಾಡಿದ ಕಾರ್ಯವನ್ನು ತಂಡದ ಪ್ರತಿಯೊಬ್ಬರೂ ಶ್ಲಾಘಿಸುತ್ತಾರೆ. ಅವನ ಇಂಟರ್ನ್‌ಶಿಪ್ ಕೊನೆಗೊಳ್ಳುತ್ತಿದ್ದಂತೆ, ಅವನಿಗೆ ವ್ಯಾಪಾರ ಅಭಿವೃದ್ಧಿ ತಂಡದಲ್ಲಿ ಬ್ಯಾಂಕ್‌ನಲ್ಲಿ ಪೂರ್ಣ ಸಮಯದ ಕೆಲಸವನ್ನು ನೀಡಲಾಗುತ್ತದೆ.",
              "te": "మైక్రోలోన్ల గురించి అవగాహన కల్పించడంలో శిఖర్ చేసిన పనిని బృందంలోని ప్రతి ఒక్కరూ అభినందిస్తున్నారు. అతని ఇంటర్న్‌షిప్ ముగియడంతో, అతనికి బిజినెస్ డెవలప్‌మెంట్ టీమ్ లో బ్యాంక్ లో పూర్తి సమయం ఉద్యోగం ఇవ్వబడుతుంది.",
              "or": "ମାଇକ୍ରୋଲୋନ୍ ବିଷୟରେ ସଚେତନତା ସୃଷ୍ଟି କରିବାରେ ଶିଖରଙ୍କ କାର୍ଯ୍ୟକୁ \n ଦଳର ସମସ୍ତେ ପ୍ରଶଂସା କରନ୍ତି | ତାଙ୍କର ଇଣ୍ଟର୍ନସିପ୍ ଶେଷ ହେବାପରେ ସେ \n ବ୍ୟବସାୟ ବିକାଶ ଦଳରେ ବ୍ୟାଙ୍କରେ ଏକ ପୂର୍ଣ୍ଣକାଳୀନ ଚାକିରି ପାଇଥାନ୍ତି |",
              "as": "দলটোৰ সকলোৱে মাইক্ৰ'লোনৰ বিষয়ে সজাগতা সৃষ্টিত শিখৰৰ কামক প্ৰশংসা কৰে। তাৰ ইণ্টাৰ্ণশ্বিপ শেষ হোৱাৰ লগে লগে, সি ব্যৱসায় বিকাশ দলত বেঙ্কত পূৰ্ণ-সময়ৰ চাকৰিৰ প্ৰস্তাৱ পায়।",
              "gu": "ટીમમાંથી દરેક વ્યક્તિ માઇક્રોલોન્સ વિશે જાગૃતિ લાવવા શિખરના કાર્યની પ્રશંસા કરે છે. જેમ જેમ તેની ઇન્ટર્નશિપ સમાપ્ત થાય છે, તેમ તેમ તેને બિઝનેસ ડેવલપમેન્ટ ટીમમાં બેંકમાં પૂરા-સમયની નોકરીની તક મળે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8_1_2",
              "hi": "D8_1_2",
              "ka": "D8_1_2",
              "te": "D8_1_2",
              "or": "D8_1_2",
              "as": "D8_1_2",
              "gu": "D8_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "It is a job that offers a mix of financial and field knowledge. His job is to bring awareness about microfinance and how to avail the loans.",
              "hi": "यह एक ऐसा काम है जो वित्त और क्षेत्र के ज्ञान का मिश्रण प्रदान करता है। उसका काम माइक्रोफाइनेंस में जागरूकता लाना और ऋण का लाभ किस प्रकार लिया जा सकता है, इस विषय में बताना है।",
              "ka": "ಇದು ಆರ್ಥಿಕ ಮತ್ತು ಕ್ಷೇತ್ರ ಜ್ಞಾನದ ಮಿಶ್ರಣವನ್ನು ನೀಡುವ ಕೆಲಸವಾಗಿದೆ. ಕಿರುಬಂಡವಾಳ ಮತ್ತು ಸಾಲವನ್ನು ಹೇಗೆ ಪಡೆಯುವುದು ಎಂಬುದರ ಕುರಿತು ಜಾಗೃತಿ ಮೂಡಿಸುವುದು ಅವನ ಕೆಲಸ.",
              "te": "ఇది ఆర్థిక మరియు క్షేత్ర పరిజ్ఞానాన్ని మిళితం చేసే ఉద్యోగం. మైక్రోఫైనాన్స్‌పై అవగాహన కల్పించడం మరియు రుణాలను ఎలా పొందాలనేది అతని పని.",
              "or": "ଏହା ଏକ କାମ ଯାହା ଆର୍ଥିକ ଏବଂ କ୍ଷେତ୍ର ଜ୍ଞାନର ମିଶ୍ରଣ ପ୍ରଦାନ କରେ |\n  ତାଙ୍କର କାର୍ଯ୍ୟ ହେଉଛି ମାଇକ୍ରୋ ଫାଇନାନ୍ସ ଏବଂ ରୁଣ କିପରି ଲାଭ\n  ହେବ ସେ ସମ୍ବନ୍ଧରେ ସଚେତନତା ଆଣିବା |",
              "as": "এইটো এটা বিত্তীয় আৰু ক্ষেত্ৰ জ্ঞানৰ মিশ্ৰণ প্ৰদান কৰা কাম। তাৰ কাম হৈছে মাইক্ৰ'ফাইনেন্স আৰু ঋণ কেনেদৰে পাব লাগে সেই বিষয়ে সজাগতা অনা।",
              "gu": "તે એક એવી નોકરી છે જે નાણાકીય અને ક્ષેત્રીય જ્ઞાનનું મિશ્રણ પ્રદાન કરે છે. તેનું કામ માઇક્રોફાઇનાન્સ અને લોન કેવી રીતે મેળવવી તે અંગે જાગૃતિ લાવવાનું છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8_1_3",
              "hi": "D8_1_3",
              "ka": "D8_1_3",
              "te": "D8_1_3",
              "or": "D8_1_3",
              "as": "D8_1_3",
              "gu": "D8_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He enjoys the job as he gets to understand the different business aspects of microfinance.",
              "hi": "वह नौकरी का आनंद लेता है क्योंकि इसमें उसे माइक्रोफाइनेंस के विभिन्न व्यावसायिक पहलुओं को समझने का अवसर मिलता है।",
              "ka": "ಮೈಕ್ರೋಫೈನಾನ್ಸ್‌ನ ವಿವಿಧ ವ್ಯವಹಾರ ಅಂಶಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಅವನು ಕೆಲಸವನ್ನು ಆನಂದಿಸುತ್ತಾನೆ.",
              "te": "అతను మైక్రోఫైనాన్స్ యొక్క విభిన్న వ్యాపార అంశాలను అర్థం చేసుకున్నందున అతను ఉద్యోగాన్ని ఆనందిస్తాడు.",
              "or": "ମାଇକ୍ରୋ ଫାଇନାନ୍ସର ବିଭିନ୍ନ ବ୍ୟବସାୟିକ ଦିଗ ବୁଝିବା \n ସହିତ ସେ ଏହି କାର୍ଯ୍ୟକୁ ଉପଭୋଗ କରନ୍ତି |",
              "as": "সি কামটো উপভোগ কৰে কিয়নো সি মাইক্ৰ'ফাইনেন্সৰ বিভিন্ন ব্যৱসায়িক দিশবোৰ বুজি পায়।",
              "gu": "માઈક્રોફાઈનાન્સના વિવિધ વ્યાપારીના વિચારોને સમજવામાં તે નોકરીનો આનંદ માણે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8_1_4",
              "hi": "D8_1_4",
              "ka": "D8_1_4",
              "te": "D8_1_4",
              "or": "D8_1_4",
              "as": "D8_1_4",
              "gu": "D8_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "It feels like an ideal job for him, as he likes knowing a bit about every department rather than specialising in one aspect of finance or business.",
              "hi": "यह उसके लिए एक आदर्श नौकरी की तरह लगता है, क्योंकि वह वित्त या व्यापार के  किसी एक पहलू में विशेषज्ञता के बजाय हर विभाग के बारे में थोडा बहुत जानना पसंद करता है।",
              "ka": "ಹಣಕಾಸು ಅಥವಾ ವ್ಯವಹಾರದ ಒಂದು ಅಂಶದಲ್ಲಿ ಪರಿಣತಿ ಹೊಂದುವುದಕ್ಕಿಂತ ಹೆಚ್ಚಾಗಿ ಪ್ರತಿಯೊಂದು ವಿಭಾಗದ ಬಗ್ಗೆ ಸ್ವಲ್ಪ ತಿಳಿದುಕೊಳ್ಳಲು ಅವನು ಇಷ್ಟಪಡುವ ಕಾರಣ ಇದು ಅವನಿಗೆ ಆದರ್ಶ ಉದ್ಯೋಗದಂತೆ ಭಾಸವಾಗುತ್ತದೆ.",
              "te": "ఫైనాన్స్ లేదా వ్యాపారానికి సంబంధించిన ఒక అంశంలో నైపుణ్యం పొందడం కంటే ప్రతి విభాగం గురించి కొంచెం తెలుసుకోవడం అతనికి ఇష్టం కాబట్టి ఇది అతనికి కావలసిన ఉద్యోగంలా అనిపిస్తుంది.",
              "or": "ଏହା ତାଙ୍କ ପାଇଁ ଏକ ଆଦର୍ଶ କାର୍ଯ୍ୟ ପରି ଅନୁଭବ କରେ, ଯେହେତୁ ସେ ଅର୍ଥ\n  କିମ୍ବା ବ୍ୟବସାୟର ଗୋଟିଏ ଦିଗରେ ବିଶେଷଜ୍ଞ ହେବା ପରିବର୍ତ୍ତେ ପ୍ରତ୍ୟେକ ବିଭାଗ\n  ବିଷୟରେ ଟିକେ ଜାଣିବାକୁ ପସନ୍ଦ କରନ୍ତି |",
              "as": "এইটো তাৰ বাবে এক আদৰ্শ কাম যেন লাগে, কিয়নো সি বিত্ত বা ব্যৱসায়ৰ এটা দিশত বিশেষজ্ঞ হোৱাৰ সলনি প্ৰতিটো বিভাগৰ বিষয়ে অলপ জানিবলৈ ভাল পায়।",
              "gu": "તે તેના માટે એક આદર્શ નોકરી જેવું લાગે છે, કારણ કે તે ફાઇનાન્સ અથવા વ્યવસાયના એક વિચારોમાં વિશેષતા મેળવવાને બદલે દરેક વિભાગ વિશે થોડું જાણવાનું પસંદ કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8_2_1",
              "hi": "D8_2_1",
              "ka": "D8_2_1",
              "te": "D8_2_1",
              "or": "D8_2_1",
              "as": "D8_2_1",
              "gu": "D8_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Soon Shikhar becomes the best at Microfinance sales and business development in the bank. He gets two trainees under him and now manages the team.",
              "hi": "जल्द ही शिखर बैंक में माइक्रोफाइनेंस बिक्री और व्यापार विकास में सबसे अच्छा बन गया। उसे अपने अधीन दो ट्रेनी/ प्रशिक्षु मिलते हैं और अब वह टीम का प्रबंधन करता है।",
              "ka": "ಶೀಘ್ರದಲ್ಲೇ ಶಿಖರ್ ಬ್ಯಾಂಕಿನಲ್ಲಿ ಮೈಕ್ರೋಫೈನಾನ್ಸ್ ಮಾರಾಟ ಮತ್ತು ವ್ಯಾಪಾರ ಅಭಿವೃದ್ಧಿಯಲ್ಲಿ ಅತ್ಯುತ್ತಮ ಹೆಸರು ಗಳಿಸುತ್ತಾನೆ. ಅವನು ತನ್ನ ಅಡಿಯಲ್ಲಿ ಇಬ್ಬರು ಪ್ರಶಿಕ್ಷಣಾರ್ಥಿಗಳನ್ನು ಪಡೆಯುತ್ತಾನೆ ಮತ್ತು ಈಗ ತಂಡವನ್ನು ನಿರ್ವಹಿಸುತ್ತಾನೆ.",
              "te": "త్వరలో శిఖర్ బ్యాంక్‌లో మైక్రోఫైనాన్స్ విక్రయాలు మరియు వ్యాపార అభివృద్ధిలో అత్యుత్తమంగా మారాడు. అతను తన కింద ఇద్దరు ట్రైనీలను పొందాడు మరియు ఇప్పుడు జట్టును మేనేజ్ చేస్తున్నాడు.",
              "or": "ଶୀଘ୍ର ମାଇକ୍ରୋଫାଇନାନ୍ସ ବିକ୍ରୟ ଏବଂ ବ୍ୟାଙ୍କର ବ୍ୟବସାୟ ବିକାଶରେ ଶିଖର\n  ସର୍ବୋତ୍ତମ ହୋଇଯାଏ | ସେ ତାଙ୍କ ଅଧୀନରେ ଦୁଇଜଣ ପ୍ରଶିକ୍ଷକ ପାଆନ୍ତି ଏବଂ ବର୍ତ୍ତମାନ ଦଳ ପରିଚାଳନା କରନ୍ତି |",
              "as": "সোনকালেই শিখৰ বেংকৰ মাইক্ৰ'ফাইনেন্স বিক্ৰী আৰু ব্যৱসায় বিকাশত সৰ্বশ্ৰেষ্ঠ হৈ পৰে। সি তাৰ অধীনত দুজন প্ৰশিক্ষাৰ্থী পায় আৰু এতিয়া দলটো পৰিচালনা কৰে।",
              "gu": "ટૂંક સમયમાં શિખર બેંકમાં માઇક્રોફાઇનાન્સ વેચાણ અને વ્યવસાય વિકાસમાં શ્રેષ્ઠ બની જશે. તેને તેની નીચે બે તાલીમાર્થીઓ મળે છે અને હવે તે ટીમનું સંચાલન કરે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8_2_2",
              "hi": "D8_2_2",
              "ka": "D8_2_2",
              "te": "D8_2_2",
              "or": "D8_2_2",
              "as": "D8_2_2",
              "gu": "D8_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar’s interest in doing good for the people around him helps him support his family and work in a job he likes.",
              "hi": "अपने आस-पास के लोगों के लिए अच्छा करने में शिखर की रुचि, परिवार का सहयोग करने और अपनी पसंद वाला काम करने में उसकी सहायता करती है।",
              "ka": "ತನ್ನ ಸುತ್ತಮುತ್ತಲಿನ ಜನರಿಗೆ ಒಳ್ಳೆಯದನ್ನು ಮಾಡುವಲ್ಲಿ ಶಿಖರ್‌ನ ಆಸಕ್ತಿಯು ಅವನ ಕುಟುಂಬವನ್ನು ಬೆಂಬಲಿಸಲು ಮತ್ತು ಅವನು ಇಷ್ಟಪಡುವ ಕೆಲಸದಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "తన చుట్టూ ఉన్న వ్యక్తులకు మంచి చేయాలనే శిఖర్‌కు ఉన్న ఆసక్తి అతని కుటుంబాన్ని పోషించడానికి మరియు అతను ఇష్టపడే ఉద్యోగంలో పని చేయడానికి సహాయపడుతుంది.",
              "or": "ତାଙ୍କ ଆଖପାଖର ଲୋକଙ୍କ ପାଇଁ ଭଲ କାମ କରିବାକୁ ଶିଖରଙ୍କ ଆଗ୍ରହ ତାଙ୍କୁ\n  ତାଙ୍କ ପରିବାରକୁ ସାହାଯ୍ୟ କରେ ଏବଂ ସେ ପସନ୍ଦ କରୁଥିବା ଚାକିରିରେ କାମ କରେ |",
              "as": "তাৰ চাৰিওফালৰ লোকসকলৰ বাবে ভাল কৰিবলৈ শিখৰৰ আগ্ৰহে তাক তাৰ পৰিয়ালক সমৰ্থন কৰাত আৰু সি পছন্দ কৰা চাকৰি এটাত কাম কৰাত সহায় কৰে।",
              "gu": "શિખરની તેની આસપાસના લોકો માટે સારું કરવામાં રસ તેને તેના પરિવારને ટેકો આપવામાં અને તેને ગમતી નોકરીમાં કામ કરવામાં મદદ કરે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "Microfinance",
              "hi": "माइक्रोफाइनांस",
              "ka": "ಕಿರುಬಂಡವಾಳ",
              "te": "మైక్రోఫైనాన్స్",
              "or": "ମାଇକ୍ରୋ ଫାଇନାନ୍ସ |",
              "as": "মাইক্ৰ'ফাইনেন্স",
              "gu": "માઇક્રોફાઇનાન્સ"
            }
          }
        ]
      },
      "scene36": {
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "Wait for other opportunities",
              "hi": "अन्य अवसरों की प्रतीक्षा करो ",
              "ka": "ಇತರ ಅವಕಾಶಗಳಿಗಾಗಿ ನಿರೀಕ್ಷಿಸುವುದು",
              "te": "ఇతర అవకాశాల కోసం వేచి ఉండడం",
              "or": "ଅନ୍ୟ ସୁଯୋଗ ପାଇଁ ଅପେକ୍ଷା କର |",
              "as": "অন্যান্য সুযোগৰ বাবে ৰোৱা",
              "gu": "અન્ય તકોની રાહ જુઓ"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar decides to wait for other opportunities to show up. He goes back home.",
              "hi": "शिखर अन्य अवसरों में अपनी योग्यता को दिखाने की प्रतीक्षा करने का निर्णय करता है। वह घर वापस जाता है।",
              "ka": "ಶಿಖರ್ ಇತರ ಅವಕಾಶಗಳಿಗಾಗಿ ಕಾಯಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಅವನು ಮನೆಗೆ ಹಿಂತಿರುಗುತ್ತಾನೆ",
              "te": "శిఖర్ ఇతర అవకాశాల కోసం వేచి ఉండాలని నిర్ణయించుకున్నాడు. అతను ఇంటికి తిరిగి వెళ్తాడు.",
              "or": "ଶିଖର ଅନ୍ୟ ସୁଯୋଗକୁ ଅପେକ୍ଷା କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ସେ ଘରକୁ \n ଫେରିଯାଏ |",
              "as": "শিখৰে আন সুযোগবোৰ চাবলৈ ৰোৱাৰ সিদ্ধান্ত লৈছে। সি ঘৰলৈ ঘূৰি যায়।",
              "gu": "શિખર અન્ય તકોની રાહ જોવાનું નક્કી કરે છે તેની આવડત દેખાડવા માટે. તે ઘરે પાછો જાય છે."
            }
          }
        ],
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D9_2_1",
              "hi": "D9_2_1",
              "ka": "D9_2_1",
              "te": "D9_2_1",
              "or": "D9_2_1",
              "as": "D9_2_1",
              "gu": "D9_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The next day Shikhar goes to meet Devansh. They’re meeting after three years and are happy to catch up. Devansh has been studying B.Sc. in the city.",
              "hi": "अगले दिन शिखर देवांश से मिलने के लिए जाता है। वे तीन साल बाद मिल रहे हैं और एक दूसरे से मिलकर प्रसन्न हैं। देवांश शहर में बीएससी का अध्ययन कर रहां है।",
              "ka": "ಮರುದಿನ ಶಿಖರ್ ದೇವಾಂಶ್ ನನ್ನು ಭೇಟಿಯಾಗಲು ಹೋಗುತ್ತಾನೆ. ಅವರು ಮೂರು ವರ್ಷಗಳ ನಂತರ ಭೇಟಿಯಾಗುತ್ತಿದ್ದಾರೆ ಮತ್ತು ಮತ್ತೆ ಭೇಟಿಯಾಗಲು ಸಂತೋಷಪಡುತ್ತಾರೆ. ದೇವಾಂಶ್ ಬಿ.ಎಸ್ಸಿ ಓದುತ್ತಿದ್ದಾನೆ. ನಗರದಲ್ಲಿ.",
              "te": "మరుసటి రోజు శిఖర్ దేవాన్ష్ ని కలవడానికి వెళ్తాడు. వారు మూడు సంవత్సరాల తర్వాత కలుసుకున్నారు మరియు కలుసుకోవడం సంతోషంగా ఉంది. దేవాన్ష్ నగరంలో B.Sc చదువుతున్నాడు.",
              "or": "ପରଦିନ ଶିଖର ଦେବନଙ୍କୁ ଭେଟିବାକୁ ଯାଆନ୍ତି | ସେମାନେ ତିନି ବର୍ଷ ପରେ \n ସାକ୍ଷାତ କରୁଛନ୍ତି ଏବଂଦେଖାହୋଇ ଖୁସି ଅଛନ୍ତି | ଦେବାଂଶ ସହରରେ B. Sc ପଢନ୍ତି।",
              "as": "পিছদিনা শিখৰে দেৱাংশক লগ কৰিবলৈ যায়। তেওঁলোকে তিনি বছৰৰ পিছত লগ পাইছে আৰু দেখা কৰিবলৈ পাই সুখী। দেৱাংশে চহৰখনত B.Sc পঢ়ি আছে।",
              "gu": "બીજા દિવસે શિખર દેવાંશને મળવા જાય છે. તેઓ ત્રણ વર્ષ પછી મળી રહ્યા છે અને મળવા માટે ખુશ છે. દેવાંશ B.Sc નો અભ્યાસ કરે છે શહેરમાં."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D9_2_2///",
              "hi": "D9_2_2 ///",
              "ka": "D9_2_2///",
              "te": "D9_2_2///",
              "or": "D9_2_2///",
              "as": "D9_2_2///",
              "gu": "D9_2_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "So what if you did not get a job! You’re so intelligent, Shikhar. You’ll soon get it. Tell me, how did you like the course?",
              "hi": "तो क्या हुआ, अगर तुमको नौकरी नहीं मिली! तुम बहुत बुद्धिमान हो, शिखर। तुमको जल्द ही नौकरी मिल जाएगी। मुझे बताओ, तुमको कोर्स कैसा लगा?",
              "ka": "ಹಾಗಾದರೆ ನಿನಗೆ ಕೆಲಸ ಸಿಗದಿದ್ದರೆ ಏನು! ನೀನು ತುಂಬಾ ಬುದ್ಧಿವಂತೆ, ಶಿಖರ್. ನೀನು ಶೀಘ್ರದಲ್ಲೇ ಅದನ್ನು ಪಡೆಯುತ್ತೀಯಾ. ಹೇಳು, ನೀನು ಕೋರ್ಸ್ ಅನ್ನು ಯಾವ ರೀತಿ ಇಷ್ಟಪಟ್ಟಿದ್ದೀಯಾ?",
              "te": "నీకు ఉద్యోగం రాకపోతే ఎలా! నువ్వు చాలా తెలివైనవాడివి, శిఖర్. నువ్వు త్వరలో దాన్ని పొందుతావు. నాకు చెప్పు, నీకు కోర్సు ఎలా అనిపిస్తుంది?",
              "or": "ତେବେ କଣ ହେଲା ତୁମେ ଯଦି ଚାକିରି ପାଇ ନାହଁ! ତୁମେ ଏତେ ବୁଦ୍ଧିମାନ, ଶିଖର | ତୁମେ\n  ଶୀଘ୍ର ଏହାକୁ ପାଇବ | ମୋତେ କୁହ, ତୁମେ ପାଠ୍ୟକ୍ରମକୁ କିପରି ପସନ୍ଦ କଲ?",
              "as": "তেন্তে যদি তুমি চাকৰি নোপোৱা তেন্তে কি হ'ব! তুমি ইমান বুদ্ধিমান, শিখৰ। তুমি সোনকালেই এইটো পাবা। মোক কোৱা, তুমি পাঠ্যক্ৰমটো কেনেদৰে ভাল পাইছিলা?",
              "gu": "તો નોકરી ન મળી તો શું! તું બહુ બુદ્ધિશાળી છે, શિખર. તને જલ્દી જ મળી જશે. મને બતાવ, તને કોર્સ કેવો લાગ્યો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Oh, I have thoroughly enjoyed it. I learnt how important it is to become aware of our finances and utilise them. There is a real need to make money management accessible to all, especially to people in villages.",
              "hi": "ओह, मैंने इसका पूरी तरह से आनंद लिया है। मैंने सीखा कि हमारा वित्त के बारे में जागरूक होना और उसका सही उपयोग करना कितना महत्वपूर्ण है। विशेष रूप से गांवों के लोगों के लिए धन प्रबंधन को सुलभ बनाने की वास्तविक आवश्यकता है।",
              "ka": "ಓಹ್, ನಾನು ಅದನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಆನಂದಿಸಿದ್ದೇನೆ. ನಮ್ಮ ಹಣಕಾಸಿನ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳುವುದು ಮತ್ತು ಅವುಗಳನ್ನು ಬಳಸಿಕೊಳ್ಳುವುದು ಎಷ್ಟು ಮುಖ್ಯ ಎಂದು ನಾನು ಕಲಿತಿದ್ದೇನೆ. ಹಣದ ನಿರ್ವಹಣೆಯನ್ನು ಎಲ್ಲರಿಗೂ, ವಿಶೇಷವಾಗಿ ಹಳ್ಳಿಗಳ ಜನರಿಗೆ ಸುಲಭವಾಗಿಸುವ ನಿಜವಾದ ಅವಶ್ಯಕತೆಯಿದೆ.",
              "te": "ఓహ్, నాకు బాగా నచ్చింది. మన ఆర్థిక విషయాల గురించి తెలుసుకోవడం మరియు వాటిని ఉపయోగించడం ఎంత ముఖ్యమో నేను తెలుసుకున్నాను. డబ్బు నిర్వహణ అందరికీ అందుబాటులోకి తీసుకురావాల్సిన అవసరం ఉంది, ముఖ్యంగా గ్రామాల్లోని ప్రజలకు.",
              "or": "ଓହ, ମୁଁ ଏହାକୁ ପୁରା ଉପଭୋଗ କରିଛି | ଆମର ଆର୍ଥିକ ବିଷୟରେ ସଚେତନ\n  ହେବା ଏବଂ ସେଗୁଡିକର ଉପଯୋଗ କରିବା କେତେ ମହତ୍ଵ ପୂର୍ଣ୍ଣ ତାହା ମୁଁ ଶିଖିଲି |\n  ସମସ୍ତଙ୍କ ପାଇଁ ଅର୍ଥ ପରିଚାଳନାକୁ ବିଶେଷ କରି ଗାଁର ଲୋକଙ୍କ ପାଇଁ ଉପଲବ୍ଧ\n  କରାଇବା ପାଇଁ ପ୍ରକୃତ ଆବଶ୍ୟକତା ଅଛି |",
              "as": "ওহ, মই ইয়াক ভালদৰে উপভোগ কৰিছোঁ। মই শিকিছিলো যে আমাৰ বিত্তৰ বিষয়ে সজাগ হোৱা আৰু সেইবোৰ ব্যৱহাৰ কৰাটো কিমান গুৰুত্বপূৰ্ণ। টকা-পইচাৰ মেনেজমেন্ট সকলোৰে বাবে, বিশেষকৈ গাওঁৰ লোকসকলৰ বাবে সুলভ কৰাৰ প্ৰকৃত প্ৰয়োজন আছে।",
              "gu": "ઓહ, મેં તેનો સંપૂર્ણ આનંદ લીધો છે. મેં શીખ્યા કે આપણી નાણાકીય બાબતો વિશે જાગૃત થવું અને તેનો ઉપયોગ કરવો કેટલું મહત્વપૂર્ણ છે. પૈસાનું સંચાલન બધા માટે સુલભ બનાવવાની ખરેખર જરૂર છે, ખાસ કરીને ગામડાઓમાં લોકો માટે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "That is a great point, Shikhar. My cousin is working at a company making an app that helps people with their financial planning. I can ask her if they have an opening available.",
              "hi": "यह बहुत अच्छी बात है, शिखर। मेरी चचेरी बहन एक कंपनी में ऐप बनाने का काम करती है जो लोगों को उनकी वित्तीय योजना में सहायता करता  है। मैं उससे पूछ सकता हूं कि क्या उसके पास नौकरी के कोई अवसर उपलब्ध हैं।",
              "ka": "ಅದೊಂದು ದೊಡ್ಡ ವಿಚಾರ ಶಿಖರ್. ನನ್ನ ಸೋದರಸಂಬಂಧಿ ಕಂಪನಿಯೊಂದರಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾನೆ, ಅದು ಜನರಿಗೆ ಅವರ ಹಣಕಾಸಿನ ಯೋಜನೆಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಅವರಿಗೆ ತೆರೆಯುವಿಕೆ ಲಭ್ಯವಿದೆಯೇ ಎಂದು ನಾನು ಅವಳನ್ನು ಕೇಳಬಹುದು.",
              "te": "అది గొప్ప పాయింట్ శిఖర్. నా బంధువు వ్యక్తులకు ఆర్థిక ఫైనాన్షియల్ ప్లానింగ్ కి సహాయపడే యాప్‌ ను తయారు చేస్తున్న కంపెనీలో పని చేస్తున్నారు. వారికి ఓపెనింగ్ ఏమైనా ఉందా అని నేను ఆమెను అడగగలను.",
              "or": "ଶିଖର ଏହା ଏକ ଉତ୍ତମ ବିଷୟ | ମୋର ଭଉଣୀ ଏକ କମ୍ପାନୀରେ ଏକ ଆପ ତିଆରି କରୁଛନ୍ତି \n ଯାହା ଲୋକମାନଙ୍କୁ ସେମାନଙ୍କର ଆର୍ଥିକ ଯୋଜନାରେ ସାହାଯ୍ୟ କରିଥାଏ | \n ମୁଁ ତାଙ୍କୁ ପଚାରି ପାରିବି କି ସେମାନଙ୍କର ଏକ ପଦବୀ ଉପଲବ୍ଧ ଅଛି କି?",
              "as": "শিখৰ, এইটো এটা ডাঙৰ কথা। মোৰ সম্পৰ্কীয় বা এজনীয়ে এটা কোম্পানীত এটা এপ বনাই আছে যিয়ে মানুহক তেওঁলোকৰ বিত্তীয় পৰিকল্পনাত সহায় কৰে। মই তাইক সুধিব পাৰো যে তেওঁলোকৰ এটা আৰম্ভণি আছে নেকি।",
              "gu": "તે એક મહાન મુદ્દો છે, શિખર. મારા પિતરાઈ ભાઈ એક એપ બનાવતી કંપનીમાં કામ કરે છે જે લોકોને તેમના નાણાકીય આયોજનમાં મદદ કરે છે. હું તેને પૂછી શકું છું કે શું તેમની પાસે તે ઉપલબ્ધ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Yes, that would be great!",
              "hi": "हाँ यह बहुत अच्छा होगा!",
              "ka": "ಹೌದು, ಅದು ಉತ್ತಮವಾದುದು!",
              "te": "అవును అది చాలా ఉపయోగపడుతుంది!",
              "or": "ହଁ, ଏହା ବହୁତ ଭଲ ହେବ!",
              "as": "হয়, সেয়া ভাল হ'ব!",
              "gu": "હા, તે ખૂબ સરસ હશે!"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D9_3_2 bg",
              "hi": "D9_3_2 बीजी",
              "ka": "D9_3_2 bg",
              "te": "D9_3_2 bg",
              "or": "D9_3_2 bg",
              "as": "D9_3_2 bg",
              "gu": "D9_3_2 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He talks to Devansh’s cousin, Gauri, on the phone to know about the company and find out if there is an opening for him.",
              "hi": "वह कंपनी के बारे में जानने के लिए फोन पर देवांश की चचेरी बहन, गौरी से बात करता है और पता लगाता है कि उसके लिए कोई अवसर उपलब्ध है या नहीं।",
              "ka": "ಕಂಪನಿಯ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಲು ಮತ್ತು ಅವನಿಗಾಗಿ ತೆರೆಯುವಿಕೆ ಇದೆಯೇ ಎಂದು ಕಂಡುಹಿಡಿಯಲು ಅವನು ದೇವಾಂಶ್‌ನ ಸೋದರಸಂಬಂಧಿ ಗೌರಿಯೊಂದಿಗೆ ಫೋನ್‌ನಲ್ಲಿ ಮಾತನಾಡುತ್ತಾನೆ.",
              "te": "అతను దేవాన్ష్ బంధువు గౌరీతో ఫోన్‌లో మాట్లాడి కంపెనీ గురించి తెలుసుకుని, అతనికి ఓపెనింగ్ ఉందో లేదో తెలుసుకుంటాడు.",
              "or": "ସେ କମ୍ପାନୀ ବିଷୟରେ ଜାଣିବା ପାଇଁ ଏବଂ ତାଙ୍କ ପାଇଁ ପଦବୀ ଖାଲି ଅଛି କି\n  ନାହିଁ ଜାଣିବା ପାଇଁ ସେ ଦେବାଂଶଙ୍କ ସମ୍ପର୍କୀୟ ଭଉଣୀ ଗୌ\n ରୀଙ୍କ ସହ ଫୋନରେ କଥା ହୋଇଥିଲେ।",
              "as": "সি দেৱাংশৰ সম্পৰ্কীয় বায়েক গৌৰীৰ সৈতে ফোনত কথা পাতি কোম্পানীটোৰ বিষয়ে জানিব লৈছিল আৰু তাৰ বাবে কোনো আৰম্ভণি আছে নেকি জানিব বিচাৰিছিল।",
              "gu": "તે દેવાંશના પિતરાઈ ભાઈ ગૌરી સાથે ફોન પર વાત કરે છે અને કંપની વિશે જાણવા અને તેના માટે કોઈ જગ્યા છે કે કેમ તે જાણવા માટે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Gauri",
              "hi": "गौरी",
              "ka": "ಗೌರಿ",
              "te": "గౌరీ",
              "or": "ଗୌରି",
              "as": "গৌৰী",
              "gu": "ગૌરી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Yes, we do have many openings. In our company, we have a referral program. You can send me your resume, and I can refer you to the Human Resource (HR) Department. \nIt will be good to visit our website and look at the roles you qualify for.",
              "hi": "हां, हमारे पास नौकरी के अनेक अवसर उपलब्ध हैं। हमारी कंपनी में, एक रेफरल कार्यक्रम है। आप मुझे अपना रेज्यूमे भेज सकते हैं, और मैं आपको मानव संसाधन/ एचआर विभाग को रेफेर कर सकती हूं।\nहमारी वेबसाइट पर जाकर अपनी योग्यता अनुसार उपलब्ध नौकरी के लिए उन पदों को देखना अच्छा रहेगा।",
              "ka": "ಹೌದು, ನಾವು ಅನೇಕ ತೆರೆಯುವಿಕೆಗಳನ್ನು ಹೊಂದಿದ್ದೇವೆ. ನಮ್ಮ ಕಂಪನಿಯಲ್ಲಿ, ನಾವು ಉಲ್ಲೇಖಿತ ಕಾರ್ಯಕ್ರಮವನ್ನು ಹೊಂದಿದ್ದೇವೆ. ನಿಮ್ಮ ರೆಸ್ಯೂಮ್ ಅನ್ನು ನೀವು ನನಗೆ ಕಳುಹಿಸಬಹುದು ಮತ್ತು ನಾನು ನಿಮ್ಮನ್ನು ಮಾನವ ಸಂಪನ್ಮೂಲ (HR) ಇಲಾಖೆಗೆ ಉಲ್ಲೇಖಿಸಬಹುದು. ನಮ್ಮ ವೆಬ್‌ಸೈಟ್‌ಗೆ ಭೇಟಿ ನೀಡುವುದು ಮತ್ತು ನೀವು ಅರ್ಹತೆ ಪಡೆದ ಪಾತ್ರಗಳನ್ನು ನೋಡುವುದು ಒಳ್ಳೆಯದು",
              "te": "\"అవును, మాకు చాలా ఓపెనింగ్‌లు ఉన్నాయి. మా కంపెనీలో, మాకు రెఫరల్ ప్రోగ్రామ్ ఉంది. మీరు మీ రెజ్యూమ్‌ని నాకు పంపవచ్చు మరియు నేను మిమ్మల్ని మానవ వనరుల (HR) విభాగానికి సూచించగలను.\n మా వెబ్‌సైట్‌ను సందర్శించడం మరియు మీరు అర్హత పొందిన పాత్రలను చూడటం మంచిది.\"",
              "or": "ହଁ, ଆମର ଅନେକ ପଦବୀ ଅଛି। ଆମ କମ୍ପାନୀରେ ଆମର ଏକ ରେଫରାଲ୍ ପ୍ରୋଗ୍ରାମ୍ ଅଛି। ତୁମେ ମୋତେ ତୁମର ରିଜ୍ୟୁମ୍ ପଠାଇ ପାରିବ ଏବଂ ମୁଁ ତୁମକୁ ମାନବ ସମ୍ବଳ (HR) ବିଭାଗକୁ ପଠାଇ ପାରିବି।\n \n ଆମର ୱେବସାଇଟ୍ ପରିଦର୍ଶନ କରିବ ଏବଂ ତୁମେ ଯେଉଁ ଭୂମିକା ଗ୍ରହଣ କରିବାକୁ ଯୋଗ୍ୟ ତାହା ଦେଖିବା ଭଲ ହେବ |",
              "as": "হয়, আমাৰ বহুতো আৰম্ভণি আছে। আমাৰ কোম্পানীত, আমাৰ এটা ৰেফাৰেল কাৰ্যসূচী আছে। তুমি মোক তোমাৰ ৰিজিউমি পঠিয়াব পাৰা, আৰু মই তোমাক মানৱ সম্পদ (HR) বিভাগলৈ পঠিয়াব পাৰো। \n \n আমাৰ ৱেবছাইটদৰ্শন কৰা আৰু আপুনি যোগ্য ভূমিকাবোৰ চোৱাটো ভাল হ'ব।",
              "gu": "હા, અમારી પાસે ઘણી બધી જગ્યાઓ છે. અમારી કંપનીમાં, અમારી પાસે રેફરલ યોજના છે. તમે મને તમારો બાયોડેટા મોકલી શકો છો અને હું તમને હ્યુમન રિસોર્સ (HR) વિભાગમાં મોકલી શકું છું. અમારી વેબસાઇટની મુલાકાત લેવી અને તમે જે ભૂમિકાઓ માટે લાયક છો તે જોવું સારું રહેશે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D9_3_3///",
              "hi": "D9_3_3 ///////",
              "ka": "D9_3_3///",
              "te": "D9_3_3///",
              "or": "D9_3_3///",
              "as": "D9_3_3///",
              "gu": "D9_3_3///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Whoa! Is it that simple?",
              "hi": "वाह! क्या यह इतना सहज है?",
              "ka": "ವಾವ್! ಇದು ಇಷ್ಟು ಸರಳವಾಗಿದೆಯೇ?",
              "te": "అయ్యో! ఇది చాలా సులభం?",
              "or": "ହା! ଏହା ଏତେ ସରଳ କି?",
              "as": "ৱাহ! এইটো ইমান সহজ নেকি?",
              "gu": "વાહ! તે સરળ છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "That’s not the end of the process. Referral only helps your resume reach HR faster. You still have to give all the interviews and tests.",
              "hi": "यह प्रक्रिया का अंत नहीं है। रेफरल केवल आपके रेज़्यूमे को एचआर तक जल्दी पहुंचाने में सहायक होता है। आपको अभी भी सभी साक्षात्कार और परीक्षा देनी हैं।",
              "ka": "ಅದು ಪ್ರಕ್ರಿಯೆಯ ಅಂತ್ಯವಲ್ಲ. ರೆಫರಲ್ ನಿಮ್ಮ ರೆಸ್ಯೂಮ್ ವೇಗವಾಗಿ HR ತಲುಪಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನೀವು ಇನ್ನೂ ಎಲ್ಲಾ ಸಂದರ್ಶನಗಳು ಮತ್ತು ಪರೀಕ್ಷೆಗಳನ್ನು ನೀಡಬೇಕಾಗಿದೆ.",
              "te": "అది ప్రక్రియ ముగింపు కాదు. రిఫరల్ మీ రెజ్యూమ్ HRని వేగంగా చేరుకోవడానికి మాత్రమే సహాయపడుతుంది. మీరు అన్ని ఇంటర్వ్యూలు మరియు పరీక్షలను ఇవ్వాల్సి ఉంటుంది.",
              "or": "ତାହା ପ୍ରକ୍ରିୟାର ସମାପ୍ତ ନୁହେଁ | ରେଫରାଲ୍ କେବଳ ତୁମର ରିଜ୍ୟୁମ୍ କୁ ଶୀଘ୍ର\n  HR ଙ୍କ ନିକଟରେ ପହଞ୍ଚିବାରେ ସାହାଯ୍ୟ କରେ | ଆପଣଙ୍କୁ ତଥାପି ସମସ୍ତ \n ସାକ୍ଷାତକାର ଏବଂ ପରୀକ୍ଷା ଦେବାକୁ ପଡିବ |",
              "as": "এইটো প্ৰক্ৰিয়াটোৰ শেষ নহয়। ৰেফাৰেলে কেৱল তোমাৰ ৰিজিউমিক এইচআৰ দ্ৰুতগতিত পোৱাত সহায় কৰে। তুমি এতিয়াও সকলো সাক্ষাৎকাৰ আৰু পৰীক্ষা দিব লাগিব।",
              "gu": "તે પ્રક્રિયાનો અંત નથી. રેફરલ ફક્ત તમારા રેઝ્યૂમેને HR સુધી ઝડપથી પહોંચવામાં મદદ કરે છે. તમારે હજુ પણ તમામ ઇન્ટરવ્યુ અને પરીક્ષા આપવાના છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Got it! Thank you, Di! I’ll send you an email with the job I would like to apply for and my resume with it.",
              "hi": "समझ लिया! धन्यवाद,दी! मैं आपको उस नौकरी के लिए एक ईमेल और रेज्यूमे भेजूंगा जिसके लिए मैं आवेदन करना चाहता हूं ।",
              "ka": "ಅರ್ಥವಾಯಿತು! ಧನ್ಯವಾದಗಳು, ದೀ! ನಾನು ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಬಯಸುವ ಕೆಲಸ ಮತ್ತು ಅದರೊಂದಿಗೆ ನನ್ನ ರೆಸ್ಯುಮೆಯನ್ನು ನಾನು ನಿಮಗೆ ಇಮೇಲ್ನಲ್ಲಿ ಕಳುಹಿಸುತ್ತೇನೆ.",
              "te": "అర్థమైంది! ధన్యవాదాలు! నేను దరఖాస్తు చేయాలనుకుంటున్న ఉద్యోగం మరియు దానితో పాటు నా రెజ్యూమ్‌ తో మీకు ఇమెయిల్ పంపుతాను.",
              "or": "ବୁଝିଗଲି ! ଧନ୍ୟବାଦ, ଦିଦି ! ମୁଁ ଯେଉଁ ଚାକିରି ପାଇଁ ଆବେଦନ କରିବାକୁ ଚାହୁଁଛି\n  ଏବଂ ଏହା ସହିତ ମୋର ରିଜ୍ୟୁମ୍ ମୁଁ ଆପଣଙ୍କୁ ଏକ ଇମେଲ୍ରେ ପଠାଇବି |",
              "as": "বুজিলোঁ! ধন্যবাদ, বা! মই তোমাক মই আবেদন কৰিব বিচৰা চাকৰিটোৰ সৈতে এটা ইমেইল আৰু ইয়াৰ সৈতে মোৰ ৰিজিউমি পঠিয়াম।",
              "gu": "ખબર પડી ગઈ! આભાર, બહેન! હું તને જે નોકરી માટે અરજી કરવા માંગુ છું અને તેની સાથે મારો બાયોડેટા સાથે એક ઈમેલ મોકલીશ."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D9_4_1 bg",
              "hi": "D9_4_1 बीजी",
              "ka": "D9_4_1 bg",
              "te": "D9_4_1 bg",
              "or": "D9_4_1 bg",
              "as": "D9_4_1 bg",
              "gu": "D9_4_1 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "After all the rounds of interviews are done, Shikhar is eagerly waiting to know the result.",
              "hi": "साक्षात्कार के सभी दौरों के बाद, शिखर उत्सुकता से परिणाम जानने की प्रतीक्षा कर रहा है।",
              "ka": "ಎಲ್ಲಾ ಸುತ್ತಿನ ಸಂದರ್ಶನಗಳು ಮುಗಿದ ನಂತರ, ಶಿಖರ್ ಫಲಿತಾಂಶವನ್ನು ತಿಳಿಯಲು ಕಾತುರದಿಂದ ಕಾಯುತ್ತಿದ್ದಾನೆ",
              "te": "అన్ని రౌండ్ల ఇంటర్వ్యూలు పూర్తయిన తర్వాత, శిఖర్ ఫలితం కోసం ఆసక్తిగా ఎదురుచూస్తున్నాడు.",
              "or": "ସାକ୍ଷାତକାର ର ସମସ୍ତ ରାଉଣ୍ଡ ସମାପ୍ତ ହେବା ପରେ, ଶିଖର ଫଳାଫଳ ଜାଣିବାକୁ\n  ଉତ୍ସାହର ସହିତ ଅପେକ୍ଷା କରନ୍ତି |",
              "as": "সকলো ধৰণৰ সাক্ষাৎকাৰ শেষ হোৱাৰ পিছত, শিখৰে ফলাফল জানিবলৈ আগ্ৰহেৰে ৰৈ আছে।",
              "gu": "ઇન્ટરવ્યુના તમામ રાઉન્ડ પૂર્ણ થયા પછી, શિખર પરિણામ જાણવા માટે આતુરતાથી રાહ જોઈ રહ્યો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Babuji",
              "hi": "बाबूजी",
              "ka": "ಬಾಬುಜಿ",
              "te": "నాన్నగారు",
              "or": "ବାବୁଜୀ",
              "as": "দেউতা",
              "gu": "બાબુજી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Babuji",
              "hi": "बाबूजी",
              "ka": "ಬಾಬುಜಿ",
              "te": "నాన్నగారు",
              "or": "ବାବୁଜୀ",
              "as": "দেউতা",
              "gu": "બાબુજી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Did you get the job?",
              "hi": "क्या तुम्हें नौकरी मिल गई?",
              "ka": "ನಿನಗೆ ಕೆಲಸ ಸಿಕ್ಕಿತೆ?",
              "te": "నీకు ఉద్యోగం వచ్చిందా?",
              "or": "ତୁମେ ଚାକିରି ପାଇଲ କି?",
              "as": "তুমি চাকৰিটো পালানে?",
              "gu": "શું તને નોકરી મળી?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "They haven’t responded to the email till now.",
              "hi": "उन्होंने अब तक ईमेल का जवाब नहीं दिया है।",
              "ka": "ಅವರು ಇಲ್ಲಿಯವರೆಗೆ ನನ್ನ ಇಮೇಲ್‌ಗೆ ಪ್ರತಿಕ್ರಿಯಿಸಿಲ್ಲ.",
              "te": "వారు ఇప్పటివరకు ఇమెయిల్‌కు స్పందించలేదు.",
              "or": "ସେମାନେ ବର୍ତ୍ତମାନ ପର୍ଯ୍ୟନ୍ତ ଇମେଲର କୌଣସି ପ୍ରତିକ୍ରିୟା କରିନାହାଁନ୍ତି |",
              "as": "তেওঁলোকে এতিয়ালৈকে ইমেইলৰ সঁহাৰি দিয়া নাই।",
              "gu": "તેઓએ હજુ સુધી ઈમેલનો જવાબ આપ્યો નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "When were they supposed to reply to you?",
              "hi": "वे तुमको जवाब कब देने वाले थे?",
              "ka": "ಅವರು ನಿನಗೆ ಯಾವಾಗ ಪ್ರತ್ಯುತ್ತರ ನೀಡಬೇಕಿತ್ತು?",
              "te": "వారు మీకు ఎప్పుడు సమాధానం ఇవ్వాలి?",
              "or": "ସେମାନେ ତୁମକୁ କେବେ ଉତ୍ତର ଦେବାକୁ ଥିଲେ?",
              "as": "তেওঁলোকে তোমাক কেতিয়া উত্তৰ দিব লাগিছিল?",
              "gu": "તેઓ તને ક્યારે જવાબ આપવાના હતા?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "I should have got the message by now. I don’t know what is taking them so long.",
              "hi": "मुझे अब तक संदेश मिल जाना चाहिए था। मुझे नहीं पता कि उन्हें इतना अधिक समय क्यों लग रहा है।",
              "ka": "ಇಷ್ಟೊತ್ತಿಗೆ ನನಗೆ ಸಂದೇಶ ಬರಬೇಕಿತ್ತು. ಅವರು ಇಷ್ಟು ಸಮಯ ಏಕೆ ತೆಗೆದುಕೊಳ್ಳುತ್ತಿದ್ದಾರೆ ಎಂದು ನನಗೆ ತಿಳಿದಿಲ್ಲ.",
              "te": "నాకు ఈపాటికి మెసేజ్ వచ్చి వుండాలి. వారికి ఇంత సమయం ఎందుకు పడుతుందో నాకు తెలియదు.",
              "or": "ମୁଁ ବର୍ତ୍ତମାନ ପର୍ଯ୍ୟନ୍ତ ଉତ୍ତର ପାଇଯାଇଥାନ୍ତି | ସେମାନଙ୍କୁ ଏତେ ସମୟ କାହିଁକି ଲାଗୁଛି ମୁଁ ଜାଣେ ନାହିଁ |",
              "as": "মই এতিয়ালৈকে খবৰটো পাব লাগিছিল। মই নাজানো তেওঁলোকক ইমান সময় কিহে লৈছে।",
              "gu": "મને અત્યાર સુધીમાં મેસેજ મળી ગયો હશે. મને ખબર નથી કે આટલો સમય શું કામ લાગી રહ્યો છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D9_4_2",
              "hi": "D9_4_2",
              "ka": "D9_4_2",
              "te": "D9_4_2",
              "or": "D9_4_2",
              "as": "D9_4_2",
              "gu": "D9_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar is highly restless and keeps checking his phone.",
              "hi": "शिखर अत्यधिक बेचैन है और अपने फोन को बार - बार देखता रहता है।",
              "ka": "ಶಿಖರ್ ತುಂಬಾ ಪ್ರಕ್ಷುಬ್ಧನಾಗಿದ್ದಾನೆ ಮತ್ತು ಅವನ ಫೋನ್ ಅನ್ನು ಪರಿಶೀಲಿಸುತ್ತಲೇ ಇರುತ್ತಾನೆ.",
              "te": "శిఖర్ చాలా అశాంతిగా ఉన్నాడు మరియు అతని ఫోన్‌ ని చెక్ చేస్తూనే ఉన్నాడు.",
              "or": "ଶିଖର ଅତ୍ୟଧିକ ଅଶାନ୍ତ ଏବଂ ତାଙ୍କ ଫୋନ୍ ଯାଞ୍ଚ ଜାରି ରଖନ୍ତି |",
              "as": "শিখৰ অত্যন্ত অস্থিৰ আৰু তাৰ ফোনটো চাই থাকে।",
              "gu": "શિખર ખૂબ જ બેચેન છે અને તેનો ફોન ચેક કરતો રહે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D9_4_3 email",
              "hi": "D9_4_3 ईमेल",
              "ka": "D9_4_3 email",
              "te": "D9_4_3 ఇమెయిల్",
              "or": "D9_4_3 email",
              "as": "D9_4_3 email",
              "gu": "D9_4_3 ઇમેઇલ"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailTo",
            "value": {
              "en": "shikhar@zmail.com",
              "hi": "shikhar@zmail.com",
              "ka": "shikhar@zmail.com",
              "te": "shikhar@zmail.com",
              "or": "shikhar@zmail.com",
              "as": "shikhar@zmail.com",
              "gu": "shikhar@zmail.com"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailSubject",
            "value": {
              "en": "Job offer",
              "hi": "नौकरी का प्रस्ताव",
              "ka": "ಉದ್ಯೋಗದ ಪ್ರಸ್ತಾಪ.",
              "te": "జాబ్ ఆఫర్",
              "or": "ଚାକିରି ଅଫର୍ |",
              "as": "চাকৰিৰ প্ৰস্তাৱ",
              "gu": "નોકરી ની તક"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailMsg",
            "value": {
              "en": "Dear Shikhar,\n\nWe are pleased to inform you that you have been selected for the role of Associate Financial Advisor. Your offer letter is attached to the email. We want you to join our team as soon as possible. If you choose to accept, please send us the signed offer letter and the possible joining date.\n\nRegards,\nHR team",
              "hi": "प्रिय शिखर,\n\nहमें आपको यह बताते हुए प्रसन्नता हो रही है कि आपको एसोसिएट फाइनेंशियल एडवाइजर के पद के लिए चुना गया है। आपका प्रस्ताव पत्र ईमेल के साथ ही संलग्न है। हम चाहते हैं कि आप जितनी जल्दी हो सके हमारी टीम में शामिल हों। यदि आप स्वीकार करते हैं, तो कृपया हमें हस्ताक्षरित ऑफ़र पत्र और पद ग्रहण की  संभावित तिथि बताएं।\n\nसादर,\nएचआर टीम",
              "ka": "ಆತ್ಮೀಯ ಶಿಖರ್,                                                                                                                                                                                                                  ಅಸೋಸಿಯೇಟ್ ಫೈನಾನ್ಶಿಯಲ್ ಅಡ್ವೈಸರ್ ಪಾತ್ರಕ್ಕೆ ನಿಮ್ಮನ್ನು ಆಯ್ಕೆ ಮಾಡಲಾಗಿದೆ ಎಂದು ನಿಮಗೆ ತಿಳಿಸಲು ನಾವು ಸಂತೋಷಪಡುತ್ತೇವೆ. ನಿಮ್ಮ ಆಫರ್ ಪತ್ರವನ್ನು ಇಮೇಲ್‌ಗೆ ಲಗತ್ತಿಸಲಾಗಿದೆ. ನೀವು ಆದಷ್ಟು ಬೇಗ ನಮ್ಮ ತಂಡವನ್ನು ಸೇರಬೇಕೆಂದು ನಾವು ಬಯಸುತ್ತೇವೆ. ನೀವು ಕೆಲಸದ ಆಫರ್ ಅನ್ನು ಸ್ವೀಕರಿಸಲು ಆಯ್ಕೆಮಾಡಿದರೆ, ದಯವಿಟ್ಟು ಸಹಿ ಮಾಡಿದ ಆಫರ್ ಪತ್ರ ಮತ್ತು ಸಂಭವನೀಯ ಸೇರ್ಪಡೆ ದಿನಾಂಕವನ್ನು ನಮಗೆ ಕಳುಹಿಸಿ.                                                                                                                                                                                ವಂದನೆಗಳು,                                                                                                                                                                                                                                  ಎಚ್‌ಆರ್ ತಂಡ                                                   ",
              "te": "ప్రియమైన శిఖర్,\n \n మీరు అసోసియేట్ ఫైనాన్షియల్ అడ్వైజర్ పాత్రకు ఎంపికయ్యారని మీకు తెలియజేయడానికి మేము సంతోషిస్తున్నాము. మీ ఆఫర్ లెటర్ ఇమెయిల్‌కి జోడించబడింది. మీరు వీలైనంత త్వరగా మా బృందంలో చేరాలని మేము కోరుకుంటున్నాము. మీరు అంగీకరించాలని ఎంచుకుంటే, దయచేసి సంతకం చేసిన ఆఫర్ లెటర్ మరియు చేరే తేదీని మాకు పంపండి.\n \n గౌరవంతో,\n HR బృందం",
              "or": "ପ୍ରିୟ ଶିଖର,\n \n \n \n ଆମେ ଆପଣଙ୍କୁ ଜଣାଇବାକୁ ଖୁସି ଯେ ଆପଣ ଆସୋସିଏଟ୍ ଆର୍ଥିକ ପରାମର୍ଶଦାତା ଭାବରେ ମନୋନୀତ ହୋଇଛନ୍ତି | ତୁମର ଅଫର ଚିଠି ଇମେଲ ସହିତ ସଂଲଗ୍ନ ହୋଇଛି | ଆମେ ଚାହୁଁ ତୁମେ ଯଥାଶୀଘ୍ର ଆମ ଦଳରେ ଯୋଗ ଦିଅ | ଯଦି ଆପଣ ଗ୍ରହଣ କରିବାକୁ ବାଛିଛନ୍ତି, ଦୟାକରି ଆମକୁ ଦସ୍ତଖତ ଅଫର ଚିଠି ଏବଂ ସମ୍ଭାବ୍ୟ ଯୋଗଦାନ ତାରିଖ ପଠାନ୍ତୁ |\n \n \n \n ଶୁଭେଚ୍ଛା,\n \n HR ଦଳ",
              "as": "মৰমৰ শিখৰ,\n \n \n \n আমি তোমাক জনাবলৈ পাই আনন্দিত যে তোমাক সহযোগী বিত্তীয় উপদেষ্টাৰ কামৰ বাবে বাছনি কৰা হৈছে। তোমাৰ অফাৰ লেটাৰ ইমেইলৰ সৈতে সংলগ্ন হৈ আছে। আমি বিচাৰো যে তুমি যিমান সম্ভৱ সোনকালে আমাৰ দলত যোগদান কৰা। যদি তুমি গ্ৰহণ কৰিব বিচাৰা, অনুগ্ৰহ কৰি আমালৈ স্বাক্ষৰিত অফাৰ পত্ৰ আৰু সাম্ভাব্য যোগদানৰ তাৰিখ প্ৰেৰণ কৰা।\n \n \n \n বিনীত,\n \n HR দল",
              "gu": "પ્રિય શિખર, અમને તમને જણાવતા આનંદ થાય છે કે તમને સહયોગી નાણાકીય સલાહકારની ભૂમિકા માટે પસંદ કરવામાં આવ્યા છે. તમારો ઑફર લેટર ઈમેલ સાથે જોડાયેલ છે. અમે ઈચ્છીએ છીએ કે તમે શક્ય તેટલી વહેલી તકે અમારી ટીમમાં જોડાઓ. જો તમે સ્વીકારવાનું પસંદ કરો છો, તો કૃપા કરીને અમને સહી કરેલ ઑફર લેટર અને સંભવિત જોડાવાની તારીખ મોકલો. સાદર, એચઆર ટીમ"
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D9_4_5 bg",
              "hi": "D9_4_5 बीजी",
              "ka": "D9_4_5 bg",
              "te": "D9_4_5 bg",
              "or": "D9_4_5 bg",
              "as": "D9_4_5 bg",
              "gu": "D9_4_5 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar jumps off his seat!",
              "hi": "शिखर अपनी सीट से कूद पड़ता है!",
              "ka": "ಆಸನದಿಂದ ಜಿಗಿದ ಶಿಖರ್!",
              "te": "శిఖర్ తన సీటు నుండి దూకాడు!",
              "or": "ଶିଖର ତାଙ୍କ ଆସନରୁ ଡେଇଁଲେ!",
              "as": "শিখৰে বহাৰ পৰা জঁপিয়াই পৰিল!",
              "gu": "શિખર તેની સીટ પરથી કૂદી પડ્યો!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Babuji",
              "hi": "बाबूजी",
              "ka": "ಬಾಬುಜಿ",
              "te": "నాన్నగారు",
              "or": "ବାବୁଜୀ",
              "as": "দেউতা",
              "gu": "બાબુજી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I got it! I got it!",
              "hi": "मैं समझ गया! मैं समझ गया!",
              "ka": "ನನಗೆ ಸಿಕ್ಕಿತು! ನನಗೆ ಸಿಕ್ಕಿತು!",
              "te": "నాకు అది అర్థమైంది! నాకు అది అర్థమైంది!",
              "or": "ମୁଁ ପାଇଗଲି , ମୁଁ ପାଇଗଲି !",
              "as": "মই সেইটো পালোঁ! মই সেইটো পালোঁ!",
              "gu": "હુ સમજી ગયો! હુ સમજી ગયો!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Excellent beta! I knew you were meant to do something big.",
              "hi": "बहुत अच्छा बेटा! मुझे पता था कि तुम्हें कुछ बड़ा काम करना था।",
              "ka": "ಅತ್ಯುತ್ತಮ ಬೇಟ! ನೀನು ಏನಾದರೂ ದೊಡ್ಡದನ್ನು ಮಾಡಲು ಉದ್ದೇಶಿಸಿದ್ದೀಯಾ ಎಂದು ನನಗೆ ತಿಳಿದಿತ್ತು.",
              "te": "అద్భుతం అబ్బాయి! నువ్వు ఏదైనా పెద్దగా చేస్తావని నాకు తెలుసు.",
              "or": "ବହୁତ ବଢିଆ ପୁଅ! ମୁଁ ଜାଣିଥିଲି ତୁମେ କିଛି ବଡ କରିବା ପାଇଁ ଉଦ୍ଦିଷ୍ଟ |",
              "as": "বঢ়িয়া বেটা! মই জানিছিলো যে তুমি কিবা ডাঙৰ কৰিব বিচাৰিছিলা।",
              "gu": "ઉત્તમ બેટા! હું જાણતો હતો કે તું કંઈક મોટું કરવા માટે હતા."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "employee referral",
              "hi": "कर्मचारी रेफरल",
              "ka": "ಉದ್ಯೋಗಿ ಉಲ್ಲೇಖ",
              "te": "ఉద్యోగి రిఫరల్",
              "or": "କର୍ମଚାରୀଙ୍କ ରେଫରାଲ୍ |",
              "as": "কৰ্মচাৰী ৰেফাৰেল",
              "gu": "કર્મચારી રેફરલ"
            }
          }
        ]
      },
      "scene37": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D10_1_1",
              "hi": "D10_1_1",
              "ka": "D10_1_1",
              "te": "D10_1_1",
              "or": "D10_1_1",
              "as": "D10_1_1",
              "gu": "D10_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar moves to the city. He is pleased that he can help his family.",
              "hi": "शिखर शहर जाता है। वह प्रसन्न है कि वह अपने परिवार की मदद कर सकता है।",
              "ka": "ಶಿಖರ್ ನಗರಕ್ಕೆ ತೆರಳುತ್ತಾನೆ. ಆತನು ತನ್ನ ಕುಟುಂಬಕ್ಕೆ ಸಹಾಯ ಮಾಡಬಹುದು ಎಂದು ಸಂತೋಷಪಡುತ್ತಾನೆ.",
              "te": "శిఖర్ నగరానికి వెళతాడు. అతను తన కుటుంబానికి సహాయం చేయగలనని సంతోషిస్తున్నాడు.",
              "or": "ଶିଖର ସହରକୁ ଚାଲିଆସନ୍ତି | ସେ ଖୁସି ଯେ ସେ ନିଜ ପରିବାରକୁ ସାହାଯ୍ୟ \n କରିପାରିବେ |",
              "as": "শিখৰ চহৰলৈ যায়। সি সুখী যে সি তাৰ পৰিয়ালক সহায় কৰিব পাৰে।",
              "gu": "શિખર શહેરમાં ફરે છે. તે ખુશ છે કે તે તેના પરિવારને મદદ કરી શકે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D10_1_2",
              "hi": "D10_1_2",
              "ka": "D10_1_2",
              "te": "D10_1_2",
              "or": "D10_1_2",
              "as": "D10_1_2",
              "gu": "D10_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Within a few months into the job, the family is able to pay back the loan for their tractor. Slowly the family feels more financially secure.",
              "hi": "नौकरी के कुछ महीनों में ही, परिवार अपने ट्रैक्टर के लिए ऋण को वापस करने में सक्षम है। धीरे-धीरे परिवार आर्थिक रूप से अधिक सुरक्षित महसूस करता है।",
              "ka": "ಕೆಲಸಕ್ಕೆ ಸೇರಿದ ಕೆಲವೇ ತಿಂಗಳುಗಳಲ್ಲಿ, ಕುಟುಂಬವು ತಮ್ಮ ಟ್ರ್ಯಾಕ್ಟರ್‌ಗಾಗಿ ಮಾಡಿದ ಸಾಲವನ್ನು ಮರುಪಾವತಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ. ನಿಧಾನವಾಗಿ ಕುಟುಂಬವು ಆರ್ಥಿಕವಾಗಿ ಹೆಚ್ಚು ಸುರಕ್ಷಿತವಾಗಿದೆ.",
              "te": "ఉద్యోగంలో చేరిన కొద్ది నెలల్లోనే ఆ కుటుంబం ట్రాక్టర్‌ కు తీసుకున్న అప్పును తిరిగి చెల్లించగలుగుతుంది. మెల్లగా కుటుంబం ఆర్థికంగా మరింత సెక్యూర్ గా అవుతారు.",
              "or": "ଚାକିରିରେ କିଛି ମାସ ମଧ୍ୟରେ, ପରିବାର ସେମାନଙ୍କ ଟ୍ରାକ୍ଟର ପାଇଁ ରୁ ଣ ପରିଶୋଧ କରିବାକୁ ସକ୍ଷମ ଅଟନ୍ତି | ଧୀରେ ଧୀରେ ପରିବାର ଅଧିକ ଆର୍ଥିକ ଭାବେ ସୁରକ୍ଷିତ ଅନୁଭବ କରନ୍ତି |",
              "as": "চাকৰিৰ কেইমাহমানৰ ভিতৰতে, পৰিয়ালটোৱে তেওঁলোকৰ ট্ৰেক্টৰৰ ঋণ পৰিশোধ কৰিবলৈ সক্ষম হয়। লাহে লাহে পৰিয়ালটোৱে অধিক আৰ্থিকভাৱে সুৰক্ষিত বোধ কৰে।",
              "gu": "નોકરીના થોડા મહિનાની અંદર, પરિવાર તેમના ટ્રેક્ટરની લોન પરત ચૂકવવામાં સક્ષમ છે. ધીમે ધીમે પરિવાર આર્થિક રીતે વધુ સુરક્ષિત અનુભવે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D10_1_3",
              "hi": "D10_1_3",
              "ka": "D10_1_3",
              "te": "D10_1_3",
              "or": "D10_1_3",
              "as": "D10_1_3",
              "gu": "D10_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar and his team are responsible for account management for different clients. He does the work well and soon starts managing the books independently.",
              "hi": "शिखर और उसकी टीम विभिन्न ग्राहकों हेतु अकाउंट प्रबंधन के लिए उत्तरदायी है। वह अच्छी तरह से काम करता है और जल्द ही बहीखातों को स्वतंत्र रूप से संभालना शुरु कर देता है।",
              "ka": "ಶಿಖರ್ ಮತ್ತು ಆತನ ತಂಡವು ವಿವಿಧ ಕ್ಲೈಂಟ್‌ಗಳಿಗೆ ಖಾತೆ ನಿರ್ವಹಣೆಯ ಜವಾಬ್ದಾರಿಯನ್ನು ಹೊಂದಿದೆ. ಅವರು ಕೆಲಸವನ್ನು ಚೆನ್ನಾಗಿ ಮಾಡುತ್ತಾರೆ ಮತ್ತು ಶೀಘ್ರದಲ್ಲೇ ಪುಸ್ತಕಗಳನ್ನು ಸ್ವತಂತ್ರವಾಗಿ ನಿರ್ವಹಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ.",
              "te": "శిఖర్ మరియు అతని బృందం వేర్వేరు ఖాతాదారులకు అకౌంట్ మేనేజ్మెంట్ కు బాధ్యత వహిస్తారు. అతను పనిని బాగా చేస్తాడు మరియు త్వరలో స్వతంత్రంగా పుస్తకాలను మేనేజ్ చేయడం ప్రారంభిస్తాడు.",
              "or": "ବିଭିନ୍ନ ଗ୍ରାହକଙ୍କ ପାଇଁ ଖାତା ପରିଚାଳନା ପାଇଁ ଶିଖର ଏବଂ ତାଙ୍କ ଦଳ ଦାୟୀ | ସେ କାମଟି ଭଲ କରନ୍ତି ଏବଂ ଶୀଘ୍ର ସ୍ୱାଧୀନ ଭାବରେ ପୁସ୍ତକ ପରିଚାଳନା କରିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "শিখৰ আৰু তাৰ দল বিভিন্ন গ্ৰাহকৰ বাবে একাউণ্ট মেনেজমেন্ট বাবে দায়বদ্ধ। সি কামটো ভালদৰে কৰে আৰু সোনকালেই কিতাপবোৰ স্বতন্ত্ৰভাৱে পৰিচালনা কৰা আৰম্ভ কৰে।",
              "gu": "શિખર અને તેની ટીમ અલગ-અલગ ગ્રાહકો માટે એકાઉન્ટ મેનેજમેન્ટ માટે જવાબદાર છે. તે કામ સારી રીતે કરે છે અને ટૂંક સમયમાં સ્વતંત્ર રીતે પુસ્તકોનું સંચાલન કરવાનું શરૂ કરે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D10_2_1",
              "hi": "D10_2_1",
              "ka": "D10_2_1",
              "te": "D10_2_1",
              "or": "D10_2_1",
              "as": "D10_2_1",
              "gu": "D10_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The app becomes popular, and the team grows. After two years, Shikhar gets a promotion and gets to work with a new team.",
              "hi": "ऐप लोकप्रिय हो जाता है, और टीम की तरक्की होती है। दो साल बाद, शिखर को पदोन्नति मिलती है और एक नई टीम के साथ काम करने के लिए मिलता है।",
              "ka": "ಆ್ಯಪ್‌ ಜನಪ್ರಿಯವಾಗುತ್ತದೆ ಮತ್ತು ತಂಡವು ಬೆಳೆಯುತ್ತದೆ. ಎರಡು ವರ್ಷಗಳ ನಂತರ, ಶಿಖರ್‌ಗೆ ಬಡ್ತಿ ಸಿಕ್ಕಿತು ಮತ್ತು ಹೊಸ ತಂಡದೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುತ್ತಾನೆ.",
              "te": "యాప్ జనాదరణ పొందుతుంది మరియు జట్టు పెరుగుతుంది. రెండు సంవత్సరాల తర్వాత, శిఖర్ ప్రమోషన్ పొంది కొత్త టీమ్‌ తో పని చేస్తాడు.",
              "or": "ଆପ୍ ଲୋକପ୍ରିୟ ହୁଏ, ଏବଂ ଟିମ ବଢିବାକୁ ଲାଗେ | ଦୁଇ ବର୍ଷ ପରେ ଶିଖର\n  ଏକ ପଦୋନ୍ନତି ପାଇ ଏକ ନୂତନ ଦଳ ସହିତ କାମ କରିବାକୁ ଲାଗିଲେ |",
              "as": "এপটো জনপ্ৰিয় হৈ পৰে, আৰু দলটো বৃদ্ধি হয়। দুবছৰৰ পিছত, শিখৰে পদোন্নতি পায় আৰু এটা নতুন দলৰ সৈতে কাম কৰিবলৈ পায়।",
              "gu": "એપ્લિકેશન લોકપ્રિય બને છે, અને ટીમ વધે છે. બે વર્ષ પછી, શિખરને બઢતી મળે છે અને તે નવી ટીમ સાથે કામ કરવા જાય છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D10_2_2",
              "hi": "D10_2_2",
              "ka": "D10_2_2",
              "te": "D10_2_2",
              "or": "D10_2_2",
              "as": "D10_2_2",
              "gu": "D10_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "While the team works hard, they also make time for doing fun things at work. The team has a tradition to arrange monthly group dinners, where everyone makes and brings a dish. Shikhar also has to bring a dish.",
              "hi": "टीम कड़ी मेहनत के साथ साथ काम पर मजेदार चीजें करने के लिए भी समय निकालते हैं। टीम में हर महीने  समूह रात्रिभोज की व्यवस्था करने की परंपरा है, जहां हर कोई एक पकवान बनाकर लाता है। शिखर को भी एक डिश लाना है।",
              "ka": "ತಂಡವು ಕಷ್ಟಪಟ್ಟು ಕೆಲಸ ಮಾಡುವಾಗ, ಅವರು ಕೆಲಸದಲ್ಲಿ ಮೋಜಿನ ಕೆಲಸಗಳನ್ನು ಮಾಡಲು ಸಮಯವನ್ನು ಮಾಡುತ್ತಾರೆ. ತಂಡವು ಮಾಸಿಕ ಗುಂಪು ಭೋಜನವನ್ನು ಏರ್ಪಡಿಸುವ ಸಂಪ್ರದಾಯವನ್ನು ಹೊಂದಿದೆ, ಅಲ್ಲಿ ಪ್ರತಿಯೊಬ್ಬರೂ ಭಕ್ಷ್ಯವನ್ನು ತಯಾರಿಸುತ್ತಾರೆ ಮತ್ತು ತರುತ್ತಾರೆ. ಶಿಖರನೂ ತಿನಿಸು ತರಬೇಕು.",
              "te": "బృందం కష్టపడి పనిచేస్తూనే పనిలో వినోదభరితమైన పనులు చేయడానికి కూడా సమయాన్ని వెచ్చిస్తారు. నెలవారీ గ్రూప్ డిన్నర్ ఏర్పాటు చేయడం వారి బృందానికి అలవాటు, ఇక్కడ ప్రతి ఒక్కరూ ఒక వంటకాన్ని తయారు చేసి తీసుకువస్తారు. శిఖర్ కూడా ఒక డిష్ తీసుకురావాలి.",
              "or": "ଦଳ କଠିନ ପରିଶ୍ରମ କରୁଥିବାବେଳେ, ସେମାନେ କାର୍ଯ୍ୟରେ ମଜାଳିଆ କାର୍ଯ୍ୟ\n  କରିବା ପାଇଁ ମଧ୍ୟ ସମୟ ଦିଅନ୍ତି | ଦଳର ମାସିକ ଗୋଷ୍ଠୀ ରାତ୍ରୀ ଭୋଜନ କରିବାର ଏକ ପରମ୍ପରା ଅଛି, ଯେଉଁଠାରେ ସମସ୍ତେ ଏକ ଥାଳି ପ୍ରସ୍ତୁତ କରନ୍ତି | ଶିଖରଙ୍କୁ ମଧ୍ୟ ଏକ ଥାଳି ଆଣିବାକୁ ପଡିବ |",
              "as": "যদিও দলটোৱে কঠোৰ পৰিশ্ৰম কৰে, তেওঁলোকে কামত আমোদজনক কাম কৰাৰ বাবেও সময় উলিয়ায়। দলটোৰ প্ৰতি মাহে সমূহীয়া ভোজ আয়োজন কৰাৰ এক নিয়ম আছে, য'ত সকলোৱে এটা ব্যঞ্জন বনায় আৰু আনি দিয়ে। শিখৰেও এটা ব্যঞ্জন আনিব লাগিব।",
              "gu": "જ્યારે ટીમ સખત મહેનત કરે છે, ત્યારે તેઓ કામ પર મનોરંજક વસ્તુઓ કરવા માટે પણ સમય કાઢે છે. ટીમ પાસે માસિક સમૂહ રાત્રિભોજનની વ્યવસ્થા કરવાની પરંપરા છે, જ્યાં દરેક વ્યક્તિ વાનગી બનાવે છે અને લાવે છે. શિખરને પણ ભોજન  લાવવાનું રહેશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D10_3_1",
              "hi": "D10_3_1",
              "ka": "D10_3_1",
              "te": "D10_3_1",
              "or": "D10_3_1",
              "as": "D10_3_1",
              "gu": "D10_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar is jumping with joy! He can’t remember the last time he cooked and finds it refreshing that everyone in the team cooks!",
              "hi": "शिखर ख़ुशी से कूद रहा है! पिछली बार उसने कब खाना बनाया था उसको याद नहीं और यह बात उसे बहुत खुशी देती है कि टीम में हर कोई खाना बनाता है!",
              "ka": "ಖುಷಿಯಿಂದ ಕುಣಿದಾಡುತ್ತಿರುವ ಶಿಖರ್! ಅವನು ಕೊನೆಯ ಬಾರಿಗೆ ಅಡುಗೆ ಮಾಡಿದ್ದು ನೆನಪಿಲ್ಲ ಮತ್ತು ತಂಡದಲ್ಲಿರುವ ಪ್ರತಿಯೊಬ್ಬರೂ ಅಡುಗೆ ಮಾಡುವುದನ್ನು ರಿಫ್ರೆಶ್ ಆಗಿ ಕಂಡುಕೊಳ್ಳುತ್ತಾರೆ!",
              "te": "శిఖర్ ఆనందంతో గెంతుతున్నాడు! అతను చివరిసారి వండినది అతనికి గుర్తులేదు మరియు టీమ్‌ లోని ప్రతి ఒక్కరూ వండడం రిఫ్రెష్‌ గా ఉంది!",
              "or": "ଶିଖର ଆନନ୍ଦରେ ଡେଇଁପଡୁଛି! ସେ ଶେଷ ଥର କେବେ ରାନ୍ଧିଥିଲେ ତାର ମନେ ନାହିଁ ଏବଂ ଦଳର ସମସ୍ତେ ରୋଷେଇ କରିବା କୁ ସେ ସତେଜ ମନେ କରନ୍ତି!",
              "as": "শিখৰে ফূৰ্তিত জঁপিয়াই ফুৰিছে! সি শেষবাৰ ৰন্ধাৰ কথা মনত পেলাব পৰা নাই আৰু দলৰ সকলোৱে ৰান্ধিবলৈ সতেজ অনুভৱ কৰে!",
              "gu": "શિખર આનંદથી કૂદી રહ્યો છે! તેને યાદ નથી હોતું કે તેણે છેલ્લી વખત ક્યારે રાંધ્યું હતું અને તે તાજગીભર્યું લાગે છે કે ટીમમાં દરેક વ્યક્તિ રસોઈ કરે છે!"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D10_3_2",
              "hi": "D10_3_2",
              "ka": "D10_3_2",
              "te": "D10_3_2",
              "or": "D10_3_2",
              "as": "D10_3_2",
              "gu": "D10_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "These dinners keep happening at work and soon become a highlight for Shikhar. He also starts cooking regularly and starts putting his recipes on his social media account.",
              "hi": "काम पर ये रात्रिभोज होते रहते हैं और जल्द ही शिखर के लिए आकर्षण बन जाते हैं। वह नियमित रूप से खाना बनाना शुरु कर देता है और व्यंजनों को अपने सोशल मीडिया अकाउंट में डालने लगता  है।",
              "ka": "ಈ ಔತಣಕೂಟಗಳು ಕೆಲಸದಲ್ಲಿ ನಡೆಯುತ್ತಲೇ ಇರುತ್ತವೆ ಮತ್ತು ಶೀಘ್ರದಲ್ಲೇ ಶಿಖರ್‌ಗೆ ಹೈಲೈಟ್ ಆಗುತ್ತವೆ. ಅವನು ನಿಯಮಿತವಾಗಿ ಅಡುಗೆ ಮಾಡಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ ಮತ್ತು ಅವನ ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಖಾತೆಯಲ್ಲಿ ಅವನ ಪಾಕವಿಧಾನಗಳನ್ನು ಹಾಕಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ.",
              "te": "ఈ విందులు పనిలో జరుగుతూనే ఉంటాయి మరియు త్వరలో శిఖర్‌ కి హైలైట్‌గా మారతాయి. అతను క్రమం తప్పకుండా వంట చేయడం ప్రారంభించాడు మరియు తన వంటకాలను తన సోషల్ మీడియా ఖాతాలో ఉంచడం ప్రారంభిస్తాడు.",
              "or": "ଏହି ରାତ୍ରୀ ଭୋଜନ କାମ ସମୟରେ ଘଟେ ଏବଂ ଶୀଘ୍ର ଶିଖରଙ୍କ ପାଇଁ ଏକ ମୁଖ୍ୟ ଆକର୍ଷଣ ହୋଇଯାଏ | ସେ ନିୟମିତ ଭାବେ \n ରୋଷେଇ କରିବା ଆରମ୍ଭ କରନ୍ତି ଏବଂ ତାଙ୍କ ରେସିପିକୁ ସୋସିଆଲ ମିଡିଆ ଆକାଉଣ୍ଟରେ ରଖିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "এই ভোজবোৰ কামত হৈ থাকে আৰু সোনকালেই শিখৰৰ বাবে আকৰ্ষণীয় বিষয় হৈ পৰে। সি নিয়মীয়াকৈ ৰন্ধা আৰম্ভ কৰে আৰু তাৰ ৰেচিপিবোৰ তাৰ সামাজিক মাধ্যম একাউণ্টত ৰাখিবলৈ লয়।",
              "gu": "આ ડિનર કામ પર થતું રહે છે અને ટૂંક સમયમાં જ શિખર માટે હાઇલાઇટ બની જાય છે. તે નિયમિતપણે રસોઈ બનાવવાનું પણ શરૂ કરે છે અને તેની વાનગીઓ તેના સોશિયલ મીડિયા એકાઉન્ટ પર મૂકવાનું શરૂ કરે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D10_3_3",
              "hi": "D10_3_3",
              "ka": "D10_3_3",
              "te": "D10_3_3",
              "or": "D10_3_3",
              "as": "D10_3_3",
              "gu": "D10_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He is happy that he gets to work as a Financial Advisor and pursue his hobby on the side.",
              "hi": "वह खुश है कि वह एक वित्तीय सलाहकार के रूप में काम करता है और साथ ही अपने शौक को भी पूरा करता है।",
              "ka": "ಆರ್ಥಿಕ ಸಲಹೆಗಾರನಾಗಿ ಕೆಲಸ ಮಾಡಲು ಮತ್ತು ಬದಿಯಲ್ಲಿ ತನ್ನ ಹವ್ಯಾಸವನ್ನು ಮುಂದುವರಿಸಲು ಅವನು ಸಂತೋಷಪಡುತ್ತಾನೆ.",
              "te": "అతను ఫైనాన్షియల్ అడ్వైజర్ గా పని చేయడం మరియు ఒక పక్క తనకు ఇష్టమైనది చేయడం పట్ల అతను సంతోషంగా ఉన్నాడు.",
              "or": "ସେ ଖୁସି ଯେ ସେ ଆର୍ଥିକ ପରାମର୍ଶଦାତା ଭାବରେ କାର୍ଯ୍ୟ କରିବାକୁ ଯାଉଛନ୍ତି\n  ଏବଂ ପାର୍ଶ୍ୱରେ ନିଜର ହବିକୁ ଅନୁସରଣ କରୁଛନ୍ତି |",
              "as": "সি এজন বিত্তীয় উপদেষ্টা হিচাপে কাম কৰিবলৈ পায় সুখী আৰু লগতে তাৰ চখ অনুসৰণ কৰে।",
              "gu": "તે ખુશ છે કે તેને નાણાકીય સલાહકાર તરીકે કામ કરવા મળે છે અને તેના શોખને આગળ ધપાવે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "Financial Advisor",
              "hi": "वित्तीय सलाहकार",
              "ka": "ಹಣಕಾಸು ಸಲಹೆಗಾರ",
              "te": "ఫైనాన్షియల్ అడ్వైజర్",
              "or": "ଆର୍ଥିକ ପରାମର୍ଶଦାତା |",
              "as": "বিত্তীয় উপদেষ্টা",
              "gu": "નાણાંકીય સલાહકાર"
            }
          }
        ]
      },
      "scene31": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Discontinue Studies",
              "hi": "अध्ययन बंद करे ",
              "ka": "ಅಧ್ಯಯನವನ್ನು ನಿಲ್ಲಿಸುವುದು",
              "te": "చదువులను ఆపివేయడం",
              "or": "ଅଧ୍ୟୟନ ବନ୍ଦ କରନ୍ତୁ |",
              "as": "পঢ়া বন্ধ কৰা",
              "gu": "અભ્યાસ બંધ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar thinks to himself",
              "hi": "शिखर मन ही मन सोचता है",
              "ka": "ಶಿಖರ್ ತನ್ನಷ್ಟಕ್ಕೆ ಯೋಚಿಸುತ್ತಾನೆ",
              "te": "శిఖర్ తనలో తాను ఆలోచిస్తున్నాడు",
              "or": "ଶିଖର ନିଜେ ଭାବନ୍ତି |",
              "as": "শিখৰে নিজকে ভাবে",
              "gu": "શિખર પોતે જ વિચારે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I can’t let my father take a loan. The money they have saved for my education will be enough to manage the expenses on the farm. \n\nI don’t even want to study engineering. Let me work on the farm for a few years, and then I can decide what I want to do.",
              "hi": "मैं अपने पिता को ऋण लेने नहीं दे सकता। मेरी शिक्षा के लिए जो पैसा बचाया गया है वह खेत पर खर्चों का प्रबंधन करने के लिए पर्याप्त होगा।\n\nमैं इंजीनियरिंग का अध्ययन भी नहीं करना चाहता। मैं कुछ सालों तक खेत पर काम करता हूँ, और फिर मैं तय कर सकता हूं कि मैं क्या करना चाहता हूं।",
              "ka": "ನನ್ನ ತಂದೆಗೆ ಸಾಲ ತೆಗೆದುಕೊಳ್ಳಲು ನಾನು ಬಿಡಲಾರೆ. ನನ್ನ ವಿದ್ಯಾಭ್ಯಾಸಕ್ಕಾಗಿ ಅವರು ಉಳಿಸಿದ ಹಣ ಜಮೀನಿನ ಖರ್ಚು ವೆಚ್ಚಗಳಿಗೆ ಸಾಕಾಗುತ್ತದೆ. ನನಗೆ ಇಂಜಿನಿಯರಿಂಗ್ ಓದಲೂ ಇಷ್ಟವಿಲ್ಲ. ನಾನು ಕೆಲವು ವರ್ಷಗಳ ಕಾಲ ಜಮೀನಿನಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತೇನೆ, ಮತ್ತು ನಂತರ ನಾನು ಏನು ಮಾಡಬೇಕೆಂದು ನಿರ್ಧರಿಸಬಹುದು.",
              "te": "నేను మా నాన్నను అప్పు తీసుకోనివ్వలేను. నా చదువు కోసం వాళ్లు పొదుపు చేసిన డబ్బు పొలం ఖర్చులకే సరిపోతుంది.\n \n నాకు ఇంజినీరింగ్ చదవాలని కూడా లేదు. నన్ను కొన్నాళ్లు పొలంలో పని చేయనివ్వండి, ఆపై నేను ఏమి చేయాలో నిర్ణయించుకోవచ్చు.",
              "or": "ମୁଁ ମୋ ବାପାଙ୍କୁ ରୁଣ ନେବାକୁ ଦେଇ ପାରିବି ନାହିଁ। ମୋ ଶିକ୍ଷା ପାଇଁ ସେମାନେ ସଞ୍ଚୟ କରିଥିବା ଅର୍ଥ ଚାଷ ଜମିରେ ଖର୍ଚ୍ଚ ପରିଚାଳନା ପାଇଁ ଯଥେଷ୍ଟହେବ।\n ମୁଁ ଇଞ୍ଜିନିୟରିଂ ପଢିବାକୁ ମଧ୍ୟ ଚାହେଁ ନାହିଁ | ମୋତେ କିଛି ବର୍ଷ ଚାଷ ଜମିରେ କାମ କରିବାକୁ ଦିଅ, ଏବଂ ତା’ପରେ ମୁଁ କ’ଣ କରିବାକୁ ଚାହୁଁଛି ତାହା ସ୍ଥିର କରିପାରିବି। ”",
              "as": "মই মোৰ দেউতাক ঋণ ল'বলৈ দিব নোৱাৰো। তেওঁলোকে মোৰ শিক্ষাৰ বাবে জমা কৰা ধন খেতিপথাৰত খৰচ কৰিবলৈ যথেষ্ট হ'ব। \n \n \n মই আনকি ইঞ্জিনিয়াৰিং পঢ়িব নিবিচাৰো। মোক কেইবছৰমানৰ বাবে খেতিপথাৰত কাম কৰিবলৈ দিয়ক, আৰু তাৰ পিছত মই কি কৰিব বিচাৰো সেইটো ঠিক কৰিব পাৰো।",
              "gu": "હું મારા પિતાને લોન લેવા દેતો નથી. મારા શિક્ષણ માટે તેઓએ જે પૈસા બચાવ્યા છે તે ખેતરના ખર્ચનું સંચાલન કરવા માટે પૂરતા હશે. મારે એન્જિનિયરિંગ પણ ભણવું નથી. મને થોડા વર્ષો ખેતરમાં કામ કરવા દો, અને પછી હું નક્કી કરી શકું કે મારે શું કરવું છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_2_1",
              "hi": "B1_2_1",
              "ka": "B1_2_1",
              "te": "B1_2_1",
              "or": "B1_2_1",
              "as": "B1_2_1",
              "gu": "B1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Since Shikhar likes working on the farm, he wants to know more about farming. He starts watching different youtube videos to improve the processes at the farm.",
              "hi": "चूंकि शिखर खेत पर काम करना पसंद करता है, इसलिए वह खेती के बारे में और जानना चाहता है। वह खेत में प्रक्रियाओं को और अच्छा बनाने के लिए विभिन्न यूट्यूब वीडियो देखना शुरु कर देता है।",
              "ka": "ಶಿಖರ್‌ಗೆ ಫಾರ್ಮ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದು ಇಷ್ಟವಾದ ಕಾರಣ, ಅವನು ಕೃಷಿಯ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಬಯಸುತ್ತಾನೆ. ಫಾರ್ಮ್‌ನಲ್ಲಿನ ಪ್ರಕ್ರಿಯೆಗಳನ್ನು ಸುಧಾರಿಸಲು ಅವನು ವಿಭಿನ್ನ ಯೂಟ್ಯೂಬ್ ವೀಡಿಯೊಗಳನ್ನು ವೀಕ್ಷಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ.",
              "te": "శిఖర్‌ కి పొలంలో పని చేయడం ఇష్టం కాబట్టి, వ్యవసాయం గురించి మరింత తెలుసుకోవాలనుకుంటాడు. అతను వ్యవసాయంలో ప్రక్రియలను మెరుగుపరచడానికి వివిధ యూట్యూబ్ వీడియోలను చూడటం ప్రారంభించాడు.",
              "or": "ଯେହେତୁ ଶିଖର ଚାଷ ଜମିରେ କାମ କରିବାକୁ ପସନ୍ଦ କରନ୍ତି, ତେଣୁ ସେ \n ଚାଷ ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ଚାହାଁନ୍ତି | ସେ ଫାର୍ମରେ ପ୍ରକ୍ରିୟାଗୁଡ଼ିକର \n ଉନ୍ନତି ପାଇଁ ବିଭିନ୍ନ ୟୁଟ୍ୟୁବ୍ ଭିଡିଓ ଦେଖିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "যিহেতু শিখৰে খেতিপথাৰত কাম কৰিবলৈ ভাল পায়, সি খেতিৰ বিষয়ে অধিক জানিব বিচাৰে। সি ফাৰ্মত প্ৰক্ৰিয়াবোৰ উন্নত কৰিবলৈ বিভিন্ন ইউটিউব ভিডিঅ' চাবলৈ লয়।",
              "gu": "શિખરને ખેતરમાં કામ કરવાનું પસંદ હોવાથી તે ખેતી વિશે વધુ જાણવા માંગે છે. તે ખેતરમાં પ્રક્રિયાઓને સુધારવા માટે જુદા જુદા યુટ્યુબ વીડિયો જોવાનું શરૂ કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_2_2",
              "hi": "B1_2_2",
              "ka": "B1_2_2",
              "te": "B1_2_1",
              "or": "B1_2_2",
              "as": "B1_2_2",
              "gu": "B1_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He experiments with different techniques shown in the videos. He innovates some devices like automated sprayers using liquid pressure to make work easier.",
              "hi": "वह वीडियो में दिखाए गए विभिन्न तकनीकों के साथ प्रयोग करता है। वह काम को आसान बनाने के लिए तरल दबाव का उपयोग करके स्वचालित स्प्रेयर जैसे कुछ उपकरणों का नवाचार करता है।",
              "ka": "ಅವನು ವೀಡಿಯೊಗಳಲ್ಲಿ ತೋರಿಸಿರುವ ವಿವಿಧ ತಂತ್ರಗಳನ್ನು ಪ್ರಯೋಗಿಸುತ್ತಾನೆ. ಕೆಲಸವನ್ನು ಸುಲಭಗೊಳಿಸಲು ದ್ರವದ ಒತ್ತಡವನ್ನು ಬಳಸಿಕೊಂಡು ಸ್ವಯಂಚಾಲಿತ ಸ್ಪ್ರೇಯರ್‌ಗಳಂತಹ ಕೆಲವು ಸಾಧನಗಳನ್ನು ಅವನು ಆವಿಷ್ಕರಿಸುತ್ತಾನೆ.",
              "te": "అతను వీడియోలలో చూపిన విభిన్న పద్ధతులతో ప్రయోగాలు చేస్తాడు. అతను పనిని సులభతరం చేయడానికి ద్రవ ఒత్తిడిని ఉపయోగించి ఆటోమేటెడ్ స్ప్రేయర్ల వంటి కొన్ని పరికరాలను ఆవిష్కరించాడు.",
              "or": "ଭିଡିଓରେ ଦେଖାଯାଇଥିବା ବିଭିନ୍ନ କୌଶଳ ସହିତ ସେ ପରୀକ୍ଷଣ କରନ୍ତି \n | କାର୍ଯ୍ୟକୁ ସହଜ କରିବା ପାଇଁ ସେ ତରଳ ଚାପ ବ୍ୟବହାର କରି ସ୍ୱୟଂଚାଳିତ ସ୍ପ୍ରେ\n  ପରି କିଛି ଉପକରଣକୁ ଉଦ୍ଭାବନ କରନ୍ତି |",
              "as": "সি ভিডিঅ'বোৰত দেখুওৱা বিভিন্ন কৌশলৰ সৈতে পৰীক্ষা-নিৰীক্ষা কৰে। সি কাম সহজ কৰিবলৈ তৰল চাপ ব্যৱহাৰ কৰি স্বয়ংক্ৰিয় স্প্ৰেয়াৰৰ দৰে কিছুমান সঁজুলি উলিয়ায়।",
              "gu": "તે વિડીયોમાં દર્શાવેલ વિવિધ ટેકનિક સાથે પ્રયોગ કરે છે. તે કામને સરળ બનાવવા માટે પ્રવાહી દબાણનો ઉપયોગ કરીને સ્વચાલિત સ્પ્રેયર જેવા કેટલાક ઉપકરણોને નવીન બનાવે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_2_3 bg",
              "hi": "B1_2_3 बीजी",
              "ka": "B1_2_3 bg",
              "te": "B1_2_3 bg",
              "or": "B1_2_3 bg",
              "as": "B1_2_3 bg",
              "gu": "B1_2_3 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "His brother is pleased to see his interest in the farm and how he uses his curiosity to develop these interventions.",
              "hi": "उसका भाई खेत में उसकी रुचि और इन हस्तक्षेपों को विकसित करने के लिए वह अपनी जिज्ञासा का उपयोग कैसे करता है देखकर प्रसन्न है।",
              "ka": "ಅವನ ಸಹೋದರನು ಜಮೀನಿನಲ್ಲಿ ಅವನ ಆಸಕ್ತಿಯನ್ನು ಮತ್ತು ಈ ಆವಿಷ್ಕಾರಗಳನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಲು ಅವನು ತನ್ನ ಕುತೂಹಲವನ್ನು ಹೇಗೆ ಬಳಸುತ್ತಾನೆ ಎನ್ನುವುದನ್ನು ನೋಡಿ ಸಂತೋಷಪಡುತ್ತಾನೆ.",
              "te": "అతని సోదరుడు పొలం పట్ల అతని ఆసక్తిని మరియు ఈ ఆవిష్కరణలను అభివృద్ధి చేయడానికి అతను తన ఉత్సుకతను ఎలా ఉపయోగిస్తున్నాడో చూసి సంతోషిస్తున్నాడు.",
              "or": "ତାଙ୍କ ଭାଇ ଚାଷ ପ୍ରତି ତାଙ୍କର ଆଗ୍ରହ ଏବଂ ଏହି କାମର\n  ବିକାଶ ପାଇଁ ସେ କିପରି ନିଜର କୌତୁହଳକୁ ବ୍ୟବହାର କରନ୍ତି, ଦେଖି ଖୁସି ହୁଅନ୍ତି।",
              "as": "তাৰ ককায়েকে খেতিপথাৰৰ প্ৰতি তাৰ আগ্ৰহ দেখি আৰু সি কেনেদৰে এই ব্যৱধানবোৰ বিকাশ কৰিবলৈ তাৰ কৌতূহল ব্যৱহাৰ কৰে দেখি আনন্দিত হৈছে।",
              "gu": "તેનો ભાઈ ખેતરમાં તેની રુચિ જોઈને ખુશ થાય છે અને તે આ હસ્તક્ષેપો વિકસાવવા માટે તેની જિજ્ઞાસાઓ કેવી રીતે ઉપયોગ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Arun",
              "hi": "अरुण",
              "ka": "ಅರುಣ್",
              "te": "అరుణ్",
              "or": "ଅରୁଣ",
              "as": "অৰুণ",
              "gu": "અરુણ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Arun",
              "hi": "अरुण",
              "ka": "ಅರುಣ್",
              "te": "అరుణ్",
              "or": "ଅରୁଣ",
              "as": "অৰুণ",
              "gu": "અરુણ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Good job Shikhar, your jugaad sprayer has worked well. Half my time used to go in pouring pesticide on the leaves.",
              "hi": "बहुत बढ़िया शिखर, आपके जुगाड स्प्रेयर ने अच्छा काम किया है। मेरा आधा समय पत्तियों पर कीटनाशक डालने में जाता था।",
              "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ ಶಿಖರ್, ನಿನ್ನ ಜುಗಾಡ್ ಸ್ಪ್ರೇಯರ್ ಚೆನ್ನಾಗಿ ಕೆಲಸ ಮಾಡಿದೆ. ನನ್ನ ಅರ್ಧದಷ್ಟು ಸಮಯ ಎಲೆಗಳಿಗೆ ಕೀಟನಾಶಕವನ್ನು ಸುರಿಯುವುದರಲ್ಲಿಯೇ ಹೋಗುತ್ತಿತ್ತು.",
              "te": "బాగుంది శిఖర్, మీ జుగాడ్ స్ప్రేయర్ బాగా పని చేసింది. నా సగం సమయం ఆకులపై క్రిమిసంహారక మందు పోయడానికే సరిపోయేది.",
              "or": "ଭଲ କାମ ଶିଖର, ତୁମର ଯୁଗାଡୁ ସ୍ପ୍ରେ ଭଲ କାମ କରିଛି | ମୋର ଅଧା ସମୟ ପତ୍ରରେ କୀଟନାଶକ ଔଷଧ ପକେଇବାରେ ଯାଉଥିଲା |",
              "as": "ভাল কাম শিখৰ, তোমাৰ জুগাদ স্প্ৰেয়াৰে ভালদৰে কাম কৰিছে। মোৰ আধা সময় পাতত কীটনাশক ধালোতেই গৈছিল।",
              "gu": "સરસ કામ શિખર, તારું જુગાડ સ્પ્રેયર સારું કામ કર્યું છે. મારો અડધો સમય પાંદડા પર જંતુનાશક રેડવામાં પસાર થતો હતો."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Bhaiya, this is nothing. There are so many do-it-yourself videos to learn from. The internet is full of wonderful ideas!",
              "hi": "भैया, यह कुछ भी नहीं है। सीखने के लिए बहुत सारे डू इट योरसेल्फ वीडियो हैं। इंटरनेट अद्भुत उपायों से भरा है!",
              "ka": "ಭೈಯ್ಯಾ, ಇದು ಏನೂ ಅಲ್ಲ. ಕಲಿಯಲು ಹಲವಾರು ನೀವೇ-ಮಾಡಿ ವೀಡಿಯೊಗಳಿವೆ. ಇಂಟರ್ನೆಟ್ ಅದ್ಭುತ ವಿಚಾರಗಳಿಂದ ತುಂಬಿದೆ!",
              "te": "అన్నయ్య, ఇదేమీ కాదు. నేర్చుకోవడానికి చాలా మీరే స్వయంగా చేయండి వీడియోలు ఉన్నాయి. ఇంటర్నెట్ అద్భుతమైన ఆలోచనలతో నిండి ఉంది!",
              "or": "ଭାଇ ଏହା କିଛି ନୁହେଁ | ଏଥିରୁ ଶିଖିବାକୁ ଅନେକଗୁଡ଼ିଏ ନିଜେ ଭିଡିଓ ଅଛି |\n  ଇଣ୍ଟରନେଟ୍ ଚମତ୍କାର କଳ୍ପନାରେ ପରିପୂର୍ଣ୍ଣ!",
              "as": "দাদা, এয়া একো নহয়। শিকিবলৈ বহুতো নিজে কৰাৰ ভিডিঅ' আছে। ইণ্টাৰনেট আচৰিত ধাৰণাৰে ভৰি আছে!",
              "gu": "ભાઈ, આ કંઈ નથી. શીખવા માટે ઘણા બધા જાતે બનાવેલા વીડિયો જોવે છે. ઇન્ટરનેટ અદ્ભુત વિચારોથી ભરેલું છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Yes, me and Babuji are so busy cutting the harvest on time. We are never able to use the newer methods to work.",
              "hi": "हां, मैं और बाबूजी समय पर फसल काटने में व्यस्त हैं। हम कभी भी काम करने के लिए नए तरीकों का उपयोग करने में सक्षम नहीं हैं।",
              "ka": "ಹೌದು, ನಾನು ಮತ್ತು ಬಾಬೂಜಿ ಸಮಯಕ್ಕೆ ಸರಿಯಾಗಿ ಕಟಾವು ಮಾಡುವುದರಲ್ಲಿ ನಿರತರಾಗಿದ್ದೇವೆ. ಕೆಲಸ ಮಾಡಲು ನಾವು ಎಂದಿಗೂ ಹೊಸ ವಿಧಾನಗಳನ್ನು ಬಳಸಲು ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ.",
              "te": "అవును, నేనూ, నాన్నగారు కూడా సమయానికి పంట కోయడంలో చాలా బిజీగా ఉన్నాం. పని చేయడానికి మేము ఎప్పుడూ కొత్త పద్ధతులను ఉపయోగించలేము.",
              "or": "ହଁ, ମୁଁ ଏବଂ ବାବୁଜୀ ଠିକ୍ ସମୟରେ ଅମଳ କାଟିବାରେ ବ୍ୟସ୍ତ | ଆମେ କାର୍ଯ୍ୟ\n କରିବା ପାଇଁ ନୂତନ ପଦ୍ଧତି ବ୍ୟବହାର କରିବାକୁ କେବେବି ସକ୍ଷମ ନୁହଁ |",
              "as": "হয়, মই আৰু দেউতা সময়মতে শস্য চপোৱাত লাগি আছোঁ। আমি কেতিয়াও কাম কৰিবলৈ নতুন উপায় ব্যৱহাৰ কৰিবলৈ সক্ষম নহয়।",
              "gu": "હા, હું અને બાબુજી સમયસર કાપણી કરવામાં વ્યસ્ત છીએ. આપણે કામ કરવા માટે ક્યારેય નવી પદ્ધતિઓનો ઉપયોગ કરી શકતા નથી."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_2_4///",
              "hi": "B1_2_4 ///",
              "ka": "B1_2_4///",
              "te": "B1_2_4///",
              "or": "B1_2_4///",
              "as": "B1_2_4///",
              "gu": "B1_2_4///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Yes, and these are some simple inventions to make life easier. Imagine the kind of farming technologies that exist out there. You would not have to use your hands at all, Bhaiya!",
              "hi": "हां, और ये जीवन को आसान बनाने के लिए कुछ सरल आविष्कार हैं। जिस तरह की भी  खेती तकनीकों की कल्पना करें वो वहां मौजूद हैं। आपको अपने हाथों का बिलकुल उपयोग नहीं करना पड़ेगा, भैया!",
              "ka": "ಹೌದು, ಮತ್ತು ಇವುಗಳು ಜೀವನವನ್ನು ಸುಲಭಗೊಳಿಸುವ ಕೆಲವು ಸರಳ ಆವಿಷ್ಕಾರಗಳಾಗಿವೆ. ಅಲ್ಲಿ ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಕೃಷಿ ತಂತ್ರಜ್ಞಾನಗಳ ರೀತಿಯ ಬಗ್ಗೆ ಸ್ವಲ್ಪ ಯೋಚಿಸಿ. ನೀವು ನಿಮ್ಮ ಕೈಗಳನ್ನು ಬಳಸಬೇಕಾಗಿಲ್ಲ, ಭೈಯ್ಯಾ!",
              "te": "అవును, మరియు ఇవి జీవితాన్ని సులభతరం చేయడానికి కొన్ని సాధారణ ఆవిష్కరణలు. అక్కడ ఉన్న వ్యవసాయ సాంకేతికతలను ఊహించుకోండి. మీరు మీ చేతులను అస్సలు ఉపయోగించాల్సిన అవసరం లేదు, అన్నయ్య!",
              "or": "ହଁ, ଏବଂ ଜୀବନକୁ ସହଜ କରିବା ପାଇଁ ଏଗୁଡ଼ିକ କିଛି ସରଳ ଉଦ୍ଭାବନ | ସେଠାରେ କେଉଁ ପ୍ରକାରର କୃଷି ପ୍ରଯୁକ୍ତିବିଦ୍ୟା କଳ୍ପନା କର | ତୁମ ହାତକୁ ଆଦୌ ବ୍ୟବହାର କରିବାକୁ ପଡିବ ନାହିଁ, ଭାଇ !",
              "as": "হয়, আৰু জীৱন সহজ কৰাৰ বাবে এইবোৰ কিছুমান সৰল আৱিষ্কাৰ। ভাবাচোন তাত কেনে ধৰণৰ কৃষি প্ৰযুক্তি আছে। তুমি তোমাৰ হাতো ব্যৱহাৰ কৰিব নালাগে, দাদা!",
              "gu": "હા, અને જીવનને સરળ બનાવવા માટે આ કેટલીક સરળ શોધો છે. ત્યાં અસ્તિત્વમાં છે તે પ્રકારની ખેતી તકનીકોની કલ્પના કરો. તારે હાથ બિલકુલ વાપરવો નહિ પડે, ભાઈ!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "There’s a Krishi Mela that happens around this time every year in Jabalpur. Why don’t you go there this year to learn about the latest farming technologies?",
              "hi": "एक कृषि मेला है जो हर साल इस समय जबलपुर में  होता है। तुम इस वर्ष नवीनतम खेती की तकनीकों के बारे में जानने के लिए वहां क्यों नहीं जाते?",
              "ka": "ಜಬಲ್ಪುರದಲ್ಲಿ ಪ್ರತಿ ವರ್ಷ ಇದೇ ಸಮಯದಲ್ಲಿ ನಡೆಯುವ ಕೃಷಿ ಮೇಳವಿದೆ. ಇತ್ತೀಚಿನ ಕೃಷಿ ತಂತ್ರಜ್ಞಾನಗಳ ಬಗ್ಗೆ ತಿಳಿಯಲು ನೀನು ಈ ವರ್ಷ ಅಲ್ಲಿಗೆ ಏಕೆ ಹೋಗಬಾರದು?",
              "te": "జబల్‌పూర్లో ప్రతి సంవత్సరం ఈ సమయంలో జరిగే కృషి మేళా ఉంది. అత్యాధునిక వ్యవసాయ సాంకేతికతలను తెలుసుకోవడానికి నువ్వు ఈ సంవత్సరం ఎందుకు అక్కడికి వెళ్లకూడదు?",
              "or": "ସେଠାରେ ଏକ କୃଷି ମେଲା ଅଛି ଯାହା ଜବଲପୁରରେ ପ୍ରତିବର୍ଷ ଏହି ସମୟରେ ହୋଇଥାଏ |\n  ଅତ୍ୟାଧୁନିକ କୃଷି ପ୍ରଯୁକ୍ତିବିଦ୍ୟା ବିଷୟରେ ଜାଣିବା ପାଇଁ ଆପଣ କାହିଁକି ଏ ବର୍ଷ ସେଠାକୁ ଯାଉନାହାନ୍ତି?",
              "as": "জবলপুৰত প্ৰতি বছৰে এই সময়ত এখন কৃষি মেলা হয়। শেহতীয়া কৃষি প্ৰযুক্তিৰ বিষয়ে জানিবলৈ তুমি এই বছৰ তালৈ নোযোৱা কিয়?",
              "gu": "દર વર્ષે આ સમયે જબલપુરમાં કૃષિ મેળો ભરાય છે. ખેતીની નવીનતમ તકનીકો વિશે જાણવા માટે તમે આ વર્ષે શા માટે ત્યાં નથી જતા?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Jabalpur?! Would Babuji let me go??",
              "hi": "जबलपुर?! क्या बाबूजी मुझे जाने देंगे??",
              "ka": "ಜಬಲ್ಪುರ?! ಬಾಬೂಜಿ ನನ್ನನ್ನು ಹೋಗಲು ಬಿಡುತ್ತಾರಾ??",
              "te": "జబల్పూర్?! నాన్నగారు నన్ను వెళ్లనిస్తారా??",
              "or": "ଜବଲପୁର ?! ବାବୁଜୀ ମୋତେ ଯିବାକୁ ଦେବେ କି ??",
              "as": "জবলপুৰ?! দেউতাই মোক যাবলৈ দিব জানো??",
              "gu": "જબલપુર ?! બાબુજી મને જવા દેશે??"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Yes, why not? I will convince him. But take someone along with you. Why don’t you ask Devansh?",
              "hi": "हाँ क्यों नहीं? मैं उन्हें समझाऊंगा। लेकिन किसी को अपने साथ ले जाओ। तुम देवांश से क्यों नहीं पूछते?",
              "ka": "ಆಯ್ತು ಯಾಕಾಗಬಾರದು? ನಾನು ಅವರಿಗೆ ಮನವರಿಕೆ ಮಾಡುತ್ತೇನೆ. ಆದರೆ ನಿನ್ನೊಂದಿಗೆ ಯಾರನ್ನಾದರೂ ಕರೆದುಕೊಂಡು ಹೋಗು. ದೇವಾಂಶ್ ನನ್ನು ಯಾಕೆ ಕೇಳಬಾರದು?",
              "te": "అవును! ఎందుకు వెళ్లనివ్వరు? నేను అతనిని ఒప్పిస్తాను. కానీ నీతో పాటు ఎవరినైనా తీసుకెళ్లు. దేవాన్ష్ ని ఎందుకు అడగకూడదు?",
              "or": "ହଁ, କାହିଁକି ନୁହେଁ? ମୁଁ ତାଙ୍କୁ ବିଶ୍ୱାସ କରାଈବି କିନ୍ତୁ କାହାକୁ ସାଙ୍ଗରେ ନେଇଯାଅ |\n  ଆପଣ କାହିଁକି ଦେବାଂଶ କୁ ପଚାରୁ ନାହାନ୍ତି ?",
              "as": "দিব, কিয় নিদিব? মই তেওঁক সৈমান কৰিম। কিন্তু তোমাৰ লগত কাৰোবাক লৈ যোৱা। তুমি দেৱাংশক কিয় সোধা নাই?",
              "gu": "હા, કેમ નહિ? હું તેને મનાવીશ. પણ તારી સાથે કોઈને લઈ જા. તું દેવાંશને કેમ પૂછતા નથી?"
            }
          }
        ]
      },
      "scene38": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_1",
              "hi": "B2_1_1",
              "ka": "B2_1_1",
              "te": "B2_1_1",
              "or": "B2_1_1",
              "as": "B2_1_1",
              "gu": "B2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "A few weeks later, Shikhar and Devansh travel to Jabalpur to attend the Krishi Mela.",
              "hi": "कुछ सप्ताह बाद, शिखर और देवांश कृषि मेले में भाग लेने के लिए जबलपुर जाते हैं।",
              "ka": "ಕೆಲವು ವಾರಗಳ ನಂತರ, ಶಿಖರ್ ಮತ್ತು ದೇವಾಂಶ್ ಕೃಷಿ ಮೇಳದಲ್ಲಿ ಪಾಲ್ಗೊಳ್ಳಲು ಜಬಲ್ಪುರಕ್ಕೆ ಪ್ರಯಾಣಿಸುತ್ತಾರೆ.",
              "te": "కొన్ని వారాల తర్వాత, శిఖర్ మరియు దేవాన్ష్ కృషి మేళాకు హాజరయ్యేందుకు జబల్పూర్ వెళతారు.",
              "or": "କିଛି ସପ୍ତାହ ପରେ, ଶିଖର ଏବଂ ଦେବାଂଶ କୃଷି ମେଲାରେ ଯୋଗଦେବା ପାଇଁ ଜବଲପୁର ଯାତ୍ରା କରନ୍ତି |",
              "as": "কেইসপ্তাহমানৰ পিছত, শিখৰ আৰু দেৱাংশে কৃষি মেলাত ভাগ লবলৈ জবলপুৰলৈ যায়।",
              "gu": "થોડા અઠવાડિયા પછી, શિખર અને દેવાંશ કૃષિ મેળામાં હાજરી આપવા જબલપુર જાય છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_2",
              "hi": "B2_1_2",
              "ka": "B2_1_2",
              "te": "B2_1_2",
              "or": "B2_1_2",
              "as": "B2_1_2",
              "gu": "B2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar has read about the event details in a flyer that he got at the Panchayat office.",
              "hi": "शिखर ने मेले के विवरण के बारे में पंचायत कार्यालय में मिले एक पर्चे में पढ़ा है।",
              "ka": "ಶಿಖರ್ ತಾನು ಪಂಚಾಯತ್ ಕಛೇರಿಯಲ್ಲಿ ಪಡೆದ ಫ್ಲೈಯರ್‌ನಲ್ಲಿ ಕಾರ್ಯಕ್ರಮದ ವಿವರಗಳನ್ನು ಓದಿದ್ದಾನೆ.",
              "te": "పంచాయితీ కార్యాలయంలో తనకు లభించిన ఫ్లైయర్‌లో ఈవెంట్ వివరాలను శిఖర్ చదివాడు.",
              "or": "ଶିଖର ପଞ୍ଚାୟତ କାର୍ଯ୍ୟାଳୟରେ ପାଇଥିବା ଫ୍ଲାଏୟାରରେ ଘଟଣାର ବିବରଣୀ\n  ବିଷୟରେ ପଢୁଛନ୍ତି |",
              "as": "শিখৰে পঞ্চায়ত অফিচত পোৱা প্ৰচাৰপত্ৰত অনুষ্ঠানটোৰ বিৱৰণ পঢ়িছে।",
              "gu": "શિખરે ઘટનાની વિગતો એક પત્રિકામાં વાંચી છે જે તેને પંચાયત ઓફિસમાંથી મળી છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_3",
              "hi": "B2_1_3",
              "ka": "B2_1_3",
              "te": "B2_1_3",
              "or": "B2_1_3",
              "as": "B2_1_3",
              "gu": "B2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "From the flyer, he learns about the speakers who will come, the stalls that different organisations will put up, and the demonstrations that will take place.",
              "hi": "पर्चे से, उसे उन वक्ताओं के बारे में पता चलता है जो आएंगे, स्टाल जो अलग-अलग संगठन रखेंगे, और जो प्रदर्शन  होंगे।",
              "ka": "ಫ್ಲೈಯರ್‌ನಿಂದ ಅವನು ಅಲ್ಲಿಗೆ ಬರುವ ಭಾಷಣಕಾರರು, ವಿವಿಧ ಸಂಸ್ಥೆಗಳು ಹಾಕುವ ಸ್ಟಾಲ್‌ಗಳು ಮತ್ತು ನಡೆಯುವ ಪ್ರಾತ್ಯಕ್ಷಿಕೆಗಳ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "ఫ్లైయర్ నుండి, అతను వచ్చే స్పీకర్ల గురించి, వివిధ సంస్థలు పెట్టే స్టాల్స్ మరియు జరిగే ప్రదర్శనల గురించి తెలుసుకుంటాడు.",
              "or": "ଫ୍ଲାଏୟାରରୁ ସେ ଆସୁଥିବା ବକ୍ତାମାନଙ୍କ ବିଷୟରେ, ବିଭିନ୍ନ ସଂଗଠନ ସ୍ଥାପନ\n  କରୁଥିବା ଷ୍ଟଲଗୁଡିକ ଏବଂ ପ୍ରଦର୍ଶନଗୁଡିକ ବିଷୟରେ ଜାଣନ୍ତି |",
              "as": "প্ৰচাৰপত্ৰৰ পৰা, সি আহিবলগীয়া বক্তা, বিভিন্ন সংগঠনে লগাব লগা দোকান, আৰু অনুষ্ঠিত হ'বলগীয়া প্ৰদৰ্শনৰ বিষয়ে শিকে।",
              "gu": "ફ્લાયરમાંથી, તે આવનારા સ્પીકર્સ, વિવિધ સંસ્થાઓ જે સ્ટોલ મૂકશે અને જે પ્રદર્શનો થશે તે વિશે તે શીખે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_4",
              "hi": "B2_1_4",
              "ka": "B2_1_4",
              "te": "B2_1_4",
              "or": "B2_1_4",
              "as": "B2_1_4",
              "gu": "B2_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "While they are on their way to the bus, Shikhar and Devansh make a list of things they are interested in to make the most of their time at the event.",
              "hi": "जब वे अपने रास्ते पर बस लेने के लिए जा रहे हैं, शिखर और देवांश कार्यक्रम में अपने समय का सदुपयोग करने के लिए उन चीजों की एक सूची बनाते हैं, जिसमें वे रुचि रखते हैं ।",
              "ka": "ಅವರು ಬಸ್‌ಗೆ ಹೋಗುತ್ತಿರುವಾಗ, ಶಿಖರ್ ಮತ್ತು ದೇವಾಂಶ್ ಅವರು ಈವೆಂಟ್‌ನಲ್ಲಿ ತಮ್ಮ ಸಮಯವನ್ನು ಹೆಚ್ಚು ಮಾಡಲು ಆಸಕ್ತಿ ಹೊಂದಿರುವ ವಿಷಯಗಳ ಪಟ್ಟಿಯನ್ನು ಮಾಡುತ್ತಾರೆ.",
              "te": "వారు బస్సులో వెళుతుండగా, శిఖర్ మరియు దేవాన్ష్ ఈవెంట్‌లో తమ సమయాన్ని సద్వినియోగం చేసుకోవడానికి ఆసక్తి ఉన్న విషయాల జాబితాను రూపొందించారు.",
              "or": "ସେମାନେ ବସ୍‌କୁ ଯିବାବେଳେ ଶିଖର ଏବଂ ଦେବାଂଶ ଇଭେଣ୍ଟରେ ସେମାନଙ୍କର ସମୟକୁ ଅଧିକ ଉପଯୋଗ କରିବାରେ ଆଗ୍ରହୀ ଥିବା ଏକ ତାଲିକା ପ୍ରସ୍ତୁତ କରନ୍ତି |",
              "as": "যেতিয়া সিহঁতে বাছৰ ফালে যায়, শিখৰ আৰু দেৱাংশে অনুষ্ঠানটোত সিহঁতৰ সময়ৰ সৰ্বাধিক সদ্ব্যৱহাৰ কৰিবলৈ আগ্ৰহী বস্তুবোৰৰ এখন তালিকা বনায়।",
              "gu": "જ્યારે તેઓ બસમાં જતા હોય ત્યારે, શિખર અને દેવાંશ ઇવેન્ટમાં તેમના સમયનો મહત્તમ ઉપયોગ કરવા માટે તેમને રસ હોય તેવી વસ્તુઓની યાદી બનાવે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_5 checklist",
              "hi": "B2_1_5 चेकलिस्ट",
              "ka": "B2_1_5 checklist",
              "te": "B2_1_5 చెక్‌ జాబితా",
              "or": "B2_1_5 checklist",
              "as": "B2_1_5 checklist",
              "gu": "B2_1_5 યાદી તપાસો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar and Devansh reach the Mela and are excited to see many stalls. Shikhar takes a look at the list of the things they has planned to do -",
              "hi": "शिखर और देवांश मेले में पहुंचते हैं और अनेक स्टाल देखने के लिए उत्साहित हैं। शिखर उन कार्यों की सूची को देखता है जिसे  पूरा करने की योजना उन्होंने बनाई है -",
              "ka": "ಶಿಖರ್ ಮತ್ತು ದೇವಾಂಶ್ ಮೇಳವನ್ನು ತಲುಪುತ್ತಾರೆ ಮತ್ತು ಅನೇಕ ಮಳಿಗೆಗಳನ್ನು ನೋಡಲು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ. ಶಿಖರ್ ಅವರು ಮಾಡಲು ಯೋಜಿಸಿರುವ ವಿಷಯಗಳ ಪಟ್ಟಿಯನ್ನು ನೋಡುತ್ತಾನೆ -",
              "te": "శిఖర్ మరియు దేవాన్ష్ మేళాకు చేరుకున్నారు మరియు అనేక స్టాల్స్ ను చూస్తూ చాలా ఉత్సాహంగా ఉన్నారు. శిఖర్ వారు చేయాలనుకున్న పనుల జాబితాను పరిశీలించారు -",
              "or": "ଶିଖର ଏବଂ ଦେବାଂଶ ମେଳା ପାଖରେ ପହଞ୍ଚିଛନ୍ତି ଏବଂ ଅନେକ ଷ୍ଟଲ୍ ଦେଖି ଉତ୍ସାହିତ ଅଛନ୍ତି | ଶିଖର ସେମାନେ କରିବାକୁ ଯୋଜନା କରିଥିବା ଜିନିଷଗୁଡ଼ିକର ତାଲିକା ଉପରେ ନଜର ପକାନ୍ତି -",
              "as": "শিখৰ আৰু দেৱাংশ মেলাত উপস্থিত হয় আৰু বহুতো দোকান দেখি উৎসাহিত হয়। শিখৰে সিহঁতে কৰিব বিচৰা কামবোৰৰ তালিকাখন চায় -",
              "gu": "શિખર અને દેવાંશ મેળામાં પહોંચે છે અને ઘણી દુકાનો જોઈને ઉત્સાહિત છે. શિખરે જે વસ્તુઓ કરવાની યોજના બનાવી છે તેની યાદી પર એક નજર નાખે છે -"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Attend the seminar on agricultural science",
              "hi": "कृषि विज्ञान पर संगोष्ठी में भाग ले",
              "ka": "ಕೃಷಿ ವಿಜ್ಞಾನ ವಿಚಾರ ಸಂಕಿರಣದಲ್ಲಿ ಭಾಗವಹಿಸುವು",
              "te": "వ్యవసాయ శాస్త్రంపై సెమినార్‌ కు హాజరవడం",
              "or": "କୃଷି ବିଜ୍ଞାନ ଉପରେ ସେମିନାରରେ ଯୋଗ ଦେବା |",
              "as": "কৃষি বিজ্ঞানৰ ওপৰত চেমিনাৰত উপস্থিত থাকিব",
              "gu": "કૃષિ વિજ્ઞાન પર સેમિનારમાં હાજરી આપો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Go with Devansh to explore the various stalls",
              "hi": "विभिन्न स्टालों का पता लगाने के लिए देवांश के साथ जाए",
              "ka": "ವಿವಿಧ ಮಳಿಗೆಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ದೇವಾಂಶ್ ಜೊತೆ ಹೋಗುವುದು",
              "te": "వివిధ స్టాల్స్ ను చూడడానికి దేవాన్ష్ తో కలిసి వెళ్లడం",
              "or": "ବିଭିନ୍ନ ଷ୍ଟଲଗୁଡିକୁ ଅନୁସନ୍ଧାନ କରିବା ପାଇଁ ଦେବାଂଶ ସହିତ ଯିବା |",
              "as": "দেৱাংশৰ সৈতে বিভিন্ন দোকানবোৰ চাবলৈ যাব",
              "gu": "વિવિધ દુકાનો જોવા દેવાંશ સાથે જાઓ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Demonstration of the new farm technology",
              "hi": "नई कृषि तकनीक का प्रदर्शन",
              "ka": "ಹೊಸ ಕೃಷಿ ತಂತ್ರಜ್ಞಾನದ ಪ್ರಾತ್ಯಕ್ಷಿಕೆ",
              "te": "కొత్త వ్యవసాయ సాంకేతికత యొక్క ప్రదర్శన",
              "or": "ନୂତନ ଚାଷ ପ୍ରଯୁକ୍ତିର ପ୍ରଦର୍ଶନ |",
              "as": "নতুন ফাৰ্ম প্ৰযুক্তিৰ প্ৰদৰ্শন",
              "gu": "નવી ખેતીની ટેકનોલોજીનું પ્રદર્શન"
            }
          }
        ]
      },
      "scene39": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_i_1_1",
              "hi": "B2_i_1_1",
              "ka": "B2_i_1_1",
              "te": "B2_i_1_1",
              "or": "B2_i_1_1",
              "as": "B2_i_1_1",
              "gu": "B2_i_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar decides to attend a seminar on Agricultural Science.",
              "hi": "शिखर कृषि विज्ञान पर एक संगोष्ठी में भाग लेने का निश्चय करता है।",
              "ka": "ಶಿಖರ್ ಕೃಷಿ ವಿಜ್ಞಾನದ ಸೆಮಿನಾರ್‌ಗೆ ಹಾಜರಾಗಲು ನಿರ್ಧರಿಸಿದ್ದಾನೆ",
              "te": "శిఖర్ వ్యవసాయ శాస్త్రం. సెమినార్‌ కు హాజరు కావాలని నిర్ణయించుకున్నాడు.",
              "or": "କୃଷି ବିଜ୍ଞାନ ଉପରେ ଏକ ସେମିନାରରେ ଯୋଗଦେବାକୁ ଶିଖର ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି।",
              "as": "শিখৰে কৃষি বিজ্ঞানৰ ওপৰত এখন চেমিনাৰত ভাগ লোৱাৰ সিদ্ধান্ত লয়।",
              "gu": "શિખરે કૃષિ વિજ્ઞાન પરના સેમિનારમાં ભાગ લેવાનું નક્કી કર્યું."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_i_1_2",
              "hi": "B2_I_1_2",
              "ka": "B2_i_1_2",
              "te": "B2_i_1_2",
              "or": "B2_i_1_2",
              "as": "B2_i_1_2",
              "gu": "B2_i_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Professor Nag is conducting the seminar. He learns that she uses a mix of new and traditional technologies in her practice. She shares her research with them.",
              "hi": "प्रोफेसर नाग सेमिनार का संचालन कर रही हैं। वह सीखता है कि वह अपनी कार्यप्रणाली में नई और पारंपरिक तकनीकों के मिश्रण का उपयोग करती है। वह उनके साथ अपने शोध को साझा करती हैं।",
              "ka": "ಪ್ರೊಫೆಸರ್ ನಾಗ್ ವಿಚಾರ ಸಂಕಿರಣ ನಡೆಸುತ್ತಿದ್ದಾರೆ. ಆಕೆಯು ತನ್ನ ಅಭ್ಯಾಸದಲ್ಲಿ ಹೊಸ ಮತ್ತು ಸಾಂಪ್ರದಾಯಿಕ ತಂತ್ರಜ್ಞಾನಗಳ ಮಿಶ್ರಣವನ್ನು ಬಳಸುತ್ತಾಳೆ ಎಂದು ಅವನಿಗೆ ತಿಳಿಯುತ್ತದೆ. ಆಕೆಯು ತಮ್ಮ ಸಂಶೋಧನೆಯನ್ನು ಅವರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "ప్రొఫెసర్ నాగ్ సెమినార్ నిర్వహిస్తున్నారు. ఆమె తన ఆచరణలో కొత్త మరియు సాంప్రదాయ సాంకేతికతల మిశ్రమాన్ని ఉపయోగిస్తుందని అతను తెలుసుకున్నాడు. ఆమె తన పరిశోధనలను వారితో పంచుకుంటుంది.",
              "or": "ପ୍ରଫେସର ନାଗ ସେମିନାର ପରିଚାଳନା କରୁଛନ୍ତି। ସେ ଶିଖିଲେ ଯେ ସେ ତାଙ୍କ ଅଭ୍ୟାସରେ ନୂତନ ଏବଂ ପାରମ୍ପାରିକ ଟେକ୍ନୋଲୋଜିର ମିଶ୍ରଣ ବ୍ୟବହାର କରନ୍ତି | ସେ ସେମାନଙ୍କ ସହିତ ଗବେଷଣା କରନ୍ତି |",
              "as": "অধ্যাপক নাগে চেমিনাৰ পৰিচালনা কৰি আছে। সি শিকিলে যে তাই তাইৰ অনুশীলনত নতুন আৰু পৰম্পৰাগত প্ৰযুক্তিৰ মিশ্ৰণ ব্যৱহাৰ কৰে। তাই তাইৰ গৱেষণা তেওঁলোকৰ সৈতে ভাগ-বতৰা কৰে।",
              "gu": "સેમિનારનું સંચાલન પ્રોફેસર નાગ કરી રહ્યા છે. તે શીખે છે કે તે તેની પ્રેક્ટિસમાં નવી અને પરંપરાગત તકનીકોના મિશ્રણનો ઉપયોગ કરે છે. તે તેમની સાથે તેમના સંશોધન બતાવે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_i_2_1 bg",
              "hi": "B2_I_2_1 बीजी",
              "ka": "B2_i_2_1 bg",
              "te": "B2_i_2_1 bg",
              "or": "B2_i_2_1 bg",
              "as": "B2_i_2_1 bg",
              "gu": "B2_i_2_1 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "After the seminar, Shikhar goes to Professor Nag to talk to her.",
              "hi": "संगोष्ठी के बाद, शिखर बात करने के लिए प्रोफेसर नाग के पास जाता है।",
              "ka": "ಸೆಮಿನಾರ್ ಮುಗಿದ ನಂತರ, ಶಿಖರ್ ಪ್ರೊಫೆಸರ್ ನಾಗ್ ಅವರ ಬಳಿಗೆ ಹೋಗಿ ಮಾತನಾಡುತ್ತಾನೆ.",
              "te": "సెమినార్ తర్వాత, శిఖర్ ఆమెతో మాట్లాడటానికి ప్రొఫెసర్ నాగ్ వద్దకు వెళ్తాడు.",
              "or": "ସେମିନାର ପରେ ଶିଖର ତାଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବାକୁ ପ୍ରଫେସର ନାଗଙ୍କ \n ନିକଟକୁ ଯାଆନ୍ତି |",
              "as": "চেমিনাৰৰ পিছত, শিখৰে তাইৰ সৈতে কথা পাতিবলৈ অধ্যাপক নাগৰ ওচৰলৈ যায়।",
              "gu": "સેમિનાર પછી શિખર પ્રોફેસર નાગ પાસે તેની સાથે વાત કરવા જાય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Professor Nag",
              "hi": "प्रोफेसर नाग",
              "ka": "ಪ್ರೊಫೆಸರ್ ನಾಗ್",
              "te": "ప్రొఫెసర్ నాగ్",
              "or": "ପ୍ରଫେସର ନାଗ",
              "as": "অধ্যাপক নাগ",
              "gu": "પ્રોફેસર નાગ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "The workshop was superb! I didn’t know that there was so much research going on in the field of agriculture!",
              "hi": "कार्यशाला शानदार थी! मुझे नहीं पता था कि कृषि के क्षेत्र में इतना शोध चल रहा है ",
              "ka": "ಕಾರ್ಯಾಗಾರ ಅದ್ಭುತವಾಗಿತ್ತು! ಕೃಷಿ ಕ್ಷೇತ್ರದಲ್ಲಿ ಇಷ್ಟೊಂದು ಸಂಶೋಧನೆಗಳು ನಡೆಯುತ್ತಿವೆ ಎಂದು ಗೊತ್ತಿರಲಿಲ್ಲ!",
              "te": "వర్క్ షాప్ అద్భుతంగా ఉంది! వ్యవసాయ రంగంలో ఇంత పరిశోధనలు జరుగుతున్నాయని నాకు తెలియదు!",
              "or": "କର୍ମଶାଳା ଅତ୍ୟନ୍ତ ସୁନ୍ଦର ଥିଲା! ମୁଁ ଜାଣି ନଥିଲି ଯେ କୃଷି କ୍ଷେତ୍ରରେ ଏତେ\n  ଗବେଷଣା ଚାଲିଛି!",
              "as": "কৰ্মশালাখন সুন্দৰ আছিল! মই নাজানিছিলো যে কৃষিৰ ক্ষেত্ৰত ইমান গৱেষণা চলি আছে!",
              "gu": "વર્કશોપ સરસ હતી! મને ખબર ન હતી કે કૃષિ ક્ષેત્રે આટલું બધું સંશોધન ચાલી રહ્યું છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I’m so glad you liked it. Do you also work on a farm?",
              "hi": "मुझे खुशी है कि आपको यह पसंद आया। क्या आप खेत में भी काम करते हैं?",
              "ka": "ನೀವು ಅದನ್ನು ಇಷ್ಟಪಟ್ಟಿದ್ದಕ್ಕೆ ನನಗೆ ತುಂಬಾ ಖುಷಿಯಾಗಿದೆ. ನೀವೂ ಜಮೀನಿನಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತೀರಾ?",
              "te": "మీరు దీన్ని ఇష్టపడినందుకు నేను చాలా సంతోషిస్తున్నాను. మీరు కూడా పొలం పని చేస్తున్నారా?",
              "or": "ତୁମେ ଏହାକୁ ପସନ୍ଦ କରିଥିବାରୁ ମୁଁ ବହୁତ ଖୁସି | ଆପଣ ମଧ୍ୟ ଏକ ଫାର୍ମରେ କାମ କରନ୍ତି କି?",
              "as": "মই বহুত সুখী যে তুমি এইটো ভাল পাইছা। তুমি এখন ফাৰ্মতো কাম কৰা নেকি?",
              "gu": "મને ખૂબ આનંદ થયો કે તમને તે ગમ્યું. શું તું ખેતરમાં પણ કામ કરો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Yes, ma’am, I do. I have been working on my family’s farm for a year now.",
              "hi": "हाँ, महोदया, मैं करता हूँ। मैं एक साल से अपने परिवार के खेत में काम कर रहा हूं।",
              "ka": "ಹೌದು, ಮೇಡಂ, ನಾನು ಮಾಡುತ್ತೇನೆ. ನಾನು ಈಗ ಒಂದು ವರ್ಷದಿಂದ ನನ್ನ ಕುಟುಂಬದ ಜಮೀನಿನಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೇನೆ",
              "te": "అవును, మేడమ్, నేను చేస్తాను. నేను ఏడాదిగా మా కుటుంబ పొలంలో పని చేస్తున్నాను.",
              "or": "ହଁ, ମ୍ୟାମ , ମୁଁ କରେ | ମୁଁ ଏକ ବର୍ଷ ହେଲା ମୋ ପରିବାରର ଫାର୍ମରେ କାମ କରୁଛି |",
              "as": "হয়, মেম, মই কৰোঁ। মই এবছৰ ধৰি মোৰ পৰিয়ালৰ ফাৰ্মত কাম কৰি আছোঁ।",
              "gu": "હા, મેડમ, હું કરું છું. હું એક વર્ષથી મારા કુટુંબના ખેતરમાં કામ કરું છું."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_i_2_2 ///",
              "hi": "B2_i_2_2 //////",
              "ka": "B2_i_2_2 ///",
              "te": "B2_i_2_2 ///",
              "or": "B2_i_2_2 ///",
              "as": "B2_i_2_2 ///",
              "gu": "B2_i_2_2 ///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Oh, that's great. You can do agricultural research on any farm. The fields are like laboratories where you can keep experimenting until you get the best results. When it comes to farming, we need to mix the latest technology and combine it with traditional practices.",
              "hi": "ओह यह बहुत अच्छी बात है। आप किसी भी खेत पर कृषि अनुसंधान कर सकते हैं। खेत प्रयोगशालाओं की तरह हैं जहां आप सबसे अच्छे परिणाम प्राप्त करने तक प्रयोग कर सकते हैं। जब खेती की बात आती है, तो हमें नवीनतम तकनीक और पारंपरिक प्रथाओं को एक साथ मिश्रण करने की आवश्यकता होती है।",
              "ka": "ಓಹ್! ಅದು ಅದ್ಭುತವಾದ ವಿಷಯ. ನೀವು ಯಾವುದೇ ಜಮೀನಿನಲ್ಲಿ ಕೃಷಿ ಸಂಶೋಧನೆ ಮಾಡಬಹುದು. ಜಮೀನುಗಳು ಪ್ರಯೋಗಾಲಯಗಳಂತಿದ್ದು, ನೀವು ಉತ್ತಮ ಫಲಿತಾಂಶಗಳನ್ನು ಪಡೆಯುವವರೆಗೆ ನೀವು ಪ್ರಯೋಗವನ್ನು ಮಾಡಬಹುದು. ಕೃಷಿಯ ವಿಷಯಕ್ಕೆ ಬಂದರೆ, ನಾವು ಇತ್ತೀಚಿನ ತಂತ್ರಜ್ಞಾನವನ್ನು ಬೆರೆಸಬೇಕು ಮತ್ತು ಸಾಂಪ್ರದಾಯಿಕ ಪದ್ಧತಿಗಳೊಂದಿಗೆ ಸಂಯೋಜಿಸಬೇಕು.",
              "te": "అబ్బో, గొప్ప విషయమే. మీరు ఏ పొలంలోనైనా వ్యవసాయ పరిశోధన చేయవచ్చు. ఫీల్డ్ లు లాబొరేటరీల లాంటివి, మీరు ఉత్తమ ఫలితాలను పొందే వరకు మీరు ప్రయోగాలు చేస్తూ ఉండాలి. వ్యవసాయం విషయానికి వస్తే, మనం అత్యాధునిక సాంకేతికతను కలిపి చేయాలి సంప్రదాయ పద్ధతులతో కలపాలి.",
              "or": "ଓହ, ଏହା ବହୁତ ଭଲ | ଆପଣ ଯେକୌଣସି ଚାଷ ଉପରେ କୃଷି ଅନୁସନ୍ଧାନ\n  କରିପାରିବେ | କ୍ଷେତ୍ରଗୁଡିକ ଲାବୋରେଟୋରୀ ପରି, ଯେଉଁଠାରେ ଆପଣ \n ସର୍ବୋତ୍ତମ ଫଳାଫଳ ନ ପାଇବା ପର୍ଯ୍ୟନ୍ତ ଆପଣଙ୍କ ପରୀକ୍ଷଣ ଜାରି ରଖିପାରିବେ |\n  ଯେତେବେଳେ ଚାଷ ବିଷୟରେ କଥା ଆସେ , ଆମକୁ ଅତ୍ୟାଧୁନିକ ଜ୍ଞାନକୌଶଳ\n  ମିଶ୍ରଣ କରିବା ଏବଂ ଏହାକୁ ପାରମ୍ପାରିକ ଅଭ୍ୟାସ ସହିତ ମିଶ୍ରଣ କରିବା ଆବଶ୍ୟକ |",
              "as": "ওহ, এইটো বৰ ভাল। তুমি যিকোনো ফাৰ্মত কৃষি গৱেষণা কৰিব পাৰা। পথাৰবোৰ পৰীক্ষাগাৰৰ দৰে য'ত তুমি সৰ্বশ্ৰেষ্ঠ ফলাফল নোপোৱালৈকে পৰীক্ষা কৰি থাকিব পাৰা। যেতিয়া কৃষিৰ কথা আহে, আমি শেহতীয়া প্ৰযুক্তিটো মিহলি কৰিব লাগিব আৰু ইয়াক পৰম্পৰাগত পদ্ধতিৰ সৈতে লগ লগাব লাগিব।",
              "gu": "ઓહ, તે સરસ છે. તું કોઈપણ ખેતરમાં કૃષિ સંશોધન કરી શકો છો. ખેતર પ્રયોગશાળાઓ જેવા છે જ્યાં સુધી તમે શ્રેષ્ઠ પરિણામો પ્રાપ્ત ન કરો ત્યાં સુધી તમે પ્રયોગ ચાલુ રાખી શકો છો. જ્યારે ખેતીની વાત આવે છે, ત્યારે આપણે નવીનતમ તકનીકને મિશ્રિત કરવાની અને તેને પરંપરાગત પદ્ધતિઓ સાથે જોડવાની જરૂર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Ma’am, I am very keen to do that. How can I learn more about this?",
              "hi": "महोदया, मैं ऐसा करने के लिए बहुत उत्सुक हूं। मैं इसके बारे में अधिक जानकारी कैसे प्राप्त कर सकता हूं?",
              "ka": "ಮೇಡಂ, ನಾನು ಅದನ್ನು ಮಾಡಲು ತುಂಬಾ ಉತ್ಸುಕನಾಗಿದ್ದೇನೆ. ನಾನು ಇದರ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳುವುದು ಹೇಗೆ?",
              "te": "మేడమ్, నేను దీన్ని చేయడానికి చాలా ఆసక్తిగా ఉన్నాను. నేను దీని గురించి మరింత ఎలా నేర్చుకోవాలి?",
              "or": "ମ୍ୟାମ , ମୁଁ ତାହା କରିବାକୁ ବହୁତ ଆଗ୍ରହୀ | ମୁଁ ଏ ବିଷୟରେ ଅଧିକ କିପରି ଜାଣିବି?",
              "as": "মেডাম, মই সেইটো কৰিবলৈ খুব আগ্ৰহী। মই এই বিষয়ে কেনেকৈ অধিক শিকিব পাৰোঁ?",
              "gu": "મેડમ, હું તે કરવા માટે ખૂબ જ ઉત્સુક છું. હું આ વિશે વધુ કેવી રીતે જાણી શકું?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "There are many ways to learn. Here, let me open up my presentation.",
              "hi": "सीखने के कई तरीके हैं। यहाँ, मुझे अपना प्रस्तुतिकरण दिखाने दो।",
              "ka": "ಕಲಿಯಲು ಹಲವು ಮಾರ್ಗಗಳಿವೆ. ಇಲ್ಲಿ, ನನ್ನ ಪ್ರಸ್ತುತಿಯನ್ನು ತೆರೆಯೋಣ.",
              "te": "నేర్చుకోవడానికి చాలా మార్గాలు ఉన్నాయి. ఇక్కడ, నా ప్రెజెంటేషన్ చూపిస్తాను.",
              "or": "ଶିଖିବାର ଅନେକ ଉପାୟ ଅଛି | ଏଠାରେ, ମୋତେ ମୋର ଉପସ୍ଥାପନା ଖୋଲିବାକୁ\n  ଦିଅ |",
              "as": "শিকাৰ বহুতো উপায় আছে। ইয়াত, মোক মোৰ প্ৰেজেণ্টেচনটো খুলিবলৈ দিয়া।",
              "gu": "શીખવાની ઘણી રીતો છે. અહીં, મને મારી રજૂઆત બાતાવા દો."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "presentation reveal",
              "hi": "प्रस्तुति को दिखाना ",
              "ka": "ಪ್ರಸ್ತುತಿ ಬಹಿರಂಗಪಡಿಸುತ್ತದೆ",
              "te": "ప్రదర్శన బహిర్గతం",
              "or": "ଉପସ୍ଥାପନା ପ୍ରକାଶ",
              "as": "প্ৰেজেণ্টেচন প্রকাশ",
              "gu": "રજૂઆત છતી કરે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Professor Nag shares 3 ways to learn more. Click to reveal what she shares with Shikhar.",
              "hi": "प्रोफेसर नाग अधिक जानकारी के लिए  3 तरीके साझा करती हैं। वह शिखर के साथ क्या साझा करती है यह बताने के लिए क्लिक करें।",
              "ka": "ಪ್ರೊಫೆಸರ್ ನಾಗ್ ಅವರು ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು 3 ಮಾರ್ಗಗಳನ್ನು ಹಂಚಿಕೊಂಡಿದ್ದಾರೆ. ಶಿಖರ್ ಜೊತೆಗೆ ಆಕೆ ಏನನ್ನು ಹಂಚಿಕೊಂಡಿದ್ದಾಳೆ ಎಂಬುದನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "ప్రొఫెసర్ నాగ్ మరింత తెలుసుకోవడానికి 3 మార్గాలను చూపిస్తుంది. శిఖర్‌ కి ఆమె ఏమి చూపించిందో చూడడానికి క్లిక్ చేయండి.",
              "or": "ଅଧିକ ଜାଣିବା ପାଇଁ ପ୍ରଫେସର ନାଗ 3 ଟି ଉପାୟ ବାଣ୍ଟିଛନ୍ତି | ସେ ଶିଖରଙ୍କ\n  ସହ କଣ ଅଂଶୀଦାର କରନ୍ତି ତାହା ଜାଣିବା ପାଇଁ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "অধ্যাপক নাগে অধিক শিকাৰ 3 টা উপায় ভাগ-বতৰা কৰিছে। তাই শিখৰৰ সৈতে কি ভাগ বতৰা কৰে তাক চাবলৈ ক্লিক কৰা।",
              "gu": "પ્રોફેસર નાગ વધુ જાણવા માટે 3 રીતો શેર કરે છે. તેણી શિખર સાથે શું શેર કરે છે તે જાણવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Formal Education",
              "hi": "औपचारिक शिक्षा",
              "ka": "ಔಪಚಾರಿಕ ಶಿಕ್ಷಣ",
              "te": "అధికారిక విద్య",
              "or": "ଆନୁଷ୍ଠାନିକ ଶିକ୍ଷା",
              "as": "আনুষ্ঠানিক শিক্ষা",
              "gu": "ઔપચારિક શિક્ષણ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Learning from experts",
              "hi": "विशेषज्ञों से सीखना",
              "ka": "ತಜ್ಞರಿಂದ ಕಲಿಯುವುದು",
              "te": "నిపుణుల నుండి నేర్చుకోవడం",
              "or": "ବିଶେଷଜ୍ଞଙ୍କଠାରୁ ଶିକ୍ଷା |",
              "as": "বিশেষজ্ঞৰ পৰা শিকা",
              "gu": "નિષ્ણાતો પાસેથી શીખવું"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Internship",
              "hi": "इंटर्नशिप",
              "ka": "ಇಂಟರ್ನ್‌ಶಿಪ್‌",
              "te": "ఇంటర్న్‌షిప్",
              "or": "ଇଣ୍ଟର୍ନସିପ୍ |",
              "as": "ইন্টাৰ্ণশ্বিপ",
              "gu": "ઇન્ટર્નશિપ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Research courses on Agriculture Science and the colleges offering them. You can then pick the one that interests you.",
              "hi": "कृषि विज्ञान पर अनुसंधान कोर्स और उनको प्रस्तावित करने वाले कॉलेज। फिर आप उसे चुन सकते हैं जिसमें आपकी रुचि है।",
              "ka": "ಕೃಷಿ ವಿಜ್ಞಾನದ ಸಂಶೋಧನಾ ಕೋರ್ಸ್‌ಗಳು ಮತ್ತು ಅವುಗಳನ್ನು ನೀಡುತ್ತಿರುವ ಕಾಲೇಜುಗಳು. ನಂತರ ನಿಮಗೆ ಆಸಕ್ತಿಯಿರುವದನ್ನು ನೀವು ಆಯ್ಕೆ ಮಾಡಬಹುದು.",
              "te": "వ్యవసాయ శాస్త్రం పై పరిశోధన కోర్సులు మరియు వాటిని అందిస్తున్న కళాశాలలు. ఆపై మీకు ఆసక్తి ఉన్నదాన్ని మీరు ఎంచుకోవచ్చు.",
              "or": "କୃଷି ବିଜ୍ଞାନ ଏବଂ ସେମାନଙ୍କୁ ପ୍ରଦାନ କରୁଥିବା କଲେଜଗୁଡ଼ିକ ଉପରେ ଗବେଷଣା\n  ପାଠ୍ୟକ୍ରମ | ତାପରେ ତୁମକୁ ପସନ୍ଦ କରୁଥିବାକୁ ବାଛି ପାରିବ |",
              "as": "কৃষি বিজ্ঞান আৰু সেইবোৰ আগবঢ়োৱা কলেজসমূহৰ ওপৰত গৱেষণা পাঠ্যক্ৰম। তুমি তেতিয়া তোমাৰ ভাল লগাটো বাছনি কৰিব পাৰা।",
              "gu": "કૃષિ વિજ્ઞાન પર સંશોધન અભ્યાસક્રમો અને તેમને ઓફર કરતી કોલેજો. પછી તમે તમારી રુચિ હોય તે પસંદ કરી શકો છો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "To learn from experts, you need to observe them. You can do this by volunteering, taking up apprenticeships or by job shadowing them.",
              "hi": "विशेषज्ञों से सीखने के लिए, आपको उनका निरीक्षण करने की आवश्यकता है। आप स्वेच्छा से, अपरेंटिसशिप लेने या नौकरी में उनका अनुसरण करके ऐसा कर सकते हैं।",
              "ka": "ತಜ್ಞರಿಂದ ಕಲಿಯಲು, ನೀವು ಅವರನ್ನು ಗಮನಿಸಬೇಕು. ಸ್ವಯಂಸೇವಕರಾಗಿ, ಅಪ್ರೆಂಟಿಸ್‌ಶಿಪ್‌ಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುವ ಮೂಲಕ ಅಥವಾ ಅವರ ಜೊತೆಗೆ ಇರುವ ಮೂಲಕ ನೀವು ಇದನ್ನು ಮಾಡಬಹುದು.",
              "te": "నిపుణుల నుండి నేర్చుకోవడానికి, మీరు మీరు వాటిని గమనించాలి. మీరు వాలంటీరింగ్ ద్వారా, అప్రెంటిస్షిప్‌ లను చేపట్టడం ద్వారా లేదా వారికి జాబ్ షాడోయింగ్ ద్వారా దీన్ని చేయవచ్చు.",
              "or": "ବିଶେଷଜ୍ଞଙ୍କଠାରୁ ଶିଖିବା ପାଇଁ, ଆପଣ ସେମାନଙ୍କୁ ପାଳନ କରିବା ଆବଶ୍ୟକ\n  କରନ୍ତି | ସ୍ବେଚ୍ଛାସେବୀ ଭାବେ, ଶିକ୍ଷୁତା ଗ୍ରହଣ କିମ୍ବା ଚାକିରି ଛାଇ କରି ଆପଣ \n ଏହା କରିପାରିବେ |",
              "as": "বিশেষজ্ঞসকলৰ পৰা শিকিবলৈ, তুমি সেইবোৰ পৰ্যবেক্ষণ কৰিব লাগিব। তুমি স্বেচ্ছাসেৱী হৈ, প্ৰশিক্ষণ লৈ বা চাকৰি লৈ এনে কৰিব পাৰা।",
              "gu": "નિષ્ણાતો પાસેથી શીખવા માટે, તમારે તેમને અવલોકન કરવાની જરૂર છે. તમે સ્વયંસેવી દ્વારા, એપ્રેન્ટિસશીપ લઈને અથવા તેમને પડછાયાની નોકરી દ્વારા આ કરી શકો છો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "Doing internships with organisations that work on farm technology.",
              "hi": "संगठनों के साथ इंटर्नशिप करना जो कृषि तकनीक पर काम करते हैं।",
              "ka": "ಕೃಷಿ ತಂತ್ರಜ್ಞಾನದಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಸಂಸ್ಥೆಗಳೊಂದಿಗೆ ಇಂಟರ್ನ್‌ಶಿಪ್ ಮಾಡುವುದು.",
              "te": "వ్యవసాయ సాంకేతికతపై పనిచేసే సంస్థలతో ఇంటర్న్‌షిప్ చేయడం.",
              "or": "ଫାର୍ମ ଟେକ୍ନୋଲୋଜିରେ କାମ କରୁଥିବା ସଂସ୍ଥାଗୁଡ଼ିକ ସହିତ ଇଣ୍ଟର୍ନସିପ୍ କରିବା |",
              "as": "ফাৰ্ম প্ৰযুক্তিৰ ওপৰত কাম কৰা সংগঠনবোৰৰ সৈতে ইন্টাৰ্ণশ্বিপ কৰা।",
              "gu": "ફાર્મ ટેકનોલોજી પર કામ કરતી સંસ્થાઓ સાથે ઇન્ટર્નશિપ કરવી."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_i_2_3 bg",
              "hi": "B2_I_2_3 बीजी",
              "ka": "B2_i_2_3 bg",
              "te": "B2_i_2_3 bg",
              "or": "B2_i_2_3 bg",
              "as": "B2_i_2_3 bg",
              "gu": "B2_i_2_3 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Professor Nag",
              "hi": "प्रोफेसर नाग",
              "ka": "ಪ್ರೊಫೆಸರ್ ನಾಗ್",
              "te": "ప్రొఫెసర్ నాగ్",
              "or": "ପ୍ରଫେସର ନାଗ",
              "as": "অধ্যাপক নাগ",
              "gu": "પ્રાધ્યાપક નાગ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Thank you so much, ma’am! Your presentation clarifies a lot!",
              "hi": "बहुत बहुत धन्यवाद, महोदया! आपकी प्रस्तुति बहुत कुछ स्पष्ट करती है!",
              "ka": "ತುಂಬಾ ಧನ್ಯವಾದಗಳು, ಮೇಡಮ್! ನಿಮ್ಮ ಪ್ರಸ್ತುತಿ ಬಹಳಷ್ಟು ಸ್ಪಷ್ಟಪಡಿಸುತ್ತದೆ!",
              "te": "చాలా ధన్యవాదాలు, మేడమ్! మీ ప్రెజెంటేషన్ చాలా స్పష్టం చేస్తుంది!",
              "or": "ଆପଣଙ୍କୁ ବହୁତ ଧନ୍ୟବାଦ, ମ୍ୟାମ! ଆପଣଙ୍କର ଉପସ୍ଥାପନା ବହୁତ ସ୍ପଷ୍ଟ କରେ!",
              "as": "আপোনাক বহুত ধন্যবাদ, মেম! আপোনাৰ প্ৰেজেণ্টেচনটোৱে বহুত স্পষ্ট কৰে!",
              "gu": "તમારો ખૂબ ખૂબ આભાર, મેડમ! તમારી રજૂઆત ઘણું સ્પષ્ટ કરે છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Take this brochure with you. Start by knowing what interests you and identifying why you want to do this.",
              "hi": "इस ब्रोशर को अपने साथ ले जाएं। यह जानकर शुरु करें कि आप किसमें रुचि रखते हैं और यह समझें कि आप ऐसा क्यों करना चाहते हैं।",
              "ka": "ಈ ಕರಪತ್ರವನ್ನು ನಿಮ್ಮೊಂದಿಗೆ ತೆಗೆದುಕೊಳ್ಳಿ. ನಿಮಗೆ ಆಸಕ್ತಿ ಏನು ಎಂಬುದನ್ನು ತಿಳಿದುಕೊಳ್ಳುವ ಮೂಲಕ ಪ್ರಾರಂಭಿಸಿ ಮತ್ತು ನೀವು ಅದನ್ನು ಏಕೆ ಮಾಡಲು ಬಯಸುತ್ತೀರಿ ಎಂಬುದನ್ನು ಗುರುತಿಸಿ",
              "te": "ఈ బ్రోచర్‌ ను మీతో తీసుకెళ్లండి. మీకు ఏది ఆసక్తిని కలిగిస్తుందో తెలుసుకోవడం మరియు మీరు దీన్ని ఎందుకు చేయాలనుకుంటున్నారో గుర్తించడం ద్వారా ప్రారంభించండి.",
              "or": "ଏହି ବିବରଣିକାକୁ ସାଙ୍ଗରେ ନିଅ | ତୁମର କ’ଣ ଜାଣିବାରେ ଆଗ୍ରହ ଏବଂ ତୁମେ\n  ଏହା କାହିଁକି କରିବାକୁ ଚାହୁଁଛ ତାହା ଜାଣିବା ଆରମ୍ଭ କର |",
              "as": "এই পুস্তিকাখন তোমাৰ লগত লৈ যোৱা। তোমাৰ কি আগ্ৰহ আছে জানি আৰু তুমি কিয় এনে কৰিব বিচাৰা চিনাক্ত কৰি আৰম্ভ কৰা ।",
              "gu": "આ પત્રિકા તમારી સાથે લો. તું શું રુચિ છે તે જાણીને અને તું શા માટે આ કરવા માંગો છો તે ઓળખીને પ્રારંભ કર."
            }
          }
        ]
      },
      "scene40": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_1",
              "hi": "B2_1_1",
              "ka": "B2_1_1",
              "te": "B2_1_1",
              "or": "B2_1_1",
              "as": "B2_1_1",
              "gu": "B2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They come across an exciting stall put up by an agricultural technology organisation named CropSense. CropSense works with farmers to plan, monitor and analyse all farm activities using an app.",
              "hi": "वे क्रॉप्सेंस नामक कृषि तकनीकी संगठन द्वारा लगाई गई एक रोमांचक स्टॉल में आते हैं। क्रॉप्सेंस किसानों के साथ एक ऐप का उपयोग करके सभी कृषि गतिविधियों की योजना बनाने, निगरानी और विश्लेषण करने के लिए काम करता है।",
              "ka": "ಅವರು ಕ್ರಾಪ್‌ಸೆನ್ಸ್ ಎಂಬ ಕೃಷಿ ತಂತ್ರಜ್ಞಾನ ಸಂಸ್ಥೆಯು ಹಾಕಿರುವ ಅತ್ಯಾಕರ್ಷಕ ಮಳಿಗೆಯನ್ನು ನೋಡುತ್ತಾರೆ. ಅಪ್ಲಿಕೇಶನ್ ಬಳಸಿಕೊಂಡು ಎಲ್ಲಾ ಕೃಷಿ ಚಟುವಟಿಕೆಗಳನ್ನು ಯೋಜಿಸಲು, ಮೇಲ್ವಿಚಾರಣೆ ಮಾಡಲು ಮತ್ತು ವಿಶ್ಲೇಷಿಸಲು ಕ್ರಾಪ್‌ಸೆನ್ಸ್ ರೈತರೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುತ್ತದೆ.",
              "te": "క్రాప్‌సెన్స్ అనే వ్యవసాయ సాంకేతిక సంస్థ ఏర్పాటు చేసిన అద్భుతమైన స్టాల్‌ ను వారు చూస్తారు. క్రాప్‌సెన్స్ యాప్‌ని ఉపయోగించి అన్ని వ్యవసాయ కార్యకలాపాలను ప్రణాళిక చేయడానికి, పర్యవేక్షించడానికి మరియు విశ్లేషించడానికి రైతులతో కలిసి పని చేస్తుంది.",
              "or": "କ୍ରପ୍ ସେନ୍ସ ନାମକ ଏକ କୃଷି ପ୍ରଯୁକ୍ତିବିଦ୍ୟା ସଂସ୍ଥା ଦ୍ୱାରା ସେମାନେ ଏକ ରୋମାଞ୍ଚକର\n  ଷ୍ଟଲ୍ ସାମ୍ନାକୁ ଆସନ୍ତି | କ୍ରପ୍ ସେନ୍ସ କୃଷକମାନଙ୍କ ସହିତ ଏକ ଆପ୍ ବ୍ୟବହାର କରି\n  ସମସ୍ତ ଚାଷ କାର୍ଯ୍ୟର ଯୋଜନା, ନୀରିକ୍ଷଣ ଏବଂ ବିଶ୍ଳେଷଣ କରିବାକୁ କାର୍ଯ୍ୟ କରେ |",
              "as": "সিহঁতে ক্ৰপচেঞ্চ নামৰ এটা কৃষি প্ৰযুক্তি সংস্থাৰ দ্বাৰা স্থাপন কৰা এখন আমোদজনক দোকান দেখে। ক্ৰপচেঞ্চে কৃষকসকলৰ সৈতে এপ এটা ব্যৱহাৰ কৰি সকলো কৃষি কাৰ্যকলাপৰ পৰিকল্পনা, নিৰীক্ষণ আৰু বিশ্লেষণ কৰিবলৈ কাম কৰে।",
              "gu": "તેઓ ક્રોપસેન્સ નામની એગ્રીકલ્ચર ટેક્નોલોજી સંસ્થા દ્વારા મુકવામાં આવેલ આકર્ષક સ્ટોલ તરફ આવે છે. ક્રોપસેન્સ એપનો ઉપયોગ કરીને તમામ કૃષિ પ્રવૃત્તિઓનું આયોજન, દેખરેખ અને વિશ્લેષણ કરવા ખેડૂતો સાથે કામ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_2",
              "hi": "B2_1_2",
              "ka": "B2_1_2",
              "te": "B2_1_2",
              "or": "B2_1_2",
              "as": "B2_1_2",
              "gu": "B2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He hears them talk about their app and sensors to a group of farmers.",
              "hi": "वह किसानों के एक समूह में उनके ऐप और सेंसर के विषय में होने वाली बात सुनता है।",
              "ka": "ಅವರು ರೈತರ ಗುಂಪಿನೊಂದಿಗೆ ತಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಮತ್ತು ಸಂವೇದಕಗಳ ಕುರಿತು ಮಾತನಾಡುವುದನ್ನು ಅವರು ಕೇಳುತ್ತಾರೆ.",
              "te": "వారు తమ యాప్ మరియు సెన్సార్ల గురించి రైతుల సమూహంతో మాట్లాడటం అతను విన్నాడు.",
              "or": "ସେ ସେମାନଙ୍କ କୃଷକ ଗୋଷ୍ଠୀ ସହିତ ସେମାନଙ୍କର ଆପ୍ ଏବଂ ସେନ୍ସର ବିଷୟରେ\n  କଥାବାର୍ତ୍ତା ଶୁଣନ୍ତି |",
              "as": "সি তেওঁলোকে কৃষকৰ এটা দলক তেওঁলোকৰ এপ আৰু চেন্সৰৰ বিষয়ে কোৱা শুনিছিল।",
              "gu": "તે તેમને ખેડૂતોના જૂથ સાથે તેમની એપ્લિકેશન અને સેન્સર વિશે વાત કરતા સાંભળે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_3",
              "hi": "B2_1_3",
              "ka": "B2_1_3",
              "te": "B2_1_3",
              "or": "B2_1_3",
              "as": "B2_1_3",
              "gu": "B2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar remembers reading about these sensors when he was in school and is curious to learn more. He goes up to the stall.",
              "hi": "शिखर याद करता है कि जब वह स्कूल में था तो उसने इन सेंसर के बारे में पढ़ा था, वह और अधिक जानने के लिए उत्सुक है। वह स्टाल तक जाता है।",
              "ka": "ಶಿಖರ್ ಅವರು ಶಾಲೆಯಲ್ಲಿದ್ದಾಗ ಈ ಸೆನ್ಸರ್‌ಗಳ ಬಗ್ಗೆ ಓದಿದ್ದನ್ನು ನೆನಪಿಸಿಕೊಳ್ಳುತ್ತಾನೆ ಮತ್ತು ಹೆಚ್ಚಿನದನ್ನು ಕಲಿಯುವ ಕುತೂಹಲವಿದೆ. ಅವನು ಸ್ಟಾಲ್‌ಗೆ ಹೋಗುತ್ತಾನೆ.",
              "te": "శిఖర్ పాఠశాలలో ఉన్నప్పుడు ఈ సెన్సార్ల గురించి చదివిన జ్ఞాపకం మరియు మరింత తెలుసుకోవాలనే కుతూహలం కలిగింది. అతను దగ్గరికి వెళ్తాడు.",
              "or": "ଶିଖର ଯେତେବେଳେ ସେ ସ୍କୁଲରେ ପଢୁଥିଲେ ଏହି ସେନ୍ସରଗୁଡ଼ିକ ବିଷୟରେ ପଢିବାକୁ\n  ସେ ଜିଜ୍ଞାଂଶୁ ଥିଲେ ଏବଂ ଅଧିକ ଜାଣିବାକୁ ଆଗ୍ରହୀ ଥିଲେ | ସେ ଷ୍ଟଲକୁ ଯାଆନ୍ତି |",
              "as": "শিখৰে স্কুলত থাকোতে এই চেন্সৰবোৰৰ বিষয়ে পঢ়াৰ কথা মনত পৰে আৰু অধিক শিকিবলৈ আগ্ৰহী হয়। সি দোকানলৈ যায়।",
              "gu": "શિખરને આ સેન્સર વિશે વાંચેલું યાદ છે જ્યારે તે શાળામાં હતો અને વધુ જાણવા માટે ઉત્સુક છે. તે સ્ટોલ ઉપર જાય છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_4",
              "hi": "B2_1_4",
              "ka": "B2_1_4",
              "te": "B2_1_4",
              "or": "B2_1_4",
              "as": "B2_1_4",
              "gu": "B2_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar learns that Cropsense has been working in villages around Ujjain but has found it challenging to widen its operations because of the hesitance of farmers to use these devices.",
              "hi": "शिखर को पता चलता है कि क्रॉप्सेंस उज्जैन के आसपास के गांवों में काम कर रहा है लेकिन किसानों में इन उपकरणों का उपयोग करने में हिचकिचाहट के कारण उसे अपने परिचालनों को बढ़ाना चुनौतीपूर्ण लग रहा है।",
              "ka": "ಉಜ್ಜಯಿನಿಯ ಸುತ್ತಮುತ್ತಲಿನ ಹಳ್ಳಿಗಳಲ್ಲಿ ಕ್ರಾಪ್‌ಸೆನ್ಸ್ ಕೆಲಸ ಮಾಡುತ್ತಿದೆ ಎಂದು ಶಿಖರ್ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆ ಆದರೆ ರೈತರು ಈ ಸಾಧನಗಳನ್ನು ಬಳಸಲು ಹಿಂಜರಿಯುವುದರಿಂದ ಅದರ ಕಾರ್ಯಾಚರಣೆಯನ್ನು ವಿಸ್ತರಿಸುವುದು ಸವಾಲಿನ ಸಂಗತಿಯಾಗಿದೆ.",
              "te": "క్రాప్‌సెన్స్ ఉజ్జయిని చుట్టుపక్కల గ్రామాలలో పనిచేస్తోందని శిఖర్ తెలుసుకుంటాడు, అయితే రైతులు ఈ పరికరాలను ఉపయోగించడానికి వెనుకాడడం వల్ల దాని కార్యకలాపాలను విస్తృతం చేయడం సవాలుగా ఉంది.",
              "or": "ଶିଖର ଜାଣିବାକୁ ପାଇଛନ୍ତି ଯେ ଫସଲ ବିଜ୍ଞାନ ଉଜ୍ଜୈନର ଆଖପାଖ ଗାଁରେ କାମ\n  କରୁଛି କିନ୍ତୁ ଚାଷୀଙ୍କ ଦ୍ଵାରା ଏହି ଉପକରଣ ବ୍ୟବହାର କରିବାରେ ଦ୍ୱିଧା ଥିବାରୁ \n ଏହାର କାର୍ଯ୍ୟକୁ ବ୍ୟାପକ କରିବା ଏକ ଆହ୍ଵାନ ଅଟେ।",
              "as": "শিখৰে জানিব পাৰিছে যে ক্ৰপচেঞ্চে উজ্জয়িনীৰ চাৰিওফালৰ গাওঁবোৰত কাম কৰি আছে কিন্তু কৃষকসকলে এই সঁজুলিবোৰ ব্যৱহাৰ কৰিবলৈ ভয় কৰাৰ বাবে ইয়াৰ কাৰ্যকলাপ বহল কৰাটো প্ৰত্যাহ্বানজনক বুলি ভাবিছে।",
              "gu": "શિખરને ખબર પડે છે કે ઉજ્જૈનની આસપાસના ગામડાઓમાં ક્રોપ્સસેન્સ કામ કરી રહી છે પરંતુ આ ઉપકરણોનો ઉપયોગ કરવામાં ખેડૂતોની ખચકાટને કારણે તેની કામગીરીને વિસ્તૃત કરવી તેને પડકારરૂપ જણાય છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_5 bg",
              "hi": "B2_1_5 बीजी",
              "ka": "B2_1_5 bg",
              "te": "B2_1_5 bg",
              "or": "B2_1_5 bg",
              "as": "B2_1_5 bg",
              "gu": "B2_1_5 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Ashwa, the person he approached, is the CEO of the company. He is happy to hear Shikhar’s enthusiasm about their work.",
              "hi": "उसने अश्व नाम के जिस व्यक्ति से संपर्क किया, वह कंपनी का सीईओ है। वह उनके काम के लिए शिखर के उत्साह को जानकर खुश है।",
              "ka": "ಅವರು ಸಂಪರ್ಕಿಸಿದ ಅಶ್ವಾ ಅವರು ಕಂಪನಿಯ ಸಿಇಒ ಆಗಿದ್ದಾರೆ. ತಮ್ಮ ಕೆಲಸದ ಬಗ್ಗೆ ಶಿಖರ್ ಅವರ ಉತ್ಸಾಹವನ್ನು ಕೇಳಿ ಅವರು ಸಂತೋಷಪಡುತ್ತಾರೆ.",
              "te": "అతను సంప్రదించిన వ్యక్తి అశ్వ కంపెనీకి CEO. వారి పని గురించి శిఖర్ యొక్క ఉత్సాహాన్ని విన్నందుకు అతను సంతోషిస్తున్నాడు.",
              "or": "ଅଶ୍ୱା, ସେ ଯେଉଁ ବ୍ୟକ୍ତିଙ୍କ ନିକଟକୁ ଯାଇଥିଲେ, ସେ ହେଉଛନ୍ତି କମ୍ପାନୀର ସିଇଓ | \n  ସେମାନଙ୍କ କାର୍ଯ୍ୟ ବିଷୟରେ ଶିଖରଙ୍କ ଉତ୍ସାହ ଶୁଣି ସେ ଖୁସି |",
              "as": "অশ্ব, সি যোগাযোগ কৰা ব্যক্তিজন হৈছে কোম্পানীটোৰ মুখ্য কাৰ্যবাহী বিষয়া। তেওঁলোকৰ কামৰ বিষয়ে শিখৰৰ উৎসাহ দেখি তেওঁ সুখী।",
              "gu": "અશ્વ, જે વ્યક્તિનો તેણે સંપર્ક કર્યો હતો તે કંપનીના CEO છે. શિખરનો તેમના કામ પ્રત્યેનો ઉત્સાહ સાંભળીને તે ખુશ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "You must come to our village! So many people would benefit from your work.",
              "hi": "आपको हमारे गांव में आना चाहिए! आपके काम से इतने सारे लोगों को लाभ होगा।",
              "ka": "ನೀವು ನಮ್ಮ ಹಳ್ಳಿಗೆ ಬರಬೇಕು! ನಿಮ್ಮ ಕೆಲಸದಿಂದ ಅನೇಕ ಜನರು ಪ್ರಯೋಜನ ಪಡೆಯುತ್ತಾರೆ",
              "te": "నువ్వు మా ఊరికి రావాలి! మీ పని వల్ల చాలా మంది ప్రయోజనం పొందుతారు.",
              "or": "ତୁମେ ନିଶ୍ଚୟ ଆମ ଗାଁକୁ ଆସିବ! ଅନେକ ଲୋକ ଆପଣଙ୍କ କାମରୁ ଉପକୃତ\n  ହେବେ |",
              "as": "আপুনি আমাৰ গাঁৱলৈ আহিব লাগিব! বহুতো মানুহে আপোনাৰ কামৰ পৰা লাভান্বিত হ'ব।",
              "gu": "તમારે અમારા ગામમાં આવવું જ જોઈએ! તમારા કામથી ઘણા લોકોને ફાયદો થશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_6///",
              "hi": "B2_1_6 ///////",
              "ka": "B2_1_6///",
              "te": "B2_1_6///",
              "or": "B2_1_6///",
              "as": "B2_1_6///",
              "gu": "B2_1_6///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "That is great to hear! We would be happy to visit your village and see how we can help. But it's not that easy to convince farmers to let us place sensors across their fields.",
              "hi": "यह सुनकर अच्छा लगा! हमें आपके गांव की यात्रा करने और यह देखने में खुशी होगी कि हम कैसे मदद कर सकते हैं। लेकिन किसानों को अपने खेतों में सेंसर रखने के लिए मनाना इतना सरल नहीं है।",
              "ka": "ಅದು ಕೇಳಲು ಅದ್ಭುತವಾಗಿದೆ! ನಿಮ್ಮ ಗ್ರಾಮಕ್ಕೆ ಭೇಟಿ ನೀಡಲು ಮತ್ತು ನಾವು ಹೇಗೆ ಸಹಾಯ ಮಾಡಬಹುದು ಎಂಬುದನ್ನು ನೋಡಲು ನಾವು ಸಂತೋಷಪಡುತ್ತೇವೆ. ಆದರೆ ರೈತರು ತಮ್ಮ ಹೊಲಗಳಲ್ಲಿ ಸೆನ್ಸರ್‌ಗಳನ್ನು ಇಡುವಂತೆ ಮನವರಿಕೆ ಮಾಡುವುದು ಅಷ್ಟು ಸುಲಭವಲ್ಲ.",
              "te": "వినడానికి చాలా బాగుంది! మేము మీ గ్రామాన్ని సందర్శించి, మేము ఎలా సహాయం చేయగలమో చూడడానికి సంతోషిస్తాము. అయితే రైతులను వారి పొలాల్లో సెన్సార్లను ఉంచమని వారిని ఒప్పించడం అంత సులభం కాదు.",
              "or": "ଏହା ଶୁଣିବାକୁ ବହୁତ ଭଲ! ଆମେ ତୁମ ଗାଁ ପରିଦର୍ଶନ କରି ଖୁସି ହେବୁ ଏବଂ\n  ଆମେ କିପରି ସାହାଯ୍ୟ କରିପାରିବା ଦେଖିବା | କିନ୍ତୁ କୃଷକମାନଙ୍କୁ ସେମାନଙ୍କ\n  କ୍ଷେତରେ ସେନ୍ସର ରଖିବାକୁ ମନାଇବା ଏତେ ସହଜ ନୁହେଁ |",
              "as": "এইটো শুনি বৰ ভাল লাগিছে! আমি তোমাৰ গাঁওখন চাবলৈ আৰু আমি কেনেদৰে সহায় কৰিব পাৰোঁ চাবলৈ পালে সুখী হ'ম। কিন্তু কৃষকসকলক তেওঁলোকৰ পথাৰত চেন্সৰ ৰাখিবলৈ দিয়াটো ইমান সহজ নহয়।",
              "gu": "તે સાંભળવા માટે મહાન છે! અમને તમારા ગામની મુલાકાત લેવામાં અને અમે કેવી રીતે મદદ કરી શકીએ તે જોઈને અમને આનંદ થશે. પરંતુ ખેડૂતોને તેમના ખેતરોમાં સેન્સર લગાવવા દેવા માટે સમજાવવું એટલું સરળ નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "But that's the reason my family sent me here! I'm sure my brother can help us convince the other farmers to try out CropSense.",
              "hi": "परन्तु यही कारण है कि मेरे परिवार ने मुझे यहां भेजा है! मुझे यकीन है कि मेरा भाई क्रॉप्सेंस के उपयोग के लिए अन्य किसानों को मनाने में हमारी सहायता कर सकता है।",
              "ka": "ಆದರೆ ನನ್ನ ಮನೆಯವರು ನನ್ನನ್ನು ಇಲ್ಲಿಗೆ ಕಳುಹಿಸಲು ಕಾರಣ ಅದೇ! ಕ್ರಾಪ್‌ಸೆನ್ಸ್ ಅನ್ನು ಪ್ರಯತ್ನಿಸಲು ಇತರ ರೈತರಿಗೆ ಮನವರಿಕೆ ಮಾಡಲು ನನ್ನ ಸಹೋದರ ನಮಗೆ ಸಹಾಯ ಮಾಡಬಹುದೆಂದು ನನಗೆ ಖಾತ್ರಿಯಿದೆ.",
              "te": "కానీ మా కుటుంబం నన్ను ఇక్కడికి పంపడానికి కారణం అదే! క్రాప్‌సెన్స్ ని ప్రయత్నించమని ఇతర రైతులను ఒప్పించేందుకు మా సోదరుడు మనకు సహాయం చేయగలడని నేను ఖచ్చితంగా అనుకుంటున్నాను.",
              "or": "କିନ୍ତୁ ସେଥିପାଇଁ ମୋର ପରିବାର ମୋତେ ଏଠାକୁ ପଠାଇଲେ! ମୁଁ ନିଶ୍ଚିତ ଯେ\n  ମୋ ଭାଇ ଅନ୍ୟ କୃଷକମାନଙ୍କୁ ଫସଲ ବିଜ୍ଞାନ ରେ ଚେଷ୍ଟା କରିବାକୁ ମନାଇବାରେ \n ସାହାଯ୍ୟ କରିପାରନ୍ତି |",
              "as": "কিন্তু সেইকাৰণেই মোৰ পৰিয়ালে মোক ইয়ালৈ পঠিয়াইছিল! মই নিশ্চিত যে মোৰ দাদাই আমাক ক্ৰপচেঞ্চ ব্যৱহাৰ কৰিবলৈ আন কৃষকসকলক সৈমান কৰাত সহায় কৰিব পাৰে।",
              "gu": "પણ એ જ કારણ છે કે મારા પરિવારે મને અહીં મોકલ્યો! મને ખાતરી છે કે મારો ભાઈ અમને અન્ય ખેડૂતોને ક્રોપસેન્સ અજમાવવા માટે સમજાવવામાં મદદ કરશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "We are happy that you came to our stall. We are always looking for young champions like you to be involved with us in spreading the word. You should consider joining CropSense.",
              "hi": "हम खुश हैं कि आप हमारे स्टाल में आए। हम हमेशा आपके जैसे युवा चैंपियन की तलाश में रहते हैं जो कि इसका प्रचार करने में हमारे साथ शामिल हो सके। आपको क्रॉप्सेंस में शामिल होने पर विचार करना चाहिए।",
              "ka": "ನೀವು ನಮ್ಮ ಸ್ಟಾಲ್‌ಗೆ ಬಂದಿದ್ದಕ್ಕೆ ನಮಗೆ ಸಂತೋಷವಾಗಿದೆ. ನಿಮ್ಮಂತಹ ಯುವ ಚಾಂಪಿಯನ್‌ಗಳನ್ನು ನಮ್ಮೊಂದಿಗೆ ಪ್ರಚಾರದಲ್ಲಿ ತೊಡಗಿಸಿಕೊಳ್ಳಲು ನಾವು ಯಾವಾಗಲೂ ಹುಡುಕುತ್ತಿದ್ದೇವೆ. ನೀವು CropSense ಗೆ ಸೇರುವುದನ್ನು ಪರಿಗಣಿಸಬೇಕು.",
              "te": "మీరు మా స్టాల్‌ కి వచ్చినందుకు సంతోషిస్తున్నాము. మీలాంటి యువ ఛాంపియన్లను ప్రచారం చేయడంలో మాతో పాలుపంచుకోవడానికి మేము ఎల్లప్పుడూ వెతుకుతున్నాము. మీరు క్రాప్‌సెన్స్ లో చేరడాన్ని పరిగణించాలి.",
              "or": "ତୁମେ ଆମ ଷ୍ଟଲକୁ ଆସିଥିବାରୁ ଆମେ ଖୁସି | କଥା ବିସ୍ତାର କରିବାରେ ଆମ ସହିତ\n  ଜଡିତ ହେବା ପାଇଁ ଆମେ ସବୁବେଳେ ତୁମ ପରି ଯୁବ ଚାମ୍ପିଅନ୍ ଖୋଜୁ | \n ଆପଣ ଫସଲ ବିଜ୍ଞାନରେ ଯୋଗଦେବାକୁ ଚିନ୍ତା କରିବା ଉଚିତ୍ |",
              "as": "আমি সুখী যে তুমি আমাৰ দোকানলৈ আহিছা। আমি সদায় তোমাৰ দৰে যুৱ চেম্পিয়নবিলাক বিচাৰি আছোঁ যাতে শব্দটো প্ৰচাৰত আমাৰ সৈতে থাকিব পাৰে। তুমি ক্ৰপচেঞ্চত যোগদান কৰাৰ কথা ভাবিব লাগে।",
              "gu": "અમે ખુશ છીએ કે તમે અમારા સ્ટોલ પર આવ્યા. અમે હંમેશા તમારા જેવા યુવા ચેમ્પિયનની શોધમાં છીએ કે જે અમારી વાતો બધાને કહે અને સાથે સામેલ થાય. તમારે ક્રોપસેન્સમાં જોડાવાનું વિચારવું જોઈએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "I would like to, but I have to help my brother out on the field. I don't get much time to spare.",
              "hi": "मैं चाहूंगा, लेकिन मुझे अपने भाई की खेती में सहायता करनी है। मुझे अतिरिक्त समय नहीं मिलता है।",
              "ka": "ನಾನು ಅದನ್ನೇ ಬಯಸುತ್ತೇನೆ, ಆದರೆ ನಾನು ಜಮೀನಿನಲ್ಲಿ  ನನ್ನ ಸಹೋದರನಿಗೆ ಸಹಾಯ ಮಾಡಬೇಕು. ನನಗೆ ಬಿಡುವಿನ  ಸಮಯ ಹೆಚ್ಚು ಸಿಗುತ್ತಿಲ್ಲ.",
              "te": "నేను చేయాలనుకుంటున్నాను, కానీ నేను పొలం లో నా సోదరుడికి సహాయం చేయాలి. నాకు ఎక్కువ సమయం కేటాయించడం లేదు.",
              "or": "ମୁଁ ଚାହେଁ, କିନ୍ତୁ ମୋତେ ମୋ ଭାଇକୁ କ୍ଷେତରେ ସାହାଯ୍ୟ କରିବାକୁ ପଡିବ | \n ମୁଁ ଅଧିକ ଖାଲି ସମୟ ପାଏ ନାହିଁ |",
              "as": "মই বিচাৰিম, কিন্তু মই মোৰ দাদাক পথাৰত সহায় কৰিব লাগিব। মই এনেয়ে থাকিবলৈ বেছি সময় নাপাওঁ।",
              "gu": "હું ઈચ્છું છું, પરંતુ મારે મારા ભાઈને ખેતરમાં મદદ કરવી પડશે. મને વધારાનો સમયમળતો નથી બીજા કઈ કામ માટે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Here is our brochure. Why don't you think about it?",
              "hi": "यह हमारा ब्रोशर है। आप इसके बारे में क्यों नहीं सोचते?",
              "ka": "ನಮ್ಮ ಕರಪತ್ರ ಇಲ್ಲಿದೆ. ನೀವು ಅದರ ಬಗ್ಗೆ ಏಕೆ ಯೋಚಿಸಬಾರದು?",
              "te": "ఇదిగో మా బ్రోచర్. మీరు దాని గురించి ఎందుకు ఆలోచించరు?",
              "or": "ଏଠାରେ ଆମର ବିବରଣୀ | ଆପଣ ଏହା ବିଷୟରେ କାହିଁକି ଭାବୁନାହାଁନ୍ତି?",
              "as": "ইয়াত আমাৰ পুস্তিকা আছে। তুমি ইয়াৰ বিষয়ে কিয় নাভাবা?",
              "gu": "આ રહ્યું અમારું પત્રિકા. તમે તેના વિશે કેમ વિચારતા નથી?"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_7",
              "hi": "B2_1_7",
              "ka": "B2_1_7",
              "te": "B2_1_7",
              "or": "B2_1_7",
              "as": "B2_1_7",
              "gu": "B2_1_7"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar is impressed by the different ways CropSense uses technology to help farmers monitor their yield with the help of a phone.",
              "hi": "शिखर क्रॉप्सेंस द्वारा तकनीक के उपयोग के विभिन्न तरीकों से प्रभावित है,  जिससे किसानों को फोन के माध्यम अपनी उपज की निगरानी करने में सहायता है।",
              "ka": "ಫೋನ್ ಸಹಾಯದಿಂದ ರೈತರು ತಮ್ಮ ಇಳುವರಿಯ ಬಗ್ಗೆ ಮೇಲ್ವಿಚಾರಣೆ ಮಾಡಲು ಸಹಾಯ ಮಾಡಲು ಕ್ರಾಪ್‌ಸೆನ್ಸ್ ತಂತ್ರಜ್ಞಾನವನ್ನು ಬಳಸುವ ವಿಭಿನ್ನ ವಿಧಾನಗಳಿಂದ ಶಿಖರ್ ಪ್ರಭಾವಿತರಾಗಿದ್ದಾನೆ.",
              "te": "ఫోన్ సహాయంతో రైతులు తమ దిగుబడిని పర్యవేక్షించడంలో సహాయపడటానికి క్రాప్‌సెన్స్ సాంకేతికతను ఉపయోగించే వివిధ మార్గాల ద్వారా శిఖర్ ఆకట్టుకున్నాడు.",
              "or": "କ୍ଫସଲ ବିଜ୍ଞାନ ଟେକ୍ନୋଲୋଜି ବ୍ୟବହାର କରି କୃଷକମାନଙ୍କୁ ଏକ ଫୋନ୍ ସାହାଯ୍ୟରେ \n ସେମାନଙ୍କର ଅମଳ ଉପରେ ନଜର ରଖିବାରେ ସାହାଯ୍ୟ କରିବାକୁ ଶିଖର ପ୍ରଭାବିତ |",
              "as": "ক্ৰপচেঞ্চে ফোনৰ সহায়ত কৃষকসকলক তেওঁলোকৰ উৎপাদন চোৱাত সহায় কৰিবলৈ প্ৰযুক্তি ব্যৱহাৰৰ বিভিন্ন উপায়ৰ দ্বাৰা শিখৰ প্ৰভাৱিত হৈছে।",
              "gu": "ફોનની મદદથી ખેડૂતોને તેમની ઉપજ પર દેખરેખ રાખવામાં મદદ કરવા માટે ક્રોપસેન્સ ટેક્નોલોજીનો ઉપયોગ કરતી વિવિધ રીતોથી શિખર પ્રભાવિત થયો છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_8",
              "hi": "B2_1_8",
              "ka": "B2_1_8",
              "te": "B2_1_8",
              "or": "B2_1_8",
              "as": "B2_1_8",
              "gu": "B2_1_8"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He is also impressed with how they have made technology simple for all farmers to understand and use it.",
              "hi": "वह इस बात से भी प्रभावित हैं कि उन्होंने सभी किसानों को समझने और इसका उपयोग करने के लिए तकनीक को कितना सरल बनाया है।",
              "ka": "ಅವರು ತಂತ್ರಜ್ಞಾನವನ್ನು ಎಲ್ಲಾ ರೈತರಿಗೆ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಮತ್ತು ಬಳಸಲು ಹೇಗೆ ಸರಳಗೊಳಿಸಿದ್ದಾರೆ ಎನ್ನುವುದರ ಬಗ್ಗೆ ಅವನು ಪ್ರಭಾವಿತರಾಗಿದ್ದಾನೆ.",
              "te": "రైతులందరికీ అర్థం చేసుకోవడానికి మరియు ఉపయోగించుకునేలా సాంకేతికతను వారు ఎలా సులభతరం చేశారో కూడా అతను ఆకట్టుకున్నాడు.",
              "or": "ସମସ୍ତ କୃଷକ ଏହାକୁ ବୁଝିବା ଏବଂ ବ୍ୟବହାର କରିବା ପାଇଁ ସେମାନେ କିପରି\n  ଟେକ୍ନୋଲୋଜିକୁ ସରଳ କରିଛନ୍ତି ସେଥିରେ ସେ ମଧ୍ୟ ପ୍ରଭାବିତ ହୋଇଛନ୍ତି |",
              "as": "সকলো কৃষকৰ বাবে কেনেকৈ প্ৰযুক্তিক বুজিবলৈ আৰু ব্যৱহাৰ কৰিবলৈ সহজ কৰি তুলিছে তাক লৈও সি প্ৰভাৱিত হৈছে।",
              "gu": "તે એ વાતથી પણ પ્રભાવિત છે કે કેવી રીતે તેઓએ તમામ ખેડૂતો માટે ટેકનોલોજીને સમજવા અને તેનો ઉપયોગ કરવા માટે સરળ બનાવી છે."
            }
          }
        ]
      },
      "scene41": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_iii_1_1",
              "hi": "B2_III_1_1",
              "ka": "B2_iii_1_1",
              "te": "B2_iii_1_1",
              "or": "B2_iii_1_1",
              "as": "B2_iii_1_1",
              "gu": "B2_iii_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar and Devansh attend a demonstration of the latest machine introduced to remove wild grass from the farms.",
              "hi": "शिखर और देवांश खेतों से जंगली घास को हटाने के लिए प्रस्तुत की गई नवीनतम मशीन के प्रदर्शन में भाग लेते हैं।",
              "ka": "ಶಿಖರ್ ಮತ್ತು ದೇವಾಂಶ್ ಅವರು ಹೊಲಗಳಿಂದ ಕಾಡು ಹುಲ್ಲನ್ನು ತೆಗೆಯಲು ಪರಿಚಯಿಸಲಾದ ಇತ್ತೀಚಿನ ಯಂತ್ರದ ಪ್ರದರ್ಶನದಲ್ಲಿ ಭಾಗವಹಿಸಿದರು.",
              "te": "పొలాల నుండి అడవి గడ్డిని తొలగించడానికి ప్రవేశపెట్టిన సరికొత్త యంత్రం యొక్క ప్రదర్శనకు శిఖర్ మరియు దేవాన్ష్ హాజరయ్యారు.",
              "or": "ଚାଷଜମିରୁ ବଣୁଆ ଘାସ ହଟାଇବା ପାଇଁ ପରିଚିତ ନୂତନ ଯନ୍ତ୍ରର ଏକ \n ପ୍ରଦର୍ଶନରେ ଶିଖର ଏବଂ ଦେବାଂଶ ଉପସ୍ଥିତ ଥିଲେ |",
              "as": "শিখৰ আৰু দেৱাংশে খেতিপথাৰৰ পৰা বনৰীয়া ঘাঁহ আঁতৰাবলৈ বনোৱা শেহতীয়া যন্ত্ৰটোৰ এক প্ৰদৰ্শনত ভাগ লয়।",
              "gu": "શિખર અને દેવાંશ ખેતરોમાંથી જંગલી ઘાસને દૂર કરવા માટે રજૂ કરાયેલ નવીનતમ મશીનના પ્રદર્શનમાં હાજરી આપે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_iii_1_2///",
              "hi": "B2_III_1_2 ////",
              "ka": "B2_iii_1_2///",
              "te": "B2_iii_1_2///",
              "or": "B2_iii_1_2///",
              "as": "B2_iii_1_2///",
              "gu": "B2_iii_1_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "The machine looks very simple to operate. Look at how fast those blades work!! It could clear all the weeds out in a few hours.",
              "hi": "मशीन संचालित करने के लिए बहुत सहज लगती है। देखो, वो ब्लेड कितनी तेजी से काम करते हैं !! यह कुछ घंटों में सभी खरपतवार को साफ़ कर सकता है।",
              "ka": "ಯಂತ್ರವು ಕಾರ್ಯನಿರ್ವಹಿಸಲು ತುಂಬಾ ಸರಳವಾಗಿದೆ. ಆ ಬ್ಲೇಡ್‌ಗಳು ಎಷ್ಟು ವೇಗವಾಗಿ ಕೆಲಸ ಮಾಡುತ್ತವೆ ನೋಡು!! ಇದು ಕೆಲವೇ ಗಂಟೆಗಳಲ್ಲಿ ಎಲ್ಲಾ ಕಳೆಗಳನ್ನು ತೆರವುಗೊಳಿಸಬಹುದು.",
              "te": "యంత్రం ఆపరేట్ చేయడం చాలా సులభం. ఆ బ్లేడ్లు ఎంత వేగంగా పనిచేస్తాయో చూడండి!! ఇది కొన్ని గంటల్లో కలుపు మొక్కలన్నింటినీ తొలగించగలదు.",
              "or": "କାର୍ଯ୍ୟ କରିବା ପାଇଁ ମେସିନ୍ ବହୁତ ସରଳ ଦେଖାଯାଏ | ଦେଖନ୍ତୁ ସେହି ବ୍ଲେଡଗୁଡିକ\n  କେତେ ଶୀଘ୍ର କାମ କରେ !! ଏହା କିଛି ଘଣ୍ଟାରେ ସମସ୍ତ ଘାସକୁ ସଫା କରିପାରେ |",
              "as": "যন্ত্ৰটো চলাবলৈ অতি সহজ যেন লাগিছে। সেই ব্লেডবোৰে কিমান জোৰেৰে কাম কৰে চাওক!! এইটোৱে কেইঘণ্টামানৰ ভিতৰতে সকলো অপতৃণ চাফা কৰিব পাৰে।",
              "gu": "મશીન ચલાવવા માટે ખૂબ જ સરળ લાગે છે. તે બ્લેડ કેટલી ઝડપથી કામ કરે છે તે જુઓ!! તે થોડા કલાકોમાં તમામ નીંદણને સાફ કરી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "It is fascinating to see how technology can save time and improve efficiency.",
              "hi": "यह देखने में कितना आकर्षक लगता है कि तकनीक समय बचा सकती है और दक्षता में सुधार कर सकती है।",
              "ka": "ತಂತ್ರಜ್ಞಾನವು ಸಮಯವನ್ನು ಹೇಗೆ ಉಳಿಸುತ್ತದೆ ಮತ್ತು ದಕ್ಷತೆಯನ್ನು ಸುಧಾರಿಸುತ್ತದೆ ಎಂಬುದನ್ನು ನೋಡಲು ಬಹಳ ಆಕರ್ಷಕವಾಗಿದೆ.",
              "te": "సాంకేతికత సమయాన్ని ఎలా ఆదా చేస్తుందో మరియు సామర్థ్యాన్ని ఎలా మెరుగుపరుస్తుందో చూడటం మనోహరంగా ఉంది.",
              "or": "ଟେକ୍ନୋଲୋଜି କିପରି ସମୟ ସଞ୍ଚୟ କରିପାରିବ ଏବଂ ଦକ୍ଷତା ବୃଦ୍ଧି କରିପାରିବ\n  ତାହା ଦେଖିବା ଆକର୍ଷଣୀୟ |",
              "as": "প্ৰযুক্তিয়ে কেনেকৈ সময় ৰাহি কৰিব পাৰে আৰু দক্ষতা উন্নত কৰিব পাৰে সেয়া চাবলৈ আকৰ্ষণীয়।",
              "gu": "ટેક્નોલોજી કેવી રીતે સમય બચાવી શકે છે અને કાર્યક્ષમતામાં સુધારો કરી શકે છે તે જોવાનું રસપ્રદ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "And this is just one of the innovations. I'm sure there are many other stalls like this.",
              "hi": "और यह नवीन खोजों में से सिर्फ एक है। मुझे यकीन है कि इस तरह के अनेक स्टाल हैं।",
              "ka": "ಮತ್ತು  ನಾವೀನ್ಯತೆಗಳಲ್ಲಿ ಇದು ಕೇವಲ ಒಂದಾಗಿದೆ. ಈ ರೀತಿಯ ಇನ್ನೂ ಅನೇಕ ಸ್ಟಾಲ್‌ಗಳಿವೆ ಎಂದು ನನಗೆ ಖಾತ್ರಿಯಿದೆ.",
              "te": "మరియు ఇది ఆవిష్కరణలలో ఒకటి మాత్రమే. ఇలాంటి స్టాల్స్ ఇంకా చాలా ఉన్నాయని నేను ఖచ్చితంగా అనుకుంటున్నాను.",
              "or": "ଏବଂ ଏହା କେବଳ ଗୋଟିଏ ଉଦ୍ଭାବନ | ମୁଁ ନିଶ୍ଚିତ ଯେ ଏହି ପରି ଅନ୍ୟାନ୍ୟ \n ଷ୍ଟଲ୍ ଅଛି |",
              "as": "আৰু এইটো মাত্ৰ এটা উদ্ভাৱন। মই নিশ্চিত যে এনে আন বহুতো দোকান আছে।",
              "gu": "અને આ માત્ર એક નવીનતા છે. મને ખાતરી છે કે આના જેવા બીજા ઘણા સ્ટોલ છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_iii_1_3",
              "hi": "B2_III_1_3",
              "ka": "B2_iii_1_3",
              "te": "B2_iii_1_3",
              "or": "B2_iii_1_3",
              "as": "B2_iii_1_3",
              "gu": "B2_iii_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Next, they visit a stall where they see an app used for weather forecasting. Shikhar and Devansh learn that they can get regular updates and detailed analysis about changing weather with such an app.",
              "hi": "इसके बाद, वे एक स्टाल पर जाते हैं जहां वे मौसम पूर्वानुमान के लिए उपयोग किए जाने वाले ऐप को देखते हैं। शिखर और देवांश सीखते हैं कि वे इस तरह के ऐप द्वारा मौसम बदलने के बारे में नवीनतम जानकारी और विस्तृत विश्लेषण नियमित प्राप्त कर सकते हैं।",
              "ka": "ಮುಂದೆ, ಅವರು ಸ್ಟಾಲ್‌ಗೆ ಭೇಟಿ ನೀಡುತ್ತಾರೆ, ಅಲ್ಲಿ ಅವರು ಹವಾಮಾನ ಮುನ್ಸೂಚನೆಗಾಗಿ ಬಳಸಲಾದ ಆ್ಯಪ್‌ ಅನ್ನು ನೋಡುತ್ತಾರೆ. ಶಿಖರ್ ಮತ್ತು ದೇವಾಂಶ್ ಅವರು ಅಂತಹ ಆ್ಯಪ್‌ನೊಂದಿಗೆ ಹವಾಮಾನವನ್ನು ಬದಲಾಯಿಸುವ ಕುರಿತು ನಿಯಮಿತ ನವೀಕರಣಗಳು ಮತ್ತು ವಿವರವಾದ ವಿಶ್ಲೇಷಣೆಯನ್ನು ಪಡೆಯಬಹುದು ಎಂದು ತಿಳಿದುಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "తర్వాత, వారు ఒక స్టాల్‌ ను సందర్శిస్తారు, అక్కడ వారు వాతావరణ సూచన కోసం ఉపయోగించే యాప్‌ను చూస్తారు. శిఖర్ మరియు దేవాన్ష్ అటువంటి యాప్‌తో వాతావరణ మార్పుల గురించి రెగ్యులర్ అప్‌డేట్లు మరియు వివరణాత్మక విశ్లేషణలను పొందవచ్చని తెలుసుకున్నారు.",
              "or": "ପରବର୍ତ୍ତୀ ସମୟରେ, ସେମାନେ ଏକ ଷ୍ଟଲ୍ ପରିଦର୍ଶନ କରନ୍ତି ଯେଉଁଠାରେ \n ସେମାନେ ପାଣିପାଗ ପୂର୍ବାନୁମାନ ପାଇଁ ବ୍ୟବହୃତ ଏକ ଆପ୍ ଦେଖନ୍ତି |\n  ଶିଖର ଏବଂ ଦେବନଶ ଜାଣନ୍ତି ଯେ ସେମାନେ ଏପରି ଏକ ଆପ୍ ସହିତ ପାଣିପାଗ \n ପରିବର୍ତ୍ତନ ବିଷୟରେ ନିୟମିତ ଅଦ୍ୟତନ ଏବଂ ବିସ୍ତୃତ ବିଶ୍ଳେଷଣ ପାଇପାରିବେ |",
              "as": "ইয়াৰ পিছত, সিহঁতে এখন দোকানলৈ যায় য'ত সিহঁতে বতৰৰ আগতীয়া অনুমানৰ বাবে ব্যৱহৃত এপ এটা দেখিছে। শিখৰ আৰু দেৱাংশে শিকিছে যে সিহঁতে এনে এটা এপৰ সৈতে বতৰ সলনি হোৱাৰ বিষয়ে নিয়মীয়া আপডেট আৰু বিতং বিৱৰণ পাব পাৰে।",
              "gu": "આગળ, તેઓ એક સ્ટોલની મુલાકાત લે છે જ્યાં તેઓ હવામાનની આગાહી માટે ઉપયોગમાં લેવાતી એપ્લિકેશન જુએ છે. શિખર અને દેવાંશ શીખે છે કે તેઓ આવી એપ દ્વારા બદલાતા હવામાન વિશે નિયમિત બદલાવ અને વિગતવાર વિશ્લેષણ મેળવી શકે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_iii_1_4",
              "hi": "B2_III_1_4",
              "ka": "B2_iii_1_4",
              "te": "B2_iii_1_4",
              "or": "B2_iii_1_4",
              "as": "B2_iii_1_4",
              "gu": "B2_iii_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They realise that this app can give them advance notice of heavy rains or heat waves, which can help them save their crops.",
              "hi": "उन्हें एहसास है कि यह ऐप उन्हें भारी बारिश या गर्म हवाओं के चलने की अग्रिम सूचना दे सकता है, जो उन्हें अपनी फसलों को बचाने में सहायक हो सकता है।",
              "ka": "ಈ ಅಪ್ಲಿಕೇಶನ್ ಭಾರೀ ಮಳೆ ಅಥವಾ ಶಾಖದ ಅಲೆಗಳ ಬಗ್ಗೆ ಮುಂಚಿತವಾಗಿ ಸೂಚನೆ ನೀಡಬಹುದು ಎಂದು ಅವನು ಅರಿತುಕೊಳ್ಳುತ್ತಾನೆ, ಹಾಗಾಗಿ ಇದು ತಮ್ಮ ಬೆಳೆಗಳನ್ನು ಉಳಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ ಎನ್ನುವುದು",
              "te": "ఈ యాప్ భారీ వర్షాలు లేదా వేడి తరంగాల గురించి ముందుగానే తెలియజేయగలదని, ఇది తమ పంటలను కాపాడుకోవడంలో సహాయపడుతుందని వారు గ్రహించారు.",
              "or": "ସେମାନେ ହୃଦୟଙ୍ଗମ କରିଛନ୍ତି ଯେ ଏହି ଆପ୍ ସେମାନଙ୍କୁ ପ୍ରବଳ ବର୍ଷା କିମ୍ବା\n  ଉତ୍ତାପ ତରଙ୍ଗର ଅଗ୍ରୀମ ସୂଚନା ଦେଇପାରେ, ଯାହା ସେମାନଙ୍କୁ ଫସଲ ସଞ୍ଚୟ\n  କରିବାରେ ସାହାଯ୍ୟ କରିଥାଏ |",
              "as": "সিহঁতে ভাবে যে এই এপটোৱে সিহঁতক ধাৰাসাৰ বৰষুণ বা তাপ তৰংগৰ আগতীয়া জাননী দিব পাৰে, যি সিহঁতক সিহঁতৰ শস্য ৰক্ষা কৰাত সহায় কৰিব পাৰে।",
              "gu": "તેઓ સમજે છે કે આ એપ તેમને ભારે વરસાદ અથવા ગરમીના મોજાની અગાઉથી સૂચના આપી શકે છે, જે તેમને તેમના પાકને બચાવવામાં મદદ કરી શકે છે."
            }
          }
        ]
      },
      "scene42": {
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_1_1 bg",
              "hi": "B3_1_1 बीजी",
              "ka": "B3_1_1 bg",
              "te": "B3_1_1 bg",
              "or": "B3_1_1 bg",
              "as": "B3_1_1 bg",
              "gu": "B3_1_1 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar and Devansh are back home, and Shikhar is sure he wants to spend his time learning something new.",
              "hi": "शिखर और देवांश घर वापस आ गए हैं, और शिखर को विश्वास है कि वह अपना समय कुछ नया सीखने में बिताना चाहता है।",
              "ka": "ಶಿಖರ್ ಮತ್ತು ದೇವಾಂಶ್ ಮನೆಗೆ ಮರಳಿದ್ದಾರೆ ಮತ್ತು ಶಿಖರ್  ಹೊಸದನ್ನು ಕಲಿಯಲು ತನ್ನ ಸಮಯವನ್ನು ಕಳೆಯಲು ಬಯಸುತ್ತಾನೆ ಎಂಬುದು ಖಚಿತವಾಗಿದೆ.",
              "te": "శిఖర్ మరియు దేవాన్ష్ ఇంటికి తిరిగి వచ్చారు, శిఖర్ ఖచ్చితంగా ఏదైనా కొత్త విషయాలను నేర్చుకోవడానికి తన సమయాన్ని వెచ్చించాలని కోరుకుంటున్నాడు.",
              "or": "ଶିଖର ଏବଂ ଦେବାଂଶ ଘରକୁ ଫେରିଛନ୍ତି, ଏବଂ ଶିଖର ନିଶ୍ଚିତ ଯେ ସେ କିଛି \n ନୂଆ ଶିଖିବାରେ ସମୟ ବିତାଇବାକୁ ଚାହୁଁଛନ୍ତି |",
              "as": "শিখৰ আৰু দেৱাংশ ঘৰলৈ উভতি আহিছে, আৰু শিখৰে নিশ্চিত যে সি নতুন কিবা শিকি সময় পাৰ কৰিব বিচাৰে।",
              "gu": "શિખર અને દેવાંશ ઘરે પાછા ફર્યા છે, અને શિખરને ખાતરી છે કે તે કંઈક નવું શીખવામાં પોતાનો સમય પસાર કરવા માંગે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arun Bhaiya",
              "hi": "अरुण भैया ",
              "ka": "ಅರುಣ್ ಭೈಯ್ಯ",
              "te": "అరుణ్ అన్నయ్య",
              "or": "ଅରୁଣ ଭାଇ",
              "as": "অৰুণ দাদা",
              "gu": "અરુણ ભાઈ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I’m so glad that you told me about Krishi Mela! There was so much that I learnt there.",
              "hi": "मुझे बहुत खुशी है कि आपने मुझे कृषि मेले के बारे में बताया! वहां पर इतना कुछ था जो मैंने सीखा।",
              "ka": "ನೀವು ನನಗೆ ಕೃಷಿ ಮೇಳದ ಬಗ್ಗೆ ಹೇಳಿದ್ದಕ್ಕೆ ನನಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿದೆ! ಅಲ್ಲಿ ಕಲಿತದ್ದು ತುಂಬಾ ಇದೆ.",
              "te": "మీరు కృషి మేళా గురించి నాకు చెప్పినందుకు నేను చాలా సంతోషిస్తున్నాను! అక్కడ నేను నేర్చుకున్నవి చాలా ఉన్నాయి.",
              "or": "ମୁଁ ବହୁତ ଖୁସି ଯେ ତୁମେ ମୋତେ କୃଷି ମେଲା ବିଷୟରେ କହିଛ! ସେଠାରେ \n ବହୁତ କିଛି ଥିଲା ଯାହା ମୁଁ ସେଠାରେ ଶିଖିଲି |",
              "as": "মই বহুত সুখী যে তুমি মোক কৃষি মেলাৰ বিষয়ে কৈছিলা! তাত বহুত শিকিলোঁ।",
              "gu": "મને ખૂબ આનંદ થયો કે તું મને કૃષિ મેળા વિશે કહ્યું! હું ત્યાં ઘણું બધું શીખ્યો."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Really?! I’m happy that you went to Jabalpur for it.",
              "hi": "सच में?! मुझे खुशी है कि तुम इसके लिए जबलपुर गए।",
              "ka": "ನಿಜವಾಗಿಯೂ?! ಅದಕ್ಕಾಗಿ ನೀನು ಜಬಲ್‌ಪುರಕ್ಕೆ ಹೋಗಿದ್ದಕ್ಕೆ ನನಗೆ ಸಂತೋಷವಾಗಿದೆ.",
              "te": "నిజమేనా?! మీరు దాని కోసం జబల్పూర్ వెళ్ళినందుకు నేను సంతోషిస్తున్నాను.",
              "or": "ପ୍ରକୃତରେ ?! ମୁଁ ଖୁସି ଯେ ତୁମେ ଏହା ପାଇଁ ଜବଲପୁର ଯାଇଥିଲ |",
              "as": "সঁচাকৈয়ে?! মই সুখী যে তুমি ইয়াৰ বাবে জবলপুৰলৈ গৈছিলা।",
              "gu": "ખરેખર?! હું ખુશ છું કે તું આના માટે જબલપુર ગયા હતા."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Bhaiya, you’ve seen how our small experiments are helping us on the farm. I think that the possibilities are endless with this. I want to explore something which can help us with our farming.",
              "hi": "भैया, आपने देखा है कि कैसे हमारे छोटे प्रयोग खेत में हमारी सहायता कर रहे हैं। मुझे लगता है कि इसमें अनंत  संभावनाएं हैं। मैं कुछ ऐसा अन्वेषण करना चाहता हूं जो हमारी खेती में हमारी मदद कर सके।",
              "ka": "ಭೈಯ್ಯ, ನಮ್ಮ ಸಣ್ಣ ಪ್ರಯೋಗಗಳು ಜಮೀನಿನಲ್ಲಿ ನಮಗೆ ಹೇಗೆ ಸಹಾಯ ಮಾಡುತ್ತಿವೆ ಎಂಬುದನ್ನು ನೀವು ನೋಡಿದ್ದೀರಿ. ಇದರೊಂದಿಗೆ ಸಾಧ್ಯತೆಗಳು ಅಂತ್ಯವಿಲ್ಲ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ನಮ್ಮ ಕೃಷಿಯಲ್ಲಿ ನಮಗೆ ಸಹಾಯ ಮಾಡುವ ಯಾವುದನ್ನಾದರೂ ಅನ್ವೇಷಿಸಲು ನಾನು ಬಯಸುತ್ತೇನೆ.",
              "te": "అన్నయ్య, పొలంలో మా చిన్నపాటి ప్రయోగాలు మనకు ఎలా సహాయపడుతున్నాయో మీరు చూశారు. దీనితో అవకాశాలు అంతంత మాత్రమే అని నేను అనుకుంటున్నాను. మన వ్యవసాయంలో మనకు సహాయపడేదాన్ని నేను అన్వేషించాలనుకుంటున్నాను.",
              "or": "ଭାଇ , ତୁମେ ଦେଖିଛ କି ଆମର ଛୋଟ ପରୀକ୍ଷଣଗୁଡିକ ଚାଷରେ କିପରି ସାହାଯ୍ୟ\n  କରେ | ମୁଁ ଭାବୁଛି ଏହା ସହିତ ସମ୍ଭାବନାଗୁଡ଼ିକ ଅସୀମ | ମୁଁ ଏପରି କିଛି ଅନୁସନ୍ଧାନ\n  କରିବାକୁ ଚାହୁଁଛି ଯାହା ଆମ ଚାଷରେ ଆମକୁ ସାହାଯ୍ୟ କରିପାରିବ |",
              "as": "দাদা, তুমি দেখিছা আমাৰ সৰু পৰীক্ষাবোৰে আমাক খেতিপথাৰত কেনেদৰে সহায় কৰিছে। মই ভাবো যে ইয়াৰ সৈতে সম্ভাৱনাৰ শেষ নাই। মই আমাক আমাৰ কৃষিত সহায় কৰিব পৰা কিবা এটা চাব বিচাৰো।",
              "gu": "ભાઈ, તમે જોયું છે કે અમારા નાના પ્રયોગો અમને ખેતરમાં કેવી રીતે મદદ કરી રહ્યા છે. મને લાગે છે કે આની સાથે શક્યતાઓ અનંત છે. હું કંઈક વધારે સોધવા માંગુ છું જે આપણી ખેતીમાં મદદ કરી શકે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_1_3///",
              "hi": "B3_1_3 ///",
              "ka": "B3_1_3///",
              "te": "B3_1_3///",
              "or": "B3_1_3///",
              "as": "B3_1_3///",
              "gu": "B3_1_3///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Do you have something in mind?",
              "hi": "क्या आपके मन में कुछ है?",
              "ka": "ನಿನ್ನ ಮನಸ್ಸಿನಲ್ಲಿ ಏನಾದರೂ ಇದೆಯೇ?",
              "te": "మీ మనసులో ఏదైనా ఉందా?",
              "or": "ଆପଣଙ୍କ ମନରେ କିଛି ଅଛି କି?",
              "as": "তোমাৰ মনত কিবা আছে নেকি?",
              "gu": "શું તમારા મનમાં કંઈક છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I came across 2 good opportunities at the Mela. One is to do a course in Agricultural Science and second is to take up a job with an agricultural technology organisation, CropSense. Now I need to decide which one to take up.",
              "hi": " मेले में मुझे दो रोचक उपलब्धियों के विषय में जानकारी मिली। पहला कृषि विज्ञान में कोर्स करना और दूसरा कृषि तकनीकी संगठन, क्रॉप्सेंस में नौकरी करना। अब मुझे यह निर्णय लेने की आवश्यकता है कि मैं कौन सा काम करूं।",
              "ka": "ಮೇಳದಲ್ಲಿ ನನಗೆ 2 ಒಳ್ಳೆಯ ಅವಕಾಶಗಳು ಸಿಕ್ಕಿದವು. ಒಂದು ಕೃಷಿ ವಿಜ್ಞಾನದಲ್ಲಿ ಕೋರ್ಸ್ ಮಾಡುವುದು ಮತ್ತು ಎರಡನೆಯದು ಕೃಷಿ ತಂತ್ರಜ್ಞಾನ ಸಂಸ್ಥೆ, ಕ್ರಾಪ್‌ಸೆನ್ಸ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದು. ಯಾವುದನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕೆಂದು ಈಗ ನಾನು ನಿರ್ಧರಿಸಬೇಕಾಗಿದೆ.",
              "te": "మేళాలో నాకు 2 మంచి అవకాశాలు వచ్చాయి. ఒకటి వ్యవసాయ శాస్త్రం లో కోర్సు చేయడం మరియు రెండవది వ్యవసాయ సాంకేతిక సంస్థ క్రాప్‌సెన్స్‌లో ఉద్యోగం చేయడం. ఇప్పుడు నేను ఏది తీసుకోవాలో నిర్ణయించుకోవాలి.",
              "or": "ମୁଁ ମେଲାରେ ଦୁଇଟି ଟି ଭଲ ସୁଯୋଗ ପାଇଲି | ଗୋଟିଏ ହେଉଛି କୃଷି ବିଜ୍ଞାନରେ \n ପାଠ୍ୟକ୍ରମ କରିବା ଏବଂ ଦ୍ୱିତୀୟଟି ହେଉଛି କୃଷି ପ୍ରଯୁକ୍ତିବିଦ୍ୟା ସଂଗଠନ, ଫସଲ ବିଜ୍ଞାନ \n ସହିତ ଚାକିରି କରିବା | ବର୍ତ୍ତମାନ ମୁଁ ନିଷ୍ପତ୍ତି ନେବାକୁ ପଡିବ କେଉଁଟିକୁ ନେଇଯିବି |",
              "as": "মই মেলাত ২ টা ভাল সুযোগ পাইছিলো। এটা হ'ল কৃষি বিজ্ঞানৰ এটা পাঠ্যক্ৰম কৰা আৰু দ্বিতীয়টো হ'ল ক্ৰপচেঞ্চ নামৰ কৃষি প্ৰযুক্তি সংগঠনএটাৰ সৈতে চাকৰি লোৱা। এতিয়া মই কোনটো ল'ব লাগে সিদ্ধান্ত ল'ব লাগিব।",
              "gu": "મને મેળામાં 2 સારી તકો મળી. એક એગ્રીકલ્ચરલ સાયન્સનો કોર્સ કરવાનો છે અને બીજો એગ્રીકલ્ચર ટેક્નોલોજી ઓર્ગેનાઈઝેશન, ક્રોપસેન્સમાં નોકરી લેવાનો છે. હવે મારે નક્કી કરવાની જરૂર છે કે કયું લેવું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "What are you interested in doing more?",
              "hi": "तुम और क्या करने में रुचि रखते हो?",
              "ka": "ನೀನು ಇದರ ಬಗ್ಗೆ ಇನ್ನೂ ಏನು ಹೆಚ್ಚು ಏನು ಮಾಡಲು ಆಸಕ್ತಿ ಹೊಂದಿದ್ದೀಯಾ?",
              "te": "మీరు ఎక్కువగా ఏమి చేయడానికి ఆసక్తి కలిగి ఉన్నారు?",
              "or": "ଆପଣ କଣ ଅଧିକ କରିବାକୁ ଆଗ୍ରହୀ କି?",
              "as": "তুমি অধিক কি কৰিবলৈ আগ্ৰহী?",
              "gu": "તને વધુ શું કરવામાં રસ છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "I am not sure. I will read more from the brochures I've got or talk to the Professor I met there.",
              "hi": "मैं समझ नहीं पा रहा हूँ। मैं उन ब्रोशर को और अधिक पढ़ूंगा जो मुझे वहां मिले हैं या उन प्रोफेसर से बात करूंगा जिनसे वहां मुलाकात हुई थी।",
              "ka": "ನನಗೆ ಖಚಿತವಿಲ್ಲ. ನನಗೆ ಸಿಕ್ಕಿರುವ ಕರಪತ್ರಗಳಿಂದ ನಾನು ಹೆಚ್ಚಿನದನ್ನು ಓದುತ್ತೇನೆ ಅಥವಾ ಅಲ್ಲಿ ನಾನು ಭೇಟಿಯಾದ ಪ್ರಾಧ್ಯಾಪಕರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತೇನೆ.",
              "te": "నాకు ఖచ్చితంగా తెలియదు. నేను పొందిన బ్రోచర్లను మరింత చదువుతాను లేదా అక్కడ నేను కలిసిన ప్రొఫెసర్‌ తో మాట్లాడతాను.",
              "or": "ମୁଁ ନିଶ୍ଚିତ ନୁହେଁ ମୁଁ ପାଇଥିବା ବିବରଣ ଗୁଡ଼ିକରୁ ମୁଁ ଅଧିକ ପଢିବି କିମ୍ବା ସେଠାରେ \n ମୁଁ ସାକ୍ଷାତ କରିଥିବା ପ୍ରଫେସରଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରିବି |",
              "as": "মই নিশ্চিত নহয়। মই পোৱা পুস্তিকাবোৰৰ পৰা অধিক পঢ়িম বা তাত লগ পোৱা অধ্যাপকৰ সৈতে কথা পাতিম।",
              "gu": "મને ખાતરી નથી. મને મળેલી પુસ્તિકા હું વધુ વાંચીશ અથવા ત્યાં મને મળેલા પ્રાધ્યાપક સાથે વાત કરીશ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Make sure whatever you take up will lead to something useful for you and us.",
              "hi": "ध्यान रखना कि जो भी तुम करोगे, वह तुम्हारे और हमारे लिए कुछ उपयोगी होगा।",
              "ka": "ನೀನು ಕೈಗೆತ್ತಿಕೊಳ್ಳುವ ಯಾವುದೇ ವಿಷಯವು ನಿನಗೆ ಮತ್ತು ನಮಗೆ ಉಪಯುಕ್ತವಾದದ್ದಕ್ಕೆ ಕಾರಣವಾಗುತ್ತದೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳು.",
              "te": "నువ్వు చేసేదేదైనా నీకు మరియు మాకు ఉపయోగకరంగా ఉండేలాగా చూసుకో.",
              "or": "ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ଯାହା ନିଅନ୍ତି ତାହା ଆପଣଙ୍କ ଏବଂ ଆମ ପାଇଁ \n କିଛି ଉପଯୋଗୀ ହେବ |",
              "as": "নিশ্চিত কৰিবা যে তুমি যি লোৱা সেয়া তোমাৰ আৰু আমাৰ বাবে উপযোগী হয়।",
              "gu": "ખાતરી કરો કે તું જે પણ કરે છે તે તારા અને આપણા માટે કંઈક ઉપયોગી થાય."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_1_4 D",
              "hi": "B3_1_4 d",
              "ka": "B3_1_4 D",
              "te": "B3_1_4 D",
              "or": "B3_1_4 D",
              "as": "B3_1_4 D",
              "gu": "B3_1_4 D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should he do?",
              "hi": "उसे क्या करना चाहिए?",
              "ka": "ಆತನು ಏನು ಮಾಡಬೇಕು?",
              "te": "అతను ఏమి చేయాలి?",
              "or": "ସେ କ’ଣ କରିବା ଉଚିତ୍?",
              "as": "সি কি কৰিব লাগে?",
              "gu": "તેણે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Research more himself",
              "hi": "स्वयं अधिक शोध करे",
              "ka": "ಸ್ವತಃ ಹೆಚ್ಚು ಸಂಶೋಧನೆ ಮಾದುವುದು",
              "te": "స్వయంగా మరింత పరిశోధన చేయడం",
              "or": "ନିଜେ ଅଧିକ ଅନୁସନ୍ଧାନ କରନ୍ତୁ |",
              "as": "নিজে অধিক গৱেষণা কৰা",
              "gu": "પોતે વધુ સંશોધન કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Talk to Professor Nag to get more clarity",
              "hi": "अधिक स्पष्टता पाने के लिए प्रोफेसर नाग से बात करे",
              "ka": "ಹೆಚ್ಚಿನ ಸ್ಪಷ್ಟತೆ ಪಡೆಯಲು ಪ್ರೊಫೆಸರ್ ನಾಗ್ ಅವರೊಂದಿಗೆ ಮಾತನಾಡುವುದು",
              "te": "మరింత స్పష్టత వచ్చేలా ప్రొఫెసర్ నాగ్‌ తో మాట్లాడడం",
              "or": "ଅଧିକ ସ୍ପଷ୍ଟତା ପାଇବା ପାଇଁ ପ୍ରଫେସର ନାଗଙ୍କ ସହ କଥା ହୁଅନ୍ତୁ |",
              "as": "অধিক স্পষ্ট হ'বলৈ অধ্যাপক নাগৰ সৈতে কথা পতা",
              "gu": "વધુ સ્પષ્ટતા મેળવવા માટે પ્રાધ્યાપક નાગ સાથે વાત કરો"
            }
          }
        ]
      },
      "scene43": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Research on his own",
              "hi": "अपने आप शोध करे ",
              "ka": "ಸ್ವಂತವಾಗಿ ಸಂಶೋಧನೆ ಮಾಡುವುದೇ",
              "te": "సొంతంగా పరిశోధన చేయడం",
              "or": "ନିଜେ ଗବେଷଣା |",
              "as": "নিজে গৱেষণা কৰা",
              "gu": "પોતાના પર સંશોધન કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He reads both the brochures he picks up from the Mela. He likes soil and water management course and is also fascinated by the CropSense work.",
              "hi": "वह मेले से लाए दोनों ब्रोशर पढ़ता है। उसे मिट्टी और जल प्रबंधन कोर्स पसंद है और वह क्रॉप्सेंस के काम के प्रति भी आकर्षित है।",
              "ka": "ಆತನು ಮೇಳದಿಂದ ತಂದಿರುವ ಎರಡೂ ಕರಪತ್ರಗಳನ್ನು ಓದುತ್ತಾನೆ. ಅವನು ಮಣ್ಣು ಮತ್ತು ನೀರು ನಿರ್ವಹಣೆ ಕೋರ್ಸ್ ಅನ್ನು ಇಷ್ಟಪಡುತ್ತಾನೆ ಮತ್ತು ಕ್ರಾಪ್ಸೆನ್ಸ್ ಕೆಲಸದಿಂದ ಆಕರ್ಷಿತನಾಗಿದ್ದಾನೆ.",
              "te": "అతను మేళా నుండి తీసుకున్న రెండు బ్రోచర్లను చదివాడు. అతను మట్టి మరియు నీటి నిర్వహణ కోర్సును ఇష్టపడతాడు మరియు క్రాప్‌సెన్స్ పనికి కూడా ఆకర్షితుడయ్యాడు.",
              "or": "ସେ ମେଲାରୁ ଉଠାଇଥିବା ଉଭୟ ବିବରଣ ପଢନ୍ତି | ସେ ମାଟି ଏବଂ ଜଳ ପରିଚାଳନା\n  ପାଠ୍ୟକ୍ରମକୁ ପସନ୍ଦ କରନ୍ତି ଏବଂ କ୍ରପ୍ସେନ୍ସ କାର୍ଯ୍ୟ ଦ୍ୱାରା ମଧ୍ୟ ଆକର୍ଷିତ ହୁଅନ୍ତି |",
              "as": "সি মেলাৰ পৰা লোৱা দুয়োখন পুস্তিকা পঢ়ে। সি মাটি আৰু পানী মেনেজমেন্ট পাঠ্যক্ৰম ভাল পায় আৰু ক্ৰপচেঞ্চৰ কামৰ দ্বাৰাও আকৰ্ষিত হয়।",
              "gu": "તે મેળામાંથી મેળવેલી બંને પુસ્તિકાઓ વાંચે છે. તેને માટી અને પાણી વ્યવસ્થાપનનો કોર્સ પસંદ છે અને તે ક્રોપસેન્સના કામથી પણ આકર્ષિત છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He decides to map his growth opportunity and benefits next to his interest for both options like Professor Nag asked him to do.",
              "hi": "वह प्रोफेसर नाग के कहे अनुसार दोनों विकल्पों को अपनी रुचि के सामने रखकर अपने विकास के अवसर और लाभों का मिलान करने का निश्चय करता है।",
              "ka": "ಪ್ರೊಫೆಸರ್ ನಾಗ್ ಅವರು ಮಾಡು  ಎಂದು ಕೇಳಿದಂತಹ ಎರಡೂ ಆಯ್ಕೆಗಳಿಗಾಗಿ ಅವನು ತನ್ನ ಬೆಳವಣಿಗೆಯ ಅವಕಾಶ ಮತ್ತು ಪ್ರಯೋಜನಗಳನ್ನು ಅವನ ಆಸಕ್ತಿಯ ಪಕ್ಕದಲ್ಲಿ ನಕ್ಷೆ ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "ప్రొఫెసర్ నాగ్ వంటి రెండు ఎంపికల కోసం తన అభిరుచికి తగినట్టుగా తన వృద్ధి అవకాశం మరియు ప్రయోజనాలను మ్యాప్ చేయాలని నిర్ణయించుకున్నాడు.",
              "or": "ପ୍ରଫେସର ନାଗ ଯେପରି କରିବାକୁ କହିଥିଲେ ଉଭୟ ବିକଳ୍ପ ପାଇଁ ସେ ତାଙ୍କର\n  ଅଭିବୃଦ୍ଧିର ସୁଯୋଗ ଏବଂ ଲାଭକୁ ଚିତ୍ରଣ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
              "as": "সি প্ৰফেচাৰ নাগে কোৱাৰ দৰে দুয়োটা বিকল্পৰ বাবে তাৰ আগ্ৰহৰ ওচৰত তাৰ বিকাশৰ সুযোগ আৰু লাভালাভ জোখাৰ সিদ্ধান্ত লয়।",
              "gu": "તે પ્રાધ્યાપક નાગ જેવા બંને વિકલ્પો માટે તેની રુચિની બાજુમાં તેની વૃદ્ધિની તક અને લાભોને ગોઠવવાનું નક્કી કરે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_2_2_Notebook",
              "hi": "B4_2_2_नोटबुक ",
              "ka": "B4_2_2_Notebook",
              "te": "B4_2_2_పుస్తకం",
              "or": "B4_2_2_Notebook",
              "as": "B4_2_2_Notebook",
              "gu": "B4_2_2_નોટબુક"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Mapping his interests to the growth and benefits of taking up the course.",
              "hi": "कोर्स करने पर विकास और लाभ के लिए अपनी रुचियों का मिलान।",
              "ka": "ಕೋರ್ಸ್ ತೆಗೆದುಕೊಳ್ಳುವ ಮುಂಚೆ ಬೆಳವಣಿಗೆ ಮತ್ತು ಪ್ರಯೋಜನಗಳ ಹಾಗೂ ಆತನ  ಆಸಕ್ತಿಗಳನ್ನು ಮ್ಯಾಪಿಂಗ್ ಮಾಡುವುದು.",
              "te": "కోర్సును చేపట్టడం వల్ల వృద్ధి మరియు ప్రయోజనాలకు అతని ఆసక్తులను మ్యాపింగ్ చేయడం.",
              "or": "ତାଙ୍କର ଆଗ୍ରହର ଅଭିବୃଦ୍ଧି ଏବଂ ପାଠ୍ୟକ୍ରମ ଗ୍ରହଣ କରିବାର ଲାଭ ସହିତ ମ୍ୟାପିଙ୍ଗ୍ |",
              "as": "পাঠ্যক্ৰমটো লোৱাৰ বিকাশ আৰু লাভালাভৰ বাবে তাৰ আগ্ৰহ জোখা।",
              "gu": "તેની રુચિઓનો વિકાસ અને અભ્યાસક્રમ લેવાના ફાયદાઓ સાથે ગોઠવણી."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page 1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "ପୃଷ୍ଠା ୧",
              "as": "পৃষ্ঠা 1",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page 2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ 2",
              "or": "ପୃଷ୍ଠା ୨",
              "as": "পৃষ্ঠা 2",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Page 3",
              "hi": "पृष्ठ 3",
              "ka": "ಪುಟ 3",
              "te": "పేజీ 3",
              "or": "ପୃଷ୍ଠା ୩",
              "as": "পৃষ্ঠা 3",
              "gu": "પેજ 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "**Interest**\n\nAfter working on the farms and visiting the Krishi Mela, I want to skill myself in this field.",
              "hi": "**रुचि**\n\nखेतों पर काम करने और कृषि मेले का दौरा करने के बाद, मैं इस क्षेत्र में अपनी कुशलता बढ़ाना चाहता हूं।",
              "ka": "**ಆಸಕ್ತಿ**                                                                                                                                                                                                                       ಹೊಲಗಳಲ್ಲಿ ಕೆಲಸ ಮಾಡಿ, ಕೃಷಿ ಮೇಳಕ್ಕೆ ಭೇಟಿ ನೀಡಿದ ನಂತರ, ಈ ಕ್ಷೇತ್ರದಲ್ಲಿ ನಾನು ಕೌಶಲ್ಯ ಹೊಂದಲು ಬಯಸುತ್ತೇನೆ.",
              "te": "** ఆసక్తి **\n \n పొలాల్లో పని చేసి, కృషి మేళాను సందర్శించిన తర్వాత, నేను ఈ రంగంలో నైపుణ్యం సాధించాలనుకుంటున్నాను.\n **వృద్ధి అవకాశాలు**",
              "or": "** ଆଗ୍ରହ **\n \n \n \n ଚାଷ ଜମିରେ କାମ କରିବା ଏବଂ କୃଷି ମେଳା ପରିଦର୍ଶନ କରିବା ପରେ ମୁଁ ନିଜକୁ ଏହି କ୍ଷେତ୍ରରେ ଦକ୍ଷ କରିବାକୁ ଚାହୁଁଛି। ”",
              "as": "**আগ্ৰহ**\n \n \n \n খেতিপথাৰত কাম কৰাৰ পিছত আৰু কৃষি মেলালৈ যোৱাৰ পিছত, মই এই ক্ষেত্ৰত নিজকে দক্ষ বনাব বিচাৰো।",
              "gu": "**રુચિ** ખેતરોમાં કામ કર્યા પછી અને કૃષિ મેળાની મુલાકાત લીધા પછી, હું મારી જાતને આ ક્ષેત્રમાં કૌશલ્ય બનાવવા માંગુ છું."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "**Growth opportunities**\n\n- The knowledge I gain will help many farmers in my village.\n\n- With a steady source of income, I can support my family financially.",
              "hi": "**विकास के अवसर**\n\n- मुझे जो ज्ञान मिलता है वह मेरे गांव में कई किसानों की सहायता करेगा।\n\n- आय के एक स्थिर स्रोत के साथ, मैं अपने परिवार को आर्थिक रूप से सहयोग दे सकता हूं।",
              "ka": "**ಬೆಳವಣಿಗೆಯ ಅವಕಾಶಗಳು**                                                                                                                                                                                                    ನಾನು ಪಡೆದ ಜ್ಞಾನವು ನನ್ನ ಹಳ್ಳಿಯ ಅನೇಕ ರೈತರಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.                                                                                                                                                ಸ್ಥಿರ ಆದಾಯದ ಮೂಲದೊಂದಿಗೆ, ನಾನು ನನ್ನ ಕುಟುಂಬವನ್ನು ಆರ್ಥಿಕವಾಗಿ ಬೆಂಬಲಿಸಬಲ್ಲೆನು. ",
              "te": "**వృద్ధి అవకాశాలు**\n \n - నేను పొందిన జ్ఞానం మా గ్రామంలోని చాలా మంది రైతులకు సహాయం చేస్తుంది.\n \n - స్థిరమైన ఆదాయ వనరుతో, నేను నా కుటుంబాన్ని ఆర్థికంగా పోషించగలను.",
              "or": "** ଅଭିବୃଦ୍ଧିର ସୁଯୋଗ **\n \n \n \n - ମୁଁ ପାଇଥିବା ଜ୍ଞାନ ମୋ ଗାଁର ଅନେକ ଚାଷୀଙ୍କୁ ସାହାଯ୍ୟ କରିବ |\n \n \n \n - ଏକ ସ୍ଥିର ଆୟର ଉତ୍ସ ସହିତ ମୁଁ ମୋ ପରିବାରକୁ ଆର୍ଥିକ ସହାୟତା କରିପାରିବି |",
              "as": "**বিকাশৰ সুযোগ**\n \n - মই পোৱা জ্ঞানে মোৰ গাঁৱৰ বহুতো কৃষকক সহায় কৰিব।\n \n - উপাৰ্জনৰ এক নিৰ্দিষ্ট উৎসৰ সৈতে, মই মোৰ পৰিয়ালক আৰ্থিকভাৱে সমৰ্থন কৰিব পাৰোঁ।",
              "gu": "**વૃદ્ધિની તકો** - મને મળેલું જ્ઞાન મારા ગામના ઘણા ખેડૂતોને મદદ કરશે. - આવકના સ્થિર સ્ત્રોત સાથે, હું મારા પરિવારને આર્થિક રીતે ટેકો આપી શકું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "**Benefits**\n\n- I’ll get to learn from experts.\n\n- My family can become financially secure.",
              "hi": "** लाभ **\n\n- मैं विशेषज्ञों से सीखूंगा।\n\n- मेरा परिवार आर्थिक रूप से सुरक्षित हो सकता है।",
              "ka": "**ಪ್ರಯೋಜನಗಳು**                                                                                                                                                                                                                 ನಾನು ತಜ್ಞರಿಂದ ಕಲಿಯುತ್ತೇನೆ.                                                                                                                                                                                                       ನನ್ನ ಕುಟುಂಬವು ಆರ್ಥಿಕವಾಗಿ ಸುರಕ್ಷಿತವಾಗಬಹುದು.",
              "te": "**లాభాలు**\n \n - నేను నిపుణుల నుండి నేర్చుకుంటాను.\n \n - నా కుటుంబం ఆర్థికంగా సురక్షితంగా ఉంటుంది.",
              "or": "** ଲାଭ **\n \n \n \n - ମୁଁ ବିଶେଷଜ୍ଞଙ୍କଠାରୁ ଶିଖିବାକୁ ପାଇବି |\n \n \n \n - ମୋ ପରିବାର ଆର୍ଥିକ ଦୃଷ୍ଟିରୁ ସୁରକ୍ଷିତ ହୋଇପାରିବେ।",
              "as": "**লাভালাভ**\n \n \n \n - মই বিশেষজ্ঞসকলৰ পৰা শিকিম।\n \n \n \n - মোৰ পৰিয়াল আৰ্থিকভাৱে সুৰক্ষিত হ'ব পাৰে।",
              "gu": "**લાભ** - હું નિષ્ણાતો પાસેથી શીખીશ. - મારો પરિવાર આર્થિક રીતે સુરક્ષિત બની શકે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_2_2b_Notebook",
              "hi": "B4_2_2B_नोटबुक ",
              "ka": "B4_2_2b_Notebook",
              "te": "B4_2_2b_పుస్తకం",
              "or": "B4_2_2b_Notebook",
              "as": "B4_2_2b_Notebook",
              "gu": "B4_2_2b_નોટબુક"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Mapping his interests to the growth and benefits of taking up the job.",
              "hi": "नौकरी लेने पर विकास और लाभों के लिए अपनी रुचियों का मिलान।",
              "ka": "ಉದ್ಯೋಗವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಅದರ ಬೆಳವಣಿಗೆ ಮತ್ತು ಪ್ರಯೋಜನಗಌ ಹಾಗೂ ಆತನ ಆಸಕ್ತಿಗಳನ್ನು ಮ್ಯಾಪಿಂಗ್ ಮಾಡುವುದು.",
              "te": "ఉద్యోగంలో చేరడం వల్ల కలిగే పెరుగుదల మరియు ప్రయోజనాలకు అతని ఆసక్తులను మ్యాపింగ్ చేయడం.",
              "or": "ଚାକିରି ଗ୍ରହଣ କରିବାର ଅଭିବୃଦ୍ଧି ଏବଂ ଲାଭ ସହିତ ତାଙ୍କର ଆଗ୍ରହକୁ ମ୍ୟାପିଙ୍ଗ୍ |",
              "as": "চাকৰিটো লোৱাৰ বিকাশ আৰু লাভালাভৰ বাবে তাৰ আগ্ৰহ জোখা।",
              "gu": "તેની રુચિઓને વૃદ્ધિ અને નોકરી લેવાના લાભો સાથે ગોઠવણી."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page 1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "ପ୍ରୁଷ୍ଠା ୧",
              "as": "পৃষ্ঠা 1",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page 2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ 2",
              "or": "ପ୍ରୁଷ୍ଠା ୨",
              "as": "পৃষ্ঠা 2",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Page 3",
              "hi": "पृष्ठ 3",
              "ka": "ಪುಟ 3",
              "te": "పేజీ 3",
              "or": "ପ୍ରୁଷ୍ଠା ୩",
              "as": "পৃষ্ঠা 3",
              "gu": "પેજ 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "**Interest**\n\nI find technology interesting.",
              "hi": "**रुचि**\n\nमुझे तकनीक रोचक लगती है।",
              "ka": "**ಆಸಕ್ತಿ**                                                                                                                                                                                                                                  ನಾನು ತಂತ್ರಜ್ಞಾನವನ್ನು ಆಸಕ್ತಿದಾಯಕವಾಗಿ ಕಾಣುತ್ತೇನೆ",
              "te": "** ఆసక్తి **\n \n నాకు సాంకేతికత ఆసక్తికరంగా ఉంది.",
              "or": "** ଆଗ୍ରହ **\n \n \n \n ମୁଁ ଟେକ୍ନୋଲୋଜିକୁ କୌତୁହଳ ପ୍ରଦ ମନେ କରୁଛି।",
              "as": "**আগ্ৰহ**\n \n মই প্ৰযুক্তি আকৰ্ষণীয় পাওঁ।",
              "gu": "**રુચિ** મને ટેકનોલોજી રસપ્રદ લાગી."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "**Growth**\n\n- Working in an emerging field of agriculture.\n\n- Financial independence.",
              "hi": "**विकास**\n\n- कृषि के एक उभरते क्षेत्र में काम करना।\n\n- वित्तीय स्वतंत्रता।",
              "ka": "**ಬೆಳವಣಿಗೆ**                                                                                                                                                                                                                       ಕೃಷಿಯ ಉದಯೋನ್ಮುಖ ಕ್ಷೇತ್ರದಲ್ಲಿ ಕೆಲಸವನ್ನು ಮಾಡಬಹುದು.                                                                                                                                                                        ಆರ್ಥಿಕ ಸ್ವಾತಂತ್ರ್ಯ ",
              "te": "**వృద్ధి**\n \n - అభివృద్ధి చెందుతున్న వ్యవసాయ రంగంలో పని చేయడం.\n \n - ఆర్థిక స్వతంత్రం",
              "or": "** ଅଭିବୃଦ୍ଧି **\n \n \n \n - କୃଷିର ଏକ ଉଦୀୟମାନ କ୍ଷେତ୍ରରେ କାର୍ଯ୍ୟ କରିବା |\n \n \n \n - ଆର୍ଥିକ ସ୍ଵାଧୀନତା।",
              "as": "**বিকাশ**\n \n \n \n - কৃষিৰ এক উদীয়মান ক্ষেত্ৰত কাম কৰা।\n \n \n \n - বিত্তীয় স্বাধীনতা।",
              "gu": "**વૃદ્ધિ** - કૃષિના ઉભરતા ક્ષેત્રમાં કામ કરવું. - નાણાકીય સ્વતંત્રતા."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "**Benefits**\n- An opportunity to work with a new kind of organisation.\n\n- The extra money and experience will help the family and me.",
              "hi": "** लाभ **\n- एक नए प्रकार के संगठन के साथ काम करने का अवसर।\n\n- अतिरिक्त धन और अनुभव परिवार और मेरे लिए सहायक होगा।",
              "ka": "**ಪ್ರಯೋಜನಗಳು**                                                                                                                                                                                                                 ಹೊಸ ರೀತಿಯ ಸಂಸ್ಥೆಯೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುವ ಅವಕಾಶ.                                                                                                                                                                ಹೆಚ್ಚುವರಿ ಹಣ ಮತ್ತು ಅನುಭವವು ಕುಟುಂಬಕ್ಕೆ ಮತ್ತು ನನಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "**లాభాలు**\n - కొత్త రకమైన సంస్థతో కలిసి పనిచేసే అవకాశం.\n \n - అదనపు డబ్బు మరియు అనుభవం కుటుంబానికి మరియు నాకు సహాయం చేస్తుంది.",
              "or": "** ଲାଭ **\n \n - ଏକ ନୂତନ ପ୍ରକାରର ସଂଗଠନ ସହିତ କାମ କରିବାର ଏକ ସୁଯୋଗ |\n \n \n \n - ଅତିରିକ୍ତ ଟଙ୍କା ଏବଂ ଅଭିଜ୍ଞତା ପରିବାର ଏବଂ ମୋତେ ସାହାଯ୍ୟ କରିବ।",
              "as": "**লাভালাভ**\n \n - এক নতুন ধৰণৰ সংগঠনৰ সৈতে কাম কৰাৰ সুযোগ।\n \n \n \n - অতিৰিক্ত ধন আৰু অভিজ্ঞতাই পৰিয়াল আৰু মোক সহায় কৰিব।",
              "gu": "**લાભ** - નવા પ્રકારની સંસ્થા સાથે કામ કરવાની તક. - વધારાના પૈસા અને અનુભવ મને અને પરિવારને મદદ કરશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4_2_3 D",
              "hi": "B4_2_3 D",
              "ka": "B4_2_3 D",
              "te": "B4_2_3 D",
              "or": "B4_2_3 D",
              "as": "B4_2_3 D",
              "gu": "B4_2_3 D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Shikar do?",
              "hi": "शिखर को क्या करना चाहिए?",
              "ka": "ಶಿಖರ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "శిఖర్ ఏమి చేయాలి?",
              "or": "ଶିକର କ’ଣ କରିବା ଉଚିତ୍?",
              "as": "শিখৰে কি কৰিব লাগে?",
              "gu": "શિખરે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Apply to the course",
              "hi": "कोर्स के लिए आवेदन करे",
              "ka": "ಕೋರ್ಸ್‌ಗೆ ಅರ್ಜಿ ಹಾಕುವುದು",
              "te": "కోర్సుకు దరఖాస్తు చేసుకోవడం",
              "or": "ପାଠ୍ୟକ୍ରମରେ ପ୍ରୟୋଗ କରନ୍ତୁ |",
              "as": "পাঠ্যক্ৰমত আবেদন কৰক",
              "gu": "કોર્સ માટે અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Take up the job",
              "hi": "काम करे ",
              "ka": "ಕೆಲಸಕ್ಕೆ ಸೇರಿಕೊಳ್ಳುವುದು",
              "te": "ఉద్యోగంలో చేరడం",
              "or": "ଚାକିରି ନିଅ |",
              "as": "কামটো লওক",
              "gu": "કામ હાથમાં લો"
            }
          }
        ]
      },
      "scene44": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Talk to professor Nag",
              "hi": "प्रोफेसर नाग से बात करे",
              "ka": "ಪ್ರೊಫೆಸರ್ ನಾಗ್ ಅವರ ಜೊತೆ ಮಾತನಾಡುವುದು",
              "te": "ప్రొఫెసర్ నాగ్‌ తో మాట్లాడడం",
              "or": "ପ୍ରଫେସର ନାଗଙ୍କ ସହ କଥା ହୁଅନ୍ତୁ |",
              "as": "অধ্যাপক নাগৰ সৈতে কথা পাতক",
              "gu": "પ્રાધ્યાપક નાગ સાથે વાત કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar decides to meet Professor Nag for some advice. He meets her in her office at the Agricultural Science department at the University of Ujjain.",
              "hi": "शिखर प्रोफेसर नाग से मिल कर सलाह लेने का निश्चय करता है। वह उनसे उज्जैन विश्वविद्यालय के कृषि विज्ञान विभाग में स्थित उनके कार्यालय में  मिलता है।",
              "ka": "ಶಿಖರ್ ಕೆಲವು ಸಲಹೆಗಾಗಿ ಪ್ರೊಫೆಸರ್ ನಾಗ್ ಅವರನ್ನು ಭೇಟಿ ಮಾಡಲು ನಿರ್ಧರಿಸಿದನು. ಉಜ್ಜಯಿನಿ ವಿಶ್ವವಿದ್ಯಾನಿಲಯದಲ್ಲಿನ ಕೃಷಿ ವಿಜ್ಞಾನ ವಿಭಾಗದಲ್ಲಿ ಆಕೆಯ ಕಛೇರಿಯಲ್ಲಿ ಅವನು ಆಕೆಯನ್ನು  ಭೇಟಿಯಾಗುತ್ತಾನೆ",
              "te": "కొన్ని సలహాల కోసం ప్రొఫెసర్ నాగ్‌ ని కలవాలని శిఖర్ నిర్ణయించుకున్నాడు. ఉజ్జయిని విశ్వవిద్యాలయంలోని వ్యవసాయ శాస్త్రం విభాగంలోని ఆమె కార్యాలయంలో అతను ఆమెను కలుస్తాడు.",
              "or": "ଶିଖର କିଛି ପରାମର୍ଶ ପାଇଁ ପ୍ରଫେସର ନାଗଙ୍କୁ ଭେଟିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି।\n  ସେ ଉଜ୍ଜୈନ ବିଶ୍ୱବିଦ୍ୟାଳୟର କୃଷି ବିଜ୍ଞାନ ବିଭାଗରେ ତାଙ୍କ କାର୍ଯ୍ୟାଳୟରେ ତାଙ୍କୁ ଭେଟିଛନ୍ତି |",
              "as": "শিখৰে কিছু পৰামৰ্শৰ বাবে অধ্যাপক নাগক লগ কৰাটো ঠিক কৰে। সি তাইক উজ্জয়িনী বিশ্ববিদ্যালয়ৰ কৃষি বিজ্ঞান বিভাগৰ অফিচত লগ পায়।",
              "gu": "શિખરે કેટલીક સલાહ માટે પ્રાધ્યાપક નાગને મળવાનું નક્કી કર્યું. તે તેણીને ઉજ્જૈન યુનિવર્સિટીના કૃષિ વિજ્ઞાન વિભાગમાં તેની ઓફિસમાં મળે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_2 bg",
              "hi": "B5_1_2 बीजी",
              "ka": "B5_1_2 bg",
              "te": "B5_1_2 bg",
              "or": "B5_1_2 bg",
              "as": "B5_1_2 bg",
              "gu": "B5_1_2 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Professor Nag",
              "hi": "प्रोफेसर नाग",
              "ka": "ಪ್ರೊಫೆಸರ್ ನಾಗ್",
              "te": "ప్రొఫెసర్ నాగ్",
              "or": "ପ୍ରଫେସର ନାଗ",
              "as": "অধ্যাপক নাগ",
              "gu": "પ્રાધ્યાપક નાગ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Professor Nag",
              "hi": "प्रोफेसर नाग",
              "ka": "ಪ್ರೊಫೆಸರ್ ನಾಗ್",
              "te": "ప్రొఫెసర్ నాగ్",
              "or": "ପ୍ରଫେସର ନାଗ",
              "as": "অধ্যাপক নাগ",
              "gu": "પ્રાધ્યાપક નાગ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Where is the confusion, Shikhar?",
              "hi": "दुविधा कहां है, शिखर?",
              "ka": "ಯಾವ ರೀತಿಯ ಗೊಂದಲ ಇದೆ ಶಿಖರ್?",
              "te": "ఎక్కడ అయోమయంగా ఉంది శిఖర్?",
              "or": "ଦ୍ୱନ୍ଦ୍ୱ କେଉଁଠାରେ ଅଛି, ଶିଖର?",
              "as": "শিখৰ, খেলিমেলিটো ক'ত?",
              "gu": "મૂંઝવણ ક્યાં છે શિખર?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "If I can get a job right now, I am not sure if I should spend the next three or four years studying.",
              "hi": "अगर मैं अभी नौकरी प्राप्त कर सकता हूं, तो मैं समझ नहीं पा रहा हूँ  कि क्या मुझे अगले तीन या चार साल तक अध्ययन करना चाहिए।",
              "ka": "ಈಗಲೇ ಕೆಲಸ ಸಿಕ್ಕರೆ ಮುಂದಿನ ಮೂರ್ನಾಲ್ಕು ವರ್ಷ ಓದಬೇಕೋ ಇಲ್ಲವೋ ಅಂತ ಖಾತ್ರಿಯಿಲ್ಲ.",
              "te": "ఇప్పుడే నాకు ఉద్యోగం దొరికితే వచ్చే మూడు, నాలుగేళ్లు చదువుకోవాలా వద్దా అనే ఆలోచన ఉంది.",
              "or": "ଯଦି ମୁଁ ବର୍ତ୍ତମାନ ଚାକିରି ପାଇ ଯିବୀ, ମୁଁ ନିଶ୍ଚିତ ନୁହେଁ ଯେ ମୋର ପରବର୍ତ୍ତୀ ତିନି\n  କିମ୍ବା ଚାରି ବର୍ଷ ଅଧ୍ୟୟନ କରିବା ଉଚିତ୍ ହବ କି?",
              "as": "যদি মই এই মুহূৰ্তত চাকৰি এটা পাব পাৰো, মই নিশ্চিত নহয় যে মই পৰৱৰ্তী তিনি বা চাৰি বছৰ পঢ়াত পাৰ কৰিব লাগে নে নাই।",
              "gu": "જો મને અત્યારે નોકરી મળી શકે, તો મને ખાતરી નથી કે મારે આગામી ત્રણ કે ચાર વર્ષ અભ્યાસમાં વિતાવવા જોઈએ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Both career journeys are unique. After taking a course, the entry point into your career will be different from directly going for a job.",
              "hi": "दोनों कैरियर पथ अलग हैं। कोर्स करने के बाद, करियर में शुरुआत सीधे नौकरी करने से अलग होगी।",
              "ka": "Both career journeys are unique. After taking a course, the entry point into your career will be different from directly going for a job.",
              "te": "రెండు కెరీర్ జర్నీలు ప్రత్యేకమైనవి. కోర్సు తీసుకున్న తర్వాత, నీ కెరీర్‌ నేరుగా ఉద్యోగం కోసం వెళ్లడం కంటే భిన్నంగా ఉంటుంది.",
              "or": "ଉଭୟ କ୍ୟାରିୟର ଯାତ୍ରା ଅନନ୍ୟ ଅଟେ | ଏକ ପାଠ୍ୟକ୍ରମ ନେବା ପରେ, ତୁମର\n  କ୍ୟାରିଅରରେ ପ୍ରବେଶ ପଏଣ୍ଟ ସିଧାସଳଖ ଚାକିରି ପାଇଁ ଭିନ୍ନ ହେବ |",
              "as": "কেৰিয়াৰৰ দুয়োটা যাত্ৰাই অনন্য। পাঠ্যক্ৰম এটা লোৱাৰ পিছত, তোমাৰ কেৰিয়াৰত সোমোৱাৰ পথটো পোনপটীয়াকৈ চাকৰি কৰাতকৈ বেলেগ হ'ব।",
              "gu": "બંનેની કારકિર્દીની સફર અનોખી છે. કોર્સ કર્યા પછી, તારી કારકિર્દીમાં પ્રવેશનો મુદ્દો સીધા નોકરી માટે જવા કરતાં અલગ હશે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_3///",
              "hi": "B5_1_3 ////",
              "ka": "B5_1_3///",
              "te": "B5_1_3///",
              "or": "B5_1_3///",
              "as": "B5_1_3///",
              "gu": "B5_1_3///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "What do you mean by entry point is different\"?\"",
              "hi": "शुरुआत अलग होगी से आपका क्या मतलब है \"?\"",
              "ka": "ಎಂಟ್ರಿ ಪಾಯಿಂಟ್ ಬೇರೆ ಎಂದು ನಿಮ್ಮ ಅರ್ಥವೇನು\"?\"",
              "te": "ప్రవేశ స్థానం భిన్నం అంటే మీ ఉద్దేశ్యం ఏమిటి\"?\"",
              "or": "ଏଣ୍ଟ୍ରି ପଏଣ୍ଟ ଅଲଗା ବୋଲି ଆପଣ କ’ଣ କହିବାକୁ ଚାହୁଁଛନ୍ତି ?",
              "as": "সোমোৱাৰ পথটো বেলেগ\" বুলি কৈ আপুনি কি বুজাইছে?",
              "gu": "અંદર દાખલ થવાનો રસ્તો અલગ છે તેનો અર્થ શું છે\"?\""
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Maybe if we map your career journeys for both options, you will understand.",
              "hi": "हो सकता है कि यदि हम दोनों विकल्पों के लिए आपके कैरियर पथ का मिलान करते हैं, तो आप समझेंगे।",
              "ka": "ಬಹುಶಃ ನಾವು ನಿಮ್ಮ ವೃತ್ತಿಜೀವನದ ಪ್ರಯಾಣವನ್ನು ಎರಡೂ ಆಯ್ಕೆಗಳಿಗಾಗಿ ನಕ್ಷೆ ಮಾಡಿದರೆ, ನೀವು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವಿರಿ.",
              "te": "రెండు ఎంపికల కోసం మనము మీ కెరీర్ ప్రయాణాన్ని మ్యాప్ చేస్తే, నీకు అర్థం అవుతుంది.",
              "or": "ବୋଧହୁଏ ଯଦି ଆମେ ଉଭୟ ବିକଳ୍ପ ପାଇଁ ତୁମର କ୍ୟାରିୟର ଯାତ୍ରା ମାନଚିତ୍ର\n  କରୁ, ତୁମେ ବୁଝିଯିବ।",
              "as": "হয়তো যদি আমি দুয়োটা বিকল্পৰ বাবে তোমাৰ কেৰিয়াৰৰ যাত্ৰা মাপ কৰোঁ, তুমি বুজি পাবা।",
              "gu": "કદાચ જો આપણે બંને વિકલ્પો માટે તારી કારકિર્દીની મુસાફરીનો નકશો બનાવીએ, તો તું સમજી શકશો."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_4 career journey",
              "hi": "B5_1_4 कैरियर पथ",
              "ka": "B5_1_4 career journey",
              "te": "B5_1_4 కెరీర్ ప్రయాణం",
              "or": "B5_1_4 career journey",
              "as": "B5_1_4 career journey",
              "gu": "B5_1_4 કારકિર્દીની સફર"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Research officer/Scientist 2- 3 years",
              "hi": "अनुसंधान अधिकारी / वैज्ञानिक 2- 3 साल",
              "ka": "ಸಂಶೋಧನಾ ಅಧಿಕಾರಿ/ವಿಜ್ಞಾನಿ 2- 3 ವರ್ಷಗಳು",
              "te": "పరిశోధన అధికారి/శాస్త్రవేత్త 2- 3 సంవత్సరాలు",
              "or": "ଅନୁସନ୍ଧାନ ଅଧିକାରୀ / ବଇଜ୍ଞାନିକ 2- 3 ବର୍ଷ |",
              "as": "গৱেষণা বিষয়া/বিজ্ঞানী 2-3 বছৰ",
              "gu": "સંશોધન અધિકારી/વૈજ્ઞાનિક 2-3 વર્ષ"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Senior Researcher/Scientist/Manager\n3-4 years",
              "hi": "वरिष्ठ शोधकर्ता / वैज्ञानिक / प्रबंधक\n3-4 साल",
              "ka": "ಹಿರಿಯ ಸಂಶೋಧಕ/ವಿಜ್ಞಾನಿ/ವ್ಯವಸ್ಥಾಪಕರು 3-4 ವರ್ಷಗಳು",
              "te": "సీనియర్ పరిశోధకుడు/శాస్త్రవేత్త/మేనేజర్\n 3-4 సంవత్సరాలు",
              "or": "ବରିଷ୍ଠ ଅନୁସନ୍ଧାନକାରୀ / ବଇଜ୍ଞାନିକ / ପରିଚାଳକ |\n \n 3-4 ବର୍ଷ",
              "as": "জ্যেষ্ঠ গৱেষক/বিজ্ঞানী/মেনেজাৰ\n \n 3-4 বছৰ",
              "gu": "વરિષ્ઠ સંશોધક/વૈજ્ઞાનિક/મેનેજર 3-4 વર્ષ"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Manager 3-4 years",
              "hi": "प्रबंधक 3-4 साल",
              "ka": "ಮ್ಯಾನೇಜರ್ 3-4 ವರ್ಷಗಳು",
              "te": "మేనేజర్ 3-4 సంవత్సరాలు",
              "or": "ମ୍ୟାନେଜର୍ 3-4 ବର୍ଷ |",
              "as": "মেনেজাৰ 3-4 বছৰ",
              "gu": "મેનેજર 3-4 વર્ષ"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Senior Manager",
              "hi": "वरिष्ठ प्रबंधक",
              "ka": "ಸೀನಿಯರ್ ಮ್ಯಾನೇಜರ್",
              "te": "సీనియర్ మేనేజర్",
              "or": "ସିନିୟର ମ୍ୟାନେଜର",
              "as": "জ্যেষ্ঠ মেনেজাৰ",
              "gu": "વરિષ્ઠ વ્યવસ્થાપક"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_5 bg",
              "hi": "B5_1_5 बीजी",
              "ka": "B5_1_5 bg",
              "te": "B5_1_5 bg",
              "or": "B5_1_5 bg",
              "as": "B5_1_5 bg",
              "gu": "B5_1_5 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Professor Nag",
              "hi": "प्रोफेसर नाग",
              "ka": "ಪ್ರೊಫೆಸರ್ ನಾಗ್",
              "te": "ప్రొఫెసర్ నాగ్",
              "or": "ପ୍ରଫେସର ନାଗ",
              "as": "অধ্যাপক নাগ",
              "gu": "પ્રાધ્યાપક નાગ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "So that means the course will help me start at a researcher/scientist level in any organisation?",
              "hi": "तो इसका अर्थ यह है कि कोर्स किसी भी संगठन में एक शोधकर्ता/ वैज्ञानिक स्तर पर शुरु करने में सहायक होगा?",
              "ka": "ಹಾಗಾಗಿ ಯಾವುದೇ ಸಂಸ್ಥೆಯಲ್ಲಿ ಸಂಶೋಧಕ/ವಿಜ್ಞಾನಿ ಮಟ್ಟದಲ್ಲಿ ಪ್ರಾರಂಭಿಸಲು ಕೋರ್ಸ್ ನನಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ ಎಂದರ್ಥವೇ?",
              "te": "అంటే ఏదైనా సంస్థలో పరిశోధన/శాస్త్రవేత్త స్థాయిలో ప్రారంభించడానికి కోర్సు నాకు సహాయపడుతుందా?",
              "or": "ତେବେ ଏହାର ଅର୍ଥ କୌଣସି ଅନୁଷ୍ଠାନରେ ଗବେଷକ / ବଇ\n ଜ୍ଞାନିକ ସ୍ତରରେ ଆରମ୍ଭ କରିବା ପାଇଁ ପାଠ୍ୟକ୍ରମ ମୋତେ ସାହାଯ୍ୟ କରିବ?",
              "as": "গতিকে তাৰ মানে পাঠ্যক্ৰমটোৱে মোক যিকোনো সংগঠনৰ গৱেষক/বিজ্ঞানী পৰ্যায়ত আৰম্ভ কৰাত সহায় কৰিব?",
              "gu": "તો તેનો અર્થ એ છે કે કોર્સ મને કોઈપણ સંસ્થામાં સંશોધક/વૈજ્ઞાનિક સ્તરે શરૂ કરવામાં મદદ કરશે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Since you will be studying agricultural science, it would be an organisation working in food production, seed conservation or soil management.",
              "hi": "चूंकि आप कृषि विज्ञान का अध्ययन करेंगे, यह खाद्य उत्पादन, बीज संरक्षण या मिट्टी प्रबंधन में काम करने वाला संगठन होगा।",
              "ka": "ನೀವು ಕೃಷಿ ವಿಜ್ಞಾನವನ್ನು ಅಧ್ಯಯನ ಮಾಡುತ್ತಿರುವುದರಿಂದ, ಇದು ಆಹಾರ ಉತ್ಪಾದನೆ, ಬೀಜ ಸಂರಕ್ಷಣೆ ಅಥವಾ ಮಣ್ಣಿನ ನಿರ್ವಹಣೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಸಂಸ್ಥೆಯಾಗಿದೆ.",
              "te": "మీరు వ్యవసాయ శాస్త్రాన్ని చదువుతున్నారు కాబట్టి, ఇది ఆహార ఉత్పత్తి, విత్తన సంరక్షణ లేదా నేల నిర్వహణలో పనిచేసే సంస్థ.",
              "or": "ଯେହେତୁ ଆପଣ କୃଷି ବିଜ୍ଞାନ ଅଧ୍ୟୟନ କରିବେ, ଏହା ଖାଦ୍ୟ ଉତ୍ପାଦନ, ବିହନ\n  ସଂରକ୍ଷଣ କିମ୍ବା ମୃତ୍ତିକା ପରିଚାଳନାରେ କାର୍ଯ୍ୟ କରୁଥିବା ଏକ ସଂଗଠନ ହେବ |",
              "as": "যিহেতু তুমি কৃষি বিজ্ঞান অধ্যয়ন কৰিবা, ই হ'ব খাদ্য উৎপাদন, বীজ সংৰক্ষণ বা মাটি ব্যৱস্থাপনাত কাম কৰা এটা সংগঠন।",
              "gu": "તમે કૃષિ વિજ્ઞાનનો અભ્યાસ કરતા હોવાથી, તે ખાદ્ય ઉત્પાદન, બીજ સંરક્ષણ અથવા માટી વ્યવસ્થાપનમાં કામ કરતી સંસ્થા હશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_6///",
              "hi": "B5_1_6 ///////",
              "ka": "B5_1_6///",
              "te": "B5_1_6///",
              "or": "B5_1_6///",
              "as": "B5_1_6///",
              "gu": "B5_1_6///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "That’s exactly what I want to do! In this way, I will bring about a change and support many other farmers.",
              "hi": "यह वही है जो मैं करना चाहता हूं! इस तरह, मैं एक बदलाव लाऊंगा और अनेक अन्य किसानों की सहायता करूंगा।",
              "ka": "ಅದನ್ನೇ ನಾನು ಮಾಡಲು ಬಯಸುತ್ತೇನೆ! ಈ ಮೂಲಕ ನಾನು ಬದಲಾವಣೆಯನ್ನು ತರುತ್ತೇನೆ ಮತ್ತು ಇನ್ನೂ ಅನೇಕ ರೈತರಿಗೆ ಬೆಂಬಲ ನೀಡುತ್ತೇನೆ.",
              "te": "నేను చేయాలనుకుంటున్నది అదే! ఈ విధంగా మార్పు తీసుకొచ్చి ఎంతో మంది రైతులను ఆదుకుంటాను.",
              "or": "ମୁଁ ତାହା କରିବାକୁ ଚାହୁଁଛି! ଏହିପରି, ମୁଁ ଏକ ପରିବର୍ତ୍ତନ ଆଣିବି ଏବଂ ଅନ୍ୟାନ୍ୟ\n  କୃଷକଙ୍କୁ ସମର୍ଥନ କରିବି |",
              "as": "মই ঠিক এইটোৱেই কৰিব বিচাৰোঁ! এনেদৰে, মই এক পৰিৱৰ্তন আনিম আৰু আন বহুতো কৃষকক সমৰ্থন কৰিম।",
              "gu": "હું શું કરવા માંગુ છું તે બરાબર છે! આ રીતે, હું પરિવર્તન લાવીશ અને બીજા ઘણા ખેડૂતોને ટેકો આપીશ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "But Shikhar, all this will start only after four years. You need to get your degree first! Let’s take a look at your other option.",
              "hi": "लेकिन शिखर, यह सब चार साल के बाद ही शुरु होगा। आपको पहले अपनी डिग्री प्राप्त करने की आवश्यकता है! आइए अपने दूसरे विकल्प को देखें।",
              "ka": "ಆದರೆ ಶಿಖರ್, ಇದೆಲ್ಲ ಶುರುವಾಗುವುದು ನಾಲ್ಕು ವರ್ಷಗಳ ನಂತರವೇ. ನೀವು ಮೊದಲು ನಿಮ್ಮ ಪದವಿಯನ್ನು ಪಡೆಯಬೇಕು! ನಿಮ್ಮ ಇನ್ನೊಂದು ಆಯ್ಕೆಯನ್ನು ನೋಡೋಣ.",
              "te": "అయితే శిఖర్ ఇది మాత్రం నాలుగేళ్ల తర్వాతే మొదలవుతుంది. నువ్వు ముందుగా నీ డిగ్రీని పొందాలి! నీ ఇతర ఎంపికను పరిశీలిద్దాం.",
              "or": "କିନ୍ତୁ ଶିଖର, ଏହି ସବୁ ଚାରି ବର୍ଷ ପରେ ହିଁ ଆରମ୍ଭ ହେବ | ତୁମେ ପ୍ରଥମେ\n  ତୁମର ଡିଗ୍ରୀ ପାଇବା ଆବଶ୍ୟକ! ଚାଲନ୍ତୁ ତୁମର ଅନ୍ୟ ବିକଳ୍ପ ଉପରେ ନଜର \n ପକାଇବା |",
              "as": "কিন্তু শিখৰ, এই সকলোবোৰ চাৰি বছৰৰ পিছতহে আৰম্ভ হ'ব। তুমি প্ৰথমে তোমাৰ ডিগ্ৰী ল'ব লাগিব! তোমাৰ আন বিকল্পটো চাওঁ আহা।",
              "gu": "પણ શિખર, આ બધું ચાર વર્ષ પછી જ શરૂ થશે. તારે પહેલા તારી ડિગ્રી મેળવવાની જરૂર છે! ચાલ તારા બીજા વિકલ્પ પર એક નજર કરીએ."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_7 career journey",
              "hi": "B5_1_7 कैरियर पथ",
              "ka": "B5_1_7 career journey",
              "te": "B5_1_7 కెరీర్ ప్రయాణం",
              "or": "B5_1_7 କ୍ୟାରିୟର ଯାତ୍ରା |",
              "as": "B5_1_7 career journey",
              "gu": "B5_1_7 કારકિર્દીની સફર"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Youth ambassador 1-2 years",
              "hi": "युवा राजदूत 1-2 साल",
              "ka": "ಯುವ ರಾಯಭಾರಿ 1-2 ವರ್ಷಗಳು",
              "te": "యూత్ అంబాసిడర్ 1-2 సంవత్సరాలు",
              "or": "Youth ambassador 1-2 years",
              "as": "যুৱ ৰাষ্ট্ৰদূত 1-2 বছৰ",
              "gu": "યુવા રાજદૂત 1-2 વર્ષ"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Training",
              "hi": "प्रशिक्षण",
              "ka": "ತರಬೇತಿ",
              "te": "శిక్షణ",
              "or": "ତାଲିମ",
              "as": "প্ৰশিক্ষণ",
              "gu": "તાલીમ"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Senior YA/Field assistant 2-3 years",
              "hi": "सीनियर वाइए / फील्ड सहायक 2-3 साल",
              "ka": "ಹಿರಿಯ YA/ ಕ್ಷೇತ್ರ ಸಹಾಯಕ 2-3 ವರ್ಷಗಳು",
              "te": "సీనియర్ YA/ఫీల్డ్ అసిస్టెంట్ 2-3 సంవత్సరాలు",
              "or": "ସିନିୟର YA / ଫିଲ୍ଡ ସହାୟକ 2-3 ବର୍ଷ |",
              "as": "জ্যেষ্ঠ YA/ফিল্ড সহায়ক 2-3 বছৰ",
              "gu": "વરિષ્ઠ YA/ક્ષેત્ર સહાયક 2-3 વર્ષ"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Junior executive/marketing associate 3-4 years",
              "hi": "जूनियर कार्यकारी/ मार्केटिंग एसोसिएट 3-4 साल",
              "ka": "ಜೂನಿಯರ್ ಎಕ್ಸಿಕ್ಯೂಟಿವ್/ಮಾರ್ಕೆಟಿಂಗ್ ಅಸೋಸಿಯೇಟ್ 3-4 ವರ್ಷಗಳು",
              "te": "జూనియర్ ఎగ్జిక్యూటివ్/మార్కెటింగ్ అసోసియేట్ 3-4 సంవత్సరాలు",
              "or": "ଜୁନିଅର କାର୍ଯ୍ୟନିର୍ବାହୀ / ମାର୍କେଟିଂ ସହଯୋଗୀ 3-4 ବର୍ଷ |",
              "as": "কনিষ্ঠ কাৰ্যবাহী/বিপণন সহযোগী 3-4 বছৰ",
              "gu": "જુનિયર એક્ઝિક્યુટિવ/માર્કેટિંગ એસોસિયેટ 3-4 વર્ષ"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "District Team Lead",
              "hi": "जिला टीम का नेतृत्व",
              "ka": "ಜಿಲ್ಲಾ ತಂಡದ ನಾಯಕ",
              "te": "జిల్లా జట్టు నాయకుడు",
              "or": "ଜିଲ୍ଲା ଟିମ୍ ଲିଡ୍ |",
              "as": "জিলা দলৰ নেতৃত্ব",
              "gu": "જીલ્લા ટીમ લીડ"
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "career map",
              "hi": "कैरियर मैप  ",
              "ka": "ವೃತ್ತಿ ನಕ್ಷೆ",
              "te": "కెరీర్ మ్యాప్",
              "or": "କ୍ୟାରିୟର ମାନଚିତ୍ର",
              "as": "কেৰিয়াৰ মেপ",
              "gu": "કારકિર્દી નકશો"
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "ಎಮ್‌ಸಿ‌ಕ್ಯೂ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Prof. Nag helps Shikhar make a career map to understand his options and help him know where he is and what it will take to reach where he wants to go. In which of the following situations, is making a career map a good activity:",
              "hi": "प्रो. नाग शिखर को अपने विकल्पों को समझने के लिए करियर मैप बनाने में सहायता करती हैं और उसे यह जानने में मदद करती है कि वह कहां है और वहाँ जाने के लिए उसे क्या करना होगा जहां वह जाना चाहता है। निम्नलिखित में से किस परिस्थिति में, करियर मैप बनाना एक अच्छी गतिविधि है:",
              "ka": "ಪ್ರೊ. ನಾಗ್, ಶಿಖರ್ ಆತನ ಆಯ್ಕೆಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ವೃತ್ತಿ ನಕ್ಷೆಯನ್ನು ಹಾಕಲು ಸಹಾಯ ಮಾಡುತ್ತಾರೆ ಮತ್ತು ಆತನು ಎಲ್ಲಿದ್ದಾನೆ ಮತ್ತು ಆತನು ಎಲ್ಲಿಗೆ ಹೋಗಬೇಕೆಂದು ಬಯಸುತ್ತಾನೆ ಎನ್ನುವುದನ್ನು ತಿಳಿದುಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತಾರೆ. ಕೆಳಗಿನ ಯಾವ ಸಂದರ್ಭವು, ವೃತ್ತಿ ನಕ್ಷೆಯನ್ನು ಉತ್ತಮ ಚಟುವಟಿಕೆಯನ್ನಾಗಿ ಮಾಡುತ್ತದೆ:",
              "te": "ప్రొఫెసర్ నాగ్ శిఖర్‌ కు కెరీర్ మ్యాప్‌ ను రూపొందించడంలో అతని ఎంపికలను అర్థం చేసుకోవడంలో సహాయం చేస్తుంది మరియు అతను ఎక్కడ ఉన్నాడో మరియు అతను ఎక్కడికి వెళ్లాలనుకుంటున్నాడో తెలుసుకోవడానికి అతనికి సహాయం చేస్తుంది. కింది పరిస్థితులలో ఏది కెరీర్ మ్యాప్‌ ను మంచి కార్యాచరణగా మారుస్తోంది:",
              "or": "ପ୍ରଫେସର ନାଗ ଶିଖରଙ୍କୁ ତାଙ୍କ ବିକଳ୍ପ ବୁଝାଇବା ପାଇଁ ଏକ\n  କ୍ୟାରିୟର ମାନଚିତ୍ର ତିଆରି କରିବାରେ ସାହାଯ୍ୟ କରନ୍ତି ଏବଂ ସେ କେଉଁଠାରେ\n  ଅଛନ୍ତି ଏବଂ ସେ କେଉଁଠାକୁ ଯିବାକୁ ଚାହୁଁଛନ୍ତି ତାହା ଜାଣିବାରେ ସାହାଯ୍ୟ କରନ୍ତି \n | ନିମ୍ନଲିଖିତ ପରିସ୍ଥିତି ମଧ୍ୟରୁ କେଉଁଟି, ଏକ କ୍ୟାରିୟର ମାନଚିତ୍ରକୁ ଏକ ଭଲ ରୂପେ ଦର୍ଶାଉଛି:",
              "as": "অধ্যাপক নাগে শিখৰক তাৰ বিকল্পবোৰ বুজিবলৈ আৰু সি ক'ত আছে আৰু সি ক'লৈ যাব বিচাৰে তাত উপনীত হ'বলৈ কি লাগিব জানিবলৈ কেৰিয়াৰ মেপ বনোৱাত সহায় কৰে। তলৰ কোনটো পৰিস্থিতিত, কেৰিয়াৰ মেপক এটা ভাল কাৰ্যকলাপ কৰি তুলিছে:",
              "gu": "પ્રાધ્યાપક નાગ શિખરને તેના વિકલ્પો સમજવા માટે કારકિર્દીનો નકશો બનાવવામાં મદદ કરે છે અને તે ક્યાં છે અને તે જ્યાં જવા માંગે છે ત્યાં પહોંચવા માટે શું કરવું પડશે તે જાણવામાં મદદ કરે છે. નીચેનામાંથી કઈ પરિસ્થિતિમાં, કારકિર્દીના નકશાને સારી પ્રવૃત્તિ બનાવી રહી છે:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "To identify your strengths and areas of improvement for a particular career",
              "hi": "किसी विशेष करियर के लिए अपनी क्षमता और सुधार के क्षेत्रों की पहचान करना",
              "ka": "ನಿರ್ದಿಷ್ಟ ವೃತ್ತಿಜೀವನಕ್ಕಾಗಿ ನಿಮ್ಮ ಸಾಮರ್ಥ್ಯ ಮತ್ತು ಸುಧಾರಣೆಯ ಕ್ಷೇತ್ರಗಳನ್ನು ಗುರುತಿಸಲು",
              "te": "నిర్దిష్ట కెరీర్ కోసం మీ బలాలు మరియు మెరుగుదల ప్రాంతాలను గుర్తించడానికి",
              "or": "ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ବୃତ୍ତି ପାଇଁ ଆପଣଙ୍କର ଶକ୍ତି ଏବଂ ଉନ୍ନତିର କ୍ଷେତ୍ରଗୁଡିକ ଚିହ୍ନଟ କରିବାକୁ |",
              "as": "এক নিৰ্দিষ্ট কেৰিয়াৰৰ বাবে তোমাৰ শক্তি আৰু উন্নতিৰ ক্ষেত্ৰবোৰ চিনাক্ত কৰিবলৈ",
              "gu": "ચોક્કસ કારકિર્દી માટે તમારી શક્તિઓ અને સુધારણાના ક્ષેત્રોને ઓળખવા"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "To ensure you do not shift career paths and stay committed to a direction",
              "hi": "यह सुनिश्चित करना कि आप करियर के रास्तों में बदलाव न करें और एक दिशा में समर्पित रहें",
              "ka": "ನೀವು ವೃತ್ತಿ ಮಾರ್ಗಗಳನ್ನು ಬದಲಾಯಿಸುವುದಿಲ್ಲ ಮತ್ತು ನಿರ್ದೇಶನಕ್ಕೆ ಬದ್ಧರಾಗಿರಿ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು",
              "te": "మీరు కెరీర్ మార్గాలను మార్చుకోకుండా మరియు ఒకే రంగంలో ఉండేలా చూసుకోవడానికి",
              "or": "ନିଶ୍ଚିତ କର ଯେ ତୁମେ କ୍ୟାରିୟର ପଥ ସ୍ଥାନାନ୍ତର କର ନାହିଁ ଏବଂ ଏକ ଦିଗ\n  ପ୍ରତି ପ୍ରତିବଦ୍ଧ ରୁହ |",
              "as": "তুমি কেৰিয়াৰৰ পথ সলনি নকৰা আৰু এটা দিশত প্ৰতিশ্ৰুতিবদ্ধ থকাটো নিশ্চিত কৰিবলৈ",
              "gu": "તમે કારકિર્દીના માર્ગો બદલતા નથી અને દિશા માટે પ્રતિબદ્ધ રહો તેની ખાતરી કરવા માટે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "To test a career choice out with an in-depth experience before finalising",
              "hi": "निश्चित करने से पहले कैरियर की पसंद का गहन अनुभव के साथ परीक्षण करना ",
              "ka": "ಅಂತಿಮಗೊಳಿಸುವ ಮೊದಲು ಆಳವಾದ ಅನುಭವದೊಂದಿಗೆ ವೃತ್ತಿ ಆಯ್ಕೆಯನ್ನು ಪರೀಕ್ಷಿಸಲು",
              "te": "ఖరారు చేయడానికి ముందు మరింత లోతుగా కెరీర్ ఎంపికను పరీక్షించడానికి",
              "or": "ଚୂଡ଼ାନ୍ତ କରିବା ପୂର୍ବରୁ ଏକ ଗଭୀର ଅନୁଭୂତି ସହିତ କ୍ୟାରିୟର ପସନ୍ଦ ପରୀକ୍ଷା କରିବାକୁ |",
              "as": "চূড়ান্ত কৰাৰ আগতে গভীৰ অভিজ্ঞতাৰ সৈতে কেৰিয়াৰৰ পছন্দ পৰীক্ষা কৰা",
              "gu": "કારકિર્દીની પસંદગીને અંતિમ સ્વરૂપ આપતા પહેલા ઊંડાણપૂર્વકના અનુભવ સાથે ચકાસવા માટે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "To know the future job opportunities after joining a particular course",
              "hi": "किसी विशेष कोर्स को करने के बाद भविष्य में नौकरी के अवसरों को जानना ",
              "ka": "ನಿರ್ದಿಷ್ಟ ಕೋರ್ಸ್‌ಗೆ ಸೇರಿದ ನಂತರ ಭವಿಷ್ಯದ ಉದ್ಯೋಗಾವಕಾಶಗಳನ್ನು ತಿಳಿದುಕೊಳ್ಳಲು",
              "te": "ఫలానా కోర్సులో చేరిన తర్వాత భవిష్యత్తు ఉద్యోగ అవకాశాలను తెలుసుకోవడం",
              "or": "ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ପାଠ୍ୟକ୍ରମରେ ଯୋଗଦେବା ପରେ ଭବିଷ୍ୟତର ଚାକିରି ସୁଯୋଗ ଜାଣିବା |",
              "as": "এটা নিৰ্দিষ্ট পাঠ্যক্ৰমত যোগদান কৰাৰ পিছত ভৱিষ্যতৰ চাকৰিৰ সুযোগবোৰ জানিবলৈ",
              "gu": "ચોક્કસ કોર્સમાં જોડાયા પછી ભવિષ્યમાં નોકરીની તકો જાણવા"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಉತ್ತಮ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ବହୁତ ଭଲ କାମ!",
              "as": "ভাল কাম!",
              "gu": "સરસ કામ!"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Think again! Career Maps can be made again if you choose to shift direction",
              "hi": "फिर से विचार करो! यदि आप दिशा में बदलाव चुनते हैं तो कैरियर मैप दुबारा बनाया जा सकता हैं",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ನೀವು ದಿಕ್ಕನ್ನು ಬದಲಾಯಿಸಲು ಆಯ್ಕೆ ಮಾಡಿದಲ್ಲಿ ವೃತ್ತಿ ನಕ್ಷೆಗಳನ್ನು ಪುನಃ ಮಾಡಬಹುದು",
              "te": "మళ్లీ ఆలోచించండి! మీరు దిశను మార్చాలని అనుకుంటే కెరీర్ మ్యాప్‌లను మళ్లీ తయారు చేయవచ్చు",
              "or": "ପୁଣି ଥରେ ଭାବ! ଯଦି ଆପଣ ଦିଗ ବଦଳାଇବାକୁ ବାଛିଛନ୍ତି ତେବେ କ୍ୟାରିଅର୍\n  ମ୍ୟାପ୍ସ ପୁଣି ତିଆରି କରାଯାଇପାରିବ |",
              "as": "আকৌ চিন্তা কৰা! যদি তুমি দিশ সলনি কৰিব বিচাৰা তেন্তে কেৰিয়াৰ মেপবোৰ পুনৰ নিৰ্মাণ কৰিব পাৰি",
              "gu": "ફરીથી વિચારો! જો તમે દિશા બદલવાનું પસંદ કરો તો કારકિર્દીના નકશા ફરીથી બનાવી શકાય છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Think again! To test a career choice an internship is a better option",
              "hi": "फिर से विचार करो! करियर रुचि की जांच के लिए इंटर्नशिप एक ज़्यादा अच्छा विकल्प है",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ವೃತ್ತಿ ಆಯ್ಕೆಯನ್ನು ಪರೀಕ್ಷಿಸಲು ಇಂಟರ್ನ್‌ಶಿಪ್ ಉತ್ತಮ ಆಯ್ಕೆಯಾಗಿದೆ",
              "te": "మళ్లీ ఆలోచించండి! కెరీర్ ఎంపికను పరీక్షించడానికి ఇంటర్న్‌షిప్ ఉత్తమ ఎంపిక",
              "or": "ପୁଣି ଥରେ ଭାବ! ନିଜ ପସନ୍ଦର କ୍ୟାରିୟର ପରୀକ୍ଷା କରିବାକୁ ଏକ ଇଣ୍ଟର୍ନସିପ୍\n  ଏକ ଉତ୍ତମ ବିକଳ୍ପ |",
              "as": "আকৌ চিন্তা কৰা! কেৰিয়াৰৰ পছন্দ পৰীক্ষা কৰিবলৈ এটা ইণ্টাৰ্নশ্বিপ এটা ভাল বিকল্প",
              "gu": "ફરીથી વિચારો! કારકિર્દીની પસંદગી ચકાસવા માટે ઇન્ટર્નશિપ એ વધુ સારો વિકલ્પ છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Think again! Speaking with alumni from a course or college officials can help you know the job opportunities possible after a course.",
              "hi": "फिर से विचार करो! पूर्व छात्रों के साथ या कॉलेज के अधिकारियों से बात कर के आपको कोर्स के बाद नौकरी के अवसरों को जानने में सहायता मिल सकती है।",
              "ka": "ಮತ್ತೊಮ್ಮೆ ಆಲೋಚಿಸಿ! ಕೋರ್ಸ್‌ನ ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳು ಅಥವಾ ಕಾಲೇಜು ಅಧಿಕಾರಿಗಳೊಂದಿಗೆ ಮಾತನಾಡುವುದು ಕೋರ್ಸ್‌ನ ನಂತರ ಸಾಧ್ಯವಿರುವ ಉದ್ಯೋಗಾವಕಾಶಗಳನ್ನು ತಿಳಿದುಕೊಳ್ಳಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "మరలా ఆలోచించు! ఒక కోర్సుకు చెందిన పూర్వ విద్యార్థులతో లేదా కళాశాల అధికారులతో మాట్లాడటం కోర్సు తర్వాత సాధ్యమయ్యే ఉద్యోగ అవకాశాలను తెలుసుకోవడంలో మీకు సహాయపడుతుంది.",
              "or": "ପୁଣି ଥରେ ଭାବ! ଏକ ପାଠ୍ୟକ୍ରମ କିମ୍ବା କଲେଜ ଅଧିକାରୀଙ୍କ ଠାରୁ ଆଲୁମିନି ସହିତ କଥାବାର୍ତ୍ତା ଆପଣଙ୍କୁ ଏକ ପାଠ୍ୟକ୍ରମ ପରେ ଚାକିରିର ସୁଯୋଗ ଜାଣିବାରେ ସାହାଯ୍ୟ କରିଥାଏ |",
              "as": "আকৌ চিন্তা কৰা! পাঠ্যক্ৰম বা কলেজৰ বিষয়াসকলৰ সৈতে কথা পাতিলে তোমাক এটা পাঠ্যক্ৰমৰ পিছত পাব পৰা চাকৰিৰ সুযোগবোৰ জনাত সহায় কৰিব পাৰে।",
              "gu": "ફરીથી વિચારો! કોર્સના ભૂતપૂર્વ વિદ્યાર્થીઓ અથવા કૉલેજ અધિકારીઓ સાથે વાત કરવાથી તમને કોર્સ પછી સંભવિત નોકરીની તકો જાણવામાં મદદ મળી શકે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_8 bg",
              "hi": "B5_1_8 बीजी",
              "ka": "B5_1_8 bg",
              "te": "B5_1_8 bg",
              "or": "B5_1_8 bg",
              "as": "B5_1_8 bg",
              "gu": "B5_1_8 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Professor Nag",
              "hi": "प्रोफेसर नाग",
              "ka": "ಪ್ರೊಫೆಸರ್ ನಾಗ್",
              "te": "ప్రొఫెసర్ నాగ్",
              "or": "ପ୍ରଫେସର ନାଗ",
              "as": "অধ্যাপক নাগ",
              "gu": "પ્રાધ્યાપક નાગ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Professor Nag",
              "hi": "प्रोफेसर नाग",
              "ka": "ಪ್ರೊಫೆಸರ್ ನಾಗ್",
              "te": "ప్రొఫెసర్ నాగ్",
              "or": "ପ୍ରଫେସର ନାଗ",
              "as": "অধ্যাপক নাগ",
              "gu": "પ્રાધ્યાપક નાગ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "With this, I can work and learn on the job. But more importantly, I'll start earning much sooner.",
              "hi": "इसके साथ, मैं काम कर सकता हूं और नौकरी पर सीख सकता हूं। लेकिन सबसे महत्वपूर्ण बात यह है कि मैं जल्द ही कमाई शुरु कर दूंगा।",
              "ka": "ಇದರೊಂದಿಗೆ, ನಾನು ಕೆಲಸವನ್ನು ಮಾಡಬಹುದು ಮತ್ತು ಕಲಿಯಬಹುದು. ಆದರೆ ಹೆಚ್ಚು ಮುಖ್ಯವಾಗಿ, ನಾನು ಹೆಚ್ಚು ಬೇಗ ಗಳಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತೇನೆ.",
              "te": "దీంతో ఉద్యోగం చేయగలను మరియు నేర్చుకోగలను. కానీ మరీ ముఖ్యంగా, నేను చాలా త్వరగా సంపాదించడం ప్రారంభిస్తాను.",
              "or": "ଏହା ସହିତ, ମୁଁ ଚାକିରି କରିପାରିବି ଏବଂ ଶିଖିବି | କିନ୍ତୁ ସବୁଠାରୁ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ \n କଥା ହେଉଛି, ମୁଁ ବହୁତ ଶୀଘ୍ର ରୋଜଗାର କରିବା ଆରମ୍ଭ କରିବି |",
              "as": "ইয়াৰ সৈতে, মই কাম কৰিব পাৰিম আৰু কাম কৰাৰ লগে লগে শিকিবও পাৰিম। কিন্তু অধিক গুৰুত্বপূৰ্ণ ভাৱে, মই অতি সোনকালে উপাৰ্জন কৰা আৰম্ভ কৰিম।",
              "gu": "આનાથી, હું નોકરી પર કામ કરી શકું છું અને શીખી શકું છું. પરંતુ વધુ મહત્ત્વની વાત એ છે કે હું ખૂબ જલ્દી કમાવાનું શરૂ કરીશ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Yes, that is one clear advantage. But if you do the course, you will start with a better job position.",
              "hi": "हां, यह एक स्पष्ट लाभ है। लेकिन अगर आप कोर्स करते हैं, तो आप ज़्यादा अच्छे पद की नौकरी से शुरु करेंगे।",
              "ka": "ಹೌದು, ಇದು ಒಂದು ಸ್ಪಷ್ಟವಾದ ಪ್ರಯೋಜನವಾಗಿದೆ. ಆದರೆ ನೀವು ಕೋರ್ಸ್ ಮಾಡಿದರೆ, ನೀವು ಉತ್ತಮ ಉದ್ಯೋಗದ ಸ್ಥಾನದೊಂದಿಗೆ ಪ್ರಾರಂಭಿಸುತ್ತೀರಿ.",
              "te": "అవును, ఇది ఒక స్పష్టమైన ప్రయోజనం. కానీ మీరు కోర్సు చేస్తే, మీరు మంచి ఉద్యోగంతో ప్రారంభిస్తారు.",
              "or": "ହଁ, ତାହା ହେଉଛି ଏକ ସ୍ପଷ୍ଟ ସୁବିଧା | କିନ୍ତୁ ଯଦି ତୁମେ ପାଠ୍ୟକ୍ରମ କର, ତୁମେ \n ଏକ ଉତ୍ତମ ଚାକିରି ସ୍ଥିତି ସହିତ ଆରମ୍ଭ କରିବ |",
              "as": "হয়, এইটো এটা ভাল সুবিধা। কিন্তু যদি আপুনি পাঠ্যক্ৰমটো সম্পূৰ্ণ কৰে, আপুনি এটা উন্নত চাকৰিৰে আৰম্ভ কৰিব পাৰিব।",
              "gu": "હા, તે એક સ્પષ્ટ ફાયદો છે. પરંતુ જો તું કોર્સ કરો છો, તો તને સારી નોકરીની સ્થિતિ સાથે પ્રારંભ કરશો."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Both options will give me good exposure to the kind of technology used in agriculture.",
              "hi": "दोनों विकल्प मुझे कृषि में उपयोग की जाने वाली तकनीक की अच्छी जानकारी देंगे।",
              "ka": "ಎರಡೂ ಆಯ್ಕೆಗಳು ನನಗೆ ಕೃಷಿಯಲ್ಲಿ ಬಳಸುವ ತಂತ್ರಜ್ಞಾನದ ರೀತಿಯ ಬಗ್ಗೆ ಉತ್ತಮ ತಿಳುವಳಿಕೆ ನೀಡುತ್ತದೆ.",
              "te": "రెండు ఎంపికలు నాకు వ్యవసాయంలో ఉపయోగించే సాంకేతికత రకాన్ని బాగా బహిర్గతం చేస్తాయి.",
              "or": "କୃଷି କ୍ଷେତ୍ରରେ ବ୍ୟବହୃତ ହେଉଥିବା ଟେକ୍ନୋଲୋଜିର ଉଭୟ ବିକଳ୍ପ ମୋତେ\n  ଭଲ ଏକ୍ସପୋଜର୍ ଦେବ |",
              "as": "দুয়োটা বিকল্পই মোক কৃষি খণ্ডত ব্যৱহৃত প্ৰযুক্তিৰ বিষয়ে ভাল জ্ঞান দিব।",
              "gu": "બંને વિકલ્પો મને કૃષિમાં વપરાતી ટેક્નોલોજીનો સારો સંપર્ક આપશે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "Yes. Now it is up to you to make a choice.",
              "hi": "हां। अब आप क्या चुनते हैं यह आप पर निर्भर है।",
              "ka": "ಹೌದು. ಈಗ ಆಯ್ಕೆ ಮಾಡುವುದು ನಿಮಗೆ ಬಿಟ್ಟದ್ದು.",
              "te": "అవును. ఇప్పుడు ఎంపిక చేసుకోవడం మీ ఇష్టం.",
              "or": "ହଁ ବର୍ତ୍ତମାନ ଏକ ପସନ୍ଦ କରିବା ଆପଣଙ୍କ ଉପରେ ନିର୍ଭର କରେ |",
              "as": "হয়। এতিয়া এটা বাছি লোৱাটো আপোনাৰ ওপৰত নিৰ্ভৰ কৰে।",
              "gu": "હા. હવે પસંદગી કરવાનું તારા પર છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "Role of mentor",
              "hi": "परामर्शदाता की भूमिका",
              "ka": "ಮಾರ್ಗದರ್ಶಕರ ಪಾತ್ರ",
              "te": "మెంటార్ యొక్క పాత్ర",
              "or": "ପରାମର୍ଶଦାତାଙ୍କର ଭୂମିକା |",
              "as": "পৰামৰ্শদাতাৰ ভূমিকা",
              "gu": "માર્ગદર્શકની ભૂમિકા"
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_9_D",
              "hi": "B5_1_9_D",
              "ka": "B5_1_9_D",
              "te": "B5_1_9_D",
              "or": "B5_1_9_D",
              "as": "B5_1_9_D",
              "gu": "B5_1_9_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Shikhar do?",
              "hi": "शिखर को क्या करना चाहिए?",
              "ka": "ಶಿಖರ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "శిఖర్ ఏం చేయాలి?",
              "or": "ଶିଖର କ’ଣ କରିବା ଉଚିତ୍?",
              "as": "শিখৰে কি কৰা উচিত?",
              "gu": "શિખરે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Apply to the course",
              "hi": "कोर्स के लिए आवेदन करे",
              "ka": "ಕೋರ್ಸ್‌ಗೆ ಅರ್ಜಿ ಹಾಕುವುದು",
              "te": "కోర్సుకు దరఖాస్తు చేయడం",
              "or": "ପାଠ୍ୟକ୍ରମ ପାଇଁ ଆବେଦନ କରନ୍ତୁ |",
              "as": "পাঠ্যক্ৰমটোৰ বাবে আবেদন কৰক",
              "gu": "કોર્સ માટે અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Take up the job",
              "hi": "काम करे",
              "ka": "ಕೆಲಸಕ್ಕೆ ಹೋಗುವುದು",
              "te": "ఉద్యోగంలో చేరడం",
              "or": "ଚାକିରି ନିଅ |",
              "as": "চাকৰিটো কৰক",
              "gu": "નોકરી ચાલુ કરો"
            }
          }
        ]
      },
      "scene45": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Apply to the course",
              "hi": "कोर्स के लिए आवेदन करे",
              "ka": "ಕೋರ್ಸ್‌ಗೆ ಅರ್ಜಿ ಹಾಕುವುದು",
              "te": "కోర్సుకు దరఖాస్తు చేయడం",
              "or": "ପାଠ୍ୟକ୍ରମ ପାଇଁ ଆବେଦନ କରନ୍ତୁ |",
              "as": "পাঠ্যক্ৰমটোৰ বাবে আবেদন কৰক",
              "gu": "કોર્સ માટે અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Shikhar decides to take the course at the agriculture college in Jabalpur. When he brings up the topic with his father, he is not very happy.",
              "hi": "शिखर ने जबलपुर में कृषि कॉलेज में कोर्स करने का निर्णय लिया। जब वह अपने पिता के पास यह विषय लाता है, तो वह बहुत खुश नहीं होते हैं।",
              "ka": "ಶಿಖರ್ ಜಬಲ್ಪುರದ ಕೃಷಿ ಕಾಲೇಜಿನಲ್ಲಿ ಕೋರ್ಸ್ ತೆಗೆದುಕೊಳ್ಳಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಅವನು ತನ್ನ ತಂದೆಯೊಂದಿಗೆ ವಿಷಯವನ್ನು ತಂದಾಗ, ಅವನು ಅಷ್ಟೇನೂ ಸಂತೋಷ ಪಡುವುದಿಲ್ಲ",
              "te": "శిఖర్ జబల్పూర్‌ లోని వ్యవసాయ కళాశాలలో కోర్సు తీసుకోవాలని నిర్ణయించుకున్నాడు. అతను తన తండ్రితో ఈ విషయం చెప్పినపుడు, అతను బాధపడ్డాడు.",
              "or": "ଶିଖର ଜବଲପୁରର କୃଷି ମହାବିଦ୍ୟାଳୟରେ ପାଠ୍ୟକ୍ରମ ନେବାକୁ ନିଷ୍ପତ୍ତି\n  ନେଇଛନ୍ତି। ଯେତେବେଳେ ସେ ତାଙ୍କ ପିତାଙ୍କ ସହିତ ପ୍ରସଙ୍ଗ କୁହନ୍ତି , \n ସେତେବେଳେ ସେ ବହୁତ ଖୁସି ନୁହନ୍ତି |",
              "as": "শিখৰে জবলপুৰৰ কৃষি মহাবিদ্যালয়ত পাঠ্যক্ৰমটো লোৱাৰ সিদ্ধান্ত ল'লে। যেতিয়া তেওঁ দেউতাকৰ আগত কথাটো ক'লে দেউতাক বৰ সুখী নাছিল।",
              "gu": "શિખરે જબલપુરની એગ્રીકલ્ચર કોલેજમાં અભ્યાસક્રમ લેવાનું નક્કી કર્યું. જ્યારે તે તેના પિતા સાથે વિષય કહે છે, ત્યારે તે ખૂબ ખુશ નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Babuji",
              "hi": "बाबूजी",
              "ka": "ಬಾಬುಜಿ",
              "te": "నాన్నగారు",
              "or": "ବାବୁଜୀ",
              "as": "দেউতা",
              "gu": "બાબુજી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Course on Agriculture? We have gained this knowledge through generations. How will you sit in a classroom and learn something about agriculture?",
              "hi": "कृषि के लिए कोर्स? हमने पीढ़ियों के माध्यम से इस ज्ञान को प्राप्त किया है। तुम कक्षा में बैठकर कृषि के बारे में कैसे कुछ सीखोगे?",
              "ka": "ಕೃಷಿಯ ಕೋರ್ಸ್? ನಾವು ತಲೆಮಾರುಗಳ ಮೂಲಕ ಈ ಜ್ಞಾನವನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೇವೆ. ತರಗತಿಯಲ್ಲಿ ಕುಳಿತು ಕೃಷಿಯ ಬಗ್ಗೆ ಕಲಿಯುವುದು ಹೇಗೆ?",
              "te": "వ్యవసాయంపై కోర్సు? మేము తరతరాలుగా ఈ జ్ఞానాన్ని పొందాము. నువ్వు తరగతి గదిలో కూర్చుని వ్యవసాయం గురించి ఎలా నేర్చుకుంటావు?",
              "or": "କୃଷି ଉପରେ ପାଠ୍ୟକ୍ରମ? ପିଢି ପରେ ପିଢୀ ଆମେ ଏହି ଜ୍ଞାନ ହାସଲ କରିଛୁ\n  | ଆପଣ କିପରି ଏକ ଶ୍ରେଣୀଗୃହରେ ବସି କୃଷି ବିଷୟରେ କିଛି ଶିଖିବେ?",
              "as": "কৃষি বিষয়ৰ পাঠ্যক্ৰম? আমি বংশানুক্ৰমে এই জ্ঞান লাভ কৰিছোঁ। তুমি শ্ৰেণীকোঠাত বহি কৃষিৰ বিষয়ে কেনেকৈ কিবা শিকিবা?",
              "gu": "કૃષિ પર અભ્યાસક્રમ? અમે પેઢીઓ દ્વારા આ જ્ઞાન મેળવ્યું છે. તમે વર્ગખંડમાં બેસીને ખેતી વિશે કઈ રીતે શીખશો?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "But Ba….",
              "hi": "लेकिन बा ...।",
              "ka": "ಆದರೆ ಬಾ....",
              "te": "కానీ బా....",
              "or": "କିନ୍ତୁ ବାବୁଜୀ",
              "as": "কিন্তু দে….",
              "gu": "પણ બાબુજી…."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_1_2///",
              "hi": "B6_1_2 ////",
              "ka": "B6_1_2///",
              "te": "B6_1_2///",
              "or": "B6_1_2///",
              "as": "B6_1_2///",
              "gu": "B6_1_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Will you get a job after this course? Your jugaad isn't going to help us. Only a steady income will keep us at ease.",
              "hi": "क्या तुमको इस कोर्स के बाद नौकरी मिल जाएगी? तुम्हारा जुगाड हमारी मदद नहीं करेगा। केवल एक स्थिर आय हमें सहूलियत से रखेगी।",
              "ka": "ಈ ಕೋರ್ಸ್ ನಂತರ ನಿನಗೆ ಕೆಲಸ ಸಿಗುತ್ತದೆಯೇ? ನಿನ್ನ ಜುಗಾದ್ ನಮಗೆ ಸಹಾಯ ಮಾಡುವುದಿಲ್ಲ. ಸ್ಥಿರವಾದ ಆದಾಯ ಮಾತ್ರವೇ ನಮ್ಮನ್ನು ನಿರಾಳವಾಗಿಡುತ್ತದೆ.",
              "te": "ఈ కోర్సు తర్వాత నీకు ఉద్యోగం వస్తుందా? నీ జుగాద్ మాకు సహాయం చేయదు. స్థిరమైన ఆదాయం మాత్రమే మమ్మల్ని సుఖంగా ఉంచుతుంది.",
              "or": "ଏହି ପାଠ୍ୟକ୍ରମ ପରେ ତୁମେ ଚାକିରି ପାଇବ କି? ତୁମର ଜୁଗାଡ ଆମକୁ \n ସାହାଯ୍ୟ କରିବାକୁ ଯିବ ନାହିଁ | କେବଳ ଏକ ସ୍ଥିର ଆୟ ଆମକୁ ଆରାମରେ ରଖିବ |",
              "as": "এই পাঠ্যক্ৰমৰ পিছত তুমি চাকৰি পাবা নে? তোমাৰ যোগাৰে আমাক সহায় কৰিব নোৱাৰে। কেৱল এটা স্থিৰ উপাৰ্জনে আমাক সুখত ৰাখিব।",
              "gu": "શું તમને આ કોર્સ પછી નોકરી મળશે? તારો જુગાડ આપણને મદદ કરશે નહીં. માત્ર સ્થિર આવક જ આપણને આરામમાં રાખશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "But Babuji, please hear me out. A lot is being done in agriculture to improve our yield. Thanks to the subjects I took in school, I am eligible for the course.",
              "hi": "लेकिन बाबूजी, कृपया मुझे सुनें। हमारी उपज में सुधार के लिए कृषि में बहुत कुछ किया जा रहा है। स्कूल में  लिए गए विषयों के कारण, मैं कोर्स के लिए योग्य हूं।",
              "ka": "ಆದರೆ ಬಾಬೂಜಿ, ದಯವಿಟ್ಟು ನನ್ನ ಮಾತು ಕೇಳಿ. ನಮ್ಮ ಇಳುವರಿಯನ್ನು ಸುಧಾರಿಸಲು ಕೃಷಿಯಲ್ಲಿ ಬಹಳಷ್ಟು ಕೆಲಸವನ್ನು ಮಾಡಲಾಗುತ್ತಿದೆ. ನಾನು ಶಾಲೆಯಲ್ಲಿ ತೆಗೆದುಕೊಂಡ ವಿಷಯಗಳಿಗೆ ಧನ್ಯವಾದಗಳು, ನಾನು ಕೋರ್ಸ್‌ಗೆ ಅರ್ಹನಾಗಿದ್ದೇನೆ.",
              "te": "కానీ నాన్నగారు, దయచేసి నా మాట వినండి. మన దిగుబడిని మెరుగుపరచడానికి వ్యవసాయంలో చాలా చేస్తున్నారు. నేను పాఠశాలలో చదివిన సబ్జెక్టులకు ధన్యవాదాలు, నేను కోర్సుకు అర్హత పొందాను.",
              "or": "କିନ୍ତୁ ବାବୁଜୀ, ଦୟାକରି ମୋତେ ଶୁଣନ୍ତୁ | ଆମର ଅମଳର ଉନ୍ନତି ପାଇଁ କୃଷି\n  କ୍ଷେତ୍ରରେ ଅନେକ କାର୍ଯ୍ୟ କରାଯାଉଛି | ମୁଁ ବିଦ୍ୟାଳୟରେ ନେଇଥିବା ବିଷୟଗୁଡିକ\n  ପାଇଁ ଧନ୍ୟବାଦ ଦେବି, ମୁଁ ପାଠ୍ୟକ୍ରମ ପାଇଁ ଯୋଗ୍ୟ ଅଟେ |",
              "as": "কিন্তু দেউতা, মোৰ কথাটো শুনকচোন। আমাৰ উৎপাদন বৃদ্ধি কৰিবলৈ কৃষিক্ষেত্ৰত বহুতো কাম কৰা হৈছে। মই স্কুলত লোৱা বিষয়বোৰক ধন্যবাদ যে মই এই পাঠ্যক্ৰমটোৰ বাবে যোগ্য বিবেচিত হ'লো।",
              "gu": "પણ બાબુજી, મારી વાત સાંભળો. આપણી ઉપજને સુધારવા માટે કૃષિમાં ઘણું કરવામાં આવી રહ્યું છે. મેં શાળામાં લીધેલા વિષયો માટે આભાર, હું અભ્યાસક્રમ માટે પાત્ર છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "You said you wouldn't do engineering, and I accepted it. But now, if you tell me you want to waste money on an education that will only lead you back to the farm, I won't tolerate it.",
              "hi": "तुमने कहा कि तुम इंजीनियरिंग नहीं करोगे, और मैंने इसे स्वीकार कर लिया। लेकिन अब, यदि तुम मुझे बताते हैं कि तुम ऐसी शिक्षा पर पैसे बर्बाद करना चाहते हो जो तुमको केवल खेत में वापस ले जाएगा, मैं इसे बर्दाश्त नहीं करूंगा।",
              "ka": "ನೀನು ಇಂಜಿನಿಯರಿಂಗ್ ಮಾಡುವುದಿಲ್ಲ ಎಂದು ಹೇಳಿದ್ದೆ, ನಾನು ಒಪ್ಪಿಕೊಂಡೆ. ಆದರೆ ಈಗ, ನೀನು ಮತ್ತೂಮ್ಮೆ ಕೃಷಿ ಶಿಕ್ಷಣಕ್ಕಾಗಿ ಹಣವನ್ನು ವ್ಯರ್ಥ ಮಾಡಲು ಬಯಸುತ್ತೀಯಾ ಎಂದು ಹೇಳಿದರೆ, ನಾನು ಅದನ್ನು ಸಹಿಸುವುದಿಲ್ಲ.",
              "te": "నువ్వు ఇంజినీరింగ్ చేయనని చెప్పావు, నేను అంగీకరించాను. కానీ ఇప్పుడు, నువ్వు వ్యవసాయానికి దారితీసే విద్య కోసం డబ్బును వృధా చేయాలనుకుంటున్నావని నాకు చెబితే, నేను దానిని సహించను.",
              "or": "ତୁମେ କହିଲ ଯେ ତୁମେ ଇଞ୍ଜିନିୟରିଂ କରିବ ନାହିଁ, ଏବଂ ମୁଁ ଏହାକୁ ଗ୍ରହଣ କଲି | କିନ୍ତୁ ବର୍ତ୍ତମାନ, ଯଦି ତୁମେ ମୋତେ କୁହ ଯେ ତୁମେ ଏକ ଶିକ୍ଷା ପାଇଁ ଟଙ୍କା ନଷ୍ଟ କରିବାକୁ ଚାହୁଁଛ ଯାହା ତୁମକୁ ଚାଷ ଜମିକୁ ଫେରାଇ ଆଣିବ, ମୁଁ ଏହାକୁ ବରଦାସ୍ତ\n  କରିବି ନାହିଁ |",
              "as": "তুমি কৈছিলা তুমি ইঞ্জিনিয়াৰিং নপঢ়া, আৰু মই সেইটো মানি লৈছিলোঁ। কিন্তু এতিয়া যদি তুমি কোৱা যে, আকৌ পথাৰলৈ ঘুৰি আহিব লগা বিষয়ত পঢ়াৰ নামত টকাৰ অপচয় কৰিব বিচাৰা, মই ইয়াক সহ্য নকৰোঁ।",
              "gu": "તે કહ્યું હતું કે તું એન્જીનીયરીંગ નહિ કરીશ, અને મેં તે સ્વીકાર્યું. પણ હવે, જો તું મને કહે છે કે તું એવા શિક્ષણ પર પૈસા વેડફવા માંગો છો જે તને ફક્ત ખેતરમાં જ લઈ જશે, તો હું તેને સહન કરીશ નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "But the course is from a Government Agriculture college and the fees is very low. I can study without taking loans. There's a lot we can gain as a community from this knowledge, Babuji, trust me.",
              "hi": "लेकिन कोर्स एक सरकारी कृषि कॉलेज से है और फीस बहुत कम है। मैं ऋण लिए बिना अध्ययन कर सकता हूं। बाबूजी, मुझ पर विश्वास करें, इस ज्ञान से एक समुदाय के रूप में हम बहुत कुछ हासिल कर सकते हैं।",
              "ka": "ಆದರೆ ಕೋರ್ಸ್ ಸರ್ಕಾರಿ ಕೃಷಿ ಕಾಲೇಜಿನದ್ದು ಮತ್ತು ಶುಲ್ಕ ತುಂಬಾ ಕಡಿಮೆ. ನಾನು ಸಾಲ ಮಾಡದೆ ಓದಬಲ್ಲೆ. ಈ ಜ್ಞಾನದಿಂದ ನಾವು ಸಮುದಾಯವಾಗಿ ಬಹಳಷ್ಟು ಗಳಿಸಬಹುದು, ಬಾಬೂಜಿ, ನನ್ನನ್ನು ನಂಬಿರಿ.",
              "te": "కానీ కోర్సు ప్రభుత్వ వ్యవసాయ కళాశాలలో ఉంది మరియు ఫీజు చాలా తక్కువ. అప్పులు లేకుండా చదువుకోగలను. ఈ జ్ఞానం మన సమాజానికి చాలా ఊపయోగపడుతుంది, నాన్నగారు, నన్ను నమ్మండి.",
              "or": "କିନ୍ତୁ ଏହି ପାଠ୍ୟକ୍ରମ ଏକ ସରକାରୀ କୃଷି ମହାବିଦ୍ୟାଳୟରୁ କରିବାକୁ ହେବ ଏବଂ\n  ଫିସ୍ ବହୁତ କମ୍ ଅଟେ | ମୁଁ ରୁଣ ନ ନେଇ ଅଧ୍ୟୟନ କରିପାରିବି | ଏହି ଜ୍ଞାନରୁ \n ଏକ ସମ୍ପ୍ରଦାୟ ଭାବରେ ଆମେ ଅନେକ କିଛି ହାସଲ କରିପାରିବା, ବାବୁଜୀ, ମୋତେ\n  ବିଶ୍ୱାସ କର |",
              "as": "কিন্তু পাঠ্যক্ৰমটো এখন চৰকাৰী কৃষি মহাবিদ্যালয়ৰ অধীনত আৰু মাচুলো বহুত কম। মই ধাৰ-ঋণ নোলোৱাকৈয়ে পঢ়িব পাৰিম। এই শিক্ষাৰ পৰা আমি আমি একগোট হৈ যথেষ্ট উন্নতি কৰিব পাৰিম, মোক বিশ্বাস কৰক, দেউতা।",
              "gu": "પરંતુ અભ્યાસક્રમ સરકારી એગ્રીકલ્ચર કોલેજનો છે અને ફી ઘણી ઓછી છે. હું લોન લીધા વિના અભ્યાસ કરી શકું છું. આ જ્ઞાનમાંથી આપણે એક સમુદાય તરીકે ઘણું બધું મેળવી શકીએ છીએ, બાબુજી, મારા પર વિશ્વાસ કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "You need to be practical, Shikhar. Do the course only if you get a good job after it, or else don't.",
              "hi": "शिखर तुमको व्यावहारिक होना चाहिए। कोर्स केवल तभी करना जब तुमको इसके बाद अच्छी नौकरी मिलती है, अन्यथा नहीं।",
              "ka": "ನೀನು ಪ್ರಾಕ್ಟಿಕಲ್ ಆಗಿರಬೇಕು ಶಿಖರ್. ಅದರ ನಂತರ ಒಳ್ಳೆಯ ಕೆಲಸ ಸಿಕ್ಕರೆ ಮಾತ್ರ ಕೋರ್ಸ್ ಮಾಡು, ಇಲ್ಲದಿದ್ದರೆ ಬೇಡ.",
              "te": "నువ్వు ప్రాక్టికల్ గా ఉండాలి శిఖర్. ఆ తర్వాత మంచి ఉద్యోగం వస్తుంది అంటేనే కోర్సు చేయి, లేదంటే వద్దు.",
              "or": "ତୁମେ ବ୍ୟବହାରିକ ହେବା ଆବଶ୍ୟକ, ଶିଖର | ଯଦି ତୁମେ ଏହା ପରେ ଭଲ\n  ଚାକିରି ପାଇବ, ତେବେ ପାଠ୍ୟକ୍ରମ କର, ନଚେତ କରନାହି।",
              "as": "তুমি প্ৰেক্টিকেল হ'ব লাগিব শিখৰ। বিষয়টো পঢ়াৰ পাছত যদি তুমি এটা ভাল চাকৰি পোৱা তেতিয়াহে পঢ়া, নহ'লে নালাগে।",
              "gu": "તારે પ્રેક્ટિકલ બનવાની જરૂર છે, શિખર. પછી સારી નોકરી મળે તો જ કોર્સ કર, નહીં તો ના કર."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B6_1_3",
              "hi": "B6_1_3",
              "ka": "B6_1_3",
              "te": "B6_1_3",
              "or": "B6_1_3",
              "as": "B6_1_4",
              "gu": "B6_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar leaves the room, thinking he’ll not bring up this topic until the course is over.",
              "hi": "शिखर कमरे से यह सोचते हुए बाहर चला जाता है कि वह इस विषय को तब तक नहीं उठाएगा जब तक कि कोर्स पूरा न हो जाए।",
              "ka": "ಕೋರ್ಸ್ ಮುಗಿಯುವವರೆಗೆ ಈ ವಿಷಯವನ್ನು ಪ್ರಸ್ತಾಪಿಸುವುದಿಲ್ಲ ಎಂದು ಭಾವಿಸಿ ಶಿಖರ್ ಕೊಠಡಿಯಿಂದ ಹೊರಡುತ್ತಾನೆ",
              "te": "కోర్స్ పూర్తయ్యే వరకు ఈ అంశం గురించి మాట్లాడను అని భావించి శిఖర్ గది నుండి వెళ్లిపోయాడు.",
              "or": "ପାଠ୍ୟକ୍ରମ ଶେଷ ନହେବା ପର୍ଯ୍ୟନ୍ତ ସେ ଏହି ପ୍ରସଙ୍ଗ ଆଣିବେ ନାହିଁ ବୋଲି \n ଭାବି ଶିଖର ରୁମ୍ ଛାଡିଛନ୍ତି |",
              "as": "শিখৰে কোঠাৰ পৰা ওলাই গ'ল, পাঠ্যক্ৰমটো শেষ নোহোৱালৈকে তেওঁ এই বিষয়টো উত্থাপন নকৰাৰ কথা ভাবিছে।",
              "gu": "શિખર એ વિચારીને રૂમ છોડી દે છે જ્યાં સુધી કોર્સ પૂરો ન થાય ત્યાં સુધી તે આ વિષય ઉપર નહીં લાવે."
            }
          }
        ]
      },
      "scene46": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_1_1",
              "hi": "B7_1_1",
              "ka": "B7_1_1",
              "te": "B7_1_1",
              "or": "B7_1_1",
              "as": "B7_1_1",
              "gu": "B7_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar has finished three years in college.",
              "hi": "शिखर ने कॉलेज में तीन साल पूरे कर लिए हैं।",
              "ka": "ಶಿಖರ್ ಕಾಲೇಜಿನಲ್ಲಿ ಮೂರು ವರ್ಷ ಮುಗಿಸಿದ್ದಾನೆ.",
              "te": "శిఖర్ కళాశాలలో మూడేళ్లు పూర్తి చేశాడు.",
              "or": "ଶିଖର କଲେଜରେ ତିନି ବର୍ଷ ଶେଷ କରିଛନ୍ତି |",
              "as": "শিখৰে কলেজত তিনি বছৰ পাৰ কৰিলে।",
              "gu": "શિખરે કોલેજના ત્રણ વર્ષ પૂરા કર્યા છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_1_2",
              "hi": "B7_1_2",
              "ka": "B7_1_2",
              "te": "B7_1_2",
              "or": "B7_1_2",
              "as": "B7_1_2",
              "gu": "B7_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He is enjoying his course a lot. He is learning many things about agriculture science and applying these techniques to improve agricultural produce.",
              "hi": "वह अपने कोर्स का आनंद ले रहा है। वह कृषि विज्ञान के बारे में कई चीजें सीख रहा है और कृषि उपज में सुधार के लिए इन तकनीकों को लागू कर रहा है।",
              "ka": "ಅವನು ತನ್ನ ಕೋರ್ಸ್ ಅನ್ನು ತುಂಬಾ ಆನಂದಿಸುತ್ತಿದ್ದಾನೆ. ಅವನು ಕೃಷಿ ವಿಜ್ಞಾನದ ಬಗ್ಗೆ ಅನೇಕ ವಿಷಯಗಳನ್ನು ಕಲಿಯುತ್ತಿದ್ದಾನೆ ಮತ್ತು ಕೃಷಿ ಉತ್ಪನ್ನಗಳನ್ನು ಸುಧಾರಿಸಲು ಈ ತಂತ್ರಗಳನ್ನು ಅನ್ವಯಿಸುತ್ತಿದ್ದಾನೆ.",
              "te": "అతను తన కోర్సును చాలా ఎంజాయ్ చేస్తున్నాడు. అతను వ్యవసాయ శాస్త్రం గురించి చాలా విషయాలు నేర్చుకుంటున్నాడు మరియు వ్యవసాయ ఉత్పత్తులను మెరుగుపరచడానికి ఈ పద్ధతులను అమలు చేస్తున్నాడు.",
              "or": "ସେ ତାଙ୍କ ପାଠ୍ୟକ୍ରମକୁ ବହୁତ ଉପଭୋଗ କରୁଛନ୍ତି | ସେ କୃଷି ବିଜ୍ଞାନ ବିଷୟରେ\n  ଅନେକ ଜିନିଷ ଶିଖୁଛନ୍ତି ଏବଂ କୃଷି ଉତ୍ପାଦନରେ ଉନ୍ନତି ଆଣିବା ପାଇଁ ଏହି \n କୌଶଳଗୁଡିକ ପ୍ରୟୋଗ କରୁଛନ୍ତି |",
              "as": "তেওঁ পাঠ্যক্ৰমটো পঢ়ি বহুত আনন্দ উপভোগ কৰি আছে। তেওঁ কৃষি বিজ্ঞান আৰু কৃষিজাত সামগ্ৰীৰ উন্নতিৰ বাবে এই কৌশলবোৰ প্ৰয়োগ কৰাৰ বিষয়ে বহুতো কথা শিকি আছে।",
              "gu": "તે તેના અભ્યાસક્રમનો ઘણો આનંદ માણી રહ્યો છે. તે કૃષિ વિજ્ઞાન વિશે ઘણી વસ્તુઓ શીખી રહ્યો છે અને કૃષિ પેદાશોને સુધારવા માટે આ તકનીકોનો ઉપયોગ કરી રહ્યો છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_1_3",
              "hi": "B7_1_3",
              "ka": "B7_1_3",
              "te": "B7_1_3",
              "or": "B7_1_3",
              "as": "B7_1_4",
              "gu": "B7_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He has been maintaining his journal regularly with notes about his experiences and learnings.",
              "hi": "वह अपनी जर्नल को अपने अनुभवों और शिक्षाओं के बारे में नोटस के साथ नियमित रूप से बनाए रख रहा है।",
              "ka": "ಅವನು ತನ್ನ ಅನುಭವಗಳು ಮತ್ತು ಕಲಿಕೆಗಳ ಬಗ್ಗೆ ಟಿಪ್ಪಣಿಗಳೊಂದಿಗೆ ತನ್ನ ಜರ್ನಲ್ ಅನ್ನು ನಿಯಮಿತವಾಗಿ ನಿರ್ವಹಿಸುತ್ತಿದ್ದಾನೆ.",
              "te": "అతను తన అనుభవాలు మరియు అభ్యాసాల గురించి నోట్స్ తో తన జర్నల్‌ను క్రమం తప్పకుండా రాసేవాడు.",
              "or": "ସେ ତାଙ୍କ ଅନୁଭୂତି ଏବଂ ଶିଖ୍ୟା ବିଷୟରେ ନୋଟ୍ ସହିତ ନିୟମିତ ଭାବରେ\n  ନିଜର ପତ୍ରିକାକୁ ବଜାୟ ରଖିଛନ୍ତି |",
              "as": "তেওঁ নিজৰ অভিজ্ঞতা আৰু অধ্যয়নৰ বিষয়ে নিয়মীয়াকৈ টোকা দি তেওঁৰ আলোচনীখন বজাই ৰাখিছে।",
              "gu": "તે તેના અનુભવો અને શીખવાની નોંધો સાથે નિયમિતપણે તેની પુસ્તક જાળવી રહ્યો છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_1_4 journal",
              "hi": "B7_1_4 जर्नल",
              "ka": "B7_1_4 journal",
              "te": "B7_1_4 జర్నల్",
              "or": "B7_1_4 journal",
              "as": "B7_1_4 আলোচনী",
              "gu": "B7_1_4 જર્નલ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click on the pages to flip through Shikhar’s journal.",
              "hi": "शिखर की जर्नल को देखने के लिए पृष्ठों पर क्लिक करें।",
              "ka": "ಶಿಖರ್ ಅವರ ಜರ್ನಲ್ ಅನ್ನು ಫ್ಲಿಪ್ ಮಾಡಲು ಪುಟಗಳ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "శిఖర్ జర్నల్‌ ని తిప్పడానికి పేజీలపై క్లిక్ చేయండి.",
              "or": "ଶିଖରଙ୍କ ଜର୍ନାଲ୍ ମାଧ୍ୟମରେ ପୃଷ୍ଠାଗୁଡ଼ିକରେ ଫ୍ଲିପ୍ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "শিখৰৰ আলোচনীখন চাবৰ বাবে পৃষ্ঠাবোৰত ক্লিক কৰক।",
              "gu": "શિખરની ચોપડીમાં ફ્લિપ કરવા માટે પેજ પર ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page 1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "ପୃଷ୍ଠା ୧",
              "as": "পৃষ্ঠা ১",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page 2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ 2",
              "or": "ପୃଷ୍ଠା ୨",
              "as": "পৃষ্ঠা ২",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Page 3",
              "hi": "पृष्ठ 3",
              "ka": "ಪುಟ 3",
              "te": "పేజీ 3",
              "or": "ପୃଷ୍ଠା ୩",
              "as": "পৃষ্ঠা ৩",
              "gu": "પેજ 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "I learned a lot about various types of soil and how to manage water at the time of irrigation.",
              "hi": "मैंने विभिन्न प्रकार की मिट्टी और सिंचाई के समय पानी का प्रबंधन करने के बारे में बहुत कुछ सीखा।",
              "ka": "ವಿವಿಧ ರೀತಿಯ ಮಣ್ಣಿನ ಬಗ್ಗೆ ಮತ್ತು ನೀರಾವರಿ ಸಮಯದಲ್ಲಿ ನೀರನ್ನು ಹೇಗೆ ನಿರ್ವಹಿಸುವುದು ಎನ್ನುವುದರ ಬಗ್ಗೆ ನಾನು ಸಾಕಷ್ಟು ಕಲಿತಿದ್ದೇನೆ.",
              "te": "నేను వివిధ రకాల మట్టి గురించి మరియు నీటిపారుదల సమయంలో నీటిని ఎలా నిర్వహించాలో చాలా నేర్చుకున్నాను.",
              "or": "ବିଭିନ୍ନ ପ୍ରକାରର ମାଟି ଏବଂ ଜଳସେଚନ ସମୟରେ ଜଳକୁ କିପରି ପରିଚାଳନା\n  କରାଯିବ ସେ ବିଷୟରେ ମୁଁ ବହୁତ କିଛି ଶିଖିଲି |",
              "as": "মই বিভিন্ন প্ৰকাৰৰ মাটি আৰু জলসিঞ্চনৰ সময়ত পানী কেনেদৰে নিয়ন্ত্ৰণ কৰিব লাগে তাৰ বিষয়ে বহুত শিকিলোঁ।",
              "gu": "મેં વિવિધ પ્રકારની જમીન અને સિંચાઈ સમયે પાણીની વ્યવસ્થા કેવી રીતે કરવી તે વિશે ઘણું શીખ્યા."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "I enjoyed the course as it is a lot of practical work. The agriculture college has farmlands within the campus for students to practice what they are learning.",
              "hi": "मैंने कोर्स का आनंद लिया क्योंकि यह बहुत प्रयोगात्मक काम है। कृषि कॉलेज में कैंपस के भीतर छात्रों के लिए कृषि भूमि है जिसमें जो वे सीख रहे हैं उसका अभ्यास कर सकें।",
              "ka": "ಇದು ಸಾಕಷ್ಟು ಪ್ರಾಯೋಗಿಕ ಕೆಲಸವಾಗಿರುವುದರಿಂದ ನಾನು ಕೋರ್ಸ್ ಅನ್ನು ಆನಂದಿಸಿದೆ. ವಿದ್ಯಾರ್ಥಿಗಳು ತಾವು ಕಲಿಯುತ್ತಿರುವುದನ್ನು ಅಭ್ಯಸಿಸಲು ಕೃಷಿ ಕಾಲೇಜು ಕ್ಯಾಂಪಸ್‌ನೊಳಗೆ ಕೃಷಿ ಭೂಮಿಯನ್ನು ಹೊಂದಿದೆ.",
              "te": "ఇది చాలా ఆచరణాత్మక పని. కాబట్టి నేను కోర్సును ఆస్వాదించాను. విద్యార్థులు తాము నేర్చుకుంటున్న వాటిని చేయడానికి వ్యవసాయ కళాశాల ప్రాంగణం లో వ్యవసాయ భూములు ఉన్నాయి.",
              "or": "ଏହା ବହୁତ ବ୍ୟବହାରିକ କାର୍ଯ୍ୟ ହୋଇଥିବାରୁ ମୁଁ ପାଠ୍ୟକ୍ରମକୁ ଉପଭୋଗ କଲି |\n  ଛାତ୍ରମାନେ ଯାହା ଶିଖୁଛନ୍ତି ତାହା ଅଭ୍ୟାସ କରିବା ପାଇଁ କୃଷି କଲେଜରେ କ୍ୟାମ୍ପସ\n  ମଧ୍ୟରେ ଚାଷ ଜମି ଅଛି |",
              "as": "বিষয়টো যথেষ্ট ব্যৱহাৰিক আছিল বাবে মই পাঠ্যক্ৰমটো পঢ়ি আনন্দ উপভোগ কৰিছিলো। কৃষি মহাবিদ্যালয়খনত শিক্ষাৰ্থীসকলে শিকি থকাবোৰ নিজে অনুশীলন কৰিবৰ বাবে চৌহদৰ ভিতৰত খেতিৰ পথাৰ আছিল।",
              "gu": "મેં કોર્સનો આનંદ માણ્યો કારણ કે તે ઘણું વ્યવહારુ કાર્ય છે. વિદ્યાર્થીઓ જે શીખી રહ્યા છે તેનો અભ્યાસ કરી શકે તે માટે કૃષિ કોલેજ પાસે કેમ્પસમાં ખેતીની જમીનો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "One of their professors believes that students should travel to different farmlands to understand soil conservation and water quantity. \nIn one of the field visits, I saw how the farmer had planted trees on the edge of his farm to prevent soil erosion!",
              "hi": "उसके प्रोफेसरों में से एक का मानना ​​है कि छात्रों को मिट्टी संरक्षण और पानी की मात्रा को समझने के लिए विभिन्न खेतों का दौरा करना चाहिए।\nखेत के एक दौरे के समय, मैंने देखा कि किसान ने मिट्टी के कटाव को रोकने के लिए अपने खेत के किनारे पर पेड़ लगाए थे!",
              "ka": "ಮಣ್ಣಿನ ಸಂರಕ್ಷಣೆ ಮತ್ತು ನೀರಿನ ಪ್ರಮಾಣವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ವಿದ್ಯಾರ್ಥಿಗಳು ವಿವಿಧ ಕೃಷಿಭೂಮಿಗಳಿಗೆ ಪ್ರಯಾಣಿಸಬೇಕು ಎಂದು ಅವರ ಪ್ರಾಧ್ಯಾಪಕರೊಬ್ಬರು ನಂಬುತ್ತಾರೆ. ಒಂದು ಜಮೀನಿನ ಭೇಟಿಯಲ್ಲಿ, ಮಣ್ಣಿನ ಸವಕಳಿಯನ್ನು ತಡೆಯಲು ರೈತ ತನ್ನ ಜಮೀನಿನ ಅಂಚಿನಲ್ಲಿ ಹೇಗೆ ಮರಗಳನ್ನು ನೆಟ್ಟಿದ್ದಾನೆಂದು ನಾನು ನೋಡಿದೆ!",
              "te": "మట్టి సంరక్షణ మరియు నీటి పరిమాణాన్ని అర్థం చేసుకోవడానికి విద్యార్థులు వివిధ వ్యవసాయ భూములకు వెళ్లాలని వారి ప్రొఫెసర్లలో ఒకరు నమ్ముతారు.\n ఒక క్షేత్ర సందర్శనలో, నేల కోతను నివారించడానికి రైతు తన పొలం అంచున చెట్లను ఎలా నాటారో నేను చూశాను!",
              "or": "ସେମାନଙ୍କର ଜଣେ ପ୍ରଫେସର ବିଶ୍ୱାସ କରନ୍ତି ଯେ ମୃତ୍ତିକା ସଂରକ୍ଷଣ ଏବଂ ଜଳ\n  ପରିମାଣ ବୁଝିବା ପାଇଁ ଛାତ୍ରମାନେ ବିଭିନ୍ନ ଚାଷ ଜମିକୁ ଯିବା ଉଚିତ୍।",
              "as": "\"তেওঁলোকৰ এজন অধ্যাপকে বিশ্বাস কৰে যে শিক্ষাৰ্থীসকলে মাটি সংৰক্ষণ আৰু পানীৰ পৰিমাণ বুজিবলৈ বিভিন্ন কৃষিভূমিলৈ ভ্ৰমণ কৰা উচিত। \n পথাৰৰ এটা ভ্ৰমণত, মই দেখিছিলো যে কৃষকজনে কেনেকৈ মাটিৰ খহনীয়া প্ৰতিহত কৰিবলৈ তেওঁৰ খেতিপথাৰৰ কাষত গছ ৰোপণ কৰিছিল!\"",
              "gu": "તેમના એક પ્રોફેસર માને છે કે વિદ્યાર્થીઓએ જમીન સંરક્ષણ અને પાણીની માત્રાને સમજવા માટે વિવિધ ખેતરોમાં પ્રવાસ કરવો જોઈએ. એક ક્ષેત્રની મુલાકાતમાં, મેં જોયું કે કેવી રીતે ખેડૂતે જમીનનું ધોવાણ અટકાવવા તેના ખેતરની ધાર પર વૃક્ષો વાવ્યા છે!"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_2_1",
              "hi": "B7_2_1",
              "ka": "B7_2_1",
              "te": "B7_2_1",
              "or": "ଗୋଟିଏ କ୍ଷେତ୍ର ପରିଦର୍ଶନ ସମୟରେ, ମୁଁ ଦେଖିଲି କୃଷକ କିପରି ନିଜ ଚାଷ \n ଜମିରେ ବୃକ୍ଷ ରୋପଣ କରିଛନ୍ତି!",
              "as": "B7_2_1",
              "gu": "B7_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Whatever he learns, he shares with Arun and Babuji. Babuji has also started seeing value in what he is learning at college but is still adamant about Shikhar getting a job.",
              "hi": "जो भी वह सीखता है, वह अरुण और बाबूजी के साथ साझा करता है। कॉलेज में वह जो भी सीख रहा है बाबूजी ने उसके महत्त्व को देखना शुरु कर दिया है लेकिन अभी भी शिखर के नौकरी करने के विषय में अडिग हैं।",
              "ka": "ಏನೇ ಕಲಿತರೂ ಅರುಣ್ ಮತ್ತು ಬಾಬೂಜಿ ಜೊತೆ ಹಂಚಿಕೊಳ್ಳುತ್ತಾನೆ. ಬಾಬೂಜಿ ಆತನು ಕಾಲೇಜಿನಲ್ಲಿ ಕಲಿಯುತ್ತಿರುವುದನ್ನು ಮೌಲ್ಯಯುತವಾಗಿ ಕಾಣಲು ಪ್ರಾರಂಭಿಸಿದ್ದಾರೆ ಆದರೆ ಶಿಖರ್‌ ಕೆಲಸ ಮಾಡುವ ಬಗ್ಗೆ ಅವರ ನಿರ್ಧಾರ ಇನ್ನೂ ಅಚಲವಾಗಿದೆ.",
              "te": "తను ఏది నేర్చుకున్నా అరుణ్, నాన్నగారితో పంచుకుంటాడు. నాన్నగారు కళాశాలలో నేర్చుకుంటున్న దాని విలువను చూడటం ప్రారంభించాడు, కానీ శిఖర్‌ కు ఉద్యోగం రావడంపై ఇంకా మొండిగా ఉన్నాడు.",
              "or": "ସେ ଯାହା ବି ଶିଖନ୍ତି, ସେ ଅରୁଣ ଏବଂ ବାବୁଜୀଙ୍କ ସହ ଆଲୋଚନା କରନ୍ତି | \n ବାବୁଜୀ ମଧ୍ୟ କଲେଜରେ ଯାହା ଶିଖୁଛନ୍ତି ତାହାର ମୂଲ୍ୟ ଦେଖିବା ଆରମ୍ଭ କରିଛନ୍ତି\n  କିନ୍ତୁ ଶିଖରଙ୍କୁ ଚାକିରି କରନ୍ତୁ ବୋଲି ସେ ଜିଦ୍ ଧରିଛନ୍ତି |",
              "as": "তেওঁ যিয়েই শিকে, অৰুণ আৰু দেউতাকৰ লগত আলোচনা কৰে। দেউতাকেও লাহে লাহে সি কলেজত শিকা কথাবোৰত গুৰুত্ব প্ৰদান কৰা হৈছে কিন্তু এতিয়াও শিখৰে এটা চাকৰি পোৱাক লৈ অটল হৈ আছে।",
              "gu": "તે જે પણ શીખે છે તે અરુણ અને બાબુજી સાથે શેર કરે છે. બાબુજી પણ કૉલેજમાં જે શીખે છે તેનું મૂલ્ય જોવાનું શરૂ કરી દીધું છે પણ શિખરને નોકરી મેળવવા માટે હજુ પણ મક્કમ છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_2_2",
              "hi": "B7_2_2",
              "ka": "B7_2_2",
              "te": "B7_2_2",
              "or": "B7_2_2",
              "as": "B7_2_2",
              "gu": "B7_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar’s course is coming to an end. Now he needs to decide what to do.",
              "hi": "शिखर का कोर्स खत्म हो रहा है। अब उसे यह तय करने की जरूरत है कि क्या करना है।",
              "ka": "ಶಿಖರ್ ಅವರ ಕೋರ್ಸ್ ಮುಗಿಯುತ್ತಿದೆ. ಈಗ ಅವನು ಏನು ಮಾಡಬೇಕೆಂದು ನಿರ್ಧರಿಸಬೇಕು.",
              "te": "శిఖర్ కోర్సు ముగింపు దశకు చేరుకుంది. ఇప్పుడు అతను ఏమి చేయాలో నిర్ణయించుకోవాలి.",
              "or": "ଶିଖରଙ୍କ ପାଠ୍ୟକ୍ରମ ଶେଷ ହେବାକୁ ଯାଉଛି। ବର୍ତ୍ତମାନ ସେ କଣ କରିବେ ତାହା\n  ସ୍ଥିର କରିବା ଆବଶ୍ୟକ |",
              "as": "শিখৰৰ পাঠ্যক্ৰমটো সমাপ্ত হ'বৰ হৈছে। এতিয়া তেওঁ কি কৰিব তাৰ সিদ্ধান্ত ল'ব লাগিব।",
              "gu": "શિખરનો અભ્યાસક્રમ સમાપ્ત થઈ રહ્યો છે. હવે તેણે શું કરવું તે નક્કી કરવાની જરૂર છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B7_2_3 D",
              "hi": "B7_2_3 d",
              "ka": "B7_2_3 D",
              "te": "B7_2_3 D",
              "or": "B7_2_3 D",
              "as": "B7_2_3 D",
              "gu": "B7_2_3 D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should he do?",
              "hi": "उसे क्या करना चाहिए?",
              "ka": "ಆತನು ಏನು ಮಾಡಬೇಕು?",
              "te": "అతను ఏమి చేయాలి?",
              "or": "ସେ କ’ଣ କରିବା ଉଚିତ୍?",
              "as": "তেওঁ কি কৰা উচিত?",
              "gu": "તેણે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Convince Babuji about the different ways he can help the farm and the village",
              "hi": "बाबूजी को मनाये  कि  वह खेत और गांव में विभिन्न तरीकों से सहायता करेगा  ",
              "ka": "ಬಾಬೂಜಿ ಅವರು ಕೃಷಿ ಮತ್ತು ಗ್ರಾಮಕ್ಕೆ ವಿವಿಧ ರೀತಿಯಲ್ಲಿ ಸಹಾಯ ಮಾಡುವ ಬಗ್ಗೆ ಮನವರಿಕೆ ಮಾಡಿಕೊಡುವುದು",
              "te": "పొలానికి మరియు గ్రామానికి వివిధ లాభాల గురించి నాన్నగారిని ఒప్పించడం",
              "or": "ଚାଷ ଏବଂ ଗାଁକୁ ସାହାଯ୍ୟ କରିବାର ବିଭିନ୍ନ ଉପାୟ ବିଷୟରେ ବାବୁଜୀଙ୍କୁ ବିଶ୍ୱାସ\n  କରାଅ |",
              "as": "খেতি পথাৰ আৰু গাঁওখনক সহায় কৰিব পৰা বিভিন্ন উপায়ৰ বিষয়ে দেউতাকক সৈমান কৰক।",
              "gu": "બાબુજીને સમજાવો કે તે કઈ રીતે ખેતર અને ગામને મદદ કરી શકે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Look for a job and earn to help his family",
              "hi": "नौकरी की खोज करे और अपने परिवार को सहयोग देने के लिए कमाए",
              "ka": "ತನ್ನ ಕುಟುಂಬಕ್ಕೆ ಸಹಾಯ ಮಾಡಲು ಉದ್ಯೋಗವನ್ನು ಹುಡುಕುವುದು ಮತ್ತು ಸಂಪಾದಿಸಿಕೊಳ್ಳುವುದು",
              "te": "అతని కుటుంబానికి సహాయం చేయడానికి ఉద్యోగం చూసుకొని సంపాదించడం",
              "or": "ଚାକିରି ଖୋଜ ଏବଂ ତାଙ୍କ ପରିବାରକୁ ସାହାଯ୍ୟ କରିବା ପାଇଁ ରୋଜଗାର କର |",
              "as": "চাকৰি এটা বিচাৰক আৰু তেওঁৰ পৰিয়ালক সহায় কৰিবলৈ উপাৰ্জন কৰক",
              "gu": "નોકરી શોધો અને તેના પરિવારને મદદ કરવા કમાઓ"
            }
          }
        ]
      },
      "scene47": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Convince Babuji",
              "hi": "बाबूजी को समझाए ",
              "ka": "ಬಾಬುಜಿಗೆ ಮನವರಿಕೆ ಮಾಡಿಕೊಡುವುದು",
              "te": "నాన్నగారిని ఒప్పించడం",
              "or": "ବାବୁଜୀଙ୍କୁ ବିଶ୍ୱାସ କରାଅ|",
              "as": "দেউতাকক সৈমান কৰক।",
              "gu": "બાબુજીને મનાવો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He decides to go to his father with a proper plan to double their profits in 3 years.",
              "hi": "वह 3 वर्ष में अपने लाभ को दोगुना करने के लिए उचित योजना के साथ अपने पिता के पास जाने का निर्णय करता है।",
              "ka": "3 ವರ್ಷಗಳಲ್ಲಿ ತಮ್ಮ ಲಾಭವನ್ನು ದ್ವಿಗುಣಗೊಳಿಸಲು ಸರಿಯಾದ ಯೋಜನೆಯೊಂದಿಗೆ ಅವನು ತನ್ನ ತಂದೆಯ ಬಳಿಗೆ ಹೋಗಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "3 సంవత్సరాలలో వారి లాభాలను రెట్టింపు చేయడానికి సరైన ప్రణాళికతో అతను తన తండ్రి వద్దకు వెళ్లాలని నిర్ణయించుకున్నాడు.",
              "or": "3 ବର୍ଷରେ ସେମାନଙ୍କର ଲାଭକୁ ଦ୍ୱିଗୁଣିତ କରିବା ପାଇଁ ସେ ଏକ ଉପଯୁକ୍ତ\n  ଯୋଜନା ସହିତ ତାଙ୍କ ପିତାଙ୍କ ନିକଟକୁ ଯିବାକୁ ସ୍ଥିର କରନ୍ତି |",
              "as": "তেওঁ ৩ বছৰত তেওঁলোকৰ লাভ দুগুণ কৰাৰ সঠিক পৰিকল্পনাৰে তেওঁৰ দেউতাকৰ ওচৰলৈ যোৱাৰ সিদ্ধান্ত ল'লে।",
              "gu": "તે 3 વર્ષમાં તેમનો નફો બમણો કરવા માટે યોગ્ય યોજના સાથે તેના પિતા પાસે જવાનું નક્કી કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_1_2",
              "hi": "B8_1_2",
              "ka": "B8_1_2",
              "te": "B8_1_2",
              "or": "B8_1_2",
              "as": "B8_1_2",
              "gu": "B8_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He collects all the pictures and information he has saved for the last four years to explain his point.",
              "hi": "अपनी बात को समझाने के लिए वह पिछले चार वर्षों से बचाए गए सभी चित्रों और जानकारी को एकत्र करता है।",
              "ka": "ಅವರು ತಮ್ಮ ವಿಷಯವನ್ನು ವಿವರಿಸಲು ಕಳೆದ ನಾಲ್ಕು ವರ್ಷಗಳಿಂದ ಉಳಿಸಿದ ಎಲ್ಲಾ ಚಿತ್ರಗಳು ಮತ್ತು ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸುತ್ತಾರೆ.",
              "te": "అతను తన అభిప్రాయాన్ని వివరించడానికి గత నాలుగు సంవత్సరాలుగా అతను సేవ్ చేసిన చిత్రాలను మరియు సమాచారాన్ని సేకరిస్తాడు.",
              "or": "ସେ ନିଜର ମତ ବୁଝାଇବା ପାଇଁ ଗତ ଚାରି ବର୍ଷ ମଧ୍ୟରେ ସେ ସଂରକ୍ଷଣ କରିଥିବା\n  ସମସ୍ତ ଚିତ୍ର ଏବଂ ସୂଚନା ସଂଗ୍ରହ କରନ୍ତି |",
              "as": "তেওঁ নিজৰ কথাটো বুজাবলৈ যোৱা চাৰি বছৰ ধৰি সংৰক্ষণ কৰা সকলো ছবি আৰু তথ্য একত্ৰিত কৰিলে।",
              "gu": "પોતાની વાત સમજાવવા માટે તેણે છેલ્લા ચાર વર્ષથી સાચવેલી તમામ તસવીરો અને માહિતી એકઠી કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_1_3 bg",
              "hi": "B8_1_3 बीजी",
              "ka": "B8_1_3 bg",
              "te": "B8_1_3 bg",
              "or": "B8_1_3 bg",
              "as": "B8_1_3 bg",
              "gu": "B8_1_3 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Babuji",
              "hi": "बाबूजी",
              "ka": "ಬಾಬುಜಿ",
              "te": "నాన్నగారు",
              "or": "ବାବୁଜୀ",
              "as": "দেউতা",
              "gu": "બાબુજી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "So you are saying we will increase our yield by 40% if we plant trees around the edge of the farm?",
              "hi": "तो तुम कह रहे हो कि अगर हम खेत के किनारे चारों ओर पेड़ लगाते हैं तो हम अपनी उपज में 40% तक वृद्धि करेंगे?",
              "ka": "ಹಾಗಾದರೆ ಹೊಲದ ಅಂಚಿನಲ್ಲಿ ಗಿಡಗಳನ್ನು ನೆಟ್ಟರೆ ಶೇ.40ರಷ್ಟು ಇಳುವರಿ ಹೆಚ್ಚುತ್ತದೆ ಎನ್ನುತ್ತಿದ್ದೀರಾ?",
              "te": "అంటే పొలం చుట్టూ చెట్లను నాటితే 40% దిగుబడి పెరుగుతుందని నువ్వు అంటున్నావా?",
              "or": "ତେବେ ତୁମେ କହୁଛ ଯଦି ଚାଷଜମିର ଧାରରେ ଗଛ ଲଗାଇଲେ ଆମେ ଆମର\n  ଅମଳ ୪% ବୃଦ୍ଧି କରିବୁ?",
              "as": "সেয়ে তুমি কৈছা যে যদি আমি খেতিপথাৰৰ কাষত গছ ৰোপণ কৰোঁ তেন্তে আমি আমাৰ উৎপাদন 40% বৃদ্ধি কৰিম?",
              "gu": "તો તું જણાવે છે કે જો આપણે ખેતરની આજુબાજુ વૃક્ષો વાવીશું તો આપણી ઉપજમાં 40% વધારો થશે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Yes, because we lose 15% of our land harvest because it is not protected.",
              "hi": "हां, क्योंकि हम संरक्षित न होने के कारण अपनी फसल का 15% खो देते हैं।",
              "ka": "ಹೌದು, ಏಕೆಂದರೆ ನಾವು ನಮ್ಮ ಭೂಮಿಯನ್ನು ರಕ್ಷಿಸದ ಕಾರಣ ಕೊಯ್ಲಿನ 15% ನಷ್ಟು ಭಾಗವನ್ನು  ಕಳೆದುಕೊಳ್ಳುತ್ತೇವೆ",
              "te": "అవును, ఎందుకంటే మన భూమిని సరిగా చూసుకోకపోవడం వల్ల పంటలో 15% కోల్పోతాము.",
              "or": "ହଁ, କାରଣ ଆମେ ଆମର ଜମି ଅମଳର ୧୫% ହରାଉଛୁ କାରଣ ଏହା ସୁରକ୍ଷିତ ନୁହେଁ |",
              "as": "হয়, কাৰণ এইবোৰক সুৰক্ষা দিয়া নহয় কাৰণে আমি আমাৰ মাটিৰ শস্যৰ 15% হেৰুৱাওঁ।",
              "gu": "હા, કારણ કે આપણે આપણી જમીનનો 15% પાક ગુમાવીએ છીએ કારણ કે તે સુરક્ષિત નથી."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_1_4///",
              "hi": "B8_1_4 /////",
              "ka": "B8_1_4///",
              "te": "B8_1_4///",
              "or": "B8_1_4///",
              "as": "B8_1_4///",
              "gu": "B8_1_4///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "The trees also help prevent soil displacement. We can also use the leaves as manure to save more costs.",
              "hi": "पेड़ मिट्टी को खिसकने से रोकने में भी सहायक होते हैं। हम अधिक लागत बचाने के लिए खाद के रूप में पत्तियों का भी उपयोग कर सकते हैं।",
              "ka": "ಮರಗಳು ಮಣ್ಣಿನ ಸ್ಥಳಾಂತರವನ್ನು ತಡೆಯಲು ಸಹ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಹೆಚ್ಚಿನ ವೆಚ್ಚವನ್ನು ಉಳಿಸಲು ನಾವು ಎಲೆಗಳನ್ನು ಗೊಬ್ಬರವಾಗಿ ಬಳಸಬಹುದು.",
              "te": "చెట్లు మట్టి వేరే చోటుకి వెళ్లకుండా ఆపడానికి కూడా సహాయపడతాయి. ఆకులను మనం ఎరువుగా కూడా ఉపయోగించుకుని ఎక్కువ ఖర్చులు ఆదా చేసుకోవచ్చు.",
              "or": "ଗଛଗୁଡିକ ମୃତ୍ତିକା ବିସ୍ଥାପନକୁ ରୋକିବାରେ ମଧ୍ୟ ସାହାଯ୍ୟ କରେ | ଅଧିକ ଖର୍ଚ୍ଚ \n ରୋକିବା ପାଇଁ ଆମେ ପତ୍ରକୁ ଖତ ଭାବରେ ବ୍ୟବହାର କରିପାରିବା |",
              "as": "গছবোৰে মাটিৰ স্থানচ্যুতি ৰোধ কৰিবৰ বাবেও সহায় কৰে। আমি আৰু খৰচ কমাবৰ বাবে পাতবোৰকো সাৰ হিচাপে ব্যৱহাৰ কৰিব পাৰোঁ।",
              "gu": "વૃક્ષો જમીનના વિસ્થાપનને રોકવામાં પણ મદદ કરે છે. વધુ ખર્ચ બચાવવા માટે આપણે ખાતર તરીકે પણ પાંદડાનો ઉપયોગ કરી શકીએ છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Hmm...",
              "hi": "हम्म...",
              "ka": "ಹ್ಮ್ಮ...",
              "te": "మ్...",
              "or": "ହଁ. . ।",
              "as": "উম…",
              "gu": "બરાબર..."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "With that money, we can then start a small section of organic vegetables. \nYou know Babuji, good organic vegetables sell three times more than regular vegetables!",
              "hi": "उस पैसे से, हम  एक छोटे से भाग में ऑर्गेनिक सब्जियां उगाना  शुरु कर सकते हैं।\n बाबूजी आप जानते हैं, अच्छी ऑर्गेनिक सब्जियां नियमित सब्जियों की तुलना में तीन गुना अधिक मूल्य पर  बिकती हैं!",
              "ka": "ಆ ಹಣದಿಂದ ನಾವು ಸಾವಯವ ತರಕಾರಿಗಳ ಸಣ್ಣ ವಿಭಾಗವನ್ನು ಪ್ರಾರಂಭಿಸಬಹುದು. ನಿಮಗೆ ಗೊತ್ತಾ ಬಾಬೂಜಿ, ಒಳ್ಳೆಯ ಸಾವಯವ ತರಕಾರಿಗಳು ಸಾಮಾನ್ಯ ತರಕಾರಿಗಳಿಗಿಂತ ಮೂರು ಪಟ್ಟು ಹೆಚ್ಚು ಮಾರಾಟವಾಗುತ್ತವೆ!",
              "te": "ఆ డబ్బుతో, మనము చిన్న సేంద్రీయ కూరగాయల విభాగాన్ని ప్రారంభించవచ్చు.\n మీకు తెలుసా నాన్నగారు, మంచి సేంద్రీయ కూరగాయలు సాధారణ కూరగాయల కంటే మూడు రెట్లు ఎక్కువగా అమ్ముడవుతాయి!",
              "or": "ସେହି ଟଙ୍କା ସହିତ ଆମେ କାରବନିକ ପନିପରିବାର ଏକ ଛୋଟ ବିଭାଗ ଆରମ୍ଭ କରିପାରିବା |\n  ବାବୁଜୀ ଆପଣ ଜାଣିଛନ୍ତି, ଭଲ କାର୍ବନିକ ପନିପରିବା ନିୟମିତ ପନିପରିବା ଅପେକ୍ଷା ତିନି ଗୁଣ ଅଧିକ ବିକ୍ରି ହୁଏ!",
              "as": "সেই টকাৰে, তাৰ পাছত আমি জৈৱিক পাচলিৰ এটা সৰু শাখাও আৰম্ভ কৰিব পাৰোঁ।\n আপুনি জানেনে দেউতা, ভাল জৈৱিক পাচলি নিয়মীয়া পাচলিতকৈ তিনিগুণ বেছি বিক্ৰী হয়।",
              "gu": "તે પૈસાથી, આપણે પછી ઓર્ગેનિક શાકભાજીનો એક નાનો વિભાગ શરૂ કરી શકીએ છીએ. તમે જાણો છો બાબુજી, સારા ઓર્ગેનિક શાકભાજી રેગ્યુલર શાકભાજી કરતાં ત્રણ ગણા વધુ વેચાય છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Babuji, let us start with Shikhar’s techniques? This might be an opportunity to improve our lives.",
              "hi": "बाबूजी, आइए शिखर की तकनीकों से शुरु करें? यह हमारे जीवन को बेहतर बनाने का अवसर हो सकता है।",
              "ka": "ಬಾಬೂಜಿ, ಶಿಖರ್ ಹೇಳಿದ ತಂತ್ರಗಳೊಂದಿಗೆ ಪ್ರಾರಂಭಿಸೋಣ? ಇದು ನಮ್ಮ ಜೀವನವನ್ನು ಸುಧಾರಿಸಲು ಒಂದು ಅವಕಾಶವಾಗಿರಬಹುದು",
              "te": "నాన్నగారు, శిఖర్ పద్ధతులతో ప్రారంభిద్దాం? ఇది మన జీవితాలను మెరుగుపరచుకోవడానికి ఒక అవకాశం కావచ్చు.",
              "or": "ବାବୁଜୀ, ଚାଲ ଶିଖରଙ୍କ କୌଶଳରୁ ଆରମ୍ଭ କରିବା? ଏହା ଆମ ଜୀବନର ଉନ୍ନତି \n ପାଇଁ ଏକ ସୁଯୋଗ ହୋଇପାରେ |",
              "as": "দেউতা, আমি শিখৰৰ কৌশলবোৰেৰে আৰম্ভ কৰোঁ আহক? এইটো আমাৰ জীৱন উন্নত কৰাৰ এটা সুযোগ হ'ব পাৰে।",
              "gu": "બાબુજી, ચાલો શિખરની ટેકનિકથી શરૂઆત કરીએ? આ આપણા જીવનને સુધારવાની તક હોઈ શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Yes, Arun. This is worth trying.",
              "hi": "हाँ, अरुण। यह कोशिश करने लायक है।",
              "ka": "ಹೌದು, ಅರುಣ್. ಇದು ಪ್ರಯತ್ನಿಸಲು ಯೋಗ್ಯವಾಗಿದೆ.",
              "te": "అవును అరుణ్. ఇది ప్రయత్నించడం విలువైనది.",
              "or": "ହଁ, ଅରୁଣ | ଏହା ଚେଷ୍ଟା କରିବା ଯୋଗ୍ୟ |",
              "as": "হয়, অৰুণ। এইটো চেষ্টা কৰাৰ যোগ্য।",
              "gu": "હા, અરુણ. આ પ્રયાસ કરવા યોગ્ય છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_2_1",
              "hi": "B8_2_1",
              "ka": "B8_2_1",
              "te": "B8_2_1",
              "or": "B8_2_1",
              "as": "B8_2_1",
              "gu": "B8_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They all work as per Shikhar’s suggestions. And in a few years, they start seeing an increase in produce. Use of natural pesticides changes their soil quality.",
              "hi": "वे सभी शिखर के सुझावों के अनुसार काम करते हैं। और कुछ ही वर्षों में, वे उत्पादन में वृद्धि को देखना शुरु कर देते हैं। प्राकृतिक कीटनाशकों का उपयोग अपनी मिट्टी की गुणवत्ता को बदलता है।",
              "ka": "ಶಿಖರ್ ನೀಡಿದ ಸಲಹೆಯಂತೆ ಅವರೆಲ್ಲರೂ ಕೆಲಸ ಮಾಡುತ್ತಾರೆ. ಮತ್ತು ಕೆಲವು ವರ್ಷಗಳಲ್ಲಿ, ಅವರು ಉತ್ಪನ್ನಗಳ ಹೆಚ್ಚಳವನ್ನು ನೋಡುತ್ತಾರೆ. ನೈಸರ್ಗಿಕ ಕೀಟನಾಶಕಗಳ ಬಳಕೆಯು ಮಣ್ಣಿನ ಗುಣಮಟ್ಟವನ್ನು ಬದಲಾಯಿಸುತ್ತದೆ.",
              "te": "వీరంతా శిఖర్ సూచనల మేరకు పనిచేస్తారు. మరియు కొన్ని సంవత్సరాలలో, వారు ఉత్పత్తిలో పెరుగుదలను చూడటం ప్రారంభిస్తారు. సహజ పురుగుమందుల వాడకం నేల నాణ్యతను మారుస్తుంది.",
              "or": "ସମସ୍ତେ ଶିଖରଙ୍କ ପରାମର୍ଶ ଅନୁଯାୟୀ କାର୍ଯ୍ୟ କରନ୍ତି | ଏବଂ କିଛି ବର୍ଷ ମଧ୍ୟରେ,\n  ସେମାନେ ଉତ୍ପାଦନରେ ବୃଦ୍ଧି ଦେଖିବା ଆରମ୍ଭ କରନ୍ତି | ପ୍ରାକୃତିକ କୀଟନାଶକ\n  ବ୍ୟବହାର ସେମାନଙ୍କ ମାଟିର ଗୁଣକୁ ପରିବର୍ତ୍ତନ କରିଥାଏ |",
              "as": "তেওঁলোক সকলোৱে শিখৰৰ পৰামৰ্শ অনুসৰি কাম কৰে। আৰু কেইবছৰমানৰ ভিতৰতে, তেওঁলোকে উৎপাদন বৃদ্ধি হোৱা দেখা পালে। প্ৰাকৃতিক কীটনাশকৰ ব্যৱহাৰেৰে তেওঁলোকৰ মাটিৰ মানদণ্ডও উন্নত কৰিলে।",
              "gu": "તેઓ બધા શિખરના સૂચન મુજબ કામ કરે છે. અને થોડા વર્ષોમાં, તેઓ ઉત્પાદનમાં વધારો જોવાનું શરૂ કરે છે. કુદરતી જંતુનાશકોનો ઉપયોગ તેમની જમીનની ગુણવત્તામાં ફેરફાર કરે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_2_2",
              "hi": "B8_2_2",
              "ka": "B8_2_2",
              "te": "B8_2_2",
              "or": "B8_2_2",
              "as": "B8_2_2",
              "gu": "B8_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Seeing them, the farmers in the neighbourhood also start taking Shikhar’s suggestions.",
              "hi": "उन्हें देखकर, पड़ोस के किसान भी शिखर से सुझाव लेने लगते हैं।",
              "ka": "ಅವರನ್ನು ನೋಡಿ ನೆರೆಹೊರೆಯ ರೈತರೂ ಶಿಖರನ ಸಲಹೆಗಳನ್ನು ಸ್ವೀಕರಿಸಲು ಆರಂಭಿಸುತ್ತಾರೆ.",
              "te": "వారిని చూసి ఇరుగుపొరుగు రైతులు కూడా శిఖర్ సలహాలు తీసుకోవడం మొదలు పెట్టారు.",
              "or": "ସେମାନଙ୍କୁ ଦେଖି ପଡୋଶୀ କୃଷକମାନେ ମଧ୍ୟ ଶିଖରଙ୍କ ପରାମର୍ଶ ନେବା \n ଆରମ୍ଭ କରନ୍ତି |",
              "as": "তেওঁলোকক দেখি, চুবুৰীৰ কৃষকসকলেও শিখৰৰ পৰামৰ্শ লোৱা আৰম্ভ কৰিলে।",
              "gu": "તેમને જોઈને આસપાસના ખેડૂતો પણ શિખરના સૂચનો લેવા માંડે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B8_2_3",
              "hi": "B8_2_3",
              "ka": "B8_2_3",
              "te": "B8_2_3",
              "or": "B8_2_3",
              "as": "B8_2_3",
              "gu": "B8_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar creates a small WhatsApp support group where he explains his processes in small video clips.",
              "hi": "शिखर व्हाट्सएप पर एक छोटा समर्थन ग्रुप बनाता है जिसमें वह छोटी वीडियो क्लिप के द्वारा अपनी प्रक्रियाओं को समझाता है।",
              "ka": "ಶಿಖರ್ ಒಂದು ಸಣ್ಣ WhatsApp ಬೆಂಬಲ ಗುಂಪನ್ನು ರಚಿಸುತ್ತಾನೆ, ಅಲ್ಲಿ ಆತನು ತಮ್ಮ ಪ್ರಕ್ರಿಯೆಗಳನ್ನು ಸಣ್ಣ ವೀಡಿಯೊ ಕ್ಲಿಪ್‌ಗಳ ಮೂಲಕ ವಿವರಿಸುತ್ತಾನೆ.",
              "te": "శిఖర్ చిన్న వాట్సాప్ సపోర్ట్ గ్రూప్‌ని క్రియేట్ చేస్తాడు, అక్కడ అతను తన ప్రక్రియలను చిన్న వీడియో క్లిప్‌లలో వివరిస్తాడు.",
              "or": "ଶିଖର ଏକ ଛୋଟ ହ୍ଵାଟ୍ସଆପ୍ ସପୋର୍ଟ ଗ୍ରୁପ୍ ତିଆରି କରେ ଯେଉଁଠାରେ ସେ ଛୋଟ ଭିଡିଓ\n  କ୍ଲିପଗୁଡିକରେ ତାଙ୍କର ପ୍ରକ୍ରିୟାଗୁଡ଼ିକୁ ବ୍ୟାଖ୍ୟା କରନ୍ତି |",
              "as": "শিখৰে এটা সৰু WhatsApp চাপ'ৰ্ট গ্ৰুপ খুলিলে য'ত তেওঁ সৰু ভিডিঅ' ক্লিপত নিজৰ প্ৰক্ৰিয়াবোৰ ব্যাখ্যা কৰে।",
              "gu": "શિખર એક નાનું વોટ્સએપ મદદગાર ગ્રુપ બનાવે છે જ્યાં તે નાના વિડીયો ક્લિપ્સમાં તેની પ્રક્રિયાઓ સમજાવે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "Farming",
              "hi": "खेती",
              "ka": "ಬೇಸಾಯ",
              "te": "వ్యవసాయం చేయడం",
              "or": "ଚାଷ",
              "as": "খেতি",
              "gu": "ખેતી"
            }
          }
        ]
      },
      "scene48": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Look for a job",
              "hi": "नौकरी की खोज",
              "ka": "ಕೆಲಸವನ್ನು ನೋಡಿಕೊಳ್ಳುವುದು",
              "te": "ఉద్యోగం కోసం చూడడం",
              "or": "ଚାକିରି ଖୋଜ |",
              "as": "চাকৰি এটা বিচৰা",
              "gu": "નોકરી માટે જુઓ"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar decides to look for a job to sustain himself and do as per his father’s expectations.",
              "hi": "शिखर अपनी आजीविका के लिए नौकरी की तलाश करने का निर्णय लेता है और अपने पिता की अपेक्षाओं के अनुसार काम करता है।",
              "ka": "ಶಿಖರ್ ತನ್ನನ್ನು ಉಳಿಸಿಕೊಳ್ಳಲು ಮತ್ತು ತನ್ನ ತಂದೆಯ ನಿರೀಕ್ಷೆಗೆ ತಕ್ಕಂತೆ ಕೆಲಸ ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "శిఖర్ తనను తాను నిలబెట్టుకోవడానికి ఉద్యోగం కోసం వెతకాలని నిర్ణయించుకున్నాడు మరియు తన తండ్రి ఆశించిన విధంగా చేయాలని నిర్ణయించుకున్నాడు.",
              "or": "ନିଜକୁ ବଞ୍ଚାଇବା ପାଇଁ ଏବଂ ପିତାଙ୍କ ଆଶା ଅନୁଯାୟୀ କାର୍ଯ୍ୟ କରିବାକୁ ଶିଖର\n  ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "শিখৰে নিজকে বৰ্তাই ৰাখিবলৈ আৰু দেউতাকৰ ইচ্ছামতে কৰিবৰ বাবে এটা চাকৰি বিচৰাৰ সিদ্ধান্ত ল'লে।",
              "gu": "શિખર પોતાની જાતને ટકાવી રાખવા અને તેના પિતાની અપેક્ષા મુજબ નોકરી શોધવાનું નક્કી કર્યું."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B9_1_2",
              "hi": "B9_1_2",
              "ka": "B9_1_2",
              "te": "B9_1_2",
              "or": "B9_1_2",
              "as": "B9_1_2",
              "gu": "B9_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He applies to various organisations while completing his final semester and eagerly waits to get a response. But many weeks pass, and he doesn’t receive any reply.",
              "hi": "वह अपने अंतिम सेमेस्टर को पूरा करते हुए विभिन्न संगठनों में आवेदन करता है और प्रतिक्रिया पाने के लिए उत्सुकता से प्रतीक्षा करता है। लेकिन कई सप्ताह बीतते हैं, और उन्हें कोई उत्तर नहीं मिलता है।",
              "ka": "ಆತನು ತನ್ನ ಅಂತಿಮ ಸೆಮಿಸ್ಟರ್ ಅನ್ನು ಪೂರ್ಣಗೊಳಿಸುವಾಗ ವಿವಿಧ ಸಂಸ್ಥೆಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಾನೆ ಮತ್ತು ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಪಡೆಯಲು ಕುತೂಹಲದಿಂದ ಕಾಯುತ್ತಾನೆ. ಆದರೆ ಹಲವು ವಾರಗಳು ಕಳೆದರೂ ಆತನು ಯಾವುದೇ ಉತ್ತರವನ್ನು ಸ್ವೀಕರಿಸುವುದಿಲ್ಲ.",
              "te": "అతను తన చివరి సెమిస్టర్‌ ని పూర్తి చేస్తున్నప్పుడు వివిధ సంస్థలకు దరఖాస్తు చేస్తాడు మరియు రిప్లై కోసం ఆసక్తిగా ఎదురుచూస్తున్నాడు. కానీ చాలా వారాలు గడిచిపోయాయి, మరియు అతనికి ఎటువంటి సమాధానం రాలేదు.",
              "or": "ସେ ତାଙ୍କର ଅନ୍ତିମ ସେମିଷ୍ଟାର ସମାପ୍ତ କରିବା ସମୟରେ ବିଭିନ୍ନ ସଂଗଠନ\n  ପାଇଁ ଆବେଦନ କରନ୍ତି ଏବଂ ପ୍ରତିକ୍ରିୟା ପାଇବାକୁ ଉତ୍ସାହର ସହିତ ଅପେକ୍ଷା\n  କରନ୍ତି | କିନ୍ତୁ ଅନେକ ସପ୍ତାହ ବିତିଯାଏ, ଏବଂ ସେ କୌଣସି ଉତ୍ତର \n ପାଆନ୍ତି ନାହିଁ |",
              "as": "তেওঁ অন্তিমটো ছেমিষ্টাৰ সম্পূৰ্ণ কৰাৰ সময়তে বিভিন্ন প্ৰতিষ্ঠানত আবেদন কৰিছিল আৰু উত্তৰৰ বাবে অধীৰ আগ্ৰহেৰে বাট চাইছিল। কিন্তু, কেইবা সপ্তাহৰ পাছতো তেওঁ কোনো উত্তৰ পোৱা নাছিল।",
              "gu": "તે પોતાનું અંતિમ સત્ર પૂરું કરતી વખતે વિવિધ સંસ્થાઓને અરજી કરે છે અને પ્રતિભાવ મેળવવા આતુરતાપૂર્વક રાહ જુએ છે. પરંતુ ઘણા અઠવાડિયા પસાર થાય છે, અને તેને કોઈ જવાબ મળતો નથી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B9_2_1",
              "hi": "B9_2_1",
              "ka": "B9_2_1",
              "te": "B9_2_1",
              "or": "B9_2_1",
              "as": "B9_2_1",
              "gu": "B9_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "During one of his field visits from college, Shikhar gets involved with researching the impact that different crops have on the quality of the soil.",
              "hi": "कॉलेज की ओर से खेत के एक दौरे में, शिखर विभिन्न फसलों द्वारा मिट्टी की गुणवत्ता पर प्रभाव की शोध करने में शामिल हो जाता है।",
              "ka": "ಕಾಲೇಜಿನಿಂದ ಕೈಗೊಂಡ ತನ್ನ ಕ್ಷೇತ್ರ ಭೇಟಿಯೊಂದರಲ್ಲಿ, ಶಿಖರ್ ವಿವಿಧ ಬೆಳೆಗಳು, ಮಣ್ಣಿನ ಗುಣಮಟ್ಟದ ಮೇಲೆ ಬೀರುವ ಪ್ರಭಾವದ ಸಂಶೋಧನೆಯಲ್ಲಿ ತೊಡಗಿಸಿಕೊಂಡಿದ್ದಾನೆ.",
              "te": "కళాశాలలో తన ఫీల్డ్ సందర్శనలో, శిఖర్ వివిధ పంటలు నేల నాణ్యతపై చూపే ప్రభావాన్ని పరిశోధించడంలో పాలుపంచుకుంటాడు.",
              "or": "କଲେଜରୁ ତାଙ୍କର ଏକ କ୍ଷେତ୍ର ପରିଦର୍ଶନ ସମୟରେ, ଶିଖର ବିଭିନ୍ନ ଫସଲର\n  ମାଟିର ଗୁଣ ଉପରେ ଏହାର ପ୍ରଭାବ ଅନୁସନ୍ଧାନ ସହିତ ଜଡିତ ରହେ।",
              "as": "মহাবিদ্যালয়ৰ পৰা যোৱা এটা ক্ষেত্ৰ ভ্ৰমণৰ সময়ত, শিখৰে মাটিৰ মানদণ্ডৰ ওপৰত বিভিন্ন শস্যৰ প্ৰভাৱৰ বিষয়ে কৰা গৱেষণাৰ সৈতে জড়িত আছিল।",
              "gu": "કોલેજમાંથી તેની એક ફિલ્ડ વિઝિટ દરમિયાન, શિખર જમીનની ગુણવત્તા પર વિવિધ પાકોની અસરના સંશોધનમાં સામેલ થાય છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B9_2_2",
              "hi": "B9_2_2",
              "ka": "B9_2_2",
              "te": "B9_2_2",
              "or": "B9_2_2",
              "as": "B9_2_2",
              "gu": "B9_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He decides to do an in-depth study of the quality of the soil in his village.",
              "hi": "वह अपने गांव में मिट्टी की गुणवत्ता का गहराई से अध्ययन करने का निश्चय करता है।",
              "ka": "ಅವನು ತನ್ನ ಹಳ್ಳಿಯಲ್ಲಿನ ಮಣ್ಣಿನ ಗುಣಮಟ್ಟದ ಬಗ್ಗೆ ಆಳವಾದ ಅಧ್ಯಯನವನ್ನು ಮಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ",
              "te": "అతను తన గ్రామంలోని నేల నాణ్యతపై లోతైన అధ్యయనం చేయాలని నిర్ణయించుకున్నాడు.",
              "or": "ସେ ନିଜ ଗାଁରେ ମାଟିର ଗୁଣ ବିଷୟରେ ଏକ ଗଭୀର ଅଧ୍ୟୟନ କରିବାକୁ ନିଷ୍ପତ୍ତି\n  ନେଇଛନ୍ତି |",
              "as": "তেওঁ নিজৰ গাৱৰ মাটিৰ মানদণ্ডৰ বিষয়ে গভীৰভাৱে অধ্যয়ন কৰাৰ সিদ্ধান্ত ল'লে।",
              "gu": "તે પોતાના ગામની જમીનની ગુણવત્તાનો ઊંડો અભ્યાસ કરવાનું નક્કી કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B9_3_1",
              "hi": "B9_3_1",
              "ka": "B9_3_1",
              "te": "B9_3_1",
              "or": "B9_3_1",
              "as": "B9_3_1",
              "gu": "B9_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He applies as a Research Assistant in a smart-farming company, Anaaj. Seeing the work he has already been doing, they are keen to add him to their team of researchers.",
              "hi": "वह एक स्मार्ट-फार्मिंग कंपनी, अनाज में एक शोध सहायक के रूप में आवेदन करता है। जो वह कर रहा है, उस काम को देखते हुए वे उसे शोधकर्ताओं की टीम में नियुक्त करने के इच्छुक हैं।",
              "ka": "ಆತನು ಅನಾಜ್ ಎಂಬ ಸ್ಮಾರ್ಟ್ ಫಾರ್ಮಿಂಗ್ ಕಂಪನಿಯಲ್ಲಿ ಸಂಶೋಧನಾ ಸಹಾಯಕ ಹುದ್ದೆಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಾನೆ. ಆತನು ಈಗಾಗಲೇ ಮಾಡುತ್ತಿರುವ ಕೆಲಸವನ್ನು ನೋಡಿ, ಅವರು ತಮ್ಮ ಸಂಶೋಧಕರ ತಂಡಕ್ಕೆ ಸೇರಿಸಲು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ.",
              "te": "అతను అనాజ్ అనే స్మార్ట్-ఫార్మింగ్ కంపెనీలో పరిశోధన సహాయకుడు దరఖాస్తు చేసుకున్నాడు. అతను ఇప్పటికే చేస్తున్న పనిని చూసి, వారు అతనిని తమ పరిశోధన బృందంలో చేర్చుకోవడానికి ఆసక్తి చూపుతున్నారు.",
              "or": "ସେ ଏକ ସ୍ମାର୍ଟ-ଫାର୍ମିଂ କମ୍ପାନୀ ଆନାଜରେ ରିସର୍ଚ୍ଚ ଆସିଷ୍ଟାଣ୍ଟ ଭାବରେ ଆବେଦନ\n  କରନ୍ତି | ସେ ପୂର୍ବରୁ କରିଥିବା କାର୍ଯ୍ୟ ଦେଖି ସେମାନେ ତାଙ୍କୁ ଅନୁସନ୍ଧାନକାରୀ\n  ଦଳରେ ଯୋଡିବାକୁ ଆଗ୍ରହୀ ଅଟନ୍ତି |",
              "as": "তেওঁ অনাজ নামৰ স্মাৰ্ট-ফাৰ্মিং কোম্পানীএটাত গৱেষণা সহায়ক হিচাপে আবেদন কৰে। তেওঁ ইতিমধ্যে কৰি থকা কামবোৰ দেখি, তেওঁলোকে তেওঁক তেওঁলোকৰ গৱেষকৰ দলত অন্তৰ্ভূক্ত কৰিবলৈ আগ্ৰহ প্ৰকাশ কৰে।",
              "gu": "તે સ્માર્ટ-ફાર્મિંગ કંપની અનાજમાં સંશોધન સહાયક તરીકે અરજી કરે છે. તે જે કામ કરી રહ્યો છે તે જોઈને, તેઓ તેને તેમની સંશોધકોની ટીમમાં ઉમેરવા આતુર છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B9_3_2",
              "hi": "B9_3_2",
              "ka": "B9_3_2",
              "te": "B9_3_2",
              "or": "B9_3_2",
              "as": "B9_3_2",
              "gu": "B9_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "At Anaaj, they use data to predict ideal growth conditions for the crop and the various requirements for a good yield.",
              "hi": "अनाज में, वे फसल के लिए आदर्श विकास की स्थिति और अच्छी उपज के लिए विभिन्न आवश्यकताओं की पूर्व सूचना देने के लिए डेटा का उपयोग करते हैं।",
              "ka": "ಅನಾಜ್‌ನಲ್ಲಿ, ಆತನು ಬೆಳೆಗೆ ಸೂಕ್ತವಾದ ಬೆಳವಣಿಗೆಯ ಪರಿಸ್ಥಿತಿಗಳನ್ನು ಮತ್ತು ಉತ್ತಮ ಇಳುವರಿಗಾಗಿ ವಿವಿಧ ಅವಶ್ಯಕತೆಗಳನ್ನು ಊಹಿಸಲು ಡೇಟಾವನ್ನು ಬಳಸುತ್ತಾನೆ.",
              "te": "అనాజ్ వద్ద, వారు పంటకు అనువైన ఎదుగుదల పరిస్థితులను మరియు మంచి దిగుబడి కోసం వివిధ అవసరాలను అంచనా వేయడానికి డేటాను ఉపయోగిస్తారు.",
              "or": "ଅନାଜରେ, ସେମାନେ ଫସଲ ପାଇଁ ଆଦର୍ଶ ଅଭିବୃଦ୍ଧି ଅବସ୍ଥା ଏବଂ ଭଲ ଅମଳ\n  ପାଇଁ ବିଭିନ୍ନ ଆବଶ୍ୟକତାର ପୂର୍ବାନୁମାନ କରିବାକୁ ତଥ୍ୟ ବ୍ୟବହାର କରନ୍ତି |",
              "as": "অনাজত, তেওঁলোকে শস্যৰ বাবে আনুমানিক বিকাশৰ স্থিতি আৰু ভাল উৎপাদনৰ বাবে বিভিন্ন প্ৰয়োজনীয়তাৰ পূৰ্বানুমান কৰিবলৈ তথ্যৰ ব্যৱহাৰ কৰে।",
              "gu": "અનાજ ખાતે, તેઓ પાક માટે આદર્શ વૃદ્ધિની સ્થિતિ અને સારી ઉપજ માટે વિવિધ જરૂરિયાતોની આગાહી કરવા માટે ડેટાનો ઉપયોગ કરે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B9_3_3",
              "hi": "B9_3_3",
              "ka": "B9_3_3",
              "te": "B9_3_3",
              "or": "B9_3_3",
              "as": "B9_3_3",
              "gu": "B9_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They notify farmers based on the data they receive from their research to avoid crop damage.",
              "hi": "वे फसल क्षति से बचने के लिए अपने शोध से प्राप्त डेटा के आधार पर किसानों को सूचित करते हैं।",
              "ka": "ಬೆಳೆ ಹಾನಿಯನ್ನು ತಪ್ಪಿಸಲು ಆತನು ತನ್ನ ಸಂಶೋಧನೆಯಿಂದ ಪಡೆದ ಡೇಟಾವನ್ನು ಆಧರಿಸಿ ರೈತರಿಗೆ ಸೂಚನೆ ನೀಡುತ್ತಾನೆ.",
              "te": "పంట నష్టాన్ని నివారించడానికి వారు తమ పరిశోధన నుండి అందుకున్న డేటా ఆధారంగా రైతులకు తెలియజేస్తారు.",
              "or": "ଫସଲ ନଷ୍ଟ ନହେବା ପାଇଁ ସେମାନେ ସେମାନଙ୍କର ଅନୁସନ୍ଧାନରୁ ପ୍ରାପ୍ତ ତଥ୍ୟ \n ଉପରେ ଆଧାର କରି କୃଷକମାନଙ୍କୁ ସୂଚିତ କରନ୍ତି |",
              "as": "তেওঁলোকে শস্যৰ ক্ষতি নহ'বলৈ তেওঁলোকৰ গৱেষণাৰ পৰা প্ৰাপ্ত তথ্যৰ ওপৰত ভিত্তি কৰি কৃষকসকলক অৱগত কৰে।",
              "gu": "તેઓ ખેડૂતોને તેમના સંશોધનમાંથી મળેલા ડેટાના આધારે પાકના નુકસાનને ટાળવા માટે સૂચિત કરે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B9_2_3",
              "hi": "B9_2_3",
              "ka": "B9_2_3",
              "te": "B9_2_3",
              "or": "B9_2_3",
              "as": "B9_2_3",
              "gu": "B9_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The work involves soil testing and experimenting on what plants to grow based on soil analysis.",
              "hi": "इस काम में मिट्टी के परीक्षण के आधार पर यह प्रयोग किया जाता है कि किस प्रकार के पौधों को उगाना चाहिए।",
              "ka": "ಕೆಲಸವು ಮಣ್ಣಿನ ಪರೀಕ್ಷೆ ಮತ್ತು ಮಣ್ಣಿನ ವಿಶ್ಲೇಷಣೆಯ ಆಧಾರದ ಮೇಲೆ ಯಾವ ಸಸ್ಯಗಳನ್ನು ಬೆಳೆಸಬೇಕು ಎಂಬುದರ ಕುರಿತು ಪ್ರಯೋಗವನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.",
              "te": "పనిలో మట్టి పరీక్ష మరియు నేల విశ్లేషణ ఆధారంగా ఏ మొక్కలు పెంచాలనే దానిపై ప్రయోగాలు ఉంటాయి.",
              "or": "ଏହି କାର୍ଯ୍ୟ ମୃତ୍ତିକା ବିଶ୍ଳେଷଣ ଏବଂ ମୃତ୍ତିକା ବିଶ୍ଳେଷଣ ଉପରେ ଆଧାର କରି\n  କେଉଁ ଉଦ୍ଭିଦଗୁଡିକ ବଢିପାରିବ ସେ ସମ୍ବନ୍ଧରେ ପରୀକ୍ଷଣ ସହିତ ଜଡିତ |",
              "as": "কামটোত মাটিৰ পৰীক্ষা আৰু মাটিৰ বিশ্লেষণৰ ভিত্তিত কি উদ্ভিদ ৰুব লাগে তাৰ ওপৰতো পৰীক্ষা কৰা হয়।",
              "gu": "આ કાર્યમાં માટી પરીક્ષણ અને માટી વિશ્લેષણના આધારે કયા છોડ ઉગાડવા તે અંગે પ્રયોગ કરવાનો સમાવેશ થાય છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B9_2_4",
              "hi": "B9_2_4",
              "ka": "B9_2_4",
              "te": "B9_2_4",
              "or": "B9_2_4",
              "as": "B9_2_4",
              "gu": "B9_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar enjoys the work as it involves travelling to different villages across the state to collect soil samples.",
              "hi": "शिखर को काम में आनंद मिलता है क्योंकि इसमें मिट्टी के नमूने एकत्र करने के लिए राज्य भर के विभिन्न गांवों की यात्रा शामिल है।",
              "ka": "ಮಣ್ಣಿನ ಮಾದರಿಗಳನ್ನು ಸಂಗ್ರಹಿಸಲು ರಾಜ್ಯದಾದ್ಯಂತ ವಿವಿಧ ಗ್ರಾಮಗಳಿಗೆ ಪ್ರಯಾಣಿಸುವುದರಿಂದ ಶಿಖರ್ ಕೆಲಸವನ್ನು ಆನಂದಿಸುತ್ತಿದ್ದಾನೆ.",
              "te": "మట్టి నమూనాలను సేకరించేందుకు రాష్ట్రవ్యాప్తంగా వివిధ గ్రామాలకు వెళ్లి శిఖర్ పనిని ఆనందిస్తాడు.",
              "or": "ଶିଖର ଏହି କାମକୁ ଉପଭୋଗ କରନ୍ତି କାରଣ ଏହା ରାଜ୍ୟର ବିଭିନ୍ନ ଗ୍ରାମକୁ\n  ମାଟି ନମୁନା ସଂଗ୍ରହ କରିବା ସହିତ ଜଡିତ |",
              "as": "শিখৰে কামটোত আনন্দ লাভ কৰে কিয়নো ইয়াত মাটিৰ নমুনা সংগ্ৰহ কৰিবলৈ ৰাজ্যৰ বিভিন্ন গাৱলৈ ভ্ৰমণ কৰাৰ সুবিধা আছে।",
              "gu": "શિખરને આ કામ ખૂબ ગમે છે કારણ કે તેમાં માટીના નમૂના લેવા માટે રાજ્યભરના વિવિધ ગામોમાં મુસાફરી કરવાનો સમાવેશ થાય છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B9_4_1",
              "hi": "B9_4_1",
              "ka": "B9_4_1",
              "te": "B9_4_1",
              "or": "B9_4_1",
              "as": "B9_4_1",
              "gu": "B9_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He also calls Arun Bhaiya regularly to share his findings. Arun keeps incorporating his suggestions.",
              "hi": "वह अपने निष्कर्ष साझा करने के लिए अरुण भैया से भी नियमित रूप से बात करता रहता है। अरुण उसके सुझावों को अपने काम में अपनाता जाता है।",
              "ka": "ಅವರು ತಮ್ಮ ಸಂಶೋಧನೆಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ಅರುಣ್ ಭೈಯ್ಯ ಅವರಿಗೆ ನಿಯಮಿತವಾಗಿ ಕರೆ ಮಾಡುತ್ತಾರೆ. ಅರುಣ್ ತಮ್ಮ ಸಲಹೆಗಳನ್ನು ಸೇರಿಸುತ್ತಲೇ ಇರುತ್ತಾರೆ.",
              "te": "అతను తన అన్వేషణలను పంచుకోవడానికి అరుణ్ అన్నయ్యకు క్రమం తప్పకుండా కాల్ చేస్తాడు. అరుణ్ తన సూచనలను ఇస్తూనే ఉన్నాడు.",
              "or": "ସେ ନିଜର ଅନୁସନ୍ଧାନ ବାଣ୍ଟିବା ପାଇଁ ନିୟମିତ ଅରୁଣ ଭାଇଙ୍କୁ ମଧ୍ୟ ଡାକନ୍ତି |\n  ଅରୁଣ ତାଙ୍କର ପରାମର୍ଶଗୁଡ଼ିକୁ ଅନ୍ତର୍ଭୁକ୍ତ କରି ରଖନ୍ତି |",
              "as": "তেওঁ ককায়েক অৰুণকো নিয়মীয়াকৈ তেওঁৰ ফলাফলবোৰ জনাই ফ'ন কৰে। অৰুণেও তেওঁৰ পৰামৰ্শসমূহত সহযোগিতা আগবঢ়ায়।",
              "gu": "તે અરુણ ભાઈને નિયમિતપણે તેના તારણો કહેવા માટે ફોન પણ કરે છે. અરુણ તેના સૂચનો સામેલ કરતો રહે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B9_4_2",
              "hi": "B9_4_2",
              "ka": "B9_4_2",
              "te": "B9_4_2",
              "or": "B9_4_2",
              "as": "B9_4_2",
              "gu": "B9_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar is also able to help Babuji with the expenses. They pay back the loan for their tractor and employ more people to help on their farm.",
              "hi": "शिखर बाबूजी को खर्चों में सहयोग करने में भी सक्षम है। वे अपने ट्रैक्टर का ऋण वापस भुगतान करते हैं और अधिक लोगों को अपने खेत में सहायता करने के लिए लगा लेते हैं।",
              "ka": "ಶಿಖರ್ ಕೂಡ ಬಾಬೂಜಿಗೆ ಖರ್ಚಿಗೆ ಸಹಾಯ ಮಾಡಬಲ್ಲ. ಅವರು ತಮ್ಮ ಟ್ರ್ಯಾಕ್ಟರ್‌ಗಾಗಿ ಸಾಲವನ್ನು ಮರುಪಾವತಿಸುತ್ತಾರೆ ಮತ್ತು ತಮ್ಮ ಜಮೀನಿನಲ್ಲಿ ಸಹಾಯ ಮಾಡಲು ಹೆಚ್ಚಿನ ಜನರನ್ನು ನೇಮಿಸಿಕೊಳ್ಳು",
              "te": "శిఖర్ కూడా నాన్నగారికి ఖర్చులకు సహాయం చేయగలడు. వారు తమ ట్రాక్టర్ కోసం తీసుకున్న రుణాన్ని తిరిగి చెల్లిస్తారు మరియు వారి పొలంలో సహాయం చేయడానికి ఎక్కువ మందిని నియమించుకుంటారు.",
              "or": "ଶିଖର ମଧ୍ୟ ଖର୍ଚ୍ଚରେ ବାବୁଜୀଙ୍କୁ ସାହାଯ୍ୟ କରିବାରେ ସକ୍ଷମ ଅଟନ୍ତି | ସେମାନେ \n ସେମାନଙ୍କର ଟ୍ରାକ୍ଟର ପାଇଁ ରୁଣ ଫେରସ୍ତ କରନ୍ତି ଏବଂ ସେମାନଙ୍କ ଚାଷରେ\n  ସାହାଯ୍ୟ କରିବାକୁ ଅଧିକ ଲୋକଙ୍କୁ ନିଯୁକ୍ତି ଦିଅନ୍ତି |",
              "as": "শিখৰেও দেউতাকৰ খৰচত সহায় কৰিবলৈ সক্ষম হৈছে। তেওঁলোকে ট্ৰেক্টৰৰ বাবে লোৱা ঋণ পৰিশোধ কৰিছে আৰু তেওঁলোকৰ খেতি পথাৰত সহায় কৰিবলৈ কেইবাজনো লোককো নিয়োগ কৰিছে।",
              "gu": "શિખર બાબુજીને ખર્ચમાં મદદ કરવા પણ સક્ષમ છે. તેઓ તેમના ટ્રેક્ટરની લોન પરત ચૂકવે છે અને તેમના ખેતરમાં મદદ કરવા માટે વધુ લોકોને રોજગારી આપે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "Research Assistant",
              "hi": "अनुसंधान सहायक",
              "ka": "ಸಂಶೋಧನಾ ಸಹಾಯಕ",
              "te": "పరిశోధన సహాయకుడు",
              "or": "ଅନୁସନ୍ଧାନ ସହାୟକ",
              "as": "গৱেষণা সহায়ক",
              "gu": "સંશોધન સહાયક"
            }
          }
        ]
      },
      "scene49": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Take up the job",
              "hi": "काम करना",
              "ka": "ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳುವುದು",
              "te": "ఉద్యోగంలో చేరడం",
              "or": "ଚାକିରି ନିଅ |",
              "as": "চাকৰিটো লওক",
              "gu": "નોકરી ચાલુ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar is not ready to commit four years to studying. He decides to join CropSense as their youth champion.",
              "hi": "शिखर अध्ययन में चार साल लगाने के लिए तैयार नहीं है। वह क्रॉप्सेंस से उनके युवा चैंपियन के रूप में  जुड़ने का निश्चय करता है।",
              "ka": "ಶಿಖರ್ ನಾಲ್ಕು ವರ್ಷ ಅಧ್ಯಯನವನ್ನು ಮಾಡಲು ಸಿದ್ಧನಿಲ್ಲ. ಆತನು ಯುವ ಚಾಂಪಿಯನ್ ಆಗಿ ಕ್ರಾಪ್‌ಸೆನ್ಸ್‌ಗೆ ಸೇರಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "నాలుగేళ్లు చదువుకు కట్టుబడి ఉండేందుకు శిఖర్ సిద్ధంగా లేడు. అతను క్రాప్‌సెన్స్ లో వారి యూత్ ఛాంపియన్‌ గా చేరాలని నిర్ణయించుకున్నాడు.",
              "or": "ଶିଖର ଅଧ୍ୟୟନ ପାଇଁ ଚାରି ବର୍ଷ କରିବାକୁ ପ୍ରସ୍ତୁତ ନୁହଁନ୍ତି | ସେ ଫସଲ ବିଜ୍ଞାନ ରେ \n  ସେମାନଙ୍କର ଯୁବ ଚାମ୍ପିଅନ୍ ଭାବରେ ଯୋଗଦେବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "শিখৰে অধ্যয়নৰ বাবে চাৰি বছৰ খৰচ কৰিবলৈ সাজু নহয়। তেওঁ ক্ৰপচেঞ্চত তেওঁলোকৰ য়ুথ চেম্পিয়ন হিচাপে যোগদান কৰাৰ সিদ্ধান্ত লয়।",
              "gu": "શિખર ચાર વર્ષ ભણવા માટે તૈયાર નથી. તે તેના યુવા ચેમ્પિયન તરીકે ક્રોપસેન્સમાં જોડાવાનું નક્કી કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B10_1_2",
              "hi": "B10_1_2",
              "ka": "B10_1_2",
              "te": "B10_1_2",
              "or": "B10_1_2",
              "as": "B10_1_2",
              "gu": "B10_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The work is only for a few hours in the day, and he is happy that he can work both on his farm and at CropSense.",
              "hi": "दिन में केवल कुछ घंटों का काम है, और वह खुश है कि वह अपने खेत और क्रॉप्सेंस दोनों पर काम कर सकता है।",
              "ka": "ದಿನದಲ್ಲಿ ಕೆಲವೇ ಗಂಟೆಗಳ ಕಾಲ ಮಾತ್ರ ಕೆಲಸವಿದ್ದು, ತನ್ನ ಜಮೀನಿನಲ್ಲಿ ಮತ್ತು ಕ್ರಾಪ್‌ಸೆನ್ಸ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡಬಹುದೆಂದು ಸಂತೋಷಪಡುತ್ತಾನೆ.",
              "te": "పని రోజులో కొన్ని గంటలు మాత్రమే, మరియు అతను తన పొలంలో మరియు క్రాప్‌సెన్స్ లో పని చేయగలనని సంతోషంగా ఉన్నాడు.",
              "or": "କାମଟି ଦିନରେ ମାତ୍ର କିଛି ଘଣ୍ଟା ପାଇଁ ହୋଇଥାଏ, ଏବଂ ସେ ଖୁସି ଯେ ସେ \n ନିଜ ଚାଷ ଜମିରେ ଏବଂ ଫସଲ ବିଜ୍ଞାନରେ କାମ କରିପାରିବେ |",
              "as": "কামটো দিনটোত কেৱল কেইঘণ্টামানৰ বাবেহে কৰিব লাগে, আৰু তেওঁ সুখী যে তেওঁ নিজৰ ফাৰ্ম আৰু ক্ৰপচেঞ্চ দুয়োটাতে কাম কৰিব পাৰে।",
              "gu": "કામ દિવસમાં માત્ર થોડા કલાકો માટે જ હોય છે, અને તે ખુશ છે કે તે તેના ખેતરમાં અને ક્રોપસેન્સ બંનેમાં કામ કરી શકે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B10_2_1",
              "hi": "B10_2_1",
              "ka": "B10_2_1",
              "te": "B10_2_1",
              "or": "B10_2_1",
              "as": "B10_2_1",
              "gu": "B10_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As a junior Youth Champion, he has to talk to the farmers and fix the sensors in their fields.",
              "hi": "एक जूनियर युवा चैंपियन के रूप में, उन्हें किसानों से बात करना है और सेंसर को उनके खेतों में  लगाना है।",
              "ka": "ಜೂನಿಯರ್ ಯೂತ್ ಚಾಂಪಿಯನ್ ಆದ ಆತನು ರೈತರೊಂದಿಗೆ ಮಾತನಾಡಿ ಅವರ ಹೊಲಗಳಲ್ಲಿ ಸೆನ್ಸಾರ್‌ಗಳನ್ನು ಸರಿಪಡಿಸಬೇಕು.",
              "te": "జూనియర్ యూత్ ఛాంపియన్ గా రైతులతో మాట్లాడి వారి పొలాల్లో సెన్సార్లు బిగించాలి.",
              "or": "ଜୁନିଅର ୟୁଥ୍ ଚାମ୍ପିଅନ୍ ଭାବରେ ତାଙ୍କୁ କୃଷକମାନଙ୍କ ସହ କଥା ହେବା ଏବଂ\n  ସେମାନଙ୍କ କ୍ଷେତରେ ସେନ୍ସର ଠିକ୍ କରିବାକୁ ପଡିବ |",
              "as": "এজন জুনিয়ৰ য়ুথ চেম্পিয়ন হিচাপে, তেওঁ কৃষকসকলৰ সৈতে কথা পাতিব লাগিব আৰু তেওঁলোকৰ পথাৰত চেন্সৰবোৰ ঠিক কৰিব লাগিব।",
              "gu": "જુનિયર યુથ ચેમ્પિયન તરીકે તેણે ખેડૂતો સાથે વાત કરવી પડશે અને તેમના ખેતરોમાં સેન્સર ઠીક કરવા પડશે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B10_2_2",
              "hi": "B10_2_2",
              "ka": "B10_2_2",
              "te": "B10_2_2",
              "or": "B10_2_2",
              "as": "B10_2_2",
              "gu": "B10_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "One day, he records one of his conversations with a farmer.",
              "hi": "एक दिन, वह एक किसान के साथ अपनी बातचीत को रिकॉर्ड करता है।",
              "ka": "ಒಂದು ದಿನ, ಅವನು ರೈತನೊಂದಿಗಿನ ತನ್ನ ಸಂಭಾಷಣೆಯನ್ನು ರೆಕಾರ್ಡ್ ಮಾಡುತ್ತಾನೆ.",
              "te": "ఒక రోజు, అతను ఒక రైతుతో తన సంభాషణను రికార్డ్ చేశాడు.",
              "or": "ଦିନେ, ସେ ଜଣେ କୃଷକଙ୍କ ସହ ତାଙ୍କର ଏକ ବାର୍ତ୍ତାଳାପକୁ ରେକର୍ଡ କରନ୍ତି |",
              "as": "এদিন, তেওঁ এজন কৃষকৰ সৈতে হোৱা কথা-বতৰাৰ এটা ৰেকৰ্ড কৰে।",
              "gu": "એક દિવસ, તે એક ખેડૂત સાથેની તેમની વાતચીત રેકોર્ડ કરે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B10_2_3",
              "hi": "B10_2_3",
              "ka": "B10_2_3",
              "te": "B10_2_3",
              "or": "B10_2_3",
              "as": "B10_2_3",
              "gu": "B10_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He finds it very interesting and makes it a practice to do interviews with farmers willing to talk to him.",
              "hi": "वह इसे बहुत रोचक लगता है और वह बात करने के इच्छुक किसानों से साक्षात्कार करना अपनी आदत बना लेता है।",
              "ka": "ಆತನು ಅದನ್ನು ತುಂಬಾ ಆಸಕ್ತಿದಾಯಕವಾಗಿ ಕಂಡುಕೊಳ್ಳುತ್ತಾನೆ ಮತ್ತು ತನ್ನೊಂದಿಗೆ ಮಾತನಾಡಲು ಸಿದ್ಧರಿರುವ ರೈತರೊಂದಿಗೆ ಸಂದರ್ಶನಗಳನ್ನು ಮಾಡುವುದನ್ನು ಅಭ್ಯಾಸ ಮಾಡಿಕೊಳ್ಳುತ್ತಾನೆ",
              "te": "అతను దానిని చాలా ఆసక్తికరంగా భావించాడు మరియు అతనితో మాట్లాడటానికి ఇష్టపడే రైతులతో ఇంటర్వ్యూలు చేయడం అలవాటు చేసుకున్నాడు.",
              "or": "ସେ ଏହାକୁ ଅତ୍ୟନ୍ତ କୌତୁହଳଜନକ ମନେ କରନ୍ତି ଏବଂ ତାଙ୍କ ସହ କଥାବାର୍ତ୍ତା\n  କରିବାକୁ ଇଚ୍ଛୁକ କୃଷକମାନଙ୍କ ସହିତ ସାକ୍ଷାତକାର କରିବା ଏକ ଅଭ୍ୟାସ କରନ୍ତି |",
              "as": "তেওঁ এইটো বহুত আকৰ্ষণীয় অনুভৱ কৰে আৰু তেওঁৰ সৈতে কথা পাতিবলৈ ইচ্ছুক কৃষকসকলৰ সৈতে সাক্ষাৎকাৰ লোৱাটো এটা অভ্যাস কৰে।",
              "gu": "તેને તે ખૂબ જ રસપ્રદ લાગે છે અને તેની સાથે વાત કરવા ઇચ્છુક ખેડૂતો સાથે ઇન્ટરવ્યુ લેવાની પ્રેક્ટિસ બનાવે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B10_3_1 bg",
              "hi": "B10_3_1 बीजी",
              "ka": "B10_3_1 bg",
              "te": "B10_3_1 bg",
              "or": "B10_3_1 bg",
              "as": "B10_3_1 bg",
              "gu": "B10_3_1 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Ashwa",
              "hi": "अश्व",
              "ka": "ಅಶ್ವ",
              "te": "అశ్వ",
              "or": "ଅଶ୍ଵ",
              "as": "অশ্ব",
              "gu": "અશ્વ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikhar",
              "hi": "शिखर",
              "ka": "ಶಿಖರ್",
              "te": "శిఖర్",
              "or": "ଶିଖର",
              "as": "শিখৰ",
              "gu": "શિખર"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "These interviews are so good, Shikhar!",
              "hi": "ये साक्षात्कार बहुत अच्छे हैं, शिखर!",
              "ka": "ಈ ಸಂದರ್ಶನಗಳು ತುಂಬಾ ಚೆನ್ನಾಗಿವೆ ಶಿಖರ್!",
              "te": "ఈ ఇంటర్వ్యూలు చాలా బాగున్నాయి శిఖర్!",
              "or": "ଏହି ସାକ୍ଷାତକାରଗୁଡ଼ିକ ବହୁତ ଭଲ, ଶିଖର!",
              "as": "এই সাক্ষাৎকাৰবোৰ ইমান ভাল, শিখৰ!",
              "gu": "આ ઇન્ટરવ્યુ ખૂબ સારા છે, શિખર!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Yes! I use these to convince other farmers who are unsure about using the sensors. After listening to more farmers talk about it, they feel comfortable about using it themselves.",
              "hi": "हां! मैं इन्हें उन किसानों को मनाने के लिए उपयोग करता हूं जो सेंसर का उपयोग करने के बारे में अनिश्चित हैं। अधिक किसानों को सुनने के बाद वे इसके बारे में बात करते हैं, वे इसे स्वयं उपयोग करने के बारे में सहज महसूस करते हैं।",
              "ka": "ಹೌದು! ಸಂವೇದಕಗಳನ್ನು ಬಳಸುವ ಬಗ್ಗೆ ಖಚಿತತೆಯಿಲ್ಲದ ಇತರ ರೈತರಿಗೆ ಮನವರಿಕೆ ಮಾಡಲು ನಾನು ಇದನ್ನು ಬಳಸುತ್ತೇನೆ. ಹೆಚ್ಚಿನ ರೈತರು ಅದರ ಬಗ್ಗೆ ಮಾತನಾಡುವುದನ್ನು ಕೇಳಿದ ನಂತರ, ಅದನ್ನು ಸ್ವತಃ ಬಳಸುವ ಬಗ್ಗೆ ಅವರು ತಯಾರಾಗಿದ್ದಾರೆ.",
              "te": "అవును! సెన్సార్లను ఉపయోగించడం గురించి తెలియని ఇతర రైతులను ఒప్పించేందుకు నేను వీటిని ఉపయోగిస్తాను. ఎక్కువ మంది రైతులు దాని గురించి మాట్లాడటం విన్న తర్వాత, వారు దానిని స్వయంగా ఉపయోగించుకోవడంలో ఆసక్తి చూపిస్తున్నారు.",
              "or": "ହଁ! ସେନ୍ସର ବ୍ୟବହାର ବିଷୟରେ ଅନିଶ୍ଚିତ ଥିବା ଅନ୍ୟ କୃଷକମାନଙ୍କୁ ମନାଇବା\n  ପାଇଁ ମୁଁ ଏଗୁଡିକ ବ୍ୟବହାର କରେ | ଅଧିକ କୃଷକ ଏହା ବିଷୟରେ କଥାବାର୍ତ୍ତା\n  ଶୁଣିବା ପରେ, ସେମାନେ ଏହାକୁ ନିଜେ ବ୍ୟବହାର କରିବାରେ ସହଜ \n ଅନୁଭବ କରନ୍ତି |",
              "as": "হয়! মই এইবোৰ আন কৃষকসকলক সৈমান কৰিবলৈ ব্যৱহাৰ কৰোঁ যিসকলে চেন্সৰ ব্যৱহাৰ কৰাৰ বিষয়ে নিশ্চিত নহয়। অধিক কৃষকে ইয়াৰ বিষয়ে কোৱা শুনি, তেওঁলোকে নিজে ইয়াক ব্যৱহাৰ কৰিবলৈ সহজ অনুভৱ কৰে।",
              "gu": "હા! હું આનો ઉપયોગ અન્ય ખેડૂતોને સમજાવવા માટે કરું છું જેઓ સેન્સરનો ઉપયોગ કરવા વિશે અચોક્કસ છે. તેના વિશે વધુ ખેડૂતોની વાતો સાંભળ્યા પછી, તેઓ પોતે તેનો ઉપયોગ કરવામાં આરામદાયક અનુભવે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B10_3_2 ///",
              "hi": "B10_3_2 /////",
              "ka": "B10_3_2 ///",
              "te": "B10_3_2 ///",
              "or": "B10_3_2 ///",
              "as": "B10_3_2 ///",
              "gu": "B10_3_2 ///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Wow! That's an excellent idea. It's also great for our team to have these interviews in our database.",
              "hi": "वाह! यह एक उत्कृष्ट विचार है। हमारी टीम के लिए अपने डेटाबेस में इन साक्षात्कारों का होना भी बहुत अच्छा है।",
              "ka": "ಅದ್ಭುತ! ಅದೊಂದು ಅತ್ಯುತ್ತಮ ಉಪಾಯ. ನಮ್ಮ ಡೇಟಾಬೇಸ್‌ನಲ್ಲಿ ಈ ಸಂದರ್ಶನಗಳನ್ನು ಹೊಂದಲು ನಮ್ಮ ತಂಡಕ್ಕೆ ಇದು ಅದ್ಭುತ ಮಾಹಿತಿಯಾಗುತ್ತದೆ.",
              "te": "వావ్! అది అద్భుతమైన ఆలోచన. మా డేటాబేస్‌ లో ఈ ఇంటర్వ్యూలు ఉండటం మా బృందానికి కూడా గొప్ప విషయం.",
              "or": "ବାହା! ତାହା ଏକ ଉତ୍କୃଷ୍ଟ ଧାରଣା | ଆମ ଡାଟାବେସରେ ଏହି ସାକ୍ଷାତକାରଗୁଡ଼ିକ\n  ଆମ ଦଳ ପାଇଁ ମଧ୍ୟ ଭଲ ଅଟେ |",
              "as": "বাঃ! এইটো এটা উৎকৃষ্ট আইডিয়া। আমাৰ দলৰ বাবে আমাৰ ডাটাবেছত এই সাক্ষাৎকাৰবোৰ থকাটোও ভাল।",
              "gu": "વાહ! તે એક ઉત્તમ વિચાર છે. અમારા ડેટાબેઝમાં આ ઇન્ટરવ્યુ રાખવા અમારી ટીમ માટે પણ ખૂબ સરસ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, it is interesting to explore different ways to sell the product to farmers.",
              "hi": "हां, किसानों को उत्पाद बेचने के विभिन्न तरीकों का पता लगाना रोचक है।",
              "ka": "ಹೌದು, ರೈತರಿಗೆ ಉತ್ಪನ್ನವನ್ನು ಮಾರಾಟ ಮಾಡಲು ವಿವಿಧ ಮಾರ್ಗಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಇದು ಆಸಕ್ತಿದಾಯಕವಾಗಿದೆ.",
              "te": "అవును, రైతులకు ఉత్పత్తిని విక్రయించడానికి వివిధ మార్గాలను అన్వేషించడం ఆసక్తికరంగా ఉంటుంది.",
              "or": "ହଁ, କୃଷକମାନଙ୍କୁ ଉତ୍ପାଦ ବିକ୍ରୟ କରିବାର ବିଭିନ୍ନ ଉପାୟ ଅନୁସନ୍ଧାନ କରିବା \n କୌତୁହଳପ୍ରଦ |",
              "as": "হয়, কৃষকসকলক সামগ্ৰীটো বিক্ৰী কৰাৰ বিভিন্ন উপায় বিচৰাটো আকৰ্ষণীয়।",
              "gu": "હા, ખેડૂતોને ઉત્પાદન વેચવાની વિવિધ રીતોની શોધ કરવી રસપ્રદ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Hmm... If you're interested, why don't you join us full time as a field assistant?",
              "hi": "हम्म ... यदि आप रुचि रखते हैं, तो आप एक क्षेत्र सहायक के रूप में पूर्णकालिक क्यों नहीं जुड़ते हैं?",
              "ka": "ಹಾಂ... ನಿಮಗೆ ಆಸಕ್ತಿ ಇದ್ದಲ್ಲಿ, ನೀವು ನಮ್ಮೊಂದಿಗೆ ಫೀಲ್ಡ್ ಅಸಿಸ್ಟೆಂಟ್ ಆಗಿ ಪೂರ್ಣ ಸಮಯದ ಕೆಲಸಕ್ಕೆ  ಏಕೆ ಸೇರಬಾರದು?",
              "te": "హ్మ్ ... మీకు ఆసక్తి ఉంటే, మీరు మాతో పూర్తి సమయం ఫీల్డ్ సహాయకుడు గా ఎందుకు చేరకూడదు?",
              "or": "ହମ୍ ... ଯଦି ତୁମେ ଆଗ୍ରହୀ, ତୁମେ କାହିଁକି ଫିଲ୍ଡ ଆସିଷ୍ଟାଣ୍ଟ ଭାବରେ ପୂର୍ଣ୍ଣ \n ସମୟ ଯୋଗଦାନ କରୁନାହଁ?",
              "as": "হুম... যদি তুমি আগ্ৰহী, তুমি ফিল্ড এচিষ্টেণ্ট হিচাপে আমাৰ সৈতে সম্পূৰ্ণ সময় যোগদান নকৰা কিয়?",
              "gu": "બરાબર... જો તને રુચિ છે, તો શા માટે તું અમારી સાથે ખેતરના સહાયક તરીકે પુરા સમય માટે જોડાતા નથી?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Full time? That would mean I wouldn't be able to work on our farm.",
              "hi": "पूरा समय? इसका अर्थ यह होगा कि मैं अपने खेत पर काम नहीं कर पाऊंगा।",
              "ka": "ಪೂರ್ಣ ಸಮಯ? ನಮ್ಮ ಜಮೀನಿನಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ನನಗೆ ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ ಎಂದರ್ಥ.",
              "te": "పూర్తి సమయం? అంటే నేను మా పొలంలో పని చేయలేను.",
              "or": "ପୁରା ସମୟ? ଏହାର ଅର୍ଥ ମୁଁ ଆମ ଫାର୍ମରେ କାମ କରିବାକୁ ସମର୍ଥ ହେବି ନାହିଁ |",
              "as": "সম্পূৰ্ণ সময়? তাৰ মানে মই আমাৰ খেতিপথাৰত কাম কৰিব নোৱাৰিম।",
              "gu": "આખો સમય? તેનો અર્થ એ થશે કે હું અમારા ખેતરમાં કામ કરી શકીશ નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Yes, but this is a big opportunity. We don't simply offer this to everyone.",
              "hi": "हाँ, लेकिन यह एक बड़ा अवसर है। हम सभी को यह सब कुछ नहीं प्रस्तावित करते।",
              "ka": "ಹೌದು, ಆದರೆ ಇದು ಒಂದು ದೊಡ್ಡ ಅವಕಾಶ. ನಾವು ಇದನ್ನು ಎಲ್ಲರಿಗೂ ಸರಳವಾಗಿ ನೀಡುವುದಿಲ್ಲ.",
              "te": "అవును, కానీ ఇది ఒక పెద్ద అవకాశం. మేము దీన్ని అందరికీ అందించము.",
              "or": "ହଁ, କିନ୍ତୁ ଏହା ଏକ ବଡ଼ ସୁଯୋଗ | ଆମେ ଏହାକୁ ସମସ୍ତଙ୍କୁ ପ୍ରଦାନ କରୁନାହୁଁ |",
              "as": "হয়, কিন্তু এইটো এটা ডাঙৰ সুযোগ। আমি সাধাৰণতে এইটো সকলোকে নিদিও।",
              "gu": "હા, પણ આ એક મોટી તક છે. અમે ફક્ત દરેકને આ ઓફર કરતા નથી."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B10_3_3 D",
              "hi": "B10_3_3 d",
              "ka": "B10_3_3 D",
              "te": "B10_3_3 D",
              "or": "B10_3_3 D",
              "as": "B10_3_3 D",
              "gu": "B10_3_3 D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Shikhar do?",
              "hi": "शिखर को क्या करना चाहिए?",
              "ka": "ಶಿಖರ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "శిఖర్ ఏం చేయాలి?",
              "or": "ଶିଖର କ’ଣ କରିବା ଉଚିତ୍?",
              "as": "শিখৰে কি কৰিব লাগে?",
              "gu": "શિખરે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Get the job as a field assistant",
              "hi": "एक क्षेत्र सहायक के रूप में नौकरी प्राप्त करे",
              "ka": "ಕ್ಷೇತ್ರ ಸಹಾಯಕರಾಗಿ ಕೆಲಸ ಪಡೆಯುವುದು",
              "te": "ఫీల్డ్ సహాయకుడు గా ఉద్యోగం పొందడం",
              "or": "ଫିଲ୍ଡ ଆସିଷ୍ଟାଣ୍ଟ ଭାବରେ ଚାକିରି ପାଆନ୍ତୁ |",
              "as": "ফিল্ড এচিষ্টেণ্ট হিচাপে চাকৰিটো পোৱা",
              "gu": "ખેતરમાં સહાયક તરીકે નોકરી મેળવો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Continue with the part-time role and help the family on the side.",
              "hi": "अंशकालिक नौकरी करना जारी रखे और साथ ही परिवार की सहायता करे।",
              "ka": "ಅರೆಕಾಲಿಕ ಕೆಲಸವನ್ನು ಮುಂದುವರಿಸುವುದು ಮತ್ತು ತನ್ನ ಕುಟುಂಬಕ್ಕೆ ಸಹಾಯ ಮಾಡುವುದು",
              "te": "పార్ట్-టైమ్ పాత్రతో కొనసాగుతూ కుటుంబానికి సహాయం చేయడం.",
              "or": "ପାର୍ଟ ଟାଇମ୍ ଭୂମିକା ସହିତ ଜାରି ରଖନ୍ତୁ ଏବଂ ପାର୍ଶ୍ୱରେ ପରିବାରକୁ ସାହାଯ୍ୟ\n  କରନ୍ତୁ |",
              "as": "আংশিক-সময়ৰ কাম বজাই ৰাখা আৰু কাষত থকা পৰিয়ালটোক সহায় কৰা।",
              "gu": "પાર્ટ-ટાઇમ ભૂમિકા સાથે ચાલુ રાખો અને પરિવારને બાજુ પર મદદ કરો."
            }
          }
        ]
      },
      "scene50": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Get the job of field assistant",
              "hi": "फील्ड सहायक का काम प्राप्त करे",
              "ka": "ಕ್ಷೇತ್ರ ಸಹಾಯಕರ ಕೆಲಸ ಪಡೆಯುವುದು",
              "te": "ఫీల్డ్ సహాయకుడు ఉద్యోగం పొందడం",
              "or": "ଫିଲ୍ଡ ଆସିଷ୍ଟାଣ୍ଟ ଚାକିରି ପାଆନ୍ତୁ |",
              "as": "ফিল্ড এচিষ্টেণ্টৰ চাকৰি পোৱা",
              "gu": "ખેતર સહાયકની નોકરી મેળવો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar decides to take up the opportunity to become a field assistant. He has to undergo training before he can take up the position.",
              "hi": "शिखर ने एक क्षेत्र सहायक बनने का निश्चय किया। पदभार ग्रहण करने से पहले उसे प्रशिक्षण से गुजरना पड़ता है।",
              "ka": "ಕ್ಷೇತ್ರ ಸಹಾಯಕನಾಗುವ ಅವಕಾಶವನ್ನು ಬಳಸಿಕೊಳ್ಳಲು ಶಿಖರ್ ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಆ ಸ್ಥಾನವನ್ನು ಪಡೆದುಕೊಳ್ಳುವ ಮೊದಲು ಅವನು ತರಬೇತಿಯನ್ನು ಪಡೆಯಬೇಕು.",
              "te": "శిఖర్ ఫీల్డ్ సహాయకుడు అయ్యే అవకాశాన్ని ఉపయోగించుకోవాలని నిర్ణయించుకున్నాడు. అతను పదవిని చేపట్టడానికి ముందు అతను శిక్షణ పొందాలి.",
              "or": "ଫିଲ୍ଡ ଆସିଷ୍ଟାଣ୍ଟ ହେବାର ସୁଯୋଗ ନେବାକୁ ଶିଖର ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ସେ\n  ଏହି ପଦବୀ ଗ୍ରହଣ କରିବା ପୂର୍ବରୁ ତାଲିମ ନେବାକୁ ପଡିବ।",
              "as": "শিখৰে ফিল্ড এচিষ্টেণ্ট হোৱাৰ সুযোগ লোৱাৰ সিদ্ধান্ত লয়। সি পদটো গ্ৰহণ কৰাৰ আগতে প্ৰশিক্ষণ ল'ব লাগিব।",
              "gu": "શિખરે ફિલ્ડ આસિસ્ટન્ટ બનવાની તક ઝડપી લેવાનું નક્કી કર્યું. તે પદ સંભાળી શકે તે પહેલા તેણે તાલીમ લેવી પડશે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B11_1_2",
              "hi": "B11_1_2",
              "ka": "B11_1_2",
              "te": "B11_1_2",
              "or": "B11_1_2",
              "as": "B11_1_2",
              "gu": "B11_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar is excited about the training, which is going to happen online. He learns how to understand data, interpret it, and share it with the farmers in training.",
              "hi": "शिखर प्रशिक्षण के बारे में उत्साहित है, जो ऑनलाइन होने जा रहा है। वह सीखता है कि डेटा को कैसे समझे, इसकी व्याख्या करे, और इसे प्रशिक्षण में किसानों के साथ साझा करे।",
              "ka": "ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ನಡೆಯಲಿರುವ ತರಬೇತಿಯ ಬಗ್ಗೆ ಶಿಖರ್ ಉತ್ಸುಕನಾಗಿದ್ದಾನೆ. ಡೇಟಾವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು, ಅದನ್ನು ಅರ್ಥೈಸುವುದು ಮತ್ತು ತರಬೇತಿಯಲ್ಲಿ ರೈತರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳುವುದು ಹೇಗೆ ಎಂದು ಅವನು ಕಲಿಯುತ್ತಾನೆ.",
              "te": "ఆన్‌లైన్లో జరగబోయే శిక్షణ గురించి శిఖర్ ఉత్సాహంగా ఉన్నాడు. అతను డేటాను అర్థం చేసుకోవడం మరియు శిక్షణలో రైతులతో పంచుకోవడం ఎలాగో నేర్చుకుంటాడు.",
              "or": "ଅନଲାଇନରେ ହେବାକୁ ଥିବା ଏହି ତାଲିମକୁ ନେଇ ଶିଖର ଉତ୍ସାହିତ ଅଛନ୍ତି। \n ସେ ତାଲିମରେ କିପରି ତଥ୍ୟ ବୁଝିବା, ଏବଂ ଏହାର ବ୍ୟାଖ୍ୟା କରିବା କୃଷକମାନଙ୍କ ସହ ବାଣ୍ଟିବା ଶିଖନ୍ତି |",
              "as": "শিখৰ প্ৰশিক্ষণৰ বিষয়ে উৎসাহিত, যিটো অনলাইনত হ'বলৈ গৈ আছে। সি তথ্য কেনেদৰে বুজিব লাগে, ইয়াক ব্যাখ্যা কৰিব লাগে আৰু প্ৰশিক্ষণত কৃষকসকলৰ সৈতে ভাগ বতৰা কৰিব লাগে শিকে।",
              "gu": "શિખર આ ટ્રેનિંગને લઈને ઉત્સાહિત છે, જે ઓનલાઈન થવા જઈ રહી છે. તે તાલીમમાં ડેટાને કેવી રીતે સમજવું, તેનું અર્થઘટન કરવું અને ખેડૂતોને તે કેવી રીતે શીખવું તે શીખે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B11_1_3",
              "hi": "B11_1_3",
              "ka": "B11_1_3",
              "te": "B11_1_3",
              "or": "B11_1_3",
              "as": "B11_1_3",
              "gu": "B11_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After completing the training, he becomes a certified field assistant.",
              "hi": "प्रशिक्षण पूरा करने के बाद, वह एक प्रमाणित क्षेत्र सहायक बन जाता है।",
              "ka": "ತರಬೇತಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ ನಂತರ, ಅವನು ಪ್ರಮಾಣೀಕೃತ ಕ್ಷೇತ್ರ ಸಹಾಯಕನಾಗುತ್ತಾನೆ",
              "te": "శిక్షణ పూర్తయిన తర్వాత, అతను ధృవీకరించబడిన ఫీల్డ్ సహాయకుడు అవుతాడు.",
              "or": "ତାଲିମ ସମାପ୍ତ କରିବା ପରେ, ସେ ଏକ ସାର୍ଟିଫାଏଡ୍ ଫିଲ୍ଡ ଆସିଷ୍ଟାଣ୍ଟ ହୁଅନ୍ତି |",
              "as": "প্ৰশিক্ষণ সম্পূৰ্ণ কৰাৰ পিছত, সি এজন প্ৰমাণিত ক্ষেত্ৰ সহায়ক হয়।",
              "gu": "તાલીમ પૂર્ણ કર્યા પછી, તે પ્રમાણિત ખેતરના સહાયક બને છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B11_2_1",
              "hi": "B11_2_1",
              "ka": "B11_2_1",
              "te": "B11_2_1",
              "or": "B11_2_1",
              "as": "B11_2_1",
              "gu": "B11_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As a field assistant, he conducts workshops for farmers on sensors and how to use their phones to monitor the data from the sensors.",
              "hi": "एक क्षेत्र सहायक के रूप में, वह किसानों के लिए सेंसर पर और सेंसर से डेटा की निगरानी के लिए अपने फोन का उपयोग कैसे करे, इसपर कार्यशालाएं आयोजित करता है ।",
              "ka": "ಕ್ಷೇತ್ರ ಸಹಾಯಕನಾಗಿ, ಅವನು ಸಂವೇದಕಗಳ ಕುರಿತು ರೈತರಿಗೆ ಕಾರ್ಯಾಗಾರಗಳನ್ನು ನಡೆಸುತ್ತಾನೆ ಮತ್ತು ಸಂವೇದಕಗಳಿಂದ ಡೇಟಾವನ್ನು ಮೇಲ್ವಿಚಾರಣೆ ಮಾಡಲು ಅವರ ಫೋನ್‌ಗಳನ್ನು ಹೇಗೆ ಬಳಸುವುದು ಎನ್ನುವುದನ್ನು ತಿಳಿಸಿಕೊಡುತ್ತಾನೆ",
              "te": "ఫీల్డ్ సహాయకుడు గా, అతను రైతులకు సెన్సార్లపై మరియు సెన్సార్ల నుండి డేటాను పర్యవేక్షించడానికి వారి ఫోన్లను ఎలా ఉపయోగించాలి అనే వాటిపై వర్క్ షాప్లు నిర్వహిస్తాడు.",
              "or": "ଫିଲ୍ଡ ଆସିଷ୍ଟାଣ୍ଟ ଭାବରେ ସେ ସେନ୍ସର ଏବଂ \n ସେନ୍ସର ର ତଥ୍ୟ ଉପରେ ନଜର ରଖିବା ପାଇଁ ସେମାନଙ୍କ ଫୋନ୍ କିପରି ବ୍ୟବହାର\n  କରିବେ ସେହି ବିଷୟରେ ବୁଝାଇବା ପାଇଁ କୃଷକମାନଙ୍କ ପାଇଁ ଏକ କର୍ମଶାଳା ର\n  ଆୟୋଜନ କରନ୍ତି।",
              "as": "ক্ষেত্ৰ সহায়ক হিচাপে, সি কৃষকসকলৰ বাবে চেন্সৰ আৰু চেন্সৰৰ পৰা ডাটা নিৰীক্ষণ কৰিবলৈ তেওঁলোকৰ ফোন কেনেদৰে ব্যৱহাৰ কৰিব লাগে তাৰ ওপৰত কৰ্মশালা অনুষ্ঠিত কৰে।",
              "gu": "ફિલ્ડ આસિસ્ટન્ટ તરીકે, તે સેન્સર પર ખેડૂતો માટે વર્કશોપનું આયોજન કરે છે અને સેન્સરમાંથી ડેટાને મોનિટર કરવા માટે તેમના ફોનનો ઉપયોગ કેવી રીતે કરવો."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B11_2_2",
              "hi": "B11_2_2",
              "ka": "B11_2_2",
              "te": "B11_2_2",
              "or": "B11_2_2",
              "as": "B11_2_2",
              "gu": "B11_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "His favourite part of the job is teaching about technology to fellow farmers.",
              "hi": "नौकरी में उसका पसंदीदा काम साथी किसानों को तकनीक के बारे में पढ़ाना  है।",
              "ka": "ಸಹವರ್ತಿ ರೈತರಿಗೆ ತಂತ್ರಜ್ಞಾನದ ಬಗ್ಗೆ ಕಲಿಸುವುದು ಅವನ ನೆಚ್ಚಿನ ಕೆಲಸದ ಭಾಗವಾಗಿದೆ.",
              "te": "తోటి రైతులకు సాంకేతికత గురించి బోధించడం అతనికి ఇష్టమైన పని.",
              "or": "ଚାକିରିରେ ତାଙ୍କର ପ୍ରିୟ ଅଂଶ ହେଉଛି ସାଥୀ କୃଷକମାନଙ୍କୁ ଟେକ୍ନୋଲୋଜି\n  ବିଷୟରେ ଶିକ୍ଷା ଦେବା |",
              "as": "তাৰ চাকৰিটোৰ প্ৰিয় অংশটো হ'ল সহ কৃষকসকলক প্ৰযুক্তিৰ বিষয়ে শিকোৱা।",
              "gu": "નોકરીનો તેમનો પ્રિય ભાગ સાથી ખેડૂતોને ટેકનોલોજી વિશે શીખવવાનો છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B11_2_3",
              "hi": "B11_2_3",
              "ka": "B11_2_3",
              "te": "B11_2_3",
              "or": "B11_2_3",
              "as": "B11_2_3",
              "gu": "B11_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "His family also agrees to experiment with sensors in one part of the farm. They are waiting to see how the yield is different.",
              "hi": "उसका परिवार भी खेत के एक हिस्से में सेंसर के साथ प्रयोग करने के लिए सहमत है। वे यह देखने के लिए इंतजार कर रहे हैं कि उपज अलग-अलग कैसे है।",
              "ka": "ಜಮೀನಿನ ಒಂದು ಭಾಗದಲ್ಲಿ ಸಂವೇದಕಗಳನ್ನು ಪ್ರಯೋಗಿಸಲು ಅವರ ಕುಟುಂಬದವರು ಸಹ ಒಪ್ಪುತ್ತಾರೆ. ಇಳುವರಿ ಹೇಗೆ ಭಿನ್ನವಾಗುತ್ತದೆ ಎಂದು ಅವರು ಕಾಯುತ್ತಿದ್ದಾರೆ.",
              "te": "పొలంలోని ఒక భాగంలో సెన్సార్లతో ప్రయోగాలు చేయడానికి అతని కుటుంబం కూడా అంగీకరిస్తుంది. దిగుబడి ఎలా ఉంటుందోనని ఎదురు చూస్తున్నారు.",
              "or": "ତାଙ୍କ ପରିବାର ମଧ୍ୟ ଚାଷର ଗୋଟିଏ ଅଂଶରେ ସେନ୍ସର ସହିତ ପରୀକ୍ଷା କରିବାକୁ\n  ରାଜି ହୋଇଥିଲେ | ଅମଳରେ କିପରି ପାର୍ଥକ୍ୟ ଆସୁଛି ତାହା ଦେଖିବାକୁ ସେମାନେ\n  ଅପେକ୍ଷା କରିଛନ୍ତି |",
              "as": "তাৰ পৰিয়ালেও ফাৰ্মৰ এটা অংশত চেন্সৰৰ সৈতে পৰীক্ষা কৰিবলৈ সন্মত হৈছে। তেওঁলোকে উৎপাদন কেনেদৰে বেলেগ হয় চাবলৈ ৰৈ আছে।",
              "gu": "તેનો પરિવાર પણ ખેતરના એક ભાગમાં સેન્સરનો પ્રયોગ કરવા સંમત થાય છે. ઉપજ કેવી રીતે અલગ છે તે જોવા માટે તેઓ રાહ જોઈ રહ્યા છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B11_3_1",
              "hi": "B11_3_1",
              "ka": "B11_3_1",
              "te": "B11_3_1",
              "or": "B11_3_1",
              "as": "B11_3_1",
              "gu": "B11_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The money he earns helps Babuji with the expenses. They pay back the loan for their tractor and employ more people to help on their farm.",
              "hi": " जो धन वह कमाता है उससे वह बाबूजी की खर्च में सहायता करता है। वे अपने ट्रैक्टर के लिए ऋण वापस भुगतान करते हैं और अधिक लोगों को अपने खेत में सहायता करने के लिए काम देते हैं।",
              "ka": "ದುಡಿದ ಹಣ ಬಾಬೂಜಿಗೆ ಖರ್ಚಿಗೆ ನೆರವಾಗುತ್ತದೆ. ಅವರು ತಮ್ಮ ಟ್ರ್ಯಾಕ್ಟರ್‌ಗಾಗಿ ಮಾಡಿದ ಸಾಲವನ್ನು ಮರುಪಾವತಿಸುತ್ತಾರೆ ಮತ್ತು ತಮ್ಮ ಜಮೀನಿನಲ್ಲಿ ಸಹಾಯ ಮಾಡಲು ಹೆಚ್ಚಿನ ಜನರನ್ನು ನೇಮಿಸಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "అతను సంపాదించిన డబ్బు నాన్నగారి ఖర్చులకు సహాయం చేస్తుంది. వారు తమ ట్రాక్టర్ కోసం తీసుకున్న రుణాన్ని తిరిగి చెల్లిస్తారు మరియు వారి పొలంలో సహాయం చేయడానికి ఎక్కువ మందిని నియమించుకుంటారు.",
              "or": "ସେ ରୋଜଗାର କରୁଥିବା ଅର୍ଥ ବାବୁଜୀଙ୍କୁ ଖର୍ଚ୍ଚରେ ସାହାଯ୍ୟ କରେ | ସେମାନେ\n  ସେମାନଙ୍କର ଟ୍ରାକ୍ଟର ପାଇଁ ରୁଣ ଫେରସ୍ତ କରନ୍ତି ଏବଂ ସେମାନଙ୍କ ଚାଷରେ\n  ସାହାଯ୍ୟ କରିବାକୁ ଅଧିକ ଲୋକଙ୍କୁ ନିଯୁକ୍ତି ଦିଅନ୍ତି |",
              "as": "সি উপাৰ্জন কৰা টকাই দেউতাকক খৰচত সহায় কৰে। তেওঁলোকে তেওঁলোকৰ ট্ৰেক্টৰৰ বাবে ঋণ পৰিশোধ কৰে আৰু তেওঁলোকৰ খেতিপথাৰত সহায় কৰিবলৈ অধিক লোক নিয়োগ কৰে।",
              "gu": "તે જે પૈસા કમાય છે તે બાબુજીને ખર્ચમાં મદદ કરે છે. તેઓ તેમના ટ્રેક્ટરની લોન પરત ચૂકવે છે અને તેમના ખેતરમાં મદદ કરવા માટે વધુ લોકોને રોજગારી આપે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "Field Assistant",
              "hi": "क्षेत्रीय सहायक",
              "ka": "ಕ್ಷೇತ್ರ ಸಹಾಯಕ",
              "te": "ఫీల్డ్ సహాయకుడు",
              "or": "ଫିଲ୍ଡ ଆସିଷ୍ଟାଣ୍ଟ",
              "as": "ক্ষেত্ৰ সহায়ক",
              "gu": "ખેતર સહાયક"
            }
          }
        ]
      },
      "scene51": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Continue working part time",
              "hi": "अंशकालिक काम जारी रखे",
              "ka": "ಅರೆಕಾಲಿಕ ಕೆಲಸವನ್ನು ಮುಂದುವರಿಸುವುದು",
              "te": "పార్ట్ టైమ్ పనిని కొనసాగించడం",
              "or": "ପାର୍ଟ ଟାଇମ୍ କାମ ଜାରି ରଖ |",
              "as": "আংশিক সময় কাম অব্যাহত ৰাখক",
              "gu": "પાર્ટ ટાઇમ કામ કરવાનું ચાલુ રાખો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He decides to continue working as a Jr. Youth champion. His family needs his support to run the farm.",
              "hi": "वह जूनियर युवा चैंपियन के रूप में काम करते रहने का निर्णय करता है। उसके परिवार को खेती करने  के लिए उसके सहयोग की आवश्यकता है।",
              "ka": "ಜೂನಿಯರ್ ಯೂತ್ ಚಾಂಪಿಯನ್ ಆಗಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಮುಂದುವರಿಸಲು ಅವನು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಅವನ ಕುಟುಂಬಕ್ಕೆ ಕೃಷಿ ನಡೆಸಲು ಅವನ ಆಸರೆ ಬೇಕು.",
              "te": "అతను జూనియర్ యూత్ ఛాంపియన్‌ గా పని చేయడం కొనసాగించాలని నిర్ణయించుకున్నాడు. అతని కుటుంబానికి పొలం పనిలో అతని ఆసరా కావాలి.",
              "or": "ସେ ଏକ ଜୁନିଅର ୟୁଥ୍ ଚାମ୍ପିଅନ୍ ଭାବରେ କାର୍ଯ୍ୟ ଜାରି ରଖିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି| ଚାଷ କାର୍ଯ୍ୟ କରିବା ପାଇଁ ତାଙ୍କ ପରିବାର ତାଙ୍କ ସମର୍ଥନ ଆବଶ୍ୟକ କରନ୍ତି|",
              "as": "সি জুনিয়ৰ যুৱ চেম্পিয়ন হিচাপে কাম কৰি থকাৰ সিদ্ধান্ত লয়। তাৰ পৰিয়ালক ফাৰ্মখন চলাবলৈ তাৰ সমৰ্থনৰ প্ৰয়োজন।",
              "gu": "તેણે જુનિયર યુથ ચેમ્પિયન તરીકે કામ કરવાનું ચાલુ રાખવાનું નક્કી કર્યું. તેના પરિવારને ખેતર ચલાવવા માટે તેના ટેકાની જરૂર છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B12_1_3",
              "hi": "B12_1_3",
              "ka": "B12_1_3",
              "te": "B12_1_3",
              "or": "B12_1_3",
              "as": "B12_1_3",
              "gu": "B12_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The income from CropSense is not much, but they can use it to make some changes on the farm. He is quite busy spending time between his work at CropSense and the farm.",
              "hi": "क्रॉप्सेंस से आय बहुत ज्यादा नहीं है, लेकिन वे खेत पर कुछ बदलाव करने के लिए इसका उपयोग कर सकते हैं। वह क्रॉप्सेंस और खेत में  काम के बीच अपना समय बिताने में अत्यंत व्यस्त है।",
              "ka": "ಕ್ರಾಪ್‌ಸೆನ್ಸ್‌ನಿಂದ ಬರುವ ಆದಾಯವು ಹೆಚ್ಚು ಅಲ್ಲ, ಆದರೆ ಅವರು ಅದನ್ನು ಜಮೀನಿನಲ್ಲಿ ಕೆಲವು ಬದಲಾವಣೆಗಳನ್ನು ಮಾಡಲು ಬಳಸಬಹುದು. ಆತನು ಕ್ರಾಪ್‌ಸೆನ್ಸ್ ಮತ್ತು ಫಾರ್ಮ್‌ನಲ್ಲಿ ತನ್ನ ಕೆಲಸಗಳ ನಡುವೆ ಸಾಕಷ್ಟು ಸಮಯ ಕಳೆಯುತ್ತಿದ್ದಾನೆ.",
              "te": "క్రాప్‌సెన్స్ ద్వారా వచ్చే ఆదాయం అంతగా లేదు, కానీ వారు దానిని ఉపయోగించి పొలంలో కొన్ని మార్పులు చేసుకోవచ్చు. అతను క్రాప్‌సెన్స్ మరియు పొలంలో తన పని మధ్య చాలా బిజీగా గడిపాడు.",
              "or": "କ୍ରପ୍ସେନ୍ସରୁ ଆୟ ଅଧିକ ନୁହେଁ, କିନ୍ତୁ ସେମାନେ ଏହାକୁ ଚାଷରେ କିଛି ପରିବର୍ତ୍ତନ\n  କରିବା ପାଇଁ ବ୍ୟବହାର କରିପାରିବେ | ସେ କ୍ରପ୍ସେନ୍ସ ଏବଂ ଫାର୍ମରେ କାମ ମଧ୍ୟରେ \n ସମୟ ଅତିବାହିତ କରିବାରେ ବ୍ୟସ୍ତ |",
              "as": "ক্ৰপচেঞ্চৰ পৰা উপাৰ্জন বেছি নহয়, কিন্তু তেওঁলোকে ইয়াক ফাৰ্মত কিছু পৰিৱৰ্তন আনিবলৈ ব্যৱহাৰ কৰিব পাৰে। সি ক্ৰপচেঞ্চ আৰু ফাৰ্মত তাৰ কামত সময় কটোৱাত যথেষ্ট ব্যস্ত।",
              "gu": "ક્રોપસેન્સમાંથી આવક વધારે નથી, પરંતુ તેઓ તેનો ઉપયોગ ખેતરમાં કેટલાક ફેરફારો કરવા માટે કરી શકે છે. તે ક્રોપસેન્સ અને ફાર્મમાં તેના કામ વચ્ચે સમય પસાર કરવામાં ખૂબ વ્યસ્ત છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B12_2_1",
              "hi": "B12_2_1",
              "ka": "B12_2_1",
              "te": "B12_2_1",
              "or": "B12_2_1",
              "as": "B12_2_1",
              "gu": "B12_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He looks forward to recording the farmer interviews. He also starts documenting their work on the farm in short videos to share on WhatsApp with everyone.",
              "hi": "वह किसान के साक्षात्कार को रिकॉर्ड करने के लिए तत्पर है। वह सब के साथ व्हाट्सएप पर साझा करने के लिए छोटे वीडियो में खेत पर अपने काम को भी दस्तावेज के रूप में रखना शुरु कर देता है।",
              "ka": "ಆತನು ರೈತರ ಸಂದರ್ಶನಗಳನ್ನು ರೆಕಾರ್ಡ್ ಮಾಡಲು ಎದುರು ನೋಡುತ್ತಿದ್ದಾನೆ. ವಾಟ್ಸಾಪ್‌ನಲ್ಲಿ ಎಲ್ಲರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಲು ಅವನು ಜಮೀನಿನಲ್ಲಿ ತಣ್ಣ ಕೆಲಸವನ್ನು ಸಣ್ಣ ವೀಡಿಯೊಗಳಲ್ಲಿ ದಾಖಲಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ",
              "te": "రైతు ఇంటర్వ్యూలను రికార్డు చేసేందుకు అతను ఎదురుచూస్తున్నాడు. వాట్సాప్‌ లో అందరితో పంచుకోవడానికి పొలంలో వారి పనిని చిన్న వీడియోలలో డాక్యుమెంట్ చేయడం కూడా అతను ప్రారంభిస్తాడు.",
              "or": "ସେ କୃଷକ ସାକ୍ଷାତକାରକୁ ରେକର୍ଡିଂ କରିବାକୁ ଆଗ୍ରହୀ | ସେ କ୍ଷୁଦ୍ର ଭିଡିଓରେ\n  ଫାର୍ମରେ ସେମାନଙ୍କର କାର୍ଯ୍ୟକୁ ଡକ୍ୟୁମେଣ୍ଟ୍ କରିବା ଆରମ୍ଭ କରନ୍ତି ଓ ସମସ୍ତଙ୍କ ସହ ହ୍ଵାଟ୍ସଆପରେ ସେୟାର କରନ୍ତି।",
              "as": "সি কৃষকৰ সাক্ষাৎকাৰবোৰ ৰেকৰ্ড কৰিবলৈ ৰৈ আছে। সি সকলোৰে সৈতে হোৱাটছএপত ভাগ বতৰা কৰিবলৈ চমু ভিডিঅ'ত ফাৰ্মত সিহঁতৰ কাম নথিভুক্ত কৰিবলৈ লয়।",
              "gu": "તે ખેડૂતોના ઇન્ટરવ્યુ રેકોર્ડ કરવા આતુર છે. તે દરેક સાથે વ્હોટ્સએપ પર શેર કરવા માટે ટૂંકા વિડિયોમાં ખેતરમાં તેમના કામનું દસ્તાવેજીકરણ પણ શરૂ કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B12_2_2",
              "hi": "B12_2_2",
              "ka": "B12_2_2",
              "te": "B12_2_2",
              "or": "B12_2_2",
              "as": "B12_2_2",
              "gu": "B12_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Shikhar feels that once his family is more settled, he would like to travel to learn different farming methods used across the state.",
              "hi": "शिखर को लगता ​​है कि उसका परिवार और स्थिर हो जाए, तो वह राज्य भर में उपयोग की जाने वाली विभिन्न कृषि पद्धतियों को सीखने के लिए यात्रा करना चाहता है।",
              "ka": "ಶಿಖರ್ ತನ್ನ ಕುಟುಂಬವು ಹೆಚ್ಚು ನೆಲೆಗೊಂಡ ನಂತರ, ಆತನು ರಾಜ್ಯದಾದ್ಯಂತ ಬಳಸುವ ವಿವಿಧ ಕೃಷಿ ವಿಧಾನಗಳನ್ನು ಕಲಿಯಲು ಪ್ರಯಾಣಿಸಲು ಬಯಸುತ್ತಾನೆ",
              "te": "శిఖర్ తన కుటుంబం మరింత స్థిరపడిన తర్వాత, రాష్ట్రవ్యాప్తంగా ఉపయోగించే వివిధ వ్యవసాయ పద్ధతులను నేర్చుకోవడానికి ప్రయాణించాలనుకుంటాడు.",
              "or": "ଶିଖର ଅନୁଭବ କରନ୍ତି ଯେ ଥରେ ତାଙ୍କ ପରିବାର ଅଧିକ ସେଟେଲ ହୋଇଗଲେ,\n  ସେ ସାରା ରାଜ୍ୟରେ ବ୍ୟବହୃତ ବିଭିନ୍ନ ଚାଷ ପ୍ରଣାଳୀ ଶିଖିବାକୁ ଯାତ୍ରା କରିବାକୁ ଚାହାଁନ୍ତି |",
              "as": "শিখৰে ভাবে যে তাৰ পৰিয়াল অধিক স্থিৰ হোৱাৰ পিছত, সি ৰাজ্যজুৰি ব্যৱহৃত বিভিন্ন কৃষি পদ্ধতি শিকিবলৈ ঘূৰিব বিচাৰে।",
              "gu": "શિખરને લાગે છે કે એકવાર તેનો પરિવાર વધુ સ્થાયી થઈ જાય, પછી તે રાજ્યભરમાં વપરાતી વિવિધ ખેતી પદ્ધતિઓ શીખવા માટે મુસાફરી કરવા માંગશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "Jr. Youth Champion",
              "hi": "जूनियर युवा चैंपियन",
              "ka": "ಜೂನಿಯರ್ ಯೂತ್ ಚಾಂಪಿಯನ್",
              "te": "జూనియర్ యూత్ ఛాంపియన్",
              "or": "Jr. Youth Champion",
              "as": "জুনিয়ৰ য়ুথ চেম্পিয়ন",
              "gu": "જુનિયર યુથ ચેમ્પિયન"
            }
          }
        ]
      }
    }
  