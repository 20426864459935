import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83 5.651c0-.69.56-1.25 1.25-1.25h14.84a1.25 1.25 0 110 2.5H5.08c-.69 0-1.25-.56-1.25-1.25zm-2.283 6.85c0-.691.56-1.25 1.25-1.25h19.405a1.25 1.25 0 010 2.5H2.797c-.69 0-1.25-.56-1.25-1.25zm3.533 5.598a1.25 1.25 0 000 2.5h14.84a1.25 1.25 0 100-2.5H5.08z"
        fill="#fff"
      />
      <path
        d="M19.92 6.901v-.5.5zm-14.84 0v.5-.5zm0 11.198v-.5.5zm0 2.5v-.5.5zm14.84 0v.5-.5zm0-2.5v.5-.5zM5.08 3.901a1.75 1.75 0 00-1.75 1.75h1a.75.75 0 01.75-.75v-1zm14.84 0H5.08v1h14.84v-1zm1.75 1.75a1.75 1.75 0 00-1.75-1.75v1a.75.75 0 01.75.75h1zm-1.75 1.75a1.75 1.75 0 001.75-1.75h-1a.75.75 0 01-.75.75v1zm-14.84 0h14.84v-1H5.08v1zm-1.75-1.75c0 .967.784 1.75 1.75 1.75v-1a.75.75 0 01-.75-.75h-1zm-.533 5.1a1.75 1.75 0 00-1.75 1.75h1a.75.75 0 01.75-.75v-1zm19.405 0H2.797v1h19.405v-1zm1.75 1.75a1.75 1.75 0 00-1.75-1.75v1a.75.75 0 01.75.75h1zm-1.75 1.75a1.75 1.75 0 001.75-1.75h-1a.75.75 0 01-.75.75v1zm-19.405 0h19.405v-1H2.797v1zm-1.75-1.75c0 .966.784 1.75 1.75 1.75v-1a.75.75 0 01-.75-.75h-1zm3.283 6.848a.75.75 0 01.75-.75v-1a1.75 1.75 0 00-1.75 1.75h1zm.75.75a.75.75 0 01-.75-.75h-1c0 .966.784 1.75 1.75 1.75v-1zm14.84 0H5.08v1h14.84v-1zm.75-.75a.75.75 0 01-.75.75v1a1.75 1.75 0 001.75-1.75h-1zm-.75-.75a.75.75 0 01.75.75h1a1.75 1.75 0 00-1.75-1.75v1zm-14.84 0h14.84v-1H5.08v1z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgComponent