

export const __SGVal = (
    action,
    Resource,
    valuePath,
    value
  ) => {
    //action = 'get' or 'set'. value if not required if action is 'get'
    if (valuePath === null) return Resource;
    var ResourceRef = Resource; // a moving reference to internal objects within Resource
  
    var keysArray = valuePath.split(".");
    // var keysArray = valuePath;
    var len = keysArray.length;
    let valIsUndefined = false;
    for (var i = 0; i < len - 1; i++) {
      var key = keysArray[i];
      if (!ResourceRef[key]) {
        if (action === "set") {
          ResourceRef[key] = {};
        } else {
          //action === get
          valIsUndefined = true;
          break;
        }
      }
      ResourceRef = ResourceRef[key]; //if key is a number (as a string) the code recognizes it as an ary idx; so arrays also work with this func. !
    }
    if (valIsUndefined) return undefined; //if the loop above is broken because we didnt find anything at one of the keys, then return undefined;
  
    if (action === "set") {
      ResourceRef[keysArray[len - 1]] = value;
      return Resource;
    } else if (action === "delete") {
      delete ResourceRef[keysArray[len - 1]];
      return Resource;
    } else {
      return ResourceRef[keysArray[len - 1]];
    }
  };


  export const __GetValsFromValPaths = (d, valPath) => {
    //could be either string or array of strings.
    let val = Array.isArray(valPath)
    ? valPath.map(path => __SGVal('get', d, path))
    : __SGVal('get', d, valPath)
    return val;
  }

  export const __GetFirstValFromValPaths = (property, valuePath) => { //first path in array is prioritized if valuer is found
    let value = null;
    
    if(Array.isArray(valuePath)){
      for(var i = 0 ; i < valuePath.length ; i++){
        value = __SGVal('get', property, valuePath[i]);
        if(value) break;
      }
    }else{
      value = __SGVal('get', property, valuePath);
    }
    return value;
  }