import styled from "styled-components/macro";

export const StyledP = styled.p`
  ${({theme}) => theme.typo.p}
`;

export const StyledH5 = styled.h5`
  ${({theme}) => theme.typo.h5}
`;

export const StyledH4 = styled.h4`
  ${({theme}) => theme.typo.h4}
`;

export const StyledH6 = styled.h4`
  ${({theme}) => theme.typo.h6}
  ${({styled}) => styled && styled }
`;
