import { Fragment, useEffect, useContext } from "react";
import { IconButton, Button } from "../Button";
import {useSwipeable} from 'react-swipeable'
import {SoundContext} from '../../contexts/SoundContext'

export const LeftRightNav = ({
  disableBtnLeft = false,
  disableBtnRight = false,
  showButtonLeft = true,
  showButtonRight = true,
  onClickLeft,
  onClickRight,
  align = "center",
  buttonRightText,
  customStyle,
  broadcastSwipeHandlers,
  zIndex,
  forceActive
}) => {
  const alignConfig = {
    top: {top: "0", transform: "translateY(0)"},
    center: {top: "50%", transform: "translateY(-50%)"},
    bottom: {bottom: 0, transform: "translateY(100%)"},
  };

  const switchCardRightStyle = {
    position: "absolute",
    right: 0,
    ...alignConfig[align],
    ...(customStyle || {}),
    ...(zIndex ? {zIndex} : {})
  };

  const switchCardLeftStyle = {
    position: "absolute",
    left: 0,
    ...alignConfig[align],
    ...(customStyle || {}),
    ...(zIndex ? {zIndex} : {})
  };

  const handlers = useSwipeable({
    onSwipedLeft: onClickRight,
    onSwipedRight: onClickLeft
  })

  useEffect(() => {
    broadcastSwipeHandlers && broadcastSwipeHandlers(handlers)
  },[])

  const {triggerSoundEffect } = useContext(SoundContext)
  

  return (
    <Fragment>
      {showButtonLeft && (
        <div style={switchCardLeftStyle}>
          <IconButton
            disabled={disableBtnLeft}
            icon="ArrowLeft"
            onClick={(e) => {
              triggerSoundEffect('otherTaps')
              onClickLeft(e)
            }}
            
            forceActive={forceActive ? true : undefined}
          />
        </div>
      )}
      {showButtonRight && (
        <div style={switchCardRightStyle}>
          {buttonRightText ? 
          <Button
            disabled={disableBtnRight}
            onClick={(e) => {
              triggerSoundEffect('otherTaps')
              onClickRight(e)
            }}
            children={buttonRightText}
            style={{height: '4rem'}}
          /> 
        : <IconButton
            icon="ArrowRight"
            disabled={disableBtnRight}
            onClick={(e) => {
              triggerSoundEffect('otherTaps')
              onClickRight(e)
            }}
            forceActive={forceActive ? true : undefined}
            /> }
        </div>
      )}
    </Fragment>
  );
};
