import React, { Fragment, useContext, useState } from "react";
import { NavContext } from "../../contexts/NavContext";
import { IconButton, InventoryButton } from "../Button";
import { StyledNav, StyledNavContainer, StyledNavWrapper } from "./style";
import { MainMenu } from "../MainMenu";
import { MetricsButton } from "../MetricsButton";
import { CoachMark } from "../CoachMark";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { bubbleConfig_mainMenu_inventoryButton, bubbleConfig_mapButton_metricsButton } from "../../data/coachMarkConfigs";

export default function Nav(props) {
  const { nav } = useContext(NavContext);
  const { pathname } = useLocation();

  const Comps = {
    IconButton,
    InventoryButton,
    MainMenu,
    MetricsButton,
  };

  const [activeCoach, setActiveCoach] = useState(undefined);
  const toggleZIndexes_mainMenu_inventoryButton = ({ isActive, activeBubbleIdx }) => {
    if (isActive) {
      if (activeBubbleIdx === 0) setActiveCoach("left");
      else if (activeBubbleIdx === 1) setActiveCoach("right");
    } else setActiveCoach(undefined);
  };

  const toggleZIndexes_mapButton_metricsButton = ({ isActive, activeBubbleIdx }) => {
    if (isActive) {
      if (activeBubbleIdx === 0) setActiveCoach("metricsButton");
      else if (activeBubbleIdx === 1) setActiveCoach("mapButton");
    } else setActiveCoach(undefined);
  };
console.log({activeCoach})

  const genIndicatorButtons = () => (
    <StyledNavWrapper>
      {pathname === "/" && (
        <CoachMark
          bubbleConfig={bubbleConfig_mainMenu_inventoryButton}
          onShowCoachMarkChange={toggleZIndexes_mainMenu_inventoryButton}
          coachmarkItemName={"mainMenu_inventoryButton"}
        />
      )}
      {pathname.includes("/story") && (
        <CoachMark
          bubbleConfig={bubbleConfig_mapButton_metricsButton}
          onShowCoachMarkChange={toggleZIndexes_mapButton_metricsButton}
          coachmarkItemName={"mapButton_metricsButton"}
        />
      )}
      <StyledNav
        navLeft
        style={{ zIndex: activeCoach === "left" ? 100 : "unset" }}
        >
        {nav.left.map((d) => {
          let Comp = Comps[d.comp || "IconButton"];
          return (
            <Comp
              key={d.id}
              nav={true}
              icon={d.icon}
              to={d.to}
              onClick={d.onClick}
              links={d.links}
              {...d.props}
            />
          );
        })}
      </StyledNav>

      <StyledNav
        navRight
        style={{ zIndex: activeCoach === "right" ? 100 : "unset" }}
      >
        {nav.right.map((d) => {
          let Comp = Comps[d.comp || "IconButton"];
          return (
            <Comp
              key={d.id}
              activeCoach={activeCoach}
              nav={true}
              icon={d.icon}
              to={d.to}
              onClick={d.onClick}
              links={d.links}
              {...d.props}
            />
          );
        })}
      </StyledNav>
    </StyledNavWrapper>
  );

  return (
    <StyledNavContainer id="qagNav">{genIndicatorButtons()}</StyledNavContainer>
  );
}
