import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 18a8 8 0 100-16 8 8 0 000 16z"
        fill="#FDCB4C"
        stroke="#222"
        strokeMiterlimit={10}
      />
      <path
        d="M6 11s.674 3 4.083 3C13.493 14 14 11 14 11"
        stroke="#222"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M12 8c.333-.5 1.2-1.2 2 0m-8 .01c.333-.5 1.2-1.2 2 0"
        stroke="#222"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default SvgComponent