import { colors, breakpoints, typo, transition, base, layout } from "./general";

export const theme = {
  colors,
  breakpoints,
  typo,
  transition,
  base,
  layout
};
