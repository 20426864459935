import React, { useState, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  StyleOverlayBlackScreen,
  StyleOverlayBlackScreenWrapper,
} from "../../Theme/globalStyledComps";
import { StyledH6 } from "../blocks/Typo/style";
import { IconButton } from "../Button";
import PageContainer from "../PageContainer";
import { StyledMenuListWrapper } from "./style";
import { theme } from "../../Theme";
import styled from "styled-components";
import { X } from "phosphor-react";
import { Metrics } from "../Metrics";
import {PopUp} from '../PopUp'


export const OverlayBlackScreen = (props) => {

  return (
   
      <StyleOverlayBlackScreenWrapper onClick={props.onClick} show={props.show}>
        <StyleOverlayBlackScreen show={props.show} />
      </StyleOverlayBlackScreenWrapper>
      
  );
};
