import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.116 8.408c.487 0 .866.125 1.136.378.27.254.403.608.403 1.065v12.706c0 .457-.133.812-.403 1.065-.27.253-.649.378-1.136.378h-2.77c-.487 0-.866-.125-1.136-.378-.27-.253-.403-.608-.403-1.065v-9.822H9.54c-.488 0-.866-.125-1.136-.379-.27-.253-.404-.608-.404-1.065V9.848c0-.456.133-.811.404-1.065.27-.253.648-.378 1.136-.378h4.576v.003zM16 3.776c0 1.804-1.114 2.703-3.347 2.703-2.179 0-3.268-.902-3.268-2.703 0-1.85 1.09-2.776 3.268-2.776 1.025 0 1.841.221 2.444.666.602.445.903 1.15.903 2.11z"
        fill="#fff"
        stroke="#222"
        strokeMiterlimit={10}
      />
    </svg>
  )
}

export default SvgComponent