import { Player } from "tone";

export const musicPlayersConfig = [
  {
    id: "main",
    controls: {
      loop: true,
      mute: false,
      volume: 1,
      fadeIn: 3, //secs
      fadeOut: 3, //secs
    },
  },
  {
    id: "story",
    controls: {
      loop: true,
      mute: false,
      volume: 1,
      fadeIn: 3, //secs
      fadeOut: 3, //secs
    },
  },
  {
    id: "choice",
    controls: {
      loop: true,
      mute: false,
      volume: 1,
      fadeIn: 3, //secs
      fadeOut: 3, //secs
    },
  },
  {
    id: "threadEndBlock",
    controls: {
      loop: true,
      mute: false,
      volume: 1,
      fadeIn: 3, //secs
      fadeOut: 3, //secs
    },
  },
  {
    id: "otherUI",
    controls: {
      loop: true,
      mute: false,
      volume: 1,
      fadeIn: 3, //secs
      fadeOut: 3, //secs
    },
  },
  {
    id: "map",
    controls: {
      loop: true,
      mute: false,
      volume: 1,
      fadeIn: 3, //secs
      fadeOut: 3, //secs
    },
  },
];

export const ambiencePlayersConfig = {
  id: "ambience",
  controls: {
    loop: true,
    mute: false,
    volume: 1,
    fadeIn: 3, //secs
    fadeOut: 3, //secs
  },
}

export const soundEffectsPlayerConfig = {
  id: "soundEffects",
  controls: {
    loop: false,
    mute: false,
    volume: -5,
    fadeIn: 0, //secs
    fadeOut: 0, //secs
  },
}

// export const ambiencePlayersConfig = 


export const initSourcesRef = () => {
    let toReturn = {
      ambience : undefined,
      soundEffects : undefined
    };

    musicPlayersConfig.forEach(d => {
        toReturn[d.id] = undefined;
    })

    return toReturn;
}

export const initPlayers = () => {
    let toReturn = {
      ambience : new Player(ambiencePlayersConfig.controls).toDestination(),
      soundEffects : new Player(soundEffectsPlayerConfig.controls).toDestination()
    };

    musicPlayersConfig.forEach(d => {
        toReturn[d.id] = new Player(d.controls).toDestination();
    })

    return toReturn;
}
