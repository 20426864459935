
import { throttle } from "lodash";
import { Fragment, useLayoutEffect } from "react";
import { icons as iconImports } from "../importGroups/icons.js";

export const makeAryFromLen = (len) => Array.from(Array(len).keys());

export const toArray = (property) => {
	if (!property) return [] //return an empty array if the property is falsy.
	if (property.constructor === Array) return property
	//else
	return [property]
}

export const getOptImg = (imgUrl, webpSupported, screenWidth) => {
    // console.log({imgUrl, webpSupported, screenWidth})
    
    let format = webpSupported ? '.webp' : '.jpeg';
    

    let imgWoExt = imgUrl.split('.')
    imgWoExt.splice(-1, 1);
    let nwString = imgWoExt.join('.');
    // console.log(format, 'REPLCAE',nwString + '.' + `_960${format}`)
    switch(true){
        case screenWidth < 760 :
        return  nwString + `_960${format}`;
        case screenWidth < 990 :
        return nwString + `_1280${format}`;
        default :
        return nwString + format;
    }
    
}

export const setToLink = (to) => {
    return typeof to === 'string'
      ? { pathname : to }
      : to
  }


  export const injectValueIntoOptions = (options) =>
  options.map((option, i) => ({
    ...option,
    value: option.display?.toLowerCase().split(" ").join("") || i,
  }));

  export const insertLineBreaks = textString => {
    if(!textString) return;
    let text = !Array.isArray(textString)
    ? textString.split("\n")
    : textString;

    return text.map((d) => <Fragment>{d}<br/></Fragment>)
  }

  export const genStoryLinkList = (storyId, data, options={}) => {
    let scenes = data;
    
    let toReturn = {
      storyId : storyId,
      storyName : options.storyName,
      data : {}
    };
      /**
      * 
      * data property structure:
      *  
      * "link" : {
      *  id : //link,
      *  visited : //bool
      * active : //bool
      *  metrics : { //metrics }
      *  linkedTo : [ { id: //link, visited : //bool }  ] 
      * }
      * 
      */

    scenes.map(s => {
      s.pages.map(p => {
        let pageTo = p.to;
        let comp = p.blocks[0]?.comp;
        let endThread = p.endThread;
        let omitFromMap = p.omitFromMap;
        let nodePosConfig = p.nodePosConfig;
        //all pages always have only 1 block
        
        //get the pathname of page where story music is triggered
        if(!!p.startMusic === true){
          toReturn.startMusicPathname = `/story/${storyId}/${s.id}/${p.id}`
        }
        
        //we ignore the option links on Checklist Block since it is an exception where
        //the 'to' link is what actually matters for the progress of the story
        //besides the options.to in Checklist Block wont be mapped in the story map
        let optionTo = 
          comp !== 'ChecklistBlock' && 
          p.blocks[0]?.props?.options?.some(d => !!d.to === true) && 
          p.blocks[0].props.options.map(d => d.to);

        let propTo = p.blocks[0]?.props?.to;
        //key is the pathname of this page
        toReturn.data[`/story/${storyId}/${s.id}/${p.id}`] = {
          id: `/story/${storyId}/${s.id}/${p.id}`,
          display: p.display || p.id,
          endThread,
          comp,
          nodePosConfig,
          omitFromMap,
          visited: false,
          metrics: null,
          linkedTo: [
            ...(pageTo && !optionTo ? [{ type: 'pageTo', id: pageTo, visited: false}] : []),
            ...(optionTo ? optionTo.map(d => ({ type: 'optionTo', id: d, visited: false})) : []),
            ...(propTo && !optionTo ? [{ type: 'propTo', id: propTo, visited: false}] : [])
          ]
        }
      })
    })

    // console.log({toReturn, len : Object.values(toReturn.data).length});
    // console.log({linkedTo : Object.values(toReturn.data).map(d => d.linkedTo)});
    return toReturn;
  }

  export const toTitleCase = (word) => word[0].toUpperCase() + word.slice(1)

  export const getQueryParam = (locationSearch, query) => {
    let params = new URLSearchParams(locationSearch);
    
    return params.get(query);
  };

  export const useScroll = (scrollFn, options = {}) => {
    useLayoutEffect(() => {
      scrollFn() //run the scroll fn once before any scroll to set initial states
  
      let throttledScrollFn = throttle(scrollFn, 100)
      window.addEventListener('scroll', throttledScrollFn)
      return () => window.removeEventListener('scroll', throttledScrollFn)
    }, options.deps || [])
  }

  export const genIcon = icon => {
    let Icon = iconImports[icon];
    return <Icon/>
  }