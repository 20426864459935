
    export default {
      "scene0": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X",
              "hi": "एक्स",
              "ka": "X",
              "te": "X",
              "or": "X",
              "as": "X",
              "gu": "X"
            }
          },
          {
            "valuePath": "blocks.0.props.desc",
            "value": {
              "en": "placeholder desc",
              "hi": "प्लेसहोल्डर डेस्क ",
              "ka": "ಪ್ಲೇಸ್‌ಹೋಲ್ಡರ್ ಡೆಸ್ಕ್",
              "te": "placeholder desc",
              "or": "placeholder desc",
              "as": "placeholder desc",
              "gu": "placeholder desc"
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.0.text",
            "value": {
              "en": "In the 8th standard, Asmita is known to everyone as Arvind. She has an elder brother, Pranav, and a younger sister, Roshni.",
              "hi": " 8वीं कक्षा में, अस्मिता को हर कोई अरविंद के रूप में जानता है। उसका एक बड़ा भाई, प्रणव, और एक छोटी बहन, रोशनी है।",
              "ka": "8ನೇ ತರಗತಿಯಲ್ಲಿರುವ ಅಸ್ಮಿತಾಳು ಅರವಿಂದ್ ಎಂದೇ ಎಲ್ಲರಿಗೂ ಪರಿಚಿತ. ಅವಳಿಗೆ ಹಿರಿಯ ಸಹೋದರ ಪ್ರಣವ್ ಮತ್ತು ಕಿರಿಯ \n ಸಹೋದರಿ ರೋಶನಿ ಇದ್ದಾರೆ.",
              "te": "8వ తరగతిలో అస్మిత అందరికీ అరవింద్‌గా తెలుసు. ఆమెకు అన్నయ్య ప్రణవ్ మరియు చెల్లెలు రోష్ని ఉన్నారు.",
              "or": "ଅଷ୍ଟମ ଶ୍ରେଣୀରେ ଅସ୍ମିତା ସମସ୍ତଙ୍କୁ ଅରବିନ୍ଦ ଭାବରେ ଜଣାଶୁଣା | ତାଙ୍କର ଏକ ବଡ଼ ଭାଇ, ପ୍ରଣବ ଏବଂ ସାନ ଭଉଣୀ, ରୋଶନି ଅଛନ୍ତି |",
              "as": "অষ্টম শ্ৰেণীত, অস্মিতাক সকলোৱে অৰবিন্দ বুলি জানিছিল। তাইৰ এজন ককায়েক প্ৰণৱ আৰু এগৰাকী ভনীয়েক ৰোশনি আছে।",
              "gu": "ધોરણ-8માં અસ્મિતા એ અરવિંદ જેટલી જ દરેક જાણકારી ધરાવે છે. તેનો મોટો ભાઈ પ્રણવ, અને નાની બહેન રોશની છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.1.text",
            "value": {
              "en": "Her father is a tailor, and her mother is a flower seller. They are known to be very friendly and kind people in the neighbourhood. They live in a small town called Nalgonda near Hyderabad.",
              "hi": "उसके पिता एक दर्जी हैं, और मां फूल विक्रेता हैं। वे पड़ोस में बहुत स्नेहशील और दयालु लोगों के रूप में जाने जाते हैं। वे हैदराबाद के पास नालगोंडा नाम के एक छोटे से शहर में रहते हैं।",
              "ka": "ಆಕೆಯ ತಂದೆ ಟೈಲರ್, ಮತ್ತು ತಾಯಿ ಹೂವು ಮಾರುವವಳು. ಅವರು ನೆರೆಹೊರೆಯಲ್ಲಿ ತುಂಬಾ ಸ್ನೇಹಪರದಿಂದ ಮತ್ತು ದಯೆ ಇರುವ ಜನರು ಎಂದು ತಿಳಿದುಬಂದಿದೆ. ಅವರು ಹೈದರಾಬಾದ್ ಸಮೀಪದ ನಲ್ಗೊಂಡ ಎಂಬ ಸಣ್ಣ ಪಟ್ಟಣದಲ್ಲಿ ವಾಸಿಸುತ್ತಿದ್ದಾರೆ.",
              "te": "ఆమె తండ్రి టైలర్, మరియు ఆమె తల్లి పూలు అమ్మేది. వారు చుట్టుపక్కల వారితో చాలా స్నేహపూర్వకంగా మరియు దయతో ఉండేవారు. వారు హైదరాబాద్ సమీపంలోని నల్గొండ అనే చిన్న పట్టణంలో నివసిస్తున్నారు.",
              "or": "ତାଙ୍କ ପିତା ଜଣେ ଟେଲର୍‌, ଏବଂ ମାତା ଏକ ଫୁଲ ବିକ୍ରେତା ଅଟନ୍ତି | ସେମାନେ ଅଞ୍ଚଳରେ ବହୁତ ବନ୍ଧୁତ୍ୱପୂର୍ଣ୍ଣ ଏବଂ ଦୟାଳୁ ଲୋକ ବୋଲି ଜଣାଶୁଣା | ସେମାନେ ହାଇଦ୍ରାବାଦ ନିକଟସ୍ଥ ନାଲଗୋଣ୍ଡା ନାମକ ଏକ ଛୋଟ ସହରରେ ରୁହନ୍ତି |",
              "as": "তাইৰ দেউতাক এজন দৰ্জী, আৰু তাইৰ মাক এগৰাকী ফুল বিক্ৰী কৰা মহিলা। তেওঁলোকক চুবুৰীত বহুত বন্ধুত্বপূৰ্ণ আৰু দয়ালু লোক বুলি জনা যায়। তেওঁলোকে হায়দৰাবাদৰ ওচৰৰ নালগোণ্ডা নামৰ এখন সৰু চহৰত বাস কৰে।",
              "gu": "તેના પિતા દરજી છે અને તેણીની માતા ફુલોનું વેચાણ કરે છે. તેઓ પડોશમાં ખુબ જ મિત્રભાવ ધરાવે છે અને માયાળું લોકો છે. તેઓ હૈદરાબાદની નજીક આવેલા નાલગોંડા નામના નાના ગામમાં રહે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.2.text",
            "value": {
              "en": "She was assigned as a male gender at birth and is referred to as a boy for most of her childhood and teenage years.",
              "hi": "उसे जन्म के समय एक बालक (पुरुष लिंग) के रूप में सौंपा गया था और उसके बचपन और किशोरावस्था के अधिकांश वर्षों तक उसकी एक लड़के के रूप में चर्चा की जाती रही।",
              "ka": "ಆಕೆಯನ್ನು ಜನನದ ಸಮಯದಲ್ಲಿ ಪುರುಷ ಲಿಂಗ ಎಂದು ನಿಯೋಜಿಸಲಾಯಿತು ಮತ್ತು ಆಕೆಯ ಬಾಲ್ಯ ಮತ್ತು ಹದಿಹರೆಯದ ವರ್ಷಗಳಲ್ಲಿ ಆಕೆಯನ್ನು ಹುಡುಗ ಎಂದು ಉಲ್ಲೇಖಿಸಲಾಗುತ್ತದೆ.",
              "te": "ఆమె పుట్టినప్పుడు మగ లింగం కేటాయించబడింది మరియు ఆమె బాల్యం మరియు యుక్తవయస్సులో చాలా వరకు అబ్బాయిగానే గుర్తించబడింది.",
              "or": "ତାଙ୍କୁ ଜନ୍ମ ସମୟରେ ପୁରୁଷ ଲିଙ୍ଗ ଭାବରେ ନ୍ୟସ୍ତ କରାଯାଇଥିଲା ଏବଂ ତାଙ୍କର ଅଧିକାଂଶ ବାଲ୍ୟାବସ୍ଥା ଏବଂ କିଶୋରାବସ୍ଥାରେ ଏକ ବାଳକ ବୋଲି କୁହାଯାଏ |",
              "as": "তাইক জন্মৰ সময়ত পুৰুষ লিংগ হিচাপে চিনাক্ত কৰা হৈছিল আৰু তাইৰ শৈশৱ আৰু কৈশোৰ বয়সৰ বেছিভাগ সময় ল'ৰা হিচাপেই পাৰ কৰিছিল।",
              "gu": "તેણી જન્મના સમયે એક પુરુષ લિંગના સ્વરૂપમાં સોંપવામાં આવેલો અને તેણે પોતાનું બાળપણ અને કિશોરાવસ્થાના મોટાભાગના વર્ષો એક છોકરા તરીકે જ સંદર્ભિત કરવામાં આવે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.3.text",
            "value": {
              "en": "Asmita does not like being identified as a boy. She is usually teased by other boys as her behaviour and mannerisms feel different.",
              "hi": "अस्मिता को एक लड़के के रूप में पहचाने जाना पसंद नहीं है। उसे सामान्यतः अन्य लड़के छेड़ते हैं क्योंकि उसका व्यवहार और तरीका अलग है।",
              "ka": "ಅಸ್ಮಿತಾಳಿಗೆ ಅವಳನ್ನು ಹುಡುಗ ಎಂದು ಗುರುತಿಸುವುದು ಇಷ್ಟವಿಲ್ಲ. ಆಕೆಯ ನಡತೆ ಮತ್ತು ನಡೆನುಡಿಗಳು ವಿಭಿನ್ನವಾಗಿ ಭಾಸವಾಗುವುದರಿಂದ ಆಕೆಯನ್ನು ಸಾಮಾನ್ಯವಾಗಿ ಇತರ ಹುಡುಗರು ಗೇಲಿ ಮಾಡುತ್ತಾರೆ.",
              "te": "అబ్బాయిగా గుర్తించబడడం అస్మితకు ఇష్టం లేదు. ఆమె ప్రవర్తన మరియు వ్యవహారశైలి భిన్నంగా ఉన్నందున ఆమెను సాధారణంగా ఇతర అబ్బాయిలు ఆటపట్టించేవారు.",
              "or": "ଅସ୍ମିତା ଏକ ବାଳକ ଭାବରେ ପରିଚିତ ହେବାକୁ ପସନ୍ଦ କରନ୍ତି ନାହିଁ | ତାଙ୍କର ଆଚରଣ ଏବଂ ଭାବଭଙ୍ଗୀ ଭିନ୍ନ ଅନୁଭବ କରାଉଥିବାରୁ ସେ ସାଧାରଣତଃ ଅନ୍ୟ ପୁଅମାନଙ୍କ ଦ୍ୱାରା ଥଟ୍ଟା ହୁଅନ୍ତି |",
              "as": "অস্মিতাই নিজকে ল'ৰা হিচাপে চিনাকি দি ভাল নাপায়। তাইক সাধাৰণতে আন ল'ৰাবোৰে জোকাইছিল কিয়নো তাইৰ আচৰণ আৰু কথা কোৱাৰ ধৰণ বেলেগ যেন অনুভৱ হৈছিল।",
              "gu": "અસ્મિતાને એક છોકરા તરીકે પોતાની ઓળખ કરવામાં આવે તે પસંદ નથી. તે સામાન્ય રીતે અન્ય છોકરાઓ દ્વારા પજવવામાં આવે છે કારણ કે તેનો વ્યવહાર અને વર્તણુંક તથા પદ્ધતિ અલગ લાગે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.4.text",
            "value": {
              "en": "Most of the time, she feels confused and frustrated. She spends a lot of time with her sister, Roshni, and her friends as she identifies with their interests more than she does with her brother, Pranav.",
              "hi": "अधिकांशतः, वह भ्रमित और निराश अनुभव करती है। वह अपनी बहन, रोशनी और अपने मित्रों के साथ बहुत समय बिताती है क्योंकि वह अपने भाई प्रणव के साथ की तुलना में उनके हितों की पहचान करती है।",
              "ka": "ಹೆಚ್ಚಿನ ಸಮಯ, ಅವಳು ಗೊಂದಲ ಮತ್ತು ನಿರಾಶೆಯನ್ನು ಅನುಭವಿಸುತ್ತಾಳೆ. ಅವಳು ತನ್ನ ಸಹೋದರ ಪ್ರಣವ್‌ನೊಂದಿಗೆ ಮಾಡುವುದಕ್ಕಿಂತ ಹೆಚ್ಚಾಗಿ ತನ್ನ ಸಹೋದರಿ, ರೋಶ್ನಿ ಮತ್ತು ಅವಳ ಸ್ನೇಹಿತರೊಂದಿಗೆ ಅವರ ಆಸಕ್ತಿಗಳೊಂದಿಗೆ ಸಾಕಷ್ಟು ಸಮಯವನ್ನು ಕಳೆಯುತ್ತಾಳೆ.",
              "te": "చాలా సమయం, ఆమెకు గందరగోళంగా మరియు నిరాశగా అనిపిస్తుంది. ఆమె వారి అభిరుచులతోనే ఎక్కువ నచ్చినందున తన సోదరుడు ప్రణవ్‌తో కంటే తన సోదరి, రోష్ని మరియు ఆమె స్నేహితులతో చాలా సమయం గడుపుతుంది.",
              "or": "ଅଧିକାଂଶ ସମୟ, ସେ ଦ୍ୱନ୍ଦ୍ୱ ଏବଂ ହତାଶ ଅନୁଭବ କରନ୍ତି | ସେ ତାଙ୍କ ଭଉଣୀ, ରୋଶନି, ଏବଂ ତାଙ୍କ ସାଙ୍ଗମାନଙ୍କ ସହିତ ବହୁତ ସମୟ ବିତାଇଥାନ୍ତି ଯେହେତୁ ସେ ତାଙ୍କ ଭାଇ ପ୍ରଣବଙ୍କ ଅପେକ୍ଷା ସେମାନଙ୍କ ଇଛା ସହିତ ଅଧିକ ପରିଚିତ ଥିଲେ |",
              "as": "বেছিভাগ সময়তে, তাই বিভ্ৰান্ত আৰু হতাশ অনুভৱ কৰে। তাই তাইৰ ভনী ৰোশনি আৰু তাইৰ বন্ধুবৰ্গৰ সৈতে যথেষ্ট সময় অতিবাহিত কৰে কিয়নো তাইক তাইৰ ককায়েক প্ৰণৱৰ তুলনাত সিহঁতে বেছি ভাল পায় বুলি বুজি পায়।",
              "gu": "મોટેભાગે, તે મૂંઝવણ અને હતાશા અનુભવે છે. તે તેની બહેન, રોશની અને તેના મિત્રો સાથે ઘણો સમય વિતાવે છે કારણ કે તે તે તેની રુચિઓ પ્રણવ કરતાં વધુ સારી રીતે ઓળખે છે ."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.5.text",
            "value": {
              "en": "Her parents want their children to be independent and excel in any field they are interested in. More importantly, they want the children to grow up to be good people.",
              "hi": "उसके माता-पिता चाहते हैं कि उनके बच्चे स्वतंत्र हों और किसी भी क्षेत्र में उत्कृष्टता प्राप्त करें। सबसे महत्वपूर्ण है कि वे चाहते हैं कि बच्चे बड़े हो कर अच्छे इंसान बनें।",
              "ka": "ಆಕೆಯ ಪೋಷಕರು ತಮ್ಮ ಮಕ್ಕಳು ಸ್ವತಂತ್ರವಾಗಿರಬೇಕು ಮತ್ತು ಅವರು ಆಸಕ್ತಿ ಹೊಂದಿರುವ ಯಾವುದೇ ಕ್ಷೇತ್ರದಲ್ಲಿ ಮೇಲುಗೈ ಸಾಧಿಸಬೇಕು ಎಂದು ಬಯಸುತ್ತಾರೆ.ಇದಕ್ಕಿಂತ ಮುಖ್ಯವಾಗಿ ಮಕ್ಕಳು ಉತ್ತಮ ವ್ಯಕ್ತಿಗಳಾಗಿ ಬೆಳೆಯಬೇಕೆಂದು ಅವರು ಬಯಸುತ್ತಾರೆ.",
              "te": "ఆమె తల్లిదండ్రులు తమ పిల్లలు స్వతంత్రంగా ఉండాలని, వారికి ఆసక్తి ఉన్న ఏ రంగంలోనైనా రాణించాలని కోరుకుంటారు. మరీ ముఖ్యంగా పిల్లలు మంచి వ్యక్తులుగా ఎదగాలని కోరుకుంటారు.",
              "or": "ତାଙ୍କ ପିତାମାତା ଚାହାଁନ୍ତି ଯେ ସେମାନଙ୍କର ପିଲାମାନେ ସ୍ୱାଧୀନ ହୁଅନ୍ତୁ ଏବଂ ସେମାନେ ଇଚ୍ଛୁକ ଥିବା ଯେକୌଣସି କ୍ଷେତ୍ରରେ ଉତ୍କର୍ଷ ହୁଅନ୍ତୁ | ଅଧିକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ, ସେମାନେ ଚାହାଁନ୍ତି ପିଲାମାନେ ବଡ ହୋଇ ଭଲ ମଣିଷ ହୁଅନ୍ତୁ |",
              "as": "তাইৰ মাক-বাপেকে বিচাৰে যে তেওঁলোকৰ সন্তান স্বতন্ত্ৰ হওক আৰু তেওঁলোকে নিজৰ আগ্ৰহ থকা যিকোনো ক্ষেত্ৰত ভাল ফল দেখাওক। আটাইতকৈ গুৰুত্বপূৰ্ণ কথাটো হ'ল, তেওঁলোকে বিচাৰে যে ল'ৰা-ছোৱালী কেইটা ডাঙৰ হৈ ভাল মানুহ হওক।",
              "gu": "તેના માતાપિતા ઈચ્છે છે કે તેમનું બાળક એક સ્વતંત્ર હોય અને કોઈ પણ ક્ષેત્રમાં તે રસ ધરાવે. તેનાથી વિશેષ વાત એ છે કે તે ઈચ્છે છે કે બાળક મોટું થઈને સારી વ્યક્તિ બને."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.6.text",
            "value": {
              "en": "At home, the family members have an amicable relationship with each other. The children come home and share their day with their parents every day.",
              "hi": "घर पर, परिवार के सदस्यों का एक दूसरे के साथ एक सुखद संबंध है। बच्चे घर आते हैं और प्रतिदिन अपने माता-पिता के साथ दिनभर की बातें साझा करते हैं।",
              "ka": "ಮನೆಯಲ್ಲಿ, ಕುಟುಂಬದ ಸದಸ್ಯರು ಪರಸ್ಪರ ಸೌಹಾರ್ದಯುತ ಸಂಬಂಧವನ್ನು ಹೊಂದಿದ್ದಾರೆ. ಮಕ್ಕಳು ಮನೆಗೆ ಬಂದು ಪ್ರತಿದಿನ ತಮ್ಮ ಪೋಷಕರೊಂದಿಗೆ ತಮ್ಮ ದಿನಚರಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "ఇంట్లో కుటుంబ సభ్యులు పరస్పరం స్నేహపూర్వకంగా మెలగుతారు. పిల్లలు ఇంటికి వచ్చి ప్రతిరోజూ తల్లిదండ్రులతో తమ రోజు ఎలా గడిచిందో చెప్తారు.",
              "or": "ଘରେ, ପରିବାରର ସଦସ୍ୟମାନେ ପରସ୍ପର ସହିତ ଏକ ସୌହାର୍ଦ୍ଦ୍ୟପୂର୍ଣ୍ଣ ସମ୍ପର୍କ ରଖନ୍ତି | ପ୍ରତିଦିନ ପିଲାମାନେ ଘରକୁ ଆସନ୍ତି ଏବଂ ସେମାନଙ୍କ ପିତାମାତାଙ୍କ ସହିତ ଦିନ ଅଂଶୀଦାର କରନ୍ତି |",
              "as": "ঘৰত, পৰিয়ালৰ সদস্য কেইজনৰ ইজনৰ সিজনৰ লগত এক সুন্দৰ সম্পৰ্ক আছে। ল'ৰা- ছোৱালীবোৰ ঘৰলৈ আহে আৰু প্ৰতিদিনে তেওঁলোকৰ মাক দেউতাকৰ লগত দিনটো পাৰ কৰে।",
              "gu": "ઘરમાં પરિવારના સભ્યો વચ્ચે પરસ્પર સારા સંબંધ હોય છે. બાળકો ઘરે આવે છે અને દરેક દિવસે પોતાના માતાપિતા સાથે પોતાના દિવસને શેર કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.7.text",
            "value": {
              "en": "Asmita faces many challenges as she grows up being seen as a boy until she and people around her finally accept her as a woman.",
              "hi": "जब तक कि अस्मिता और उसके आसपास के लोग अंततः उसे एक महिला के रूप में स्वीकार नहीं कर लेते, उसे अनेक चुनौतियों का सामना करना पड़ता है क्योंकि वह बड़ी होकर भी एक लड़के के रूप में जानी जाती है ।",
              "ka": "ಅಸ್ಮಿತಾ ಮತ್ತು ಆಕೆಯ ಸುತ್ತಲಿನ ಜನರು ಅಂತಿಮವಾಗಿ ಅವಳನ್ನು ಮಹಿಳೆ ಎಂದು ಒಪ್ಪಿಕೊಳ್ಳುವವರೆಗೂ ಅವಳು ಹುಡುಗನಾಗಿ ಕಾಣುವಂತೆ ಬೆಳೆಯುವಾಗ ಅನೇಕ ಸವಾಲುಗಳನ್ನು ಎದುರಿಸುತ್ತಾಳೆ.",
              "te": "అస్మిత మరియు ఆమె చుట్టుపక్కల ఉన్నవారు చివరకు ఆమెను స్త్రీగా అంగీకరించే వరకు ఆమె అబ్బాయిలా కనిపించడం వల్ల అనేక సవాళ్లను ఎదుర్కొంటుంది.",
              "or": "ଅସ୍ମିତା ଅନେକ ଚ୍ଯାଲେଞ୍ଜର ସମ୍ମୁଖୀନ ହୁଅନ୍ତି ଯେହେତୁ ସେ ବଢୁଥିବା ବେଳେ ଏକ ବାଳକ ପରି ଦେଖାଯାନ୍ତି ଯେପର୍ଯ୍ୟନ୍ତ ସେ ଏବଂ ତାଙ୍କ ଆଖପାଖର ଲୋକମାନେ ସର୍ବଶେଷରେ ତାଙ୍କୁ ଜଣେ ମହିଳା ଭାବରେ ଗ୍ରହଣ କରନ୍ତି |",
              "as": "অস্মিতা যেতিয়া ডাঙৰ হয় তেওঁক ল'ৰা হিচাপে দেখা নাযায় আৰু তেওঁৰ চাৰিওফালৰ মানুহে তেওঁক শেষত এগৰাকী মহিলা হিচাপে গ্ৰহণ কৰালৈকে অস্মিতাই বহুতো প্ৰত্যাহ্বানৰ সন্মুখীন হ'ব লগা হয়।",
              "gu": "અસ્મિતાને ઘણા પડકારોનો સામનો કરવો પડે છે કારણ કે તે એક છોકરા તરીકે જોવામાં આવે છે જ્યાં સુધી તે અને તેની આસપાસના લોકો આખરે તેને એક સ્ત્રી તરીકે સ્વીકારે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.descTexts.8.text",
            "value": {
              "en": "In the story, we look at her journey of transition from being Arvind to becoming Asmita and how she takes decisions towards her professional dream.",
              "hi": "कहानी में, हम अरविन्द से अस्मिता बनने तक के उसके परिवर्तन के सफर को देखते हैं और देखते हैं कि कैसे वह अपने प्रोफेशनल सपने के प्रति निर्णय लेती है।",
              "ka": "ಕಥೆಯಲ್ಲಿ, ಅರವಿಂದ್‌ನಿಂದ ಅಸ್ಮಿತಾ ಆಗುವವರೆಗೆ ಅವಳ ಪರಿವರ್ತನೆಯ ಪ್ರಯಾಣವನ್ನು ಮತ್ತು ಅವಳು ತನ್ನ ವೃತ್ತಿಪರ ಕನಸಿನ ಕಡೆಗೆ ಹೇಗೆ ನಿರ್ಧಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾಳೆ ಎಂಬುದನ್ನು ನಾವು ನೋಡುತ್ತೇವೆ",
              "te": "కథలో, అరవింద్ నుండి అస్మితగా మారిన ఆమె ప్రయాణం మరియు ఆమె తన వృత్తిపరమైన కల నెరవేర్చుకోవడానికి ఎలా నిర్ణయాలు తీసుకుంటుందో చూద్దాం.",
              "or": "କାହାଣୀରେ, ଆମେ ଦେଖିବା ତାଙ୍କର ଅରବିନ୍ଦ ହେବା ଠାରୁ ଅସ୍ମିତା ହେବାର ଅବସ୍ଥାନ୍ତର ଯାତ୍ରାକୁ ଏବଂ ସେ କିପରି ଭାବରେ ତାଙ୍କର ବୃତ୍ତିଗତ ସ୍ୱପ୍ନ ଉପରେ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
              "as": "কাহিনীটোত, আমি তাইৰ অৰবিন্দৰ পৰা অস্মিতা হোৱালৈ কৰা পৰিৱৰ্তনৰ যাত্ৰা আৰু তাই কেনেদৰে তাইৰ পেছাদাৰী সপোনৰ দিশত সিদ্ধান্ত লয় সেয়া চাওঁ।",
              "gu": "વાર્તામાં, અમે અરવિંદ બનવાથી અસ્મિતા બનવા સુધીની તેણીની સંક્રમણની સફર અને તે કેવી રીતે તેના વ્યાવસાયિક સ્વપ્ન તરફ નિર્ણયો લે છે તે જુઓ."
            }
          }
        ]
      },
      "scene1": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_1",
              "hi": "X1_1_1",
              "ka": "X1_1_1",
              "te": "X1_1_1",
              "or": "X1_1_1",
              "as": "X1_1_1",
              "gu": "X1_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is walking home from school and is feeling good about his dance practice for the upcoming annual day. He is excited to tell his parents about it.",
              "hi": " आगामी वार्षिक दिवस के लिए अरविंद स्कूल से घर जा रहा है अपने नृत्य अभ्यास के बारे में अच्छा अनुभव कर रहा है। वह अपने माता-पिता को इसके बारे में बताने के लिए उत्साहित है।",
              "ka": "ಅರವಿಂದ್ ಶಾಲೆಯಿಂದ ಮನೆಗೆ ನಡೆದುಕೊಂಡು ಹೋಗುತ್ತಿದ್ದಾನೆ ಮತ್ತು ಮುಂಬರುವ ವಾರ್ಷಿಕ ದಿನದಂದು ತನ್ನ ನೃತ್ಯ ಅಭ್ಯಾಸದ ಬಗ್ಗೆ ಉತ್ತಮ ಭಾವನೆ ಹೊಂದಿದ್ದಾನೆ. ಈ ಬಗ್ಗೆ ಪೋಷಕರಿಗೆ ಹೇಳಲು ಉತ್ಸುಕನಾಗಿದ್ದಾನೆ.",
              "te": "అరవింద్ పాఠశాల నుండి ఇంటికి నడుచుకుంటూ వస్తున్నాడు మరియు రాబోయే వార్షిక దినోత్సవం కోసం తన డ్యాన్స్ ప్రాక్టీస్ విషయంలో సంతోషంగా ఉన్నాడు. ఈ విషయాన్ని తల్లిదండ్రులకు చెప్పేందుకు అతను ఉత్సాహం చూపుతున్నాడు.",
              "or": "ଅରବିନ୍ଦ ସ୍କୁଲରୁ ଘରକୁ ଯାଉଛନ୍ତି ଏବଂ ଆଗାମୀ ବାର୍ଷିକ ଦିନ ପାଇଁ ତାଙ୍କ ନୃତ୍ୟ ଅଭ୍ୟାସ ବିଷୟରେ ଭଲ ଅନୁଭବ କରୁଛନ୍ତି | ସେ ନିଜ ପିତାମାତାଙ୍କୁ ଏହା ବିଷୟରେ କହିବାକୁ ଉତ୍ସାହିତ ଅଛନ୍ତି |",
              "as": "অৰবিন্দে স্কুলৰ পৰা ঘৰলৈ খোজ কাঢ়ি আহি আছে আৰু আগন্তুক বাৰ্ষিক দিনটোৰ বাবে তেওঁৰ নাচৰ অনুশীলনৰ বিষয়ে ভাবি ভাল লাগি আছে। তেওঁ তেওঁৰ মাক দেউতাকক এই বিষয়ে ক'বলৈ উৎসাহিত হৈ আছে।",
              "gu": "અરવિંદ શાળાથી ઘરે ચાલીને જઈ રહ્યો છે અને આગામી વાર્ષિક દિવસે તેની ડાન્સ પ્રેક્ટિસ અંગે તે સારો અહેસાસ કરી રહ્યો છે. તે પોતાના માતા પિતાને આ અંગે વાત કરવા માટે ખૂબ જ ઉત્સાહીત છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_2",
              "hi": "X1_1_2",
              "ka": "X1_1_2",
              "te": "X1_1_2",
              "or": "X1_1_2",
              "as": "X1_1_2",
              "gu": "X1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Suddenly, a group of senior students from his brother's class stop him. Arvind is scared because these boys always tease him.",
              "hi": "अचानक, अपने भाई की कक्षा के सीनियर छात्रों का एक समूह उसे रोकता है। अरविंद डरा हुआ है क्योंकि ये लड़के हमेशा उसे चिढ़ाते हैं।",
              "ka": "ಇದ್ದಕ್ಕಿದ್ದಂತೆ, ಅವನ ಸಹೋದರನ ತರಗತಿಯ ಹಿರಿಯ ವಿದ್ಯಾರ್ಥಿಗಳ ಗುಂಪು ಅವನನ್ನು ತಡೆಯುತ್ತದೆ. ಈ ಹುಡುಗರು ಸದಾ ಚುಡಾಯಿಸುವುದರಿಂದ ಅರವಿಂದನಿಗೆ ಭಯವಾಗುತ್ತದೆ.",
              "te": "అకస్మాత్తుగా, అతని సోదరుడి తరగతికి చెందిన సీనియర్ విద్యార్థుల బృందం అతన్ని అడ్డుకుంటుంది. ఈ అబ్బాయిలు ఎప్పుడూ తనని ఆటపట్టించడం వల్ల అరవింద్ భయపడ్డాడు.",
              "or": "ହଠାତ୍, ତାଙ୍କ ଭାଇଙ୍କ ଶ୍ରେଣୀର ବରିଷ୍ଠ ଛାତ୍ରମାନଙ୍କର ଏକ ସମୁଦାୟ ତାଙ୍କୁ ଅଟକାଇଲେ | ଅରବିନ୍ଦ ଭୟଭୀତ ହୁଅନ୍ତି କାରଣ ଏହି ପୁଅମାନେ ତାଙ୍କୁ ସବୁବେଳେ ଥଟ୍ଟା କରନ୍ତି |",
              "as": "হঠাতে, তেওঁৰ ককায়েকৰ শ্ৰেণীৰ জ্যেষ্ঠ ছাত্ৰৰ এটা দলে তেওঁক ৰখালে। অৰবিন্দে ভয় খাইছে কাৰণ এই ল'ৰাবোৰে সদায় তাক জোকাই থাকে।",
              "gu": "ઓચિંતા જ તેના ભાઈના વર્ગખંડના વરિષ્ઠ વિદ્યાર્થીના એક જૂથે તેને અટકાવ્યો. અરવિંદ ડરવા લાગ્યો કારણ કે આ છોકરાઓ તેમને ડરાવતા હતા."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_3",
              "hi": "X1_1_3",
              "ka": "X1_1_3",
              "te": "X1_1_3",
              "or": "X1_1_3",
              "as": "X1_1_3",
              "gu": "X1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He looks around for his brother, but Pranav is nowhere to be found.",
              "hi": "वह अपने भाई के लिए चारों ओर देखता है, लेकिन प्रणव कहीं नहीं मिलता है।",
              "ka": "ಅವನು ತನ್ನ ಸಹೋದರನಿಗಾಗಿ ಸುತ್ತಲೂ ನೋಡುತ್ತಾನೆ, ಆದರೆ ಎಲ್ಲಿಯೂ ಪ್ರಣವ್ ಪತ್ತೆಯಾಗಲಿಲ್ಲ.",
              "te": "అన్న కోసం చుట్టుపక్కల వెతికాడు కానీ ప్రణవ్ కనిపించలేదు.",
              "or": "ସେ ନିଜ ଭାଇକୁ ଖୋଜନ୍ତି, କିନ୍ତୁ ପ୍ରଣବ କେଉଁଠାରେ ମିଳନ୍ତି ନାହିଁ |",
              "as": "সি তাৰ ককায়েকক চাৰিওফালে চালে, কিন্তু প্ৰণৱক কতো বিচাৰি নাপালে।",
              "gu": "તે તેના ભાઈને આજુબાજુ શોધતો હતો, પણ પ્રણવ ત્યાં ક્યાંય જોવા મળ્યો નહીં."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_4///",
              "hi": "X1_1_4 ////",
              "ka": "X1_1_4///",
              "te": "X1_1_4///",
              "or": "X1_1_4///",
              "as": "X1_1_4///",
              "gu": "X1_1_4///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "One of the seniors, Jayant, walks up to Arvind while another senior, Harish, takes out his smartphone. Arvind is looking to escape, but the others have surrounded him.",
              "hi": "सीनियर छात्रों में से एक, जयंत, अरविंद तक जाता है जबकि एक और सीनियर, हरीश, अपने स्मार्ट फोन को बाहर निकालता है। अरविंद भागना चाहता है, लेकिन दूसरों ने उसे घेर लिया है।",
              "ka": "ಸೀನಿಯರ್ಸ್ ನಲ್ಲಿ ಒಬ್ಬನಾದ ಜಯಂತ್ ಅರವಿಂದ್ ಅವರ ಬಳಿಗೆ ಹೋದನು, ಇನ್ನೊಬ್ಬ ಸೀನಿಯರ್ ಹರೀಶ್ ಅವನ ಸ್ಮಾರ್ಟ್‌ಫೋನ್ ಅನ್ನು ಹೊರಗೆ ತೆಗೆದನು. ಅರವಿಂದ್ ತಪ್ಪಿಸಿಕೊಳ್ಳಲು ನೋಡುತ್ತಿದ್ದನು, ಆದರೆ ಇತರರು ಅವನನ್ನು ಸುತ್ತುವರೆದಿದ್ದಾರೆ.",
              "te": "సీనియర్లలో ఒకరైన జయంత్ అరవింద్ దగ్గరకు వెళుతుండగా మరో సీనియర్ హరీష్ తన స్మార్ట్‌ఫోన్‌ను బయటకు తీస్తున్నాడు. అరవింద్ పారిపోవాలని చూస్తున్నాడు, కాని ఇతరులు అతనిని చుట్టుముట్టారు.",
              "or": "ସିନିୟରମାନଙ୍କ ମଧ୍ୟରୁ ଜଣେ, ଜୟନ୍ତ, ଅରବିନ୍ଦଙ୍କ ନିକଟକୁ ଯାଉଥିବାବେଳେ ଅନ୍ୟ ଜଣେ ସିନିୟର, ହରିଶ, ତାଙ୍କ ସ୍ମାର୍ଟଫୋନ୍ ବାହାର କରନ୍ତି। ଅରବିନ୍ଦ ଖସିଯିବାକୁ ଚାହାଁନ୍ତି, କିନ୍ତୁ ଅନ୍ୟମାନେ ତାଙ୍କୁ ଘେରି ରହିଛନ୍ତି।",
              "as": "তাৰে এজন জ্যেষ্ঠ ছাত্ৰ জয়ন্ত অৰবিন্দৰ ওচৰলৈ যায় আৰু আন এজন জ্যেষ্ঠ ছাত্ৰ হৰিশে তেওঁৰ স্মাৰ্টফোনটো উলিয়াই লয়। অৰবিন্দে পলাই যাব বিচাৰিছে, কিন্তু বাকীসকলে তেওঁক আগুৰি ধৰিছে।",
              "gu": "સિનિયરો પૈકી એક જયંત એ અરવિંદ પાસે જાય છે, જ્યારે અન્ય એક સિનિયર હરીશ તેનો સ્માર્ટફોન કાઢે છે. અરવિંદ ભાગી જવાની ફિરાકમાં છે, જોકે અન્ય લોકોએ તેને ઘેરી લીધો છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_1_5////",
              "hi": "X1_1_5 /////////",
              "ka": "X1_1_5////",
              "te": "X1_1_5////",
              "or": "X1_1_5////",
              "as": "X1_1_5////",
              "gu": "X1_1_5////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Harish suddenly starts playing a song on his phone, and the other's start clapping.",
              "hi": "हरीश अचानक अपने फोन पर एक गाना बजाता है, और दूसरे ताली बजाने लगते हैं।",
              "ka": "ಹರೀಶ್ ಇದ್ದಕ್ಕಿದ್ದಂತೆ ತನ್ನ ಫೋನ್‌ನಲ್ಲಿ ಹಾಡನ್ನು ಪ್ಲೇ ಮಾಡಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ, ಮತ್ತು ಇನ್ನೊಬ್ಬ ಚಪ್ಪಾಳೆ ತಟ್ಟಲು ಪ್ರಾರಂಭಿಸಿದನು.",
              "te": "హరీష్ అకస్మాత్తుగా తన ఫోన్‌లో పాటను ప్లే చేయడం ప్రారంభించాడు, మిగతావాళ్లు చప్పట్లు కొట్టడం ప్రారంభించారు.",
              "or": "ହରିଶ ହଠାତ୍ ତାଙ୍କ ଫୋନ୍‌ରେ ଏକ ଗୀତ ବଜାଇବା ଆରମ୍ଭ କରନ୍ତି, ଏବଂ ଅନ୍ୟମାନେ କରତାଳି ଆରମ୍ଭ କରନ୍ତି |",
              "as": "হৰিশে হঠাতে তেওঁৰ ফোনত এটা গান বজাবলৈ আৰম্ভ কৰিলে, আৰু বাকিবোৰে হাততালি বজাবলৈ আৰম্ভ কৰিলে।",
              "gu": "હરીશ ઓચિંતાજ તેના ફોન ઉપર ગીત વગાડવા લાગે છે અને અન્ય યુવકો તાલી પાડવા લાગે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Come on, why don't you dance for us?",
              "hi": "चलो, तुम हमारे लिए नृत्य क्यों नहीं करते?",
              "ka": "ಬನ್ನಿ, ನೀವು ನಮಗಾಗಿ ಏಕೆ ನೃತ್ಯ ಮಾಡಬಾರದು?",
              "te": "కమాన్, నువ్వు మా కోసం డ్యాన్స్ చేయవచ్చు కదా?",
              "or": "ଆସ, ତୁମେ ଆମ ପାଇଁ କାହିଁକି ନାଚୁ ନାହଁ ?",
              "as": "আহাঁ, তুমি আমাৰ বাবেও কিয় নানাচা?",
              "gu": "અરે તુ શા માટે અમારા માટે ડાન્સ કરતો નથી?"
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_2_1",
              "hi": "X1_2_1",
              "ka": "X1_2_1",
              "te": "X1_2_1",
              "or": "X1_2_1",
              "as": "X1_2_1",
              "gu": "X1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is embarrassed and scared. He is close to tears when he sees Pranav running towards them from the other side of the ground.",
              "hi": "अरविंद शर्मिंदा है और डर गया है। उसके आँसू निकलने ही वाले हैं, जब वह प्रणव को जमीन के दूसरी तरफ से दौड़ते हुए देखता है।",
              "ka": "ಅರವಿಂದನಿಗೆ ಮುಜುಗರ, ಭಯ. ಮೈದಾನದ ಇನ್ನೊಂದು ಬದಿಯಿಂದ ತಮ್ಮೆಡೆಗೆ ಓಡುತ್ತಿರುವ ಪ್ರಣವ್‌ನನ್ನು ಕಂಡು ಕಣ್ಣೀರು ಸುರಿಸುತ್ತಾನೆ.",
              "te": "అరవింద్ ఇబ్బందిగా, భయంగా ఉన్నాడు. గ్రౌండ్ అవతలి వైపు నుంచి తన వైపు పరిగెత్తుకుంటూ వస్తున్న ప్రణవ్ ని చూసి అతనికి కంట నీరు వస్తుంది.",
              "or": "ଅରବିନ୍ଦ ଲଜ୍ଜିତ ଏବଂ ଭୟଭୀତ ହୁଅନ୍ତି | ସେ କାନ୍ଦିବା ଉପରେ ଯେତେବେଳେ ସେ ପ୍ରଣବକୁ ପଡ଼ିଆର ଅନ୍ୟ ପାର୍ଶ୍ୱରୁ ସେମାନଙ୍କ ଆଡ଼୍କୁ ଦୌଡି଼ଥିବାର ଦେଖନ୍ତି |",
              "as": "অৰবিন্দে লাজ আৰু ভয় খাইছে। প্ৰণৱক অন্য দিশৰ পৰা সিহঁতৰ ফালে দৌৰি অহা দেখি তেওঁৰ চকুপানী ওলাও ওলাও হ'ল।",
              "gu": "અરવિંદ શરમાઈ ગયો અને ડરી ગયો છે.જ્યારે તે મેદાનની બીજી બાજુથી પ્રણવને ભાગતો જોવે છે ત્યારે તેની આંખમાં આસુ આવી જાય છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_2_2 bg",
              "hi": "X1_2_2 बीजी",
              "ka": "X1_2_2 ಬಿಜಿ",
              "te": "X1_2_2 bg",
              "or": "X1_2_2 bg",
              "as": "X1_2_2 bg",
              "gu": "X1_2_2 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Pranav",
              "hi": "प्रणव",
              "ka": "ಪ್ರಣವ್",
              "te": "ప్రణవ్",
              "or": "ପ୍ରଣବ",
              "as": "প্ৰণৱ",
              "gu": "પ્રણવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Bro, Jayant, it's getting late, the coach is waiting for us, let's go.",
              "hi": "भाई, जयंत, देर हो रही है, कोच हमारे लिए प्रतीक्षा कर रहे हैं, चलो चलें।",
              "ka": "ಬ್ರೋ, ಜಯಂತ್, ತಡವಾಗುತ್ತಿದೆ, ಕೋಚ್ ನಮಗಾಗಿ ಕಾಯುತ್ತಿದ್ದಾರೆ, ಹೋಗೋಣ.",
              "te": "బ్రో, జయంత్, ఆలస్యం అవుతోంది, కోచ్ మన కోసం ఎదురు చూస్తున్నాడు, వెళ్దాం.",
              "or": "ଭାଇ, ଜୟନ୍ତ, ବିଳମ୍ବ ହେଉଛି, କୋଚ୍ ଆମକୁ ଅପେକ୍ଷା କରୁଥିବେ, ଚାଲ ଯିବା |",
              "as": "ভাই, জয়ন্ত, দেৰি হৈছে, প্ৰশিক্ষক আমাৰ বাবে ৰৈ আছে, আহাঁ।",
              "gu": "\nભાઈ જયંત મોડું થઈ રહ્યું છે, કોચ અમારી રાહ જોઈ રહ્યા છે, ચાલો જઇએ"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_2_3",
              "hi": "X1_2_3",
              "ka": "X1_2_3",
              "te": "X1_2_3",
              "or": "X1_2_3",
              "as": "X1_2_3",
              "gu": "X1_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind sees the boys getting distracted and makes his escape. He runs as fast as he can until he reaches the main road.",
              "hi": "अरविंद लड़कों को विचलित होते देखता है और भाग जाता है। वह उतनी ही तेज दौड़ता है जब तक वह मुख्य सड़क तक नहीं पहुंच जाता।",
              "ka": "ಹುಡುಗರು ವಿಚಲಿತರಾಗುವುದನ್ನು ನೋಡಿ ಅರವಿಂದ್ ತಪ್ಪಿಸಿಕೊಳ್ಳುತ್ತಾರೆ. ಮುಖ್ಯರಸ್ತೆ ತಲುಪುವಷ್ಟರಲ್ಲಿ ಎಷ್ಟು ಬೇಗ ಸಾಧ್ಯವೋ ಅಷ್ಟು ವೇಗವಾಗಿ ಓಡುತ್ತಾನೆ.",
              "te": "అబ్బాయిలు పరధ్యానంలో ఉండడం చూసి అరవింద్ తప్పించుకుంటాడు. మెయిన్ రోడ్డుకు చేరేంత వరకు వేగంగా పరిగెత్తాడు.",
              "or": "ଅରବିନ୍ଦ ପୁଅମାନଙ୍କ ଧ୍ଯାନ ଅନ୍ଯତ୍ର ହେବା ଦେଖିନ୍ତି ଏବଂ ସେ ଖସି ପଳାଇଯାଆନ୍ତି | ସେ ମୁଖ୍ୟ ରାସ୍ତାରେ ପହଞ୍ଚିବା ପର୍ଯ୍ୟନ୍ତ ଯେତେ ସମ୍ଭବ ଦ୍ରୁତ ଗତିରେ ଦୌଡ଼ନ୍ତି |",
              "as": "অৰবিন্দে ল'ৰাবোৰক অন্যমনস্ক হোৱা দেখিছিল আৰু পলাই যোৱা দেখিছিল। তেওঁ মূল ৰাস্তা নোপোৱালৈকে যিমান পাৰে সিমান জোৰত দৌৰে।",
              "gu": "અરવિંદ છોકરાઓને વિચલિત થતા જુએ અને ભાગી જાય છે. તે એટલું ઝડપથી દોડે છે કે જ્યાં સુધી તે મુખ્ય માર્ગ સુધી પહોંચી ન જાય."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_2_4",
              "hi": "X1_2_4",
              "ka": "X1_2_4",
              "te": "X1_2_4",
              "or": "X1_2_4",
              "as": "X1_2_4",
              "gu": "X1_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is used to the seniors picking on him, and he feels extremely humiliated. He cries all the way home.",
              "hi": "अरविंद को सीनियर्स के द्वारा छेड़े जाने की आदत है, और वह बेहद अपमानित महसूस करता है। वह घर के रास्ते में पूरे समय रोता है।",
              "ka": "ಅರವಿಂದ್ ನನ್ನ ಸೀನಿಯರ್ಸ್ ಗಳು ಎತ್ತಿಕೊಳ್ಳಲು ಬಳಸುತ್ತಾರೆ ಮತ್ತು ಅವನು ತುಂಬಾ ಅವಮಾನಿತರಾಗಿದ್ದಾನೆ. ಅವನು ಮನೆ ತಲುಪುವವರೆಗೂ ಅಳುತ್ತಾನೆ",
              "te": "అరవింద్ ని తన సీనియర్లు ఏడిపించడం అలవాటు అయింది మరియు అతను చాలా అవమానంగా భావిస్తాడు. ఇంటికి వెళ్లేంత వరకు ఏడుస్తాడు.",
              "or": "ସିନିୟରମାନେ ତାଙ୍କୁ ବାଛୁଥିବାରୁ ଅରବିନ୍ଦ ଅଭ୍ୟସ୍ତ ଥାନ୍ତି, ଏବଂ ସେ ଅତ୍ୟଧିକ ଅପମାନିତ ଅନୁଭବ କରନ୍ତି | ସେ ଘରକୁ ଯିବା ପୁରା ରାସ୍ତା ସାରା କାନ୍ଦନ୍ତି |",
              "as": "অৰবিন্দে তেওঁক জ্যেষ্ঠসকলে কৰা অন্যায়ৰ লগত অভ্যস্ত আৰু তেওঁ বহুত অপমানিত অনুভৱ কৰে। তেওঁ ঘৰলৈ যাওতে গোটেই ৰাস্তাটো কান্দিলে।",
              "gu": "અરવિંદને સિનિયરો પસંદ કરવા માટે ટેવાયેલા છે, અને તે અત્યંત અપમાનિત અનુભવે છે. તે ઘરે જતાં આખા રસ્તે રડે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_3_1",
              "hi": "X1_3_1",
              "ka": "X1_3_1",
              "te": "X1_3_1",
              "or": "X1_3_1",
              "as": "X1_3_1",
              "gu": "X1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After his football practice, Pranav comes home, and Arvind thanks him for saving him earlier.",
              "hi": "अपने फुटबॉल अभ्यास के बाद, प्रणव घर आता है, और अरविंद पहले बचाने के लिए धन्यवाद देता है।",
              "ka": "ಫುಟ್‌ಬಾಲ್ ಅಭ್ಯಾಸದ ನಂತರ, ಪ್ರಣವ್ ಮನೆಗೆ ಬರುತ್ತಾನೆ ಮತ್ತು ಅವನನ್ನು ರಕ್ಷಿಸಿದಕ್ಕಾಗಿ ಅರವಿಂದ್ ಧನ್ಯವಾದಗಳನ್ನು ಅರ್ಪಿಸುತ್ತಾನೆ..",
              "te": "అతని ఫుట్‌బాల్ ప్రాక్టీస్ తర్వాత, ప్రణవ్ ఇంటికి వస్తాడు మరియు అరవింద్ తనని రక్షించినందుకు కృతజ్ఞతలు తెలిపాడు.",
              "or": "ତାଙ୍କର ଫୁଟବଲ୍ ଅଭ୍ୟାସ ପରେ, ପ୍ରଣବ ଘରକୁ ଆସନ୍ତି, ଏବଂ ଅରବିନ୍ଦ ତାଙ୍କୁ ପୂର୍ବରୁ ରକ୍ଷା କରିଥିବା ପାଇଁ ତାଙ୍କୁ ଧନ୍ୟବାଦ ଦିଅନ୍ତି |",
              "as": "তেওঁৰ ফুটবল অনুশীলনৰ পিছত, প্ৰণৱ ঘৰলৈ আহে, আৰু অৰবিন্দে তেওঁক তেতিয়া ৰক্ষা কৰাৰ বাবে ধন্যবাদ জনায়।",
              "gu": "\nપ્રણવ તેની ફૂટબોલ પ્રેક્ટિસ બાદ ઘરે આવે છે, અને અરવિંદ તેને પહેલા બચાવવા બદલ આભાર વ્યક્ત કરે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_3_2//",
              "hi": "X1_3_2 //",
              "ka": "X1_3_2//",
              "te": "X1_3_2//",
              "or": "X1_3_2//",
              "as": "X1_3_2//",
              "gu": "X1_3_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Pranav",
              "hi": "प्रणव",
              "ka": "ಪ್ರಣವ್",
              "te": "ప్రణవ్",
              "or": "ପ୍ରଣବ",
              "as": "প্ৰণৱ",
              "gu": "પ્રણવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ "
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Pranav",
              "hi": "प्रणव",
              "ka": "ಪ್ರಣವ್",
              "te": "ప్రణవ్",
              "or": "ପ୍ରଣବ",
              "as": "প্ৰণৱ",
              "gu": "પ્રણવ "
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I did not save you, and I was trying not to embarrass myself. How many times have I told you not to come in the way of Jayant and his gang?",
              "hi": "मैंने तुम्हें नहीं बचाया, और मैं खुद को शर्मिंदा अनुभव न कराने का प्रयास कर रहा था। मैंने तुम्हें कितनी बार कहा है कि जयंत और उसके गिरोह के रास्ते में नहीं आना?",
              "ka": "ನಾನು ನಿನ್ನನ್ನು ಉಳಿಸಲಿಲ್ಲ, ಮತ್ತು ನಾನು ಮುಜುಗರಕ್ಕೊಳಗಾಗದಿರಲು ಪ್ರಯತ್ನಿಸುತ್ತಿದ್ದೆ. ಜಯಂತ್ ಮತ್ತು ಅವನ ಗ್ಯಾಂಗ್ ದಾರಿಯಲ್ಲಿ ಬರಬೇಡ ಎಂದು ಎಷ್ಟು ಬಾರಿ ಹೇಳಿದ್ದೇನೆ?",
              "te": "నేను నిన్ను రక్షించలేదు, మరియు నేను ఇబ్బంది పడకుండా ఉండాలని ప్రయత్నిస్తున్నాను. జయంత్ మరియు అతని గ్యాంగ్ దారిలో రావద్దని నీకు ఎన్నిసార్లు చెప్పాను?",
              "or": "ମୁଁ ତୁମକୁ ବଞ୍ଚାଇ ନାହିଁ, ଏବଂ ମୁଁ ନିଜକୁ ଲଜ୍ଜିତ ନହେବାରୁ ଚେଷ୍ଟା କରୁଥିଲି | ମୁଁ ତୁମକୁ କେତେଥର ଜୟନ୍ତ ଏବଂ ତାଙ୍କ ଗ୍ୟାଙ୍ଗ୍‌ର ରାସ୍ତାରେ ନ ଆସିବାକୁ କହିଛି ?",
              "as": "মই তোমাক বচাব পৰা নাছিলো, আৰু মই নিজকে অপ্ৰস্তুত নকৰিলৈ চেষ্টা কৰি আছিলো। জয়ন্ত আৰু তেওঁৰ দলৰ বাটত নাহিবলৈ মই তোমাক কিমানবাৰ ক'ব লাগে?",
              "gu": "મે તને બચાવ્યો નથી, અને હું મારી જાતને શરમજનક સ્થિતિમાં ન મુકુ તેનો પ્રયત્ન કરતો હતો. કેટલી વખત હું તને કહું કે જયંત અને તેની ગેંગના માર્ગમાં આવીશ નહીં?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Embarrass you? I was walking home. They came up to me.",
              "hi": "तुम्हें शर्मिंदा? मैं घर जा रहा था। वे मेरे पास आए।",
              "ka": "ನಿನಗೆ ಮುಜುಗರ? ನಾನು ಮನೆಗೆ ನಡೆದುಕೊಂಡು ಹೋಗುತ್ತಿದ್ದೆ. ಅವರು ನನ್ನ ಬಳಿಗೆ ಬಂದರು.",
              "te": "మిమ్మల్ని ఇబ్బంది పెట్టానా? నేను ఇంటికి నడుస్తూ ఉన్నాను. వారు నా దగ్గరకు వచ్చారు.",
              "or": "ଲଜ୍ଜିତ ତୁମେ ? ମୁଁ ଘରକୁ ଯାଉଥିଲି | ସେମାନେ ମୋ ପାଖକୁ ଆସିଲେ |",
              "as": "আপুনি অপ্ৰস্তুত হৈছিল নেকি? মই ঘৰলৈ খোজ কাঢ়ি আহি আছিলো। তেওঁলোক মোৰ ওচৰলৈ আহিছিল।",
              "gu": "તને શરમ આવે છે? હું ઘરે જઈ રહ્યો હતો ત્યારે તેઓ મારી પાસે આવ્યા હતા."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Do you realise they were teasing you because of that stupid dance you are doing with the girls. You dance more like a girl than the girls themselves.",
              "hi": "क्या  तुमको पता है कि वे तुमको उस बेवकूफी भरे नृत्य के कारण चिढ़ा रहे थे जो तुम लड़कियों के साथ कर रहे थे। तुम लड़की बनकर उन लड़कियों से अच्छा नृत्य करते हो।",
              "ka": "ನೀವು ಹುಡುಗಿಯರೊಂದಿಗೆ ಮಾಡುತ್ತಿರುವ ಮೂರ್ಖ ನೃತ್ಯದಿಂದ ಅವರು ನಿನ್ನನ್ನು ಕೀಟಲೆ ಮಾಡುತ್ತಿದ್ದಾರೆ ಎಂದು ನಿನಗೆ ತಿಳಿದಿದೆಯೇ? ನೀವು ಹುಡುಗಿಯರಿಗಿಂತ ಹೆಚ್ಚಾಗಿ ಹುಡುಗಿಯಂತೆ ನೃತ್ಯ ಮಾಡುತ್ತಿ.",
              "te": "నువ్వు అమ్మాయిలతో చేస్తున్న ఆ స్టుపిడ్ డ్యాన్స్ కారణంగా వారు మిమ్మల్ని ఆటపట్టిస్తున్నారని నీకు తెలుసా. నువ్వు అమ్మాయిల కంటే కూడా ఎక్కువగా అమ్మాయిలా డ్యాన్స్ చేస్తావు.",
              "or": "ତୁମେ ଜାଣିପାରୁଛକି ତୁମେ ସେହି ଝିଅମାନଙ୍କ ସହିତ କରୁଥିବା ସେହି ମୂର୍ଖ ନୃତ୍ୟ ହେତୁ ସେମାନେ ତୁମକୁ ଥଟ୍ଟା କରୁଥିଲେ | ତୁମେ ନିଜେ ଝିଅମାନଙ୍କ ଅପେକ୍ଷା ଏକ ଝିଅ ପରି ଅଧିକ ନାଚୁଥିଲ |",
              "as": "তুমি বুজি পাইছানে যে তুমি ছোৱালীবোৰৰ সৈতে কৰি থকা সেই মূৰ্খ নাচৰ বাবে তেওঁলোকে তোমাক জোকাই আছিল। তুমি ছোৱালীবোৰতকৈও ছোৱালীৰ দৰে বেছি ভালকৈ নাচিছিলা।",
              "gu": "શું તને એ વાતનો અહેસાસ છે કે છોકરીઓ સાથે તું જે ડાન્સ કરી રહ્યો છે તેને કારણે તેઓ ચિડાઈ ગયા હતા. તું પોતે છોકરીઓથી વિશેષ છોકરીની માફક ડાન્સ કરે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_3_3_d",
              "hi": "X1_3_3_d",
              "ka": "X1_3_3_d",
              "te": "X1_3_3_d",
              "or": "X1_3_3_d",
              "as": "X1_3_3_d",
              "gu": "X1_3_3_d"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Arvind say?",
              "hi": "अरविंद को क्या कहना चाहिए?",
              "ka": "ಅರವಿಂದ್ ಏನು ಹೇಳಬೇಕು?",
              "te": "అరవింద్ ఏం చెప్పాలి?",
              "or": "ଅରବିନ୍ଦ କ’ଣ କହିବା ଉଚିତ୍ ?",
              "as": "অৰবিন্দে কি কোৱা উচিত?",
              "gu": "અરવિંદ તારે શું કહેવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "What is the problem with me dancing? Is there some rule that says I can’t dance?",
              "hi": "मुझे नाचने में क्या समस्या है? क्या कोई नियम है जो कहता है कि मैं नृत्य नहीं कर सकता?",
              "ka": "ನನಗೆ ನೃತ್ಯ ಮಾಡಲು ಏನು ತೊಂದರೆ? ನಾನು ನೃತ್ಯ ಮಾಡಲಾರೆ ಎಂದು ಹೇಳುವ ನಿಯಮವಿದೆಯೇ?",
              "te": "నేను డ్యాన్స్ చేయడంలో సమస్య ఏమిటి? నేను డ్యాన్స్ చేయద్దు అని ఏదైనా రూల్ ఉందా?",
              "or": "ମୋ ନାଚିବାରେ ଅସୁବିଧା କ’ଣ ଅଛି ? ଏପରି କିଛି ନିୟମ ଅଛି କି ଯାହା କହିଥାଏ ମୁଁ ନୃତ୍ୟ କରିପାରିବି ନାହିଁ ?",
              "as": "মোৰ নচাত বাৰু কি সমস্যা আছে? মই নাচিব নোৱাৰো বুলি কোৱা কোনো নিয়ম আছে নেকি?",
              "gu": "મને ડાન્સ કરવામાં શું તકલીફ છે? શું કોઈ નિયમ છે કે જે કહેતો હોય કે હું ડાન્સ કરી શકતો નથી?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Why can’t I do what I like to do? Have I ever told you what to do?",
              "hi": "मैं ऐसा कुछ क्यों नहीं कर सकता जो मैं करना चाहता हूं? क्या मैंने कभी आपको बताया है कि क्या करना है?",
              "ka": "ನಾನು ಇಷ್ಟಪಡುವದನ್ನು ನಾನು ಏಕೆ ಮಾಡಬಾರದು? ಏನು ಮಾಡಬೇಕೆಂದು ನಾನು ನಿನಗೆ ಎಂದಾದರೂ ಹೇಳಿದ್ದೇನೆಯೇ?",
              "te": "నేను చేయాలనుకున్నది నేను ఎందుకు చేయకూడదు? మీరు ఏమి చేయాలో నేను మీకు ఎప్పుడైనా చెప్పానా?",
              "or": "ମୁଁ ଯାହା କରିବାକୁ ପସନ୍ଦ କରେ ମୁଁ କାହିଁକି କରିପାରିବି ନାହିଁ? ମୁଁ କ’ଣ କରିବି ତୁମକୁ କେବେ କହିଛି କି ?",
              "as": "মই যি কৰিব বিচাৰো সেয়া মই কিয় কৰিব নোৱাৰো? মই কেতিয়াবা আপোনাক কি কৰিব লাগে কৈছো নেকি?",
              "gu": "હું એ શા માટે ન કરી શકું કે જે મને પસંદ છે?શું મે ક્યારેય કહ્યું કે શું કરવાનું છે?"
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_3_4///",
              "hi": "X1_3_4 ///",
              "ka": "X1_3_4///",
              "te": "X1_3_4///",
              "or": "X1_3_4///",
              "as": "X1_3_4///",
              "gu": "X1_3_4///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "You do whatever you want to do, but don't be an embarrassment for me. Why can't you act normal?",
              "hi": "तुम जो भी करना चाहते हो, करो, लेकिन मेरे लिए शर्मिंदगी मत करो। तुम सामान्य क्यों नहीं रह सकते?",
              "ka": "ನೀನು ಏನು ಬೇಕಾದರೂ ಮಾಡು, ಆದರೆ ನನಗೆ ಮುಜುಗರವಾಗಬೇಡ. ನೀವು ಏಕೆ ಸಾಮಾನ್ಯವಾಗಿ ವರ್ತಿಸಬಾರದು?",
              "te": "నువ్వేం చేయాలనుకున్నావో చేస్కో కానీ నాకు ఇబ్బందిగా ఉండకు. నువ్వు మామూలుగా ఎందుకు ఉండలేవు?",
              "or": "ତୁମେ ଯାହା କରିବାକୁ ଚାହୁଁଛ ତାହା କର, କିନ୍ତୁ ମୋ ପାଇଁ ଲଜ୍ଜାଜନକ ହୁଅ ନାହିଁ | ତୁମେ ସାଧାରଣ ଭାବରେ କାର୍ଯ୍ୟ କାହିଁକି କରିପାର ନାହିଁ ?",
              "as": "তুমি যি কৰিব বিচাৰা সেয়াই কৰা, কিন্তু মই তাৰ বাবে যাতে অস্বস্তিকৰ পৰিস্থিতিত পৰিবলগীয়া নহয়। তুমি স্বাভাৱিক কাম কিয় কৰিব নোৱাৰা?",
              "gu": "તને જે ગમે તે તું કર, પણ મારા માટે શરમજનક ન બન. તું સામાન્ય વ્યવહાર શા માટે કરતો નથી?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Pranav! Mind your words and apologise to Arvind.",
              "hi": "प्रणव! अपने शब्दों का ध्यान रखो और अरविंद से क्षमा मांगो।",
              "ka": "ಪ್ರಣವ್! ನಿನ್ನ ಮಾತುಗಳನ್ನು ಗಮನದಲ್ಲಿಟ್ಟುಕೊಂಡು ಅರವಿಂದ್ ನ ಹತ್ತಿರ ಕ್ಷಮೆಯಾಚಿಸು.",
              "te": "ప్రణవ్! మాటలు జాగ్రత్త, అరవింద్‌కి క్షమాపణలు చెప్పు.",
              "or": "ପ୍ରଣବ ! ତୁମ ଶବ୍ଦଗୁଡିକୁ ଧ୍ୟାନ ଦିଅ ଏବଂ ଅରବିନ୍ଦଙ୍କୁ କ୍ଷମା ମାଗ |",
              "as": "প্ৰণৱ! মুখত যি আহে তাকে নকবা আৰু অৰবিন্দৰ ওচৰত ক্ষমা বিচাৰা।",
              "gu": "પ્રણવ! તારા શબ્દો ઉપર ધ્યાન આપ અને અરવિંદની માફી માગ"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "But Amma!",
              "hi": "लेकिन अम्मा!",
              "ka": "ಆದರೆ ಅಮ್ಮಾ!",
              "te": "కానీ అమ్మా!",
              "or": "କିନ୍ତୁ ଆମ୍ମା !",
              "as": "কিন্তু আম্মা!",
              "gu": "પણ અમ્મા!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Let Arvind be. Since you were children, I have told all three, Respect everyone even if they are different from you\"\"",
              "hi": "अरविंद रहने दो। जब तुम सब बच्चे थे, तब से मैंने तीनों से कहा है, सबका सम्मान करो, भले ही वे तुमसे अलग हों\"\"",
              "ka": "ಅರವಿಂದ್ ಇರಲಿ. ನೀವು ಚಿಕ್ಕವರಾಗಿದ್ದಾಗಿನಿಂದ ನಾನು ಮೂವರಿಗೂ ಹೇಳಿದ್ದೇನೆ, ಅವನು ನಿಮ್ಮಿಂದ ಭಿನ್ನರಾಗಿದ್ದರೂ ಎಲ್ಲರನ್ನೂ ಗೌರವಿಸಿ.",
              "te": "అరవింద్ ని అలా ఉండనివ్వు. మీ చిన్నప్పటి నుండి, నేను మీ ముగ్గురికీ చెప్పాను, వారు మీలాగా లేకున్నా కూడా ప్రతి ఒక్కరినీ గౌరవించండి\"\"",
              "or": "ଅରବିନ୍ଦ ଥାଉ | ଯେବେ ତୁମେ ପିଲା ଥିଲ, ମୁଁ ତିନିଜଣଙ୍କୁ କହିଛି, ସମସ୍ତଙ୍କୁ ସମ୍ମାନ ଦିଅ ଯଦିଓ ସେମାନେ ତୁମଠାରୁ ଭିନ୍ନ ଅଟନ୍ତି\"",
              "as": "অৰবিন্দক তেনেদৰেই থাকিব দিয়া। তোমালোক সৰুহৈ থকাৰ পৰাই, মই তিনিওটাকে কৈছিলো, সকলোকে সন্মান কৰিবা আনকি কোনোবাজন তোমালোকৰ পৰা পৃথক হ'লেও\"\"",
              "gu": "અરવિંદ રહેવા દે. જ્યારથી તુ બાળક હતો, મે ત્રણેયને કહ્યું છે, સૌનું સન્માન કરો,પછી ભલે તે અલગ હોય\"\""
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_4_1 bg",
              "hi": "X1_4_1 बीजी",
              "ka": "X1_4_1 ಬಿಜಿ",
              "te": "X1_4_1 bg",
              "or": "X1_4_1 bg",
              "as": "X1_4_1 bg",
              "gu": "X1_4_1 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Mother",
              "hi": "मां",
              "ka": "ತಾಯಿ",
              "te": "అమ్మ",
              "or": "ମାତା",
              "as": "মাক",
              "gu": "માતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશનિ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Arvind, don't take Pranav's words seriously. Roshni said your dance performance was excellent. Tell me about it.",
              "hi": "अरविंद, प्रणव के शब्दों को गंभीरता से मत लो। रोशनी ने बताया कि तुम्हारा नृत्य प्रदर्शन उत्कृष्ट था। मुझे इसके बारे में बताओ।",
              "ka": "ಅರವಿಂದ್, ಪ್ರಣವ್ ಮಾತನ್ನು ಗಂಭೀರವಾಗಿ ತೆಗೆದುಕೊಳ್ಳಬೇಡ. ನಿನ್ನ ನೃತ್ಯ ಪ್ರದರ್ಶನ ಅದ್ಭುತವಾಗಿದೆ ಎಂದು ರೋಶನಿ ಹೇಳಿದಳು. ನನಗೆ ಅದರ ಬಗ್ಗೆ ಹೇಳು.",
              "te": "ప్రణవ్ మాటలను సీరియస్ గా తీసుకోకు అరవింద్. నీ డ్యాన్స్ పెర్ఫార్మెన్స్ అద్భుతంగా ఉందని రోష్ని చెప్పింది. దాని గురించి నాకు చెప్పు.",
              "or": "ଅରବିନ୍ଦ, ପ୍ରଣବଙ୍କ କଥାକୁ ଗମ୍ଭୀରତାର ସହ ନିଅ ନାହିଁ | ରୋଶନି କହୁଥିଲା ତୁମର ନୃତ୍ୟ ପରିବେଷଣ ବହୁତ ଭଲ ଥିଲା | ମୋତେ ଏହା ବିଷୟରେ କୁହ |",
              "as": "অৰবিন্দ, প্ৰণৱৰ কথাবোৰ গুৰুত্বসহকাৰে নলবা। ৰোশনিয়ে কৈছিল যে তুমি বহুত ধুনীয়াকৈ নাচিছিলা। মোক ইয়াৰ বিষয়ে কোৱা।",
              "gu": "અરવિંદ પ્રણવની વાતોને ગંભીરતાથી ન લે. રોશનીએ કહ્યું કે તારું ડાન્સ પર્ફોમન્સ ખૂબ જ સારું હતું. મને તેના વિશે કહે"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Amma, I am telling you, Arvind danced just like Madhuri Dixit.",
              "hi": "अम्मा, मैं आपको बता रही हूं, अरविंद ने माधुरी दीक्षित की तरह नृत्य किया।",
              "ka": "ಅಮ್ಮಾ, ನಾನು ಹೇಳುತ್ತಿದ್ದೇನೆ, ಅರವಿಂದ್ ಮಾಧುರಿ ದೀಕ್ಷಿತ್ ಅವರಂತೆಯೇ ನೃತ್ಯ ಮಾಡಿದನು.",
              "te": "అమ్మా, నేను చెప్తున్నా కదా, మాధురీ దీక్షిత్ లాగే అరవింద్ డ్యాన్స్ చేసాడు.",
              "or": "ଆମ୍ମା, ମୁଁ ଆପଣଙ୍କୁ କହୁଛି, ଅରବିନ୍ଦ ମାଧୁରୀ ଦୀକ୍ଷିତଙ୍କ ପରି ନାଚିଲେ |",
              "as": "আম্মা, মই আপোনাক কৈছো, অৰবিন্দে মাধুৰী দীক্ষিতৰ দৰেই নাচিছিল।",
              "gu": "અમ્મા, હું તમને કહું છું, અરવિંદે માધુરી દીક્ષિતની જેમ જ ડાન્સ કર્યો હતો"
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_4_2",
              "hi": "X1_4_2",
              "ka": "X1_4_2",
              "te": "X1_4_2",
              "or": "X1_4_2",
              "as": "X1_4_2",
              "gu": "X1_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind likes to study and is a bright student. He is not very participative in class discussions but is very good at understanding complex topics.",
              "hi": "अरविंद को अध्ययन करना पसंद है और वह एक मेधावी छात्र है। कक्षा चर्चाओं में उसकी सहभागिता अधिक नहीं है लेकिन जटिल विषयों को समझने में वह बहुत अच्छा है।",
              "ka": "ಅರವಿಂದ್ ಓದಲು ಇಷ್ಟಪಡುತ್ತಾನೆ ಮತ್ತು ಬುದ್ದಿವಂತ ವಿದ್ಯಾರ್ಥಿಯಾಗಿದ್ದಾನೆ. ಅವನು ತರಗತಿ ಚರ್ಚೆಗಳಲ್ಲಿ ಹೆಚ್ಚು ಭಾಗವಹಿಸುವುದಿಲ್ಲ ಆದರೆ ಸಂಕೀರ್ಣ ವಿಷಯಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವಲ್ಲಿ ಉತ್ತಮನು.",
              "te": "అరవింద్‌కి చదువు అంటే ఇష్టం మరియు తెలివైన విద్యార్థి. క్లాస్ డిస్కషన్స్‌లో అంతగా పాల్గొనకపోయినా, క్లిష్ట విషయాలను అర్థం చేసుకోవడంలో దిట్ట.",
              "or": "ଅରବିନ୍ଦ ଅଧ୍ୟୟନ କରିବାକୁ ପସନ୍ଦ କରନ୍ତି ଏବଂ ଜଣେ ଉଜ୍ଜ୍ୱଳ ଛାତ୍ର ଅଟନ୍ତି | ସେ କ୍ଲାସ୍‌ ଆଲୋଚନାଗୁଡି଼କରେ ବେଶୀ ଅଂଶଗ୍ରହଣ କରନ୍ତି ନାହିଁ କିନ୍ତୁ ଜଟିଳ ବିଷୟ ବୁଝିବାରେ ବହୁତ ଭଲ |",
              "as": "অৰবিন্দে পঢ়িবলৈ ভাল পায় আৰু এজন উজ্জ্বল শিক্ষাৰ্থী। তেওঁ শ্ৰেণী আলোচনাত ভাগ লোৱাত সিমান ভাল নহয় কিন্তু জটিল বিষয়বোৰ বুজিপোৱাত খুব ভাল।",
              "gu": "અરવિંદને અભ્યાસ પસંદ છે અને તે એક હોશિયાર વિદ્યાર્થી છે. તે વર્ગખંડમાં ચર્ચામાં ઘણો ભાગ લઈ શકતો નથી, પણ જટિલ વિષયોને સમજવામાં ઘણો સારો છે"
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_4_3",
              "hi": "X1_4_3",
              "ka": "X1_4_3",
              "te": "X1_4_3",
              "or": "X1_4_3",
              "as": "X1_4_3",
              "gu": "X1_4_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind's parents encourage their children to participate in all the activities in school as they want them to become all-rounders.",
              "hi": "अरविंद के माता-पिता अपने बच्चों को स्कूल में सभी गतिविधियों में भाग लेने के लिए प्रोत्साहित करते हैं क्योंकि वे चाहते हैं कि  बच्चों का सर्वांगीण विकास हो।",
              "ka": "ಅರವಿಂದನ ಪೋಷಕರು ತಮ್ಮ ಮಕ್ಕಳನ್ನು ಶಾಲೆಯ ಎಲ್ಲಾ ಚಟುವಟಿಕೆಗಳಲ್ಲಿ ಭಾಗವಹಿಸಲು ಪ್ರೋತ್ಸಾಹಿಸುತ್ತಾರೆ ಏಕೆಂದರೆ ಅವರು ಆಲ್ ರೌಂಡರ್ ಆಗಬೇಕೆಂದು ಬಯಸುತ್ತಾರೆ.",
              "te": "అరవింద్ తల్లిదండ్రులు తమ పిల్లలను ఆల్ రౌండర్లుగా తీర్చిదిద్దాలని పాఠశాలలో జరిగే అన్ని కార్యక్రమాల్లో పాల్గొనేలా ప్రోత్సహిస్తున్నారు.",
              "or": "ଅରବିନ୍ଦଙ୍କ ପିତାମାତା ସେମାନଙ୍କ ପିଲାମାନଙ୍କୁ ବିଦ୍ୟାଳୟର ସମସ୍ତ କାର୍ଯ୍ୟକଳାପରେ ଅଂଶଗ୍ରହଣ କରିବାକୁ ଉତ୍ସାହିତ କରନ୍ତି ଯେହେତୁ ସେ ସେମାନଙ୍କୁ ଅଲରାଉଣ୍ଡର ହେବାକୁ ଚାହାଁନ୍ତି |",
              "as": "অৰবিন্দৰ পিতৃ-মাতৃয়ে তেওঁলোকৰ সন্তানক বিদ্যালয়ৰ সকলো কাৰ্যকলাপত অংশগ্ৰহণ কৰিবলৈ উৎসাহিত কৰে কিয়নো তেওঁলোকে সিহঁত অলৰাউণ্ডাৰ হোৱাটো বিচাৰে।",
              "gu": "અરવિંદના માતા-પિતા તેમના બાળકોને શાળામાં તમામ પ્રવૃત્તિઓમાં ભાગ લેવા પ્રોત્સાહન આપે છે, કારણ કે તેઓ ઈચ્છે છે કે ઓલ-રાઉન્ડર બને."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_5_1",
              "hi": "X1_5_1",
              "ka": "X1_5_1",
              "te": "X1_5_1",
              "or": "X1_5_1",
              "as": "X1_5_1",
              "gu": "X1_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Although Arvind likes sports, the cricket and football team boys usually tease him.",
              "hi": "यद्यपि अरविंद को खेल पसंद है,लेकिन क्रिकेट और फुटबॉल टीम के लड़के प्रायः उसे चिढ़ाते हैं।",
              "ka": "ಅರವಿಂದ್‌ಗೆ ಕ್ರೀಡೆ ಎಂದರೆ ಇಷ್ಟವಾದರೂ ಕ್ರಿಕೆಟ್‌ ಮತ್ತು ಫುಟ್‌ಬಾಲ್‌ ತಂಡದ ಹುಡುಗರು ಅವನನ್ನು ಚುಡಾಯಿಸುತ್ತಾರೆ.",
              "te": "అరవింద్‌కి స్పోర్ట్స్ అంటే ఇష్టం అయినప్పటికీ క్రికెట్, ఫుట్‌బాల్ టీమ్ అబ్బాయిలు అతన్ని ఆటపట్టించేవారు.",
              "or": "ଯଦିଓ ଅରବିନ୍ଦ କ୍ରୀଡା ପସନ୍ଦ କରନ୍ତି, କ୍ରିକେଟ୍ ଏବଂ ଫୁଟବଲ୍ ଦଳର ବାଳକମାନେ ତାଙ୍କୁ ସାଧାରଣତଃ ଥଟ୍ଟା କରନ୍ତି |",
              "as": "যদিও অৰবিন্দে খেল ভাল পায়, ক্ৰিকেট আৰু ফুটবল দলৰ ল'ৰাবোৰে সাধাৰণতে তেওঁক জোকাই থাকে।",
              "gu": "જોકે, અરવિંદને રમવાનું પસંદ છે, પણ ક્રિકેટ અને ફુટબોલ ટીમના  છોકરાઓ સામાન્ય રીતે ચિડાઈ જાય છે"
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_5_2",
              "hi": "X1_5_2",
              "ka": "X1_5_2",
              "te": "X1_5_2",
              "or": "X1_5_2",
              "as": "X1_5_2",
              "gu": "X1_5_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He likes participating in performing arts like dance, music, drama, and more. The other students enjoy performing with Arvind due to his energy and enthusiasm.",
              "hi": "वह नृत्य, संगीत, नाटक और अन्य प्रदर्शन कलाओं में भाग लेना अधिक पसंद करता है। अन्य छात्रों को अरविंद की शक्ति और उत्साह के कारण उसके साथ प्रदर्शन करने में आनंद आता है।",
              "ka": "ಅವನು ನೃತ್ಯ, ಸಂಗೀತ, ನಾಟಕ ಮತ್ತು ಹೆಚ್ಚಿನ ಪ್ರದರ್ಶನ ಕಲೆಗಳಲ್ಲಿ ಭಾಗವಹಿಸಲು ಇಷ್ಟಪಡುತ್ತಾನೆ. ಅರವಿಂದನ ಶಕ್ತಿ ಮತ್ತು ಉತ್ಸಾಹದಿಂದ ಇತರ ವಿದ್ಯಾರ್ಥಿಗಳು ಅವನೊಂದಿಗೆ ಪ್ರದರ್ಶನವನ್ನು ಆನಂದಿಸುತ್ತಾರೆ.",
              "te": "అతను డ్యాన్స్, సంగీతం, నాటకం మరియు మరిన్ని కళలలో పాల్గొనడానికి ఇష్టపడతాడు. అతని శక్తి మరియు ఉత్సాహం కారణంగా ఇతర విద్యార్థులు అరవింద్‌తో కలిసి ప్రదర్శించడం ఆనందిస్తారు.",
              "or": "ସେ କଳା ପରିବେଷଣ ଯଥା ନୃତ୍ୟ, ସଙ୍ଗୀତ, ନାଟକ ଏବଂ ଅନ୍ୟାନ୍ୟ ରେ ଅଂଶଗ୍ରହଣ କରିବାକୁ ପସନ୍ଦ କରନ୍ତି | ଅନ୍ୟ ଛାତ୍ରମାନେ ତାଙ୍କ ଶକ୍ତି ଏବଂ ଉତ୍ସାହ ହେତୁ ଅରବିନ୍ଦଙ୍କ ସହିତ ପରିବେଷଣ କରିବାକୁ ଉପଭୋଗ କରନ୍ତି |",
              "as": "তেওঁ নৃত্য, সংগীত, নাটক আদিৰ দৰে প্ৰদৰ্শনমূলক কলাত ভাগ লবলৈ ভাল পায়। আন শিক্ষাৰ্থীসকলে তেওঁৰ শক্তি আৰু উৎসাহৰ বাবে অৰবিন্দৰ সৈতে প্ৰদৰ্শন কৰি ভাল পায়।",
              "gu": "તે ડાન્સ, સંગીત, નાટક અને અન્ય પર્ફોમિંગ કલામાં ભાગ લેવાનું પસંદ કરે છે. અન્ય વિદ્યાર્થીઓને અરવિંદની ઉર્જા અને ઉત્સાહને લીધે તેમની સાથે પ્રદર્શન કરવામાં મજા આવે છે."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "X1_5_3",
              "hi": "X1_5_3",
              "ka": "X1_5_3",
              "te": "X1_5_3",
              "or": "X1_5_3",
              "as": "X1_5_3",
              "gu": "X1_5_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Although he doesn't have many friends in class, he feels safe and accepted in the performing arts club.",
              "hi": "हालाँकि कक्षा में उसके अधिक मित्र नहीं हैं, लेकिन वह प्रदर्शन कला क्लब में सुरक्षित और स्वीकृत अनुभव करता है।",
              "ka": "ಅವನು ತರಗತಿಯಲ್ಲಿ ಹೆಚ್ಚು ಸ್ನೇಹಿತರನ್ನು ಹೊಂದಿಲ್ಲದಿದ್ದರೂ, ಅವನು ಸುರಕ್ಷಿತ ಎಂದು ಭಾವಿಸುತ್ತಾನೆ ಮತ್ತು ಪ್ರದರ್ಶನ ಕಲೆಗಳ ಕ್ಲಬ್‌ನಲ್ಲಿ ಅವನಿಗೆ ಅಂಗೀಕಾರವಿದೆ.",
              "te": "అతనికి తరగతిలో ఎక్కువ మంది స్నేహితులు లేనప్పటికీ, అతను సేఫ్ గా ఫీల్ అవుతాడు మరియు పెర్ఫార్మింగ్ ఆర్ట్స్ క్లబ్‌లో అంగీకరించబడ్డాడు.",
              "or": "ଯଦିଓ କ୍ଲାସ୍‌ରେ ତାଙ୍କର ଅନେକ ସାଙ୍ଗ ନାହାଁନ୍ତି, ସେ କଳା ପରିବେଷଣ କ୍ଳବ୍‌ରେ ନିଜକୁ ନିରାପଦ ଏବଂ ଗ୍ରହଣୀୟ ଅନୁଭବ କରନ୍ତି |",
              "as": "যদিও শ্ৰেণীত তেওঁৰ বেছি বন্ধু নাই, তেওঁ প্ৰদৰ্শন কলা ক্লাবত সুৰক্ষিত অনুভৱ কৰে আৰু ইয়াক গ্ৰহণযোগ্য বুলি ভাবে।",
              "gu": "જોકે વર્ગખંડમાં તેના અનેક મિત્ર નથી. જોકે તે પર્ફોમન્સ કલા ક્લબમાં સુરક્ષિત અને જોડાયેલ અહેસાસ કરે છે."
            }
          }
        ]
      },
      "scene2": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_1_1",
              "hi": "X2_1_1",
              "ka": "X2_1_1",
              "te": "X2_1_1",
              "or": "X2_1_1",
              "as": "X2_1_1",
              "gu": "X2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As Arvind moves into his teenage years, he begins feeling restless. He senses his body going through different changes and is unsure how to express himself to his family.",
              "hi": "जैसे ही अरविन्द अपनी किशोरावस्था में प्रवेश करता है, वह बेचैन होने लगता है। वह अनुभव करता है कि उसका शरीर विभिन्न परिवर्तनों से गुजर रहा है और यह सुनिश्चित नहीं है कि परिवार के सामने अपनी भावनाएं कैसे व्यक्त करे।",
              "ka": "ಅರವಿಂದ್ ತನ್ನ ಹದಿಹರೆಯದ ವರ್ಷಗಳಲ್ಲಿ ಸಾಗುತ್ತಿದ್ದಂತೆ, ಅವನು ಚಂಚಲತೆಯನ್ನು ಅನುಭವಿಸುತ್ತಾನೆ. ತನ್ನ ದೇಹವು ವಿವಿಧ ಬದಲಾವಣೆಗಳ ಮೂಲಕ ಹೋಗುವುದನ್ನು ಅವನು ಗ್ರಹಿಸುತ್ತಾನೆ ಮತ್ತು ತನ್ನ ಕುಟುಂಬಕ್ಕೆ ತನ್ನನ್ನು ಹೇಗೆ ವ್ಯಕ್ತಪಡಿಸಬೇಕೆಂದು ಅವನಿಗೆ ಖಚಿತ ಆಗುವುದಿಲ್ಲ.",
              "te": "అరవింద్ తన యుక్తవయస్సులోకి వెళ్తునప్పుడు, అతను నీరసంగా ఫీల్ అవుతాడు. అతను తన శరీరం వివిధ మార్పులకు గురౌతున్నట్లు గ్రహించాడు మరియు తన కుటుంబానికి ఎలా వ్యక్తపరచాలో తెలియడం లేదు.",
              "or": "ଯେତେବେଳେ ଅରବିନ୍ଦ କିଶୋରାବସ୍ଥା ବର୍ଷ ଗତି କରନ୍ତି, ସେ ଅସ୍ଥିର ଅନୁଭବ କରିବାକୁ ଲାଗନ୍ତି | ସେ ନିଜ ଶରୀରକୁ ବିଭିନ୍ନ ପରିବର୍ତ୍ତନ ଦେଇ ଗତି କରୁଥିବା ଅନୁଭବ କରନ୍ତି ଏବଂ ଅନିଶ୍ଚିତ ନିଜ ପରିବାରକୁ କିପରି ନିଜେ ପ୍ରକାଶ କରିବେ |",
              "as": "অৰবিন্দে কিশোৰ অৱস্থালৈ যোৱাৰ লগে লগে, তেওঁ অস্থিৰ অনুভৱ কৰিবলৈ আৰম্ভ কৰে। তেওঁ অনুভৱ কৰে যে তেওঁৰ শৰীৰটো বিভিন্ন পৰিৱৰ্তনৰ মাজেৰে গৈ আছে আৰু ইয়াক কেনেকৈ নিজৰ পৰিয়ালৰ ওচৰত প্ৰকাশ কৰিব লাগিব সেই বিষয়ে নিশ্চিত নহয়।",
              "gu": "જે રીતે અરવિંદ તેની કિશોરાવસ્થામાં પ્રવેશ કરે છે, તે વ્યાકુળ થવા લાગે છે. તે અહેસાસ કરે છે કે તેનું શરીર અલગ પ્રકારની વર્તણૂંકમાંથી પસાર થઈ રહ્યું છે. અને તે એ નક્કી કરી શકતો નથી કે તેના પરિવાર સમક્ષ તે પોતાની જાતને કેવી રીતે પ્રસ્તુત કરે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_1_2",
              "hi": "X2_1_2",
              "ka": "X2_1_2",
              "te": "X2_1_2",
              "or": "X2_1_2",
              "as": "X2_1_2",
              "gu": "X2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind's interest in studies and his urge to do well in academics is one thing that keeps him going.",
              "hi": "अरविंद की पढ़ाई में रुचि और पढ़ाई में अच्छा करने की उसकी ललक एक ऐसी चीज है जो उसे आगे बढ़ाती है।",
              "ka": "ಅರವಿಂದನಿಗೆ ಅಧ್ಯಯನದಲ್ಲಿ ಇರುವ ಆಸಕ್ತಿ ಮತ್ತು ಶಿಕ್ಷಣದಲ್ಲಿ ಉತ್ತಮ ಸಾಧನೆ ಮಾಡಬೇಕೆಂಬ ಅವನ ಉತ್ಸಾಹವು ಅವನು ಮುಂದುವರಿದು ಹೋಗಲು ಒಂದು ವಿಷಯವಾಗಿದೆ.",
              "te": "అరవింద్‌కు చదువుపై ఆసక్తి, చదువులో రాణించాలనే తపన అతడిని ముందుకు నడిపిస్తున్నాయి.",
              "or": "ଅଧ୍ୟୟନରେ ଅରବିନ୍ଦଙ୍କ ଆଗ୍ରହ ଏବଂ ଶିକ୍ଷା କ୍ଷେତ୍ରରେ ଭଲ କରିବା ପାଇଁ ତାଙ୍କର ଉତ୍ସାହ ଏକ ଜିନିଷ ଯାହା ତାଙ୍କୁ ଆଗକୁ ବଢାଇଥାଏ |",
              "as": "অধ্যয়নৰ প্ৰতি অৰবিন্দৰ আগ্ৰহ আৰু শিক্ষাৰ ক্ষেত্ৰত যুক্তিগত ভাবে ভাল ফল দেখুওৱা এনে এটি বস্তু যিয়ে তেওঁক আগুৱাই লৈ গৈছে।",
              "gu": "અરવિંદ અભ્યાસ કરવામાં રસ ધરાવે છે અને શિક્ષણમાં કંઈક હાંસલ કરવાની તેની ઈચ્છા એક એવી બાબત છે કે જે તેને આગળ વધારે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_1_3",
              "hi": "X2_1_3",
              "ka": "X2_1_3",
              "te": "X2_1_3",
              "or": "X2_1_3",
              "as": "X2_1_3",
              "gu": "X2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He is curious about everything, but he doesn't say much in class as he is afraid that the other students will make fun of him.",
              "hi": "वह हर चीज के बारे में उत्सुक रहता है, लेकिन वह कक्षा में ज्यादा नहीं बोलता क्योंकि वह डरता है कि अन्य छात्र उसका मजाक उड़ाएंगे।",
              "ka": "ಅವನಿಗೆ ಎಲ್ಲದರ ಬಗ್ಗೆ ಕುತೂಹಲವಿದೆ, ಆದರೆ ಇತರ ವಿದ್ಯಾರ್ಥಿಗಳು ತನ್ನನ್ನು ಗೇಲಿ ಮಾಡುತ್ತಾರೆ ಎಂಬ ಭಯದಿಂದ ಅವನು ತರಗತಿಯಲ್ಲಿ ಹೆಚ್ಚು ಮಾತನಾಡುವುದಿಲ್ಲ.",
              "te": "అతను ప్రతిదానిపై ఆసక్తి కలిగి ఉంటాడు, కానీ ఇతర విద్యార్థులు తనను ఎగతాళి చేస్తారనే భయంతో అతను తరగతిలో పెద్దగా మాట్లాడడు.",
              "or": "ସେ ସବୁକିଛି ବିଷୟରେ ଉତ୍ସୁକ, କିନ୍ତୁ ସେ କ୍ଲାସ୍‌ରେ ଅଧିକ କିଛି କୁହନ୍ତି ନାହିଁ କାରଣ ସେ ଭୟ କରନ୍ତି ଯେ ଅନ୍ୟ ଛାତ୍ରମାନେ ତାଙ୍କୁ ପରିହାସ କରିବେ |",
              "as": "তেওঁ সকলো বিষয়ৰ প্ৰতি কৌতূহলী, কিন্তু তেওঁ শ্ৰেণীত বেছি কথা নকয় কিয়নো তেওঁ ভয় কৰে যে আন শিক্ষাৰ্থীসকলে তেওঁক হাঁহিব।",
              "gu": "તે દરેક બાબત અંગે ઉત્સુક છે, પણ તે વર્ગખંડમાં વિશેષ કઈ કરી શકતો નથી કારણ કે તેને ડર છે કે અન્ય વિદ્યાર્થી તેની મજાક કરવા લાગશે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_1_4",
              "hi": "X2_1_4",
              "ka": "X2_1_4",
              "te": "X2_1_4",
              "or": "X2_1_4",
              "as": "X2_1_4",
              "gu": "X2_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He reads a lot to find answers to most of the things. But the amount of time he spends studying forces him to drop out of the performing arts club.",
              "hi": "वह अधिकतर चीजों के जवाब खोजने के लिए बहुत कुछ पढ़ता है। लेकिन जितना समय वह पढ़ाई में बिताता है, वह उसे प्रदर्शन कला क्लब छोड़ने के लिए विवश करता है।",
              "ka": "ಹೆಚ್ಚಿನ ವಿಷಯಗಳಿಗೆ ಉತ್ತರಗಳನ್ನು ಹುಡುಕಲು ಅವನು ಬಹಳಷ್ಟು ಓದುತ್ತಾನೆ. ಆದರೆ ಅವನು ಅಧ್ಯಯನದಲ್ಲಿ ಕಳೆಯುವ ಸಮಯವು ಪ್ರದರ್ಶನ ಕಲಾ ಕ್ಲಬ್‌ನಿಂದ ಹೊರಗುಳಿಯುವಂತೆ ಒತ್ತಾಯಿಸುತ್ತದೆ.",
              "te": "అతను చాలా విషయాలకు సమాధానాలు వెతకడానికి చాలా చదువుతాడు. కానీ అతను చదువుకోవడానికి వెచ్చించే సమయం అతనిని పెర్ఫార్మింగ్ ఆర్ట్స్ క్లబ్ నుండి తప్పుకునేలా చేస్తుంది.",
              "or": "ଅଧିକାଂଶ ଜିନିଷର ଉତ୍ତର ଖୋଜିବା ପାଇଁ ସେ ବହୁତ ପଢ଼ନ୍ତି | କିନ୍ତୁ ସେ ଅଧ୍ୟୟନ ପାଇଁ ଯେତେ ସମୟ ବିତାଉଛନ୍ତି ତାହା ତାଙ୍କୁ ବାଧ୍ୟ କରିଥାଏ କଳା ପରିବେଷଣ କ୍ଲବ ଛାଡିବାକୁ |",
              "as": "তেওঁ বেছিভাগ বস্তুৰ উত্তৰ বিচাৰিবলৈ বহুত পঢ়ে। কিন্তু তেওঁ অধ্যয়নত অতিবাহিত কৰা সময়ৰ পৰিমাণে তেওঁক প্ৰদৰ্শন কলা ক্লাব বাদ দিবলৈ বাধ্য কৰে।",
              "gu": "તે મોટાભાગની બાબતના જવાબ શોધવા માટે ઘણો અભ્યાસ કરે છે. પણ જેટલો સમય તે અભ્યાસમાં પસાર કરે છે તે તેના પર્ફોમિંગ આર્ટથી બહાર કરવા માટે મજબૂર કરે છે"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_2_1",
              "hi": "X2_2_1",
              "ka": "X2_2_1",
              "te": "X2_2_1",
              "or": "X2_2_1",
              "as": "X2_2_1",
              "gu": "X2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Meanwhile, Pranav keeps his distance from Arvind as he feels like he cannot relate to him.",
              "hi": "इस बीच, प्रणव अरविंद से अपनी दूरी बनाए रखता है क्योंकि वह अनुभव करता है कि वह उससे संबद्ध नहीं हो सकता है।",
              "ka": "ಏತನ್ಮಧ್ಯೆ, ಪ್ರಣವ್ ಅರವಿಂನೊಂದಿಗೆ ಸಂಬಂಧ ಹೊಂದಲು ಸಾಧ್ಯವಿಲ್ಲ ಎಂದು ಭಾವಿಸಿ ಅವನಿಂದ ದೂರವಿರಿಸುತ್ತಾನೆ.",
              "te": "ఇంతలో, అరవింద్‌తో తనకు సంబంధం లేదని భావించిన ప్రణవ్ అతని నుండి దూరంగా ఉంటాడు.",
              "or": "ଏହି ସମୟରେ, ପ୍ରଣବ ଅରବିନ୍ଦଙ୍କ ଠାରୁ ତାଙ୍କର ଦୂରତା ରଖନ୍ତି ଯେହେତୁ ତାଙ୍କ ସହ ସମ୍ପର୍କ ରଖିପାରିବେ ନାହିଁ ସେ ଅନୁଭବ କରନ୍ତି |",
              "as": "ইফালে, প্ৰণৱে অৰবিন্দৰ পৰা দূৰত্ব বজাই ৰাখে কিয়নো তেওঁ অনুভৱ কৰে যে ককায়েকে তেওঁৰ সৈতে সম্পৰ্ক ৰাখিব নিবিচাৰে।",
              "gu": "દરમિયાન પ્રણવ અરવિંદથી અંતર બનાવી રાખે છે, કારણ કે તેને લાગે છે કે તે તેની સાથે કોઈ સંબંધ ધરાવી શકશે નહીં."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_2_2",
              "hi": "X2_2_2",
              "ka": "X2_2",
              "te": "X2_2_2",
              "or": "X2_2_2",
              "as": "X2_2_2",
              "gu": "X2_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Now Arvind spends most of his time with his sister and sometimes with her friends who visit.",
              "hi": "अब अरविंद अपनी बहन के साथ अपना अधिकांश समय बिताता है और कभी-कभी अपने मित्रों के साथ जाता है।",
              "ka": "ಈಗ ಅರವಿಂದ್ ತನ್ನ ಹೆಚ್ಚಿನ ಸಮಯವನ್ನು ತನ್ನ ಸಹೋದರಿಯೊಂದಿಗೆ ಮತ್ತು ಕೆಲವೊಮ್ಮೆ ಭೇಟಿ ನೀಡುವ ಅವಳ ಸ್ನೇಹಿತರೊಂದಿಗೆ ಕಳೆಯುತ್ತಾನೆ.",
              "te": "ఇప్పుడు అరవింద్ తన సోదరితో మరియు కొన్నిసార్లు తన దగ్గరికి వచ్చే ఆమె స్నేహితులతో ఎక్కువ సమయం గడుపుతున్నాడు.",
              "or": "ବର୍ତ୍ତମାନ ଅରବିନ୍ଦ ତାଙ୍କ ଭଉଣୀ ସହିତ ଏବଂ ବେଳେବେଳେ ତାଙ୍କ ସାଙ୍ଗମାନେ ଯେଁଉମାନେ ଦେଖାକରିବାକୁ ଆସନ୍ତି ସହିତ ଅଧିକାଂଶ ସମୟ ଅତିବାହିତ କରନ୍ତି |",
              "as": "এতিয়া অৰবিন্দে তেওঁৰ বেছিভাগ সময় তেওঁৰ ভনীয়েকৰৰ সৈতে আৰু কেতিয়াবা তাইৰ বন্ধুবৰ্গৰ সৈতে অতিবাহিত কৰে যিসকলে তেওঁক লগ কৰিবলৈ আহে।",
              "gu": "હવે અરવિંદ પોતાનો મોટાભાગનો સમય તેની બહેન સાથે અને ક્યારેક પોતાના મિત્રો સાથે પસાર કરે છે"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_2_3",
              "hi": "X2_2_3",
              "ka": "X2_2_3",
              "te": "X2_2_3",
              "or": "X2_2_3",
              "as": "X2_2_3",
              "gu": "X2_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Roshni is a fun-loving sister who looks up to Arvind, and they are close to each other. She accepts Arvind as he is and does not question his behaviour and mannerisms.",
              "hi": "उसकी बहन रोशनी ज़िंदादिल है जो अरविंद का सम्मान करती है, और वे दोनों एक-दूसरे के करीब हैं। वह अरविंद को वैसे ही स्वीकार करती है जैसा वह है और उसके व्यवहार और तौर-तरीकों पर सवाल नहीं उठाती है।",
              "ka": "ರೋಶ್ನಿ ಒಬ್ಬ ವಿನೋದ-ಪ್ರೀತಿಯ ಸಹೋದರಿಯಾಗಿದ್ದು, ಅರವಿಂದನನ್ನು ನೋಡುತ್ತಾಳೆ ಮತ್ತು ಅವರು ಪರಸ್ಪರ ಹತ್ತಿರವಾಗಿದ್ದಾರೆ. ಅವಳು ಅರವಿಂದನನ್ನು ಅವನಂತೆಯೇ ಸ್ವೀಕರಿಸುತ್ತಾಳೆ ಮತ್ತು ಅವನ ನಡವಳಿಕೆಯನ್ನು ಪ್ರಶ್ನಿಸುವುದಿಲ್ಲ.",
              "te": "రోష్ని సరదాలను ఇష్టపడే చెల్లెలు, ఆమె అరవింద్ ను చూసుకుంటుంది మరియు వారు క్లోస్ గా ఉంటారు. ఆమె అరవింద్‌ని అలాగే అంగీకరిస్తుంది మరియు అతని ప్రవర్తన గురించి ప్రశ్నించదు.",
              "or": "ରୋଶନି ଜଣେ ମଜା-ପ୍ରେମୀ ଭଉଣୀ ଯିଏ ଅରବିନ୍ଦଙ୍କୁ ଦେଖନ୍ତି, ଏବଂ ସେମାନେ ପରସ୍ପରର ନିକଟତର ଅଟନ୍ତି | ଅରବିନ୍ଦ ଯେପରି ତାଙ୍କୁ ସେହିପରି ସେ ଗ୍ରହଣ କରନ୍ତି ଏବଂ ତାଙ୍କ ଆଚରଣ ଏବଂ ଭାବଭଙ୍ଗୀ ଉପରେ ପ୍ରଶ୍ନ କରନ୍ତି ନାହିଁ |",
              "as": "ৰোশনি এগৰাকী ধেমেলীয়া স্বভাৱৰ ভনী যিয়ে অৰবিন্দৰ খবৰ ৰাখে, আৰু তেওঁলোকৰ মাজত ওচৰ সম্পৰ্ক আছে। তাই অৰবিন্দক তেওঁ যেনে তেনেদৰেই গ্ৰহণ কৰিছে আৰু তেওঁৰ আচৰণ আৰু কথা কোৱাৰ ধৰণৰ ওপৰত প্ৰশ্ন নকৰে।",
              "gu": "રોશની એક સારી બહેન છે, જે અરવિંદની કાળજી રાખે છે અને તેઓ એકબીજાની નજીક છે. તે અરવિંદનો એવી રીતે જ સ્વીકાર કરે છે કે જે રીતે તે છે અને તેની વર્તણૂંક તથા પદ્ધતિ અંગે કોઈ પ્રશ્ન કરતી નથી."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_2_4",
              "hi": "X2_2_4",
              "ka": "X2_2_4",
              "te": "X2_2_4",
              "or": "X2_2_4",
              "as": "X2_2_4",
              "gu": "X2_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind also spends time tutoring Roshni to do well at school.",
              "hi": "अरविंद रोशनी को स्कूल में अच्छा करने के लिए पढ़ाने में भी समय बिताता है।",
              "ka": "ಅರವಿಂದ್ ಕೂಡ ರೋಶನಿಗೆ ಶಾಲೆಯಲ್ಲಿ ಚೆನ್ನಾಗಿ ಓದಲು ಪಾಠ ಹೇಳುತ್ತಾ ಕಾಲ ಕಳೆಯುತ್ತಾನೆ.",
              "te": "అరవింద్ కూడా రోష్ని స్కూల్లో బాగా రాణించాలని తనకి ట్యూషన్ చెబుతూ గడిపేవాడు.",
              "or": "ଅରବିନ୍ଦ ରୋଶନୀଙ୍କୁ ସ୍କୁଲରେ ଭଲ କରିବା ପାଇଁ ଟ୍ୟୁସନ୍‌ କରାଇ ସମୟ ଅତିବାହିତ କରନ୍ତି |",
              "as": "অৰবিন্দে ৰোশনিয়ে স্কুলত ভাল ফল দেখাবলৈ তাইক পঢ়োৱাত সময় অতিবাহিত কৰে।",
              "gu": "અરવિંદ શાળામાં રોશનીને સારી રીતે શીખવવામાં પણ સમય પસાર કરે છે"
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_3_1",
              "hi": "X2_3_1",
              "ka": "X2_3_1",
              "te": "X2_3_1",
              "or": "X2_3_1",
              "as": "X2_3_1",
              "gu": "X2_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "It is the month of November, and Roshni is excited about her birthday. They are sitting in Roshni's room, selecting what clothes she should wear.",
              "hi": "नवंबर का महीना है और रोशनी अपने जन्मदिन को लेकर उत्साहित है। वे रोशनी के कमरे में बैठे हैं और चुनाव कर रहे हैं कि उसे कौन से कपड़े पहनने चाहिए।",
              "ka": "ಇದು ನವೆಂಬರ್ ತಿಂಗಳು, ಮತ್ತು ರೋಶ್ನಿ ತನ್ನ ಹುಟ್ಟುಹಬ್ಬದ ಬಗ್ಗೆ ಉತ್ಸುಕನಾಗಿದ್ದಾಳೆ. ಅವರು ರೋಶ್ನಿಯ ಕೋಣೆಯಲ್ಲಿ ಕುಳಿತು, ಅವರು ಯಾವ ಬಟ್ಟೆಯನ್ನು ಧರಿಸಬೇಕೆಂದು ಆಯ್ಕೆ ಮಾಡುತ್ತಾರೆ.",
              "te": "అది నవంబర్ నెల, మరియు రోష్ని తన పుట్టినరోజు గురించి ఉత్సాహంగా ఉంది. వారు రోష్ని గదిలో కూర్చొని, ఆమె ఏ బట్టలు వేసుకోవాలో సెలెక్ట్ చేస్తున్నారు.",
              "or": "ଏହା ନଭେମ୍ବର ମାସ ଅଟେ, ଏବଂ ରୋଶନି ତାଙ୍କ ଜନ୍ମଦିନ ପାଇଁ ଉତ୍ସାହିତ ଅଛନ୍ତି | ସେମାନେ ରୋଶନିଙ୍କ ରୁମ୍‌ରେ ବସି, ଚୟନ କରନ୍ତି କେଉଁ ପୋଷାକ ସେ ପିନ୍ଧିବା ଉଚିତ୍ |",
              "as": "এইটো নৱেম্বৰ মাহ, আৰু ৰোশনি তাইৰ জন্মদিনক লৈ উৎসাহিত। তেওঁলোকে ৰোশনিৰ কোঠাত বহি আছে আৰু তাই কি কাপোৰ পিন্ধিব বাছনি কৰি আছে।",
              "gu": "નવેમ્બરનો આ મહિનો છે, અને રોશની તેના જન્મ દિવસ અંગે ખૂબ ઉત્સાહિત છે. તે રોશનીના રૂમમાં બેઠા છે અને તેણીનીએ કયાં કપડાં પહેરવા જોઈએ તેની પસંદગી કરવા ઈચ્છે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_3_2//",
              "hi": "X2_3_2 //",
              "ka": "X2_3_2//",
              "te": "X2_3_2//",
              "or": "X2_3_2//",
              "as": "X2_3_2//",
              "gu": "X2_3_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની "
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.4.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Arvind, what do you think? What skirt should I wear with this top?",
              "hi": "अरविंद, तुमको क्या लगता है? इस टॉप के साथ मुझे कौन सी स्कर्ट पहननी चाहिए?",
              "ka": "ಅರವಿಂದ್, ನಿನ್ನ ಅಭಿಪ್ರಾಯವೇನು? ಈ ಟಾಪ್ ನೊಂದಿಗೆ ನಾನು ಯಾವ ಸ್ಕರ್ಟ್ ಧರಿಸಬೇಕು?",
              "te": "అరవింద్, నువ్వు ఏమనుకుంటున్నావు? ఈ టాప్‌ కి నేను ఏ స్కర్ట్ వేసుకోవాలి?",
              "or": "ଅରବିନ୍ଦ, ତୁମେ କଣ ଭାବୁଛ ? ଏହି ଟପ୍ ସହିତ ମୁଁ କେଉଁ ସ୍କର୍ଟ ପିନ୍ଧିବା ଉଚିତ୍ ?",
              "as": "অৰবিন্দ, আপুনি কি ভাবে? এই টপটোৰ সৈতে মই কি স্কাৰ্ট পিন্ধিলে ভাল লাগিব?",
              "gu": "અરવિંદ, તુ શું વિચારે છે? મારે આ ટોપ સાથે કયો સ્કર્ટ પહેરવો જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I think this yellow skirt will go well with it. You are so lucky you get to choose from so many options. I wish I had so many skirts to choose from.",
              "hi": "मुझे लगता है कि यह पीली स्कर्ट इसके साथ अच्छी लगेगी। तुम इतनी भाग्यशाली हो कि तुम इतने सारे विकल्पों में से चुनती हो। काश चुनने के लिए मेरे पास इतने सारे स्कर्ट होते।",
              "ka": "ಈ ಹಳದಿ ಸ್ಕರ್ಟ್ ಅದರೊಂದಿಗೆ ಚೆನ್ನಾಗಿ ಹೋಗುತ್ತದೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ನೀವು ತುಂಬಾ ಅದೃಷ್ಟವಂತರು, ನೀವು ಹಲವಾರು ಆಯ್ಕೆಗಳಿಂದ ಆಯ್ಕೆ ಮಾಡಿಕೊಳ್ಳುತ್ತೀರಿ. ನನಗು ಆಯ್ಕೆ ಮಾಡಲು ಹಲವು ಸ್ಕರ್ಟ್ ಗಳು ಇದ್ದಿದ್ದರೆ ಎಂದು ನಾನು ಬಯಸುತ್ತೇನೆ.",
              "te": "ఈ పసుపు స్కర్ట్ దానికి బాగుంటుందని నేను భావిస్తున్నాను. నువ్వు చాలా అదృష్టవంతురాలు, మీరు చాలా ఆప్షన్స్ నుండి ఎంచుకోవచ్చు. నేను కూడా చాలా స్కర్ట్స్ నుండి ఎంచుకోవాలనుకుంటున్నాను.",
              "or": "ମୁଁ ଭାବୁଛି ଏହି ହଳଦିଆ ସ୍କର୍ଟ ଏହା ସହିତ ଭଲରେ ଯିବ | ମୋ ଇଚ୍ଛା ମୋ ପାଖରେ ଏତେଗୁଡ଼ିଏ ସ୍କର୍ଟରୁ ଚୟନ କରିବାକୁ ଥାନ୍ତା |",
              "as": "মই ভাবো এই হালধীয়া স্কাৰ্টটো ইয়াৰ সৈতে ভালদৰে মিলিব। তুমি ইমান ভাগ্যৱান যে তুমি বহুতো বিকল্পৰ পৰা বাছনি কৰিব পাৰা। মই আশা কৰোঁ যে মোৰ ওচৰতো যদি বাছনি কৰিবলৈ বহুতো স্কাৰ্ট থাকিলে হেঁতেন।",
              "gu": "મને લાગે છે કે આ પીળો સ્કર્ટ સાથે સરસ લાગશે. તુ ઘણી નસીબદાર છે કે તને આટલાબધા વિકલ્પોમાંથી પસંદગી કરવાની યોગ્ય તક મળે છે.મારી પાસે પણ આ પ્રકારના વિકલ્પને પસંદ કરવા અનેક સ્કર્ટ હોત"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Why would you want skirts?",
              "hi": "तुम स्कर्ट क्यों चाहती हो?",
              "ka": "ನಿನಗೆ ಸ್ಕರ್ಟ್‌ಗಳು ಏಕೆ ಬೇಕು?",
              "te": "నీకు స్కర్టులు ఎందుకు?",
              "or": "ତୁମେ ସ୍କର୍ଟ କାହିଁକି ଚାହୁଁଛ ?",
              "as": "আপুনি স্কাৰ্ট কিয় বিচাৰিছে?",
              "gu": "તુ શા માટે સ્કર્ટ્સ ઈચ્છે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "I want to wear a skirt. I see everything you wear, and I feel like I would be more comfortable wearing those clothes.",
              "hi": "मैं एक स्कर्ट पहनना चाहता हूं। मैं जो कुछ भी पहनता हूं उसे देखता हूं, और मुझे लगता है कि मैं उन कपड़ों को पहनने में अधिक सहज हूं।",
              "ka": "ನಾನು ಸ್ಕರ್ಟ್ ಧರಿಸಲು ಬಯಸುತ್ತೇನೆ. ನೀವು ಧರಿಸುವ ಎಲ್ಲವನ್ನೂ ನಾನು ನೋಡುತ್ತೇನೆ ಮತ್ತು ಆ ಬಟ್ಟೆಗಳನ್ನು ಧರಿಸಿ ನಾನು ಹೆಚ್ಚು ಆರಾಮದಾಯಕವಾಗಿರಬಹುದು ಎಂದು ನನಗೆ ಅನಿಸುತ್ತದೆ.",
              "te": "నాకు స్కర్ట్ వేసుకోవాలనిపిస్తుంది. నువ్వు ధరించే ప్రతిదాన్ని నేను చూస్తున్నాను మరియు ఆ బట్టలు ధరించడం మరింత సౌకర్యంగా ఉంటుందని నేను భావిస్తున్నాను.",
              "or": "ମୁଁ ଏକ ସ୍କର୍ଟ ପିନ୍ଧିବାକୁ ଚାହେଁ | ତୁମେ ପିନ୍ଧୁଥିବା ସମସ୍ତ ଜିନିଷ ମୁଁ ଦେଖେ, ଏବଂ ମୋତେ ଲାଗୁଛି ମୁଁ ସେହି ପୋଷାକଗୁଡିକ ପିନ୍ଧିଲେ ଅଧିକ ଆରାମଦାୟକ ରୁହନ୍ତି |",
              "as": "মই স্কাৰ্ট পিন্ধিব বিচাৰো। মই তুমি পিন্ধা সকলোবোৰ কাপোৰ দেখিছোঁ, আৰু মই অনুভৱ কৰোঁ যে সেই কাপোৰবোৰ মোৰ বাবে অধিক আৰামদায়ক হ'ব।",
              "gu": "મારે સ્કર્ટ પહેરવો છે.હું એ બધુ જ જોવું છું કે જે તમે પહેરો છે, અમને મને લાગે છે કે હું આ કપડાં પહેરવામાં વધારે સહજ અહેસાસ કરીશ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.4.text",
            "value": {
              "en": "Why didn't you say this before? Come, try this combination on. Our sizes would also be similar.",
              "hi": "तुमने पहले यह क्यों नहीं कहा? आओ, इस कॉम्बिनेशन/ संयोजन को आजमाएं। हमारे आकार भी समान होंगे।",
              "ka": "ನೀನು ಇದನ್ನು ಮೊದಲೇ ಏಕೆ ಹೇಳಲಿಲ್ಲ? ಬನ್ನಿ, ಈ ಸಂಯೋಜನೆಯನ್ನು ಪ್ರಯತ್ನಿಸಿ. ನಮ್ಮ ಗಾತ್ರಗಳು ಸಹ ಹೋಲುತ್ತವೆ.",
              "te": "ఇంతకు ముందు ఎందుకు చెప్పలేదు? రా, ఈ కాంబినేషన్ ప్రయత్నించు. మన సైజులు కూడా సమానంగా ఉంటాయి.",
              "or": "ତୁମେ ଏହା ପୂର୍ବରୁ କାହିଁକି କହିଲ ନାହିଁ ? ଆସ, ଏହି ମିଶ୍ରଣକୁ ଚେଷ୍ଟା କର | ଆମର ସାଇଜ୍ ମଧ୍ୟ ସମାନ ହେବ |",
              "as": "আপুনি এইটো আগতে কিয় কোৱা নাছিল? আহক, এই জোৰাটো পিন্ধি চাওক। আমাৰ আকাৰো একে হ'ব।",
              "gu": "અરે તે આ અગાઉ શા માટે ન કહ્યું? આવ, આ કોમ્બિનેશનને ટ્રાય કર. આપણી સાઈડ પણ એક સરખી હશે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "X2_3_3////",
              "hi": "X2_3_3 ////",
              "ka": "X2_3_3////",
              "te": "X2_3_3////",
              "or": "X2_3_3////",
              "as": "X2_3_3////",
              "gu": "X2_3_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Arvind tries wearing the skirt and top that Roshni gives him. He looks in the mirror.",
              "hi": "अरविंद स्कर्ट पहनने की कोशिश करता है और टॉप जो रोशनी ने उसे दिया है। वह दर्पण में देखता है।",
              "ka": "ಅರವಿಂದ್ ರೋಶ್ನಿ ನೀಡಿದ ಸ್ಕರ್ಟ್ ಮತ್ತು ಟಾಪ್ ಧರಿಸಲು ಪ್ರಯತ್ನಿಸುತ್ತಾನೆ. ಅವನು ಕನ್ನಡಿಯಲ್ಲಿ ನೋಡುತ್ತಾನೆ.",
              "te": "అరవింద్ రోష్ని ఇచ్చిన స్కర్ట్ మరియు టాప్ ధరించడానికి ప్రయత్నిస్తాడు. అతను అద్దంలో చూసుకుంటాడు.",
              "or": "ରୋଶନୀ ତାଙ୍କୁ ଦେଇଥିବା ସ୍କର୍ଟ ଏବଂ ଟପ୍ ପିନ୍ଧିବାକୁ ଅରବିନ୍ଦ ଚେଷ୍ଟା କରନ୍ତି | ସେ ଦର୍ପଣରେ ଦେଖନ୍ତି |",
              "as": "অৰবিন্দে স্কাৰ্ট আৰু টপ পিন্ধিবলৈ চেষ্টা কৰে যিটো ৰোশনিয়ে তেওঁক দিয়ে। তেওঁ আইনাখনত চালে।",
              "gu": "અરવિંદ એ સ્કર્ટ અને ટોપ પહેરવાનો પ્રયત્ન કરે છે કે જે રોશની તેને આપે છે. તે અરીસામાં જુએ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I feel so good wearing these.",
              "hi": "मैं इन कपड़ों में बहुत अच्छा अनुभव करता हूं।",
              "ka": "ಇವುಗಳನ್ನು ಧರಿಸುವುದು ನನಗೆ ತುಂಬಾ ಚೆನ್ನಾಗಿದೆ.",
              "te": "ఇవి ధరించడం నాకు చాలా నచ్చింది.",
              "or": "ଏଗୁଡିକ ପିନ୍ଧିବା ମୋତେ ବହୁତ ଭଲ ଲାଗେ |",
              "as": "মই এইবোৰ পিন্ধি বৰ ভাল পাইছো।",
              "gu": "મને આ પહેરીને ઘણુ સારું લાગી રહ્યું છે"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "It looks perfect on you. You can come to try these clothes on whenever you want. You know that, right?",
              "hi": "यह तुम पर सही लग रहा है। जब भी तुम चाहो इन कपड़ों को आजमा सकते हो। वो तुम्हे पता हैना?",
              "ka": "ಇದು ನಿನಗೆ ಪರಿಪೂರ್ಣವಾಗಿ ಕಾಣುತ್ತದೆ. ನಿನಗೆ ಬೇಕಾದಾಗ ಈ ಬಟ್ಟೆಗಳನ್ನು ಪ್ರಯತ್ನಿಸಲು ನೀನು ಬರಬಹುದು. ಅದು ನಿನಗೆ ತಿಳಿದಿದೆಯೇ, ಸರಿ?",
              "te": "ఇది నీకు పర్ఫెక్ట్ గా కనిపిస్తుంది. నీకు కావలసినప్పుడల్లా ఈ దుస్తులను ప్రయత్నించడానికి నువ్వు రావచ్చు. అది నీకు తెలుసు కదా?",
              "or": "ଏହା ତୁମ ଉପରେ ପର୍‌ଫେକ୍ଟ ଦେଖାଯାଉଛି | ତୁମେ ଯେତେବେଳେ ଚାହିଁବ ଏହି ପୋଷାକ ଚେଷ୍ଟା କରିବାକୁ ଆସିପାରିବେ | ତୁମେ ଏହା ଜାଣିଛ, ଠିକ୍?",
              "as": "এইটো আপোনাৰ বাবে সঠিক হৈছে। আপুনি যেতিয়াই বিচাৰে এই কাপোৰবোৰ পিন্ধিব পাৰে। আপুনি সেইটো জানে, নহয় জানো?",
              "gu": "આ તારી ઉપર પરફેક્ટ લાગે છે. તું જ્યારે ઈચ્છે ત્યારે આ કપડાંને ટ્રાઈ કરવા આવી શકે છે. તુ તો જાણે જ છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Thanks, Roshni. Please don't mention this in front of anyone at home, okay? If Pranav finds out, he will get furious.",
              "hi": "धन्यवाद, रोशनी। कृपया घर पर किसी के सामने इसका जिक्र मत करो, ठीक है? अगर प्रणव को पता चला, तो वह उग्र हो जाएगा।",
              "ka": "ಧನ್ಯವಾದಗಳು, ರೋಶನಿ. ದಯವಿಟ್ಟು ಇದನ್ನು ಮನೆಯಲ್ಲಿ ಯಾರ ಮುಂದೆಯೂ ಹೇಳಬೇಡ, ಸರೀನಾ? ಪ್ರಣವ್‌ಗೆ ಗೊತ್ತಾದರೆ ಸಿಟ್ಟು ಬರುತ್ತೆ.",
              "te": "ధన్యవాదాలు, రోష్ని. దయచేసి ఇంట్లో ఎవరి ముందు ఈ విషయం చెప్పకు, సరేనా? ప్రణవ్‌కి తెలిస్తే కోపం వస్తుంది.",
              "or": "ଧନ୍ୟବାଦ, ରୋଶନି | ଦୟାକରି ଘରେ କାହା ସାମ୍ନାରେ ଏହା ଉଲ୍ଲେଖ କର ନାହିଁ, ଠିକ ଅଛି ? ଯଦି ପ୍ରଣବ ଏହା ଜାଣିବ, ସେ କ୍ରୋଧିତ ହେବ |",
              "as": "ধন্যবাদ, ৰোশনি। অনুগ্ৰহ কৰি এই কথাটো ঘৰত কাকো নকবা, ঠিক আছে? যদি প্ৰণৱে গম পায়, তাৰ খং উঠিব।",
              "gu": "રોશની તારો આભાર! મહેરબાની કરીને ઘરમાં કોઈની સાથે આ અંગે વાત કરતી નહીં, ઠીક છે? પ્રણવને ખબર પડી જશે તો તે ભડકી ઉઠશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Of course, this will be our secret.",
              "hi": "बेशक, यह हमारे बीच की बात होगी।",
              "ka": "ಖಂಡಿತ, ಇದು ನಮ್ಮ ರಹಸ್ಯವಾಗಿರುತ್ತದೆ.",
              "te": "అలాగే, ఇది మన మధ్య రహస్యంగా ఉంటుంది.",
              "or": "ଅବଶ୍ୟ, ଏହା ଆମର ସିକ୍ରେଟ୍‍ ରହିବ |",
              "as": "নিশ্চয়, এইটো আমাৰ মাজত গোপন হৈ থাকিব।",
              "gu": "ચોક્કસ, આ આપણની ગુપ્ત વાત હશે."
            }
          }
        ]
      },
      "scene3": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_1",
              "hi": "X3_1_1",
              "ka": "X3_1_1",
              "te": "X3_1_1",
              "or": "X3_1_1",
              "as": "X3_1_1",
              "gu": "X3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Pranav soon moves to Hyderabad for college. Arvind feels a bit more relaxed at home now.",
              "hi": "प्रणव जल्द ही कॉलेज के लिए हैदराबाद जाता है। अरविंद अब घर पर थोड़ा अधिक सहज अनुभव करता है।",
              "ka": "ಪ್ರಣವ್ ಶೀಘ್ರದಲ್ಲೇ ಕಾಲೇಜಿಗೆ ಹೈದರಾಬಾದ್‌ಗೆ ತೆರಳುತ್ತಾನೆ. ಅರವಿಂದ್ ಈಗ ಮನೆಯಲ್ಲಿ ಸ್ವಲ್ಪ ನಿರಾಳವಾಗಿದ್ದಾರೆ.",
              "te": "ప్రణవ్ తర్వాత కాలేజీకి హైదరాబాద్ వెళ్తాడు. అరవింద్ ఇప్పుడు ఇంట్లో కాస్త రిలాక్స్‌గా ఉన్నాడు.",
              "or": "ପ୍ରଣବ ଶୀଘ୍ର କଲେଜ ପାଇଁ ହାଇଦ୍ରାବାଦକୁ ଚାଲିଯାନ୍ତି | ଅରବିନ୍ଦ ବର୍ତ୍ତମାନ ଘରେ ଟିକେ କୋହଳ ଅନୁଭବ କରନ୍ତି |",
              "as": "প্ৰণৱ সোনকালেই কলেজৰ বাবে হায়দৰাবাদলৈ যায়। অৰবিন্দে এতিয়া ঘৰত অলপ আৰাম অনুভৱ কৰে।",
              "gu": "પ્રણવ જલ્દીથી કોલેજ માટે હૈદરાબાદ જતો રહે છે. અરવિંદ હવે ઘરે થોડી આરામદાયક સ્થિતિનો અહેસાસ કરે છે"
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_2",
              "hi": "X3_1_2",
              "ka": "X3_1_2",
              "te": "X3_1_2",
              "or": "X3_1_2",
              "as": "X3_1_2",
              "gu": "X3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Over the past year, Arvind's body has undergone significant changes. He looks very different from the other boys in his school.",
              "hi": "पिछले साल, अरविंद के शरीर में महत्वपूर्ण परिवर्तन हुए हैं। वह अपने स्कूल के अन्य लड़कों से बहुत अलग दिखता है।",
              "ka": "ಕಳೆದ ಒಂದು ವರ್ಷದಲ್ಲಿ ಅರವಿಂದ್ ಅವರ ದೇಹವು ಗಮನಾರ್ಹ ಬದಲಾವಣೆಗಳನ್ನು ಕಂಡಿದೆ. ಅವನು ತನ್ನ ಶಾಲೆಯ ಇತರ ಹುಡುಗರಿಗಿಂತ ತುಂಬಾ ಭಿನ್ನವಾಗಿ ಕಾಣುತ್ತಾನೆ.",
              "te": "గత ఏడాది కాలంగా అరవింద్ శరీరంలో గణనీయమైన మార్పులు వచ్చాయి. అతను తన పాఠశాలలోని ఇతర అబ్బాయిల కంటే చాలా భిన్నంగా కనిపిస్తాడు.",
              "or": "ଗତ ଏକ ବର୍ଷ ମଧ୍ୟରେ, ଅରବିନ୍ଦଙ୍କ ଶରୀର ଗୁରୁତ୍ବପୁର୍ଣ୍ଣ ପରିବର୍ତ୍ତନ ଦେଇ ଗତି କରିଛି | ସେ ତାଙ୍କ ସ୍କୁଲର ଅନ୍ୟ ପୁଅମାନଙ୍କଠାରୁ ବହୁତ ଅଲଗା ଦେଖାଯାନ୍ତି |",
              "as": "যোৱা বছৰত, অৰবিন্দৰ শৰীৰত গুৰুত্বপূৰ্ণ পৰিৱৰ্তন হৈছে। তেওঁক তেওঁৰ বিদ্যালয়ৰ আন ল'ৰাবোৰতকৈ বহুত বেলেগ দেখায়।",
              "gu": "છેલ્લા એક વર્ષમાં અરવિંદના શરીરમાં ઘણા મહત્વના પરિવર્તન આવ્યા છે. તે પોતાની શાળાના અન્ય છોકરાથી બિલકુલ અલગ દેખાય છે"
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_3",
              "hi": "X3_1_3",
              "ka": "X3_1_3",
              "te": "X3_1_3",
              "or": "X3_1_3",
              "as": "X3_1_3",
              "gu": "X3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind and his classmates had read about male and female bodies in their biology class in 10th standard. Arvind's body looks nothing like the diagrams shown in the textbook.",
              "hi": "अरविंद और उसके सहपाठियों ने 10वीं कक्षा में जीवविज्ञान वर्ग में नर और मादा  शरीर के बारे में पढ़ा था। अरविंद का शरीर पाठ्यपुस्तक में दिखाए गए डाइग्राम की तरह कुछ भी नहीं दिखता है।",
              "ka": "ಅರವಿಂದ್ ಮತ್ತು ಅವರ ಸಹಪಾಠಿಗಳು 10ನೇ ತರಗತಿಯಲ್ಲಿ ಜೀವಶಾಸ್ತ್ರ ತರಗತಿಯಲ್ಲಿ ಗಂಡು ಮತ್ತು ಹೆಣ್ಣಿನ ದೇಹಗಳ ಬಗ್ಗೆ ಓದಿದ್ದರು. ಅರವಿಂದ್ ಅವರ ದೇಹವು ಪಠ್ಯಪುಸ್ತಕದಲ್ಲಿ ತೋರಿಸಿರುವ ರೇಖಾಚಿತ್ರಗಳಂತೆ ಕಾಣುತ್ತಿಲ್ಲ.",
              "te": "అరవింద్ మరియు అతని సహవిద్యార్థులు 10వ తరగతిలో బయాలజీ క్లాస్‌లో మగ మరియు ఆడ శరీరాల గురించి చదివారు. అరవింద్ శరీరం పాఠ్యపుస్తకంలో చూపిన రేఖాచిత్రాలలా ఏమీ కనిపించడం లేదు.",
              "or": "ଅରବିନ୍ଦ ଏବଂ ତାଙ୍କ ସହପାଠୀମାନେ 10th ଶ୍ରେଣୀରେ ସେମାନଙ୍କର ଜୀବ ବିଜ୍ଞାନ କ୍ଲାସରେ ପୁରୁଷ ଏବଂ ମହିଳା ଶରୀର ବିଷୟରେ ପଢିଥିଲେ | ଅରବିନ୍ଦଙ୍କ ଶରୀର ପାଠ୍ୟ ପୁସ୍ତକରେ ଦେଖାଯାଇଥିବା ଚିତ୍ର ପରି ଆଦୌଂ ଦେଖାଯାଏ ନାହିଁ |",
              "as": "অৰবিন্দ আৰু তেওঁৰ সহপাঠীসকলে দশম শ্ৰেণীত তেওঁলোকৰ জীৱবিজ্ঞানৰ শ্ৰেণীত পুৰুষ আৰু মহিলাৰ শৰীৰৰ বিষয়ে পঢ়িছিল। পাঠ্যপুথিত দেখুওৱা নক্সাৰ দৰে অৰবিন্দৰ শৰীৰত একো দেখা নাযায়।",
              "gu": "અરવિંદ અને તેના સહઅધ્યાયીએ ધોરણ-10માં તેમના જીવ વિજ્ઞાનમાં નર અને માદાના શરીર વિશે અભ્યાસ કર્યો હતો. અરવિંદનું શરીર પાઠ્યપુસ્તરમાં દર્શાવવામાં આલા ડાયાગ્રામ જેવું કંઈ જ દેખાતું ન હતું."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_1_4///",
              "hi": "X3_1_4 ///",
              "ka": "X3_1_4///",
              "te": "X3_1_4///",
              "or": "X3_1_4///",
              "as": "X3_1_4///",
              "gu": "X3_1_4///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "There are many instances where his classmates tease him for how he looks. Even while walking around in school, he feels people staring at him and passing comments.",
              "hi": "ऐसे कई उदाहरण हैं जब उसके सहपाठियों ने उसे कितना चिढ़ाया कि वह कैसा दिखता है। स्कूल में घूमते समय भी, उसे लगता है कि लोग उसे घूरते हैं और टिप्पणियां किया करते हैं।",
              "ka": "ಅವನು ಹೇಗೆ ಕಾಣುತ್ತಾನೆ ಎಂದು ಅವನ ಸಹಪಾಠಿಗಳು ಅವನನ್ನು ಕೀಟಲೆ ಮಾಡುವ ಅನೇಕ ನಿದರ್ಶನಗಳಿವೆ. ಶಾಲೆಯಲ್ಲಿ ತಿರುಗಾಡುತ್ತಿರುವಾಗಲೂ, ಜನರು ತನ್ನನ್ನು ದಿಟ್ಟಿಸಿ ಕಾಮೆಂಟ್‌ಗಳನ್ನು ರವಾನಿಸುವುದನ್ನು ಅವನು ಅನುಭವಿಸುತ್ತಾನೆ.",
              "te": "అతని శరీరాన్ని చూసి అతని సహవిద్యార్థులు ఆటపట్టించిన సందర్భాలు చాలా ఉన్నాయి. స్కూల్‌లో తిరుగుతున్నప్పుడు కూడా, ప్రజలు తన వైపు చూస్తూ కామెంట్‌లు చేస్తున్నట్లు అతను భావిస్తాడు.",
              "or": "ଅନେକ ଉଦାହରଣ ଅଛି ଯେଉଁଠାରେ ତାଙ୍କ ସହପାଠୀମାନେ ତାଙ୍କୁ କିପରି ଦେଖାଯାଏ ବୋଲି ତାଙ୍କୁ ଥଟ୍ଟା କରନ୍ତି | ବିଦ୍ୟାଳୟରେ ବୁଲିବା ସମୟରେ ମଧ୍ୟ, ସେ ଅନୁଭବ କରନ୍ତି ଯେ ଲୋକମାନେ ତାଙ୍କ ଆଡକୁ ଅନାନ୍ତି ଏବଂ କମେଣ୍ଟ କରନ୍ତି |",
              "as": "বহুতো উদাহৰণ আছে য'ত তেওঁৰ সহপাঠীসকলে তেওঁ দেখিবলৈ কেনে সেই লৈ জোকাইছিল। আনকি স্কুলত খোজ কাঢ়ি থাকোঁতে, তেওঁ অনুভৱ কৰে যে মানুহবোৰে তেওঁৰ ফালে চাই আছে আৰু মন্তব্য দিছে।",
              "gu": "આવા તો અનેક ઉદાહરણ છે કે જ્યાં સહઅધ્યાયી તેને જોઈને પજવણી કરે છે. શાળામાં ફરતી વખતે તેને લાગે છે કે લોકો તેને જોઈ રહ્યા છે અને ટિપ્પણી કરી રહ્યા છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_2_1",
              "hi": "X3_2_1",
              "ka": "X3_2_1",
              "te": "X3_2_1",
              "or": "X3_2_1",
              "as": "X3_2_1",
              "gu": "X3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He starts missing classes. He becomes body-conscious and spends a lot of time adjusting his posture or wearing clothes that might conceal his body better.",
              "hi": "वह कक्षा से गायब रहने लगता है। वह शरीर के प्रति सचेत हो जाता है और अपनी मुद्रा को समायोजित करने या ऐसे कपड़े पहनने में बहुत समय व्यतीत करता है जो उसके शरीर को बेहतर ढंग से छुपा सकते हैं।",
              "ka": "ಅವನು ತರಗತಿಗಳನ್ನು ಕಳೆದುಕೊಳ್ಳಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ. ಅವನು ದೇಹ ಪ್ರಜ್ಞೆ ಹೊಂದುತ್ತಾನೆ ಮತ್ತು ಅವನ ಭಂಗಿಯನ್ನು ಸರಿಹೊಂದಿಸಲು ಅಥವಾ ಅವನ ದೇಹವನ್ನು ಉತ್ತಮವಾಗಿ ಮರೆಮಾಡಬಹುದಾದ ಬಟ್ಟೆಗಳನ್ನು ಧರಿಸಲು ಸಾಕಷ್ಟು ಸಮಯವನ್ನು ಕಳೆಯುತ್ತಾನೆ.",
              "te": "అతను తరగతులను కోల్పోవడం ప్రారంభించాడు. అతను శరీర స్పృహ కలిగి ఉంటాడు మరియు అతని భంగిమను సర్దుకోవడానికి లేదా అతని శరీరాన్ని బాగా దాచిపెట్టే దుస్తులను ధరించడానికి ఎక్కువ సమయం గడుపుతాడు.",
              "or": "ସେ କ୍ଲାସ୍ ମିସ୍‌ କରିବା ଆରମ୍ଭ କରନ୍ତି | ସେ ଶରୀର-ସଚେତନ ହୁଅନ୍ତି ଏବଂ ନିଜର ମୁଦ୍ରାକୁ ସଜାଡ଼ିବା ପାଇଁ କିମ୍ବା ପୋଷାକ ପିନ୍ଧିବା ପାଇଁ ଅନେକ ସମୟ ଅତିବାହିତ କରନ୍ତି ଯାହା ତାଙ୍କ ଶରୀରକୁ ଭଲ ଭାବରେ ଲୁଚାଇପାରିବ |",
              "as": "তেওঁ শ্ৰেণীবোৰত অনুপস্থিত থাকিবলৈ আৰম্ভ কৰে। তেওঁ শৰীৰ-সচেতন হৈ পৰে আৰু তেওঁৰ ভংগীমা সলনি কৰা বা তেওঁৰ শৰীৰ ভালদৰে লুকুৱাব পৰা কাপোৰ পিন্ধি যথেষ্ট সময় অতিবাহিত কৰে।",
              "gu": "તે વર્ગો ગુમાવવાનું શરૂ કરે છે. તે શરીર પ્રત્યે સભાન બને છે અને તેની મુદ્રાને સમાયોજિત કરવામાં અથવા તેના શરીરને વધુ સારી રીતે છુપાવી શકે તેવા કપડાં પહેરવામાં ઘણો સમય વિતાવે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_2_2",
              "hi": "X3_2_2",
              "ka": "X3_2_2",
              "te": "X3_2_2",
              "or": "X3_2_2",
              "as": "X3_2_2",
              "gu": "X3_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind manages to attend only the important classes and spends most of his time at home studying.",
              "hi": "अरविंद केवल महत्वपूर्ण कक्षाओं में भाग लेने की व्यवस्था करता है और घर पर अध्ययन करने में अपना अधिकांश समय बिताता है।",
              "ka": "ಅರವಿಂದ್ ಮುಖ್ಯ ತರಗತಿಗಳಿಗೆ ಮಾತ್ರ ಹಾಜರಾಗುತ್ತಾನೆ ಮತ್ತು ಹೆಚ್ಚಿನ ಸಮಯವನ್ನು ಮನೆಯಲ್ಲಿಯೇ ಓದುತ್ತಾನೆ.",
              "te": "అరవింద్ ముఖ్యమైన తరగతులకు మాత్రమే హాజరయ్యేవాడు మరియు ఎక్కువ సమయం ఇంట్లోనే చదువుకునేవాడు.",
              "or": "ଅରବିନ୍ଦ କେବଳ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ କ୍ଲାସରେ ଯୋଗଦେବାକୁ ମ୍ଯାନେଜ୍‍ କରନ୍ତି ଏବଂ ଅଧିକାଂଶ ସମୟ ଘରେ ଅଧ୍ୟୟନରେ ଅତିବାହିତ କରନ୍ତି |",
              "as": "অৰবিন্দে দৰকাৰী শ্ৰেণীসমূহত উপস্থিত থাকিবলৈ মিলাই লয় আৰু বেছিভাগ সময় ঘৰত অধ্যয়ন কৰি কটায়।",
              "gu": "અરવિંદ ફક્ત મહત્વના હોય તેવા જ વર્ગખંડમાં ભાગ લેવાનું સંચાલન કરે છે અને પોતાનો મોટાભાગનો સમય ઘરે અભ્યાસ કરવા પાછળ વિતાવે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_2_3",
              "hi": "X3_2_3",
              "ka": "X3_2_3",
              "te": "X3_2_3",
              "or": "X3_2_3",
              "as": "X3_2_3",
              "gu": "X3_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind's parents realise that Arvind is different from other boys. His body is showing characteristics of both male and female parts. They take him to a doctor to understand what the problem might be.",
              "hi": "अरविंद के माता-पिता को आभास हुआ कि अरविंद अन्य लड़कों से अलग है। उसका शरीर नर और मादा दोनों भागों की विशेषताओं को दिखा रहा है। वे उसे एक डॉक्टर के पास यह समझने के लिए ले जाते हैं कि समस्या क्या हो सकती है।",
              "ka": "ಇತರ ಹುಡುಗರಿಗಿಂತ ಅರವಿಂದ್ ವಿಭಿನ್ನ ಎಂದು ಅರವಿಂದ್ ಅವರ ಹೆತ್ತವರು ಅರಿತುಕೊಳ್ಳುತ್ತಾರೆ. ಅವನ ದೇಹವು ಗಂಡು ಮತ್ತು ಹೆಣ್ಣು ಎರಡೂ ಭಾಗಗಳ ಗುಣಲಕ್ಷಣಗಳನ್ನು ತೋರಿಸುತ್ತದೆ. ಸಮಸ್ಯೆ ಏನೆಂದು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಅವರು ಅವನನ್ನು ವೈದ್ಯರ ಬಳಿಗೆ ಕರೆದೊಯ್ಯುತ್ತಾರೆ.",
              "te": "అరవింద్ ఇతర అబ్బాయిల కంటే భిన్నంగా ఉన్నాడని అరవింద్ తల్లిదండ్రులు గ్రహించారు. అతని శరీరం మగ మరియు ఆడ భాగాల లక్షణాలను చూపుతుంది. సమస్య ఏమిటో అర్థం చేసుకోవడానికి వారు అతన్ని వైద్యుడి వద్దకు తీసుకువెళతారు.",
              "or": "ଅରବିନ୍ଦଙ୍କ ପିତାମାତା ଅନୁଭବ କରନ୍ତି ଯେ ଅରବିନ୍ଦ ଅନ୍ୟ ପୁଅମାନଙ୍କଠାରୁ ଭିନ୍ନ ଅଟନ୍ତି | ତାଙ୍କ ଶରୀର ଉଭୟ ପୁରୁଷ ଏବଂ ନାରୀ ଅଙ୍ଗଗୁଡି଼କର ବୈଶିଷ୍ଟ୍ୟ ଦେଖାଉଛି | ଅସୁବିଧା କଣ ହୋଇପାରେ ବୁଝିବା ପାଇଁ ସେମାନେ ତାଙ୍କୁ ଡାକ୍ତରଙ୍କ ନିକଟକୁ ନେଇଯାଆନ୍ତି |",
              "as": "অৰবিন্দৰ মাক-বাপেকে উপলব্ধি কৰিছে যে অৰবিন্দ আন ল'ৰাতকৈ পৃথক। তেওঁৰ শৰীৰে পুৰুষ আৰু মহিলা দুয়োটাৰে অংশৰ বৈশিষ্ট্য প্ৰদৰ্শন কৰিছে। সমস্যাটো কি হ'ব পাৰে বুজিবলৈ তেওঁলোকে তেওঁক চিকিৎসকৰ ওচৰলৈ লৈ যায়।",
              "gu": "અરવિંદના માતા-પિતાને એ વાતનો અહેસાસ થવા લાગે છે કે અરવિંદ અન્ય છોકરાઓથી અલગ છે. તેના શરીરમાં નર અને માદા બન્ને અંગોના લક્ષણ દેખાય છે. સમસ્યા શું હોઈ શકે છે, તે સમજવા માટે તેઓ તેમના ડોક્ટર પાસે જાય છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_3_1",
              "hi": "X3_3_1",
              "ka": "X3_3_1",
              "te": "X3_3_1",
              "or": "X3_3_1",
              "as": "X3_3_1",
              "gu": "X3_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The doctor is busy with other patients. He briefly attends to Arvind and says that his bodily changes could result from hormone fluctuations during puberty.",
              "hi": "डॉक्टर अन्य रोगियों के साथ व्यस्त है। वह अरविंद को थोड़ा बहुत देखता है और कहता है कि उसके शारीरिक परिवर्तन युवावस्था के दौरान हार्मोन उतार-चढ़ाव के परिणामस्वरूप हो सकते हैं।",
              "ka": "ವೈದ್ಯರು ಇತರ ರೋಗಿಗಳೊಂದಿಗೆ ಕಾರ್ಯನಿರತರಾಗಿದ್ದಾರೆ. ಅವರು ಅರವಿಂದ್‌ಗೆ ಸಂಕ್ಷಿಪ್ತವಾಗಿ ಹಾಜರಾಗುತ್ತಾರೆ ಮತ್ತು ಪ್ರೌಢಾವಸ್ಥೆಯ ಸಮಯದಲ್ಲಿ ಹಾರ್ಮೋನ್ ಏರಿಳಿತದಿಂದ ಅವನಿಗೆ ದೈಹಿಕ ಬದಲಾವಣೆಗಳು ಉಂಟಾಗಬಹುದು ಎಂದು ಹೇಳುತ್ತಾರೆ.",
              "te": "డాక్టర్ ఇతర రోగులతో బిజీగా ఉన్నారు. అతను అరవింద్‌ ని బాగా పరిశీలించి యుక్తవయస్సులో హార్మోన్ హెచ్చుతగ్గుల వల్ల అతని శారీరక మార్పులు సంభవిస్తాయని చెప్పాడు.",
              "or": "ଡାକ୍ତର ଅନ୍ୟ ରୋଗୀମାନଙ୍କ ସହିତ ବ୍ୟସ୍ତ ଅଛନ୍ତି | ସେ ଅରବିନ୍ଦଙ୍କୁ ଅଳ୍ପ ସମୟ ପାଇଁ ଧ୍ଯାନ ଦିଅନ୍ତି ଏବଂ କହନ୍ତି ତାଙ୍କର ଶାରୀରିକ ପରିବର୍ତ୍ତନ ଯୌବନାବସ୍ଥାରେ ହରମୋନ୍ ପରିବର୍ତ୍ତନ ହେତୁ ହୋଇପାରେ |",
              "as": "চিকিৎসকজন আন ৰোগীৰ সৈতে ব্যস্ত। তেওঁ কম সময়ৰ বাবে অৰবিন্দৰ ওচৰলৈ যায় আৰু কয় যে তেওঁৰ শাৰীৰিক পৰিৱৰ্তনবোৰ যৌৱনৰ সময়ত হৰমনৰ তাৰতম্যৰ ফলত হ'ব পাৰে।",
              "gu": "ડોક્ટર અન્ય દર્દીને તપાસવામાં વ્યસ્ત છે. તેઓ અરવિંદને થોડો જ સમય આપે છે અને કહે છે કે તેના શારીરિક ફેરફાર યુવાનીના સમયમાં હોર્મોનમાં થતી વધઘટને લીધે આમ શક્ય બની શકે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_3_2",
              "hi": "X3_3_2",
              "ka": "X3_3_2",
              "te": "X3_3_2",
              "or": "X3_3_2",
              "as": "X3_3_2",
              "gu": "X3_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind does not feel any better about this visit. His parents are still concerned, but they don't know what to do about it.",
              "hi": "अरविंद इस निरीक्षण के विषय में कुछ अच्छा अनुभव नहीं करता  है। उसके माता-पिता अभी भी चिंतित हैं, लेकिन वे नहीं जानते कि इसके बारे में क्या करना है।",
              "ka": "ಈ ಭೇಟಿಯಿಂದ ಅರವಿಂದ್‌ಗೆ ಯಾವುದೇ ರೀತಿಯ ನೆಮ್ಮದಿ ಇಲ್ಲ. ಅವನ ಪೋಷಕರು ಇನ್ನೂ ಚಿಂತಿತರಾಗಿದ್ದಾರೆ, ಆದರೆ ಅದರ ಬಗ್ಗೆ ಏನು ಮಾಡಬೇಕೆಂದು ಅವರಿಗೆ ತಿಳಿದಿಲ್ಲ.",
              "te": "ఈ సందర్శన అరవింద్‌కి ఏమీ నచ్చలేదు. అతని తల్లిదండ్రులు ఇప్పటికీ ఆందోళన చెందుతున్నారు, కానీ ఏమి చేయాలో వారికి తెలియట్లేదు.",
              "or": "ଏହି ପରିଦର୍ଶନ ବିଷୟରେ ଅରବିନ୍ଦ କିଛି ଭଲ ଅନୁଭବ କରନ୍ତି ନାହିଁ | ତାଙ୍କ ପିତାମାତା ତଥାପି ଚିନ୍ତିତ ଅଛନ୍ତି, କିନ୍ତୁ ସେମାନେ ଏହା ବିଷୟରେ କ’ଣ କରିବେ ଜାଣନ୍ତି ନାହିଁ |",
              "as": "অৰবিন্দে এই কথা-বতৰাৰ পৰা এই বিষয়ে একো ভাল অনুভৱ নকৰিলে। তেওঁৰ মাক-দেউতাক এতিয়াও চিন্তিত, কিন্তু তেওঁলোকে এই বিষয়ে কি কৰিব লাগে নাজানে।",
              "gu": "અરવિંદને ડોક્ટર સાથેની આ મુલાકાતથી વિશેષ કોઈ સારું લાગ્યું નહીં. તેના માતા-પિતા હજુ પણ ચિંતિત છે, પણ તેઓ એ જાણતા નથી કે તેમને આ અંગે શું કરવું."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_3_3",
              "hi": "X3_3_3",
              "ka": "X3_3_3",
              "te": "X3_3_3",
              "or": "X3_3_3",
              "as": "X3_3_3",
              "gu": "X3_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He feels very hurt and angry at the world around him. He decides to focus on his studies to keep himself busy.",
              "hi": "वह अपने आसपास की दुनिया से बहुत आहत और क्रोधित अनुभव करता है। वह खुद को व्यस्त रखने के लिए अपनी पढ़ाई पर ध्यान देने का निश्चय करता है।",
              "ka": "ಅವನು ತನ್ನ ಸುತ್ತಲಿನ ಪ್ರಪಂಚದಲ್ಲಿ ತುಂಬಾ ನೋವು ಮತ್ತು ಕೋಪವನ್ನು ಅನುಭವಿಸುತ್ತಾನೆ. ಅವನು ತನ್ನನ್ನು ತಾನು ನಿರತನಾಗಿರಲು ತನ್ನ ಅಧ್ಯಯನದ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "అతను తన చుట్టూ ఉన్న ప్రపంచం పట్ల చాలా బాధపడ్డాడు మరియు కోపంగా ఉన్నాడు. తనను తాను బిజీగా ఉంచుకోవడానికి తన చదువుపై దృష్టి పెట్టాలని నిర్ణయించుకున్నాడు.",
              "or": "ସେ ନିଜ ଆଖପାଖରେ ଥିବା ଦୁନିଆ ଉପରେ ଅତ୍ୟଧିକ ଆଘାତ ଏବଂ କ୍ରୋଧ ଅନୁଭବ କରନ୍ତି | ନିଜକୁ ବ୍ୟସ୍ତ ରଖିବା ପାଇଁ ସେ ନିଜ ଅଧ୍ୟୟନ ଉପରେ ଧ୍ୟାନ ଦେବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
              "as": "তেওঁ বহুত আঘাত পাইছে আৰু তেওঁৰ চাৰিওফালৰ পৃথিৱীখনৰ ওপৰত খং উঠিছে। তেওঁ নিজকে ব্যস্ত ৰাখিবলৈ পঢ়া-শুনাত মনোযোগ দিয়াৰ সিদ্ধান্ত লয়।",
              "gu": "તેઓ તેમની આજુબાજુની દુનિયાથી ખૂબ જ દુખી છે અને ગુસ્સાનો અહેસાસ કરે છે. તે પોતાને વ્યસ્ત રાખવા માટે અભ્યાસ પર ધ્યાન આપવાનો નિર્ણય કરે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_4_1",
              "hi": "X3_4_1",
              "ka": "X3_4_1",
              "te": "X3_4_1",
              "or": "X3_4_1",
              "as": "X3_4_1",
              "gu": "X3_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind tries his best to concentrate on his studies but feels low. He feels distracted and restless. Arvind's father asks him to help with a few things at his tailoring shop to keep his mind off.",
              "hi": "अरविंद अपनी पढ़ाई पर ध्यान केंद्रित करने का पूरा प्रयास करता है लेकिन दुखी रहता है। वह विचलित और बेचैन अनुभव करता है। अरविंद के पिता ने उसे अपना दिमाग शांत रखने के लिए अपनी सिलाई की दुकान पर कुछ चीजों में सहायता करने के लिए कहा।",
              "ka": "ಅರವಿಂದ್ ತನ್ನ ಅಧ್ಯಯನದ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಲು ತನ್ನ ಅತ್ಯುತ್ತಮ ಪ್ರಯತ್ನವನ್ನು ಮಾಡುತ್ತಾನೆ ಆದರೆ ಕಡಿಮೆ ಎಂದು ಭಾವಿಸುತ್ತಾನೆ. ಅವನು ಚಂಚಲ ಮತ್ತು ಪ್ರಕ್ಷುಬ್ಧತೆಯನ್ನು ಅನುಭವಿಸುತ್ತಾನೆ. ಅರವಿಂದ್‌ನ ತಂದೆ ಅವನ ಮನಸ್ಸನ್ನು ತಡೆಯಲು ಅವನ ಟೈಲರಿಂಗ್ ಅಂಗಡಿಯಲ್ಲಿ ಕೆಲವು ವಿಷಯಗಳಲ್ಲಿ ಸಹಾಯ ಮಾಡಲು ಕೇಳುತ್ತಾನೆ.",
              "te": "అరవింద్ తన చదువుపై ఏకాగ్రత పెట్టడానికి తన వంతు ప్రయత్నం చేస్తాడు కానీ తనకు తాను తక్కువ అనిపిస్తాడు. అతను పరధ్యానంగా ఉంటాడు మరియు నిరాశ చెందుతాడు. అరవింద్ తండ్రి అతని ఆలోచనలు తగ్గించడానికి అతని టైలరింగ్ షాప్‌లో కొన్ని విషయాలలో సహాయం చేయమని అడిగాడు.",
              "or": "ଅରବିନ୍ଦ ନିଜ ଅଧ୍ୟୟନରେ ଧ୍ୟାନ ଦେବା ପାଇଁ ଯଥାସମ୍ଭବ ଚେଷ୍ଟା କରନ୍ତି କିନ୍ତୁ ନିଜକୁ କମ୍ ଅନୁଭବ କରନ୍ତି | ତାଙ୍କ ମନକୁ ଦୂରେଇ ରଖିବା ପାଇଁ ଅରବିନ୍ଦଙ୍କ ପିତା ତାଙ୍କୁ ଟେଲରିଂ ଦୋକାନରେ କିଛି ଜିନିଷରେ ସାହାଯ୍ୟ କରିବାକୁ କୁହନ୍ତି |",
              "as": "অৰবিন্দে পঢ়া-শুনাত মনোযোগ দিবলৈ যিমান পাৰে সিমান চেষ্টা কৰিছে যদিও পৰা নাই। তেওঁ অন্যমনস্ক আৰু অস্থিৰ অনুভৱ কৰে। অৰবিন্দৰ দেউতাকে তেওঁক তেওঁৰ মনটো এইবোৰৰ পৰা আঁতৰাই ৰাখিবলৈ তেওঁৰ টেইলাৰিং দোকানত কেইটামান বস্তুৰ সৈতে সহায় কৰিবলৈ কয়।",
              "gu": "અરવિંદ તેના અભ્યાસ ઉપર ધ્યાન કેન્દ્રિત કરવાનો સંપૂર્ણ પ્રયત્ન કરે છે, પણ તે આમ કરી શકતો નથી. અરવિંદના પિતાએ તેના મનને શાંતિ મળે તે માટે પોતાની સિલાઈની દુકાન પર કેટલીક બાબતોમાં મદદ કરવા માટે કહ્યું."
            }
          }
        ],
        "page23": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_4_1b",
              "hi": "X3_4_1b",
              "ka": "X3_4_1b",
              "te": "X3_4_1b",
              "or": "X3_4_1b",
              "as": "X3_4_1b",
              "gu": "X3_4_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "At the shop, Arvind observes his father's work closely. He sees how his father manages his tailoring business.",
              "hi": "दुकान में, अरविंद अपने पिता के काम को नजदीक से देखता है। वह देखता है कि उसके पिता अपने सिलाई व्यवसाय का प्रबंधन कैसे करते हैं।",
              "ka": "ಅಂಗಡಿಯಲ್ಲಿ ಅರವಿಂದ್ ತನ್ನ ತಂದೆಯ ಕೆಲಸವನ್ನು ಸೂಕ್ಷ್ಮವಾಗಿ ಗಮನಿಸುತ್ತಾನೆ. ತನ್ನ ತಂದೆ ತನ್ನ ಟೈಲರಿಂಗ್ ವ್ಯವಹಾರವನ್ನು ಹೇಗೆ ನಿರ್ವಹಿಸುತ್ತಾನೆಂದು ಅವನು ನೋಡುತ್ತಾನೆ.",
              "te": "దుకాణంలో అరవింద్ తన తండ్రి చేసే పనిని నిశితంగా గమనిస్తున్నాడు. తన తండ్రి తన టైలరింగ్ వ్యాపారాన్ని ఎలా నిర్వహిస్తున్నాడో చూస్తాడు.",
              "or": "ଦୋକାନରେ, ଅରବିନ୍ଦ ତାଙ୍କ ପିତାଙ୍କ କାର୍ଯ୍ୟକୁ ଅତି ନିକଟରୁ ଦେଖନ୍ତି | ସେ ଦେଖନ୍ତି କିପରି ତାଙ୍କ ପିତା ତାଙ୍କ ଟେଲରିଂ ବ୍ୟବସାୟକୁ ପରିଚାଳନା କରନ୍ତି |",
              "as": "দোকানখনত, অৰবিন্দে তেওঁৰ দেউতাকৰ কামবোৰ নিবিড়ভাৱে লক্ষ্য কৰে। তেওঁ দেখিছে যে তেওঁৰ দেউতাকে কেনেকৈ তেওঁৰ দৰ্জীৰ ব্যৱসায় পৰিচালনা কৰে।",
              "gu": "અરવિંદ દુકાન ખાતે તેના પિતાની કામગીરીને નજીકથી જુએ છે. તે જુએ છે કે તેના પિતા તેમના સિલાઈના કારોબારને કેટલા સારી રીતે સંચાલન કરે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_4_2",
              "hi": "X3_4_2",
              "ka": "X3_4_2",
              "te": "X3_4_2",
              "or": "X3_4_2",
              "as": "X3_4_2",
              "gu": "X3_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He notices that his father could take on a lot more work. Still, due to his inefficient communication with clients and book based accounting, his business seems to have stagnated.",
              "hi": "उसने ध्यान दिया कि उसके पिता बहुत अधिक काम कर सकते हैं। फिर भी, ग्राहकों और पुस्तक आधारित अकाउंटिंग/ लेखांकन के साथ अपने अक्षम सम्पर्क के कारण, उनका व्यवसाय स्थिर हो गया है।",
              "ka": "ತನ್ನ ತಂದೆ ಇನ್ನೂ ಹೆಚ್ಚಿನ ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದೆಂದು ಅವನು ಗಮನಿಸುತ್ತಾನೆ. ಇನ್ನೂ, ಗ್ರಾಹಕರೊಂದಿಗೆ ಅವರ ಅಸಮರ್ಥ ಸಂವಹನ ಮತ್ತು ಪುಸ್ತಕ ಆಧಾರಿತ ಲೆಕ್ಕಪತ್ರ ನಿರ್ವಹಣೆಯಿಂದಾಗಿ, ಅವರ ವ್ಯವಹಾರವು ಸ್ಥಗಿತಗೊಂಡಂತೆ ತೋರುತ್ತದೆ.",
              "te": "తన తండ్రి చాలా ఎక్కువ పని చేయవచ్చని అతను గమనించాడు. కానీ, ఖాతాదారులతో తక్కువ మాట్లాడడం మరియు బుక్ ఆధారిత అకౌంటింగ్ కారణంగా, అతని వ్యాపారం నిలిచిపోయినట్లు కనిపిస్తోంది.",
              "or": "ସେ ଲକ୍ଷ୍ୟ କରନ୍ତି ଯେ ତାଙ୍କ ପିତା ବହୁତ ଅଧିକ କାମ ନେଇପାରନ୍ତି | ତଥାପି, ଗ୍ରାହକମାନଙ୍କ ସହିତ ତାଙ୍କର ଅପାରଗ ଯୋଗାଯୋଗ ଏବଂ ବହି ଆଧାରିତ ଆକାଉଣ୍ଟିଂ ହେତୁ, ତାଙ୍କ ବ୍ୟବସାୟ ନିଶ୍ଚଳ ହୋଇଥିବାର ଦେଖାଯାଏ |",
              "as": "তেওঁ লক্ষ্য কৰিছে যে তেওঁৰ দেউতাকে আৰু বহুতো কাম কৰিব পাৰে। তথাপিও, গ্ৰাহকৰ সৈতে তেওঁৰ অদক্ষ যোগাযোগ আৰু বহীত লিখা হিচাপ-নিকাচৰ বাবে, তেওঁৰ ব্যৱসায় স্থবিৰ হোৱা দেখা গৈছে।",
              "gu": "તે નોંધે છે કે તેના પિતા ઘણું વધારે કામ લઈ શકે છે. તેમ છતાં, ગ્રાહકો સાથેના તેમના બિનકાર્યક્ષમ સંદેશાવ્યવહાર અને પુસ્તક આધારિત એકાઉન્ટિંગને કારણે, તેમનો વ્યવસાય અટકી ગયો હોય તેવું લાગે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_4_3",
              "hi": "X3_4_3",
              "ka": "X3_4_3",
              "te": "X3_4_3",
              "or": "X3_4_3",
              "as": "X3_4_3",
              "gu": "X3_4_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind researches how technology can help small businesses. He draws up a plan for his father to improve his customers' experience.",
              "hi": "अरविंद ने शोध किया कि कैसे प्रौद्योगिकी छोटे व्यवसायों की सहायता कर सकती है। वह अपने ग्राहकों के अनुभव को और अच्छा करने हेतु अपने पिता के लिए एक योजना बनाता है।",
              "ka": "ಅರವಿಂದನು ತಂತ್ರಜ್ಞಾನವು ಸಣ್ಣ ಉದ್ಯಮಗಳಿಗೆ ಹೇಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ ಎಂದು ಸಂಶೋಧಿಸಿದ್ದಾನೆ. ತನ್ನ ಗ್ರಾಹಕರ ಅನುಭವವನ್ನು ಸುಧಾರಿಸಲು ಅವನು ತನ್ನ ತಂದೆಗೆ ಯೋಜನೆಯನ್ನು ರೂಪಿಸುತ್ತಾನೆ.",
              "te": "చిన్న వ్యాపారాలకు సాంకేతికత ఎలా సహాయపడుతుంది అని అరవింద్ రీసర్చ్ చేశాడు. అతను తన కస్టమర్ల అనుభవాన్ని మెరుగుపరచడానికి తన తండ్రి కోసం ఒక ప్రణాళికను రూపొందించాడు.",
              "or": "ଅରବିନ୍ଦ ଅନୁସନ୍ଧାନ କରନ୍ତି କିପରି ଟେକ୍ନୋଲୋଜି ଛୋଟ ବ୍ୟବସାୟକୁ ସାହାଯ୍ୟ କରିପାରିବ | ଗ୍ରାହକଙ୍କ ଅଭିଜ୍ଞତାରେ ଉନ୍ନତି ଆଣିବା ପାଇଁ ସେ ତାଙ୍କ ପିତାଙ୍କ ପାଇଁ ଏକ ଯୋଜନା ପ୍ରସ୍ତୁତ କରନ୍ତି |",
              "as": "অৰবিন্দে গৱেষণা কৰিছে যে প্ৰযুক্তিয়ে কেনেকৈ ক্ষুদ্ৰ ব্যৱসায়বোৰক সহায় কৰিব পাৰে। তেওঁ তেওঁৰ গ্ৰাহকৰ অভিজ্ঞতা উন্নত কৰাৰ বাবে তেওঁৰ দেউতাকৰ বাবে এটা পৰিকল্পনা প্ৰস্তুত কৰিছে।",
              "gu": "અરવિંદે સંશોધન કર્યું કે કેવી રીતે ટેકનોલોજી નાના કારોબારને મદદરૂપ બની શકે છે. તેઓ પોતાના ગ્રાહકોના અનુભવને વધારે સારો કરવા માટે તેમના પિતા માટે એક યોજના ઘડે છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_4_4_reveal",
              "hi": "X3_4_4_रिवील ",
              "ka": "X3_4_4_reveal",
              "te": "X3_4_4_రివీల్",
              "or": "X3_4_4_reveal",
              "as": "X3_4_4_প্ৰকাশ কৰে",
              "gu": "X3_4_4_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Arvind focuses on effective communication with customers through instant messaging, getting feedback from customers regularly, and managing all money transactions. Click to read his plan.",
              "hi": "अरविंद तत्काल संदेश के द्वारा  ग्राहकों के साथ प्रभावी संचार पर ध्यान केंद्रित करता  है, नियमित रूप से ग्राहकों से प्रतिक्रिया प्राप्त करता है, और सभी वित्तीय लेनदेन का प्रबंधन करता है। अपनी योजना पढ़ने के लिए क्लिक करें।",
              "ka": "ಅರವಿಂದನು ತ್ವರಿತ ಸಂದೇಶ ಕಳುಹಿಸುವ ಮೂಲಕ ಗ್ರಾಹಕರೊಂದಿಗೆ ಪರಿಣಾಮಕಾರಿ ಸಂವಹನವನ್ನು ಕೇಂದ್ರೀಕರಿಸುತ್ತಾನೆ, ನಿಯಮಿತವಾಗಿ ಗ್ರಾಹಕರಿಂದ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಪಡೆಯುತ್ತಾನೆ ಮತ್ತು ಎಲ್ಲಾ ಹಣದ ವಹಿವಾಟುಗಳನ್ನು ನಿರ್ವಹಿಸುತ್ತಾನೆ. ಅವನ ಯೋಜನೆಯನ್ನು ಓದಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "తక్షణ సందేశం, కస్టమర్‌ల నుండి క్రమం తప్పకుండా ఫీడ్బాక్ పొందడం మరియు అన్ని డబ్బు లావాదేవీలను మేనేజ్ చేయడం ద్వారా కస్టమర్‌లతో సమర్థవంతమైన కమ్యూనికేషన్‌పై అరవింద్ దృష్టి సారిస్తాడు. అతని ప్లాన్ ను చదవడానికి క్లిక్ చేయండి.",
              "or": "ଅରବିନ୍ଦ ତୁରନ୍ତ ମେସେଜିଂ ମାଧ୍ୟମରେ ଗ୍ରାହକଙ୍କ ସହିତ ପ୍ରଭାବଶାଳୀ ଯୋଗାଯୋଗ, ଗ୍ରାହକଙ୍କଠାରୁ ନିୟମିତ ମତାମତ ପାଇବା ଏବଂ ସମସ୍ତ ଅର୍ଥ କାରବାର ପରିଚାଳନା ଉପରେ ଧ୍ୟାନ ଦିଅନ୍ତି | ତାଙ୍କର ଯୋଜନା ପଢିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "অৰবিন্দে তাৎক্ষণিক বাৰ্তাৰ জৰিয়তে গ্ৰাহকৰ সৈতে কাৰ্যকৰী যোগাযোগ, গ্ৰাহকৰ পৰা নিয়মীয়াকৈ মতামত লাভ কৰা, আৰু সকলো ধনৰ লেনদেন পৰিচালনা কৰাৰ ওপৰত গুৰুত্ব দিয়ে। তেওঁৰ পৰিকল্পনা পঢ়িবলৈ ক্লিক কৰক।",
              "gu": "અરવિંદ અસરકારક પ્રત્યાયનના માધ્યમથી ગ્રાહકોના તમામ પ્રભાવી સંરચના ઉપર ધ્યાન કેન્દ્રીત કરે છે, નિયમિત રીતે ગ્રાહકો સાથે પ્રતિક્રિયા મેળવે છે, અને તમામ નાણાની લેવડ-દેવડનું યોગ્ય સંચાલન કરે છે. તેની યોજના અંગે વાંચવા માટે અહીં ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Step 1",
              "hi": "चरण 1",
              "ka": "ಹಂತ 1",
              "te": "దశ 1",
              "or": "Step 1",
              "as": "পদক্ষেপ ১",
              "gu": "પગલું 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Step 2",
              "hi": "चरण 2 ",
              "ka": "ಹಂತ 2",
              "te": "దశ 2",
              "or": "Step 2",
              "as": "পদক্ষেপ ২",
              "gu": "પગલું 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Step 3",
              "hi": "चरण 3",
              "ka": "ಹಂತ 3",
              "te": "దశ 3",
              "or": "Step 3",
              "as": "পদক্ষেপ ৩",
              "gu": "પગલું 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Set up a chat business account for efficient communication with customers",
              "hi": "ग्राहकों के साथ कुशल संचार के लिए एक चैट व्यापार खाता सेट अप करे",
              "ka": "ಗ್ರಾಹಕರೊಂದಿಗೆ ಸಮರ್ಥ ಸಂವಹನಕ್ಕಾಗಿ ಚಾಟ್ ವ್ಯವಹಾರ ಖಾತೆಯನ್ನು ಹೊಂದಿಸಿ",
              "te": "కస్టమర్‌లతో సమర్థవంతమైన కమ్యూనికేషన్ కోసం చాట్ వ్యాపార ఖాతాను సెటప్ చేయండి",
              "or": "ଗ୍ରାହକଙ୍କ ସହିତ ଫଳପ୍ରଦ ଯୋଗାଯୋଗ ପାଇଁ ଏକ ଚାଟ୍ ବିଜନେସ୍‌ ଆକାଉଣ୍ଟ୍ ସେଟ୍ ଅପ୍ କରନ୍ତି",
              "as": "গ্ৰাহকৰ সৈতে ভালদৰে যোগাযোগ কৰিবলৈ এটা বাৰ্তালাপ ব্যৱসায় একাউণ্ট স্থাপন কৰক",
              "gu": "ગ્રાહકો સાથે કુશળ પ્રત્યાયન માટે ચેટ વ્યવસાકી ખાતુ સેટ કરે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Set up a google card for customers to write online reviews about their experience and the quality of work",
              "hi": "ग्राहकों के लिए अपने अनुभव और कार्य की गुणवत्ता के बारे में ऑनलाइन समीक्षा लिखने के लिए एक गूगल कार्ड सेट अप करे",
              "ka": "ಗ್ರಾಹಕರು ತಮ್ಮ ಅನುಭವ ಮತ್ತು ಕೆಲಸದ ಗುಣಮಟ್ಟದ ಕುರಿತು ಆನ್‌ಲೈನ್ ವಿಮರ್ಶೆಗಳನ್ನು ಬರೆಯಲು ಗೂಗಲ್ ಕಾರ್ಡ್ ಅನ್ನು ಹೊಂದಿಸಿ",
              "te": "కస్టమర్‌లు వారి అనుభవం మరియు పని నాణ్యత గురించి ఆన్‌లైన్ రివ్యూలు వ్రాయడానికి Google కార్డ్‌ని సెటప్ చేయండి",
              "or": "ଗ୍ରାହକଙ୍କ ପାଇଁ ସେମାନଙ୍କର ଅଭିଜ୍ଞତା ଏବଂ କାର୍ଯ୍ୟର ଗୁଣ ବିଷୟରେ ଅନ୍‌ଲାଇନ୍ ସମୀକ୍ଷା ଲେଖିବା ପାଇଁ ଏକ ଗୁଗୁଲ୍ କାର୍ଡ ସେଟ୍ ଅପ୍ କରନ୍ତି",
              "as": "গ্ৰাহকসকলে তেওঁলোকৰ অভিজ্ঞতা আৰু কামৰ মানদণ্ডৰ বিষয়ে অনলাইন সমীক্ষা লিখিবলৈ এটা গুগল কাৰ্ড স্থাপন কৰক",
              "gu": "ગ્રાહકો માટે તેમના અનુભવ અને કામની ગુણવત્તા અંગે ઓનલાઈન સમીક્ષા લખવા માટે તે એક ગુગલ કાર્ડ સેટ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "He sets up an account on the Khatabook app for his father to do easy accounting",
              "hi": "वह अपने पिता के लिए सहज अकाउंटिंग/ लेखांकन करने हेतु खाताबुक ऐप पर एक  अकाउंट/ खाता स्थापित करता है",
              "ka": "ಅವನು ತನ್ನ ತಂದೆಗೆ ಸುಲಭವಾಗಿ ಲೆಕ್ಕಪತ್ರ ನಿರ್ವಹಣೆ ಮಾಡಲು ಖಾತಾಬುಕ್ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ಖಾತೆಯನ್ನು ಹೊಂದಿಸುತ್ತಾನೆ",
              "te": "అతను తన తండ్రి సులభంగా అకౌంటింగ్ చేయడానికి ఖాతాబుక్ యాప్‌లో ఖాతాను సెటప్ చేస్తాడు",
              "or": "ତାଙ୍କ ପିତା ସହଜ ଆକାଉଣ୍ଟିଂ କରିବା ପାଇଁ ସେ ଖାତାବୁକ୍ ଆପ୍‌ରେ ଏକ ଆକାଉଣ୍ଟ୍ ସେଟ୍ ଅପ୍ କରନ୍ତି",
              "as": "তেওঁ তেওঁৰ দেউতাকক সহজে হিচাপ ৰখাৰ বাবে খাটাবুক এপত এটা একাউণ্ট খুলি দিয়ে",
              "gu": "તેઓ પોતાના પિતા માટે સરળતાપૂર્વક હિસાબો માટે ખાતાબુક એપ પર એક ખાતું તૈયાર કરે છે"
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_4_5",
              "hi": "X3_4_5",
              "ka": "X3_4_5",
              "te": "X3_4_5",
              "or": "X3_4_5",
              "as": "X3_4_5",
              "gu": "X3_4_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "At first, Arvind's father is sceptical about these changes. But seeing Arvind's enthusiasm, he follows his advice.",
              "hi": " पहले तो, अरविंद के पिता इन परिवर्तनों के विषय में संशय में हैं। लेकिन अरविंद के उत्साह को देखते हुए, वह उसके सुझाव को मान लेते हैं।",
              "ka": "ಮೊದಮೊದಲು ಅರವಿಂದನ ತಂದೆಗೆ ಈ ಬದಲಾವಣೆಗಳ ಬಗ್ಗೆ ಸಂಶಯ. ಆದರೆ ಅರವಿಂದನ ಉತ್ಸಾಹವನ್ನು ಕಂಡು ಅವನ ಸಲಹೆಯನ್ನು ಅನುಸರಿಸುತ್ತಾನೆ.",
              "te": "ఈ మార్పులపై మొదట అరవింద్ తండ్రికి అనుమానం వచ్చింది. కానీ అరవింద్ ఉత్సాహం చూసి అతని సలహాను పాటిస్తాడు.",
              "or": "ପ୍ରଥମେ, ଅରବିନ୍ଦଙ୍କ ପିତା ଏହି ପରିବର୍ତ୍ତନଗୁଡିକ ଉପରେ ସନ୍ଦେହ କରନ୍ତି | କିନ୍ତୁ ଅରବିନ୍ଦଙ୍କ ଉତ୍ସାହକୁ ଦେଖି, ସେ ତାଙ୍କ ପରାମର୍ଶକୁ ଅନୁସରଣ କରନ୍ତି |",
              "as": "প্ৰথমে, অৰবিন্দৰ দেউতাকে এই পৰিৱৰ্তনবোৰৰ বিষয়ে একো আও-ভাও পোৱা নাছিল। কিন্তু অৰবিন্দৰ উৎসাহ দেখি তেওঁ তাৰ পৰামৰ্শ অনুসৰণ কৰে।",
              "gu": "સૌથી પહેલા તો, અરવિંદના પિતા આ ફેરફારો અંગે આશંકા ધરાવે છે. પણ અરવિંદનો ઉત્સાહ જોઈને તેની સલાહ માને છે."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_5_1",
              "hi": "X3_5_1",
              "ka": "X3_5_1",
              "te": "X3_5_1",
              "or": "X3_5_1",
              "as": "X3_5_1",
              "gu": "X3_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The customers are pleased and appreciate the changes Arvind's father has brought about. They start referring more people to him.",
              "hi": "अरविंद के पिता ने जो परिवर्तन किए हैं उससे ग्राहक खुश हैं और उनकी सराहना करते हैं। वे और लोगों से उनका जिक्र करना शुरू करते हैं।",
              "ka": "ಅರವಿಂದ್ ಅವರ ತಂದೆ ತಂದಿರುವ ಬದಲಾವಣೆಗೆ ಗ್ರಾಹಕರು ಸಂತಸಗೊಂಡಿದ್ದಾರೆ ಮತ್ತು ಮೆಚ್ಚುಗೆ ವ್ಯಕ್ತಪಡಿಸಿದ್ದಾರೆ. ಅವರು ಅವನಿಗೆ ಹೆಚ್ಚು ಜನರನ್ನು ಉಲ್ಲೇಖಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ.",
              "te": "అరవింద్ తండ్రి కోసం తీసుకొచ్చిన మార్పులను చూసి కస్టమర్లు సంతోషిస్తున్నారు మరియు అభినందిస్తున్నారు. వారు అతని వద్దకు ఎక్కువ మందిని సూచించడం ప్రారంభిస్తారు.",
              "or": "ଅରବିନ୍ଦଙ୍କ ପିତା ଆଣିଥିବା ପରିବର୍ତ୍ତନଗୁଡିକରେ ଗ୍ରାହକମାନେ ଖୁସି ଏବଂ ପ୍ରଶଂସା କରନ୍ତି | ସେମାନେ ଅଧିକ ଲୋକଙ୍କୁ ତାଙ୍କ ନିକଟକୁ ପଠାଇବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "গ্ৰাহকসকল সন্তুষ্ট হয় আৰু অৰবিন্দৰ দেউতাকে অনা পৰিৱৰ্তনবোৰৰ প্ৰশংসা কৰে। তেওঁলোকে অধিক লোকক তেওঁৰ ওচৰলৈ পঠোৱা আৰম্ভ কৰে।",
              "gu": "ગ્રાહક ખુશ છે અને અરવિંદના પિતા દ્વારા રજૂ કરવામાં આવેલ પરિવર્તનની પ્રશંસા કરે છે. તેઓ તેને વધુ લોકો રેફર કરવાનું શરૂ કર્યું"
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_5_2",
              "hi": "X3_5_2",
              "ka": "X3_5_2",
              "te": "X3_5_2",
              "or": "X3_5_2",
              "as": "X3_5_2",
              "gu": "X3_5_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind's father is delighted! He is very proud of Arvind for helping his business grow. When he tells the rest of the family, they all look pleased.",
              "hi": "अरविंद के पिता प्रसन्न हैं! उन्हें अपने व्यापार को बढ़ाने में सहायता करने के लिए अरविंद पर बहुत गर्व है। जब वह बाकी परिवार को बताते हैं, तो वे सभी खुश दिखते हैं।",
              "ka": "ಅರವಿಂದನ ತಂದೆಗೆ ಸಂತಸ! ತನ್ನ ವ್ಯಾಪಾರದ ಬೆಳವಣಿಗೆಗೆ ಸಹಾಯ ಮಾಡಿದ್ದಕ್ಕಾಗಿ ಅರವಿಂದ್ ಬಗ್ಗೆ ಅವರಿಗೆ ತುಂಬಾ ಹೆಮ್ಮೆ ಇದೆ. ಅವರು ಕುಟುಂಬದ ಇತರರಿಗೆ ಹೇಳಿದಾಗ, ಅವರೆಲ್ಲರೂ ಸಂತೋಷಪಟ್ಟರು.",
              "te": "అరవింద్ తండ్రి సంతోషించాడు! తన వ్యాపారం వృద్ధికి సహకరించినందుకు అరవింద్‌ పట్ల తన తండ్రి చాలా గర్వంగా ఉన్నాడు. అతను మిగిలిన కుటుంబ సభ్యులకు చెప్పినప్పుడు, వారంతా సంతోషంగా ఉన్నారు.",
              "or": "ଅରବିନ୍ଦଙ୍କ ପିତା ବହୁତ ଖୁସି ଅଛନ୍ତି ! ତାଙ୍କର ବ୍ୟବସାୟ ବୃଦ୍ଧିରେ ସାହାଯ୍ୟ କରିଥିବାରୁ ସେ ଅରବିନ୍ଦଙ୍କ ପାଇଁ ବହୁତ ଗର୍ବିତ | ଯେତେବେଳେ ସେ ବାକି ପରିବାରକୁ କୁହନ୍ତି, ସେମାନେ ସମସ୍ତେ ଖୁସି ହୁଅନ୍ତି |",
              "as": "অৰবিন্দৰ দেউতাক সুখী হৈছে! তেওঁৰ ব্যৱসায় বৃদ্ধিত সহায় কৰাৰ বাবে তেওঁ অৰবিন্দৰ বাবে বহুত গৌৰৱান্বিত। যেতিয়া তেওঁ কথাটো পৰিয়ালৰ বাকী সকলক কয়, তেওঁলোক সকলোৱে ভাল পায়।",
              "gu": "અરવિંદના પિતા ખુશ છે! તેમને પોતાના વ્યવસાયને વધારવામાં મદદ કરવા માટે અરવિંદ ઉપર ખૂબ ગર્વ છે. જ્યારે તે પરિવારના અન્ય લોકોને જણાવે છે તો તેઓ ખુબ ખુશ થઈ જાય છે."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_5_3//",
              "hi": "X3_5_3 //",
              "ka": "X3_5_3//",
              "te": "X3_5_3//",
              "or": "X3_5_3//",
              "as": "X3_5_3//",
              "gu": "X3_5_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Arvind, you always surprise me. How did you make such a big difference to Appa's business? That too in a matter of a few weeks!",
              "hi": "अरविंद, तुम हमेशा मुझे चौंका देते हो। तुमने अप्पा के व्यापार में इतना बड़ा अंतर कैसे बनाया? और वोह भी कुछ ही सप्ताह में!",
              "ka": "ಅರವಿಂದ್, ನೀವು ಯಾವಾಗಲೂ ನನ್ನನ್ನು ಆಶ್ಚರ್ಯಗೊಳಿಸುತ್ತಿ. ಅಪ್ಪನ ವ್ಯವಹಾರಕ್ಕೆ ಇಷ್ಟು ದೊಡ್ಡ ವ್ಯತ್ಯಾಸ ಹೇಗೆ ಮಾಡಿದೆ? ಅದೂ ಕೆಲವೇ ವಾರಗಳಲ್ಲಿ!",
              "te": "అరవింద్, నువ్వు నన్ను ఎప్పుడూ ఆశ్చర్యపరుస్తావు. అప్ప బిజినెస్ లో ఇంత పెద్ద తేడా ఎలా చేశావు? అది కూడా కొన్ని వారాల వ్యవధిలోనే!",
              "or": "ଅରବିନ୍ଦ, ତୁମେ ମୋତେ ସବୁବେଳେ ଆଶ୍ଚର୍ଯ୍ୟ କରିଦିଅ | ଆପ୍ପାଙ୍କ ବ୍ୟବସାୟରେ ତୁମେ କିପରି ଏତେ ବଡ଼ ପରିବର୍ତ୍ତନ କଲ ? ତାହା ମଧ୍ୟ କିଛି ସପ୍ତାହ ମଧ୍ୟରେ !",
              "as": "অৰবিন্দ, আপুনি সদায় মোক আচৰিত কৰে। আপুনি আপ্পাৰ ব্যৱসায়ত ইমান ডাঙৰ পাৰ্থক্য কেনেকৈ কৰিলে? সেয়াও কেইসপ্তাহমানৰ ভিতৰতে!",
              "gu": "અરવિંદ, તુ હંમેશા મને આશ્ચર્ય પમાડે છે. તે અપ્પાના કારોબાર માટે આટલું મોટું પરિવર્તન કેવી રીતે કર્યું?આ થોડા સપ્તાહની તો વાત છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "No no, it was nothing. I found some apps that help small businesses grow, and I learnt how to use them in a way that helps Appa.",
              "hi": "नहीं, यह कुछ भी नहीं था। मुझे कुछ ऐप्स मिले जो छोटे व्यवसायों को बढ़ाने में सहायक होते हैं, और मैंने सीखा कि अप्पा की सहायता करने के तरीके में उनका उपयोग कैसे किया जाए।",
              "ka": "ಇಲ್ಲ ಇಲ್ಲ, ಏನೂ ಆಗಿರಲಿಲ್ಲ. ಸಣ್ಣ ವ್ಯಾಪಾರಗಳು ಬೆಳೆಯಲು ಸಹಾಯ ಮಾಡುವ ಕೆಲವು ಅಪ್ಲಿಕೇಶನ್‌ಗಳನ್ನು ನಾನು ಕಂಡುಕೊಂಡಿದ್ದೇನೆ ಮತ್ತು ಅಪ್ಪಾಗೆ ಸಹಾಯ ಮಾಡುವ ರೀತಿಯಲ್ಲಿ ಅವುಗಳನ್ನು ಹೇಗೆ ಬಳಸುವುದು ಎಂದು ನಾನು ಕಲಿತಿದ್ದೇನೆ.",
              "te": "లేదు లేదు, అది ఏమీ కాదు. చిన్న వ్యాపారాలు వృద్ధి చెందడంలో సహాయపడే కొన్ని యాప్‌ల గురించి తెలుసుకోని అప్పాకి సహాయపడే విధంగా వాటిని ఎలా ఉపయోగించాలో నేర్చుకున్నాను.",
              "or": "ନା ନା, ଏହା କିଛି ନଥିଲା | ମୁଁ କିଛି ଆପ୍ ପାଇଲି ଯାହା ଛୋଟ ବ୍ୟବସାୟକୁ ବଢିବାରେ ସାହାଯ୍ୟ କରେ, ଏବଂ ମୁଁ ସେଗୁଡିକୁ ଏକ ଉପାୟରେ ବ୍ୟବହାର କରିବି ଶିଖିଲି ଯାହା ଆପ୍ପାକୁ କିପରି ସାହାଯ୍ୟ କରିବ |",
              "as": "নহয় নহয়, এইটো অন্য একো নাছিল। মই কিছুমান এপ বিচাৰি পাইছিলো যিবোৰে ক্ষুদ্ৰ ব্যৱসায়ৰ বিকাশত সহায় কৰে, আৰু মই সেইবোৰ কেনেদৰে ব্যৱহাৰ কৰিব লাগে শিকিছিলোঁ যিটোৱে আপ্পাক সহায় কৰিলে।",
              "gu": "ના ના, એવું કંઈ જ નથી. મને કેટલીક એપ મળી હતી કે જે નાના કારોબારને પ્રોત્સાહન આપવા મદદ કરે છે અને મને શીખવા મળ્યું કે કેવી રીતે અપ્પાને મદદ કરવા તેનો ઉપયોગ કરી શકાય છે."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_5_4////",
              "hi": "X3_5_4 ////",
              "ka": "X3_5_4////",
              "te": "X3_5_4////",
              "or": "X3_5_4////",
              "as": "X3_5_4////",
              "gu": "X3_5_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Arvind, if this is what you can do now, imagine what you will do after getting a degree!",
              "hi": "अरविंद, अगर तुम यह अभी कर सकते हो, तो कल्पना करो कि डिग्री प्राप्त करने के बाद तुम क्या करोगे!",
              "ka": "ಅರವಿಂದ್, ನೀನು ಈಗ ಮಾಡಬಹುದಾದ ಕೆಲಸವು ಇದೇ ಆಗಿದ್ದರೆ, ನೀನು ಡಿಗ್ರಿ ಪಡೆದ ನಂತರ ಏನು ಮಾಡುತ್ತೀಯ ಎಂದು ಊಹಿಸಿಕೊ!",
              "te": "అరవింద్, ఇప్పుడే నువ్వు ఇంత చేయగలిగితే, డిగ్రీ అయ్యాక ఏం చేస్తావో ఊహించుకో!",
              "or": "ଅରବିନ୍ଦ, ଯଦି ଏହା ତୁମେ ବର୍ତ୍ତମାନ କରିପାରିବ, କଳ୍ପନା କର ତୁମେ ଡିଗ୍ରୀ ପାଇବା ପରେ କଣ କରିବ !",
              "as": "অৰবিন্দ, যদি আপুনি এতিয়াই এনে কৰিব পাৰে, কল্পনা কৰক ডিগ্ৰী পোৱাৰ পিছত আপুনি কি কৰিব !",
              "gu": "અરવિંદ, જો તમે હવે આ કરી શકો તો કલ્પના કરો કે ડિગ્રી મેળવ્યા પછી તમે શું કરશો!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "You can help big businesses grow. Everyone is talking about Business Management these days.",
              "hi": "तुम बड़े व्यवसायों को बढ़ाने में सहायता कर सकते हो। हर कोई इन दिनों व्यापार प्रबंधन के बारे में बात कर रहा है।",
              "ka": "ದೊಡ್ಡ ಉದ್ಯಮಗಳು ಬೆಳೆಯಲು ನೀನು ಸಹಾಯ ಮಾಡಬಹುದು. ಇತ್ತೀಚಿನ ದಿನಗಳಲ್ಲಿ ಎಲ್ಲರೂ ವ್ಯವಹಾರ ನಿರ್ವಹಣೆ ಬಗ್ಗೆ ಮಾತನಾಡುತ್ತಿದ್ದಾರೆ.",
              "te": "పెద్ద వ్యాపారాలు వృద్ధి చెందడానికి నువ్వు సహాయం చేయవచ్చు. ఈ రోజుల్లో అందరూ బిజినెస్ మేనేజ్‌మెంట్ గురించి మాట్లాడుకుంటున్నారు.",
              "or": "ତୁମେ ବଡ ବ୍ୟବସାୟକୁ ବୃଦ୍ଧି କରିବାରେ ସାହାଯ୍ୟ କରିପାରିବ | ସମସ୍ତେ ଏବେ ବିଜନେସ୍‌ ମ୍ଯାନେଜମେଣ୍ଟ୍‍ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରୁଛନ୍ତି |",
              "as": "আপুনি ডাঙৰ ব্যৱসায় বৃদ্ধিত সহায় কৰিব পাৰে। সকলোৱে আজিকালি ব্যৱসায় ব্যৱস্থাপনাৰ বিষয়ে কথা পাতি আছে।",
              "gu": "તું મોટા કારોબારને વેગ આપવામાં મદદ કરી શકે છે. આજકાલ કોઈપણ વ્યક્તિ બિઝનેસ મેનેજમેન્ટની વાત કરી રહી છે"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Hmm, maybe. I like technology and everything it can offer, not just to businesses. Perhaps I could do something in Engineering?",
              "hi": "हाँ शायद। न केवल व्यवसाय के लिए बल्कि मुझे तकनीक और यह सब कुछ पसंद है, । शायद मैं इंजीनियरिंग में कुछ कर सकता था?",
              "ka": "ಹಾಂ, ಇರಬಹುದು. ನಾನು ತಂತ್ರಜ್ಞಾನವನ್ನು ಇಷ್ಟಪಡುತ್ತೇನೆ ಮತ್ತು ವ್ಯವಹಾರಗಳಿಗೆ ಮಾತ್ರವಲ್ಲದೆ ಅದು ನೀಡುವ ಎಲ್ಲವನ್ನೂ. ಬಹುಶಃ ನಾನು ಎಂಜಿನಿಯರಿಂಗ್‌ನಲ್ಲಿ ಏನಾದರೂ ಮಾಡಬಹುದೇ?",
              "te": "హా, కావచ్చు. నేను టెక్నాలజీని వ్యాపారాలకు మాత్రమే కాకుండా అది అందించే ప్రతిదాన్ని ఇష్టపడుతున్నాను. బహుశా నేను ఇంజనీరింగ్‌లో ఏదైనా చేయగలను అనుకుంటా?",
              "or": "ହମ୍, ବୋଧହୁଏ | ମୁଁ ଟେକ୍ନୋଲୋଜି ଏବଂ ଏହା ପ୍ରଦାନ କରୁଥିବା ସବୁକିଛି ପସନ୍ଦ କରେ, କେବଳ ବ୍ୟବସାୟକୁ ନୁହେଁ | ବୋଧହୁଏ ମୁଁ ଇଞ୍ଜିନିୟରିଂରେ କିଛି କରିପାରେ?",
              "as": "হুম, হবও পাৰে। মই প্ৰযুক্তি আৰু ই আগবঢ়াব পৰা সকলো বোৰ ভাল পাওঁ, কেৱল ব্যৱসায়ৰ বাবে নহয়। সম্ভৱতঃ মই অভিযান্ত্ৰিক বিদ্যাত কিবা কৰিব পাৰিম?",
              "gu": "હા કદાંચ! મને ટેકનોલોજી અને એવી તમામ બાબત પસંદ છે જે રજૂ કરી,તે ફક્ત વ્યવસાયો માટે છે. કદાંચ હું એન્જીનિયરિંગમાં શું એન્જીનિયરિંગમાં કંઈ કરી શકું છું?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "My son will become an engineer! Your mother and I will manage the funds. You just focus on your studies. You will do great things with your life!",
              "hi": "मेरा बेटा एक इंजीनियर बन जाएगा! तुम्हारी माँ और मैं धन का प्रबंध कर लेंगे । तुम बस अपने अध्ययन पर ध्यान केंद्रित करो। तुम अपने जीवन में कुछ बड़ा करोगे!",
              "ka": "ನನ್ನ ಮಗ ಇಂಜಿನಿಯರ್ ಆಗುತ್ತಾನೆ! ನಿನ್ನ ತಾಯಿ ಮತ್ತು ನಾನು ಹಣವನ್ನು ನಿರ್ವಹಿಸುತ್ತೇವೆ. ನೀನು ಕೇವಲ ನಿನ್ನ ಅಧ್ಯಯನದ ಮೇಲೆ ಗಮನ ಕೇಂದ್ರೀಕರಿಸು. ನಿನ್ನ ಜೀವನದಲ್ಲಿ ನೀನು ಉತ್ತಮ ಕೆಲಸಗಳನ್ನು ಮಾಡುವೆ!",
              "te": "నా కొడుకు ఇంజనీర్ అవుతాడు! మీ అమ్మ మరియు నేను డబ్బు సంగతి చూసుకుంటాము. నువ్వు కేవలం నీ చదువుపై దృష్టి పెట్టు. నువ్వు నీ జీవితంలో గొప్ప పనులు చేస్తావు!",
              "or": "ମୋ ପୁଅ ଜଣେ ଇଞ୍ଜିନିୟର ହେବ ! ତୁମ ମା ଏବଂ ମୁଁ ପାଣ୍ଠି ମ୍ଯାନେଜ୍‌ କରିବୁ | ତୁମେ କେବଳ ତୁମର ଅଧ୍ୟୟନ ଉପରେ ଧ୍ୟାନ ଦିଅ | ତୁମେ ତୁମ ଜୀବନ ସହିତ ବହୁତ ଭଲ କାମ କରିବ !",
              "as": "মোৰ পুত্ৰ এজন অভিযন্তা হ'ব! তোমাৰ মা আৰু মই পুঁজিবোৰ পৰিচালনা কৰিম। তুমি কেৱল তোমাৰ পঢ়াত মনোযোগ দিয়া। তুমি তোমাৰ জীৱনত ডাঙৰ কাম কৰিব পাৰিবা!",
              "gu": "મારો દીકરો એન્જીનિયર બનશે! તમારી માતા અને હું ફંડનું સંચાલન કરશું. તમે ફક્ત તમારા અભ્યાસ પર ધ્યાન આપે. તમે તમારા જીવન સાથે મહાન કાર્ય કરશે."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_6_1//",
              "hi": "X3_6_1 //",
              "ka": "X3_6_1//",
              "te": "X3_6_1//",
              "or": "X3_6_1//",
              "as": "X3_6_1//",
              "gu": "X3_6_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Roshni doesn't look very happy. After dinner, she takes Arvind aside.",
              "hi": "रोशनी बहुत खुश नहीं दिखती। रात के खाने के बाद, वह अरविंद को अलग ले जाती है।",
              "ka": "ರೋಶನಿ ತುಂಬಾ ಖುಷಿಯಾಗಿಲ್ಲ. ಊಟವಾದ ನಂತರ ಅರವಿಂದನನ್ನು ಪಕ್ಕಕ್ಕೆ ಕರೆದುಕೊಂಡು ಹೋದಳು.",
              "te": "రోష్ని చాలా సంతోషంగా కనిపించడం లేదు. డిన్నర్ అయ్యాక అరవింద్ ని పక్కకు తీసుకెళ్ళింది.",
              "or": "ରୋଶନି ବହୁତ ଖୁସି ଦେଖାଯାନ୍ତି ନାହିଁ | ରାତ୍ରୀ ଭୋଜନ ପରେ, ସେ ଅରବିନ୍ଦଙ୍କୁ ଏକ ପାଖକୁ ନେଇଯାନ୍ତି |",
              "as": "ৰোশনিক দেখাত বৰ সুখী যেন লগা নাই। ৰাতিৰ আহাৰৰ পিছত, তাই অৰবিন্দক একাষৰীয়াকৈ লৈ যায়।",
              "gu": "રોશની ઘણી ખુશ લાગતી નથી. રાત્રીના ભોજન બાદ તે અરવિંદને એક તરફ લઈ જાય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Arvind, are you mad?",
              "hi": "अरविंद, क्या तुम पागल हो?",
              "ka": "ಅರವಿಂದ್, ನಿನಗೆ ಹುಚ್ಚು ಹಿಡಿದಿದೆಯಾ?",
              "te": "అరవింద్ నీకు పిచ్చి పట్టిందా?",
              "or": "ଅରବିନ୍ଦ, ତୁମେ ପାଗଳ କି ?",
              "as": "অৰবিন্দ, আপুনি পাগল নেকি?",
              "gu": "અરવિંદ, શું તુ ગાંડો છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "What did I do?",
              "hi": "मैंने क्या किया?",
              "ka": "ನಾನೇನು ಮಾಡಿದೆ?",
              "te": "నేనేం చేశాను?",
              "or": "ମୁଁ କ’ଣ କଲି ?",
              "as": "মই কি কৰিলোঁ?",
              "gu": "મે શું કર્યું?"
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_6_1////",
              "hi": "X3_6_1 //////",
              "ka": "X3_6_1////",
              "te": "X3_6_1////",
              "or": "X3_6_1////",
              "as": "X3_6_1////",
              "gu": "X3_6_1////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "How can you go for engineering? Do you know what that means? It means moving to a boys hostel and staying away from home. You know how difficult it is for you to manage to be around other boys, even for a few hours!",
              "hi": "तुम इंजीनियरिंग के लिए कैसे जा सकते हो? तुम्हें पता है उसका अर्थ क्या है? इसका अर्थ है कि एक लड़कों के छात्रावास में जाना और घर से दूर रहना। तुम जानते हो कि तुम्हारे लिए अन्य लड़कों के आसपास होने से कितनी समस्याएं हैं, यहां तक ​​कि कुछ घंटों के लिए भी!",
              "ka": "ನೀನು ಎಂಜಿನಿಯರಿಂಗ್‌ಗೆ ಹೇಗೆ ಹೋಗಬಹುದು? ಅದರ ಅರ್ಥವೇನು ಗೊತ್ತಾ? ಹುಡುಗರ ಹಾಸ್ಟೆಲ್‌ಗೆ ಹೋಗುವುದು ಮತ್ತು ಮನೆಯಿಂದ ದೂರ ಉಳಿಯುವುದು ಎಂದರ್ಥ. ಕೆಲವು ಗಂಟೆಗಳ ಕಾಲ ಸಹ ನೀನು ಇತರ ಹುಡುಗರೊಂದಿಗೆ ಇರಲು ಎಷ್ಟು ಕಷ್ಟಪಡುತ್ತಿ ಎಂದು ನಿನಗೆ ತಿಳಿದಿದೆ!",
              "te": "నువ్వు ఇంజనీరింగ్‌కి ఎలా వెళ్తావు? అంటే ఏంటో నీకు తెలుసా? అంటే బాలుర హాస్టల్‌కి వెళ్లడం, ఇంటికి దూరంగా ఉండడం. నువ్వు కొన్ని గంటల పాటు ఇతర అబ్బాయిలతో కలిసి ఉండడం ఎంత కష్టమో నీకు తెలుసు కదా!",
              "or": "ତୁମେ କିପରି ଇଞ୍ଜିନିୟରିଂ ପାଇଁ ଯାଇପାରିବେ ? ତୁମେ ଜାଣିଛ ଏହାର ଅର୍ଥ କଣ ? ଏହାର ଅର୍ଥ ଏକ ବାଳକ ହଷ୍ଟେଲକୁ ଯିବା ଏବଂ ଘରୁ ଦୂରରେ ରହିବା | ତୁମେ ଜାଣିଛ ଅନ୍ୟ ପୁଅମାନଙ୍କ ପାଖରେ ରହିବା ତୁମ ପାଇଁ କେତେ କଷ୍ଟକର, ଏପରିକି କିଛି ଘଣ୍ଟା ପାଇଁ !",
              "as": "আপুনি কেনেকৈ অভিযান্ত্ৰিক শিক্ষাৰ বাবে যাব পাৰে? ইয়াৰ অৰ্থ আপুনি জানেনে? ইয়াৰ অৰ্থ হৈছে ল'ৰাৰ হোষ্টেললৈ যোৱা আৰু ঘৰৰ পৰা আঁতৰত থকা। আপুনি জানে যে আপোনাৰ বাবে আন ল'ৰাৰ চাৰিওফালে থকাটো কিমান কঠিন, আনকি কেইঘণ্টামানৰ বাবেও!",
              "gu": "તમે એન્જીનિયરિંગ માટે કેવી રીતે જઈ શકો છો? તમને માલુમ થાય છે કે તેનો અર્થ શું છે? તેનો અર્થ છે છોકરાઓના વિદ્યાર્થીકાળમાં જવું અને ઘરેથી દૂર રહેવું. તમે જાણો છો કે તમારા માટે અન્ય છોકરાઓની આજુબાજુ રહેવું કેટલું મુશ્કેલ છે, ત્યાં સુધી કે કેટલાક કલાક માટે પણ!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "So then? Will I sit at home and do nothing with my life? Everything feels difficult because I'm a little different from other people my age. I don't think Amma and Appa fully understand how I feel about myself and my body. I can't live my life like this.",
              "hi": "तो फिर? क्या मैं घर पर बैठूंगा और अपने जीवन में कुछ भी नहीं करूंगा? सब कुछ मुश्किल लगता है क्योंकि मैं अपनी उम्र के अन्य लोगों से थोड़ा अलग हूं। मुझे नहीं लगता कि अम्मा और अप्पा पूरी तरह से समझते हैं कि मैं अपने और अपने शरीर के बारे में कैसा अनुभव करता हूं। मैं इस तरह अपना जीवन नहीं जी सकता।",
              "ka": "ಆದ್ದರಿಂದ? ನಾನು ಮನೆಯಲ್ಲಿ ಕುಳಿತು ನನ್ನ ಜೀವನದಲ್ಲಿ ಏನೂ ಮಾಡದೆ ಇರುತ್ತೇನೆಯೇ? ನನ್ನ ವಯಸ್ಸಿನ ಇತರ ಜನರಿಗಿಂತ ನಾನು ಸ್ವಲ್ಪ ಭಿನ್ನವಾಗಿರುವುದರಿಂದ ಎಲ್ಲವೂ ಕಷ್ಟಕರವಾಗಿದೆ. ನನ್ನ ಮತ್ತು ನನ್ನ ದೇಹದ ಬಗ್ಗೆ ನಾನು ಹೇಗೆ ಭಾವಿಸುತ್ತೇನೆ ಎಂದು ಅಮ್ಮ ಮತ್ತು ಅಪ್ಪ ಸಂಪೂರ್ಣವಾಗಿ ಅರ್ಥಮಾಡಿಕೊಂಡಿಲ್ಲ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ. ನಾನು ನನ್ನ ಜೀವನವನ್ನು ಹೀಗೆ ಬದುಕಲಾರೆ.",
              "te": "అయితే ఏంటి? నేను ఇంట్లో కూర్చుని నా జీవితంతో ఏమీ చేయలేనా? నేను నా వయస్సులో ఉన్న ఇతర వ్యక్తుల కంటే కొంచెం భిన్నంగా ఉన్నందున ప్రతిదీ కష్టంగా అనిపిస్తుంది. నా గురించి మరియు నా శరీరం గురించి నేను ఎలా భావిస్తున్నానో అమ్మ మరియు నాన్న పూర్తిగా అర్థం చేసుకున్నారని నేను అనుకోను. నేను నా జీవితాన్ని ఇలా గడపలేను.",
              "or": "ତେବେ? ମୁଁ ଘରେ ବସିବି କି ଏବଂ ମୋ ଜୀବନ ସହିତ କିଛି କରିବି ନାହିଁ ? ସବୁକିଛି କଷ୍ଟକର ଅନୁଭବ କରେ କାରଣ ମୁଁ ମୋ ବୟସର ଅନ୍ୟ ଲୋକଙ୍କଠାରୁ ଟିକିଏ ଭିନ୍ନ | ମୁଁ ଭାବୁନାହିଁ ଆମ୍ମା ଏବଂ ଆପ୍ପା ସମ୍ପୂର୍ଣ୍ଣ ଭାବେ ବୁଝନ୍ତି ମୁଁ କିପରି ନିଜ ଏବଂ ମୋ ଶରୀର ବିଷୟରେ ଅନୁଭବ କରେ | ମୁଁ ମୋ ଜୀବନ ଏହିପରି ବଞ୍ଚି ପାରିବି ନାହିଁ |",
              "as": "তেন্তে? মই ঘৰতে বহি থাকিম নেকি আৰু মোৰ জীৱনত একো নকৰোঁ নেকি? সকলোবোৰ কঠিন অনুভৱ হয় কাৰণ মই মোৰ বয়সৰ আন লোকতকৈ অলপ বেলেগ। মই নাভাবোঁ যে আম্মা আৰু আপ্পাই সম্পূৰ্ণকৈ বুজি পাইছে যে মই নিজৰ আৰু মোৰ শৰীৰৰ বিষয়ে কেনে অনুভৱ কৰোঁ। মই মোৰ জীৱন এনেদৰে জীয়াই থাকিব নোৱাৰো।",
              "gu": "તો પછી? શું હું ઘરે બેશીસ અને પોતાના જીવન સાથે કંઈ જ નહીં કરું? બધુ જ મુશ્કેલ લાગે છે કારણ કે હું મારી ઉંમરના અન્ય લોકોથી થોડો અલગ છું. મને નથી લાગતું કે અમ્મા તથા અપ્પા સંપૂર્ણપણે સમજે છે કે હું મારા અને મારા શરીર અંગે કેવો અહેસાસ કરું છું. હું મારા જીવનમાં આ રીતે જીવી શકતો નથી."
            }
          }
        ],
        "page21": [
          {
            "valuePath": "display",
            "value": {
              "en": "X3_6_3//",
              "hi": "X3_6_3 //",
              "ka": "X3_6_3//",
              "te": "X3_6_3//",
              "or": "X3_6_3//",
              "as": "X3_6_3//",
              "gu": "X3_6_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I know, Arvind. I'm sorry, but I'm just concerned for your safety, and I don't want you getting hurt. Just think about it before you apply to any college.",
              "hi": "मुझे पता है, अरविंद। मुझे खेद है, लेकिन मैं सिर्फ तुम्हारी सुरक्षा के लिए चिंतित हूं, और मैं नहीं चाहती कि तुमको चोट लगे। किसी भी कॉलेज में आवेदन करने से पहले बस इसके बारे में सोचना।",
              "ka": "ನನಗೆ ಗೊತ್ತು ಅರವಿಂದ್. ನನ್ನನ್ನು ಕ್ಷಮಿಸು, ಆದರೆ ನಾನು ನಿನ್ನ ಸುರಕ್ಷತೆಯ ಬಗ್ಗೆ ಚಿಂತಿಸುತ್ತಿದ್ದೇನೆ ಮತ್ತು ನೀನು ನೊಂದುಕೊಳ್ಳುವುದನ್ನು ನಾನು ಬಯಸುವುದಿಲ್ಲ. ನೀನು ಯಾವುದೇ ಕಾಲೇಜಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವ ಮೊದಲು ಅದರ ಬಗ್ಗೆ ಯೋಚಿಸು.",
              "te": "నాకు తెలుసు అరవింద్. నన్ను క్షమించు, కానీ నేను నీ భద్రత గురించి ఆందోళన చెందుతున్నాను మరియు నువ్వు బాధ పడాలని నేను కోరుకోవడం లేదు. నువ్వు ఏదైనా కళాశాలకు అప్లై చేసే ముందు దాని గురించి ఆలోచించు.",
              "or": "ମୁଁ ଜାଣେ, ଅରବିନ୍ଦ | ମୁଁ ଦୁଃଖିତ, କିନ୍ତୁ ମୁଁ କେବଳ ତୁମର ସୁରକ୍ଷା ପାଇଁ ଚିନ୍ତିତ, ଏବଂ ମୁଁ ତୁମକୁ ଆଘାତ କରିବାକୁ ଚାହୁଁ ନାହିଁ | ତୁମେ ଯେକୌଣସି କଲେଜରେ ଆବେଦନ କରିବା ପୂର୍ବରୁ ଟିକିଏ ଏହା ବିଷୟରେ ଚିନ୍ତା କରିବ |",
              "as": "মই জানো, অৰবিন্দ। মই দুঃখিত, কিন্তু মই কেৱল আপোনাৰ সুৰক্ষাৰ বাবে চিন্তিত, আৰু মই নিবিচাৰো যে আপুনি আঘাত পাওক। আপুনি যিকোনো কলেজত আবেদন কৰাৰ আগতে ইয়াৰ বিষয়ে চিন্তা কৰক।",
              "gu": "મને ખબર છે, અરવિંદ!  મને માફ કરજો, પણ હું ફક્ત તમારી સુરક્ષા માટે ચિંતિત છું, અને હું ઈચ્છતો નથી કે તમને ઈજા પહોંચે.કોઈપણ કોલેજમાં લાગૂ કરતા પહેલા ફરી એક વખત વિચારી લો."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Ofcourse, Roshni, there's still so much time to decide. Don't worry.",
              "hi": "बिलकुल, रोशनी, तय करने के लिए अभी भी इतना समय है। चिंता मत करो।",
              "ka": "ಖಂಡಿತ, ರೋಶನಿ, ನಿರ್ಧರಿಸಲು ಇನ್ನೂ ತುಂಬಾ ಸಮಯವಿದೆ. ಚಿಂತಿಸಬೇಡ.",
              "te": "అలాగే రోష్ని, నిర్ణయించుకోవడానికి ఇంకా చాలా సమయం ఉంది. చింతించకు.",
              "or": "ଅବଶ୍ୟ, ରୋଶନି, ନିଷ୍ପତ୍ତି ନେବାକୁ ଏପର୍ଯ୍ୟନ୍ତ ବହୁତ ସମୟ ଅଛି | ବ୍ୟସ୍ତ ହୁଅ ନାହିଁ |",
              "as": "অৱশ্যে, ৰোশনি, সিদ্ধান্ত ল'বলৈ এতিয়াও যথেষ্ট সময় আছে। চিন্তা নকৰিবা।",
              "gu": "ચોક્કસ, રોશની, અત્યારે નિર્ણય કરવા માટે ઘણો સમય છે. ચિંતા ન કરો."
            }
          }
        ],
        "page22": [
          {
            "valuePath": "display",
            "value": {
              "en": "ConvoWithOthers",
              "hi": "दूसरोंकेसाथबातचीत ",
              "ka": "ಇತರರೊಂದಿಗೆ ಸಂವಾದ",
              "te": "ఇతరులతో సంభాషణ",
              "or": "ConvoWithOthers",
              "as": "আনৰ সৈতে কথাবাৰ্তা",
              "gu": "અન્યો સાથે  વાર્તાલાપ"
            }
          }
        ],
        "page24": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "MCQ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Arvind discusses his interests and dreams with his sister Roshni as he makes his \ndecisions regarding his career. Which of the following is TRUE about talking with \nsignificant others while making career decisions:",
              "hi": "अरविंद अपनी बहन रोशनी के साथ अपनी रुचियों और सपनों पर चर्चा करता है जब वह अपने करियर के संबंध में निर्णय  लेता है।                                                                                                     निम्नलिखित में से किसके साथ बात करने  के बारे में सत्य है\n करियर संबंधी निर्णय लेते समय  महत्वपूर्ण अन्य",
              "ka": "ಅರವಿಂದನು ತನ್ನ ವೃತ್ತಿಜೀವನದ ಬಗ್ಗೆ ನಿರ್ಧಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುವಾಗ ಅವನ ಆಸಕ್ತಿಗಳು ಮತ್ತು ಕನಸುಗಳ ಬಗ್ಗೆ ಅವನ ಸಹೋದರಿ ರೋಶನಿಯೊಂದಿಗೆ ಚರ್ಚಿಸುತ್ತಾನೆ. ವೃತ್ತಿಜೀವನದ ನಿರ್ಧಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುವಾಗ ಇತರರೊಂದಿಗೆ ಮಾತನಾಡುವ ಮಹತ್ವದ ಬಗ್ಗೆ ಈ ಕೆಳಗಿನವುಗಳಲ್ಲಿ ಯಾವುದು ನಿಜ:",
              "te": "అరవింద్ తన అభిరుచులు మరియు కలల గురించి తన సోదరి రోష్నితో చర్చించి\n అతని కెరీర్ గురించి నిర్ణయాలు తీసుకుంటాడు. కెరీర్ నిర్ణయాలు తీసుకునేటప్పుడు కిందివాటిలో వేరేవారితో మాట్లాడటంలో ఏది నిజం:",
              "or": "ଅରବିନ୍ଦ ତାଙ୍କ କ୍ୟାରିଅର୍ ସମ୍ବନ୍ଧରେ ନିଷ୍ପତ୍ତି ନେବାବେଳେ ତାଙ୍କ ଭଉଣୀ ରୋଶନିଙ୍କ ସହିତ ତାଙ୍କର ଆଗ୍ରହ ଏବଂ ସ୍ୱପ୍ନ ବିଷୟରେ ଆଲୋଚନା କରନ୍ତି | କ୍ୟାରିୟର ନିଷ୍ପତ୍ତି ନେବାବେଳେ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ଅନ୍ୟମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା ବିଷୟରେ ନିମ୍ନଲିଖିତ ମଧ୍ୟରୁ କେଉଁଟି ସତ୍ୟ ଅଟେ:",
              "as": "অৰবিন্দে তেওঁৰ ভনী ৰোশনিৰ সৈতে তেওঁৰ আগ্ৰহ আৰু সপোনৰ বিষয়ে আলোচনা কৰে যিহেতু তাই তেওঁৰ কেৰিয়াৰ সম্পৰ্কীয় সিদ্ধান্তবোৰ প্ৰস্তুত কৰে। নিম্নলিখিত কোনটো \n  কেৰিয়াৰৰ সিদ্ধান্ত লোৱাৰ সময়ত গুৰুত্বপূৰ্ণ আনৰ সৈতে কথা পতাৰ বিষয়ে সঁচা :",
              "gu": "અરવિંદ તેની બહેન રોશની સાથે પોતાના હિતો અને સ્વપ્ના અંગે ચર્ચા કરે છે, તે પોતાની કરિયર અંગે નિર્ણય લે છે. કરિયર સંબંધિત નિર્ણય લેતી વખતે મહત્વપૂર્ણ હોય તેવા અન્ય લોકો સાથે વાત કરવા અંગે નીચે દર્શાવેલ કેટલીક મહત્વની બાબત છે:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Talking to significant others can help make decisions only after the 12 standard.",
              "hi": "महत्वपूर्ण अन्य लोगों से बात करने के लिए 12वीं कक्षा के बाद ही निर्णय लेने में सहायता मिल सकती है।",
              "ka": "ಮಹತ್ವದ ಇತರರೊಂದಿಗೆ ಮಾತನಾಡುವುದು 12 ಸ್ಟ್ಯಾಂಡರ್ಡ್ ನಂತರ ಮಾತ್ರ ನಿರ್ಧಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "ముఖ్యమైన వ్యక్తులతో మాట్లాడటం 12 స్టాండర్డ్ తర్వాత మాత్రమే నిర్ణయాలు తీసుకోవడంలో సహాయపడుతుంది.",
              "or": "ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ଅନ୍ୟମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କେବଳ 12 ଶ୍ରେଣୀ ପରେ ନିଷ୍ପତ୍ତି ନେବାରେ ସାହାଯ୍ୟ କରିଥାଏ |",
              "as": "গুৰুত্বপূৰ্ণ অন্য কাৰোবাৰ সৈতে কথা পাতিলে দ্বাদশ শ্ৰেণীৰ পিছতহে সিদ্ধান্ত লোৱাত সহায় কৰিব পাৰে।",
              "gu": "અન્ય મહત્વના લોકો સાથે વાત કરવાથી ધોરણ-12 બાદ જ નિર્ણય લેવામાં મદદ મળી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "We should follow all the advice our significant others share with us",
              "hi": "हमें उन सभी की सलाह माननी चाहिए जो हमारे महत्वपूर्ण अन्य हमारे साथ साझा करते हैं",
              "ka": "ನಮ್ಮ ಪ್ರಮುಖ ಇತರರು ನಮ್ಮೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳುವ ಎಲ್ಲಾ ಸಲಹೆಗಳನ್ನು ನಾವು ಅನುಸರಿಸಬೇಕು",
              "te": "మన ముఖ్యమైన ఇతరులు మనకు చెప్పే అన్ని సలహాలను మనం పాటించాలి",
              "or": "ଆମର ମହତ୍ୱପୂର୍ଣ୍ଣ ଅନ୍ୟମାନେ ଆମ ସହିତ ଅଂଶୀଦାର କରୁଥିବା ସମସ୍ତ ପରାମର୍ଶକୁ ଆମେ ଅନୁସରଣ କରିବା ଉଚିତ୍",
              "as": "আমি গুৰুত্বপূৰ্ণ অন্য কোনোবাই আমাৰ সৈতে আলোচনা কৰা সকলো পৰামৰ্শ অনুসৰণ কৰা উচিত",
              "gu": "અમે એવી તમામ સલાહનું પાલન કરવા માગી છીએ કે જે આપણી સાથે અન્યોને લઈ પણ ઘણું મહત્વ ધરાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Conversations with significant others only means talking to your parents.",
              "hi": "महत्वपूर्ण अन्य लोगों के साथ बातचीत का अभिप्राय है कि अपने माता-पिता से बात करना।",
              "ka": "ಮಹತ್ವದ ಇತರರೊಂದಿಗೆ ಸಂಭಾಷಣೆ ಎಂದರೆ ನಿನ್ನ ಪೋಷಕರೊಂದಿಗೆ ಮಾತನಾಡುವುದು ಮಾತ್ರ.",
              "te": "ముఖ్యమైన వ్యక్తులతో సంభాషణలు అంటే మీ తల్లిదండ్రులతో మాట్లాడటం మాత్రమే.",
              "or": "ମହତ୍ୱପୂର୍ଣ୍ଣ ଅନ୍ୟମାନଙ୍କ ସହିତ ବାର୍ତ୍ତାଳାପର ଅର୍ଥ କେବଳ ତୁମ ପିତାମାତାଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା ଅଟେ |",
              "as": "গুৰুত্বপূৰ্ণ অন্য কাৰোবাৰ সৈতে কথোপকথনৰ অৰ্থ হৈছে কেৱল আপোনাৰ পিতৃ-মাতৃৰ সৈতে কথা পতা।",
              "gu": "અન્ય મહત્વના લોકો સાથે વાત કરવી એટલે માતા-પિતા સાથે વાત કરવી."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "Your significant others can make you aware about new information about yourself",
              "hi": "आपके महत्वपूर्ण अन्य आपको अपने बारे में नई जानकारी के बारे में जागरूक कर सकते हैं",
              "ka": "ನಿನ್ನ ಮಹತ್ವದ ಇತರರು ನಿನ್ನ ಬಗ್ಗೆ ಹೊಸ ಮಾಹಿತಿಯ ಬಗ್ಗೆ ನಿನಗೆ ಅರಿವು ಮೂಡಿಸಬಹುದು",
              "te": "మీ ముఖ్యమైన వ్యక్తులు మీ గురించిన కొత్త సమాచారం గురించి మీకు తెలియజేయగలరు",
              "or": "ତୁମର ମହତ୍ୱପୂର୍ଣ୍ଣ ଅନ୍ୟମାନେ ତୁମ ବିଷୟରେ ନୂତନ ସୂଚନା ବିଷୟରେ ଅବଗତ କରାଇ ପାରନ୍ତି",
              "as": "গুৰুত্বপূৰ্ণ অন্য কোনোবাই আপোনাক আপোনাৰ বিষয়ে নতুন তথ্যৰ বিষয়ে সজাগ কৰিব পাৰে",
              "gu": "તમારા મહત્વના અન્ય લોકો તમને તમારા વિશે નવી જાણકારી અંગે માહિતગાર કરાવી શકે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Think again! You will keep making career decisions throughout your life.",
              "hi": "फिर से विचार करो! आप अपने पूरे जीवन में करियर निर्णय बनाए रखेंगे।",
              "ka": "ಪುನಃ ಆಲೋಚಿಸು! ನಿನ್ನ ಜೀವನದುದ್ದಕ್ಕೂ ನೀನು ವೃತ್ತಿ ನಿರ್ಧಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತೀರಿ.",
              "te": "మళ్లీ ఆలోచించండి! మీరు మీ జీవితాంతం కెరీర్ నిర్ణయాలు తీసుకుంటూ ఉంటారు.",
              "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ତୁମେ ତୁମ ଜୀବନସାରା କ୍ୟାରିୟର୍‌ ନିଷ୍ପତ୍ତି ନେବା ଜାରି ରଖିବ |",
              "as": "আকৌ চিন্তা কৰক! আপুনি গোটেই জীৱন কেৰিয়াৰৰ সিদ্ধান্ত লৈ থাকিব।",
              "gu": "ફરી વખત વિચારો! તમે જીવન પર્યાંત કરિયર અંગે નિર્ણય લેતા રહેશો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Think again! It is important to pay attention to the bias behind any advice.",
              "hi": "फिर से विचार करो! किसी भी सलाह के पीछे पूर्वाग्रह पर ध्यान देना महत्वपूर्ण है।",
              "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಯಾವುದೇ ಸಲಹೆಯ ಹಿಂದೆ ಪಕ್ಷಪಾತವನ್ನು ಗಮನಿಸುವುದು ಮುಖ್ಯ.",
              "te": "మళ్లీ ఆలోచించండి! ఏదైనా సలహా వెనుక ఉన్న పక్షపాతం పట్ల శ్రద్ధ చూపడం ముఖ్యం.",
              "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! କୌଣସି ପରାମର୍ଶ ପଛରେ ଥିବା ପକ୍ଷପାତିତା ପ୍ରତି ଧ୍ୟାନ ଦେବା ଜରୁରୀ ଅଟେ |",
              "as": "আকৌ চিন্তা কৰক! যিকোনো পৰামৰ্শৰ আঁৰত থকা পক্ষপাতিত্বৰ প্ৰতি মনোযোগ দিয়াটো গুৰুত্বপূৰ্ণ।",
              "gu": "ફરી વખત વિચારો! કોઈપણ સલાહ પાછળ પૂર્વાગ્રહ અંગે ધ્યાન આપવું જરૂરી છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Think again! Parents are one of many significant others. You can speak with friends, other family, mentors and role models while making career related decisions.",
              "hi": "फिर से विचार करो! माता-पिता अनेक महत्वपूर्ण लोगों में से एक हैं। करियर से संबंधित निर्णय लेने के दौरान आप मित्रों, अन्य परिवार, सलाहकारों और रोल मॉडल/ प्रेरणास्रोत के साथ बात कर सकते हैं।",
              "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಪಾಲಕರು ಅನೇಕ ಪ್ರಮುಖ ಇತರರಲ್ಲಿ ಒಬ್ಬರು. ವೃತ್ತಿ ಸಂಬಂಧಿತ ನಿರ್ಧಾರಗಳನ್ನು ಮಾಡುವಾಗ ನೀವು ಸ್ನೇಹಿತರು, ಇತರ ಕುಟುಂಬ, ಮಾರ್ಗದರ್ಶಕರು ಮತ್ತು ರೋಲ್ ಮಾಡೆಲ್‌ಗಳೊಂದಿಗೆ ಮಾತನಾಡಬಹುದು.",
              "te": "మళ్లీ ఆలోచించండి! చాలా ముఖ్యమైన వ్యక్తులలో తల్లిదండ్రులు ఒకరు. కెరీర్ సంబంధిత నిర్ణయాలు తీసుకునేటప్పుడు మీరు స్నేహితులు, ఇతర కుటుంబ సభ్యులు, మార్గదర్శకులు మరియు రోల్ మోడల్‌లతో మాట్లాడవచ్చు.",
              "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ପିତାମାତା ମହତ୍ୱପୁର୍ଣ୍ଣ ଅନ୍ୟମାନଙ୍କ ମଧ୍ୟରୁ ଅନ୍ୟତମ ଅଟନ୍ତି | କ୍ୟାରିୟର୍‌ ସମ୍ବନ୍ଧୀୟ ନିଷ୍ପତ୍ତି ନେବାବେଳେ ଆପଣ ସାଙ୍ଗ, ଅନ୍ୟ ପରିବାର, ମେଣ୍ଟର୍‌ ଏବଂ ରୋଲ୍‌ ମଡେଲ୍‌ଙ୍କ ସହିତ କଥା ହୋଇପାରିବେ |",
              "as": "আকৌ চিন্তা কৰক! অভিভাৱকসকল আন বহুতো গুৰুত্বপূৰ্ণ লোকৰ মাজৰ এজন। আপুনি কেৰিয়াৰ সম্পৰ্কীয় সিদ্ধান্ত লোৱাৰ সময়ত বন্ধুবৰ্গ, অন্যান্য পৰিয়াল, পৰামৰ্শদাতা আৰু আদৰ্শব্যক্তিৰ লগত কথা পাতিব পাৰে।",
              "gu": "ફરીથી વિચાર કરો! માતા-પિતા અન્ય મહત્વના લોકો પૈકી એક છે. કારકિર્દી સંબંધિત નિર્ણય લેવાના સમયે તમે મિત્રો, અન્ય પરિવાર, સંબંધિઓ અને રોલ મોડેલ સાથે વાત કરી શકો છો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಉತ್ತಮ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ !",
              "as": "মহান কাম!",
              "gu": "ખૂબ જ સારું"
            }
          }
        ]
      },
      "scene4": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_1",
              "hi": "X4_1_1",
              "ka": "X4_1_1",
              "te": "X4_1_1",
              "or": "X4_1_1",
              "as": "X4_1_1",
              "gu": "X4_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind completes his 10th standard exams and is now preparing for his 12th board exams.",
              "hi": "अरविंद अपनी 10वीं कक्षा परीक्षा पूरी करता है और अब 12वीं बोर्ड परीक्षाओं की तैयारी कर रहा है।",
              "ka": "ಅರವಿಂದ್ ತನ್ನ 10 ನೇ ತರಗತಿಯ ಪರೀಕ್ಷೆಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದಾನೆ ಮತ್ತು ಈಗ ತನ್ನ 12 ನೇ ಬೋರ್ಡ್ ಪರೀಕ್ಷೆಗಳಿಗೆ ತಯಾರಿ ನಡೆಸುತ್ತಿದ್ದಾನೆ.",
              "te": "అరవింద్ తన 10వ తరగతి పరీక్షలను పూర్తి చేసి ఇప్పుడు 12వ తరగతి పరీక్షలకు సిద్ధమవుతున్నాడు.",
              "or": "ଅରବିନ୍ଦ ତାଙ୍କର 10th ଶ୍ରେଣୀ ପରୀକ୍ଷା ସମାପ୍ତ କରିଛନ୍ତି ଏବଂ ବର୍ତ୍ତମାନ ତାଙ୍କର 12th ବୋର୍ଡ ପରୀକ୍ଷା ପାଇଁ ପ୍ରସ୍ତୁତ ହେଉଛନ୍ତି |",
              "as": "অৰবিন্দে তেওঁৰ দশম শ্ৰেণীৰ পৰীক্ষা সম্পূৰ্ণ কৰে আৰু এতিয়া তেওঁৰ দ্বাদশ শ্ৰেণীৰ বৰ্ড পৰীক্ষাৰ বাবে প্ৰস্তুতি চলাই আছে।",
              "gu": "અરવિંદે ધોરણ-10ની પરીક્ષા પૂરી કરી લીધી છે અને હવે તે ધોરણ-12ની બોર્ડની પરીક્ષા માટે તૈયારી કરી  રહ્યો છે"
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_2",
              "hi": "X4_1_2",
              "ka": "X4_1_2",
              "te": "X4_1_2",
              "or": "X4_1_2",
              "as": "X4_1_2",
              "gu": "X4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He and Roshni are keen on figuring out what they want to take up as careers. They both aspire for steady jobs to become financially independent and support their family.",
              "hi": "वह और रोशनी यह जानना चाहते हैं कि वे करियर के रूप में क्या लेना चाहते हैं। वे दोनों वित्तीय रूप से स्वतंत्र बनने के लिए स्थिर नौकरियों की इच्छा रखते हैं और अपने परिवार का सहयोग करते हैं।",
              "ka": "ಅವನು ಮತ್ತು ರೋಶ್ನಿ ಅವರು ವೃತ್ತಿಯಾಗಿ ಏನನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಬಯಸುತ್ತಾರೆ ಎಂಬುದನ್ನು ಕಂಡುಹಿಡಿಯಲು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ. ಅವರಿಬ್ಬರೂ ಆರ್ಥಿಕವಾಗಿ ಸ್ವತಂತ್ರರಾಗಲು ಮತ್ತು ತಮ್ಮ ಕುಟುಂಬವನ್ನು ಬೆಂಬಲಿಸಲು ಸ್ಥಿರವಾದ ಉದ್ಯೋಗಗಳನ್ನು ಬಯಸುತ್ತಾರೆ.",
              "te": "అతను మరియు రోష్ని వారు కెరీర్‌గా ఏమి తీసుకోవాలనుకుంటున్నారో తెలుసుకోవడానికి ఆసక్తిగా ఉన్నారు. వారిద్దరూ ఆర్థికంగా స్వతంత్రంగా మారడానికి మరియు వారి కుటుంబాన్ని పోషించడానికి స్థిరమైన ఉద్యోగాల కోసం చూస్తున్నారు.",
              "or": "ସେ ଏବଂ ରୋଶନି କ୍ୟାରିୟର୍‌ ଭାବରେ କଣ କରିବାକୁ ଚାହୁଁଛନ୍ତି ତାହା ଜାଣିବାକୁ ଚେଷ୍ଠା କରନ୍ତି | ଆର୍ଥିକ ସ୍ୱାଧୀନ ହେବା ଏବଂ ସେମାନଙ୍କ ପରିବାରକୁ ସମର୍ଥନ କରିବା ପାଇଁ ଉଭୟେ ସ୍ଥିର ଚାକିରି ପାଇଁ ଇଚ୍ଛା କରନ୍ତି |",
              "as": "তেওঁ আৰু ৰোশনিয়ে তেওঁলোকে কেৰিয়াৰ হিচাপে কি ল'ব বিচাৰে তাক বিচাৰি উলিয়াবলৈ আগ্ৰহী। তেওঁলোক দুয়োজনে আৰ্থিকভাৱে স্বতন্ত্ৰ হ'বলৈ আৰু তেওঁলোকৰ পৰিয়ালক সমৰ্থন কৰিবলৈ স্থায়ী চাকৰিৰ আশা কৰে।",
              "gu": "તે તથા રોશની એ ભાળ મેળવવા માટે ઉત્સુક છે કે તે કારકિર્દીના સ્વરૂપમાં શું લેવા માગે છે. તે બન્ને આર્થિક રીતે સ્વતંત્ર થવા તથા પોતાના પરિવારનું સમર્થન કરવા માટે સ્થિર નોકરી ઈચ્છે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_3",
              "hi": "X4_1_3",
              "ka": "X4_1_3",
              "te": "X4_1_3",
              "or": "X4_1_3",
              "as": "X4_1_3",
              "gu": "X4_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Roshni is keen on studying Arts in her 11th and 12th. She wants to explore something where she can learn how to deal with the different problems faced by society.",
              "hi": "रोशनी 11वीं और 12वीं में कला वर्ग का अध्ययन करना चाहती है। वह कुछ ऐसा खोजना चाहती है, जहां वह समाज द्वारा सामना की जाने वाली विभिन्न समस्याओं का  सामना कैसे करें, इसे सीख सके।",
              "ka": "ರೋಶ್ನಿ ತನ್ನ 11 ಮತ್ತು 12 ನೇ ತರಗತಿಯಲ್ಲಿ ಆರ್ಟ್ಸ್ ಕಲಿಯಲು ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ. ಸಮಾಜವು ಎದುರಿಸುತ್ತಿರುವ ವಿವಿಧ ಸಮಸ್ಯೆಗಳನ್ನು ಹೇಗೆ ಎದುರಿಸಬೇಕೆಂದು ಅವಳು ಕಲಿಯಬಹುದಾದ ಯಾವುದನ್ನಾದರೂ ಅನ್ವೇಷಿಸಲು ಅವಳು ಬಯಸುತ್ತಾಳೆ.",
              "te": "రోష్ని తన 11వ మరియు 12వ తరగతిలో ఆర్ట్స్ చదవడానికి ఆసక్తిగా ఉంది. ఆమె సమాజం ఎదుర్కొంటున్న విభిన్న సమస్యలను ఎలా ఎదుర్కోవాలో తెలుసుకోవాలనుకుంటోంది.",
              "or": "ରୋଶନି ତାଙ୍କର 11th ଏବଂ 12th ରେ କଳା ଅଧ୍ୟୟନ କରିବାକୁ ଆଗ୍ରହୀ ଅଟନ୍ତି | ସେ ଏପରି କିଛି ଅନୁସନ୍ଧାନ କରିବାକୁ ଚାହାଁନ୍ତି ଯେଉଁଠାରେ ସେ ସମାଜ ଦ୍ୱାରା ସମ୍ମୁଖୀନ ହେଉଥିବା ବିଭିନ୍ନ ସମସ୍ୟାର ମୁକାବିଲା କରିବାକୁ ଶିଖିପାରିବେ |",
              "as": "ৰোশনি তাইৰ একাদশ আৰু দ্বাদশ শ্ৰেণীত কলা শাখাত পঢ়িবলৈ আগ্ৰহী। তাই এনে কিবা এটা অনুসন্ধান কৰিব বিচাৰে য'ত তাই সমাজে সন্মুখীন হোৱা বিভিন্ন সমস্যাৰ সৈতে কেনেদৰে মোকাবিলা কৰিব লাগে শিকিব পাৰে।",
              "gu": "રોશની ધોરણ-11 અને ધોરણ-12માં આર્ટ્સનો અભ્યાસ કરવા માગે છે. તે કંઈક એવું શોધવા માગે છે કે જ્યાં તે શીખી શકે કે સમાજની સામે આવતી વિવિધ સમસ્યાઓથી કેવી રીતે સામનો કરવો."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_1_4",
              "hi": "X4_1_4",
              "ka": "X4_1_4",
              "te": "X4_1_4",
              "or": "X4_1_4",
              "as": "X4_1_4",
              "gu": "X4_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind knows he is interested in technology and how it can help people. He is keen on finding out more about the possibilities of engineering and what career opportunities exist.",
              "hi": "अरविंद जानता है कि वह प्रौद्योगिकी में रुचि रखता है और यह लोगों की सहायता कैसे कर सकता है। वह इंजीनियरिंग की संभावनाओं के बारे में और कैरियर के कौन से अवसर हो सकते हैं, जानने के लिए उत्सुक है।",
              "ka": "ಅರವಿಂದನಿಗೆ ತಂತ್ರಜ್ಞಾನದಲ್ಲಿ ಆಸಕ್ತಿ ಇದೆ ಮತ್ತು ಅದು ಜನರಿಗೆ ಹೇಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ ಎಂದು ತಿಳಿದಿದೆ. ಎಂಜಿನಿಯರಿಂಗ್‌ನ ಸಾಧ್ಯತೆಗಳು ಮತ್ತು ಯಾವ ವೃತ್ತಿ ಅವಕಾಶಗಳು ಅಸ್ತಿತ್ವದಲ್ಲಿವೆ ಎಂಬುದರ ಕುರಿತು ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಅವರು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ.",
              "te": "అరవింద్‌కు టెక్నాలజీపై ఆసక్తి ఉందని, అది ప్రజలకు ఎలా ఉపయోగపడుతుందని తెలుసు. అతను ఇంజినీరింగ్ యొక్క అవకాశాల గురించి మరియు కెరీర్ అవకాశాల గురించి మరింత తెలుసుకోవడానికి ఆసక్తిని కలిగి ఉన్నాడు.",
              "or": "ଅରବିନ୍ଦ ଜାଣନ୍ତି ଯେ ସେ ଟେକ୍ନୋଲୋଜି ପ୍ରତି ଆଗ୍ରହୀ ଏବଂ ଏହା କିପରି ଲୋକଙ୍କୁ ସାହାଯ୍ୟ କରିପାରିବ | ଇଞ୍ଜିନିୟରିଂର ସମ୍ଭାବନା ଏବଂ କ୍ୟାରିୟର୍‌ର ସୁଯୋଗ ବିଦ୍ୟମାନ ବିଷୟରେ ସେ ଅଧିକ ଜାଣିବାକୁ ଆଗ୍ରହୀ |",
              "as": "অৰবিন্দে জানে যে তেওঁ প্ৰযুক্তিবিদ্যাৰ প্ৰতি আগ্ৰহী আৰু ই মানুহক কেনেকৈ সহায় কৰিব পাৰে। তেওঁ অভিযান্ত্ৰিকৰ সম্ভাৱনা আৰু কেৰিয়াৰৰ কি সুযোগ আছে সেই বিষয়ে অধিক জানিবলৈ আগ্ৰহী।",
              "gu": "અરવિંદ જાણે છે કે તેને ટેકનિકલ રીતે રસ ધરાવે છે અને તે કેવા લોગોની મદદ કરી શકે છે. તે એન્જીનિયરિંગની સંભાવનાઓ અંગે તથા કરિયરને લગતી તકો અંગે વિશેષ જાણવા ઈચ્છે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_2_1",
              "hi": "X4_2_1",
              "ka": "X4_2_1",
              "te": "X4_2_1",
              "or": "X4_2_1",
              "as": "X4_2_1",
              "gu": "X4_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He reaches out to his cousin, Akshara, who has studied engineering before moving into business management. Roshni is also keen to hear what Akshara has to share.",
              "hi": "वह अपने चचेरी बहन, अक्षरा से मिलता है, जिसने व्यवसाय प्रबंधन में जाने से पहले इंजीनियरिंग का अध्ययन किया है। रोशनी भी जानने के लिए उत्सुक है कि अक्षरा क्या साझा करना चाहती है।",
              "ka": "ಅವನು ತನ್ನ ಸೋದರಸಂಬಂಧಿ ಅಕ್ಷರಾ ಅವರನ್ನು ತಲುಪುತ್ತಾನೆ, ಅವರು ವ್ಯಾಪಾರ ನಿರ್ವಹಣೆಗೆ ಹೋಗುವ ಮೊದಲು ಎಂಜಿನಿಯರಿಂಗ್ ಓದಿದ್ದಾರೆ. ಅಕ್ಷರಾ ಅವರು ಏನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ ಎಂಬುದನ್ನು ಕೇಳಲು ರೋಶ್ನಿ ಕೂಡ ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ.",
              "te": "అతను బిజినెస్ మేనేజ్‌మెంట్‌లోకి వెళ్లే ముందు ఇంజనీరింగ్ చదివిన తన బంధువు అక్షరను కలుస్తాడు. రోష్ని కూడా అక్షర ఏమి చెప్తుందో వినడానికి ఆసక్తిగా ఉంది.",
              "or": "ସେ ତାଙ୍କ ସମ୍ପର୍କୀୟ ଅକ୍ଷରାଙ୍କ ନିକଟରେ ପହଞ୍ଚିଛନ୍ତି, ଯିଏକି ବିଜନେସ୍‌ ମ୍ୟାନେଜମେଣ୍ଟ୍‍କୁ ଯିବା ପୂର୍ବରୁ ଇଞ୍ଜିନିୟରିଂ ଅଧ୍ୟୟନ କରିଛନ୍ତି | ଅକ୍ଷରା କଣ ଅଂଶୀଦାର କରିବେ ରୋଶନି ଶୁଣିବାକୁ ମଧ୍ୟ ଆଗ୍ରହୀ |",
              "as": "তেওঁ তেওঁৰ সম্পৰ্কীয় ভনী অক্ষৰাৰ ওচৰলৈ যায়, যিগৰাকীয়ে ব্যৱসায় ব্যৱস্থাপনালৈ যোৱাৰ আগতে অভিযান্ত্ৰিক অধ্যয়ন কৰিছে। অক্ষৰাই কিনো কথা পাতিব সেয়া শুনিবলৈ ৰোশনিও আগ্ৰহী।",
              "gu": "તે પોતાના પિત્રાઈ ભાઈ અક્ષરા પાસે જાય છે, જેણે વ્યવસાયિક સંચાલનમાં જતા પહેલા એન્જીનિયરિંગનો અભ્યાસ કર્યો છે. રોશની પણ એ જાણવા માટે ઉત્સુક છે કે અક્ષરા શું શેર કરવા ઈચ્છે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_2_2",
              "hi": "X4_2_2",
              "ka": "X4_2_2",
              "te": "X4_2_2",
              "or": "X4_2_2",
              "as": "X4_2_2",
              "gu": "X4_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They take their father's phone and call her. Akshara is happy to hear from her cousins after a long time.",
              "hi": "वे अपने पिता के फोन से उसे कॉल करते हैं। अक्षरा लंबे समय के बाद अपने चचेरे भाई बहन से बात करके खुश है।",
              "ka": "ಅವರು ತಮ್ಮ ತಂದೆಯ ಫೋನ್ ತೆಗೆದುಕೊಂಡು ಅವಳಿಗೆ ಕರೆ ಮಾಡುತ್ತಾರೆ. ಬಹಳ ದಿನಗಳ ನಂತರ ಸೋದರ ಸಂಬಂಧಿ ಮಾತು ಕೇಳಿ ಅಕ್ಷರಾ ಖುಷಿಯಾಗಿದ್ದಾರೆ.",
              "te": "వాళ్ళ నాన్న ఫోన్ తీసుకుని ఆమెకు కాల్ చేసారు. చాలా కాలం తర్వాత తన కజిన్స్ తో మాట్లాడడం అక్షరకి సంతోషంగా ఉంది.",
              "or": "ସେମାନେ ତାଙ୍କ ପିତାଙ୍କ ଫୋନ୍ ନିଅନ୍ତି ଏବଂ ତାଙ୍କୁ କଲ୍‌ କରନ୍ତି | ଦୀର୍ଘ ସମୟ ପରେ ଅକ୍ଷରା ତାଙ୍କ ସହୋଦରଙ୍କଠାରୁ ଶୁଣି ଖୁସି ହୁଅନ୍ତି |",
              "as": "তেওঁলোকে তেওঁলোকৰ দেউতাকৰ ফোনটো লয় আৰু তাইক ফোন কৰে। অক্ষৰাই বহুদিনৰ পিছত তাইৰ সম্পৰ্কীয় ভায়েক-ভনীয়েকসকলৰ মাত শুনি সুখী হৈছে।",
              "gu": "તે તેના પિતાનો ફોન લે છે અને તેમને ફોન કરે છે. અક્ષરા લાંબા સમય બાદ તેના પિત્રાઈ ભાઈઓની વાત સાંભળીને ખુશ થાય છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_2_3",
              "hi": "X4_2_3",
              "ka": "X4_2_3",
              "te": "X4_2_3",
              "or": "X4_2_3",
              "as": "X4_2_3",
              "gu": "X4_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "While asking questions about her work, Arvind says that he would love to visit her office and see her work in person.",
              "hi": "उसके काम के बारे में सवाल पूछते हुए, अरविंद कहता है कि उसे उसके कार्यालय में जाना और उसको सामने से काम करता देखना उसे बहुत अच्छा लगेगा।",
              "ka": "ಆಕೆಯ ಕೆಲಸದ ಬಗ್ಗೆ ಪ್ರಶ್ನೆಗಳನ್ನು ಕೇಳುವಾಗ, ಅರವಿಂದ ಅವರೊಂದಿಗೆ ಅವರ ಕಚೇರಿಗೆ ಭೇಟಿ ನೀಡಲು ಮತ್ತು ಅವರ ಕೆಲಸವನ್ನು ಖುದ್ದಾಗಿ ನೋಡಲು ಇಷ್ಟಪಡುತ್ತಾನೆ ಎಂದು ಹೇಳುತ್ತಾನೆ.",
              "te": "ఆమె పని గురించి ప్రశ్నలు అడుగుతున్నప్పుడు, అరవింద్ ఆమె ఆఫీస్ కి వెళ్ళి ఆమె పనిని ప్రత్యక్షంగా చూడటానికి ఇష్టపడుతున్నట్లు చెప్పాడు.",
              "or": "ତାଙ୍କ କାର୍ଯ୍ୟ ବିଷୟରେ ପ୍ରଶ୍ନ ପଚାରିଥିବାବେଳେ, ଅରବିନ୍ଦ କହନ୍ତି ଯେ ସେ ତାଙ୍କ କାର୍ଯ୍ୟାଳୟ ପରିଦର୍ଶନ କରିବାକୁ ଏବଂ ତାଙ୍କ କାର୍ଯ୍ୟକୁ ବ୍ୟକ୍ତିଗତ ଭାବରେ ଦେଖିବାକୁ ପସନ୍ଦ କରିବେ |",
              "as": "তাইৰ কামৰ বিষয়ে প্ৰশ্ন সোধাৰ সময়ত, অৰবিন্দে কয় যে সি তাইৰ অফিচলৈ গৈ তাইৰ কাম ব্যক্তিগতভাৱে চাবলৈ ভাল পাব।",
              "gu": "તેમના કામ અંગે પ્રશ્ન પૂછે છે, અરવિંદ કહે છે કે તે તેમના કાર્યકાળમાં જવા તથા વ્યક્તિગત રીતે તેમના કામ જોવાનું પસંદ કરશે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_2_4",
              "hi": "X4_2_4",
              "ka": "X4_2_4",
              "te": "X4_2_4",
              "or": "X4_2_4",
              "as": "X4_2_4",
              "gu": "X4_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Akshara is happy that her cousins want to know more about her work and offers to give them a tour of her office on Saturday when the office is less busy.",
              "hi": "अक्षरा खुश है कि उसके चचेरे भाई बहन उसके काम के बारे में और जानना चाहते हैं और उन्हें शनिवार को अपने कार्यालय का दौरा करने का प्रस्ताव रखती है जब कार्यालय में व्यस्तता कम होती है।",
              "ka": "ತನ್ನ ಸೋದರಸಂಬಂಧಿಗಳು ತನ್ನ ಕೆಲಸದ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಬಯಸುತ್ತಾರೆ ಎಂದು ಅಕ್ಷರಾ ಸಂತೋಷಪಡುತ್ತಾಳೆ ಮತ್ತು ಕಚೇರಿಯಲ್ಲಿ ಕಡಿಮೆ ಕಾರ್ಯನಿರತವಾಗಿರುವ ಶನಿವಾರದಂದು ಅವರಿಗೆ ತನ್ನ ಕಚೇರಿಗೆ ಪ್ರವಾಸವನ್ನು ನೀಡಲು ಮುಂದಾಗುತ್ತಾಳೆ.",
              "te": "తన కజిన్స్ తన పని గురించి మరింత తెలుసుకోవాలని కోరుకుంటున్నందుకు అక్షర సంతోషంగా ఉంది మరియు ఆఫీసులో రద్దీ తక్కువగా ఉన్నప్పుడు శనివారం తన ఆఫీసుని సందర్శించమని చెప్తుంది.",
              "or": "ଅକ୍ଷରା ଖୁସି ଯେ ତାଙ୍କ ଭାଇମାନେ ତାଙ୍କ କାର୍ଯ୍ୟ ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ଚାହାଁନ୍ତି ଏବଂ ଶନିବାର ଦିନ ଅଫିସ୍ କମ୍ ବ୍ୟସ୍ତ ଥିବାବେଳେ ତାଙ୍କୁ ତାଙ୍କ କାର୍ଯ୍ୟାଳୟ ପରିଦର୍ଶନ କରିବାକୁ ଅଫର୍ ଦିଅନ୍ତି |",
              "as": "অক্ষৰা সুখী যে তাইৰ সম্পৰ্কীয় ভায়েক-ভনীয়েকসকলে তাইৰ কামৰ বিষয়ে অধিক জানিব বিচাৰে আৰু শনিবাৰে যেতিয়া অফিচত ব্যস্ততা কম থাকে তেতিয়া তেওঁলোকক তাইৰ অফিচলৈ আহিবলৈ কয়।",
              "gu": "અક્ષરા ખુશ છે કે તેના પિત્રાઈ ભાઈ તેમના કામ અંગે વધારે જાણકારી ઈચ્છે છે અને શનિવારે કાર્યકાળમાં ઓછા વ્યસ્ત હોવાના સંજોગોમાં તેમના કાર્યકાળ સમયે રજૂ કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_2_5//",
              "hi": "X4_2_5 //",
              "ka": "X4_2_5//",
              "te": "X4_2_5//",
              "or": "X4_2_5//",
              "as": "X4_2_5//",
              "gu": "X4_2_5//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Arvind and Roshni are overjoyed and thank their cousin.",
              "hi": "अरविंद और रोशनी बहुत खुश हैं और अपनी चचेरी बहन का धन्यवाद करते हैं।",
              "ka": "ಅರವಿಂದ್ ಮತ್ತು ರೋಶ್ನಿ ತುಂಬಾ ಸಂತೋಷಪಟ್ಟಿದ್ದಾರೆ ಮತ್ತು ಅವರ ಸೋದರಸಂಬಂಧಿಗೆ ಧನ್ಯವಾದಗಳು.",
              "te": "అరవింద్ మరియు రోష్ని చాలా ఆనందంగా ఉన్నారు మరియు వారి కజిన్‌కి కృతజ్ఞతలు తెలిపారు.",
              "or": "ଅରବିନ୍ଦ ଏବଂ ରୋଶନି ଅତ୍ୟଧିକ ଆନନ୍ଦିତ ଏବଂ ସେମାନଙ୍କ ସହୋଦରକୁ ଧନ୍ୟବାଦ ଦିଅନ୍ତି |",
              "as": "অৰবিন্দ আৰু ৰোশনিয়ে ভাল পাইছে আৰু তেওঁলোকৰ সম্পৰ্কীয় ভনীয়েকজনীক ধন্যবাদ জনাইছে।",
              "gu": "અરવિંદ અને રોશની ઘણા ખુશ છે અને તેમના પિત્રાઈ ભાઈનો આભાર વ્યક્ત કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Arvind, this is great! You are so good at talking to people.",
              "hi": "अरविंद, यह बहुत अच्छा है! तुम लोगों से बात करने में बहुत अच्छे हो।",
              "ka": "ಅರವಿಂದ್, ಇದು ಅದ್ಭುತವಾಗಿದೆ! ನೀವು ಜನರೊಂದಿಗೆ ಮಾತನಾಡುವುದರಲ್ಲಿ ತುಂಬಾ ಉತ್ತಮರು.",
              "te": "అరవింద్, ఇది చాలా బాగుంది! మీరు అందరితో బాగా మాట్లాడుతావు.",
              "or": "ଅରବିନ୍ଦ, ଏହା ଉତ୍ତମ ! ତୁମେ ଲୋକଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବାରେ ବହୁତ ଭଲ |",
              "as": "অৰবিন্দ, এইটো বহুত ভাল কথা! আপুনি মানুহৰ সৈতে কথা পতাত ইমান ভাল।",
              "gu": "અરવિંદ, આ ઘણુ સારું છે! તમારા લોકોની સાથે વાત કરવામાં મને ઘણુ સારું લાગ્યું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Akshara is our cousin. She was not going to say no.",
              "hi": "अक्षरा हमारी चचेरी बहन है।  वह मना नहीं करने वाली थी।",
              "ka": "ಅಕ್ಷರ ನಮ್ಮ ಸೋದರ ಸಂಬಂಧಿ. ಅವಳು ಬೇಡ ಎನ್ನಲು ಹೋಗುತ್ತಿರಲಿಲ್ಲ.",
              "te": "అక్షర మన కజిన్. ఆమె నో చెప్పదు.",
              "or": "ଅକ୍ଷରା ଆମର ସହୋଦର | ସେ ନା କହିବାକୁ ଯାଉନଥିଲେ |",
              "as": "অক্ষৰা আমাৰ সম্পৰ্কীয় ভনী। তাই কেতিয়াও আমাক না নকয়।",
              "gu": "અક્ષરા અમારી પિત્રાઈ છે. તેણીની કંઈ કહેવા જઈ રહી ન હતી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "But I can't get information about my courses like this. I am not good at talking to people, and I don't even know who to talk to.",
              "hi": "लेकिन मुझे इस तरह के मेरे पाठ्यक्रम के बारे में जानकारी नहीं मिल रही है। मैं लोगों से बात करने में अच्छी नहीं हूं, और मैं यह भी नहीं जानता कि किससे बात करना है।",
              "ka": "ಆದರೆ ಈ ರೀತಿಯ ನನ್ನ ಕೋರ್ಸ್‌ಗಳ ಬಗ್ಗೆ ನನಗೆ ಮಾಹಿತಿ ಸಿಗುವುದಿಲ್ಲ. ನಾನು ಜನರೊಂದಿಗೆ ಮಾತನಾಡಲು ಸರಿಯಾಗಿಲ್ಲ, ಮತ್ತು ಯಾರೊಂದಿಗೆ ಮಾತನಾಡಬೇಕೆಂದು ನನಗೆ ತಿಳಿದಿಲ್ಲ.",
              "te": "కానీ నేను ఇలాంటి నా కోర్సుల గురించి సమాచారాన్ని పొందలేను. నేను వ్యక్తులతో మాట్లాడటం మంచిది కాదు, ఎవరితో మాట్లాడాలో కూడా నాకు తెలియదు.",
              "or": "କିନ୍ତୁ ମୁଁ ଏହି ପରି ମୋ ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ସୂଚନା ପାଇପାରୁ ନାହିଁ | ମୁଁ ଲୋକଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବାରେ ଭଲ ନୁହେଁ, ଏବଂ କାହା ସହିତ କଥାବାର୍ତ୍ତା କରିବାକୁ ହେବ ମଧ୍ୟ ମୁଁ ଜାଣେ ନାହିଁ |",
              "as": "কিন্তু মই মোৰ পাঠ্যক্ৰমৰ বিষয়ে এনেধৰণে তথ্য লাভ কৰিব নোৱাৰো। মই মানুহৰ সৈতে কথা পতাত ভাল নহয়, আৰু আনকি কাৰ সৈতে কথা পাতিব লাগে মই সেইটোও নাজানো।",
              "gu": "પણ મને મારા પાઠ્ય અભ્યાસક્રમો અંગે આ અંગે કોઈ જાણકારી મળી શકી નથી. મને લોકો સાથે વાતચીત કરવામાં સારું લાગતું નથી, અને મને એ પણ માલુમ નથી કે કોની સાથે વાત કરવાની છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "Don't worry, I'll help you do your research.",
              "hi": "चिंता मत करो, मैं शोध करने में तुम्हारी सहायता करूंगा।",
              "ka": "ಚಿಂತಿಸಬೇಡಿ, ನಿನ್ನ ಸಂಶೋಧನೆ ಮಾಡಲು ನಾನು ನಿನಗೆ ಸಹಾಯ ಮಾಡುತ್ತೇನೆ.",
              "te": "చింతించకండి, నీ రీసర్చ్ కోసం నేను సహాయం చేస్తాను.",
              "or": "ବ୍ୟସ୍ତ ହୁଅ ନାହିଁ, ମୁଁ ତୁମର ଅନୁସନ୍ଧାନ କରିବାରେ ତୁମକୁ ସାହାଯ୍ୟ କରିବି |",
              "as": "চিন্তা নকৰিবা, মই তোমাক তোমাৰ গৱেষণাত সহায় কৰিম।",
              "gu": "ચિંતા ન કરીશ, હું તારા સંશોધનમાં મદદરૂપ બનીશ."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4_2_6_Checklist",
              "hi": "X4_2_6_चेकलिस्ट ",
              "ka": "X4_2_6_ಪರಿಶೀಲನಾಪಟ್ಟಿ",
              "te": "X4_2_6_చెక్‌లిస్ట్",
              "or": "X4_2_6_Checklist",
              "as": "X4_2_6_Checklist",
              "gu": "X4_2_6_તપાસયાદી"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Arvind has two tasks on his list. Click on each task to complete it.",
              "hi": "अरविंद की सूची में दो कार्य हैं। इसे पूरा करने के लिए प्रत्येक कार्य पर क्लिक करें।",
              "ka": "ಅರವಿಂದ್ ಅವರ ಪಟ್ಟಿಯಲ್ಲಿ ಎರಡು ಕೆಲಸಗಳಿವೆ. ಪ್ರತಿ ಕೆಲಸವನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ಅದರ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "అరవింద్ లిస్టులో రెండు పనులు ఉన్నాయి. ప్రతి పనిని పూర్తి చేయడానికి దానిపై క్లిక్ చేయండి.",
              "or": "ତାଙ୍କ ତାଲିକାରେ ଅରବିନ୍ଦଙ୍କର ଦୁଇଟି ଟାସ୍କ୍‌ ଅଛି | ଏହାକୁ ସମ୍ପୂର୍ଣ୍ଣ କରିବାକୁ ପ୍ରତ୍ୟେକ ଟାସ୍କ୍‌ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "অৰবিন্দৰ তালিকাত দুটা কাম আছে। ইয়াক সম্পূৰ্ণ কৰিবলৈ প্ৰতিটো কামত ক্লিক কৰক।",
              "gu": "અરવિંદની નામની યાદીમાં બે કાર્ય છે. દરેક કાર્યને પૂરા કરવા માટે તેની ઉપર ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Visit Akshara's office",
              "hi": "अक्षरा के कार्यालय की यात्रा करे",
              "ka": "ಅಕ್ಷರ ಕಚೇರಿಗೆ ಭೇಟಿ ನೀಡಿ",
              "te": "అక్షర ఆఫీస్ ని సందర్శించడం",
              "or": "ଅକ୍ଷରାଙ୍କ କାର୍ଯ୍ୟାଳୟ ପରିଦର୍ଶନ କରିବା",
              "as": "অক্ষৰাৰ অফিচলৈ যাওক",
              "gu": "અક્ષરાની ઓફિસ ખાતે જાઓ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Help Roshni in her research",
              "hi": " शोध में रोशनी की सहायता करे",
              "ka": "ರೋಶ್ನಿ ಅವರ ಸಂಶೋಧನೆಯಲ್ಲಿ ಸಹಾಯ ಮಾಡಿ",
              "te": "రోష్నికి రీసర్చ్ లో సహాయం చేయడం",
              "or": "ରୋଶନିଙ୍କୁ ତାଙ୍କ ଅନୁସନ୍ଧାନରେ ସାହାଯ୍ୟ କରିବା",
              "as": "ৰোশনীক তাইৰ গৱেষণাত সহায় কৰক",
              "gu": "રોશનીને તેના સંશોધનમાં મદદ કરો"
            }
          }
        ]
      },
      "scene5": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4i_1_1",
              "hi": "X4i_1_1",
              "ka": "X4i_1_1",
              "te": "X4i_1_1",
              "or": "X4i_1_1",
              "as": "X4i_1_1",
              "gu": "X4i_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind and Roshni visit Akshara's office in Hyderabad over the weekend. She works as a software engineer at Prilworks Software.",
              "hi": "अरविंद और रोशनी सप्ताहांत में हैदराबाद में अक्षरा के कार्यालय की यात्रा करते हैं। वह प्रिलवर्क्स सॉफ्टवेयर में एक सॉफ्टवेयर इंजीनियर के रूप में काम करती है।",
              "ka": "ವಾರಾಂತ್ಯದಲ್ಲಿ ಅರವಿಂದ್ ಮತ್ತು ರೋಶ್ನಿ ಹೈದರಾಬಾದ್‌ನಲ್ಲಿರುವ ಅಕ್ಷರ ಅವರ ಕಚೇರಿಗೆ ಭೇಟಿ ನೀಡುತ್ತಾರೆ. ಅವಳು ಪ್ರಿಲ್‌ವರ್ಕ್ಸ್ ಸಾಫ್ಟ್‌ವೇರ್‌ನಲ್ಲಿ ಸಾಫ್ಟ್‌ವೇರ್ ಇಂಜಿನಿಯರ್ ಆಗಿ ಕೆಲಸ ಮಾಡುತ್ತಾಳೆ.",
              "te": "అరవింద్ మరియు రోష్ని వారాంతంలో హైదరాబాద్‌లోని అక్షర ఆఫీస్ ని సందర్శించారు. ఆమె ప్రిల్‌వర్క్స్ సాఫ్ట్‌వేర్‌లో సాఫ్ట్‌వేర్ ఇంజనీర్‌గా పనిచేస్తుంది.",
              "or": "ସପ୍ତାହ ଶେଷରେ ଅରବିନ୍ଦ ଏବଂ ରୋଶନୀ ହାଇଦ୍ରାବାଦରେ ଅକ୍ଷରାଙ୍କ କାର୍ଯ୍ୟାଳୟ ପରିଦର୍ଶନ କରନ୍ତି | ସେ ପ୍ରିଲୱାର୍କ ସଫ୍ଟୱେୟରରେ ଏକ ସଫ୍ଟୱେୟର ଇଞ୍ଜିନିୟର ଭାବରେ କାର୍ଯ୍ୟ କରନ୍ତି |",
              "as": "অৰবিন্দ আৰু ৰোশনিয়ে সপ্তাহটোৰ শেষত হায়দৰাবাদত থকা অক্ষৰাৰ অফিচলৈ যায়। তাই প্ৰিলৱৰ্কছ ছফ্টৱেৰত ছফ্টৱেৰ অভিযন্তা হিচাপে কাম কৰে।",
              "gu": "અરવિંદ અને રોશની સપ્તાહના અંતે અક્ષરાના હૈદરાબાદ સ્થિત ઓફિસ જાય છે. તે પ્રિલ્વર્ક્સ સોફ્ટવેરમાં સોફ્ટવેર એન્જીનિયર તરીકે કામ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4i_1_2",
              "hi": "X4i_1_2",
              "ka": "X4i_1_2",
              "te": "X4i_1_2",
              "or": "X4i_1_2",
              "as": "X4i_1_2",
              "gu": "X4i_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind and Roshni are excited as they have never visited an office space before.",
              "hi": "अरविंद और रोशनी उत्साहित हैं क्योंकि उन्होंने पहले कभी कार्यालय नहीं देखा है।",
              "ka": "ಅರವಿಂದ್ ಮತ್ತು ರೋಶ್ನಿ ಅವರು ಇದುವರೆಗೆ ಕಚೇರಿ ಸ್ಥಳಕ್ಕೆ ಭೇಟಿ ನೀಡಿಲ್ಲ ಎಂದು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ.",
              "te": "అరవింద్ మరియు రోష్ని ఇంతకు ముందెన్నడూ ఆఫీస్ స్పేస్‌ని సందర్శించలేదు కాబట్టి ఉత్సాహంగా ఉన్నారు.",
              "or": "ଅରବିନ୍ଦ ଏବଂ ରୋଶନି ଉତ୍ସାହିତ ଅଛନ୍ତି କାରଣ ସେମାନେ ପୂର୍ବରୁ କେବେ କୌଣସି ଅଫିସ୍ ସ୍ଥାନ ପରିଦର୍ଶନ କରିନାହାଁନ୍ତି |",
              "as": "অৰবিন্দ আৰু ৰোশনি উৎসাহিত কিয়নো তেওঁলোকে আগতে কেতিয়াও অফিচৰ ভিতৰখন দেখা নাছিল।",
              "gu": "અરવિંદ અને રોશની ઉત્સાહિત છે કારણ કે તેમણે અગાઉ ક્યારેય કોઈ ઓફિસનો પ્રવાસ કર્યો ન હતો."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4i_1_3",
              "hi": "X4i_1_3",
              "ka": "X4i_1_3",
              "te": "X4i_1_3",
              "or": "X4i_1_3",
              "as": "X4i_1_3",
              "gu": "X4i_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Akshara shows them around the different departments at her office and their work. She explains that her company makes customised apps for businesses.",
              "hi": "अक्षरा उन्हें अपने कार्यालय और अपने काम पर विभिन्न विभागों के आसपास दिखाती है। वह बताती है कि उनकी कंपनी व्यवसायों के लिए अनुकूलित ऐप्स बनाती है।",
              "ka": "ಅಕ್ಷರಾ ಅವರಿಗೆ ತನ್ನ ಕಛೇರಿಯಲ್ಲಿನ ವಿವಿಧ ವಿಭಾಗಗಳು ಮತ್ತು ಅವರ ಕೆಲಸವನ್ನು ತೋರಿಸುತ್ತಾಳೆ. ತನ್ನ ಕಂಪನಿಯು ವ್ಯವಹಾರಗಳಿಗಾಗಿ ಕಸ್ಟಮೈಸ್ ಮಾಡಿದ ಅಪ್ಲಿಕೇಶನ್‌ಗಳನ್ನು ಮಾಡುತ್ತದೆ ಎಂದು ಅವರು ವಿವರಿಸುತ್ತಾರೆ.",
              "te": "అక్షర తన కార్యాలయంలోని వివిధ విభాగాలను మరియు తన పనిని వారికి చూపుతుంది. తన కంపెనీ వ్యాపారాల కోసం కస్టమైజ్డ్ యాప్‌లను తయారు చేస్తుందని ఆమె వివరిస్తుంది.",
              "or": "ଅକ୍ଷରା ସେମାନଙ୍କୁ ତାଙ୍କ କାର୍ଯ୍ୟାଳୟରେ ବିଭିନ୍ନ ବିଭାଗ ଏବଂ ସେମାନଙ୍କ କାର୍ଯ୍ୟ ଦେଖାଏ | ସେ ବ୍ୟାଖ୍ୟା କରିଛନ୍ତି ଯେ ତାଙ୍କ କମ୍ପାନୀ ବ୍ୟବସାୟଗୁଡିକ ପାଇଁ କଷ୍ଟୋମାଇଜ୍ ଆପ୍ ତିଆରି କରେ |",
              "as": "অক্ষৰাই তেওঁলোকক তাইৰ অফিচৰ বিভিন্ন বিভাগ আৰু তেওঁলোকৰ কামবোৰ চাৰিওফালে দেখুৱাইছিল। তাই তাইৰ কোম্পানীয়ে ব্যৱসায়ৰ বাবে অনুকূলিত এপ তৈয়াৰ কৰাৰ বিষয়ে বৰ্ণনা কৰিছে।",
              "gu": "અક્ષરા તેમને પોતાની ઓફિસમાં વિવિધ વિભાગો તથા તેમના કાર્ય અંગે માહિતી આપી છે. તે કહે છે કે તેમની કંપની વ્યવસાયો માટે અનુકૂળ એપ્સ તૈયાર કરે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4i_1_4",
              "hi": "X4i_1_4",
              "ka": "X4i_1_4",
              "te": "X4i_1_4",
              "or": "X4i_1_4",
              "as": "X4i_1_4",
              "gu": "X4i_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "These apps help businesses conduct their operations more efficiently.",
              "hi": "ये ऐप्स व्यवसायों को अपने परिचालनों/ ऑपरेशन्स को अधिक कुशलता से संचालित करने में सहायता करते हैं।",
              "ka": "ಈ ಅಪ್ಲಿಕೇಶನ್‌ಗಳು ವ್ಯಾಪಾರಗಳು ತಮ್ಮ ಕಾರ್ಯಾಚರಣೆಗಳನ್ನು ಹೆಚ್ಚು ಪರಿಣಾಮಕಾರಿಯಾಗಿ ನಡೆಸಲು ಸಹಾಯ ಮಾಡುತ್ತವೆ.",
              "te": "ఈ యాప్‌లు వ్యాపారాలు తమ కార్యకలాపాలను మరింత సమర్థవంతంగా నిర్వహించడంలో సహాయపడతాయి.",
              "or": "ଏହି ଆପ୍‌ଗୁଡିକ ବ୍ୟବସାୟକୁ ସେମାନଙ୍କର କାର୍ଯ୍ୟକୁ ଅଧିକ ଦକ୍ଷତାର ସହିତ ପରିଚାଳନା କରିବାରେ ସାହାଯ୍ୟ କରେ |",
              "as": "এই এপবোৰে ব্যৱসায়বোৰক তেওঁলোকৰ কামবোৰ বেছি ভালদৰে পৰিচালনা কৰাত সহায় কৰে।",
              "gu": "આ એપ વ્યવસાયોને તેમના સંચાલન વધારે કુશળતાથી ચલાવવામાં મદદ કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4i_1_4b",
              "hi": "X4i_1_4b",
              "ka": "X4i_1_4b",
              "te": "X4i_1_4b",
              "or": "X4i_1_4b",
              "as": "X4i_1_4b",
              "gu": "X4i_1_4b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She is showing them an app they are currently making for an event management company. The app will help event organisers keep track of all the inventory they use in an event.",
              "hi": "वह उन्हें एक ऐप दिखा रही है जो वे वर्तमान में एक इवेंट मैनेजमेंट कंपनी के लिए बना रहे हैं। ऐप घटना आयोजकों को एक घटना में उपयोग की जाने वाली सभी सूची का ट्रैक रखने में सहायता करेगा।",
              "ka": "ಅವರು ಪ್ರಸ್ತುತ ಈವೆಂಟ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್ ಕಂಪನಿಗಾಗಿ ತಯಾರಿಸುತ್ತಿರುವ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಅವರಿಗೆ ತೋರಿಸುತ್ತಿದ್ದಾರೆ. ಈವೆಂಟ್ ಸಂಘಟಕರು ಈವೆಂಟ್‌ನಲ್ಲಿ ಅವರು ಬಳಸುವ ಎಲ್ಲಾ ದಾಸ್ತಾನುಗಳನ್ನು ಟ್ರ್ಯಾಕ್ ಮಾಡಲು ಅಪ್ಲಿಕೇಶನ್ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "ప్రస్తుతం ఈవెంట్ మేనేజ్‌మెంట్ కంపెనీ కోసం తయారు చేస్తున్న యాప్‌ను ఆమె వారికి చూపుతోంది. ఈవెంట్ నిర్వాహకులు ఈవెంట్‌లో ఉపయోగించే మొత్తం ఇన్వెంటరీని ట్రాక్ చేయడానికి యాప్ సహాయం చేస్తుంది.",
              "or": "ସେ ବର୍ତ୍ତମାନ ଏକ ଇଭେଣ୍ଟ ମ୍ୟାନେଜମେଣ୍ଟ କମ୍ପାନୀ ପାଇଁ ତିଆରି କରୁଥିବା ଏକ ଆପ୍ ସେମାନଙ୍କୁ ଦେଖାନ୍ତି | ଆପ୍ ଇଭେଣ୍ଟ ଆୟୋଜକମାନଙ୍କୁ ଏକ ଇଭେଣ୍ଟରେ ସେମାନେ ବ୍ୟବହାର କରୁଥିବା ସବିଶେଷ ତାଲିକା ଉପରେ ନଜର ରଖିବାରେ ସାହାଯ୍ୟ କରିବ |",
              "as": "তাই তেওঁলোকক এটা এপ দেখুৱাই আছে যিটো তেওঁলোকে বৰ্তমান এটা অনুষ্ঠান ব্যৱস্থাপনা কোম্পানীৰ বাবে নিৰ্মাণ কৰি আছে। এপটোৱে অনুষ্ঠানৰ আয়োজকসকলক তেওঁলোকে এটা অনুষ্ঠানত ব্যৱহাৰ কৰা সকলো ইনভেণ্টৰীৰ হিচাপ ৰখাত সহায় কৰিব।",
              "gu": "તેણીની પોતાની એક એપ દેખાડી રહ્યા છે,જે વર્તમાન સમયમાં એક ઈવેન્ટ મેનેજમેન્ટ કંપની માટે તૈયાર કરવામાં આવી છે. એપ ઈવેન્ટના આયોજકોને એવી તમામ ઈવેન્ટ્રીનો ટ્રેક રાખવામાં મદદ કરશે કે જે કોઈ ઈવેન્ટમાં ઉપયોગ કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4i_1_5",
              "hi": "X4i_1_5",
              "ka": "X4i_1_5",
              "te": "X4i_1_5",
              "or": "X4i_1_5",
              "as": "X4i_1_5",
              "gu": "X4i_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is fascinated to see how a smartly developed app can help improve efficiency and productivity.",
              "hi": "अरविंद यह देख कर मंत्रमुग्ध है कि कैसे एक बुद्धिमानी से विकसित ऐप दक्षता और उत्पादकता में सुधार करने में सहायता कर सकता है।",
              "ka": "ಅಚ್ಚುಕಟ್ಟಾಗಿ ಅಭಿವೃದ್ಧಿಪಡಿಸಿದ ಅಪ್ಲಿಕೇಶನ್ ದಕ್ಷತೆ ಮತ್ತು ಉತ್ಪಾದಕತೆಯನ್ನು ಸುಧಾರಿಸಲು ಹೇಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ ಎಂಬುದನ್ನು ನೋಡಲು ಅರವಿಂದ್ ಆಕರ್ಷಿತರಾಗಿದ್ದಾರೆ.",
              "te": "స్మార్ట్‌గా డెవలప్ చేసిన యాప్ సామర్థ్యాన్ని మరియు ఉత్పాదకతను మెరుగుపరచడంలో ఎలా సహాయపడుతుందని అరవింద్ ఆసక్తిగా చూశాడు.",
              "or": "ଏକ ସ୍ମାର୍ଟ ଭାବରେ ବିକଶିତ ଆପ୍ କିପରି ଦକ୍ଷତା ଏବଂ ଉତ୍ପାଦକତାରେ ଉନ୍ନତି ଆଣିବାରେ ସାହାଯ୍ୟ କରିପାରିବ ତାହା ଦେଖି ଅରବିନ୍ଦ ମୁଗ୍ଧ ହୋଇଯାନ୍ତି |",
              "as": "এটা স্মাৰ্টলি বিকশিত এপে কেনেদৰে দক্ষতা আৰু উৎপাদনশীলতা উন্নত কৰাত সহায় কৰিব পাৰে সেয়া চাই অৰবিন্দ মোহিত হৈছে।",
              "gu": "અરવિંદ આ જોઈને રોમાંચિત છે કે કેવી રીતે બુદ્ધિપૂર્વક વિકસિત કરવામાં આવેલ એપ સક્ષમ અને ઉત્પાદકતામાં સુધારો કરવામાં મદદ કરી શકે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4i_1_6 bg",
              "hi": "X4i_1_6 bg",
              "ka": "X4i_1_6 ಬಿಜಿ",
              "te": "X4i_1_6 bg",
              "or": "X4i_1_6 bg",
              "as": "X4i_1_6 bg",
              "gu": "X4i_1_6 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Akshara",
              "hi": "अक्षरा",
              "ka": "ಅಕ್ಷರ",
              "te": "అక్షర",
              "or": "ଅକ୍ଷରା",
              "as": "অক্ষৰা",
              "gu": "અક્ષરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Akka, what is your favourite project you've worked on here?",
              "hi": "अक्का, आपका पसंदीदा प्रोजेक्ट क्या है जिस पर आपने यहां काम किया है?",
              "ka": "ಅಕ್ಕಾ, ನೀವು ಇಲ್ಲಿ ಕೆಲಸ ಮಾಡಿದ ನಿನ್ನ ನೆಚ್ಚಿನ ಯೋಜನೆ ಯಾವುದು?",
              "te": "అక్కా, నువ్వు ఇక్కడ చేసినవాటిలో నీకు ఇష్టమైన ప్రాజెక్ట్ ఏది?",
              "or": "ଆକ୍କା, ତୁମର ପ୍ରିୟ ପ୍ରୋଜେକ୍ଟ କେଉଁଟି ଯାହା ଉପରେ ତୁମେ ଏଠାରେ କାମ କରିଛ ?",
              "as": "আক্কা, আপুনি ইয়াত কাম কৰা আপোনাৰ প্ৰিয় প্ৰকল্পটো কি?",
              "gu": "અક્કા, તમારા પસંદગીના પ્રોજેક્ટ કયો છે કે જેની ઉપર તમે કામ કર્યું છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Oh! Last year we worked on a loan application system for a bank.",
              "hi": "ओह! पिछले साल हमने एक बैंक के लिए ऋण आवेदन प्रणाली पर काम किया।",
              "ka": "ಓಹ್! ಕಳೆದ ವರ್ಷ ನಾವು ಬ್ಯಾಂಕ್‌ಗೆ ಸಾಲ ಅರ್ಜಿ ವ್ಯವಸ್ಥೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಿದ್ದೇವೆ.",
              "te": "ఓ! గత సంవత్సరం మేము బ్యాంకు కోసం లోన్ అప్లికేషన్ సిస్టమ్‌పై పని చేసాము.",
              "or": "ଓହ ! ଗତ ବର୍ଷ ଆମେ ଏକ ବ୍ୟାଙ୍କ ପାଇଁ ଋଣ ଆବେଦନ ସିଷ୍ଟମ୍‌ ଉପରେ କାମ କରିଥିଲୁ |",
              "as": "অহ! যোৱা বছৰ আমি এটা বেংকৰ বাবে ঋণ আবেদন প্ৰণালীত কাম কৰিছিলো।",
              "gu": "અરે! ગયા વર્ષે અમે એક બેન્ક સમક્ષ ધિરાણ માટે અરજી ઉપર કામ કર્યું હતું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "That sounds a little boring, Akka.",
              "hi": " यह थोड़ा उबाऊ लगता है,अक्का ।",
              "ka": "ಸ್ವಲ್ಪ ಬೇಜಾರಾಗಿದೆ ಅಕ್ಕಾ.",
              "te": "అది కొంచెం బోరింగ్ గా ఉంది అక్కా.",
              "or": "ଏହା ଟିକେ ବିରକ୍ତ ଲାଗୁଛି, ଆକ୍କା |",
              "as": "এইটো অলপ আমনিদায়ক যেন লাগিছে, আক্কা।",
              "gu": "આ અવાજ થોડો કંટાળાજનક લાગે છે, અક્કા."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4i_1_7////",
              "hi": "X4i_1_7 /////",
              "ka": "X4i_1_7////",
              "te": "X4i_1_7////",
              "or": "X4i_1_7////",
              "as": "X4i_1_7////",
              "gu": "X4i_1_7////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Haha, it does. Initially, it used to take people three days to get a loan because of all the paperwork. After the app started getting used, people could take a loan in just 30mins.",
              "hi": "हाहा, यह करता है। प्रारंभ में, यह सभी कागजी कार्य के कारण ऋण प्राप्त करने में तीन दिन लग रहे थे। ऐप का उपयोग शुरू होने के बाद, लोग केवल 30 मिनट में ऋण ले सकते थे।",
              "ka": "ಹ್ಹಾ, ಅದು ಮಾಡುತ್ತದೆ. ಆರಂಭದಲ್ಲಿ, ಎಲ್ಲಾ ಕಾಗದಪತ್ರಗಳ ಕಾರಣ ಸಾಲ ಪಡೆಯಲು ಜನರು ಮೂರು ದಿನಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಿದ್ದರು. ಅಪ್ಲಿಕೇಶನ್ ಬಳಕೆಯನ್ನು ಪ್ರಾರಂಭಿಸಿದ ನಂತರ, ಜನರು ಕೇವಲ 30 ನಿಮಿಷಗಳಲ್ಲಿ ಸಾಲವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು.",
              "te": "హా, అది ప్రారంభంలో అలా ఉండేది, మొత్తం పేపర్ వర్క్ కారణంగా రుణం పొందడానికి ప్రజలకు మూడు రోజులు పట్టేది. యాప్ ఉపయోగించడం ప్రారంభించిన తర్వాత, ప్రజలు కేవలం 30 నిమిషాల్లో రుణం తీసుకోగలుగుతున్నారు.",
              "or": "ହାହା, ଏହା ହୁଏ | ପ୍ରାରମ୍ଭରେ, ସମସ୍ତ କାଗଜପତ୍ର କାର୍ଯ୍ୟ ହେତୁ ଋଣ ପାଇବାକୁ ଲୋକଙ୍କୁ ତିନି ଦିନ ଲାଗୁଥିଲା | ଆପ୍ ବ୍ୟବହାର ଆରମ୍ଭ ହେବା ପରେ, ଲୋକମାନେ ମାତ୍ର 30 ମିନିଟରେ ଏକ ଋଣ ନେଇପାରୁଛନ୍ତି |",
              "as": "হাঃহাঃ, এইটো হয়। আৰম্ভণিতে, সকলো কাগজপত্ৰৰ বাবে ঋণ পাবলৈ মানুহক তিনি দিন লাগিছিল। এপটোৰ ব্যৱহাৰ আৰম্ভ হোৱাৰ পিছত, মানুহে মাত্ৰ 30 মিনিটত ঋণ ল'ব পাৰে।",
              "gu": "હાહા, તે કહે છે. શરૂઆતમાં તમામ પેપરવર્કની કાર્યવાહીને લીધે લોકોને ધિરાણ લેવામાં ત્રણ દિવસ લાગતો હતો. એપના ઉપયોગ બાદ લોકો ફક્ત 30 મિનિટમાં લોન લઈ શકતા હતા."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Wow!",
              "hi": "वाह!",
              "ka": "ಅದ್ಭುತ!",
              "te": "వావ్!",
              "or": "ବାହ !",
              "as": "বাঃ!",
              "gu": "અરે!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "What do you study in college to do this kind of work?",
              "hi": "इस तरह के काम करने के लिए आप कॉलेज में क्या अध्ययन करते हैं?",
              "ka": "ಈ ರೀತಿಯ ಕೆಲಸವನ್ನು ಮಾಡಲು ನೀವು ಕಾಲೇಜಿನಲ್ಲಿ ಏನು ಓದುತ್ತೀರಿ?",
              "te": "ఇలాంటి పని చేయడానికి మీరు కాలేజీలో ఏమి చదువుతారు?",
              "or": "ଏହି ପ୍ରକାର କାମ କରିବା ପାଇଁ ତୁମେ କଲେଜରେ କ’ଣ ଅଧ୍ୟୟନ କରିଛ ?",
              "as": "এই ধৰণৰ কাম কৰিবলৈ আপুনি কলেজত কি পঢ়িছিল?",
              "gu": "આ રીતે કામ કરવા માટે તમે કોલેજમાં શું ભણો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "I did a Bachelor in computer applications (BCA). \nBut studying computers and software is quite a trend these days, and there are other courses also which are available like  B.Tech in Computer science engineering and Bachelor of Science in IT (Bsc. IT)",
              "hi": "मैंने कंप्यूटर अनुप्रयोगों (बीसीए) में स्नातक किया।\nलेकिन कंप्यूटर और सॉफ्टवेयर का अध्ययन इन दिनों काफी चलन में है, और अन्य पाठ्यक्रम भी हैं जो कंप्यूटर साइंस इंजीनियरिंग और बैचलर ऑफ साइंस में बीटेक जैसे उपलब्ध हैं (बीएससी। आईटी)",
              "ka": "ನಾನು ಕಂಪ್ಯೂಟರ್ ಅಪ್ಲಿಕೇಶನ್‌ಗಳಲ್ಲಿ (ಬಿಸಿಎ) ಬ್ಯಾಚುಲರ್ ಮಾಡಿದ್ದೇನೆ.\n ಆದರೆ ಕಂಪ್ಯೂಟರ್‌ಗಳು ಮತ್ತು ಸಾಫ್ಟ್‌ವೇರ್‌ಗಳನ್ನು ಅಧ್ಯಯನ ಮಾಡುವುದು ಇತ್ತೀಚಿನ ದಿನಗಳಲ್ಲಿ ಸಾಕಷ್ಟು ಪ್ರವೃತ್ತಿಯಾಗಿದೆ ಮತ್ತು ಕಂಪ್ಯೂಟರ್ ಸೈನ್ಸ್ ಎಂಜಿನಿಯರಿಂಗ್‌ನಲ್ಲಿ ಬಿ.ಟೆಕ್ ಮತ್ತು ಬ್ಯಾಚುಲರ್‌ನಂತಹ ಇತರ ಕೋರ್ಸ್‌ಗಳು ಲಭ್ಯವಿದೆ.",
              "te": "నేను కంప్యూటర్ అప్లికేషన్స్ (BCA)లో బ్యాచిలర్ చేసాను.\n \n కానీ ఈ రోజుల్లో కంప్యూటర్‌లు మరియు సాఫ్ట్‌వేర్‌లను చదవడం చాలా ట్రెండ్‌ అయింది మరియు కంప్యూటర్ సైన్స్ ఇంజనీరింగ్‌లో B.Tech మరియు ITలో బ్యాచిలర్ ఆఫ్ సైన్స్ (Bsc. IT) వంటి ఇతర కోర్సులు కూడా అందుబాటులో ఉన్నాయి.",
              "or": "ମୁଁ କମ୍ପ୍ୟୁଟର ଆପ୍ଲିକେସନ୍ (BCA) ରେ ଏକ ସ୍ନାତକ କରିଥିଲି |\n କିନ୍ତୁ ଆଜିକାଲି କମ୍ପ୍ୟୁଟର ଏବଂ ସଫ୍ଟୱେୟର୍ ଅଧ୍ୟୟନ କରିବା ଏକ ଟ୍ରେଣ୍ଡ ଅଟେ, ଏବଂ ଅନ୍ୟାନ୍ୟ ପାଠ୍ୟକ୍ରମ ମଧ୍ୟ ଅଛି ଯାହା ଉପଲବ୍ଧ ଅଛି ଯଥା କମ୍ପ୍ୟୁଟର ସାଇନ୍ସ ଇଞ୍ଜିନିୟରିଂରେ B.Tech ଏବଂ IT ରେ ବିଜ୍ଞାନ ର ସ୍ନାତକ (Bsc. IT) |",
              "as": "মই কম্পিউটাৰ এপ্লিকেশ্বনত (BCA) স্নাতক কৰিছিলো। \n কিন্তু আজিকালি কম্পিউটাৰ আৰু ছফ্টৱেৰ অধ্যয়ন কৰাটো সম্পূৰ্ণভাবে এটা ট্ৰেণ্ড হৈছে, আৰু আন কিছুমান পাঠ্যক্ৰমো আছে যিবোৰ কম্পিউটাৰ বিজ্ঞান অভিযান্ত্ৰিকত B.Tech আৰু তথ্য প্ৰযুক্তিত বিজ্ঞানৰ স্নাতক (Bsc. IT)ৰ দৰে উপলব্ধ।",
              "gu": "મે બેચલર ઈન કોમ્પ્યુટર એપ્લિકેશન્સ (BCA) કર્યું છે. જોકે અત્યારમાં કોમ્પ્યુટર અને સોફ્ટવેરને લગતો અભ્યાસ ઘણો ચલણમાં છે, અને અન્ય અભ્યાસક્રમ પણ છે કે જે કોમ્પ્યુટર સાયન્સ એન્જીનિયરિંગમાં બી.ટેક અને Itમાં બેચલર ઓફ સાયન્સ (Bsc.IT) ઉપલબ્ધ છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X4i_1_8 bg",
              "hi": "X4i_1_8 बीजी",
              "ka": "X4i_1_8 ಬಿಜಿ",
              "te": "X4i_1_8 bg",
              "or": "X4i_1_8 bg",
              "as": "X4i_1_8 bg",
              "gu": "X4i_1_8 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Akshara",
              "hi": "अक्षरा",
              "ka": "ಅಕ್ಷರ",
              "te": "అక్షర",
              "or": "ଅକ୍ଷରା",
              "as": "অক্ষৰা",
              "gu": "અક્ષરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Akshara",
              "hi": "अक्षरा",
              "ka": "ಅಕ್ಷರ",
              "te": "అక్షర",
              "or": "ଅକ୍ଷରା",
              "as": "অক্ষৰা",
              "gu": "અક્ષરા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I'm keen on doing my engineering in computer science, but I'm not sure what I want to do after that.",
              "hi": "मैं कंप्यूटर विज्ञान में इंजीनियरिंग करना चाहता हूं, लेकिन मुझे पता नहीं है कि मैं उसके बाद क्या करना है।",
              "ka": "ನಾನು ಕಂಪ್ಯೂಟರ್ ಸೈನ್ಸ್‌ನಲ್ಲಿ ನನ್ನ ಎಂಜಿನಿಯರಿಂಗ್ ಮಾಡಲು ಉತ್ಸುಕನಾಗಿದ್ದೇನೆ, ಆದರೆ ಅದರ ನಂತರ ನಾನು ಏನು ಮಾಡಬೇಕೆಂದು ನನಗೆ ಖಚಿತವಿಲ್ಲ.",
              "te": "నేను కంప్యూటర్ సైన్స్‌లో ఇంజనీరింగ్ చేయాలనే ఆసక్తితో ఉన్నాను, కానీ దాని తర్వాత నేను ఏమి చేయాలనుకుంటున్నానో నాకు ఖచ్చితంగా తెలియదు.",
              "or": "ମୁଁ କମ୍ପ୍ୟୁଟର ସାଇନ୍ସରେ ମୋର ଇଞ୍ଜିନିୟରିଂ କରିବାକୁ ଆଗ୍ରହୀ, କିନ୍ତୁ ଏହା ପରେ ମୁଁ କ’ଣ କରିବାକୁ ଚାହୁଁଛି ତାହା ନିଶ୍ଚିତ ନୁହେଁ |",
              "as": "মই কম্পিউটাৰ বিজ্ঞানত অভিযান্ত্ৰিক পঢ়িবলৈ আগ্ৰহী, কিন্তু তাৰ পিছত মই কি কৰিম সেই বিষয়ে মই নিশ্চিত নহয়।",
              "gu": "હું કોમ્પ્યુટર સાયન્સમાં એન્જીનિયરિંગ કરવા ઈચ્છું છું, પણ મને ખબર નથી કે ત્યારબાદ હું શું કરવા માગુ છું"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "That's a good start, Arvind. Don't worry about what you would want to do later. There's enough time for you to plan that out. But it would help if you started preparing as hard as you could. It isn't easy to get into  B.Tech in computer science engineering!",
              "hi": "यह एक अच्छी शुरुआत है, अरविंद। इस बारे में चिंता न करो कि तुम बाद में क्या करना चाहते हैं। यह योजना बनाने के लिए तुम्हारे पास पर्याप्त समय है। लेकिन अगर तुम उतनी कठिन तैयारी शुरू कर सकते हो तो यह सहायक होगा। कंप्यूटर विज्ञान इंजीनियरिंग में बीटेक करना सरल नहीं है!",
              "ka": "ಇದು ಒಳ್ಳೆಯ ಆರಂಭ, ಅರವಿಂದ್. ನೀವು ನಂತರ ಏನು ಮಾಡಬೇಕೆಂದು ಚಿಂತಿಸಬೇಡಿ. ಅದನ್ನು ಯೋಜಿಸಲು ನಿನಗೆ ಸಾಕಷ್ಟು ಸಮಯವಿದೆ. ಆದರೆ ನೀವು ಸಾಧ್ಯವಾದಷ್ಟು ಕಷ್ಟಪಟ್ಟು ತಯಾರಿ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿದರೆ ಅದು ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಕಂಪ್ಯೂಟರ್ ಸೈನ್ಸ್ ಎಂಜಿನಿಯರಿಂಗ್‌ನಲ್ಲಿ ಬಿ.ಟೆಕ್‌ಗೆ ಸೇರುವುದು ಸುಲಭವಲ್ಲ!",
              "te": "ఇది మంచి ప్రారంభం, అరవింద్. నువ్వు తర్వాత ఏమి చేయాలనుకుంటున్నావు అనే దానిపై చింతించకు. దాన్ని ప్లాన్ చేసుకోవడానికి నీకు తగినంత సమయం ఉంది. కానీ నువ్వు వీలైనంత కష్టపడి ప్రిపేర్ అవ్వడం ప్రారంభిస్తే అది సహాయపడుతుంది. కంప్యూటర్ సైన్స్ ఇంజినీరింగ్‌లో బీటెక్‌లో చేరడం అంత సులభం కాదు!",
              "or": "ଏହା ଏକ ଭଲ ପ୍ରାରମ୍ଭ, ଅରବିନ୍ଦ | ତୁମେ ପରେ କଣ କରିବାକୁ ଚାହୁଁଛ ସେ ବିଷୟରେ ଚିନ୍ତା କର ନାହିଁ | ଏହାକୁ ଯୋଜନା କରିବା ପାଇଁ ତୁମ ପାଇଁ ଯଥେଷ୍ଟ ସମୟ ଅଛି | କମ୍ପ୍ୟୁଟର ସାଇନ୍ସ ଇଞ୍ଜିନିୟରିଂରେ B.Tech ରେ ଯିବା ସହଜ ନୁହେଁ !",
              "as": "এইটো এটা ভাল আৰম্ভণি, অৰবিন্দ। তুমি পিছত কি কৰিব বিচাৰিবা সেই বিষয়ে চিন্তা নকৰিবা। সেইটো পৰিকল্পনা কৰিবলৈ তোমাৰ হাতত বহুত সময় আছে। কিন্তু যদি তুমি যিমান পাৰা সিমান কঠিন প্ৰস্তুতি আৰম্ভ কৰা তেন্তে ই তোমাক সহায় কৰিব। কম্পিউটাৰ বিজ্ঞান অভিযান্ত্ৰিকত B.Tech ত চিট পোৱাটো ইমান সহজ নহয়!",
              "gu": "સારી શરૂઆત છે અરવિંદ! આ અંગે ચિંતા ન કર કે તું ત્યારપછી શું કરવા માગે છે.તારી પાસે આ અંગે યોજના તૈયાર કરવા પૂરતો સમય છે. જોકે તે મદદરૂપ બનશે જો તમે જેટલી મહેનતથી તૈયારી કરતાં હતા, શરૂઆત કરો. કોમ્પ્યુટર સાયન્સ એન્જીનિયરિંગમાં B.Tech કરવું એટલું સરળ નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Yes Akka I will! I know I will get good marks, and I am just worried about the fees. Engineering is an expensive course.",
              "hi": "हाँ अक्का मैं करूँगा! मुझे पता है कि मुझे अच्छे अंक मिलेंगे, और मैं सिर्फ फीस के बारे में चिंतित हूं। इंजीनियरिंग एक महंगा कोर्स है।",
              "ka": "ಹೌದು ಅಕ್ಕಾ ನಾನು ಮಾಡುತ್ತೇನೆ! ನಾನು ಉತ್ತಮ ಅಂಕಗಳನ್ನು ಪಡೆಯುತ್ತೇನೆ ಎಂದು ನನಗೆ ತಿಳಿದಿದೆ ಮತ್ತು ನಾನು ಶುಲ್ಕದ ಬಗ್ಗೆ ಚಿಂತಿಸುತ್ತಿದ್ದೇನೆ. ಎಂಜಿನಿಯರಿಂಗ್ ದುಬಾರಿ ಕೋರ್ಸ್ ಆಗಿದೆ.",
              "te": "అవును అక్కా నేను చేస్తాను! నాకు మంచి మార్కులు వస్తాయని నాకు తెలుసు, మరియు నేను ఫీజు గురించి ఆందోళన చెందుతున్నాను. ఇంజినీరింగ్ ఖరీదైన కోర్సు.",
              "or": "ହଁ ଆକ୍କା ମୁଁ କରିବି ! ମୁଁ ଜାଣେ ମୁଁ ଭଲ ମାର୍କ ପାଇବି, ଏବଂ ମୁଁ କେବଳ ଫିସ୍ ପାଇଁ ଚିନ୍ତିତ | ଇଞ୍ଜିନିୟରିଂ ଏକ ମହଙ୍ଗା ପାଠ୍ୟକ୍ରମ |",
              "as": "হয় আক্কা মই কৰিম! মই জানো যে মই ভাল নম্বৰ পাম, আৰু মই কেৱল মাচুলৰ বিষয়ে চিন্তিত। অভিযান্ত্ৰিক এটা খৰচী পাঠ্যক্ৰম।",
              "gu": "હા અક્કા હું ચોક્કસ કરીશ! મને ખબર છે કે મને સારા ગુણ મળશે, અને મને ફક્ત ફીની જ ચિંતા છે. એન્જીનિયરીંગ એક મોંઘો કોર્સ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "If you get good scores in your entrance exam, you can get a government college seat where the fees are comparatively less.",
              "hi": "यदि तुमको अपनी प्रवेश परीक्षा में अच्छे स्कोर मिलते हैं, तो तुम एक सरकारी कॉलेज में सीट प्राप्त कर सकते हो जहां शुल्क तुलनात्मक रूप से कम है।",
              "ka": "ನಿನ್ನ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಯಲ್ಲಿ ನೀವು ಉತ್ತಮ ಅಂಕಗಳನ್ನು ಪಡೆದರೆ, ತುಲನಾತ್ಮಕವಾಗಿ ಕಡಿಮೆ ಶುಲ್ಕವಿರುವ ಸರ್ಕಾರಿ ಕಾಲೇಜು ಸೀಟನ್ನು ನೀವು ಪಡೆಯಬಹುದು.",
              "te": "నువ్వు నీ ప్రవేశ పరీక్షలో మంచి స్కోర్లు సాధిస్తే, నువ్వు ఫీజు తక్కువగా ఉన్న ప్రభుత్వ కళాశాల సీటును పొందవచ్చు.",
              "or": "ଯଦି ତୁମେ ତୁମର ପ୍ରବେଶିକା ପରୀକ୍ଷାରେ ଭଲ ସ୍କୋର ପାଇବ, ତୁମେ ଏକ ସରକାରୀ କଲେଜ ସିଟ୍ ପାଇପାରିବ ଯେଉଁଠାରେ ଫିସ୍‌ ତୁଳନାତ୍ମକ ଭାବରେ କମ୍ ଅଟେ |",
              "as": "যদি তুমি তোমাৰ প্ৰৱেশ পৰীক্ষাত ভাল নম্বৰ পোৱা, তুমি এখন চৰকাৰী কলেজত আসন পাব পাৰা য'ত মাচুল তুলনামূলকভাৱে কম হয়।",
              "gu": "જો તમે તમારી પ્રવેશ પરીક્ષામાં સારા ગુણ પ્રાપ્ત કરો છો તો તમને એક સરકારી કોલેજમાં બેઠક પ્રાપ્ત થઈ શકે છે જ્યાં બિનજરૂરી ફી તુલનાત્મક રીતે ઓછી હોય છે."
            }
          }
        ]
      },
      "scene6": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "x4ii_1_1",
              "hi": "x4II_1_1",
              "ka": "x4ii_1_1",
              "te": "x4ii_1_1",
              "or": "x4ii_1_1",
              "as": "x4ii_1_1",
              "gu": "x4ii_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As promised to Roshini, Arvind helps her research for her course and the job prospects after the course.",
              "hi": "जैसा कि रोशिनी से वादा किया गया था, अरविंद उसके पाठ्यक्रम के लिए और पाठ्यक्रम के बाद नौकरी की संभावनाओं के लिए उसके शोध में सहायता करता है।",
              "ka": "ರೋಷಿಣಿಗೆ ಭರವಸೆ ನೀಡಿದಂತೆ, ಅರವಿಂದ್ ಅವರ ಕೋರ್ಸ್‌ಗೆ ಸಂಶೋಧನೆ ಮತ್ತು ಕೋರ್ಸ್ ನಂತರದ ಉದ್ಯೋಗದ ನಿರೀಕ್ಷೆಗಳಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತಾರೆ.",
              "te": "రోష్నికి వాగ్దానం చేసినట్లుగా, అరవింద్ ఆమె కోర్సు కోసం మరియు కోర్సు తర్వాత ఉద్యోగ అవకాశాల కోసం రీసర్చ్ లో ఆమెకు సహాయం చేస్తాడు.",
              "or": "ରୋଶିନିଙ୍କୁ ପ୍ରତିଶ୍ରୁତି ଅନୁଯାୟୀ, ଅରବିନ୍ଦ ତାଙ୍କ ପାଠ୍ୟକ୍ରମ ଏବଂ ପାଠ୍ୟକ୍ରମ ପରେ ଚାକିରି ସମ୍ଭାବନା ପାଇଁ ଅନୁସନ୍ଧାନ କରିବାରେ ତାଙ୍କୁ ସାହାଯ୍ୟ କରନ୍ତି |",
              "as": "ৰোশনিক দিয়া প্ৰতিশ্ৰুতি অনুসৰি, অৰবিন্দে তাইৰ পাঠ্যক্ৰম আৰু পাঠ্যক্ৰমৰ পিছত চাকৰিৰ সম্ভাৱনাৰ বাবে গৱেষণা কৰাত সহায় কৰে।",
              "gu": "જે પ્રમાણે રોશનીને વચન આપવામાં આવેલું, અરવિંદ તેના અભ્યાસક્રમ માટે તથા અભ્યાસક્રમ બાદ નોકરીની સંભાવના માટે તેને શોધવામાં મદદ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "x4ii_1_2",
              "hi": "x4II_1_2",
              "ka": "x4ii_1_2",
              "te": "x4ii_1_2",
              "or": "x4ii_1_2",
              "as": "x4ii_1_2",
              "gu": "x4ii_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind knows that Roshni likes to read articles in the newspaper which talk about various social issues, like a violation of citizen’s rights, unemployment, homelessness and discrimination.",
              "hi": "अरविंद जानता है कि रोशनी अखबार में ऐसे लेख पढ़ना पसंद करती है जो विभिन्न सामाजिक मुद्दों पर बात करते हैं, जैसे नागरिकों के अधिकारों का उल्लंघन, बेरोजगारी, बेघर होना और भेदभाव।",
              "ka": "ನಾಗರಿಕರ ಹಕ್ಕುಗಳ ಉಲ್ಲಂಘನೆ, ನಿರುದ್ಯೋಗ, ನಿರಾಶ್ರಿತತೆ ಮತ್ತು ತಾರತಮ್ಯದಂತಹ ವಿವಿಧ ಸಾಮಾಜಿಕ ಸಮಸ್ಯೆಗಳ ಕುರಿತು ಮಾತನಾಡುವ ಲೇಖನಗಳನ್ನು ರೋಶ್ನಿ ಪತ್ರಿಕೆಯಲ್ಲಿ ಓದಲು ಇಷ್ಟಪಡುತ್ತಾರೆ ಎಂದು ಅರವಿಂದ್ ಅವರಿಗೆ ತಿಳಿದಿದೆ.",
              "te": "పౌరుల హక్కుల ఉల్లంఘన, నిరుద్యోగం, నిరాశ్రయత మరియు వివక్ష వంటి అనేక సామాజిక సమస్యల గురించి మాట్లాడే కథనాలను రోష్ని వార్తాపత్రికలో చదవడానికి ఇష్టపడుతుందని అరవింద్‌కు తెలుసు.",
              "or": "ଅରବିନ୍ଦ ଜାଣନ୍ତି ଯେ ରୋଶନି ଖବରକାଗଜରେ ପ୍ରବନ୍ଧ ଯାହାକି ନାଗରିକଙ୍କ ଅଧିକାରର ଉଲ୍ଲଂଘନ, ବେରୋଜଗାରୀ, ଗୃହହୀନତା ଏବଂ ଭେଦଭାବ ଭଳି ବିଭିନ୍ନ ସାମାଜିକ ପ୍ରସଙ୍ଗଗୁଡିକ ଉପରେ ଆଲୋଚନା କରିଥାଏ, ପଢିବାକୁ ପସନ୍ଦ କରନ୍ତି |",
              "as": "অৰবিন্দে জানে যে ৰোশনিয়ে বাতৰি কাকতত এনে প্ৰবন্ধ পঢ়ি ভাল পায় য'ত বিভিন্ন সামাজিক বিষয়ৰ বিষয়ে কোৱা হয়, যেনে নাগৰিকৰ অধিকাৰ উলংঘন, নিবনুৱা, গৃহহীনতা আৰু বৰ্ণ বৈষম্য।",
              "gu": "અરવિંદ જાણે છે કે રોશની અખબારમાં એવા લેખ વાંચવાનું પસંદ કરે છે કે જે વિવિધ સામાજીક મુદ્દા અંગે વાત કરે છે, જેમ કે નાગરિકોના અધિકારોનું ઉલ્લંઘન, બેરોજગારી,ઘરવિહાણા થવું અને ભેદભાવનો સામનો કરવો."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "x4ii_1_3",
              "hi": "X4II_1_3",
              "ka": "x4ii_1_3",
              "te": "x4ii_1_3",
              "or": "x4ii_1_3",
              "as": "x4ii_1_3",
              "gu": "x4ii_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After searching on the internet, he learns that courses in social science involve the study of social issues.",
              "hi": "इंटरनेट पर खोज करने के बाद, वह सीखता है कि सामाजिक विज्ञान के पाठ्यक्रमों में सामाजिक मुद्दों का अध्ययन शामिल है।",
              "ka": "ಅಂತರ್ಜಾಲದಲ್ಲಿ ಹುಡುಕಿದ ನಂತರ, ಸಮಾಜ ವಿಜ್ಞಾನದ ಕೋರ್ಸ್‌ಗಳು ಸಾಮಾಜಿಕ ಸಮಸ್ಯೆಗಳ ಅಧ್ಯಯನವನ್ನು ಒಳಗೊಂಡಿರುತ್ತವೆ ಎಂದು ಅವರು ಕಲಿಯುತ್ತಾರೆ.",
              "te": "ఇంటర్నెట్‌లో వెతికిన తర్వాత, సాంఘిక శాస్త్రంలోని కోర్సులు సామాజిక సమస్యల గురించి ఉంటుందని అతను తెలుసుకున్నాడు.",
              "or": "ଇଣ୍ଟରନେଟ୍‌ରେ ସନ୍ଧାନ କରିବା ପରେ, ସେ ଜାଣିବାକୁ ପାଇଲେ ଯେ ସାମାଜିକ ବିଜ୍ଞାନର ପାଠ୍ୟକ୍ରମଗୁଡ଼ିକ ସାମାଜିକ ସମସ୍ୟାଗୁଡ଼ିକର ଅଧ୍ୟୟନ ସହିତ ଜଡିତ |",
              "as": "ইণ্টাৰনেটত অনুসন্ধান কৰাৰ পিছত, তেওঁ জানিব পাৰে যে সমাজ বিজ্ঞানৰ পাঠ্যক্ৰমবোৰত সামাজিক সমস্যাৰ অধ্যয়ন অন্তৰ্ভুক্ত থাকে।",
              "gu": "ઈન્ટરનેટ ઉપર તપાસ કર્યાં બાદ તેને ખબર પડી કે સામાજીક વિજ્ઞાનના અભ્યાસક્રમોમાં સામાજીક મુદ્દાના અભ્યાસનો પણ સમાવેશ થાય છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "x4ii_1_4",
              "hi": "x4ii_1_4",
              "ka": "x4ii_1_4",
              "te": "x4ii_1_4",
              "or": "x4ii_1_4",
              "as": "x4ii_1_4",
              "gu": "x4ii_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "These are courses like political science, sociology and psychology, among others. These courses prepare you to work on the various challenges faced by societies everywhere.",
              "hi": "ये राजनीति विज्ञान, समाजशास्त्र और मनोविज्ञान जैसे पाठ्यक्रम हैं। ये पाठ्यक्रम तुमको हर जगह समाज के सामने आने वाली विभिन्न चुनौतियों पर काम करने के लिए तैयार करते हैं।",
              "ka": "ಇವು ರಾಜಕೀಯ ವಿಜ್ಞಾನ, ಸಮಾಜಶಾಸ್ತ್ರ ಮತ್ತು ಮನೋವಿಜ್ಞಾನದಂತಹ ಕೋರ್ಸ್‌ಗಳಾಗಿವೆ. ಎಲ್ಲೆಡೆ ಸಮಾಜಗಳು ಎದುರಿಸುತ್ತಿರುವ ವಿವಿಧ ಸವಾಲುಗಳ ಮೇಲೆ ಕೆಲಸ ಮಾಡಲು ಈ ಕೋರ್ಸ್‌ಗಳು ನಿಮ್ಮನ್ನು ಸಿದ್ಧಪಡಿಸುತ್ತವೆ.",
              "te": "పొలిటికల్ సైన్స్, సోషియాలజీ మరియు సైకాలజీ వంటి ఇతర కోర్సులు ఉన్నాయి. ఈ కోర్సులు సమాజాలు ప్రతిచోటా ఎదుర్కొంటున్న వివిధ సవాళ్లపై పని చేయడానికి మీకు సహాయపడతాయి.",
              "or": "ଅନ୍ୟମାନଙ୍କ ମଧ୍ୟରେ ରାଜନୀତି ବିଜ୍ଞାନ, ସାମାଜିକବିଜ୍ଞାନ ଏବଂ ମନୋବିଜ୍ଞାନ ପରି ଏହି ପାଠ୍ୟକ୍ରମଗୁଡ଼ିକ | ଏହି ପାଠ୍ୟକ୍ରମଗୁଡ଼ିକ ଆପଣଙ୍କୁ ସମାଜ ସବୁ ଜାଗାରେ ସମ୍ମୁଖୀନ ହେଉଥିବା ବିଭିନ୍ନ ଚ୍ୟାଲେଞ୍ଜ ଉପରେ କାର୍ଯ୍ୟ କରିବାକୁ ଆପଣଙ୍କୁ ପ୍ରସ୍ତୁତ କରେ |",
              "as": "আনবোৰ পাঠ্যক্ৰমৰ ভিতৰত এইবোৰ হৈছে ৰাজনীতি বিজ্ঞান, সমাজ বিজ্ঞান আৰু মনোবিজ্ঞানৰ দৰে পাঠ্যক্ৰম। এই পাঠ্যক্ৰমবোৰে আপোনাক সকলো ঠাইতে সমাজে সন্মুখীন হোৱা বিভিন্ন প্ৰত্যাহ্বানৰ ওপৰত কাম কৰিবলৈ প্ৰস্তুত কৰে।",
              "gu": "આ એક રાજકીય વિજ્ઞાન, સમાજશાસ્ત્ર તથા મનોવિજ્ઞાન જેવા અભ્યાસક્રમ છે. આ અભ્યાસક્રમ તમને દરેક જગ્યાએ સમાજ સમક્ષ આવતા વિવિધ પડકારોનો સામનો કરવા પર કામ કરવા તૈયાર કરવામાં આવે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "x4ii_1_5",
              "hi": "X4II_1_5",
              "ka": "x4ii_1_5",
              "te": "x4ii_1_5",
              "or": "x4ii_1_5",
              "as": "x4ii_1_5",
              "gu": "x4ii_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He finds out that the University of Hyderabad has an integrated 5-year course through his research. At the end of the 5th year, Roshni can get a Master's Degree if she gets selected.",
              "hi": "अपने शोध के द्वारा वह पता लगाता है कि हैदराबाद विश्वविद्यालय में  एकीकृत 5 साल का कोर्स है। 5वें वर्ष के अंत में, यदि उसे चुना जाता है तो रोशनी को मास्टर की डिग्री मिल सकती है।",
              "ka": "ಹೈದರಾಬಾದ್ ವಿಶ್ವವಿದ್ಯಾನಿಲಯವು ತನ್ನ ಸಂಶೋಧನೆಯ ಮೂಲಕ 5 ವರ್ಷಗಳ ಸಮಗ್ರ ಕೋರ್ಸ್ ಅನ್ನು ಹೊಂದಿದೆ ಎಂದು ಅವರು ಕಂಡುಕೊಂಡರು. 5ನೇ ವರ್ಷದ ಕೊನೆಯಲ್ಲಿ ರೋಶನಿ ಆಯ್ಕೆಯಾದರೆ ಸ್ನಾತಕೋತ್ತರ ಪದವಿ ಪಡೆಯಬಹುದು.",
              "te": "తన రీసర్చ్ ద్వారా యూనివర్సిటీ ఆఫ్ హైదరాబాద్‌లో ఐదేళ్ల ఇంటిగ్రేటెడ్ కోర్సు ఉందని తెలుసుకున్నాడు. 5వ సంవత్సరం చివరిలో, రోష్ని ఎంపికైతే మాస్టర్స్ డిగ్రీని పొందవచ్చు.",
              "or": "ତାଙ୍କ ଅନୁସନ୍ଧାନ ମାଧ୍ୟମରେ ସେ ଜାଣିବାକୁ ପାଆନ୍ତି ଯେ ହାଇଦ୍ରାବାଦ ବିଶ୍ୱବିଦ୍ୟାଳୟର ଏକ ଇଣ୍ଟିଗ୍ରେଟେଡ୍ 5 ବର୍ଷ ପାଠ୍ୟକ୍ରମ ଅଛି | 5th ବର୍ଷ ଶେଷରେ, ଯଦି ମନୋନୀତ ହୁଅନ୍ତି ତେବେ ରୋଶନି ମାଷ୍ଟର ଡିଗ୍ରୀ ହାସଲ କରିପାରିବେ |",
              "as": "তেওঁ জানিব পাৰিলে যে হায়দৰাবাদ বিশ্ববিদ্যালয়ৰ গৱেষণাৰ জৰিয়তে ৫বছৰীয়া এক একত্ৰিত পাঠ্যক্ৰম আছে। ৰোশনি নিৰ্বাচিত হ'লে পঞ্চম বছৰৰ শেষত, স্নাতকোত্তৰ ডিগ্ৰী লাভ কৰিব পাৰিব।",
              "gu": "તેને માલુમ થાય છે કે હૈદરાબાદ યુનિવર્સિટીમાં તેના સંશોધનના માધ્યમથી સંકલિત 5 વર્ષનો અભ્યાસક્રમ છે. 5માં વર્ષના અંતે રોશની જો પસંદગી પામે તો માસ્ટર ડિગ્રી મેળવી શકે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "x4ii_1_6 bg",
              "hi": "x4II_1_6 बीजी",
              "ka": "x4ii_1_6 ಬಿಜಿ",
              "te": "x4ii_1_6 bg",
              "or": "x4ii_1_6 bg",
              "as": "x4ii_1_6 bg",
              "gu": "x4ii_1_6 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Roshini",
              "hi": "रोशिनी",
              "ka": "ರೋಷಿಣಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I have researched, based on your interest and the subjects. I feel you should pursue either political science or sociology.",
              "hi": "मैंने तुम्हारी रुचि और विषयों के आधार पर शोध किया है। मुझे लगता है कि तुमको या तो राजनीतिक विज्ञान या समाजशास्त्र का अध्ययन करना चाहिए।",
              "ka": "ನಿನ್ನ ಆಸಕ್ತಿ ಮತ್ತು ವಿಷಯಗಳ ಆಧಾರದ ಮೇಲೆ ನಾನು ಸಂಶೋಧನೆ ಮಾಡಿದ್ದೇನೆ. ನೀವು ರಾಜಕೀಯ ವಿಜ್ಞಾನ ಅಥವಾ ಸಮಾಜಶಾಸ್ತ್ರವನ್ನು ಅನುಸರಿಸಬೇಕು ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "నేను నీకు ఆసక్తి ఉండే విషయాల ఆధారంగా రీసర్చ్ చేశాను. నువ్వు పొలిటికల్ సైన్స్ లేదా సోషియాలజీని అభ్యసించాలని నేను భావిస్తున్నాను.",
              "or": "ତୁମର ଆଗ୍ରହ ଏବଂ ବିଷୟ ଉପରେ ଆଧାର କରି ମୁଁ ଅନୁସନ୍ଧାନ କରିଛି | ମୁଁ ଅନୁଭବ କରୁଛି ତୁମେ ରାଜନୀତି ବିଜ୍ଞାନ କିମ୍ବା ସାମାଜିକ ବିଜ୍ଞାନ ଅନୁସରଣ କରିବା ଉଚିତ୍ |",
              "as": "মই তোমাৰ আগ্ৰহ আৰু বিষয়বোৰৰ ওপৰত ভিত্তি কৰি গৱেষণা কৰিছো। মই ভাবো যে তুমি ৰাজনীতি বিজ্ঞান বা সমাজবিজ্ঞান পঢ়া উচিত।",
              "gu": "મે તેમના રસ અને વિષયોના આધારે સંશોધન કર્યું છે. મને લાગે છે કે તમારે પોલિટીકલ સાયન્સ અથવા સમાજીકશાસ્ત્રનો અભ્યાસ કરવો જોઈએ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "What are the job opportunities?",
              "hi": "नौकरी के अवसर क्या हैं?",
              "ka": "ಉದ್ಯೋಗಾವಕಾಶಗಳೇನು?",
              "te": "ఉద్యోగావకాశాలు ఏమిటి?",
              "or": "ଚାକିରିର ସୁଯୋଗଗୁଡ଼ିକ କ’ଣ ଅଟେ ?",
              "as": "ইয়াত চাকৰিৰ সুযোগবোৰ কেনেধৰণৰ?",
              "gu": "નોકરીને લગતી કઈ તકો રહેલી છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Here, I’ve downloaded the prospectus of the college, you can go through the details of the course in this.",
              "hi": "यहां, मैंने कॉलेज के प्रॉस्पेक्टस को डाउनलोड किया है, तुम इस में पाठ्यक्रम के विवरण देख सकते हो।",
              "ka": "ಇಲ್ಲಿ, ನಾನು ಕಾಲೇಜಿನ ಪ್ರಾಸ್ಪೆಕ್ಟಸ್ ಅನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿದ್ದೇನೆ, ನೀವು ಇದರಲ್ಲಿ ಕೋರ್ಸ್‌ನ ವಿವರಗಳ ಮೂಲಕ ಹೋಗಬಹುದು.",
              "te": "ఇక్కడ, నేను కళాశాల ప్రాస్పెక్టస్‌ను డౌన్‌లోడ్ చేసాను, నువ్వు ఇందులోని కోర్సు వివరాలను తెలుసుకోవచ్చు.",
              "or": "ଏଠାରେ, ମୁଁ କଲେଜର ପ୍ରୋସପେକ୍ଟସ୍ ଡାଉନଲୋଡ୍ କରିସାରିଛି, ତୁମେ ଏଥିରେ ପାଠ୍ୟକ୍ରମର ସବିଶେଷ ବିବରଣୀ ଦେଇ ଯାଇପାରିବ |",
              "as": "ইয়াত, মই মহাবিদ্যালয়ৰ প্ৰস্পেক্টাচ ডাউনলোড কৰিছোঁ, তুমি ইয়াত পাঠ্যক্ৰমৰ বিৱৰণবোৰ পঢ়িব পাৰা।",
              "gu": "અહીં, મે કોલેજનું પ્રોસ્પેક્ટર ડાઉનલોડ કરી લીધુ છે, તમે તેમાં અભ્યાસક્રમને લગતી વિગતોના માધ્યમમાં જોઈ શકો છો."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "x4ii_1_7 notebook",
              "hi": "X4II_1_7 नोटबुक",
              "ka": "x4ii_1_7 ನೋಟ್‌ಬುಕ್",
              "te": "x4ii_1_7 నోట్‌బుక్",
              "or": "x4ii_1_7 notebook",
              "as": "x4ii_1_7 টোকাবহী",
              "gu": "x4ii_1_7 નોટબુક"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to read the prospectus.",
              "hi": "प्रॉस्पेक्टस को पढ़ने के लिए क्लिक करें।",
              "ka": "ಪ್ರಾಸ್ಪೆಕ್ಟಸ್ ಓದಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "ప్రాస్పెక్టస్ చదవడానికి క్లిక్ చేయండి.",
              "or": "ପ୍ରୋସପେକ୍ଟସ୍ ପଢି଼ବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "প্ৰস্পেক্টাচখন পঢ়িবলৈ ক্লিক কৰক।",
              "gu": "પ્રોસ્પેક્ટસ વાંચવા માટે ક્લિક કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Political Science",
              "hi": "राजनीति विज्ञान",
              "ka": "ರಾಜಕೀಯ ವಿಜ್ಞಾನ",
              "te": "పోలిటికల్ సైన్స్",
              "or": "ରାଜନୀତି ବିଜ୍ଞାନ",
              "as": "ৰাজনীতি বিজ্ঞান",
              "gu": "પોલિટીકલ સાયન્સ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Sociology",
              "hi": "समाज शास्त्र",
              "ka": "ಸಮಾಜಶಾಸ್ತ್ರ",
              "te": "సోషియాలజీ",
              "or": "ସମାଜବିଜ୍ଞାନ",
              "as": "সমাজবিজ্ঞান",
              "gu": "સમાજશાસ્ત્ર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "**About the Course**   \nPolitical Science is a versatile subject. Through this course, you develop skills like negotiation and communication, writing, generic and analytical reasoning, and public response management.\n\n**Job Opportunities**\n- Political consultant\n\n- Journalism\n- Work with organisations working on policy \n- Law firms",
              "hi": "** पाठ्यक्रम के बारे में **\nराजनीति विज्ञान एक बहुमुखी विषय है। इस कोर्स के माध्यम से, आप वार्ता और संचार, लेखन, सामान्य और विश्लेषणात्मक तर्क, और सार्वजनिक प्रतिक्रिया प्रबंधन जैसे कौशल विकसित करते हैं।\n\n**रोजगार के अवसर**\n- राजनीतिक सलाहकार\n\n- पत्रकारिता\n- नीति पर काम कर रहे संगठनों के साथ काम करें\n- कानूनी फ़र्म",
              "ka": "**ಕೋರ್ಸ್ ಬಗ್ಗೆ**\n ರಾಜ್ಯಶಾಸ್ತ್ರವು ಬಹುಮುಖ ವಿಷಯವಾಗಿದೆ. ಈ ಕೋರ್ಸ್ ಮೂಲಕ, ನೀವು ಸಮಾಲೋಚನೆ ಮತ್ತು ಸಂವಹನ, ಬರವಣಿಗೆ, ಸಾರ್ವತ್ರಿಕ ಮತ್ತು ವಿಶ್ಲೇಷಣಾತ್ಮಕ ತಾರ್ಕಿಕತೆ ಮತ್ತು ಸಾರ್ವಜನಿಕ ಪ್ರತಿಕ್ರಿಯೆ ನಿರ್ವಹಣೆಯಂತಹ ಕೌಶಲ್ಯಗಳನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸುತ್ತೀರಿ.\n \n **ಉದ್ಯೋಗಾವಕಾಶಗಳು**\n - ರಾಜಕೀಯ ಸಲಹೆಗಾರ\n \n - ಪತ್ರಿಕೋದ್ಯಮ\n - ನೀತಿಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಸಂಸ್ಥೆಗಳೊಂದಿಗೆ ಕೆಲಸ ಮಾಡಿ\n - ಕಾನೂನು ಸಂಸ್ಥೆಗಳು",
              "te": "**కోర్సు గురించి**\n \n పొలిటికల్ సైన్స్ అనేది వర్సటైల్ సబ్జెక్ట్. ఈ కోర్సు ద్వారా, మీరు నెగోషియేషన్ మరియు కమ్యూనికేషన్, రైటింగ్, జెనెరిక్ మరియు ఎనలిటికల్ రీజనింగ్ మరియు పబ్లిక్ రెస్పాన్స్ మేనేజ్‌మెంట్ వంటి నైపుణ్యాలను అభివృద్ధి చేసుకోవచ్చు.\n \n \n \n **ఉద్యోగావకాశాలు**\n \n - పొలిటికల్ కన్సల్టెంట్ జర్నలిజం\n \n - పాలసీపై పనిచేసే సంస్థలతో కలిసి పని చేయడం\n \n - న్యాయ సంస్థలు",
              "or": "** ପାଠ୍ୟକ୍ରମ ବିଷୟରେ **\n ରାଜନୀତି ବିଜ୍ଞାନ ଏକ ବହୁମୁଖୀ ବିଷୟ ଅଟେ | ଏହି ପାଠ୍ୟକ୍ରମ ମାଧ୍ୟମରେ, ଆପଣ ବୁଝାମଣା ଏବଂ ଯୋଗାଯୋଗ, ଲେଖିବା, ଜେନେରିକ୍ ଏବଂ ଆନାଲିଟିକାଲ୍ ଯୁକ୍ତିଯୁକ୍ତତା ଏବଂ ଜନସାଧାରଣ ପ୍ରତିକ୍ରିୟା ପରିଚାଳନା ଭଳି ଦକ୍ଷତା ବିକାଶ କରନ୍ତି |\n \n ** ଚାକିରି ସୁଯୋଗ **\n - ରାଜନୀତିକ ପରାମର୍ଶଦାତା \n \n - ସାମ୍ବାଦିକତା \n - ନୀତିରେ କାର୍ଯ୍ୟ କରୁଥିବା ସଂସ୍ଥାଗୁଡ଼ିକ ସହିତ କାର୍ଯ୍ୟ କରିବେ \n - ଆଇନ ସଂସ୍ଥାଗୁଡ଼ିକ",
              "as": "**পাঠ্যক্ৰমৰ বিষয়ে** \n ৰাজনীতি বিজ্ঞান এক বহুমুখী বিষয়। এই পাঠ্যক্ৰমৰ জৰিয়তে, তুমি আলাপ-আলোচনা আৰু যোগাযোগ, লিখনি, সাধাৰণ আৰু বিশ্লেষণাত্মক যুক্তি, আৰু ৰাজহুৱা সঁহাৰি ব্যৱস্থাপনা আদিৰ দৰে দক্ষতাৰ বিকাশ কৰিব পাৰিবা।\n \n **চাকৰিৰ সুযোগ**\n - ৰাজনৈতিক পৰামৰ্শদাতা\n \n - সাংবাদিকতা\n -কৰ্মনীতিৰ ওপৰত কাম কৰা প্ৰতিষ্ঠানবোৰৰ সৈতে কাম কৰা \n - আইন প্ৰতিষ্ঠানসমূহ",
              "gu": "** કોર્સ વિશે**રાજકીય વિજ્ઞાન બહુમુખી વિષય છે. આ કોર્સ દ્વારા, તમે વાટાઘાટો અને સંદેશાવ્યવહાર, લેખન, સામાન્ય અને વિશ્લેષણાત્મક તર્ક અને જાહેર પ્રતિભાવ વ્યવસ્થાપન જેવી કુશળતા વિકસાવો છો.**નોકરી ની તકો**- રાજકીય સલાહકાર- પત્રકારત્વ- નીતિ પર કામ કરતી સંસ્થાઓ સાથે કામ કરો- કાયદાકીય સંસ્થાઓ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "**About the Course**\nMA in Sociology imparts knowledge about society and its mechanisms. \nThis course will help you develop a knack for detailed observation, patience, and good communication and research skills.\n\n**Job Opportunities**\n- Research \n\n- Social worker \n- Public health and welfare organisations, \n- Advertising firms\n- Public relations firms",
              "hi": "** पाठ्यक्रम के बारे में **\nसमाजशास्त्र में एमए समाज और उसके तंत्र के बारे में ज्ञान प्रदान करता है।\nयह कोर्स आपको विस्तृत अवलोकन, धैर्य और अच्छे संचार और शोध कौशल के लिए कुशलता विकसित करने में सहायक होगा।\n\n**रोजगार के अवसर**\n- अनुसंधान\n\n- समाज सेवक\n- सार्वजनिक स्वास्थ्य और कल्याण संगठन,\n- विज्ञापन फर्म\n- जनसंपर्क फर्म",
              "ka": "**ಕೋರ್ಸ್ ಬಗ್ಗೆ**\n ಸಮಾಜಶಾಸ್ತ್ರದಲ್ಲಿ ಎಂಎ ಸಮಾಜ ಮತ್ತು ಅದರ ಕಾರ್ಯವಿಧಾನಗಳ ಬಗ್ಗೆ ಜ್ಞಾನವನ್ನು ನೀಡುತ್ತದೆ.\n ವಿವರವಾದ ವೀಕ್ಷಣೆ, ತಾಳ್ಮೆ ಮತ್ತು ಉತ್ತಮ ಸಂವಹನ ಮತ್ತು ಸಂಶೋಧನಾ ಕೌಶಲ್ಯಗಳ ಕೌಶಲ್ಯವನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಲು ಈ ಕೋರ್ಸ್ ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.\n \n **ಉದ್ಯೋಗಾವಕಾಶಗಳು**\n - ಸಂಶೋಧನೆ\n \n - ಸಾಮಾಜಿಕ ಕಾರ್ಯಕರ್ತ\n - ಸಾರ್ವಜನಿಕ ಆರೋಗ್ಯ ಮತ್ತು ಕಲ್ಯಾಣ ಸಂಸ್ಥೆಗಳು,\n - ಜಾಹೀರಾತು ಸಂಸ್ಥೆಗಳು\n - ಸಾರ್ವಜನಿಕ ಸಂಪರ್ಕ ಸಂಸ್ಥೆಗಳು",
              "te": "**కోర్సు గురించి**\n \n సోషియాలజీలో MA సమాజం మరియు దాని యంత్రాంగాల గురించి జ్ఞానాన్ని అందిస్తుంది.\n \n వివరణాత్మక పరిశీలన, సహనం మరియు మంచి కమ్యూనికేషన్ మరియు రీసర్చ్ స్కిల్స్ పెంపొందించడానికి ఈ కోర్సు మీకు సహాయం చేస్తుంది.\n \n \n **ఉద్యోగావకాశాలు**\n - పరిశోధన\n \n \n - సామాజిక కార్యకర్త\n \n - ప్రజారోగ్యం మరియు సంక్షేమ సంస్థలు,\n \n - అడ్వర్టైజ్మెంట్ సంస్థలు\n \n - ప్రజా సంబంధాల సంస్థలు",
              "or": "** ପାଠ୍ୟକ୍ରମ ବିଷୟରେ **\n ସାମାଜିକ ବିଜ୍ଞାନରେ MA ସମାଜ ଏବଂ ଏହାର ଯନ୍ତ୍ରକୌଶଳ ବିଷୟରେ ଜ୍ଞାନ ପ୍ରଦାନ କରେ |\n ଏହି ପାଠ୍ୟକ୍ରମ ଆପଣଙ୍କୁ ବିସ୍ତୃତ ପର୍ଯ୍ୟବେକ୍ଷଣ, ଧୈର୍ଯ୍ୟ, ଏବଂ ଉତ୍ତମ ଯୋଗାଯୋଗ ଏବଂ ଅନୁସନ୍ଧାନ କୌଶଳ ପାଇଁ ଏକ ନିପୁଣତା ବିକଶିତ କରିବାରେ ସାହାଯ୍ୟ କରିବ |\n \n ** ଚାକିରି ସୁଯୋଗ **\n - ଅନୁସନ୍ଧାନ \n \n - ସମାଜସେବୀ\n - ଜନସ୍ୱାସ୍ଥ୍ୟ ଏବଂ କଲ୍ୟାଣକାରୀ ସଂଗଠନ,\n - ବିଜ୍ଞାପନ ସଂସ୍ଥାଗୁଡ଼ିକ \n - ଜନସମ୍ପର୍କ ସଂସ୍ଥାଗୁଡିକ",
              "as": "**পাঠ্যক্ৰমৰ বিষয়ে**\n সমাজবিজ্ঞানত MA কৰিলে সমাজ আৰু ইয়াৰ প্ৰণালীৰ বিষয়ে জ্ঞান লাভ কৰিব পাৰি। \n এই পাঠ্যক্ৰমে আপোনাক বিতং পৰ্যৱেক্ষণ, ধৈৰ্য্য, আৰু ভাল যোগাযোগ আৰু গৱেষণা দক্ষতাৰ বাবে এক কৌশল বিকশিত কৰাত সহায় কৰিব।\n \n **চাকৰিৰ সুযোগ**\n - গৱেষণা \n \n - সমাজকৰ্মী \n - জনস্বাস্থ্য আৰু কল্যাণ প্ৰতিষ্ঠান, \n - বিজ্ঞাপন প্ৰতিষ্ঠানসমূহ\n - জনসম্পৰ্ক প্ৰতিষ্ঠানসমূহ",
              "gu": "** કોર્સ વિશે**સમાજશાસ્ત્રમાં MA સમાજ અને તેની પદ્ધતિઓ વિશે જ્ઞાન આપે છે.આ કોર્સ તમને વિગતવાર અવલોકન, ધૈર્ય અને સારા સંચાર અને સંશોધન કૌશલ્યો વિકસાવવામાં મદદ કરશે.**નોકરી ની તકો**- સંશોધન- સામાજિક કાર્યકર- જાહેર આરોગ્ય અને કલ્યાણ સંસ્થાઓ,- જાહેરાત કંપનીઓ- જનસંપર્ક કંપનીઓ"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "x4ii_1_8 ///",
              "hi": "x4ii_1_8 ///",
              "ka": "x4ii_1_8 ///",
              "te": "x4ii_1_8 ///",
              "or": "x4ii_1_8 ///",
              "as": "x4ii_1_8 ///",
              "gu": "x4ii_1_8 ///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Wow, Arvind! This is so informative. Thanks for all the help.",
              "hi": "वाह, अरविंद! यह इतना सूचनाप्रद है। इस सहायताके लिए शुक्रिया।",
              "ka": "ವಾಹ್, ಅರವಿಂದ್! ಇದು ತುಂಬಾ ತಿಳಿವಳಿಕೆಯಾಗಿದೆ. ಎಲ್ಲಾ ಸಹಾಯಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು.",
              "te": "వావ్, అరవింద్! ఇందులో చాలా సమాచారం ఉంది. ఈ సహాయానికి ధన్యవాదాలు.",
              "or": "ୱାଓ, ଅରବିନ୍ଦ ! ଏହା ଅତ୍ୟନ୍ତ ସୂଚନାଯୋଗ୍ୟ | ସମସ୍ତ ସାହାଯ୍ୟ ପାଇଁ ଧନ୍ୟବାଦ |",
              "as": "ৱাও, অৰবিন্দ! এইটো ইমান তথ্যপূৰ্ণ। এই সকলো সহায়ৰ বাবে ধন্যবাদ।",
              "gu": "વાહ અરવિંદ! આ ઘણી માહિતીસભર છે. તમામ મદદ કરવા બદલ તારો આભાર"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I have saved all the information. You can reread it and decide.",
              "hi": "मैंने सारी जानकारी एकत्र कर ली है। तुम इसे फिर से पढ़ सकती हो और निर्णय कर सकती हो।",
              "ka": "ನಾನು ಎಲ್ಲಾ ಮಾಹಿತಿಯನ್ನು ಉಳಿಸಿದ್ದೇನೆ. ನೀವು ಅದನ್ನು ಮತ್ತೆ ಓದಬಹುದು ಮತ್ತು ನಿರ್ಧರಿಸಬಹುದು.",
              "te": "నేను మొత్తం సమాచారాన్ని సేవ్ చేసాను. మీరు దాన్ని మళ్లీ చదివి నిర్ణయం తీసుకోవచ్చు.",
              "or": "ମୁଁ ସମସ୍ତ ସୂଚନା ସେଭ୍ କରିଛି | ଆପଣ ଏହାକୁ ପୁନଃପଢି଼ପାରିବେ ଏବଂ ନିଷ୍ପତ୍ତି କରିପାରିବେ",
              "as": "মই সকলো তথ্য সংৰক্ষণ কৰিছোঁ। তুমি ইয়াক পুনৰ পঢ়িব পাৰা আৰু সিদ্ধান্ত ল'ব পাৰা।",
              "gu": "મે આ તમામ માહિતીને બચાવી રાખી છે. તુ તેને ફરી વાંચી શકે છે અને નિર્ણય કરી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Looking at the job opportunities and my interest, I want to study sociology.",
              "hi": "नौकरी के अवसरों और अपनी रुचि को देखते हुए, मैं समाजशास्त्र का अध्ययन करना चाहती हूं।",
              "ka": "ಉದ್ಯೋಗಾವಕಾಶಗಳು ಮತ್ತು ನನ್ನ ಆಸಕ್ತಿಯನ್ನು ನೋಡಿ, ನಾನು ಸಮಾಜಶಾಸ್ತ್ರವನ್ನು ಅಧ್ಯಯನ ಮಾಡಲು ಬಯಸುತ್ತೇನೆ.",
              "te": "ఉద్యోగావకాశాలు మరియు నా ఆసక్తిని చూసి నేను సోషియాలజీ చదవాలనుకుంటున్నాను.",
              "or": "ଚାକିରି ସୁଯୋଗଗୁଡିକ ଏବଂ ମୋର ଆଗ୍ରହକୁ ଦେଖି ମୁଁ ସାମାଜିକବିଜ୍ଞାନ ଅଧ୍ୟୟନ କରିବାକୁ ଚାହେଁ |",
              "as": "চাকৰিৰ সুযোগ আৰু মোৰ আগ্ৰহলৈ লক্ষ্য কৰি, মই সমাজবিজ্ঞান অধ্যয়ন কৰিব বিচাৰো।",
              "gu": "નોકરીની તકો તથા મારા રસને જોતા હું સમાજશાસ્ત્રનો અભ્યાસ કરવા માગું છું"
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "Internet research",
              "hi": "इंटरनेट अनुसंधान",
              "ka": "ಇಂಟರ್ನೆಟ್ ಸಂಶೋಧನೆ",
              "te": "ఇంటర్నెట్ రీసర్చ్",
              "or": "ଇଣ୍ଟରନେଟ୍ ଅନୁସନ୍ଧାନ",
              "as": "ইণ্টাৰনেট গৱেষণা",
              "gu": "ઈન્ટરનેટ રિસર્ચ"
            }
          }
        ]
      },
      "scene7": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_1_1",
              "hi": "X5_1_1",
              "ka": "X5_1_1",
              "te": "X5_1_1",
              "or": "X5_1_1",
              "as": "X5_1_1",
              "gu": "X5_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind scores well in the Telangana State Engineering, Agriculture, and Medical Common Entrance Test.",
              "hi": "तेलंगाना राज्य इंजीनियरिंग, कृषि, और चिकित्सा सामान्य प्रवेश परीक्षा में अरविंद स्कोर अच्छा स्कोर करता है।",
              "ka": "ತೆಲಂಗಾಣ ರಾಜ್ಯ ಎಂಜಿನಿಯರಿಂಗ್, ಕೃಷಿ ಮತ್ತು ವೈದ್ಯಕೀಯ ಸಾಮಾನ್ಯ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಯಲ್ಲಿ ಅರವಿಂದ್ ಉತ್ತಮ ಅಂಕಗಳನ್ನು ಗಳಿಸಿದ್ದಾರೆ.",
              "te": "తెలంగాణ స్టేట్ ఇంజినీరింగ్, అగ్రికల్చర్ మరియు మెడికల్ కామన్ ఎంట్రన్స్ టెస్ట్‌లో అరవింద్ మంచి స్కోర్ సాధించాడు.",
              "or": "ତେଲେଙ୍ଗାନା ଷ୍ଟେଟ୍‍ ଇଞ୍ଜିନିୟରିଂ, କୃଷି ଏବଂ ମେଡିକାଲ ସାଧାରଣ ପ୍ରବେଶିକା ପରୀକ୍ଷାରେ ଅରବିନ୍ଦ ଭଲ ସ୍କୋର କରନ୍ତି।",
              "as": "তেলেংগানা ৰাজ্যিক অভিযান্ত্ৰিক, কৃষি, আৰু চিকিৎসা উমৈহতীয়া প্ৰৱেশ পৰীক্ষাত অৰবিন্দে ভাল নম্বৰ লাভ কৰিছে।",
              "gu": "અરવિંદે તેલંગાણા સ્ટેટ એન્જીનિયરીંગ એગ્રીકલ્ચર તથા મેડિકલ કોમન એન્ટ્રેન્સ ટેસ્ટમાં સારો સ્કોર હાંસલ કર્યો છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_1_2",
              "hi": "X5_1_2",
              "ka": "X5_1_2",
              "te": "X5_1_2",
              "or": "X5_1_2",
              "as": "X5_1_2",
              "gu": "X5_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He gets admission to the University College of Technology, a government engineering college in Hyderabad.",
              "hi": "उसे  हैदराबाद में एक सरकारी इंजीनियरिंग कॉलेज, यूनिवर्सिटी कॉलेज ऑफ टेक्नोलॉजी में प्रवेश मिलता है।",
              "ka": "ಅವರು ಹೈದರಾಬಾದಿನ ಸರ್ಕಾರಿ ಇಂಜಿನಿಯರಿಂಗ್ ಕಾಲೇಜಾಗಿರುವ ಯುನಿವರ್ಸಿಟಿ ಕಾಲೇಜ್ ಆಫ್ ಟೆಕ್ನಾಲಜಿಗೆ ಪ್ರವೇಶ ಪಡೆಯುತ್ತಾರೆ.",
              "te": "అతను యూనివర్సిటీ కాలేజ్ ఆఫ్ టెక్నాలజీ, హైదరాబాద్‌లోని ప్రభుత్వ ఇంజనీరింగ్ కళాశాలలో అడ్మిషన్ పొందాడు.",
              "or": "ସେ ହାଇଦ୍ରାବାଦର ଏକ ସରକାରୀ ଇଞ୍ଜିନିୟରିଂ କଲେଜ, ୟୁନିଭରସିଟି କଲେଜ ଅଫ୍ ଟେକ୍ନୋଲୋଜିରେ ଆଡମିଶନ ପାଆନ୍ତି।",
              "as": "তেওঁ হায়দৰাবাদৰ এখন চৰকাৰী অভিযান্ত্ৰিক কলেজ ইউনিভাৰ্চিটি কলেজ অফ টেকন'লজীত নামভৰ্তি কৰে।",
              "gu": "તેને હૈદરાબાદની એક સરકારી એન્જીનિયરિંગ કોલેજ, યુનિવર્સિટી કોલેજ ઓફ ટેકનોલોજીમાં એડમિશન મળ્યું છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_1_3",
              "hi": "X5_1_3",
              "ka": "X5_1_3",
              "te": "X5_1_3",
              "or": "X5_1_3",
              "as": "X5_1_3",
              "gu": "X5_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The computer science course in his college is popular and has the highest number of students. Arvind is interested in the subject and likes attending classes.",
              "hi": "उसके कॉलेज में कंप्यूटर विज्ञान पाठ्यक्रम लोकप्रिय है और इसमें उच्चतम छात्र हैं। अरविंद विषय में रुचि रखता है और कक्षाओं में भाग लेना पसंद करता है।",
              "ka": "ಅವರ ಕಾಲೇಜಿನಲ್ಲಿ ಕಂಪ್ಯೂಟರ್ ಸೈನ್ಸ್ ಕೋರ್ಸ್ ಜನಪ್ರಿಯವಾಗಿದೆ ಮತ್ತು ಹೆಚ್ಚಿನ ಸಂಖ್ಯೆಯ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಹೊಂದಿದೆ. ಅರವಿಂದ್ ಅವರು ವಿಷಯದ ಬಗ್ಗೆ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದಾರೆ ಮತ್ತು ತರಗತಿಗಳಿಗೆ ಹಾಜರಾಗಲು ಇಷ್ಟಪಡುತ್ತಾರೆ.",
              "te": "అతని కళాశాలలో కంప్యూటర్ సైన్స్ కోర్సు పాపులర్ మరియు అత్యధిక సంఖ్యలో విద్యార్థులను ఉన్నారు. అరవింద్‌కు సబ్జెక్ట్‌పై ఆసక్తి ఉంది మరియు తరగతులకు హాజరు కావడానికి ఇష్టపడతాడు.",
              "or": "ତାଙ୍କ କଲେଜରେ କମ୍ପ୍ୟୁଟର ବିଜ୍ଞାନ ପାଠ୍ୟକ୍ରମ ଲୋକପ୍ରିୟ ଏବଂ ଏଥିରେ ସର୍ବାଧିକ ସଂଖ୍ୟକ ଛାତ୍ର ଅଛନ୍ତି | ଅରବିନ୍ଦ ଏହି ବିଷୟ ପ୍ରତି ଆଗ୍ରହୀ ଏବଂ କ୍ଲାସରେ ଯୋଗଦେବାକୁ ପସନ୍ଦ କରନ୍ତି |",
              "as": "তেওঁৰ মহাবিদ্যালয়ৰ কম্পিউটাৰ বিজ্ঞান পাঠ্যক্ৰমটো জনপ্ৰিয় আৰু ইয়াত সৰ্বাধিক সংখ্যক শিক্ষাৰ্থী আছে। অৰবিন্দ বিষয়টোৰ প্ৰতি আগ্ৰহী আৰু শ্ৰেণীত উপস্থিত থাকিবলৈ ভাল পায়।",
              "gu": "તેમણે કોલેજમાં કોમ્પ્યુટર સાયન્સનો કોર્સ ઘણો લોકપ્રિય છે તથા તેમાં વિદ્યાર્થીની સંખ્યા સૌથી વધારે છે.અરવિંદ આ વિષયમાં રસ ધરાવે છે અને વર્ગખંડોમાં ભાગ લેવાનું પસંદ કરે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_1_4",
              "hi": "X5_1_4",
              "ka": "X5_1_4",
              "te": "X5_1_4",
              "or": "X5_1_4",
              "as": "X5_1_4",
              "gu": "X5_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "His parents are happy that he got a government seat in computer science. His father takes a small loan to fund Arvind’s education. Arvind shifts to Hyderabad to stay in the university campus hostel.",
              "hi": "उसके माता-पिता खुश हैं कि उन्हें कंप्यूटर विज्ञान में सरकारी सीट मिली है। उसके पिता अरविंद का शिक्षा शुल्क देने के लिए एक छोटा सा ऋण लेते हैं। अरविंद  विश्वविद्यालय परिसर छात्रावास में रहने के लिए  हैदराबाद चला जाता है।",
              "ka": "ಕಂಪ್ಯೂಟರ್ ಸೈನ್ಸ್ ನಲ್ಲಿ ಸರ್ಕಾರಿ ಸೀಟು ಸಿಕ್ಕಿರುವುದಕ್ಕೆ ತಂದೆ-ತಾಯಿ ಸಂತಸ ವ್ಯಕ್ತಪಡಿಸಿದ್ದಾರೆ. ಅರವಿಂದನ ವಿದ್ಯಾಭ್ಯಾಸಕ್ಕೆ ಅವನ ತಂದೆ ಸಣ್ಣ ಸಾಲವನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾರೆ. ಅರವಿಂದ್ ಯೂನಿವರ್ಸಿಟಿ ಕ್ಯಾಂಪಸ್ ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿ ಉಳಿಯಲು ಹೈದರಾಬಾದ್‌ಗೆ ತೆರಳುತ್ತಾನೆ.",
              "te": "కంప్యూటర్ సైన్స్ లో ప్రభుత్వ సీటు రావడంతో అతని తల్లిదండ్రులు సంతోషం వ్యక్తం చేస్తున్నారు. అరవింద్ చదువు కోసం అతని తండ్రి చిన్న లోన్ తీసుకుంటాడు. యూనివర్సిటీ క్యాంపస్ హాస్టల్‌లో ఉండేందుకు అరవింద్ హైదరాబాద్‌కు షిఫ్ట్ అయ్యాడు.",
              "or": "କମ୍ପ୍ୟୁଟର ବିଜ୍ଞାନରେ ସେ ସରକାରୀ ସିଟ୍‌ ପାଇଥିବାରୁ ତାଙ୍କ ପିତାମାତା ଖୁସି ଅଛନ୍ତି। ଅରବିନ୍ଦଙ୍କ ଶିକ୍ଷାକୁ ପୈଠ କରିବା ପାଇଁ ତାଙ୍କ ପିତା ଏକ ଛୋଟ ଋଣ ନେଇଥାନ୍ତି | ବିଶ୍ୱବିଦ୍ୟାଳୟ କ୍ୟାମ୍ପସ୍‌ ହଷ୍ଟେଲରେ ରହିବାକୁ ଅରବିନ୍ଦ ହାଇଦ୍ରାବାଦକୁ ସ୍ଥାନାନ୍ତରିତ ହୁଅନ୍ତି |",
              "as": "তেওঁৰ পিতৃ-মাতৃ সুখী যে তেওঁ কম্পিউটাৰ বিজ্ঞানত চৰকাৰী আসন লাভ কৰিছে। তেওঁৰ দেউতাকে অৰবিন্দৰ শিক্ষাৰ বাবে পুঁজি যোগান ধৰিবলৈ এটা সৰু ঋণ লয়। অৰবিন্দ বিশ্ববিদ্যালয় চৌহদৰ হোষ্টেলত থাকিবলৈ হায়দৰাবাদলৈ যায়।",
              "gu": "તેના માતા-પિતા ઘણા ખુશ છે કે તેને કોમ્પ્યુટર સાયન્સમાં સરકારી સીટ મળી. તેના પિતા અરવિંદના શિક્ષણ માટે એક નાની લોન લીધી છે. અરવિંદ યુનિવર્સિટી કેમ્પસની હોસ્ટલમાં રહેવા માટે હૈદરાબાદ જઈ રહિયો  હતો."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_2_1",
              "hi": "X5_2_1",
              "ka": "X5_2_1",
              "te": "X5_2_1",
              "or": "X5_2_1",
              "as": "X5_2_1",
              "gu": "X5_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind stays in a room with another student, Prakash. Although Prakash is friendly, they don’t talk much as he is studying in a different department.",
              "hi": "अरविंद एक कमरे में एक और छात्र, प्रकाश के साथ रहता है। यद्यपि प्रकाश मित्रतापूर्ण है फिर भी वे ज्यादा बातें नहीं करते  हैं क्योंकि वह एक अलग विभाग में पढ़ रहा है  ।",
              "ka": "ಅರವಿಂದ್ ಮತ್ತೋರ್ವ ವಿದ್ಯಾರ್ಥಿ ಪ್ರಕಾಶ್ ಜೊತೆ ಕೊಠಡಿಯಲ್ಲಿ ತಂಗಿದ್ದಾನೆ. ಪ್ರಕಾಶ್ ಸ್ನೇಹಜೀವಿಯಾದರೂ ಬೇರೆ ವಿಭಾಗದಲ್ಲಿ ಓದುತ್ತಿರುವುದರಿಂದ ಹೆಚ್ಚು ಮಾತನಾಡುವುದಿಲ್ಲ.",
              "te": "అరవింద్ మరో విద్యార్థి ప్రకాష్‌తో కలిసి ఓ గదిలో ఉంటున్నాడు. ప్రకాష్ స్నేహపూర్వకంగా ఉన్నప్పటికీ, అతను వేరే డిపార్ట్‌మెంట్‌లో చదువుతున్నందున వారు ఎక్కువగా మాట్లాడుకోరు.",
              "or": "ଅରବିନ୍ଦ ଅନ୍ୟ ଜଣେ ଛାତ୍ର ପ୍ରକାଶଙ୍କ ସହିତ ଏକ ରୁମ୍‌ରେ ରୁହନ୍ତି | ଯଦିଓ ପ୍ରକାଶ ବନ୍ଧୁତ୍ୱପୂର୍ଣ୍ଣ, ସେମାନେ ଅଧିକ କଥାବାର୍ତ୍ତା କରନ୍ତି ନାହିଁ ଯେହେତୁ ସେ ଏକ ଭିନ୍ନ ବିଭାଗରେ ପଢେ |",
              "as": "অৰবিন্দ আন এজন ছাত্ৰ প্ৰকাশৰ সৈতে এটা কোঠাত থাকে। যদিও প্ৰকাশ বন্ধুত্বপূৰ্ণ, তেওঁলোকে বেছি কথা নকয় কিয়নো তেওঁ বেলেগ বিভাগত পঢ়ি আছে।",
              "gu": "અરવિંદ અન્ય એક વિદ્યાર્થી પ્રકાશ સાથે એક રૂમમાં રહે છે. જોકે પ્રકાશ ખુબ સારી વ્યક્તિ છે, પણ તે વધારે વાતચીત કરતો નથી કારણ કે તે એક અલગ વિભાગમાં અભ્યાસ કરે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_2_2",
              "hi": "X5_2_2",
              "ka": "X5_2_2",
              "te": "X5_2_2",
              "or": "X5_2_2",
              "as": "X5_2_2",
              "gu": "X5_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind still feels quite uncomfortable sharing his room with another boy.",
              "hi": "अरविंद अभी भी एक और लड़के के साथ अपने कमरे को साझा करने में काफी असहज अनुभव करता है।",
              "ka": "ಅರವಿಂದ್ ಇನ್ನೂ ತನ್ನ ಕೋಣೆಯನ್ನು ಇನ್ನೊಬ್ಬ ಹುಡುಗನೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಲು ಸಾಕಷ್ಟು ಅನಾನುಕೂಲತೆಯನ್ನು ಅನುಭವಿಸುತ್ತಾನೆ.",
              "te": "అరవింద్ తన గదిని మరొక అబ్బాయితో పంచుకోవడం చాలా అసౌకర్యంగా భావిస్తాడు.",
              "or": "ଅରବିନ୍ଦ ତଥାପି ନିଜ ରୁମ୍‌କୁ ଅନ୍ୟ ପୁଅ ସହିତ ଅଂଶୀଦାର କରିବାରେ ବହୁତ ଅସହଜ ଅନୁଭବ କରନ୍ତି |",
              "as": "অৰবিন্দে এতিয়াও আন এটা ল'ৰাৰ সৈতে তেওঁৰ ৰুমটো ভাগ-বতৰা কৰিবলৈ যথেষ্ট অস্বস্তি অনুভৱ কৰে।",
              "gu": "અરવિંદ હજુ પણ અન્ય છોકરાઓ સાથે પોતાની રૂમ શેર કરવામાં ઘણી અસહજતા અનુભવે છે"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_2_3",
              "hi": "X5_2_3",
              "ka": "X5_2_3",
              "te": "X5_2_3",
              "or": "X5_2_3",
              "as": "X5_2_3",
              "gu": "X5_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "For the first two semesters, Arvind stays in the college hostel. Like Roshni had warned him, staying in the hostel is not easy.",
              "hi": "पहले दो सेमेस्टर के लिए, अरविंद कॉलेज छात्रावास में रहता है। जैसे रोशनी ने उसे चेतावनी दी थी, छात्रावास में रहना सरल नहीं है।",
              "ka": "ಮೊದಲ ಎರಡು ಸೆಮಿಸ್ಟರ್‌ಗಳಿಗೆ ಅರವಿಂದ್ ಕಾಲೇಜು ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿ ಇರುತ್ತಾರೆ. ರೋಶನಿ ಅವರಿಗೆ ಎಚ್ಚರಿಕೆ ನೀಡಿದಂತೆ, ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿ ಉಳಿಯುವುದು ಸುಲಭವಲ್ಲ.",
              "te": "మొదటి రెండు సెమిస్టర్లు అరవింద్ కాలేజీ హాస్టల్‌లోనే ఉంటాడు. రోష్ని హెచ్చరించినట్లుగా, హాస్టల్‌లో ఉండటం సులభం కాదు.",
              "or": "ପ୍ରଥମ ଦୁଇଟି ସେମିଷ୍ଟାର ପାଇଁ, ଅରବିନ୍ଦ କଲେଜ ହଷ୍ଟେଲରେ ରୁହନ୍ତି | ଯେପରି ରୋଶନି ତାଙ୍କୁ ଚେତାବନୀ ଦେଇଥିଲେ, ହଷ୍ଟେଲରେ ରହିବା ସହଜ ନୁହେଁ |",
              "as": "প্ৰথম দুটা ছেমিষ্টাৰৰ বাবে, অৰবিন্দ কলেজ হোষ্টেলত থাকে। ৰোশনিয়ে তেওঁক সতৰ্ক কৰাৰ দৰে, হোষ্টেলত থকাটো সহজ নহয়।",
              "gu": "પ્રથમ બે સેમેસ્ટર માટે અરવિંદ કોલેજની હોસ્ટેલમાં રહે છે. જેવા રોશનીએ તેને ચેતવણી આપી હતી, હોસ્ટેલમાં રહેવું એટલું સરળ નથી."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_3_2 bg",
              "hi": "X5_3_2 बीजी",
              "ka": "X5_3_2 ಬಿಜಿ",
              "te": "X5_3_2 bg",
              "or": "X5_3_2 bg",
              "as": "X5_3_2 bg",
              "gu": "X5_3_2 bg"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "One night a few seniors barge into their room.",
              "hi": "एक रात कुछ सीनियर उसके कमरे में जबरदस्ती घुस आते हैं।",
              "ka": "ಒಂದು ರಾತ್ರಿ ಕೆಲವು ಹಿರಿಯರು ಅವರ ಕೋಣೆಗೆ ನುಗ್ಗುತ್ತಾರೆ.",
              "te": "ఒక రాత్రి కొంతమంది సీనియర్లు వారి గదిలోకి ప్రవేశించారు.",
              "or": "ଗୋଟିଏ ରାତିରେ କିଛି ସିନିୟରମାନେ ସେମାନଙ୍କ ରୁମ୍‌କୁ ଧସେଇ ପଶନ୍ତି |",
              "as": "এদিন ৰাতি কেইজনমান জ্যেষ্ঠ ছাত্ৰ তেওঁলোকৰ কোঠাত সোমাই পৰিল।",
              "gu": "એક રાત્રે કેટલાક વરિષ્ઠ તેના રૂમમાં પ્રવેશ કર્યો હતો."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Senior 1",
              "hi": "सीनियर 1",
              "ka": "ಹಿರಿಯ 1",
              "te": "సీనియర్ 1",
              "or": "Senior 1",
              "as": "জ্যেষ্ঠ ছাত্ৰ ১",
              "gu": "વરિષ્ઠ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Don’t you both know that you have to stand up and greet your seniors if they are in the room?!",
              "hi": "क्या तुम दोनों नहीं जानते कि तुमको खड़े रहना है और अपने सीनियर को अभिवादन करना है यदि वे कमरे में हैं?!",
              "ka": "ಸೀನಿಯರ್ ಗಳು ರೂಮಿನಲ್ಲಿದ್ದರೆ ಎದ್ದು ನಿಂತು ನಮಸ್ಕಾರ ಮಾಡಬೇಕು ಅಂತ ಇಬ್ಬರಿಗೂ ಗೊತ್ತಿಲ್ಲವೇ?!",
              "te": "మీ సీనియర్లు గదిలోకి వస్తే లేచి నిలబడి పలకరించాలని మీ ఇద్దరికీ తెలియదా?!",
              "or": "ତୁମେ ଦୁହେଁ ଜାଣି ନାହଁ କି ତୁମକୁ ଠିଆ ହୋଇ ତୁମର ସିନିୟରମାନଙ୍କୁ ଅଭିବାଦନ କରିବାକୁ ପଡିବ ଯଦି ସେମାନେ ରୁମ୍‌ରେ ଥାନ୍ତି ?!",
              "as": "তোমালোক দুয়ো নাজানা নেকি যে জ্যেষ্ঠ ছাত্ৰ ৰুমলৈ আহিলে তোমালোকে থিয় হৈ সম্ভাষণ জনাব লাগে?!",
              "gu": "શું તમે બંને નથી જાણતા કે જો તમારા વરિષ્ઠ લોકો રૂમમાં હોય તો તમારે ઊભા થઈને અભિવાદન કરવું પડશે?!"
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_3_3///",
              "hi": "X5_3_3 ///",
              "ka": "X5_3_3///",
              "te": "X5_3_3///",
              "or": "X5_3_3///",
              "as": "X5_3_3///",
              "gu": "X5_3_3///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Arvind and Prakash immediately stand up and do namaste.",
              "hi": "अरविंद और प्रकाश तुरंत खड़े हो जाते हैं और नमस्ते करते हैं।",
              "ka": "ಅರವಿಂದ್ ಮತ್ತು ಪ್ರಕಾಶ್ ತಕ್ಷಣ ಎದ್ದು ನಮಸ್ತೆ ಮಾಡಿದರು.",
              "te": "అరవింద్ మరియు ప్రకాష్ వెంటనే లేచి నిలబడి నమస్తే పెడతారు.",
              "or": "ଅରବିନ୍ଦ ଏବଂ ପ୍ରକାଶ ତୁରନ୍ତ ଠିଆ ହୁଅନ୍ତି ଏବଂ ନମଷ୍କାର କରନ୍ତି |",
              "as": "অৰবিন্দ আৰু প্ৰকাশে লগে লগে থিয় হৈ নমস্কাৰ কৰে।",
              "gu": "અરવિંદ અને પ્રકાશ તાત્કાલિક ઉભા થાય છે અને કહે છે નમસ્તે"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "[Looking at Arvind] You are doing engineering or fashion? What kind of clothes are you wearing?",
              "hi": "[अरविंद को देखते हुए] तुम इंजीनियरिंग या फैशन कर रहे हो? तुम किस तरह के कपड़े पहन रहे हो?",
              "ka": "[ಅರವಿಂದನನ್ನು ನೋಡುತ್ತಾ] ನೀನು ಇಂಜಿನಿಯರಿಂಗ್ ಮಾಡುತ್ತಿದ್ದೀಯಾ ಅಥವಾ ಫ್ಯಾಷನ್ ಮಾಡುತ್ತಿದ್ದೀಯಾ? ನೀವು ಯಾವ ರೀತಿಯ ಬಟ್ಟೆಗಳನ್ನು ಧರಿಸಿದ್ದೀರಿ?",
              "te": "[అరవింద్ వైపు చూస్తూ] నువ్వు ఇంజనీరింగ్ చేస్తున్నావా లేక ఫ్యాషన్ చేస్తున్నావా? నువ్వు ఎలాంటి దుస్తులు ధరించావు?",
              "or": "[ଅରବିନ୍ଦକୁ ଚାହିଁ] ତୁମେ ଇଞ୍ଜିନିୟରିଂ କରୁଛ କି ଫ୍ୟାଶନ୍ ? ତୁମେ କେଉଁ ପ୍ରକାରର ପୋଷାକଗୁଡିକ ପିନ୍ଧୁଛ ?",
              "as": "[অৰবিন্দলৈ চাই] তুমি অভিযান্ত্ৰিক পঢ়িব আহিছা নে ফেশ্বন কৰিব আহিছা? তুমি এইবোৰ কেনে ধৰণৰ কাপোৰ পিন্ধি আছা?",
              "gu": "[અરવિંદ તરફ જોઈને] તુ એન્જીનિયરિંગ કરે છે કે ફેશન? તમે કેવા પ્રકારના કપડાં રહેરો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Arvind looks at the senior nervously.",
              "hi": "अरविंद घबराहट के साथ सीनियर को देखता है।",
              "ka": "ಅರವಿಂದ್ ಸೀನಿಯರ್ ನತ್ತ ಆತಂಕದಿಂದ ನೋಡುತ್ತಾನೆ.",
              "te": "అరవింద్ భయంగా సీనియర్ వైపు చూస్తున్నాడు.",
              "or": "ଅରବିନ୍ଦ ସିନିୟରଙ୍କୁ ନର୍ଭସ ହୋଇ ଦେଖନ୍ତି |",
              "as": "অৰবিন্দে জ্যেষ্ঠ ছাত্ৰ জনৰ ফালে উদ্বিগ্ন হৈ চালে।",
              "gu": "અરવિંદ તેના વરિષ્ઠ તરફ ઉદાસ થઈને જુએ છે"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Hey, this is the guy I told you about. He always locks the bathroom door when he goes for a bath.",
              "hi": "अरे, यह वही लड़का है जिसके बारे में मैंने आपको बताया था। जब वह स्नान के लिए जाता है तो वह हमेशा बाथरूम के दरवाजे को बंद कर देता है।",
              "ka": "ಹೇ, ಇವನು ನಾನು ನಿನಗೆ ಹೇಳಿದ ವ್ಯಕ್ತಿ. ಅವನು ಸ್ನಾನಕ್ಕೆ ಹೋದಾಗ ಯಾವಾಗಲೂ ಬಾತ್ ರೂಂ ಬಾಗಿಲಿಗೆ ಬೀಗ ಹಾಕುತ್ತಾನೆ.",
              "te": "హే, నేను మీకు చెప్పిన వ్యక్తి ఇతనే. అతను స్నానానికి వెళ్లినప్పుడు ఎప్పుడూ బాత్‌రూమ్‌ తలుపు తాళం వేసుకుంటాడు.",
              "or": "ଆରେ, ଏହି ହେଉଛି ସେହି ବ୍ୟକ୍ତି ଯାହା ବିଷୟରେ ମୁଁ ତୁମକୁ କହିଥିଲି | ସେ ସବୁବେଳେ ବାଥରୁମ୍‌ କବାଟ ବନ୍ଦ କରିଦିଏ ଯେବେ ସେ ଗାଧୋଇବାକୁ ଯାଏ |",
              "as": "হেই, এইজনেই সেই ল'ৰাজন যাৰ বিষয়ে মই তোমাক কৈছিলোঁ। যেতিয়া তেওঁ গা ধুবলৈ যায় তেতিয়া তেওঁ সদায় বাথৰুমৰ দুৱাৰখন লক কৰে।",
              "gu": "અરે, આ એ જ છોકરો છે કે જેમના વિશે મે તમને કહ્યું હતું. જ્યારે તે નાહવા જાય છે તો તે હંમેશા બાથરૂમનો દરવાજો બંધ કરી લે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Are you a girl or what? If I see you doing it again, I will lock you in the bathroom.",
              "hi": "क्या तुम लड़की हो क्या? अगर मैंने तुम्हें फिर से ऐसा करते देखा, तो मैं तुमको बाथरूम में बंद कर दूंगा।",
              "ka": "ನೀವು ಹುಡುಗಿಯೇ ಅಥವಾ ಏನು? ನೀನು ಮತ್ತೆ ಹಾಗೆ ಮಾಡುವುದನ್ನು ನಾನು ನೋಡಿದರೆ, ನಾನು ನಿನ್ನನ್ನು ಬಾತ್ರೂಮ್ನಲ್ಲಿ ಲಾಕ್ ಮಾಡುತ್ತೇನೆ.",
              "te": "నువ్వు ఆడపిల్లవా ఏమిటి? నువ్వు మళ్ళీ ఇలా చేయడం చూస్తే నిన్ను బాత్రూంలో పెట్టి లాక్ చేస్తాను.",
              "or": "ତୁମେ ଜଣେ ଝିଅ ନା କଣ ? ଯଦି ମୁଁ ତୁମକୁ ପୁନର୍ବାର ଏହା କରୁଥିବାର ଦେଖେ, ମୁଁ ତୁମକୁ ବାଥରୁମରେ ବନ୍ଦ କରିଦେବି |",
              "as": "তুমি ছোৱালী নেকি? যদি মই তোমাক এইটো পুনৰ কৰা দেখোঁ, মই তোমাক বাথৰুমত বন্দী কৰি থম।",
              "gu": "અરે તું છોકરી છે? હવે પછી આમ કરતા જોવા મળીશ તો હું તને બાથરૂમમાં જ બંધ કરી દઈશ."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_3_4",
              "hi": "X5_3_4",
              "ka": "X5_3_4",
              "te": "X5_3_4",
              "or": "X5_3_4",
              "as": "X5_3_4",
              "gu": "X5_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Prakash and Arvind are scared. The seniors tease Arvind, and Prakash doesn’t do anything.",
              "hi": "प्रकाश और अरविंद डरे हुए हैं। सीनियर अरविंद को चिढ़ाते हैं, और प्रकाश कुछ भी नहीं करता है।",
              "ka": "ಪ್ರಕಾಶ್ ಮತ್ತು ಅರವಿಂದ್ ಭಯಗೊಂಡಿದ್ದಾರೆ. ಹಿರಿಯರು ಅರವಿಂದ್ ಅವರನ್ನು ಚುಡಾಯಿಸುತ್ತಾರೆ, ಮತ್ತು ಪ್ರಕಾಶ್ ಏನನ್ನೂ ಮಾಡುವುದಿಲ್ಲ.",
              "te": "ప్రకాష్‌, అరవింద్‌లు భయపడ్డారు. సీనియర్లు అరవింద్‌ని ఆటపట్టించారు, కానీ ప్రకాష్ ఏమీ చేయడు.",
              "or": "ପ୍ରକାଶ ଏବଂ ଅରବିନ୍ଦ ଭୟଭୀତ ଅଛନ୍ତି। ସିନିୟରମାନେ ଅରବିନ୍ଦଙ୍କୁ ଥଟ୍ଟା କରନ୍ତି, ଏବଂ ପ୍ରକାଶ କିଛି କରନ୍ତି ନାହିଁ |",
              "as": "প্ৰকাশ আৰু অৰবিন্দে ভয় খাইছে। জ্যেষ্ঠ ছাত্ৰবোৰে অৰবিন্দক জোকায় আৰু প্ৰকাশে একো নকৰে।",
              "gu": "પ્રકાશ અને અરવિંદ ડરેલા છે. વરિષ્ઠ અરવિંદને ચિડાવે છે અને પ્રકાશ કંઈ જ કરતો નથી."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_4_1",
              "hi": "X5_4_1",
              "ka": "X5_4_1",
              "te": "X5_4_1",
              "or": "X5_4_1",
              "as": "X5_4_1",
              "gu": "X5_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "A little while later, they leave. Arvind feels humiliated and scared. He sits on his bed and cries.",
              "hi": "थोड़ी देर बाद, वे छोड़ देते हैं। अरविंद को अपमानित और डर लगता है। वह अपने बिस्तर पर बैठता है और रोता है।",
              "ka": "ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ, ಅವರು ಹೊರಡುತ್ತಾರೆ. ಅರವಿಂದ್‌ಗೆ ಅವಮಾನ ಮತ್ತು ಭಯವಾಗುತ್ತದೆ. ಅವನು ತನ್ನ ಹಾಸಿಗೆಯ ಮೇಲೆ ಕುಳಿತು ಅಳುತ್ತಾನೆ.",
              "te": "కొద్దిసేపటి తరువాత, వారు వెళ్లిపోతారు. అరవింద్ అవమానంగా మరియు భయంగా ఫీల్ అవుతాడు. అతను తన మంచం మీద కూర్చుని ఏడుస్తున్నాడు.",
              "or": "ଅଳ୍ପ ସମୟ ପରେ, ସେମାନେ ଚାଲିଯାଆନ୍ତି | ଅରବିନ୍ଦ ଅପମାନିତ ଏବଂ ଭୟଭୀତ ଅନୁଭବ କରନ୍ତି | ସେ ନିଜ ବିଛଣା ଉପରେ ବସନ୍ତି ଏବଂ କାନ୍ଦନ୍ତି |",
              "as": "অলপ পিছত, তেওঁলোক গুচি যায়। অৰবিন্দ অপমানিত হয় আৰু ভয় খায়। তেওঁ বিচনাত বহি কান্দে।",
              "gu": "થોડીવાર રહીને તેઓ જતા રહે છે. અરવિંદ અપમાનિત અને ડરનો અહેસાસ કરે છે. તેઓ પોતાની પથારી પર બેસે છે અને રડે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_4_2",
              "hi": "X5_4_2",
              "ka": "X5_4_2",
              "te": "X5_4_2",
              "or": "X5_4_2",
              "as": "X5_4_2",
              "gu": "X5_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind starts using the common spaces in the hostel when nobody is around.",
              "hi": "जब कोई भी आसपास नहीं होता है तो अरविंद छात्रावास में कॉमन स्थान का उपयोग करना शुरू कर देता है।",
              "ka": "ಅರವಿಂದ್ ಯಾರೂ ಇಲ್ಲದಿದ್ದಾಗ ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿರುವ ಸಾಮಾನ್ಯ ಸ್ಥಳಗಳನ್ನು ಬಳಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ.",
              "te": "ఎవరూ లేని సమయంలో అరవింద్ హాస్టల్‌లోని కామన్ స్థలాలను ఉపయోగించడం ప్రారంభించాడు.",
              "or": "ଯେତେବେଳେ କେହି ଆଖପାଖରେ ନଥାନ୍ତି ଅରବିନ୍ଦ ହଷ୍ଟେଲରେ ସାଧାରଣ ସ୍ଥାନ ବ୍ୟବହାର କରିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "যেতিয়া কোনো ওচৰত নাথাকে অৰবিন্দে হোষ্টেলৰ সাধাৰণ ঠাইবোৰত চলা-ফুৰা কৰে।",
              "gu": "જ્યારે આજુબાજુ કોઈ ન હોય ત્યારે અરવિંદ હોસ્ટેલમાં કોમન સ્પેસનો ઉપયોગ કરવાનું શરૂ કરે છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_4_3",
              "hi": "X5_4_3",
              "ka": "X5_4_3",
              "te": "X5_4_3",
              "or": "X5_4_3",
              "as": "X5_4_3",
              "gu": "X5_4_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He uses the shared bathrooms early in the morning before the other students get up and makes sure he is always with someone when he goes to the mess.",
              "hi": "वह अन्य छात्रों के उठने से पहले सुबह सुबह कॉमन बाथरूम का उपयोग करता है और यह सुनिश्चित करता है कि वह हमेशा किसी के साथ रहे जब वह खाना खाने जाता है।",
              "ka": "ಅವರು ಇತರ ವಿದ್ಯಾರ್ಥಿಗಳು ಏಳುವ ಮೊದಲು ಹಂಚಿದ ಸ್ನಾನಗೃಹಗಳನ್ನು ಬಳಸುತ್ತಾರೆ ಮತ್ತು ಅವರು ಮೆಸ್‌ಗೆ ಹೋದಾಗ ಅವರು ಯಾವಾಗಲೂ ಯಾರೊಂದಿಗಾದರೂ ಇರುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "అతను ఇతర విద్యార్థులు లేవడానికి ముందే షేర్డ్ బాత్‌రూమ్‌లను ఉపయోగించేవాడు మరియు అతను మెస్‌కి వెళ్లినప్పుడు ఎప్పుడూ ఎవరితోనైనా ఉండేలా చూసుకుంటాడు.",
              "or": "ସେ ଅନ୍ୟ ଛାତ୍ରମାନେ ଉଠିବା ପୂର୍ବରୁ ସକାଳେ ସେୟାର୍ ହୋଇଥିବା ବାଥରୁମ୍ ବ୍ୟବହାର କରନ୍ତି ଏବଂ ନିଶ୍ଚିତ କରନ୍ତି ଯେ ସେ ମେସ୍‌କୁ ଯିବାବେଳେ ସର୍ବଦା କାହା ସହିତ ଥାଆନ୍ତି |",
              "as": "তেওঁ আন শিক্ষাৰ্থীসকল উঠাৰ আগতেই ৰাতিপুৱা বাথৰুম ব্যৱহাৰ কৰে আৰু নিশ্চিত কৰে যে যেতিয়া তেওঁ মেছলৈ যায় তেতিয়া তেওঁ যাতে সদায় কাৰোবাৰ সৈতে থাকে।",
              "gu": "જ્યારે અન્ય વિદ્યાર્થી ઉઠે તે અગાઉ જ વહેલી સવારે બાથરૂમનો ઉપયોગ કરે છે અને તે સુનિશ્ચિત કરે છે કે જ્યારે તે મેસમાં જાય ત્યારે હંમેશા કોઈની સાથે જાય."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_4_4",
              "hi": "X5_4_4",
              "ka": "X5_4_4",
              "te": "X5_4_4",
              "or": "X5_4_4",
              "as": "X5_4_4",
              "gu": "X5_4_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "One day, his roommate Prakash sees him coming back from the bathroom in the morning. He notices that Arvind looks physically different from the other boys.",
              "hi": "एक दिन, उसका रूममेट प्रकाश उसे सुबह बाथरूम से वापस आ रहा है। उसने ध्यान दिया कि अरविंद दूसरे लड़कों से शारीरिक रूप से अलग दिखता है।",
              "ka": "ಒಂದು ದಿನ, ಅವನ ರೂಮ್‌ಮೇಟ್ ಪ್ರಕಾಶ್ ಅವರು ಬೆಳಿಗ್ಗೆ ಸ್ನಾನಗೃಹದಿಂದ ಹಿಂತಿರುಗುವುದನ್ನು ನೋಡುತ್ತಾರೆ. ಅರವಿಂದ್ ಇತರ ಹುಡುಗರಿಗಿಂತ ದೈಹಿಕವಾಗಿ ಭಿನ್ನವಾಗಿರುವುದನ್ನು ಅವನು ಗಮನಿಸುತ್ತಾನೆ.",
              "te": "ఒకరోజు తన రూమ్మేట్ ప్రకాష్ ఉదయం తను బాత్రూమ్ నుండి తిరిగి రావడం చూస్తాడు. అరవింద్ ఇతర అబ్బాయిల కంటే శారీరకంగా భిన్నంగా కనిపించడం అతను గమనించాడు.",
              "or": "ଦିନେ, ତାଙ୍କ ରୁମମେଟ୍‍ ପ୍ରକାଶ ତାଙ୍କୁ ସକାଳେ ବାଥରୁମରୁ ଫେରୁଥିବାର ଦେଖନ୍ତି | ସେ ଲକ୍ଷ୍ୟ କରନ୍ତି ଯେ ଅରବିନ୍ଦ ଅନ୍ୟ ପୁଅମାନଙ୍କଠାରୁ ଶାରୀରିକ ଭାବରେ ଭିନ୍ନ ଦେଖାଯାଉଛନ୍ତି |",
              "as": "এদিন তেওঁৰ ৰুমমেট প্ৰকাশে তেওঁক ৰাতিপুৱা বাথৰুমৰ পৰা উভতি অহা দেখিলে। তেওঁ লক্ষ্য কৰিছে যে অৰবিন্দ আন ল'ৰাবোৰতকৈ শাৰীৰিকভাৱে পৃথক দেখায়।",
              "gu": "એક દિવસ તેનો રૂમમેટ પ્રકાશ તેને સવારે બાથરૂમમાંથી પરત આવતા જોઈ જાય છે. અને તે જોવે છે કે અરવિંદ અન્ય છોકરાઓ કરતા શારીરિક રીતે અલગ દેખાય છે."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_4_5",
              "hi": "X5_4_5",
              "ka": "X5_4_5",
              "te": "X5_4_5",
              "or": "X5_4_5",
              "as": "X5_4_5",
              "gu": "X5_4_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Prakash gets scared of Arvind. He wants to change his room but is not able to. For many weeks Prakash stops coming to the room and stays elsewhere.",
              "hi": "प्रकाश अरविंद से डरता है। वह अपने कमरे को बदलना चाहता है लेकिन सक्षम नहीं है। अनेक सप्ताह के लिए प्रकाश का कमरे में आना बंद हो जाता है और वह कहीं और रहता है।",
              "ka": "ಪ್ರಕಾಶ್ ಅರವಿಂದನಿಗೆ ಹೆದರುತ್ತಾನೆ. ಅವನು ತನ್ನ ಕೋಣೆಯನ್ನು ಬದಲಾಯಿಸಲು ಬಯಸುತ್ತಾನೆ ಆದರೆ ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ. ಹಲವು ವಾರಗಳಿಂದ ಪ್ರಕಾಶ್ ಕೋಣೆಗೆ ಬರುವುದನ್ನು ನಿಲ್ಲಿಸಿ ಬೇರೆಡೆ ಇರುತ್ತಾರೆ.",
              "te": "ప్రకాష్‌ అరవింద్‌ ని చూసి భయపడ్డాడు. అతను తన గదిని మార్చాలనుకుంటున్నాడు, కానీ కుదరదు. చాలా వారాల పాటు ప్రకాష్ గదికి రావడం మానేసి వేరే చోట ఉంటాడు.",
              "or": "ପ୍ରକାଶ ଅରବିନ୍ଦଙ୍କୁ ଡରନ୍ତି | ସେ ନିଜ ରୁମ୍‌ ବଦଳାଇବାକୁ ଚାହାଁନ୍ତି କିନ୍ତୁ ସକ୍ଷମ ହୁଅନ୍ତି ନାହିଁ | ଅନେକ ସପ୍ତାହ ପାଇଁ ପ୍ରକାଶ ରୁମ୍‌କୁ ଆସିବା ବନ୍ଦ କରିଦି‍ଅନ୍ତି ଏବଂ ଅନ୍ୟତ୍ର ରହନ୍ତି |",
              "as": "প্ৰকাশে অৰবিন্দক ভয় কৰে। তেওঁ নিজৰ ৰুমটো সলনি কৰিব বিচাৰে কিন্তু কৰিব নোৱাৰে। বহু সপ্তাহৰ বাবে প্ৰকাশে ৰুমলৈ অহা বন্ধ কৰে আৰু আন ঠাইত থাকে।",
              "gu": "પ્રકાશ અરવિંદથી ડરી જાય છે. તે પોતાનો રૂમ બદલવા ઈચ્છે છે પણ તેમ કરી શકતો નથી. કેટલાક સપ્તાહ સુધી પ્રકાશ રૂમમાં આવવાનું બંધ કરી દે છે અને અન્ય ક્યાંક રહે છે."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_5_1",
              "hi": "X5_5_1",
              "ka": "X5_5_1",
              "te": "X5_5_1",
              "or": "X5_5_1",
              "as": "X5_5_1",
              "gu": "X5_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is upset and spends all his time in the library or his room studying to keep himself distracted.",
              "hi": "अरविंद परेशान है और पूरे समय लाइब्रेरी या अपने कमरे में खुद का ध्यान इधर- उधर भटकाने के लिए अध्ययन करता है।",
              "ka": "ಅರವಿಂದ್ ಅಸಮಾಧಾನಗೊಂಡಿದ್ದಾರೆ ಮತ್ತು ಲೈಬ್ರರಿಯಲ್ಲಿ ಅಥವಾ ತನ್ನ ಕೊಠಡಿಯಲ್ಲಿ ತನ್ನನ್ನು ವಿಚಲಿತನಾಗಿರಿಸಲು ತನ್ನ ಸಮಯವನ್ನು ಕಳೆಯುತ್ತಾನೆ.",
              "te": "అరవింద్ కలత చెంది, తన దృష్టిని మరల్చుకోవడానికి లైబ్రరీలో లేదా తన గదిలో చదువుకుంటూ గడిపాడు.",
              "or": "ଅରବିନ୍ଦ ଅପ୍‌ସେଟ୍‌ ଅଛନ୍ତି ଏବଂ ନିଜକୁ ବିଭ୍ରାନ୍ତ କରିବା ପାଇଁ ପାଠାଗାରରେ କିମ୍ବା ତାଙ୍କ ରୁମ୍‌ରେ ଅଧ୍ୟୟନ କରି ତାଙ୍କର ସମସ୍ତ ସମୟ ଅତିବାହିତ କରନ୍ତି |",
              "as": "অৰবিন্দ বিচলিত হৈ আছে আৰু নিজকে অন্যমনস্ক কৰি ৰাখিবলৈ পুথিভঁৰাল বা তেওঁৰ ৰুমত সকলো সময় অধ্যয়ন কৰি কটায়।",
              "gu": "અરવિંદ ખૂબ જ પરેશાન થઈ જાય છે અને પોતાનો સંપૂર્ણ સમય લાઈબ્રેરીમાં અથવા પોતાના રૂમમાં અભ્યાસ કરવા માટે પસાર કરે છે, આ સંજોગોમાં તે ખૂબ વિચલિત હોય છે."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_5_2",
              "hi": "X5_5_2",
              "ka": "X5_5_2",
              "te": "X5_5_2",
              "or": "X5_5_2",
              "as": "X5_5_2",
              "gu": "X5_5_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He has no friends in the hostel, just one or two people with whom he shares the table in the dining hall.",
              "hi": "उसका छात्रावास में कोई मित्र नहीं है, बस एक या दो लोग जिनके साथ वह डाइनिंग हॉल में टेबल साझा करता है।",
              "ka": "ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿ ಅವನಿಗೆ ಸ್ನೇಹಿತರಿಲ್ಲ, ಡೈನಿಂಗ್ ಹಾಲ್‌ನಲ್ಲಿ ಅವನು ಟೇಬಲ್ ಹಂಚಿಕೊಳ್ಳುವ ಒಬ್ಬ ಅಥವಾ ಇಬ್ಬರು ಜನರೊಂದಿಗೆ ಮಾತ್ರ.",
              "te": "హాస్టల్‌లో అతనికి స్నేహితులు లేరు, డైనింగ్ హాల్‌లో టేబుల్‌ను పంచుకునే ఒకరిద్దరు మాత్రమే.",
              "or": "ହଷ୍ଟେଲରେ ତାଙ୍କର କୌଣସି ବନ୍ଧୁ ନାହାଁନ୍ତି, କେବଳ ଜଣେ କିମ୍ବା ଦୁଇଜଣ ବ୍ୟକ୍ତି ଯାହାଙ୍କ ସହ ସେ ଡାଇନିଂ ହଲରେ ଟେବୁଲ୍ ସେୟାର କରନ୍ତି |",
              "as": "হোষ্টেলত তেওঁৰ কোনো বন্ধু নাই, মাত্ৰ এজন বা দুজন মানুহ আছে যাৰ সৈতে তেওঁ ডাইনিং হলত মেজত বহে।",
              "gu": "હોસ્ટેલમાં તેના કોઈ મિત્ર નથી, ફક્ત એક અથવા બે લોકો જ છે જેની સાથે તે ડાઈનિંગ હોલમાં ટેબલ શેર કરે છે."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_5_3",
              "hi": "X5_5_3",
              "ka": "X5_5_3",
              "te": "X5_5_3",
              "or": "X5_5_3",
              "as": "X5_5_3",
              "gu": "X5_5_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The professors in the department are also not very approachable, and some of them ask Arvind to act more like a man and be ‘normal’. He attends the classes only to maintain his attendance.",
              "hi": "विभाग के प्रोफेसर तक पहुंचना भी संभव नहीं हैं, और उनमें से कुछ अरविंद से एक आदमी की तरह कार्य करने और 'सामान्य' होने के लिए कहते हैं। वह केवल अपनी उपस्थिति को बनाए रखने के लिए कक्षाओं में भाग लेता है।",
              "ka": "ವಿಭಾಗದ ಪ್ರೊಫೆಸರ್‌ಗಳು ಸಹ ಹೆಚ್ಚು ಹತ್ತಿರವಾಗುವುದಿಲ್ಲ, ಮತ್ತು ಅವರಲ್ಲಿ ಕೆಲವರು ಅರವಿಂದ್ ಅವರನ್ನು ಹೆಚ್ಚು ಮನುಷ್ಯನಂತೆ ವರ್ತಿಸುವಂತೆ ಮತ್ತು ‘ಸಾಮಾನ್ಯ’ರಾಗಿರಲು ಕೇಳುತ್ತಾರೆ. ಅವನು ತನ್ನ ಹಾಜರಾತಿಯನ್ನು ಕಾಪಾಡಿಕೊಳ್ಳಲು ಮಾತ್ರ ತರಗತಿಗಳಿಗೆ ಹಾಜರಾಗುತ್ತಾನೆ.",
              "te": "డిపార్ట్‌మెంట్‌లోని ప్రొఫెసర్‌లు కూడా పెద్దగా చేరువ కాలేదు, మరికొందరు అరవింద్‌ని మగాడిలా ప్రవర్తించమని, ‘మామూలుగా’ ఉండమని చెప్తారు. అతను తన హాజరును కొనసాగించడానికి మాత్రమే తరగతులకు హాజరయ్యేవాడు.",
              "or": "ଡିପାର୍ଟମେଣ୍ଟ୍‍ର ପ୍ରଫେସରମାନେ ମଧ୍ୟ ମେଳାପି ନୁହଁନ୍ତି ଏବଂ ସେମାନଙ୍କ ମଧ୍ୟରୁ କେତେକ ଅରବିନ୍ଦଙ୍କୁ ଜଣେ ପୁରୁଷ ଭଳି କାମ କରିବାକୁ ଏବଂ ‘ନର୍ମାଲ୍‌’ ହେବାକୁ କୁହନ୍ତି | ସେ କେବଳ ତାଙ୍କର ଉପସ୍ଥାନ ବଜାୟ ରଖିବା ପାଇଁ କ୍ଲାସରେ ଯୋଗ ଦିଅନ୍ତି |",
              "as": "বিভাগটোৰ অধ্যাপকসকলো সহজে কাষ চাপিব পৰা বিধৰ নহয়, আৰু তেওঁলোকৰ কিছুমানে অৰবিন্দক এজন পুৰুষৰ দৰে থাকিবলৈ আৰু 'স্বাভাৱিক' হ'বলৈ কয়। তেওঁ কেৱল তেওঁৰ উপস্থিতি বজাই ৰাখিবলৈ শ্ৰেণীসমূহত উপস্থিত থাকে।",
              "gu": "વિભાગના પ્રોફેસર પણ એટલા મિલનસાર નથી, અને આ પૈકી કેટલાક તો અરવિંદને એક પુરુષની જેમ કામ કરવા અને 'સામાન્ય' થવા માટે કહે છે.તે ફક્ત પોતાની હાજરી પૂરાવવા માટે જ વર્ગખંડમાં આવે છે."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_6_1",
              "hi": "X5_6_1",
              "ka": "X5_6_1",
              "te": "X5_6_1",
              "or": "X5_6_1",
              "as": "X5_6_1",
              "gu": "X5_6_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "One day, Arvind leaves the library a little late. As he walks back to his hostel, a couple of boys start following him.",
              "hi": "एक दिन, अरविंद लाइब्रेरी से थोड़ी देर में निकलता है। जब वह अपने छात्रावास में वापस जाता है, तो कुछ लड़के उसका पीछा करने लगते हैं।",
              "ka": "ಒಂದು ದಿನ, ಅರವಿಂದ್ ಸ್ವಲ್ಪ ತಡವಾಗಿ ಲೈಬ್ರರಿಯಿಂದ ಹೊರಡುತ್ತಾನೆ. ಅವನು ತನ್ನ ಹಾಸ್ಟೆಲ್‌ಗೆ ಹಿಂತಿರುಗಿದಾಗ, ಒಂದೆರಡು ಹುಡುಗರು ಅವನನ್ನು ಹಿಂಬಾಲಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ.",
              "te": "ఒకరోజు అరవింద్ లైబ్రరీ నుండి కొంచెం ఆలస్యంగా బయలుదేరాడు. అతను తన హాస్టల్‌కి తిరిగి వెళుతుండగా, ఇద్దరు అబ్బాయిలు అతనిని అనుసరించడం ప్రారంభించారు.",
              "or": "ଦିନେ, ଅରବିନ୍ଦ ଲାଇବ୍ରେରୀକୁ ଟିକିଏ ବିଳମ୍ବରେ ଛାଡନ୍ତି | ସେ ନିଜ ହଷ୍ଟେଲକୁ ଫେରୁଥିବା ବେଳେ, କିଛି ପୁଅ ତାଙ୍କୁ ଅନୁସରଣ କରିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "এদিন, অৰবিন্দ লাইব্ৰেৰীৰ পৰা অলপ পলমকৈ ওলাই আহে। তেওঁ নিজৰ হোষ্টেললৈ উভতি যাওতে, কেইজনমান ল'ৰাই তেওঁক অনুসৰণ কৰিবলৈ আৰম্ভ কৰিলে।",
              "gu": "એક દિવસ અરવિંદ લાઈબ્રેરીથી થોડો મોડો નિકળે છે. જેવા તે પોતાના હોસ્ટેલમાં પરત ફરે છે. કેટલાક છોકરા તેનો પીછો કરવા લાગે છે."
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_6_2",
              "hi": "X5_6_2",
              "ka": "X5_6_2",
              "te": "X5_6_2",
              "or": "X5_6_2",
              "as": "X5_6_2",
              "gu": "X5_6_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They keep calling out to him as he walks, saying mean and disrespectful things. Arvind is scared they might hurt him.",
              "hi": "जब वह चलता है वे उसे बुलाते रहते हैं, और अपमानजनक बातें कहते हैं। अरविंद डरता है कि वे उसे चोट पहुंचा सकते हैं।",
              "ka": "ಅವರು ನಡೆಯುವಾಗ ಅವರು ಅವನನ್ನು ಕರೆಯುತ್ತಾರೆ, ಕೀಳು ಮತ್ತು ಅಗೌರವದ ಮಾತುಗಳನ್ನು ಹೇಳುತ್ತಾರೆ. ಅವರು ತನಗೆ ನೋವುಂಟು ಮಾಡಬಹುದೆಂದು ಅರವಿಂದ್ ಹೆದರುತ್ತಾನೆ.",
              "te": "అతను నడుస్తున్నప్పుడు వారు అతనిని పిలుస్తూ ఉంటారు, వారు నీచంగా మరియు అగౌరవంగా మాట్లాడుతారు. వారు తనని బాధ పెడతారు ఏమో అని అరవింద్ భయపడుతున్నాడు.",
              "or": "ଯେବେ ସେ ଚାଲନ୍ତି ସେମାନେ ତାଙ୍କୁ, ନୀଚ ଏବଂ ଅସମ୍ମାନିତ କଥା କହି ଡାକନ୍ତି | ସେମାନେ ତାଙ୍କୁ ଆଘାତ ଦେଇ ପାରନ୍ତି ବୋଲି ଅରବିନ୍ଦ ଡରନ୍ତି |",
              "as": "তেওঁলোকে খোজ কাঢ়ি থাকোঁতে তেওঁক মাতি থাকে, বেলেগ অৰ্থ থকা আৰু অসন্মানজনক কথা কয়। অৰবিন্দে ভয় খাইছে যে তেওঁলোকে তেওঁক মাৰিব পাৰে।",
              "gu": "તે જતો હોય છે ત્યારે અપમાનજક રીતે તેને બોલાવે છે. અરવિંદને ડર લાગે છે કે ક્યાંક તેને કોઈ ઈજા ન પહોંચે."
            }
          }
        ],
        "page21": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_6_3////",
              "hi": "X5_6_3 //////",
              "ka": "X5_6_3////",
              "te": "X5_6_3////",
              "or": "X5_6_3////",
              "as": "X5_6_3////",
              "gu": "X5_6_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He walks back hurriedly to his hostel, thinking they would not enter. But to his surprise, they continue following him into the hostel.",
              "hi": "वह जल्दी से जल्दी अपने छात्रावास में जाता है, और सोचता है कि वे प्रवेश नहीं करेंगे। लेकिन आश्चर्यचकित, वे छात्रावास में उसका पीछा करना जारी रखते हैं।",
              "ka": "ಅವರು ಪ್ರವೇಶಿಸುವುದಿಲ್ಲ ಎಂದು ಭಾವಿಸಿ ಅವರು ತಮ್ಮ ಹಾಸ್ಟೆಲ್‌ಗೆ ಅವಸರದಿಂದ ಹಿಂತಿರುಗುತ್ತಾರೆ. ಆದರೆ ಅವನ ಆಶ್ಚರ್ಯಕ್ಕೆ, ಅವರು ಹಾಸ್ಟೆಲ್‌ಗೆ ಅವನನ್ನು ಹಿಂಬಾಲಿಸುತ್ತಾರೆ.",
              "te": "వాళ్ళు లోపలికి రారు అనుకుంటూ హడావిడిగా తిరిగి తన హాస్టల్‌కి వెళ్ళిపోయాడు. కానీ ఆశ్చర్యంగా, వారు అతనిని హాస్టల్‌లో కూడా అనుసరిస్తూనే ఉన్నారు.",
              "or": "ସେମାନେ ପ୍ରବେଶ କରିବେ ନାହିଁ ବୋଲି ଭାବି, ସେ ହଷ୍ଟେଲକୁ ଫେରିବା ପାଇଁ ଶୀଘ୍ର ଚାଲନ୍ତି | କିନ୍ତୁ ତାଙ୍କୁ ଆଶ୍ଚର୍ଯ୍ୟ ଲାଗେ, ସେମାନେ ତାଙ୍କୁ ହଷ୍ଟେଲରେ ଅନୁସରଣ କରିବା ଜାରି ରଖିଛନ୍ତି |",
              "as": "সিহঁতে নুসোমাব বুলি ভাবি তেওঁ লৰালৰিকৈ হোষ্টেললৈ উভতি আহিল। কিন্তু আচৰিত যে তেওঁলোকে তেওঁক হোষ্টেলৈকে অনুসৰণ কৰি আছে।",
              "gu": "તે એવું વિચારીને ખૂબ જલ્દીથી તેની હોસ્ટેલમાં પરત જતો રહે છે કે ક્યાંક તેને પ્રવેશ નહીં કરવા દે. જોકે તેના આશ્ચર્ય વચ્ચે તેઓ હોસ્ટેલ સુધી તેનો પીછો કરતા રહે છે."
            }
          }
        ],
        "page25": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_6_4////",
              "hi": "X5_6_4 /////",
              "ka": "X5_6_4////",
              "te": "X5_6_4////",
              "or": "X5_6_4////",
              "as": "X5_6_4////",
              "gu": "X5_6_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He runs as fast as he can to his room. The other boys are pretty fast and almost catch hold of him. He drops his books and doesn’t stop to pick them up.",
              "hi": "वह अपने कमरे की ओर जितनी तेजी से चल सकता है, चलता है। अन्य लड़के बहुत तेज़ हैं और उसे लगभग पकड़ लेते हैं। उसकी किताबें गिरती हैं लेकिन वह उन्हें लेने के लिए रुकता नहीं है।",
              "ka": "ಅವನು ತನ್ನ ಕೋಣೆಗೆ ಸಾಧ್ಯವಾದಷ್ಟು ವೇಗವಾಗಿ ಓಡುತ್ತಾನೆ. ಇತರ ಹುಡುಗರು ಬಹಳ ವೇಗವಾಗಿ ಮತ್ತು ಬಹುತೇಕ ಅವನನ್ನು ಹಿಡಿಯುತ್ತಾರೆ. ಅವನು ತನ್ನ ಪುಸ್ತಕಗಳನ್ನು ಬೀಳಿಸುತ್ತಾನೆ ಮತ್ತು ಅವುಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ನಿಲ್ಲುವುದಿಲ್ಲ.",
              "te": "అతను తన గదికి వీలైనంత వేగంగా పరిగెత్తాడు. ఇతర అబ్బాయిలు చాలా వేగంగా వెళ్ళి దాదాపు అతనిని పట్టుకుంటారు. అతను తన పుస్తకాలను పడవేసి వాటిని తీయడానికి ఆగుతాడు.",
              "or": "ସେ ନିଜ ରୁମ୍‌କୁ ଯଥାସମ୍ଭବ ଶୀଘ୍ର ଦୌଡ଼ନ୍ତି | ଅନ୍ୟ ପୁଅମାନେ ଖୁବ୍ ଦ୍ରୁତ ଏବଂ ତାଙ୍କୁ ପ୍ରାୟ ଧରି ପକାନ୍ତି | ସେ ତାଙ୍କର ବହିଗୁଡ଼ିକୁ ଛାଡି଼ଦିଅନ୍ତି ଏବଂ ସେଗୁଡ଼ିକୁ ଉଠାଇବା ପାଇଁ ଅଟକନ୍ତି ନାହିଁ |",
              "as": "তেওঁ যিমান পাৰে সিমান জোৰত নিজৰ কোঠালৈ দৌৰে। আন ল'ৰাবোৰো যথেষ্ট খৰগতিত দৌৰে আৰু তেওঁক প্ৰায় ধৰো ধৰো হয়। তেওঁ তেওঁৰ কিতাপবোৰ পেলাই দিয়ে আৰু সেইবোৰ তুলিবলৈ ৰৈ নাযায়।",
              "gu": "તે જેટલો ઝડપી દોડી શકે એટલી ઝડપથી દોડે છે. અન્ય છોકરા પણ તેને ખૂબ ઝડપથી દોડીને તેને પકડી લેવાનો પ્રય્તન કરે છે. તેના પુસ્તકો પડી જાય છે અને તેમ છતાં તે પુસ્તકો લેવા અટકતો નથી."
            }
          }
        ],
        "page22": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_7_1///",
              "hi": "X5_7_1 ///",
              "ka": "X5_7_1///",
              "te": "X5_7_1///",
              "or": "X5_7_1///",
              "as": "X5_7_1///",
              "gu": "X5_7_1///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Arvind is somehow able to reach his hostel room just in time to lock it before the boys get him.",
              "hi": "अरविन्द किसी तरह अपने छात्रावास के कमरे तक पहुंच कर लड़कों द्वारा उसको पकड पाने से पहले इसे लॉक करने में सक्षम है, ।",
              "ka": "ಹುಡುಗರು ಅವನನ್ನು ಪಡೆಯುವ ಮೊದಲು ಬೀಗ ಹಾಕುವ ಸಮಯಕ್ಕೆ ಸರಿಯಾಗಿ ಅರವಿಂದ್ ತನ್ನ ಹಾಸ್ಟೆಲ್ ಕೋಣೆಯನ್ನು ತಲುಪಲು ಸಾಧ್ಯವಾಯಿತು.",
              "te": "అరవింద్ తన హాస్టల్ గదిని అబ్బాయిలు రాకముందే చేరుకోని తాళం వేయగలిగాడు.",
              "or": "ପୁଅମାନେ ତାଙ୍କୁ ପାଇବା ପୂର୍ବରୁ ଅରବିନ୍ଦ କୌଣସି ପ୍ରକାରେ ଏହାକୁ ଲକ୍ କରିବାକୁ ଠିକ୍ ସମୟରେ ତାଙ୍କ ହଷ୍ଟେଲ ରୁମରେ ପହଞ୍ଚିବାରେ ସକ୍ଷମ ହୁଅନ୍ତି |",
              "as": "ল'ৰাবোৰে তেওঁক পোৱাৰ আগতে অৰবিন্দে কেনেবাকৈ তেওঁৰ হোষ্টেলৰ ৰুমত ঠিক সময়ত উপস্থিত হৈ লক কৰিবলৈ সক্ষম হয় ।",
              "gu": "આ છોકરાઓ તેના સુધી પહોંચે તે અગાઉ અરવિંદ તેની હોસ્ટેલના રૂમમાં પહોંચી લોક લગાવી દે છે."
            }
          }
        ],
        "page23": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_7_2",
              "hi": "X5_7_2",
              "ka": "X5_7_2",
              "te": "X5_7_2",
              "or": "X5_7_2",
              "as": "X5_7_2",
              "gu": "X5_7_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They slam the door, asking him to open it. They say horrible things about him and threaten to beat him. Arvind curls up on his bed, afraid to move.",
              "hi": "वे दरवाजे पर जोर जोर से प्रहार करते हैं, और उसे खोलने के लिए कहते हैं। वे उसके बारे में भयानक बातें कहते हैं और उसे मारने की धमकी देते हैं। अरविंद अपने बिस्तर पर करवटें बदलता है और आगे बढ़ने से डरता है।",
              "ka": "ಅವರು ಬಾಗಿಲನ್ನು ಸ್ಲ್ಯಾಮ್ ಮಾಡಿದರು, ಅದನ್ನು ತೆರೆಯಲು ಕೇಳಿದರು. ಅವರು ಅವನ ಬಗ್ಗೆ ಭಯಾನಕ ವಿಷಯಗಳನ್ನು ಹೇಳುತ್ತಾರೆ ಮತ್ತು ಅವನನ್ನು ಹೊಡೆಯುವುದಾಗಿ ಬೆದರಿಕೆ ಹಾಕುತ್ತಾರೆ. ಅರವಿಂದ್ ತನ್ನ ಹಾಸಿಗೆಯ ಮೇಲೆ ಸುರುಳಿಯಾಗುತ್ತಾನೆ, ಚಲಿಸಲು ಹೆದರುತ್ತಾನೆ.",
              "te": "వారు తలుపును గట్టిగా కొడుతూ, దానిని తెరవమని అడుగుతారు. వారు అతని గురించి భయంకరమైన విషయాలు చెప్పారు మరియు అతనిని కొడతామని బెదిరించారు. అరవింద్ కదలడానికి భయపడుతూ తన మంచం మీద ముడుచుకుని ఉంటాడు.",
              "or": "ସେମାନେ କବାଟ ବାଡାଇ, ତାଙ୍କୁ ଏହା ଖୋଲିବାକୁ କୁହନ୍ତି। ସେମାନେ ତାଙ୍କ ବିଷୟରେ ଭୟଙ୍କର କଥା କୁହନ୍ତି ଏବଂ ତାଙ୍କୁ ପିଟିବାକୁ ଧମକ ଦିଅନ୍ତି | ଅରବିନ୍ଦ ନିଜ ବିଛଣାରେ ମୋଡି ହୋଇ ରୁହନ୍ତି, ଘୁଞ୍ଚିବାକୁ ଭୟ କରନ୍ତି |",
              "as": "তেওঁলোকে দুৱাৰখন খুলিবলৈ কৈছিল। তেওঁলোকে তেওঁৰ বিষয়ে ভয়ংকৰ কথা কয় আৰু তেওঁক মাৰিবলৈ ভাবুকি দিয়ে। অৰবিন্দে বিচনাত পাক খাই কেকোঁৰাৰ দৰে হ'ল আৰু লৰচৰ কৰিবলৈ ভয় কৰিলে।",
              "gu": "તેમણે દરવાજા પટકી દે છે, તેને ખોલવા માટે કહ્યું. છોકરાઓ તેના વિશે ભયાનક વાતો કરે છે અને માર મારવાની ધમકી આપે છે. અરવિંદ તેની પથારીમાં લપેટાઈ જાય છે,તે ખૂબ જ ડર ધરાવતો હતો."
            }
          }
        ],
        "page24": [
          {
            "valuePath": "display",
            "value": {
              "en": "X5_7_3",
              "hi": "X5_7_3",
              "ka": "X5_7_3",
              "te": "X5_7_3",
              "or": "X5_7_3",
              "as": "X5_7_3",
              "gu": "X5_7_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The boys leave in a few minutes. But after that day, he is too scared to do anything at all. He does not attend most of the classes, and he sleeps the whole day and barely goes out of the room.",
              "hi": "लड़के कुछ ही मिनटों में छोड़ देते हैं। लेकिन उस दिन के बाद, वह भी कुछ भी करने के लिए डर गया है। वह अधिकांश कक्षाओं में नहीं जाता है, और पूरे दिन सोता है और मुश्किल से कमरे से बाहर जाता है।",
              "ka": "ಹುಡುಗರು ಕೆಲವೇ ನಿಮಿಷಗಳಲ್ಲಿ ಹೊರಡುತ್ತಾರೆ. ಆದರೆ ಆ ದಿನದ ನಂತರ, ಅವನು ಏನನ್ನೂ ಮಾಡಲು ತುಂಬಾ ಹೆದರುತ್ತಾನೆ. ಅವನು ಹೆಚ್ಚಿನ ತರಗತಿಗಳಿಗೆ ಹಾಜರಾಗುವುದಿಲ್ಲ, ಮತ್ತು ಅವನು ಇಡೀ ದಿನ ಮಲಗುತ್ತಾನೆ ಮತ್ತು ಕೋಣೆಯಿಂದ ಹೊರಗೆ ಹೋಗುತ್ತಾನೆ.",
              "te": "అబ్బాయిలు కొన్ని నిమిషాల్లో వెళ్లిపోతారు. కానీ ఆ రోజు తర్వాత, అతను ఏమీ చేయలేక చాలా భయపడతాడు. అతను చాలా తరగతులకు హాజరు కాలేడు మరియు అతను రోజంతా నిద్రపోతాడు మరియు ఎక్కువగా గది నుండి బయటకు రాడు.",
              "or": "ପୁଅମାନେ କିଛି ମିନିଟରେ ଚାଲିଯାଆନ୍ତି | କିନ୍ତୁ ସେହି ଦିନ ପରେ, ସେ ଆଦୌଂ କିଛି କରିବାକୁ ବହୁତ ଡରୁଛନ୍ତି | ସେ ଅଧିକାଂଶ କ୍ଲାସରେ ଆଟେଣ୍ଡ କରନ୍ତି ନାହିଁ, ଏବଂ ସେ ଦିନସାରା ଶୋଇଥାନ୍ତି ଏବଂ ରୁମରୁ କ୍ୱଚିତ୍ ବାହାରକୁ ଯାଆନ୍ତି |",
              "as": "ল'ৰাবোৰ কেইমিনিটমানৰ ভিতৰতে গুচি যায়। কিন্তু সেই দিনটোৰ পিছত, তেওঁ কোনো কাম কৰিবলৈ একেবাৰে ভয় কৰা হ'ল। তেওঁ বেছিভাগ শ্ৰেণীত উপস্থিত নাথাকে, তেওঁ গোটেই দিনটো শুই থাকে আৰু কোনোমতেহে কোঠাৰ বাহিৰলৈ যায়।",
              "gu": "કેટકાલ મિનિટોમાં છોકરા જતા રહે છે. જોકે તે દિવસ બાદ કંઈપણ કરવાથી ડરે છે. તે મોટાભાગે વર્ગખંડમાં જતો નથી, અને તે સંપૂર્ણ દિવસ ઉંઘે છે અને મુશ્કેલીથી રૂમની બહાર નિકળે છે."
            }
          }
        ]
      },
      "scene8": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6_1_1",
              "hi": "X6_1_1",
              "ka": "X6_1_1",
              "te": "X6_1_1",
              "or": "X6_1_1",
              "as": "X6_1_1",
              "gu": "X6_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Roshini knows that Arvind is struggling in college and cannot focus on his studies.",
              "hi": "रोशनी जानती है कि अरविंद कॉलेज में संघर्ष कर रहा है और अपने अध्ययन पर ध्यान केंद्रित नहीं कर सकता है।",
              "ka": "ಅರವಿಂದ್ ಕಾಲೇಜಿನಲ್ಲಿ ಕಷ್ಟಪಡುತ್ತಿದ್ದಾನೆ ಮತ್ತು ಅವನ ಅಧ್ಯಯನದತ್ತ ಗಮನಹರಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ ಎಂದು ರೋಶಿನಿಗೆ ತಿಳಿದಿದೆ.",
              "te": "అరవింద్ కాలేజీలో కష్టపడుతున్నాడని, చదువుపై దృష్టి పెట్టలేకపోతున్నాడని రోష్నికి తెలుసు.",
              "or": "ରୋଶିନି ଜାଣନ୍ତି ଯେ ଅରବିନ୍ଦ କଲେଜରେ ସଂଘର୍ଷ କରୁଛନ୍ତି ଏବଂ ତାଙ୍କ ଅଧ୍ୟୟନ ଉପରେ ଧ୍ୟାନ ଦେଇ ପାରୁନାହାଁନ୍ତି |",
              "as": "ৰোশনিয়ে জানে যে অৰবিন্দে কলেজত সংগ্ৰাম কৰি আছে আৰু পঢ়া-শুনাত মনোনিবেশ কৰিব পৰা নাই।",
              "gu": "રોશની જાણે છે કે અરવિંદ કોલેજમાં સંઘર્ષ કરી રહ્યા છે અને પોતાના અભ્યાસ પર ધ્યાન આપી શકતા નથી"
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6_1_2",
              "hi": "X6_1_2",
              "ka": "X6_1_2",
              "te": "X6_1_2",
              "or": "X6_1_2",
              "as": "X6_1_2",
              "gu": "X6_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She encourages him to talk to the hostel warden or the Head of the department about the constant harassment he faces.  Arvind does not want to do this.",
              "hi": "वह उन्हें छात्रावास वार्डन या विभाग के प्रमुख से लगातार उत्पीड़न के बारे में बात करने के लिए प्रोत्साहित करती है। अरविंद ऐसा नहीं करना चाहता है।",
              "ka": "ಅವನು ಎದುರಿಸುತ್ತಿರುವ ನಿರಂತರ ಕಿರುಕುಳದ ಬಗ್ಗೆ ಹಾಸ್ಟೆಲ್ ವಾರ್ಡನ್ ಅಥವಾ ವಿಭಾಗದ ಮುಖ್ಯಸ್ಥರೊಂದಿಗೆ ಮಾತನಾಡಲು ಅವಳು ಅವನನ್ನು ಪ್ರೋತ್ಸಾಹಿಸುತ್ತಾಳೆ. ಅರವಿಂದ್ ಇದನ್ನು ಮಾಡಲು ಬಯಸುವುದಿಲ್ಲ.",
              "te": "అతను ఎదుర్కొంటున్న వేధింపుల గురించి హాస్టల్ వార్డెన్ లేదా డిపార్ట్‌మెంట్ హెడ్‌తో మాట్లాడమని ఆమె అతనికి చెప్తుంది. అరవింద్ కి ఇలా చేయడం ఇష్టం లేదు.",
              "or": "ସେ ତାଙ୍କୁ ହଷ୍ଟେଲ ୱାର୍ଡେନ୍‌ କିମ୍ବା ଡିପାର୍ଟମେଣ୍ଟ୍‌ର ମୁଖ୍ୟଙ୍କ ସହ ସେ ସାମନା କରୁଥିବା ନିରନ୍ତର ଉତ୍ପୀଡ଼ନ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରିବାକୁ ଉତ୍ସାହିତ କରନ୍ତି | ଅରବିନ୍ଦ ଏହା କରିବାକୁ ଚାହାଁନ୍ତି ନାହିଁ |",
              "as": "তাই তাক হোষ্টেলৱাৰ্ডেন বা বিভাগৰ মুৰব্বীৰ সৈতে তেওঁ সন্মুখীন হোৱা নিৰন্তৰ নিৰ্যাতনৰ বিষয়ে কথা পাতিবলৈ উৎসাহিত কৰে। অৰবিন্দে এইটো কৰিব নিবিচাৰে।",
              "gu": "તેણીની સતત ઉત્પીડનનો સામનો કરવા અંગે હોસ્ટેલના વોર્ડન અથવા વિભાગના વડા સાથે વાત કરવા માટે પ્રોત્સાહન આપે છે. અરવિંદ આમ કરવા ઈચ્છતા નથી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6_1_3bg",
              "hi": "X6_1_3bg",
              "ka": "X6_1_3bg",
              "te": "X6_1_3bg",
              "or": "X6_1_3bg",
              "as": "X6_1_3bg",
              "gu": "X6_1_3bg"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Arvind, what is wrong?  Before coming to college, you used to share everything with me, and now you just avoid any conversation.",
              "hi": "अरविंद, क्या बात है? कॉलेज आने से पहले, तुम मेरे साथ सबकुछ साझा करते थे, और अब तुम किसी भी वार्तालाप से बचते हो।",
              "ka": "ಅರವಿಂದ್, ಏನು ತಪ್ಪಾಗಿದೆ? ಕಾಲೇಜಿಗೆ ಬರುವ ಮೊದಲು, ನೀವು ನನ್ನೊಂದಿಗೆ ಎಲ್ಲವನ್ನೂ ಹಂಚಿಕೊಳ್ಳುತ್ತಿದ್ದಿರಿ ಮತ್ತು ಈಗ ನೀವು ಯಾವುದೇ ಸಂಭಾಷಣೆಯನ್ನು ತಪ್ಪಿಸುತ್ತೀರಿ.",
              "te": "అరవింద్, తప్పు ఏమిటి? కాలేజీకి రాకముందు, నువ్వు ప్రతి విషయాన్ని నాతో పంచుకునేవాడివి, కానీ ఇప్పుడు నువ్వు అసలు సరిగ్గా మాట్లాడట్లేదు.",
              "or": "ଅରବିନ୍ଦ, ଭୁଲ୍ କଣ ? କଲେଜକୁ ଆସିବା ପୂର୍ବରୁ, ତୁମେ ମୋ ସହିତ ସବୁକିଛି ଅଂଶୀଦାର କରୁଥିଲ, ଏବଂ ବର୍ତ୍ତମାନ ତୁମେ କୌଣସି ବାର୍ତ୍ତାଳାପରୁ ଏଡାଇ ଦେଉଛ |",
              "as": "অৰবিন্দ, কি হৈছে? কলেজলৈ অহাৰ আগতে, আপুনি সকলোবোৰ মোৰ সৈতে আলোচনা কৰিছিল, আৰু এতিয়া আপুনি যিকোনো কথা-বতৰা এৰাই চলিছে।",
              "gu": "અરવિંદ, શું ખોટું છે? કોલેજ આવતા પહેલા તમે મને બધુ જ શેર કરતા હતા અને હવે તુ કોઈપણ પ્રકારની વાતચીતથી બચે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "What do you want me to say, Roshini!  You had warned me that engineering college would be tough, but I thought I could handle it.",
              "hi": "तुम मुझसे क्या कहना चाहती हो, रोशनी! तुमने मुझे चेतावनी दी थी कि इंजीनियरिंग कॉलेज कठिन होगा, लेकिन मैंने सोचा कि मैं इसे संभाल सकता हूं।",
              "ka": "ನಾನೇನು ಹೇಳ್ತೀನಿ ರೋಷಿಣಿ! ಇಂಜಿನಿಯರಿಂಗ್ ಕಾಲೇಜ್ ಟಫ್ ಆಗುತ್ತೆ ಅಂತ ವಾರ್ನಿಂಗ್ ಕೊಟ್ಟಿದ್ದೀನಿ, ಆದ್ರೆ ನಾನು ನಿಭಾಯಿಸಬಹುದೆಂದುಕೊಂಡೆ.",
              "te": "నేనేం చెప్పాలి రోష్ని! ఇంజినీరింగ్ కాలేజీ కఠినంగా ఉంటుందని నువ్వు నన్ను హెచ్చరించావు, కానీ నేను దానిని హాండిల్ చేయగలను అనుకున్నాను.",
              "or": "ତୁମେ ମୋ ଠାରୁ କଣ କୁହାଇବାକୁ ଚାହୁଁଛ, ରୋଶିନି ! ତୁମେ ମୋତେ ଚେତାବନୀ ଦେଇଥିଲ ଯେ ଇଞ୍ଜିନିୟରିଂ କଲେଜ କଠିନ ହେବ, କିନ୍ତୁ ମୁଁ ଭାବିଥିଲି ମୁଁ ଏହାକୁ ସମ୍ଭାଳି ପାରିବି |",
              "as": "তুমি মোক কি ক'ব বিচাৰিছা, ৰোশনি! তুমি মোক সতৰ্ক কৰি দিছিলা যে অভিযান্ত্ৰিক কলেজ কঠিন হ'ব, কিন্তু মই ভাবিছিলো যে মই ইয়াক চম্ভালিব পাৰিম।",
              "gu": "તમને મને શું કહેવા ઈચ્છો છો, રોશની! તમે મને ચેતવણી આપી હતી કે એન્જીનિયરિંગ કોલેજ મુશ્કેલ હશે, જોકે મને લાગે છે હું તેને સંભાળી શકું છું."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6_1_4////",
              "hi": "X6_1_4 //////",
              "ka": "X6_1_4////",
              "te": "X6_1_4////",
              "or": "X6_1_4////",
              "as": "X6_1_4////",
              "gu": "X6_1_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "You are handling it well, Arvind! Your marks are brilliant even with all this pressure that you are facing!",
              "hi": "तुम इसे अच्छी तरह से संभाल रहे हो, अरविंद! यह दबाव जिसका तुम सामना कर रहे हो इस के साथ भी तुम्हारे अंक इतने शानदार हैं!",
              "ka": "ನೀವು ಅದನ್ನು ಚೆನ್ನಾಗಿ ನಿಭಾಯಿಸುತ್ತಿದ್ದೀರಿ, ಅರವಿಂದ್! ನೀವು ಎದುರಿಸುತ್ತಿರುವ ಈ ಎಲ್ಲಾ ಒತ್ತಡದಲ್ಲೂ ನಿನ್ನ ಅಂಕಗಳು ಅದ್ಭುತವಾಗಿವೆ!",
              "te": "నువ్వు బాగానే హాండిల్ చేస్తున్నావు అరవింద్! నువ్వు ఎదుర్కొంటున్న ఈ ఒత్తిడిలో కూడా నీకు మార్కులు అద్భుతంగా వచ్చాయి!",
              "or": "ଆପଣ ଏହାକୁ ଭଲ ଭାବରେ ସମ୍ଭାଳି ପାରୁଛ, ଅରବିନ୍ଦ ! ତୁମର ସମ୍ମୁଖୀନ ହେଉଥିବା ଏହି ସମସ୍ତ ଚାପ ସତ୍ୱେ ତୁମର ମାର୍କଗୁଡିକ ଉଜ୍ଜ୍ୱଳ !",
              "as": "আপুনি ইয়াক ভালদৰে চম্ভালি আছে, অৰবিন্দ! আপুনি সন্মুখীন হৈ থকা এই সকলো চাপৰ সৈতেও আপোনাৰ নম্বৰবোৰ বহুত ভাল!",
              "gu": "તમે આ સારી રીતે સંભાળી રહ્યા છો, અરવિંદ!તમે જે દબાણનો સામનો કરી રહ્યા છો, તેમ છતાં તમારા ગુણ ઘણા સારા છે!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "It’s not about the marks Roshni. The reality is I hate college.  I am always scared of the senior boys in the hostel. My roommate Prakash avoids me, and he thinks I am abnormal.",
              "hi": "यह अंकों के बारे में नहीं है। वास्तविकता में मैं कॉलेज से नफरत करता हूं। मैं हमेशा छात्रावास में सीनियर लड़कों से डरता हूं। मेरे कमरे में रहने वाला प्रकाश मुझसे बचता है, और वह सोचता है कि मैं असामान्य हूं।",
              "ka": "ಇದು ರೋಶನಿ ಅಂಕಗಳ ಬಗ್ಗೆ ಅಲ್ಲ. ವಾಸ್ತವವೆಂದರೆ ನಾನು ಕಾಲೇಜನ್ನು ದ್ವೇಷಿಸುತ್ತೇನೆ. ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿರುವ ಹಿರಿಯ ಹುಡುಗರೆಂದರೆ ನನಗೆ ಯಾವಾಗಲೂ ಭಯ. ನನ್ನ ರೂಮ್‌ಮೇಟ್ ಪ್ರಕಾಶ್ ನನ್ನನ್ನು ತಪ್ಪಿಸುತ್ತಾನೆ ಮತ್ತು ನಾನು ಅಸಹಜ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ.",
              "te": "ఇది మార్కుల గురించి కాదు రోష్ని. వాస్తవం ఏమిటంటే నాకు కాలేజీ నచ్చట్లేదు. హాస్టల్‌లో సీనియర్‌ అబ్బాయిలంటే నాకు ఎప్పుడూ భయం. నా రూమ్‌మేట్ ప్రకాష్ నన్ను అవాయిడ్ చేస్తున్నాడు మరియు నేను మామూలుగా లేనని అతను అనుకుంటున్నాడు.",
              "or": "ଏହା ମାର୍କ ବିଷୟରେ ନୁହେଁ ରୋଶନି | ବାସ୍ତବତା ହେଉଛି ମୁଁ କଲେଜକୁ ଘୃଣା କରେ | ହଷ୍ଟେଲରେ ଥିବା ସିନିୟର ପୁଅମାନଙ୍କୁ ମୁଁ ସବୁବେଳେ ଭୟ କରେ | ମୋର ରୁମମେଟ୍‍ ପ୍ରକାଶ ମୋତେ ଏଡାଏ, ଏବଂ ସେ ଭାବେ ମୁଁ ଅସ୍ୱାଭାବିକ ଅଟେ |",
              "as": "কথাটো নম্বৰক লৈ নহয় ৰোশনি। আচলতে মই কলেজ ভাল নাপাওঁ। মই সদায় হোষ্টেলৰ জ্যেষ্ঠ ছাত্ৰবোৰক ভয় কৰোঁ। মোৰ ৰুমমেট প্ৰকাশে মোক এৰাই চলে, আৰু তেওঁ ভাবে যে মই এজন অস্বাভাৱিক ব্যক্তি।",
              "gu": "આ રોશનીના ટિપ્પણી નથી. હકીકતમાં હું કોલેજને નફરત કરું છું. મને હોસ્ટેલમાં વરિષ્ઠ બોઈઝથી સતત ડર લાગી રહ્યો છે. મારો રુમમેટ પ્રકાશ મારાથી બચે છે, અને તે વિચારે છે કે હું અસામાન્ય છું."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6_1_5",
              "hi": "X6_1_5",
              "ka": "X6_1_5",
              "te": "X6_1_5",
              "or": "X6_1_5",
              "as": "X6_1_5",
              "gu": "X6_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind talks to Roshni about the harassment he faces at college and the hostel.  Roshni feels helpless that Arvind is having a tough time in college, and she can’t do much to help him.",
              "hi": "अरविंद रोशनी से उस उत्पीड़न के बारे में बात करते हैं जिसका वह कॉलेज और छात्रावास में सामना करता है। रोशनी असहाय अनुभव करती है कि अरविंद को कॉलेज में कठिन समय का सामना करना पड़ रहा है, और वह उसकी सहायता करने के लिए ज्यादा कुछ नहीं कर सकती है।",
              "ka": "ಅರವಿಂದ್ ಅವರು ಕಾಲೇಜು ಮತ್ತು ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿ ಎದುರಿಸುತ್ತಿರುವ ಕಿರುಕುಳದ ಬಗ್ಗೆ ರೋಶ್ನಿಯೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾರೆ. ಅರವಿಂದ್ ಕಾಲೇಜಿನಲ್ಲಿ ಕಠಿಣ ಸಮಯವನ್ನು ಹೊಂದಿದ್ದಾನೆ ಮತ್ತು ಅವನಿಗೆ ಸಹಾಯ ಮಾಡಲು ಅವಳು ಹೆಚ್ಚು ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ ಎಂದು ರೋಶನಿ ಅಸಹಾಯಕಳಾಗುತ್ತಾಳೆ.",
              "te": "అరవింద్ కాలేజీలో మరియు హాస్టల్‌లో తనకు ఎదురయ్యే వేధింపుల గురించి రోష్నితో మాట్లాడాడు. అరవింద్ కాలేజీలో చాలా కష్టాలు అనుభవిస్తున్నాడని రోష్ని నిస్సహాయంగా భావించింది, అతనికి ఆమె పెద్దగా సహాయం చేయలేకపోతుంది.",
              "or": "କଲେଜ ଏବଂ ହଷ୍ଟେଲରେ ସେ ସମ୍ମୁଖୀନ ହେଉଥିବା ଉତ୍ପୀଡ଼ନ ବିଷୟରେ ଅରବିନ୍ଦ ରୋଶନିଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରନ୍ତି | ରୋଶନି ନିଜକୁ ଅସହାୟ ମନେ କରନ୍ତି ଯେ ଅରବିନ୍ଦ କଲେଜରେ କଠିନ ସମୟ ଅତିବାହିତ କରୁଛି, ଏବଂ ତାଙ୍କୁ ସାହାଯ୍ୟ କରିବା ପାଇଁ ସେ ଅଧିକ କିଛି କରିପାରୁନାହାଁନ୍ତି |",
              "as": "অৰবিন্দে ৰোশনিৰ সৈতে কলেজ আৰু হোষ্টেলত সন্মুখীন হোৱা নিৰ্যাতনৰ বিষয়ে কথা পাতিছিল। ৰোশনিয়ে অসহায় অনুভৱ কৰে যে অৰবিন্দে কলেজত কঠিন সময় অতিবাহিত কৰিছে, আৰু তাই তাক সহায় কৰিবলৈ বেছি একো কৰিব নোৱাৰে।",
              "gu": "અરવિંદ કોલેજ તથા હોસ્ટેલમાં તેની સાથે થતા ઉત્પીડન અંગે રોશની સાથે વાત કરે છે. રોશની અસહાયનો અહેસાસ કરે છે કે અરવિંદ કોલેજમાં મુશ્કેલ સમય વિતાવે છે, અને તે તેમની મદદ કરવા માટે ઘણું કંઈ કરી શકતા નથી."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6_2_1",
              "hi": "X6_2_1",
              "ka": "X6_2_1",
              "te": "X6_2_1",
              "or": "X6_2_1",
              "as": "X6_2_1",
              "gu": "X6_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She talks to her parents about the situation in college, knowing fully well that Arvind will not tell them.",
              "hi": "वह अपने माता-पिता से कॉलेज की स्थिति के बारे में बात करती है, जो अच्छी तरह से जानती है कि अरविंद उन्हें पूरी बात नहीं बताएगा।",
              "ka": "ಅರವಿಂದ್ ಅವರಿಗೆ ಹೇಳುವುದಿಲ್ಲ ಎಂದು ಚೆನ್ನಾಗಿ ತಿಳಿದ ಆಕೆ ಕಾಲೇಜಿನ ಪರಿಸ್ಥಿತಿಯ ಬಗ್ಗೆ ಪೋಷಕರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾಳೆ.",
              "te": "అరవింద్ వాళ్లకు చెప్పడని పూర్తిగా తెలుసుకుని కాలేజీలో పరిస్థితి గురించి ఆమె తన తల్లిదండ్రులతో మాట్లాడుతుంది.",
              "or": "ସେ ନିଜ ପିତାମାତାଙ୍କୁ କଲେଜରେ ପରିସ୍ଥିତି ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି, ଭଲଭାବେ ଜାଣି ଯେ ଅରବିନ୍ଦ ସେମାନଙ୍କୁ କହିବ ନାହିଁ |",
              "as": "তাই তাইৰ মাক-দেউতাকৰ সৈতে কলেজৰ পৰিস্থিতিৰ বিষয়ে কথা পাতিছিল, তাই ভালদৰে জানিছিল যে অৰবিন্দে তেওঁলোকক নক'ব।",
              "gu": "તે પોતાના માતા-પિતાને કોલેજની સ્થિતિ અંગે વાત કરે છે, આ સારી રીતે જાણતા અરવિંદ તેમને જણાવતા નથી."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6_2_2",
              "hi": "X6_2_2",
              "ka": "X6_2_2",
              "te": "X6_2_2",
              "or": "X6_2_2",
              "as": "X6_2_2",
              "gu": "X6_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "When Arvind’s parents find out about the harassment he faces at college, they want him to come back home immediately.",
              "hi": "जब अरविंद के माता-पिता को कॉलेज में उसके उत्पीड़न के बारे में पता चलता है, तो वे चाहते हैं कि वह तुरंत घर वापस आ जाए।",
              "ka": "ಅರವಿಂದ್‌ನ ಪೋಷಕರಿಗೆ ಕಾಲೇಜಿನಲ್ಲಿ ಅವರು ಎದುರಿಸುತ್ತಿರುವ ಕಿರುಕುಳದ ಬಗ್ಗೆ ತಿಳಿದಾಗ, ಅವರು ತಕ್ಷಣ ಮನೆಗೆ ಹಿಂತಿರುಗಬೇಕೆಂದು ಅವರು ಬಯಸುತ್ತಾರೆ.",
              "te": "అరవింద్‌కు కాలేజీలో ఎదురయ్యే వేధింపుల గురించి తల్లిదండ్రులు తెలుసుకున్నప్పుడు, అతను వెంటనే ఇంటికి తిరిగి రావాలని కోరారు.",
              "or": "ଯେତେବେଳେ ଅରବିନ୍ଦଙ୍କ ପିତାମାତା କଲେଜରେ ସମ୍ମୁଖୀନ ହେଉଥିବା ଉତ୍ପୀଡ଼ନ ବିଷୟରେ ଜାଣନ୍ତି, ସେମାନେ ଚାହାଁନ୍ତି ସେ ତୁରନ୍ତ ଘରକୁ ଫେରି ଆସୁ |",
              "as": "যেতিয়া অৰবিন্দৰ মাক-দেউতাকে কলেজত সন্মুখীন হোৱা নিৰ্যাতনৰ বিষয়ে জানিব পাৰে, তেওঁলোকে বিচাৰে যে তেওঁ লগে লগে ঘৰলৈ ঘূৰি আহক।",
              "gu": "જ્યારે અરવિંદના માતા-પિતાને ખબર પડી કે તે કોલેજમાં ઉત્પીડનનો સામનો કરવો પડી રહ્યો છે, તો તે ઈચ્છે છે કે તે તાત્કાલિક ઘરે પાછો આવી જાય."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6_2_3////",
              "hi": "X6_2_3 ////",
              "ka": "X6_2_3////",
              "te": "X6_2_3////",
              "or": "X6_2_3////",
              "as": "X6_2_3////",
              "gu": "X6_2_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Arvind, how can you study in that college if you are constantly being harassed?  I don’t want you living like this.",
              "hi": "अरविंद, यदि तुम लगातार परेशान हो रहे हो तो तुम उस कॉलेज में कैसे अध्ययन कर सकते हो? मैं नहीं चाहता कि तुम इस तरह से रहो।",
              "ka": "ಅರವಿಂದ್, ನಿನಗೆ ನಿರಂತರವಾಗಿ ಕಿರುಕುಳ ನೀಡುತ್ತಿದ್ದರೆ ನೀವು ಆ ಕಾಲೇಜಿನಲ್ಲಿ ಹೇಗೆ ಓದುತ್ತೀರಿ? ನೀನು ಹೀಗೆ ಬದುಕುವುದು ನನಗೆ ಇಷ್ಟವಿಲ್ಲ.",
              "te": "నిత్యం వేధింపులకు గురవుతుంటే ఆ కాలేజీలో ఎలా చదువుతావు అరవింద్? నువ్వు ఇలా జీవించడం నాకు ఇష్టం లేదు.",
              "or": "ଅରବିନ୍ଦ, ଯଦି ତୁମେ କ୍ରମାଗତ ଭାବରେ ହଇରାଣ ହେଉଛ, ତୁମେ ସେହି କଲେଜରେ କିପରି ଅଧ୍ୟୟନ କରିପାରିବ? ମୁଁ ଚାହେଁ ନାହିଁ ତୁମେ ଏହିପରି ଜୀବନଯାପନ କର |",
              "as": "অৰবিন্দ, যদি তোমাক নিৰন্তৰ হাৰাশাস্তি কৰা হয় তেন্তে তুমি সেই কলেজত কেনেকৈ পঢ়িবা? মই তুমি এনেদৰে জীয়াই থকাটো নিবিচাৰো।",
              "gu": "અરવિંદ, સતત પ્રતાડિત થઈ રહ્યો હોઈ તમે આ કોલેજમાં કેવી રીતે અભ્યાસ કરી શકો છો?હું નથી ઈચ્છતો કે તમે આ રીતે જીવો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Studies are essential, and I know you have dreams, but it does not mean you have to suffer through college for it. I will come and talk to the Director of your college if required.",
              "hi": "अध्ययन आवश्यक है, और मुझे पता है कि तुम्हारे सपने हैं, लेकिन इसका यह अर्थ नहीं है कि तुमको इसके लिए कॉलेज में पीड़ित होना है। यदि आवश्यक हो तो मैं आऊंगा और अपने कॉलेज के निदेशक से बात करूंगा।",
              "ka": "ಅಧ್ಯಯನಗಳು ಅತ್ಯಗತ್ಯ, ಮತ್ತು ನೀವು ಕನಸುಗಳನ್ನು ಹೊಂದಿದ್ದೀರಿ ಎಂದು ನನಗೆ ತಿಳಿದಿದೆ, ಆದರೆ ಅದಕ್ಕಾಗಿ ನೀವು ಕಾಲೇಜಿನಲ್ಲಿ ಬಳಲುತ್ತಿದ್ದಾರೆ ಎಂದು ಅರ್ಥವಲ್ಲ. ಬೇಕಾದರೆ ನಿಮ್ಮ ಕಾಲೇಜಿನ ನಿರ್ದೇಶಕರ ಬಳಿ ಬಂದು ಮಾತನಾಡುತ್ತೇನೆ.",
              "te": "చదువు చాలా అవసరం, మరియు నీకు కలలు ఉన్నాయని నాకు తెలుసు, కానీ దాని కోసం నువ్వు కళాశాలలో బాధపడాలని దీని అర్థం కాదు. కావాలంటే నేను వచ్చి మీ కాలేజీ డైరెక్టర్‌తో మాట్లాడతాను.",
              "or": "ଅଧ୍ୟୟନ ଜରୁରୀ ଅଟେ, ଏବଂ ମୁଁ ଜାଣେ ତୁମର ସ୍ୱପ୍ନ ଅଛି, କିନ୍ତୁ ଏହାର ଅର୍ଥ ନୁହେଁ ଯେ ତୁମେ ଏଥିପାଇଁ କଲେଜ ମାଧ୍ୟମରେ ଯନ୍ତ୍ରଣା ଭୋଗ | ଆବଶ୍ୟକ ହେଲେ ମୁଁ ଆସିବି ଏବଂ ତୁମ କଲେଜର ନିର୍ଦ୍ଦେଶକଙ୍କ ସହିତ କଥା ହେବି |",
              "as": "পঢ়াটো জৰুৰী, আৰু মই জানো যে তোমাৰ সপোন আছে, কিন্তু ইয়াৰ অৰ্থ এইটো নহয় যে তুমি ইয়াৰ বাবে কলেজত ভুগিব লাগিব। প্ৰয়োজন হ'লে মই আহি তোমাৰ কলেজৰ সঞ্চালকৰ সৈতে কথা পাতিম।",
              "gu": "અભ્યાસ જરૂરી છે, અને મને ખબર છે કે તમે સ્વપ્ન ધરાવો છો, પણ તેનો અર્થ એ નથી કે તમારે આ માટે કોલેજના માધ્યમથી ચુકવણી કરવાની રહેશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Appa, there is no need. I can handle this myself.  I have to complete this course, get a good job and clear the loan you have taken.",
              "hi": "अप्पा, कोई ज़रूरत नहीं है। मैं इसे खुद संभाल सकता हूं। मुझे इस कोर्स को पूरा करना है, और अच्छा काम करना है और आपके द्वारा लिया गया ऋण चुकाना है।",
              "ka": "ಅಪ್ಪಾ ಬೇಕಿಲ್ಲ. ಇದನ್ನು ನಾನೇ ನಿಭಾಯಿಸಬಲ್ಲೆ. ನಾನು ಈ ಕೋರ್ಸನ್ನು ಮುಗಿಸಿ, ಒಳ್ಳೆಯ ಉದ್ಯೋಗ ಪಡೆದು ನೀನು ತೆಗೆದುಕೊಂಡಿರುವ ಸಾಲವನ್ನು ತೀರಿಸಬೇಕು.",
              "te": "అప్పా, అవసరం లేదు. దీన్ని నేను హాండిల్ చేయగలను. నేను ఈ కోర్సు పూర్తి చేసి, మంచి ఉద్యోగం సంపాదించి, మీరు తీసుకున్న రుణాన్ని తీర్చాలి.",
              "or": "ଆପା, କୌଣସି ଆବଶ୍ୟକତା ନାହିଁ | ମୁଁ ଏହାକୁ ନିଜେ ସମ୍ଭାଳି ପାରିବି | ମୋତେ ଏହି ପାଠ୍ୟକ୍ରମ ସମାପ୍ତ କରିବାକୁ ପଡିବ, ଏକ ଭଲ ଚାକିରି ପାଇ ଏବଂ ଆପଣ ନେଇଥିବା ଋଣକୁ କ୍ଲିଅର୍‌ କରିବାକୁ ପଡିବ |",
              "as": "আপ্পা, কোনো প্ৰয়োজন নাই। মই নিজেই এইটো চম্ভালিব পাৰিম। মই এই পাঠ্যক্ৰমটো সম্পূৰ্ণ কৰিব লাগিব, এটা ভাল চাকৰি পাব লাগিব আৰু আপুনি লোৱা ঋণ পৰিশোধ কৰিব লাগিব।",
              "gu": "અપ્પા, કોઈ જ જરૂર નથી.હું તેને જાતે જ સંભાળી શકું છું.મારે આ અભ્યાસક્રમ પૂરો કરવાનો છે, એક સારી નોકરી પ્રાપ્ત કરવાની છે તથા તમારા દ્વારા લેવામાં આવેલ ધિરાણ ચુકવવાનું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "This is not the time to worry about it.  It is not so bad. Pranav is supporting us.",
              "hi": "यह इसके बारे में चिंता करने का समय नहीं है। यह इतना बुरा नहीं है। प्रणव हमें सहयोग दे रहा है।",
              "ka": "ಅದರ ಬಗ್ಗೆ ಚಿಂತಿಸುವ ಸಮಯ ಇದಲ್ಲ. ಇದು ತುಂಬಾ ಕೆಟ್ಟದ್ದಲ್ಲ. ಪ್ರಣವ್ ನಮಗೆ ಬೆಂಬಲ ನೀಡುತ್ತಿದ್ದಾರೆ.",
              "te": "దాని గురించి ఆందోళన చెందాల్సిన సమయం ఇది కాదు. ఇది అంత ఇబ్బందిగా ఏం లేదు. ప్రణవ్ మాకు సపోర్ట్ చేస్తున్నాడు.",
              "or": "ଏହା ବିଷୟରେ ଚିନ୍ତା କରିବାର ଏବେ ସମୟ ନୁହେଁ | ଏହା ଏତେ ଖରାପ ନୁହେଁ | ପ୍ରଣବ ଆମକୁ ସମର୍ଥନ କରୁଛି |",
              "as": "এইটো ইয়াৰ বিষয়ে চিন্তা কৰাৰ সময় নহয়। এতিয়া ইমানো বেয়া সময় চলি থকা নাই। প্ৰণৱে আমাক সমৰ্থন কৰি আছে।",
              "gu": "આ તેના વિશે ચિંતા કરવાનો સમય નથી. આ એટલું ખરાબ નથી. પ્રણવ આપણને સાથ આપી રહ્યા છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Come home for some time at least, until you feel better. We might even be able to find better places for you to study.",
              "hi": "कम से कम कुछ समय के लिए घर आओ, जब तक तुम बेहतर अनुभव न करो। हम अध्ययन करने के लिए तुम्हारे लिए बेहतर स्थानों को भी ढूंढ सकते हैं।",
              "ka": "ಸ್ವಲ್ಪ ಸಮಯದವರೆಗೆ ಮನೆಗೆ ಬನ್ನಿ, ನಿನಗೆ ಉತ್ತಮವಾಗುವವರೆಗೆ. ನೀವು ಅಧ್ಯಯನ ಮಾಡಲು ಉತ್ತಮ ಸ್ಥಳಗಳನ್ನು ಹುಡುಕಲು ನಮಗೆ ಸಾಧ್ಯವಾಗಬಹುದು.",
              "te": "నువ్వు కొంచెం మెరుగయ్యే వరకు అయినా కొంత సమయం ఇంటికి రా. నువ్వు చదువుకోవడానికి మేము మంచి స్థలాలను కూడా చూపించగలము.",
              "or": "ଅନ୍ତତଃପକ୍ଷେ କିଛି ସମୟ ପାଇଁ ଘରକୁ ଆସ, ଯେପର୍ଯ୍ୟନ୍ତ ତୁମକୁ ଭଲ ନ ଲାଗିଛି | ଆମେ ହୁଏତ ତୁମ ଅଧ୍ୟୟନ ପାଇଁ ଉନ୍ନତ ସ୍ଥାନଗୁଡିକ ଖୋଜିବାରେ ସକ୍ଷମ ହୋଇପାରିବା |",
              "as": "কম সময়ৰ বাবে হলেও ঘৰলৈ আহাঁ, যেতিয়ালৈকে তুমি ভাল অনুভৱ নকৰা। আমি আনকি তোমাৰৰ অধ্যয়নৰ বাবে ভাল ঠাই বিচাৰি উলিয়াবলৈও সক্ষম হ'ব পাৰোঁ।",
              "gu": "જ્યાં સુધી તુ સારો અહેસાસ ન કરે ત્યાં સુધી કમ સે કમ થોડા સમય માટે ઘરે આવી જા.અમે તારા માટે અભ્યાસ કરવા સારી જગ્યા શોધી શકીએ છીએ."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6_2_4",
              "hi": "X6_2_4",
              "ka": "X6_2_4",
              "te": "X6_2_4",
              "or": "X6_2_4",
              "as": "X6_2_4",
              "gu": "X6_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Roshni understands Arvind’s situation and wants to help him make a decision.  She brings him a list of positives and negatives for both options in her diary.",
              "hi": "रोशनी अरविंद की स्थिति को समझती है और उसे निर्णय लेने में सहायता करना चाहती है। वह उन्हें अपनी डायरी में दोनों विकल्पों के लिए सकारात्मक और नकारात्मक की एक सूची लाती है।",
              "ka": "ರೋಶ್ನಿ ಅರವಿಂದ್‌ನ ಪರಿಸ್ಥಿತಿಯನ್ನು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದಾಳೆ ಮತ್ತು ಅವನಿಗೆ ನಿರ್ಧಾರ ತೆಗೆದುಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡಲು ಬಯಸುತ್ತಾಳೆ. ಅವಳು ತನ್ನ ಡೈರಿಯಲ್ಲಿ ಎರಡೂ ಆಯ್ಕೆಗಳಿಗೆ ಧನಾತ್ಮಕ ಮತ್ತು ಋಣಾತ್ಮಕ ಪಟ್ಟಿಯನ್ನು ತಂದಿದ್ದಾಳೆ.",
              "te": "రోష్ని అరవింద్ పరిస్థితిని అర్థం చేసుకుని అతనికి నిర్ణయం తీసుకోవడంలో సహాయం చేయాలనుకుంటోంది. ఆమె తన డైరీలో రెండు ఆప్షన్‌లకు సంబంధించిన పాజిటివ్‌లు మరియు నెగటివ్‌ల జాబితాను అతనికి అందించింది.",
              "or": "ରୋଶନି ଅରବିନ୍ଦଙ୍କ ଅବସ୍ଥା ବୁଝନ୍ତି ଏବଂ ତାଙ୍କୁ ନିଷ୍ପତ୍ତି ନେବାରେ ସାହାଯ୍ୟ କରିବାକୁ ଚାହାଁନ୍ତି |ସେ ତାଙ୍କ ଡାଏରୀରେ ଉଭୟ ବିକଳ୍ପ ପାଇଁ ସକରାତ୍ମକ ଏବଂ ନକାରାତ୍ମକତାର ଏକ ତାଲିକା ଆଣିଥାନ୍ତି |",
              "as": "ৰোশনিয়ে অৰবিন্দৰ পৰিস্থিতি বুজি পায় আৰু তেওঁক এটা সিদ্ধান্ত লোৱাত সহায় কৰিব বিচাৰে। তাই তাইৰ ডায়েৰীত দুয়োটা বিকল্পৰ বাবে ইতিবাচক আৰু নেতিবাচক দিশৰ এখন তালিকা আনিছে।",
              "gu": "રોશની અરવિંદની સ્થિતિને સમજે છે અને યોગ્ય નિર્ણય લેવામાં તે અંગે મદદ કરવા માગે છે.તે પોતાની ડાયરીમાં બે વિકલ્પ માટે સકારાત્મક અને નકારાત્મકની એક યાદી લાવે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6_2_5 reveal",
              "hi": "X6_2_5 रिवील",
              "ka": "X6_2_5 ಬಹಿರಂಗ",
              "te": "X6_2_5 రివీల్",
              "or": "X6_2_5 reveal",
              "as": "X6_2_5 প্ৰকাশ কৰে",
              "gu": "X6_2_5 પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal the positives and negatives of going home.",
              "hi": "घर जाने के सकारात्मक और नकारात्मक को प्रकट करने के लिए क्लिक करें।",
              "ka": "ಮನೆಗೆ ಹೋಗುವ ಧನಾತ್ಮಕ ಮತ್ತು ಋಣಾತ್ಮಕತೆಯನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "ఇంటికి వెళ్లడం యొక్క పాజిటివ్ మరియు నెగటివ్ అంశాలను రివీల్ చేయడానికి క్లిక్ చేయండి.",
              "or": "ଘରକୁ ଯିବାର ସକରାତ୍ମକ ଏବଂ ନକାରାତ୍ମକତା ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "ঘৰলৈ যোৱাৰ ইতিবাচক আৰু নেতিবাচক কথাবোৰ প্ৰকাশ কৰিবলৈ ক্লিক কৰক।",
              "gu": "ઘરે જવાના હકારાત્મક અને નકારાત્મક પાસે જાહેર કરવા અહીં ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Positive",
              "hi": "सकारात्मक",
              "ka": "ಧನಾತ್ಮಕ",
              "te": "పాజిటివ్",
              "or": "ସକରାତ୍ମକ",
              "as": "ভালদিশ",
              "gu": "હકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Positive",
              "hi": "सकारात्मक",
              "ka": "ಧನಾತ್ಮಕ",
              "te": "పాజిటివ్",
              "or": "ସକରାତ୍ମକ",
              "as": "ভালদিশ",
              "gu": "હકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Positive",
              "hi": "सकारात्मक",
              "ka": "ಧನಾತ್ಮಕ",
              "te": "పాజిటివ్",
              "or": "ସକରାତ୍ମକ",
              "as": "ভালদিশ",
              "gu": "હકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "Positive",
              "hi": "सकारात्मक",
              "ka": "ಧನಾತ್ಮಕ",
              "te": "పాజిటివ్",
              "or": "ସକରାତ୍ମକ",
              "as": "ভালদিশ",
              "gu": "હકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.4.beforeReveal",
            "value": {
              "en": "Negative",
              "hi": "नकारात्मक",
              "ka": "ಋಣಾತ್ಮಕ",
              "te": "నెగటివ్",
              "or": "ନକାରାତ୍ମକ",
              "as": "বেয়াদিশ",
              "gu": "નકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "You can spend time with the family, think about your next steps, and not worry about the constant harassment.",
              "hi": "तुम परिवार के साथ समय बिता सकते हो, अपने अगले चरणों के बारे में सोच सकते हो, और निरंतर उत्पीड़न के बारे में चिंता मत करो।",
              "ka": "ನೀವು ಕುಟುಂಬದೊಂದಿಗೆ ಸಮಯ ಕಳೆಯಬಹುದು, ನಿಮ್ಮ ಮುಂದಿನ ಕ್ರಮಗಳ ಬಗ್ಗೆ ಯೋಚಿಸಬಹುದು ಮತ್ತು ನಿರಂತರ ಕಿರುಕುಳದ ಬಗ್ಗೆ ಚಿಂತಿಸಬೇಡಿ.",
              "te": "నువ్వు కుటుంబంతో సమయం గడపవచ్చు, నీ తదుపరి చర్యల గురించి ఆలోచించవచ్చు మరియు నిరంతర వేధింపుల గురించి చింతించే అవసరం ఉండదు.",
              "or": "ତୁମେ ପରିବାର ସହିତ ସମୟ ବିତାଇ ପାରିବେ, ତୁମର ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପଗୁଡିକ ବିଷୟରେ ଚିନ୍ତା କରିପାରିବ, ଏବଂ କ୍ରମାଗତ ଉତ୍ପୀଡ଼ନ ବିଷୟରେ ଚିନ୍ତା କର ନାହିଁ |",
              "as": "আপুনি পৰিয়ালৰ সৈতে সময় কটাব পাৰে, আপোনাৰ পৰৱৰ্তী পদক্ষেপবোৰৰ বিষয়ে চিন্তা কৰিব পাৰে, আৰু নিৰন্তৰ উৎপীড়নৰ বিষয়ে চিন্তা নকৰাকৈ থাকিব পাৰে।",
              "gu": "તમે પરિવારની સાથે યોગ્ય સમય વિતાવી શકો છો,તમારા હવે પછીના પગલાં અંગે વિચારી શકો છો, અને સતત ઉત્પીડન અંગે ચિંતા કરી શકતા નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "You will feel safe at home.",
              "hi": "तुम घर पर सुरक्षित अनुभव करोगे।",
              "ka": "ನೀವು ಮನೆಯಲ್ಲಿ ಸುರಕ್ಷಿತವಾಗಿರುತ್ತೀರಿ.",
              "te": "నువ్వు ఇంట్లో సురక్షితంగా ఉండవచ్చు.",
              "or": "ତୁମେ ଘରେ ସୁରକ୍ଷିତ ଅନୁଭବ କରିବ |",
              "as": "আপুনি ঘৰত সুৰক্ষিত অনুভৱ কৰিব।",
              "gu": "તમે ઘરે સલામતીનો અહેસાસ કરશો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "A few weeks of rest at home will help you come back and face the situation better.",
              "hi": "घर पर आराम के कुछ सप्ताह तुमको वापस आने और स्थिति का सामना करने में सहायता करेंगे।",
              "ka": "ಮನೆಯಲ್ಲಿ ಕೆಲವು ವಾರಗಳ ವಿಶ್ರಾಂತಿ ನಿನಗೆ ಹಿಂತಿರುಗಲು ಮತ್ತು ಪರಿಸ್ಥಿತಿಯನ್ನು ಉತ್ತಮವಾಗಿ ಎದುರಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "ఇంట్లో కొన్ని వారాల విశ్రాంతి నీకు ఉపశమనం ఇస్తుంది మరియు పరిస్థితిని మెరుగ్గా ఎదుర్కోవటానికి సహాయపడుతుంది.",
              "or": "ଘରେ କିଛି ସପ୍ତାହର ବିଶ୍ରାମ ତୁମକୁ ଫେରି ଆସି ପରିସ୍ଥିତିକୁ ଭଲ ଭାବରେ ସାମ୍ନା କରିବାରେ ସାହାଯ୍ୟ କରିବ |",
              "as": "ঘৰত কেইসপ্তাহমান জিৰণি ল'লে আপোনাক ঘূৰি আহি পৰিস্থিতিটোৰ ভালদৰে সন্মুখীন হোৱাত সহায় কৰিব।",
              "gu": "ઘરે કેટલાક સપ્તાહનો આરામ તમને પરત આવવા અને સ્થિતિને વધારે સારી કરવાનો સામનો કરવામાં મદદ કરશે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "You will get a chance to think about your life and start afresh.",
              "hi": "तुमको अपने जीवन के बारे में सोचने और नए सिरे से शुरू करने का अवसर मिलेगा।",
              "ka": "ನಿಮ್ಮ ಜೀವನದ ಬಗ್ಗೆ ಯೋಚಿಸಲು ಮತ್ತು ಹೊಸದಾಗಿ ಪ್ರಾರಂಭಿಸಲು ನಿನಗೆ ಅವಕಾಶ ಸಿಗುತ್ತದೆ.",
              "te": "నీ జీవితం గురించి ఆలోచించి కొత్తగా ప్రారంభించే అవకాశం నీకు లభిస్తుంది.",
              "or": "ତୁମ ଜୀବନ ବିଷୟରେ ଚିନ୍ତା କରିବା ଏବଂ ନୁତନ ଭାବରେ ଆରମ୍ଭ କରିବାର ତୁମେ ଏକ ସୁଯୋଗ ପାଇବ |",
              "as": "আপুনি আপোনাৰ জীৱনৰ বিষয়ে চিন্তা কৰাৰ আৰু নতুনকৈ আৰম্ভ কৰাৰ সুযোগ পাব।",
              "gu": "તમારા જીવન વિશે વિચાર કરવાની તમને તક મળશે અને નવેસરથી શરૂઆત કરી શકાશે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.4.afterReveal",
            "value": {
              "en": "If you come home, you might not feel like going back to this place.",
              "hi": "अगर तुम घर आते हो, तो शायद तुम्हारा इस जगह पर वापस जाने का मन न करे।",
              "ka": "ನೀವು ಮನೆಗೆ ಬಂದರೆ, ಈ ಸ್ಥಳಕ್ಕೆ ಹಿಂತಿರುಗಲು ನಿನಗೆ ಅನಿಸುವುದಿಲ್ಲ.",
              "te": "నువ్వు ఇంటికి వచ్చినట్లయితే, ఈ ప్రదేశానికి తిరిగి వెళ్లాలని నీకు అనిపించకపోవచ్చు.",
              "or": "ଯଦି ତୁମେ ଘରକୁ ଆସ, ତୁମେ ଏହି ସ୍ଥାନକୁ ଫେରିବା ଭଳି ଅନୁଭବ ହୁଏତ କରିବ ନାହିଁ |",
              "as": "যদি আপুনি ঘৰলৈ আহে, আপুনি সেই ঠাইলৈ ঘূৰি যাবলৈ মন নকৰিবও পাৰে।",
              "gu": "જો તું ઘરે આવીશ તો કદાંચ તમને આ જગ્યા ઉપર પરત ફરવાનું મન ન લાગે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6_2_6 reveal",
              "hi": "X6_2_6 रिवील ",
              "ka": "X6_2_6 ಬಹಿರಂಗ",
              "te": "X6_2_6 రివీల్",
              "or": "X6_2_6 reveal",
              "as": "X6_2_6 প্ৰকাশ কৰে",
              "gu": "X6_2_6 પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal the positives and negatives of staying in the college.",
              "hi": "कॉलेज में रहने के सकारात्मक और नकारात्मक को प्रकट करने के लिए क्लिक करें।",
              "ka": "ಕಾಲೇಜಿನಲ್ಲಿ ಉಳಿಯುವುದರ ಧನಾತ್ಮಕ ಮತ್ತು ಋಣಾತ್ಮಕ ಅಂಶಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "కాలేజీలో ఉండడం వల్ల కలిగే పాజిటివ్ మరియు నెగటివ్ అంశాలను రివీల్ చేయడానికి క్లిక్ చేయండి.",
              "or": "କଲେଜରେ ରହିବାର ସକରାତ୍ମକ ଏବଂ ନକାରାତ୍ମକତା ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "কলেজত থকাৰ ইতিবাচক আৰু নেতিবাচক কথাবোৰ প্ৰকাশ কৰিবলৈ ক্লিক কৰক।",
              "gu": "કોલેજમાં રહેવાના હકારાત્મક અને નકારાત્મક પાસાને રજૂ કરવા અહીં ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Positive",
              "hi": "सकारात्मक",
              "ka": "ಧನಾತ್ಮಕ",
              "te": "పాజిటివ్",
              "or": "ସକରାତ୍ମକ",
              "as": "ভালদিশ",
              "gu": "હકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Positive",
              "hi": "सकारात्मक",
              "ka": "ಧನಾತ್ಮಕ",
              "te": "పాజిటివ్",
              "or": "ସକରାତ୍ମକ",
              "as": "ভালদিশ",
              "gu": "નકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Negative",
              "hi": "नकारात्मक",
              "ka": "ಋಣಾತ್ಮಕ",
              "te": "నెగటివ్",
              "or": "ନକାରାତ୍ମକ",
              "as": "বেয়াদিশ",
              "gu": "નકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "Negative",
              "hi": "नकारात्मक",
              "ka": "ಋಣಾತ್ಮಕ",
              "te": "నెగటివ్",
              "or": "ନକାରାତ୍ମକ",
              "as": "বেয়াদিশ",
              "gu": "નકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "You like the course you are doing and would like to continue studying it.",
              "hi": "तुम उस कोर्स को पसंद करते हो जो तुम कर रहे हो और इसे पढ़ना जारी रखना चाहते हो ।",
              "ka": "ನೀವು ಮಾಡುತ್ತಿರುವ ಕೋರ್ಸ್ ನಿನಗೆ ಇಷ್ಟವಾಗಿದೆ ಮತ್ತು ಅದರ ಅಧ್ಯಯನವನ್ನು ಮುಂದುವರಿಸಲು ಬಯಸುತ್ತೀರಿ.",
              "te": "నువ్వు నీకు నచ్చిన కోర్సు చేస్తున్నావు మరియు దానిని చదవడం కొనసాగించాలనుకుంటున్నావు.",
              "or": "ତୁମେ କରୁଥିବା ପାଠ୍ୟକ୍ରମକୁ ତୁମେ ପସନ୍ଦ କର ଏବଂ ଏହାକୁ ଅଧ୍ୟୟନ ଜାରି ରଖିବାକୁ ଚାହୁଁଛ |",
              "as": "আপুনি কৰি থকা পাঠ্যক্ৰমটো আপুনি ভাল পায় আৰু ইয়াক অধ্যয়ন কৰি থাকিব বিচাৰে।",
              "gu": "તમે જે કોર્સ કરી રહ્યા છો તે તમને પસંદ છે અને તમે તેનો અભ્યાસ ચાલુ રાખવા ઈચ્છશો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "You will be able to get a degree and pay off the loan.",
              "hi": "तुम डिग्री प्राप्त करने और ऋण का भुगतान करने में सक्षम होगे।",
              "ka": "ಪದವಿ ಪಡೆದು ಸಾಲ ತೀರಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ.",
              "te": "నువ్వు డిగ్రీ పొంది లోన్ తీర్చగలుగుతావు.",
              "or": "ତୁମେ ଏକ ଡିଗ୍ରୀ ହାସଲ କରିବାକୁ ଏବଂ ଋଣ ପରିଶୋଧ କରିବାକୁ ସକ୍ଷମ ହେବ |",
              "as": "আপুনি ডিগ্ৰী লাভ কৰিবলৈ আৰু ঋণ পৰিশোধ কৰিবলৈ সক্ষম হ'ব।",
              "gu": "તમે ડિગ્રી મેળવવા માટે સક્ષમ બનશો અને લોન પરત કરી શકશો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "You will continue to get harassed in college.",
              "hi": "तुम कॉलेज में परेशान रहना जारी रखोगे।",
              "ka": "ಕಾಲೇಜಿನಲ್ಲಿ ನಿನಗೆ ಕಿರುಕುಳ ನೀಡುವುದು ಮುಂದುವರಿಯುತ್ತದೆ.",
              "te": "నువ్వు కాలేజీలో వేధింపులకు గురవుతూనే ఉంటారు.",
              "or": "ତୁମକୁ କଲେଜରେ ଉତ୍ପୀଡ଼ନ କରାଯିବା ଜାରି ରହିବ |",
              "as": "আপুনি কলেজত হাৰাশাস্তি খাই থাকিব।",
              "gu": "તને કોલેજમાં પજવણી કરવાનું ચાલું રાખવામાં આવશે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "You will miss classes and no one in the class will help you with the notes.",
              "hi": "नोट्स",
              "ka": "ನೀವು ತರಗತಿಗಳನ್ನು ಕಳೆದುಕೊಳ್ಳುತ್ತೀರಿ ಮತ್ತು ತರಗತಿಯಲ್ಲಿ ಯಾರೂ ನಿನಗೆ ಟಿಪ್ಪಣಿಗಳೊಂದಿಗೆ ಸಹಾಯ ಮಾಡುವುದಿಲ್ಲ.",
              "te": "నువ్వు తరగతులు మిస్ అవుతావు మరియు క్లాస్‌లో ఎవరూ నీకు నోట్స్ ఇచ్చి సహాయం చేయరు.",
              "or": "ତୁମେ କ୍ଲାସ୍ଗୁଡିକ ମିସ୍‌ କରିବ ଏବଂ କ୍ଲାସ୍‌ରେ କେହି ତୁମକୁ ନୋଟ୍ସ ସହିତ ସାହାଯ୍ୟ କରିବେ ନାହିଁ |",
              "as": "আপুনি শ্ৰেণীবোৰ হেৰুৱাব আৰু শ্ৰেণীৰ কোনেও আপোনাক টোকাবোৰ নিদিব।",
              "gu": "તમે વર્ગને ચુકીશ અને વર્ગને લગતી નોંધ અંગે તને કોઈ જ મદદરૂપ બનશે નહીં"
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "X6_2_7 D",
              "hi": "X6_2_7 d",
              "ka": "X6_2_7 ಡಿ",
              "te": "X6_2_7 డి",
              "or": "X6_2_7 D",
              "as": "X6_2_7 D",
              "gu": "X6_2_7 D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Arvind do?",
              "hi": "अरविंद को क्या करना चाहिए?",
              "ka": "ಅರವಿಂದ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "అరవింద్ ఏం చేయాలి?",
              "or": "ଅରବିନ୍ଦ କ’ଣ କରିବା ଉଚିତ୍ ?",
              "as": "অৰবিন্দে কি কৰা উচিত?",
              "gu": "અરવિંદે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Take some time off from college and decide what he wants to do",
              "hi": "कॉलेज से कुछ समय निकाले और तय करे कि वह क्या करना चाहता है",
              "ka": "ಕಾಲೇಜಿನಿಂದ ಸ್ವಲ್ಪ ಸಮಯ ತೆಗೆದುಕೊಳ್ಳಿ ಮತ್ತು ಅವನು ಏನು ಮಾಡಬೇಕೆಂದು ನಿರ್ಧರಿಸಿ",
              "te": "కాలేజీ నుండి కొంత సమయం దూరంగా ఉండి, అతను ఏమి చేయాలనుకుంటున్నాడో నిర్ణయించుకోవాలి",
              "or": "କଲେଜରୁ କିଛି ସମୟ ଛୁଟି ନିଅନ୍ତୁ ଏବଂ ନିଷ୍ପତ୍ତି ନିଅନ୍ତୁ ସେ କଣ କରିବାକୁ ଚାହାଁନ୍ତି",
              "as": "কলেজৰ পৰা অলপ সময় উলিয়াওক আৰু তেওঁ কি কৰিব বিচাৰে সিদ্ধান্ত লওক",
              "gu": "કોલેજમાંથી કેટલોક સમય કાઢો અને નક્કી કરો કે તે શું કરવા ઈચ્છે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Continue studying engineering and find a way around his problems",
              "hi": "इंजीनियरिंग की पढ़ाई जारी रखे और उसकी समस्याओं का समाधान खोजे",
              "ka": "ಇಂಜಿನಿಯರಿಂಗ್ ಓದುವುದನ್ನು ಮುಂದುವರಿಸಿ ಮತ್ತು ಅವನ ಸಮಸ್ಯೆಗಳಿಗೆ ದಾರಿ ಕಂಡುಕೊಳ್ಳಿ",
              "te": "ఇంజినీరింగ్ చదవడం కొనసాగిస్తూ అతని సమస్యలకు మార్గం కనుక్కోవాలి",
              "or": "ଇଞ୍ଜିନିୟରିଂ ଅଧ୍ୟୟନ ଜାରି ରଖନ୍ତୁ ଏବଂ ତାଙ୍କ ସମସ୍ୟାର ସମାଧାନ ପାଇଁ ଏକ ଉପାୟ ଖୋଜନ୍ତୁ",
              "as": "অভিযান্ত্ৰিক অধ্যয়ন কৰি থাকক আৰু তেওঁৰ চাৰিওফালৰ সমস্যাবোৰৰ এটা পথ বিচাৰি উলিয়াওক",
              "gu": "એન્જીનિયરિંગનો અભ્યાસ ચાલું રાખો અને તેની સમસ્યાઓનું સમાધાન શોધો"
            }
          }
        ]
      },
      "scene9": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Take a break from college",
              "hi": "कॉलेज से ब्रेक ले",
              "ka": "ಕಾಲೇಜಿನಿಂದ ವಿರಾಮ ತೆಗೆದುಕೊಳ್ಳಿ",
              "te": "కళాశాల నుండి విరామం తీసుకోవాలి",
              "or": "କଲେଜରୁ ଏକ ବିରତି ନିଅନ୍ତୁ",
              "as": "কলেজৰ পৰা অলপ সময় বিৰতি লওক",
              "gu": "કોલેજમાંથી થોડો સમય રજા લે"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind has decided to take some time away from college. The experience in college has left him shocked and overwhelmed.",
              "hi": "अरविंद ने कॉलेज से कुछ समय निकालने का निर्णय किया है। कॉलेज में अनुभव ने उसे चौंका दिया और अभिभूत कर दिया है।",
              "ka": "ಅರವಿಂದ್ ಕಾಲೇಜಿನಿಂದ ಸ್ವಲ್ಪ ಸಮಯ ಕಳೆಯಲು ನಿರ್ಧರಿಸಿದ್ದಾರೆ. ಕಾಲೇಜಿನಲ್ಲಿನ ಅನುಭವವು ಅವನಿಗೆ ಆಘಾತವನ್ನುಂಟುಮಾಡಿದೆ ಮತ್ತು ಮುಳುಗಿದೆ.",
              "te": "అరవింద్ కాలేజీ నుండి కొంత సమయం దూరంగా ఉండాలని నిర్ణయించుకున్నాడు. కాలేజీలో ఎదురైన అనుభవం అతన్ని దిగ్భ్రాంతికి గురిచేసింది.",
              "or": "ଅରବିନ୍ଦ କଲେଜରୁ କିଛି ସମୟ ନେବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି। କଲେଜରେ ହୋଇଥିବା ଅଭିଜ୍ଞତା ତାଙ୍କୁ ଅଭିପାତ ଏବଂ ଅଭିଭୁତ କରିଦେଇଛି |",
              "as": "অৰবিন্দে কলেজৰ পৰা কিছু সময় আঁতৰত থকাৰ সিদ্ধান্ত লৈছে। কলেজৰ অভিজ্ঞতাই তেওঁক হতবাক আৰু অভিভূত কৰি তুলিছে।",
              "gu": "અરવિંદે કોલેજથી થોડો સમય દૂર રહેવાનું નક્કી કર્યું છે. કૉલેજના અનુભવે તેમને આઘાત અને અભિભૂત કરી દીધા છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_1_2",
              "hi": "A1_1_2",
              "ka": "A1_1_2",
              "te": "A1_1_2",
              "or": "A1_1_2",
              "as": "A1_1_2",
              "gu": "A1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He is physically and mentally tired, and his home feels like an escape from reality. When he tells his family exactly what happened, they are also upset that he had to go through so much.",
              "hi": "वह शारीरिक और मानसिक रूप से थक गया है, और उसका घर उसे वास्तविकता से बचने के लिए सुरक्षित स्थान लगता है। जब वह अपने परिवार को बताता है कि वास्तव में क्या हुआ, तो वे भी परेशान हो जाते हैं कि उसे इतना कुछ सहना पड़ा।",
              "ka": "ಅವನು ದೈಹಿಕವಾಗಿ ಮತ್ತು ಮಾನಸಿಕವಾಗಿ ದಣಿದಿದ್ದಾನೆ, ಮತ್ತು ಅವನ ಮನೆಯು ವಾಸ್ತವದಿಂದ ತಪ್ಪಿಸಿಕೊಳ್ಳುವಂತೆ ಭಾಸವಾಗುತ್ತದೆ. ಏನಾಯಿತು ಎಂದು ಅವನು ತನ್ನ ಮನೆಯವರಿಗೆ ನಿಖರವಾಗಿ ಹೇಳಿದಾಗ, ಅವನು ತುಂಬಾ ಕಷ್ಟಪಡಬೇಕಾಗಿತ್ತು ಎಂದು ಅವರು ಅಸಮಾಧಾನಗೊಂಡಿದ್ದಾರೆ.",
              "te": "అతను శారీరకంగా మరియు మానసికంగా అలసిపోయాడు మరియు అతను ఇంట్లో ఉంటే వాస్తవికత నుండి తప్పించుకున్నట్లుగా అనిపిస్తుంది. ఏం జరిగిందో సరిగ్గా తన కుటుంబీకులకు చెప్పినప్పుడు, అతను చాలా కష్టపడాల్సి వచ్చిందని వారు కూడా బాధపడతారు.",
              "or": "ସେ ଶାରୀରିକ ଏବଂ ମାନସିକ ସ୍ତରରେ କ୍ଳାନ୍ତ, ଏବଂ ତାଙ୍କ ଘର ବାସ୍ତବତାରୁ ରକ୍ଷା ପାଇବା ଭଳି ଅନୁଭବ କରାଏ | ଯେତେବେଳେ ସେ ତାଙ୍କ ପରିବାରକୁ ଠିକ୍ ଭାବରେ କୁହନ୍ତି କଣ ହୋଇଥିଲା, ସେମାନେ ମଧ୍ୟ ଅପ୍‌ସେଟ୍‌ ହୁଅନ୍ତି ଯେ ତାଙ୍କୁ ଏତେ ଦେଇ ଗତି କରିବାକୁ ପଡିଥିଲା |",
              "as": "তেওঁ শাৰীৰিক আৰু মানসিকভাৱে ভাগৰুৱা, আৰু তেওঁৰ ঘৰতো বাস্তৱৰ পৰা পলাই থকাৰ দৰে অনুভৱ হয়। যেতিয়া তেওঁ তেওঁৰ পৰিয়ালক সঠিকভাৱে কি ঘটিছিল সেয়া কয়, তেওঁলোকো দুঃখিত হয় যে তেওঁ ইমানখিনি পাৰ কৰিব লগা হৈছিল।",
              "gu": "તે શારીરિક અને માનસિક રીતે થાકેલ છે, અને તેનું ઘર તેને પલાયન જેવું લાગે છે. જ્યારે તે પોતાના પરિવારને કહે છે કે હકીકતમાં શું થયું, તો તેઓ ભારે પરેશાન થઈ જાય છે કે તેને આટલું બધુ કરવું પડ્યું."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_1_3",
              "hi": "A1_1_3",
              "ka": "A1_1_3",
              "te": "A1_1_3",
              "or": "A1_1_3",
              "as": "A1_1_3",
              "gu": "A1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind's parents are trying their best to support him. They try to talk to him, but Arvind doesn't say much.",
              "hi": "अरविंद के माता-पिता उनका समर्थन करने के लिए अपनी पूरी कोशिश कर रहे हैं। वे उससे बात करने की कोशिश करते हैं, लेकिन अरविंद ज्यादा नहीं कहता है।",
              "ka": "ಅರವಿಂದ್ ಅವರ ಪೋಷಕರು ಆತನನ್ನು ಬೆಂಬಲಿಸಲು ಪ್ರಯತ್ನಿಸುತ್ತಿದ್ದಾರೆ. ಅವರು ಅವನೊಂದಿಗೆ ಮಾತನಾಡಲು ಪ್ರಯತ್ನಿಸಿದರು, ಆದರೆ ಅರವಿಂದ್ ಹೆಚ್ಚು ಹೇಳಲಿಲ್ಲ.",
              "te": "అరవింద్ తల్లిదండ్రులు అతడిని ఆదుకునేందుకు తమ వంతు ప్రయత్నం చేస్తున్నారు. వారు అతనితో మాట్లాడటానికి ప్రయత్నించారు, కానీ అరవింద్ పెద్దగా మాట్లాడలేదు.",
              "or": "ଅରବିନ୍ଦଙ୍କ ପିତାମାତା ଯଥାସମ୍ଭବ ତାଙ୍କୁ ସମର୍ଥନ କରିବାକୁ ଚେଷ୍ଟା କରୁଛନ୍ତି। ସେମାନେ ତାଙ୍କ ସହ କଥା ହେବାକୁ ଚେଷ୍ଟା କରନ୍ତି, କିନ୍ତୁ ଅରବିନ୍ଦ ଅଧିକ କିଛି କୁହନ୍ତି ନାହିଁ |",
              "as": "অৰবিন্দৰ পিতৃ-মাতৃয়ে তেওঁক সমৰ্থন কৰিবলৈ যথাসম্ভৱ চেষ্টা কৰি আছে। তেওঁলোকে তেওঁৰ সৈতে কথা পাতিবলৈ চেষ্টা কৰে, কিন্তু অৰবিন্দে বেছি কথা নকয়।",
              "gu": "અરવિંદના માતા-પિતા તેનું સમર્થન કરવાનો સંપૂર્ણ પ્રયત્ન કરી રહ્યા છે.તેઓ તેની સાથે વાત કરવાનો પ્રયત્ન કરે છે, પણ અરવિંદ વિશેષ કંઈ જ કહેતો નથી"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_1_4",
              "hi": "A1_1_4",
              "ka": "A1_1_4",
              "te": "A1_1_4",
              "or": "A1_1_4",
              "as": "A1_1_4",
              "gu": "A1_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind prefers staying inside the room and feels tired all the time.",
              "hi": "अरविंद कमरे के अंदर रहना पसंद करता है और हर समय थका हुआ अनुभव करता है।",
              "ka": "ಅರವಿಂದ್ ಕೋಣೆಯೊಳಗೆ ಇರಲು ಇಷ್ಟಪಡುತ್ತಾನೆ ಮತ್ತು ಸಾರ್ವಕಾಲಿಕ ಸುಸ್ತಾಗಿರುತ್ತಾನೆ.",
              "te": "అరవింద్ గది లోపల ఉండటానికి ఇష్టపడతాడు మరియు ఎప్పుడూ అలసిపోయినట్టు ఉంటాడు.",
              "or": "ଅରବିନ୍ଦ ରୁମ୍‌ ଭିତରେ ରହିବାକୁ ପସନ୍ଦ କରନ୍ତି ଏବଂ ସବୁବେଳେ କ୍ଳାନ୍ତ ଅନୁଭବ କରନ୍ତି |",
              "as": "অৰবিন্দে কোঠাটোৰ ভিতৰত থাকিবলৈ পছন্দ কৰে আৰু সকলো সময়তে ভাগৰুৱা অনুভৱ কৰে।",
              "gu": "અરવિંદ રૂમની અંદર રહેવાનું પસંદ કરે છે અને દરેક સમયે થાકનો અહેસાસ કરે છે"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_1_5",
              "hi": "A1_1_5",
              "ka": "A1_1_5",
              "te": "A1_1_5",
              "or": "A1_1_5",
              "as": "A1_1_5",
              "gu": "A1_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind can barely sleep, so he starts staying up all night. He feels alone and helpless.",
              "hi": "अरविंद मुश्किल से सो सकता है, इसलिए वह पूरी रात जागना शुरू कर देता है। वह अकेले और असहाय महसूस करता है।",
              "ka": "ಅರವಿಂದ್‌ಗೆ ನಿದ್ರೆ ಬರುವುದಿಲ್ಲ, ಆದ್ದರಿಂದ ಅವನು ರಾತ್ರಿಯಿಡೀ ಎಚ್ಚರವಾಗಿರಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ. ಅವನು ಏಕಾಂಗಿಯಾಗಿ ಮತ್ತು ಅಸಹಾಯಕನಾಗಿರುತ್ತಾನೆ.",
              "te": "అరవింద్‌కి నిద్ర పట్టట్లేదు, కాబట్టి రాత్రంతా మేల్కొని ఉండడం ప్రారంభించాడు. అతను ఒంటరిగా మరియు నిస్సహాయంగా భావిస్తాడు.",
              "or": "ଅରବିନ୍ଦ କ୍ୱଚିତ୍ ଶୋଇ ପାରନ୍ତି, ତେଣୁ ସେ ରାତିସାରା ଅନିଦ୍ରା ରହିବାକୁ ଲାଗିଲେ | ସେ ଏକାକୀ ଏବଂ ଅସହାୟ ଅନୁଭବ କରନ୍ତି |",
              "as": "অৰবিন্দে মুঠেই শুব নোৱাৰে, সেয়েহে তেওঁ গোটেই ৰাতি জাগি থাকিবলৈ আৰম্ভ কৰে। তেওঁ অকলশৰীয়া আৰু অসহায় অনুভৱ কৰে।",
              "gu": "અરવિંદ મુશ્કેલીથી ઉંઘી શકે છે, માટે તે સંપૂર્ણ રાત્રીમાં જાગે છે. તે એકલાપણાનો અને અસહાયતાનો અહેસાસ થાય છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_2_1",
              "hi": "A1_2_1",
              "ka": "A1_2_1",
              "te": "A1_2_1",
              "or": "A1_2_1",
              "as": "A1_2_1",
              "gu": "A1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He tries reading some things on the internet that might help him, but the things he finds make him feel even worse.",
              "hi": "वह इंटरनेट पर कुछ चीजों को पढ़ने की कोशिश करता है जो उसकी सहायता कर सकती हैं, लेकिन जो चीजें उसे मिलती है वह उसे और भी खराब अनुभव् कराती है।",
              "ka": "ಅವನು ಇಂಟರ್ನೆಟ್‌ನಲ್ಲಿ ಕೆಲವು ವಿಷಯಗಳನ್ನು ಓದಲು ಪ್ರಯತ್ನಿಸುತ್ತಾನೆ ಅದು ಅವನಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ, ಆದರೆ ಅವನು ಕಂಡುಕೊಂಡ ವಿಷಯಗಳು ಅವನನ್ನು ಇನ್ನಷ್ಟು ಕೆಟ್ಟದಾಗಿ ಭಾವಿಸುತ್ತವೆ.",
              "te": "అతను ఇంటర్నెట్‌లో అతనికి సహాయపడే కొన్ని విషయాలను చదవడానికి ప్రయత్నిస్తాడు, కానీ అతను కనుగొన్న విషయాలు అతనిని మరింత దిగజార్చాయి.",
              "or": "ସେ ଇଣ୍ଟରନେଟ୍‌ରେ କିଛି ଜିନିଷ ପଢିବାକୁ ଚେଷ୍ଟା କରନ୍ତି ଯାହା ହୁଏତ ତାଙ୍କୁ ସାହାଯ୍ୟ କରିପାରେ, କିନ୍ତୁ ସେ ପାଇଥିବା ଜିନିଷଗୁଡ଼ିକ ତାଙ୍କୁ ଆହୁରି ଖରାପ କରାଇଦିଏ |",
              "as": "তেওঁ ইণ্টাৰনেটত কিছুমান বস্তু পঢ়িবলৈ চেষ্টা কৰে যিয়ে তেওঁক সহায় কৰিব পাৰে, কিন্তু তেওঁ বিচাৰি পোৱা বস্তুবোৰে তেওঁক আৰু বেয়া অনুভৱ কৰায়।",
              "gu": "તે ઈન્ટરનેટ ઉપર કંઈ એવી બાબતો અંગે અભ્યાસ કરવાનો પ્રયત્ન કરે છે કે જે તેને મદદ કરી શકે છે, જોકે જે બાબત તેને મળે છે તે તેને વધારે ખરાબ અહેસાસ કરાવે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_2_2",
              "hi": "A1_2_2",
              "ka": "A1_2_2",
              "te": "A1_2_2",
              "or": "A1_2_2",
              "as": "A1_2_2",
              "gu": "A1_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He reads about how people like him are asked to leave home and stay in other communities.\nHe feels scared, and it gets difficult for Arvind to live every day.",
              "hi": "वह इस बारे में पढ़ता है कि उसके जैसे लोगों को घर छोड़ने और अन्य समुदायों में रहने के लिए कहा जाता है।\n वह डरता है, और हर दिन जीवित रहना उसके लिए कठिन हो जाता है।",
              "ka": "ಅವರಂತಹ ಜನರನ್ನು ಮನೆ ಬಿಟ್ಟು ಇತರ ಸಮುದಾಯಗಳಲ್ಲಿ ಉಳಿಯಲು ಹೇಗೆ ಕೇಳಲಾಗುತ್ತದೆ ಎಂಬುದರ ಕುರಿತು ಅವರು ಓದುತ್ತಾರೆ.\n ಅವನು ಹೆದರುತ್ತಾನೆ ಮತ್ತು ಅರವಿಂದನಿಗೆ ಪ್ರತಿದಿನ ಬದುಕುವುದು ಕಷ್ಟವಾಗುತ್ತದೆ.",
              "te": "తనలాంటి వ్యక్తులను ఇంటిని విడిచిపెట్టి ఇతర కమ్యూనిటీలలో ఉండమని ఎలా ఇబ్బంది పెడతారో అతను చదువుతాడు.\n అతను భయపడుతాడు మరియు అరవింద్‌కి రోజు రోజుకీ జీవించడం కష్టమవుతుంది.",
              "or": "ସେ ପଢନ୍ତି କିପରି ତାଙ୍କ ପରି ଲୋକଙ୍କୁ ଘର ଛାଡିବାକୁ ଏବଂ ଅନ୍ୟ ସମୁଦାୟରେ ରହିବାକୁ କୁହାଯାଏ | \n ସେ ଭୟଭୀତ ଅନୁଭବ କରନ୍ତି, ଏବଂ ଅରବିନ୍ଦଙ୍କୁ ପ୍ରତିଦିନ ବଞ୍ଚିବା କଷ୍ଟକର ହୋଇଯାଏ |",
              "as": "তেওঁৰ দৰে লোকসকলক কেনেদৰে ঘৰ এৰি আন সম্প্ৰদায়ত থাকিবলৈ কোৱা হয় সেই বিষয়ে তেওঁ পঢ়ে।\n তেওঁ ভয় খায়, আৰু অৰবিন্দৰ বাবে প্ৰতিদিনে জীয়াই থকাটো কঠিন হৈ পৰে।",
              "gu": "તે વાંચે છે કે કેવી રીતે તેના જેવા લોકોએ ઘર છોડીને અન્ય સમુદાયોમાં રહેવા માટે કહેવામાં આવ્યું છે. તેને ડર લાગે છે, અને અરવિંદ માટે દરેક દિવસ જીવન મુશ્કેલ બનતું જાય છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_1",
              "hi": "A1_3_1",
              "ka": "A1_3_1",
              "te": "A1_3_1",
              "or": "A1_3_1",
              "as": "A1_3_1",
              "gu": "A1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Meanwhile, Roshini has been studying Sociology at Osmania University. When Roshini comes home for her semester vacation, she sees Arvind sitting in his room quietly. She sits next to  Arvind and holds his hand tightly.",
              "hi": "इस बीच, रोशनी उस्मानिया विश्वविद्यालय में समाजशास्त्र का अध्ययन कर रही है। जब रोशनी अपने सेमेस्टर छुट्टी के लिए घर आती है, तो वह अपने कमरे में अरविंद को चुपचाप बैठे देखती है। वह अरविंद के बगल में बैठती है और अपना हाथ कसकर रखती है।",
              "ka": "ಏತನ್ಮಧ್ಯೆ, ರೋಶಿನಿ ಉಸ್ಮಾನಿಯಾ ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಸಮಾಜಶಾಸ್ತ್ರವನ್ನು ಓದುತ್ತಿದ್ದಾಳೆ. ರೋಶಿನಿ ತನ್ನ ಸೆಮಿಸ್ಟರ್ ರಜೆಗೆ ಮನೆಗೆ ಬಂದಾಗ, ಅರವಿಂದ್ ತನ್ನ ಕೋಣೆಯಲ್ಲಿ ಶಾಂತವಾಗಿ ಕುಳಿತಿರುವುದನ್ನು ನೋಡುತ್ತಾಳೆ. ಅವಳು ಅರವಿಂದನ ಪಕ್ಕದಲ್ಲಿ ಕುಳಿತು ಅವನ ಕೈಯನ್ನು ಗಟ್ಟಿಯಾಗಿ ಹಿಡಿದಿದ್ದಾಳೆ.",
              "te": "కాగా, రోష్ని ఉస్మానియా యూనివర్సిటీలో సోషియాలజీ చదువుతోంది. రోష్ని తన సెమిస్టర్ సెలవులకు ఇంటికి వచ్చినప్పుడు, అరవింద్ తన గదిలో నిశ్శబ్దంగా కూర్చోవడం చూస్తుంది. ఆమె అరవింద్ పక్కన కూర్చుని అతని చేతిని గట్టిగా పట్టుకుంటుంది.",
              "or": "ଏହି ସମୟରେ, ରୋଶିନି ଓସମାନିଆ ବିଶ୍ୱବିଦ୍ୟାଳୟରେ ସାମାଜିକ ବିଜ୍ଞାନ ଅଧ୍ୟୟନ କରୁଛନ୍ତି | ଯେତେବେଳେ ରୋଶିନି ତାଙ୍କ ସେମିଷ୍ଟର ଅବକାଶ ପାଇଁ ଘରକୁ ଆସନ୍ତି, ସେ ଅରବିନ୍ଦଙ୍କୁ ତାଙ୍କ ରୁମ୍‌ରେ ଚୁପଚାପ୍ ବସିଥିବାର ଦେଖନ୍ତି | ସେ ଅରବିନ୍ଦଙ୍କ ପାଖରେ ବସି ତାଙ୍କ ହାତକୁ ଜୋରରେ ଧରନ୍ତି |",
              "as": "ইফালে, ৰোশনিয়ে ওচমানিয়া বিশ্ববিদ্যালয়ত সমাজবিজ্ঞান অধ্যয়ন কৰি আছে। যেতিয়া ৰোশনি তাইৰ ছেমিষ্টাৰৰ বন্ধত ঘৰলৈ আহে, তাই অৰবিন্দক তেওঁৰ কোঠাত চুপচাপ বহি থকা দেখিলে। তাই অৰবিন্দৰ কাষত বহি তাৰ হাতখন জোৰেৰে ধৰে।",
              "gu": "દરમિયાન રોશની ઉસ્માનિયા યુનિવર્સિટીમાં સોશિયોલોજીનો અભ્યાસ કરી રહી છે.જ્યારે રોશની તેના સેમેસ્ટરમાં રજા લઈને ઘરે આવે છે તો તે જુએ છે કે અરવિંદ ચુપચાપ તેના રૂમમાં બેઠો છે.તે અરવિંદની બાજુમાં જઈને બેસી જાય છે અને તેનો હાથ સખત રીતે પકડે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_2////",
              "hi": "A1_3_2 ////////////",
              "ka": "A1_3_2////",
              "te": "A1_3_2////",
              "or": "A1_3_2////",
              "as": "A1_3_2////",
              "gu": "A1_3_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I feel so suffocated, Roshni. I'm too scared to do anything.",
              "hi": "मेरा बहुत दम घुटता है, रोशनी। मैं कुछ भी करने के लिए बहुत डर रहा हूँ।",
              "ka": "ನನಗೆ ತುಂಬಾ ಉಸಿರುಗಟ್ಟಿದೆ ರೋಶನಿ. ನಾನು ಏನು ಮಾಡಲು ತುಂಬಾ ಹೆದರುತ್ತೇನೆ.",
              "te": "నేను చాలా ఉక్కిరిబిక్కిరి అవుతున్నాను, రోష్ని. నేను ఏది చేయలన్నా చాలా భయమేస్తుంది.",
              "or": "ରୋଶନି, ମୋତେ ବହୁତ ଶ୍ୱାସରୁଦ୍ଧ ଅନୁଭବ ଲାଗୁଛି | ମୁଁ କିଛି କରିବାକୁ ବହୁତ ଡରୁଛି |",
              "as": "ৰোশনি, মোৰ ইমান শ্বাসৰুদ্ধ অনুভৱ হৈছে। মই যিকোনো কাম কৰিবলৈ বহুত ভয় কৰোঁ।",
              "gu": "મને ખૂબ જ અકડામણનો અહેસાસ થઈ રહ્યો છે. મને કંઈપણ કરતા ખૂબ જ ડર લાગી રહ્યો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "You take your time. There is no rush to do anything. We are all with you.",
              "hi": "तुम अपना पूरा समय लो। कुछ भी करने के लिए कोई जल्दी नहीं है। हम सब तुम्हारे साथ हैं।",
              "ka": "ನೀವು ನಿಮ್ಮ ಸಮಯವನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ. ಏನನ್ನೂ ಮಾಡುವ ಆತುರವಿಲ್ಲ. ನಾವೆಲ್ಲರೂ ನಿಮ್ಮೊಂದಿಗಿದ್ದೇವೆ.",
              "te": "నీకు కావలిసినంత సమయం తీసుకో. ఇప్పుడు ఏదైనా చేయాలనే తొందర లేదు. మేమంతా నీతో ఉన్నాం.",
              "or": "ତୁମେ ତୁମର ସମୟ ନିଅ | କିଛି କରିବାକୁ ତତ୍ପରତା ନାହିଁ | ଆମେ ସମସ୍ତେ ତୁମ ସହିତ ଅଛୁ |",
              "as": "আপুনি আপোনাৰ সময় লওক। যিকোনো কাম কৰিবলৈ কোনো খৰধৰ কৰিব নালাগে। আমি সকলো আপোনাৰ সৈতে আছোঁ।",
              "gu": "તુ થોડો સમય લે.કંઈપણ કરવામાં કોઈ જ ઉતાવળ નથી. અમે તારી સાથે છીએ."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_3",
              "hi": "A1_3_3",
              "ka": "A1_3_3",
              "te": "A1_3_3",
              "or": "A1_3_3",
              "as": "A1_3_3",
              "gu": "A1_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Seeing Arvind in this state, Roshini decides to do something. She reaches out to her college community for help.",
              "hi": "अरविंद की इस स्थिति को देखते हुए रोशनी ने कुछ करने का निश्चय किया। वह सहायता के लिए अपने कॉलेज समुदाय तक पहुंच जाती है।",
              "ka": "ಅರವಿಂದ್ ಅವರನ್ನು ಈ ಸ್ಥಿತಿಯಲ್ಲಿ ನೋಡಿದ ರೋಶಿನಿ ಏನಾದರೂ ಮಾಡಬೇಕೆಂದು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಅವಳು ಸಹಾಯಕ್ಕಾಗಿ ತನ್ನ ಕಾಲೇಜು ಸಮುದಾಯವನ್ನು ತಲುಪುತ್ತಾಳೆ.",
              "te": "అరవింద్‌ని ఈ స్థితిలో చూసిన రోష్ని ఏదో ఒకటి చేయాలని నిర్ణయించుకుంది. ఆమె సహాయం కోసం తన కాలేజీ కమ్యూనిటీ దగ్గరికి వెళ్తుంది.",
              "or": "ଏହି ଅବସ୍ଥାରେ ଅରବିନ୍ଦଙ୍କୁ ଦେଖି, ରୋଶିନି କିଛି କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି। ସେ ତାଙ୍କ କଲେଜ ସମୁଦାୟ ପାଖରେ ସାହାଯ୍ୟ ପାଇଁ ପହଞ୍ଚନ୍ତି |",
              "as": "এই অৱস্থাত অৰবিন্দক দেখি ৰোশনিয়ে কিবা এটা কৰাৰ সিদ্ধান্ত লয়। তাই সহায়ৰ বাবে তাইৰ কলেজ সম্প্ৰদায়ৰ ওচৰলৈ গৈছিল।",
              "gu": "અરવિંદે આ સ્થિતિમાં જોઈ રોશની કંઈક કરી છૂટવાનો નિર્ણય કરે છે. તે મદદ માટે પોતાની કોલેજના સમુદાય સુધી પહોંચે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_4_msgblock",
              "hi": "A1_3_4_एमएसजीब्लॉक ",
              "ka": "A1_3_4_msgblock",
              "te": "A1_3_4_msgblock",
              "or": "A1_3_4_msgblock",
              "as": "A1_3_4_মেচেজব্লক",
              "gu": "A1_3_4_msgblock"
            }
          },
          {
            "valuePath": "blocks.0.props.allMessages.0.messageText",
            "value": {
              "en": "Hello, I am looking for information about a counsellor or therapist who has experience talking to people from across the gender spectrum or people from the LGBTQ+ community. Someone I know has been facing harassment due to their gender identity.",
              "hi": "हैलो, मैं एक परामर्शदाता या चिकित्सक के बारे में जानकारी ढूंढ रही हूं, जिसने लिंग स्पेक्ट्रम या एलजीबीटीक्यू + समुदाय के लोगों से लोगों से बात करने का अनुभव किया है। मैं किसी को जानती हूँ, वह अपनी लिंग पहचान के कारण उत्पीड़न का सामना कर रहा है।",
              "ka": "ಹಲೋ, ಲಿಂಗ ಸ್ಪೆಕ್ಟ್ರಮ್‌ನಾದ್ಯಂತ ಇರುವ ಜನರು ಅಥವಾ LGBTQ+ ಸಮುದಾಯದ ಜನರೊಂದಿಗೆ ಮಾತನಾಡುವ ಅನುಭವ ಹೊಂದಿರುವ ಸಲಹೆಗಾರರು ಅಥವಾ ಚಿಕಿತ್ಸಕರ ಕುರಿತು ನಾನು ಮಾಹಿತಿಯನ್ನು ಹುಡುಕುತ್ತಿದ್ದೇನೆ. ನನಗೆ ತಿಳಿದಿರುವ ಯಾರಾದರೂ ತಮ್ಮ ಲಿಂಗ ಗುರುತಿನ ಕಾರಣದಿಂದ ಕಿರುಕುಳವನ್ನು ಎದುರಿಸುತ್ತಿದ್ದಾರೆ.",
              "te": "హలో, నేను లింగ స్పెక్ట్రమ్‌లోని వ్యక్తులతో లేదా LGBTQ+ కమ్యూనిటీకి చెందిన వ్యక్తులతో మాట్లాడిన అనుభవం ఉన్న కౌన్సెలర్ లేదా థెరపిస్ట్ గురించిన సమాచారం కోసం వెతుకుతున్నాను. నాకు తెలిసిన ఒకరు వారి లింగ గుర్తింపు కారణంగా వేధింపులను ఎదుర్కొంటున్నారు.",
              "or": "ନମସ୍କାର, ମୁଁ ଜଣେ ପରାମର୍ଶଦାତା କିମ୍ବା ଥେରାପିଷ୍ଟ ବିଷୟରେ ସୂଚନା ଖୋଜୁଛି, ଯାହାର ଲିଙ୍ଗ ସ୍ପେକ୍ଟ୍ରମର କିମ୍ବା LGBTQ + ସମୁଦାୟର ଲୋକଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବାର ଅଭିଜ୍ଞତା ଅଛି | ମୁଁ ଜାଣେ କେହି ଜଣେ ତାଙ୍କର ଲିଙ୍ଗ ପରିଚୟ ହେତୁ ଉତ୍ପୀଡ଼ନର ସମ୍ମୁଖୀନ ହେଉଛନ୍ତି |",
              "as": "নমস্কাৰ, মই এজন পৰামৰ্শদাতা বা থেৰাপিষ্টৰ বিষয়ে তথ্য বিচাৰি আছোঁ যাৰ লিংগ স্পেকট্ৰামৰ লোক বা LGBTQ+ সম্প্ৰদায়ৰ লোকসকলৰ সৈতে কথা পতাৰ অভিজ্ঞতা আছে। মোৰ চিনাকি কোনোবা এজনে তেওঁলোকৰ লিংগ পৰিচয়ৰ বাবে নিৰ্যাতনৰ সন্মুখীন হৈছে।",
              "gu": "નમસ્કે, હું એક કાઉન્સીલર અથવા થેરાપિસ્ટ અંગે માહિતી શોધી રહી છું કે જેમની પાસે લિંગ સ્પેક્ટ્રમના લોકો અથવા LGBTQ+સમુદાયના લોકો સાથે વાતચીત કરવાનો અનુભવ ધરાવે છે. મારા એક સંબંધિને તેની લૈંગિક ઓળખને લીધે ઉત્પીડનનો સામનો કરવો પડી રહ્યો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.allMessages.1.messageText",
            "value": {
              "en": "Hi Roshni, so glad you reached out. We have been running an LGBTQ+ support group for some time now. Can they join us for a few sessions?",
              "hi": "हाय रोशनी, बहुत खुशी है कि आप यहाँ तक पहुंचीं। हम कुछ समय के लिए एक एलजीबीटीक्यू + सपोर्ट ग्रुप चला रहे हैं। क्या वे कुछ सत्रों के लिए हमसे जुड़ सकते हैं?",
              "ka": "ಹಾಯ್ ರೋಶ್ನಿ, ನೀವು ತಲುಪಿದ್ದಕ್ಕೆ ತುಂಬಾ ಖುಷಿಯಾಗಿದೆ. ನಾವು ಸ್ವಲ್ಪ ಸಮಯದಿಂದ LGBTQ+ ಬೆಂಬಲ ಗುಂಪನ್ನು ನಡೆಸುತ್ತಿದ್ದೇವೆ. ಅವರು ಕೆಲವು ಸೆಷನ್‌ಗಳಿಗೆ ನಮ್ಮೊಂದಿಗೆ ಸೇರಬಹುದೇ?",
              "te": "హాయ్ రోష్ని, నువ్వు వచ్చినందుకు చాలా ఆనందంగా ఉంది. మేము కొంతకాలంగా LGBTQ+ సపోర్ట్ గ్రూప్ నడుపుతున్నాము. వారు కొన్ని సెషన్ల కోసం మాతో చేరగలరా?",
              "or": "ହାଏ ରୋଶନି, ତୁମେ ପହଞ୍ଚିଥିବାରୁ ବହୁତ ଖୁସି | ଆମେ କିଛି ସମୟ ହେଲା ଏକ LGBTQ + ସମର୍ଥନ ସମୁଦାୟ ଚଳାଉଛୁ | ସେମାନେ କିଛି ଅଧିବେଶନ ପାଇଁ ଆମ ସହିତ ଯୋଗଦାନ କରିପାରିବେ କି ?",
              "as": "হাই ৰোশনি, আপুনি অহাৰ বাবে ভাল লাগিল। আমি কিছু সময়ৰ পৰা LGBTQ+ সমৰ্থন গোট এটা চলাই আছো। তেওঁলোকে কেইটামান অধিৱেশনৰ বাবে আমাৰ সৈতে যোগ দিব পাৰিবনে?",
              "gu": "હાય રોશની, તારા સુધી પહોંચવા બદલ મને ઘણી ખુશી થઈ. અમે છેલ્લા કેટલાક સમયથી LGBTQ+ સપોર્ટ ગ્રુપ ચલાવી રહ્યા છીએ.શું તેઓ થોડા સત્રો માટે આપણી સાથે જોડાઈ શકે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.allMessages.2.messageText",
            "value": {
              "en": "Hi Tarini, thanks. Can you send me the details of your support group? I will share the details with them and let you know.",
              "hi": "हाय तारिनी, धन्यवाद। क्या आप मुझे अपने समर्थन समूह का विवरण भेज सकती हैं? मैं उनके साथ विवरण साझा करूंगी और आपको बता दूंगी।",
              "ka": "ನಮಸ್ಕಾರ ತಾರಿಣಿ, ಧನ್ಯವಾದಗಳು. ನಿಮ್ಮ ಬೆಂಬಲ ಗುಂಪಿನ ವಿವರಗಳನ್ನು ನನಗೆ ಕಳುಹಿಸಬಹುದೇ? ನಾನು ಅವರೊಂದಿಗೆ ವಿವರಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತೇನೆ ಮತ್ತು ನಿನಗೆ ತಿಳಿಸುತ್ತೇನೆ.",
              "te": "హాయ్ తరిణి, ధన్యవాదాలు. మీరు మీ సపోర్ట్ గ్రూప్ వివరాలను నాకు పంపగలరా? నేను వారికి వివరాలను పంపి మీకు తెలియజేస్తాను.",
              "or": "ହାଏ ତାରିଣୀ, ଧନ୍ୟବାଦ | ଆପଣ ମୋତେ ଆପଣଙ୍କର ସମର୍ଥନ ସମୁଦାୟର ବିବରଣୀ ପଠାଇ ପାରିବେ କି ? ମୁଁ ସେମାନଙ୍କ ସହିତ ବିବରଣୀ ଅଂଶୀଦାର କରିବି ଏବଂ ଆପଣଙ୍କୁ ଜଣାଇବି |",
              "as": "হাই তাৰিণী, ধন্যবাদ। আপুনি আপোনাৰ সমৰ্থন গোটৰ বিৱৰণ মোলৈ প্ৰেৰণ কৰিব পাৰিবনে? মই তেওঁলোকৰ সৈতে বিৱৰণবোৰ আলোচনা কৰিম আৰু আপোনাক জনাম।",
              "gu": "હાય તારિણી, તારો ખૂબ ખૂબ આભાર. શું તું તમારા સપોર્ટ ગ્રુપને લગતી માહિતી મને મોકલી શકીશ?હું આ વિગતો તેના સુધી પહોંચાડીશ અને તને આ અંગે જાણ કરીશ."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_5",
              "hi": "A1_3_5",
              "ka": "A1_3_5",
              "te": "A1_3_5",
              "or": "A1_3_5",
              "as": "A1_3_5",
              "gu": "A1_3_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Roshni finds out that support groups are people from different backgrounds who support each other regarding their gender identity and sexual orientation.",
              "hi": "रोशनी पता लगाती है कि समर्थन समूह विभिन्न पृष्ठभूमि के लोग हैं जो एक दूसरे को उनके लिंग पहचान और यौन अभिविन्यास के संबंध में समर्थन करते हैं।",
              "ka": "ಬೆಂಬಲ ಗುಂಪುಗಳು ತಮ್ಮ ಲಿಂಗ ಗುರುತಿಸುವಿಕೆ ಮತ್ತು ಲೈಂಗಿಕ ದೃಷ್ಟಿಕೋನಕ್ಕೆ ಸಂಬಂಧಿಸಿದಂತೆ ಪರಸ್ಪರ ಬೆಂಬಲಿಸುವ ವಿಭಿನ್ನ ಹಿನ್ನೆಲೆಯ ಜನರು ಎಂದು ರೋಶ್ನಿ ಕಂಡುಕೊಂಡಿದ್ದಾರೆ.",
              "te": "సపోర్ట్ గ్రూప్‌లు అంటే వారి లింగ గుర్తింపు మరియు లైంగిక ధోరణి ఆధారంగా ఒకరికొకరు మద్దతు ఇచ్చే విభిన్న నేపథ్యాలకు చెందిన వ్యక్తులు అని రోష్ని తెలుసుకుంటుంది.",
              "or": "ରୋଶନି ଜାଣିବାକୁ ପାଆନ୍ତି ଯେ ସମର୍ଥନ ସମୁଦାୟ ହେଉଛି ବିଭିନ୍ନ ପୃଷ୍ଠଭୂମିରୁ ଆସିଥିବା ବ୍ୟକ୍ତି ଯେଉଁମାନେ ସେମାନଙ୍କର ଲିଙ୍ଗ ପରିଚୟ ଏବଂ ଯୌନ ଆଭିମୁଖ୍ୟ ସମ୍ବନ୍ଧରେ ପରସ୍ପରକୁ ସମର୍ଥନ କରନ୍ତି |",
              "as": "ৰোশনিয়ে গম পাইছে যে সমৰ্থন গোটৰ লোকসকল বিভিন্ন পৃষ্ঠভূমিৰ লোক যিসকলে তেওঁলোকৰ লিংগ পৰিচয় আৰু যৌন অভিমুখীতা সম্পৰ্কে ইজনে সিজনক সমৰ্থন কৰে।",
              "gu": "રોશનીને માલુમ થાય છે કે સહાયતા સમૂહ વિવિધ પુષ્ટભૂમિના વિવિધ લોકો છે, જે તેમની લિંગની ઓળખ તથા સેક્સ્યુઅલ ઓએન્ટેશનનું સમર્થન કરે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_6",
              "hi": "A1_3_6",
              "ka": "A1_3_6",
              "te": "A1_3_6",
              "or": "A1_3_6",
              "as": "A1_3_6",
              "gu": "A1_3_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She shares this with Arvind. Although he is initially reluctant, he is happy to hear that people in the group would understand him.",
              "hi": "वह इसे अरविंद के साथ साझा करती है। यद्यपि वह शुरुआत में अनिच्छुक है, लेकिन वह यह सुनकर खुश है कि समूह में लोग उसे समझेंगे।",
              "ka": "ಇದನ್ನು ಅರವಿಂದ್ ಜೊತೆ ಹಂಚಿಕೊಂಡಿದ್ದಾಳೆ. ಆರಂಭದಲ್ಲಿ ಹಿಂಜರಿಯುತ್ತಿದ್ದರೂ, ಗುಂಪಿನಲ್ಲಿರುವ ಜನರು ಅವನನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ ಎಂದು ಕೇಳಲು ಅವನು ಸಂತೋಷಪಡುತ್ತಾನೆ.",
              "te": "ఈ విషయాన్ని ఆమె అరవింద్‌ కి చెప్తుంది. మొదట్లో అయిష్టంగానే ఉన్నా, గ్రూప్ లోని వ్యక్తులు తనను అర్థం చేసుకుంటారని విని సంతోషించాడు.",
              "or": "ସେ ଏହା ଅରବିନ୍ଦଙ୍କ ସହ ଅଂଶୀଦାର କରନ୍ତି | ଯଦିଓ ସେ ପ୍ରଥମେ ଅନିଚ୍ଛା ପ୍ରକାଶ କରନ୍ତି, ସେ ଶୁଣି ଖୁସି ହୁଅନ୍ତି ଯେ ସମୁଦାୟର ଲୋକମାନେ ତାଙ୍କୁ ବୁଝିପାରିବେ |",
              "as": "তাই এই কথাটো অৰবিন্দৰ সৈতে আলোচনা কৰে। যদিও তেওঁ প্ৰথমতে ইচ্ছা কৰা নাছিল, তেওঁ শুনি সুখী হয় যে গোটটোৰ লোকসকলে তেওঁক বুজি পাব।",
              "gu": "તે આ માહિતી અરવિંદ સાથે શેર કરે છે. જોકે તે શરૂઆતમાં આ માટે નિરુત્સાહી હતી, પણ તેને સાંભળીને ખુશી થાય છે કે સમૂહના લોકો તેને સમજશે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_3_7",
              "hi": "A1_3_7",
              "ka": "A1_3_7",
              "te": "A1_3_7",
              "or": "A1_3_7",
              "as": "A1_3_7",
              "gu": "A1_3_7"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After Roshni motivates him a little, he decides to try the support group.",
              "hi": "रोशनी के उसे थोड़ा प्रेरित करने के बाद, वह समर्थन समूह को आजमाने का निश्चय करता है।",
              "ka": "ರೋಶ್ನಿ ಅವನನ್ನು ಸ್ವಲ್ಪ ಪ್ರೇರೇಪಿಸಿದ ನಂತರ, ಅವನು ಬೆಂಬಲ ಗುಂಪನ್ನು ಪ್ರಯತ್ನಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "రోష్ని అతనిని కొద్దిగా ప్రేరేపించిన తర్వాత, అతను సపోర్ట్ గ్రూప్‌ని ప్రయత్నించాలని నిర్ణయించుకున్నాడు.",
              "or": "ରୋଶନି ତାଙ୍କୁ ଟିକେ ଉତ୍ସାହିତ କରିବା ପରେ, ସେ ସମର୍ଥନ ସମୁଦାୟକୁ ଚେଷ୍ଠା କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
              "as": "ৰোশনীয়ে তেওঁক অলপ অনুপ্ৰাণিত কৰাৰ পিছত, তেওঁ সমৰ্থন গোটটো চেষ্টা কৰাৰ সিদ্ধান্ত লয়।",
              "gu": "રોશની દ્વારા તેને થોડું પ્રોત્સાહન આપ્યાં બાદ, તે સહાયતા સમૂહ સાથે જોડાવાનો નિર્ણય કરે છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_4_1",
              "hi": "A1_4_1",
              "ka": "A1_4_1",
              "te": "A1_4_1",
              "or": "A1_4_1",
              "as": "A1_4_1",
              "gu": "A1_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The next day, Arvind attends the meeting at the youth centre in their town, and Arvind is late for the meeting.",
              "hi": "अगले दिन, अरविंद अपने शहर के युवा केंद्र की बैठक में भाग लेता है, और  अरविंद को बैठक के लिए  देर हो चुकी है।",
              "ka": "ಮರುದಿನ ಅರವಿಂದ್ ತಮ್ಮ ಊರಿನ ಯುವ ಕೇಂದ್ರದಲ್ಲಿ ಸಭೆಗೆ ಹಾಜರಾಗುತ್ತಾರೆ, ಮತ್ತು ಅರವಿಂದ್ ಸಭೆಗೆ ತಡವಾಗಿ ಬರುತ್ತಾರೆ.",
              "te": "మరుసటి రోజు, అరవింద్ తమ పట్టణంలోని యూత్ సెంటర్ లో మీటింగ్‌కు హాజరవుతాడు, అరవింద్ సమావేశానికి ఆలస్యంగా వెళ్తాడు.",
              "or": "ପରଦିନ, ଅରବିନ୍ଦ ସେମାନଙ୍କ ସହରର ୟୁଥ୍‌ ସେଣ୍ଟର୍‌ ଠାରେ ବୈଠକରେ ଯୋଗ ଦିଅନ୍ତି, ଏବଂ ଅରବିନ୍ଦ ବୈଠକ ପାଇଁ ବିଳମ୍ବ ହୁଅନ୍ତି |",
              "as": "পিছদিনা, অৰবিন্দে তেওঁলোকৰ চহৰৰ যুৱ কেন্দ্ৰৰ সভালৈ যায়, আৰু অৰবিন্দৰ সভালৈ পলম হয়।",
              "gu": "ત્યારપછીના દિવસે, અરવિંદ તેના શહેરના યુવા કેન્દ્રમાં બેઠકમાં ભાગ લે છે, અને અરવિંદને બેઠકમાં ભાગ લેવામાં થોડો વિલંબ થઈ જાય છે."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_4_2",
              "hi": "A1_4_2",
              "ka": "A1_4_2",
              "te": "A1_4_2",
              "or": "A1_4_2",
              "as": "A1_4_2",
              "gu": "A1_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He peeks into the room and sees that people have already taken their seats. He quietly takes a seat at the back of the room.",
              "hi": "वह कमरे में झांकता है और देखता है कि लोग पहले ही अपना स्थान ग्रहण कर चुके हैं। वह चुपचाप कमरे में पीछे बैठ जाता है।",
              "ka": "ಅವನು ಕೋಣೆಯೊಳಗೆ ಇಣುಕಿ ನೋಡುತ್ತಾನೆ ಮತ್ತು ಜನರು ಈಗಾಗಲೇ ತಮ್ಮ ಸ್ಥಾನಗಳನ್ನು ತೆಗೆದುಕೊಂಡಿದ್ದಾರೆ. ಅವನು ಸದ್ದಿಲ್ಲದೆ ಕೋಣೆಯ ಹಿಂಭಾಗದಲ್ಲಿ ಆಸನವನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "అతను గదిలోకి ప్రవేశిస్తూ, అందరూ అప్పటికే తమ సీట్లను తీసుకున్నారని చూశాడు. అతను నిశ్శబ్దంగా గది వెనుక ఒక సీటు తీసుకుంటాడు.",
              "or": "ସେ ରୁମ୍‌ ଭିତରକୁ ଚାହାଁନ୍ତି ଏବଂ ଦେଖନ୍ତି ଯେ ଲୋକମାନେ ନିଜ ସିଟ୍‍ ନେଇସାରିଛନ୍ତି | ସେ ଚୁପଚାପ୍ ରୁମ୍ ପଛପଟେ ଏକ ସିଟ୍‍ ନିଅନ୍ତି |",
              "as": "তেওঁ কোঠাটোলৈ জুমি চাই দেখিলে যে মানুহে ইতিমধ্যে তেওঁলোকৰ আসন লৈছে। তেওঁ মনে মনে কোঠাটোৰ পিছফালে এখন আসন লয়।",
              "gu": "તે રૂમમાં જુએ છે અને માલુમ થાય છે કે લોકોએ અગાઉથી જ તેમની સીટ લઈ લીધેલી છે.તે ચુપચાપ રૂમની પાછળના ભાગમાં ત્વરીતપણે સીટ લઈ લે છે."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_4_3//",
              "hi": "A1_4_3 //",
              "ka": "A1_4_3//",
              "te": "A1_4_3//",
              "or": "A1_4_3//",
              "as": "A1_4_3//",
              "gu": "A1_4_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Session Moderator",
              "hi": "सत्र मॉडरेटर",
              "ka": "ಸೆಷನ್ ಮಾಡರೇಟರ್",
              "te": "సెషన్ మోడరేటర్",
              "or": "ସେଶନ ମୋଡରେଟର୍",
              "as": "অধিবেশন সঞ্চালক",
              "gu": "સત્રના મધ્યસ્થી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Hello everyone, I'm so happy to see all of you again this week. I also see some new faces. My name is Vandana. My pronouns are 'She/Her'. Could we do a quick round of introductions?",
              "hi": "सभी को नमस्कार, मैं इस सप्ताह फिर से आप सभी को देखकर बहुत खुश हूं। मैं कुछ नए चेहरे भी देखती  हूं। मेरा नाम वंदना है। मेरे सर्वनाम 'वह / उसकी' हैं। क्या हम जल्दी - जल्दी एक दूसरे से परिचित हो सकते हैं?",
              "ka": "ಎಲ್ಲರಿಗೂ ನಮಸ್ಕಾರ, ಈ ವಾರ ನಿಮ್ಮೆಲ್ಲರನ್ನೂ ಮತ್ತೆ ನೋಡಲು ನನಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿದೆ. ನಾನು ಕೆಲವು ಹೊಸ ಮುಖಗಳನ್ನೂ ನೋಡುತ್ತೇನೆ. ನನ್ನ ಹೆಸರು ವಂದನಾ. ನನ್ನ ಸರ್ವನಾಮಗಳು 'ಅವಳು/ಅವಳು'. ನಾವು ತ್ವರಿತ ಸುತ್ತಿನ ಪರಿಚಯಗಳನ್ನು ಮಾಡಬಹುದೇ?",
              "te": "అందరికీ నమస్కారం, ఈ వారం మీ అందరినీ మళ్లీ చూడడం నాకు చాలా సంతోషంగా ఉంది. నేను కొన్ని కొత్త ముఖాలను కూడా చూస్తున్నాను. నా పేరు వందన. నా సర్వనామాలు 'ఆమె/తను'. మనము ఒక రౌండ్ పరిచయాలు చేసుకుందామా?",
              "or": "ସମସ୍ତଙ୍କୁ ନମସ୍କାର, ଏହି ସପ୍ତାହରେ ଆପଣ ସମସ୍ତଙ୍କୁ ପୁଣି ଦେଖି ମୁଁ ବହୁତ ଖୁସି | ମୁଁ କିଛି ନୂଆ ଚେହେରା ମଧ୍ୟ ଦେଖୁଛି | ମୋର ନାମ ବନ୍ଦନା | ମୋର ସର୍ବନାମଗୁଡ଼ିକ ହେଉଛି 'ସେ/ତାଙ୍କ' | ଆମେ ଏକ ଶୀଘ୍ର ପରିଚୟ ପ୍ରଦାନ କରିପାରିବା କି ?",
              "as": "সকলোকে নমস্কাৰ জনাইছো, এই সপ্তাহত আপোনালোক সকলোকে পুনৰ দেখি মই বহুত সুখী। মই কিছুমান নতুন মুখো দেখিছো। মোৰ নাম বন্দনা। মোৰ সৰ্বনামবোৰ হৈছে 'তাই/তাইৰ'। আমি সকলোৱে সোনকালে পৰিচয়বোৰ দিব পাৰোঁনে?",
              "gu": "સૌને નમસ્તે, આ સપ્તાહે તમને સૌને જોઈને મને ઘણી ખુશી થઈ રહી છે. મને કેટલાક નવા ચહેરા પણ દેખાઈ રહ્યા છે. મારું નામ વંદના છે.મારું સર્વનામ 'તેણીની/તે' છે.શું આપણે ઝડપભેર આપણો પરિચય આપી શકીએ છીએ?"
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_4_4",
              "hi": "A1_4_4",
              "ka": "A1_4_4",
              "te": "A1_4_4",
              "or": "A1_4_4",
              "as": "A1_4_4",
              "gu": "A1_4_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Everyone introduces themselves while stating their pronouns. Some say 'He/Him', some say 'She/Her', and others say 'They/Them'. Arvind is confused.",
              "hi": "हर कोई अपने सर्वनाम बताते हुए खुद को प्रस्तुत करता है। कुछ कहते हैं 'वह / उसका', कुछ कहते हैं 'वह / उसकी', और अन्य कहते हैं 'वे / वे'। अरविंद को  समझ नहीं आ रहा है।",
              "ka": "ಪ್ರತಿಯೊಬ್ಬರೂ ತಮ್ಮ ಸರ್ವನಾಮಗಳನ್ನು ಹೇಳುವಾಗ ತಮ್ಮನ್ನು ಪರಿಚಯಿಸಿಕೊಳ್ಳುತ್ತಾರೆ. ಕೆಲವರು 'ಅವನು/ಅವನು' ಎಂದು ಹೇಳುತ್ತಾರೆ, ಕೆಲವರು 'ಅವಳು/ಅವಳು' ಎಂದು ಹೇಳುತ್ತಾರೆ, ಮತ್ತು ಇತರರು 'ಅವರು/ಅವರು' ಎಂದು ಹೇಳುತ್ತಾರೆ. ಅರವಿಂದ್ ಗೊಂದಲದಲ್ಲಿದ್ದಾರೆ.",
              "te": "ప్రతి ఒక్కరూ తమ సర్వనామాలను చెప్పుకుంటూ తమను తాము పరిచయం చేసుకుంటారు. కొందరు 'అతడు/తను' అని, మరికొందరు 'ఆమె/తను' అని, మరికొందరు 'వారు/వారు' అని అంటారు. అరవింద్ కన్ఫ్యూస్ అయ్యాడు.",
              "or": "ସମସ୍ତେ ନିଜକୁ ପରିଚୟ କରାଇବା ସମୟରେ ନିଜର ସର୍ବନାମ ଜଣାନ୍ତି | କେହି 'ସେ/ତାଙ୍କୁ' କୁହନ୍ତି, କେହି କେହି 'ସେ/ତାଙ୍କୁ' କୁହନ୍ତି, ଏବଂ ଅନ୍ୟମାନେ କୁହନ୍ତି 'ସେମାନେ/ସେମାନଙ୍କୁ' କୁହନ୍ତି | ଅରବିନ୍ଦ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛନ୍ତି |",
              "as": "সকলোৱে তেওঁলোকৰ সৰ্বনাম উল্লেখ কৰোঁতে নিজৰ পৰিচয় দিয়ে। কিছুমানে কয় 'তেওঁ/তেওঁক', কিছুমানে কয় 'সি/ তাক', আৰু আন কিছুমানে কয় 'তেওঁলোক/তেওঁলোকক'। অৰবিন্দ বিভ্ৰান্ত হৈছে।",
              "gu": "કોઈપણ પોતાના સર્વનામ કહે ત્યારે પોતાના પરિચય આપે છે. કેટલાક કહે છે 'તેણ/તેણીની, પોતાના અન્ય કહે છે 'તે/તેણીની' કહે છે. અરવિંદ ભ્રમિત છે."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_4_5////",
              "hi": "A1_4_5 ///////////",
              "ka": "A1_4_5////",
              "te": "A1_4_5////",
              "or": "A1_4_5////",
              "as": "A1_4_5////",
              "gu": "A1_4_5////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Finally, it is Arvind’s turn. He stands up nervously.",
              "hi": "अंत में, अब अरविंद की बारी है। वह घबराया हुआ खड़ा है।",
              "ka": "ಕೊನೆಗೂ ಅರವಿಂದ್ ಸರದಿ. ಅವನು ಉದ್ವಿಗ್ನನಾಗಿ ಎದ್ದು ನಿಲ್ಲುತ್ತಾನೆ.",
              "te": "చివరగా, ఇది అరవింద్ వంతు. అతను భయంగా లేచి నిలబడి ఉన్నాడు.",
              "or": "ଶେଷରେ, ଏହା ଅରବିନ୍ଦଙ୍କ ପାଳି | ସେ ନର୍ଭସ୍‌ ହୋଇ ଠିଆ ହୁଅନ୍ତି |",
              "as": "অৱশেষত, অৰবিন্দৰ পাল। তেওঁ উদ্বিগ্ন হৈ থিয় হ'ল।",
              "gu": "છેવટે અરવિંદનો વારો છે.તે ગભરાઈને ઉભો થઈ જાય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "My name is Arvind. My pronouns are… 'he/him'... I think.",
              "hi": "मेरा नाम अरविंद है। मेरे सर्वनाम हैं ... 'वह / उसे' ... मुझे लगता है।",
              "ka": "ನನ್ನ ಹೆಸರು ಅರವಿಂದ್. ನನ್ನ ಸರ್ವನಾಮಗಳು... 'ಅವನು/ಅವನು'... ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "నా పేరు అరవింద్. నా సర్వనామాలు... 'అతడు/తను'..అని నేను అనుకుంటున్నాను.",
              "or": "ମୋର ନାମ ଅରବିନ୍ଦ | ମୋର ସର୍ବନାମଗୁଡ଼ିକ ହେଉଛି… 'ସେ /ତାଙ୍କୁ' ... ମୁଁ ଭାବୁଛି |",
              "as": "মোৰ নাম অৰবিন্দ। মোৰ সৰ্বনামবোৰ হৈছে...'সি/ তাক'... মই ভাবোঁ।",
              "gu": "મારું નામ અરવિંદ છે. મારું પ્રોનાઉન્ટ એ…'તે/તેણીની'….છે. મારા મતે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Thank you, Arvind. So, who would like to share first?",
              "hi": "धन्यवाद, अरविंद। तो, पहले कौन साझा करना चाहेगा?",
              "ka": "ಧನ್ಯವಾದಗಳು, ಅರವಿಂದ್. ಹಾಗಾದರೆ, ಯಾರು ಮೊದಲು ಹಂಚಿಕೊಳ್ಳಲು ಬಯಸುತ್ತಾರೆ?",
              "te": "ధన్యవాదాలు, అరవింద్. కాబట్టి, ఎవరు ముందుగా మాట్లాడాలి అనుకుంటున్నారు?",
              "or": "ଧନ୍ୟବାଦ, ଅରବିନ୍ଦ | ତେବେ, କିଏ ପ୍ରଥମେ ଅଂଶୀଦାର କରିବାକୁ ଚାହିଁବେ ?",
              "as": "ধন্যবাদ, অৰবিন্দ। গতিকে, কোনে প্ৰথমে কথা পাতিব বিচাৰে?",
              "gu": "તારો આભાર, અરવિંદ. તો કોણ સૌ પ્રથમ શેર કરવાનું પસંદ કરશે?"
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_4_6",
              "hi": "A1_4_6",
              "ka": "A1_4_6",
              "te": "A1_4_6",
              "or": "A1_4_6",
              "as": "A1_4_6",
              "gu": "A1_4_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "In the next one hour, people take turns to talk about what they have been struggling with lately.",
              "hi": "अगले एक घंटे में, लोग बारी-बारी से बात करते हैं कि वे हाल ही में किन परिस्थितियों से जूझ रहे हैं।",
              "ka": "ಮುಂದಿನ ಒಂದು ಗಂಟೆಯಲ್ಲಿ, ಜನರು ಇತ್ತೀಚೆಗೆ ಏನು ಕಷ್ಟಪಡುತ್ತಿದ್ದಾರೆ ಎಂಬುದರ ಕುರಿತು ಮಾತನಾಡಲು ಸರದಿ ತೆಗೆದುಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "తర్వాతి ఒక గంటసేపు, అందరూ ఇంతకాలం కష్టపడుతున్న దాని గురించి మాట్లాడతారు.",
              "or": "ପରବର୍ତ୍ତୀ ଏକ ଘଣ୍ଟା ମଧ୍ୟରେ, ପରବର୍ତ୍ତୀ ଏକ ଘଣ୍ଟା ମଧ୍ୟରେ, ଲୋକମାନେ ପାଳିରେ କୁହନ୍ତି ଯେ ସେମାନେ ନିକଟ ଅତୀତରେ କ’ଣ ସଂଘର୍ଷ କରୁଛନ୍ତି |",
              "as": "পৰৱৰ্তী এক ঘণ্টাত, মানুহে শেহতীয়াকৈ কিহৰ সৈতে যুঁজি আছে সেই বিষয়ৰ ওপৰত তেওঁলোকে পাল পাতি পাতি কথা পাতে।",
              "gu": "આગામી એક કલાકમાં, વારંવાર વાત કરે છે કે તાજેતરમાં જ કોનો સામનો કરે છે."
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_4_7",
              "hi": "A1_4_7",
              "ka": "A1_4_7",
              "te": "A1_4_7",
              "or": "A1_4_7",
              "as": "A1_4_7",
              "gu": "A1_4_7"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Everyone in the group is dealing with issues regarding how they are treated or how they felt about their gender and sexuality.",
              "hi": "समूह में हर कोई इस समस्या का सामना कर रहा है कि उनके साथ कैसा व्यवहार किया जाता है या वे अपने लिंग और सैक्सुएलिटी के बारे में कैसा अनुभव करते हैं।",
              "ka": "ಗುಂಪಿನಲ್ಲಿರುವ ಪ್ರತಿಯೊಬ್ಬರೂ ಅವರನ್ನು ಹೇಗೆ ಪರಿಗಣಿಸಲಾಗುತ್ತದೆ ಅಥವಾ ಅವರ ಲಿಂಗ ಮತ್ತು ಲೈಂಗಿಕತೆಯ ಬಗ್ಗೆ ಅವರು ಹೇಗೆ ಭಾವಿಸುತ್ತಾರೆ ಎಂಬುದರ ಕುರಿತು ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುತ್ತಿದ್ದಾರೆ.",
              "te": "గ్రూప్ లోని ప్రతి ఒక్కరూ వారిని ఎలా చూస్తున్నారు లేదా వారి లింగం మరియు లైంగికత గురించి వారు ఎలా భావించారు అనే విషయాలు అనుభవించారు.",
              "or": "ସେମାନଙ୍କ ସହିତ କିପରି ବ୍ୟବହାର କରାଯାଏ କିମ୍ବା ସେମାନଙ୍କର ଲିଙ୍ଗ ଏବଂ କାମ ପ୍ରବୃତ୍ତି ବିଷୟରେ ସେମାନେ କିପରି ଅନୁଭବ କରନ୍ତି ସମ୍ବନ୍ଧୀୟ ସମସ୍ୟାର ସମୁଦାୟର ସମସ୍ତେ ମୁକାବିଲା କରୁଛନ୍ତି |",
              "as": "গোটটোৰ সকলোৱে তেওঁলোকৰ সৈতে কেনে ব্যৱহাৰ কৰা হয় বা তেওঁলোকে লিংগ আৰু যৌনতাৰ বিষয়ে কেনে অনুভৱ কৰে সেই বিষয়বোৰৰ সৈতে মোকাবিলা কৰি আছে।",
              "gu": "સમૂહમાં લગભગ દરેક વ્યક્તિ આ મુદ્દાનો સામનો કરી રહ્યા છે કે તેમની સાથે કેવા પ્રકારનો વ્યવહાર કરવામાં આવે છે અથવા તો તેમની લિંગ તથા કામુકતા અંગે કેવો અહેસાસ કરે છે."
            }
          }
        ],
        "page21": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_4_8///",
              "hi": "A1_4_8 ////",
              "ka": "A1_4_8///",
              "te": "A1_4_8///",
              "or": "A1_4_8///",
              "as": "A1_4_8///",
              "gu": "A1_4_8///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "No one prepared me for these types of experiences. I feel very alone and confused.",
              "hi": "इस तरह के अनुभवों के लिए किसी ने मुझे तैयार नहीं किया। मैं बहुत अकेला और उलझन में हूं।",
              "ka": "ಈ ರೀತಿಯ ಅನುಭವಗಳಿಗೆ ಯಾರೂ ನನ್ನನ್ನು ಸಿದ್ಧಪಡಿಸಲಿಲ್ಲ. ನಾನು ತುಂಬಾ ಒಂಟಿಯಾಗಿ ಮತ್ತು ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದೇನೆ.",
              "te": "ఇలాంటి అనుభవాలకు ఎవరూ నన్ను సిద్ధం చేయలేదు. నాకు చాలా ఒంటరిగా మరియు గందరగోళంగా అనిపిస్తుంది.",
              "or": "ଏହି ପ୍ରକାରର ଅଭିଜ୍ଞତା ପାଇଁ ମୋତେ କେହି ପ୍ରସ୍ତୁତ କରିନାହାଁନ୍ତି | ମୁଁ ନିଜକୁ ଏକାକୀ ଅନୁଭବ କରୁଛି ଏବଂ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛି |",
              "as": "এই ধৰণৰ অভিজ্ঞতাৰ বাবে মোক কোনেও প্ৰস্তুত কৰা নাছিল। মই বহুত অকলশৰীয়া আৰু বিভ্ৰান্ত অনুভৱ কৰোঁ।",
              "gu": "આ પ્રકારના અનુભવ માટે મને કોઈએ તૈયાર કર્યો નથી.હું ખૂબ જ એકલતા અને ગુંચવણતા અનુભવું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I left my workplace after facing constant harassment from my coworkers. I don't know what to do now because I liked the work I was doing and can't imagine myself doing something else.",
              "hi": "अपने सहकर्मियों से निरंतर उत्पीड़न का सामना करने के बाद मैंने अपना कार्यस्थल छोड़ा। मुझे नहीं पता कि अब क्या करना है क्योंकि मुझे वह काम पसंद था, जो मैं कर रहा था और मैं कल्पना नहीं कर सकता कि अब कुछ और करूं।",
              "ka": "ನನ್ನ ಸಹೋದ್ಯೋಗಿಗಳಿಂದ ನಿರಂತರ ಕಿರುಕುಳವನ್ನು ಎದುರಿಸಿದ ನಂತರ ನಾನು ನನ್ನ ಕೆಲಸದ ಸ್ಥಳವನ್ನು ತೊರೆದಿದ್ದೇನೆ. ಈಗ ಏನು ಮಾಡಬೇಕೆಂದು ನನಗೆ ತಿಳಿದಿಲ್ಲ ಏಕೆಂದರೆ ನಾನು ಮಾಡುತ್ತಿದ್ದ ಕೆಲಸವನ್ನು ನಾನು ಇಷ್ಟಪಟ್ಟಿದ್ದೇನೆ ಮತ್ತು ನಾನು ಬೇರೆ ಯಾವುದನ್ನಾದರೂ ಮಾಡುತ್ತಿದ್ದೇನೆ ಎಂದು ಊಹಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ.",
              "te": "నా సహోద్యోగుల నుండి నిరంతరం వేధింపులు ఎదుర్కొన్న తర్వాత నేను నా వర్క్ ప్లేస్ ని విడిచిపెట్టాను. నేను చేస్తున్న పని నాకు నచ్చేది, వేరే పని చేయాలని నేను అనుకోలేదు కాబట్టి ఇప్పుడు ఏమి చేయాలో నాకు తెలియట్లేదు.",
              "or": "ମୋର ସହକର୍ମୀମାନଙ୍କ ଦ୍ୱାରା ନିରନ୍ତର ହଇରାଣର ସମ୍ମୁଖୀନ ହେବା ପରେ ମୁଁ ମୋର କର୍ମକ୍ଷେତ୍ର ଛାଡିଥିଲି | ମୁଁ ବର୍ତ୍ତମାନ କ’ଣ କରିବି ଜାଣେ ନାହିଁ କାରଣ ମୁଁ କରୁଥିବା କାର୍ଯ୍ୟକୁ ମୁଁ ପସନ୍ଦ କରେ ଏବଂ ମୁଁ ନିଜକୁ ଅନ୍ୟ କିଛି କରିବାର କଳ୍ପନା କରିପାରିବି ନାହିଁ |",
              "as": "মই মোৰ সহকৰ্মীসকলৰ পৰা নিৰন্তৰ হাৰাশাস্তিৰ সন্মুখীন হোৱাৰ পিছত মোৰ কৰ্মস্থান এৰিছিলো। মই এতিয়া কি কৰিব লাগে নাজানো কাৰণ মই কৰি থকা কামটো ভাল পাইছিলোঁ আৰু নিজে আন কিবা কৰাৰ কল্পনা কৰিব নোৱাৰো।",
              "gu": "મારા સાથીઓ દ્વારા સતત મારું ઉત્પીડનનો સામનો કરવાને લીધે છેવટે મે કાર્યસ્થળ છોડી દીધું. મને ખબર ન હતી કે હવે મારે શું કરવાનું છે કારણ કે હું એ કરું છું કે જે કામ મને પસંદ આવતું હતું અને હું અન્ય કોઈની કલ્પના કરી શકતો ન હતો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I identify as a woman and want to undergo gender affirmation surgery. However, I don't know how to tell this to my parents.",
              "hi": "मैं एक महिला के रूप में पहचान रखता हूं और लिंग अभिपुष्टि सर्जरी से गुजरना चाहता हूं। हालाँकि, मुझे नहीं पता कि मैं अपने माता-पिता को यह कैसे बताऊँ।",
              "ka": "ನಾನು ಮಹಿಳೆ ಎಂದು ಗುರುತಿಸಿಕೊಂಡಿದ್ದೇನೆ ಮತ್ತು ಲಿಂಗ ದೃಢೀಕರಣ ಶಸ್ತ್ರಚಿಕಿತ್ಸೆಗೆ ಒಳಗಾಗಲು ಬಯಸುತ್ತೇನೆ. ಆದರೆ, ಇದನ್ನು ನನ್ನ ಪೋಷಕರಿಗೆ ಹೇಗೆ ಹೇಳಬೇಕೆಂದು ನನಗೆ ತಿಳಿದಿಲ್ಲ.",
              "te": "నేను స్త్రీగా గుర్తించబడి లింగ నిర్ధారణ శస్త్రచికిత్స చేయించుకోవాలనుకుంటున్నాను. అయితే ఈ విషయాన్ని తల్లిదండ్రులకు ఎలా చెప్పాలో తెలియడం లేదు.",
              "or": "ମୁଁ ଜଣେ ମହିଳା ଭାବରେ ପରିଚିତ ଏବଂ ଲିଙ୍ଗ ନିଶ୍ଚିତକରଣ ଅସ୍ତ୍ରୋପଚାର କରିବାକୁ ଚାହୁଁଛି | ତଥାପି, ମୁଁ ମୋର ପିତାମାତାଙ୍କୁ ଏହା କିପରି କହିବି ଜାଣି ନାହିଁ |",
              "as": "মই এগৰাকী মহিলা হিচাপে পৰিচয় দিওঁ আৰু লিংগ নিৰ্দেশক অস্ত্ৰোপচাৰ কৰিব বিচাৰো। অৱশ্যে, মই নাজানো মোৰ মা-দেউতাক এইটো কেনেকৈ ক'ব লাগে।",
              "gu": "હું એક મહિલા તરીકે ઓળખાવા ઈચ્છું છું અને લિંગ પુષ્ટિની સર્જરીમાંથી પસાર થવા ઈચ્છું છું. જોકે, મને ખબર ન હતી કે મારા માતા-પિતાને આ અંગે કેવી રીતે જાણ કરું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "My friend recently shared with me that they don't identify with the gender they were assigned at birth. While I'm happy that they shared this with me, I'm not quite sure how to support them better.",
              "hi": "मेरे मित्र ने हाल ही में मेरे साथ साझा किया कि वे उस लिंग को नहीं पहचानते हैं जो उनमें जन्म के समय से था। जबकि मुझे खुशी है कि उन्होंने इसे मेरे साथ साझा किया, मुझे पूरा विश्वास नहीं है कि उनका समर्थन और अच्छे से कैसे किया जाए।",
              "ka": "ನನ್ನ ಸ್ನೇಹಿತ ಇತ್ತೀಚೆಗೆ ನನ್ನೊಂದಿಗೆ ಹಂಚಿಕೊಂಡಿದ್ದಾರೆ, ಅವರು ಹುಟ್ಟಿನಿಂದಲೇ ನಿಯೋಜಿಸಲಾದ ಲಿಂಗದೊಂದಿಗೆ ಗುರುತಿಸುವುದಿಲ್ಲ. ಅವರು ಇದನ್ನು ನನ್ನೊಂದಿಗೆ ಹಂಚಿಕೊಂಡಿದ್ದಕ್ಕಾಗಿ ನನಗೆ ಸಂತೋಷವಾಗಿದ್ದರೂ, ಅವರನ್ನು ಉತ್ತಮವಾಗಿ ಬೆಂಬಲಿಸುವುದು ಹೇಗೆ ಎಂದು ನನಗೆ ಖಚಿತವಿಲ್ಲ.",
              "te": "పుట్టినప్పుడు కేటాయించిన లింగంతో వారు ఇప్పుడు లేరని నా స్నేహితుడు ఇటీవల నాతో చెప్పాడు. వారు దీన్ని నాతో పంచుకున్నందుకు నేను సంతోషంగా ఉన్నాను, వారికి ఎలా మద్దతు ఇవ్వాలో నాకు ఖచ్చితంగా తెలియట్లేదు.",
              "or": "ମୋର ବନ୍ଧୁ ଏବେ ମୋ ସହ ଅଂଶୀଦାର କରିଛନ୍ତି ଯେ ସେମାନେ ଜନ୍ମ ସମୟରେ ଦିଆଯାଇଥିବା ଲିଙ୍ଗ ସହିତ ପରିଚିତ ନୁହଁନ୍ତି | ମୁଁ ଖୁସି ଯେ ସେମାନେ ମୋ ସହିତ ଏହା ଅଂଶୀଦାର କରିଛନ୍ତି, ମୁଁ ସେମାନଙ୍କୁ କିପରି ଭଲ ଭାବରେ ସମର୍ଥନ କରିବି ତାହା ନିଶ୍ଚିତ ନୁହେଁ |",
              "as": "মোৰ বন্ধুৱে অলপতে মোৰ সৈতে কথা পাতিছিল যে তেওঁলোকে জন্মৰ সময়ত তেওঁলোকক পোৱা লিংগৰ সৈতে পৰিচয় অনুভৱ নকৰে। যদিও মই সুখী যে তেওঁলোকে এইটো মোৰ সৈতে আলোচনা কৰিছিল, মই নিশ্চিত নহয় যে তেওঁলোকক কেনেদৰে ভালদৰে সমৰ্থন কৰিব লাগে।",
              "gu": "તે આ માહિતી અરવિંદ સાથે શેર કરે છે. જોકે તે શરૂઆતમાં આ માટે નિરુત્સાહી હતી, પણ તેને સાંભળીને ખુશી થાય છે કે સમૂહના લોકો તેને સમજશે."
            }
          }
        ],
        "page22": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_4_9",
              "hi": "A1_4_9",
              "ka": "A1_4_9",
              "te": "A1_4_9",
              "or": "A1_4_9",
              "as": "A1_4_9",
              "gu": "A1_4_9"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is surprised. There are so many more people like him.",
              "hi": "अरविंद हैरान है। उसके जैसे बहुत सारे लोग हैं।",
              "ka": "ಅರವಿಂದನಿಗೆ ಆಶ್ಚರ್ಯವಾಯಿತು. ಅವನಂತೆ ಇನ್ನೂ ಎಷ್ಟೋ ಜನ ಇದ್ದಾರೆ.",
              "te": "అరవింద్ ఆశ్చర్యపోయాడు. అతనిలాంటి వారు ఇంకా చాలా మంది ఉన్నారు.",
              "or": "ଅରବିନ୍ଦ ଆଶ୍ଚର୍ଯ୍ୟ ହୋଇଗଲେ | ତାଙ୍କ ପରି ଆହୁରି ଅନେକ ଲୋକ ଅଛନ୍ତି |",
              "as": "অৰবিন্দ আচৰিত হৈছে। তেওঁৰ দৰে আৰু বহুতো মানুহ আছে।",
              "gu": "અરવિંદને આશ્ચર્ય છે. તેના જેવા અન્ય ઘણા લોકો છે."
            }
          }
        ],
        "page23": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_5_1//",
              "hi": "A1_5_1 //",
              "ka": "A1_5_1//",
              "te": "A1_5_1//",
              "or": "A1_5_1//",
              "as": "A1_5_1//",
              "gu": "A1_5_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "While he is thinking about this, Vandana turns to him and asks...",
              "hi": "जब वह इस बारे में सोच रहा है, वंदना उसे अपनी ओर घुमा कर पूछती है ...",
              "ka": "ಅವನು ಈ ಬಗ್ಗೆ ಯೋಚಿಸುತ್ತಿರುವಾಗ, ವಂದನಾ ಅವನತ್ತ ತಿರುಗಿ ಕೇಳುತ್ತಾಳೆ ...",
              "te": "అతను ఇలా ఆలోచిస్తుండగా వందన అతని వైపు తిరిగి ఇలా అడుగుతుంది...",
              "or": "ସେ ଏ ବିଷୟରେ ଚିନ୍ତା କରୁଥିବାବେଳେ ବନ୍ଦନା ତାଙ୍କ ଆଡକୁ ବୁଲି ପଚାରନ୍ତି …",
              "as": "যেতিয়া তেওঁ এই বিষয়ে চিন্তা কৰি আছে, বন্দনাই তেওঁৰ ফালে ঘূৰি সুধিলে...",
              "gu": "જ્યારે તે આ અંગે વિચારી રહ્યા છે ત્યારે વંદના તેમના તરફ વળે છે અને પૂછે છે..."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Vandana",
              "hi": "वंदना",
              "ka": "ವಂದನಾ",
              "te": "వందన",
              "or": "ବନ୍ଦନା",
              "as": "বন্দনা",
              "gu": "વંદના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Vandana",
              "hi": "वंदना",
              "ka": "ವಂದನಾ",
              "te": "వందన",
              "or": "ବନ୍ଦନା",
              "as": "বন্দনা",
              "gu": "વંદના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Arvind, Would you like to share?",
              "hi": "अरविंद, क्या तुम साझा करना चाहते हो?",
              "ka": "ಅರವಿಂದ್, ನೀವು ಹಂಚಿಕೊಳ್ಳಲು ಬಯಸುವಿರಾ?",
              "te": "అరవింద్, మీరు ఏమైనా చెప్పాలనుకుంటున్నారా?",
              "or": "ଅରବିନ୍ଦ, ତୁମେ ଅଂଶୀଦାର କରିବାକୁ ଚାହୁଁଛ କି ?",
              "as": "অৰবিন্দ, আপুনি কিবা কবলৈ বিচাৰে নেকি?",
              "gu": "અરવિંદ,શું તુ તારા વિચાર રજૂ કરીશ?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "…",
              "hi": "...",
              "ka": "…",
              "te": "…",
              "or": "…",
              "as": "...",
              "gu": "…"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "It's okay, you can take your time.",
              "hi": " कोई बात नहीं, तुम अपना समय ले सकते हो।",
              "ka": "ಪರವಾಗಿಲ್ಲ, ನೀವು ನಿಮ್ಮ ಸಮಯವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು.",
              "te": "పర్వాలేదు, మీరు మీకు కావలిసినంత సమయం తీసుకోవచ్చు.",
              "or": "ଠିକ ଅଛି, ତୁମେ ତୁମର ସମୟ ନେଇ ପାରିବ |",
              "as": "ঠিক আছে, আপুনি আপোনাৰ সময় ল'ব পাৰে।",
              "gu": "સારું, તુ તારો સમય લઈ શકી છી."
            }
          }
        ],
        "page24": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_5_2////",
              "hi": "A1_5_2 ////",
              "ka": "A1_5_2////",
              "te": "A1_5_2////",
              "or": "A1_5_2////",
              "as": "A1_5_2////",
              "gu": "A1_5_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I stayed in a hostel until very recently, but living with other boys in the boy's hostel made me uncomfortable. I was born a boy, but I have always felt more comfortable thinking of myself as a girl.",
              "hi": "मैं हाल ही में एक छात्रावास में रहा, लेकिन लड़कों के छात्रावास में अन्य लड़कों के साथ रहना मुझे असहज लगा। मेरा एक लड़के के रूप में जन्म हुआ था, लेकिन मैंने हमेशा एक लड़की के रूप में स्वयं को अधिक सहज अनुभव किया है।",
              "ka": "ನಾನು ತೀರಾ ಇತ್ತೀಚಿನವರೆಗೂ ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿಯೇ ಇದ್ದೆ, ಆದರೆ ಹುಡುಗನ ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿ ಇತರ ಹುಡುಗರೊಂದಿಗೆ ವಾಸಿಸುವುದು ನನಗೆ ಅನಾನುಕೂಲವಾಗಿದೆ. ನಾನು ಹುಡುಗನಾಗಿ ಜನಿಸಿದೆ, ಆದರೆ ನಾನು ಯಾವಾಗಲೂ ನನ್ನನ್ನು ಹುಡುಗಿ ಎಂದು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "నేను మొన్నటివరకు హాస్టల్‌లో ఉన్నాను, కాని అబ్బాయిల హాస్టల్‌లో ఇతర అబ్బాయిలతో నివసించడం నాకు అసౌకర్యంగా అనిపించింది. నేను అబ్బాయిగా పుట్టాను, కానీ నేను ఎప్పుడూ ఒక అమ్మాయిగా భావించడం మరింత సౌకర్యంగా అనిపిస్తుంది.",
              "or": "ମୁଁ ବର୍ତ୍ତମାନ ପର୍ଯ୍ୟନ୍ତ ଏକ ହଷ୍ଟେଲରେ ରହିଥିଲି, କିନ୍ତୁ ବାଳକ ହଷ୍ଟେଲରେ ଅନ୍ୟ ପୁଅମାନଙ୍କ ସହିତ ରହିବା ମୋତେ ଅସହଜ କଲା | ମୁଁ ଏକ ବାଳକ ଭାବରେ ଜନ୍ମ ହୋଇଥିଲି, କିନ୍ତୁ ମୁଁ ନିଜକୁ ଝିଅ ବୋଲି ଭାବିବାରେ ସବୁବେଳେ ଅଧିକ ଆରାମଦାୟକ ଅନୁଭବ କରେ",
              "as": "মই অলপতে যেতিয়ালৈকে এটা হোষ্টেলত আছিলোঁ, ল'ৰা হোষ্টেলত আন ল'ৰাবোৰৰ সৈতে থকাটোৱে মোক অস্বস্তিত পেলাইছিল। মই এজন ল'ৰা হিচাপে জন্মগ্ৰহণ কৰিছিলোঁ, কিন্তু মই সদায়েই নিজকে ছোৱালী বুলি ভাবি অধিক সহজ অনুভৱ কৰি আহিছো।",
              "gu": "હું તાજેતરમાં જ એક હોસ્ટેલમાં રહેતો હતો, પણ છોકરાઓની હોસ્ટેલમાં અન્ય છોકરાઓ સાથે રહેવું તે મારા માટે બિલકુલ પ્રતિકૂળ સ્થિતિ બની ગયેલી. હું એક છોકરા તરીકે જન્મ્યો હતો, પણ હું હંમેશા મારી જાતને એક છોકરી તરીકે વિચાર કરવામાં વધારે સાનુકૂળતા ધરાવતો હતો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Go on ...",
              "hi": "जारी रखें ...",
              "ka": "ಮುಂದೆ ಸಾಗು ...",
              "te": "కొనసాగించు ...",
              "or": "ଆଗେଇ ଚାଲ …",
              "as": "কৈ থাকা...",
              "gu": "તમારી વાત આગળ રજૂ કરો..."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I feel like I have been given the wrong body. I don't look entirely like a boy, but neither do I look like a girl. In my head, I'd rather live like a woman. I like wearing sister's clothes. I wish people wouldn't pick on me so much. It's not easy to understand what you are feeling when all you think about is who will hurt me next.",
              "hi": "मुझे लगता है कि मुझे गलत शरीर दिया गया है। मैं पूरी तरह से एक लड़के की तरह नहीं दिखता, लेकिन न ही मैं एक लड़की की तरह दिखता हूं। अपने दिमाग में, मैं एक महिला की तरह रहना पसंद करूंगा। मुझे बहन के कपड़े पहनना अच्छा लगता है। काश लोग मुझे इतना पसंद नहीं करते। यह समझना सहज नहीं है कि आप क्या अनुभव कर रहे हैं जब आप  सिर्फ यही सोचते हैं कि अब न जाने कौन मुझे चोट पहुंचाएगा। ",
              "ka": "ನನಗೆ ತಪ್ಪು ದೇಹವನ್ನು ನೀಡಲಾಗಿದೆ ಎಂದು ನನಗೆ ಅನಿಸುತ್ತದೆ. ನಾನು ಸಂಪೂರ್ಣವಾಗಿ ಹುಡುಗನಂತೆ ಕಾಣುವುದಿಲ್ಲ, ಆದರೆ ನಾನು ಹುಡುಗಿಯಂತೆ ಕಾಣುವುದಿಲ್ಲ. ನನ್ನ ತಲೆಯಲ್ಲಿ, ನಾನು ಮಹಿಳೆಯಂತೆ ಬದುಕಲು ಬಯಸುತ್ತೇನೆ. ನಾನು ಸಹೋದರಿಯ ಬಟ್ಟೆಗಳನ್ನು ಧರಿಸಲು ಇಷ್ಟಪಡುತ್ತೇನೆ. ಜನರು ನನ್ನನ್ನು ಅಷ್ಟಾಗಿ ಆರಿಸಿಕೊಳ್ಳಬಾರದು ಎಂದು ನಾನು ಬಯಸುತ್ತೇನೆ. ಮುಂದೆ ಯಾರು ನನ್ನನ್ನು ನೋಯಿಸುತ್ತಾರೆ ಎಂದು ನೀವು ಯೋಚಿಸುತ್ತಿರುವಾಗ ನಿಮ್ಮ ಭಾವನೆಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು ಸುಲಭವಲ್ಲ.",
              "te": "నాకు తప్పు శరీరాన్ని ఇచ్చినట్లు అనిపిస్తుంది. నేను పూర్తిగా అబ్బాయిలా కనిపించను, కానీ అమ్మాయిలా కూడా కనిపించను. నా తలలో, నేను స్త్రీలా జీవించాలనుకుంటున్నాను. చెల్లి బట్టలు వేసుకోవడం నాకు ఇష్టం. ప్రజలు నన్ను అంతగా ఏడిపించకూడదని నేను కోరుకుంటున్నాను. తర్వాత నన్ను ఎవరు బాధపెడతారనే దాని గురించి మీరు ఆలోచిస్తూ ఉండడం ఎంత కష్టమో అర్థం చేసుకోవడం అంత సులభం కాదు.",
              "or": "ମୋତେ ଲାଗୁଛି ଯେ ମୋତେ ଭୁଲ ଶରୀର ଦିଆଯାଇଛି | । ମୁଁ ସଂପୂର୍ଣ୍ଣ ରୂପେ ପୁଅ ପରି ଦେଖାଯାଉ ନାହିଁ, କିନ୍ତୁ ମୁଁ ଝିଅ ପରି ଦେଖାଯାଏ ନାହିଁ | । ମୋ ମନରେ, ମୁଁ ଜଣେ ମହିଳାଙ୍କ ପରି ବଞ୍ଚିବାକୁ ଚାହେଁ | ମୁଁ ଭଉଣୀର ପୋଷାକ ପିନ୍ଧିବାକୁ ପସନ୍ଦ କରେ | ମୁଁ ଇଚ୍ଛା କରେ ଲୋକମାନେ ମୋତେ ଏତେ ପସନ୍ଦ ନ କରନ୍ତୁ | ଏହା ବୁଝିବା ସହଜ ନୁହେଁ କି ତୁମେ କଣ ଅନୁଭବ କରୁଛ ଯେତେବେଳେ ତୁମେ ଏହା ଭାବୁଛ କି ପରବର୍ତ୍ତୀ ସମୟରେ ମୋତେ କିଏ ଆଘାତ ଦେବ |",
              "as": "মই অনুভৱ কৰোঁ যেন মোক ভুল শৰীৰ দিয়া হৈছে। মই নিজকে সম্পূৰ্ণৰূপে ল'ৰাৰ দৰে দেখা নাপাওঁ, কিন্তু মই ছোৱালীৰ দৰেও দেখা নাপাওঁ। মোৰ মনেৰে, মই এগৰাকী মহিলাৰ দৰে জীয়াই থাকিব বিচাৰো। মই ভনীৰ কাপোৰ পিন্ধি ভাল পাওঁ। মই আশা কৰোঁ মানুহে মোক ইমান বেছি তুলি নলব। যেতিয়া আপুনি চিন্তা কৰে যে পৰৱৰ্তী সময়ত কোনে আপোনাক আঘাত কৰিব তেতিয়া আপুনি কি অনুভৱ কৰিছে বুজিপোৱা সহজ নহয়।",
              "gu": "મને સતત લાગતું હતું કે મને ખોટું શરીર આપવામાં આવ્યું છે. હું સંપૂર્ણપણે એક છોકરાની માફક દેખાતો ન હતો. અલબત હું છોકરીની માફક પણ દેખાતો ન હતો.મારા મનમાં હું એક મહિલાની માફક રહેવાનું પસંદ કરીશ.મને બહેનના કપડાં પહેરવાના સારા લાગે છે.કદાંચ લોકો મને એટલો પસંદ ન કર્યો હોત.એ બાબત સમજવી સરળ નથી કે તમે શુ અહેસાસ કરી રહ્યા છો કે જ્યારે તમે ફક્ત એ વિચારતા હોય કે આગળજતા કોણ ઈજા પહોંચાડશે."
            }
          }
        ],
        "page25": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_5_3//",
              "hi": "A1_5_3 //",
              "ka": "A1_5_3//",
              "te": "A1_5_3//",
              "or": "A1_5_3//",
              "as": "A1_5_3//",
              "gu": "A1_5_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Some other members",
              "hi": "कुछ अन्य सदस्य",
              "ka": "ಇತರ ಕೆಲವು ಸದಸ್ಯರು",
              "te": "మరికొందరు సభ్యులు",
              "or": "କିଛି ଅନ୍ୟ ସଦସ୍ୟ",
              "as": "আন কিছুমান সদস্য",
              "gu": "અન્ય કેટલાક સભ્યો"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Vandana",
              "hi": "वंदना",
              "ka": "ವಂದನಾ",
              "te": "వందన",
              "or": "ବନ୍ଦନା",
              "as": "বন্দনা",
              "gu": "વંદના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "We hear you, we hear you.",
              "hi": "हम तुमको सुनते हैं, हम तुमको सुनते हैं।",
              "ka": "ನಾವು ನಿಮ್ಮನ್ನು ಕೇಳುತ್ತೇವೆ, ನಾವು ನಿಮ್ಮನ್ನು ಕೇಳುತ್ತೇವೆ.",
              "te": "మేము మీ మాట వింటాము, మీ మాట వింటాము.",
              "or": "ଆମେ ତୁମକୁ ଶୁଣୁଛୁ, ଆମେ ତୁମକୁ ଶୁଣୁଛୁ |",
              "as": "আমি আপোনাৰ কথা শুনিছো, আমি আপোনাৰ কথা শুনিছো।",
              "gu": "અમે તને સાંભળીએ છીએ.અમે તને સાંભળી છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Thank you for sharing, Arvind.",
              "hi": "साझा करने के लिए धन्यवाद, अरविंद।",
              "ka": "ಹಂಚಿಕೊಂಡಿದ್ದಕ್ಕೆ ಧನ್ಯವಾದಗಳು, ಅರವಿಂದ್.",
              "te": "పంచుకున్నందుకు ధన్యవాదాలు, అరవింద్.",
              "or": "ଅଂଶୀଦାର ପାଇଁ ଧନ୍ୟବାଦ, ଅରବିନ୍ଦ |",
              "as": "আপোনাৰ কথাবোৰ কোৱাৰ বাবে ধন্যবাদ, অৰবিন্দ।",
              "gu": "તારા વિચારો રજૂ કરવા બાદલ આભાર, અરવિંદ"
            }
          }
        ],
        "page26": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_4_10",
              "hi": "A1_4_10",
              "ka": "A1_4_10",
              "te": "A1_4_10",
              "or": "A1_4_10",
              "as": "A1_4_10",
              "gu": "A1_4_10"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind sits back feeling so much lighter. He smiles to himself because everybody around him seems to understand his experience.",
              "hi": "अरविंद इतना हल्का महसूस कर रहा है। वह अपनेआप मुस्कराता है क्योंकि उसके आस-पास के सभी लोग उसके अनुभव को समझते प्रतीत होते हैं।",
              "ka": "ಅರವಿಂದ್ ತುಂಬಾ ಹಗುರವಾಗಿ ಕುಳಿತುಕೊಳ್ಳುತ್ತಾನೆ. ಅವನು ತನ್ನಷ್ಟಕ್ಕೆ ತಾನೇ ನಗುತ್ತಾನೆ ಏಕೆಂದರೆ ಅವನ ಸುತ್ತಲಿರುವ ಪ್ರತಿಯೊಬ್ಬರೂ ಅವನ ಅನುಭವವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "అరవింద్ మనసులో చాలా తేలికగా భావిస్తూ కూర్చున్నాడు. చుట్టుపక్కల ఉన్న ప్రతి ఒక్కరూ తన అనుభవాన్ని అర్థం చేసుకున్నట్లు కనిపిస్తున్నందున అతను తనలో తాను సంతోషిస్తాడు.",
              "or": "ଅରବିନ୍ଦ ବହୁତ ହାଲୁକା ଅନୁଭବ କରି ପଛକୁ ବସିଛନ୍ତି | ସେ ମନକୁ ମନ ହସନ୍ତି କାରଣ ତାଙ୍କ ଆଖପାଖରେ ଥିବା ସମସ୍ତେ ତାଙ୍କ ଅନୁଭୂତି ବୁଝିବା ପରି ଜଣାପଡୁଛନ୍ତି |",
              "as": "অৰবিন্দে বহুত পাতল অনুভৱ কৰি বহি আছে। তেওঁ নিজৰ ভিতৰতে হাঁহিছিল কাৰণ তেওঁৰ চাৰিওফালৰ সকলোৱে তেওঁৰ অভিজ্ঞতা বুজি পোৱা যেন লাগিছে।",
              "gu": "અરવિંદ બિલકુલ હળવાસ અનુભવ કરવા સાથે બેસી જાય છે. તે આપમેળે સ્માઈલ આપે છે કારણ કે તેને આજુબાજુના લોકોને તેના અનુભવ પ્રત્યે યોગ્ય સમજણ કેળવી છે."
            }
          }
        ],
        "page27": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_6_1",
              "hi": "A1_6_1",
              "ka": "A1_6_1",
              "te": "A1_6_1",
              "or": "A1_6_1",
              "as": "A1_6_1",
              "gu": "A1_6_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He feels blessed that he has a family that supports him and that he has found a community that he can relate to. It makes all the difference.",
              "hi": "वह धन्य अनुभव करता है कि उसके पास एक परिवार है जो उसे समर्थन देता है और उसे एक समुदाय मिला है जिससे वह अपने को जोड़ सकता है। इससे सारा फर्क पड़ता है।",
              "ka": "ಅವರು ಅವರನ್ನು ಬೆಂಬಲಿಸುವ ಕುಟುಂಬವನ್ನು ಹೊಂದಿದ್ದಾರೆ ಮತ್ತು ಅವರು ಸಂಬಂಧಿಸಬಹುದಾದ ಸಮುದಾಯವನ್ನು ಕಂಡುಕೊಂಡಿದ್ದಾರೆ ಎಂದು ಅವರು ಭಾವಿಸುತ್ತಾರೆ. ಇದು ಎಲ್ಲಾ ವ್ಯತ್ಯಾಸವನ್ನು ಮಾಡುತ್ತದೆ.",
              "te": "తనకు మద్దతిచ్చే కుటుంబాన్ని కలిగి ఉన్నందుకు మరియు అతని లాగానే ఉన్న సంఘాన్ని కనుగొన్నందుకు అతను సంతోషించాడు. ఇది అన్ని తేడాలు చేస్తుంది.",
              "or": "ସେ ନିଜକୁ ଧନ୍ଯ ମନେ କରନ୍ତି ଯେ ତାଙ୍କର ଏକ ପରିବାର ଅଛି ଯେକି ତାଙ୍କୁ ସମର୍ଥନ କରନ୍ତି ଏବଂ ସେ ଏକ ଗୋଷ୍ଠି ପାଇଛନ୍ତି ଯାହା ସହିତ ସେ ସମ୍ପର୍କ କରିପାରିବେ | ଏହା ସମସ୍ତ ପାର୍ଥକ୍ୟ ସୃଷ୍ଟି କରେ |",
              "as": "তেওঁ ধন্য অনুভৱ কৰে যে তেওঁৰ এটা পৰিয়াল আছে যিয়ে তেওঁক সমৰ্থন কৰে আৰু তেওঁ এটা সম্প্ৰদায় বিচাৰি পাইছে যাৰ সৈতে তেওঁ সম্পৰ্কিত হ'ব পাৰে। এইটোৱেই সকলো পাৰ্থক্য সৃষ্টি কৰে।",
              "gu": "તેને હવે એક એવો અહેસાસ થાય છે કે તેનો પણ એક એવો પરિવાર છે કે જે તેનું સમર્થન કરે છે અને તેને એક એવો સમુદાય મળ્યો છે કે જેની સાથે તે સંબંધ ધરાવે છે. તેનાથી ઘણો ફરક પડ્યો છે."
            }
          }
        ],
        "page28": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_6_2",
              "hi": "A1_6_2",
              "ka": "A1_6_2",
              "te": "A1_6_2",
              "or": "A1_6_2",
              "as": "A1_6_2",
              "gu": "A1_6_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The group meets once a month in person, and every Sunday, they meet online.",
              "hi": "समूह एक महीने में एक बार व्यक्तिगत रूप से मिलता है, और हर रविवार, वे ऑनलाइन मिलते हैं।",
              "ka": "ಗುಂಪು ತಿಂಗಳಿಗೊಮ್ಮೆ ವೈಯಕ್ತಿಕವಾಗಿ ಭೇಟಿಯಾಗುತ್ತದೆ ಮತ್ತು ಪ್ರತಿ ಭಾನುವಾರ ಅವರು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಭೇಟಿಯಾಗುತ್ತಾರೆ.",
              "te": "ఈ బృందం వారు నెలకు ఒకసారి వ్యక్తిగతంగా కలుస్తారు మరియు ప్రతి ఆదివారం ఆన్‌లైన్‌లో కలుస్తారు.",
              "or": "ସମୁଦାୟ ମାସରେ ଥରେ ବ୍ୟକ୍ତିଗତ ଭାବରେ ଭେଟେ, ଏବଂ ପ୍ରତ୍ୟେକ ରବିବାର ରେ, ସେମାନେ ଅନ୍‌ଲାଇନରେ ଭେଟନ୍ତି |",
              "as": "গোটটোৱে মাহত এবাৰ ব্যক্তিগতভাৱে মিলিত হয়, আৰু প্ৰতি দেওবাৰে, তেওঁলোকে অনলাইনত লগ হয়।",
              "gu": "આ ગ્રુપ પ્રત્યેક મહિને એક વખત મળે છે, અને પ્રત્યેક રવિવારે ઓનલાઈન મળે છે."
            }
          }
        ],
        "page29": [
          {
            "valuePath": "display",
            "value": {
              "en": "A1_6_3",
              "hi": "A1_6_3",
              "ka": "A1_6_3",
              "te": "A1_6_3",
              "or": "A1_6_3",
              "as": "A1_6_3",
              "gu": "A1_6_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind attends the online sessions on Sunday, and he starts feeling much better about himself. Other support group members share thoughts and feelings that deeply resonate with Arvind.",
              "hi": "अरविंद रविवार को ऑनलाइन सत्रों में भाग लेता है, और वह स्वयं के बारे में बहुत अच्छा अनुभव करना शुरू कर देता है। अन्य समर्थन समूह के सदस्य विचार और भावनाओं को साझा करते हैं जो अरविंद के साथ गहराई से सम्बंधित हैं।",
              "ka": "ಅರವಿಂದ್ ಭಾನುವಾರ ಆನ್‌ಲೈನ್ ಸೆಷನ್‌ಗಳಿಗೆ ಹಾಜರಾಗುತ್ತಾರೆ ಮತ್ತು ಅವರು ತಮ್ಮ ಬಗ್ಗೆ ಹೆಚ್ಚು ಉತ್ತಮ ಭಾವನೆ ಹೊಂದಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ. ಇತರ ಬೆಂಬಲ ಗುಂಪಿನ ಸದಸ್ಯರು ಅರವಿಂದ್ ಅವರೊಂದಿಗೆ ಆಳವಾಗಿ ಪ್ರತಿಧ್ವನಿಸುವ ಆಲೋಚನೆಗಳು ಮತ್ತು ಭಾವನೆಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "అరవింద్ ఆదివారం ఆన్‌లైన్ సెషన్‌లకు హాజరయ్యాడు మరియు అతను తన గురించి చాలా మెరుగ్గా భావించడం ప్రారంభించాడు. ఇతర సపోర్ట్ గ్రూప్ సభ్యులు కూడా అరవింద్‌ కి దగ్గరగా ఉండే ఆలోచనలు మరియు భావాలను పంచుకుంటారు.",
              "or": "ଅରବିନ୍ଦ ରବିବାର ଦିନ ଅନଲାଇନ୍ ସେଶନ୍‌ ରେ ଯୋଗ ଦିଅନ୍ତି, ଏବଂ ସେ ନିଜ ବିଷୟରେ ବହୁତ ଭଲ ଅନୁଭବ କରିବା ଆରମ୍ଭ କରନ୍ତି | ଅନ୍ୟ ସମର୍ଥନ ସମୁଦାୟର ସଦସ୍ୟମାନେ ଭାବନା ଏବଂ ବିଚାର ଅଶିଂଦାର କରନ୍ତି ଯାହା ଅରବିନ୍ଦଙ୍କ ସହିତ ଗଭୀର ଭାବରେ ପୁନଃ ପ୍ରତିରୂପିତ |",
              "as": "অৰবিন্দে দেওবাৰে অনলাইন অধিৱেশনত উপস্থিত থাকে, আৰু তেওঁ নিজৰ বিষয়ে বহুত ভাল অনুভৱ কৰিবলৈ আৰম্ভ কৰে। সমৰ্থন গোটৰ আন সদস্যসকলে চিন্তা আৰু অনুভূতি বোৰৰ আলোচনা কৰে যি অৰবিন্দৰ সৈতে গভীৰভাৱে প্ৰতিধ্বনিত হয়।",
              "gu": "અરવિંદ રવિવારે ઓનલાઈન સત્રમાં ભાગ લે છે, અને પોતાના વિશે ઘણો સારો અહેસાસ કરે છે. અન્ય સહાયતા સમૂહના સભ્ય એ વિચારો તથા ભાવનાઓનું આદાન-પ્રદાન કરે છે કે જે અરવિંદ સાથે વ્યાપક રીતે અહેસાસ થતી રહી છે."
            }
          }
        ],
        "page31": [
          {
            "valuePath": "display",
            "value": {
              "en": "GenderandIdentity",
              "hi": "लिंगऔरपहचान ",
              "ka": "ಲಿಂಗ ಮತ್ತು ಗುರುತು",
              "te": "లింగం మరియు గుర్తింపు",
              "or": "GenderandIdentity",
              "as": "লিংগ আৰু পৰিচয়",
              "gu": "લિંગ અને ઓળખ"
            }
          }
        ]
      },
      "scene10": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_1",
              "hi": "A2_1_1",
              "ka": "A2_1_1",
              "te": "A2_1_1",
              "or": "A2_1_1",
              "as": "A2_1_1",
              "gu": "A2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Things are not entirely okay, but he is feeling better. He regularly attends the sessions.",
              "hi": "चीजें पूरी तरह से ठीक नहीं हैं, लेकिन वह अच्छा अनुभव कर रहा है। वह नियमित रूप से सत्रों में भाग लेता है।",
              "ka": "ವಿಷಯಗಳು ಸಂಪೂರ್ಣವಾಗಿ ಸರಿಯಾಗಿಲ್ಲ, ಆದರೆ ಅವನು ಉತ್ತಮವಾಗಿದ್ದಾನೆ. ಅವರು ನಿಯಮಿತವಾಗಿ ಅಧಿವೇಶನಗಳಿಗೆ ಹಾಜರಾಗುತ್ತಾರೆ.",
              "te": "అన్ని విషయాలు సరిగ్గా లేవు, కానీ అతను బాగానే ఉన్నాడు. అతను క్రమం తప్పకుండా సమావేశాలకు హాజరవుతున్నాడు.",
              "or": "ଜିନିଷଗୁଡିକ ସଂପୂର୍ଣ୍ଣ ଠିକ ନୁହେଁ, କିନ୍ତୁ ସେ ଭଲ ଅନୁଭବ କରୁଛନ୍ତି | ସେ ନିୟମିତ ଭାବରେ ଅଧିବେଶନ ରେ ଯୋଗ ଦିଅନ୍ତି |",
              "as": "বস্তুবোৰ সম্পূৰ্ণৰূপে ঠিক নহয়, কিন্তু তেওঁ ভাল অনুভৱ কৰি আছে। তেওঁ নিয়মীয়াকৈ অধিৱেশনত উপস্থিত থাকে।",
              "gu": "વસ્તુઓ સંપૂર્ણપણે યોગ્ય નથી, જોકે તે વધારે સારો અહેસાસ કરી રહ્યો છે. તે નિયમિતપણે સત્રોમાં ભાગ લે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_2",
              "hi": "A2_1_2",
              "ka": "A2_1_2",
              "te": "A2_1_2",
              "or": "A2_1_2",
              "as": "A2_1_2",
              "gu": "A2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "People in the group talk about gender and identity and openly share their experiences.",
              "hi": "समूह के लोग लिंग और पहचान के बारे में बात करते हैं और स्पष्ट रूप से अपने अनुभव साझा करते हैं।",
              "ka": "ಗುಂಪಿನಲ್ಲಿರುವ ಜನರು ಲಿಂಗ ಮತ್ತು ಗುರುತಿನ ಬಗ್ಗೆ ಮಾತನಾಡುತ್ತಾರೆ ಮತ್ತು ತಮ್ಮ ಅನುಭವಗಳನ್ನು ಮುಕ್ತವಾಗಿ ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "సమూహంలోని వ్యక్తులు లింగం మరియు గుర్తింపు గురించి మాట్లాడతారు మరియు వారి అనుభవాలను బహిరంగంగా పంచుకుంటారు.",
              "or": "ସମୁଦାୟର ଲୋକମାନେ ଲିଙ୍ଗ ଏବଂ ପରିଚୟ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି ଏବଂ ଖୋଲାଖୋଲି ଭାବରେ ସେମାନଙ୍କ ଅଭିଜ୍ଞତା ଅଂଶୀଦାର କରନ୍ତି |",
              "as": "গোটটোৰ মানুহে লিংগ আৰু পৰিচয়ৰ বিষয়ে কয় আৰু মুকলিকৈ তেওঁলোকৰ অভিজ্ঞতাবোৰ আলোচনা কৰে।",
              "gu": "ગ્રુપમાં લોકો લિંગ અને તેની ઓળખ અંગે વાત કરે છે તથા ખુલ્લી રીતે તેમના અનુભવો રજૂ કરે છે"
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_3",
              "hi": "A2_1_3",
              "ka": "A2_1_3",
              "te": "A2_1_3",
              "or": "A2_1_3",
              "as": "A2_1_3",
              "gu": "A2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They also share what they are doing to help the LGBTQ+ community.",
              "hi": "वे यह भी साझा करते हैं कि वे एलजीबीटीक्यू + समुदाय की सहायता के लिए क्या कर रहे हैं।",
              "ka": "ಅವರು LGBTQ+ ಸಮುದಾಯಕ್ಕೆ ಸಹಾಯ ಮಾಡಲು ಏನು ಮಾಡುತ್ತಿದ್ದಾರೆ ಎಂಬುದನ್ನು ಸಹ ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "LGBTQ+ కమ్యూనిటీకి సహాయం చేయడానికి వారు ఏమి చేస్తున్నారో కూడా వారు పంచుకుంటారు.",
              "or": "ସେମାନେ LGBTQ +ସମୁଦାୟକୁ ସାହାଯ୍ୟ କରିବା ପାଇଁ କଣ କରୁଛନ୍ତି ତାହା ମଧ୍ୟ ଅଂଶୀଦାର କରନ୍ତି |",
              "as": "তেওঁলোকে LGBTQ+ সম্প্ৰদায়ক সহায় কৰিবলৈ কি কৰি আছে সেয়াও আলোচনা কৰে।",
              "gu": "તેઓ LGBTQ+ સમુદાયની મદદ માટે જે પણ કરી રહ્યા છે તે અંગે પણ રજૂઆત કરે છે"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_4",
              "hi": "A2_1_4",
              "ka": "A2_1_4",
              "te": "A2_1_4",
              "or": "A2_1_4",
              "as": "A2_1_4",
              "gu": "A2_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Gradually, his aspirations start coming back to him. Roshni and his parents are pleased seeing Arvind back and taking control.",
              "hi": "धीरे-धीरे उसमें इच्छाएं वापस जगने लगी हैं। रोशनी और उसके माता-पिता अरविंद को वापस से शांत और नियंत्रण में देखकर खुश हैं।",
              "ka": "ಕ್ರಮೇಣ, ಅವನ ಆಕಾಂಕ್ಷೆಗಳು ಅವನಿಗೆ ಮರಳಲು ಪ್ರಾರಂಭಿಸುತ್ತವೆ. ರೋಶ್ನಿ ಮತ್ತು ಅವನ ಹೆತ್ತವರು ಅರವಿಂದನನ್ನು ಹಿಂತಿರುಗಿ ನೋಡಿದ ಮತ್ತು ನಿಯಂತ್ರಣವನ್ನು ತೆಗೆದುಕೊಂಡರು.",
              "te": "క్రమంగా, అతని ఆకాంక్షలు అతనికి తిరిగి రావడం ప్రారంభిస్తాయి. రోష్ని మరియు అతని తల్లిదండ్రులు అరవింద్‌ని తిరిగి చూసి, తమ నియంత్రణలోకి రావడం పట్ల సంతోషిస్తున్నారు.",
              "or": "ଧୀରେ ଧୀରେ, ତାଙ୍କର ଆକାଂକ୍ଷା ତାଙ୍କ ନିକଟକୁ ଫେରିବା ଆରମ୍ଭ କରେ | ରୋଶନି ଏବଂ ତାଙ୍କ ପିତାମାତା ଅରବିନ୍ଦଙ୍କୁ ଫେରିବା ଏବଂ ନିୟନ୍ତ୍ରଣ ରେ ଦେଖି ଖୁସି ହୁଅନ୍ତି |",
              "as": "লাহে লাহে তেওঁৰ আশা-আকাংক্ষা তেওঁৰ ওচৰলৈ ঘূৰি আহিবলৈ আৰম্ভ কৰে। ৰোশনি আৰু তেওঁৰ মাক-দেউতাকে অৰবিন্দক ঘূৰি অহা দেখি আৰু নিয়ন্ত্ৰণলৈ অহা দেখি আনন্দিত হৈছে।",
              "gu": "ધીમે ધીમે તેમની પ્રેરણા તેમની પાસે આવવા લાગે છે. રોશની અને તેના માતા-પિતા અરવિંદને ફરી વખત નિયંત્રણમાં જોઈને ઘણા ખુશ થાય છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_5",
              "hi": "A2_1_5",
              "ka": "A2_1_5",
              "te": "A2_1_5",
              "or": "A2_1_5",
              "as": "A2_1_5",
              "gu": "A2_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They support him unconditionally in whatever career path he wants to take.",
              "hi": "वे किसी भी कैरियर पथ में बिना शर्त रूप से उसका समर्थन करते हैं।",
              "ka": "ಅವರು ಯಾವುದೇ ವೃತ್ತಿಜೀವನದ ಹಾದಿಯಲ್ಲಿ ಹೋಗಲು ಅವರು ಬೇಷರತ್ತಾಗಿ ಬೆಂಬಲಿಸುತ್ತಾರೆ.",
              "te": "అతను ఏ కెరీర్‌లో వెళ్లాలనుకున్నా వారు అతనికి పూర్తి మద్దతు ఇస్తారు.",
              "or": "ସେ ଯେକୌଣସି କ୍ୟାରିୟର ପଥରେ ନେବାକୁ ଚାହାଁନ୍ତି, ସେମାନେ ତାଙ୍କୁ ବିନା ସର୍ତ୍ତରେ ସମର୍ଥନ କରନ୍ତି |",
              "as": "তেওঁলোকে যিকোনো কেৰিয়াৰৰ পথত তেওঁক চৰ্তহীনভাৱে সমৰ্থন কৰে।",
              "gu": "તે જે પણ કારકિર્દીના માર્ગ ઉપર આગળ વધી રહ્યો છે તેમાં કોઈ જ શરત વગર સમર્થન આપે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_6//",
              "hi": "A2_1_6 //",
              "ka": "A2_1_6//",
              "te": "A2_1_6//",
              "or": "A2_1_6//",
              "as": "A2_1_6//",
              "gu": "A2_1_6//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Roshni, I want to do something. I can’t sit like this forever, right? I will have to go out there. At least get qualified. The loan Appa has taken is still sitting on our heads. I have to clear that as soon as I can.",
              "hi": "रोशनी, मैं कुछ करना चाहता हूं। मैं हमेशा के लिए इस तरह नहीं बैठ सकता, है ना? मुझे वहां जाना होगा। कम से कम योग्य हो जाऊँ।  अप्पा ने जो ऋण लिया है वह अभी भी अपने सिर पर चढ़ा है। मुझे यह सब जल्दी से जल्दी उतारना होगा।",
              "ka": "ರೋಶನಿ, ನಾನು ಏನಾದರೂ ಮಾಡಬೇಕೆಂದು ಬಯಸುತ್ತೇನೆ. ನಾನು ಈ ರೀತಿ ಶಾಶ್ವತವಾಗಿ ಕುಳಿತುಕೊಳ್ಳಲು ಸಾಧ್ಯವಿಲ್ಲ, ಸರಿ? ನಾನು ಅಲ್ಲಿಗೆ ಹೋಗಬೇಕು. ಕನಿಷ್ಠ ಅರ್ಹತೆ ಪಡೆಯಿರಿ. ಅಪ್ಪ ಮಾಡಿದ ಸಾಲ ಇನ್ನೂ ತಲೆ ಮೇಲೆ ಕೂತಿದೆ. ನಾನು ಸಾಧ್ಯವಾದಷ್ಟು ಬೇಗ ಅದನ್ನು ತೆರವುಗೊಳಿಸಬೇಕಾಗಿದೆ.",
              "te": "రోష్నీ, నేను ఏదో ఒకటి చేయాలనుకుంటున్నాను. నేను ఎప్పటికీ ఇలా కూర్చోలేను, కదా? నేను అక్కడకు వెళ్ళవలసి ఉంటుంది. కనీసం అర్హత పొందడానికి. అప్పా తీసుకున్న రుణభారం ఇప్ప టికీ మనపై ఉంది. నేను వీలైనంత త్వరగా దాన్ని క్లియర్ చేయాలి.",
              "or": "ରୋଶନି, ମୁଁ କିଛି କରିବାକୁ ଚାହୁଁଛି | ମୁଁ ସବୁଦିନ ପାଇଁ ଏହିପରି ବସି ପାରିବି ନାହିଁ, ଠିକ୍ ? ମୋତେ ସେଠାକୁ ଯିବାକୁ ପଡିବ | ଅତିକମରେ ଯୋଗ୍ୟତା ପ୍ରାପ୍ତ ପାଇଁ | ଆପ୍ପା ନେଇଥିବା ୠଣ ଏବେ ବି ଆମ ମୁଣ୍ଡ ଉପରେ ଅଛି | ମୋତେ ଯେତେ ଶୀଘ୍ର ସମ୍ଭବ ଶୂଝିଦେବାକୁ ପଡିବ |",
              "as": "ৰোশনি, মই কিবা এটা কৰিব বিচাৰো। মই চিৰকাল এনেদৰে বহি থাকিব নোৱাৰো, হয় নে নহয়? মই তালৈ যাব লাগিব। অন্ততঃ যোগ্যতা অৰ্জন কৰিবলৈ যাব লাগিব। আপ্পাই লোৱা ঋণটো এতিয়াও আমাৰ মূৰৰ ওপৰত আছে। মই যিমান সোনকালে পাৰো সেইটো পৰিশোধ কৰিব লাগিব।",
              "gu": "રોશની મારે કંઈક કરવું છે. હું આ રીતે હંમેશા બેસી શકું નહીં, બરોબરને?હું ત્યાંથી બહાર જઈશ. કમ સે કેમ યોગ્યતા પ્રાપ્ત કરવા. અપ્પાએ જે દેવું લીધેલું છે તે આજે પણ આપણા માથે છે.મારે જલ્દીથી તે પૂરું કરવું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Yes, Arvind, I agree! It would be so good for you to take that step finally.",
              "hi": "हाँ, अरविंद, मैं सहमत हूँ! अंत में उस कदम को लेना तुम्हारे लिए बहुत अच्छा रहेगा।",
              "ka": "ಹೌದು, ಅರವಿಂದ್, ನಾನು ಒಪ್ಪುತ್ತೇನೆ! ನೀವು ಅಂತಿಮವಾಗಿ ಆ ಹೆಜ್ಜೆ ಇಡುವುದು ತುಂಬಾ ಒಳ್ಳೆಯದು.",
              "te": "అవును, అరవింద్, నేను అంగీకరిస్తున్నాను! నువ్వు చివరిగా ఆ అడుగు వేస్తే చాలా బాగుంటుంది.",
              "or": "ହଁ, ଅରବିନ୍ଦ, ମୁଁ ରାଜି! ଶେଷରେ ସେହି ପଦକ୍ଷେପ ନେବା ତୁମ ପାଇଁ କେତେ ଭଲ ହେବ |",
              "as": "হয়, অৰবিন্দ, মই সন্মত! অৱশেষত সেই পদক্ষেপটো লোৱাটো আপোনাৰ বাবে বহুত ভাল হ'ব।",
              "gu": "હા, અરવિંદ, હું સહમત છું.તમારા માટે અંતે આ પગલું ભરવું કેટલું સારું રહેશે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_1_7////",
              "hi": "A2_1_7 /////",
              "ka": "A2_1_7////",
              "te": "A2_1_7////",
              "or": "A2_1_7////",
              "as": "A2_1_7////",
              "gu": "A2_1_7////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "But I don't want to go back to the hostel or the college. I'm too afraid of having to live that experience all over again.",
              "hi": "लेकिन मैं हॉस्टल या कॉलेज में वापस नहीं जाना चाहता। मैं उस अनुभव को फिर से जीने से बहुत डरता हूं।",
              "ka": "ಆದರೆ ನಾನು ಮತ್ತೆ ಹಾಸ್ಟೆಲ್ ಅಥವಾ ಕಾಲೇಜಿಗೆ ಹೋಗಲು ಬಯಸುವುದಿಲ್ಲ. ಆ ಅನುಭವವನ್ನು ಮತ್ತೊಮ್ಮೆ ಜೀವಿಸಬೇಕೆನ್ನುವ ಭಯ ನನಗಿದೆ.",
              "te": "కానీ నేను హాస్టల్‌కి లేదా కాలేజీకి తిరిగి వెళ్లాలని అనుకోవట్లేదు. ఆ అనుభవాన్ని మళ్లీ మళ్లీ గడపాలంటే నాకు చాలా భయమవుతుంది.",
              "or": "କିନ୍ତୁ ମୁଁ ହଷ୍ଟେଲ କିମ୍ବା କଲେଜକୁ ଫେରିବାକୁ ଚାହେଁ ନାହିଁ | ମୁଁ ସେହି ଅନୁଭୂତିରେ ପୁଣି ଥରେ ବଞ୍ଚିବାକୁ ବହୁତ ଭୟ କରେ |",
              "as": "কিন্তু মই হোষ্টেল বা কলেজলৈ ঘূৰি যাব নিবিচাৰো। মই সেই অভিজ্ঞতাটোত পুনৰ জীয়াই থাকিবলৈ বহুত ভয় কৰোঁ।",
              "gu": "પણ હું હોસ્ટેલ અથવા કોલેજ પરત જવા ઈચ્છતો નથી.હું તે અનુભવ સાથે ફરી જીવન જીવવાથી ઘણો ડરી જાઉં છું"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "You might not need to live in that kind of a hostel again, Arvind. Things could be different this time around. You have to trust yourself and move into safer environments for yourself.",
              "hi": "हो सकता है, तुमको उस तरह के छात्रावास में फिर से रहने की आवश्यकता नहीं हो, अरविंद। इस बार चीजें अलग हो सकती हैं। तुमको अपने आप पर भरोसा करना है और अपने लिए सुरक्षित वातावरण में जाना है।",
              "ka": "ನೀವು ಮತ್ತೆ ಅಂತಹ ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿ ವಾಸಿಸುವ ಅಗತ್ಯವಿಲ್ಲ, ಅರವಿಂದ್. ಈ ಸಮಯದಲ್ಲಿ ವಿಷಯಗಳು ವಿಭಿನ್ನವಾಗಿರಬಹುದು. ನೀವು ನಿಮ್ಮನ್ನು ನಂಬಬೇಕು ಮತ್ತು ನಿಮಗಾಗಿ ಸುರಕ್ಷಿತ ಪರಿಸರಕ್ಕೆ ಹೋಗಬೇಕು.",
              "te": "నువ్వు మళ్లీ అలాంటి హాస్టల్‌లో నివసించాల్సిన అవసరం ఉండకపోవచ్చు, అరవింద్. ఈసారి పరిస్థితులు భిన్నంగా ఉండవచ్చు. నిన్ను నువ్వు విశ్వసించాలి మరియు నీకు సురక్షితమైన వాతావరణంలోకి వెళ్లాలి.",
              "or": "ତୁମକୁ ପୁନର୍ବାର ସେହି ପ୍ରକାରର ହଷ୍ଟେଲରେ ରହିବାକୁ ଆବଶ୍ୟକ ନପଡୁ, ଅରବିନ୍ଦ | ଏଥର କିଛି ଅଲଗା ହୋଇପାରେ | ତୁମଙ୍କୁ ନିଜ ଉପରେ ବିଶ୍ୱାସ କରିବାକୁ ପଡିବ ଏବଂ ନିଜ ପାଇଁ ନିରାପଦ ପରିବେଶକୁ ଯିବାକୁ ପଡିବ |",
              "as": "আপুনি হয়তো পুনৰ তেনে ধৰণৰ হোষ্টেলত থকাৰ প্ৰয়োজন নাই, অৰবিন্দ। এইবাৰ বস্তুবোৰ বেলেগ হ'ব পাৰে। আপুনি নিজকে বিশ্বাস কৰিব লাগিব আৰু নিজৰ বাবে সুৰক্ষিত পৰিৱেশলৈ যাব লাগিব।",
              "gu": "એવું પણ બને કે તારે તે પ્રકારની હોસ્ટેલમાં રહેવાની જરૂર ન પણ પડે, અરવિંદ.આ વખત સ્થિતિ બિલકુલ અલગ પણ હોઈ શકે છે.તારે તારી જાત ઉપર વિશ્વાસ રાખવો પડશે અને તારા માટે એક સુરક્ષિત વાતાવરણમાં જવાનું રહેશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Hmm, I agree. But all the members in the support group come from different professional backgrounds. Each of them still faces so many problems regarding their identity.",
              "hi": "हम्म, मैं सहमत हूं। लेकिन समर्थन समूह के सभी सदस्य विभिन्न प्रोफेशनल पृष्ठभूमि से आते हैं। उनमें से प्रत्येक को अभी भी उनकी पहचान के बारे में इतनी सारी समस्याओं का सामना करना पड़ता है।",
              "ka": "ಹಾಂ, ನಾನು ಒಪ್ಪುತ್ತೇನೆ. ಆದರೆ ಬೆಂಬಲ ಗುಂಪಿನಲ್ಲಿರುವ ಎಲ್ಲಾ ಸದಸ್ಯರು ವಿಭಿನ್ನ ವೃತ್ತಿಪರ ಹಿನ್ನೆಲೆಯಿಂದ ಬಂದವರು. ಅವರಲ್ಲಿ ಪ್ರತಿಯೊಬ್ಬರೂ ತಮ್ಮ ಗುರುತಿನ ಬಗ್ಗೆ ಇನ್ನೂ ಅನೇಕ ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುತ್ತಾರೆ.",
              "te": "హ్మ్, నేను అంగీకరిస్తున్నాను. కానీ సపోర్ట్ గ్రూప్‌లోని సభ్యులందరూ విభిన్న వృత్తిపరమైన నేపథ్యాల నుండి వచ్చినవారే. వారిలో ప్రతి ఒక్కరూ ఇప్పటికీ తమ గుర్తింపుకు సంబంధించి చాలా సమస్యలను ఎదుర్కొంటున్నారు.",
              "or": "ହମ୍ମ, ମୁଁ ରାଜି | କିନ୍ତୁ ସମର୍ଥନ ସମୁଦାୟର ସମସ୍ତ ସଦସ୍ୟ ବିଭିନ୍ନ ବୃତ୍ତିଗତ ପୃଷ୍ଠଭୂମିରୁ ଆସିଛନ୍ତି | ସେମାନଙ୍କ ମଧ୍ୟରୁ ପ୍ରତ୍ୟେକ ତାଙ୍କ ପରିଚୟ ସମ୍ବନ୍ଧରେ ଅନେକ ସମସ୍ୟାର ସମ୍ମୁଖୀନ ହେଉଛନ୍ତି |",
              "as": "হুম, মই ৰাজি আছোঁ। কিন্তু সমৰ্থন গোটৰ সকলো সদস্য বিভিন্ন পেছাদাৰী পৃষ্ঠভূমিৰ পৰা আহিছে। তেওঁলোকৰ প্ৰত্যেকেই এতিয়াও তেওঁলোকৰ পৰিচয় সম্পৰ্কে বহুতো সমস্যাৰ সন্মুখীন হৈছে।",
              "gu": "હા હું સહમત છું. પણ સહાયતા સમૂહના તમામ સભ્યો અલગ-અલગ વ્યવસાયની પુષ્ટભૂમિમાંથી આવે છે. આ પૈકી દરેકને પોતાના ઓળખ અંગે અનેક સમસ્યાનો સામનો કરવો પડે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_2_1",
              "hi": "A2_2_1",
              "ka": "A2_2_1",
              "te": "A2_2_1",
              "or": "A2_2_1",
              "as": "A2_2_1",
              "gu": "A2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He speaks to Vandana, the support group coordinator, and shares that he wants to restart his career journey but is unsure where to begin.",
              "hi": "वह , समर्थन समूह कोऑर्डिनेटर वंदना से बात करता है, और साझा करता है कि वह अपनी करियर यात्रा को पुनरारंभ करना चाहता है लेकिन यह अनिश्चित है कि कहां से शुरू करना है।",
              "ka": "ಅವರು ಬೆಂಬಲ ಗುಂಪಿನ ಸಂಯೋಜಕರಾದ ವಂದನಾ ಅವರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾರೆ ಮತ್ತು ಅವರು ತಮ್ಮ ವೃತ್ತಿಜೀವನದ ಪ್ರಯಾಣವನ್ನು ಮರುಪ್ರಾರಂಭಿಸಲು ಬಯಸುತ್ತಾರೆ ಎಂದು ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ ಆದರೆ ಎಲ್ಲಿ ಪ್ರಾರಂಭಿಸಬೇಕು ಎಂದು ಖಚಿತವಾಗಿಲ್ಲ.",
              "te": "అతను సపోర్ట్ గ్రూప్ కోఆర్డినేటర్ అయిన వందనతో మాట్లాడాడు మరియు అతను తన కెరీర్ జర్నీని పునఃప్రారంభించాలనుకుంటున్నానని, కానీ ఎక్కడ ప్రారంభించాలో తెలియడం లేదని చెప్తాడు.",
              "or": "ସେ ସପୋର୍ଟ ଗ୍ରୁପ୍ କୋ-ଓର୍ଡିନେଟର୍‌, ବନ୍ଦନାଙ୍କ ସହ କଥା ହୁଅନ୍ତି ଏବଂ ଅଂଶୀଦାର କରନ୍ତି ଯେ ସେ ତାଙ୍କ କ୍ୟାରିଅର୍ ଯାତ୍ରାକୁ ପୁନଃଆରମ୍ଭ କରିବାକୁ ଚାହୁଁଛନ୍ତି କିନ୍ତୁ କେଉଁଠାରୁ ଆରମ୍ଭ କରିବେ ତାହା ନିଶ୍ଚିତ ନୁହଁନ୍ତି |",
              "as": "তেওঁ সমৰ্থন গোটৰ সমন্বয়ক বন্দনাৰ সৈতে কথা পাতিছে, আৰু আলোচনা কৰিছে যে তেওঁ তেওঁৰ কেৰিয়াৰ যাত্ৰা পুনৰ আৰম্ভ কৰিব বিচাৰে কিন্তু ক'ৰ পৰা আৰম্ভ কৰিব লাগে সেয়া নিশ্চিত নহয়।",
              "gu": "તે સહાયતા સમૂહના કોઓર્ડિનેટર વંદના સાથે વાત કરે છે, અને વિચાર રજૂ કરે છે કે તે પોતાની કારકિર્દીની યાત્રા પુનઃપ્રારંભ કરવા ઈચ્છે છે. જોકે એ બાબત સુનિશ્ચિત નથી કે તે ક્યાંથી શરૂઆત કરશે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_2_2",
              "hi": "A2_2_2",
              "ka": "A2_2_2",
              "te": "A2_2_2",
              "or": "A2_2_2",
              "as": "A2_2_2",
              "gu": "A2_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He shares that he is looking for a job where he can help LGBTQ+ people feel equal and safe in the society.",
              "hi": "वह साझा करता है कि वह ऐसी नौकरी की तलाश में है जहां वह एलजीबीटीक्यू + लोगों को समाज में बराबर और सुरक्षित महसूस कराने में सहायता कर सकता है।",
              "ka": "ಅವರು LGBTQ+ ಜನರು ಸಮಾಜದಲ್ಲಿ ಸಮಾನ ಮತ್ತು ಸುರಕ್ಷಿತವಾಗಿರಲು ಸಹಾಯ ಮಾಡುವ ಕೆಲಸವನ್ನು ಹುಡುಕುತ್ತಿರುವುದಾಗಿ ಅವರು ಹಂಚಿಕೊಂಡಿದ್ದಾರೆ.",
              "te": "LGBTQ+ వ్యక్తులు సమాజంలో సమానంగా మరియు సురక్షితంగా ఉండేందుకు సహాయం చేసే ఉద్యోగం కోసం తాను వెతుకుతున్నట్లు అతను తెలిపాడు.",
              "or": "ସେ ଅଂଶୀଦାର କରନ୍ତି ଯେ ସେ ଏକ ଚାକିରି ଖୋଜୁଛନ୍ତି ଯେଉଁଠାରେ ସେ LGBTQ + ଲୋକଙ୍କୁ ସମାଜରେ ସମାନ ଏବଂ ନିରାପଦ ଅନୁଭବ କରିବାରେ ସାହାଯ୍ୟ କରିପାରିବେ |",
              "as": "তেওঁ কয় যে তেওঁ এনে এটা চাকৰি বিচাৰি আছে য'ত তেওঁ LGBTQ+ লোকসকলক সমাজত সমান আৰু সুৰক্ষিত অনুভৱ কৰাত সহায় কৰিব পাৰে।",
              "gu": "તે વિચાર રજૂ કરે છે કે તે એક એવી નોકરી શોધી રહ્યો છે કે જ્યાં LGBTQ+ લોકોને સમાજમાં સમાન અને સુરક્ષિતપણાનો અહેસાસ કરવામાં મદદ મળી શકે"
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_2_3",
              "hi": "A2_2_3",
              "ka": "A2_2_3",
              "te": "A2_2_3",
              "or": "A2_2_3",
              "as": "A2_2_3",
              "gu": "A2_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Vandana is happy to hear about Arvind’s ambition.",
              "hi": "वंदना अरविंद की महत्वाकांक्षा के बारे में सुनकर खुश है।",
              "ka": "ಅರವಿಂದ್ ಅವರ ಮಹತ್ವಾಕಾಂಕ್ಷೆಯನ್ನು ಕೇಳಿ ವಂದನಾ ಖುಷಿಯಾಗಿದ್ದಾರೆ.",
              "te": "అరవింద్ ఆశయం గురించి విన్నందుకు వందన సంతోషంగా ఉంది.",
              "or": "ଅରବିନ୍ଦଙ୍କ ଉଚ୍ଚାଭିଳାଷ ବିଷୟରେ ଶୁଣି ବନ୍ଦନା ଖୁସି ହୁଅନ୍ତି |",
              "as": "বন্দনাই অৰবিন্দৰ উচ্চাকাংক্ষাৰ বিষয়ে শুনি সুখী হৈছে।",
              "gu": "અરવિંદની આ મહત્વકાંક્ષા અંગે સાંભળીને વંદનાને ખુશી થઈ"
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_2_4",
              "hi": "A2_2_4",
              "ka": "A2_2_4",
              "te": "A2_2_4",
              "or": "A2_2_4",
              "as": "A2_2_4",
              "gu": "A2_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She connects Arvind to Shikha, a transgender activist working towards equal rights for the LGBTQ+ community. Shikha is a good friend of Vandana.",
              "hi": "वह अरविंद को शिखा से मिलाती है, एक ट्रांसजेंडर कार्यकर्ता एलजीबीटीक्यू + समुदाय के लिए समान अधिकारों की दिशा में काम कर रहा है। शिखा वंदना की एक अच्छी मित्र है।",
              "ka": "ಅವಳು ಅರವಿಂದ್‌ರನ್ನು LGBTQ+ ಸಮುದಾಯಕ್ಕೆ ಸಮಾನ ಹಕ್ಕುಗಳಿಗಾಗಿ ಕೆಲಸ ಮಾಡುವ ಟ್ರಾನ್ಸ್‌ಜೆಂಡರ್ ಕಾರ್ಯಕರ್ತ ಶಿಖಾ ಅವರನ್ನು ಸಂಪರ್ಕಿಸುತ್ತಾಳೆ. ಶಿಖಾ ವಂದನಾಗೆ ಒಳ್ಳೆಯ ಸ್ನೇಹಿತೆ.",
              "te": "ఆమె LGBTQ+ కమ్యూనిటీకి సమాన హక్కుల కోసం పనిచేస్తున్న ట్రాన్స్ జెండర్ కార్యకర్త శిఖతో అరవింద్‌ని కలుపుతుంది. వందనకి శిఖ మంచి స్నేహితురాలు.",
              "or": "ସେ ଅରବିନ୍ଦଙ୍କୁ ଶିଖା ସହିତ ସଂଯୋଗ କରନ୍ତି, LGBTQ + ସମୁଦାୟ ପାଇଁ ସମାନ ଅଧିକାର ଦିଗରେ କାର୍ଯ୍ୟ କରୁଥିବା ଏକ ଟ୍ରାନ୍ସଜେଣ୍ଡର କାର୍ଯ୍ୟକର୍ତ୍ତା | ଶିଖା ବନ୍ଦନାଙ୍କ ଜଣେ ଭଲ ବନ୍ଧୁ |",
              "as": "তেওঁ অৰবিন্দক শিখাৰ সৈতে যোগাযোগ কৰাই দিয়ে, যি LGBTQ+ সম্প্ৰদায়ৰ বাবে সমান অধিকাৰৰ দিশত কাম কৰা এজন ট্ৰেন্সজেণ্ডাৰ কৰ্মী। শিখা বন্দনাৰ এগৰাকী ভাল বান্ধৱী।",
              "gu": "તે અરવિંદને LGBTQ+ સમુદાય માટે સમાન અધિકારોની દિશામાં કામ કરનાર એક ટ્રાન્સલિંગ કાર્યકર્તા શિખા સાથે સંપર્ક કરાવે છે. શિખા એ વંદનાની સારી મિત્ર છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_2_5",
              "hi": "A2_2_5",
              "ka": "A2_2_5",
              "te": "A2_2_5",
              "or": "A2_2_5",
              "as": "A2_2_5",
              "gu": "A2_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He makes a list of questions he wants to ask. He is interested in knowing how they navigated the difficult situations in their life.",
              "hi": "वह उन प्रश्नों की एक सूची बनाता है जो वह पूछना चाहता है। उसे यह जानने में रुचि है कि उन्होंने अपने जीवन में कठिन परिस्थितियों को कैसे नेविगेट किया।",
              "ka": "ಅವರು ಕೇಳಲು ಬಯಸುವ ಪ್ರಶ್ನೆಗಳ ಪಟ್ಟಿಯನ್ನು ಮಾಡುತ್ತಾರೆ. ಅವರು ತಮ್ಮ ಜೀವನದಲ್ಲಿ ಕಷ್ಟಕರ ಸಂದರ್ಭಗಳಲ್ಲಿ ಹೇಗೆ ನ್ಯಾವಿಗೇಟ್ ಮಾಡಿದರು ಎಂಬುದನ್ನು ತಿಳಿದುಕೊಳ್ಳಲು ಅವರು ಆಸಕ್ತಿ ಹೊಂದಿದ್ದಾರೆ.",
              "te": "అతను అడగాలనుకుంటున్న ప్రశ్నలతో ఒక లిస్ట్ ను తయారు చేస్తాడు. వారు తమ జీవితంలోని క్లిష్ట పరిస్థితులను ఎలా ఎదుర్కొన్నారో తెలుసుకోవాలనే ఆసక్తి అతనికి కలిగింది.",
              "or": "ସେ ପଚାରିବାକୁ ଚାହୁଁଥିବା ପ୍ରଶ୍ନଗୁଡ଼ିକର ଏକ ତାଲିକା ପ୍ରସ୍ତୁତ କରନ୍ତି | ସେମାନେ ନିଜ ଜୀବନରେ କଠିନ ପରିସ୍ଥିତିକୁ କିପରି ଗତି କଲେ ତାହା ଜାଣିବାକୁ ସେ ଆଗ୍ରହୀ |",
              "as": "তেওঁ সুধিব বিচৰা প্ৰশ্নবোৰৰ এখন তালিকা প্ৰস্তুত কৰে। তেওঁ জানিবলৈ আগ্ৰহী যে তেওঁলোকে কেনেকৈ তেওঁলোকৰ জীৱনৰ কঠিন পৰিস্থিতিবোৰ কঢ়িয়াই আনিছিল।",
              "gu": "તે એક એવા પ્રશ્નોની યાદી તૈયાર કરે છે કે જે તે પૂછવા ઈચ્છે છે.તે એ જાણવામાં રસ ધરાવે છે કે તે પોતાના જીવનમાં મુશ્કેલ પરિસ્થિતિઓને કેવી રીતે સામનો કર્યો."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_3_1//",
              "hi": "A2_3_1 //",
              "ka": "A2_3_1//",
              "te": "A2_3_1//",
              "or": "A2_3_1//",
              "as": "A2_3_1//",
              "gu": "A2_3_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "The next day, Arvind gets on a call with Shikha.",
              "hi": "अगले दिन, अरविंद शिखा के साथ एक कॉल पर  है।",
              "ka": "ಮರುದಿನ, ಅರವಿಂದ್ ಶಿಖಾಳೊಂದಿಗೆ ಕರೆ ಮಾಡುತ್ತಾರೆ.",
              "te": "మరుసటి రోజు, అరవింద్‌కి శిఖ నుండి కాల్ వస్తుంది.",
              "or": "ପରଦିନ, ଅରବିନ୍ଦ ଶିଖାଙ୍କ ସହ କଲ୍‌ରେ କଥା କରନ୍ତି।",
              "as": "পিছদিনা, অৰবিন্দে শিখালৈ ফোন কৰিলে।",
              "gu": "ત્યારપછીના દિવસે અરવિંદ શિખા સાથે વાત કરે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shikha",
              "hi": "शिखा",
              "ka": "ಶಿಖಾ",
              "te": "శిఖ",
              "or": "ଶିଖା",
              "as": "শিখা",
              "gu": "શિખા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Thank you for taking the time to chat with me. I understand how busy you are.",
              "hi": "मेरे साथ चैट करने के लिए समय निकालने के लिए धन्यवाद। मैं समझता हूं कि आप कितनी व्यस्त हैं।",
              "ka": "ನನ್ನೊಂದಿಗೆ ಚಾಟ್ ಮಾಡಲು ಸಮಯ ತೆಗೆದುಕೊಂಡಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ನೀವು ಎಷ್ಟು ಕಾರ್ಯನಿರತರಾಗಿದ್ದೀರಿ ಎಂದು ನನಗೆ ಅರ್ಥವಾಗಿದೆ.",
              "te": "నాతో చాట్ చేయడానికి సమయాన్ని వెచ్చించినందుకు ధన్యవాదాలు. మీరు ఎంత బిజీగా ఉన్నారో నాకు అర్థమైంది.",
              "or": "ମୋ ସହିତ କଥା କରିବାକୁ ସମୟ ଦେଇଥିବାରୁ ଧନ୍ୟବାଦ | ମୁଁ ବୁଝିପାରୁଛି ଆପଣ କେତେ ବ୍ୟସ୍ତ |",
              "as": "মোৰ সৈতে কথা পাতিবলৈ সময় উলিওৱাৰ বাবে আপোনাক ধন্যবাদ। আপুনি কিমান ব্যস্ত মই বুজি পাইছোঁ ।",
              "gu": "મારી સાથે વાત કરવા માટે સમય પસાર કરવા બદલ તારો આભાર. હું સમજી શકું છું કે તમે કેટલા વ્યસ્ત છો"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Don’t worry, I'm pretty happy to have this conversation.",
              "hi": "चिंता न करें, मैं इस बातचीत के लिए बहुत खुश हूं।",
              "ka": "ಚಿಂತಿಸಬೇಡಿ, ಈ ಸಂಭಾಷಣೆಯನ್ನು ನಡೆಸಲು ನನಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿದೆ.",
              "te": "చింతించకండి, ఈ సంభాషణ జరిగినందుకు నేను చాలా సంతోషంగా ఉన్నాను.",
              "or": "ବ୍ୟସ୍ତ ହୁଅ ନାହିଁ, ଏହି ବାର୍ତ୍ତାଳାପ ପାଇ ମୁଁ ବହୁତ ଖୁସି |",
              "as": "চিন্তা নকৰিব, মই কথা পাতিবলৈ পাই যথেষ্ট সুখী।",
              "gu": "ચિંતા ન કરશો, મને પણ આ વાતચીત કરતાં ઘણી ખુશી થઈ છે"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "I have a few questions.",
              "hi": "मेरे कुछ प्रश्न हैं।",
              "ka": "ನನಗೆ ಕೆಲವು ಪ್ರಶ್ನೆಗಳಿವೆ.",
              "te": "నాకు కొన్ని ప్రశ్నలు ఉన్నాయి.",
              "or": "ମୋର କିଛି ପ୍ରଶ୍ନ ଅଛି |",
              "as": "মোৰ কেইটামান প্ৰশ্ন আছে।",
              "gu": "મારે પણ કેટલાક પ્રશ્નો છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_3_2",
              "hi": "A2_3_2",
              "ka": "A2_3_2",
              "te": "A2_3_2",
              "or": "A2_3_2",
              "as": "A2_3_2",
              "gu": "A2_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal Shikha's answers to Arvind's questions:",
              "hi": "अरविंद के सवालों के शिखा के उत्तर प्रकट करने के लिए क्लिक करें:",
              "ka": "ಅರವಿಂದ್ ಅವರ ಪ್ರಶ್ನೆಗಳಿಗೆ ಶಿಖಾ ಅವರ ಉತ್ತರಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "అరవింద్ ప్రశ్నలకు శిఖ సమాధానాలను వెల్లడించడానికి క్లిక్ చేయండి:",
              "or": "ଅରବିନ୍ଦଙ୍କ ପ୍ରଶ୍ନକୁ ଶିଖାଙ୍କ ଉତ୍ତର ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "অৰবিন্দৰ প্ৰশ্নবোৰৰ শিখাৰ উত্তৰ জানিবলৈ ক্লিক কৰক:",
              "gu": "અરવિંદના પ્રશ્નોના શિખાએ આપેલા જવાબ અંગે જાણવા માટે અહીં ક્લિક કરોઃ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "What was the motivation behind taking up the role of an activist?",
              "hi": "एक कार्यकर्ता की भूमिका लेने के पीछे प्रेरणा क्या थी?",
              "ka": "ಕಾರ್ಯಕರ್ತನ ಪಾತ್ರವನ್ನು ತೆಗೆದುಕೊಳ್ಳುವ ಹಿಂದಿನ ಪ್ರೇರಣೆ ಏನು?",
              "te": "కార్యకర్త పాత్రను చేపట్టడం వెనుక ఉన్న ప్రేరణ ఏమిటి?",
              "or": "ଜଣେ କାର୍ଯ୍ୟକର୍ତ୍ତାଙ୍କ ଭୂମିକା ଗ୍ରହଣ କରିବା ପଛରେ ପ୍ରେରଣା କ’ଣ ଥିଲା ?",
              "as": "এজন কৰ্মী হিচাপে কাম কৰাৰ আঁৰত কি অনুপ্ৰেৰণা আছিল?",
              "gu": "એક કાર્યકર્તા તરીકેની ભૂમિકા ભજવવા પાછળ શું પ્રેરણા હતી?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "What were the challenges you faced because of your identity?",
              "hi": "आपकी पहचान के कारण आपको किन चुनौतियों का सामना करना पड़ा?",
              "ka": "ನಿಮ್ಮ ಗುರುತಿನ ಕಾರಣದಿಂದ ನೀವು ಎದುರಿಸಿದ ಸವಾಲುಗಳೇನು?",
              "te": "మీ గుర్తింపు కారణంగా మీరు ఎదుర్కొన్న సవాళ్లు ఏమిటి?",
              "or": "ତୁମର ପରିଚୟ ହେତୁ ତୁମେ କେଉଁ ଚ୍ଯାଲେଞ୍ଜଗୁଡିକ ସାମ୍ନା କରିଥିଲ ?",
              "as": "আপোনাৰ পৰিচয়ৰ বাবে আপুনি কি প্ৰত্যাহ্বানৰ সন্মুখীন হৈছিল?",
              "gu": "પોતાની ઓળખને લીધે તમારે કેવા કેવા પડકારોનો સામનો કરવો પડ્યો હતો?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "I wanted to make our society a fair place for LGBTQ+ people. I myself have faced discrimination and harassment for being different, and I did not want other people to have the same experience.",
              "hi": "मैं अपने समाज को एलजीबीटीक्यू + लोगों के लिए एक उचित स्थान बनाना चाहता था। मुझे खुद के अलग होने के कारण भेदभाव और उत्पीड़न का सामना करना पड़ा है, और मैं नहीं चाहता था कि अन्य लोगों को ऐसा ही अनुभव हो।",
              "ka": "ನಮ್ಮ ಸಮಾಜವನ್ನು LGBTQ+ ಜನರಿಗೆ ನ್ಯಾಯಯುತ ಸ್ಥಳವನ್ನಾಗಿ ಮಾಡಲು ನಾನು ಬಯಸುತ್ತೇನೆ. ನಾನು ವಿಭಿನ್ನವಾಗಿರುವ ಕಾರಣಕ್ಕಾಗಿ ತಾರತಮ್ಯ ಮತ್ತು ಕಿರುಕುಳವನ್ನು ಎದುರಿಸಿದ್ದೇನೆ ಮತ್ತು ಇತರ ಜನರು ಅದೇ ಅನುಭವವನ್ನು ಹೊಂದಲು ನಾನು ಬಯಸಲಿಲ್ಲ.",
              "te": "నేను మన సొసైటీని LGBTQ+ వ్యక్తులకు సౌకర్యమైన ప్రదేశంగా మార్చాలనుకున్నాను. నేను విభిన్నంగా ఉన్నందుకు వివక్ష మరియు వేధింపులను ఎదుర్కొన్నాను మరియు ఇతరులకు కూడా అదే అనుభవం ఉండకూడదని నేను కోరుకున్నాను.",
              "or": "ମୁଁ ଆମ ସମାଜକୁ LGBTQ + ଲୋକଙ୍କ ପାଇଁ ଏକ ଉପଯୁକ୍ତ ସ୍ଥାନ କରିବାକୁ ଚାହେଁ | ଅଲଗା ହୋଇଥିବାରୁ ମୁଁ ନିଜେ ଭେଦଭାବ ଓ ଉତ୍ପୀଡ଼ନର ସମ୍ମୁଖୀନ ହୋଇଛି, ଏବଂ ମୁଁ ଚାହେଁ ନାହିଁ ଅନ୍ୟ ଲୋକମାନେ ମଧ୍ୟ ସମାନ ଅନୁଭୂତି କରନ୍ତୁ |",
              "as": "মই আমাৰ সমাজক LGBTQ+ লোকসকলৰ বাবে এক উচিত স্থান হিচাপে গঢ়ি তুলিব বিচাৰিছিলো। মই নিজে বেলেগ হোৱাৰ বাবে বৈষম্য আৰু হাৰাশাস্তিৰ সন্মুখীন হৈছো, আৰু মই বিচৰা নাছিলোঁ যে আন লোকৰো একেই অভিজ্ঞতা হওক।",
              "gu": "હું મારા સમાજને LGBTQ+ લોકો માટે એક યોગ્ય સ્થાન બનાવવા ઈચ્છતો હતો. હું પોતે પણ અલગ હોવા અંગે ભેદભાવ તથા ઉત્પીડનનો સામનો કર્યો છે, અને હું ઈચ્છતો ન હતો કે અન્ય લોકો પણ આ પ્રકારનો જ અનુભવ કરતાં હોય."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "I lived in a very conservative neighbourhood and was teased constantly for my identity. I ran away from home and started living on my own in the city. I even had to beg at one point, to support my education. Eventually I was able to find an organisation that supported me.",
              "hi": "मैं एक बहुत ही रूढ़िवादी पड़ोस में रहता था और मेरी पहचान के लिए मुझे लगातार चिढ़ाया जाता था। मैं घर से भाग गया और शहर में अकेले रहने लगा। मुझे अपनी शिक्षा प्राप्ति के लिए एक समय पर भीख माँगनी पड़ी। आखिरकार मुझे एक ऐसा संगठन मिल गया जिसने मेरा सहयोग किया।",
              "ka": "ನಾನು ಅತ್ಯಂತ ಸಂಪ್ರದಾಯವಾದಿ ನೆರೆಹೊರೆಯಲ್ಲಿ ವಾಸಿಸುತ್ತಿದ್ದೆ ಮತ್ತು ನನ್ನ ಗುರುತಿಗಾಗಿ ನಿರಂತರವಾಗಿ ಕೀಟಲೆ ಮಾಡಲಾಗುತ್ತಿತ್ತು. ನಾನು ಮನೆಯಿಂದ ಓಡಿಹೋಗಿ ನಗರದಲ್ಲಿ ಸ್ವಂತವಾಗಿ ವಾಸಿಸಲು ಪ್ರಾರಂಭಿಸಿದೆ. ನನ್ನ ಶಿಕ್ಷಣವನ್ನು ಬೆಂಬಲಿಸಲು ನಾನು ಒಂದು ಹಂತದಲ್ಲಿ ಬೇಡಿಕೊಳ್ಳಬೇಕಾಯಿತು. ಅಂತಿಮವಾಗಿ ನನಗೆ ಬೆಂಬಲ ನೀಡಿದ ಸಂಸ್ಥೆಯನ್ನು ಹುಡುಕಲು ಸಾಧ್ಯವಾಯಿತು.",
              "te": "నేను చాలా సాంప్రదాయిక పరిసరాల్లో నివసించాను మరియు నా గుర్తింపు వల్ల నిరంతరం ఆటపట్టించబడ్డాను. ఇంటి నుంచి పారిపోయి నగరంలో సొంతంగా బతకడం మొదలుపెట్టాను. నా చదువు కోసం ఒకానొక సమయంలో నేను అడుక్కోవలసి వచ్చింది. చివరికి నాకు మద్దతు ఇచ్చే సంస్థను కనుగొనగలిగాను.",
              "or": "ମୁଁ ଏକ ରକ୍ଷଣଶୀଳ ଅଞ୍ଚଳରେ ରହୁଥିଲି ଏବଂ ମୋର ପରିଚୟ ପାଇଁ କ୍ରମାଗତ ଭାବରେ ଥଟ୍ଟା ହେଉଥିଲି | ମୁଁ ଘରୁ ପଳାଇ ଆସିଲି ଏବଂ ସହରରେ ନିଜେ ରହିବାକୁ ଲାଗିଲି | ମୋର ଶିକ୍ଷାକୁ ସମର୍ଥନ କରିବା ପାଇଁ, ମୋତେ ଏକ ସମୟରେ ଭିକ ମାଗିବାକୁ ମଧ୍ଯ ପଡିଲା | ପରିଶେଷରେ ମୁଁ ଏକ ସଂସ୍ଥା ପାଇବାକୁ ସକ୍ଷମ ହେଲି ଯାହା ମୋତେ ସମର୍ଥନ କଲା |",
              "as": "মই এটা অতি ৰক্ষণশীল চুবুৰীত বাস কৰিছিলো আৰু মোৰ পৰিচয়ৰ বাবে নিৰন্তৰ জোকাৰি আছিলোঁ। মই ঘৰৰ পৰা পলাই গৈছিলো আৰু চহৰখনত নিজে থাকিবলৈ আৰম্ভ কৰিছিলো। আনকি মই এটা সময়ত, মোৰ শিক্ষাৰ বাবে ভিক্ষা কৰিব লগা হৈছিল। অৱশেষত মই এটা প্ৰতিষ্ঠান বিচাৰি উলিয়াবলৈ সক্ষম হৈছিলো যিয়ে মোক সমৰ্থন কৰিছিল।",
              "gu": "હું એક ખૂબ જ રૂઢીવાદી પડોશીમાં રહેતો હતો અને મારી ઓળખ માટે મને સતત ચિડવવામાં આવતો હતો. હું ઘરેથી ભાગી ગયો અને શહેરમાં અલગથી રહેવા લાગ્યો.મને મારા શિક્ષણના સમર્થન કરવા માટે એક મુદ્દે ભીખ માગવી પડેલી.છેવટે મને એક સંસ્થા મળી ગઈ, જે મારે મદદ કરતી હતી."
            }
          }
        ],
        "page22": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_3_2b",
              "hi": "A2_3_2B",
              "ka": "A2_3_2b",
              "te": "A2_3_2b",
              "or": "A2_3_2b",
              "as": "A2_3_2b",
              "gu": "A2_3_2b"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal Shikha's answers to Arvind's questions:",
              "hi": "अरविंद के सवालों के शिखा के उत्तर प्रकट करने के लिए क्लिक करें:",
              "ka": "ಅರವಿಂದ್ ಅವರ ಪ್ರಶ್ನೆಗಳಿಗೆ ಶಿಖಾ ಅವರ ಉತ್ತರಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "అరవింద్ ప్రశ్నలకు శిఖ యొక్క సమాధానాలను వెల్లడించడానికి క్లిక్ చేయండి:",
              "or": "ଅରବିନ୍ଦଙ୍କ ପ୍ରଶ୍ନକୁ ଶିଖାଙ୍କ ଉତ୍ତର ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "অৰবিন্দৰ প্ৰশ্নবোৰৰ শিখাৰ উত্তৰ জানিবলৈ ক্লিক কৰক:",
              "gu": "અરવિંદના પ્રશ્નો અંગે શિખાએ આપેલા જવાબને જોવા માટે અહીં ક્લિક કરોઃ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Why do you do the work you do?",
              "hi": "आप जो काम करते हैं वो क्यों करते हैं?",
              "ka": "ನೀವು ಮಾಡುವ ಕೆಲಸವನ್ನು ಏಕೆ ಮಾಡುತ್ತೀರಿ?",
              "te": "మీరు చేసే పని ఎందుకు చేస్తారు?",
              "or": "ତୁମେ କରୁଥିବା କାମ କାହିଁକି କରୁଛ?",
              "as": "আপুনি কৰা কামটো আপুনি কিয় কৰে?",
              "gu": "તમે જે કામ કરો છો તે શા માટે કરો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "The problem is that we always feel unsafe because the law does not protect our rights as much as they would protect the rights of other citizens. I have faced many situations where I was treated inhumanely. So I want to work on delivering basic rights to everyone in my community.",
              "hi": "समस्या यह है कि हम हमेशा असुरक्षित महसूस करते हैं क्योंकि कानून हमारे अधिकारों की रक्षा नहीं करता जितना वे अन्य नागरिकों के अधिकारों की रक्षा करेंगे। मैंने कई स्थितियों का सामना किया है जहां मेरे साथ अमानवीय तरीके से व्यवहार किया गया था। तो मैं अपने समुदाय में हर किसी के लिए बुनियादी अधिकार देने पर काम करना चाहता हूं।",
              "ka": "ಸಮಸ್ಯೆಯೆಂದರೆ ನಾವು ಯಾವಾಗಲೂ ಅಸುರಕ್ಷಿತರಾಗಿದ್ದೇವೆ ಏಕೆಂದರೆ ಕಾನೂನು ನಮ್ಮ ಹಕ್ಕುಗಳನ್ನು ಇತರ ನಾಗರಿಕರ ಹಕ್ಕುಗಳನ್ನು ರಕ್ಷಿಸುವಷ್ಟು ರಕ್ಷಿಸುವುದಿಲ್ಲ. ನನ್ನನ್ನು ಅಮಾನವೀಯವಾಗಿ ನಡೆಸಿಕೊಂಡ ಹಲವು ಸನ್ನಿವೇಶಗಳನ್ನು ಎದುರಿಸಿದ್ದೇನೆ. ಹಾಗಾಗಿ ನನ್ನ ಸಮುದಾಯದ ಪ್ರತಿಯೊಬ್ಬರಿಗೂ ಮೂಲಭೂತ ಹಕ್ಕುಗಳನ್ನು ತಲುಪಿಸುವ ಕೆಲಸ ಮಾಡಲು ನಾನು ಬಯಸುತ್ತೇನೆ.",
              "te": "సమస్య ఏమిటంటే, ఇతర పౌరుల హక్కులను రక్షించినంతగా చట్టం మన హక్కులను రక్షించదు కాబట్టి మనం ఎల్లప్పుడూ అసురక్షితంగా భావిస్తున్నాము. నా పట్ల అమానుషంగా ప్రవర్తించిన అనేక పరిస్థితులను నేను ఎదుర్కొన్నాను. కాబట్టి నా కమ్యూనిటీలోని ప్రతి ఒక్కరికీ ప్రాథమిక హక్కులను అందించడానికి నేను ఈ పని చేయాలనుకున్నాను.",
              "or": "ସମସ୍ୟା ହେଉଛି ଯେ ଆମେ ସର୍ବଦା ନିଜକୁ ଅସୁରକ୍ଷିତ ମନେ କରୁ କାରଣ ଆଇନ ଆମର ଅଧିକାରଗୁଡିକୁ ସେତିକି ସୁରକ୍ଷା ଦେଉନାହିଁ ଯେତିକି ଅନ୍ଯ ନାଗରିକଙ୍କ ଅଧିକାରଗୁଡିକୁ ସୁରକ୍ଷା ଦିଏ। । ମୁଁ ଅନେକ ପରିସ୍ଥିତିର ସାମ୍ନା କରିଛି ଯେଉଁଠି ମୋତେ ଅମାନୁଷିକ ଭାବରେ ବ୍ୟବହାର କରାଯାଇଥିଲା | ତେଣୁ ମୁଁ ମୋର ସମୁଦାୟର ସମସ୍ତଙ୍କୁ ମୌଳିକ ଅଧିକାର ପ୍ରଦାନ ଉପରେ କାର୍ଯ୍ୟ କରିବାକୁ ଚାହୁଁଛି |",
              "as": "সমস্যাটো হ'ল যে আমি সদায় অসুৰক্ষিত অনুভৱ কৰোঁ কিয়নো আইনে আমাৰ অধিকাৰবোৰ সিমান সুৰক্ষিত নকৰে যিমান তেওঁলোকে আন নাগৰিকৰ অধিকাৰ সুৰক্ষিত কৰে। মই বহুতো পৰিস্থিতিৰ সন্মুখীন হৈছো য'ত মোক অমানৱীয় ব্যৱহাৰ কৰা হৈছিল। গতিকে মই মোৰ সম্প্ৰদায়ৰ সকলোকে মৌলিক অধিকাৰ প্ৰদান কৰাৰ বাবে কাম কৰিব বিচাৰো।",
              "gu": "સમસ્યા એ છે કે આપણે હંમેશા અસુરક્ષાનો જ અહેસાસ કરે છે કારણ કે કાયદો હંમેશા અધિકારોની એટલી રક્ષા કરતા નથી કે જેટલા તે અન્ય નાગરિકોના અધિકારોની કરે છે.મે અનેક પરિસ્થિતિઓનો સામનો કરેલો છે, જ્યાં મારી સાથે અમાનવિય વ્યવહાર કરવામાં આવ્યો હોય. માટે હું મારા સમુદાયમાં સૌને પાયાગત અધિકાર આપવા અંગે કામ કરવા માગું છું."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_3_3",
              "hi": "A2_3_3",
              "ka": "A2_3_3",
              "te": "A2_3_3",
              "or": "A2_3_3",
              "as": "A2_3_3",
              "gu": "A2_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is moved by Shikha’s story and the struggles she had to go through.",
              "hi": "अरविंद शिखा की कहानी और उसको जिस संघर्ष का सामना करना पड़ा, उसे सुनकर द्रवित हो जाता है।",
              "ka": "ಅರವಿಂದ್ ಶಿಖಾಳ ಕಥೆ ಮತ್ತು ಅವಳು ಅನುಭವಿಸಿದ ಹೋರಾಟದಿಂದ ಭಾವುಕರಾಗಿದ್ದಾರೆ.",
              "te": "శిఖ కథ మరియు ఆమె పడిన కష్టాలు విని అరవింద్ కదిలిపోయాడు.",
              "or": "ଅରବିନ୍ଦ ଶିଖାଙ୍କ କାହାଣୀ ଏବଂ ସେ ଗତି କରି ଯାଇଥିବା ସଂଘର୍ଷଗୁଡିକ ରୁ ପ୍ରଭାବିତ ହୋଇଥିଲେ |",
              "as": "অৰবিন্দে শিখাৰ কাহিনী আৰু তাই সংগ্ৰামৰ মাজেৰে যাব লগীয়া হোৱা কথাৰ দ্বাৰা আপ্লুত হৈছে।",
              "gu": "અરવિંદ શિખાની કહાની અને તેના સંઘર્ષોથી ખૂબ જ પ્રભાવીત છે, જેમાંથી તેને પસાર થવું પડ્યું."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_3_4",
              "hi": "A2_3_4",
              "ka": "A2_3_4",
              "te": "A2_3_4",
              "or": "A2_3_4",
              "as": "A2_3_4",
              "gu": "A2_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After speaking to Shikha, what stands out for Arvind is her resilience, kindness, and desire to work for people from the LGBTQ+ community to ensure they have access to their basic rights.",
              "hi": "शिखा से बात करने के बाद, अरविंद के लिए जो बात सबसे अलग है, वह है उनका लचीलापन, दयालुता, और एलजीबीटीक्यू+ समुदाय के लोगों के लिए काम करने की इच्छा, ताकि यह सुनिश्चित हो सके कि उनके पास उनके मूल अधिकारों तक पहुंच है।",
              "ka": "ಶಿಖಾ ಅವರೊಂದಿಗೆ ಮಾತನಾಡಿದ ನಂತರ, ಅರವಿಂದ್ ಅವರ ಸ್ಥಿತಿಸ್ಥಾಪಕತ್ವ, ದಯೆ ಮತ್ತು LGBTQ+ ಸಮುದಾಯದ ಜನರಿಗೆ ಅವರ ಮೂಲಭೂತ ಹಕ್ಕುಗಳಿಗೆ ಪ್ರವೇಶವಿದೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಕೆಲಸ ಮಾಡುವ ಬಯಕೆಯಾಗಿದೆ.",
              "te": "శిఖతో మాట్లాడిన తర్వాత, LGBTQ+ కమ్యూనిటీకి చెందిన వ్యక్తులకు వారి ప్రాథమిక హక్కులకు ప్రాప్యత ఉండేలా చూడాలనే ఆమె యొక్క దృఢత్వం, దయ మరియు కోరిక అరవింద్‌కు బాగా నచ్చాయి.",
              "or": "ଶିଖାଙ୍କ ସହ କଥା ହେବା ପରେ, ଅରବିନ୍ଦଙ୍କ ପାଇଁ ଯାହା ସବୁଠୁ ଅଲଗା, ତାହା ହେଉଛି ତାଙ୍କର ନମନୀୟତା, ଦୟା, ଏବଂ LGBTQ + ସମୁଦାୟର ଲୋକଙ୍କ ପାଇଁ ସେମାନଙ୍କର ମୌଳିକ ଅଧିକାର ପାଇବାକୁ ସୁନିଶ୍ଚିତ କରିବାକୁ କାମ କରିବାକୁ ଇଛା |",
              "as": "শিখাৰ সৈতে কথা পতাৰ পিছত, অৰবিন্দৰ বাবে যিটো কাম সেয়া হ'ল LGBTQ + সম্প্ৰদায়ৰ লোকসকলৰ বাবে তেওঁলোকৰ মৌলিক অধিকাৰ প্ৰাপ্ত কৰাটো নিশ্চিত কৰাৰ বাবে তেওঁৰ স্থিতিস্থাপকতা, দয়া আৰু কাম কৰাৰ আকাংক্ষা।",
              "gu": "શિખા સાથે વાતચીત કર્યાં બાદ અરવિંદ માટે જે વાત સૌથી અલગ છે, તે એ છે કે તેનું લચીલાપણુ, દયાળુ, અને LGBTQ+ સમુદાયના લોકો માટે કામ કરવાની ઈચ્છા, જેથી એ સુનિશ્ચિત થઈ શકે કે તેમની પાસે તેમના મૂળ અધિકારો સુધી પહોંચે છે."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "RoleModels",
              "hi": "रोल मॉडल्स",
              "ka": "ರೋಲ್ ಮಾಡೆಲ್",
              "te": "రోల్ మోడల్స్",
              "or": "RoleModels",
              "as": "আদৰ্শব্যক্তিসকল",
              "gu": "આદર્શો"
            }
          }
        ],
        "page23": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "ಎಂಸಿಕ್ಯೂ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Arvind decides he wishes to pursue a career which helps LGBTQ+ people and speaks with Shikha, a transgender lawyer to help get clarity on his next steps for his career. Which of the following is TRUE about role models?",
              "hi": "अरविंद निश्चय करता है कि वह एक ऐसा करियर बनाना चाहता है जो एलजीबीटीक्यू+ लोगों की सहायता करे और शिखा के साथ बात करे, जो एक ट्रांसजेंडर वकील है ताकि उसके करियर के अगले कदमों पर स्पष्टता प्राप्त करने में सहायता मिल सके। रोल मॉडल के बारे में निम्नलिखित में से कौन सा सत्य है?",
              "ka": "ಅರವಿಂದ್ ಅವರು LGBTQ+ ಜನರಿಗೆ ಸಹಾಯ ಮಾಡುವ ವೃತ್ತಿಜೀವನವನ್ನು ಮುಂದುವರಿಸಲು ಬಯಸುತ್ತಾರೆ ಮತ್ತು ಅವರ ವೃತ್ತಿಜೀವನದ ಮುಂದಿನ ಹಂತಗಳ ಬಗ್ಗೆ ಸ್ಪಷ್ಟತೆ ಪಡೆಯಲು ಸಹಾಯ ಮಾಡಲು ಟ್ರಾನ್ಸ್ಜೆಂಡರ್ ವಕೀಲರಾದ ಶಿಖಾ ಅವರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾರೆ. ರೋಲ್ ಮಾಡೆಲ್‌ಗಳ ಬಗ್ಗೆ ಈ ಕೆಳಗಿನವುಗಳಲ್ಲಿ ಯಾವುದು ನಿಜ?",
              "te": "అరవింద్ LGBTQ+ వ్యక్తులకు సహాయపడే వృత్తిని కొనసాగించాలని నిర్ణయించుకున్నాడు మరియు అతని కెరీర్‌కు సంబంధించిన తదుపరి చర్యలపై స్పష్టత పొందడానికి ట్రాన్స్జెండర్ లాయర్ అయిన శిఖతో మాట్లాడాడు. రోల్ మోడల్స్ విషయంలో కింది వాటిలో ఏది నిజం?",
              "or": "ଅରବିନ୍ଦ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି ଯେ ସେ ଏକ କ୍ୟାରିୟର୍‌ ଅନୁସରଣ କରିବାକୁ ଚାହାଁନ୍ତି ଯାହାକି LGBTQ + ଲୋକଙ୍କୁ ସାହାଯ୍ୟ କରେ ଏବଂ ତାଙ୍କ କ୍ୟାରିୟର ପାଇଁ ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପଗୁଡ଼ିକ ଉପରେ ସ୍ପଷ୍ଟତା ଆଣିବାରେ ସାହାଯ୍ୟ କରିବାକୁ ଜଣେ ଟ୍ରାନ୍ସଜେଣ୍ଡର ଓକିଲ ଶିଖାଙ୍କ ସହ କଥା ହୁଅନ୍ତି | ନିମ୍ନଲିଖିତ ମଧ୍ୟରୁ କେଉଁଟି ରୋଲ୍ ମଡେଲ୍‌ଙ୍କ ବିଷୟରେ ସତ୍ୟ ଅଟେ ?",
              "as": "অৰবিন্দে সিদ্ধান্ত লয় যে তেওঁ এনে এটা কেৰিয়াৰ গঢ়িব বিচাৰে যি LGBTQ+ লোকসকলক সহায় কৰে আৰু তেওঁৰ কেৰিয়াৰৰ পৰৱৰ্তী পদক্ষেপবোৰৰ বিষয়ে স্পষ্টতা লাভ কৰাত সহায় কৰিবলৈ এজন ট্ৰেন্সজেণ্ডাৰ উকীলে শিখাৰ সৈতে কথা পাতিছে। আদৰ্শৰ বিষয়ে তলত দিয়া কোনটো সঁচা?",
              "gu": "અરવિંદ નિર્ણય કરે છે કે તેઓ એક એવી કારકિર્દી બનાવવા ઈચ્છે છે કે જે LGTQ+લોકોની મદદ કરે અને શિખા સાથે વાત કરે, જે એક ટ્રાન્સલિંગ વકીલ છે, જેથી તેની કારકિર્દીના હવે પછીના પગલાં પણ સ્પષ્ટતા પ્રાપ્ત કરવામાં મદદ મળી શકે. આદર્શો અંગે નીચે પૈકી કઈ બાબત સત્ય છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "It’s important to spend many days with your role model",
              "hi": "अपने रोल मॉडल के साथ अनेक दिन बिताना महत्वपूर्ण है",
              "ka": "ನಿಮ್ಮ ರೋಲ್ ಮಾಡೆಲ್‌ನೊಂದಿಗೆ ಹಲವು ದಿನಗಳನ್ನು ಕಳೆಯುವುದು ಮುಖ್ಯ",
              "te": "మీ రోల్ మోడల్‌తో చాలా రోజులు గడపడం ముఖ్యం",
              "or": "ତୁମ ରୋଲ ମଡେଲ୍‌ ସହିତ ଅନେକ ଦିନ ବିତାଇବା ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ଅଟେ",
              "as": "আপোনাৰ আদৰ্শব্যক্তিসকলৰ সৈতে বহুদিন কটোৱাটো গুৰুত্বপূৰ্ণ",
              "gu": "પોતાના આદર્શો સાથે અનેક દિવસો પસાર કરવાની જરૂરી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "A role model is someone who inspires you to follow their behaviour and methods to chart a career journey.",
              "hi": "एक रोल मॉडल वह होता है जो आपको उनके व्यवहार और करियर की यात्रा के तरीकों का पालन करने के लिए प्रेरित करता है।",
              "ka": "ಒಬ್ಬ ರೋಲ್ ಮಾಡೆಲ್ ಎಂದರೆ ವೃತ್ತಿಜೀವನವನ್ನು ಚಾರ್ಟ್ ಮಾಡಲು ಅವರ ನಡವಳಿಕೆ ಮತ್ತು ವಿಧಾನಗಳನ್ನು ಅನುಸರಿಸಲು ನಿಮ್ಮನ್ನು ಪ್ರೇರೇಪಿಸುವ ವ್ಯಕ್ತಿ.",
              "te": "కెరీర్ జర్నీని చార్ట్ చేయడానికి వారి ప్రవర్తన మరియు పద్ధతులను అనుసరించడానికి మిమ్మల్ని ప్రేరేపించే వ్యక్తి రోల్ మోడల్.",
              "or": "ଏକ ରୋଲ୍ ମଡେଲ୍ ହେଉଛି ଜଣେ ଯିଏକି ଏକ କ୍ୟାରିଅର୍‌ ଯାତ୍ରା ତାଲିକା କରିବାକୁ ସେମାନଙ୍କର ଆଚରଣ ଏବଂ ପଦ୍ଧତି ଅନୁସରଣ କରିବା ପାଇଁ ତୁମକୁ ପ୍ରେରଣା ଦିଏ |",
              "as": "আদৰ্শব্যক্তি হৈছে এনে এজন ব্যক্তি যিয়ে আপোনাক তেওঁলোকৰ আচৰণ আৰু পদ্ধতি অনুসৰণ কৰি কেৰিয়াৰ যাত্ৰা নিৰ্ধাৰণ কৰিবলৈ অনুপ্ৰাণিত কৰে।",
              "gu": "એક આદર્શો એ છે કે જે તમારા વ્યવહાર અને કારકિર્દીની યાત્રા અંગેની પદ્ધતિનું પાલન કરવા માટે પ્રેરણા આપે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Everyone’s role models can and should be the same",
              "hi": "हर किसी का रोल मॉडल एक जैसा हो सकता है और होना भी चाहिए",
              "ka": "ಪ್ರತಿಯೊಬ್ಬರ ರೋಲ್ ಮಾಡೆಲ್‌ಗಳು ಒಂದೇ ಆಗಿರಬಹುದು ಮತ್ತು ಇರಬೇಕು",
              "te": "ప్రతి ఒక్కరి రోల్ మోడల్స్ ఒకేలా ఉండవచ్చు",
              "or": "ସମସ୍ତଙ୍କର ରୋଲ ମଡେଲ୍‌ ସମାନ ହୋଇପାରେ ଏବଂ ହେବା ଉଚିତ୍ |",
              "as": "সকলোৰে আদৰ্শব্যক্তিসকল একে হ'ব পাৰে আৰু হ'ব লাগে",
              "gu": "દરેક આદર્શો એક સમાન હોઈ શકે છે અને તે હોવું પણ જરૂરી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "A role model’s gender plays no role in how we look at career options",
              "hi": "हम करियर के विकल्पों को कैसे देखते हैं, इसमें रोल मॉडल जेंडर की कोई भूमिका नहीं होती है",
              "ka": "ವೃತ್ತಿಯ ಆಯ್ಕೆಗಳನ್ನು ನಾವು ಹೇಗೆ ನೋಡುತ್ತೇವೆ ಎಂಬುದರಲ್ಲಿ ರೋಲ್ ಮಾಡೆಲ್‌ನ ಲಿಂಗವು ಯಾವುದೇ ಪಾತ್ರವನ್ನು ವಹಿಸುವುದಿಲ್ಲ",
              "te": "మనము కెరీర్ ఎంపికలను ఎలా చూస్తాము అనే విషయంలో రోల్ మోడల్ యొక్క లింగం ఎటువంటి పాత్రను పోషించదు",
              "or": "ଆମେ କ୍ୟାରିୟର ବିକଳ୍ପକୁ କିପରି ଦେଖିବାରେ ଏକ ରୋଲ୍ ମଡେଲ୍‌ଙ୍କ ଲିଙ୍ଗ କୌଣସି ଭୂମିକା ଗ୍ରହଣ କରେ ନାହିଁ |",
              "as": "আমি কেৰিয়াৰৰ বিকল্পবোৰ কেনেদৰে চাওঁ তাত এজন আদৰ্শব্যক্তিৰ লিংগই কোনো ভূমিকা পালন নকৰে",
              "gu": "દરેક કારકિર્દીના વિકલ્પોને કેવી રીતે જોવામાં આવે છે,તેમા આદર્શો લિંગની કોઈ ભૂમિકા હોતી નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Think again! Prepare your questions in advance to make the most of the time you have available with your role model.",
              "hi": "फिर से विचार करो! अपने रोल मॉडल के साथ उपलब्ध समय का अधिक से अधिक लाभ लेने के लिए अपने प्रश्नों को पहले से तैयार कर लो।",
              "ka": "ಪುನಃ ಆಲೋಚಿಸಿ! ನಿಮ್ಮ ರೋಲ್ ಮಾಡೆಲ್‌ನೊಂದಿಗೆ ನೀವು ಲಭ್ಯವಿರುವ ಸಮಯವನ್ನು ಹೆಚ್ಚು ಬಳಸಿಕೊಳ್ಳಲು ನಿಮ್ಮ ಪ್ರಶ್ನೆಗಳನ್ನು ಮುಂಚಿತವಾಗಿ ತಯಾರಿಸಿ.",
              "te": "మళ్లీ ఆలోచించండి! మీ రోల్ మోడల్‌తో మీకు అందుబాటులో ఉన్న సమయాన్ని సద్వినియోగం చేసుకోవడానికి మీ ప్రశ్నలను ముందుగానే సిద్ధం చేసుకోండి.",
              "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ତୁମର ରୋଲ୍ ମଡେଲ୍ ସହିତ ଉପଲବ୍ଧ ଥିବା ସମୟକୁ ଅଧିକ ଉପଯୋଗ କରିବାକୁ ତୁମର ପ୍ରଶ୍ନଗୁଡ଼ିକୁ ଆଗୁଆ ପ୍ରସ୍ତୁତ କର |",
              "as": "আকৌ চিন্তা কৰক! আপোনাৰ আদৰ্শব্যক্তিজনৰ সৈতে আপোনাৰ থকা সময়ৰ সৰ্বাধিক সদ্ব্যৱহাৰ কৰিবলৈ আপোনাৰ প্ৰশ্নবোৰ আগতীয়াকৈ প্ৰস্তুত কৰক।",
              "gu": "ફરી વખત વિચાર કરવો! પોતાના આદર્શો સાથે ઉપલબ્ધ સમયનો વધારે લાભ મેળવવા માટે પોતાના પ્રશ્નોને પહેલા તૈયાર કરી લેવા."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಉತ್ತಮ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ !",
              "as": "ভাল কাম!",
              "gu": "ખૂબ જ સારી કામગીરી!"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Think again! We choose our own role models based on our values & dreams.",
              "hi": "फिर से विचार करो! हम अपने मूल्यों और सपनों के आधार पर अपने रोल मॉडल चुनते हैं।",
              "ka": "ಪುನಃ ಆಲೋಚಿಸಿ! ನಮ್ಮ ಮೌಲ್ಯಗಳು ಮತ್ತು ಕನಸುಗಳ ಆಧಾರದ ಮೇಲೆ ನಾವು ನಮ್ಮದೇ ಆದ ಮಾದರಿಗಳನ್ನು ಆರಿಸಿಕೊಳ್ಳುತ್ತೇವೆ.",
              "te": "మళ్లీ ఆలోచించండి! మనము విలువలు & కలల ఆధారంగా మన స్వంత రోల్ మోడల్‌లను ఎంచుకుంటాము.",
              "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ଆମର ମୂଲ୍ୟବୋଧ ଏବଂ ସ୍ୱପ୍ନଗୁଡିକ ଉପରେ ଆଧାର କରି ଆମେ ଆମର ନିଜର ରୋଲ ମଡେଲ୍‌ ବାଛିଥାଉ |",
              "as": "আকৌ চিন্তা কৰক! আমি আমাৰ মূল্যবোধ আৰু সপোনৰ ওপৰত আধাৰিত কৰি আমাৰ নিজৰ আদৰ্শব্যক্তিসকলক বাছনি কৰোঁ।",
              "gu": "ફરીથી વિચાર કરવો! આપણે આપણા મૂલ્યો તથા સપનાના આધારે આદર્શો પસંદ કરી છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Think again! Many jobs are gender-stereotyped. For example; Firefighting is popular as a man’s job, which results in many women and other genders not considering it while picking a job",
              "hi": "फिर से विचार करो! अनेक नौकरियां लिंग-रूढ़िवादी हैं। उदाहरण के लिए; अग्निशमन एक पुरुष की नौकरी के रूप में लोकप्रिय है, जिसके फलस्वरूप  महिलाएं और अन्य लिंग नौकरी का चुनाव करते समय इस पर विचार नहीं करते हैं।",
              "ka": "ಪುನಃ ಆಲೋಚಿಸು! ಅನೇಕ ಉದ್ಯೋಗಗಳು ಲಿಂಗ-ಸ್ಟೀರಿಯೊಟೈಪ್ ಆಗಿವೆ. ಉದಾಹರಣೆಗೆ; ಅಗ್ನಿಶಾಮಕವು ಪುರುಷನ ಕೆಲಸವಾಗಿ ಜನಪ್ರಿಯವಾಗಿದೆ, ಇದರಿಂದಾಗಿ ಅನೇಕ ಮಹಿಳೆಯರು ಮತ್ತು ಇತರ ಲಿಂಗಗಳು ಉದ್ಯೋಗವನ್ನು ಆಯ್ಕೆಮಾಡುವಾಗ ಅದನ್ನು ಪರಿಗಣಿಸುವುದಿಲ್ಲ",
              "te": "మళ్లీ ఆలోచించండి! చాలా ఉద్యోగాలు జెండర్-స్టీరియోటైప్ గా ఉంటాయి. ఉదాహరణకి; అగ్నిమాపక పని పురుషుల ఉద్యోగంగా ప్రసిద్ధి చెందింది, దీని ఫలితంగా ఉద్యోగుల ఎంపిక సమయంలో చాలా మంది మహిళలు మరియు ఇతర లింగాల వారిని పరిగణనలోకి తీసుకోరు.",
              "or": "ପୁଣି ଥରେ ଭାବ! ଅନେକ କାର୍ଯ୍ୟ ଲିଙ୍ଗ-ଷ୍ଟେରିଓଟାଇପ୍ | ଉଦାହରଣ ସ୍ଵରୁପ; ଅଗ୍ନି ନିର୍ବାପକ ହେଉଛି ପୁରୁଷଙ୍କ କାର୍ଯ୍ୟ ଭାବରେ ଲୋକପ୍ରିୟ, ଯାହା ଫଳସ୍ୱରୂପ ଅନେକ ମହିଳା ଏବଂ ଅନ୍ୟ ଲିଙ୍ଗର ଲୋକମାନେ ଚାକିରି ବାଛିବାବେଳେ ଏହାକୁ ବିଚାର କରନ୍ତି ନାହିଁ |",
              "as": "আকৌ চিন্তা কৰক! বহুতো চাকৰি লিংগ-গতানুগতিক। উদাহৰণ স্বৰূপে; অগ্নি নিৰ্বাপক চাকৰিটো এজন পুৰুষৰ চাকৰি হিচাপে জনপ্ৰিয়, যাৰ ফলত বহুতো মহিলা আৰু অন্যান্য লিংগৰ মানুহে চাকৰি এটা বাছনি কৰোঁতে ইয়াক বিবেচনা নকৰে",
              "gu": "ફરી વખત વિચારો! કોઈ નોકરીયો લિંગ-રુઢિવાદી છે. જેમ કે; અગ્રિશમન એક પુરુષની નોકરીના સ્વરૂપમાં લોકપ્રિય છે, જેના પરિણામ સ્વરૂપે અનેક મહિલાઓ અને અન્ય લિંગ નોકરીની પસંદગી સમયે આ વિચાર કરતાં નથી."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_3_5",
              "hi": "A2_3_5",
              "ka": "A2_3_5",
              "te": "A2_3_5",
              "or": "A2_3_5",
              "as": "A2_3_5",
              "gu": "A2_3_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is impressed with Shikha’s understanding about the different laws pertaining to the rights of people.",
              "hi": "अरविंद लोगों के अधिकारों से संबंधित विभिन्न कानूनों के बारे में शिखा की समझ से प्रभावित हैं।",
              "ka": "ಜನರ ಹಕ್ಕುಗಳಿಗೆ ಸಂಬಂಧಿಸಿದ ವಿವಿಧ ಕಾನೂನುಗಳ ಬಗ್ಗೆ ಶಿಖಾ ಅವರ ತಿಳುವಳಿಕೆಯಿಂದ ಅರವಿಂದ್ ಪ್ರಭಾವಿತರಾಗಿದ್ದಾನೆ.",
              "te": "ప్రజల హక్కులకు సంబంధించిన వివిధ చట్టాల గురించి శిఖకి ఉన్న అవగాహన చూసి అరవింద్ ఇంప్రెస్ అవుతాడు.",
              "or": "ଲୋକଙ୍କ ଅଧିକାର ସହ ଜଡିତ ବିଭିନ୍ନ ନିୟମ ବିଷୟରେ ଶିଖାଙ୍କ ବୁଝାମଣାରେ ଅରବିନ୍ଦ ପ୍ରଭାବିତ |",
              "as": "মানুহৰ অধিকাৰ সম্পৰ্কীয় বিভিন্ন আইনৰ বিষয়ে শিখাই যি বুজালে তাৰ দ্বাৰা অৰবিন্দ প্ৰভাৱিত হৈছে।",
              "gu": "અરવિંદ લોકોના અધિકારો સંબંધિત વિવિધ કાયદા અંગે શિખાની સમજણથી પ્રભાવિત થાય છે."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_3_6",
              "hi": "A2_3_6",
              "ka": "A2_3_6",
              "te": "A2_3_6",
              "or": "A2_3_6",
              "as": "A2_3_6",
              "gu": "A2_3_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Talking to Shikha and reading more about people working for the LGBTQ+ community, Arvind realises that he has to start from scratch and everything will be new.",
              "hi": "शिखा से बात करते हुए और एलजीबीटीक्यू + समुदाय के लिए काम करने वाले लोगों के बारे में अधिक पढ़कर, अरविंद को पता चलता है कि उसे शुरुआत से शुरू करना है और सबकुछ नया होगा।",
              "ka": "ಶಿಖಾ ಅವರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾ ಮತ್ತು LGBTQ+ ಸಮುದಾಯಕ್ಕಾಗಿ ಕೆಲಸ ಮಾಡುವ ಜನರ ಬಗ್ಗೆ ಹೆಚ್ಚು ಓದುತ್ತಾ, ಅರವಿಂದ್ ಅವರು ಮೊದಲಿನಿಂದ ಪ್ರಾರಂಭಿಸಬೇಕು ಮತ್ತು ಎಲ್ಲವೂ ಹೊಸದಾಗಿರುತ್ತದೆ ಎಂದು ಅರಿತುಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "శిఖతో మాట్లాడటం మరియు LGBTQ+ కమ్యూనిటీ కోసం పనిచేసే వ్యక్తుల గురించి మరింత చదవడం ద్వారా అరవింద్ తను మొదటి నుండి ప్రారంభించాలని మరియు ప్రతిదీ కొత్తగా ఉంటుందని గ్రహించాడు.",
              "or": "ଶିଖାଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବା ଏବଂ LGBTQ + ସମୁଦାୟରେ କାର୍ଯ୍ୟ କରୁଥିବା ଲୋକଙ୍କ ବିଷୟରେ ଅଧିକ ପଡି଼ବା ପରେ ଅରବିନ୍ଦ ବୁଝନ୍ତି ଯେ ତାଙ୍କୁ ପ୍ରଥମରୁ ଆରମ୍ଭ କରିବାକୁ ହେବ ଏବଂ ସବୁକିଛି ନୂଆ ହେବ |",
              "as": "শিখাৰ সৈতে কথা পাতি আৰু LGBTQ+ সম্প্ৰদায়ৰ বাবে কাম কৰা লোকসকলৰ বিষয়ে অধিক পঢ়ি অৰবিন্দে উপলব্ধি কৰে যে তেওঁ গুৰিৰ পৰা আৰম্ভ কৰিব লাগিব আৰু সকলো নতুন হ'ব।",
              "gu": "શિખા સાથે વાત કરતા અને LGBTQ+ સમુદાય માટે કામ કરનારા લોકો વિશે વધારે અભ્યાસ, અરવિંદને માલુમ થાય છે કે તેને પ્રારંભથી જ શરૂઆત કરવાનું રહેશે અને બધુ જ નવું હશે."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_3_7",
              "hi": "A2_3_7",
              "ka": "A2_3_7",
              "te": "A2_3_7",
              "or": "A2_3_7",
              "as": "A2_3_7",
              "gu": "A2_3_7"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "But he is wondering that since he was always interested in science and technology, should he consider going back to engineering instead.",
              "hi": "लेकिन वह सोच रहा है कि चूंकि वह हमेशा विज्ञान और प्रौद्योगिकी में रूचि रखता था, इसलिए क्या उसे इसके बजाय वापस इंजीनियरिंग में जाने पर विचार करना चाहिए।",
              "ka": "ಆದರೆ ಅವರು ಯಾವಾಗಲೂ ವಿಜ್ಞಾನ ಮತ್ತು ತಂತ್ರಜ್ಞಾನದಲ್ಲಿ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದರಿಂದ, ಅದರ ಬದಲು ಮತ್ತೆ ಎಂಜಿನಿಯರಿಂಗ್‌ಗೆ ಹೋಗಲು ಯೋಚಿಸಬೇಕೇ ಎಂದು ಅವರು ಆಶ್ಚರ್ಯ ಪಡುತ್ತಾನೆ.",
              "te": "కానీ అతను ఎల్లప్పుడూ సైన్స్ మరియు టెక్నాలజీపై ఆసక్తిని కలిగి ఉన్నందున, అతను మళ్లీ ఇంజనీరింగ్‌కు వెళ్లాలా అని ఆలోచిస్తున్నాడు.",
              "or": "କିନ୍ତୁ ସେ ଆଶ୍ଚର୍ଯ୍ୟ ହେଉଛନ୍ତି ଯେହେତୁ ସେ ସର୍ବଦା ବିଜ୍ଞାନ ଏବଂ ଟେକ୍ନୋଲୋଜୀ ପ୍ରତି ଆଗ୍ରହୀ ଥିଲେ, ସେ ଏହା ବଦଳରେ ଇଞ୍ଜିନିୟରିଂକୁ ଫେରିବା କଥା ଚିନ୍ତା କରିବା ଉଚିତ୍ କି?",
              "as": "কিন্তু তেওঁ আচৰিত হৈছে যে যিহেতু তেওঁ সদায় বিজ্ঞান আৰু প্ৰযুক্তিৰ প্ৰতি আগ্ৰহী আছিল, তেওঁ তাৰ সলনি অভিযান্ত্ৰিকলৈ উভতি যোৱাৰ কথা বিবেচনা কৰাটো উচিত হ'ব নে নাই।",
              "gu": "પણ તે વિચારી રહ્યા છે કે તેઓ હંમેશા વિજ્ઞાન તથા ટેકનોલોજીમાં રસ ધરાવતો હતો, માટે શું તેને બદલે એન્જીનિયરિંગમાં પરત જવા અંગે વિચાર કરવો જોઈએ."
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_3_8_reveal",
              "hi": "A2_3_8_रिवील ",
              "ka": "A2_3_8_reveal",
              "te": "A2_3_8_రివీల్",
              "or": "A2_3_8_reveal",
              "as": "A2_3_8_reveal",
              "gu": "A2_3_8_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal Arvind’s thoughts:",
              "hi": "अरविंद के विचारों को प्रकट करने के लिए क्लिक करें:",
              "ka": "ಅರವಿಂದ್ ಅವರ ಆಲೋಚನೆಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "అరవింద్ ఆలోచనలను వెల్లడించడానికి క్లిక్ చేయండి:",
              "or": "ଅରବିନ୍ଦଙ୍କ ଚିନ୍ତାଧାରା ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "অৰবিন্দৰ চিন্তাবোৰ প্ৰকাশ কৰিবলৈ ক্লিক কৰক:",
              "gu": "અરવિંદના વિચારો રજૂ કરવા માટે ક્લિક કરોઃ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Thought 1",
              "hi": "विचार 1",
              "ka": "ಚಿಂತನೆ 1",
              "te": "ఆలోచన 1",
              "or": "Thought 1",
              "as": "চিন্তা ১",
              "gu": "વિચાર 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Thought 2",
              "hi": "विचार 2 ",
              "ka": "ಚಿಂತನೆ 2",
              "te": "ఆలోచన 2",
              "or": "Thought 2",
              "as": "চিন্তা ২",
              "gu": "વિચાર 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Though I want to work towards equal rights for the LGBTQ+ community I enjoy working with technology.",
              "hi": "हालांकि मैं एलजीबीटीक्यू + समुदाय के लिए समान अधिकारों की दिशा में काम करना चाहता हूं, लेकिन मैं प्रौद्योगिकी के साथ काम करने में आनंद लेता हूं।",
              "ka": "ನಾನು LGBTQ+ ಸಮುದಾಯಕ್ಕೆ ಸಮಾನ ಹಕ್ಕುಗಳಿಗಾಗಿ ಕೆಲಸ ಮಾಡಲು ಬಯಸಿದ್ದರೂ ನಾನು ತಂತ್ರಜ್ಞಾನದೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಆನಂದಿಸುತ್ತೇನೆ.",
              "te": "నేను LGBTQ+ కమ్యూనిటీకి సమాన హక్కుల కోసం పని చేయాలనుకుంటున్నా కూడా టెక్నాలజీతో పని చేయడం నాకు చాలా ఇష్టం.",
              "or": "ଯଦିଓ ମୁଁ LGBTQ + ସମୁଦାୟ ପାଇଁ ସମାନ ଅଧିକାର ଦିଗରେ କାମ କରିବାକୁ ଚାହେଁ, ତଥାପି ମୁଁ ଟେକ୍ନୋଲୋଜି ସହିତ କାମ କରିବାକୁ ଉପଭୋଗ କରେ |",
              "as": "যদিও মই LGBTQ+ সম্প্ৰদায়ৰ বাবে সমান অধিকাৰৰ দিশত কাম কৰিব বিচাৰো মই প্ৰযুক্তিৰ সৈতে কাম কৰিও ভাল পাওঁ।",
              "gu": "જોકે હું LGBTQ+ સમુદાય માટે સમાન અધિકારોની દિશામાં કામ કરવા ઈચ્છું છું, જોકે મને ટેકનિક સાથે કામ કરવામાં મજા આવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Talking to Shikha made me realise that there is so much work to be done for the LGBTQ+ and more people from the community have to be involved in it.",
              "hi": "शिखा से बात करने से मुझे आभास हुआ कि एलजीबीटीक्यू + के लिए बहुत अधिक काम किया जाना चाहिए और समुदाय के अधिक लोगों को इसमें शामिल होना चाहिए।",
              "ka": "ಶಿಖಾ ಅವರೊಂದಿಗೆ ಮಾತನಾಡುವಾಗ LGBTQ+ ಗಾಗಿ ಸಾಕಷ್ಟು ಕೆಲಸಗಳನ್ನು ಮಾಡಬೇಕಾಗಿದೆ ಮತ್ತು ಸಮುದಾಯದ ಹೆಚ್ಚಿನ ಜನರು ಅದರಲ್ಲಿ ತೊಡಗಿಸಿಕೊಳ್ಳಬೇಕು ಎಂದು ನನಗೆ ಅರ್ಥವಾಯಿತು.",
              "te": "శిఖతో మాట్లాడడం వల్ల LGBTQ+ కోసం చాలా పని చేయాల్సి ఉందని మరియు సంఘం నుండి ఎక్కువ మంది వ్యక్తులు ఇందులో పాల్గొనాలని నాకు అర్థమైంది.",
              "or": "ଶିଖାଙ୍କ ସହ କଥାବାର୍ତ୍ତା ମୋତେ ଅନୁଭବ କଲା ଯେ LGBTQ + ପାଇଁ ବହୁତଗୁଡ଼ିଏ କାର୍ଯ୍ୟ କରିବାକୁ ପଡିବ ଏବଂ ସମ୍ପ୍ରଦାୟର ଅଧିକ ଲୋକ ଏଥିରେ ଜଡିତ ହେବା ଆବଶ୍ୟକ |",
              "as": "শিখাৰ সৈতে কথা পাতি মোৰ অনুভৱ হৈছিল যে LGBTQ+ ৰ বাবে বহুতো কাম কৰিব লাগিব আৰু সম্প্ৰদায়ৰ অধিক লোক ইয়াত জড়িত হ'ব লাগিব।",
              "gu": "શિખા સાથે વાતચીત કરવાથી મને એવો અહેસાસ થયો કે LGBTQ+ માટે ઘણુ કામ કરવાનું છે અને સમુદાય તથા લોકોને તેમા સામેલ થવાનું છે."
            }
          }
        ],
        "page21": [
          {
            "valuePath": "display",
            "value": {
              "en": "A2_3_9_D",
              "hi": "A2_3_9_डी ",
              "ka": "A2_3_9_D",
              "te": "A2_3_9_D",
              "or": "A2_3_9_D",
              "as": "A2_3_9_D",
              "gu": "A2_3_9_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Arvind do?",
              "hi": "अरविंद को क्या करना चाहिए?",
              "ka": "ಅರವಿಂದ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "అరవింద్ ఏం చేయాలి?",
              "or": "ଅରବିନ୍ଦ କ’ଣ କରିବା ଉଚିତ୍ ?",
              "as": "অৰবিন্দে কি কৰা উচিত?",
              "gu": "અરવિંદે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Go back to engineering",
              "hi": "इंजीनियरिंग पर वापस जाए",
              "ka": "ಇಂಜಿನಿಯರಿಂಗ್ ಗೆ ಹಿಂತಿರುಗಬೇಕು",
              "te": "ఇంజనీరింగ్‌కి తిరిగి వెళ్ళాలి",
              "or": "ଇଞ୍ଜିନିୟରିଂକୁ ଫେରିବେ",
              "as": "ইঞ্জিনিয়াৰিঙলৈ উভতি যাওক",
              "gu": "એન્જીનિયરિંગમાં પરત ફરવું જોઈએ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Take up a career path towards improving the lives of LGBTQ+ people",
              "hi": " एलजीबीटीक्यू + लोगों के जीवन में सुधार की दिशा में एक कैरियर पथ ले ले ",
              "ka": "LGBTQ+ ಜನರ ಜೀವನವನ್ನು ಸುಧಾರಿಸಲು ವೃತ್ತಿ ಮಾರ್ಗವನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ",
              "te": "LGBTQ+ వ్యక్తుల జీవితాలను మెరుగుపరచడానికి కెరీర్ మార్గాన్ని ప్రారంభించాలి",
              "or": "LGBTQ + ଲୋକଙ୍କ ଜୀବନରେ ଉନ୍ନତି ଆଣିବା ଦିଗ ରେ ଏକ କ୍ୟାରିଅର୍ ପଥ ନିଅନ୍ତୁ",
              "as": "LGBTQ+ লোকসকলৰ জীৱন উন্নত কৰাৰ দিশত কেৰিয়াৰৰ পথ লওক",
              "gu": "LGBTQ+ લોકોના જીવનને વધારે સારું કરવાની દિશામાં કારકિર્દીનો માર્ગ અપનાવવો"
            }
          }
        ]
      },
      "scene11": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Choose a different career",
              "hi": "एक अलग करियर चुने",
              "ka": "ಬೇರೆ ವೃತ್ತಿಯನ್ನು ಆರಿಸಿಕೊಳ್ಳಿ",
              "te": "వేరే కెరీర్‌ని ఎంచుకోవాలి",
              "or": "ଏକ ଭିନ୍ନ କ୍ୟାରିଅର୍ ବାଛନ୍ତୁ",
              "as": "এটা বেলেগ কেৰিয়াৰ বাছনি কৰক",
              "gu": "અલગ કારકિર્દી પસંદ કરવી જોઈએ"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is inspired by Shikha’s work and is determined to work towards improving the lives of LGBTQ+ people.",
              "hi": "अरविंद शिखा के काम से प्रेरित है और एलजीबीटीक्यू + लोगों के जीवन में सुधार की दिशा में काम करने के लिए दृढ़ है।",
              "ka": "ಅರವಿಂದ್ ಅವರು ಶಿಖಾ ಅವರ ಕೆಲಸದಿಂದ ಸ್ಫೂರ್ತಿ ಪಡೆದಿದ್ದಾನೆ ಮತ್ತು LGBTQ+ ಜನರ ಜೀವನವನ್ನು ಸುಧಾರಿಸುವ ನಿಟ್ಟಿನಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ನಿರ್ಧರಿಸಿದ್ದಾನೆ.",
              "te": "అరవింద్ శిఖ పని నుండి ప్రేరణ పొందాడు మరియు LGBTQ+ వ్యక్తుల జీవితాలను మెరుగుపరిచేందుకు కృషి చేయాలని నిశ్చయించుకున్నాడు.",
              "or": "ଅରବିନ୍ଦ ଶିଖାଙ୍କ କାର୍ଯ୍ୟରୁ ଅନୁପ୍ରାଣିତ ଏବଂ LGBTQ + ଲୋକଙ୍କ ଜୀବନରେ ଉନ୍ନତି ଆଣିବା ଦିଗରେ କାର୍ଯ୍ୟ କରିବାକୁ ସଂକଳ୍ପବଦ୍ଧ |",
              "as": "অৰবিন্দ শিখাৰ কামৰ দ্বাৰা অনুপ্ৰাণিত আৰু LGBTQ+ লোকসকলৰ জীৱন উন্নত কৰাৰ দিশত কাম কৰিবলৈ সংকল্পবদ্ধ।",
              "gu": "અરવિંદ શિખાના કાર્યથી પ્રેરણા ધરાવે છે અને LGBTQ+ લોકોના જીવનને વધારે યોગ્ય બનાવવાની દિશામાં કામ કરવા કટિબદ્ધ છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_1_2",
              "hi": "C1_1_2",
              "ka": "C1_1_2",
              "te": "C1_1_2",
              "or": "C1_1_2",
              "as": "C1_1_2",
              "gu": "C1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is thinking about his options. When he mentions this to Roshni, she immediately tells him that he should speak to one of her faculty members, Professor Prasidh,  who might help Arvind.",
              "hi": "अरविंद अपने विकल्पों के बारे में सोच रहा है। जब उसने रोशनी से इस बात का उल्लेख किया, तो वह तुरंत उसे बताती है कि उसे अपने फैकल्टी/ संकाय सदस्यों में से एक से बात करनी चाहिए, प्रोफेसर प्रसिध, जो अरविंद की सहायता कर सकते हैं।",
              "ka": "ಅರವಿಂದ್ ತನ್ನ ಆಯ್ಕೆಗಳ ಬಗ್ಗೆ ಯೋಚಿಸುತ್ತಿದ್ದಾನೆ. ಅವನು ಇದನ್ನು ರೋಶ್ನಿ ಬಳಿ ಹೇಳಿದಾಗ, ಅವಳು ತಕ್ಷಣ ಅರವಿಂದ್‌ಗೆ ಸಹಾಯ ಮಾಡಬಹುದಾದ ತನ್ನ ಅಧ್ಯಾಪಕರಲ್ಲಿ ಒಬ್ಬರಾದ ಪ್ರೊಫೆಸರ್ ಪ್ರಸಿದ್ಧ್ ಅವರೊಂದಿಗೆ ಮಾತನಾಡಬೇಕೆಂದು ಹೇಳುತ್ತಾಳೆ. .",
              "te": "అరవింద్ తన ఆప్షన్ల గురించి ఆలోచిస్తున్నాడు. అతను రోష్నితో ఈ విషయాన్ని ప్రస్తావించినప్పుడు, ఆమె వెంటనే అరవింద్‌కి సహాయం చేయగల తన ఫ్యాకల్టీ సభ్యులలో ఒకరైన ప్రొఫెసర్ ప్రసిధ్ తో మాట్లాడాలని చెప్పింది.",
              "or": "ଅରବିନ୍ଦ ତାଙ୍କ ବିକଳ୍ପଗୁଡିକ ବିଷୟରେ ଚିନ୍ତା କରୁଛନ୍ତି | ଯେତେବେଳେ ସେ ରୋଶନିଙ୍କୁ ଏହା ଉଲ୍ଲେଖ କରନ୍ତି, ସେ ସଙ୍ଗେ ସଙ୍ଗେ ତାଙ୍କୁ କହିଥିଲେ ଯେ ସେ ତାଙ୍କର ଜଣେ ଅଧ୍ୟାପକ ପ୍ରଫେସର ପ୍ରସିଦ୍ଧଙ୍କ ସହ କଥା ହେବା ଉଚିତ୍‌, ଯିଏ ଅରବିନ୍ଦଙ୍କୁ ହୁଏତ ସାହାଯ୍ୟ କରିପାରନ୍ତି |",
              "as": "অৰবিন্দে তেওঁৰ বিকল্পবোৰৰ বিষয়ে চিন্তা কৰি আছে। যেতিয়া তেওঁ ৰোশনিৰ ওচৰত এই কথা উল্লেখ কৰে, তাই লগে লগে তেওঁক কয় যে তেওঁ তেওঁৰ এজন শিক্ষক সদস্য, অধ্যাপক প্ৰসিদ্ধৰ সৈতে কথা পাতিব লাগে, যিজনে অৰবিন্দক সহায় কৰিব পাৰে।",
              "gu": "અરવિંદ પોતાના વિકલ્પો અંગે વિચાર કરે છે. જ્યારે તેઓ રોશનીને આ અંગે ઉલ્લેખ કરે છે તો તેઓ તાત્કાલિક તે અંગે કહે છે કે તેના એક સંકાય સભ્ય, પ્રોફેસર પ્રસિદ્ધથી વાત કરવા ઈચ્છે છે, જે અરવિંદની મદદ કરી શકે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_1_3",
              "hi": "C1_1_3",
              "ka": "C1_1_3",
              "te": "C1_1_3",
              "or": "C1_1_3",
              "as": "C1_1_3",
              "gu": "C1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He decides to approach Professor Prasidh for advice. Roshni arranges a meeting with Professor Prasidh at the University.",
              "hi": "वह सलाह के लिए प्रोफेसर प्रसिध से संपर्क करने का निश्चय करता है। रोशनी विश्वविद्यालय में प्रोफेसर प्रसिध के साथ एक बैठक की व्यवस्था करती है।",
              "ka": "ಸಲಹೆಗಾಗಿ ಪ್ರೊಫೆಸರ್ ಪ್ರಸಿದ್ಧ್ ಅವರನ್ನು ಸಂಪರ್ಕಿಸಲು ಅವರು ನಿರ್ಧರಿಸುತ್ತಾರೆ. ವಿಶ್ವವಿದ್ಯಾನಿಲಯದಲ್ಲಿ ಪ್ರೊಫೆಸರ್ ಪ್ರಸಿದ್ಧ್ ಅವರೊಂದಿಗೆ ರೋಶ್ನಿ ಸಭೆಯನ್ನು ಏರ್ಪಡಿಸುತ್ತಾರೆ.",
              "te": "అతను సలహా కోసం ప్రొఫెసర్ ప్రసిధ్ ను సంప్రదించాలని నిర్ణయించుకున్నాడు. రోష్ని యూనివర్సిటీలో ప్రొఫెసర్ ప్రసిధ్ తో సమావేశం ఏర్పాటు చేసింది.",
              "or": "ସେ ପରାମର୍ଶ ପାଇଁ ପ୍ରଫେସର ପ୍ରସିଦ୍ଧଙ୍କ ନିକଟକୁ ଯିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି। ରୋଶନି ବିଶ୍ୱବିଦ୍ୟାଳୟରେ ପ୍ରଫେସର ପ୍ରସିଦ୍ଧଙ୍କ ସହ ଏକ ସାକ୍ଷାତ ଆୟୋଜନ କରନ୍ତି।",
              "as": "তেওঁ পৰামৰ্শৰ বাবে অধ্যাপক প্ৰসিদ্ধৰ ওচৰলৈ যোৱাৰ সিদ্ধান্ত লয়। ৰোশনিয়ে বিশ্ববিদ্যালয়ত অধ্যাপক প্ৰসিদ্ধৰ সৈতে এখন বৈঠকৰ আয়োজন কৰে।",
              "gu": "તે સલાહ માટે પ્રોફેસર પ્રસિદ્ધનો સંપર્ક કરવાનો નિર્ણય કરે છે. રોશની યુનિવર્સિટીમાં પ્રોફેસર પ્રસિદ્ધ સાથે એક બેઠકનું આયોજન કરે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_1_4////",
              "hi": "C1_1_4 ////////",
              "ka": "C1_1_4////",
              "te": "C1_1_4////",
              "or": "C1_1_4////",
              "as": "C1_1_4////",
              "gu": "C1_1_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Thank you for taking out the time, professor.",
              "hi": "समय निकालने के लिए धन्यवाद, प्रोफेसर।",
              "ka": "ಸಮಯ ತೆಗೆದುಕೊಂಡಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು, ಪ್ರಾಧ್ಯಾಪಕರೇ.",
              "te": "సమయం కేటాయించినందుకు ధన్యవాదాలు, ప్రొఫెసర్.",
              "or": "ପ୍ରଫେସର, ସମୟ ବାହାର କରିଥିବାରୁ ଧନ୍ୟବାଦ |",
              "as": "সময় উলিয়াই দিয়াৰ বাবে ধন্যবাদ, অধ্যাপক।",
              "gu": "પ્રોફેસર,અમને સમય આપવા બદલ તમારો આભાર"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Roshni has told me a lot about you. Tell me, Arvind, how can I help you?",
              "hi": "रोशनी ने मुझे तुम्हारे बारे में बहुत कुछ बताया है। मुझे बताओ, अरविंद, मैं तुम्हारी कैसे सहायता कर सकता हूं?",
              "ka": "ರೋಶನಿ ನಿನ್ನ ಬಗ್ಗೆ ತುಂಬಾ ಹೇಳಿದ್ದಾಳೆ. ಹೇಳು ಅರವಿಂದ್, ನಾನು ನಿನಗೆ ಹೇಗೆ ಸಹಾಯ ಮಾಡಲಿ?",
              "te": "రోష్ని నీ గురించి చాలా చెప్పింది. నాకు చెప్పు, అరవింద్, నేను మీకు ఎలా సహాయం చేయగలను?",
              "or": "ରୋଶନି ତୁମ ବିଷୟରେ ମୋତେ ବହୁତ କିଛି କହିଛି | ମୋତେ କୁହ, ଅରବିନ୍ଦ, ମୁଁ ତୁମକୁ କିପରି ସାହାଯ୍ୟ କରିପାରିବି?",
              "as": "ৰোশনিয়ে মোক তোমাৰ বিষয়ে বহুত কৈছে। মোক কওক, অৰবিন্দ, মই আপোনাক কেনেকৈ সহায় কৰিব পাৰোঁ?",
              "gu": "શું હું તમને મદદ કરી શકું છું?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Sir, in the last year, I have read a lot about equal rights for LGBTQ+ people, and I am very interested in studying the laws and policies involved. I have a few questions that I wanted to ask you about my future studies.",
              "hi": "महोदय, पिछले वर्ष में, मैंने  एलजीबीटीक्यू+ लोगों के लिए समान अधिकारों के बारे में बहुत कुछ पढ़ा है, और मुझे इसमें शामिल कानूनों और नीतियों का अध्ययन करने में बहुत रुचि है। मेरे कुछ प्रश्न हैं जो मैं अपने भविष्य के अध्ययन के बारे में आपसे पूछना चाहता था।",
              "ka": "ಸರ್, ಕಳೆದ ವರ್ಷದಲ್ಲಿ, LGBTQ+ ಜನರಿಗೆ ಸಮಾನ ಹಕ್ಕುಗಳ ಕುರಿತು ನಾನು ಸಾಕಷ್ಟು ಓದಿದ್ದೇನೆ ಮತ್ತು ಒಳಗೊಂಡಿರುವ ಕಾನೂನುಗಳು ಮತ್ತು ನೀತಿಗಳನ್ನು ಅಧ್ಯಯನ ಮಾಡಲು ನಾನು ತುಂಬಾ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದೇನೆ. ನನ್ನ ಭವಿಷ್ಯದ ಅಧ್ಯಯನಗಳ ಕುರಿತು ನಾನು ನಿಮ್ಮನ್ನು ಕೇಳಲು ಬಯಸುವ ಕೆಲವು ಪ್ರಶ್ನೆಗಳಿವೆ.",
              "te": "సర్, గత సంవత్సరంలో, నేను LGBTQ+ వ్యక్తులకు సమాన హక్కుల గురించి చాలా చదివాను మరియు చట్టాలు మరియు విధానాలను అధ్యయనం చేయడంలో నాకు చాలా ఆసక్తి ఉంది. నా తదుపరి చదువు గురించి నేను మిమ్మల్ని కొన్ని ప్రశ్నలు అడగాలనుకుంటున్నాను.",
              "or": "ସାର୍, ଗତ ବର୍ଷ ମଧ୍ଯରେ, ମୁଁ LGBTQ + ଲୋକଙ୍କ ପାଇଁ ସମାନ ଅଧିକାର ବିଷୟରେ ଅନେକ ପଢି଼ଛି, ଏବଂ ମୁଁ ଜଡିତ ଆଇନ ଏବଂ ନୀତିଗୁଡିକ ଅଧ୍ୟୟନ କରିବାକୁ ବହୁତ ଆଗ୍ରହୀ | ମୋର କିଛି ପ୍ରଶ୍ନ ଅଛି ଯାହା ମୁଁ ମୋର ଭବିଷ୍ୟତ ଅଧ୍ୟୟନ ବିଷୟରେ ଆପଣଙ୍କୁ ପଚାରିବାକୁ ଚାହୁଁଥିଲି |",
              "as": "ছাৰ, যোৱা বছৰত, মই LGBTQ+ লোকসকলৰ বাবে সমান অধিকাৰৰ বিষয়ে বহুত পঢ়িছোঁ, আৰু মই ইয়াৰ লগত জড়িত আইন আৰু নীতিবোৰ অধ্যয়ন কৰিবলৈ যথেষ্ট আগ্ৰহী। মোৰ কেইটামান প্ৰশ্ন আছে যিবোৰ মই আপোনাক মোৰ ভৱিষ্যতৰ অধ্যয়নৰ বিষয়ে সুধিব বিচাৰিছিলো।",
              "gu": "સર, ગયા વર્ષમાં હું LGBTQ+ લોકો માટે સમાન અધિકારો અંગે ઘણુબધુ ભણ્યો છું, અને મને તેમા સામેલ કાયદા તથા નિયમોનો અભ્યાસ કરવા માટે ઘણો જ રસ ધરાવું છું. મારે કેટલાક પ્રશ્નો છે,જે હું તમને ભવિષ્યના અભ્યાસ અંગે પૂછવા ઈચ્છતો હતો."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_1_5_reveal",
              "hi": "C1_1_5_रिवील ",
              "ka": "C1_1_5_reveal",
              "te": "C1_1_5_రివీల్",
              "or": "C1_1_5_reveal",
              "as": "C1_1_5_প্ৰকাশ কৰা",
              "gu": "C1_1_5_reveal"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click on the questions below to find out Professor Prasidh’s answers:",
              "hi": "प्रोफेसर प्रसिध के उत्तर जानने के लिए नीचे दिए गए प्रश्नों पर क्लिक करें:",
              "ka": "ಪ್ರೊಫೆಸರ್ ಪ್ರಸಿದ್ಧ್ ಅವರ ಉತ್ತರಗಳನ್ನು ಕಂಡುಹಿಡಿಯಲು ಕೆಳಗಿನ ಪ್ರಶ್ನೆಗಳ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "ప్రొఫెసర్ ప్రసిధ్ సమాధానాలను తెలుసుకోవడానికి క్రింది ప్రశ్నలపై క్లిక్ చేయండి:",
              "or": "ପ୍ରଫେସର ପ୍ରସିଦ୍ଧଙ୍କ ଉତ୍ତର ଜାଣିବା ପାଇଁ ନିମ୍ନରେ ଥିବା ପ୍ରଶ୍ନଗୁଡ଼ିକ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "অধ্যাপক প্ৰসিদ্ধৰ উত্তৰ বিচাৰিবলৈ তলৰ প্ৰশ্নবোৰত ক্লিক কৰক:",
              "gu": "પ્રોફેસર પ્રસિદ્ધના જવાબ જાણવા માટે નીચે આપવામાં આવેલા પ્રશ્નો ઉપર ક્લિક કરોઃ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "What course, according to you, would help me better understand this subject?",
              "hi": "आपके अनुसार कौन सा पाठ्यक्रम इस विषय को बेहतर ढंग से समझने में मेरी सहायता करेगा?",
              "ka": "ನಿಮ್ಮ ಪ್ರಕಾರ ಯಾವ ಕೋರ್ಸ್, ಈ ವಿಷಯವನ್ನು ಚೆನ್ನಾಗಿ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ನನಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ?",
              "te": "మీ అభిప్రాయం ప్రకారం, ఈ విషయాన్ని బాగా అర్థం చేసుకోవడానికి నాకు ఏ కోర్సు సహాయపడుతుంది?",
              "or": "ଆପଣଙ୍କ ଅନୁଯାୟୀ, କେଉଁ କୋର୍ସ୍‌, ମୋତେ ଏହି ବିଷୟକୁ ଭଲ ଭାବରେ ବୁଝିବାରେ ସାହାଯ୍ୟ କରିବ ?",
              "as": "আপোনাৰ মতে, কেনে ধৰণৰ পাঠ্যক্ৰমে মোক এই বিষয়টো ভালদৰে বুজাত সহায় কৰিব?",
              "gu": "તમારા મતે કયા અભ્યાસક્રમો આ વિષયને વધારે સારી રીતે સમજવામાં મદદ કરશે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "I have read a few things about studying from home and not college. Would I be able to pursue a degree like that? Is it recognised?",
              "hi": "मैंने घर से पढ़ाई के बारे में कुछ बातें पढ़ी हैं न कि कॉलेज से। क्या मैं इस तरह की डिग्री हासिल कर पाऊंगा? क्या यह मान्यता प्राप्त है?",
              "ka": "ನಾನು ಮನೆಯಿಂದಲೇ ಓದುವ ಬಗ್ಗೆ ಕೆಲವು ವಿಷಯಗಳನ್ನು ಓದಿದ್ದೇನೆ ಮತ್ತು ಕಾಲೇಜಿನಲ್ಲ. ನಾನು ಅಂತಹ ಪದವಿಯನ್ನು ಮುಂದುವರಿಸಬಹುದೇ? ಇದು ಗುರುತಿಸಲ್ಪಟ್ಟಿದೆಯೇ?",
              "te": "కాలేజీలో కాకుండా ఇంటి నుంచి చదువుకోవడం గురించి కొన్ని విషయాలు చదివాను. నేను అలాంటి డిగ్రీని అభ్యసించగలనా? దీనికి గుర్తింపు ఉంటుందా?",
              "or": "ମୁଁ କଲେଜରୁ ନୁହେଁ, ଘରୁ ଅଧ୍ଯୟନ ବିଷୟରେ କିଛି କଥା ପଢିଛି | ମୁଁ ସେପରି ଏକ ଡିଗ୍ରୀ ହାସଲ କରିବାକୁ ସମର୍ଥ ହେବି କି? ଏହା ସ୍ୱୀକୃତିପ୍ରାପ୍ତ କି?",
              "as": "মই কলেজৰ পৰা নহয় ঘৰৰ পৰা পঢ়াৰ বিষয়ে কেইটামান কথা পঢ়িছোঁ। মই তেনেধৰণৰ ডিগ্ৰী ল'ব পাৰিম নেকি? ইয়াক স্বীকৃতি দিয়া হৈছে নেকি?",
              "gu": "મે ઘરે અભ્યાસ કરવા માટે કેટલીક બાબતોનું અધ્યયન કર્યું છે, તે કોલેજમાંથી નથી. શું આ રીતે ડિગ્રી હાંસલ કરી શકીશ? શું તે માન્યતા ધરાવે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Does this happen entirely remotely?",
              "hi": "क्या यह पूरी तरह से दूर से होता है?",
              "ka": "ಇದು ಸಂಪೂರ್ಣವಾಗಿ ದೂರದಿಂದ ಸಂಭವಿಸುತ್ತದೆಯೇ?",
              "te": "ఇది పూర్తిగా రిమోట్‌గా జరుగుతుందా?",
              "or": "ଏହା ସମ୍ପୁର୍ଣ୍ଣ ଭାବରେ ଦୂରରୁ ହୁଏ କି ?",
              "as": "এইটো সম্পূৰ্ণৰূপে দূৰৱৰ্তীভাৱে হয় নেকি?",
              "gu": "શું તે સંપૂર્ણપણે દૂરથી થઈ શકે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "I haven’t studied Arts in school, will that be a problem?",
              "hi": "मैंने स्कूल में कला का अध्ययन नहीं किया है, क्या यह एक समस्या होगी?",
              "ka": "ನಾನು ಶಾಲೆಯಲ್ಲಿ ಆರ್ಟ್ಸ್ ಓದಿಲ್ಲ, ಅದು ಸಮಸ್ಯೆಯಾಗಬಹುದೇ?",
              "te": "నేను స్కూల్లో ఆర్ట్స్ చదవలేదు, అది సమస్య అవుతుందా?",
              "or": "ମୁଁ ବିଦ୍ୟାଳୟରେ କଳା ପଢ଼ି ନାହିଁ, ଏହା ଏକ ଅସୁବିଧା ହେବ କି ?",
              "as": "মই স্কুলত কলা অধ্যয়ন কৰা নাই, সেইটো এটা সমস্যা হ'ব পাৰে নেকি?",
              "gu": "મે શાળામાં આર્ટ્સનો અભ્યાસ કર્યો નથી, શું તેનાથી કોઈ સમસ્યા સર્જાશે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "I think political sciences would give you an excellent foundation to begin.",
              "hi": "मुझे लगता है कि आरम्भ करने के लिए राजनीतिक विज्ञान आपको  एक उत्कृष्ट नींव प्रदान करेगा।",
              "ka": "ರಾಜಕೀಯ ವಿಜ್ಞಾನವು ನಿನಗೆ ಪ್ರಾರಂಭಿಸಲು ಅತ್ಯುತ್ತಮ ಅಡಿಪಾಯವನ್ನು ನೀಡುತ್ತದೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "పోలిటికల్ సైన్స్ మీకు ప్రారంభించడానికి అద్భుతమైన పునాదిని ఇస్తాయని నేను భావిస్తున్నాను.",
              "or": "ମୁଁ ଭାବୁଛି ରାଜନୀତି ବିଜ୍ଞାନ ଆରମ୍ଭ କରିବା ପାଇଁ ତୁମକୁ ଏକ ଉତ୍କୃଷ୍ଟ ଭିତ୍ତି ଦେବ |",
              "as": "মই ভাবো ৰাজনৈতিক বিজ্ঞানে আপোনাক আৰম্ভ কৰিবলৈ এটা উৎকৃষ্ট ভেটি দিব।",
              "gu": "મને લાગે છે કે પોલિટીકલ સાયન્સ તમારે શરૂ કરવા માટે એક ઉત્કૃષ્ટ આધાર પ્રદાન આપે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Many social science courses can be done online or through a distance learning program. They are equivalent to a regular course, provided it is UGC (University Grants Commission) approved.",
              "hi": "अनेक सामाजिक विज्ञान पाठ्यक्रम ऑनलाइन या दूरस्थ शिक्षा कार्यक्रम के माध्यम से किए जा सकते हैं। वे एक नियमित पाठ्यक्रम के बराबर हैं, यदि यह यूजीसी (विश्वविद्यालय अनुदान आयोग) अनुमोदित है।",
              "ka": "ಅನೇಕ ಸಮಾಜ ವಿಜ್ಞಾನ ಕೋರ್ಸ್‌ಗಳನ್ನು ಆನ್‌ಲೈನ್ ಅಥವಾ ದೂರಶಿಕ್ಷಣ ಕಾರ್ಯಕ್ರಮದ ಮೂಲಕ ಮಾಡಬಹುದು. ಯುಜಿಸಿ (ವಿಶ್ವವಿದ್ಯಾನಿಲಯ ಧನಸಹಾಯ ಆಯೋಗ) ಅನುಮೋದಿಸಲ್ಪಟ್ಟಿದ್ದರೆ ಅವು ಸಾಮಾನ್ಯ ಕೋರ್ಸ್‌ಗೆ ಸಮನಾಗಿರುತ್ತದೆ.",
              "te": "అనేక సోషల్ సైన్సెస్ కోర్సులు ఆన్‌లైన్‌లో లేదా దూరవిద్యా కార్యక్రమం ద్వారా చేయవచ్చు. UGC (యూనివర్శిటీ గ్రాంట్స్ కమీషన్) చే ఆమోదించబడిన సాధారణ కోర్సులకు అది సమానం.",
              "or": "ଅନେକ ସାମାଜିକ ବିଜ୍ଞାନ ପାଠ୍ୟକ୍ରମ ଅନ୍‌ଲାଇନ୍ କିମ୍ବା ଦୂରସ୍ଥ ଶିକ୍ଷା କାର୍ଯ୍ୟକ୍ରମ ମାଧ୍ୟମରେ କରାଯାଇପାରିବ | ଯଦି ଏହା UGC (ବିଶ୍ୱବିଦ୍ୟାଳୟ ଅନୁଦାନ ଆୟୋଗ) ଅନୁମୋଦିତ ହୁଏ, ସେଗୁଡିକ ଏକ ନିୟମିତ କୋର୍ସ୍‌ ସହିତ ସମାନ ଅଟେ |",
              "as": "বহুতো সামাজিক বিজ্ঞান পাঠ্যক্ৰম অনলাইন বা দূৰত্ব শিক্ষণ কাৰ্যসূচীৰ জৰিয়তে কৰিব পাৰি। এইবোৰ নিয়মীয়া পাঠ্যক্ৰমৰ সমান, যদিহে ই UGC (বিশ্ববিদ্যালয় অনুদান আয়োগ) অনুমোদিত হয়।",
              "gu": "અનેક સામાજીક સાયન્સ અભ્યાસક્રમો ઓનલાઈન અથવા ડિસ્ટન્સ લર્નિંગ પ્રોગ્રામ મારફતે અથવા ઓનલાઈન કરી શકાય છે.આ એક રેગ્યુલર અભ્યાસક્રમ છે, પણ શરત છે કે UGC (યુનિવર્સિટી ગ્રાન્ટ્સ કમિશન) મંજૂરી ધરાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "To give your exams you’ll have to appear in person at the university exam centre. And studying a subject like this means you have to do a lot of research which involves visiting places and talking to people.",
              "hi": "अपनी परीक्षा देने के लिए आपको विश्वविद्यालय परीक्षा केंद्र में व्यक्तिगत रूप से उपस्थित होना होगा। और इस तरह के विषय का अध्ययन करने का अर्थ है कि आपको बहुत सारे शोध करने हैं, जिसमें अलग - अलग स्थान पर जाकर लोगों से बात करना शामिल है।",
              "ka": "ನಿಮ್ಮ ಪರೀಕ್ಷೆಗಳನ್ನು ನೀಡಲು ನೀವು ವಿಶ್ವವಿದ್ಯಾಲಯದ ಪರೀಕ್ಷಾ ಕೇಂದ್ರದಲ್ಲಿ ಖುದ್ದಾಗಿ ಹಾಜರಾಗಬೇಕಾಗುತ್ತದೆ. ಮತ್ತು ಈ ರೀತಿಯ ವಿಷಯವನ್ನು ಅಧ್ಯಯನ ಮಾಡುವುದು ಎಂದರೆ ನೀವು ಸ್ಥಳಗಳಿಗೆ ಭೇಟಿ ನೀಡುವುದು ಮತ್ತು ಜನರೊಂದಿಗೆ ಮಾತನಾಡುವುದನ್ನು ಒಳಗೊಂಡಿರುವ ಸಾಕಷ್ಟು ಸಂಶೋಧನೆಗಳನ್ನು ಮಾಡಬೇಕು.",
              "te": "మీ పరీక్షలు రాయడానికి మీరు యూనివర్సిటీ పరీక్షా కేంద్రంలో వ్యక్తిగతంగా హాజరు కావాలి. మరియు ఇలాంటి సబ్జెక్టును చదవడం అంటే మీరు చాలా రీసర్చ్ చేయాల్సి ఉంటుంది, ఇందులో స్థలాలను సందర్శించడం మరియు వ్యక్తులతో మాట్లాడటం వంటివి ఉంటాయి.",
              "or": "ତୁମର ପରୀକ୍ଷା ଦେବାକୁ ତୁମକୁ ବିଶ୍ୱବିଦ୍ୟାଳୟ ପରୀକ୍ଷା କେନ୍ଦ୍ରରେ ବ୍ୟକ୍ତିଗତ ଭାବରେ ଆସିବାକୁ ପଡିବ | ଏବଂ ଏହି ପରି ଏକ ବିଷୟ ଅଧ୍ୟୟନ କରିବା ଅର୍ଥ ଆପଣଙ୍କୁ ଅନେକ ଅନୁସନ୍ଧାନ କରିବାକୁ ପଡିବ ଯେଉଁଥିରେ ସ୍ଥାନ ପରିଦର୍ଶନ କରିବା ଏବଂ ଲୋକଙ୍କ ସହ କଥାବାର୍ତ୍ତା ଅନ୍ତର୍ଭୁକ୍ତ |",
              "as": "আপোনাৰ পৰীক্ষা দিবলৈ আপুনি বিশ্ববিদ্যালয় পৰীক্ষা কেন্দ্ৰত ব্যক্তিগতভাৱে অৱতীৰ্ণ হ'ব লাগিব। আৰু এনে ধৰণৰ বিষয় এটা অধ্যয়ন কৰাৰ অৰ্থ হৈছে আপুনি বহুতো গৱেষণা কৰিব লাগিব য'ত ঠাইবোৰ ভ্ৰমণ কৰা আৰু মানুহৰ সৈতে কথা পতা অন্তৰ্ভুক্ত হৈ থাকে।",
              "gu": "તમારી પરીક્ષા આપવા માટે તમારે યુનિવર્સિટી પરીક્ષા કેન્દ્ર ખાતે હાજર રહેવાનું હોય છે. અને આ રીતે એક વિષયનો અભ્યાસ કરવો તેનો અર્થ એ છે કે તમારે ઘણા વધારે સંશોધન કરવાના રહેશે. જેથી સ્થાનોનો અભ્યાસ કરવો તથા લોકો સાથે વાત કરવા સામેલ થાય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "That’ll be fine. People from any field can apply.",
              "hi": "वह बढिया होगा। किसी भी क्षेत्र के लोग आवेदन कर सकते हैं।",
              "ka": "ಅದು ಸರಿ. ಯಾವುದೇ ಕ್ಷೇತ್ರದ ಜನರು ಅರ್ಜಿ ಸಲ್ಲಿಸಬಹುದು.",
              "te": "అది బాగానే ఉంటుంది. ఏ రంగానికి చెందిన వారైనా అప్లై చేసుకోవచ్చు.",
              "or": "ତାହା ଠିକ୍ ହେବ | ଯେକୌଣସି କ୍ଷେତ୍ରର ଲୋକମାନେ ଆବେଦନ କରିପାରିବେ |",
              "as": "সেয়া ঠিক হ'ব। যিকোনো ক্ষেত্ৰৰ মানুহে আবেদন কৰিব পাৰে।",
              "gu": "આ ખૂબ જ સારું રહેશે. કોઈપણ ક્ષેત્રમાંથી લોકો અરજી કરી શકે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_1_6",
              "hi": "C1_1_6",
              "ka": "C1_1_6",
              "te": "C1_1_6",
              "or": "C1_1_6",
              "as": "C1_1_6",
              "gu": "C1_1_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is happy to take up a distance learning course in political sciences. He feels all set to take the next step in his learning journey.",
              "hi": "अरविंद राजनीति विज्ञान में दूरस्थ शिक्षा का कोर्स करने को लेकर खुश हैं। वह अपनी सीखने की यात्रा में अगला कदम उठाने के लिए पूरी तरह तैयार है।",
              "ka": "ಅರವಿಂದ್ ರಾಜಕೀಯ ವಿಜ್ಞಾನದಲ್ಲಿ ದೂರಶಿಕ್ಷಣ ಕೋರ್ಸ್ ತೆಗೆದುಕೊಳ್ಳಲು ಸಂತೋಷಪಡುತ್ತಾನೆ. ಅವನು ತನ್ನ ಕಲಿಕೆಯ ಪಯಣದಲ್ಲಿ ಮುಂದಿನ ಹೆಜ್ಜೆ ಇಡಲು ಸಿದ್ಧರಾಗಿದ್ದಾನೆ.",
              "te": "అరవింద్ పొలిటికల్ సైన్స్‌లో దూరవిద్యా కోర్సు చేయాలనుకుంటాడు. అతను తన లర్నింగ్ ప్రయాణంలో తదుపరి అడుగు వేయడానికి సిద్ధంగా ఉన్నాడు.",
              "or": "ରାଜନୀତି ବିଜ୍ଞାନରେ ଦୂର୍ସ୍ଥ ଶିକ୍ଷା କୋର୍ସ୍‌ ଗ୍ରହଣ କରି ଅରବିନ୍ଦ ଖୁସି ଅଛନ୍ତି | ସେ ନିଜ ଶିକ୍ଷଣ ଯାତ୍ରାରେ ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପ ନେବାକୁ ପ୍ରସ୍ତୁତ ଥିବା ଅନୁଭବ କରନ୍ତି |",
              "as": "অৰবিন্দ ৰাজনৈতিক বিজ্ঞানত দূৰত্ব শিকাৰ পাঠ্যক্ৰম গ্ৰহণ কৰি সুখী। তেওঁ অনুভৱ কৰে যে তেওঁৰ শিকাৰ যাত্ৰাত পৰৱৰ্তী পদক্ষেপ ল'বলৈ সকলো সাজু।",
              "gu": "અરવિંદ પોલિટીકલ સાયન્સમાં ડિસ્ટન્સ લર્નિંગનો કોર્સ કરવા અંગે ખુશ છે. તે પોતાના શીખવાની યાત્રામાં હવે પછીના પગલાં ભરવા માટે સંપૂર્ણપણે તૈયાર છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "TalkToExpert",
              "hi": "विशेषज्ञसेबातकरें  ",
              "ka": "ತಜ್ಞರೊಂದಿಗೆ ಮಾತನಾಡಿ",
              "te": "నిపుణుడితో మాట్లాడడం",
              "or": "TalkToExpert",
              "as": "বিশেষজ্ঞৰ সৈতে কথা পাতক",
              "gu": "નિષ્ણાતો સાથે વાતચીત કરવી"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "MCQ",
              "hi": "एमसीक्यू",
              "ka": "ಎಂಸಿಕ್ಯೂ",
              "te": "MCQ",
              "or": "MCQ",
              "as": "MCQ",
              "gu": "MCQ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Arvind speaks with an expert that is Prof. Prasidh as he considers which \ncourse to take to study the laws & policies for LGBTQ+ rights. What is \nTRUE about speaking with experts for career decisions?",
              "hi": " अरविंद एलजीबीटीक्यू+ अधिकारों के लिए कानूनों और नीतियों का अध्ययन करने के लिए कोर्स के चयन हेतु एक विशेषज्ञ प्रो. प्रसिद्ध से वार्ता करता है। \nकरियर संबंधी निर्णयों के लिए विशेषज्ञों से बात करने के बारे में क्या सत्य है?",
              "ka": "\n\"ಅರವಿಂದ್ ಅವರು ಪ್ರೊ. ಪ್ರಸಿದ್ಧ್ ಎಂಬ ತಜ್ಞರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾರೆ\nLGBTQ+ ಹಕ್ಕುಗಳಿಗಾಗಿ ಕಾನೂನುಗಳು ಮತ್ತು ನೀತಿಗಳನ್ನು ಅಧ್ಯಯನ ಮಾಡಲು ತೆಗೆದುಕೊಳ್ಳಬೇಕಾದ ಕೋರ್ಸ್. ಏನದು\nವೃತ್ತಿ ನಿರ್ಧಾರಗಳಿಗಾಗಿ ತಜ್ಞರೊಂದಿಗೆ ಮಾತನಾಡುವುದು ನಿಜವೇ?\"",
              "te": "LGBTQ+ హక్కుల కోసం చట్టాలు & విధానాల గురించి చదవడానికి తీసుకోవలసిన కోర్సు గురించి అరవింద్ ప్రొ. ప్రసిధ్ అనే నిపుణుడితో మాట్లాడాడు \n \n కెరీర్ నిర్ణయాల కోసం నిపుణులతో మాట్లాడటంలో ఏది నిజం?",
              "or": "ଅରବିନ୍ଦ ଜଣେ ବିଶେଷଜ୍ଞ ପ୍ରଫେସର ପ୍ରସିଦ୍ଧଙ୍କ ସହ କଥା ହୁଅନ୍ତି ଯେହେତୁ ସେ LGBTQ + ଅଧିକାର ପାଇଁ ନିୟମ ଏବଂ ନୀତିଗୁଡିକ ଅଧ୍ୟୟନ କରିବାକୁ କେଉଁ କୋର୍ସ୍‌ ବିଚାର କରନ୍ତି | କ୍ୟାରିୟର୍‌ ନିଷ୍ପତ୍ତି ପାଇଁ ବିଶେଷଜ୍ଞମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା ବିଷୟରେ ସତ୍ୟ କ’ଣ?",
              "as": "অৰবিন্দে এজন বিশেষজ্ঞ অধ্যাপক প্ৰসিদ্ধৰ সৈতে কথা পাতিছে যিহেতু তেওঁ \n LGBTQ+ অধিকাৰৰ বাবে আইন আৰু নীতিঅধ্যয়ন কৰিবলৈ কোনটো পাঠ্যক্ৰম লব লাগে বিবেচনা কৰে। \n বিশেষজ্ঞৰ সৈতে কেৰিয়াৰৰ সিদ্ধান্তৰ কথা পতাৰ বিষয়ে কোনটো সঁচা?",
              "gu": "અરવિંદ એક નિષ્ણાત સાથે વાતચીત કરે છે, જેઓ એક પ્રોફેસર છે, તે માને છે કે LGBTQ+ અધિકારો માટે કાયદા તથા નીતિઓનો અભ્યાસ કરવા માટે પાઠ્યક્રમ શું છે.કારકિર્દી સંબંધિત નિર્ણયો માટે નિષ્ણાતો સાથે વાત કરવા માટે યોગ્ય છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Experts are useful only when we need to choose a course",
              "hi": "विशेषज्ञ केवल तभी उपयोगी होते हैं जब हमें कोर्स का चुनाव करने की आवश्यकता होती है",
              "ka": "ನಾವು ಕೋರ್ಸ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಬೇಕಾದಾಗ ಮಾತ್ರ ತಜ್ಞರು ಉಪಯುಕ್ತರಾಗಿದ್ದಾರೆ",
              "te": "మనం కోర్సును ఎంచుకోవాల్సిన సమయంలో మాత్రమే నిపుణులు అవసరం",
              "or": "ବିଶେଷଜ୍ଞମାନେ ଉପଯୋଗୀ କେବଳ ଯେବେ ଆମେ ଏକ କୋର୍ସ୍‍ ବାଛିବା ଆବଶ୍ୟକ କରୁ",
              "as": "বিশেষজ্ঞসকল কেৱল তেতিয়াহে উপযোগী হয় যেতিয়া আমি এটা পাঠ্যক্ৰম বাছনি কৰাৰ প্ৰয়োজন হয়",
              "gu": "નિષ્ણાતો ત્યારે પણ ઉપયોગી હોય છે કે જ્યારે આપણે કોઈ કોર્સની પસંદગી આવશ્યકતા હોય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Experts are always right about trends & updates about an industry",
              "hi": "किसी इंडस्ट्री के रुझानों और अपडेट के बारे में विशेषज्ञ हमेशा सही होते हैं",
              "ka": "ಉದ್ಯಮದ ಬಗ್ಗೆ ಟ್ರೆಂಡ್‌ಗಳು ಮತ್ತು ನವೀಕರಣಗಳ ಬಗ್ಗೆ ತಜ್ಞರು ಯಾವಾಗಲೂ ಸರಿಯಾಗಿರುತ್ತಾರೆ",
              "te": "ఇండస్ట్రీకి సంబంధించిన ట్రెండ్‌లు & అప్‌డేట్‌ల గురించి నిపుణులు ఎల్లప్పుడూ సరిగ్గా చెప్తారు",
              "or": "ବିଶେଷଜ୍ଞମାନେ ଏକ ଶିଳ୍ପ ବିଷୟରେ ଟ୍ରେଣ୍ଡ ଏବଂ ଅପଡେଟ୍ ବିଷୟରେ ସର୍ବଦା ଠିକ୍",
              "as": "বিশেষজ্ঞসকল সদায়ে এটা উদ্যোগৰ বিষয়ে ট্ৰেণ্ড আৰু আপডেটৰ কাৰণে উপযোগী হয়",
              "gu": "નિષ્ણાતો ત્યારે જ ઉપયોગી હોય છે કે જ્યારે આપણે કોઈ અભ્યાસક્રમ પસંદ કરવાની આવશ્યકતાનું વલણ રહે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "xperts can help you focus. For example; To work on LGBTQ+ rights, an expert can advise you on the subjects and debates  you need to be aware about",
              "hi": "विशेषज्ञ आपको ध्यान केंद्रित करने में सहायता कर सकते हैं। उदाहरण के लिए; एलजीबीटीक्यू + अधिकारों पर काम करने के लिए, एक विशेषज्ञ आपको विषयों और बहसों पर सलाह दे सकता है जिसके बारे में आपको अवगत होना चाहिए",
              "ka": "ತಜ್ಞರು ನಿನಗೆ ಗಮನಹರಿಸಲು ಸಹಾಯ ಮಾಡಬಹುದು. ಉದಾಹರಣೆಗೆ; LGBTQ+ ಹಕ್ಕುಗಳ ಮೇಲೆ ಕೆಲಸ ಮಾಡಲು, ತಜ್ಞರು ನಿನಗೆ ತಿಳಿದಿರಬೇಕಾದ ವಿಷಯಗಳು ಮತ್ತು ಚರ್ಚೆಗಳ ಕುರಿತು ಸಲಹೆ ನೀಡಬಹುದು",
              "te": "నిపుణులు మీకు దృష్టి పెట్టడంలో సహాయపడగలరు. ఉదాహరణకి; LGBTQ+ హక్కులపై పని చేయడానికి, మీరు తెలుసుకోవలసిన విషయాలు మరియు చర్చల గురించి నిపుణుడు మీకు సలహా ఇవ్వగలరు",
              "or": "ବିଶେଷଜ୍ଞମାନେ ଆପଣଙ୍କୁ ଧ୍ୟାନ ଦେବାରେ ସାହାଯ୍ୟ କରିପାରିବେ | ଉଦାହରଣ ସ୍ଵରୁପ; LGBTQ + ଅଧିକାର ଉପରେ କାର୍ଯ୍ୟ କରିବାକୁ, ଜଣେ ବିଶେଷଜ୍ଞ ଆପଣଙ୍କୁ ବିଷୟ ଏବଂ ବିତର୍କଗୁଡିକ ଉପରେ ପରାମର୍ଶ ଦେଇପାରିବେ ଯାହା ବିଷୟରେ ଆପଣ ସଚେତନ ହେବା ଆବଶ୍ୟକ",
              "as": "এক্সপাৰ্টে আপোনাক মনোযোগ দিয়াত সহায় কৰিব পাৰে। উদাহৰণ স্বৰূপে; LGBTQ+ অধিকাৰৰ ওপৰত কাম কৰিবলৈ, এজন বিশেষজ্ঞই আপোনাক আপুনি সজাগ হ'ব লগা বিষয় আৰু বিতৰ্কৰ বিষয়ে পৰামৰ্শ দিব পাৰে",
              "gu": "નિષ્ણાતો તમને ધ્યાન કેન્દ્રિત કરવામાં મદદ કરી શકાય છે. જેમ કે LGBTQ+ અધિકારો અંગે કામ કરવા માટે, એક નિષ્ણાત તમને એવા વિષયો તથા દલીલો અંગે સલાહ આપી શકે છે, કે જે અંગે તમે જાણકારી જરૂરી હોય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "You need to spend many days with an expert to answer your questions",
              "hi": "आपको अपने प्रश्नों के उत्तर देने के लिए एक विशेषज्ञ के साथ कई दिन बिताने होंगे ",
              "ka": "ನಿಮ್ಮ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಲು ನೀವು ತಜ್ಞರೊಂದಿಗೆ ಹಲವು ದಿನಗಳನ್ನು ಕಳೆಯಬೇಕಾಗಿದೆ",
              "te": "మీ ప్రశ్నలకు సమాధానమివ్వడానికి మీరు నిపుణులతో చాలా రోజులు గడపవలసి ఉంటుంది",
              "or": "ତୁମର ପ୍ରଶ୍ନର ଉତ୍ତର ଦେବାକୁ ତୁମେ ଜଣେ ବିଶେଷଜ୍ଞଙ୍କ ସହିତ ଅନେକ ଦିନ ବିତାଇବାକୁ ପଡିବ |",
              "as": "আপোনাৰ প্ৰশ্নৰ উত্তৰ দিবলৈ আপুনি এজন বিশেষজ্ঞৰ সৈতে বহুদিন থাকিব লাগিব",
              "gu": "તમારા પ્રશ્નોના જવાબ આપવા માટે તમારે કોઈ નિષ્ણાતો સાથે અનેક દિવસો વિતાવવાના રહેશે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Think again! There will be many decisions to make for your career such as which company to join or which growth pathway to choose. Experts can help during those times too.",
              "hi": "फिर से विचार करो! आपके करियर के लिए कई निर्णय लेने होंगे जैसे कि किस कंपनी में शामिल होना है या कौन सा विकास मार्ग चुनना है। विशेषज्ञ उस समय भी सहायता कर सकते हैं।",
              "ka": "ಪುನಃ ಆಲೋಚಿಸು! ನಿಮ್ಮ ವೃತ್ತಿಜೀವನಕ್ಕಾಗಿ ಯಾವ ಕಂಪನಿಗೆ ಸೇರಲು ಅಥವಾ ಯಾವ ಬೆಳವಣಿಗೆಯ ಮಾರ್ಗವನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಹಲವು ನಿರ್ಧಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲಾಗುತ್ತದೆ. ಆ ಸಮಯದಲ್ಲಿ ತಜ್ಞರು ಸಹ ಸಹಾಯ ಮಾಡಬಹುದು.",
              "te": "మళ్లీ ఆలోచించండి! మీ కెరీర్ కోసం ఏ కంపెనీలో చేరాలి లేదా ఏ మార్గాన్ని ఎంచుకోవాలి వంటి అనేక నిర్ణయాలు తీసుకోవలసి ఉంటుంది. ఆ సమయంలో నిపుణులు కూడా సహాయం చేయగలరు.",
              "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ଆପଣଙ୍କ କ୍ୟାରିୟର୍‌ ପାଇଁ ଅନେକ ନିଷ୍ପତ୍ତି ନିଆଯିବ ଯେପରିକି କେଉଁ କମ୍ପାନୀରେ ଯୋଗଦେବେ କିମ୍ବା କେଉଁ ଅଭିବୃଦ୍ଧି ପଥ ବାଛିବେ | ସେହି ସମୟରେ ମଧ୍ୟ ବିଶେଷଜ୍ଞମାନେ ସାହାଯ୍ୟ କରିପାରିବେ |",
              "as": "আকৌ চিন্তা কৰক! আপোনাৰ কেৰিয়াৰৰ বাবে বহুতো সিদ্ধান্ত ল'ব লাগিব যেনে কোনটো কোম্পানীত যোগদান কৰিব লাগে বা কোনটো বিকাশৰ পথ বাছনি কৰিব লাগে। বিশেষজ্ঞসকলে সেই সময়ছোৱাতো সহায় কৰিব পাৰে।",
              "gu": "ફરી વખત વિચાર કરો! તમારી કારકિર્દી માટે અનેક નિર્ણય લેવાના હશે, જે કોઈપણ કંપનીમાં સામેલ થાય છે અથવા તો કયા વિકાસના માર્ગ પસંદ કરવાની છે. નિષ્ણાત તે સમયે પણ મદદ કરી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "Think again! Experts can be biased too. It is good to do your research well.",
              "hi": "फिर से विचार करो! विशेषज्ञ भी पक्षपाती हो सकते हैं। अपने शोध को भली भांति करना अच्छा है।",
              "ka": "ಪುನಃ ಆಲೋಚಿಸು! ತಜ್ಞರು ಸಹ ಪಕ್ಷಪಾತ ಮಾಡಬಹುದು. ನಿಮ್ಮ ಸಂಶೋಧನೆಯನ್ನು ಚೆನ್ನಾಗಿ ಮಾಡುವುದು ಒಳ್ಳೆಯದು.",
              "te": "మళ్లీ ఆలోచించండి! నిపుణులు పక్షపాతంగా కూడా ఉండవచ్చు. మీ స్వంత రీసర్చ్ బాగా చేయడం మంచిది.",
              "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ବିଶେଷଜ୍ଞମାନେ ପକ୍ଷପାତ ମଧ୍ୟ ହୋଇପାରନ୍ତି | ତୁମର ଅନୁସନ୍ଧାନକୁ ଭଲଭାବରେ କରିବା ଭଲ |",
              "as": "আকৌ চিন্তা কৰক! বিশেষজ্ঞসকলেও পক্ষপাতিত্ব কৰিব পাৰে। আপোনাৰ গৱেষণা ভালদৰে কৰাটো ভাল।",
              "gu": "ફરી વખત વિચાર કરો! નિષ્ણાતો પણ પક્ષપાતી હોઈ શકે છે. તમારા સંશોધનોને સારી રીતે કરવા યોગ્ય છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Great job!",
              "hi": "उत्तम काम!",
              "ka": "ಉತ್ತಮ ಕೆಲಸ!",
              "te": "గొప్ప పని!",
              "or": "ଉତ୍ତମ କାର୍ଯ୍ୟ !",
              "as": "ভাল কাম!",
              "gu": "ખૂબ જ સારી કામગીરી!"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Think again! If you prepare your thoughts & questions, you can learn \na lot in a short conversation too.",
              "hi": "फिर से विचार करो! यदि आप अपने विचार और प्रश्न तैयार करते हैं,तो एक छोटी बातचीत में भी आप बहुत कुछ सीख सकते हैं।\n",
              "ka": "\"ಮತ್ತೆ ಯೋಚಿಸಿ! ನಿಮ್ಮ ಆಲೋಚನೆಗಳು ಮತ್ತು ಪ್ರಶ್ನೆಗಳನ್ನು ನೀವು ಸಿದ್ಧಪಡಿಸಿದರೆ,ಒಂದು ಸಣ್ಣ ಸಂಭಾಷಣೆಯಲ್ಲಿ ನೀವುತುಂಬಾ ಕಲಿಯಬಹುದು.\"",
              "te": "మళ్లీ ఆలోచించండి! మీరు మీ ఆలోచనలు & ప్రశ్నలను సిద్ధం చేసుకొని ఉంటే, మీరు చిన్న సంభాషణలో కూడా చాలా నేర్చుకోవచ్చు.",
              "or": "ପୁଣି ଥରେ ଭାବନ୍ତୁ ! ଯଦି ତୁମେ ତୁମର ଚିନ୍ତାଧାରା ଏବଂ ପ୍ରଶ୍ନ ପ୍ରସ୍ତୁତ କର, ତୁମେ ଏକ ଛୋଟ ବାର୍ତ୍ତାଳାପରେ ମଧ୍ୟ ବହୁତ କିଛି ଶିଖିପାରିବ |",
              "as": "আকৌ চিন্তা কৰক! যদি আপুনি আপোনাৰ চিন্তা আৰু প্ৰশ্ন প্ৰস্তুত কৰে, আপুনি শিকিব পাৰে \n এটা চমু কথোপকথনতো বহুত শিকিব পাৰে।",
              "gu": "ફરીથી વિચાર કરવા! જો તમે તમારા વિચાર અને પ્રશ્ન તૈયાર કરે છે, તો તમે શીખી શકો છો."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_1",
              "hi": "C1_2_1",
              "ka": "C1_2_1",
              "te": "C1_2_1",
              "or": "C1_2_1",
              "as": "C1_2_1",
              "gu": "C1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "When Arvind returns home that day, Pranav waits for him at the entrance.",
              "hi": "जब अरविंद उस दिन घर लौटता है, तो प्रणव प्रवेश द्वार पर उसके लिए प्रतीक्षा कर रहा है।",
              "ka": "ಆ ದಿನ ಅರವಿಂದ್ ಮನೆಗೆ ಹಿಂದಿರುಗಿದಾಗ, ಪ್ರಣವ್ ಪ್ರವೇಶದ್ವಾರದಲ್ಲಿ ಅವನಿಗಾಗಿ ಕಾಯುತ್ತಾನೆ.",
              "te": "ఆ రోజు అరవింద్ ఇంటికి తిరిగి రాగానే, ప్రణవ్ ఎంట్రన్స్ వద్ద అతని కోసం ఎదురు చూస్తున్నాడు.",
              "or": "ସେଦିନ ଅରବିନ୍ଦ ଯେବେ ଘରକୁ ଫେରନ୍ତି, ପ୍ରଣବ ତାଙ୍କୁ ପ୍ରବେଶ ଦ୍ୱାରରେ ଅପେକ୍ଷା କରିଥିଲେ |",
              "as": "যেতিয়া অৰবিন্দ সেইদিনা ঘৰলৈ উভতি আহে, প্ৰণৱে দুৱাৰমুখত তেওঁৰ বাবে অপেক্ষা কৰে।",
              "gu": "જ્યારે અરવિંદ તે દિવસે ઘરે પરત ફર્યો તો પ્રણવ પ્રવેશ દ્વાર ઉપર તેમની રાહ જોઈ રહ્યો છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_2",
              "hi": "C1_2_2",
              "ka": "C1_2_2",
              "te": "C1_2_2",
              "or": "C1_2_2",
              "as": "C1_2_2",
              "gu": "C1_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Pranav has not been involved in Arvind's life since he left for college. But when he hears about everything Arvind has been through, he feels extremely sad.",
              "hi": "कॉलेज जाने के बाद से प्रणव अरविंद के जीवन  घनिष्ठ रूप से जुड़ा हुआ नहीं है। लेकिन जब वह सुनता है कि अरविंद किस परिस्थिति से गुजर रहा है, वह बेहद दुखी है।",
              "ka": "ಪ್ರಣವ್ ಕಾಲೇಜಿಗೆ ಹೋದಾಗಿನಿಂದ ಅರವಿಂದ್ ಜೀವನದಲ್ಲಿ ತೊಡಗಿಸಿಕೊಂಡಿಲ್ಲ. ಆದರೆ ಅರವಿಂದ್ ಅನುಭವಿಸಿದ ಎಲ್ಲವನ್ನೂ ಕೇಳಿದಾಗ, ಅವನು ತುಂಬಾ ದುಃಖಿತರಾಗುತ್ತಾನೆ.",
              "te": "కాలేజీకి వెళ్ళినప్పటి నుండి అరవింద్ జీవితంలో ప్రణవ్ ప్రమేయం లేదు. కానీ అరవింద్ అనుభవించిన ప్రతి దాని గురించి విన్నప్పుడు, అతను చాలా బాధపడ్డాడు.",
              "or": "ଯେବେଠାରୁ ସେ କଲେଜ ପାଇଁ ଯାଇଥିଲେ ପ୍ରଣବ ଅରବିନ୍ଦଙ୍କ ଜୀବନରେ ଜଡିତ ନଥିଲେ | କିନ୍ତୁ ଯେତେବେଳେ ସେ ଶୁଣନ୍ତି ଅରବିନ୍ଦ ଯାହା ଦେଇ ଗତି କରିଛନ୍ତି ବିଷୟରେ, ସେ ଅତ୍ୟନ୍ତ ଦୁଃଖିତ ଅନୁଭବ କରନ୍ତି |",
              "as": "কলেজলৈ যোৱাৰ পিছৰে পৰা প্ৰণৱ অৰবিন্দৰ জীৱনৰ সৈতে জড়িত হোৱা নাই। কিন্তু যেতিয়া তেওঁ অৰবিন্দৰ সকলোবোৰ কথা শুনিছিল, তেওঁ অত্যন্ত দুখ অনুভৱ কৰিছিল।",
              "gu": "કોલેજ ગયા બાદથી પ્રણવ અરવિંદના જીવનમાં સામેલ કરી શકાય નહીં. જોકે જ્યારે તે સાંભળે છે કે અરવિંદ જે હવેથી પસાર થઈ રહ્યા છે, તે ઘણું દુખી છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_3",
              "hi": "C1_2_3",
              "ka": "C1_2_3",
              "te": "C1_2_3",
              "or": "C1_2_3",
              "as": "C1_2_3",
              "gu": "C1_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He is disappointed with himself for not being there for Arvind when he needed him the most.",
              "hi": "वह अपने से निराश है क्योंकि जब अरविंद को उसकी सबसे ज्यादा जरूरत थी, वह उसके लिए वहां नहीं था।",
              "ka": "ಅರವಿಂದನಿಗೆ ಅತ್ಯಂತ ಅಗತ್ಯವಿದ್ದಾಗ ಅವನ ಬಳಿ ಇಲ್ಲದಿದ್ದಕ್ಕಾಗಿ ಅವನು ತನ್ನ ಬಗ್ಗೆಯೇ ನಿರಾಶೆಗೊಂಡಿದ್ದಾನೆ.",
              "te": "అరవింద్‌కు అత్యంత అవసరమైనప్పుడు అతని వద్ద లేకపోవడంతో అతను నిరాశ చెందుతాడు.",
              "or": "ଯେତେବେଳେ ସେ ତାଙ୍କୁ ଅଧିକ ଆବଶ୍ୟକ କରୁଥିଲେ, ଅରବିନ୍ଦଙ୍କ ପାଇଁ ସେଠାରେ ନଥିବାରୁ ସେ ନିଜ ଉପରେ ନିରାଶ ହୁଅନ୍ତି |",
              "as": "যেতিয়া তেওঁক আটাইতকৈ বেছি প্ৰয়োজন হৈছিল তেতিয়া অৰবিন্দৰ সৈতে তাত নথকাৰ বাবে তেওঁৰ বৰ বেয়া লাগিছিল।",
              "gu": "તે અરવિંદ માટે કઈ કરી શકતી નથી કારણ કે તે પોતે નિરાશ છે જ્યારે તેની સૌથી વધારે જરૂરિયાત હતી."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_4////",
              "hi": "C1_2_4 /////",
              "ka": "C1_2_4////",
              "te": "C1_2_4////",
              "or": "C1_2_4////",
              "as": "C1_2_4////",
              "gu": "C1_2_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "This is unfair, Arvind. How could they do this to you? I heard you aren't even continuing with engineering anymore. We must go file a complaint against them.",
              "hi": "यह अनुचित है, अरविंद। वे तुम्हारे साथ ऐसा कैसे कर सकते हैं? मैंने सुना है कि अब तुम इंजीनियरिंग भी नहीं कर रहे हो। हमें उनके खिलाफ शिकायत दर्ज करनी होगी।",
              "ka": "ಇದು ಅನ್ಯಾಯ ಅರವಿಂದ್. ಅವರು ನಿನಗೆ ಇದನ್ನು ಹೇಗೆ ಮಾಡಬಹುದು? ನೀವು ಇನ್ನು ಮುಂದೆ ಇಂಜಿನಿಯರಿಂಗ್ ಅನ್ನು ಮುಂದುವರಿಸುವುದಿಲ್ಲ ಎಂದು ನಾನು ಕೇಳಿದೆ. ನಾವು ಅವರ ವಿರುದ್ಧ ದೂರು ದಾಖಲಿಸಲು ಹೋಗಬೇಕು.",
              "te": "ఇది అన్యాయం అరవింద్. వారు నీకు అలా ఎలా చేయగలరు? నువ్వు ఇంజినీరింగ్‌ కూడా కొనసాగించడం లేదని విన్నాను. మనము వెళ్ళి వారిపై ఫిర్యాదు చేయాలి.",
              "or": "ଏହା ଅନ୍ୟାୟ, ଅରବିନ୍ଦ | ସେମାନେ ତୁମକୁ ଏହା କିପରି କରିପାରିବେ ? ମୁଁ ଶୁଣିଛି ତୁମେ ଆଉ ଇଞ୍ଜିନିୟରିଂ ଜାରି ରଖୁନାହଁ | ଆମେ ସେମାନଙ୍କ ବିରୋଧରେ ଏକ ଅଭିଯୋଗ ଦାଖଲ କରିବାକୁ ଯିବା ଜରୁରୀ |",
              "as": "এয়া অন্যায়, অৰবিন্দ। তেওঁলোকে তোমাৰ সৈতে কেনেকৈ এনে কৰিব পাৰে? মই শুনিছিলো যে তুমি আনকি এতিয়া অভিযান্ত্ৰিক পঢ়া নাই। আমি তেওঁলোকৰ বিৰুদ্ধে অভিযোগ দাখিল কৰিব লাগিব।",
              "gu": "આ અયોગ્ય છે, અરવિંદ. આ તમારી સાથે આ કેવી રીતે કરી શકાય છે? મે સાંભળ્યું છે કે તમે હવે એન્જીનિયરિંગ જારી રાખી શકાય તેમ ન હતી. અમે તેની સામે ફરિયાદ નોંધાવવી જોઈએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Pranav, there's nothing much you can do. And even if you stop these boys from harassing me, someone else will.",
              "hi": "प्रणव, तुम ज्यादा कुछ नहीं कर सकते। और यदि तुम इन लड़कों को परेशान करने से रोकते हो, तो कोई और होगा।",
              "ka": "ಪ್ರಣವ್, ನಿನ್ನಿಂದ ಏನೂ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ. ಮತ್ತು ಈ ಹುಡುಗರು ನನಗೆ ಕಿರುಕುಳ ನೀಡುವುದನ್ನು ನಿಲ್ಲಿಸಿದರೂ, ಬೇರೆಯವರು ಮಾಡುತ್ತಾರೆ.",
              "te": "ప్రణవ్, నువ్వు చేయగలిగింది ఏమీ లేదు. ఈ అబ్బాయిలు నన్ను వేధించకుండా నువ్వు ఆపినా కూడా, ఇంకొకరు చేస్తారు.",
              "or": "ପ୍ରଣବ, ତୁମେ ଅଧିକ କିଛି କରିପାରିବ ନାହିଁ | ଏବଂ ଯଦି ତୁମେ ଏହି ପୁଅମାନଙ୍କୁ ମୋତେ ହଇରାଣ କରିବାକୁ ଅଟକାଇବ, ଅନ୍ୟ କେହି କରିବେ |",
              "as": "প্ৰণৱ, আপুনি বিশেষ একো কৰিব নোৱাৰে। আৰু আপুনি এই ল'ৰাবোৰক মোক হাৰাশাস্তি কৰাত বাধা দিলেও, আন কোনোবাই কৰিব।",
              "gu": "પ્રણવ,તમે વધારે કંઈ જ કરી શકતા નથી. અને જો તુ આ છોકરાઓને પરેશાન કરતા અટકાવીશ તો અન્ય કોઈ કરશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I'm sorry, Arvind, that you have had to go through this.",
              "hi": "मुझे दुःख है, अरविंद, कि तुमको इस सब से गुजरना पड़ा।",
              "ka": "ಕ್ಷಮಿಸಿ, ಅರವಿಂದ್, ನೀವು ಇದನ್ನೆಲ್ಲ ಅನುಭವಿಸಬೇಕಾಗಿತ್ತು.",
              "te": "సారీ అరవింద్, నువ్వు చాలా అనుభవించవలసి వచ్చింది.",
              "or": "ମୁଁ ଦୁଃଖିତ, ଅରବିନ୍ଦ, ଯେ ତୁମକୁ ଏହା ଦେଇ ଯିବାକୁ ପଡିଲା |",
              "as": "মই দুঃখিত, অৰবিন্দ, তুমি এনেকুৱা দিন পাৰ কৰিব লগা হৈছে।",
              "gu": "મને માફ કરશો, કે તારે આ સ્થિતિ માથી પસાર થવું પડે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "It's okay. I'm used to it now. I still feel sad and scared, but I have a fantastic support group where people understand me and care about me. I'm doing much better.",
              "hi": "कोई बात नहीं। अब  मुझे इसकी आदत हो गई है। मैं अभी भी दुख और डर का अनुभव करता हूं, लेकिन मेरे पास एक शानदार समर्थन समूह है जहां लोग मुझे समझते हैं और मेरी परवाह करते हैं। मैं बहुत बेहतर कर रहा हूं।",
              "ka": "ಪರವಾಗಿಲ್ಲ. ನನಗೀಗ ಅಭ್ಯಾಸವಾಗಿದೆ. ನಾನು ಇನ್ನೂ ದುಃಖ ಮತ್ತು ಭಯವನ್ನು ಅನುಭವಿಸುತ್ತೇನೆ, ಆದರೆ ಜನರು ನನ್ನನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವ ಮತ್ತು ನನ್ನ ಬಗ್ಗೆ ಕಾಳಜಿ ವಹಿಸುವ ಅದ್ಭುತ ಬೆಂಬಲ ಗುಂಪನ್ನು ನಾನು ಹೊಂದಿದ್ದೇನೆ. ನಾನು ಹೆಚ್ಚು ಉತ್ತಮವಾಗಿ ಮಾಡುತ್ತಿದ್ದೇನೆ.",
              "te": "పర్వాలేదు. నేను ఇప్పుడు అలవాటు పడ్డాను. నాకు ఇప్పటికీ విచారంగా మరియు భయంగా ఉంది కానీ నన్ను అర్థం చేసుకునే మరియు నా పట్ల శ్రద్ధ వహించే అద్భుతమైన సపోర్ట్ గ్రూప్ నాకు దొరికింది. నేను ఇప్పుడు చాలా బాగున్నాను.",
              "or": "ଠିକ ଅଛି। ମୁଁ ବର୍ତ୍ତମାନ ଏଥିରେ ଅଭ୍ୟସ୍ତ | ମୁଁ ତଥାପି ଦୁଃଖିତ ଏବଂ ଭୟଭୀତ ଅନୁଭବ କରୁଛି, କିନ୍ତୁ ମୋର ଏକ ଉତକୃଷ୍ଟ ସମର୍ଥନ ସମୁଦାୟ ଅଛି ଯେଉଁଠାରେ ଲୋକମାନେ ମୋତେ ବୁଝନ୍ତି ଏବଂ ମୋ ବିଷୟରେ ଚିନ୍ତା କରନ୍ତି | ମୁଁ ବହୁତ ଭଲ କରୁଛି |",
              "as": "ঠিক আছে। মই এতিয়া এইবোৰত অভ্যস্ত। মই এতিয়াও দুখ আৰু ভয় অনুভৱ কৰোঁ, কিন্তু মোৰ এটা চমৎকাৰ সমৰ্থন গোট আছে য'ত মানুহে মোক বুজি পায় আৰু মোৰ বিষয়ে চিন্তা কৰে। মই বহুত ভাল কৰি আছো।",
              "gu": "સારું છે, મને હવે આદત થઈ રહી છે. હું હજુ પણ દુખી છું અને ડરનો અહેસાસ કરું છું, જોકે મારી પાસે એક શાનદાર સપોર્ટ ગ્રુપ છે, જ્યાં લોકો મને સમજે છે અને મારી કાળજી લે છે. હું ઘણું સારું કરી  રહ્યો છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "I wish I could have been there for you. I feel like I have been silently watching you get bullied since we were in school, and I wasn't brave enough to stand up for you.",
              "hi": "काश मैं तुम्हारे लिए वहां होता। मुझे ऐसा लगता है कि जब से हम स्कूल में थे, तब से मैं चुपचाप  ऐसे लड़कों द्वारा तुमको धमकाते हुए देख रहा हूं, और मैं इतना बहादुर नहीं था कि मैं तुम्हारे लिए खड़ा हो सकूं।",
              "ka": "ನಾನು ನಿನಗಾಗಿ ಅಲ್ಲಿರಬಹುದೆಂದು ನಾನು ಬಯಸುತ್ತೇನೆ. ನಾವು ಶಾಲೆಯಲ್ಲಿದ್ದಾಗಿನಿಂದ ನೀನು ಹಿಂಸೆಗೆ ಒಳಗಾಗುವುದನ್ನು ನಾನು ಮೌನವಾಗಿ ನೋಡುತ್ತಿದ್ದೇನೆ ಮತ್ತು ನಿನ್ನ ಪರವಾಗಿ ನಿಲ್ಲುವಷ್ಟು ಧೈರ್ಯವಿರಲಿಲ್ಲ.",
              "te": "నేను నీ కోసం అక్కడ ఉండవలసింది. మనము స్కూల్‌లో ఉన్నప్పటి నుండి నువ్వు వేధింపులకు గురికావడాన్ని నేను మౌనంగా చూస్తున్నాను కానీ నీ కోసం నిలబడేంత ధైర్యం నాకు లేదు.",
              "or": "ମୋ ଇଚ୍ଛା ମୁଁ ତୁମ ପାଇଁ ସେଠାରେ ଥାନ୍ତି | ମୁଁ ଅନୁଭବ କରୁଛି ଯେ ଆମେ ସ୍କୁଲରେ ପଢିବା ଦିନଠାରୁ ତୁମକୁ ଗାଳିଗୁଲଜ ହେଉଥିବାର ନୀରବରେ ଦେଖୁଥିଲି, ଏବଂ ମୁଁ ତୁମ ପାଇଁ ଠିଆ ହେବା ପାଇଁ ୟଥେଷ୍ଟ ସାହସୀ ନଥିଲି |",
              "as": "মই যদি তোমাৰ বাবে তাত থাকিব পাৰিলোহেঁতেন। মই অনুভৱ কৰোঁ যে আমি স্কুলত পঢ়ি থকাৰ পৰাই তোমাক উৎপীড়ন কৰা দেখি আছোঁ, আৰু মই তোমাৰ হৈ থিয় হ'বলৈ যথেষ্ট সাহসী নাছিলো।",
              "gu": "કદાંચ હું તમારા માટે ત્યા હોઈ શકું છું.મને એવું લાગે છે કે જ્યારથી આપણે શાળામાં હતા ત્યારથી હું ચુપચાપ તને ધમકાવતા જોઈ દુખી થાવું છું, અને હું એટલો બહાદૂર નથી કે તમારા માટે સાથે ઉભો રહું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.5.text",
            "value": {
              "en": "I always wished you didn't treat me like an embarrassment. But I am glad you understand now.",
              "hi": "मैं हमेशा कामना करता था कि आपने मेरे साथ शर्मिंदगी की तरह व्यवहार न किया होता। लेकिन मुझे खुशी है कि आप अब समझते हैं।",
              "ka": "ನೀನು ನನ್ನನ್ನು ಮುಜುಗರದ ರೀತಿಯಲ್ಲಿ ನಡೆಸಿಕೊಳ್ಳಬಾರದೆಂದು ನಾನು ಯಾವಾಗಲೂ ಬಯಸುತ್ತೇನೆ. ಆದರೆ ನೀನು ಈಗ ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೀರಿ ಎಂದು ನನಗೆ ಖುಷಿಯಾಗಿದೆ.",
              "te": "నువ్వు నన్ను ఇబ్బందిగా చూడకూడదని నేను ఎప్పుడూ అనుకునేవాన్ని. కానీ నువ్వు ఇప్పుడు అర్థం చేసుకున్నందుకు నేను సంతోషిస్తున్నాను.",
              "or": "ମୁଁ ସବୁବେଳେ ଇଚ୍ଛା କରେ ତୁମେ ମୋତେ ଏକ ଲଜ୍ଜାଜନକ ପରି ବ୍ୟବହାର କର ନାହିଁ | କିନ୍ତୁ ମୁଁ ଖୁସି ତୁମେ ବର୍ତ୍ତମାନ ବୁଝିଛ |",
              "as": "মই সদায় আশা কৰিছিলো যে আপুনি মোক লজ্জ্বাজনক ব্যক্তিৰ দৰে ব্যৱহাৰ নকৰে। কিন্তু মই সুখী যে আপুনি এতিয়া বুজি পাইছে।",
              "gu": "હું હંમેશા ઈચ્છું છું કે તુ મારી સાથે અપમાનજનક વ્યવહાર નહીં કરે. જોકે મને ખુશી છે કે હવે તમે સમજી ચુક્યા છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "C1_2_5",
              "hi": "C1_2_5",
              "ka": "C1_2_5",
              "te": "C1_2_5",
              "or": "C1_2_5",
              "as": "C1_2_5",
              "gu": "C1_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Pranav and Arvind's relationship slowly begins to mend. Pranav starts visiting him more often and sometimes accompanies Arvind when he wants to go out to places to feel safe.",
              "hi": "प्रणव और अरविंद के रिश्ते धीरे-धीरे ठीक होने लगते हैं। प्रणव उससे अधिक बार मिलने लगता है और कभी-कभी अरविंद के साथ जाता है जब वह सुरक्षित अनुभव करने के लिए बाहर जाना चाहता है।",
              "ka": "ಪ್ರಣವ್ ಮತ್ತು ಅರವಿಂದ್ ಸಂಬಂಧವು ನಿಧಾನವಾಗಿ ಸರಿಪಡಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತದೆ. ಪ್ರಣವ್ ಅವರನ್ನು ಹೆಚ್ಚಾಗಿ ಭೇಟಿ ಮಾಡಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ ಮತ್ತು ಕೆಲವೊಮ್ಮೆ ಅರವಿಂದ್ ಅವರು ಸುರಕ್ಷಿತವಾಗಿರಲು ಸ್ಥಳಗಳಿಗೆ ಹೋಗಲು ಬಯಸಿದಾಗ ಅವರೊಂದಿಗೆ ಹೋಗುತ್ತಾರೆ.",
              "te": "ప్రణవ్ మరియు అరవింద్ మధ్య సంబంధం మెల్లగా మెరుగుపడటం ప్రారంభమవుతుంది. ప్రణవ్ అతనిని తరచుగా కలవడం ప్రారంభిస్తాడు మరియు కొన్నిసార్లు అరవింద్ ఎక్కడికైనా వెళ్లాలనుకున్నప్పుడు సురక్షితంగా ఉండటానికి అతనితో పాటు వెళ్తాడు.",
              "or": "ପ୍ରଣବ ଏବଂ ଅରବିନ୍ଦଙ୍କ ସମ୍ପର୍କ ଧୀରେ ଧୀରେ ସୁଧାରିବାକୁ ଲାଗିଲା | ପ୍ରଣବ ତାଙ୍କୁ ବାରମ୍ବାର ପରିଦର୍ଶନ କରିବା ଆରମ୍ଭ କରନ୍ତି ଏବଂ ବେଳେବେଳେ ଅରବିନ୍ଦଙ୍କୁ ସାଙ୍ଗରେ ନେଇଯାଆନ୍ତି ଯେତେବେଳେ ସେ ନିଜକୁ ନିରାପଦ ଅନୁଭବ କରିବା ପାଇଁ ବାହାରକୁ ଯିବାକୁ ଚାହାଁନ୍ତି |",
              "as": "প্ৰণৱ আৰু অৰবিন্দৰ সম্পৰ্ক লাহে লাহে ভাল হ'বলৈ আৰম্ভ কৰে। প্ৰণৱে তেওঁক সঘনাই লগ কৰিবলৈ আৰম্ভ কৰে আৰু কেতিয়াবা অৰবিন্দৰ সৈতে তেওঁ সুৰক্ষিত অনুভৱ কৰা ঠাইলৈ যায় যেতিয়া যাব বিচাৰে।",
              "gu": "પ્રણવ અને અરવિંદ વચ્ચેના સંબંધ ધીમે ધીમે સારા થઈ રહ્યા છે. પ્રણવ તેને ઘણી વખત મળી ચુક્યો છે અને ક્યારેક ક્યારેક અરવિંદ સાથે જાય છે, કે જ્યારે તે સુરક્ષિત હોવાનો અહેસાસ કરવા માટે બહાર જવું જોઈએ."
            }
          }
        ]
      },
      "scene12": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_1",
              "hi": "C2_1_1",
              "ka": "C2_1_1",
              "te": "C2_1_1",
              "or": "C2_1_1",
              "as": "C2_1_1",
              "gu": "C2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "With the help of his family and the support group, Arvind starts feeling confident enough to get back to studying.",
              "hi": "अपने परिवार और समर्थन समूह की सहायता से, अरविंद अध्ययन करने के लिए पर्याप्त आत्मविश्वास अनुभव कर रहा है।",
              "ka": "ತನ್ನ ಕುಟುಂಬ ಮತ್ತು ಬೆಂಬಲ ಗುಂಪಿನ ಸಹಾಯದಿಂದ, ಅರವಿಂದ್ ಮತ್ತೆ ಅಧ್ಯಯನಕ್ಕೆ ಮರಳಲು ಸಾಕಷ್ಟು ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಅನುಭವಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ.",
              "te": "తన కుటుంబం మరియు సపోర్టు గ్రూప్ సహాయంతో, అరవింద్ తిరిగి చదువుకోవడానికి కావలసినంత నమ్మకం సంపాదిస్తాడు.",
              "or": "ତାଙ୍କ ପରିବାର ଏବଂ ସମର୍ଥନ ସମୁଦାୟର ସାହାଯ୍ୟରେ, ଅରବିନ୍ଦ ଅଧ୍ୟୟନକୁ ଫେରିବା ପାଇଁ ଯଥେଷ୍ଟ ଆତ୍ମବିଶ୍ୱାସୀ ଅନୁଭବ କରିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "তেওঁৰ পৰিয়াল আৰু সমৰ্থন গোটৰ সহায়ত, অৰবিন্দে অধ্যয়নলৈ উভতি যাবলৈ যথেষ্ট আত্মবিশ্বাসী অনুভৱ কৰিবলৈ আৰম্ভ কৰে।",
              "gu": "પોતાના પરિવાર અને સહાયતા સમૂહની મદદથી અરવિંદ અભ્યાસમાં પરત આવવા માટે પૂરતા આત્મવિશ્વાસનો અહેસાસ કરવા લાગે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_2",
              "hi": "C2_1_2",
              "ka": "C2_1_2",
              "te": "C2_1_2",
              "or": "C2_1_2",
              "as": "C2_1_2",
              "gu": "C2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Taking the advice of Professor Prasidh, Arvind applies for the distance learning program in political science from Telangana Institute of Social Sciences.",
              "hi": "प्रोफेसर प्रसिध की सलाह से, अरविंद तेलंगाना इंस्टीट्यूट ऑफ सोशल साइंसेज से राजनीति विज्ञान में दूरस्थ शिक्षा कार्यक्रम के लिए आवेदन करता है।",
              "ka": "ಪ್ರೊಫೆಸರ್ ಪ್ರಸಿದ್ಧ್ ಅವರ ಸಲಹೆಯನ್ನು ಪಡೆದು, ಅರವಿಂದ್ ಅವರು ತೆಲಂಗಾಣ ಇನ್‌ಸ್ಟಿಟ್ಯೂಟ್ ಆಫ್ ಸೋಶಿಯಲ್ ಸೈನ್ಸಸ್‌ನಿಂದ ರಾಜಕೀಯ ವಿಜ್ಞಾನದಲ್ಲಿ ದೂರಶಿಕ್ಷಣ ಕಾರ್ಯಕ್ರಮಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಾರೆ.",
              "te": "ప్రొఫెసర్ ప్రసిధ్ సలహా తీసుకొని, తెలంగాణ ఇన్‌స్టిట్యూట్ ఆఫ్ సోషల్ సైన్సెస్ నుండి పొలిటికల్ సైన్స్‌లో దూరవిద్య ప్రోగ్రామ్‌కు అరవింద్ దరఖాస్తు చేసుకున్నాడు.",
              "or": "ପ୍ରଫେସର ପ୍ରସିଦ୍ଧଙ୍କ ପରାମର୍ଶ ଗ୍ରହଣ କରି, ଅରବିନ୍ଦ ତେଲେଙ୍ଗାନା ଇନଷ୍ଟିଚ୍ୟୁଟ୍ ଅଫ୍ ସୋସିଆଲ୍ ସାଇନ୍ସରୁ ରାଜନୀତି ବିଜ୍ଞାନରେ ଦୂରସ୍ଥ ଶିକ୍ଷା କାର୍ଯ୍ୟକ୍ରମ ପାଇଁ ଆବେଦନ କରନ୍ତି।",
              "as": "অধ্যাপক প্ৰসিদ্ধৰ পৰামৰ্শ লৈ, অৰবিন্দে তেলেংগানা সামাজিক বিজ্ঞান প্ৰতিষ্ঠানৰ পৰা ৰাজনীতি বিজ্ঞানত দূৰত্ব শিক্ষণ কাৰ্যসূচীৰ বাবে আবেদন কৰে।",
              "gu": "પ્રોફેસર પ્રસિદ્ધની સલાહ લેતા અરવિંદે તેલંગાણા ઈન્સ્ટીટ્યુટ ઓફ સોશિયલ સાયન્સિસમાંથી પોલિટીકલ સાયન્સથી ડિસ્ટન્સ લર્નિંગ કાર્યક્રમ માટે અરજી કરી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_3",
              "hi": "C2_1_3",
              "ka": "C2_1_3",
              "te": "C2_1_3",
              "or": "C2_1_3",
              "as": "C2_1_3",
              "gu": "C2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The selection is based on the 12th standard percentage, and since he has a good score, he gets admission.",
              "hi": "चयन 12वीं कक्षा के प्रतिशत पर आधारित है, और चूंकि उसके पास एक अच्छा स्कोर है, इसलिए उसे प्रवेश मिल जाता है।",
              "ka": "ಆಯ್ಕೆಯು 12 ನೇ ತರಗತಿಯ ಶೇಕಡಾವಾರು ಆಧಾರದ ಮೇಲೆ ಮತ್ತು ಅವರು ಉತ್ತಮ ಅಂಕಗಳನ್ನು ಹೊಂದಿರುವುದರಿಂದ, ಅವರು ಪ್ರವೇಶವನ್ನು ಪಡೆಯುತ್ತಾರೆ.",
              "te": "ఎంపిక 12వ తరగతి శాతంపై ఆధారపడి ఉంటుంది మరియు అతనికి మంచి స్కోర్ ఉన్నందున, అతను అడ్మిషన్ పొందుతాడు.",
              "or": "ଚୟନ 12th ଶ୍ରେଣୀ ଶତକଡା ଉପରେ ଆଧାରିତ, ଏବଂ ଯେହେତୁ ତାଙ୍କର ଭଲ ସ୍କୋର ଥିଲା, ସେ ଆଡମିଶନ ପାଆନ୍ତି |",
              "as": "বাছনিটো দ্বাদশ শ্ৰেণীৰ শতাংশৰ ওপৰত আধাৰিত, আৰু যিহেতু তেওঁৰ ভাল নম্বৰ আছে, তেওঁ নামভৰ্তি কৰে।",
              "gu": "પસંદગી ધોરણ-12ના ટકાવારીના આધારે થાય છે, અને આ સાથે સ્કોર સારા છે, આ માટે પ્રવેશ મળે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_4",
              "hi": "C2_1_4",
              "ka": "C2_1_4",
              "te": "C2_1_4",
              "or": "C2_1_4",
              "as": "C2_1_4",
              "gu": "C2_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "In the course, Arvind studies many different subjects where he learns about other systems of the government, political thought and political behaviour.",
              "hi": "पाठ्यक्रम में, अरविंद कई अलग-अलग विषयों का अध्ययन करता है जहां वह सरकार की अन्य प्रणालियों, राजनीतिक विचार और राजनीतिक व्यवहार के बारे में सीखता है।",
              "ka": "ಕೋರ್ಸ್‌ನಲ್ಲಿ, ಅರವಿಂದ್ ಅವರು ಸರ್ಕಾರದ ಇತರ ವ್ಯವಸ್ಥೆಗಳು, ರಾಜಕೀಯ ಚಿಂತನೆ ಮತ್ತು ರಾಜಕೀಯ ನಡವಳಿಕೆಯ ಬಗ್ಗೆ ಕಲಿಯುವ ವಿವಿಧ ವಿಷಯಗಳನ್ನು ಅಧ್ಯಯನ ಮಾಡುತ್ತಾರೆ.",
              "te": "కోర్సులో, అరవింద్ అనేక విభిన్న విషయాల గురించి చదువుతాడు, అక్కడ అతను ఇతర ప్రభుత్వ వ్యవస్థలు, రాజకీయ ఆలోచనలు మరియు రాజకీయ ప్రవర్తన గురించి తెలుసుకుంటాడు.",
              "or": "କୋର୍ସରେ, ଅରବିନ୍ଦ ଅନେକ ଭିନ୍ନ ବିଷୟଗୁଡି଼କ ଅଧ୍ୟୟନ କରନ୍ତି ଯେଉଁଠାରେ ସେ ସରକାରଙ୍କ ଅନ୍ୟାନ୍ୟ ବ୍ୟବସ୍ଥା, ରାଜନୈତିକ ଚିନ୍ତାଧାରା ଏବଂ ରାଜନୈତିକ ଆଚରଣ ବିଷୟରେ ଜାଣନ୍ତି |",
              "as": "পাঠ্যক্ৰমত, অৰবিন্দে বিভিন্ন বিষয় অধ্যয়ন কৰে য'ত তেওঁ চৰকাৰৰ অন্যান্য প্ৰণালী, ৰাজনৈতিক চিন্তা আৰু ৰাজনৈতিক আচৰণৰ বিষয়ে শিকে।",
              "gu": "અભ્યાસક્રમમાં અરવિંદ અનેક અલગ-અલગ વિષયો અંગે અભ્યાસ કરે છે, જ્યાં આ સરકારની અન્ય પદ્ધતિઓ, રાજકીય વિચારો તથા રાજકીય વ્યવહાર અંગે શીખવે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_1_5",
              "hi": "C2_1_5",
              "ka": "C2_1_5",
              "te": "C2_1_5",
              "or": "C2_1_5",
              "as": "C2_1_5",
              "gu": "C2_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to learn about some of the different subjects he is studying:",
              "hi": "उन कुछ अलग-अलग विषयों के बारे में जानने के लिए क्लिक करें जो वह पढ़ रहा है:",
              "ka": "ಅವರು ಅಧ್ಯಯನ ಮಾಡುತ್ತಿರುವ ಕೆಲವು ವಿಭಿನ್ನ ವಿಷಯಗಳ ಕುರಿತು ತಿಳಿಯಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "అతను చదువుతున్న కొన్ని విభిన్న సబ్జెక్ట్స్ గురించి తెలుసుకోవడానికి క్లిక్ చేయండి:",
              "or": "ସେ ଅଧ୍ୟୟନ କରୁଥିବା ବିଭିନ୍ନ ବିଷୟ ବିଷୟରେ ଜାଣିବା ପାଇଁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "তেওঁ অধ্যয়ন কৰি থকা কিছুমান বিভিন্ন বিষয়ৰ বিষয়ে জানিবলৈ ক্লিক কৰক:",
              "gu": "તેમના દ્વારા અભ્યાસ કરવામાં આવી રહેલા વિવિધ વિષયો અંગે જાણવા માટે અહીં ક્લિક કરોઃ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Subject 1",
              "hi": "विषय 1",
              "ka": "ವಿಷಯ 1",
              "te": "సబ్జెక్ట్ 1",
              "or": "Subject 1",
              "as": "বিষয় ১",
              "gu": "વિષય 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Subject 2",
              "hi": "विषय 2",
              "ka": "ವಿಷಯ 2",
              "te": "సబ్జెక్ట్ 2",
              "or": "Subject 2",
              "as": "বিষয় ২",
              "gu": "વિષય 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Subject 3",
              "hi": "विषय 3",
              "ka": "ವಿಷಯ 3",
              "te": "సబ్జెక్ట్ 3",
              "or": "Subject 3",
              "as": "বিষয় ৩",
              "gu": "વિષય 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "**Public Administration**\n\nHow are government policies created and implemented?",
              "hi": "**लोक प्रशासन**\n\nसरकारी नीतियां कैसे बनाई और कार्यान्वित की गई हैं?",
              "ka": "\"**ಸಾರ್ವಜನಿಕ ಆಡಳಿತ**\n \n ಸರ್ಕಾರದ ನೀತಿಗಳನ್ನು ಹೇಗೆ ರಚಿಸಲಾಗಿದೆ ಮತ್ತು ಕಾರ್ಯಗತಗೊಳಿಸಲಾಗುತ್ತದೆ?\"",
              "te": "**ప్రజా పరిపాలన**\n \n \n \n ప్రభుత్వ విధానాలు ఎలా రూపొందించబడతాయి మరియు ఎలా అమలు చేయబడతాయి?",
              "or": "** ସାଧାରଣ ପ୍ରଶାସନ **\n \n ସରକାରୀ ନୀତିଗୁଡିକ କିପରି ସୃଷ୍ଟି ଏବଂ କାର୍ଯ୍ୟକାରୀ ହୁଏ ?",
              "as": "**ৰাজহুৱা প্ৰশাসন**\n \n \n \n চৰকাৰী নীতিবোৰ কেনেদৰে সৃষ্টি আৰু ৰূপায়ণ কৰা হয়?",
              "gu": "** જાહેર વહીવટ**સરકારી નીતિઓ કેવી રીતે તૈયાર અને કાર્યરત કરવામાં આવે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "**Political Theory**\n\nUnderstanding political ideas and values like justice, democracy, power, liberty and freedom.",
              "hi": "** राजनीतिक सिद्धांत **\n\nन्याय, लोकतंत्र, शक्ति, स्वतंत्रता और स्वतंत्रता जैसे राजनीतिक विचारों और मूल्यों को समझना।",
              "ka": "\"**ರಾಜಕೀಯ ಸಿದ್ಧಾಂತ**\n \n ನ್ಯಾಯ, ಪ್ರಜಾಪ್ರಭುತ್ವ, ಅಧಿಕಾರ, ಸ್ವಾತಂತ್ರ್ಯ ಮತ್ತು ಸ್ವಾತಂತ್ರ್ಯದಂತಹ ರಾಜಕೀಯ ವಿಚಾರಗಳು ಮತ್ತು ಮೌಲ್ಯಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು",
              "te": "**రాజకీయ సిద్ధాంతం**\n \n \n \n న్యాయం, ప్రజాస్వామ్యం, అధికారం, స్వేచ్ఛ మరియు స్వతంత్రం వంటి రాజకీయ ఆలోచనలు మరియు విలువలను అర్థం చేసుకోవడం.",
              "or": "ରାଜନୀତିକ ଚିନ୍ତାଧାରା ଏବଂ ମୂଲ୍ୟବୋଧ ଯଥା ନ୍ୟାୟ, ଗଣତନ୍ତ୍ର, କ୍ଷମତା, ସ୍ୱାତନ୍ତ୍ର୍ୟ ଏବଂ ସ୍ୱାଧୀନତାକୁ ବୁଝିବା |",
              "as": "**ৰাজনৈতিক তত্ত্ব**\n \n \n \n ন্যায়, গণতন্ত্ৰ, ক্ষমতা, স্বাধীনতা আৰু স্বাধীনতাৰ দৰে ৰাজনৈতিক ধাৰণা আৰু মূল্যবোধ বুজা।",
              "gu": "**જાહેર થિયરી **ન્યાય વ્યવસ્થા, શક્તિ, સ્વતંત્રતા અને આઝાદી જેવા રાજકીય વિચારો અને મૂલ્યોનું આદાન-પ્રદાન કરવું."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "**Indian Constitution**\n\nFundamental rights and duties; History of governing bodies like Panchayati Raj and the Parliament.",
              "hi": "** भारतीय संविधान **\n\nमौलिक अधिकार और कर्तव्यों; पंचायती राज और संसद जैसे शासी निकाय का इतिहास।",
              "ka": "\"**ಭಾರತೀಯ ಸಂವಿಧಾನ**\n \n ಮೂಲಭೂತ ಹಕ್ಕುಗಳು ಮತ್ತು ಕರ್ತವ್ಯಗಳು; ಪಂಚಾಯತ್ ರಾಜ್ ಮತ್ತು ಸಂಸತ್ತಿನಂತಹ ಆಡಳಿತ ಮಂಡಳಿಗಳ ಇತಿಹಾಸ.\"",
              "te": "**భారత రాజ్యాంగం**\n \n \n \n ప్రాథమిక హక్కులు మరియు విధులు; పంచాయతీరాజ్ మరియు పార్లమెంటు వంటి పాలక సంస్థల చరిత్ర.",
              "or": "** ଭାରତୀୟ ସମ୍ବିଧାନ **\n \n ମୌଳିକ ଅଧିକାର ଏବଂ କର୍ତ୍ତବ୍ୟଗୁଡି଼କ; ପଞ୍ଚାୟତିରାଜ ଏବଂ ସଂସଦ ପରି ଶାସକ ସଂସ୍ଥାନଗୁଡିକର ଇତିହାସ।",
              "as": "**ভাৰতীয় সংবিধান**\n \n মৌলিক অধিকাৰ আৰু কৰ্তব্য; পঞ্চায়তীৰাজ আৰু সংসদৰ দৰে পৰিচালনা সমিতিৰ ইতিহাস।",
              "gu": "**ભારતીય બંધારણ**મૌલિક અધિકાર અને કર્તવ્ય; પંચાયતી રાજ અને સંસદ જેવી સંસ્થાનો ઈતિહાસ"
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_2_1",
              "hi": "C2_2_1",
              "ka": "C2_2_1",
              "te": "C2_2_1",
              "or": "C2_2_1",
              "as": "C2_2_1",
              "gu": "C2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Since he took science in school, these subjects feel very new to him, and it takes him some time to get comfortable with them.",
              "hi": "चूंकि उसने स्कूल में विज्ञान लिया, ये विषय उसके लिए बहुत नए लगते हैं, और उसे उनके साथ सहज होने में कुछ समय लगता है।",
              "ka": "ಅವರು ಶಾಲೆಯಲ್ಲಿ ವಿಜ್ಞಾನವನ್ನು ತೆಗೆದುಕೊಂಡಿದ್ದರಿಂದ, ಈ ವಿಷಯಗಳು ಅವರಿಗೆ ತುಂಬಾ ಹೊಸದು ಎಂದು ತೋರುತ್ತದೆ ಮತ್ತು ಅವರೊಂದಿಗೆ ಆರಾಮದಾಯಕವಾಗಲು ಸ್ವಲ್ಪ ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ.",
              "te": "అతను స్కూల్‌లో సైన్స్ తీసుకున్నాడు కాబట్టి, ఈ సబ్జెక్టులు అతనికి చాలా కొత్తగా అనిపిస్తాయి మరియు వాటికి అలవాటు పడడానికి అతనికి కొంత సమయం పడుతుంది.",
              "or": "ଯେହେତୁ ସେ ବିଦ୍ୟାଳୟରେ ବିଜ୍ଞାନ ଗନେଇଥିଲେ, ଏହି ବିଷୟଗୁଡ଼ିକ ତାଙ୍କ ପାଇଁ ଅତ୍ୟନ୍ତ ନୂଆ ଲାଗିଛି , ଏବଂ ସେଗୁଡିକ ସହ ସହଜ ହେବାକୁ ତାଙ୍କୁ କିଛି ସମୟ ଲାଗେ |",
              "as": "যিহেতু তেওঁ স্কুলত বিজ্ঞান লৈছিল, এই বিষয়বোৰ তেওঁৰ বাবে অতি নতুন যেন লাগে, আৰু বিষয়বোৰৰ সৈতে একাত্ম হ'বলৈ তেওঁক কিছু সময় লাগে।",
              "gu": "જોકે તેમણે સ્કૂલમાં વિજ્ઞાન લેવામાં આવ્યા હતા, માટે આ વિષય તેમને ઘણા નવા લાગે છે અને તેમને તેની સાથે સહજ થવામાં થોડો સમય લાગે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_2_2",
              "hi": "C2_2_2",
              "ka": "C2_2_2",
              "te": "C2_2_2",
              "or": "C2_2_2",
              "as": "C2_2_2",
              "gu": "C2_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Through these subjects, Arvind learns a lot about how the government works at the local, national and international levels.",
              "hi": "इन विषयों के माध्यम से, अरविंद स्थानीय, राष्ट्रीय और अंतर्राष्ट्रीय स्तर पर सरकार के काम करने के तरीके के बारे में बहुत कुछ सीखता है।",
              "ka": "ಈ ವಿಷಯಗಳ ಮೂಲಕ, ಸ್ಥಳೀಯ, ರಾಷ್ಟ್ರೀಯ ಮತ್ತು ಅಂತರಾಷ್ಟ್ರೀಯ ಮಟ್ಟದಲ್ಲಿ ಸರ್ಕಾರವು ಹೇಗೆ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ ಎಂಬುದರ ಕುರಿತು ಅರವಿಂದ್ ಬಹಳಷ್ಟು ಕಲಿಯುತ್ತಾನೆ.",
              "te": "ఈ సబ్జెక్ట్‌ల ద్వారా అరవింద్ స్థానిక, జాతీయ మరియు అంతర్జాతీయ స్థాయిలో ప్రభుత్వం ఎలా పని చేస్తుందో చాలా నేర్చుకుంటారు.",
              "or": "ଏହି ବିଷୟଗୁଡିକ ମାଧ୍ୟମରେ, ଅରବିନ୍ଦ ସ୍ଥାନୀୟ, ଜାତୀୟ ତଥା ଆନ୍ତର୍ଜାତୀୟ ସ୍ତରରେ ସରକାର କିପରି କାର୍ଯ୍ୟ କରନ୍ତି ସେ ବିଷୟରେ ଅନେକ କିଛି ଜାଣନ୍ତି |",
              "as": "এই বিষয়বোৰৰ জৰিয়তে, অৰবিন্দে স্থানীয়, ৰাষ্ট্ৰীয় আৰু আন্তঃৰাষ্ট্ৰীয় পৰ্যায়ত চৰকাৰে কেনেদৰে কাম কৰে সেই বিষয়ে বহুতো শিকে।",
              "gu": "આ વિષયોના માધ્યમથી, અરવિંદ સ્થાનીય, રાષ્ટ્રીય તથા આંતરરાષ્ટ્રીય લેવલ ઉપર સરકારની કામ કરવાની પદ્ધતિ અંગે ઘણું શીખવા મળ્યું છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_2_3",
              "hi": "C2_2_3",
              "ka": "C2_2_3",
              "te": "C2_2_3",
              "or": "C2_2_3",
              "as": "C2_2_3",
              "gu": "C2_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He also understands how different political institutions such as courts and parliaments function.",
              "hi": "वह यह भी समझता है कि विभिन्न राजनीतिक संस्थान जैसे कि अदालतें और संसद कैसे कार्य करते हैं।",
              "ka": "ನ್ಯಾಯಾಲಯಗಳು ಮತ್ತು ಸಂಸತ್ತುಗಳಂತಹ ವಿವಿಧ ರಾಜಕೀಯ ಸಂಸ್ಥೆಗಳು ಹೇಗೆ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತವೆ ಎಂಬುದನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "న్యాయస్థానాలు మరియు పార్లమెంటులు వంటి వివిధ రాజకీయ సంస్థలు ఎలా పనిచేస్తాయో కూడా అతను అర్థం చేసుకున్నాడు.",
              "or": "ସେ ମଧ୍ୟ ବୁଝନ୍ତି କିପରି ବିଭିନ୍ନ ରାଜନୀତିକ ଅନୁଷ୍ଠାନ ଯଥା ଅଦାଲତ ଏବଂ ସଂସଦ କାର୍ଯ୍ୟ କରେ |",
              "as": "তেওঁ এইটোও বুজি পায় যে বিভিন্ন ৰাজনৈতিক প্ৰতিষ্ঠান যেনে আদালত আৰু সংসদে কেনেদৰে কাম কৰে।",
              "gu": "તે એ પણ સમજાવે છે કે વિવિધ રાજકીય સંસ્થા જેવા કે કોર્ટ તથા સંસદ કેવી રીતે કાર્ય કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_2_4",
              "hi": "C2_2_4",
              "ka": "C2_2_4",
              "te": "C2_2_4",
              "or": "C2_2_4",
              "as": "C2_2_4",
              "gu": "C2_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind tries to improve his understanding by watching parliamentary debates on the internet and reading articles about politics.",
              "hi": "अरविंद इंटरनेट पर संसदीय बहसों को देखकर और राजनीति के बारे में लेख पढ़कर अपनी समझ को बेहतर बनाने की कोशिश करता है।",
              "ka": "ಇಂಟರ್‌ನೆಟ್‌ನಲ್ಲಿ ಸಂಸತ್ತಿನ ಚರ್ಚೆಗಳನ್ನು ನೋಡುವ ಮೂಲಕ ಮತ್ತು ರಾಜಕೀಯದ ಬಗ್ಗೆ ಲೇಖನಗಳನ್ನು ಓದುವ ಮೂಲಕ ಅರವಿಂದ್ ತನ್ನ ತಿಳುವಳಿಕೆಯನ್ನು ಸುಧಾರಿಸಲು ಪ್ರಯತ್ನಿಸುತ್ತಾನೆ.",
              "te": "అరవింద్ ఇంటర్‌నెట్‌లో పార్లమెంటరీ డిబేట్‌లను చూడటం మరియు రాజకీయాలకు సంబంధించిన కథనాలను చదవడం ద్వారా తన అవగాహనను మెరుగుపరచుకోవడానికి ప్రయత్నిస్తాడు.",
              "or": "ଇଣ୍ଟରନେଟରେ ସଂସଦୀୟ ବିତର୍କ ଦେଖିବା ଏବଂ ରାଜନୀତି ବିଷୟରେ ପ୍ରବନ୍ଧ ପଢ଼ି ଅରବିନ୍ଦ ତାଙ୍କ ବୁଝାମଣାରେ ଉନ୍ନତି ଆଣିବାକୁ ଚେଷ୍ଟା କରନ୍ତି।",
              "as": "অৰবিন্দে ইণ্টাৰনেটত সংসদীয় বিতৰ্ক চাই আৰু ৰাজনীতিৰ বিষয়ে প্ৰবন্ধ পঢ়ি নিজৰ বুজন শক্তি উন্নত কৰিবলৈ চেষ্টা কৰে।",
              "gu": "અરવિંદ ઈન્ટરનેટ અંગે સંસદીય બાબતોને જોતા અને રાજકીય બાબત અંગેના લેખ વાંચીને પોતાની સમજવા માટે વધારે સારી કરવાનો પ્રયત્ન કરે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_2_5",
              "hi": "C2_2_5",
              "ka": "C2_2_5",
              "te": "C2_2_5",
              "or": "C2_2_5",
              "as": "C2_2_5",
              "gu": "C2_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Since he studies from home, Arvind feels safe and can concentrate on studying.",
              "hi": "चूंकि वह घर से अध्ययन करता है, अरविंद सुरक्षित अनुभव करता है और अध्ययन पर ध्यान केंद्रित कर सकता है।",
              "ka": "ಅವರು ಮನೆಯಿಂದಲೇ ಓದುವುದರಿಂದ, ಅರವಿಂದ್ ಸುರಕ್ಷಿತವಾಗಿರುತ್ತಾನೆ ಮತ್ತು ಅಧ್ಯಯನದ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಬಹುದು.",
              "te": "అతను ఇంటి నుండి చదువుతున్నందున, అరవింద్ సురక్షితంగా ఉన్నాడు మరియు చదువుపై దృష్టి పెట్టగలడు.",
              "or": "ଯେହେତୁ ସେ ଘରୁ ଅଧ୍ୟୟନ କରନ୍ତି, ଅରବିନ୍ଦ ନିଜକୁ ନିରାପଦ ମନେ କରନ୍ତି ଏବଂ ଅଧ୍ୟୟନରେ ଧ୍ୟାନ ଦେଇପାରନ୍ତି |",
              "as": "যিহেতু তেওঁ ঘৰৰ পৰা পঢ়ে, অৰবিন্দে সুৰক্ষিত অনুভৱ কৰে আৰু অধ্যয়নত মনোনিবেশ কৰিব পাৰে।",
              "gu": "ઘરેથી અભ્યાસ કરવા સાથે અરવિંદ સુરક્ષિતપણાનો અહેસાસ કરે છે અને અભ્યાસ ઉપર ધ્યાન કેન્દ્રીત કરી શકે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_3_2",
              "hi": "C2_3_2",
              "ka": "C2_3_2",
              "te": "C2_3_2",
              "or": "C2_3_2",
              "as": "C2_3_2",
              "gu": "C2_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As days go by, Roshni returns to her university hostel, and his parents get busy with their work. Arvind begins to feel a little isolated.",
              "hi": "जैसे-जैसे दिन बीतते हैं, रोशनी अपने विश्वविद्यालय के छात्रावास में लौट आती है, और उसके माता-पिता अपने काम में व्यस्त हो जाते हैं। अरविंद कुछ अलग-थलग महसूस करने लगता है।",
              "ka": "ದಿನಗಳು ಕಳೆದಂತೆ, ರೋಶ್ನಿ ತನ್ನ ವಿಶ್ವವಿದ್ಯಾಲಯದ ಹಾಸ್ಟೆಲ್‌ಗೆ ಹಿಂತಿರುಗುತ್ತಾಳೆ ಮತ್ತು ಅವನ ಪೋಷಕರು ತಮ್ಮ ಕೆಲಸದಲ್ಲಿ ನಿರತರಾಗುತ್ತಾರೆ. ಅರವಿಂದನಿಗೆ ಸ್ವಲ್ಪ ಒಂಟಿತನ ಕಾಡತೊಡಗಿತು.",
              "te": "కొన్ని రోజుల తర్వాత , రోష్ని తన యూనివర్శిటీ హాస్టల్‌కి తిరిగి వెళ్తుంది మరియు అతని తల్లిదండ్రులు తమ పనిలో బిజీ అయిపోతారు. అరవింద్‌కి కొంచెం ఒంటరిగా అనిపించడం మొదలవుతుంది.",
              "or": "ଦିନ ଗଡିବା ସହିତ, ରୋଶନି ତାଙ୍କ ବିଶ୍ୱବିଦ୍ୟାଳୟ ହଷ୍ଟେଲକୁ ଫେରି ଆସନ୍ତି ଏବଂ ତାଙ୍କ ପିତାମାତା ସେମାନଙ୍କ କାର୍ଯ୍ୟରେ ବ୍ୟସ୍ତ ହୁଅନ୍ତି | ଅରବିନ୍ଦ ଟିକେ ବିଚ୍ଛିନ୍ନ ଅନୁଭବ କରିବାକୁ ଲାଗନ୍ତି |",
              "as": "দিন যোৱাৰ লগে লগে ৰোশনি তাইৰ বিশ্ববিদ্যালয়ৰ হোষ্টেললৈ উভতি আহে, আৰু তেওঁৰ মাক-দেউতাক তেওঁলোকৰ কামত ব্যস্ত হৈ পৰে। অৰবিন্দে অলপ অকলশৰীয়া অনুভৱ কৰিবলৈ আৰম্ভ কৰে।",
              "gu": "જેમ જેમ દિવસો પસાર થતા જાય છે તેમ રોશની તેની યુનિવર્સિટીની હોસ્ટેલમાં પરત ફરે છે, અને તેના માતા-પિતા પોતાના કામમાં વ્યસ્ત થઈ જાય છે. અરવિંદ થોડા અલગ-થલગ થવાનો અહેસાસ કરવા લાગે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_3_3",
              "hi": "C2_3_3",
              "ka": "C2_3_3",
              "te": "C2_3_3",
              "or": "C2_3_3",
              "as": "C2_3_3",
              "gu": "C2_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Keeping up with the studies also becomes difficult. The subjects Arvind is studying require a lot of discussion and debate, and he doesn’t get many opportunities to do that except for class hours.",
              "hi": "पढ़ाई में मन लगाना भी मुश्किल हो जाता है। अरविंद जिन विषयों का अध्ययन कर रहा है, उसके लिए बहुत अधिक चर्चा और बहस की आवश्यकता होती है, और उसे कक्षा के घंटों को छोड़कर ऐसा करने के अधिक अवसर नहीं मिलते हैं।",
              "ka": "ಅಧ್ಯಯನವನ್ನು ಮುಂದುವರಿಸುವುದು ಸಹ ಕಷ್ಟವಾಗುತ್ತದೆ. ಅರವಿಂದ್ ಅಧ್ಯಯನ ಮಾಡುತ್ತಿರುವ ವಿಷಯಗಳಿಗೆ ಸಾಕಷ್ಟು ಚರ್ಚೆ ಮತ್ತು ಚರ್ಚೆಯ ಅಗತ್ಯವಿರುತ್ತದೆ ಮತ್ತು ತರಗತಿಯ ಸಮಯವನ್ನು ಹೊರತುಪಡಿಸಿ ಅದನ್ನು ಮಾಡಲು ಅವರಿಗೆ ಹೆಚ್ಚಿನ ಅವಕಾಶಗಳು ಸಿಗುವುದಿಲ್ಲ",
              "te": "చదువును కొనసాగించడం కూడా కష్టంగా మారుతుంది. అరవింద్ చదువుతున్న సబ్జెక్ట్‌లకు చాలా డిస్కషన్స్ మరియు డిబేట్స్ అవసరమవుతాయి కానీ క్లాస్ అవర్స్‌లో తప్ప అతనికి ఎక్కువ అవకాశాలు లభించవు.",
              "or": "ଅଧ୍ୟୟନ ସହିତ ଚାଲିବା ମଧ୍ୟ କଷ୍ଟସାଧ୍ୟ ହୋଇଯାଏ | ଅରବିନ୍ଦ ଅଧ୍ୟୟନ କରୁଥିବା ବିଷୟଗୁଡିକ ବହୁ ଆଲୋଚନା ଏବଂ ବିତର୍କ ଆବଶ୍ୟକ କରେ, ଏବଂ ସେ ଶ୍ରେଣୀ ଘଣ୍ଟା ବ୍ୟତୀତ ତାହା କରିବାକୁ ଅନେକ ସୁଯୋଗ ପାଆନ୍ତି ନାହିଁ |",
              "as": "অধ্যয়নৰ সৈতে খাপ খোৱাটোও কঠিন হৈ পৰে। অৰবিন্দে অধ্যয়ন কৰি থকা বিষয়বোৰৰ বাবে যথেষ্ট আলোচনা আৰু বিতৰ্কৰ প্ৰয়োজন, আৰু শ্ৰেণীৰ সময়ৰ বাহিৰে তেওঁ সেইটো কৰিবলৈ বেছি সুযোগ নাপায়।",
              "gu": "અભ્યાસમાં મન લગાવવામાં મુશ્કેલી પડે છે. અરવિંદ જે વિષયો અંગે અભ્યાસ કરી રહ્યો છે તેના માટે વ્યાપક પ્રમાણમાં ચર્ચા કરવાની જરૂર રહે છે, અને તેને વર્ગખંડના કલાકોનો સમય છોડીને આમ કરવા માટે વિશેષ તક મળતી નથી."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_4_1",
              "hi": "C2_4_1",
              "ka": "C2_4_1",
              "te": "C2_4_1",
              "or": "C2_4_1",
              "as": "C2_4_1",
              "gu": "C2_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He travels to Hyderabad every six months for his on-campus exams. Those trips help him interact with Pranav a little more as he stays with him when he visits Hyderabad.",
              "hi": "वह अपनी ऑन-कैंपस परीक्षा के लिए हर छह महीने में हैदराबाद जाता है। उन यात्राओं से उसे प्रणव के साथ थोड़ी और बातचीत करने में सहायता मिलती है क्योंकि जब वह हैदराबाद आता है तो वह उसके साथ रहता है।",
              "ka": "ಅವರು ತಮ್ಮ ಕ್ಯಾಂಪಸ್ ಪರೀಕ್ಷೆಗಳಿಗಾಗಿ ಪ್ರತಿ ಆರು ತಿಂಗಳಿಗೊಮ್ಮೆ ಹೈದರಾಬಾದ್‌ಗೆ ಪ್ರಯಾಣಿಸುತ್ತಾರೆ. ಆ ಪ್ರವಾಸಗಳು ಪ್ರಣವ್ ಹೈದರಾಬಾದ್‌ಗೆ ಭೇಟಿ ನೀಡಿದಾಗ ಅವರೊಂದಿಗೆ ಸ್ವಲ್ಪ ಹೆಚ್ಚು ಸಂವಹನ ನಡೆಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "అతను తన క్యాంపస్ లో జరిగే పరీక్షల కోసం ప్రతి ఆరు నెలలకోసారి హైదరాబాద్ వెళ్తాడు. అతను హైదరాబాదుకు వెళ్లినప్పుడు ప్రణవ్ తో కలిసి ఉండడం వల్ల అతనితో కొంచెం ఎక్కువ మాట్లాడడానికి ఈ ట్రిప్స్ సహాయపడతాయి.",
              "or": "ସେ ଅନ୍‌-କ୍ୟାମ୍ପସ୍‌ରେ ପରୀକ୍ଷା ପାଇଁ ପ୍ରତି ଛଅ ମାସରେ ହାଇଦ୍ରାବାଦକୁ ଯାତ୍ରା କରନ୍ତି | ସେହି ଯାତ୍ରାଗୁଡି଼କ ତାଙ୍କୁ ପ୍ରଣବଙ୍କ ସହିତ ଟିକିଏ ଅଧିକ ପାରସ୍ପରିକ କଥାବାର୍ତା କରିବାରେ ସାହାଯ୍ୟ କରେ ଯେତେବେଳେ ସେ ହାଇଦ୍ରାବାଦ ପରିଦର୍ଶନ କରନ୍ତି |",
              "as": "তেওঁ তেওঁৰ কেম্পাচ পৰীক্ষাৰ বাবে প্ৰতি ছয় মাহত হায়দৰাবাদলৈ যায়। সেই ভ্ৰমণবোৰে তেওঁক প্ৰণৱৰ সৈতে অলপ বেছি কথাপতাত সহায় কৰে কিয়নো তেওঁ হায়দৰাবাদলৈ যোৱাৰ সময়ত তেওঁৰ সৈতে থাকে।",
              "gu": "તે પોતાના ઓન-કેમ્પસ પરીક્ષા માટે પ્રત્યેક છ મહિનામાં હૈદરાબાદ જાય છે. આ યાત્રાઓથી તેમને પ્રણવ સાથે થોડી વધારે વાતચીત કરવામાં મદદ મળે છે કારણ કે જ્યારે તે હૈદરાબાદ આવે ચે તો તેમની સાથે રહે છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_4_2",
              "hi": "C2_4_2",
              "ka": "C2_4_2",
              "te": "C2_4_2",
              "or": "C2_4_2",
              "as": "C2_4_2",
              "gu": "C2_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Pranav reassures him to focus on his studies and not worry about money.",
              "hi": "प्रणव ने उसे अपने अध्ययन पर ध्यान केंद्रित करने और पैसे के बारे में चिंता न करने के लिए आश्वस्त किया।",
              "ka": "ಪ್ರಣವ್ ತನ್ನ ಓದಿನತ್ತ ಗಮನಹರಿಸಿ ಮತ್ತು ಹಣದ ಬಗ್ಗೆ ಚಿಂತಿಸಬೇಡಿ ಎಂದು ಅವನಿಗೆ ಧೈರ್ಯ ತುಂಬುತ್ತಾನೆ",
              "te": "ప్రణవ్ అతనికి డబ్బు గురించి చింతించకుండా చదువుపైనే దృష్టి పెడతానని భరోసా ఇచ్చాడు.",
              "or": "ପ୍ରଣବ ତାଙ୍କୁ ଅଧ୍ୟୟନ ଉପରେ ଧ୍ୟାନ ଦେବାକୁ ଏବଂ ଟଙ୍କା ବିଷୟରେ ଚିନ୍ତା ନକରିବାକୁ ଆଶ୍ୱାସନା ଦିଅନ୍ତି |",
              "as": "প্ৰণৱে তেওঁক পঢ়া-শুনাত মনোযোগ দিবলৈ আৰু টকাৰ বিষয়ে চিন্তা নকৰিবলৈ আশ্বস্ত কৰে।",
              "gu": "પ્રણવ તેને પોતાના અભ્યાસ પર ધ્યાન કેન્દ્રિત કરવા અને પૈસાની ચિંતા ન કરવા ખાતરી આપે છે."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_4_3//",
              "hi": "C2_4_3 //",
              "ka": "C2_4_3//",
              "te": "C2_4_3//",
              "or": "C2_4_3//",
              "as": "C2_4_3//",
              "gu": "C2_4_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Meeting Arvind also helps Pranav understand what Arvind feels about these causes.",
              "hi": "अरविंद से मिलना प्रणव को यह समझने में भी सहायता करता है कि अरविंद इन कारणों के बारे में क्या अनुभव करता है।",
              "ka": "ಅರವಿಂದ್ ಅವರನ್ನು ಭೇಟಿ ಮಾಡುವುದರಿಂದ ಈ ಕಾರಣಗಳ ಬಗ್ಗೆ ಅರವಿಂದ್ ಏನು ಭಾವಿಸುತ್ತಾರೆ ಎಂಬುದನ್ನು ಪ್ರಣವ್ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "అరవింద్‌ని కలవడం వల్ల ఈ కారణాల గురించి అరవింద్ ఏమనుకుంటున్నాడో కూడా ప్రణవ్‌కు అర్థమవుతుంది.",
              "or": "ଅରବିନ୍ଦଙ୍କୁ ଭେଟିବା ମଧ୍ୟ ପ୍ରଣବଙ୍କୁ ବୁଝିବାରେ ସାହାଯ୍ୟ କରେ ଯେ ଏହି କାରଣଗୁଡ଼ିକ ଉପରେ ଅରବିନ୍ଦ କ’ଣ ଅନୁଭବ କରନ୍ତି |",
              "as": "অৰবিন্দক লগ পোৱাটোৱে প্ৰণৱক এই কাৰণবোৰৰ বিষয়ে অৰবিন্দে কি ভাবে সেইটো বুজাত সহায় কৰে।",
              "gu": "અરવિંદને મળવું પ્રણવને એ સમજાવવામાં પણ મદદ કરે છે કે અરવિંદ આ કારણો અંગે શું- અહેસાસ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Pranav",
              "hi": "प्रणव",
              "ka": "ಅರವಿಂದ್",
              "te": "ప్రణవ్",
              "or": "ପ୍ରଣବ",
              "as": "প্ৰণৱ",
              "gu": "પ્રણવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "There are so many communities whose voices are not heard. And it’s not just the fact that society rejects them, but even our policies don’t include them.",
              "hi": "ऐसे कई समुदाय हैं जिनकी आवाज़ें नहीं सुनी जाती हैं। और यह सिर्फ इतना तथ्य नहीं है कि समाज उन्हें खारिज कर देता है, लेकिन यहां तक ​​कि हमारी नीतियों में भी उन्हें शामिल नहीं किया गया है।",
              "ka": "ದನಿ ಕೇಳದ ಎಷ್ಟೋ ಸಮುದಾಯಗಳಿವೆ. ಮತ್ತು ಸಮಾಜವು ಅವರನ್ನು ತಿರಸ್ಕರಿಸುತ್ತದೆ ಎಂಬುದು ಕೇವಲ ಸತ್ಯವಲ್ಲ, ಆದರೆ ನಮ್ಮ ನೀತಿಗಳು ಸಹ ಅವರನ್ನು ಒಳಗೊಂಡಿಲ್ಲ.",
              "te": "మరుగున పడిన సంఘాలు ఎన్నో ఉన్నాయి. మరియు సమాజం వారిని తిరస్కరించడమే కాకుండా, మన పాలసీలలో కూడా వాటి గురించి ఉండవు.",
              "or": "ଏପରି ଅନେକ ସମୁଦାୟଗୁଡି଼କ ଅଛି ଯାହାର ସ୍ୱର ଶୁଣାଯାଏ ନାହିଁ | ଏବଂ ଏହା କେବଳ ସତ୍ୟ ନୁହେଁ ଯେ ସମାଜ ସେମାନଙ୍କୁ ପ୍ରତ୍ୟାଖ୍ୟାନ କରେ, ବରଂ ଆମର ନୀତିଗୁଡି଼କ ମଧ୍ୟ ସେଗୁଡିକୁ ଅନ୍ତର୍ଭୂକ୍ତ କରେ ନାହିଁ |",
              "as": "বহুতো সম্প্ৰদায় আছে যাৰ মাত শুনা নাযায়। আৰু ইয়াৰ অৰ্থ এইটো নহয় যে সমাজে তেওঁলোকক প্ৰত্যাখ্যান কৰে, আনকি আমাৰ নীতিবোৰতো সেইবোৰ অন্তৰ্ভুক্ত নহয়।",
              "gu": "આવા તો ઘણા સમુદાય છે કે જેમનો અવાજ સાંભળવા મળતો નથી. અને તે ફક્ત એક હકીકત નથી કે સમાજ તેને અસ્વીકાર કરે છે, પણ આપણી નીતિઓ પણ તેને સામેલ કરતી નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "But there are so many bills being passed to include these communities. Even the fact that it is not illegal to be gay in India anymore is such a big step that has been taken.",
              "hi": "लेकिन इन समुदायों को शामिल करने के लिए कई विधेयक पारित किए जा रहे हैं। यहां तक कि यह तथ्य भी कि भारत में अब समलैंगिक होना अवैध नहीं है, इतना बड़ा कदम उठाया गया है।",
              "ka": "ಆದರೆ ಈ ಸಮುದಾಯಗಳನ್ನು ಸೇರಿಸಲು ಹಲವು ಮಸೂದೆಗಳನ್ನು ಅಂಗೀಕರಿಸಲಾಗುತ್ತಿದೆ. ಇನ್ನು ಮುಂದೆ ಭಾರತದಲ್ಲಿ ಸಲಿಂಗಕಾಮಿಯಾಗುವುದು ಕಾನೂನುಬಾಹಿರವಲ್ಲ ಎಂಬ ಅಂಶವು ತೆಗೆದುಕೊಂಡಿರುವ ದೊಡ್ಡ ಹೆಜ್ಜೆಯಾಗಿದೆ.",
              "te": "కానీ ఈ వర్గాలను చేర్చేందుకు చాలా బిల్లులు ఆమోదం పొందుతున్నాయి. ఇకపై భారతదేశంలో గే లుగా ఉండటం చట్టవిరుద్ధం కాదనే వాస్తవం పెద్ద మార్పు అని చెప్పవచ్చు.",
              "or": "କିନ୍ତୁ ଏହି ସମୁଦାୟଗୁଡ଼ିକୁ ଅନ୍ତର୍ଭୁକ୍ତ କରିବା ପାଇଁ ବହୁତ ବିଲ୍ ପାସ୍ ହୋଇଛି | ଏପରିକି ଭାରତରେ ସମଲିଙ୍ଗୀ ହେବା ବେଆଇନ ନୁହେଁ ବୋଲି ଏକ ବଡ଼ ପଦକ୍ଷେପ ଯାହା ନିଆଯାଇଛି।",
              "as": "কিন্তু এই সম্প্ৰদায়বোৰক অন্তৰ্ভুক্ত কৰিবলৈ বহুতো বিধেয়ক গৃহীত কৰা হৈছে। আনকি ভাৰতত সমকামী হোৱাটো আৰু অবৈধ যে নহয় এনেধৰণৰ ইমান ডাঙৰ পদক্ষেপো লোৱা হৈছে।",
              "gu": "પણ આ સમુદાયને સામેલ કરવા માટે અનેક વિધેયક પસાર કરવામાં આવી ચુક્યા છે. ત્યા સુધી કે આ હકીકત છે કે ભારતમાં સમલૈંગિક હોવું ગેરકાયદેસર નથી, આ સંજોગોમાં આટલું મોટું પગલું ભરવામાં આવ્યું છે."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_4_4////",
              "hi": "C2_4_4 ////",
              "ka": "C2_4_4////",
              "te": "C2_4_4////",
              "or": "C2_4_4////",
              "as": "C2_4_4////",
              "gu": "C2_4_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "It’s not that easy, Pranav. The bills passed are necessary, but many a times the laws also reflect the broader social biases that are held. Without the support of rest of the society, the LGBTQ+ community can do very little.",
              "hi": "यह इतना सरल नहीं है प्रणव। पास किए गए विधेयक आवश्यक हैं, लेकिन कई बार कानून व्यापक सामाजिक पूर्वाग्रहों को भी दिखाते हैं। शेष समाज के समर्थन के बिना एलजीबीटीक्यू+ समुदाय बहुत कम कर सकता है।",
              "ka": "ಅದು ಅಷ್ಟು ಸುಲಭವಲ್ಲ ಪ್ರಣವ್. ಅಂಗೀಕರಿಸಿದ ಮಸೂದೆಗಳು ಅವಶ್ಯಕ, ಆದರೆ ಅನೇಕ ಬಾರಿ ಕಾನೂನುಗಳು ವಿಶಾಲವಾದ ಸಾಮಾಜಿಕ ಪಕ್ಷಪಾತಗಳನ್ನು ಪ್ರತಿಬಿಂಬಿಸುತ್ತವೆ. ಸಮಾಜದ ಉಳಿದವರ ಬೆಂಬಲವಿಲ್ಲದೆ, LGBTQ+ ಸಮುದಾಯವು ತುಂಬಾ ಕಡಿಮೆ ಮಾಡಬಹುದು.",
              "te": "ఇది అంత ఈజీ కాదు ప్రణవ్. ఆమోదించబడిన బిల్లులు అవసరం, కానీ చాలా సార్లు చట్టాలు విస్తృత సామాజిక పక్షపాతాలు కలిగి ఉంటాయి. సమాజంలోని మిగిలిన వారి మద్దతు లేకుండా, LGBTQ+ సంఘం ఎక్కువ ఏం చేయలేరు.",
              "or": "ଏହା ସେତେ ସହଜ ନୁହେଁ, ପ୍ରଣବ | ପାସ୍ ହୋଇଥିବା ବିଲ୍ ଗୁଡିକ ଆବଶ୍ୟକ, କିନ୍ତୁ ଅନେକ ସମୟରେ ଆଇନଗୁଡ଼ିକ ମଧ୍ୟ ବ୍ୟାପକ ସାମାଜିକ ପକ୍ଷପାତ, ଯାହା ଅଛି, ପ୍ରତିଫଳିତ କରିଥାଏ | ବାକି ସମାଜର ସମର୍ଥନ ବିନା, LGBTQ + ସମୁଦାୟ ବହୁତ କିଛି କରିପାରିବ ନାହିଁ |",
              "as": "এয়া ইমান সহজ নহয়, প্ৰণৱ। গৃহীত বিধেয়কবোৰ প্ৰয়োজনীয়, কিন্তু বহুসময়ত আইনবোৰে বহল সামাজিক পক্ষপাতিত্ববোৰো প্ৰতিফলিত কৰে। সমাজৰ বাকী অংশৰ সমৰ্থন অবিহনে, LGBTQ+ সম্প্ৰদায়ে খুব কম কাম কৰিব পাৰে।",
              "gu": "આ એટલું સરળ નથી કે પ્રણવ. પસાર કરવામાં આવેલ વિધેયક આવશ્યક છે, પણ અનેક કાયદા વ્યાપક સામાજીક પૂર્વાગ્રહને દર્શાવે છે. બાકી સમાજના સમર્થન વગર LGBTO+ સમુદાય ઘણું ઓછું કરી શકે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "So how can we help?",
              "hi": "तो हम कैसे सहायता कर सकते हैं?",
              "ka": "ಹಾಗಾದರೆ ನಾವು ಹೇಗೆ ಸಹಾಯ ಮಾಡಬಹುದು?",
              "te": "అయితే మనం ఎలా సహాయం చేయవచ్చు?",
              "or": "ତେବେ ଆମେ କିପରି ସାହାଯ୍ୟ କରିପାରିବା ?",
              "as": "তেন্তে আমি কেনেকৈ সহায় কৰিব পাৰোঁ?",
              "gu": "તો આપણે કેવી રીતે મદદ કરી શકાય છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "At a fundamental level, accept and respect all people and know that gender is not binary, but it is a spectrum.",
              "hi": "मौलिक स्तर पर, सभी लोगों को स्वीकार करें और उनका सम्मान करें और जानें कि लिंग बाइनरी नहीं है, बल्कि यह एक स्पेक्ट्रम है।",
              "ka": "ಮೂಲಭೂತ ಮಟ್ಟದಲ್ಲಿ, ಎಲ್ಲಾ ಜನರನ್ನು ಸ್ವೀಕರಿಸಿ ಮತ್ತು ಗೌರವಿಸಿ ಮತ್ತು ಲಿಂಗವು ಬೈನರಿ ಅಲ್ಲ, ಆದರೆ ಇದು ಒಂದು ಸ್ಪೆಕ್ಟ್ರಮ್ ಎಂದು ತಿಳಿಯಿರಿ.",
              "te": "ప్రాథమిక స్థాయిలో, ప్రజలందరినీ అంగీకరించాలి మరియు గౌరవించాలి మరియు లింగం బైనరీ కాదు, కానీ అది స్పెక్ట్రమ్ అని తెలుసుకోవాలి.",
              "or": "ଏକ ମୌଳିକ ସ୍ତରରେ, ସମସ୍ତ ଲୋକଙ୍କୁ ଗ୍ରହଣ ଏବଂ ସମ୍ମାନ ଦିଅ ଏବଂ ଜାଣ ଯେ ଲିଙ୍ଗ ଦ୍ୱିପାକ୍ଷିକ ନୁହେଁ, ବରଂ ଏହା ଏକ ସ୍ପେକ୍ଟ୍ରମ୍‍ |",
              "as": "মৌলিক স্তৰত, সকলো লোকক গ্ৰহণ আৰু সন্মান কৰক আৰু জানক যে লিংগ বাইনাৰী নহয়, কিন্তু ই এক বৰ্ণালী।",
              "gu": "મૌલિક લેવલ ઉપર, તમામ લોકોને સ્વીકાર કરો તથા તેમના સન્માન કરો તથા જવા માટે લિંગ દ્વિઆધારી નથી,પણ તે એક સ્પેક્ટ્રમ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Hmm... I understand...\nThat reminds me, are you going to the support meeting this week? I would be happy to drop you.",
              "hi": "हम्म ... मैं समझता हूँ ...\nयह मुझे याद दिलाता है, क्या तुम इस सप्ताह समर्थन बैठक में जा रहे हो? मुझे तुमको छोड़ने के लिए जाने में खुशी होगी।",
              "ka": "\"ಹೂಂ... ಅರ್ಥವಾಯಿತು...\n ಅದು ನನಗೆ ನೆನಪಿಸುತ್ತದೆ, ನೀನು ಈ ವಾರ ಬೆಂಬಲ ಸಭೆಗೆ ಹೋಗುತ್ತೀಯ? ನಾನು ನಿನ್ನನ್ನು ಬಿಡಲು ಸಂತೋಷಪಡುತ್ತೇನೆ.\"",
              "te": "\"హ్మ్.. అర్థమైంది..\n నాకు ఒకటి గుర్తుకు వచ్చింది, నువ్వు ఈ వారం మద్దతు సమావేశానికి వెళ్తున్నావా? కావాలంటే నేను నిన్ను డ్రాప్ చేస్తాను.\"",
              "or": "ହମ୍ ... ମୁଁ ବୁଝିପାରୁଛି ...\n ତାହା ମୋତେ ମନେ ପକାଇଦିଏ, ତୁମେ ଏହି ସପ୍ତାହରେ ସମର୍ଥନ ସଭାକୁ ଯାଉଛନ୍ତି କି? ମୁଁ ତୁମକୁ ଛାଡି ଖୁସି ହେବି |",
              "as": "হুম... মই বুজি পাইছোঁ।।।\n এইটোৱে মোক মনত পেলাই দিছে, আপুনি এই সপ্তাহত সমৰ্থন সভালৈ যাব নেকি? মই আপোনাক তাত থৈ আহিব পাৰিলে সুখী হ'ম।",
              "gu": "આ સપ્તાહે સહાયતા માટે તમે જઈ રહ્યા છો તે અંગે યાદ આપે છે? હું તમને છોડતા ઘણો ખુશી છું."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_4_5",
              "hi": "C2_4_5",
              "ka": "C2_4_5",
              "te": "C2_4_5",
              "or": "C2_4_5",
              "as": "C2_4_5",
              "gu": "C2_4_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Pranav drops him at the support group and picks him up once the session is over to ensure that Arvind can travel safely in public.",
              "hi": "प्रणव उसे सहायता समूह में छोड़ देता है और सत्र समाप्त होने के बाद उसे लेने जाता है ताकि अरविंद का सार्वजनिक रूप से सुरक्षित यात्रा करना सुनिश्चित हो सके।",
              "ka": "ಅರವಿಂದ್ ಸಾರ್ವಜನಿಕವಾಗಿ ಸುರಕ್ಷಿತವಾಗಿ ಪ್ರಯಾಣಿಸಬಹುದೆಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಪ್ರಣವ್ ಅವನ್ನನ್ನು ಬೆಂಬಲ ಗುಂಪಿನಲ್ಲಿ ಇಳಿಸುತ್ತಾನೆ ಮತ್ತು ಅಧಿವೇಶನ ಮುಗಿದ ನಂತರ ಅವನನ್ನು ಕರೆದುಕೊಂಡು ಹೋಗುತ್ತಾನೆ.",
              "te": "అరవింద్ పబ్లిక్‌గా సురక్షితంగా ప్రయాణించగలడని నిర్ధారించుకోవడానికి ప్రణవ్ అతనిని సపోర్ట్ గ్రూప్ వద్ద డ్రాప్ చేసి, సెషన్ ముగిసిన తర్వాత అతన్ని పికప్ చేసుకుంటాడు.",
              "or": "ପ୍ରଣବ ତାଙ୍କୁ ସମର୍ଥନ ସମୁଦାୟରେ ଛାଡି ଦିଅନ୍ତି ଏବଂ ଅଧିବେଶନ ଶେଷ ହେବା ପରେ ତାଙ୍କୁ ନିଅନ୍ତି ନିଶ୍ଚିତ କରିବାକୁ ଯେ ଅରବିନ୍ଦ ସର୍ବସାଧାରଣରେ ନିରାପଦରେ ଯାତ୍ରା କରିପାରିବେ ବୋଲି |",
              "as": "প্ৰণৱে তেওঁক সমৰ্থন গোটত থৈ আহিছিল আৰু অধিৱেশন শেষ হোৱাৰ পিছত তেওঁক তুলি লৈছিল যাতে অৰবিন্দে ৰাজহুৱাস্থানত সুৰক্ষিতভাৱে যাব পাৰে।",
              "gu": "પ્રણવ તેને સહાયતા સમૂહમાં છોડી આપે છે અને સત્ર પૂરું થયા બાદ તેમણે ઉઠાવ્યા, જેથી આ સુનિશ્ચિત થઈ શકે છે કે અરવિંદ જાહેર રીતે સુરક્ષિત યાત્રા કરી શકે."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "C2_4_6",
              "hi": "C2_4_6",
              "ka": "C2_4_6",
              "te": "C2_4_6",
              "or": "C2_4_6",
              "as": "C2_4_6",
              "gu": "C2_4_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Pranav ensures that Arvind attends the support group meetings during his time in Hyderabad.",
              "hi": "प्रणव सुनिश्चित करता है कि अरविंद हैदराबाद में अपने समय के दौरान सहायता समूह की बैठकों में भाग ले।",
              "ka": "ಅರವಿಂದ್ ಅವರು ಹೈದರಾಬಾದ್‌ನಲ್ಲಿರುವ ಸಮಯದಲ್ಲಿ ಬೆಂಬಲ ಗುಂಪಿನ ಸಭೆಗಳಿಗೆ ಹಾಜರಾಗುವುದನ್ನು ಪ್ರಣವ್ ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "అరవింద్ హైదరాబాద్‌లో ఉన్న సమయంలో సపోర్ట్ గ్రూప్ మీటింగ్‌లకు హాజరయ్యేలా ప్రణవ్ చూసుకుంటాడు.",
              "or": "ପ୍ରଣବ ନିଶ୍ଚିତ କରନ୍ତି ଯେ ହାଇଦ୍ରାବାଦରେ ଥିବା ସମୟରେ ଅରବିନ୍ଦ ସମର୍ଥନ ସମୁଦାୟ ବୈଠକଗୁଡି଼କରେ ଯୋଗ ଦିଅନ୍ତି |",
              "as": "প্ৰণৱে নিশ্চিত কৰে যে অৰবিন্দে হায়দৰাবাদত থকা সময়ছোৱাত সমৰ্থন গোটৰ সভাত উপস্থিত থাকিব।",
              "gu": "પ્રણવ સુનિશ્ચિત કરે છે કે અરવિંદ હૈદરાબાદમાં પોતાના સમયમાં સહાયતા સમૂહની બેઠકોમાં ભાગ લીધો."
            }
          }
        ]
      },
      "scene13": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_1",
              "hi": "C3_1_1",
              "ka": "C3_1_1",
              "te": "C3_1_1",
              "or": "C3_1_1",
              "as": "C3_1_1",
              "gu": "C3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "It's the last semester of the course. Studying alone for two and a half years hasn't been that easy for Arvind. His visits to Hyderabad are his only outing.",
              "hi": "यह कोर्स का आखिरी सेमेस्टर है। ढाई साल तक अकेले पढ़ाई करना अरविंद के लिए इतना सहज नहीं रहा। उसकी हैदराबाद यात्रा ही उसकी एकमात्र यात्रा है।",
              "ka": "ಇದು ಕೋರ್ಸ್‌ನ ಕೊನೆಯ ಸೆಮಿಸ್ಟರ್. ಎರಡೂವರೆ ವರ್ಷ ಒಬ್ಬಂಟಿಯಾಗಿ ಓದುವುದು ಅರವಿಂದನಿಗೆ ಅಷ್ಟು ಸುಲಭವಾಗಿರಲಿಲ್ಲ. ಹೈದರಾಬಾದ್‌ಗೆ ಅವರ ಭೇಟಿಗಳು ಅವರ ಏಕೈಕ ಪ್ರವಾಸವಾಗಿದೆ.",
              "te": "ఇది కోర్సు యొక్క చివరి సెమిస్టర్. రెండున్నరేళ్లు ఒంటరిగా చదువుకోవడం అరవింద్‌కి అంత ఈజీగా లేదు. అతను హైదరాబాద్ పర్యటనకు మాత్రమే బయటకు వచ్చేవాడు.",
              "or": "ଏହା କୋର୍ସ୍‌ର ଶେଷ ସେମିଷ୍ଟାର | ଆଢେ଼ଇ ବର୍ଷ ଧରି ଏକୁଟିଆ ଅଧ୍ୟୟନ କରିବା ଅରବିନ୍ଦଙ୍କ ପାଇଁ ସେତେ ସହଜ ନଥିଲା। ତାଙ୍କର ହାଇଦ୍ରାବାଦ ଗସ୍ତ ହି ତାଙ୍କର ଏକମାତ୍ର ବାହାରକୁ ଯିବା ହୁଏ |",
              "as": "এইটো পাঠ্যক্ৰমৰ শেষ ছেমিষ্টাৰ। আঢ়ৈ বছৰ ধৰি অকলে অধ্যয়ন কৰাটো অৰবিন্দৰ বাবে ইমান সহজ নাছিল। হায়দৰাবাদলৈ যোৱাটোৱে তেওঁৰ একমাত্ৰ ভ্ৰমণ।",
              "gu": "આ કોર્સનું છેલ્લું સેમેસ્ટર છે. અરવિંદ માટે અઢી વર્ષ સુધી એકલા ભણવું એટલું સરળ નહોતું. હૈદરાબાદની તેમની મુલાકાતો તેમની એકમાત્ર સહેલગાહ છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_2",
              "hi": "C3_1_2",
              "ka": "C3_1_2",
              "te": "C3_1_2",
              "or": "C3_1_2",
              "as": "C3_1_2",
              "gu": "C3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He also goes through phases of feeling isolated and alone, but he is now excited about his last semester.",
              "hi": "वह अलग-अलग और अकेलेपन के अनुभव से भी गुजरता है, लेकिन अब वह अपने अंतिम सेमेस्टर को लेकर उत्साहित है।",
              "ka": "ಅವನು ಏಕಾಂಗಿಯಾಗಿ ಮತ್ತು ಏಕಾಂಗಿಯಾಗಿ ಅನುಭವಿಸುವ ಹಂತಗಳ ಮೂಲಕ ಹೋಗುತ್ತಾನೆ, ಆದರೆ ಅವನು ಈಗ ತನ್ನ ಕೊನೆಯ ಸೆಮಿಸ್ಟರ್‌ನ ಬಗ್ಗೆ ಉತ್ಸುಕನಾಗಿದ್ದಾನೆ.",
              "te": "అతను చాలాసార్లు ఒంటరిగా ఫీల్ అయ్యాడు. కానీ అతను ఇప్పుడు తన చివరి సెమిస్టర్ గురించి ఆతృతగా ఉన్నాడు.",
              "or": "ସେ ମଧ୍ୟ ବିଚ୍ଛିନ୍ନ ଏବଂ ଏକାକୀ ଅନୁଭବ କରିବାର ପର୍ଯ୍ୟାୟ ଦେଇ ଗତି କରନ୍ତି, କିନ୍ତୁ ସେ ତାଙ୍କର ଶେଷ ସେମିଷ୍ଟାର ପାଇଁ ଉତ୍ସାହିତ ଅଛନ୍ତି |",
              "as": "তেওঁ অকলশৰীয়া আৰু বাকীবোৰৰ পৰা পৃথক অনুভৱ কৰাৰ পৰ্যায়বোৰো পাৰ কৰে, কিন্তু তেওঁ এতিয়া তেওঁৰ অন্তিম ছেমিষ্টাৰক লৈ উৎসাহিত।",
              "gu": "તે અલગ-થલગ તથા એકલાપણા અંગે અહેસાસ કરવાના તબક્કામાંથી પણ પસાર થયા છે, જોકે તેઓ પોતાના અંતિમ સેમેસ્ટર અંગે ઉત્સાહિત છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_3",
              "hi": "C3_1_3",
              "ka": "C3_1_3",
              "te": "C3_1_3",
              "or": "C3_1_3",
              "as": "C3_1_3",
              "gu": "C3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind has to undertake a research project and prepare a thesis on it, and it is a document that has all the details of his project.",
              "hi": "अरविंद को एक शोध पर प्रोजेक्ट शुरू करना है और उस पर एक थीसिस तैयार करना है, यह एक दस्तावेज है जिसमें उसके प्रोजेक्ट के सभी विवरण हैं।",
              "ka": "ಅರವಿಂದ್ ಅವರು ಸಂಶೋಧನಾ ಯೋಜನೆಯನ್ನು ಕೈಗೆತ್ತಿಕೊಳ್ಳಬೇಕು ಮತ್ತು ಅದರ ಬಗ್ಗೆ ಪ್ರಬಂಧವನ್ನು ಸಿದ್ಧಪಡಿಸಬೇಕು ಮತ್ತು ಅದು ಅವರ ಯೋಜನೆಯ ಎಲ್ಲಾ ವಿವರಗಳನ್ನು ಹೊಂದಿರುವ ದಾಖಲೆಯಾಗಿದೆ.",
              "te": "అరవింద్ ఒక రీసెర్చ్ ప్రాజెక్ట్ తీసుకొని, దానిపై థీసిస్ సిద్ధం చేయాలి. అది అతని ప్రాజెక్ట్ యొక్క అన్ని విషయాలను వివరించే డాక్యుమెంట్.",
              "or": "ଅରବିନ୍ଦଙ୍କୁ ଏକ ଗବେଷଣା ପ୍ରୋଜେକ୍ଟ କରିବାକୁ ପଡିବ ଏବଂ ଏହା ଉପରେ ଏକ ଥିଏସ୍ ପ୍ରସ୍ତୁତ କରିବାକୁ ପଡିବ, ଏବଂ ଏହା ହେଉଛି ଏକ ଡକ୍ୟୁମେଣ୍ଟ ଯେଉଁଥିରେ ତାଙ୍କ ପ୍ରୋଜେକ୍ଟର ସମସ୍ତ ବିବରଣୀ ଅଛି |",
              "as": "অৰবিন্দে এটা গৱেষণা প্ৰকল্প হাতত ল'ব লাগিব আৰু ইয়াৰ ওপৰত এখন গৱেষণাপত্ৰ লিখিব লাগিব, আৰু ই হৈছে এক নথি য'ত তেওঁৰ প্ৰকল্পৰ সকলো বিৱৰণ থাকিব।",
              "gu": "અરવિંદ એક સંશોધન યોજના શરૂ કરવી છે અને આ અંગે એક થિસિસ તૈયાર કરવો છે, તથા આ એક હસ્તાવેજ છે,જેમાં તેની પરિયોજના અંગે તમામ વિગતો આપવામાં આવેલ છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_1_4",
              "hi": "C3_1_4",
              "ka": "C3_1_4",
              "te": "C3_1_4",
              "or": "C3_1_4",
              "as": "C3_1_4",
              "gu": "C3_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "It is an important project for Arvind, and he will be able to graduate if he does well in this project.",
              "hi": "यह अरविंद के लिए एक महत्वपूर्ण प्रोजेक्ट है, और यदि वह इस प्रोजेक्ट में अच्छा करता है तो वह स्नातक हो सकेगा।",
              "ka": "ಅರವಿಂದ್ ಗೆ ಇದೊಂದು ಮಹತ್ವದ ಪ್ರಾಜೆಕ್ಟ್ ಆಗಿದ್ದು, ಈ ಪ್ರಾಜೆಕ್ಟ್ ನಲ್ಲಿ ಉತ್ತಮ ಸಾಧನೆ ಮಾಡಿದರೆ ಪದವಿ ಪಡೆಯಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ",
              "te": "ఇది అరవింద్‌కి చాలా ముఖ్యమైన ప్రాజెక్ట్, ఈ ప్రాజెక్ట్‌లో బాగా చేస్తే అతను గ్రాడ్యుయేట్ అవ్వచ్చు.",
              "or": "ଅରବିନ୍ଦଙ୍କ ପାଇଁ ଏହା ଏକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ପ୍ରୋଜେକ୍ଟ, ଏବଂ ଯଦି ସେ ଏହି ପ୍ରୋଜେକ୍ଟରେ ଭଲ ପ୍ରଦର୍ଶନ କରନ୍ତି ସେ ସ୍ନାତକ ହାସଲ କରିବାକୁ ସମର୍ଥ ହେବେ |",
              "as": "এইটো অৰবিন্দৰ বাবে এক গুৰুত্বপূৰ্ণ প্ৰকল্প, আৰু যদি তেওঁ এই প্ৰকল্পটোত ভালদৰে কাম কৰে তেন্তে তেওঁ স্নাতক হ'ব পাৰিব।",
              "gu": "અરવિંદ માટે આ એક મહત્વનો પ્રોજેક્ટ છે, અને જો આ યોજનામાં તે સારું પર્ફોમન્સ રજૂ કરશે તો તે સ્નાતક થવા માટે સક્ષમ બની જશે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_2_1",
              "hi": "C3_2_1",
              "ka": "C3_2_1",
              "te": "C3_2_1",
              "or": "C3_2_1",
              "as": "C3_2_1",
              "gu": "C3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Through his project, Arvind wants to explore how law and policymaking can positively affect the lives of transgender people. He talks to Roshni about his idea.",
              "hi": "अपने प्रोजेक्ट के द्वारा, अरविंद यह पता लगाना चाहता है कि कानून और नीति निर्माण कैसे ट्रांसजेंडर लोगों के जीवन को सकारात्मक रूप से प्रभावित कर सकते हैं। वह रोशनी से अपने विचार के बारे में बात करता है।",
              "ka": "ತಮ್ಮ ಪ್ರಾಜೆಕ್ಟ್ ಮೂಲಕ, ಅರವಿಂದ್ ಅವರು ಕಾನೂನು ಮತ್ತು ನೀತಿ ನಿರೂಪಣೆಗಳು ಟ್ರಾನ್ಸ್ಜೆಂಡರ್ ಜನರ ಜೀವನವನ್ನು ಹೇಗೆ ಧನಾತ್ಮಕವಾಗಿ ಪರಿಣಾಮ ಬೀರಬಹುದು ಎಂಬುದನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುತ್ತಾರೆ. ಅವನು ತನ್ನ ಆಲೋಚನೆಯ ಬಗ್ಗೆ ರೋಶನಿಯೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾನೆ.",
              "te": "తన ప్రాజెక్ట్ ద్వారా, అరవింద్ చట్టం మరియు విధాన రూపకల్పన ద్వారా ట్రాన్స్ జెండర్ వ్యక్తుల జీవితాలను ఎలా బాగు చేస్తాయో తెలుసుకోవాలనుకుంటున్నారు. అతను తన ఆలోచన గురించి రోష్నితో మాట్లాడాడు.",
              "or": "ତାଙ୍କ ପ୍ରୋଜେକ୍ଟ ଜରିଆରେ ଅରବିନ୍ଦ ଏକ୍ସପ୍ଲୋର୍‌ କରିବାକୁ ଚାହାଁନ୍ତି କି ଟ୍ରା‍ନ୍ସଜେଣ୍ଡର୍‌ ଲୋକଙ୍କ ଜୀବନରେ ଆଇନ ଏବଂ ନୀତି ନିର୍ଣ୍ଣୟ କିପରି ସକରାତ୍ମକ ଭାବରେ ପ୍ରଭାବ ପକାଇପାରେ | ସେ ରୋଶନିଙ୍କ ସହିତ ତାଙ୍କ ଆଇଡି଼ୟା ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି |",
              "as": "তেওঁৰ প্ৰকল্পৰ জৰিয়তে, অৰবিন্দে আইন আৰু নীতি নিৰ্ধাৰণে কেনেদৰে ট্ৰেন্সজেণ্ডাৰ লোকসকলৰ জীৱনত ইতিবাচক প্ৰভাৱ পেলাব পাৰে তাৰ অনুসন্ধান কৰিব বিচাৰে। তেওঁ ৰোশনিৰ সৈতে তেওঁৰ ধাৰণাটোৰ বিষয়ে কথা পাতিছিল।",
              "gu": "તેની આ યોજના મારફતે અરવિંદને જાણ થવી જોઈએ કે કાયદો અને નીતિ નિર્માણ કેવી રીતે ટ્રાન્સઝેન્ડર લોકોના જીવનમાં હકારાત્મક રીતે અસર કરી શકે છે. તે રોશની સાથે પણ પોતાના વિચાર અંગે ચર્ચા કરે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_2_2",
              "hi": "C3_2_2",
              "ka": "C3_2_2",
              "te": "C3_2_2",
              "or": "C3_2_2",
              "as": "C3_2_2",
              "gu": "C3_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind decides to look into the opportunities transgender people have in mainstream jobs and their challenges.",
              "hi": "अरविंद ने मुख्य धारा की नौकरियों में ट्रांसजेंडर लोगों के अवसरों और उनकी चुनौतियों पर ध्यान देने का निश्चय किया।",
              "ka": "ಮುಖ್ಯವಾಹಿನಿಯ ಉದ್ಯೋಗಗಳಲ್ಲಿ ಟ್ರಾನ್ಸ್‌ಜೆಂಡರ್‌ಗಳಿಗೆ ಇರುವ ಅವಕಾಶಗಳು ಮತ್ತು ಅವರ ಸವಾಲುಗಳನ್ನು ಪರಿಶೀಲಿಸಲು ಅರವಿಂದ್ ನಿರ್ಧರಿಸಿದ್ದಾರೆ.",
              "te": "ప్రధాన ఉద్యోగాలలో ట్రాన్స్‌జెండర్‌లకు ఉన్న అవకాశాలను మరియు వారు ఎదుర్కోవాల్సిన సవాళ్లను పరిశీలించాలని అరవింద్ నిర్ణయించుకున్నాడు.",
              "or": "ମୁଖ୍ୟ ସ୍ରୋତ ଚାକିରିରେ ଟ୍ରାନ୍ସଜେଣ୍ଡର୍‌ ଲୋକଙ୍କ ପାଇଁ ସୁଯୋଗ ଏବଂ ସେମାନଙ୍କ ଚାଲେଞ୍ଜ୍‌ କୁ ଅରବିନ୍ଦ ଦେଖିବା ପାଇଁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
              "as": "অৰবিন্দে মূলসুঁতিৰ চাকৰিবোৰত ট্ৰেন্সজেণ্ডাৰ লোকসকলৰ বাবে থকা সুযোগবোৰ আৰু প্ৰত্যাহ্বান বোৰ চালি-জাৰি চাবলৈ সিদ্ধান্ত লৈছে।",
              "gu": "અરવિંદ મુખ્ય પ્રવાહની નોકરીઓમાં ટ્રાન્સલિંગ્સ લોકોને તક આપે છે અને તે પડકારોનો સામનો કરવાનો નિર્ણય કરે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_2_3",
              "hi": "C3_2_3",
              "ka": "C3_2_3",
              "te": "C3_2_3",
              "or": "C3_2_3",
              "as": "C3_2_3",
              "gu": "C3_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is researching his project. He has read all the relevant books and research papers and is now reaching out to organisations in India that work on the issues of transgender people to interview them online.",
              "hi": "अरविंद अपने प्रोजेक्ट पर शोध कर रहा है। उसने सभी प्रासंगिक किताबें और शोध पत्र पढ़े हैं और अब भारत में  उन संगठनों तक पहुंच रहा है जो ट्रांसजेंडर लोगों के मुद्दों पर काम करते हैं और उनका ऑनलाइन साक्षात्कार करते हैं।",
              "ka": "ಅರವಿಂದ್ ಅವನ ಪ್ರಾಜೆಕ್ಟ್ ಬಗ್ಗೆ ಸಂಶೋಧನೆ ನಡೆಸುತ್ತಿದ್ದಾನೆ. ಅವನು ಎಲ್ಲಾ ಸಂಬಂಧಿತ ಪುಸ್ತಕಗಳು ಮತ್ತು ಸಂಶೋಧನಾ ಪ್ರಬಂಧಗಳನ್ನು ಓದಿದ್ದಾನೆ ಮತ್ತು ಈಗ ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಅವರನ್ನು ಸಂದರ್ಶಿಸಲು ಟ್ರಾನ್ಸ್‌ಜೆಂಡರ್‌ಗಳ ಸಮಸ್ಯೆಗಳ ಕುರಿತು ಕೆಲಸ ಮಾಡುವ ಭಾರತದಲ್ಲಿನ ಸಂಸ್ಥೆಗಳನ್ನು ತಲುಪುತ್ತಿದ್ದಾನೆ.",
              "te": "అరవింద్ తన ప్రాజెక్ట్ గురించి రీసెర్చ్ చేస్తున్నాడు. అతను అన్ని సంబంధిత పుస్తకాలు మరియు పరిశోధనా పత్రాలను చదివాడు. ఇప్పుడు అతను భారతదేశంలోని ట్రాన్స్జెండర్ వ్యక్తుల సమస్యలపై పని చేసే సంస్థలను ఆన్‌లైన్‌లో ఇంటర్వ్యూ చేయాలనుకుంటున్నాడు.",
              "or": "ଅରବିନ୍ଦ ତାଙ୍କ ପ୍ରୋଜେକ୍ଟ ଉପରେ ଗବେଷଣା କରୁଛନ୍ତି। ସେ ସମସ୍ତ ପ୍ରାସଙ୍ଗିକ ପୁସ୍ତକ ଏବଂ ଗବେଷଣା କାଗଜପତ୍ର ପଢିଛନ୍ତି ଏବଂ ବର୍ତ୍ତମାନ ପହଞ୍ଚୁଛନ୍ତି ଭାରତର ସଂଗଠନମାନଙ୍କ ନିକଟରେ ଅ‍ନ୍‌ଲାଇନ ସାକ୍ଷାତକାର ପାଇଁ ଯେଉଁମାନେ ଟ୍ରାନ୍ସଜେଣ୍ଡର ଲୋକଙ୍କ ସମସ୍ୟା ଉପରେ କାର୍ଯ୍ୟ କରୁଛନ୍ତି |",
              "as": "অৰবিন্দে তেওঁৰ প্ৰকল্পটোৰ ওপৰত গৱেষণা কৰি আছে। তেওঁ সকলো প্ৰাসংগিক কিতাপ আৰু গৱেষণা পত্ৰ পঢ়িছে আৰু এতিয়া ভাৰতৰ প্ৰতিষ্ঠানবোৰৰ ওচৰলৈ গৈ আছে যিয়ে ট্ৰেন্সজেণ্ডাৰ লোকসকলৰ বিষয়বোৰৰ ওপৰত কাম কৰি অনলাইন সাক্ষাৎকাৰ ল'ব।",
              "gu": "અરવિંદ તેના પ્રોજેક્ટ અંગે સંશોધન કરી રહ્યો છે. તેને તમામ પ્રાસંગિક પુસ્તકો અને સંશોધન પત્રનો અભ્યાસ કર્યો છે તથા ભારતમાં આ સંગઠનો સુધી પહોંચી રહ્યો છે કે જે ટ્રાન્સલિંગ લોકોના મુદ્દે કામ કરી રહ્યા છે અને તેમનો ઓનલાઈન ઈન્ટર્વ્યુ લઈ રહ્યો છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_2_4",
              "hi": "C3_2_4",
              "ka": "C3_2_4",
              "te": "C3_2_4",
              "or": "C3_2_4",
              "as": "C3_2_4",
              "gu": "C3_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Neelam, the manager at Arise in Chennai, has agreed to talk to him.",
              "hi": "चेन्नई के एराइज में मैनेजर नीलम उनसे बात करने के लिए तैयार हो गई हैं।",
              "ka": "ಚೆನ್ನೈನ ಏರೈಸ್‌ನ ಮ್ಯಾನೇಜರ್ ನೀಲಂ ಅವರೊಂದಿಗೆ ಮಾತನಾಡಲು ಒಪ್ಪಿಕೊಂಡಿದ್ದಾರೆ.",
              "te": "చెన్నైలోని అరైజ్‌ యొక్క మేనేజర్ నీలం అతనితో మాట్లాడేందుకు అంగీకరించారు.",
              "or": "ଚେନ୍ନାଇରେ ଅରାଇଜ୍‌ ର ମ୍ୟାନେଜର ନୀଲମ୍‌ ତାଙ୍କ ସହ କଥା ହେବାକୁ ରାଜି ହୋଇଛନ୍ତି।",
              "as": "চেন্নাইৰ এৰিছৰ মেনেজাৰ নীলমে তেওঁৰ সৈতে কথা পাতিবলৈ সন্মত হৈছে।",
              "gu": "ચેન્નાઈના એરાઈઝ ખાતે મેનેજર નીલમ તેમની સાથે વાતચીત કરવા માટે તૈયાર થઈ ગઈ છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_2_5",
              "hi": "C3_2_5",
              "ka": "C3_2_5",
              "te": "C3_2_5",
              "or": "C3_2_5",
              "as": "C3_2_5",
              "gu": "C3_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Help Arvind prepare for the interview. Select the questions that he should ask.",
              "hi": "साक्षात्कार के लिए अरविंद को तैयार करने में सहायता करें। उन प्रश्नों का चयन करें जिन्हें उसे पूछना चाहिए।",
              "ka": "ಸಂದರ್ಶನಕ್ಕೆ ತಯಾರಿ ನಡೆಸಲು ಅರವಿಂದನಿಗೆ ಸಹಾಯ ಮಾಡಿ. ಅವನು ಕೇಳಬೇಕಾದ ಪ್ರಶ್ನೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ.",
              "te": "ఇంటర్వ్యూకి సిద్ధం కావడానికి అరవింద్‌కు సహాయం చేయండి. అతను అడగవలసిన ప్రశ్నలను ఎంచుకోండి.",
              "or": "ସାକ୍ଷାତକାର ପାଇଁ ଅରବିନ୍ଦଙ୍କୁ ସାହାଯ୍ୟ କରନ୍ତୁ | ପ୍ରଶ୍ନଗୁଡିକ ବାଛନ୍ତୁ ଯାହା ସେ ପଚାରିବା ଉଚିତ୍‌ |",
              "as": "অৰবিন্দক সাক্ষাৎকাৰৰ বাবে প্ৰস্তুত কৰাত সহায় কৰক। তেওঁ সুধিব লগা প্ৰশ্নবোৰ বাছনি কৰক।",
              "gu": "ઈન્ટરવ્યુની તૈયારીમાં અરવિંદની મદદ કરો. તેના પ્રશ્નોની પસંદગી કરો કે જે તે પૂછવા ઈચ્છે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.0.display",
            "value": {
              "en": "Why did you start this organisation?",
              "hi": "आपने इस संगठन को क्यों शुरू किया?",
              "ka": "ಈ ಸಂಸ್ಥೆಯನ್ನು ಏಕೆ ಆರಂಭಿಸಿದ್ದೀರಿ?",
              "te": "మీరు ఈ సంస్థను ఎందుకు ప్రారంభించారు?",
              "or": "କାହିଁକି ଆପଣ ଏହି ସଂଗଠନ ଆରମ୍ଭ କରିଥିଲେ ?",
              "as": "আপুনি এই প্ৰতিষ্ঠানটো কিয় আৰম্ভ কৰিছিল?",
              "gu": "આ સંસ્થા તમે શા માટે શરૂ કરી?"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.1.display",
            "value": {
              "en": "Why aren’t there many organisations like yours?",
              "hi": "आपके जैसे कई संगठन क्यों नहीं हैं?",
              "ka": "ನಿಮ್ಮಂತಹ ಅನೇಕ ಸಂಸ್ಥೆಗಳು ಏಕೆ ಇಲ್ಲ?",
              "te": "మీలాంటి సంస్థలు ఇంకా ఎందుకు లేవు?",
              "or": "କାହିଁକି ଆପଣଙ୍କ ପରି ଆଉ ଅନେକ ସଂସ୍ଥା ନାହିଁ ?",
              "as": "আপোনাৰ প্ৰতিষ্ঠানৰ দৰে আৰু বহুতো প্ৰতিষ্ঠান কিয় নাই?",
              "gu": "તમારા જેવી અન્ય કોઈ સંસ્થા શા માટે નથી?"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.1.options.0.display",
            "value": {
              "en": "Why doesn’t  your organisation work outside Chennai?",
              "hi": "आपका संगठन चेन्नई के बाहर क्यों नहीं काम करता है?",
              "ka": "ನಿಮ್ಮ ಸಂಸ್ಥೆ ಚೆನ್ನೈನ ಹೊರಗೆ ಏಕೆ ಕೆಲಸ ಮಾಡುವುದಿಲ್ಲ?",
              "te": "మీ సంస్థ చెన్నై బయట ఎందుకు పనిచేయదు?",
              "or": "ଚେନ୍ନାଇ ବାହାରେ ଆପଣଙ୍କ ସଂସ୍ଥା କାହିଁକି କାମ କରୁନାହିଁ ?",
              "as": "আপোনাৰ প্ৰতিষ্ঠানে চেন্নাইৰ বাহিৰত কিয় কাম নকৰে?",
              "gu": "તમે શા માટે ચેન્નાઈની બહાર તમારી સંસ્થા રાખતા નથી?"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.1.options.1.display",
            "value": {
              "en": "Can you explain the work that you do?",
              "hi": "क्या आप उस काम को समझा सकते हैं जो आप करते हैं?",
              "ka": "ನೀವು ಮಾಡುವ ಕೆಲಸವನ್ನು ವಿವರಿಸುವಿರಾ?",
              "te": "మీరు చేసే పని గురించి వివరించగలరా?",
              "or": "ଆପଣ କରୁଥିବା କାର୍ଯ୍ୟକୁ ଆପଣ ବ୍ୟାଖ୍ୟା କରିପାରିବେ କି ?",
              "as": "আপুনি কৰি থকা কামটোৰ বৰ্ণনা কৰিব পাৰিবনে?",
              "gu": "તમે જે કાર્ય કરી રહ્યા છો તે અંગે વિગતવાર માહિતી આપશો?"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.2.options.0.display",
            "value": {
              "en": "How do you think a workplace can become more inclusive of transgender people?",
              "hi": "आपको लगता है कि एक कार्यस्थल ट्रांसजेंडर लोगों के साथ अधिक समावेशी हो सकता है?",
              "ka": "ಕೆಲಸದ ಸ್ಥಳವು ಟ್ರಾನ್ಸ್ಜೆಂಡರ್ ಜನರನ್ನು ಹೇಗೆ ಹೆಚ್ಚು ಒಳಗೊಳ್ಳಬಹುದು ಎಂದು ನೀವು ಯೋಚಿಸುತ್ತೀರಿ?",
              "te": "వర్క్ ప్లేస్ లో ట్రాన్స్జెండర్ వ్యక్తులు కూడా పని చేసేలాగా ఎలా మారుతుందని మీరు అనుకుంటున్నారు?",
              "or": "ଆପଣ କିପରି ଭାବୁଛନ୍ତି ଏକ କର୍ମକ୍ଷେତ୍ରରେ ଟ୍ରାନ୍ସଜେଣ୍ଡର୍‌ ଲୋକଙ୍କ ମଧ୍ୟରେ ଅଧିକ ଅନ୍ତର୍ଭୂକ୍ତ ହୋଇପାରିବ ?",
              "as": "আপুনি কৰ্মস্থান এটাত ট্ৰেন্সজেণ্ডাৰ লোকক অধিক অন্তৰ্ভুক্ত কৰাৰ বিষয়ে কেনেকৈ ভাবে?",
              "gu": "તમને શું લાગે છે કે એક કાર્યસ્થળે ટ્રાન્સઝેન્ડર લોકોને વધારે સમાવેશી કેવી રીતે બનાવી શકાય છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.2.options.1.display",
            "value": {
              "en": "I will be looking for a job soon, can I send in my application?",
              "hi": "मैं जल्द ही नौकरी की खोज करूंगा, क्या मैं अपना आवेदन भेज सकता हूं?",
              "ka": "ನಾನು ಶೀಘ್ರದಲ್ಲೇ ಉದ್ಯೋಗವನ್ನು ಹುಡುಕುತ್ತಿದ್ದೇನೆ, ನನ್ನ ಅರ್ಜಿಯನ್ನು ನಾನು ಕಳುಹಿಸಬಹುದೇ?",
              "te": "నేను త్వరలో ఉద్యోగం కోసం వెతుకుతున్నాను, నేను నా అప్లికేషన్ పంపవచ్చా?",
              "or": "ଶୀଘ୍ର ମୁଁ ଚାକିରି ଖୋଜିବି, ମୁଁ ମୋର ଆବେଦନ ପଠାଇ ପାରିବି କି ?",
              "as": "মই সোনকালেই চাকৰি এটা বিচাৰিম, মই মোৰ আবেদন পঠিয়াব পাৰিম নেকি?",
              "gu": "હું જલ્દીથી નોકરીની શોધમાં છું, શું હું મારી અરજી મોકલી શકું છું?"
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "Research",
              "hi": "अनुसंधान",
              "ka": "ಸಂಶೋಧನೆ",
              "te": "రీసర్చ్",
              "or": "ଅନୁସନ୍ଧାନ",
              "as": "গৱেষণা",
              "gu": "સંશોધન"
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_2_6",
              "hi": "C3_2_6",
              "ka": "C3_2_6",
              "te": "C3_2_6",
              "or": "C3_2_6",
              "as": "C3_2_6",
              "gu": "C3_2_6"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Help Arvind prepare for the interview. Select the questions that he should ask.",
              "hi": "साक्षात्कार के लिए अरविंद को तैयार करने में मदद करें। उन प्रश्नों का चयन करें जिन्हें उसे पूछना चाहिए।",
              "ka": "ಸಂದರ್ಶನಕ್ಕೆ ತಯಾರಿ ನಡೆಸಲು ಅರವಿಂದನಿಗೆ ಸಹಾಯ ಮಾಡಿ. ಅವನು ಕೇಳಬೇಕಾದ ಪ್ರಶ್ನೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ.",
              "te": "ఇంటర్వ్యూకి సిద్ధం కావడానికి అరవింద్‌కు సహాయం చేయండి. అతను అడగవలసిన ప్రశ్నలను ఎంచుకోండి.",
              "or": "ସାକ୍ଷାତକାର ପାଇଁ ଅରବିନ୍ଦଙ୍କୁ ସାହାଯ୍ୟ କରନ୍ତୁ | ପ୍ରଶ୍ନଗୁଡିକ ବାଛନ୍ତୁ ଯାହା ସେ ପଚାରିବା ଉଚିତ୍‌ |",
              "as": "অৰবিন্দক সাক্ষাৎকাৰৰ বাবে প্ৰস্তুত কৰাত সহায় কৰক। তেওঁ সুধিব লগা প্ৰশ্নবোৰ বাছনি কৰক।",
              "gu": "ઈન્ટરવ્યુની તૈયારીમાં અરવિંદની મદદ કરો. તેના પ્રશ્નોની પસંદગી કરો કે જે તે પૂછવા ઈચ્છે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.0.display",
            "value": {
              "en": "Why did you start this organisation?",
              "hi": "आपने इस संगठन को क्यों शुरू किया?",
              "ka": "ಈ ಸಂಸ್ಥೆಯನ್ನು ಏಕೆ ಆರಂಭಿಸಿದ್ದೀರಿ?",
              "te": "మీరు ఈ సంస్థను ఎందుకు ప్రారంభించారు?",
              "or": "କାହିଁକି ଆପଣ ଏହି ସଂଗଠନ ଆରମ୍ଭ କରିଥିଲେ ?",
              "as": "আপুনি এই প্ৰতিষ্ঠানটো কিয় আৰম্ভ কৰিছিল?",
              "gu": "તમે આ સંગઠન શા માટે શરૂ કર્યું?"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.1.display",
            "value": {
              "en": "Why aren’t there many organisations like yours?",
              "hi": "आपके जैसे कई संगठन क्यों नहीं हैं?",
              "ka": "ನಿಮ್ಮಂತಹ ಅನೇಕ ಸಂಸ್ಥೆಗಳು ಏಕೆ ಇಲ್ಲ?",
              "te": "మీలాంటి సంస్థలు ఇంకా ఎందుకు లేవు?",
              "or": "କାହିଁକି ଆପଣଙ୍କ ପରି ଆଉ ଅନେକ ସଂସ୍ଥା ନାହିଁ ?",
              "as": "আপোনাৰ প্ৰতিষ্ঠানৰ দৰে আৰু বহুতো প্ৰতিষ্ঠান কিয় নাই?",
              "gu": "તમારા જેવા અનેક સંગઠન શા માટે નથી?"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.1.options.0.display",
            "value": {
              "en": "Why doesn’t  your organisation work outside Chennai?",
              "hi": "आपका संगठन चेन्नई के बाहर क्यों नहीं काम करता है?",
              "ka": "ನಿಮ್ಮ ಸಂಸ್ಥೆ ಚೆನ್ನೈನ ಹೊರಗೆ ಏಕೆ ಕೆಲಸ ಮಾಡುವುದಿಲ್ಲ?",
              "te": "మీ సంస్థ చెన్నై బయట ఎందుకు పనిచేయదు?",
              "or": "ଚେନ୍ନାଇ ବାହାରେ ଆପଣଙ୍କ ସଂସ୍ଥା କାହିଁକି କାମ କରୁନାହିଁ ?",
              "as": "আপোনাৰ প্ৰতিষ্ঠানে চেন্নাইৰ বাহিৰত কিয় কাম নকৰে?",
              "gu": "આ સંગઠન ચેન્નઈની બહાર કામ શા માટે કરતાં નથી?"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.1.options.1.display",
            "value": {
              "en": "Can you explain the work that you do?",
              "hi": "क्या आप उस काम को समझा सकते हैं जो आप करते हैं?",
              "ka": "ನೀವು ಮಾಡುವ ಕೆಲಸವನ್ನು ವಿವರಿಸುವಿರಾ?",
              "te": "మీరు చేసే పని గురించి వివరించగలరా?",
              "or": "ଆପଣ କରୁଥିବା କାର୍ଯ୍ୟକୁ ଆପଣ ବ୍ୟାଖ୍ୟା କରିପାରିବେ କି ?",
              "as": "আপুনি কৰি থকা কামটোৰ বৰ্ণনা কৰিব পাৰিবনে?",
              "gu": "તમે જે કાર્ય કરો છો તે અંગે વિગતો આપી શકો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.2.options.0.display",
            "value": {
              "en": "How do you think a workplace can become more inclusive of transgender people?",
              "hi": "आपको लगता है कि एक कार्यस्थल ट्रांसजेंडर लोगों के साथ अधिक समावेशी हो सकता है?",
              "ka": "ಕೆಲಸದ ಸ್ಥಳವು ಟ್ರಾನ್ಸ್ಜೆಂಡರ್ ಜನರನ್ನು ಹೇಗೆ ಹೆಚ್ಚು ಒಳಗೊಳ್ಳಬಹುದು ಎಂದು ನೀವು ಯೋಚಿಸುತ್ತೀರಿ?",
              "te": "వర్క్ ప్లేస్ లో ట్రాన్స్జెండర్ వ్యక్తులు కూడా పని చేసేలాగా ఎలా మారుతుందని మీరు అనుకుంటున్నారు?",
              "or": "ଆପଣ କିପରି ଭାବୁଛନ୍ତି ଏକ କର୍ମକ୍ଷେତ୍ରରେ ଟ୍ରାନ୍ସଜେଣ୍ଡର୍‌ ଲୋକଙ୍କ ମଧ୍ୟରେ ଅଧିକ ଅନ୍ତର୍ଭୂକ୍ତ ହୋଇପାରିବ ?",
              "as": "আপুনি কৰ্মস্থান এটাত ট্ৰেন্সজেণ্ডাৰ লোকক অধিক অন্তৰ্ভুক্ত কৰাৰ বিষয়ে কেনেকৈ ভাবে?",
              "gu": "તમને શું લાગે છે કે એક કાર્યસ્થળે ટ્રાન્સઝેન્ડર લોકોને વધારે સમાવેશી કેવી રીતે બનાવી શકાય છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.2.options.1.display",
            "value": {
              "en": "I will be looking for a job soon, can I send in my application?",
              "hi": "मैं जल्द ही नौकरी की खोज करूंगा, क्या मैं अपना आवेदन भेज सकता हूं?",
              "ka": "ನಾನು ಶೀಘ್ರದಲ್ಲೇ ಉದ್ಯೋಗವನ್ನು ಹುಡುಕುತ್ತಿದ್ದೇನೆ, ನನ್ನ ಅರ್ಜಿಯನ್ನು ನಾನು ಕಳುಹಿಸಬಹುದೇ?",
              "te": "నేను త్వరలో ఉద్యోగం కోసం వెతుకుతున్నాను, నేను నా అప్లికేషన్ పంపవచ్చా?",
              "or": "ଶୀଘ୍ର ମୁଁ ଚାକିରି ଖୋଜିବି, ମୁଁ ମୋର ଆବେଦନ ପଠାଇ ପାରିବି କି ?",
              "as": "মই সোনকালেই চাকৰি এটা বিচাৰিম, মই মোৰ আবেদন পঠিয়াব পাৰিম নেকি?",
              "gu": "હું જલ્દીથી નોકરીની શોધમાં છું, શું હું મારી અરજી મોકલી શકું છું?"
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_3_1",
              "hi": "C3_3_1",
              "ka": "C3_3_1",
              "te": "C3_3_1",
              "or": "C3_3_1",
              "as": "C3_3_1",
              "gu": "C3_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is glad that organisations are working for the betterment of his community. He wants to finish his work soon to share it with Neelam.",
              "hi": "अरविंद खुश है कि संगठन अपने समुदाय के सुधार के लिए काम कर रहे हैं। वह नीलम के साथ इसे साझा करने के लिए जल्द ही अपना काम पूरा करना चाहता है।",
              "ka": "ಅರವಿಂದ್ ಅವರ ಸಮುದಾಯದ ಶ್ರೇಯೋಭಿವೃದ್ಧಿಗೆ ಸಂಘಟನೆಗಳು ಶ್ರಮಿಸುತ್ತಿರುವುದು ಸಂತಸ ತಂದಿದೆ. ನೀಲಂ ಜೊತೆ ಹಂಚಿಕೊಳ್ಳಲು ಅವನು ತನ್ನ ಕೆಲಸವನ್ನು ಬೇಗ ಮುಗಿಸಲು ಬಯಸುತ್ತಾನೆ.",
              "te": "తన సంఘం అభ్యున్నతి కోసం సంస్థలు కృషి చేస్తున్నందుకు అరవింద్ సంతోషిస్తున్నాడు. నీలంతో అది చెప్పడానికి అతను తన పనిని త్వరగా పూర్తి చేయాలనుకుంటున్నాడు.",
              "or": "ଅରବିନ୍ଦ ଖୁସି ଅଛନ୍ତି ଯେ ସଂଗଠନଗୁଡ଼ିକ ତାଙ୍କ ସମୁଦାୟର ଉନ୍ନତି ପାଇଁ କାର୍ଯ୍ୟ କରୁଛନ୍ତି | ସେ ନୀଲମଙ୍କ ସହ ଅଂଶୀଦାର କରିବାକୁ ଶୀଘ୍ର କାମ ଶେଷ କରିବାକୁ ଚାହୁଁଛନ୍ତି |",
              "as": "অৰবিন্দ সুখী যে প্ৰতিষ্ঠানবোৰে তেওঁৰ সম্প্ৰদায়ৰ উন্নতিৰ বাবে কাম কৰি আছে। তেওঁ নীলমৰ সৈতে কথা ভাগ-বতৰা কৰিবলৈ সোনকালেই তেওঁৰ কাম শেষ কৰিব বিচাৰে।",
              "gu": "અરવિંદ ખુશ છે કે સંગઠન તેના સમુદાયને વધારે સારું કરવા માટે કામ કરે છે. તે નીલમ સાથે વિચારોનું આદાન-પ્રદાન કરવા માટે પોતાનું કામ જલ્દી ખતમ કરવા ઈચ્છે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_3_2",
              "hi": "C3_3_2",
              "ka": "C3_3_2",
              "te": "C3_3_2",
              "or": "C3_3_2",
              "as": "C3_3_2",
              "gu": "C3_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind returns to his room that day and looks in the mirror.",
              "hi": "अरविंद उस दिन अपने कमरे में वापस आता है और दर्पण  देखता है।",
              "ka": "ಆ ದಿನ ಅರವಿಂದ್ ತನ್ನ ಕೋಣೆಗೆ ಹಿಂತಿರುಗಿ ಕನ್ನಡಿಯಲ್ಲಿ ನೋಡುತ್ತಾನೆ.",
              "te": "ఆ రోజు అరవింద్ తన గదికి తిరిగి వచ్చి అద్దంలో చూసుకుంటాడు.",
              "or": "ସେଦିନ ଅରବିନ୍ଦ ନିଜ ରୁମ୍‌କୁ ଫେରନ୍ତି ଏବଂ ଦର୍ପଣକୁ ଚାହାଁନ୍ତି |",
              "as": "অৰবিন্দ সেইদিনা তেওঁৰ কোঠালৈ ঘূৰি আহি নিজকে আইনাত চালে।",
              "gu": "અરવિંદ તે દિવસે પોતાના રૂમમાં પરત ફરે છે અને અરીસામાં જુએ છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_3_3",
              "hi": "C3_3_3",
              "ka": "C3_3_3",
              "te": "C3_3_3",
              "or": "C3_3_3",
              "as": "C3_3_3",
              "gu": "C3_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He has always known that he is more comfortable living as a woman. He wishes that he had been given a female body instead.",
              "hi": "उसे हमेशा से पता है कि वह एक महिला के रूप में अधिक सहज है। वह चाहता है कि उसे इसके बजाय स्त्री का शरीर दिया गया हो।",
              "ka": "ಹೆಣ್ಣಾಗಿ ಬದುಕುವುದು ಹೆಚ್ಚು ಆರಾಮದಾಯಕ ಎಂದು ಅವರು ಯಾವಾಗಲೂ ತಿಳಿದಿದ್ದಾರೆ. ಬದಲಾಗಿ ತನಗೆ ಹೆಣ್ಣಿನ ದೇಹವನ್ನು ನೀಡಿದ್ದರೆಂದು ಅವನು ಬಯಸುತ್ತಾನೆ.",
              "te": "అతను స్త్రీగా ఉంటే ఇంకా సౌకర్యంగా జీవించవచ్చు అని అతనికి ఎప్పటి నుంచో తెలుసు. అతనికి స్త్రీ శరీరం ఇస్తే బాగుండేది అని అతను కోరుకుంటాడు.",
              "or": "ସେ ସର୍ବଦା ଜାଣିଛନ୍ତି ଯେ ସେ ଜଣେ ମହିଳା ଭାବରେ ବଞ୍ଚିବାରେ ଅଧିକ ଆରାମଦାୟକ | ସେ ଚାହାଁନ୍ତି ଯେ ଏହା ବଦଳରେ ତାଙ୍କୁ ଏକ ମହିଳା ଶରୀର ଦିଆଯାଇଥାନ୍ତା।",
              "as": "তেওঁ সদায় জানে যে তেওঁ এগৰাকী মহিলা হিচাপে জীয়াই থকি বেছি ভাল পাব। তেওঁ আশা কৰে যে লৰাৰ সলনি তেওঁক এটা মহিলাৰ শৰীৰ যদি দিয়া হ'লহেঁতেন।",
              "gu": "તે હંમેશા જાણે છે કે તે એક મહિલા તરીકે રહેવામાં વધારે સહજ છે. તે ઈચ્છે છે કે તેના બદલામાં તેને એક સ્ત્રી શરીર આપવામાં આવે છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_4_1",
              "hi": "C3_4_1",
              "ka": "C3_4_1",
              "te": "C3_4_1",
              "or": "C3_4_1",
              "as": "C3_4_1",
              "gu": "C3_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After a few days, he decides to talk to Roshni.",
              "hi": "कुछ दिनों के बाद, वह रोशनी से बात करने का निश्चय करता है।",
              "ka": "ಕೆಲವು ದಿನಗಳ ನಂತರ, ಅವನು ರೋಶನಿಯೊಂದಿಗೆ ಮಾತನಾಡಲು ನಿರ್ಧರಿಸಿದರು.",
              "te": "కొన్ని రోజుల తర్వాత, అతను రోష్నితో మాట్లాడాలని నిర్ణయించుకున్నాడు.",
              "or": "କିଛି ଦିନ ପରେ, ସେ ରୋଶନିଙ୍କ ସହ କଥା ହେବାକୁ ସ୍ଥିର କରନ୍ତି |",
              "as": "কেইদিনমানৰ পিছত, তেওঁ ৰোশনিৰ সৈতে কথা পাতিবলৈ সিদ্ধান্ত লয়।",
              "gu": "થોડા દિવસ બાદ તે રોશની સાથે વાત કરવાનો નિર્ણય કરે છે."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_4_2////",
              "hi": "C3_4_2 ///////",
              "ka": "C3_4_2////",
              "te": "C3_4_2////",
              "or": "C3_4_2////",
              "as": "C3_4_2////",
              "gu": "C3_4_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Roshni, I have been thinking about this for a while now. I have decided that I am a woman and that my name from now on will be Asmita, with ‘she/her’ pronouns.",
              "hi": "रोशनी, मैं थोड़े दिनों से  इस बारे में सोच रहा हूं। मैंने निश्चय किया है कि मैं एक महिला हूं और अब से मेरा नाम अस्मिता होगा, 'वह / उसके' सर्वनाम के साथ।",
              "ka": "ರೋಶನಿ, ನಾನು ಈ ಬಗ್ಗೆ ಸ್ವಲ್ಪ ಸಮಯದಿಂದ ಯೋಚಿಸುತ್ತಿದ್ದೇನೆ. ನಾನೊಬ್ಬ ಮಹಿಳೆ ಮತ್ತು ಇನ್ನು ಮುಂದೆ ನನ್ನ ಹೆಸರು ‘ಅವಳು/ಅವಳ’ ಸರ್ವನಾಮಗಳೊಂದಿಗೆ ಅಸ್ಮಿತಾ ಎಂದು ನಾನು ನಿರ್ಧರಿಸಿದೆ.",
              "te": "రోష్నీ, నేను కొంతకాలంగా దీని గురించి ఆలోచిస్తున్నాను. నేను స్త్రీని, ఇక నుండి నా పేరు అస్మిత అని, ‘ఆమె/తను’ నా సర్వనామాలు అని నిర్ణయించుకున్నాను.",
              "or": "ରୋଶନି, ବର୍ତ୍ତମାନ ମୁଁ କିଛି ସମୟ ପାଇଁ ଏହି ବିଷୟରେ ଚିନ୍ତା କରି ଆସୁଛି | ମୁଁ ସ୍ଥିର କରିଛି ଯେ ମୁଁ ଜଣେ ମହିଳା ଏବଂ ବର୍ତ୍ତମାନଠାରୁ ମୋର ନାମ ‘ସେ / ତା’ର’ ସର୍ବନାମ ସହିତ, ଅସ୍ମିତା ହେବ |",
              "as": "ৰোশনি, মই অলপ সময়ৰ পৰা এই বিষয়ে চিন্তা কৰি আছো। মই সিদ্ধান্ত লৈছো যে মই এতিয়াৰ পৰা এগৰাকী মহিলা আৰু 'তাই/তাইৰ' সৰ্বনামৰ সৈতে মোৰ নাম হ'ব অস্মিতা।",
              "gu": "રોશની, હું કેટલાક સમયથી વિચાર કરું છું. મે નક્કી કર્યું છે કે હું એક મહિલા છું અને હવેથી મારુ નામ અસ્મિતા હશે, જેમા 'તે/તેણીની' સર્વનામ હશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I am so happy for you, Arvind!.. I mean Asmita!... sorry, I’m so used to calling you Arvind. Who all have you spoken to about this?",
              "hi": "मैं तुम्हारे लिए बहुत खुश हूं, अरविंद! .. मेरा मतलब अस्मिता! ... क्षमा करो, मैं तुमको अरविंद कहने की इतनी आदी हो गई हूँ। तुमने इस बारे में किससे बात की है?",
              "ka": "ನಿನಗಾಗಿ ನನಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿದೆ, ಅರವಿಂದ್!.. ಅಂದರೆ ಅಸ್ಮಿತಾ!... ಕ್ಷಮಿಸಿ, ನಾನು ನಿನ್ನನ್ನು ಅರವಿಂದ್ ಎಂದು ಕರೆಯುವುದು ತುಂಬಾ ಅಭ್ಯಾಸವಾಗಿದೆ. ಇದರ ಬಗ್ಗೆ ನೀವು ಯಾರೊಂದಿಗೆ ಮಾತನಾಡಿದ್ದೀರಿ?",
              "te": "నాకు చాలా సంతోషంగా ఉంది, అరవింద్!.. అంటే అస్మితా!... సారీ, నేను నిన్ను అరవింద్ అని పిలవడం అలవాటు అయింది. దీని గురించి నువ్వు ఎవరితో మాట్లాడారు?",
              "or": "ମୁଁ ତୁମ ପାଇଁ ବହୁତ ଖୁସି, ଅରବିନ୍ଦ! .. ମୋର ଅର୍ଥ ଅସ୍ମିତା! ... ଦୁଃଖିତ, ମୁଁ ତୁମକୁ ଅରବିନ୍ଦ ବୋଲି ଡାକିବାରେ ଏତେ ଅଭ୍ୟସ୍ତ | ଏହି ବିଷୟରେ ତୁମେ କେଉଁ ମାନଙ୍କ ସହିତ କଥା ହୋଇଛ ?",
              "as": "মই আপোনাৰ বাবে বহুত সুখী, অৰবিন্দ!.. মানে অস্মিতা!... বেয়া নাপাব, মই আপোনাক অৰবিন্দ বুলি মতাতেই বহুত অভ্যস্ত। আপুনি এই বিষয়ে কাৰ সৈতে কথা পাতিছে?",
              "gu": "હું તમારા માટે ઘણો ખુશ છું, અરવિંદ!...મારો અર્થ અસ્મિતા!...માફ કરો, હું તમને અરવિંદ કહેવા ટેવાયેલ છું. શું તમે કોઈની સાથે વાતચીત કરી?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "No one. You’re the first to know.",
              "hi": "किसी से नहीं। तुम सबसे पहली जानने वाली हो।",
              "ka": "ಯಾರೂ ಇಲ್ಲ. ನೀವು ಮೊದಲು ತಿಳಿದಿರುವಿರಿ.",
              "te": "ఎవరూ లేరు. మొదట నీకే చెప్తున్నాను.",
              "or": "କେହି ନୁହେଁ | ତୁମେ ସବୁଠାରୁ ପ୍ରଥମ ଜାଣିଳ |",
              "as": "কাৰো লগত পতা নাই। তুমিয়ে প্ৰথমে জানিব পাৰিছা।",
              "gu": "ના કોઈની સાથે નહીં. તમે જ જાણકારીમાં પ્રથમ છો"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "I feel glad. But when are you planning to share this with Amma, Appa, and Pranav? There’s probably so much to do, but don’t worry, I’ll be with you every step of the way.",
              "hi": "मुझे खुशी है। लेकिन तुम इसे अम्मा, अप्पा और प्रणव के साथ कब साझा करने की योजना बना रहे हो? करने के लिए शायद बहुत कुछ है, लेकिन चिंता मत करो, मैं हर कदम पर तुम्हारे साथ रहूंगी।",
              "ka": "ನನಗೆ ಖುಷಿಯಾಗುತ್ತಿದೆ. ಆದರೆ ನೀವು ಇದನ್ನು ಯಾವಾಗ ಅಮ್ಮ, ಅಪ್ಪ ಮತ್ತು ಪ್ರಣವ್ ಅವರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಲು ಯೋಜಿಸುತ್ತಿದ್ದೀರಿ? ಬಹುಶಃ ಮಾಡಲು ತುಂಬಾ ಇದೆ, ಆದರೆ ಚಿಂತಿಸಬೇಡಿ, ಪ್ರತಿ ಹಂತದಲ್ಲೂ ನಾನು ನಿಮ್ಮೊಂದಿಗೆ ಇರುತ್ತೇನೆ.",
              "te": "నాకు సంతోషంగా ఉంది. మరి ఈ విషయాన్ని అమ్మ, అప్ప, ప్రణవ్‌లతో ఎప్పుడు చెప్పాలని అనుకుంటున్నావు? బహుశా చాలా చేయాల్సి వస్తుంది, కానీ చింతించకు, నేను అడుగడుగునా నీతో ఉంటాను.",
              "or": "ମୁଁ ଖୁସି ଅନୁଭବ କରୁଛି | କିନ୍ତୁ ତୁମେ କେବେ ଏହା ଆମ୍ମା, ଆପ୍ପା, ଏବଂ ପ୍ରଣବଙ୍କ ସହ ଅଂଶୀଦାର କରିବାକୁ ଯୋଜନା କରୁଛ? ବୋଧହୁଏ ବହୁତ କିଛି କରିବାକୁ ଅଛି, କିନ୍ତୁ ବ୍ୟସ୍ତ ହୁଅ ନାହିଁ, ମୁଁ ପଥର ପ୍ରତ୍ୟେକ ପଦକ୍ଷେପରେ ତୁମ ସହିତ ରହିବି |",
              "as": "মই সুখী হৈছো। কিন্তু আপুনি কেতিয়া আম্মা, আপ্পা আৰু প্ৰণৱৰ সৈতে এইটো আলোচনা কৰাৰ কথা ভাবিছে? সম্ভৱতঃ বহুত কাম কৰিব লাগিব, কিন্তু চিন্তা নকৰিব, মই প্ৰতিটো ক্ষেত্ৰতে আপোনাৰ সৈতে থাকিম।",
              "gu": "મને ખુશી હોય છે. જોકે તમે આ અમ્મા, અપ્પા અને પ્રણવ સાથે ક્યારે વિચારોને રજૂ કરવાની યોજના ધરાવી રહ્યા છો? આ કરવા માટે કદાંચ ઘણુબધુ છે, જોકે ચિંતા ન કરો, હું દરેક પગલા પર તમારી સાથે રહીશ."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_5_1",
              "hi": "C3_5_1",
              "ka": "C3_5_1",
              "te": "C3_5_1",
              "or": "C3_5_1",
              "as": "C3_5_1",
              "gu": "C3_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Roshni and Asmita go to their parents and Pranav to talk about transitioning into Asmita.",
              "hi": "रोशनी और अस्मिता अपने माता-पिता और प्रणव के पास अस्मिता में बदलाव की बात करने जाते हैं।",
              "ka": "ರೋಶ್ನಿ ಮತ್ತು ಅಸ್ಮಿತಾ ಅಸ್ಮಿತಾ ಆಗಿ ಪರಿವರ್ತನೆಯಾಗುವ ಬಗ್ಗೆ ಮಾತನಾಡಲು ಅವರ ಪೋಷಕರು ಮತ್ತು ಪ್ರಣವ್ ಬಳಿಗೆ ಹೋಗುತ್ತಾರೆ.",
              "te": "రోష్ని మరియు అస్మిత ఇద్దరూ అస్మితగా మారడం గురించి మాట్లాడటానికి వారి తల్లిదండ్రులు మరియు ప్రణవ్ వద్దకు వెళతారు.",
              "or": "ରୋଶନି ଏବଂ ଅସ୍ମିତା ସେମାନଙ୍କ ପିତାମାତା ଏବଂ ପ୍ରଣବଙ୍କ ନିକଟକୁ ଯାଇ ଅସ୍ମିତାରେ ପରିବର୍ତ୍ତନ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି |",
              "as": "ৰোশনি আৰু অস্মিতাই তেওঁলোকৰ মাক দেউতাক আৰু প্ৰণৱৰ ওচৰলৈ অস্মিতালৈ হোৱা পৰিৱৰ্তনৰ বিষয়ে কথা পাতিবলৈ যায়।",
              "gu": "રોશની અને અસ્મિતા તેમના માતા-પિતા તથા પ્રણવની પાસે અસ્મિતામાં તબદિલ કરવાની વાત કરે છે."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_5_2",
              "hi": "C3_5_2",
              "ka": "C3_5_2",
              "te": "C3_5_2",
              "or": "C3_5_2",
              "as": "C3_5_2",
              "gu": "C3_5_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They are at a loss for words. Her father is worried about what people might say, while her mother is worried about her future, especially because she does not know what it means for Asmita to live as a trans person.",
              "hi": "उनके पास शब्द कम पड़ गए हैं। उसके पिता इस बात से चिंतित हैं कि लोग क्या कह सकते हैं, जबकि उसकी माँ उसके भविष्य को लेकर चिंतित है, खासकर इसलिए कि वह नहीं जानती कि अस्मिता के ट्रांस व्यक्ति के रूप में रहने का क्या मतलब है।",
              "ka": "ಅವರು ಮಾತಿಗೆ ಸೋತಿದ್ದಾರೆ. ಅಸ್ಮಿತಾ ಟ್ರಾನ್ಸ್ ವ್ಯಕ್ತಿಯಾಗಿ ಬದುಕುವುದರ ಅರ್ಥವೇನೆಂದು ತಿಳಿದಿಲ್ಲದ ಕಾರಣ, ಆಕೆಯ ತಾಯಿ ತನ್ನ ಭವಿಷ್ಯದ ಬಗ್ಗೆ ಚಿಂತಿತರಾಗಿರುವಾಗ ಆಕೆಯ ತಂದೆ ಜನರು ಏನು ಹೇಳಬಹುದು ಎಂದು ಚಿಂತಿತರಾಗಿದ್ದಾರೆ.",
              "te": "వారికి మాటలు రావు. ఎవరు ఏం అనుకుంటారో అని ఆమె తండ్రి, అస్మిత ట్రాన్స్ పర్సన్‌గా జీవించడం అంటే ఏమిటో తెలియకపోతే భవిష్యత్తులో ఇబ్బంది పడుతుందని ఆమె తల్లి ఆందోళన చెందుతున్నారు.",
              "or": "ସେମାନେ କିଛି କହିପାରନ୍ତି ନାହିଁ | ଲୋକମାନେ ଯାହା କହିପାରନ୍ତି ସେଥିପାଇଁ ତାଙ୍କ ପିତା ଚିନ୍ତିତ ଥିବାବେଳେ ତାଙ୍କ ମା ତାଙ୍କ ଭବିଷ୍ୟତ ପାଇଁ ଚିନ୍ତିତ ଅଛନ୍ତି, ବିଶେଷ କରି ଅସ୍ମିତାଙ୍କ ପାଇଁ ଜଣେ ଟ୍ରାନ୍ସ ବ୍ୟକ୍ତି ଭାବରେ ବଞ୍ଚିବାର ଅର୍ଥ ସେ ଜାଣି ନାହାଁନ୍ତି |",
              "as": "তেওঁলোকে কি কব ভাবি পোৱা নাই। তাইৰ দেউতাকে মানুহে কি ক'ব পাৰে তাক লৈ চিন্তিত, আনহাতে তাইৰ মাক তাইৰ ভৱিষ্যতৰ বিষয়ে চিন্তিত, বিশেষকৈ তেওঁ নাজানে যে অস্মিতাৰ বাবে এজন ট্ৰেন্স ব্যক্তি হিচাপে জীয়াই থকাৰ অৰ্থ কি।",
              "gu": "આ શબ્દોના નુકસાનમાં છે.તેના પિતા આ વાતથી ચિંતિત છે કે લોગો શું કહી શકે છે, જ્યારે તેમની માતા પોતાના ભવિષ્યને લઈ ચિંતિત છે, ખાસ એટલા માટે તે જાણતી નથી કે અસ્મિતાના ટ્રાન્સ વ્યક્તિના સ્વરૂપમાં રહેવાનો અર્થ શું છે"
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_5_3",
              "hi": "C3_5_3",
              "ka": "C3_5_3",
              "te": "C3_5_3",
              "or": "C3_5_3",
              "as": "C3_5_3",
              "gu": "C3_5_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is worried about this reaction, and she doesn’t know how she will handle her parents and their concerns. Nobody talks in the house for two days.",
              "hi": "अस्मिता इस प्रतिक्रिया के बारे में चिंतित है, और वह नहीं जानती कि वह अपने माता-पिता और उनकी चिंताओं को कैसे संभालेगी। दो दिनों के लिए घर में कोई बात नहीं करता है।",
              "ka": "ಈ ಪ್ರತಿಕ್ರಿಯೆಯ ಬಗ್ಗೆ ಅಸ್ಮಿತಾ ಚಿಂತಿತರಾಗಿದ್ದಾರೆ ಮತ್ತು ಆಕೆಯ ಪೋಷಕರು ಮತ್ತು ಅವರ ಕಾಳಜಿಯನ್ನು ಹೇಗೆ ನಿಭಾಯಿಸುತ್ತಾರೆ ಎಂದು ತಿಳಿದಿಲ್ಲ. ಎರಡು ದಿನ ಮನೆಯಲ್ಲಿ ಯಾರೂ ಮಾತನಾಡುವುದಿಲ್ಲ.",
              "te": "వాళ్ళ రియాక్షన్ చూసి అస్మిత ఆందోళన చెందుతోంది మరియు తన తల్లిదండ్రులను మరియు వారి ఆందోళనలను ఎలా హ్యాండిల్ చేయాలో ఆమెకు తెలియట్లేదు. రెండు రోజులు ఇంట్లో ఎవరూ మాట్లాడరు.",
              "or": "ଅସ୍ମିତା ଏହି ପ୍ରତିକ୍ରିୟା ପାଇଁ ଚିନ୍ତିତ, ଏବଂ ସେ ଜାଣନ୍ତି ନାହିଁ ଯେ ସେ କିପରି ତାଙ୍କ ପିତାମାତା ଏବଂ ସେମାନଙ୍କର ଚିନ୍ତାଧାରାକୁ ସମ୍ଭାଳିବେ | ଦୁଇ ଦିନ ପର୍ଯ୍ୟନ୍ତ ଘରେ କେହି କଥାବାର୍ତ୍ତା କରନ୍ତି ନାହିଁ |",
              "as": "অস্মিতা এই প্ৰতিক্ৰিয়াৰ বিষয়ে চিন্তিত, আৰু তাই নাজানে যে তাই তাইৰ মাক-দেউতাক আৰু তেওঁলোকৰ উদ্বেগবোৰ কেনেদৰে নিয়ন্ত্ৰণ কৰিব। ঘৰত দুদিন ধৰি কোনেও কথা নকয়।",
              "gu": "અસ્મિતા આ પ્રતિક્રિયાથી ચિંતિત છે, અને તે જાણતા નથી કે પોતાના માતા-પિતા તથા તેમની ચિંતાઓ કેવી રીતે સંભાળી લેશે. બે દિવસ ઘરમાં કોઈ વાત કરી શકતા નથી."
            }
          }
        ],
        "page21": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_5_3b",
              "hi": "C3_5_3B",
              "ka": "C3_5_3b",
              "te": "C3_5_3b",
              "or": "C3_5_3b",
              "as": "C3_5_3b",
              "gu": "C3_5_3b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She decides to get in touch with her support group for advice. They ask her to give her parents some time. They also suggest bringing her family to one of the support group meetings to answer their concerns and questions.",
              "hi": "वह सुझाव के लिए अपने समर्थन समूह के संपर्क में आने का निश्चय करती है। वे उसे अपने माता-पिता को कुछ समय देने के लिए कहते हैं। वे उनकी चिंताओं और प्रश्नों के उत्तर देने के लिए अपने परिवार को समर्थन समूह की किसी एक बैठक में लाने का सुझाव देते हैं।",
              "ka": "ಸಲಹೆಗಾಗಿ ತನ್ನ ಬೆಂಬಲ ಗುಂಪಿನೊಂದಿಗೆ ಸಂಪರ್ಕದಲ್ಲಿರಲು ಅವಳು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಅವರು ತಮ್ಮ ಪೋಷಕರಿಗೆ ಸ್ವಲ್ಪ ಸಮಯ ನೀಡುವಂತೆ ಕೇಳುತ್ತಾರೆ. ಅವರ ಕಾಳಜಿ ಮತ್ತು ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಲು ಬೆಂಬಲ ಗುಂಪಿನ ಸಭೆಗಳಲ್ಲಿ ಒಂದಕ್ಕೆ ಅವರ ಕುಟುಂಬವನ್ನು ಕರೆತರುವಂತೆ ಅವರು ಸಲಹೆ ನೀಡುತ್ತಾರೆ.",
              "te": "ఆమె సలహా కోసం తన సపోర్ట్ గ్రూప్‌ని సంప్రదించాలని నిర్ణయించుకుంది. తన తల్లిదండ్రులకు కొంత సమయం ఇవ్వాలని వారు చెప్తారు. వారి ఆందోళనలు మరియు ప్రశ్నలకు సమాధానమివ్వడానికి ఆమె కుటుంబ సభ్యులను సపోర్ట్ గ్రూప్ మీటింగ్‌లలో ఒకదానికి తీసుకురావాలని కూడా వారు సూచిస్తారు.",
              "or": "ପରାମର୍ଶ ପାଇଁ ସେ ତାଙ୍କ ସମର୍ଥନ ସମୁଦାୟ ସହିତ ଯୋଗାଯୋଗ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି | ସେମାନେ ତାଙ୍କୁ ପିତାମାତାଙ୍କୁ କିଛି ସମୟ ଦେବାକୁ କୁହନ୍ତି | ସେମାନେ ସେମାନଙ୍କର ଚିନ୍ତା ଏବଂ ପ୍ରଶ୍ନର ଉତ୍ତର ଦେବାକୁ ତାଙ୍କ ପରିବାରକୁ ଏକ ସମର୍ଥନ ସମୁଦାୟ ବୈଠକକୁ ଆଣିବାକୁ ମଧ୍ୟ ପରାମର୍ଶ ଦିଅନ୍ତି |",
              "as": "তাই পৰামৰ্শৰ বাবে তাইৰ সমৰ্থন গোটৰ সৈতে যোগাযোগ কৰাৰ সিদ্ধান্ত লয়। তেওঁলোকে তাইক তাইৰ মাক দেউতাকক কিছু সময় দিবলৈ কয়। তেওঁলোকে তাইৰ পৰিয়ালক তেওঁলোকৰ উদ্বেগ আৰু প্ৰশ্নৰ উত্তৰ দিবলৈ সমৰ্থন গোটৰ এখন সভালৈ অনাৰ পৰামৰ্শ দিয়ে।",
              "gu": "આ સલાહ માટે પોતાની સહાયતા સમૂહથી સંપર્ક કરવાનો નિર્ણય કરે છે. તે પોતાના માતા-પિતાને કેટલાક સમય આપવા કહે છે. તે પોતાના પરિવારને તેમની ચિંતાઓ તથા પ્રશ્નોના જવાબ આપવા માટે સહાયતા સમૂહની બેઠકોમાંથી એકમાં રજૂ કરવાનું સૂચન આપે છે."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "UnderstandingSelf",
              "hi": "स्वयं को समझना",
              "ka": "ಅಂಡರ್ಸ್ಟ್ಯಾಂಡಿಂಗ್ ಸೆಲ್ಫ್",
              "te": "అండర్ స్టాండింగ్ సెల్ఫ్",
              "or": "UnderstandingSelf",
              "as": "নিজকে বুজা",
              "gu": "જાતે જ સમજણ કેળવવી"
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "C3_5_4",
              "hi": "C3_5_4",
              "ka": "C3_5_4",
              "te": "C3_5_4",
              "or": "C3_5_4",
              "as": "C3_5_4",
              "gu": "C3_5_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Meanwhile, the day of the on-campus presentation is coming closer. Asmita decides to focus on her studies and worry about this later.",
              "hi": "इस बीच, कैंपस प्रस्तुति का दिन करीब आ रहा है। अस्मिता ने अपने अध्ययन पर ध्यान केंद्रित करने और बाद में चिंता करने का निश्चय किया।",
              "ka": "ಏತನ್ಮಧ್ಯೆ, ಕ್ಯಾಂಪಸ್ ಪ್ರಸ್ತುತಿಯ ದಿನ ಹತ್ತಿರ ಬರುತ್ತಿದೆ. ಅಸ್ಮಿತಾ ತನ್ನ ಅಧ್ಯಯನದತ್ತ ಗಮನ ಹರಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ ಮತ್ತು ನಂತರ ಈ ಬಗ್ಗೆ ಚಿಂತಿಸುತ್ತಾಳೆ.",
              "te": "ఇంతలో క్యాంపస్ లో ప్రజెంటేషన్ రోజు దగ్గర పడుతోంది. అస్మిత తన చదువుపై దృష్టి పెట్టాలని మరియు దీని గురించి తరువాత ఆలోచించాలని నిర్ణయించుకుంది.",
              "or": "ଏହି ମଧ୍ଯରେ, ଅନ୍‌-କ୍ୟାମ୍ପସରେ ଉପସ୍ଥାପନା ଦିନ ପାଖେଇ ଆସୁଛି | ଅସ୍ମିତା ତାଙ୍କ ଅଧ୍ୟୟନ ଉପରେ ଧ୍ୟାନ ଦେବାକୁ ଏବଂ ପରେ ଏହା ବିଷୟରେ ଚିନ୍ତା କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
              "as": "ইফালে, কেম্পাচ প্ৰেজেণ্টেচনৰ দিনটো ওচৰ চাপি আহিছে। অস্মিতাই তাইৰ পঢ়া-শুনাত মনোনিবেশ কৰাৰ সিদ্ধান্ত লয় আৰু পিছত এই বিষয়ে চিন্তা কৰিম বুলি ভাবে।",
              "gu": "દરમિયાન, કેમ્પસમાં રજૂઆતનો દિવસ નજીક આવી રહ્યો છે. અસ્મિતા તેના અભ્યાસ પર ધ્યાન કેન્દ્રિત કરવાનું નક્કી કરે છે અને પછીથી આ વિશે ચિંતા કરે છે."
            }
          }
        ]
      },
      "scene14": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_1_1",
              "hi": "C4_1_1",
              "ka": "C4_1_1",
              "te": "C4_1_1",
              "or": "C4_1_1",
              "as": "C4_1_1",
              "gu": "C4_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita has been working hard on her thesis project. After days of working tirelessly, the day of the presentation of her project is finally here.",
              "hi": "अस्मिता अपने थीसिस प्रोजेक्ट पर कड़ी मेहनत कर रही है। अथक रूप से काम करने के दिनों के बाद, उनके प्रोजेक्ट की प्रस्तुति का दिन अंततः आ जाता है।",
              "ka": "ಅಸ್ಮಿತಾ ತನ್ನ ಪ್ರಬಂಧ ಯೋಜನೆಯಲ್ಲಿ ಶ್ರಮಿಸುತ್ತಿದ್ದಾರೆ. ದಿನಗಟ್ಟಲೆ ದಣಿವರಿಯದೆ ದುಡಿದ ನಂತರ, ಅವಳ ಪ್ರಾಜೆಕ್ಟ್‌ನ ಪ್ರಸ್ತುತಿಯ ದಿನ ಕೊನೆಗೂ ಬಂದಿದೆ.",
              "te": "అస్మిత తన థీసిస్ ప్రాజెక్ట్ కోసం చాలా కష్టపడుతోంది. రోజుల తరబడి అవిశ్రాంతంగా శ్రమించిన తర్వాత ఆమె ప్రాజెక్ట్‌ను ప్రదర్శించే రోజు ఎట్టకేలకు వచ్చింది.",
              "or": "ଅସ୍ମିତା ତାଙ୍କ ଥିଏସ୍ ପ୍ରୋଜେକ୍ଟରେ କଠିନ ପରିଶ୍ରମ କରୁଛନ୍ତି | ଅନେକ ଦିନଗୁଡିକର ଅକ୍ଲାନ୍ତ ପରିଶ୍ରମ ପରେ, ତାଙ୍କ ପ୍ରୋଜେକ୍ଟର ଉପସ୍ଥାପନା ଦିନ ଶେଷରେ ଆସିକି ପହଞ୍ଚିଲା |",
              "as": "অস্মিতাই তাইৰ গৱেষণাপত্ৰৰ প্ৰকল্পত কঠোৰ পৰিশ্ৰম কৰি আছে। কেইবাদিনো অক্লান্ত পৰিশ্ৰম কৰাৰ পিছত, তাইৰ প্ৰকল্পটো প্ৰেজেণ্টেচন কৰাৰ দিনটো অৱশেষত পালেহি।",
              "gu": "અસ્મિતા પોતાના થિસિસ પ્રોજેક્ટ અંગે સખત મહેનત કરી રહ્યા છે. અનેક દિવસો સુધી સતત પરિશ્રમ કર્યાં બાદ, છેવટે તેના પ્રોજેક્ટની રજૂઆતનો દિવસ આવી ગયો છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_1_2",
              "hi": "C4_1_2",
              "ka": "C4_1_2",
              "te": "C4_1_2",
              "or": "C4_1_2",
              "as": "C4_1_2",
              "gu": "C4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita and Roshni are travelling by bus to Hyderabad for her on-campus presentation.",
              "hi": "अस्मिता और रोशनी अपने कैंपस प्रस्तुति के लिए हैदराबाद से बस से यात्रा कर रहे हैं।",
              "ka": "ಅಸ್ಮಿತಾ ಮತ್ತು ರೋಶ್ನಿ ತನ್ನ ಕ್ಯಾಂಪಸ್ ಪ್ರಸ್ತುತಿಗಾಗಿ ಹೈದರಾಬಾದ್‌ಗೆ ಬಸ್‌ನಲ್ಲಿ ಪ್ರಯಾಣಿಸುತ್ತಿದ್ದಾರೆ.",
              "te": "అస్మిత మరియు రోష్ని తన క్యాంపస్ ప్రదర్శన కోసం హైదరాబాద్‌కు బస్సులో బయలుదేరారు.",
              "or": "ଅସ୍ମିତା ଏବଂ ରୋଶନି ତାଙ୍କ ଅନ୍‌-କ୍ୟାମ୍ପସରେ ଉପସ୍ଥାପନା ପାଇଁ ବସ୍ ଯୋଗେ ହାଇଦ୍ରାବାଦ ଅଭିମୁଖେ ଯାତ୍ରା କରନ୍ତି |",
              "as": "অস্মিতা আৰু ৰোশনীয়ে তাইৰ কেম্পাচ প্ৰেজেণ্টেচনৰ বাবে বাছেৰে হায়দৰাবাদলৈ গৈ আছে।",
              "gu": "અસ્મિતા અને રોશની તેમના કેમ્પસમાં પ્રસ્તુતિ માટે બસમાં હૈદરાબાદ જઈ રહી છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_1_3",
              "hi": "C4_1_3",
              "ka": "C4_1_3",
              "te": "C4_1_3",
              "or": "C4_1_3",
              "as": "C4_1_3",
              "gu": "C4_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The bus is more crowded than usual, and they are met with many stares. Roshni wonders if this is how Asmita is stared at every time she steps out.",
              "hi": "बस में सामान्य से अधिक भीड़ होती है, और उन्हें अनेक लोग घूरते हैं। रोशनी सोचती है कि क्या अस्मिता जब भी बाहर निकलती है तो उसे ऐसे ही देखा जाता है।",
              "ka": "ಬಸ್ಸು ಸಾಮಾನ್ಯಕ್ಕಿಂತ ಹೆಚ್ಚು ಜನಸಂದಣಿಯಿಂದ ಕೂಡಿದೆ ಮತ್ತು ಅವರು ಅನೇಕ ದಿಟ್ಟಿಸುವಿಕೆಯೊಂದಿಗೆ ಭೇಟಿಯಾಗುತ್ತಾರೆ. ಪ್ರತಿ ಬಾರಿಯೂ ಅಸ್ಮಿತಾಳನ್ನು ದಿಟ್ಟಿಸಿ ನೋಡುವುದು ಹೀಗೆಯೇ ಎಂದು ರೋಶ್ನಿ ಆಶ್ಚರ್ಯ ಪಡುತ್ತಾಳೆ.",
              "te": "బస్సు సాధారణం కంటే ఎక్కువ రద్దీగా ఉంది, మరియు వారు ఆమెను తదేకంగా చూస్తున్నారు. అస్మిత బయటకి అడుగుపెట్టినప్పుడల్లా ఇలాగే చూస్తారా అని రోష్ని ఆశ్చర్యపోతుంది.",
              "or": "ବସ୍ ସାଧାରଣ ଅପେକ୍ଷା ଅଧିକ ଭିଡ଼ ହୋଇଥାଏ, ଏବଂ ସେମାନେ ଅନେକ ଚାହାଣୀ ସହିତ ଭେଟି ହୁଅନ୍ତି | ରୋଶନି ବିସ୍ମିତ ଯେ ପ୍ରତ୍ୟେକ ଥର ବାହାରକୁ ଯିବାବେଳେ ଏହିପରି ଅସ୍ମିତାଙ୍କୁ ଚାହାଁ ଯାଏ କି |",
              "as": "বাছখনত স্বাভাৱিকতকৈ বেছি ভিৰ হয়, আৰু তেওঁলোকক বহুতো চকুৱে লক্ষ্য কৰে। ৰোশনীয়ে আচৰিত হয় যে অস্মিতাই বাহিৰলৈ ওলাই অহাৰ প্ৰতিবাৰেই এনেদৰেই লক্ষ্য কৰি থাকে নেকি।",
              "gu": "બસમાં સામાન્ય કરતાં વધારે ભીડ હોય છે, અને તે કોઈ સ્ટાર્સથી મળે છે. રોશની વિચારે છે કે શું અસ્મિતા જ્યારે પણ બહાર નિકળે છે તો તેને એ પ્રમાણે જ જોવા મળે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_1_4",
              "hi": "C4_1_4",
              "ka": "C4_1_4",
              "te": "C4_1_4",
              "or": "C4_1_4",
              "as": "C4_1_4",
              "gu": "C4_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The campus is not far from the bus stop, so they decide to walk. Asmita is nervous about her presentation.",
              "hi": "कैंपस बस स्टॉप से ​​बहुत दूर नहीं है, इसलिए वे पैदल चलने का निश्चय करते हैं। अस्मिता उसकी प्रस्तुति के बारे में परेशान है।",
              "ka": "ಕ್ಯಾಂಪಸ್ ಬಸ್ ನಿಲ್ದಾಣದಿಂದ ದೂರದಲ್ಲಿಲ್ಲ, ಆದ್ದರಿಂದ ಅವರು ನಡೆಯಲು ನಿರ್ಧರಿಸುತ್ತಾರೆ. ಅಸ್ಮಿತಾ ತನ್ನ ಪ್ರಸ್ತುತಿಯ ಬಗ್ಗೆ ಆತಂಕಗೊಂಡಿದ್ದಾಳೆ.",
              "te": "క్యాంపస్ బస్టాప్ నుండి చాలా దూరం కాదు, కాబట్టి వారు నడవాలని నిర్ణయించుకున్నారు. అస్మిత తన ప్రెజెంటేషన్ గురించి భయపడుతోంది.",
              "or": "କ୍ୟାମ୍ପସ ବସ୍‌ ଷ୍ଟାଣ୍ଡଠାରୁ ବେଶୀ ଦୂରରେ ନୁହେଁ, ତେଣୁ ସେମାନେ ଚାଲିବାକୁ ସ୍ଥିର କରନ୍ତି | ଅସ୍ମିତା ତାଙ୍କ ଉପସ୍ଥାପନାକୁ ନେଇ ଚିନ୍ତିତ ଅଛନ୍ତି |",
              "as": "কেম্পাচটো বাছ ষ্টপৰ পৰা বেছি দূৰত নহয়, সেয়েহে তেওঁলোকে খোজ কাঢ়িবলৈ সিদ্ধান্ত লয়। অস্মিতা তাইৰ প্ৰেজেণ্টেচনক লৈ উদ্বিগ্ন হৈ পৰিছে।",
              "gu": "કેમ્પસ બસ સ્ટોપથી વધારે દૂર નથી, માટે તે પગે ચાલીને જવાનો નિર્ણય કરે છે. અસ્મિતા પોતાના પ્રોજેક્ટને પ્રેઝન્ટેશનને લઈ ઉદાસ છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_1",
              "hi": "C4_2_1",
              "ka": "C4_2_1",
              "te": "C4_2_1",
              "or": "C4_2_1",
              "as": "C4_2_1",
              "gu": "C4_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Once in the presentation room, Asmita focuses on giving the presentation.",
              "hi": "एक बार प्रस्तुति कक्ष में, अस्मिता प्रस्तुति देने पर ध्यान केंद्रित करती है।",
              "ka": "ಪ್ರೆಸೆಂಟೇಶನ್ ರೂಮಿನಲ್ಲಿ ಒಮ್ಮೆ ಅಸ್ಮಿತಾ ಪ್ರೆಸೆಂಟೇಶನ್ ನೀಡುವತ್ತ ಗಮನ ಹರಿಸುತ್ತಾಳೆ.",
              "te": "ప్రెజెంటేషన్ గదిలోకి వెళ్ళాక, అస్మిత ప్రెజెంటేషన్ ఇవ్వడంపై దృష్టి పెడుతుంది.",
              "or": "ଥରେ ଉପସ୍ଥାପନା ରୁମ୍‌ରେ, ଅସ୍ମିତା ଉପସ୍ଥାପନା ଦେବା ଉପରେ ଧ୍ୟାନ ଦିଅନ୍ତି |",
              "as": "এইবাৰ প্ৰেজেণ্টেচন ৰুমত , অস্মিতাই প্ৰেজেণ্টেচনটো দিয়াত গুৰুত্ব দিয়ে।",
              "gu": "એક વખત પ્રેઝન્ટેશન રૂમમાં અસ્મિતા પ્રેઝન્ટેશન આપવા માટે ફોકસ કરે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_2//",
              "hi": "C4_2_2 //",
              "ka": "C4_2_2//",
              "te": "C4_2_2//",
              "or": "C4_2_2//",
              "as": "C4_2_2//",
              "gu": "C4_2_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Before the presentation, she makes a small announcement.",
              "hi": "प्रस्तुति से पहले, वह एक छोटी घोषणा करती है।",
              "ka": "ಪ್ರಸ್ತುತಿಯ ಮೊದಲು, ಅವಳು ಸಣ್ಣ ಘೋಷಣೆಯನ್ನು ಮಾಡುತ್ತಾಳೆ.",
              "te": "ప్రెజెంటేషన్ కి ముందు, ఆమె ఒక చిన్న ప్రకటన చేస్తుంది.",
              "or": "ଉପସ୍ଥାପନା ପୂର୍ବରୁ, ସେ ଏକ ଛୋଟ ଘୋଷଣା କରନ୍ତି |",
              "as": "প্ৰেজেণ্টেচনৰ আগতে, তাই এটা সৰু ঘোষণা কৰে।",
              "gu": "રજૂઆત કરતાં પહેલાં તે એક નાની જાહેરાત કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Asmita",
              "hi": "अस्मिता",
              "ka": "ಅಸ್ಮಿತಾ",
              "te": "అస్మిత",
              "or": "ଅସ୍ମିତା",
              "as": "অস্মিতা",
              "gu": "અસ્મિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Good morning to everyone present here. As I worked on this thesis, I was forced to come to terms with who I am and who I want to be. I want to inform everyone here, especially my professors, that my name is now Asmita, and my preferred pronouns are ‘she/her’. I hope you will accommodate this change.",
              "hi": "यहां उपस्थित सभी लोगों को सुप्रभात। जब मैंने इस थीसिस पर काम किया, तो मुझे यह मानने के लिए मजबूर होना पड़ा कि मैं कौन हूं और मैं कौन बनना चाहती हूं। मैं यहां सभी को, विशेष रूप से अपने प्रोफेसरों को सूचित करना चाहती हूं कि मेरा नाम अब अस्मिता है, और मेरे पसंदीदा सर्वनाम 'वह / उसके' हैं। मुझे आशा है कि आप इस परिवर्तन को स्वीकार करेंगे।",
              "ka": "ಇಲ್ಲಿ ಉಪಸ್ಥಿತರಿರುವ ಎಲ್ಲರಿಗೂ ಶುಭೋದಯ. ನಾನು ಈ ಪ್ರಬಂಧದಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿರುವಾಗ, ನಾನು ಯಾರು ಮತ್ತು ನಾನು ಯಾರಾಗಬೇಕು ಎಂಬುದಕ್ಕೆ ಬರಲು ನಾನು ಒತ್ತಾಯಿಸಲ್ಪಟ್ಟೆ. ನಾನು ಇಲ್ಲಿ ಎಲ್ಲರಿಗೂ, ವಿಶೇಷವಾಗಿ ನನ್ನ ಪ್ರಾಧ್ಯಾಪಕರಿಗೆ, ನನ್ನ ಹೆಸರು ಈಗ ಅಸ್ಮಿತಾ ಮತ್ತು ನನ್ನ ಆದ್ಯತೆಯ ಸರ್ವನಾಮಗಳು 'ಅವಳು / ಅವಳನ್ನು' ಎಂದು ತಿಳಿಸಲು ಬಯಸುತ್ತೇನೆ. ಈ ಬದಲಾವಣೆಯನ್ನು ನೀವು ಸರಿಹೊಂದಿಸುತ್ತೀರಿ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "ఇక్కడ ఉన్న ప్రతి ఒక్కరికీ శుభోదయం. నేను ఈ థీసిస్‌పై పని చేస్తున్నప్పుడు, నేను ఎవరు మరియు నేను ఏం కావాలనుకుంటున్నాను అనే నిర్ణయానికి వచ్చాను. ఇక్కడ ఉన్న ప్రతి ఒక్కరికీ ముఖ్యంగా నా ప్రొఫెసర్‌లకు నా పేరు ఇప్పుడు అస్మిత మరియు నేను ఇష్టపడే సర్వనామాలు 'ఆమె/తను' అని తెలియజేయాలనుకుంటున్నాను. మీరు ఈ మార్పుకు అనుగుణంగా ఉంటారని ఆశిస్తున్నాను.",
              "or": "ଏଠାରେ ଉପସ୍ଥିତ ସମସ୍ତଙ୍କୁ ଶୁଭ ସକାଳ | ଯେପରି ମୁଁ ଏହି ଥିସିସ୍‌ ଉପରେ କାମ କରୁଛି, ମୁଁ କିଏ ଏବଂ ମୁଁ କିଏ ହେବାକୁ ଚାହେଁ ସହିତ ମୁଁ ସର୍ତ୍ତକୁ ଆସିବାକୁ ବାଧ୍ୟ ହେଲି | । ମୁଁ ଏଠାରେ ସମସ୍ତଙ୍କୁ, ବିଶେଷକରି ମୋର ପ୍ରଫେସରମାନଙ୍କୁ ଜଣାଇବାକୁ ଚାହୁଁଛି, ଯେ ବର୍ତ୍ତମାନ ମୋର ନାମ ଅସ୍ମିତା, ଏବଂ ମୋର ପସନ୍ଦିତ ସର୍ବନାମଗୁଡ଼ିକ ହେଉଛି “ସେ/ତାଙ୍କ” | ମୁଁ ଆଶା କରୁଛି ଆପଣ ଏହି ପରିବର୍ତ୍ତନକୁ ଖାପ ଖୁଆଇ ଚାଲିବେ |",
              "as": "ইয়াত উপস্থিত সকলোকে শুপ্ৰভাত। যেতিয়া মই এই গৱেষণাপত্ৰত কাম কৰিছিলো, মই কোন আৰু মই কোন হ'ব বিচাৰো সেই বিষয়ে বুজাবুজি কৰিবলৈ বাধ্য হৈছিলোঁ। মই ইয়াত সকলোকে, বিশেষকৈ মোৰ অধ্যাপকসকলক জনাব বিচাৰো যে মোৰ নাম এতিয়া অস্মিতা, আৰু মোৰ পছন্দৰ সৰ্বনাম হৈছে 'তাই/তাইৰ'। মই আশা কৰোঁ আপোনাপোকে এই পৰিৱৰ্তনটোত সামঞ্জস্য কৰিব।",
              "gu": "અહીં ઉપસ્થિતિ તમામ લોકોને અસર કરે છે.જ્યારે હું આ થિસિસ ઉપર કામ કરી છીએ તો મને એવું માનવા માટે મજબૂર થવું પડે છે કે હું કોણ છું અને હું કોણ બનવા ઈચ્છું છું. હું અહીં તમામને ખાસ રીતે પોતાના પ્રોફેસરોને માહિતગાર કરવા ઈચ્છું છે કે મારું નામ હવે અસ્મિતા છે, અને મારી પસંદગીનું સર્વનામ'તે/તેણીની' છે. મને આશા છે કે તમે આ પરિવર્તનને સ્વીકારશો."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_3",
              "hi": "C4_2_3",
              "ka": "C4_2_3",
              "te": "C4_2_3",
              "or": "C4_2_3",
              "as": "C4_2_3",
              "gu": "C4_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She passes her thesis around for the professors to see. She then gives a detailed explanation of the process of conducting research.",
              "hi": "वह प्रोफेसरों के देखने के लिए अपनी थीसिस उन्हें देती है। फिर वह शोध करने की प्रक्रिया की विस्तृत व्याख्या देती है।",
              "ka": "ಪ್ರೊಫೆಸರ್‌ಗಳು ನೋಡುವಂತೆ ಅವಳು ತನ್ನ ಪ್ರಬಂಧವನ್ನು ರವಾನಿಸುತ್ತಾಳೆ. ನಂತರ ಅವರು ಸಂಶೋಧನೆ ನಡೆಸುವ ಪ್ರಕ್ರಿಯೆಯ ವಿವರವಾದ ವಿವರಣೆಯನ್ನು ನೀಡುತ್ತಾರೆ.",
              "te": "ప్రొఫెసర్లు చూసేందుకు ఆమె తన థీసిస్‌ను పాస్ చేసింది. ఆమె రీసర్చ్ చేసిన విధానం గురించి వివరంగా చెప్తుంది.",
              "or": "ପ୍ରଫେସରମାନେ ଦେଖିବା ପାଇଁ ସେ ତାଙ୍କର ଥିସିସ୍‌କୁ ପାସ୍ କରନ୍ତି | ତା’ପରେ ସେ ଅନୁସନ୍ଧାନ କରିବାର ପ୍ରକ୍ରିୟାର ଏକ ବିସ୍ତୃତ ବ୍ୟାଖ୍ୟା ପ୍ରଦାନ କରନ୍ତି |",
              "as": "তাই তাইৰ থেচিছখন অধ্যাপকসকলে চাবলৈ চাৰিওফালে পাৰ কৰি দিয়ে। তাই তেতিয়া গৱেষণা কৰাৰ প্ৰক্ৰিয়াটোৰ বিতং ব্যাখ্যা দিয়ে।",
              "gu": "એવા પ્રોફેસરોને દેખાડવા માટે પોતાના થિસિસ પાસ કરે છે. ત્યારબાદ તે તપાસની પ્રક્રિયાની વિગતવાર વિતરણ આપે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_4",
              "hi": "C4_2_4",
              "ka": "C4_2_4",
              "te": "C4_2_4",
              "or": "C4_2_4",
              "as": "C4_2_4",
              "gu": "C4_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita speaks about the problems faced by the community and provides a very close and detailed analysis of the results.",
              "hi": "अस्मिता समुदाय के सामने आने वाली समस्याओं के बारे में बोलती है और परिणामों का बहुत करीबी और विस्तृत विश्लेषण प्रदान करती है।",
              "ka": "ಅಸ್ಮಿತಾ ಸಮುದಾಯವು ಎದುರಿಸುತ್ತಿರುವ ಸಮಸ್ಯೆಗಳ ಬಗ್ಗೆ ಮಾತನಾಡುತ್ತಾರೆ ಮತ್ತು ಫಲಿತಾಂಶಗಳ ಅತ್ಯಂತ ನಿಕಟ ಮತ್ತು ವಿವರವಾದ ವಿಶ್ಲೇಷಣೆಯನ್ನು ಒದಗಿಸುತ್ತದೆ",
              "te": "అస్మిత సంఘం ఎదుర్కొంటున్న సమస్యల గురించి మాట్లాడుతుంది మరియు ఫలితాల గురించి చాలా వివరణాత్మక విశ్లేషణను అందిస్తుంది.",
              "or": "ସମୁଦାୟ ସମ୍ମୁଖୀନ ହେଉଥିବା ସମସ୍ୟାଗୁଡିକ ବିଷୟରେ ଅସ୍ମିତା କହନ୍ତି ଏବଂ ଫଳାଫଳଗୁଡିକର ଅତି ନିକଟ ଏବଂ ବିସ୍ତୃତ ବିଶ୍ଳେଷଣ ପ୍ରଦାନ କରନ୍ତି |",
              "as": "অস্মিতাই সম্প্ৰদায়টোৱে সন্মুখীন হোৱা সমস্যাবোৰৰ বিষয়ে কয় আৰু ফলাফলবোৰৰ এক অতি নিবিড় আৰু বিতং বিশ্লেষণ আগবঢ়ায়।",
              "gu": "અસ્મિતા સમુદાયની સામે આવતી સમસ્યાઓ અંગે હું બોલુ છું અને પરિણામોને ઘણા નજીકથી અને વિસ્તૃત વિશ્લેષણ રજૂ કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_5",
              "hi": "C4_2_5",
              "ka": "C4_2_5",
              "te": "C4_2_5",
              "or": "C4_2_5",
              "as": "C4_2_5",
              "gu": "C4_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She mentions her own experience in college and tells the professors why she understands the problem that transgender people face.",
              "hi": "वह कॉलेज में अपने स्वयं के अनुभव का उल्लेख करती है और प्रोफेसरों को बताती है कि वह उस समस्या को क्यों समझती है जिसका सामना ट्रांसजेंडर लोग करते हैं।",
              "ka": "ಅವಳು ಕಾಲೇಜಿನಲ್ಲಿನ ಸ್ವಂತ ಅನುಭವವನ್ನು ಉಲ್ಲೇಖಿಸುತ್ತಾಳೆ ಮತ್ತು ಟ್ರಾನ್ಸ್ಜೆಂಡರ್ಗಳು ಎದುರಿಸುತ್ತಿರುವ ಸಮಸ್ಯೆಯನ್ನು ಅವಳು ಏಕೆ ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದಾಳೆಂದು ಪ್ರಾಧ್ಯಾಪಕರಿಗೆ ಹೇಳುತ್ತಾಳೆ.",
              "te": "కాలేజీలో తనకున్న అనుభవాన్ని చెప్తూనే, ట్రాన్స్‌జెండర్లు ఎదుర్కొనే సమస్యను తాను ఎలా అర్థం చేసుకుందో ప్రొఫెసర్‌లకు చెప్పింది.",
              "or": "ସେ କଲେଜରେ ନିଜର ଅଭିଜ୍ଞତା ବିଷୟରେ ଉଲ୍ଲେଖ କରନ୍ତି ଏବଂ ଟ୍ରାନ୍ସଜେଣ୍ଡର୍‌ ଲୋକମାନେ ସମ୍ମୁଖୀନ ହେଉଥିବା ସମସ୍ୟାକୁ ସେ କାହିଁକି ବୁଝନ୍ତି ବୋଲି ପ୍ରଫେସରମାନଙ୍କୁ କୁହନ୍ତି |",
              "as": "তাই কলেজত তাইৰ নিজৰ অভিজ্ঞতাৰ কথা উল্লেখ কৰে আৰু অধ্যাপকসকলক কয় যে তাই কিয় ট্ৰেন্সজেণ্ডাৰ লোকসকলে সন্মুখীন হোৱা সমস্যাটো বুজি পায়।",
              "gu": "એવી કોલેજમાં તમારા પોતાના અનુભવનો ઉલ્લેખ કરે છે અને પ્રોફેસર્સને બતાવે છે કે તે એવી સમસ્યાને રજૂ કરે છે કે જેનો ટ્રાન્સલિંગ લોકોએ સામનો કરવો પડે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_6",
              "hi": "C4_2_6",
              "ka": "C4_2_6",
              "te": "C4_2_6",
              "or": "C4_2_6",
              "as": "C4_2_6",
              "gu": "C4_2_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She has experienced what it is like to be a transgender person and want a successful career.",
              "hi": "उसने अनुभव किया है कि एक ट्रांसजेंडर व्यक्ति होना कैसा है और वह एक सफल करियर चाहती है।",
              "ka": "ಲಿಂಗಾಯತ ವ್ಯಕ್ತಿಯಾಗಿರುವುದು ಮತ್ತು ಯಶಸ್ವಿ ವೃತ್ತಿಜೀವನವನ್ನು ಬಯಸುವುದು ಹೇಗೆ ಎಂಬುದನ್ನು ಅವರು ಅನುಭವಿಸಿದ್ದಾರೆ.",
              "te": "ట్రాన్స్జెండర్ వ్యక్తిగా ఉంటూ విజయవంతమైన కెరీర్‌ను కోరుకోవడం ఎలా ఉంటుందో ఆమె అనుభవించింది.",
              "or": "ଜଣେ ଟ୍ରାନ୍ସଜେଣ୍ଡର ବ୍ୟକ୍ତି ହେବା ଭଳି କ’ଣ ସେ ଅନୁଭବ କରିଛନ୍ତି ଏବଂ ଏକ ସଫଳ କ୍ୟାରିଅର୍ ଚାହୁଁଛନ୍ତି |",
              "as": "তাই এজন ট্ৰেন্সজেণ্ডাৰ ব্যক্তি হোৱাটো কেনে অনুভৱ কৰিছে আৰু এটা সফল কেৰিয়াৰ বিচাৰে।",
              "gu": "પોતાના અનુભવ કર્યો છે કે એક ટ્રાન્સલિંગ વ્યક્તિ હોવું કેવું હોય છે અને તે એક સફળ કારકિર્દી ઈચ્છે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_2_7",
              "hi": "C4_2_7",
              "ka": "C4_2_7",
              "te": "C4_2_7",
              "or": "C4_2_7",
              "as": "C4_2_7",
              "gu": "C4_2_7"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The professors are very impressed with Asmita’s work.",
              "hi": "प्रोफेसर अस्मिता के काम से बहुत प्रभावित हैं।",
              "ka": "ಅಸ್ಮಿತಾ ಅವರ ಕೆಲಸದಿಂದ ಪ್ರಾಧ್ಯಾಪಕರು ತುಂಬಾ ಪ್ರಭಾವಿತರಾಗಿದ್ದಾರೆ.",
              "te": "అస్మిత పనికి ప్రొఫెసర్లు చాలా ముగ్ధులయ్యారు.",
              "or": "ଅସ୍ମିତାଙ୍କ କାର୍ଯ୍ୟ ସହିତ ପ୍ରଫେସରମାନେ ବହୁତ ପ୍ରଭାବିତ ହୋଇଥିଲେ |",
              "as": "অধ্যাপকসকল অস্মিতাৰ কামত বহুত প্ৰভাৱিত হৈছে।",
              "gu": "પ્રોફેસર્સ અસ્મિતાના કાર્યથી ખૂબ જ પ્રભાવિત છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_3_1",
              "hi": "C4_3_1",
              "ka": "C4_3_1",
              "te": "C4_3_1",
              "or": "C4_3_1",
              "as": "C4_3_1",
              "gu": "C4_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After the presentation, Prof. Prasidh, who had come to see Asmita’s presentation, comes up to congratulate her.",
              "hi": "प्रस्तुति के बाद, प्रो। प्रसिध, जो अस्मिता की प्रस्तुति को देखने के लिए आए थे, उन्हें बधाई देने के लिए आते हैं।",
              "ka": "ಪ್ರಸ್ತುತಿಯ ನಂತರ, ಅಸ್ಮಿತಾ ಅವರ ಪ್ರಸ್ತುತಿಯನ್ನು ನೋಡಲು ಬಂದಿದ್ದ ಪ್ರೊ.ಪ್ರಸಿದ್ ಅವರನ್ನು ಅಭಿನಂದಿಸಲು ಬರುತ್ತಾರೆ.",
              "te": "ప్రెజెంటేషన్ తర్వాత, అస్మిత ప్రెజెంటేషన్‌ని చూడటానికి వచ్చిన ప్రొఫెసర్ ప్రసిధ్ ఆమెను అభినందించడానికి వచ్చాడు.",
              "or": "ଉପସ୍ଥାପନା ପରେ, ପ୍ରଫେସର ପ୍ରସିଦ୍ଧ, ଯିଏକି ଅସ୍ମିତାଙ୍କ ଉପସ୍ଥାପନା ଦେଖିବାକୁ ଆସିଥିଲେ, ତାଙ୍କୁ ଅଭିନନ୍ଦନ ଜଣାଇବାକୁ ଆସନ୍ତି |",
              "as": "প্ৰেজেণ্টেচনৰ পিছত, অস্মিতাৰ প্ৰেজেণ্টেচন চাবলৈ অহা অধ্যাপক প্ৰসিদ্ধে তাইক অভিনন্দন জনাবলৈ আহিছিল।",
              "gu": "પ્રસ્તુતિ પછી, પ્રો. પ્રસિદ્ધ, જેઓ અસ્મિતાનું પ્રેઝન્ટેશન જોવા આવ્યા હતા, તેઓ તેને અભિનંદન આપવા આવ્યા."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_3_2//",
              "hi": "C4_3_2 //",
              "ka": "C4_3_2//",
              "te": "C4_3_2//",
              "or": "C4_3_2//",
              "as": "C4_3_2//",
              "gu": "C4_3_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Professor Prasidh",
              "hi": "प्रोफेसर प्रसिध",
              "ka": "ಪ್ರೊಫೆಸರ್ ಪ್ರಸಿದ್ಧ್",
              "te": "ప్రొఫెసర్ ప్రసిధ్",
              "or": "ପ୍ରଫେସର ପ୍ରସିଦ୍ଧ",
              "as": "অধ্যাপক প্ৰসিদ্ধ",
              "gu": "પ્રોફેસર પ્રસિદ્ધ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Asmita",
              "hi": "अस्मिता",
              "ka": "ಅಸ್ಮಿತಾ",
              "te": "అస్మిత",
              "or": "ଅସ୍ମିତା",
              "as": "অস্মিতা",
              "gu": "અસ્મિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Well done, Asmita! Your research was in-depth. The work you’ve done is commendable.",
              "hi": "बहुत अच्छा, अस्मिता! आपका शोध गहन था। आपके द्वारा किए गए कार्य सराहनीय हैं।",
              "ka": "ಚೆನ್ನಾಗಿದೆ, ಅಸ್ಮಿತಾ! ನಿಮ್ಮ ಸಂಶೋಧನೆಯು ಆಳವಾಗಿತ್ತು. ನೀವು ಮಾಡಿರುವ ಕೆಲಸ ಶ್ಲಾಘನೀಯ.",
              "te": "బాగా చేసావు, అస్మితా! నువ్వు చాలా లోతుగా రీసర్చ్ చేసావు. మీరు చేసిన పని అభినందనీయం.",
              "or": "ଭଲ କାମ, ଅସ୍ମିତା ! ତୁମର ଗବେଷଣା ଗଭୀର ଥିଲା | ତୁମ କରିଥିବା କାର୍ଯ୍ୟ ପ୍ରଶଂସନୀୟ ଅଟେ |",
              "as": "বঢ়িয়া, অস্মিতা! আপোনাৰ গৱেষণা গভীৰ আছিল। আপুনি কৰা কামটো প্ৰশংসনীয়।",
              "gu": "સારું કર્યું, અસ્મિતા! તમારું સંશોધન વ્યાપક હતું. તમે જે કાર્ય કર્યું છે તે પ્રશંસનિય રહ્યું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Thank you so much, professor!",
              "hi": "बहुत बहुत धन्यवाद, प्रोफेसर!",
              "ka": "ತುಂಬಾ ಧನ್ಯವಾದಗಳು, ಪ್ರೊಫೆಸರ್!",
              "te": "చాలా ధన్యవాదాలు, ప్రొఫెసర్!",
              "or": "ଆପଣଙ୍କୁ ବହୁତ ଧନ୍ୟବାଦ, ପ୍ରଫେସର !",
              "as": "অধ্যাপক, আপোনাক বহুত ধন্যবাদ!",
              "gu": "પ્રોફેસર તમારો આભાર!"
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_3_3////",
              "hi": "C4_3_3 //////",
              "ka": "C4_3_3////",
              "te": "C4_3_3////",
              "or": "C4_3_3////",
              "as": "C4_3_3////",
              "gu": "C4_3_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "So what's next for you? Now that you'll soon get your degree, what do you plan to do?",
              "hi": "तो आपके लिए अगला क्या है? अब जब आपको जल्द ही आपकी डिग्री मिल जाएगी, तो आप क्या करने की योजना बना रही हैं?",
              "ka": "ಹಾಗಾದರೆ ನಿಮ್ಮ ಮುಂದೇನು? ಈಗ ನೀವು ಶೀಘ್ರದಲ್ಲೇ ನಿಮ್ಮ ಪದವಿಯನ್ನು ಪಡೆಯುತ್ತೀರಿ, ನೀವು ಏನು ಮಾಡಲು ಯೋಜಿಸುತ್ತೀರಿ?",
              "te": "మరి తర్వాత సంగతి ఏమిటి? ఇప్పుడు త్వరలో నీ డిగ్రీ వస్తుంది, నువ్వు ఏమి చేయాలని అనుకుంటున్నావు?",
              "or": "ତେବେ ତୁମ ପାଇଁ ପରବର୍ତ୍ତୀ କଣ ? ବର୍ତ୍ତମାନ ଯେ ତୁମେ ଶୀଘ୍ର ତୁମର ଡିଗ୍ରୀ ପାଇବ, ତୁମେ କ’ଣ କରିବାକୁ ଯୋଜନା କରୁଛ ?",
              "as": "তেন্তে এতিয়া আপোনাৰ পৰৱৰ্তী পদক্ষেপ কি? এতিয়া যেতিয়া আপুনি সোনকালেই আপোনাৰ ডিগ্ৰী লাভ কৰিব, আপুনি কি কৰাৰ পৰিকল্পনা কৰিছে?",
              "gu": "તો તમારા માટે હવે આગળ શું છે?હવે તમને જલ્દીથી ડિગ્રી મળી જશે, તમે શું કરવાની યોજના ધરાવો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I would like to work in an organisation that works to better transgender people. I want some experience in the real world. I’ve stayed inside for far too long.",
              "hi": "मैं एक ऐसे संगठन में काम करना चाहूंगी जो  ट्रांसजेंडर लोगों की बेहतरी के लिए काम करे। मुझे वास्तविक दुनिया में कुछ अनुभव चाहिए। मैं बहुत लम्बे समय तक अन्दर रही।",
              "ka": "ನಾನು ಉತ್ತಮ ಟ್ರಾನ್ಸ್ಜೆಂಡರ್ ಜನರಿಗೆ ಕೆಲಸ ಮಾಡುವ ಸಂಸ್ಥೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ಬಯಸುತ್ತೇನೆ. ನಾನು ನೈಜ ಜಗತ್ತಿನಲ್ಲಿ ಸ್ವಲ್ಪ ಅನುಭವವನ್ನು ಬಯಸುತ್ತೇನೆ. ನಾನು ತುಂಬಾ ಹೊತ್ತು ಒಳಗೆ ಇದ್ದೆ.",
              "te": "నేను ట్రాన్స్ జెండర్ వ్యక్తుల బాగు కోసం పనిచేసే సంస్థలో పని చేయాలనుకుంటున్నాను. నాకు వాస్తవ ప్రపంచంలో కొంత అనుభవం కావాలి. నేను చాలా కాలం లోపలే ఉండిపోయాను.",
              "or": "ମୁଁ ଏକ ସଂସ୍ଥାରେ କାମ କରିବାକୁ ଚାହେଁ, ଯାହା ଭଲ ଟ୍ରାନ୍ସଜେଣ୍ଡର ଲୋକଙ୍କୁ ଭଲ ପାଇଁ କାମ କରେ | । ମୁଁ ବାସ୍ତବ ଦୁନିଆରେ କିଛି ଅଭିଜ୍ଞତା ଚାହୁଁଛି | ମୁଁ ବହୁତ ଦିନ ପର୍ଯ୍ୟନ୍ତ ଭିତରେ ରହିଲି |",
              "as": "মই এটা প্ৰতিষ্ঠানত কাম কৰিব বিচাৰো যিয়ে ট্ৰেন্সজেণ্ডাৰ লোকসকলক উন্নত কৰাৰ বাবে কাম কৰে। মই বাস্তৱ পৃথিৱীত কিছু অভিজ্ঞতা বিচাৰো। মই বহুত দীঘলীয়া সময় ৰৈছো।",
              "gu": "હું એક એવા સંગઠનમાં કામ કરવા ઈચ્છું છું કે જે વધારે સારી રીતે ટ્રાન્સલિંગ લોકો માટે કામ કરે. મને વાસ્તવિક દુનિયામાં કેટલાક પ્રમાણમાં અનુભવ હોવો જોઈએ.હું ઘણા સમય સુધી આ સ્થિતિ સાથે જોડાયેલો રહેલો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Have you considered applying for the Transform Fellowship? There you’ll get to take the work you’ve done further and an opportunity to learn and get mentored by expert professionals.",
              "hi": "क्या आपने ट्रांसफॉर्म फेलोशिप के लिए आवेदन करने पर विचार किया है? वहां आपको अपने द्वारा किए गए काम को आगे बढ़ाने और विशेषज्ञ प्रोफेशनल्स द्वारा सीखने और सुझाव लेने का अवसर मिलेगा।",
              "ka": "ಟ್ರಾನ್ಸ್‌ಫಾರ್ಮ್ ಫೆಲೋಶಿಪ್‌ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನೀವು ಪರಿಗಣಿಸಿದ್ದೀರಾ? ಅಲ್ಲಿ ನೀವು ಮುಂದೆ ಮಾಡಿದ ಕೆಲಸವನ್ನು ನೀವು ತೆಗೆದುಕೊಳ್ಳುತ್ತೀರಿ ಮತ್ತು ಪರಿಣಿತ ವೃತ್ತಿಪರರಿಂದ ಕಲಿಯಲು ಮತ್ತು ಮಾರ್ಗದರ್ಶನ ಪಡೆಯುವ ಅವಕಾಶವನ್ನು ಪಡೆಯುತ್ತೀರಿ.",
              "te": "ట్రాన్స్‌ఫార్మ్ ఫెలోషిప్ కోసం అప్లై చేసుకున్నావా? అక్కడ నువ్వు చేసిన పనిని మరింత ముందుకు తీసుకెళ్లవచ్చు మరియు నిపుణుల నుండి గైడెన్స్ పొందే అవకాశాన్ని పొందుతారు.",
              "or": "ତୁମେ ଟ୍ରାନ୍ସଫର୍ମ ଫେଲୋସିପ୍ ପାଇଁ ଆବେଦନ କରିବାକୁ ଚିନ୍ତା କରିଛ କି ? ସେଠାରେ ତୁମେ କରିଥିବା କାର୍ଯ୍ୟକୁ ତୁମେ ଆଗେଇ ନେବାକୁ ପାଇବେ ଏବଂ ବୃତ୍ତିଗତ ବିଶେଷଜ୍ଞମାନଙ୍କ ଦ୍ୱାରା ଶିଖିବା ଏବଂ ପରାମର୍ଶ ପ୍ରାପ୍ତ କରିବାର ଏକ ସୁଯୋଗ ପାଇବେ |",
              "as": "আপুনি ট্ৰান্সফৰ্ম ফেলোশ্বিপৰ বাবে আবেদন কৰাৰ কথা ভাবিছে নেকি? তাত আপুনি আগতে কৰা কামটো কৰিব পাৰিব আৰু বিশেষজ্ঞ পেছাদাৰীসকলৰ দ্বাৰা শিকিবলৈ আৰু পৰামৰ্শ দিয়াৰ সুযোগ পাব।",
              "gu": "શું તમે ટ્રાન્સફોર્મ ફેલોશિપ માટે અરજી કરવા વિચાર કર્યો છે? ત્યા તમારે પોતાના દ્વારા કરવામાં આવેલા કાર્યને આગળ વધારવા અને ખાસ કરીને વ્યવસાયિકો દ્વારા શિખવા તથા સલાહ લેવાની તક મળશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "A fellowship?",
              "hi": "एक फैलोशिप?",
              "ka": "ಫೆಲೋಶಿಪ್?",
              "te": "ఒక ఫెలోషిప్?",
              "or": "ଏକ ଫେଲୋସିପ୍ ?",
              "as": "এটা ফেলোশ্বিপ?",
              "gu": "શું ફેલોશીપ છે?"
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_3_4",
              "hi": "C4_3_4",
              "ka": "C4_3_4",
              "te": "C4_3_4",
              "or": "C4_3_4",
              "as": "C4_3_4",
              "gu": "C4_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Prof. Prasidh explains that a fellowship is a program that offers an opportunity to gain experience and knowledge of working in the social sector.",
              "hi": "प्रो. प्रसिध बताते हैं कि एक फैलोशिप एक ऐसा कार्यक्रम है जो सामाजिक क्षेत्र में काम करने का अनुभव और ज्ञान प्राप्त करने का अवसर प्रदान करता है।",
              "ka": "ಫೆಲೋಶಿಪ್ ಎನ್ನುವುದು ಸಾಮಾಜಿಕ ಕ್ಷೇತ್ರದಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಅನುಭವ ಮತ್ತು ಜ್ಞಾನವನ್ನು ಪಡೆಯಲು ಅವಕಾಶವನ್ನು ನೀಡುವ ಕಾರ್ಯಕ್ರಮವಾಗಿದೆ ಎಂದು ಪ್ರೊ.ಪ್ರಸಿದ್ ವಿವರಿಸುತ್ತಾರೆ.",
              "te": "ఫెలోషిప్ అనేది సామాజిక రంగంలో పనిచేసిన అనుభవం మరియు జ్ఞానాన్ని పొందే అవకాశాన్ని అందించే కార్యక్రమం అని ప్రొఫెసర్ ప్రసిధ్ వివరించారు.",
              "or": "ପ୍ରଫେସର ପ୍ରସିଦ୍ଧ ବ୍ୟାଖ୍ୟା କରନ୍ତି ଯେ ଏକ ଫେଲୋସିପ୍ ହେଉଛି ଏକ କାର୍ଯ୍ୟକ୍ରମ ଯାହା ସାମାଜିକ କ୍ଷେତ୍ରରେ କାର୍ଯ୍ୟ କରିବାର ଅଭିଜ୍ଞତା ଏବଂ ଜ୍ଞାନ ଆହରଣ କରିବାର ସୁଯୋଗ ପ୍ରଦାନ କରେ |",
              "as": "অধ্যাপক প্ৰসিদ্ধে ব্যাখ্যা কৰে যে ফেলোশ্বিপ হৈছে এক কাৰ্যসূচী যি সামাজিক খণ্ডত কাম কৰাৰ অভিজ্ঞতা আৰু জ্ঞান লাভ কৰাৰ সুযোগ প্ৰদান কৰে।",
              "gu": "પ્રોફેસર પ્રસિદ્ધ કહે છે કે ફેલોશિપ એક એવો કાર્યક્રમ છે કે જે સામાજીક ક્ષેત્રમાં કામ કરવાનો અનુભવ અને જાણકારી હાંસલ કરવાની તક પ્રદાન કરે છે."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_3_5//",
              "hi": "C4_3_5 //",
              "ka": "C4_3_5//",
              "te": "C4_3_5//",
              "or": "C4_3_5//",
              "as": "C4_3_5//",
              "gu": "C4_3_5//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Professor Prasidh",
              "hi": "प्रोफेसर प्रसिध",
              "ka": "ಪ್ರೊಫೆಸರ್ ಪ್ರಸಿದ್ಧ್",
              "te": "ప్రొఫెసర్ ప్రసిధ్",
              "or": "ପ୍ରଫେସର ପ୍ରସିଦ୍ଧ",
              "as": "অধ্যাপক প্ৰসিদ্ধ",
              "gu": "પ્રોફેસર પ્રસિદ્ધ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Let me know if you’re applying, I can write a recommendation for you as well.",
              "hi": "यदि आप आवेदन कर रहे हैं तो मुझे बताएं, मैं आपके लिए सिफारिश भी लिख सकता हूं।",
              "ka": "ನೀವು ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಿದ್ದರೆ ನನಗೆ ತಿಳಿಸಿ, ನಾನು ನಿಮಗಾಗಿ ಶಿಫಾರಸನ್ನೂ ಬರೆಯಬಹುದು",
              "te": "నువ్వు అప్లై చేస్తే నాకు చెప్పు, నేను నీ కోసం ఒక రికమెండేషన్ కూడా వ్రాయగలను.",
              "or": "ତୁମେ ଆବେଦନ କରୁଛ କି ମୋତେ ଜଣାଇବ, ମୁଁ ତୁମ ପାଇଁ ଏକ ସୁପାରିଶ ମଧ୍ୟ ଲେଖିପାରେ |",
              "as": "যদি আপুনি আবেদন কৰিছে মোক জনাব, মই আপোনাৰ বাবেও এটা পৰামৰ্শ লিখিব পাৰো।",
              "gu": "જો તમે અરજી કરી રહ્યા છો તો મને જણાવો, હું તમારા માટે એક ભલામણ પણ લખી શકું છું."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_4_1//",
              "hi": "C4_4_1 //",
              "ka": "C4_4_1//",
              "te": "C4_4_1//",
              "or": "C4_4_1//",
              "as": "C4_4_1//",
              "gu": "C4_4_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Asmita is happy about the way the presentation went. But she is still nervous about her parents and Pranav.",
              "hi": "अस्मिता प्रस्तुति के तरीके के बारे में खुश है। लेकिन वह अभी भी अपने माता-पिता और प्रणव के विषय में सोच कर घबरा रही है।",
              "ka": "ಪ್ರಸ್ತುತಿ ಸಾಗಿದ ರೀತಿಗೆ ಅಸ್ಮಿತಾ ಖುಷಿಯಾಗಿದ್ದಾಳೆ. ಆದರೆ ಅವಳು ಇನ್ನೂ ತನ್ನ ಹೆತ್ತವರ ಬಗ್ಗೆ ಮತ್ತು ಪ್ರಣವ್ ಬಗ್ಗೆ ಹೆದರುತ್ತಾಳೆ.",
              "te": "ప్రజెంటేషన్‌ సాగిన తీరుపై అస్మిత సంతోషం వ్యక్తం చేసింది. కానీ ఆమె తన తల్లిదండ్రులు మరియు ప్రణవ్ గురించి ఇప్పటికీ భయపడుతోంది.",
              "or": "ଉପସ୍ଥାପନା ଯେଉଁ ପଥରେ ଗଲା ସେଥିପାଇଁ ଅସ୍ମିତା ଖୁସି ଅଛନ୍ତି | କିନ୍ତୁ ସେ ତଥାପି ନିଜ ପିତାମାତା ଏବଂ ପ୍ରଣବଙ୍କ ବିଷୟରେ ନର୍ଭସ୍‌ ଅଛନ୍ତି |",
              "as": "প্ৰেজেণ্টেচনটো যিধৰণে হৈছিল তাক লৈ অস্মিতা সুখী। কিন্তু তাই এতিয়াও তাইৰ মাক দেউতাক আৰু প্ৰণৱক লৈ উদ্বিগ্ন হৈ আছে।",
              "gu": "પ્રેઝન્ટેશનની પદ્ધતિથી અસ્મિતા ઘણી ખુશ છે. જોકે તે હજુ પણ તેના માતા-પિતા તથા પ્રણવને લઈ ગભરાયેલ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Pranav",
              "hi": "प्रणव",
              "ka": "ಪ್ರಣವ್",
              "te": "ప్రణవ్",
              "or": "ପ୍ରଣବ",
              "as": "প্ৰণৱ",
              "gu": "પ્રણવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I have found it very hard to accept you as Arvind. I am sorry about how I have behaved with you, and I will try my best to accept you as Asmita. I’m happy that you made this transition.",
              "hi": "मुझे तुमको अरविंद के रूप में स्वीकार करना बहुत कठिन है। मुझे खेद है कि मैंने तुम्हारे साथ कैसा व्यवहार किया, और मैं तुमको अस्मिता  के रूप में स्वीकार करने का पूरा प्रयास करूंगा। मुझे खुशी है कि तुमने यह बदलाव किया है।",
              "ka": "ನಿನ್ನನ್ನು ಅರವಿಂದ ಎಂದು ಒಪ್ಪಿಕೊಳ್ಳಲು ನನಗೆ ತುಂಬಾ ಕಷ್ಟವಾಯಿತು. ನಾನು ನಿನ್ನೊಂದಿಗೆ ಹೇಗೆ ನಡೆದುಕೊಂಡೆ ಎಂಬುದರ ಬಗ್ಗೆ ನನಗೆ ವಿಷಾದವಿದೆ ಮತ್ತು ನಿನ್ನನ್ನು ಅಸ್ಮಿತಾ ಎಂದು ಒಪ್ಪಿಕೊಳ್ಳಲು ನಾನು ನನ್ನ ಕೈಲಾದಷ್ಟು ಪ್ರಯತ್ನಿಸುತ್ತೇನೆ. ನೀನು ಈ ಪರಿವರ್ತನೆ ಮಾಡಿದ್ದೀರಿ ಎಂದು ನನಗೆ ಸಂತೋಷವಾಗಿದೆ.",
              "te": "నిన్ను అరవింద్‌గా అంగీకరించడం నాకు చాలా కష్టంగా అనిపించింది. నేను నీతో అలా ప్రవర్తించినందుకు చింతిస్తున్నాను మరియు నిన్ను అస్మితగా అంగీకరించడానికి నా శాయశక్తులా ప్రయత్నిస్తాను. నువ్వు ఇలా మార్పు చెందినందుకు నేను సంతోషిస్తున్నాను.",
              "or": "ତୁମକୁ ଅରବିନ୍ଦ ଭାବରେ ଗ୍ରହଣ କରିବା ମୋତେ ବହୁତ କଷ୍ଟକର | ମୁଁ ତୁମ ସହିତ ଯେପରି ବ୍ୟବହାର କରିଛି ସେଥିପାଇଁ ମୁଁ ଦୁଃଖିତ, ଏବଂ ତୁମକୁ ଅସ୍ମିତା ଭାବରେ ଗ୍ରହଣ କରିବାକୁ ମୁଁ ଯଥାସମ୍ଭବ ଚେଷ୍ଟା କରିବି | ମୁଁ ଖୁସି ଯେ ତୁମେ ଏହି ପରିବର୍ତ୍ତନ କରିଛ |",
              "as": "মই তোমাক অৰবিন্দ হিচাপে গ্ৰহণ কৰিবলৈ বহুত টান পাইছো। মই তোমাৰ সৈতে যেনেধৰণৰ আচৰণ কৰিছো তাৰ বাবে মই দুঃখিত, আৰু মই তোমাক অস্মিতা হিচাপে গ্ৰহণ কৰিবলৈ যথাসাধ্য চেষ্টা কৰিম। মই সুখী যে তুমি এই পৰিৱৰ্তনটো কৰিছা।",
              "gu": "મને તમને અરવિંદની સ્વરૂપમાં સ્વીકાર કરવાનું ઘણું મુશ્કેલ લાગે છે. મે તમારી સાથે કેવો વ્યવહાર કર્યો, તે અંગે મને ખેદ છે, અને હું તમને અસ્મિતા તરીકે સ્વીકાર કરવાનો પૂરો પ્રયત્ન કરીશ. મને ખુશી છે કે તમે આ પરિવર્તન કર્યું છે."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_4_2////",
              "hi": "C4_4_2 //////",
              "ka": "C4_4_2////",
              "te": "C4_4_2////",
              "or": "C4_4_2////",
              "as": "C4_4_2////",
              "gu": "C4_4_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Her Mother and Father walk in with Roshni. They smile at Asmita and hug her.",
              "hi": "उसकी मां और पिता रोशनी के साथ चलते हैं। वे अस्मिता को देख कर मुस्कराते हैं और उसे गले लगाते हैं।",
              "ka": "ಅವಳ ತಾಯಿ ಮತ್ತು ತಂದೆ ರೋಶನಿಯೊಂದಿಗೆ ನಡೆಯುತ್ತಾರೆ. ಅವರು ಅಸ್ಮಿತಾ ಅವರನ್ನು ನೋಡಿ ಮುಗುಳ್ನಕ್ಕು ಅವಳನ್ನು ತಬ್ಬಿಕೊಂಡರು.",
              "te": "ఆమె తల్లి మరియు తండ్రి రోష్నితో కలిసి వస్తారు. వాళ్ళు అస్మితను చూసి నవ్వి కౌగిలించుకుంటారు.",
              "or": "ତାଙ୍କ ମାତା ଏବଂ ପିତା ରୋଶନିଙ୍କ ସହିତ ଚାଲନ୍ତି | ସେମାନେ ଅସ୍ମିତାଙ୍କ ଉପରେ ହସନ୍ତି ଏବଂ ଆଲିଙ୍ଗନ କରନ୍ତି |",
              "as": "তাইৰ মাক আৰু দেউতাকে ৰোশনিৰ সৈতে খোজ কাঢ়ে। তেওঁলোকে অস্মিতাৰ ফালে চাই হাঁহিছিল আৰু তাইক সাৱতি কৰিছিল।",
              "gu": "તેના માતા અને પિતા રોશની સાથે અંદર જાય છે. તેઓ અસ્મિતા તરફ સ્મિત કરે છે અને તેને ગળે લગાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "We are proud of you, Asmita. I wasn't sure how to respond, mostly because I am concerned for you. I don't want you to be hurt, that's all.",
              "hi": "हमें तुम पर गर्व है, अस्मिता। मुझे समझ नहीं  आ रहा था कि कैसे प्रतिक्रिया दूं, ज्यादातर इसलिए कि मैं तुम्हारे लिए चिंतित हूं। मैं नहीं चाहती कि तुम आहत हो, बस इतना ही।",
              "ka": "ಅಸ್ಮಿತಾ, ನಿನ್ನ ಬಗ್ಗೆ ನಮಗೆ ಹೆಮ್ಮೆ ಇದೆ. ನಾನು ನಿನ್ನ ಬಗ್ಗೆ ಕಾಳಜಿ ವಹಿಸುತ್ತಿರುವ ಕಾರಣ, ಹೇಗೆ ಪ್ರತಿಕ್ರಿಯಿಸಬೇಕು ಎಂದು ನನಗೆ ಖಾತ್ರಿಯಾಗಲಿಲ್ಲ. ನಿನಗೆ ನೋವಾಗುವುದು ನನಗೆ ಇಷ್ಟವಿಲ್ಲ, ಅಷ್ಟೇ.",
              "te": "అస్మితా, నీ గురించి మేము గర్విస్తున్నాము. నేను నీ గురించి ఆందోళన చెందుతున్నందున, ఎలా రియాక్ట్ అవ్వాలో నాకు తెలియలేదు. నువ్వు బాధపడటం నాకు ఇష్టం లేదు, అంతే.",
              "or": "ଅସ୍ମିତା, ଆମେ ତୁମ ପାଇଁ ଗର୍ବିତ | ମୁଁ କିପରି ପ୍ରତିକ୍ରିୟା କରିବି ନିଶ୍ଚିତ ନଥିଲି, କାରଣ ପ୍ରାୟତଃ ମୁଁ ତୁମ ପାଇଁ ଚିନ୍ତିତ ଥାଏ | ମୁଁ ଚାହେଁ ନାହିଁ ତୁମେ ଆଘାତ ପାଓ, କେବଳ ସେତିକି |",
              "as": "আমি তোমাক লৈ গৌৰৱান্বিত, অস্মিতা। মই কেনেদৰে সঁহাৰি দিব লাগে নিশ্চিত নাছিলো, কাৰণ মই বেছিভাগ সময় তোমাৰ বাবে চিন্তিত আছিলো। তুমি আঘাত পোৱাটো মই নিবিচাৰো, সেয়াই কথা।",
              "gu": "અમને તમારી ઉપર ગર્વ છે અસ્મિતા. મને સમજાતુ નથી કે હું કેવી પ્રતિક્રિયા આપું, મોટાભાગે એટલા માટે હું તમારા માટે ચિંતિત છું.હું ઈચ્છતો નથી કે તમને ઈજા પહોંચે. બસ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, we will all figure together how to deal with what people say. But as long as you can live a life of happiness and self-respect, we will be satisfied.",
              "hi": "हां, हम सब मिलकर यह तय करेंगे कि लोग जो कहते हैं उसका कैसे सामना किया जाए। लेकिन जब तक तुम खुश हो और स्वाभिमान का जीवन जी सकती हो, हम संतुष्ट रहेंगे।",
              "ka": "ಹೌದು, ಜನರು ಹೇಳುವುದನ್ನು ಹೇಗೆ ಎದುರಿಸಬೇಕೆಂದು ನಾವೆಲ್ಲರೂ ಒಟ್ಟಾಗಿ ಲೆಕ್ಕಾಚಾರ ಮಾಡುತ್ತೇವೆ. ಆದರೆ ನೀವು ಎಲ್ಲಿಯವರೆಗೆ ಸಂತೋಷ ಮತ್ತು ಸ್ವಾಭಿಮಾನದ ಜೀವನವನ್ನು ನಡೆಸಬಹುದು, ನಾವು ತೃಪ್ತರಾಗುತ್ತೇವೆ.",
              "te": "ఎవరు ఏమంటారు అనే దాన్ని ఎలా ఎదుర్కోవాలో మనమందరం కలిసి ఆలోచిద్దాం. కానీ నువ్వు సంతోషంగా మరియు ఆత్మగౌరవంతో జీవించగలిగినంత కాలం, మేము సంతృప్తి చెందుతాము.",
              "or": "ହଁ, ଲୋକମାନେ ଯାହା କହନ୍ତି ତାହା ସହିତ କିପରି ମୁକାବିଲା କରିବା ଆମେ ସମସ୍ତେ ଏକାଠି ଆକଳନ କରିବା | କିନ୍ତୁ ଯେପର୍ଯ୍ୟନ୍ତ ତୁମେ ସୁଖ ଏବଂ ଆତ୍ମ ସମ୍ମାନର ଜୀବନ ବିତାଇ ପାରିବ, ଆମେ ସନ୍ତୁଷ୍ଟ ହେବୁ |",
              "as": "হয়, মানুহে কোৱা কথাবোৰৰ সৈতে কেনেদৰে মোকাবিলা কৰিব লাগে আমি সকলোৱে একেলগে বুজি পাম। কিন্তু যেতিয়ালৈকে তুমি সুখ আৰু আত্মসন্মানেৰে জীৱন যাপন কৰিব পাৰা, আমি সন্তুষ্ট হৈ থাকিম।",
              "gu": "હાં, બસ બધા સાથે મળી એ નક્કી કરશું કે લોકો જે કહે છે તેને કેવી રીતે સામનો કરવામાં આવે. જોકે જ્યાં સુધી તમે ખુશી અને સ્વાભિમાનનું જીવન જીવી શકો છો, અમને સંતોષ રહેશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Appa, you don't have to be so dramatic.",
              "hi": "अप्पा, आपको इतना नाटकीय नहीं होना चाहिए।",
              "ka": "ಅಪ್ಪಾ, ನೀನು ನಾಟಕೀಯವಾಗಿ ವರ್ತಿಸಬೇಕಿಲ್ಲ.",
              "te": "అప్పా, నువ్వు అంత నాటకీయంగా ఉండనవసరం లేదు.",
              "or": "ଆପ୍ପା, ତୁ୍ମେ ଏତେ ନାଟକୀୟ ହେବା ଦରକାର ନାହିଁ |",
              "as": "আপ্পা, আপুনি ইমান নাটকীয় হ'ব নালাগে।",
              "gu": "અપ્પા.તારે આટલી બધી નાટકબાજી કરવાની જરૂર નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Hahaha",
              "hi": "हाहाहा",
              "ka": "ಹಹಹ",
              "te": "హహహ",
              "or": "ହାହାହା",
              "as": "হাঃহাঃহাঃ",
              "gu": "હાહાહા"
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_5_1",
              "hi": "C4_5_1",
              "ka": "C4_5_1",
              "te": "C4_5_1",
              "or": "C4_5_1",
              "as": "C4_5_1",
              "gu": "C4_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Later that night, Asmita tries to find out more about the Transform Fellowship. Asmita goes to the Transgender Empowerment and Awareness Mission (TEAM) website, the organisation that offers the Transform Fellowship.",
              "hi": "उस रात बाद में, अस्मिता ट्रांसफॉर्म फेलोशिप के बारे में और अधिक जानने का प्रयास करती है। अस्मिता ट्रांसजेंडर एंपावरमेंट एंड अवेयरनेस मिशन (टीईएम) की वेबसाइट पर जाती है, जो संगठन ट्रांसफॉर्म फेलोशिप प्रदान करता है।",
              "ka": "ಆ ರಾತ್ರಿಯ ನಂತರ, ಅಸ್ಮಿತಾ ಟ್ರಾನ್ಸ್‌ಫಾರ್ಮ್ ಫೆಲೋಶಿಪ್ ಕುರಿತು ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಪ್ರಯತ್ನಿಸುತ್ತಾಳೆ. ಟ್ರಾನ್ಸ್‌ಫಾರ್ಮ್ ಫೆಲೋಶಿಪ್ ನೀಡುವ ಸಂಸ್ಥೆಯಾದ ಟ್ರಾನ್ಸ್‌ಜೆಂಡರ್ ಎಂಪವರ್‌ಮೆಂಟ್ ಮತ್ತು ಅವೇರ್ನೆಸ್ ಮಿಷನ್ (ಟೀಮ್) ವೆಬ್‌ಸೈಟ್‌ಗೆ ಅಸ್ಮಿತಾ ಹೋಗುತ್ತಾಳೆ.",
              "te": "ఆరోజు రాత్రి, అస్మిత ట్రాన్స్‌ఫార్మ్ ఫెలోషిప్ గురించి మరింత తెలుసుకోవడానికి ప్రయత్నిస్తుంది. ట్రాన్స్‌ఫార్మ్ ఫెలోషిప్‌ను అందించే సంస్థ అయిన ట్రాన్స్‌జెండర్ ఎంపవర్‌మెంట్ అండ్ అవేర్‌నెస్ మిషన్ (TEAM) వెబ్‌సైట్‌ ని అస్మిత చూస్తుంది.",
              "or": "ପରେ ସେହି ରାତିରେ, ଅସ୍ମିତା ଟ୍ରାନ୍ସଫର୍ମ ଫେଲୋସିପ୍ ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ଚେଷ୍ଟା କରନ୍ତି | ଅସ୍ମିତା ଟ୍ରାନ୍ସଜେଣ୍ଡର ଫେଲୋସିପ୍ ପ୍ରଦାନ କରୁଥିବା ସଂସ୍ଥା ଟ୍ରାନ୍ସଜେଣ୍ଡର ଏମ୍‍ପାୱର୍‌ମେଣ୍ଟ୍‌ ଆଣ୍ଡ ଅୱାଏର୍‌ନେସ୍‌ ମିଶନ୍ (TEAM) ୱେବସାଇଟ୍ କୁ ଯା‍ଆନ୍ତି |",
              "as": "সেই নিশাৰ পিছত, অস্মিতাই ট্ৰান্সফৰ্ম ফেলোশ্বিপৰ বিষয়ে অধিক জানিবলৈ চেষ্টা কৰে। অস্মিতাই ট্ৰেন্সফৰ্ম ফেলোশ্বিপ প্ৰদান কৰা প্ৰতিষ্ঠান ট্ৰেন্সজেণ্ডাৰ এমপাৱাৰমেণ্ট এণ্ড এৱাৰনেছ মিছন (TEAM) ৱেবছাইটো চায়।",
              "gu": "તે રાત્ર બાદમાં અસ્મિતા ટ્રાન્સફોર્મ ફેલોશિપ અંગે વધારે જાણવાનો પ્રયત્ન કરે છે. અસ્મિતા ટ્રાન્સલિંગ એમ્પાવરમેન્ટ એન્ડ અવેરનેસ મિશન (TEAM) વેબસાઈટ, સંસ્થા ટ્રાન્સફોર્મ ફેલોશિપ રજૂ કરે છે."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_5_2",
              "hi": "C4_5_2",
              "ka": "C4_5_2",
              "te": "C4_5_2",
              "or": "C4_5_2",
              "as": "C4_5_2",
              "gu": "C4_5_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She finds out that the Transform fellowship recognises the stigma and discrimination faced by transgender people and seeks to bring them into the mainstream by protecting and promoting the rights of LGBTQ+ people.",
              "hi": "उसे पता चलता है कि ट्रांसजेंडर फेलोशिप ट्रांसजेंडर लोगों द्वारा सामना किए जाने वाले कलंक और भेदभाव को पहचानती है और एलजीबीटीक्यू+ लोगों के अधिकारों की रक्षा और प्रचार करके उन्हें मुख्यधारा में लाने का प्रयास करती है।",
              "ka": "ಟ್ರಾನ್ಸ್‌ಫಾರ್ಮ್ ಫೆಲೋಶಿಪ್ ಟ್ರಾನ್ಸ್‌ಜೆಂಡರ್ ಜನರು ಎದುರಿಸುತ್ತಿರುವ ಕಳಂಕ ಮತ್ತು ತಾರತಮ್ಯವನ್ನು ಗುರುತಿಸುತ್ತದೆ ಮತ್ತು LGBTQ+ ಜನರ ಹಕ್ಕುಗಳನ್ನು ರಕ್ಷಿಸುವ ಮತ್ತು ಪ್ರಚಾರ ಮಾಡುವ ಮೂಲಕ ಅವರನ್ನು ಮುಖ್ಯವಾಹಿನಿಗೆ ತರಲು ಪ್ರಯತ್ನಿಸುತ್ತದೆ ಎಂದು ಅವಳು ಕಂಡುಕೊಂಡಳು.",
              "te": "ట్రాన్స్ ఫార్మ్ ఫెలోషిప్ ట్రాన్స్ జెండర్ వ్యక్తులు ఎదుర్కొంటున్న కళంకం మరియు వివక్షను గుర్తించి LGBTQ+ వ్యక్తుల హక్కులను రక్షించడం మరియు ప్రచారం చేయడం ద్వారా వారిని ప్రధాన అంశాలలో కలపాలని ప్రయత్నిస్తున్నారు అని ఆమె కనుగొంటుంది.",
              "or": "ସେ ଜାଣିବାକୁ ପାଆନ୍ତି ଯେ ଟ୍ରାନ୍ସଫର୍ମ ର ଫେଲୋସିପ୍ ଟ୍ରାନ୍ସଜେଣ୍ଡର୍‌ ଲୋକଙ୍କ ସମ୍ମୁଖୀନ ହେଉଥିବା କଳଙ୍କ ଏବଂ ଭେଦଭାବକୁ ସ୍ୱୀକୃତି ଦେଇଥାଏ ଏବଂ LGBTQ + ଲୋକଙ୍କ ଅଧିକାରର ସୁରକ୍ଷା ତଥା ପ୍ରୋତ୍ସାହନ ଦେଇ ସେମାନଙ୍କୁ ମୁଖ୍ୟ ସ୍ରୋତକୁ ଆଣିବାକୁ ଚେଷ୍ଟା କରେ |",
              "as": "তেওঁ গম পায় যে ট্ৰান্সফৰ্ম ফেলোশ্বিপে ট্ৰেন্সজেণ্ডাৰ লোকসকলৰ দ্বাৰা সন্মুখীন হোৱা কলঙ্ক আৰু বৈষম্যক স্বীকৃতি দিয়ে আৰু LGBTQ+ লোকসকলৰ অধিকাৰ সুৰক্ষিত আৰু প্ৰচাৰ কৰি তেওঁলোকক মূলসুঁতিলৈ আনিব বিচাৰে।",
              "gu": "તેણીનીને માલુમ થાય છે કે ટ્રાન્સલિંગ ફેલોશિપ ટ્રાન્સલિંગ લોકો દ્વારા સામનો કરવામાં આવતા કલંક તથા ભેદભાવની ઓળખ કરે છે અને LGBTQ+ લોકોના અધિકારોની રક્ષા અને પ્રચાર કરવાના તેમની મુખ્યધારામાં લાવવાનો પ્રયત્ન કરે છે."
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_5_3",
              "hi": "C4_5_3",
              "ka": "C4_5_3",
              "te": "C4_5_3",
              "or": "C4_5_3",
              "as": "C4_5_3",
              "gu": "C4_5_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She is convinced that this is an excellent opportunity to learn, and the experience will also help her understand what kind of work she wants to do in the future.",
              "hi": "वह आश्वस्त है कि यह सीखने का एक उत्कृष्ट अवसर है, और अनुभव उसे यह समझने में भी सहायक होगा कि वह भविष्य में किस तरह का काम करना चाहती है।",
              "ka": "ಕಲಿಯಲು ಇದು ಅತ್ಯುತ್ತಮ ಅವಕಾಶ ಎಂದು ಅವಳು ಮನಗಂಡಿದ್ದಾಳೆ ಮತ್ತು ಭವಿಷ್ಯದಲ್ಲಿ ಅವಳು ಯಾವ ರೀತಿಯ ಕೆಲಸವನ್ನು ಮಾಡಲು ಬಯಸುತ್ತಾಳೆ ಎಂಬುದನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಅನುಭವವು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "నేర్చుకోవడానికి ఇది ఒక అద్భుతమైన అవకాశం అని ఆమెకు నమ్మకం ఉంది మరియు భవిష్యత్తులో ఆమె ఎలాంటి పని చేయాలో అర్థం చేసుకోవడానికి కూడా ఈ అనుభవం ఆమెకు సహాయపడుతుంది.",
              "or": "ସେ ନିଶ୍ଚିତ ଯେ ଏହା ଶିଖିବା ପାଇଁ ଏକ ଉତ୍କୃଷ୍ଟ ସୁଯୋଗ, ଏବଂ ଭବିଷ୍ୟତରେ ସେ କେଉଁ ପ୍ରକାର କାମ କରିବାକୁ ଚାହୁଁଛନ୍ତି ତାହା ଅଭିଜ୍ଞତା ମଧ୍ୟ ତାଙ୍କୁ ବୁଝିବାରେ ସାହାଯ୍ୟ କରିବ |",
              "as": "তাই নিশ্চিত যে এইটো শিকাৰ এক উৎকৃষ্ট সুযোগ, আৰু অভিজ্ঞতাই তাইক ভৱিষ্যতে কেনে ধৰণৰ কাম কৰিব বিচাৰে বুজাত সহায় কৰিব।",
              "gu": "તેણીની ખાતરી કરે છે કે આ શીખવાની ઉત્કૃષ્ઠ તક છે, અને અનુભવ તેને એ સમજાવવામાં પણ મદદ કરશે કે તે ભવિષ્યમાં કેવી રીતે કામ કરવા ઈચ્છે છે."
            }
          }
        ],
        "page21": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_5_4",
              "hi": "C4_5_4",
              "ka": "C4_5_4",
              "te": "C4_5_4",
              "or": "C4_5_4",
              "as": "C4_5_4",
              "gu": "C4_5_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita decides to apply for the fellowship and fills out the application form. Meanwhile, she shares her thesis with Neelam from Arise since she had been keen to read it.",
              "hi": "अस्मिता फेलोशिप के लिए आवेदन करने का निश्चय करती है और आवेदन पत्र भरती है। इस बीच, वह अपनी थीसिस नीलम के साथ अराइज से साझा करती है क्योंकि वह इसे पढ़ने के लिए उत्सुक थी।",
              "ka": "ಅಸ್ಮಿತಾ ಫೆಲೋಶಿಪ್‌ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾರೆ ಮತ್ತು ಅರ್ಜಿ ನಮೂನೆಯನ್ನು ಭರ್ತಿ ಮಾಡುತ್ತಾರೆ. ಏತನ್ಮಧ್ಯೆ, ಅವಳು ತನ್ನ ಪ್ರಬಂಧವನ್ನು ಓದಲು ಉತ್ಸುಕನಾಗಿದ್ದರಿಂದ ಏರೈಸ್‌ನಿಂದ ನೀಲಂನೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "అస్మిత ఫెలోషిప్ కి అప్లై చేయాలని నిర్ణయించుకొని అప్లికేషన్ ఫార్మ్ నింపుతుంది. తన థీసిస్ ని చదవడానికి ఆసక్తిగా ఉందని ఆమె అరైజ్ లోని నీలంకి తన థీసిస్ పంపుతుంది.",
              "or": "ଅସ୍ମିତା ଫେଲୋସିପ୍ ପାଇଁ ଆବେଦନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି ଏବଂ ଆବେଦନ ଫର୍ମ ପୂରଣ କରନ୍ତି | ଏହି ସମୟରେ, ସେ ତାଙ୍କ ଥିସିସ୍‍ ଅରାଇଜ୍‌ରୁ ନୀଲମଙ୍କ ସହିତ ଅଂଶୀଦାର କରନ୍ତି ଯେହେତୁ ସେ ଏହାକୁ ପଢିବାକୁ ଆଗ୍ରହୀ ଅଛନ୍ତି |",
              "as": "অস্মিতাই ফেলোশ্বিপৰ বাবে আবেদন কৰাৰ সিদ্ধান্ত লয় আৰু আবেদন পত্ৰ পূৰণ কৰে। ইফালে, তাই তাইৰ গৱেষণাপত্ৰখন এৰাইজৰ নীলমৰ সৈতে ভাগ-বতৰা কৰে কিয়নো তেওঁ ইয়াক পঢ়িবলৈ আগ্ৰহী আছিল।",
              "gu": "અસ્મિતા ફેલોશિપ માટે અરજી કરવાનો નિર્ણય કરે છે અને અરજીપત્ર ભરે છે. દરમિયાન, તે પોતાના શિસિસ નીલમ સાથે સર્જાવા સાથે રજૂ કરે છે, કારણ કે તે તેના અભ્યાસ માટે ઉત્સુક હતી."
            }
          }
        ],
        "page22": [
          {
            "valuePath": "display",
            "value": {
              "en": "C4_5_5",
              "hi": "C4_5_5",
              "ka": "C4_5_5",
              "te": "C4_5_5",
              "or": "C4_5_5",
              "as": "C4_5_5",
              "gu": "C4_5_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The email also mentions that she underwent a social transition, with her new name as Asmita and preferred pronouns as she/her\".\"",
              "hi": "ईमेल में यह भी उल्लेख किया गया है कि उसने अस्मिता के रूप में अपने नए नाम और पसंदीदा सर्वनाम के साथ एक सामाजिक परिवर्तन किया\"वह/उसकी\"।",
              "ka": "ಆಕೆಯ ಹೊಸ ಹೆಸರು ಅಸ್ಮಿತಾ ಮತ್ತು ಆದ್ಯತೆಯ ಸರ್ವನಾಮಗಳೊಂದಿಗೆ ಅವಳು ಸಾಮಾಜಿಕ ಪರಿವರ್ತನೆಗೆ ಒಳಗಾದಳು ಎಂದು ಇಮೇಲ್ ಉಲ್ಲೇಖಿಸುತ್ತದೆ\".\"",
              "te": "ఆమె కొత్త పేరు అస్మిత మరియు ఆమె/ఆమె వంటి సర్వనామాలతో ఆమె సామాజిక పరివర్తన చెందింది అని కూడా ఇమెయిల్ లో పేర్కొంది.\"",
              "or": "ଇମେଲରେ ମଧ୍ୟ ଉଲ୍ଲେଖ କରାଯାଇଛି ଯେ ଅସ୍ମିତାଙ୍କ ନୂତନ ନାମ ଏବଂ ପସନ୍ଦିତ ସର୍ବନାମଗୁଡିକ ସେ/ତାଙ୍କ, ସେ ଏକ ସାମାଜିକ ପରିବର୍ତ୍ତନ ଦେଇ ଗତି କରିଥିଲେ |",
              "as": "ইমেইলখনত এইটোও উল্লেখ কৰিছে যে তাই এক সামাজিক পৰিৱৰ্তনৰ মাজেৰে গৈছিল, তাইৰ নতুন নাম অস্মিতা আৰু \"তাই/তাইৰ\" দৰে সৰ্বনাম পছন্দ কৰিছিল।",
              "gu": "ઈમેલમાં એ બાબતનો ઉલ્લેખ કરવામાં આવ્યો છે કે તેને અસ્મિતાના સ્વરૂપમાં પોતાના નવા નામ અને પસંદગીના સર્વનામ સાથે એક સામાજીક પરિવર્તન કર્યું છે."
            }
          }
        ]
      },
      "scene15": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_1_1",
              "hi": "C5_1_1",
              "ka": "C5_1_1",
              "te": "C5_1_1",
              "or": "C5_1_1",
              "as": "C5_1_1",
              "gu": "C5_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita learns that the fellowship is two years long and involves a stipend. A total of 15 candidates will be selected for this.",
              "hi": "अस्मिता को पता चलता है कि फेलोशिप दो साल लंबी है और इसमें एक स्टाइपेंड/ छात्रवृत्ति शामिल है। इसके लिए कुल 15 उम्मीदवारों का चयन किया जाएगा।",
              "ka": "ಫೆಲೋಶಿಪ್ ಎರಡು ವರ್ಷಗಳು ಮತ್ತು ಸ್ಟೈಫಂಡ್ ಅನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ ಎಂದು ಅಸ್ಮಿತಾ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾರೆ. ಇದಕ್ಕಾಗಿ ಒಟ್ಟು 15 ಅಭ್ಯರ್ಥಿಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಲಾಗುತ್ತದೆ.",
              "te": "ఫెలోషిప్ రెండేళ్లు ఉంటుందని మరియు స్టైఫండ్‌తో కూడుకున్నదని అస్మిత తెలుసుకుంది. ఇందుకోసం మొత్తం 15 మంది అభ్యర్థులను ఎంపిక చేస్తారు.",
              "or": "ଅସ୍ମିତା ଜାଣନ୍ତି ଯେ ଫେଲୋସିପ୍ ଦୁଇ ବର୍ଷ ଦୀର୍ଘ ଏବଂ ଏକ ଷ୍ଟାଇପେଣ୍ଡ୍ ଅନ୍ତର୍ଭୁକ୍ତ କରେ | ଏଥିପାଇଁ ମୋଟ୍‌ 15 ଜଣ ପ୍ରାର୍ଥୀ ଚୟନ କରାଯିବ।",
              "as": "অস্মিতাই জানিব পাৰিলে যে ফেলোশ্বিপটো দুবছৰীয়া দীঘল আৰু ইয়াত এটা বৃত্তি অন্তৰ্ভুক্ত থাকে। ইয়াৰ বাবে মুঠ ১৫জন প্ৰাৰ্থী বাছনি কৰা হ'ব।",
              "gu": "અસ્મિતાને ખબર પડી છે કે ફેલોશિપ બે વર્ષ લાંબી છે અને તેમા એક સ્પાઈપેન્ડ સામેલ છે. આ માટે કુલ 15 ઉમેદવારોની પસંદગી કરવામાં આવશે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_1_2_reveal",
              "hi": "C5_1_2_रिवील ",
              "ka": "C5_1_2_reveal",
              "te": "C5_1_2_రివీల్",
              "or": "C5_1_2_reveal",
              "as": "C5_1_2_reveal",
              "gu": "C5_1_2_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Asmita is looking at the application process of the fellowship.\nClick to see the steps involved:",
              "hi": "अस्मिता फेलोशिप की आवेदन प्रक्रिया देख रही है।\nशामिल चरणों को देखने के लिए क्लिक करें:",
              "ka": "“ಅಸ್ಮಿತಾ ಫೆಲೋಶಿಪ್‌ನ ಅರ್ಜಿ ಪ್ರಕ್ರಿಯೆಯನ್ನು ನೋಡುತ್ತಿದ್ದಾರೆ.\n ಒಳಗೊಂಡಿರುವ ಹಂತಗಳನ್ನು ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ:\"",
              "te": "‘‘ఫెలోషిప్ దరఖాస్తు ప్రక్రియను అస్మిత పరిశీలిస్తోంది. దానిలోని దశలను చూడటానికి క్లిక్ చేయండి:\"",
              "or": "ଅସ୍ମିତା ଫେଲୋସିପ୍ ର ଆବେଦନ ପ୍ରକ୍ରିୟାକୁ ଦେଖୁଛନ୍ତି |\n ଜଡିତ ପଦକ୍ଷେପଗୁଡିକ ଦେଖିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "অস্মিতাই ফেলোশ্বিপৰ আবেদন প্ৰক্ৰিয়াটো চাই আছে।\n অন্তৰ্ভুক্ত পদক্ষেপবোৰ চাবলৈ ক্লিক কৰক:",
              "gu": "અસ્મિતા ફેલોશિપની અરજીની પ્રક્રિયા જોવા મળે છે.સામેલ તબક્કાને જોવા અહીં ક્લિક કરોઃ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Step 1",
              "hi": "चरण 1",
              "ka": "ಹಂತ 1",
              "te": "దశ 1",
              "or": "Step 1",
              "as": "পদক্ষেপ ১",
              "gu": "પગલું 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Step 2",
              "hi": "चरण 2 ",
              "ka": "ಹಂತ 2",
              "te": "దశ 2",
              "or": "Step 2",
              "as": "পদক্ষেপ ২",
              "gu": "પગલું 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Step 3",
              "hi": "चरण 3",
              "ka": "ಹಂತ 3",
              "te": "దశ 3",
              "or": "Step 3",
              "as": "পদক্ষেপ 3",
              "gu": "પગલું 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Online application",
              "hi": "ऑनलाइन आवेदन",
              "ka": "ಆನ್ಲೈನ್ ​​ಅಪ್ಲಿಕೇಶನ್",
              "te": "ఆన్‌లైన్ అప్లికేషన్",
              "or": "ଅନ୍‌ଲାଇନ୍ ଆପ୍ଲିକେସନ୍‌",
              "as": "অনলাইন এপ্লিকেচন",
              "gu": "ઓનલાઈન અરજી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Telephonic interview",
              "hi": "टेलिफ़ोनिक साक्षात्कार",
              "ka": "ದೂರವಾಣಿ ಸಂದರ್ಶನ",
              "te": "టెలిఫోనిక్ ఇంటర్వ్యూ",
              "or": "ଟେଲିଫୋନ୍ ସାକ୍ଷାତକାର",
              "as": "টেলিফোনিক সাক্ষাৎকাৰ",
              "gu": "ટેલિફોનિક ઈન્ટરવ્યુ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "Group discussion and in-person interview",
              "hi": "समूह चर्चा और व्यक्तिगत साक्षात्कार",
              "ka": "ಗುಂಪು ಚರ್ಚೆ ಮತ್ತು ವೈಯಕ್ತಿಕ ಸಂದರ್ಶನ",
              "te": "గ్రూప్ డిస్కషన్ మరియు పర్సనల్ ఇంటర్వ్యూ",
              "or": "ସମୁଦାୟ ଆଲୋଚନା ଏବଂ ବ୍ୟକ୍ତିଗତ ସାକ୍ଷାତକାର",
              "as": "গোট আলোচনা আৰু ব্যক্তিগত সাক্ষাৎকাৰ",
              "gu": "જૂથ ચર્ચા અને ઈન-પર્સન ઈન્ટરવ્યુ"
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_1_3",
              "hi": "C5_1_3",
              "ka": "C5_1_3",
              "te": "C5_1_3",
              "or": "C5_1_3",
              "as": "C5_1_3",
              "gu": "C5_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is filling out the online application form to share her personal and academic details. She has to write an essay to explain why she wants to join the fellowship.",
              "hi": "अस्मिता अपने व्यक्तिगत और शैक्षणिक विवरण साझा करने के लिए ऑनलाइन आवेदन पत्र भरती है। उसे यह समझाने के लिए एक निबंध लिखना होगा कि वह फेलोशिप में क्यों शामिल होना चाहती है।",
              "ka": "ಅಸ್ಮಿತಾ ತನ್ನ ವೈಯಕ್ತಿಕ ಮತ್ತು ಶೈಕ್ಷಣಿಕ ವಿವರಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ಆನ್‌ಲೈನ್ ಅರ್ಜಿ ನಮೂನೆಯನ್ನು ಭರ್ತಿ ಮಾಡುತ್ತಿದ್ದಾಳೆ. ಅವಳು ಏಕೆ ಫೆಲೋಶಿಪ್‌ಗೆ ಸೇರಲು ಬಯಸುತ್ತಾಳೆ ಎಂಬುದನ್ನು ವಿವರಿಸಲು ಅವಳು ಪ್ರಬಂಧವನ್ನು ಬರೆಯಬೇಕು.",
              "te": "అస్మిత తన వ్యక్తిగత మరియు అకడమిక్ వివరాలను తెలుపడానికి ఆన్‌లైన్ అప్లికేషన్ ఫారమ్‌ను నింపుతోంది. ఆమె ఫెలోషిప్‌లో ఎందుకు చేరాలనుకుంటుందో వివరించడానికి ఆమె ఒక వ్యాసం రాయాలి.",
              "or": "ଅସ୍ମିତା ତାଙ୍କର ବ୍ୟକ୍ତିଗତ ଏବଂ ଶୈକ୍ଷିକ ବିବରଣୀ ଅଂଶୀଦାର କରିବାକୁ ଅନ୍‌ଲାଇନ୍‌ ଆବେଦନ ଫର୍ମ ପୂରଣ କରୁଛନ୍ତି | ସେ କାହିଁକି ଫେଲୋସିପ୍ ରେ ଯୋଗଦେବାକୁ ଚାହୁଁଛନ୍ତି ତାହା ବ୍ୟାଖ୍ୟା କରିବାକୁ ତାଙ୍କୁ ଏକ ପ୍ରବନ୍ଧ ଲେଖିବାକୁ ପଡିବ |",
              "as": "অস্মিতাই তাইৰ ব্যক্তিগত আৰু শৈক্ষিক বিৱৰণ অনলাইন আবেদন পত্ৰত পূৰণ কৰি আছে। তাই কিয় ফেলোশ্বিপত যোগদান কৰিব বিচাৰে বুজাবলৈ তাই এখন প্ৰবন্ধ লিখিব লাগিব।",
              "gu": "અસ્મિતા પોતાની વ્યક્તિગત અને શૈક્ષણિક વિગતો રજૂ કરવા માટે ઓનલાઈન અરજીપત્ર ભરે છે. તેને આ રજૂ કરવા માટે એક નિબંધ લખવાનો રહેશે કે તે શા માટે ફેલોશિપમાં સામેલ થવા ઈચ્છે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_1_4",
              "hi": "C5_1_4",
              "ka": "C5_1_4",
              "te": "C5_1_4",
              "or": "C5_1_4",
              "as": "C5_1_4",
              "gu": "C5_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Help Asmita write the essay by selecting the valid points she should add.",
              "hi": "अस्मिता को उन मान्य बिंदुओं का चयन करके निबंध लिखने में सहायता करें।",
              "ka": "ಅಸ್ಮಿತಾ ಅವರು ಸೇರಿಸಬೇಕಾದ ಮಾನ್ಯ ಅಂಶಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡುವ ಮೂಲಕ ಪ್ರಬಂಧವನ್ನು ಬರೆಯಲು ಸಹಾಯ ಮಾಡಿ.",
              "te": "అస్మిత రాయాల్సిన మంచి పాయింట్‌లను ఎంచుకోవడం ద్వారా వ్యాసం రాయడంలో తనకు సహాయపడండి.",
              "or": "ସେ ଯୋଗ କରିବାକୁ ଥିବା ଉଚିତ ବୈଧ ପଏଣ୍ଟଗୁଡିକ ଚୟନ କରି ଅସ୍ମିତା୍ଙ୍କୁ ପ୍ରବନ୍ଧ ଲେଖିବାରେ ସାହାଯ୍ୟ କରନ୍ତୁ |",
              "as": "অস্মিতাক তেওঁ যোগ দিব লগীয়া বৈধ বিষয়বোৰ বাছনি কৰি প্ৰবন্ধটো লিখাত সহায় কৰক।",
              "gu": "અસ્મિતાને એવા માન્ય મુદ્દાની પસંદગી કરી નિબંધ લખવામાં મદદ કરો કે જેને જોડવામાં આવવા જોઈએ"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_1_5_singleSelect",
              "hi": "C5_1_5_सिंगलसेलेक्ट ",
              "ka": "C5_1_5_singleSelect",
              "te": "C5_1_5_singleSelect",
              "or": "C5_1_5_singleSelect",
              "as": "C5_1_5_singleSelect",
              "gu": "C5_1_5_એક પસંદ કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "“What are your aspirations and motivations for joining the Transform Fellowship?”",
              "hi": "\"ट्रांसफॉर्म फैलोशिप में शामिल होने के लिए आपकी आकांक्षाएं और प्रेरणाएं क्या हैं?\"",
              "ka": "\"ಟ್ರಾನ್ಸ್‌ಫಾರ್ಮ್ ಫೆಲೋಶಿಪ್‌ಗೆ ಸೇರಲು ನಿಮ್ಮ ಆಕಾಂಕ್ಷೆಗಳು ಮತ್ತು ಪ್ರೇರಣೆಗಳು ಯಾವುವು?\"",
              "te": "ట్రాన్స్‌ఫార్మ్ ఫెలోషిప్‌లో చేరడానికి మీ ఆకాంక్షలు మరియు ప్రేరణలు ఏమిటి?",
              "or": "ଟ୍ରାନ୍ସଫର୍ମ ଫେଲୋସିପ୍‌ରେ ଯୋଗଦେବା ପାଇଁ ତୁମର ଆକାଂକ୍ଷା ଏବଂ ପ୍ରେରଣା କ’ଣ ?",
              "as": "ট্ৰান্সফৰ্ম ফেলোশ্বিপত যোগদান কৰাৰ বাবে আপোনাৰ আকাংক্ষা আৰু অনুপ্ৰেৰণা কি?",
              "gu": "\"ટ્રાન્સફોર્મ ફેલોશિપમાં સામેલ થવા માટે તમારી અપેક્ષા અને પ્રેરણાઓ શું છે?\""
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.0.display",
            "value": {
              "en": "I want to get a well-paying job, and this experience will help me.",
              "hi": "मैं एक अच्छा वेतन देने वाली नौकरी करना चाहती हूं, और यह अनुभव मेरी सहायता करेगा।",
              "ka": "ನಾನು ಉತ್ತಮ ಸಂಬಳದ ಕೆಲಸವನ್ನು ಪಡೆಯಲು ಬಯಸುತ್ತೇನೆ ಮತ್ತು ಈ ಅನುಭವವು ನನಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "నేను మంచి జీతంతో కూడిన ఉద్యోగం పొందాలనుకుంటున్నాను మరియు ఈ అనుభవం నాకు సహాయం చేస్తుంది.",
              "or": "ମୁଁ ଏକ ଭଲ-ବେତନ ଦେଉଥିବା ଚାକିରି କରିବାକୁ ଚାହୁଁଛି, ଏବଂ ଏହି ଅଭିଜ୍ଞତା ମୋତେ ସାହାଯ୍ୟ କରିବ |",
              "as": "মই ভাল দৰমহাৰ চাকৰি এটা পাবলৈ বিচাৰো, আৰু এই অভিজ্ঞতাই মোক সহায় কৰিব।",
              "gu": "હું એક સારા પગારવાળી નોકરી મેળવવા ઈચ્છું છું, અને તે અનુભવ મારી મદદ કરશે."
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.0.options.1.display",
            "value": {
              "en": "As a member of the LGBTQ+ community, I feel responsible to champion the cause of our rights.",
              "hi": " एलजीबीटीक्यू + समुदाय के एक सदस्य के रूप में, मैं अपने अधिकारों के लिए समर्थन करने के लिए जिम्मेदार अनुभव करता हूं।",
              "ka": "LGBTQ+ ಸಮುದಾಯದ ಸದಸ್ಯನಾಗಿ, ನಮ್ಮ ಹಕ್ಕುಗಳ ಕಾರಣಕ್ಕಾಗಿ ನಾನು ಜವಾಬ್ದಾರನಾಗಿರುತ್ತೇನೆ.",
              "te": "LGBTQ+ కమ్యూనిటీ సభ్యురాలిగా, మా హక్కుల కోసం పోరాడాల్సిన బాధ్యత నాకు ఉంది.",
              "or": "LGBTQ + ସମୁଦାୟର ଜଣେ ସଦସ୍ୟ ଭାବରେ, ମୁଁ ଆମର ଅଧିକାରର କାରଣକୁ ସମର୍ଥନ କରିବାକୁ ଦାୟୀ ମନେ କରେ |",
              "as": "LGBTQ+ সম্প্ৰদায়ৰ এজন সদস্য হিচাপে, মই আমাৰ অধিকাৰৰ সপক্ষে থিয় দিবলৈ দায়বদ্ধ অনুভৱ কৰো।",
              "gu": "LGBTQ+ સમુદાયના એક સભ્ય તરીકે, હું મારા અધિકારો માટે સમર્થન કરવા માટે જવાબદારીનો અહેસાસ કરું છું"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.1.options.0.display",
            "value": {
              "en": "I have relevant experience and knowledge through my thesis project on the subject of livelihoods for transgender people.",
              "hi": "ट्रांसजेंडर लोगों के लिए आजीविका के विषय पर मेरे थीसिस प्रोजेक्ट के आधार पर मेरे पास प्रासंगिक अनुभव और ज्ञान है।",
              "ka": "ತೃತೀಯಲಿಂಗಿಗಳಿಗೆ ಜೀವನೋಪಾಯದ ವಿಷಯದ ಕುರಿತು ನನ್ನ ಪ್ರಬಂಧ ಯೋಜನೆಯ ಮೂಲಕ ನನಗೆ ಸೂಕ್ತವಾದ ಅನುಭವ ಮತ್ತು ಜ್ಞಾನವಿದೆ.",
              "te": "ట్రాన్స్‌జెండర్ల జీవనోపాధి అనే అంశంపై నా థీసిస్ ప్రాజెక్ట్ ద్వారా నాకు సంబంధిత అనుభవం మరియు జ్ఞానం ఉంది.",
              "or": "ଟ୍ରାନ୍ସଜେଣ୍ଡର୍‍ ଲୋକଙ୍କ ପାଇଁ ଜୀବିକା ବିଷୟ ଉପରେ ମୋର ଥିସିସ୍‌ ପ୍ରୋଜେକ୍ଟ ମାଧ୍ୟମରେ ମୋର ପ୍ରାସଙ୍ଗିକ ଅଭିଜ୍ଞତା ଏବଂ ଜ୍ଞାନ ଅଛି |",
              "as": "ট্ৰেন্সজেণ্ডাৰ লোকসকলৰ জীৱিকাৰ বিষয়ত মোৰ গৱেষণাপত্ৰ প্ৰকল্পৰ জৰিয়তে মোৰ প্ৰাসংগিক অভিজ্ঞতা আৰু জ্ঞান আছে।",
              "gu": "ટ્રાન્સલિંગ લોકો માટે આજીવિકાના વિષય અંગે મારા થિસીસ યોજનાના માધ્યમથી મારી પાસે પ્રાસંગિક અનુભવ તથા જાણકારી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.1.options.1.display",
            "value": {
              "en": "Many people appreciated my thesis project, and hence I think I deserve to be selected.",
              "hi": "अनेक लोगों ने मेरे थीसिस प्रोजेक्ट की सराहना की, और इसलिए मुझे लगता है कि मैं चुने जाने के लायक हूं।",
              "ka": "ಅನೇಕ ಜನರು ನನ್ನ ಪ್ರಬಂಧ ಯೋಜನೆಯನ್ನು ಮೆಚ್ಚಿದ್ದಾರೆ ಮತ್ತು ಆದ್ದರಿಂದ ನಾನು ಆಯ್ಕೆಯಾಗಲು ಅರ್ಹನೆಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "చాలా మంది నా థీసిస్ ప్రాజెక్ట్‌ను మెచ్చుకున్నారు, అందుకే నేను ఎంపిక కావడానికి అర్హుడని అనుకుంటున్నాను.",
              "or": "ଅନେକ ଲୋକ ମୋର ଥିଏସ୍ ପ୍ରୋଜେକ୍ଟକୁ ପ୍ରଶଂସା କରିଥିଲେ, ଏବଂ ସେଥିପାଇଁ ମୁଁ ମନୋନୀତ ହେବାକୁ ଯୋଗ୍ୟ ବୋଲି ଭାବୁଛି |",
              "as": "বহুলোকে মোৰ গৱেষণাপত্ৰ প্ৰকল্পৰ প্ৰশংসা কৰিছিল, আৰু সেয়েহে মই ভাবো যে মই নিৰ্বাচিত হোৱাৰ বাবে যোগ্য।",
              "gu": "અનેક લોકોએ મારા થિસિસ યોજનાની પ્રશંસા કરી છે અને માટે મને લાગે છે કે મે પસંદ થવા માટે યોગ્ય છું."
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.2.options.0.display",
            "value": {
              "en": "The fellows of this fellowship have done great work to advocate for trans rights. I also want to be a part of this esteemed alumni group.",
              "hi": "इस फेलोशिप के साथियों ने ट्रांस अधिकारों की वकालत करने के लिए बहुत अच्छा काम किया है। मैं भी इस सम्मानित पूर्व छात्र समूह का हिस्सा बनना चाहती हूं।",
              "ka": "ಈ ಫೆಲೋಶಿಪ್‌ನ ಫೆಲೋಗಳು ಟ್ರಾನ್ಸ್ ಹಕ್ಕುಗಳಿಗಾಗಿ ಪ್ರತಿಪಾದಿಸಲು ಉತ್ತಮ ಕೆಲಸ ಮಾಡಿದ್ದಾರೆ. ನಾನು ಸಹ ಈ ಗೌರವಾನ್ವಿತ ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳ ಗುಂಪಿನ ಭಾಗವಾಗಲು ಬಯಸುತ್ತೇನೆ.",
              "te": "ఈ ఫెలోషిప్ సభ్యులు ట్రాన్స్ హక్కుల కోసం వాదించడానికి గొప్ప పని చేసారు. నేను కూడా ఈ గౌరవనీయమైన అల్యుమ్ని గ్రూప్ లో భాగం కావాలనుకుంటున్నాను.",
              "or": "ଏହି ଫେଲୋସିପ୍ ର ସାଥୀମାନେ ଟ୍ରାନ୍ସ ଅଧିକାରକୁ ସମର୍ଥନ କରିବା ପାଇଁ ବହୁତ ଭଲ କାର୍ଯ୍ୟ କରିଛନ୍ତି | ମୁଁ ମଧ୍ୟ ଏହି ସମ୍ମାନିତ ଆଲୁମ୍ନି ସମୁଦାୟର ଏକ ଅଂଶ ହେବାକୁ ଚାହୁଁଛି |",
              "as": "এই ফেলোশ্বিপৰ সহকৰ্মীসকলে ট্ৰেন্স অধিকাৰৰ বাবে ওকালতি কৰিবলৈ মহান কাম কৰিছে। মই এই সন্মানীয় প্ৰাক্তন ছাত্ৰ গোটৰ অংশ হ'ব বিচাৰো।",
              "gu": "આ ફેલોશિપના સાથીઓએ ટ્રાન્સ અધિકારોની વકીલાત કરવા ઘણું સારું કામ કર્યું છે. હું પણ આ સન્માનિત અગાઉ વિદ્યાર્થી જૂથોનો ભાગ બનવા ઈચ્છું છું"
            }
          },
          {
            "valuePath": "blocks.0.props.optionGroups.2.options.1.display",
            "value": {
              "en": "This fellowship is very high profile and will look good on my CV.",
              "hi": "यह फैलोशिप बहुत हाइ प्रोफ़ाइल है और मेरे सीवी पर अच्छी लगेगी।",
              "ka": "ಈ ಫೆಲೋಶಿಪ್ ತುಂಬಾ ಉನ್ನತವಾಗಿದೆ ಮತ್ತು ನನ್ನ CV ಯಲ್ಲಿ ಉತ್ತಮವಾಗಿ ಕಾಣುತ್ತದೆ.",
              "te": "ఈ ఫెలోషిప్ చాలా ఉన్నతమైనది మరియు నా CVలో బాగా కనిపిస్తుంది.",
              "or": "ଏହି ଫେଲୋସିପ୍ ବହୁତ ଉଚ୍ଚ ପ୍ରୋଫାଇଲ୍ ଅଟେ ଏବଂ ମୋ CV ରେ ଭଲ ଦେଖାଯିବ |",
              "as": "এই ফেলোশ্বিপটো বহুত উচ্চ শ্ৰেণীৰ আৰু মোৰ চিভিত ভাল দেখাব।",
              "gu": "આ ફેલોશિપ ઘણું હાઈ પ્રોફાઈલ છે અને મારા CV પર સારું લાગે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_2_1",
              "hi": "C5_2_1",
              "ka": "C5_2_1",
              "te": "C5_2_1",
              "or": "C5_2_1",
              "as": "C5_2_1",
              "gu": "C5_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita gets selected for the telephonic interview based on her application.",
              "hi": "अस्मिता को उसके आवेदन के आधार पर टेलीफ़ोनिक साक्षात्कार के लिए चुना जाता है।",
              "ka": "ಅಸ್ಮಿತಾ ತನ್ನ ಅರ್ಜಿಯ ಆಧಾರದ ಮೇಲೆ ದೂರವಾಣಿ ಸಂದರ್ಶನಕ್ಕೆ ಆಯ್ಕೆಯಾಗುತ್ತಾಳೆ.",
              "te": "అస్మిత తన దరఖాస్తు ఆధారంగా టెలిఫోనిక్ ఇంటర్వ్యూకు ఎంపికైంది.",
              "or": "ତାଙ୍କ ଆବେଦନ ଉପରେ ଆଧାର କରି ଅସ୍ମିତା ଟେଲିଫୋନ୍‌ ସାକ୍ଷାତକାର ପାଇଁ ମନୋନୀତ ହୁଅନ୍ତି |",
              "as": "অস্মিতাই তাইৰ আবেদনৰ ওপৰত ভিত্তি কৰি টেলিফোনিক সাক্ষাৎকাৰৰ বাবে নিৰ্বাচিত হয়।",
              "gu": "અસ્મિતાને તેની અરજીના આધારે ટેલિફોનિક ઈન્ટર્વ્યુ માટે પસંદ કરવામાં આવે છે"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_2_2",
              "hi": "C5_2_2",
              "ka": "C5_2_2",
              "te": "C5_2_2",
              "or": "C5_2_2",
              "as": "C5_2_2",
              "gu": "C5_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "In the interview, Asmita answers questions about her education, expectations from the fellowship and her approach towards the work she will do.",
              "hi": "साक्षात्कार में, अस्मिता अपनी शिक्षा, फेलोशिप से आशाएं और काम के प्रति उनके दृष्टिकोण के बारे में प्रश्नों के उत्तर देती है।",
              "ka": "ಸಂದರ್ಶನದಲ್ಲಿ, ಅಸ್ಮಿತಾ ತನ್ನ ಶಿಕ್ಷಣದ ಬಗ್ಗೆ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸುತ್ತಾಳೆ, ಫೆಲೋಶಿಪ್‌ನಿಂದ ನಿರೀಕ್ಷೆಗಳು ಮತ್ತು ತಾನು ಮಾಡುವ ಕೆಲಸದ ಕಡೆಗೆ ಅವಳ ವಿಧಾನದ ಬಗ್ಗೆ ಉತ್ತರಿಸುತ್ತಾಳೆ.",
              "te": "ఇంటర్వ్యూలో, అస్మిత తన చదువు గురించి, ఫెలోషిప్ నుండి వచ్చే అంచనాలు మరియు తను చేయబోయే పని పట్ల ఆమె విధానానికి సంబంధించిన ప్రశ్నలకు సమాధానమిస్తుంది.",
              "or": "ସାକ୍ଷାତକାରରେ, ଅସ୍ମିତା ତାଙ୍କ ଶିକ୍ଷା, ଫେଲୋସିପ୍ ଠାରୁ ଆଶା ଏବଂ ସେ କରିବା ପାଇଁ କାର୍ଯ୍ୟ ପ୍ରତି ତାଙ୍କର ଆଭିମୁଖ୍ୟ ବିଷୟରେ ପ୍ରଶ୍ନର ଉତ୍ତର ଦିଅନ୍ତି |",
              "as": "সাক্ষাৎকাৰটোত, অস্মিতাই তাইৰ শিক্ষা, ফেলোশ্বিপৰ বাবে কৰা আশা আৰু তাই কৰিব লগা কামৰ প্ৰতি তাইৰ দৃষ্টিভংগীৰ বিষয়ে প্ৰশ্নৰ উত্তৰ দিয়ে।",
              "gu": "ઈન્ટર્વ્યુમાં અસ્મિતા પોતાના શિક્ષણ, ફેલોશિપથી આશા અને કામ પ્રત્યે તેનો દ્રષ્ટિકોણ અંગે પ્રશ્નોના જવાબ આપે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_2_3",
              "hi": "C5_2_3",
              "ka": "C5_2_3",
              "te": "C5_2_3",
              "or": "C5_2_3",
              "as": "C5_2_3",
              "gu": "C5_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "A few weeks after the interview, she travels to their Hyderabad office for the final stage of the selection process.",
              "hi": "साक्षात्कार के कुछ सप्ताह बाद, वह चयन प्रक्रिया के अंतिम चरण के लिए अपने हैदराबाद कार्यालय की यात्रा करती है।",
              "ka": "ಸಂದರ್ಶನದ ಕೆಲವು ವಾರಗಳ ನಂತರ, ಆಯ್ಕೆ ಪ್ರಕ್ರಿಯೆಯ ಅಂತಿಮ ಹಂತಕ್ಕಾಗಿ ಅವರು ತಮ್ಮ ಹೈದರಾಬಾದ್ ಕಚೇರಿಗೆ ಪ್ರಯಾಣಿಸುತ್ತಾರೆ.",
              "te": "ఇంటర్వ్యూ ముగిసిన కొన్ని వారాల తర్వాత, ఎంపిక ప్రక్రియ యొక్క చివరి దశ కోసం ఆమె వారి హైదరాబాద్ కార్యాలయానికి వెళుతుంది.",
              "or": "ସାକ୍ଷାତକାରର କିଛି ସପ୍ତାହ ପରେ, ସେ ଚୟନ ପ୍ରକ୍ରିୟାର ଅନ୍ତିମ ପର୍ଯ୍ୟାୟ ପାଇଁ ସେମାନଙ୍କ ହାଇଦ୍ରାବାଦ କାର୍ଯ୍ୟାଳୟକୁ ଯାତ୍ରା କରନ୍ତି |",
              "as": "সাক্ষাৎকাৰৰ কেইসপ্তাহমান পিছত, তেওঁ বাছনি প্ৰক্ৰিয়াৰ অন্তিম পৰ্যায়ৰ বাবে তেওঁলোকৰ হায়দৰাবাদ অফিচলৈ যায়।",
              "gu": "ઈન્ટર્વ્યુના કેટલાક સપ્તાહ બાદ, તે પસંદગીની પ્રક્રિયાના અંતિમ તબક્કા માટે તે હૈદરાબાદ ઓફિસ જાય છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_2_4",
              "hi": "C5_2_4",
              "ka": "C5_2_4",
              "te": "C5_2_4",
              "or": "C5_2_4",
              "as": "C5_2_4",
              "gu": "C5_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is most nervous about the group discussion since she hasn’t had many interactions with people during the distance learning course.",
              "hi": "अस्मिता समूह चर्चा के बारे में बहुत अधिक घबड़ा रही है क्योंकि दूरस्थ शिक्षा पाठ्यक्रम के दौरान लोगों के साथ उसकी अधिक बातचीत नहीं हुई है।",
              "ka": "ಅಸ್ಮಿತಾ ದೂರಶಿಕ್ಷಣ ಕೋರ್ಸ್‌ನಲ್ಲಿ ಜನರೊಂದಿಗೆ ಹೆಚ್ಚು ಸಂವಹನ ನಡೆಸದ ಕಾರಣ ಗುಂಪು ಚರ್ಚೆಯ ಬಗ್ಗೆ ಹೆಚ್ಚು ಆತಂಕಕ್ಕೊಳಗಾಗಿದ್ದಾಳೆ.",
              "te": "అస్మిత దూరవిద్యా కోర్సులో వ్యక్తులతో పెద్దగా మాట్లాడనందున గ్రూప్ డిస్కషన్ గురించి చాలా భయపడుతుంది.",
              "or": "ଦୂରସ୍ଥ ଶିକ୍ଷା କୋର୍ସ୍‌ ସମୟରେ ଲୋକଙ୍କ ସହ ତାଙ୍କର କୌଣସି ପାରସ୍ପରିକ ସମ୍ପର୍କ ନ ଥିବାରୁ ଅସ୍ମିତା ସମୁଦାୟ ଆଲୋଚନା ବିଷୟ ଅଧିକ ନର୍ଭସ୍‌ ଅଛନ୍ତି |",
              "as": "অস্মিতা গোট আলোচনাক লৈ আটাইতকৈ বেছি উদ্বিগ্ন কিয়নো দূৰত্ব শিক্ষণ পাঠ্যক্ৰমৰ সময়ত মানুহৰ সৈতে তাইৰ বহুত বেছি কথা-বতৰা হোৱা নাই।",
              "gu": "અસ્મિતા ગ્રુપ ચર્ચાને લઈ સૌથી વધારે ગભરાયેલ છે કારણ કે ડિસ્ટન્સ લર્નિંગ કોર્સ સમયે લોકો સાથે તેની વધારે વાતચીત થઈ હતી નહીં."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_3_1",
              "hi": "C5_3_1",
              "ka": "C5_3_1",
              "te": "C5_3_1",
              "or": "C5_3_1",
              "as": "C5_3_1",
              "gu": "C5_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Roshni tries to help her practice by simulating a group discussion with her and Asmita as the participants.",
              "hi": " एक समूह चर्चा का अनुकरण करके रोशनी एक प्रतिभागी के रूप में अस्मिता के साथ उसके अभ्यास में सहायता करने का प्रयास करती है।",
              "ka": "ರೋಶ್ನಿ ತನ್ನ ಮತ್ತು ಅಸ್ಮಿತಾ ಭಾಗವಹಿಸುವವರೊಂದಿಗೆ ಗುಂಪು ಚರ್ಚೆಯನ್ನು ಅನುಕರಿಸುವ ಮೂಲಕ ತನ್ನ ಅಭ್ಯಾಸಕ್ಕೆ ಸಹಾಯ ಮಾಡಲು ಪ್ರಯತ್ನಿಸುತ್ತಾಳೆ.",
              "te": "రోష్ని ఆమె మరియు అస్మిత గ్రూప్ డిస్కషన్ లో పాల్గొన్నట్లు చేసి ఆమె నేర్చుకోవడంలో సహాయపడటానికి ప్రయత్నిస్తుంది.",
              "or": "ଅଂଶଗ୍ରହଣକାରୀ ଭାବରେ ତାଙ୍କ ଏବଂ ଅସ୍ମିତାଙ୍କ ସହ ଏକ ସମୁଦାୟ ଆଲୋଚନାକୁ ଅନୁକରଣ କରି ରୋଶନି ତାଙ୍କ ଅଭ୍ୟାସରେ ସାହାଯ୍ୟ କରିବାକୁ ଚେଷ୍ଟା କରନ୍ତି |",
              "as": "ৰোশনিয়ে অস্মিতাৰ সৈতে অংশগ্ৰহণকাৰী হিচাপে এটা গোট আলোচনাৰ অনুকৰণ কৰি তাইৰ অনুশীলনত সহায় কৰিবলৈ চেষ্টা কৰে।",
              "gu": "રોશની તેના અને અસ્મિતા સાથે એક સમૂહ ચર્ચાનું અનુકરણ કરી તેના અભ્યાસમાં મદદ કરવાનો પ્રયત્ન કરે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_3_2",
              "hi": "C5_3_2",
              "ka": "C5_3_2",
              "te": "C5_3_2",
              "or": "C5_3_2",
              "as": "C5_3_2",
              "gu": "C5_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "On the day of the final stage, Asmita goes into the group discussion along with three other applicants.",
              "hi": "अंतिम चरण के दिन, अस्मिता तीन अन्य आवेदकों के साथ समूह चर्चा में जाती है।",
              "ka": "ಅಂತಿಮ ಹಂತದ ದಿನದಂದು, ಅಸ್ಮಿತಾ ಇತರ ಮೂವರು ಅರ್ಜಿದಾರರೊಂದಿಗೆ ಗುಂಪು ಚರ್ಚೆಗೆ ಹೋಗುತ್ತಾರೆ.",
              "te": "చివరి దశ రోజున, అస్మిత మరో ముగ్గురు అప్లికెంట్స్ తో కలిసి గ్రూప్ డిస్కషన్‌కి వెళుతుంది.",
              "or": "ଅନ୍ତିମ ପର୍ଯ୍ୟାୟ ଦିନ, ଅସ୍ମିତା ଅନ୍ୟ ତିନି ଆବେଦନକାରୀଙ୍କ ସହିତ ସମୁଦାୟ ଆଲୋଚନାକୁ ଯାଆନ୍ତି |",
              "as": "শেষৰ পৰ্যায়ৰ দিনা, অস্মিতাই আন তিনিজন আবেদনকাৰীৰ সৈতে গোট আলোচনালৈ যায়।",
              "gu": "અંતિમ તબક્કાના દિવસે અસ્મિતા ત્રણ અન્ય અરજદારો સાથે સમૂહ ચર્ચામાં જાય છે."
            }
          }
        ],
        "page23": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_3_2b///",
              "hi": "C5_3_2B ////",
              "ka": "C5_3_2b///",
              "te": "C5_3_2b///",
              "or": "C5_3_2b///",
              "as": "C5_3_2b///",
              "gu": "C5_3_2b///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "The moderator is impressed with the points Asmita has to contribute because of her in-depth understanding of the policies that affect the lives of transgender people and her background in political science.",
              "hi": "ट्रांसजेंडर लोगों के जीवन को प्रभावित करने वाली नीतियों और राजनीति विज्ञान में उनकी पृष्ठभूमि को प्रभावित करने वाली गहरी समझ के कारण मॉडरेटर उन बिंदुओं से प्रभावित हैं, जिनका योगदान अस्मिता को करना है।",
              "ka": "ಟ್ರಾನ್ಸ್‌ಜೆಂಡರ್ ಜನರ ಜೀವನದ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುವ ನೀತಿಗಳು ಮತ್ತು ರಾಜಕೀಯ ವಿಜ್ಞಾನದಲ್ಲಿ ಅವರ ಹಿನ್ನೆಲೆಯ ಆಳವಾದ ತಿಳುವಳಿಕೆಯಿಂದಾಗಿ ಅಸ್ಮಿತಾ ಅವರು ಕೊಡುಗೆ ನೀಡಬೇಕಾದ ಅಂಶಗಳಿಂದ ಮಾಡರೇಟರ್ ಪ್ರಭಾವಿತರಾಗಿದ್ದಾರೆ.",
              "te": "ట్రాన్స్ జెండర్ వ్యక్తుల జీవితాలను ప్రభావితం చేసే విధానాలపై లోతైన అవగాహన మరియు రాజకీయ శాస్త్రంలో ఆమె అనుభవం కారణంగా అస్మిత చెప్పిన పాయింట్లతో మోడరేటర్ ఇంప్రెస్ అయ్యారు.",
              "or": "ଟ୍ରାନ୍ସଜେଣ୍ଡର୍‍ ଲୋକଙ୍କ ଜୀବନ ଏବଂ ରାଜନୈତିକ ବିଜ୍ଞାନରେ ତାଙ୍କର ପୃଷ୍ଠଭୂମି ଉପରେ ପ୍ରଭାବ ପକାଉଥିବା ନୀତିଗୁଡିକ ବିଷୟରେ ଗଭୀର ଭାବରେ ବୁଝିବା ହେତୁ ମୋଡରେଟର୍ ଏହି ସବୁ ପଏଣ୍ଟ ଦ୍ୱାରା ପ୍ରଭାବିତଯାହାର ଅବଦାନ ଅସ୍ମିତାଙ୍କୁ କରିବାକୁ ପଡିବ |",
              "as": "ট্ৰেন্সজেণ্ডাৰ লোকসকলৰ জীৱনত প্ৰভাব পেলাব পৰা নীতিবোৰৰ বিযয়ে থকা গভীৰ জ্ঞানৰ লগতে ৰাজনীতি বিজ্ঞান বিভাগৰ পৰা অহা বাবে অস্মিতাই যিবোৰ বিযয় আগবঢ়াব পাৰিছে তাকে দেখি সঞ্চালকজন প্ৰভাৱিত হৈছে।",
              "gu": "ટ્રાન્સલિંગ લોકોના જીવનને અસર કરનારી નીતિઓ અને પોલિટીકલ સાયન્સમાં તેમની પુષ્ઠભૂમિને અસર અસર કરનારી નીતિઓના ઉંડી સમજને લીધે મોડરેટર એવા મુદ્દાથી પ્રભાવિત છે કે જે તેનું યોગદાન અસ્મિતાએ કરવાનું છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_4_1",
              "hi": "C5_4_1",
              "ka": "C5_4_1",
              "te": "C5_4_1",
              "or": "C5_4_1",
              "as": "C5_4_1",
              "gu": "C5_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "When the results are announced, Asmita is among the 15 people selected for the fellowship.",
              "hi": "जब परिणाम घोषित किए जाते हैं, तो अस्मिता फैलोशिप के लिए चुने गए 15 लोगों में से एक है।",
              "ka": "ಫಲಿತಾಂಶ ಪ್ರಕಟವಾದಾಗ ಫೆಲೋಶಿಪ್‌ಗೆ ಆಯ್ಕೆಯಾದ 15 ಮಂದಿಯಲ್ಲಿ ಅಸ್ಮಿತಾ ಕೂಡ ಇದ್ದಾರೆ.",
              "te": "ఫలితాలు వెలువడినప్పుడు, ఫెలోషిప్‌కు ఎంపికైన 15 మందిలో అస్మిత కూడా ఉంది.",
              "or": "ଯେତେବେଳେ ଫଳାଫଳ ଘୋଷଣା ହୁଏ, ଫେଲୋସିପ୍ ପାଇଁ ମନୋନୀତ 15 ଜଣଙ୍କ ମଧ୍ୟରେ ଅସମିତା ଅଛନ୍ତି |",
              "as": "যেতিয়া ফলাফল ঘোষণা কৰা হয়, ফেলোশ্বিপৰ বাবে বাছনি কৰা ১৫জন লোকৰ ভিতৰত অস্মিতা আছে।",
              "gu": "જ્યારે પરિણામ જાહેર થાય છે તો અસ્મિતા ફેલોશિપ માટે પસંદ કરવામાં આવેલ 15 લોકોમાં સામેલ છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_4_2",
              "hi": "C5_4_2",
              "ka": "C5_4_2",
              "te": "C5_4_2",
              "or": "C5_4_2",
              "as": "C5_4_2",
              "gu": "C5_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is happy that her efforts have brought her here despite all the struggles. Her family is incredibly proud of her.",
              "hi": "अस्मिता खुश है कि उसके प्रयास अनेक संघर्षों के बावजूद फलीभूत हुए हैं। उसके परिवार को उस पर अविश्वसनीय रूप से गर्व है।",
              "ka": "ಎಷ್ಟೇ ಕಷ್ಟ ಪಟ್ಟರೂ ತನ್ನ ಪ್ರಯತ್ನ ಇಲ್ಲಿಗೆ ತಂದಿರುವುದಕ್ಕೆ ಅಸ್ಮಿತಾ ಖುಷಿಯಾಗಿದ್ದಾರೆ. ಅವಳ ಕುಟುಂಬವು ಅವಳ ಬಗ್ಗೆ ನಂಬಲಾಗದಷ್ಟು ಹೆಮ್ಮೆಪಡುತ್ತದೆ.",
              "te": "ఎన్ని కష్టాలు ఎదురైనా తన కృషి ఇక్కడికి తీసుకొచ్చిందని అస్మిత సంతోషిస్తోంది. ఆమె కుటుంబం ఆమె గురించి చాలా గర్వంగా ఉంది.",
              "or": "ଅସ୍ମିତା ଖୁସି ଯେ ସମସ୍ତ ସଂଘର୍ଷ ସତ୍ତ୍ବେ ତାଙ୍କର ଉଦ୍ୟମ ତାଙ୍କୁ ଏଠାକୁ ଆଣିଛି | ତାଙ୍କ ପରିବାର ତାଙ୍କ ପାଇଁ ଅବିଶ୍ବାସ୍ୟ ଗର୍ବିତ |",
              "as": "অস্মিতা সুখী যে তাইৰ প্ৰচেষ্টাই সকলো সংগ্ৰামৰ পিছতো তাইক ইয়ালৈ লৈ আহিছে। তাইৰ পৰিয়ালে তাইক লৈ অবিশ্বাস্যভাৱে গৌৰৱান্বিত।",
              "gu": "અસ્મિતા ખુશ છે કે તેના પ્રયત્નોએ તેને તમામ સંઘર્ષો વચ્ચે અહીં લાવ્યા છે. તેના પરિવારને તેની ઉપર અસાધારણ ગર્વ છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_5_1",
              "hi": "C5_5_1",
              "ka": "C5_5_1",
              "te": "C5_5_1",
              "or": "C5_5_1",
              "as": "C5_5_1",
              "gu": "C5_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Meanwhile, Asmita gets an email from Neelam. She is surprised because she had forgotten that she had shared her project with her and is excited to see what she has to say about her thesis.",
              "hi": "इस बीच, अस्मिता को नीलम का एक ईमेल मिलता है। वह हैरान है क्योंकि वह भूल गई थी कि उसने उसके साथ अपना प्रोजेक्ट साझा किया था और यह देखने के लिए उत्साहित है कि उसे अपनी थीसिस के बारे में क्या कहना है।",
              "ka": "ಅಷ್ಟರಲ್ಲಿ ಅಸ್ಮಿತಾಗೆ ನೀಲಂನಿಂದ ಇಮೇಲ್ ಬರುತ್ತದೆ. ಅವಳು ತನ್ನ ಪ್ರಾಜೆಕ್ಟ್ ಅನ್ನು ಅವಳೊಂದಿಗೆ ಹಂಚಿಕೊಂಡಿದ್ದನ್ನು ಮರೆತಿದ್ದರಿಂದ ಅವಳು ಆಶ್ಚರ್ಯ ಪಡುತ್ತಾಳೆ ಮತ್ತು ತನ್ನ ಪ್ರಬಂಧದ ಬಗ್ಗೆ ಅವಳು ಏನು ಹೇಳಬೇಕೆಂದು ನೋಡಲು ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ.",
              "te": "ఇంతలో అస్మితకి నీలం నుండి ఇమెయిల్ వచ్చింది. ఆమె తన ప్రాజెక్ట్‌ను తనకి పంపిన విషయాన్ని మరచిపోయినందున ఆమె ఆశ్చర్యానికి గురవుతుంది మరియు ఆమె తన థీసిస్ గురించి ఏమి చెబుతుందో చూడటానికి ఉత్సాహంగా ఉంది.",
              "or": "ଏହି ସମୟରେ, ଅସ୍ମିତା ନୀଲମଙ୍କଠାରୁ ଏକ ଇମେଲ୍‌ ପାଆନ୍ତି | ସେ ଆଶ୍ଚର୍ଯ୍ୟ ହୋଇଯାଇଛନ୍ତି କାରଣ ସେ ଭୁଲି ଯାଇଛନ୍ତି ଯେ ସେ ତାଙ୍କ ସହିତ ତାଙ୍କ ପ୍ରୋଜେକ୍ଟ ଅଂଶୀଦାର କରିଛନ୍ତି ଏବଂ ତାଙ୍କ ଥିସିସ୍‌ ବିଷୟରେ ସେ କ’ଣ କହିବାକୁ ଉତ୍ସାହିତ ଅଛନ୍ତି |",
              "as": "ইফালে, অস্মিতাই নীলমৰ পৰা এটা ইমেইল পাইছে। তাই আচৰিত হৈছিল কাৰণ তাই পাহৰি গৈছিল যে তাই তাইৰ প্ৰকল্পটো তাইৰ সৈতে আলোচনা কৰিছিল আৰু তাইৰ গৱেষণাপত্ৰৰ বিষয়ে তাই কি কয় চাবলৈ উৎসাহিত হৈছিল।",
              "gu": "દરમિયાન, અસ્મિતાને નીલમને એક ઈમેલ મોકલે છે. તેને આશ્ચર્ય થાય છે કારણ કે તે ભૂલી ગઈ હતી કે તેણીનીએ તેની સાથે પ્રોજેક્ટ શેર કર્યો હતો અને તે જોવા માટે તે ઉત્સાહિત છે કે તેને પોતાના થિસિસ અંગે શું કહેવું છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_5_2_email",
              "hi": "C5_5_2_email",
              "ka": "C5_5_2_email",
              "te": "C5_5_2_ఇమెయిల్",
              "or": "C5_5_2_email",
              "as": "C5_5_2_email",
              "gu": "C5_5_2_email"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailTo",
            "value": {
              "en": "neelam@arise.org",
              "hi": "neelam@arise.org",
              "ka": "neelam@arise.org",
              "te": "neelam@arise.org",
              "or": "neelam@arise.org",
              "as": "neelam@arise.org",
              "gu": "neelam@arise.org"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailSubject",
            "value": {
              "en": "Offer to work with Arise",
              "hi": "अराइज के साथ काम करने का प्रस्ताव ",
              "ka": "ಎರೈಸ್ ಜೊತೆ ಕೆಲಸ ಮಾಡಲು ಆಫರ್",
              "te": "అరైజ్‌తో కలిసి పనిచేయడానికి ఆఫర్",
              "or": "ଅରାଇଜ୍‌ ସହିତ କାମ କରିବାକୁ ଅଫର୍",
              "as": "এৰাইজৰ সৈতে কাম কৰাৰ প্ৰস্তাৱ",
              "gu": "અરાઈઝ સાથે કાર્યની ઓફર"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailMsg",
            "value": {
              "en": "Hello Asmita\n\nWe had a look at your project and are very impressed. In light of the same, we wanted to extend an offer to you. We’re doing a project with the Tamil Nadu Transgender Welfare Board to come up with proposals for the betterment of transgender people in the state.\nWe wanted to have you on the team as a research consultant on contract for six months. Given that you’ve done such promising work, we feel you’ll have valuable inputs to share.\n\nLet me know if you’re interested, and I’ll share more details.\n\nRegards,\nNeelam",
              "hi": "नमस्ते अस्मिता\n\nहमने आपके प्रोजेक्ट देखकर बहुत प्रभावित हुए। उसी के सन्दर्भ में, हम आपको एक प्रस्ताव देना चाहते थे। हम राज्य में ट्रांसजेंडर लोगों की बेहतरी के लिए प्रस्ताव लाने के लिए तमिलनाडु ट्रांसजेंडर वेलफेयर बोर्ड के साथ एक प्रोजेक्ट कर रहे हैं।\nहम आपको छह महीने के लिए अनुबंध पर एक शोध सलाहकार के रूप में टीम में रखना चाहते थे। यह देखते हुए कि आपने ऐसा आशाजनक काम किया है, हमें लगता है कि आपके पास साझा करने के लिए मूल्यवान सुझाव होंगे।\n\nमुझे बताएं कि क्या आपको इसमें रुचि है,  मैं और अधिक विवरण साझा करूंगी।\n\nसादर,\nनीलम",
              "ka": "\"ಹಲೋ ಅಸ್ಮಿತಾ\n \n ನಿಮ್ಮ ಪ್ರಾಜೆಕ್ಟ್ ಅನ್ನು ನಾವು ನೋಡಿದ್ದೇವೆ ಮತ್ತು ತುಂಬಾ ಪ್ರಭಾವಿತರಾಗಿದ್ದೇವೆ. ಅದೇ ಬೆಳಕಿನಲ್ಲಿ, ನಾವು ನಿನಗೆ ಆಫರ್ ಅನ್ನು ವಿಸ್ತರಿಸಲು ಬಯಸುತ್ತೇವೆ. ತಮಿಳುನಾಡು ಟ್ರಾನ್ಸ್‌ಜೆಂಡರ್ ಕಲ್ಯಾಣ ಮಂಡಳಿಯೊಂದಿಗೆ ರಾಜ್ಯದಲ್ಲಿ ಟ್ರಾನ್ಸ್‌ಜೆಂಡರ್‌ಗಳ ಸುಧಾರಣೆಗಾಗಿ ಪ್ರಸ್ತಾವನೆಗಳನ್ನು ತರಲು ನಾವು ಯೋಜನೆಯನ್ನು ಮಾಡುತ್ತಿದ್ದೇವೆ.\n ಆರು ತಿಂಗಳ ಕಾಲ ಒಪ್ಪಂದದ ಮೇಲೆ ಸಂಶೋಧನಾ ಸಲಹೆಗಾರರಾಗಿ ತಂಡದಲ್ಲಿ ನಿಮ್ಮನ್ನು ಹೊಂದಲು ನಾವು ಬಯಸುತ್ತೇವೆ. ನೀವು ಅಂತಹ ಭರವಸೆಯ ಕೆಲಸವನ್ನು ಮಾಡಿದ್ದೀರಿ ಎಂದು ನಾವು ಭಾವಿಸುತ್ತೇವೆ, ನೀವು ಹಂಚಿಕೊಳ್ಳಲು ಅಮೂಲ್ಯವಾದ ಇನ್‌ಪುಟ್‌ಗಳನ್ನು ಹೊಂದಿರುವಿರಿ ಎಂದು ನಾವು ಭಾವಿಸುತ್ತೇವೆ.\n \n ನಿನಗೆ ಆಸಕ್ತಿ ಇದ್ದರೆ ನನಗೆ ತಿಳಿಸಿ ಮತ್ತು ನಾನು ಹೆಚ್ಚಿನ ವಿವರಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತೇನೆ.\n \n ವಂದನೆಗಳು,\n ನೀಲಂ\"",
              "te": "హల్లో అస్మిత,\n మేము మీ ప్రాజెక్ట్‌ని పరిశీలించి చాలా ఇంప్రెస్ అయ్యాము. అందువల్ల, మేము మీకు ఆఫర్‌ని అందించాలనుకుంటున్నాము. రాష్ట్రంలోని ట్రాన్స్‌జెండర్ల అభ్యున్నతి కోసం మేము తమిళనాడు ట్రాన్స్‌జెండర్ వెల్ఫేర్ బోర్డ్‌తో ఒక ప్రాజెక్ట్‌ను తయారు చేస్తున్నాము.\n మేము మిమ్మల్ని ఆరు నెలల పాటు ఒప్పందంపై రీసర్చ్ కన్సల్టెంట్ గా బృందంలో చేర్చాలనుకుంటున్నాము. మీరు అలాంటి గొప్ప పనిని చేసినందున, మీరు విలువైన ఇన్‌పుట్‌లను ఇవ్వగలరు అని మేము భావిస్తున్నాము.\n \n మీకు ఆసక్తి ఉంటే నాకు తెలియజేయండి మరియు నేను మరిన్ని వివరాలను తెలుపుతాను.\n \n గౌరవంతో,\n నీలం",
              "or": "ନମସ୍କାର ଅସ୍ମିତା \n \n ଆମେ ତୁମର ପ୍ରୋଜେକ୍ଟକୁ ଦେଖିଲୁ ଏବଂ ବହୁତ ପ୍ରଭାବିତ | ସେହି ଦୃଷ୍ଟିରୁ, ଆମେ ଆପଣଙ୍କୁ ଏକ ଅଫର୍‍ ଦେବାକୁ ଚାହୁଁଥିଲୁ | ରାଜ୍ୟରେ ଟ୍ରାନ୍ସଜେଣ୍ଡର୍‌ ଲୋକଙ୍କ ଉନ୍ନତି ପାଇଁ ପ୍ରସ୍ତାବ ଆଣିବା ପାଇଁ ଆମେ ତାମିଲନାଡୁ ଟ୍ରାନ୍ସଜେଣ୍ଡର୍‌ ୱେଲଫେୟାର ବୋର୍ଡ ସହିତ ଏକ ପ୍ରୋଜେକ୍ଟ କରୁଛୁ।\n ଛଅ ମାସ ପାଇଁ ଚୁକ୍ତିନାମା ଉପରେ ଏକ ଅନୁସନ୍ଧାନ ପରାମର୍ଶଦାତା ଭାବରେ ଆମେ ଆପଣଙ୍କୁ ଦଳରେ ରଖିବାକୁ ଚାହୁଁଥିଲୁ | ଏହା ଯେ ଆପଣ ଏପରି ପ୍ରତିଜ୍ଞାକାରୀ କାର୍ଯ୍ୟ କରିସାରିଛନ୍ତି, ଆମେ ଅନୁଭବ କରୁଛୁ ଆପଣଙ୍କ ପାଖରେ ଅଂଶୀଦାର କରିବାକୁ ମୂଲ୍ୟବାନ ଇନପୁଟ୍ ଅଛି |\n \n ଯଦି ଆପଣ ଆଗ୍ରହୀ ମୋତେ ଜଣାନ୍ତୁ, ଏବଂ ମୁଁ ଅଧିକ ବିବରଣୀ ଅଂଶୀଦାର କରିବି |\n \n ଶୁଭେଚ୍ଛା,\n ନୀଲମ୍",
              "as": "হেল্ল অস্মিতা\n \n আমি আপোনাৰ প্ৰকল্পটো চালোঁ আৰু বহুত প্ৰভাৱিত হৈছো। ইয়াৰ পৰিপ্ৰেক্ষিতত, আমি আপোনাক এটা প্ৰস্তাৱ আগবঢ়াব বিচাৰিছিলো। আমি তামিলনাডু ট্ৰেন্সজেণ্ডাৰ ৱেলফেয়াৰ ব'ৰ্ডৰ সৈতে ৰাজ্যখনত ট্ৰেন্সজেণ্ডাৰ লোকসকলৰ উন্নতিৰ বাবে প্ৰস্তাৱ আনিবলৈ এটা প্ৰকল্প কৰি আছো।\n আমি আপোনাক ছয় মাহৰ বাবে চুক্তিত গৱেষণা পৰামৰ্শদাতা হিচাপে দলত ৰাখিব বিচাৰিছো। আপুনি এনে প্ৰতিশ্ৰুতিপূৰ্ণ কাম কৰিছে বুলি বিবেচনা কৰি, আমি অনুভৱ কৰোঁ যে আপোনাৰ ওচৰত ভাগ-বতৰা কৰিবলৈ মূল্যৱান তথ্য থাকিব।\n আপুনি আগ্ৰহী হয় নে নহয় মোক জনাব, আৰু মই বেছি বিতংকৈ আলোচনা কৰিম।\n \n সম্মানেৰে,\n নীলম",
              "gu": "નમસ્તે અસ્મિતામે તમારા પ્રોજેક્ટને જોયો છે અને હું તેનાથી ઘણી પ્રભાવિત થઈ છું. તેના સંદર્ભમાં હું તને એક પ્રસ્તાવ આપું છું. આપણે રાજ્યમાં ટ્રાન્સલિંગ લોકોના કલ્યાણ માટે દરખાસ્ત લાવવા માટે તમિલનાડુ ટ્રાન્સજેન્ટર વેલફેર બોર્ડની સાથે એક પ્રોજેક્ટ કરી રહ્યા છીએ.અમે તમને છ મહિના માટે સંબંધિક એક સંશોધન સલાહકાર તરીકે ટીમમાં રહેવા ઈચ્છીએ છીએ. આ જોતા તે આવું આશાજનક કાર્ય કર્યું છે, અમને લાગે છે કે તારી પાસે આ માહિતી શેર કરવી માટે માટે બહુમૂલ્ય ઈનપુટ હશે.મને જાણ કરીશ કે તું શેમા રસ ધરાવે છે, અને હું વધારે માહિતીને રજૂ કરીશ.સાદરનીલમ"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to read the email.",
              "hi": "ईमेल पढ़ने के लिए क्लिक करें।",
              "ka": "ಇಮೇಲ್ ಓದಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "ఇమెయిల్ చదవడానికి క్లిక్ చేయండి.",
              "or": "ଇମେଲ୍ ପଢିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "ইমেইলটো পঢ়িবলৈ ক্লিক কৰক।",
              "gu": "ઈમેલ વાંચવા માટે ક્લિક કરો."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_5_3",
              "hi": "C5_5_3",
              "ka": "C5_5_3",
              "te": "C5_5_3",
              "or": "C5_5_3",
              "as": "C5_5_3",
              "gu": "C5_5_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is excited, but at the same time, very confused. She now has two opportunities, and she doesn’t know which one to take up.",
              "hi": "अस्मिता उत्साहित है, लेकिन साथ ही, बहुत उलझन में है। उसके पास अब दो अवसर हैं, और वह नहीं जानती कि कौन सा लेना है।",
              "ka": "ಅಸ್ಮಿತಾ ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ, ಆದರೆ ಅದೇ ಸಮಯದಲ್ಲಿ ತುಂಬಾ ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದಾಳೆ. ಅವಳು ಈಗ ಎರಡು ಅವಕಾಶಗಳನ್ನು ಹೊಂದಿದ್ದಾಳೆ ಮತ್ತು ಯಾವುದನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕೆಂದು ಅವಳು ತಿಳಿದಿಲ್ಲ.",
              "te": "అస్మిత ఉత్సాహంగా ఉంది, కానీ అదే సమయంలో, చాలా గందరగోళంగా ఉంది. ఆమెకు ఇప్పుడు రెండు అవకాశాలు ఉన్నాయి మరియు ఏది తీసుకోవాలో ఆమెకు తెలియట్లేదు.",
              "or": "ଅସ୍ମିତା ଉତ୍ସାହିତ, କିନ୍ତୁ ସେହି ସମୟରେ ବହୁତ ଦ୍ୱନ୍ଦ୍ୱରେ | ତାଙ୍କର ବର୍ତ୍ତମାନ ଦୁଇଟି ସୁଯୋଗ ଅଛି, ଏବଂ ସେ ଜାଣନ୍ତି ନାହିଁ କେଉଁଟି ନେବାକୁ ଅଛି |",
              "as": "অস্মিতা উত্তেজিত, কিন্তু একে সময়তে, বহুত বিভ্ৰান্তও হৈছে। তাইৰ ওচৰত এতিয়া দুটা সুযোগ আছে, আৰু কোনটো ল'ব লাগে তাই নাজানে।",
              "gu": "અસ્મિતા ઉત્સાહિત છે, પણ આ સાથે તે ગુંચવણભરી સ્થિતિ પણ ધરાવે છે. તેણીની પાસે હવે બે તક રહેલી છે, અને તેણીની જાણતી નથી કે તે કોને ગ્રહણ કરે છે."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_5_4",
              "hi": "C5_5_4",
              "ka": "C5_5_4",
              "te": "C5_5_4",
              "or": "C5_5_4",
              "as": "C5_5_4",
              "gu": "C5_5_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After putting in all the effort to clear the selection procedure for the Transform fellowship, she feels bad to let it go.",
              "hi": "ट्रांसफॉर्म फेलोशिप के लिए चयन प्रक्रिया को पूरा करने के सभी प्रयास करने के बाद, उसे इसे जाने देने में बुरा लगता है।",
              "ka": "ಟ್ರಾನ್ಸ್‌ಫಾರ್ಮ್ ಫೆಲೋಶಿಪ್‌ಗಾಗಿ ಆಯ್ಕೆ ವಿಧಾನವನ್ನು ತೆರವುಗೊಳಿಸಲು ಎಲ್ಲಾ ಪ್ರಯತ್ನಗಳನ್ನು ಮಾಡಿದ ನಂತರ, ಅದನ್ನು ಬಿಡಲು ಬೇಸರವಾಗುತ್ತದೆ.",
              "te": "ట్రాన్స్‌ఫార్మ్ ఫెలోషిప్ కోసం ఎంపిక ప్రక్రియను క్లియర్ చేయడానికి అన్ని ప్రయత్నాలు చేసిన తర్వాత, దానిని వదిలివేయడం ఆమెకి బాధగా ఉంది.",
              "or": "ଟ୍ରାନ୍ସଫର୍ମ ଫେଲୋସିପ୍ ପାଇଁ ଚୟନ ପ୍ରକ୍ରିୟା ସମପ୍ତ କରିବାକୁ ସମସ୍ତ ପ୍ରୟାସ କରିବା ପରେ, ସେ ଏହାକୁ ଛାଡିବାକୁ ଖରାପ ଅନୁଭବ କରନ୍ତି |",
              "as": "ট্ৰান্সফৰ্ম ফেলোশ্বিপৰ বাবে বাছনি প্ৰক্ৰিয়াটো সম্পূৰ্ণ কৰাৰ বাবে সকলো প্ৰচেষ্টা কৰাৰ পিছত, তাই ইয়াক এৰি দিবলৈ বেয়া পাইছে।",
              "gu": "ટ્રાન્સફોર્મ ફેલોશિપ માટે પસંદગીની પ્રક્રિયાને પૂરી કરવા માટે તમામ પ્રયત્ન કર્યાં બાદ, તેણીનીને જવામાં ખરાબ લાગે છે."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_5_5",
              "hi": "C5_5_5",
              "ka": "C5_5_5",
              "te": "C5_5_5",
              "or": "C5_5_5",
              "as": "C5_5_5",
              "gu": "C5_5_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "At the same time, she sees that she will become financially independent with the offer that Arise was giving her.",
              "hi": "साथ ही, वह देखती है कि अराइज उसे जो प्रस्ताव दे रहा था, उससे वह आर्थिक रूप से स्वतंत्र हो जाएगी।",
              "ka": "ಅದೇ ಸಮಯದಲ್ಲಿ, ಎರೈಸ್ ತನಗೆ ನೀಡುತ್ತಿದ್ದ ಪ್ರಸ್ತಾಪದಿಂದ ಅವಳು ಆರ್ಥಿಕವಾಗಿ ಸ್ವತಂತ್ರಳಾಗುತ್ತಾಳೆ ಎಂದು ಅವಳು ನೋಡುತ್ತಾಳೆ.",
              "te": "అదే స‌మ‌యంలో అరైజ్ ఇస్తున్న ఆఫ‌ర్‌తో ఆర్థికంగా బాగుంటుందని చూస్తుంది.",
              "or": "ଏକାସାଙ୍ଗରେ, ସେ ଦେଖନ୍ତି ଯେ ସେ ଆର୍ଥିକ ଭାବେ ସ୍ୱାଧୀନ ହେବେ ଯାହା ଅରାଇଜ୍‌ ତାଙ୍କୁ ଦେଉଛି |",
              "as": "একে সময়তে, তাই দেখিলে যে এৰাইজে তাইক দিয়া প্ৰস্তাৱটোৰ দ্বাৰা তাই আৰ্থিকভাৱে স্বতন্ত্ৰ হ'ব।",
              "gu": "આ સાથે તે જુએ છે કે અરાઈઝ તેને જે પ્રસ્તાવ આપ્યો હતો તે આર્થિક રીતે સ્વતંત્ર થઈ જશે"
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_6_1",
              "hi": "C5_6_1",
              "ka": "C5_6_1",
              "te": "C5_6_1",
              "or": "C5_6_1",
              "as": "C5_6_1",
              "gu": "C5_6_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She decides to list the positives and negatives of the two opportunities to help her make this decision.",
              "hi": "साथ ही, वह देखती है कि अराइज उसे जो प्रस्ताव दे रहा था, उससे वह आर्थिक रूप से स्वतंत्र हो जाएगी।",
              "ka": "ಈ ನಿರ್ಧಾರವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡಲು ಎರಡು ಅವಕಾಶಗಳ ಧನಾತ್ಮಕ ಮತ್ತು ಋಣಾತ್ಮಕತೆಯನ್ನು ಪಟ್ಟಿ ಮಾಡಲು ಅವಳು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
              "te": "ఈ నిర్ణయం తీసుకోవడంలో ఆమెకు సహాయపడటానికి ఆమె రెండు అవకాశాల యొక్క పాజిటివ్ మరియు నెగటివ్ అంశాలను లిస్ట్ చేయాలని నిర్ణయించుకుంది.",
              "or": "ତାଙ୍କୁ ଏହି ନିଷ୍ପତ୍ତି ନେବାରେ ସାହାଯ୍ୟ କରିବାକୁ ସେ ଦୁଇଟି ସୁଯୋଗର ସକାରାତ୍ମକତା ଏବଂ ନକାରାତ୍ମକତାକୁ ତାଲିକାଭୁକ୍ତ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
              "as": "তাই এই সিদ্ধান্ত লোৱাত সহায় কৰিবলৈ দুয়োটা সুযোগৰ ইতিবাচক আৰু নেতিবাচক তালিকা বনোৱাৰ সিদ্ধান্ত লয়।",
              "gu": "તે આ નિર્ણય લેવામાં મદદરૂપ બનવા માટે બે તકને હકારાત્મક અને નકારાત્મકની યાદી કરવા માટે નિર્ણય લે છે"
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_6_2_reveal",
              "hi": "C5_6_2_रिवील ",
              "ka": "C5_6_2_reveal",
              "te": "C5_6_2_రివీల్",
              "or": "C5_6_2_reveal",
              "as": "C5_6_2_reveal",
              "gu": "C5_6_2_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "TRANSFORM FELLOWSHIP",
              "hi": "ट्रांसफॉर्म फेलोशिप",
              "ka": "ಫೆಲೋಶಿಪ್ ಅನ್ನು ಪರಿವರ್ತಿಸಿ",
              "te": "ట్రాన్స్ఫార్మ్ ఫెలోషిప్‌",
              "or": "ଟ୍ରାନ୍ସଫର୍ମ ଫେଲୋସିପ୍",
              "as": "ফেলোশ্বিপ ৰূপান্তৰ কৰক",
              "gu": "ટ્રાન્સફોર્મ ફેલોશિપ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Positive 1",
              "hi": "सकारात्मक 1 ",
              "ka": "ಧನಾತ್ಮಕ 1",
              "te": "పాజిటివ్ 1",
              "or": "Positive 1",
              "as": "ভালদিশ ১",
              "gu": "હકારાત્મક 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Positive 2",
              "hi": "सकारात्मक 2 ",
              "ka": "ಧನಾತ್ಮಕ 2",
              "te": "పాజిటివ్ 2",
              "or": "Positive 2",
              "as": "ভালদিশ ২",
              "gu": "હકારાત્મક 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Positive 3",
              "hi": "सकारात्मक 3 ",
              "ka": "ಧನಾತ್ಮಕ 3",
              "te": "పాజిటివ్ 3",
              "or": "Positive 3",
              "as": "ভালদিশ ৩",
              "gu": "હકારાત્મક 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "Negative 1",
              "hi": "नकारात्मक 1 ",
              "ka": "ಋಣಾತ್ಮಕ 1",
              "te": "నెగటివ్ 1",
              "or": "Negative 1",
              "as": "বেয়াদিশ ১",
              "gu": "નકારાત્મક 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.4.beforeReveal",
            "value": {
              "en": "Negative 2",
              "hi": "नकारात्मक 2 ",
              "ka": "ಋಣಾತ್ಮಕ 2",
              "te": "నెగటివ్ 2",
              "or": "Negative 2",
              "as": "বেয়াদিশ ২",
              "gu": "નકારાત્મક 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.5.beforeReveal",
            "value": {
              "en": "Negative 3",
              "hi": "नकारात्मक 3 ",
              "ka": "ಋಣಾತ್ಮಕ 3",
              "te": "నెగటివ్ 3",
              "or": "Negative 3",
              "as": "বেয়াদিশ ৩",
              "gu": "નકારાત્મક 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Stable work and earning for 2 years",
              "hi": "स्थिर काम और 2 वर्ष का वेतन ",
              "ka": "2 ವರ್ಷಗಳ ಕಾಲ ಸ್ಥಿರ ಕೆಲಸ ಮತ್ತು ಗಳಿಕೆ",
              "te": "2 సంవత్సరాల పాటు స్థిరమైన పని మరియు సంపాదన",
              "or": "2 ବର୍ଷ ପାଇଁ ସ୍ଥିର କାର୍ଯ୍ୟ ଏବଂ ରୋଜଗାର",
              "as": "২ বছৰৰ বাবে স্থিৰ কাম আৰু উপাৰ্জন",
              "gu": "સ્થિર કાર્ય અને 2 વર્ષ માટે કમાણી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Good mix of learning and work experience",
              "hi": "सीखने और कार्य अनुभव का अच्छा मिश्रण\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n.",
              "ka": "ಕಲಿಕೆ ಮತ್ತು ಕೆಲಸದ ಅನುಭವದ ಉತ್ತಮ ಮಿಶ್ರಣ",
              "te": "అభ్యాసం మరియు పని అనుభవం యొక్క మంచి కలయిక",
              "or": "ଶିକ୍ଷଣ ଏବଂ କାର୍ଯ୍ୟ ଅଭିଜ୍ଞତାର ଭଲ ମିଶ୍ରଣ",
              "as": "শিকা আৰু কামৰ অভিজ্ঞতাৰ এটা ভাল মিশ্ৰণ",
              "gu": "અભ્યાસ અને કાર્ય અનુભવનું સારું મિશ્રણ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "Opportunity to explore variety of work",
              "hi": "काम की विविधता का पता लगाने का अवसर",
              "ka": "ವಿವಿಧ ಕೆಲಸಗಳನ್ನು ಅನ್ವೇಷಿಸುವ ಅವಕಾಶ",
              "te": "వివిధ రకాల పనులను అన్వేషించే అవకాశం",
              "or": "ବିଭିନ୍ନ ପ୍ରକାର ର କାର୍ଯ୍ୟ ଅନୁସନ୍ଧାନ କରିବାର ସୁଯୋଗ",
              "as": "বিভিন্ন ধৰণৰ কামৰ অনুসন্ধান কৰাৰ সুযোগ",
              "gu": "કાર્યને લગતી વિવિધતા શોધવા માટેની તક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "Pay is not very high",
              "hi": " वेतन का सीमित होना ",
              "ka": "ಸಂಭಾವನೆ ತುಂಬಾ ಹೆಚ್ಚಿಲ್ಲ",
              "te": "జీతం ఎక్కువ ఉండదు",
              "or": "ଦେୟ ବହୁତ ଅଧିକ ନୁହେଁ |",
              "as": "দৰমহা বৰ বেছি নহয়",
              "gu": "વેતન ઉંચા નથી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.4.afterReveal",
            "value": {
              "en": "Away from home",
              "hi": "घर से दूरी ",
              "ka": "ಮನೆಯಿಂದ ದೂರ",
              "te": "ఇంటి నుంచి దూరంగా",
              "or": "ଘରୁ ଠାରୁ ଦୂରରେ",
              "as": "ঘৰৰ পৰা আঁতৰত",
              "gu": "ઘરથી દૂર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.5.afterReveal",
            "value": {
              "en": "Not a permanent job",
              "hi": "नौकरी का अस्थाई होना ",
              "ka": "ಕಾಯಂ ಕೆಲಸವಲ್ಲ",
              "te": "పర్మినెంట్ ఉద్యోగం కాదు",
              "or": "ଏକ ସ୍ଥାୟୀ ଚାକିରି ନୁହେଁ",
              "as": "স্থায়ী চাকৰি নহয়",
              "gu": "નોકરી માટે મંજૂરી નહીં"
            }
          }
        ],
        "page24": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_6_2b_reveal",
              "hi": "C5_6_2b_रिवील ",
              "ka": "C5_6_2b_reveal",
              "te": "C5_6_2b_రివీల్",
              "or": "C5_6_2b_reveal",
              "as": "C5_6_2b_reveal",
              "gu": "C5_6_2b_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "CONTRACT AT ARISE",
              "hi": "अराइज के साथ अनुबंध ",
              "ka": "ಉದ್ಭವಿಸಿದ ಒಪ್ಪಂದ",
              "te": "అరైజ్ వద్ద ఒప్పందం",
              "or": "କଣ୍ଟ୍ରାକ୍ଟ ଆଟ୍‍ ଅରାଇଜ୍‌",
              "as": "এৰাইজৰ চুক্তি",
              "gu": "અરાઈઝ ખાતે કોન્ટ્રેક્ટ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Positive 1",
              "hi": "सकारात्मक 1 ",
              "ka": "ಧನಾತ್ಮಕ 1",
              "te": "పాజిటివ్ 1",
              "or": "Positive 1",
              "as": "ভালদিশ ১",
              "gu": "હકારાત્મક 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Positive 2",
              "hi": "सकारात्मक 2 ",
              "ka": "ಧನಾತ್ಮಕ 2",
              "te": "పాజిటివ్ 2",
              "or": "Positive 2",
              "as": "ভালদিশ ২",
              "gu": "હકારાત્મક 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Positive 3",
              "hi": "सकारात्मक 3 ",
              "ka": "ಧನಾತ್ಮಕ 3",
              "te": "పాజిటివ్ 3",
              "or": "Positive 3",
              "as": "ভালদিশ ৩",
              "gu": "હકારાત્મક 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "Negative 1",
              "hi": "नकारात्मक 1 ",
              "ka": "ಋಣಾತ್ಮಕ 1",
              "te": "నెగటివ్ 1",
              "or": "Negative 1",
              "as": "বেয়াদিশ ১",
              "gu": "નકારાત્મક 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.4.beforeReveal",
            "value": {
              "en": "Negative 2",
              "hi": "नकारात्मक 2 ",
              "ka": "ಋಣಾತ್ಮಕ 2",
              "te": "నెగటివ్ 2",
              "or": "Negative 2",
              "as": "বেয়াদিশ ২",
              "gu": "નકારાત્મક 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.5.beforeReveal",
            "value": {
              "en": "Negative 3",
              "hi": "नकारात्मक 3 ",
              "ka": "ಋಣಾತ್ಮಕ 3",
              "te": "నెగటివ్ 3",
              "or": "Negative 3",
              "as": "বেয়াদিশ ৩",
              "gu": "નકારાત્મક 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Good pay for 6 months",
              "hi": "6 महीने के लिए अच्छा वेतन ",
              "ka": "6 ತಿಂಗಳಿಗೆ ಉತ್ತಮ ವೇತನ",
              "te": "6 నెలలకు మంచి జీతం",
              "or": "6 ମାସ ପାଇଁ ଭଲ ଦରମା",
              "as": "৬ মাহৰ বাবে ভাল দৰমহা",
              "gu": "6 મહિના માટે સારી ચુકવણી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Opportunity to work with expert researchers",
              "hi": "विशेषज्ञ शोधकर्ताओं के साथ काम करने का अवसर ",
              "ka": "ಪರಿಣಿತ ಸಂಶೋಧಕರೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುವ ಅವಕಾಶ",
              "te": "నిపుణులైన పరిశోధకులతో కలిసి పనిచేసే అవకాశం",
              "or": "ବିଶେଷଜ୍ଞ ଅନୁସନ୍ଧାନକାରୀଙ୍କ ସହିତ କାମ କରିବାର ସୁଯୋଗ |",
              "as": "বিশেষজ্ঞ গৱেষকৰ সৈতে কাম কৰাৰ সুযোগ",
              "gu": "નિષ્ણાત સંશોધકો સાથે કાર્ય કરવાની તક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "Opportunity to put the thesis project to use in the real world",
              "hi": "थीसिस परियोजना को वास्तविक दुनिया में उपयोग करने का अवसर\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n.",
              "ka": "ನೈಜ ಜಗತ್ತಿನಲ್ಲಿ ಬಳಸಲು ಪ್ರಬಂಧ ಯೋಜನೆಯನ್ನು ಹಾಕುವ ಅವಕಾಶ",
              "te": "థీసిస్ ప్రాజెక్ట్‌ను వాస్తవ ప్రపంచంలో ఉపయోగించుకునే అవకాశం",
              "or": "ବାସ୍ତବ ଦୁନିଆରେ ବ୍ୟବହାର କରିବାକୁ ଥେସିସ୍‍ ପ୍ରୋଜେକ୍ଟ ରଖିବାର ସୁଯୋଗ",
              "as": "গৱেষণাপত্ৰৰ প্ৰকল্পটো বাস্তৱ পৃথিৱীত ব্যৱহাৰ কৰাৰ সুযোগ",
              "gu": "વાસ્તવિક દુનિયામાં થિસિસ પ્રોજેક્ટ મુકવાની તકનો ઉપયોગ કરવો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "Short duration",
              "hi": "अल्पावधि/ थोड़ा समय ",
              "ka": "ಕಡಿಮೆ ಅವಧಿ",
              "te": "తక్కువ వ్యవధి",
              "or": "କମ୍‍ ସମୟ",
              "as": "সংক্ষিপ্ত সময়",
              "gu": "ટૂંકા સમયગાળા માટે"
            }
          },
          {
            "valuePath": "blocks.0.props.options.4.afterReveal",
            "value": {
              "en": "Mostly research work, not much variety in job responsibilities",
              "hi": "अधिकांशतः शोध कार्य, नौकरी की जिम्मेदारियों में ज्यादा विविधता नहीं\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n.",
              "ka": "ಹೆಚ್ಚಾಗಿ ಸಂಶೋಧನೆಯ ಕೆಲಸ, ಕೆಲಸದ ಜವಾಬ್ದಾರಿಗಳಲ್ಲಿ ಹೆಚ್ಚು ವೈವಿಧ್ಯವಿಲ್ಲ",
              "te": "ఎక్కువగా రీసర్చ్ వర్క్, ఉద్యోగ బాధ్యతల్లో పెద్దగా వైవిధ్యం ఉండదు",
              "or": "ପ୍ରାୟତଃ ଅନୁସନ୍ଧାନ କାର୍ଯ୍ୟ, ଚାକିରି ଦାୟିତ୍ୱ ରେ ବିଭିନ୍ନତା ନୁହେଁ",
              "as": "বেছিভাগেই গৱেষণাৰ কাম, চাকৰিৰ দায়িত্ববোৰত বেছি ভিন্নতা নাই",
              "gu": "સશોધન કાર્ય મોટાભાગે થાય છે,નોકરીની જવાબદારીઓમાં ઘણી વિવિધતા નથી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.5.afterReveal",
            "value": {
              "en": "Not a permanent job",
              "hi": "नौकरी का अस्थाई होना ",
              "ka": "ಕಾಯಂ ಕೆಲಸವಲ್ಲ",
              "te": "పర్మినెంట్ ఉద్యోగం కాదు",
              "or": "ସ୍ଥାୟୀ ଚାକିରି ନୁହେଁ",
              "as": "স্থায়ী চাকৰি নহয়",
              "gu": "કાયમી નોકરી નથી"
            }
          }
        ],
        "page21": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_6_3",
              "hi": "C5_6_3",
              "ka": "C5_6_3",
              "te": "C5_6_3",
              "or": "C5_6_3",
              "as": "C5_6_3",
              "gu": "C5_6_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After analysing the decision by doing positives and negatives, Asmita feels she is in a better place to take the decision.",
              "hi": "सकारात्मक और नकारात्मक करके निर्णय का विश्लेषण करने के बाद, अस्मिता को लगता है कि वह निर्णय लेने के लिए बेहतर जगह पर है।",
              "ka": "ಧನಾತ್ಮಕ ಮತ್ತು ಋಣಾತ್ಮಕಗಳನ್ನು ಮಾಡುವ ಮೂಲಕ ನಿರ್ಧಾರವನ್ನು ವಿಶ್ಲೇಷಿಸಿದ ನಂತರ, ಅಸ್ಮಿತಾ ನಿರ್ಧಾರ ತೆಗೆದುಕೊಳ್ಳಲು ಉತ್ತಮ ಸ್ಥಳದಲ್ಲಿದ್ದಾಳೆ ಎಂದು ಭಾವಿಸುತ್ತಾಳೆ.",
              "te": "పాజిటివ్‌లు మరియు నెగెటివ్‌లు చూసి విశ్లేషించిన తర్వాత, అస్మిత నిర్ణయం తీసుకోవడానికి రెడీగా ఉన్నట్లు భావిస్తుంది.",
              "or": "ନକରାତ୍ମକ ଏବଂ ସକରାତ୍ମକ କରି ନିଷ୍ପତ୍ତି ବିଶ୍ଳେଷଣ କରିବା ପରେ ଅସ୍ମିତା ଅନୁଭବ କରନ୍ତି ଯେ ସେ ନିଷ୍ପତ୍ତି ନେବା ପାଇଁ ଏକ ଭଲ ସ୍ଥାନରେ ଅଛନ୍ତି",
              "as": "ইতিবাচক আৰু নেতিবাচক এই দুয়োটা দিশ চাই সিদ্ধান্তটো বিশ্লেষণ কৰাৰ পিছত, অস্মিতাই অনুভৱ কৰে যে তাই সিদ্ধান্ত লোৱাৰ বাবে এক ভাল স্থানত আছে।",
              "gu": "હકારાત્મક અને નકારાત્મકને લગતા નિર્ણયનું વિષ્લેષણ કર્યાં બાદ અસ્મિતાને લાગે છે કે તે નિર્ણય લેવા માટે વધારે સારી જગ્યા પર છે"
            }
          }
        ],
        "page22": [
          {
            "valuePath": "display",
            "value": {
              "en": "C5_6_4_D",
              "hi": "C5_6_4_D",
              "ka": "C5_6_4_D",
              "te": "C5_6_4_D",
              "or": "C5_6_4_D",
              "as": "C5_6_4_D",
              "gu": "C5_6_4_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Asmita do?",
              "hi": "अस्मिता को क्या करना चाहिए?",
              "ka": "ಅಸ್ಮಿತಾ ಏನು ಮಾಡಬೇಕು?",
              "te": "అస్మిత ఏం చేయాలి?",
              "or": "ଅସ୍ମିତା କ’ଣ କରିବା ଉଚିତ୍?",
              "as": "অস্মিতাই কি কৰা উচিত?",
              "gu": "અસ્મિતાએ શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Take up the opportunity at Arise in Chennai",
              "hi": "चेन्नई में उठने का अवसर उठाए",
              "ka": "ಚೆನ್ನೈನಲ್ಲಿ ಎರೈಸ್‌ನಲ್ಲಿ ಅವಕಾಶವನ್ನು ಪಡೆದುಕೊಳ್ಳಿ",
              "te": "చెన్నైలోని అరైజ్‌లో అవకాశాన్ని పొందడం",
              "or": "ଚେନ୍ନାଇର ଅରାଇଜ୍‌ ଠାରେ ସୁଯୋଗ ର ଲାଭ ଉଠାନ୍ତୁ",
              "as": "চেন্নাইৰ এৰাইজত সুযোগটো লওক",
              "gu": "ચેન્નાઈ ખાતે અરાઈસ ખાતે યોગ્ય તક હાંસલ કરવી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Go for the Fellowship",
              "hi": "फैलोशिप के लिए जाए ",
              "ka": "ಫೆಲೋಶಿಪ್‌ಗೆ ಹೋಗಿ",
              "te": "ఫెలోషిప్ కోసం వెళ్ళడం",
              "or": "ଫେଲୋସିପ୍ ପାଇଁ ଯାଆନ୍ତୁ",
              "as": "ফেলোশ্বিপৰ বাবে যাওক",
              "gu": "ફેલોશિપ માટે જવું"
            }
          }
        ]
      },
      "scene16": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Work at Arise",
              "hi": "अराइज में काम करे ",
              "ka": "ಎರೈಸ್ ನಲ್ಲಿ ಕೆಲಸ ಮಾಡಿ",
              "te": "అరైజ్ లో పని చేయడం",
              "or": "ଅରାଇଜ୍‌ ଠାରେ କାମ କର",
              "as": "এৰাইজত কাম কৰক",
              "gu": "અરાઈઝ ખાતે કાર્ય"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita joins Arise as a research consultant. Arise is working with the Tamil Nadu Transgender Welfare Board, a government organisation, to develop policy recommendations to address the needs of transgender persons.",
              "hi": "अस्मिता एक शोध सलाहकार के रूप में अराइज से जुड़ती है। अराइज ट्रांसजेंडर व्यक्तियों की जरूरतों को पूरा करने के लिए नीतिगत सिफारिशें विकसित करने के लिए तमिलनाडु ट्रांसजेंडर वेलफेयर बोर्ड, एक सरकारी संगठन के साथ काम कर रहा है।\n.",
              "ka": "ಅಸ್ಮಿತಾ ಅರೈಸ್‌ಗೆ ಸಂಶೋಧನಾ ಸಲಹೆಗಾರ್ತಿಯಾಗಿ ಸೇರುತ್ತಾಳೆ. ಟ್ರಾನ್ಸ್‌ಜೆಂಡರ್ ವ್ಯಕ್ತಿಗಳ ಅಗತ್ಯತೆಗಳನ್ನು ಪರಿಹರಿಸಲು ನೀತಿ ಶಿಫಾರಸುಗಳನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಲು ಸರ್ಕಾರಿ ಸಂಸ್ಥೆಯಾದ ತಮಿಳುನಾಡು ಟ್ರಾನ್ಸ್‌ಜೆಂಡರ್ ಕಲ್ಯಾಣ ಮಂಡಳಿಯೊಂದಿಗೆ ಎರೈಸ್ ಕೆಲಸ ಮಾಡುತ್ತಿದೆ.",
              "te": "అస్మిత అరైజ్‌లో రీసర్చ్ కన్సల్టెంట్‌గా చేరింది. ట్రాన్స్ జెండర్ వ్యక్తుల అవసరాలను తీర్చడానికి కావల్సిన పాలసీలు రూపొందించడానికి అరైజ్ తమిళనాడు ట్రాన్స్‌జెండర్ సంక్షేమ బోర్డు అనే ప్రభుత్వ సంస్థతో కలిసి పనిచేస్తోంది.",
              "or": "ଅସ୍ମିତା ଏକ ଅନୁସନ୍ଧାନ ପରାମର୍ଶଦାତା ଭାବରେ ଅରାଇଜ୍‌ ରେ ଯୋଗ ଦିଅନ୍ତି | ଟ୍ରାନ୍ସଜେଣ୍ଡର ବ୍ୟକ୍ତିଙ୍କ ଆବଶ୍ୟକତାକୁ ଦୃଷ୍ଟିରେ ରଖି ନୀତି ସୁପାରିଶ ପ୍ରସ୍ତୁତ କରିବାକୁ ଏକ ସରକାରୀ ସଂଗଠନ ତାମିଲନାଡୁର ଟ୍ରାନ୍ସଜେଣ୍ଡର ୱେଲଫେୟାର ବୋର୍ଡ ସହିତ ଅରାଇଜ୍‌ କାମ କରୁଛି।",
              "as": "অস্মিতাই গৱেষণা পৰামৰ্শদাতা হিচাপে এৰাইজত যোগদান কৰে। এৰাইজে তামিলনাডু ট্ৰেন্সজেণ্ডাৰ ৱেলফেয়াৰ ব'ৰ্ড নামৰ এক চৰকাৰী প্ৰতিষ্ঠানৰ সৈতে কাম কৰি আছে, যাতে ট্ৰেন্সজেণ্ডাৰ ব্যক্তিসকলৰ প্ৰয়োজনীয়তা পূৰণ কৰাৰ বাবে নীতিগত পৰামৰ্শ বিকশিত কৰা হয়।",
              "gu": "અસ્મિતા એક સંશોધન સલાહકાર તરીકે અરાઈઝ સાથે જોડાયેલ છે. અરાઈઝ ટ્રાન્સઝેન્ડર વ્યક્તિઓની જરૂરિયાતોને પૂરી કરવા માટે નીતિવિષયક ભલામણોને રજૂ કરવા માટે તમિલનાડુ ટ્રાન્સલિંગ વેલ્ફેર બોર્ડ, એક સરકારી સંસ્થા સાથે કામ કરી રહી છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_1_2",
              "hi": "C6_1_2",
              "ka": "C6_1_2",
              "te": "C6_1_2",
              "or": "C6_1_2",
              "as": "C6_1_2",
              "gu": "C6_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Since she did her study online, she takes some time to get used to working from an office. However, she is happy to meet new people.",
              "hi": "चूंकि उसने अपनी पढ़ाई ऑनलाइन की थी, इसलिए उसे कार्यालय से काम करने की आदत लगने में कुछ समय लगता है। हालांकि, वह नए लोगों से मिलकर खुश है।",
              "ka": "ಅವಳು ತನ್ನ ಅಧ್ಯಯನವನ್ನು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಮಾಡಿದ್ದರಿಂದ, ಅವಳು ಕಚೇರಿಯಿಂದ ಕೆಲಸ ಮಾಡಲು ಸ್ವಲ್ಪ ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತಾಳೆ. ಆದಾಗ್ಯೂ, ಅವಳು ಹೊಸ ಜನರನ್ನು ಭೇಟಿಯಾಗಲು ಸಂತೋಷಪಡುತ್ತಾಳೆ.",
              "te": "ఆమె ఆన్‌లైన్‌లో చదువుకున్నందున, ఆమె ఆఫీసు నుండి పని చేయడానికి కొంత సమయం పడుతుంది. కానీ, ఆమెకు కొత్త వ్యక్తులను కలవడం ఆనందంగా ఉంది.",
              "or": "ଯେହେତୁ ସେ ଅନଲାଇନରେ ଅଧ୍ୟୟନ କରିଥିଲେ, ସେ ଏକ ଅଫିସରେ କାମ କରିବାକୁ ଅଭ୍ୟସ୍ତ ହେବାକୁ କିଛି ସମୟ ନେଇଥାନ୍ତି | ତଥାପି, ସେ ନୂଆ ଲୋକଙ୍କୁ ଭେଟି ଖୁସି ଅଛନ୍ତି |",
              "as": "যিহেতু তাই এতিয়ালৈকে অনলাইন অধ্যয়ন কৰিছিল, তাই অফিচৰ পৰা কাম কৰিবলৈ অভ্যস্ত হ'বলৈ কিছু সময় লয়। অৱশ্যে, তাই নতুন মানুহক লগ পাই সুখী।",
              "gu": "અલબત તેને પોતાના અભ્યાસ ઓનલાઈન હતો, માટે તે ઓફિસથી કામ કરવાની ટેવ પડવામાં કેટલોક સમય લાગ્યો. જોકે, તે નવા લોકોને મળતા ખુશ છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_1_3",
              "hi": "C6_1_3",
              "ka": "C6_1_3",
              "te": "C6_1_3",
              "or": "C6_1_3",
              "as": "C6_1_3",
              "gu": "C6_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She also gets accommodation from the organisation for her stay in Chennai. Her work timings are flexible to avoid working late and travelling at night.",
              "hi": "उसे चेन्नई में रहने के लिए संगठन से आवास भी मिलता है। देर तक काम करने और रात में यात्रा करने से बचने के लिए उसके काम का समय लचीला है।",
              "ka": "ಆಕೆಗೆ ಚೆನ್ನೈನಲ್ಲಿ ಉಳಿಯಲು ಸಂಸ್ಥೆಯಿಂದ ವಸತಿ ಕೂಡ ಸಿಗುತ್ತದೆ. ತಡವಾಗಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಮತ್ತು ರಾತ್ರಿಯಲ್ಲಿ ಪ್ರಯಾಣಿಸುವುದನ್ನು ತಪ್ಪಿಸಲು ಅವಳ ಕೆಲಸದ ಸಮಯವು ಹೊಂದಿಕೊಳ್ಳುತ್ತದೆ.",
              "te": "ఆమె చెన్నైలో ఉండటానికి సంస్థ నుండి వసతి కూడా పొందుతుంది. ఆలస్యంగా పని చేయకుండా మరియు రాత్రి ప్రయాణం చేయకుండా ఉండటానికి ఆమె పని సమయాలు అనువైనవి.",
              "or": "ଚେନ୍ନାଇରେ ରହିବା ପାଇଁ ସେ ସଂଗଠନରୁ ରହଣି ବ୍ଯବସ୍ଥା ମଧ୍ୟ ପାଆନ୍ତି | ବିଳମ୍ବରେ କାମ କରିବା ଏବଂ ରାତିରେ ନ ଯିବା ପାଇଁ ତା’ର କାର୍ଯ୍ୟ ସମୟ ନମନୀୟ ଅଛି |",
              "as": "তাই চেন্নাইত থকাৰ বাবে প্ৰতিষ্ঠানটোৰ পৰা বাসস্থানো লাভ কৰে। তাইৰ কামৰ সময়খিনি দেৰিলৈকে কাম কৰা আৰু ৰাতি ভ্ৰমণ কৰাৰ পৰা হাত সাৰিব পৰাকৈ সাল-সলনি কৰি ল'ব পৰা বিধৰ।",
              "gu": "તેને ચેન્નાઈમાં રહેવા માટે સંગઠનમાં રહેઠાણ પણ મળે છે. મોડેથી કામ કરવા અને રાત્રીની યાત્રા કરવાથી બચવા માટે તેણે કામના સમયમાં લવચીકપણું ધરાવે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_1_4",
              "hi": "C6_1_4",
              "ka": "C6_1_4",
              "te": "C6_1_4",
              "or": "C6_1_4",
              "as": "C6_1_4",
              "gu": "C6_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She feels connected to the organisation because people make her feel comfortable there.",
              "hi": "वह संगठन से जुड़ी महसूस करती है क्योंकि लोग उसे वहां सहज अनुभव कराते हैं।",
              "ka": "ಅವಳು ಸಂಸ್ಥೆಯೊಂದಿಗೆ ಸಂಪರ್ಕ ಹೊಂದಿದ್ದಾಳೆ ಏಕೆಂದರೆ ಜನರು ಅಲ್ಲಿ ಅವಳನ್ನು ಆರಾಮದಾಯಕವಾಗಿಸುತ್ತಾರೆ.",
              "te": "తను ఆ సంస్థతో కనెక్ట్ అయినట్లు భావిస్తుంది, ఎందుకంటే అక్కడ వ్యక్తులు ఆమెతో బాగుంటారు.",
              "or": "ସେ ସଂଗଠନ ସହିତ ଯୋଡିହେବା ଅନୁଭବ କରନ୍ତି କାରଣ ଲୋକମାନେ ତାଙ୍କୁ ସେଠାରେ ଆରାମଦାୟକ ଅନୁଭବ କରାନ୍ତି |",
              "as": "তাই প্ৰতিষ্ঠানটোৰ সৈতে সংযুক্ত অনুভৱ কৰে কাৰণ মানুহে তাইক তাত সহজ অনুভৱ কৰায়।",
              "gu": "તે સંગઠન સાથે જોડાવાનો અહેસાસ કરે છે કારણ કે લોકો તેને ત્યાં સહજ અહેસાસ કરે છે"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_1_5",
              "hi": "C6_1_5",
              "ka": "C6_1_5",
              "te": "C6_1_5",
              "or": "C6_1_5",
              "as": "C6_1_5",
              "gu": "C6_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Now that she is feeling more settled, she is keen on taking the next step of her transition.",
              "hi": "अब जबकि वह अधिक व्यवस्थित अनुभव कर रही है, वह अपने ट्रांज़िशन/ बदलाव का अगला कदम उठाने के लिए उत्सुक है।",
              "ka": "ಈಗ ಅವಳು ಹೆಚ್ಚು ನೆಲೆಗೊಂಡಿದ್ದಾಳೆ, ಅವಳು ತನ್ನ ಪರಿವರ್ತನೆಯ ಮುಂದಿನ ಹೆಜ್ಜೆಯನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ.",
              "te": "ఇప్పుడు ఆమె మరింత స్థిరపడినట్లు భావిస్తుంది, ఆమె తన పరివర్తన యొక్క తదుపరి దశను తీసుకోవాలని ఆసక్తిగా ఉంది.",
              "or": "ବର୍ତ୍ତମାନ ଯେହେତୁ ସେ ଅଧିକ ସ୍ଥାୟୀ ଅନୁଭବ କରୁଛନ୍ତି, ସେ ତାଙ୍କ ପରିବର୍ତ୍ତନର ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପ ନେବାକୁ ଆଗ୍ରହୀ ଅଛନ୍ତି |",
              "as": "এতিয়া যেতিয়া তাই অধিক স্থিৰ অনুভৱ কৰিছে, তাই তাইৰ পৰিৱৰ্তনৰ পৰৱৰ্তী পদক্ষেপ ল'বলৈ আগ্ৰহী।",
              "gu": "હવે જ્યારે તે વધારે સેટલ થયેલ હોવાનો અહેસાસ કરી રહી છે, તે વહે ટ્રાન્સિશનથી આગળનું પગલું ભરવા માટે ઉત્સાહિત છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_2_1",
              "hi": "C6_2_1",
              "ka": "C6_2_1",
              "te": "C6_2_1",
              "or": "C6_2_1",
              "as": "C6_2_1",
              "gu": "C6_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She gets on a call with Vandana from her support group to get her advice.\nVandana is also a transwoman, so she understands many of Asmita's problems.",
              "hi": "वह वंदना को अपने सहायता समूह से सलाह लेने के लिए कॉल करती है।\nवंदना एक ट्रांसवुमन भी हैं, इसलिए वह अस्मिता की कई समस्याओं को समझती हैं।",
              "ka": "\"ವಂದನಾ ಅವರ ಸಲಹೆಯನ್ನು ಪಡೆಯಲು ಅವರ ಬೆಂಬಲ ಗುಂಪಿನಿಂದ ಅವರು ಕರೆ ಮಾಡುತ್ತಾರೆ.\n ವಂದನಾ ಕೂಡ ಟ್ರಾನ್ಸ್ ವುಮನ್ ಆಗಿರುವುದರಿಂದ ಅಸ್ಮಿತಾ ಅವರ ಅನೇಕ ಸಮಸ್ಯೆಗಳನ್ನು ಅವರು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "ఆమె సలహా కోసం ఆమె సపోర్ట్ గ్రూప్ లోని వందనకు కాల్ చేస్తుంది.\n వందన కూడా ట్రాన్స్ ఉమెన్ కాబట్టి ఆమె అస్మిత యొక్క చాలా సమస్యలను అర్థం చేసుకుంటుంది.",
              "or": "ତାଙ୍କ ପରାମର୍ଶ ପାଇବା ପାଇଁ ସେ ତାଙ୍କ ସମର୍ଥନ ସମୁଦାୟରୁ ଭନ୍ଦନାଙ୍କ ସହ କଲ୍ କରନ୍ତି |\n ଭନ୍ଦନା ମଧ୍ୟ ଜଣେ ଟ୍ରାନ୍ସମ୍ୟାନ୍, ତେଣୁ ସେ ଅସମିତାର ଅନେକ ସମସ୍ୟା ବୁଝନ୍ତି |",
              "as": "তাই পৰামৰ্শ পাবলৈ তাইৰ সমৰ্থন গোটৰ বন্দনালৈ ফোন কৰিছিল।\n \n বন্দনা এগৰাকী ট্ৰেন্সৱমেন, সেয়েহে তেওঁ অস্মিতাৰ বহুতো সমস্যা বুজি পায়।",
              "gu": "તે વંદના સાથે પોતાના સહાયતા ગ્રુપની સલાહ લેવા માટે કોલ કરે છે. વંદના એક ટ્રાન્સવુમન પણ છે, માટે તે અસ્મિતાની અનેક સમસ્યાઓને રજૂ કરે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_2_2//",
              "hi": "C6_2_2 //",
              "ka": "C6_2_2//",
              "te": "C6_2_2//",
              "or": "C6_2_2//",
              "as": "C6_2_2//",
              "gu": "C6_2_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Vandana",
              "hi": "वंदना",
              "ka": "ವಂದನಾ",
              "te": "వందన",
              "or": "ଭନ୍ଦନା",
              "as": "বন্দনা",
              "gu": "વંદના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Asmita",
              "hi": "अस्मिता",
              "ka": "ಅಸ್ಮಿತಾ",
              "te": "అస్మిత",
              "or": "ଅସ୍ମିତା",
              "as": "অস্মিতা",
              "gu": "અસ્મિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "It's great that your family is so supportive.",
              "hi": "यह बहुत अच्छा है कि आपका परिवार इतना सहयोग देने वाला है।",
              "ka": "ನಿಮ್ಮ ಕುಟುಂಬದವರು ಬೆಂಬಲ ನೀಡುತ್ತಿರುವುದು ಸಂತಸ ತಂದಿದೆ.",
              "te": "మీ కుటుంబం సపోర్ట్ చేయడం చాలా బాగుంది.",
              "or": "ଏହା ବହୁତ ଭଲ ଯେ ଆପଣଙ୍କ ପରିବାର ଏତେ ସହଯୋଗ କରନ୍ତି |",
              "as": "এইটো বৰ ভাল কথা যে আপোনাৰ পৰিয়ালে ইমান সহায় কৰে।",
              "gu": "તમાંરો પરિવારનો આટલો સપોર્ટ છે તે ખૂબ જ સારી વાત છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I can't thank them enough. I owe so much to my family.",
              "hi": "मैं उन्हें पर्याप्त धन्यवाद नहीं दे सकती। मैं अपने परिवार की बहुत ऋणी हूं।",
              "ka": "ನಾನು ಅವರಿಗೆ ಸಾಕಷ್ಟು ಧನ್ಯವಾದ ಹೇಳಲಾರೆ. ನಾನು ನನ್ನ ಕುಟುಂಬಕ್ಕೆ ತುಂಬಾ ಋಣಿಯಾಗಿದ್ದೇನೆ.",
              "te": "నేను వారికి తగినంత కృతజ్ఞతలు చెప్పలేను. నేను నా కుటుంబానికి చాలా రుణపడి ఉన్నాను.",
              "or": "ମୁଁ ସେମାନଙ୍କୁ ଯଥେଷ୍ଟ ଧନ୍ୟବାଦ ଦେଇ ପାରିବି ନାହିଁ | ମୋର ପରିବାର ପ୍ରତି ମୋର ବହୁତ ଋଣ ଅଛି |",
              "as": "মই তেওঁলোকক পৰ্যাপ্ত ধন্যবাদ দিব নোৱাৰো। মই মোৰ পৰিয়ালৰ ওচৰত বহুত ঋণী।",
              "gu": "તેમનો આભાર માનવા માટે મારી પાસે શબ્દો નથી.  હું મારા પરિવારની ખૂબજ ઋણી છું."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_2_3////",
              "hi": "C6_2_3 //////////",
              "ka": "C6_2_3////",
              "te": "C6_2_3////",
              "or": "C6_2_3////",
              "as": "C6_2_3////",
              "gu": "C6_2_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I am glad that you want to take the next steps for your transition, but it is not going to be as easy this time. With hormone therapy, you'll go through both physical and mental changes.",
              "hi": "मुझे खुशी है कि आप अपने ट्रांज़िशन/ बदलाव के लिए अगले कदम उठाना चाहती हैं, लेकिन यह इस बार इतना सरल नहीं होने वाला है। हार्मोन थेरेपी के साथ, आप शारीरिक और मानसिक दोनों परिवर्तनों से गुजरेंगे।",
              "ka": "ನಿಮ್ಮ ಸ್ಥಿತ್ಯಂತರಕ್ಕಾಗಿ ನೀವು ಮುಂದಿನ ಹಂತಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಬಯಸಿದ್ದಕ್ಕಾಗಿ ನನಗೆ ಸಂತೋಷವಾಗಿದೆ, ಆದರೆ ಈ ಬಾರಿ ಅದು ಸುಲಭವಾಗುವುದಿಲ್ಲ. ಹಾರ್ಮೋನ್ ಚಿಕಿತ್ಸೆಯೊಂದಿಗೆ, ನೀವು ದೈಹಿಕ ಮತ್ತು ಮಾನಸಿಕ ಬದಲಾವಣೆಗಳ ಮೂಲಕ ಹೋಗುತ್ತೀರಿ.",
              "te": "నువ్వు నీ పరివర్తన కోసం తదుపరి దశలను తీసుకోవాలని కోరుకుంటున్నందుకు నేను సంతోషిస్తున్నాను, కానీ ఈసారి అది అంత సులభం కాదు. హార్మోన్ థెరపీతో, నువ్వు శారీరక మరియు మానసిక మార్పులను ఎదుర్కొంటావు.",
              "or": "ମୁଁ ଖୁସି ଯେ ତୁମେ ତୁମର ପରିବର୍ତ୍ତନ ପାଇଁ ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପ ନେବାକୁ ଚାହୁଁଛ, କିନ୍ତୁ ଏଥର ଏହା ସହଜ ହେବ ନାହିଁ | ହରମୋନ୍ ଥେରାପି ସହିତ, ଆପଣ ଉଭୟ ଶାରୀରିକ ଏବଂ ମାନସିକ ପରିବର୍ତ୍ତନ ଦେଇ ଗତି କରିବେ |",
              "as": "মই আনন্দিত যে আপুনি আপোনাৰ পৰিৱৰ্তনৰ বাবে পৰৱৰ্তী পদক্ষেপ ল'ব বিচাৰে, কিন্তু এইবোৰ এই সময়ত সিমান সহজ নহ'ব। হৰমন থেৰাপীৰ দ্বাৰা, আপুনি শাৰীৰিক আৰু মানসিক দুয়োধৰণৰ পৰিৱৰ্তনৰ মাজেৰে যাব লাগিব।",
              "gu": "મને ખુશી છે કે તમે પોતાનાં ટ્રાન્સિશન માટે આગળનાં પગલાં લેવા માંગો છો, પરંતુ આ વખતે તે એટલું સરળ નથી.  હોર્મોન ઉપચાર સાથે, તમે શારીરિક અને માનસિક બંને ફેરફારોમાંથી પસાર થશો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, that's exactly what I wanted to understand.",
              "hi": "हां, यह वही है जो मैं समझना चाहती थी।",
              "ka": "ಹೌದು, ನಾನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಬಯಸಿದ್ದು ಅದನ್ನೇ.",
              "te": "అవును, నేను అర్థం చేసుకోవాలనుకున్నది అదే.",
              "or": "ହଁ, ତାହା ଠିକ୍ ଯାହା ମୁଁ ବୁଝିବାକୁ ଚାହୁଁଥିଲି |",
              "as": "হয়, মই ঠিক সেইটোৱেই বুজিব বিচাৰিছিলো।",
              "gu": "હા, હું  બરાબર એ જ સમજવા માંગતી હતી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "The endocrinologist (the doctor who will administer hormone therapy) will take you through the medical aspects. Make sure you're connected with a specialist or a good support group to keep your mental health in check. Having a routine apart from work will also help you cope with the changes.",
              "hi": "एंडोक्रिनोलॉजिस्ट (डॉक्टर जो हार्मोन थेरेपी का प्रबंध करेगा) आपको चिकित्सा पहलुओं के बारे में बताएगा। अपने मानसिक स्वास्थ्य को नियंत्रण में रखने के लिए सुनिश्चित करें कि आप किसी विशेषज्ञ या किसी अच्छे सहायता समूह से जुड़े रहें। काम के साथ साथ एक अलग रूटीन रखने से आपको बदलावों से निपटने में भी सहायता मिलेगी।",
              "ka": "ಅಂತಃಸ್ರಾವಶಾಸ್ತ್ರಜ್ಞರು (ಹಾರ್ಮೋನ್ ಚಿಕಿತ್ಸೆಯನ್ನು ನಿರ್ವಹಿಸುವ ವೈದ್ಯರು) ನಿಮ್ಮನ್ನು ವೈದ್ಯಕೀಯ ಅಂಶಗಳ ಮೂಲಕ ತೆಗೆದುಕೊಳ್ಳುತ್ತಾರೆ. ನಿಮ್ಮ ಮಾನಸಿಕ ಆರೋಗ್ಯವನ್ನು ಪರೀಕ್ಷಿಸಲು ನೀವು ತಜ್ಞರು ಅಥವಾ ಉತ್ತಮ ಬೆಂಬಲ ಗುಂಪಿನೊಂದಿಗೆ ಸಂಪರ್ಕ ಹೊಂದಿದ್ದೀರಿ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ. ಕೆಲಸದ ಹೊರತಾಗಿ ದಿನಚರಿಯನ್ನು ಹೊಂದಿರುವುದು ಬದಲಾವಣೆಗಳನ್ನು ನಿಭಾಯಿಸಲು ನಿನಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "ఎండోక్రైనాలజిస్ట్ (హార్మోన్ థెరపీని నిర్వహించే వైద్యుడు) వైద్యపరమైన అంశాలు నీకు చెప్తారు. నీ మానసిక ఆరోగ్యాన్ని అదుపులో ఉంచుకోవడానికి నువ్వు స్పెషలిస్ట్ లేదా మంచి సపోర్ట్ గ్రూప్‌తో కనెక్ట్ అయ్యేలాగా చూసుకో. పని కాకుండా వేరే రొటీన్ కలిగి ఉండటం కూడా మార్పులను ఎదుర్కోవడంలో నీకు సహాయపడుతుంది.",
              "or": "ଏଣ୍ଡୋକ୍ରାଇନୋଲୋଜିଷ୍ଟ (ଡାକ୍ତର ଯିଏ ହରମୋନ୍ ଥେରାପି ପରିଚାଳନା କରିବେ) ଆପଣଙ୍କୁ ଚିକିତ୍ସା ମାଧ୍ଯମ ଦେଇ ନେଇଯିବେ | ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ମାନସିକ ସ୍ୱାସ୍ଥ୍ୟକୁ ନିୟନ୍ତ୍ରଣରେ ରଖିବା ପାଇଁ ଏକ ବିଶେଷଜ୍ଞ କିମ୍ବା ଏକ ଭଲ ସମର୍ଥନ ସମୁଦାୟ ସହିତ ସଂଯୁକ୍ତ ଅଟନ୍ତି | କାମ ବ୍ୟତୀତ ଏକ ନିତ୍ୟ ବ୍ୟବହାର୍ଯ୍ୟ ରହିବା ମଧ୍ୟ ପରିବର୍ତ୍ତନଗୁଡ଼ିକର ମୁକାବିଲା କରିବାରେ ସାହାଯ୍ୟ କରିବ |",
              "as": "এণ্ডোক্ৰিনোলজিষ্টজনে (হৰমন থেৰাপী প্ৰদান কৰা চিকিৎসক) আপোনাক চিকিৎসাৰ দিশবোৰৰ মাজেৰে লৈ যাব। নিশ্চিত কৰিব যে আপোনাৰ মানসিক স্বাস্থ্য নিয়ন্ত্ৰণত ৰাখিবলৈ আপুনি বিশেষজ্ঞ বা ভাল সমৰ্থন গোট এটাৰ সৈতে সংযুক্ত হৈ আছে। কামৰ বাহিৰেও এটা ৰুটিন থাকিলে আপোনাক পৰিৱৰ্তনবোৰৰ সৈতে মোকাবিলা কৰাত সহায় কৰিব।",
              "gu": "એન્ડોક્રિનોલોજિસ્ટ (ડોક્ટર જે હોર્મોન ઉપચારનું સંચાલન કરશે) તમને બધી તબીબી બાબતો સમજાવશે. તમારા માનસિક સ્વાસ્થ્યને નિયંત્રણમાં રાખવા માટે તમે નિષ્ણાત અથવા સારા સહાયક જૂથ સાથે જોડાયેલા છો તેની ખાતરી કરો. કામ સિવાય અન્ય બાબતની પણ દિનચર્યા રાખવાથી તમને ફેરફારોનો સામનો કરવામાં પણ મદદ મળશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "I hadn't thought about that.",
              "hi": "मैंने इसके बारे में नहीं सोचा था।",
              "ka": "ನಾನು ಅದರ ಬಗ್ಗೆ ಯೋಚಿಸಿರಲಿಲ್ಲ.",
              "te": "నేను దాని గురించి ఆలోచించలేదు.",
              "or": "ମୁଁ ସେ ବିଷୟରେ ଚିନ୍ତା କରି ନ ଥିଲି |",
              "as": "মই সেই বিষয়ে ভবা নাছিলো।",
              "gu": "મેં આનાં વિશે વિચાર્યું ન હતું."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_3_1",
              "hi": "C6_3_1",
              "ka": "C6_3_1",
              "te": "C6_3_1",
              "or": "C6_3_1",
              "as": "C6_3_1",
              "gu": "C6_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Over the next few months, her body undergoes several changes. She begins feeling a lot more comfortable with herself.",
              "hi": "अगले कुछ महीनों में, उसके शरीर में कई बदलाव हुए। वह खुद के साथ बहुत अधिक आरामदायक अनुभव कर रही है।",
              "ka": "ಮುಂದಿನ ಕೆಲವು ತಿಂಗಳುಗಳಲ್ಲಿ, ಅವಳ ದೇಹವು ಹಲವಾರು ಬದಲಾವಣೆಗಳಿಗೆ ಒಳಗಾಗುತ್ತದೆ. ಅವಳು ತನ್ನೊಂದಿಗೆ ಹೆಚ್ಚು ಆರಾಮದಾಯಕವಾಗಲು ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ.",
              "te": "తరువాతి కొన్ని నెలల్లో, ఆమె శరీరం అనేక మార్పులకు గురవుతుంది. ఆమె చాలా సౌకర్యంగా ఉండటం ప్రారంభించింది.",
              "or": "ପରବର୍ତ୍ତୀ କିଛି ମାସ ମଧ୍ୟରେ, ତାଙ୍କ ଶରୀରରେ ଅନେକ ପରିବର୍ତ୍ତନ ଆସିଛି | ସେ ନିଜ ସହିତ ବହୁତ ଆରାମଦାୟକ ଅନୁଭବ କରିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "পৰৱৰ্তী কেইমাহমানৰ ভিতৰত, তাইৰ শৰীৰত কেইবাটাও পৰিৱৰ্তন হয়। তাই নিজৰ সৈতে বহুত বেছি সহজ অনুভৱ কৰিবলৈ আৰম্ভ কৰে।",
              "gu": "આગામી કેટલાક મહિનાઓમાં, તેના શરીરમાં ઘણા ફેરફારો થયા. તેણીને પોતાનું શરીર વધુ સ્વસ્થ હોય તેવા અનુભવવા થવા લાગ્યો."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_3_2",
              "hi": "C6_3_2",
              "ka": "C6_3_2",
              "te": "C6_3_2",
              "or": "C6_3_2",
              "as": "C6_3_2",
              "gu": "C6_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "To clear her mind, she talks to her counsellor and her family regularly.\nWhen she wants to be alone, she goes for a long walk, watches good movies, and dances on her favourite track.",
              "hi": "अपने मन को स्पष्ट रखने के लिए, वह अपने परामर्शदाता और अपने परिवार से नियमित रूप से  बात करती है।\nजब वह अकेले रहना चाहती है, तो वह लंबी सैर के लिए जाती है, अच्छी फिल्में देखती है, और अपने पसंदीदा संगीत पर नृत्य करती है।",
              "ka": "\"ಅವಳ ಮನಸ್ಸನ್ನು ತೆರವುಗೊಳಿಸಲು, ಅವಳು ತನ್ನ ಸಲಹೆಗಾರ ಮತ್ತು ಅವಳ ಕುಟುಂಬದೊಂದಿಗೆ ನಿಯಮಿತವಾಗಿ ಮಾತನಾಡುತ್ತಾಳೆ.\n ಅವಳು ಒಬ್ಬಂಟಿಯಾಗಿರಲು ಬಯಸಿದಾಗ, ಅವಳು ದೀರ್ಘ ನಡಿಗೆಗೆ ಹೋಗುತ್ತಾಳೆ, ಒಳ್ಳೆಯ ಚಲನಚಿತ್ರಗಳನ್ನು ನೋಡುತ್ತಾಳೆ ಮತ್ತು ಅವಳ ನೆಚ್ಚಿನ ಟ್ರ್ಯಾಕ್ನಲ್ಲಿ ನೃತ್ಯ ಮಾಡುತ್ತಾಳೆ.",
              "te": "ఆమె మనస్సును క్లియర్ చేయడానికి, ఆమె తన కౌన్సెలర్ మరియు ఆమె కుటుంబ సభ్యులతో క్రమం తప్పకుండా మాట్లాడుతుంది.\n \n ఆమె ఒంటరిగా ఉండాలనుకున్నప్పుడు, ఆమె చాలా దూరం నడుస్తుంది, మంచి సినిమాలు చూస్తుంది మరియు ఆమె ఇష్టమైన ట్రాక్‌లో డ్యాన్స్ చేస్తుంది.",
              "or": "ତାଙ୍କ ମନକୁ ସଫା କରିବା ପାଇଁ ସେ ତାଙ୍କ ପରାମର୍ଶଦାତା ଏବଂ ତାଙ୍କ ପରିବାର ସହିତ ନିୟମିତ କଥାବାର୍ତ୍ତା କରନ୍ତି।\n ଯେତେବେଳେ ସେ ଏକୁଟିଆ ରହିବାକୁ ଚାହାଁନ୍ତି, ସେତେବେଳେ ସେ ଦୀର୍ଘ ସମୟ ବୁଲିବାକୁ ଯାଆନ୍ତି, ଭଲ ଚଳଚ୍ଚିତ୍ର ଦେଖନ୍ତି ଏବଂ ନିଜ ପ୍ରିୟ ଟ୍ରାକରେ ନାଚନ୍ତି |",
              "as": "তাইৰ মনটো ভালে ৰাখিবলৈ, তাই তাইৰ পৰামৰ্শদাতা আৰু তাইৰ পৰিয়ালৰ সৈতে নিয়মীয়াকৈ কথা পাতে।\n \n যেতিয়া তাই অকলে থাকিব বিচাৰে, তাই বহু দূৰ খোজ কাঢ়িবলৈ যায়, ভাল চিনেমা চায় আৰু তাইৰ প্ৰিয় ট্ৰেকত নৃত্য কৰে।",
              "gu": "પોતાનાં વિચારોમાં સ્પષ્ટતા લાવવા માટે તેણી પોતાના કાઉન્સેલર અને પરિવાર સાથે નિયમિત વાત કરવા લાગી. જ્યારે તેણીને એકલા રહેવાની ઈચ્છા થતી ત્યારે તેણી દૂર સુધી ચાલવા જતી, સારી ફિલ્મો જોતી અને પોતાના મનપસંદ ટ્રેક પર ડાન્સ કરતી."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_3_3",
              "hi": "C6_3_3",
              "ka": "C6_3_3",
              "te": "C6_3_3",
              "or": "C6_3_3",
              "as": "C6_3_3",
              "gu": "C6_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Since Arise is very supportive of her decision, she gets a lot of help managing her treatment and healthcare.",
              "hi": "चूंकि अराइज उसके निर्णय का बहुत समर्थक है, इसलिए उसे अपने उपचार और स्वास्थ्य देखभाल के प्रबंधन में बहुत सहायता मिलती है।",
              "ka": "ಏರೈಸ್ ತನ್ನ ನಿರ್ಧಾರಕ್ಕೆ ತುಂಬಾ ಬೆಂಬಲ ನೀಡುತ್ತಿರುವುದರಿಂದ, ಆಕೆಯ ಚಿಕಿತ್ಸೆ ಮತ್ತು ಆರೋಗ್ಯವನ್ನು ನಿರ್ವಹಿಸುವಲ್ಲಿ ಅವಳು ಸಾಕಷ್ಟು ಸಹಾಯವನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
              "te": "అరైజ్ తన నిర్ణయానికి చాలా మద్దతుగా ఉంది కాబట్టి, ఆమె తన చికిత్స మరియు ఆరోగ్య సంరక్షణ నిర్వహణలో చాలా సహాయాన్ని పొందుతుంది.",
              "or": "ଯେହେତୁ ଅରାଇଜ୍‌ ତାଙ୍କ ନିଷ୍ପତ୍ତିକୁ ବହୁତ ସମର୍ଥନ କରନ୍ତି, ତେଣୁ ସେ ତାଙ୍କର ଚିକିତ୍ସା ଏବଂ ସ୍ୱାସ୍ଥ୍ୟସେବା ପରିଚାଳନାରେ ବହୁତ ସାହାଯ୍ୟ ପାଆନ୍ତି |",
              "as": "যিহেতু এৰাইজে তাইৰ সিদ্ধান্তত যথেষ্ট সমৰ্থন কৰে, তাই তাইৰ চিকিৎসা আৰু স্বাস্থ্যৰ যত্ন লোৱাত যথেষ্ট সহায় পায়।",
              "gu": "એરાઈઝ તેણીનાં નિર્ણયને ખૂબ જ ટેકો આપતી હોવાથી, તેણીને પોતાની સારવાર અને સ્વસ્થ્યની સંભાળ કરવામાં ઘણી મદદ મળતી હતી."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_3_4",
              "hi": "C6_3_4",
              "ka": "C6_3_4",
              "te": "C6_3_4",
              "or": "C6_3_4",
              "as": "C6_3_4",
              "gu": "C6_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita has a great time working on research in the organisation. She spends a lot of her time talking through ideas with her colleagues to get feedback. Her colleagues appreciate her work.",
              "hi": "अस्मिता के पास संगठन में अनुसंधान पर काम करने का पर्याप्त समय रहता है। वह प्रतिक्रिया प्राप्त करने के लिए अपने सहयोगियों के साथ अपने विचारों पर चर्चा में अपना अधिक समय बिताती है। उसके सहयोगी उसके काम की सराहना करते हैं।",
              "ka": "ಅಸ್ಮಿತಾ ಸಂಸ್ಥೆಯಲ್ಲಿ ಸಂಶೋಧನೆಯಲ್ಲಿ ಉತ್ತಮ ಸಮಯವನ್ನು ಹೊಂದಿದ್ದಾರೆ. ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಪಡೆಯಲು ತನ್ನ ಸಹೋದ್ಯೋಗಿಗಳೊಂದಿಗೆ ವಿಚಾರಗಳ ಮೂಲಕ ಮಾತನಾಡುತ್ತಾ ತನ್ನ ಸಮಯವನ್ನು ಕಳೆಯುತ್ತಾಳೆ. ಅವಳ ಕೆಲಸವನ್ನು ಸಹೋದ್ಯೋಗಿಗಳು ಮೆಚ್ಚುತ್ತಾರೆ.",
              "te": "అస్మిత సంస్థలో పరిశోధనలో పని చేయడంలో చాలా గొప్ప సమయం గడిపింది. తన ఐడియాల పట్ల వారి అభిప్రాయాన్ని తెలుసుకోడానికి ఆమె తన సహోద్యోగులతో మాట్లాడటానికి చాలా సమయాన్ని వెచ్చిస్తుంది. ఆమె పనిని సహచరులు అభినందిస్తున్నారు.",
              "or": "ସଂଗଠନରେ ଗବେଷଣା ଉପରେ କାମ କରିବା ପାଇଁ ଅସ୍ମିତାଙ୍କର ବହୁତ ସମୟ ଅଛି | ମତାମତ ପାଇବା ପାଇଁ ସେ ତାଙ୍କ ସହକର୍ମୀମାନଙ୍କ ସହିତ ଧାରଣା ମାଧ୍ୟମରେ କଥାବାର୍ତ୍ତା କରିବାରେ ବହୁ ସମୟ ଅତିବାହିତ କରନ୍ତି | ତାଙ୍କ ସହକର୍ମୀମାନେ ତାଙ୍କ କାର୍ଯ୍ୟକୁ ପ୍ରଶଂସା କରନ୍ତି |",
              "as": "অস্মিতাই প্ৰতিষ্ঠানটোত গৱেষণাৰ ওপৰত কাম কৰি ভাল সময় অতিবাহ কৰিছে। তাই তাইৰ সহকৰ্মীসকলৰ সৈতে মতামত পাবলৈ ধাৰণাবোৰৰ জৰিয়তে কথা পাতি বহুসময় অতিবাহিত কৰে। তাইৰ সহকৰ্মীসকলে তাইৰ কামৰ প্ৰশংসা কৰে।",
              "gu": "સંસ્થામાં રિસર્ચ પર કામ કરવા  અસ્મિતાનો સમય ઘણો સારો ગુજારતો હતો.  તેણીનો ઘણો સમય ફીડ બેક લેવામાં અને પોતાના સાથીદારો સાથે વિચારોના આદાન પ્રદાન માં ગુજરતો હતો.  તેણીનાં સાથીદારો તેણીનાં કામની પ્રશંસા કરતા હતા."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_3_5",
              "hi": "C6_3_5",
              "ka": "C6_3_5",
              "te": "C6_3_5",
              "or": "C6_3_5",
              "as": "C6_3_5",
              "gu": "C6_3_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "For research, she regularly visits Garima Greh, a shelter home for transgender people in Chennai. The residents there enjoy talking to her and are inspired by her story.",
              "hi": "शोध के लिए, वह नियमित रूप से चेन्नई में ट्रांसजेंडर लोगों के लिए एक आश्रय गृह गरिमा गृह का दौरा करती है। वहां के निवासी उसके साथ बात करने का आनंद लेते हैं और उसकी कहानी से प्रेरणा लेते हैं।",
              "ka": "ಸಂಶೋಧನೆಗಾಗಿ, ಅವರು ನಿಯಮಿತವಾಗಿ ಗರಿಮಾ ಗ್ರೆಹ್‌ಗೆ ಭೇಟಿ ನೀಡುತ್ತಾರೆ, ಚೆನ್ನೈನಲ್ಲಿರುವ ಟ್ರಾನ್ಸ್‌ಜೆಂಡರ್‌ಗಳ ಆಶ್ರಯ ಮನೆ. ಅಲ್ಲಿನ ನಿವಾಸಿಗಳು ಅವಳೊಂದಿಗೆ ಮಾತನಾಡುವುದನ್ನು ಆನಂದಿಸುತ್ತಾರೆ ಮತ್ತು ಅವಳ ಕಥೆಯಿಂದ ಸ್ಫೂರ್ತಿ ಪಡೆದಿದ್ದಾರೆ.",
              "te": "పరిశోధన కోసం, ఆమె చెన్నైలోని ట్రాన్స్ జెండర్ వ్యక్తుల కోసం ఒక షెల్టర్ హోమ్ అయిన గరిమా గ్రేహ్‌ను క్రమం తప్పకుండా సందర్శిస్తుంది. అక్కడ నివాసితులు ఆమెతో మాట్లాడటం ఆనందిస్తారు మరియు ఆమె కథ నుండి ప్రేరణ పొందుతారు.",
              "or": "ଅନୁସନ୍ଧାନ ପାଇଁ, ସେ ଚେନ୍ନାଇର ଟ୍ରାନ୍ସଜେଣ୍ଡର ଲୋକଙ୍କ ପାଇଁ ଏକ ଆଶ୍ରୟ ଗୃହ ଗାରିମା ଗ୍ରେହ୍‌ଙ୍କୁ ନିୟମିତ ଭାବରେ ପରିଦର୍ଶନ କରନ୍ତି | ସେଠାରେ ରହୁଥିବା ବାସିନ୍ଦାମାନେ ତାଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବାକୁ ଉପଭୋଗ କରନ୍ତି ଏବଂ ତାଙ୍କ କାହାଣୀ ଦ୍ୱାରା ଅନୁପ୍ରାଣିତ ହୁଅନ୍ତି |",
              "as": "গৱেষণাৰ বাবে, তাই নিয়মীয়াকৈ চেন্নাইৰ ট্ৰেন্সজেণ্ডাৰ লোকসকলৰ বাবে আশ্ৰয় গৃহ গৰিমা গ্ৰেহ লৈ যায়। তাত থকা আৱাসীসকলে তাইৰ সৈতে কথা পাতি ভাল পায় আৰু তাইৰ কাহিনীৰ দ্বাৰা অনুপ্ৰাণিত হয়।",
              "gu": "રિસર્ચ માટે, તેણી ચેન્નેઈમાં સ્થિતિ ટ્રાન્સલિંગ લોકો માટેના આશ્રયસ્થાન ગરિમા ગૃહની નિયમિત મુલાકાત લે છે.  ત્યાંના રહેવાસીઓને તેણી સાથે વાત કરી આનંદ થાય છે અને તેણીની સ્ટોરીથી પ્રેરિત થાય છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_3_6///",
              "hi": "C6_3_6 ///",
              "ka": "C6_3_6///",
              "te": "C6_3_6///",
              "or": "C6_3_6///",
              "as": "C6_3_6///",
              "gu": "C6_3_6///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I have faced many challenges in my life. I couldn't continue my education in engineering because I was harassed. I didn't even have friends. After that experience, I was confused about who I was and what I wanted.",
              "hi": "मैंने अपने जीवन में अनेक चुनौतियों का सामना किया है। मैं इंजीनियरिंग में अपनी शिक्षा जारी नहीं रख सकी क्योंकि मुझे परेशान किया जाता था। मेरे पास मित्र भी नहीं थे। उस अनुभव के बाद, मैं उलझन में थी कि मैं कौन थी और मैं क्या चाहती थी ।",
              "ka": "ನನ್ನ ಜೀವನದಲ್ಲಿ ಹಲವು ಸವಾಲುಗಳನ್ನು ಎದುರಿಸಿದ್ದೇನೆ. ನನಗೆ ಕಿರುಕುಳ ನೀಡಿದ್ದರಿಂದ ಇಂಜಿನಿಯರಿಂಗ್ ಶಿಕ್ಷಣವನ್ನು ಮುಂದುವರಿಸಲಾಗಲಿಲ್ಲ. ನನಗೆ ಸ್ನೇಹಿತರೇ ಇರಲಿಲ್ಲ. ಆ ಅನುಭವದ ನಂತರ, ನಾನು ಯಾರು ಮತ್ತು ನನಗೆ ಏನು ಬೇಕು ಎಂಬ ಗೊಂದಲದಲ್ಲಿದ್ದೆ.",
              "te": "నా జీవితంలో ఎన్నో సవాళ్లను ఎదుర్కొన్నాను. వేధింపుల కారణంగా ఇంజినీరింగ్‌ చదువు కొనసాగించలేకపోయాను. నాకు స్నేహితులు కూడా లేరు. ఆ అనుభవం తర్వాత నేనెవరు, నాకు ఏమి కావాలో తెలియక అయోమయంలో పడ్డాను.",
              "or": "ମୁଁ ମୋ ଜୀବନରେ ଅନେକ ଚ୍ୟାଲେଞ୍ଜର ସମ୍ମୁଖୀନ ହୋଇଛି | ଇଞ୍ଜିନିୟରିଂରେ ମୁଁ ମୋର ଶିକ୍ଷା ଜାରି ରଖିପାରିଲି ନାହିଁ କାରଣ ମୋତେ ହଇରାଣ କରାଯାଇଥିଲା | ମୋର ସାଙ୍ଗମାନେ ମଧ୍ୟ ନଥିଲେ | ସେହି ଅଭିଜ୍ଞତା ପରେ ମୁଁ କିଏ ଏବଂ ମୁଁ କ’ଣ ଚାହୁଁଥିଲି ସେ ବିଷୟରେ ଦ୍ୱନ୍ଦ୍ୱରେ ପଡ଼ିଥିଲି |",
              "as": "মই মোৰ জীৱনত বহুতো প্ৰত্যাহ্বানৰ সন্মুখীন হৈছো। মই অভিযান্ত্ৰিক বিদ্যাত শিক্ষা অব্যাহত ৰাখিব নোৱাৰিলোঁ কাৰণ মোক হাৰাশাস্তি কৰা হৈছিল। মোৰ আনকি বন্ধুও নাছিল। সেই অভিজ্ঞতাৰ পিছত, মই কোন আৰু মই কি বিচাৰিছিলো সেই বিষয়ে মই বিভ্ৰান্ত হৈছিলোঁ।",
              "gu": "મેં મારા જીવનમાં ઘણા પડકારોનો સામનો કર્યો છે. મને હેરાન કરવામાં આવતો હોવાથી હું એન્જિનિયરિંગમાં મારું શિક્ષણ ચાલુ રાખી શક્યો નહીં. મારા મિત્રો પણ નહોતા. એ અનુભવ પછી હું મૂંઝવણમાં હતો કે હું કોણ છું અને મારે શું જોઈએ છે."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_3_6b///",
              "hi": "C6_3_6b /////",
              "ka": "C6_3_6b///",
              "te": "C6_3_6b///",
              "or": "C6_3_6b///",
              "as": "C6_3_6b///",
              "gu": "C6_3_6b///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Some people will accept you, irrespective of what your gender is, and I am glad I have found such people. Hence, now I feel every day is not challenging but exciting.",
              "hi": "कुछ लोग आपको स्वीकार करेंगे, भले ही आपका लिंग क्या हो, और मुझे खुशी है कि मुझे ऐसे लोग मिल गए हैं। इसलिए, अब मुझे लगता है कि हर दिन चुनौतीपूर्ण नहीं परन्तु रोमांचक है।",
              "ka": "ನಿಮ್ಮ ಲಿಂಗವನ್ನು ಲೆಕ್ಕಿಸದೆ ಕೆಲವರು ನಿಮ್ಮನ್ನು ಸ್ವೀಕರಿಸುತ್ತಾರೆ ಮತ್ತು ಅಂತಹ ಜನರನ್ನು ನಾನು ಕಂಡುಕೊಂಡಿದ್ದೇನೆ ಎಂದು ನನಗೆ ಖುಷಿಯಾಗಿದೆ. ಆದ್ದರಿಂದ, ಈಗ ನಾನು ಪ್ರತಿದಿನವೂ ಸವಾಲಿನದ್ದಲ್ಲ ಆದರೆ ರೋಮಾಂಚನಕಾರಿ ಎಂದು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "మీ లింగంతో సంబంధం లేకుండా కొంతమంది మిమ్మల్ని అంగీకరిస్తారు మరియు అలాంటి వారు నాకు ఉన్నందుకు సంతోషిస్తున్నాను. అందుకే, ఇప్పుడు నేను ప్రతిరోజు సవాలుతో కూడుకున్నది కాదు, ఉత్తేజకరమైనదిగా భావిస్తున్నాను.",
              "or": "ତୁମର ଲିଙ୍ଗ ଯାହା ହେଉନା କାହିଁକି କିଛି ଲୋକ ତୁମକୁ ଗ୍ରହଣ କରିବେ, ଏବଂ ମୁଁ ଖୁସି ମୁଁ ଏହିପରି ଲୋକଙ୍କୁ ପାଇଛି | ତେଣୁ, ମୁଁ ବର୍ତ୍ତମାନ ପ୍ରତ୍ୟେକ ଦିନ ଚ୍ୟାଲେଞ୍ଜ ନୁହେଁ ବରଂ ରୋମାଞ୍ଚକର ଅନୁଭବ କରୁଛି |",
              "as": "কিছুমান লোকে আপোনাক গ্ৰহণ কৰিব, আপোনাৰ লিংগ যিয়েই নহওক, আৰু মই আনন্দিত যে মই এনে লোক বিচাৰি পাইছো। সেয়েহে, এতিয়া মই অনুভৱ কৰোঁ যে প্ৰতিটো দিন প্ৰত্যাহ্বানজনক নহয় কিন্তু ৰোমাঞ্চকৰ।",
              "gu": "તમારા લિંગને ધ્યાનમાં લીધા વિના કેટલાક લોકો તમને સ્વીકારશે, અને મને આનંદ છે કે મને આવા લોકો મળ્યા છે.  તેથી, હવે મને લાગે છે કે દરેક દિવસ ચુનૌતી ભર્યો નથી પણ રોમાંચક છે."
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_3_6c///",
              "hi": "C6_3_6C ////",
              "ka": "C6_3_6c///",
              "te": "C6_3_6c///",
              "or": "C6_3_6c///",
              "as": "C6_3_6c///",
              "gu": "C6_3_6c///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I know it will take time to get where I want to be. I am proud and happy for being independent and standing on my own feet.",
              "hi": "मुझे पता है कि मैं जहां जाना चाहती हूं, वहां पहुंचने में समय लगेगा। स्वतंत्र होने और अपने पैरों पर खड़े होने पर मुझे गर्व और खुशी है।",
              "ka": "ನಾನು ಎಲ್ಲಿರಬೇಕೆಂದು ಬಯಸುತ್ತೇನೋ ಅಲ್ಲಿಗೆ ಹೋಗಲು ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ ಎಂದು ನನಗೆ ತಿಳಿದಿದೆ. ನಾನು ಸ್ವತಂತ್ರವಾಗಿ ಮತ್ತು ನನ್ನ ಸ್ವಂತ ಕಾಲಿನ ಮೇಲೆ ನಿಂತಿರುವುದಕ್ಕೆ ಹೆಮ್ಮೆ ಮತ್ತು ಸಂತೋಷವಾಗಿದೆ.",
              "te": "నేను ఎక్కడ ఉండాలనుకుంటున్నానో అక్కడికి చేరుకోవడానికి సమయం పడుతుందని నాకు తెలుసు. నేను స్వతంత్రంగా ఉండి నా స్వంత కాళ్లపై నిలబడినందుకు గర్వంగా మరియు సంతోషంగా ఉన్నాను.",
              "or": "ମୁଁ ଜାଣେ ମୁଁ ଯେଉଁଠାରେ ରହିବାକୁ ଚାହୁଁଛି ତାହା ପାଇବାକୁ ସମୟ ଲାଗିବ | ସ୍ୱାଧୀନ ହେବା ଏବଂ ନିଜ ପାଦରେ ଠିଆ ହୋଇଥିବାରୁ ମୁଁ ଗର୍ବିତ ଏବଂ ଖୁସି |",
              "as": "মই জানো যে মই য'ত থাকিব বিচাৰো তালৈ যাবলৈ সময় লাগিব। স্বতন্ত্ৰ হোৱা আৰু নিজৰ ভৰিত থিয় হোৱাৰ বাবে মই গৌৰৱান্বিত আৰু সুখী।",
              "gu": "હું જાણું છું કે મારે જ્યાં પહુંચવું  છે ત્યાં પહોંચવામાં સમય લાગશે.  મને ગર્વ છે કે હું સ્વતંત્ર છું અને પગભર છું ."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_4_1",
              "hi": "C6_4_1",
              "ka": "C6_4_1",
              "te": "C6_4_1",
              "or": "C6_4_1",
              "as": "C6_4_1",
              "gu": "C6_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita has completed three months in Chennai, and her family comes to visit her. They go to her office and meet her coworkers, and they are happy to see that she has found a positive work environment.",
              "hi": "अस्मिता ने चेन्नई में तीन महीने पूरे किए हैं, और उसका परिवार उससे मिलने के लिए आता है। वे उसके कार्यालय में जाते हैं और उसके सहकर्मियों से मिलते हैं, और वे यह देखकर खुश हैं कि उसे सकारात्मक कार्य वातावरण मिला है।",
              "ka": "ಅಸ್ಮಿತಾ ಚೆನ್ನೈನಲ್ಲಿ ಮೂರು ತಿಂಗಳುಗಳನ್ನು ಪೂರೈಸಿದ್ದಾರೆ ಮತ್ತು ಅವರ ಕುಟುಂಬವು ಅವಳನ್ನು ಭೇಟಿ ಮಾಡಲು ಬರುತ್ತದೆ. ಅವರು ಅವಳ ಕಚೇರಿಗೆ ಹೋಗಿ ಅವಳ ಸಹೋದ್ಯೋಗಿಗಳನ್ನು ಭೇಟಿಯಾಗುತ್ತಾರೆ ಮತ್ತು ಅವರು ಸಕಾರಾತ್ಮಕ ಕೆಲಸದ ವಾತಾವರಣವನ್ನು ಕಂಡುಕೊಂಡಿದ್ದಾರೆ ಎಂದು ಅವರು ಸಂತೋಷಪಡುತ್ತಾರೆ.",
              "te": "అస్మిత చెన్నైలో మూడు నెలలు గడిపింది, మరియు ఆమె కుటుంబం ఆమెను చూడటానికి వచ్చింది. వారు ఆమె కార్యాలయానికి వెళ్లి ఆమె సహోద్యోగులను కలుసుకున్నారు, మరియు ఆమె పని వాతావరణాన్ని చూసినందుకు వారు సంతోషిస్తారు.",
              "or": "ଅସ୍ମିତା ଚେନ୍ନାଇରେ ତିନିମାସ ପୂରଣ କରିଛନ୍ତି ଏବଂ ତାଙ୍କ ପରିବାର ତାଙ୍କୁ ଦେଖା କରିବାକୁ ଆସିଛନ୍ତି | ସେମାନେ ତାଙ୍କ କାର୍ଯ୍ୟାଳୟକୁ ଯାଆନ୍ତି ଏବଂ ତାଙ୍କ ସହକର୍ମୀଙ୍କୁ ଭେଟନ୍ତି, ଏବଂ ସେମାନେ ଖୁସି ଅନୁଭବ କରନ୍ତି ଯେ ସେ ଏକ ସକାରାତ୍ମକ କାର୍ଯ୍ୟ ପରିବେଶ ପାଇଛନ୍ତି |",
              "as": "অস্মিতাই চেন্নাইত তিনি মাহ সম্পূৰ্ণ কৰিছে, আৰু তেওঁৰ পৰিয়ালে তেওঁক লগ কৰিবলৈ আহিছে। তেওঁলোকে তাইৰ অফিচলৈ যায় আৰু তাইৰ সহকৰ্মীসকলক লগ কৰে, আৰু তেওঁলোকে দেখি সুখী হয় যে তাই এক ইতিবাচক কামৰ পৰিৱেশ বিচাৰি পাইছে।",
              "gu": "અસ્મિતાએ ચેન્નેઈમાં  રહેતા ત્રણ મહિના પૂરા થયા છે, અને તેનો પરિવાર તેને મળવા આવે છે.  તેઓ તેણીની ઑફિસમાં જાય છે અને તેણીના સહકાર્યકરોને મળે છે, અને તેઓ એ જોઈને ખુશ થાય છે કે તેણીને કામ કરવા માટે સકારાત્મક વાતાવરણ મળ્યું છે."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "C6_4_3",
              "hi": "C6_4_3",
              "ka": "C6_4_3",
              "te": "C6_4_3",
              "or": "C6_4_3",
              "as": "C6_4_3",
              "gu": "C6_4_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita knows that there are many challenges she still has to face in her life. Still, she is grateful for the support of her family through all the difficulties in her life.",
              "hi": "अस्मिता जानती है कि अनेक चुनौतियां हैं जिनका उसे अभी भी अपने जीवन में सामना करना पड़ता है। फिर भी, वह अपने जीवन में सभी कठिनाइयों को सहते हुए अपने परिवार के समर्थन के लिए आभारी है।",
              "ka": "ಅಸ್ಮಿತಾ ತನ್ನ ಜೀವನದಲ್ಲಿ ಇನ್ನೂ ಅನೇಕ ಸವಾಲುಗಳನ್ನು ಎದುರಿಸಬೇಕಾಗಿದೆ ಎಂದು ತಿಳಿದಿದೆ. ಆದರೂ, ತನ್ನ ಜೀವನದ ಎಲ್ಲಾ ಕಷ್ಟಗಳ ಮೂಲಕ ತನ್ನ ಕುಟುಂಬದ ಬೆಂಬಲಕ್ಕಾಗಿ ಅವಳು ಕೃತಜ್ಞಳಾಗಿದ್ದಾಳೆ.",
              "te": "అస్మితకు తన జీవితంలో ఇంకా ఎన్నో సవాళ్లు ఎదురవుతున్నాయని తెలుసు. అయినప్పటికీ, తన జీవితంలోని అన్ని కష్టాల నుండి తన కుటుంబం యొక్క మద్దతుకు ఆమె కృతజ్ఞతలు తెలుపుతుంది.",
              "or": "ଅସ୍ମିତା ଜାଣନ୍ତି ଯେ ତାଙ୍କ ଜୀବନରେ ତାଙ୍କୁ ତଥାପି ଅନେକ ଚ୍ୟାଲେଞ୍ଜର ସମ୍ମୁଖୀନ ହେବାକୁ ପଡିବ | ତଥାପି, ତାଙ୍କ ଜୀବନର ସମସ୍ତ ଅସୁବିଧାର ସମ୍ମୁଖୀନ ହୋଇଥିବା ବେଳେ ତାଙ୍କ ପରିବାରର ସାହାଯ୍ୟ ପାଇଁ ସେ କୃତଜ୍ଞ |",
              "as": "অস্মিতাই জানে যে তাই এতিয়াও তাইৰ জীৱনত বহুতো প্ৰত্যাহ্বানৰ সন্মুখীন হ'ব লাগিব। তথাপিও, তাই তাইৰ জীৱনৰ সকলো অসুবিধাৰ মাজতো তাইৰ পৰিয়ালৰ সহায়ৰ বাবে কৃতজ্ঞ।",
              "gu": "અસ્મિતા જાણે છે કે તેના જીવનમાં હજુ પણ ઘણી ચુનૌતીઓનો સામનો કરવાનો છે.  પણ, તેણી પોતાના જીવનની તમામ મુશ્કેલીઓમાં તેણીનાં પરિવારે જે સમર્થન આપ્યું તે  માટે આભારી છે."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "Research Consultant",
              "hi": "शोध परामर्शदाता",
              "ka": "ಸಂಶೋಧನಾ ಸಲಹೆಗಾರ",
              "te": "రీసర్చ్ కన్సల్టెంట్",
              "or": "ଅନୁସନ୍ଧାନ ପରାମର୍ଶଦାତା",
              "as": "গৱেষণা পৰামৰ্শদাতা",
              "gu": "રિસર્ચ કન્સલ્ટન્ટ"
            }
          }
        ]
      },
      "scene17": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Do the Fellowship",
              "hi": "फैलोशिप करे",
              "ka": "ಫೆಲೋಶಿಪ್ ಮಾಡಿ",
              "te": "ఫెలోషిప్ చేయాలి",
              "or": "ଫେଲୋସିପ୍ କରନ୍ତୁ",
              "as": "ফেলোশ্বিপ কৰক",
              "gu": "ફેલોશિપ કરે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita arrives in Delhi for the fellowship. She has already informed the team about her identity, and they are happy to have her on board.",
              "hi": "अस्मिता फैलोशिप के लिए दिल्ली आती है। उसने पहले से ही अपनी पहचान के बारे में टीम को सूचित किया है, और वे उसे बोर्ड पर रखने में प्रसन्न हैं।",
              "ka": "ಫೆಲೋಶಿಪ್‌ಗಾಗಿ ಅಸ್ಮಿತಾ ದೆಹಲಿಗೆ ಆಗಮಿಸುತ್ತಾಳೆ. ಅವಳು ಈಗಾಗಲೇ ತನ್ನ ಗುರುತಿನ ಬಗ್ಗೆ ತಂಡಕ್ಕೆ ತಿಳಿಸಿದ್ದಾಳೆ ಮತ್ತು ಅವಳನ್ನು ಮಂಡಳಿಯಲ್ಲಿ ಹೊಂದಲು ಅವರು ಸಂತೋಷಪಡುತ್ತಾರೆ.",
              "te": "ఫెలోషిప్ కోసం అస్మిత ఢిల్లీకి చేరుకుంది. ఆమె ఇప్పటికే తన గురించి బృందానికి తెలియజేసింది మరియు వారు ఆమెను బోర్డులోకి తీసుకున్నందుకు సంతోషంగా ఉన్నారు.",
              "or": "ଅସ୍ମିତା ଫେଲୋସିପ୍ ପାଇଁ ଦିଲ୍ଲୀରେ ପହଞ୍ଚିଛନ୍ତି | ସେ ତାଙ୍କ ପରିଚୟ ବିଷୟରେ ଦଳକୁ ଅବଗତ କରାଇ ସାରିଛନ୍ତି, ଏବଂ ସେମାନେ ତାଙ୍କୁ ନିଜ ପାଖରେ ପାଇଁ ଖୁସି ଅଛନ୍ତି |",
              "as": "অস্মিতা ফেলোশ্বিপৰ বাবে দিল্লীত উপস্থিত হয়। তাই ইতিমধ্যে দলটোক তাইৰ পৰিচয়ৰ বিষয়ে অৱগত কৰিছে, আৰু তেওঁলোকে তাইক ব'ৰ্ডত পাই সুখী।",
              "gu": "અસ્મિતા ફેલોશિપ માટે દિલ્હી પહોંચે છે.  તેણીએ પોતાની ઓળખ વિશે ટીમને પહેલેથી જ જાણ કરી દીધી છે, અને તેઓ તેણીને બોર્ડમાં સામેલ કરીને ખુશ છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_1_2",
              "hi": "C7_1_2",
              "ka": "C7_1_2",
              "te": "C7_1_2",
              "or": "C7_1_2",
              "as": "C7_1_2",
              "gu": "C7_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "However, she has a lot of trouble finding a place to stay. Eventually, through the organisation's support, she finds a room to stay.",
              "hi": "हालांकि, उसे रहने के लिए जगह खोजने में बहुत परेशानी होती है। आखिरकार, संगठन के समर्थन के माध्यम से, उसे रहने के लिए एक कमरा मिलता है।",
              "ka": "ಆದಾಗ್ಯೂ, ಅವಳು ಉಳಿಯಲು ಸ್ಥಳವನ್ನು ಹುಡುಕಲು ಸಾಕಷ್ಟು ತೊಂದರೆಗಳನ್ನು ಹೊಂದಿದ್ದಾಳೆ. ಅಂತಿಮವಾಗಿ, ಸಂಸ್ಥೆಯ ಬೆಂಬಲದ ಮೂಲಕ, ಅವಳು ಉಳಿಯಲು ಒಂದು ಕೋಣೆಯನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "కానీ, ఆమె నివసించడానికి చాలా ఇబ్బందులు పడుతోంది. చివరికి, సంస్థ యొక్క మద్దతు ద్వారా, ఆమె ఉండడానికి ఒక గదిని చూసుకుంటుంది.",
              "or": "ତଥାପି, ରହିବାକୁ ସ୍ଥାନ ଖୋଜିବାରେ ତାଙ୍କୁ ବହୁତ ଅସୁବିଧା ହୁଏ | ପରିଶେଷରେ, ସଂଗଠନର ସମର୍ଥନ ମାଧ୍ୟମରେ, ସେ ରହିବାକୁ ଏକ ରୁମ୍‌ ପାଆନ୍ତି |",
              "as": "অৱশ্যে, থাকিবলৈ ঠাই বিচাৰি পোৱাত তাইৰ যথেষ্ট সমস্যা হয়। অৱশেষত, প্ৰতিষ্ঠানটোৰ সমৰ্থনৰ জৰিয়তে, তাই থাকিবলৈ এটা ৰুম বিচাৰি পায়।",
              "gu": "પણ, તેણીને રહેવાની જગ્યા શોધવામાં ઘણી મુશ્કેલી પડે છે.  આખરે, સંસ્થાના મદદ થી, તેણીને રહેવા માટે એક રૂમ મળે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_1_3",
              "hi": "C7_1_3",
              "ka": "C7_1_3",
              "te": "C7_1_3",
              "or": "C7_1_3",
              "as": "C7_1_3",
              "gu": "C7_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "In the fellowship, they make social spaces for the LGBTQ+ community sensitive and inclusive. Click to read the work they are doing.",
              "hi": " फैलोशिप में,वे एलजीबीटीक्यू + समुदाय के लिए सामाजिक स्थान को संवेदनशील और समावेशी बनाते हैं। वे जो काम कर रहे हैं, उसे पढ़ने के लिए क्लिक करें।",
              "ka": "ಫೆಲೋಶಿಪ್‌ನಲ್ಲಿ, ಅವರು LGBTQ+ ಸಮುದಾಯಕ್ಕೆ ಸಾಮಾಜಿಕ ಸ್ಥಳಗಳನ್ನು ಸೂಕ್ಷ್ಮ ಮತ್ತು ಒಳಗೊಳ್ಳುವಂತೆ ಮಾಡುತ್ತಾರೆ. ಅವರು ಮಾಡುತ್ತಿರುವ ಕೆಲಸವನ್ನು ಓದಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "ఫెలోషిప్‌లో, వారు LGBTQ+ కమ్యూనిటీ కోసం సెన్సిటివ్‌గా మరియు అందరినీ కలుపుకొని పోయేలా సోషల్ స్పేస్‌లను ఏర్పాటు చేస్తారు. వారు చేసే పనిని చదవడానికి క్లిక్ చేయండి.",
              "or": "ଫେଲୋସିପ୍ ରେ, ସେମାନେ LGBTQ + ସମୁଦାୟ ପାଇଁ ସମ୍ବେଦନଶୀଳ ଏବଂ ଅନ୍ତର୍ଭୂକ୍ତ ପାଇଁ ସାମାଜିକ ସ୍ଥାନ ସୃଷ୍ଟି କରନ୍ତି | ସେମାନେ କରୁଥିବା କାର୍ଯ୍ୟ ପଢିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "ফেলোশ্বিপত, তেওঁলোকে LGBTQ+ সম্প্ৰদায়ৰ বাবে সংবেদনশীল সামাজিক স্থানবোৰ অন্তৰ্ভুক্ত কৰে। তেওঁলোকে কৰি থকা কামটো পঢ়িবলৈ ক্লিক কৰক।",
              "gu": "ફેલોશિપમાં, તેઓ LGBTQ+ સંવેદનશીલ સમુદાય માટે સામાજમાં સ્થાન બનાવે છે અને તેમને પણ સમાજની સાથે જોડાઈ છે.  તેઓ જે કામ કરી રહ્યા છે તે વાંચવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "Page1",
              "as": "পৃষ্ঠা ১",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ2",
              "or": "Page2",
              "as": "পৃষ্ঠা ২",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Page3",
              "hi": "पृष्ठ 3",
              "ka": "ಪುಟ 3",
              "te": "పేజీ3",
              "or": "Page3",
              "as": "পৃষ্ঠা ৩",
              "gu": "પેજ 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "Page4",
              "hi": "पृष्ठ 4",
              "ka": "ಪುಟ 4",
              "te": "పేజీ4",
              "or": "Page4",
              "as": "পৃষ্ঠা ৪",
              "gu": "પેજ 4"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Support the research and analysis of human rights violations affecting LGBTQ+ people.",
              "hi": "एलजीबीटीक्यू + लोगों को प्रभावित करने वाले मानवाधिकार उल्लंघन के अनुसंधान और विश्लेषण का समर्थन करे।",
              "ka": "LGBTQ+ ಜನರ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುವ ಮಾನವ ಹಕ್ಕುಗಳ ಉಲ್ಲಂಘನೆಗಳ ಸಂಶೋಧನೆ ಮತ್ತು ವಿಶ್ಲೇಷಣೆಯನ್ನು ಬೆಂಬಲಿಸಿ.",
              "te": "LGBTQ+ వ్యక్తులను ప్రభావితం చేసే మానవ హక్కుల ఉల్లంఘనల పరిశోధన మరియు విశ్లేషణకు మద్దతు ఇవ్వండి.",
              "or": "LGBTQ + ଲୋକଙ୍କୁ ପ୍ରଭାବିତ କରୁଥିବା ମାନବିକ ଅଧିକାରର ଉଲ୍ଲଂଘନର ଅନୁସନ୍ଧାନ ଏବଂ ବିଶ୍ଳେଷଣକୁ ସମର୍ଥନ କରନ୍ତୁ |",
              "as": "LGBTQ+ লোকসকলক প্ৰভাৱিত কৰা মানৱ অধিকাৰ উলংঘনৰ গৱেষণা আৰু বিশ্লেষণ সমৰ্থন কৰক।",
              "gu": "LGBTQ+ લોકોને અસર કરતા માનવ અધિકારોના ઉલ્લંઘનના  રિસર્ચ અને વિશ્લેષણને સમર્થન આપવું."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "File PILs (Public interest litigation) and petitions for the elimination of laws that discriminate against the LGBTQ+ community",
              "hi": "एलजीबीटीक्यू समुदाय के खिलाफ भेदभाव करने वाले कानूनों को खत्म करने के लिए जनहित याचिका (जनहित याचिका) और प्रार्थना पत्र  दायर करे",
              "ka": "LGBTQ+ ಸಮುದಾಯದ ವಿರುದ್ಧ ತಾರತಮ್ಯ ಮಾಡುವ ಕಾನೂನುಗಳ ನಿರ್ಮೂಲನೆಗಾಗಿ PIL ಗಳು (ಸಾರ್ವಜನಿಕ ಹಿತಾಸಕ್ತಿ ದಾವೆ) ಮತ್ತು ಅರ್ಜಿಗಳನ್ನು ಸಲ್ಲಿಸಿ",
              "te": "LGBTQ+ సంఘంపై వివక్ష చూపే చట్టాల తొలగింపు కోసం PILలు (ప్రజా ప్రయోజన వ్యాజ్యం) మరియు పిటిషన్‌లను ఫైల్ చేయండి",
              "or": "ଆଇନଗୁଡିକ, ଯାହା LGBTQ ସମୁଦାୟ ପ୍ରତି ଭେଦଭାବ କରେ, ବିଲୋପ ପାଇଁ PIL ଫାଇଲ୍ (ଜନ ସ୍ୱାର୍ଥ ମକଦ୍ଦମା) ଏବଂ ପିଟିସନ୍‌ ଦର୍ଜ କରିବା",
              "as": "ফাইল PILs (ৰাজহুৱা স্বাৰ্থৰ মোকৰ্দমা) আৰু LGBTQ+ সম্প্ৰদায়ৰ বিৰুদ্ধে বৈষম্যমূলক আইন বিলোপ কৰাৰ বাবে আবেদন",
              "gu": "PIL (જાહેર હિતની અરજી) ફાઇલ કરવી અને LGBTQ + સમુદાય સામે ભેદભાવ કરતા કાયદાઓને નાબૂદ કરવા માટેની અરજીઓ ફાઈલ કરવી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Use innovative methods like workshops, street plays, demonstrations and film screenings to highlight legal and social issues which affect the LGBTQ+ community",
              "hi": "एलजीबीटीक्यू + समुदाय को प्रभावित करने वाले कानूनी और सामाजिक मुद्दों को हाइलाइट करने के लिए कार्यशालाओं, सड़क के नाटकों, प्रदर्शनों और फिल्म स्क्रीनिंग जैसे नवीन विधियों का उपयोग करे",
              "ka": "LGBTQ+ ಸಮುದಾಯದ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರುವ ಕಾನೂನು ಮತ್ತು ಸಾಮಾಜಿಕ ಸಮಸ್ಯೆಗಳನ್ನು ಹೈಲೈಟ್ ಮಾಡಲು ಕಾರ್ಯಾಗಾರಗಳು, ಬೀದಿ ನಾಟಕಗಳು, ಪ್ರದರ್ಶನಗಳು ಮತ್ತು ಚಲನಚಿತ್ರ ಪ್ರದರ್ಶನಗಳಂತಹ ನವೀನ ವಿಧಾನಗಳನ್ನು ಬಳಸಿ",
              "te": "LGBTQ+ సంఘాన్ని ప్రభావితం చేసే చట్టపరమైన మరియు సామాజిక సమస్యలను హైలైట్ చేయడానికి వర్క్‌షాప్‌లు, వీధి నాటకాలు, ప్రదర్శనలు మరియు చలనచిత్ర ప్రదర్శనలు వంటి వినూత్న పద్ధతులను ఉపయోగించండి",
              "or": "ଆଇନଗତ ଏବଂ ସାମାଜିକ ସମସ୍ୟାକୁ LGBTQ + ସମୁଦାୟକୁ ପ୍ରଭାବିତ କରୁଥିବା ଆଇନଗତ ଏବଂ ସାମାଜିକ ସମସ୍ୟାକୁ ଆଲୋକିତ କରିବା ପାଇଁ କର୍ମଶାଳା, ଗଳି ନାଟକ, ପ୍ରଦର୍ଶନ ଏବଂ ଚଳଚ୍ଚିତ୍ର ସ୍କ୍ରିନିଂ ଭଳି ଅଭିନବ ପଦ୍ଧତି ବ୍ୟବହାର କରନ୍ତୁ |",
              "as": "LGBTQ+ সম্প্ৰদায়ক প্ৰভাৱিত কৰা আইনী আৰু সামাজিক সমস্যাবোৰৰ বাবে কৰ্মশালা, পথনাটক, প্ৰদৰ্শন আৰু চলচ্চিত্ৰ প্ৰদৰ্শনৰ দৰে অভিনৱ পদ্ধতি ব্যৱহাৰ কৰা",
              "gu": "LGBTQ+ સમુદાયને અસર કરતા કાયદાકીય અને સામાજિક મુદ્દાઓને પ્રકાશિત કરવા માટે વર્કશોપ, શેરી નાટકો નું પ્રદર્શન અને ફિલ્મ સ્ક્રીનીંગ જેવી નવીન પદ્ધતિઓનો ઉપયોગ કરવો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.popUpText",
            "value": {
              "en": "Engage in discussions with several stakeholders such as the police, the bureaucrats, policy makers, the press and the corporate sector to sensitize them about the issues of transgender people",
              "hi": "ट्रांसजेंडर लोगों के मुद्दों के बारे में उन्हें संवेदनशील बनाने के लिए पुलिस, नौकरशाहों, नीति निर्माताओं, प्रेस और कॉर्पोरेट क्षेत्र जैसे कई हितधारकों के साथ चर्चाओं में संलग्न हो",
              "ka": "ಪೊಲೀಸ್, ಅಧಿಕಾರಿಗಳು, ನೀತಿ ನಿರೂಪಕರು, ಪತ್ರಿಕಾ ಮತ್ತು ಕಾರ್ಪೊರೇಟ್ ವಲಯದಂತಹ ಹಲವಾರು ಮಧ್ಯಸ್ಥಗಾರರೊಂದಿಗೆ ಲಿಂಗಾಯತ ಜನರ ಸಮಸ್ಯೆಗಳ ಬಗ್ಗೆ ಜಾಗೃತಿ ಮೂಡಿಸಲು ಚರ್ಚೆಯಲ್ಲಿ ತೊಡಗಿಸಿಕೊಳ್ಳಿ",
              "te": "ట్రాన్స్‌జెండర్ల సమస్యల గురించి వారికి అవగాహన కల్పించేందుకు పోలీసులు, బ్యూరోక్రాట్‌లు, పాలసీ మేకర్స్, ప్రెస్ మరియు కార్పొరేట్ సెక్టార్ వంటి అనేక మంది వాటాదారులతో చర్చలు జరపండి.",
              "or": "ଟ୍ରାନ୍ସଜେଣ୍ଡର୍‌ ଲୋକଙ୍କ ସମସ୍ୟା ବିଷୟରେ ସଚେତନ କରିବା ପାଇଁ ପୋଲିସ୍, ଅମଲାତନ୍ତ୍ର, ନୀତି ନିର୍ମାତା, ପ୍ରେସ୍ ଏବଂ କର୍ପୋରେଟ୍ ସେକ୍ଟର ଭଳି ଅନେକ ହିତାଧିକାରୀଙ୍କ ସହ ଆଲୋଚନାରେ ଜଡିତ ହୁଅନ୍ତୁ |",
              "as": "আৰক্ষী, আমোলা, নীতি নিৰ্মাতা, প্ৰেছ আৰু কৰ্পোৰেট খণ্ডৰ দৰে কেইবাজনো লোকক আলোচনাত অংশগ্ৰহণ কৰিবলৈ মাতিব লাগিব যাতে তেওঁলোকক ট্ৰেন্সজেণ্ডাৰ লোকসকলৰ সমস্যাবোৰৰ বিষয়ে সংবেদনশীল কৰিব পৰা যায়।",
              "gu": "ટ્રાન્સલિંગ લોકોની સમસ્યાઓ વિશે સંવેદનશીલ બનાવવા માટે પોલીસ, અમલદારો, નીતિ નિર્માતાઓ, પ્રેસ અને કોર્પોરેટ ક્ષેત્ર જેવા કેટલાક હિસ્સેદારો સાથે ચર્ચામાં જોડાવું."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_1_4",
              "hi": "C7_1_4",
              "ka": "C7_1_4",
              "te": "C7_1_4",
              "or": "C7_1_4",
              "as": "C7_1_4",
              "gu": "C7_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The fellows have regular meetings with expert lawyers, policymakers, and activists to share progress and collectively solve challenges.",
              "hi": "प्रगति को साझा करने और सामूहिक रूप से चुनौतियों का समाधान करने के लिए फेलो विशेषज्ञ वकीलों, नीति निर्माताओं और कार्यकर्ताओं के साथ नियमित बैठकें करते हैं।",
              "ka": "ಫೆಲೋಗಳು ಪ್ರಗತಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ಮತ್ತು ಒಟ್ಟಾಗಿ ಸವಾಲುಗಳನ್ನು ಪರಿಹರಿಸಲು ಪರಿಣಿತ ವಕೀಲರು, ನೀತಿ ನಿರೂಪಕರು ಮತ್ತು ಕಾರ್ಯಕರ್ತರೊಂದಿಗೆ ನಿಯಮಿತವಾಗಿ ಸಭೆಗಳನ್ನು ನಡೆಸುತ್ತಾರೆ.",
              "te": "పురోగతిని పంచుకోవడానికి మరియు సవాళ్లను పరిష్కరించడానికి నిపుణులైన న్యాయవాదులు, విధాన రూపకర్తలు మరియు కార్యకర్తలతో సభ్యులు క్రమం తప్పకుండా సమావేశాలు నిర్వహిస్తారు.",
              "or": "ଅଗ୍ରଗତି ଅଂଶୀଦାର କରିବା ଏବଂ ଚାଲେଞ୍ଜର ସମାଧାନ ପାଇଁ ଆଇନଜୀବୀ ବିଶେଷଜ୍ଞ, ନୀତି ନିର୍ଣ୍ଣୟକାରୀ ଏବଂ କାର୍ଯ୍ୟକର୍ତ୍ତାଙ୍କ ସହ ସାଥୀମାନେ ନିୟମିତ ବୈଠକ କରନ୍ତି |",
              "as": "সহকৰ্মীসকলে তেওঁলোকৰ প্ৰগতিৰ বিষয়ে আলেচনা কৰিবলৈ আৰু সামূহিকভাৱে প্ৰত্যাহ্বান সমাধান কৰিবলৈ বিশেষজ্ঞ উকীল, নীতি নিৰ্ধাৰক আৰু কৰ্মীসকলৰ সৈতে নিয়মীয়া বৈঠক কৰে।",
              "gu": "ફેલો  નિષ્ણાત વકીલો, નીતિ ઘડવૈયાઓ અને કાર્યકર્તાઓ સાથે પ્રગતિ શેર કરવા અને સામૂહિક રીતે પડકારોનો ઉકેલ લાવવા માટે નિયમિત બેઠકો કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_1_5",
              "hi": "C7_1_5",
              "ka": "C7_1_5",
              "te": "C7_1_5",
              "or": "C7_1_5",
              "as": "C7_1_5",
              "gu": "C7_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The fellows also attend many workshops and sessions where they are trained to develop the following four competencies: Self-Awareness, Collaborative Work, Entrepreneurship, and Leadership.",
              "hi": "फेलो कई कार्यशालाओं और सत्रों में भी भाग लेते हैं जहाँ उन्हें निम्नलिखित चार दक्षताओं को विकसित करने के लिए प्रशिक्षित किया जाता है: आत्म-जागरूकता, सहयोगात्मक कार्य, उद्यमिता और नेतृत्व।",
              "ka": "ಫೆಲೋಗಳು ಅನೇಕ ಕಾರ್ಯಾಗಾರಗಳು ಮತ್ತು ಸೆಷನ್‌ಗಳಿಗೆ ಹಾಜರಾಗುತ್ತಾರೆ, ಅಲ್ಲಿ ಅವರು ಈ ಕೆಳಗಿನ ನಾಲ್ಕು ಸಾಮರ್ಥ್ಯಗಳನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಲು ತರಬೇತಿ ನೀಡುತ್ತಾರೆ: ಸ್ವಯಂ-ಅರಿವು, ಸಹಯೋಗದ ಕೆಲಸ, ಉದ್ಯಮಶೀಲತೆ ಮತ್ತು ನಾಯಕತ್ವ.",
              "te": "సహచరులు అనేక వర్క్‌షాప్‌లు మరియు సెషన్‌లకు హాజరవుతారు, ఇక్కడ వారు క్రింది నాలుగు సామర్థ్యాలను అభివృద్ధి చేసుకోవడానికి శిక్షణ పొందుతారు: స్వీయ-అవగాహన, సహకార పని, వ్యవస్థాపకత మరియు నాయకత్వం.",
              "or": "ସାଥୀମାନେ ଅନେକ କର୍ମଶାଳା ଏବଂ ଅଧିବେଶନରେ ମଧ୍ୟ ଯୋଗ ଦିଅନ୍ତି ଯେଉଁଠାରେ ସେମାନେ ନିମ୍ନଲିଖିତ ଚାରୋଟି ଦକ୍ଷତାର ବିକାଶ ପାଇଁ ତାଲିମପ୍ରାପ୍ତ: ଆତ୍ମ-ସଚେତନତା, ସହଯୋଗୀ କାର୍ଯ୍ୟ, ଉଦ୍ୟୋଗୀତା ଏବଂ ନେତୃତ୍ୱ |",
              "as": "সহকৰ্মীসকলে বহুতো কৰ্মশালা আৰু অধিৱেশনত অংশগ্ৰহণ কৰে য'ত তেওঁলোকক নিম্নলিখিত চাৰিটা দক্ষতা বিকাশৰ বাবে প্ৰশিক্ষণ দিয়া হয়: আত্ম-সজাগতা, সহযোগিতামূলক কাম, উদ্যোগ, আৰু নেতৃত্ব।",
              "gu": "ફેલો ઘણી વર્કશોપ અને સત્રોમાં પણ હાજરી આપે છે જ્યાં તેમને નીચેની ચાર ક્ષમતાઓ વિકસાવવા માટે તાલીમ આપવામાં આવે છે: સ્વ-જાગૃતિ, સહયોગી કાર્ય, સાહસિકતા અને નેતૃત્વ."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "Fellowship",
              "hi": "फैलोशिप ",
              "ka": "ಫೆಲೋಶಿಪ್",
              "te": "ఫెలోషిప్",
              "or": "ଫେଲୋସିପ୍",
              "as": "ফেলোশ্বিপ",
              "gu": "ફેલોશિપ"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_2_1",
              "hi": "C7_2_1",
              "ka": "C7_2_1",
              "te": "C7_2_1",
              "or": "C7_2_1",
              "as": "C7_2_1",
              "gu": "C7_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Now that she is feeling more settled, she is keen on taking the next step in her transition.",
              "hi": "अब जबकि वह अधिक व्यवस्थित अनुभव कर रही है, वह अपने परिवर्तन में अगला कदम उठाने के लिए उत्सुक है।",
              "ka": "ಈಗ ಅವಳು ಹೆಚ್ಚು ನೆಲೆಗೊಂಡಿದ್ದಾಳೆ, ಅವಳು ತನ್ನ ಪರಿವರ್ತನೆಯ ಮುಂದಿನ ಹೆಜ್ಜೆಯನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಉತ್ಸುಕಳಾಗಿದ್ದಾಳೆ.",
              "te": "ఇప్పుడు ఆమె మరింత స్థిరపడినట్లు భావిస్తుంది, ఆమె తన పరివర్తనలో తదుపరి అడుగు వేయాలని ఆసక్తిగా ఉంది.",
              "or": "ବର୍ତ୍ତମାନ ଯେହେତୁ ସେ ଅଧିକ ସ୍ଥିର ଅନୁଭବ କରୁଛନ୍ତି, ସେ ତାଙ୍କ ପରିବର୍ତ୍ତନର ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପ ନେବାକୁ ଆଗ୍ରହୀ ହୁଅନ୍ତି |",
              "as": "এতিয়া যেতিয়া তাই অধিক স্থিৰ অনুভৱ কৰিছে, তাই তাইৰ পৰিৱৰ্তনৰ পৰৱৰ্তী পদক্ষেপ ল'বলৈ আগ্ৰহী।",
              "gu": "હવે તેણી વધુ સ્થાયી  થઈ હોય તેવો અનુભવી રહી છે, અને તેણી પોતાનાં ટ્રાન્સિશનમાં આગળનું પગલું ભરવા માટે ઉત્સુક છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_2_2",
              "hi": "C7_2_2",
              "ka": "C7_2_2",
              "te": "C7_2_2",
              "or": "C7_2_2",
              "as": "C7_2_2",
              "gu": "C7_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She gets on a call with Vandana from her support group to get her advice.\nVandana is also a transwoman, so she understands many of Asmita's problems.",
              "hi": "वह वंदना को अपने सहायता समूह से सलाह लेने के लिए कॉल करती है।\nवंदना एक ट्रांसवुमन भी हैं, इसलिए वह अस्मिता की कई समस्याओं को समझती हैं।",
              "ka": "\"ವಂದನಾ ಅವರ ಸಲಹೆಯನ್ನು ಪಡೆಯಲು ಅವರ ಬೆಂಬಲ ಗುಂಪಿನಿಂದ ಅವರು ಕರೆ ಮಾಡುತ್ತಾರೆ.\n ವಂದನಾ ಕೂಡ ಟ್ರಾನ್ಸ್ ವುಮನ್ ಆಗಿರುವುದರಿಂದ ಅಸ್ಮಿತಾ ಅವರ ಅನೇಕ ಸಮಸ್ಯೆಗಳನ್ನು ಅವರು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "ఆమె సలహా కోసం ఆమె సపోర్ట్ గ్రూప్ లోని వందనకు కాల్ చేస్తుంది.\n వందన కూడా ట్రాన్స్ ఉమెన్ కాబట్టి ఆమె అస్మిత యొక్క చాలా సమస్యలను అర్థం చేసుకుంటుంది.",
              "or": "ତାଙ୍କ ପରାମର୍ଶ ପାଇବା ପାଇଁ ସେ ତାଙ୍କ ସମର୍ଥନ ଗୋଷ୍ଠୀରୁ ବନ୍ଦନାଙ୍କ ସହ ଏକ କଲ କରନ୍ତି |\n ବନ୍ଦନା ମଧ୍ୟ ଜଣେ ଟ୍ରାନ୍ସୱୁମ୍ୟାନ୍, ତେଣୁ ସେ ଅସ୍ମିତାର ଅନେକ ସମସ୍ୟା ବୁଝିପାରନ୍ତି |",
              "as": "তাই পৰামৰ্শ পাবলৈ তাইৰ সমৰ্থন গোটৰ বন্দনালৈ ফোন কৰিছিল।\n \n বন্দনা এগৰাকী ট্ৰেন্সৱমেন, সেয়েহে তেওঁ অস্মিতাৰ বহুতো সমস্যা বুজি পায়।",
              "gu": "સલાહ લેવા માટે તેણી પોતાનાં સપોર્ટ ગ્રૂપની વંદના સાથે ફોન પર વાત કરે છે. વંદના પણ એક ટ્રાન્સવુમન છે, તેથી તેણી અસ્મિતાની ઘણી સમસ્યાઓ સમજે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_2_3//",
              "hi": "C7_2_3 //",
              "ka": "C7_2_3//",
              "te": "C7_2_3//",
              "or": "C7_2_3//",
              "as": "C7_2_3//",
              "gu": "C7_2_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Vandana",
              "hi": "वंदना",
              "ka": "ವಂದನಾ",
              "te": "వందన",
              "or": "ବନ୍ଦନା",
              "as": "বন্দনা",
              "gu": "વંદના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Asmita",
              "hi": "अस्मिता",
              "ka": "ಅಸ್ಮಿತಾ",
              "te": "అస్మిత",
              "or": "ଅସ୍ମିତା",
              "as": "অস্মিতা",
              "gu": "અસ્મિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "It’s great that your family is so supportive.",
              "hi": "यह बहुत अच्छा है कि आपका परिवार इतना सहयोग देने वाला  है।",
              "ka": "ನಿಮ್ಮ ಕುಟುಂಬವು ತುಂಬಾ ಬೆಂಬಲ ನೀಡುತ್ತಿರುವುದು ಅದ್ಭುತವಾಗಿದೆ.",
              "te": "మీ కుటుంబం సపోర్ట్ చేయడం చాలా బాగుంది.",
              "or": "ଏହା ବହୁତ ଭଲ ଯେ ତୁମ ପରିବାର ଏତେ ସହଯୋଗୀ |",
              "as": "এইটো বৰ ভাল কথা যে আপোনাৰ পৰিয়ালে ইমান সহায় কৰে।",
              "gu": "આ ખૂબ જ સારી વાત છે કે તમારો પરિવાર ખૂબ જ સાથ આપે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I can’t thank them enough. I owe so much to them.",
              "hi": "मैं उन्हें पर्याप्त धन्यवाद नहीं दे सकती। मैं उनकी बहुत ऋणी हूं।",
              "ka": "ನಾನು ಅವರಿಗೆ ಸಾಕಷ್ಟು ಧನ್ಯವಾದ ಹೇಳಲಾರೆ. ನಾನು ಅವರಿಗೆ ತುಂಬಾ ಋಣಿಯಾಗಿದ್ದೇನೆ.",
              "te": "నేను వారికి తగినంత కృతజ్ఞతలు చెప్పలేను. నేను వారికి చాలా రుణపడి ఉన్నాను.",
              "or": "ମୁଁ ସେମାନଙ୍କୁ ଯଥେଷ୍ଟ ଧନ୍ୟବାଦ ଦେଇ ପାରିବି ନାହିଁ | ମୋର ପରିବାର ପ୍ରତି ମୋର ବହୁତ ଋଣ ଅଛି |",
              "as": "মই তেওঁলোকক পৰ্যাপ্ত ধন্যবাদ দিব নোৱাৰো। মই তেওঁলোকৰ ওচৰত বহুত ঋণী।",
              "gu": "તેમનો આભાર માનવા માટે મારી પાસે શબ્દો નથી.  હું મારા પરિવારની ખૂબજ ઋણી છું."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_2_4////",
              "hi": "C7_2_4 ////////",
              "ka": "C7_2_4////",
              "te": "C7_2_4////",
              "or": "C7_2_4////",
              "as": "C7_2_4////",
              "gu": "C7_2_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I am glad that you want to take the next steps for your transition, but it is not going to be as easy this time. With hormone therapy, you'll go through both physical and mental changes.",
              "hi": "मुझे खुशी है कि आप अपने ट्रांज़िशन / बदलाव के लिए अगले कदम उठाना चाहती हैं, लेकिन यह इस समय जितना सरल नहीं होगा। हार्मोन थेरेपी के साथ, आप को शारीरिक और मानसिक दोनों परिवर्तनों से गुजरना होगा।",
              "ka": "ನಿಮ್ಮ ಸ್ಥಿತ್ಯಂತರಕ್ಕಾಗಿ ನೀವು ಮುಂದಿನ ಹಂತಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಬಯಸಿದ್ದಕ್ಕಾಗಿ ನನಗೆ ಸಂತೋಷವಾಗಿದೆ, ಆದರೆ ಈ ಬಾರಿ ಅದು ಸುಲಭವಾಗುವುದಿಲ್ಲ. ಹಾರ್ಮೋನ್ ಚಿಕಿತ್ಸೆಯೊಂದಿಗೆ, ನೀವು ದೈಹಿಕ ಮತ್ತು ಮಾನಸಿಕ ಬದಲಾವಣೆಗಳ ಮೂಲಕ ಹೋಗುತ್ತೀರಿ.",
              "te": "నువ్వు నీ పరివర్తన కోసం తదుపరి దశలను తీసుకోవాలని కోరుకుంటున్నందుకు నేను సంతోషిస్తున్నాను, కానీ ఈసారి అది అంత సులభం కాదు. హార్మోన్ థెరపీతో, నువ్వు శారీరక మరియు మానసిక మార్పులను ఎదుర్కొంటావు.",
              "or": "ମୁଁ ଖୁସି ଯେ ତୁମେ ତୁମର ପରିବର୍ତ୍ତନ ପାଇଁ ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପ ନେବାକୁ ଚାହୁଁଛ, କିନ୍ତୁ ଏଥର ଏହା ସହଜ ହେବ ନାହିଁ | ହରମୋନ୍ ଥେରାପି ସହିତ, ଆପଣ ଉଭୟ ଶାରୀରିକ ଏବଂ ମାନସିକ ପରିବର୍ତ୍ତନ ଦେଇ ଗତି କରିବେ |",
              "as": "মই আনন্দিত যে আপুনি আপোনাৰ পৰিৱৰ্তনৰ বাবে পৰৱৰ্তী পদক্ষেপ ল'ব বিচাৰে, কিন্তু এইবোৰ এই সময়ত সিমান সহজ নহ'ব। হৰমন থেৰাপীৰ দ্বাৰা, আপুনি শাৰীৰিক আৰু মানসিক দুয়োধৰণৰ পৰিৱৰ্তনৰ মাজেৰে যাব।",
              "gu": "મને ખુશી છે કે તમે પોતાનાં ટ્રાન્સિશન માટે આગળનાં પગલાં લેવા માંગો છો, પરંતુ આ વખતે તે એટલું સરળ નથી.  હોર્મોન ઉપચાર સાથે, તમે શારીરિક અને માનસિક બંને ફેરફારોમાંથી પસાર થશો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, that's exactly what I wanted to understand.",
              "hi": "हां, यह वही है जो मैं समझना चाहती थी।",
              "ka": "ಹೌದು, ನಾನು ಅದನ್ನೇ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಬಯಸಿದ್ದು .",
              "te": "అవును, నేను అర్థం చేసుకోవాలనుకున్నది అదే.",
              "or": "ହଁ, ତାହା ଠିକ୍ ଯାହା ମୁଁ ବୁଝିବାକୁ ଚାହୁଁଥିଲି |",
              "as": "হয়, মই ঠিক সেইটোৱেই বুজিব বিচাৰিছিলো।",
              "gu": "હા, હું  બરાબર એ જ સમજવા માંગતી હતી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "The endocrinologist (the doctor who will administer hormone therapy) will take you through the medical aspects. Make sure you're connected with a specialist or a good support group to keep your mental health in check. Having a routine apart from work will also help you cope with the changes.",
              "hi": "एंडोक्रिनोलॉजिस्ट (डॉक्टर जो हार्मोन थेरेपी का प्रबंध करेगा) आपको चिकित्सा पहलुओं के बारे में बताएगा। अपने मानसिक स्वास्थ्य को नियंत्रण में रखने के लिए सुनिश्चित करें कि आप किसी विशेषज्ञ या किसी अच्छे सहायता समूह से जुड़े हुए हैं। काम के अलावा एक रूटीन रखने से आपको बदलावों से निपटने में भी सहायता मिलेगी।",
              "ka": "ಅಂತಃಸ್ರಾವಶಾಸ್ತ್ರಜ್ಞರು (ಹಾರ್ಮೋನ್ ಚಿಕಿತ್ಸೆಯನ್ನು ನಿರ್ವಹಿಸುವ ವೈದ್ಯರು) ನಿಮ್ಮನ್ನು ವೈದ್ಯಕೀಯ ಅಂಶಗಳ ಮೂಲಕ ತೆಗೆದುಕೊಳ್ಳುತ್ತಾರೆ. ನಿಮ್ಮ ಮಾನಸಿಕ ಆರೋಗ್ಯವನ್ನು ಪರೀಕ್ಷಿಸಲು ನೀವು ತಜ್ಞರು ಅಥವಾ ಉತ್ತಮ ಬೆಂಬಲ ಗುಂಪಿನೊಂದಿಗೆ ಸಂಪರ್ಕ ಹೊಂದಿದ್ದೀರಿ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ. ಕೆಲಸದ ಹೊರತಾಗಿ ದಿನಚರಿಯನ್ನು ಹೊಂದಿರುವುದು ಬದಲಾವಣೆಗಳನ್ನು ನಿಭಾಯಿಸಲು ನಿನಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "ఎండోక్రైనాలజిస్ట్ (హార్మోన్ థెరపీని నిర్వహించే వైద్యుడు) వైద్యపరమైన అంశాలు నీకు తెలుపుతారు. నీ మానసిక ఆరోగ్యాన్ని అదుపులో ఉంచుకోవడానికి నువ్వు స్పెషలిస్ట్ లేదా మంచి సపోర్ట్ గ్రూప్‌తో కనెక్ట్ అయి ఉండేలాగా చూసుకోవాలి. పని కాకుండా వేరే రొటీన్ కలిగి ఉండటం కూడా మార్పులను ఎదుర్కోవడంలో మీకు సహాయపడుతుంది.",
              "or": "ଏଣ୍ଡୋକ୍ରାଇନୋଲୋଜିଷ୍ଟ (ଡାକ୍ତର ଯିଏ ହରମୋନ୍ ଥେରାପି ପରିଚାଳନା କରିବେ) ଆପଣଙ୍କୁ ଚିକିତ୍ସା ମାଧ୍ଯମ ଦେଇ ନେଇଯିବେ | ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ମାନସିକ ସ୍ୱାସ୍ଥ୍ୟକୁ ନିୟନ୍ତ୍ରଣରେ ରଖିବା ପାଇଁ ଏକ ବିଶେଷଜ୍ଞ କିମ୍ବା ଏକ ଭଲ ସମର୍ଥନ ସମୁଦାୟ ସହିତ ସଂଯୁକ୍ତ ଅଟନ୍ତି | କାମ ବ୍ୟତୀତ ଏକ ନିତ୍ୟ ବ୍ୟବହାର୍ଯ୍ୟ ରହିବା ମଧ୍ୟ ପରିବର୍ତ୍ତନଗୁଡ଼ିକର ମୁକାବିଲା କରିବାରେ ସାହାଯ୍ୟ କରିବ |",
              "as": "এণ্ডোক্ৰিনোলজিষ্টজনে (হৰমন থেৰাপী প্ৰদান কৰা চিকিৎসক) আপোনাক চিকিৎসাৰ দিশবোৰৰ মাজেৰে লৈ যাব। নিশ্চিত কৰিব যে আপোনাৰ মানসিক স্বাস্থ্য নিয়ন্ত্ৰণত ৰাখিবলৈ আপুনি বিশেষজ্ঞ বা ভাল সমৰ্থন গোটএটাৰ সৈতে সংযুক্ত হৈ আছে। কামৰ বাহিৰেও এটা ৰুটিন থাকিলে আপোনাক পৰিৱৰ্তনবোৰৰ সৈতে মোকাবিলা কৰাত সহায় কৰিব।",
              "gu": "એન્ડોક્રિનોલોજિસ્ટ (ડોક્ટર જે હોર્મોન ઉપચારનું સંચાલન કરશે) તમને બધી તબીબી બાબતો સમજાવશે .  તમારા માનસિક સ્વાસ્થ્યને નિયંત્રણમાં રાખવા માટે તમે નિષ્ણાત અથવા સારા સહાયક જૂથ સાથે જોડાયેલા છો તેની ખાતરી કરો.  કામ સિવાય અન્ય બાબતની પણ દિનચર્યા રાખવાથી તમને ફેરફારોનો સામનો કરવામાં પણ મદદ મળશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "I hadn't thought about that.",
              "hi": "मैंने इसके बारे में नहीं सोचा था।",
              "ka": "ನಾನು ಅದರ ಬಗ್ಗೆ ಯೋಚಿಸಿರಲಿಲ್ಲ",
              "te": "నేను దాని గురించి ఆలోచించలేదు.",
              "or": "ମୁଁ ସେ ବିଷୟରେ ଚିନ୍ତା କରି ନ ଥିଲି |",
              "as": "মই সেই বিষয়ে ভবা নাছিলো।",
              "gu": "મેં આનાં વિશે વિચાર્યું ન હતું."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_3_1",
              "hi": "C7_3_1",
              "ka": "C7_3_1",
              "te": "C7_3_1",
              "or": "C7_3_1",
              "as": "C7_3_1",
              "gu": "C7_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Over the next few months, her body undergoes several changes. She begins feeling a lot more comfortable with herself.",
              "hi": "अगले कुछ महीनों में, उसके शरीर में कई बदलाव हुए। वह खुद के साथ बहुत अधिक आरामदायक अनुभव कर रही है।",
              "ka": "ಮುಂದಿನ ಕೆಲವು ತಿಂಗಳುಗಳಲ್ಲಿ, ಅವಳ ದೇಹವು ಹಲವಾರು ಬದಲಾವಣೆಗಳಿಗೆ ಒಳಗಾಗುತ್ತದೆ. ಅವಳು ತನ್ನೊಂದಿಗೆ ಹೆಚ್ಚು ಆರಾಮದಾಯಕವಾಗಲು ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ.",
              "te": "తరువాతి కొన్ని నెలల్లో, ఆమె శరీరం అనేక మార్పులకు గురవుతుంది. ఆమె చాలా సౌకర్యంగా ఉండటం ప్రారంభించింది.",
              "or": "ପରବର୍ତ୍ତୀ କିଛି ମାସ ମଧ୍ୟରେ, ତାଙ୍କ ଶରୀରରେ ଅନେକ ପରିବର୍ତ୍ତନ ଆସିଛି | ସେ ନିଜ ସହିତ ବହୁତ ଆରାମଦାୟକ ଅନୁଭବ କରିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "পৰৱৰ্তী কেইমাহমানৰ ভিতৰত, তাইৰ শৰীৰত কেইবাটাও পৰিৱৰ্তন হয়। তাই নিজৰ সৈতে বহুত বেছি সহজ অনুভৱ কৰিবলৈ আৰম্ভ কৰে।",
              "gu": "આગામી કેટલાક મહિનાઓમાં, તેના શરીરમાં ઘણા ફેરફારો થયા.  તેણીને પોતાનું શરીર  વધુ સ્વસ્થ હોય તેવા અનુભવવા થવા લાગ્યો."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_3_2",
              "hi": "C7_3_2",
              "ka": "C7_3_2",
              "te": "C7_3_2",
              "or": "C7_3_2",
              "as": "C7_3_2",
              "gu": "C7_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "To clear her mind, she talks to her counsellor and her family regularly.\nWhen she wants to be alone, she goes for a long walk, watches good movies, and dances on her favourite track.",
              "hi": "अपने दिमाग को शांत रखने के लिए, वह अपने काउंसलर और अपने परिवार से नियमित रूप से बात करती है।\nजब वह अकेले रहना चाहती है, तो वह लंबी सैर पर जाती है, अच्छी फिल्में देखती है और अपने पसंदीदा धुनों पर नृत्य करती है।",
              "ka": "\"ಅವಳ ಮನಸ್ಸನ್ನು ತೆರವುಗೊಳಿಸಲು, ಅವಳು ತನ್ನ ಸಲಹೆಗಾರ ಮತ್ತು ಅವಳ ಕುಟುಂಬದೊಂದಿಗೆ ನಿಯಮಿತವಾಗಿ ಮಾತನಾಡುತ್ತಾಳೆ.\n ಅವಳು ಒಬ್ಬಂಟಿಯಾಗಿರಲು ಬಯಸಿದಾಗ, ಅವಳು ದೀರ್ಘ ನಡಿಗೆಗೆ ಹೋಗುತ್ತಾಳೆ, ಒಳ್ಳೆಯ ಚಲನಚಿತ್ರಗಳನ್ನು ನೋಡುತ್ತಾಳೆ ಮತ್ತು ಅವಳ ನೆಚ್ಚಿನ ಟ್ರ್ಯಾಕ್ನಲ್ಲಿ ನೃತ್ಯ ಮಾಡುತ್ತಾಳೆ.",
              "te": "ఆమె మనస్సును క్లియర్ చేయడానికి, ఆమె తన కౌన్సెలర్ మరియు ఆమె కుటుంబ సభ్యులతో క్రమం తప్పకుండా మాట్లాడుతుంది.\n \n ఆమె ఒంటరిగా ఉండాలనుకున్నప్పుడు, ఆమె చాలా దూరం నడుస్తుంది, మంచి సినిమాలు చూస్తుంది మరియు ఆమె ఇష్టమైన ట్రాక్‌లో డ్యాన్స్ చేస్తుంది.",
              "or": "ତାଙ୍କ ମନକୁ ସଫା କରିବା ପାଇଁ ସେ ତାଙ୍କ ପରାମର୍ଶଦାତା ଏବଂ ତାଙ୍କ ପରିବାର ସହିତ ନିୟମିତ କଥାବାର୍ତ୍ତା କରନ୍ତି।\n ଯେତେବେଳେ ସେ ଏକୁଟିଆ ରହିବାକୁ ଚାହାଁନ୍ତି, ସେତେବେଳେ ସେ ଦୀର୍ଘ ସମୟ ବୁଲିବାକୁ ଯାଆନ୍ତି, ଭଲ ଚଳଚ୍ଚିତ୍ର ଦେଖନ୍ତି ଏବଂ ନିଜ ପ୍ରିୟ ଟ୍ରାକରେ ନାଚନ୍ତି |",
              "as": "তাইৰ মনটো ভালে ৰাখিবলৈ, তাই তাইৰ পৰামৰ্শদাতা আৰু তাইৰ পৰিয়ালৰ সৈতে নিয়মীয়াকৈ কথা পাতে।\n যেতিয়া তাই অকলে থাকিব বিচাৰে, তাই বহু দূৰ খোজ কাঢ়িবলৈ যায়, ভাল চিনেমা চায় আৰু তাইৰ প্ৰিয় ট্ৰেকত নৃত্য কৰে।",
              "gu": "પોતાનાં વિચારોમાં સ્પષ્ટતા લાવવા માટે તેણી પોતાના કાઉન્સેલર અને પરિવાર સાથે નિયમિત વાત કરવા લાગી. જ્યારે તેણીને એકલા રહેવાની ઈચ્છા થતી ત્યારે તેણી દૂર સુધી ચાલવા જતી, સારી ફિલ્મો જોતી અને પોતાના મનપસંદ ટ્રેક પર ડાન્સ કરતી."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_3_3",
              "hi": "C7_3_3",
              "ka": "C7_3_3",
              "te": "C7_3_3",
              "or": "C7_3_3",
              "as": "C7_3_3",
              "gu": "C7_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Since her colleagues are very supportive of her decision, she gets a lot of help managing her treatment and healthcare.",
              "hi": "चूंकि उसके सहयोगी उसके निर्णय के पूर्णतया समर्थक हैं, इसलिए उसे अपने उपचार और स्वास्थ्य देखभाल के प्रबंधन में बहुत सहायता मिलती है।",
              "ka": "ಆಕೆಯ ಸಹೋದ್ಯೋಗಿಗಳು ಆಕೆಯ ನಿರ್ಧಾರವನ್ನು ಬೆಂಬಲಿಸುತ್ತಿರುವುದರಿಂದ, ಆಕೆಯ ಚಿಕಿತ್ಸೆ ಮತ್ತು ಆರೋಗ್ಯವನ್ನು ನಿರ್ವಹಿಸುವಲ್ಲಿ ಆಕೆಗೆ ಸಾಕಷ್ಟು ಸಹಾಯ ದೊರೆಯುತ್ತದೆ.",
              "te": "ఆమె నిర్ణయానికి ఆమె సహోద్యోగులు చాలా మద్దతుగా ఉన్నారు కాబట్టి, ఆమె తన చికిత్స మరియు ఆరోగ్య సంరక్షణ నిర్వహణలో చాలా సహాయాన్ని పొందుతుంది.",
              "or": "ଯେହେତୁ ଅରାଇଜ୍‌ ତାଙ୍କ ନିଷ୍ପତ୍ତିକୁ ବହୁତ ସମର୍ଥନ କରନ୍ତି, ତେଣୁ ସେ ତାଙ୍କର ଚିକିତ୍ସା ଏବଂ ସ୍ୱାସ୍ଥ୍ୟସେବା ପରିଚାଳନାରେ ବହୁତ ସାହାଯ୍ୟ ପାଆନ୍ତି |",
              "as": "যিহেতু তাইৰ সহকৰ্মীসকলে তাইৰ সিদ্ধান্তত যথেষ্ট সমৰ্থন কৰে, তাই তাইৰ চিকিৎসা আৰু স্বাস্থ্যৰ যত্ন লোৱাত যথেষ্ট সহায় পায়।",
              "gu": "તેણીનાં સહકર્મચારીઓ તેણીનાં નિર્ણયને ખૂબ જ ટેકો આપતા હોવાથી, તેણીને પોતાની સારવાર અને સ્વસ્થ્યની સંભાળ કરવામાં ઘણી મદદ મળતી હતી."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_3_4",
              "hi": "C7_3_4",
              "ka": "C7_3_4",
              "te": "C7_3_4",
              "or": "C7_3_4",
              "as": "C7_3_4",
              "gu": "C7_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is happy about the work she is doing. A few months after she settles in Delhi, her family visits her.",
              "hi": "अस्मिता उस काम के बारे में खुश है जो वह कर रही है। दिल्ली में बसने के कुछ महीने बाद, उसका परिवार उससे मिलने आता है।",
              "ka": "ತಾನು ಮಾಡುತ್ತಿರುವ ಕೆಲಸದ ಬಗ್ಗೆ ಅಸ್ಮಿತಾ ಖುಷಿಯಾಗಿದ್ದಾಳೆ. ಅವಳು ದೆಹಲಿಯಲ್ಲಿ ನೆಲೆಸಿದ ಕೆಲವು ತಿಂಗಳ ನಂತರ, ಅವಳ ಕುಟುಂಬವು ಅವಳನ್ನು ಭೇಟಿ ಮಾಡುತ್ತದೆ.",
              "te": "తను చేస్తున్న పనికి అస్మిత సంతోషంగా ఉంటుంది. ఆమె ఢిల్లీలో స్థిరపడిన కొన్ని నెలల తర్వాత, ఆమె కుటుంబం ఆమెను దగ్గరకు వెళ్తుంది.",
              "or": "ସେ କରୁଥିବା କାମ ସହିତ ଅସ୍ମିତା ଖୁସି ଅଛନ୍ତି | ସେ ଦିଲ୍ଲୀରେ ବସିବାର କିଛି ମାସ ପରେ, ତାଙ୍କ ପରିବାର ତାଙ୍କୁ ଦେଖା କରନ୍ତି |",
              "as": "অস্মিতাই তাই কৰি থকা কামটোৰ বাবে সুখী। তাই দিল্লীত থিতাপি লোৱাৰ কেইমাহমান পিছত, তাইৰ পৰিয়ালে তাইক লগ কৰিবলৈ আহে।",
              "gu": "અસ્મિતા જે કામ કરી રહી છે તેનાથી ખુશ છે.  તેણી દિલ્હીમાં સ્થાયી થયાના થોડા મહિના પછી, તેણીનો પરિવાર તેની મુલાકાત લે છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_1_6",
              "hi": "C7_1_6",
              "ka": "C7_1_6",
              "te": "C7_1_6",
              "or": "C7_1_6",
              "as": "C7_1_6",
              "gu": "C7_1_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Her parents are proud of her for finding a space where she is respected and valued. Pranav calls her often to check how she is doing.",
              "hi": "उसके माता-पिता को उस पर गर्व है कि उसने एक ऐसा स्थान पाया जहाँ उसे सम्मान और महत्व दिया जाता है। प्रणव उसे बार-बार फोन करके पता लगाता है कि वह कैसा कर रही है।",
              "ka": "ಅವಳು ಗೌರವಾನ್ವಿತ ಮತ್ತು ಮೌಲ್ಯಯುತವಾದ ಸ್ಥಳವನ್ನು ಕಂಡುಕೊಂಡಿದ್ದಕ್ಕಾಗಿ ಆಕೆಯ ಪೋಷಕರು ಅವಳ ಬಗ್ಗೆ ಹೆಮ್ಮೆಪಡುತ್ತಾರೆ. ಅವಳು ಹೇಗಿದ್ದಾಳೆ ಎಂದು ಪರೀಕ್ಷಿಸಲು ಪ್ರಣವ್ ಆಗಾಗ್ಗೆ ಅವಳಿಗೆ ಕರೆ ಮಾಡುತ್ತಾನೆ.",
              "te": "ఆమెకు గౌరవం మరియు విలువ ఇచ్చే స్థలంలో ఉన్నందుకు ఆమె తల్లిదండ్రులు ఆమె గురించి గర్విస్తున్నారు. ఆమె ఎలా ఉందో చూసుకోవడానికి ప్రణవ్ తరచూ ఆమెకు ఫోన్ చేస్తుంటాడు.",
              "or": "ତାଙ୍କ ପିତାମାତା ତାଙ୍କ ପାଇଁ ଗର୍ବିତ, ଏକ ସ୍ଥାନ ପାଇବା ପାଇଁ ଯେଉଁଠାରେ ତାଙ୍କୁ ସମ୍ମାନିତ କରାଯାଏ ଏବଂ ମୂଲ୍ୟ ଦିଆଯାଏ | ସେ କିପରି ଅଛନ୍ତି ତାହା ଯାଞ୍ଚ କରିବା ପାଇଁ ପ୍ରଣବ ତାଙ୍କୁ ପ୍ରାୟ କଲ୍‌ କରନ୍ତି |",
              "as": "তাইৰ মাক দেউতাকে তাইক সন্মান আৰু মূল্য দিয়া ঠাই এটুকুৰা বিচাৰি পোৱাৰ বাবে গৌৰৱান্বিত। প্ৰণৱে তাই কেনে আছে জনিবলৈ তাইক প্ৰায়ে ফোন কৰিছিল।",
              "gu": "તેણીના માતા-પિતાને તેણી પર ગર્વ છે કે તેણીને એક એવી જગ્યા મળી છે જ્યાં તેણીનું સન્માન અને મૂલ્ય છે.  પ્રણવ તેણીને વારંવાર ફોન કરે છે કે તેણી કેમ છે."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "C7_1_7",
              "hi": "C7_1_7",
              "ka": "C7_1_7",
              "te": "C7_1_7",
              "or": "C7_1_7",
              "as": "C7_1_7",
              "gu": "C7_1_7"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She is living and working with social entrepreneurs, designers, engineers, and many other unique individuals for the first time. She has a great time listening to their stories and learning about their experiences.",
              "hi": "वह पहली बार सामाजिक उद्यमियों, डिजाइनरों, इंजीनियरों और कई अन्य अद्वितीय व्यक्तियों के साथ रह रही है और काम कर रही है। उनकी कहानियों को सुनने और उनके अनुभवों के बारे में जानने के लिए उसके पास बहुत अच्छा समय है।",
              "ka": "ಅವಳು ಮೊದಲ ಬಾರಿಗೆ ಸಾಮಾಜಿಕ ಉದ್ಯಮಿಗಳು, ವಿನ್ಯಾಸಕರು, ಎಂಜಿನಿಯರ್‌ಗಳು ಮತ್ತು ಇತರ ಅನೇಕ ವಿಶಿಷ್ಟ ವ್ಯಕ್ತಿಗಳೊಂದಿಗೆ ವಾಸಿಸುತ್ತಿದ್ದಾಳೆ ಮತ್ತು ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾಳೆ. ಅವರ ಕಥೆಗಳನ್ನು ಕೇಳಲು ಮತ್ತು ಅವರ ಅನುಭವಗಳ ಬಗ್ಗೆ ಕಲಿಯಲು ಅವಳು ಉತ್ತಮ ಸಮಯವನ್ನು ಹೊಂದಿದ್ದಾಳೆ.",
              "te": "ఆమె మొదటిసారిగా సామాజిక వ్యవస్థాపకులు, డిజైనర్లు, ఇంజనీర్లు మరియు అనేక ఇతర ప్రత్యేక వ్యక్తులతో నివసిస్తుంది మరియు పని చేస్తుంది. ఆమె వారి కథలను వినడం మరియు వారి అనుభవాల గురించి తెలుసుకోవడం ద్వారా చాలా గొప్ప సమయం గడుపుతుంది.",
              "or": "ସେ ପ୍ରଥମ ଥର ପାଇଁ ସାମାଜିକ ଉଦ୍ୟୋଗୀ, ଡିଜାଇନର୍, ଇଞ୍ଜିନିୟର୍ ଏବଂ ଅନ୍ୟାନ୍ୟ ଅନନ୍ୟ ବ୍ୟକ୍ତିବିଶେଷଙ୍କ ସହ ରୁହନ୍ତି ଏବଂ କାର୍ଯ୍ୟ କରନ୍ତି | ସେ ସେମାନଙ୍କର କାହାଣୀ ଶୁଣିବା ଏବଂ ସେମାନଙ୍କ ଅନୁଭୂତି ବିଷୟରେ ଜାଣିବା ପାଇଁ ବହୁତ ଭଲ ସମୟ ବିତାଏ |",
              "as": "তেওঁ প্ৰথমবাৰৰ বাবে সামাজিক উদ্যোগী, ডিজাইনাৰ, অভিযন্তা আৰু আন বহুতো অনন্য ব্যক্তিৰ লগত আছে আৰু কাম কৰি আছে। তাইৰ তেওঁলোকৰ কাহিনী শুনি আৰু তেওঁলোকৰ অভিজ্ঞতাৰ পৰা শিকি ভাল সময় পাৰ কৰিছে।",
              "gu": "તેણી પ્રથમ વખત સામાજિક ઉદ્યમીઓ, ડિઝાઇનરો, એન્જિનિયરો અને અન્ય ઘણી અનૂઠી વ્યક્તિઓ સાથે રહી હતી અને કામ કરી રહી છે.   તેમની સ્ટોરીઓ સાંભળવામાં અને તેમના અનુભવો વિશે શીખવામાં તેણીનો  સારો  સમય ગુજરતો હતો ."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "Fellowship",
              "hi": "फैलोशिप ",
              "ka": "ಫೆಲೋಶಿಪ್",
              "te": "ఫెలోషిప్",
              "or": "ଫେଲୋସିପ୍",
              "as": "ফেলোশ্বিপ",
              "gu": "ફેલોશિપ"
            }
          }
        ]
      },
      "scene19": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Go back to engineering",
              "hi": "इंजीनियरिंग पर वापस जाए",
              "ka": "ಇಂಜಿನಿಯರಿಂಗ್ ಗೆ ಹಿಂತಿರುಗಿ",
              "te": "ఇంజనీరింగ్‌కి తిరిగి వెళ్ళడం",
              "or": "ଇଞ୍ଜିନିୟରିଂକୁ ଫେରନ୍ତୁ",
              "as": "অভিযান্ত্ৰিকলৈ উভতি যাওক",
              "gu": "એન્જિનિયરિંગ માં પાછું જવાનું"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind decides to continue engineering. Although he is nervous about going back to the campus, he is happy that he is getting back to the subjects he is interested in.",
              "hi": "अरविंद ने इंजीनियरिंग जारी रखने का निर्णय किया। यद्यपि वह परिसर में वापस जाने से घबराता है, वह खुश है कि वह उन विषयों पर वापस आ रहा है जिसमें उसकी रुचि है।",
              "ka": "ಅರವಿಂದ್ ಇಂಜಿನಿಯರಿಂಗ್ ಮುಂದುವರಿಸಲು ನಿರ್ಧರಿಸಿದರು. ಕ್ಯಾಂಪಸ್‌ಗೆ ಹಿಂತಿರುಗಲು ಅವರು ನರ್ವಸ್ ಆಗಿದ್ದರೂ, ಅವರು ಆಸಕ್ತಿ ಹೊಂದಿರುವ ವಿಷಯಗಳಿಗೆ ಮರಳುತ್ತಿದ್ದಾರೆ ಎಂದು ಅವರು ಸಂತೋಷಪಡುತ್ತಾರೆ.",
              "te": "అరవింద్ ఇంజనీరింగ్ కొనసాగించాలని నిర్ణయించుకున్నాడు. క్యాంపస్‌కి తిరిగి వెళ్లడం గురించి అతను భయపడుతున్నప్పటికీ, అతను తనకు ఆసక్తి ఉన్న సబ్జెక్ట్‌లకు తిరిగి వెళ్తున్నందుకు సంతోషంగా ఉన్నాడు.",
              "or": "ଇଞ୍ଜିନିୟରିଂ ଜାରି ରଖିବାକୁ ଅରବିନ୍ଦ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ଯଦିଓ ସେ କ୍ୟାମ୍ପସକୁ ଫେରିଯିବା ପାଇଁ ନର୍ଭସ୍‌, ସେ ଖୁସି ଅଛନ୍ତି ଯେ ସେ ଆଗ୍ରହୀ ଥିବା ପାଠକୁ ଫେରୁଛନ୍ତି |",
              "as": "অৰবিন্দে অভিযান্ত্ৰিক কৰি থকাৰ সিদ্ধান্ত লয়। যদিও তেওঁ কেম্পাচলৈ উভতি যোৱাৰ বাবে উদ্বিগ্ন, তেওঁ সুখী যে তেওঁ আগ্ৰহী বিষয়বোৰলৈ ঘূৰি আহিছে।",
              "gu": "અરવિંદે એન્જિનિયરિંગ ચાલુ રાખવાનું નક્કી કર્યું.  જો કે તે કેમ્પસમાં પાછો જવાનાં કારણે નર્વસ છે, પરંતુ તે ખુશ છે કે પોતે જે વિષયોમાં રસ ધરાવે છે તેમાં તે પાછો ફરી રહ્યો છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_1_2",
              "hi": "B1_1_2",
              "ka": "B1_1_2",
              "te": "B1_1_2",
              "or": "B1_1_2",
              "as": "B1_1_2",
              "gu": "B1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The family is also happy that Arvind is keen to get back to his studies again. They feel it would be safe if Arvind gets a place outside the college.",
              "hi": "परिवार इस बात से भी खुश है कि अरविंद फिर से अपनी पढ़ाई पर वापस जाना चाहता है। उसे लगता है कि अगर अरविंद को कॉलेज के बाहर जगह मिल जाए तो यह सुरक्षित रहेगा।",
              "ka": "ಅರವಿಂದ್ ಮತ್ತೆ ಓದಲು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ ಎಂದು ಕುಟುಂಬದವರೂ ಖುಷಿಯಾಗಿದ್ದಾರೆ. ಅರವಿಂದ್‌ಗೆ ಕಾಲೇಜಿನ ಹೊರಗೆ ಸ್ಥಳ ಸಿಕ್ಕರೆ ಸೇಫ್ ಎಂಬ ಭಾವನೆ ಅವರಲ್ಲಿದೆ",
              "te": "అరవింద్ మళ్లీ చదువుకోవాలనుకోడం వల్ల కుటుంబసభ్యులు కూడా ఆనందం వ్యక్తం చేస్తున్నారు. అరవింద్‌కు కాలేజీ బయట చోటు దొరికితే సేఫ్ అని భావిస్తున్నారు.",
              "or": "ପରିବାର ମଧ୍ୟ ଖୁସି ଯେ ଅରବିନ୍ଦ ପୁଣିଥରେ ଅଧ୍ୟୟନକୁ ଫେରିବାକୁ ଆଗ୍ରହୀ | ସେମାନେ ଅନୁଭବ କରନ୍ତି ଏହା ନିରାପଦ ହୁଅନ୍ତା ଯଦି ଅରବିନ୍ଦ କଲେଜ ବାହାରେ ସ୍ଥାନ ପାଆନ୍ତି |",
              "as": "পৰিয়ালটোও সুখী যে অৰবিন্দ পুনৰ পঢ়া-শুনালৈ উভতি আহিবলৈ আগ্ৰহী। তেওঁলোকে অনুভৱ কৰে যে যদি অৰবিন্দে কলেজৰ বাহিৰত থাকিবলৈ ঠাই পায় তেন্তে তেওঁ সুৰক্ষিত হ'ব।",
              "gu": "પરિવાર પણ ખુશ છે કે અરવિંદ ફરીથી અભ્યાસ ચાલું કરવાની ઈચ્છા રાખે છે.  તેઓને લાગે છે કે જો અરવિંદને કોલેજની બહાર જગ્યા મળે તો તે સુરક્ષિત રહેશે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_1_3",
              "hi": "B1_1_3",
              "ka": "B1_1_3",
              "te": "B1_1_3",
              "or": "B1_1_3",
              "as": "B1_1_3",
              "gu": "B1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They help him get a place for himself in a safe neighbourhood. His mother stays with him for some time until he settles down in the new environment.",
              "hi": "वे उसे एक सुरक्षित पड़ोस में अपने लिए जगह दिलाने में सहायता करते हैं। जब तक वह नए माहौल में व्यवस्थित नहीं हो जाता, तब तक उसकी मां उसके साथ कुछ समय तक रहती है।",
              "ka": "ಅವನು ಸುರಕ್ಷಿತ ನೆರೆಹೊರೆಯಲ್ಲಿ ತನಗಾಗಿ ಒಂದು ಸ್ಥಳವನ್ನು ಪಡೆಯಲು ಸಹಾಯ ಮಾಡುತ್ತಾರೆ. ಅವನು ಹೊಸ ಪರಿಸರದಲ್ಲಿ ನೆಲೆಗೊಳ್ಳುವವರೆಗೂ ಅವನ ತಾಯಿ ಅವನೊಂದಿಗೆ ಸ್ವಲ್ಪ ಸಮಯ ಇರುತ್ತಾಳೆ.",
              "te": "సురక్షితమైన పరిసరాల్లో ఉండడానికి అవి అతనికి సహాయపడతాయి. అతను కొత్త వాతావరణంలో స్థిరపడే వరకు అతని తల్లి అతనితో కొంతకాలం ఉంటుంది.",
              "or": "ସେମାନେ ତାଙ୍କୁ ଏକ ସୁରକ୍ଷିତ ଅଞ୍ଚଳରେ ନିଜ ପାଇଁ ସ୍ଥାନ ପାଇବାରେ ସାହାଯ୍ୟ କରନ୍ତି | ନୂତନ ପରିବେଶରେ ସ୍ଥିର ହେବା ପର୍ଯ୍ୟନ୍ତ ତାଙ୍କ ମା ତାଙ୍କ ସହିତ କିଛି ସମୟ ରହନ୍ତି |",
              "as": "তেওঁলোকে তেওঁক এটা সুৰক্ষিত চুবুৰীত নিজৰ বাবে ঠাই পোৱাত সহায় কৰে। নতুন পৰিৱেশত থিতাপি নোলোৱালৈকে তেওঁৰ মাক কেইদিনমান তেওঁৰ লগত থাকে।",
              "gu": "તેઓએ તેને સુરક્ષિત પડોશમાં પોતાના માટે સ્થાન મેળવવામાં મદદ કરી.  જ્યાં સુધી તે નવા વાતાવરણમાં સ્થાયી ન થાય ત્યાં સુધી તેની માતા તેની સાથે થોડો સમય રહી."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_1_4",
              "hi": "B1_1_4",
              "ka": "B1_1_4",
              "te": "B1_1_4",
              "or": "B1_1_4",
              "as": "B1_1_4",
              "gu": "B1_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is very nervous to go back to college. People continue to tease him and he starts skipping classes. He feels helpless and suffocated as the days progress.",
              "hi": "अरविंद कॉलेज वापस जाने के लिए बहुत घबराया हुआ है। लोग उसे चिढ़ाते रहते हैं और वह कक्षा से अनुपस्थित रहने लगता है। दिन की शुरुआत के साथ वह असहाय और घुटन अनुभव करता है।",
              "ka": "ಮತ್ತೆ ಕಾಲೇಜಿಗೆ ಹೋಗಲು ಅರವಿಂದ್ ತುಂಬಾ ನರ್ವಸ್ ಆಗಿದ್ದಾನೆ. ಜನರು ಅವನನ್ನು ಕೀಟಲೆ ಮಾಡುವುದನ್ನು ಮುಂದುವರೆಸುತ್ತಾರೆ ಮತ್ತು ಅವನು ತರಗತಿಗಳನ್ನು ಬಿಡಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ. ದಿನಗಳು ಮುಂದುವರೆದಂತೆ ಅವನು ಅಸಹಾಯಕ ಮತ್ತು ಉಸಿರುಗಟ್ಟಿಸುವಂತಾಗುತ್ತದೆ.",
              "te": "అరవింద్ తిరిగి కాలేజీకి వెళ్ళడానికి చాలా భయపడ్డాడు. అందరూ అతనిని ఆటపట్టించడం కొనసాగిస్తున్నారు మరియు అతను తరగతులను మిస్ చేయడం ప్రారంభించాడు. రోజులు గడుస్తున్న కొద్దీ అతను నిస్సహాయంగా మరియు కష్టంగా భావిస్తాడు.",
              "or": "କଲେଜକୁ ଫେରିବା ପାଇଁ ଅରବିନ୍ଦ ବହୁତ ନର୍ଭସ ଅଛନ୍ତି | ଲୋକମାନେ ତାଙ୍କୁ ଥଟ୍ଟା କରିବା ଜାରି ରଖିଛନ୍ତି ଏବଂ ସେ କ୍ଲାସ ଛାଡିବା ଆରମ୍ଭ କରନ୍ତି | ଦିନ ଗଡିବା ସହିତ ସେ ନିଜକୁ ଅସହାୟ ଏବଂ ଶ୍ୱାସରୁଦ୍ଧ ଅନୁଭବ କରନ୍ତି |",
              "as": "অৰবিন্দ কলেজলৈ উভতি যাবলৈ বৰ নাৰ্ভাছ হৈ আছে। মানুহে তেওঁক জোকাই থাকে আৰু তেওঁ শ্ৰেণীবোৰ এৰাই চলিবলৈ আৰম্ভ কৰে। দিনবোৰ আগবাঢ়ি যোৱাৰ লগে লগে তেওঁ অসহায় আৰু শ্বাসৰুদ্ধ অনুভৱ কৰে।",
              "gu": "અરવિંદ કોલેજમાં પાછાં જતાં સમય બહુ નર્વસ હતો.  લોકો તેને ચીડવતા હતાં અને તે ક્લાસમાં ગેરહાજર રહેવા લાગ્યો.  જેમ જેમ દિવસો પસાર થતા ગયા તેમ તેમ તે અસહાયતા અને ગૂંગળામણનો અનુભવ કરવા લાગ્યો."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_1",
              "hi": "B1_3_1",
              "ka": "B1_3_1",
              "te": "B1_3_1",
              "or": "B1_3_1",
              "as": "B1_3_1",
              "gu": "B1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Meanwhile, Roshini has been studying Sociology at Osmania University. When Roshini comes home for her semester vacation, she sees Arvind sitting in his room quietly. She sits next to Arvind and holds his hand tightly.",
              "hi": "इस बीच रोशनी उस्मानिया विश्वविद्यालय से  समाजशास्त्र की पढ़ाई कर रही है। जब रोशनी अपने सेमेस्टर की छुट्टी में घर आती है, तो वह देखती है कि अरविंद चुपचाप अपने कमरे में बैठा है। वह अरविंद के बगल में बैठ जाती है और उसका हाथ कसकर पकड़ लेती है।",
              "ka": "ಏತನ್ಮಧ್ಯೆ, ರೋಶಿನಿ ಉಸ್ಮಾನಿಯಾ ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಸಮಾಜಶಾಸ್ತ್ರವನ್ನು ಓದುತ್ತಿದ್ದಾಳೆ. ರೋಶಿನಿ ತನ್ನ ಸೆಮಿಸ್ಟರ್ ರಜೆಗೆ ಮನೆಗೆ ಬಂದಾಗ, ಅರವಿಂದ್ ತನ್ನ ಕೋಣೆಯಲ್ಲಿ ಶಾಂತವಾಗಿ ಕುಳಿತಿರುವುದನ್ನು ನೋಡುತ್ತಾಳೆ. ಅವಳು ಅರವಿಂದನ ಪಕ್ಕದಲ್ಲಿ ಕುಳಿತು ಅವನ ಕೈಯನ್ನು ಗಟ್ಟಿಯಾಗಿ ಹಿಡಿದಿದ್ದಾಳೆ.",
              "te": "కాగా, రోష్ని ఉస్మానియా యూనివర్సిటీలో సోషియాలజీ చదువుతోంది. రోష్ని తన సెమిస్టర్ సెలవులకు ఇంటికి వచ్చినప్పుడు, అరవింద్ తన గదిలో నిశ్శబ్దంగా కూర్చోవడం చూస్తుంది. ఆమె అరవింద్ పక్కన కూర్చుని అతని చేతిని గట్టిగా పట్టుకుంటుంది.",
              "or": "ଏହି ସମୟରେ, ରୋଶିନି ଓସମାନିଆ ବିଶ୍ୱବିଦ୍ୟାଳୟରେ ସମାଜବିଜ୍ଞାନ ଅଧ୍ୟୟନ କରୁଛନ୍ତି | ଯେତେବେଳେ ରୋଶିନି ତାଙ୍କ ସେମିଷ୍ଟାର ଛୁଟି ପାଇଁ ଘରକୁ ଆସନ୍ତି, ସେତେବେଳେ ସେ ଅରବିନ୍ଦଙ୍କୁ ତାଙ୍କ କୋଠରୀରେ ଚୁପଚାପ୍ ବସିଥିବାର ଦେଖନ୍ତି | ସେ ଅରବିନ୍ଦଙ୍କ ପାଖରେ ବସି ତାଙ୍କ ହାତକୁ ଜୋରରେ ଧରିଥାନ୍ତି |",
              "as": "ইফালে, ৰোশনিয়ে ওচমানিয়া বিশ্ববিদ্যালয়ত সমাজবিজ্ঞান অধ্যয়ন কৰি আছে। যেতিয়া ৰোশনি তাইৰ ছেমিষ্টাৰৰ বন্ধৰ বাবে ঘৰলৈ আহে, তাই অৰবিন্দক তেওঁৰ কোঠাত চুপচাপ বহি থকা দেখিলে। তাই অৰবিন্দৰ কাষত বহি তেওঁৰ হাতখন জোৰেৰে ধৰে।",
              "gu": "આ દરમિયાન, રોશિની ઓસ્માનિયા યુનિવર્સિટીમાંસમાજશાસ્ત્રનો અભ્યાસ કરી રહી હતી.  જ્યારે રોશિની  સેમેસ્ટર વેકેશન માટે ઘરે આવી, ત્યારે તેણીએ અરવિંદને પોતાનાં રૂમમાં ચુપચાપ બેઠેલો જોયો.  તે અરવિંદની બાજુમાં બેસી ગઈ અને તેનો હાથ જોરથી પકડી લીધો."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_2////",
              "hi": "B1_3_2 //////////",
              "ka": "B1_3_2////",
              "te": "B1_3_2////",
              "or": "B1_3_2////",
              "as": "B1_3_2////",
              "gu": "B1_3_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I feel so suffocated, Roshni. I’m too scared to do anything.",
              "hi": "मैं बहुत घुटन का अनुभव करता हूं, रोशनी। मैं कुछ भी करने के लिए बहुत डरता हूँ।",
              "ka": "ನನಗೆ ತುಂಬಾ ಉಸಿರುಗಟ್ಟಿದೆ ರೋಶನಿ. ನಾನು ಏನನ್ನೂ ಮಾಡಲು ತುಂಬಾ ಹೆದರುತ್ತೇನೆ.",
              "te": "నేను చాలా ఉక్కిరిబిక్కిరి అవుతున్నాను, రోష్ని. నాకు ఏదైనా చేయడానికి చాలా భయంగా ఉంది.",
              "or": "ମୁଁ ବହୁତ ଶ୍ୱାସରୁଦ୍ଧ ଅନୁଭବ କରୁଛି, ରୋଶନି | ମୁଁ କିଛି କରିବାକୁ ବହୁତ ଡରୁଛି |",
              "as": "ৰোশনি, মোৰ ইমান শ্বাসৰুদ্ধ অনুভৱ হৈছে। মই কিবা কৰিবলৈ বহুত ভয় কৰোঁ।",
              "gu": "હું ખૂબ ગૂંગળામણનો અનુભવ કરું છું, રોશની.  મને કંઈપણ કરવા માટે બહુ ડર લાગે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "You take your time. There is no rush to do anything. We are all with you.",
              "hi": "तुम अपना पूरा समय लो। कुछ भी करने के लिए कोई जल्दी नहीं है। हम सब तुम्हारे साथ हैं।",
              "ka": "ನೀವು ನಿಮ್ಮ ಸಮಯವನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ. ಏನನ್ನೂ ಮಾಡುವ ಆತುರವಿಲ್ಲ. ನಾವೆಲ್ಲರೂ ನಿಮ್ಮೊಂದಿಗೆ ಇದ್ದೇವೆ.",
              "te": "నువ్వు కావల్సినంత సమయం తీసుకో. ఏదో ఒకటి చేయాలనే తొందర ఏం లేదు. మేమంతా నీతో ఉన్నాం.",
              "or": "ତୁମେ ତୁମର ସମୟ ନିଅ | କିଛି କରିବାକୁ ତତ୍ପରତା ନାହିଁ | ଆମେ ସମସ୍ତେ ତୁମ ସହିତ ଅଛୁ |",
              "as": "আপুনি আপোনাৰ সময় লওক। একো কৰিবলৈ কোনো খৰধৰ কৰিব নালাগে। আমি সকলো আপোনাৰ সৈতে আছোঁ।",
              "gu": "તૂં તારી રીતે કામ કરી..  કંઈપણ કરવાની ઉતાવળ નથી.  અમે બધા તારી સાથે છીએ."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_3",
              "hi": "B1_3_3",
              "ka": "B1_3_3",
              "te": "B1_3_3",
              "or": "B1_3_3",
              "as": "B1_3_3",
              "gu": "B1_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Seeing Arvind in this state, Roshini decides to do something. She reaches out to her college community for help.",
              "hi": "अरविंद को इस स्थिति में  देखते हुए रोशनी ने कुछ करने का निश्चय किया। वह सहायता के लिए अपने कॉलेज समुदाय तक पहुंच जाती है।",
              "ka": "ಅರವಿಂದ್ ಅವರನ್ನು ಈ ಸ್ಥಿತಿಯಲ್ಲಿ ನೋಡಿದ ರೋಶಿನಿ ಏನಾದರೂ ಮಾಡಬೇಕೆಂದು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಅವಳು ಸಹಾಯಕ್ಕಾಗಿ ತನ್ನ ಕಾಲೇಜು ಸಮುದಾಯವನ್ನು ತಲುಪುತ್ತಾಳೆ.",
              "te": "అరవింద్‌ని ఈ స్థితిలో చూసిన రోష్ని ఏదో ఒకటి చేయాలని నిర్ణయించుకుంది. ఆమె సహాయం కోసం తన కళాశాల సంఘం దగ్గరకు వెళ్తుంది.",
              "or": "ଏହି ଅବସ୍ଥାରେ ଅରବିନ୍ଦଙ୍କୁ ଦେଖି ରୋଶିନି କିଛି କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି। ସାହାଯ୍ୟ ପାଇଁ ସେ ତାଙ୍କ କଲେଜ ସମ୍ପ୍ରଦାୟ ପାଖରେ ପହଞ୍ଚିଛନ୍ତି |",
              "as": "এই অৱস্থাত অৰবিন্দক দেখি ৰোশিনীয়ে কিবা এটা কৰাৰ সিদ্ধান্ত লয়। তাই সহায়ৰ বাবে তাইৰ কলেজৰ সম্প্ৰদায়ৰ ওচৰলৈ গৈছিল।",
              "gu": "અરવિંદને આ અવસ્થામાં જોઈને રોશિની કંઈક કરવાનું નક્કી કર્યું.  તેણી મદદ માટે તેણીના કોલેજ સમુદાય કમ્યુનિટી નો સંપર્ક કર્યો."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_4_msgblock",
              "hi": "B1_3_4_एमएसजीब्लॉक ",
              "ka": "B1_3_4_msgblock",
              "te": "B1_3_4_msgblock",
              "or": "B1_3_4_msgblock",
              "as": "B1_3_4_msgblock",
              "gu": "B1_3_4_msgblock"
            }
          },
          {
            "valuePath": "blocks.0.props.allMessages.0.messageText",
            "value": {
              "en": "Hello, I am looking for information about a counsellor or therapist who has experience talking to people from across the gender spectrum or people from the LGBTQ+ community. Someone I know has been facing harassment due to their gender identity.",
              "hi": "हैलो, मैं एक परामर्शदाता या चिकित्सक के बारे में जानकारी ढूंढ रही हूं, जिसके पास लिंग स्पेक्ट्रम के लोगों या एलजीबीटीक्यू + समुदाय के लोगों से बात करने का अनुभव है। मेरे किसी परिचित को उनकी लैंगिक पहचान के कारण उत्पीड़न का सामना करना पड़ रहा है।",
              "ka": "ಹಲೋ, ಲಿಂಗ ಸ್ಪೆಕ್ಟ್ರಮ್‌ನಾದ್ಯಂತ ಇರುವ ಜನರು ಅಥವಾ LGBTQ+ ಸಮುದಾಯದ ಜನರೊಂದಿಗೆ ಮಾತನಾಡುವ ಅನುಭವ ಹೊಂದಿರುವ ಸಲಹೆಗಾರರು ಅಥವಾ ಚಿಕಿತ್ಸಕರ ಕುರಿತು ನಾನು ಮಾಹಿತಿಯನ್ನು ಹುಡುಕುತ್ತಿದ್ದೇನೆ. ನನಗೆ ತಿಳಿದಿರುವ ಒಬ್ಬರು ತಮ್ಮ ಲಿಂಗ ಗುರುತಿನ ಕಾರಣದಿಂದ ಕಿರುಕುಳವನ್ನು ಎದುರಿಸುತ್ತಿದ್ದಾರೆ.",
              "te": "హలో, నేను లింగ స్పెక్ట్రమ్‌లోని వ్యక్తులతో లేదా LGBTQ+ కమ్యూనిటీకి చెందిన వ్యక్తులతో మాట్లాడిన అనుభవం ఉన్న కౌన్సెలర్ లేదా థెరపిస్ట్ గురించి సమాచారం కోసం వెతుకుతున్నాను. నాకు తెలిసిన ఒకరు వారి లింగ గుర్తింపు కారణంగా వేధింపులను ఎదుర్కొంటున్నారు.",
              "or": "ନମସ୍କାର, ମୁଁ ଜଣେ ପରାମର୍ଶଦାତା କିମ୍ବା ଥେରାପିଷ୍ଟ ବିଷୟରେ ସୂଚନା ଖୋଜୁଛି, ଯାହାର ଲିଙ୍ଗ ସ୍ପେକ୍ଟ୍ରମର କିମ୍ବା LGBTQ + ସମୁଦାୟର ଲୋକଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରିବାର ଅଭିଜ୍ଞତା ଅଛି | ମୁଁ ଜଣକୁ ଜାଣେ ସେମାନଙ୍କର ଲିଙ୍ଗ ପରିଚୟ ହେତୁ ହଇରାଣର ସମ୍ମୁଖୀନ ହେଉଛି |",
              "as": "নমস্কাৰ, মই এজন পৰামৰ্শদাতা বা থেৰাপিষ্টৰ বিষয়ে তথ্য বিচাৰি আছোঁ যাৰ লিংগ বৰ্ণালীৰ লোক বা LGBTQ+ সম্প্ৰদায়ৰ লোকসকলৰ সৈতে কথা পতাৰ অভিজ্ঞতা আছে। মোৰ চিনাকি কোনোবা এজনে তেওঁলোকৰ লিংগ পৰিচয়ৰ বাবে নিৰ্যাতনৰ সন্মুখীন হৈছে।",
              "gu": "નમસ્કાર, મને એવા કાઉન્સેલર અથવા ચિકિત્સક વિશે માહિતી જોઈએ છીએ જેને સમગ્ર જાતિના લોકો અથવા LGBTQ+ સમુદાયના લોકો સાથે વાત કરવાનો અનુભવ હોય.  હું એક એવા વ્યક્તિને જાણું છું કે પોતાના લિંગની ઓળખને કારણે ઉત્પીડનનો સામનો કરી રહી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.allMessages.1.messageText",
            "value": {
              "en": "Hi Roshni, so glad you reached out. We have been running an LGBTQ+ support group for some time now. Can they join us for a few sessions?",
              "hi": "हाय रोशनी, बहुत खुशी  की बात है कि आपने हमको बताया। हम कुछ समय के लिए एक एलजीबीटीक्यू + सपोर्ट ग्रुप चला रहे हैं। क्या वे कुछ सत्रों के लिए हमसे जुड़ सकते हैं?",
              "ka": "ಹಾಯ್ ರೋಶ್ನಿ, ನೀವು ತಲುಪಿದ್ದಕ್ಕೆ ತುಂಬಾ ಖುಷಿಯಾಗಿದೆ. ನಾವು ಸ್ವಲ್ಪ ಸಮಯದಿಂದ LGBTQ+ ಬೆಂಬಲ ಗುಂಪನ್ನು ನಡೆಸುತ್ತಿದ್ದೇವೆ. ಅವರು ಕೆಲವು ಸೆಷನ್‌ಗಳಿಗೆ ನಮ್ಮೊಂದಿಗೆ ಸೇರಬಹುದೇ?",
              "te": "హాయ్ రోష్ని, నువ్వు వచ్చినందుకు చాలా ఆనందంగా ఉంది. మేము కొంతకాలంగా LGBTQ+ సపోర్ట్ గ్రూప్ ని నడిపిస్తున్నాము. వారు కొన్ని సెషన్ల కోసం మాతో చేరగలరా?",
              "or": "ହାଏ ରୋଶନି, ତୁମେ ପହଂଚିଥିବାରୁ ବହୁତ ଖୁସି | ଆମେ କିଛି ସମୟ ହେଲା ଏକ LGBTQ + ସମର୍ଥନ ଗୋଷ୍ଠୀ ଚଳାଉଛୁ | ସେମାନେ କିଛି ଅଧିବେଶନ ପାଇଁ ଆମ ସହିତ ଯୋଗଦାନ କରିପାରିବେ କି?",
              "as": "হাই ৰোশনি, আপুনি উপস্থিত হৈ আনন্দিত কৰিলে। আমি কিছু সময়ৰ পৰা LGBTQ+ সমৰ্থন গোট এটা চলাই আছো। তেওঁলোকে কেইটামান অধিৱেশনৰ বাবে আমাৰ সৈতে যোগ দিব পাৰিবনে?",
              "gu": "હાય રોશની, ખૂબ જ આનંદ થયો કે તમે સંપર્ક કર્યો.  અમે છેલ્લા કેટલાક સમયથી LGBTQ+ સપોર્ટ ગ્રૂપ ચલાવી રહ્યા છીએ.  શું તે વ્યક્તિ થોડા સત્રો માટે અમારી સાથે જોડાઈ શકે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.allMessages.2.messageText",
            "value": {
              "en": "Hi Tarini, thanks. Can you send me the details of your support group? I will share the details with them and let you know.",
              "hi": "हाय तारिनी, धन्यवाद। क्या आप मुझे अपने समर्थन समूह का विवरण भेज सकते हैं? मैं उनके साथ विवरण साझा करूंगी और आपको बताऊंगी।",
              "ka": "ನಮಸ್ಕಾರ ತಾರಿಣಿ, ಧನ್ಯವಾದಗಳು. ನಿಮ್ಮ ಬೆಂಬಲ ಗುಂಪಿನ ವಿವರಗಳನ್ನು ನನಗೆ ಕಳುಹಿಸಬಹುದೇ? ನಾನು ಅವರೊಂದಿಗೆ ವಿವರಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತೇನೆ ಮತ್ತು ನಿಮಗೆ ತಿಳಿಸುತ್ತೇನೆ.",
              "te": "హాయ్ తారిణి, ధన్యవాదాలు. మీరు మీ సపోర్ట్ గ్రూప్ వివరాలను నాకు పంపగలరా? నేను వారికి వివరాలను పంపించి మీకు తెలియజేస్తాను.",
              "or": "ହାଏ ତାରିଣୀ, ଧନ୍ୟବାଦ | ତୁମେ ମୋତେ ତୁମର ସମର୍ଥନ ଗୋଷ୍ଠୀର ବିବରଣୀ ପଠାଇ ପାରିବ କି ? ମୁଁ ସେମାନଙ୍କ ସହିତ ସବିଶେଷ ତଥ୍ୟ ଅଂଶୀଦାର କରିବି ଏବଂ ତୁମକୁ ଜଣାଇବି |",
              "as": "হাই তাৰিণী, ধন্যবাদ। আপুনি আপোনাৰ সমৰ্থন গোটৰ বিৱৰণ মোলৈ প্ৰেৰণ কৰিব পাৰিবনে? মই তেওঁলোকৰ সৈতে বিৱৰণবোৰ আলোচনা কৰিম আৰু আপোনাক জনাম।",
              "gu": "હાય તારિણી, આભાર.  શું તમે મને તમારા સપોર્ટ ગ્રુપની વિગતો મોકલી શકો છો?  હું તેમની સાથે વિગતો શેર કરીશ અને તમને જણાવીશ."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_5",
              "hi": "B1_3_5",
              "ka": "B1_3_5",
              "te": "B1_3_5",
              "or": "B1_3_5",
              "as": "B1_3_5",
              "gu": "B1_3_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Roshni finds out that support groups are people from different backgrounds who support each other regarding their gender identity and sexual orientation.",
              "hi": "रोशनी को पता चलता है कि समर्थन समूह विभिन्न पृष्ठभूमि के लोग हैं जो एक दूसरे को उनके लिंग पहचान और यौन अभिविन्यास के संबंध में समर्थन करते हैं।",
              "ka": "ಬೆಂಬಲ ಗುಂಪುಗಳು ತಮ್ಮ ಲಿಂಗ ಗುರುತಿಸುವಿಕೆ ಮತ್ತು ಲೈಂಗಿಕ ದೃಷ್ಟಿಕೋನಕ್ಕೆ ಸಂಬಂಧಿಸಿದಂತೆ ಪರಸ್ಪರ ಬೆಂಬಲಿಸುವ ವಿಭಿನ್ನ ಹಿನ್ನೆಲೆಯ ಜನರು ಎಂದು ರೋಶ್ನಿ ಕಂಡುಕೊಂಡಿದ್ದಾರೆ.",
              "te": "వారి లింగ గుర్తింపు మరియు లైంగిక ధోరణికి సంబంధించి ఒకరికొకరు మద్దతు ఇచ్చే విభిన్న నేపథ్యాలకు చెందిన వ్యక్తులు సపోర్ట్ గ్రూప్‌లు అని రోష్ని తెలుసుకుంటాడు.",
              "or": "ରୋଶନି ଜାଣିବାକୁ ପାଇଲେ ଯେ ସମର୍ଥନ ଗୋଷ୍ଠୀ ହେଉଛି ବିଭିନ୍ନ ପୃଷ୍ଠଭୂମିରୁ ଆସିଥିବା ବ୍ୟକ୍ତି ଯେଉଁମାନେ ସେମାନଙ୍କର ଲିଙ୍ଗ ପରିଚୟ ଏବଂ ଯୌନ ଆଭିମୁଖ୍ୟ ସମ୍ବନ୍ଧରେ ପରସ୍ପରକୁ ସମର୍ଥନ କରନ୍ତି |",
              "as": "ৰোশনীয়ে গম পাইছে যে সমৰ্থন গোটবোৰ বিভিন্ন পৃষ্ঠভূমিৰ লোক যি তেওঁলোকৰ লিংগ পৰিচয় আৰু যৌন অভিমুখীতা সম্পৰ্কে ইজনে সিজনক সমৰ্থন কৰে।",
              "gu": "રોશનીને જાણવા મળ્યું કે સપોર્ટ ગ્રૂપ એ વિવિધ પૃષ્ઠભૂમિના લોકોનો સમૂહ છે જેમાં તેમની લિંગની ઓળખ અને જાતિ અંગે તેઓ એકબીજાની મદદ કરે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_6",
              "hi": "B1_3_6",
              "ka": "B1_3_6",
              "te": "B1_3_6",
              "or": "B1_3_6",
              "as": "B1_3_6",
              "gu": "B1_3_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She shares this with Arvind. Although he is initially reluctant, he is happy to hear that people in the group would understand him.",
              "hi": "वह इसे अरविंद के साथ साझा करती है। यद्यपि वह शुरुआत में अनिच्छुक है, लेकिन वह यह सुनकर खुश है कि समूह में लोग उसे समझेंगे।",
              "ka": "ಇದನ್ನು ಅರವಿಂದ್ ಜೊತೆ ಹಂಚಿಕೊಂಡಿದ್ದಾಳೆ. ಆರಂಭದಲ್ಲಿ ಹಿಂಜರಿಯುತ್ತಿದ್ದರೂ, ಗುಂಪಿನಲ್ಲಿರುವ ಜನರು ಅವನನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ ಎಂದು ಕೇಳಲು ಅವನು ಸಂತೋಷಪಡುತ್ತಾನೆ.",
              "te": "ఈ విషయాన్ని ఆమె అరవింద్‌ కి చెప్తుంది. మొదట్లో అయిష్టంగానే ఉన్నా, గ్రూప్ లోని వ్యక్తులు తనను అర్థం చేసుకుంటారని విని సంతోషించాడు.",
              "or": "ସେ ଅରବିନ୍ଦଙ୍କ ସହ ଏହା ଅଂଶୀଦାର କରନ୍ତି | ଯଦିଓ ସେ ପ୍ରଥମେ ଅନିଚ୍ଛା ପ୍ରକାଶ କରନ୍ତି, ସେ ଶୁଣି ଖୁସି ଯେ ଗୋଷ୍ଠୀର ଲୋକମାନେ ତାଙ୍କୁ ବୁଝିବେ |",
              "as": "তাই এইটো অৰবিন্দৰ সৈতে আলোচনা কৰে। যদিও তেওঁ প্ৰথমতে ইচ্ছা কৰা নাছিল, তেওঁ শুনি সুখী হয় যে গোটটোৰ লোকসকলে তেওঁক বুজি পাব।",
              "gu": "તે આ વાત અરવિંદને જણાવે છે.  જો કે તેને શરૂઆતમાં અનિચ્છા થઈ, પણ  તેને એ સાંભળીને ખુશી થઈ  કે જૂથના લોકો તેને સમજી શકશે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_3_7",
              "hi": "B1_3_7",
              "ka": "B1_3_7",
              "te": "B1_3_7",
              "or": "B1_3_7",
              "as": "B1_3_7",
              "gu": "B1_3_7"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After Roshni motivates him a little, he decides to try the support group.",
              "hi": "रोशनी द्वारा उसे थोड़ा प्रेरित करने के बाद, वह समर्थन समूह को आजमाने का फैसला करता है।",
              "ka": "ರೋಶ್ನಿ ಅವನನ್ನು ಸ್ವಲ್ಪ ಪ್ರೇರೇಪಿಸಿದ ನಂತರ, ಅವನು ಬೆಂಬಲ ಗುಂಪನ್ನು ಪ್ರಯತ್ನಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "రోష్ని అతనిని కొద్దిగా ప్రేరేపించిన తర్వాత, అతను సపోర్ట్ గ్రూప్‌ని ప్రయత్నించాలని నిర్ణయించుకున్నాడు.",
              "or": "ରୋଶନି ତାଙ୍କୁ ଟିକେ ଉତ୍ସାହିତ କରିବା ପରେ, ସେ ସପୋର୍ଟ ଗ୍ରୁପ ଚେଷ୍ଟା କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
              "as": "ৰোশনীয়ে তেওঁক অলপ অনুপ্ৰাণিত কৰাৰ পিছত, তেওঁ সমৰ্থন গোটটো চেষ্টা কৰাৰ সিদ্ধান্ত লয়।",
              "gu": "રોશનીએ તેને થોડો પ્રોત્સાહિત કર્યા પછી, તેણે સપોર્ટ ગ્રુપ અજમાવવાનું નક્કી કર્યું."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_4_1",
              "hi": "B1_4_1",
              "ka": "B1_4_1",
              "te": "B1_4_1",
              "or": "B1_4_1",
              "as": "B1_4_1",
              "gu": "B1_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The next day, Arvind attends the meeting at the youth centre in their town, and Arvind is late for the meeting.",
              "hi": "अगले दिन, अरविंद अपने शहर के युवा केंद्र की बैठक में भाग लेता है, और  अरविंद को बैठक के लिए  देर हो चुकी है।",
              "ka": "ಮರುದಿನ ಅರವಿಂದ್ ತಮ್ಮ ಊರಿನ ಯುವ ಕೇಂದ್ರದಲ್ಲಿ ಸಭೆಗೆ ಹಾಜರಾಗುತ್ತಾನೆ, ಮತ್ತು ಅರವಿಂದ್ ಸಭೆಗೆ ತಡವಾಗಿ ಬರುತ್ತಾನೆ.",
              "te": "మరుసటి రోజు, అరవింద్ తమ పట్టణంలోని యూత్ సెంటర్ లో మీటింగ్‌కు హాజరయ్యాడు, అరవింద్ సమావేశానికి ఆలస్యంగా వెళ్తాడు.",
              "or": "ପରଦିନ, ଅରବିନ୍ଦ ସେମାନଙ୍କ ସହରର ଯୁବ କେନ୍ଦ୍ରରେ ବୈଠକରେ ଯୋଗ ଦେଇଥିଲେ ଏବଂ ଅରବିନ୍ଦ ସଭା ପାଇଁ ବିଳମ୍ବ ହୋଇଥିଲେ।",
              "as": "পিছদিনা, অৰবিন্দে তেওঁলোকৰ চহৰৰ যুৱ কেন্দ্ৰৰ সভালৈ যায়, আৰু অৰবিন্দৰ সভালৈ পলম হয়।",
              "gu": "બીજા દિવસે, અરવિંદે  નગરના યુવા કેન્દ્રમાં મીટીંગમાં હાજરી આપી, અને અરવિંદ મીટીંગ માટે મોડો પહોંચ્યો."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_4_2",
              "hi": "B1_4_2",
              "ka": "B1_4_2",
              "te": "B1_4_2",
              "or": "B1_4_2",
              "as": "B1_4_2",
              "gu": "B1_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He peeks into the room and sees that people have already taken their seats. He quietly takes a seat at the back of the room.",
              "hi": "वह कमरे में झांकता है और देखता है कि लोग पहले ही अपना स्थान ग्रहण कर चुके हैं। वह चुपचाप कमरे में पीछे बैठ जाता है।",
              "ka": "ಅವನು ಕೋಣೆಯೊಳಗೆ ಇಣುಕಿ ನೋಡುತ್ತಾನೆ ಮತ್ತು ಜನರು ಈಗಾಗಲೇ ತಮ್ಮ ಸ್ಥಾನಗಳನ್ನು ತೆಗೆದುಕೊಂಡಿದ್ದಾರೆ. ಅವನು ಸದ್ದಿಲ್ಲದೆ ಕೋಣೆಯ ಹಿಂಭಾಗದಲ್ಲಿ ಆಸನವನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "అతను గదిలోకి చూస్తూ, అందరూ అప్పటికే తమ సీట్లను తీసుకున్నారని చూశాడు. అతను నిశ్శబ్దంగా గది వెనుక ఒక సీటు తీసుకుంటాడు.",
              "or": "ସେ ରୁମ୍ ଭିତରକୁ ଚାହିଁ ଦେଖନ୍ତି ଯେ ଲୋକମାନେ ନିଜ ଆସନ ନେଇସାରିଛନ୍ତି | ସେ ଚୁପଚାପ୍ ରୁମ୍ ପଛପଟେ ଏକ ଆସନ ନେଇଥାନ୍ତି |",
              "as": "তেওঁ কোঠাটোলৈ জুমি চাই দেখিলে যে মানুহে ইতিমধ্যে তেওঁলোকৰ আসন লৈছে। তেওঁ মনে মনে কোঠাটোৰ পিছফালে এখন আসন লয়।",
              "gu": "તેણે ઓરડામાં ડોકિયું કર્યું અને જોયું કે લોકો પહેલેથી જ પોતાની જગ્યા પર બેસી ગયા છે.  તે શાંતિથી રૂમની પાછળની બાજુએ બેસી ગયો."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_4_3//",
              "hi": "B1_4_3 //",
              "ka": "B1_4_3//",
              "te": "B1_4_3//",
              "or": "B1_4_3//",
              "as": "B1_4_3//",
              "gu": "B1_4_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Session Moderator",
              "hi": "सत्र मॉडरेटर",
              "ka": "ಸೆಷನ್ ಮಾಡರೇಟರ್",
              "te": "సెషన్ మోడరేటర్",
              "or": "ଅଧିବେଶନ ମୋଡରେଟର୍ |",
              "as": "অধিবেশন সঞ্চালক",
              "gu": "સત્ર મધ્યસ્થી"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Hello everyone, I’m so happy to see all of you again this week. I also see some new faces. My name is Vandana. My pronouns are ‘She/Her’. Could we do a quick round of introductions?",
              "hi": "सभी को नमस्कार, मैं इस सप्ताह फिर से आप सभी को देखकर बहुत खुश हूं। मैं कुछ नए चेहरे भी देखती हूं। मेरा नाम वंदना है। मेरे सर्वनाम 'वह / उसकी' हैं। क्या हम जल्दी - जल्दी एक दूसरे से परिचित हो सकते हैं?",
              "ka": "ಎಲ್ಲರಿಗೂ ನಮಸ್ಕಾರ, ಈ ವಾರ ನಿಮ್ಮೆಲ್ಲರನ್ನೂ ಮತ್ತೆ ನೋಡಲು ನನಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿದೆ. ನಾನು ಕೆಲವು ಹೊಸ ಮುಖಗಳನ್ನೂ ನೋಡುತ್ತೇನೆ. ನನ್ನ ಹೆಸರು ವಂದನಾ. ನನ್ನ ಸರ್ವನಾಮಗಳು ‘ಅವಳು/ಅವಳು’. ನಾವು ತ್ವರಿತ ಸುತ್ತಿನ ಪರಿಚಯಗಳನ್ನು ಮಾಡಬಹುದೇ?",
              "te": "అందరికీ నమస్కారం, ఈ వారం మీ అందరినీ మళ్లీ చూడడం నాకు చాలా సంతోషంగా ఉంది. నేను కొన్ని కొత్త ముఖాలను కూడా చూస్తున్నాను. నా పేరు వందన. నా సర్వనామాలు 'ఆమె/తను'. మనము త్వరగా ఒక రౌండ్ పరిచయాలు చేసుకుందామా?",
              "or": "ସମସ୍ତଙ୍କୁ ନମସ୍କାର, ଏହି ସପ୍ତାହରେ ପୁଣି ଆପଣ ସମସ୍ତଙ୍କୁ ଦେଖି ମୁଁ ବହୁତ ଖୁସି | ମୁଁ କିଛି ନୂଆ ଚେହେରା ମଧ୍ୟ ଦେଖୁଛି | ମୋର ନାମ ବନ୍ଦନା | ମୋର ସର୍ବନାମଗୁଡ଼ିକ ହେଉଛି ‘ସେ / ତା’ର’ | ଆମେ ଏକ ଶୀଘ୍ର ପରିଚୟ ପ୍ରଦାନ କରିପାରିବା କି ?",
              "as": "সকলোকে নমস্কাৰ জনাইছো, এই সপ্তাহত আপোনালোক সকলোকে পুনৰ দেখি মই বহুত সুখী। মই কিছুমান নতুন মুখো দেখিছো। মোৰ নাম বন্দনা। মোৰ সৰ্বনামবোৰ হৈছে 'তাই/তাইৰ'। আমি সকলোৱে সোনকালে পৰিচয়বোৰ দিব পাৰোনে?",
              "gu": "બધાને નમસ્કાર, આ અઠવાડિયે તમને બધાને ફરી જોઈને મને ખૂબ આનંદ થયો.  હું કેટલાક નવા ચહેરા પણ જોઈ રહી છું.  મારું નામ વંદના છે.  મારા સર્વનામ 'તેણી/તેણીનું' છે.  શું આપણે પરિચયનો ઝડપી રાઉન્ડ કરીશું?"
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_4_4",
              "hi": "B1_4_4",
              "ka": "B1_4_4",
              "te": "B1_4_4",
              "or": "B1_4_4",
              "as": "B1_4_4",
              "gu": "B1_4_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Everyone introduces themselves while stating their pronouns. Some say ‘He/Him’, some say ‘She/Her’, and others say ‘They/Them’. Arvind is confused.",
              "hi": "हर कोई अपने सर्वनाम बताते हुए खुद को प्रस्तुत करता है। कुछ कहते हैं 'वह / उसका', कुछ कहते हैं 'वह / उसकी', और अन्य कहते हैं 'वे / वे'। अरविंद को  समझ नहीं आ रहा है।",
              "ka": "ಪ್ರತಿಯೊಬ್ಬರೂ ತಮ್ಮ ಸರ್ವನಾಮಗಳನ್ನು ಹೇಳುವಾಗ ತಮ್ಮನ್ನು ಪರಿಚಯಿಸಿಕೊಳ್ಳುತ್ತಾರೆ. ಕೆಲವರು ‘ಅವನು/ಅವನು’, ಕೆಲವರು ‘ಅವಳು/ಅವಳು’, ಇನ್ನು ಕೆಲವರು ‘ಅವರು/ಅವರು’ ಎನ್ನುತ್ತಾರೆ. ಅರವಿಂದ್ ಗೊಂದಲದಲ್ಲಿದ್ದಾನೆ.",
              "te": "ప్రతి ఒక్కరూ తమ సర్వనామాలను చెప్పుకుంటూ తమను తాము పరిచయం చేసుకుంటారు. కొందరు ‘అతడు/తను’ అని, కొందరు ‘ఆమె/తను’ అని, మరికొందరు ‘వారు/వారు’ అని అంటారు. అరవింద్ కంగారు పడ్డాడు.",
              "or": "ସମସ୍ତେ ସେମାନଙ୍କର ସର୍ବନାମ କହିବା ନିଜକୁ ପରିଚିତ କରାନ୍ତି | କେହି ‘ସେ/ତାଙ୍କୁ’ କୁହନ୍ତି, କେହି ‘ସେ/ସେ’ କୁହନ୍ତି, ଆଉ କେହି କେହି ‘ସେମାନେ/ସେମାନଙ୍କୁ’ କୁହନ୍ତି | ଅରବିନ୍ଦ ଦ୍ୱନ୍ଦ୍ୱରେ ଅଛନ୍ତି |",
              "as": "সকলোৱে তেওঁলোকৰ সৰ্বনাম উল্লেখ কৰোঁতে নিজৰ পৰিচয় দিয়ে। কিছুমানে কয় 'তেওঁ/তেওঁক', কিছুমানে কয় 'তাই/তাইক', আৰু আন কিছুমানে কয় 'তেওঁলোক/তেওঁলোকক'। অৰবিন্দ বিভ্ৰান্ত হৈছে।",
              "gu": "દરેક વ્યક્તિ પોત પોતાનું સર્વનામ કહેતી વખતે પોતાનો પરિચય આપે છે.  કેટલાક કહે છે 'તે/તેમ', કેટલાક કહે છે 'તેણી/તેણીનાં', અને અન્ય કહે છે 'તે/તેમનાં'.  અરવિંદ મૂંઝાઈ ગયો."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_4_5////",
              "hi": "B1_4_5 ////////",
              "ka": "B1_4_5////",
              "te": "B1_4_5////",
              "or": "B1_4_5////",
              "as": "B1_4_5////",
              "gu": "B1_4_5////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Finally, it is Arvind's turn. He stands up nervously.",
              "hi": "अंत में, यह अरविंद की बारी है। वह घबराहट से खड़ा है।",
              "ka": "ಕೊನೆಗೂ ಅರವಿಂದ್ ಸರದಿ. ಅವನು ಉದ್ವಿಗ್ನನಾಗಿ ಎದ್ದು ನಿಲ್ಲುತ್ತಾನೆ.",
              "te": "చివరకు అరవింద్ వంతు వచ్చింది. అతను భయంగా లేచి నిలబడతాడు.",
              "or": "ଶେଷରେ, ଅରବିନ୍ଦଙ୍କ ପର୍ଯ୍ୟାୟ ଆସିଥାଏ | ସେ ଉତ୍କଣ୍ଠିତ ଭାବରେ ଠିଆ ହୁଅନ୍ତି |",
              "as": "অৱশেষত, অৰবিন্দৰ পাল। তেওঁ উদ্বিগ্ন হৈ থিয় হ'ল।",
              "gu": "આખરે અરવિંદનો વારો આવ્યો.  તે ગભરાઈને ઉભો થાયો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "My name is Arvind. My pronouns are… 'he/him'... I think.",
              "hi": "मेरा नाम अरविंद है। मेरे सर्वनाम हैं ... 'वह / उसे' ... मुझे लगता है।",
              "ka": "ನನ್ನ ಹೆಸರು ಅರವಿಂದ್. ನನ್ನ ಸರ್ವನಾಮಗಳು... 'ಅವನು/ಅವನು'... ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "నా పేరు అరవింద్. నా సర్వనామాలు... 'అతడు/తను'... నేను అనుకుంటున్నాను.",
              "or": "ମୋର ନାମ ଅରବିନ୍ଦ | ମୋର ଉଚ୍ଚାରଣଗୁଡ଼ିକ ହେଉଛି… 'ସେ/ତାଙ୍କୁ' ... ମୁଁ ଭାବୁଛି |",
              "as": "মোৰ নাম অৰবিন্দ। মোৰ সৰ্বনামবোৰ হৈছে... 'তেওঁ/তেওঁৰ'... মই ভাবোঁ।",
              "gu": "મારું નામ અરવિંદ છે.  મારા સર્વનામ છે... 'તે/તેમનાં'... મને લાગે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Thank you, Arvind. So, who would like to share first?",
              "hi": "धन्यवाद, अरविंद। तो, पहले कौन साझा करना चाहेगा?",
              "ka": "ಧನ್ಯವಾದಗಳು, ಅರವಿಂದ್. ಹಾಗಾದರೆ, ಯಾರು ಮೊದಲು ಹಂಚಿಕೊಳ್ಳಲು ಬಯಸುತ್ತಾರೆ?",
              "te": "ధన్యవాదాలు, అరవింద్. మరి, ఎవరు ముందుగా మాట్లాడాలనుకుంటున్నారు?",
              "or": "ଧନ୍ୟବାଦ, ଅରବିନ୍ଦ | ତେବେ, କିଏ ପ୍ରଥମେ ଅଂଶୀଦାର କରିବାକୁ ଚାହିଁବ?",
              "as": "ধন্যবাদ, অৰবিন্দ। গতিকে, কোনে প্ৰথমে আলোচনা কৰিব বিচাৰে?",
              "gu": "આભાર, અરવિંદ.  તો, કોણ પ્રથમ કહેવા માંગે છે?"
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_4_6",
              "hi": "B1_4_6",
              "ka": "B1_4_6",
              "te": "B1_4_6",
              "or": "B1_4_6",
              "as": "B1_4_6",
              "gu": "B1_4_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "In the next one hour, people take turns to talk about what they have been struggling with lately.",
              "hi": "अगले एक घंटे में, लोग बारी-बारी से बात करते हैं कि वे हाल ही में किन परिस्थितियों से जूझ रहे हैं।",
              "ka": "ಮುಂದಿನ ಒಂದು ಗಂಟೆಯಲ್ಲಿ, ಜನರು ಇತ್ತೀಚೆಗೆ ಅವರು ಏನು ಕಷ್ಟಪಡುತ್ತಿದ್ದಾರೆ ಎಂಬುದರ ಕುರಿತು ಮಾತನಾಡಲು ಸರದಿ ತೆಗೆದುಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "తర్వాత ఒక గంట పాటు, అందరూ ఇంతకాలం కష్టపడుతున్న దాని గురించి మాట్లాడతారు.",
              "or": "ପରବର୍ତ୍ତୀ ଏକ ଘଣ୍ଟା ମଧ୍ୟରେ, ଲୋକମାନେ ନିକଟ ଅତୀତରେ କ’ଣ ସଂଘର୍ଷ କରୁଛନ୍ତି ସେ ବିଷୟରେ ଆଲୋଚନା କରିବାକୁ ଘେରିଥାନ୍ତି |",
              "as": "পাছৰ এক ঘণ্টাত, মানুহে শেহতীয়াকৈ কিহৰ সৈতে যুঁজি আছে সেই বিষয়ে তেওঁলোকে পাল পাতি পাতি কথা পাতে।",
              "gu": "આગલા એક કલાક, લોકો વારાફરતી વાત કરે છે કે તેઓ તાજેતરમાં કઈ પરેશાનીનો સામનો કરી રહ્યા છે."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_4_7",
              "hi": "B1_4_7",
              "ka": "B1_4_7",
              "te": "B1_4_7",
              "or": "B1_4_7",
              "as": "B1_4_7",
              "gu": "B1_4_7"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Everyone in the group is dealing with issues regarding how they are treated or how they felt about their gender and sexuality.",
              "hi": "समूह में हर कोई इस समस्या का सामना कर रहा है कि उनके साथ कैसा व्यवहार किया जाता है या वे अपने लिंग और सैक्सुएलिटी के बारे में कैसा अनुभव करते हैं।",
              "ka": "ಗುಂಪಿನಲ್ಲಿರುವ ಪ್ರತಿಯೊಬ್ಬರೂ ಅವರನ್ನು ಹೇಗೆ ಪರಿಗಣಿಸಲಾಗುತ್ತದೆ ಅಥವಾ ಅವರ ಲಿಂಗ ಮತ್ತು ಲೈಂಗಿಕತೆಯ ಬಗ್ಗೆ ಅವರು ಹೇಗೆ ಭಾವಿಸುತ್ತಾರೆ ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುತ್ತಿದ್ದಾರೆ ಎಂಬುದರ ಕುರಿತು ಹೇಳುತ್ತಾರೆ.",
              "te": "గ్రూప్ లోని ప్రతి ఒక్కరూ వారితో ఎలా ప్రవర్తిస్తారు లేదా వారి లింగం మరియు లైంగికత గురించి వారు ఎలా భావించారు అనే విషయాల గురించి మాట్లాడుతున్నారు.",
              "or": "ଗୋଷ୍ଠୀର ସମସ୍ତେ ସେମାନଙ୍କ ସହିତ କିପରି ବ୍ୟବହାର କରାଯାଏ କିମ୍ବା ସେମାନଙ୍କର ଲିଙ୍ଗ ଏବଂ ଯୌନ ସମ୍ପର୍କ ବିଷୟରେ ସେମାନେ କିପରି ଅନୁଭବ କଲେ ସେ ସମ୍ବନ୍ଧରେ ପଦକ୍ଷେପ ନଉଛନ୍ତି |",
              "as": "গোটটোৰ সকলোৱে তেওঁলোকৰ সৈতে কেনে ব্যৱহাৰ কৰা হয় বা তেওঁলোকে লিংগ আৰু যৌনতাৰ বিষয়ে কেনে অনুভৱ কৰে সেই বিষয়বোৰৰ সৈতে মোকাবিলা কৰি আছে।",
              "gu": "જૂથમાં દરેક વ્યક્તિ તેમની સાથે લોકો કેવી રીતે વર્તે છે અથવા તેઓ તેમના લિંગ અને જાતિયતા વિશે કેવું અનુભવે છે આ  સંબંધિત મુદ્દાઓ નો સામનો કરી રહ્યા હતા."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_4_8///",
              "hi": "B1_4_8 ///",
              "ka": "B1_4_8///",
              "te": "B1_4_8///",
              "or": "B1_4_8///",
              "as": "B1_4_8///",
              "gu": "B1_4_8///"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "No one prepared me for these types of experiences. I feel very alone and confused.",
              "hi": "इस तरह के अनुभवों के लिए किसी ने मुझे तैयार नहीं किया। मैं बहुत अकेला और उलझन में हूं।",
              "ka": "ಈ ರೀತಿಯ ಅನುಭವಗಳಿಗೆ ಯಾರೂ ನನ್ನನ್ನು ಸಿದ್ಧಪಡಿಸಲಿಲ್ಲ. ನಾನು ತುಂಬಾ ಒಂಟಿಯಾಗಿ ಮತ್ತು ಗೊಂದಲಕ್ಕೊಳಗಾಗಿದ್ದೇನೆ.",
              "te": "ఇలాంటి అనుభవాలకు ఎవరూ నన్ను సిద్ధం చేయలేదు. నేను చాలా ఒంటరిగా మరియు అయోమయంగా ఉన్నాను.",
              "or": "ଏହି ପ୍ରକାରର ଅନୁଭୂତି ପାଇଁ ମୋତେ କେହି ପ୍ରସ୍ତୁତ କରିନାହାଁନ୍ତି | ମୁଁ ବହୁତ ଏକୁଟିଆ ଏବଂ ଦ୍ୱନ୍ଦ୍ୱ ଅନୁଭବ କରୁଛି |",
              "as": "এই ধৰণৰ অভিজ্ঞতাৰ বাবে মোক কোনেও প্ৰস্তুত কৰা নাছিল। মই বহুত অকলশৰীয়া আৰু বিভ্ৰান্ত অনুভৱ কৰোঁ।",
              "gu": "કોઈએ મને આ પ્રકારના અનુભવો માટે તૈયાર કર્યો નથી.  હું ખૂબ જ એકલો પડી ગયો છું અને મૂંઝવણનો અનુભવું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I left my workplace after facing constant harassment from my coworkers. I don’t know what to do now because I liked the work I was doing and can’t imagine myself doing something else.",
              "hi": "अपने सहकर्मियों से निरंतर उत्पीड़न का सामना करने के बाद मैंने अपना कार्यस्थल छोड़ा। मुझे नहीं पता कि अब क्या करना है क्योंकि मुझे वह काम पसंद था, जो मैं कर रहा था और मैं कल्पना नहीं कर सकता कि अब कुछ और करूं।",
              "ka": "ನನ್ನ ಸಹೋದ್ಯೋಗಿಗಳಿಂದ ನಿರಂತರ ಕಿರುಕುಳವನ್ನು ಎದುರಿಸಿದ ನಂತರ ನಾನು ನನ್ನ ಕೆಲಸದ ಸ್ಥಳವನ್ನು ತೊರೆದಿದ್ದೇನೆ. ಈಗ ಏನು ಮಾಡಬೇಕೆಂದು ನನಗೆ ತಿಳಿದಿಲ್ಲ ಏಕೆಂದರೆ ನಾನು ಮಾಡುತ್ತಿದ್ದ ಕೆಲಸವನ್ನು ನಾನು ಇಷ್ಟಪಟ್ಟೆ ಮತ್ತು ನಾನು ಬೇರೆ ಏನಾದರೂ ಮಾಡುತ್ತಿದ್ದೇನೆ ಎಂದು ಊಹಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ.",
              "te": "నా సహోద్యోగుల నుండి నిరంతరం వేధింపులు ఎదుర్కొన్న తర్వాత నేను నా కార్యాలయాన్ని విడిచిపెట్టాను. నేను చేస్తున్న పని నాకు నచ్చినందున ఇప్పుడు ఏమి చేయాలో నాకు తెలియదు మరియు వేరే పనిని చేయలేను.",
              "or": "ମୋର ସହକର୍ମୀମାନଙ୍କ ଦ୍ୱାରା ନିରନ୍ତର ହଇରାଣର ସମ୍ମୁଖୀନ ହେବା ପରେ ମୁଁ ମୋର କର୍ମକ୍ଷେତ୍ର ଛାଡିଥିଲି | ମୁଁ ବର୍ତ୍ତମାନ କ’ଣ କରିବି ଜାଣେ ନାହିଁ କାରଣ ମୁଁ କରୁଥିବା କାର୍ଯ୍ୟକୁ ମୁଁ ପସନ୍ଦ କରୁଥିଲି ଏବଂ ମୁଁ ନିଜକୁ ଅନ୍ୟ କିଛି କରିବା କଳ୍ପନା କରିପାରିବି ନାହିଁ |",
              "as": "মই মোৰ সহকৰ্মীসকলৰ পৰা নিৰন্তৰ হাৰাশাস্তিৰ সন্মুখীন হোৱাৰ পিছত মোৰ কৰ্মস্থান এৰিছিলো। মই এতিয়া কি কৰিব লাগে নাজানো কাৰণ মই কৰি থকা কামটো ভাল পাইছিলো আৰু নিজকে আন কিবা কৰাৰ কল্পনা কৰিব নোৱাৰো।",
              "gu": "મારા સહકર્મચારીઓ તરફથી સતત ઉત્પીડનનો સામનો કર્યા પછી મેં મારું કામ છોડી દીધું.  મને ખબર નથી કે હવે શું કરવું જોઈએ કારણ કે હું જે કામ કરી રહ્યો હતો તે મને ગમતું હતું અને બીજું કંઈક કરવાની કલ્પના હું કરી શકતો નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I identify as a woman and want to undergo gender affirmation surgery. However, I don’t know how to tell this to my parents.",
              "hi": "मैं एक महिला के रूप में पहचान रखता हूं और लिंग अभिपुष्टि सर्जरी से गुजरना चाहता हूं। हालाँकि, मुझे नहीं पता कि मैं अपने माता-पिता को यह कैसे बताऊँ।",
              "ka": "ನಾನು ಮಹಿಳೆ ಎಂದು ಗುರುತಿಸಿಕೊಂಡಿದ್ದೇನೆ ಮತ್ತು ಲಿಂಗ ದೃಢೀಕರಣ ಶಸ್ತ್ರಚಿಕಿತ್ಸೆಗೆ ಒಳಗಾಗಲು ಬಯಸುತ್ತೇನೆ. ಆದಾಗ್ಯೂ, ಇದನ್ನು ನನ್ನ ಪೋಷಕರಿಗೆ ಹೇಗೆ ಹೇಳಬೇಕೆಂದು ನನಗೆ ತಿಳಿದಿಲ್ಲ.",
              "te": "నేను స్త్రీ అని గుర్తించిన తర్వాత లింగ నిర్ధారణ శస్త్రచికిత్స చేయించుకోవాలనుకుంటున్నాను. అయితే, ఈ విషయాన్ని నా తల్లిదండ్రులకు ఎలా చెప్పాలో తెలియడం లేదు.",
              "or": "ମୁଁ ଜଣେ ମହିଳା ଭାବରେ ପରିଚିତ ଏବଂ ଲିଙ୍ଗ ନିଶ୍ଚିତକରଣ ଅସ୍ତ୍ରୋପଚାର କରିବାକୁ ଚାହୁଁଛି | ଅଥଚ, ମୁଁ ମୋର ପିତାମାତାଙ୍କୁ ଏହା କିପରି କହିବି ଜାଣେ ନାହିଁ |",
              "as": "মই এগৰাকী মহিলা হিচাপে পৰিচয় দিওঁ আৰু লিংগ প্ৰতিশ্ৰুতি অস্ত্ৰোপচাৰ কৰিব বিচাৰো। অৱশ্যে, মই নাজানো মোৰ মা-দেউতাক এইটো কেনেকৈ ক'ব লাগে।",
              "gu": "હું પોતાને એક મહિલા તરીકે ઓળખું છું અને લિંગ સમર્થનની સર્જરી કરાવવા માંગુ છું.  જો કે, મને ખબર નથી કે મારા માતા-પિતાને આ કેવી રીતે કહેવું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "My friend recently shared with me that they don’t identify with the gender they were assigned at birth. While I’m happy that they shared this with me, I’m not quite sure how to support them better.",
              "hi": "मेरे मित्र ने हाल ही में मेरे साथ साझा किया कि वे उस लिंग को नहीं पहचानते हैं जो उनमें जन्म के समय से था। जबकि मुझे खुशी है कि उन्होंने इसे मेरे साथ साझा किया, मुझे पूरा विश्वास नहीं है कि उनका समर्थन और अच्छे से कैसे किया जाए।",
              "ka": "ನನ್ನ ಸ್ನೇಹಿತ ಇತ್ತೀಚೆಗೆ ನನ್ನೊಂದಿಗೆ ಹಂಚಿಕೊಂಡಿದ್ದಾರೆ, ಅವರು ಹುಟ್ಟಿನಿಂದಲೇ ನಿಯೋಜಿಸಲಾದ ಲಿಂಗದೊಂದಿಗೆ ಅವರು ಗುರುತಿಸುವುದಿಲ್ಲ. ಅವರು ಇದನ್ನು ನನ್ನೊಂದಿಗೆ ಹಂಚಿಕೊಂಡಿದ್ದಕ್ಕಾಗಿ ನನಗೆ ಸಂತೋಷವಾಗಿದ್ದರೂ, ಅವರನ್ನು ಉತ್ತಮವಾಗಿ ಬೆಂಬಲಿಸುವುದು ಹೇಗೆ ಎಂದು ನನಗೆ ಖಚಿತವಿಲ್ಲ.",
              "te": "వారు పుట్టినప్పుడు కేటాయించిన లింగంతో వారు గుర్తించబడరని నా స్నేహితుడు ఇటీవల నాతో చెప్పాడు. వారు దీన్ని నాతో చెప్పినందుకు నేను సంతోషంగా ఉన్నాను, వారికి ఎలా మద్దతు ఇవ్వాలో నాకు తెలియట్లేదు.",
              "or": "ମୋର ବନ୍ଧୁ ସମ୍ପ୍ରତି ମୋ ସହିତ ଅଂଶୀଦାର କରିଛନ୍ତି ଯେ ସେ ଜନ୍ମ ସମୟରେ ନ୍ଯସ୍ଥ ଲିଙ୍ଗ ସହିତ ପରିଚିତ ନୁହଁନ୍ତି | ମୁଁ ଖୁସି ଯେ ସେମାନେ ଏହା ମୋ ସହିତ ଅଂଶୀଦାର କରିଛନ୍ତି, ହେଲେମଦ୍ଧ୍ୟ ମୁଁ ସେମାନଙ୍କୁ ନିଶ୍ଚିତ ଭାବରେ କିପରି ଭଲ ଭାବରେ ସମର୍ଥନ କରିବି ତାହା ନିଶ୍ଚିତ ନୁହେଁ |",
              "as": "মোৰ বন্ধুৱে অলপতে মোৰ সৈতে আলোচনা কৰিছিল যে তেওঁলোকে জন্মৰ সময়ত তেওঁলোকক আৱণ্টন কৰা লিংগৰ সৈতে পৰিচয় অনুভৱ নকৰে। যদিও মই সুখী যে তেওঁলোকে এইটো মোৰ সৈতে আলোচনা কৰিছিল, মই নিশ্চিত নহয় যে তেওঁলোকক কেনেদৰে ভালদৰে সমৰ্থন কৰিব লাগে।",
              "gu": "મારા મિત્રએ તાજેતરમાં મારી સાથે શેર કર્યું છે કે તેઓ જન્મ સમયે તેમને જે લિંગ તેની સાથે જોડવામાં આવ્યું તેની સાથે પોતાને ઓળખતા નથી.  હું ખુશ છું કે તેઓએ મારી સાથે આ શેર કર્યું છે, પણ મને નથી સમજાતું હું તેમની  કેવી રીતે મદદ કરી શકું ."
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_4_9",
              "hi": "B1_4_9",
              "ka": "B1_4_9",
              "te": "B1_4_9",
              "or": "B1_4_9",
              "as": "B1_4_9",
              "gu": "B1_4_9"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is surprised. There are so many more people like him.",
              "hi": "अरविंद हैरान है। उसके जैसे बहुत सारे लोग हैं।",
              "ka": "ಅರವಿಂದನಿಗೆ ಆಶ್ಚರ್ಯವಾಯಿತು. ಅವನಂತೆ ಇನ್ನೂ ಎಷ್ಟೋ ಜನ ಇದ್ದಾರೆ.",
              "te": "అరవింద్ ఆశ్చర్యపోయాడు. అతని లాంటి వారు ఇంకా చాలా మంది ఉన్నారు.",
              "or": "ଅରବିନ୍ଦ ଆଶ୍ଚର୍ଯ୍ୟ ହୋଇଗଲେ | ତାଙ୍କ ପରି ଆହୁରି ଅନେକ ଲୋକ ଅଛନ୍ତି |",
              "as": "অৰবিন্দ আচৰিত হৈছে। তেওঁৰ দৰে আৰু বহুতো মানুহ আছে।",
              "gu": "અરવિંદને નવાઈ લાગી.  એ જાણીને કે તેના જેવા બીજા ઘણા લોકો છે."
            }
          }
        ],
        "page21": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_5_1//",
              "hi": "B1_5_1 //",
              "ka": "B1_5_1//",
              "te": "B1_5_1//",
              "or": "B1_5_1//",
              "as": "B1_5_1//",
              "gu": "B1_5_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "While he is thinking about this, Vandana turns to him and asks...",
              "hi": "जब वह इस बारे में सोच रहा है, वंदना उसे अपनी ओर घुमाती है और पूछती है ...",
              "ka": "ಅವನು ಈ ಬಗ್ಗೆ ಯೋಚಿಸುತ್ತಿರುವಾಗ, ವಂದನಾ ಅವನತ್ತ ತಿರುಗಿ ಕೇಳುತ್ತಾಳೆ ...",
              "te": "అతను ఇలా ఆలోచిస్తుండగా వందన అతని వైపు తిరిగి ఇలా అడుగుతుంది...",
              "or": "ସେ ଏ ବିଷୟରେ ଚିନ୍ତା କରୁଥିବା ବେଳେ, ଭଣ୍ଡନା ତାଙ୍କ ଆଡକୁ ବୁଲି ପଚାରନ୍ତି ...",
              "as": "যেতিয়া তেওঁ এই বিষয়ে চিন্তা কৰি আছে, বন্দনাই তেওঁৰ ফালে ঘূৰি সুধিলে...",
              "gu": "જ્યારે તે આ વિશે વિચારી રહ્યો હતો, ત્યારે વંદના તેની તરફ ફરીને પૂછે છે..."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Vandana",
              "hi": "वंदना",
              "ka": "ವಂದನಾ",
              "te": "వందన",
              "or": "ଭଣ୍ଡନା",
              "as": "বন্দনা",
              "gu": "વંદના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Vandana",
              "hi": "वंदना",
              "ka": "ವಂದನಾ",
              "te": "వందన",
              "or": "ଭଣ୍ଡନା",
              "as": "বন্দনা",
              "gu": "વંદના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Arvind, Would you like to share?",
              "hi": "अरविंद, क्या तुम साझा करना चाहते हो?",
              "ka": "ಅರವಿಂದ್, ನೀವು ಹಂಚಿಕೊಳ್ಳಲು ಬಯಸುವಿರಾ?",
              "te": "అరవింద్, నువ్వు అయినా చెప్పాలనుకుంటున్నావా?",
              "or": "ଅରବିନ୍ଦ, ଆପଣ ଅଂଶୀଦାର କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
              "as": "অৰবিন্দ, আপুনি আপোনাৰ বিষয়ে ক'ব বিচাৰে নেকি?",
              "gu": "અરવિંદ, શું તૂં કશુ શેર કરવા માંગે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "…",
              "hi": "...",
              "ka": "…",
              "te": "…",
              "or": "…",
              "as": "...",
              "gu": "…"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "It's okay, you can take your time.",
              "hi": "कोई बात नहीं, तुम अपना समय ले सकते हो।",
              "ka": "ಪರವಾಗಿಲ್ಲ, ನೀವು ನಿಮ್ಮ ಸಮಯವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು.",
              "te": "ఫర్వాలేదు, మీకు కావల్సినంత సమయం తీసుకోండి.",
              "or": "ଆପଣ ଆପଣଙ୍କର ସମୟ ନେଇପାରିବେ, କିଛି ଅଶୁବିଧା ନାହିଁ |",
              "as": "ঠিক আছে, আপুনি আপোনাৰ সময় ল'ব পাৰে।",
              "gu": "ઠીક છે, તૂં વિચાર કરી ને જવાબ આપી શકે છે."
            }
          }
        ],
        "page22": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_5_2////",
              "hi": "B1_5_2 //////////////",
              "ka": "B1_5_2////",
              "te": "B1_5_2////",
              "or": "B1_5_2////",
              "as": "B1_5_2////",
              "gu": "B1_5_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I stayed in a hostel until very recently, but living with other boys in the boy’s hostel made me uncomfortable. I was born a boy, but I have always felt more comfortable thinking of myself as a girl.",
              "hi": "मैं हाल ही में एक छात्रावास में रहा, लेकिन लड़कों के छात्रावास में अन्य लड़कों के साथ रहना मुझे असहज लगा। मेरा एक लड़के के रूप में जन्म हुआ था, लेकिन मैंने हमेशा एक लड़की के रूप में स्वयं को अधिक सहज अनुभव किया है।",
              "ka": "ನಾನು ತೀರಾ ಇತ್ತೀಚಿನವರೆಗೂ ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿಯೇ ಇದ್ದೆ, ಆದರೆ ಹುಡುಗನ ಹಾಸ್ಟೆಲ್‌ನಲ್ಲಿ ಇತರ ಹುಡುಗರೊಂದಿಗೆ ವಾಸಿಸುವುದು ನನಗೆ ಅಹಿತಕರವಾಗಿತ್ತು. ನಾನು ಹುಡುಗನಾಗಿ ಜನಿಸಿದೆ, ಆದರೆ ನಾನು ಯಾವಾಗಲೂ ನನ್ನನ್ನು ಹುಡುಗಿ ಎಂದು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "నేను ఇటీవలి వరకు హాస్టల్‌లో ఉన్నాను, కాని అబ్బాయిల హాస్టల్‌లో ఇతర అబ్బాయిలతో నివసించడం నాకు అసౌకర్యంగా ఉంది. నేను అబ్బాయిగా పుట్టాను, కానీ నేను ఎప్పుడూ ఒక అమ్మాయిగా భావించడం సౌకర్యంగా ఉంది.",
              "or": "ମୁଁ ବର୍ତ୍ତମାନ ପର୍ଯ୍ୟନ୍ତ ଏକ ହଷ୍ଟେଲରେ ରହିଥିଲି, କିନ୍ତୁ ବାଳକ ହଷ୍ଟେଲରେ ଅନ୍ୟ ପୁଅମାନଙ୍କ ସହିତ ରହିବା ମୋତେ ଅସହଜ କଲା | ମୁଁ ଏକ ବାଳକ ଭାବରେ ଜନ୍ମ ହୋଇଥିଲି, କିନ୍ତୁ ମୁଁ ନିଜକୁ ଝିଅ ବୋଲି ଭାବିବାରେ ସର୍ବଦା ଅଧିକ ଆରାମଦାୟକ ଅନୁଭବ କରେ |",
              "as": "মই অলপতে যেতিয়ালৈকে এটা হোষ্টেলত আছিলোঁ, কিন্তু ল'ৰা হোষ্টেলত আন ল'ৰাবোৰৰ সৈতে থকাটোৱে মোক অস্বস্তিত পেলাইছিল। মই এজন ল'ৰা হিচাপে জন্মগ্ৰহণ কৰিছিলোঁ, কিন্তু মই সদায়েই নিজকে ছোৱালী বুলি ভাবি অধিক আৰামদায়ক অনুভৱ কৰি আহিছো।",
              "gu": "હું હમણાં સુધી હોસ્ટેલમાં રહ્યો, પરંતુ છોકરાઓની હોસ્ટેલમાં અન્ય છોકરાઓ સાથે રહેવાનું મને ગમતું ન હતું.   હું એક છોકરો જન્મ્યો હતો, પરંતુ મને હંમેશા મારી જાતને એક છોકરી તરીકે વિચારવું  વધુ સારું લાગતું હતું ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Go on ...",
              "hi": "जारी रखें ...",
              "ka": "ಮುಂದೆ ಹೇಳಿ ...",
              "te": "కొనసాగించు ...",
              "or": "ଜାରି ରଖନ୍ତୁ …",
              "as": "কৈ থাকা...",
              "gu": "આગળ કહે..."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I feel like I have been given the wrong body. I don’t look entirely like a boy, but neither do I look like a girl. In my head, I’d rather live like a woman. I like wearing sister’s clothes. I wish people wouldn’t pick on me so much. It’s not easy to understand what you are feeling when all you think about is who will hurt me next.",
              "hi": "मुझे लगता है कि मुझे गलत शरीर दिया गया है। मैं पूरी तरह से एक लड़के की तरह नहीं दिखता, लेकिन न ही मैं एक लड़की की तरह दिखता हूं। अपने दिमाग में, मैं एक महिला की तरह रहना पसंद करूंगा। मुझे बहन के कपड़े पहनना अच्छा लगता है। काश लोग मुझे इतना पसंद नहीं करते। यह समझना आसान नहीं है कि आप क्या अनुभव कर रहे हैं जब आप  सिर्फ यही सोचते हैं कि अब न जाने कौन मुझे चोट पहुंचाएगा। ",
              "ka": "ನನಗೆ ತಪ್ಪು ದೇಹವನ್ನು ನೀಡಲಾಗಿದೆ ಎಂದು ನನಗೆ ಅನಿಸುತ್ತದೆ. ನಾನು ಸಂಪೂರ್ಣವಾಗಿ ಹುಡುಗನಂತೆ ಕಾಣುವುದಿಲ್ಲ, ಆದರೆ ನಾನು ಹುಡುಗಿಯಂತೆ ಕಾಣುವುದಿಲ್ಲ. ನನ್ನ ತಲೆಯಲ್ಲಿ, ನಾನು ಮಹಿಳೆಯಂತೆ ಬದುಕಲು ಬಯಸುತ್ತೇನೆ. ನಾನು ಸಹೋದರಿಯ ಬಟ್ಟೆಗಳನ್ನು ಧರಿಸಲು ಇಷ್ಟಪಡುತ್ತೇನೆ. ಜನರು ನನ್ನನ್ನು ತುಂಬಾ ಆಯ್ಕೆ ಮಾಡಬಾರದು ಎಂದು ನಾನು ಬಯಸುತ್ತೇನೆ. ಮುಂದೆ ನನ್ನನ್ನು ಯಾರು ನೋಯಿಸುತ್ತಾರೆ ಎಂದು ನೀವು ಯೋಚಿಸುತ್ತಿರುವಾಗ ನಿಮ್ಮ ಭಾವನೆಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು ಸುಲಭವಲ್ಲ.",
              "te": "నాకు తప్పు శరీరాన్ని ఇచ్చినట్లు నేను భావిస్తున్నాను. నేను పూర్తిగా అబ్బాయిలా కనిపించను, అలా అని అమ్మాయిలా కూడా కనిపించను. నా ఆలోచనలో, నేను స్త్రీలా జీవించాలనుకుంటున్నాను. నాకు సోదరి దుస్తులు ధరించడం ఇష్టం. ప్రజలు నన్ను అంతగా ఏడిపించకూడదని నేను కోరుకుంటున్నాను. తర్వాత నన్ను ఎవరు బాధపెడతారనే దాని గురించి మీరు ఆలోచిస్తున్నప్పుడు మీకు ఏమి అనిపిస్తుందో అర్థం చేసుకోవడం అంత సులభం కాదు.",
              "or": "ମୋତେ ଲାଗୁଛି ଯେ ମୋତେ ଭୁଲ ଶରୀର ଦିଆଯାଇଛି | ମୁଁ ସମ୍ପୂର୍ଣ୍ଣ ରୂପେ ପୁଅ ପରି ଦେଖାଯାଉ ନାହିଁ, କିନ୍ତୁ ମୁଁ ଝିଅ ପରି ମଧ୍ଯ ଦେଖାଯାଉ ନାହିଁ | ମୋ ମୁଣ୍ଡରେ, ମୁଁ ଜଣେ ମହିଳାଙ୍କ ପରି ବଞ୍ଚିବାକୁ ପସନ୍ଦ କରେ | ମୁଁ ମୋର ଭଉଣୀର ପୋଷାକ ପିନ୍ଧିବାକୁ ପସନ୍ଦ କରେ | ମୁଁ ଇଚ୍ଛା କରେ ଲୋକମାନେ ମୋତେ ଏତେ ନଜର ନା ପକାନ୍ତୁ | ତୁମେ ଯାହା ଅନୁଭବ କରୁଛ ତାହା ବୁଝିବା ସହଜ ନୁହେଁ ଯେତେବେଳେ ଆମେ ସଦାବେଳେ ଭାବୁଥାଉ କି ପରବର୍ତ୍ତୀ ସମୟରେ ଆମକୁ କିଏ ଆଘାତ ଦେବ |",
              "as": "মই অনুভৱ কৰোঁ যেন মোক ভুল শৰীৰ দিয়া হৈছে। মই নিজকে সম্পূৰ্ণৰূপে ল'ৰাৰ দৰে দেখা নাপাওঁ, কিন্তু মই ছোৱালীৰ দৰেও দেখা নাপাওঁ। মোৰ মনেৰে, মই এগৰাকী মহিলাৰ দৰে জীয়াই থাকিব বিচাৰো। মই ভনীৰ কাপোৰ পিন্ধি ভাল পাওঁ। মই আশা কৰোঁ মানুহে মোক ইমান বেছি তুলি নলব। যেতিয়া আপুনি চিন্তা কৰে যে পৰৱৰ্তী সময়ত কোনে আপোনাক আঘাত কৰিব তেতিয়া আপুনি কি অনুভৱ কৰিছে বুজিপোৱা সহজ নহয়।",
              "gu": "મને એવું લાગે છે કે મને ખોટું શરીર આપવામાં આવ્યું છે.  હું સંપૂર્ણપણે છોકરા જેવો દેખાતો નથી, પણ હું છોકરી જેવો બી દેખાતો નથી.  મારા મનમાં, મને એક સ્ત્રીની જેમ જીવવું ગમશે.  મને બહેનના કપડાં પહેરવા ગમે છે.  હું ઈચ્છું છું કે લોકો મને આટલું બધું સતાવે નહીં.  તમે કેવો અનુભવ કરી રહ્યા છો તે સમજવું સરળ નથી જ્યારે તમારા મનમાં ફક્ત એ જ વિચાર આવતો હોય  કે હવે પછી મને કોણ દુઃખ પહોંચાડશે."
            }
          }
        ],
        "page23": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_5_3//",
              "hi": "B1_5_3 //",
              "ka": "B1_5_3//",
              "te": "B1_5_3//",
              "or": "B1_5_3//",
              "as": "B1_5_3//",
              "gu": "B1_5_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Some other members",
              "hi": "कुछ अन्य सदस्य",
              "ka": "ಇತರ ಕೆಲವು ಸದಸ್ಯರು",
              "te": "మరికొందరు సభ్యులు",
              "or": "ଅନ୍ୟ କିଛି ସଦସ୍ୟ |",
              "as": "আন কিছুমান সদস্য",
              "gu": "કોઈ અન્ય સભ્ય"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Vandana",
              "hi": "वंदना",
              "ka": "ವಂದನಾ",
              "te": "వందన",
              "or": "ଭଣ୍ଡନା",
              "as": "বন্দনা",
              "gu": "વંદના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "We hear you, we hear you.",
              "hi": "हम आपको सुनते हैं, हम आपको सुनते हैं।",
              "ka": "ನಾವು ನಿಮ್ಮನ್ನು ಕೇಳುತ್ತೇವೆ, ನಾವು ನಿಮ್ಮನ್ನು ಕೇಳುತ್ತೇವೆ.",
              "te": "మేము మీ మాట వింటాము, మీ మాట వింటాము.",
              "or": "ଆମେ ତୁମକୁ ଶୁଣୁଛୁ, ଆମେ ଶୁଣୁଛୁ |",
              "as": "আমি আপোনাৰ কথা শুনিছো, আমি আপোনাৰ কথা শুনিছো।",
              "gu": "અમે તમારી વાત સાંભળીએ છીએ, અમે તમારી વાત સાંભળીએ છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Thank you for sharing, Arvind.",
              "hi": "साझा करने के लिए धन्यवाद, अरविंद।",
              "ka": "ಹಂಚಿಕೊಂಡಿದ್ದಕ್ಕೆ ಧನ್ಯವಾದಗಳು, ಅರವಿಂದ್.",
              "te": "పంచుకున్నందుకు ధన్యవాదాలు, అరవింద్.",
              "or": "ଅଂଶୀଦାର କରିବା ପାଇଁ ଧନ୍ୟବାଦ, ଅରବିନ୍ଦ |",
              "as": "আপোনাৰ কথাবোৰ কোৱাৰ বাবে ধন্যবাদ, অৰবিন্দ।",
              "gu": "શેર કરવા બદલ આભાર, અરવિંદ."
            }
          }
        ],
        "page24": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_4_10",
              "hi": "B1_4_10",
              "ka": "B1_4_10",
              "te": "B1_4_10",
              "or": "B1_4_10",
              "as": "B1_4_10",
              "gu": "B1_4_10"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind sits back feeling so much lighter. He smiles to himself because everybody around him seems to understand his experience.",
              "hi": "अरविंद हल्का महसूस कर रहा है। वह खुद पर मुस्कराता है क्योंकि उसके आस-पास के सभी लोग उसके अनुभव को समझते हैं।",
              "ka": "ಅರವಿಂದ್ ತುಂಬಾ ಹಗುರವಾಗಿ ಕುಳಿತುಕೊಳ್ಳುತ್ತಾನೆ. ಅವನು ತನ್ನಷ್ಟಕ್ಕೆ ತಾನೇ ನಗುತ್ತಾನೆ ಏಕೆಂದರೆ ಅವನ ಸುತ್ತಲಿರುವ ಪ್ರತಿಯೊಬ್ಬರೂ ಅವನ ಅನುಭವವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "అరవింద్ చాలా తేలికగా భావిస్తూ కూర్చున్నాడు. చుట్టుపక్కల ఉన్న ప్రతి ఒక్కరూ తన అనుభవాన్ని అర్థం చేసుకున్నట్లు కనిపిస్తున్నందున అతను తనలో తాను నవ్వుకుంటాడు.",
              "or": "ଅରବିନ୍ଦ ହାଲୁକା ଅନୁଭବ କରି ବସିଛନ୍ତି | ସେ ନିଜକୁ ହସନ୍ତି କାରଣ ତାଙ୍କ ଚାରିପାଖରେ ଥିବା ସମସ୍ତେ ତାଙ୍କ ଅନୁଭୂତି ବୁଝି ପାରିଥାନ୍ତି |",
              "as": "অৰবিন্দইমান পাতল অনুভৱ কৰি বহি আছে। তেওঁ নিজৰ ভিতৰতে হাঁহিছিল কাৰণ তেওঁৰ চাৰিওফালৰ সকলোৱে তেওঁৰ অভিজ্ঞতা বুজি পোৱা যেন লাগিছে।",
              "gu": "અરવિંદ ખૂબ હળવાશ અનુભવતા પાછળ બેસે ગયો.  તેનાં ચહેરા પર  સ્મિત હતું કારણ કે તેની આસપાસના દરેક વ્યક્તિ તેના અનુભવને સમજતાં હતાં."
            }
          }
        ],
        "page25": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_6_1",
              "hi": "B1_6_1",
              "ka": "B1_6_1",
              "te": "B1_6_1",
              "or": "B1_6_1",
              "as": "B1_6_1",
              "gu": "B1_6_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He feels blessed that he has a family that supports him and that he has found a community that he can relate to. It makes all the difference.",
              "hi": "वह धन्य अनुभव करता है कि उसके पास एक परिवार है जो उसे समर्थन देता है और उसे एक समुदाय मिला है जिससे वह खुद को संबंधित कर सकता है। इससे सारा फर्क पड़ता है।",
              "ka": "ಅವನು ಅವನ್ನನ್ನು ಬೆಂಬಲಿಸುವ ಕುಟುಂಬವನ್ನು ಹೊಂದಿದ್ದಾನೆ ಮತ್ತು ಅವನು ಸಂಬಂಧಿಸಬಹುದಾದ ಸಮುದಾಯವನ್ನು ಕಂಡುಕೊಂಡಿದ್ದಾನೆ ಎಂದು ಅವನು ಭಾವಿಸುತ್ತಾನೆ. ಇದು ಎಲ್ಲಾ ವ್ಯತ್ಯಾಸವನ್ನು ಮಾಡುತ್ತದೆ.",
              "te": "తనకు మద్దతిచ్చే కుటుంబాన్ని కలిగి ఉన్నందుకు మరియు అతనికి మద్దతు ఇచ్చే సంఘాన్ని కనుగొన్నందుకు అతను సంతోషించాడు. ఇది చాలా తేడా చూపిస్తుంది.",
              "or": "ସେ ନିଜକୁ ଭାଗ୍ୟବାନ ଅନୁଭବ କରନ୍ତି ଯେ ତାଙ୍କର ଏକ ପରିବାର ଅଛି ଯାହା ତାଙ୍କୁ ସମର୍ଥନ କରେ ଏବଂ ସେ ଏକ ସମ୍ପ୍ରଦାୟ ପାଇଛନ୍ତି ଯାହା ସହିତ ସେ ସମ୍ପର୍କ କରିପାରିବେ | ଏହା ସମସ୍ତ ପାର୍ଥକ୍ୟ ସୃଷ୍ଟି କରେ |",
              "as": "তেওঁ ধন্য অনুভৱ কৰে যে তেওঁৰ এটা পৰিয়াল আছে যিয়ে তেওঁক সমৰ্থন কৰে আৰু তেওঁ এটা সম্প্ৰদায় বিচাৰি পাইছে যাৰ সৈতে তেওঁ সম্পৰ্কিত হ'ব পাৰে। এইটোৱেই সকলো পাৰ্থক্য সৃষ্টি কৰে।",
              "gu": "તે પોતાને ધન્ય માને છે કે તેની પાસે એક કુટુંબ છે જે તેને ટેકો આપે છે અને તેને એક એવો સમુદાય મળ્યો છે જે તેનાં જેવો જ છે.  આ બધાની કારણે બહું ફરક પડે છે."
            }
          }
        ],
        "page26": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_6_2",
              "hi": "B1_6_2",
              "ka": "B1_6_2",
              "te": "B1_6_2",
              "or": "B1_6_2",
              "as": "B1_6_2",
              "gu": "B1_6_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The group meets once a month in person, and every Sunday, they meet online.",
              "hi": "समूह महीने में एक बार व्यक्तिगत रूप से मिलता है, और हर रविवार, वे ऑनलाइन मिलते हैं।",
              "ka": "ಗುಂಪು ತಿಂಗಳಿಗೊಮ್ಮೆ ವೈಯಕ್ತಿಕವಾಗಿ ಭೇಟಿಯಾಗುತ್ತದೆ ಮತ್ತು ಪ್ರತಿ ಭಾನುವಾರ ಅವರು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಭೇಟಿಯಾಗುತ್ತಾರೆ.",
              "te": "ఈ గ్రూప్ నెలకు ఒకసారి వ్యక్తిగతంగా కలుస్తుంది మరియు ప్రతి ఆదివారం వారు ఆన్‌లైన్‌లో కలుస్తారు.",
              "or": "ସେହି ଗୋଷ୍ଠୀ ମାସରେ ଥରେ ବ୍ୟକ୍ତିଗତ ଭାବରେ ସାକ୍ଷାତ ହୁଏ, ଏବଂ ପ୍ରତ୍ୟେକ ରବିବାର, ସେମାନେ ଅନଲାଇନରେ ଭେଟନ୍ତି |",
              "as": "গোটটোৱে মাহত এবাৰ ব্যক্তিগতভাৱে মিলিত হয়, আৰু প্ৰতি দেওবাৰে, তেওঁলোকে অনলাইনত লগ হয়।",
              "gu": "સમૂહ મહિનામાં એકવાર રૂબરૂ મળે છે અને દર રવિવારે તેઓ ઑનલાઇન મળે છે."
            }
          }
        ],
        "page27": [
          {
            "valuePath": "display",
            "value": {
              "en": "B1_6_3",
              "hi": "B1_6_3",
              "ka": "B1_6_3",
              "te": "B1_6_3",
              "or": "B1_6_3",
              "as": "B1_6_3",
              "gu": "B1_6_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind attends the online sessions on Sunday, and he starts feeling much better about himself. Other support group members share thoughts and feelings that deeply resonate with Arvind.",
              "hi": "अरविंद रविवार को ऑनलाइन सत्र में भाग लेता है, और वह अपने बारे में काफी बेहतर अनुभव करने लगता है। अन्य सहायता समूह के सदस्य उन विचारों और भावनाओं को साझा करते हैं जो अरविंद के साथ गहराई से प्रतिध्वनित होते हैं।",
              "ka": "ಅರವಿಂದ್ ಭಾನುವಾರ ಆನ್‌ಲೈನ್ ಸೆಷನ್‌ಗಳಿಗೆ ಹಾಜರಾಗುತ್ತಾನೆ ಮತ್ತು ಅವನು ತಮ್ಮ ಬಗ್ಗೆ ಹೆಚ್ಚು ಉತ್ತಮ ಭಾವನೆ ಹೊಂದಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ. ಇತರ ಬೆಂಬಲ ಗುಂಪಿನ ಸದಸ್ಯರು ಅರವಿಂದ್ ಅವರೊಂದಿಗೆ ಆಳವಾಗಿ ಪ್ರತಿಧ್ವನಿಸುವ ಆಲೋಚನೆಗಳು ಮತ್ತು ಭಾವನೆಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "అరవింద్ ఆదివారం ఆన్‌లైన్ సెషన్‌లకు హాజరవుతాడు మరియు అతను తన గురించి చాలా మెరుగ్గా భావించడం ప్రారంభించాడు. ఇతర సపోర్ట్ గ్రూప్ సభ్యులు కూడా అరవింద్‌ ఆలోచనలు మరియు భావాలకు దగ్గరగా ఉంటారు.",
              "or": "ଅରବିନ୍ଦ ରବିବାର ଦିନ ଅନଲାଇନ୍ ଅଧିବେଶନରେ ଯୋଗ ଦିଅନ୍ତି, ଏବଂ ସେ ନିଜ ବିଷୟରେ ବହୁତ ଭଲ ଅନୁଭବ କରିବା ଆରମ୍ଭ କରନ୍ତି | ଅନ୍ୟ ସମର୍ଥନ ଗୋଷ୍ଠୀର ସଦସ୍ୟମାନେ ନିଜର ଭାବନା ବାଣ୍ଟନ୍ତି ଯାହା ଅରବିନ୍ଦଙ୍କ ସହିତ ଗଭୀର ଭାବରେ ଅନୁବାଦ କରୁଥାଏ |",
              "as": "অৰবিন্দে দেওবাৰে অনলাইন অধিৱেশনত উপস্থিত থাকে, আৰু তেওঁ নিজৰ বিষয়ে বহুত ভাল অনুভৱ কৰিবলৈ আৰম্ভ কৰে। সমৰ্থন গোটৰ আন সদস্যসকলে চিন্তা আৰু অনুভূতি ভাগ বতৰা কৰে যি অৰবিন্দৰ সৈতে গভীৰভাৱে প্ৰতিধ্বনিত হয়।",
              "gu": "અરવિંદ રવિવારે ઓનલાઈન સત્રોમાં હાજરી આપે છે, અને તે પોતાના વિશે ઘણું સારું અનુભવવા લાગે છે.  અન્ય સપોર્ટ ગ્રુપ સભ્યો વિચારો અને લાગણીઓ શેર કરે છે જે અરવિંદનાં મન પર ગહરી છાપ છોડે છે."
            }
          }
        ],
        "page29": [
          {
            "valuePath": "display",
            "value": {
              "en": "Gender and Identity",
              "hi": "लिंग और पहचान",
              "ka": "ಲಿಂಗ ಮತ್ತು ಗುರುತು",
              "te": "లింగం మరియు గుర్తింపు",
              "or": "ଲିଙ୍ଗ ଏବଂ ପରିଚୟ |",
              "as": "লিংগ আৰু পৰিচয়",
              "gu": "લિંગ અને ઓળખ"
            }
          }
        ]
      },
      "scene20": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_1",
              "hi": "B2_1_1",
              "ka": "B2_1_1",
              "te": "B2_1_1",
              "or": "B2_1_1",
              "as": "B2_1_1",
              "gu": "B2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He gradually manages to attend more classes in his college. To avoid being targeted or calling attention to himself, he dresses like other boys in the college.",
              "hi": "वह धीरे-धीरे अपने कॉलेज में अधिक कक्षाओं में भाग लेने लगता है। लक्षित होने या खुद पर ध्यान देने से बचने के लिए, वह कॉलेज में अन्य लड़कों की तरह कपड़े पहनता है।",
              "ka": "ಅವನು ಕ್ರಮೇಣ ತಮ್ಮ ಕಾಲೇಜಿನಲ್ಲಿ ಹೆಚ್ಚಿನ ತರಗತಿಗಳಿಗೆ ಹಾಜರಾಗಲು ನಿರ್ವಹಿಸುತ್ತಾನೆ. ಗುರಿಯಾಗುವುದನ್ನು ತಪ್ಪಿಸಲು ಅಥವಾ ತನ್ನತ್ತ ಗಮನ ಸೆಳೆಯುವುದನ್ನು ತಪ್ಪಿಸಲು, ಅವನು ಕಾಲೇಜಿನ ಇತರ ಹುಡುಗರಂತೆ ಬಟ್ಟೆ ಧರಿಸುತ್ತಾನೆ.",
              "te": "అతను క్రమంగా తన కళాశాలలో ఎక్కువ తరగతులకు హాజరవడం ప్రారంభిస్తాడు. ఎవరూ తనను లక్ష్యంగా చేసుకోకుండా లేదా తనవైపు చూడకుండా ఉండేందుకు, అతను కాలేజీలోని ఇతర అబ్బాయిల మాదిరిగానే దుస్తులు ధరిస్తాడు.",
              "or": "ସେ ଧୀରେ ଧୀରେ ନିଜ କଲେଜରେ ଅଧିକ କ୍ଲାସରେ ପଢିବାକୁ ପରିଚାଳନା କରନ୍ତି | ଟାର୍ଗେଟ ନହେବା କିମ୍ବା ନିଜ ଆଡକୁ ଧ୍ୟାନ ନ ଆଣିବା ପାଇଁ, ସେ କଲେଜର ଅନ୍ୟ ପୁଅମାନଙ୍କ ପରି ପୋଷାକ ପିନ୍ଧନ୍ତି |",
              "as": "তেওঁ লাহে লাহে তেওঁৰ কলেজৰ শ্ৰেণীবোৰত বেছিকৈ উপস্থিত থাকিবলৈ সক্ষম হয়। তেওঁক লক্ষ্য কৰা বা নিজৰ প্ৰতি মনোযোগ আকৰ্ষণ নকৰিবলৈ, তেওঁ কলেজৰ আন ল'ৰাৰ দৰে কাপোৰ পিন্ধে।",
              "gu": "તે ધીમે ધીમે તેની કોલેજમાં વધુ વર્ગોમાં હાજરી આપવાનું ચાલુ કરે છે.  ટાર્ગેટ થવાથી બચવા અથવા પોતાની તરફ ધ્યાન ન ખેંચવા માટે, તે કૉલેજના અન્ય છોકરાઓની જેમ પોશાક પહેરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_2",
              "hi": "B2_1_2",
              "ka": "B2_1_2",
              "te": "B2_1_2",
              "or": "B2_1_2",
              "as": "B2_1_2",
              "gu": "B2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind feels suffocated as he cannot express himself freely, but he does not want to be different in college.",
              "hi": "अरविंद को घुटन का अनुभव होता है क्योंकि वह खुद को स्वतंत्र रूप से व्यक्त नहीं कर सकता है, लेकिन वह कॉलेज में अलग नहीं होना चाहता।",
              "ka": "ಅರವಿಂದ್‌ಗೆ ತನ್ನನ್ನು ಮುಕ್ತವಾಗಿ ವ್ಯಕ್ತಪಡಿಸಲು ಸಾಧ್ಯವಾಗದೆ ಉಸಿರುಗಟ್ಟಿದಂತಾಗುತ್ತದೆ, ಆದರೆ ಅವನು ಕಾಲೇಜಿನಲ್ಲಿ ಭಿನ್ನವಾಗಿರಲು ಬಯಸುವುದಿಲ್ಲ.",
              "te": "అరవింద్ స్వేచ్చగా తన భావాలు చెప్పలేక ఉక్కిరిబిక్కిరి అవుతున్నాడు, కానీ కాలేజీలో వేరేలా ఉండాలనుకోవట్లేదు.",
              "or": "ନିଜକୁ ମୁକ୍ତ ଭାବରେ ପ୍ରକାଶ କରିପାରୁ ନ ଥିବାରୁ ଅରବିନ୍ଦ ଶ୍ୱାସରୁଦ୍ଧ ଅନୁଭବ କରନ୍ତି, କିନ୍ତୁ ସେ କଲେଜରେ ଭିନ୍ନ ହେବାକୁ ଚାହାଁନ୍ତି ନାହିଁ |",
              "as": "অৰবিন্দে নিজকে মুক্তভাৱে প্ৰকাশ কৰিব নোৱাৰাৰ বাবে শ্বাসৰুদ্ধ অনুভৱ কৰে, কিন্তু তেওঁ কলেজত বেলেগ হ'ব নিবিচাৰে।",
              "gu": "અરવિંદ ગૂંગળામણનો  અનુભવે છે કારણ કે તે પોતાની જાતને મુક્તપણે વ્યક્ત કરી શકતો નથી, પરંતુ તે કોલેજમાં અલગ થલગ રહેવા માંગતો નથી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_3",
              "hi": "B2_1_3",
              "ka": "B2_1_3",
              "te": "B2_1_3",
              "or": "B2_1_3",
              "as": "B2_1_3",
              "gu": "B2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The support group helps him understand the complexities of owning one’s identity. Most of them talk about the coping mechanisms that help them deal with hostile surroundings and unsupportive families.",
              "hi": "समर्थन समूह उसे किसी की पहचान की जटिलताओं को समझने में सहायता करता है। उनमें से अधिकतर मुकाबला तंत्र के बारे में बात करते हैं जो उन्हें शत्रुतापूर्ण परिवेश और असमर्थित परिवारों से निपटने में सहायता करते हैं।",
              "ka": "ಒಬ್ಬರ ಗುರುತನ್ನು ಹೊಂದುವ ಸಂಕೀರ್ಣತೆಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಬೆಂಬಲ ಗುಂಪು ಅವನಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಅವರಲ್ಲಿ ಹೆಚ್ಚಿನವರು ಪ್ರತಿಕೂಲ ಪರಿಸರ ಮತ್ತು ಬೆಂಬಲವಿಲ್ಲದ ಕುಟುಂಬಗಳೊಂದಿಗೆ ವ್ಯವಹರಿಸಲು ಸಹಾಯ ಮಾಡುವ ನಿಭಾಯಿಸುವ ಕಾರ್ಯವಿಧಾನಗಳ ಬಗ್ಗೆ ಮಾತನಾಡುತ್ತಾರೆ.",
              "te": "ఒకరి గుర్తింపును సొంతం చేసుకోవడంలోని సంక్లిష్టతలను అర్థం చేసుకోవడంలో సపోర్ట్ గ్రూప్ అతనికి సహాయపడుతుంది. శత్రు పరిసరాలు మరియు మద్దతు లేని కుటుంబాలతో వ్యవహరించడంలో వారికి సహాయపడే కోపింగ్ మెకానిజమ్స్ గురించి చాలా మంది మాట్లాడతారు.",
              "or": "ସମର୍ଥନ ଗୋଷ୍ଠୀର ତାଙ୍କୁ ଏକର ପରିଚୟ କରିବାର ଜଟିଳତା ବୁଝିବାରେ ସାହାଯ୍ୟ କରେ | ସେମାନଙ୍କ ମଧ୍ୟରୁ ଅଧିକାଂଶ କପିଙ୍ଗ ଯନ୍ତ୍ରକୌଶଳ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି ଯାହା ସେମାନଙ୍କୁ ବନ୍ଧୁତ୍ୱହୀନ ପରିବେଶ ଏବଂ ଅସମର୍ଥିତ ପରିବାର ସହିତ ମୁକାବିଲା କରିବାରେ ସାହାଯ୍ୟ କରିଥାଏ |",
              "as": "সমৰ্থন গোটে তেওঁক নিজৰ পৰিচয়ৰ গৰাকী হোৱাৰ জটিলতাবোৰ বুজাত সহায় কৰে। তেওঁলোকৰ বেছিভাগেই কাৰ্য্য প্ৰণালীৰ বিষয়ে কয় যিয়ে তেওঁলোকক প্ৰতিকূল পৰিৱেশ আৰু সহায় নকৰা পৰিয়ালৰ সৈতে মোকাবিলা কৰাত সহায় কৰে।",
              "gu": "સ્પોર્ટ ગ્રુપ તેને પોતાની ઓળખ સ્થાપિત કરવામાં આવતી  મુશ્કેલીઓને સમજવામાં મદદ કરે છે.  તેમાંના મોટાભાગના લોકો પ્રતિકૂળ વાતાવરણ અને  પરિવારોનો ટેકો ના  મળતો હોય તો તેવી પરિસ્થિતિઓનો સામનો  કરવા માટેની પદ્ધતિઓ વિશે વાત કરે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_4",
              "hi": "B2_1_4",
              "ka": "B2_1_4",
              "te": "B2_1_4",
              "or": "B2_1_4",
              "as": "B2_1_4",
              "gu": "B2_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Getting inspired by the support group, Arvind makes a routine for himself. He dedicates fixed hours to his studies every day.",
              "hi": "समर्थन समूह से प्रेरित होकर, अरविंद अपने लिए एक नियमित दिनचर्या बनाता है। वह हर दिन अपने अध्ययन के लिए निश्चित घंटे समर्पित करता है।",
              "ka": "ಬೆಂಬಲ ಗುಂಪಿನಿಂದ ಸ್ಫೂರ್ತಿ ಪಡೆದ ಅರವಿಂದ್ ತನಗಾಗಿ ದಿನಚರಿ ಮಾಡುತ್ತಾನೆ. ಅವನು ಪ್ರತಿದಿನ ತನ್ನ ಅಧ್ಯಯನಕ್ಕೆ ನಿಗದಿತ ಸಮಯವನ್ನು ಮೀಸಲಿಡುತ್ತಾನೆ.",
              "te": "సపోర్ట్ గ్రూప్ నుండి ప్రేరణ పొంది, అరవింద్ తన కోసం ఒక రొటీన్ చేసుకుంటాడు. అతను ప్రతిరోజూ తన అధ్యయనానికి నిర్ణీత గంటలను కేటాయించాడు.",
              "or": "ସମର୍ଥନ ଗୋଷ୍ଠୀ ଦ୍ୱାରା ଅନୁପ୍ରାଣିତ ହୋଇ ଅରବିନ୍ଦ ନିଜ ପାଇଁ ଏକ ନିତ୍ୟକର୍ମ କରନ୍ତି | ସେ ପ୍ରତିଦିନ ନିଜ ଅଧ୍ୟୟନକୁ ନିର୍ଦ୍ଦିଷ୍ଟ ଘଣ୍ଟା ଉତ୍ସର୍ଗ କରନ୍ତି |",
              "as": "সমৰ্থন গোটৰ দ্বাৰা অনুপ্ৰাণিত হৈ, অৰবিন্দে নিজৰ বাবে এটা ৰুটিন প্ৰস্তুত কৰে। তেওঁ প্ৰতিদিনে পঢ়া-শুনাত নিৰ্দিষ্ট সময় উৎসৰ্গা কৰে।",
              "gu": "સપોર્ટ ગ્રૂપથી પ્રેરિત થઈને, અરવિંદ પોતાના માટે એક રૂટિન બનાવે છે.  તે દરરોજ તેના અભ્યાસ માટે નિશ્ચિત કલાકો સમર્પિત કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_5",
              "hi": "B2_1_5",
              "ka": "B2_1_5",
              "te": "B2_1_5",
              "or": "B2_1_5",
              "as": "B2_1_5",
              "gu": "B2_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As a part of his routine, he spends some time during the day either doodling or dancing. He finds that it helps him release his stress.",
              "hi": "अपने दिनचर्या के एक हिस्से के रूप में, वह दिन का कुछ समय या तो डूडलिंग करने या नृत्य करने में बिताता है। वह पाता है कि यह उसे अपने तनाव को मुक्त करने में सहायता करता है।",
              "ka": "ಅವನ ದಿನಚರಿಯ ಭಾಗವಾಗಿ, ಅವನು ದಿನವಿಡೀ ಸ್ವಲ್ಪ ಸಮಯವನ್ನು ಡೂಡ್ಲಿಂಗ್ ಅಥವಾ ನೃತ್ಯದಲ್ಲಿ ಕಳೆಯುತ್ತಾನೆ. ಇದು ತನ್ನ ಒತ್ತಡವನ್ನು ನಿವಾರಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ ಎಂದು ಅವನು ಕಂಡುಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "తన దినచర్యలో భాగంగా, అతను పగటిపూట డూడ్లింగ్ లేదా డ్యాన్స్‌తో కొంత సమయం గడుపుతాడు. అది తన ఒత్తిడిని తగ్గించుకోవడానికి సహాయపడుతుందని అతను కనుగొన్నాడు.",
              "or": "ତାଙ୍କର ନିତ୍ୟକର୍ମ ଏକ ଅଂଶ ଭାବରେ ସେ ଦିନରେ କିଛି ସମୟ ଡୁଡଲିଂ କିମ୍ବା ନାଚରେ ବିତାନ୍ତି | ସେ ପାଆନ୍ତି ଯେ ଏହା ତାଙ୍କୁ ତାଙ୍କ ଚାପରୁ ମୁକ୍ତି ଦେଇଥାଏ |",
              "as": "তেওঁৰ ৰুটিনৰ অংশ হিচাপে, তেওঁ দিনটোত কিছু সময় হয় ডুডলিং বা নাচি অতিবাহিত কৰে। তেওঁ বিচাৰি পাইছে যে এইটোৱে তেওঁক তেওঁৰ চাপ মুকলি কৰাত সহায় কৰে।",
              "gu": "તેની દિનચર્યાના ભાગરૂપે, તે દિવસ દરમિયાન થોડો સમય ડૂડલિંગ અથવા ડાન્સિંગમાં વિતાવે છે.  તેને લાગે છે કે આ બંને તેના તણાવને મુક્ત કરવામાં મદદ કરે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_1_6",
              "hi": "B2_1_6",
              "ka": "B2_1_6",
              "te": "B2_1_6",
              "or": "B2_1_6",
              "as": "B2_1_6",
              "gu": "B2_1_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Having a routine and a plan gives him some stability. He also spends time reading more about gender and acceptance from different sources. But doesn't feel ready to transition into the gender he identifies with.",
              "hi": "एक नियमित दिनचर्या और एक योजना होने से उसे कुछ स्थिरता मिलती है। वह विभिन्न स्रोतों से लिंग और स्वीकृति के बारे में अधिक पढ़ने में समय बिताता है। लेकिन वह लिंग में ट्रांज़िशन/ बदलाव के लिए तैयार नहीं है जिससे उसकी पहचान है।",
              "ka": "ದಿನಚರಿ ಮತ್ತು ಯೋಜನೆಯನ್ನು ಹೊಂದಿರುವುದು ಅವನಿಗೆ ಸ್ವಲ್ಪ ಸ್ಥಿರತೆಯನ್ನು ನೀಡುತ್ತದೆ. ಅವನು ವಿವಿಧ ಮೂಲಗಳಿಂದ ಲಿಂಗ ಮತ್ತು ಸ್ವೀಕಾರದ ಬಗ್ಗೆ ಹೆಚ್ಚು ಓದಲು ಸಮಯವನ್ನು ಕಳೆಯುತ್ತಾನೆ. ಆದರೆ ಅವನು ಗುರುತಿಸುವ ಲಿಂಗಕ್ಕೆ ಪರಿವರ್ತನೆಗೊಳ್ಳಲು ಸಿದ್ಧ ಎಂದು ಭಾವಿಸುವುದಿಲ್ಲ.",
              "te": "ఒక రొటీన్ మరియు ప్రణాళిక కలిగి ఉండటం అతనికి కొంత స్థిరత్వాన్ని ఇస్తుంది. అతను వివిధ సోర్సెస్ నుండి లింగం మరియు అంగీకారం గురించి మరింత చదవడానికి సమయాన్ని వెచ్చిస్తాడు. కానీ అతను గుర్తించిన లింగంలోకి మారడానికి సిద్ధంగా లేడు.",
              "or": "ଏକ ନିତ୍ୟକର୍ମ ଏବଂ ଯୋଜନା ରହିବା ତାଙ୍କୁ କିଛି ସ୍ଥିରତା ଦେଇଥାଏ | ଲିଙ୍ଗ ଏବଂ ବିଭିନ୍ନ ଉତ୍ସରୁ ଗ୍ରହଣୀୟତା ବିଷୟରେ ସେ ଅଧିକ ପଢିବା ପାଇଁ ମଧ୍ୟ ସମୟ ବିତାନ୍ତି | କିନ୍ତୁ ସେ ଯେଉଁ ଲିଙ୍ଗ ସହିତ ପରିଚିତ, ସେଥିରେ ପରିବର୍ତ୍ତନ କରିବାକୁ ପ୍ରସ୍ତୁତ ନୁହଁନ୍ତି |",
              "as": "এটা ৰুটিন আৰু পৰিকল্পনা থাকিলে তেওঁ কিছু স্থিৰতা লাভ কৰে। তেওঁ বিভিন্ন উৎসৰ পৰা লিংগ আৰু গ্ৰহণযোগ্যতাৰ বিষয়ে অধিক পঢ়ি সময় অতিবাহিত কৰে। কিন্তু তেওঁ চিনাক্ত কৰা লিংগলৈ পৰিৱৰ্তন কৰিবলৈ সাজু নহয় যেন অনুভৱ কৰে।",
              "gu": "નિયમિત દિનચર્યા અને યોજના નું પાલન કરવાથી તેને થોડી સ્થિરતા મળે છે.  તે વિવિધ સ્ત્રોતોમાંથી લિંગ અને તેની સ્વીકૃતિ વિશે વધુ વાંચવામાં પણ સમય વિતાવે છે.  પરંતુ તેની પોતાની  ઓળખે જેની સાથે છે તે લિંગમાં માં ટ્રાન્સિટ કરવા પોતે તૈયાર હોય તેવું તેને નથી લાગતું."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_2_1",
              "hi": "B2_2_1",
              "ka": "B2_2_1",
              "te": "B2_2_1",
              "or": "B2_2_1",
              "as": "B2_2_1",
              "gu": "B2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind thinks that the students will stop bothering him when he gets good marks but some of the students get jealous of him doing well in his studies.",
              "hi": "अरविंद सोचता है कि अच्छे अंक प्राप्त करने पर छात्र उसे परेशान करना बंद कर देंगे लेकिन कुछ छात्र अध्ययन में उसके अच्छे प्रदर्शन करने से ईर्ष्या करने लगे हैं।",
              "ka": "ಅರವಿಂದ್ ಉತ್ತಮ ಅಂಕಗಳನ್ನು ಪಡೆದಾಗ ವಿದ್ಯಾರ್ಥಿಗಳು ತನಗೆ ತೊಂದರೆ ಕೊಡುವುದನ್ನು ನಿಲ್ಲಿಸುತ್ತಾರೆ ಎಂದು ಭಾವಿಸುತ್ತಾನೆ ಆದರೆ ಕೆಲವು ವಿದ್ಯಾರ್ಥಿಗಳು ಅವನು ತನ್ನ ಅಧ್ಯಯನದಲ್ಲಿ ಚೆನ್ನಾಗಿ ಮಾಡುತ್ತಿರುವುದನ್ನು ನೋಡಿ ಹೊಟ್ಟೆಕಿಚ್ಚುಪಡುತ್ತಾರೆ.",
              "te": "మంచి మార్కులు వస్తే స్టూడెంట్స్ తనని ఇబ్బంది పెట్టడం మానేస్తారని అరవింద్ అనుకుంటాడు కానీ చదువులో బాగా రాణిస్తున్నాడని చూసి కొంతమంది స్టూడెంట్స్ అసూయ పడతారు.",
              "or": "ଅରବିନ୍ଦ ଭାବନ୍ତି ଯେ ଯେତେବେଳେ ସେ ଭଲ ମାର୍କ ପାଇବେ ସେତେବେଳେ ଛାତ୍ରମାନେ ତାଙ୍କୁ କଷ୍ଟ ଦେବା ବନ୍ଦ କରିବେ କିନ୍ତୁ କିଛି ଛାତ୍ର ତାଙ୍କ ଅଧ୍ୟୟନରେ ଭଲ କରିଲେ ଇର୍ଷା କରନ୍ତି |",
              "as": "অৰবিন্দে ভাবে যে যেতিয়া তেওঁ ভাল নম্বৰ পাব তেতিয়া শিক্ষাৰ্থীসকলে তেওঁক আমনি কৰা বন্ধ কৰিব কিন্তু কিছুমান শিক্ষাৰ্থীয়ে তেওঁ পঢ়াশুনাত ভাল কৰাৰ বাবে ঈৰ্ষা কৰে।",
              "gu": "અરવિંદ વિચારતો હતો કે જ્યારે તે સારા માર્કસ મેળવશે ત્યારે વિદ્યાર્થીઓ તેને હેરાન કરવાનું બંધ કરી દેશે પરંતુ કેટલાક વિદ્યાર્થીઓ અભ્યાસમાં તેનાં સારા પ્રદર્શનનાં કારણે ઈર્ષ્યા કરવા લાગ્યા."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_2_2",
              "hi": "B2_2_2",
              "ka": "B2_2_2",
              "te": "B2_2_2",
              "or": "B2_2_2",
              "as": "B2_2_2",
              "gu": "B2_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They attack Arvind by teasing and harassing him in class and the corridors. They call him names and say mean things about him all the time.",
              "hi": "वे अरविन्द को कक्षा और गलियारे में चिढ़ाकर और परेशान करके हमला करते हैं। वे उसे हर समय कई नाम से बुलाते हैं और उसके बारे में तुच्छ बातें करते हैं।",
              "ka": "ಅವರು ಅರವಿಂದ್ ಅನ್ನು ತರಗತಿಯಲ್ಲಿ ಮತ್ತು ಕಾರಿಡಾರ್‌ಗಳಲ್ಲಿ ಚುಡಾಯಿಸಿ ಕಿರುಕುಳ ನೀಡುವ ಮೂಲಕ ಹಲ್ಲೆ ಮಾಡುತ್ತಾರೆ. ಅವರು ಅವನನ್ನು ಹೆಸರುಗಳನ್ನು ಕರೆಯುತ್ತಾರೆ ಮತ್ತು ಅವನ ಬಗ್ಗೆ ಯಾವಾಗಲೂ ಕೆಟ್ಟದ್ದನ್ನು ಹೇಳುತ್ತಾರೆ.",
              "te": "వారు అరవింద్‌పై క్లాస్‌లోనూ, కారిడార్‌లోనూ ఆటపట్టిస్తూ, వేధిస్తూ దాడి చేసేవారు. వారు అతనిని వివిధ పేర్లతో పిలిచేవారు మరియు అతని గురించి ఎప్పుడూ నీచంగా మాట్లాడేవారు.",
              "or": "ସେମାନେ କ୍ଲାସ୍ ଏବଂ କରିଡରରେ ଅରବିନ୍ଦଙ୍କୁ ଥଟ୍ଟା କରି ହଇରାଣ କରି ତାଙ୍କୁ ଆକ୍ରମଣ କରନ୍ତି | ସେମାନେ ତାଙ୍କୁ ନାମ ଦିଅନ୍ତି ଏବଂ ସବୁବେଳେ ତାଙ୍କ ବିଷୟରେ କିଛି କହନ୍ତି |",
              "as": "তেওঁলোকে অৰবিন্দক শ্ৰেণী আৰু কৰিডৰত জোকাই আৰু হাৰাশাস্তি কৰি আক্ৰমণ কৰে। তেওঁলোকে তেওঁৰ নাম ধৰি মাতে আৰু সকলো সময়তে তেওঁৰ বিষয়ে অৰ্থপূৰ্ণ কথা কয়।",
              "gu": "તેઓ અરવિંદને ક્લાસ અને કોરિડોરમાં ચીડવવા અને હેરાન કરીને તેના પર હુમલો કરવા લાગ્યા.  તેઓ તેને અજબ નામોથી બોલાવા લાગ્યા અને તેના વિશે ફક્ત મતલબ થી જ વાતો કરવા લાગ્યા."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_2_4",
              "hi": "B2_2_4",
              "ka": "B2_2_4",
              "te": "B2_2_4",
              "or": "B2_2_4",
              "as": "B2_2_4",
              "gu": "B2_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind tries a lot of things apart from studying to keep himself busy. Though he likes to dance, he avoids that, fearing they will tease him more.",
              "hi": "अरविंद अपने आपको व्यस्त रखने के लिए अध्ययन के अतिरिक्त अन्य कई चीज करने का प्रयास करता है। यद्यपि उसे नृत्य करना पसंद है, इस डर से कि वे उसे और चिढ़ाएंगे वह इससे बचता है।",
              "ka": "ಅರವಿಂದ್ ತನ್ನನ್ನು ಬ್ಯುಸಿಯಾಗಿರಿಸಲು ಅಧ್ಯಯನದ ಹೊರತಾಗಿ ಬಹಳಷ್ಟು ವಿಷಯಗಳನ್ನು ಪ್ರಯತ್ನಿಸುತ್ತಾನೆ. ಅವನು ನೃತ್ಯ ಮಾಡಲು ಇಷ್ಟಪಡುತ್ತಿದ್ದರೂ, ಅವರು ಅವನನ್ನು ಹೆಚ್ಚು ಕೀಟಲೆ ಮಾಡುತ್ತಾರೆ ಎಂಬ ಭಯದಿಂದ ಅವನು ಅದನ್ನು ತಪ್ಪಿಸುತ್ತಾನೆ.",
              "te": "అరవింద్ తనను తాను బిజీగా ఉంచుకోవడానికి చదువుతో పాటు చాలా ప్రయత్నాలు చేస్తుంటాడు. అతను డ్యాన్స్ చేయడానికి ఇష్టపడినప్పటికీ, వారు తనను మరింత ఆటపట్టిస్తారనే భయంతో అతను దానికి దూరంగా ఉన్నాడు.",
              "or": "ନିଜକୁ ବ୍ୟସ୍ତ ରଖିବା ପାଇଁ ଅରବିନ୍ଦ ଅଧ୍ୟୟନ ବ୍ୟତୀତ ଅନେକ ଜିନିଷ ଚେଷ୍ଟା କରନ୍ତି | ଯଦିଓ ସେ ନାଚିବାକୁ ପସନ୍ଦ କରନ୍ତି, ସେ ଭୟଭୀତ ହୁଅନ୍ତି ଯେ ସେମାନେ ତାଙ୍କୁ ଅଧିକ ଥଟ୍ଟା କରିବେ |",
              "as": "অৰবিন্দে নিজকে ব্যস্ত ৰাখিবলৈ অধ্যয়নৰ বাহিৰেও বহুতো বস্তু চেষ্টা কৰে। যদিও তেওঁ নাচিবলৈ ভাল পায়, তেওঁ সেইটো এৰাই চলে, তেওঁলোকে তেওঁক অধিক জোকাব বুলি ভয় কৰে।",
              "gu": "અરવિંદ પોતાની જાતને વ્યસ્ત રાખવા માટે ભણવા સિવાય પણ ઘણી અન્ય પ્રવૃત્તિઓ કરવા લાગ્યો.   તેને ડાન્સ કરવાનું પસંદ હતું, તે નહોતો કરતો  કેમકે તેને ડર હતો કે તેઓ તેને વધુ ચીડવશે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_2_5",
              "hi": "B2_2_5",
              "ka": "B2_2_5",
              "te": "B2_2_5",
              "or": "B2_2_5",
              "as": "B2_2_5",
              "gu": "B2_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He goes for a walk every day and talks to his family regularly. He also takes up an online drawing class that helps him deal with the situation.",
              "hi": "वह हर दिन टहलने जाता है और नियमित रूप से अपने परिवार से बात करता है। वह एक ऑनलाइन ड्राइंग क्लास भी जाने लगता है जो उसे स्थिति से निपटने में सहायता करता है।",
              "ka": "ಅವನು ಪ್ರತಿದಿನ ವಾಕಿಂಗ್ ಹೋಗುತ್ತಾನೆ ಮತ್ತು ಅವನ ಕುಟುಂಬದೊಂದಿಗೆ ನಿಯಮಿತವಾಗಿ ಮಾತನಾಡುತ್ತಾನೆ. ಅವನು ಪರಿಸ್ಥಿತಿಯನ್ನು ನಿಭಾಯಿಸಲು ಸಹಾಯ ಮಾಡುವ ಆನ್‌ಲೈನ್ ಡ್ರಾಯಿಂಗ್ ತರಗತಿಯನ್ನು ಸಹ ತೆಗೆದುಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "రోజూ వాకింగ్ కు వెళ్లి కుటుంబ సభ్యులతో నిత్యం మాట్లాడుతుంటాడు. అతను పరిస్థితిని ఎదుర్కోవడంలో సహాయపడడానికి ఆన్‌లైన్ డ్రాయింగ్ క్లాస్‌ను కూడా తీసుకుంటాడు.",
              "or": "ସେ ପ୍ରତିଦିନ ବୁଲିବାକୁ ଯାଆନ୍ତି ଏବଂ ତାଙ୍କ ପରିବାର ସହିତ ନିୟମିତ କଥାବାର୍ତ୍ତା କରନ୍ତି | ସେ ଏକ ଅନଲାଇନ୍ ଚିତ୍ରାଙ୍କନ ଶ୍ରେଣୀ ମଧ୍ୟ ନିଅନ୍ତି ଯାହା ତାଙ୍କୁ ପରିସ୍ଥିତିର ମୁକାବିଲା କରିବାରେ ସାହାଯ୍ୟ କରେ |",
              "as": "তেওঁ সদায় খোজ কাঢ়িবলৈ যায় আৰু তেওঁৰ পৰিয়ালৰ সৈতে নিয়মীয়াকৈ কথা পাতে। তেওঁ এটা অনলাইন ড্ৰয়িং শ্ৰেণীও লয় যিয়ে তেওঁক পৰিস্থিতিটোৰ সৈতে মোকাবিলা কৰাত সহায় কৰে।",
              "gu": "તે દરરોજ ફરવા જાય છે અને પોતાના પરિવાર સાથે નિયમિત વાત કરે છે.  તે ઓનલાઈન ડ્રોઈંગ ક્લાસ પણ કરે છે જે તેને પરિસ્થિતિનો સામનો કરવામાં મદદ મળે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_3_1",
              "hi": "B2_3_1",
              "ka": "B2_3_1",
              "te": "B2_3_1",
              "or": "B2_3_1",
              "as": "B2_3_1",
              "gu": "B2_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "One day when Arvind enters the lab, there is a lot of laughing at something on their computer screen.",
              "hi": "एक दिन जब अरविंद प्रयोगशाला में प्रवेश करता है, तो पाता है कि सब अपने कंप्यूटर स्क्रीन पर कुछ देखकर बहुत हंस रहे हैं।",
              "ka": "ಒಂದು ದಿನ ಅರವಿಂದ್ ಲ್ಯಾಬ್‌ಗೆ ಪ್ರವೇಶಿಸಿದಾಗ ಅವರ ಕಂಪ್ಯೂಟರ್ ಪರದೆಯ ಮೇಲೆ ಏನೋ ನಗು.",
              "te": "ఒకరోజు అరవింద్ ల్యాబ్‌లోకి రాగానే అందరూ వాళ్ళ కంప్యూటర్ స్క్రీన్‌లో ఏదో చూస్తూ నవ్వుతుంటారు.",
              "or": "ଦିନେ ଯେତେବେଳେ ଅରବିନ୍ଦ ଲ୍ୟାବରେ ପ୍ରବେଶ କରନ୍ତି, ସେମାନଙ୍କ କମ୍ପ୍ୟୁଟର ସ୍କ୍ରିନରେ କିଛି ଦେଖି ବହୁତ ହସୁଥାନ୍ତି |",
              "as": "এদিন যেতিয়া অৰবিন্দ লেবলৈ আহে, তেওঁলোকে তেওঁলোকৰ কম্পিউটাৰ স্ক্ৰীণত কিবা এটা দেখি বহুত হাঁহি থাকে।",
              "gu": "એક દિવસ જ્યારે અરવિંદે લેબમાં પ્રવેશ કર્યો ત્યારે અમૂક લોકો તેમના કોમ્પ્યુટર સ્ક્રીન પર કંઈક જોઈને ખૂબ હસતાં હતાં."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_3_2///",
              "hi": "B2_3_2 //////",
              "ka": "B2_3_2///",
              "te": "B2_3_2///",
              "or": "B2_3_2///",
              "as": "B2_3_2///",
              "gu": "B2_3_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Arvind switches on his computer and realises that all the computer screens have a morphed picture of a girl with Arvind’s face.",
              "hi": "अरविंद अपने कंप्यूटर को स्विच ऑन करता है और देखता है कि सभी कंप्यूटर स्क्रीन पर एक लड़की की तस्वीर है जिस पर अरविंद का चेहरा लगा हुआ है।",
              "ka": "ಅರವಿಂದ್ ತನ್ನ ಕಂಪ್ಯೂಟರನ್ನು ಆನ್ ಮಾಡಿದನು ಮತ್ತು ಎಲ್ಲಾ ಕಂಪ್ಯೂಟರ್ ಸ್ಕ್ರೀನ್‌ಗಳಲ್ಲಿ ಅರವಿಂದನ ಮುಖವಿರುವ ಹುಡುಗಿಯ ಮಾರ್ಫ್ ಮಾಡಿದ ಚಿತ್ರವಿದೆ ಎಂದು ಅರವಿಂದ್ ಅರಿತುಕೊಂಡ.",
              "te": "అరవింద్ తన కంప్యూటర్‌ను ఆన్ చేసి, అన్ని కంప్యూటర్ స్క్రీన్‌లలో అరవింద్ ముఖంతో ఒక అమ్మాయి యొక్క మార్ఫింగ్ చిత్రం ఉందని గ్రహించాడు.",
              "or": "ଅରବିନ୍ଦ ତାଙ୍କ କମ୍ପ୍ୟୁଟରକୁ ସୁଇଚ୍ ଅନ୍‍ କରନ୍ତି ଏବଂ ହୃଦୟଙ୍ଗମ କରନ୍ତି ଯେ ସମସ୍ତ କମ୍ପ୍ୟୁଟର ସ୍କ୍ରିନରେ ଅରବିନ୍ଦଙ୍କ ଚେହେରା ଥିବା ଜଣେ ଝିଅର ଚିତ୍ର ଅଛି |",
              "as": "অৰবিন্দে তেওঁৰ কম্পিউটাৰটো অন কৰে আৰু উপলব্ধি কৰে যে সকলো কম্পিউটাৰ স্ক্ৰীণত অৰবিন্দৰ মুখৰ ছোৱালী এজনীৰ এখন মৰ্ফকৰা ছবি আছে।",
              "gu": "અરવિંદ પોતાના કોમ્પ્યુટર પર સ્વિચ કરીને જોયું  અને તેને ખબર પડી કે તમામ કોમ્પ્યુટર સ્ક્રીન પર અરવિંદના ચહેરાવાળી એક છોકરીની મોર્ફ કરેલી તસવીર હતી."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_3_3",
              "hi": "B2_3_3",
              "ka": "B2_3_3",
              "te": "B2_3_3",
              "or": "B2_3_3",
              "as": "B2_3_3",
              "gu": "B2_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind shuts down the computer and goes back to his room.",
              "hi": "अरविंद कंप्यूटर को बंद कर देता है और वापस अपने कमरे में चला जाता है।",
              "ka": "ಅರವಿಂದ್ ಕಂಪ್ಯೂಟರ್ ಬಂದ್ ಮಾಡಿ ಮತ್ತೆ ತನ್ನ ರೂಮಿಗೆ ಹೋದ.",
              "te": "అరవింద్ కంప్యూటర్ షట్ డౌన్ చేసి తిరిగి తన గదిలోకి వెళ్తాడు.",
              "or": "ଅରବିନ୍ଦ କମ୍ପ୍ୟୁଟରକୁ ବନ୍ଦ କରି ନିଜ ରୁମକୁ ଯାଆନ୍ତି |",
              "as": "অৰবিন্দে কম্পিউটাৰটো বন্ধ কৰি নিজৰ কোঠালৈ উভতি যায়।",
              "gu": "અરવિંદ કોમ્પ્યુટર બંધ કરે છે અને પોતાના રૂમમાં પાછો જાય છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_3_4",
              "hi": "B2_3_4",
              "ka": "B2_3_4",
              "te": "B2_3_4",
              "or": "B2_3_4",
              "as": "B2_3_4",
              "gu": "B2_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The lab assistant finds out about this but feels like it was just a small prank. He scolds the students who were responsible and let them go.",
              "hi": "प्रयोगशाला सहायक इस बारे में पता लगाता है लेकिन उसे ऐसा लगता है कि यह सिर्फ एक छोटी शरारत थी। वह उन छात्रों को डांटता है जो इसके जिम्मेदार थे और उन्हें जाने देता  है।",
              "ka": "ಲ್ಯಾಬ್ ಅಸಿಸ್ಟೆಂಟ್ ಇದರ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆ ಆದರೆ ಇದು ಕೇವಲ ಒಂದು ಸಣ್ಣ ತಮಾಷೆ ಎಂದು ಭಾವಿಸುತ್ತಾನೆ.\n ಅದಕ್ಕೆ ಜವಾಬ್ದಾರರಾದ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಗದರಿಸಿ ಹೊರಗೆ ಹೋಗಲು ಹೇಳುತ್ತಾನೆ.",
              "te": "ల్యాబ్ అసిస్టెంట్ దీని గురించి తెలుసుకుంటాడు కానీ ఇది కేవలం చిన్న ప్రాంక్ అని అనుకుంటాడు. బాధ్యులైన విద్యార్థులను తిట్టి వదిలేస్తాడు.",
              "or": "ଲ୍ୟାବ ଆସିସ୍‌ଟାଣ୍ଟ ଏହା ବିଷୟରେ ଜାଣିପାରନ୍ତି କିନ୍ତୁ ଅନୁଭବ କରନ୍ତି ଯେ ଏହା ଏକ ଛୋଟ ମଜା | ସେ ଦାୟୀ ଥିବା ଛାତ୍ରମାନଙ୍କୁ ଗାଳି ଦିଅନ୍ତି ଏବଂ ସେମାନଙ୍କୁ ଛାଡି ଦିଅନ୍ତି |",
              "as": "লেবৰ সহায়কজনে এই বিষয়ে জানিব পাৰিছিল কিন্তু ভাবিছিল যে এইটো মাত্ৰ এটা সৰু ধেমালি আছিল। তেওঁ এইবোৰ কৰি থকা শিক্ষাৰ্থীসকলক গালি পাৰিছিল আৰু তেওঁলোকক যাবলৈ দিছিল।",
              "gu": "લેબ આસિસ્ટન્ટને આ વિશે જાણવા મળી પરંતુ તેને લાગે છે કે તે માત્ર એક નાની ટીખળ હતી.  તે જવાબદાર વિદ્યાર્થીઓને ઠપકો આપે છે અને તેમને જવા દે છે."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_4_1",
              "hi": "B2_4_1",
              "ka": "B2_4_1",
              "te": "B2_4_1",
              "or": "B2_4_1",
              "as": "B2_4_1",
              "gu": "B2_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "That week, Arvind goes to the support group feeling troubled.",
              "hi": "उस सप्ताह, अरविंद परेशानी का अनुभव करता है और समर्थन समूह के पास जाता है।",
              "ka": "ಆ ವಾರ, ಅರವಿಂದ್ ತೊಂದರೆ ಅನುಭವಿಸುತ್ತ ಬೆಂಬಲ ಗುಂಪಿಗೆ ಹೋಗುತ್ತಾನೆ.",
              "te": "ఆ వారం, అరవింద్ ఇబ్బందిగా భావించి సపోర్ట్ గ్రూప్‌కి వెళ్తాడు.",
              "or": "ସେହି ସପ୍ତାହରେ, ଅରବିନ୍ଦ ଅସୁବିଧା ଅନୁଭବ କରି ସମର୍ଥନ ଗୋଷ୍ଠୀକୁ ଯାଆନ୍ତି |",
              "as": "সেই সপ্তাহত, অৰবিন্দে অশান্তি পাই সমৰ্থন গোটলৈ যায়।",
              "gu": "તે અઠવાડિયે, અરવિંદ પરેશાન થઈને સપોર્ટ ગ્રુપ પાસે ગયો."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_4_2////",
              "hi": "B2_4_2 /////",
              "ka": "B2_4_2////",
              "te": "B2_4_2////",
              "or": "B2_4_2////",
              "as": "B2_4_2////",
              "gu": "B2_4_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "The incident in the computer lab felt so personal. I felt violated. They wanted me to feel shame. Why are they judging me on my gender and not other things that I am good at.",
              "hi": "कंप्यूटर लैब में हुई घटना मुझे व्यक्तिगत लगी। मुझे अपना निरादर होना अनुभव हुआ। वे चाहते थे कि मैं शर्म का अनुभव करूं। वे मुझे मेरे लिंग के आधार पर क्यों आंक रहे हैं न कि अन्य चीजों में जिनमें मैं अच्छा हूं।",
              "ka": "ಕಂಪ್ಯೂಟರ್ ಲ್ಯಾಬ್‌ನಲ್ಲಿ ನಡೆದ ಘಟನೆ ತುಂಬಾ ವೈಯಕ್ತಿಕ ಅನಿಸಿತು. ನಾನು ಅಲ್ಲಿ ಉಲ್ಲಂಘನೆಯಾದಂತೆ ಅನಿಸಿತು. ನಾನು ಅವಮಾನ ಅನುಭವಿಸಬೇಕೆಂದು ಅವರು ಬಯಸಿದ್ದರು. ಅವರು ನನ್ನನ್ನು ನನ್ನ ಲಿಂಗದ ಮೇಲೆ ಏಕೆ ನಿರ್ಣಯಿಸುತ್ತಿದ್ದಾರೆ ಮತ್ತು ನಾನು ಉತ್ತಮವಾಗಿರುವ ಇತರ ವಿಷಯಗಳಲ್ಲಿ ಅಲ್ಲ.",
              "te": "కంప్యూటర్‌ ల్యాబ్‌లో జరిగిన సంఘటన చాలా వ్యక్తిగతంగా అనిపించింది. నేను చాలా అవమానంగా భావించాను. వారు నా లింగాన్ని బట్టి నన్ను ఎందుకు అంచనా వేస్తున్నారు మరియు నేను మంచిగా ఉన్న ఇతర విషయాలను ఎందుకు చూడట్లేదు.",
              "or": "କମ୍ପ୍ୟୁଟର ଲ୍ୟାବ୍‌ରେ ଘଟିଥିବା ଘଟଣା ଏତେ ବ୍ୟକ୍ତିଗତ ଅନୁଭବ କଲା | ମୁଁ ଉଲ୍ଲଂଘନ ଅନୁଭବ କଲି | ସେମାନେ ଚାହୁଁଥିଲେ ମୁଁ ଲଜ୍ଜା ଅନୁଭବ କରେ | ସେମାନେ ମୋତେ କାହିଁକି ମୋର ଲିଙ୍ଗ ଉପରେ ବିଚାର କରୁଛନ୍ତି ଏବଂ ଅନ୍ୟ ଜିନିଷରେ ନୁହେଁ ଯେଉଁଥିରେ ମୁଁ ଭଲ ଅଟେ |",
              "as": "কম্পিউটাৰ লেবৰ ঘটনাটোৱে মোক ব্যক্তিগতভাবে বহুত দুখ দিছিল। মই ভাগি পৰিছিলো। তেওঁলোকে বিচাৰিছিল যে মই লাজ অনুভৱ কৰোঁ। তেওঁলোকে মোক মোৰ লিংগৰ ওপৰত কিয় বিচাৰ কৰি আছে আৰু মই কৰা আন ভাল কামবোৰৰ ওপৰত নহয়।",
              "gu": "કોમ્પ્યુટર લેબમાં બનેલી ઘટના બહુ અપમાનજનક લાગી.  મને આઘાત લાગ્યો.  તેઓ ઈચ્છતા હતા કે હું શરમનો અનુભવું કરું.  શા માટે તેઓ મને લિંગને લઈને  મારું મૂલ્યાંકન કરી રહ્યા છે અને અન્ય વસ્તુઓ કે જેમાં હું સારો  છું તેના દ્વારા નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "It’s easy for them to forget that we are also human beings with emotions, and they look at us like we are strange beings.",
              "hi": "उनके लिए यह भूलना सहज है कि हम मनुष्य हैं और हमारी भी भावनाएँ हैं, और वे हमें इस तरह देखते हैं जैसे हम अजीब प्राणी हैं।",
              "ka": "ನಾವು ಸಹ ಭಾವನೆಗಳನ್ನು ಹೊಂದಿರುವ ಮನುಷ್ಯರು ಎಂಬುದನ್ನು ಅವರು ಸುಲಭವಾಗಿ ಮರೆತುಬಿಡುತ್ತಾರೆ ಮತ್ತು ಅವರು ನಮ್ಮನ್ನು ವಿಚಿತ್ರ ಜೀವಿಗಳಂತೆ ನೋಡುತ್ತಾರೆ.",
              "te": "మనం కూడా భావోద్వేగాలున్న మనుషులమే అని మర్చిపోవడం వారికి చాలా తేలిక, మరియు వారు మనల్ని వింత జీవులలా చూస్తారు.",
              "or": "ତାଙ୍କ ପାଇଁ ଏହା ଭୁଲିବା ସହଜ ଯେ ଆମେ ମଧ୍ଯ ଭାବନା ଥିବା ସହିତ ମଣିଷ, ଏବଂ ସେମାନେ ଆମକୁ ଏହିପରି ଦେଖନ୍ତି ଯେମିତିକି ଆମେ ମାନେ ଅଜବ ପ୍ରାଣୀ |",
              "as": "তেওঁলোকৰ বাবে পাহৰি যোৱাটো সহজ যে আমিও আৱেগ থকা মানুহ, আৰু তেওঁলোকে আমাক এনেদৰে চাই যেন আমি অদ্ভুত জীৱ।",
              "gu": "તેમના માટે એ ભૂલી જવું સહેલું છે કે અમે પણ લાગણીઓવાળા માણસો છીએ, અને તેઓ અમને વિચિત્ર માણસોની જેમ જુએ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Thank you for sharing, everyone. Arvind, we support you and are proud of you for who you are.",
              "hi": "साझा करने के लिए, सबको धन्यवाद। अरविंद, आप जो हैं, हम आपका समर्थन करते हैं और आप पर गर्व करते हैं।",
              "ka": "ಎಲ್ಲರಿಗೂ, ಹಂಚಿಕೊಂಡಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ಅರವಿಂದ್, ನಾವು ನಿಮ್ಮನ್ನು ಬೆಂಬಲಿಸುತ್ತೇವೆ ಮತ್ತು ನಿಮ್ಮ ಬಗ್ಗೆ ಹೆಮ್ಮೆ ಪಡುತ್ತೇವೆ.",
              "te": "పంచుకున్నందుకు అందరికీ ధన్యవాదాలు. అరవింద్, మేము మీకు మద్దతు ఇస్తున్నాము మరియు మీరు ఎవరైనా మీ పట్ల గర్వంగా ఉంటాము.",
              "or": "ସମସ୍ତଙ୍କୁ ଅଂଶୀଦାର କରିଥିବାରୁ, ଧନ୍ୟବାଦ | ଅରବିନ୍ଦ, ଆମେ ତୁମକୁ ସମର୍ଥନ କରୁ ଏବଂ ତୁମେ ଯେମିତି ଅଛ ଆମେ ତୁମ ପାଇଁ ଗର୍ବିତ |",
              "as": "সকলোবোৰ কোৱাৰ বাবে আপোনাক ধন্যবাদ, । অৰবিন্দ, আমি আপোনাক সমৰ্থন কৰো আৰু আপুনি কোন তাক লৈ গৌৰৱান্বিত।",
              "gu": "દરેક સાથે શેર કરવા બદલ આભાર.  અરવિંદ, અમે તમારું સમર્થન કરીએ છીએ અને તમે જે છો તેના માટે અમને તમારા પર ગર્વ છે."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_4_3",
              "hi": "B2_4_3",
              "ka": "B2_4_3",
              "te": "B2_4_3",
              "or": "B2_4_3",
              "as": "B2_4_3",
              "gu": "B2_4_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The support group asks Arvind to start writing down how he feels every day or when he feels overwhelmed by the situation.",
              "hi": "सहायता समूह अरविंद को यह लिखना शुरू करने के लिए कहता है कि जब वह स्थिति से अभिभूत अनुभव करता है या वह हर दिन कैसा अनुभव करता है।",
              "ka": "ಸಪೋರ್ಟ್ ಗ್ರೂಪ್ ಅರವಿಂದ್ ಅವರಿಗೆ ಪ್ರತಿದಿನ ಹೇಗೆ ಅನಿಸುತ್ತದೆ ಅಥವಾ ಪರಿಸ್ಥಿತಿಯಿಂದ ಅವರು ಮುಳುಗಿದಾಗ ಹೇಗೆ ಅನಿಸುತ್ತದೆ ಎಂದು ಬರೆಯಲು ಪ್ರಾರಂಭಿಸಲು ಕೇಳುತ್ತದೆ.",
              "te": "సపోర్టు గ్రూప్ అరవింద్‌ని ప్రతిరోజూ అతను ఎలా భావిస్తున్నాడో లేదా అతను పరిస్థితిని చూసి కృంగిపోయినప్పుడు అవన్నీ రాయడం ప్రారంభించమని అడుగుతుంది.",
              "or": "ସମର୍ଥନ ଗୋଷ୍ଠୀ ଅରବିନ୍ଦଙ୍କୁ ପ୍ରତିଦିନ କିପରି ଅନୁଭବ କରେ କିମ୍ବା ଯେତେବେଳେ ସେ ପରିସ୍ଥିତିରେ ଅତିଷ୍ଠ ଅନୁଭବ କରନ୍ତି ତାହା ଲେଖିବା ଆରମ୍ଭ କରିବାକୁ କୁହନ୍ତି |",
              "as": "সমৰ্থন গোটে অৰবিন্দক প্ৰতিদিনে কেনে অনুভৱ কৰে বা যেতিয়া পৰিস্থিতিৰ দ্বাৰা অভিভূত অনুভৱ কৰে সেইবোৰ লিখিবলৈ কয়।",
              "gu": "સપોર્ટ ગ્રૂપ અરવિંદને દરરોજ કેવું અનુભવ કરે છે તે  અથવા જ્યારે તે પરિસ્થિતિ તેનાં પર હાવી થઈ જાય  ત્યારે તેનાં વિશે  લખવાનું શરૂ કરવા કહે છે."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_4_4",
              "hi": "B2_4_4",
              "ka": "B2_4_4",
              "te": "B2_4_4",
              "or": "B2_4_4",
              "as": "B2_4_4",
              "gu": "B2_4_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They also share the contacts he can reach out to if he wants professional help.",
              "hi": "वे उन संपर्कों को भी साझा करते हैं जिन तक वह पहुंच सकता है यदि उसे प्रोफेशनल सहायता की आवश्यकता पड़ती है।",
              "ka": "ಅವನು ವೃತ್ತಿಪರ ಸಹಾಯವನ್ನು ಬಯಸಿದರೆ ಅವರು ಸಂಪರ್ಕಿಸಬಹುದಾದ ಸಂಪರ್ಕಗಳನ್ನು ಸಹ ಹಂಚಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "అతనికి వృత్తిపరమైన సహాయం కావాలనుకుంటే కూడా అతను సంప్రదించగల కాంటాక్ట్స్ కూడా వారు అతనికి ఇస్తారు.",
              "or": "ସେମାନେ ଯୋଗାଯୋଗ ଗୁଡିକ ମଧ୍ଯ ଅଂଶୀଦାର କରନ୍ତି ସେ ପହଞ୍ଚିପାରିବ ଯଦିଓ ସେ ବୃତ୍ତିଗତ ସହାୟତା ଚାହେଁ |",
              "as": "যদি তেওঁ পেছাদাৰী সহায় বিচাৰে তেন্তে তেওঁলোকে তেওঁ কৰিব পৰা সম্পৰ্কবোৰো আলোচনা কৰে।",
              "gu": "જો તેને વ્યાવસાયિક મદદ જોઈતી હોય તો તેઓ તેનાં માટે સંપર્ક શેર કરે છે."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "B2_4_5",
              "hi": "B2_4_5",
              "ka": "B2_4_5",
              "te": "B2_4_5",
              "or": "B2_4_5",
              "as": "B2_4_5",
              "gu": "B2_4_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Having a place to share his experiences and listening to others gives Arvind the ability to cope with the harassment in college gradually. The support group gives him the space to be himself.",
              "hi": "अपने अनुभवों को साझा करने और दूसरों को सुनने के लिए एक जगह का होना अरविंद को धीरे-धीरे कॉलेज में उत्पीड़न का सामना करने की क्षमता प्रदान करता है। समर्थन समूह उसे अपने स्वयं के लिए एक स्थान देता है।",
              "ka": "ತನ್ನ ಅನುಭವಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ಮತ್ತು ಇತರರ ಮಾತುಗಳನ್ನು ಕೇಳಲು ಸ್ಥಳವನ್ನು ಹೊಂದಿರುವುದು ಅರವಿಂದ್‌ಗೆ ಕಾಲೇಜಿನಲ್ಲಿನ ಕಿರುಕುಳವನ್ನು ಕ್ರಮೇಣ ನಿಭಾಯಿಸುವ ಸಾಮರ್ಥ್ಯವನ್ನು ನೀಡುತ್ತದೆ. ಬೆಂಬಲ ಗುಂಪು ಅವನಿಗೆ ತಾನಾಗಿಯೇ ಇರಲು ಜಾಗವನ್ನು ನೀಡುತ್ತದೆ.",
              "te": "తన అనుభవాలను పంచుకోవడానికి మరియు ఇతరుల మాటలు వినడానికి ఒక స్థలాన్ని కలిగి ఉండటం వల్ల అరవింద్‌కు కాలేజీలో వేధింపులను క్రమంగా తట్టుకోగల సామర్థ్యం లభిస్తుంది. సపోర్టు గ్రూప్ అతనికి తనంతట తానుగా ఉండటానికి అవకాశం ఇస్తుంది.",
              "or": "ତାଙ୍କ ଅନୁଭୂତି ବାଣ୍ଟିବା ଏବଂ ଅନ୍ୟମାନଙ୍କ କଥା ଶୁଣିବା ପାଇଁ ଏକ ସ୍ଥାନ ରହିବା ଅରବିନ୍ଦଙ୍କୁ ଧୀରେ ଧୀରେ କଲେଜରେ ହେଉଥିବା ହଇରାଣର ମୁକାବିଲା କରିବାର କ୍ଷମତା ଦେଇଥାଏ | ସମର୍ଥନ ଗୋଷ୍ଠୀ ତାଙ୍କୁ ନିଜକୁ ପ୍ରକାଶ କରିବାକୁ ସ୍ଥାନ ଦେଇଥାଏ |",
              "as": "তেওঁৰ অভিজ্ঞতা ভাগ-বতৰা কৰিবলৈ আৰু আনৰ কথা শুনিবলৈ ঠাই পোৱাটোৱে অৰবিন্দক ক্ৰমান্বয়ে কলেজত উৎপীড়নৰ সৈতে মোকাবিলা কৰাৰ ক্ষমতা প্ৰদান কৰে। সমৰ্থন গোটে তেওঁক নিজে থাকিবলৈ ঠাই দিয়ে।",
              "gu": "પોતાના અનુભવો શેર કરવા અને અન્ય લોકોનાં વિચાર સાંભળવાનું સ્થળ મળવાથી અરવિંદમાં કોલેજમાં થતી હેરાનગતિનો ધીમે-ધીમે સામનો કરવાની ક્ષમતા આવી .  સપોર્ટ ગ્રૂપ તેને પોતાની ઓળખ જાળવી રાખવા  માટે છૂટ આપે છે."
            }
          }
        ]
      },
      "scene21": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_1_1",
              "hi": "B3_1_1",
              "ka": "B3_1_1",
              "te": "B3_1_1",
              "or": "B3_1_1",
              "as": "B3_1_1",
              "gu": "B3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind's parents are still worried about him continuing in the same college, and they speak to Pranav regarding their concerns.",
              "hi": "अरविंद के माता-पिता अभी भी अरविन्द को उसी कॉलेज में जारी रखने के बारे में चिंतित हैं, और वे अपनी चिंताओं के बारे में प्रणव से बात करते हैं।",
              "ka": "ಅರವಿಂದ್ ಅದೇ ಕಾಲೇಜಿನಲ್ಲಿ ಮುಂದುವರಿಯುವ ಬಗ್ಗೆ ಅವರ ಪೋಷಕರು ಇನ್ನೂ ಚಿಂತಿತರಾಗಿದ್ದಾರೆ ಮತ್ತು ಅವರು ತಮ್ಮ ಕಳವಳದ ಬಗ್ಗೆ ಪ್ರಣವ್ ಅವರೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾರೆ.",
              "te": "అరవింద్ అదే కాలేజీలో కొనసాగడం పట్ల అతని తల్లిదండ్రులు ఇప్పటికీ ఆందోళన చెందుతున్నారు మరియు వారు తమ ఆందోళనల గురించి ప్రణవ్‌తో మాట్లాడారు.",
              "or": "ଅରବିନ୍ଦଙ୍କ ପିତାମାତା ତାଙ୍କୁ ସେହି କଲେଜରେ ଅବ୍ୟାହତ ରଖିବା ପାଇଁ ଚିନ୍ତିତ ଅଛନ୍ତି ଏବଂ ସେମାନେ ସେମାନଙ୍କର ସମସ୍ୟା ବିଷୟରେ ପ୍ରଣବଙ୍କ ସହ କଥା ହୋଇଥାନ୍ତି |",
              "as": "অৰবিন্দৰ মাক-দেউতাক এতিয়াও তেওঁ একেখন কলেজতে থকাৰ বাবে চিন্তিত, আৰু তেওঁলোকে তেওঁলোকৰ উদ্বেগসম্পৰ্কে প্ৰণৱৰ সৈতে কথা পাতিছে।",
              "gu": "અરવિંદના માતા-પિતા હજુ પણ તેના એ જ કૉલેજમાં અભ્યાસ ચાલુ રાખવા વિશે ચિંતિત છે, અને તેઓ પોતાની ચિંતાઓ અંગે પ્રણવ સાથે વાત કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_1_2",
              "hi": "B3_1_2",
              "ka": "B3_1_2",
              "te": "B3_1_2",
              "or": "B3_1_2",
              "as": "B3_1_2",
              "gu": "B3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Pranav has not been involved in Arvind's life since he left for college. But when he hears about everything Arvind has been through, he feels bad about not being there.",
              "hi": "कॉलेज जाने के बाद से प्रणव अरविंद के जीवन  घनिष्ठ रूप से जुड़ा हुआ नहीं है। लेकिन जब वह सुनता है कि अरविंद किस परिस्थिति से गुजर रहा है, वह बेहद दुखी है।",
              "ka": "ಪ್ರಣವ್ ಕಾಲೇಜಿಗೆ ಹೋದಾಗಿನಿಂದ ಅರವಿಂದ್ ಜೀವನದಲ್ಲಿ ತೊಡಗಿಸಿಕೊಂಡಿಲ್ಲ. ಆದರೆ ಅರವಿಂದ್ ಅನುಭವಿಸಿದ ಎಲ್ಲದರ ಬಗ್ಗೆ ಕೇಳಿದಾಗ, ಅವರು ಇಲದ್ದಿದ್ದ ಬಗ್ಗೆ ಬೇಸರವಾಗುತ್ತದೆ.",
              "te": "కాలేజీకి వెళ్ళినప్పటి నుండి అరవింద్ జీవితంలో ప్రణవ్ ప్రమేయం లేదు. కానీ అరవింద్ అనుభవించిన విషయాలన్నీ విన్నప్పుడు, అతను అక్కడ లేడని బాధపడ్డాడు.",
              "or": "ପ୍ରଣବ କଲେଜ ଯିବା ପରଠାରୁ ଅରବିନ୍ଦଙ୍କ ଜୀବନରେ ଜଡିତ ନଥିଲେ। କିନ୍ତୁ ଯେତେବେଳେ ସେ ଅରବିନ୍ଦଙ୍କ ବିଷୟରେ ସବୁକିଛି ଶୁଣନ୍ତି, ସେଠାରେ ନ ଥିବାରୁ ସେ ଖରାପ ଅନୁଭବ କରନ୍ତି |",
              "as": "কলেজলৈ যোৱাৰ পিছৰে পৰা প্ৰণৱ অৰবিন্দৰ জীৱনৰ সৈতে জড়িত হোৱা নাই। কিন্তু যেতিয়া তেওঁ অৰবিন্দৰ সকলোবোৰ কথা শুনিছিল, তেওঁ তাত থাকিব নোৱাৰাৰ বাবে দুখ কৰিচিল।",
              "gu": "પ્રણવ કોલેજમાં ગયો લાગ્યો ત્યારથી અરવિંદના જીવનથી દૂર હતો .  પરંતુ જ્યારે તે સાંભળે છે કે અરવિંદ મોટી મુશ્કેલનો સામનો કરી રહ્યો છે, ત્યારે તેને  ખરાબ લાગે છે કે પોતે આ સમયે અરવિંદની જોડે નથી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_1_3",
              "hi": "B3_1_3",
              "ka": "B3_1_3",
              "te": "B3_1_3",
              "or": "B3_1_3",
              "as": "B3_1_3",
              "gu": "B3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Pranav is angry with himself for treating Arvind poorly while in school and not being in touch with him after moving out of the house. He decides to talk to Arvind.",
              "hi": "प्रणव स्कूल में रहते हुए अरविन्द के साथ किये गए बुरे व्यवहार और घर छोड़ने के बाद उसके संपर्क में न रहने के लिए खुद से नाराज है । वह अरविंद से बात करने का निश्चय करता है।",
              "ka": "ಶಾಲೆಯಲ್ಲಿ ಓದುತ್ತಿರುವಾಗ ಅರವಿಂದ್ ನನ್ನು ಕೆಟ್ಟದಾಗಿ ನಡೆಸಿಕೊಂಡಿದ್ದು, ಮನೆಯಿಂದ ಹೊರಗೆ ಹೋದ ನಂತರ ಅವನೊಂದಿಗೆ ಸಂಪರ್ಕದಲ್ಲಿಲ್ಲ ಎಂದು ಪ್ರಣವ್ ತನ್ನ ಮೇಲೆಯೇ ಕೋಪಗೊಂಡಿದ್ದಾನೆ. ಅರವಿಂದ್ ಜೊತೆ ಮಾತನಾಡಲು ನಿರ್ಧರಿಸಿದರು.",
              "te": "స్కూల్‌లో చదువుతున్నప్పుడు అరవింద్‌తో హీనంగా ప్రవర్తించాడని, ఇంట్లో నుంచి వెళ్లిన తర్వాత అతనితో టచ్‌లో లేడని ప్రణవ్‌కి తనపైనే కోపం వచ్చింది. అరవింద్‌తో మాట్లాడాలని నిర్ణయించుకున్నాడు.",
              "or": "ବିଦ୍ୟାଳୟରେ ଅରବିନ୍ଦଙ୍କୁ ଖରାପ ବ୍ୟବହାର କରିବା ଏବଂ ଘରୁ ବାହାରକୁ ଯିବା ପରେ ତାଙ୍କ ସହ ସମ୍ପର୍କ ନ ଥିବାରୁ ପ୍ରଣବ ନିଜ ଉପରେ ରାଗିଛନ୍ତି। ସେ ଅରବିନ୍ଦଙ୍କ ସହ କଥା ହେବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି।",
              "as": "স্কুলত থাকোঁতে অৰবিন্দৰ সৈতে দুৰ্বল আচৰণ কৰা আৰু ঘৰৰ বাহিৰলৈ যোৱাৰ পিছত তেওঁৰ সৈতে যোগাযোগ নকৰাৰ বাবে প্ৰণৱৰ নিজৰ ওপৰত খং উঠিছে। তেওঁ অৰবিন্দৰ সৈতে কথা পাতিবলৈ সিদ্ধান্ত লয়।",
              "gu": "પ્રણવ શાળામાં અરવિંદ સાથે ખરાબ વર્તન કરવા અને ઘરની બહાર ગયા પછી તેની સાથે સંપર્કમાં ન રહેવા બદલ પોતાને કોસે છે.  તેણે અરવિંદ સાથે વાત કરવાનું નક્કી કર્યું."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_1_5//",
              "hi": "B3_1_5 //",
              "ka": "B3_1_5//",
              "te": "B3_1_5//",
              "or": "B3_1_5//",
              "as": "B3_1_5//",
              "gu": "B3_1_5//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Pranav",
              "hi": "प्रणव",
              "ka": "ಪ್ರಣವ್",
              "te": "ప్రణవ్",
              "or": "ପ୍ରଣବ",
              "as": "প্ৰণৱ",
              "gu": "પ્રણવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "This is unfair, Arvind. How could they do this to you? I heard you aren’t even continuing with engineering anymore. We must go file a complaint against them.",
              "hi": "यह अनुचित है, अरविंद। वे आपके साथ ऐसा कैसे कर सकते हैं? मैंने सुना है कि अब आप इंजीनियरिंग भी नहीं कर रहे हैं। हमें उनके खिलाफ शिकायत दर्ज करनी होगी।",
              "ka": "ಇದು ಅನ್ಯಾಯ ಅರವಿಂದ್. ಅವರು ನಿನಗೆ ಇದನ್ನು ಹೇಗೆ ಮಾಡಬಹುದು? ನೀನು ಇನ್ನು ಮುಂದೆ ಇಂಜಿನಿಯರಿಂಗ್‌ನಲ್ಲಿ ಮುಂದುವರಿಯುತ್ತಿಲ್ಲ ಎಂದು ನಾನು ಕೇಳಿದೆ. ನಾವು ಅವರ ವಿರುದ್ಧ ದೂರು ದಾಖಲಿಸಲು ಹೋಗಬೇಕು.",
              "te": "ఇది అన్యాయం అరవింద్. వారు నిన్ను ఇలా ఎలా చేయగలరు? నువ్వు ఇంజినీరింగ్‌లో కూడా కొనసాగడం లేదని విన్నాను. మనం వెళ్లి వారిపై ఫిర్యాదు చేయాలి.",
              "or": "ଏହା ଅନ୍ୟାୟ, ଅରବିନ୍ଦ | ସେମାନେ ତୁମକୁ ଏହା କିପରି କରିପାରିବେ ? ମୁଁ ଶୁଣିଛି ତୁମେ ଆଉ ଇଞ୍ଜିନିୟରିଂ ଜାରି ରଖୁନାହଁ | ଆମେ ସେମାନଙ୍କ ବିରୋଧରେ ଏକ ଅଭିଯୋଗ ଦାଖଲ କରିବାକୁ ଯିବା ଜରୁରୀ |",
              "as": "এয়া অন্যায়, অৰবিন্দ। তেওঁলোকে আপোনাৰ সৈতে কেনেকৈ এনে কৰিব পাৰে? মই শুনিছিলো যে আপুনি আনকি এতিয়া অভিযান্ত্ৰিকত পঢ়ি থকা নাই। আমি তেওঁলোকৰ বিৰুদ্ধে অভিযোগ দাখিল কৰিব লাগিব।",
              "gu": "આ ઠીક નથી, અરવિંદ.  તેઓ તારી સાથે આવું કી રીતે કરી શકે?  મેં સાંભળ્યું છે કે તૂં હવે એન્જિનિયરિંગનો અભ્યાસ ચાલુ રાખવા માંગતો નથી.  આપણે તેમની સામે ફરિયાદ નોંધાવવી જોઈએ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Pranav, there’s nothing much you can do. And even if you stop these boys from harassing me, someone else will.",
              "hi": "प्रणव, आप कुछ भी नहीं कर सकते हैं। और यहां तक ​​कि यदि आप इन लड़कों को परेशान करने से रोकते हैं, तो कोई और करेगा।",
              "ka": "ಪ್ರಣವ್, ನಿನ್ನಿಂದ ಹೆಚ್ಚೇನೂ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ. ಮತ್ತು ಈ ಹುಡುಗರು ನನಗೆ ಕಿರುಕುಳ ನೀಡುವುದನ್ನು ನೀನು ನಿಲ್ಲಿಸಿದರೂ, ಬೇರೆಯವರು ಮಾಡುತ್ತಾರೆ.",
              "te": "ప్రణవ్, నువ్వు చేయగలిగేది పెద్దగా ఏమీ లేదు. ఈ అబ్బాయిలు నన్ను వేధించకుండా నువ్వు ఆపినా కూడా, ఇంకొకరు చేస్తారు.",
              "or": "ପ୍ରଣବ, ତୁମେ ଅଧିକ କିଛି କରିପାରିବ ନାହିଁ | ଏବଂ ଯଦି ତୁମେ ଏହି ପୁଅମାନଙ୍କୁ ମୋତେ ହଇରାଣ କରିବାକୁ ଅଟକାଇବ, ଅନ୍ୟ କେହି କରିବେ |",
              "as": "প্ৰণৱ, আপুনি বিশেষ একো কৰিব নোৱাৰে। আৰু আপুনি এই ল'ৰাবোৰক মোক হাৰাশাস্তি কৰাত বাধা দিলেও, আন কোনোবাই কৰিব।",
              "gu": "પ્રણવ, તૂં આમાં કંઈ નહીં કરી શકે.  અને જો તૂં આ છોકરાઓને મને હેરાન કરતા અટકાવશ તો  બીજું કોઈ શરૂ કરશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_1_6////",
              "hi": "B3_1_6 ///////////////",
              "ka": "B3_1_6////",
              "te": "B3_1_6////",
              "or": "B3_1_6////",
              "as": "B3_1_6////",
              "gu": "B3_1_6////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I’m sorry, Arvind, that you have had to go through this.",
              "hi": "मुझे खेद है, अरविंद, कि तुमको इन समस्याओं से गुज़रना पड़ा है।",
              "ka": "ಕ್ಷಮಿಸು, ಅರವಿಂದ್. ನೀನು ಇದನ್ನೆಲ್ಲಾ ನೋಡಬೇಕಾಗಿತ್ತು",
              "te": "సారీ, అరవింద్, నువ్వు చాలా అనుభవించావు.",
              "or": "ମୁଁ ଦୁଃଖିତ, ଅରବିନ୍ଦ, ଯେ ତୁମକୁ ଏହା ଦେଇ ଯିବାକୁ ପଡିଲା |",
              "as": "মই দুঃখিত, অৰবিন্দ, তুমি এইবোৰৰ মাজেৰে দিন পাৰ কৰিবলগীয়া হৈছিল।",
              "gu": "મને દુખ થાય છે, અરવિંદ, કે તારે આવી  સ્થિતિમાંથી પસાર થવું પડ્યું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "It’s okay. I’m used to it now. I still feel sad and scared, but I have a fantastic support group where people understand me and care about me. I’m doing much better.",
              "hi": "ठीक है। अब मुझे इसकी आदत है। मैं अभी भी दुखी हूं और डरता हूं, लेकिन मेरे पास एक शानदार समर्थन समूह है जहां लोग मुझे समझते हैं और मेरी परवाह करते हैं। मैं बहुत बेहतर कर रहा हूं।",
              "ka": "ಪರವಾಗಿಲ್ಲ. ನಾನು ಈಗ ಅದನ್ನು ಅಭ್ಯಾಸ ಮಾಡಿದ್ದೇನೆ. ನಾನು ಇನ್ನೂ ದುಃಖ ಮತ್ತು ಭಯಪಡುತ್ತೇನೆ, ಆದರೆ ಜನರು ನನ್ನನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವ ಮತ್ತು ನನ್ನ ಬಗ್ಗೆ ಕಾಳಜಿ ವಹಿಸುವ ಅದ್ಭುತ ಬೆಂಬಲ ಗುಂಪನ್ನು ನಾನು ಹೊಂದಿದ್ದೇನೆ. ನಾನು ಹೆಚ್ಚು ಉತ್ತಮವಾಗಿ ಮಾಡುತ್ತಿದ್ದೇನೆ.",
              "te": "ఇది ఓకే. నేను ఇప్పుడు అలవాటు పడ్డాను. నేను ఇప్పటికీ విచారంగా ఉన్నాను మరియు భయపడుతున్నాను, కానీ నన్ను అర్థం చేసుకునే మరియు నా పట్ల శ్రద్ధ వహించే అద్భుతమైన సపోర్ట్ గ్రూప్ నాకు ఉంది. నేను ఇప్పుడు చాలా బాగున్నాను.",
              "or": "ଠିକ ଅଛି। ମୁଁ ବର୍ତ୍ତମାନ ଏଥିରେ ଅଭ୍ୟସ୍ତ | ମୁଁ ତଥାପି ଦୁଃଖିତ ଏବଂ ଭୟଭୀତ ଅନୁଭବ କରୁଛି, କିନ୍ତୁ ମୋର ଏକ ଉତକୃଷ୍ଟ ସମର୍ଥନ ସମୁଦାୟ ଅଛି ଯେଉଁଠାରେ ଲୋକମାନେ ମୋତେ ବୁଝନ୍ତି ଏବଂ ମୋ ବିଷୟରେ ଚିନ୍ତା କରନ୍ତି | ମୁଁ ବହୁତ ଭଲ କରୁଛି |",
              "as": "ঠিক আছে। মই এতিয়া ইয়াত অভ্যস্ত। মই এতিয়াও দুখ আৰু ভয় অনুভৱ কৰোঁ, কিন্তু মোৰ এটা চমৎকাৰ সমৰ্থন গোট আছে য'ত মানুহে মোক বুজি পায় আৰু মোৰ বিষয়ে চিন্তা কৰে। মই বহুত ভাল কৰি আছো।",
              "gu": "વાંધો નહીં.  મને હવે તેની આદત પડી ગઈ છે.  હું હજુ પણ ઉદાસી અને ડરનો અનુભવુ કરું છું, પરંતુ મારી પાસે એક અદભૂત સપોર્ટ ગ્રુપ છે જ્યાં લોકો મને સમજે છે અને મારી કાળજી રાખે છે.  મને ઘણું સારું લાગી રહ્યું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I wish I could have been there for you. I feel like I have been silently watching you get bullied since we were in school, and I wasn’t brave enough to stand up for you.",
              "hi": "काश मैं तुम्हारे लिए वहां होता। मुझे ऐसा लगता है कि जब से हम स्कूल में थे, तब से मैं चुपचाप  ऐसे लड़कों द्वारा तुमको धमकाते हुए देख रहा हूं, और मैं इतना बहादुर नहीं था कि मैं तुम्हारे लिए खड़ा हो सकूं।",
              "ka": "ನಾನು ನಿನಗಾಗಿ ಅಲ್ಲಿರಬಹುದೆಂದು ನಾನು ಬಯಸುತ್ತೇನೆ. ನಾವು ಶಾಲೆಯಲ್ಲಿದ್ದಾಗಿನಿಂದ ನೀನು ಹಿಂಸೆಗೆ ಒಳಗಾಗುವುದನ್ನು ನಾನು ಮೌನವಾಗಿ ನೋಡುತ್ತಿದ್ದೇನೆ ಮತ್ತು ನಿನ್ನ ಪರವಾಗಿ ನಿಲ್ಲುವಷ್ಟು ಧೈರ್ಯವಿರಲಿಲ್ಲ.",
              "te": "నేను నీ కోసం అక్కడ ఉంటే బాగుండు అనుకుంటున్నాను. మనము స్కూల్‌లో ఉన్నప్పటి నుండి నువ్వు వేధింపులకు గురికావడాన్ని నేను మౌనంగా చూస్తూనే ఉన్నాను కానీ నీ కోసం నిలబడేంత ధైర్యం నాకు లేదు.",
              "or": "ମୋ ଇଚ୍ଛା ମୁଁ ତୁମ ପାଇଁ ସେଠାରେ ଥାନ୍ତି | ମୁଁ ଅନୁଭବ କରୁଛି ଯେ ଆମେ ସ୍କୁଲରେ ପଢିବା ଦିନଠାରୁ ତୁମକୁ ଗାଳିଗୁଲଜ ହେଉଥିବାର ନୀରବରେ ଦେଖୁଥିଲି, ଏବଂ ମୁଁ ତୁମ ପାଇଁ ଠିଆ ହେବା ପାଇଁ ୟଥେଷ୍ଟ ସାହସୀ ନଥିଲି |",
              "as": "মই যদি তোমাৰ বাবে তাত থাকিব পাৰিলোহেঁতেন। মই অনুভৱ কৰোঁ যে আমি স্কুলত পঢ়ি থকাৰ পৰাই তোমাক উৎপীড়ন কৰা দেখি আছোঁ, আৰু মই তোমাৰ হৈ থিয় হ'বলৈ যথেষ্ট সাহসী নাছিলো।",
              "gu": "માને ઈચ્છું થાય છે કે હું  ત્યાં તારી મદદ માટે હોત તો.  મને એવું લાગે છે કે આપણે શાળામાં હતા ત્યારથી હું ચુપચાપ તારા પર થતી ગઉશ્કેરીઓ જોઈ રહ્યો હતો, અને હું એટલો બહાદુર નહતો કે તારા પક્ષમાં ઊભો રહું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "I always wished you didn’t treat me like an embarrassment. But I am glad you understand now.",
              "hi": "मैं हमेशा कामना करता था कि तुमने मेरे साथ शर्मिंदगी की तरह व्यवहार न किया होता। लेकिन मुझे खुशी है कि आप अब समझते हैं।",
              "ka": "ನೀನು ನನ್ನನ್ನು ಮುಜುಗರದ ರೀತಿಯಲ್ಲಿ ನಡೆಸಿಕೊಳ್ಳಬಾರದೆಂದು ನಾನು ಯಾವಾಗಲೂ ಬಯಸುತ್ತಿದ್ದೆ. ಆದರೆ ನೀನು ಈಗ ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೀಯ ಎಂದು ನನಗೆ ಖುಷಿಯಾಗಿದೆ.",
              "te": "నువ్వు నన్ను ఇబ్బందిగా చూడకూడదని నేను ఎప్పుడూ కోరుకునేవాన్ని. కానీ నువ్వు ఇప్పుడు అర్థం చేసుకున్నందుకు నేను సంతోషిస్తున్నాను.",
              "or": "ମୁଁ ସବୁବେଳେ ଇଚ୍ଛା କରେ ତୁମେ ମୋତେ ଏକ ଲଜ୍ଜାଜନକ ପରି ବ୍ୟବହାର କର ନାହିଁ | କିନ୍ତୁ ମୁଁ ଖୁସି ତୁମେ ବର୍ତ୍ତମାନ ବୁଝିଛ |",
              "as": "মই সদায় আশা কৰিছিলো যে আপুনি মোক লজ্জ্বাজনক ব্যক্তিৰ দৰে ব্যৱহাৰ নকৰে। কিন্তু মই সুখী যে আপুনি এতিয়া বুজি পাইছে।",
              "gu": "હું હંમેશા ઈચ્છતો હતો કે તૂં મારી સાથે શરમનો  અનુભવ ન કર.  પણ મને ખુશી છે કે હવે તૂં મને સમજો છો."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_1_7",
              "hi": "B3_1_7",
              "ka": "B3_1_7",
              "te": "B3_1_7",
              "or": "B3_1_7",
              "as": "B3_1_7",
              "gu": "B3_1_7"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Pranav and Arvind's relationship slowly begins to improve. Pranav starts visiting Arvind more often.",
              "hi": "प्रणव और अरविंद के रिश्ते धीरे-धीरे ठीक होने लगते हैं। प्रणव उससे अधिक बार मिलने लगता है और कभी-कभी अरविंद के साथ जाता है जब वह सुरक्षित अनुभव करने के लिए बाहर जाना चाहता है।",
              "ka": "ಪ್ರಣವ್ ಮತ್ತು ಅರವಿಂದ್ ಸಂಬಂಧವು ನಿಧಾನವಾಗಿ ಸುಧಾರಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತದೆ. ಪ್ರಣವ್ ಅರವಿಂದನನ್ನು ಹೆಚ್ಚಾಗಿ ಭೇಟಿ ಮಾಡಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ.",
              "te": "ప్రణవ్ మరియు అరవింద్ మధ్య సంబంధం మెల్లగా మెరుగుపడటం ప్రారంభమవుతుంది. ప్రణవ్ అరవింద్‌ని తరచుగా కలవడం ప్రారంభించాడు.",
              "or": "ପ୍ରଣବ ଏବଂ ଅରବିନ୍ଦଙ୍କ ସମ୍ପର୍କ ଧୀରେ ଧୀରେ ଉନ୍ନତ ହେବାକୁ ଲାଗିଲା | ପ୍ରଣବ ଅରବିନ୍ଦଙ୍କୁ ପ୍ରାୟ ପରିଦର୍ଶନ କରିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "প্ৰণৱ আৰু অৰবিন্দৰ সম্পৰ্ক লাহে লাহে উন্নত হ'বলৈ আৰম্ভ কৰে। প্ৰণৱে অৰবিন্দক সঘনাই লগ কৰিবলৈ আৰম্ভ কৰে।",
              "gu": "પ્રણવ અને અરવિંદના સંબંધો ધીમે ધીમે સુધરવા માંડ્યા.  પ્રણવ અવાર નવાર અરવિંદને મળવા જવા લાગ્યો."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_2_1",
              "hi": "B3_2_1",
              "ka": "B3_2_1",
              "te": "B3_2_1",
              "or": "B3_2_1",
              "as": "B3_2_1",
              "gu": "B3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind feels more confident and starts concentrating on his studies due to the support from his family and the support group.",
              "hi": "अपने परिवार और समर्थन समूह की सहायता से, अरविंद अध्ययन करने के लिए पर्याप्त आत्मविश्वास अनुभव कर रहा है।",
              "ka": "ಅರವಿಂದ್ ಹೆಚ್ಚು ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಅನುಭವಿಸುತ್ತಾನೆ ಮತ್ತು ಅವನ ಕುಟುಂಬ ಮತ್ತು ಬೆಂಬಲ ಗುಂಪಿನ ಬೆಂಬಲದಿಂದಾಗಿ ತನ್ನ ಅಧ್ಯಯನದ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ.",
              "te": "అరవింద్ మరింత ఆత్మవిశ్వాసంతో ఉన్నాడు మరియు అతని కుటుంబం మరియు సపోర్ట్ గ్రూప్ నుండి వచ్చిన మద్దతు కారణంగా తన చదువుపై దృష్టి పెట్టడం ప్రారంభించాడు.",
              "or": "ଅରବିନ୍ଦ ଅଧିକ ଆତ୍ମବିଶ୍ୱାସୀ ଅନୁଭବ କରନ୍ତି ଏବଂ ତାଙ୍କ ପରିବାର ଏବଂ ସମର୍ଥନ ଗୋଷ୍ଠୀର ସମର୍ଥନ ହେତୁ ତାଙ୍କ ଅଧ୍ୟୟନରେ ଧ୍ୟାନ ଦେବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "অৰবিন্দে অধিক আত্মবিশ্বাসী অনুভৱ কৰে আৰু তেওঁৰ পৰিয়াল আৰু সমৰ্থন গোটৰ সমৰ্থনৰ বাবে তেওঁৰ পঢ়া-শুনাত মনোযোগ দিবলৈ আৰম্ভ কৰে।",
              "gu": "અરવિંદ વધુ આત્મવિશ્વાસનો અનુભવે છે અને પોતાના પરિવાર અને સપોર્ટ ગ્રૂપના સમર્થનને કારણે પોતાના અભ્યાસ પર ધ્યાન આપવાનું શરૂ કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_2_2",
              "hi": "B3_2_2",
              "ka": "B3_2_2",
              "te": "B3_2_2",
              "or": "B3_2_2",
              "as": "B3_2_2",
              "gu": "B3_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He starts learning different programming languages and practices developing applications and other functions.",
              "hi": "वह विभिन्न प्रोग्रामिंग भाषाओं और अनुप्रयोगों और अन्य कार्यों के विकास को सीखना शुरू कर देता है।",
              "ka": "ಅವರು ವಿವಿಧ ಪ್ರೋಗ್ರಾಮಿಂಗ್ ಭಾಷೆಗಳನ್ನು ಕಲಿಯಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ ಮತ್ತು ಅಪ್ಲಿಕೇಶನ್‌ಗಳು ಮತ್ತು ಇತರ ಕಾರ್ಯಗಳನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸುವುದನ್ನು ಅಭ್ಯಾಸ ಮಾಡುತ್ತಾನೆ.",
              "te": "అతను వివిధ ప్రోగ్రామింగ్ భాషలను నేర్చుకోవడం ప్రారంభించాడు మరియు అప్లికేషన్లు మరియు ఇతర విధులను అభివృద్ధి చేయడం నేర్చుకుంటాడు.",
              "or": "ସେ ବିଭିନ୍ନ ପ୍ରୋଗ୍ରାମିଂ ଭାଷା ଶିଖିବା ଆରମ୍ଭ କରନ୍ତି ଏବଂ ଅନ୍ୟାନ୍ୟ କାର୍ଯ୍ୟଗୁଡ଼ିକର ବିକାଶ ଅଭ୍ୟାସ କରନ୍ତି |",
              "as": "তেওঁ বিভিন্ন প্ৰগ্ৰামিং ভাষা শিকিবলৈ আৰম্ভ কৰে আৰু এপ্লিকেচন আৰু অন্যান্য কাৰ্যবোৰ উন্নত কৰাৰ অনুশীলন কৰে।",
              "gu": "તે વિવિધ પ્રોગ્રામિંગ લાન્વેજસ શીખવાનું શરૂ કરે છે અને એપ્લિકેશન અને અન્ય ફન્ક્શન્સ વિકસાવવાની પ્રેક્ટિસ કરે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_2_3",
              "hi": "B3_2_3",
              "ka": "B3_2_3",
              "te": "B3_2_3",
              "or": "B3_2_3",
              "as": "B3_2_3",
              "gu": "B3_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He looks at how people are building these apps and how technology makes life easy for others.",
              "hi": "वह देखता है कि लोग इन ऐप्स का निर्माण कैसे कर रहे हैं और तकनीक दूसरों के लिए जीवन को कैसे सहज बनाती है।",
              "ka": "ಜನರು ಈ ಅಪ್ಲಿಕೇಶನ್‌ಗಳನ್ನು ಹೇಗೆ ನಿರ್ಮಿಸುತ್ತಿದ್ದಾರೆ ಮತ್ತು ತಂತ್ರಜ್ಞಾನವು ಇತರರಿಗೆ ಜೀವನವನ್ನು ಹೇಗೆ ಸುಲಭಗೊಳಿಸುತ್ತದೆ ಎಂಬುದನ್ನು ಅವನು ನೋಡುತ್ತಾನೆ.",
              "te": "వ్యక్తులు ఈ యాప్‌లను ఎలా రూపొందిస్తున్నారు మరియు టెక్నాలజీ ఇతరుల జీవితాన్ని ఎలా సులభతరం చేస్తుందో అతను చూస్తాడు.",
              "or": "ଲୋକମାନେ କିପରି ଏହି ଆପ୍ସ ନିର୍ମାଣ କରୁଛନ୍ତି ଏବଂ ଟେକ୍ନୋଲୋଜି ଅନ୍ୟମାନଙ୍କ ପାଇଁ ଜୀବନକୁ କିପରି ସହଜ କରିଥାଏ ସେ ଦେଖନ୍ତି |",
              "as": "তেওঁ চায় যে মানুহে কেনেকৈ এই এপবোৰ নিৰ্মাণ কৰি আছে আৰু প্ৰযুক্তিয়ে কেনেকৈ আনৰ বাবে জীৱন সহজ কৰি তোলিছে।",
              "gu": "તે જુએ છે કે લોકો આ એપ્સ કેવી રીતે બનાવી રહ્યા છે અને કેવી રીતે ટેક્નોલોજી અન્ય લોકોનું જીવન સરળ બનાવે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_2_4",
              "hi": "B3_2_4",
              "ka": "B3_2_4",
              "te": "B3_2_4",
              "or": "B3_2_4",
              "as": "B3_2_4",
              "gu": "B3_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He starts exploring different apps, and by the time he is in his final year, he works on a language learning app.",
              "hi": "वह विभिन्न ऐप्स की खोज शुरू कर देता है, और जब तक वह अपने अंतिम वर्ष में होता है, वह एक भाषा सीखने वाले ऐप पर काम करता है।",
              "ka": "ಅವನು ವಿವಿಧ ಅಪ್ಲಿಕೇಶನ್‌ಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಪ್ರಾರಂಭಿಸುತ್ತಾನೆ ಮತ್ತು ಅವನು ತಮ್ಮ ಅಂತಿಮ ವರ್ಷದಲ್ಲಿದ್ದಾಗ, ಭಾಷಾ ಕಲಿಕೆಯ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಾನೆ.",
              "te": "అతను వివిధ యాప్‌లను అన్వేషించడం ప్రారంభించాడు మరియు అతను తన చివరి సంవత్సరంలో ఉన్న సమయానికి, అతను లాంగ్వేజ్ లెర్నింగ్ యాప్‌లో పని చేస్తాడు.",
              "or": "ସେ ବିଭିନ୍ନ ଆପ୍ ଅନ୍ୱେଷଣ କରିବା ଆରମ୍ଭ କରନ୍ତି, ଏବଂ ତାଙ୍କର ଶେଷ ବର୍ଷରେ, ସେ ଏକ ଭାଷା ଶିକ୍ଷା ଆପରେ କାର୍ଯ୍ୟ କରନ୍ତି |",
              "as": "তেওঁ বিভিন্ন এপ অনুসন্ধান কৰা আৰম্ভ কৰে, আৰু তেওঁ শেষৰ বছৰত থকালৈকে, তেওঁ এটা ভাষা শিক্ষণ এপত কাম কৰে।",
              "gu": "તે અલગ-અલગ એપ્લિકેશન્સ શોધવાનું શરૂ કરે છે, અને જ્યારે તે તેના અંતિમ વર્ષમાં હોય છે, ત્યારે તે ભાષા શીખવાની એપ્લિકેશન પર કામ કરે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_3_1",
              "hi": "B3_3_1",
              "ka": "B3_3_1",
              "te": "B3_3_1",
              "or": "B3_3_1",
              "as": "B3_3_1",
              "gu": "B3_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He presents this app to a panel of teachers and guests from the industry who are more impressed with how he presents his solution than the solution itself.",
              "hi": "वह इस ऐप को इंडस्ट्री के शिक्षकों और मेहमानों के एक पैनल में प्रस्तुत करता है, जो इस बात से अधिक प्रभावित हैं कि वह समाधान की तुलना में उसका समाधान कैसे प्रस्तुत करता है।",
              "ka": "ಅವನು ಈ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಉದ್ಯಮದ ಶಿಕ್ಷಕರು ಮತ್ತು ಅತಿಥಿಗಳ ಸಮಿತಿಗೆ ಪ್ರಸ್ತುತಪಡಿಸುತ್ತಾನೆ, ಅವರು ಪರಿಹಾರಕ್ಕಿಂತ ತನ್ನ ಪರಿಹಾರವನ್ನು ಹೇಗೆ ಪ್ರಸ್ತುತಪಡಿಸುತ್ತಾರೆ ಎಂಬುದರ ಕುರಿತು ಹೆಚ್ಚು ಪ್ರಭಾವಿತರಾಗಿದ್ದಾರೆ.",
              "te": "అతను ఈ యాప్‌ని పరిశ్రమకు చెందిన ఉపాధ్యాయులు మరియు అతిథుల ప్యానెల్‌కు అందజేస్తాడు, వారు పరిష్కారం కంటే తన పరిష్కారాన్ని ప్రదర్శించిన దానితో ఎక్కువ ఇంప్రెస్ అయ్యారు.",
              "or": "ସେ ଏହି ଆପକୁ ଇଣ୍ଡଷ୍ଟ୍ରିର ଶିକ୍ଷକ ଏବଂ ଅତିଥିମାନଙ୍କ ଏକ ପ୍ୟାନେଲରେ ଉପସ୍ଥାପନ କରନ୍ତି, ଯେଉଁମାନେ ସମାଧାନର ସମାଧାନ ଅପେକ୍ଷା ସେ କିପରି ନିଜର ସମାଧାନ ଉପସ୍ଥାପନ କରନ୍ତି ସେଥିରେ ଅଧିକ ପ୍ରଭାବିତ ହୁଅନ୍ତି |",
              "as": "তেওঁ এই এপটো উদ্যোগটোৰ শিক্ষক আৰু অতিথিৰ এটা পেনেলৰ ওচৰত উপস্থাপন কৰে যিসকলে নিজে সমাধান কৰাতকৈ তেওঁ কেনেকৈ তেওঁৰ সমাধান উপস্থাপন কৰে তাক লৈ অধিক প্ৰভাৱিত হয়।",
              "gu": "તે આ એપને શિક્ષકો અને ઉદ્યોગના મહેમાનોની પેનલ સમક્ષ રજૂ કરે છે જેઓ તેના ઉકેલને બદલે તેના ઉકેલને કેવી રીતે રજૂ કરે છે તેનાથી વધુ પ્રભાવિત થાય છે."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_3_1b",
              "hi": "B3_3_1B",
              "ka": "B3_3_1b",
              "te": "B3_3_1b",
              "or": "B3_3_1b",
              "as": "B3_3_1b",
              "gu": "B3_3_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The next day he receives an email.",
              "hi": "अगले दिन वह एक ईमेल प्राप्त करता है।",
              "ka": "ಮರುದಿನ ಅವರು ಇಮೇಲ್ ಸ್ವೀಕರಿಸುತ್ತಾನೆ.",
              "te": "మరుసటి రోజు అతనికి ఇమెయిల్ వస్తుంది.",
              "or": "ପରଦିନ ସେ ଏକ ଇମେଲ୍ ଗ୍ରହଣ କରନ୍ତି |",
              "as": "পিছদিনা তেওঁ এটা ইমেইল পায়।",
              "gu": "બીજા દિવસે તેને એક ઈમેલ મળે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_3_2_email",
              "hi": "B3_3_2_email",
              "ka": "B3_3_2_email",
              "te": "B3_3_2_ఇమెయిల్",
              "or": "B3_3_2_email",
              "as": "B3_3_2_email",
              "gu": "B3_3_2_email"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailTo",
            "value": {
              "en": "surekha@metalab.in",
              "hi": "Surekha@metalab.in",
              "ka": "surekha@metalab.in",
              "te": "surekha@metalab.in",
              "or": "surekha@metalab.in",
              "as": "surekha@metalab.in",
              "gu": "surekha@metalab.in"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailSubject",
            "value": {
              "en": "Call for job interview",
              "hi": "नौकरी साक्षात्कार के लिए कॉल करें",
              "ka": "ಉದ್ಯೋಗ ಸಂದರ್ಶನಕ್ಕೆ ಕರೆ",
              "te": "ఉద్యోగ ఇంటర్వ్యూ కోసం కాల్",
              "or": "ଚାକିରି ସାକ୍ଷାତକାର ପାଇଁ କଲ୍ କରନ୍ତୁ |",
              "as": "চাকৰিৰ সাক্ষাৎকাৰৰ বাবে কল কৰক",
              "gu": "જોબ ઇન્ટરવ્યુ માટે કૉલ"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailMsg",
            "value": {
              "en": "Hi Arvind,\n\nI am Surekha, from Meta Lab. I was there at your presentation yesterday. We are looking for someone who understands technology and communicates well to join our tech and sales team. We have attached a job description to the email. \n\nIf you are interested, we can schedule a meeting in our office.\n\nRegards,\nSurekha \nProject Lead, Meta Lab",
              "hi": "हाय अरविंद,\n\nमैं मेटा लैब से सुरेखा हूं। कल आपकी प्रस्तुतिकरण के समय मैं उपस्थित थी। हम किसी ऐसे व्यक्ति की खोज में हैं जो प्रौद्योगिकी को समझता है और हमारी तकनीक और बिक्री टीम में शामिल होने के लिए अच्छी तरह से संचार करता है। हमने ईमेल में नौकरी का विवरण संलग्न किया है।\n\nयदि आप रुचि रखते हैं, तो हम अपने कार्यालय में एक बैठक शेड्यूल कर सकते हैं।\n\nसादर,\nसुरेखा\nपरियोजना लीड, मेटा लैब",
              "ka": "\"ಹಾಯ್ ಅರವಿಂದ್,\n \n ನಾನು ಸುರೇಖಾ, ಮೆಟಾ ಲ್ಯಾಬ್‌ನಿಂದ. ನಿನ್ನೆ ನಿಮ್ಮ ಪ್ರಸ್ತುತಿಯಲ್ಲಿ ನಾನು ಇದ್ದೆ. ನಮ್ಮ ತಂತ್ರಜ್ಞಾನ ಮತ್ತು ಮಾರಾಟ ತಂಡವನ್ನು ಸೇರಲು ತಂತ್ರಜ್ಞಾನವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವ ಮತ್ತು ಉತ್ತಮವಾಗಿ ಸಂವಹನ ಮಾಡುವ ಯಾರನ್ನಾದರೂ ನಾವು ಹುಡುಕುತ್ತಿದ್ದೇವೆ. ನಾವು ಇಮೇಲ್‌ಗೆ ಉದ್ಯೋಗ ವಿವರಣೆಯನ್ನು ಲಗತ್ತಿಸಿದ್ದೇವೆ.\n \n ನಿಮಗೆ ಆಸಕ್ತಿ ಇದ್ದರೆ, ನಾವು ನಮ್ಮ ಕಚೇರಿಯಲ್ಲಿ ಸಭೆಯನ್ನು ನಿಗದಿಪಡಿಸಬಹುದು.\n \n ವಂದನೆಗಳು,\n ಸುರೇಖಾ\n ಪ್ರಾಜೆಕ್ಟ್ ಲೀಡ್, ಮೆಟಾ ಲ್ಯಾಬ್\"",
              "te": "హాయ్ అరవింద్,\n \n నేను మెటా ల్యాబ్ నుండి సురేఖ. నేను నిన్న నీ ప్రెజెంటేషన్ చూసాను. మేము మా టెక్ మరియు సేల్స్ టీమ్‌లో చేరడానికి టెక్నాలజీ తెలిసిన మరియు బాగా కమ్యూనికేట్ చేసే వారి కోసం వెతుకుతున్నాము. మేము ఇమెయిల్‌కు ఉద్యోగ వివరణను జోడించాము.\n \n మీకు ఆసక్తి ఉంటే, మనము మా కార్యాలయంలో ఒక మీటింగ్ షెడ్యూల్ చేయవచ్చు.\n \n గౌరవంతో,\n సురేఖ\n ప్రాజెక్ట్ లీడ్, మెటా ల్యాబ్",
              "or": "ହାଏ ଅରବିନ୍ଦ,\n \n ମୁଁ ମେଟା ଲ୍ୟାବ୍‌ ରୁ ସୁରେଖା | ଗତକାଲି ତୁମର ଉପସ୍ଥାପନାରେ ମୁଁ ସେଠାରେ ଥିଲି | ଆମର ଟେକ୍ ଏବଂ ସେଲ୍ସ ଦଳରେ ଯୋଗଦେବା ପାଇଁ ଆମେ ଟେକ୍ନୋଲୋଜି ଏବଂ ଯୋଗାଯୋଗକୁ ଭଲରେ ବୁଝି ପାରୁଥିବା ବ୍ୟକ୍ତିଙ୍କୁ ଖୋଜୁଛୁ | ଆମେ ଇମେଲ୍‌ରେ ଏକ ଚାକିରିର ବର୍ଣ୍ଣନା ସଂଲଗ୍ନ କରିଛୁ |\n \n ଯଦି ଆପଣ ଆଗ୍ରହୀ, ଆମେ ଆମ କାର୍ଯ୍ୟାଳୟରେ ଏକ ମିଟିଂ ସ୍ଥିର କରିପାରିବା |\n \n ଶୁଭେଚ୍ଛା,\n ସୁରେଖା |\n ପ୍ରୋଜେକ୍ଟ ଲିଡ୍, ମେଟା ଲ୍ୟାବ୍",
              "as": "হাই অৰবিন্দ,\n \n মই সুৰেখা, মেটা লেবৰ পৰা। মই কালি আপোনাৰ প্ৰেজেণ্টেচনত উপস্থিত আছিলো। আমি এনে এজন ব্যক্তি বিচাৰি আছোঁ যিয়ে প্ৰযুক্তি বুজি পায় আৰু আমাৰ প্ৰযুক্তি আৰু বিক্ৰী দলত যোগদান কৰিবলৈ ভালদৰে যোগাযোগ কৰিব জানে। আমি ইমেইলত এটা চাকৰিৰ বিৱৰণ সংলগ্ন কৰিছো।\n যদি আপুনি আগ্ৰহী, আমি আমাৰ অফিচত এখন সভাৰ সময়সূচী নিৰ্ধাৰণ কৰিব পাৰোঁ।\n সম্মানেৰে,\n সুৰেখা \n মুখ্য প্ৰকল্পকাৰ, মেটা লেব",
              "gu": "હાય અરવિંદ, હું મેટા લેબથી સુરેખા છું .  ગઈકાલે હું તમારી રજૂઆતમાં ઉપસ્થિત હતી.  અમારી ટેક અને સેલ્સ ટીમમાં જોડાવા માટે અમે એવી વ્યક્તિને શોધી રહ્યા છીએ જે ટેક્નોલોજીને સમજે અને સારી રીતે વાતચીત કરી શકે છે.  અમે ઈમેલ સાથે જોબનું વર્ણન જોડ્યું છે. જો તમને રસ હોય, તો અમે અમારી ઑફિસમાં મીટિંગ શેડ્યૂલ કરી શકીએ છીએ. સાદર, સુરેખા પ્રોજેક્ટ લીડ, મેટા લેબ"
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_3_3",
              "hi": "B3_3_3",
              "ka": "B3_3_3",
              "te": "B3_3_3",
              "or": "B3_3_3",
              "as": "B3_3_3",
              "gu": "B3_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is thrilled! He can't believe he got an opportunity based on his project and presentation. He is happy that his skill and hardwork is recognised.",
              "hi": "अरविंद रोमांचित है! वह विश्वास नहीं कर सकता कि उसे अपने प्रोजेक्ट और प्रस्तुति के आधार पर अवसर मिला। वह खुश है कि उसके कौशल और कड़ी मेहनत को मान्यता दी गई है।",
              "ka": "ಅರವಿಂದ್ ರೋಮಾಂಚನಗೊಂಡಿದ್ದಾನೆ! ಅವನ ಪ್ರಾಜೆಕ್ಟ್ ಮತ್ತು ಪ್ರಸ್ತುತಿಯ ಆಧಾರದ ಮೇಲೆ ಅವನಿಗೆ ಅವಕಾಶ ಸಿಕ್ಕಿತು ಎಂದು ನಂಬಲು ಸಾಧ್ಯವಿಲ್ಲ. ತನ್ನ ಕೌಶಲ್ಯ ಮತ್ತು ಶ್ರಮಕ್ಕೆ ಮನ್ನಣೆ ಸಿಕ್ಕಿರುವುದಕ್ಕೆ ಸಂತಸ ವ್ಯಕ್ತಪಡಿಸಿದ್ದಾನೆ.",
              "te": "అరవింద్ థ్రిల్ అవుతాడు! తన ప్రాజెక్ట్ మరియు ప్రెజెంటేషన్ ఆధారంగా తనకు అవకాశం వచ్చిందని అతను నమ్మలేకపోతున్నాడు. తన నైపుణ్యం, శ్రమకు గుర్తింపు లభించినందుకు సంతోషంగా ఉంటాడు.",
              "or": "ଅରବିନ୍ଦ ବହୁତ ଖୁସି! ସେ ବିଶ୍ୱାସ କରିପାରନ୍ତି ନାହିଁ ଯେ ସେ ତାଙ୍କ ପ୍ରୋଜେକ୍ଟ ଏବଂ ଉପସ୍ଥାପନା ଉପରେ ଆଧାର କରି ଏକ ସୁଯୋଗ ପାଇଛନ୍ତି | ତାଙ୍କର କୌଶଳ ଏବଂ ପରିଶ୍ରମର ମାନ୍ୟତା ପାଇ ସେ ଖୁସି |",
              "as": "অৰবিন্দ ৰোমাঞ্চিত! তেওঁ বিশ্বাস কৰিব নোৱাৰে যে তেওঁ তেওঁৰ প্ৰকল্প আৰু উপস্থাপনৰ ওপৰত ভিত্তি কৰি এটা সুযোগ পাইছিল। তেওঁ সুখী যে তেওঁৰ দক্ষতা আৰু কঠোৰ পৰিশ্ৰমক স্বীকৃতি দিয়া হৈছে।",
              "gu": "અરવિંદ રોમાંચિત થઈ ગયો!  તેને વિશ્વાસ નથી આવતો કે તેને   પોતાના પ્રોજેક્ટ અને પ્રેઝન્ટેશનના આધારે તેને એક તક મળી છે.  તેને ખુશ થઈ કે તેના કૌશલ્ય અને મહેનતને માન્યતા મળી છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_4_1",
              "hi": "B3_4_1",
              "ka": "B3_4_1",
              "te": "B3_4_1",
              "or": "B3_4_1",
              "as": "B3_4_1",
              "gu": "B3_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "While he wants to meet them, he feels scared. He has heard from his support group that members of the LGBTQ+ community face problems even at the workplace. He calls Roshini to discuss.",
              "hi": "जब वह उनसे मिलना चाहता है, तो उसे डर लगता है। उसने अपने सहायता समूह से सुना है कि एलजीबीटीक्यू + समुदाय के सदस्यों को कार्यस्थल पर भी समस्याओं का सामना करना पड़ता है। वह रोशनी को चर्चा के लिए बुलाता है।",
              "ka": "ಅವನು ಅವರನ್ನು ಭೇಟಿಯಾಗಲು ಬಯಸಿದಾಗ, ಅವನು ಭಯಪಡುತ್ತಾನೆ. LGBTQ+ ಸಮುದಾಯದ ಸದಸ್ಯರು ಕೆಲಸದ ಸ್ಥಳದಲ್ಲಿಯೂ ಸಹ ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುತ್ತಾರೆ ಎಂದು ಅವರು ತಮ್ಮ ಬೆಂಬಲ ಗುಂಪಿನಿಂದ ಕೇಳಿದ್ದಾರೆ. ಅವನು ರೋಷಿನಿಯನ್ನು ಚರ್ಚಿಸಲು ಕರೆದರು",
              "te": "అతను వారిని కలవాలనుకున్నప్పుడు, అతను భయపడ్డాడు. LGBTQ+ కమ్యూనిటీ సభ్యులు కార్యాలయంలో కూడా సమస్యలను ఎదుర్కొంటారని అతను తన సపోర్ట్ గ్రూప్ నుండి విన్నాడు. రోష్నిని పిలిచి ఇది చర్చించాడు.",
              "or": "ସେ ସେମାନଙ୍କୁ ଭେଟିବାକୁ ଚାହୁଁଥିବାବେଳେ ସେ ଭୟଭୀତ ଅନୁଭବ କରନ୍ତି | ସେ ତାଙ୍କ ସମର୍ଥନ ଗୋଷ୍ଠୀରୁ ଶୁଣିଛନ୍ତି ଯେ LGBTQ + ସମ୍ପ୍ରଦାୟର ସଦସ୍ୟମାନେ କର୍ମକ୍ଷେତ୍ରରେ ମଧ୍ୟ ଅସୁବିଧାର ସମ୍ମୁଖୀନ ହୁଅନ୍ତି | ସେ ଆଲୋଚନା କରିବାକୁ ରୋଶନିଙ୍କୁ ଡାକନ୍ତି |",
              "as": "যেতিয়া তেওঁ তেওঁলোকক লগ কৰিব বিচাৰে, তেওঁ ভয় অনুভৱ কৰে। তেওঁ তেওঁৰ সমৰ্থন গোটৰ পৰা শুনিছে যে LGBTQ+ সম্প্ৰদায়ৰ সদস্যসকলে আনকি কৰ্মক্ষেত্ৰতো সমস্যাৰ সন্মুখীন হয়। তেওঁ আলোচনা কৰিবলৈ ৰোশনিক মাতিছিল।",
              "gu": "તે તેમને મળવા માંગે છે, પણ  તે ડરનો  અનુભવ કરે છે.  તેણે પોતાના સપોર્ટ ગ્રૂપમાં સાંભળ્યું હતું કે LGBTQ+ સમુદાયના સભ્યો કાર્યસ્થળ પર પણ સમસ્યાઓનો સામનો કરે છે.  તે ચર્ચા કરવા  રોશની ને કૉલ કરે છે."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_4_2//",
              "hi": "B3_4_2 //",
              "ka": "B3_4_2//",
              "te": "B3_4_2//",
              "or": "B3_4_2//",
              "as": "B3_4_2//",
              "gu": "B3_4_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Roshni! You won't believe it! I got an email from someone who works at Meta Lab. Remember? I told you they were there at my presentation. They have asked me to meet them regarding a job opening.",
              "hi": "रोशनी! तुम इस पर विश्वास नहीं करोगी! मुझे मेटा लैब में काम करने वाले किसी व्यक्ति से एक ईमेल मिला। याद है? मैंने तुमसे कहा था कि वे मेरे प्रस्तुतिकरण में उपस्थित थे। उन्होंने मुझे नौकरी की रिक्तियों के बारे में उनसे मिलने के लिए कहा है।",
              "ka": "ರೋಶನಿ! ನೀವು ಅದನ್ನು ನಂಬುವುದಿಲ್ಲ! ಮೆಟಾ ಲ್ಯಾಬ್‌ನಲ್ಲಿ ಕೆಲಸ ಮಾಡುವವರಿಂದ ನನಗೆ ಇಮೇಲ್ ಬಂದಿದೆ. ನೆನಪಿದೆಯೇ? ನನ್ನ ಪ್ರಸ್ತುತಿಯಲ್ಲಿ ಅವರು ಇದ್ದಾರೆ ಎಂದು ನಾನು ನಿಮಗೆ ಹೇಳಿದೆ. ಉದ್ಯೋಗಾವಕಾಶದ ಕುರಿತು ತಮ್ಮನ್ನು ಭೇಟಿಯಾಗಲು ಅವರು ನನ್ನನ್ನು ಕೇಳಿದ್ದಾರೆ.",
              "te": "రోష్నీ! నువ్వు నమ్మవు! మెటా ల్యాబ్‌లో పని చేస్తున్న వ్యక్తి నుండి నాకు ఇమెయిల్ వచ్చింది. నీకు గుర్తుందా? వారు నా ప్రెజెంటేషన్ లో ఉన్నారని నేను మీకు చెప్పాను. జాబ్ ఓపెనింగ్ గురించి తమను కలవాలని వారు నన్ను కోరారు.",
              "or": "ରୋଶନି! ତୁମେ ଏହାକୁ ବିଶ୍ୱାସ କରିବେ ନାହିଁ! ମେଟା ଲ୍ୟାବରେ କାମ କରୁଥିବା ବ୍ୟକ୍ତିଙ୍କଠାରୁ ମୁଁ ଏକ ଇମେଲ୍ ପାଇଲି | ମନେରଖନ୍ତୁ? ମୁଁ ତୁମକୁ କହିଲି ସେମାନେ ମୋର ଉପସ୍ଥାପନାରେ ସେଠାରେ ଅଛନ୍ତି | ଚାକିରି ଖୋଲିବା ବିଷୟରେ ସେମାନେ ମୋତେ ସାକ୍ଷାତ କରିବାକୁ କହିଛନ୍ତି।",
              "as": "ৰোশনি! তুমি এইটো বিশ্বাস নকৰিবা! মেটা লেবত কাম কৰা কাৰোবাৰ পৰা মই এটা ইমেইল পাইছিলো। মনত আছেনে? মই তোমাক কৈছিলো যে তেওঁলোক মোৰ প্ৰেজেণ্টেচন কৰা ঠাইত আছিল। তেওঁলোকে মোক চাকৰি কৰাৰ সন্দৰ্ভত তেওঁলোকক লগ কৰিবলৈ কৈছিল।",
              "gu": "રોશની!  તૂં માનશ  નહીં!  મને મેટા લેબમાં કામ કરતા કોઈ વ્યક્તિ તરફથી ઈમેલ મળ્યો.  યાદ છે?  મેં તને કહ્યું હતું કે તેઓ મારી રજૂઆતમાં ત્યાં હતા.  તેઓએ મને નોકરીના સંદર્ભમાં મળવાનું કહ્યું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Congratulations Arvind! I am so proud of you! What is the role?",
              "hi": "बधाई अरविंद! मुझे तुम पर बहुत गर्व है! भूमिका क्या है?",
              "ka": "ಅಭಿನಂದನೆಗಳು ಅರವಿಂದ್! ನಾನು ನಿನ್ನ ಬಗ್ಗೆ ತುಂಬಾ ಹೆಮ್ಮೆಪಡುತ್ತೇನೆ! ಪಾತ್ರ ಏನು?",
              "te": "కంగ్రాట్స్ అరవింద్! నేను నీ గురించి చాలా గర్వపడుతున్నాను! రోల్ ఏమిటి?",
              "or": "ଅଭିନନ୍ଦନ ଅରବିନ୍ଦ! ମୁଁ ତୁମ ପାଇଁ ବହୁତ ଗର୍ବିତ! ଭୂମିକା କ’ଣ?",
              "as": "অভিনন্দন অৰবিন্দ! মই আপোনাক লৈ বহুত গৌৰৱান্বিত! কামটো কি?",
              "gu": "અભિનંદન અરવિંદ!  મને તારાં પર ગર્વ છે!  રોલ શું છે?"
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_4_3////",
              "hi": "B3_4_3 //////////",
              "ka": "B3_4_3////",
              "te": "B3_4_3////",
              "or": "B3_4_3////",
              "as": "B3_4_3////",
              "gu": "B3_4_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "It is a mix of tech and sales. Sales is new for me, but the role seems to be interesting. If I get the job, I have to go through training.",
              "hi": "यह तकनीक और बिक्री का मिश्रण है। बिक्री मेरे लिए नई है, लेकिन नौकरी रोचक प्रतीत होती है। अगर मुझे नौकरी मिलती है, तो मुझे प्रशिक्षण प्राप्त करना होगा।",
              "ka": "ಇದು ತಂತ್ರಜ್ಞಾನ ಮತ್ತು ಮಾರಾಟದ ಮಿಶ್ರಣವಾಗಿದೆ. ಮಾರಾಟ ನನಗೆ ಹೊಸದು, ಆದರೆ ಪಾತ್ರವು ಆಸಕ್ತಿದಾಯಕವಾಗಿದೆ. ಕೆಲಸ ಸಿಕ್ಕರೆ ತರಬೇತಿ ಪಡೆಯಬೇಕು.",
              "te": "ఇది టెక్నికల్ మరియు సేల్స్ మిశ్రమం. సేల్స్ నాకు కొత్తే అయినా రోల్ మాత్రం ఆసక్తికరంగా ఉంటుందనిపిస్తుంది. ఉద్యోగం వస్తే శిక్షణ పొందాలి.",
              "or": "ଏହା ବୈଷୟିକ ଏବଂ ବିକ୍ରୟର ମିଶ୍ରଣ | ବିକ୍ରୟ ମୋ ପାଇଁ ନୂଆ, କିନ୍ତୁ ଭୂମିକା ଆକର୍ଷଣୀୟ ମନେହୁଏ | ଯଦି ମୁଁ ଚାକିରୀ ପାଏ, ମୋତେ ତାଲିମ ଦେଇ ଯିବାକୁ ପଡିବ |",
              "as": "এইটো প্ৰযুক্তি আৰু বিক্ৰীৰ মিশ্ৰণ। বিক্ৰী বিষয়টো মোৰ বাবে নতুন, কিন্তু কামটো আকৰ্ষণীয় যেন লাগিছে। যদি মই চাকৰিটো পাওঁ, মই প্ৰশিক্ষণৰ মাজেৰে যাব লাগিব।",
              "gu": "તે ટેક અને વેચાણનું મિશ્રણ છે.  વેચાણ મારા માટે નવું છે, પરંતુ રોલ રસપ્રદ લાગે છે.  જો મને નોકરી મળે તો મારે તાલીમ લેવી પડશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Okay, that's good, right?",
              "hi": "ठीक है, यह अच्छा है, है ना?",
              "ka": "ಸರಿ, ಅದು ಒಳ್ಳೆಯದು, ಸರಿ?",
              "te": "సరే, అది మంచిది, కదా?",
              "or": "ଠିକ ଅଛି, ତାହା ଭଲ, ଠିକ୍?",
              "as": "ঠিক আছে, এইটো ভাল কথা, হয় নে নহয়?",
              "gu": "ઠીક તો પછી તે સારું છે, બરાબર?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Umm, yes, but I am not sure if I want to take this job as it requires me to go to an office. I was thinking of doing a   remote role instead.",
              "hi": "उम्म, हां, लेकिन मुझे विश्वास नहीं है कि मैं यह नौकरी लेना चाहता हूं क्योंकि इस नौकरी में एक कार्यालय में जाने की आवश्यकता है। मैं इसके बजाय रिमोट/ दूरस्थ नौकरी करने की सोच रहा था।",
              "ka": "ಉಮ್, ಹೌದು, ಆದರೆ ನಾನು ಈ ಕೆಲಸವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಬಯಸುತ್ತೇನೋ ಇಲ್ಲವೋ ಎಂದು ನನಗೆ ಖಚಿತವಿಲ್ಲ ಏಕೆಂದರೆ ನಾನು ಕಚೇರಿಗೆ ಹೋಗಬೇಕಾಗಿದೆ. ಬದಲಿಗೆ ರಿಮೋಟ್ ರೋಲ್ ಮಾಡುವ ಯೋಚನೆ ಇತ್ತು.",
              "te": "ఉమ్, అవును, కానీ నేను ఈ ఉద్యోగాన్ని తీసుకోవాలో లేదో నాకు ఖచ్చితంగా తెలియదు, ఎందుకంటే నేను ఆఫీస్ కి వెళ్లవలసి ఉంటుంది. దానికి బదులు ఏదైనా రిమోట్ రోల్ చేయాలనే ఆలోచనలో ఉన్నాను.",
              "or": "ଉମ୍ମ, ହଁ, କିନ୍ତୁ ମୁଁ ନିଶ୍ଚିତ ନୁହେଁ ଯେ ମୁଁ ଏହି ଚାକିରି ନେବାକୁ ଚାହୁଁଛି କାରଣ ଏହା ପାଇଁ ମୋତେ ଏକ ଅଫିସ୍ ଯିବାକୁ ଆବଶ୍ୟକ ପଡିପାରେ | ମୁଁ ଏହା ବଦଳରେ ଏକ ସୁଦୂର ଭୂମିକା କରିବାକୁ ଚିନ୍ତା କରୁଥିଲି |",
              "as": "উম, হয়, কিন্তু মই এই চাকৰিটো ল'ব বিচাৰো নে নাই নিশ্চিত নহয় কিয়নো ইয়াৰ বাবে মোক এটা অফিচলৈ যোৱাৰ প্ৰয়োজন হ'ব। মই তাৰ সলনি এটা দূৰৱৰ্তী কাম লোৱাৰ কথা ভাবি আছিলো।",
              "gu": "હુ.. હા, પણ મને ખાતરી નથી કે હું આ નોકરી સ્વીકારીશ  કેમ કે મારે  આમાં ઓફિસમાં જવું પડશે.  તેના બદલે હું રિમોટ રોલ કરવાનું વિચારી રહ્યો હતો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Remote? Do you mean freelancing?",
              "hi": "रिमोट/ दूरस्थ? क्या तुम्हारा मतलब फ्रीलांसिंग है?",
              "ka": "ರಿಮೋಟ್? ನಿಮ್ಮ ಪ್ರಕಾರ ಸ್ವತಂತ್ರ ಕೆಲಸವೇ?",
              "te": "రిమోట్? అంటే నువ్వు ఫ్రీలాన్సింగ్ అంటున్నావా?",
              "or": "ରିମୋଟ ? ତୁମର ଅର୍ଥ ଫ୍ରିଲାନ୍ସିଂର କି?",
              "as": "দূৰৱৰ্তী? আপুনি ফ্ৰীলাঞ্চিং বুজাইছে নেকি?",
              "gu": "રિમોટ?  શું તારો મતલબ ફ્રીલાન્સિંગ છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "No, but, maybe yes. Any role where I don't have to work from an office or meet people. Just work from home and stay with mom and dad.",
              "hi": "नहीं, लेकिन, शायद हाँ। कोई भी नौकरी जहां मुझे किसी कार्यालय से काम करने या लोगों से मिलने की आवश्यकता नहीं है। बस घर से काम करें और माँ और पिताजी के साथ रहें।",
              "ka": "ಇಲ್ಲ, ಆದರೆ, ಬಹುಶಃ ಹೌದು. ನಾನು ಕಚೇರಿಯಿಂದ ಕೆಲಸ ಮಾಡಬೇಕಾಗಿಲ್ಲ ಅಥವಾ ಜನರನ್ನು ಭೇಟಿ ಮಾಡಬೇಕಾಗಿಲ್ಲದ ಯಾವುದೇ ಪಾತ್ರ. ಮನೆಯಿಂದ ಕೆಲಸ ಮಾಡಿ ಮತ್ತು ತಾಯಿ ಮತ್ತು ತಂದೆಯೊಂದಿಗೆ ಇರುವುದು.",
              "te": "లేదు, కానీ, బహుశా అవును. నేను ఆఫీసు నుండి పని చేయనవసరం లేని లేదా వ్యక్తులను కలవాల్సిన అవసరం లేని ఏదైనా రోల్. ఇంటి నుండి పని చేయడం మరియు అమ్మ మరియు నాన్నతో ఉండడం.",
              "or": "ନା, କିନ୍ତୁ, ବୋଧହୁଏ ହଁ | ଯେକୌଣସି ଭୂମିକା ଯେଉଁଠାରେ ମୋତେ ଏକ ଅଫିସରୁ କାମ କରିବାକୁ କିମ୍ବା ଲୋକଙ୍କୁ ଭେଟିବାକୁ ପଡିବ ନାହିଁ | କେବଳ ଘରୁ କାମ କର ଏବଂ ମା ଏବଂ ବାପାଙ୍କ ସହିତ ରୁହ |",
              "as": "নহয়, কিন্তু, হয়তো হয়। যিকোনো কাম য'ত মই অফিচৰ পৰা কাম কৰিব নালাগে বা মানুহক লগ কৰিব নালাগে। মাত্ৰ ঘৰৰ পৰা কাম কৰা আৰু মা আৰু দেউতাৰ সৈতে থাকা।",
              "gu": "ના, પણ, કદાચ હા.  કોઈપણ એવો રોલ જેમાં મારે ઓફિસમાં કામ કરવું ન પડે કે લોકોને મળવાનું ન પડે.  ફક્ત ઘરેથી કામ કરો અને મમ્મી-પપ્પા સાથે રહી શકું."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_4_4//",
              "hi": "B3_4_4 //",
              "ka": "B3_4_4//",
              "te": "B3_4_4//",
              "or": "B3_4_4//",
              "as": "B3_4_4//",
              "gu": "B3_4_4//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Hmm... Do you have any idea how you will go about it?",
              "hi": "हम्म ... क्या तुमने कोई विचार किया है कि तुम इस विषय में कैसे आगे बढ़ोगे?",
              "ka": "ಹಾಂ... ನೀವು ಅದನ್ನು ಹೇಗೆ ಮಾಡುತ್ತೀರಿ ಎಂದು ನಿಮಗೆ ಏನಾದರೂ ಕಲ್ಪನೆ ಇದೆಯೇ?",
              "te": "హ్మ్... అది ఎలా చేయాలో నీకు ఏమైనా తెలుసా?",
              "or": "ହମ୍ ... ତୁମେ ଏହା ବିଷୟରେ କେମିତି ଯିବେ ତୁମର କୌଣସି ଧାରଣା ଅଛି କି?",
              "as": "হুম... আপুনি এইটো কেনেদৰে কৰিব এই বিষয়ে কোনো ধাৰণা আছে নেকি?",
              "gu": "હમ્મ... શું તમને કોઈ ખ્યાલ છે કે તૂં આ  કેવી રીતે કરીશ?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "I will have to do research and I know a few people who are working remotely. I could ask them how to go about it.",
              "hi": "मुझे शोध करना होगा और मैं कुछ ऐसे लोगों को जानता हूं जो दूरस्थ रूप से काम कर रहे हैं। मैं उनसे पूछ सकता हूँ कि इस विषय में कैसे आगे बढ़ना है।",
              "ka": "ನಾನು ಸಂಶೋಧನೆ ಮಾಡಬೇಕಾಗಿದೆ ಮತ್ತು ದೂರದಿಂದಲೇ ಕೆಲಸ ಮಾಡುವ ಕೆಲವು ಜನರನ್ನು ನಾನು ತಿಳಿದಿದ್ದೇನೆ. ಅದರ ಬಗ್ಗೆ ಹೇಗೆ ಹೋಗಬೇಕೆಂದು ನಾನು ಅವರನ್ನು ಕೇಳಬಹುದು.",
              "te": "నేను రీసర్చ్ చేయవలసి ఉంటుంది మరియు రిమోట్‌గా పని చేస్తున్న కొంతమంది వ్యక్తులు నాకు తెలుసు. దాని గురించి ఏం చేయాలో నేను వారిని అడగగలను.",
              "or": "ମୋତେ ଗବେଷଣା କରିବାକୁ ପଡିବ ଏବଂ ମୁଁ କିଛି ଲୋକଙ୍କୁ ଜାଣେ ଯେଉଁମାନେ ରିମୋଟରେ କାର୍ଯ୍ୟ କରୁଛନ୍ତି | ମୁଁ ସେମାନଙ୍କୁ ପଚାରି ପାରିବି ଯେ ଏହା ବିଷୟରେ କେମିତି ଯିବେ |",
              "as": "মই গৱেষণা কৰিব লাগিব আৰু মই কেইজনমান লোকক চিনি পাওঁ যিসকলে দূৰৱৰ্তী ভাৱে কাম কৰি আছে। কামটো কেনেদৰে কৰিব লাগিব এই বিষয়ে মই তেওঁলোকক সুধিব পাৰোঁ ।",
              "gu": "મારે રિસર્ચ કરવું પડશે અને હું થોડા લોકોને જાણું છું જેઓ રિમોટથી કામ કરી રહ્યા છે.  હું તેમને તે વિશે કેવી રીતે કરવું તે પૂછી શકું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Why don't you anyway go for the interview at Meta Lab and then decide? They like your work. More and more organisations are becoming inclusive these days.",
              "hi": "क्यों न तुम मेटा लैब में साक्षात्कार के लिए जाओ और फिर निर्णय लो? उन्हें तुम्हारा काम पसंद है। अधिक से अधिक संगठन इन दिनों समावेशी हो रहे हैं।",
              "ka": "ನೀವು ಹೇಗಾದರೂ ಮೆಟಾ ಲ್ಯಾಬ್‌ನಲ್ಲಿ ಸಂದರ್ಶನಕ್ಕೆ ಹೋಗಿ ನಂತರ ನಿರ್ಧರಿಸಬಾರದು ಏಕೆ? ಅವರು ನಿಮ್ಮ ಕೆಲಸವನ್ನು ಇಷ್ಟಪಡುತ್ತಾರೆ. ಇತ್ತೀಚಿನ ದಿನಗಳಲ್ಲಿ ಹೆಚ್ಚು ಹೆಚ್ಚು ಸಂಸ್ಥೆಗಳು ಎಲ್ಲರನ್ನೂ ಒಳಗೊಳ್ಳುತ್ತಿವೆ.",
              "te": "నువ్వు మెటా ల్యాబ్‌లో ఇంటర్వ్యూకి వెళ్లి, ఆపై ఎందుకు నిర్ణయించుకోకూడదు? వారు నీ పనిని ఇష్టపడతారు. ఈ రోజుల్లో చాలా సంస్థలు కలుస్తున్నాయి.",
              "or": "ତୁମେ କାହିଁକି ମେଟା ଲ୍ୟାବରେ ସାକ୍ଷାତକାର ପାଇଁ ଯାଆନ୍ତି ନାହିଁ ଏବଂ ତା’ପରେ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି? ସେମାନେ ତୁମର କାମକୁ ପସନ୍ଦ କରନ୍ତି | ଆଜିକାଲି ଅଧିକରୁ ଅଧିକ ସଂସ୍ଥା ଅନ୍ତର୍ଭୂକ୍ତ ହେବାକୁ ଯାଉଛନ୍ତି |",
              "as": "আপুনি তথাপিও মেটা লেবত সাক্ষাৎকাৰৰ বাবে কিয় নাযায় আৰু তাৰ পিছত কিয় সিদ্ধান্ত নলয়? তেওঁলোকে আপোনাৰ কাম ভাল পায়। আজিকালি অধিকসংখ্যক প্ৰতিষ্ঠান অন্তৰ্ভুক্ত হৈ পৰিছে।",
              "gu": "તો પણ તૂં મેટા લેબમાં ઇન્ટરવ્યુ માટે કેમ નથી જતો અને પછી નિર્ણય લે તો?  તેમને તારું કામ ગમે છે.  આ દિવસોમાં વધુ ને વધુ સંસ્થાઓ ઈન્કુસિવ બની રહી છે."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "B3_4_5_checklist",
              "hi": "B3_4_5_चेकलिस्ट ",
              "ka": "B3_4_5_ಪರಿಶೀಲನಾಪಟ್ಟಿ",
              "te": "B3_4_5_చెక్‌లిస్ట్",
              "or": "B3_4_5_checklist",
              "as": "B3_4_5_চেকলিষ্ট",
              "gu": "B3_4_5_યાદી તપાસો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Help Arvind find out more about each of his options:",
              "hi": "अरविंद को अपने प्रत्येक विकल्प के बारे में अधिक जानकारी प्राप्त करने में सहायता करें :",
              "ka": "ಅರವಿಂದ್ ಅವರ ಪ್ರತಿಯೊಂದು ಆಯ್ಕೆಗಳ ಕುರಿತು ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡಿ:",
              "te": "అరవింద్ తన ప్రతి ఎంపిక గురించి మరింత తెలుసుకోవడానికి సహాయం చేయండి:",
              "or": "ଅରବିନ୍ଦଙ୍କୁ ତାଙ୍କର ପ୍ରତ୍ୟେକ ବିକଳ୍ପ ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ସାହାଯ୍ୟ କରନ୍ତୁ:",
              "as": "অৰবিন্দক তেওঁৰ প্ৰতিটো বিকল্পৰ বিষয়ে অধিক জানিবলৈ সহায় কৰক:",
              "gu": "અરવિંદને તેના દરેક વિકલ્પો વિશે વધુ જાણવામાં મદદ કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Research about freelance",
              "hi": "फ्रीलांस के बारे में अनुसंधान",
              "ka": "ಫ್ರೀಲಾನ್ಸ ಬಗ್ಗೆ ಸಂಶೋಧನೆ",
              "te": "ఫ్రీలాన్స్ గురించి రీసర్చ్",
              "or": "ଫ୍ରିଲାନ୍ସ ବିଷୟରେ ଗବେଷଣା",
              "as": "ফ্ৰিলেন্সৰ বিষয়ে গৱেষণা",
              "gu": "ફ્રીલાન્સ વિશે રિસર્ચ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Appear for the interview",
              "hi": "साक्षात्कार के लिए उपस्थित हो ",
              "ka": "ಸಂದರ್ಶನಕ್ಕೆ ಹಾಜರಾಗಿ",
              "te": "ఇంటర్వ్యూకి హాజరు అవడం",
              "or": "ସାକ୍ଷାତକାର ପାଇଁ ଉପସ୍ଥିତ ହୁଅନ୍ତୁ",
              "as": "সাক্ষাৎকাৰৰ বাবে উপস্থিত হওক",
              "gu": "ઇન્ટરવ્યુ માટે હાજર રહેવું"
            }
          }
        ]
      },
      "scene22": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4i_1_1_Apply for the interview",
              "hi": " b4i_1_1_साक्षात्कार के लिएआवेदन करे ",
              "ka": "B4i_1_1_ಸಂದರ್ಶನಕ್ಕೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ",
              "te": "B4i_1_1_ఇంటర్వ్యూ కోసం అప్లై చేయడం",
              "or": "B4i_1_1_Apply for the interview",
              "as": "B4i_1_1_সাক্ষাৎকাৰৰ বাবে আবেদন কৰক",
              "gu": "B4i_1_1_ઇન્ટરવ્યુ માટે અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind goes for the interview at MetaLab in their Hyderabad office. Arvind has cleared the technical round of the interview. He is meeting Surekha, the project lead and the HR manager, for the final round of interviews.",
              "hi": "अरविंद अपने हैदराबाद कार्यालय में मेटालैब में साक्षात्कार के लिए जाता है। अरविंद साक्षात्कार के  टेक्निकल चरण में सफलता प्राप्त कर लेता है। वह साक्षात्कार के अंतिम चरण के लिए प्रोजेक्ट लीड और एचआर मैनेजर सुरेखा से मिलता है।",
              "ka": "ಅರವಿಂದ್ ಹೈದರಾಬಾದ್ ಕಛೇರಿಯಲ್ಲಿ ಮೆಟಾ ಲ್ಯಾಬ್‌ ನಲ್ಲಿ ಸಂದರ್ಶನಕ್ಕೆ ಹೋಗುತ್ತಾನೆ. ಅರವಿಂದ್ ಸಂದರ್ಶನದ ತಾಂತ್ರಿಕ ಸುತ್ತಿನಲ್ಲಿ ತೇರ್ಗಡೆಯಾಗಿದ್ದಾನೆ. ಅವನು ಅಂತಿಮ ಸುತ್ತಿನ ಸಂದರ್ಶನಕ್ಕಾಗಿ ಪ್ರಾಜೆಕ್ಟ್ ಲೀಡ್ ಮತ್ತು HR ಮ್ಯಾನೇಜರ್ ಸುರೇಖಾ ಅವರನ್ನು ಭೇಟಿಯಾಗುತ್ತಿದ್ದಾನೆ.",
              "te": "అరవింద్ వాళ్ళ హైదరాబాద్ ఆఫీసులో MetaLab లో ఇంటర్వ్యూకి వెళ్ళాడు. అరవింద్ టెక్నికల్ రౌండ్ ఇంటర్వ్యూలో ఉత్తీర్ణుడయ్యాడు. అతను చివరి రౌండ్ ఇంటర్వ్యూల కోసం ప్రాజెక్ట్ లీడ్ మరియు HR మేనేజర్ అయిన సురేఖను కలుస్తున్నాడు.",
              "or": "ଅରବିନ୍ଦ ସେମାନଙ୍କ ହାଇଦ୍ରାବାଦ କାର୍ଯ୍ୟାଳୟରେ ମେଟାଲାବ୍‌ରେ ସାକ୍ଷାତକାର ପାଇଁ ଯାଆନ୍ତି | ସାକ୍ଷାତକାରର ଟେକ୍ନିକାଲ୍‌ ରାଉଣ୍ଡକୁ ଅରବିନ୍ଦ କ୍ଲିଅର୍‌ କରନ୍ତି | ଅନ୍ତିମ ରାଉଣ୍ଡ ସାକ୍ଷାତକାରର ଅନ୍ତିମ ରାଉଣ୍ଡରେ ପାଇଁ ସେ ପ୍ରୋଜେକ୍ଟ ଲିଡ୍ ଏବଂ HR ମ୍ୟାନେଜର ସୁରେଖାଙ୍କୁ ଭେଟନ୍ତି |",
              "as": "অৰবিন্দে তেওঁলোকৰ হায়দৰাবাদ অফিচৰ মেটালেবত সাক্ষাৎকাৰৰ বাবে যায়। অৰবিন্দে সাক্ষাৎকাৰটোৰ কাৰিকৰী পৰ্যায়টো সম্পূৰ্ণ কৰিছে। তেওঁ শেষৰ পৰ্যায়ৰ সাক্ষাৎকাৰৰ বাবে মুখ্য প্ৰকল্পকাৰ আৰু HR মেনেজাৰ সুৰেখাক লগ কৰিছে।",
              "gu": "અરવિંદ  હૈદરાબાદ સ્થિત મેટાલેબની ઑફિસમાં ઇન્ટરવ્યુ માટે જાય છે.  અરવિંદે ઈન્ટરવ્યુનો ટેક્નિકલ રાઉન્ડ ક્લિયર કરી લીધો .  તે ઇન્ટરવ્યુના અંતિમ રાઉન્ડ માટે સુરેખા, પ્રોજેક્ટ લીડ અને HR મેનેજરને મળે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4i_1_2",
              "hi": "B4i_1_2",
              "ka": "B4i_1_2",
              "te": "B4i_1_2",
              "or": "B4i_1_2",
              "as": "B4i_1_2",
              "gu": "B4i_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He answers all their questions. They seem pretty impressed with how he responds.",
              "hi": "वह अपने सभी प्रश्नों के उत्तर देता है। वह जिस तरह से प्रतिक्रिया देता है उससे वे काफी प्रभावित लगते हैं।",
              "ka": "ಅವರ ಎಲ್ಲಾ ಪ್ರಶ್ನೆಗಳಿಗೆ ಅವನು ಉತ್ತರಿಸುತ್ತಾನೆ. ಅವನು ಹೇಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುತ್ತಾರೆ ಎಂಬುದರ ಬಗ್ಗೆ ಅವರು ಬಹಳ ಪ್ರಭಾವಿತರಾಗಿದ್ದಾರೆ.",
              "te": "అతను వారి ప్రశ్నలన్నింటికీ సమాధానం ఇస్తాడు. అతను సమాధానాలు చెప్పడం చూసి వారు ఇంప్రెస్ అయ్యారు.",
              "or": "ସେ ସେମାନଙ୍କର ସମସ୍ତ ପ୍ରଶ୍ନର ଉତ୍ତର ଦିଅନ୍ତି | ସେ ଯେପରି ପ୍ରତିକ୍ରିୟା ଦିଅନ୍ତି ସେଥିରେ ସେମାନେ ବହୁତ ପ୍ରଭାବିତ ହୁଅନ୍ତି |",
              "as": "তেওঁ তেওঁলোকৰ সকলো প্ৰশ্নৰ উত্তৰ দিয়ে। তেওঁ কেনেদৰে সঁহাৰি দিয়ে তাক লৈ তেওঁলোক যথেষ্ট প্ৰভাৱিত যেন লাগিছে।",
              "gu": "તે તેમના તમામ પ્રશ્નોના જવાબ આપે છે.  તે કેવી રીતે પ્રતિભાવ આપે છે તેનાથી તેઓ ખૂબ પ્રભાવિત લાગે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4i_1_3////",
              "hi": "B4i_1_3 /////",
              "ka": "B4i_1_3////",
              "te": "B4i_1_3////",
              "or": "B4i_1_3////",
              "as": "B4i_1_3////",
              "gu": "B4i_1_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Do you have any other questions for us?",
              "hi": "क्या आपके पास हमारे लिए कोई अन्य प्रश्न है?",
              "ka": "ನೀವು ನಮಗೆ ಬೇರೆ ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳನ್ನು ಹೊಂದಿದ್ದೀರಾ?",
              "te": "మా కోసం మీకు ఏవైనా ఇతర ప్రశ్నలు ఉన్నాయా?",
              "or": "ଆମ ପାଇଁ ତୁମ ପାଖରେ ଅନ୍ୟ କୌଣସି ପ୍ରଶ୍ନ ଅଛି କି ?",
              "as": "আমাৰ বাবে আপোনাৰ আন কোনো প্ৰশ্ন আছে নেকি?",
              "gu": "શું તમારી  અન્ય કોઈ પ્રશ્નો પૂછવા  છે અમને?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I belong to the LGBTQ+ community, so I was keen to know what kind of policies you have for preventing harassment, especially for people from my community.",
              "hi": "मैं एलजीबीटीक्यू + समुदाय से संबंधित हूं, इसलिए मैं यह जानने के लिए उत्सुक था कि उत्पीड़न को रोकने के लिए आपकी किस प्रकार की नीतियां हैं, विशेष रूप से मेरे समुदाय के लोगों के लिए।",
              "ka": "ನಾನು LGBTQ+ ಸಮುದಾಯಕ್ಕೆ ಸೇರಿದ್ದೇನೆ, ಆದ್ದರಿಂದ ಕಿರುಕುಳವನ್ನು ತಡೆಗಟ್ಟಲು ನೀವು ಯಾವ ರೀತಿಯ ನೀತಿಗಳನ್ನು ಹೊಂದಿದ್ದೀರಿ ಎಂದು ತಿಳಿಯಲು ನಾನು ಉತ್ಸುಕನಾಗಿದ್ದೆ, ವಿಶೇಷವಾಗಿ ನನ್ನ ಸಮುದಾಯದ ಜನರಿಗೆ.",
              "te": "నేను LGBTQ+ కమ్యూనిటీకి చెందినవాడిని, కాబట్టి ముఖ్యంగా నా సంఘంలోని వ్యక్తుల పట్ల వేధింపులను నివారించడానికి మీరు ఎలాంటి విధానాలను కలిగి ఉన్నారో తెలుసుకోవాలని నేను ఆసక్తిగా ఉన్నాను.",
              "or": "ମୁଁ LGBTQ + ସମ୍ପ୍ରଦାୟରୁ ଅଟେ, ତେଣୁ ନିର୍ଯ୍ୟାତନାକୁ ରୋକିବା ପାଇଁ, ବିଶେଷକରି ମୋ ସମୁଦାୟର ଲୋକଙ୍କ ପାଇଁ ଆପଣଙ୍କର କି ପ୍ରକାର ନୀତି ଅଛି ତାହା ଜାଣିବାକୁ ମୁଁ ଆଗ୍ରହୀ |",
              "as": "মই LGBTQ+ সম্প্ৰদায়ৰ, সেয়েহে মই জানিবলৈ আগ্ৰহী আছিলোঁ যে উৎপীড়ন প্ৰতিৰোধ কৰাৰ বাবে আপোনাৰ কেনে ধৰণৰ নীতি আছে, বিশেষকৈ মোৰ সম্প্ৰদায়ৰ লোকসকলৰ বাবে।",
              "gu": "હું LGBTQ+ સમુદાયનો છું, તેથી મને જાણવાની ઉત્સુકતા હતી કે તમે સતામણી અટકાવવા, ખાસ કરીને મારા સમુદાયના લોકો માટે કેવા પ્રકારની નીતિઓ ધરાવો છો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Our organisation is non-interfering of people's choices as long as they do not violate any company policy. But, we don't have a specific policy for the LGBTQ+ community.",
              "hi": "हमारा संगठन लोगों की पसंद में तब तक हस्तक्षेप नहीं करता है जब तक कि वे कंपनी की किसी नीति का उल्लंघन नहीं करते हैं। लेकिन, हमारे पास एलजीबीटीक्यू+ समुदाय के लिए कोई विशिष्ट नीति नहीं है।",
              "ka": "ಯಾವುದೇ ಕಂಪನಿ ನೀತಿಯನ್ನು ಉಲ್ಲಂಘಿಸದಿರುವವರೆಗೆ ನಮ್ಮ ಸಂಸ್ಥೆಯು ಜನರ ಆಯ್ಕೆಗಳಲ್ಲಿ ಮಧ್ಯಪ್ರವೇಶಿಸುವುದಿಲ್ಲ. ಆದರೆ, LGBTQ+ ಸಮುದಾಯಕ್ಕಾಗಿ ನಾವು ನಿರ್ದಿಷ್ಟ ನೀತಿಯನ್ನು ಹೊಂದಿಲ್ಲ.",
              "te": "వ్యక్తులు కంపెనీ విధానాలను ఉల్లంఘించనంత వరకు మా సంస్థ వారి విషయాలలో జోక్యం చేసుకోదు. కానీ, LGBTQ+ కమ్యూనిటీ కోసం మాకు నిర్దిష్ట విధానం ఏదీ లేదు.",
              "or": "ଯେପର୍ଯ୍ୟନ୍ତ ସେମାନେ କୌଣସି କମ୍ପାନୀ ନୀତିର ଉଲ୍ଲଂଘନ ନକରନ୍ତି ସେ ପର୍ଯ୍ୟନ୍ତ ଆମର ସଂଗଠନ ଲୋକଙ୍କ ପସନ୍ଦରେ ହସ୍ତକ୍ଷେପ କରେନାହିଁ | କିନ୍ତୁ, LGBTQ + ସମୁଦାୟ ପାଇଁ ଆମର ଏକ ନିର୍ଦ୍ଦିଷ୍ଟ ନୀତି ନାହିଁ |",
              "as": "আমাৰ প্ৰতিষ্ঠানে মানুহৰ পছন্দত হস্তক্ষেপ নকৰে যেতিয়ালৈকে তেওঁলোকে কোম্পানীৰ কোনো নীতি উলংঘা নকৰে। কিন্তু, LGBTQ+ সম্প্ৰদায়ৰ বাবে আমাৰ কোনো নিৰ্দিষ্ট নীতি নাই।",
              "gu": "જ્યાં સુધી આવાં લોકો  કંપનીની કોઈ પણ નીતિનું ઉલ્લંઘન ન કરે ત્યાં સુધી અમારી સંસ્થા લોકોની પસંદગીમાં દખલ નથી દેતી.  પરંતુ, અમારી પાસે LGBTQ+ સમુદાય માટે કોઈ વિશેષ નીતિ નથી."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4i_1_4",
              "hi": "B4i_1_4",
              "ka": "B4i_1_4",
              "te": "B4i_1_4",
              "or": "B4i_1_4",
              "as": "B4i_1_4",
              "gu": "B4i_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind leaves the office with an offer letter in hand, and he has a few days to respond to it.\nHe is concerned that they don't have any policies that might protect him.",
              "hi": "अरविंद हाथ में एक प्रस्ताव पत्र लेकर कार्यालय छोड़ देता है, और उसके पास इसका जवाब देने के लिए कुछ दिन होते हैं।\nवह चिंतित है कि उनके पास ऐसी कोई नीति नहीं है जो उसकी रक्षा कर सके।",
              "ka": "\"ಅರವಿಂದ್ ಆಫರ್ ಲೆಟರ್ ಅನ್ನು ಕೈಯಲ್ಲಿ ಹಿಡಿದುಕೊಂಡು ಕಚೇರಿಯಿಂದ ಹೊರಡುತ್ತಾನೆ ಮತ್ತು ಅದಕ್ಕೆ ಪ್ರತಿಕ್ರಿಯಿಸಲು ಅವನು ಕೆಲವು ದಿನಗಳನ್ನು ಹೊಂದಿದ್ದಾನೆ.\n ಅವರನ್ನು ರಕ್ಷಿಸುವ ಯಾವುದೇ ನೀತಿಗಳನ್ನು ಹೊಂದಿಲ್ಲ ಎಂದು ಅವನು ಕಳವಳ ವ್ಯಕ್ತಪಡಿಸಿದ್ದಾನೆ.",
              "te": "అరవింద్ చేతిలో ఆఫర్ లెటర్‌తో ఆఫీసు నుండి బయలుదేరాడు మరియు దానికి రిప్లై ఇవ్వడానికి అతనికి కొన్ని రోజుల సమయం ఉంది. తనను రక్షించే విధానాలేవీ తమ వద్ద లేవని అతను ఆందోళన చెందుతున్నారు.",
              "or": "ଅରବିନ୍ଦ ହାତରେ ଏକ ଅଫର ଚିଠି ଧରି ଅଫିସରୁ ବାହାରି ଯାଆନ୍ତି, ଏବଂ ଏହାର ଜବାବ ଦେବାକୁ ତାଙ୍କର କିଛି ଦିନ ଅଛି |\n ସେ ଚିନ୍ତିତ ଯେ ସେମାନଙ୍କର କୌଣସି ନୀତି ନାହିଁ ଯାହା ତାଙ୍କୁ ସୁରକ୍ଷା ଦେଇପାରେ |",
              "as": "অৰবিন্দে হাতত এখন অফাৰ লেটাৰ লৈ অফিচৰ পৰা আহে, আৰু ইয়াৰ উত্তৰ দিবলৈ তেওঁৰ ওচৰত কেইদিনমান আছে।\n \n তেওঁ চিন্তিত যে তেওঁলোকৰ এনে কোনো নীতি নাই যিয়ে তেওঁক সুৰক্ষা দিব পাৰে।",
              "gu": "અરવિંદ હાથમાં ઑફર લેટર લઈને ઑફિસથી બાહર આવ્યો, અને લેટરનો જવાબ આપવા માટે તેની પાસે થોડા દિવસો છે. તે ચિંતિત હતો કે ઓફિસ પાસે એવી કોઈ નીતિઓ નથી જે તેને સુરક્ષિત કરી શકે."
            }
          }
        ]
      },
      "scene23": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4ii_1_1_Research about freelance",
              "hi": "B4II_1_1_फ्रीलांस के विषय में शोध करे ",
              "ka": "B4ii_1_1_ಫ್ರೀಲ್ಯಾನ್ಸ್ ಬಗ್ಗೆ ಸಂಶೋಧನೆ",
              "te": "B4ii_1_1_ఫ్రీలాన్స్ గురించి రీసర్చ్",
              "or": "B4ii_1_1_Research about freelance",
              "as": "B4ii_1_1_ফ্ৰিলেন্সৰ বিষয়ে গৱেষণা",
              "gu": "B4ii_1_1_ફ્રીલાન્સ વિશે સંશોધન"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind decides to find out more about freelancing remotely.",
              "hi": "अरविंद दूरस्थ रूप से फ्रीलांसिंग के बारे में और जानने का निश्चय करता है।",
              "ka": "ಅರವಿಂದ್ ರಿಮೋಟ್ ಮೂಲಕ ಫ್ರೀಲ್ಯಾನ್ಸಿಂಗ್ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ನಿರ್ಧರಿಸಿದನು.",
              "te": "అరవింద్ రిమోట్‌గా ఫ్రీలాన్సింగ్ చేయడం గురించి మరింత తెలుసుకోవాలని నిర్ణయించుకున్నాడు.",
              "or": "ରିମୋଟ୍‌ରେ ଫ୍ରିଲାନ୍ସ କରିବା ବିଷୟରେ ଅଧିକ ଜାଣିବାକୁ ଅରବିନ୍ଦ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
              "as": "অৰবিন্দে দূৰৱৰ্তীভাৱে ফ্ৰীলাঞ্চিংৰ বিষয়ে অধিক জানিবলৈ সিদ্ধান্ত লয়।",
              "gu": "અરવિંદે રિમોટ ફ્રીલાન્સિંગ વિશે વધુ જાણવાનું નક્કી કર્યું."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4ii_1_2",
              "hi": "B4II_1_2",
              "ka": "B4ii_1_2",
              "te": "B4ii_1_2",
              "or": "B4ii_1_2",
              "as": "B4ii_1_2",
              "gu": "B4ii_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "On his college portal, Arvind searches for people working as freelance developers. He finds Shweta, who graduated four years before him, has been working as a freelancer for three years.",
              "hi": "अपने कॉलेज के पोर्टल पर, अरविंद फ्रीलांस डेवलपर्स के रूप में काम करने वाले लोगों की खोज करता है। उसे पता लगता है कि श्वेता, जिसने उससे चार साल पहले स्नातक किया था, तीन साल से एक फ्रीलांसर के रूप में काम कर रही है।",
              "ka": "ಅವರ ಕಾಲೇಜು ಪೋರ್ಟಲ್‌ನಲ್ಲಿ, ಅರವಿಂದ್ ಫ್ರೀಲ್ಯಾನ್ಸ್ ಡೆವಲಪರ್‌ಗಳಾಗಿ ಕೆಲಸ ಮಾಡುವ ಜನರನ್ನು ಹುಡುಕುತ್ತಾನೆ. ತನಗಿಂತ ನಾಲ್ಕು ವರ್ಷಗಳ ಹಿಂದೆ ಪದವಿ ಪಡೆದ ಶ್ವೇತಾ ಮೂರು ವರ್ಷಗಳಿಂದ ಫ್ರೀಲ್ಯಾನ್ಸರ್ ಆಗಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾಳೆ ಎಂದು ಅವನು ಕಂಡುಕೊಂಡನು.",
              "te": "అరవింద్ తన కాలేజ్ పోర్టల్‌లో ఫ్రీలాన్స్ డెవలపర్‌లుగా పనిచేస్తున్న వ్యక్తుల కోసం వెతుకుతున్నాడు. తన కంటే నాలుగు సంవత్సరాల ముందు గ్రాడ్యుయేషన్ పూర్తి చేసిన శ్వేత మూడేళ్లుగా ఫ్రీలాన్సర్‌గా పనిచేస్తోందని అతను తెలుసుకున్నాడు.",
              "or": "ତାଙ୍କ କଲେଜ ପୋର୍ଟାଲ୍‌ରେ, ଅରବିନ୍ଦ ଫ୍ରିଲାନ୍ସ ଡେଭଲପର୍ ଭାବରେ କାର୍ଯ୍ୟ କରୁଥିବା ଲୋକଙ୍କୁ ଖୋଜନ୍ତି | ସେ ଶ୍ୱେତାଙ୍କୁ ପାଆନ୍ତି, ଯେ ତାଙ୍କ ଚାରି ପୂର୍ବରୁ ବର୍ଷ ସ୍ନାତକ କରିଛନ୍ତି, ତିନି ବର୍ଷ ଧରି ଫ୍ରିଲାନ୍ସର୍ ଭାବରେ କାର୍ଯ୍ୟ କରୁଛନ୍ତି |",
              "as": "তেওঁৰ কলেজৰ পৰ্টেলত, অৰবিন্দে ফ্ৰিলেন্স বিকাশক হিচাপে কাম কৰা লোকসকলক বিচাৰিছে। তেওঁ দেখিলে যে শ্বেতা, যি তেওঁৰ চাৰি বছৰ আগতে স্নাতক ডিগ্ৰী লাভ কৰিছিল, তিনি বছৰ ধৰি ফ্ৰিলাঞ্চাৰ হিচাপে কাম কৰি আছে।",
              "gu": "પોતાનાં કોલેજ પોર્ટલ પર, અરવિંદ ફ્રીલાન્સ ડેવલપર તરીકે કામ કરતા લોકોને શોધે છે.  તે શોધે છે કે શ્વેતા, જે તેના ચાર વર્ષ પહેલાં સ્નાતક થઈ છે, તે ત્રણ વર્ષથી ફ્રીલાન્સર તરીકે કામ કરી રહી છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4ii_1_3_email",
              "hi": "B4II_1_3_ईमेल ",
              "ka": "B4ii_1_3_ಇಮೇಲ್",
              "te": "B4ii_1_3_ఇమెయిల్",
              "or": "B4ii_1_3_email",
              "as": "B4ii_1_3_email",
              "gu": "B4ii_1_3_email"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He writes an email to her.",
              "hi": "वह उसे एक ईमेल लिखता है।",
              "ka": "ಅವನು ಅವಳಿಗೆ ಇಮೇಲ್ ಬರೆಯುತ್ತಾನೆ.",
              "te": "అతను ఆమెకు ఒక ఇమెయిల్ వ్రాస్తాడు.",
              "or": "ସେ ତାଙ୍କୁ ଏକ ଇମେଲ୍ ଲେଖନ୍ତି |",
              "as": "তেওঁ তাইলৈ এটা ইমেইল লিখিছে।",
              "gu": "તે તેણીને એક ઈમેલ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.sendMailTo",
            "value": {
              "en": "shweta@zmail.com",
              "hi": "shweta@zmail.com",
              "ka": "shweta@zmail.com",
              "te": "shweta@zmail.com",
              "or": "shweta@zmail.com",
              "as": "shweta@zmail.com",
              "gu": "shweta@zmail.com"
            }
          },
          {
            "valuePath": "blocks.0.props.sendMailSubject",
            "value": {
              "en": "Reaching out to know more about freelancing",
              "hi": "फ्रीलांसिंग के बारे में और जानने के लिए",
              "ka": "ಫ್ರೀಲ್ಯಾನ್ಸಿಂಗ್ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು",
              "te": "ఫ్రీలాన్సింగ్ గురించి మరింత తెలుసుకోవడానికి",
              "or": "ଫ୍ରିଲାନ୍ସ ବିଷୟରେ ଅଧିକ ଜାଣିବା ପାଇଁ ପହଞ୍ଚିବା",
              "as": "ফ্ৰীলাঞ্চিং ৰ বিষয়ে অধিক জানিবলৈ আগবাঢ়িছে",
              "gu": "ફ્રીલાન્સિંગ વિશે વધુ જાણવા માટે સંપર્ક કરેલ છે"
            }
          },
          {
            "valuePath": "blocks.0.props.sendMailMsg",
            "value": {
              "en": "Hi Shweta, \nI'm Arvind, your junior from the University College of Technology, Hyderabad. I found your contact information via the alumni database. I noticed you have been working as a freelance developer. I have recently completed my undergraduate degree, and I wanted to understand the feasibility and challenges of working as a freelancer. I would love to get your input on the best position I could apply for in the industry. Is it possible to speak to you sometime next week? \n\nRegards,\nArvind",
              "hi": "हाय श्वेता,\nमैं अरविंद हूं, यूनिवर्सिटी कॉलेज ऑफ टेक्नोलॉजी, हैदराबाद से आपका जूनियर। मुझे आपकी संपर्क जानकारी पूर्व छात्रों के डेटाबेस के माध्यम से मिली। मैंने देखा कि आप एक फ्रीलांस डेवलपर के रूप में काम कर रही हैं। मैंने हाल ही में अपनी स्नातक की डिग्री पूरी की है, और मैं एक फ्रीलांसर के रूप में काम करने की व्यवहार्यता और चुनौतियों को समझना चाहता था। मुझे इंडस्ट्री में सबसे अच्छी स्थिति के लिए आपका सुझाव प्राप्त करना अच्छा लगेगा, जिसके लिए मैं आवेदन कर सकता हूं। क्या अगले सप्ताह आपसे बात करना संभव है?\n\nसादर,\nअरविंद",
              "ka": "\"ಹಾಯ್ ಶ್ವೇತಾ,\n ನಾನು ಅರವಿಂದ್, ಹೈದರಾಬಾದ್‌ನ ಯೂನಿವರ್ಸಿಟಿ ಕಾಲೇಜ್ ಆಫ್ ಟೆಕ್ನಾಲಜಿಯಿಂದ ನಿಮ್ಮ ಜೂನಿಯರ್. ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳ ಡೇಟಾಬೇಸ್ ಮೂಲಕ ನಿಮ್ಮ ಸಂಪರ್ಕ ಮಾಹಿತಿಯನ್ನು ನಾನು ಕಂಡುಕೊಂಡಿದ್ದೇನೆ. ನೀವು ಫ್ರೀಲ್ಯಾನ್ಸ್ ಡೆವಲಪರ್ ಆಗಿ ಕೆಲಸ ಮಾಡುತ್ತಿರುವುದನ್ನು ನಾನು ಗಮನಿಸಿದ್ದೇನೆ. ನಾನು ಇತ್ತೀಚೆಗೆ ನನ್ನ ಪದವಿಪೂರ್ವ ಪದವಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದೇನೆ ಮತ್ತು ಸ್ವತಂತ್ರವಾಗಿ ಕೆಲಸ ಮಾಡುವ ಕಾರ್ಯಸಾಧ್ಯತೆ ಮತ್ತು ಸವಾಲುಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ನಾನು ಬಯಸುತ್ತೇನೆ. ಉದ್ಯಮದಲ್ಲಿ ನಾನು ಅರ್ಜಿ ಸಲ್ಲಿಸಬಹುದಾದ ಅತ್ಯುತ್ತಮ ಸ್ಥಾನದ ಕುರಿತು ನಿಮ್ಮ ಇನ್‌ಪುಟ್ ಪಡೆಯಲು ನಾನು ಇಷ್ಟಪಡುತ್ತೇನೆ. ಮುಂದಿನ ವಾರ ನಿಮ್ಮೊಂದಿಗೆ ಮಾತನಾಡಲು ಸಾಧ್ಯವೇ?\n \n ವಂದನೆಗಳು,\n ಅರವಿಂದ್\"",
              "te": "హాయ్ శ్వేతా, నేను అరవింద్, యూనివర్శిటీ కాలేజ్ ఆఫ్ టెక్నాలజీ, హైదరాబాద్ నుండి మీ జూనియర్. నేను అల్యుమ్ని డేటాబేస్ ద్వారా మీ సమాచారాన్ని తెలుసుకున్నాను. మీరు ఫ్రీలాన్స్ డెవలపర్‌గా పని చేస్తున్నారని నేను గమనించాను. నేను ఇటీవలే నా అండర్ గ్రాడ్యుయేట్ డిగ్రీని పూర్తి చేసాను మరియు ఫ్రీలాన్సర్‌గా పనిచేయడానికి గల సాధ్యత మరియు సవాళ్లను నేను అర్థం చేసుకోవాలనుకుంటున్నాను. ఇండస్ట్రీలో నేను అప్లై చేసుకోగలిగే మంచి స్థానం గురించి మీ ఇన్‌పుట్ తెలుసుకోవాలనుకుంటున్నాను. వచ్చే వారం మీతో మాట్లాడటం సాధ్యమేనా?",
              "or": "ନମସ୍କାର ଶ୍ୱେତା,\n ମୁଁ ଅରବିନ୍ଦ, ହାଇଦ୍ରାବାଦର ୟୁନିଭରସିଟି କଲେଜ ଅଫ୍ ଟେକ୍ନୋଲୋଜିରୁ ତୁମର କନିଷ୍ଠ | ଆଲୁମିନି ଡାଟାବେସ୍ ମାଧ୍ୟମରେ ମୁଁ ତୁମର ଯୋଗାଯୋଗ ସୂଚନା ପାଇଲି | ମୁଁ ଦେଖିଲି ତୁମେ ଏକ ଫ୍ରିଲାନ୍ସ ଡେଭଲପର୍ ଭାବରେ କାମ କରୁଛ | ମୁଁ ବର୍ତ୍ତମାନ ମୋର ସ୍ନାତକୋତ୍ତର ଡିଗ୍ରୀ ସମାପ୍ତ କରିଛି, ଏବଂ ମୁଁ ଜଣେ ଫ୍ରିଲାନ୍ସର୍ ଭାବରେ କାର୍ଯ୍ୟ କରିବାର ସମ୍ଭାବ୍ୟତା ଏବଂ ଆହ୍ୱାନଗୁଡ଼ିକୁ ବୁ to ିବାକୁ ଚାହୁଁଥିଲି | ମୁଁ ଇଣ୍ଡଷ୍ଟ୍ରିରେ ଆବେଦନ କରିଥିବା ସର୍ବୋତ୍ତମ ପଦବୀରେ ତୁମର ଇନପୁଟ୍ ପାଇବାକୁ ପସନ୍ଦ କରିବି | ଆସନ୍ତା ସପ୍ତାହରେ ଆପଣଙ୍କ ସହ କଥା ହେବା ସମ୍ଭବ କି?\n \n ଶୁଭେଚ୍ଛା,\n ଅରବିନ୍ଦ |",
              "as": "হাই শ্বেতা, \n মই অৰবিন্দ, হায়দৰাবাদৰ ইউনিভাৰ্চিটি কলেজ অফ টেকন'লজীৰ আপোনাৰ কনিষ্ঠ। মই প্ৰাক্তন শিক্ষাৰ্থীৰ ডাটাবেছৰ জৰিয়তে আপোনাৰ বিষয়ে তথ্য বিচাৰি পাইছিলো। মই লক্ষ্য কৰিছিলো যে আপুনি এজন ফ্ৰিলান্স বিকাশকাৰী হিচাপে কাম কৰি আছে। মই অলপতে মোৰ স্নাতক ডিগ্ৰী সম্পূৰ্ণ কৰিছো, আৰু মই এজন ফ্ৰিলাঞ্চাৰ হিচাপে কাম কৰাৰ সম্ভাৱ্যতা আৰু প্ৰত্যাহ্বানবোৰ বুজিব বিচাৰিছিলো। মই উদ্যোগটোত আবেদন কৰিব পৰা সৰ্বশ্ৰেষ্ঠ স্থিতিত আপোনাৰ পৰা কিবা তথ্য পাভ কৰিবলৈ পালে ভাল পাম। অহা সপ্তাহত কেতিয়াবা আপোনাৰ সৈতে কথা পতাটো সম্ভৱ নেকি? \n \n \n সম্মানেৰে,\n অৰবিন্দ",
              "gu": "હાય શ્વેતા,\n હું અરવિંદ છું, યુનિવર્સિટી કોલેજ ઓફ ટેકનોલોજી, હૈદરાબાદમાં હું તમારો જુનિયર હતો.   મને ભૂતપૂર્વ વિદ્યાર્થીઓના ડેટાબેઝ દ્વારા તમારી સંપર્ક માહિતી મળી છે.  મેં નોંધ્યું છે કે તમે ફ્રીલાન્સ ડેવલપર તરીકે કામ કરી રહ્યાં છો.  મેં તાજેતરમાં મારી અંડરગ્રેજ્યુએટ ડિગ્રી પૂર્ણ કરી છે, અને હું ફ્રીલાન્સર તરીકે કામ કરવાની શક્યતા અને પડકારોને સમજવા માંગું છું.  ઉદ્યોગમાં હું જે શ્રેષ્ઠ પદ માટે અરજી કરી શકું તે અંગે તમારું ઇનપુટ આપો એવી ઈચ્છા છૂટ.  શું આવતા અઠવાડિયે તમારી સાથે વાત કરવી શક્ય છે?\n\n સાદર,\n અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.sendButtonText",
            "value": {
              "en": "Send",
              "hi": "भेजें  ",
              "ka": "ಕಳುಹಿಸು",
              "te": "పంపండి",
              "or": "ପଠାନ୍ତୁ",
              "as": "পঠাওক",
              "gu": "સેન્ડ"
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4ii_2_1",
              "hi": "B4II_2_1",
              "ka": "B4ii_2_1",
              "te": "B4ii_2_1",
              "or": "B4ii_2_1",
              "as": "B4ii_2_1",
              "gu": "B4ii_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind receives a reply from Shweta the next day.",
              "hi": "अरविंद अगले दिन श्वेता से एक जवाब प्राप्त करता है।",
              "ka": "ಮರುದಿನ ಶ್ವೇತಾಳಿಂದ ಅರವಿಂದ್ ಉತ್ತರ ಪಡೆಯುತ್ತಾನೆ.",
              "te": "మరుసటి రోజు శ్వేత నుండి అరవింద్ రిప్లై అందుకున్నాడు.",
              "or": "ପରଦିନ ଅରବିନ୍ଦ ଶ୍ୱେତାଙ୍କଠାରୁ ଏକ ଉତ୍ତର ଗ୍ରହଣ କରନ୍ତି |",
              "as": "পিছদিনা অৰবিন্দে শ্বেতাৰ পৰা এটা উত্তৰ লাভ কৰে।",
              "gu": "અરવિંદને બીજા દિવસે શ્વેતા તરફથી જવાબ મળે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4ii_2_1b__email",
              "hi": "B4II_2_1B__ईमेल ",
              "ka": "B4ii_2_1b__email",
              "te": "B4ii_2_1b__ఇమెయిల్",
              "or": "B4ii_2_1b__email",
              "as": "B4ii_2_1b__email",
              "gu": "B4ii_2_1b__email"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailTo",
            "value": {
              "en": "arvind@zmail.com",
              "hi": "arvind@zmail.com",
              "ka": "arvind@zmail.com",
              "te": "arvind@zmail.com",
              "or": "arvind@zmail.com",
              "as": "arvind@zmail.com",
              "gu": "arvind@zmail.com"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailSubject",
            "value": {
              "en": "Re: Reaching out to know more about freelancing",
              "hi": "पुन: फ्रीलांसिंग के बारे में अधिक जानने के लिए संपर्क करना ",
              "ka": "ಮರು: ಫ್ರೀಲ್ಯಾನ್ಸಿಂಗ್ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ತಲುಪುವುದು",
              "te": "Re: ఫ్రీలాన్సింగ్ గురించి మరింత తెలుసుకోవడానికి",
              "or": "Re: ଫ୍ରିଲାନ୍ସିଂ ବିଷୟରେ ଅଧିକ ଜାଣିବା ପାଇଁ ପହଞ୍ଚିବା |",
              "as": "পুনৰ: ফ্ৰীলাঞ্চিং ৰ বিষয়ে অধিক জানিবলৈ আগবাঢ়িছে",
              "gu": "Re: ફ્રીલાન્સિંગ વિશે વધુ જાણવા માટે સંપર્ક "
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailMsg",
            "value": {
              "en": "Dear Arvind\nWe can talk next week Wednesday at 3 PM. Let me know if the time is convenient for you.\n\nRegards,\nShweta",
              "hi": "प्रिय अरविंद\nहम अगले सप्ताह बुधवार को 3 बजे बात कर सकते हैं। मुझे बताएं कि क्या समय आपके लिए सुविधाजनक है।\n\nसादर,\nश्वेता",
              "ka": "\"ಆತ್ಮೀಯ ಅರವಿಂದ್\n ನಾವು ಮುಂದಿನ ವಾರ ಬುಧವಾರ ಮಧ್ಯಾಹ್ನ 3 ಗಂಟೆಗೆ ಮಾತನಾಡಬಹುದು. ಸಮಯವು ನಿಮಗೆ ಅನುಕೂಲಕರವಾಗಿದ್ದರೆ ನನಗೆ ತಿಳಿಸಿ.\n \n ವಂದನೆಗಳು,\n ಶ್ವೇತಾ\"",
              "te": "డియర్ అరవింద్\n వచ్చే వారం బుధవారం మధ్యాహ్నం 3 గంటలకు మాట్లాడుకోవచ్చు. మీకు ఆ సమయం కుదిరితే నాకు తెలియజేయండి.\n \n గౌరవంతో,\n శ్వేతా",
              "or": "ପ୍ରିୟ ଅରବିନ୍ଦ \n ଆସନ୍ତା ସପ୍ତାହରେ ବୁଧବାର ଦିନ ୩ ଟା ସମୟରେ ଆମେ ଆଲୋଚନା କରିପାରିବା | ସମୟ ଆପଣଙ୍କ ପାଇଁ ସୁବିଧାଜନକ କି ନାହିଁ ମୋତେ ଜଣାନ୍ତୁ |\n \n ଶୁଭେଚ୍ଛା,\n ଶ୍ୱେତା |",
              "as": "মৰমৰ অৰবিন্দ\n \n আমি অহা সপ্তাহত বুধবাৰে আবেলি ৩ বজাত কথা পাতিব পাৰোঁ। সময়টো আপোনাৰ বাবে সুবিধাজনক হয় নে নহয় মোক জনাব।\n \n \n \n সম্মানেৰে,\n \n শ্বেতা",
              "gu": "પ્રિય અરવિંદ\n આપણે આવતા અઠવાડિયે બુધવારે બપોરે 3 વાગ્યે વાત કરી શકીએ છીએ.  તમારા માટે સમય અનુકૂળ હોય તો મને જણાવો.\n\n સાદર,\n શ્વેતા"
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4ii_2_2",
              "hi": "B4II_2_2",
              "ka": "B4ii_2_2",
              "te": "B4ii_2_2",
              "or": "B4ii_2_2",
              "as": "B4ii_2_2",
              "gu": "B4ii_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is looking forward to the conversation with Shweta. They meet on a video call that day, and Shweta is interested to learn about Arvind's projects.",
              "hi": "अरविंद श्वेता के साथ बातचीत  प्रतीक्षा कर रहा है। वे उस दिन एक वीडियो कॉल पर मिलते हैं, और श्वेता को अरविंद के प्रोजेक्ट्स के बारे में जानने की रुचि है।",
              "ka": "ಅರವಿಂದ್ ಶ್ವೇತಾ ಜೊತೆಗಿನ ಸಂಭಾಷಣೆಗಾಗಿ ಎದುರು ನೋಡುತ್ತಿದ್ದಾನೆ. ಆ ದಿನ ಅವರು ವೀಡಿಯೊ ಕರೆಯಲ್ಲಿ ಭೇಟಿಯಾಗುತ್ತಾರೆ ಮತ್ತು ಅರವಿಂದ್ ಅವರ ಯೋಜನೆಗಳ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಲು ಶ್ವೇತಾ ಆಸಕ್ತಿ ಹೊಂದಿದ್ದಾರೆ.",
              "te": "అరవింద్ శ్వేతతో మాట్లాడడం కోసం ఎదురు చూస్తున్నాడు. వారు ఆ రోజు వీడియో కాల్‌లో కలుసుకున్నారు, అరవింద్ ప్రాజెక్ట్‌ల గురించి తెలుసుకోవడానికి శ్వేత ఆసక్తి చూపుతుంది.",
              "or": "ଅରବିନ୍ଦ ଶ୍ୱେତାଙ୍କ ସହ ବାର୍ତ୍ତାଳାପକୁ ଅପେକ୍ଷା କରିଛନ୍ତି | ସେଦିନ ସେମାନେ ଏକ ଭିଡିଓ କଲ୍ ରେ ସାକ୍ଷାତ କରନ୍ତି, ଏବଂ ଶ୍ୱେତା ଅରବିନ୍ଦଙ୍କ ପ୍ରୋଜେକ୍ଟ ବିଷୟରେ ଜାଣିବାକୁ ଆଗ୍ରହୀ ଅଛନ୍ତି |",
              "as": "অৰবিন্দে শ্বেতাৰ সৈতে কথা পাতিবলৈ অপেক্ষা কৰি আছে। তেওঁলোকে সেইদিনা এটা ভিডিঅ' কলত লগ পাইছিল, আৰু শ্বেতাই অৰবিন্দৰ প্ৰকল্পবোৰৰ বিষয়ে জানিবলৈ আগ্ৰহী হৈছে।",
              "gu": "અરવિંદ શ્વેતા સાથેની વાતચીતની રાહ જોઈ રહ્યો હતો.  તે દિવસે તેઓ વિડિયો કૉલ પર મળ્યા, અને શ્વેતાને અરવિંદના પ્રોજેક્ટ્સ વિશે જાણવામાં રસ હતો."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4ii_2_3//",
              "hi": "B4II_2_3 //",
              "ka": "B4ii_2_3//",
              "te": "B4ii_2_3//",
              "or": "B4ii_2_3//",
              "as": "B4ii_2_3//",
              "gu": "B4ii_2_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Shweta",
              "hi": "श्वेता",
              "ka": "ಶ್ವೇತಾ",
              "te": "శ్వేత",
              "or": "ଶ୍ୱେତା",
              "as": "শ্বেতা",
              "gu": "શ્વેતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "It takes some time to become an established freelancer. You have to do some projects and put that up in your portfolio. Once your portfolio is strong, you get more projects easily.",
              "hi": "एक स्थापित फ्रीलांसर बनने में कुछ समय लगता है। आपको कुछ प्रोजेक्ट्स करने हैं और इन्हें अपने पोर्टफोलियो में रखना होगा। एक बार आपका पोर्टफोलियो मजबूत हो जाने के बाद, आपको सरलता से अधिक प्रोजेक्ट्स मिलते हैं।",
              "ka": "ಸ್ಥಾಪಿತ ಸ್ವತಂತ್ರೋದ್ಯೋಗಿಯಾಗಲು ಸ್ವಲ್ಪ ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ. ನೀವು ಕೆಲವು ಯೋಜನೆಗಳನ್ನು ಮಾಡಬೇಕು ಮತ್ತು ಅದನ್ನು ನಿಮ್ಮ ಪೋರ್ಟ್‌ಫೋಲಿಯೊದಲ್ಲಿ ಹಾಕಬೇಕು. ಒಮ್ಮೆ ನಿಮ್ಮ ಪೋರ್ಟ್‌ಫೋಲಿಯೊ ಪ್ರಬಲವಾಗಿದ್ದರೆ, ನೀವು ಹೆಚ್ಚು ಪ್ರಾಜೆಕ್ಟ್‌ಗಳನ್ನು ಸುಲಭವಾಗಿ ಪಡೆಯುತ್ತೀರಿ.",
              "te": "గుర్తింపు కల ఫ్రీలాన్సర్‌గా మారడానికి కొంత సమయం పడుతుంది. మీరు కొన్ని ప్రాజెక్ట్‌లను చేయాలి మరియు దానిని మీ పోర్ట్‌ఫోలియోలో ఉంచాలి. ఒకసారి మీ పోర్ట్‌ఫోలియో బలంగా ఉంటే, మీరు కొత్త ప్రాజెక్ట్‌లను సులభంగా పొందుతారు.",
              "or": "ଏକ ପ୍ରତିଷ୍ଠିତ ଫ୍ରିଲାନ୍ସର୍ ହେବାକୁ କିଛି ସମୟ ଲାଗେ | ଆପଣଙ୍କୁ କିଛି ପ୍ରୋଜେକ୍ଟ କରିବାକୁ ପଡିବ ଏବଂ ଏହାକୁ ନିଜ ପୋର୍ଟଫୋଲିଓରେ ରଖିବାକୁ ପଡିବ | ଥରେ ନିଜର ପୋର୍ଟଫୋଲିଓ ଦୃ୍ଢ ହୋଇଗଲେ ତୁମେ ସହଜରେ ଅଧିକ ପ୍ରୋଜେକ୍ଟ ପାଇବ |",
              "as": "এজন প্ৰতিষ্ঠিত ফ্ৰিলাঞ্চাৰ হ'বলৈ কিছু সময় লাগে। আপুনি কিছুমান প্ৰকল্প কৰিব লাগিব আৰু সেইটো আপোনাৰ পৰ্টফলিঅ'ত ৰাখিব লাগিব। আপোনাৰ পৰ্টফলিঅ' শক্তিশালী হোৱাৰ পিছত, আপুনি সহজে অধিক প্ৰকল্প লাভ কৰিব।",
              "gu": "સ્થાપિત ફ્રીલાન્સર બનવામાં થોડો સમય લાગે છે.  તમારે કેટલાક પ્રોજેક્ટ કરવા પડશે અને તેને તમારા પોર્ટફોલિયોમાં જોડવા પડશે.  એકવાર તમારો પોર્ટફોલિયો મજબૂત થઈ જાય, પછી તમને વધુ પ્રોજેક્ટ સરળતાથી મળે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Ah, I see, but how does one start?",
              "hi": "आह, हाँ-अच्छा, लेकिन कोई कैसे शुरूआत करे?",
              "ka": "ಆಹ್, ನಾನು ನೋಡುತ್ತೇನೆ, ಆದರೆ ಒಬ್ಬರು ಹೇಗೆ ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ?",
              "te": "ఆహ్, అలాగా, కానీ ఎలా ప్రారంభించాలి?",
              "or": "ଆହା, ଏମିକି, କିନ୍ତୁ ଜଣେ କିପରି ଆରମ୍ଭ କରେ ?",
              "as": "আহ, মই দেখিছো, কিন্তু এজনে কেনেকৈ আৰম্ভ কৰে?",
              "gu": "અચ્છા, હું સમજ્યો, પરંતુ આપણે કેવી રીતે શરૂ કરી શકીએ?"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4ii_2_4////",
              "hi": "B4II_2_4 /////",
              "ka": "B4ii_2_4////",
              "te": "B4ii_2_4////",
              "or": "B4ii_2_4////",
              "as": "B4ii_2_4////",
              "gu": "B4ii_2_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "It's pretty easy to get started. You have to create a profile on freelance websites and put up your college projects to showcase your work.",
              "hi": "आरंभ करना काफी सहज है। आपको फ्रीलांस वेबसाइटों पर एक प्रोफाइल बनानी होगी और अपने काम को प्रदर्शित करने के लिए अपने कॉलेज प्रोजेक्ट्स को प्रस्तुत करना होगा।",
              "ka": "ಪ್ರಾರಂಭಿಸಲು ಇದು ತುಂಬಾ ಸುಲಭ. ನೀವು ಸ್ವತಂತ್ರ ವೆಬ್‌ಸೈಟ್‌ಗಳಲ್ಲಿ ಪ್ರೊಫೈಲ್ ಅನ್ನು ರಚಿಸಬೇಕು ಮತ್ತು ನಿಮ್ಮ ಕೆಲಸವನ್ನು ಪ್ರದರ್ಶಿಸಲು ನಿಮ್ಮ ಕಾಲೇಜು ಯೋಜನೆಗಳನ್ನು ಹಾಕಬೇಕು.",
              "te": "ప్రారంభించడం చాలా సులభం. మీరు ఫ్రీలాన్స్ వెబ్‌సైట్‌లలో ప్రొఫైల్‌ని సృష్టించాలి మరియు మీ పనితనం చూపించడానికి మీ కాలేజీ ప్రాజెక్ట్‌లను పెట్టాలి.",
              "or": "ଆରମ୍ଭ କରିବା ବହୁତ ସହଜ ଅଟେ | ତୁମକୁ ଫ୍ରିଲାନ୍ସ ୱେବସାଇଟରେ ଏକ ପ୍ରୋଫାଇଲ୍ ସୃଷ୍ଟି କରିବାକୁ ପଡିବ ଏବଂ ତୁମର କାର୍ଯ୍ୟ ପ୍ରଦର୍ଶନ କରିବାକୁ ତୁମ କଲେଜ ପ୍ରୋଜେକ୍ଟଗୁଡ଼ିକୁ ରଖିବାକୁ ପଡିବ |",
              "as": "আৰম্ভ কৰাটো বহুত সহজ। আপুনি ফ্ৰিলেন্স ৱেবছাইটত এটা প্ৰ'ফাইল সৃষ্টি কৰিব লাগিব আৰু আপোনাৰ কাম প্ৰদৰ্শন কৰিবলৈ আপোনাৰ কলেজৰ প্ৰকল্পবোৰ স্থাপন কৰিব লাগিব।",
              "gu": "આ શરૂ કરવું ખૂબ સરળ છે.  તમારે ફ્રીલાન્સ વેબસાઇટ્સ પર પ્રોફાઇલ બનાવવી પડશે અને તમારા કાર્યને પ્રદર્શિત કરવા માટે તમારા કૉલેજ પ્રોજેક્ટ્સ જોડવા જોશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "I'm worried that I might not be able to manage all the work myself. Are you busy catching up with projects all the time?",
              "hi": "मुझे इस बात की चिंता है कि कहीं मैं सारा काम स्वयं न कर पाऊँ। क्या आप हर समय प्रोजेक्ट्स के कार्य में व्यस्त रहती हैं?",
              "ka": "ಎಲ್ಲ ಕೆಲಸಗಳನ್ನು ನಾನೇ ನಿಭಾಯಿಸಲು ಸಾಧ್ಯವಾಗದೇ ಇರಬಹುದೆಂಬ ಆತಂಕ ನನ್ನದು. ನೀವು ಸಾರ್ವಕಾಲಿಕ ಯೋಜನೆಗಳನ್ನು ಹಿಡಿಯುವಲ್ಲಿ ನಿರತರಾಗಿದ್ದೀರಾ?",
              "te": "నేను అన్ని పనులను స్వయంగా చేయలేనేమో అని నేను భయపడుతున్నాను. మీరు ఎప్పుడూ ప్రాజెక్ట్‌లతో బిజీగా ఉంటారా?",
              "or": "ମୁଁ ଚିନ୍ତିତ ଯେ ମୁଁ ହୁଏତ ସମସ୍ତ କାର୍ଯ୍ୟ ନିଜେ ପରିଚାଳନା କରିପାରିବି ନାହିଁ | ତୁମେ ସବୁବେଳେ ପ୍ରୋଜେକ୍ଟ ଧରିବାରେ ବ୍ୟସ୍ତ କି?",
              "as": "মই চিন্তিত যে মই নিজে সকলো কাম পৰিচালনা কৰিব নোৱাৰিম। আপুনি সকলো সময়তে প্ৰকল্পবোৰ ৰখাত ব্যস্ত হৈ থাকে নেকি?",
              "gu": "મને લાગે છે કે હું કદાચ બધા કામ જાતે જ મેનેજ કરી શકીશ નહીં.  શું તમે પૂરો સમય પ્રોજેકટ્સનાં કામમાં વ્યસ્ત રહો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "It's not like that all the time. Sometimes it's hectic, but sometimes there could be no work for a few weeks or even a month.",
              "hi": "यह हर समय ऐसा नहीं है। कभी-कभी यह अति व्यस्त होता है, लेकिन कभी-कभी कुछ सप्ताह या यहां तक ​​कि एक महीने तक हो सकता है कि कोई काम न हो।",
              "ka": "ಎಲ್ಲಾ ಸಮಯದಲ್ಲೂ ಹಾಗಲ್ಲ. ಕೆಲವೊಮ್ಮೆ ಇದು ಒತ್ತಡದಿಂದ ಕೂಡಿರುತ್ತದೆ, ಆದರೆ ಕೆಲವೊಮ್ಮೆ ಕೆಲವು ವಾರಗಳವರೆಗೆ ಅಥವಾ ಒಂದು ತಿಂಗಳವರೆಗೆ ಯಾವುದೇ ಕೆಲಸ ಇರುವುದಿಲ್ಲ.",
              "te": "అన్ని వేళలా అలా ఉండదు. కొన్నిసార్లు ఇది చాలా ఎక్కువ ఉంటుంది, కానీ కొన్నిసార్లు కొన్ని వారాలు లేదా ఒక నెల వరకు పని ఉండదు.",
              "or": "ଏହା ସବୁବେଳେ ସେପରି ନୁହେଁ | ବେଳେବେଳେ ଏହା ବ୍ୟସ୍ତବହୂଳ, କିନ୍ତୁ ବେଳେବେଳେ କିଛି ସପ୍ତାହ କିମ୍ବା ଏକ ମାସ ପାଇଁ କୌଣସି କାମ ନଥାଏ |",
              "as": "এইটো সকলো সময়তে তেনেকুৱা নহয়। কেতিয়াবা বহুত ব্যস্ত হৈ থাকো, কিন্তু কেতিয়াবা কেইসপ্তাহমান বা আনকি এমাহৰ বাবে কোনো কাম নাথাকিবও পাৰে।",
              "gu": "બધા સમય એવું નથી હોતું.  કેટલીકવાર તમે  વ્યસ્ત રહો છો પરંતુ કેટલીકવાર થોડા અઠવાડિયા અથવા એક મહિના સુધી કોઈ કામ નથી હોતું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Then what about money? How do you manage when there is no work?",
              "hi": "फिर पैसे के बारे में क्या? कोई काम न होने पर आप कैसे प्रबंधित करती हैं?",
              "ka": "ಹಾಗಾದರೆ ಹಣದ ಬಗ್ಗೆ ಏನು? ಕೆಲಸವಿಲ್ಲದಿದ್ದಾಗ ಹೇಗೆ ನಿರ್ವಹಿಸುತ್ತೀರಿ?",
              "te": "అప్పుడు డబ్బు సంగతి ఏమిటి? పని లేనప్పుడు ఎలా మేనేజ్ చేస్తారు?",
              "or": "ତେବେ ଟଙ୍କା ବିଷୟରେ କଣ ? କୌଣସି କାମ ନଥିବାବେଳେ ତୁମେ କିପରି ପରିଚାଳନା କର ?",
              "as": "তেন্তে টকাৰ বিষয়ে কি? যেতিয়া কোনো কাম নাথাকে তেতিয়া আপুনি কেনেদৰে চলে?",
              "gu": "તો પછી પૈસાનું શું?  જ્યારે કોઈ કામ ન હોય ત્યારે તમે કેવી રીતે મેનેજ કરો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Initially, it was a struggle, but these days I make sure I work with some big clients regularly, and those projects then help me save some money for the times when I don't have work.",
              "hi": "प्रारंभ में, यह एक संघर्ष था, लेकिन इन दिनों मैं सुनिश्चित करती हूं कि मैं कुछ बड़े ग्राहकों के साथ नियमित रूप से काम करती रहूं, और उन प्रोजेक्ट्स से मुझे उस समय के लिए कुछ पैसे बचाने में सहायता मिलती है जब मेरे पास काम नहीं होता है।",
              "ka": "ಆರಂಭದಲ್ಲಿ, ಇದು ಹೋರಾಟವಾಗಿತ್ತು, ಆದರೆ ಈ ದಿನಗಳಲ್ಲಿ ನಾನು ಕೆಲವು ದೊಡ್ಡ ಕ್ಲೈಂಟ್‌ಗಳೊಂದಿಗೆ ನಿಯಮಿತವಾಗಿ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೇನೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುತ್ತೇನೆ ಮತ್ತು ಆ ಯೋಜನೆಗಳು ನನಗೆ ಕೆಲಸವಿಲ್ಲದ ಸಮಯಕ್ಕಾಗಿ ಸ್ವಲ್ಪ ಹಣವನ್ನು ಉಳಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತವೆ.",
              "te": "ప్రారంభంలో, ఇది చాలా కష్టంగా ఉండేది, కానీ ఈ రోజుల్లో నేను కొంతమంది పెద్ద క్లయింట్‌లతో క్రమం తప్పకుండా పని చేసేలా చూసుకుంటున్నాను మరియు ఆ ప్రాజెక్ట్‌లు నాకు పని లేని సమయంలో ఉపయోగించడానికి కొంత డబ్బు ఆదా చేయడంలో సహాయపడతాయి.",
              "or": "ପ୍ରାରମ୍ଭରେ, ଏହା ଏକ ସଂଘର୍ଷ ଥିଲା, କିନ୍ତୁ ଏବେ ମୁଁ ନିଶ୍ଚିତ କରେ ଯେ ମୁଁ ନିୟମିତ ଭାବରେ କିଛି ବଡ ଗ୍ରାହକଙ୍କ ସହିତ କାମ କରେ, ଏବଂ ସେହି ପ୍ରୋଜେକ୍ଟଗୁଡ଼ିକ ମୋତେ କିଛି ସମୟ ଅର୍ଥ ସଞ୍ଚୟ କରିବାରେ ସାହାଯ୍ୟ କରେ ଯେତେବେଳେ ମୋ ପାଖରେ କାମ ନଥାଏ |",
              "as": "আৰম্ভণিতে, এইটো এটা সংগ্ৰাম আছিল, কিন্তু আজিকালি মই নিশ্চিত কৰোঁ যে মই কিছুমান ডাঙৰ গ্ৰাহকৰ সৈতে নিয়মীয়াকৈ কাম কৰোঁ, আৰু সেই প্ৰকল্পবোৰে মোক সেই সময়ৰ বাবে কিছু ধন ৰাহি কৰাত সহায় কৰে যেতিয়া মোৰ কোনো কাম নাথাকে।",
              "gu": "શરૂઆતમાં, આમ થતું હતું, પરંતુ હમણાં હું ખાતરી કરું છું કે હું કેટલાક મોટા ગ્રાહકો સાથે નિયમિતપણે કામ કરું , અને તે પ્રોજેક્ટ્સ મને એવા સમયે માટે પૈસા બચાવવામાં મદદ કરે છે જ્યારે મારી પાસે કામ ન હોય."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "B4ii_2_5//",
              "hi": "B4II_2_5 //",
              "ka": "B4ii_2_5//",
              "te": "B4ii_2_5//",
              "or": "B4ii_2_5//",
              "as": "B4ii_2_5//",
              "gu": "B4ii_2_5//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Shweta",
              "hi": "श्वेता",
              "ka": "ಶ್ವೇತಾ",
              "te": "శ్వేత",
              "or": "ଶ୍ୱେତା",
              "as": "শ্বেতা",
              "gu": "શ્વેતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Ah, this sounds stressful.",
              "hi": "आह, यह तनावपूर्ण लगता है।",
              "ka": "ಆಹ್, ಇದು ಒತ್ತಡದಿಂದ ಕೂಡಿದೆ.",
              "te": "ఆహ్, ఇది ఒత్తిడిగా అనిపిస్తుంది.",
              "or": "ଆହା, ଏହା ଚାପଗ୍ରସ୍ତ ମନେହୁଏ |",
              "as": "আহ, এইটো চাপপূৰ্ণ যেন লাগিছে।",
              "gu": "અરે આ તો, આ તણાવપૂર્ણ લાગે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Of course, it does. But I like to work independently and have the freedom to select who and how to work. I define my boundaries and constraints. It also helps if you network well and build a strong portfolio and get good recommendations on your profile. If that is something you want for yourself, it would be worth pursuing!",
              "hi": "बिलकुल यह करता है। लेकिन मुझे स्वतंत्र रूप से काम करना पसंद है और मुझे यह चुनने की आजादी है कि किसके साथ और कैसे काम करना है। मैं अपनी सीमाओं और बाधाओं को परिभाषित करती हूं। यदि आप अच्छी तरह से नेटवर्क करते हैं और एक मजबूत पोर्टफोलियो बनाते हैं और अपने प्रोफाइल पर अच्छी सिफारिशें प्राप्त करते हैं तो यह भी सहायता करता है। अगर ऐसा कुछ है जो आप अपने लिए चाहते हैं, तो यह अनुसरण करने लायक होगा!",
              "ka": "ಸಹಜವಾಗಿ, ಅದು ಮಾಡುತ್ತದೆ. ಆದರೆ ನಾನು ಸ್ವತಂತ್ರವಾಗಿ ಕೆಲಸ ಮಾಡಲು ಇಷ್ಟಪಡುತ್ತೇನೆ ಮತ್ತು ಯಾರು ಮತ್ತು ಹೇಗೆ ಕೆಲಸ ಮಾಡಬೇಕೆಂದು ಆಯ್ಕೆ ಮಾಡುವ ಸ್ವಾತಂತ್ರ್ಯವಿದೆ. ನನ್ನ ಗಡಿಗಳು ಮತ್ತು ನಿರ್ಬಂಧಗಳನ್ನು ನಾನು ವ್ಯಾಖ್ಯಾನಿಸುತ್ತೇನೆ. ನೀವು ಚೆನ್ನಾಗಿ ನೆಟ್‌ವರ್ಕ್ ಮಾಡಿದರೆ ಮತ್ತು ಬಲವಾದ ಪೋರ್ಟ್‌ಫೋಲಿಯೊವನ್ನು ನಿರ್ಮಿಸಿದರೆ ಮತ್ತು ನಿಮ್ಮ ಪ್ರೊಫೈಲ್‌ನಲ್ಲಿ ಉತ್ತಮ ಶಿಫಾರಸುಗಳನ್ನು ಪಡೆದರೆ ಇದು ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಅದು ನಿಮಗಾಗಿ ನೀವು ಬಯಸಿದರೆ, ಅದನ್ನು ಅನುಸರಿಸಲು ಯೋಗ್ಯವಾಗಿದೆ!",
              "te": "హా, అలానే ఉంటుంది. కానీ నేను స్వతంత్రంగా పనిచేయడానికి ఇష్టపడతాను మరియు ఎవరు మరియు ఎలా పని చేయాలో ఎంపిక చేసుకునే స్వేచ్ఛ నాకు ఉంది. నేను నా సరిహద్దులు మరియు పరిమితులను చూసుకుంటాను. మీరు బాగా నెట్‌వర్క్ చేయడం మరియు బలమైన పోర్ట్‌ఫోలియోను నిర్మించడం మరియు మీ ప్రొఫైల్‌లో మంచి రికమెండేషన్స్ ఉంచడం కూడా మీకు సహాయపడుతుంది. మీరు అదే కావాలని కోరుకుంటే, ఇలా చేయడం విలువైనదే!",
              "or": "ଅବଶ୍ୟ, ଏହା କରେ | କିନ୍ତୁ ମୁଁ ସ୍ୱାଧୀନ ଭାବରେ କାମ କରିବାକୁ ପସନ୍ଦ କରେ ଏବଂ କିଏ ଏବଂ କିପରି କାର୍ଯ୍ୟ ହେବ ତାହା ବାଛିବା ପାଇଁ ମୋର ସ୍ୱାଧୀନତା ଅଛି | ମୁଁ ମୋର ସୀମା ଏବଂ ପ୍ରତିବନ୍ଧକକୁ ବ୍ୟାଖ୍ୟା କରିଛି | ଯଦି ତୁମେ ଭଲ ନେଟୱାର୍କ କରିଛ ଏବଂ ଏକ ଶକ୍ତିଶାଳୀ ପୋର୍ଟଫୋଲିଓ ତିଆରି କରିଛ ଏବଂ ତୁମ ପ୍ରୋଫାଇଲରେ ଭଲ ସୁପାରିଶ ଅଛି ତେବେ ଏହା ମଧ୍ୟ ସାହାଯ୍ୟ କରେ | ଯଦି ତାହା ତୁମେ ନିଜ ପାଇଁ ଚାହୁଁଛ, ଏହା ଅନୁସରଣଯୋଗ୍ୟ କରିବା ହେବ !",
              "as": "অৱশ্যেই, এইটো এনেকুৱাই। কিন্তু মই স্বতন্ত্ৰভাৱে কাম কৰিবলৈ ভাল পাওঁ আৰু কোনে আৰু কেনেকৈ কাম কৰিব লাগে বাছনি কৰাৰ স্বাধীনতা পাওঁ। মই মোৰ সীমা আৰু সীমাবদ্ধতা নিৰ্ধাৰণ কৰোঁ। যদি আপুনি ভালদৰে নেটৱৰ্ক কৰে আৰু এটা শক্তিশালী পৰ্টফলিঅ' নিৰ্মাণ কৰে আৰু আপোনাৰ প্ৰ'ফাইলত ভাল পৰামৰ্শ প্ৰাপ্ত কৰে তেন্তে ই সহায় কৰে। যদি সেইটো আপুনি নিজৰ বাবে বিচাৰে, তেন্তে ইয়াক অনুসৰণ কৰা উচিত হ'ব!",
              "gu": "ખરેખર, તે આવું છે.  પરંતુ મને સ્વતંત્ર રીતે કામ કરવું ગમે છે અને કોની સાથે અને કેવી રીતે કામ કરવું તે પસંદ કરવાની સ્વતંત્રતા છે.  હું મારી સીમાઓ અને મર્યાદાઓને વ્યાખ્યાયિત કરું છું.  જો તમે સારી રીતે નેટવર્ક કરો છો અને મજબૂત પોર્ટફોલિયો બનાવો છો અને તમારી પ્રોફાઇલ પર સારી ભલામણો મેળવો છો તો તે પણ મદદ કરે છે.  જો આ એવું  છે જેની તમે પોતાના માટે ઇચ્છો છો, તો તે અનુસરવા યોગ્ય રહેશે!"
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "Research (Alumni)",
              "hi": "शोध (पूर्व छात्र)",
              "ka": "ಸಂಶೋಧನೆ (ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳು)",
              "te": "రీసర్చ్ (అల్యుమ్ని)",
              "or": "ଅନୁସନ୍ଧାନ (ଆଲୁମ୍ନି)",
              "as": "গৱেষণা (প্ৰাক্তন ছাত্ৰ)",
              "gu": "રિસર્ચ ( અલુમનાઇ )"
            }
          }
        ]
      },
      "scene24": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_1",
              "hi": "B5_1_1",
              "ka": "B5_1_1",
              "te": "B5_1_1",
              "or": "B5_1_1",
              "as": "B5_1_1",
              "gu": "B5_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "With all this information, Arvind calls Roshni to discuss his options.",
              "hi": "इस सारी जानकारी के साथ, अरविंद रोशनी को अपने विकल्पों पर चर्चा करने के लिए कहता है।",
              "ka": "ಈ ಎಲ್ಲಾ ಮಾಹಿತಿಯೊಂದಿಗೆ, ಅರವಿಂದ್ ತನ್ನ ಆಯ್ಕೆಗಳನ್ನು ಚರ್ಚಿಸಲು ರೋಶನಿಗೆ ಕರೆ ಮಾಡುತ್ತಾನೆ.",
              "te": "ఈ సమాచారంతో, అరవింద్ తన ఎంపికలను చర్చించడానికి రోష్నిని పిలిచాడు.",
              "or": "ଏହି ସମସ୍ତ ସୂଚନା ସହିତ, ଅରବିନ୍ଦ ରୋଶନୀଙ୍କୁ ତାଙ୍କ ବିକଳ୍ପ ବିଷୟରେ ଆଲୋଚନା କରିବାକୁ କଲ୍‌ କରନ୍ତି |",
              "as": "এই সকলো তথ্যৰ সৈতে, অৰবিন্দে ৰোশনিক তেওঁৰ বিকল্পবোৰ আলোচনা কৰিবলৈ মাতিছিল।",
              "gu": "આ બધી માહિતી સાથે, અરવિંદ રોશનીને તેના વિકલ્પોની ચર્ચા કરવા માટે કૉલ કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_2////",
              "hi": "B5_1_2 //////////////",
              "ka": "B5_1_2////",
              "te": "B5_1_2////",
              "or": "B5_1_2////",
              "as": "B5_1_2////",
              "gu": "B5_1_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I am glad I went for the interview and saw the office, and it gave me a good idea about the organisation and the people working there.",
              "hi": "मुझे खुशी है कि मैं साक्षात्कार के लिए गया और कार्यालय को देखा, और इसने मुझे संगठन और वहां काम करने वाले लोगों के बारे में अच्छा विचार दिया।",
              "ka": "ನಾನು ಸಂದರ್ಶನಕ್ಕೆ ಹೋಗಿ ಕಛೇರಿಯನ್ನು ನೋಡಿದ ಬಗ್ಗೆ ನನಗೆ ಸಂತೋಷವಾಗಿದೆ, ಮತ್ತು ಅದು ನನಗೆ ಸಂಸ್ಥೆ ಮತ್ತು ಅಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಜನರ ಬಗ್ಗೆ ಒಳ್ಳೆಯ ಕಲ್ಪನೆಯನ್ನು ನೀಡಿತು.",
              "te": "నేను ఇంటర్వ్యూకి వెళ్లి ఆఫీస్‌ని చూశాను, సంస్థ గురించి మరియు అక్కడ పనిచేస్తున్న వ్యక్తుల గురించి నాకు మంచి ఐడియా వచ్చింది.",
              "or": "ମୁଁ ଖୁସି ଯେ ମୁଁ ସାକ୍ଷାତକାର ପାଇଁ ଯାଇ ଅଫିସ୍ ଦେଖିଲି, ଏବଂ ଏହା ମୋତେ ସଂଗଠନ ଏବଂ ସେଠାରେ କାର୍ଯ୍ୟ କରୁଥିବା ଲୋକମାନଙ୍କ ବିଷୟରେ ଏକ ଭଲ ଧାରଣା ଦେଲା |",
              "as": "মই সুখী যে মই সাক্ষাৎকাৰৰ বাবে গৈছিলো আৰু অফিচটো দেখিছিলো, আৰু ই মোক প্ৰতিষ্ঠানটো আৰু তাত কাম কৰা লোকসকলৰ বিষয়ে ভাল ধাৰণা দিছিল।",
              "gu": "મને ખુશી છે કે હું ઇન્ટરવ્યુ માટે ગયો અને ઓફિસ જોઈ, અને તેનાથી મને સંસ્થા અને ત્યાં કામ કરતા લોકો વિશે ઘણો ખ્યાલ આવ્યો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "That sounds great! Are you feeling more convinced?",
              "hi": "सुनकर बहुत अच्छा लगा! क्या तुम अधिक आश्वस्त अनुभव कर रहे हो?",
              "ka": "ಅದು ಮಹಾನ್ ಎನಿಸುತ್ತದೆ! ನಿಮಗೆ ಹೆಚ್ಚು ಮನವರಿಕೆಯಾಗಿದೆಯೇ?",
              "te": "చాలా మంచి విషయం! నీకు మరింత నమ్మకంగా ఉందా?",
              "or": "ତାହା ବହୁତ ଭଲ ଲାଗୁଛି ! ତୁମେ ଅଧିକ ନିଶ୍ଚିତ ଅନୁଭବ କରୁଛନ୍ତି କି ?",
              "as": "এইটো শুনি বৰ ভাল লাগিছে! আপুনি অধিক নিশ্চিত অনুভৱ কৰিছিল নে নাই?",
              "gu": "તે  સારુ કહેવાય!  શું તને વધુ વિશ્વાસ નો અનુભવ થઈ રહ્યો છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Well, I asked if the company has employees from the LGBTQ+ community, and they said there were a few. They also said they do not have any policy or process for our safety. After my hostel experience, I am worried.",
              "hi": "ठीक है, मैंने पूछा कि क्या कंपनी के पास एलजीबीटीक्यू + समुदाय से कर्मचारी हैं, और उन्होंने कहा कि कुछ थे। उन्होंने यह भी कहा कि उनके पास हमारी सुरक्षा के लिए कोई नीति या प्रक्रिया नहीं है। मेरे छात्रावास के अनुभव के बाद, मैं चिंतित हूं।",
              "ka": "ಸರಿ, ಕಂಪನಿಯು LGBTQ+ ಸಮುದಾಯದಿಂದ ಉದ್ಯೋಗಿಗಳನ್ನು ಹೊಂದಿದೆಯೇ ಎಂದು ನಾನು ಕೇಳಿದೆ ಮತ್ತು ಕೆಲವರು ಇದ್ದಾರೆ ಎಂದು ಅವರು ಹೇಳಿದರು. ಅವರು ನಮ್ಮ ಸುರಕ್ಷತೆಗಾಗಿ ಯಾವುದೇ ನೀತಿ ಅಥವಾ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಹೊಂದಿಲ್ಲ ಎಂದು ಹೇಳಿದರು. ನನ್ನ ಹಾಸ್ಟೆಲ್ ಅನುಭವದ ನಂತರ, ನಾನು ಚಿಂತಿತನಾಗಿದ್ದೇನೆ.",
              "te": "కంపెనీకి LGBTQ+ కమ్యూనిటీ నుండి ఉద్యోగులు ఉన్నారా అని నేను అడిగాను మరియు వారు కొంతమంది ఉన్నారని చెప్పారు. మా భద్రత కోసం తమకు ఎలాంటి విధానం లేదా ప్రక్రియ లేదని కూడా వారు చెప్పారు. నా హాస్టల్ అనుభవం తర్వాత, నాకు ఆందోళనగా ఉంది.",
              "or": "ଠିକ୍ ଅଛି, ମୁଁ ପଚାରିଥିଲି ଯେ କମ୍ପାନୀରେ LGBTQ + ସମୁଦାୟର କର୍ମଚାରୀ ଅଛନ୍ତି କି, ଏବଂ ସେମାନେ କହିଥିଲେ ଯେ ସେଠାରେ କିଛି ଅଛନ୍ତି | ଆମର ସୁରକ୍ଷା ପାଇଁ ସେମାନଙ୍କର କୌଣସି ନୀତି କିମ୍ବା ପ୍ରକ୍ରିୟା ନାହିଁ ବୋଲି ସେମାନେ ଏହା ମଧ୍ୟ କହିଛନ୍ତି। ମୋର ହଷ୍ଟେଲ ଅଭିଜ୍ଞତା ପରେ, ମୁଁ ଚିନ୍ତିତ |",
              "as": "ঠিকেই আছে, মই সুধিছিলো যে কোম্পানীটোৰ LGBTQ+ সম্প্ৰদায়ৰ কৰ্মচাৰী আছে নেকি, আৰু তেওঁলোকে কৈছিল যে কেইজনমান আছে। তেওঁলোকে এইটোও কৈছিল যে আমাৰ সুৰক্ষাৰ বাবে তেওঁলোকৰ কোনো নীতি বা প্ৰক্ৰিয়া নাই। মোৰ হোষ্টেলৰ অভিজ্ঞতাৰ পিছত, মই চিন্তিত হৈ আছো।",
              "gu": "સારું, મેં પૂછ્યું કે શું કંપનીમાં LGBTQ+ સમુદાયના કર્મચારીઓ છે, અને તેઓએ કહ્યું કે ત્યાં થોડા છે.  તેઓએ એમ પણ કહ્યું કે અમારી સુરક્ષા માટે તેમની પાસે કોઈ નીતિ કે પ્રક્રિયા નથી.  મારા હોસ્ટેલના અનુભવ પછી, હું ચિંતિત છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "And what about the freelance developer role?",
              "hi": "और फ्रीलांस डेवलपर भूमिका के बारे में क्या?",
              "ka": "ಮತ್ತು ಸ್ವತಂತ್ರ ಡೆವಲಪರ್ ಪಾತ್ರದ ಬಗ್ಗೆ ಏನು?",
              "te": "మరియు ఫ్రీలాన్స్ డెవలపర్ రోల్ సంగతి ఏమిటి?",
              "or": "ଏବଂ ଫ୍ରିଲାନ୍ସ ଡେଭଲପର୍ ରୋଲ୍ ବିଷୟରେ କ’ଣ ?",
              "as": "আৰু ফ্ৰিলেন্স বিকাশকাৰীৰ কামৰ বিষয়ে কি জানিলে?",
              "gu": "અને ફ્રીલાન્સ ડેવલપરના રોલ વિશે શું?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "It has its challenges, but at least I will be safe and could manage my work.",
              "hi": "इसकी चुनौतियां हैं, लेकिन कम से कम मैं सुरक्षित रहूंगा और अपने काम का प्रबंधन कर सकता हूं।",
              "ka": "ಇದು ತನ್ನ ಸವಾಲುಗಳನ್ನು ಹೊಂದಿದೆ, ಆದರೆ ಕನಿಷ್ಠ ನಾನು ಸುರಕ್ಷಿತವಾಗಿರುತ್ತೇನೆ ಮತ್ತು ನನ್ನ ಕೆಲಸವನ್ನು ನಿರ್ವಹಿಸಬಲ್ಲೆ.",
              "te": "దీంట్లో సవాళ్లు ఉన్నాయి, కానీ కనీసం నేను సురక్షితంగా ఉంటాను మరియు నా పనిని చూసుకుంటాను.",
              "or": "ଏହାର ଚ୍ୟାଲେଞ୍ଜ ଅଛି, କିନ୍ତୁ ଅତିକମରେ ମୁଁ ସୁରକ୍ଷିତ ରହିବି ଏବଂ ମୋର କାର୍ଯ୍ୟ ପରିଚାଳନା କରିପାରିବି |",
              "as": "ইয়াৰ প্ৰত্যাহ্বান আছে, কিন্তু অন্ততঃ মই সুৰক্ষিত থাকিম আৰু মোৰ কাম পৰিচালনা কৰিব পাৰিম।",
              "gu": "તેના પણ પડકારો છે, પરંતુ ઓછામાં ઓછું હું સુરક્ષિત રહીશ અને મારું કામ મેનેજ કરી શકીશ."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_3//",
              "hi": "B5_1_3 //",
              "ka": "B5_1_3//",
              "te": "B5_1_3//",
              "or": "B5_1_3//",
              "as": "B5_1_3//",
              "gu": "B5_1_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Hmm... Why don’t we list down the pros (positives) and cons (negatives) for this? It will help you decide.",
              "hi": "हम्म ... क्यों न हम इसके लिए इसकी अच्छाई (सकारात्मक) और बुराई (नकारात्मक) को सूचीबद्ध करें? यह आपको निर्णय लेने में सहायता करेगा।",
              "ka": "ಹಾಂ... ಇದಕ್ಕಾಗಿ ನಾವು ಸಾಧಕ (ಧನಾತ್ಮಕ) ಮತ್ತು ಅನಾನುಕೂಲಗಳನ್ನು (ಋಣಾತ್ಮಕ) ಏಕೆ ಪಟ್ಟಿ ಮಾಡಬಾರದು? ಇದು ನಿಮಗೆ ನಿರ್ಧರಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
              "te": "హా... మనం వీటి అనుకూలతలు (పాజిటివ్‌లు) మరియు నష్టాలు (నెగటివ్ లు) ఎందుకు జాబితా చేయకూడదు? నిర్ణయం తీసుకోవడంలో మీకు ఇది సహాయం చేస్తుంది.",
              "or": "ହମ୍ ... ଆମେ ଏଥିପାଇଁ ଭଲ (ସକାରାତ୍ମକତା) ଏବଂ ଖରାପ (ନକାରାତ୍ମକତା) ତାଲିକାଭୁକ୍ତ କରୁନାହୁଁ କାହିଁକି? ଏହା ତୁମକୁ ନିଷ୍ପତ୍ତି ନେବାରେ ସାହାଯ୍ୟ କରିବ |",
              "as": "হুম... আমি ইয়াৰ বাবে ভাল (ধনাত্মক) আৰু বেয়া (ঋণাত্মক) দিশৰ তালিকা এখন নকৰোঁ কিয়? ই আপোনাক সিদ্ধান্ত লোৱাত সহায় কৰিব।",
              "gu": "હહહહ.. શા માટે આપણે આના ગુણ (સકારાત્મક) અને અવગુણ (નકારાત્મક) સૂચિબદ્ધ નથી કરતા?  તે તને નક્કી કરવામાં મદદ કરશે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_4_reveal",
              "hi": "B5_1_4_रिवील ",
              "ka": "B5_1_4_reveal",
              "te": "B5_1_4_రివీల్",
              "or": "B5_1_4_reveal",
              "as": "B5_1_4_প্ৰকাশ কৰে",
              "gu": "B5_1_4_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal the positives and negatives for joining MetaLab:",
              "hi": "मेटा लैब में शामिल होने के लिए सकारात्मक और नकारात्मक प्रकट करने के लिए क्लिक करें:",
              "ka": "ಮೆಟಾಲ್ಯಾಬ್‌ ಗೆ ಸೇರಲು ಧನಾತ್ಮಕ ಮತ್ತು ನಿರಾಕರಣೆಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "MetaLabలో చేరడానికి పాజిటివ్ మరియు నెగటివ్ అంశాలను రివీల్ చేయడానికి క్లిక్ చేయండి:",
              "or": "ମେଟାଲ୍ୟାବ୍‌ରେ ଯୋଗଦେବା ପାଇଁ ସକରାତ୍ମକତା ଏବଂ ନକାରାତ୍ମକତା ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "মেটালেবত যোগদান কৰাৰ বাবে ভাল আৰু বেয়া দিশবোৰ প্ৰকাশ কৰিবলৈ ক্লিক কৰক:",
              "gu": "મૅટામાં જોડાવા માટેના સકારાત્મક અને નકારાત્મક પક્ષોનેજાણવા માટે ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Positive",
              "hi": "सकारात्मक",
              "ka": "ಧನಾತ್ಮಕ",
              "te": "పాజిటివ్",
              "or": "ସକରାତ୍ମକ",
              "as": "ভালদিশ",
              "gu": "સકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Positive",
              "hi": "सकारात्मक",
              "ka": "ಧನಾತ್ಮಕ",
              "te": "పాజిటివ్",
              "or": "ସକରାତ୍ମକ",
              "as": "ভালদিশ",
              "gu": "સકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Positive",
              "hi": "सकारात्मक",
              "ka": "ಧನಾತ್ಮಕ",
              "te": "పాజిటివ్",
              "or": "ସକରାତ୍ମକ",
              "as": "ভালদিশ",
              "gu": "સકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "Negative",
              "hi": "नकारात्मक",
              "ka": "ಋಣಾತ್ಮಕ",
              "te": "నెగటివ్",
              "or": "ନକାରାତ୍ମକ",
              "as": "বেয়াদিশ",
              "gu": "નકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.4.beforeReveal",
            "value": {
              "en": "Negative",
              "hi": "नकारात्मक",
              "ka": "ಋಣಾತ್ಮಕ",
              "te": "నెగటివ్",
              "or": "ନକାରାତ୍ମକ",
              "as": "বেয়াদিশ",
              "gu": "નકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "It is a leading technology organisation, and I get a steady salary.",
              "hi": "यह एक अग्रणी प्रौद्योगिकी संगठन है, और मुझे एक स्थिर वेतन मिलेगा।",
              "ka": "ಇದು ಪ್ರಮುಖ ತಂತ್ರಜ್ಞಾನ ಸಂಸ್ಥೆಯಾಗಿದ್ದು, ನಾನು ಸ್ಥಿರವಾದ ಸಂಬಳವನ್ನು ಪಡೆಯುತ್ತೇನೆ.",
              "te": "ఇది ప్రముఖ టెక్నాలజీ సంస్థ, నేను స్థిరమైన జీతం పొందుతాను.",
              "or": "ଏହା ଏକ ଅଗ୍ରଣୀ ଟେକ୍ନୋଲୋଜି ସଂଗଠନ, ଏବଂ ମୁଁ ଏକ ସ୍ଥିର ବେତନ ପାଏ |",
              "as": "ই এক আগশাৰীৰ প্ৰযুক্তি প্ৰতিষ্ঠান, আৰু মই এটা স্থিৰ দৰমহা পাম।",
              "gu": "તે એક અગ્રણી ટેક્નોલોજી સંસ્થા છે અને મને સતત પગાર મળતો રહેશે ."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "I can take more financial responsibility at home.",
              "hi": "मैं घर पर अधिक वित्तीय जिम्मेदारी ले सकूंगा।",
              "ka": "ನಾನು ಮನೆಯಲ್ಲಿ ಹೆಚ್ಚಿನ ಆರ್ಥಿಕ ಜವಾಬ್ದಾರಿಯನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು.",
              "te": "నేను ఇంట్లో మరింత ఆర్థిక బాధ్యత తీసుకోగలను.",
              "or": "ମୁଁ ଘରେ ଅଧିକ ଆର୍ଥିକ ଦାୟିତ୍ବ ନେଇପାରିବି |",
              "as": "মই ঘৰত অধিক আৰ্থিক দায়িত্ব ল'ব পাৰো।",
              "gu": "હું ઘરે વધુ નાણાકીય જવાબદારી લઈ શકું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "I can work in a team and learn a lot from them.",
              "hi": "मैं एक टीम में काम कर सकूंगा और उनसे बहुत कुछ सीख सकूंगा।",
              "ka": "ನಾನು ತಂಡದಲ್ಲಿ ಕೆಲಸ ಮಾಡಬಹುದು ಮತ್ತು ಅವರಿಂದ ಬಹಳಷ್ಟು ಕಲಿಯಬಹುದು.",
              "te": "నేను టీమ్ తో కలిసి పని చేయగలను మరియు వారి నుండి చాలా నేర్చుకోవచ్చు.",
              "or": "ମୁଁ ଏକ ଦଳରେ କାମ କରିପାରିବି ଏବଂ ସେମାନଙ୍କଠାରୁ ବହୁତ କିଛି ଶିଖିପାରିବି |",
              "as": "মই এটা দলত কাম কৰিব পাৰো আৰু তেওঁলোকৰ পৰা বহুত শিকিব পাৰো।",
              "gu": "હું ટીમમાં કામ કરી શકું છું અને તેમની પાસેથી ઘણું શીખી શકું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "I don’t have clarity on the company policy and am worried about my safety.",
              "hi": "मेरे पास कंपनी की नीति पर स्पष्टता नहीं है और अपनी सुरक्षा के बारे में चिंतित हूँ।",
              "ka": "ಕಂಪನಿಯ ನೀತಿಯ ಬಗ್ಗೆ ನನಗೆ ಸ್ಪಷ್ಟತೆ ಇಲ್ಲ ಮತ್ತು ನನ್ನ ಸುರಕ್ಷತೆಯ ಬಗ್ಗೆ ಚಿಂತಿತನಾಗಿದ್ದೇನೆ.",
              "te": "కంపెనీ పాలసీపై నాకు స్పష్టత లేదు మరియు నా భద్రత గురించి ఆందోళన చెందుతున్నాను.",
              "or": "କମ୍ପାନୀ ନୀତି ଉପରେ ମୋର ସ୍ପଷ୍ଟତା ନାହିଁ ଏବଂ ମୁଁ ମୋର ସୁରକ୍ଷା ପାଇଁ ଚିନ୍ତିତ |",
              "as": "কোম্পানীৰ নীতিৰ বিষয়ে মোৰ স্পষ্টতা নাই আৰু মই মোৰ সুৰক্ষাৰ বিষয়ে চিন্তিত।",
              "gu": "મને કંપનીની નીતિ અંગે સ્પષ્ટતા નથી અને હું મારી સલામતી વિશે ચિંતિત છું."
            }
          },
          {
            "valuePath": "blocks.0.props.options.4.afterReveal",
            "value": {
              "en": "I am scared of how people will treat me.",
              "hi": "मैं डरता हूं कि लोग मेरे साथ कैसे व्यवहार करेंगे।",
              "ka": "ಜನರು ನನ್ನನ್ನು ಹೇಗೆ ನಡೆಸಿಕೊಳ್ಳುತ್ತಾರೆ ಎಂದು ನನಗೆ ಭಯವಾಗಿದೆ.",
              "te": "అక్కడ వ్యక్తులు నన్ను ఎలా చూస్తారో అని నేను భయపడుతున్నాను.",
              "or": "ଲୋକମାନେ ମୋତେ କିପରି ବ୍ୟବହାର କରିବେ ମୁଁ ଭୟଭୀତ ଲୋକମାନେ ମୋତେ କିପରି ବ୍ୟବହାର କରିବେ |",
              "as": "মানুহে মোক কেনেদৰে ব্যৱহাৰ কৰিব তাক লৈ মই ভয় খাইছো।",
              "gu": "મને ડર લાગે છે કે લોકો મારી સાથે કેવું વર્તન કરશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_4b_reveal",
              "hi": "B5_1_4b_रिवील ",
              "ka": "B5_1_4b_reveal",
              "te": "B5_1_4b_రివీల్",
              "or": "B5_1_4b_reveal",
              "as": "B5_1_4b_প্ৰকাশ কৰে",
              "gu": "B5_1_4b_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal the positives and negatives for doing freelance work:",
              "hi": "फ्रीलांस कार्य करने के लिए सकारात्मक और नकारात्मक प्रकट करने के लिए क्लिक करें:",
              "ka": "ಸ್ವತಂತ್ರ ಕೆಲಸ ಮಾಡಲು ಧನಾತ್ಮಕ ಮತ್ತು ಋಣಾತ್ಮಕಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "ఫ్రీలాన్స్ వర్క్ చేయడం యొక్క పాజిటివ్ మరియు నెగటివ్‌లను రివీల్ చేయడానికి క్లిక్ చేయండి:",
              "or": "ଫ୍ରିଲାନ୍ସ କାମ କରିବା ପାଇଁ ସକରାତ୍ମକତା ଏବଂ ନକାରାତ୍ମକତା ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "ফ্ৰিলেন্স কাম কৰাৰ বাবে ভাল আৰু বেয়া দিশবোৰ প্ৰকাশ কৰিবলৈ ক্লিক কৰক:",
              "gu": "ફ્રીલાન્સ કામ કરવા માટેના સકારાત્મક અને નકારાત્મક પક્ષોને જોવા માટે ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Positive",
              "hi": "सकारात्मक",
              "ka": "ಧನಾತ್ಮಕ",
              "te": "పాజిటివ్",
              "or": "ସକରାତ୍ମକ",
              "as": "ভালদিশ",
              "gu": "સકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Positive",
              "hi": "सकारात्मक",
              "ka": "ಧನಾತ್ಮಕ",
              "te": "పాజిటివ్",
              "or": "ସକରାତ୍ମକ",
              "as": "ভালদিশ",
              "gu": "સકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Negative",
              "hi": "नकारात्मक",
              "ka": "ಋಣಾತ್ಮಕ",
              "te": "నెగటివ్",
              "or": "ନକାରାତ୍ମକ",
              "as": "বেয়াদিশ",
              "gu": "નકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "Negative",
              "hi": "नकारात्मक",
              "ka": "ಋಣಾತ್ಮಕ",
              "te": "నెగటివ్",
              "or": "ନକାରାତ୍ମକ",
              "as": "বেয়াদিশ",
              "gu": "નકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.4.beforeReveal",
            "value": {
              "en": "Negative",
              "hi": "नकारात्मक",
              "ka": "ಋಣಾತ್ಮಕ",
              "te": "నెగటివ్",
              "or": "ନକାରାତ୍ମକ",
              "as": "বেয়াদিশ",
              "gu": "નકારાત્મક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "I can work from home, and that will be safe for me.",
              "hi": "मैं घर से काम कर सकता हूं, और यह मेरे लिए सुरक्षित होगा।",
              "ka": "ನಾನು ಮನೆಯಿಂದಲೇ ಕೆಲಸ ಮಾಡಬಹುದು ಮತ್ತು ಅದು ನನಗೆ ಸುರಕ್ಷಿತವಾಗಿರುತ್ತದೆ",
              "te": "నేను ఇంటి నుండి పని చేయగలను మరియు అది నాకు సురక్షితంగా ఉంటుంది.",
              "or": "ମୁଁ ଘରୁ କାମ କରିପାରିବି, ଏବଂ ଏହା ମୋ ପାଇଁ ସୁରକ୍ଷିତ ରହିବ |",
              "as": "মই ঘৰৰ পৰা কাম কৰিব পাৰো, আৰু সেয়া মোৰ বাবে সুৰক্ষিত হ'ব।",
              "gu": "હું ઘરેથી કામ કરી શકું છું, અને તે મારા માટે સલામત ભર્યું રહેશે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "I can work for international clients also.",
              "hi": "मैं अंतरराष्ट्रीय ग्राहकों के लिए भी काम कर सकूंगा।",
              "ka": "ನಾನು ಅಂತರರಾಷ್ಟ್ರೀಯ ಗ್ರಾಹಕರಿಗಾಗಿಯೂ ಕೆಲಸ ಮಾಡಬಹುದು.",
              "te": "నేను అంతర్జాతీయ క్లయింట్‌ల కోసం కూడా పని చేయగలను.",
              "or": "ମୁଁ ଆନ୍ତର୍ଜାତୀୟ କ୍ଲାଏଣ୍ଟମାନଙ୍କ ପାଇଁ ମଧ୍ୟ କାମ କରିପାରିବି |",
              "as": "মই আন্তৰ্জাতিক গ্ৰাহকৰ বাবেও কাম কৰিব পাৰো।",
              "gu": "હું આંતરરાષ્ટ્રીય ગ્રાહકો માટે પણ કામ કરી શકું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "I will be isolated and have to figure things out on my own.",
              "hi": "मैं अकेला हो जाऊँगा और चीजों को अपने आप समझना होगा।",
              "ka": "ನಾನು ಪ್ರತ್ಯೇಕವಾಗಿರುತ್ತೇನೆ ಮತ್ತು ನನ್ನದೇ ಆದ ವಿಷಯಗಳನ್ನು ಕಂಡುಹಿಡಿಯಬೇಕು.",
              "te": "నేను ఒంటరిగా ఉంటాను మరియు విషయాలను నా స్వంతంగా నేర్చుకోవాలి.",
              "or": "ମୁଁ ଏକୁଟିଆ ହୋଇଯିବି ଏବଂ ମୋତେ ନିଜେ ବାହାର କରିବାକୁ ପଡିବ |",
              "as": "মই বিচ্ছিন্ন হৈ পৰিম আৰু বস্তুবোৰৰ বাবে মই নিজে ইচ্ছুক হ'ব লাগিব।",
              "gu": "હું અલગ થઈ જઈશ અને મારી જાતે જ સમાધાન શોધી કાઢવી પડશે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "The income might not be steady, and I may not be able to financially support my family.",
              "hi": "आय स्थिर नहीं हो सकती है, और मैं अपने परिवार को आर्थिक रूप से समर्थन नहीं कर सकता।",
              "ka": "ಆದಾಯವು ಸ್ಥಿರವಾಗಿಲ್ಲದಿರಬಹುದು ಮತ್ತು ನನ್ನ ಕುಟುಂಬವನ್ನು ಆರ್ಥಿಕವಾಗಿ ಬೆಂಬಲಿಸಲು ನನಗೆ ಸಾಧ್ಯವಾಗದಿರಬಹುದು.",
              "te": "ఆదాయం స్థిరంగా ఉండకపోవచ్చు మరియు నేను నా కుటుంబాన్ని ఆర్థికంగా పోషించలేకపోవచ్చు.",
              "or": "ଆୟ ସ୍ଥିର ହୋଇନପାରେ, ଏବଂ ମୁଁ ମୋ ପରିବାରକୁ ଆର୍ଥିକ ସହାୟତା କରିବାକୁ ସମର୍ଥ ହୋଇନପାରେ |",
              "as": "উপাৰ্জন স্থিৰ নহ'বও পাৰে, আৰু মই মোৰ পৰিয়ালক আৰ্থিকভাৱে সহায় কৰিব নোৱাৰিম।",
              "gu": "આવક સ્થિર ન હોઈ શકે, અને હું મારા પરિવારને આર્થિક રીતે મદદ ન કરી શકું."
            }
          },
          {
            "valuePath": "blocks.0.props.options.4.afterReveal",
            "value": {
              "en": "I won't be able to learn from my peers and work in a team.",
              "hi": "मैं अपने साथियों से सीखने और टीम में काम करने में सक्षम नहीं होऊँगा।",
              "ka": "ನನ್ನ ಗೆಳೆಯರಿಂದ ಕಲಿಯಲು ಮತ್ತು ತಂಡದಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ನನಗೆ ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ.",
              "te": "నేను నా తోటివారి నుండి నేర్చుకోలేను మరియు టీమ్ లో పని చేయలేను.",
              "or": "ମୁଁ ମୋ ପ୍ରତିଷ୍ଠିତମାନଙ୍କଠାରୁ ଶିଖିବାକୁ ଏବଂ ଏକ ଦଳରେ କାମ କରିବାକୁ ସମର୍ଥ ହେବି ନାହିଁ |",
              "as": "মই মোৰ সহকৰ্মীসকলৰ পৰা শিকিব নোৱাৰিম আৰু এটা দলত কাম কৰিব নোৱাৰিম।",
              "gu": "હું મારા સાથીદારો પાસેથી શીખી  નહીં શકું અને ટીમમાં કામ કરી શકીશ નહીં."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "B5_1_5_D",
              "hi": "B5_1_5_D",
              "ka": "B5_1_5_D",
              "te": "B5_1_5_D",
              "or": "B5_1_5_D",
              "as": "B5_1_5_D",
              "gu": "B5_1_5_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Arvind do?",
              "hi": "अरविंद को क्या करना चाहिए?",
              "ka": "ಅರವಿಂದ್ ಏನು ಮಾಡಬೇಕು?",
              "te": "అరవింద్ ఏం చేయాలి?",
              "or": "ଅରବିନ୍ଦ କ’ଣ କରିବା ଉଚିତ୍ ?",
              "as": "অৰবিন্দে কি কৰা উচিত?",
              "gu": "અરવિંદે શું કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Take up the job offer from MetaLab in Hyderabad",
              "hi": "हैदराबाद में मेटा लैब से नौकरी का प्रस्ताव स्वीकार करे",
              "ka": "ಹೈದರಾಬಾದ್‌ನಲ್ಲಿರುವ ಮೆಟಾಲ್ಯಾಬ್‌ನಿಂದ ಉದ್ಯೋಗದ ಪ್ರಸ್ತಾಪವನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ",
              "te": "హైదరాబాద్‌లోని MetaLab లో జాబ్ ఆఫర్‌ని తీసుకోవాలి",
              "or": "ହାଇଦ୍ରାବାଦରେ ମେଟାଲ୍ୟାବ୍‌ରୁ ଚାକିରି ଅଫର ନିଅନ୍ତୁ",
              "as": "হায়দৰাবাদৰ মেটালেবৰ পৰা চাকৰিৰ প্ৰস্তাৱ গ্ৰহণ কৰক",
              "gu": "હૈદરાબાદમાં મેટાલેબની  નોકરીની ઑફર સ્વીકાર કરી લેવી જોઈએ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Work as a freelance developer to be in a safe environment",
              "hi": "एक सुरक्षित वातावरण में रहने के लिए एक फ्रीलांस डेवलपर के रूप में काम करे",
              "ka": "ಸುರಕ್ಷಿತ ವಾತಾವರಣದಲ್ಲಿರಲು ಸ್ವತಂತ್ರ ಡೆವಲಪರ್ ಆಗಿ ಕೆಲಸ ಮಾಡಿ",
              "te": "సురక్షితమైన వాతావరణంలో ఉండటానికి ఫ్రీలాన్స్ డెవలపర్‌గా పని చేయాలి",
              "or": "ଏକ ନିରାପଦ ପରିବେଶରେ ରହିବାକୁ ଏକ ଫ୍ରିଲାନ୍ସ ଡେଭଲପର୍ ଭାବରେ କାର୍ଯ୍ୟ କରନ୍ତୁ |",
              "as": "সুৰক্ষিত পৰিৱেশত থাকিবলৈ ফ্ৰিলেন্স বিকাশক হিচাপে কাম কৰক",
              "gu": "સુરક્ષિત વાતાવરણમાં રહેવા માટે ફ્રીલાન્સ ડેવલપર તરીકે કામ કરવું જોઈએ"
            }
          }
        ]
      },
      "scene25": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Work in MetaLab",
              "hi": "मेटा लैब में काम",
              "ka": "ಮೆಟಾಲ್ಯಾಬ್‌ ನಲ್ಲಿ ಕೆಲಸ ಮಾಡಿ",
              "te": "MetaLabలో పని చేయాలి",
              "or": "ମେଟାଲ୍ୟାବ୍‌ ରେ କାମ କରନ୍ତୁ",
              "as": "মেটালেবত কাম",
              "gu": "મેટાલેબમાં કામ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind joins MetaLab as a trainee. He has been assigned to a small team with a mixed group of freshers and people with some work experience.",
              "hi": "अरविंद एक प्रशिक्षु के रूप में मेटा लैब में शामिल हो गया। उसे फ्रेशर्स एवं कुछ कार्य अनुभव वाले लोगों के मिश्रित समूह वाली एक छोटी सी टीम में लगाया गया है।",
              "ka": "ಅರವಿಂದ್ ಮೆಟಾಲ್ಯಾಬ್‌ಗೆ ಟ್ರೈನಿಯಾಗಿ ಸೇರುತ್ತಾರೆ. ಫ್ರೆಶರ್‌ಗಳು ಮತ್ತು ಕೆಲವು ಕೆಲಸದ ಅನುಭವ ಹೊಂದಿರುವ ಜನರ ಮಿಶ್ರ ಗುಂಪಿನೊಂದಿಗೆ ಅವರನ್ನು ಸಣ್ಣ ತಂಡಕ್ಕೆ ನಿಯೋಜಿಸಲಾಗಿದೆ.",
              "te": "అరవింద్ మెటాల్యాబ్‌లో ట్రైనీగా చేరాడు. అతను ఫ్రెషర్లు మరియు కొంత పని అనుభవం ఉన్న వ్యక్తుల మిశ్రమం కల ఒక చిన్న గ్రూప్ కేటాయించబడ్డాడు.",
              "or": "ଅରବିନ୍ଦ ଜଣେ ଟ୍ରେଣି ଭାବରେ ମେଟାଲ୍ୟାବ୍‌ରେ ଯୋଗ ଦିଅନ୍ତି | ତାଙ୍କୁ ଏକ ଛୋଟ ଦଳରେ ଫ୍ରେଶର୍‌ ଏବଂ କିଛି କାର୍ଯ୍ୟ ଅଭିଜ୍ଞତା ଥିବା ଲୋକଙ୍କ ମିଶ୍ରିତ ଗୋଷ୍ଠୀ ସହିତ ନ୍ଯସ୍ତ କରାଯାଇଛି |",
              "as": "অৰবিন্দে মেটালেবত প্ৰশিক্ষাৰ্থী হিচাপে যোগদান কৰে। তেওঁক এটা সৰু দলত নিযুক্ত কৰা হৈছে য'ত এক মিশ্ৰিত দলত নতুন আৰু কিছু কামৰ অভিজ্ঞতা থকা লোক আছে।",
              "gu": "અરવિંદ મેટાલેબમાં તાલીમાર્થી તરીકે જોડાય છે.  તેને ફ્રેશર્સ અને કેટલાક કામનો અનુભવ ધરાવતા લોકોના મિશ્ર જૂથ સાથે નાની ટીમમાં સોંપવામાં આવ્યો ."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_1_2",
              "hi": "D1_1_2",
              "ka": "D1_1_2",
              "te": "D1_1_2",
              "or": "D1_1_2",
              "as": "D1_1_2",
              "gu": "D1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The company has launched a new product and has hired a few engineering graduates for multiple roles. All freshers are supposed to undergo training in technology and sales.",
              "hi": "कंपनी ने एक नया उत्पाद लॉन्च किया है और बहुमुखी भूमिकाओं के लिए कुछ इंजीनियरिंग स्नातकों को काम पर रखा है। सभी फ्रेशर्स को प्रौद्योगिकी और बिक्री में प्रशिक्षण प्राप्त करना है।",
              "ka": "ಕಂಪನಿಯು ಹೊಸ ಉತ್ಪನ್ನವನ್ನು ಬಿಡುಗಡೆ ಮಾಡಿದೆ ಮತ್ತು ಕೆಲವು ಇಂಜಿನಿಯರಿಂಗ್ ಪದವೀಧರರನ್ನು ಬಹು ಪಾತ್ರಗಳಿಗೆ ನೇಮಿಸಿಕೊಂಡಿದೆ. ಎಲ್ಲಾ ಫ್ರೆಶರ್‌ಗಳು ತಂತ್ರಜ್ಞಾನ ಮತ್ತು ಮಾರಾಟದಲ್ಲಿ ತರಬೇತಿ ಪಡೆಯಬೇಕು.",
              "te": "కంపెనీ కొత్త ఉత్పత్తిని ప్రారంభించింది మరియు వివిధ రోల్స్ కోసం కొంతమంది ఇంజనీరింగ్ గ్రాడ్యుయేట్‌లను నియమించుకుంది. ఫ్రెషర్స్ అందరూ టెక్నాలజీ మరియు సేల్స్‌లో శిక్షణ పొందవలసి ఉంటుంది.",
              "or": "କମ୍ପାନୀ ଏକ ନୂତନ ଉତ୍ପାଦ ଲଞ୍ଚ୍‌ କରିଛି ଏବଂ ଏକାଧିକ ଭୂମିକା ପାଇଁ କିଛି ଇଞ୍ଜିନିୟରିଂ ସ୍ନାତକମାନଙ୍କୁ ନିଯୁକ୍ତ କରିଛି | ସମସ୍ତ ଫ୍ରେଶର୍‌ ଟେକ୍ନୋଲୋଜି ଏବଂ ସେଲ୍ସ ଉପରେ ତାଲିମ ଦେଇ ଗତି କରିବା ଉଚିତ୍ |",
              "as": "কোম্পানীটোৱে এটা নতুন সামগ্ৰী মুকলি কৰিছে আৰু একাধিক কামৰ বাবে কেইজনমান অভিযান্ত্ৰিক স্নাতকক নিয়োগ কৰিছে। সকলো নতুন কেইজনে প্ৰযুক্তি আৰু বিক্ৰীৰ প্ৰশিক্ষণ লোৱাৰ কথা।",
              "gu": "કંપનીએ એક નવી પ્રોડક્ટ લૉન્ચ કરી છે અને ઘણાબધા રોલ્સ માટે થોડા એન્જિનિયરિંગ સ્નાતકોને હાયર કર્યા છે.  તમામ ફ્રેશર્સે ટેક્નોલોજી અને સેલની તાલીમ લેવાની છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_1_3",
              "hi": "D1_1_3",
              "ka": "D1_1_3",
              "te": "D1_1_3",
              "or": "D1_1_3",
              "as": "D1_1_3",
              "gu": "D1_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Based on the performance and interest shown in training, they get allocated for different roles.",
              "hi": "प्रशिक्षण में दिखाए गए प्रदर्शन और रुचि के आधार पर, उन्हें विभिन्न भूमिकाओं के लिए निर्धारित किया जाता है।",
              "ka": "ತರಬೇತಿಯಲ್ಲಿ ತೋರಿದ ಕಾರ್ಯಕ್ಷಮತೆ ಮತ್ತು ಆಸಕ್ತಿಯ ಆಧಾರದ ಮೇಲೆ, ಅವರು ವಿಭಿನ್ನ ಪಾತ್ರಗಳಿಗೆ ನಿಯೋಜಿಸಲ್ಪಡುತ್ತಾರೆ.",
              "te": "శిక్షణలో చూపిన పనితీరు మరియు ఆసక్తి ఆధారంగా, వారు విభిన్న పాత్రలకు కేటాయించబడతారు.",
              "or": "ଟ୍ରେଣିଂରେ ପ୍ରଦର୍ଶିତ କାର୍ଯ୍ୟଦକ୍ଷତା ଏବଂ ଆଗ୍ରହ ଉପରେ ଆଧାର କରି, ସେମାନେ ବିଭିନ୍ନ ଭୂମିକା ପାଇଁ ଆବଣ୍ଟିତ ହୁଅନ୍ତି |",
              "as": "প্ৰশিক্ষণত দেখুওৱা প্ৰদৰ্শন আৰু আগ্ৰহৰ ওপৰত আধাৰিত কৰি, তেওঁলোকক বিভিন্ন কামৰ বাবে আৱণ্টন কৰা হয়।",
              "gu": "તાલીમમાં દર્શાવવામાં આવેલ પ્રદર્શન અને રુચીના આધારે, તેઓને વિવિધ રોલ  માટે ફાળવવામાં આવે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_1_4",
              "hi": "D1_1_4",
              "ka": "D1_1_4",
              "te": "D1_1_4",
              "or": "D1_1_4",
              "as": "D1_1_4",
              "gu": "D1_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The training is crucial for the trainees to understand the two departments. It is a way for the organisation to onboard new employees.",
              "hi": "प्रशिक्षुओं को दो विभागों को समझने के लिए प्रशिक्षण महत्वपूर्ण है। यह संगठन के लिए नए कर्मचारियों को शामिल करने का एक तरीका है।",
              "ka": "ಎರಡು ವಿಭಾಗಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಪ್ರಶಿಕ್ಷಣಾರ್ಥಿಗಳಿಗೆ ತರಬೇತಿಯು ನಿರ್ಣಾಯಕವಾಗಿದೆ. ಹೊಸ ಉದ್ಯೋಗಿಗಳನ್ನು ಆನ್‌ಬೋರ್ಡ್ ಮಾಡಲು ಸಂಸ್ಥೆಗೆ ಇದು ಒಂದು ಮಾರ್ಗವಾಗಿದೆ.",
              "te": "ట్రైనీలు రెండు విభాగాలను అర్థం చేసుకోవడానికి శిక్షణ చాలా కీలకం. సంస్థ కొత్త ఉద్యోగులను చేర్చుకోవడానికి ఇది ఒక మార్గం.",
              "or": "ଟ୍ରେଣିମାନଙ୍କ ପାଇଁ ଦୁଇଟି ବିଭାଗ ବୁଝିବା ପାଇଁ ଏହି ଟ୍ରେଣିଂ ଅତ୍ୟନ୍ତ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ଅଟେ | ନୂତନ କର୍ମଚାରୀଙ୍କ ସଂଗଠନରେ ଆଣିବା ପାଇଁ ଏହା ଏକ ଉପାୟ |",
              "as": "প্ৰশিক্ষাৰ্থী সকলৰ বাবে দুয়োটা বিভাগ বুজি পাবলৈ প্ৰশিক্ষণটো গুৰুত্বপূৰ্ণ। এইটো প্ৰতিষ্ঠানটোৰ বাবে নতুন কৰ্মচাৰী লোৱাৰ এটা উপায়।",
              "gu": "તાલીમાર્થીઓ માટે આ બે વિભાગોને સમજવા માટે તાલીમ મહત્વપૂર્ણ છે.  તે સંસ્થા માટે નવા કર્મચારીઓને ઓનબોર્ડ કરવાનો એક માર્ગ છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_1_5",
              "hi": "D1_1_5",
              "ka": "D1_1_5",
              "te": "D1_1_5",
              "or": "D1_1_5",
              "as": "D1_1_5",
              "gu": "D1_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The training is for 6 months. 3 months are spent in Technology training and 3 months for Sales and Business Development.",
              "hi": "प्रशिक्षण 6 महीने के लिए है। प्रौद्योगिकी प्रशिक्षण में 3 महीने और बिक्री और व्यवसाय विकास के लिए 3 महीने समय लगता है।",
              "ka": "ತರಬೇತಿಯು 6 ತಿಂಗಳವರೆಗೆ ಇರುತ್ತದೆ. ತಂತ್ರಜ್ಞಾನ ತರಬೇತಿಯಲ್ಲಿ 3 ತಿಂಗಳು ಮತ್ತು ಮಾರಾಟ ಮತ್ತು ವ್ಯಾಪಾರ ಅಭಿವೃದ್ಧಿಗಾಗಿ 3 ತಿಂಗಳುಗಳನ್ನು ಕಳೆಯಲಾಗುತ್ತದೆ.",
              "te": "శిక్షణ 6 నెలల పాటు ఉంటుంది. 3 నెలలు టెక్నాలజీ శిక్షణలో మరియు 3 నెలలు సేల్స్ మరియు బిజినెస్ డెవలప్‌మెంట్ కోసం వెచ్చిస్తారు.",
              "or": "ଟ୍ରେଣିଂ 6 ମାସ ପାଇଁ | ଟେକ୍ନୋଲୋଜି ଟ୍ରେଣିଂରେ 3 ମାସ ଏବଂ ସେଲ୍ସ ଏବଂ ବିଜନେସ୍‌ ଡେଭଲପମେଣ୍ଟ୍‍ ପାଇଁ 3 ମାସ ଅତିବାହିତ ହୁଏ |",
              "as": "প্ৰশিক্ষণটো 6 মাহৰ বাবে। প্ৰযুক্তি প্ৰশিক্ষণত 3 মাহ আৰু বিক্ৰী আৰু ব্যৱসায় বিকাশৰ বাবে 3 মাহ অতিবাহিত কৰা হয়।",
              "gu": "તાલીમ 6 મહિના માટે છે.  3 મહિના ટેક્નોલોજી તાલીમમાં અને 3 મહિના સેલ્સ અને બિઝનેસ ડેવલપમેન્ટ માટે રખાયેલ છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1_1_6_checklist",
              "hi": "D1_1_6_चेकलिस्ट ",
              "ka": "D1_1_6_checklist",
              "te": "D1_1_6_చెక్‌లిస్ట్",
              "or": "D1_1_6_checklist",
              "as": "D1_1_6_চেকলিষ্ট",
              "gu": "D1_1_6_યાદી તપાસો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click on each to find out what happens in the 2 departments.",
              "hi": "दोनों विभागों में क्या होता है यह जानने के लिए प्रत्येक पर क्लिक करें।",
              "ka": "2 ಇಲಾಖೆಗಳಲ್ಲಿ ಏನಾಗುತ್ತದೆ ಎಂಬುದನ್ನು ಕಂಡುಹಿಡಿಯಲು ಪ್ರತಿಯೊಂದರ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "2 విభాగాలలో ఏమి జరుగుతుందో తెలుసుకోవడానికి ప్రతిదానిపై క్లిక్ చేయండి.",
              "or": "2 ଟି ବିଭାଗରେ କ’ଣ ହୁଏ ଜାଣିବା ପାଇଁ ପ୍ରତ୍ୟେକ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "2 টা বিভাগত কি ঘটে জানিবলৈ প্ৰতিটোতে ক্লিক কৰক।",
              "gu": "2 વિભાગોમાં શું થાય છે તે જાણવા માટે દરેક પર ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Technology",
              "hi": "प्रौद्योगिकी",
              "ka": "ತಂತ್ರಜ್ಞಾನ",
              "te": "టెక్నాలజీ",
              "or": "ଟେକ୍ନୋଲୋଜି",
              "as": "প্ৰযুক্তি",
              "gu": "ટેક્નોલોજી"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Sales and Business Development",
              "hi": "बिक्री और व्यापार विकास",
              "ka": "ಮಾರಾಟ ಮತ್ತು ವ್ಯಾಪಾರ ಅಭಿವೃದ್ಧಿ",
              "te": "సేల్స్ మరియు బిజినెస్ డెవలప్మెంట్",
              "or": "ସେଲ୍ସ ଆଉ ବିଜନେସ୍‌ ଡେଭଲପମେଣ୍ଟ୍‍",
              "as": "বিক্ৰী আৰু ব্যৱসায় বিকাশ",
              "gu": "સેલ્સ અને બિઝનેસ ડેવલપમેન્ટ"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "Training",
              "hi": "प्रशिक्षण",
              "ka": "ತರಬೇತಿ",
              "te": "శిక్షణ",
              "or": "ଟ୍ରେନିଙ୍ଗ୍‌",
              "as": "প্ৰশিক্ষণ",
              "gu": "તાલીમ"
            }
          }
        ]
      },
      "scene26": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1i_1_1",
              "hi": "D1i_1_1",
              "ka": "D1i_1_1",
              "te": "D1i_1_1",
              "or": "D1i_1_1",
              "as": "D1i_1_1",
              "gu": "D1i_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "In the Technology training, the lead shares all the knowledge about the software, like the technical frameworks.",
              "hi": "प्रौद्योगिकी प्रशिक्षण में, टीम प्रमुख तकनीकी ढांचे की तरह, सॉफ्टवेयर के बारे में सभी ज्ञान साझा करता है।",
              "ka": "ಟೆಕ್ನಾಲಜಿ ತರಬೇತಿಯಲ್ಲಿ, ಪ್ರಮುಖ ತಂತ್ರಾಂಶದ ಬಗ್ಗೆ ಎಲ್ಲಾ ಜ್ಞಾನವನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತದೆ, ಉದಾಹರಣೆಗೆ ತಾಂತ್ರಿಕ ಚೌಕಟ್ಟುಗಳು.",
              "te": "టెక్నాలజీ శిక్షణలో, టెక్నికల్ ఫ్రేమ్‌వర్క్‌ల వంటి సాఫ్ట్‌వేర్ గురించిన మొత్తం జ్ఞానాన్ని లీడ్ షేర్ చేస్తారు.",
              "or": "ଟେକ୍ନୋଲୋଜି ଟ୍ରେଣିଂରେ, ଲିଡ୍ ଟେକ୍ନିକାଲ୍ ଫ୍ରେମୱାର୍କ ପରି ସଫ୍ଟୱେୟର୍ ବିଷୟରେ ସମସ୍ତ ଜ୍ଞାନ ଅଂଶୀଦାର କରନ୍ତି |",
              "as": "প্ৰযুক্তি প্ৰশিক্ষণত, মুখ্য জনে ছফ্টৱেৰৰ বিষয়ে থকা সকলো জ্ঞান আলোচনা কৰে, যেনে কাৰিকৰী ফ্ৰেমৱৰ্ক।",
              "gu": "ટેક્નોલોજી તાલીમમાં, લીડ,  ટેકનિકલ ફ્રેમવર્ક જેવાં સોફ્ટવેર વિશેના તમામ જ્ઞાનને શેર કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1i_1_2",
              "hi": "D1I_1_2",
              "ka": "D1i_1_2",
              "te": "D1i_1_2",
              "or": "D1i_1_2",
              "as": "D1i_1_2",
              "gu": "D1i_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As part of the training, they also go through pre-recorded lectures and tests. Once they complete the training, they are given a certificate.",
              "hi": "प्रशिक्षण के हिस्से के रूप में, वे पहले से रिकॉर्ड किए गए व्याख्यान और परीक्षण से भी गुजरते हैं। एक बार जब वे प्रशिक्षण पूरा कर लेते हैं, तो उन्हें प्रमाण पत्र दिया जाता है।",
              "ka": "ತರಬೇತಿಯ ಭಾಗವಾಗಿ, ಅವರು ಪೂರ್ವ-ರೆಕಾರ್ಡ್ ಮಾಡಿದ ಉಪನ್ಯಾಸಗಳು ಮತ್ತು ಪರೀಕ್ಷೆಗಳ ಮೂಲಕವೂ ಹೋಗುತ್ತಾರೆ. ತರಬೇತಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ ನಂತರ ಅವರಿಗೆ ಪ್ರಮಾಣಪತ್ರವನ್ನು ನೀಡಲಾಗುತ್ತದೆ.",
              "te": "శిక్షణలో భాగంగా, వారు ముందుగా రికార్డ్ చేసిన ఉపన్యాసాలు మరియు పరీక్షలలో కూడా పాల్గొంటారు. శిక్షణ పూర్తి కాగానే వారికి సర్టిఫికెట్ ఇస్తారు.",
              "or": "ଟ୍ରେଣିଂର ଏକ ଅଂଶ ଭାବରେ, ସେମାନେ ପୂର୍ବ-ରେକର୍ଡ ହୋଇଥିବା ବକ୍ତୃତା ଏବଂ ପରୀକ୍ଷା ମଧ୍ୟ ଦେଇ ଯାଆନ୍ତି | ଥରେ ସେମାନେ ଟ୍ରେଣିଂ ସମାପ୍ତ କରିବା ପରେ, ସେମାନଙ୍କୁ ଏକ ସାର୍ଟିଫିକେଟ୍ ଦିଆଯାଏ |",
              "as": "প্ৰশিক্ষণৰ অংশ হিচাপে, তেওঁলোকে পূৰ্ব-ৰেকৰ্ড কৰা বক্তৃতা আৰু পৰীক্ষাবোৰো পঢ়ে। তেওঁলোকে প্ৰশিক্ষণ সম্পূৰ্ণ কৰাৰ পিছত, তেওঁলোকক এখন প্ৰমাণপত্ৰ প্ৰদান কৰা হয়।",
              "gu": "તાલીમના ભાગરૂપે, તેઓ પ્રી-રેકોર્ડ કરેલા લેક્ચર્સ અને ટેસ્ટમાંથી પણ પસાર થાય છે.  જ્યારે તાલીમ પૂર્ણ થાય છે તો તેમને પ્રમાણપત્ર આપવામાં આવે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1i_1_3",
              "hi": "D1i_1_3",
              "ka": "D1i_1_3",
              "te": "D1i_1_3",
              "or": "D1i_1_3",
              "as": "D1i_1_3",
              "gu": "D1i_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "At the end of 3 months, they go through a mid-training evaluation with the team lead.",
              "hi": "3 महीने के अंत में, वे टीम प्रमुख के साथ मिड ट्रेनिंग मूल्यांकन से गुजरते हैं।",
              "ka": "3 ತಿಂಗಳ ಕೊನೆಯಲ್ಲಿ, ಅವರು ತಂಡದ ನಾಯಕನೊಂದಿಗೆ ಮಧ್ಯ-ತರಬೇತಿ ಮೌಲ್ಯಮಾಪನದ ಮೂಲಕ ಹೋಗುತ್ತಾರೆ.",
              "te": "3 నెలల ముగింపులో, వారు టీమ్ లీడ్‌తో మిడ్-ట్రైనింగ్ మూల్యాంకనం చేయబడతారు.",
              "or": "3 ମାସ ଶେଷରେ, ସେମାନେ ଦଳର ଲିଡ୍‌ ସହିତ ଏକ ମିଡ୍‌-ଟ୍ରେଣିଂ ମୂଲ୍ୟାଙ୍କନ ଦେଇ ଯାଆନ୍ତି |",
              "as": "3 মাহৰ শেষত, তেওঁলোকে দলৰ নেতৃত্বৰ সৈতে মধ্য-প্ৰশিক্ষণ মূল্যাঙ্কনৰ মাজেৰে যায়।",
              "gu": "3 મહિનાના અંતે, તેઓનું ટીમ લીડ દ્વારા મધ્ય-તાલીમ મૂલ્યાંકન કરવામાં આવે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1i_1_4////",
              "hi": "D1i_1_4 ////",
              "ka": "D1i_1_4////",
              "te": "D1i_1_4////",
              "or": "D1i_1_4////",
              "as": "D1i_1_4////",
              "gu": "D1i_1_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Arvind, you are good at general technical frameworks, but you can work more on development details.",
              "hi": "अरविंद, आप सामान्य तकनीकी ढांचे में अच्छे हैं, लेकिन आप विकास विवरण पर अधिक काम कर सकते हैं।",
              "ka": "ಅರವಿಂದ್, ನೀವು ಸಾಮಾನ್ಯ ತಾಂತ್ರಿಕ ಚೌಕಟ್ಟುಗಳಲ್ಲಿ ಉತ್ತಮರು, ಆದರೆ ನೀವು ಅಭಿವೃದ್ಧಿ ವಿವರಗಳ ಬಗ್ಗೆ ಹೆಚ್ಚು ಕೆಲಸ ಮಾಡಬಹುದು.",
              "te": "అరవింద్, మీరు సాధారణ జనరల్ టెక్నికల్ ఫ్రేమ్‌వర్క్‌లలో బాగా చేశారు, కానీ మీరు డెవలప్‌మెంట్ వివరాలపై మరింత పని చేయవచ్చు.",
              "or": "ଅରବିନ୍ଦ, ତୁମେ ସାଧାରଣ ଟେକ୍ନିକଲ୍ ଫ୍ରେମୱାର୍କରେ ଭଲ, କିନ୍ତୁ ବିକାଶର ବିବରଣୀ ଉପରେ ତୁମକୁ ଅଧିକ କାମ କରିବାକୁ ପଡିବ |",
              "as": "অৰবিন্দ, আপুনি সাধাৰণ কাৰিকৰী ফ্ৰেমৱৰ্কত ভাল, কিন্তু আপুনি বিকাশৰ বিৱৰণৰ ওপৰত অধিক কাম কৰিব পাৰে।",
              "gu": "અરવિંદ, તમે સામાન્ય તકનીકીના ફ્રેમવર્ક માં સારા છો, પરંતુ તમે ડેવલપમેન્ટ ની વિગતો પર વધુ જ્ઞાન મેળવી શકો છો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, I think I need to strengthen my basics.",
              "hi": "हां, मुझे लगता है कि मुझे अपनी मूल बातें मजबूत करने की आवश्यकता है।",
              "ka": "ಹೌದು, ನನ್ನ ಮೂಲಭೂತ ಅಂಶಗಳನ್ನು ನಾನು ಬಲಪಡಿಸಬೇಕಾಗಿದೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "అవును, నేను నా బేసిక్స్‌ మెరుగు పరుచుకోవాలని అనుకుంటున్నాను.",
              "or": "ହଁ, ମୁଁ ଭାବୁଛି ମୋର ମ ics ଳିକ ଉପରେ ଅଧିକ କାମ କରିବା ଆବଶ୍ୟକ |",
              "as": "হয়, মই ভাবো মই মোৰ মৌলিক বিষয়বোৰ শক্তিশালী কৰিব লাগিব।",
              "gu": "હા, મને લાગે છે કે મારે  બેસિક્સને મજબૂત કરવાની જરૂર છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "That's okay, you have a good mind, and your skills can be used for more strategic thinking.",
              "hi": "यह ठीक है, आपकी तीव्र बुद्धि है, और आपके कौशल का उपयोग अधिक रणनीतिक सोच के लिए किया जा सकता है।",
              "ka": "ಅದು ಸರಿ, ನೀವು ಉತ್ತಮ ಮನಸ್ಸನ್ನು ಹೊಂದಿದ್ದೀರಿ ಮತ್ತು ನಿಮ್ಮ ಕೌಶಲ್ಯಗಳನ್ನು ಹೆಚ್ಚು ಕಾರ್ಯತಂತ್ರದ ಚಿಂತನೆಗಾಗಿ ಬಳಸಬಹುದು.",
              "te": "అది సరే, మీకు మంచి మైండ్ ఉంది మరియు మీ స్కిల్స్ ని మరింత వ్యూహాత్మక ఆలోచన కోసం ఉపయోగించవచ్చు.",
              "or": "That's okay, you have a good brain, and your skills can be used for more strategic thinking.",
              "as": "সেয়া ঠিক আছে, আপোনাৰ এটা সুন্দৰ মন আছে, আৰু আপোনাৰ দক্ষতা অধিক কৌশলগত চিন্তাৰ বাবে ব্যৱহাৰ কৰিব পাৰি।",
              "gu": "વાંધો નહીં, તમારું મસ્તિષ્ક તેજ છે, અને તમારી કુશળતાનો ઉપયોગ વધુ સ્ટ્રેટેજીક્સ વિચારસરણી માટે થઈ શકે છે."
            }
          }
        ]
      },
      "scene27": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1ii_1_1",
              "hi": "D1II_1_1",
              "ka": "D1ii_1_1",
              "te": "D1ii_1_1",
              "or": "D1ii_1_1",
              "as": "D1ii_1_1",
              "gu": "D1ii_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "This part of the training is very new for Arvind. He is familiar with the technology aspects of the job, but he has not worked in sales before.",
              "hi": "प्रशिक्षण का यह हिस्सा अरविंद के लिए बहुत नया है। वह नौकरी के तकनीकी पहलुओं से परिचित है, लेकिन उसने पहले बिक्री में काम नहीं किया है।",
              "ka": "ಅರವಿಂದ್ ಅವರಿಗೆ ತರಬೇತಿಯ ಈ ಭಾಗವು ತುಂಬಾ ಹೊಸದು. ಅವರು ಕೆಲಸದ ತಂತ್ರಜ್ಞಾನದ ಅಂಶಗಳೊಂದಿಗೆ ಪರಿಚಿತರಾಗಿದ್ದಾರೆ, ಆದರೆ ಅವರು ಮೊದಲು ಮಾರಾಟದಲ್ಲಿ ಕೆಲಸ ಮಾಡಿಲ್ಲ.",
              "te": "అరవింద్‌కి ఈ శిక్షణ చాలా కొత్తది. అతను ఉద్యోగం యొక్క టెక్నాలజీ అంశాలు తెలిసినవాడు, కానీ అతను ఇంతకు ముందు సేల్స్‌లో పని చేయలేదు.",
              "or": "ପ୍ରଶିକ୍ଷଣର ଏହି ଅଂଶ ଅରବିନ୍ଦଙ୍କ ପାଇଁ ବହୁତ ନୂଆ ଅଟେ | ସେ ଚାକିରିର ଟେକ୍ନୋଲୋଜି ଦିଗଗୁଡ଼ିକ ସହିତ ପରିଚିତ, କିନ୍ତୁ ସେ ପୂର୍ବରୁ ବିକ୍ରିରେ କାମ କରିନାହାଁନ୍ତି |",
              "as": "প্ৰশিক্ষণৰ এই অংশটো অৰবিন্দৰ বাবে অতি নতুন। তেওঁ চাকৰিটোৰ প্ৰযুক্তিগত দিশবোৰৰ সৈতে পৰিচিত, কিন্তু তেওঁ আগতে বিক্ৰীত কাম কৰা নাই।",
              "gu": "તાલીમનો આ ભાગ અરવિંદ માટે ઘણો નવો હતો.  તે નોકરીના ટેક્નોલોજી પાસાઓથી પરિચિત હતો, પરંતુ તેણે અગાઉ સેલ્સમાં કામ કર્યું ન હતું."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1ii_1_2",
              "hi": "D1II_1_2",
              "ka": "D1ii_1_2",
              "te": "D1ii_1_2",
              "or": "D1ii_1_2",
              "as": "D1ii_1_2",
              "gu": "D1ii_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He learns techniques for being an effective salesperson. They learn eight different tools for better communication, how to build a Sales Toolkit, and how to use them at each phase of sales.",
              "hi": "वह एक प्रभावी विक्रेता बनने की तकनीक सीखता है। वे बेहतर संचार के लिए आठ अलग-अलग उपकरण सीखते हैं, बिक्री टूलकिट कैसे बनाते हैं, और बिक्री के प्रत्येक चरण में उनका उपयोग कैसे करते हैं।",
              "ka": "ಪರಿಣಾಮಕಾರಿ ಮಾರಾಟಗಾರನಾಗಲು ಅವನು ತಂತ್ರಗಳನ್ನು ಕಲಿಯುತ್ತಾನೆ. ಉತ್ತಮ ಸಂವಹನಕ್ಕಾಗಿ ಅವರು ಎಂಟು ವಿಭಿನ್ನ ಪರಿಕರಗಳನ್ನು ಕಲಿಯುತ್ತಾರೆ, ಮಾರಾಟದ ಟೂಲ್‌ಕಿಟ್ ಅನ್ನು ಹೇಗೆ ನಿರ್ಮಿಸುವುದು ಮತ್ತು ಮಾರಾಟದ ಪ್ರತಿ ಹಂತದಲ್ಲಿ ಅವುಗಳನ್ನು ಹೇಗೆ ಬಳಸುವುದು.",
              "te": "అతను సమర్థవంతమైన సేల్స్ పర్సన్ గా ఉండటానికి టెక్నిక్స్ నేర్చుకుంటాడు. వారు మెరుగైన కమ్యూనికేషన్ కోసం, సేల్స్ టూల్‌కిట్‌ను ఎలా నిర్మించాలి మరియు విక్రయాల యొక్క ప్రతి దశలో వాటిని ఎలా ఉపయోగించాలి అనే విషయాలపై ఎనిమిది విభిన్న సాధనాలను నేర్చుకుంటారు.",
              "or": "ଜଣେ ପ୍ରଭାବଶାଳୀ ବିକ୍ରେତା ହେବା ପାଇଁ ସେ ବିଭିନ୍ନ କ ques ଶଳ ଶିଖନ୍ତି | ଉତ୍ତମ ଯୋଗାଯୋଗ ପାଇଁ, ସେମାନେ କିପରି ଏକ ବିକ୍ରୟ ଉପକରଣ ନିର୍ମାଣ କରିବେ ଏବଂ ବିକ୍ରୟର ପ୍ରତ୍ୟେକ ପର୍ଯ୍ୟାୟରେ ସେମାନଙ୍କୁ କିପରି ବ୍ୟବହାର କରିବେ, ସେମାନେ ଆଠଟି ଭିନ୍ନ ଉପକରଣ ଶିଖନ୍ତି |",
              "as": "তেওঁ এজন কাৰ্যকৰী বিক্ৰেতা হোৱাৰ কৌশল শিকে। তেওঁলোকে উন্নত যোগাযোগৰ বাবে আঠটা পৃথক সঁজুলি শিকে, বিক্ৰী টুলকিট কেনেদৰে নিৰ্মাণ কৰিব লাগে, আৰু বিক্ৰীৰ প্ৰতিটো পৰ্যায়ত সেইবোৰ কেনেদৰে ব্যৱহাৰ কৰিব লাগে।",
              "gu": "તેણે અસરકારક સેલ્સપર્સન બનવા માટેની તકનીકો શીખી. તેઓ વધુ સારા સંચાર માટે આઠ અલગ-અલગ ટૂલ્સ શીખે છે, સેલ્સ ટૂલકીટ કેવી રીતે બનાવવી અને વેચાણના દરેક તબક્કામાં તેનો ઉપયોગ કેવી રીતે કરવો."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1ii_1_3",
              "hi": "D1II_1_3",
              "ka": "D1ii_1_3",
              "te": "D1ii_1_3",
              "or": "D1ii_1_3",
              "as": "D1ii_1_3",
              "gu": "D1ii_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Even though it is new, Arvind loves every aspect of the training process. The Project Lead notices Arvind's natural aptitude to apply these tools and asks him to demonstrate a sales pitch.",
              "hi": "हालांकि यह नया है, अरविंद को प्रशिक्षण प्रक्रिया के हर पहलू से प्यार है। प्रोजेक्ट प्रमुख इन उपकरणों को लागू करने के लिए अरविंद की स्वाभाविक योग्यता को नोटिस करती हैं और उसे बिक्री के प्रस्ताव का प्रदर्शन करने के लिए कहती हैं।",
              "ka": "ಇದು ಹೊಸದಾದರೂ, ತರಬೇತಿ ಪ್ರಕ್ರಿಯೆಯ ಪ್ರತಿಯೊಂದು ಅಂಶವನ್ನು ಅರವಿಂದ್ ಇಷ್ಟಪಡುತ್ತಾನೆ. ಪ್ರಾಜೆಕ್ಟ್ ಲೀಡ್ ಅರವಿಂದ್‌ನ ಈ ಉಪಕರಣಗಳನ್ನು ಅನ್ವಯಿಸುವ ಸ್ವಾಭಾವಿಕ ಯೋಗ್ಯತೆಯನ್ನು ಗಮನಿಸುತ್ತಾನೆ ಮತ್ತು ಮಾರಾಟದ ಪಿಚ್ ಅನ್ನು ಪ್ರದರ್ಶಿಸಲು ಅವನನ್ನು ಕೇಳುತ್ತಾನೆ.",
              "te": "ఇది కొత్తదే అయినప్పటికీ, శిక్షణ ప్రక్రియలోని ప్రతి అంశాన్ని అరవింద్ ఇష్టపడతాడు. ప్రాజెక్ట్ లీడ్ ఈ సాధనాలను వర్తింపజేయడంలో అరవింద్ యొక్క సామర్థ్యాన్ని గమనించి, సేల్స్ పిచ్‌ను ప్రజెంట్ చేయమని అతన్ని అడుగుతారు.",
              "or": "ଅରବିନ୍ଦ ତାଲିମ ପ୍ରକ୍ରିୟାର ପ୍ରତ୍ୟେକ ଦିଗକୁ ଭଲ ପାଆନ୍ତି, ଯଦିଓ ଏହା ତାଙ୍କ ପାଇଁ ନୂଆ ଅଟେ | ପ୍ରୋଜେକ୍ଟ ଲିଡ୍ ଏହି ଉପକରଣଗୁଡ଼ିକୁ ପ୍ରୟୋଗ କରିବା ପାଇଁ ଅରବିନ୍ଦଙ୍କ ପ୍ରାକୃତିକ ଦକ୍ଷତାକୁ ଲକ୍ଷ୍ୟ କରେ ଏବଂ ତାଙ୍କୁ ଏକ ବିକ୍ରୟ ପିଚ୍ ପ୍ରଦର୍ଶନ କରିବାକୁ କହିଥାଏ |",
              "as": "যদিও এইটো নতুন, অৰবিন্দে প্ৰশিক্ষণ প্ৰক্ৰিয়াৰ প্ৰতিটো দিশ ভাল পায়। মুখ্য প্ৰকল্পকাৰে এই সঁজুলিবোৰ প্ৰয়োগ কৰিবলৈ অৰবিন্দৰ প্ৰাকৃতিক দক্ষতা লক্ষ্য কৰে আৰু তেওঁক এটা বিক্ৰীৰ গুণাগুণ প্ৰদৰ্শন কৰিবলৈ কয়।",
              "gu": "તે નવું હોવા છતાં, અરવિંદને તાલીમ પ્રક્રિયાના દરેક પાસાઓ પસંદ હતાં.  પ્રોજેક્ટ લીડ અરવિંદની આ ટૂલ્સને લાગુ કરવાની કુદરતી ક્ષમતાની નોંધ લે છે અને તેને સેલ્સ પિચ દર્શાવવા કહે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1ii_1_5//",
              "hi": "D1II_1_5 //",
              "ka": "D1ii_1_5//",
              "te": "D1ii_1_5//",
              "or": "D1ii_1_5//",
              "as": "D1ii_1_5//",
              "gu": "D1ii_1_5//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Project lead",
              "hi": "प्रोजेक्ट प्रमुख ",
              "ka": "ಪ್ರಾಜೆಕ್ಟ್ ಲೀಡ್",
              "te": "ప్రాజెక్ట్ లీడ్",
              "or": "ପ୍ରୋଜେକ୍ଟ ଲିଡ୍",
              "as": "প্ৰকল্পৰ নেতৃত্ব",
              "gu": "પ્રોજેક્ટ લીડ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Good job Arvind, you handled that demonstration well. You have done better than most of our other fresh recruits.",
              "hi": "उत्तम काम अरविंद, आपने उस प्रदर्शन को अच्छी तरह से संभाला। आपने हमारे अन्य हाल ही में भर्ती  किए गए लोगों की तुलना में बेहतर प्रदर्शन किया है।",
              "ka": "ಒಳ್ಳೆಯ ಕೆಲಸ ಅರವಿಂದ್, ನೀವು ಆ ಪ್ರದರ್ಶನವನ್ನು ಚೆನ್ನಾಗಿ ನಿಭಾಯಿಸಿದ್ದೀರಿ. ನಮ್ಮ ಇತರ ಹೊಸ ನೇಮಕಾತಿಗಳಿಗಿಂತ ನೀವು ಉತ್ತಮವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸಿದ್ದೀರಿ",
              "te": "బాగుంది అరవింద్, మీరు ఆ ప్రదర్శనను చక్కగా చేశారు. మా ఇతర తాజా రిక్రూట్‌ల కంటే మీరు బాగా చేసారు.",
              "or": "ଉତ୍ତମ କାମ ଅରବିନ୍ଦ, ତୁମେ ସେହି ପ୍ରଦର୍ଶନକୁ ଭଲ ଭାବରେ ପରିଚାଳନା କରିଛ | ଆମର ଅନ୍ୟାନ୍ୟ ନବନିଯୁକ୍ତ ଅପେକ୍ଷା ତୁମେ ଭଲ କରିଛ |",
              "as": "ভাল কাম অৰবিন্দ, আপুনি সেই প্ৰদৰ্শনটো ভালদৰে চম্ভালিছিল। আপুনি আমাৰ বেছিভাগ নতুন নিযুক্ত লোকতকৈ ভাল কৰিছে।",
              "gu": "સરસ કાર્ય કર્યું અરવિંદ, તમે તે પ્રદર્શનને સારી રીતે સંભાળ્યું.  તમે અમારા અન્ય નવા ભરતી કરાયેલા કર્મચારીઓ કરતા સારી કામગીરી કરી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Thank you, Ma'am. I was nervous.",
              "hi": "धन्यवाद मैम। मैं घबरा गया था।",
              "ka": "ಧನ್ಯವಾದಗಳು, ಮೇಡಂ. ನಾನು ನರ್ವಸ್ ಆಗಿದ್ದೆನೆ.",
              "te": "ధన్యవాదాలు, మేడమ్. నేను కొంచెం కంగారు పడ్డాను.",
              "or": "ଧନ୍ୟବାଦ, ମ୍ୟାଡାମ୍‌, ମୁଁ ନର୍ଭସ ଥିଲି |",
              "as": "ধন্যবাদ, মেডাম। মই ভয় খাইছিলো।",
              "gu": "આભાર, મેડમ.  હું નર્વસ હતો."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1ii_1_6////",
              "hi": "D1II_1_6 ////",
              "ka": "D1ii_1_6////",
              "te": "D1ii_1_6////",
              "or": "D1ii_1_6////",
              "as": "D1ii_1_6////",
              "gu": "D1ii_1_6////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Oh, it did not show at all. You seemed to have such a good grasp of the company's services and the technology involved.",
              "hi": "ओह, ऐसा बिल्कुल नहीं लगा। आपको कंपनी की सेवाओं और इसमें शामिल प्रौद्योगिकी की इतनी अच्छी समझ है।",
              "ka": "ಓಹ್, ಅದು ತೋರಿಸಲಿಲ್ಲ. ಕಂಪನಿಯ ಸೇವೆಗಳು ಮತ್ತು ಒಳಗೊಂಡಿರುವ ತಂತ್ರಜ್ಞಾನದ ಬಗ್ಗೆ ನೀವು ಉತ್ತಮ ಗ್ರಹಿಕೆಯನ್ನು ಹೊಂದಿರುವಂತೆ ತೋರುತ್ತಿದೆ.",
              "te": "అవునా, అది అస్సలు కనపడలేదు. కంపెనీ సర్వీసెస్ మరియు టెక్నాలజీలపై మీకు మంచి అవగాహన ఉన్నట్లు అనిపించింది.",
              "or": "ଓହ, ଏହା ଆଦୌଂ ଦେଖାଗଲା ନାହିଁ | କମ୍ପାନୀର ସେବା ଏବଂ ଜଡିତ ଟେକ୍ନୋଲୋଜି ବିଷୟରେ ତୁମର ଏତେ ଭଲ ଅନୁଭବ ଥିବା ପରି ମନେ ହେଉଥିଲା |",
              "as": "ওহ, এইটো একেবাৰে গম পোৱা হোৱা নাছিল। কোম্পানীৰ কামবোৰ আৰু ইয়াৰ সৈতে জড়িত প্ৰযুক্তিৰ বিষয়ে আপোনাৰ ইমান ভাল ধাৰণা থকা যেন লাগিছিল।",
              "gu": "ઓહ, તે બિલકુલ દેખાતું ન હતું.  તમે કંપનીની સેવાઓ અને તેમાં સામેલ ટેક્નોલૉજી વિશે આટલી સારી સમજ ધરાવો છો."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Ma’am, I have spent time reading about that to understand the services thoroughly.",
              "hi": "मैम, मैंने कार्य को अच्छी तरह से समझने के लिए इसके बारे में पढ़ने में समय बिताया है।",
              "ka": "ಮೇಡಂ, ಸೇವೆಗಳನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ನಾನು ಅದರ ಬಗ್ಗೆ ಓದಲು ಸಮಯವನ್ನು ಕಳೆದಿದ್ದೇನೆ.",
              "te": "మేడమ్, నేను సర్వీసెస్ గురించి పూర్తిగా అర్థం చేసుకోవడానికి దాని గురించి చదవడానికి సమయాన్ని వెచ్చించాను.",
              "or": "ମ୍ୟାଡାମ୍‌, ସେବାଗୁଡ଼ିକୁ ଭଲଭାବେ ବୁଝିବା ‍ପାଇଁ ମୁଁ ସେ ବିଷୟରେ ପଢିବାରେ ସମୟ ଅତିବାହିତ କରିଛି |",
              "as": "মেডাম, কামবোৰ ভালদৰে বুজিবলৈ মই সেই বিষয়ে পঢ়ি সময় অতিবাহিত কৰিছো।",
              "gu": "મેડમ, સેવાઓને સારી રીતે સમજવા માટે મેં તેના વિશે વાંચવામાં સમય પસાર કર્યો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "That's the attitude we want. Keep up the good work!",
              "hi": "यही वह दृष्टिकोण है जो हम चाहते हैं। अच्छा काम करते रहें!",
              "ka": "ಅದು ನಮಗೆ ಬೇಕಾದ ಮನೋಭಾವ. ಹೀಗೆ ಒಳ್ಳೆ ಕೆಲಸ ಮುಂದುವರಿಸಿ!",
              "te": "అదే మేం కోరుకునేది. మంచి పనిని ఇలానే కొనసాగించండి!",
              "or": "ଆମେ ସେହି ମନୋଭାବ ଚାହୁଁ | ଭଲ କାମ ଜାରି ରଖ !",
              "as": "আমি এইটোৱেই মনোভাৱ বিচাৰো। এই ভাল কামবোৰ অব্যাহত ৰাখিব!",
              "gu": "એ જ વલણ અમેઈચ્છીએ છીએ.  સારું કામ કરો છો ચાલુ રાખો!"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1ii_1_7",
              "hi": "D1II_1_7",
              "ka": "D1ii_1_7",
              "te": "D1ii_1_7",
              "or": "D1ii_1_7",
              "as": "D1ii_1_7",
              "gu": "D1ii_1_7"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is happy that his talent, hard work, curiosity and skill is being appreciated without any bias.",
              "hi": "अरविंद खुश है कि उसकी प्रतिभा, कड़ी मेहनत, जिज्ञासा और कौशल को बिना किसी पूर्वाग्रह के सराहा जा रहा है।",
              "ka": "ಅವರ ಪ್ರತಿಭೆ, ಶ್ರಮ, ಕುತೂಹಲ, ಕೌಶಲ್ಯಕ್ಕೆ ಯಾವುದೇ ಪಕ್ಷಪಾತವಿಲ್ಲದೆ ಮೆಚ್ಚುಗೆ ವ್ಯಕ್ತವಾಗುತ್ತಿದೆ ಎಂದು ಅರವಿಂದ್ ಸಂತಸ ವ್ಯಕ್ತಪಡಿಸಿದ್ದಾರೆ.",
              "te": "ఎలాంటి పక్షపాతం లేకుండా తన ప్రతిభ, శ్రమ, ఉత్సుకత, నైపుణ్యానికి ప్రశంసలు వస్తున్నాయని అరవింద్ సంతోషం వ్యక్తం చేశారు.",
              "or": "ଅରବିନ୍ଦ ଖୁସି ଯେ ତାଙ୍କ ପ୍ରତିଭା, କଠିନ ପରିଶ୍ରମ, କୌତୁହଳ ଏବଂ କୌଶଳ କୌଣସି ପକ୍ଷପାତ ବିନା ପ୍ରଶଂସିତ ହେଉଛି |",
              "as": "অৰবিন্দ সুখী যে তেওঁৰ প্ৰতিভা, কঠোৰ পৰিশ্ৰম, কৌতূহল আৰু দক্ষতাক কোনো পক্ষপাতিত্ব অবিহনে প্ৰশংসা কৰা হৈছে।",
              "gu": "અરવિંદ ખુશ છે કે તેની પ્રતિભા, મહેનત, જિજ્ઞાસા અને કૌશલ્યની કોઈપણ પક્ષપાત વિના પ્રશંસા થઈ રહી છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D1ii_1_8",
              "hi": "D1II_1_8",
              "ka": "D1ii_1_8",
              "te": "D1ii_1_8",
              "or": "D1ii_1_8",
              "as": "D1ii_1_8",
              "gu": "D1ii_1_8"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Surekha Ma'am is impressed with Arvind's progress in the training and asks him to join the sales team as a Tech consultant. Arvind is happy to accept the role.",
              "hi": "सुरेखा मैम प्रशिक्षण में अरविंद की प्रगति से प्रभावित हैं और उन्हें तकनीकी सलाहकार के रूप में बिक्री टीम में शामिल होने के लिए कहती हैं। अरविंद इस भूमिका को स्वीकार कर खुश है।",
              "ka": "ಸುರೇಖಾ ಮೇಮ್ ಅವರು ಅರವಿಂದ್ ಅವರ ತರಬೇತಿಯಲ್ಲಿನ ಪ್ರಗತಿಯಿಂದ ಪ್ರಭಾವಿತರಾಗಿದ್ದಾರೆ ಮತ್ತು ಮಾರಾಟ ತಂಡದಲ್ಲಿ ಟೆಕ್ ಸಲಹೆಗಾರರಾಗಿ ಸೇರಲು ಕೇಳುತ್ತಾರೆ. ಅರವಿಂದ್ ಈ ಪಾತ್ರವನ್ನು ಒಪ್ಪಿಕೊಳ್ಳಲು ಖುಷಿಯಾಗಿದ್ದಾರೆ.",
              "te": "శిక్షణలో అరవింద్ సాధించిన పురోగతికి సురేఖ మామ్ ముగ్ధులై, సేల్స్ టీమ్‌లో టెక్ కన్సల్టెంట్‌గా చేరమని కోరింది. అరవింద్ కి ఈ రోల్ ని అంగీకరించడం ఆనందంగా ఉంది.",
              "or": "ଟ୍ରେଣିଂରେ ଅରବିନ୍ଦଙ୍କ ଅଗ୍ରଗତି ଦେଖି ସୁରେଖା ମ୍ୟାଡାମ୍‌ ପ୍ରଭାବିତ ହୋଇ ତାଙ୍କୁ ଟେକ୍ କନ୍‌ସଲ୍‌ଟାଣ୍ଟ୍‌ ଭାବରେ ସେଲ୍ସ ଦଳରେ ଯୋଗଦେବାକୁ କୁହନ୍ତି। ଏହି ଭୂମିକା ଗ୍ରହଣ କରିବାକୁ ଅରବିନ୍ଦ ଖୁସି ।",
              "as": "সুৰেখা মেমে প্ৰশিক্ষণত অৰবিন্দৰ অগ্ৰগতিত প্ৰভাৱিত হৈছে আৰু তেওঁক এজন প্ৰযুক্তি পৰামৰ্শদাতা হিচাপে বিক্ৰী দলত যোগদান কৰিবলৈ কৈছে। অৰবিন্দে কামটো গ্ৰহণ কৰি সুখী হৈছে।",
              "gu": "સુરેખા મેમ તાલીમમાં અરવિંદની પ્રગતિથી પ્રભાવિત થયા અને તેમણે તેને ટેક કન્સલ્ટન્ટ તરીકે સેલ્સ ટીમમાં જોડાવા માટે કહ્યું.  અરવિંદ આ રોલ સ્વીકારીને ખુશ છે."
            }
          }
        ]
      },
      "scene28": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_1_1",
              "hi": "D2_1_1",
              "ka": "D2_1_1",
              "te": "D2_1_1",
              "or": "D2_1_1",
              "as": "D2_1_1",
              "gu": "D2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Over time, being in the office is a different experience from college. Arvind feels safe at his workplace and is acknowledged for his work rather than his identity.",
              "hi": "समय के साथ, ऑफिस में रहना कॉलेज से अलग अनुभव देता है। अरविंद अपने कार्यस्थल पर सुरक्षित अनुभव करता है और उसे उसकी पहचान के बजाय उसके काम के लिए जाना जाता है।",
              "ka": "ಕಾಲಾನಂತರದಲ್ಲಿ, ಕಛೇರಿಯಲ್ಲಿರುವುದು ಕಾಲೇಜಿನಿಂದ ವಿಭಿನ್ನ ಅನುಭವವಾಗಿದೆ. ಅರವಿಂದ್ ತನ್ನ ಕೆಲಸದ ಸ್ಥಳದಲ್ಲಿ ಸುರಕ್ಷಿತವಾಗಿರುತ್ತಾನೆ ಮತ್ತು ತನ್ನ ಗುರುತಿಗಿಂತ ಹೆಚ್ಚಾಗಿ ತನ್ನ ಕೆಲಸಕ್ಕಾಗಿ ಅಂಗೀಕರಿಸಲ್ಪಟ್ಟಿದ್ದಾನೆ.",
              "te": "కాలక్రమేణా, ఆఫీసులో ఉండటం కాలేజీకి భిన్నమైన అనుభవం. అరవింద్ తన ఆఫీస్ లో సురక్షితంగా ఉంటాడు మరియు అతని గుర్తింపు కంటే అతని పనికి ఎక్కువ గుర్తింపు పొందాడు.",
              "or": "ସମୟ ସହିତ, ଅଫିସରେ ରହିବା କଲେଜଠାରୁ ଭିନ୍ନ ଅନୁଭୂତି | ଅରବିନ୍ଦ ନିଜ କର୍ମକ୍ଷେତ୍ରରେ ନିରାପଦ ଅନୁଭବ କରନ୍ତି ଏବଂ ତାଙ୍କ ପରିଚୟ ଅପେକ୍ଷା ତାଙ୍କ କାର୍ଯ୍ୟ ପାଇଁ ସ୍ୱୀକୃତିପ୍ରାପ୍ତ ହୁଅନ୍ତି |",
              "as": "সময়ৰ লগে লগে, অফিচত থকাটো কলেজৰ পৰা সম্পূৰ্ণ এক পৃথক অভিজ্ঞতা। অৰবিন্দে তেওঁৰ কৰ্মক্ষেত্ৰত সুৰক্ষিত অনুভৱ কৰে আৰু তেওঁৰ পৰিচয়ৰ সলনি তেওঁক তেওঁৰ কামৰ বাবে স্বীকাৰ কৰা হয়।",
              "gu": "સમય પસાર થતા, ઑફિસમાં કામ કરવું એ કૉલેજ કરતાં અલગ અનુભવ છે.  અરવિંદ  કાર્યસ્થળ પર પોતાને સલામત અનુભવે છે અને તેની  ઓળખને બદલે તેના કામ માટે તેને સ્વીકારવામાં આવે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_1_2",
              "hi": "D2_1_2",
              "ka": "D2_1_2",
              "te": "D2_1_2",
              "or": "D2_1_2",
              "as": "D2_1_2",
              "gu": "D2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Most people accept Arvind's identity, but some still feel uncomfortable. He notices them talking about him in the cafe whenever he walks by.",
              "hi": "अधिकतर लोग अरविंद की पहचान को स्वीकार करते हैं, लेकिन कुछ अभी भी असहज अनुभव करते हैं। वह उन्हें कैफे में उसके बारे में बात करते हुए देखता है जब भी वह चलता है।",
              "ka": "ಹೆಚ್ಚಿನ ಜನರು ಅರವಿಂದ್ ಅವರ ಗುರುತನ್ನು ಸ್ವೀಕರಿಸುತ್ತಾರೆ, ಆದರೆ ಕೆಲವರು ಇನ್ನೂ ಅನಾನುಕೂಲತೆಯನ್ನು ಅನುಭವಿಸುತ್ತಾರೆ. ಅವನು ಕೆಫೆಯಲ್ಲಿ ಹೋಗುವಾಗಲೆಲ್ಲಾ ಅವನ ಬಗ್ಗೆ ಮಾತನಾಡುವುದನ್ನು ಅವನು ಗಮನಿಸುತ್ತಾನೆ.",
              "te": "చాలా మంది అరవింద్ గుర్తింపును అంగీకరిస్తారు, కానీ కొందరు ఇప్పటికీ అసౌకర్యంగా ఉన్నారు. తను వెళ్ళినప్పుడల్లా కేఫ్‌లో తన గురించి మాట్లాడుకోవడం గమనిస్తాడు.",
              "or": "ଅଧିକାଂଶ ଲୋକ ଅରବିନ୍ଦଙ୍କ ପରିଚୟ ଗ୍ରହଣ କରନ୍ତି, କିନ୍ତୁ କେତେକ ତଥାପି ଅସହଜ ଅନୁଭବ କରନ୍ତି | ସେ ଦେଖନ୍ତି ଯେ ଯିବା ବେଳେ ସେମାନେ କାଫେରେ ତାଙ୍କ ବିଷୟରେ କଥାବାର୍ତ୍ତା ହୁଅନ୍ତି |",
              "as": "বেছিভাগ লোকে অৰবিন্দৰ পৰিচয় স্বীকাৰ কৰে, কিন্তু কিছুমানে এতিয়াও অস্বস্তি অনুভৱ কৰে। তেওঁ যেতিয়াই খোজ কাঢ়ে তেওঁলোকক কেফেত তেওঁৰ বিষয়ে কথা পাতি থকা লক্ষ্য কৰিছে।",
              "gu": "મોટાભાગના લોકો અરવિંદની ઓળખ સ્વીકારે છે, પરંતુ કેટલાક હજુ પણ અસ્વસ્થતા અનુભવે છે.  જ્યારે પણ તે ત્યાંથી પસાર થાય છે ત્યારે તે તેમને કૅફેમાં પોતાના વિશે વાત કરતા જોવે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_1_3///",
              "hi": "D2_1_3 ////",
              "ka": "D2_1_3///",
              "te": "D2_1_3///",
              "or": "D2_1_3///",
              "as": "D2_1_3///",
              "gu": "D2_1_3///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Though Arvind tries his best not to get noticed by dressing up like others, some of his colleagues find it difficult to relate to him.",
              "hi": "हालाँकि अरविंद दूसरों की तरह कपड़े पहनकर लोगों की नज़रों में न आने का पूरा प्रयास करता है, लेकिन उसके कुछ सहयोगियों के लिए उससे संबंध बनाना कठिन होता है।",
              "ka": "ಅರವಿಂದ್ ಇತರರಂತೆ ಡ್ರೆಸ್ಸಿಂಗ್ ಮಾಡುವ ಮೂಲಕ ಗಮನಕ್ಕೆ ಬರದಿರಲು ತನ್ನ ಅತ್ಯುತ್ತಮ ಪ್ರಯತ್ನವನ್ನು ಮಾಡುತ್ತಿದ್ದರೂ, ಅವನ ಕೆಲವು ಸಹೋದ್ಯೋಗಿಗಳು ಅವನೊಂದಿಗೆ ಸಂಬಂಧ ಹೊಂದಲು ಕಷ್ಟಪಡುತ್ತಾರೆ.",
              "te": "అరవింద్ ఇతరుల వలె దుస్తులు ధరించడం ద్వారా గుర్తించబడకుండా ఉండటానికి తన శాయశక్తులా ప్రయత్నించినప్పటికీ, అతని సహచరులు కొందరు అతనితో మాట్లాడడం ఇబ్బందిగా ఫీల్ అవుతారు.",
              "or": "ଯଦିଓ ଅରବିନ୍ଦ ଅନ୍ୟମାନଙ୍କ ପରି ପରିଧାନ କରି ଧ୍ୟାନ ନ ପାଇବାକୁ ଚେଷ୍ଟା କରନ୍ତି, ତଥାପି ତାଙ୍କର କିଛି ସହକର୍ମୀ ତାଙ୍କ ସହ ସମ୍ପର୍କ କରିବା କଷ୍ଟକର ହୋଇପଡନ୍ତି |",
              "as": "যদিও অৰবিন্দে আনৰ দৰে পোছাক পিন্ধি তেওঁক লক্ষ্য নকৰিবলৈ যথাসাধ্য চেষ্টা কৰে, তেওঁৰ কিছুমান সহকৰ্মীয়ে তেওঁৰ সৈতে সম্পৰ্ক স্থাপন কৰাত অসুবিধা পায়।",
              "gu": "જો કે અરવિંદ અન્ય લોકોની જેમ પોશાક પહેરીને પોતાના તરફ ધ્યાન ન ખેંચાય તે માટે શ્રેષ્ઠ પ્રયાસ કરે છે, તેમ છતાં તેના કેટલાક સાથીદારોને તેની સાથે સંબંધ રાખવા માં મુશ્કેલી પડે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_1_4",
              "hi": "D2_1_4",
              "ka": "D2_1_4",
              "te": "D2_1_4",
              "or": "D2_1_4",
              "as": "D2_1_4",
              "gu": "D2_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Most of the men in the team stay back late in the office. Arvind is not comfortable with it as it means travelling in an auto or cab late at night.",
              "hi": "टीम के ज्यादातर पुरुष ऑफिस में देर तक रुकते हैं। अरविंद इससे सहज नहीं है क्योंकि इसका मतलब देर रात ऑटो या कैब में सफर करना है।",
              "ka": "ತಂಡದಲ್ಲಿರುವ ಹೆಚ್ಚಿನ ಪುರುಷರು ಕಚೇರಿಯಲ್ಲಿ ತಡವಾಗಿ ಹಿಂತಿರುಗುತ್ತಾರೆ. ತಡರಾತ್ರಿ ಆಟೋ ಅಥವಾ ಕ್ಯಾಬ್ ನಲ್ಲಿ ಪ್ರಯಾಣ ಮಾಡುವುದು ಎಂದರೆ ಅರವಿಂದ್ ಗೆ ನೆಮ್ಮದಿಯಿಲ್ಲ.",
              "te": "జట్టులోని చాలా మంది పురుషులు ఆఫీసులో చాలాసేపు ఉంటారు. అర్థరాత్రి ఆటోలోనో, క్యాబ్‌లోనో ప్రయాణం చేయడం అరవింద్ కి అంత సౌకర్యంగా ఉండేది కాదు.",
              "or": "ଦଳର ଅଧିକାଂଶ ପୁରୁଷ ଅଫିସରେ ବିଳମ୍ବ ପର୍ଯ୍ୟନ୍ତ ରୁହନ୍ତି | ଅରବିନ୍ଦ ଏହା ସହ ସହଜ ନୁହଁନ୍ତି କାରଣ ଏହାର ଅର୍ଥ ହେଉଛି ବିଳମ୍ବିତ ରାତିରେ ଏକ ଅଟୋ କିମ୍ବା କ୍ୟାବରେ ଯାତ୍ରା କରିବା |",
              "as": "দলটোৰ বেছিভাগ পুৰুষ অফিচত পলমকৈ থাকে। অৰবিন্দ ইয়াৰ সৈতে সহজ নহয় কিয়নো ইয়াৰ অৰ্থ হৈছে ৰাতি দেৰিকৈ এখন অটো বা কেবত যোৱা।",
              "gu": "ટીમના મોટા ભાગના માણસો ઓફિસમાં મોડે સુધી રહેતા હતા .  અરવિંદને આરામદાયક નહતું લાગતું કારણ કે તેનો અર્થ એ હતો કે મોડી રાત્રે ઓટો અથવા કેબમાં મુસાફરી કરવી પડે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_1_5",
              "hi": "D2_1_5",
              "ka": "D2_1_5",
              "te": "D2_1_5",
              "or": "D2_1_5",
              "as": "D2_1_5",
              "gu": "D2_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Due to this, he is one of the few people from his team who goes home early.",
              "hi": "इस कारण से वह अपनी टीम के उन गिने-चुने लोगों में से है जो जल्दी घर जाते हैं.",
              "ka": "ಈ ಕಾರಣದಿಂದಾಗಿ, ಅವರ ತಂಡದಿಂದ ಬೇಗನೆ ಮನೆಗೆ ಹೋಗುವ ಕೆಲವೇ ಜನರಲ್ಲಿ ಒಬ್ಬರು.",
              "te": "దీని కారణంగా, అతని బృందం నుండి త్వరగా ఇంటికి వెళ్ళే కొద్దిమంది వ్యక్తులలో అతను ఒకడు.",
              "or": "ଏହି କାରଣରୁ, ସେ ତାଙ୍କ ଦଳର ଅଳ୍ପ ଲୋକମାନଙ୍କ ମଧ୍ୟରୁ ଜଣେ ଯିଏ ଶୀଘ୍ର ଘରକୁ ଯାଆନ୍ତି |",
              "as": "ইয়াৰ ভিতৰত, তেওঁৰ দলৰ কেইজনমান লোকৰ মাজৰ অৰবিন্দেই এজন যি সোনকালে ঘৰলৈ যায়।",
              "gu": "આ કારણે, તે તેની ટીમના થોડા એ લોકોમાંથી એક હતો જે વહેલા ઘરે જતા હતા."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_1",
              "hi": "D2_2_1",
              "ka": "D2_2_1",
              "te": "D2_2_1",
              "or": "D2_2_1",
              "as": "D2_2_1",
              "gu": "D2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Some of his team members think Arvind is doing this to avoid work. Arvind is unable to tell everyone the reasons for his discomfort.",
              "hi": "उनके कुछ टीम के सदस्यों को लगता है कि अरविंद काम से बचने के लिए ऐसा कर रहा है। अरविंद सभी को असुविधा के कारण बताने में असमर्थ है।",
              "ka": "ಕೆಲಸ ತಪ್ಪಿಸಲು ಅರವಿಂದ್ ಈ ರೀತಿ ಮಾಡುತ್ತಿದ್ದಾರೆ ಎಂದು ಅವರ ತಂಡದ ಕೆಲವು ಸದಸ್ಯರು ಭಾವಿಸಿದ್ದಾರೆ. ಅರವಿಂದ್ ತನ್ನ ಅಸಮಾಧಾನದ ಕಾರಣಗಳನ್ನು ಎಲ್ಲರಿಗೂ ಹೇಳಲು ಸಾಧ್ಯವಿಲ್ಲ.",
              "te": "పని చేయకుండా ఉండేందుకు అరవింద్ ఇలా చేస్తున్నాడని అతని టీమ్ సభ్యులు కొందరు భావిస్తున్నారు. అరవింద్ తన అసౌకర్యానికి గల కారణాలను అందరికీ చెప్పలేకపోతున్నాడు.",
              "or": "ତାଙ୍କର ଦଳର କିଛି ସଦସ୍ୟ ଭାବନ୍ତି ଅରବିନ୍ଦ କାମ ଏଡାଇବା ପାଇଁ ଏହା କରୁଛନ୍ତି | ଅରବିନ୍ଦ ସମସ୍ତଙ୍କୁ ତାଙ୍କ ଅସହଜତାର କାରଣ ଜଣାଇବାକୁ ଅସମର୍ଥ |",
              "as": "তেওঁৰ দলৰ কিছুমান সদস্যই ভাবে যে অৰবিন্দে কাম এৰাই চলিবলৈ এইটো কৰি আছে। অৰবিন্দে সকলোকে তেওঁৰ অস্বস্তিৰ কাৰণ ক'ব নোৱাৰে।",
              "gu": "તેમની ટીમના કેટલાક સભ્યોને લાગતું હતું કે અરવિંદ કામથી બચવા માટે આવું કરી રહ્યો છે.  અરવિંદ પોતાની અગવડતાના કારણો બધાને જણાવી શકતો નહોતો."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_2",
              "hi": "D2_2_2",
              "ka": "D2_2_2",
              "te": "D2_2_2",
              "or": "D2_2_2",
              "as": "D2_2_2",
              "gu": "D2_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "However, Arvind makes a few friends in the office who appreciate his work.",
              "hi": "हालांकि, अरविंद कार्यालय में कुछ मित्र बनाता है जो उसके काम की सराहना करते हैं।",
              "ka": "ಆದಾಗ್ಯೂ, ಅರವಿಂದ್ ಅವರ ಕೆಲಸವನ್ನು ಮೆಚ್ಚುವ ಕೆಲವು ಸ್ನೇಹಿತರನ್ನು ಕಚೇರಿಯಲ್ಲಿ ಮಾಡುತ್ತಾರೆ.",
              "te": "కానీ, అరవింద్ తన పనిని మెచ్చుకునే కొంతమంది స్నేహితులను ఆఫీసులో సంపాదించుకుంటాడు.",
              "or": "ତଥାପି, ଅରବିନ୍ଦ ଅଫିସରେ କିଛି ବନ୍ଧୁ କରନ୍ତି ଯେଉଁମାନେ ତାଙ୍କ କାର୍ଯ୍ୟକୁ ପ୍ରଶଂସା କରନ୍ତି |",
              "as": "অৱশ্যে, অৰবিন্দে অফিচত কেইজনমান বন্ধু পাতিছে যিসকলে তেওঁৰ কামৰ প্ৰশংসা কৰে।",
              "gu": "જો કે, અરવિંદે ઓફિસમાં થોડા મિત્રો બનાવ્યા હતા જેઓ તેમના કામની પ્રશંસા કરતા હતા."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_2_3",
              "hi": "D2_2_3",
              "ka": "D2_2_3",
              "te": "D2_2_3",
              "or": "D2_2_3",
              "as": "D2_2_3",
              "gu": "D2_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "But when they ask Arvind to come with them for dinner after work, he usually tells them that he can't. Arvind is still not comfortable staying out late, as he is afraid of being harassed.",
              "hi": "लेकिन जब वे अरविंद को काम के बाद रात के खाने के लिए उनके साथ आने के लिए कहते हैं, तो वह आमतौर पर उन्हें बताता है कि वह ऐसा नहीं कर सकता। अरविंद अभी भी देर तक बाहर रहने में सहज नहीं है, क्योंकि उसे परेशान किए जाने का डर है।",
              "ka": "ಆದರೆ ಕೆಲಸ ಮುಗಿಸಿ ರಾತ್ರಿ ಊಟಕ್ಕೆ ತಮ್ಮೊಂದಿಗೆ ಬರುವಂತೆ ಅರವಿಂದ್‌ಗೆ ಕೇಳಿದಾಗ ಅವನು ಸಾಮಾನ್ಯವಾಗಿ ಸಾಧ್ಯವಿಲ್ಲ ಎಂದು ಹೇಳುತ್ತಿದ್ದನು. ಕಿರುಕುಳಕ್ಕೊಳಗಾಗುವ ಭಯದಲ್ಲಿರುವ ಅರವಿಂದ್‌ಗೆ ತಡವಾಗಿ ಹೊರಗಿರುವುದು ಇನ್ನೂ ಆರಾಮದಾಯಕವಲ್ಲ.",
              "te": "కానీ అరవింద్‌ని పని ముగించుకుని డిన్నర్‌కి తమతో రమ్మని అడిగితే కుదరదని చెప్పేవాడు. వేధింపులకు గురి అవుతారనే భయంతో అరవింద్‌కి ఎక్కువసేపు ఉండడం సౌకర్యంగా ఉండదు.",
              "or": "କିନ୍ତୁ ଯେତେବେଳେ ସେମାନେ ଅରବିନ୍ଦଙ୍କୁ କାମ ସାରି ସେମାନଙ୍କ ସହିତ ରାତ୍ରୀ ଭୋଜନ କରିବାକୁ ଆସିବାକୁ କୁହନ୍ତି, ସେ ସାଧାରଣତ ସେମାନଙ୍କୁ କୁହନ୍ତି ଯେ ସେ ପାରିବେ ନାହିଁ | ଅରବିନ୍ଦ ଏପର୍ଯ୍ୟନ୍ତ ବିଳମ୍ବରେ ରହିବାକୁ ଆରାମଦାୟକ ନୁହଁନ୍ତି, କାରଣ ସେ ହଇରାଣ ହରକତ ହେବାକୁ ଭୟ କରନ୍ତି |",
              "as": "কিন্তু যেতিয়া তেওঁলোকে অৰবিন্দক কামৰ পিছত ৰাতিৰ আহাৰৰ বাবে তেওঁলোকৰ সৈতে আহিবলৈ কয়, তেওঁ সাধাৰণতে তেওঁলোকক কয় যে তেওঁ নোৱাৰে। অৰবিন্দৰ বাবে এতিয়াও পলমকৈ বাহিৰত থাকাটো সহজ নহয়, কিয়নো তেওঁ হাৰাশাস্তি খোৱাৰ ভয় কৰে।",
              "gu": "પરંતુ જ્યારે તેઓ કામ પછી અરવિંદને તેમની સાથે રાત્રિભોજન માટે આવવાનું કહેતો , ત્યારે તે સામાન્ય રીતે તેમને કહેતો  કે તે આવી શકશે નહીં.  અરવિંદને હજુ પણ મોડે સુધી બહાર રહેવું આરામદાયક નહતું લાગતું, કારણ કે તેને પજવણીનો ડર હતો."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_3_1",
              "hi": "D2_3_1",
              "ka": "D2_3_1",
              "te": "D2_3_1",
              "or": "D2_3_1",
              "as": "D2_3_1",
              "gu": "D2_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He shares what he feels with his support group during their next meeting.",
              "hi": "वह अपनी अगली बैठक के दौरान अपने समर्थन समूह के साथ क्या अनुभव करता है, वह साझा करता है।",
              "ka": "ಅವರ ಮುಂದಿನ ಸಭೆಯ ಸಮಯದಲ್ಲಿ ಅವರು ತಮ್ಮ ಬೆಂಬಲ ಗುಂಪಿನೊಂದಿಗೆ ತನಗೆ ಅನಿಸಿದ್ದನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "అతను తన తదుపరి సమావేశంలో తన సపోర్ట్ గ్రూప్ తో తనకు అనిపించిన వాటిని పంచుకున్నాడు.",
              "or": "ସେ ସେମାନଙ୍କର ପରବର୍ତ୍ତୀ ସାକ୍ଷାତ ସମୟରେ ନିଜ ସମର୍ଥନ ଗୋଷ୍ଠୀ ସହିତ ଯାହା ଅନୁଭବ କରନ୍ତି ତାହା ସେୟାର କରନ୍ତି |",
              "as": "তেওঁ তেওঁলোকৰ পৰৱৰ্তী বৈঠকৰ সময়ত তেওঁৰ সমৰ্থন গোটৰ সৈতে কি অনুভৱ কৰে সেয়া আলোচনা কৰে।",
              "gu": "તે પોતાની આગામી મીટિંગ દરમિયાન તેના સપોર્ટ ગ્રૂપ સાથે જે અનુભવે છે તે શેર કરે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_3_2//",
              "hi": "D2_3_2 //",
              "ka": "D2_3_2//",
              "te": "D2_3_2//",
              "or": "D2_3_2//",
              "as": "D2_3_2//",
              "gu": "D2_3_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I feel so trapped. On one hand, people have started considering me as friends, and I want to join them for dinners and get to know them outside work.",
              "hi": "मैं बहुत फंसा हुआ अनुभव कर रहा हूं। एक तरफ तो लोग मुझे मित्र मानने लगे हैं, और मैं उनके साथ डिनर में शामिल होना चाहता हूं और काम के अतिरिक्त भी उन्हें जानना चाहता हूं।",
              "ka": "ನಾನು ತುಂಬಾ ಸಿಕ್ಕಿಬಿದ್ದಿದ್ದೇನೆ. ಒಂದೆಡೆ, ಜನರು ನನ್ನನ್ನು ಸ್ನೇಹಿತರೆಂದು ಪರಿಗಣಿಸಲು ಪ್ರಾರಂಭಿಸಿದ್ದಾರೆ, ಮತ್ತು ನಾನು ಅವರೊಂದಿಗೆ ರಾತ್ರಿಯ ಊಟಕ್ಕೆ ಸೇರಲು ಮತ್ತು ಹೊರಗಿನ ಕೆಲಸದ ಬಗ್ಗೆ ತಿಳಿದುಕೊಳ್ಳಲು ಬಯಸುತ್ತೇನೆ.",
              "te": "నాకు చిక్కుకున్నట్లు అనిపిస్తుంది. ఒక వైపు, వ్యక్తులు నన్ను స్నేహితులుగా పరిగణించడం ప్రారంభించారు, మరియు నేను వారితో కలిసి డిన్నర్‌లలో పాల్గొనాలనుకుంటున్నాను మరియు బయట పనిని తెలుసుకోవాలనుకుంటున్నాను.",
              "or": "ମୁଁ ବହୁତ ଜାଲରେ ପଡ଼ିଛି | ଗୋଟିଏ ପଟେ, ଲୋକମାନେ ମୋତେ ବନ୍ଧୁ ଭାବରେ ବିବେଚନା କରିବା ଆରମ୍ଭ କରିଛନ୍ତି, ଏବଂ ମୁଁ ସେମାନଙ୍କ ସହିତ ରାତ୍ରୀ ଭୋଜନରେ ଯୋଗଦେବାକୁ ଏବଂ ସେମାନଙ୍କୁ ବାହ୍ୟ କାର୍ଯ୍ୟ ବିଷୟରେ ଜାଣିବାକୁ ଚାହୁଁଛି |",
              "as": "মই ইমান আৱদ্ধ অনুভৱ কৰোঁ। এফালে, মানুহে মোক বন্ধু হিচাপে বিবেচনা কৰা আৰম্ভ কৰিছে, আৰু মই তেওঁলোকৰ সৈতে ৰাতিৰ সাঁজ খাব বিচাৰো আৰু তেওঁলোকক কামৰ বাহিৰত জানিব বিচাৰো।",
              "gu": "મને લાગે છે કે હું ફસાઈ ગયો છું   એક તરફ, લોકો મને મિત્રો તરીકે માનવા લાગ્યા છે, અને હું ડિનરમાં તેમની સાથે જોડાવા અને  કામના કલાકોથી બાહર તેમને જાણવા માંગુ છું."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_3_3////",
              "hi": "D2_3_3 //////",
              "ka": "D2_3_3////",
              "te": "D2_3_3////",
              "or": "D2_3_3////",
              "as": "D2_3_3////",
              "gu": "D2_3_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "On the other hand, there is that constant fear that the people around me will dislike me. I feel like I'm stuck in between.",
              "hi": "दूसरी ओर, यह डर लगातार बना रहता है कि मेरे आसपास के लोग मुझे नापसंद करेंगे। मुझे ऐसा लग रहा है कि मैं बीच में फंस गया हूं।",
              "ka": "ಮತ್ತೊಂದೆಡೆ, ನನ್ನ ಸುತ್ತಮುತ್ತಲಿನ ಜನರು ನನ್ನನ್ನು ಇಷ್ಟಪಡುವುದಿಲ್ಲ ಎಂಬ ನಿರಂತರ ಭಯವಿದೆ. ನಾನು ನಡುವೆ ಸಿಲುಕಿಕೊಂಡಿದ್ದೇನೆ ಎಂದು ನನಗೆ ಅನಿಸುತ್ತದೆ.",
              "te": "మరోవైపు, నా చుట్టూ ఉన్నవారు నన్ను ఇష్టపడరు అనే భయం నిరంతరం ఉంటుంది. నేను మధ్యలో ఇరుక్కుపోయినట్లు అనిపిస్తుంది.",
              "or": "ଅନ୍ୟ ପଟେ, ସେହି ନିରନ୍ତର ଭୟ ଅଛି ଯେ ମୋ ଆଖପାଖର ଲୋକମାନେ ମୋତେ ନାପସନ୍ଦ କରିବେ | ମୋତେ ଲାଗୁଛି ଯେମିତି ମୁଁ ମଝିରେ ଅତ୍କିଛି |",
              "as": "আনহাতে, মোৰ চাৰিওফালৰ মানুহে মোক পছন্দ নকৰিব বুলি নিৰন্তৰ ভয় লাগি থাকে। মই অনুভৱ কৰোঁ যেন মই এইবোৰৰ মাজতে আবদ্ধ হৈ আছোঁ।",
              "gu": "બીજી બાજુ, મારી આસપાસના લોકો મને નાપસંદ કરશે તેવો સતત ભય રહે છે.  મને લાગે છે કે હું વચ્ચે અટવાઈ ગયો છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "We hear you.",
              "hi": "हम तुम्हें सुनते हैं।",
              "ka": "ನಾವು ನಿಮ್ಮನ್ನು ಕೇಳುತ್ತೇವೆ.",
              "te": "మేము మీ మాట వింటాము.",
              "or": "ଆମେ ତୁମ କଥା ଶୁଣୁଛୁ |",
              "as": "আমি তোমাৰ কথা শুনিছো।",
              "gu": "અમે તમારી વાત સાંભળી રહ્યા  છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Arvind, do you think embracing your identity will help you accept yourself?",
              "hi": "अरविंद, क्या आपको लगता है कि अपनी पहचान को अपनाने से आपको खुद को स्वीकार करने में सहायता मिलेगी?",
              "ka": "ಅರವಿಂದ್, ನಿಮ್ಮ ಗುರುತನ್ನು ಅಳವಡಿಸಿಕೊಳ್ಳುವುದು ನಿಮ್ಮನ್ನು ಒಪ್ಪಿಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ ಎಂದು ನೀವು ಭಾವಿಸುತ್ತೀರಾ?",
              "te": "అరవింద్, మీ గుర్తింపును స్వీకరించడం మిమ్మల్ని మీరు అంగీకరించడంలో సహాయపడుతుందని మీరు భావిస్తున్నారా?",
              "or": "ଅରବିନ୍ଦ, ତୁମେ ଭାବୁଛ କି ତୁମର ପରିଚୟ ଗ୍ରହଣ କରିବା ତୁମକୁ ଗ୍ରହଣ କରିବାରେ ସାହାଯ୍ୟ କରିବ?",
              "as": "অৰবিন্দ, আপুনি ভাবেনে যে আপোনাৰ পৰিচয় গ্ৰহণ কৰিলে আপোনাৰ নিজকে গ্ৰহণ কৰাত সহায় হ'ব?",
              "gu": "અરવિંદ, શું તમને લાગે છે કે તમારી ઓળખ સ્વીકારવાથી તમને તમારી જાતને સ્વીકારવામાં મદદ મળશે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "We have all gone through this. This is a long and complex journey. Generally, one tends to come out of this journey feeling strong and beautiful.",
              "hi": "हम सब इससे गुजरे हैं। यह एक लंबी और जटिल यात्रा है। आम तौर पर, व्यक्ति इस यात्रा से बाहर निकल कर मजबूत और अच्छा अनुभव करता है।",
              "ka": "ನಾವೆಲ್ಲರೂ ಇದರ ಮೂಲಕ ಹೋಗಿದ್ದೇವೆ. ಇದು ದೀರ್ಘ ಮತ್ತು ಸಂಕೀರ್ಣ ಪ್ರಯಾಣ. ಸಾಮಾನ್ಯವಾಗಿ, ಒಬ್ಬರು ಈ ಪ್ರಯಾಣದಿಂದ ಬಲವಾದ ಮತ್ತು ಸುಂದರವಾಗಿ ಹೊರಬರಲು ಒಲವು ತೋರುತ್ತಾರೆ.",
              "te": "మనమందరం ఇది అనుభవించాము. ఇది సుదీర్ఘమైన మరియు సంక్లిష్టమైన ప్రయాణం. సాధారణంగా, ఒక వ్యక్తి ఈ ప్రయాణం తర్వాత బలపడినట్లు భావిస్తాడు.",
              "or": "ଆମେ ସମସ୍ତେ ଏହା ଦେଇ ଗତି କରିଛୁ | ଏହା ଏକ ଦୀର୍ଘ ଏବଂ ଜଟିଳ ଯାତ୍ରା | ସାଧାରଣତଃ ଜଣେ ଦୃଢ ଏବଂ ସୁନ୍ଦର ଅନୁଭବ କରି ଏହି ଯାତ୍ରାରୁ ବାହାରକୁ ଆସେ |",
              "as": "আমি সকলোৱে ইয়াৰ মাজেৰে পাৰ হৈছো। এইটো এটা দীঘলীয়া আৰু জটিল যাত্ৰা। সাধাৰণতে, মানুহে শক্তিশালী আৰু সুন্দৰ অনুভৱ কৰি এই যাত্ৰাৰ পৰা ওলাই আহে।",
              "gu": "અમે બધા આમાંથી પસાર થયા છીએ.  આ એક લાંબી અને જટિલ યાત્રા છે.  સામાન્ય રીતે, વ્યક્તિ આ સફરમાંથી બહાર આવવાથી મજબૂત અને સુંદર લાગે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_4_1",
              "hi": "D2_4_1",
              "ka": "D2_4_1",
              "te": "D2_4_1",
              "or": "D2_4_1",
              "as": "D2_4_1",
              "gu": "D2_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind returns to his room that day and looks in the mirror. He is not sure if he is comfortable with being called Arvind.",
              "hi": "अरविंद उस दिन अपने कमरे में लौटता है और आईने में देखता है। उसे समझ नहीं  आ रहा है कि वे अरविंद कहलाने में सहज हैं या नहीं।",
              "ka": "ಆ ದಿನ ಅರವಿಂದ್ ತನ್ನ ಕೋಣೆಗೆ ಹಿಂತಿರುಗಿ ಕನ್ನಡಿಯಲ್ಲಿ ನೋಡುತ್ತಾನೆ. ಅರವಿಂದ್ ಎಂದು ಕರೆದರೆ ತನಗೆ ಆರಾಮವೇ ಇಲ್ಲವೇನೋ ಎಂಬ ಖಾತ್ರಿಯಿಲ್ಲ.",
              "te": "ఆ రోజు అరవింద్ తన గదికి తిరిగి వచ్చి అద్దంలో చూస్తాడు. అరవింద్ అని పిలవడం తనకు సౌకర్యంగా ఉంటుందో లేదో అతనికి తెలియదు.",
              "or": "ସେଦିନ ଅରବିନ୍ଦ ନିଜ କୋଠରୀକୁ ଫେରି ଦର୍ପଣକୁ ଚାହିଁଲେ | ସେ ଅରବିନ୍ଦ କୁହାଯିବାରେ ଆରାମଦାୟକ କି ନାହିଁ ସେ ନିଶ୍ଚିତ ନୁହଁନ୍ତି |",
              "as": "অৰবিন্দ সেইদিনা তেওঁৰ ৰুমলৈ ঘূৰি আহিছিল আৰু নিজকে আইনাত চালে। তেওঁ নিশ্চিত নহয় যে তেওঁ অৰবিন্দ বুলি কোৱাত সহজবোধ কৰিব নে নাই।",
              "gu": "તે દિવસે અરવિંદ તેના રૂમમાં પાછો ફર્યો અને અરીસામાં જોયું .  તેને ખાતરી નથી કે પોતાને અરવિંદ તરીકે ઓળખાવાથી  સારું લાગે છે કે નહીં."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_4_2",
              "hi": "D2_4_2",
              "ka": "D2_4_2",
              "te": "D2_4_2",
              "or": "D2_4_2",
              "as": "D2_4_2",
              "gu": "D2_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He has always known that he is more comfortable living as a woman. He wishes he was born with a female body instead.",
              "hi": "वह हमेशा से जानता है कि वह एक महिला के रूप में रहने में अधिक सहज है। वह चाहता है कि वह इसके बजाय एक महिला शरीर के साथ पैदा हुआ होता।",
              "ka": "ಹೆಣ್ಣಾಗಿ ಬದುಕುವುದು ಹೆಚ್ಚು ಆರಾಮದಾಯಕ ಎಂದು ಅವರು ಯಾವಾಗಲೂ ತಿಳಿದಿದ್ದಾರೆ. ಬದಲಿಗೆ ಹೆಣ್ಣಿನ ದೇಹದೊಂದಿಗೆ ಹುಟ್ಟಿದ್ದರೆಂದು ಬಯಸುತ್ತಾನೆ.",
              "te": "అతను స్త్రీగా జీవించడం మరింత సౌకర్యంగా ఉంటుందని అతనికి ఎప్పటి నుంచో తెలుసు. అతను బదులుగా స్త్రీ శరీరంతో జన్మిస్తే బాగుండేది అని కోరుకుంటాడు.",
              "or": "ସେ ସର୍ବଦା ଜାଣିଛନ୍ତି ଯେ ସେ ଜଣେ ମହିଳା ଭାବରେ ଜୀବନଯାପନ କରିବାରେ ଅଧିକ ଆରାମଦାୟକ | ସେ ଇଚ୍ଛା କରନ୍ତି ଯେ ସେ ଏହା ବଦଳରେ ଏକ ମହିଳା ଶରୀର ସହିତ ଜନ୍ମ ହୋଇଥିଲେ |",
              "as": "তেওঁ সদায় জানে যে তেওঁ এগৰাকী মহিলা হিচাপে জীয়াই থাকিবলৈ বেছি ভাল পাব। তেওঁ আশা কৰে যে তেওঁ যদি ল'ৰাৰ সলনি এটা মহিলাৰ শৰীৰৰ সৈতে জন্ম গ্ৰহণ কৰিলেহেতেন।",
              "gu": "તે હંમેશાથી જાણતો હતો કે તે એક મહિલા તરીકે જીવવું તેના માટે વધુ આરામદાયક હતું.  તે ઈચ્છે છે કે તેના બદલે તે સ્ત્રી શરીર સાથે જન્મ લીધો હોત તો."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_4_3",
              "hi": "D2_4_3",
              "ka": "D2_4_3",
              "te": "D2_4_3",
              "or": "D2_4_3",
              "as": "D2_4_3",
              "gu": "D2_4_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He decides to read up more regarding this. His friends in the support group also help him understand what he feels.",
              "hi": "वह इसके बारे में अधिक पढ़ने का निश्चय करता है। समर्थन समूह में उसके मित्र भी उसे समझने में सहायता करते हैं कि वह क्या अनुभव करता है।",
              "ka": "ಈ ಬಗ್ಗೆ ಹೆಚ್ಚು ಓದಲು ಅವನು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಬೆಂಬಲ ಗುಂಪಿನಲ್ಲಿರುವ ಅವನ ಸ್ನೇಹಿತರು ಸಹ ಅವನಿಗೆ ಏನನಿಸುತ್ತದೆ ಎಂಬುದನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತಾರೆ.",
              "te": "అతను దీని గురించి మరింత చదవాలని నిర్ణయించుకున్నాడు. సపోర్టు గ్రూప్‌లోని అతని స్నేహితులు కూడా అతని భావాలను అర్థం చేసుకోవడంలో సహాయపడతారు.",
              "or": "ସେ ଏ ବିଷୟରେ ଅଧିକ ପଢିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ସମର୍ଥନ ଗୋଷ୍ଠୀ ରେ ଥିବା ତାଙ୍କର ସାଙ୍ଗମାନେ ମଧ୍ୟ ସେ ଯାହା ଅନୁଭବ କରନ୍ତି ତାହା ବୁଝିବାରେ ସାହାଯ୍ୟ କରନ୍ତି |",
              "as": "তেওঁ এই সন্দৰ্ভত আৰু বেছি পঢ়িবলৈ সিদ্ধান্ত লয়। সমৰ্থন গোটত থকা তেওঁৰ বন্ধুসকলেও তেওঁ কি অনুভৱ কৰে বুজাত সহায় কৰে।",
              "gu": "તેણે આ અંગે વધુ વાંચવાનું નક્કી કર્યું.  સપોર્ટ ગ્રુપના તેના મિત્રો પણ તેને શું અનુભવે છે તે સમજવામાં મદદ કરે છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_3_4////",
              "hi": "D2_3_4 /////",
              "ka": "D2_3_4////",
              "te": "D2_3_4////",
              "or": "D2_3_4////",
              "as": "D2_3_4////",
              "gu": "D2_3_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He eventually feels confident enough to tell everyone in his support group what he feels.",
              "hi": "उसमें अंततः अपने समर्थन समूह में सभी को यह बताने के लिए पर्याप्त आत्मविश्वास आ गया है कि वह क्या अनुभव करता है।",
              "ka": "ಅವನು ಅಂತಿಮವಾಗಿ ತನ್ನ ಬೆಂಬಲ ಗುಂಪಿನಲ್ಲಿರುವ ಎಲ್ಲರಿಗೂ ತಾನು ಏನನಿಸುತ್ತದೆ ಎಂಬುದನ್ನು ಹೇಳಲು ಸಾಕಷ್ಟು ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಅನುಭವಿಸುತ್ತಾನೆ.",
              "te": "అతను చివరికి తన సపోర్ట్ గ్రూప్‌లోని ప్రతి ఒక్కరికి తన భావాలను చెప్పగలిగేంత నమ్మకం సాధిస్తాడు.",
              "or": "ସେ ଶେଷରେ ନିଜ ସମର୍ଥନ ଗୋଷ୍ଠୀର ସମସ୍ତଙ୍କୁ ଯାହା ଅନୁଭବ କରନ୍ତି ତାହା ଜଣାଇବାକୁ ଯଥେଷ୍ଟ ଆତ୍ମବିଶ୍ୱାସୀ ଅନୁଭବ କରନ୍ତି |",
              "as": "তেওঁ অৱশেষত তেওঁৰ সমৰ্থন গোটৰ সকলোকে তেওঁ কি অনুভৱ কৰে ক'বলৈ যথেষ্ট আত্মবিশ্বাসী অনুভৱ কৰে।",
              "gu": "આખરે તે પૂરતો આત્મવિશ્વાસ અનુભવે છે કે તે પોતાના સપોર્ટ ગ્રૂપને  તે શું અનુભવે છે તે જણાવે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I have spent the last few weeks understanding who I am and how I want to be seen.",
              "hi": "मैंने पिछले कुछ सप्ताह यह समझने में बिताए हैं कि मैं कौन हूं और मैं कैसे दिखना चाहता हूं।",
              "ka": "ನಾನು ಯಾರು ಮತ್ತು ನಾನು ಹೇಗೆ ಕಾಣಬೇಕೆಂದು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಕಳೆದ ಕೆಲವು ವಾರಗಳನ್ನು ಕಳೆದಿದ್ದೇನೆ.",
              "te": "నేను ఎవరో మరియు నేను ఎలా కనిపించాలనుకుంటున్నానో అర్థం చేసుకోవడానికి గత కొన్ని వారాలు గడిపాను.",
              "or": "ମୁଁ କିଏ ଏବଂ ମୁଁ କିପରି ଦେଖାଯିବାକୁ ଚାହୁଁଛି ବୁଝିବା ପାଇଁ ମୁଁ ଗତ କିଛି ସପ୍ତାହ ଅତିବାହିତ କରିଛି |",
              "as": "মই যোৱা কেইসপ্তাহমান ধৰি বুজি পাইছো যে মই কোন আৰু মই নিজকে কেনেকৈ চাব বিচাৰো।",
              "gu": "હું કોણ છું અને હું કેવી રીતે ઓળખાવા માંગુ છું તે સમજવામાં મેં છેલ્લાં કેટલાંક અઠવાડિયાં પસાર કર્યાં છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Go on! Tell them!",
              "hi": "जारी रखें! उन्हें बताओ!",
              "ka": "ಮುಂದೆ ಸಾಗು! ಅವರಿಗೆ ಹೇಳು!",
              "te": "అలాగే! వాళ్ళకి చెప్పండి!",
              "or": "ଜାରି ରଖ ! ସେମାନଙ୍କୁ କୁହନ୍ତୁ !",
              "as": "কৈ যোৱা! তেওঁলোকক কোৱা!",
              "gu": "આગળ વધો!  બધાને કહો!"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I would like you all to call me Asmita. That is the name I have chosen. And I would like to use 'she/her' pronouns.",
              "hi": "मैं चाहूंगा कि आप सब मुझे अस्मिता कहें। यही वह नाम है जिसे मैंने चुना है। और मैं 'वह / उसके' सर्वनाम का उपयोग करना चाहूंगा।",
              "ka": "ನೀವೆಲ್ಲರೂ ನನ್ನನ್ನು ಅಸ್ಮಿತಾ ಎಂದು ಕರೆಯಬೇಕೆಂದು ನಾನು ಬಯಸುತ್ತೇನೆ. ನಾನು ಆರಿಸಿಕೊಂಡ ಹೆಸರು ಅದು. ಮತ್ತು ನಾನು 'ಅವಳು/ಅವಳ' ಸರ್ವನಾಮಗಳನ್ನು ಬಳಸಲು ಬಯಸುತ್ತೇನೆ.",
              "te": "మీరందరూ నన్ను అస్మిత అని పిలవాలని కోరుకుంటున్నాను. అదే నేను ఎంచుకున్న పేరు. మరియు నేను 'ఆమె/తను' సర్వనామాలను ఉపయోగించాలనుకుంటున్నాను.",
              "or": "ମୁଁ ଚାହେଁ ତୁମେ ସମସ୍ତେ ମୋତେ ଅସମିତା ବୋଲି ଡାକ | ସେହି ନାମ ମୁଁ ବାଛିଛି | ଏବଂ ମୁଁ 'ସେ / ତା’ର' ଉଚ୍ଚାରଣ ବ୍ୟବହାର କରିବାକୁ ଚାହେଁ |",
              "as": "মই বিচাৰো যে আপোনালোক সকলোৱে মোক অস্মিতা বুলি মাতিব। মই সেই নামটো বাছনি কৰিছো। আৰু মই 'তাই/তাইৰ' সৰ্বনাম ব্যৱহাৰ কৰিব বিচাৰো।",
              "gu": "હું ઈચ્છું છું કે તમે બધા મને અસ્મિતા કહીને બોલાવો.  એ નામ મેં પસંદ કર્યું છે.  અને હું 'તેણી/તેણીના' સર્વનામનો ઉપયોગ કરવા માંગુ છું."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "D2_3_5",
              "hi": "D2_3_5",
              "ka": "D2_3_5",
              "te": "D2_3_5",
              "or": "D2_3_5",
              "as": "D2_3_5",
              "gu": "D2_3_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Everyone in the support group starts clapping. They are so proud of Asmita for finally coming out with her identity!",
              "hi": "सहायता समूह में सभी लोग ताली बजाने लगते हैं। उन्हें अस्मिता पर अपनी पहचान के साथ आने पर बहुत गर्व है!",
              "ka": "ಬೆಂಬಲ ಗುಂಪಿನಲ್ಲಿರುವ ಪ್ರತಿಯೊಬ್ಬರೂ ಚಪ್ಪಾಳೆ ತಟ್ಟಲು ಪ್ರಾರಂಭಿಸುತ್ತಾರೆ. ಅಸ್ಮಿತಾ ತನ್ನ ಗುರುತನ್ನು ಬಹಿರಂಗಪಡಿಸಿದ್ದಕ್ಕಾಗಿ ಅವರು ತುಂಬಾ ಹೆಮ್ಮೆಪಡುತ್ತಾರೆ!",
              "te": "సపోర్ట్ గ్రూప్‌లోని ప్రతి ఒక్కరూ చప్పట్లు కొట్టడం ప్రారంభిస్తారు. అస్మిత తన గుర్తింపుతో బయటకు వచ్చినందుకు వారు చాలా గర్వపడుతున్నారు!",
              "or": "ସମର୍ଥନ ଗୋଷ୍ଠୀର ସମସ୍ତେ କରତାଳି ଦେବା ଆରମ୍ଭ କରନ୍ତି | ଶେଷରେ ତାଙ୍କ ପରିଚୟ ସହିତ ବାହାରକୁ ଆସିବା ପାଇଁ ସେମାନେ ଅସ୍ମିତା ପାଇଁ ବହୁତ ଗର୍ବିତ!",
              "as": "সমৰ্থন গোটৰ সকলোৱে হাততালি বজাবলৈ আৰম্ভ কৰে। অৱশেষত তাই তাইৰ পৰিচয়েৰে সন্মুখলৈ অহাৰ বাবে তেওঁলোকে অস্মিতাক লৈ গৌৰৱান্বিত!",
              "gu": "સપોર્ટ ગ્રુપના દરેક વ્યક્તિ તાળીઓ પાડવાનું શરૂ કરે છે.  તેઓને અસ્મિતા પર ખૂબ ગર્વ છે કે આખરે તેણીએ પોતાની ઓળખ બહાર પાડી!"
            }
          }
        ]
      },
      "scene29": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_1",
              "hi": "D3_1_1",
              "ka": "D3_1_1",
              "te": "D3_1_1",
              "or": "D3_1_1",
              "as": "D3_1_1",
              "gu": "D3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita has been working at MetaLab for more than a year now. Her focus has been on growing professionally and also saving money.",
              "hi": "अस्मिता मेटालैब में एक साल से अधिक समय से काम कर रही है। उसका ध्यान प्रोफेशनल रूप से तरक्की करने और पैसे बचाने पर रहा है।",
              "ka": "ಅಸ್ಮಿತಾ ಮೆಟಾಲ್ಯಾಬ್‌ನಲ್ಲಿ ಒಂದು ವರ್ಷಕ್ಕೂ ಹೆಚ್ಚು ಕಾಲ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದಾರೆ. ಆಕೆಯ ಗಮನವು ವೃತ್ತಿಪರವಾಗಿ ಬೆಳೆಯುವುದರ ಜೊತೆಗೆ ಹಣವನ್ನು ಉಳಿಸುವುದು ಆಗಿರುತ್ತದೆ.",
              "te": "అస్మిత మెటాల్యాబ్‌లో ఏడాదికి పైగా పని చేస్తోంది. ఆమె దృష్టి వృత్తిపరంగా ఎదగడం మరియు డబ్బు ఆదా చేయడంపైనే ఉంది.",
              "or": "ଅସ୍ମିତା ଏକ ବର୍ଷରୁ ଅଧିକ ସମୟ ଧରି ମେଟାଲାବରେ କାମ କରୁଛନ୍ତି | ବୃତ୍ତିଗତ ଭାବରେ ବୃଦ୍ଧି ଏବଂ ଅର୍ଥ ସଞ୍ଚୟ ଉପରେ ମଧ୍ୟ ତାଙ୍କର ଧ୍ୟାନ ଦିଆଯାଇଛି |",
              "as": "অস্মিতাই এবছৰৰো অধিক সময় ধৰি মেটালেবত কাম কৰি আছে। তাইৰ পেছাদাৰীভাৱে আগবাঢ়ি যাবলৈ আৰু ধন ৰাহি কৰাত মনোযোগ দিছিল।",
              "gu": "અસ્મિતા છેલ્લા એક વર્ષથી વધુ સમયથી મેટાલેબમાં કામ કરી રહી છે.  તેણીનું ધ્યાન વ્યવસાયિક રીતે આગળ વધવા અને પૈસા બચાવવા પર છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_2",
              "hi": "D3_1_2",
              "ka": "D3_1_2",
              "te": "D3_1_2",
              "or": "D3_1_2",
              "as": "D3_1_2",
              "gu": "D3_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Apart from work, Asmita has been reading and talking in the support group about transitioning.",
              "hi": "अस्मिता काम के अलावा सपोर्ट ग्रुप में ट्रांजिशनिंग के बारे में पढ़ती और बातें करती रही है।",
              "ka": "ಕೆಲಸದ ಹೊರತಾಗಿ, ಅಸ್ಮಿತಾ ಪರಿವರ್ತನೆಯ ಬಗ್ಗೆ ಬೆಂಬಲ ಗುಂಪಿನಲ್ಲಿ ಓದುತ್ತಿದ್ದಾರೆ ಮತ್ತು ಮಾತನಾಡುತ್ತಿದ್ದಾರೆ.",
              "te": "పనితో పాటు, అస్మిత ట్రాన్సిషన్ గురించి సపోర్ట్ గ్రూప్‌లో చదువుతోంది మరియు మాట్లాడుతోంది.",
              "or": "କାର୍ଯ୍ୟ ବ୍ୟତୀତ, ଅସ୍ମିତା ସ୍ଥାନାନ୍ତରଣ ବିଷୟରେ ସମର୍ଥନ ଗୋଷ୍ଠୀ ରେ ପଢୁଛନ୍ତି ଏବଂ କଥାବାର୍ତ୍ତା କରୁଛନ୍ତି |",
              "as": "কামৰ উপৰিও, অস্মিতাই সমৰ্থন গোটত পৰিৱৰ্তনৰ বিষয়ে পঢ়ি আছে আৰু কথা পাতি আছে।",
              "gu": "કામ ઉપરાંત, અસ્મિતા ટ્રાન્સિશન વિશે સપોર્ટ ગ્રુપમાં વાંચતી અને વાત કરતી રહી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_3",
              "hi": "D3_1_3",
              "ka": "D3_1_3",
              "te": "D3_1_3",
              "or": "D3_1_3",
              "as": "D3_1_3",
              "gu": "D3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She has been spending a lot of time with Vandana to understand everything she must do in this process. Vandana is also a trans woman, so she understands many of the problems that Asmita may face.",
              "hi": "इस प्रक्रिया में उसे जो कुछ भी करना चाहिए उसे समझने के लिए वह वंदना के साथ काफी समय बिता रही है। वंदना भी एक ट्रांस महिला हैं, इसलिए वह अस्मिता की कई समस्याओं को समझती है।",
              "ka": "ಈ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ಅವಳು ಮಾಡಬೇಕಾದ ಎಲ್ಲವನ್ನೂ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಅವಳು ವಂದನಾಳೊಂದಿಗೆ ಸಾಕಷ್ಟು ಸಮಯವನ್ನು ಕಳೆಯುತ್ತಿದ್ದಳು. ವಂದನಾ ಕೂಡ ಟ್ರಾನ್ಸ್ ಮಹಿಳೆ, ಆದ್ದರಿಂದ ಅಸ್ಮಿತಾ ಎದುರಿಸಬಹುದಾದ ಅನೇಕ ಸಮಸ್ಯೆಗಳನ್ನು ಅವಳು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "ఈ ప్రక్రియలో ఆమె తప్పక చేయవలసిన ప్రతిదాన్ని అర్థం చేసుకోవడానికి ఆమె వందనతో చాలా సమయం గడుపుతోంది. వందన కూడా ట్రాన్స్ ఉమెన్ కాబట్టి అస్మిత ఎదుర్కొనే అనేక సమస్యలను ఆమె అర్థం చేసుకుంది.",
              "or": "ଏହି ପ୍ରକ୍ରିୟାରେ ସେ ଯାହା କରିବା ଆବଶ୍ୟକ ତାହା ବୁଝିବା ପାଇଁ ସେ ଭଣ୍ଡନାଙ୍କ ସହ ବହୁତ ସମୟ ଅତିବାହିତ କରିଛନ୍ତି | ବନ୍ଦନା ମଧ୍ୟ ଜଣେ ଟ୍ରାନ୍ସ ମହିଳା, ତେଣୁ ସେ ଅସ୍ମିତା ସମ୍ମୁଖୀନ ହେଉଥିବା ଅନେକ ସମସ୍ୟା ବୁଝନ୍ତି |",
              "as": "তাই এই প্ৰক্ৰিয়াটোত কৰিব লগা সকলোবোৰ বুজিবলৈ বন্দনাৰ সৈতে যথেষ্ট সময় অতিবাহিত কৰি আছে। বন্দনা এগৰাকী ট্ৰেন্স মহিলাও, সেয়েহে তেওঁ অস্মিতাই সন্মুখীন হ'ব পৰা বহুতো সমস্যা বুজি পায়।",
              "gu": "આ પ્રક્રિયામાં તેણે શું કરવું જોઈએ તે બધું સમજવા માટે તે વંદના સાથે ઘણો સમય વિતાવી હતી.  વંદના પણ એક ટ્રાન્સ વુમન છે, તેથી તે અસ્મિતાને આવી શકે તેવી ઘણી સમસ્યાઓને સમજે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_4//",
              "hi": "D3_1_4 //",
              "ka": "D3_1_4//",
              "te": "D3_1_4//",
              "or": "D3_1_4//",
              "as": "D3_1_4//",
              "gu": "D3_1_4//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Vandana",
              "hi": "वंदना",
              "ka": "ವಂದನಾ",
              "te": "వందన",
              "or": "ବନ୍ଦନା",
              "as": "বন্দনা",
              "gu": "વંદના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Asmita, you must remember everyone’s experience with coming out is unique and different. But there are a few things that you must do to make things easier for you and others to get used to your new identity.",
              "hi": "अस्मिता, आपको समझना चाहिए कि इस परिस्थिति से बाहर आने का हर किसी का अनुभव अनोखा और अलग होता है। लेकिन कुछ चीजें हैं जो आपको अपने और दूसरों के लिए अपनी नई पहचान के साथ अभ्यस्त होने के लिए चीजों को सरल बनाने के लिए करनी चाहिए।",
              "ka": "ಅಸ್ಮಿತಾ, ಹೊರಬರುವ ಪ್ರತಿಯೊಬ್ಬರ ಅನುಭವವು ಅನನ್ಯ ಮತ್ತು ವಿಭಿನ್ನವಾಗಿದೆ ಎಂದು ನೀವು ನೆನಪಿನಲ್ಲಿಟ್ಟುಕೊಳ್ಳಬೇಕು. ಆದರೆ ನೀವು ಮತ್ತು ಇತರರಿಗೆ ನಿಮ್ಮ ಹೊಸ ಗುರುತನ್ನು ಬಳಸಿಕೊಳ್ಳಲು ವಿಷಯಗಳನ್ನು ಸುಲಭಗೊಳಿಸಲು ನೀವು ಮಾಡಬೇಕಾದ ಕೆಲವು ವಿಷಯಗಳಿವೆ.",
              "te": "అస్మితా, బయటకు వచ్చిన ప్రతి ఒక్కరి అనుభవం ప్రత్యేకమైనది మరియు విభిన్నమైనది అని నువ్వు గుర్తుంచుకోవాలి. కానీ నువ్వు మరియు ఇతరులు నీ కొత్త గుర్తింపుకు అలవాటు పడేలా చేయడం కోసం నువ్వు తప్పనిసరిగా కొన్ని విషయాలు చేయాలి.",
              "or": "ଅସ୍ମିତା, ବାହାରକୁ ଆସିବା ସହିତ ସମସ୍ତଙ୍କର ଅନୁଭୂତି ଅନନ୍ୟ ଏବଂ ଭିନ୍ନ ଅଟେ | କିନ୍ତୁ ସେଠାରେ କିଛି ଜିନିଷ ଅଛି ଯାହାକି ତୁମ ଏବଂ ଅନ୍ୟମାନଙ୍କ ପାଇଁ ତୁମର ନୂତନ ପରିଚୟରେ ଅଭ୍ୟସ୍ତ ହେବା ପାଇଁ ଜିନିଷଗୁଡ଼ିକୁ ସହଜ କରିବା ପାଇଁ ତୁମେ ନିଶ୍ଚୟ କରିବା ଉଚିତ |",
              "as": "অস্মিতা, আপুনি মনত ৰাখিব লাগিব যে নতুন পৰিচয়ৰ সৈতে সকলোৰে অভিজ্ঞতা অনন্য আৰু পৃথক। কিন্তু আপোনাৰ আৰু আনৰ বাবে আপোনাৰ নতুন পৰিচয়ৰ সৈতে অভ্যস্ত হ'বলৈ বস্তুবোৰ সহজ কৰি তুলিবলৈ আপুনি কিছুমান কাম কৰিব লাগিব।",
              "gu": "અસ્મિતા, તારે યાદ રાખવું જોઈએ કે બહાર બતાવવાનો દરેકનો અનુભવ અનન્ય અને અલગ હોય છે.  પરંતુ એવી કેટલીક બાબતો છે જે તારે અને અન્ય લોકો માટે તારી નવી ઓળખની આદત પડે તે માટે વસ્તુઓને સરળ બનાવવા માટે તારે કાર્ય કરવું જોઈએ."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_5_reveal",
              "hi": "D3_1_5_रिवील ",
              "ka": "D3_1_5_reveal",
              "te": "D3_1_5_reveal",
              "or": "D3_1_5_reveal",
              "as": "D3_1_5_প্ৰকাশ কৰে",
              "gu": "D3_1_5_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal the steps Vandana shares with Asmita.",
              "hi": "वंदना ने अस्मिता के साथ जो चरण साझा किए, उन्हें प्रकट करने के लिए क्लिक करें।",
              "ka": "ವಂದನಾ ಅವರು ಅಸ್ಮಿತಾ ಅವರೊಂದಿಗೆ ಹಂಚಿಕೊಂಡಿರುವ ಹಂತಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "వందన అస్మితతో పంచుకున్న దశలను రివీల్ చేయడానికి క్లిక్ చేయండి.",
              "or": "ଅସ୍ମିତାଙ୍କ ସହ ବନ୍ଦନା ଅଂଶୀଦାର କରୁଥିବା ଷ୍ଟେପଗୁଡିକ ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "বন্দনাই অস্মিতাৰ সৈতে আলোচনা কৰা পদক্ষেপবোৰ প্ৰকাশ কৰিবলৈ ক্লিক কৰক।",
              "gu": "વંદનાએ અસ્મિતા સાથે જે પગલાં શેર કર્યા છે તે જાણવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Step 1",
              "hi": "चरण 1",
              "ka": "ಹಂತ 1",
              "te": "దశ 1",
              "or": "Step 1",
              "as": "পদক্ষেপ ১",
              "gu": "પગલું 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Step 2",
              "hi": "चरण 2  ",
              "ka": "ಹಂತ 2",
              "te": "దశ 2",
              "or": "Step 2",
              "as": "পদক্ষেপ ২",
              "gu": "પગલું 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Step 3",
              "hi": "चरण 3",
              "ka": "ಹಂತ 3",
              "te": "దశ 3",
              "or": "Step 3",
              "as": "পদক্ষেপ ৩",
              "gu": "પગલું 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Inform your family members and close friends about your decision.",
              "hi": "अपने निर्णय के विषय में अपने परिवार के सदस्यों और घनिष्ठ मित्रों को सूचित करे।",
              "ka": "ನಿಮ್ಮ ನಿರ್ಧಾರದ ಬಗ್ಗೆ ನಿಮ್ಮ ಕುಟುಂಬ ಸದಸ್ಯರು ಮತ್ತು ನಿಕಟ ಸ್ನೇಹಿತರಿಗೆ ತಿಳಿಸಿ.",
              "te": "మీ నిర్ణయం గురించి మీ కుటుంబ సభ్యులు మరియు సన్నిహితులకు తెలియజేయండి.",
              "or": "ତୁମର ନିଷ୍ପତ୍ତି ବିଷୟରେ ତୁମର ପରିବାର ସଦସ୍ୟ ଏବଂ ଘନିଷ୍ଠ ବନ୍ଧୁମାନଙ୍କୁ ଜଣାଅ |",
              "as": "আপোনাৰ সিদ্ধান্তৰ বিষয়ে আপোনাৰ পৰিয়ালৰ সদস্য আৰু ঘনিষ্ঠ বন্ধুবৰ্গক জনাব।",
              "gu": "તમારા નિર્ણય વિશે તમારા પરિવારના સભ્યો અને નજીકના મિત્રોને જાણ કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Talk to HR and your project team.",
              "hi": "एचआर और अपनी प्रोजेक्ट टीम से बात करे।",
              "ka": "HR ಮತ್ತು ನಿಮ್ಮ ಪ್ರಾಜೆಕ್ಟ್ ತಂಡದೊಂದಿಗೆ ಮಾತನಾಡಿ.",
              "te": "HR మరియు మీ ప్రాజెక్ట్ బృందంతో మాట్లాడండి.",
              "or": "HR ଏବଂ ତୁମ ପ୍ରୋଜେକ୍ଟ ଦଳ ସହିତ କଥା ହୁଅ",
              "as": "HR আৰু আপোনাৰ প্ৰকল্প দলৰ সৈতে কথা পাতক।",
              "gu": "HR અને તમારી પ્રોજેક્ટ ટીમ સાથે વાત કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "Update your social media so that people are aware of your transition.",
              "hi": "अपने सोशल मीडिया को अपडेट करे ताकि लोगों को आपके ट्रांज़िशन/ परिवर्तन के बारे में पता चले।",
              "ka": "ನಿಮ್ಮ ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮವನ್ನು ನವೀಕರಿಸಿ ಇದರಿಂದ ಜನರು ನಿಮ್ಮ ಪರಿವರ್ತನೆಯ ಬಗ್ಗೆ ತಿಳಿದಿರುತ್ತಾರೆ.",
              "te": "మీ పరివర్తన గురించి ప్రజలు తెలుసుకునేలా మీ సోషల్ మీడియాను అప్‌డేట్ చేయండి.",
              "or": "ଆପଣଙ୍କର ସୋସିଆଲ୍ ମିଡିଆ ଅପଡେଟ୍ କରନ୍ତୁ ଯାହା ଦ୍ୱାରା ଲୋକମାନେ ଆପଣଙ୍କର ପରିବର୍ତ୍ତନ ବିଷୟରେ ଅବଗତ ହେବେ |",
              "as": "আপোনাৰ সামাজিক মাধ্যম উন্নত কৰক যাতে মানুহে আপোনাৰ পৰিৱৰ্তনৰ বিষয়ে অৱগত হয়।",
              "gu": "તમારા સોશિયલ મીડિયાને અપડેટ કરો જેથી કરીને લોકો તમારા ટ્રાન્સિશનથી વાકેફ થાય."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_6//",
              "hi": "D3_1_6 //",
              "ka": "D3_1_6//",
              "te": "D3_1_6//",
              "or": "D3_1_6//",
              "as": "D3_1_6//",
              "gu": "D3_1_6//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Asmita",
              "hi": "अस्मिता",
              "ka": "ಅಸ್ಮಿತಾ",
              "te": "అస్మిత",
              "or": "ଅସ୍ମିତା",
              "as": "অস্মিতা",
              "gu": "અસ્મિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Vandana",
              "hi": "वंदना",
              "ka": "ವಂದನಾ",
              "te": "వందన",
              "or": "ବନ୍ଦନା",
              "as": "বন্দনা",
              "gu": "વંદના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Asmita",
              "hi": "अस्मिता",
              "ka": "ಅಸ್ಮಿತಾ",
              "te": "అస్మిత",
              "or": "ଅସ୍ମିତା",
              "as": "অস্মিতা",
              "gu": "અસ્મિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Is that all?",
              "hi": " क्या बस यही बात है?",
              "ka": "ಇಷ್ಟೇನಾ?",
              "te": "అంతేనా?",
              "or": "ସେ ସବୁ କି ?",
              "as": "অকল এইবোৰেই নেকি?",
              "gu": "બસ આટલુજ?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Yes, the rest are things that gradually come to you. Choose to dress up however you want to, and remember that your name is not Arvind anymore.",
              "hi": "हां, बाकी चीजें हैं जो धीरे-धीरे तुम्हारे सामने आती हैं। तुम जैसे चाहो वैसे ड्रेस अप करो और याद रखो कि तुम्हारा नाम अब अरविंद नहीं है।",
              "ka": "ಹೌದು, ಉಳಿದವು ಕ್ರಮೇಣ ನಿಮಗೆ ಬರುವ ವಿಷಯಗಳು. ನೀವು ಬಯಸಿದಂತೆ ಉಡುಗೆಯನ್ನು ಆರಿಸಿಕೊಳ್ಳಿ ಮತ್ತು ನಿಮ್ಮ ಹೆಸರು ಅರವಿಂದ್ ಅಲ್ಲ ಎಂಬುದನ್ನು ನೆನಪಿಡಿ.",
              "te": "అవును, మిగిలినవి క్రమంగా నీకు తెలుస్తాయి. నీకు నచ్చిన విధంగా దుస్తులు ధరించు మరియు నీ పేరు అరవింద్ కాదని గుర్తుంచుకో.",
              "or": "ହଁ, ବାକି ସବୁ ଜିନିଷ ଯାହା ଧୀରେ ଧୀରେ ଆପଣଙ୍କ ପାଖକୁ ଆସେ | ଆପଣ ଚାହିଁଲେ ପରିଧାନ କରିବାକୁ ବାଛନ୍ତୁ ଏବଂ ମନେରଖନ୍ତୁ ଯେ ଆପଣଙ୍କର ନାମ ଆଉ ଅରବିନ୍ଦ ନୁହେଁ |",
              "as": "হয়, বাকীবোৰ হৈছে এনে বস্তু যিবোৰ লাহে লাহে আপোনাৰ ওচৰলৈ আহে। আপুনি যিধৰণৰ পোছাক পিন্ধিবলৈ বিচাৰে বাছনি কৰক, আৰু মনত ৰাখিব যে আপোনাৰ নাম এতিয়া অৰবিন্দ নহয়।",
              "gu": "હા, બાકીની વસ્તુઓ છે જે ધીમે ધીમે તારી સામે સ્પષ્ટ થતી જશે.  તૂં ઇચ્છો તે પહેરવાનું પસંદ કર અને યાદ રાખ કે તારું નામ હવે અરવિંદ નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "(Asmita smiles) That won’t be difficult at all.",
              "hi": "(अस्मिता मुस्कराते हुए) जो बिल्कुल कठिन नहीं होगा।",
              "ka": "(ಅಸ್ಮಿತಾ ಮುಗುಳ್ನಕ್ಕು) ಅದು ಕಷ್ಟವೇನೂ ಆಗುವುದಿಲ್ಲ.",
              "te": "(అస్మిత నవ్వుతూ) అది అస్సలు కష్టం కాదు.",
              "or": "(ଅସମିତା ହସନ୍ତି) ତାହା ଜମାରୁ କଷ୍ଟସାଧ୍ୟ ହେବ ନାହିଁ |",
              "as": "(অস্মিতাই হাঁহিছে) সেইটো একেবাৰেই টান নহ'ব।",
              "gu": "(અસ્મિતા સ્મિત કરે છે) તે બિલકુલ મુશ્કેલ નહીં હોય."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_7////",
              "hi": "D3_1_7 ////////////",
              "ka": "D3_1_7////",
              "te": "D3_1_7////",
              "or": "D3_1_7////",
              "as": "D3_1_7////",
              "gu": "D3_1_7////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Yes, but this is only the social transition and the challenges that come with it. Medical transition is the second step to your complete transition.",
              "hi": "हाँ, लेकिन यह केवल सामाजिक परिवर्तन और इसके साथ आने वाली चुनौतियाँ हैं। मेडिकल ट्रांज़िशन तुम्हारे पूर्ण ट्रांज़िशन का दूसरा चरण है।",
              "ka": "ಹೌದು, ಆದರೆ ಇದು ಸಾಮಾಜಿಕ ಪರಿವರ್ತನೆ ಮತ್ತು ಅದರೊಂದಿಗೆ ಬರುವ ಸವಾಲುಗಳು ಮಾತ್ರ. ವೈದ್ಯಕೀಯ ಪರಿವರ್ತನೆಯು ನಿಮ್ಮ ಸಂಪೂರ್ಣ ಪರಿವರ್ತನೆಗೆ ಎರಡನೇ ಹಂತವಾಗಿದೆ.",
              "te": "అవును, కానీ ఇవి సామాజిక పరివర్తన మరియు దానితో వచ్చే సవాళ్లు మాత్రమే. వైద్య పరివర్తన అనేది నీ పూర్తి పరివర్తనకు రెండవ దశ.",
              "or": "ତୁମକୁ ଅରବିନ୍ଦ ଭାବରେ ଗ୍ରହଣ କରିବା ମୋତେ ବହୁତ କଷ୍ଟକର | ମୁଁ ତୁମ ସହିତ କିପରି ବ୍ୟବହାର କରିଛି ସେଥିପାଇଁ ମୁଁ ଦୁଖିତ, ଏବଂ ତୁମକୁ ଗ୍ରହଣ ଏବଂ ସମର୍ଥନ କରିବାକୁ ମୁଁ ଚେଷ୍ଟା କରିବି | ମୁଁ ଖୁସି ଯେ ତୁମେ ଏହି ପରିବର୍ତ୍ତନ କରିଛ |",
              "as": "হয়, কিন্তু এয়া কেৱল সামাজিক পৰিৱৰ্তন আৰু ইয়াৰ সৈতে অহা প্ৰত্যাহ্বান। চিকিৎসা পৰিৱৰ্তন হৈছে আপোনাৰ সম্পূৰ্ণ ৰূপান্তৰৰ দ্বিতীয় পদক্ষেপ।",
              "gu": "હા, પરંતુ આ માત્ર સામાજિક ટ્રાન્સમિશન છે અને તેની સાથે આવતા પડકારો છે.  તબીબી ટ્રાન્સિશન એ તારા સંપૂર્ણ ટ્રાન્સિશનનું બીજું પગલું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, I have already started my hormone therapy. I have been approaching an Endocrinologist who has given me the right hormones to begin my transition. I am saving for the rest of the procedures.",
              "hi": "हां, मैंने अपनी हार्मोन थेरेपी पहले ही शुरू कर दी है। मैं एक एंडोक्रिनोलॉजिस्ट से संपर्क कर रही हूं जिसने मुझे अपना ट्रांज़िशन शुरू करने के लिए सही हार्मोन दिए हैं। मैं बाकी प्रक्रियाओं के लिए बचत कर रही हूं।",
              "ka": "ಹೌದು, ನಾನು ಈಗಾಗಲೇ ನನ್ನ ಹಾರ್ಮೋನ್ ಚಿಕಿತ್ಸೆಯನ್ನು ಪ್ರಾರಂಭಿಸಿದ್ದೇನೆ. ನನ್ನ ಪರಿವರ್ತನೆಯನ್ನು ಪ್ರಾರಂಭಿಸಲು ನನಗೆ ಸರಿಯಾದ ಹಾರ್ಮೋನುಗಳನ್ನು ನೀಡಿದ ಅಂತಃಸ್ರಾವಶಾಸ್ತ್ರಜ್ಞರನ್ನು ನಾನು ಸಂಪರ್ಕಿಸುತ್ತಿದ್ದೇನೆ. ಉಳಿದ ಕಾರ್ಯವಿಧಾನಗಳಿಗಾಗಿ ನಾನು ಉಳಿಸುತ್ತಿದ್ದೇನೆ.",
              "te": "అవును, నేను ఇప్పటికే నా హార్మోన్ థెరపీని ప్రారంభించాను. నా పరివర్తనను ప్రారంభించడానికి నాకు సరైన హార్మోన్‌లను అందించిన ఎండోక్రైనాలజిస్ట్‌ని నేను కలుస్తున్నాను. నేను మిగిలిన విధానాల కోసం ఆదా చేస్తున్నాను.",
              "or": "ହଁ, ମୁଁ ମୋର ହରମୋନ୍ ଥେରାପି ଆରମ୍ଭ କରିସାରିଛି | ମୁଁ ଏକ ଏଣ୍ଡୋକ୍ରାଇନୋଲୋଜିଷ୍ଟଙ୍କ ନିକଟକୁ ଆସୁଛି, ଯିଏ ମୋର ପରିବର୍ତ୍ତନ ଆରମ୍ଭ କରିବା ପାଇଁ ମୋତେ ସଠିକ୍ ହରମୋନ୍ ଦେଇଛି | ମୁଁ ବାକି ପଦ୍ଧତିଗୁଡ଼ିକ ପାଇଁ ସଞ୍ଚୟ କରୁଛି |",
              "as": "হয়, মই ইতিমধ্যে মোৰ হৰমন থেৰাপী আৰম্ভ কৰিছো। মই এজন এণ্ডোক্ৰিনোলজিষ্টৰ ওচৰলৈ গৈ আছোঁ যিয়ে মোক মোৰ পৰিৱৰ্তন আৰম্ভ কৰিবলৈ সঠিক হৰমন দিছে। মই বাকী প্ৰক্ৰিয়াবোৰৰ বাবে টকা জমা কৰি আছোঁ।",
              "gu": "હા, મેં મારી હોર્મોન થેરાપી શરૂ કરી દીધી છે.  હું એન્ડોક્રિનોલોજિસ્ટનો સંપર્ક કરી રહી છું જેણે મને મારા ટ્રાન્સિશનની શરૂઆત કરવા માટે યોગ્ય હોર્મોન્સ આપ્યા છે.  હું બાકીની પ્રક્રિયાઓ માટે બચત કરી રહી છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I am glad, Asmita, that you want to take your next steps of transitioning, but it is not going to be easy. With hormone therapy, you’ll go through both physical and mental changes.",
              "hi": "मुझे खुशी है, अस्मिता, कि आप ट्रांज़िशन के अपने अगले कदम उठाना चाहती हैं, लेकिन यह सहज नहीं होने वाला है। हार्मोन थेरेपी के साथ, आप शारीरिक और मानसिक दोनों परिवर्तनों से गुजरेंगी।",
              "ka": "ಅಸ್ಮಿತಾ, ನೀವು ಪರಿವರ್ತನೆಯ ಮುಂದಿನ ಹಂತಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಬಯಸುತ್ತೀರಿ ಎಂದು ನನಗೆ ಖುಷಿಯಾಗಿದೆ, ಆದರೆ ಅದು ಸುಲಭವಲ್ಲ. ಹಾರ್ಮೋನ್ ಚಿಕಿತ್ಸೆಯೊಂದಿಗೆ, ನೀವು ದೈಹಿಕ ಮತ್ತು ಮಾನಸಿಕ ಬದಲಾವಣೆಗಳ ಮೂಲಕ ಹೋಗುತ್ತೀರಿ.",
              "te": "నేను సంతోషిస్తున్నాను, అస్మితా, నువ్వు నీ తదుపరి పరివర్తన దశలకు సిద్ధంగా ఉన్నావు, కానీ అది అంత సులభం కాదు. హార్మోన్ థెరపీతో, నువ్వు శారీరక మరియు మానసిక మార్పులను ఎదుర్కొంటావు.",
              "or": "ମୁଁ ଖୁସି, ଅସ୍ମିତା, ତୁମେ ତୁମର ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପ ନେବାକୁ ଚାହୁଁଛ, କିନ୍ତୁ ଏହା ସହଜ ହେବ ନାହିଁ | ହରମୋନ୍ ଥେରାପି ସହିତ, ଆପଣ ଉଭୟ ଶାରୀରିକ ଏବଂ ମାନସିକ ପରିବର୍ତ୍ତନ ଦେଇ ଗତି କରିବେ |",
              "as": "মই আনন্দিত, অস্মিতা, আপুনি আপোনাৰ পৰৱৰ্তী পৰিৱৰ্তনৰ পদক্ষেপ ল'ব বিচাৰে, কিন্তু ই সহজ নহ'ব। হৰমন থেৰাপীৰ দ্বাৰা, আপুনি শাৰীৰিক আৰু মানসিক দুয়োধৰণৰ পৰিৱৰ্তনৰ মাজেৰে যাব লাগিব।",
              "gu": "મને આનંદ છે, અસ્મિતા, તૂં પોતાના ટ્રાન્સિશનના આગળના પગલાં લેવા માગે છે, પરંતુ તે સરળ નથી.  હોર્મોન ઉપચાર સાથે, તૂં શારીરિક અને માનસિક બંને ફેરફારોમાંથી પસાર થઈશ."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_8//",
              "hi": "D3_1_8 //",
              "ka": "D3_1_8//",
              "te": "D3_1_8//",
              "or": "D3_1_8//",
              "as": "D3_1_8//",
              "gu": "D3_1_8//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Asmita",
              "hi": "अस्मिता",
              "ka": "ಅಸ್ಮಿತಾ",
              "te": "అస్మిత",
              "or": "ଅସ୍ମିତା",
              "as": "অস্মিতা",
              "gu": "અસ્મિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Vandana",
              "hi": "वंदना",
              "ka": "ವಂದನಾ",
              "te": "వందన",
              "or": "ବନ୍ଦନା",
              "as": "বন্দনা",
              "gu": "વંદના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "How did you cope with the changes?",
              "hi": "तुमने परिवर्तनों का सामना कैसे किया?",
              "ka": "ಬದಲಾವಣೆಗಳನ್ನು ನೀವು ಹೇಗೆ ನಿಭಾಯಿಸಿದ್ದೀರಿ?",
              "te": "మీరు మార్పులను ఎలా ఎదుర్కొన్నారు?",
              "or": "ଆପଣ ପରିବର୍ତ୍ତନଗୁଡିକ ସହିତ କିପରି ମୁକାବିଲା କଲେ?",
              "as": "আপুনি পৰিৱৰ্তনবোৰৰ সৈতে কেনেদৰে মোকাবিলা কৰিছিল?",
              "gu": "તે ફેરફારોનો  કેવી રીતે સામનો કર્યો?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "The endocrinologist handled the medical aspects but to keep my mental health in check, I made sure to be connected with a mental health specialist and a good support group. Having a routine apart from work also helped me cope with the changes.",
              "hi": "एंडोक्रिनोलॉजिस्ट ने चिकित्सा पहलुओं को संभाला लेकिन अपने मानसिक स्वास्थ्य को नियंत्रण में रखने के लिए, मैंने एक मानसिक स्वास्थ्य विशेषज्ञ और एक अच्छे सहायता समूह के साथ जुड़ना सुनिश्चित किया। काम के अलावा एक रूटीन होने से भी मुझे बदलाव का सामना करने में सहायता मिली।",
              "ka": "ಅಂತಃಸ್ರಾವಶಾಸ್ತ್ರಜ್ಞರು ವೈದ್ಯಕೀಯ ಅಂಶಗಳನ್ನು ನಿರ್ವಹಿಸಿದ್ದಾರೆ ಆದರೆ ನನ್ನ ಮಾನಸಿಕ ಆರೋಗ್ಯವನ್ನು ನಿಯಂತ್ರಣದಲ್ಲಿಡಲು, ನಾನು ಮಾನಸಿಕ ಆರೋಗ್ಯ ತಜ್ಞರು ಮತ್ತು ಉತ್ತಮ ಬೆಂಬಲ ಗುಂಪಿನೊಂದಿಗೆ ಸಂಪರ್ಕ ಹೊಂದಲು ಖಚಿತಪಡಿಸಿಕೊಂಡಿದ್ದೇನೆ. ಕೆಲಸದ ಹೊರತಾಗಿ ದಿನಚರಿಯನ್ನು ಹೊಂದಿರುವುದು ಬದಲಾವಣೆಗಳನ್ನು ನಿಭಾಯಿಸಲು ನನಗೆ ಸಹಾಯ ಮಾಡಿತು.",
              "te": "ఎండోక్రైనాలజిస్ట్ వైద్యపరమైన అంశాలను హ్యాండిల్ చేశాడు కానీ నా మానసిక ఆరోగ్యాన్ని అదుపులో ఉంచడానికి, నేను మానసిక ఆరోగ్య నిపుణుడు మరియు మంచి సపోర్ట్ గ్రూప్‌తో కనెక్ట్ అయ్యేలా చూసుకున్నాను. పని కాకుండా వేరే రొటీన్ చేయడం కూడా మార్పులను ఎదుర్కోవడంలో నాకు సహాయపడింది.",
              "or": "ଏଣ୍ଡୋକ୍ରାଇନୋଲୋଜିଷ୍ଟ ଡାକ୍ତରୀ ଦିଗଗୁଡିକ ପରିଚାଳନା କରିଥିଲେ କିନ୍ତୁ ମୋର ମାନସିକ ସ୍ୟାସ୍ଥ୍ୟକୁ ନିୟନ୍ତ୍ରଣରେ ରଖିବା ପାଇଁ ମୁଁ ଏକ ମାନସିକ ସ୍ୟାସ୍ଥ୍ୟ ବିଶେଷଜ୍ଞ ଏବଂ ଏକ ଭଲ ସହାୟକ ଗୋଷ୍ଠୀ ସହିତ ସଂଯୁକ୍ତ ହେବା ନିଶ୍ଚିତ କରିଥିଲି | କାର୍ଯ୍ୟ ବ୍ୟତୀତ ଏକ ନିତ୍ୟ ବ୍ୟବହାର୍ଯ୍ୟ ରହିବା ମଧ୍ୟ ମୋତେ ପରିବର୍ତ୍ତନଗୁଡ଼ିକର ମୁକାବିଲା କରିବାରେ ସାହାଯ୍ୟ କଲା |",
              "as": "এণ্ডোক্ৰিনোলজিষ্টজনে চিকিৎসাৰ দিশবোৰ চম্ভালিছিল কিন্তু মোৰ মানসিক স্বাস্থ্য নিয়ন্ত্ৰণত ৰাখিবলৈ, মই এজন মানসিক স্বাস্থ্য বিশেষজ্ঞ আৰু এটা ভাল সমৰ্থন গোটৰ সৈতে সম্পৰ্কিত হোৱাটো নিশ্চিত কৰিছিলো। কামৰ বাহিৰেও এটা ৰুটিন থকাটোৱে মোক পৰিৱৰ্তনবোৰৰ সৈতে মোকাবিলা কৰাত সহায় কৰিছিল।",
              "gu": "એન્ડોક્રિનોલોજિસ્ટ તબીબી પાસાઓ સંભાળે છે પરંતુ મારા માનસિક સ્વાસ્થ્યને નિયંત્રણમાં રાખવા માટે, મેં માનસિક સ્વાસ્થ્ય નિષ્ણાત અને સારા સપોર્ટ ગ્રુપ સાથે જોડાયેલ હોવાનું સુનિશ્ચિત કર્યું.  કામ સિવાયની દિનચર્યા રાખવાથી પણ મને ફેરફારોનો સામનો કરવામાં મદદ મળી."
            }
          }
        ],
        "page21": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_1_9",
              "hi": "D3_1_9",
              "ka": "D3_1_9",
              "te": "D3_1_9",
              "or": "D3_1_9",
              "as": "D3_1_9",
              "gu": "D3_1_9"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita feels confident and nervous at the same time, but she is sure that she wants to live as Asmita from her 25th Birthday onwards.",
              "hi": "अस्मिता एक ही समय में आत्मविश्वास और घबराहट अनुभव करती है, लेकिन उसे यकीन है कि वह अपने 25 वें जन्मदिन से अस्मिता के रूप में रहना चाहती है।",
              "ka": "ಅಸ್ಮಿತಾ ಅದೇ ಸಮಯದಲ್ಲಿ ಆತ್ಮವಿಶ್ವಾಸ ಮತ್ತು ಆತಂಕವನ್ನು ಅನುಭವಿಸುತ್ತಾಳೆ, ಆದರೆ ಅವಳು ತನ್ನ 25 ನೇ ಹುಟ್ಟುಹಬ್ಬದಿಂದ ಅಸ್ಮಿತಾ ಆಗಿ ಬದುಕಲು ಬಯಸುತ್ತಾಳೆ ಎಂದು ಅವಳು ಖಚಿತವಾಗಿ ಹೇಳುತ್ತಾಳೆ.",
              "te": "అస్మిత ఒకే సమయంలో ఆత్మవిశ్వాసంతో ఉంటుంది మరియు భయాందోళనలకు గురవుతుంది, కానీ ఆమె తన 25వ పుట్టినరోజు నుండి అస్మితగా జీవించాలనుకుంటున్నట్లు ఆమె నిర్ణయించుకుంది.",
              "or": "ଅସ୍ମିତା ଏକ ସମୟରେ ଆତ୍ମବିଶ୍ୱାସୀ ଏବଂ ନର୍ଭସ ଅନୁଭବ କରନ୍ତି, କିନ୍ତୁ ସେ ନିଶ୍ଚିତ ଯେ ସେ 25 ତମ ଜନ୍ମଦିନରୁ ଅସ୍ମିତା ଭାବରେ ବଞ୍ଚିବାକୁ ଚାହାଁନ୍ତି |",
              "as": "অস্মিতাই একে সময়তে আত্মবিশ্বাসী আৰু উদ্বিগ্ন অনুভৱ কৰে, কিন্তু তেওঁ নিশ্চিত যে তেওঁ তেওঁৰ ২৫তম জন্মদিনৰ পৰা অস্মিতা হিচাপে জীয়াই থাকিব বিচাৰে।",
              "gu": "અસ્મિતા એક જ સમયે આત્મવિશ્વાસ અને નર્વસનેસનો અનુભવ કરે છે, પરંતુ તેણીને ખાતરી છે કે તેણી તેના 25 માં જન્મદિવસથી  પોતે અસ્મિતા તરીકે જીવવા માંગે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_2_1",
              "hi": "D3_2_1",
              "ka": "D3_2_1",
              "te": "D3_2_1",
              "or": "D3_2_1",
              "as": "D3_2_1",
              "gu": "D3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She decides to talk to Roshini first. When she comes to visit, Asmita asks her to sit down.",
              "hi": "वह पहले रोशनी से बात करने का निश्चय करती है। जब वह मिलने आती है तो अस्मिता उसे बैठने के लिए कहती है।",
              "ka": "ಅವಳು ಮೊದಲು ರೋಶಿನಿಯೊಂದಿಗೆ ಮಾತನಾಡಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ. ಅವಳು ಭೇಟಿ ಮಾಡಲು ಬಂದಾಗ, ಅಸ್ಮಿತಾ ಅವಳನ್ನು ಕುಳಿತುಕೊಳ್ಳಲು ಕೇಳುತ್ತಾಳೆ.",
              "te": "ముందుగా రోష్ని తో మాట్లాడాలని నిర్ణయించుకుంది. ఆమె కలవడానికి వచ్చినప్పుడు, అస్మిత ఆమెను కూర్చోమని అడుగుతుంది.",
              "or": "ସେ ପ୍ରଥମେ ରୋଶିନିଙ୍କ ସହ କଥା ହେବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ଯେତେବେଳେ ସେ ଦେଖା କରିବାକୁ ଆସନ୍ତି, ଅସ୍ମିତା ତାଙ୍କୁ ବସିବାକୁ କୁହନ୍ତି |",
              "as": "তাই প্ৰথমে ৰোশনিৰ সৈতে কথা পাতিবলৈ সিদ্ধান্ত লয়। যেতিয়া তাই লগ কৰিবলৈ আহে, অস্মিতাই তাইক বহিবলৈ কয়।",
              "gu": "તેણીએ પહેલા રોશિની સાથે વાત કરવાનું નક્કી કર્યું.  જ્યારે તે મળવા આવે છે ત્યારે અસ્મિતા તેને બેસવાનું કહ્યું."
            }
          }
        ],
        "page22": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_2_2////",
              "hi": "D3_2_2 ////",
              "ka": "D3_2_2////",
              "te": "D3_2_2////",
              "or": "D3_2_2////",
              "as": "D3_2_2////",
              "gu": "D3_2_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Roshni, I have been thinking about this for a while. I have decided that I am a woman and that my name from now on will be Asmita, with ‘she/her’ pronouns.",
              "hi": "रोशनी, मैं इस विषय में कुछ दिनों से सोच रही थी। मैंने तय किया है कि मैं एक महिला हूं और अब से मेरा नाम अस्मिता होगा, जिसमें 'वह/उसकी' सर्वनाम होगा।",
              "ka": "ರೋಶನಿ, ನಾನು ಸ್ವಲ್ಪ ಸಮಯದಿಂದ ಈ ಬಗ್ಗೆ ಯೋಚಿಸುತ್ತಿದ್ದೇನೆ. ನಾನೊಬ್ಬ ಮಹಿಳೆ ಮತ್ತು ಇನ್ನು ಮುಂದೆ ನನ್ನ ಹೆಸರು ‘ಅವಳು/ಅವಳ’ ಸರ್ವನಾಮಗಳೊಂದಿಗೆ ಅಸ್ಮಿತಾ ಎಂದು ನಾನು ನಿರ್ಧರಿಸಿದೆ.",
              "te": "రోష్ని, నేను దీని గురించి కొంతకాలంగా ఆలోచిస్తున్నాను. నేను స్త్రీని, ఇక నుండి నా పేరు అస్మిత అని, ‘ఆమె/తను’ సర్వనామాలతో ఉండాలని నిర్ణయించుకున్నాను.",
              "or": "ରୋଶନି, ମୁଁ କିଛି ସମୟ ପାଇଁ ଏ ବିଷୟରେ ଚିନ୍ତା କରୁଛି | ମୁଁ ସ୍ଥିର କଲି ଯେ ମୁଁ ଜଣେ ମହିଳା ଏବଂ ବର୍ତ୍ତମାନଠାରୁ ମୋର ନାମ ‘ସେ / ତା’ର’ ଉଚ୍ଚାରଣ ସହିତ ଅସମିତା ହେବ |",
              "as": "ৰোশনি, মই কিছু সময়ৰ পৰা এই বিষয়ে চিন্তা কৰি আছো। মই সিদ্ধান্ত লৈছো যে মই এগৰাকী মহিলা আৰু এতিয়াৰ পৰা 'তাই/তাইৰ' সৰ্বনামৰ সৈতে মোৰ নাম হ'ব অস্মিতা।",
              "gu": "રોશની, હું થોડા સમયથી આ વિશે વિચારી રહ્યો છું.  મેં નક્કી કર્યું છે કે હું એક સ્ત્રી છું અને હવેથી મારું નામ 'તે/તેણીનાં' સર્વનામ સાથે અસ્મિતા રહેશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I am so happy for you, Arvin.. I mean Asmita!... sorry, I’m too used to calling you Arvind. Who all have you spoken to about this?",
              "hi": "मैं तुम्हारे लिए बहुत खुश हूं, अरविन! .. मेरा मतलब अस्मिता! ... क्षमा करो, मैं तुमको अरविंद कहने की इतनी आदी हो गई हूँ। तुमने इस बारे में किससे बात की है?",
              "ka": "ನಿನಗಾಗಿ ನನಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿದೆ, ಅರವಿಂದ್.. ಅಂದರೆ ಅಸ್ಮಿತಾ!... ಕ್ಷಮಿಸಿ, ನಾನು ನಿನ್ನನ್ನು ಅರವಿಂದ್ ಎಂದು ಕರೆಯುವುದು ತುಂಬಾ ಅಭ್ಯಾಸವಾಗಿದೆ. ಇದರ ಬಗ್ಗೆ ನೀವು ಯಾರೊಂದಿಗೆ ಮಾತನಾಡಿದ್ದೀರಿ?",
              "te": "నాకు చాలా సంతోషంగా ఉంది, అర్విన్.. సారీ, అస్మితా!...నేను నిన్ను అరవింద్ అని పిలవడం చాలా అలవాటు అయింది. దీని గురించి నువ్వు ఎవరితో మాట్లాడావు?",
              "or": "ମୁଁ ତୁମ ପାଇଁ ବହୁତ ଖୁସି, ଅରବିନ୍ .. ମୋର ଅର୍ଥ ଅସ୍ମିତା! ... ଦୁଖିତ, ମୁଁ ତୁମକୁ ଅରବିନ୍ଦ ବୋଲି ଡାକିବାରେ ଅଭ୍ୟସ୍ତ | ଆପଣ ଏ ବିଷୟରେ କାହା ସହିତ କଥା ହୋଇଛନ୍ତି?",
              "as": "মই আপোনাৰ বাবে বহুত সুখী, অৰবিন্দ.. মানে অস্মিতা!... দুঃখিত, মই আপোনাক অৰবিন্দ বুলি মাতাতেই বহুত অভ্যস্ত। আপুনি এই বিষয়ে কাৰ সৈতে কথা পাতিছে?",
              "gu": "હું   ખૂબ જ ખુશ છું, અરવિન.. આઈ મીન અસ્મિતા!... માફ કરજે, હું પણ તને અરવિંદ કહેવાની આદત છે.  તે આ વિશે કોની સાથે વાત કરી છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "I have been talking to Vandana for a while now, and she has been helping me through this.",
              "hi": "मैं पिछले कुछ समय से वंदना से बात कर रहा हूं और वह इसमें मेरी सहायता कर रही हैं।",
              "ka": "ನಾನು ವಂದನಾ ಅವರೊಂದಿಗೆ ಸ್ವಲ್ಪ ಸಮಯದಿಂದ ಮಾತನಾಡುತ್ತಿದ್ದೇನೆ ಮತ್ತು ಅವಳು ನನಗೆ ಸಹಾಯ ಮಾಡುತ್ತಿದ್ದಾಳೆ.",
              "te": "నేను వందనతో చాలా కాలంగా మాట్లాడుతున్నాను మరియు ఆమె నాకు సహాయం చేస్తోంది.",
              "or": "ମୁଁ କିଛି ସମୟ ପାଇଁ ଭଣ୍ଡାନା ସହିତ କଥାବାର୍ତ୍ତା କରୁଛି, ଏବଂ ସେ ମୋତେ ଏହା ମାଧ୍ୟମରେ ସାହାଯ୍ୟ କରୁଛନ୍ତି |",
              "as": "মই কিছু সময়ৰ পৰা বন্দনাৰ সৈতে কথা পাতি আছোঁ, আৰু তাই এই কামবোৰত মোক সহায় কৰি আহিছে।",
              "gu": "હું થોડા સમયથી વંદના સાથે વાત કરી રહી છું, અને તેણી આમાં મને મદદ કરી રહી છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Okay, so how do you want to go about this? We have to tell Amma and Appa, even Pranav. There’s so much to do, but don’t worry, I’ll be with you every step of the way.",
              "hi": "मुझे खुशी है। लेकिन तुम इसे अम्मा, अप्पा और प्रणव के साथ कब साझा करने की योजना बना रहे हो? करने के लिए शायद बहुत कुछ है, लेकिन चिंता मत करो, मैं हर कदम पर तुम्हारे साथ रहूंगी।",
              "ka": "ಸರಿ, ನೀವು ಇದನ್ನು ಹೇಗೆ ಮಾಡಲು ಬಯಸುತ್ತೀರಿ? ಅಮ್ಮ ಅಪ್ಪನಿಗೆ ಹೇಳಬೇಕು, ಪ್ರಣವ್ ಕೂಡ. ಮಾಡಲು ತುಂಬಾ ಇದೆ, ಆದರೆ ಚಿಂತಿಸಬೇಡಿ, ಪ್ರತಿ ಹಂತದಲ್ಲೂ ನಾನು ನಿಮ್ಮೊಂದಿಗೆ ಇರುತ್ತೇನೆ",
              "te": "సరే, దీనిని ఎలా చేయాలి అనుకుంటున్నావు? మనం అమ్మా, నాన్న, ప్రణవ్ లకు చెప్పాలి. దీని కోసం చాలా చేయాల్సి ఉంది, కానీ చింతించకు, నేను అడుగడుగునా నీతో ఉంటాను.",
              "or": "ଠିକ ଅଛି, ତେବେ ଆପଣ ଏ ବିଷୟରେ କିପରି ଯିବାକୁ ଚାହାଁନ୍ତି? ଆମକୁ ଆମ୍ମା ଏବଂ ଆପାଙ୍କୁ କହିବାକୁ ପଡିବ, ଏପରିକି ପ୍ରଣବ | ବହୁତ କିଛି କରିବାକୁ ଅଛି, କିନ୍ତୁ ବ୍ୟସ୍ତ ହୁଅନ୍ତୁ ନାହିଁ, ମୁଁ ପ୍ରତ୍ୟେକ ପଥରେ ଆପଣଙ୍କ ସହିତ ରହିବି |",
              "as": "ঠিক আছে, তেন্তে আপুনি এই বিষয়ে কেনেকৈ যাব বিচাৰে? আমি আম্মা আৰু আপ্পাক ক'ব লাগিব, আনকি প্ৰণৱকো। বহুত কৰিব লগা আছে, কিন্তু চিন্তা নকৰিব, মই প্ৰতিটো পদক্ষেপতে আপোনাৰ সৈতে থাকিম।",
              "gu": "ઠીક છે, તો તૂ આ વિશે કેવી રીતે આગળ વધવા માંગે છે?  આપણે મમ્મી અને પપ્પાને કહેવું પડશે, પ્રણવને પણ.  કરવા માટે ઘણું બધું છે, પરંતુ ચિંતા કરશ નહીં, હું દરેક પગલે તારી સાથે રહીશ."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_3_1",
              "hi": "D3_3_1",
              "ka": "D3_3_1",
              "te": "D3_3_1",
              "or": "D3_3_1",
              "as": "D3_3_1",
              "gu": "D3_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Roshni and Asmita go to their parents and Pranav to talk about Asmita’s transition.",
              "hi": "रोशनी और अस्मिता, अस्मिता के ट्रांज़िशन/ बदलाव के बारे में बात करने के लिए अपने माता-पिता और प्रणव के पास जाते हैं।",
              "ka": "ರೋಶ್ನಿ ಮತ್ತು ಅಸ್ಮಿತಾ ಅವರ ಪೋಷಕರು ಮತ್ತು ಪ್ರಣವ್ ಬಳಿ ಅಸ್ಮಿತಾ ಪರಿವರ್ತನೆಯ ಬಗ್ಗೆ ಮಾತನಾಡಲು ಹೋಗುತ್ತಾರೆ.",
              "te": "రోష్ని మరియు అస్మిత వారి తల్లిదండ్రులు మరియు ప్రణవ్ వద్దకు వెళ్లి అస్మిత పరివర్తన గురించి మాట్లాడతారు.",
              "or": "ରୋଶନୀ ଏବଂ ଅସ୍ମିତା ସେମାନଙ୍କ ପିତାମାତା ଏବଂ ପ୍ରଣବଙ୍କ ନିକଟକୁ ଯାଇ ଅସ୍ମିତାର ପରିବର୍ତ୍ତନ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି |",
              "as": "ৰোশনি আৰু অস্মিতাই তেওঁলোকৰ মাক দেউতাক আৰু প্ৰণৱৰ ওচৰলৈ অস্মিতাৰ পৰিৱৰ্তনৰ বিষয়ে কথা পাতিবলৈ যায়।",
              "gu": "રોશની અને અસ્મિતા પોતાના માતા-પિતા અને પ્રણવ પાસે અસ્મિતાના ટ્રાન્સિશન વિશે વાત કરવા જાય છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_3_2",
              "hi": "D3_3_2",
              "ka": "D3_3_2",
              "te": "D3_3_2",
              "or": "D3_3_2",
              "as": "D3_3_2",
              "gu": "D3_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They are at a loss for words. Her father is worried about what people might say, while her mother is worried about her future, especially because she does not know what it means for Asmita to live as a trans person.",
              "hi": "उनके पास शब्द कम पड़ गए हैं। उसके पिता इस बात से चिंतित हैं कि लोग क्या कह सकते हैं, जबकि उसकी माँ उसके भविष्य को लेकर चिंतित है, खासकर इसलिए कि वह नहीं जानती कि अस्मिता के ट्रांस व्यक्ति के रूप में रहने का क्या मतलब है।",
              "ka": "ಅವರು ಮಾತಿಗೆ ಸೋತಿದ್ದಾರೆ. ಅಸ್ಮಿತಾ ಟ್ರಾನ್ಸ್ ವ್ಯಕ್ತಿಯಾಗಿ ಬದುಕುವುದರ ಅರ್ಥವೇನೆಂದು ತಿಳಿದಿಲ್ಲದ ಕಾರಣ, ಆಕೆಯ ತಾಯಿ ತನ್ನ ಭವಿಷ್ಯದ ಬಗ್ಗೆ ಚಿಂತಿತರಾಗಿರುವಾಗ ಆಕೆಯ ತಂದೆ ಜನರು ಏನು ಹೇಳಬಹುದು ಎಂದು ಚಿಂತಿತರಾಗಿದ್ದಾರೆ.",
              "te": "వారికి మాటలు రావు. అస్మిత ట్రాన్స్ పర్సన్‌గా జీవించడం అంటే ఎలా ఉంటుందో తెలియక ఆమె తల్లి తన భవిష్యత్తు గురించి ఆందోళన చెందుతుండగా, ఆమె తండ్రి అందరూ ఏమి అంటారో అని ఆందోళన చెందుతున్నారు.",
              "or": "ସେମାନେ ଶବ୍ଦ ପାଇଁ କ୍ଷତିଗ୍ରସ୍ତ | ଲୋକମାନେ ଯାହା କହିପାରନ୍ତି ସେଥିପାଇଁ ତାଙ୍କ ପିତା ଚିନ୍ତିତ ଥିବାବେଳେ ତାଙ୍କ ମା ତାଙ୍କ ଭବିଷ୍ୟତ ପାଇଁ ଚିନ୍ତିତ ଅଛନ୍ତି, ବିଶେଷ କରି ଅସ୍ମିତାଙ୍କ ପାଇଁ ଜଣେ ଟ୍ରାନ୍ସ ବ୍ୟକ୍ତି ଭାବରେ ବଞ୍ଚିବାର ଅର୍ଥ ସେ ଜାଣି ନାହାଁନ୍ତି |",
              "as": "তেওঁলোকে কি কব ভাবি পোৱা নাই। তাইৰ দেউতাকে মানুহে কি ক'ব পাৰে তাক লৈ চিন্তিত, আনহাতে তাইৰ মাক তাইৰ ভৱিষ্যতক লৈ চিন্তিত, বিশেষকৈ তেওঁ নাজানে যে অস্মিতাৰ বাবে এজন ট্ৰেন্স ব্যক্তি হিচাপে জীয়াই থকাৰ অৰ্থ কি।",
              "gu": "તેઓ પાસે બોલવા માટે શબ્દ નથી.  તેના પિતા ચિંતિત છે કે લોકો શું કહેશે, જ્યારે તેની માતા તેના ભવિષ્ય વિશે ચિંતિત છે, ખાસ કરીને કારણ કે તે જાણતી નથી કે અસ્મિતા માટે ટ્રાન્સ વ્યક્તિ તરીકે જીવવાનો અર્થ શું છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_3_3",
              "hi": "D3_3_3",
              "ka": "D3_3_3",
              "te": "D3_3_3",
              "or": "D3_3_3",
              "as": "D3_3_3",
              "gu": "D3_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is worried about this reaction, and she doesn’t know how she will handle her parents and their concerns. Nobody talks in the house for two days.",
              "hi": "अस्मिता इस प्रतिक्रिया के बारे में चिंतित है, और वह नहीं जानती कि वह अपने माता-पिता और उनकी चिंताओं को कैसे संभालेगी। दो दिनों के लिए घर में कोई बात नहीं करता है।",
              "ka": "ಈ ಪ್ರತಿಕ್ರಿಯೆಯ ಬಗ್ಗೆ ಅಸ್ಮಿತಾ ಚಿಂತಿತರಾಗಿದ್ದಾರೆ ಮತ್ತು ಆಕೆಯ ಪೋಷಕರು ಮತ್ತು ಅವರ ಕಾಳಜಿಯನ್ನು ಹೇಗೆ ನಿಭಾಯಿಸುತ್ತಾರೆ ಎಂದು ತಿಳಿದಿಲ್ಲ. ಎರಡು ದಿನ ಮನೆಯಲ್ಲಿ ಯಾರೂ ಮಾತನಾಡುವುದಿಲ್ಲ",
              "te": "అస్మిత ఈ రియాక్షన్ చూసి ఆందోళన చెందుతోంది మరియు తన తల్లిదండ్రులను మరియు వారి ఆందోళనలను ఎలా హ్యాండిల్ చేయాలో ఆమెకు తెలియట్లేదు. రెండు రోజులు ఇంట్లో ఎవరూ మాట్లాడరు.",
              "or": "ଅସ୍ମିତା ଏହି ପ୍ରତିକ୍ରିୟା ପାଇଁ ଚିନ୍ତିତ, ଏବଂ ସେ ଜାଣନ୍ତି ନାହିଁ ଯେ ସେ କିପରି ତାଙ୍କ ପିତାମାତା ଏବଂ ସେମାନଙ୍କର ଚିନ୍ତାଧାରାକୁ ପରିଚାଳନା କରିବେ | ଦୁଇ ଦିନ ଧରି ଘରେ କେହି କଥାବାର୍ତ୍ତା କରନ୍ତି ନାହିଁ |",
              "as": "অস্মিতা এই প্ৰতিক্ৰিয়াৰ বিষয়ে চিন্তিত, আৰু তাই নাজানে যে তাই তাইৰ মাক-দেউতাক আৰু তেওঁলোকৰ উদ্বেগবোৰ কেনেদৰে নিয়ন্ত্ৰণ কৰিব। ঘৰত দুদিন ধৰি কোনেও কথা নকয়।",
              "gu": "અસ્મિતા આ પ્રતિક્રિયાથી ચિંતિત છે, અને તેણીને ખબર નથી કે તે તેના માતાપિતા અને તેમની ચિંતાઓને કેવી રીતે સંભાળશે.  બે દિવસ ઘરમાં કોઈ વાત કરતું નથી."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_4_1",
              "hi": "D3_4_1",
              "ka": "D3_4_1",
              "te": "D3_4_1",
              "or": "D3_4_1",
              "as": "D3_4_1",
              "gu": "D3_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After two days, while everyone is sitting in the hall, Asmita is making tea in the kitchen. Pranav comes over and talks to Asmita.",
              "hi": "दो दिन बाद जब सब हॉल में बैठे हैं, अस्मिता किचन में चाय बना रही है। प्रणव आता है और अस्मिता से बात करता है।",
              "ka": "ಎರಡು ದಿನಗಳ ನಂತರ ಎಲ್ಲರೂ ಹಾಲ್ ನಲ್ಲಿ ಕುಳಿತಿರುವಾಗ ಅಸ್ಮಿತಾ ಅಡುಗೆ ಮನೆಯಲ್ಲಿ ಟೀ ಮಾಡುತ್ತಿದ್ದಳು. ಪ್ರಣವ್ ಬಂದು ಅಸ್ಮಿತಾಳೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾನೆ.",
              "te": "రెండు రోజుల తర్వాత అందరూ హాల్లో కూర్చుని ఉండగా అస్మిత కిచెన్‌లో టీ చేస్తోంది. ప్రణవ్ అస్మిత దగ్గరకు వచ్చి మాట్లాడతాడు.",
              "or": "ଦୁଇ ଦିନ ପରେ ସମସ୍ତେ ହଲରେ ବସିଥିବାବେଳେ ଅସମିତା ରୋଷେଇ ଘରେ ଚା ତିଆରି କରୁଛନ୍ତି | ପ୍ରଣବ ଆସି ଆସମିତାଙ୍କ ସହ କଥାବାର୍ତ୍ତା କରନ୍ତି |",
              "as": "দুদিনৰ পিছত, সকলোৱে হলত বহি থাকোঁতে, অস্মিতাই পাকঘৰত চাহ বনাই আছে। প্ৰণৱ আহি অস্মিতাৰ সৈতে কথা পাতিলে।",
              "gu": "બે દિવસ પછી બધા હોલમાં બેઠા હતા ત્યારે અસ્મિતા રસોડામાં ચા બનાવી રહી હતી.  પ્રણવ આવ્યો અને અસ્મિતા સાથે વાત કરી."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_4_2//",
              "hi": "D3_4_2 //",
              "ka": "D3_4_2//",
              "te": "D3_4_2//",
              "or": "D3_4_2//",
              "as": "D3_4_2//",
              "gu": "D3_4_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Pranav",
              "hi": "प्रणव",
              "ka": "ಪ್ರಣವ್",
              "te": "ప్రణవ్",
              "or": "ପ୍ରଣବ",
              "as": "প্ৰণৱ",
              "gu": "પ્રણવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I have found it very hard to accept you as Arvind. I am sorry about how I have behaved with you, and I will try my best to accept and support you. I’m happy that you made this transition.",
              "hi": "मुझे तुमको अरविंद के रूप में स्वीकार करना बहुत कठिन लग रहा है। मुझे खेद है कि मैंने तुम्हारे साथ कैसा व्यवहार किया है, और मैं तुमको स्वीकार करने और समर्थन करने की पूरी कोशिश करूंगा। मुझे खुशी है कि तुमने यह ट्रांज़िशन/ बदलाव किया है।",
              "ka": "ನಿನ್ನನ್ನು ಅರವಿಂದ ಎಂದು ಒಪ್ಪಿಕೊಳ್ಳಲು ನನಗೆ ತುಂಬಾ ಕಷ್ಟವಾಯಿತು. ನಾನು ನಿಮ್ಮೊಂದಿಗೆ ಹೇಗೆ ವರ್ತಿಸಿದೆ ಎಂಬುದರ ಬಗ್ಗೆ ನನಗೆ ವಿಷಾದವಿದೆ ಮತ್ತು ನಿಮ್ಮನ್ನು ಸ್ವೀಕರಿಸಲು ಮತ್ತು ಬೆಂಬಲಿಸಲು ನಾನು ನನ್ನ ಅತ್ಯುತ್ತಮ ಪ್ರಯತ್ನ ಮಾಡುತ್ತೇನೆ. ನೀವು ಈ ಪರಿವರ್ತನೆ ಮಾಡಿದ್ದೀರಿ ಎಂದು ನನಗೆ ಸಂತೋಷವಾಗಿದೆ.",
              "te": "నిన్ను అరవింద్‌గా అంగీకరించడం నాకు చాలా కష్టంగా అనిపించింది. నేను నీతో అలా ప్రవర్తించినందుకు చింతిస్తున్నాను మరియు నిన్ను అంగీకరించడానికి మరియు మద్దతు ఇవ్వడానికి నేను నా వంతు ప్రయత్నం చేస్తాను. నువ్వు ఈ పరివర్తన చేసినందుకు నేను సంతోషిస్తున్నాను.",
              "or": "ତୁମକୁ ଅରବିନ୍ଦ ଭାବରେ ଗ୍ରହଣ କରିବା ମୋତେ ବହୁତ କଷ୍ଟକର | ମୁଁ ତୁମ ସହିତ କିପରି ବ୍ୟବହାର କରିଛି ସେଥିପାଇଁ ମୁଁ ଦୁଖିତ, ଏବଂ ତୁମକୁ ଗ୍ରହଣ ଏବଂ ସମର୍ଥନ କରିବାକୁ ମୁଁ ଚେଷ୍ଟା କରିବି | ମୁଁ ଖୁସି ଯେ ତୁମେ ଏହି ପରିବର୍ତ୍ତନ କରିଛ |",
              "as": "মই তোমাক অৰবিন্দ হিচাপে মানি লবলৈ বহুত টান পাইছো। মই তোমাৰ সৈতে যেনেধৰণৰ আচৰণ কৰিছো তাৰ বাবে মই দুঃখিত, আৰু মই তোমাক অস্মিতা হিচাপে মানি লবলৈ যথাসাধ্য চেষ্টা কৰিম। মই সুখী যে তুমি এই পৰিৱৰ্তনটো কৰিছা।",
              "gu": "તને અરવિંદ તરીકે સ્વીકારવો મને બહુ મુશ્કેલ લાગ્યું હતું.  મેં તારી સાથે જે વર્તન કર્યું તે બદલ હું દિલગીર છું, અને હું તને સ્વીકારવા અને ટેકો આપવા માટે મારા તરફથી શ્રેષ્ઠ પ્રયાસ કરીશ.  હું ખુશ છું કે તે આ ટ્રાન્સિશન કર્યું છે."
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_5_1////",
              "hi": "D3_5_1 /////",
              "ka": "D3_5_1////",
              "te": "D3_5_1////",
              "or": "D3_5_1////",
              "as": "D3_5_1////",
              "gu": "D3_5_1////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Her parents and Roshni overhear the conversation and walk into the kitchen. They smile at Asmita and hug her.",
              "hi": "उसके माता-पिता और रोशनी बातचीत को सुनते हैं और रसोई में चले जाते हैं। वे अस्मिता के लिए मुस्कराते हैं और उसे गले लगाते हैं।",
              "ka": "ಆಕೆಯ ಪೋಷಕರು ಮತ್ತು ರೋಶ್ನಿ ಸಂಭಾಷಣೆಯನ್ನು ಕೇಳಿದರು ಮತ್ತು ಅಡುಗೆಮನೆಗೆ ನಡೆದರು. ಅವರು ಅಸ್ಮಿತಾ ಅವರನ್ನು ನೋಡಿ ಮುಗುಳ್ನಕ್ಕು ಅವಳನ್ನು ತಬ್ಬಿಕೊಂಡರು.",
              "te": "ఆమె తల్లిదండ్రులు మరియు రోష్ని వారి మాటలు విని వంటగదిలోకి వెళ్ళారు. వాళ్ళు అస్మితను చూసి నవ్వి కౌగిలించుకున్నారు.",
              "or": "ତାଙ୍କ ପିତାମାତା ଏବଂ ରୋଶନି ବାର୍ତ୍ତାଳାପ ଶୁଣି ରୋଷେଇ ଘରକୁ ଯାଆନ୍ତି | ସେମାନେ ଅସ୍ମିତାଙ୍କୁ ହସନ୍ତି ଏବଂ ତାଙ୍କୁ ଆଲିଙ୍ଗନ କରନ୍ତି |",
              "as": "তাইৰ মাক দেউতাক আৰু ৰোশনিয়ে কথাখিনি শুনি পাকঘৰলৈ আহিছিল। তেওঁলোকে অস্মিতাৰ ফালে চাই হাঁহিছিল আৰু তাইক সাৱটি ধৰিছিল।",
              "gu": "તેના માતા-પિતા અને રોશની વાતચીત સાંભળીને રસોડામાં જાય છે.  તેઓ અસ્મિતા તરફ સ્મિત કરે છે અને તેને ગળે લગાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "We are proud of you, Asmita. I wasn't sure how to respond, mostly because I am concerned for you. I don't want you to be hurt, that's all.",
              "hi": "हमें तुम पर गर्व है अस्मिता। मुझे समझ नहीं आ रहा था कि मैं कैसे प्रतिक्रिया दूं, ज्यादातर इसलिए कि मैं तुम्हारे लिए चिंतित हूं। मैं नहीं चाहता कि तुमको चोट लगे, बस।",
              "ka": "ಅಸ್ಮಿತಾ, ನಿನ್ನ ಬಗ್ಗೆ ನಮಗೆ ಹೆಮ್ಮೆ ಇದೆ. ನಾನು ನಿನ್ನ ಬಗ್ಗೆ ಕಾಳಜಿ ವಹಿಸುತ್ತಿರುವ ಕಾರಣ, ಹೇಗೆ ಪ್ರತಿಕ್ರಿಯಿಸಬೇಕು ಎಂದು ನನಗೆ ಖಾತ್ರಿಯಾಗಲಿಲ್ಲ. ನಿನಗೆ ನೋವಾಗುವುದು ನನಗೆ ಇಷ್ಟವಿಲ್ಲ, ಅಷ್ಟೇ.",
              "te": "అస్మితా, నీ గురించి మేము గర్విస్తున్నాము. నేను నీ గురించి ఆందోళన చెందుతున్నందున, ఏం మాట్లాడాలో నాకు అర్థం కాలేదు. నువ్వు బాధపడటం నాకు ఇష్టం లేదు, అంతే.",
              "or": "ଅସ୍ମିତା, ଆମେ ତୁମ ପାଇଁ ଗର୍ବିତ | ମୁଁ କିପରି ପ୍ରତିକ୍ରିୟା କରିବି ନିଶ୍ଚିତ ନଥିଲି, ମୁଖ୍ୟତ୍ୟ ମୁଁ ଆପଣଙ୍କ ପାଇଁ ଚିନ୍ତିତ | ମୁଁ ଚାହେଁ ନାହିଁ ତୁମେ ଆଘାତ ପାଇବ, ସେତିକି |",
              "as": "আমি তোমাক লৈ গৌৰৱান্বিত, অস্মিতা। মই কেনেদৰে সঁহাৰি দিব লাগে নিশ্চিত নাছিলো, কাৰণ বেছিভাগ সময় মই তোমাৰ বাবে চিন্তিত আছিলো। মই তুমি আঘাত পোৱাটো নিবিচাৰো, সেইটোৱেই কথা।",
              "gu": "અમને તારા પર ગર્વ છે, અસ્મિતા.  મને ખાતરી ન હતી કે કેવી રીતે પ્રતિસાદ આપું, મોટે ભાગે કારણ કે હું તારા માટે ચિંતિત હતી .  હું નથી ઈચ્છતી કે તને દુઃખ થાય, બસ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, we will all figure together how to deal with what people say. But as long as you can live a life of happiness and self-respect, we will be satisfied.",
              "hi": "हां, हम सब मिलकर यह तय करेंगे कि लोग जो कहते हैं उसका कैसे सामना किया जाए। लेकिन जब तक तुम खुश हो और स्वाभिमान का जीवन जी सकती हो, हम संतुष्ट रहेंगे।",
              "ka": "ಹೌದು, ಜನರು ಹೇಳುವುದನ್ನು ಹೇಗೆ ಎದುರಿಸಬೇಕೆಂದು ನಾವೆಲ್ಲರೂ ಒಟ್ಟಾಗಿ ಲೆಕ್ಕಾಚಾರ ಮಾಡುತ್ತೇವೆ. ಆದರೆ ನೀವು ಎಲ್ಲಿಯವರೆಗೆ ಸಂತೋಷ ಮತ್ತು ಸ್ವಾಭಿಮಾನದ ಜೀವನವನ್ನು ನಡೆಸಬಹುದು, ನಾವು ತೃಪ್ತರಾಗುತ್ತೇವೆ.",
              "te": "అవును, అందరూ అనే మాటలను ఎలా ఎదుర్కోవాలో మనమందరం కలిసి తెలుసుకుందాం. కానీ నువ్వు సంతోషంగా మరియు ఆత్మగౌరవంతో జీవించగలిగినంత కాలం, మేము సంతృప్తి చెందుతాము.",
              "or": "ଏଣ୍ଡୋକ୍ରାଇନୋଲୋଜିଷ୍ଟ ଡାକ୍ତରୀ ଦିଗଗୁଡିକ ପରିଚାଳନା କରିଥିଲେ କିନ୍ତୁ ମୋର ମାନସିକ ସସ୍ଥ୍ୟକୁ ନିୟନ୍ତ୍ରଣରେ ରଖିବା ପାଇଁ ମୁଁ ଏକ ମାନସିକ ସ୍ୟାସ୍ଥ୍ୟ ବିଶେଷଜ୍ଞ ଏବଂ ଏକ ଭଲ ସହାୟକ ଗୋଷ୍ଠୀ ସହିତ ସଂଯୁକ୍ତ ହେବା ନିଶ୍ଚିତ କରିଥିଲି | କାର୍ଯ୍ୟ ବ୍ୟତୀତ ଏକ ନିତ୍ୟ ବ୍ୟବହାର୍ଯ୍ୟ ରହିବା ମଧ୍ୟ ମୋତେ ପରିବର୍ତ୍ତନଗୁଡ଼ିକର ମୁକାବିଲା କରିବାରେ ସାହାଯ୍ୟ କଲା |",
              "as": "হয়, মানুহে কোৱা কথাবোৰৰ সৈতে কেনেদৰে মোকাবিলা কৰিব লাগে আমি সকলোৱে একেলগে বুজি পাম। কিন্তু যেতিয়ালৈকে তুমি সুখ আৰু আত্মসন্মানেৰে জীৱন যাপন কৰিব পাৰা, আমি সন্তুষ্ট হৈ থাকিম।",
              "gu": "હા, લોકો જે કહે છે તેની પ્રતિ કેવો વ્યવહાર કરવો તે આપણે બધા મળીને વિચારીશું.  પણ જ્યાં સુધી તૂં સુખી અને સ્વાભિમાનનું જીવન જીવી શકશ ત્યાં સુધી અમે સંતુષ્ટ રહીશું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Appa, you don't have to be so dramatic.",
              "hi": "अप्पा, आपको इतना नाटकीय नहीं होना चाहिए।",
              "ka": "ಅಪ್ಪಾ, ನೀನು ನಾಟಕೀಯವಾಗಿ ವರ್ತಿಸಬೇಕಿಲ್ಲ",
              "te": "అప్పా, నువ్వు అంత నాటకీయంగా ఉండనవసరం లేదు.",
              "or": "ଆପ୍ପା, ତୁମକୁ ଏତେ ନାଟକୀୟ ହେବାକୁ ପଡିବ ନାହିଁ |",
              "as": "আপ্পা, আপুনি ইমান নাটকীয় হ'ব নালাগে।",
              "gu": "પપ્પા, તમારે આટલા બધા નાટકીય બનવાની જરૂર નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Hahaha",
              "hi": "हाहाहा",
              "ka": "ಹಹಹ",
              "te": "హహహ",
              "or": "ହାହାହା",
              "as": "হাঃহাঃহাঃ",
              "gu": "હાહાહા"
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "D3_5_2",
              "hi": "D3_5_2",
              "ka": "D3_5_2",
              "te": "D3_5_2",
              "or": "D3_5_2",
              "as": "D3_5_2",
              "gu": "D3_5_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is feeling a little more comfortable about her social transition. She has completed the first step of the transition.",
              "hi": "अस्मिता अपने सामाजिक परिवर्तन को लेकर कुछ अधिक सहज अनुभव कर रही है। उसने ट्रांज़िशन/ बदलाव का पहला चरण पूरा कर लिया है।",
              "ka": "ಅಸ್ಮಿತಾ ತನ್ನ ಸಾಮಾಜಿಕ ಪರಿವರ್ತನೆಯ ಬಗ್ಗೆ ಸ್ವಲ್ಪ ಹೆಚ್ಚು ಆರಾಮದಾಯಕವಾಗಿದ್ದಾಳೆ. ಅವಳು ಪರಿವರ್ತನೆಯ ಮೊದಲ ಹಂತವನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದಾಳೆ.",
              "te": "అస్మిత తన సామాజిక పరివర్తనతో సౌకర్యంగా ఉంది. ఆమె పరివర్తన యొక్క మొదటి దశను పూర్తి చేసింది.",
              "or": "ଅସମିତା ତାଙ୍କ ସାମାଜିକ ପରିବର୍ତ୍ତନ ବିଷୟରେ ଟିକିଏ ଅଧିକ ଆରାମଦାୟକ ଅନୁଭବ କରୁଛନ୍ତି | ସେ ପରିବର୍ତ୍ତନର ପ୍ରଥମ ସୋପାନ ସମାପ୍ତ କରିଛନ୍ତି |",
              "as": "অস্মিতাই তাইৰ সামাজিক পৰিৱৰ্তনৰ বিষয়ে অলপ সুখ অনুভৱ কৰিছে। তাই পৰিৱৰ্তনৰ প্ৰথম পদক্ষেপটো সম্পূৰ্ণ কৰিছে।",
              "gu": "અસ્મિતા તેના સામાજિક ટ્રાન્સિશન વિશે થોડી વધુ સારું અનુભવે છે.  તેણીએ સંક્રમણનું પ્રથમ પગલું પૂર્ણ કર્યું છે."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "UnderstandingSelf",
              "hi": "स्वयंकोसमझना",
              "ka": "ಅಂಡರ್ಸ್ಟ್ಯಾಂಡಿಂಗ್ ಸೆಲ್ಫ್",
              "te": "అండర్ స్టాండింగ్ సెల్ఫ్",
              "or": "UnderstandingSelf",
              "as": "নিজকে বুজা",
              "gu": "સ્વયંને સમજવું"
            }
          }
        ]
      },
      "scene30": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_1_1",
              "hi": "D4_1_1",
              "ka": "D4_1_1",
              "te": "D4_1_1",
              "or": "D4_1_1",
              "as": "D4_1_1",
              "gu": "D4_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita talks to the HR team in her office and informs them about her transition. She is nervous about how her friends at the office would respond.",
              "hi": "अस्मिता अपने कार्यालय में एचआर टीम से बात करती है और उन्हें अपने ट्रांज़िशन/ बदलाव के बारे में सूचित करती है। वह इस बारे में परेशान है कि कार्यालय में उसके मित्र कैसे प्रतिक्रिया देंगे।",
              "ka": "ಅಸ್ಮಿತಾ ತನ್ನ ಕಚೇರಿಯಲ್ಲಿ ಮಾನವ ಸಂಪನ್ಮೂಲ ತಂಡದೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾಳೆ ಮತ್ತು ತನ್ನ ಪರಿವರ್ತನೆಯ ಬಗ್ಗೆ ತಿಳಿಸುತ್ತಾಳೆ. ಆಫೀಸ್‌ನಲ್ಲಿರುವ ತನ್ನ ಸ್ನೇಹಿತರು ಹೇಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುತ್ತಾರೆ ಎಂದು ಅವಳು ಹೆದರುತ್ತಾಳೆ.",
              "te": "అస్మిత తన ఆఫీస్ లోని HR టీమ్‌తో మాట్లాడుతుంది మరియు ఆమె పరివర్తన గురించి వారికి తెలియజేస్తుంది. ఆఫీసులో తన స్నేహితులు ఎలా స్పందిస్తారోనని ఆమె కంగారుపడుతుంది.",
              "or": "ଅସ୍ମିତା ତାଙ୍କ କାର୍ଯ୍ୟାଳୟରେ HR ଦଳ ସହିତ କଥାବାର୍ତ୍ତା କରନ୍ତି ଏବଂ ତାଙ୍କ ପରିବର୍ତ୍ତନ ବିଷୟରେ ସୂଚନା ଦିଅନ୍ତି | ଅଫିସରେ ଥିବା ସାଙ୍ଗମାନେ କିପରି ପ୍ରତିକ୍ରିୟା କରିବେ ସେ ବିଷୟରେ ସେ ଚିନ୍ତିତ ଅଛନ୍ତି |",
              "as": "অস্মিতাই তাইৰ অফিচত HR দলৰ সৈতে কথা পাতিছিল আৰু তেওঁলোকক তাইৰ পৰিৱৰ্তনৰ বিষয়ে অৱগত কৰিছিল। অফিচত থকা তাইৰ বন্ধুবৰ্গই কেনেদৰে সঁহাৰি দিব তাক লৈ তাই উদ্বিগ্ন হৈ পৰিছে।",
              "gu": "અસ્મિતા તેની ઓફિસમાં HR ટીમ સાથે વાત કરે છે અને તેમને પોતાના ટ્રાન્સિશન વિશે જાણ કરે છે.  ઓફિસમાં તેના મિત્રો કેવો પ્રતિભાવ આપશે તે અંગે તેણી નર્વસ છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_1_2",
              "hi": "D4_1_2",
              "ka": "D4_1_2",
              "te": "D4_1_2",
              "or": "D4_1_2",
              "as": "D4_1_2",
              "gu": "D4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She goes to her office dressed as she usually does. She goes up to her HR and talks to her about her transition.",
              "hi": "वह हमेशा की तरह ड्रेसअप हो कर अपने ऑफिस जाती है। वह अपने एचआर के पास जाती है और उनसे अपने ट्रांज़िशन/ बदलाव के बारे में बात करती है।",
              "ka": "ಅವಳು ಎಂದಿನಂತೆ ಬಟ್ಟೆ ಧರಿಸಿ ತನ್ನ ಕಚೇರಿಗೆ ಹೋಗುತ್ತಾಳೆ. ಅವಳು ತನ್ನ HR ಗೆ ಹೋಗುತ್ತಾಳೆ ಮತ್ತು ಅವಳ ಪರಿವರ್ತನೆಯ ಬಗ್ಗೆ ಅವಳೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾಳೆ.",
              "te": "ఆమె మామూలుగా దుస్తులు వేసుకుని ఆఫీసుకు వెళుతుంది. ఆమె తన HR దగ్గరికి వెళ్లి తన పరివర్తన గురించి ఆమెతో మాట్లాడుతుంది.",
              "or": "ସେ ସାଧାରଣତ as ପରିଧାନ କରି ନିଜ ଅଫିସକୁ ଯାଆନ୍ତି | ସେ ତାଙ୍କ HR କୁ ଯାଇ ତାଙ୍କ ପରିବର୍ତ୍ତନ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି |",
              "as": "তাই সাধাৰণতে যেনেধৰণৰ পোছাক পিন্ধি তাইৰ অফিচলৈ যায় তেনেদৰেই গৈছে। তাই তাইৰ HR গৰাকীৰ ওচৰলৈ যায় আৰু তাইৰ পৰিৱৰ্তনৰ বিষয়ে তেওঁৰ সৈতে কথা পাতে।",
              "gu": "તે સામાન્ય રીતે પોશાક પહેરીને પોતાની ઓફિસ જાય છે.  તેણી તેના HR પાસે જાય છે અને તેણીના ટ્રાન્સિશન વિશે તેની સાથે વાત કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_1_3",
              "hi": "D4_1_3",
              "ka": "D4_1_3",
              "te": "D4_1_3",
              "or": "D4_1_3",
              "as": "D4_1_3",
              "gu": "D4_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The HR informs Asmita that she will email all the team members about her change in identity.",
              "hi": "एचआर अस्मिता को सूचित करता है कि वह उनकी पहचान में बदलाव के बारे में टीम के सभी सदस्यों को ईमेल करेगी।",
              "ka": "ತನ್ನ ಗುರುತಿನ ಬದಲಾವಣೆಯ ಬಗ್ಗೆ ಎಲ್ಲಾ ತಂಡದ ಸದಸ್ಯರಿಗೆ ಇಮೇಲ್ ಮಾಡುವುದಾಗಿ ಅಸ್ಮಿತಾಗೆ HR ತಿಳಿಸುತ್ತದೆ.",
              "te": "HR అస్మిత తన గుర్తింపులో మార్పు గురించి జట్టు సభ్యులందరికీ ఇమెయిల్ చేస్తానని తెలియజేసింది.",
              "or": "ଅସ୍ମିତାଙ୍କୁ HR ସୂଚନା ଦେଇଥାଏ ଯେ ସେ ତାଙ୍କର ପରିଚୟର ପରିବର୍ତ୍ତନ ବିଷୟରେ ସମସ୍ତ ଦଳର ସଦସ୍ୟଙ୍କୁ ଇମେଲ୍ କରିବେ |",
              "as": "HR গৰাকীয়ে অস্মিতাক জনাইছে যে তেওঁ দলৰ সকলো সদস্যক তেওঁৰ পৰিচয়ৰ পৰিৱৰ্তনৰ বিষয়ে ইমেইল কৰিব।",
              "gu": "HR અસ્મિતાને જાણ કરે છે કે તે ટીમના તમામ સભ્યોને તેની ઓળખમાં ફેરફાર વિશે ઈમેલ કરી દે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_1_4",
              "hi": "D4_1_4",
              "ka": "D4_1_4",
              "te": "D4_1_4",
              "or": "D4_1_4",
              "as": "D4_1_4",
              "gu": "D4_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The HR is keen that Asmita has a smooth transition at the workspace. Asmita is happy to get HR's support.",
              "hi": "एचआर उत्सुक है कि अस्मिता का कार्यक्षेत्र में एक सहज ट्रांज़िशन/ बदलाव हो। एचआर का सहयोग पाकर अस्मिता खुश है।",
              "ka": "ಅಸ್ಮಿತಾ ಕಾರ್ಯಕ್ಷೇತ್ರದಲ್ಲಿ ಸುಗಮ ಪರಿವರ್ತನೆ ಹೊಂದಲು HR ಉತ್ಸುಕವಾಗಿದ್ದರೆ. ಅಸ್ಮಿತಾಗೆ ಎಚ್‌ಆರ್‌ ಬೆಂಬಲ ಸಿಕ್ಕಿದ್ದು ಖುಷಿ ತಂದಿದೆ.",
              "te": "వర్క్‌స్పేస్‌లో అస్మిత ట్రాన్సిషన్ సజావుగా జరగాలని HR అనుకుంటుంది. అస్మిత HR సపోర్ట్ పొందడంతో సంతోషంగా ఉంది.",
              "or": "କର୍ମକ୍ଷେତ୍ରରେ ଅସ୍ମିତାଙ୍କର ଏକ ସୁଗମ ପରିବର୍ତ୍ତନ ଅଛି ବୋଲି HR ଆଗ୍ରହୀ | ଅସ୍ମିତା HR ର ସମର୍ଥନ ପାଇ ଖୁସି |",
              "as": "HR আগ্ৰহী যে অস্মিতাৰ কৰ্মক্ষেত্ৰত এক মসৃণ পৰিৱৰ্তন আহিছে। অস্মিতাই HR ৰ সমৰ্থন লাভ কৰি সুখী।",
              "gu": "HR ઈચ્છે  છે કે અસ્મિતા કાર્યસ્થળ પર સરળતાથી ટ્રાન્સિશન કરે . HRનો સહયોગ મેળવાથી અસ્મિતા ખુશ છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_2_1_reveal",
              "hi": "D4_2_1_रिवील ",
              "ka": "D4_2_1_reveal",
              "te": "D4_2_1_reveal",
              "or": "D4_2_1_reveal",
              "as": "D4_2_1_প্ৰকাশ কৰে",
              "gu": "D4_2_1_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailTo",
            "value": {
              "en": "All MetaLab team members",
              "hi": "सभी मेटा लैब टीम के सदस्य",
              "ka": "ಎಲ್ಲಾ ಮೆಟಾ ಲ್ಯಾಬ್‌ ತಂಡದ ಸದಸ್ಯರು",
              "te": "అందరు మెటాల్యాబ్ బృందం సభ్యులు",
              "or": "ସମସ୍ତ ମେଟାଲ୍ୟାବ୍‌ ଦଳ ସଦସ୍ୟମାନେ",
              "as": "মেটালেব দলৰ সকলো সদস্য",
              "gu": "મેટલેબની  ટીમના તમામ સભ્યો"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailSubject",
            "value": {
              "en": "Employee gender transition",
              "hi": "कर्मचारी लिंग ट्रांज़िशन ",
              "ka": "ಉದ್ಯೋಗಿ ಲಿಂಗ ಪರಿವರ್ತನೆ",
              "te": "ఉద్యోగి జెండర్ ట్రాన్సిషన్",
              "or": "କର୍ମଚାରୀଙ୍କ ଲିଙ୍ଗ ପରିବର୍ତ୍ତନ",
              "as": "কৰ্মচাৰীৰ লিংগ পৰিৱৰ্তন",
              "gu": "કર્મચારી લિંગ ટ્રાન્સિશન"
            }
          },
          {
            "valuePath": "blocks.0.props.receiveMailMsg",
            "value": {
              "en": "This email is intended to inform you of an employee's gender transition and address some of the most common questions you may have regarding the employee's transitional period.\nEffective tomorrow, Asmita (previously known to you as Arvind), who is in the process of a gender transition, will exhibit some personal changes while at work...",
              "hi": "इस ईमेल का उद्देश्य आपको किसी कर्मचारी के लिंग परिवर्तन के बारे में सूचित करना और कर्मचारी की संक्रमणकालीन अवधि के संबंध में आपके कुछ सबसे सामान्य प्रश्नों का समाधान करना है।\nकल से प्रभावी, अस्मिता (जिसे आप पहले अरविंद के नाम से जानते थे), जो लिंग परिवर्तन की प्रक्रिया में है, काम के दौरान कुछ व्यक्तिगत बदलाव प्रदर्शित करेगी...",
              "ka": "\"ಈ ಇಮೇಲ್ ಉದ್ಯೋಗಿಯ ಲಿಂಗ ಪರಿವರ್ತನೆಯ ಕುರಿತು ನಿಮಗೆ ತಿಳಿಸಲು ಮತ್ತು ಉದ್ಯೋಗಿಯ ಪರಿವರ್ತನೆಯ ಅವಧಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ನೀವು ಹೊಂದಿರುವ ಕೆಲವು ಸಾಮಾನ್ಯ ಪ್ರಶ್ನೆಗಳನ್ನು ಪರಿಹರಿಸಲು ಉದ್ದೇಶಿಸಲಾಗಿದೆ.\n ನಾಳೆಯಿಂದ ಜಾರಿಗೆ ಬರಲಿದೆ, ಲಿಂಗ ಪರಿವರ್ತನೆಯ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿರುವ ಅಸ್ಮಿತಾ (ಈ ಹಿಂದೆ ನಿಮಗೆ ಅರವಿಂದ್ ಎಂದು ಕರೆಯಲಾಗುತ್ತಿತ್ತು) ಅವರು ಕೆಲಸದಲ್ಲಿರುವಾಗ ಕೆಲವು ವೈಯಕ್ತಿಕ ಬದಲಾವಣೆಗಳನ್ನು ಪ್ರದರ್ಶಿಸುತ್ತಾರೆ...\"",
              "te": "ఈ ఇమెయిల్ ఉద్యోగి యొక్క జెండర్ ట్రాన్సిషన్ గురించి మీకు తెలియజేయడానికి మరియు ఉద్యోగి పరివర్తన కాలానికి సంబంధించి మీకు ఉన్న కొన్ని సాధారణ ప్రశ్నలను పరిష్కరించడానికి ఉద్దేశించబడింది.\n రేపటి నుండి, లింగ మార్పిడి ప్రక్రియలో ఉన్న అస్మితలో (గతంలో మీకు అరవింద్ అని పిలిచేవారు) పనిలో ఉన్నప్పుడు కొన్ని వ్యక్తిగత మార్పులు జరగవచ్చు ...",
              "or": "ଏହି ଇମେଲ୍ ଆପଣଙ୍କୁ ଜଣେ କର୍ମଚାରୀଙ୍କ ଲିଙ୍ଗ ପରିବର୍ତ୍ତନ ବିଷୟରେ ଜଣାଇବା ପାଇଁ ଉଦ୍ଦିଷ୍ଟ ଏବଂ କର୍ମଚାରୀଙ୍କ କ୍ରାନ୍ତିକାରୀ ଅବଧି ବିଷୟରେ ଆପଣଙ୍କର କିଛି ସାଧାରଣ ପ୍ରଶ୍ନର ସମାଧାନ କରିବାକୁ ଉଦ୍ଦିଷ୍ଟ | ଆସନ୍ତାକାଲି ଠାରୁ, ଅସ୍ମିତା (ପୂର୍ବରୁ ଆପଣଙ୍କୁ ଅରବିନ୍ଦ ଭାବରେ ଜଣାଶୁଣା), ଯିଏ ଲିଙ୍ଗ ପରିବର୍ତ୍ତନ ପ୍ରକ୍ରିୟାରେ ଅଛନ୍ତି, କାର୍ଯ୍ୟ ସମୟରେ କିଛି ବ୍ୟକ୍ତିଗତ ପରିବର୍ତ୍ତନ ପ୍ରଦର୍ଶନ କରିବେ ...",
              "as": "এই ইমেইলৰ উদ্দেশ্য হৈছে আপোনাক এজন কৰ্মচাৰীৰ লিংগ পৰিৱৰ্তনৰ বিষয়ে অৱগত কৰা আৰু কৰ্মচাৰীৰ পৰিৱৰ্তনশীল সময়সম্পৰ্কে আপোনাৰ থাকিব পৰা কিছুমান সচৰাচৰ প্ৰশ্নৰ সমাধান কৰা।\n কাইলৈ কাৰ্যকৰী হোৱাকৈ, অস্মিতাই (আগতে তেওঁক অৰবিন্দ বুলি জনা গৈছিল), যি লিংগ পৰিৱৰ্তনৰ প্ৰক্ৰিয়াত আছে, কামত থাকোঁতে কিছুমান ব্যক্তিগত পৰিৱৰ্তন প্ৰদৰ্শন কৰিব...",
              "gu": "આ ઈમેઈલનો હેતુ તમને કર્મચારીના લિંગ ટ્રાન્સિશન વિશે જાણ કરવા અને કર્મચારીના ટ્રાન્સિશન સમયગાળાને લગતા કેટલાક સામાન્ય પ્રશ્નોને સંબોધવા માટે છે. આવતીકાલથી  પ્રભાવિત, અસ્મિતા (અગાઉ તમને અરવિંદ તરીકે ઓળખાતી હતી), જે લિંગ પરિવર્તનની પ્રક્રિયામાં છે, કામ પર હોય ત્યારે કેટલાક વ્યક્તિગત ફેરફારો પ્રદર્શિત કરશે..."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_2_2",
              "hi": "D4_2_2",
              "ka": "D4_2_2",
              "te": "D4_2_2",
              "or": "D4_2_2",
              "as": "D4_2_2",
              "gu": "D4_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is happy to read this, but it also makes her nervous. She doesn’t know what to expect.",
              "hi": "यह पढ़कर अस्मिता खुश तो होती है, लेकिन इससे वह घबरा भी जाती है। वह नहीं जानती कि क्या आशा की जाए।",
              "ka": "ಇದನ್ನು ಓದಿ ಅಸ್ಮಿತಾಗೆ ಖುಷಿಯಾದರೂ ಆತಂಕವೂ ಆಗುತ್ತದೆ. ಏನನ್ನು ನಿರೀಕ್ಷಿಸಬೇಕೆಂದು ಅವಳಿಗೆ ತಿಳಿದಿಲ್ಲ.",
              "te": "అస్మితకి ఇది చదివి సంతోషం కలిగింది, కానీ అది ఆమెకి కొంచెం కంగారు కలిగిస్తుంది. ఏమి ఆశించాలో ఆమెకు అర్థం అవ్వదు.",
              "or": "ଅସ୍ମିତା ଏହାକୁ ପଢି ଖୁସି, କିନ୍ତୁ ଏହା ମଧ୍ୟ ତାଙ୍କୁ ନର୍ଭସ କରିଥାଏ | ସେ କ’ଣ ଆଶା କରିବେ ଜାଣନ୍ତି ନାହିଁ |",
              "as": "অস্মিতাই এইটো পঢ়ি সুখী হয়, কিন্তু ই তাইক উদ্বিগ্ন কৰি তোলে। তাই নাজানে যে তাই কি আশা কৰিব লাগে।",
              "gu": "અસ્મિતા આ વાંચીને ખુશ થાય છે, પણ તેણી નર્વસ પણ  છે.  તેણીને ખબર નથી કે શું અપેક્ષા રાખવી."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D4_2_3",
              "hi": "D4_2_3",
              "ka": "D4_2_3",
              "te": "D4_2_3",
              "or": "D4_2_3",
              "as": "D4_2_3",
              "gu": "D4_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The next day feels different, and Asmita decides that she will make the best first impression in front of her friends and colleagues.",
              "hi": "अगले दिन अलग अनुभव होता है, और अस्मिता निश्चय करती है कि वह अपने मित्रों और सहकर्मियों के सामने पहला प्रभाव सबसे अच्छा डालेगी।",
              "ka": "ಮರುದಿನ ವಿಭಿನ್ನವಾಗಿ ಭಾಸವಾಗುತ್ತದೆ, ಮತ್ತು ಅಸ್ಮಿತಾ ತನ್ನ ಸ್ನೇಹಿತರು ಮತ್ತು ಸಹೋದ್ಯೋಗಿಗಳ ಮುಂದೆ ಅತ್ಯುತ್ತಮವಾದ ಮೊದಲ ಪ್ರಭಾವ ಬೀರಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
              "te": "మరుసటి రోజు భిన్నంగా అనిపిస్తుంది. అస్మిత తన స్నేహితులు మరియు సహోద్యోగుల ముందు మంచి ఫస్ట్ ఇంప్రెషన్ పొందాలని నిర్ణయించుకుంది.",
              "or": "ପରଦିନ ଅଲଗା ଅନୁଭବ ହୁଏ, ଏବଂ ଅସ୍ମିତା ନିଷ୍ପତ୍ତି ନିଅନ୍ତି ଯେ ସେ ନିଜ ବନ୍ଧୁ ଏବଂ ସହକର୍ମୀଙ୍କ ସାମ୍ନାରେ ସର୍ବୋତ୍ତମ ଭାବନା ସୃଷ୍ଟି କରିବେ |",
              "as": "পিছদিনা বেলেগ অনুভৱ হয়, আৰু অস্মিতাই সিদ্ধান্ত লয় যে তাই তাইৰ বন্ধু আৰু সহকৰ্মীসকলৰ সন্মুখত সৰ্বশ্ৰেষ্ঠ প্ৰথম প্ৰভাৱ পেলাব।",
              "gu": "બીજા દિવસે અલગ લાગે છે, અને અસ્મિતા નક્કી કરે છે કે તેણી તેના મિત્રો અને સહકાર્યકરોની સામે શ્રેષ્ઠ પ્રથમ છાપ છોડશે."
            }
          }
        ]
      },
      "scene31": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_1_1",
              "hi": "D5_1_1",
              "ka": "D5_1_1",
              "te": "D5_1_1",
              "or": "D5_1_1",
              "as": "D5_1_1",
              "gu": "D5_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita dresses up as a woman for the first time. She is nervous but also relieved that she can finally be true to herself.",
              "hi": "अस्मिता पहली बार एक महिला के रूप में कपड़े पहनती है। उसे घबराहट है लेकिन इस बात की भी तसल्ली है कि वह अंततः स्वयं के साथ सच साबित हो सकती है।",
              "ka": "ಅಸ್ಮಿತಾ ಮೊದಲ ಬಾರಿಗೆ ಮಹಿಳೆಯಾಗಿ ಕಂಗೊಳಿಸುತ್ತಿದ್ದಾರೆ. ಅವಳು ಉದ್ವಿಗ್ನಳಾಗಿದ್ದಾಳೆ ಆದರೆ ಅವಳು ಅಂತಿಮವಾಗಿ ತನಗೆ ತಾನೇ ನಿಜವಾಗಬಲ್ಲಳು ಎಂಬ ಸಮಾಧಾನವೂ ಇದೆ.",
              "te": "అస్మిత తొలిసారిగా స్త్రీ లాగా డ్రెస్ వేసుకుంది. ఆమెకు కొంచెం భయంగా ఉంది, కానీ చివరకు ఆమె సౌకర్యంగా ఉండగలదని ఉపశమనం పొందింది.",
              "or": "ଅସ୍ମିତା ପ୍ରଥମ ଥର ପାଇଁ ଜଣେ ମହିଳା ଭାବରେ ପରିଧାନ କରନ୍ତି | ସେ ନର୍ଭସ କିନ୍ତୁ ଏଥିରୁ ମୁକ୍ତି ପାଇଲେ ଯେ ସେ ଶେଷରେ ନିଜ ପାଇଁ ସତ୍ୟ ହୋଇପାରନ୍ତି |",
              "as": "অস্মিতাই প্ৰথমবাৰৰ বাবে এগৰাকী মহিলাৰ পোছাক পৰিধান কৰিছিল। তাই নাৰ্ভাছ কিন্তু লগতে সকাহ পাইছে যে তাই অৱশেষত নিজৰ প্ৰতি সঁচা হ'ব পাৰিছে।",
              "gu": "અસ્મિતા પહેલીવાર એક મહિલા જેવો પોશાક પહેરે છે.  તે નર્વસ છે પણ રાહત અનુભવે છે કે આખરે તેણી પોતાની જાત પ્રત્યે સાચી બની શકે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_1_2///",
              "hi": "D5_1_2 ////",
              "ka": "D5_1_2///",
              "te": "D5_1_2///",
              "or": "D5_1_2///",
              "as": "D5_1_2///",
              "gu": "D5_1_2///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "She cannot describe the joy of wearing the clothes she had always admired.",
              "hi": "वह उन कपड़ों को पहनने की खुशी का वर्णन नहीं कर सकती, जिनकी उसने हमेशा प्रशंसा की थी।",
              "ka": "ಅವಳು ಯಾವಾಗಲೂ ಮೆಚ್ಚಿದ ಬಟ್ಟೆಗಳನ್ನು ಧರಿಸಿದಾಗ ಆಗುವ ಸಂತೋಷವನ್ನು ಅವಳು ವರ್ಣಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
              "te": "తను ఎప్పుడూ కావాలి అనుకునే బట్టలు వేసుకోవడం వల్ల చాలా ఆనందంగా ఉంది.",
              "or": "ସେ ସର୍ବଦା ପ୍ରଶଂସା କରୁଥିବା ପୋଷାକ ପିନ୍ଧିବାର ଆନନ୍ଦ ବର୍ଣ୍ଣନା କରିପାରନ୍ତି ନାହିଁ |",
              "as": "তাই সদায় পিন্ধিবলৈ বিচৰা কাপোৰ পিন্ধি কিমান সুখ পাইছে সেয়া বৰ্ণনা কৰিব নোৱাৰে।",
              "gu": "તેણીને હંમેશા જે કપડાંનો શોખ હતો  તે પહેરવાનો આનંદ તેણી વર્ણવી શકતી નથી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_1_3",
              "hi": "D5_1_3",
              "ka": "D5_1_3",
              "te": "D5_1_3",
              "or": "D5_1_3",
              "as": "D5_1_3",
              "gu": "D5_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Everyone is nervous for her. Her parents give her their blessings before she leaves for work.",
              "hi": "उसके लिए हर कोई घबराया हुआ है। काम पर जाने से पहले उसके माता-पिता उसे आशीर्वाद देते हैं।",
              "ka": "ಎಲ್ಲರೂ ಅವಳಿಗೆ ಹೆದರುತ್ತಾರೆ. ಅವಳು ಕೆಲಸಕ್ಕೆ ಹೊರಡುವ ಮೊದಲು ಅವಳ ಪೋಷಕರು ಅವಳಿಗೆ ತಮ್ಮ ಆಶೀರ್ವಾದವನ್ನು ನೀಡುತ್ತಾರೆ.",
              "te": "అందరూ ఆమె గురించి కంగారు పడుతున్నారు. ఆమె ఉద్యోగానికి వెళ్లే ముందు తల్లిదండ్రులు ఆమెకు ఆశీస్సులు అందిస్తారు.",
              "or": "ସମସ୍ତେ ତାଙ୍କ ପାଇଁ ନର୍ଭସ୍‌ ଅଛନ୍ତି | ସେ କାମକୁ ଯିବା ପୂର୍ବରୁ ତାଙ୍କ ପିତାମାତା ତାଙ୍କୁ ଆଶୀର୍ବାଦ ଦିଅନ୍ତି |",
              "as": "সকলোৱে তাইৰ বাবে নাৰ্ভাছ হৈ আছে। তাই কামলৈ যোৱাৰ আগতে তাইৰ মাক দেউতাকে তাইক তেওঁলোকৰ আশীৰ্বাদ দিয়ে।",
              "gu": "દરેક જણ તેને લઈને નર્વસ છે.  તેણી કામ પર જાય તે પહેલા તેના માતા-પિતા તેણીને આશીર્વાદ આપે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_1_4",
              "hi": "D5_1_4",
              "ka": "D5_1_4",
              "te": "D5_1_4",
              "or": "D5_1_4",
              "as": "D5_1_4",
              "gu": "D5_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Pranav and Roshni want to be there for her when she enters the office space and show their support. They go with her to drop her at the office.",
              "hi": "प्रणव और रोशनी उसके लिए वहां उपस्थित रहना चाहते हैं और उसको अपना समर्थन देते हैं, जब वह कार्यालय में प्रवेश करती है। वे उसे कार्यालय छोड़ने के लिए उसके साथ जाते हैं।",
              "ka": "ಪ್ರಣವ್ ಮತ್ತು ರೋಶ್ನಿ ಅವರು ಕಚೇರಿಗೆ ಪ್ರವೇಶಿಸಿದಾಗ ಅವರ ಬೆಂಬಲವನ್ನು ತೋರಿಸಲು ಬಯಸುತ್ತಾರೆ. ಅವರು ಅವಳನ್ನು ಕಚೇರಿಗೆ ಬಿಡಲು ಅವಳೊಂದಿಗೆ ಹೋಗುತ್ತಾರೆ.",
              "te": "ప్రణవ్ మరియు రోష్ని ఆఫీస్ స్పేస్‌లోకి ప్రవేశించినప్పుడు ఆమెకు అండగా ఉండాలని మరియు వారి మద్దతును తెలియజేయాలని కోరుకుంటారు. ఆమెని ఆఫీసులో దింపడానికి వాళ్ళు ఆమెతో వెళ్ళారు.",
              "or": "ଯେତେବେଳେ ସେ ଅଫିସ୍ ସ୍ପେସରେ ପ୍ରବେଶ କରନ୍ତି ସେତେବେଳେ ପ୍ରଣବ ରୋଶନି ତାଙ୍କ ସେମାନଙ୍କର ସମର୍ଥନ ଦେଖାନ୍ତି ଏବଂ ତାଙ୍କ ପାଇଁ ସେଠାରେ ରହିବାକୁ ଚାହାଁନ୍ତି | ସେମାନେ ତାଙ୍କ ସହିତ ଅଫିସରେ ପକାଇବାକୁ ଯାଇଥାନ୍ତି |",
              "as": "প্ৰণৱ আৰু ৰোশনিয়ে তাইৰ বাবে অফিচত থাকিব বিচাৰে আৰু যেতিয়া তাই অফিচত সোমায় তেতিয়া তেওঁলোকৰ সমৰ্থন দেখুৱায়। তেওঁলোকে তাইক অফিচত থৈ আহিবলৈ তাইৰ সৈতে যায়।",
              "gu": "પ્રણવ અને રોશની જ્યારે તેણી ઓફિસમાં પ્રવેશ કરે છે  પોતાનો ટેકો દર્શાવવા ત્યાં હાજર રહેવા માંગે છે.  તેઓ તેનણીને ઓફિસે મૂકવા તેણીની સાથે જાય છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_2_1",
              "hi": "D5_2_1",
              "ka": "D5_2_1",
              "te": "D5_2_1",
              "or": "D5_2_1",
              "as": "D5_2_1",
              "gu": "D5_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "People at the workplace react quite differently. Most of the team members keep staring at her, and a few others ask inappropriate questions.",
              "hi": "कार्यस्थल पर लोग काफी अलग तरह से प्रतिक्रिया करते हैं। टीम के अधिकांश सदस्य उसे घूरते रहते हैं, और कुछ अन्य अनुचित प्रश्न पूछते हैं।",
              "ka": "ಕೆಲಸದ ಸ್ಥಳದಲ್ಲಿ ಜನರು ವಿಭಿನ್ನವಾಗಿ ಪ್ರತಿಕ್ರಿಯಿಸುತ್ತಾರೆ. ತಂಡದ ಹೆಚ್ಚಿನ ಸದಸ್ಯರು ಅವಳನ್ನು ನೋಡುತ್ತಲೇ ಇರುತ್ತಾರೆ ಮತ್ತು ಇನ್ನೂ ಕೆಲವರು ಅನುಚಿತ ಪ್ರಶ್ನೆಗಳನ್ನು ಕೇಳುತ್ತಾರೆ.",
              "te": "ఆఫీస్ లోని వ్యక్తులు చాలా భిన్నంగా స్పందిస్తారు. చాలా మంది బృంద సభ్యులు ఆమెను చూస్తూనే ఉంటారు, మరికొందరు ఏవేవో ప్రశ్నలు అడుగుతారు.",
              "or": "କର୍ମକ୍ଷେତ୍ରରେ ଲୋକମାନେ ଭିନ୍ନ ଭିନ୍ନ ପ୍ରତିକ୍ରିୟା କରନ୍ତି | ଦଳର ଅଧିକାଂଶ ସଦସ୍ୟ ତାଙ୍କ ଆଡକୁ ଚାହିଁ ରହିଥା’ନ୍ତି ଏବଂ ଅନ୍ୟ କେତେକ ଅନୁପଯୁକ୍ତ ପ୍ରଶ୍ନ ପଚାରନ୍ତି |",
              "as": "কৰ্মস্থানৰ লোকসকলে একেবাৰে বেলেগ ধৰণে প্ৰতিক্ৰিয়া কৰে। দলৰ বেছিভাগ সদস্যই তাইৰ ফালে চাই থাকে, আৰু আন কেইজনমানে অদ্ভুত প্ৰশ্ন কৰে।",
              "gu": "કાર્યસ્થળ પર લોકો તદ્દન અલગ રીતે પ્રતિક્રિયા આપે છે.  ટીમના મોટા ભાગના સભ્યો તેની સામે તાકી રહે છે, અને કેટલાક અન્ય અયોગ્ય પ્રશ્નો પૂછે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_2_2//",
              "hi": "D5_2_2 //",
              "ka": "D5_2_2//",
              "te": "D5_2_2//",
              "or": "D5_2_2//",
              "as": "D5_2_2//",
              "gu": "D5_2_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Collegue 1",
              "hi": "सहकर्मी 1",
              "ka": "ಸಹೋದ್ಯೋಗಿ 1",
              "te": "సహోద్యోగి 1",
              "or": "Collegue 1",
              "as": "সহকৰ্মী ১",
              "gu": "સહકર્મચારી 1"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Asmita",
              "hi": "अस्मिता",
              "ka": "ಅಸ್ಮಿತಾ",
              "te": "అస్మిత",
              "or": "ଅସ୍ମିତା",
              "as": "অস্মিতা",
              "gu": "અસ્મિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Collegue 1",
              "hi": "सहकर्मी 1",
              "ka": "ಸಹೋದ್ಯೋಗಿ 1",
              "te": "సహోద్యోగి 1",
              "or": "Collegue 1",
              "as": "সহকৰ্মী ১",
              "gu": "સહકર્મચારી 1"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.label",
            "value": {
              "en": "Collegue 2",
              "hi": "सहकर्मी 2",
              "ka": "ಸಹೋದ್ಯೋಗಿ 2",
              "te": "సహోద్యోగి 2",
              "or": "Collegue 2",
              "as": "সহকৰ্মী ২",
              "gu": "સહકર્મચારી 2"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "So from today, we have to call you Asmita?",
              "hi": "तो आज से, हमें आपको अस्मिता बुलाना पड़ेगा?",
              "ka": "ಹಾಗಾದರೆ ಇಂದಿನಿಂದ ನಿನ್ನನ್ನು ಅಸ್ಮಿತಾ ಎಂದು ಕರೆಯಬೇಕಾ?",
              "te": "ఐతే ఈరోజు నుండి నిన్ను అస్మితా అని పిలవాలా?",
              "or": "ତେବେ, ଆଜିଠାରୁ ତୁମକୁ ଅସ୍ମିତା କହିବାକୁ ପଡିବ ?",
              "as": "গতিকে আজিৰ পৰা, আমি আপোনাক অস্মিতা বুলি মাতিব লাগিব?",
              "gu": "તો આજથી અમારે તમને અસ્મિતા કહીને બોલાવવાના છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Yes",
              "hi": "हां",
              "ka": "ಹೌದು",
              "te": "అవును",
              "or": "ହଁ",
              "as": "হয়",
              "gu": "હાં"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "It is going to feel a little weird, I guess. This is all-new, and I was so used to calling you Arvind.",
              "hi": "यह थोड़ा अजीब लगेगा, मुझे लगता है। यह बिल्कुल नया है, और मैं आपको अरविन्द कहने की आदी थी।",
              "ka": "ಇದು ಸ್ವಲ್ಪ ವಿಚಿತ್ರ ಅನಿಸುತ್ತದೆ. ಇದು ಹೊಸದು, ಮತ್ತು ನಾನು ನಿನ್ನನ್ನು ಅರವಿಂದ್ ಎಂದು ಕರೆಯುತ್ತಿದ್ದೆ.",
              "te": "ఇది కొంచెం విచిత్రంగా అనిపిస్తుంది అనుకుంటున్నాను. ఇది చాలా కొత్తది, నేను నిన్ను అరవింద్ అని పిలవడం అలవాటు చేసుకున్నాను.",
              "or": "ଏହା ଟିକିଏ ଅଭୁତ ଅନୁଭବ କରେଇବାକୁ ଯାଉଛି, ମୁଁ ଅନୁମାନ କରୁଛି | ଏହା ସବୁ ନୂଆ, ଏବଂ ମୁଁ ତୁମକୁ ଅରବିନ୍ଦ ବୋଲି ଡାକିବାରେ ଏତେ ଅଭ୍ୟସ୍ତ ଥିଲି |",
              "as": "মই ভাবো, এইটো অলপ অদ্ভুত ধৰণৰ হ'ব। এয়া একেবাৰে নতুন, আৰু মই আপোনাক অৰবিন্দ বুলি মতাতেই বহুত অভ্যস্ত আছিলোঁ।",
              "gu": "મને લાગે છે કે આ થોડું વિચિત્ર છે.  આ બધું નવું છે, અને મને તમને અરવિંદ કહેવાની આદત હતી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.3.text",
            "value": {
              "en": "What are you saying? There is nothing weird about this, we have to be conscious, and soon we will get used to it. Asmita, did you always know you wanted to be a woman?",
              "hi": "तुम क्या कह रहे हो? इसमें कुछ भी अजीब नहीं है, हमें जागरूक होना होगा, और जल्द ही हमें इसकी आदत हो जाएगी। अस्मिता, क्या आप हमेशा से जानती थीं कि आप एक महिला बनना चाहती हैं?",
              "ka": "ನೀನು ಏನು ಹೇಳುತ್ತಿದ್ದೀಯ? ಇದರಲ್ಲಿ ಯಾವುದೇ ವಿಚಿತ್ರವಿಲ್ಲ, ನಾವು ಜಾಗೃತರಾಗಿರಬೇಕು ಮತ್ತು ಶೀಘ್ರದಲ್ಲೇ ನಾವು ಅದನ್ನು ಬಳಸಿಕೊಳ್ಳುತ್ತೇವೆ. ಅಸ್ಮಿತಾ, ನೀವು ಯಾವಾಗಲೂ ಮಹಿಳೆಯಾಗಬೇಕೆಂದು ನಿಮಗೆ ತಿಳಿದಿದೆಯೇ?",
              "te": "మీరేం అంటున్నారు? ఇందులో విచిత్రం ఏమీ లేదు, మనం స్పృహతో ఉండాలి మరియు త్వరలో మనం అలవాటు చేసుకోవచ్చు. అస్మితా, నువ్వు స్త్రీగా ఉండాలనుకుంటున్నావని నీకు ముందే తెలుసా?",
              "or": "ତମେ କ 'ଣ କହୁଛ ? ଏଥିରେ କୌଣସି ଅଦ୍ଭୁତ ବିଷୟ ନାହିଁ, ଆମକୁ ସଚେତନ ହେବାକୁ ପଡିବ, ଏବଂ ଶୀଘ୍ର ଆମେ ଏହାକୁ ଅଭ୍ୟସ୍ତ କରିବୁ | ଅସ୍ମିତା, ତୁମେ ସବୁବେଳେ ଜାଣିଥିଲ କି ତୁମେ ଜଣେ ମହିଳା ହେବାକୁ ଚାହୁଁଛ ?",
              "as": "আপুনি কি কৈ আছে? ইয়াত একো অদ্ভুত নাই, আমি সচেতন হ'ব লাগিব, আৰু সোনকালেই আমি ইয়াত অভ্যস্ত হ'ম। অস্মিতা, আপুনি সদায় জানিছিলনে যে আপুনি এগৰাকী মহিলা হ'ব বিচাৰে?",
              "gu": "શું કહી રહ્યા છો?  આમાં કંઈ વિચિત્ર નથી, આપણે સભાન રહેવું પડશે, અને ટૂંક સમયમાં આપણને તેની આદત પડી જશે.  અસ્મિતા, શું તમે હંમેશા થી જાણતા હતા કે તમે સ્ત્રી બનવા માગો છો?"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_2_3////",
              "hi": "D5_2_3 //////",
              "ka": "D5_2_3////",
              "te": "D5_2_3////",
              "or": "D5_2_3////",
              "as": "D5_2_3////",
              "gu": "D5_2_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Not always, but I feel like I have known this about myself for the last few years, even though it has taken me a long time to accept it.",
              "hi": "हमेशा नहीं, लेकिन मुझे ऐसा लगता है कि मैं पिछले कुछ सालों से अपने बारे में जानती हूं, भले ही मुझे इसे स्वीकार करने में काफी समय लगा हो।",
              "ka": "ಯಾವಾಗಲೂ ಅಲ್ಲ, ಆದರೆ ಕಳೆದ ಕೆಲವು ವರ್ಷಗಳಿಂದ ನನ್ನ ಬಗ್ಗೆ ನನಗೆ ತಿಳಿದಿದೆ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ, ಅದನ್ನು ಸ್ವೀಕರಿಸಲು ನನಗೆ ಬಹಳ ಸಮಯ ತೆಗೆದುಕೊಂಡಿದ್ದರೂ ಸಹ.",
              "te": "ఎప్పటినుండో కాదు, గత కొన్ని సంవత్సరాలుగా ఇది నాకు తెలుసునని నేను భావిస్తున్నాను, దానిని అంగీకరించడానికి నాకు చాలా సమయం పట్టింది.",
              "or": "ସବୁବେଳେ ନୁହେଁ, କିନ୍ତୁ ମୋତେ ଲାଗୁଛି ଯେ ମୁଁ ଗତ କିଛି ବର୍ଷ ଧରି ନିଜ ବିଷୟରେ ଏହା ଜାଣିଛି, ଯଦିଓ ଏହାକୁ ଗ୍ରହଣ କରିବାକୁ ମୋତେ ବହୁତ ସମୟ ଲାଗିଛି |",
              "as": "সদায়ে নহয়, কিন্তু মই অনুভৱ কৰোঁ যে মই যোৱা কেইবছৰমান ধৰি নিজৰ বিষয়ে এইটো জানিছোঁ, যদিও ইয়াক গ্ৰহণ কৰিবলৈ মোক দীঘলীয়া সময় লাগিছে।",
              "gu": "હંમેશા નહીં, પરંતુ મને લાગે છે કે છેલ્લા કેટલાક વર્ષોથી હું મારા વિશે આ જાણું છું, તેમ છતાં મને તે સ્વીકારવામાં ઘણો સમય લાગ્યો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "And did you get the surgery done?",
              "hi": "और क्या आपने सर्जरी करवाई?",
              "ka": "ಮತ್ತು ನೀವು ಶಸ್ತ್ರಚಿಕಿತ್ಸೆಯನ್ನು ಮಾಡಿದ್ದೀರಾ?",
              "te": "మరి మీరు సర్జరీ చేసుకున్నారా?",
              "or": "ଏବଂ ତୁମେ ଅସ୍ତ୍ରୋପଚାର କରେଇସାରିଛ କି ?",
              "as": "আৰু আপুনি অস্ত্ৰোপচাৰটো কৰিছিলনে?",
              "gu": "અને શું તમે સર્જરી કરાવી?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Umm... I don't think I'm comfortable answering that. I am a woman. I identify as one, and that's what matters. It's inappropriate to ask questions about my surgery.",
              "hi": "उम्म... मुझे नहीं लगता कि मैं इसका उत्तर देने में सहज हूं। मैं एक औरत हूँ। मैं एक औरत के रूप में पहचाना जाना चाहता हूं, और यही मायने रखता है। मेरी सर्जरी के बारे में सवाल पूछना अनुचित है।",
              "ka": "ಉಹುಂ... ಅದಕ್ಕೆ ಉತ್ತರಿಸಲು ನನಗೆ ಸಮಾಧಾನವಿಲ್ಲ. ನಾನು ಒಬ್ಬ ಮಹಿಳೆ. ನಾನು ಒಬ್ಬನಾಗಿ ಗುರುತಿಸಿಕೊಳ್ಳುತ್ತೇನೆ ಮತ್ತು ಅದು ಮುಖ್ಯವಾದುದು. ನನ್ನ ಶಸ್ತ್ರಚಿಕಿತ್ಸೆಯ ಬಗ್ಗೆ ಪ್ರಶ್ನೆಗಳನ್ನು ಕೇಳುವುದು ಸೂಕ್ತವಲ್ಲ.",
              "te": "అమ్మో... దానికి సమాధానం చెప్పడం నాకు సౌకర్యంగా లేదు. నేను ఒక స్త్రీని. నేను అలా గుర్తించబడ్డాను మరియు అది ముఖ్యమైనది. నా శస్త్రచికిత్స గురించి ప్రశ్నలు అడగడం సరికాదు.",
              "or": "ଓମ୍ ... ମୁଁ ଭାବୁନାହିଁ ଯେ ମୁଁ ଏହାର ଉତ୍ତର ଦେବାକୁ ସହଜ ଅଟେ | ମୁଁ ଜଣେ ମହିଳା | ମୁଁ ଗୋଟିଏ ଭାବରେ ପରିଚିତ, ଏବଂ ତାହା ହିଁ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ | ମୋର ଅସ୍ତ୍ରୋପଚାର ବିଷୟରେ ପ୍ରଶ୍ନ ପଚାରିବା ଅନୁଚିତ",
              "as": "উমম... মই নাভাবো যে মই ইয়াৰ উত্তৰ সহজভাবে দিব পাৰিম। মই এগৰাকী মহিলা। মই যি হিচাপে পৰিচয় দিওঁ, সেইটোৱেই গুৰুত্বপূৰ্ণ। মোৰ অস্ত্ৰোপচাৰৰ বিষয়ে প্ৰশ্ন কৰাটো অনুচিত।",
              "gu": "બરાબર... મને નથી લાગતું કે હું તેનો જવાબ આપવામાં સહજતાનો અનુભવ કરૂં છું.  હું એક સ્ત્રી છું.  હું એક તરીકે ઓળખું છું, અને તે મહત્વનું છે.  મારી સર્જરી વિશે પ્રશ્નો પૂછવા તે યોગ્ય નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Yes, but how can you call yourself a woman when you still are biologically a man? Isn't that illogical?",
              "hi": "हां, लेकिन अब भी आप जैविक रूप से एक पुरुष हैं तो आप खुद को एक महिला कैसे कह सकते हैं? क्या वह अजीब नहीं है?",
              "ka": "ಹೌದು, ಆದರೆ ನೀವು ಇನ್ನೂ ಜೈವಿಕವಾಗಿ ಪುರುಷನಾಗಿದ್ದಾಗ ನಿಮ್ಮನ್ನು ಮಹಿಳೆ ಎಂದು ಹೇಗೆ ಕರೆಯಬಹುದು? ಅದು ತರ್ಕಬದ್ಧವಲ್ಲವೇ?",
              "te": "అవును, కానీ మీరు శారీరకంగా పురుషుడిగా ఉన్నప్పుడు మిమ్మల్ని మీరు స్త్రీ అని ఎలా పిలుచుకుంటారు? అది అశాస్త్రీయం కాదా?",
              "or": "ହଁ, କିନ୍ତୁ ଯେତେବେଳେ ତୁମେ ଜୈବିକ ଭାବରେ ଜଣେ ପୁରୁଷ, ତୁମେ କିପରି ନିଜକୁ ଜଣେ ମହିଳା ବୋଲି କହିପାରିବ ? ତାହା ଅଯୌକ୍ତିକ ନୁହେଁ କି ?",
              "as": "হয়, কিন্তু আপুনি কেনেকৈ নিজকে এগৰাকী মহিলা বুলি ক'ব পাৰে যেতিয়া আপুনি এতিয়াও জৈৱিকভাৱে পুৰুষ? এইটো অযৌক্তিক নহয় নেকি?",
              "gu": "હા, પણ જ્યારે તમે હજી પણ જૈવિક રીતે પુરુષ છો ત્યારે તમે તમારી જાતને સ્ત્રી કેવી રીતે કહી શકો?  શું તે અતાર્કિક નથી?"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_2_4",
              "hi": "D5_2_4",
              "ka": "D5_2_4",
              "te": "D5_2_4",
              "or": "D5_2_4",
              "as": "D5_2_4",
              "gu": "D5_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is tired of hearing this question repeatedly. Every time she hears this, many thoughts run into her mind, but she always takes time to think and answer such questions without sounding rude.",
              "hi": "यह सवाल बार-बार सुनकर अस्मिता थक गई है। हर बार जब वह यह सुनती है, तो उसके दिमाग में कई विचार दौड़ते हैं, लेकिन वह हमेशा सोचने के लिए समय निकालती है और ऐसे सवालों का जवाब बिना अशिष्टता के देती है।",
              "ka": "ಈ ಪ್ರಶ್ನೆಯನ್ನು ಪದೇ ಪದೇ ಕೇಳಿ ಅಸ್ಮಿತಾ ಸುಸ್ತಾಗಿದ್ದಾಳೆ. ಇದನ್ನು ಕೇಳಿದಾಗಲೆಲ್ಲಾ ಅವಳ ಮನಸ್ಸಿನಲ್ಲಿ ಅನೇಕ ಆಲೋಚನೆಗಳು ಬರುತ್ತವೆ, ಆದರೆ ಅಂತಹ ಪ್ರಶ್ನೆಗಳಿಗೆ ಅಸಭ್ಯವಾಗಿ ಧ್ವನಿಸದೆ ಯೋಚಿಸಲು ಮತ್ತು ಉತ್ತರಿಸಲು ಅವಳು ಯಾವಾಗಲೂ ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "ఈ ప్రశ్న పదే పదే విని విసిగిపోయింది అస్మిత. ఇది విన్న ప్రతిసారీ, ఆమె మనస్సులో అనేక ఆలోచనలు వస్తాయి, కానీ ఆమె ఎప్పుడూ అలాంటి ప్రశ్నలకు మొరటుగా అనిపించకుండా ఆలోచించి సమాధానం ఇవ్వడానికి సమయం తీసుకుంటుంది.",
              "or": "ଅସ୍ମିତା ଏହି ପ୍ରଶ୍ନକୁ ବାରମ୍ବାର ଶୁଣି ଥକି ଯାଇଛନ୍ତି | ପ୍ରତ୍ୟେକ ଥର ଯେତେବେଳେ ସେ ଏହା ଶୁଣନ୍ତି, ଅନେକ ଚିନ୍ତାଧାରା ତାଙ୍କ ମନରେ ପ୍ରବାହିତ ହୁଏ, କିନ୍ତୁ ସେ ସର୍ବଦା ରୁଦ୍ର ଶବ୍ଦ ନକରି ଏପରି ପ୍ରଶ୍ନର ଉତ୍ତର ଓ ଉତ୍ତର ଦେବାକୁ ସମୟ ନେଇଥାନ୍ତି |",
              "as": "অস্মিতাই এই প্ৰশ্নটো বাৰে বাৰে শুনি ভাগৰি পৰিছে। যেতিয়াই তাই এইকথা শুনিব, বহুতো চিন্তা তাইৰ মনলৈ আহে, কিন্তু তাই সদায় সময় লয় আৰু অভদ্ৰ আচৰণ নকৰাকৈ এনে প্ৰশ্নবোৰ ভাবিবলৈ আৰু উত্তৰ দিবলৈ চেষ্টা কৰে।",
              "gu": "આ પ્રશ્ન વારંવાર સાંભળીને અસ્મિતા થાકી ગઈ છે.  જ્યારે પણ તેણી આ સાંભળે છે, ત્યારે તેના મગજમાં ઘણા વિચારો દોડે છે, પરંતુ તે હંમેશા અસંસ્કારી સ્વર  વિના આવા પ્રશ્નોના જવાબ આપવા માટે સમય લે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_2_5_reveal",
              "hi": "D5_2_5_रिवील ",
              "ka": "D5_2_5_reveal",
              "te": "D5_2_5_reveal",
              "or": "D5_2_5_reveal",
              "as": "D5_2_5_প্ৰকাশ কৰে",
              "gu": "D5_2_5_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal her thoughts.",
              "hi": "उसके विचार प्रकट करने के लिए क्लिक करें।",
              "ka": "ಅವಳ ಆಲೋಚನೆಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "ఆమె ఆలోచనలను రివీల్ చేయడానికి క్లిక్ చేయండి.",
              "or": "ତାଙ୍କ ଚିନ୍ତାଧାରା ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "তাইৰ চিন্তাবোৰ প্ৰকাশ কৰিবলৈ ক্লিক কৰক।",
              "gu": "તેણીનાં વિચારો જણાવવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Thought 1",
              "hi": "विचार 1",
              "ka": "ವಿಚಾರ 1",
              "te": "ఆలోచన 1",
              "or": "Thought 1",
              "as": "চিন্তা ১",
              "gu": "વિચાર 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Thought 2",
              "hi": "विचार 2 ",
              "ka": "ವಿಚಾರ 2",
              "te": "ఆలోచన 2",
              "or": "Thought 2",
              "as": "চিন্তা ২",
              "gu": "વિચાર 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "It makes me angry that people question my identity even after coming out as a woman.",
              "hi": "यह बात मुझे गुस्सा दिलाती है कि लोग एक महिला के रूप में सामने आने के बाद भी मेरी पहचान पर सवाल उठाते हैं।",
              "ka": "ಮಹಿಳೆಯಾಗಿ ಹೊರಬಂದ ನಂತರವೂ ಜನರು ನನ್ನ ಗುರುತನ್ನು ಪ್ರಶ್ನಿಸುತ್ತಾರೆ ಎಂದು ನನಗೆ ಕೋಪ ಬರುತ್ತದೆ.",
              "te": "మహిళగా బయటకు వచ్చిన తర్వాత కూడా నా గుర్తింపును ప్రజలు ప్రశ్నించడం నాకు కోపం తెప్పిస్తుంది.",
              "or": "ଏହା ମୋତେ କ୍ରୋଧିତ କରେ ଯେ ଜଣେ ମହିଳା ଭାବରେ ବାହାରକୁ ଆସିବା ପରେ ମଧ୍ୟ ଲୋକମାନେ ମୋର ପରିଚୟ ଉପରେ ପ୍ରଶ୍ନ କରନ୍ତି |",
              "as": "এইটোৰ কাৰণে মোৰ খং উঠে যে এগৰাকী মহিলা হিচাপে ওলাই অহাৰ পিছতো মানুহে মোৰ পৰিচয়ৰ ওপৰত প্ৰশ্ন কৰে।",
              "gu": "મને ગુસ્સો આવે છે કે સ્વયં ને એક મહિલા જાહેર કર્યા  પછી પણ લોકો મારી ઓળખ પર સવાલ ઉઠાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "The acceptance of trans people is new, and people don't know enough. I should be more empathetic. I need to remember what my counselor said about these situations.",
              "hi": "ट्रांस लोगों की स्वीकृति नई है, और लोग पर्याप्त नहीं जानते हैं। मुझे और अधिक सहानुभूतिपूर्ण होना चाहिए। मुझे याद रखने की जरूरत है कि मेरे सलाहकार ने इन स्थितियों के बारे में क्या कहा।",
              "ka": "ಟ್ರಾನ್ಸ್ ಜನರ ಸ್ವೀಕಾರವು ಹೊಸದು, ಮತ್ತು ಜನರಿಗೆ ಸಾಕಷ್ಟು ತಿಳಿದಿಲ್ಲ. ನಾನು ಹೆಚ್ಚು ಸಹಾನುಭೂತಿಯಾಗಿರಬೇಕು. ಈ ಸಂದರ್ಭಗಳ ಬಗ್ಗೆ ನನ್ನ ಸಲಹೆಗಾರರು ಏನು ಹೇಳಿದರು ಎಂಬುದನ್ನು ನಾನು ನೆನಪಿಟ್ಟುಕೊಳ್ಳಬೇಕು.",
              "te": "ట్రాన్స్ పీపుల్ యొక్క అంగీకారం కొత్తది మరియు ప్రజలకు తగినంత తెలియదు. నేను మరింత సానుభూతితో ఉండాలి. ఈ పరిస్థితుల గురించి నా కౌన్సిలర్ ఏమి చెప్పారో నేను గుర్తుంచుకోవాలి.",
              "or": "ଟ୍ରାନ୍ସ ଲୋକମାନଙ୍କର ଗ୍ରହଣ କରାଯିବା ନୂତନ ଅଟେ, ଏବଂ ଲୋକମାନେ ଯଥେଷ୍ଟ ଜାଣନ୍ତି ନାହିଁ | ମୁଁ ଅଧିକ ସହାନୁଭୂତିଶୀଳ ହେବା ଉଚିତ୍ | ମୋର ମନେ ରଖିବା ଆବଶ୍ୟକ ଯେ ଏହି ପରିସ୍ଥିତି ବିଷୟରେ ମୋର ପରାମର୍ଶଦାତା କ’ଣ କହିଥିଲେ |",
              "as": "ট্ৰেন্স লোকসকলৰ গ্ৰহণযোগ্যতা নতুন, আৰু মানুহে পৰ্যাপ্ত নাজানে। মই অধিক সহানুভূতিশীল হোৱা উচিত। এই পৰিস্থিতিবোৰৰ বিষয়ে মোৰ পৰামৰ্শদাতাই কি কৈছিল মই মনত ৰাখিব লাগিব।",
              "gu": "ટ્રાન્સ લોકોની સ્વીકૃતિ નવી છે, અને લોકો પૂરતી જાણકારી નથી.  હું વધુ સહાનુભૂતિ ધરાવતો હોવો જોઈએ.  મારા સલાહકારે આ પરિસ્થિતિઓ વિશે શું કહ્યું તે મારે યાદ રાખવાની જરૂર છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_2_6////",
              "hi": "D5_2_6 /////",
              "ka": "D5_2_6////",
              "te": "D5_2_6////",
              "or": "D5_2_6////",
              "as": "D5_2_6////",
              "gu": "D5_2_6////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Asmita finally answers.",
              "hi": " अंत में अस्मिता जवाब देती है।",
              "ka": "ಕೊನೆಗೆ ಅಸ್ಮಿತಾ ಉತ್ತರಿಸುತ್ತಾಳೆ.",
              "te": "ఎట్టకేలకు అస్మిత సమాధానం చెప్పింది.",
              "or": "ଅସ୍ମିତା ଶେଷରେ ଉତ୍ତର ଦିଅନ୍ତି |",
              "as": "অস্মিতাই অৱশেষত উত্তৰ দিলে।",
              "gu": "આખરે અસ્મિતા જવાબ આપે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "These are personal questions that you don't need to ask. Maybe you should try educating yourself a little on how gender and sexuality work.",
              "hi": "ये व्यक्तिगत प्रश्न हैं जिन्हें आपको पूछने की आवश्यकता नहीं है। हो सकता है कि आपको खुद को थोड़ा शिक्षित करने की कोशिश करनी चाहिए कि लिंग और सेक्सुएलिटी कैसे काम करती है।",
              "ka": "ಇವುಗಳು ನೀವು ಕೇಳಬೇಕಾಗಿಲ್ಲದ ವೈಯಕ್ತಿಕ ಪ್ರಶ್ನೆಗಳು. ಲಿಂಗ ಮತ್ತು ಲೈಂಗಿಕತೆಯು ಹೇಗೆ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ ಎಂಬುದರ ಕುರಿತು ನೀವು ಸ್ವಲ್ಪ ಶಿಕ್ಷಣವನ್ನು ಪಡಯಬೇಕು.",
              "te": "ఇవి మీరు అడగాల్సిన అవసరం లేని వ్యక్తిగత ప్రశ్నలు. లింగం మరియు లైంగికత ఎలా పని చేస్తాయనే దాని గురించి మీరు కొంచెం అవగాహన చేసుకోవడానికి ప్రయత్నించాలి.",
              "or": "ଏଗୁଡ଼ିକ ହେଉଛି ବ୍ୟକ୍ତିଗତ ପ୍ରଶ୍ନ ଯାହା ତୁମକୁ ପଚାରିବା ଆବଶ୍ୟକ ନାହିଁ | ବୋଧହୁଏ ତୁମେ ଲିଙ୍ଗ ଏବଂ କାମ ପ୍ରବୃତ୍ତି କିପରି କାର୍ଯ୍ୟ କରେ ସେ ବିଷୟରେ ନିଜକୁ ଟିକିଏ ଶିକ୍ଷିତ କରିବାକୁ ଚେଷ୍ଟା କରିବା ଉଚିତ୍ |",
              "as": "এইবোৰ ব্যক্তিগত প্ৰশ্ন যিবোৰ আপুনি সোধাৰ প্ৰয়োজন নাই। হয়তো আপুনি লিংগ আৰু যৌনতাই কেনেদৰে কাম কৰে তাৰ ওপৰত নিজকে অলপ শিক্ষিত কৰাৰ চেষ্টা কৰা উচিত।",
              "gu": "આ વ્યક્તિગત પ્રશ્નો છે જે તમારે પૂછવાની જરૂર નથી.  કદાચ તમારે તમારી જાતને લિંગ અને જાતિયતા કેવી રીતે કાર્ય કરે છે તેના પર થોડું શિક્ષિત કરવાનો પ્રયાસ કરવો જોઈએ."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_2_7",
              "hi": "D5_2_7",
              "ka": "D5_2_7",
              "te": "D5_2_7",
              "or": "D5_2_7",
              "as": "D5_2_7",
              "gu": "D5_2_7"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Though Asmita comes out as a transgender woman, she knows she has a long way to go and has many battles to face.",
              "hi": "हालाँकि अस्मिता एक ट्रांसजेंडर महिला के रूप में सामने आती है, लेकिन वह जानती है कि उसे अभी लंबा रास्ता तय करना है और उसे कई लड़ाइयाँ लड़नी हैं।",
              "ka": "ಅಸ್ಮಿತಾ ಲಿಂಗಾಯತ ಮಹಿಳೆಯಾಗಿ ಹೊರಬಂದರೂ, ಅವಳು ಸಾಕಷ್ಟು ದೂರ ಹೋಗಬೇಕೆಂದು ತಿಳಿದಿದ್ದಾಳೆ ಮತ್ತು ಅನೇಕ ಯುದ್ಧಗಳನ್ನು ಎದುರಿಸಬೇಕಾಗಿದೆ.",
              "te": "అస్మిత ట్రాన్స్ జెండర్ మహిళగా బయటకు వచ్చినప్పటికీ, ఆమె ఎదుర్కోవాల్సిన పోరాటాలు చాలా ఉన్నాయని ఆమెకు తెలుసు.",
              "or": "ଯଦିଓ ଅସ୍ମିତା ଜଣେ ଟ୍ରାନ୍ସଜେଣ୍ଡର ମହିଳା ଭାବରେ ଆଗକୁ ଆସନ୍ତି, ସେ ଜାଣନ୍ତି ଯେ ତାଙ୍କୁ ବହୁତ ଦୂର ବାଟ ଯିବାକୁ ଅଛି ଏବଂ ଅନେକ ଯୁଦ୍ଧର ସମ୍ମୁଖୀନ ହେବାକୁ ପଡିବ |",
              "as": "যদিও অস্মিতা এগৰাকী ট্ৰেন্সজেণ্ডাৰ মহিলা হিচাপে পৰিচিত হৈছে, তাই জানে যে তাই বহুদূৰ যাব লাগিব আৰু বহুতো যুঁজৰ সন্মুখীন হ'ব লাগিব।",
              "gu": "અસ્મિતા એક ટ્રાન્સલિંગ મહિલા તરીકે પોતાને જાહેર કરી છે તે છતાં, તેણી જાણે છે કે તેણીએ લાંબી મજલ કાપવાની છે અને ઘણી લડાઈઓનો સામનો કરવાનો છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_3_1",
              "hi": "D5_3_1",
              "ka": "D5_3_1",
              "te": "D5_3_1",
              "or": "D5_3_1",
              "as": "D5_3_1",
              "gu": "D5_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The HR promises to do gender sensitization workshops for the employees regularly.",
              "hi": "एचआर नियमित रूप से कर्मचारियों के लिए लिंग संवेदीकरण कार्यशालाएं करने का वादा करता है।",
              "ka": "ಉದ್ಯೋಗಿಗಳಿಗೆ ನಿಯಮಿತವಾಗಿ ಲಿಂಗ ಸಂವೇದನೆ ಕಾರ್ಯಾಗಾರಗಳನ್ನು ಮಾಡಲು HR ಭರವಸೆ ನೀಡುತ್ತಾರೆ.",
              "te": "ఉద్యోగుల కోసం జెండర్ సెన్సిటైజేషన్ వర్క్‌షాప్‌లు క్రమం తప్పకుండా చేస్తామని HR హామీ ఇచ్చింది.",
              "or": "କର୍ମଚାରୀଙ୍କ ପାଇଁ ଲିଙ୍ଗ ସମ୍ବେଦନଶୀଳ କର୍ମଶାଳା ନିୟମିତ ଆୟୋଜନ କରିବାକୁ HR ପ୍ରତିଶୃତି ଦିଅନ୍ତି |",
              "as": "HRএ কৰ্মচাৰীসকলৰ বাবে নিয়মীয়াকৈ লিংগ সংবেদনশীলতা কৰ্মশালা কৰাৰ প্ৰতিশ্ৰুতি দিয়ে।",
              "gu": "HR કર્મચારીઓ માટે નિયમિતપણે લિંગ સંવેદના વર્કશોપ કરવાનું વચન આપે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_3_2",
              "hi": "D5_3_2",
              "ka": "D5_3_2",
              "te": "D5_3_2",
              "or": "D5_3_2",
              "as": "D5_3_2",
              "gu": "D5_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The following months are challenging. Though HR assures Asmita that there will be no change in her responsibilities, her experiences with the team lead are different.",
              "hi": "आने वाले महीने चुनौतीपूर्ण हैं। हालांकि एचआर अस्मिता को आश्वासन देता है कि उनकी जिम्मेदारियों में कोई बदलाव नहीं होगा, टीम प्रमुख के साथ उनके अनुभव अलग-अलग हैं।  ",
              "ka": "ಮುಂದಿನ ತಿಂಗಳುಗಳು ಸವಾಲಿನವು. ತನ್ನ ಜವಾಬ್ದಾರಿಗಳಲ್ಲಿ ಯಾವುದೇ ಬದಲಾವಣೆಯಿಲ್ಲ ಎಂದು HR ಅಸ್ಮಿತಾಗೆ ಭರವಸೆ ನೀಡಿದರೂ, ತಂಡದ ನಾಯಕನೊಂದಿಗಿನ ಅವರ ಅನುಭವಗಳು ವಿಭಿನ್ನವಾಗಿವೆ.",
              "te": "తరువాతి కొన్ని నెలలు సవాలుగా ఉన్నాయి. తన బాధ్యతల్లో ఎలాంటి మార్పు ఉండదని HR అస్మితకు హామీ ఇచ్చినప్పటికీ, టీమ్ లీడ్‌తో ఆమె అనుభవాలు భిన్నంగా ఉన్నాయి.",
              "or": "ପରବର୍ତ୍ତୀ ମାସଗୁଡିକ ଚ୍ୟାଲେଞ୍ଜପୁର୍ଣ୍ଣ ଅଟେ | ଯଦିଓ HR ଅସ୍ମିତାଙ୍କୁ ନିଶ୍ଚିତ କରନ୍ତି ଯେ ତାଙ୍କ ଦାୟିତ୍ବ ରେ କୌଣସି ପରିବର୍ତ୍ତନ ହେବ ନାହିଁ, ଦଳର ନେତୃତ୍ୱ ସହିତ ତାଙ୍କର ଅଭିଜ୍ଞତା ଅଲଗା ଅଟେ |",
              "as": "পৰৱৰ্তী মাহবোৰ প্ৰত্যাহ্বানজনক। যদিও HRএ অস্মিতাক আশ্বাস দিয়ে যে তেওঁৰ দায়িত্বত কোনো পৰিৱৰ্তন নহ'ব, দলৰ নেতৃত্বৰ সৈতে তেওঁৰ অভিজ্ঞতা পৃথক আছিল।",
              "gu": "આગામી મહિનાઓ પડકારજનક છે.  જો કે HR અસ્મિતાને ખાતરી આપે છે કે તેની જવાબદારીઓમાં કોઈ ફેરફાર થશે નહીં, ટીમ લીડ સાથેના તેના અનુભવો અલગ રહ્યા."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_3_3",
              "hi": "D5_3_3",
              "ka": "D5_3_3",
              "te": "D5_3_3",
              "or": "D5_3_3",
              "as": "D5_3_3",
              "gu": "D5_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She faces some resistance from her manager to give her more complex projects.",
              "hi": "उसे अधिक जटिल प्रोजेक्ट्स को देने के लिए अपने प्रबंधक से कुछ प्रतिरोध का सामना करना पड़ता है।",
              "ka": "ಆಕೆಗೆ ಹೆಚ್ಚು ಸಂಕೀರ್ಣವಾದ ಯೋಜನೆಗಳನ್ನು ನೀಡಲು ತನ್ನ ಮ್ಯಾನೇಜರ್‌ನಿಂದ ಸ್ವಲ್ಪ ಪ್ರತಿರೋಧವನ್ನು ಎದುರಿಸಬೇಕಾಗುತ್ತದೆ",
              "te": "ఆమెకు మరింత క్లిష్టమైన ప్రాజెక్ట్‌లను అందించడానికి ఆమె మేనేజర్ నుండి కొంత ప్రతిఘటనను ఎదుర్కొంటుంది.",
              "or": "ତାଙ୍କୁ ଅଧିକ ଜଟିଳ ପ୍ରୋଜେକ୍ଟଗୁଡିକ ଦେବା ପାଇଁ ସେ ତାଙ୍କ ମ୍ୟାନେଜରଙ୍କ ଠାରୁ କିଛି ପ୍ରତିରୋଧର ସମ୍ମୁଖୀନ ହୋଇଥିଲେ |",
              "as": "তাইক অধিক জটিল প্ৰকল্প দিবলৈ তাই তাইৰ মেনেজাৰৰ পৰা কিছু প্ৰতিৰোধৰ সন্মুখীন হৈছে।",
              "gu": "તેણીને વધુ જટિલ પ્રોજેક્ટ્સ આપવા વિશે તેણીના મેનેજર તરફથી કેટલાક પ્રતિકારનો સામનો કરવો પડે છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_3_4",
              "hi": "D5_3_4",
              "ka": "D5_3_4",
              "te": "D5_3_4",
              "or": "D5_3_4",
              "as": "D5_3_4",
              "gu": "D5_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Before coming out, she worked on three projects, but after transitioning, she is removed from all three of them and given a new one that is not challenging.",
              "hi": "बाहर आने से पहले, उसने तीन प्रोजेक्ट्स पर काम किया, लेकिन ट्रांज़िशन/ बदलाव के बाद, उसे उन तीनों से हटा दिया गया और एक नया दिया गया जो चुनौतीपूर्ण नहीं है।",
              "ka": "ಹೊರಬರುವ ಮೊದಲು, ಅವಳು ಮೂರು ಪ್ರಾಜೆಕ್ಟ್‌ಗಳಲ್ಲಿ ಕೆಲಸ ಮಾಡಿದಳು, ಆದರೆ ಪರಿವರ್ತನೆಯ ನಂತರ, ಅವಳನ್ನು ಮೂರರಿಂದಲೂ ತೆಗೆದುಹಾಕಲಾಗುತ್ತದೆ ಮತ್ತು ಸವಾಲು ಅಲ್ಲದ ಹೊಸದನ್ನು ನೀಡಲಾಯಿತು.",
              "te": "బయటకు రాకముందు, ఆమె మూడు ప్రాజెక్ట్‌లలో పనిచేసింది, కానీ ట్రాన్సిషన్ అయిన తర్వాత, ఆ మూడింటి నుండి ఆమెను తొలగించి, ఛాలెంజింగ్ లేని కొత్తది ఇవ్వబడింది.",
              "or": "ବାହାରକୁ ଆସିବା ପୂର୍ବରୁ, ସେ ତିନୋଟି ପ୍ରୋଜେକ୍ଟରେ କାମ କରିଥିଲେ, କିନ୍ତୁ ପରିବର୍ତ୍ତନ ପରେ, ତାଙ୍କୁ ତିନୋଟିରୁ ଅପସାରଣ କରାଯାଇଛି ଏବଂ ଏକ ନୂତନ ପ୍ରୋଜେକ୍ଟ ଦିଆଯାଇଛି ଯାହା ଚ୍ୟାଲେଞ୍ଜ ନୁହେଁ |",
              "as": "ওলাই অহাৰ আগতে, তাই তিনিটা প্ৰকল্পত কাম কৰিছিল, কিন্তু পৰিৱৰ্তনৰ পিছত, তাইক সেই তিনিওটাৰ পৰা আঁতৰাই দিয়া হয় আৰু এটা নতুন প্ৰকল্প দিয়া হয় যিটো প্ৰত্যাহ্বানজনক নহয়।",
              "gu": "જાહેર કરતા પહેલા, તેણીએ ત્રણ પ્રોજેક્ટ્સ પર કામ કર્યું હતું, પરંતુ ટ્રાન્સિશન પછી, તેણીને તે ત્રણેયમાંથી દૂર કરવામાં આવી  અને એક નવો પ્રોજેક્ટ આપવામાં આવ્યો જે પડકારજનક ન હતો."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_4_1",
              "hi": "D5_4_1",
              "ka": "D5_4_1",
              "te": "D5_4_1",
              "or": "D5_4_1",
              "as": "D5_4_1",
              "gu": "D5_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She decides to confront her project lead.",
              "hi": "वह अपने प्रोजेक्ट लीड का सामना करने का निश्चय करती है।",
              "ka": "ಅವಳು ತನ್ನ ಪ್ರಾಜೆಕ್ಟ್ ಲೀಡ್ ಅನ್ನು ಎದುರಿಸಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
              "te": "ఆమె తన ప్రాజెక్ట్ లీడ్‌ను ఎదుర్కోవాలని నిర్ణయించుకుంది.",
              "or": "ସେ ତାଙ୍କ ପ୍ରୋଜେକ୍ଟ ଲିଡ୍‌ଙ୍କ ସହ ମୁହାଁମୁହିଁ ହେବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
              "as": "তাই তাইৰ প্ৰকল্পৰ নেতৃত্বৰ সন্মুখীন হোৱাৰ সিদ্ধান্ত লয়।",
              "gu": "તેણીએ પોતાનાં પ્રોજેક્ટ લીડનો સામનો કરવાનું નક્કી કર્યું."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_4_2//",
              "hi": "D5_4_2 //",
              "ka": "D5_4_2//",
              "te": "D5_4_2//",
              "or": "D5_4_2//",
              "as": "D5_4_2//",
              "gu": "D5_4_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Project Lead",
              "hi": "प्रोजेक्ट प्रमुख ",
              "ka": "ಪ್ರಾಜೆಕ್ಟ್ ಲೀಡ್",
              "te": "ప్రాజెక్ట్ లీడ్",
              "or": "ପ୍ରୋଜେକ୍ଟ ଲିଡ୍",
              "as": "প্ৰকল্পৰ নেতৃত্ব",
              "gu": "પ્રોજેક્ટ લીડ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "We thought you are going through a lot so we decided it's best not to add more to your plate.",
              "hi": "हमने सोचा कि इस समय आप बहुत कुछ सह रही हैं इसलिए हमने निश्चय किया कि आपकी प्लेट में और अधिक नहीं जोड़ना सबसे अच्छा है।",
              "ka": "ನೀವು ಬಹಳಷ್ಟು ಅನುಭವಿಸುತ್ತಿದ್ದೀರಿ ಎಂದು ನಾವು ಭಾವಿಸಿದ್ದೇವೆ ಆದ್ದರಿಂದ ನಿಮ್ಮ ಪ್ಲೇಟ್‌ಗೆ ಹೆಚ್ಚಿನದನ್ನು ಸೇರಿಸದಿರುವುದು ಉತ್ತಮ ಎಂದು ನಾವು ನಿರ್ಧರಿಸಿದ್ದೇವೆ.",
              "te": "మీరు చాలా కష్టాలు అనుభవిస్తున్నారని మరియు మిమ్మల్ని ఇంకా ఇబ్బంది పెట్టద్దు అని భావించాము.",
              "or": "ଆମେ ଭାବୁଥିଲୁ ତୁମେ ବହୁତ ଖରାପ ଦେଇ ଗତି କରୁଛ ତେଣୁ ଆମେ ସ୍ଥିର କଲୁ ଯେ ତୁମର ଉପରେ ଆଉ ବୋଝ ପକାଇବାକୁ ନାହିଁ |",
              "as": "আমি ভাবিছিলো যে আপুনি বহুত কষ্টকৰ সময় পাৰ কৰি আছে গতিকে আমি সিদ্ধান্ত লৈছিলো যে আপোনাৰ ভাগত অধিক যোগ নকৰাই ভাল হ'ব।",
              "gu": "અમને લાગ્યું કે તમે ઘણી પરેશાનીઓથી પસાર થઈ રહ્યાં છો તેથી અમે નક્કી કર્યું કે તેમાં વધુ ન ઉમેરવું શ્રેષ્ઠ છે."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_4_3d",
              "hi": "D5_4_3D",
              "ka": "D5_4_3d",
              "te": "D5_4_3d",
              "or": "D5_4_3d",
              "as": "D5_4_3d",
              "gu": "D5_4_3d"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Asmita say?",
              "hi": "अस्मिता को क्या कहना चाहिए?",
              "ka": "ಅಸ್ಮಿತಾ ಏನು ಹೇಳಬೇಕು?",
              "te": "అస్మిత ఏం చెప్పాలి?",
              "or": "ଅସ୍ମିତା କ’ଣ କହିବା ଉଚିତ୍?",
              "as": "অস্মিতাই কি কোৱা উচিত?",
              "gu": "અસ્મિતાને શું કહે?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Just because I look different, it is not going to change anything. I am still the same person with the same ability to perform.",
              "hi": "सिर्फ इसलिए कि मैं अलग दिखती हूं, इससे कुछ भी बदलने वाला नहीं है। मैं अभी भी वही व्यक्ति हूं जिसमें प्रदर्शन करने की समान क्षमता है।",
              "ka": "ನಾನು ವಿಭಿನ್ನವಾಗಿ ಕಾಣುವುದರಿಂದ ಅದು ಏನನ್ನೂ ಬದಲಾಯಿಸುವುದಿಲ್ಲ. ನಾನು ಈಗಲೂ ಅದೇ ಸಾಮರ್ಥ್ಯವನ್ನು ಹೊಂದಿರುವ ಅದೇ ವ್ಯಕ್ತಿ",
              "te": "నేను భిన్నంగా కనిపించినంత మాత్రాన అది ఏమీ మారదు. నేను ఇప్పటికీ అదే సామర్థ్యాన్ని కలిగి ఉన్న వ్యక్తిని.",
              "or": "କେବଳ ଏଇଥିପାଇଁ ମୁଁ ଅଲଗା ଦେଖାଯାଉଛି, ଏଥିରେ କିଛି ପରିବର୍ତ୍ତନ କରିବାର ନାହିଁ | ମୁଁ ତଥାପି ସେହି ଲୋକ ଯାହାଭିତରେ ପ୍ରଦର୍ଶନ କରିବାର ସମାନ କ୍ଷମତା ଅଛି",
              "as": "কেৱল মই বেলেগ দেখাৰ বাবেই, একো সলনি হোৱা নাই। মই এতিয়াও একেজন ব্যক্তি যাৰ প্ৰদৰ্শন কৰাৰ একেই ক্ষমতা আছে।",
              "gu": "એક માત્ર કારણ કે હું અલગ દેખાઉં છું, તેનાથી કંઈપણ બદલાશે નહીં.  હું હજુ પણ એ જ વ્યક્તિ છું જે પરફોર્મ કરવાની ક્ષમતા ધરાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "I would have appreciated it if you had discussed it with me before making your decision.",
              "hi": "यदि आपने अपना निर्णय लेने से पहले मेरे साथ इस पर चर्चा की होती तो मैं इसकी सराहना करता।",
              "ka": "ನಿಮ್ಮ ನಿರ್ಧಾರವನ್ನು ತೆಗೆದುಕೊಳ್ಳುವ ಮೊದಲು ನೀವು ಅದನ್ನು ನನ್ನೊಂದಿಗೆ ಚರ್ಚಿಸಿದ್ದರೆ ನಾನು ಅದನ್ನು ಪ್ರಶಂಸಿಸುತ್ತೇನೆ.",
              "te": "మీరు నిర్ణయం తీసుకునే ముందు నాతో చర్చించి ఉంటే నేను దానిని అభినందించేదానిని.",
              "or": "ଯଦି ତୁମେ ତୁମର ନିଷ୍ପତ୍ତି ନେବା ପୂର୍ବରୁ ମୋ ସହିତ ଆଲୋଚନା କରିଥାନ୍ତ, ତେବେ ମୁଁ ଏହାକୁ ପ୍ରଶଂସା କରିଥା’ନ୍ତି |",
              "as": "যদি আপুনি আপোনাৰ সিদ্ধান্ত লোৱাৰ আগতে মোৰ সৈতে আলোচনা কৰিলে হয় মই ইয়াক প্ৰশংসা কৰিলোহেঁতেন।",
              "gu": "જો તમે તમારો નિર્ણય લેતા પહેલા મારી સાથે ચર્ચા કરી હોત તો મેં તેની પ્રશંસા કરી હોત."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_4_4////",
              "hi": "D5_4_4 //////",
              "ka": "D5_4_4////",
              "te": "D5_4_4////",
              "or": "D5_4_4////",
              "as": "D5_4_4////",
              "gu": "D5_4_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Asmita, you have to understand that giving you old projects means sharing your identity with our clients. As a company, we are inclusive, but we don’t know how our clients will react.",
              "hi": "अस्मिता, आपको यह समझना होगा कि आपको पुराने प्रोजेक्ट देने का मतलब है अपने ग्राहकों के साथ आपकी पहचान साझा करना। एक कंपनी के रूप में, हम समावेशी हैं, लेकिन हम नहीं जानते कि हमारे ग्राहक कैसे प्रतिक्रिया देंगे।",
              "ka": "ಅಸ್ಮಿತಾ, ನಿಮಗೆ ಹಳೆಯ ಯೋಜನೆಗಳನ್ನು ನೀಡುವುದು ಎಂದರೆ ನಿಮ್ಮ ಗುರುತನ್ನು ನಮ್ಮ ಗ್ರಾಹಕರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳುವುದು ಎಂದು ನೀವು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಬೇಕು. ಕಂಪನಿಯಾಗಿ, ನಾವು ಒಳಗೊಳ್ಳುತ್ತೇವೆ, ಆದರೆ ನಮ್ಮ ಗ್ರಾಹಕರು ಹೇಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುತ್ತಾರೆ ಎಂದು ನಮಗೆ ತಿಳಿದಿಲ್ಲ.",
              "te": "అస్మితా, మీకు పాత ప్రాజెక్ట్‌లు ఇవ్వడం అంటే మీ గుర్తింపును మా క్లయింట్‌లతో పంచుకోవడం అని మీరు అర్థం చేసుకోవాలి. కంపెనీగా, మనము కలిసి ఉంటాము, కానీ క్లయింట్లు ఎలా స్పందిస్తారో మాకు తెలియదు.",
              "or": "ଅସ୍ମିତା, ତୁମକୁ ବୁ୍ଝିବାକୁ ପଡିବ ଯେ ତୁମକୁ ପୁରୁଣା ପ୍ରୋଜେକ୍ଟ ଦେବା ଅର୍ଥ ହେଉଛି ଆମର ପରିଚୟ ଆମ ଗ୍ରାହକମାନଙ୍କ ସହିତ ବାଣ୍ଟିବା | ଏକ କମ୍ପାନୀ ଭାବରେ, ଆମେ ଅନ୍ତର୍ଭୂକ୍ତ, କିନ୍ତୁ ଆମର ଗ୍ରାହକମାନେ କିପରି ପ୍ରତିକ୍ରିୟା କରିବେ ତାହା ଆମେ ଜାଣିନାହୁଁ |",
              "as": "অস্মিতা, আপুনি বুজিব লাগিব যে আপোনাক পুৰণি প্ৰকল্প দিয়াৰ অৰ্থ হৈছে আমাৰ গ্ৰাহকৰ সৈতে আপোনাৰ পৰিচয় ভাগ-বতৰা কৰা। কোম্পানী হিচাপে, আমি অন্তৰ্ভুক্ত, কিন্তু আমি নাজানো যে আমাৰ গ্ৰাহকসকলে কেনে প্ৰতিক্ৰিয়া দেখুৱাব।",
              "gu": "અસ્મિતા, તમારે સમજવું પડશે કે તમને જૂના પ્રોજેક્ટ આપવાનો અર્થ છે કે તમારી ઓળખ અમારા ગ્રાહકો સાથે શેર કરવી.  એક કંપની તરીકે, અમે ઈન્કુસિવ છીએ, પરંતુ અમને ખબર નથી કે અમારા ગ્રાહકો કેવી પ્રતિક્રિયા આપશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Being born as a transgender person is not my choice, but transitioning to a woman is. The clients' concern is only the product and not my identity. Globally organisations have strong diversity and inclusion policies, I don't think they will mind.",
              "hi": "एक ट्रांसजेंडर व्यक्ति के रूप में पैदा होना मेरी पसंद नहीं है, लेकिन एक महिला के रूप में ट्रांज़िशन/ बदलाव करना मेरी पसंद है। ग्राहकों की चिंता केवल उत्पाद है, मेरी पहचान नहीं। विश्व स्तर पर संगठनों में मजबूत विविधता और समावेशी नीतियां हैं, मुझे नहीं लगता कि वे बुरा मानेंगे।",
              "ka": "ಲಿಂಗಾಯತ ವ್ಯಕ್ತಿಯಾಗಿ ಹುಟ್ಟುವುದು ನನ್ನ ಆಯ್ಕೆಯಲ್ಲ, ಆದರೆ ಮಹಿಳೆಯಾಗಿ ಪರಿವರ್ತನೆಯಾಗುವುದು ನನ್ನ ಆಯ್ಕೆ. ಗ್ರಾಹಕರ ಕಾಳಜಿ ಕೇವಲ ಉತ್ಪನ್ನವಾಗಿದೆ ಮತ್ತು ನನ್ನ ಗುರುತು ಅಲ್ಲ. ಜಾಗತಿಕವಾಗಿ ಸಂಸ್ಥೆಗಳು ಬಲವಾದ ವೈವಿಧ್ಯತೆ ಮತ್ತು ಸೇರ್ಪಡೆ ನೀತಿಗಳನ್ನು ಹೊಂದಿವೆ, ಅವುಗಳು ಮನಸ್ಸಿಗೆ ಬರುವುದಿಲ್ಲ ಎಂದು ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "ట్రాన్స్‌జెండర్‌గా పుట్టడం నా ఇష్టం కాదు, కానీ స్త్రీగా మారడం నా నిర్ణయం. క్లైంట్స్ యొక్క ఆలోచన కేవలం ఉత్పత్తి గురించి మాత్రమే కానీ నా గుర్తింపు గురించి కాదు. ప్రపంచవ్యాప్తంగా చాలా సంస్థలు బలమైన వైవిధ్యం మరియు చేరిక విధానాలను కలిగి ఉన్నాయి, వారు దీనిని పట్టించుకుంటారని నేను అనుకోను.",
              "or": "ଟ୍ରାନ୍ସଜେଣ୍ଡର ବ୍ୟକ୍ତି ଭାବରେ ଜନ୍ମ ହେବା ମୋର ପସନ୍ଦ ନୁହେଁ, କିନ୍ତୁ ଜଣେ ମହିଳା ଭାବରେ ରୂପାନ୍ତରିତ ହେବା | ଗ୍ରାହକଙ୍କ ଚିନ୍ତା କେବଳ ଉତ୍ପାଦ ଏବଂ ମୋର ପରିଚୟ ନୁହେଁ | ସର୍ବଭାରତୀୟ ସ୍ତରରେ ସଂଗଠନଗୁଡ଼ିକର ଦୃଢ ବିବିଧତା ଏବଂ ଅନ୍ତର୍ଭୁକ୍ତୀକରଣ ନୀତି ରହିଛି, ମୁଁ ଭାବୁନାହିଁ ଯେ ସେମାନେ ଏଥିରେ ଧ୍ୟାନ ଦେବେ |",
              "as": "এজন ট্ৰেন্সজেণ্ডাৰ ব্যক্তি হিচাপে জন্ম হোৱাটো মোৰ পছন্দ নহয়, কিন্তু এতিয়া এগৰাকী মহিলালৈ পৰিৱৰ্তন হৈছো। গ্ৰাহকৰ চিন্তা কেৱল সামগ্ৰী আৰু মোৰ পৰিচয় নহয়। বিশ্বব্যাপী প্ৰতিষ্ঠানবোৰৰ শক্তিশালী বৈচিত্ৰ্য আৰু অন্তৰ্ভুক্তি নীতি আছে, মই নাভাবো যে তেওঁলোকে বেয়া পাব।",
              "gu": "ટ્રાન્સલિંગ વ્યક્તિ તરીકે જન્મ લેવો એ મારી પસંદગી નથી, પરંતુ સ્ત્રીમાં સંક્રમણ એ મારી પસંદગી છે.  ગ્રાહકોની ચિંતા માત્ર ઉત્પાદનની છે અને મારી ઓળખ નથી.  વૈશ્વિક સ્તરે સંસ્થાઓ મજબૂત વિવિધતા અને ઈન્કુસિવ નીતિઓ ધરાવે છે, મને નથી લાગતું કે તેઓને કોઈ વાંધો હશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "You have to understand, Asmita, these are important projects for the company. Not being on the team gives us time to close the deal with the client while also figuring out how best to include you in these conversations. If the clients say something, I don’t want you to be responsible for losing a project.",
              "hi": "आपको समझना होगा, अस्मिता, ये कंपनी के लिए महत्वपूर्ण प्रोजेक्ट हैं। टीम में नहीं होने से हमें ग्राहक के साथ व्यापार को पूरा करने का समय मिलता है, साथ ही यह भी पता चलता है कि इन वार्तालापों में आपको कैसे शामिल किया जाए। यदि ग्राहक कुछ कहते हैं, तो मैं नहीं चाहता कि आप किसी प्रोजेक्ट को खोने के लिए जिम्मेदार हों।",
              "ka": "ನೀವು ಅರ್ಥ ಮಾಡಿಕೊಳ್ಳಬೇಕು ಅಸ್ಮಿತಾ, ಇವು ಕಂಪನಿಯ ಪ್ರಮುಖ ಯೋಜನೆಗಳು. ತಂಡದಲ್ಲಿ ಇಲ್ಲದಿರುವುದು ಕ್ಲೈಂಟ್‌ನೊಂದಿಗಿನ ಒಪ್ಪಂದವನ್ನು ಮುಚ್ಚಲು ನಮಗೆ ಸಮಯವನ್ನು ನೀಡುತ್ತದೆ ಮತ್ತು ಈ ಸಂಭಾಷಣೆಗಳಲ್ಲಿ ನಿಮ್ಮನ್ನು ಹೇಗೆ ಸೇರಿಸುವುದು ಉತ್ತಮ ಎಂದು ಲೆಕ್ಕಾಚಾರ ಮಾಡುತ್ತದೆ. ಗ್ರಾಹಕರು ಏನನ್ನಾದರೂ ಹೇಳಿದರೆ, ಯೋಜನೆಯನ್ನು ಕಳೆದುಕೊಳ್ಳಲು ನೀವು ಜವಾಬ್ದಾರರಾಗಿರಲು ನಾನು ಬಯಸುವುದಿಲ್ಲ.",
              "te": "మీరు అర్థం చేసుకోవాలి అస్మితా, ఇవి కంపెనీకి ముఖ్యమైన ప్రాజెక్ట్‌లు. టీం లో లేకపోవడం క్లయింట్‌తో డీల్ క్లోస్ చేయడానికి మాకు సమయం ఇస్తుంది, మరియు ఈ సంభాషణలలో మిమ్మల్ని ఎలా చేర్చుకోవాలి అనే ఆలోచన కూడా ఉంటుంది. క్లయింట్లు ఏమైనా అంటే, ప్రాజెక్ట్‌ను కోల్పోవడానికి మీరు బాధ్యత వహించాలని నేను అనుకోవడం లేదు.",
              "or": "ତୁମକୁ ବୁଝିବାକୁ ପଡିବ, ଅସ୍ମିତା, କମ୍ପାନୀ ପାଇଁ ଏଗୁଡିକ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ପ୍ରୋଜେକ୍ଟ | ଦଳରେ ନ ରହିବା ଆମକୁ ଗ୍ରାହକ ସହିତ ଡିଲ୍ ବନ୍ଦ କରିବାକୁ ସମୟ ଦେଇଥାଏ ଏବଂ ଏହି ବାର୍ତ୍ତାଳାପରେ ତୁମକୁ କିପରି ଅନ୍ତର୍ଭୂକ୍ତ କରାଯିବ ତାହା ମଧ୍ୟ ଅନୁଧ୍ୟାନ କରେ | ଯଦି ଗ୍ରାହକମାନେ କିଛି କୁହନ୍ତି, ମୁଁ ଚାହେଁ ନାହିଁ ଯେ ତୁମେ ଏକ ପ୍ରୋଜେକ୍ଟ ହରାଇବା ପାଇଁ ଦାୟୀ ହୁଅ |",
              "as": "আপুনি বুজিব লাগিব, অস্মিতা, এইবোৰ কোম্পানীৰ বাবে গুৰুত্বপূৰ্ণ প্ৰকল্প। দলত নাথাকিলে আমাক গ্ৰাহকৰ সৈতে চুক্তিটো সমাপ্ত কৰিবলৈ সময় দিয়ে আৰু লগতে এই কথা-বতৰাবোৰত আপোনাক কেনেদৰে সৰ্বশ্ৰেষ্ঠভাৱে অন্তৰ্ভুক্ত কৰিব লাগে সেইটোও বিচাৰি উলিয়ায়। যদি গ্ৰাহকসকলে কিবা কয়, মই নিবিচাৰো যে প্ৰকল্প এটা হেৰুওৱাৰ বাবে আপুনি দায়বদ্ধ হওক।",
              "gu": "તમારે સમજવું પડશે, અસ્મિતા, આ કંપની માટે મહત્વપૂર્ણ પ્રોજેક્ટ છે.  ટીમમાં ન હોવાને કારણે અમને ક્લાયંટ સાથેનો સોદો પૂર્ણ કરવાનો સમય મળે છે જ્યારે તમને આ વાર્તાલાપમાં કેવી રીતે શ્રેષ્ઠ રીતે સામેલ કરવું તે પણ શોધી શકાય છે.  જો ગ્રાહકો કંઈક કહે છે, તો હું નથી ઈચ્છતો કે તમે પ્રોજેક્ટ ગુમાવવા માટે જવાબદાર બનો."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "D5_4_5_D",
              "hi": "D5_4_5_D",
              "ka": "D5_4_5_D",
              "te": "D5_4_5_D",
              "or": "D5_4_5_D",
              "as": "D5_4_5_D",
              "gu": "D5_4_5_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "What should Asmita say?",
              "hi": "अस्मिता को क्या कहना चाहिए?",
              "ka": "ಅಸ್ಮಿತಾ ಏನು ಹೇಳಬೇಕು?",
              "te": "అస్మిత ఏం చెప్పాలి?",
              "or": "ଅସ୍ମିତା କ’ଣ କହିବା ଉଚିତ୍?",
              "as": "অস্মিতাই কি কোৱা উচিত?",
              "gu": "અસ્મિતાને શું કહેવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "You know my abilities and work ethics. Even our client does. If you are being inclusive, even they will be. It is your chance to showcase our organisation’s stand on the LGBTQ+ community.",
              "hi": "आप मेरी क्षमताओं और कार्य नैतिकता को जानते हैं। यहां तक ​​कि हमारा ग्राहक भी जानता है। यदि आप समावेशी हैं, तो वे भी होंगे। एलजीबीटीक्यू+ समुदाय पर हमारे संगठन के रुख को प्रदर्शित करने का यह आपके लिए अवसर है।",
              "ka": "ನನ್ನ ಸಾಮರ್ಥ್ಯಗಳು ಮತ್ತು ಕೆಲಸದ ನೀತಿಗಳು ನಿಮಗೆ ತಿಳಿದಿದೆ. ನಮ್ಮ ಕ್ಲೈಂಟ್ ಕೂಡ ತಿಳಿದ್ದಿದಾರೆ. ನೀವು ಅಂತರ್ಗತವಾಗಿದ್ದರೆ, ಅವರು ಕೂಡ ಆಗಿರುತ್ತಾರೆ. LGBTQ+ ಸಮುದಾಯದಲ್ಲಿ ನಮ್ಮ ಸಂಸ್ಥೆಯ ನಿಲುವನ್ನು ಪ್ರದರ್ಶಿಸಲು ಇದು ನಿಮ್ಮ ಅವಕಾಶವಾಗಿದೆ.",
              "te": "నా సామర్థ్యాలు మరియు పనితీరు మీకు తెలుసు. మన క్లయింట్ కి కూడా తెలుసు. మీరు కలుపుకొని ఉంటే, వారు కూడా అలానే ఉంటారు. LGBTQ+ సంఘంలో మన సంస్థ యొక్క స్టాండ్‌ను ప్రదర్శించడానికి ఇది మీకు అవకాశం.",
              "or": "ତୁମେ ମୋର ଦକ୍ଷତା ଏବଂ କାର୍ଯ୍ୟ ର ତିକତା ଜାଣିଛ | ଆମର କ୍ଲାଏଣ୍ଟ ମଧ୍ୟ ଜାଣିଛନ୍ତି | ଯଦି ତୁମେ ଅନ୍ତର୍ଭୂକ୍ତ ହେଉଛ, ସେ୍ମାନେ ମଧ୍ୟ ହେବେ | LGBTQ + ସମ୍ପ୍ରଦାୟରେ ଆମର ସଂଗଠନର ସ୍ଥିତି ପ୍ରଦର୍ଶନ କରିବା ତୁମ ପାଇଁ ଏକ ସୁଯୋଗ |",
              "as": "আপুনি মোৰ সামৰ্থ্য আৰু কৰ্ম নৈতিকতা জানে। আনকি আমাৰ গ্ৰাহকেও জানে। যদি আপুনি অন্তৰ্ভুক্ত হৈ থাকে, তেওঁলোকো থাকিব। এইয়া আপোনাৰ বাবে LGBTQ+ সম্প্ৰদায়ৰ ওপৰত আমাৰ প্ৰতিষ্ঠানৰ স্থিতি প্ৰদৰ্শন কৰাৰ সুযোগ হ'ব।",
              "gu": "તમે મારી ક્ષમતાઓ અને કાર્ય નીતિશાસ્ત્ર જાણો છો.  આપણા ક્લાયન્ટ જાણે છે.  જો તમે ઈન્કુસિવ છો, તો તેઓ પણ હશે.  LGBTQ+ સમુદાય પર આપણી સંસ્થાનું વલણ દર્શાવવાની તમારી તક છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "I understand the risk. But I’d like you to treat me the same way in terms of ability and give me other projects to handle.",
              "hi": "मैं जोखिम को समझती हूं। लेकिन मैं चाहती हूं कि आप मेरे साथ क्षमता के मामले में भी ऐसा ही व्यवहार करें और मुझे संभालने के लिए अन्य प्रोजेक्ट दें।",
              "ka": "ನಾನು ಅಪಾಯವನ್ನು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೇನೆ. ಆದರೆ ಸಾಮರ್ಥ್ಯದ ವಿಷಯದಲ್ಲಿ ನೀವು ನನ್ನನ್ನು ಅದೇ ರೀತಿ ಪರಿಗಣಿಸಬೇಕೆಂದು ಮತ್ತು ನಿರ್ವಹಿಸಲು ಇತರ ಯೋಜನೆಗಳನ್ನು ನನಗೆ ನೀಡಬೇಕೆಂದು ನಾನು ಬಯಸುತ್ತೇನೆ.",
              "te": "నేను రిస్క్ ని అర్థం చేసుకున్నాను. కానీ మీరు సామర్థ్యం విషయంలో నన్ను అదే విధంగా చూడాలని మరియు ఇతర ప్రాజెక్ట్‌లను ఇవ్వాలని నేను కోరుకుంటున్నాను.",
              "or": "ମୁଁ ବିପଦକୁ ବୁଝିପାରୁଛି | କିନ୍ତୁ ମୁଁ ଚାହେଁ ତୁମେ ଦକ୍ଷତା ଦୃଷ୍ଟିରୁ ମୋତେ ସମାନ ବ୍ୟବହାର କର ଏବଂ ପରିଚାଳନା କରିବାକୁ ମୋତେ ଅନ୍ୟ ପ୍ରୋଜେକ୍ଟ ଦିଅ |",
              "as": "মই বিপদাশংকাটো বুজি পাওঁ। কিন্তু মই বিচাৰোঁ যে আপুনি মোক সামৰ্থ্যৰ ক্ষেত্ৰত একে ধৰণে ব্যৱহাৰ কৰক আৰু মোক পৰিচালনা কৰিবলৈ আন প্ৰকল্প দিয়ক।",
              "gu": "હું જોખમ સમજું છું.  પરંતુ હું ઈચ્છું છું કે તમે મારી સાથે ક્ષમતાના સંદર્ભમાં સમાન રીતે વર્તો અને મને હેન્ડલ કરવા માટે અન્ય પ્રોજેક્ટ્સ આપો."
            }
          }
        ]
      },
      "scene32": {
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "Asking for old projects",
              "hi": "पुराने प्रोजेक्ट्स के लिए पूछना ",
              "ka": "ಹಳೆಯ ಯೋಜನೆಗಳನ್ನು ಕೇಳುತ್ತಿದ್ದಾರೆ",
              "te": "పాత ప్రాజెక్టులు అడగడం",
              "or": "ପୁରୁଣା ପ୍ରୋଜେକ୍ଟ ପାଇଁ ପଚାରିବା",
              "as": "পুৰণি প্ৰকল্পবিলাকৰ বিষয়ে সুধিছে",
              "gu": "જૂના પ્રોજેક્ટ માંગવા"
            }
          }
        ],
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6i_1_1",
              "hi": "D6i_1_1",
              "ka": "D6i_1_1",
              "te": "D6i_1_1",
              "or": "D6i_1_1",
              "as": "D6i_1_1",
              "gu": "D6i_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After her conversation with the project lead, she gets one of her old projects.",
              "hi": "प्रोजेक्ट लीड के साथ बातचीत के बाद, उसे अपना एक पुराना प्रोजेक्ट मिलता है।",
              "ka": "ಪ್ರಾಜೆಕ್ಟ್ ಲೀಡ್‌ನೊಂದಿಗಿನ ಸಂಭಾಷಣೆಯ ನಂತರ, ಅವಳು ತನ್ನ ಹಳೆಯ ಯೋಜನೆಗಳಲ್ಲಿ ಒಂದನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
              "te": "ప్రాజెక్ట్ లీడ్‌తో ఆమె సంభాషణ తర్వాత, ఆమె తన పాత ప్రాజెక్ట్‌లలో ఒకదాన్ని పొందుతుంది.",
              "or": "ପ୍ରୋଜେକ୍ଟ ଲିଡ୍ ସହିତ ବାର୍ତ୍ତାଳାପ ପରେ, ସେ ତାଙ୍କର ଏକ ପୁରାତନ ପ୍ରୋଜେକ୍ଟ ପାଇଲେ |",
              "as": "মুখ্য প্ৰকল্পকাৰৰ সৈতে তাইৰ আলোচনাৰ পিছত, তাই তাইৰ এটা পুৰণি প্ৰকল্প পায়।",
              "gu": "પ્રોજેક્ટ લીડ સાથે તેણીની વાતચીત પછી, તેણીને પોતાનો એક જૂનો પ્રોજેક્ટ મળે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6i_1_2",
              "hi": "D6i_1_2",
              "ka": "D6i_1_2",
              "te": "D6i_1_2",
              "or": "D6i_1_2",
              "as": "D6i_1_2",
              "gu": "D6i_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The project lead writes an email to the clients, introducing Asmita.",
              "hi": "प्रोजेक्ट लीड अस्मिता का परिचय देते हुए ग्राहकों को एक ईमेल लिखता है।",
              "ka": "ಪ್ರಾಜೆಕ್ಟ್ ಲೀಡ್ ಅಸ್ಮಿತಾ ಅವರನ್ನು ಪರಿಚಯಿಸಲು ಕ್ಲೈಂಟ್‌ಗಳಿಗೆ ಇಮೇಲ್ ಬರೆಯುತ್ತಾರೆ.",
              "te": "ప్రాజెక్ట్ లీడ్ అస్మితను పరిచయం చేస్తూ క్లయింట్‌లకు ఇమెయిల్ వ్రాసింది.",
              "or": "ଅସ୍ମିତା",
              "as": "মুখ্য প্ৰকল্পকাৰে অস্মিতাৰ পৰিচয় দি, গ্ৰাহকসকললৈ এটা ইমেইল লিখিছে।",
              "gu": "પ્રોજેક્ટ લીડ ગ્રાહકો ને ઈમેલ લખે છે, જેમાં અસ્મિતાનો પરિચય થાય છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6i_1_3",
              "hi": "D6i_1_3",
              "ka": "D6i_1_3",
              "te": "D6i_1_3",
              "or": "D6i_1_3",
              "as": "D6i_1_3",
              "gu": "D6i_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "When she interacts with the clients, she realises that the same people who were so warm and friendly with her now seem cold and less approachable.",
              "hi": "जब वह ग्राहकों के साथ बातचीत करती है, तो उसे पता चलता है कि वही लोग जो उसके साथ इतने नर्म और मिलनसार थे, अब उनका व्यवहार बदल गया है।",
              "ka": "ಅವಳು ಗ್ರಾಹಕರೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸಿದಾಗ, ಅವಳೊಂದಿಗೆ ತುಂಬಾ ಬೆಚ್ಚಗಿರುವ ಮತ್ತು ಸ್ನೇಹಪರರಾಗಿದ್ದ ಅದೇ ಜನರು ಈಗ ತಣ್ಣಗಾಗುತ್ತಾರೆ ಮತ್ತು ಕಡಿಮೆ ಸಂಪರ್ಕಕ್ಕೆ ಬರುತ್ತಾರೆ ಎಂದು ಅವಳು ಅರಿತುಕೊಂಡಳು.",
              "te": "ఆమె క్లయింట్‌లతో సంభాషించినప్పుడు, తనతో చాలా స్నేహపూర్వకంగా ఉన్న అదే వ్యక్తులు ఇప్పుడు తక్కువగా చూస్తున్నారని ఆమె గ్రహిస్తుంది.",
              "or": "ଯେତେବେଳେ ସେ କ୍ଲାଏଣ୍ଟମାନଙ୍କ ସହିତ କଥାବାର୍ତ୍ତା କରନ୍ତି, ସେତେବେଳେ ସେ ହୃଦୟଙ୍ଗମ କରନ୍ତି ଯେ ସେହି ସମାନ ବ୍ୟକ୍ତି ଯେଉଁମାନେ ତାଙ୍କ ସହ ଏତେ ନିକଟତର ଏବଂ ବନ୍ଧୁତ୍ୱପୂର୍ଣ୍ଣ ଥିଲେ ସେମାନେ ବର୍ତ୍ତମାନ ନରମ ଏବଂ କମ୍ ନିକଟତର ହୁଅନ୍ତି |",
              "as": "যেতিয়া তাই গ্ৰাহকৰ সৈতে বাৰ্তালাপ কৰে, তাই অনুভৱ কৰে যে তাইৰ সৈতে ইমান উষ্ণ আৰু বন্ধুত্বপূৰ্ণ একেজন লোক এতিয়া ঠাণ্ডা আৰু কম উপলব্ধ যেন লাগিছে।",
              "gu": "જ્યારે તે ગ્રાહકો સાથે વાતચીત કરે છે, ત્યારે તેને ખ્યાલ આવે છે કે જે લોકો તેની સાથે ખૂબ જ ઉષ્માભર્યા અને મૈત્રીપૂર્ણ વ્યવહાર કરતાં હતા તે જ લોકો હવે ઠંડા અને ઓછા સંપર્કમાં આવવા લાગ્યા  છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6i_1_4",
              "hi": "D6i_1_4",
              "ka": "D6i_1_4",
              "te": "D6i_1_4",
              "or": "D6i_1_4",
              "as": "D6i_1_4",
              "gu": "D6i_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The deal still takes place, but she senses tension from the clients. The project lead does her best to act casual.",
              "hi": "सौदा अभी भी होता है, लेकिन वह ग्राहकों से तनाव अनुभव करती है। प्रोजेक्ट प्रमुख अनौपचारिक अभिनय की पूरी कोशिश करता है।",
              "ka": "ಒಪ್ಪಂದವು ಇನ್ನೂ ನಡೆಯುತ್ತದೆ, ಆದರೆ ಅವಳು ಗ್ರಾಹಕರಿಂದ ಉದ್ವೇಗವನ್ನು ಅನುಭವಿಸುತ್ತಾಳೆ. ಪ್ರಾಜೆಕ್ಟ್ ಲೀಡ್ ಕ್ಯಾಶುಯಲ್ ಆಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸಲು ತನ್ನ ಕೈಲಾದಷ್ಟು ಮಾಡುತ್ತದೆ.",
              "te": "ఒప్పందం ఇప్పటికీ జరుగుతుంది, కానీ ఆమె క్లైంట్స్ లో టెన్షన్ గమనిస్తుంది. ప్రాజెక్ట్ లీడ్ క్యాజువల్ గా నటించడానికి తన వంతు కృషి చేస్తుంది.",
              "or": "ଚୁକ୍ତି ଏପର୍ଯ୍ୟନ୍ତ ଚାଲିଛି, କିନ୍ତୁ ସେ ଗ୍ରାହକଙ୍କଠାରୁ କଷ୍ଟ ଅନୁଭବ କରୁଛନ୍ତି | ସାଧାରଣ ଭାବରେ ଅଭିନୟ କରିବାକୁ ପ୍ରୋଜେକ୍ଟ ଲିଡ୍ ଯଥାସମ୍ଭବ ଚେଷ୍ଟା କରେ |",
              "as": "চুক্তিটো এতিয়াও হয়, কিন্তু তাই গ্ৰাহকৰ পৰা উত্তেজনা অনুভৱ কৰে। মুখ্য প্ৰকল্পকাৰে নৈমিত্তিক অভিনয় কৰিবলৈ যথাসাধ্য চেষ্টা কৰে।",
              "gu": "ડીલ્સ હજુ પણ થાય છે, પરંતુ તેણીને ગ્રાહકો તરફથી તણાવ અનુભવાય છે.  પ્રોજેક્ટ લીડ સામાન્ય વ્યવહાર કરવા શ્રેષ્ઠ પ્રયાસો કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6i_2_1//",
              "hi": "D6i_2_1 //",
              "ka": "D6i_2_1//",
              "te": "D6i_2_1//",
              "or": "D6i_2_1//",
              "as": "D6i_2_1//",
              "gu": "D6i_2_1//"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "In the next support group session, she shares her experience.",
              "hi": "अगले समर्थन समूह सत्र में, वह अपना अनुभव साझा करती है।",
              "ka": "ಮುಂದಿನ ಬೆಂಬಲ ಗುಂಪಿನ ಅಧಿವೇಶನದಲ್ಲಿ, ಅವಳು ತನ್ನ ಅನುಭವವನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "తదుపరి సపోర్ట్ గ్రూప్ సెషన్‌లో, ఆమె తన అనుభవాన్ని పంచుకుంది.",
              "or": "ପରବର୍ତ୍ତୀ ସମର୍ଥନ ଗୋଷ୍ଠୀ ଅଧିବେଶନରେ, ସେ ତାଙ୍କ ଅଭିଜ୍ଞତା ଅଂଶୀଦାର କରିଛନ୍ତି|",
              "as": "পৰৱৰ্তী সমৰ্থন গোট অধিৱেশনত, তাই তাইৰ অভিজ্ঞতা বোৰৰ বিষয়ে কয়।",
              "gu": "આગામી સપોર્ટ ગ્રૂપ સત્રમાં, તેણીએ પોતાનો અનુભવ શેર કર્યો ."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Vandana",
              "hi": "वंदना",
              "ka": "ವಂದನಾ",
              "te": "వందన",
              "or": "ଭନ୍ଦନା",
              "as": "বন্দনা",
              "gu": "વંદના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Asmita",
              "hi": "अस्मिता",
              "ka": "ಅಸ್ಮಿತಾ",
              "te": "అస్మిత",
              "or": "ଅସ୍ମିତା",
              "as": "অস্মিতা",
              "gu": "અસ્મિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Asmita, would you like to share?",
              "hi": "अस्मिता, क्या आप साझा करना चाहेंगी?",
              "ka": "ಅಸ್ಮಿತಾ, ನೀವು ಹಂಚಿಕೊಳ್ಳಲು ಬಯಸುವಿರಾ?",
              "te": "అస్మిత, మీరు ఏదైనా చెప్పాలనుకుంటున్నారా?",
              "or": "ଅସ୍ମିତା, ତୁମେ ଅଂଶୀଦାର କରିବାକୁ ଚାହଁ କି?",
              "as": "অস্মিতা, আপুনি কিবা ক'ব বিচাৰে নেকি?",
              "gu": "અસ્મિતા, તમે શેર કરવા માંગો છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "It has been a difficult few weeks, with many happy and sad moments. But something that bothered me was how I lost so many of the projects I was enjoying working on.",
              "hi": "कई सुखद और दुखद क्षणों के साथ यह कुछ सप्ताह कठिन रहे हैं। लेकिन जिस चीज ने मुझे परेशान किया, वह यह थी कि मैंने उन कई प्रोजेक्ट्स को खो दिया, जिन पर मुझे काम करने में अच्छा लग रहा था।",
              "ka": "ಇದು ಕಷ್ಟಕರವಾದ ಕೆಲವು ವಾರಗಳು, ಅನೇಕ ಸಂತೋಷ ಮತ್ತು ದುಃಖದ ಕ್ಷಣಗಳು. ಆದರೆ ನಾನು ಕೆಲಸ ಮಾಡುತ್ತಿರುವ ಅನೇಕ ಯೋಜನೆಗಳನ್ನು ನಾನು ಹೇಗೆ ಕಳೆದುಕೊಂಡೆ ಎಂಬುದು ನನಗೆ ಬೇಸರ ತಂದಿದೆ.",
              "te": "చాలా సంతోషకరమైన మరియు విచారకరమైన క్షణాలతో కొన్ని వారాలు కష్టంగా గడిచాయి. కానీ నాకు ఇబ్బంది కలిగించే విషయం ఏమిటంటే, నేను పని చేస్తున్న చాలా ప్రాజెక్ట్‌లను నేను ఎలా కోల్పోయాను.",
              "or": "ଅନେକ ଖୁସି ଏବଂ ଦୁଃଖଦ ମୁହୂର୍ତ୍ତ ସହିତ ଏହା କିଛି ସପ୍ତାହ କଷ୍ଟସାଧ୍ୟ ହୋଇଛି | କିନ୍ତୁ କିଛି ଜିନିଷ ମୋତେ ବ୍ୟଥିତ କଲା,ଯେଉଁଥିରେ ମୁଁ ଅନେକ ପ୍ରୋଜେକ୍ଟକୁ ହରାଇଲି, ଯେଉଁଥିରେ ମୁଁ କାମ କରିବାକୁ ଉପଭୋଗ କରୁଥିଲି |",
              "as": "বহুতো সুখ আৰু দুখৰ মুহূৰ্তৰ সৈতে, কেইসপ্তাহমান বৰ কঠিন সময় পাৰ কৰিলো। কিন্তু মোক বিচলিত কৰা এনে কিবা এটা হ'ল যাৰ কাৰণে মই বহুতো প্ৰকল্প হেৰুৱাইছিলো য'ত মই কাম কৰি ভাল পাইছিলো।",
              "gu": "ઘણા સુખીના અને ઉદાસીના ક્ષણો વાળા, આ મુશ્કેલ થોડા અઠવાડિયાઓ હતા.  પરંતુ મને પરેશાન કરતી બાબત એ હતી કે મેં કેટલા બધા પ્રોજેક્ટ્સ ગુમાવ્યા જેના પર હું કામ કરવાનો આનંદ માણી રહી હતી."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6i_2_2////",
              "hi": "D6i_2_2 /////",
              "ka": "D6i_2_2////",
              "te": "D6i_2_2////",
              "or": "D6i_2_2////",
              "as": "D6i_2_2////",
              "gu": "D6i_2_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "You lost the projects?",
              "hi": "आपने प्रोजेक्ट्स को खो दिया?",
              "ka": "ನೀವು ಯೋಜನೆಗಳನ್ನು ಕಳೆದುಕೊಂಡಿದ್ದೀರಾ?",
              "te": "ప్రాజెక్టులు పోగొట్టుకున్నారా?",
              "or": "ତୁମେ ପ୍ରୋଜେକ୍ଟ ଗୁଡିକ ହରାଇଛ କି?",
              "as": "আপুনি প্ৰকল্পবোৰ হেৰুৱালে?",
              "gu": "તમે પ્રોજેક્ટ્સ ગુમાવ્યા?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, they have been hesitant to let me interact with clients ever since my transition. I convinced my lead to give me one of those projects. But now I feel that maybe their decision of not giving me old projects might have made a lot more sense.",
              "hi": "हां, वे मेरे ट्रांजिशन के बाद से ग्राहकों के साथ बातचीत करने में संकोच कर रहे हैं। मैंने अपने टीम लीड को उन प्रोजेक्ट्स में से एक देने के लिए आश्वस्त किया। लेकिन अब मुझे लगता है कि शायद मुझे पुराने प्रोजेक्ट्स को न देने का उनका निर्णय बहुत अधिक समझ में आ सकता है।",
              "ka": "ಹೌದು, ನನ್ನ ಸ್ಥಿತ್ಯಂತರದಿಂದಲೂ ಗ್ರಾಹಕರೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸಲು ನನಗೆ ಅವಕಾಶ ನೀಡಲು ಅವರು ಹಿಂಜರಿಯುತ್ತಿದ್ದಾರೆ. ಆ ಯೋಜನೆಗಳಲ್ಲಿ ಒಂದನ್ನು ನನಗೆ ನೀಡಲು ನನ್ನ ಮುಂದಾಳತ್ವವನ್ನು ನಾನು ಮನವರಿಕೆ ಮಾಡಿದೆ. ಆದರೆ ಹಳೆಯ ಪ್ರಾಜೆಕ್ಟ್‌ಗಳನ್ನು ನನಗೆ ನೀಡದಿರುವ ಅವರ ನಿರ್ಧಾರವು ಹೆಚ್ಚು ಅರ್ಥಪೂರ್ಣವಾಗಿರಬಹುದು ಎಂದು ಈಗ ನಾನು ಭಾವಿಸುತ್ತೇನೆ.",
              "te": "అవును, నా పరివర్తన నుండి క్లయింట్‌లతో పరస్పర చర్య చేయడానికి నన్ను అనుమతించడానికి వారు వెనుకాడుతున్నారు. ఆ ప్రాజెక్ట్‌లలో ఒకదానిని నాకు ఇవ్వడానికి నా లీడ్‌ని ఒప్పించాను. కానీ ఇప్పుడు పాత ప్రాజెక్ట్‌లను ఇవ్వకూడదనే వారి నిర్ణయం సరైనది అని నేను భావిస్తున్నాను.",
              "or": "ହଁ, ମୋର ପରିବର୍ତ୍ତନ ପରଠାରୁ ମୋତେ ଗ୍ରାହକମାନଙ୍କ ସହିତ ଯୋଗାଯୋଗ କରିବାକୁ ସେମାନେ ଦ୍ୱିଧାବୋଧ କରନ୍ତି | ମୋତେ ସେହି ପ୍ରୋଜେକ୍ଟଗୁଡ଼ିକ ମଧ୍ୟରୁ ଗୋଟିଏ ଦେବା ପାଇଁ ମୋର ନେତୃତ୍ୱକୁ ନିଶ୍ଚିତ କରିଥିଲି | କିନ୍ତୁ ବର୍ତ୍ତମାନ ମୁଁ ଅନୁଭବ କରୁଛି ଯେ ବୋଧହୁଏ ମୋତେ ପୁରୁଣା ପ୍ରୋଜେକ୍ଟ ନ ଦେବାର ନିଷ୍ପତ୍ତି ହୁଏତ ମୋତେ ଅଧିକ ଲାଭ ଦେଇପାରେ |",
              "as": "হয়, তেওঁলোকে মোৰ পৰিৱৰ্তনৰ পিছৰে পৰা মোক গ্ৰাহকৰ সৈতে বাৰ্তালাপ কৰিবলৈ দিবলৈ সংকোচ বোধ কৰি আহিছে। মই মোক সেই প্ৰকল্পবোৰৰ এটা দিবলৈ মোৰ মুখ্য প্ৰকল্পকাৰক নিশ্চিত কৰিছিলো। কিন্তু এতিয়া মই অনুভৱ কৰোঁ যে হয়তো তেওঁলোকৰ মোক পুৰণি প্ৰকল্প নিদিয়াৰ সিদ্ধান্তই হয়তো বহুত বেছি অৰ্থ বহন কৰিছে।",
              "gu": "હા, મારા ટ્રાન્સિશન થી તેઓ મને ગ્રાહકો સાથે વાર્તાલાપ કરવા દેતા અચકાતા હતા.  મને તેમાંથી એક પ્રોજેક્ટ આપવા માટે મેં મારા લીડને ખાતરી આપી.  પણ હવે મને લાગે છે કે કદાચ મને જૂના પ્રોજેક્ટ ન આપવાનો તેમનો નિર્ણય વધુ અર્થપૂર્ણ હોઈ શકે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "Why do you feel that way?",
              "hi": "आप ऐसा क्यों अनुभव करते हैं?",
              "ka": "ನಿಮಗೇಕೆ ಹಾಗೆ ಅನಿಸುತ್ತಿದೆ?",
              "te": "మీకు అలా ఎందుకు అనిపిస్తుంది?",
              "or": "ତୁମେ କାହିଁକି ଏପରି ଅନୁଭବ କରୁଛ ?",
              "as": "আপুনি কিয় এনেদৰে অনুভৱ কৰিছে?",
              "gu": "તમને એવું કેમ લાગે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "In the meeting with the clients, they were so uncomfortable while interacting with me. The project was still approved, but that's primarily because of how reliable the company is. But I felt like I had risked the deal by being who I am.",
              "hi": "ग्राहकों के साथ बैठक में, वे मेरे साथ बातचीत करते समय इतने असहज थे।  प्रोजेक्ट को अभी भी मंजूरी दी गई थी, लेकिन इसका मुख्य कारण यह है कि कंपनी कितनी विश्वसनीय है। लेकिन मुझे ऐसा लगा कि मैं जो हूं, वह बनकर मैंने इस सौदे को जोखिम में डाल दिया है।",
              "ka": "ಗ್ರಾಹಕರೊಂದಿಗಿನ ಸಭೆಯಲ್ಲಿ, ನನ್ನೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸುವಾಗ ಅವರು ತುಂಬಾ ಅನಾನುಕೂಲರಾಗಿದ್ದರು. ಯೋಜನೆಯನ್ನು ಇನ್ನೂ ಅನುಮೋದಿಸಲಾಗಿದೆ, ಆದರೆ ಅದು ಪ್ರಾಥಮಿಕವಾಗಿ ಕಂಪನಿಯು ಎಷ್ಟು ವಿಶ್ವಾಸಾರ್ಹವಾಗಿದೆ ಎಂಬ ಕಾರಣದಿಂದಾಗಿ. ಆದರೆ ನಾನು ನಾನಾಗಿಯೇ ಒಪ್ಪಂದವನ್ನು ಅಪಾಯಕ್ಕೆ ಸಿಲುಕಿಸಿದೆ ಎಂದು ನನಗೆ ಅನಿಸಿತು.",
              "te": "క్లయింట్లతో సమావేశంలో, వారు నాతో సంభాషించేటప్పుడు చాలా అసౌకర్యంగా ఉన్నారు. ప్రాజెక్ట్ ఇప్పటికీ ఆమోదించబడింది, కానీ అది ప్రాథమికంగా కంపెనీ విశ్వసనీయత వల్ల జరిగింది. కానీ నేను ఇలా ఉండటం ద్వారా డీల్‌ను రిస్క్ చేసినట్లు అనిపించింది.",
              "or": "ଗ୍ରାହକମାନଙ୍କ ସହିତ ସାକ୍ଷାତ ସମୟରେ, ସେମାନେ ମୋ ସହିତ କଥାବାର୍ତ୍ତା କରିବା ସମୟରେ ବହୁତ ଅସହଜ ଥିଲେ | ପ୍ରୋଜେକ୍ଟଟି ଏପର୍ଯ୍ୟନ୍ତ ଅନୁମୋଦିତ ହୋଇଥିଲା, କିନ୍ତୁ ଏହାର ମୁଖ୍ୟ କାରଣ ଏହାକି କମ୍ପାନୀ କେତେ ନିର୍ଭରଯୋଗ୍ୟ | କିନ୍ତୁ ମୁଁ ଅନୁଭବ କଲି ଯେ ମୁଁ ଯାହା ହେଉନା କାହିଁକି ଏହି ଡିଲକୁ ବିପଦରେ ପକାଇଛି |",
              "as": "গ্ৰাহকৰ সৈতে হোৱা আলোচনাত, তেওঁলোকে মোৰ সৈতে কথা পাতোতে ইমান অস্বস্তিবোধ কৰিছিল। প্ৰকল্পটো এতিয়াও অনুমোদিত হৈছে, কিন্তু ইয়াৰ মুখ্য কাৰণ হৈছে কোম্পানীটো কিমান নিৰ্ভৰযোগ্য। কিন্তু মই অনুভৱ কৰিছিলো যে মই মোৰ পৰিচয়ৰ দ্বাৰা চুক্তিটো কৰাত বিপদহে ঘটালোহেঁতেন।",
              "gu": "ગ્રાહકો સાથેની મીટિંગમાં, તેઓ મારી સાથે વાતચીત કરતી વખતે ખૂબ જ અસ્વસ્થતા અનુભવતા હતા.  પ્રોજેક્ટ હજુ પણ મંજૂર કરવામાં આવ્યો હતો, પરંતુ તે મુખ્યત્વે કંપની કેટલી વિશ્વસનીય છે તેના કારણે છે.  પરંતુ મને લાગ્યું કે હું જે છું તે બનીને મેં ડીલ જોખમમાં મૂક્યું હતું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "You have challenged many notions about transgender people being equal at workplaces. The journey will be tough, and you might have to fight and prove your abilities constantly. But remember, you are a trendsetter!",
              "hi": "आपने कार्यस्थलों पर ट्रांसजेंडर लोगों के समान होने के बारे में कई धारणाओं को चुनौती दी है। यात्रा कठिन होगी, और आपको लगातार अपनी क्षमताओं से लड़ना और साबित करना पड़ सकता है। लेकिन याद रखें, आप एक ट्रेंडसेटर हैं!",
              "ka": "ಟ್ರಾನ್ಸ್‌ಜೆಂಡರ್‌ಗಳು ಕೆಲಸದ ಸ್ಥಳಗಳಲ್ಲಿ ಸಮಾನರಾಗಿರುತ್ತಾರೆ ಎಂಬ ಹಲವು ಕಲ್ಪನೆಗಳನ್ನು ನೀವು ಪ್ರಶ್ನಿಸಿದ್ದೀರಿ. ಪ್ರಯಾಣವು ಕಠಿಣವಾಗಿರುತ್ತದೆ, ಮತ್ತು ನೀವು ನಿರಂತರವಾಗಿ ನಿಮ್ಮ ಸಾಮರ್ಥ್ಯಗಳನ್ನು ಹೋರಾಡಬೇಕು ಮತ್ತು ಸಾಬೀತುಪಡಿಸಬೇಕಾಗಬಹುದು. ಆದರೆ ನೆನಪಿಡಿ, ನೀವು ಟ್ರೆಂಡ್‌ಸೆಟರ್!",
              "te": "ట్రాన్స్ జెండర్ వ్యక్తులు పని ప్రదేశాలలో సమానంగా ఉండాలనే అనేక భావనలను మీరు సవాలు చేసారు. ప్రయాణం కఠినంగా ఉంటుంది మరియు మీరు నిరంతరం పోరాడవలసి ఉంటుంది మరియు మీ సామర్థ్యాలను నిరూపించుకోవాలి. కానీ గుర్తుంచుకోండి, మీరు ట్రెండ్‌సెట్టర్‌!",
              "or": "ଟ୍ରାନ୍ସଜେଣ୍ଡର ଲୋକ କର୍ମକ୍ଷେତ୍ରରେ ସମାନ ହେବା ବିଷୟରେ ଆପଣ ଅନେକ ଧାରଣାକୁ ଚ୍ୟାଲେଞ୍ଜ କରିଛନ୍ତି | କରିବା କଠିନ ହେବ, ଏବଂ ତୁମକୁ ଲଗାତାର ଯୁଦ୍ଧ କରିବାକୁ ପଡିବ ଏବଂ ତୁମର ଦକ୍ଷତା ପ୍ରମାଣ କରିବାକୁ ପଡିବ | କିନ୍ତୁ ମନେରଖ, ତୁମେ ଏକ ଟ୍ରେଣ୍ଡସେଟର!",
              "as": "আপুনি কৰ্মক্ষেত্ৰত ট্ৰেন্সজেণ্ডাৰ লোকসকল সমান হোৱাৰ বিষয়ে বহুতো ধাৰণাক প্ৰত্যাহ্বান জনাইছে। যাত্ৰাটো কঠিন হ'ব, আৰু আপুনি অহৰহ যুঁজ দিব লাগিব আৰু আপোনাৰ সামৰ্থ্য প্ৰমাণ কৰিব লাগিব। কিন্তু মনত ৰাখিব, আপুনি এজন ট্ৰেণ্ডছেটাৰ!",
              "gu": "તમે કાર્યસ્થળો પર ટ્રાન્સલિંગ લોકો સમાન હોવાની ઘણી ધારણાઓને પડકારી છે.  પ્રવાસ કઠિન હશે, અને તમારે સતત લડવું પડશે અને તમારી ક્ષમતાઓને સાબિત કરવી પડશે.  પરંતુ યાદ રાખો, તમે ટ્રેન્ડસેટર છો!"
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6i_3_1",
              "hi": "D6i_3_1",
              "ka": "D6i_3_1",
              "te": "D6i_3_1",
              "or": "D6i_3_1",
              "as": "D6i_3_1",
              "gu": "D6i_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Over time, her clients and colleagues in the office become more accepting of her identity. She is acknowledged for her work and confidence, but challenges keep coming her way.",
              "hi": "समय के साथ, कार्यालय में उसके ग्राहक और सहकर्मी उसकी पहचान को स्वीकार करने लगते हैं। उसे उसके काम और आत्मविश्वास के लिए पहचाना जाता है, लेकिन चुनौतियां उसके रास्ते में आती रहती हैं।",
              "ka": "ಕಾಲಾನಂತರದಲ್ಲಿ, ಅವಳ ಗ್ರಾಹಕರು ಮತ್ತು ಕಚೇರಿಯಲ್ಲಿ ಸಹೋದ್ಯೋಗಿಗಳು ಅವಳ ಗುರುತನ್ನು ಹೆಚ್ಚು ಒಪ್ಪಿಕೊಳ್ಳುತ್ತಾರೆ. ಆಕೆಯ ಕೆಲಸ ಮತ್ತು ಆತ್ಮವಿಶ್ವಾಸಕ್ಕಾಗಿ ಅವಳು ಗುರುತಿಸಲ್ಪಟ್ಟಿದ್ದಾಳೆ, ಆದರೆ ಸವಾಲುಗಳು ಅವಳ ದಾರಿಯಲ್ಲಿ ಬರುತ್ತಲೇ ಇರುತ್ತವೆ.",
              "te": "కాలక్రమేణా, ఆఫీసులో ఆమె క్లయింట్లు మరియు సహోద్యోగులు ఆమె గుర్తింపును అంగీకరిస్తారు. ఆమె పని మరియు ఆత్మవిశ్వాసం వల్ల ఆమె గుర్తింపు పొందింది, కానీ ఆమెకు సవాళ్లు వస్తూనే ఉన్నాయి.",
              "or": "ସମୟ ସହିତ, ଅଫିସରେ ଥିବା ତାଙ୍କ ଗ୍ରାହକ ଏବଂ ସହକର୍ମୀମାନେ ତାଙ୍କ ପରିଚୟକୁ ଅଧିକ ଗ୍ରହଣ କରନ୍ତି | ସେ ନିଜ କାର୍ଯ୍ୟ ଏବଂ ଆତ୍ମବିଶ୍ୱାସ ପାଇଁ ସ୍ୱୀକୃତିପ୍ରାପ୍ତ, କିନ୍ତୁ ଚ୍ୟାଲେଞ୍ଜଗୁଡ଼ିକ ତାଙ୍କ ରାସ୍ତାରେ ଆସିବା ରହିବ |",
              "as": "সময়ৰ লগে লগে, অফিচত থকা তাইৰ গ্ৰাহক আৰু সহকৰ্মীসকলে তাইৰ পৰিচয় অধিক গ্ৰহণ কৰে। তাইক তাইৰ কাম আৰু আত্মবিশ্বাসৰ বাবে স্বীকাৰ কৰা হয়, কিন্তু প্ৰত্যাহ্বানবোৰ তাইৰ পথলৈ আহি থাকে।",
              "gu": "સમય જતાં, ઓફિસમાં તેના ગ્રાહકો અને સહકર્મીઓ તેની ઓળખને વધુ સ્વીકારવા લાગ્યા.  તેણીના કામ અને આત્મવિશ્વાસ માટે તેણીની પ્રશંસા કરવામાં આવે છે, પરંતુ પડકારો તેના માર્ગે માં આવતા રહશે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6i_3_2",
              "hi": "D6I_3_2",
              "ka": "D6i_3_2",
              "te": "D6i_3_2",
              "or": "D6i_3_2",
              "as": "D6i_3_2",
              "gu": "D6i_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As soon as she steps outside the office, Asmita is teased and harassed by people in public spaces. She feels unsafe while travelling on public transport or walking home late in the evening.",
              "hi": "ऑफिस के बाहर कदम रखते ही अस्मिता को सार्वजनिक जगहों पर लोग चिढ़ाते और परेशान करते हैं। वह सार्वजनिक परिवहन में यात्रा करते समय या देर शाम को घर जाते समय असुरक्षित अनुभव करती है।",
              "ka": "ಕಚೇರಿಯಿಂದ ಹೊರಗೆ ಕಾಲಿಟ್ಟ ತಕ್ಷಣ ಅಸ್ಮಿತಾ ಅವರನ್ನು ಸಾರ್ವಜನಿಕ ಸ್ಥಳಗಳಲ್ಲಿ ಜನರು ಚುಡಾಯಿಸಿ ಕಿರುಕುಳ ನೀಡುತ್ತಾರೆ. ಸಾರ್ವಜನಿಕ ಸಾರಿಗೆಯಲ್ಲಿ ಪ್ರಯಾಣಿಸುವಾಗ ಅಥವಾ ಸಂಜೆ ತಡವಾಗಿ ಮನೆಗೆ ಹೋಗುವಾಗ ಅವಳು ಅಸುರಕ್ಷಿತ ಎಂದು ಭಾವಿಸುತ್ತಾಳೆ.",
              "te": "ఆఫీస్ బయటకి అడుగు పెట్టగానే అస్మితను పబ్లిక్ ప్లేస్‌లో ఉండే వాళ్ళు ఆటపట్టిస్తూ వేధించేవారు. పబ్లిక్ ట్రాన్స్‌పోర్ట్‌లో ప్రయాణిస్తున్నప్పుడు లేదా సాయంత్రం ఆలస్యంగా ఇంటికి నడిచేటప్పుడు ఆమెకు అసురక్షితంగా అనిపిస్తుంది.",
              "or": "ସେ ଅଫିସ୍ ବାହାରେ ପାଦ ଦେବା ମାତ୍ରେ ଅସ୍ମିତା ସର୍ବସାଧାରଣ ସ୍ଥାନରେ ଲୋକଙ୍କ ଦ୍ୱାରା ହଇରାଣ ହେଉଛନ୍ତି | ସର୍ବସାଧାରଣ ପରିବହନରେ ଯାତ୍ରା କରିବା ସମୟରେ କିମ୍ବା ସନ୍ଧ୍ୟାରେ ଘରକୁ ଯିବାବେଳେ ସେ ନିଜକୁ ଅସୁରକ୍ଷିତ ମନେ କରନ୍ତି |",
              "as": "তাই অফিচৰ বাহিৰত খোজ দিয়াৰ লগে লগে, অস্মিতাক ৰাজহুৱা স্থানত মানুহে জোকাইছিল আৰু হাৰাশাস্তি কৰিছিল। ৰাজহুৱা বাছত ঘূৰা-ফুৰা কৰোঁতে বা সন্ধিয়া পলমকৈ ঘৰলৈ খোজ কাঢ়ি থাকোঁতে তাই অসুৰক্ষিত অনুভৱ কৰে।",
              "gu": "ઓફિસની બહાર પગ મૂકતાની સાથે જ અસ્મિતાને જાહેર જગ્યાઓ પર લોકો ચીડવે છે અને હેરાન કરે છે.  તે જાહેર પરિવહન પર મુસાફરી કરતી વખતે અથવા મોડી સાંજે ઘરે ચાલતી વખતે અસુરક્ષિત અનુભવે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6i_3_3",
              "hi": "D6I_3_3",
              "ka": "D6i_3_3",
              "te": "D6i_3_3",
              "or": "D6i_3_3",
              "as": "D6i_3_3",
              "gu": "D6i_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "To avoid that, she buys a secondhand car to commute to the office as she wants to avoid public transport. Although it’s an expensive investment, she finds herself feeling at ease about travelling.",
              "hi": "इससे बचने के लिए, वह कार्यालय जाने के लिए एक पुरानी कार खरीदती है क्योंकि वह सार्वजनिक परिवहन से बचना चाहती है। हालांकि यह एक महंगा निवेश है, लेकिन वह यात्रा करने में खुद को सहज अनुभव करती है।",
              "ka": "ಅದನ್ನು ತಪ್ಪಿಸಲು, ಸಾರ್ವಜನಿಕ ಸಾರಿಗೆಯನ್ನು ತಪ್ಪಿಸಲು ಅವಳು ಬಯಸಿದಂತೆ ಅವಳು ಕಚೇರಿಗೆ ಪ್ರಯಾಣಿಸಲು ಸೆಕೆಂಡ್‌ಹ್ಯಾಂಡ್ ಕಾರನ್ನು ಖರೀದಿಸುತ್ತಾಳೆ. ಇದು ದುಬಾರಿ ಹೂಡಿಕೆಯಾಗಿದ್ದರೂ, ಪ್ರಯಾಣದ ಬಗ್ಗೆ ಅವಳು ನಿರಾಳವಾಗಿದ್ದಾಳೆ.",
              "te": "దానిని నివారించడానికి, ఆమె పబ్లిక్ ట్రాన్స్‌పోర్ట్‌ లో వెళ్లకూడదని ఆఫీసుకి వెళ్లేందుకు సెకండ్‌హ్యాండ్ కారును కొనుగోలు చేసింది. ఇది ఖరీదైన పెట్టుబడి అయినప్పటికీ, ఆమె ప్రయాణంలో తాను సంతోషంగా ఉంటుంది.",
              "or": "ଏଥିରୁ ରକ୍ଷା ପାଇବା ପାଇଁ, ସେ ସାଧାରଣ ପରିବହନରୁ ଦୂରେଇ ରହିବାକୁ ଚାହୁଁଥିବାରୁ ଅଫିସକୁ ଯିବା ପାଇଁ ଏକ ସେକେଣ୍ଡହାଣ୍ଡ କାର କିଣନ୍ତି | ଯଦିଓ ଏହା ଏକ ମହଙ୍ଗା ବିନିଯୋଗ, ସେ ନିଜକୁ ଭ୍ରମଣରେ ଆରାମଦାୟକ ଅନୁଭବ କରନ୍ତି |",
              "as": "সেইটো এৰাই চলিবলৈ, তাই ৰাজহুৱা বাছত ঘূৰা-ফুৰা কৰিব নিবিচৰাৰ বাবে অফিচলৈ যাবলৈ এখন ছেকেণ্ডহেণ্ড গাড়ী কিনে। যদিও এইটো এটা খৰচি কাৰবাৰ, তাই সেইখনত ঘূৰা-ফুৰা কৰি ভাল পাইছে।",
              "gu": "આનાં થી બચવા માટે, તેણી ઓફિસમાં જવા માટે સેકન્ડહેન્ડ કાર ખરીદે છે કારણ કે તેણી જાહેર પરિવહનમાં જવા નથી  માંગતી.  જો કે તે એક મોંઘુ રોકાણ છે, તેણી પોતાની મુસાફરીમાં આનાથી સરળતા અનુભવે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6i_3_4",
              "hi": "D6i_3_4",
              "ka": "D6i_3_4",
              "te": "D6i_3_4",
              "or": "D6i_3_4",
              "as": "D6i_3_4",
              "gu": "D6i_3_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita enjoys work but feels like she can do more. After working for a few years and making sure that her family is financially settled, she decides to study further.",
              "hi": "अस्मिता को काम पसंद है लेकिन लगता है कि वह और भी कुछ कर सकती है। कुछ वर्षों तक काम करने और यह सुनिश्चित करने के बाद कि उसका परिवार आर्थिक रूप से स्थिर है, वह आगे की पढ़ाई करने का निश्चय करती है।",
              "ka": "ಅಸ್ಮಿತಾ ಕೆಲಸವನ್ನು ಆನಂದಿಸುತ್ತಾಳೆ ಆದರೆ ಅವಳು ಹೆಚ್ಚು ಮಾಡಬಲ್ಲಳು ಎಂದು ಭಾವಿಸುತ್ತಾಳೆ. ಕೆಲವು ವರ್ಷಗಳ ಕಾಲ ಕೆಲಸ ಮಾಡಿದ ನಂತರ ಮತ್ತು ತನ್ನ ಕುಟುಂಬ ಆರ್ಥಿಕವಾಗಿ ನೆಲೆಸಿದೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಂಡ ನಂತರ, ಅವಳು ಮುಂದೆ ಓದಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
              "te": "అస్మిత పనిని ఆస్వాదిస్తుంది, కానీ ఆమె ఇంకా ఎక్కువ చేయగలనని అనిపిస్తుంది. కొన్నాళ్లు ఉద్యోగం చేసి, కుటుంబం ఆర్థికంగా స్థిరపడిందని నిర్ధారించుకున్న తర్వాత, ఆమె మరింత చదువుకోవాలని నిర్ణయించుకుంది.",
              "or": "ଅସ୍ମିତା କାମକୁ ଉପଭୋଗ କରନ୍ତି କିନ୍ତୁ ଅନୁଭବ କରନ୍ତି ଯେ ସେ ଅଧିକ କିଛି କରିପାରିବେ | କିଛି ବର୍ଷ କାମ କରିବା ପରେ ଏବଂ ନିଶ୍ଚିତ ଯେ ତାଙ୍କ ପରିବାର ର ଆର୍ଥିକ ସ୍ଥିତି ଠିକ ଅଛି , ସେ ଅଧିକ ଅଧ୍ୟୟନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "অস্মিতাই কাম কৰি ভাল পাইছে কিন্তু অনুভৱ কৰে যে তাই আৰু বেছি কৰিব পাৰিব। কেইবছৰমান কাম কৰাৰ পিছত আৰু তাইৰ পৰিয়ালটো আৰ্থিকভাৱে স্থিৰ হোৱাটো নিশ্চিত কৰাৰ পিছত, তাই আৰু বেছি অধ্যয়ন কৰাৰ সিদ্ধান্ত লয়।",
              "gu": "અસ્મિતાને કામ કરવામાં મજા આવે છે પણ લાગે છે કે પોતે વધુ કરી શકે છે.  થોડા વર્ષો કામ કર્યા પછી અને તેણીનો પરિવાર આર્થિક રીતે સ્થિર છે તેની ખાતરી કર્યા પછી, તેણીએ આગળ અભ્યાસ કરવાનું નક્કી કર્યું."
            }
          }
        ]
      },
      "scene33": {
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "Taking on new projects",
              "hi": "नए प्रोजेक्ट्स को लेना ",
              "ka": "ಹೊಸ ಯೋಜನೆಗಳನ್ನು ಕೈಗೆತ್ತಿಕೊಳ್ಳುವುದು",
              "te": "కొత్త ప్రాజెక్టులు తీసుకోవాలి",
              "or": "ନୂତନ ପ୍ରୋଜେକ୍ଟ ଗ୍ରହଣ କରିବା",
              "as": "নতুন প্ৰকল্প গ্ৰহণ কৰা",
              "gu": "નવા પ્રોજેક્ટ્સ હાથ ધરવા"
            }
          }
        ],
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6ii_1_1",
              "hi": "D6II_1_1",
              "ka": "D6ii_1_1",
              "te": "D6ii_1_1",
              "or": "D6ii_1_1",
              "as": "D6ii_1_1",
              "gu": "D6ii_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After her conversation with the project lead, Asmita gets two new projects. She feels it's a fresh start, and she's happy that she has regained the trust of her project lead.",
              "hi": "प्रोजेक्ट लीड के साथ बातचीत के बाद अस्मिता को दो नए प्रोजेक्ट मिले। उसे लगता है कि यह एक नई शुरुआत है, और वह खुश है कि उसने अपने प्रोजेक्ट लीड का विश्वास फिर से हासिल कर लिया है।",
              "ka": "ಪ್ರಾಜೆಕ್ಟ್ ಲೀಡ್‌ನೊಂದಿಗಿನ ಸಂಭಾಷಣೆಯ ನಂತರ, ಅಸ್ಮಿತಾ ಎರಡು ಹೊಸ ಯೋಜನೆಗಳನ್ನು ಪಡೆಯುತ್ತಾಳೆ. ಇದು ಹೊಸ ಆರಂಭ ಎಂದು ಅವಳು ಭಾವಿಸುತ್ತಾಳೆ ಮತ್ತು ತನ್ನ ಪ್ರಾಜೆಕ್ಟ್ ಲೀಡ್‌ನ ನಂಬಿಕೆಯನ್ನು ಮರಳಿ ಪಡೆದಿದ್ದಾಳೆ ಎಂದು ಅವಳು ಸಂತೋಷಪಡುತ್ತಾಳೆ.",
              "te": "ప్రాజెక్ట్ లీడ్‌తో ఆమె సంభాషణ తర్వాత, అస్మిత రెండు కొత్త ప్రాజెక్ట్‌లను పొందుతుంది. ఇది కొత్త ప్రారంభం అని ఆమె భావిస్తుంది మరియు ఆమె తన ప్రాజెక్ట్ లీడ్ యొక్క నమ్మకాన్ని తిరిగి పొందినందుకు సంతోషంగా ఉంది.",
              "or": "ପ୍ରୋଜେକ୍ଟ ଲିଡ୍ ସହିତ ବାର୍ତ୍ତାଳାପ ପରେ ଅସ୍ମିତା ଦୁଇଟି ନୂତନ ପ୍ରୋଜେକ୍ଟ ପାଆନ୍ତି | ସେ ଅନୁଭବ କରନ୍ତି ଯେ ଏହା ନୂଆକରି ଆରମ୍ଭ କରନ୍ତି, ଏବଂ ସେ ଖୁସି ଯେ ସେ ତାଙ୍କ ପ୍ରୋଜେକ୍ଟ ଲିଡର ବିଶ୍ୱାସ ପୁଣି ଫେରି ପାଇଛନ୍ତି |",
              "as": "মুখ্য প্ৰকল্পকাৰৰ সৈতে তাইৰ কথা-বতৰা হোৱাৰ পিছত, অস্মিতাই দুটা নতুন প্ৰকল্প লাভ কৰে। তাই অনুভৱ কৰে যে এইটো এটা নতুন আৰম্ভণি আৰু তাই সুখী যে তাই তাইৰ মুখ্য প্ৰকল্পকাৰৰ বিশ্বাস ঘূৰাই পাইছে।",
              "gu": "પ્રોજેક્ટ લીડ સાથેની વાતચીત પછી, અસ્મિતાને બે નવા પ્રોજેક્ટ મળે છે.  તેણીને લાગે છે કે તે એક નવી શરૂઆત છે, અને તેણી ખુશ છે કે તેણીએ તેના પ્રોજેક્ટ લીડનો વિશ્વાસ પાછો જીતી લીધો છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6ii_1_2",
              "hi": "D6II_1_2",
              "ka": "D6ii_1_2",
              "te": "D6ii_1_2",
              "or": "D6ii_1_2",
              "as": "D6ii_1_2",
              "gu": "D6ii_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The new clients also seem to be comfortable working with Asmita. The projects have a good start, and the clients are happy with Asmita's efficiency.",
              "hi": "नए ग्राहक भी अस्मिता के साथ काम करने में सहज अनुभव करते हैं। प्रोजेक्ट्स की शुरुआत अच्छी रही और ग्राहक अस्मिता की दक्षता से खुश हैं।",
              "ka": "ಹೊಸ ಗ್ರಾಹಕರು ಅಸ್ಮಿತಾ ಅವರೊಂದಿಗೆ ಕೆಲಸ ಮಾಡಲು ಆರಾಮದಾಯಕವಾಗಿದ್ದಾರೆ. ಯೋಜನೆಗಳು ಉತ್ತಮ ಆರಂಭವನ್ನು ಹೊಂದಿವೆ, ಮತ್ತು ಗ್ರಾಹಕರು ಅಸ್ಮಿತಾ ಅವರ ದಕ್ಷತೆಯಿಂದ ಸಂತೋಷಪಟ್ಟಿದ್ದಾರೆ.",
              "te": "కొత్త క్లయింట్లు కూడా అస్మితతో కలిసి పనిచేయడం సౌకర్యంగా ఉన్నట్లు తెలుస్తోంది. ప్రాజెక్ట్‌లు మంచిగా ప్రారంభించబడ్డాయి మరియు అస్మిత సమర్థతతో క్లైంట్స్ సంతోషంగా ఉన్నారు.",
              "or": "ନୂତନ ଗ୍ରାହକମାନେ ମଧ୍ୟ ଅସ୍ମିତାଙ୍କ ସହିତ କାମ କରିବାକୁ ଆରାମଦାୟକ ମନେକରନ୍ତି | ପ୍ରୋଜେକ୍ଟଗୁଡିକର ଆରମ୍ଭ ଭଲ ଅଛି, ଏବଂ ଗ୍ରାହକମାନେ ଅସ୍ମିତାଙ୍କ ଦକ୍ଷତାକୁ ନେଇ ଖୁସି ଅଛନ୍ତି |",
              "as": "নতুন গ্ৰাহকসকলেও অস্মিতাৰ সৈতে কাম কৰিবলৈ ভাল পাইছে। প্ৰকল্পবোৰৰ আৰম্ভণি ভাল, আৰু গ্ৰাহকসকল অস্মিতাৰ দক্ষতাত সুখী।",
              "gu": "નવા ગ્રાહકોને  પણ અસ્મિતા સાથે કામ કરવું આરામદાયક લાગે છે.  પ્રોજેક્ટની શરૂઆત સારી છે, અને ગ્રાહકો અસ્મિતાની કાર્યક્ષમતાથી ખુશ છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6ii_1_3",
              "hi": "D6II_1_3",
              "ka": "D6ii_1_3",
              "te": "D6ii_1_3",
              "or": "D6ii_1_3",
              "as": "D6ii_1_3",
              "gu": "D6ii_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Both projects became a huge success due to their cooperation and efficiency.",
              "hi": "दोनों प्रोजेक्ट्स उनके सहयोग और दक्षता के कारण एक बड़ी सफलता बन गए।",
              "ka": "ಅವರ ಸಹಕಾರ ಮತ್ತು ದಕ್ಷತೆಯಿಂದ ಎರಡೂ ಯೋಜನೆಗಳು ದೊಡ್ಡ ಯಶಸ್ಸನ್ನು ಗಳಿಸಿದವು.",
              "te": "వారి సహకారం మరియు సమర్థత కారణంగా రెండు ప్రాజెక్టులు భారీ విజయాన్ని సాధించాయి.",
              "or": "ସେମାନଙ୍କର ସହଯୋଗ ଏବଂ ଦକ୍ଷତା ହେତୁ ଉଭୟ ପ୍ରିଜେକ୍ଟ ଏକ ବଡ଼ ସଫଳତା ପାଇଲା |",
              "as": "দুয়োটা প্ৰকল্পই তেওঁলোকৰ সহযোগিতা আৰু দক্ষতাৰ বাবে এক বৃহৎ সফলতা লাভ কৰিছিল।",
              "gu": "બંને પ્રોજેક્ટ તેમના સહકાર અને કાર્યક્ષમતાને કારણે ખૂબ જ સફળ બન્યા."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6ii_1_4",
              "hi": "D6II_1_4",
              "ka": "D6ii_1_4",
              "te": "D6ii_1_4",
              "or": "D6ii_1_4",
              "as": "D6ii_1_4",
              "gu": "D6ii_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Her clients and her colleagues in the office get used to her identity. They acknowledge her work and her confidence. But every time she crosses one challenge, she runs into a new one.",
              "hi": "उसके ग्राहकों और कार्यालय में उसके सहयोगियों को उसकी पहचान की आदत हो जाती है। वे उसके काम और उसके आत्मविश्वास को स्वीकार करते हैं। लेकिन हर बार जब वह एक चुनौती को पार करती है, तो वह एक नई चुनौती का सामना करती है।",
              "ka": "ಅವಳ ಗ್ರಾಹಕರು ಮತ್ತು ಕಛೇರಿಯಲ್ಲಿ ಅವಳ ಸಹೋದ್ಯೋಗಿಗಳು ಅವಳ ಗುರುತನ್ನು ಬಳಸಿಕೊಳ್ಳುತ್ತಾರೆ. ಅವರು ಅವಳ ಕೆಲಸವನ್ನು ಮತ್ತು ಅವಳ ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಗುರುತಿಸುತ್ತಾರೆ. ಆದರೆ ಪ್ರತಿ ಬಾರಿ ಅವಳು ಒಂದು ಸವಾಲನ್ನು ದಾಟಿದಾಗ, ಅವಳು ಹೊಸದಕ್ಕೆ ಓಡುತ್ತಾಳೆ.",
              "te": "ఆమె క్లయింట్లు మరియు ఆఫీసులో ఆమె సహచరులు ఆమె గుర్తింపుకు అలవాటు పడతారు. వారు ఆమె పనిని మరియు ఆమె విశ్వాసాన్ని గుర్తిస్తారు. కానీ ఆమె ఒక సవాలును అధిగమించిన ప్రతిసారీ, ఆమె కొత్తదానిని ఎదుర్కొంటుంది.",
              "or": "ତାଙ୍କ ଗ୍ରାହକ ଏବଂ ଅଫିସରେ ଥିବା ସହକର୍ମୀମାନେ ତାଙ୍କ ପରିଚୟରେ ଅଭ୍ୟସ୍ତ | ସେମାନେ ତାଙ୍କ କାର୍ଯ୍ୟ ଏବଂ ଆତ୍ମବିଶ୍ୱାସକୁ ସ୍ୱୀକାର କରନ୍ତି | କିନ୍ତୁ ପ୍ରତ୍ୟେକ ଥର ସେ ଗୋଟିଏ ଚ୍ୟାଲେଞ୍ଜ ଅତିକ୍ରମ କଲାବେଳେ ସେ ଏକ ନୂଆ ଚ୍ୟାଲେଞ୍ଜକୁ ସାମନା କରନ୍ତି |",
              "as": "তাইৰ গ্ৰাহক আৰু অফিচৰ তাইৰ সহকৰ্মীসকল তাইৰ পৰিচয়ৰ সৈতে অভ্যস্ত হৈ পৰে। তেওঁলোকে তাইৰ কাম আৰু তাইৰ আত্মবিশ্বাসক স্বীকাৰ কৰে। কিন্তু যেতিয়াই তাই এটা প্ৰত্যাহ্বান অতিক্ৰম কৰে, আকৌ তাইক নতুন এটাই ধৰে।",
              "gu": "તેણીનાં ગ્રાહકો અને ઓફિસમાં તેના સાથીદારો તેની ઓળખ માટે ટેવાય ગયાછે.  તેઓ તેના કામ અને તેના આત્મવિશ્વાસને સ્વીકારે છે.  પરંતુ જ્યારે પણ તે એક પડકાર પાર કરે છે, ત્યારે તે એક નવો પડકાર સામે આવી જાય છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6ii_2_1",
              "hi": "D6II_2_1",
              "ka": "D6ii_2_1",
              "te": "D6ii_2_1",
              "or": "D6ii_2_1",
              "as": "D6ii_2_1",
              "gu": "D6ii_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "As soon as she steps outside the office, Asmita is teased and harassed by people in public spaces. She feels unsafe while travelling on public transport or walking home late in the evening.",
              "hi": "ऑफिस के बाहर कदम रखते ही अस्मिता को सार्वजनिक जगहों पर लोग चिढ़ाते और परेशान करते हैं। वह सार्वजनिक परिवहन में यात्रा करते समय या देर शाम को घर जाते समय असुरक्षित अनुभव करती है।",
              "ka": "ಕಚೇರಿಯಿಂದ ಹೊರಗೆ ಕಾಲಿಟ್ಟ ತಕ್ಷಣ ಅಸ್ಮಿತಾ ಅವರನ್ನು ಸಾರ್ವಜನಿಕ ಸ್ಥಳಗಳಲ್ಲಿ ಜನರು ಚುಡಾಯಿಸಿ ಕಿರುಕುಳ ನೀಡುತ್ತಾರೆ. ಸಾರ್ವಜನಿಕ ಸಾರಿಗೆಯಲ್ಲಿ ಪ್ರಯಾಣಿಸುವಾಗ ಅಥವಾ ಸಂಜೆ ತಡವಾಗಿ ಮನೆಗೆ ಹೋಗುವಾಗ ಅವಳು ಅಸುರಕ್ಷಿತ ಎಂದು ಭಾವಿಸುತ್ತಾಳೆ.",
              "te": "ఆఫీస్ బయటకి అడుగు పెట్టగానే అస్మితను పబ్లిక్ ప్లేస్‌లో ఉండే వాళ్ళు ఆటపట్టిస్తూ వేధించేవారు. పబ్లిక్ ట్రాన్స్‌పోర్ట్‌లో ప్రయాణిస్తున్నప్పుడు లేదా సాయంత్రం ఆలస్యంగా ఇంటికి నడిచేటప్పుడు ఆమెకు అసురక్షితంగా అనిపిస్తుంది.",
              "or": "ସେ ଅଫିସ୍ ବାହାରେ ପାଦ ଦେବା ମାତ୍ରେ ଅସ୍ମିତା ସର୍ବସାଧାରଣ ସ୍ଥାନରେ ଲୋକମାନଙ୍କ ଦ୍ୱାରା ହଇରାଣ ହୁଅନ୍ତି | ସର୍ବସାଧାରଣ ପରିବହନରେ ଯାତ୍ରା କରିବା ସମୟରେ କିମ୍ବା ସନ୍ଧ୍ୟାରେ ଘରକୁ ଯିବାବେଳେ ସେ ନିଜକୁ ଅସୁରକ୍ଷିତ ମନେ କରନ୍ତି |",
              "as": "তাই অফিচৰ বাহিৰত খোজ দিয়াৰ লগে লগে, অস্মিতাক ৰাজহুৱা স্থানত মানুহে জোকাইছিল আৰু হাৰাশাস্তি কৰিছিল। ৰাজহুৱা বাছত ঘূৰা-ফুৰা কৰোঁতে বা সন্ধিয়া পলমকৈ ঘৰলৈ খোজ কাঢ়ি থাকোঁতে তাই অসুৰক্ষিত অনুভৱ কৰে।",
              "gu": "ઓફિસની બહાર પગ મૂકતાની સાથે જ અસ્મિતાને જાહેર જગ્યાઓ પર લોકો ચીડવે છે અને હેરાન કરે છે.  તે જાહેર પરિવહન પર મુસાફરી કરતી વખતે અથવા મોડી સાંજે ઘરે ચાલતી વખતે તેણી અસુરક્ષિત અનુભવે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6ii_2_2",
              "hi": "D6II_2_2",
              "ka": "D6ii_2_2",
              "te": "D6ii_2_2",
              "or": "D6ii_2_2",
              "as": "D6ii_2_2",
              "gu": "D6ii_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "To avoid that, she buys a secondhand car to commute to the office as she wants to avoid public transport. Although it’s an expensive investment, she finds herself feeling at ease about travelling.",
              "hi": "इससे बचने के लिए, वह कार्यालय जाने के लिए एक पुरानी कार खरीदती है क्योंकि वह सार्वजनिक परिवहन से बचना चाहती है। हालांकि यह एक महंगा निवेश है, लेकिन वह यात्रा करने में खुद को सहज अनुभव करती है।",
              "ka": "ಅದನ್ನು ತಪ್ಪಿಸಲು, ಸಾರ್ವಜನಿಕ ಸಾರಿಗೆಯನ್ನು ತಪ್ಪಿಸಲು ಅವಳು ಬಯಸಿದಂತೆ ಅವಳು ಕಚೇರಿಗೆ ಪ್ರಯಾಣಿಸಲು ಸೆಕೆಂಡ್‌ಹ್ಯಾಂಡ್ ಕಾರನ್ನು ಖರೀದಿಸುತ್ತಾಳೆ. ಇದು ದುಬಾರಿ ಹೂಡಿಕೆಯಾಗಿದ್ದರೂ, ಪ್ರಯಾಣದ ಬಗ್ಗೆ ಅವಳು ನಿರಾಳವಾಗಿದ್ದಾಳೆ.",
              "te": "దానిని నివారించడానికి, ఆమె పబ్లిక్ ట్రాన్స్‌పోర్ట్‌లో వెళ్లకూడదని కోరుకోవడంతో ఆఫీస్ కి వెళ్లేందుకు సెకండ్‌హ్యాండ్ కారును కొనుగోలు చేసింది. ఇది ఖరీదైన పెట్టుబడి అయినప్పటికీ, ఆమె ప్రయాణంలో సంతోషంగా ఉంటుంది.",
              "or": "ଏଥିରୁ ରକ୍ଷା ପାଇବା ପାଇଁ, ସେ ସାଧାରଣ ପରିବହନରୁ ଦୂରେଇ ରହିବାକୁ ଚାହୁଁଥିବାରୁ ଅଫିସକୁ ଯିବା ପାଇଁ ଏକ ସେକେଣ୍ଡହାଣ୍ଡ କାର କିଣନ୍ତି | ଯଦିଓ ଏହା ଏକ ମହଙ୍ଗା ବିନିଯୋଗ, ସେ ନିଜକୁ ଭ୍ରମଣରେ ଆରାମଦାୟକ ଅନୁଭବ କରନ୍ତି |",
              "as": "সেইটো এৰাই চলিবলৈ, তাই ৰাজহুৱা বাছত ঘূৰা-ফুৰা কৰিব নিবিচৰাৰ বাবে অফিচলৈ যাবলৈ এখন ছেকেণ্ডহেণ্ড গাড়ী কিনে। যদিও এইটো এটা খৰচি কাৰবাৰ, তাই সেইখনত ঘূৰা-ফুৰা কৰি ভাল পাইছে।",
              "gu": "આનાં થી બચવા માટે, તેણી ઓફિસમાં જવા માટે સેકન્ડહેન્ડ કાર ખરીદે છે કારણ કે તેણી જાહેર પરિવહનમાં જવા નથી  માંગતી.  જો કે તે એક મોંઘુ રોકાણ છે, તેણી પોતાની મુસાફરીમાં આનાથી સરળતા અનુભવે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D6ii_2_3",
              "hi": "D6II_2_3",
              "ka": "D6ii_2_3",
              "te": "D6ii_2_3",
              "or": "D6ii_2_3",
              "as": "D6ii_2_3",
              "gu": "D6ii_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita enjoys work but feels like she can do more. After working for a few years and making sure that her family is financially settled, she decides to study further.",
              "hi": "अस्मिता को काम पसंद है लेकिन लगता है कि वह और भी कुछ कर सकती है। कुछ वर्षों तक काम करने और यह सुनिश्चित करने के बाद कि उसका परिवार आर्थिक रूप से स्थिर है, वह आगे की पढ़ाई करने का निश्चय करती है।",
              "ka": "ಅಸ್ಮಿತಾ ಕೆಲಸವನ್ನು ಆನಂದಿಸುತ್ತಾಳೆ ಆದರೆ ಅವಳು ಹೆಚ್ಚು ಮಾಡಬಲ್ಲಳು ಎಂದು ಭಾವಿಸುತ್ತಾಳೆ. ಕೆಲವು ವರ್ಷಗಳ ಕಾಲ ಕೆಲಸ ಮಾಡಿದ ನಂತರ ಮತ್ತು ತನ್ನ ಕುಟುಂಬ ಆರ್ಥಿಕವಾಗಿ ನೆಲೆಸಿದೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಂಡ ನಂತರ, ಅವಳು ಮುಂದೆ ಓದಲು ನಿರ್ಧರಿಸುತ್ತಾಳೆ.",
              "te": "అస్మిత పనిని ఆస్వాదిస్తుంది, కానీ ఆమె ఇంకా ఎక్కువ చేయగలనని అనిపిస్తుంది. కొన్నాళ్లు ఉద్యోగం చేసి, కుటుంబం ఆర్థికంగా స్థిరపడిందని నిర్ధారించుకున్న తర్వాత, ఆమె మరింత చదువుకోవాలని నిర్ణయించుకుంది.",
              "or": "ଅସ୍ମିତା କାମକୁ ଉପଭୋଗ କରନ୍ତି କିନ୍ତୁ ଅନୁଭବ କରନ୍ତି ଯେ ସେ ଅଧିକ କିଛି କରିପାରିବେ | କିଛି ବର୍ଷ କାମ କରିବା ପରେ ଏବଂ ନିଶ୍ଚିତ ଯେ ତାଙ୍କ ପରିବାର ର ଆର୍ଥିକ ସ୍ଥିତି ଠିକ ଅଛି , ସେ ଅଧିକ ଅଧ୍ୟୟନ କରିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି |",
              "as": "অস্মিতাই কাম কৰি ভাল পাইছে কিন্তু অনুভৱ কৰে যে তাই আৰু বেছি কৰিব পাৰিব। কেইবছৰমান কাম কৰাৰ পিছত আৰু তাইৰ পৰিয়ালটো আৰ্থিকভাৱে স্থিৰ হোৱাটো নিশ্চিত কৰাৰ পিছত, তাই আৰু বেছি অধ্যয়ন কৰাৰ সিদ্ধান্ত লয়।",
              "gu": "અસ્મિતાને કામ કરવામાં મજા આવે છે પણ લાગે છે કે પોતે વધુ કરી શકે છે.  થોડા વર્ષો કામ કર્યા પછી અને તેણીનો પરિવાર આર્થિક રીતે સ્થિર છે તેની ખાતરી કર્યા પછી, તેણીએ આગળ અભ્યાસ કરવાનું નક્કી કર્યું."
            }
          }
        ]
      },
      "scene34": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_1_1",
              "hi": "D7_1_1",
              "ka": "D7_1_1",
              "te": "D7_1_1",
              "or": "D7_1_1",
              "as": "D7_1_1",
              "gu": "D7_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "At her workplace, Asmita realises that although she has the aptitude for technology and sales, she will have to study management to move to higher positions.",
              "hi": "अपने कार्यस्थल पर, अस्मिता को पता चलता है कि यद्यपि उसके पास प्रौद्योगिकी और बिक्री के लिए योग्यता है, उसे उच्च पदों पर जाने के लिए प्रबंधन का अध्ययन करना होगा।",
              "ka": "ತನ್ನ ಕೆಲಸದ ಸ್ಥಳದಲ್ಲಿ, ಅಸ್ಮಿತಾ ತಾನು ತಂತ್ರಜ್ಞಾನ ಮತ್ತು ಮಾರಾಟದ ಯೋಗ್ಯತೆಯನ್ನು ಹೊಂದಿದ್ದರೂ, ಉನ್ನತ ಸ್ಥಾನಗಳಿಗೆ ಹೋಗಲು ಮ್ಯಾನೇಜ್ಮೆಂಟ್ ಅನ್ನು ಅಧ್ಯಯನ ಮಾಡಬೇಕಾಗುತ್ತದೆ ಎಂದು ಅರಿತುಕೊಂಡಳು.",
              "te": "తన ఆఫీస్ లో, అస్మిత తనకు టెక్నాలజీ మరియు సేల్స్ పట్ల ఆప్టిట్యూడ్ ఉన్నప్పటికీ, ఉన్నత స్థానాలకు వెళ్లడానికి మేనేజ్‌మెంట్‌ను చదవవలసి ఉంటుందని గ్రహించింది.",
              "or": "ତାଙ୍କ କର୍ମକ୍ଷେତ୍ରରେ ଅସ୍ମିତା ହୃଦୟଙ୍ଗମ କରିଛନ୍ତି ଯେ ଯଦିଓ ତାଙ୍କର ଟେକ୍ନୋଲୋଜି ଏବଂ ସେଲ୍‌ସ୍‍ ପାଇଁ ଦକ୍ଷତା ଅଛି, ତଥାପି ତାଙ୍କୁ ଉଚ୍ଚ ପଦବୀକୁ ଯିବାକୁ ମ୍ୟାନେଜମେଣ୍ଟ ଅଧ୍ୟୟନ କରିବାକୁ ପଡିବ |",
              "as": "তাইৰ কৰ্মস্থানত, অস্মিতাই উপলব্ধি কৰে যে যদিও তাইৰ প্ৰযুক্তি আৰু বিক্ৰীৰ ক্ষেত্ৰত দক্ষতা আছে, তাই উচ্চ পদলৈ যাবলৈ মেনেজমেণ্ট অধ্যয়ন কৰিব লাগিব।",
              "gu": "તેણીના કાર્યસ્થળ પર, અસ્મિતાને સમજાયું કે તેણી ટેક્નોલોજી અને વેચાણ માટે યોગ્યતા ધરાવતી હોવા છતાં, તેણે ઉચ્ચ હોદ્દા પર જવા માટે મેનેજમેન્ટનો અભ્યાસ કરવો પડશે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_1_2",
              "hi": "D7_1_2",
              "ka": "D7_1_2",
              "te": "D7_1_2",
              "or": "D7_1_2",
              "as": "D7_1_2",
              "gu": "D7_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Her project lead encourages her to apply for a Master’s of Business Administration (MBA). She tells her that she can get into a foreign university if she works towards it.",
              "hi": "उसकी प्रोजेक्ट लीड उसे मास्टर ऑफ बिजनेस एडमिनिस्ट्रेशन (एमबीए) के लिए आवेदन करने के लिए प्रोत्साहित करती है। वह उसे बताती है कि अगर वह इस दिशा में काम करती है तो वह एक विदेशी विश्वविद्यालय में प्रवेश ले सकती है।",
              "ka": "ಆಕೆಯ ಪ್ರಾಜೆಕ್ಟ್ ಲೀಡ್ ಅವಳನ್ನು ಮಾಸ್ಟರ್ಸ್ ಆಫ್ ಬಿಸಿನೆಸ್ ಅಡ್ಮಿನಿಸ್ಟ್ರೇಷನ್ (ಎಂಬಿಎ) ಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಪ್ರೋತ್ಸಾಹಿಸುತ್ತಾರೆ. ಅವಳು ಕೆಲಸ ಮಾಡಿದರೆ ವಿದೇಶಿ ವಿಶ್ವವಿದ್ಯಾಲಯಕ್ಕೆ ಪ್ರವೇಶಿಸಬಹುದು ಎಂದು ಅವಳು ಹೇಳುತ್ತಾಳೆ.",
              "te": "ఆమె ప్రాజెక్ట్ లీడ్ ఆమెను మాస్టర్స్ ఆఫ్ బిజినెస్ అడ్మినిస్ట్రేషన్ (MBA) కోసం అప్లై చేయమని ప్రోత్సహిస్తుంది. ఆమె కొంచెం కష్టపడితే విదేశీ యూనివర్సిటీలో చేరవచ్చని ఆమె చెప్పింది.",
              "or": "ତାଙ୍କ ପ୍ରୋଜେକ୍ଟ ଲିଡ୍ ତାଙ୍କୁ ମାଷ୍ଟର ଅଫ୍ ବିଜିନେସ୍ ଆଡମିନିଷ୍ଟ୍ରେସନ୍ (MBA) ପାଇଁ ଆବେଦନ କରିବାକୁ ଉତ୍ସାହିତ କରେ | ସେ ତାଙ୍କୁ କହିଛନ୍ତି ଯେ ଯଦି ସେ ଏହା ଦିଗରେ କାମ କରନ୍ତି ତେବେ ସେ ଏକ ବିଦେଶୀ ବିଶ୍ୱବିଦ୍ୟାଳୟରେ ପ୍ରବେଶ କରିପାରିବେ |",
              "as": "তাইৰ প্ৰকল্পৰ নেতৃত্বই তাইক মাষ্টাৰ অফ বিজনেছ এডমিনিষ্ট্ৰেচন (MBA)ৰ বাবে আবেদন কৰিবলৈ উৎসাহিত কৰে। তেওঁ তাইক কৈছিল যে যদি তাই ইয়াৰ বাবে কাম কৰে তেন্তে তাই বিদেশৰ বিশ্ববিদ্যালয়ত ভৰ্তি হ'ব পাৰিব।",
              "gu": "તેણીના પ્રોજેક્ટ લીડ તેણીને માસ્ટર ઓફ બિઝનેસ એડમિનિસ્ટ્રેશન (MBA) માટે અરજી કરવા પ્રોત્સાહિત કરે છે.  તેઓ તેને કહે છે કે જો અસ્મિતા આ  માટે કામ કરે તો તેણી વિદેશી યુનિવર્સિટીમાં પ્રવેશ મેળવી શકે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_1_3",
              "hi": "D7_1_3",
              "ka": "D7_1_3",
              "te": "D7_1_3",
              "or": "D7_1_3",
              "as": "D7_1_3",
              "gu": "D7_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita likes the idea, as she is keen on experiencing academic life in other countries after an unpleasant college experience during her undergraduate studies.",
              "hi": "अस्मिता को यह विचार पसंद है, क्योंकि वह अपने स्नातक अध्ययन के समय कॉलेज के  एक अप्रिय अनुभव के बाद अन्य देशों में अकादमिक जीवन का अनुभव करने के लिए उत्सुक है।",
              "ka": "ಅಸ್ಮಿತಾ ಈ ಕಲ್ಪನೆಯನ್ನು ಇಷ್ಟಪಡುತ್ತಾಳೆ, ಏಕೆಂದರೆ ಅವರು ಪದವಿಪೂರ್ವ ಅಧ್ಯಯನದ ಸಮಯದಲ್ಲಿ ಅಹಿತಕರ ಕಾಲೇಜು ಅನುಭವದ ನಂತರ ಇತರ ದೇಶಗಳಲ್ಲಿ ಶೈಕ್ಷಣಿಕ ಜೀವನವನ್ನು ಅನುಭವಿಸಲು ಉತ್ಸುಕರಾಗಿದ್ದಾರೆ.",
              "te": "అస్మిత తన అండర్ గ్రాడ్యుయేట్ చదువుతున్న సమయంలో ఒక అసహ్యకరమైన కాలేజీ అనుభవం తర్వాత ఇతర దేశాలలో చదువుకోవాలనే ఆసక్తి కలిగింది, కనుక ఈ ఆలోచనను ఇష్టపడింది.",
              "or": "ଅସ୍ମିତା ଏହି ଧାରଣାକୁ ପସନ୍ଦ କରନ୍ତି, କାରଣ ସେ ଅଣସ୍ନାତକଅଧ୍ୟୟନ ସମୟରେ ଏକ ଅପ୍ରୀତିକର କଲେଜ ଅଭିଜ୍ଞତା ପରେ ଅନ୍ୟ ଦେଶରେ ଏକାଡେମିକ୍ ଜୀବନ ଅନୁଭବ କରିବାକୁ ଆଗ୍ରହୀ ଥିଲେ |",
              "as": "অস্মিতাই এই ধাৰণাটো ভাল পাইছে, কিয়নো তেওঁ স্নাতক অধ্যয়নৰ সময়ত কলেজৰ এক অপ্ৰীতিকৰ অভিজ্ঞতাৰ পিছত আন দেশত শৈক্ষিক জীৱনৰ অভিজ্ঞতা ল'বলৈ আগ্ৰহী।",
              "gu": "અસ્મિતાને આ વિચાર ગમ્યો, કારણ કે તેણી તેના અંડરગ્રેજ્યુએટ અભ્યાસ દરમિયાન કોલેજના અપ્રિય અનુભવ પછી અન્ય દેશોમાં શૈક્ષણિક જીવનનો અનુભવ લેવા ઉત્સુક હતી."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_1_4",
              "hi": "D7_1_4",
              "ka": "D7_1_4",
              "te": "D7_1_4",
              "or": "D7_1_4",
              "as": "D7_1_4",
              "gu": "D7_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She knows that she will get admission provided she has the necessary qualification and good scores in the Graduate Management Admission Test (GMAT).",
              "hi": "वह जानती है कि उसे प्रवेश मिलेगा बशर्ते उसके पास स्नातक प्रबंधन प्रवेश परीक्षा (जीमैट) में आवश्यक योग्यता और अच्छे अंक हों।",
              "ka": "ಗ್ರಾಜುಯೇಟ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್ ಅಡ್ಮಿಷನ್ ಟೆಸ್ಟ್ (GMAT) ನಲ್ಲಿ ಅಗತ್ಯ ಅರ್ಹತೆ ಮತ್ತು ಉತ್ತಮ ಅಂಕಗಳನ್ನು ಹೊಂದಿದ್ದರೆ ಅವಳು ಪ್ರವೇಶವನ್ನು ಪಡೆಯುತ್ತಾಳೆ ಎಂದು ಅವಳು ತಿಳಿದಿದ್ದಾಳೆ.",
              "te": "గ్రాడ్యుయేట్ మేనేజ్‌మెంట్ అడ్మిషన్ టెస్ట్ (GMAT)లో ఆమెకు అవసరమైన అర్హత మరియు మంచి స్కోర్లు ఉంటే ఆమె అడ్మిషన్ పొందుతుందని ఆమెకు తెలుసు.",
              "or": "ସେ ଜାଣନ୍ତି ଯେ ଗ୍ରାଜୁଏଟ୍ ମ୍ୟାନେଜମେଣ୍ଟ ଆଡମିଶନ ଟେଷ୍ଟ (GMAT) ରେ ଆବଶ୍ୟକ ଯୋଗ୍ୟତା ଏବଂ ଭଲ ସ୍କୋର ରହିଲେ ସେ ଆଡମିଶନ ପାଇବେ |",
              "as": "তেওঁ জানে যে গ্ৰেজুৱেট মেনেজমেণ্ট এডমিছন টেষ্টত (GMAT) প্ৰয়োজনীয় অৰ্হতা আৰু ভাল নম্বৰ থাকিলে তেওঁ নামভৰ্তি কৰিব পাৰিব।",
              "gu": "તેણી જાણે છે કે તેણી પાસે જરૂરી લાયકાત છે અને ગ્રેજ્યુએટ મેનેજમેન્ટ એડમિશન ટેસ્ટ (GMAT) માં તેણીનાં સારા સ્કોર હશે તો તેણીને પ્રવેશ મળશે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_2_1",
              "hi": "D7_2_1",
              "ka": "D7_2_1",
              "te": "D7_2_1",
              "or": "D7_2_1",
              "as": "D7_2_1",
              "gu": "D7_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The process of preparing and applying to colleges takes almost a year. Asmita begins preparing for the GMAT and finds it so much easier to study now that she has more stability in her immediate surroundings.",
              "hi": "कॉलेज की तैयारी करने और आवेदन करने की प्रक्रिया में लगभग एक वर्ष का समय लग जाता है। अस्मिता जीमैट की तैयारी शुरू करती है और उसे अब अध्ययन करना इतना सरल लगता है कि उसे अपने आस-पास के वातावरण में अधिक स्थिरता मिलती है।",
              "ka": "ಕಾಲೇಜುಗಳಿಗೆ ಸಿದ್ಧಪಡಿಸುವ ಮತ್ತು ಅರ್ಜಿ ಸಲ್ಲಿಸುವ ಪ್ರಕ್ರಿಯೆಯು ಸುಮಾರು ಒಂದು ವರ್ಷ ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ. ಅಸ್ಮಿತಾ GMAT ಗಾಗಿ ತಯಾರಿಯನ್ನು ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ ಮತ್ತು ಈಗ ಅಧ್ಯಯನ ಮಾಡುವುದು ತುಂಬಾ ಸುಲಭ ಎಂದು ಕಂಡುಕೊಳ್ಳುತ್ತಾಳೆ, ಅವಳು ತನ್ನ ಸುತ್ತಮುತ್ತಲಿನ ಪರಿಸರದಲ್ಲಿ ಹೆಚ್ಚು ಸ್ಥಿರತೆಯನ್ನು ಹೊಂದಿದ್ದಾಳೆ.",
              "te": "కళాశాలలకు ప్రిపేర్ అయి అప్లై చేసే ప్రక్రియ దాదాపు ఒక సంవత్సరం పడుతుంది. అస్మిత GMAT కోసం ప్రిపేర్ అవడం ప్రారంభించింది మరియు ఆమె తన పరిసరాలు బాగున్నందున ఇప్పుడు చదువుకోవడం చాలా సులభమైంది అని భావిస్తుంది.",
              "or": "କଲେଜଗୁଡ଼ିକରେ ପ୍ରସ୍ତୁତି ଏବଂ ଆବେଦନ କରିବାର ପ୍ରକ୍ରିୟା ପ୍ରାୟ ଏକ ବର୍ଷ ଲାଗିଥାଏ | ଅସ୍ମିତା GMAT ପାଇଁ ପ୍ରସ୍ତୁତି ଆରମ୍ଭ କରନ୍ତି ଏବଂ ବର୍ତ୍ତମାନ ଅଧ୍ୟୟନ କରିବା ଏତେ ସହଜ ଯେ ତାଙ୍କ ନିକଟବର୍ତ୍ତୀ ଅଞ୍ଚଳରେ ଅଧିକ ସ୍ଥିରତା ଅଛି |",
              "as": "কলেজসমূহত প্ৰস্তুতি আৰু আবেদন কৰাৰ প্ৰক্ৰিয়াটোত প্ৰায় এবছৰ সময় লাগে। অস্মিতাই GMATৰ বাবে প্ৰস্তুতি আৰম্ভ কৰে আৰু এতিয়া তাই বিচাৰি পাইছে যে অধ্যয়ন কৰাটো বহুত সহজ আৰু তাইৰ ওচৰৰ পৰিৱেশত অধিক স্থিৰতা অনুভৱ কৰিছে।",
              "gu": "કોલેજોની તૈયાર કરવા અને અરજી કરવાની પ્રક્રિયામાં લગભગ એક વર્ષનો સમય લાગે છે.  અસ્મિતા GMT માટે તૈયારી કરવાનું શરૂ કરે છે અને તેને હવે અભ્યાસ કરવાનું એટલું સરળ લાગે છે કે તેણીની નજીકના વાતાવરણમાં વધુ સ્થિરતા છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_2_2",
              "hi": "D7_2_2",
              "ka": "D7_2_2",
              "te": "D7_2_2",
              "or": "D7_2_2",
              "as": "D7_2_2",
              "gu": "D7_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "While preparing for the tests, Asmita also applies for her gender change certificate. There are many steps to getting this done, and she feels overwhelmed.",
              "hi": "परीक्षा की तैयारी करते समय, अस्मिता अपने लिंग परिवर्तन प्रमाण पत्र के लिए भी आवेदन करती है। इसे पूरा करने के लिए कई कदम हैं, और वह अभिभूत अनुभव करती है।",
              "ka": "ಪರೀಕ್ಷೆಗಳಿಗೆ ತಯಾರಿ ನಡೆಸುತ್ತಿರುವಾಗ, ಅಸ್ಮಿತಾ ತನ್ನ ಲಿಂಗ ಬದಲಾವಣೆ ಪ್ರಮಾಣಪತ್ರಕ್ಕಾಗಿ ಸಹ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಾಳೆ. ಇದನ್ನು ಮಾಡಲು ಹಲವು ಹಂತಗಳಿವೆ, ಮತ್ತು ಅವಳು ಅತಿಯಾಗಿ ಖುಷಿಯಾಗುತ್ತಾಳೆ.",
              "te": "పరీక్షలకు సిద్ధమవుతున్న సమయంలో, అస్మిత తన లింగ మార్పు సర్టిఫికేట్ కోసం కూడా అప్లై చేసింది. దీన్ని పూర్తి చేయడానికి అనేక దశలు ఉన్నాయి, మరియు ఆమెకు ఇది భారంగా అనిపిస్తుంది.",
              "or": "ପରୀକ୍ଷା ପାଇଁ ପ୍ରସ୍ତୁତ ହେଉଥିବାବେଳେ ଅସ୍ମିତା ତାଙ୍କ ଲିଙ୍ଗ ପରିବର୍ତ୍ତନ ପ୍ରମାଣପତ୍ର ପାଇଁ ମଧ୍ୟ ଆବେଦନ କରନ୍ତି | ଏହା କରିବା ପାଇଁ ଅନେକ ପଦକ୍ଷେପ ଅଛି, ଏବଂ ସେ ଅତ୍ୟଧିକ କଷ୍ଟ ଅନୁଭବ କରନ୍ତି |",
              "as": "পৰীক্ষাবোৰৰ বাবে প্ৰস্তুতি কৰোঁতে, অস্মিতাই তাইৰ লিংগ পৰিৱৰ্তন প্ৰমাণপত্ৰৰ বাবেও আবেদন কৰে। এইটো সম্পন্ন কৰাৰ বাবে বহুতো পদক্ষেপ আছে, আৰু তাই অভিভূত অনুভৱ কৰে।",
              "gu": "પરીક્ષણોની તૈયારી કરતી વખતે, અસ્મિતા તેના લિંગ પરિવર્તનના પ્રમાણપત્ર માટે પણ અરજી કરે છે.  આ પૂર્ણ કરવા માટે ઘણા પગલાં છે, અને તેણી અભિભૂત થઈ જાય છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_2_3",
              "hi": "D7_2_3",
              "ka": "D7_2_3",
              "te": "D7_2_3",
              "or": "D7_2_3",
              "as": "D7_2_3",
              "gu": "D7_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She does a lot of internet research and also talks about the process with Vandana.",
              "hi": "वह इंटरनेट पर काफी शोध करती है और वंदना के साथ प्रक्रिया के बारे में भी बात करती है।",
              "ka": "ಅವಳು ಸಾಕಷ್ಟು ಇಂಟರ್ನೆಟ್ ಸಂಶೋಧನೆಗಳನ್ನು ಮಾಡುತ್ತಾಳೆ ಮತ್ತು ವಂದನಾ ಅವರೊಂದಿಗೆ ಪ್ರಕ್ರಿಯೆಯ ಬಗ್ಗೆ ಮಾತನಾಡುತ್ತಾಳೆ.",
              "te": "ఆమె ఇంటర్నెట్ లో చాలా రీసర్చ్ చేస్తుంది మరియు వందనతో కూడా ప్రక్రియ గురించి మాట్లాడుతుంది.",
              "or": "ସେ ବହୁତ ଇଣ୍ଟରନେଟ୍ ଗବେଷଣା କରନ୍ତି ଏବଂ ଭନ୍ଦନା ସହିତ ପ୍ରକ୍ରିୟା ବିଷୟରେ ମଧ୍ୟ ଆଲୋଚନା କରନ୍ତି |",
              "as": "তাই ইণ্টাৰনেটত বহুতো গৱেষণা কৰে আৰু বন্দনাৰ সৈতে প্ৰক্ৰিয়াটোৰ বিষয়ে কথাও পাতে।",
              "gu": "તેણી ઇન્ટરનેટ પર ઘણું રિસર્ચ કરે છે અને વંદના સાથે આ પ્રક્રિયા વિશે વાત પણ કરે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_2_4",
              "hi": "D7_2_4",
              "ka": "D7_2_4",
              "te": "D7_2_4",
              "or": "D7_2_4",
              "as": "D7_2_4",
              "gu": "D7_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Since she has yet to transition completely as a woman, she cannot change her gender identity to a ‘female’ but she can change it to ‘transgender’.",
              "hi": "चूंकि उसने अभी तक एक महिला के रूप में पूरी तरह से ट्रांज़िशन/ बदलाव नहीं किया है, इसलिए वह अपनी लिंग पहचान को 'महिला' में नहीं बदल सकती है, लेकिन वह इसे 'ट्रांसजेंडर' में बदल सकती है।",
              "ka": "ಅವಳು ಇನ್ನೂ ಮಹಿಳೆಯಾಗಿ ಸಂಪೂರ್ಣವಾಗಿ ಪರಿವರ್ತನೆಗೊಳ್ಳದ ಕಾರಣ, ಅವಳು ತನ್ನ ಲಿಂಗ ಗುರುತನ್ನು 'ಹೆಣ್ಣು' ಎಂದು ಬದಲಾಯಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ ಆದರೆ ಅವಳು ಅದನ್ನು 'ಟ್ರಾನ್ಸ್ಜೆಂಡರ್' ಎಂದು ಬದಲಾಯಿಸಬಹುದು.",
              "te": "ఆమె ఇంకా పూర్తిగా స్త్రీగా మారలేదు కాబట్టి, ఆమె తన లింగ గుర్తింపును 'ఆడ'గా మార్చుకోలేకపోతుంది, కానీ ఆమె దానిని 'ట్రాన్స్‌జెండర్'గా మార్చగలదు.",
              "or": "ଯେହେତୁ ସେ ଏପର୍ଯ୍ୟନ୍ତ ଜଣେ ମହିଳା ଭାବରେ ସଂପୂର୍ଣ୍ଣ ରୂପାନ୍ତରିତ ହୋଇନାହାଁନ୍ତି, ସେ ନିଜର ଲିଙ୍ଗ ପରିଚୟକୁ ଏକ ‘ମହିଳା’ରେ ପରିବର୍ତ୍ତନ କରିପାରିବେ ନାହିଁ କିନ୍ତୁ ସେ ଏହାକୁ‘ ଟ୍ରାନ୍ସଜେଣ୍ଡର’ରେ ପରିବର୍ତ୍ତନ କରିପାରିବେ |",
              "as": "যিহেতু তাই এতিয়াও এগৰাকী মহিলা হিচাপে সম্পূৰ্ণৰূপে পৰিৱৰ্তন কৰিবলৈ বাকী আছে, তাই তাইৰ লিংগ পৰিচয় 'মহিলা' লৈ সলনি কৰিব নোৱাৰে কিন্তু তাই ইয়াক 'ট্ৰেন্সজেণ্ডাৰ'লৈ সলনি কৰিব পাৰে।",
              "gu": "તેણીએ હજુ સુધી એક મહિલા તરીકે સંપૂર્ણપણે ટ્રાન્સિશન કરવાનું બાકી હોવાથી, તે પોતાની લિંગની ઓળખને 'સ્ત્રી'માં બદલી શકતી નથી પરંતુ તે તેને 'ટ્રાન્સલિંગ'માં બદલી શકે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_2_5_notebook",
              "hi": "D7_2_5_नोटबुक ",
              "ka": "D7_2_5_notebook",
              "te": "D7_2_5_నోట్‌బుక్",
              "or": "D7_2_5_notebook",
              "as": "D7_2_5_টোকাবহী",
              "gu": "D7_2_5_નોટબુક"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to read through the legal aspects for her transition:",
              "hi": "उसके ट्रांज़िशन/ बदलाव के कानूनी पहलुओं को पढ़ने के लिए क्लिक करें:",
              "ka": "ಆಕೆಯ ಪರಿವರ್ತನೆಗಾಗಿ ಕಾನೂನು ಅಂಶಗಳ ಮೂಲಕ ಓದಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "ఆమె పరివర్తనకు సంబంధించిన చట్టపరమైన అంశాలను చదవడానికి క్లిక్ చేయండి:",
              "or": "ତା’ର ପରିବର୍ତ୍ତନ ପାଇଁ ଆଇନଗତ ଦିଗଗୁଡିକ ପଢିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "তাইৰ পৰিৱৰ্তনৰ বাবে আইনী দিশবোৰ পঢ়িবলৈ ক্লিক কৰক:",
              "gu": "તેણીના ટ્રાન્સિશન માટેના કાનૂની પાસાઓ વાંચવા માટે ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Page 1",
              "hi": "पृष्ठ 1",
              "ka": "ಪುಟ 1",
              "te": "పేజీ 1",
              "or": "Page 1",
              "as": "পৃষ্ঠা ১",
              "gu": "પેજ 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Page 2",
              "hi": "पृष्ठ 2",
              "ka": "ಪುಟ 2",
              "te": "పేజీ 2",
              "or": "Page 2",
              "as": "পৃষ্ঠা ২",
              "gu": "પેજ 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "Page 3",
              "hi": "पृष्ठ 3",
              "ka": "ಪುಟ 3",
              "te": "పేజీ 3",
              "or": "Page 3",
              "as": "পৃষ্ঠা ৩",
              "gu": "પેજ 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.popUpText",
            "value": {
              "en": "Before they begin the process, Asmita gets a medical examination and certificate issued by a government hospital about her hormone treatment and other reports.",
              "hi": "प्रक्रिया शुरू करने से पहले, अस्मिता को एक सरकारी अस्पताल द्वारा उसके हार्मोन उपचार और अन्य रिपोर्टों के बारे में एक चिकित्सा परीक्षण और प्रमाण पत्र जारी किया जाता है।",
              "ka": "ಅವಳು ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪ್ರಾರಂಭಿಸುವ ಮೊದಲು, ಅಸ್ಮಿತಾ ಅವರ ಹಾರ್ಮೋನ್ ಚಿಕಿತ್ಸೆ ಮತ್ತು ಇತರ ವರದಿಗಳ ಬಗ್ಗೆ ಸರ್ಕಾರಿ ಆಸ್ಪತ್ರೆಯಿಂದ ವೈದ್ಯಕೀಯ ಪರೀಕ್ಷೆ ಮತ್ತು ಪ್ರಮಾಣಪತ್ರವನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
              "te": "వారు ప్రక్రియను ప్రారంభించే ముందు, అస్మిత తన హార్మోన్ చికిత్స మరియు ఇతర రిపోర్ట్స్ గురించి ప్రభుత్వ ఆసుపత్రి నుండి వైద్య పరీక్ష మరియు సర్టిఫికేట్ పొందుతుంది.",
              "or": "ସେମାନେ ପ୍ରକ୍ରିୟା ଆରମ୍ଭ କରିବା ପୂର୍ବରୁ, ଅସ୍ମିତା ତାଙ୍କ ହର୍‌ମୋନ୍ ଚିକିତ୍ସା ଏବଂ ଅନ୍ୟାନ୍ୟ ରିପୋର୍ଟ ବିଷୟରେ ସରକାରୀ ଡାକ୍ତରଖାନା ଦ୍ୱାରା ଏକ ଡାକ୍ତରୀ ପରୀକ୍ଷା ଏବଂ ପ୍ରମାଣପତ୍ର ଦିଆଯାଇଛି |",
              "as": "তেওঁলোকে প্ৰক্ৰিয়াটো আৰম্ভ কৰাৰ আগতে, অস্মিতাই তাইৰ হৰমন চিকিৎসা আৰু অন্যান্য প্ৰতিবেদনৰ বিষয়ে চৰকাৰী চিকিৎসালয়ৰ দ্বাৰা জাৰী কৰা চিকিৎসা পৰীক্ষা আৰু প্ৰমাণপত্ৰ লাভ কৰে।",
              "gu": "તેઓ પ્રક્રિયા શરૂ કરે તે પહેલાં, અસ્મિતાને તેની હોર્મોન સારવાર અને અન્ય અહેવાલો વિશે સરકારી હોસ્પિટલ દ્વારા જારી કરાયેલ તબીબી તપાસનાં અને અન્ય પ્રમાણપત્ર મળે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.popUpText",
            "value": {
              "en": "She applies for gender change through the transgender portal by the government of India. She submits the form in person to the District Magistrate.",
              "hi": "वह भारत सरकार द्वारा ट्रांसजेंडर पोर्टल के द्वारा लिंग परिवर्तन के लिए आवेदन करती है। वह व्यक्तिगत रूप से जिला मजिस्ट्रेट को फॉर्म जमा करती है।",
              "ka": "ಅವಳು ಭಾರತ ಸರ್ಕಾರದಿಂದ ಟ್ರಾನ್ಸ್ಜೆಂಡರ್ ಪೋರ್ಟಲ್ ಮೂಲಕ ಲಿಂಗ ಬದಲಾವಣೆಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಾಳೆ. ಅವಳು ಫಾರ್ಮ್ ಅನ್ನು ಜಿಲ್ಲಾ ಮ್ಯಾಜಿಸ್ಟ್ರೇಟ್‌ಗೆ ವೈಯಕ್ತಿಕವಾಗಿ ಸಲ್ಲಿಸುತ್ತಾಳೆ.",
              "te": "ఆమె భారత ప్రభుత్వం యొక్క ట్రాన్స్‌జెండర్ పోర్టల్ ద్వారా లింగ మార్పు కోసం అప్లై చేసింది. ఆమె జిల్లా మేజిస్ట్రేట్‌కు వ్యక్తిగతంగా ఫారమ్‌ను సమర్పించింది.",
              "or": "ସେ ଭାରତ ସରକାରଙ୍କ ଦ୍ୱାରା ଟ୍ରାନ୍ସଜେଣ୍ଡର ପୋର୍ଟାଲ ମାଧ୍ୟମରେ ଲିଙ୍ଗ ପରିବର୍ତ୍ତନ ପାଇଁ ଆବେଦନ କରିଛନ୍ତି। ସେ ବ୍ୟକ୍ତିଗତ ଭାବେ ଫର୍ମକୁ ଜିଲ୍ଲା ମାଜିଷ୍ଟ୍ରେଟଙ୍କ ନିକଟରେ ଦାଖଲ କରନ୍ତି |",
              "as": "তাই ভাৰত চৰকাৰৰ দ্বাৰা ট্ৰেন্সজেণ্ডাৰ পৰ্টেলৰ জৰিয়তে লিংগ পৰিৱৰ্তনৰ বাবে আবেদন কৰে। তাই প্ৰপত্ৰখন ব্যক্তিগতভাৱে জিলা দণ্ডাধীশৰ ওচৰত দাখিল কৰে।",
              "gu": "તેણી ભારત સરકાર દ્વારા ટ્રાન્સલિંગ પોર્ટલ દ્વારા લિંગ પરિવર્તન માટે અરજી કરે છે.  તેણી જીલ્લા મેજીસ્ટ્રેટને રૂબરૂમાં ફોર્મ સબમિટ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.popUpText",
            "value": {
              "en": "Once she gets the transgender identity card, she changes her registered gender in her other government documents. She is not very happy with the transgender identity and wants to change it to ‘female’ as soon as possible.",
              "hi": "एक बार जब उसे ट्रांसजेंडर पहचान पत्र मिल जाता है, तो वह अपने अन्य सरकारी दस्तावेजों में अपना पंजीकृत लिंग बदल लेती है। वह ट्रांसजेंडर पहचान से बहुत खुश नहीं हैं और इसे जल्द से जल्द 'महिला' में बदलना चाहती हैं।",
              "ka": "ಅವಳು ಟ್ರಾನ್ಸ್ಜೆಂಡರ್ ಗುರುತಿನ ಚೀಟಿಯನ್ನು ಪಡೆದ ನಂತರ, ಅವಳು ತನ್ನ ಇತರ ಸರ್ಕಾರಿ ದಾಖಲೆಗಳಲ್ಲಿ ತನ್ನ ನೋಂದಾಯಿತ ಲಿಂಗವನ್ನು ಬದಲಾಯಿಸುತ್ತಾಳೆ. ಟ್ರಾನ್ಸ್ಜೆಂಡರ್ ಗುರುತಿನ ಬಗ್ಗೆ ಅವಳು ತುಂಬಾ ಸಂತೋಷವಾಗಿಲ್ಲ ಮತ್ತು ಸಾಧ್ಯವಾದಷ್ಟು ಬೇಗ ಅದನ್ನು 'ಹೆಣ್ಣು' ಎಂದು ಬದಲಾಯಿಸಲು ಬಯಸುತ್ತಾಳೆ.",
              "te": "ఆమె లింగమార్పిడి గుర్తింపు కార్డును పొందిన తర్వాత, ఆమె తన ఇతర ప్రభుత్వ పత్రాలలో తన రిజిస్టర్డ్ లింగాన్ని మారుస్తుంది. ఆమె లింగమార్పిడి గుర్తింపుతో సంతోషంగా లేదు మరియు వీలైనంత త్వరగా దానిని 'ఆడ'గా మార్చాలని కోరుకుంటుంది.",
              "or": "ଥରେ ସେ ଟ୍ରାନ୍ସଜେଣ୍ଡର ପରିଚୟ ପତ୍ର ପାଇବା ପରେ, ସେ ତାଙ୍କର ଅନ୍ୟାନ୍ୟ ସରକାରୀ ଦଲିଲରେ ପଞ୍ଜୀକୃତ ଲିଙ୍ଗ ପରିବର୍ତ୍ତନ କରନ୍ତି | ସେ ଟ୍ରାନ୍ସଜେଣ୍ଡର ପରିଚୟକୁ ନେଇ ଅତ୍ୟନ୍ତ ଖୁସି ନୁହଁନ୍ତି ଏବଂ ଏହାକୁ ଯଥାଶୀଘ୍ର ‘ମହିଳା’ରେ ପରିବର୍ତ୍ତନ କରିବାକୁ ଚାହୁଁଛନ୍ତି |",
              "as": "এবাৰ তাই ট্ৰেন্সজেণ্ডাৰ পৰিচয় পত্ৰ লাভ কৰাৰ পিছত, তাই তাইৰ আন চৰকাৰী নথিপত্ৰত তাইৰ পঞ্জীভুক্ত লিংগ সলনি কৰে। তেওঁ ট্ৰেন্সজেণ্ডাৰ পৰিচয়ৰ সৈতে বৰ সুখী নহয় আৰু যিমান সম্ভৱ সোনকালে ইয়াক 'মহিলা'লৈ সলনি কৰিব বিচাৰে।",
              "gu": "એકવાર તેણીને ટ્રાન્સલિંગ ઓળખ કાર્ડ મળી જાય, તે તેના અન્ય સરકારી દસ્તાવેજોમાં તેણીનું નોંધાયેલ લિંગ બદલી નાખે છે.  તે ટ્રાન્સલિંગ ઓળખથી બહુ ખુશ નથી અને શક્ય તેટલી વહેલી તકે તેને 'સ્ત્રી'માં બદલવા માંગે છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_3_1",
              "hi": "D7_3_1",
              "ka": "D7_3_1",
              "te": "D7_3_1",
              "or": "D7_3_1",
              "as": "D7_3_1",
              "gu": "D7_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Pranav and Roshni ask her to take it one step at a time. Both the siblings divide the work to help Asmita by dividing the paperwork and travelling to the different government offices.",
              "hi": "प्रणव और रोशनी उसे एक बार में एक कदम उठाने के लिए कहते हैं। दोनों भाई-बहन अस्मिता की सहायता के लिए कागजी कार्रवाई बांटकर और अलग-अलग सरकारी दफ्तरों में जाते हैं।",
              "ka": "ಪ್ರಣವ್ ಮತ್ತು ರೋಶ್ನಿ ಅವಳನ್ನು ಒಂದೊಂದೇ ಹೆಜ್ಜೆ ಇಡುವಂತೆ ಕೇಳುತ್ತಾರೆ. ಅಸ್ಮಿತಾಗೆ ಸಹಾಯ ಮಾಡಲು ಒಡಹುಟ್ಟಿದವರಿಬ್ಬರೂ ಕೆಲಸವನ್ನು ಹಂಚುತ್ತಾರೆ, ಕಾಗದದ ಕೆಲಸವನ್ನು ವಿಂಗಡಿಸಿ ಮತ್ತು ವಿವಿಧ ಸರ್ಕಾರಿ ಕಚೇರಿಗಳಿಗೆ ಪ್ರಯಾಣಿಸುತ್ತಾರೆ.",
              "te": "ప్రణవ్ మరియు రోష్ని ఆమెను ఒక్కో స్టెప్ చేయమని చెప్తారు. తోబుట్టువులిద్దరూ అస్మితకు సహాయం చేయడానికి పత్రాలను విభజించడం మరియు వేర్వేరు ప్రభుత్వ కార్యాలయాలకు వెళ్లడం ద్వారా పనిని పంచుకుంటారు.",
              "or": "ପ୍ରଣବ ଏବଂ ରୋଶନୀ ତାଙ୍କୁ ଗୋଟିଏ ପରେ ଗୋଟିଏ ପଦକ୍ଷେପ ନେବାକୁ କୁହନ୍ତି | ଉଭୟ ଭାଇଭଉଣୀ କାଗଜପତ୍ରକୁ ଭାଗ ଭାଗ କରି ବିଭିନ୍ନ ସରକାରୀ କାର୍ଯ୍ୟାଳୟକୁ ଯାଇ ଅସ୍ମିତାଙ୍କୁ ସାହାଯ୍ୟ କରିବା ପାଇଁ କାର୍ଯ୍ୟକୁ ଭାଗ କରନ୍ତି |",
              "as": "প্ৰণৱ আৰু ৰোশনিয়ে তাইক এবাৰত এটা খোজ দিবলৈ কৈছিল। দুয়োজন ভাই-ভনীয়ে কাগজপত্ৰ বিভাজন কৰি আৰু বিভিন্ন চৰকাৰী অফিচলৈ গৈ অস্মিতাক সহায় কৰিবলৈ কামটো ভগাই লয়।",
              "gu": "પ્રણવ અને રોશની તેને એક સમયે એક પગલું ભરવાનું કહે છે.  બંને ભાઈ-બહેન અસ્મિતાને મદદ કરવા માટે કાગળિયાં વહેંચીને અલગ-અલગ સરકારી ઑફિસમાં જાય છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_3_2",
              "hi": "D7_3_2",
              "ka": "D7_3_2",
              "te": "D7_3_2",
              "or": "D7_3_2",
              "as": "D7_3_2",
              "gu": "D7_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Through all this, she continues to prepare for her GMAT exams. To help Asmita decide the colleges she should apply for, Pranav suggests attending a Webinar on MBA in foreign universities.",
              "hi": "इस सब के द्वारा, वह अपनी जीमैट परीक्षा की तैयारी जारी रखती है। अस्मिता को यह तय करने में सहायता करने के लिए कि उसे किन कॉलेजों के लिए आवेदन करना चाहिए, प्रणव विदेशी विश्वविद्यालयों में एमबीए पर एक वेबिनार में भाग लेने का सुझाव देता है।",
              "ka": "ಈ ಎಲ್ಲದರ ಮೂಲಕ, ಅವಳು ತನ್ನ GMAT ಪರೀಕ್ಷೆಗಳಿಗೆ ತಯಾರಿ ಮುಂದುವರೆಸುತ್ತಾಳೆ. ಅಸ್ಮಿತಾ ಅವರು ಅರ್ಜಿ ಸಲ್ಲಿಸಬೇಕಾದ ಕಾಲೇಜುಗಳನ್ನು ನಿರ್ಧರಿಸಲು ಸಹಾಯ ಮಾಡಲು, ಪ್ರಣವ್ ವಿದೇಶಿ ವಿಶ್ವವಿದ್ಯಾನಿಲಯಗಳಲ್ಲಿ ಎಂಬಿಎ ನಲ್ಲಿ ವೆಬ್‌ನಾರ್‌ಗೆ ಹಾಜರಾಗಲು ಸೂಚಿಸುತ್ತಾರೆ.",
              "te": "వీటన్నింటి ద్వారా, ఆమె తన GMAT పరీక్షలకు సిద్ధమవుతూనే ఉంటుంది. అస్మిత అప్లై చేయవలసిన కాలేజీలను నిర్ణయించడంలో సహాయపడటానికి, ప్రణవ్ విదేశీ విశ్వవిద్యాలయాలలో MBA పై వెబినార్‌కు హాజరు కావాలని సూచించాడు.",
              "or": "ଏସବୁ ଜରିଆରେ ସେ ନିଜର GMAT ପରୀକ୍ଷା ପାଇଁ ପ୍ରସ୍ତୁତି ଜାରି ରଖିଛନ୍ତି | ଅସ୍ମିତା କେଉଁ କଲେଜଗୁଡ଼ିକରେ ଆବେଦନ କରିବା ଉଚିତ୍ ତାହା ସ୍ଥିର କରିବାରେ ସାହାଯ୍ୟ କରିବାକୁ, ପ୍ରଣବ ବିଦେଶୀ ବିଶ୍ୱବିଦ୍ୟାଳୟରେ MBA ରେ ଏକ ୱେବିନିନରରେ ଯୋଗଦେବାକୁ ପରାମର୍ଶ ଦେଇଛନ୍ତି |",
              "as": "এই সকলোবোৰৰ মাজতে, তাই তাইৰ GMAT পৰীক্ষাৰ বাবে প্ৰস্তুতিও চলাই আছে। অস্মিতাক তেওঁ আবেদন কৰিব লগা কলেজবোৰ নিৰ্ণয় কৰাত সহায় কৰিবলৈ, প্ৰণৱে বিদেশী বিশ্ববিদ্যালয়ত MBAৰ ৱেবিনাৰত উপস্থিত থাকিবলৈ পৰামৰ্শ দিয়ে।",
              "gu": "આ બધા દ્વારા, તેણી તેની GMAT પરીક્ષાઓની તૈયારી કરવાનું ચાલુ રાખે છે.  અસ્મિતાએ કઈ કૉલેજ માટે અરજી કરવી જોઈએ તે નક્કી કરવામાં મદદ કરવા માટે, પ્રણવ વિદેશી યુનિવર્સિટીઓમાં MBA પર વેબિનારમાં હાજરી આપવાની સલાહ આપે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_4_1",
              "hi": "D7_4_1",
              "ka": "D7_4_1",
              "te": "D7_4_1",
              "or": "D7_4_1",
              "as": "D7_4_1",
              "gu": "D7_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The Webinar has education consultants and students from a few Universities talking about the admission process, job opportunities, scholarships.",
              "hi": "वेबिनार में शिक्षा सलाहकार और कुछ विश्वविद्यालयों के छात्र प्रवेश प्रक्रिया, नौकरी के अवसरों, छात्रवृत्ति के बारे में बात कर रहे हैं।",
              "ka": "ವೆಬೈನರ್ ಶಿಕ್ಷಣ ಸಲಹೆಗಾರರು ಮತ್ತು ಕೆಲವು ವಿಶ್ವವಿದ್ಯಾಲಯಗಳ ವಿದ್ಯಾರ್ಥಿಗಳು ಪ್ರವೇಶ ಪ್ರಕ್ರಿಯೆ, ಉದ್ಯೋಗ ಅವಕಾಶಗಳು, ವಿದ್ಯಾರ್ಥಿವೇತನಗಳ ಬಗ್ಗೆ ಮಾತನಾಡುತ್ತಿದ್ದಾರೆ.",
              "te": "వెబినార్‌లో అడ్మిషన్ ప్రక్రియ, ఉద్యోగ అవకాశాలు, స్కాలర్‌షిప్‌ల గురించి కొన్ని యూనివర్సిటీల నుండి ఎడ్యుకేషన్ కన్సల్టెంట్స్ మరియు విద్యార్థులు మాట్లాడుతున్నారు.",
              "or": "ୱେବିନିନରରେ ଶିକ୍ଷା ପରାମର୍ଶଦାତା ଏବଂ କିଛି ବିଶ୍ୱବିଦ୍ୟାଳୟର ଛାତ୍ରମାନେ ଆଡମିଶନ ପ୍ରକ୍ରିୟା, ଚାକିରି ସୁଯୋଗ, ବୃତ୍ତି ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି |",
              "as": "ৱেবিনাৰত শিক্ষা পৰামৰ্শদাতা আৰু কেইখনমান বিশ্ববিদ্যালয়ৰ শিক্ষাৰ্থী আছে যিকেইজনে নামভৰ্তি প্ৰক্ৰিয়া, চাকৰিৰ সুযোগ, বৃত্তিৰ বিষয়ে কথা পাতি আছে।",
              "gu": "વેબિનારમાં એજ્યુકેશન કન્સલ્ટન્ટ્સ અને કેટલીક યુનિવર્સિટીઓના વિદ્યાર્થીઓ છે જે પ્રવેશ પ્રક્રિયા, નોકરીની તકો, સ્કૉલરશીપ વિશે વાત કરે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_4_2",
              "hi": "D7_4_2",
              "ka": "D7_4_2",
              "te": "D7_4_2",
              "or": "D7_4_2",
              "as": "D7_4_2",
              "gu": "D7_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The students also talk about their experience of studying in these universities.",
              "hi": "छात्र इन विश्वविद्यालयों में अध्ययन के अपने अनुभव के बारे में भी बताते हैं।",
              "ka": "ವಿದ್ಯಾರ್ಥಿಗಳು ಈ ವಿಶ್ವವಿದ್ಯಾನಿಲಯಗಳಲ್ಲಿ ಅಧ್ಯಯನ ಮಾಡಿದ ಅನುಭವದ ಬಗ್ಗೆಯೂ ಮಾತನಾಡುತ್ತಾರೆ.",
              "te": "విద్యార్థులు ఆ యూనివర్సిటీలలో చదివిన వారి అనుభవాన్ని కూడా చెప్పారు.",
              "or": "ଛାତ୍ରମାନେ ଏହି ବିଶ୍ୱବିଦ୍ୟାଳୟରେ ଅଧ୍ୟୟନ କରିବାର ଅଭିଜ୍ଞତା ବିଷୟରେ ମଧ୍ୟ ଆଲୋଚନା କରନ୍ତି |",
              "as": "শিক্ষাৰ্থীসকলে এই বিশ্ববিদ্যালয়সমূহত অধ্যয়ন কৰাৰ অভিজ্ঞতাৰ বিষয়েও কয়।",
              "gu": "વિદ્યાર્થીઓ આ યુનિવર્સિટીઓમાં અભ્યાસ કરવાના પોતાના અનુભવ વિશે પણ વાત કરે છે."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_4_3",
              "hi": "D7_4_3",
              "ka": "D7_4_3",
              "te": "D7_4_3",
              "or": "D7_4_3",
              "as": "D7_4_3",
              "gu": "D7_4_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is glad she attended the Webinar. She now knows that she must focus on getting a good GMAT score, request recommendation letters, and write good essays.",
              "hi": "अस्मिता खुश हैं कि उन्होंने वेबिनार में भाग लिया। अब वह जानती है कि उसे एक अच्छा जीमैट स्कोर प्राप्त करने, अनुशंसा पत्रों का अनुरोध करने और अच्छे निबंध लिखने पर ध्यान देना चाहिए।",
              "ka": "ಅಸ್ಮಿತಾ ವೆಬ್ನಾರ್‌ಗೆ ಹಾಜರಾಗಿದ್ದಕ್ಕೆ ಖುಷಿಯಾಗಿದ್ದಾಳೆ. ಅವಳು ಉತ್ತಮ GMAT ಸ್ಕೋರ್ ಪಡೆಯುವುದು, ಶಿಫಾರಸು ಪತ್ರಗಳನ್ನು ವಿನಂತಿಸುವುದು ಮತ್ತು ಉತ್ತಮ ಪ್ರಬಂಧಗಳನ್ನು ಬರೆಯಲು ಗಮನಹರಿಸಬೇಕು ಎಂದು ಅವಳು ಈಗ ತಿಳಿದಿದ್ದಾಳೆ.",
              "te": "అస్మిత వెబినార్‌కు హాజరైనందుకు సంతోషంగా ఉంది. ఆమె మంచి GMAT స్కోర్‌ సాధించడం, రికమెండేషన్ లేఖలను అభ్యర్థించడం మరియు మంచి వ్యాసాలు రాయడంపై దృష్టి పెట్టాలని ఆమెకు ఇప్పుడు తెలుసు.",
              "or": "ଅସ୍ମିତା ୱେବିନିନରରେ ଯୋଗ ଦେଇ ଖୁସି | ସେ ବର୍ତ୍ତମାନ ଜାଣିଛନ୍ତି ଯେ ସେ ନିଶ୍ଚିତ ଭାବରେ ଏକ ଭଲ GMAT ସ୍କୋର ପାଇବାକୁ ଧ୍ୟାନ ଦେବେ, ସୁପାରିଶ ପତ୍ର ଅନୁରୋଧ କରିବେ ଏବଂ ଭଲ ପ୍ରବନ୍ଧ ଲେଖିବେ |",
              "as": "অস্মিতা সুখী যে তাই ৱেবিনাৰত উপস্থিত আছিল। তাই এতিয়া জানে যে তাই GMAT ভাল নম্বৰ পাবলৈ, অনুৰোধ কৰি পৰামৰ্শপত্ৰ লিখা, আৰু ভাল প্ৰবন্ধ লিখাত গুৰুত্ব দিব লাগিব।",
              "gu": "અસ્મિતા ખુશ છે કે તેણીએ વેબિનારમાં હાજરી આપી.  તેણી હવે જાણે છે કે તેણીએ સારો GMAT સ્કોર મેળવવા, ભલામણ પત્રોની વિનંતી કરવા અને સારા નિબંધો લખવા પર ધ્યાન કેન્દ્રિત કરવું જોઈએ."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_4_4",
              "hi": "D7_4_4",
              "ka": "D7_4_4",
              "te": "D7_4_4",
              "or": "D7_4_4",
              "as": "D7_4_4",
              "gu": "D7_4_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita finally appears for the GMAT exam. She walks out of the examination centre feeling good about her performance.",
              "hi": "अस्मिता आखिरकार जीमैट परीक्षा के लिए उपस्थित होती है। वह अपने प्रदर्शन के बारे में अच्छा अनुभव करते हुए परीक्षा केंद्र से बाहर चली जाती है।",
              "ka": "ಅಸ್ಮಿತಾ ಅಂತಿಮವಾಗಿ GMAT ಪರೀಕ್ಷೆಗೆ ಹಾಜರಾಗುತ್ತಾಳೆ. ಅವಳು ತನ್ನ ಕಾರ್ಯಕ್ಷಮತೆಯ ಬಗ್ಗೆ ಉತ್ತಮ ಭಾವನೆಯಿಂದ ಪರೀಕ್ಷಾ ಕೇಂದ್ರದಿಂದ ಹೊರನಡೆದಳು.",
              "te": "అస్మిత చివరకు GMAT పరీక్షకు హాజరవుతుంది. ఆమె తన పనితీరు గురించి మంచి అనుభూతి చెందుతూ పరీక్షా కేంద్రం నుండి బయటకు వెళ్లింది.",
              "or": "ଅସ୍ମିତା ଶେଷରେ GMAT ପରୀକ୍ଷା ଦେଇଛନ୍ତି | ସେ ନିଜ ପ୍ରଦର୍ଶନ ବିଷୟରେ ଭଲ ଅନୁଭବ କରି ପରୀକ୍ଷା କେନ୍ଦ୍ରରୁ ବାହାରକୁ ଯାଆନ୍ତି |",
              "as": "অৱশেষত অস্মিতা GMAT পৰীক্ষাত বহে। তাই বহুত ভালদৰে পৰীক্ষা দি পৰীক্ষা কেন্দ্ৰৰ পৰা ওলাই আহে।",
              "gu": "અસ્મિતા આખરે GMAT ની પરીક્ષા આપે છે.  તેણી પોતાના પ્રદર્શન વિશે સારી લાગણી અનુભવતા પરીક્ષા કેન્દ્રની બહાર નીકળી જાય છે."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_5_1_reveal",
              "hi": "D7_5_1_रिवील ",
              "ka": "D7_5_1_reveal",
              "te": "D7_5_1_reveal",
              "or": "D7_5_1_reveal",
              "as": "D7_5_1_প্ৰকাশ কৰে",
              "gu": "D7_5_1_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal Asmita’s score.",
              "hi": "अस्मिता के स्कोर को प्रकट करने के लिए क्लिक करें।",
              "ka": "ಅಸ್ಮಿತಾ ಅವರ ಅಂಕವನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "అస్మిత స్కోర్‌ని వెల్లడించడానికి క్లిక్ చేయండి.",
              "or": "ଅସ୍ମିତାଙ୍କ ସ୍କୋର ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "অস্মিতাৰ নম্বৰ প্ৰকাশ কৰিবলৈ ক্লিক কৰক।",
              "gu": "અસ્મિતાનો સ્કોર જાણવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Asmita’s score",
              "hi": "अस्मिता का स्कोर",
              "ka": "ಅಸ್ಮಿತಾ ಅಂಕ",
              "te": "అస్మిత స్కోరు",
              "or": "ଅସ୍ମିତାଙ୍କ ସ୍କୋର",
              "as": "অস্মিতাৰ নম্বৰ",
              "gu": "અસ્મિતાનો સ્કોર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "713",
              "hi": "713",
              "ka": "713",
              "te": "713",
              "or": "713",
              "as": "713",
              "gu": "713"
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7_5_2_checklist",
              "hi": "D7_5_2_चेकलिस्ट ",
              "ka": "D7_5_2_checklist",
              "te": "D7_5_2_చెక్‌లిస్ట్",
              "or": "D7_5_2_checklist",
              "as": "D7_5_2_চেকলিষ্ট",
              "gu": "D7_5_2_યાદી તપાસો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Asmita makes a checklist for the next steps in her MBA application process. \nClick on each button to see what Asmita decides.",
              "hi": "अस्मिता अपनी एमबीए आवेदन प्रक्रिया के अगले चरणों के लिए एक चेकलिस्ट बनाती है।\nअस्मिता क्या निर्णय लेती है यह देखने के लिए प्रत्येक बटन पर क्लिक करें।",
              "ka": "\"ಅಸ್ಮಿತಾ ತನ್ನ ಎಂಬಿಎ ಅರ್ಜಿ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ಮುಂದಿನ ಹಂತಗಳಿಗಾಗಿ ಪರಿಶೀಲನಾಪಟ್ಟಿಯನ್ನು ತಯಾರಿಸುತ್ತಾಳೆ.\n ಅಸ್ಮಿತಾ ಏನು ನಿರ್ಧರಿಸುತ್ತಾಳೆ ಎಂಬುದನ್ನು ನೋಡಲು ಪ್ರತಿ ಬಟನ್ ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "అస్మిత తన MBA అప్లికేషన్ ప్రక్రియలో తదుపరి దశల కోసం చెక్‌లిస్ట్‌ను తయారు చేస్తుంది.\n అస్మిత ఏ నిర్ణయం తీసుకుంటుందో చూడటానికి ప్రతి బటన్‌పై క్లిక్ చేయండి.",
              "or": "\"ଅସ୍ମିତା ତାଙ୍କ MBA ଆବେଦନ ପ୍ରକ୍ରିୟାରେ ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପ ପାଇଁ ଏକ ଯାଞ୍ଚ ତାଲିକା ପ୍ରସ୍ତୁତ କରନ୍ତି |\n ଅସ୍ମିତା କ’ଣ ନିଷ୍ପତ୍ତି ନେଉଛନ୍ତି ତାହା ଦେଖିବା ପାଇଁ ପ୍ରତ୍ୟେକ ବଟନ୍ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "অস্মিতাই তেওঁৰ MBA আবেদন প্ৰক্ৰিয়াৰ পৰৱৰ্তী পদক্ষেপবোৰৰ বাবে এখন চেকলিষ্ট প্ৰস্তুত কৰে। \n \n অস্মিতাই কি সিদ্ধান্ত লয় চাবলৈ প্ৰতিটো বুটামত ক্লিক কৰক।",
              "gu": "અસ્મિતા પોતાની MBA અરજી પ્રક્રિયામાં આગળના પગલાઓ માટે એક ચેકલિસ્ટ બનાવે છે.\n અસ્મિતા શું નિર્ણય લે છે તે જોવા માટે દરેક બટન પર ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "Apply to Universities",
              "hi": "विश्वविद्यालयों में आवेदन करे",
              "ka": "ವಿಶ್ವವಿದ್ಯಾಲಯಗಳಿಗೆ ಅನ್ವಯಿಸಿ",
              "te": "యూనివర్సిటీలకు అప్లై చేయండి",
              "or": "ବିଶ୍ୱବିଦ୍ୟାଳୟଗୁଡ଼ିକରେ ଆବେଦନ କରନ୍ତି",
              "as": "বিশ্ববিদ্যালয়সমূহত আবেদন কৰক",
              "gu": "યુનિવર્સિટીઓમાં અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Apply for Scholarship",
              "hi": "छात्रवृत्ति के लिए आवेदन करे",
              "ka": "ವಿದ್ಯಾರ್ಥಿವೇತನಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ",
              "te": "స్కాలర్‌షిప్ కోసం అప్లై చేసుకోండి",
              "or": "ଛାତ୍ରବୃତ୍ତି ପାଇଁ ଆବେଦନ କରନ୍ତି",
              "as": "বৃত্তিৰ বাবে আবেদন কৰক",
              "gu": "સ્કોલરશીપ માટે અરજી કરો"
            }
          }
        ]
      },
      "scene35": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7i_1_1",
              "hi": "D7i_1_1",
              "ka": "D7i_1_1",
              "te": "D7i_1_1",
              "or": "D7i_1_1",
              "as": "D7i_1_1",
              "gu": "D7i_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Many of these Universities also have good scholarships for students. Asmita knows how expensive studying abroad can be, and she works hard to get a scholarship.",
              "hi": "इनमें से कई विश्वविद्यालयों में छात्रों के लिए अच्छी छात्रवृत्तियां भी हैं। अस्मिता जानती है कि विदेश में पढ़ाई करना कितना महंगा हो सकता है और वह छात्रवृत्ति पाने के लिए कड़ी मेहनत करती है।",
              "ka": "ಈ ವಿಶ್ವವಿದ್ಯಾನಿಲಯಗಳಲ್ಲಿ ಹಲವು ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಉತ್ತಮ ವಿದ್ಯಾರ್ಥಿವೇತನವನ್ನು ಸಹ ಹೊಂದಿವೆ. ವಿದೇಶದಲ್ಲಿ ಓದುವುದು ಎಷ್ಟು ದುಬಾರಿ ಎಂದು ಅಸ್ಮಿತಾಗೆ ತಿಳಿದಿದೆ ಮತ್ತು ವಿದ್ಯಾರ್ಥಿವೇತನವನ್ನು ಪಡೆಯಲು ಅವಳು ಶ್ರಮಿಸುತ್ತಾಳೆ.",
              "te": "ఈ యూనివర్సిటీలలో చాలా వరకు విద్యార్థులకు మంచి స్కాలర్‌షిప్‌లు కూడా ఉన్నాయి. విదేశాల్లో చదువుకోవడం ఎంత ఖర్చుతో కూడుకున్నదో అస్మితకు తెలుసు మరియు స్కాలర్‌షిప్ పొందడానికి ఆమె చాలా కష్టపడుతుంది.",
              "or": "ଏହି ବିଶ୍ୱବିଦ୍ୟାଳୟଗୁଡ଼ିକ ମଧ୍ୟରୁ ଅନେକ ଛାତ୍ରଛାତ୍ରୀମାନଙ୍କ ପାଇଁ ଭଲ ବୃତ୍ତି ମଧ୍ୟ ରଖିଛି | ଅସ୍ମିତା ଜାଣନ୍ତି ଯେ ବିଦେଶରେ ପଢିବା କେତେ ମହଙ୍ଗା ହୋଇପାରେ, ଏବଂ ବୃତ୍ତି ପାଇବା ପାଇଁ ସେ କଠିନ ପରିଶ୍ରମ କରନ୍ତି |",
              "as": "ইয়াৰে বহুতো বিশ্ববিদ্যালয়ত শিক্ষাৰ্থীসকলৰ বাবে ভাল বৃত্তিও আছে। অস্মিতাই জানে যে বিদেশত অধ্যয়ন কৰাটো কিমান খৰচি হ'ব পাৰে, আৰু তেওঁ বৃত্তি পাবলৈ বহুত কষ্ট কৰে।",
              "gu": "આમાંની ઘણી યુનિવર્સિટીઓમાં વિદ્યાર્થીઓ માટે સારી સ્કોલરશીપ પણ છે.  અસ્મિતા જાણે છે કે વિદેશમાં ભણવું કેટલું મોંઘું હોઈ શકે છે અને તે સ્કોલરશીપ મેળવવા માટે સખત મહેનત કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7i_1_2",
              "hi": "D7i_1_2",
              "ka": "D7i_1_2",
              "te": "D7i_1_2",
              "or": "D7i_1_2",
              "as": "D7i_1_2",
              "gu": "D7i_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She has researched that a few International institutions provide scholarships to people from the LGBTQ+ community.",
              "hi": "उसने शोध किया है कि कुछ अंतरराष्ट्रीय संस्थान एलजीबीटीक्यू + समुदाय के लोगों को छात्रवृत्ति प्रदान करते हैं।",
              "ka": "ಕೆಲವು ಅಂತರರಾಷ್ಟ್ರೀಯ ಸಂಸ್ಥೆಗಳು LGBTQ+ ಸಮುದಾಯದ ಜನರಿಗೆ ವಿದ್ಯಾರ್ಥಿವೇತನವನ್ನು ಒದಗಿಸುತ್ತವೆ ಎಂದು ಅವಳು ಸಂಶೋಧಿಸಿದ್ದಾಳೆ.",
              "te": "LGBTQ+ కమ్యూనిటీకి చెందిన వ్యక్తులకు కొన్ని అంతర్జాతీయ సంస్థలు స్కాలర్‌షిప్‌లను అందజేస్తాయని ఆమె రీసర్చ్ తో తెలుసుకుంది.",
              "or": "ସେ ଅନୁସନ୍ଧାନ କରିଛନ୍ତି ଯେ କିଛି ଅନ୍ତର୍ଜାତୀୟ ଅନୁଷ୍ଠାନ LGBTQ + ସମ୍ପ୍ରଦାୟର ଲୋକଙ୍କୁ ଛାତ୍ରବୃତ୍ତି ପ୍ରଦାନ କରେ |",
              "as": "তেওঁ গৱেষণা কৰিছে যে কেইটামান আন্তৰ্জাতিক প্ৰতিষ্ঠানে LGBTQ+ সম্প্ৰদায়ৰ লোকসকলক বৃত্তি প্ৰদান কৰে।",
              "gu": "તેણીએ રિસર્ચ કર્યું છે કે કેટલીક આંતરરાષ્ટ્રીય સંસ્થાઓ LGBTQ+ સમુદાયના લોકોને સ્કોલરશીપ પ્રદાન કરે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7i_1_3_apply",
              "hi": "D7i_1_3_अप्लाइ ",
              "ka": "D7i_1_3_apply",
              "te": "D7i_1_3_అప్లై",
              "or": "D7i_1_3_apply",
              "as": "D7i_1_3_আবেদন",
              "gu": "D7i_1_3_અરજી કરો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to apply for these scholarships:",
              "hi": "इन छात्रवृत्ति के लिए आवेदन करने के लिए क्लिक करें:",
              "ka": "ಈ ವಿದ್ಯಾರ್ಥಿವೇತನಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "ఈ స్కాలర్‌షిప్‌ల కోసం అప్లై చేయడానికి క్లిక్ చేయండి:",
              "or": "ଏହି ବୃତ୍ତି ପାଇଁ ଆବେଦନ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତି:",
              "as": "এই বৃত্তিবোৰৰ বাবে আবেদন কৰিবলৈ ক্লিক কৰক:",
              "gu": "આ સ્કોલરશીપો માટે અરજી કરવા માટે ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "The Point Foundation",
              "hi": "द पॉइंट फाउंडेशन",
              "ka": "ದಿ ಪಾಯಿಂಟ್ ಫೌಂಡೇಶನ್",
              "te": "ది పాయింట్ ఫౌండేషన్",
              "or": "ପଏଣ୍ଟ ଫାଉଣ୍ଡେସନ୍",
              "as": "দ্য পইণ্ট ফাউণ্ডেচন",
              "gu": "ધ પોઈન્ટ ફાઉન્ડેશન"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "The Queer Foundation",
              "hi": "क्वेर फाउंडेशन",
              "ka": "ಕ್ವೀರ್ ಫೌಂಡೇಶನ್",
              "te": "ది క్వీర్ ఫౌండేషన్",
              "or": "କୁଇର୍ ଫାଉଣ୍ଡେସନ୍",
              "as": "দ্য কুইৰ ফাউণ্ডেচন",
              "gu": "ધ ક્વિર ફાઉન્ડેશન"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "The Pride Foundation",
              "hi": "अभिमान फाउंडेशन",
              "ka": "ಪ್ರೈಡ್ ಫೌಂಡೇಶನ್",
              "te": "ప్రైడ్ ఫౌండేషన్",
              "or": "ପ୍ରାଇଡ୍‌ ଫାଉଣ୍ଡେସନ୍",
              "as": "দ্য প্ৰাইড ফাউণ্ডেচন",
              "gu": "ધ પ્રાઇડ ફાઉન્ડેશન"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "Colin Higgins Courage Award",
              "hi": "कॉलिन हिगिन्स साहस पुरस्कार",
              "ka": "ಕಾಲಿನ್ ಹಿಗ್ಗಿನ್ಸ್ ಕರೇಜ್ ಪ್ರಶಸ್ತಿ",
              "te": "కోలిన్ హిగ్గిన్స్ కరేజ్ అవార్డు",
              "or": "କଲିନ୍ ହିଗିନ୍ସ ସାହସିକତା ପୁରସ୍କାର",
              "as": "কলিন হিগিন্স সাহস বঁটা",
              "gu": "કોલિન હિગિન્સ કરેજ એવાર્ડ"
            }
          }
        ]
      },
      "scene36": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7ii_1_1",
              "hi": "D7II_1_1",
              "ka": "D7ii_1_1",
              "te": "D7ii_1_1",
              "or": "D7ii_1_1",
              "as": "D7ii_1_1",
              "gu": "D7ii_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After reading about the different Universities, their ranking and the courses they offer along with MBA, Asmita makes a list of universities she is keen on getting admission into.",
              "hi": "विभिन्न विश्वविद्यालयों के बारे में पढ़ने के बाद, उनकी रैंकिंग और वे एमबीए के साथ प्रदान किए जाने वाले पाठ्यक्रम, अस्मिता विश्वविद्यालयों की एक सूची बनाती है जिसमें वह प्रवेश पाने की इच्छुक हैं।",
              "ka": "ವಿವಿಧ ವಿಶ್ವವಿದ್ಯಾಲಯಗಳು, ಅವುಗಳ ಶ್ರೇಯಾಂಕಗಳು ಮತ್ತು ಎಂಬಿಎ ಜೊತೆಗೆ ಅವರು ನೀಡುವ ಕೋರ್ಸ್‌ಗಳ ಬಗ್ಗೆ ಓದಿದ ನಂತರ, ಅಸ್ಮಿತಾ ಪ್ರವೇಶ ಪಡೆಯಲು ಉತ್ಸುಕರಾಗಿರುವ ವಿಶ್ವವಿದ್ಯಾಲಯಗಳ ಪಟ್ಟಿಯನ್ನು ಮಾಡುತ್ತಾಳೆ.",
              "te": "వివిధ యూనివర్సిటీలు, వాటి ర్యాంకింగ్ మరియు MBAతో పాటు వారు అందించే కోర్సుల గురించి చదివిన తర్వాత, అస్మిత తాను అడ్మిషన్ పొందడానికి ఆసక్తిగా ఉన్న యూనివర్సిటీల జాబితాను తయారు చేసుకుంది.",
              "or": "ବିଭିନ୍ନ ବିଶ୍ୱବିଦ୍ୟାଳୟ, ସେମାନଙ୍କର ର଼୍ୟାଙ୍କିଙ୍ଗ୍ ଏବଂ ଏମବିଏ ସହିତ ସେମାନେ ପ୍ରଦାନ କରୁଥିବା ପାଠ୍ୟକ୍ରମ ବିଷୟରେ ଜାଣିବା ପରେ, ଅସ୍ମିତା ଆଡମିଶନ କରିବାକୁ ଆଗ୍ରହୀ ଥିବା ବିଶ୍ୱବିଦ୍ୟାଳୟଗୁଡ଼ିକର ଏକ ତାଲିକା ପ୍ରସ୍ତୁତ କରନ୍ତି |",
              "as": "বিভিন্ন বিশ্ববিদ্যালয়, তেওঁলোকৰ ৰেংকিং আৰু MBAৰ সৈতে তেওঁলোকে আগবঢ়োৱা পাঠ্যক্ৰমবোৰৰ বিষয়ে পঢ়াৰ পিছত, অস্মিতাই তাই নামভৰ্তি কৰিবলৈ আগ্ৰহী বিশ্ববিদ্যালয়সমূহৰ এখন তালিকা প্ৰস্তুত কৰে।",
              "gu": "વિવિધ યુનિવર્સિટીઓ, તેમના રેન્કિંગ અને MBA ની સાથે તેઓ જે અભ્યાસક્રમો ઓફર કરે છે તે વિશે વાંચ્યા પછી, અસ્મિતા એવી યુનિવર્સિટીઓની યાદી બનાવે છે જેમાં તે પ્રવેશ મેળવવા આતુર છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7ii_1_2",
              "hi": "D7II_1_2",
              "ka": "D7ii_1_2",
              "te": "D7ii_1_2",
              "or": "D7ii_1_2",
              "as": "D7ii_1_2",
              "gu": "D7ii_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After going through this initial list, she selects four universities that she wants to apply to.",
              "hi": "इस प्रारंभिक सूची के माध्यम से जाने के बाद, वह चार विश्वविद्यालयों का चयन करती है जिनमें वह आवेदन करना चाहती है।",
              "ka": "ಈ ಆರಂಭಿಕ ಪಟ್ಟಿಯ ಮೂಲಕ ಹೋದ ನಂತರ, ಅವಳು ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಬಯಸುವ ನಾಲ್ಕು ವಿಶ್ವವಿದ್ಯಾಲಯಗಳನ್ನು ಆಯ್ಕೆಮಾಡುತ್ತಾಳೆ.",
              "te": "ఈ లిస్ట్ ను పరిశీలించిన తర్వాత, ఆమె అప్లై చేయాలనుకుంటున్న నాలుగు యూనివర్సిటీలను ఎంపిక చేస్తుంది.",
              "or": "ଏହି ପ୍ରାରମ୍ଭିକ ତାଲିକା ଅତିକ୍ରମ କରିବା ପରେ, ସେ ଚାରୋଟି ବିଶ୍ୱବିଦ୍ୟାଳୟ ଚୟନ କରନ୍ତି ଯାହାକୁ ସେ ଆବେଦନ କରିବାକୁ ଚାହାଁନ୍ତି |",
              "as": "এই প্ৰাৰম্ভিক তালিকাখন পঢ়াৰ পিছত, তাই চাৰিখন বিশ্ববিদ্যালয় বাছনি কৰে য'ত তাই আবেদন কৰিব বিচাৰে।",
              "gu": "આ પ્રારંભિક સૂચિમાંથી પસાર થયા પછી, તેણી ચાર યુનિવર્સિટીઓ પસંદ કરે છે જેમાં તેણી અરજી કરવા માંગે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7ii_1_3",
              "hi": "D7II_1_3",
              "ka": "D7ii_1_3",
              "te": "D7ii_1_3",
              "or": "D7ii_1_3",
              "as": "D7ii_1_3",
              "gu": "D7ii_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "For each of these universities, Asmita writes a Statement of Purpose on why she would be a good fit for the MBA programme.",
              "hi": "इनमें से प्रत्येक विश्वविद्यालय के लिए, अस्मिता उद्देश्य का विवरण लिखती है कि वह एमबीए प्रोग्राम के लिए उपयुक्त क्यों रहेगी।",
              "ka": "ಈ ಪ್ರತಿಯೊಂದು ವಿಶ್ವವಿದ್ಯಾನಿಲಯಗಳಿಗೆ, ಅಸ್ಮಿತಾ ಎಂಬಿಎ ಕಾರ್ಯಕ್ರಮಕ್ಕೆ ಏಕೆ ಸೂಕ್ತವಾಗುತ್ತಾರೆ ಎಂಬುದರ ಕುರಿತು ಉದ್ದೇಶದ ಹೇಳಿಕೆಯನ್ನು ಬರೆಯುತ್ತಾರೆ.",
              "te": "ఈ యూనివర్సిటీలలో ప్రతిదానికీ, అస్మిత MBA ప్రోగ్రామ్‌కు ఎందుకు సరిపోతారనే దానిపై ఒక స్టేట్‌మెంట్ ఆఫ్ పర్పస్‌ను రాస్తుంది.",
              "or": "ଏହି ପ୍ରତ୍ୟେକ ବିଶ୍ୱବିଦ୍ୟାଳୟ ପାଇଁ ଅସ୍ମିତା ଏମବିଏ ପ୍ରୋଗ୍ରାମ ପାଇଁ କେମିତି ଭଲ ଫିଟ୍ ହେବେ ସେ ସମ୍ବନ୍ଧରେ ଉଦ୍ଦେଶ୍ଯ ର ଏକ ବିବରଣୀ ଲେଖନ୍ତି |",
              "as": "এই প্ৰতিখন বিশ্ববিদ্যালয়ৰ বাবে, অস্মিতাই MBA কাৰ্যসূচীৰ বাবে কিয় উপযুক্ত হ'ব তাৰ উদ্দেশ্যৰ ওপৰত এক বিবৃতি লিখিছে।",
              "gu": "આ દરેક યુનિવર્સિટીઓ માટે, અસ્મિતા MBA પ્રોગ્રામ માટે શા માટે યોગ્ય છે તેના પર હેતુનું નિવેદન લખે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7ii_1_4",
              "hi": "D7II_1_4",
              "ka": "D7ii_1_4",
              "te": "D7ii_1_4",
              "or": "D7ii_1_4",
              "as": "D7ii_1_4",
              "gu": "D7ii_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She also gets a recommendation letter from the project lead at MetaLab.",
              "hi": "उन्हें मेटा लैब में प्रोजेक्ट प्रमुख से एक सिफारिश पत्र भी मिलता है।",
              "ka": "ಮೆಟಾಲ್ಯಾಬ್‌ನಲ್ಲಿ ಪ್ರಾಜೆಕ್ಟ್ ಲೀಡ್‌ನಿಂದ ಅವಳು ಶಿಫಾರಸು ಪತ್ರವನ್ನು ಸಹ ಪಡೆಯುತ್ತಾಳೆ.",
              "te": "ఆమె మెటాల్యాబ్ వద్ద ప్రాజెక్ట్ లీడ్ నుండి రికమెండేషన్ లేఖను కూడా పొందుతుంది.",
              "or": "ସେ ମେଟାଲାବରେ ପ୍ରୋଜେକ୍ଟ ଲିଡରୁ ଏକ ସୁପାରିଶ ପତ୍ର ମଧ୍ୟ ପାଇଛନ୍ତି |",
              "as": "তাই মেটালেবৰ মুখ্য প্ৰকল্পকাৰৰ পৰা এখন পৰামৰ্শ পত্ৰও লাভ কৰে।",
              "gu": "તેણીને મેટાલેબના પ્રોજેક્ટ લીડ તરફથી ભલામણ પત્ર પણ મળે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D7ii_1_5_apply",
              "hi": "D7II_1_5_अप्लाइ ",
              "ka": "D7ii_1_5_apply",
              "te": "D7ii_1_5_apply",
              "or": "D7ii_1_5_apply",
              "as": "D7ii_1_5_আবেদন",
              "gu": "D7ii_1_5_apply"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click on each University to send her application:",
              "hi": "प्रत्येक विश्वविद्यालय पर अपना आवेदन भेजने के लिए क्लिक करें:",
              "ka": "ಅವಳ ಅರ್ಜಿಯನ್ನು ಕಳುಹಿಸಲು ಪ್ರತಿ ವಿಶ್ವವಿದ್ಯಾಲಯದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "ఆమె అప్లికేషన్ పంపడానికి ప్రతి యూనివర్సిటీ పై క్లిక్ చేయండి:",
              "or": "ତାଙ୍କ ଆବେଦନ ପଠାଇବାକୁ ପ୍ରତ୍ୟେକ ବିଶ୍ୱବିଦ୍ୟାଳୟ ଉପରେ କ୍ଲିକ୍ କରନ୍ତି :",
              "as": "তাইৰ আবেদন পঠিয়াবলৈ প্ৰতিখন বিশ্ববিদ্যালয়ত ক্লিক কৰক:",
              "gu": "તેણી અરજી મોકલવા માટે દરેક યુનિવર્સિટી પર ક્લિક કરે છે:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "The University of Chicago, Booth School of Business, Chicago (IL), United States",
              "hi": "शिकागो विश्वविद्यालय, बूथ स्कूल ऑफ बिजनेस, शिकागो (आईएल), संयुक्त राज्य अमेरिका",
              "ka": "ಚಿಕಾಗೋ ವಿಶ್ವವಿದ್ಯಾಲಯ, ಬೂತ್ ಸ್ಕೂಲ್ ಆಫ್ ಬ್ಯುಸಿನೆಸ್, ಚಿಕಾಗೋ (IL), ಯುನೈಟೆಡ್ ಸ್ಟೇಟ್ಸ್",
              "te": "చికాగో యూనివర్సిటీ, బూత్ స్కూల్ ఆఫ్ బిజినెస్, చికాగో (IL), యునైటెడ్ స్టేట్స్",
              "or": "ଚିକାଗୋ ୟୁନିଭରସିଟି, ବୁଥ୍ ସ୍କୁଲ୍ ଅଫ୍ ବିଜିନେସ୍, ଚିକାଗୋ (ଆଇଏଲ୍), ଯୁକ୍ତରାଷ୍ଟ୍ର",
              "as": "চিকাগো বিশ্ববিদ্যালয়, বুথ স্কুল অফ বিজনেছ, চিকাগো (IL), আমেৰিকা যুক্তৰাষ্ট্ৰ",
              "gu": "શિકાગો યુનિવર્સિટી, બૂથ સ્કૂલ ઑફ બિઝનેસ, શિકાગો (IL), યુનાઇટેડ સ્ટેટ્સ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "Haas School of Business, Berkeley (CA), United States",
              "hi": "हास स्कूल ऑफ बिजनेस, बर्कले (सीए), संयुक्त राज्य अमेरिका",
              "ka": "ಹಾಸ್ ಸ್ಕೂಲ್ ಆಫ್ ಬ್ಯುಸಿನೆಸ್, ಬರ್ಕ್ಲಿ (CA), ಯುನೈಟೆಡ್ ಸ್ಟೇಟ್ಸ್",
              "te": "హాస్ స్కూల్ ఆఫ్ బిజినెస్, బెర్కెలీ (CA), యునైటెడ్ స్టేట్స్",
              "or": "ହାସ୍ ସ୍କୁଲ୍ ଅଫ୍ ବିଜିନେସ୍, ବର୍‌କିଲି (CA), ଯୁକ୍ତରାଷ୍ଟ୍ର",
              "as": "হাছ স্কুল অফ বিজনেছ, বাৰ্কলে (CA), আমেৰিকা যুক্তৰাষ্ট্ৰ",
              "gu": "હાસ સ્કૂલ ઓફ બિઝનેસ, બર્કલે (CA), યુનાઇટેડ સ્ટેટ્સ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.display",
            "value": {
              "en": "London Business School, London, United Kingdom",
              "hi": "लंदन बिजनेस स्कूल, लंदन, यूनाइटेड किंगडम",
              "ka": "ಲಂಡನ್ ಬಿಸಿನೆಸ್ ಸ್ಕೂಲ್, ಲಂಡನ್, ಯುನೈಟೆಡ್ ಕಿಂಗ್ಡಮ್",
              "te": "లండన్ బిజినెస్ స్కూల్, లండన్, యునైటెడ్ కింగ్‌డమ్",
              "or": "ଲଣ୍ଡନ ବିଜ୍‍ନେସ୍‌ ବିଦ୍ୟାଳୟ, ଲଣ୍ଡନ, ଯୁକ୍ତରାଜ୍ୟ",
              "as": "লণ্ডন বিজনেছ স্কুল, লণ্ডন, যুক্তৰাজ্য",
              "gu": "લંડન બિઝનેસ સ્કૂલ, લંડન, યુનાઇટેડ કિંગડમ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.display",
            "value": {
              "en": "INSEAD - Singapore",
              "hi": "INSEAD - सिंगापुर",
              "ka": "ಇನ್ಸೆಡ್ - ಸಿಂಗಾಪುರ",
              "te": "INSEAD - సింగపూర్",
              "or": "INSEAD - ସିଙ୍ଗାପୁର",
              "as": "INSEAD - ছিংগাপুৰ",
              "gu": "INSEAD - સિંગાપોર"
            }
          },
          {
            "valuePath": "blocks.0.props.buttonText",
            "value": {
              "en": "Send",
              "hi": "भेजें ",
              "ka": "ಕಳುಹಿಸು",
              "te": "పంపండి",
              "or": "ପଠାନ୍ତୁ",
              "as": "পঠাওক",
              "gu": "મોકલો"
            }
          }
        ]
      },
      "scene37": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8_1_1",
              "hi": "D8_1_1",
              "ka": "D8_1_1",
              "te": "D8_1_1",
              "or": "D8_1_1",
              "as": "D8_1_1",
              "gu": "D8_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After she has sent in everything, she gets a response over her email. Asmita gets selected in two of the Universities, along with a scholarship.",
              "hi": "सब कुछ भेजने के बाद, उसे अपने ईमेल पर प्रतिक्रिया मिलती है। अस्मिता का चयन स्कॉलरशिप के साथ-साथ दो विश्वविद्यालय में हो जाता है।",
              "ka": "ಅವಳು ಎಲ್ಲವನ್ನೂ ಕಳುಹಿಸಿದ ನಂತರ, ಅವಳು ತನ್ನ ಇಮೇಲ್ ಮೂಲಕ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಪಡೆಯುತ್ತಾಳೆ. ಅಸ್ಮಿತಾ ಸ್ಕಾಲರ್‌ಶಿಪ್‌ನೊಂದಿಗೆ ಎರಡು ವಿಶ್ವವಿದ್ಯಾಲಯಗಳಿಗೆ ಆಯ್ಕೆಯಾಗುತ್ತಾಳೆ.",
              "te": "ఆమె ప్రతిదీ పంపిన తర్వాత, ఆమె ఇమెయిల్ ద్వారా రిప్లై పొందుతుంది. అస్మిత స్కాలర్‌షిప్‌తో పాటు రెండు యూనివర్సిటీలలో ఎంపిక అవుతుంది.",
              "or": "ସେ ସବୁକିଛି ପଠାଇବା ପରେ, ସେ ତାଙ୍କ ଇମେଲ୍‌ ରେ ଏକ ପ୍ରତିକ୍ରିୟା ପାଆନ୍ତି | ଅସ୍ମିତା ଛାତ୍ରବୃତ୍ତି ସହିତ ଦୁଇଟି ବିଶ୍ୱବିଦ୍ୟାଳୟରେ ମନୋନୀତ ହୁଅନ୍ତି |",
              "as": "তাই সকলোবোৰ পঠিওৱাৰ পিছত, তাই তাইৰ ইমেইলৰ যোগেৰে সঁহাৰি পায়। অস্মিতা দুখন বিশ্ববিদ্যালয়ত নিৰ্বাচিত হয়, লগতে বৃত্তিও লাভ কৰে।",
              "gu": "તેણીએ બધું મોકલ્યા પછી, તેણીને તેના ઇમેઇલ પર પ્રતિસાદ મળે છે.  અસ્મિતા સ્કોલરશીપ સાથે બે યુનિવર્સિટીઓમાં પસંદગી પામે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8_1_2_D",
              "hi": "D8_1_2_D",
              "ka": "D8_1_2_D",
              "te": "D8_1_2_D",
              "or": "D8_1_2_D",
              "as": "D8_1_2_D",
              "gu": "D8_1_2_D"
            }
          },
          {
            "valuePath": "blocks.0.props.question",
            "value": {
              "en": "Which should she choose?",
              "hi": "उसे किसे चुनना चाहिए?",
              "ka": "ಅವಳು ಯಾವುದನ್ನು ಆರಿಸಬೇಕು?",
              "te": "ఆమె ఏది ఎంచుకోవాలి?",
              "or": "ସେ କେଉଁଟି ବାଛିବା ଉଚିତ୍ ?",
              "as": "তাই কোনটো বাছনি কৰা উচিত?",
              "gu": "તેણીએ કયું પસંદ કરવું જોઈએ?"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.display",
            "value": {
              "en": "INSEAD - Singapore",
              "hi": "INSEAD - सिंगापुर",
              "ka": "ಇನ್ಸೆಡ್ - ಸಿಂಗಾಪುರ",
              "te": "INSEAD - సింగపూర్",
              "or": "INSEAD - ସିଙ୍ଗାପୁର",
              "as": "INSEAD - ছিংগাপুৰ",
              "gu": "INSEAD - સિંગાપોર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.display",
            "value": {
              "en": "The University of Chicago, Booth School of Business, Chicago (IL), United States",
              "hi": "शिकागो विश्वविद्यालय, बूथ स्कूल ऑफ बिजनेस, शिकागो (आईएल), संयुक्त राज्य अमेरिका",
              "ka": "ಚಿಕಾಗೋ ವಿಶ್ವವಿದ್ಯಾಲಯ, ಬೂತ್ ಸ್ಕೂಲ್ ಆಫ್ ಬ್ಯುಸಿನೆಸ್, ಚಿಕಾಗೋ (IL), ಯುನೈಟೆಡ್ ಸ್ಟೇಟ್ಸ್",
              "te": "చికాగో యూనివర్సిటీ, బూత్ స్కూల్ ఆఫ్ బిజినెస్, చికాగో (IL), యునైటెడ్ స్టేట్స్",
              "or": "ଚିକାଗୋ ୟୁନିଭରସିଟି, ବୁଥ୍ ସ୍କୁଲ୍ ଅଫ୍ ବିଜିନେସ୍, ଚିକାଗୋ (ଆଇଏଲ୍), ଯୁକ୍ତରାଷ୍ଟ୍ର",
              "as": "চিকাগো বিশ্ববিদ্যালয়, বুথ স্কুল অফ বিজনেছ, চিকাগো (IL), আমেৰিকা যুক্তৰাষ্ট্ৰ",
              "gu": "શિકાગો યુનિવર્સિટી, બૂથ સ્કૂલ ઑફ બિઝનેસ, શિકાગો (IL), યુનાઇટેડ સ્ટેટ્સ"
            }
          }
        ]
      },
      "scene38": {
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8i_2_2",
              "hi": "D8i_2_2",
              "ka": "D8i_2_2",
              "te": "D8i_2_2",
              "or": "D8i_2_2",
              "as": "D8i_2_2",
              "gu": "D8i_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After her MBA, she gets a job at a leading company as a Sales Head. The company has defined policies for LGBTQ+ employees, and she is treated and paid equally and respected for her work. At work, she finds that most of them are cordial.",
              "hi": "एमबीए करने के बाद, उन्हें एक प्रमुख कंपनी में सेल्स प्रमुख के रूप में नौकरी मिल जाती है। कंपनी ने एलजीबीटीक्यू+ कर्मचारियों के लिए नीतियों को परिभाषित किया है, और उसके साथ उसके काम के लिए समान व्यवहार और भुगतान किया जाता है और उसका सम्मान किया जाता है। काम पर, वह पाती है कि उनमें से अधिकांश सौहार्दपूर्ण हैं।",
              "ka": "ತನ್ನ ಎಂಬಿಎ ನಂತರ, ಅವಳು ಸೇಲ್ಸ್ ಹೆಡ್ ಆಗಿ ಪ್ರಮುಖ ಕಂಪನಿಯಲ್ಲಿ ಕೆಲಸ ಪಡೆಯುತ್ತಾಳೆ. ಕಂಪನಿಯು LGBTQ+ ಉದ್ಯೋಗಿಗಳಿಗೆ ನೀತಿಗಳನ್ನು ವ್ಯಾಖ್ಯಾನಿಸಿದೆ ಮತ್ತು ಆಕೆಯನ್ನು ಸಮಾನವಾಗಿ ಪರಿಗಣಿಸಲಾಗಿದೆ ಮತ್ತು ಪಾವತಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ಅವರ ಕೆಲಸಕ್ಕೆ ಗೌರವವನ್ನು ನೀಡಲಾಗುತ್ತದೆ. ಕೆಲಸದಲ್ಲಿ, ಅವರಲ್ಲಿ ಹೆಚ್ಚಿನವರು ಸೌಹಾರ್ದಯುತರು ಎಂದು ಅವಳು ಕಂಡುಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "MBA తర్వాత ఓ ప్రముఖ కంపెనీలో సేల్స్ హెడ్‌గా ఉద్యోగం సంపాదించింది. కంపెనీ LGBTQ+ ఉద్యోగుల కోసం పాలసీలను కలిగి ఉంది మరియు ఆమె పని విషయంలో సమానంగా పరిగణించబడుతుంది మరియు గౌరవించబడుతుంది. పనిలో, వారిలో చాలా మంది సహృదయతతో ఉన్నారని ఆమె కనుగొంటుంది.",
              "or": "ଏମବିଏ ପରେ, ସେ ଏକ ସେଲ୍ସ ହେଡ୍‌ ଭାବରେ ଏକ ଅଗ୍ରଣୀ କମ୍ପାନୀରେ ଚାକିରି ପାଆନ୍ତି | କମ୍ପାନୀ LGBTQ + କର୍ମଚାରୀଙ୍କ ପାଇଁ ନୀତି ନିର୍ଣ୍ଣୟ କରିଛି, ଏବଂ ତାଙ୍କୁ ସମାନ ଭାବରେ ବ୍ୟବହାର ଏବଂ ଦେୟ ଦିଆଯାଏ ଏବଂ ତାଙ୍କ କାର୍ଯ୍ୟ ପାଇଁ ସମ୍ମାନ କରାଯାଏ | କର୍ମକ୍ଷେତ୍ରରେ, ସେ ଦେଖନ୍ତି ଯେ ସେମାନଙ୍କ ମଧ୍ୟରୁ ଅଧିକାଂଶ ସ୍ନେହପୁର୍ଣ୍ଣ ଅଟନ୍ତି |",
              "as": "MBAৰ পিছত, তাই বিক্ৰী বিভাগৰ মুৰব্বী হিচাপে এটা আগশাৰীৰ কোম্পানীত চাকৰি পায়। কোম্পানীটোৱে LGBTQ+ কৰ্মচাৰীসকলৰ বাবে নীতি নিৰ্ধাৰণ কৰিছে, আৰু তাইক সমানভাৱে ব্যৱহাৰ কৰা হয় আৰু দৰমহা দিয়া হয় আৰু তাইৰ কামৰ বাবে সন্মান কৰা হয়। কৰ্মক্ষেত্ৰত, তাই দেখিলে যে তেওঁলোকৰ বেছিভাগেই সৌহাৰ্য্যপূৰ্ণ।",
              "gu": "તેણીના MBA પછી, તેણીને એક અગ્રણી કંપનીમાં સેલ્સ હેડ તરીકે નોકરી મળે છે.  કંપનીએ LGBTQ+ કર્મચારીઓ માટે નીતિઓ વ્યાખ્યાયિત કરી છે, અને તેણીને તેના કામ માટે સમાન રીતે વર્તવામાં આવે છે અને ચૂકવવામાં આવે છે અને આદર આપવામાં આવે છે.  કામ પર, તેણીને લાગે છે કે તેમાંના મોટાભાગના લોકો સૌહાર્દપૂર્ણ છે."
            }
          }
        ],
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "MBA in Singapore",
              "hi": "सिंगापुर में एमबीए",
              "ka": "ಸಿಂಗಾಪುರದಲ್ಲಿ ಎಂಬಿಎ",
              "te": "సింగపూర్‌లో MBA",
              "or": "ସିଙ୍ଗାପୁରରେ MBA",
              "as": "ছিংগাপুৰত MBA কৰক",
              "gu": "સિંગાપોરમાં MBA"
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8i_1_1",
              "hi": "D8i_1_1",
              "ka": "D8i_1_1",
              "te": "D8i_1_1",
              "or": "D8i_1_1",
              "as": "D8i_1_1",
              "gu": "D8i_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita starts her MBA at INSEAD. She is thrilled to be doing an MBA from a prestigious University.",
              "hi": "अस्मिता ने एमबीए इनसीड से शुरू किया है। वह एक प्रतिष्ठित विश्वविद्यालय से एमबीए करने के लिए रोमांचित है।",
              "ka": "ಅಸ್ಮಿತಾ ತನ್ನ ಎಂಬಿಎ ಅನ್ನು INSEAD ನಲ್ಲಿ ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ. ಪ್ರತಿಷ್ಠಿತ ವಿಶ್ವವಿದ್ಯಾನಿಲಯದಿಂದ ಎಂಬಿಎ ಮಾಡುತ್ತಿರುವುದಕ್ಕೆ ಆಕೆ ಥ್ರಿಲ್ ಆಗಿದ್ದಾಳೆ.",
              "te": "అస్మిత INSEADలో MBA ప్రారంభించింది. ప్రతిష్టాత్మకమైన యూనివర్సిటీ నుంచి MBA చేస్తున్నందుకు ఆమె థ్రిల్‌గా ఉంది.",
              "or": "ଅସ୍ମିତା INSEAD ରେ MBA ଆରମ୍ଭ କରନ୍ତି | ସେ ଏକ ପ୍ରତିଷ୍ଠିତ ବିଶ୍ୱବିଦ୍ୟାଳୟରୁ MBA କରିଥିବାରୁ ବହୁତ ଖୁସି |",
              "as": "অস্মিতাই INSEAD MBA আৰম্ভ কৰে। তাই এখন সন্মানীয় বিশ্ববিদ্যালয়ৰ পৰা MBA কৰিবলৈ পাই সুখী হৈছে।",
              "gu": "અસ્મિતાએ INSEAD ખાતે MBA શરૂ કર્યું.  તે પ્રતિષ્ઠિત યુનિવર્સિટીમાંથી MBA કરીને રોમાંચિત છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8i_1_2",
              "hi": "D8i_1_2",
              "ka": "D8i_1_2",
              "te": "D8i_1_2",
              "or": "D8i_1_2",
              "as": "D8i_1_2",
              "gu": "D8i_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Her batchmates are students from all over the world. It is a unique experience for Asmita.",
              "hi": "उसके सहपाठी दुनिया भर के छात्र हैं। यह अस्मिता के लिए एक अनूठा अनुभव है।",
              "ka": "ಅವಳ ಬ್ಯಾಚ್‌ಮೇಟ್‌ಗಳು ಪ್ರಪಂಚದಾದ್ಯಂತದ ವಿದ್ಯಾರ್ಥಿಗಳು. ಅಸ್ಮಿತಾಗೆ ಅದೊಂದು ವಿಶಿಷ್ಟ ಅನುಭವ.",
              "te": "ఆమె బ్యాచ్‌మేట్స్ ప్రపంచం నలుమూలల నుండి వచ్చిన విద్యార్థులు. అస్మితకు ఇది ఒక ప్రత్యేకమైన అనుభవం.",
              "or": "ତାଙ୍କର ବ୍ୟାଚ୍ ସହପାଠୀମାନେ ସମଗ୍ର ବିଶ୍ୱର ଛାତ୍ର ଅଟନ୍ତି | ଅସ୍ମିତାଙ୍କ ପାଇଁ ଏହା ଏକ ନିଆରା ଅନୁଭୂତି |",
              "as": "তাইৰ বেটচমেটসকল গোটেই পৃথিৱীৰ শিক্ষাৰ্থী। এইটো অস্মিতাৰ বাবে এক অনন্য অভিজ্ঞতা।",
              "gu": "તેના બેચમેટ્સ વિશ્વભરના વિદ્યાર્થીઓ છે.  અસ્મિતા માટે આ એક અનોખો અનુભવ હતો."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8i_1_3_reveal",
              "hi": "D8i_1_3_रिवील ",
              "ka": "D8i_1_3_reveal",
              "te": "D8i_1_3_రివీల్",
              "or": "D8i_1_3_reveal",
              "as": "D8i_1_3_প্ৰকাশ কৰে",
              "gu": "D8i_1_3_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to know her thoughts on studying at the university:",
              "hi": "विश्वविद्यालय में पढ़ाई पर उसके विचार जानने के लिए क्लिक करें:",
              "ka": "ವಿಶ್ವವಿದ್ಯಾನಿಲಯದಲ್ಲಿ ಅಧ್ಯಯನ ಮಾಡುವ ಬಗ್ಗೆ ಅವರ ಆಲೋಚನೆಗಳನ್ನು ತಿಳಿಯಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "యూనివర్సిటీలో చదువుకోవడంపై ఆమె ఆలోచనలను తెలుసుకోవడానికి క్లిక్ చేయండి:",
              "or": "ବିଶ୍ୱବିଦ୍ୟାଳୟରେ ପଢିବା ବିଷୟରେ ତାଙ୍କ ଚିନ୍ତାଧାରା ଜାଣିବା ପାଇଁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "বিশ্ববিদ্যালয়ত অধ্যয়ন কৰাৰ বিষয়ে তাইৰ চিন্তা জানিবলৈ ক্লিক কৰক:",
              "gu": "યુનિવર્સિટીમાં અભ્યાસ કરવા અંગે તેના વિચારો જાણવા ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Thought 1",
              "hi": "विचार 1",
              "ka": "ವಿಚಾರ 1",
              "te": "ఆలోచన 1",
              "or": "Thought 1",
              "as": "চিন্তা ১",
              "gu": "વિચાર 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Thought 2",
              "hi": "विचार 2",
              "ka": "ವಿಚಾರ 2",
              "te": "ఆలోచన 2",
              "or": "Thought 2",
              "as": "চিন্তা ২",
              "gu": "વિચાર 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Thought 3",
              "hi": "विचार 3",
              "ka": "ವಿಚಾರ 3",
              "te": "ఆలోచన 3",
              "or": "Thought 3",
              "as": "চিন্তা ৩",
              "gu": "વિચાર 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "The university has a large LGBTQ+ community which has made me feel comfortable despite my initial stress.",
              "hi": "विश्वविद्यालय में एक बड़ा एलजीबीटीक्यू + समुदाय है जिसने अपने शुरुआती तनाव के बावजूद मुझे सहज अनुभव कराया है।",
              "ka": "ವಿಶ್ವವಿದ್ಯಾನಿಲಯವು ದೊಡ್ಡ LGBTQ+ ಸಮುದಾಯವನ್ನು ಹೊಂದಿದೆ, ಇದು ನನ್ನ ಆರಂಭಿಕ ಒತ್ತಡದ ಹೊರತಾಗಿಯೂ ನನಗೆ ಆರಾಮದಾಯಕವಾಗಿದೆ.",
              "te": "యూనివర్సిటీ పెద్ద LGBTQ+ కమ్యూనిటీని కలిగి ఉంది, మొదట ఒత్తిడిగా ఉన్నా కూడా, దీని వల్ల నాకు సుఖంగా ఉంది.",
              "or": "ବିଶ୍ୱବିଦ୍ୟାଳୟର ଏକ ବୃହତ LGBTQ + ସମୁଦାୟ ଅଛି ଯାହା ମୋର ପ୍ରାରମ୍ଭିକ ଚାପ ସତ୍ତ୍ୱେ ମୋତେ ସହଜ କରିଛି |",
              "as": "বিশ্ববিদ্যালয়খনত এটা ডাঙৰ LGBTQ+ সম্প্ৰদায় আছে যিয়ে মোৰ প্ৰাৰম্ভিক চাপৰ স্বত্বেও মোক আৰামদায়ক অনুভৱ কৰাইছে।",
              "gu": "યુનિવર્સિટીમાં વિશાળ LGBTQ+ સમુદાય છે જેણે મારા પ્રારંભિક તણાવ છતાં મને આરામદાયક અનુભવ કરાવ્યો છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "It is great to see so many other people from India here! Together we have adjusted to the new culture and the rigorous studies.",
              "hi": "यहां भारत के इतने सारे अन्य लोगों को देखना बहुत अच्छा लग रहा है! एक साथ हमने नई संस्कृति और कठोर अध्ययनों से तालमेल बना लिया है।",
              "ka": "ಭಾರತದಿಂದ ಹಲವಾರು ಜನರನ್ನು ಇಲ್ಲಿ ನೋಡುವುದು ಅದ್ಭುತವಾಗಿದೆ! ಒಟ್ಟಿಗೆ ನಾವು ಹೊಸ ಸಂಸ್ಕೃತಿ ಮತ್ತು ಕಠಿಣ ಅಧ್ಯಯನಗಳಿಗೆ ಹೊಂದಿಕೊಂಡಿದ್ದೇವೆ.",
              "te": "భారతదేశం నుండి చాలా మంది ఇతర వ్యక్తులను ఇక్కడ చూడటం గొప్ప విషయం! మేము కలిసి కొత్త సంస్కృతికి మరియు కఠినమైన అధ్యయనాలకు అలవాటు పడ్డాము.",
              "or": "ଭାରତର ଅନ୍ୟାନ୍ୟ ଲୋକଙ୍କୁ ଏଠାରେ ଦେଖିବା ବହୁତ ଭଲ ! ମିଳିତ ଭାବେ ଆମେ ନୂତନ ସଂସ୍କୃତି ଏବଂ କଠୋର ଅଧ୍ୟୟନ ସହିତ ଆଡଜଷ୍ଟ କରିଛୁ |",
              "as": "ভাৰতৰ আন বহুতো লোকক ইয়াত দেখি বৰ ভাল লাগিছে! আমি একেলগে নতুন সংস্কৃতি আৰু কঠোৰ অধ্যয়নৰ সৈতে খাপ খাইছো।",
              "gu": "ભારતમાંથી બીજા ઘણા લોકોને અહીં જોઈને આનંદ થયો!  અમે સાથે મળીને નવી સંસ્કૃતિ અને કઠિન અભ્યાસમાં સમાયોજિત થયા છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "I’m glad that I don’t get asked too many questions about my identity.",
              "hi": "मुझे खुशी है कि मुझसे अपनी पहचान के बारे में बहुत सारे सवाल नहीं पूछे जाते।",
              "ka": "ನನ್ನ ಗುರುತಿನ ಬಗ್ಗೆ ನನಗೆ ಹೆಚ್ಚು ಪ್ರಶ್ನೆಗಳನ್ನು ಕೇಳಲಾಗುವುದಿಲ್ಲ ಎಂದು ನನಗೆ ಖುಷಿಯಾಗಿದೆ.",
              "te": "నా గుర్తింపు గురించి ప్రశ్నలు అడగనందుకు నేను సంతోషిస్తున్నాను.",
              "or": "ମୁଁ ଖୁସି ଯେ ମୋର ପରିଚୟ ବିଷୟରେ ମୋତେ ଅଧିକ ପ୍ରଶ୍ନ ପଚରାଯାଏ ନାହିଁ |",
              "as": "মই সুখী যে মোক মোৰ পৰিচয়ৰ বিষয়ে বেছি প্ৰশ্ন সোধা নাই।",
              "gu": "મને ખુશી છે કે મને મારી ઓળખ વિશે વધુ પ્રશ્નો પૂછવામાં આવતા નથી."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8i_1_4///",
              "hi": "D8i_1_4 ///",
              "ka": "D8i_1_4///",
              "te": "D8i_1_4///",
              "or": "D8i_1_4///",
              "as": "D8i_1_4///",
              "gu": "D8i_1_4///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Although she still gets stared at when she goes outside and is always cautious about her security, it doesn’t stop her from exploring Singapore.",
              "hi": "यद्यपि उसे अभी भी घूरा जाता है जब वह बाहर जाती है और वह हमेशा अपनी सुरक्षा के बारे में सतर्क रहती है, यह उसे सिंगापुर की खोज करने से नहीं रोकती है।",
              "ka": "ಅವಳು ಹೊರಗೆ ಹೋಗುವಾಗ ಅವಳು ಇನ್ನೂ ನೋಡುತ್ತಿದ್ದರೂ ಮತ್ತು ಯಾವಾಗಲೂ ತನ್ನ ಭದ್ರತೆಯ ಬಗ್ಗೆ ಜಾಗರೂಕರಾಗಿದ್ದರೂ, ಸಿಂಗಾಪುರವನ್ನು ಅನ್ವೇಷಿಸುವುದನ್ನು ಇದು ತಡೆಯುವುದಿಲ್ಲ.",
              "te": "ఆమె ఇప్పటికీ బయటికి వెళ్లినప్పుడు అందరూ చూస్తారు, కానీ తన భద్రత గురించి ఎల్లప్పుడూ జాగ్రత్తగా ఉన్నప్పటికీ, అది ఆమెను సింగపూర్‌ని అన్వేషించకుండా ఆపలేదు.",
              "or": "ଯଦିଓ ସେ ଯେତେବେଳେ ବାହାରକୁ ଯାଆନ୍ତି ତାଙ୍କ ଉପରେ ନଜର ପକାନ୍ତି ଏବଂ ତାଙ୍କ ସୁରକ୍ଷା ପ୍ରତି ସର୍ବଦା ସେ ସତର୍କ ରୁହନ୍ତି, ଏହା ତାଙ୍କୁ ସିଙ୍ଗାପୁର ଅନୁସନ୍ଧାନରୁ ରୋକେ ନାହିଁ |",
              "as": "যদিও তাই এতিয়াও বাহিৰলৈ যোৱাৰ সময়ত চাই থাকে আৰু তাইৰ নিৰাপত্তাৰ বিষয়ে সদায় সতৰ্ক হৈ থাকে, এইটোৱে তাইক ছিংগাপুৰখন ভালদৰে চোৱাত বাধা নিদিয়ে।",
              "gu": "તેમ છતાં જ્યારે તેણી બહાર જાય છે ત્યારે તે હજી પણ તેની તરફ જોતી રહે છે અને તેની સુરક્ષા વિશે હંમેશા સાવચેત રહે છે, તે તેણીને સિંગાપોરની શોધખોળ  કરવાથી રોકતું નથી."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8i_2_1",
              "hi": "D8i_2_1",
              "ka": "D8i_2_1",
              "te": "D8i_2_1",
              "or": "D8i_2_1",
              "as": "D8i_2_1",
              "gu": "D8i_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Over the two years, Asmita does not go home but stays on the University campus and studies. During the holidays, she works part-time at a cafe and does some online work.",
              "hi": "पिछले दो वर्षों से, अस्मिता घर नहीं जाती लेकिन विश्वविद्यालय परिसर में रहती है और अध्ययन करती है। छुट्टियों के दौरान, वह एक कैफे में अंशकालिक काम करती है और कुछ ऑनलाइन कार्य भी करती है।",
              "ka": "ಎರಡು ವರ್ಷಗಳಲ್ಲಿ, ಅಸ್ಮಿತಾ ಮನೆಗೆ ಹೋಗಲಿಲ್ಲ ಆದರೆ ವಿಶ್ವವಿದ್ಯಾಲಯದ ಕ್ಯಾಂಪಸ್‌ನಲ್ಲಿ ಉಳಿದು ಓದುತ್ತಾಳೆ. ರಜಾದಿನಗಳಲ್ಲಿ, ಅವಳು ಕೆಫೆಯಲ್ಲಿ ಅರೆಕಾಲಿಕ ಕೆಲಸ ಮಾಡುತ್ತಾಳೆ ಮತ್ತು ಕೆಲವು ಆನ್‌ಲೈನ್ ಕೆಲಸಗಳನ್ನು ಮಾಡುತ್ತಾಳೆ.",
              "te": "రెండేళ్లుగా అస్మిత ఇంటికి వెళ్లకుండా యూనివర్సిటీ క్యాంపస్‌లోనే ఉండి చదువుకుంటోంది. సెలవుల్లో, ఆమె ఒక కేఫ్‌లో పార్ట్‌టైమ్‌గా పని చేస్తుంది మరియు కొన్ని ఆన్‌లైన్ పనులు చేస్తుంది.",
              "or": "ଦୁଇ ବର୍ଷ ମଧ୍ୟରେ ଅସ୍ମିତା ଘରକୁ ଯାଆନ୍ତି ନାହିଁ କିନ୍ତୁ ବିଶ୍ୱବିଦ୍ୟାଳୟ କ୍ୟାମ୍ପସରେ ରହି ଅଧ୍ୟୟନ କରନ୍ତି | ଛୁଟିଦିନରେ, ସେ ଏକ କାଫେରେ ପାର୍ଟ ଟାଇମ୍ କାମ କରନ୍ତି ଏବଂ କିଛି ଅନ୍‌ଲାଇନ୍ କାମ କରନ୍ତି |",
              "as": "দুবছৰ ধৰি, অস্মিতা ঘৰলৈ যোৱা নাই কিন্তু বিশ্ববিদ্যালয়ৰ কেম্পাচত থাকে আৰু অধ্যয়ন কৰে। বন্ধৰ দিনবোৰত, তাই এটা কেফেত আংশিক সময়ৰ বাবে কাম কৰে আৰু কিছু অনলাইন কাম কৰে।",
              "gu": "બે વર્ષથી અસ્મિતા ઘરે નથી ગઈ પણ યુનિવર્સિટી કેમ્પસમાં રહીને અભ્યાસ કરે છે.  રજાઓ દરમિયાન, તે કાફેમાં પાર્ટ-ટાઇમ કામ કરે છે અને કેટલાક ઑનલાઇન કામ કરે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8i_2_3",
              "hi": "D8i_2_3",
              "ka": "D8i_2_3",
              "te": "D8i_2_3",
              "or": "D8i_2_3",
              "as": "D8i_2_3",
              "gu": "D8i_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After working for a few years, she saves up enough to undergo medical treatment and surgery to transition completely as a woman. Her parents come and stay with her during this time.",
              "hi": "कुछ सालों से काम करने के बाद, वह एक महिला के रूप में पूरी तरह से ट्रांज़िशन/ बदलाव के लिए चिकित्सा उपचार और सर्जरी से गुजरने के लिए पर्याप्त धन बचाती है। इस दौरान उसके माता-पिता उसके पास आकर रहने लगते हैं।",
              "ka": "ಕೆಲವು ವರ್ಷಗಳ ಕಾಲ ಕೆಲಸ ಮಾಡಿದ ನಂತರ, ಮಹಿಳೆಯಾಗಿ ಸಂಪೂರ್ಣವಾಗಿ ಪರಿವರ್ತನೆ ಮಾಡಲು ಶಸ್ತ್ರಚಿಕಿತ್ಸೆ ಮತ್ತು ವೈದ್ಯಕೀಯ ಚಿಕಿತ್ಸೆಗೆ ಒಳಗಾಗುವಷ್ಟು ಹಣವನ್ನು ಉಳಿಸುತ್ತಾಳೆ. ಈ ಸಮಯದಲ್ಲಿ ಆಕೆಯ ಪೋಷಕರು ಬಂದು ಅವಳೊಂದಿಗೆ ಇರುತ್ತಾರೆ.",
              "te": "కొన్ని సంవత్సరాలు పనిచేసిన తర్వాత, ఆమె వైద్య చికిత్స మరియు శస్త్రచికిత్స చేయించుకోవడానికి సరిపోయేంత ఆదా చేస్తుంది. ఆ సమయంలో ఆమె తల్లిదండ్రులు వచ్చి ఆమెతో ఉంటారు.",
              "or": "କିଛି ବର୍ଷ କାମ କରିବା ପରେ, ସେ ଜଣେ ମହିଳା ଭାବରେ ସଂପୂର୍ଣ୍ଣ ରୂପାନ୍ତର ପାଇଁ ଚିକିତ୍ସା ଏବଂ ଅସ୍ତ୍ରୋପଚାର କରିବାକୁ ଯଥେଷ୍ଟ ସଞ୍ଚୟ କରନ୍ତି | ଏହି ସମୟରେ ତାଙ୍କ ପିତାମାତା ଆସନ୍ତି ଏବଂ ତାଙ୍କ ସହିତ ରହନ୍ତି |",
              "as": "কেইবছৰমান কাম কৰাৰ পিছত, তাই এগৰাকী মহিলা হিচাপে সম্পূৰ্ণৰূপে পৰিৱৰ্তনৰ বাবে চিকিৎসা আৰু অস্ত্ৰোপচাৰৰ বাবে যথেষ্ট টকা জমা কৰে। তাইৰ মাক-দেউতাক এই সময়ত তাইৰ ওচৰলৈ আহে আৰু তাইৰ লগত থাকে।",
              "gu": "થોડા વર્ષો સુધી કામ કર્યા પછી, તે એક મહિલા તરીકે સંપૂર્ણ રીતે ટ્રાન્સિશન કરવા માટે તબીબી સારવાર અને સર્જરી કરાવવા માટે પૂરતી બચત કરે છે.  આ સમય દરમિયાન તેના માતા-પિતા આવે છે અને તેની સાથે રહે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8i_2_4",
              "hi": "D8i_2_4",
              "ka": "D8i_2_4",
              "te": "D8i_2_4",
              "or": "D8i_2_4",
              "as": "D8i_2_4",
              "gu": "D8i_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is grateful to her parents for their support. She knows her journey was possible because of the support she got from her family and her education.",
              "hi": "अस्मिता अपने माता-पिता के समर्थन के लिए आभारी है। वह जानती है कि अपने परिवार से मिलने वाले समर्थन और अपनी शिक्षा के कारण उसकी यात्रा संभव हो पायी थी।",
              "ka": "ಅಸ್ಮಿತಾ ತನ್ನ ಪೋಷಕರ ಬೆಂಬಲಕ್ಕೆ ಕೃತಜ್ಞಳಾಗಿದ್ದಾಳೆ. ತನ್ನ ಕುಟುಂಬ ಮತ್ತು ಅವಳ ಶಿಕ್ಷಣದಿಂದ ಪಡೆದ ಬೆಂಬಲದಿಂದಾಗಿ ತನ್ನ ಪ್ರಯಾಣ ಸಾಧ್ಯವಾಯಿತು ಎಂದು ಅವಳು ತಿಳಿದಿದ್ದಾಳೆ.",
              "te": "అస్మిత తన తల్లిదండ్రులు సపోర్ట్ చేసినందుకు కృతజ్ఞతలు తెలిపింది. ఆమె కుటుంబం మరియు ఆమె విద్య నుండి ఆమెకు లభించిన మద్దతు కారణంగా తన ప్రయాణం సాధ్యమైందని ఆమెకు తెలుసు.",
              "or": "ଅସ୍ମିତା ସେମାନଙ୍କ ପିତାମାତାଙ୍କୁ ସେମାନଙ୍କ ସମର୍ଥନ ପାଇଁ କୃତଜ୍ଞ | ସେ ଜାଣନ୍ତି ଯେ ତାଙ୍କ ପରିବାର ଏବଂ ତାଙ୍କ ଶିକ୍ଷାରୁ ମିଳିଥିବା ସମର୍ଥନ ଯୋଗୁଁ ତାଙ୍କର ଯାତ୍ରା ସମ୍ଭବ ହୋଇଥିଲା |",
              "as": "অস্মিতা তাইৰ মাক দেউতাকৰ সমৰ্থনৰ বাবে কৃতজ্ঞ। তাই জানে যে তাইৰ পৰিয়াল আৰু তাইৰ শিক্ষাৰ পৰা পোৱা সমৰ্থনৰ বাবে তাইৰ যাত্ৰা সম্ভৱ হৈছে।",
              "gu": "અસ્મિતા તેમના માતા-પિતાના સમર્થન માટે તેમની આભારી છે.  તેણી જાણે છે કે તેણીના પરિવાર અને તેણીના શિક્ષણના કારણે તેણીની મુસાફરી શક્ય બની હતી."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8i_3_1",
              "hi": "D8i_3_1",
              "ka": "D8i_3_1",
              "te": "D8i_3_1",
              "or": "D8i_3_1",
              "as": "D8i_3_1",
              "gu": "D8i_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She feels she was able to survive the harassment outside the home environment only because she had a safe environment at home.",
              "hi": "उसे लगता है कि वह घरेलू माहौल के बाहर उत्पीड़न से बचने में सक्षम थी क्योंकि उसके पास घर में एक सुरक्षित वातावरण था।",
              "ka": "ಮನೆಯಲ್ಲಿ ಸುರಕ್ಷಿತ ವಾತಾವರಣವಿದ್ದುದರಿಂದಲೇ ಮನೆಯ ಪರಿಸರದ ಹೊರಗಿನ ಕಿರುಕುಳದಿಂದ ಬದುಕಲು ಸಾಧ್ಯವಾಯಿತು ಎಂದು ಅವಳು ಭಾವಿಸುತ್ತಾಳೆ.",
              "te": "ఇంట్లో సురక్షితమైన వాతావరణం ఉండడం వల్లనే ఇంటి వాతావరణం వెలుపల వేధింపులను తట్టుకుని నిలబడగలిగానని ఆమె భావిస్తోంది.",
              "or": "ସେ ଅନୁଭବ କରନ୍ତି ଯେ ଘରେ ସୁରକ୍ଷିତ ପରିବେଶ ଥିବାରୁ ସେ ଘର ପରିବେଶ ବାହାରେ ଥିବା ନିର୍ଯ୍ୟାତାରୁ ବଞ୍ଚିବାରେ ସକ୍ଷମ ହୋଇଥିଲେ।",
              "as": "তাই অনুভৱ কৰে যে তাই ঘৰৰ পৰিৱেশৰ বাহিৰত হোৱা নিৰ্যাতনৰ পৰা বাচি থাকিবলৈ সক্ষম হৈছিল কাৰণ তাইৰ ঘৰত এক সুৰক্ষিত পৰিৱেশ আছিল।",
              "gu": "તેણીને લાગે છે કે તેણી ઘરના વાતાવરણની બહાર ઉત્પીડનથી બચી શકી હતી કારણ કે તેણીના ઘરનું સલામત વાતાવરણ હતું."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8i_3_2",
              "hi": "D8i_3_2",
              "ka": "D8i_3_2",
              "te": "D8i_3_2",
              "or": "D8i_3_2",
              "as": "D8i_3_2",
              "gu": "D8i_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She travels to different countries as a Sales Head. Asmita joins the LGBTQ+ support group in whichever city she lives in.",
              "hi": "वह बिक्री प्रमुख के रूप में विभिन्न देशों की यात्रा करती है। अस्मिता जिस भी शहर में रहती है, एलजीबीटीक्यू + सपोर्ट ग्रुप में शामिल हो जाती है।",
              "ka": "ಅವಳು ಸೇಲ್ಸ್ ಹೆಡ್ ಆಗಿ ವಿವಿಧ ದೇಶಗಳಿಗೆ ಪ್ರಯಾಣಿಸುತ್ತಾಳೆ. ಅಸ್ಮಿತಾ ವಾಸಿಸುವ ನಗರದಲ್ಲಿ LGBTQ+ ಬೆಂಬಲ ಗುಂಪಿಗೆ ಸೇರುತ್ತಾಳೆ.",
              "te": "ఆమె సేల్స్ హెడ్‌గా వివిధ దేశాలకు వెళుతుంది. అస్మిత ఆమె ఏ నగరానికి వెళ్ళినా కూడా LGBTQ+ సపోర్ట్ గ్రూప్‌లో చేరింది.",
              "or": "ସେ ସେଲ୍ସ ହେଡ୍ ଭାବରେ ବିଭିନ୍ନ ଦେଶ ଭ୍ରମଣ କରନ୍ତି | ଅସ୍ମିତା ସେ କେଉଁ ସହରରେ ରୁହନ୍ତି LGBTQ + ସମର୍ଥନ ଗୋଷ୍ଠୀରେ ଯୋଗ ଦିଅନ୍ତି |",
              "as": "তাই বিক্ৰী বিভাগৰ মুৰব্বী হিচাপে বিভিন্ন দেশ ভ্ৰমণ কৰে। অস্মিতাই যি চহৰতে বাস কৰে সেই চহৰৰ LGBTQ+ সমৰ্থন গোটত যোগদান কৰে।",
              "gu": "તે સેલ્સ હેડ તરીકે વિવિધ દેશોમાં પ્રવાસ કરે છે.  અસ્મિતા જે પણ શહેરમાં રહે છે ત્યાં LGBTQ+ સપોર્ટ ગ્રુપમાં જોડાય છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8i_3_3",
              "hi": "D8i_3_3",
              "ka": "D8i_3_3",
              "te": "D8i_3_3",
              "or": "D8i_3_3",
              "as": "D8i_3_3",
              "gu": "D8i_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She shares her journey in various forums as she believes that this might give courage to others facing similar gender identity issues.",
              "hi": "वह विभिन्न मंचों पर अपनी यात्रा साझा करती है क्योंकि उसका मानना है कि इससे समान लिंग पहचान के मुद्दों का सामना करने वाले अन्य लोगों को साहस मिल सकता है।",
              "ka": "ಇದೇ ರೀತಿಯ ಲಿಂಗ ಗುರುತಿನ ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುತ್ತಿರುವ ಇತರರಿಗೆ ಇದು ಧೈರ್ಯವನ್ನು ನೀಡುತ್ತದೆ ಎಂದು ಅವಳು ನಂಬುತ್ತಾಳೆ ಎಂದು ಅವರು ವಿವಿಧ ವೇದಿಕೆಗಳಲ್ಲಿ ತಮ್ಮ ಪ್ರಯಾಣವನ್ನು ಹಂಚಿಕೊಂಡಿದ್ದಾಳೆ.",
              "te": "ఇలాంటి లింగ గుర్తింపు సమస్యలను ఎదుర్కొంటున్న ఇతరులకు ఇది ధైర్యాన్ని ఇస్తుందని ఆమె విశ్వసిస్తున్నందున ఆమె వివిధ ఫోరమ్‌లలో తన ప్రయాణం గురించి పంచుకుంది.",
              "or": "ସେ ବିଭିନ୍ନ ଫୋରମ୍‌ରେ ନିଜର ଯାତ୍ରା ଅଂଶୀଦାର କରନ୍ତି କାରଣ ସେ ବିଶ୍ବାସ କରନ୍ତି ଯେ ଏହା ସମାନ ଲିଙ୍ଗ ପରିଚୟ ସମସ୍ୟାର ସମ୍ମୁଖୀନ ହେଉଥିବା ଅନ୍ୟମାନଙ୍କୁ ସାହସ ଦେଇପାରେ |",
              "as": "তাই বিভিন্ন মঞ্চত তাইৰ যাত্ৰাৰ বিষয়ে কয় কিয়নো তাই বিশ্বাস কৰে যে ই একে ধৰণৰ লিংগ পৰিচয়ৰ সমস্যাৰ সন্মুখীন হোৱা আনসকলক সাহস দিব পাৰে।",
              "gu": "તેણી વિવિધ ફોરમમાં તેણીની સફર શેર કરે છે કારણ કે તેણી માને છે કે આ સમાન લિંગ ઓળખ સમસ્યાઓનો સામનો કરી રહેલા અન્ય લોકોને હિંમત આપી શકે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "Sales Head",
              "hi": "बिक्री प्रमुख",
              "ka": "ಮಾರಾಟ ಮುಖ್ಯಸ್ಥ",
              "te": "సేల్స్ హెడ్",
              "or": "ସେଲ୍ସ ହେଡ୍",
              "as": "বিক্ৰী বিভাগৰ মুৰব্বী",
              "gu": "સેલ્સ હેડ"
            }
          }
        ]
      },
      "scene39": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "MBA in USA",
              "hi": "यूएसए में एमबीए",
              "ka": "ಅಮೇರಿಕಾದಲ್ಲಿ ಎಂಬಿಎ",
              "te": "USAలో MBA",
              "or": "ଯୁକ୍ତରାଷ୍ଟ୍ରରେ MBA",
              "as": "আমেৰিকা যুক্তৰাষ্ট্ৰত MBA কৰক",
              "gu": "યુએસએમાં MBA"
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8ii_1_1_",
              "hi": "D8II_1_1_",
              "ka": "D8ii_1_1_",
              "te": "D8ii_1_1_",
              "or": "D8ii_1_1_",
              "as": "D8ii_1_1_",
              "gu": "D8ii_1_1_"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita starts her MBA at the University of Chicago, Booth School of Business.",
              "hi": "अस्मिता ने शिकागो विश्वविद्यालय, बूथ स्कूल ऑफ बिजनेस में एमबीए करना शुरू किया।",
              "ka": "ಅಸ್ಮಿತಾ ಚಿಕಾಗೋ ವಿಶ್ವವಿದ್ಯಾನಿಲಯದ ಬೂತ್ ಸ್ಕೂಲ್ ಆಫ್ ಬ್ಯುಸಿನೆಸ್‌ನಲ್ಲಿ ತನ್ನ ಎಂಬಿಎ ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ.",
              "te": "అస్మిత చికాగో యూనివర్సిటీ, బూత్ స్కూల్ ఆఫ్ బిజినెస్‌లో MBA ప్రారంభించింది.",
              "or": "ଅସ୍ମିତା ବୁଥ୍ ସ୍କୁଲ୍ ଅଫ୍ ବିଜନେସ୍, ଚିକାଗୋ ୟୁନିଭରସିଟି ରେ ଏମବିଏ ଆରମ୍ଭ କରନ୍ତି |",
              "as": "অস্মিতাই চিকাগো বিশ্ববিদ্যালয়, বুথ স্কুল অফ বিজনেছত MBA আৰম্ভ কৰে।",
              "gu": "અસ્મિતાએ યુનિવર્સિટી ઓફ શિકાગો, બૂથ સ્કૂલ ઓફ બિઝનેસમાં MBA શરૂ કર્યું."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8ii_1_2",
              "hi": "D8II_1_2",
              "ka": "D8ii_1_2",
              "te": "D8ii_1_2",
              "or": "D8ii_1_2",
              "as": "D8ii_1_2",
              "gu": "D8ii_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The coursework is hectic, but Asmita copes well with the schedule. Over the two years, Asmita does not go home but stays on the University campus and studies.",
              "hi": "कोर्सवर्क व्यस्त है, लेकिन अस्मिता शेड्यूल को अच्छी तरह संभालती है। दो साल अस्मिता घर नहीं जाती बल्कि यूनिवर्सिटी कैंपस में रहती है और पढ़ाई करती है।",
              "ka": "ಕೋರ್ಸ್‌ವರ್ಕ್ ತೀವ್ರವಾಗಿರುತ್ತದೆ, ಆದರೆ ಅಸ್ಮಿತಾ ವೇಳಾಪಟ್ಟಿಯನ್ನು ಚೆನ್ನಾಗಿ ನಿಭಾಯಿಸುತ್ತಾಳೆ. ಎರಡು ವರ್ಷಗಳಲ್ಲಿ, ಅಸ್ಮಿತಾ ಮನೆಗೆ ಹೋಗಲಿಲ್ಲ ಆದರೆ ವಿಶ್ವವಿದ್ಯಾಲಯದ ಕ್ಯಾಂಪಸ್‌ನಲ್ಲಿ ಉಳಿದು ಓದುತ್ತಾಳೆ.",
              "te": "కోర్స్ వర్క్ చాలా ఎక్కువగా ఉంటుంది, కానీ అస్మిత షెడ్యూల్‌ని బాగానే మేనేజ్ చేస్తుంది. రెండేళ్లుగా అస్మిత ఇంటికి వెళ్లకుండా యూనివర్సిటీ క్యాంపస్‌లోనే ఉండి చదువుకుంటోంది.",
              "or": "କୋର୍ସ କାର୍ଯ୍ୟବହୁଳ ଅଟେ, କିନ୍ତୁ ଅସ୍ମିତା କାର୍ଯ୍ୟସୂଚୀ ସହିତ ଭଲ ଭାବରେ ମୁକାବିଲା କରନ୍ତି | ଦୁଇ ବର୍ଷ ମଧ୍ୟରେ, ଅସ୍ମିତା ଘରକୁ ଯାଆନ୍ତି ନାହିଁ କିନ୍ତୁ ବିଶ୍ୱବିଦ୍ୟାଳୟ କ୍ୟାମ୍ପସରେ ରହି ଅଧ୍ୟୟନ କରନ୍ତି |",
              "as": "পাঠ্যক্ৰমটো ব্যস্ততাপূৰ্ণ, কিন্তু অস্মিতাই সময়সূচীৰ সৈতে ভালদৰে মোকাবিলা কৰে। দুবছৰধৰি, অস্মিতা ঘৰলৈ যোৱা নাই কিন্তু বিশ্ববিদ্যালয়ৰ কেম্পাচত থাকে আৰু অধ্যয়ন কৰে।",
              "gu": "અભ્યાસક્રમ વ્યસ્ત છે, પરંતુ અસ્મિતા સમયપત્રકનો સારી રીતે સામનો કરે છે.  બે વર્ષથી અસ્મિતા ઘરે નથી જતી પણ યુનિવર્સિટી કેમ્પસમાં રહીને અભ્યાસ કરે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8ii_1_3_reveal",
              "hi": "D8II_1_3_रिवील ",
              "ka": "D8ii_1_3_reveal",
              "te": "D8ii_1_3_రివీల్",
              "or": "D8ii_1_3_reveal",
              "as": "D8ii_1_3_প্ৰকাশ কৰে",
              "gu": "D8ii_1_3_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to know her thoughts on studying at the university:",
              "hi": "विश्वविद्यालय में पढ़ाई पर उसके विचार जानने के लिए क्लिक करें:",
              "ka": "ವಿಶ್ವವಿದ್ಯಾನಿಲಯದಲ್ಲಿ ಅಧ್ಯಯನ ಮಾಡುವ ಬಗ್ಗೆ ಅವರ ಆಲೋಚನೆಗಳನ್ನು ತಿಳಿಯಲು ಕ್ಲಿಕ್ ಮಾಡಿ:",
              "te": "యూనివర్సిటీలో చదువుకోవడంపై ఆమె ఆలోచనలను తెలుసుకోవడానికి క్లిక్ చేయండి:",
              "or": "ବିଶ୍ୱବିଦ୍ୟାଳୟରେ ପଢିବା ବିଷୟରେ ତାଙ୍କ ଚିନ୍ତାଧାରା ଜାଣିବା ପାଇଁ କ୍ଲିକ୍ କରନ୍ତୁ:",
              "as": "বিশ্ববিদ্যালয়ত অধ্যয়ন কৰাৰ বিষয়ে তাইৰ চিন্তা জানিবলৈ ক্লিক কৰক:",
              "gu": "યુનિવર્સિટીમાં અભ્યાસ કરવા અંગે તેના વિચારો જાણવા ક્લિક કરો:"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Thought 1",
              "hi": "विचार 1",
              "ka": "ವಿಚಾರ 1",
              "te": "ఆలోచన 1",
              "or": "Thought 1",
              "as": "চিন্তা ১",
              "gu": "વિચાર 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Thought 2",
              "hi": "विचार 2",
              "ka": "ವಿಚಾರ 2",
              "te": "ఆలోచన 2",
              "or": "Thought 2",
              "as": "চিন্তা ২",
              "gu": "વિચાર 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Thought 3",
              "hi": "विचार 3",
              "ka": "ವಿಚಾರ 3",
              "te": "ఆలోచన 3",
              "or": "Thought 3",
              "as": "চিন্তা ৩",
              "gu": "વિચાર 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "The university has a large LGBTQ+ community which has made me feel comfortable despite my initial stress.",
              "hi": "विश्वविद्यालय में एक बड़ा एलजीबीटीक्यू + समुदाय है जिसने अपने शुरुआती तनाव के बावजूद मुझे सहज अनुभव कराया है।",
              "ka": "ವಿಶ್ವವಿದ್ಯಾನಿಲಯವು ದೊಡ್ಡ LGBTQ+ ಸಮುದಾಯವನ್ನು ಹೊಂದಿದೆ, ಇದು ನನ್ನ ಆರಂಭಿಕ ಒತ್ತಡದ ಹೊರತಾಗಿಯೂ ನನಗೆ ಆರಾಮದಾಯಕವಾಗಿದೆ.",
              "te": "యూనివర్సిటీ పెద్ద LGBTQ+ కమ్యూనిటీని కలిగి ఉంది, ప్రారంభంలో ఇబ్బంది ఉన్నప్పటికీ ఇప్పుడు నాకు సుఖంగా ఉంది.",
              "or": "ବିଶ୍ୱବିଦ୍ୟାଳୟର ଏକ ବୃହତ LGBTQ + ସମୁଦାୟ ଅଛି ଯାହା ମୋର ପ୍ରାରମ୍ଭିକ ଚାପ ସତ୍ତ୍ୱେ ମୋତେ ସହଜ କରିଛି |",
              "as": "বিশ্ববিদ্যালয়খনত এটা ডাঙৰ LGBTQ+ সম্প্ৰদায় আছে যিয়ে মোৰ প্ৰাৰম্ভিক চাপৰ স্বত্বেও মোক আৰামদায়ক অনুভৱ কৰাইছে।",
              "gu": "યુનિવર્સિટીમાં વિશાળ LGBTQ+ સમુદાય છે જેણે મારા પ્રારંભિક તણાવ છતાં મને આરામદાયક અનુભવ કરાવ્યું."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "It is great to see so many other people from India here! Together we have adjusted to the new culture and the rigorous studies.",
              "hi": "यहां भारत के इतने सारे अन्य लोगों को देखना बहुत अच्छा लग रहा है! एक साथ हमने नई संस्कृति और कठोर अध्ययनों से तालमेल बना लिया है।",
              "ka": "ಭಾರತದಿಂದ ಹಲವಾರು ಜನರನ್ನು ಇಲ್ಲಿ ನೋಡುವುದು ಅದ್ಭುತವಾಗಿದೆ! ಒಟ್ಟಿಗೆ ನಾವು ಹೊಸ ಸಂಸ್ಕೃತಿ ಮತ್ತು ಕಠಿಣ ಅಧ್ಯಯನಗಳಿಗೆ ಹೊಂದಿಕೊಂಡಿದ್ದೇವೆ.",
              "te": "భారతదేశం నుండి చాలా మంది ఇతర వ్యక్తులను ఇక్కడ చూడటం గొప్ప విషయం! మేము కలిసి కొత్త సంస్కృతికి మరియు కఠినమైన అధ్యయనాలకు అలవాటు పడ్డాము.",
              "or": "ଭାରତର ଅନ୍ୟାନ୍ୟ ଲୋକଙ୍କୁ ଏଠାରେ ଦେଖିବା ବହୁତ ଭଲ ! ମିଳିତ ଭାବେ ଆମେ ନୂତନ ସଂସ୍କୃତି ଏବଂ କଠୋର ଅଧ୍ୟୟନ ସହିତ ଆଡଜଷ୍ଟ କରିଛୁ |",
              "as": "ভাৰতৰ আন বহুতো লোকক ইয়াত দেখি বৰ ভাল লাগিছে! আমি একেলগে নতুন সংস্কৃতি আৰু কঠোৰ অধ্যয়নৰ সৈতে খাপ খাইছো।",
              "gu": "ભારતમાંથી બીજા ઘણા લોકોને અહીં જોઈને આનંદ થયો!  અમે સાથે મળીને નવી સંસ્કૃતિ અને સખત અભ્યાસમાં સમાયોજિત થયા છીએ."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "I’m glad that I don’t get asked too many questions about my identity.",
              "hi": "मुझे खुशी है कि मुझसे अपनी पहचान के बारे में बहुत सारे सवाल नहीं पूछे जाते।",
              "ka": "ನನ್ನ ಗುರುತಿನ ಬಗ್ಗೆ ನನಗೆ ಹೆಚ್ಚು ಪ್ರಶ್ನೆಗಳನ್ನು ಕೇಳಲಾಗುವುದಿಲ್ಲ ಎಂದು ನನಗೆ ಖುಷಿಯಾಗಿದೆ.",
              "te": "నా గుర్తింపు గురించి ప్రశ్నలు అడగనందుకు నేను సంతోషిస్తున్నాను.",
              "or": "ମୁଁ ଖୁସି ଯେ ମୋର ପରିଚୟ ବିଷୟରେ ମୋତେ ଅଧିକ ପ୍ରଶ୍ନ ପଚରାଯାଏ ନାହିଁ |",
              "as": "মই সুখী যে মোক মোৰ পৰিচয়ৰ বিষয়ে বেছি প্ৰশ্ন সোধা নাই।",
              "gu": "મને ખુશી છે કે અહીં મને મારી ઓળખ વિશે વધુ પ્રશ્નો પૂછવામાં આવતા નથી."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8ii_1_4///",
              "hi": "D8II_1_4 /////",
              "ka": "D8ii_1_4///",
              "te": "D8ii_1_4///",
              "or": "D8ii_1_4///",
              "as": "D8ii_1_4///",
              "gu": "D8ii_1_4///"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Although she still gets stared at when she goes outside and is always cautious about her security,  it doesn’t stop her from exploring Chicago.",
              "hi": "यद्यपि उसे अभी भी घूरा जाता है जब वह बाहर जाती है और वह हमेशा अपनी सुरक्षा के बारे में सतर्क रहती है, यह उसे शिकागो की खोज करने से नहीं रोकती है।",
              "ka": "ಅವಳು ಹೊರಗೆ ಹೋಗುವಾಗ ಅವಳನ್ನು ಇನ್ನೂ ನೋಡುತ್ತಿದ್ದರೂ ಮತ್ತು ಅವಳ ಸುರಕ್ಷತೆಯ ಬಗ್ಗೆ ಯಾವಾಗಲೂ ಜಾಗರೂಕರಾಗಿದ್ದರೂ, ಇದು ಅವಳು ಚಿಕಾಗೋವನ್ನು ಅನ್ವೇಷಿಸುವುದನ್ನು ತಡೆಯುವುದಿಲ್ಲ.",
              "te": "ఆమె ఇప్పటికీ బయటికి వెళ్లినప్పుడు అందరూ చూస్తారు, తన భద్రత గురించి ఎల్లప్పుడూ జాగ్రత్తగా ఉన్నప్పటికీ, అది ఆమెను చికాగోను అన్వేషించకుండా ఆపలేదు.",
              "or": "ଯଦିଓ ସେ ଯେତେବେଳେ ବାହାରକୁ ଯାଆନ୍ତି ତାଙ୍କ ଉପରେ ନଜର ପକାନ୍ତି ଏବଂ ତାଙ୍କ ସୁରକ୍ଷା ପ୍ରତି ସର୍ବଦା ସେ ସତର୍କ ରୁହନ୍ତି, ଏହା ତାଙ୍କୁ ଚିକାଗୋ ଅନୁସନ୍ଧାନରୁ ରୋକେ ନାହିଁ |",
              "as": "যদিও তাই এতিয়াও বাহিৰলৈ যোৱাৰ সময়ত চাই থাকে আৰু তাইৰ নিৰাপত্তাৰ বিষয়ে সদায় সতৰ্ক হৈ থাকে, এইটোৱে তাইক ছিংগাপুৰখন ভালদৰে চোৱাত বাধা নিদিয়ে।",
              "gu": "તેમ છતાં જ્યારે તેણી બહાર જાય છે ત્યારે તે હજી પણ તેની તરફ જોતી રહે છે અને તેણીની સુરક્ષા વિશે હંમેશા સાવચેત રહે છે, તે તેણીને શિકાગોનુ ભ્રમણ કરતા અટકાવતું નથી."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8ii_2_1",
              "hi": "D8II_2_1",
              "ka": "D8ii_2_1",
              "te": "D8ii_2_1",
              "or": "D8ii_2_1",
              "as": "D8ii_2_1",
              "gu": "D8ii_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "During the holidays she works part-time at a cafe.",
              "hi": "छुट्टियों के दौरान वह एक कैफे में अंशकालिक काम करती है।",
              "ka": "ರಜಾದಿನಗಳಲ್ಲಿ ಅವಳು ಕೆಫೆಯಲ್ಲಿ ಅರೆಕಾಲಿಕ ಕೆಲಸ ಮಾಡುತ್ತಾಳೆ.",
              "te": "సెలవుల్లో ఆమె ఒక కేఫ్‌లో పార్ట్‌టైమ్‌గా పని చేస్తుంది.",
              "or": "ଛୁଟିଦିନରେ ସେ ଏକ କାଫେରେ ପାର୍ଟ ଟାଇମ୍ କାମ କରନ୍ତି |",
              "as": "বন্ধৰ দিনবোৰত তাই এটা কেফেত আংশিক সময়ৰ বাবে কাম কৰে।",
              "gu": "રજાઓ દરમિયાન તે કાફેમાં પાર્ટ-ટાઇમ કામ કરે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8ii_2_2",
              "hi": "D8II_2_2",
              "ka": "D8ii_2_2",
              "te": "D8ii_2_2",
              "or": "D8ii_2_2",
              "as": "D8ii_2_2",
              "gu": "D8ii_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After her MBA, she gets a job at a leading company as a Sales Head. The company has defined policies for transgender people, and she is treated and paid equally.",
              "hi": "एमबीए के बाद, उसे एक अग्रणी कंपनी में बिक्री प्रमुख के रूप में नौकरी मिलती है। कंपनी ने ट्रांसजेंडर लोगों के लिए नीतियां परिभाषित की हैं, और उसका समान रूप से व्यवहार और भुगतान किया जाता है।",
              "ka": "ತನ್ನ ಎಂಬಿಎ ನಂತರ, ಅವಳು ಸೇಲ್ಸ್ ಹೆಡ್ ಆಗಿ ಪ್ರಮುಖ ಕಂಪನಿಯಲ್ಲಿ ಕೆಲಸ ಪಡೆಯುತ್ತಾಳೆ. ಕಂಪನಿಯು ಟ್ರಾನ್ಸ್ಜೆಂಡರ್ ಜನರಿಗೆ ನೀತಿಗಳನ್ನು ವ್ಯಾಖ್ಯಾನಿಸಿದೆ ಮತ್ತು ಆಕೆಯನ್ನು ಸಮಾನವಾಗಿ ಪರಿಗಣಿಸಲಾಗಿದೆ ಮತ್ತು ಪಾವತಿಸಲಾಗುತ್ತದೆ.",
              "te": "MBA తర్వాత ఓ ప్రముఖ కంపెనీలో సేల్స్ హెడ్‌గా ఉద్యోగం సంపాదించింది. సంస్థ ట్రాన్స్‌జెండర్ల కోసం పాలసీలను కలిగి ఉంది మరియు ఆమె అందరితో సమానంగా పరిగణించబడుతుంది మరియు చెల్లించబడుతుంది.",
              "or": "ଏମବିଏ ପରେ, ସେ ଏକ ସେଲ୍ସ ହେଡ୍‌ ଭାବରେ ଏକ ଅଗ୍ରଣୀ କମ୍ପାନୀରେ ଚାକିରି ପାଆନ୍ତି | କମ୍ପାନୀ LGBTQ + କର୍ମଚାରୀଙ୍କ ପାଇଁ ନୀତି ନିର୍ଣ୍ଣୟ କରିଛି, ଏବଂ ତାଙ୍କୁ ସମାନ ଭାବରେ ବ୍ୟବହାର ଏବଂ ଦେୟ ଦିଆଯାଏ ଏବଂ ତାଙ୍କ କାର୍ଯ୍ୟ ପାଇଁ ସମ୍ମାନ କରାଯାଏ | କର୍ମକ୍ଷେତ୍ରରେ, ସେ ଦେଖନ୍ତି ଯେ ସେମାନଙ୍କ ମଧ୍ୟରୁ ଅଧିକାଂଶ ସ୍ନେହପୁର୍ଣ୍ଣ ଅଟନ୍ତି |",
              "as": "MBAৰ পিছত, তাই চেলছ হেড হিচাপে এটা আগশাৰীৰ কোম্পানীত চাকৰি পায়। কোম্পানীটোৱে ট্ৰেন্সজেণ্ডাৰসকলৰ বাবে নীতি নিৰ্ধাৰণ কৰিছে, তাইক সমানভাৱে ব্যৱহাৰ কৰিছে আৰু সমান দৰমহা দিছে।",
              "gu": "MBA કર્યા પછી, તેણીને એક અગ્રણી કંપનીમાં સેલ્સ હેડ તરીકે નોકરી મળે છે.  કંપનીએ ટ્રાન્સલિંગ લોકો માટે નીતિઓ વ્યાખ્યાયિત કરી છે, અને તેણી સાથે સમાન રીતે વર્તે છે અને ચૂકવણી કરવામાં આવે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8ii_2_3",
              "hi": "D8II_2_3",
              "ka": "D8ii_2_3",
              "te": "D8ii_2_3",
              "or": "D8ii_2_3",
              "as": "D8ii_2_3",
              "gu": "D8ii_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After working for a few years, she saves up enough to undergo medical treatment and surgery to transition completely as a woman. Her parents come and stay with her during this time.",
              "hi": "कुछ सालों से काम करने के बाद, वह एक महिला के रूप में पूरी तरह से ट्रांज़िशन/ बदलाव के लिए चिकित्सा उपचार और सर्जरी से गुजरने के लिए पर्याप्त धन बचाती है। इस दौरान उसके माता-पिता उसके पास आकर रहने लगते हैं।    ",
              "ka": "ಕೆಲವು ವರ್ಷಗಳ ಕಾಲ ಕೆಲಸ ಮಾಡಿದ ನಂತರ, ಮಹಿಳೆಯಾಗಿ ಸಂಪೂರ್ಣವಾಗಿ ಪರಿವರ್ತನೆ ಮಾಡಲು ಶಸ್ತ್ರಚಿಕಿತ್ಸೆ ಮತ್ತು ವೈದ್ಯಕೀಯ ಚಿಕಿತ್ಸೆಗೆ ಒಳಗಾಗುವಷ್ಟು ಹಣವನ್ನು ಉಳಿಸುತ್ತಾಳೆ. ಈ ಸಮಯದಲ್ಲಿ ಆಕೆಯ ಪೋಷಕರು ಬಂದು ಅವಳೊಂದಿಗೆ ಇರುತ್ತಾರೆ.",
              "te": "కొన్ని సంవత్సరాలు పనిచేసిన తర్వాత, ఆమె వైద్య చికిత్స మరియు శస్త్రచికిత్స చేయించుకోవడానికి తగినంతగా ఆదా చేస్తుంది. ఈ సమయంలో ఆమె తల్లిదండ్రులు వచ్చి ఆమెతో ఉంటారు.",
              "or": "କିଛି ବର୍ଷ କାମ କରିବା ପରେ, ସେ ଜଣେ ମହିଳା ଭାବରେ ସଂପୂର୍ଣ୍ଣ ରୂପାନ୍ତର ପାଇଁ ଚିକିତ୍ସା ଏବଂ ଅସ୍ତ୍ରୋପଚାର କରିବାକୁ ଯଥେଷ୍ଟ ସଞ୍ଚୟ କରନ୍ତି | ଏହି ସମୟରେ ତାଙ୍କ ପିତାମାତା ଆସନ୍ତି ଏବଂ ତାଙ୍କ ସହିତ ରହନ୍ତି |",
              "as": "কেইবছৰমান কাম কৰাৰ পিছত, তাই এগৰাকী মহিলা হিচাপে সম্পূৰ্ণৰূপে পৰিৱৰ্তনৰ বাবে চিকিৎসা আৰু অস্ত্ৰোপচাৰৰ বাবে যথেষ্ট টকা জমা কৰে। তাইৰ মাক দেউতাক এই সময়ত তাইৰ ওচৰলৈ আহে আৰু তাইৰ লগত থাকে।",
              "gu": "થોડા વર્ષો સુધી કામ કર્યા પછી, તે એક મહિલા તરીકે સંપૂર્ણ રીતે ટ્રાન્સિશન કરવા માટે તબીબી સારવાર અને સર્જરી કરાવવા માટે પૂરતી બચત કરે છે.  આ સમય દરમિયાન તેના માતા-પિતા આવે છે અને તેની સાથે રહે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8ii_3_1",
              "hi": "D8II_3_1",
              "ka": "D8ii_3_1",
              "te": "D8ii_3_1",
              "or": "D8ii_3_1",
              "as": "D8ii_3_1",
              "gu": "D8ii_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is grateful to her parents for their support. She knows her journey was possible because of the support she got from her family and her education.",
              "hi": "अस्मिता अपने माता-पिता के समर्थन के लिए आभारी है। वह जानती है कि अपने परिवार से मिलने वाले समर्थन और अपनी शिक्षा  के कारण उसकी यात्रा संभव हो पायी थी।",
              "ka": "ಅಸ್ಮಿತಾ ತನ್ನ ಪೋಷಕರ ಬೆಂಬಲಕ್ಕೆ ಕೃತಜ್ಞಳಾಗಿದ್ದಾಳೆ. ತನ್ನ ಕುಟುಂಬ ಮತ್ತು ಅವಳ ಶಿಕ್ಷಣದಿಂದ ಪಡೆದ ಬೆಂಬಲದಿಂದಾಗಿ ತನ್ನ ಪ್ರಯಾಣ ಸಾಧ್ಯವಾಯಿತು ಎಂದು ಅವಳು ತಿಳಿದಿದ್ದಾಳೆ.",
              "te": "అస్మిత తనకు సపోర్ట్ చేసినందుకు తల్లిదండ్రులకు కృతజ్ఞతలు తెలిపింది. ఆమె కుటుంబం మరియు ఆమె విద్య నుండి ఆమెకు లభించిన మద్దతు కారణంగా తన ప్రయాణం సాధ్యమైందని ఆమెకు తెలుసు.",
              "or": "ଅସ୍ମିତା ସେମାନଙ୍କ ପିତାମାତାଙ୍କୁ ସେମାନଙ୍କ ସମର୍ଥନ ପାଇଁ କୃତଜ୍ଞ | ସେ ଜାଣନ୍ତି ଯେ ତାଙ୍କ ପରିବାର ଏବଂ ତାଙ୍କ ଶିକ୍ଷାରୁ ମିଳିଥିବା ସମର୍ଥନ ଯୋଗୁଁ ତାଙ୍କର ଯାତ୍ରା ସମ୍ଭବ ହୋଇଥିଲା |",
              "as": "অস্মিতা তাইৰ মাক দেউতাকৰ সমৰ্থনৰ বাবে কৃতজ্ঞ। তাই জানে যে তাইৰ পৰিয়াল আৰু তাইৰ শিক্ষাৰ পৰা পোৱা সমৰ্থনৰ বাবে তাইৰ যাত্ৰা সম্ভৱ হৈছে।",
              "gu": "અસ્મિતા તેમના માતા-પિતાના સમર્થન માટે તેમની આભારી છે.  તેણી જાણે છે કે તેણીના પરિવાર અને તેણીના શિક્ષણના  કારણે તેણીની મુસાફરી શક્ય બની હતી."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8ii_3_1b",
              "hi": "D8II_3_1B",
              "ka": "D8ii_3_1b",
              "te": "D8ii_3_1b",
              "or": "D8ii_3_1b",
              "as": "D8ii_3_1b",
              "gu": "D8ii_3_1b"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She feels she was able to survive the harassment outside the home environment only because she had a safe environment at home.",
              "hi": "उसे लगता है कि वह घरेलू माहौल के बाहर उत्पीड़न से बचने में सक्षम थी क्योंकि उसके पास घर में एक सुरक्षित वातावरण था।",
              "ka": "ಮನೆಯಲ್ಲಿ ಸುರಕ್ಷಿತ ವಾತಾವರಣವಿದ್ದುದರಿಂದಲೇ ಮನೆಯ ಪರಿಸರದ ಹೊರಗಿನ ಕಿರುಕುಳದಿಂದ ಬದುಕಲು ಸಾಧ್ಯವಾಯಿತು ಎಂದು ಅವಳು ಭಾವಿಸುತ್ತಾಳೆ.",
              "te": "ఇంట్లో సురక్షితమైన వాతావరణం ఉండడం వల్లనే ఇంటి వాతావరణం వెలుపల వేధింపులను తట్టుకుని నిలబడగలిగానని ఆమె భావిస్తోంది.",
              "or": "ସେ ଅନୁଭବ କରନ୍ତି ଯେ ଘରେ ସୁରକ୍ଷିତ ପରିବେଶ ଥିବାରୁ ସେ ଘର ପରିବେଶ ବାହାରେ ଥିବା ନିର୍ଯ୍ୟାତାରୁ ବଞ୍ଚିବାରେ ସକ୍ଷମ ହୋଇଥିଲେ।",
              "as": "তাই অনুভৱ কৰে যে তাই ঘৰৰ পৰিৱেশৰ বাহিৰত হোৱা নিৰ্যাতনৰ পৰা বাচি থাকিবলৈ সক্ষম হৈছিল কাৰণ তাইৰ ঘৰত এক সুৰক্ষিত পৰিৱেশ আছিল।",
              "gu": "તેણીને લાગે છે કે તેણી ઘરના વાતાવરણની બહાર ઉત્પીડનથી બચી શકી હતી કારણ કે તેણીના ઘરનું સલામત વાતાવરણ હતું."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8ii_3_2",
              "hi": "D8II_3_2",
              "ka": "D8ii_3_2",
              "te": "D8ii_3_2",
              "or": "D8ii_3_2",
              "as": "D8ii_3_2",
              "gu": "D8ii_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She travels to different countries as a Sales Head. Asmita joins the LGBTQ+ support group in whichever city she lives in.",
              "hi": "वह बिक्री प्रमुख के रूप में विभिन्न देशों की यात्रा करती हैं। अस्मिता जिस भी शहर में रहती है, एलजीबीटीक्यू + सपोर्ट ग्रुप में शामिल हो जाती हैं।",
              "ka": "ಅವಳು ಸೇಲ್ಸ್ ಹೆಡ್ ಆಗಿ ವಿವಿಧ ದೇಶಗಳಿಗೆ ಪ್ರಯಾಣಿಸುತ್ತಾಳೆ. ಅಸ್ಮಿತಾ ವಾಸಿಸುವ ನಗರದಲ್ಲಿ LGBTQ+ ಬೆಂಬಲ ಗುಂಪಿಗೆ ಸೇರುತ್ತಾಳೆ.",
              "te": "ఆమె సేల్స్ హెడ్‌గా వివిధ దేశాలకు వెళుతుంది. అస్మిత ఆమె ఏ నగరానికి వెళ్ళినా కూడా LGBTQ+ సపోర్ట్ గ్రూప్‌లో చేరుతుంది.",
              "or": "ସେ ସେଲ୍ସ ହେଡ୍ ଭାବରେ ବିଭିନ୍ନ ଦେଶ ଭ୍ରମଣ କରନ୍ତି | ଅସ୍ମିତା ସେ କେଉଁ ସହରରେ ରୁହନ୍ତି LGBTQ + ସମର୍ଥନ ଗୋଷ୍ଠୀରେ ଯୋଗ ଦିଅନ୍ତି |",
              "as": "তাই বিক্ৰী বিভাগৰ মুৰব্বী হিচাপে বিভিন্ন দেশ ভ্ৰমণ কৰে। অস্মিতাই যি চহৰতে বাস কৰে সেই চহৰৰ LGBTQ+ সমৰ্থন গোটত যোগদান কৰে।",
              "gu": "તે સેલ્સ હેડ તરીકે વિવિધ દેશોમાં પ્રવાસ કરે છે.  અસ્મિતા જે પણ શહેરમાં રહે છે ત્યાં LGBTQ+ સપોર્ટ ગ્રુપમાં જોડાય છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "D8ii_3_3",
              "hi": "D8II_3_3",
              "ka": "D8ii_3_3",
              "te": "D8ii_3_3",
              "or": "D8ii_3_3",
              "as": "D8ii_3_3",
              "gu": "D8ii_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She shares her journey in various forums as she believes that this might give courage to others facing similar gender identity issues.",
              "hi": "वह विभिन्न मंचों पर अपनी यात्रा साझा करती है क्योंकि उनका मानना है कि इससे समान लिंग पहचान के मुद्दों का सामना करने वाले अन्य लोगों को साहस मिल सकता है।",
              "ka": "ಇದೇ ರೀತಿಯ ಲಿಂಗ ಗುರುತಿನ ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುತ್ತಿರುವ ಇತರರಿಗೆ ಇದು ಧೈರ್ಯವನ್ನು ನೀಡುತ್ತದೆ ಎಂದು ಅವಳು ನಂಬುತ್ತಾಳೆ ಎಂದು ಅವರು ವಿವಿಧ ವೇದಿಕೆಗಳಲ್ಲಿ ತಮ್ಮ ಪ್ರಯಾಣವನ್ನು ಹಂಚಿಕೊಂಡಿದ್ದಾಳೆ.",
              "te": "ఇలాంటి లింగ గుర్తింపు సమస్యలను ఎదుర్కొంటున్న ఇతరులకు ఇది ధైర్యాన్ని ఇస్తుందని ఆమె విశ్వసిస్తున్నందున ఆమె వివిధ ఫోరమ్‌లలో తన ప్రయాణాన్ని పంచుకుంది.",
              "or": "ସେ ବିଭିନ୍ନ ଫୋରମ୍‌ରେ ନିଜର ଯାତ୍ରା ଅଂଶୀଦାର କରନ୍ତି କାରଣ ସେ ବିଶ୍ବାସ କରନ୍ତି ଯେ ଏହା ସମାନ ଲିଙ୍ଗ ପରିଚୟ ସମସ୍ୟାର ସମ୍ମୁଖୀନ ହେଉଥିବା ଅନ୍ୟମାନଙ୍କୁ ସାହସ ଦେଇପାରେ |",
              "as": "তাই বিভিন্ন মঞ্চত তাইৰ যাত্ৰাৰ বিষয়ে কয় কিয়নো তাই বিশ্বাস কৰে যে ই একে ধৰণৰ লিংগ পৰিচয়ৰ সমস্যাৰ সন্মুখীন হোৱা আনসকলক সাহস দিব পাৰে।",
              "gu": "તેણી વિવિધ ફોરમમાં તેણીની સફર શેર કરે છે કારણ કે તેણી માને છે કે આ સમાન લિંગ ઓળખ સમસ્યાઓનો સામનો કરી રહેલા અન્ય લોકોને હિંમત આપી શકે છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "Sales Head",
              "hi": "बिक्री प्रमुख ",
              "ka": "ಮಾರಾಟ ಮುಖ್ಯಸ್ಥ",
              "te": "సేల్స్ హెడ్",
              "or": "ସେଲ୍ସ ହେଡ୍",
              "as": "বিক্ৰী বিভাগৰ মুৰব্বী",
              "gu": "સેલ્સ હેડ"
            }
          }
        ]
      },
      "scene40": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "Work as a Freelancer",
              "hi": "एक फ्रीलांसर के रूप में काम करे",
              "ka": "ಫ್ರೀಲ್ಯಾನ್ಸರ್ ಆಗಿ ಕೆಲಸ ಮಾಡಿ",
              "te": "ఫ్రీలాన్సర్‌గా పని చేయడం",
              "or": "ଏକ ଫ୍ରିଲାନ୍ସର୍ ଭାବରେ କାମ କରନ୍ତି",
              "as": "ফ্ৰিলাঞ্চাৰ হিচাপে কাম কৰক",
              "gu": "ફ્રીલાન્સર તરીકે કામ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind is not comfortable working full time at MetaLab and decides to work remotely.",
              "hi": "अरविंद मेटा लैब पर पूर्णकालिक काम करने में सहज नहीं है और दूर रहकर काम करने का निश्चय करता है।",
              "ka": "ಮೆಟಾಲ್ಯಾಬ್‌ ನಲ್ಲಿ ಪೂರ್ಣ ಸಮಯ ಕೆಲಸ ಮಾಡಲು ಅರವಿಂದ್ ಆರಾಮವಾಗಿಲ್ಲ ಮತ್ತು ದೂರದಿಂದಲೇ ಕೆಲಸ ಮಾಡಲು ನಿರ್ಧರಿಸಿದ್ದಾರೆ.",
              "te": "అరవింద్ కి మెటాల్యాబ్ లో పూర్తి సమయం పని చేయడం సౌకర్యంగా లేదు మరియు రిమోట్‌గా పని చేయాలని నిర్ణయించుకున్నాడు.",
              "or": "ଅରବିନ୍ଦ ମେଟାଲାବ୍‌ରେ ପୂର୍ଣ୍ଣକାଳୀନ କାମ କରିବା ସହଜ ନାହାଁନ୍ତି ଏବଂ ଦୂରରୁ କାମ କରିବାକୁ ନିଷ୍ପତ୍ତି ନିଅନ୍ତି |",
              "as": "অৰবিন্দে মেটালেবত গোটেই দিনটো কাম কৰিবলৈ সহজবোধ নকৰে আৰু দূৰৱৰ্তীভাৱে কাম কৰাৰ সিদ্ধান্ত লয়।",
              "gu": "અરવિંદ મેટા લેબમાં પૂર્ણ સમય કામ કરવા માટે તૈયાર નથી અને તે દૂરથી કામ કરવાનું નક્કી કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_1_2",
              "hi": "E1_1_2",
              "ka": "E1_1_2",
              "te": "E1_1_2",
              "or": "E1_1_2",
              "as": "E1_1_2",
              "gu": "E1_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Although his family wants Arvind to get a stable job to secure himself financially, they understand Arvind’s decision.",
              "hi": "यद्यपि उसका परिवार चाहता है कि अरविंद को वित्तीय रूप से सुरक्षित होने के लिए एक स्थिर नौकरी मिले, लेकिन वे अरविंद के निर्णय को समझते हैं।",
              "ka": "ಅರವಿಂದ್ ತನ್ನನ್ನು ಆರ್ಥಿಕವಾಗಿ ಭದ್ರಪಡಿಸಿಕೊಳ್ಳಲು ಸ್ಥಿರವಾದ ಕೆಲಸವನ್ನು ಪಡೆಯಬೇಕೆಂದು ಅವನ ಕುಟುಂಬವು ಬಯಸಿದ್ದರೂ, ಅವರು ಅರವಿಂದ್ ಅವರ ನಿರ್ಧಾರವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ.",
              "te": "ఆర్థికంగా నిలదొక్కుకోవడానికి అరవింద్‌కు స్థిరమైన ఉద్యోగం రావాలని అతని కుటుంబ సభ్యులు కోరుకుంటున్నప్పటికీ, అరవింద్ నిర్ణయాన్ని వారు అర్థం చేసుకున్నారు.",
              "or": "ଯଦିଓ ତାଙ୍କ ପରିବାର ଚାହୁଁଛନ୍ତି ଯେ ଅରବିନ୍ଦ ନିଜକୁ ଆର୍ଥିକ ଦୃଷ୍ଟିରୁ ସୁରକ୍ଷିତ ରଖିବା ପାଇଁ ଏକ ସ୍ଥିର ଚାକିରି କରନ୍ତୁ, ସେମାନେ ଅରବିନ୍ଦଙ୍କ ନିଷ୍ପତ୍ତି ବୁଝନ୍ତି |",
              "as": "যদিও তেওঁৰ পৰিয়ালে বিচাৰে যে অৰবিন্দে নিজকে আৰ্থিকভাৱে সুৰক্ষিত কৰিবলৈ এক স্থিৰ চাকৰি লাভ কৰক, তেওঁলোকে অৰবিন্দৰ সিদ্ধান্ত বুজি পায়।",
              "gu": "તેનો પરિવાર ઇચ્છે છે કે અરવિંદ પોતાને આર્થિક રીતે સુરક્ષિત રાખવા માટે સ્થિર નોકરી મેળવે, તેઓ અરવિંદના નિર્ણયને સમજે છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_1_3_reveal",
              "hi": "E1_1_3_रिवील ",
              "ka": "E1_1_3_reveal",
              "te": "E1_1_3_రివీల్",
              "or": "E1_1_3_reveal",
              "as": "E1_1_3_প্ৰকাশ কৰে",
              "gu": "E1_1_3_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "He makes a profile on a freelance portal.",
              "hi": "वह एक फ्रीलांस पोर्टल पर एक प्रोफ़ाइल बनाता है।",
              "ka": "ಅವರು ಸ್ವತಂತ್ರ ಪೋರ್ಟಲ್‌ನಲ್ಲಿ ಪ್ರೊಫೈಲ್ ಮಾಡುತ್ತಾರೆ.",
              "te": "అతను ఒక ఫ్రీలాన్స్ పోర్టల్‌లో ప్రొఫైల్‌ను తయారు చేసుకుంటాడు.",
              "or": "ସେ ଏକ ଫ୍ରିଲାନ୍ସ ପୋର୍ଟାଲ୍‌ରେ ଏକ ପ୍ରୋଫାଇଲ୍ ତିଆରି କରନ୍ତି |",
              "as": "তেওঁ এটা ফ্ৰিলেন্স পৰ্টেলত এটা প্ৰ'ফাইল তৈয়াৰ কৰে।",
              "gu": "તે ફ્રીલાન્સ પોર્ટલ પર પ્રોફાઇલ બનાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Name",
              "hi": "नाम",
              "ka": "ಹೆಸರು",
              "te": "పేరు",
              "or": "ନାମ",
              "as": "নাম",
              "gu": "નામ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Expertise",
              "hi": "विशेषज्ञता",
              "ka": "ಪರಿಣಿತಿ",
              "te": "నైపుణ్యం",
              "or": "ବିଶେଷ ଦକ୍ଷତା",
              "as": "দক্ষতা",
              "gu": "નિપુણતા"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Hourly Rate",
              "hi": "प्रति घंटा - दर",
              "ka": "ದರ ಪ್ರತಿ ಗಂಟೆಗೆ",
              "te": "గంటకు రేటు",
              "or": "ଘଣ୍ଟା ପ୍ରତି ଦର",
              "as": "প্ৰতি ঘণ্টাৰ দাম",
              "gu": "કલાકદીઠ દર"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "Bio",
              "hi": "बायो ",
              "ka": "ಬಯೋ",
              "te": "బయో",
              "or": "ବାୟୋ |",
              "as": "জৈৱ",
              "gu": "બાયો"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Full Stack Developer: NodeJS / React.js / Gatsby.js / Tailwind CSS",
              "hi": "पूर्ण स्टैक डेवलपर: NODEJS / REACT.JS / GATSBY.JS / TAILWIND CSS",
              "ka": "ಪೂರ್ಣ ಸ್ಟಾಕ್ ಡೆವಲಪರ್: NodeJS / React.js / Gatsby.js / Tailwind CSS",
              "te": "ఫుల్ స్టాక్ డెవలపర్: NodeJS / React.js / Gatsby.js / Tailwind CSS",
              "or": "Full Stack Developer: NodeJS / React.js / Gatsby.js / Tailwind CSS",
              "as": "সম্পূৰ্ণ ষ্টেক বিকাশক: NodeJS / React.js / Gatsby.js / Tailwind CSS",
              "gu": "સંપૂર્ણ સ્ટેક ડેવલપર: NodeJS / React.js / Gatsby.js / Tailwind CSS"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "$10.00/hr",
              "hi": "$ 10.00 / HR",
              "ka": "$10.00/ಗಂ",
              "te": "$10.00/గం",
              "or": "$10.00/hr",
              "as": "$10.00/ঘণ্টা",
              "gu": "$10.00/કલાક"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "I am a recent graduate interested in working as a front-end developer. I am an efficient, reliable and organised. Quick work and high-quality results are guaranteed. I work according to my client's instructions, and at the same time, I suggest the best ways and methods for the project to run successfully.",
              "hi": "मैं एक स्नातक हूं जो फ्रंट-एंड डेवलपर के रूप में काम करने में रुचि रखता हूं। मैं कुशल, भरोसेमंद और संगठित हूं। त्वरित काम और उच्च गुणवत्ता वाले परिणाम की गारंटी है। मैं अपने ग्राहक के निर्देशों के अनुसार काम करता हूं, और साथ ही, मैं प्रोजेक्ट को सफलतापूर्वक चलाने के लिए सर्वोत्तम तरीकों और विधियों का सुझाव देता हूं।",
              "ka": "ನಾನು ಫ್ರಂಟ್-ಎಂಡ್ ಡೆವಲಪರ್ ಆಗಿ ಕೆಲಸ ಮಾಡಲು ಆಸಕ್ತಿ ಹೊಂದಿರುವ ಇತ್ತೀಚಿನ ಪದವೀಧರನಾಗಿದ್ದೇನೆ. ನಾನು ಸಮರ್ಥ, ವಿಶ್ವಾಸಾರ್ಹ ಮತ್ತು ಸಂಘಟಿತ. ತ್ವರಿತ ಕೆಲಸ ಮತ್ತು ಉತ್ತಮ ಗುಣಮಟ್ಟದ ಫಲಿತಾಂಶಗಳನ್ನು ಖಾತರಿಪಡಿಸಲಾಗಿದೆ. ನನ್ನ ಕ್ಲೈಂಟ್‌ನ ಸೂಚನೆಗಳ ಪ್ರಕಾರ ನಾನು ಕೆಲಸ ಮಾಡುತ್ತೇನೆ ಮತ್ತು ಅದೇ ಸಮಯದಲ್ಲಿ, ಯೋಜನೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನಡೆಸಲು ನಾನು ಉತ್ತಮ ಮಾರ್ಗಗಳು ಮತ್ತು ವಿಧಾನಗಳನ್ನು ಸೂಚಿಸುತ್ತೇನೆ.",
              "te": "నేను ఫ్రంట్-ఎండ్ డెవలపర్‌గా పని చేయడానికి ఆసక్తిని కలిగి ఉన్న ఇటీవలి గ్రాడ్యుయేట్. నేను సమర్ధవంతుడిని, విశ్వసనీయత కలవాన్ని మరియు శ్రద్ధగా ఉండేవాన్ని. త్వరిత పని మరియు అధిక-నాణ్యతకు నేను హామీ ఇవ్వగలను. నేను నా క్లయింట్ సూచనల ప్రకారం పని చేస్తాను మరియు అదే సమయంలో, ప్రాజెక్ట్ విజయవంతంగా అమలు చేయడానికి ఉత్తమమైన మార్గాలు మరియు పద్ధతులను నేను సూచించగలను.",
              "or": "ମୁଁ ଏକ ସାମ୍ପ୍ରତିକ ସ୍ନାତକ, ଏକ ଫ୍ରଣ୍ଟ-ଏଣ୍ଡ୍ ଡେଭଲପର୍ ଭାବରେ କାମ କରିବାକୁ ଆଗ୍ରହୀ | ମୁଁ ଜଣେ ଦକ୍ଷ, ନିର୍ଭରଯୋଗ୍ୟ ଏବଂ ସଂଗଠିତ | ଶୀଘ୍ର କାର୍ଯ୍ୟ ଏବଂ ଉଚ୍ଚ-ଗୁଣାତ୍ମକ ଫଳାଫଳ ନିଶ୍ଚିତ ଅଟେ | ମୁଁ ମୋ ଗ୍ରାହକଙ୍କ ନିର୍ଦ୍ଦେଶ ଅନୁଯାୟୀ କାର୍ଯ୍ୟ କରେ, ଏବଂ ସେହି ସମୟରେ, ପ୍ରକଳ୍ପଟି ସଫଳତାର ସହିତ ଚାଲିବା ପାଇଁ ସର୍ବୋତ୍ତମ ଉପାୟ ଏବଂ ପଦ୍ଧତିକୁ ମୁଁ ପରାମର୍ଶ ଦିଏ |",
              "as": "মই এজন শেহতীয়া স্নাতক যি এজন ফ্ৰণ্ট-এণ্ড ডেভলপাৰ হিচাপে কাম কৰিবলৈ আগ্ৰহী। মই এজন দক্ষ, নিৰ্ভৰযোগ্য আৰু সংগঠিত ব্যক্তি। দ্ৰুত গতিত কাম আৰু উচ্চ-মানদণ্ডৰ ফলাফল নিশ্চিত কৰিব পাৰো। মই মোৰ গ্ৰাহকৰ নিৰ্দেশনা অনুসৰি কাম কৰোঁ, আৰু একে সময়তে, মই প্ৰকল্পটো সফলতাৰে চলাবলৈ সৰ্বশ্ৰেষ্ঠ উপায় আৰু পদ্ধতিৰ পৰামৰ্শ দিওঁ।",
              "gu": "હું ફ્રન્ટ-એન્ડ ડેવલપર તરીકે કામ કરવામાં રસ ધરાવતો તાજેતરનો સ્નાતક છું.  હું એક કાર્યક્ષમ, ભરોસાપાત્ર અને આયોજિત છું.  ઝડપી કાર્ય અને ઉચ્ચ-ગુણવત્તાના પરિણામોની ખાતરી આપવામાં આવે છે.  હું મારા ક્લાયન્ટની સૂચનાઓ અનુસાર કામ કરું છું, અને તે જ સમયે, હું પ્રોજેક્ટને સફળતાપૂર્વક ચલાવવા માટે શ્રેષ્ઠ માર્ગો અને પદ્ધતિઓ સૂચવું છું."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_1_4",
              "hi": "E1_1_4",
              "ka": "E1_1_4",
              "te": "E1_1_4",
              "or": "E1_1_4",
              "as": "E1_1_4",
              "gu": "E1_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The next step for Arvind is to prepare his portfolio. He uploads the projects he worked on in college.",
              "hi": "अरविंद के लिए अगला कदम अपने पोर्टफोलियो को तैयार करना है। वह उन प्रोजेक्ट्स को अपलोड करता है जिन पर उसने कॉलेज में काम किया था।",
              "ka": "ಅರವಿಂದ್ ಮುಂದಿನ ಹಂತವೆಂದರೆ ಅವನ ಪೋರ್ಟ್ಫೋಲಿಯೊವನ್ನು ಸಿದ್ಧಪಡಿಸುವುದು. ಅವನು ಕಾಲೇಜಿನಲ್ಲಿ ಕೆಲಸ ಮಾಡಿದ ಪ್ರಾಜೆಕ್ಟ್‌ಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡುತ್ತಾನೆ.",
              "te": "అరవింద్ తదుపరి దశ అతని పోర్ట్‌ఫోలియోను సిద్ధం చేయడం. అతను కాలేజీలో పనిచేసిన ప్రాజెక్ట్‌లను అప్‌లోడ్ చేస్తాడు.",
              "or": "ଅରବିନ୍ଦଙ୍କ ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପ ହେଉଛି ତାଙ୍କ ପୋର୍ଟଫୋଲିଓ ପ୍ରସ୍ତୁତ କରିବା | ସେ କଲେଜରେ କାମ କରିଥିବା ପ୍ରୋଜେକ୍ଟଗୁଡ଼ିକୁ ଅପଲୋଡ୍ କରନ୍ତି |",
              "as": "অৰবিন্দৰ বাবে পৰৱৰ্তী পদক্ষেপটো হ'ল তেওঁৰ পৰ্টফলিঅ প্ৰস্তুত কৰা। তেওঁ কলেজত কাম কৰা প্ৰকল্পবোৰ আপলোড কৰে।",
              "gu": "અરવિંદ માટે આગળનું પગલું પોતાનો પોર્ટફોલિયો તૈયાર કરવાનું હતું.  તેણે કોલેજમાં જે પ્રોજેક્ટ પર કામ કર્યું હતું તે અપલોડ કરવાનું હતું."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_2_1",
              "hi": "E1_2_1",
              "ka": "E1_2_1",
              "te": "E1_2_1",
              "or": "E1_2_1",
              "as": "E1_2_1",
              "gu": "E1_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "It takes some time for him to get work. The first few projects Arvind gets are short-term projects for a week to ten days. They don't challenge him enough and he does not get paid much.",
              "hi": "काम मिलने में उसे कुछ समय लगता है। अरविंद को मिलने वाले पहले कुछ प्रोजेक्ट्स एक सप्ताह से दस दिनों तक अल्पकालिक प्रोजेक्ट्स हैं। वे उसे पर्याप्त चुनौती नहीं देते हैं और उसे ज्यादा भुगतान भी नहीं मिलता है।",
              "ka": "ಅವನಿಗೆ ಕೆಲಸ ಸಿಗಲು ಸ್ವಲ್ಪ ಸಮಯ ಬೇಕು. ಅರವಿಂದ್ ಪಡೆಯುವ ಮೊದಲ ಕೆಲವು ಯೋಜನೆಗಳು ಒಂದು ವಾರದಿಂದ ಹತ್ತು ದಿನಗಳವರೆಗೆ ಅಲ್ಪಾವಧಿಯ ಯೋಜನೆಗಳಾಗಿವೆ. ಅದು ಅವನಿಗೆ ಸಾಕಷ್ಟು ಸವಾಲು ಹಾಕುವುದಿಲ್ಲ ಮತ್ತು ಅವನು ಹೆಚ್ಚು ಸಂಭಾವನೆ ಪಡೆಯುವುದಿಲ್ಲ.",
              "te": "అతనికి పని రావడానికి కొంత సమయం పడుతుంది. అరవింద్‌కి వచ్చే మొదటి కొన్ని ప్రాజెక్ట్‌లు వారం నుంచి పది రోజుల పాటు షార్ట్‌టర్మ్ ప్రాజెక్ట్‌లు. వారు అతనిని తగినంతగా సవాలు చేయరు మరియు అతను ఎక్కువ జీతం పొందడు.",
              "or": "ତାଙ୍କୁ କାମ ପାଇବା ପାଇଁ କିଛି ସମୟ ଲାଗିଥାଏ | ଅରବିନ୍ଦ ପାଇଥିବା ପ୍ରଥମ ପ୍ରୋଜେକ୍ଟଗୁଡ଼ିକ ହେଉଛି ଏକ ସପ୍ତାହରୁ ଦଶ ଦିନ ପାଇଁ ସ୍ୱଳ୍ପକାଳୀନ ପ୍ରକଳ୍ପ | ସେମାନେ ତାଙ୍କୁ ଯଥେଷ୍ଟ ଚ୍ୟାଲେଞ୍ଜ କରନ୍ତି ନାହିଁ ଏବଂ ତାଙ୍କୁ ଅଧିକ ପାରିଶ୍ରମିକ ଦିଅନ୍ତି ନାହିଁ |",
              "as": "কাম পাবলৈ তেওঁৰ কিছু সময় লাগে। অৰবিন্দে লাভ কৰা প্ৰথম কেইটামান প্ৰকল্প হৈছে এক সপ্তাহৰ পৰা দহ দিনৰ বাবে কম সময়ৰ প্ৰকল্প। তেওঁলোকে তেওঁক বেছি প্ৰত্যাহ্বান নজনায় আৰু তেওঁ বেছি দৰমহা নাপায়।",
              "gu": "તેને કામ મળવામાં થોડો સમય લાગે છે.  અરવિંદને મળેલા પ્રથમ કેટલાક પ્રોજેક્ટ એક અઠવાડિયાથી દસ દિવસના ટૂંકા ગાળાના પ્રોજેક્ટ છે.  તેઓ તેને પૂરતો પડકાર આપતા નથી અને તેને વધારે પગાર પણ મળતો નથી."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_2_2",
              "hi": "E1_2_2",
              "ka": "E1_2_2",
              "te": "E1_2_2",
              "or": "E1_2_2",
              "as": "E1_2_2",
              "gu": "E1_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After a few weeks, he gets his first big project to work as a front-end developer for a fintech company.",
              "hi": "कुछ सप्ताह के बाद, उसे एक फिनटेक कंपनी के लिए फ्रंट एंड डेवलपर के रूप में काम करने के लिए अपना पहला बड़ा प्रोजेक्ट मिलता है।",
              "ka": "ಕೆಲವು ವಾರಗಳ ನಂತರ, ಫಿನ್‌ಟೆಕ್ ಕಂಪನಿಯ ಮುಂಭಾಗದ ಡೆವಲಪರ್ ಆಗಿ ಕೆಲಸ ಮಾಡಲು ಅವನು ತನ್ನ ಮೊದಲ ದೊಡ್ಡ ಯೋಜನೆಯನ್ನು ಪಡೆಯುತ್ತಾನೆ.",
              "te": "కొన్ని వారాల తర్వాత, అతను ఫిన్‌టెక్ కంపెనీకి ఫ్రంట్-ఎండ్ డెవలపర్‌గా పని చేయడానికి తన మొదటి పెద్ద ప్రాజెక్ట్‌ను పొందాడు.",
              "or": "କିଛି ସପ୍ତାହ ପରେ, ସେ ତାଙ୍କର ପ୍ରଥମ ବଡ ପ୍ରୋଜେକ୍ଟକୁ ଏକ ଫିଣ୍ଟେକ୍ କମ୍ପାନୀ ପାଇଁ ଫ୍ରଣ୍ଟ-ଏଣ୍ଡ ଡେଭଲପର୍ ଭାବରେ କାମ କରିବାକୁ ପାଇଲେ |",
              "as": "কেইসপ্তাহমানৰ পিছত, তেওঁ ফিনটেক কোম্পানী এটাৰ ফ্ৰণ্ট-এণ্ড ডেভেলপাৰ হিচাপে কাম কৰিবলৈ তেওঁৰ প্ৰথমটো ডাঙৰ প্ৰকল্প লাভ কৰে।",
              "gu": "થોડા અઠવાડિયા પછી, તેને ફિનટેક કંપની માટે ફ્રન્ટ-એન્ડ ડેવલપર તરીકે કામ કરવા માટે તેનો પ્રથમ મોટો પ્રોજેક્ટ મળે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_2_3",
              "hi": "E1_2_3",
              "ka": "E1_2_3",
              "te": "E1_2_3",
              "or": "E1_2_3",
              "as": "E1_2_3",
              "gu": "E1_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The project is two months long, and he enjoys the work. He understands all the aspects of front-end development.",
              "hi": "प्रोजेक्ट दो महीने तक चलने वाला है, और वह काम का आनंद लेता है। वह फ्रंट-एन्ड विकास के सभी पहलुओं को समझता है।",
              "ka": "ಯೋಜನೆಯು ಎರಡು ತಿಂಗಳ ಅವಧಿಯಾಗಿದೆ, ಮತ್ತು ಅವನು ಕೆಲಸವನ್ನು ಆನಂದಿಸುತ್ತಾನೆ. ಮುಂಭಾಗದ ಅಭಿವೃದ್ಧಿಯ ಎಲ್ಲಾ ಅಂಶಗಳನ್ನು ಅವನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾನೆ.",
              "te": "ప్రాజెక్ట్ రెండు నెలల ఉంది, మరియు అతను పనిని ఆనందిస్తాడు. అతను ఫ్రంట్ ఎండ్ డెవలప్‌మెంట్ యొక్క అన్ని అంశాలను అర్థం చేసుకున్నాడు.",
              "or": "ପ୍ରକଳ୍ପଟି ଦୁଇମାସ ଧରି ଚାଲିବ, ଏବଂ ସେ ଏହି କାର୍ଯ୍ୟକୁ ଉପଭୋଗ କରନ୍ତି | ସେ ଫ୍ରଣ୍ଟ-ଏଣ୍ଡ ବିକାଶର ସମସ୍ତ ଦିଗ ବୁ୍ଝନ୍ତି |",
              "as": "প্ৰকল্পটো দুমাহ দীঘল, আৰু তেওঁ কামটো কৰি ভাল পায়। তেওঁ ফ্ৰণ্ট-এণ্ড ডেভেলপাৰৰ সকলো দিশ বুজি পায়।",
              "gu": "આ પ્રોજેક્ટ બે મહિના લાંબો છે, અને તે કામનો આનંદ માણે છે.  તે ફ્રન્ટ એન્ડ ડેવલપમેન્ટના તમામ પાસાઓને સમજે છે."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_2_4",
              "hi": "E1_2_4",
              "ka": "E1_2_4",
              "te": "E1_2_4",
              "or": "E1_2_4",
              "as": "E1_2_4",
              "gu": "E1_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Over the next few months, he upskills himself on new technologies and coding language through many video tutorials.",
              "hi": "अगले कुछ महीनों में, वह कई वीडियो ट्यूटोरियल के माध्यम से नई प्रौद्योगिकियों और कोडिंग भाषा पर खुद को अपस्किल करता है।",
              "ka": "ಮುಂದಿನ ಕೆಲವು ತಿಂಗಳುಗಳಲ್ಲಿ, ಅವರು ಅನೇಕ ವೀಡಿಯೊ ಟ್ಯುಟೋರಿಯಲ್‌ಗಳ ಮೂಲಕ ಹೊಸ ತಂತ್ರಜ್ಞಾನಗಳು ಮತ್ತು ಕೋಡಿಂಗ್ ಭಾಷೆಯಲ್ಲಿ ಸ್ವತಃ ಕೌಶಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸಿಕೊಂಡರು.",
              "te": "తరువాతి కొన్ని నెలల్లో, అతను అనేక వీడియో ట్యుటోరియల్స్ ద్వారా కొత్త టెక్నాలజీలు మరియు కోడింగ్ లాంగ్వేజ్‌పై జ్ఞానం పెంచుకున్నాడు.",
              "or": "ପରବର୍ତ୍ତୀ କିଛି ମାସ ମଧ୍ୟରେ, ସେ ଅନେକ ଭିଡିଓ ଟ୍ୟୁଟୋରିଆଲ୍ ମାଧ୍ୟମରେ ନୂତନ ଟେକ୍ନୋଲୋଜି ଏବଂ କୋଡିଂ ଭାଷା ଉପରେ ନିଜର ଦକ୍ଷତା ବଢାନ୍ତି |",
              "as": "পৰৱৰ্তী কেইমাহমানৰ ভিতৰত, তেওঁ বহুতো ভিডিঅ' টিউটোৰিয়েলৰ জৰিয়তে নতুন প্ৰযুক্তি আৰু কোডিং ভাষাৰ ওপৰত নিজৰ দক্ষতা অৰ্জন কৰে।",
              "gu": "આગામી થોડા મહિનામાં, તે ઘણા વિડિયો ટ્યુટોરિયલ્સ દ્વારા નવી ટેક્નોલોજી અને કોડિંગ લેંગ્વેજ પર પોતાનાં કૌશલ્યનો વિકસિત કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_2_5",
              "hi": "E1_2_5",
              "ka": "E1_2_5",
              "te": "E1_2_5",
              "or": "E1_2_5",
              "as": "E1_2_5",
              "gu": "E1_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After the first two projects, he gets regular work. In the first year of work, he earns enough money to support himself and save some alongside.",
              "hi": "पहले दो प्रोजेक्ट्स के बाद, उसे नियमित रूप से काम मिलने लगता है। काम के पहले वर्ष में, वह खुद का समर्थन करने और साथ ही कुछ बचाने के लिए पर्याप्त धन कमाता है।",
              "ka": "ಮೊದಲ ಎರಡು ಯೋಜನೆಗಳ ನಂತರ, ಸಾಮಾನ್ಯ ಕೆಲಸವನ್ನು ಪಡೆಯುತ್ತಾನೆ. ಕೆಲಸದ ಮೊದಲ ವರ್ಷದಲ್ಲಿ, ಅವನು ತನ್ನನ್ನು ಬೆಂಬಲಿಸಲು ಮತ್ತು ಸ್ವಲ್ಪ ಹಣವನ್ನು ಉಳಿಸಲು ಸಾಕಷ್ಟು ಹಣವನ್ನು ಗಳಿಸುತ್ತಾನೆ.",
              "te": "మొదటి రెండు ప్రాజెక్ట్‌ల తర్వాత, అతనికి రెగ్యులర్ వర్క్ వస్తుంది. పని చేసిన మొదటి సంవత్సరంలో, అతను తనకు పోషణకు మరియు కొంత పొదుపు చేసుకోవడానికి తగినంత డబ్బు సంపాదిస్తాడు.",
              "or": "ପ୍ରଥମ ଦୁଇଟି ପ୍ରୋଜେକ୍ଟ ପରେ ସେ ନିୟମିତ କାମ ପାଆନ୍ତି | କାର୍ଯ୍ୟର ପ୍ରଥମ ବର୍ଷରେ, ସେ ନିଜକୁ ସମର୍ଥନ କରିବା ପାଇଁ ଏବଂ କିଛି ପାଖରେ ସଞ୍ଚୟ କରିବା ପାଇଁ ପର୍ଯ୍ୟାପ୍ତ ଅର୍ଥ ଉପାର୍ଜନ କରନ୍ତି |",
              "as": "প্ৰথম দুটা প্ৰকল্পৰ পিছত, তেওঁ নিয়মীয়াকৈ কাম পায়। কামৰ প্ৰথম বছৰত, তেওঁ নিজৰ ভৰণ-পোষণ আৰু লগতে কিছু সঞ্চয় কৰিবলৈ যথেষ্ট ধন উপাৰ্জন কৰে।",
              "gu": "પહેલા બે પ્રોજેક્ટ પછી તેને નિયમિત કામ મળે છે.  કામના પ્રથમ વર્ષમાં, તે પોતાની જાતને ટેકો આપવા અને સાથે કેટલાક બચત કરવા માટે પૂરતા પૈસા કમાય છે."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_3_1",
              "hi": "E1_3_1",
              "ka": "E1_3_1",
              "te": "E1_3_1",
              "or": "E1_3_1",
              "as": "E1_3_1",
              "gu": "E1_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He loves the freedom of the job as it also gives him the time to continue his involvement with the support group.",
              "hi": "वह नौकरी की स्वतंत्रता से प्यार करता है क्योंकि उसे समर्थन समूह के साथ अपनी भागीदारी जारी रखने का समय भी मिल जाता है।",
              "ka": "ಅವರು ಕೆಲಸದ ಸ್ವಾತಂತ್ರ್ಯವನ್ನು ಪ್ರೀತಿಸುತ್ತಾರೆ ಏಕೆಂದರೆ ಇದು ಬೆಂಬಲ ಗುಂಪಿನೊಂದಿಗೆ ತನ್ನ ತೊಡಗಿಸಿಕೊಳ್ಳುವಿಕೆಯನ್ನು ಮುಂದುವರಿಸಲು ಸಮಯವನ್ನು ನೀಡುತ್ತದೆ.",
              "te": "అతను ఉద్యోగ స్వేచ్ఛను ఇష్టపడతాడు, ఎందుకంటే ఇది సహాయక బృందంతో తన ప్రమేయాన్ని కొనసాగించడానికి అతనికి సమయాన్ని ఇస్తుంది.",
              "or": "ସେ ଚାକିରିର ସ୍ୱାଧୀନତାକୁ ଭଲ ପାଆନ୍ତି କାରଣ ଏହା ତାଙ୍କୁ ସହାୟକ ଗୋଷ୍ଠୀ ସହିତ ଜଡିତ ହେବା ପାଇଁ ସମୟ ଦେଇଥାଏ |",
              "as": "তেওঁ চাকৰিটোৰ স্বাধীনতা ভাল পায় কিয়নো ই তেওঁক সমৰ্থন গোটৰ সৈতে জড়িত হৈ থাকিবলৈ সময় দিয়ে।",
              "gu": "તેને નોકરીની સ્વતંત્રતા ગમે છે કારણ કે તે તેને સપોર્ટ ગ્રૂપ સાથે તેની સંલગ્નતા ચાલુ રાખવાનો સમય પણ આપે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "Freelance",
              "hi": "फ्रीलांस",
              "ka": "ಫ್ರೀಲಾನ್ಸ",
              "te": "ఫ్రీలాన్స్",
              "or": "ଫ୍ରିଲାନ୍ସ",
              "as": "ফ্ৰিলেন্স",
              "gu": "ફ્રીલાન્સ"
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_3_2",
              "hi": "E1_3_2",
              "ka": "E1_3_2",
              "te": "E1_3_2",
              "or": "E1_3_1",
              "as": "E1_3_2",
              "gu": "E1_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The support group has been helping him overcome his fears. He has been feeling a lot more confident about stepping out.",
              "hi": "समर्थन समूह उसे अपने डर को दूर करने में सहायता कर रहा है। वह यह कदम उठाने के बारे में बहुत अधिक आत्मविश्वास का अनुभव कर रहा है।",
              "ka": "ಬೆಂಬಲ ಗುಂಪು ಅವನ ಭಯವನ್ನು ಹೋಗಲಾಡಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತಿದೆ. ಅವರು ಹೊರಹೋಗುವ ಬಗ್ಗೆ ಹೆಚ್ಚು ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಅನುಭವಿಸುತ್ತಿದ್ದಾರೆ.",
              "te": "అతని భయాలను అధిగమించడానికి సహాయక బృందం అతనికి సహాయం చేస్తోంది. అతను బయటకు వెళ్లడంపై మరింత నమ్మకంగా ఉన్నాడు.",
              "or": "ସହାୟକ ଦଳ ତାଙ୍କୁ ତାଙ୍କ ଭୟ ଦୂର କରିବାରେ ସାହାଯ୍ୟ କରୁଛି | ସେ ବାହାରକୁ ଯିବା ବିଷୟରେ ଅଧିକ ଆତ୍ମବିଶ୍ୱାସ ଅନୁଭବ କରୁଛନ୍ତି |",
              "as": "সমৰ্থন গোটে তেওঁক তেওঁৰ ভয় দূৰ কৰাত সহায় কৰি আহিছে। তেওঁ বাহিৰলৈ ওলাই যোৱাৰ বিষয়ে বহুত আত্মবিশ্বাসী অনুভৱ কৰি আছে।",
              "gu": "સપોર્ટ ગ્રુપ તેને તેના ડરને દૂર કરવામાં મદદ કરી રહ્યું છે.  બહાર નીકળવા અંગે તે ઘણો વધુ આત્મવિશ્વાસ અનુભવી રહ્યો છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_3_3",
              "hi": "E1_3_3",
              "ka": "E1_3_3",
              "te": "E1_3_3",
              "or": "E1_3_3",
              "as": "E1_3_3",
              "gu": "E1_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "But he still struggles with his own identity. After his experience in college, he doesn't feel comfortable about who he is and how he feels.",
              "hi": "लेकिन वह अभी भी अपनी पहचान के साथ संघर्ष कर रहा है। कॉलेज में अपने अनुभव के बाद, वह इस बारे में सहज महसूस नहीं करता कि वह कौन है और कैसा अनुभव करता है।",
              "ka": "ಆದರೆ ಅವನು ಇನ್ನೂ ತನ್ನದೇ ಆದ ಗುರುತಿನೊಂದಿಗೆ ಹೋರಾಡುತ್ತಾನೆ. ಕಾಲೇಜಿನಲ್ಲಿನ ಅನುಭವದ ನಂತರ, ಅವನು ಯಾರೆಂದು ಮತ್ತು ಅವನು ಹೇಗೆ ಭಾವಿಸುತ್ತಾನೆ ಎಂಬುದರ ಬಗ್ಗೆ ಅವನಿಗೆ ಆರಾಮದಾಯಕವಾಗುವುದಿಲ್ಲ.",
              "te": "కానీ అతను ఇప్పటికీ తన స్వంత గుర్తింపుతో పోరాడుతున్నాడు. కాలేజీలో తన అనుభవం తర్వాత, అతను ఎవరో మరియు అతను ఎలా భావిస్తున్నాడు అనే విషయాలపై అతను సౌకర్యంగా లేడు.",
              "or": "କିନ୍ତୁ ତଥାପି ସେ ନିଜର ପରିଚୟ ସହିତ ସଂଘର୍ଷ କରନ୍ତି | କଲେଜରେ ତାଙ୍କର ଅଭିଜ୍ଞତା ପରେ, ସେ କିଏ ଏବଂ ସେ କିପରି ଅନୁଭବ କରନ୍ତି, ତାହା ବିଷୟରେ ଆରାମଦାୟକ ଅନୁଭବ କରନ୍ତି ନାହିଁ |",
              "as": "কিন্তু তেওঁ এতিয়াও নিজৰ পৰিচয়ৰ সৈতে সংগ্ৰাম কৰি আছে। কলেজত তেওঁৰ অভিজ্ঞতাৰ পিছত, তেওঁ কোন আৰু তেওঁ কেনে অনুভৱ কৰে সেই বিষয়ে সহজ অনুভৱ নকৰে।",
              "gu": "પરંતુ તે હજુ પણ પોતાની ઓળખ સાથે સંઘર્ષ કરી રહ્યો છે.  કૉલેજમાં તેના અનુભવ પછી, તે કોણ છે અને તે કેવું અનુભવે છે તે વિશે તે સારાં અનુભવતો નથી."
            }
          }
        ],
        "page13": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_4_1",
              "hi": "E1_4_1",
              "ka": "E1_4_1",
              "te": "E1_4_1",
              "or": "E1_4_1",
              "as": "E1_4_1",
              "gu": "E1_4_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "One day, Roshni and Arvind decide to go out in the evening. As they walk to the local food stall, they talk about Arvind's work and how he likes his projects.",
              "hi": "एक दिन, रोशनी और अरविंद शाम को बाहर जाने का निश्चय करते हैं। स्थानीय खाद्य स्टाल तक पैदल चलते हुए वे अरविंद के काम और वह अपने प्रोजेक्ट्स को कितना पसंद करता है इस विषय में बात करते हैं।",
              "ka": "ಒಂದು ದಿನ, ರೋಶ್ನಿ ಮತ್ತು ಅರವಿಂದ್ ಸಂಜೆ ಹೊರಗೆ ಹೋಗಲು ನಿರ್ಧರಿಸಿದರು. ಅವರು ಸ್ಥಳೀಯ ಫುಡ್ ಸ್ಟಾಲ್‌ಗೆ ಹೋಗುವಾಗ, ಅವರು ಅರವಿಂದ್ ಅವರ ಕೆಲಸದ ಬಗ್ಗೆ ಮತ್ತು ಅವರ ಯೋಜನೆಗಳನ್ನು ಅವರು ಹೇಗೆ ಇಷ್ಟಪಡುತ್ತಾರೆ ಎಂಬುದರ ಕುರಿತು ಮಾತನಾಡುತ್ತಾರೆ.",
              "te": "ఒకరోజు రోష్ని, అరవింద్ సాయంత్రం బయటకు వెళ్లాలని నిర్ణయించుకున్నారు. వారు స్థానిక ఫుడ్ స్టాల్‌కి వెళుతున్నప్పుడు, వారు అరవింద్ పని గురించి మరియు అతని ప్రాజెక్ట్‌లను అతను ఎలా ఇష్టపడుతున్నాడో మాట్లాడుకుంటారు.",
              "or": "ଦିନେ ରୋଶନି ଏବଂ ଅରବିନ୍ଦ ସନ୍ଧ୍ୟାରେ ବାହାରକୁ ଯିବାକୁ ସ୍ଥିର କରନ୍ତି | ସେମାନେ ସ୍ଥାନୀୟ ଖାଦ୍ୟ ଷ୍ଟଲକୁ ଯିବାବେଳେ ସେମାନେ ଅରବିନ୍ଦଙ୍କ କାର୍ଯ୍ୟ ଏବଂ ସେ କିପରି ଭାବରେ ତାଙ୍କର ପ୍ରୋଜେକ୍ଟଗୁଡିକ ପସନ୍ଦ କରନ୍ତି ସେ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି |",
              "as": "এদিন, ৰোশনি আৰু অৰবিন্দে সন্ধিয়া বাহিৰলৈ যোৱাৰ সিদ্ধান্ত লয়। তেওঁলোকে স্থানীয় খাদ্যৰ দোকানলৈ খোজ কাঢ়ি যাওঁতে, তেওঁলোকে অৰবিন্দৰ কাম আৰু তেওঁ কেনেদৰে তেওঁৰ প্ৰকল্পবোৰ কৰি ভাল পায় তাৰ বিষয়ে কয়।",
              "gu": "એક દિવસ, રોશની અને અરવિંદ સાંજે બહાર જવાનું નક્કી કરે છે.  જ્યારે તેઓ સ્થાનિક ફૂડ સ્ટોલ પર જાય છે, ત્યારે તેઓ અરવિંદના કામ વિશે અને તેમને તેમના પ્રોજેક્ટ્સ કેવી રીતે પસંદ છે તે વિશે વાત કરે છે."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_4_2//",
              "hi": "E1_4_2 //",
              "ka": "E1_4_2//",
              "te": "E1_4_2//",
              "or": "E1_4_2//",
              "as": "E1_4_2//",
              "gu": "E1_4_2//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "You seem so lost, Arvind. Is work going okay?",
              "hi": "तुम इतने खोए खोए लगते हो, अरविंद। क्या काम ठीक से हो रहा है?",
              "ka": "ನೀನು ಸೋತಂತೆ ಕಾಣುತ್ತಿದೆ ಅರವಿಂದ್. ಕೆಲಸ ಸರಿಯಾಗಿ ನಡೆಯುತ್ತಿದೆಯೇ?",
              "te": "నువ్వు చాలా బాధగా ఉన్నావు, అరవింద్. పని సరిగ్గా జరుగుతుందా?",
              "or": "ତୁମେ ହଜିଯାଇଥିବା ପରି ଲାଗୁଛ, ଅରବିନ୍ଦ | କାମ ଠିକ ଅଛି କି?",
              "as": "আপুনি ইমান অন্যমনস্ক যেন লাগিছে, অৰবিন্দ। কাম ঠিকেই চলি আছেতো?",
              "gu": "અરવિંદ, તું ઉદાસ લાગે છે.  શું કામ બરાબર ચાલે છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Work is good.",
              "hi": "काम अच्छा है।",
              "ka": "ಕೆಲಸ ಚೆನ್ನಾಗಿದೆ.",
              "te": "పని బాగుంది.",
              "or": "କାମ ଭଲ ଅଛି",
              "as": "কাম ঠিকেই চলি আছে।",
              "gu": "કામ સારું ચાલે છે"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "So then? What's wrong?",
              "hi": "तो फिर? क्या गलत है?",
              "ka": "ಆದ್ದರಿಂದ? ಏನು ತಪ್ಪಾಯಿತು?",
              "te": "అయితే మరి? సమస్య ఏమిటి?",
              "or": "ତେବେ? କଣ ଭୁଲ ହେଲା?",
              "as": "তেন্তে? সমস্যাটো কি?",
              "gu": "તો પછી?  સમસ્યા શું છે?"
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_4_3////",
              "hi": "E1_4_3 ////",
              "ka": "E1_4_3////",
              "te": "E1_4_3////",
              "or": "E1_4_3////",
              "as": "E1_4_3////",
              "gu": "E1_4_3////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "I've been feeling very lonely.",
              "hi": "मैं बहुत अकेलापन अनुभव कर रहा हूँ।",
              "ka": "ನಾನು ತುಂಬಾ ಒಂಟಿತನ ಅನುಭವಿಸುತ್ತಿದ್ದೇನೆ.",
              "te": "నేను చాలా ఒంటరిగా ఫీలయ్యాను.",
              "or": "ମୁଁ ବହୁତ ଏକୁଟିଆ ଅନୁଭବ କରୁଛି",
              "as": "মই বৰ অকলশৰীয়া অনুভৱ কৰি আছোঁ।",
              "gu": "હું ખૂબ જ એકલતાનો અનુભવ કરી રહ્યો છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Obviously, you are working by yourself the entire day. Do you think you can get a job somewhere instead?",
              "hi": "जाहिर है, तुम पूरे दिन अकेले ही काम कर रहे हो। क्या तुमको लगता है कि इसके बदले तुमको कहीं नौकरी मिल सकती है?",
              "ka": "ನಿಸ್ಸಂಶಯವಾಗಿ, ನೀವು ಇಡೀ ದಿನ ನೀವೇ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೀರಿ. ಬದಲಾಗಿ ಎಲ್ಲಿಯಾದರೂ ಕೆಲಸ ಸಿಗುತ್ತದೆ ಎಂದು ನೀವು ಭಾವಿಸುತ್ತೀರಾ?",
              "te": "అంతే కదా, నువ్వు రోజంతా పని చేస్తున్నావు. బదులుగా నువ్వు ఎక్కడైనా ఉద్యోగం పొందవచ్చని అనుకుంటున్నావా?",
              "or": "ଆଜ୍ଞା ହଁ, ଆପଣ ଦିନସାରା ନିଜେ କାମ କରୁଛନ୍ତି | ଆପଣ ଏହା ବଦଳରେ କୌଣସି ସ୍ଥାନରେ ଚାକିରି ପାଇପାରିବେ ବୋଲି ଆପଣ ଭାବୁଛନ୍ତି କି?",
              "as": "অৱশ্যে, আপুনি গোটেই দিনটো নিজে কাম কৰি আছে। আপুনি ভাবেনে যে আপুনি তাৰ সলনি কৰবাত চাকৰি পাব পাৰে?",
              "gu": "સ્વાભાવિક છે, તમે આખો દિવસ જાતે કામ કરો છો.  શું તમને લાગે છે કે તમે તેના બદલે ક્યાંક નોકરી મેળવી શકશો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I don't know Roshni. Even if I do get a job somewhere, it isn't going to help me feel better about myself.",
              "hi": "मैं नहीं जानता रोशनी। यहां तक ​​कि अगर मुझे कहीं नौकरी मिल भी जाती है, तो यह मुझे अपने बारे में बेहतर अनुभव करने में सहायता नहीं करेगी।",
              "ka": "ರೋಶನಿ ನನಗೆ ಗೊತ್ತಿಲ್ಲ. ನಾನು ಎಲ್ಲೋ ಒಂದು ಉದ್ಯೋಗವನ್ನು ಪಡೆದರೂ, ಅದು ನನ್ನ ಬಗ್ಗೆ ಉತ್ತಮ ಭಾವನೆಯನ್ನು ಹೊಂದಲು ಸಹಾಯ ಮಾಡುವುದಿಲ್ಲ.",
              "te": "రోష్నీ నాకు తెలియదు. నేను ఎక్కడైనా ఉద్యోగం సంపాదించినా, అది నా గురించి మంచి అనుభూతిని పొందడంలో నాకు సహాయం చేయదు.",
              "or": "ମୁଁ ରୋଶନିଙ୍କୁ ଜାଣେ ନାହିଁ | ଯଦିଓ ମୁଁ କୌଣସି ସ୍ଥାନରେ ଚାକିରୀ ପାଏ, ଏହା ମୋତେ ନିଜ ବିଷୟରେ ଭଲ ଅନୁଭବ କରିବାରେ ସାହାଯ୍ୟ କରିବ ନାହିଁ |",
              "as": "মই ৰোশনিক চিনি নাপাওঁ। আনকি মই কৰবাত চাকৰি এটা পালেও, ই মোক নিজৰ বিষয়ে ভাল অনুভৱ কৰাত সহায় নকৰে।",
              "gu": "મને ખબર નથી રોશની  જો મને ક્યાંક નોકરી મળી જાય, તો પણ તે મને મારા વિશે સારું અનુભવવામાં મદદ કરશે નહીં."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "What about the support group? Has that not been helping?",
              "hi": "समर्थन समूह के बारे में क्या? क्या वह सहायता नहीं कर रहा है?",
              "ka": "ಬೆಂಬಲ ಗುಂಪಿನ ಬಗ್ಗೆ ಏನು? ಅದು ಸಹಾಯ ಮಾಡಲಿಲ್ಲವೇ?",
              "te": "సపోర్ట్ గ్రూప్ సంగతి ఏమిటి? అది సహాయం చేయలేదా?",
              "or": "ସମର୍ଥନ ଗୋଷ୍ଠୀ ବିଷୟରେ କ’ଣ? ତାହା ସାହାଯ୍ୟ କରୁନାହିଁ କି?",
              "as": "সমৰ্থন গোটটোৰ বিষয়ে কি? সেইটোৱে সহায় কৰা নাই নেকি?",
              "gu": "સપોર્ટ ગ્રુપ નું  શું?  શું તે મદદ કરતું નથી?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "It has, but that isn't the problem. I'm just not comfortable with who I am.",
              "hi": "कर रहा है, लेकिन यह समस्या नहीं है। मैं कौन हूँ बस इस बात से मैं इतना सहज नहीं हूं।",
              "ka": "ಇದು ಹೊಂದಿದೆ, ಆದರೆ ಅದು ಸಮಸ್ಯೆ ಅಲ್ಲ. ನಾನು ಯಾರು ಎಂಬುದರ ಬಗ್ಗೆ ಬಗ್ಗೆ ಆರಾಮದಾಯಕವಾಗಿಲ್ಲ.",
              "te": "అది చేసింది, కానీ అది సమస్య కాదు. నేను ఎవరు అనేదానితో నేను సౌకర్యంగా లేను.",
              "or": "ଏହାର ଅଛି, କିନ୍ତୁ ତାହା ସମସ୍ଯା ନୁହେଁ | ମୁଁ କେବଳ କାହା ସହିତ ଆରାମଦାୟକ ନୁହେଁ |",
              "as": "কৰি আছে, কিন্তু সমস্যাটো সেইটো নহয়। মই কোন তাৰ সৈতে মই কেৱল সহজবোধ নকৰো।",
              "gu": "તે છે, પરંતુ તે સમસ્યા નથી.  હું જે છું તેનાથી હું કમ્ફર્ટેબલ નથી."
            }
          }
        ],
        "page16": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_4_4//",
              "hi": "E1_4_4 //",
              "ka": "E1_4_4//",
              "te": "E1_4_4//",
              "or": "E1_4_4//",
              "as": "E1_4_4//",
              "gu": "E1_4_4//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Roshni",
              "hi": "रोशनी",
              "ka": "ರೋಶನಿ",
              "te": "రోష్ని",
              "or": "ରୋଶନି",
              "as": "ৰোশনি",
              "gu": "રોશની"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Arvind",
              "hi": "अरविंद",
              "ka": "ಅರವಿಂದ್",
              "te": "అరవింద్",
              "or": "ଅରବିନ୍ଦ",
              "as": "অৰবিন্দ",
              "gu": "અરવિંદ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I don't understand.",
              "hi": "मुझे समझ नहीं आ रहा है।",
              "ka": "ನನಗೆ ಅರ್ಥವಾಗುತ್ತಿಲ್ಲ.",
              "te": "నాకు అర్థం కాలేదు.",
              "or": "ମୁଁ ବୁଝିପାରୁ ନାହିଁ",
              "as": "মই বুজি পোৱা নাই।",
              "gu": "મને સમજાતું નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Nothing Roshni. Let's not discuss this now.",
              "hi": "कुछ भी नहीं रोशनी। अब इस पर चर्चा मत करो।",
              "ka": "ಏನಿಲ್ಲ ರೋಶನಿ. ಇದನ್ನು ಈಗ ಚರ್ಚಿಸುವುದು ಬೇಡ",
              "te": "ఏమీ లేదు రోష్నీ. దీని గురించి తర్వాత మాట్లాడుదాం.",
              "or": "କିଛି ନାହିଁ ରୋଶନି | ଚାଲନ୍ତୁ ବର୍ତ୍ତମାନ ଏହି ବିଷୟରେ ଆଲୋଚନା କରିବା ନାହିଁ",
              "as": "একো নহয় ৰোশনি। এতিয়া এই বিষয়ে আলোচনা নকৰোঁ দিয়া।",
              "gu": "કંઈ નહીં રોશની.  ચાલો હવે આની ચર્ચા નહીં કરીએ."
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_4_5",
              "hi": "E1_4_5",
              "ka": "E1_4_5",
              "te": "E1_4_5",
              "or": "E1_4_5",
              "as": "E1_4_5",
              "gu": "E1_4_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind doesn't know how to talk about his problem with Roshni. He decides to approach Vandana for help.",
              "hi": "अरविंद नहीं जानता कि रोशनी के साथ अपनी समस्या के बारे में कैसे बात करे। वह सहायता के लिए वंदना से संपर्क करने का निश्चय करता है।",
              "ka": "ಅರವಿಂದ್‌ಗೆ ರೋಶನಿಯೊಂದಿಗೆ ತನ್ನ ಸಮಸ್ಯೆಯನ್ನು ಹೇಗೆ ಮಾತನಾಡಬೇಕೆಂದು ತಿಳಿದಿಲ್ಲ. ಸಹಾಯಕ್ಕಾಗಿ ವಂದನಾಳನ್ನು ಸಂಪರ್ಕಿಸಲು ಅವನು ನಿರ್ಧರಿಸುತ್ತಾನೆ.",
              "te": "రోష్నితో తన సమస్య గురించి ఎలా మాట్లాడాలో అరవింద్‌కి తెలియదు. సహాయం కోసం వందనను సంప్రదించాలని నిర్ణయించుకున్నాడు.",
              "or": "ଅରବିନ୍ଦ ଜାଣନ୍ତି ନାହିଁ କି ରୋଶନିଙ୍କ ସହ ତାଙ୍କ ସମସ୍ୟା ବିଷୟରେ କିପରି କହିବେ | ସେ ସାହାର୍ଯ୍ଯ ପାଇଁ ଭନ୍ଦନା ନିକଟକୁ ଯିବାକୁ ସ୍ଥିର କଲେ |",
              "as": "অৰবিন্দে ৰোশনিৰ সৈতে তেওঁৰ সমস্যাৰ বিষয়ে কেনেকৈ কথা পাতিব লাগে নাজানে। তেওঁ সহায়ৰ বাবে বন্দনাৰ কাষ চাপিবলৈ সিদ্ধান্ত লয়।",
              "gu": "અરવિંદને ખબર ન હતી કે રોશની સાથે તેની સમસ્યા વિશે કેવી રીતે વાત કરવી.  તેણે મદદ માટે વંદનાનો સંપર્ક કરવાનું નક્કી કર્યું."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_5_1",
              "hi": "E1_5_1",
              "ka": "E1_5_1",
              "te": "E1_5_1",
              "or": "E1_5_1",
              "as": "E1_5_1",
              "gu": "E1_5_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Vandana immediately understands what Arvind is going through. She tells him that it is normal to feel that way.",
              "hi": "वंदना तुरंत समझ जाती है कि अरविंद किस स्थिति से गुज़र रहा है। वह उसे बताती है कि इस तरह अनुभव करना सामान्य है।",
              "ka": "ಅರವಿಂದ್ ಏನಾಗುತ್ತಿದ್ದಾರೆಂದು ವಂದನಾಗೆ ತಕ್ಷಣ ಅರ್ಥವಾಗುತ್ತದೆ. ಹಾಗೆ ಅನಿಸುವುದು ಸಹಜ ಎಂದು ಅವಳು ಅವನಿಗೆ ಹೇಳುತ್ತಾಳೆ.",
              "te": "అరవింద్ ఏం చేస్తున్నాడో వందనకు వెంటనే అర్థమైంది. అలా అనిపించడం సహజమని ఆమె అతనికి చెప్పింది.",
              "or": "ଅରବିନ୍ଦ କ’ଣ କରୁଛନ୍ତି ତାହା ତୁରନ୍ତ ଭନ୍ଦନା କୁ ଜଣାପଡିଲା | ସେ ତାଙ୍କୁ କୁହନ୍ତି ଯେ ସେପରି ଅନୁଭବ କରିବା ସ୍ୱାଭାବିକ |",
              "as": "বন্দনাই লগে লগে বুজি পায় যে অৰবিন্দ কিহৰ মাজেৰে গৈ আছে। তাই তাক কৈছিল যে তেনে অনুভৱ কৰাটো স্বাভাৱিক।",
              "gu": "વંદના તરત જ સમજી ગઈ કે અરવિંદ પર શું ગુજરી રહી છે.  તેણી તેને કહે છે કે આવું અનુભવવું સામાન્ય છે."
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_5_2",
              "hi": "E1_5_2",
              "ka": "E1_5_2",
              "te": "E1_5_2",
              "or": "E1_5_2",
              "as": "E1_5_2",
              "gu": "E1_5_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "According to her, he has to understand who he wants to be and what identity he is comfortable with.",
              "hi": "उनके अनुसार, उसे समझना है कि वह कौन बनना चाहता है और वह किस पहचान के साथ सहज है।",
              "ka": "ಅವಳ ಪ್ರಕಾರ, ಅವನು ಯಾರಾಗಬೇಕೆಂದು ಬಯಸುತ್ತಾನೆ ಮತ್ತು ಅವನು ಯಾವ ಗುರುತನ್ನು ಹೊಂದಿದ್ದಾನೆ ಎಂಬುದನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಬೇಕು.",
              "te": "ఆమె ప్రకారం, అతను ఎవరు కావాలనుకుంటున్నారో మరియు అతను ఏ గుర్తింపుతో సౌకర్యవంతంగా ఉంటాడో అర్థం చేసుకోవాలి.",
              "or": "ତାଙ୍କ କହିବାନୁସାରେ, ତାଙ୍କୁ ବୁଝିବାକୁ ପଡ଼ିବ ଯେ ସେ କଣହେବାକୁ ଚାହାଁନ୍ତି ଏବଂ କେଉଁ ପରିଚୟ ସହିତ ସେ ଆରାମଦାୟକ |",
              "as": "তাইৰ মতে, তেওঁ বুজিব লাগিব তেওঁ কোন হ'ব বিচাৰে আৰু তেওঁ কি পৰিচয়ৰ সৈতে সহজবোধ কৰে।",
              "gu": "તેણીના મતે, તેણે સમજવું પડશે કે તે કોણ બનવા માંગે છે અને તે કઈ ઓળખ સાથે સુખનો અનુભવ કરે છે."
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_5_3",
              "hi": "E1_5_3",
              "ka": "E1_5_3",
              "te": "E1_5_3",
              "or": "E1_5_3",
              "as": "E1_5_3",
              "gu": "E1_5_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Only when he is comfortable with himself will the people around him learn to be comfortable with his identity.",
              "hi": "जब वह खुद के साथ सहज होगा, तब ही उसके आस-पास के लोग उसकी पहचान के साथ सहज अनुभव करना सीखेंगे।",
              "ka": "ಅವನು ತನ್ನೊಂದಿಗೆ ಆರಾಮವಾಗಿದ್ದಾಗ ಮಾತ್ರ ಅವನ ಸುತ್ತಲಿನ ಜನರು ಅವನ ಗುರುತಿನೊಂದಿಗೆ ಆರಾಮದಾಯಕವಾಗಲು ಕಲಿಯುತ್ತಾರೆ.",
              "te": "అతను తనతో సుఖంగా ఉన్నప్పుడు మాత్రమే అతని చుట్టూ ఉన్న వ్యక్తులు అతని గుర్తింపుతో సౌకర్యవంతంగా ఉండటం నేర్చుకుంటారు.",
              "or": "ଯେତେବେଳେ ସେ ନିଜ ସହିତ ଆରାମଦାୟକ, ସେତେବେଳେ ତାଙ୍କ ଆଖପାଖର ଲୋକମାନେ ତାଙ୍କ ପରିଚୟ ସହିତ ଆରାମଦାୟକ ହେବାକୁ ଶିଖିବେ |",
              "as": "কেৱল যেতিয়া তেওঁ নিজৰ সৈতে সহজবোধ কৰে তেতিয়াহে তেওঁৰ চাৰিওফালৰ মানুহে তেওঁৰ পৰিচয়ৰ সৈতে সহজবোধ কৰিবলৈ শিকিব।",
              "gu": "જ્યારે તે પોતાની જાત સાથે આરામદાયક હશે ત્યારે જ તેની આસપાસના લોકો તેની ઓળખ સાથે આરામદાયક થશે."
            }
          }
        ],
        "page21": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_6_1",
              "hi": "E1_6_1",
              "ka": "E1_6_1",
              "te": "E1_6_1",
              "or": "E1_6_1",
              "as": "E1_6_1",
              "gu": "E1_6_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Arvind returns to his room that day and looks in the mirror. He isn't even sure if he is comfortable with being called Arvind.",
              "hi": "अरविंद उस दिन अपने कमरे में लौट कर आता है और दर्पण में देखता है। वह आश्वस्त नहीं है कि अरविंद बुलाए जाने पर वह कितना सहज है।",
              "ka": "ಆ ದಿನ ಅರವಿಂದ್ ತನ್ನ ಕೋಣೆಗೆ ಹಿಂತಿರುಗಿ ಕನ್ನಡಿಯಲ್ಲಿ ನೋಡುತ್ತಾನೆ. ಅರವಿಂದ್ ಎಂದು ಕರೆಯಲು ಅವರು ಆರಾಮದಾಯಕವಾಗಿದ್ದಾರೆಯೇ ಎಂದು ಖಚಿತವಾಗಿಲ್ಲ.",
              "te": "ఆ రోజు అరవింద్ తన గదికి తిరిగి వచ్చి అద్దంలో చూస్తున్నాడు. అతను అరవింద్ అని పిలవడానికి సౌకర్యంగా ఉన్నాడో లేదో కూడా అతనికి తెలియదు.",
              "or": "ସେଦିନ ଅରବିନ୍ଦ ନିଜ କୋଠରୀକୁ ଫେରି ଦର୍ପଣକୁ ଚାହିଁଲେ | ସେ ଅରବିନ୍ଦ କୁହାଯିବାରେ ଆରାମଦାୟକ କି ନାହିଁ ସେ ମଧ୍ୟ ନିଶ୍ଚିତ ନୁହଁନ୍ତି |",
              "as": "অৰবিন্দ সেইদিনা তেওঁৰ কোঠালৈ ঘূৰি আহি নিজকে আইনাত চালে। তেওঁ আনকি নিশ্চিত নহয় যে তেওঁক অৰবিন্দ বুলি কোৱাত সহজবোধ কৰে নে নকৰে।",
              "gu": "તે દિવસે અરવિંદ તેના રૂમમાં પાછો ફર્યો અને અરીસામાં જોયું .  તેને એ પણ ખાતરી નથી કે તે અરવિંદ તરીકે ઓળખાવાથી આરામદાયક છે કે નહીં."
            }
          }
        ],
        "page22": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_6_2",
              "hi": "E1_6_2",
              "ka": "E1_6_2",
              "te": "E1_6_2",
              "or": "E1_6_2",
              "as": "E1_6_2",
              "gu": "E1_6_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He has always known that he is more comfortable living as a woman. He wishes that he was born with a female body instead.",
              "hi": "वह हमेशा से जानता था कि उसे एक महिला के रूप में रहना अधिक आरामदायक लगता है। वह चाहता है कि वह एक स्त्री के शरीर के साथ पैदा हुआ होता।",
              "ka": "ಹೆಣ್ಣಾಗಿ ಬದುಕುವುದು ಹೆಚ್ಚು ಆರಾಮದಾಯಕ ಎಂದು ಯಾವಾಗಲೂ ತಿಳಿದಿದ್ದಾನೆ. ಬದಲಿಗೆ ಹೆಣ್ಣಿನ ದೇಹದೊಂದಿಗೆ ಹುಟ್ಟಿರಬೇಕು ಎಂದು ಹಾರೈಸುತ್ತಾನೆ.",
              "te": "అతను స్త్రీగా జీవించడం మరింత సౌకర్యంగా ఉంటుంది అని అతనికి ఎప్పటి నుంచో తెలుసు. అతను బదులుగా స్త్రీ శరీరంతో జన్మిస్తే బాగుండు అని కోరుకుంటాడు.",
              "or": "ସେ ସର୍ବଦା ଜାଣିଛନ୍ତି ଯେ ସେ ଜଣେ ମହିଳା ଭାବରେ ଜୀବନଯାପନ କରିବାରେ ଅଧିକ ଆରାମଦାୟକ | ସେ ଇଚ୍ଛା କରନ୍ତି ଯେ ସେ ଏହା ପରିବର୍ତ୍ତେ ଏକ ମହିଳା ଶରୀର ସହିତ ଜନ୍ମ ହୋଇଥିଲେ |",
              "as": "তেওঁ সদায় জানে যে তেওঁ এগৰাকী মহিলা হিচাপে জীয়াই থকি বেছি ভাল পাব। তেওঁ আশা কৰে যে ল'ৰাৰ সলনি তেওঁক এটা মহিলাৰ শৰীৰ যদি দিয়া হ'লহেঁতেন।",
              "gu": "તે હંમેશાથી જાણતો હતો કે તે એક મહિલા તરીકે જીવવામાં વધુ આરામદાયક અનુભવે છે.  તે ઈચ્છે છે કે તેનો સ્ત્રી શરીર સાથે જન્મે થયો હોત તો."
            }
          }
        ],
        "page23": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_7_1",
              "hi": "E1_7_1",
              "ka": "E1_7_1",
              "te": "E1_7_1",
              "or": "E1_7_1",
              "as": "E1_7_1",
              "gu": "E1_7_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "He decides to read up more regarding this. Eventually, he feels comfortable enough to tell Roshni what he is feeling.",
              "hi": "वह इसके बारे में अधिक पढ़ने का निश्चय करता है। आखिरकार, वह रोशनी को यह बताने के लिए पर्याप्त सहज महसूस करता है कि उसे कैसा अनुभव हो रहा है।",
              "ka": "ಈ ಬಗ್ಗೆ ಹೆಚ್ಚು ಓದಲು ಅವನು ನಿರ್ಧರಿಸುತ್ತಾನೆ. ಅಂತಿಮವಾಗಿ, ಅವನು ತನ್ನ ಭಾವನೆಯನ್ನು ರೋಶನಿಗೆ ಹೇಳಲು ಸಾಕಷ್ಟು ಹಾಯಾಗಿರುತ್ತಾನೆ.",
              "te": "అతను దీని గురించి మరింత చదవాలని నిర్ణయించుకున్నాడు. చివరికి, అతను తన అనుభూతిని రోష్నికి చెప్పడానికి తగినంత సౌకర్యంగా ఉన్నాడు.",
              "or": "ସେ ଏ ବିଷୟରେ ଅଧିକ ପଡିବାକୁ ନିଷ୍ପତ୍ତି ନେଇଛନ୍ତି | ପରିଶେଷରେ, ସେ ରୋଶନିଙ୍କୁ କ’ଣ ଅନୁଭବ କରୁଛନ୍ତି ତାହା ଜଣାଇବାକୁ ଯଥେଷ୍ଟ ଆରାମଦାୟକ ଅନୁଭବ କରନ୍ତି |",
              "as": "তেওঁ এই সন্দৰ্ভত অধিক পঢ়িবলৈ সিদ্ধান্ত লয়। অৱশেষত, তেওঁ ৰোশনিক তেওঁ কি অনুভৱ কৰিছে ক'বলৈ যথেষ্ট সহজ অনুভৱ কৰে।",
              "gu": "તેણે આ અંગે વધુ વાંચવાનું નક્કી કર્યું.  આખરે, પોતે કેવું અનુભવ કરે છે તે રોશનીને કહેવા માટે તે પૂરતું આરામદાયક અનુભવે છે."
            }
          }
        ],
        "page25": [
          {
            "valuePath": "display",
            "value": {
              "en": "E1_7_2////",
              "hi": "E1_7_2 ////////",
              "ka": "E1_7_2////",
              "te": "E1_7_2////",
              "or": "E1_7_2////",
              "as": "E1_7_2////",
              "gu": "E1_7_2////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Roshni, I have been thinking about this for a while. I have decided that I am a woman and that my name from now on will be Asmita, with ‘she/her’ pronouns.",
              "hi": "रोशनी, मैं इस विषय में कुछ दिनों से सोच रही थी। मैंने तय किया है कि मैं एक महिला हूं और अब से मेरा नाम अस्मिता होगा, जिसमें 'वह/उसकी' सर्वनाम होगा।",
              "ka": "ರೋಶನಿ, ನಾನು ಸ್ವಲ್ಪ ಸಮಯದಿಂದ ಈ ಬಗ್ಗೆ ಯೋಚಿಸುತ್ತಿದ್ದೇನೆ. ನಾನೊಬ್ಬ ಮಹಿಳೆ ಮತ್ತು ಇನ್ನು ಮುಂದೆ ನನ್ನ ಹೆಸರು ‘ಅವಳು/ಅವಳ’ ಸರ್ವನಾಮಗಳೊಂದಿಗೆ ಅಸ್ಮಿತಾ ಎಂದು ನಾನು ನಿರ್ಧರಿಸಿದೆ.",
              "te": "రోష్నీ, నేను దీని గురించి కొంతకాలంగా ఆలోచిస్తున్నాను. నేను స్త్రీని, ఇక నుండి నా పేరు అస్మిత అని, ‘ఆమె/తను’ సర్వనామాలతో ఉండాలని నిర్ణయించుకున్నాను.",
              "or": "ରୋଶନି, ମୁଁ କିଛି ସମୟ ପାଇଁ ଏ ବିଷୟରେ ଚିନ୍ତା କରୁଛି | ମୁଁ ସ୍ଥିର କଲି ଯେ ମୁଁ ଜଣେ ମହିଳା ଏବଂ ବର୍ତ୍ତମାନଠାରୁ ମୋର ନାମ ଅସ୍ମିତା ହେବ ସର୍ବନାମ ‘ସେ / ତା’ର’ ଉଚ୍ଚାରଣ ସହିତ |",
              "as": "ৰোশনি, মই অলপ সময়ৰ পৰা এই বিষয়ে চিন্তা কৰি আছো। মই সিদ্ধান্ত লৈছো যে মই এতিয়াৰ পৰা এগৰাকী মহিলা আৰু 'তাই/তাইৰ' সৰ্বনামৰ সৈতে মোৰ নাম হ'ব অস্মিতা।",
              "gu": "રોશની, હું થોડા સમયથી આ વિશે વિચારી રહ્યો છું.  મેં નક્કી કર્યું છે કે હું એક સ્ત્રી છું અને હવેથી મારું નામ 'તે/તેણીના' સર્વનામ સાથે અસ્મિતા હશે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I am so happy for you, Arvin.. I mean Asmita!... sorry, I’m too used to calling you Arvind. Who all have you spoken to about this?",
              "hi": "मैं तुम्हारे लिए बहुत खुश हूं, अरविन! .. मेरा मतलब अस्मिता! ... क्षमा करो, मैं तुमको अरविंद कहने की इतनी आदी हो गई हूँ। तुमने इस बारे में किससे बात की है?",
              "ka": "ನಿನಗಾಗಿ ನನಗೆ ತುಂಬಾ ಸಂತೋಷವಾಗಿದೆ, ಅರವಿಂದ್.. ಅಂದರೆ ಅಸ್ಮಿತಾ!... ಕ್ಷಮಿಸಿ, ನಾನು ನಿನ್ನನ್ನು ಅರವಿಂದ್ ಎಂದು ಕರೆಯುವುದು ತುಂಬಾ ಅಭ್ಯಾಸವಾಗಿದೆ. ಇದರ ಬಗ್ಗೆ ನೀವು ಯಾರೊಂದಿಗೆ ಮಾತನಾಡಿದ್ದೀರಿ?",
              "te": "నాకు చాలా సంతోషంగా ఉంది, అర్విన్.. అంటే అస్మితా!... సారీ, నేను నిన్ను అరవింద్ అని పిలవడం చాలా అలవాటు అయింది. దీని గురించి నువ్వు ఎవరితో మాట్లాడావు?",
              "or": "ମୁଁ ତୁମ ପାଇଁ ବହୁତ ଖୁସି, ଅରବିନ୍ .. ମୋର ଅର୍ଥ ଅସ୍ମିତା! ... ଦୁଃଖିତ, ମୁଁ ତୁମକୁ ଅରବିନ୍ଦ ବୋଲି ଡାକିବାରେ ଅଭ୍ୟସ୍ତ | ଆପଣ ଏ ବିଷୟରେ କାହା ସହିତ କଥା ହୋଇଛନ୍ତି?",
              "as": "মই আপোনাৰ বাবে বহুত সুখী, অৰবিন্দ!.. মানে অস্মিতা!... বেয়া নাপাব, মই আপোনাক অৰবিন্দ বুলি মতাতেই বহুত অভ্যস্ত। আপুনি এই বিষয়ে কাৰ সৈতে কথা পাতিছে?",
              "gu": "હું તારા માટે ખૂબ જ ખુશ છું, અરવિન.. આઈ મીન અસ્મિતા!... માફ કરજે, પણ મને તને અરવિંદ કહેવાની આદત છે.  તમે આ વિશે કોની સાથે વાત કરી છે?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "No one. You’re the first to know.",
              "hi": "किसी से नहीं। तुम सबसे पहली जानने वाली हो।",
              "ka": "ಯಾರೂ ಇಲ್ಲ. ನೀವು ಮೊದಲು ತಿಳಿದಿರುವಿರಿ.",
              "te": "ఎవరితో మాట్లాడలేదు. మొదట నీకే చెప్పాను.",
              "or": "କେହି ନୁହେ। ଆପଣ ପ୍ରଥମ ଜାଣିଛନ୍ତି",
              "as": "কাৰো লগত পতা নাই। তুমিয়ে প্ৰথমে জানিব পাৰিছা।",
              "gu": "કોઈને નહિ.  આ જાણનારા તું પ્રથમ છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "I feel glad. But when are you planning to share this with Amma and Appa, and even Pranav? There’s so much to do, but don’t worry, I’ll be with you every step of the way.",
              "hi": "मुझे खुशी है। लेकिन तुम इसे अम्मा, अप्पा और प्रणव के साथ कब साझा करने की योजना बना रहे हो? करने के लिए शायद बहुत कुछ है, लेकिन चिंता मत करो, मैं हर कदम पर तुम्हारे साथ रहूंगी।",
              "ka": "ನನಗೆ ಖುಷಿಯಾಗುತ್ತಿದೆ. ಆದರೆ ನೀವು ಇದನ್ನು ಯಾವಾಗ ಅಮ್ಮ ಮತ್ತು ಅಪ್ಪ, ಮತ್ತು ಪ್ರಣವ್ ಅವರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಲು ಯೋಜಿಸುತ್ತಿದ್ದೀರಿ? ಮಾಡಲು ತುಂಬಾ ಇದೆ, ಆದರೆ ಚಿಂತಿಸಬೇಡಿ, ಪ್ರತಿ ಹಂತದಲ್ಲೂ ನಾನು ನಿಮ್ಮೊಂದಿಗೆ ಇರುತ್ತೇನೆ.",
              "te": "నాకు సంతోషంగా ఉంది. అయితే ఈ విషయాన్ని అమ్మతో, నాన్నతో, ప్రణవ్‌తో ఎప్పుడు చెప్పాలని ప్లాన్ చేస్తున్నావు? చేయాల్సింది చాలా ఉంది, కానీ చింతించకు, నేను నీతో అడుగడుగునా ఉంటాను.",
              "or": "ମୁଁ ଖୁସି ଅନୁଭବ କରୁଛି | କିନ୍ତୁ ଆପଣ କେବେ ଆମ୍ମା ଏବଂ ଆପ୍ପା, ଏବଂ ପ୍ରଣବଙ୍କ ସହ ଏହା ଅଂଶୀଦାର କରିବାକୁ ଯୋଜନା କରୁଛନ୍ତି ? ବହୁତ କିଛି କରିବାକୁ ଅଛି, କିନ୍ତୁ ବ୍ୟସ୍ତ ହୁଅ ନାହିଁ, ମୁଁ ପ୍ରତ୍ୟେକ ପଥରେ ତୁମ ସହିତ ରହିବି |",
              "as": "মই সুখী হৈছো। কিন্তু আপুনি কেতিয়া আম্মা, আপ্পা আৰু প্ৰণৱৰ সৈতে এইটো আলোচনা কৰাৰ কথা ভাবিছে? সম্ভৱতঃ বহুত কাম কৰিব লাগিব, কিন্তু চিন্তা নকৰিব, মই প্ৰতিটো ক্ষেত্ৰতে আপোনাৰ সৈতে থাকিম।",
              "gu": "મને આનંદ થાય છે.  પણ તું આ વાત મમ્મી, પપ્પા અને પ્રણવ સાથે ક્યારે શેર કરવાનું વિચારી રહ્યો છે?  કરવા માટે ઘણું બધું છે, પરંતુ ચિંતા ન કરીશ, હું દરેક પગલે તારી સાથે રહીશ."
            }
          }
        ]
      },
      "scene41": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_1",
              "hi": "E2_1_1",
              "ka": "E2_1_1",
              "te": "E2_1_1",
              "or": "E2_1_1",
              "as": "E2_1_1",
              "gu": "E2_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is happy that Roshni is so comfortable with her transition. But they decide to hold on to this until she feels comfortable enough to tell her family.",
              "hi": "अस्मिता खुश है कि रोशनी उसके ट्रांज़िशन/ बदलाव के साथ सहज है। लेकिन वे इस बात को तब तक रोक कर रखने का निर्णय लेते हैं जब तक वह अपने परिवार को बताने के लिए पर्याप्त सहज अनुभव नहीं करती।",
              "ka": "ರೋಶನಿ ತನ್ನ ಸ್ಥಿತ್ಯಂತರದಲ್ಲಿ ತುಂಬಾ ಆರಾಮದಾಯಕಳಾಗಿದ್ದಾಳೆ ಎಂದು ಅಸ್ಮಿತಾ ಸಂತೋಷಪಟ್ಟಿದ್ದಾಳೆ. ಆದರೆ ಅವಳು ತನ್ನ ಮನೆಯವರಿಗೆ ಹೇಳುವಷ್ಟು ಆರಾಮದಾಯಕವಾಗುವವರೆಗೆ ಇದನ್ನು ಹಿಡಿದಿಟ್ಟುಕೊಳ್ಳಲು ಅವರು ನಿರ್ಧರಿಸುತ್ತಾರೆ.",
              "te": "రోష్ని తన పరివర్తనతో చాలా సౌకర్యంగా ఉన్నందుకు అస్మిత సంతోషంగా ఉంది. కానీ ఆమె తన కుటుంబానికి చెప్పడానికి రెడీగా ఉండేవరకు వారు దీనిని చెప్పొద్దు అని నిర్ణయించుకున్నారు.",
              "or": "ଅସ୍ମିତା ଖୁସି ଯେ ରୋଶନି ତାଙ୍କ ପରିବର୍ତ୍ତନ ସହିତ ଏତେ ସହଜ ଅଛନ୍ତି | କିନ୍ତୁ ସେ ନିଜ ପରିବାରକୁ କହିବାକୁ ଯଥେଷ୍ଟ ସହଜ ନହେବା ପର୍ଯ୍ୟନ୍ତ ସେମାନେ ଏହାକୁ ସ୍ଥଗିତ ରଖିବାକୁ ସ୍ଥିର କରନ୍ତି |",
              "as": "অস্মিতা সুখী যে ৰোশনি তাইৰ পৰিৱৰ্তনৰ সৈতে ইমান সহজবোধ কৰিছে। কিন্তু যেতিয়ালৈকে তাই তাইৰ পৰিয়ালক ক'বলৈ যথেষ্ট সহজ অনুভৱ নকৰে তেতিয়ালৈকে তেওঁলোকে ইয়াক মনে মনে ৰখাৰ সিদ্ধান্ত লয় ।",
              "gu": "અસ્મિતા ખુશ છે કે રોશની તેના ટ્રાન્સિશનથી ખૂબ જ ખુશ છે.  પરંતુ જ્યાં સુધી તેણી તેના પરિવારને કહેવા માટે પૂરતી આરામનો અનુભવ ન કરે ત્યાં સુધી તેઓ આને છુપાવી રાખવાનું નક્કી કરે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_2",
              "hi": "E2_1_2",
              "ka": "E2_1_2",
              "te": "E2_1_2",
              "or": "E2_1_2",
              "as": "E2_1_2",
              "gu": "E2_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She has been spending a lot of time with Vandana to understand everything she must do in the process of transitioning.",
              "hi": "ट्रांज़िशन/ बदलाव की प्रक्रिया में उसे जो कुछ भी करना चाहिए यह समझने के लिए वह वंदना के साथ बहुत समय बिता रही है।",
              "ka": "ಪರಿವರ್ತನೆಯ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ಅವಳು ಮಾಡಬೇಕಾದ ಎಲ್ಲವನ್ನೂ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಅವಳು ವಂದನಾಳೊಂದಿಗೆ ಸಾಕಷ್ಟು ಸಮಯವನ್ನು ಕಳೆಯುತ್ತಿದ್ದಳು.",
              "te": "పరివర్తన ప్రక్రియలో ఆమె చేయవలసిన ప్రతిదాన్ని అర్థం చేసుకోవడానికి ఆమె వందనతో చాలా సమయం గడుపుతోంది.",
              "or": "ପରିବର୍ତ୍ତନ ପ୍ରକ୍ରିୟାରେ ସେ ଯାହା କରିବାକୁ ପଡିବ ତାହା ବୁଝିବା ପାଇଁ ସେ ବନ୍ଦନାଙ୍କ ସହ ବହୁ ସମୟ ଅତିବାହିତ କରୁଛନ୍ତି |",
              "as": "পৰিৱৰ্তনৰ প্ৰক্ৰিয়াত তাই কৰিব লগা সকলোবোৰ বুজিবলৈ তাই বন্দনাৰ সৈতে যথেষ্ট সময় অতিবাহিত কৰি আছে।",
              "gu": "ટ્રાન્સિશનની પ્રક્રિયામાં તેણીએ શું કરવું જોઈએ તે બધું સમજવા માટે તે વંદના સાથે ઘણો સમય વિતાવી રહી હતી."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_3",
              "hi": "E2_1_3",
              "ka": "E2_1_3",
              "te": "E2_1_3",
              "or": "E2_1_3",
              "as": "E2_1_3",
              "gu": "E2_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Vandana is also a trans woman, so she understands many of the problems that Asmita may face.",
              "hi": "वंदना भी एक ट्रांसवुमन है, इसलिए वह उन समस्याओं को समझती है जिनका सामना अस्मिता को करना पड़ सकता है।",
              "ka": "ವಂದನಾ ಕೂಡ ಟ್ರಾನ್ಸ್ ಮಹಿಳೆ, ಆದ್ದರಿಂದ ಅಸ್ಮಿತಾ ಎದುರಿಸಬಹುದಾದ ಅನೇಕ ಸಮಸ್ಯೆಗಳನ್ನು ಅವಳು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "వందన కూడా ట్రాన్స్ ఉమెన్ కాబట్టి అస్మిత ఎదుర్కొనే అనేక సమస్యలను ఆమె అర్థం చేసుకుంది.",
              "or": "ବନ୍ଦନା ମଧ୍ୟ ଜଣେ ଟ୍ରାନ୍ସ ମହିଳା, ତେଣୁ ସେ ଅସ୍ମିତା ସମ୍ମୁଖୀନ ହେଉଥିବା ଅନେକ ସମସ୍ୟା ବୁଝନ୍ତି |",
              "as": "বন্দনা এগৰাকী ট্ৰেন্স মহিলাও, সেয়েহে তেওঁ অস্মিতাই সন্মুখীন হ'ব পৰা বহুতো সমস্যা বুজি পায়।",
              "gu": "વંદના પણ એક ટ્રાન્સ વુમન છે, તેથી તે અસ્મિતા સામે આવી શકે તેવી ઘણી સમસ્યાઓને સમજે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_4",
              "hi": "E2_1_4",
              "ka": "E2_1_4",
              "te": "E2_1_4",
              "or": "E2_1_4",
              "as": "E2_1_4",
              "gu": "E2_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Vandana",
              "hi": "वंदना",
              "ka": "ವಂದನಾ",
              "te": "వందన",
              "or": "ବନ୍ଦନା",
              "as": "বন্দনা",
              "gu": "વંદના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Asmita, you must remember everyone’s experience with coming out is unique and different. But there are a few things that you must do to make things easier for you and others to get used to your new identity.",
              "hi": "अस्मिता, आपको याद रखना चाहिए कि बाहर आने का हर किसी का अनुभव अनोखा और अलग होता है। लेकिन आपको अपने और दूसरों के लिए अपनी नई पहचान से आसानी से अभ्यस्त होने के लिए कुछ चीज़ें करनी चाहिए।",
              "ka": "ಅಸ್ಮಿತಾ, ಹೊರಬರುವ ಪ್ರತಿಯೊಬ್ಬರ ಅನುಭವವು ಅನನ್ಯ ಮತ್ತು ವಿಭಿನ್ನವಾಗಿದೆ ಎಂದು ನೀವು ನೆನಪಿನಲ್ಲಿಟ್ಟುಕೊಳ್ಳಬೇಕು. ಆದರೆ ನೀವು ಮತ್ತು ಇತರರಿಗೆ ನಿಮ್ಮ ಹೊಸ ಗುರುತನ್ನು ಬಳಸಿಕೊಳ್ಳಲು ವಿಷಯಗಳನ್ನು ಸುಲಭಗೊಳಿಸಲು ನೀವು ಮಾಡಬೇಕಾದ ಕೆಲವು ವಿಷಯಗಳಿವೆ.",
              "te": "అస్మిత, బయటకు వచ్చిన ప్రతి ఒక్కరి అనుభవం ప్రత్యేకమైనది మరియు విభిన్నమైనది అని మీరు గుర్తుంచుకోవాలి. కానీ మీరు మరియు ఇతరులు మీ కొత్త గుర్తింపుకు అలవాటు పడేలా చేయడం కోసం మీరు తప్పనిసరిగా కొన్ని విషయాలు చేయాలి.",
              "or": "ଅସ୍ମିତା, ତୁମେ ମନେ ରଖି ବାହାରକୁ ଆସିବା ସହିତ ସମସ୍ତଙ୍କର ଅନୁଭୂତି ଅନନ୍ୟ ଏବଂ ଅଲଗା | କିନ୍ତୁ କିଛି ଜିନିଷ ଅଛି ଯାହାକି ତୁମ ଏବଂ ଅନ୍ୟମାନଙ୍କ ପାଇଁ ତୁମର ନୂତନ ପରିଚୟରେ ଅଭ୍ୟସ୍ତ ହେବା ପାଇଁ ଜିନିଷଗୁଡ଼ିକୁ ସହଜ କରିବା ପାଇଁ ତୁମକୁ ନିଶ୍ଚୟ କରିବା ଉଚିତ୍‌ |",
              "as": "অস্মিতা, আপুনি মনত ৰাখিব লাগিব যে নতুন পৰিচয়ৰ সৈতে সকলোৰে অভিজ্ঞতা অনন্য আৰু পৃথক । কিন্তু আপোনাৰ আৰু আনৰ বাবে আপোনাৰ নতুন পৰিচয়ৰ সৈতে অভ্যস্ত হ'বলৈ আৰু বস্তুবোৰ সহজ কৰি তুলিবলৈ আপুনি কিছুমান কাম কৰিব লাগিব।",
              "gu": "અસ્મિતા, તારે યાદ રાખવું જોઈએ કે બહાર આવવાનો દરેકનો અનુભવ અનન્ય અને અલગ હોય છે.  પરંતુ એવી કેટલીક બાબતો છે જે તારે અને અન્ય લોકો માટે તારી નવી ઓળખની આદત પડે તે માટે વસ્તુઓને સરળ બનાવવા માટે તારે કરવું જોઈએ."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_5_reveal",
              "hi": "E2_1_5_रिवील ",
              "ka": "E2_1_5_reveal",
              "te": "E2_1_5_రివీల్",
              "or": "E2_1_5_reveal",
              "as": "E2_1_5_reveal",
              "gu": "E2_1_5_પ્રગટ કરવું"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Click to reveal the steps Vandana shares with Asmita.",
              "hi": "वंदना द्वारा अस्मिता के साथ साझा किये गए चरणों को प्रकट करने के लिए क्लिक करें।",
              "ka": "ವಂದನಾ ಅವರು ಅಸ್ಮಿತಾ ಅವರೊಂದಿಗೆ ಹಂಚಿಕೊಂಡಿರುವ ಹಂತಗಳನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.",
              "te": "వందన అస్మితతో పంచుకున్న దశలను రివీల్ చేయడానికి క్లిక్ చేయండి.",
              "or": "ଅସ୍ମିତାଙ୍କ ସହ ବନ୍ଦନା ଅଂଶୀଦାର କରୁଥିବା ପଦକ୍ଷେପଗୁଡ଼ିକୁ ପ୍ରକାଶ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
              "as": "বন্দনাই অস্মিতাৰ সৈতে আলোচনা কৰা পদক্ষেপবোৰ প্ৰকাশ কৰিবলৈ ক্লিক কৰক।",
              "gu": "વંદનાએ અસ્મિતા સાથે જે પગલાં શેર કર્યા છે તે જાણવા માટે ક્લિક કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.beforeReveal",
            "value": {
              "en": "Step 1",
              "hi": "चरण 1",
              "ka": "ಹಂತ 1",
              "te": "దశ 1",
              "or": "Step 1",
              "as": "পদক্ষেপ ১",
              "gu": "પગલું 1"
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.beforeReveal",
            "value": {
              "en": "Step 2",
              "hi": "चरण 2 ",
              "ka": "ಹಂತ 2",
              "te": "దశ 2",
              "or": "Step 2",
              "as": "পদক্ষেপ ২",
              "gu": "પગલું 2"
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.beforeReveal",
            "value": {
              "en": "Step 3",
              "hi": "चरण 3",
              "ka": "ಹಂತ 3",
              "te": "దశ 3",
              "or": "Step 3",
              "as": "পদক্ষেপ ৩",
              "gu": "પગલું 3"
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.beforeReveal",
            "value": {
              "en": "Step 4",
              "hi": "चरण 4",
              "ka": "ಹಂತ 4",
              "te": "దశ 4",
              "or": "Step 4",
              "as": "পদক্ষেপ ৪",
              "gu": "પગલું 4"
            }
          },
          {
            "valuePath": "blocks.0.props.options.0.afterReveal",
            "value": {
              "en": "Inform your family members and close friends about your decision.",
              "hi": "अपने परिवार के सदस्यों और घनिष्ठ  मित्रों को अपने निर्णय के बारे में सूचित करे।",
              "ka": "ನಿಮ್ಮ ನಿರ್ಧಾರದ ಬಗ್ಗೆ ನಿಮ್ಮ ಕುಟುಂಬ ಸದಸ್ಯರು ಮತ್ತು ನಿಕಟ ಸ್ನೇಹಿತರಿಗೆ ತಿಳಿಸಿ.",
              "te": "మీ నిర్ణయం గురించి మీ కుటుంబ సభ్యులు మరియు సన్నిహితులకు తెలియజేయండి.",
              "or": "ତୁମର ନିଷ୍ପତ୍ତି ବିଷୟରେ ତୁମର ପରିବାର ସଦସ୍ୟ ଏବଂ ଘନିଷ୍ଠ ବନ୍ଧୁମାନଙ୍କୁ ଜଣାନ୍ତୁ |",
              "as": "আপোনাৰ সিদ্ধান্তৰ বিষয়ে আপোনাৰ পৰিয়ালৰ সদস্য আৰু ঘনিষ্ঠ বন্ধুবৰ্গক জনাব।",
              "gu": "તમારા નિર્ણય વિશે તમારા પરિવારના સભ્યો અને નજીકના મિત્રોને જાણ કરો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.1.afterReveal",
            "value": {
              "en": "Update your social media so that people are aware of your transition.",
              "hi": "अपने सोशल मीडिया को अपडेट करे जिससे कि लोग आपके ट्रांज़िशन / बदलाव के विषय में जान सकें।",
              "ka": "ನಿಮ್ಮ ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮವನ್ನು ನವೀಕರಿಸಿ ಇದರಿಂದ ಜನರು ನಿಮ್ಮ ಪರಿವರ್ತನೆಯ ಬಗ್ಗೆ ತಿಳಿದಿರುತ್ತಾರೆ.",
              "te": "మీ పరివర్తన గురించి ప్రజలు తెలుసుకునేలా మీ సోషల్ మీడియాను అప్‌డేట్ చేయండి.",
              "or": "ତୁମର ସୋସିଆଲ୍ ମିଡିଆ ଅପଡେଟ୍ କରନ୍ତୁ ଯାହା ଦ୍ୱାରା ଲୋକମାନେ ଆପଣଙ୍କର ପରିବର୍ତ୍ତନ ବିଷୟରେ ଅବଗତ ହେବେ |",
              "as": "আপোনাৰ সামাজিক মাধ্যম উন্নত কৰক যাতে মানুহে আপোনাৰ পৰিৱৰ্তনৰ বিষয়ে গম পায়।",
              "gu": "તમારા સોશિયલ મીડિયાને અપડેટ કરો જેથી કરીને લોકો તમારા ટ્રાન્સિશનથી વાકેફ થાય."
            }
          },
          {
            "valuePath": "blocks.0.props.options.2.afterReveal",
            "value": {
              "en": "Use this as an opportunity to educate others and have some of your answers and resources ready.",
              "hi": "इसे दूसरों को शिक्षित करने के अवसर के रूप में उपयोग करे और अपने कुछ उत्तरों और संसाधनों को तैयार रखे।",
              "ka": "ಇತರರಿಗೆ ಶಿಕ್ಷಣ ನೀಡಲು ಮತ್ತು ನಿಮ್ಮ ಕೆಲವು ಉತ್ತರಗಳು ಮತ್ತು ಸಂಪನ್ಮೂಲಗಳನ್ನು ಸಿದ್ಧಗೊಳಿಸಲು ಇದನ್ನು ಒಂದು ಅವಕಾಶವಾಗಿ ಬಳಸಿ.",
              "te": "ఇతరులకు అవగాహన కల్పించడానికి మరియు మీ సమాధానాలు మరియు వనరులను సిద్ధంగా ఉంచుకోవడానికి దీన్ని అవకాశంగా ఉపయోగించండి.",
              "or": "ଅନ୍ୟମାନଙ୍କୁ ଶିକ୍ଷିତ କରିବା ପାଇଁ ଏହାକୁ ଏକ ସୁଯୋଗ ଭାବରେ ବ୍ୟବହାର କରନ୍ତୁ ଏବଂ ତୁମର କିଛି ଉତ୍ତର ଏବଂ ଉତ୍ସ ପ୍ରସ୍ତୁତ ଅଛି |",
              "as": "ইয়াক আনক শিক্ষিত কৰাৰ সুযোগ হিচাপে ব্যৱহাৰ কৰক আৰু আপোনাৰ কিছুমান উত্তৰ আৰু তথ্য সাজু কৰি ৰাখিব।",
              "gu": "અન્યોને શિક્ષિત કરવાની તક તરીકે આનો ઉપયોગ કરો અને તમારા કેટલાક જવાબો અને સંસાધનો તૈયાર રાખો."
            }
          },
          {
            "valuePath": "blocks.0.props.options.3.afterReveal",
            "value": {
              "en": "It will be exhausting to constantly answer personal questions know where to draw the line.",
              "hi": "व्यक्तिगत प्रश्नों का लगातार जवाब देना थकाऊ होगा, लाइन कहां खींचें यह जानना आवश्यक है।",
              "ka": "ವೈಯಕ್ತಿಕ ಪ್ರಶ್ನೆಗಳಿಗೆ ನಿರಂತರವಾಗಿ ಉತ್ತರಿಸಲು ಆಯಾಸವಾಗುತ್ತದೆ ರೇಖೆಯನ್ನು ಎಲ್ಲಿ ಎಳೆಯಬೇಕು ಎಂದು ತಿಳಿದಿರಿ.",
              "te": "ఎలా అవుతుంది అనే వ్యక్తిగత ప్రశ్నలకు నిరంతరం సమాధానం ఇవ్వడం చాలా కష్టం అవుతుంది.",
              "or": "ବ୍ୟକ୍ତିଗତ ପ୍ରଶ୍ନ ର କ୍ରମାଗତ ଉତ୍ତର ଦେବା କ୍ଳାନ୍ତିକର ହେବ, ଏହା ଜାଣିବା ପାଇଁ କି କେଉଁଠାରେ ରେଖା ଅଙ୍କନ ହେବ |",
              "as": "ব্যক্তিগত প্ৰশ্নৰ একেৰাহে উত্তৰ দিয়াটো ক্লান্তিকৰ হ'ব আৰু ক'ত ৰ'ব লাগিব জানিব লাগিব।",
              "gu": "લાઇન ક્યાં દોરવી તે જાણી રાખો કેમકે સતત વ્યક્તિગત પ્રશ્નોના જવાબ આપવાથી થાકી જવાશે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_6//",
              "hi": "E2_1_6 //",
              "ka": "E2_1_6//",
              "te": "E2_1_6//",
              "or": "E2_1_6//",
              "as": "E2_1_6//",
              "gu": "E2_1_6//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Asmita",
              "hi": "अस्मिता",
              "ka": "ಅಸ್ಮಿತಾ",
              "te": "అస్మిత",
              "or": "ଅସ୍ମିତା",
              "as": "অস্মিতা",
              "gu": "અસ્મિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Vandana",
              "hi": "वंदना",
              "ka": "ವಂದನಾ",
              "te": "వందన",
              "or": "ଭନ୍ଦନା",
              "as": "বন্দনা",
              "gu": "વંદના"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Asmita",
              "hi": "अस्मिता",
              "ka": "ಅಸ್ಮಿತಾ",
              "te": "అస్మిత",
              "or": "ଅସ୍ମିତା",
              "as": "অস্মিতা",
              "gu": "અસ્મિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "Ok, anything else?",
              "hi": "ठीक है इसके अलावा और कुछ है?",
              "ka": "ಸರಿ, ಇನ್ನೇನಾದರೂ?",
              "te": "సరే, ఇంకేమైనా ఉందా?",
              "or": "ଠିକ ଅଛି, ଆଉ କିଛି ଅଛି କି?",
              "as": "ঠিক আছে, আৰু কিবা বাকী আছে নেকি?",
              "gu": "સારું, બીજું કંઈ?"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Yes, the rest are things that gradually come to you. Choose to dress up however you want to, and remember that your name is not Arvind anymore.",
              "hi": "हां, बाकी चीजें धीरे-धीरे आपके पास आती हैं। आप जैसा चाहते हैं वैसे ड्रेस अप करें, और याद रखें कि आपका नाम अब अरविंद नहीं है।",
              "ka": "ಹೌದು, ಉಳಿದವು ಕ್ರಮೇಣ ನಿಮಗೆ ಬರುವ ವಿಷಯಗಳು. ನೀವು ಬಯಸಿದಂತೆ ಉಡುಗೆಯನ್ನು ಆರಿಸಿಕೊಳ್ಳಿ ಮತ್ತು ನಿಮ್ಮ ಹೆಸರು ಅರವಿಂದ್ ಅಲ್ಲ ಎಂಬುದನ್ನು ನೆನಪಿಡಿ.",
              "te": "అవును, మిగిలినవి క్రమంగా మీకు తెలుస్తాయి. మీకు నచ్చిన విధంగా దుస్తులు ధరించండి మరియు మీ పేరు అరవింద్ కాదని గుర్తుంచుకోండి.",
              "or": "ହଁ, ବାକି ସବୁ ଜିନିଷ ଯାହା ଧୀରେ ଧୀରେ ଆପଣଙ୍କ ପାଖକୁ ଆସେ | ଆପଣ ପରିଧାନ କରିବାକୁ ଯାହା ଚାହୁଁଛନ୍ତି ବାଛନ୍ତୁ, ଏବଂ ମନେରଖନ୍ତୁ ଯେ ଆପଣଙ୍କର ନାମ ଆଉ ଅରବିନ୍ଦ ନୁହେଁ |",
              "as": "হয়, বাকীবোৰ হৈছে এনে বস্তু যিবোৰ লাহে লাহে আপোনাৰ ওচৰলৈ আহিব। আপুনি যিধৰণৰ পোছাক পিন্ধিবলৈ বিচাৰে বাছনি কৰক, আৰু মনত ৰাখিব যে আপোনাৰ নাম এতিয়া অৰবিন্দ নহয়।",
              "gu": "હા, બાકી ધીમે ધીમે સમજાય જશે.  તું ઇચ્છે તેમ પહેરવાનું પસંદ કર અને યાદ રાખ કે તારું નામ હવે અરવિંદ નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "(Asmita smiles) That won’t be difficult at all.",
              "hi": "(अस्मिता मुस्कुराते हुए) वो बिल्कुल मुश्किल नहीं होगा।",
              "ka": "(ಅಸ್ಮಿತಾ ಮುಗುಳ್ನಕ್ಕು) ಅದು ಕಷ್ಟವೇನೂ ಆಗುವುದಿಲ್ಲ.",
              "te": "(అస్మిత నవ్వుతూ) అది అస్సలు కష్టం కాదు.",
              "or": "(ଅସ୍ମିତା ହସନ୍ତି) ତାହା ଆଦୌ କଷ୍ଟସାଧ୍ୟ ହେବ ନାହିଁ",
              "as": "(অস্মিতাই হাঁহিছে) সেইটো একেবাৰে কঠিন নহ'ব।",
              "gu": "(અસ્મિતા સ્મિત કરે છે) તે બિલકુલ મુશ્કેલ નહીં હોય."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_1_7////",
              "hi": "E2_1_7 /////",
              "ka": "E2_1_7////",
              "te": "E2_1_7////",
              "or": "E2_1_7////",
              "as": "E2_1_7////",
              "gu": "E2_1_7////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "Do you also plan to take hormone therapy?",
              "hi": "क्या आप हार्मोन थेरेपी लेने की भी योजना बना रही हैं?",
              "ka": "ನೀವು ಹಾರ್ಮೋನ್ ಚಿಕಿತ್ಸೆಯನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಯೋಜಿಸುತ್ತಿದ್ದೀರಾ?",
              "te": "మీరు హార్మోన్ థెరపీ కూడా తీసుకోవాలని ప్లాన్ చేస్తున్నారా?",
              "or": "ଆପଣ ହରମୋନ୍ ଥେରାପି ନେବାକୁ ମଧ୍ୟ ଯୋଜନା କରୁଛ କି ?",
              "as": "আপুনি হৰমন থেৰাপী গ্ৰহণ কৰাৰ পৰিকল্পনা কৰিছে নেকি?",
              "gu": "શું તું પણ હોર્મોન થેરાપી લેવાનું વિચારી રહી છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, I want to understand more about that. How did you cope with the transition?",
              "hi": "हां, मैं इसके बारे में और समझना चाहती हूं। आपने ट्रांज़िशन/ बदलाव से कैसे सामना किया?",
              "ka": "ಹೌದು, ನಾನು ಅದರ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಬಯಸುತ್ತೇನೆ. ನೀವು ಪರಿವರ್ತನೆಯನ್ನು ಹೇಗೆ ನಿಭಾಯಿಸಿದ್ದೀರಿ?",
              "te": "అవును, నేను దాని గురించి మరింత అర్థం చేసుకోవాలనుకుంటున్నాను. మీరు పరివర్తనను ఎలా ఎదుర్కొన్నారు?",
              "or": "ହଁ, ମୁଁ ସେ ବିଷୟରେ ଅଧିକ ବୁଝିବାକୁ ଚାହେଁ | ଆପଣ ପରିବର୍ତ୍ତନକୁ କିପରି ସାମ୍ନା କଲେ?",
              "as": "হয়, মই সেই বিষয়ে অধিক বুজিব বিচাৰো। আপুনি পৰিৱৰ্তনৰ সৈতে কেনেদৰে মোকাবিলা কৰিছিল?",
              "gu": "હા, હું તેના વિશે વધુ સમજવા માંગુ છું.  તે ટ્રાન્સિશનનો કેવી રીતે સામનો કર્યો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "It's not easy. You'll go through both physical and mental changes.\nThe endocrinologist handled the medical aspects but to keep my mental health in check, I made sure to be connected with a mental health specialist and a good support group. Having a routine apart from work also helped me cope with the changes.",
              "hi": "यह सहज नहीं है। आपको शारीरिक और मानसिक दोनों परिवर्तनों से गुज़रना होगा। एंडोक्राइनोलॉजिस्ट ने चिकित्सा पहलुओं को संभाला लेकिन अपने मानसिक स्वास्थ्य का ध्यान रखने के लिए, मैंने एक मानसिक स्वास्थ्य विशेषज्ञ और एक अच्छे समर्थन समूह से जुड़ना सुनिश्चित किया। काम के अलावा नियमित दिनचर्या से मुझे परिवर्तनों से निपटने में सहायता मिली।",
              "ka": "\"ಇದು ಸುಲಭವಲ್ಲ, ನೀವು ದೈಹಿಕ ಮತ್ತು ಮಾನಸಿಕ ಬದಲಾವಣೆಗಳ ಮೂಲಕ ಹೋಗುತ್ತೀರಿ.\n ಅಂತಃಸ್ರಾವಶಾಸ್ತ್ರಜ್ಞರು ವೈದ್ಯಕೀಯ ಅಂಶಗಳನ್ನು ನಿರ್ವಹಿಸಿದ್ದಾರೆ ಆದರೆ ನನ್ನ ಮಾನಸಿಕ ಆರೋಗ್ಯವನ್ನು ನಿಯಂತ್ರಣದಲ್ಲಿಡಲು, ನಾನು ಮಾನಸಿಕ ಆರೋಗ್ಯ ತಜ್ಞರು ಮತ್ತು ಉತ್ತಮ ಬೆಂಬಲ ಗುಂಪಿನೊಂದಿಗೆ ಸಂಪರ್ಕ ಹೊಂದಲು ಖಚಿತಪಡಿಸಿಕೊಂಡಿದ್ದೇನೆ. ಕೆಲಸದ ಹೊರತಾಗಿ ದಿನಚರಿಯನ್ನು ಹೊಂದಿರುವುದು ಬದಲಾವಣೆಗಳನ್ನು ನಿಭಾಯಿಸಲು ನನಗೆ ಸಹಾಯ ಮಾಡಿತು.",
              "te": "ఇది అంత సులభం కాదు, మీరు శారీరక మరియు మానసిక మార్పులను ఎదుర్కొంటారు.\n ఎండోక్రైనాలజిస్ట్ వైద్యపరమైన అంశాలను హ్యాండిల్ చేస్తాడు కానీ నా మానసిక ఆరోగ్యాన్ని అదుపులో ఉంచుకోవడానికి, నేను మానసిక ఆరోగ్య నిపుణుడు మరియు మంచి సపోర్ట్ గ్రూప్‌తో కనెక్ట్ అయ్యేలా చూసుకున్నాను. పని కాకుండా వేరే రొటీన్ చేయడం కూడా మార్పులను ఎదుర్కోవడంలో నాకు సహాయపడింది.",
              "or": "\"ଏହା ସହଜ ନୁହେଁ। ଆପଣ ଉଭୟ ଶାରୀରିକ ଏବଂ ମାନସିକ ପରିବର୍ତ୍ତନ ମଧ୍ୟ ଦେଇ ଗତି କରିବେ।\n ଏଣ୍ଡୋକ୍ରାଇନୋଲୋଜିଷ୍ଟ ଡାକ୍ତରୀ ଦିଗଗୁଡିକ ପରିଚାଳନା କରିଥିଲେ କିନ୍ତୁ ମୋର ମାନସିକ ସ୍ୱାସ୍ଥ୍ୟକୁ ନିୟନ୍ତ୍ରଣରେ ରଖିବା ପାଇଁ ମୁଁ ଏକ ମାନସିକ ସ୍ୱାସ୍ଥ୍ୟ ବିଶେଷଜ୍ଞ ଏବଂ ଏକ ଭଲ ସହାୟକ ଗୋଷ୍ଠୀ ସହିତ ସଂଯୁକ୍ତ ହେବା ନିଶ୍ଚିତ କରିଥିଲି | କାର୍ଯ୍ୟ ବ୍ୟତୀତ ଏକ ନିତ୍ୟ ବ୍ୟବହାର୍ଯ୍ୟ ରହିବା ମଧ୍ୟ ମୋତେ ପରିବର୍ତ୍ତନଗୁଡ଼ିକର ମୁକାବିଲା କରିବାରେ ସାହାଯ୍ୟ କଲା |",
              "as": "এইটো সহজ নহয়। আপুনি শাৰীৰিক আৰু মানসিক দুয়োধৰণৰ পৰিৱৰ্তনৰ মাজেৰে যাব লাগিব।\n \n এণ্ডোক্ৰিনোলজিষ্টজনে চিকিৎসাৰ দিশবোৰ চম্ভালিছিল কিন্তু মোৰ মানসিক স্বাস্থ্য নিয়ন্ত্ৰণত ৰাখিবলৈ, মই এজন মানসিক স্বাস্থ্য বিশেষজ্ঞ আৰু এটা ভাল সমৰ্থন গোটৰ সৈতে সম্পৰ্কিত হোৱাটো নিশ্চিত কৰিছিলো। কামৰ বাহিৰেও এটা ৰুটিন থকাটোৱে মোক পৰিৱৰ্তনবোৰৰ সৈতে মোকাবিলা কৰাত সহায় কৰিছিল।",
              "gu": "તે સરળ નથી.  તું શારીરિક અને માનસિક બંને ફેરફારોમાંથી પસાર થઈશ. એન્ડોક્રિનોલોજિસ્ટ તબીબી પાસાઓ સંભાળે છે પરંતુ મારા માનસિક સ્વાસ્થ્યને નિયંત્રણમાં રાખવા માટે, મેં માનસિક સ્વાસ્થ્ય નિષ્ણાત અને સારા સપોર્ટ ગ્રુપ સાથે જોડાયેલ હોવાનું સુનિશ્ચિત કર્યું.  કામ સિવાયની દિનચર્યા રાખવાથી પણ મને ફેરફારોનો સામનો કરવામાં મદદ મળી."
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_2_1",
              "hi": "E2_2_1",
              "ka": "E2_2_1",
              "te": "E2_2_1",
              "or": "E2_2_1",
              "as": "E2_2_1",
              "gu": "E2_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita feels confident and nervous simultaneously, but she is sure that she wants to live as a woman from her 25th Birthday onwards.",
              "hi": "अस्मिता एक साथ आत्मविश्वास और घबराहट का अनुभव करती है, लेकिन वह निश्चिय कर चुकी है कि वह 25 वें जन्मदिन से एक महिला के रूप में रहना चाहती है।",
              "ka": "ಅಸ್ಮಿತಾ ಏಕಕಾಲದಲ್ಲಿ ಆತ್ಮವಿಶ್ವಾಸ ಮತ್ತು ಉದ್ವೇಗವನ್ನು ಅನುಭವಿಸುತ್ತಾಳೆ, ಆದರೆ ಅವಳು ತನ್ನ 25 ನೇ ಜನ್ಮದಿನದ ನಂತರ ಮಹಿಳೆಯಾಗಿ ಬದುಕಲು ಬಯಸುತ್ತಾಳೆ ಎಂದು ಖಚಿತವಾಗಿದೆ.",
              "te": "అస్మిత ఒకేసారి ఆత్మవిశ్వాసంతో ఉంటుంది మరియు భయాందోళనలకు గురవుతుంది, కానీ ఆమె తన 25వ పుట్టినరోజు నుండి స్త్రీగా జీవించాలనుకుంటున్నట్లు ఆమె నిర్ణయించుకుంది.",
              "or": "ଅସ୍ମିତା ଏକାସାଙ୍ଗରେ ଆତ୍ମବିଶ୍ୱାସୀ ଏବଂ ଡରୁଆ ଅନୁଭବ କରନ୍ତି, କିନ୍ତୁ ସେ ନିଶ୍ଚିତ ଯେ ସେ 25 ତମ ଜନ୍ମଦିନରୁ ଜଣେ ମହିଳା ଭାବରେ ବଞ୍ଚିବାକୁ ଚାହାଁନ୍ତି |",
              "as": "অস্মিতাই একেসময়তে আত্মবিশ্বাসী আৰু উদ্বিগ্ন অনুভৱ কৰে, কিন্তু তেওঁ নিশ্চিত যে তেওঁ তেওঁৰ ১৫ তম জন্মদিনৰ পৰা এগৰাকী মহিলা হিচাপে জীয়াই থাকিব বিচাৰে।",
              "gu": "અસ્મિતા એક સાથે આત્મવિશ્વાસ અને નર્વસનેસ અનુભવે છે, પરંતુ તેણીને ખાતરી છે કે તેણી તેના 25મા જન્મદિવસને એક મહિલા તરીકે જીવવા માંગે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_2_2",
              "hi": "E2_2_2",
              "ka": "E2_2_2",
              "te": "E2_2_2",
              "or": "E2_2_2",
              "as": "E2_2_2",
              "gu": "E2_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She begins her hormone therapy by reaching out to an Endocrinologist suggested by Vandana. She explains to Asmita what the effects of hormone therapy are and how to cope with the changes.",
              "hi": "वह वंदना द्वारा सुझाए गए एंडोक्राइनोलॉजिस्ट के पास पहुंचकर हार्मोन थेरेपी शुरू करती है। वह अस्मिता को बताती है कि हार्मोन थेरेपी के प्रभाव क्या हैं और परिवर्तनों का सामना कैसे करें।",
              "ka": "ವಂದನಾ ಸೂಚಿಸಿದ ಅಂತಃಸ್ರಾವಶಾಸ್ತ್ರಜ್ಞರನ್ನು ತಲುಪುವ ಮೂಲಕ ಅವಳು ತನ್ನ ಹಾರ್ಮೋನ್ ಚಿಕಿತ್ಸೆಯನ್ನು ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ. ಹಾರ್ಮೋನ್ ಥೆರಪಿಯ ಪರಿಣಾಮಗಳೇನು ಮತ್ತು ಬದಲಾವಣೆಗಳನ್ನು ಹೇಗೆ ನಿಭಾಯಿಸಬೇಕು ಎಂದು ಅವರು ಅಸ್ಮಿತಾಗೆ ವಿವರಿಸುತ್ತಾರೆ.",
              "te": "వందన సూచించిన ఎండోక్రైనాలజిస్ట్‌ని సంప్రదించడం ద్వారా ఆమె తన హార్మోన్ థెరపీని ప్రారంభించింది. ఆమె అస్మితకు హార్మోన్ థెరపీ యొక్క ప్రభావాలు ఏమిటో మరియు మార్పులను ఎలా ఎదుర్కోవాలో వివరిస్తుంది.",
              "or": "ସେ ବନ୍ଦନାଙ୍କ ପରାମର୍ଶ ଅନୁଯାୟୀ ଏକ ଏଣ୍ଡୋକ୍ରାଇନୋଲୋଜିଷ୍ଟଙ୍କ ନିକଟରେ ପହଞ୍ଚି ନିଜର ହରମୋନ୍ ଥେରାପି ଆରମ୍ଭ କରନ୍ତି | ସେ ଅସ୍ମିତାଙ୍କୁ ହରମୋନ୍ ଥେରାପିର ପ୍ରଭାବ କ’ଣ ଏବଂ ପରିବର୍ତ୍ତନଗୁଡ଼ିକର କିପରି ମୁକାବିଲା କରିବେ ବୁଝାନ୍ତି |",
              "as": "তাই বন্দনাৰ পৰামৰ্শ দিয়া এণ্ডোক্ৰিনোলজিষ্টৰ ওচৰলৈ গৈ তাইৰ হৰমন থেৰাপী আৰম্ভ কৰে। তেওঁ অস্মিতাক বুজাই দিয়ে যে হৰমন থেৰাপীৰ প্ৰভাৱ কি আৰু পৰিৱৰ্তনবোৰৰ সৈতে কেনেদৰে মোকাবিলা কৰিব লাগিব।",
              "gu": "તે વંદના દ્વારા સૂચવવામાં આવેલા એન્ડોક્રિનોલોજિસ્ટનો સંપર્ક કરીને પોતાનો હોર્મોન ઉપચાર શરૂ કરે છે.  તે અસ્મિતાને સમજાવે છે કે હોર્મોન ઉપચારની અસરો શું છે અને ફેરફારોનો સામનો કેવી રીતે કરવો."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_2_3",
              "hi": "E2_2_3",
              "ka": "E2_2_3",
              "te": "E2_2_3",
              "or": "E2_2_3",
              "as": "E2_2_3",
              "gu": "E2_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Finally, Asmita feels ready to approach her family.",
              "hi": "अंत में, अस्मिता अपने परिवार से संपर्क करने के लिए तैयार  हो जाती है।",
              "ka": "ಅಂತಿಮವಾಗಿ, ಅಸ್ಮಿತಾ ತನ್ನ ಕುಟುಂಬವನ್ನು ಸಂಪರ್ಕಿಸಲು ಸಿದ್ಧಳಾಗಿದ್ದಾಳೆ.",
              "te": "చివరగా, అస్మిత తన కుటుంబాన్ని సంప్రదించడానికి సిద్ధంగా ఉంది.",
              "or": "ଶେଷରେ, ଅସ୍ମିତା ତାଙ୍କ ପରିବାର ନିକଟରେ ସମ୍ପର୍କ ରଖିବା ପାଇଁ ପ୍ରସ୍ତୁତ ବୋଲି ଅନୁଭବ କରନ୍ତି |",
              "as": "অৱশেষত, অস্মিতাই তাইৰ পৰিয়ালৰ ওচৰলৈ যাবলৈ সাজু অনুভৱ কৰে।",
              "gu": "અંતે, અસ્મિતા પોતાના પરિવારનો સંપર્ક કરવા તૈયાર અનુભવે છે."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_3_1",
              "hi": "E2_3_1",
              "ka": "E2_3_1",
              "te": "E2_3_1",
              "or": "E2_3_1",
              "as": "E2_3_1",
              "gu": "E2_3_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Roshni and Asmita go to their parents and Pranav to talk about Asmita's transition.",
              "hi": "रोशनी और अस्मिता, अस्मिता के ट्रांज़िशन/ बदलाव के बारे में बात करने के लिए अपने माता-पिता और प्रणव के पास जाते हैं।",
              "ka": "ರೋಶ್ನಿ ಮತ್ತು ಅಸ್ಮಿತಾ ಅವರ ಪೋಷಕರು ಮತ್ತು ಪ್ರಣವ್ ಬಳಿ ಅಸ್ಮಿತಾ ಪರಿವರ್ತನೆಯ ಬಗ್ಗೆ ಮಾತನಾಡಲು ಹೋಗುತ್ತಾರೆ.",
              "te": "రోష్ని మరియు అస్మిత వారి తల్లిదండ్రులు మరియు ప్రణవ్ వద్దకు వెళ్లి అస్మిత పరివర్తన గురించి మాట్లాడతారు.",
              "or": "ରୋଶନି ଏବଂ ଅସମିତା ସେମାନଙ୍କ ପିତାମାତା ଏବଂ ପ୍ରଣବଙ୍କ ନିକଟକୁ ଯାଇ ଅସ୍ମିତାଙ୍କ ପରିବର୍ତ୍ତନ ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି |",
              "as": "ৰোশনি আৰু অস্মিতাই তেওঁলোকৰ মাক-দেউতাক আৰু প্ৰণৱৰ ওচৰলৈ অস্মিতাৰ পৰিৱৰ্তনৰ বিষয়ে কথা পাতিবলৈ যায়।",
              "gu": "રોશની અને અસ્મિતા તેમના માતાપિતા અને પ્રણવ પાસે અસ્મિતાના ટ્રાન્સિશન વિશે વાત કરવા જાય છે."
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_3_2",
              "hi": "E2_3_2",
              "ka": "E2_3_2",
              "te": "E2_3_2",
              "or": "E2_3_2",
              "as": "E2_3_2",
              "gu": "E2_3_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They are at a loss for words. Her father is worried about what people might say, while her mother is worried about her future, especially because she does not know what it means for Asmita to live as a trans person.",
              "hi": "उसके पास शब्द नहीं हैं। उसके पिता इस बारे में चिंतित हैं कि लोग क्या कहेंगे, जबकि उसकी मां उसके भविष्य के बारे में चिंतित है, खासकर क्योंकि वह नहीं जानती कि अस्मिता के लिए ट्रांस व्यक्ति के रूप में रहना कैसा रहेगा।",
              "ka": "ಅವರು ಮಾತಿಗೆ ಸೋತಿದ್ದಾರೆ. ಅಸ್ಮಿತಾ ಟ್ರಾನ್ಸ್ ವ್ಯಕ್ತಿಯಾಗಿ ಬದುಕುವುದರ ಅರ್ಥವೇನೆಂದು ತಿಳಿದಿಲ್ಲದ ಕಾರಣ, ಆಕೆಯ ತಾಯಿ ತನ್ನ ಭವಿಷ್ಯದ ಬಗ್ಗೆ ಚಿಂತಿತರಾಗಿರುವಾಗ ಆಕೆಯ ತಂದೆ ಜನರು ಏನು ಹೇಳಬಹುದು ಎಂದು ಚಿಂತಿತರಾಗಿದ್ದಾರೆ.",
              "te": "వారికి మాటలు రావు. అస్మిత ట్రాన్స్ పర్సన్‌గా జీవించడం అంటే ఎలా ఉంటుందో తెలియక ఆమె తల్లి తన భవిష్యత్తు గురించి ఆందోళన చెందుతుండగా, ఆమె తండ్రి అందరూ ఏమి అంటారో అని ఆందోళన చెందుతున్నారు.",
              "or": "ସେମାନେ ଶବ୍ଦ ପାଇଁ କ୍ଷତିଗ୍ରସ୍ତ | ତାଙ୍କ ପିତା ଏହି କଥା ପାଇଁ ଚିନ୍ତିତ କି ଲୋକମାନେ କଣ କହିବେ, ଯେତେବେଳେ ତାଙ୍କ ମା ତାଙ୍କ ଭବିଷ୍ୟତ ପାଇଁ ଚିନ୍ତିତ ଅଛନ୍ତି, ବିଶେଷ କରି ଅସ୍ମିତାଙ୍କ ପାଇଁ ଜଣେ ଟ୍ରାନ୍ସ ବ୍ୟକ୍ତି ଭାବରେ ବଞ୍ଚିବାର ଅର୍ଥ ସେ ଜାଣି ନାହାଁନ୍ତି |",
              "as": "তেওঁলোকে কি কব ভাবি পোৱা নাই। তাইৰ দেউতাকে মানুহে কি ক'ব পাৰে তাক লৈ চিন্তিত, আনহাতে তাইৰ মাক তাইৰ ভৱিষ্যতক লৈ চিন্তিত, বিশেষকৈ তেওঁ নাজানে যে অস্মিতাৰ বাবে এজন ট্ৰেন্স ব্যক্তি হিচাপে জীয়াই থকাৰ অৰ্থ কি।",
              "gu": "તેઓ પાસે શબ્દ નથી હોતાં.  તેના પિતા ચિંતિત હતા કે લોકો શું કહેશે, જ્યારે તેની માતા તેના ભવિષ્ય વિશે ચિંતિત હતી, ખાસ કરીને કારણ કે તે જાણતી નથી કે અસ્મિતા માટે ટ્રાન્સ વ્યક્તિ તરીકે જીવવાનો અર્થ શું છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_3_3",
              "hi": "E2_3_3",
              "ka": "E2_3_3",
              "te": "E2_3_3",
              "or": "E2_3_3",
              "as": "E2_3_3",
              "gu": "E2_3_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is worried about this reaction, and she doesn’t know how she will handle her parents and their concerns. Nobody talks in the house for two days.",
              "hi": "अस्मिता इस प्रतिक्रिया से चिंतित है, और वह नहीं जानती कि वह अपने माता-पिता और उनकी चिंताओं को कैसे संभालेगी। दो दिनों के लिए घर में कोई बात नहीं करता है।",
              "ka": "ಈ ಪ್ರತಿಕ್ರಿಯೆಯ ಬಗ್ಗೆ ಅಸ್ಮಿತಾ ಚಿಂತಿತರಾಗಿದ್ದಾರೆ ಮತ್ತು ಆಕೆಯ ಪೋಷಕರು ಮತ್ತು ಅವರ ಕಾಳಜಿಯನ್ನು ಹೇಗೆ ನಿಭಾಯಿಸುತ್ತಾರೆ ಎಂದು ತಿಳಿದಿಲ್ಲ. ಎರಡು ದಿನ ಮನೆಯಲ್ಲಿ ಯಾರೂ ಮಾತನಾಡುವುದಿಲ್ಲ.",
              "te": "అస్మిత ఈ రియాక్షన్ గురించి ఆందోళన చెందుతోంది మరియు ఆమె తన తల్లిదండ్రులను మరియు వారి ఆందోళనలను ఎలా హ్యాండిల్ చేయాలో ఆమెకు తెలియలేదు. రెండు రోజులు ఇంట్లో ఎవరూ మాట్లాడరు.",
              "or": "ଅସ୍ମିତା ଏହି ପ୍ରତିକ୍ରିୟା ପାଇଁ ଚିନ୍ତିତ, ଏବଂ ସେ ଜାଣନ୍ତି ନାହିଁ ଯେ ସେ କିପରି ତାଙ୍କ ପିତାମାତା ଏବଂ ସେମାନଙ୍କର ଚିନ୍ତାଧାରାକୁ ସମ୍ଭାଳିବେ | ଦୁଇ ଦିନ ଧରି ଘରେ କେହି କଥାବାର୍ତ୍ତା କରନ୍ତି ନାହିଁ |",
              "as": "অস্মিতা এই প্ৰতিক্ৰিয়াৰ বিষয়ে চিন্তিত, আৰু তাই নাজানে যে তাই তাইৰ মাক-দেউতাক আৰু তেওঁলোকৰ উদ্বেগবোৰ কেনেদৰে নিয়ন্ত্ৰণ কৰিব। ঘৰত দুদিন ধৰি কোনেও কথা নকয়।",
              "gu": "અસ્મિતા આ પ્રતિક્રિયાથી ચિંતિત થઈ ગઈ અને તેણીને ખબર ન હતી કે તે તેના માતાપિતા અને તેમની ચિંતાઓને કેવી રીતે સંભાળશે.  બે દિવસ ઘરમાં કોઈ વાત કરતું નથી."
            }
          }
        ],
        "page14": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_4_2",
              "hi": "E2_4_2",
              "ka": "E2_4_2",
              "te": "E2_4_2",
              "or": "E2_4_2",
              "as": "E2_4_2",
              "gu": "E2_4_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After two days, while everyone is sitting in the hall, Asmita is making tea in the kitchen. Pranav comes over and talks to Asmita.",
              "hi": "दो दिनों के बाद, जब हर कोई हॉल में बैठा है, अस्मिता रसोई में चाय बना रही है। प्रणव आता है और अस्मिता से बात करता है।",
              "ka": "ಎರಡು ದಿನಗಳ ನಂತರ ಎಲ್ಲರೂ ಹಾಲ್ ನಲ್ಲಿ ಕುಳಿತಿರುವಾಗ ಅಸ್ಮಿತಾ ಅಡುಗೆ ಮನೆಯಲ್ಲಿ ಟೀ ಮಾಡುತ್ತಿದ್ದಳು. ಪ್ರಣವ್ ಬಂದು ಅಸ್ಮಿತಾಳೊಂದಿಗೆ ಮಾತನಾಡುತ್ತಾನೆ.",
              "te": "రెండు రోజుల తర్వాత అందరూ హాల్లో కూర్చుని ఉండగా అస్మిత కిచెన్‌లో టీ చేస్తోంది. ప్రణవ్ దగ్గరకు వచ్చి అస్మితతో మాట్లాడుతున్నాడు.",
              "or": "ଦୁଇ ଦିନ ପରେ ସମସ୍ତେ ହଲରେ ବସିଥିବାବେଳେ ଅସ୍ମିତା ରୋଷେଇ ଘରେ ଚା ତିଆରି କରୁଛନ୍ତି | ପ୍ରଣବ ଆସି ଅସ୍ମିତାଙ୍କ ସହ କଥା ହୁଅନ୍ତି |",
              "as": "দুদিনৰ পিছত, সকলোৱে হলত বহি থাকোঁতে, অস্মিতাই পাকঘৰত চাহ বনাই আছে। প্ৰণৱ আহি অস্মিতাৰ সৈতে কথা পাতিলে।",
              "gu": "બે દિવસ પછી બધા હોલમાં બેઠા હતા ત્યારે અસ્મિતા રસોડામાં ચા બનાવી રહી હતી.  પ્રણવ આવ્યો અને અસ્મિતા સાથે વાત કરવા લાગ્યો."
            }
          }
        ],
        "page15": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_4_3//",
              "hi": "E2_4_3 //",
              "ka": "E2_4_3//",
              "te": "E2_4_3//",
              "or": "E2_4_3//",
              "as": "E2_4_3//",
              "gu": "E2_4_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Pranav",
              "hi": "प्रणव",
              "ka": "ಪ್ರಣವ್",
              "te": "ప్రణవ్",
              "or": "ପ୍ରଣବ",
              "as": "প্ৰণৱ",
              "gu": "પ્રણવ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "I have found it very hard to accept you as Arvind. I am sorry about how I have behaved with you, and I will try my best to accept and support you. I’m happy that you made this transition.",
              "hi": "मुझे तुमको अरविंद के रूप में स्वीकार करना बहुत मुश्किल रहा है। मुझे खेद है कि मैंने तुम्हारे साथ ऐसे व्यवहार किया है, और मैं तुमको स्वीकार करने और समर्थन करने की पूरी कोशिश करूंगा। मुझे खुशी है कि तुमने यह ट्रांज़िशन/ बदलाव कराया है।",
              "ka": "ನಿನ್ನನ್ನು ಅರವಿಂದ ಎಂದು ಒಪ್ಪಿಕೊಳ್ಳಲು ನನಗೆ ತುಂಬಾ ಕಷ್ಟವಾಯಿತು. ನಾನು ನಿಮ್ಮೊಂದಿಗೆ ಹೇಗೆ ವರ್ತಿಸಿದೆ ಎಂಬುದರ ಬಗ್ಗೆ ನನಗೆ ವಿಷಾದವಿದೆ ಮತ್ತು ನಿನ್ನನ್ನು ಸ್ವೀಕರಿಸಲು ಮತ್ತು ಬೆಂಬಲಿಸಲು ನಾನು ನನ್ನ ಅತ್ಯುತ್ತಮ ಪ್ರಯತ್ನ ಮಾಡುತ್ತೇನೆ. ನೀನು ಈ ಪರಿವರ್ತನೆ ಮಾಡಿದ್ದೀಯ ಎಂದು ನನಗೆ ಸಂತೋಷವಾಗಿದೆ.",
              "te": "నిన్ను అరవింద్‌గా అంగీకరించడం నాకు చాలా కష్టంగా అనిపించింది. నేను నీతో అలా ప్రవర్తించినందుకు చింతిస్తున్నాను మరియు నిన్ను అంగీకరించడానికి మరియు మద్దతు ఇవ్వడానికి నేను నా వంతు ప్రయత్నం చేస్తాను. నువ్వు ఈ పరివర్తన చెందినందుకు నేను సంతోషిస్తున్నాను.",
              "or": "ତୁମକୁ ଅରବିନ୍ଦ ଭାବରେ ଗ୍ରହଣ କରିବା ମୋତେ ବହୁତ କଷ୍ଟକର | ମୁଁ ତୁମ ସହିତ କିପରି ବ୍ୟବହାର କରିଛି ସେଥିପାଇଁ ମୁଁ ଦୁଃଖିତ, ଏବଂ ତୁମକୁ ଗ୍ରହଣ ଏବଂ ସମର୍ଥନ କରିବାକୁ ମୁଁ ଚେଷ୍ଟା କରିବି | ମୁଁ ଖୁସି ଯେ ତୁମେ ଏହି ପରିବର୍ତ୍ତନ କରିଛ |",
              "as": "মই তোমাক অৰবিন্দ হিচাপে মানি লবলৈ বহুত টান পাইছো। মই তোমাৰ সৈতে যেনেধৰণৰ আচৰণ কৰিছো তাৰ বাবে মই দুঃখিত, আৰু মই তোমাক অস্মিতা হিচাপে মানি লবলৈ যথাসাধ্য চেষ্টা কৰিম। মই সুখী যে তুমি এই পৰিৱৰ্তনটো কৰিছা।",
              "gu": "તને અરવિંદ તરીકે સ્વીકારવું મને બહુ મુશ્કેલ લાગતું હતું.  મેં તારી સાથે જેવું વર્તન કર્યું છે તે બદલ હું દિલગીર છું, અને હું તને સ્વીકારવા અને ટેકો આપવા માટે મારા શ્રેષ્ઠ પ્રયાસ કરીશ.  હું ખુશ છું કે તે આ ટ્રાન્સિશન કર્યું છે."
            }
          }
        ],
        "page21": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_5_1////",
              "hi": "E2_5_1 //////",
              "ka": "E2_5_1////",
              "te": "E2_5_1////",
              "or": "E2_5_1////",
              "as": "E2_5_1////",
              "gu": "E2_5_1////"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Her parents and Roshni overhear the conversation and walk into the kitchen. They smile at Asmita and hug her.",
              "hi": "उसके माता-पिता और रोशनी वार्तालाप को सुनते हैं और रसोई में प्रवेश करते हैं। वे अस्मिता के लिए मुस्कराते हैं और उसे गले लगाते हैं।",
              "ka": "ಆಕೆಯ ಪೋಷಕರು ಮತ್ತು ರೋಶ್ನಿ ಸಂಭಾಷಣೆಯನ್ನು ಕೇಳಿದರು ಮತ್ತು ಅಡುಗೆಮನೆಗೆ ನಡೆದರು. ಅವರು ಅಸ್ಮಿತಾ ಅವರನ್ನು ನೋಡಿ ಮುಗುಳ್ನಕ್ಕು ಅವಳನ್ನು ತಬ್ಬಿಕೊಂಡರು.",
              "te": "ఆమె తల్లిదండ్రులు మరియు రోష్ని వారి సంభాషణ విని వంటగదిలోకి వెళ్తారు. వాళ్ళు అస్మితను చూసి నవ్వి కౌగిలించుకున్నారు.",
              "or": "ତାଙ୍କ ପିତାମାତା ଏବଂ ରୋଶନି ବାର୍ତ୍ତାଳାପ ଶୁଣି ରୋଷେଇ ଘରକୁ ଯାଆନ୍ତି | ସେମାନେ ଅସ୍ମିତାଙ୍କ ଉପରେ ହସନ୍ତି ଏବଂ ତାଙ୍କୁ ଆଲିଙ୍ଗନ କରନ୍ତି |",
              "as": "তাইৰ মাক দেউতাক আৰু ৰোশনিয়ে কথাখিনি শুনি পাকঘৰলৈ আহিছিল। তেওঁলোকে অস্মিতাৰ ফালে চাই হাঁহিছিল আৰু তাইক সাৱটি ধৰিছিল।",
              "gu": "તેણીનાં માતા-પિતા અને રોશની વાતચીત સાંભળીને રસોડામાં જાય છે.  તેઓ અસ્મિતા તરફ સ્મિત કરે છે અને તેણીને ગળે લગાવે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "We are proud of you, Asmita. I wasn’t sure how to respond, mostly because I am concerned for you. I don’t want you to be hurt, that’s all.",
              "hi": "हमें तुम पर गर्व है अस्मिता। मुझे यकीन नहीं था कि कैसे प्रतिक्रिया दूं, ज्यादातर इसलिए कि मैं तुम्हारे लिए चिंतित हूं। मैं नहीं चाहता कि तुम आहत हो, बस।",
              "ka": "ಅಸ್ಮಿತಾ, ನಿನ್ನ ಬಗ್ಗೆ ನಮಗೆ ಹೆಮ್ಮೆ ಇದೆ. ನಾನು ನಿನ್ನ ಬಗ್ಗೆ ಚಿಂತಿತನಾಗಿರುವುದರಿಂದ ಹೇಗೆ ಪ್ರತಿಕ್ರಿಯಿಸಬೇಕು ಎಂದು ನನಗೆ ಖಾತ್ರಿಯಾಗಲಿಲ್ಲ. ನೀನ್ನ ನೋಯಿಸಬೇಕೆಂದು ನಾನು ಬಯಸುವುದಿಲ್ಲ, ಅಷ್ಟೆ.",
              "te": "అస్మిత, నీ గురించి మేము గర్విస్తున్నాము. నేను నీ గురించి ఆందోళన చెందుతున్నందున ఏం మాట్లాడాలో నాకు తెలియలేదు. నువ్వు బాధపడటం నాకు ఇష్టం లేదు, అంతే.",
              "or": "ଅସ୍ମିତା, ଆମେ ତୁମ ପାଇଁ ଗର୍ବିତ | ମୁଁ କିପରି ଉତ୍ତର ଦେବି ତାହା ନିଶ୍ଚିତ ନୁହେଁ, ମୁଖ୍ୟତଃ ମୁଁ ଆପଣଙ୍କ ପାଇଁ ଚିନ୍ତିତ | ମୁଁ ଚାହେଁ ନାହିଁ ତୁମେ ଆଘାତ ପାଇବ, ତାହା ହିଁ |",
              "as": "আমি তোমাক লৈ গৌৰৱান্বিত, অস্মিতা। মই কেনেদৰে সঁহাৰি দিব লাগে নিশ্চিত নাছিলো, কাৰণ বেছিভাগ সময় মই তোমাৰ বাবে চিন্তিত আছিলো। মই তুমি আঘাত পোৱাটো নিবিচাৰো, সেইটোৱেই কথা।",
              "gu": "અમને તારા પર ગર્વ છે, અસ્મિતા.  મને ખાતરી ન હતી કે કેવી રીતે પ્રતિસાદ આપું, મોટે ભાગે કારણ કે હું તારા માટે ચિંતિત હતી.  હું નથી ઇચ્છતી કે તમને દુઃખ થાય, બસ."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Yes, we will all figure together how to deal with what people say. But as long as you can live a life of happiness and self-respect, we will be satisfied.",
              "hi": "हां, हम सब मिलकर यह तय करेंगे कि लोग जो कहते हैं उससे कैसे निपटा जाए। लेकिन जब तक तुम खुशी और स्वाभिमान का जीवन जी सकती हो, हम संतुष्ट रहेंगे।",
              "ka": "ಹೌದು, ಜನರು ಹೇಳುವುದನ್ನು ಹೇಗೆ ಎದುರಿಸಬೇಕೆಂದು ನಾವೆಲ್ಲರೂ ಒಟ್ಟಾಗಿ ಲೆಕ್ಕಾಚಾರ ಮಾಡುತ್ತೇವೆ. ಆದರೆ ನೀವು ಎಲ್ಲಿಯವರೆಗೆ ಸಂತೋಷ ಮತ್ತು ಸ್ವಾಭಿಮಾನದ ಜೀವನವನ್ನು ನಡೆಸಬಹುದು, ನಾವು ತೃಪ್ತರಾಗುತ್ತೇವೆ.",
              "te": "అవును, అందరూ అనే మాటలను ఎలా ఎదుర్కోవాలి అని మనమందరం కలిసి నిర్ణయించుకోవాలి. కానీ నువ్వు సంతోషంగా మరియు ఆత్మగౌరవంతో జీవించగలిగినంత కాలం, మేము సంతృప్తి చెందుతాము.",
              "or": "ହଁ, ଲୋକମାନେ ଯାହା କୁହନ୍ତି ତାହା ସହିତ କିପରି ମୁକାବିଲା କରିବା ଆମେ ସମସ୍ତେ ଏକାଠି ମିଶି ଚେଷ୍ଟା କରିବୁ | କିନ୍ତୁ ଯେପର୍ଯ୍ୟନ୍ତ ତୁମେ ସୁଖ ଏବଂ ଆତ୍ମ ସମ୍ମାନର ସହିତ ଜୀବନ ବଞ୍ଚାଇ ପାରିବ, ଆମେ ସନ୍ତୁଷ୍ଟ ହେବୁ |",
              "as": "হয়, মানুহে কোৱা কথাবোৰৰ সৈতে কেনেদৰে মোকাবিলা কৰিব লাগে আমি সকলোৱে একেলগে বুজি পাম। কিন্তু যেতিয়ালৈকে তুমি সুখ আৰু আত্মসন্মানেৰে জীৱন যাপন কৰিব পাৰা, আমি সন্তুষ্ট হৈ থাকিম।",
              "gu": "હા, લોકો જે કહે છે તેની સાથે કેવી રીતે વ્યવહાર કરવો તે આપણે બધા મળીને વિચારીશું.  પણ જ્યાં સુધી તું સુખી અને સ્વાભિમાનનું જીવન જીવી શકીશ ત્યાં સુધી અમે સંતુષ્ટ રહીશું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Appa, you don't have to be so dramatic.",
              "hi": "अप्पा, आपको इतना नाटकीय नहीं होना चाहिए।",
              "ka": "ಅಪ್ಪಾ, ನೀನು ನಾಟಕೀಯವಾಗಿ ವರ್ತಿಸಬೇಕಿಲ್ಲ.",
              "te": "అప్పా, నువ్వు అంత నాటకీయంగా ఉండనవసరం లేదు.",
              "or": "ଆପା, ତୁମକୁ ଏତେ ନାଟକୀୟ ହେବାକୁ ପଡିବ ନାହିଁ |",
              "as": "আপ্পা, আপুনি ইমান নাটকীয় হ'ব নালাগে।",
              "gu": "પપ્પા, તમારે આટલા નાટકીય બનવાની જરૂર નથી."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.4.text",
            "value": {
              "en": "Hahaha",
              "hi": "हाहाहा",
              "ka": "ಹಹಹ",
              "te": "హహహ",
              "or": "ହାହାହା",
              "as": "হাঃ হাঃ হাঃ",
              "gu": "હાહા"
            }
          }
        ],
        "page17": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_5_2",
              "hi": "E2_5_2",
              "ka": "E2_5_2",
              "te": "E2_5_2",
              "or": "E2_5_2",
              "as": "E2_5_2",
              "gu": "E2_5_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is feeling a little more comfortable about her social transition. She has completed the first step of the transition.",
              "hi": "अस्मिता अपने सामाजिक ट्रांज़िशन/ बदलाव के विषय में थोड़ा अधिक सहज अनुभव कर रही है। उसने ट्रांज़िशन/ बदलाव का पहला चरण पूरा कर लिया है।",
              "ka": "ಅಸ್ಮಿತಾ ತನ್ನ ಸಾಮಾಜಿಕ ಪರಿವರ್ತನೆಯ ಬಗ್ಗೆ ಸ್ವಲ್ಪ ಹೆಚ್ಚು ಆರಾಮದಾಯಕವಾಗಿದ್ದಾಳೆ. ಅವಳು ಪರಿವರ್ತನೆಯ ಮೊದಲ ಹಂತವನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದಾಳೆ.",
              "te": "అస్మిత తన సామాజిక పరివర్తన గురించి కొంచెం సౌకర్యంగా ఉంది. ఆమె పరివర్తన యొక్క మొదటి దశను పూర్తి చేసింది.",
              "or": "ଅସମିତା ତାଙ୍କ ସାମାଜିକ ପରିବର୍ତ୍ତନ ବିଷୟରେ ଟିକିଏ ଅଧିକ ଆରାମଦାୟକ ଅନୁଭବ କରୁଛନ୍ତି | ସେ ପରିବର୍ତ୍ତନର ପ୍ରଥମ ସୋପାନ ସମାପ୍ତ କରିଛନ୍ତି |",
              "as": "অস্মিতাই তাইৰ সামাজিক পৰিৱৰ্তনৰ বিষয়ে অলপ সহজ অনুভৱ কৰিছে। তাই পৰিৱৰ্তনৰ প্ৰথম পদক্ষেপটো সম্পূৰ্ণ কৰিছে।",
              "gu": "અસ્મિતા પોતાના સામાજિક ટ્રાન્સિશન વિશે થોડી વધુ આરામદાયક અનુભવે છે.  તેણીએ ટ્રાન્સિશનનું પ્રથમ પગલું પૂર્ણ કર્યું છે."
            }
          }
        ],
        "page18": [
          {
            "valuePath": "display",
            "value": {
              "en": "UnderstandingSelf",
              "hi": "स्वयं को समझना",
              "ka": "ಅಂಡರ್ಸ್ಟ್ಯಾಂಡಿಂಗ್ ಸೆಲ್ಫ್",
              "te": "అండర్ స్టాండింగ్ సెల్ఫ్",
              "or": "UnderstandingSelf",
              "as": "নিজকে বুজা",
              "gu": "સ્વયંને સમજવું"
            }
          }
        ],
        "page19": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_5_3",
              "hi": "E2_5_3",
              "ka": "E2_5_3",
              "te": "E2_5_3",
              "or": "E2_5_3",
              "as": "E2_5_3",
              "gu": "E2_5_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Over the next few months, her body undergoes several changes. To clear her mind, she talks to her counsellor and family regularly.",
              "hi": "अगले कुछ महीनों में, उसके शरीर में कई बदलाव हुए। अपने दिमाग को शांत करने के लिए, वह नियमित रूप से अपने परामर्शदाता और परिवार से बात करती है।",
              "ka": "ಮುಂದಿನ ಕೆಲವು ತಿಂಗಳುಗಳಲ್ಲಿ, ಅವಳ ದೇಹವು ಹಲವಾರು ಬದಲಾವಣೆಗಳಿಗೆ ಒಳಗಾಗುತ್ತದೆ. ಅವಳ ಮನಸ್ಸನ್ನು ತೆರವುಗೊಳಿಸಲು, ಅವಳು ತನ್ನ ಸಲಹೆಗಾರ ಮತ್ತು ಕುಟುಂಬದೊಂದಿಗೆ ನಿಯಮಿತವಾಗಿ ಮಾತನಾಡುತ್ತಾಳೆ.",
              "te": "తరువాతి కొన్ని నెలల్లో, ఆమె శరీరం అనేక మార్పులకు గురవుతుంది. ఆమె మనస్సును క్లియర్ చేయడానికి, ఆమె తన కౌన్సెలర్ మరియు కుటుంబ సభ్యులతో క్రమం తప్పకుండా మాట్లాడుతుంది.",
              "or": "ପରବର୍ତ୍ତୀ କିଛି ମାସ ମଧ୍ୟରେ, ତାଙ୍କ ଶରୀରରେ ଅନେକ ପରିବର୍ତ୍ତନ ଆସିଥାଏ | ତା’ର ମନକୁ ଠିକ୍‌ କରିବା ପାଇଁ ସେ ନିୟମିତ ଭାବେ ତାଙ୍କ ପରାମର୍ଶଦାତା ଏବଂ ପରିବାର ସହିତ କଥାବାର୍ତ୍ତା କରନ୍ତି |",
              "as": "পাছৰ কেইমাহমানৰ ভিতৰত, তাইৰ শৰীৰত কেইবাটাও পৰিৱৰ্তন হয়। তাইৰ মনটো মুকলি কৰিবলৈ, তাই তাইৰ পৰামৰ্শদাতা আৰু পৰিয়ালৰ সৈতে নিয়মীয়াকৈ কথা পাতে।",
              "gu": "આગામી કેટલાક મહિનાઓમાં, તેણીનાં શરીરમાં ઘણા ફેરફારો થાય છે.  પોતાનાં મનને સાફ કરવા તે તેના કાઉન્સેલર અને પરિવાર સાથે નિયમિત રીતે વાત કરે છે."
            }
          }
        ],
        "page20": [
          {
            "valuePath": "display",
            "value": {
              "en": "E2_5_4",
              "hi": "E2_5_4",
              "ka": "E2_5_4",
              "te": "E2_5_4",
              "or": "E2_5_4",
              "as": "E2_5_4",
              "gu": "E2_5_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "When she wants to be alone, she goes for a long walk, watches good movies, and dances on her favourite track. She begins feeling a lot more comfortable with herself.",
              "hi": "जब वह अकेले रहना चाहती है, तो वह लंबी सैर के लिए जाती है, अच्छी फिल्में देखती है, और अपने पसंदीदा ट्रैक पर नृत्य करती है। वह स्वयं के साथ बहुत अधिक सहज अनुभव कर रही है।",
              "ka": "ಅವಳು ಒಂಟಿಯಾಗಿರಲು ಬಯಸಿದಾಗ, ಅವಳು ಸುದೀರ್ಘ ನಡಿಗೆಗೆ ಹೋಗುತ್ತಾಳೆ, ಒಳ್ಳೆಯ ಚಲನಚಿತ್ರಗಳನ್ನು ನೋಡುತ್ತಾಳೆ ಮತ್ತು ಅವಳ ನೆಚ್ಚಿನ ಟ್ರ್ಯಾಕ್ನಲ್ಲಿ ನೃತ್ಯ ಮಾಡುತ್ತಾಳೆ. ಅವಳು ತನ್ನೊಂದಿಗೆ ಹೆಚ್ಚು ಆರಾಮದಾಯಕವಾಗಲು ಪ್ರಾರಂಭಿಸುತ್ತಾಳೆ.",
              "te": "ఆమె ఒంటరిగా ఉండాలనుకున్నప్పుడు, ఆమె చాలా దూరం నడుస్తుంది, మంచి సినిమాలు చూస్తుంది మరియు తనకు ఇష్టమైన ట్రాక్‌లో డ్యాన్స్ చేస్తుంది. ఆమె తనతో చాలా సుఖంగా ఉండటం ప్రారంభించింది.",
              "or": "ଯେତେବେଳେ ସେ ଏକୁଟିଆ ରହିବାକୁ ଚାହାଁନ୍ତି, ସେ ଦୀର୍ଘ ସମୟ ବୁଲିବାକୁ ଯାଆନ୍ତି, ଭଲ ଚଳଚ୍ଚିତ୍ର ଦେଖନ୍ତି ଏବଂ ନିଜ ପ୍ରିୟ ଟ୍ରାକରେ ନାଚନ୍ତି | ସେ ନିଜ ସହିତ ବହୁତ ଆରାମଦାୟକ ଅନୁଭବ କରିବା ଆରମ୍ଭ କରନ୍ତି |",
              "as": "যেতিয়া তাই অকলে থাকিব বিচাৰে, তাই বহু দূৰ খোজ কাঢ়িবলৈ যায়, ভাল চিনেমা চায়, আৰু তাইৰ প্ৰিয় ট্ৰেকত নাচে। তাই নিজৰ সৈতে বহুত বেছি সহজ অনুভৱ কৰিবলৈ আৰম্ভ কৰে।",
              "gu": "જ્યારે તે એકલા રહેવા માંગે છે, ત્યારે તે દૂર સુધી ચાલવા જાય છે, સારી ફિલ્મો જુએ છે અને તેના મનપસંદ ટ્રેક પર ડાન્સ કરે છે.  તેણી પોતાની જાત સાથે વધુ આરામદાયક અનુભવવા લાગે છે."
            }
          }
        ]
      },
      "scene42": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_1_1",
              "hi": "E3_1_1",
              "ka": "E3_1_1",
              "te": "E3_1_1",
              "or": "E3_1_1",
              "as": "E3_1_1",
              "gu": "E3_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "After working as a freelancer for a few years, Asmita is confident about herself and her skills. She feels ready to take on more challenges.",
              "hi": "कुछ सालों तक एक फ्रीलांसर के रूप में काम करने के बाद, अस्मिता अपने और अपने कौशल के बारे में आश्वस्त है। वह अपने आप को अधिक चुनौतियों का सामना करने के लिए तैयार अनुभव करती है।",
              "ka": "ಕೆಲವು ವರ್ಷಗಳ ಕಾಲ ಸ್ವತಂತ್ರವಾಗಿ ಕೆಲಸ ಮಾಡಿದ ನಂತರ, ಅಸ್ಮಿತಾ ತನ್ನ ಬಗ್ಗೆ ಮತ್ತು ತನ್ನ ಕೌಶಲ್ಯದ ಬಗ್ಗೆ ವಿಶ್ವಾಸ ಹೊಂದಿದ್ದಾಳೆ. ಹೆಚ್ಚಿನ ಸವಾಲುಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಅವಳು ಸಿದ್ಧಳಾಗಿದ್ದಾಳೆ.",
              "te": "కొన్నాళ్లు ఫ్రీలాన్సర్‌గా పనిచేసిన అస్మిత తనపై, తన నైపుణ్యాలపై నమ్మకంగా ఉంది. ఆమె మరిన్ని సవాళ్లను స్వీకరించడానికి సిద్ధంగా ఉన్నట్లు అనిపిస్తుంది.",
              "or": "କିଛି ବର୍ଷ ଫ୍ରିଲାନ୍ସର୍ ଭାବରେ କାମ କରିବା ପରେ ଅସ୍ମିତା ନିଜ ଏବଂ ତାଙ୍କ ଦକ୍ଷତା ଉପରେ ଆତ୍ମବିଶ୍ୱାସୀ | ସେ ଅଧିକ ଚ୍ୟାଲେଞ୍ଜ ନେବାକୁ ପ୍ରସ୍ତୁତ ବୋଲି ଅନୁଭବ କରନ୍ତି |",
              "as": "কেইবছৰমান ফ্ৰিলাঞ্চাৰ হিচাপে কাম কৰাৰ পিছত, অস্মিতা নিজৰ আৰু তাইৰ দক্ষতাৰ বিষয়ে আত্মবিশ্বাসী হৈ পৰিছে। তাই অধিক প্ৰত্যাহ্বান ল'বলৈ নিজকে সাজু বুলি ভাবে।",
              "gu": "થોડા વર્ષો સુધી ફ્રીલાન્સર તરીકે કામ કર્યા પછી, અસ્મિતાને પોતાની જાત અને પોતાની કુશળતા પર વિશ્વાસ આવ્યો.  તેણી વધુ પડકારોનો સામનો કરવા માટે તૈયાર અનુભવે છે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_1_2_search",
              "hi": "E3_1_2_सर्च ",
              "ka": "E3_1_2_search",
              "te": "E3_1_2_శోధన",
              "or": "E3_1_2_search",
              "as": "E3_1_2_অনুসন্ধান",
              "gu": "E3_1_2_સોધો"
            }
          },
          {
            "valuePath": "blocks.0.props.contextText",
            "value": {
              "en": "Asmita wants to apply to jobs, but before applying, she does an internet search to see if companies have an inclusive policy for the LGBTQ+ community.",
              "hi": "अस्मिता नौकरियों के लिए आवेदन करना चाहती है, लेकिन आवेदन करने से पहले, वह इंटरनेट पर यह देखने के लिए खोज करती है कि क्या कंपनियों के पास एलजीबीटीक्यू + समुदाय के लिए कोई समावेशी नीति है।",
              "ka": "ಅಸ್ಮಿತಾ ಉದ್ಯೋಗಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಬಯಸುತ್ತಾಳೆ, ಆದರೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುವ ಮೊದಲು, ಕಂಪನಿಗಳು LGBTQ+ ಸಮುದಾಯಕ್ಕೆ ಒಳಗೊಳ್ಳುವ ನೀತಿಯನ್ನು ಹೊಂದಿದೆಯೇ ಎಂದು ನೋಡಲು ಇಂಟರ್ನೆಟ್ ಹುಡುಕಾಟವನ್ನು ಮಾಡುತ್ತಾಳೆ.",
              "te": "అస్మిత ఉద్యోగాలకు అప్లై చేసుకోవాలనుకుంటోంది, కానీ అప్లై చేయడానికి ముందు, LGBTQ+ కమ్యూనిటీ కోసం కంపెనీలు విధానాన్ని కలిగి ఉన్నాయో లేదో తెలుసుకోవడానికి ఆమె ఇంటర్నెట్ లో శోధన చేస్తుంది.",
              "or": "ଅସ୍ମିତା ଚାକିରିରେ ଆବେଦନ କରିବାକୁ ଚାହାଁନ୍ତି, କିନ୍ତୁ ଆବେଦନ କରିବା ପୂର୍ବରୁ, LGBTQ + ସମ୍ପ୍ରଦାୟ ପାଇଁ କମ୍ପାନୀଗୁଡିକର ଏକ ଅନ୍ତର୍ଭୂକ୍ତ ନୀତି ଅଛି କି ନାହିଁ ତାହା ଦେଖିବା ପାଇଁ ସେ ଏକ ଇଣ୍ଟରନେଟ୍ ସର୍ଚ୍ଚ କରନ୍ତି |",
              "as": "অস্মিতাই চাকৰিৰ বাবে আবেদন কৰিব বিচাৰে, কিন্তু আবেদন কৰাৰ আগতে, তেওঁ LGBTQ+ সম্প্ৰদায়ৰ বাবে কোম্পানীবোৰৰ এক সামগ্ৰিক নীতি আছে নেকি চাবলৈ ইণ্টাৰনেটত অনুসন্ধান কৰে।",
              "gu": "અસ્મિતા નોકરીઓ માટે અરજી કરવા માંગે છે, પરંતુ અરજી કરતા પહેલા, તે કંપનીઓ પાસે LGBTQ+ સમુદાય માટે ઈન્કુસિવ પોલિસીઓ  છે કે કેમ તે જોવા માટે તે ઇન્ટરનેટ પર  સર્ચ કરે છે."
            }
          },
          {
            "valuePath": "blocks.0.props.strings.0.string",
            "value": {
              "en": "LGBTQ+ Policy + Technology Companies + India",
              "hi": "एलजीबीटीक्यू + पॉलिसी + प्रौद्योगिकी कंपनियां + भारत",
              "ka": "LGBTQ+ ನೀತಿ + ತಂತ್ರಜ್ಞಾನ ಕಂಪನಿಗಳು + ಭಾರತ",
              "te": "LGBTQ+ పాలసీ + టెక్నాలజీ కంపెనీలు + భారతదేశం",
              "or": "LGBTQ+ Policy + Technology Companies + India",
              "as": "LGBTQ+ নীতি + প্ৰযুক্তি কোম্পানী + ভাৰত",
              "gu": "LGBTQ+ નીતિ + ટેકનોલોજી કંપનીઓ + ભારત"
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_1_3",
              "hi": "E3_1_3",
              "ka": "E3_1_3",
              "te": "E3_1_3",
              "or": "E3_1_3",
              "as": "E3_1_3",
              "gu": "E3_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She is surprised that companies are undertaking diversity surveys and are consciously building inclusive workplaces.",
              "hi": "वह आश्चर्यचकित है कि कंपनियां विविधता सर्वेक्षण कर रही हैं और सचेत रूप से समावेशी कार्यस्थलों का निर्माण कर रही हैं।",
              "ka": "ಕಂಪನಿಗಳು ವೈವಿಧ್ಯತೆಯ ಸಮೀಕ್ಷೆಗಳನ್ನು ಕೈಗೊಳ್ಳುತ್ತಿವೆ ಮತ್ತು ಪ್ರಜ್ಞಾಪೂರ್ವಕವಾಗಿ ಅಂತರ್ಗತ ಕೆಲಸದ ಸ್ಥಳಗಳನ್ನು ನಿರ್ಮಿಸುತ್ತಿವೆ ಎಂದು ಅವರು ಆಶ್ಚರ್ಯ ಪಡುತ್ತಾರೆ.",
              "te": "కంపెనీలు వైవిధ్య సర్వేలు చేపట్టడం మరియు స్పృహతో వర్క్‌ప్లేస్‌లను నిర్మించడం పట్ల ఆమె ఆశ్చర్యం వ్యక్తం చేసింది.",
              "or": "ସେ ଆଶ୍ଚର୍ଯ୍ୟ ହୋଇଛନ୍ତି ଯେ କମ୍ପାନୀଗୁଡିକ ବିବିଧତା ସର୍ବେକ୍ଷଣ କରୁଛନ୍ତି ଏବଂ ସଚେତନ ଭାବରେ ଅନ୍ତର୍ଭୂକ୍ତ କାର୍ଯ୍ୟକ୍ଷେତ୍ର ନିର୍ମାଣ କରୁଛନ୍ତି ||",
              "as": "তাই আচৰিত হৈছে যে কোম্পানীবোৰে বৈচিত্ৰ্যৰ জৰীপ কৰি আছে আৰু সচেতনভাৱে সকলোকে সামৰি লোৱা কৰ্মস্থান নিৰ্মাণ কৰি আছে।",
              "gu": "તેણીને આશ્ચર્ય થયું કે કંપનીઓ વિવિધતા સર્વેક્ષણો હાથ ધરી રહી છે અને સભાનપણે ઈન્કુસિવ કાર્યસ્થળો બનાવી રહી છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_1_5",
              "hi": "E3_1_5",
              "ka": "E3_1_5",
              "te": "E3_1_5",
              "or": "E3_1_5",
              "as": "E3_1_5",
              "gu": "E3_1_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She applies to a few MultiNational Organisations. Not all interviews go well, but Asmita is looking forward to the interview at Future Technologies. Asmita has applied for the role of a senior frontend developer.",
              "hi": "वह कुछ बहुराष्ट्रीय संगठनों में आवेदन करती है। सभी साक्षात्कार अच्छी तरह से नहीं जाते हैं, लेकिन अस्मिता फ्यूचर टेक्नोलॉजीज के साक्षात्कार के लिए उत्सुक है। अस्मिता ने एक सीनियर फ्रंटेंड डेवलपर की भूमिका के लिए आवेदन किया है।",
              "ka": "ಅವಳು ಕೆಲವು ಬಹುರಾಷ್ಟ್ರೀಯ ಸಂಸ್ಥೆಗಳಿಗೆ ಅನ್ವಯಿಸುತ್ತಾಳೆ. ಎಲ್ಲಾ ಸಂದರ್ಶನಗಳು ಸರಿಯಾಗಿ ನಡೆಯುವುದಿಲ್ಲ, ಆದರೆ ಅಸ್ಮಿತಾ ಫ್ಯೂಚರ್ ಟೆಕ್ನಾಲಜೀಸ್‌ನಲ್ಲಿ ಸಂದರ್ಶನಕ್ಕಾಗಿ ಎದುರು ನೋಡುತ್ತಿದ್ದಾಳೆ. ಅಸ್ಮಿತಾ ಹಿರಿಯ ಮುಂಭಾಗದ ಡೆವಲಪರ್ ಪಾತ್ರಕ್ಕಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ್ದಾಳೆ.",
              "te": "ఆమె కొన్ని మల్టీ నేషనల్ సంస్థలకు అప్లై చేస్తుంది. అన్ని ఇంటర్వ్యూలు సరిగ్గా జరగవు, కానీ అస్మిత ఫ్యూచర్ టెక్నాలజీస్‌లో ఇంటర్వ్యూ కోసం ఎదురుచూస్తోంది. సీనియర్ ఫ్రంటెండ్ డెవలపర్ పాత్ర కోసం అస్మిత అప్లై చేసుకుంది.",
              "or": "ସେ କିଛି ମଲ୍ଟି ନ୍ୟାସନାଲ୍ ଅର୍ଗାନାଇଜେସନ୍ ପାଇଁ ଆବେଦନ କରିଛନ୍ତି | ସମସ୍ତ ସାକ୍ଷାତକାର ଭଲରେ ଗଲାନି, କିନ୍ତୁ ଅସ୍ମିତା ଫ୍ୟୁଚର ଟେକ୍ନୋଲୋଜିରେ ସାକ୍ଷାତକାରକୁ ଅପେକ୍ଷା କରିଥାଏ | ଜଣେ ବରିଷ୍ଠ ଫ୍ରଣ୍ଟେଣ୍ଡ୍ ଡେଭଲପର୍ ଭାବରେ ଆସମିତା ଆବେଦନ କରିଛନ୍ତି |",
              "as": "তাই কেইটামান বহুজাতিক প্ৰতিষ্ঠানত আবেদন কৰে। সকলোবোৰ সাক্ষাৎকাৰ ভালদৰে নহয়, কিন্তু অস্মিতাই ফিউচাৰ টেকন'লজীত সাক্ষাৎকাৰৰ বাবে অপেক্ষা কৰি আছে। অস্মিতাই এগৰাকী জ্যেষ্ঠ ফ্ৰণ্টএণ্ড বিকাশকাৰীৰ চাকৰিৰ বাবে আবেদন কৰিছে।",
              "gu": "તેણી કેટલીક બહુરાષ્ટ્રીય સંસ્થાઓમાં અરજી કરે છે.  બધા ઇન્ટરવ્યુ સારા નથી જતા, પણ અસ્મિતા ફ્યુચર ટેક્નોલોજીસમાં ઇન્ટરવ્યુની રાહ જોઈ રહી છે.  અસ્મિતાએ વરિષ્ઠ ફ્રન્ટ એન્ડ ડેવલપરના રોલ માટે અરજી કરી હતી."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "Research",
              "hi": "शोध ",
              "ka": "ಸಂಶೋಧನೆ",
              "te": "రీసర్చ్",
              "or": "ଅନୁସନ୍ଧାନ",
              "as": "গৱেষণা",
              "gu": "રિસર્ચ"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_1",
              "hi": "E3_2_1",
              "ka": "E3_2_1",
              "te": "E3_2_1",
              "or": "E3_2_1",
              "as": "E3_2_1",
              "gu": "E3_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "They require Asmita to give a technical examination. She needs to write a program within the deadline given to her.",
              "hi": "अस्मिता को तकनीकी परीक्षा देनी है। उसे दी गई समय सीमा के भीतर एक कार्यक्रम लिखने की आवश्यकता है।",
              "ka": "ಅವರು ಅಸ್ಮಿತಾ ತಾಂತ್ರಿಕ ಪರೀಕ್ಷೆಯನ್ನು ನೀಡಬೇಕೆಂದು ಹೇಳುತ್ತಾರೆ. ತನಗೆ ನೀಡಿದ ಗಡುವಿನೊಳಗೆ ಅವಳು ಕಾರ್ಯಕ್ರಮವನ್ನು ಬರೆಯಬೇಕಾಗಿದೆ.",
              "te": "వారు అస్మిత టెక్నికల్ పరీక్షను రాయాలని అనుకుంటారు. ఆమె తనకు ఇచ్చిన గడువులోపు ప్రోగ్రామ్ రాయాలి.",
              "or": "ଅସ୍ମିତାକୁ ବୈଷୟିକ ପରୀକ୍ଷା ଦେବା ଆବଶ୍ୟକ ଅଛି| ତାଙ୍କୁ ଦିଆଯାଇଥିବା ସମୟସୀମା ମଧ୍ୟରେ ସେ ଏକ ପ୍ରୋଗ୍ରାମ୍ ଲେଖିବା ଆବଶ୍ୟକ କରନ୍ତି |",
              "as": "তেওঁলোকে অস্মিতাক এটা কাৰিকৰী পৰীক্ষা দিয়াৰ প্ৰয়োজন আছে বুলি কয়। তাইক দিয়া সময়সীমাৰ ভিতৰত তাই এটা কাৰ্যসূচী লিখিব লাগিব।",
              "gu": "તેઓને કહ્યું કે અસ્મિતાને ટેકનિકલ પરીક્ષા આપવાની જરૂર છે.  તેણીએ  આપેલી સમયમર્યાદામાં પ્રોગ્રામ લખવો જરૂરી હતો."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_2",
              "hi": "E3_2_2",
              "ka": "E3_2_2",
              "te": "E3_2_2",
              "or": "E3_2_2",
              "as": "E3_2_2",
              "gu": "E3_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She clears the technical round with ease. Asmita is invited for a meeting with the HR and the project team for another round of interview.",
              "hi": "वह सरलता से तकनीकी चरण में सफल होती है। अस्मिता को साक्षात्कार के एक और चरण के लिए एचआर और प्रोजेक्ट टीम के साथ एक बैठक में आमंत्रित किया जाता है।",
              "ka": "ಅವಳು ತಾಂತ್ರಿಕ ಸುತ್ತನ್ನು ಸುಲಭವಾಗಿ ತೆರವುಗೊಳಿಸುತ್ತಾಳೆ. ಮತ್ತೊಂದು ಸುತ್ತಿನ ಸಂದರ್ಶನಕ್ಕಾಗಿ HR ಮತ್ತು ಪ್ರಾಜೆಕ್ಟ್ ತಂಡದೊಂದಿಗೆ ಸಭೆಗೆ ಅಸ್ಮಿತಾ ಅವರನ್ನು ಆಹ್ವಾನಿಸಲಾಗಿದೆ.",
              "te": "ఆమె టెక్నికల్ రౌండ్‌ను సులభంగా క్లియర్ చేస్తుంది. అస్మిత మరొక రౌండ్ ఇంటర్వ్యూ కోసం HR మరియు ప్రాజెక్ట్ బృందంతో మీటింగ్ కి ఆహ్వానించబడింది.",
              "or": "ସେ ଟେକନିକ୍‌ ରାଉଣ୍ଡକୁ ସହଜରେ ପାସ୍‌ କରନ୍ତି | ଅନ୍ୟ ଏକ ରାଉଣ୍ଡ ସାକ୍ଷାତକାର ପାଇଁ ଅସ୍ମିତାଙ୍କୁ ଏଚ୍‍ ଆର୍‌ ଏବଂ ପ୍ରୋଜେକ୍ଟ ଦଳ ସହିତ ଏକ ସାକ୍ଷାତ ପାଇଁ ନିମନ୍ତ୍ରଣ କରାଯାଇଛି |",
              "as": "তাই কাৰিকৰী ৰাউণ্ডটো সহজে সম্পূৰ্ণ কৰে। অস্মিতাক আন এটা পৰ্যায়ৰ সাক্ষাৎকাৰৰ বাবে HR আৰু প্ৰকল্প দলৰ সৈতে এখন বৈঠকৰ বাবে আমন্ত্ৰণ জনোৱা হৈছে।",
              "gu": "તે આસાનીથી ટેક્નિકલ રાઉન્ડ ક્લિયર કરે છે.  અસ્મિતાને HR અને પ્રોજેક્ટ ટીમ સાથે મુલાકાત માટે બીજા રાઉન્ડ માટે આમંત્રિત કરવામાં આવી."
            }
          }
        ],
        "page9": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_3//",
              "hi": "E3_2_3 //",
              "ka": "E3_2_3//",
              "te": "E3_2_3//",
              "or": "E3_2_3//",
              "as": "E3_2_3//",
              "gu": "E3_2_3//"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.label",
            "value": {
              "en": "Farah",
              "hi": "फराह",
              "ka": "ಫರಾಹ್",
              "te": "ఫరా",
              "or": "ଫରାହ",
              "as": "ফাৰাহ",
              "gu": "ફરાહ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.label",
            "value": {
              "en": "Asmita",
              "hi": "अस्मिता",
              "ka": "ಅಸ್ಮಿತಾ",
              "te": "అస్మిత",
              "or": "ଅସ୍ମିତା",
              "as": "অস্মিতা",
              "gu": "અસ્મિતા"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.label",
            "value": {
              "en": "Jayesh",
              "hi": "जयेश",
              "ka": "ಜಯೇಶ್",
              "te": "జయేష్",
              "or": "ଜୟେଶ",
              "as": "জয়শ",
              "gu": "જયેશ"
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.0.text",
            "value": {
              "en": "You did exceptionally well in the technical round.",
              "hi": "आपने तकनीकी दौर में असाधारण रूप से अच्छा कार्य किया है।",
              "ka": "ತಾಂತ್ರಿಕ ಸುತ್ತಿನಲ್ಲಿ ನೀವು ಅಸಾಧಾರಣವಾಗಿ ಉತ್ತಮ ಸಾಧನೆ ಮಾಡಿದ್ದೀರಿ.",
              "te": "మీరు టెక్నికల్ రౌండ్‌లో బాగా రాణించారు.",
              "or": "ବୈଷୟିକ ରାଉଣ୍ଡରେ ତୁମେ ଅସାଧାରଣ ଭାବରେ ଭଲ କରିଛ |",
              "as": "আপুনি কাৰিকৰী ৰাউণ্ডত অসাধাৰণ প্ৰদৰ্শন কৰিছিল।",
              "gu": "તમે અમુક વસ્તુઓને બાદ કરતાં ટેકનિકલ રાઉન્ડમાં  સારું પ્રદર્શન કર્યું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.1.text",
            "value": {
              "en": "Thank you! I enjoyed working on the program.",
              "hi": "शुक्रिया! मुझे कार्यक्रम पर काम करने में आनंद आया।",
              "ka": "ಧನ್ಯವಾದಗಳು! ನಾನು ಕಾರ್ಯಕ್ರಮದಲ್ಲಿ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಆನಂದಿಸಿದೆ.",
              "te": "ధన్యవాదాలు! నేను ప్రోగ్రామ్‌లో పని చేయడం ఆనందించాను.",
              "or": "ଧନ୍ୟବାଦ! ମୁଁ କାର୍ଯ୍ୟକ୍ରମରେ କାମ କରିବାକୁ ଉପଭୋଗ କଲି |",
              "as": "ধন্যবাদ! মই কাৰ্যসূচীটোত কাম কৰি ভাল পাইছিলো।",
              "gu": "આભાર!  મને પ્રોગ્રામમાં કામ કરવાની મજા આવી."
            }
          },
          {
            "valuePath": "blocks.0.props.convBlocks.2.text",
            "value": {
              "en": "Tell us about yourself and your work experience.",
              "hi": "हमें अपने और अपने कार्य अनुभव के बारे में बताएं।",
              "ka": "ನಿಮ್ಮ ಬಗ್ಗೆ ಮತ್ತು ನಿಮ್ಮ ಕೆಲಸದ ಅನುಭವದ ಬಗ್ಗೆ ನಮಗೆ ತಿಳಿಸಿ.",
              "te": "మీ గురించి మరియు మీ పని అనుభవం గురించి మాకు చెప్పండి.",
              "or": "ନିଜ ବିଷୟରେ ଏବଂ ତୁମର କାର୍ଯ୍ୟ ଅଭିଜ୍ଞତା ବିଷୟରେ ଆମକୁ କୁହନ୍ତୁ |",
              "as": "আমাক আপোনাৰ নিজৰ বিষয়ে আৰু আপোনাৰ কামৰ অভিজ্ঞতাৰ বিষয়ে কওক।",
              "gu": "અમને પોતાના અને તમારા કામના અનુભવ વિશે જણાવો."
            }
          }
        ],
        "page10": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_4////",
              "hi": "E3_2_4 /////",
              "ka": "E3_2_4////",
              "te": "E3_2_4////",
              "or": "E3_2_4////",
              "as": "E3_2_4////",
              "gu": "E3_2_4////"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.0.text",
            "value": {
              "en": "My name is Asmita. I am a transwoman. I completed my engineering at the University College of Technology, Hyderabad. After college, I worked as a freelance developer for nearly two years.",
              "hi": "मेरा नाम अस्मिता है। मैं एक ट्रांसवुमन हूं। मैंने यूनिवर्सिटी कॉलेज ऑफ टेक्नोलॉजी, हैदराबाद से अपनी इंजीनियरिंग पूरी की है। कॉलेज के बाद, मैंने लगभग दो वर्षों तक एक फ्रीलांस डेवलपर के रूप में काम किया है।",
              "ka": "ನನ್ನ ಹೆಸರು ಅಸ್ಮಿತಾ. ನಾನು ಟ್ರಾನ್ಸ್ ವುಮನ್. ನಾನು ಹೈದರಾಬಾದಿನ ಯೂನಿವರ್ಸಿಟಿ ಕಾಲೇಜ್ ಆಫ್ ಟೆಕ್ನಾಲಜಿಯಲ್ಲಿ ಇಂಜಿನಿಯರಿಂಗ್ ಮುಗಿಸಿದೆ. ಕಾಲೇಜು ನಂತರ, ನಾನು ಸುಮಾರು ಎರಡು ವರ್ಷಗಳ ಕಾಲ ಸ್ವತಂತ್ರ ಡೆವಲಪರ್ ಆಗಿ ಕೆಲಸ ಮಾಡಿದೆ.",
              "te": "నా పేరు అస్మిత. నేను ట్రాన్స్ ఉమెన్ ని. నేను యూనివర్సిటీ కాలేజ్ ఆఫ్ టెక్నాలజీ, హైదరాబాద్‌లో ఇంజనీరింగ్ పూర్తి చేసాను. కాలేజీ తర్వాత, నేను దాదాపు రెండు సంవత్సరాలు ఫ్రీలాన్స్ డెవలపర్‌గా పనిచేశాను.",
              "or": "ମୋର ନାମ ଅସ୍ମିତା | ମୁଁ ଜଣେ ଟ୍ରାନ୍ସଓମ୍ୟାନ୍ | ମୁଁ ହାଇଦ୍ରାବାଦର ୟୁନିଭରସିଟି କଲେଜ ଅଫ୍ ଟେକ୍ନୋଲୋଜିରେ ମୋର ଇଞ୍ଜିନିୟରିଂ ସମାପ୍ତ କରିଛି | କଲେଜ ପରେ, ମୁଁ ପ୍ରାୟ ଦୁଇ ବର୍ଷ ପାଇଁ ଏକ ଫ୍ରିଲାନ୍ସ ଡେଭଲପର୍ ଭାବରେ କାର୍ଯ୍ୟ କରିଛି |",
              "as": "মোৰ নাম অস্মিতা। মই এগৰাকী ট্ৰেন্সৱমেন। মই হায়দৰাবাদৰ ইউনিভাৰ্চিটি কলেজ অৱ টেকন'লজীত মোৰ অভিযান্ত্ৰিক সম্পূৰ্ণ কৰিছিলো। কলেজৰ পিছত, মই প্ৰায় দুবছৰ ফ্ৰিলান্স বিকাশক হিচাপে কাম কৰিছিলো।",
              "gu": "મારું નામ અસ્મિતા છે.  હું ટ્રાન્સવુમન છું.  મેં યુનિવર્સિટી કોલેજ ઓફ ટેકનોલોજી, હૈદરાબાદમાં મારું એન્જિનિયરિંગ પૂર્ણ કર્યું.  કૉલેજ પછી, મેં લગભગ બે વર્ષ ફ્રીલાન્સ ડેવલપર તરીકે કામ કર્યું છે."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.1.text",
            "value": {
              "en": "Why are you looking for a change from freelancing?",
              "hi": "आप फ्रीलांसिंग से बदलाव की तलाश क्यों कर रही हैं?",
              "ka": "ಫ್ರೀಲ್ಯಾನ್ಸಿಂಗ್‌ನಿಂದ ನೀವು ಬದಲಾವಣೆಯನ್ನು ಏಕೆ ಹುಡುಕುತ್ತಿದ್ದೀರಿ?",
              "te": "మీరు ఫ్రీలాన్సింగ్ నుండి మార్పు కోసం ఎందుకు చూస్తున్నారు?",
              "or": "ଫ୍ରିଲାନ୍ସିଂରୁ ଆପଣ କାହିଁକି ପରିବର୍ତ୍ତନ ଖୋଜୁଛନ୍ତି?",
              "as": "আপুনি ফ্ৰীলাঞ্চিঙৰ চাকৰি এৰি কিয় বেলেগ চাকৰি বিচাৰি আছে?",
              "gu": "તમે ફ્રીલાન્સિંગથી અલગ  બીજું કામ  કેમ શોધી રહ્યા છો?"
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.2.text",
            "value": {
              "en": "I started working as a freelancer because I was not confident about working in an organisation. I was harassed in college due to my gender identity. Over the last two years, I have been working towards accepting my identity and finding spaces that support me. Now I have confidence and experience, and I feel ready to work in inclusive organisations with LGBTQ+ friendly policies.",
              "hi": "मैंने एक फ्रीलांसर के रूप में काम करना शुरू कर दिया क्योंकि मैं एक संगठन में काम करने के बारे में आश्वस्त नहीं थी। मुझे अपनी लिंग पहचान के कारण कॉलेज में परेशान किया गया था। पिछले दो वर्षों से, मैं अपनी पहचान को स्वीकार करने और मुझे समर्थन देने वाले रिक्त स्थानों को खोजने की दिशा में काम कर रही हूं। अब मेरे पास आत्मविश्वास और अनुभव है, और मैं एलजीबीटीक्यू+ दोस्ताना नीतियों के साथ समावेशी संगठनों में काम करने के लिए तैयार हूं।",
              "ka": "ಸಂಸ್ಥೆಯಲ್ಲಿ ಕೆಲಸ ಮಾಡುವ ಬಗ್ಗೆ ನನಗೆ ವಿಶ್ವಾಸವಿಲ್ಲದ ಕಾರಣ ನಾನು ಸ್ವತಂತ್ರವಾಗಿ ಕೆಲಸ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿದೆ. ನನ್ನ ಲಿಂಗ ಗುರುತಿನ ಕಾರಣದಿಂದ ನನಗೆ ಕಾಲೇಜಿನಲ್ಲಿ ಕಿರುಕುಳ ನೀಡಲಾಯಿತು. ಕಳೆದ ಎರಡು ವರ್ಷಗಳಿಂದ, ನಾನು ನನ್ನ ಗುರುತನ್ನು ಸ್ವೀಕರಿಸಲು ಮತ್ತು ನನ್ನನ್ನು ಬೆಂಬಲಿಸುವ ಸ್ಥಳಗಳನ್ನು ಹುಡುಕಲು ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೇನೆ. ಈಗ ನಾನು ಆತ್ಮವಿಶ್ವಾಸ ಮತ್ತು ಅನುಭವವನ್ನು ಹೊಂದಿದ್ದೇನೆ ಮತ್ತು LGBTQ+ ಸ್ನೇಹಿ ನೀತಿಗಳೊಂದಿಗೆ ಅಂತರ್ಗತ ಸಂಸ್ಥೆಗಳಲ್ಲಿ ಕೆಲಸ ಮಾಡಲು ನಾನು ಸಿದ್ಧನಿದ್ದೇನೆ.",
              "te": "ఒక సంస్థలో పనిచేయడంపై నాకు నమ్మకం లేనందున నేను ఫ్రీలాన్సర్‌గా పనిచేయడం ప్రారంభించాను. నా లింగ గుర్తింపు కారణంగా కాలేజీలో నన్ను వేధించారు. గత రెండు సంవత్సరాలుగా, నేను నా గుర్తింపును అంగీకరించడానికి మరియు నాకు మద్దతు ఇచ్చే ఖాళీలను కనుగొనడానికి చూస్తున్నాను. ఇప్పుడు నాకు విశ్వాసం మరియు అనుభవం ఉంది మరియు LGBTQ+ స్నేహపూర్వక విధానాలతో కలుపుకొని ఉన్న సంస్థలలో పని చేయడానికి నేను సిద్ధంగా ఉన్నాను.",
              "or": "ମୁଁ ଏକ ଫ୍ରିଲାନ୍ସର୍ ଭାବରେ କାମ କରିବା ଆରମ୍ଭ କଲି କାରଣ ମୁଁ ଏକ ସଂସ୍ଥାରେ କାମ କରିବା ଉପରେ ଆତ୍ମବିଶ୍ୱାସୀ ନଥିଲି | ମୋର ଲିଙ୍ଗ ପରିଚୟ ହେତୁ ମୋତେ କଲେଜରେ ହଇରାଣ କରାଯାଇଥିଲା | ଗତ ଦୁଇ ବର୍ଷ ମଧ୍ୟରେ, ମୁଁ ମୋର ପରିଚୟ ଗ୍ରହଣ କରିବା ଏବଂ ମୋତେ ସମର୍ଥନ କରୁଥିବା ସ୍ଥାନ ଖୋଜିବା ଦିଗରେ କାର୍ଯ୍ୟ କରୁଛି | ବର୍ତ୍ତମାନ ମୋର ଆତ୍ମବିଶ୍ୱାସ ଏବଂ ଅଭିଜ୍ଞତା ଅଛି, ଏବଂ ମୁଁ LGBTQ + ବନ୍ଧୁତ୍ୱପୂର୍ଣ୍ଣ ନୀତି ସହିତ ଅନ୍ତର୍ଭୂକ୍ତ ସଂସ୍ଥାଗୁଡ଼ିକରେ କାର୍ଯ୍ୟ କରିବାକୁ ପ୍ରସ୍ତୁତ ବୋଲି ଅନୁଭବ କରୁଛି |",
              "as": "মই এজন ফ্ৰিলাঞ্চাৰ হিচাপে কাম কৰা আৰম্ভ কৰিছিলো কাৰণ মই এটা প্ৰতিষ্ঠানত কাম কৰাৰ বিষয়ে আত্মবিশ্বাসী নাছিলো। মোৰ লিংগ পৰিচয়ৰ বাবে মোক কলেজত হাৰাশাস্তি কৰা হৈছিল। যোৱা দুবছৰধৰি, মই মোৰ পৰিচয় গ্ৰহণ কৰা আৰু মোক সমৰ্থন কৰা স্থান বিচাৰি উলিওৱাৰ দিশত কাম কৰি আছোঁ। এতিয়া মোৰ আত্মবিশ্বাস আৰু অভিজ্ঞতা আছে, আৰু মই LGBTQ+ বন্ধুত্বপূৰ্ণ নীতিৰ সৈতে অন্তৰ্ভুক্ত প্ৰতিষ্ঠানত কাম কৰিবলৈ সাজু অনুভৱ কৰো।",
              "gu": "મેં ફ્રીલાન્સર તરીકે કામ કરવાનું શરૂ કર્યું કારણ કે મને સંસ્થામાં કામ કરવાનો વિશ્વાસ નહોતો.  મારી લિંગ ઓળખના કારણે મને કોલેજમાં હેરાન કરવામાં આવી હતી.  છેલ્લાં બે વર્ષથી, હું મારી ઓળખ સ્વીકારવા અને મને સપોર્ટ કરતી જગ્યાઓ શોધવા માટે કામ કરી રહ્યો છું.  હવે મને આત્મવિશ્વાસ અને અનુભવ છે અને હું LGBTQ+ મૈત્રીપૂર્ણ નીતિઓ સાથે ઈન્કુસિવ સંસ્થાઓમાં કામ કરવા માટે તૈયાર અનુભવું છું."
            }
          },
          {
            "valuePath": "blocks.0.props.blockData.3.text",
            "value": {
              "en": "Well, Asmita, I am glad you approached us. Future Technologies is an inclusive organisation. If you join the team, you will be the first transwoman to be part of the organisation. We are looking forward to having you on our team!",
              "hi": "खैर, अस्मिता, मुझे खुशी है कि आपने हमसे संपर्क किया है। फ्यूचर टेक्नोलॉजीस एक समावेशी संगठन है। यदि आप टीम में शामिल होती हैं, तो आप संगठन का हिस्सा बनने वाली पहली ट्रांसवुमन होंगीं। हम आपको अपनी टीम में शामिल करने की आशा करते हैं!",
              "ka": "ಸರಿ, ಅಸ್ಮಿತಾ, ನೀವು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿದ್ದಕ್ಕೆ ನನಗೆ ಖುಷಿಯಾಗಿದೆ. ಫ್ಯೂಚರ್ ಟೆಕ್ನಾಲಜೀಸ್ ಒಂದು ಅಂತರ್ಗತ ಸಂಸ್ಥೆಯಾಗಿದೆ. ನೀವು ತಂಡಕ್ಕೆ ಸೇರಿದರೆ, ನೀವು ಸಂಸ್ಥೆಯ ಭಾಗವಾಗಿರುವ ಮೊದಲ ಟ್ರಾನ್ಸ್‌ವುಮನ್ ಆಗುತ್ತೀರಿ. ನಮ್ಮ ತಂಡದಲ್ಲಿ ನಿಮ್ಮನ್ನು ಹೊಂದಲು ನಾವು ಎದುರು ನೋಡುತ್ತಿದ್ದೇವೆ!",
              "te": "సరే, అస్మితా, మీరు మమ్మల్ని సంప్రదించినందుకు నేను సంతోషిస్తున్నాను. ఫ్యూచర్ టెక్నాలజీస్ ఒక ఇంక్లుసివ్ సంస్థ. మీరు టీం లో చేరినట్లయితే, సంస్థలో భాగమైన మొదటి ట్రాన్స్‌వుమన్ అవుతారు. మీతో కలిసి టీమ్ లో పని చేసేందుకు ఉండేందుకు మేము ఎదురుచూస్తున్నాము!",
              "or": "ଆଚ୍ଛା, ଅସ୍ମିତା, ମୁଁ ଖୁସି ଯେ ତୁମେ ଆମ ପାଖକୁ ଆସିଛ | ଫ୍ୟୁଚର ଟେକ୍ନୋଲୋଜିସ୍‍ ଏକ ଅନ୍ତର୍ଭୂକ୍ତ ସଂଗଠନ | ଯଦି ଆପଣ ଦଳରେ ଯୋଗ ଦିଅନ୍ତି, ତେବେ ଆପଣ ସଂଗଠନର ଅଂଶ ହେବାରେ ପ୍ରଥମ ଟ୍ରାନ୍ସଓମ୍ୟାନ୍ ହେବେ | ଆମେ ତୁମକୁ ଆମ ଦଳରେ ରହିବାକୁ ଅପେକ୍ଷା କରିଛୁ !",
              "as": "ঠিক আছে, অস্মিতা, মই ভাল পাইছো যে আপুনি আমাৰ ওচৰলৈ আহিছে। ফিউচাৰ টেকন'লজিছ হৈছে এক অন্তৰ্ভুক্ত প্ৰতিষ্ঠান। যদি আপুনি দলটোত যোগদান কৰে, আপুনি প্ৰতিষ্ঠানটোৰ অংশ হোৱা প্ৰথম ট্ৰেন্সৱ'মেন হ'ব। আমি আপোনাক আমাৰ দলত ৰাখিবলৈ বাট চাই আছোঁ!",
              "gu": "સારું, અસ્મિતા, મને આનંદ છે કે તમે અમારો સંપર્ક કર્યો.  ફ્યુચર ટેક્નોલોજીસ એક વ્યાપક સંસ્થા છે.  જો તમે ટીમમાં જોડાઓ છો, તો તમે સંસ્થાનો ભાગ બનનાર પ્રથમ ટ્રાન્સવુમન બનશો.  અમે તમને અમારી ટીમમાં રાખવા માટે આતુર છીએ!"
            }
          }
        ],
        "page11": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_5",
              "hi": "E3_2_5",
              "ka": "E3_2_5",
              "te": "E3_2_5",
              "or": "E3_2_5",
              "as": "E3_2_5",
              "gu": "E3_2_5"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita is happy to get the job, but she is even more pleased that she is working in a space that accepts her identity and works towards creating a safe work environment.",
              "hi": "अस्मिता नौकरी पाने में प्रसन्न है, लेकिन वह इससे भी ज्यादा प्रसन्न है कि वह ऐसी जगह पर काम कर रही है जो उसकी पहचान स्वीकार करती है और एक सुरक्षित कार्य वातावरण बनाने की दिशा में काम करती है।",
              "ka": "ಅಸ್ಮಿತಾಗೆ ಕೆಲಸ ಸಿಕ್ಕಿದ್ದಕ್ಕೆ ಸಂತೋಷವಾಗಿದೆ, ಆದರೆ ಅವಳು ತನ್ನ ಗುರುತನ್ನು ಸ್ವೀಕರಿಸುವ ಮತ್ತು ಸುರಕ್ಷಿತ ಕೆಲಸದ ವಾತಾವರಣವನ್ನು ಸೃಷ್ಟಿಸುವ ನಿಟ್ಟಿನಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿರುವ ಜಾಗದಲ್ಲಿ ಕೆಲಸ ಮಾಡುತ್ತಿರುವುದಕ್ಕೆ ಇನ್ನಷ್ಟು ಸಂತೋಷವಾಗಿದೆ.",
              "te": "అస్మిత ఉద్యోగం పొందినందుకు సంతోషంగా ఉంది, కానీ ఆమె తన గుర్తింపును అంగీకరించే మరియు సురక్షితమైన పని వాతావరణాన్ని సృష్టించే దిశగా పని చేస్తున్న ప్రదేశంలో పని చేస్తున్నందుకు ఆమె మరింత సంతోషంగా ఉంది.",
              "or": "ଅସ୍ମିତା ଚାକିରି ପାଇ ଖୁସି, କିନ୍ତୁ ସେ ଆହୁରି ଖୁସି ଯେ ସେ ଏକ ଜାଗାରେ କାମ କରୁଛନ୍ତି ଯାହା ତାଙ୍କ ପରିଚୟକୁ ଗ୍ରହଣ କରେ ଏବଂ ଏକ ନିରାପଦ କାର୍ଯ୍ୟ ପରିବେଶ ସୃଷ୍ଟି ଦିଗରେ କାର୍ଯ୍ୟ କରେ |",
              "as": "অস্মিতাই চাকৰিটো পাই সুখী, কিন্তু তেওঁ আৰু বেছি সুখী এইকাৰণে যে তেওঁ এনে এক স্থানত কাম কৰি আছে যিয়ে তেওঁৰ পৰিচয় স্বীকাৰ কৰে আৰু এক সুৰক্ষিত কামৰ পৰিৱেশ সৃষ্টি কৰাৰ দিশত কাম কৰে।",
              "gu": "અસ્મિતા નોકરી મેળવીને ખુશ છે, પરંતુ તે તેનાથી પણ વધુ ખુશ છે કે તે એક એવી જગ્યામાં કામ કરી રહી છે જે તેની ઓળખને સ્વીકારે છે અને સલામતી ભર્યું કાર્ય વાતાવરણ બનાવવા માટે કામ કરે છે."
            }
          }
        ],
        "page12": [
          {
            "valuePath": "display",
            "value": {
              "en": "E3_2_6",
              "hi": "E3_2_6",
              "ka": "E3_2_6",
              "te": "E3_2_6",
              "or": "E3_2_6",
              "as": "E3_2_6",
              "gu": "E3_2_6"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "All new employees at Future Tech undergo gender sensitisation and diversity training as part of their induction.",
              "hi": "फ्यूचर टेक के सभी नए कर्मचारी अपने आगमन के हिस्से के रूप में लिंग संवेदीकरण और विविधता प्रशिक्षण से गुजरते हैं।",
              "ka": "ಫ್ಯೂಚರ್ ಟೆಕ್‌ನಲ್ಲಿರುವ ಎಲ್ಲಾ ಹೊಸ ಉದ್ಯೋಗಿಗಳು ತಮ್ಮ ಇಂಡಕ್ಷನ್‌ನ ಭಾಗವಾಗಿ ಲಿಂಗ ಸಂವೇದನೆ ಮತ್ತು ವೈವಿಧ್ಯತೆಯ ತರಬೇತಿಗೆ ಒಳಗಾಗುತ್ತಾರೆ.",
              "te": "ఫ్యూచర్ టెక్‌లోని కొత్త ఉద్యోగులందరూ వారి ఇండక్షన్‌లో భాగంగా జెండర్ సెన్సిటైజేషన్ మరియు డైవర్సిటీ ట్రైనింగ్ తీసుకుంటారు.",
              "or": "ଫ୍ୟୁଚର ଟେକ୍ ର ସମସ୍ତ ନୂତନ କର୍ମଚାରୀମାନେ ସେମାନଙ୍କର ଅନୁକରଣର ଏକ ଅଂଶ ଭାବରେ ଲିଙ୍ଗ ସମ୍ବେଦନଶୀଳତା ଏବଂ ବିବିଧତା ତାଲିମ ଗ୍ରହଣ କରନ୍ତି |",
              "as": "ফিউচাৰ টেকৰ সকলো নতুন কৰ্মচাৰীয়ে তেওঁলোকৰ অন্তৰ্ভুক্তিৰ অংশ হিচাপে লিংগ সংবেদনশীলতা আৰু বৈচিত্ৰ্যৰ প্ৰশিক্ষণ লাভ কৰে।",
              "gu": "ફ્યુચર ટેકના તમામ નવા કર્મચારીઓ તેમના ઇન્ડક્શનના ભાગરૂપે લિંગ સંવેદના અને વિવિધતાની તાલીમમાંથી પસાર થાય છે."
            }
          }
        ]
      },
      "scene43": {
        "page0": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_1_1",
              "hi": "E4_1_1",
              "ka": "E4_1_1",
              "te": "E4_1_1",
              "or": "E4_1_1",
              "as": "E4_1_1",
              "gu": "E4_1_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita joins the team as a senior developer. Her role in the team is to structure and design web pages.",
              "hi": "अस्मिता टीम में एक सीनियर डेवलपर के रूप में  शामिल हो गई। टीम में उसकी भूमिका वेब पृष्ठों की संरचना और डिजाइन करना है।",
              "ka": "ಅಸ್ಮಿತಾ ಅವರು ಹಿರಿಯ ಡೆವಲಪರ್ ಆಗಿ ತಂಡವನ್ನು ಸೇರುತ್ತಾಳೆ. ತಂಡದಲ್ಲಿ ಅವಳ ಪಾತ್ರವು ವೆಬ್ ಪುಟಗಳನ್ನು ರಚಿಸುವುದು ಮತ್ತು ವಿನ್ಯಾಸಗೊಳಿಸುವುದು.",
              "te": "అస్మిత సీనియర్ డెవలపర్‌గా టీమ్‌లో చేరింది. జట్టులో ఆమె పాత్ర వెబ్ పేజీల నిర్మాణం మరియు రూపకల్పన.",
              "or": "ଅସ୍ମିତା ଜଣେ ସିନିୟର ଡେଭଲପର୍ ଭାବରେ ଦଳରେ ଯୋଗ ଦିଅନ୍ତି | ଦଳରେ ତାଙ୍କର ଭୂମିକା ହେଉଛି ୱେବ୍ ପୃଷ୍ଠାଗୁଡ଼ିକୁ ଗଠନ ଏବଂ ଡିଜାଇନ୍ କରିବା |",
              "as": "অস্মিতাই দলটোত এজন জ্যেষ্ঠ বিকাশকাৰী হিচাপে যোগদান কৰে। দলটোত তাইৰ ভূমিকা হৈছে ৱেব পৃষ্ঠাবোৰ গঠন আৰু ডিজাইন কৰা।",
              "gu": "અસ્મિતા વરિષ્ઠ ડેવલપર તરીકે ટીમમાં જોડાય છે.  ટીમમાં તેણીનો રોલ વેબ પૃષ્ઠોની રચના અને ડિઝાઇન કરવાનો છે."
            }
          }
        ],
        "page2": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_1_2",
              "hi": "E4_1_2",
              "ka": "E4_1_2",
              "te": "E4_1_2",
              "or": "E4_1_2",
              "as": "E4_1_2",
              "gu": "E4_1_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She also has to work on striking a balance between functionality and aesthetic and ensuring websites work for mobile as well.",
              "hi": "उसे कार्यक्षमता और सौंदर्य के बीच संतुलन बनाने और वेबसाइटों को मोबाइल के लिए भी काम करने के लिए सुनिश्चित करने पर भी काम करना है।",
              "ka": "ಕ್ರಿಯಾತ್ಮಕತೆ ಮತ್ತು ಸೌಂದರ್ಯದ ನಡುವೆ ಸಮತೋಲನವನ್ನು ಸಾಧಿಸಲು ಮತ್ತು ಮೊಬೈಲ್‌ಗಾಗಿ ವೆಬ್‌ಸೈಟ್‌ಗಳು ಕಾರ್ಯನಿರ್ವಹಿಸುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಅವಳು ಕೆಲಸ ಮಾಡಬೇಕಾಗುತ್ತದೆ.",
              "te": "ఆమె ఫంక్షనాలితీ మరియు ఆస్తెటిక్ మరియు మొబైల్ కోసం వెబ్‌సైట్‌లు పని చేసేలా చూసుకోవడం మధ్య సమతుల్యతను సాధించడంలో కూడా పని చేయాలి.",
              "or": "କାର୍ଯ୍ୟକାରିତା ଏବଂ ସୌନ୍ଦର୍ଯ୍ୟ ମଧ୍ୟରେ ସନ୍ତୁଳନ ସୃଷ୍ଟି କରିବା ଏବଂ ମୋବାଇଲ୍ ପାଇଁ ୱେବସାଇଟ୍ କାର୍ଯ୍ୟ କରିବା ନିଶ୍ଚିତ କରିବାକୁ ମଧ୍ୟ ତାଙ୍କୁ କାର୍ଯ୍ୟ କରିବାକୁ ପଡିବ |",
              "as": "তাই কাৰ্যক্ষমতা আৰু নান্দনিকতাৰ মাজত ভাৰসাম্য ৰখা আৰু ৱেবছাইটবোৰে ম'বাইলৰ বাবেও কাম কৰাটো নিশ্চিত কৰাৰ ওপৰতো তাই কাম কৰিব লাগিব।",
              "gu": "તેણી કાર્યક્ષમતા અને સૌંદર્ય તત્વ વચ્ચે સંતુલન જાળવવાનું અને મોબાઇલ માટે પણ વેબસાઇટ્સ કાર્ય કરે છે તેની ખાતરી કરવાનું કામ કરવું પડે છે."
            }
          }
        ],
        "page3": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_1_3",
              "hi": "E4_1_3",
              "ka": "E4_1_3",
              "te": "E4_1_3",
              "or": "E4_1_3",
              "as": "E4_1_3",
              "gu": "E4_1_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "The project lead, Farah, is friendly and is impressed with Asmita's skills and requests her to handle presentations and client meetings.",
              "hi": "प्रोजेक्ट लीड, फराह, दोस्ताना है और अस्मिता के कौशल से प्रभावित है और उसे प्रस्तुतियों और ग्राहक के बैठकों को संभालने का अनुरोध करती है।",
              "ka": "ಪ್ರಾಜೆಕ್ಟ್ ಲೀಡ್, ಫರಾ, ಸ್ನೇಹಪರಳು ಮತ್ತು ಅಸ್ಮಿತಾಳ ಕೌಶಲ್ಯದಿಂದ ಪ್ರಭಾವಿತಳಾಗಿದ್ದಾಳೆ ಮತ್ತು ಪ್ರಸ್ತುತಿಗಳು ಮತ್ತು ಕ್ಲೈಂಟ್ ಸಭೆಗಳನ್ನು ನಿರ್ವಹಿಸಲು ಅವಳನ್ನು ವಿನಂತಿಸುತ್ತಾಳೆ.",
              "te": "ప్రాజెక్ట్ లీడ్, ఫరా స్నేహపూర్వకంగా ఉంటుంది మరియు అస్మిత నైపుణ్యాలకు ముగ్ధురాలైంది మరియు ప్రెజెంటేషన్లు మరియు క్లయింట్ సమావేశాలను నిర్వహించమని ఆమెను అభ్యర్థిస్తుంది.",
              "or": "ପ୍ରୋଜେକ୍ଟ ଲିଡ୍, ଫରାହ, ବନ୍ଧୁତ୍ୱପୂର୍ଣ୍ଣ ଏବଂ ଅସ୍ମିତାଙ୍କ କୌଶଳଗୁଡିକ ଦ୍ୱାରା ପ୍ରଭାବିତ ଏବଂ ତାଙ୍କୁ ଉପସ୍ଥାପନା ଏବଂ କ୍ଲାଏଣ୍ଟ ମିଟିଂ ପରିଚାଳନା କରିବାକୁ ଅନୁରୋଧ କରନ୍ତି |",
              "as": "মুখ্য প্ৰকল্পকাৰ, ফাৰাহ বন্ধুৰদৰে আৰু তেওঁ অস্মিতাৰ দক্ষতাত প্ৰভাৱিত হয় আৰু তাইক প্ৰেজেণ্টেচন আৰু গ্ৰাহকৰ বৈঠক চম্ভালিবলৈ অনুৰোধ কৰে।",
              "gu": "પ્રોજેક્ટ લીડ, ફરાહ, મૈત્રીપૂર્ણ છે અને અસ્મિતાની કુશળતાથી પ્રભાવિત છે અને તેણીને પ્રેઝન્ટેશન અને ક્લાયન્ટ મીટિંગ્સ સંભાળવા વિનંતી કરે છે."
            }
          }
        ],
        "page4": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_1_4",
              "hi": "E4_1_4",
              "ka": "E4_1_4",
              "te": "E4_1_4",
              "or": "E4_1_4",
              "as": "E4_1_4",
              "gu": "E4_1_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "In this manner, Asmita learns a lot from her work. She gets many opportunities to sharpen her communication skills.",
              "hi": "इस तरह, अस्मिता अपने काम से बहुत कुछ सीखती है। उसे अपने संचार कौशल को तेज करने के कई अवसर मिलते हैं।",
              "ka": "ಈ ರೀತಿಯಾಗಿ, ಅಸ್ಮಿತಾ ತನ್ನ ಕೆಲಸದಿಂದ ಬಹಳಷ್ಟು ಕಲಿಯುತ್ತಾಳೆ. ತನ್ನ ಸಂವಹನ ಕೌಶಲ್ಯವನ್ನು ಚುರುಕುಗೊಳಿಸಲು ಅವಳು ಅನೇಕ ಅವಕಾಶಗಳನ್ನು ಪಡೆಯುತ್ತಾಳೆ.",
              "te": "ఈ పద్ధతిలో, అస్మిత తన పని నుండి చాలా నేర్చుకుంటుంది. ఆమె తన కమ్యూనికేషన్ స్కిల్స్‌కు పదును పెట్టడానికి అనేక అవకాశాలను పొందుతుంది.",
              "or": "ଏହିପରି ଭାବରେ, ଅସ୍ମିତା ତାଙ୍କ କାର୍ଯ୍ୟରୁ ବହୁତ କିଛି ଶିଖନ୍ତି | ତାଙ୍କର ଯୋଗାଯୋଗ ଦକ୍ଷତାକୁ ତୀକ୍ଷ୍ଣ କରିବା ପାଇଁ ସେ ଅନେକ ସୁଯୋଗ ପାଆନ୍ତି |",
              "as": "এনেদৰে, অস্মিতাই তাইৰ কামৰ পৰা বহুতো শিকে। তাই তাইৰ যোগাযোগ দক্ষতা উন্নত কৰাৰ বহুতো সুযোগ পায়।",
              "gu": "આ રીતે અસ્મિતા તેના કામમાંથી ઘણું શીખવા મળે છે.  તેણીને  વાતચીત કરવાના  કૌશલ્યને તેજ કરવાની ઘણી તકો મળે છે."
            }
          }
        ],
        "page5": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_2_1",
              "hi": "E4_2_1",
              "ka": "E4_2_1",
              "te": "E4_2_1",
              "or": "E4_2_1",
              "as": "E4_2_1",
              "gu": "E4_2_1"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Asmita continues to attend the weekly support group meetings.",
              "hi": "अस्मिता साप्ताहिक समर्थन समूह की बैठकों में भाग लेना जारी रखती है।",
              "ka": "ಅಸ್ಮಿತಾ ಸಾಪ್ತಾಹಿಕ ಬೆಂಬಲ ಗುಂಪು ಸಭೆಗಳಿಗೆ ಹಾಜರಾಗುವುದನ್ನು ಮುಂದುವರೆಸಿದ್ದಾಳೆ.",
              "te": "అస్మిత వారానికోసారి జరిగే సపోర్టు గ్రూపు సమావేశాలకు హాజరవుతూనే ఉంది.",
              "or": "ଅସ୍ମିତା ସାପ୍ତାହିକ ସମର୍ଥନ ଗୋଷ୍ଠୀ ବୈଠକଗୁଡ଼ିକରେ ଯୋଗଦେବା ଜାରି ରଖନ୍ତି |",
              "as": "অস্মিতাই সাপ্তাহিক সমৰ্থন গোটৰ সভাত সদায় উপস্থিত থাকে।",
              "gu": "અસ્મિતા સપોર્ટ ગ્રૂપની સાપ્તાહિક મીટીંગોમાં હાજરી આપવાનું ચાલુ રાખે છે."
            }
          }
        ],
        "page6": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_2_2",
              "hi": "E4_2_2",
              "ka": "E4_2_2",
              "te": "E4_2_2",
              "or": "E4_2_2",
              "as": "E4_2_2",
              "gu": "E4_2_2"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "She talks about her experience in the support group and shares that her education and family support helped her journey.",
              "hi": "वह समर्थन समूह में अपने अनुभव के बारे में बात करती है साझा करती है कि किस तरह शिक्षा और पारिवारिक समर्थन ने उसकी यात्रा में सहायता की।",
              "ka": "ಬೆಂಬಲ ಗುಂಪಿನಲ್ಲಿನ ತನ್ನ ಅನುಭವದ ಕುರಿತು ಅವಳು ಮಾತನಾಡುತ್ತಾಳೆ ಮತ್ತು ಅವಳ ಶಿಕ್ಷಣ ಮತ್ತು ಕುಟುಂಬದ ಬೆಂಬಲವು ಅವಳ ಪ್ರಯಾಣಕ್ಕೆ ಸಹಾಯ ಮಾಡಿದೆ ಎಂದು ಹಂಚಿಕೊಳ್ಳುತ್ತಾಳೆ.",
              "te": "ఆమె సపోర్ట్ గ్రూప్‌లో తన అనుభవం గురించి మాట్లాడుతుంది మరియు తన విద్య మరియు కుటుంబ మద్దతు తన ప్రయాణానికి సహాయపడిందని చెప్తుంది.",
              "or": "ସେ ସମର୍ଥନ ଗୋଷ୍ଠୀରେ ତାଙ୍କ ଅଭିଜ୍ଞତା ବିଷୟରେ କଥାବାର୍ତ୍ତା କରନ୍ତି ଏବଂ ଅଂଶୀଦାର କରନ୍ତି ଯେ ତାଙ୍କ ଶିକ୍ଷା ଏବଂ ପାରିବାରିକ ସହାୟତା ତାଙ୍କୁ ଯାତ୍ରା କରିବାରେ ସାହାଯ୍ୟ କରିଥିଲା |",
              "as": "তাই সমৰ্থন গোটত তাইৰ অভিজ্ঞতাৰ বিষয়ে কয় আৰু এই বিষয়েও কয় যে তাইৰ শিক্ষা আৰু পৰিয়ালৰ সমৰ্থনে তাইৰ যাত্ৰাত সহায় কৰিছিল।",
              "gu": "તેણી સપોર્ટ ગ્રુપમાં તેના અનુભવ વિશે વાત કરે છે અને શેર કરે છે કે તેણીના શિક્ષણ અને કુટુંબના સમર્થનથી તેણીની મુસાફરીમાં મદદ મળી છે"
            }
          }
        ],
        "page7": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_2_3",
              "hi": "E4_2_3",
              "ka": "E4_2_3",
              "te": "E4_2_3",
              "or": "E4_2_3",
              "as": "E4_2_3",
              "gu": "E4_2_3"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "In the support group meetings, Asmita expresses her willingness to help, guide and support others.",
              "hi": "समर्थन समूह की बैठकों में, अस्मिता दूसरों की सहायता, मार्गदर्शन और समर्थन करने की इच्छा व्यक्त करती है।",
              "ka": "ಬೆಂಬಲ ಗುಂಪಿನ ಸಭೆಗಳಲ್ಲಿ, ಅಸ್ಮಿತಾ ಇತರರಿಗೆ ಸಹಾಯ ಮಾಡಲು, ಮಾರ್ಗದರ್ಶನ ಮಾಡಲು ಮತ್ತು ಬೆಂಬಲಿಸಲು ತನ್ನ ಇಚ್ಛೆಯನ್ನು ವ್ಯಕ್ತಪಡಿಸುತ್ತಾಳೆ.",
              "te": "సపోర్ట్ గ్రూప్ సమావేశాలలో, అస్మిత ఇతరులకు సహాయం చేయడానికి, గైడ్ చేయడానికి మరియు మద్దతు ఇవ్వడానికి తన సుముఖతను వ్యక్తం చేస్తుంది.",
              "or": "ସମର୍ଥନ ଗୋଷ୍ଠୀ ବୈଠକଗୁଡ଼ିକରେ, ଅସ୍ମିତା ଅନ୍ୟମାନଙ୍କୁ ସାହାଯ୍ୟ, ମାର୍ଗଦର୍ଶନ ଏବଂ ସମର୍ଥନ କରିବାକୁ ଇଚ୍ଛା ପ୍ରକାଶ କରନ୍ତି |",
              "as": "সমৰ্থন গোটৰ সভাবোৰত, অস্মিতাই আনক সহায়, নিৰ্দেশনা আৰু সমৰ্থন কৰিবলৈ ইচ্ছা প্ৰকাশ কৰে।",
              "gu": "સપોર્ટ ગ્રૂપની મીટિંગ્સમાં, અસ્મિતા અન્ય લોકોને મદદ કરવા, માર્ગદર્શન આપવા અને ટેકો આપવાની ઈચ્છા વ્યક્ત કરે છે."
            }
          }
        ],
        "page8": [
          {
            "valuePath": "display",
            "value": {
              "en": "E4_2_4",
              "hi": "E4_2_4",
              "ka": "E4_2_4",
              "te": "E4_2_4",
              "or": "E4_2_4",
              "as": "E4_2_4",
              "gu": "E4_2_4"
            }
          },
          {
            "valuePath": "blocks.0.props.text",
            "value": {
              "en": "Though Asmita knows her struggle of acceptance is far from over, she is happy with her progress. She is looking forward to the time when she can transition medically and legally get a woman's identity.",
              "hi": "हालांकि अस्मिता जानती है कि उसकी स्वीकृति का संघर्ष खत्म होने से दूर है, वह अपनी प्रगति से खुश है। वह उस समय की प्रतीक्षा कर रही है जब वह चिकित्सकीय रूप से ट्रांज़िशन/ बदलाव कर सकती है और कानूनी रूप से एक महिला की पहचान प्राप्त कर सकती है।",
              "ka": "ಅಸ್ಮಿತಾ ತನ್ನ ಸ್ವೀಕಾರದ ಹೋರಾಟವು ದೂರವಿಲ್ಲ ಎಂದು ತಿಳಿದಿದ್ದರೂ, ತನ್ನ ಪ್ರಗತಿಯಿಂದ ಅವಳು ಸಂತೋಷಪಡುತ್ತಾಳೆ. ಅವಳು ವೈದ್ಯಕೀಯವಾಗಿ ಪರಿವರ್ತನೆಯಾಗುವ ಮತ್ತು ಕಾನೂನುಬದ್ಧವಾಗಿ ಮಹಿಳೆಯ ಗುರುತನ್ನು ಪಡೆಯುವ ಸಮಯವನ್ನು ಎದುರು ನೋಡುತ್ತಿದ್ದಾಳೆ.",
              "te": "అస్మిత తన అంగీకార పోరాటం ముగిసిందని తెలుసుకుంది మరియు, ఆమె తన పురోగతి పట్ల సంతోషంగా ఉంది. ఆమె వైద్యపరంగా పరివర్తన చెందడానికి మరియు చట్టబద్ధంగా స్త్రీ గుర్తింపు పొందే సమయం కోసం ఆమె ఎదురుచూస్తోంది.",
              "or": "ଯଦିଓ ଅସ୍ମିତା ଜାଣନ୍ତି ଯେ ତାଙ୍କର ଗ୍ରହଣ ହେବାର ସଂଗ୍ରାମ ଶେଷ ହେବାଠାରୁ ବହୁ ଦୂର, ସେ ତାଙ୍କ ପ୍ରଗତିରେ ଖୁସି ଅଛନ୍ତି | ସେ ସେହି ସମୟକୁ ଅପେକ୍ଷା କରିଛନ୍ତି ଯେବେ ସେ ମେଡିକାଲ୍ ଏବଂ ଆଇନଗତ ଭାବରେ ଏକ ମହିଳାଙ୍କ ପରିଚୟ ପାଇପାରିବେ |",
              "as": "যদিও অস্মিতাই জানে যে তাইৰ গ্ৰহণযোগ্যতাৰ সংগ্ৰাম এতিয়াও শেষ হোৱা নাই, তাই তাইৰ উন্নতিত সুখী। তেওঁ সেই সময়ৰ বাবে অপেক্ষা কৰি আছে যেতিয়া তেওঁ চিকিৎসাগতভাৱে পৰিৱৰ্তন কৰিব পাৰে আৰু আইনীভাৱে এগৰাকী মহিলাৰ পৰিচয় লাভ কৰিব পাৰে।",
              "gu": "અસ્મિતા જાણે છે કે  પોતાની સ્વીકૃતિ થાય તે માટે હજુ દૂર સુધી સંઘર્ષ કરવો પડશે પણ , તેણી પોતાની પ્રગતિથી ખુશ છે.  તે એવા સમયની રાહ જોઈ રહી છે જ્યારે તે તબીબી અને કાયદેસર રીતે સ્ત્રીની ઓળખ મેળવી શકશે."
            }
          }
        ],
        "page1": [
          {
            "valuePath": "display",
            "value": {
              "en": "Senior Developer",
              "hi": "सीनियर डेवलपर",
              "ka": "ಹಿರಿಯ ಡೆವಲಪರ್",
              "te": "సీనియర్ డెవలపర్",
              "or": "ସିନିୟର ଡେଭଲପର୍",
              "as": "জ্যেষ্ঠ বিকাশক",
              "gu": "સિનિયર ડેવેલોપર "
            }
          }
        ]
      }
    }
  