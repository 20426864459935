import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#fff"
        stroke="#222"
        strokeLinejoin="round"
        d="M27.974 26h-15.5a2 2 0 00-1.754 1.038L8.812 30.52A1 1 0 009.689 32h21.456a1 1 0 00.835-1.55l-2.335-3.55a2 2 0 00-1.67-.9z"
      ></path>
      <mask id="path-3-inside-1_143_2715" fill="#fff">
        <path
          fillRule="evenodd"
          d="M26.85 14.996a7.876 7.876 0 00.15-1.658c.02-1.472-.292-2.777-1.058-3.799C24.692 7.415 22.457 6 19.909 6c-2.522 0-4.737 1.386-5.994 3.475-.785 1.041-1.11 2.387-1.096 3.908v.081c0 .335.02.664.06.987.46 4.515 3.415 10.196 6.115 14.095a.951.951 0 001.568.002c2.62-3.744 5.596-9.131 6.289-13.552z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M26.85 14.996a7.876 7.876 0 00.15-1.658c.02-1.472-.292-2.777-1.058-3.799C24.692 7.415 22.457 6 19.909 6c-2.522 0-4.737 1.386-5.994 3.475-.785 1.041-1.11 2.387-1.096 3.908v.081c0 .335.02.664.06.987.46 4.515 3.415 10.196 6.115 14.095a.951.951 0 001.568.002c2.62-3.744 5.596-9.131 6.289-13.552z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#222"
        d="M26.85 14.996l-.98-.194-.004.02-.003.02.988.154zm.15-1.658l-1-.015v.031l1-.016zm-1.058-3.799l-.862.507.028.048.034.045.8-.6zm-12.027-.064l.798.602.032-.042.027-.044-.857-.516zm-1.096 3.908l1 .01v-.02l-1 .01zm.06 1.068l.996-.1-.002-.013v-.011l-.993.124zM26 13.464c0 .46-.045.907-.13 1.338l1.962.389A8.88 8.88 0 0028 13.463h-2zm0-.11v.11h2v-.142l-2 .032zm-.858-3.215c.592.79.876 1.855.857 3.184l2 .03c.023-1.614-.318-3.16-1.257-4.414l-1.6 1.2zM19.909 7c2.163 0 4.085 1.2 5.171 3.046l1.724-1.014C25.39 6.63 22.843 5 19.909 5v2zm-5.137 2.99C15.864 8.177 17.768 7 19.909 7V5c-2.903 0-5.43 1.597-6.85 3.96l1.713 1.03zm-.953 3.383c-.013-1.382.284-2.487.894-3.296l-1.597-1.204c-.96 1.273-1.312 2.86-1.297 4.519l2-.018zm0 .091v-.071l-2-.02v.091h2zm.053.863a6.92 6.92 0 01-.054-.863h-2c0 .377.024.748.07 1.112l1.984-.25zm5.944 13.65c-2.672-3.86-5.508-9.37-5.941-13.627l-1.99.203c.486 4.772 3.56 10.624 6.287 14.563l1.644-1.139zm6.047-13.136c-.657 4.188-3.523 9.421-6.12 13.134l1.638 1.146c2.642-3.775 5.728-9.317 6.458-13.97l-1.976-.31zm-7.691 14.275a1.951 1.951 0 003.21.005l-1.64-1.146s.005-.005.014-.01a.047.047 0 01.021-.006c.006 0 .014.002.023.006.011.006.016.012.016.012l-1.644 1.139z"
        mask="url(#path-3-inside-1_143_2715)"
      ></path>
      <circle cx="20" cy="14" r="3" fill="#fff" stroke="#222"></circle>
    </svg>
  );
}

export default Icon;