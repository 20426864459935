import React from "react";
import "./_style.scss";

const PageContainer = ({ feather, className, children, style }, ref) => (
  <div ref={ref} className={`PageContainer ${className}`} style={{ ...style }}>
    {feather && (
      <div>
        <div className="featherRight" />
        <div className="featherLeft" />
      </div>
    )}
    {children}
  </div>
);

export default React.forwardRef(PageContainer);
