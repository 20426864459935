import {
  Fragment, lazy,
  Suspense, useContext,
  useEffect, useLayoutEffect, useState
} from "react";
import ReactGA from 'react-ga4';
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Button } from "./components/Button";
import Nav from "./components/Nav";
import { OverlayBlackScreen } from "./components/OverlayBlackScreen";
import { PopUp } from "./components/PopUp";
import Splash from "./components/Splash";
import { CONFIG } from "./CONFIG";
import { EnvContext } from "./contexts/EnvContext";
import { GameItemsContextProvider } from "./contexts/GameItemsContext";
import { MultipleChoiceProvider } from "./contexts/MultipleChoiceContext";
import { MultiSelectProvider } from "./contexts/MultiSelectContext";
import { NavContextProvider } from "./contexts/NavContext";
import { SingleChoiceProvider } from "./contexts/SingleChoiceContext";
import { SoundContext } from "./contexts/SoundContext";
import { StoryProvider } from "./contexts/StoryContext";
import { TCIProvider } from "./contexts/TCIContext";
import { LanguageSelect } from "./pages/LanguageSelect";
import ProgressMap from "./pages/ProgressMap";
import { theme } from './Theme';
import { __LOC } from "./utils/locale";
import LocalStorage from "./utils/LocalStorage";
import webpTest from "./webpTest.webp";






const Playground = lazy(() => import("./pages/Playground"));
const Scene = lazy(() => import("./pages/Scene"));
const Home = lazy(() => import("./pages/Home"));
const Onboarding = lazy(() => import("./pages/Onboarding"));
const InventoryList = lazy(() => import("./pages/InventoryList"));
const InventoryLanding = lazy(() => import("./pages/InventoryLanding"));
// const TCILanding = lazy(() => import("./TCI/TCILanding"));
// const TCIListing = lazy(() => import("./TCI/TCIListing"));
const PageGenerator = lazy(() => import("./components/PageGenerator"));

function App() {
  const [audioStart, setAudioStart] = useState(false);
  const [splash, setSplash] = useState(true);
  const history = useHistory();
  const location = useLocation()
  const { setWebpSupported, activeLang } = useContext(EnvContext);
  const { triggerSoundEffect } = useContext(SoundContext);

  useEffect(() => {
    setTimeout(() => {
      setSplash(false)
    },2000)
  },[])

  useEffect(() => {
    if (parseInt(LocalStorage.getItem("initLangSelect")) !== 1) {
      history.push("/languageSelect");
    } else if (parseInt(LocalStorage.getItem("onboardingComplete")) !== 1) {
      history.push("/onboarding?step=0");
    }

    //initialize GA
    console.log('ENV', process.env.NODE_ENV)
    console.log('HOST', window.location.hostname)
    if(
      process.env.NODE_ENV === 'production' &&
      window.location.hostname.includes('careerquestgame')
    ){
      // console.log('initialize GA')
      // ReactGA.initialize(CONFIG._GATag);
      // ReactGA.pageview(window.location.pathname + window.location.search);
      ReactGA.initialize(CONFIG._GATag);
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }
  }, []);

  useEffect(() => {
    const disableBack = () => {
      if(!location.search.includes('allowback')) window.history.forward(1);
    };
    window.addEventListener("popstate", disableBack);
    return () => window.removeEventListener("popstate", disableBack);
  }, []);

  useLayoutEffect(() => {
    var img = new Image();

    const callback = (result) => {
      setWebpSupported(result);
    };

    img.onload = function () {
      var result = img.width > 0 && img.height > 0;
      callback(result);
    };
    img.onerror = function () {
      callback(false);
    };
    img.src = webpTest;
  }, []);

  return (
    // <IndicatorsContext.Provider value={indicatorsHook}>
    //phase out multiplechoice and singlechoice
    <Fragment>
      { 
      
      !window.location.pathname.includes('/tci') && 
      !window.location.pathname.includes('/page-generator') && 
      (splash || !audioStart) 

      ? ( <div style={{ backgroundColor: theme.colors.BG_YELLOW}}>
        <Splash/>
          
          {!splash && !audioStart && (
          <Fragment>
            <OverlayBlackScreen show={!audioStart} />
            <PopUp>
              <div style={{ padding: "2rem", textAlign: 'center' }}>
                <h4 className='h4'>{__LOC("Enable game sound for ideal experience")}</h4>
                <div style={{paddingTop: '1rem', display: 'flex', justifyContent: 'center'}}>
                <Button
                  onClick={() => {
                    triggerSoundEffect("otherTaps");
                    setAudioStart(true);
                  }}
                  >
                  {__LOC("Enable")}
                </Button>
                </div>
              </div>
            </PopUp>
          </Fragment> ) }
      </div>
      ) : (
        <StoryProvider>
          <GameItemsContextProvider>
            <NavContextProvider>
              <TCIProvider>
                <MultiSelectProvider>
                  <MultipleChoiceProvider>
                    <SingleChoiceProvider>
                      <div className="App">
                        <Suspense fallback={<h1>Loading</h1>}>
                          <Nav />
                          <Switch>
                            <Route exact path="/" component={Home} />
                            <Route path="/onboarding" component={Onboarding} />
                            {/* <Route
                              exact
                              path={CONFIG._TCIUrl}
                              component={TCIListing}
                            />
                            <Route
                              exact
                              path="/tci/:mediaLink/:configFileName"
                              component={TCILanding}
                            /> */}
                            <Route
                              exact
                              path="/page-generator"
                              component={PageGenerator}
                            />
                            <Route
                              exact
                              path="/inventory"
                              component={InventoryList}
                            />
                            <Route
                              exact
                              path="/inventory/:id"
                              component={InventoryLanding}
                            />
                            <Route
                              exact
                              path="/story/:storyId/:sceneId/:pageId"
                              component={Scene}
                            />
                            <Route
                              exact
                              path="/progressMap/:storyId"
                              component={ProgressMap}
                            />
                            <Route
                              exact
                              path="/languageSelect"
                              component={LanguageSelect}
                            />
                            <Route
                              exact
                              path="/playground"
                              component={Playground}
                            />
                          </Switch>
                        </Suspense>
                      </div>
                    </SingleChoiceProvider>
                  </MultipleChoiceProvider>
                </MultiSelectProvider>
              </TCIProvider>
            </NavContextProvider>
          </GameItemsContextProvider>
        </StoryProvider>
      )}
    </Fragment>
  );
}

export default App;
