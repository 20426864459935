import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={74}
      height={82}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M73 24.295V57.71c0 2.4-1.295 4.618-3.392 5.806L40.335 80.12a6.756 6.756 0 01-6.664 0L4.393 63.515A6.679 6.679 0 011 57.709V24.295c0-2.4 1.295-4.616 3.393-5.806L33.669 1.88a6.76 6.76 0 016.666 0l29.273 16.61A6.677 6.677 0 0173 24.295z"
        fill="#E4E1DA"
        stroke="#000"
        strokeMiterlimit={10}
      />
      <circle cx={37} cy={34} r={8} stroke="#C3C0BA" strokeWidth={4} />
      <rect x={22} y={34} width={30} height={21} rx={4} fill="#C4C1BA" />
      <circle cx={37} cy={43} r={3} fill="#E4E1DA" />
      <path d="M34 49l3-6 3 6h-6z" fill="#E4E1DA" />
    </svg>
  )
}

export default SvgComponent