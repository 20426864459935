/**
 * Choice Screen, Map Screen : 5,6 seconds delay out , and delay in 5-6 simultaneous
 * All other 3 second fade out, next music begin immediately
 *
 * Choice , Map, Earn Exp, inventory card
 */

/**
 * ambience fade : 2-3 seconds, one gets over then the other one starts
 */

export default {
  Home: {
    music: [
      {
        playerName: "main",
        source: "themeMain",
      },
      {
        playerName: "rest",
        source: "stop", 
      },
    ],
    ambience: {
        source: 'stop'
    }
  },
  Map: {
    music: [
      {
        playerName: "map",
        source: "themeMap",
      },
      {
        playerName: "story",
        controls: {
            fadeOut: 5,
            mute: true,
        },
      },
      {
        playerName: "rest",
        controls: {
          mute: true, //secs
        },
      },
    ],
    ambience: {
        source: 'stop'
    }
  },
  Razia: { //story1
    music: [
      {
        playerName: "story",
        source: "themeRazia"
      },
      {
        playerName: "rest",
        source: "stop"
      },
    ],
    
  },
  Jhanvi: { //story2
    music: [
      {
        playerName: "story",
        source: "themeJhanvi"
      },
      {
        playerName: "rest",
        source: "stop"
      },
    ],
    
  },
  Naveen: { //story3
    music: [
      {
        playerName: "story",
        source: "themeNaveen"
      },
      {
        playerName: "rest",
        source: "stop"
      },
    ],
    
  },
  Shikhar: { //story4
    music: [
      {
        playerName: "story",
        source: "themeShikhar"
      },
      {
        playerName: "rest",
        source: "stop"
      },
    ],
    
  },
  Mira: { //story5
    music: [
      {
        playerName: "story",
        source: "themeMira"
      },
      {
        playerName: "rest",
        source: "stop"
      },
    ],
    
  },
  Revathy: { //story6
    music: [
      {
        playerName: "story",
        source: "themeRazia"
      },
      {
        playerName: "rest",
        source: "stop"
      },
    ],
    
  },
  Madhav: { //story7
    music: [
      {
        playerName: "story",
        source: "themeMadhav"
      },
      {
        playerName: "rest",
        source: "stop"
      },
    ],
    
  },
  Asmita: { //story8
    music: [
      {
        playerName: "story",
        source: "themeShikhar"
      },
      {
        playerName: "rest",
        source: "stop"
      },
    ],
    
  },
  InventoryList: {
    music: [
      {
        playerName: "otherUI",
        source: "themeInventory",
        // controls: {
        //   delayStart: 2, //secs
        // },
      },
      {
        playerName: "rest",
        controls: {
          mute: true, //secs
        },
      },
    ],
    ambience: {
      source: 'stop'
  }
   
  },
  CharacterContext: {
    music: [
      {
        playerName: "rest",
        source: "stop"
      }
    ]
  },
  Choice: {
    music: [
      {
        playerName: "choice",
        source: "themeChoice",
        // controls: {
        //   delayStart: 2, //secs
        // },
      },
      {
        playerName: "rest",
        controls: {
          mute: true, //secs
        },
      },
    ]
   
  },
   ThreadEndBlock: {
    music: [
      {
        playerName: "threadEndBlock",
        source: "themeThreadEnd",
        controls: {
          mute: false, //secs
        },
        // controls: {
        //   delayStart: 2, //secs
        // },
      },
      {
        playerName: "rest",
        controls: {
          mute: true, //secs
        },
      },
    ]
   
  }
};
