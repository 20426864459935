import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.744 11.924a1.195 1.195 0 01-.825.337c-.29 0-.57-.1-.792-.286a1.145 1.145 0 01-.05 1.6 1.128 1.128 0 01-.825.353 1.179 1.179 0 01-.842-.354 1.164 1.164 0 01.017 1.65h-.017c0 .017 0 .017-.017.017a1.16 1.16 0 01-1.65-.017 1.161 1.161 0 010 1.667 1.127 1.127 0 01-.825.354 1.18 1.18 0 01-.842-.354l-2.913-2.913-.842-.842-2.07-2.07-.034-.034a4.714 4.714 0 016.667-6.65l.034.05-4.142 4.142a2.172 2.172 0 003.03 0l1.112-1.112.286.287a2.14 2.14 0 003.03 0l2.51 2.508a1.196 1.196 0 010 1.667z"
        fill="#73CCFF"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.619 11.048l-.034.034-.842.842a1.196 1.196 0 000-1.667l-2.508-2.509a2.138 2.138 0 01-3.031 0l-.286-.286-1.111 1.111a2.172 2.172 0 01-3.031 0l4.142-4.142.033-.05a4.715 4.715 0 116.668 6.667z"
        fill="#73CCFF"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.127 11.974L12.15 9.997M12.41 13.59l-1.962-1.961M10.743 15.258L8.836 13.35"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent