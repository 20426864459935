import {
  MagnifyingGlass,
  ArrowRight,
  ArrowLeft,
  ArrowUp,
  ArrowDown,
  Stack,
  Coin,
  HeartStraight,
  Users,
  ChartLineUp,
  Question,
  HouseLine,
  X,
  Plus,
  Envelope,
  Trash,
  List,
  Info,
  Translate,
  GameController,
  MapTrifold,
  SpeakerHigh,
  SpeakerX
} from "phosphor-react";

import MetricCoin from "../components/Icons/MetricCoin";
import MetricPersonal from "../components/Icons/MetricPersonal";
import MetricSocial from "../components/Icons/MetricSocial";
import Hamburger from "../components/Icons/Hamburger";
import Inventory from "../components/Icons/Inventory";
import Language from "../components/Icons/Language";
import Help from "../components/Icons/Help";
import About from "../components/Icons/About";
import Home from "../components/Icons/Home";
import InventoryWork from "../components/Icons/InventoryWork";
import InventoryWorkSimple from "../components/Icons/InventoryWorkSimple";
import InventorySelfDev from "../components/Icons/InventorySelfDev";
import InventorySelfDevSimple from "../components/Icons/InventorySelfDevSimple";
import InventoryMisc from "../components/Icons/InventoryMisc";
import InventoryMiscSimple from "../components/Icons/InventoryMiscSimple";
import InventoryTools from "../components/Icons/InventoryTools";
import InventoryToolsSimple from "../components/Icons/InventoryToolsSimple";
import InventorySkills from "../components/Icons/InventorySkills";
import InventorySkillsSimple from "../components/Icons/InventorySkillsSimple";
import InventoryLocked from '../components/Icons/InventoryLocked';
import CategoryLabel from '../components/Icons/CategoryLabel';
import MapLock from '../components/Icons/MapLock';
import Map from '../components/Icons/Map';
import Metrics from '../components/Icons/Metrics';
import Characters from '../components/Icons/Characters';

export const icons = {
  MagnifyingGlass,
  ArrowRight,
  ArrowLeft,
  ArrowUp,
  ArrowDown,
  Stack,
  Coin,
  HeartStraight,
  Users,
  ChartLineUp,
  Question,
  HouseLine,
  X,
  Plus,
  Envelope,
  Trash,
  List,
  MapTrifold,

  MetricCoin,
  MetricPersonal,
  MetricSocial,

  Info,

  Translate,
  Hamburger,
  Inventory,
  Language,
  Help,
  GameController,
  About,
  Home,
  InventoryWork,
  InventoryWorkSimple,
  InventorySelfDev,
  InventorySelfDevSimple,
  InventoryMisc,
  InventoryMiscSimple,
  InventoryTools,
  InventoryToolsSimple,
  InventorySkills,
  InventorySkillsSimple,
  InventoryLocked,

  CategoryLabel,
  
  MapLock,

  SpeakerHigh,
  SpeakerX,

  Map,
  Metrics,
  Characters
};
