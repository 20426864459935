import React, { useState, useMemo, useEffect, useContext } from "react";
import * as allInvCardsData from "../data/translations/final/invCards/inventoryExports";
import * as allInvCardsData_hi from "../data/translations/final/invCards/inventoryExports_hi";
import * as allInvCardsData_or from "../data/translations/final/invCards/inventoryExports_or";
import * as allInvCardsData_te from "../data/translations/final/invCards/inventoryExports_te";
import * as allInvCardsData_ka from "../data/translations/final/invCards/inventoryExports_ka";
import * as allInvCardsData_gu from "../data/translations/final/invCards/inventoryExports_gu";
import * as allInvCardsData_as from "../data/translations/final/invCards/inventoryExports_as";
import LocalStorage from "../utils/LocalStorage";
import { EnvContext } from "./EnvContext";



const setAllInvCardsData = (activeLang, engData) => {
  const allLangInvCardsData = {
    en: allInvCardsData,
    hi: allInvCardsData_hi,
    te: allInvCardsData_te,
    ka: allInvCardsData_ka,
    or: allInvCardsData_or,
    gu: allInvCardsData_gu,
    as: allInvCardsData_as,
  };

  
  let data = JSON.parse(JSON.stringify(Object.values(allLangInvCardsData[activeLang] || allLangInvCardsData.en)));
  let combinedAry = [];

  data.forEach((perStoryCards) => {
    console.log({perStoryCards})
    Object.values(perStoryCards).forEach((d) => {
      let savedUnlockedIds = LocalStorage.getJSONItem("inventory");

      let toSet = {
        ...d,
        unlocked: savedUnlockedIds ? savedUnlockedIds.includes(d.id) : false,
      };
      combinedAry = [...combinedAry, toSet];
    });
  });
console.log({combinedAry})
  return combinedAry;
};

const initMetrics = {
  metricSocial: {
    id: "metricSocial",
    display: "Social Acceptance",
    icon: "MetricSocial",
    score: 0,
  },
  metricCoin: {
    id: "metricCoin",
    display: "Money",
    icon: "MetricCoin",
    score: 0,
  },
  metricPersonal: {
    id: "metricPersonal",
    display: "Satisfaction",
    icon: "MetricPersonal",
    score: 0,
  },
};

export const GameItemsContext = React.createContext();

export const GameItemsContextProvider = ({ children }) => {
  const { activeLang } = useContext(EnvContext);

  const [inventory, setInventory] = useState(
    setAllInvCardsData(activeLang.value, allInvCardsData)
  );
  useEffect(() => {
    setInventory(
      setAllInvCardsData(activeLang.value, allInvCardsData)
    );
  }, [activeLang]);

  const [metrics, setMetrics] = useState(
    JSON.parse(JSON.stringify(initMetrics))
  );
  const [lastAddedMetrics, setLastAddedMetrics] = useState(undefined);

  const categorizedInventory = useMemo(() => {
    let seg = {};
    
    inventory.forEach((d) => {
      console.log({d})
      let categoryId =
        d.inventoryCardProps.category?.toLowerCase().split(" ").join("") ||
        "uncategorised";
      let display = d.inventoryCardProps.category || "Uncategorised";
      if (seg[categoryId]?.data) {
        seg[categoryId].data.push(d);
      } else {
        seg[categoryId] = {
          display,
          categoryId,
          data: [d],
        };
      }
    });

    return seg;
  }, [inventory]);

  

  const resetMetrics = () =>
    setMetrics(JSON.parse(JSON.stringify(initMetrics)));

  const incrementMetrics = (option) => {
    if (!option.metrics) return;
    //else
    
    const { metrics: metricMods } = option;
    const newMetrics = { ...metrics };
    Object.values(newMetrics).forEach((metric) => {
      let newMetricVal = metricMods[metric.id];
      if (newMetricVal) {
        metric.score = metric.score + parseInt(newMetricVal);
      }
    });
    setLastAddedMetrics(metricMods);
    setMetrics(newMetrics);
    // LocalStorage.setJSONItem('metrics', newMetrics)
  };
  

  return (
    <GameItemsContext.Provider
      value={{
        inventory,
        setInventory,
        categorizedInventory,
        initMetrics, // DO NOT modify initMetrics, if you are using it, use it only for reading purposes. modifying can cause strange reference errors.
        metrics,
        setMetrics,
        resetMetrics,
        incrementMetrics,
        lastAddedMetrics,
      }}
    >
      {children}
    </GameItemsContext.Provider>
  );
};
