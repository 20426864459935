import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Button, IconButton } from "../../components/Button/index.js";
import { LeftRightNav } from "../../components/LeftRightNav/index.js";
import { getQueryParam } from "../../utils/general.js";
import LocalStorage from "../../utils/LocalStorage.js";
import "./style.js";
import PageContainer from "../../components/PageContainer";
import { PageTitle } from "../../components/blocks/Typo/index.js";
import { NavContext, useSetMainNav } from "../../contexts/NavContext.js";
import { EnvContext } from "../../contexts/EnvContext.js";
import { SoundContext } from "../../contexts/SoundContext";
import styled from "styled-components";
import { __LOC } from "../../utils/locale.js";

const LangButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const LanguageSelect = (props) => {
  const history = useHistory();
  const { pathname, state } = useLocation();
  const { setNav, nav } = useContext(NavContext);
  const { languageOptions, activeLang, setActiveLang } = useContext(EnvContext);

  const initLangSelectComplete =
    parseInt(LocalStorage.getItem("initLangSelect")) === 1;

  const {useControlUISounds} = useContext(SoundContext)
  useControlUISounds('Home')

  useEffect(() => {
    // no header if is in onboarding flow ( unset initLang suggests it is onboarding)
    if (!initLangSelectComplete) {
      setNav({
        left: [],
        right: [],
      });
    } else {
      setNav({
        left: [],
        right: [{ icon: "X", to: state?.startRoute || "/" }],
      });
    }
  }, []);

  const handleLangChange = (op) => {
    setActiveLang(op);
    LocalStorage.setJSONItem('activeLang', op)
    if (!initLangSelectComplete) {
      LocalStorage.setItem("initLangSelect", "1");
      history.push("/onboarding?step=0");
    }else{
      history.push(state?.startRoute || "/")
    }
  };

  return (
    <PageContainer>
      <div>
        <PageTitle>{__LOC('Select A Language')}</PageTitle>
        <LangButtonsWrapper>
          {languageOptions.map((op) => (
            <Button
              onClick={() => handleLangChange(op)}
              active={op.value === activeLang.value}
              style={{ width: "100%" }}
            >
              {op.display}
            </Button>
          ))}
        </LangButtonsWrapper>
      </div>
    </PageContainer>
  );
};
