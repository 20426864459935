export const invCardsData = {
    S1_inv1 : {
        id: 'S1_inv1',
        title : 'You just learnt about scholarships!',
        inventoryCardProps : {
          category : 'Miscellaneous',
          title : 'Scholarship',
          icon : 'InventoryMisc',
          desc : 'To continue her studies, Razia can apply for financial support/grants. These grants are called scholarships. They are provided to students who want to pursue higher studies but cannot afford them. Students do not have to pay back the money they get through scholarships. To apply for scholarships, you should search based on what is eligibility. Some criteria are course, marks, age limit, family incomes, special skills etc. The process includes filling up the application, submitting credentials and giving interviews before being selected for the scholarship.'
        }
    },
    S1_inv2 : {
        id: 'S1_inv2',
        title : 'You just gained research experience!',
        inventoryCardProps : { 
          category : 'Career Development Skills',
          title : 'Research',
          icon : 'InventorySkills',
          desc : 'Razia has searched the internet about the different colleges based on set criteria like colleges with hostel facilities. This process of finding out more information about a particular topic is called ‘Research’. You will probably come across an area or subject at various points in your career journey that you want to know about. You can get more information about that topic from the internet, friends, family, teachers, books, newspapers, and libraries.'
        }
    },
    S1_inv3 : {
        id: 'S1_inv3',
        title : 'You just gained negotiation experience!',
        inventoryCardProps : { 
          category : 'Career Development Skills',
          title : 'Negotiation',
          icon : 'InventorySkills',
          desc : 'Razia has to negotiate with her parents about going to the city to study. Negotiation is a discussion between people to reach an agreement on a decision when you have different points of view. We need to negotiate both in our personal and professional life. It requires you to be clear about what you are looking for and have a conversation with another person on why it is crucial for you. It also requires you to be open to listening and change your mind if what the other person is saying makes sense for you.'
        }
    },
    S1_inv4 : {
        id: 'S1_inv4',
        title : 'You just gained negotiation experience!',
        inventoryCardProps : { 
          category : 'Career Development Skills',
          title : 'Negotiation',
          icon : 'InventorySkills',
          desc : 'Razia has to negotiate a lot with her parents as she is the first girl in her family who wants to pursue higher education in science outside their village. There are different stages in negotiation, starting with preparation, discussion and agreement. Negotiation is not an argument. It would help if you were assertive but at the same willing to listen to the other person. Make a list of why your decision is good for you, the negative consequences, and how you will deal with those.'
        }
    },
    S1_inv5 : {
        id: 'S1_inv5',
        title : 'You just gained knowledge about Probation!',
        inventoryCardProps : { 
          category : 'Understanding Work Environment',
          title : 'Probation',
          icon : 'InventoryWork',
          desc : 'Raiza gets the job at the resort with a six month probation period. Probation is like a “trial period” at an organisation. During probation, the employer and the employee know if they are a good fit for the organisation and the role. Once the probation period is over, the employee can get a permanent job offer, or their contract ends based on their performance. As an employee, you can also choose or leave the job at that time.'
        }
    },
    S1_inv6 : {
        id: 'S1_inv6',
        title : 'You just gained upskilling experience!',
        inventoryCardProps : { 
          category : 'Career Development Skills',
          title : 'Upskilling',
          icon : 'InventorySkills',
          desc : 'To continue working in the eco-resort, Razia has to upskill her public speaking skills or lose her job. Upskilling means keeping yourself updated about new developments in your field of work. It is learning new skills from time to time. It means that even after you finish studying in school or college, you continue to learn - either through short courses, certification, workshops. Upskilling can open more growth possibilities for you in your career. You can upskill either by doing an online course, attending training, or workshops.'
        }
    },
    S1_inv7 : {
        id: 'S1_inv7',
        title : 'You just gained Understanding Self experience!',
        inventoryCardProps : { 
          category : 'Self Development',
          title : 'Understanding Self',
          icon : 'InventorySelfDev',
          desc : 'Razia uses the career planning tool to understand herself and apply for the right job. When thinking about college courses and career paths, an important aspect is to know about yourself. That is learning about your capabilities, your interests, what you are good at and your values. Before researching career options, it is helpful to spend time understanding yourself. Doing that can help you to plan and make career decisions that are right for you.'
        }
    },
    S1_inv8 : {
        id: 'S1_inv8',
        title : 'You just gained interview experience!',
        inventoryCardProps : { 
          category : 'Career Suitability Assessment Tools',
          title : 'Interview',
          icon : 'InventoryTools',
          desc : 'Razia appears in an interview for the NGO role and does well. An interview is a way for you to tell your employers or the other person about yourself and why you will be a good fit for the job. An interview means you share your learnings, aspirations, strengths, and why you are interested in the specific job. Being prepared for an interview is essential to help you be more confident in your answers about yourself and answer technical questions about the work.'
        }
    },
    S1_inv9 : {
        id: 'S1_inv9',
        title : 'You just gained upskilling experience!',
        inventoryCardProps : { 
          category : 'Career Development Skills',
          title : 'Upskilling in career',
          icon : 'InventorySkills',
          desc : 'To learn how to conduct research and write about it, Razia takes up a research and writing course online. She had to do this to be eligible for the new role. To learn a new skill is called upskilling. Upskilling is expanding your knowledge and enhancing your skills for a specific position or a new job. Sometimes organizations conduct training for all the employees, and sometimes you see an opportunity that you can apply for and upskill.'
        }
    },
    S1_inv10 : {
        id: 'S1_inv10',
        title : 'You just gained market study and trends experience!',
        inventoryCardProps : { 
          category : 'Understanding Work Environment',
          title : 'Market Study and Trends',
          icon : 'InventoryWork',
          desc : 'Razia studies the college brochure to find out the courses that have the most jobs. Researching the market and trends before selecting a particular course is to know which industry is doing well or not. A trend is a general direction in which something is developing or changing based on what is popular. You can know the market trends by reading newspapers, articles, talking to people to understand the opportunities available after completing the course, and understanding if the trend is only there for a short time.'
        }
    },
    S1_inv11 : {
        id: 'S1_inv11',
        title : 'You just gained internship experience!',
        inventoryCardProps : { 
          category : 'Career Suitability Assessment Tools',
          title : 'Internship',
          icon : 'InventoryTools',
          desc : 'Razia applies for an internship at an eco-resort while studying at the vocational training institute. An internship is a short-term work experience offered by companies, usually to students. An internship is a good opportunity to get exposure to the working environment related to your field of study, often within a specific industry. Each industry works differently. The best way to find an internship is by looking for someone who already works in that industry.'
        }
    },
    S1_inv12 : {
        id: 'S1_inv12',
        title : 'You just gained Understanding Self experience!',
        inventoryCardProps : { 
          category : 'Self Development',
          title : 'Understanding Self',
          icon : 'InventorySelfDev',
          desc : 'To prepare better for the interview, Razia lists her strengths and areas of improvement. It is an important aspect to know the places where she needs to work on for the interview. Before researching career options or preparing for interviews, it is helpful to understand yourself, know your strengths and work on your weaknesses. Doing that can help you to perform better.'
        }
    },
    S2_inv1 : {
      id: 'S2_inv1',
      title : 'You just gained college application process experience!',
      inventoryCardProps : { category : 'Career Suitability Assessment Tools',
        title : 'College Application Process',
        icon : 'InventoryTools',
        desc : 'To get into the college of her choice, Jhanvi has to go through many steps. Once you know what you want to study, applying to a college starts with researching the different colleges and their admission process. The admission process will vary depending on the course you decide to take. For example, the admission process for some professional courses like MBA has an entrance test, while a postgraduate degree of commerce will be merit based. If you choose to study in universities abroad, the process is entirely different, and you start the application process at least a year in advance.'
      }
  },
  S2_inv2 : {
      id: 'S2_inv2',
      title : 'You just gained entrepreneurship experience!',
      inventoryCardProps : { category : 'Understanding Work Environment',
        title : 'Entrepreneurship',
        icon : 'InventoryWork',
        desc : 'After college, Jhanvi and Anju decide to start a business that focuses on helping women become financially literate. Entrepreneurship is the ability and readiness to develop, organize and run a business. Being an entrepreneur requires you to have a mindset to take risks for making a profit. You can become an entrepreneur in any field. As an entrepreneur, you can decide if the business you have started is a small scale or you want to expand it.'
      }
  },
  S2_inv3 : {
      id: 'S2_inv3',
      title : 'You just gained negotiation experience!',
      inventoryCardProps : { category : 'Career Development Skills',
        title : 'Negotiation',
        icon : 'InventorySkills',
        desc : 'Jhanvi negotiates with Anju the next steps when considering closing the business due to the pandemic. She comes determined, but changes her mind after hearing the point of view of Anju. Negotiation is a discussion between people to reach an agreement on a decision when you have different points of view. It is about articulating your perspective and staying open-minded for healthy dialogue. Negotiation requires you to plan, listen, communicate properly and have an open mind to the other perspective. '
      }
  },
  S2_inv4 : {
      id: 'S2_inv4',
      title : 'You just gained negotiation experience!',
      inventoryCardProps : { category : 'Career Development Skills',
        title : 'Negotiation',
        icon : 'InventorySkills',
        desc : 'When the pandemic hits, Jhanvi and Anju are forced to relook at their business model. Jhanvi reworks the business model and is convinced that they should continue to make some changes. She talks to Anju to convince her. Negotiation is a discussion between people to reach an agreement on a decision when you have different points of view. It is about articulating your perspective and staying open-minded for healthy dialogue. Negotiation requires you to plan, listen, communicate properly and have an open mind to the other perspective.'
      }
  },
  S2_inv5 : {
      id: 'S2_inv5',
      title : 'You just gained aptitude test experience!',
      inventoryCardProps : { category : 'Career Suitability Tools',
        title : 'Aptitude Test',
        icon : 'InventoryTools',
        desc : 'When Jhanvi decides to take a job, she takes an aptitude test to know her abilities. The test helps Jhanvi to know what jobs to apply for. An aptitude test is designed to determine your ability in a particular skill or field of knowledge. However, the results only indicate a direction but should not be treated as the only path. Many companies give an aptitude test as the first round of selection for potential employees. You can take an aptitude test to know your strength and areas of improvement for a specific skill.'
      }
  },
  S2_inv6 : {
      id: 'S2_inv6',
      title : 'You just gained Job Portal experience!',
      inventoryCardProps : { category : 'Understanding Work Environment',
        title : 'Job Portal',
        icon : 'InventoryWork',
        desc : 'Jhanvi applies for the different jobs of her interest on a job portal. A job portal is a website that has information about different kinds of jobs. It is a place where one can look at the jobs listed and apply for the ones that are best suited for you. Most job portals allow you to create your profile and upload your resumes for free or for a small fee. Once you make the profile, it is easy for organisations to reach out to you if your profile matches their job opening.'
      }
  },
  S2_inv7 : {
      id: 'S2_inv7',
      title : 'You just gained career development plan experience!',
      inventoryCardProps : { category : 'Career Suitability Tools',
        title : 'Career Development Plan',
        icon : 'InventoryTools',
        desc : 'During the yearly review, Jhanvi and her manager discusses her career growth using a career plan. A Career Development Plan lists the short and long-term goals you want to achieve in your career journey. This helps understand what decisions you have to take to achieve the goals. These goals are linked to your strengths, interests and potential. You can create a plan by discussing it with an expert or your team lead or researching the career yourself.'
      }
  },
  S2_inv8 : {
      id: 'S2_inv8',
      title : 'You just gained internship experience!',
      inventoryCardProps : { category : 'Career Suitability Tools',
        title : 'Internship',
        icon : 'InventoryTools',
        desc : 'Jhanvi gets an opportunity to work at Morning Coffee Roasters as an intern in their marketing department. An internship is an opportunity to work for short-term work experience, and it is a way to learn more about a job and gain valuable workplace skills. Some organisations hire interns as employees at the end of the internship, which can be a good option if you are for looking for employment. '
      }
  },
  S2_inv9 : {
      id: 'S2_inv9',
      title : 'You just gained Networking experience!',
      inventoryCardProps : { category : 'Career Development Skills',
        title : 'Networking',
        icon : 'InventorySkills',
        desc : 'Jhanvi attends a start-up fair to meet like-minded people and learn from them. When you start working, meeting people and forming connections, they become an essential part of professional life. Networking requires you to be comfortable talking to people, staying in touch with them, and being interested in what other people are doing. Through the network of people you meet, you learn about what is happening in your industry, the market trends and get to know about work opportunities.'
      }
  },
  S2_inv10 : {
      id: 'S2_inv10',
      title : 'You just gained Mentorship experience!',
      inventoryCardProps : { category : 'Self Development',
        title : 'Mentorship',
        icon : 'InventorySelfDev',
        desc : 'When Jhanvi has to change her business model due to the pandemic, she reaches out to Sushant to discuss the issue. Sushant listens and guides on the possible paths she can take. A mentor is a guide who is more experienced than you, to whom you talk when you need clarity. You share your ideas and get feedback from your mentors. They keep your growth and development in mind and encourage you to do your best. Your mentor can be someone you know, a family member or someone from work. It is essential to find the right mentor and nurture the relationship.'
      }
  },
  S2_inv11 : {
      id: 'S2_inv11',
      title : 'You just gained job trends experience!',
      inventoryCardProps : { category : 'Understanding Work Environment',
        title : 'Job Trends',
        icon : 'InventoryWork',
        desc : 'To help Jhanvi get a job, Gunjan and Jhanvi decide to study the most trending jobs in the market. Job trends are popular directions in jobs in one industry or across industries, and they keep changing every few years. Based on the trends, the nature of the jobs also keep changing as every industry adapts itself to stay relevant. Knowing about the trends will help you understand what to expect over the next few years. You will also have to know about the trends and their impact on your career journey.'
      }
  },
  S2_inv12 : {
      id: 'S2_inv12',
      title : 'You just gained upskilling experience!',
      inventoryCardProps : { category : 'Career Development Skills',
        title : 'Upskilling',
        icon : 'InventorySkills',
        desc : 'To help her understand operations and supply chain management. Jhanvi decides to take a course as she feels this will help her in her business. This is called Upskilling. Upskilling is a way to learn a new skill or get certified for something specific that is required for a job or new role. During your career journey, you will face situations or come across roles where you realise that you need to acquire a new skill or learn something new. You can learn this new skill that can help you get a new job or a promotion.' 
      }
  },
  S2_inv13 : {
      id: 'S2_inv13',
      title : 'You just gained Research (for college) experience!',
      inventoryCardProps : { category : 'Career Development Skills',
        title : 'Research (for college)',
        icon : 'InventorySkills',
        desc : 'To help her find the right college, Jhanvi gets help from her father. He helps to identify and list what is essential for her when selecting the colleges. The list helps her in shortlisting the colleges about which she can read online. Research is done in many ways. To get the correct information, you might have to combine one or more forms of research. You can research many different ways like reading about it on the internet, speaking to people, reading books/newspapers among others.'
      }
  },
  S2_inv14 : {
      id: 'S2_inv14',
      title : 'You just gained networking and job portal experience!',
      inventoryCardProps : { category : 'Understanding Work Environment',
        title : 'Networking and Job Portal',
        icon : 'InventoryWork',
        desc : 'Due to the pandemic, Jhanvi decides to pause her business and take up a job. She decides to build a network through a job portal and uploads her resume. A job portal is a website that has information about different kinds of jobs you can apply to. There are some professional network portals/websites to connect with professionals from other industries. These connections are your professional network who can help you stay updated with the industry trends and recommend job opportunities available around them.'
      }
  },
      S2_inv15 : {
      id: 'S2_inv15',
      title : 'You just gained negotiation experience!',
      inventoryCardProps : { category : 'Career Development Skills',
        title : 'Negotiation',
        icon : 'InventorySkills',
        desc : 'Jhanvi negotiates with Anju the next steps when considering closing the business due to the pandemic. She comes determined and convinces Anju about finding a job instead. Negotiation is a discussion between people to reach an agreement on a decision when you have different points of view. It is about articulating your perspective and staying open-minded for healthy dialogue. Negotiation requires you to plan, listen, communicate properly and have an open mind to the other perspective. '
      }
  },
  S2_inv16 : {
      id: 'S2_inv16',
      title : 'You just gained negotiation experience!',
      inventoryCardProps : { category : 'Career Development Skills',
        title : 'Negotiation',
        icon : 'InventorySkills',
        desc : 'When the pandemic hits, Jhanvi and Anju are forced to relook at their business model. Jhanvi reworks the business model and is convinced that they should not continue with their business. She talks to Anju to convince her. Negotiation is a discussion between people to reach an agreement on a decision when you have different points of view. It is about articulating your perspective and staying open-minded for healthy dialogue. Negotiation requires you to plan, listen, communicate properly and have an open mind to the other perspective.'
      }
  },
  S3_inv1: {
    id: "S3_inv1",
    title: "You just learnt about Reflection with others!",
    inventoryCardProps: {
      category: "Self Development",
      title: "Reflection with others",
      icon: "InventorySelfDev",
      desc: "Naveen talks to his friends to get clarity about his options and analyse what to do next. Reflection is thinking about your actions and what you could do better and is primarily an independent activity. You can sometimes reflect with peers and friends and reason out the options with them to get clarity. While talking with friends/mentors, you need to know that you cannot share everything with them, but you need to be truthful about the information you share with them.",
    },
  },
  S3_inv2: {
    id: "S3_inv2",
    title: "You just gained research experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Research",
      icon: "InventorySkills",
      desc: "Naveen finds out about the ITI course when he talks to his friend. He later reads the course prospectus to expand his understanding. Reading to get information is a form of research. The world is changing at lightning speed. Research helps to find new ways to overcome difficulties and challenges. You can research by talking to people (teachers, family, people working in a particular field) or finding sources of information such as books and the internet.",
    },
  },
  S3_inv3: {
    id: "S3_inv3",
    title: "You just gained Apprenticeship experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Apprenticeship",
      icon: "InventorySkills",
      desc: "Naveen gets an apprenticeship at KPCL. Professions like Naveen’s and others like welding, carpentry often have apprenticeships. An apprenticeship is like an internship, where one learns by doing, getting exposed to the work environment and the everyday experience of the work. These can be six months to 2 years long. During an apprenticeship, you work with an experienced and skilled professional to learn the details of the profession.",
    },
  },
  S3_inv4: {
    id: "S3_inv4",
    title: "You just gained Informational Interview(Research) experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Informational Interview(Research)",
      icon: "InventorySkills",
      desc: "Naveen is curious to learn more from Sameer about what it means to be a freelancer and establish himself in the field. To do this, he treats the conversation like an interview and asks precise questions to ‘research’ this new career direction. In a discussion like this, it is essential to focus on the information you need and ask straightforward questions while keeping comfortable with the person you are interviewing.",
    },
  },
  S3_inv5: {
    id: "S3_inv5",
    title: "You just gained knowledge about Future of Work!",
    inventoryCardProps: {
      category: "Understanding Work Environment",
      title: "Future of Work",
      icon: "InventoryWork",
      desc: "Naveen feels that it is essential for him to keep up with the changing times to stay updated with the job opportunities by following the new work trends. As the world progresses, especially with new technologies and the internet, the type of jobs and how people work keeps changing. New opportunities unlock that are not available or known even a few months ago. Even if you are on a specific career path, it is essential to be open to new ways of working.",
    },
  },
  S3_inv6: {
    id: "S3_inv6",
    title: "You just gained Job Shadowing experience!",
    inventoryCardProps: {
      category: "Career Suitability Assessment Tools",
      title: "Job Shadowing",
      icon: "InventoryTools",
      desc: "Naveen works with Omi's father for a week, where Naveen follows and observes his work. This is called job shadowing. In job shadowing, you watch a professional while they do their work. Job shadowing will help you learn about the work they do and how they face different situations. Recording your observations while job shadowing will help you document your learning and have it for future reference. It can help you in your career decision-making process.",
    },
  },
  S3_inv7: {
    id: "S3_inv7",
    title: "You just gained Interview experience!",
    inventoryCardProps: {
      category: "Career Suitability Assessment Tools",
      title: "Interview",
      icon: "InventoryTools",
      desc: "Naveen gives an interview at the Alliance Digital store. An interview is usually part of the process of getting a job. It is a formal meeting to know if the applicant is suited for the role or not. It is essential to prepare for the interview and understand your aspirations for the job and the technical knowledge required for the position. During the interview as an applicant, you can better understand the job role and how the job will fit your career goals.",
    },
  },
  S3_inv8: {
    id: "S3_inv8",
    title: "You just gained Walk-in Interview experience!",
    inventoryCardProps: {
      category: "Career Suitability Assessment Tools",
      title: "Walk-in Interview",
      icon: "InventoryTools",
      desc: "Naveen walks in for an interview when he decides to apply for the pizza delivery agent job. Walk-in interviews are not scheduled beforehand. You can walk into the interview venue and wait for your turn, provided you have the right qualifications. Depending on the role, the interview process can be an interview or a test followed by the interview. You need to know that interviews are also a space for you to ask questions and get clarity on the nature of the job, hours of work, benefits and pay.",
    },
  },
  S3_inv9: {
    id: "S3_inv9",
    title: "You just gained Internship experience!",
    inventoryCardProps: {
      category: "Career Suitability Assessment Tools",
      title: "Internship",
      icon: "InventoryTools",
      desc: "Naveen gets an internship with Vishnu Sir. An internship is an opportunity to work in an organisation on a specific project/role for a short period. An intern usually reports to a supervisor who acts as a mentor/coach. It is the closest way to learn more about a job and gain valuable workplace skills. An internship can be quite helpful when looking for new jobs. You can also look for an internship if you need to make a career shift.",
    },
  },
  S3_inv10: {
    id: "S3_inv10",
    title: "You just gained Upskilling experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Upskilling",
      icon: "InventorySkills",
      desc: "Naveen joins MoveMore as a fitness sales assistant. As part of his job, he needs to know about the different sports equipment and fitness techniques. He also attends sports training and sports meet to know more. As you start working or look to change your role, you might realise that you need to improve or learn a new skill. Upskilling will help you learn a new skill or get certified in a specific area. You can upskill to better in your current job or to apply for a new role.",
    },
  },
  S3_inv11: {
    id: "S3_inv11",
    title: "You just gained Networking and Job Opportunity experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Networking and Job Opportunity",
      icon: "InventorySkills",
      desc: "For Naveen, meeting Judge Sir and getting feedback on his dance form is how he builds a network. This is called Networking. It is a skill to start and develop meaningful professional relationships. These connections can sometimes lead to opportunities for growth in your career ladder. A network also helps in knowing what is happening in your professional circle. You can network by meeting people at an event, social media, meeting people in the neighbourhood or alumni at your institute.",
    },
  },
  S3_inv12: {
    id: "S3_inv12",
    title: "You just gained Recommendation Letter experience!",
    inventoryCardProps: {
      category: "Understanding Work Environment",
      title: "Recommendation Letter",
      icon: "InventoryWork",
      desc: "Vishnu Sir gives a letter of recommendation to Naveen when there is an opening in a dance studio. While interviewing, employers look for the right candidate who can fit into their organizations. A recommendation letter talks about the skills, character, experiences, and achievements of a person. If you have a recommendation from your previous employer, it strengthens your application. Whenever you do an internship or a job, try and get a recommendation letter at the end for future job applications.",
    },
  },
  S3_inv13: {
    id: "S3_inv13",
    title: "You just gained Informational Interview experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Informational Interview",
      icon: "InventorySkills",
      desc: "Naveen talks to people who are working to know about the job and their career journey. To build an understanding of what each position requires, you can treat these conversations like an interview. It is also a form of research where you asks specific questions. In a discussion like this, you can share the questions you will ask them beforehand. It will help you focus on the information you need while keeping the person you are interviewing comfortable.",
    },
  },
  S3_inv14: {
    id: "S3_inv14",
    title: "You just gained Market Trends experience!",
    inventoryCardProps: {
      category: "Understanding Work Environment",
      title: "Market Trends",
      icon: "InventoryWork",
      desc: "Before applying for a job, Naveen studies the emerging trends and about stable jobs. It is essential to know the various job trends, how we work and live changes every few years with the advancement of technology. With this, the available jobs and career paths also change. When choosing a career path, it is essential to study trends and changes in the market while deciding.",
    },
  },
  S3_inv15: {
    id: "S3_inv15",
    title: "You just gained Networking experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Networking 3",
      icon: "InventorySkills",
      desc: "Naveen reaches out to Ms Sinha when he starts looking for a new job. This is a form of Networking. Meeting people and making connections are essential parts of professional life. Networking requires you to be comfortable talking to people, staying in touch with them, and being interested in what other people are doing. Networking will also help you know the latest trend in the industry.",
    },
  },
  S3_inv16: {
    id: "S3_inv16",
    title: "You just gained Experimentation experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Experimentation",
      icon: "InventorySkills",
      desc: "When Naveen does not get the government job after his apprenticeship, he decides to relook his career choice. He wants to find a job where he can balance both his interests and earn good money. Sometimes in your career journey you will work in different jobs before you find the job that balances your interest, your abilities and your need. Internships and apprenticeships give you a chance to experiment with different jobs before you find the right fit for you.",
    },
  },
  S3_inv17: {
    id: "S3_inv17",
    title: "You just gained Experimentation experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Experimentation",
      icon: "InventorySkills",
      desc: "Naveen starts his career exploration first through dance and moves to becoming a mechanic as he wants a stable job. He does an apprentinship to get enough experience to qualify for government jobs. When he does not get the government job, he decides to do something on his own. Naveen experiments and explores with these jobs and they help him understand the job best suited for him. One of the ways you can explore other options in your career journey is by looking for opportunities to work either over the weekend or part-time.",
    },
  },
  S4_inv1: {
    id: "S4_inv1",
    title: "You just gained Experimentation experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Experimentation",
      icon: "InventorySkills",
      desc: "Shikhar learns many things using technology (his smartphone), like making videos and learning online recipes. Experimentation here means being open to learning about different things, trying other activities. You can also experiment with learning through different mediums using technology by watching videos, reading online, taking up courses online, understanding how different apps work, or trying new experiences in the physical world.",
    },
  },
  S4_inv2: {
    id: "S4_inv2",
    title: "You just gained reflection by writing experience!",
    inventoryCardProps: {
      category: "Self Development",
      title: "Reflection by writing",
      icon: "InventorySelfDev",
      desc: "Shikhar writes his thoughts in a journal. Writing them down allows him to think about his actions. Reflection by writing is processing your thoughts and feelings by documenting them. It is one way to reflect on your values, strengths, weaknesses, and why you think and act in specific ways. The purpose of reflection is to help you understand yourself better. This gives you a chance to look at things from a distance and at the same time go deep into them. You can reflect on a particular incident, a difficult situation, day, or even daily by maintaining a journal/diary.",
    },
  },
  S4_inv3: {
    id: "S4_inv3",
    title: "You just gained Research (Open house) experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Research (Open house)",
      icon: "InventorySkills",
      desc: "Shikhar attends an open house at the university to learn about the different courses offered by the university. This is Shikhar's approach to research. When you want to find more information about a new topic, you find ways to gather information and this is called research. Open houses and events are a way to research more about a course or college by talking to faculty and students and getting reading material from colleges on the different departments and facilities for students. You need to ensure that you are talking to the right college representatives to get verified information.",
    },
  },
  S4_inv4: {
    id: "S4_inv4",
    title: "You just gained group discussion experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Group Discussion",
      icon: "InventoryTools",
      desc: "As part of the college admission process, Shikhar participates in a group discussion. Group discussion is a critical communication skill you need to develop as you interact with different people while studying and at work. Many colleges and organisations use group discussion to understand your personality and how you behave in a group. This discussion improves your thinking, listening and speaking skills. You can also have a group discussion with your friends or peers.",
    },
  },
  S4_inv5: {
    id: "S4_inv5",
    title: "You just gained informational interview Experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Informational Interview",
      icon: "InventoryTools",
      desc: "To know about the nutrition course, Shikhar talks to Rupali. Such a process of questioning is called an informational interview. An informational interview is a conversation you can have with someone working in an area that is of interest to you. These conversations can be formal or informal, depending on your relationship with the person you are talking to. These interviews give you exposure to a particular industry and help you make informed decisions.",
    },
  },
  S4_inv6: {
    id: "S4_inv6",
    title: "You just gained Internship experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Internship",
      icon: "InventoryTools",
      desc: "While doing his nutrition course, Shikhar works at the Indore General Hospital in the nutrition department. An internship is an opportunity to work in an organisation on a specific project/role for a short period. An intern usually reports to a supervisor who acts as a mentor/coach. It is the closest way to learn more about a job and gain valuable workplace skills. You can do your internship while studying, as part of the course or after completing your course.",
    },
  },
  S4_inv7: {
    id: "S4_inv7",
    title: "You just gained Reflection experience!",
    inventoryCardProps: {
      category: "Self Development",
      title: "Reflection",
      icon: "InventorySelfDev",
      desc: "After the internship, Shikhar reflects on his experience and documents his thoughts. Reflection is processing your thoughts and feelings. You can reflect on your day or a specific situation by writing it down in a journal or sitting quietly with your thoughts. The process of reflection will help you understand yourself better and give you insights into how to handle situations. These insights will guide your further actions. For some being reflective comes naturally, and for some, you will have to practice and learn how to reflect.",
    },
  },
  S4_inv8: {
    id: "S4_inv8",
    title: "You just gained Internship experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Internship",
      icon: "InventoryTools",
      desc: "An internship is an opportunity to work in an organisation on a specific project/role for a short period. An intern usually reports to a supervisor who acts as a mentor/coach. It is the closest way to learn more about a job and gain valuable workplace skills. An internship can be quite helpful when looking for new jobs. You can also look for an internship if you need to make a career shift.",
    },
  },
  S4_inv9: {
    id: "S4_inv9",
    title: "You just gained Trainee experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Trainee",
      icon: "InventoryTools",
      desc: "After the hotel management course, Shikhar gets a job as a management trainee. A trainee is generally a new employee of the organisation. When you join an organisation as a trainee, you get trained on the job, and the training can last a month to a few months. Most trainees generally join the organisation as entry-level professionals.",
    },
  },
  S4_inv10: {
    id: "S4_inv10",
    title: "You just gained Placement Process experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Placement Process",
      icon: "InventoryTools",
      desc: "Shikhar applies for a job in the finance field through his college placement cell. The placement cell helps students get placed in different companies. They do this through their placement process, where companies visit colleges for recruitment. The process is an elaborate one that includes written tests and several rounds of interviews. Most colleges have a placement cell that helps students through this process. You have a choice if you want to get a job through the college placement process or on your own. ",
    },
  },
  S4_inv11: {
    id: "S4_inv11",
    title: "You just gained Internship experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Internship",
      icon: "InventoryTools",
      desc: "Shikhar works in the 4 Seasons Hotel as an intern during his Hotel management course. An internship is an opportunity to work in an organisation on a specific project/role for a short period. An internship helps you understand the job and decide if you want to pursue a career in that field. It gives an opportunity to meet and connect with new people. It is essential to be proactive during your internship. You could come up with ideas on how you can help at the workplace alongside being punctual and completing tasks given well.",
    },
  },
  S4_inv12: {
    id: "S4_inv12",
    title: "You just gained Employee Referrals experience!",
    inventoryCardProps: {
      category: "Understanding Work Environment",
      title: "Employee Referrals",
      icon: "InventoryWork",
      desc: "Shikhar gets a job in a company after Devansh's cousin refers him for the role of a Associate financial advisor.  Employee referrals is a way to get a job.  If you know a person working in an organisation for some time and you qualify for a job opening there, they can recommend you for it . When you apply for a job through employee referall, there is a higher chance that their resume will be seen by the recruitment team.  Even though you apply to the job through referral, you will have to go through the same selection process.",
    },
  },
  S4_inv13: {
    id: "S4_inv13",
    title: "You just gained Mentorship experience!",
    inventoryCardProps: {
      category: "Self Development",
      title: "Mentorship",
      icon: "InventorySelfDev",
      desc: " Professor Nag helps Shikhar in his journey of becoming a farmer. She guides him whenever necessary. A mentor is someone more experienced than you, to whom you talk when you need clarity. Working with a mentor can be an invaluable experience for both the mentor and the mentee. The mentor plays the role of an advisor or a coach. They can also be someone who helps you plan your career journey. A mentor keeps your growth and development in mind and encourages you to do your best. It is your role to share your ideas and get feedback.",
    },
  },
  S4_inv14: {
    id: "S4_inv14",
    title: "You just gained Career Map experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Career Map",
      icon: "InventorySkills",
      desc: "When Shikhar is confused about studying further or continuing with a job, Prof. Nag maps a career journey to help him understand the two options. Career maps are a written plan outlining where you are currently and where you ultimately want to go. It also details the specific steps you could take to reach that objective. These maps help both the employers and employees as it offers both a clear path. When you have a map of your career journey, it will help you identify your strengths and areas of improvement. You can revisit your career map at any point in your career journey and make a new one if you choose new or different career paths.",
    },
  },
  S5_inv1: {
    id: "S5_inv1",
    title: "You just gained Discussion with significant others (family) experience!",
    inventoryCardProps: {
      category: "Self Development",
      title: "Discussion with significant others (family)",
      icon: "InventorySelfDev",
      desc: "Mira discusses with Neetu Aunty and Ranjan Uncle the subjects she wants to take after her 10th standard. She feels she will get clarity if she talks to them and listens to their point of view. When you want to discuss before making a decision, you can discuss the situation with your family, friends or your mentor. Talking to them will help you verbalise your thought process and also get another opinion. You must also know that their views might come from their biases when discussing any situation with others. While valuing their option, make sure you think critically before making a decision. ",
    },
  },
  S5_inv2: {
    id: "S5_inv2",
    title: "You just gained aptitude test experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Aptitude Test",
      icon: "InventoryTools",
      desc: "Mira takes the aptitude test to know her skills and strengths and decide her subjects accordingly. An aptitude test helps you determine your ability or potential to succeed in a specific task without acquiring prior knowledge or training. The results from the aptitude tests can guide you to make decisions like choosing the proper subjects in college. The test results may give you direction, but the final decision should be taken by keeping in mind other factors around you, like your interest, eligibility and financial situation.",
    },
  },
  S5_inv3: {
    id: "S5_inv3",
    title: "You just gained career fair experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Career Fair",
      icon: "InventoryTools",
      desc: "When Mira is confused about the different entrance exams and what colleges to apply to, her aunt suggests attending a college fair to get clarity. At the college fair, you can get first-hand information about the different colleges, their courses, and their facilities for students. You will also get information about their admission procedures. You can talk to the college representatives about the courses and facilities in the college. It is also an event where you can connect with other students.",
    },
  },
  S5_inv4: {
    id: "S5_inv4",
    title: "You just gained negotiation at the workplace experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Negotiation at the worksplace",
      icon: "InventorySkills",
      desc: "While working in the automobile factory, Mira's supervisor gives her project to another team member. Mira is upset and talks to her supervisor about leading the project. Negotiation is a discussion between people to reach an agreement on a decision when you have different points of view. While negotiating in professional settings, you need to plan and convey your points of view. Some negotiations may go in your favour, and sometimes it may not. It is important to be calm and composed at the time of such negotiations.",
    },
  },
  S5_inv5: {
    id: "S5_inv5",
    title: "You just gained knowledge about Future of Work!",
    inventoryCardProps: {
      category: "Understanding Work Environment",
      title: "Future of Work",
      icon: "InventoryWork",
      desc: "Naveen feels that it is essential for him to keep up with the changing times to stay updated with the job opportunities by following the new work trends. As the world progresses, especially with new technologies and the internet, the type of jobs and how people work keeps changing. New opportunities unlock that are not available or known even a few months ago. Even if you are on a specific career path, it is essential to be open to new ways of working.",
    },
  },
  S5_inv6: {
    id: "S5_inv6",
    title: "You just gained Discussion with significant others (family) experience!",
    inventoryCardProps: {
      category: "Self Development",
      title: "Discussion with significant others (family)",
      icon: "InventorySelfDev",
      desc: "When Mira gets an opportunity to work in Nashik, she discusses this with Rajan uncle. When you want clarity before making a decision, you can discuss it with a person who knows you and would be the right person to guide you. Talking to them will help you verbalise your thought process and also get another opinion. While valuing their opinion, you have to think critically and make your decisions.",
    },
  },
  S5_inv7: {
    id: "S5_inv7",
    title: "You just gained Trends experience!",
    inventoryCardProps: {
      category: "Understanding Work Environment",
      title: "Trends",
      icon: "InventoryWork",
      desc: "Mira decides to study engineering in Computer Science as it is a popular course for getting good-paying jobs. Mira is following trends. Trends are directions that are popular at a given point in time. You can research about trends before selecting a course and a job. As trends keep changing every few years and jobs change to keep up with the trends, every industry adapts itself to stay relevant. When selecting trends courses, you should choose them because you are interested in them and not follow the trend blindly.",
    },
  },
  S5_inv8: {
    id: "S5_inv8",
    title: "You just gained internet Research for jobs experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Internet Research for jobs",
      icon: "InventorySkills",
      desc: "While helping her brother, Mira gets interested in exploring her programming skills in game design, which is new to her. Before she shifts to the new field, she thoroughly researches on the Internet about the kinds of jobs available in the gaming sector where she can use her skill set and experience. Finding out more information about a particular topic on the web is called ‘Internet Research’. While researching on the Internet, you need to make sure that the source of information is correct. You can read articles/blogs, watch videos, read a published paper to know more about your topic.",
    },
  },
  S5_inv9: {
    id: "S5_inv9",
    title: "You just gained Negotiation at work place experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Negotiation at work place",
      icon: "InventorySkills",
      desc: "When Mira applies for the role of a physics programmer, she is offered a junior position. Mira does not want to accept a junior role as she already has experience. She discusses the same with the interviewer, and they convince her to start with a junior position. Negotiation is a discussion between people to reach an agreement on a decision when you have different points of view. It is important to do research before going into a career negotiation. You need to plan, practise and convey your point of view confidently. Some negotiations may go in your favour, and sometimes it might go against you.",
    },
  },
  S5_inv10: {
    id: "S5_inv10",
    title: "You just gained Upskilling experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Upskilling",
      icon: "InventorySkills",
      desc: "Mira wants to adapt her coding skills to create games. This is a new area for her. She finds a lot of videos tutorials online and uses them to upskill herself. When you start working, there might be a point in your career journey when you want to change your role or learn something new as part of your job. Upskilling will help you learn a new skill or get certified in a specific area. You can upskill to be better in your current job, to apply for a new role, or to keep yourself updated on recent trends.",
    },
  },
  S5_inv11: {
    id: "S5_inv11",
    title: "You just gained Job Shadowing experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Job Shadowing",
      icon: "InventoryTools",
      desc: "Mira spends a day observing Lina's aunt at a medical camp, which helps Mira decide to pursue a career in medicine. This is called job shadowing. Job shadowing can help you with your career decision making process by getting a close look at the problems faced by people in that role and the attitude required for that role. It is good to prepare a few questions in advance for what questions you wish to ask the person you shadow.",
    },
  },
  S5_inv12: {
    id: "S5_inv12",
    title: "You just gained Career Map experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Career Map",
      icon: "InventorySkills",
      desc: "After Mira doesn't clear her entrance exams for Medicine, she decides to explore her career options if she studies B.Sc. Her aunt maps the career possibilities if she chooses to pursue B.Sc. Physics. The map helps Mira make the decision. A career map is a detailed plan outlining what could be a possible career path. It will detail where you are currently in your career or studies and where you would like to end up. This map will also include the specific steps you need to take to reach your ultimate objective.",
    },
  },
  S5_inv13: {
    id: "S5_inv13",
    title: "You just gained career journey map experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Career Journey Map",
      icon: "InventorySkills",
      desc: "In her final year of MBBS, a senior doctor helps Mira map her career plan once she completes her MBBS.  A career map is a detailed plan outlining what could be a possible career path. It will detail where you are currently in your career or studies and where you would like to end up. This map will also include the specific steps you need to take to reach your ultimate objective. You can make your own career map/plan or do it with the help of a senior at work, your mentor or a career counsellor.",
    },
  },
  S5_inv14: {
    id: "S5_inv14",
    title: "You just gained Success story experience!",
    inventoryCardProps: {
      category: "Miscellaneous",
      title: "Success Story",
      icon: "InventoryMisc",
      desc: "Mira listens to Dr Nidhi Sharma's journey, which is a turning point in her career. She takes her B.Sc. degree seriously and works hard. Success stories are essential learning tools. They encourage you to keep following your dreams and keep you believing that you too can achieve your goals. They give you the motivation to believe in your journey.",
    },
  },
  S5_inv15: {
    id: "S5_inv15",
    title: "You just gained future job trends experience!",
    inventoryCardProps: {
      category: "Understanding Work Environment",
      title: "Future Job Trends",
      icon: "InventoryWork",
      desc: "Mira's dad loses his job in the factory, and to get his job back, he needs to learn how to work using a computer. As the world progresses, especially with new technologies and the internet, the type of jobs and how people work keeps changing. A lot of new opportunities requires basic computer literacy. Even if you are on a specific career path, it is essential to be open to new ways of working.",
    },
  },
  S5_inv16: {
    id: "S5_inv16",
    title: "You just gained informational interview experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Informational Interview",
      icon: "InventoryTools",
      desc: "When Mira gets an opportunity to go to France to study further, she talks to another person who had gone there to learn about her experience doing the course. These conversations are a form of research. You generally ask directed questions to a person about the topic you want information about. These discussions are called informational interviews. These can be informal interviews or formal conversations. To get the best out of these interviews, you can share the questions with the person earlier. It is important to prioritise which questions to ask to make the most of the time you have to talk with the person.",
    },
  },
  S6_inv1: {
    id: "S6_inv1",
    title: "You just gained negotiation with your close ones experience!",
    inventoryCardProps: {
      category: "Self Development",
      title: "Negotiation with your close ones",
      icon: "InventorySelfDev",
      desc: "Revathy wants to attend the Kabbadi camp, while her family wants her to participate in the training camp. She talks to her family about why the camp is essential for her. Negotiation is a discussion between people to reach an agreement on a decision when you have different points of view. Negotiation can be with your close ones or in a professional space. You need to be articulate about your thoughts and, at the same time, be open to listening and change your mind if what the other person is saying makes sense for you.",
    },
  },
  S6_inv2: {
    id: "S6_inv2",
    title: "You just gained talk to an expert experience!",
    inventoryCardProps: {
      category: "Self Development",
      title: "Talk to an Expert",
      icon: "InventorySelfDev",
      desc: "When Revathy gets rejected from the sports academy, the coach explains how sports can still help her in the future. She guides Revathy to continue playing Kabaddi as it would help her get admission under the sports quota for different colleges. Speaking with experts is helpful to understand a field of knowledge thoroughly and get another perspective. They will help you prioritise and guide you to focus on things that are worth focusing on.",
    },
  },
  S6_inv3: {
    id: "S6_inv3",
    title: "You just gained Research experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Research",
      icon: "InventorySkills",
      desc: "After hearing about the sports quota from the coach, Revathy is interested to know more about it and finds out more by talking to someone from a college near the Kabbadi camp. This process of finding out more information about a particular topic is called ‘Research’. You will probably come across an area or subject you want to know about at various points in your career journey. To learn more about a topic, you can go and meet people/visit a space where you can understand a matter well.",
    },
  },
  S6_inv4: {
    id: "S6_inv4",
    title: "You just gained interview for college admission experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Interview for college admission",
      icon: "InventoryTools",
      desc: "Revathy applies to college through sports quota. The selection process includes playing in the selections trials and a round of interviews. An interview is a way for you to tell the other person about yourself. College interviews are meant to help you and the interviewer find out if the college is a good match for you and vice versa. These interviews are an opportunity for you to learn about yourself and and your interests and how you can contribute to the college. Being prepared for an interview is essential to help you be more confident. College interviews are not part of all admission procedure.",
    },
  },
  S6_inv5: {
    id: "S6_inv5",
    title: "You just gained managing failures experience",
    inventoryCardProps: {
      category: "Self Development",
      title: "Managing failures",
      icon: "InventorySelfDev",
      desc: "Revathy does not get selected to play for the University Kabbadi team. This is the second time she has missed her chance to play for a bigger team/group. She is devastated, and her family help her to see what she has learnt from sports and use it in her life. There will be situations you face in life that will make you feel like you failed. You will have to form your healthy way of dealing with failure. Accept that you feel bad about it and see if you have learned something from the situation and how you can move forward. To help you move forward, talk about your feelings and experiences with someone you are comfortable sharing.",
    },
  },
  S6_inv6: {
    id: "S6_inv6",
    title: "You just gained job description experience!",
    inventoryCardProps: {
      category: "Understanding Work Environment",
      title: "Job description",
      icon: "InventoryWork",
      desc: "Revathy sees a poster of a job at a travel agency. Revathy has to read the job description to know about the role of a travel executive. A job description (JD) provides the job applicants with an outline of the primary duties and responsibilities of the role they are applying for. Reading the job description will give you the correct information. It will also help you determine whether the position aligns with your skillset and whether it is a job that you want to do.",
    },
  },
  S6_inv7: {
    id: "S6_inv7",
    title: "You just gained cover letter experience!",
    inventoryCardProps: {
      category: "Understanding Work Environment",
      title: "Cover Letter",
      icon: "InventoryWork",
      desc: "While applying for the job at the travel agency, Revathy mails HR her resume and a cover letter. A cover letter is a document that you send with your resume. It provides additional information about skills and experiences related to the job you have applied for. The cover letter lets you show who you are and other details on your skills not covered in the CV. Even though many employers do not expect a cover letter when submitting your CV, it is always better to write a cover letter to let the employers know a little more about you.",
    },
  },
  S6_inv8: {
    id: "S6_inv8",
    title: "You just gained job interview experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Job Interview",
      icon: "InventoryTools",
      desc: "Revathy gets a call from the travel agency to attend an interview. An interview is part of a selection process and it is an improtant process as it gives the employer to asess if you will be a good fit for the job and the empolyee to know about the position and the culture of the organisation. A job interview can be formal or casual depending on the organisation. Some organisations have mutiple rounds of interview with different people from different teams before making the decision.",
    },
  },
  S6_inv9: {
    id: "S6_inv9",
    title: "You just gained Upskilling experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Upskilling",
      icon: "InventorySkills",
      desc: "Revathy attends the workshop conducted by LIFE at Pondicherry and learns the skill of weaving baskets. When you acquire a new skill, it is called upskilling. Generally, you upskill because your job demands it. Sometimes you can learn something because you want to develop that particular skill. The skill may or may not result in a better job or more money from the job. They might come in handy at some point in life, or these skills might shape your personality.",
    },
  },
  S6_inv10: {
    id: "S6_inv10",
    title: "You just gained Recommendation Letter experience!",
    inventoryCardProps: {
      category: "Understanding Work Environment",
      title: "Recommendation Letter",
      icon: "InventoryWork",
      desc: "Sayeeda encourages Revathy to restart her sports career and gives a recommendation letter to meet her coach. A letter of recommendation is a formal letter that endorses your skills, potential and experience. You can get a letter of recommendation from your previous manager when moving jobs and from your mentor or professors when applying to colleges. Getting a recommendation letter from your senior or mentor, or colleague is a validation of your work and skill. While the employer will assess you on your skills and attitude you will learn about the different policies which will help you make the decision if you get the job.",
    },
  },
  S6_inv11: {
    id: "S6_inv11",
    title: "You just gained Networking experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Networking",
      icon: "InventorySkills",
      desc: "Revathy reaches out to Chitra and Varshini when she wants to start working to support Mani. She reaches out to the people with whom she has formed a connection through work. These connections are called networks. It is a skill to start and develop meaningful professional relationships. These connections can sometimes assist you in getting jobs by sharing the opportunities they know of from their professional network. You can build your network by meeting people at an event, social media, meeting people in the neighbourhood or alumni at your institute.",
    },
  },
  S6_inv12: {
    id: "S6_inv12",
    title: "You just gained Internship experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Internship",
      icon: "InventoryTools",
      desc: "Revathy gets an opportunity to do a 3-month internship with coach Bina. An internship is an excellent opportunity to gain exposure to the working environment related to your field of study. When you want to shift your career path into a new area, an internship can assist you in getting a foothold in the new industry. Each industry works differently, so the internship durations also varies from 1 month to 6 months. You can do an internship while studying or after your studies.",
    },
  },
  S6_inv13: {
    id: "S6_inv13",
    title: "You just gained Internship experience!",
    inventoryCardProps: {
      category: "Career Suitability Tools",
      title: "Internship",
      icon: "InventoryTools",
      desc: "After her 12th standard, Revathy goes to Pondicherry to work at the centre set up by LIFE. An internship is an opportunity to work for short-term work experience. It is a way to gain valuable workplace skills. An intern is usually at an entry-level, either in a specific role or in all the departments. During an internship, you get a chance to work closely with a supervisor/mentor/project lead.",
    },
  },
  S6_inv14: {
    id: "S6_inv14",
    title: "You just gained Upskilling experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Upskilling",
      icon: "InventorySkills",
      desc: "At the end of Revathy's internship with coach Bina, she offers Revathy to be part of the team if she learns the basics of data analysis. Revathy decides to attend a 2-month boot camp to learn about data analysis. To learn a new skill is called upskilling. Upskilling is expanding your knowledge and enhancing your skills for a specific position or a new job. You can upskill by different methods like a camp, a course or on the job.",
    },
  },
  S6_inv15: {
    id: "S6_inv15",
    title: "You just gained Upskilling experience!",
    inventoryCardProps: {
      category: "Career Development Skills",
      title: "Upskilling",
      icon: "InventorySkills",
      desc: "At the end of Revathy's internship with coach Bina, she gives an opportunity to Revathy to be part of the team if she learns the basics of data analysis. Revathy decides to attend a 6-month online course to learn about data analysis. To learn a new skill is called upskilling. Upskilling is expanding your knowledge and enhancing your skills for a specific position or a new job. To learn a new skill is called upskilling. You can expand your knowledge and improve your skills in a particular place or a new job.",
    },
  },
  S7_inv1 : {
    id: 'S7_inv1',
    title : 'You just gained Success Stories experience!',
    inventoryCardProps : {
      category : 'Self Development',
      title : 'Success Stories',
      icon : 'InventorySelfDev',
      desc : "Listening to Prakruti Ma'am's journey of becoming a police officer inspires Madhav, and it becomes the turning point of his life. Collecting stories of professionals who have succeeded in their professions could act as a motivating tool and an informative resource. Success stories are stories of people who have been doing well in a particular field, who have been adequately trained and have worked in the industry for a significant period. Gathering inputs from people of this kind will provide inputs about a particular career and give you an overall picture of the industry."
    }
},
S7_inv2 : {
    id: 'S7_inv2',
    title : 'You just gained research experience!',
    inventoryCardProps : { 
      category : 'Career Development Skills',
      title : 'Research',
      icon : 'InventorySkills',
      desc : 'Madhav spends a lot of time getting information about civil services and its examinations. This process of obtaining information about a topic is called research. Research opens up new pathways to doing things differently. It helps an individual to make connections between ideas and actions. Research provides new ways to overcome difficulties and challenges. It helps to gather resources that can help you achieve your goals. Research can be in many forms – interview with a professional,  visit websites and articles related to the field, trip to an industry/a workplace, higher education.'
    }
},
S7_inv3 : {
    id: 'S7_inv3',
    title : 'You just gained interview experience!',
    inventoryCardProps : { 
      category : 'Career Suitability Tools',
      title : 'Interview',
      icon : 'InventoryTools',
      desc : 'Madhav appears for the interview round, which is the last round in the UPSC selection process. An interview is usually part of the process of getting a job. It is a formal meeting to know if the applicant is suited for the role or not. During the interview, the panel asks questions, who assess you on how you answer the questions. Your goal is to present yourself as the best candidate and answer the questions honestly and professionally.'
    }
},
S7_inv4 : {
    id: 'S7_inv4',
    title : 'You just gained success stories experience!',
    inventoryCardProps : { 
      category : 'Self Development',
      title : 'Success Stories',
      icon : 'InventorySelfDev',
      desc : 'When Madhav is planning for alternative careers, he reads a book on popular individuals in different fields. Reading these success stories gives Madhav the inspiration to look at a career beyond Civil Service. Reading about people who have fought odds and become successful is an inspiration and will also give you an understanding of their journey in detail. You can understand their mindset, their struggles and strategies. These stories might give you the push and ideas you need to believe in yourself.'
    }
},
S7_inv5 : {
    id: 'S7_inv5',
    title : 'You just gained career alternative experience!',
    inventoryCardProps : { 
      category : 'Career Development Skills',
      title : 'Career Alternative',
      icon : 'InventorySkills',
      desc : 'Madhav wanted to become an IAS officer, but after failing in three attempts, he is forced to think of other career journeys he could take. Career alternatives are 2-3 career options suitable for an individual. Usually, career decision making leads an individual to pick a career of choice and pursue it. Having more than one career option is better to approach career decision-making. You will find having a plan for an alternative career path useful when you have to change your career due to personal or professional reasons. When you think of alternative career paths, it would be helpful to utilise your current skills as the foundation. A mindset to think from points of view that draw from different subjects, a range of skills and an interest to keep yourself updated can help you have more than one career option.'
    }
},
S7_inv6 : {
    id: 'S7_inv6',
    title : 'You just gained career plan experience!',
    inventoryCardProps : { 
      category : 'Career Suitability Tools',
      title : 'Career plan',
      icon : 'InventoryTools',
      desc : 'While working as a teacher in a private school for a few years, the academic coordinator discusses the possible career progression in the school for Madhav. While making a career plan, you will chart out the milestones you want to reach from the current position, acquire skills, resources, and qualifications, and eventually the career. A career plan also acts as a visual depiction of the journey one takes to reach career goals. It will force you to conceive goals and fix timelines to achieve them.'
    }
},
S7_inv7 : {
    id: 'S7_inv7',
    title : 'You just gained talk with expert experience!',
    inventoryCardProps : { 
      category : 'Self Development',
      title : 'Talk with Expert',
      icon : 'InventorySelfDev',
      desc : 'When Madhav begins his career as a journalist, he talks to Suraj Sir, who helps Madhav get into the field. Speaking with experts is helpful to understand a lot of knowledge thoroughly and get another perspective. They will help you prioritise and guide you to focus on things worth focusing on and make informed decisions. Talking to experts is a great way to expand career information research and learn more about a career. You can speak to the expert for a shorter time, and they can help clarify specific issues or concerns you have in their area of expertise.'
    }
},
S7_inv8 : {
    id: 'S7_inv8',
    title : 'You just gained Training experience!',
    inventoryCardProps : { 
      category : 'Understanding Work Environment',
      title : 'Training',
      icon : 'InventoryWork',
      desc : 'Madhav clears his UPSC and gets selected in the Indian Revenue Service based on his rank. The IRS training is for two years, with four months of foundation training and 16 months of specialised training. The training is designed to enable the Officer Trainees like Madhav to acquire the knowledge and skills they need to function effectively and efficiently as tax administrators. The training is intense and consists of lectures, sports, and extracurricular activities. As part of the training, they travel across different country districts. The training prepares a trainee to face all kinds of situations and learn about the various aspects of their profession. After completing the mandated training, the trainees get their posting or role.'
    }
},
S7_inv9 : {
    id: 'S7_inv9',
    title : 'You just gained Training experience!',
    inventoryCardProps : { 
      category : 'Understanding Work Environment',
      title : 'Training',
      icon : 'InventoryWork',
      desc : 'After clearing the UPSC exams as an IAS, Madhav undergoes a year-long training at Mussorie. For Madhav, the training is intensive every day for 8-9 hours and consists of lectures, sports, and extracurricular activities. It also gives them a chance to form connections with other candidates. As part of the training, they travel across the different districts of the country. The training prepares a trainee to face all kinds of situations and learn about the various aspects of their profession. After completing the mandated training, the trainees get their posting or role.'
    }
},
S7_inv10 : {
    id: 'S7_inv10',
    title : 'You just gained Conversation with significant others(Friends) experience!',
    inventoryCardProps : { 
      category : 'Self Development',
      title : 'Conversation with significant others',
      icon : 'InventorySelfDev',
      desc : 'Razia applies for an internship at an eco-resort while studying at the vocational training institute. An internship is a short-term work experience offered by companies, usually to students. An internship is a good opportunity to get exposure to the working environment related to your field of study, often within a specific industry. Each industry works differently. The best way to find an internship is by looking for someone who already works in that industry.'
    }
},
S7_inv11 : {
    id: 'S7_inv11',
    title : 'You just gained Scholarship experience!',
    inventoryCardProps : { 
      category : 'Miscellaneous',
      title : 'Scholarship',
      icon : 'InventoryMisc',
      desc : 'Madhav gets admission to the boarding school through a scholarship. Scholarships are financial aid or awards designed to help students pay for their education. Scholarships come from various sources, including clubs, organisations, charities, foundations, businesses, colleges and universities, the government and individuals. The money from the scholarship could be for tuition fees, study resources, travel, or accommodation. Each scholarship has its criteria; some scholarships are awarded based on need. You need to follow all the steps and often compete with others to procure a scholarship. You can  apply for scholarships during different stages of your education and professional development.'
    }
},
S7_inv12 : {
    id: 'S7_inv12',
    title : 'You just gained self learning experience!',
    inventoryCardProps : { 
      category : 'Career Development Skills',
      title : 'Self Learning',
      icon : 'InventorySkills',
      desc : 'Madhav opts to self learn for the UPSC exams. Self-learning is a process by which you take the initiative to know your learning needs and learn in the way you learn best. Self-learning is enriching and worthwhile because you set a convenient and affordable pace to achieve your learning outcome. It is also rooted in things you are curious about and motivated to learn. You can choose the language and resources that work best for you, like videos, books, talking to people, learning by doing, open access courses or tutorials. When you want to brush up on your skills as a professional and refresh your knowledge, self-learning is a helpful tool. People in your network can help you design your goals and outcomes.'
    }
},
S7_inv13 : {
    id: 'S7_inv13',
    title : 'You just gained networking experience!',
    inventoryCardProps : { category : 'Career Development Skills',
      title : 'Networking',
      icon : 'InventorySkills',
      desc : 'During the training, Madhav meets other officers also selected like him. The whole batch undergoes the training together in different services. They form a strong connection with each other during the training period. Connections are an essential part of professional life. Networking is the art of initiating, building and sustaining professional relationships. It is a process that fosters the exchange of information and ideas among individuals or groups that share a common interest. You will have many opportunities from various directions to meet people from different industries in your career journey.'
    }
},
S8_inv1 : {
  id: 'S8_inv1',
  title : 'You have gained Conversation with significant others experience!',
  inventoryCardProps : {
    category : 'Self Development',
    title : 'Conversation with significant others',
    icon : 'InventorySelfDev',
    desc : 'Arvind discusses with Roshni his interest in technology and engineering and his desire to pursue it after the 12th standard. Significant others are people who influence the life and choices of an individual. Being part of a collective society, your families and friends guide your decisions. Society plays a significant role in suggesting the norms and behaviours of individuals in our community. While growing up, discussions with teachers, parents, extended family, and friends provide different perspectives to reflect on a situation holistically. To have an effective discussion, make sure that you pick the right time to talk, be clear with what you want to discuss, and listen to their points of view. However, you need to pay attention to the biases of these discussions and make your own decision.'
  }
},
S8_inv2 : {
  id: 'S8_inv2',
  title : 'You just gained Internet Research experience!',
  inventoryCardProps : { 
    category : 'Career Development Skills',
    title : 'Internet Research',
    icon : 'InventorySkills',
    desc : 'To help Roshini search for the best college and course, Arvind uses the internet for his research. Internet is a powerful tool to deepen the understanding of the world of work. The identity of an organisation nowadays include websites, blogs and social media posts. Internet research could provide basic information about jobs and industries. Using the internet, you can seek career information and gather inputs on specific careers to understand the world of work. Internet is also infamous for inaccurate and invalid information. Checking information from multiple websites might be essential to ensure valid and relevant data.'
  }
},
S8_inv3 : {
  id: 'S8_inv3',
  title : 'You just gained gender and identity experience!',
  inventoryCardProps : { 
    category : 'Self Development',
    title : 'Gender and Identity',
    icon : 'InventorySelfDev',
    desc : 'In the online support group meeting, Arvind hears people talking openly about their gender and sexuality in a non-judgemental way. This gives him the courage to express himself without fear. Gender identity is an aspect of our self-identity as a male, female, or another gender.  People express their gender identities in different ways.  LGBTQ+ is an acronym for lesbian, gay, bisexual, transgender, queer. These terms describe the sexual orientation or gender identity of a person. The + signifies all gender identities and sexual orientations beyond the other five initials. People use their clothes, behaviour, and mannerisms to live as the gender that feels right for them.'
  }
},
S8_inv4 : {
  id: 'S8_inv4',
  title : 'You just gained role model interaction experience!',
  inventoryCardProps : { 
    category : 'Career Development Skills',
    title : 'Role Model Interaction',
    icon : 'InventorySkills',
    desc : 'Arvind decides to drop out of engineering, and before deciding what to study, he talks to Shikha, a transgender lawyer. Talking to Shikha helps Arvind get clarity on his career journey. A role model inspires others to imitate their good behaviour. The presence and absence of role models affect how students decide on their career paths. Interacting with them will give you insights into their lives and career journeys. Before interacting with your role model, do some basic research to decide what kind of conversation you want to have with them. You can prepare your questions and talk to them based on your research. When you get a chance to interact with your role models, be mindful of their time, speak politely and professionally, avoid asking personal questions.'
  }
},
S8_inv5 : {
  id: 'S8_inv5',
  title : 'You just gained talking to an expert experience!',
  inventoryCardProps : { 
    category : 'Career Suitability Tools',
    title : 'Talk to an expert',
    icon : 'InventoryTools',
    desc : 'Roshini asks Arvind to talk to Prof. Prasidh about his study options after he discontinues his engineering. Speaking with experts is helpful to understand a lot of knowledge thoroughly and get another perspective. They will guide you to focus on the right things and assist you in making informed decisions. Talking to experts is a great way to get career information and learn more about trends. You can speak to the expert for a shorter time, and they can help clarify specific issues or concerns you have in their area of expertise.'
  }
},
S8_inv6 : {
  id: 'S8_inv6',
  title : 'You just gained Research - prioritse questions experience!',
  inventoryCardProps : { 
    category : 'Career Development Skills',
    title : 'Research - prioritse questions',
    icon : 'InventorySkills',
    desc : 'As part of his project, Arvind reaches out to an organisation to know more about job opportunities for transgender people. This process of obtaining information about a topic is called research. It is important to prepare for the research interaction to get the correct information. Like Arvind, it is essential that before reaching out to someone for information, make sure you know the questions you want to ask them. It is essential to have the research objectives clearly defined before going into a conversation. It is also necessary to prioritise the questions and respect the time of the professional you interact with.'
  }
},
S8_inv7 : {
  id: 'S8_inv7',
  title : 'You just gained Understanding Self-gender and transitioning experience!',
  inventoryCardProps : { 
    category : 'Self Development',
    title : 'Understanding Self-gender and transitioning',
    icon : 'InventorySelfDev',
    desc : "Arvind is assigned a male at birth but identifies as a female. The bodily changes are also different for Arvind than other boys. Later as an adult, Arvind identifies as a transgender woman, Asmita. Transgender is a term for persons whose gender identity, gender expression or behaviour does not match the sex they are assigned at birth. Gender transition may or may not include changing your clothing, appearance, name, or the pronoun people use to refer to you (like 'she', 'he', or 'they'). Some people change their identification documents, like their driver's license or passport, to reflect their gender. Transitioning from one gender to another is complex and may involve medical procedures to change their gender characteristics."
  }
},
S8_inv8 : {
  id: 'S8_inv8',
  title : 'You just gained fellowship experience!',
  inventoryCardProps : { 
    category : 'Understanding Work Environment',
    title : 'Fellowship',
    icon : 'InventoryWork',
    desc : 'After graduation, Asmita joins the Transform Fellowship Programme. A fellowship is a short program that offers an opportunity to grow your knowledge and gain experience in a particular. Usually, a fellowship lasts between 10 months to 2 years, and most Fellowships involve a monthly stipend. The fellowship Asmita joins, recognises the stigma and discrimination faced by transgender people and seeks to bring them into the mainstream by protecting and promoting the rights of LGBTQ+ people. Many fellowships in the social sector are industry-specific. You can research about them and choose the ones you want to apply to.'
  }
},
S8_inv9 : {
  id: 'S8_inv9',
  title : 'You just gained Interview - assessing for yourself experience!',
  inventoryCardProps : { 
    category : 'Career Suitability Tools',
    title : 'Interview - assessing for yourself',
    icon : 'InventoryTools',
    desc : "Arvind attends the interview at Metalab to understand the role and their gender equality policies to decide if he feels safe to work there. An interview is a stage in the hiring process where members usually potential team leaders engage with applicants  and ask questions to understand their suitability. It is an essential process as it allows the employer to assess if an applicant will be a good fit for the job and the applicant to know about the role, the organisational culture and opportunities for personal and professional growth available to them. When you attend the interview physically, you will also get an opportunity to see the office space and see employees at work. This experience may be important for many to make a decision."
  }
},
S8_inv10 : {
  id: 'S8_inv10',
  title : 'You just gained market study and trends experience!',
  inventoryCardProps : { 
    category : 'Understanding Work Environment',
    title : 'Market Study and Trends',
    icon : 'InventoryWork',
    desc : 'Razia studies the college brochure to find out the courses that have the most jobs. Researching the market and trends before selecting a particular course is to know which industry is doing well or not. A trend is a general direction in which something is developing or changing based on what is popular. You can know the market trends by reading newspapers, articles, talking to people to understand the opportunities available after completing the course, and understanding if the trend is only there for a short time.'
  }
},
S8_inv11 : {
  id: 'S8_inv11',
  title : 'You just gained Training experience!',
  inventoryCardProps : { 
    category : 'Understanding Work Environment',
    title : 'Training',
    icon : 'InventoryWork',
    desc : "Arvind joins Metalab as a trainee and undergoes a six-month training program. Training is a formal process by an organisation providing its employees to learn on the job, upskilling themselves for a particular topic, getting introduced to new skills through courses specifically designed by the organisation for a specific position. The training period varies from two weeks to a few months. There are different types of training available and is dependant on the condition of the employment and the employee's skill level."
  }
},
S8_inv12 : {
  id: 'S8_inv12',
  title : 'You just gained Research(Alumni) experience!',
  inventoryCardProps : { 
    category : 'Career Development Skills',
    title : 'Research(Alumni)',
    icon : 'InventorySkills',
    desc : 'Arvind talks to his senior from college to understand and get information on starting a career as a freelance programmer. This process of obtaining information about a topic is called research. Research opens up new pathways of doing things differently and provides new ways to overcome difficulties and challenges. There are different forms of research, and talking to people from the industry to know more about their journey is also research.'
  }
},
S8_inv13 : {
  id: 'S8_inv13',
  title : 'You just gained negotiation experience!',
  inventoryCardProps : { 
    category : 'Career Development Skills',
    title : 'Negotiation',
    icon : 'InventorySkills',
    desc : 'After her transition, Asmita faces issues with the number of projects she handles at Metalab. She talks to her project lead to get back on the projects she was taking before transitioning. Pursuing a path often could mean negotiating with family, friends, or people at workplace, and the tactics of negotiation can vary under different situations and contexts. Negotiation allows room to evaluate the choices presented to a group of people, and those involved often persuade each other by sharing how changing something in the process can better outcomes and experience for everyone involved. Negotiation is an important life skill. To make a successful negotiation, a person must have clarity about the proposal, benefits of the proposal, impact, and the consequences for the parties involved. It also requires that the person communicate his viewpoints effectively and settle for a mutually agreeable outcome.'
  }
},
S8_inv14 : {
  id: 'S8_inv14',
  title : 'You just gained application process(outside India) experience!',
  inventoryCardProps : { 
    category : 'Career Suitability Tools',
    title : 'Application Process(outside India)',
    icon : 'InventoryTools',
    desc : "After working for a few years, Asmita wants to study further and do her MBA abroad. Applying to universities abroad is a big financial and time investment. Each college, course and country has a different application process. Hence, it's essential to start planning in advance. It may take about 1.5 years from applying to colleges to getting selected. It mainly requires to appear for an exam such as GMAT, GRE, IELTS, TOEFL etc. Education loans and scholarships are two ways to help students with their finances. There are a lot of resources available online and on the college websites to make the application process easier for you."
  }
},
S8_inv15 : {
  id: 'S8_inv15',
  title : 'You just gained Freelance experience!',
  inventoryCardProps : { category : 'Miscellaneous',
    title : 'Freelance',
    icon : 'InventoryMisc',
    desc : 'Arvind decides to work as a freelance developer and creates his profile to get work. A freelance job is where a person works for different clients rather than for a company. When you work as a freelancer, you earn wages per-project basis. The nature of work is usually short-term. Freelancing provides you with flexibility and control to choose your work hours, the kind of work you want to do, and the type of clients. As a freelancer, you have to build your network to get enough work. There are platforms dedicated to freelancers where you can upload your profile. People looking for your skill can approach you for employment. Make sure you network, plan, budget, and build a strong profile to develop a promising freelance career.'
  }
},
S8_inv16 : {
  id: 'S8_inv16',
  title : 'You just gained research experience!',
  inventoryCardProps : { category : 'Career Development Skills',
    title : 'Research',
    icon : 'InventorySkills',
    desc : 'After transitioning, Asmita decides to apply to companies for a full-time job. Before applying, she researches the LGBTQ+ policy changes organisations have made and applies to the LGBTQ+ friendly organisations. This process of obtaining information about a topic is called research. Research opens up new pathways to doing things differently, and it helps an individual make connections between ideas and actions. You can research about an organisation by going through their website, case studies, blogs, interviews of their ceos or executive leadership and any material posted by them on the internet. You could also gather unbiased information from an organisation ranking website. You can also look up of their employees on professional networks and talk to them. It is essential to decide the sources of information and tools used to collect new and relevant information as research can becarried out in different ways and for different purposes.'
  }
},
}
