import React, { useState, Fragment, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  StyleOverlayBlackScreen,
  StyleOverlayBlackScreenWrapper,
} from "../../Theme/globalStyledComps";
import { StyledH6 } from "../blocks/Typo/style";
import { IconButton } from "../Button";
import PageContainer from "../PageContainer";
import { StyledMenuListWrapper } from "./style";
import { theme } from "../../Theme";
import styled from "styled-components";
import { X } from "phosphor-react";
import { Metrics } from "../Metrics";
import { PopUp } from "../PopUp";
import { OverlayBlackScreen } from "../OverlayBlackScreen";
import { NavContext } from "../../contexts/NavContext";
import {
  CirclesAnimation,
  MetricsAddedAnimation,
  AlertAnimation,
} from "../Animations";
import { GameItemsContext } from "../../contexts/GameItemsContext";
import { GlowAnimation } from "../Animations";
import { __LOC } from "../../utils/locale";
import { SoundContext } from "../../contexts/SoundContext";
const {
  colors: { CYAN, SKY_BLUE2, BG_YELLOW },
} = theme;
//
export const MetricsButton = (props) => {
  const [showMetrics, setShowMetrics] = useState(false);
  const [alert, setAlert] = useState("");
  const { metrics, lastAddedMetrics } = useContext(GameItemsContext);
  const { setPageNav, triggerMetricsCircles, setTriggerMetricsCircles } =
    useContext(NavContext);
  
  const {triggerSoundEffect} = useContext(SoundContext)

  const localizedPointsUpdatedText = __LOC("Career Points Updated!")

  useEffect(() => {
    if (triggerMetricsCircles > 0){
       setAlert(localizedPointsUpdatedText);
       triggerSoundEffect('greenCircles')
    }
    
  }, [triggerMetricsCircles]);
  
  /**
   * minus cuz on first mount, the metrics useeffect will run,
   * and allow it to increment to 0, which wont trigger animation,
   * which is what we want.
   */

  const location = useLocation();

  return (
    <Fragment>
      <OverlayBlackScreen show={showMetrics} />
      {showMetrics && (
        <PopUp closePopUp={() => setShowMetrics(false)}>
          <div>
            <h2
              className="h2 bold"
              style={{
                textAlign: "center",
                paddingBottom: "1rem",
                color: theme.colors.RED,
              }}
            >
              {__LOC("Career Points")}
            </h2>
            <Metrics />
            <Link
              className="a"
              to={{
                pathname: "/onboarding",
                search: "?step=2",
                state: {
                  showOnboardingNav: false,
                  startRoute: location.pathname + location.search,
                },
              }}
            >
              <h5
                className="p"
                style={{ textAlign: "center", paddingTop: "2rem" }}
              >
                {__LOC("Read more")}
              </h5>
            </Link>
          </div>
        </PopUp>
      )}
      <div
        style={{
          position: "relative",
          zIndex:
            showMetrics || props.activeCoach === "metricsButton"
              ? 100
              : "unset",
        }}
      >
        {
          <CirclesAnimation
            size={500}
            trigger={triggerMetricsCircles > 0 && triggerMetricsCircles}
            circlesData={[
              { color: SKY_BLUE2, delay: 0 },
              { color: CYAN, delay: 0.05 },
              { color: SKY_BLUE2, delay: 0.15 },
              { color: CYAN, delay: 0.3 },
              { color: SKY_BLUE2, delay: 0.5 },
            ]}
          />
        }
        {alert && (
          <AlertAnimation
            alert={alert}
            color={"blue"}
            removeAlert={() => {
              setAlert("");
              setTriggerMetricsCircles(0);
            }}
          />
        )}
        <div style={{ position: "relative" }}>
          <IconButton
            nav
            icon={"Metrics"}
            onClick={() => setShowMetrics((prev) => !prev)}
            active={showMetrics && true}
          />
        </div>
      </div>
    </Fragment>
  );
};
