/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";

export const MultipleChoiceContext = createContext();

export const MultipleChoiceProvider = ({ children }) => {
  const [selectionAllowed, setSelectionAllowed] = useState(true);
  const [selectedChoice, setSelectedChoice] = useState([]);
  const [allowedSelectionCount, setAllowedSelectionCount] = useState(null);

  useEffect(() => {
    if (
      selectedChoice.length >= allowedSelectionCount &&
      allowedSelectionCount
    ) {
      setSelectionAllowed(false);
    } else {
      setSelectionAllowed(true);
    }
  }, [selectedChoice]);

  return (
    <MultipleChoiceContext.Provider
      value={{
        selectedChoice,
        selectionAllowed,
        setSelectionAllowed,
        setSelectedChoice,
        allowedSelectionCount,
        setAllowedSelectionCount,
      }}>
      {children}
    </MultipleChoiceContext.Provider>
  );
};
