
  export const bubbleConfig_mainMenu_inventoryButton = [
    {
      title: "Menu",
      desc: 'Use this to access options like, character select, about the game and many more',
      top: "7rem",
      left: "2rem",
      bubbleTipPos: "topLeft",
    },
    {
        title: "Experience Cards",
        desc: 'Use this to see the various experience cards you collect throughout the game',
      top: "7rem",
      right: "2rem",
      bubbleTipPos: "topRight",
    },
  ];

  export const bubbleConfig_mapButton_metricsButton = [
    {
      title: "Career Points",
      desc: 'Use this to see the career points you have collected in a story',
      top: "7rem",
      right: "12rem",
      bubbleTipPos: "topRight",
    },
    {
        title: "Map",
        desc: 'Use this to see the progress you’ve made in a story',
      top: "7rem",
      right: "7rem",
      bubbleTipPos: "topRight",
    },
  ];

  export const bubbleConfig_charSelect_leftRightNav = [
    {
      desc: "Change Characters using these arrows",
      top: "calc(50% + 5rem)",
      right: "0rem",
      bubbleTipPos: "topRight",
    }
  ];

  export const coachMark_progressMap_horScroll = {
    title : 'Explore the journey and decisions you’ve made through this map.',
    subtitle : 'Swipe left and right to explore the map'
  }

  export const coachMark_progressMap_nodeStates = {
    title : 'Once you complete one journey of the story, your decision points will get unlocked. Click on the unlocked decision point to go back to a decision in the story.',
    lockedDP : 'Locked decision point',
    unlockedDP : 'Unlocked decision point'
  }
