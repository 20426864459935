/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";

export const MultiSelectContext = createContext();

export const MultiSelectProvider = ({ children }) => {
 
  const [selected, setSelected] = useState([]);
  const [activeSelectedIdx, setActiveSelectedIdx] = useState(undefined);

  return (
    <MultiSelectContext.Provider
      value={{
        selected,
        setSelected,
        activeSelectedIdx,
        setActiveSelectedIdx
      }}
      >
      {children}
    </MultiSelectContext.Provider>
  );
};
