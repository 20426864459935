import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={26}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.215.617h22.02a1 1 0 011 1v28a1 1 0 01-1 1H2.315a1 1 0 01-.781-1.624l9.724-12.176a1 1 0 00.023-1.217L1.41 2.21A1 1 0 012.215.618z"
        fill="#000"
      />
      <path
        d="M2.215.617h22.02a1 1 0 011 1v28a1 1 0 01-1 1H2.315a1 1 0 01-.781-1.624l9.724-12.176a1 1 0 00.023-1.217L1.41 2.21A1 1 0 012.215.618z"
        fill="#000"
      />
      {/* <path
        d="M1.008 2.507C.278 1.517.985.117 2.215.117h23.02v1H2.216a.5.5 0 00-.402.797l9.87 13.389a1.5 1.5 0 01-.036 1.826L1.923 29.305a.5.5 0 00.39.812h22.923v1H2.314c-1.257 0-1.957-1.454-1.172-2.436l9.724-12.176a.5.5 0 00.012-.609L1.008 2.507z"
        fill="#222"
      /> */}
    </svg>
  )
}

export default SvgComponent
