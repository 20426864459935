import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.502 21.226l-.001.002v-.002zm-.093-.087a.41.41 0 01.087.1.408.408 0 01-.087.1c-.11.1-.3.22-.593.35-.581.255-1.453.498-2.569.706-2.223.415-5.316.674-8.747.674-3.43 0-6.524-.26-8.747-.674-1.116-.208-1.988-.45-2.569-.707-.292-.128-.483-.249-.593-.35a.41.41 0 01-.087-.1.411.411 0 01.087-.1c.11-.1.3-.22.593-.349.581-.256 1.453-.499 2.569-.707 2.223-.414 5.316-.674 8.747-.674 3.43 0 6.524.26 8.747.674 1.116.208 1.988.451 2.569.707.292.129.483.25.593.35zM.5 21.226v0zm0 .026v-.002.002zM24.5 21.25v0z"
        fill="#fff"
        stroke="#222"
      />
      <path
        d="M5.358 9.268l5.284-5.033a3 3 0 014.138 0l5.285 5.033a3 3 0 01.931 2.172v6.366a3 3 0 01-3 3H7.426a3 3 0 01-3-3V11.44a3 3 0 01.932-2.172z"
        fill="#fff"
        stroke="#222"
      />
      <path
        d="M10.731 4.763l-6.675 5.941a.95.95 0 01-.631.24c-.88 0-1.287-1.092-.622-1.667L10.744 2.4a3 3 0 013.944.015l7.69 6.759c.617.543.233 1.56-.588 1.56a.891.891 0 01-.592-.224L14.717 4.76a3 3 0 00-3.986.003zM10.45 16.27a2.064 2.064 0 014.13 0v4.432h-4.13V16.27z"
        fill="#fff"
        stroke="#222"
      />
    </svg>
  )
}

export default SvgComponent