import styled from 'styled-components/macro';

export const SplashTitle = styled.h1`
    ${({theme}) => theme.typo.h1}
    ${({theme}) => theme.typo.bold}
    text-align: center;
    color: ${({theme}) => theme.colors.RED};
`

export const SplashTitleWrapper = styled.h1`
    position: absolute;
    top: 10.5vh;


`