import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 5.422v9.516a.426.426 0 01-.426.426H1.426A.426.426 0 011 14.938V5.426C1 5.191 1.191 5 1.426 5h17.152a.42.42 0 01.422.422z"
        fill="#8FBA1F"
        stroke="#222"
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M12.198 10.185a2.2 2.2 0 11-4.4 0 2.2 2.2 0 014.4 0zM5.013 11.39a1.205 1.205 0 100-2.41 1.205 1.205 0 000 2.41zM14.987 11.39a1.205 1.205 0 100-2.41 1.205 1.205 0 000 2.41z"
        fill="#8FBA1F"
        stroke="#222"
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent