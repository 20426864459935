import React, { useState } from "react";


export const StoryContext = React.createContext();

export const StoryProvider = ({children}) => {
    const [data, setData] = useState(undefined);

    return (
        <StoryContext.Provider
            value={{
                data, setData
            }}
            >
            {children}
        </StoryContext.Provider>
    )
}