import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { CONFIG } from "../CONFIG";
import { SoundContext } from "./SoundContext";
const { _Env } = CONFIG;

export const NavContext = React.createContext();

export const NavContextProvider = ({ children }) => {
  const [nav, setNav] = useState({
    left: [],
    right: [],
  });

  const {pathname, search} = useLocation();
  const [pageNav, setPageNav] = useState(true);
  const [pageNavActive, setPageNavActive] = useState(true);
  const [pageNavAction, setPageNavAction] = useState(undefined);
  const [pageScrolledUp, setPageScrolledUp] = useState(false)
  const [pageNavStyle, setPageNavStyle] = useState(undefined)
  const [triggerMetricsCircles, setTriggerMetricsCircles] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0);
  },[pathname, search])

  return (
    <NavContext.Provider
      value={{
        nav,
        setNav,
        pageNav,
        setPageNav,
        pageNavActive,
        setPageNavActive,
        pageNavAction,
        setPageNavAction,
        setPageScrolledUp,
        pageScrolledUp,
        setPageNavStyle,
        pageNavStyle,
        triggerMetricsCircles, setTriggerMetricsCircles
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export const useSetMainNav = (options = {}) => {

  
  const { setNav } = useContext(NavContext);
  const { pathname } = useLocation();
  const { params } = useRouteMatch()
  const { handleControlsChange, handleAllControlsChange, hardMute } = useContext(SoundContext);

  
  const handleToggleMuteAllSounds = () => {
    // set isMute to false is it current IS mute, since 
    // immediately after this, it will be the opposite of mute
    handleAllControlsChange(
      "music", 
      { hardMute: !hardMute }
    );
    handleControlsChange("ambience", { hardMute: !hardMute });
    handleControlsChange("soundEffects", { hardMute: !hardMute });
    
  };

  const useEffectRerunArray = [
    options.useEffectRerunVar,
    pathname, hardMute
  ]

  useEffect(() => {
    if(options.abortCondition && options.abortCondition === true) return;
    setNav((prev) => ({
      ...prev,
      right: [
        ...(options.inStoryNav
          ? [
              {
                id: 'metricButton',
                comp: "MetricsButton"
              },
              {
                id: 'mapButton',
                comp: "IconButton",
                to: {
                  pathname: `/progressMap/${params.storyId}`,
                  search: `?startRoute=${encodeURIComponent(pathname)}`
                },
                icon: "Map",
              },
            ]
          : []),
        {
          id: 'invButton',
          comp: "InventoryButton",
          to: {
            pathname: "/inventory",
            state: { startRoute: pathname },
          },
          icon: "Inventory",
        },
      ],
      left: [
        {
          comp: "MainMenu",
          id: 'mainMenuButton',
          links: [
            { icon: "Characters", to: "/", display: "Characters" },
            {
              id: 'about', 
              icon: "About", 
              to: {
                pathname: "/onboarding",
                state: { startRoute: pathname },
                search: "?step=0",
              }, 
              display: "About" 
            },
            {
              id: 'help',
              icon: "Help",
              to: {
                pathname: "/onboarding",
                state: { startRoute: pathname },
                search: "?step=0",
              },
              display: "Help",
            },
            {
              id: 'language',
              icon: "Language",
              to: {
                pathname: "/languageSelect",
                state: { startRoute: pathname },
              },
              display: "Language",
            },
            {
              id: 'sound',
              icon: hardMute ? "SpeakerHigh" : "SpeakerX",
              onClick: () => handleToggleMuteAllSounds(),
              display: hardMute ? "Unmute" : "Mute",
            },
            ...(_Env === "dev"
              ? [
                  {
                    id: 'tci',
                    icon: "GameController",
                    to: "/tci/0ccc7b67fa37c162f8b8e9e964f7ead6a3c34862",
                    display: "TCI",
                  },
                ]
              : []),
          ],
        },
      ],
    }));

    return () => setNav((prev) => ({ ...prev, left: [], right: [] }));
  }, useEffectRerunArray);
};
