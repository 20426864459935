import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={2.5}
        y={2.5}
        width={5.391}
        height={20}
        rx={1.5}
        fill="#fff"
        stroke="#000"
      />
      <rect
        x={9.805}
        y={6.5}
        width={5.391}
        height={16}
        rx={1.5}
        fill="#fff"
        stroke="#000"
      />
      <rect
        x={17.109}
        y={10.5}
        width={5.391}
        height={12}
        rx={1.5}
        fill="#fff"
        stroke="#000"
      />
    </svg>
  )
}

export default SvgComponent