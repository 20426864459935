import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import App from "./App";
import "./index.scss";
import { theme } from "./Theme";
import GlobalStyle from "./Theme/globalStyles";
import {EnvContextProvider} from './contexts/EnvContext';
import { SoundContextProvider } from "./contexts/SoundContext";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalStyle/>
      <ThemeProvider theme={theme}>
        <EnvContextProvider>
          <SoundContextProvider>
            <App />
          </SoundContextProvider>
        </EnvContextProvider>
      </ThemeProvider>
      {/* </GlobalStyle> */}
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
